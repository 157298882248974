import React, { FC, memo } from "react";
import { StatusEnum } from "../../shared/enums/statusEnum";
import Box from "@material-ui/core/Box";
import { IChipStatus } from "./ChipStatus.interfaces";
import { chipStatusStyles } from "./ChipStatus.styles";

export const statusLabels = new Map([
  [StatusEnum.DISABLED, "INACTIVO"],
  [StatusEnum.ENABLED, "ACTIVO"],
]);

const ChipStatus: FC<IChipStatus> = ({ status }: IChipStatus) => {
  const classes = chipStatusStyles({ status });

  return <Box className={classes.chip}>{statusLabels.get(status)}</Box>;
};

export default memo(ChipStatus);
