import React from "react";
import { Grid, Skeleton } from "@mui/material";
import { FormSkeletonStyles } from "./FormSkeleton.styles";

type formSkeletonProps = {
  rows: number;
};

export const FormSkeleton: React.FC<formSkeletonProps> = (
  props: formSkeletonProps
) => {
  const totalRows: number[] = Array.from(Array(props.rows).keys());

  return (
    <>
      <Grid
        spacing={2}
        alignItems="center"
        container
        data-testid="form-skeleton"
      >
        {totalRows.map((row) => {
          return (
            <Grid
              key={`key-${row}`}
              md={6}
              xs={6}
              item
              sx={FormSkeletonStyles.skeletonText}
            >
              <Skeleton
                id={`id${row}`}
                key={`sk${row}`}
                sx={FormSkeletonStyles.skeleton}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
