import { useDispatch } from "react-redux";
import { setOpenModalInfo } from "../../../store/actionCreators";
import { IUseModalContainerBranch } from "../DataTableContainerBranch.interface";
import { useEffect, useState } from "react";
import { IModalDetailBranchHeaderProps } from "../../../shared/infrastructure/interfaces/ModalDetailHeaderBranch";
import { get } from "lodash";
import { PathsEnum } from "../../../shared/infrastructure/enums/PathsEnum";
import { IModalHeaderProps } from "@kushki/connect-ui/dist/Components/Molecules/ModalDetail/ModalHeader/ModalHeader.interfaces";
import {
  IElementModalBody,
  IElementModalComponentProps,
  IModalInformation,
  IModalTemplateInfo,
} from "../../../shared/infrastructure/interfaces/ModalContainer";
import {
  MODAL_TEMPLATE_BRANCH,
  PATH_RULE_SETTING,
} from "../../../shared/infrastructure/enums/ModalEnum";
import { LabelEnum } from "../../../shared/infrastructure/enums/LabelEnum";
import { useAppSelector } from "../../../store/hooks/store-hook";
import { ItemBusinessRule } from "../../../../types/item_business-rule";
import { format } from "date-fns-tz";
import { DateFormatEnum } from "../../../shared/infrastructure/constants/BusinessRuleConstants";
import { MerchantProcessors } from "../../../../types/merchant_processors";
import { getIsMassiveRules } from "../../../shared/utils/localStorage";

export const useModalContainerBranch = (): IUseModalContainerBranch => {
  const dispatch = useDispatch();

  const requestRuleById: ItemBusinessRule | undefined = useAppSelector(
    (state) => get(state.layout, "requestRule", undefined)
  );

  const isOpenModal: boolean = useAppSelector((state) =>
    get(state.layout, "openModalInfo", false)
  );

  const processors: MerchantProcessors[] = useAppSelector((state) =>
    get(state.layout, "processors", [])
  );

  const isLoadingOneRule: boolean = useAppSelector((state) =>
    get(state.layout, "isLoadingOneRule", false)
  );

  const [modalHeader] = useState<Omit<IModalHeaderProps, "isLoading">>();

  const [headerRuleModal, setHeaderRuleModal] =
    useState<IModalDetailBranchHeaderProps>();
  const [ruleSettings, setRuleSetting] = useState<
    IElementModalComponentProps | undefined
  >();

  useEffect(() => {
    setRuleSetting(undefined);
    setHeaderRuleModal(undefined);
    buildHeaderRuleModal(requestRuleById);
    buildRuleSetting(requestRuleById);
  }, [requestRuleById, processors]);

  const buildHeaderRuleModal = (item: ItemBusinessRule | undefined): void => {
    const rule_status: string = get(item, PathsEnum.STATUS, "");
    const createdAt: number = get(item, PathsEnum.CREATED, 0);

    const dateCreatedAt = new Date(getIsMassiveRules() ? createdAt : 0);
    if (!getIsMassiveRules()) dateCreatedAt.setUTCSeconds(createdAt);

    const dateTimeFormat = format(
      dateCreatedAt,
      DateFormatEnum.yyyy_mm_dd_hh_mm
    );

    setHeaderRuleModal({
      title: get(item, PathsEnum.RULE_NAME, ""),
      secondaryText: dateTimeFormat,
      status: rule_status.toLowerCase(),
    });
  };

  const buildRuleSetting = (item: ItemBusinessRule | undefined): void => {
    const template: IModalTemplateInfo =
      MODAL_TEMPLATE_BRANCH[LabelEnum.BUSINESS_RULES];
    const ruleSettingsColumns: IModalInformation = get(
      template,
      "ruleSetting.columns"
    );
    const rule: IElementModalBody[] = Object.keys(ruleSettingsColumns).map(
      (property: string) => ({
        label: get(ruleSettingsColumns, property, ""),
        secondaryText: "",
        text: getValuesSettings(item, property),
      })
    );

    setRuleSetting({
      title: get(template, "ruleSetting.title", ""),
      body: [...rule],
      isVisible: true,
    });
  };

  const getValuesSettings = (
    item: ItemBusinessRule | undefined,
    property: string
  ): string => {
    const value = get(item, PATH_RULE_SETTING[property], "");
    if (property === LabelEnum.KEY_PUBLIC_ID) {
      const processorName = get(
        item,
        PATH_RULE_SETTING[LabelEnum.KEY_PROCESSOR_NAME],
        ""
      );
      return getPublicProcessorId(processorName);
    }

    return value;
  };

  const getPublicProcessorId = (processorName: string): string => {
    const merchantProcessors: MerchantProcessors | undefined = processors.find(
      (processor: MerchantProcessors) => processor.alias === processorName
    );
    return get(merchantProcessors, "publicProcessorId", "");
  };

  const onChangeModal = (): void => {
    dispatch(setOpenModalInfo(false));
  };

  return {
    onChangeModal,
    data: {
      ruleModalHeader: headerRuleModal!,
      ruleSetting: ruleSettings!,
    },
    header: modalHeader!,
    isOpenModal,
    isLoadingOneRule,
  };
};
