import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const emptyCommentStyles = createNamedStyles({
  wrapper: {
    flex: 2,
  },
  container: {
    backgroundColor: "#F0F4F8",
    display: "grid",
    placeContent: "center",
    width: "100%",
    height: "150px",
    marginBottom: "1rem",
  },
  message: {
    color: "#293036",
  },
});
