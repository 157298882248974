import React, { FC } from "react";
import { ModalOverlay } from "@kushki/frontend-molecules/modal";
import DetailHeader from "./DetailHeader/DetailHeader";
import DetailFooter from "./DetailFooter/DetailFooter";
import { useSelectedTransactionModalState } from "./state/useRefundDetailModalState";
import { RefundDetailModalProps } from "./RefundDetailModal.interfaces";
import { getSimplifiedFormatedDate } from "../../../shared/utils/date_utils";

const RefundDetailModal: FC<RefundDetailModalProps> = ({
  isHistoricRefundTransaction,
  isOpen,
  onCloseModal,
  refundTransaction,
}: RefundDetailModalProps) => {
  const {
    currentTab,
    handlers: { getTabContent, handleChangeTab },
  } = useSelectedTransactionModalState();
  const TabContent = getTabContent(currentTab);

  const refundDate = isHistoricRefundTransaction
    ? getSimplifiedFormatedDate(
        refundTransaction.completedAt ?? refundTransaction.created
      )
    : getSimplifiedFormatedDate(refundTransaction.created);

  return (
    <ModalOverlay
      header={
        <DetailHeader
          currentTab={currentTab}
          handleChangeTab={handleChangeTab}
          title={"Detalle de Conciliación"}
          headerLineText={`${refundTransaction.merchant_name} ${refundTransaction.country}`}
          subheaderLineText={refundDate}
        />
      }
      body={
        <TabContent
          transaction={refundTransaction}
          showProcessorInfo={isHistoricRefundTransaction}
        />
      }
      footer={<DetailFooter />}
      openModal={isOpen}
      onCloseModal={onCloseModal}
      transition="left"
    />
  );
};

export default RefundDetailModal;
