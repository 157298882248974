import { memo } from "react";
import { Box, Grid } from "@mui/material";
import BaseDataContainer from "../commons/BaseDataContainer/BaseDataContainer";
import { useBusinessRules } from "./state/useBusinessRules";
import {
  AccordionLabelsEnum,
  AccordionRulesLabelsEnum,
} from "../../shared/enums/AccordionLabelsEnum";
import { EntityNameEnum } from "../../shared/enums/entityName";
import { AccordionRules } from "./AccordionRules/AccordionRules";
import { routes } from "../../shared/infrastructure/constants/routes";

const BusinessRules = () => {
  const {
    configRules,
    editRules,
    isLoading,
    table,
    isCentralized,
    getEntityName,
    payoutRulesTable,
  } = useBusinessRules();
  const isDecentralizeOrIsCostumer =
    !isCentralized || getEntityName === EntityNameEnum.CUSTOMER;

  return (
    <BaseDataContainer
      title={AccordionLabelsEnum.BUSINESS_RULES}
      isCentralized={isCentralized}
      entityName={getEntityName}
      withoutEdit={true}
    >
      <Box borderRadius="8px" marginBottom="16px">
        <Grid container gap="2rem">
          <AccordionRules
            id={"rules"}
            table={table}
            isLoading={isLoading}
            isDecentralizeOrIsCostumer={isDecentralizeOrIsCostumer}
            title={AccordionRulesLabelsEnum.BUSINESS_RULES_CARD}
            buttonText={AccordionRulesLabelsEnum.BUSINESS_RULES_BTN_TEXT}
            editRules={() => editRules(routes.LIST_RULE)}
            configRule={() => {
              if (isDecentralizeOrIsCostumer) configRules(routes.CREATE_RULE);
            }}
          />
          <AccordionRules
            id={"payoutRules"}
            title={AccordionRulesLabelsEnum.BUSINESS_RULES_PAYOUTS}
            buttonText={AccordionRulesLabelsEnum.BUSINESS_RULES_BTN_TEXT}
            editRules={() => editRules(routes.LIST_PAYOUTS_RULE)}
            table={payoutRulesTable!}
            isLoading={isLoading}
            isDecentralizeOrIsCostumer={isDecentralizeOrIsCostumer}
            configRule={() => {
              if (isDecentralizeOrIsCostumer) {
                configRules(routes.CREATE_PAYOUT_RULE);
              }
            }}
          />
        </Grid>
      </Box>
    </BaseDataContainer>
  );
};

export const BusinessRulesMemo = memo(BusinessRules);
