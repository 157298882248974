import { CountriesEnum } from "../enums/countries";

export enum CountriesTextValueEnum {
  PERU = "Perú",
  PANAMA = "Panamá",
  MEXICO = "México",
  BRASIL = "Brasil",
}

export const CATALOG_COUNTRY_TEXT_VALUE: Record<CountriesEnum, String> = {
  [CountriesEnum.PERU]: CountriesTextValueEnum.PERU,
  [CountriesEnum.PANAMA]: CountriesTextValueEnum.PANAMA,
  [CountriesEnum.MEXICO]: CountriesTextValueEnum.MEXICO,
  [CountriesEnum.ECUADOR]: CountriesEnum.ECUADOR,
  [CountriesEnum.COLOMBIA]: CountriesEnum.COLOMBIA,
  [CountriesEnum.CHILE]: CountriesEnum.CHILE,
  [CountriesEnum.BRAZIL]: CountriesTextValueEnum.BRASIL,
  [CountriesEnum.EL_SALVADOR]: CountriesEnum.EL_SALVADOR,
  [CountriesEnum.COSTA_RICA]: CountriesEnum.COSTA_RICA,
  [CountriesEnum.GUATEMALA]: CountriesEnum.GUATEMALA,
  [CountriesEnum.HONDURAS]: CountriesEnum.HONDURAS,
  [CountriesEnum.NICARAGUA]: CountriesEnum.NICARAGUA,
  [CountriesEnum.UNITED_STATES]: CountriesEnum.UNITED_STATES,
};
