import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { ICatalogsData } from "../../interfaces/catalogs/catalogs.interfaces";
import { getListCatalogs } from "../../thunks/catalogs/catalogs.thunk";
import { defaultTo } from "lodash";
import { TypeCatalog } from "@shared/enum/TypeCatalog";
import { Catalog } from "@local-types/catalog_response";

export const initialState: ICatalogsData = {
  isLoading: false,
  listCatalogs: [],
  listMccCatalog: [],
};

export const catalogsSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getListCatalogs.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getListCatalogs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getListCatalogs.fulfilled, (state, action) => {
        state.isLoading = false;

        const isMccCatalog: Boolean =
          defaultTo(action.payload.typeCatalog, "").toUpperCase() ==
          TypeCatalog.MCC;

        if (isMccCatalog) {
          state.listMccCatalog = action.payload.data;
        } else {
          state.listCatalogs = action.payload.data;
        }
      });
  },
  initialState,
  name: "catalogs",
  reducers: {
    setIsLoading: (
      state: Draft<ICatalogsData>,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLoading = payload;
    },
    setListCatalogs: (
      state: Draft<ICatalogsData>,
      { payload }: PayloadAction<Catalog[]>
    ) => {
      state.listCatalogs = payload;
    },
    setListMccCatalog: (
      state: Draft<ICatalogsData>,
      { payload }: PayloadAction<Catalog[]>
    ) => {
      state.listMccCatalog = payload;
    },
  },
});

export default catalogsSlice.reducer;
