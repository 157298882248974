import React, { PropsWithChildren } from "react";
import { Grid } from "@mui/material";
import { GeneralTextField } from "../GeneralTextField/GeneralTextField";
import {
  getBanksByCountry,
  getCurrencyByCountry,
  HelperText,
  Labels,
  PAYMENT_BRANDS_OPTIONS,
  PlaceHolders,
} from "../../../../shared/infrastructure/constants/BusinessRuleConstants";
import { defaultTo, get } from "lodash";
import { isOperatorEqualOrDifferent } from "../../../../utils/utilsFile";
import { TransactionType } from "../../../../../types/configuration_response";
import { AmountConditionComponent } from "../AmountConditionComponent/AmountConditionComponent";
import { CountryConditionSection } from "../CountryConditionSection/CountryConditionSection";
import MaskedCardCondition from "../MaskedCardCondition/MaskedCardCondition";
import { ConditionSimpleLabel } from "../ConditionSimpleLabel/ConditionSimpleLabel";
import { SelectComponentSection } from "../SelectComponentSection/SelectComponentSection";
import { ICategory } from "../../../../shared/infrastructure/interfaces/ICategory";
import { ITextFieldContainerProps } from "../../../../shared/infrastructure/interfaces/ITextFieldContainerProps";
import { GenericLabelConditionSection } from "../GenericLabelConditionSection/GenericLabelConditionSection";
import { RuleEnum } from "../../../../shared/infrastructure/enums/RuleEnum";
import { BinTextField } from "../BinTextField/BinTextField";
import { EntityNameEnum } from "../../../../shared/infrastructure/enums/NodeInfoEnum";
import { OptionsSelectSearchBy, SelectSearchBy } from "@kushki/connect-ui";
import { ItemsCategoryData } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectSearchBy/SelectSearchBy.interfaces";

export const TextFieldContainer: React.FC<ITextFieldContainerProps> = (
  props: PropsWithChildren<ITextFieldContainerProps>
) => {
  const basePath: string = "mainProps.conditionsItemComponentProps";
  const itemValueSelected: string[] = get(props, `${basePath}.item.value`, "");
  const itemCustomerCredentialSelected: OptionsSelectSearchBy = get(
    props,
    "defaultCustomerCredential"
  );
  const handleChange = (event: ItemsCategoryData) => {
    props.setSelectedItems([
      {
        label: get(event, "text", ""),
        value: get(event, "secondaryText", ""),
        selected: true,
      },
    ]);
  };

  switch (props.rule) {
    case RuleEnum.BANK:
      return (
        <Grid item xs={4}>
          <GeneralTextField
            conditionProperty={RuleEnum.BANK}
            className={props.className}
            placeHolder={Labels.BANKS}
            items={getBanksByCountry(get(props, "country", []))}
            setSelectedItems={props.setSelectedItems}
            selected={itemValueSelected}
          />
        </Grid>
      );
    case RuleEnum.BRAND:
      return (
        <Grid item xs={4}>
          <GeneralTextField
            conditionProperty={RuleEnum.BRAND}
            className={props.className}
            placeHolder={Labels.BRAND}
            items={PAYMENT_BRANDS_OPTIONS}
            setSelectedItems={props.setSelectedItems}
            selected={itemValueSelected}
          />
        </Grid>
      );
    case RuleEnum.BIN:
      return (
        <Grid item xs={4}>
          <BinTextField
            setSelectedItems={props.setSelectedItems}
            defaultListTextField={itemValueSelected}
          />
        </Grid>
      );
    case RuleEnum.TRANSACTION_TYPE:
      return (
        <Grid item xs={4}>
          {isOperatorEqualOrDifferent(props.operatorValue) ? (
            <GeneralTextField
              conditionProperty={RuleEnum.TRANSACTION_TYPE}
              className={props.className}
              placeHolder={PlaceHolders.TRANSACTION_TYPE}
              items={defaultTo(props.generalTransactionsType, []).map(
                (transaction: TransactionType) => ({
                  label: get(transaction, "title", ""),
                  value: get(transaction, "name", ""),
                })
              )}
              setSelectedItems={props.setSelectedItems}
              selected={itemValueSelected}
            />
          ) : (
            <GeneralTextField
              conditionProperty={RuleEnum.TRANSACTION_TYPE}
              className={props.className}
              placeHolder={PlaceHolders.TRANSACTION_TYPE}
              items={defaultTo(props.generalTransactionsType, []).map(
                (transaction: TransactionType) => ({
                  label: get(transaction, "title", ""),
                  value: get(transaction, "name", ""),
                })
              )}
              setSelectedItems={props.setSelectedItems}
              selected={itemValueSelected}
            />
          )}
        </Grid>
      );
    case RuleEnum.AMOUNT:
      return (
        <Grid item xs={4}>
          <AmountConditionComponent
            index={get(props, `${basePath}.index`)}
            operator={props.operatorValue}
            setSelectedItems={props.setSelectedItems}
            defaultValueList={itemValueSelected}
          />
        </Grid>
      );
    case RuleEnum.COUNTRY:
      return (
        <Grid item container xs={12}>
          <CountryConditionSection
            geographyList={
              get(props, `${basePath}.geographyList`) || {
                regions: [],
                countries: [],
                continents: [],
              }
            }
            index={get(props, `${basePath}.index`)}
            defaultValueList={itemValueSelected}
            setSelectedItems={props.setSelectedItems}
          />
        </Grid>
      );
    case RuleEnum.MASKED_CARD:
      return (
        <Grid item container xs={12} spacing={1}>
          <MaskedCardCondition
            operator={props.operatorValue}
            setSelectedItems={props.setMaskedCardList}
            defaultListCard={itemValueSelected}
          />
        </Grid>
      );
    case RuleEnum.IP:
      return (
        <Grid item xs={12}>
          <GenericLabelConditionSection
            tag={props.rule}
            operator={props.operatorValue}
            setSelectedItems={props.setSelectedItems}
            defaultListTextField={itemValueSelected}
          />
        </Grid>
      );
    case RuleEnum.CARD_TYPE:
    case RuleEnum.DEFERRED:
      return (
        <Grid item xs={4}>
          <ConditionSimpleLabel
            tag={props.rule}
            setSelectedItems={props.setSelectedItems}
            defaultValueList={itemValueSelected}
          />
        </Grid>
      );
    case RuleEnum.CURRENCY:
      return (
        <Grid item xs={4}>
          <SelectComponentSection
            conditionProperty={RuleEnum.CURRENCY}
            placeHolder={Labels.CURRENCY_LIST}
            items={getCurrencyByCountry(
              get(props, "country", []),
              props.currencies
            )}
            message={PlaceHolders.CURRENCY}
            setSelectedItems={props.setSelectedItems}
            selectedValue={itemValueSelected}
          />
        </Grid>
      );
    case RuleEnum.CREDENTIAL:
      if (props.entity === EntityNameEnum.CUSTOMER) {
        return (
          <Grid item xs={4}>
            <SelectSearchBy
              defaultValue={itemCustomerCredentialSelected}
              isEmptyListMessage={Labels.SEARCH_CREDENTIALS}
              items={
                props.mainProps.conditionsItemComponentProps
                  .customerCredentials!
              }
              onInputChange={() => {}}
              onItemSelected={(event: ItemsCategoryData) => {
                handleChange(event);
              }}
              optionsMultiple={{}}
              placeholder={Labels.CREDENTIAL}
            />
          </Grid>
        );
      }
      return (
        <Grid item xs={4}>
          <SelectComponentSection
            conditionProperty={RuleEnum.CREDENTIAL}
            placeHolder={Labels.CREDENTIAL_BRANCH}
            items={
              get(props, `${basePath}.credentials`)
                ? get(props, `${basePath}.credentials`, []).map(
                    (category: ICategory) => {
                      return {
                        label: get(category, "name", ""),
                        value: get(category, "value", "").toString(),
                      };
                    }
                  )
                : [{ label: HelperText.CREDENTIALS, value: "null" }]
            }
            message={PlaceHolders.CREDENTIAL}
            setSelectedItems={props.setSelectedItems}
            selectedValue={itemValueSelected}
          />
        </Grid>
      );
    default:
      return <></>;
  }
};
