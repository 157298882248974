import React from "react";

interface useModalRet {
  isOpen: boolean;
  close: () => void;
  open: () => void;
}

export default function useModal(): useModalRet {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const actions: Record<string, () => void> = {
    close: () => setIsModalOpen(false),
    open: () => setIsModalOpen(true),
  };

  return { close: actions.close, isOpen: isModalOpen, open: actions.open };
}
