export enum ActionTypes {
  SET_AUTH_INFO = "[CREATE_MERCHANT] SET AUTH INFO",
  SET_DISCOUNT_INFO = "[CREATE_MERCHANT] SET_DISCOUNT_INFO",
  SET_NOTIFICATION = "[CREATE_MERCHANT] SET_NOTIFICATION",
  SET_CREATE_MERCHANTS_LIST = "[CREATE_MERCHANT] SET_CREATE_MERCHANTS_LIST",
  HIDE_LOADING = "[CREATE_MERCHANT] HIDE_LOADING",
  SHOW_LOADING = "[CREATE_MERCHANT] SHOW_LOADING",
  SET_AFFILIATION_LEADS = "[CREATE_MERCHANT] SET_AFFILIATION_LEADS",
  SET_IS_EDIT = "[CREATE_MERCHANT] SET_IS_EDIT",
  SET_MERCHANT_AFFILIATION = "[CREATE_MERCHANT] SET_MERCHANT_AFFILIATION",
  SET_RATE_MERCHANT = "[CREATE_MERCHANT] SET_RATE_MERCHANT",
  SET_MERCHANT = "[CREATE_MERCHANT] GET_MERCHANT",
  SET_DEFERRED = "[CREATE_MERCHANT] SET_DEFERRED",
  SET_STEPS_STATUS = "[CREATE_MERCHANT] SET_STATUS_SECTION",
  SET_SEMAPHORE_DATA = "[CREATE_MERCHANT] SET_SEMAPHORE_DATE",
  SET_LOADING_FAILOVER = "[CREATE_MERCHANT] SET_LOADING_FAILOVER",
  SET_MERCHANT_SERVICES = "[CREATE_MERCHANT] SET_MERCHANT_SERVICES",
  SET_MERCHANT_USERS = "[CREATE_MERCHANT] SET_MERCHANT_USERS",
  SET_PROCESSORS = "[CREATE_MERCHANT] SET_PROCESSORS",
  SET_DEFAULT_PROCESSOR = "[CREATE_MERCHANT] SET_DEFAULT_PROCESSOR",
  SET_DEFAULT_PAYOUTS_TRANSFER_PROCESSOR = "[CONFIGURATION] SET_DEFAULT_PAYOUTS_TRANSFER_PROCESSOR",
  SET_LOADING_DEFAULT_PROCESSORS = "[CREATE_MERCHANT] SET_LOADING_DEFAULT_PROCESSORS",
  SET_RATES = "[CREATE_MERCHANT] SET_RATES",
  SET_MERCHANT_CREDENTIALS = "[CREATE_MERCHANT] SET_MERCHANT_CREDENTIALS",
  SET_MERCHANT_WEBHOOKS = "[CREATE_MERCHANT] SET_MERCHANT_WEBHOOKS",
  SET_ACCOUNT_PREFERENCES = "[CREATE_MERCHANT] SET_ACCOUNT_PREFERENCES",
  SET_PROCESSORS_RULES = "[CREATE_MERCHANT] SET_PROCESSORS_RULES",
  SET_DEFERREDSIWCHT = "[CREATE_MERCHANT] SET_DEFERREDSIWCHT",
  SET_LOADER_DEFERRED = "[CREATE_MERCHANT] SET_LOADER_DEFERRED",
  SET_RETRIES = "[CREATE_MERCHANT] SET_RETRIES",
  SET_STATUS_PROCESSOR_RATES = "[CREATE_MERCHANT] SET_STATUS_PROCESSOR_RATES",
  SET_APPCONFIG_PAYMENT_METHODS = "[CREATE_MERCHANT] SET_APPCONFIG_PAYMENT_METHODS",
}
