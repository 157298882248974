import React from "react";
import { Alert, AlertColor, Snackbar } from "@mui/material";

export interface SnackBarAlertProps {
  msg: string;
  type: AlertColor;
  show: boolean;
  handleChangeShowSnackbar: () => void;
}

export const SnackbarAlert: React.FC<SnackBarAlertProps> = (
  props: SnackBarAlertProps
) => {
  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        open={props.show}
        onClose={props.handleChangeShowSnackbar}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={props.handleChangeShowSnackbar}
          severity={props.type}
        >
          {props.msg}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};
