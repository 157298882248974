import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { RULES_INPUTS } from "../../shared/constants/rules_inputs";
import { IInputResendCertificate } from "./InputResendCertificate.interfaces";
import { inputResendCertificateStyles } from "./InputResendCertificate.styles";
import { useInputResendCertificateState } from "./state/useInputResendCertificateState";

export interface IFormInputResendCertificate {
  email: string;
}

const InputResendCertificate: FC<IInputResendCertificate> = (
  props: IInputResendCertificate
) => {
  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm<IFormInputResendCertificate>();

  const { status, transaction, transactionId } = props;

  const { disabledButton, disabledInput, onSubmit, showInputs } =
    useInputResendCertificateState(status, transaction, transactionId);

  return (
    <React.Fragment>
      {showInputs && (
        <Grid item xs={12} pb={2}>
          <Box component="main">
            <Divider sx={inputResendCertificateStyles.divider} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container sx={inputResendCertificateStyles.main}>
                <Grid item md>
                  <Controller
                    name="email"
                    control={control}
                    rules={RULES_INPUTS.email}
                    defaultValue=""
                    render={({ field }) => (
                      <FormControl
                        disabled={disabledInput}
                        error={!!errors.email}
                        fullWidth
                      >
                        <OutlinedInput
                          {...field}
                          placeholder="Ingresa el correo nuevo o existente"
                          sx={inputResendCertificateStyles.input}
                          inputProps={{ "data-testid": "email" }}
                        />
                        {!!errors.email && (
                          <FormHelperText>
                            {errors.email.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item md="auto">
                  <Button
                    data-testid="button"
                    type="submit"
                    sx={inputResendCertificateStyles.button}
                    disabled={disabledButton}
                  >
                    Reenviar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default InputResendCertificate;
