import { IStyles } from "../../shared/interfaces/create_named_styles";

export const footerStyles: IStyles = {
  footer: {
    textAlign: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: {
      xs: "15px 25px 15px 25px",
      sm: "30px 50px 30px 50px",
    },
    backgroundColor: "#FAFCFD",
  },
  footerContainer: {
    position: "absolute",
    bottom: "0",
  },
  labelBox: {
    display: "inline-block",
    padding: { xs: "10px 20px 0px 0px", lg: "14px 10px 0px 0px" },
  },
  label: {
    color: "#64798D",
    fontWeight: 400,
    fontSize: { xs: "15px", md: "13px" },
    fontFamily: "IBM Plex Sans",
  },
  returnButton: {
    marginRight: "50px",
    color: "#4498EE !important",
    borderColor: "#4498EE !important",
  },
  buttonStyle: {
    border: "1px solid #4498EE",
    color: "#4498EE",
    "&:hover": {
      color: "#023365",
    },
  },
};

export const buttonStyles = (isHidden: boolean) => ({
  returnButton: {
    marginRight: "50px",
    color: "#4498EE !important",
    borderColor: "#4498EE !important",
    display: isHidden ? "none" : "box",
  },
  continueButton: {
    display: isHidden ? "none" : "box",
  },
  columnSize: {
    columns: isHidden ? 4 : 12,
  },
});
