import { makeStyles } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/core";
import theme from "../../theme";

export const downloadButtonComponentStyle = makeStyles(() =>
  createStyles({
    buttonAlert: {
      marginLeft: "37px !important",
      marginBottom: "2px !important",
      margin: "0px",
      padding: "0px",
      minWidth: "0px",
    },
    buttonStyled: {
      borderRadius: "0px !important",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRight: "1px solid red",
      backgroundColor: "#fff",
      "&:hover": {
        border: "1px solid rgba(0, 0, 0, 0.23)",
      },
      width: theme.spacing(15.625),
    },
    buttonIconStyled: {
      borderRadius: "0px !important",
      padding: "0px",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderLeft: "1px solid rgb(2,51,101)",
      backgroundColor: "#fff",
      "&:hover": {
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderLeft: "1px solid rgb(2,51,101)",
      },
      minWidth: "28px",
      width: "26px",
    },
    errorAlert: {
      "& svg": {
        fill: "white",
        maxWidth: "100%",
        maxHeight: "100%",
      },
      backgroundColor: "#EF4444 !important",
      color: "white !important",
      left: "calc(50vw - 200px)",
      position: "absolute",
      top: "84px",
      height: "48px",
      fontSize: "16px",
    },
    menuItemStyled: {
      color: theme.palette.text.primary,
      fontSize: theme.spacing(1.75),
      fontWeight: 400,
      width: "100%",
    },
    popover: {
      width: theme.spacing(18.875),
    },
    successAlert: {
      "& svg": {
        fill: "white",
        maxWidth: "100%",
        maxHeight: "100%",
      },
      backgroundColor: "#0DC298 !important",
      color: "white !important",
      left: "calc(50vw - 200px)",
      position: "absolute",
      top: "84px",
      height: "48px",
      fontSize: "16px",
    },
  })
);
