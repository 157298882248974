import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/utils/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { MerchantProcessors } from "../../../../types/merchant_processors";
import { GetPayoutsRulesRequest } from "../../../../types/get_payouts_rules_request";
import {
  GetPayoutsRulesResponse,
  Rule,
} from "../../../../types/get_payouts_rules_response";
import { CreateRuleResponse } from "../../../../types/create_rule_response";
import { ConfigurationResponse } from "../../../../types/configuration_response";
import { AxiosResponse } from "axios";
import { DeleteRuleRequest } from "../../../../types/delete_rule_request";
import { defaultTo } from "lodash";
import { IAtomicId } from "../../../shared/interfaces/IAtomicId";
import { getBusinessRulesStorage } from "../../../shared/utils/localStorage";
import { RULE_INITIAL_STATE } from "../../../shared/utils/initialStates";

const updateStatusRule = async (request: DeleteRuleRequest) => {
  const url: string = API_ROUTES.UPDATE_RULE.replace(
    "$id",
    defaultTo(request.id, "")
  );

  delete request.id;
  const response = await axios.post(url, {
    ...request,
  });

  return response.data;
};

export const getProcessors: AsyncThunk<
  MerchantProcessors[],
  { merchantId: string },
  {}
> = createAsyncThunk<MerchantProcessors[], { merchantId: string }>(
  "app/getProcessors",
  async ({ merchantId }) => {
    const response = await axios.get<MerchantProcessors[]>(
      API_ROUTES.GET_PROCESSORS.replace("$merchantId", merchantId)
    );

    return response.data;
  }
);

export const getPayoutsRules: AsyncThunk<
  GetPayoutsRulesResponse,
  GetPayoutsRulesRequest,
  {}
> = createAsyncThunk<GetPayoutsRulesResponse, GetPayoutsRulesRequest>(
  "app/getPayoutsRules",
  async (payload: GetPayoutsRulesRequest) => {
    const response = await axios.post<GetPayoutsRulesResponse>(
      API_ROUTES.GET_PAYOUTS_RULES,
      payload
    );

    return response.data;
  }
);

export const getAtomicId: AsyncThunk<IAtomicId, void, {}> =
  createAsyncThunk<IAtomicId>("app/getAtomicId", async () => {
    const response = await axios.get<IAtomicId>(API_ROUTES.GET_ATOMIC_ID);

    return response.data;
  });

export const getRuleById: AsyncThunk<
  Rule,
  { id: string; isMassive?: boolean },
  {}
> = createAsyncThunk<Rule, { id: string; isMassive?: boolean }>(
  "app/getRuleById",
  async ({ id, isMassive }) => {
    if (isMassive) {
      const businessRules: GetPayoutsRulesResponse = getBusinessRulesStorage();

      return defaultTo(
        businessRules.data.find((rule: Rule) => rule.id === id),
        RULE_INITIAL_STATE
      );
    }

    const response = await axios.get<Rule>(
      API_ROUTES.GET_RULE_BY_ID.replace("$id", id)
    );

    return response.data;
  }
);

export const createRule: AsyncThunk<CreateRuleResponse, Rule, {}> =
  createAsyncThunk<CreateRuleResponse, Rule>(
    "app/createRule",
    async (payload: Rule) => {
      const response = await axios.post<CreateRuleResponse>(
        API_ROUTES.CREATE_RULE,
        payload
      );

      return response.data;
    }
  );

export const deleteRuleById: AsyncThunk<{}, DeleteRuleRequest, {}> =
  createAsyncThunk<{}, DeleteRuleRequest>(
    "app/deleteRuleById",
    async (request: DeleteRuleRequest) => {
      return await updateStatusRule(request);
    }
  );

export const disableRuleById: AsyncThunk<{}, DeleteRuleRequest, {}> =
  createAsyncThunk<{}, DeleteRuleRequest>(
    "app/disableRuleById",
    async (request: DeleteRuleRequest) => {
      return await updateStatusRule(request);
    }
  );

export const getConfiguration: AsyncThunk<ConfigurationResponse, void, {}> =
  createAsyncThunk<ConfigurationResponse>(
    "app/getConfiguration",
    async (): Promise<ConfigurationResponse> => {
      const response: AxiosResponse<ConfigurationResponse> =
        await axios.get<ConfigurationResponse>(API_ROUTES.GET_CONFIGURATION);

      return response.data;
    }
  );

export const updateRule: AsyncThunk<CreateRuleResponse, any, {}> =
  createAsyncThunk<CreateRuleResponse, any>(
    "app/updateRule",
    async (payload: Rule) => {
      const response = await axios.post<CreateRuleResponse>(
        API_ROUTES.UPDATE_RULE.replace("$id", payload.id),
        payload
      );

      return response.data;
    }
  );
