import { IStatusFlow } from "../interfaces/IStatusFlow";
import { StatusFlowEnum } from "../StatusFlowEnum";

export const STATUSFLOW: IStatusFlow = {
  transactions: {
    value: StatusFlowEnum.TRANSACTIONS,
    name: "Conciliación bancaria",
  },
  summary: {
    value: StatusFlowEnum.SUMMARY,
    name: "Control de depósitos",
  },
};

export enum OrderSortEnum {
  ASC = "asc",
  DESC = "desc",
}
export enum SourceTransactionsEnum {
  PENDING = "pending",
  HISTORIC = "historic",
}

export enum OriginTransactionsEnum {
  TRANSACTIONS = "transactions",
  SUMMARY = "summary",
}

export enum RequestDownloadEnum {
  CONCILIATION = "conciliation",
  DEPOSIT = "deposit",
}

export enum TabsEnum {
  PENDING = 0,
  HISTORIC = 1,
}
