import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { get } from "lodash";
import { StatusType } from "../TabItem.interfaces";
import {
  TabItemStatusEnum,
  TabItemTitleEnum,
} from "../../../../shared/enum/tabItemStatusEnum";

export const useTabItem = (
  status: StatusType,
  isDisabled: boolean,
  redirectPath: string
) => {
  const [selected, setSelected] = useState<boolean>(false);
  const location = useLocation();
  const history = useHistory();
  const statusText = () => {
    if (isDisabled) {
      return TabItemTitleEnum.NO_DISPONIBLE;
    } else if (
      [
        TabItemStatusEnum.PENDING,
        TabItemStatusEnum.IN_PROCESS,
        TabItemStatusEnum.OMITTED,
      ].includes(status) &&
      selected
    ) {
      return TabItemTitleEnum.EN_PROCESO;
    } else if (status === TabItemStatusEnum.COMPLETE) {
      return TabItemTitleEnum.COMPLETADO;
    } else {
      return TabItemTitleEnum.PENDIENTE;
    }
  };
  const statusColor =
    status === TabItemStatusEnum.COMPLETE ? "text.secondary" : "text.grey";

  useEffect(() => {
    const split_path: string[] = redirectPath.split("?");

    if (get(location, "pathname", "") === split_path[0]) {
      setSelected(true);
    }
  }, [selected]);

  const redirectTo = () => {
    history.push(redirectPath);
  };

  return { redirectTo, selected, statusColor, statusText };
};
