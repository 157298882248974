import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const headerStyles = createNamedStyles({
  boxChip: {
    bgcolor: "primary.light3",
    py: "3px",
    px: "21px",
    borderRadius: "4px",
    color: "primary.main",
    fontSize: 9,
    fontWeight: 500,
  },
  boxSelect: {
    alignItems: "center",
    bgcolor: "white",
    borderRadius: 10,
    boxShadow:
      "0px 8px 17px rgba(106, 167, 197, 0.15), 0px 1.6px 2.7625px rgba(106, 167, 197, 0.075)",
    py: 1.25,
    px: 3.75,
    display: "flex",
    columnGap: 1.125,
  },
  firstTitleHeader: {
    margin: 0,
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: " 150%",
    letterSpacing: "0.0025em",
    fontFamily: "IBM Plex Sans",
    wordWrap: "break-word",
    color: "#023365",
  },
  secondTitleHeader: {
    margin: 0,
    fontSize: "16px !important",
    fontWeight: "700 !important",
    lineHeight: " 150% !important",
    letterSpacing: "0.0025em",
    wordWrap: "break-word",
    color: "#023365",
  },
  boxTitle: {
    alignItems: "center",
    columnGap: 4,
    display: "flex",
  },
  boxSecondTitle: {
    alignItems: "center",
    columnGap: "8px",
    display: "flex",
  },
  subTitle: {
    fontWeight: "bold",
    marginLeft: "-24px",
  },
  boxUser: {
    display: "flex",
    alignItems: "center",
    columnGap: 3,
  },
  headerBox: {
    zIndex: "10",
    background: "linear-gradient(90deg, #E8F1F6 0%, #FBFDFE 3.22%)",
    height: "93px",
    position: "fixed",
    px: 5,
    pt: 3,
    pb: 3.25,
    top: 0,
    width: "100%",
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  boxTitleTwo: {
    alignItems: "center",
    columnGap: 0.5,
    display: "flex",
  },
  iconButton: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #FFFFFF",
    color: "#023365 !important",
    boxShadow:
      "rgb(106 167 197 / 15%) 0px 8px 17px, rgb(106 167 197 / 8%) 0px 1.6px 2.7625px",
    filter:
      "drop-shadow(rgba(106, 167, 197, 0.15) 0px 8px 17px) drop-shadow(rgba(106, 167, 197, 0.075) 0px 1.6px 2.7625px)",
    "&:hover": {
      color: "#4498EE !important",
      backgroundColor: "#fff !important",
      boxShadow:
        "rgb(106 167 197 / 7%) 0px 51px 80px, rgb(106 167 197 / 6%) 0px 33.0556px 46.8519px, rgb(106 167 197 / 4%) 0px 19.6444px 25.4815px, rgb(106 167 197 / 4%) 0px 10.2px 13px, rgb(106 167 197 / 3%) 0px 4.15556px 6.51852px, rgb(106 167 197 / 2%) 0px 0.944444px 3.14815px",
    },
  },
  titleChip: {
    margin: 0,
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "120%",
    fontStyle: "normal",
    letterSpacing: "0.0015em",
    fontFamily: "IBM Plex Sans",
    wordWrap: "break-word",
    color: "#023365",
  },
});
