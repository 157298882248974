import React, { FC } from "react";
import { Box, Grid } from "@mui/material";
import { ChargebackDetail } from "../../components/ChargebackDetail/ChargebackDetail";
import { useChargebackManageFiles } from "./state/useChargebackManageFiles";
import { FormProvider } from "react-hook-form";
import { chargebackContainerStyles as styles } from "../ChargebackContainer/ChargebackContainerStyles";
import { ActionButtons } from "../../components/ActionButtons/ActionButtons";
import {
  ChargebackResultEnum,
  RetrieveChildResultEnum,
} from "../../shared/enums/ConfigEnum";
import { Header } from "../../components/Header/Header";
import { LabelsEnum } from "../../shared/enums/LabelsEnum";

export interface INotification {
  open: boolean;
  message: string;
  action: ChargebackResultEnum | RetrieveChildResultEnum;
  metadata?: object;
}

export const ChargebackManageFiles: FC = () => {
  const {
    actions,
    form,
    isLoading,
    isWaitingForRequest,
    transactionDetails,
    disableButtons,
  } = useChargebackManageFiles();

  return (
    <>
      <FormProvider {...form!}>
        <Box sx={styles.main}>
          <Header label={LabelsEnum.CHARGEBACKS} component={<></>} />
          <Grid container>
            <ChargebackDetail
              isLoading={isLoading}
              transactionDetails={transactionDetails}
              handleRemoveFile={actions.handleRemoveFile}
              uploadFilesHandler={actions.handleFilesUploaded}
            />
          </Grid>
        </Box>
        <ActionButtons
          onCancel={actions.handleCancelAction}
          onSave={form!.handleSubmit(actions.handleSubmitForm)}
          disabled={disableButtons}
          sizeButtons={"large"}
          saveButtonText={"Enviar archivos"}
          backButtonText={"Regresar"}
          isLoading={isWaitingForRequest}
        />
      </FormProvider>
    </>
  );
};
