import {
  IBreadCrumbItemProps,
  IDropdownItemProps,
} from "../interfaces/Breadcrumb.interfaces";
import { CountryEnum } from "../enums/CountryEnum";
import { BANKS_ECUADOR } from "./CatalogEC";
import { BANKS_MEXICO } from "./CatalogMX";
import { BANKS_PERU } from "./CatalogPE";
import { BANKS_CHILE } from "./CatalogCL";
import { BANKS_COLOMBIA } from "./CatalogCO";
import { BANKS_BRAZIL } from "./CatalogBR";
import { union, unionBy } from "lodash";
import { ComponentIdEnum } from "../enums/ComponentIdEnum";
import { ComponentTextTitleEnum } from "../enums/ComponentTextTitleEnum";
import { BANKS_NICARAGUA } from "./CatalogNI";
import { BANKS_GUATEMALA } from "./CatalogGT";
import { BANKS_HONDURAS } from "./CatalogHN";
import { BANKS_COSTA_RICA } from "./CatalogCR";
import { BANKS_PANAMA } from "./CatalogPA";
import { BANKS_SALVADOR } from "./CatalogSV";
import { EndpointsPathsEnum } from "../enums/PathsEnum";
import { LabelEnum } from "../enums/LabelEnum";
import { Header } from "../interfaces/IModalContainer";

export const breadcrumbTitleValues: IBreadCrumbItemProps[] = [
  {
    text: "Prevención de Fraude",
    url: "/monitoring",
    id: ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_FRAUD_PREVENTION,
    isLast: false,
    isDisabled: false,
  },
  {
    text: "Reglas de seguridad",
    url: "/rule-request-manager/rules",
    id: ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_SECURITY_RULES,
    isLast: true,
    isDisabled: false,
  },
];

export const breadcrumbRuleCreationValues: IBreadCrumbItemProps[] = [
  {
    text: ComponentTextTitleEnum.FRAUD_PREVENTION_TILE,
    url: "/monitoring",
    id: ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_FRAUD_PREVENTION,
    isLast: false,
    isDisabled: false,
  },
  {
    text: ComponentTextTitleEnum.SECURITY_RULES_TITLE,
    url: EndpointsPathsEnum.RULES_COMPONENTES,
    id: ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_SECURITY_RULES,
    isLast: false,
    isDisabled: false,
  },
  {
    text: ComponentTextTitleEnum.SECURITY_RULES_TITLE,
    url: "",
    id: ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_LEVEL_ONE,
    isLast: true,
    isDisabled: false,
  },
];

export const breadcrumbRuleUpdateValues: IBreadCrumbItemProps[] = [
  {
    text: ComponentTextTitleEnum.FRAUD_PREVENTION_TILE,
    url: "/monitoring",
    id: ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_FRAUD_PREVENTION,
    isLast: false,
    isDisabled: false,
  },
  {
    text: ComponentTextTitleEnum.SECURITY_RULES_TITLE,
    url: EndpointsPathsEnum.RULES_COMPONENTES,
    id: ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_SECURITY_RULES,
    isLast: false,
    isDisabled: false,
  },
  {
    text: ComponentTextTitleEnum.UPDATE_RULE,
    url: "",
    id: ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_UPDATED,
    isLast: true,
    isDisabled: false,
  },
];

export const breadcrumbRuleCreationDetailValues: IBreadCrumbItemProps[] = [
  {
    text: "Prevención de Fraude",
    url: "/monitoring",
    id: ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_FRAUD_PREVENTION,
    isLast: false,
    isDisabled: false,
  },
  {
    text: "Reglas de seguridad",
    url: EndpointsPathsEnum.RULES_COMPONENTES,
    id: ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_SECURITY_RULES,
    isLast: false,
    isDisabled: false,
  },
  {
    text: "Crear regla de seguridad",
    url: "/rule-request-manager/rules/create",
    id: ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_CREATED,
    isLast: false,
    isDisabled: false,
  },
  {
    text: "Detalle",
    url: "",
    id: ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_DETAIL,
    isLast: true,
    isDisabled: false,
  },
];

export const breadcrumbGeneralRuleCreation: IBreadCrumbItemProps[] = [
  {
    text: "Prevención de Fraude",
    url: "/monitoring",
    id: ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_FRAUD_PREVENTION,
    isLast: false,
    isDisabled: false,
  },
  {
    text: "Reglas de seguridad",
    url: EndpointsPathsEnum.RULES_COMPONENTES,
    id: ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_SECURITY_RULES,
    isLast: false,
    isDisabled: false,
  },
  {
    text: "Crear reglas de seguridad",
    url: "",
    id: ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_CREATED,
    isLast: true,
    isDisabled: false,
  },
];

export const breadcrumbWhitelistCreationValues: IBreadCrumbItemProps[] = [
  {
    text: ComponentTextTitleEnum.FRAUD_PREVENTION_TILE,
    url: "/monitoring",
    id: ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_FRAUD_PREVENTION,
    isLast: false,
    isDisabled: false,
  },
  {
    text: ComponentTextTitleEnum.SECURITY_RULES_TITLE,
    url: EndpointsPathsEnum.RULES_COMPONENTES,
    id: ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_SECURITY_RULES,
    isLast: false,
    isDisabled: false,
  },
  {
    text: ComponentTextTitleEnum.CREATE_WHITELIST,
    url: "",
    id: ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_LEVEL_ONE,
    isLast: true,
    isDisabled: false,
  },
];

export const breadcrumbWhitelistUpdateValues: IBreadCrumbItemProps[] = [
  {
    text: ComponentTextTitleEnum.FRAUD_PREVENTION_TILE,
    url: "/monitoring",
    id: ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_FRAUD_PREVENTION,
    isLast: false,
    isDisabled: false,
  },
  {
    text: ComponentTextTitleEnum.SECURITY_RULES_TITLE,
    url: EndpointsPathsEnum.RULES_COMPONENTES,
    id: ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_SECURITY_RULES,
    isLast: false,
    isDisabled: false,
  },
  {
    text: ComponentTextTitleEnum.UPDATE_WHITELIST,
    url: "",
    id: ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_UPDATED,
    isLast: true,
    isDisabled: false,
  },
];

export const DEFAULT_BUTTON_TITTLE: Record<string, string> = {
  [LabelEnum.IP]: "Añadir IP",
  [LabelEnum.SHIPPING_ADDRESS]: "Añadir dirección",
  [LabelEnum.BILLING_ADDRESS]: "Añadir dirección",
  [LabelEnum.DOCUMENT_NUMBER]: "Añadir número",
  [LabelEnum.CARD_HOLDER]: "Añadir nombre",
  [LabelEnum.METADATA]: "Añadir metadata",
  [LabelEnum.CARD_ID]: "Añadir Kushki ID",
  [LabelEnum.BANK]: "Añadir banco",
  [LabelEnum.BRAND]: "Añadir marca",
  [LabelEnum.COUNTRY]: "Añadir país",
  [LabelEnum.PROCESSOR_CODE]: "Añadir código",
};

export const ALLOWED_FIELD_RESET_FORM: string[] = [
  "transactionBank",
  "transactionBrand",
  "transactionIsDeferred",
  "transactionProcessor",
  "transactionCountry",
  "transactionTotalAmount",
  "transactionIP",
  "transactionBIN",
  "transactionIsCreditCard",
  "transactionMaskedCreditCard",
  "transactionCardId",
  "transactionCardIssuer",
  "transactionCurrency",
  "transactionType",
  "transactionCredential",
  "transactionShippingAddress",
  "transactionBillingAddress",
  "transactionEmail",
  "transactionDocumentNumber",
  "transactionPhoneNumber",
  "transactionSchedule",
  "transactionMCC",
  "transactionChannel",
  "transactionMerchantCategory",
  "transactionCardHolderName",
  "transactionAfiliationNumber",
  "transactionMetadata",
  "transactionCommerceSchedule",
  "transactionDate",
  "transactionProcessorCode",
  "transactionSecureService",
];

export const ALLOWED_GENERAL_CONDITIONS_MODAL: string[] = [
  "transactionBank",
  "transactionBrand",
  "transactionIsDeferred",
  "transactionProcessor",
  "transactionCountry",
  "transactionTotalAmount",
  "transactionIP",
  "transactionBIN",
  "transactionIsCreditCard",
  "transactionMaskedCreditCard",
  "transactionCardId",
  "transactionCardIssuer",
  "transactionCurrency",
  "transactionType",
  "transactionCredential",
  "transactionShippingAddress",
  "transactionBillingAddress",
  "transactionEmail",
  "transactionDocumentNumber",
  "transactionPhoneNumber",
  "transactionSchedule",
  "transactionMCC",
  "transactionChannel",
  "transactionMerchantCategory",
  "transactionCardHolderName",
  "transactionAfiliationNumber",
  "transactionMetadata",
  "transactionCommerceSchedule",
  "transactionDate",
  "transactionSecureService",
];

export const ALLOWED_SPECIAL_CONDITIONS_MODAL: string[] = [
  "transactionProcessorCode",
];

export const RULE_SMARTLINKS_ACTIVE: string[] = [
  "transactionCountry",
  "transactionPhoneNumber",
  "transactionEmail",
  "transactionShippingAddress",
  "transactionBillingAddress",
  "transactionDocumentNumber",
];

export const WHITELIST_SMARTLINKS_ACTIVE: string[] = [
  "transactionPhoneNumber",
  "transactionEmail",
  "transactionDocumentNumber",
];

export enum OPERATORS {
  ALL = "all",
  EQUAL_PIPE_ALL = "=|all",
  EQUAL = "=",
  CONTAIN = "contain",
  NOT_CONTAIN = "!contain",
  MULTIPLE_MATCH = "multiplematch",
}

export interface IDropdownSplitButtonProps {
  idSecurityRulesItem: string;
  idGeneralSecurityRule: string;
  idWhiteList: string;
  onSelectedSecurityRulesItem: (data: any) => void;
  onSelectedGeneralSecurityRule: (data: any) => void;
  onSelectedWhiteList: (data: any) => void;
}

export const dropdownSplitButtonValues = (
  props: IDropdownSplitButtonProps
): IDropdownItemProps[] => {
  return [
    {
      variant: "text",
      text: "Reglas de seguridad",
      id: props.idSecurityRulesItem,
      onSelect: props.onSelectedSecurityRulesItem,
    },
    {
      variant: "text",
      text: "Lista blanca",
      id: props.idWhiteList,
      onSelect: props.onSelectedWhiteList,
    },
  ];
};

export const dropdownSplitButtonAdminValues = (
  props: IDropdownSplitButtonProps
): IDropdownItemProps[] => {
  return [
    ...dropdownSplitButtonValues(props),
    {
      variant: "text",
      text: "Crear regla general",
      id: props.idGeneralSecurityRule,
      onSelect: props.onSelectedGeneralSecurityRule,
    },
  ];
};

export enum TitleSection {
  RULES_GENERAL_CONDITIONS = "Condiciones Generales",
  RULES_CONFIGURATION = "Configuración de la regla",
  RULES_GENERAL_ACTION = "Acción General",
  RULES_UPDATE_RULE = "Editar regla de seguridad",
  RULES_CREATE = "Reglas de seguridad",
  RULES_DATA_GENERAL = "Datos Generales",
  RULES_ASSIGN = "Asignar regla",
  ADD = "Agregar",
  RULE_ENABLE = "Habilitar regla",
  ENABLE = "Habilitar",
  RULE_DISABLE = "Deshabilitar",
  ADD_SECTION = "Añadir condición",
  SAVE_SECTION = "Guardar condición",
  REMOVE_SECTION = "Quitar condición",
  EDIT_SECTION = "Editar condición",
  CONDITION_ALREADY_SELECTED = "Condición ya fue seleccionada",
  EDIT_LIST = "Editar lista",
  CREATE_WHITELIST = "Agregar Lista Blanca",
  UPDATE_WHITELIST = "Editar Lista Blanca",
  WHITELIST = "Lista Blanca",
  GENERAL_CONDITION = "Condición general",
  NEW_RULE = "Nueva Regla",
  CONFIGURATION_SECTION = "Configuración",
  PROCESSOR_CODE = "Processor Code",
  SECURE_SERVICE = "Secure Service",
}

export enum SubTitleSection {
  RULES_GENERAL_CONDITIONS_SUBTITLE = "Selecciona las condiciones que deben cumplirse para llevar a cabo la acción configurada en la sección anterior. SPLIT" +
    "Agrega tantas condiciones como sean necesarias",
  RULES_CONFIGURATION_SUBTITLE = "Genera un nombre que permita identificar la regla",
  RULES_GENERAL_ACTION_SUBTITLE = "Selecciona la acción que cumplirá la regla de seguridad",
  RULES_CREATE_SUBTITLE = "Selecciona el tipo de regla",
  WHITELIST_CREATE_SUBTITLE = "Selecciona el tipo de lista",
  RULES_ASSIGN_SUBTITLE = "Selecciona la regla general de nuestro catálogo o cree una nueva",
  RULES_CREATE_COUNTRY_SUBTITLE = "Selecciona el país o los paises a los que deseas aplicar la regla:",
  RULES_CREATE_COUNTRY_WHITELIST_SUBTITLE = "Selecciona el país o los paises a los que deseas aplicar la lista blanca:",
  RULES_CREATE_MERCHANT_SUBTITLE = "Selecciona el Customer/Branch al que aplicará la regla",
  RULES_CREATE_MERCHANT_WHITELIST_SUBTITLE = "Selecciona el Customer/Branch al que aplicará la lista blanca",
  RULES_CREATE_COMMERCE_SUBTITLE = "Selecciona el tipo de comercio",
  RULES_CREATE_MERCHANT_WITHOUT_HIERARCHY_SUBTITLE = "Selecciona el Comercio al que aplicará la regla",
  RULES_CREATE_MERCHANT_WHITELIST_WITHOUT_HIERARCHY_SUBTITLE = "Selecciona el Comercio al que aplicará la lista blanca",
  RULES_SEARCH_MERCHANT_PLACEHOLDER = "Buscar un comercio",
  RULES_CREATE_PAY_SUBTITLE = "¿Que medio de pago afectará la regla?",
  RULES_SECONDARY_SPEED = "Agrega acciones secundarias a tu Regla",
  RULES_TRX_STATUS_SPEED = "Selecciona el estado de la transacción",
  RULES_MASKED_CREDIT_CARD = "Ingrese los dígitos de su tarjeta:",
  RULES_TRX_SPEED = "Ingresa el número de transacciones a",
  RULES_PERIODICITY_SPEED = "Selecciona la periodicidad de la condición",
  RULES_SPEED_TYPE = "Selecciona la acción a realizar",
  RULES_GENERAL_CONDITIONS_LOCAL_TIME_SUBTITLE = "Selecciona el horario local:",
  RULES_GENERAL_CONDITIONS_DATE_SUBTITLE = "Ingresa la fecha para registrar la condición:",
  RULES_SPEED_CONDITION = "Condiciones de velocidad",
  RULES_COUNTRY_CONDITION_SUBTITLE = "Selecciona uno o más países",
  RULES_TRANSACTION_TYPE_SUBTITLE = "Ingresa un tipo de transacción:",
  RULES_MCC_SUBTITLE = "Ingresa un mcc:",
  RULES_CHANNEL_SUBTITLE = "Ingresa un método de integración:",
  RULES_SECURE_SERVICE_SUBTITLE = "Ingresa un secure service:",
  MERCHANT_CATEGORY = "Ingresa una categoría del comercio:",
  RULES_MAIL_SUBTITLE = "Ingresa un correo electrónico:",
  GENERAL_CONDITION_SUBTITLE = "Selecciona la condición que debe cumplirse para llevar a cabo la acción configurada en la sección anterior.",
  SPECIAL_CONDITION_SUBTITLE = "Selecciona las condiciones especiales que deberán validarse en un histórico con información de los procesadores.",
  CONFIGURED_CONDITIONS_COMMERCE = "Condiciones configuradas para el comercio:",
  CONFIGURED_CONDITIONS_COUNTRY = "Condiciones configuradas para el país:",
  ADDITIONAL_INFO = "Configuración adicional",
  ADDITIONAL_INFO_SUBTITLE = "Activa los parámetros que deben cumplirse para este servicio de autenticación. Recuerda que puedes activar más de un parámetro.",
  ASSUME_TRANSACTIONAL_RISK = "Asumir riesgo transaccional",
  ASSUME_TRANSACTIONAL_RISK_DESCRIPTION = "Todas las transacciones no seguras se enviarán a procesar si este switch está encendido.",
  RULES_PARAMETERS = "Parámetros de la nueva regla:",
  SEARCHING_MERCHANT = "Buscando comercio...",
}

export enum Labels {
  ADD_CARD = "Añadir Tarjeta",
  NAME = "Nombre",
  ACTION = "Acción",
  PROVIDER = "Proveedor",
  PERIODICITY = "Periodicidad",
  CURRENCY = "Moneda",
  RULES_SPEED_COUNT = "Contar transacciones",
  RULES_SPEED_SUM = "Sumar montos",
  CODE = "Código",
  MESSAGE = "Mensaje",
  AMOUNT_TEXT = "Ingresa un monto",
  COUNTRY = "País",
  APPROVAL = "APPROVAL",
  DECLINED = "DECLINED",
  ALL = "ALL",
  CONTINENT = "Continente",
  BIN = "BIN",
  IP = "Ingresa un valor IP",
  ADDRESS = "Dirección",
  INPUT_ADDRESS = "Ingresar una dirección",
  DOCUMENT_NUMBER = "Número de documento",
  INPUT_NAME = "Ingresa un nombre",
  METADATA = "Metadata",
  PROCESSOR = "Listado de procesadores",
  CREDENTIAL = "Seleccionar ID de credencial",
  CURRENCY_LIST = "Listado de monedas",
  CARD_ID = "Ingresa un ID",
  INPUT_BANK = "Ingresa un banco",
  INPUT_PROCESSOR_CODE = "Ingresa un código",
  INPUT_BRAND = "Ingresa una marca",
  INPUT_COUNTRY = "Ingresa un país",
  NOT_APPLICABLE = "N/A",
}

export enum Values {
  RULES_SPEED_COUNT = "contar",
  RULES_SPEED_SUM = "sumar",
  RULES_KIND_STRICT = "strict",
  RULES_KIND_SPEED = "speed",
  RULES_KIND_SPECIAL = "special",
  IGNORE_3DS = "3dsecure",
}

export enum AriaLabels {
  DELETE = "eliminar",
  EDIT = "editar",
}

export interface IOptionImageSelection {
  name: string;
  value: string;
  image: string;
  valid?: boolean;
}

export enum PlaceHolders {
  AMOUNT = "Monto",
  AMOUNT_FIRST_VALUE = "Primer valor",
  AMOUNT_SECOND_VALUE = "Segundo valor",
  AMOUNT_SINGLE_VALUE = "Valor",
  AMOUNT_VALUE = "Ingresa un valor",
  BIN = "Selecciona uno o varios bines:",
  CONTINENT_VALUE = "Seleccione un continente",
  COUNTRY_VALUE = "Selecciona un país",
  EMAIL = "Correo Electrónico",
  END_TIME = "Selecciona la hora final",
  LABEL = "Etiqueta",
  CONDITION = "Condición",
  NUMBER = "Número",
  OPERATOR = "Operador",
  QUANTITY = "Cantidad",
  REGION_VALUE = "Selecciona una región",
  START_TIME = "Selecciona la hora de inicio",
  TRANSACTION_TYPE = "Tipo de transacción",
  MCC = "Mcc",
  MERCHANT_CATEGORY = "Categoría del comercio (tamaño):",
  SECURE_SERVICE = "Secure Service:",
  CHANNEL = "Método de integración:",
  CURRENCY = "Selecciona el tipo de moneda",
  PROCESSOR = "Selecciona un procesador:",
  START_DATE = "Fecha de inicio",
  END_DATE = "Fecha final",
}

export interface IOptionSelection {
  value: string;
  label: string;
}

export enum HelperText {
  EMPTY = "",
  AMOUNT_HELPER = "Solo se permiten números",
  SPEED_COUNT_HELPER = "Solo puedes ingresar números",
  SPEED_NUMBER_INVALID = "Monto inválido",
  CREDENTIALS = "No se encontraron credenciales",
  PROCESSORS = "No se encontraron procesadores",
  MORE_THAN_ONE_BIN = "Puedes ingresar más de un bin",
}

export enum Operators {
  BETWEEN = "between",
  BETWEEN_DATE = "betweenDate",
  BETWEEN_DATE_OUT = "betweenDateOut",
  ALL = "all",
}

export const GENERAL_CONDITIONS_HEADERS: Header[] = [
  {
    title: "Etiqueta",
  },
  {
    title: "Operador",
  },
  {
    title: "Valor",
  },
];

export const SPEED_CONDITIONS_HEADERS: Header[] = [
  {
    title: "Acción",
  },
  {
    title: "Periodicidad",
  },
  {
    title: "Valor",
  },
  {
    title: "Moneda",
  },
  {
    title: "Estado",
  },
];

export enum TextFieldsNames {
  FirstAmount = "firstAmount",
  SecondAmount = "secondAmount",
}

export const getBanksByCountry = (countries: string[]): IOptionSelection[] => {
  let banks: IOptionSelection[] = [];

  if (countries.includes(CountryEnum.ECUADOR))
    banks = [...banks, ...BANKS_ECUADOR];
  if (countries.includes(CountryEnum.PERU)) banks = [...banks, ...BANKS_PERU];
  if (countries.includes(CountryEnum.MEXICO))
    banks = [...banks, ...BANKS_MEXICO];
  if (countries.includes(CountryEnum.COLOMBIA))
    banks = [...banks, ...BANKS_COLOMBIA];
  if (countries.includes(CountryEnum.CHILE)) banks = [...banks, ...BANKS_CHILE];
  if (countries.includes(CountryEnum.BRAZIL))
    banks = [...banks, ...BANKS_BRAZIL];
  if (countries.includes(CountryEnum.NICARAGUA))
    banks = [...banks, ...BANKS_NICARAGUA];
  if (countries.includes(CountryEnum.GUATEMALA))
    banks = [...banks, ...BANKS_GUATEMALA];
  if (countries.includes(CountryEnum.HONDURAS))
    banks = [...banks, ...BANKS_HONDURAS];
  if (countries.includes(CountryEnum.PANAMA))
    banks = [...banks, ...BANKS_PANAMA];
  if (countries.includes(CountryEnum.SALVADOR))
    banks = [...banks, ...BANKS_SALVADOR];
  if (countries.includes(CountryEnum.COSTA_RICA))
    banks = [...banks, ...BANKS_COSTA_RICA];

  return banks;
};

export const getCurrencyByCountry = (
  countries: string[],
  currencyByCountry: Record<string, IOptionSelection[]> | undefined
): IOptionSelection[] => {
  let currencies: IOptionSelection[] = [];
  if (!currencyByCountry) return currencies;

  if (countries.includes(CountryEnum.ECUADOR))
    currencies = union(currencies, currencyByCountry["CURRENCY_ECUADOR"]);
  if (countries.includes(CountryEnum.PERU))
    currencies = union(currencies, currencyByCountry["CURRENCY_PERU"]);
  if (countries.includes(CountryEnum.MEXICO))
    currencies = union(currencies, currencyByCountry["CURRENCY_MEXICO"]);
  if (countries.includes(CountryEnum.COLOMBIA))
    currencies = union(currencies, currencyByCountry["CURRENCY_COLOMBIA"]);
  if (countries.includes(CountryEnum.CHILE))
    currencies = union(currencies, currencyByCountry["CURRENCY_CHILE"]);
  if (countries.includes(CountryEnum.BRAZIL))
    currencies = union(currencies, currencyByCountry["CURRENCY_BRAZIL"]);
  if (countries.includes(CountryEnum.NICARAGUA))
    currencies = union(currencies, currencyByCountry["CURRENCY_NICARAGUA"]);
  if (countries.includes(CountryEnum.GUATEMALA))
    currencies = union(currencies, currencyByCountry["CURRENCY_GUATEMALA"]);
  if (countries.includes(CountryEnum.HONDURAS))
    currencies = union(currencies, currencyByCountry["CURRENCY_HONDURAS"]);
  if (countries.includes(CountryEnum.COSTA_RICA))
    currencies = union(currencies, currencyByCountry["CURRENCY_COSTA_RICA"]);
  if (countries.includes(CountryEnum.PANAMA))
    currencies = union(currencies, currencyByCountry["CURRENCY_PANAMA"]);
  if (countries.includes(CountryEnum.SALVADOR))
    currencies = union(currencies, currencyByCountry["CURRENCY_EL_SALVADOR"]);

  currencies = unionBy(currencies, "label");

  return currencies;
};

export enum Countries {
  ECUADOR = "Ecuador",
  COLOMBIA = "Colombia",
  PERU = "Peru",
  CHILE = "Chile",
  MEXICO = "Mexico",
  BRAZIL = "Brazil",
}

export enum CurrencyEnum {
  USD = "USD",
  COP = "COP",
  CLP = "CLP",
  UF = "UF",
  PEN = "PEN",
  MXN = "MXN",
  BRL = "BRL",
}

export enum AmountFormatEnum {
  THOUSAND_SEPARATOR = ".",
  DECIMAL_SEPARATOR = ",",
}

export enum MonthsValuesEnum {
  Ene = "01",
  Feb = "02",
  Mar = "03",
  Abr = "04",
  May = "05",
  Jun = "06",
  Jul = "07",
  Ago = "08",
  Sep = "09",
  Oct = "10",
  Nov = "11",
  Dic = "12",
}

export enum MonthsNameEnum {
  Ene = "Ene",
  Feb = "Feb",
  Mar = "Mar",
  Abr = "Abr",
  May = "May",
  Jun = "Jun",
  Jul = "Jul",
  Ago = "Ago",
  Sep = "Sep",
  Oct = "Oct",
  Nov = "Nov",
  Dic = "Dic",
}

export const MONTHS_RECORD: Record<string, string> = {
  [MonthsNameEnum.Ene]: MonthsValuesEnum.Ene,
  [MonthsNameEnum.Feb]: MonthsValuesEnum.Feb,
  [MonthsNameEnum.Mar]: MonthsValuesEnum.Mar,
  [MonthsNameEnum.Abr]: MonthsValuesEnum.Abr,
  [MonthsNameEnum.May]: MonthsValuesEnum.May,
  [MonthsNameEnum.Jun]: MonthsValuesEnum.Jun,
  [MonthsNameEnum.Jul]: MonthsValuesEnum.Jul,
  [MonthsNameEnum.Ago]: MonthsValuesEnum.Ago,
  [MonthsNameEnum.Sep]: MonthsValuesEnum.Sep,
  [MonthsNameEnum.Oct]: MonthsValuesEnum.Oct,
  [MonthsNameEnum.Nov]: MonthsValuesEnum.Nov,
  [MonthsNameEnum.Dic]: MonthsValuesEnum.Dic,
};

export const BANKS_COUNTRIES_MAP: Record<string, IOptionSelection[]> = {
  [Countries.ECUADOR]: BANKS_ECUADOR,
  [Countries.COLOMBIA]: BANKS_COLOMBIA,
  [Countries.PERU]: BANKS_PERU,
  [Countries.CHILE]: BANKS_CHILE,
  [Countries.MEXICO]: BANKS_MEXICO,
  [Countries.BRAZIL]: BANKS_BRAZIL,
};

export enum GeneralActionEnum {
  WARNING = "warning",
  VALIDATE = "validate",
  AUTHENTICATE = "authenticate",
  RETURN = "Regresar",
  SEND_TO_REQUEST = "Enviar a revisión",
  SAVE_RULE = "Guardar regla",
}

export enum ActionEnum {
  THREEDS = "3dsecure",
  OTP = "otp",
  BLOCK = "block",
  WARNING = "warning",
}

export enum AssignRuleLabels {
  SELECT_RULE = "Seleccionar Regla",
  CREATE_RULE = "Crear regla",
}

export const PAYMENT_BRANDS_OPTIONS: IOptionSelection[] = [
  { label: "Visa", value: "Visa" },
  { label: "Mastercard", value: "Mastercard" },
  { label: "Amex", value: "Amex" },
  { label: "American Express", value: "american express" },
  { label: "Diners", value: "Diners" },
  { label: "Diners Club", value: "diners club" },
  { label: "Discover", value: "Discover" },
  { label: "Redcompra", value: "Redcompra" },
  { label: "Alia", value: "Alia" },
  { label: "Credencial", value: "Credencial" },
  { label: "Maestro", value: "maestro" },
  { label: "Propia", value: "propia" },
  { label: "Cmrfalabella", value: "Cmrfalabella" },
  { label: "JCB", value: "JCB" },
  { label: "Serfinanza", value: "Serfinanza" },
  { label: "Unionpay", value: "unionpay" },
];

export const VALID_GENERAL_ACTIONS: string[] = [
  GeneralActionEnum.VALIDATE,
  GeneralActionEnum.WARNING,
];

export const WHITELIST_CONDITIONS: string[] = [
  "transactionEmail",
  "transactionIP",
  "transactionMaskedCreditCard",
  "transactionDocumentNumber",
  "transactionPhoneNumber",
  "transactionCardId",
];

export const SPECIAL_CONDITIONS: string[] = ["transactionProcessorCode"];

export const NOT_RULE_CONDITIONS: string[] = [
  "transactionCommerceSchedule",
  "transactionDate",
];

export const COUNTRY_CONDITIONS: string[] = [
  "transactionProcessor",
  "transactionCredential",
  "transactionCommerceSchedule",
  "transactionDate",
];
export const COUNTRIES_CONDITIONS: string[] = [
  "transactionProcessor",
  "transactionCredential",
  "transactionCommerceSchedule",
  "transactionDate",
  "transactionTotalAmount",
];
export const HIDE_SECURITY_RULES_CONDITIONS: string[] = [
  "transactionCardIssuer",
  "transactionCommerceSchedule",
  "transactionCurrency",
  "transactionProcessor",
  "transactionSchedule",
];
export const HIDE_SECURITY_RULES_3DS_CONDITIONS: string[] = [
  "transactionBillingAddress",
  "transactionCardHolderName",
  "transactionCountry",
  "transactionDocumentNumber",
  "transactionEmail",
  "transactionMetadata",
  "transactionPhoneNumber",
  "transactionShippingAddress",
  "transactionSecureService",
];
export const HIDE_SECURITY_GENERIC_AUTHENTICATE_CONDITIONS: string[] = [
  "transactionBillingAddress",
  "transactionCardHolderName",
  "transactionCountry",
  "transactionDocumentNumber",
  "transactionEmail",
  "transactionMetadata",
  "transactionPhoneNumber",
  "transactionShippingAddress",
  "transactionSecureService",
];
export const HIDE_SECURITY_RULES_OTP_CONDITIONS: string[] = [
  "transactionBillingAddress",
  "transactionCardHolderName",
  "transactionCountry",
  "transactionDocumentNumber",
  "transactionEmail",
  "transactionMetadata",
  "transactionPhoneNumber",
  "transactionShippingAddress",
  "transactionSecureService",
];
export const HIDE_CUSTOMER_CONDITIONS: string[] = ["transactionCredential"];
export const HIDE_WHITE_LIST_CONDITIONS: string[] = [
  "transactionBillingAddress",
  "transactionBank",
  "transactionBrand",
  "transactionCardHolderName",
  "transactionCountry",
  "transactionBIN",
  "transactionIsCreditCard",
  "transactionCardIssuer",
  "transactionCurrency",
  "transactionCredential",
  "transactionShippingAddress",
  "transactionSchedule",
  "transactionProcessor",
  "transactionIsDeferred",
  "transactionCardHolderName",
  "transactionMetadata",
  "transactionType",
  "transactionTotalAmount",
  "transactionProcessorCode",
  "transactionSecureService",
  "transactionMCC",
  "transactionMerchantCategory",
  "transactionChannel",
];
export enum IDENTIFIER {
  TABLE = "table",
}

export const WHITELIST_OPERATOR: string[] = [
  "contain",
  "betweenDate",
  "=",
  "multiplematch",
];

export const CONDITIONS_CONFIG_BY_COMMERCE: Record<string, string> = {
  [LabelEnum.CARD_HOLDER]: "Cardholder name",
  [LabelEnum.MASKED_CARD]: "Tarjeta enmascarada",
  [LabelEnum.CARD_ID]: "Kushki ID",
  [LabelEnum.EMAIL]: "Email",
  [LabelEnum.DOCUMENT_NUMBER]: "Número de documento",
  [LabelEnum.PHONE_NUMBER]: "Número de teléfono",
  [LabelEnum.IP]: "IP",
  [LabelEnum.TRANSACTION_COMMERCE_SCHEDULE]: "Hora del comercio",
  [LabelEnum.TRANSACTION_DATE]: "Fecha",
};

export enum UrlPaths {
  GENERAL_RULE = "general-rules",
  WHITE_LIST = "whitelist",
}

export const TRANSACTION_STATUS: Record<string, string> = {
  [Labels.APPROVAL]: "Aprobada",
  [Labels.DECLINED]: "Declinada",
  [Labels.ALL]: "Todas",
};

export const ACTION_VALUE: Record<string, string> = {
  [GeneralActionEnum.VALIDATE]: ActionEnum.BLOCK,
  [GeneralActionEnum.WARNING]: ActionEnum.WARNING,
  [`${GeneralActionEnum.AUTHENTICATE}_${ActionEnum.THREEDS}`]:
    ActionEnum.THREEDS,
  [`${GeneralActionEnum.AUTHENTICATE}_${ActionEnum.OTP}`]: ActionEnum.OTP,
};

export const TIMEOUT_VALUE = 500;
