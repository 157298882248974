import { OptionStatus } from "../../../../types/option_status";
import { get } from "lodash";
import { MovementTypeEnum } from "../MovementTypeEnum";
import { CardBrandEnum } from "../CardBrandEnum";
import { CardTypeEnum } from "../CardTypeEnum";
import { BankConciliationStatusEnum } from "../BankConciliationStatusEnum";
import { IDFilterEnum } from "../IDFilterEnum";
import { KeyStatusEnum } from "../../KeyStatusEnum";
import { clearEmptyValues } from "../constants/BankConciliationConstans";
import { CountryEnum } from "../CountryEnum";
import { PaymentTypeEnum } from "../PaymentMethodEnum";
import { ProcessorTypeEnum } from "../ProcessorNameEnum";
import { CurrencyEnum } from "../CurrencyEnum";
import { TabsEnum } from "../constants/StatusFlowDashboardConstants";
import { SourceEnum } from "../SourceEnum";

export const buildFiltersObject = (filters: {
  [key: string]: boolean;
}): object => {
  let export_filter_selected = {};
  export_filter_selected[IDFilterEnum.movementType] = buildFilterStatus(
    {
      MovementTypePayment: KeyStatusEnum.PAYMENT_MOVEMENT_TYPE,
      MovementTypeCharge: KeyStatusEnum.CHARGE_MOVEMENT_TYPE,
    },
    filters
  );
  export_filter_selected[IDFilterEnum.cardBrand] = buildFilterStatus(
    {
      CardBrandMasterCard: KeyStatusEnum.MASTERCARD_CARD_BRAND,
      CardBrandVisa: KeyStatusEnum.VISA_CARD_BRAND,
      CardBrandAmex: KeyStatusEnum.AMEX_CARD_BRAND,
      CardBrandCarnet: KeyStatusEnum.CARNET_CARD_BRAND,
    },
    filters
  );
  export_filter_selected[IDFilterEnum.cardType] = buildFilterStatus(
    {
      CardTypeDebit: KeyStatusEnum.DEBIT_CARD_TYPE,
      CardTypeCredit: KeyStatusEnum.CREDIT_CARD_TYPE,
    },
    filters
  );
  export_filter_selected[IDFilterEnum.status] = buildFilterStatus(
    {
      bankConciliationStatusConciliation:
        KeyStatusEnum.CONCILIATION_BANK_CONCILIATION_STATUS,
      bankConciliationStatusOmit: KeyStatusEnum.OMIT_BANK_CONCILIATION_STATUS,
      bankConciliationStatusNoConciliation:
        KeyStatusEnum.NO_CONCILIATION_BANK_CONCILIATION_STATUS,
    },
    filters
  );
  export_filter_selected = clearEmptyValues(export_filter_selected);
  return export_filter_selected;
};
const buildFilterStatus = (
  optionsStatus: OptionStatus,
  filters: { [key: string]: boolean }
) => {
  const statusFilters: string = Object.keys(filters)
    .filter(
      (chip: string) =>
        filters[chip] && validateFilterStatus(chip, optionsStatus)
    )
    .map((chip: string) => {
      switch (chip) {
        case get(optionsStatus, "MovementTypePayment"):
          return MovementTypeEnum.payment;
        case get(optionsStatus, "MovementTypeCharge"):
          return MovementTypeEnum.charge;
        case get(optionsStatus, "CardBrandMasterCard"):
          return CardBrandEnum.Mastercard;
        case get(optionsStatus, "CardBrandVisa"):
          return CardBrandEnum.Visa;
        case get(optionsStatus, "CardBrandAmex"):
          return CardBrandEnum.Amex;
        case get(optionsStatus, "CardBrandCarnet"):
          return CardBrandEnum.Carnet;
        case get(optionsStatus, "CardTypeDebit"):
          return CardTypeEnum.debit;
        case get(optionsStatus, "CardTypeCredit"):
          return CardTypeEnum.credit;
        case get(optionsStatus, "bankConciliationStatusConciliation"):
          return BankConciliationStatusEnum.CONCILIATION;
        case get(optionsStatus, "bankConciliationStatusOmit"):
          return BankConciliationStatusEnum.OMIT;
        default:
        case get(optionsStatus, "bankConciliationStatusNoConciliation"):
          return BankConciliationStatusEnum.NO_CONCILIATION;
      }
    })
    .join("|");
  return statusFilters;
};
const validateFilterStatus = (
  chip: string,
  optionsStatus: OptionStatus
): boolean => {
  return [
    get(optionsStatus, "MovementTypePayment"),
    get(optionsStatus, "MovementTypeCharge"),
    get(optionsStatus, "CardBrandMasterCard"),
    get(optionsStatus, "CardBrandVisa"),
    get(optionsStatus, "CardBrandAmex"),
    get(optionsStatus, "CardBrandCarnet"),
    get(optionsStatus, "CardTypeDebit"),
    get(optionsStatus, "CardTypeCredit"),
    get(optionsStatus, "bankConciliationStatusConciliation"),
    get(optionsStatus, "bankConciliationStatusOmit"),
    get(optionsStatus, "bankConciliationStatusNoConciliation"),
  ].includes(chip);
};

export const getDataRequestBody = (
  actualTab: number,
  value: {
    limit?: number;
    offset?: number;
    from: string;
    to: string;
    filter?: object;
  }
): object => {
  return {
    country: CountryEnum.mexico,
    paymentMethod: PaymentTypeEnum.CARD,
    processorName: ProcessorTypeEnum.KUSHKI_ACQUIRER,
    currency: CurrencyEnum.MXN,
    limit: get(value, "limit", undefined),
    filter: get(value, "filter", undefined),
    offset: get(value, "offset", undefined),
    sort: {
      field: "created",
      order: "asc",
    },
    source:
      actualTab === TabsEnum.PENDING ? SourceEnum.pending : SourceEnum.historic,
    from: value.from,
    to: value.to,
  };
};
