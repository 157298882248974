import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import {
  BrazilFields,
  CentroAmericaFields,
  DeferredResumeData,
  EcuadorFields,
  MexicoFields,
  ProcessorNameDeferred,
} from "./DeferredResume.data";
import { DeferredOption } from "../../../../../types/get_deferred_admin_merchant_response";
import { defaultTo, get, includes, isEmpty, isEqual, map } from "lodash";
import { format } from "date-fns";
import { FormatDateEnum } from "../../../../shared/enums/FormatDateEnum";
import { mapProcessorNameToLabel } from "../DeferredList";
import {
  ConfigDeferredItem,
  DeferredConfigResponse,
} from "../../../../../types/get_deferred_config_response";
import { RenderItemDeferred } from "../../../../shared/infrastructure/interfaces/IRenderItemDeferred";
import {
  TextColor,
  TypographyVariant,
} from "../../../../shared/enums/MaterialUIEnum";
import {
  CENTRO_AMERICA_COUNTRIES,
  CountriesEnum,
} from "../../../../shared/infrastructure/enums/CountriesEnum";
import { DeferredOptionPath } from "../../../../shared/enums/DeferredOptionPath";
import { Variants } from "../../../../../types/update_merchant_request";
import { useDeferredStyles as styles } from "../../Deferred.styles";
import { calculateDateUtc, convertFormatDate } from "../../../../utils/utils";
import Mastercard from "../../../../assets/images/mastercard.svg";
import Visa from "../../../../assets/images/visa.svg";

import { CreditCardsEnum } from "../../../../shared/infrastructure/enums/CreditCardsEnum";

export interface DeferredResumeProps {
  country: string;
  deferredConfig: DeferredConfigResponse;
  deferredInfo: DeferredOption;
}

const acceptedCardImage = {
  [CreditCardsEnum.VISA]: Visa,
  [CreditCardsEnum.MASTERCARD]: Mastercard,
};

export const DeferredResume: React.FC<DeferredResumeProps> = ({
  country,
  deferredConfig,
  deferredInfo,
}) => {
  const fields: RenderItemDeferred[] = renderFieldsByCountry(
    country,
    deferredInfo,
    deferredConfig
  );
  const rules: RenderItemDeferred[][] = map(
    get(deferredInfo, "variants", []),
    (rule) => renderRuleFields(rule)
  );

  const cardsRenderChip = (fields: string[]): JSX.Element[] => {
    return defaultTo(fields, []).map((field: string) => {
      return (
        <img
          key={field}
          src={defaultTo(acceptedCardImage[field], CreditCardsEnum.VISA)}
          alt={field}
          height={20}
        />
      );
    });
  };
  const renderResumeInformation = (
    fields: RenderItemDeferred[]
  ): (JSX.Element | false)[] => {
    return fields.map((field) => {
      return (
        field.showItem && (
          <Grid container item spacing={2} key={field.labelItem}>
            <Grid item xs={4}>
              <Typography
                color={TextColor.Grey}
                variant={TypographyVariant.Body2}
              >
                {field.labelItem}
              </Typography>
            </Grid>
            {field.labelItem === DeferredResumeData.Cards ? (
              <Grid item xs={8} style={{ margin: "0px" }}>
                <Box
                  display="flex"
                  flexDirection={"row"}
                  style={{ margin: "0px" }}
                >
                  {cardsRenderChip([
                    CreditCardsEnum.MASTERCARD,
                    CreditCardsEnum.VISA,
                  ])}
                </Box>
              </Grid>
            ) : (
              <Grid item xs={8}>
                <Typography
                  color={TextColor.Dark}
                  variant={TypographyVariant.Body2}
                >
                  {field.dataItem}
                </Typography>
              </Grid>
            )}
          </Grid>
        )
      );
    });
  };

  const renderRules: (JSX.Element | false)[] = map(
    rules,
    (rule: RenderItemDeferred[], index: number) => {
      return (
        <>
          <Typography variant="h6" sx={styles.resumeRulesTitle}>
            {`${DeferredResumeData.Rule} ${index + 1}`}
          </Typography>
          {renderResumeInformation(rule)}
        </>
      );
    }
  );

  return (
    <Grid container>
      {renderResumeInformation(fields)}
      {!isEmpty(rules) && <Divider sx={styles.resumeDivider} />}
      {renderRules}
    </Grid>
  );
};

const getBanks = (deferredInfo: DeferredOption) => {
  const banks = get(deferredInfo, "bank", []);
  return banks.join(",");
};

const sortAndJoinData = (array: string[]) => {
  if (isEmpty(array)) return "";

  const arrayForSort = [...array];

  return arrayForSort
    .sort((a, b) => {
      return Number(a) - Number(b);
    })
    .join(", ");
};

export const dateFromUnixToDate = (
  timeUnix: number,
  pattern: string
): string => {
  return format(
    new Date(timeUnix.toString().length > 10 ? timeUnix : timeUnix * 1000),
    pattern
  );
};

const showConsortium = (processorName: string): boolean => {
  switch (processorName) {
    case ProcessorNameDeferred.KUSHKI_ACQ:
    case ProcessorNameDeferred.BILLPOCKET:
      return true;
    default:
      return false;
  }
};

const mapDeferredTypeFromId = (
  ids: string[],
  deferredConfig: DeferredConfigResponse
): string[] => {
  if (isEmpty(deferredConfig)) {
    return ids;
  }

  return ids.map((id) => {
    const config = deferredConfig.find((config) => {
      const configId = get(config, "code", "");
      return configId === id;
    });

    return get(config, "name", id);
  });
};

const renderFieldsByCountry = (
  country: string,
  deferredInfo: DeferredOption,
  deferredConfig: ConfigDeferredItem[]
): RenderItemDeferred[] => {
  const deferredType: string = mapDeferredTypeFromId(
    defaultTo(deferredInfo.deferredType, []),
    deferredConfig
  ).join(", ");
  const items: RenderItemDeferred[] = renderAllFields(
    deferredInfo,
    deferredType,
    country
  );
  switch (country) {
    case CountriesEnum.MEXICO:
      return items.filter((item) =>
        MexicoFields().some((fieldName) => item.labelItem === fieldName)
      );
    case CountriesEnum.ECUADOR:
      return items.filter((item) =>
        EcuadorFields().some((fieldName) => item.labelItem === fieldName)
      );
    case CountriesEnum.BRAZIL:
      return items.filter((item) =>
        BrazilFields().some((fieldName) => item.labelItem === fieldName)
      );
    case CountriesEnum.COSTA_RICA:
    case CountriesEnum.GUATEMALA:
    case CountriesEnum.PANAMA:
    case CountriesEnum.NICARAGUA:
    case CountriesEnum.HONDURAS:
    case CountriesEnum.EL_SALVADOR:
      return items.filter((item) =>
        CentroAmericaFields().some((fieldName) => item.labelItem === fieldName)
      );
    default:
      return items;
  }
};

const renderAllFields = (
  deferredInfo: DeferredOption,
  deferredType: string,
  country: string
): RenderItemDeferred[] => {
  const processorName: string = get(
    deferredInfo,
    DeferredOptionPath.ProcessorName,
    ""
  );
  const monthsOfGrace: string[] = get(
    deferredInfo,
    DeferredOptionPath.MonthsOfGrace,
    []
  );
  const months: string[] = get(
    deferredInfo,
    includes(CENTRO_AMERICA_COUNTRIES, country)
      ? DeferredOptionPath.MerchantMonths
      : DeferredOptionPath.Months,
    []
  );

  const createdNumber: number = get(
    deferredInfo,
    DeferredOptionPath.Created,
    0
  );
  const created: string = dateFromUnixToDate(
    createdNumber,
    FormatDateEnum.ddMMyyyyHHmmLine
  );
  const startDate: number = get(deferredInfo, DeferredOptionPath.StartDate, 0);
  const formattedStartDate: string = convertFormatDate(
    calculateDateUtc(startDate * 1000, true),
    FormatDateEnum.ddMMyyyy
  );
  const endDate: number = get(deferredInfo, DeferredOptionPath.EndDate, 0);
  const formattedEndDate: string = convertFormatDate(
    calculateDateUtc(endDate * 1000, false),
    FormatDateEnum.ddMMyyyy
  );

  return [
    {
      showItem: createdNumber !== 0,
      dataItem: created,
      labelItem: DeferredResumeData.Created,
    },

    {
      showItem: true,
      dataItem: mapProcessorNameToLabel(processorName),
      labelItem: DeferredResumeData.Processor,
    },
    {
      showItem: true,
      dataItem: DeferredResumeData.Cards,
      labelItem: DeferredResumeData.Cards,
    },
    {
      showItem: showConsortium(processorName),
      dataItem: defaultTo(deferredInfo.entity, ""),
      labelItem: DeferredResumeData.Consortium,
    },
    {
      showItem: true,
      dataItem: deferredType,
      labelItem: DeferredResumeData.DeferredType,
    },
    {
      showItem: true,
      dataItem: getBanks(deferredInfo),
      labelItem: DeferredResumeData.Banks,
    },
    {
      showItem: true,
      dataItem: sortAndJoinData(months),
      labelItem: DeferredResumeData.Fees,
    },
    {
      showItem: !isEmpty(monthsOfGrace),
      dataItem: sortAndJoinData(monthsOfGrace),
      labelItem: DeferredResumeData.MonthsOfGrace,
    },
    {
      showItem: !isEqual(startDate, 0) && !isEqual(endDate, 0),
      dataItem: `${formattedStartDate} - ${formattedEndDate}`,
      labelItem: DeferredResumeData.Schedule,
    },
  ];
};

const renderRuleFields = (rule: Variants): RenderItemDeferred[] => {
  const monthsOfGrace: string[] = get(
    rule,
    DeferredOptionPath.MonthsOfGrace,
    []
  );
  const months: string[] = get(rule, DeferredOptionPath.Months, []);

  return [
    {
      showItem: true,
      dataItem: get(rule, "bank", []).join(", "),
      labelItem: DeferredResumeData.Banks,
    },
    {
      showItem: true,
      dataItem: sortAndJoinData(months),
      labelItem: DeferredResumeData.Fees,
    },
    {
      showItem: !isEmpty(monthsOfGrace),
      dataItem: sortAndJoinData(monthsOfGrace),
      labelItem: DeferredResumeData.MonthsOfGrace,
    },
  ];
};
