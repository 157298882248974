import React from "react";
import { Box, Grid } from "@mui/material";
import { defaultTo } from "lodash";
import { IContentModalDetailBranch } from "../ModalContainerBranch/DataTableContainerBranch.interface";

export const ContentModalDetailBranch: React.FC<IContentModalDetailBranch> = (
  props: IContentModalDetailBranch
) => {
  return (
    <Grid display="flex">
      <Box display="flex" flexDirection="column" marginRight="8px" width="100%">
        {defaultTo(props.children![0], <></>)}
        {defaultTo(props.children![1], <></>)}
        {defaultTo(props.children![2], <></>)}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        marginLeft="8px"
        width="100%"
      ></Box>
    </Grid>
  );
};
