import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "../../../FormControl/FormControl";
import { physicalPersonRules } from "../../../../shared/constants/form_labels_legal_details";
import React from "react";
import { useShareholderFormState } from "./state/useShareholderFormState";
import { IShareholderForm } from "./ShareholderForm.interfaces";
import PercentageInput from "../../PercentageInput/PercentageInput";
import { shareholderFormStyle as styles } from "./Shareholder.styles";
import {
  ADD_BUTTON_SH,
  CLOSE_BUTTON_SH,
  EDIT_BUTTON_SH,
  LEGAL_INFORMATION_LABEL,
  PERSON_TYPE_LABEL,
  SAVE_BUTTON_SH,
} from "../../../../shared/constants/shareholder_constants";
import ModalDialog from "../../../ModalDialog/ModalDialog";
import {
  ACCEPT_BUTTON_EDIT_TEXT,
  CANCEL_BUTTON_TEXT,
  DESCRIPTION_EDIT_TEXT,
  TITLE_EDIT_TEXT,
} from "../../../../shared/constants/labels/ModalDialogConstants";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { ComponentIdsEnum } from "../../../../shared/constants/labels/main_containter_labels";

const ShareholderForm: React.FC<IShareholderForm> = ({
  position,
}: IShareholderForm) => {
  const {
    availablePercentage,
    closeModal,
    form,
    isEdited,
    onSubmit,
    isPhysical,
    sourceName,
    modalDialogEdit,
    valuesPersonType,
  } = useShareholderFormState(position);

  return (
    <>
      <Box>
        <Box sx={styles.container}>
          <Box sx={styles.header}>
            <DialogTitle>
              <Typography variant={"h4"}>
                {isEdited ? EDIT_BUTTON_SH : ADD_BUTTON_SH} accionista
              </Typography>
              <Typography
                sx={styles.sourceName}
                variant={"body2"}
                color={"text.dark"}
              >
                {sourceName}
              </Typography>
            </DialogTitle>
            <Box sx={styles.closeButton}>
              <IconButton color="secondary" onClick={closeModal} size="small">
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Box sx={styles.dividerContainer}>
            <Divider sx={styles.divider} />
          </Box>
          <DialogContent>
            <Grid container rowSpacing={3} columnSpacing={2}>
              <Grid item xs={12}>
                <Typography variant={"body2"} color={"text.dark"}>
                  {PERSON_TYPE_LABEL}
                </Typography>
                <FormControl
                  data-testid="radioTestSh"
                  control={form.control}
                  inputComponent="radio_sh"
                  values={valuesPersonType}
                  label="Tipo de persona"
                  name="personType"
                  onChange={modalDialogEdit.onChangeRadioButton}
                  rules={physicalPersonRules.nameField}
                />
              </Grid>
            </Grid>
            {isPhysical ? (
              <Grid container rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant={"body2"}
                    color={"text.dark"}
                    sx={styles.infoTitle}
                  >
                    {LEGAL_INFORMATION_LABEL}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    key={"name"}
                    control={form.control}
                    inputComponent="text"
                    label="Nombre(s)"
                    name="name"
                    rules={physicalPersonRules.nameField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    key={"lastName"}
                    control={form.control}
                    inputComponent="text"
                    label="Apellido(s)"
                    name="lastName"
                    rules={physicalPersonRules.lastName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    key={"documentType"}
                    control={form.control}
                    inputComponent="select_sh"
                    label="Tipo de identificación"
                    name="documentType"
                    rules={physicalPersonRules.documentType}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    key={"documentNumber"}
                    control={form.control}
                    inputComponent="text"
                    label="Nro. de identificación"
                    name="documentNumber"
                    rules={physicalPersonRules.documentNumberPhysical}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PercentageInput
                    key={"participationPercentage_01"}
                    control={form.control}
                    name={"participationPercentage"}
                    label={"Porcentaje de participación"}
                    percentage={availablePercentage.toString()}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    key={"country0"}
                    control={form.control}
                    inputComponent="countries"
                    label="País de residencia"
                    name="country"
                    rules={physicalPersonRules.countryResidence}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    key={"birthdate"}
                    control={form.control}
                    inputComponent="date"
                    label="Fecha de nacimiento"
                    name="birthdate"
                    rules={physicalPersonRules.birthdate}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12}>
                  <Typography variant={"body2"} color={"text.dark"}>
                    {LEGAL_INFORMATION_LABEL}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    key={"socialReason"}
                    control={form.control}
                    inputComponent="text"
                    label="Razón social"
                    name="socialReason"
                    rules={physicalPersonRules.razonSocial}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    key={"taxId"}
                    control={form.control}
                    inputComponent="text"
                    label="Número de TAX ID"
                    name="taxId"
                    rules={physicalPersonRules.taxNumberPhysical}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PercentageInput
                    key={"participationPercentage02"}
                    control={form.control}
                    name={"participationPercentage"}
                    label={"Porcentaje de participación"}
                    percentage={availablePercentage.toString()}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    key={"countryHome"}
                    control={form.control}
                    inputComponent="countries"
                    label="País de domicilio de la empresa"
                    name="companyCountry"
                    rules={physicalPersonRules.country}
                  />
                </Grid>
              </Grid>
            )}
          </DialogContent>
        </Box>
        <DialogActions sx={styles.footer}>
          <Button size="small" onClick={closeModal}>
            {CLOSE_BUTTON_SH}
          </Button>
          <ComponentSecurityWrapper
            componentId={
              ComponentIdsEnum.M_CLIENTS_ADD_SHAREHOLDER_MODAL_BUTTON
            }
          >
            <Button
              size="small"
              variant={"contained"}
              onClick={form.handleSubmit(onSubmit)}
            >
              {isEdited ? SAVE_BUTTON_SH : ADD_BUTTON_SH}
            </Button>
          </ComponentSecurityWrapper>
        </DialogActions>
      </Box>
      <ModalDialog
        acceptButtonText={ACCEPT_BUTTON_EDIT_TEXT}
        cancelButtonText={CANCEL_BUTTON_TEXT}
        descriptionText={DESCRIPTION_EDIT_TEXT}
        onClickAcceptButton={modalDialogEdit.handleAcceptModalDialogEdit}
        onClickCancelButton={modalDialogEdit.handleCloseModalDialogEdit}
        onClose={modalDialogEdit.handleCloseModalDialogEdit}
        openModalDialog={modalDialogEdit.openModalDialogEdit}
        titleText={TITLE_EDIT_TEXT}
      />
    </>
  );
};

export default ShareholderForm;
