import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const chargebackTransactionUploadSectionStyles = createNamedStyles({
  divider: {
    marginLeft: "0px !important",
    marginRight: "0px !important",
    border: "0px solid !important",
  },
  containerDivider: {
    paddingBottom: "35px !important",
  },
  title: {
    color: "#023365",
    lineHeight: " 140%",
    letterSpacing: "-0.015em",
  },
  textDocumentation: {
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: {
      xs: "15px !important",
      md: "15px !important",
    },
    lineHeight: "144%",
    color: "#023365",
    flex: "none",
    order: 0,
    flexGrow: 1,
  },
  textDocumentationStep2: {
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: {
      xs: "15px !important",
      md: "15px !important",
    },
    lineHeight: "144%",
    color: "#023365",
    flex: "none",
    order: 0,
    flexGrow: 1,
    paddingTop: "5px !important",
  },
  textStep: {
    fontSize: "16px !important",
    fontWeight: 500,
    color: "#023365",
  },
});
