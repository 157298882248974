import {
  ICheckboxObject,
  IUseTransactionProps,
  IUseTransactionSection,
} from "./useTransactionSection.interfaces";
import { useEffect, useState } from "react";
import { findIndex } from "lodash";
import {
  CATALOG_VALUES,
  getValuesByCountry,
  isAvailableCountry,
  SemaphoreTransactionType,
} from "../../../shared/constants/catalog_by_country";
import {
  TRANSACTION_TO_BILLING,
  TRANSACTION_TO_DECLINED,
} from "../../../shared/constants/labels/billing_form_labels";
import { YesNoEnum } from "../../../shared/enums/dispersionsEnum";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { get } from "lodash";
import { SHOW_SECTION } from "../../../store/reducers/app/app.slice";
import {
  clearAllValuesBilling,
  updateValuesBilling,
  updateValuesDeclinedBilling,
  updateValuesSubtractBilling,
} from "../../../store/actions/billing/billing.actions";
import { CountriesEnum } from "../../../shared/enums/countriesEnum";

export const useTransactionSection = ({
  country,
}: IUseTransactionProps): IUseTransactionSection => {
  const [showSection, setShowSection] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [showSnackBar] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [switchBilling, setSwitchBilling] = useState(true);
  const [switchDeclined, setSwitchDeclined] = useState(false);
  const [switchSubtract, setSwitchSubtract] = useState(true);
  const [checkSubtractItems, setCheckSubtractItems] = useState<
    ICheckboxObject[]
  >(CATALOG_VALUES[SemaphoreTransactionType.SUBTRACT]);
  const [checkBillItems, setCheckBillItems] = useState<ICheckboxObject[]>(
    isAvailableCountry(country)
      ? CATALOG_VALUES[country]
      : getValuesByCountry(country as CountriesEnum)
  );
  const [checkDeclinedItems, setCheckDeclinedItems] = useState<
    ICheckboxObject[]
  >(CATALOG_VALUES[SemaphoreTransactionType.DECLINED]);
  const { getBillingConfigInfo } = useAppSelector((state) => ({
    getBillingConfigInfo: state.billing.getBillingConfigInfo,
    valuesBillingState: state.billing.valuesBillingState,
    valuesDeclinedBillingState: state.billing.valuesDeclinedBillingState,
    valuesSubtractBillingState: state.billing.valuesSubtractBillingState,
  }));

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (country != undefined) {
      setCheckBillItems(
        isAvailableCountry(country)
          ? CATALOG_VALUES[country]
          : getValuesByCountry(country as CountriesEnum)
      );
    }
  }, [country]);

  useEffect(() => {
    const elementsApprovedBilling = get(
      getBillingConfigInfo,
      "valuesBilling.elementsApproved",
      []
    );
    const elementsDeclinedBilling = get(
      getBillingConfigInfo,
      "valuesBilling.elementsDeclined",
      []
    );
    const elementsApprovedSubtractBilling = get(
      getBillingConfigInfo,
      "valuesSubtractBilling.elementsApproved",
      []
    );
    const elementsDeclinedSubtractBilling = get(
      getBillingConfigInfo,
      "valuesSubtractBilling.elementsDeclined",
      []
    );
    const statusApprovedBilling: boolean = get(
      getBillingConfigInfo,
      "valuesBilling.statusApproved",
      true
    );

    const statusIsModify: boolean = get(
      getBillingConfigInfo,
      "valuesBilling.isModify",
      true
    );

    const statusApprovedSubtractBilling: boolean = get(
      getBillingConfigInfo,
      "valuesSubtractBilling.statusApproved",
      false
    );

    const statusApprovedTrxDeclined: boolean = get(
      getBillingConfigInfo,
      "valuesBilling.statusDeclined",
      false
    );

    if (
      elementsApprovedBilling.length > 0 ||
      elementsDeclinedBilling.length > 0 ||
      elementsApprovedSubtractBilling.length > 0 ||
      elementsDeclinedSubtractBilling.length > 0
    ) {
      setShowSection(true);
      if (statusIsModify === false) {
        setShowSection(false);
      }

      setCheckBillItems((prevStatus) =>
        prevStatus.map((checkbox) => {
          return {
            ...checkbox,
            checked: elementsApprovedBilling.includes(checkbox.value),
            disabled:
              !statusApprovedBilling ||
              elementsApprovedSubtractBilling.includes(checkbox.value),
          };
        })
      );

      setCheckSubtractItems((prevStatus) =>
        prevStatus.map((checkbox) => {
          return {
            ...checkbox,
            checked: elementsApprovedSubtractBilling.includes(checkbox.value),
            disabled:
              !statusApprovedSubtractBilling ||
              elementsApprovedBilling.includes(checkbox.value),
          };
        })
      );

      setCheckDeclinedItems((prevStatus) =>
        prevStatus.map((checkbox) => {
          return {
            ...checkbox,
            checked: elementsDeclinedBilling.includes(checkbox.value),
            disabled: !statusApprovedTrxDeclined,
          };
        })
      );
    }
    setSwitchBilling(statusApprovedBilling);
    setSwitchDeclined(statusApprovedTrxDeclined);
    setSwitchSubtract(statusApprovedSubtractBilling);
  }, [getBillingConfigInfo]);

  const getCheckItemEnableValue = (
    label: string,
    arrayLabels: ICheckboxObject[]
  ): boolean => {
    const index = findIndex(arrayLabels, ["label", label]);

    return index >= 0 ? arrayLabels[index].checked : false;
  };

  useEffect(() => {
    if (!switchBilling) {
      setCheckBillItems((prevStatus) =>
        prevStatus.map((checkbox) => ({
          ...checkbox,
          disabled: true,
        }))
      );
    } else {
      setCheckBillItems((prevStatus) =>
        prevStatus.map((checkbox) => ({
          ...checkbox,
          disabled: checkbox.checked
            ? false
            : getCheckItemEnableValue(checkbox.label, checkSubtractItems),
        }))
      );
    }
  }, [switchBilling]);

  useEffect(() => {
    if (!switchDeclined) {
      setCheckDeclinedItems((prevStatus) =>
        prevStatus.map((checkbox) => ({
          ...checkbox,
          disabled: true,
        }))
      );
    } else {
      setCheckDeclinedItems((prevStatus) =>
        prevStatus.map((checkbox) => ({
          ...checkbox,
          disabled: checkbox.checked
            ? false
            : getCheckItemEnableValue(checkbox.label, checkDeclinedItems),
        }))
      );
    }
  }, [switchDeclined]);

  useEffect(() => {
    if (!switchSubtract) {
      setCheckSubtractItems((prevStatus) =>
        prevStatus.map((checkbox) => ({
          ...checkbox,
          disabled: true,
        }))
      );
    } else {
      setCheckSubtractItems((prevStatus) =>
        prevStatus.map((checkbox) => ({
          ...checkbox,
          disabled: getCheckItemEnableValue(checkbox.label, checkBillItems),
        }))
      );
    }
  }, [switchSubtract]);

  const getNewValuesChecks = (
    label: string,
    arrayLabels: ICheckboxObject[],
    checked: boolean
  ): ICheckboxObject[] => {
    const items = [...arrayLabels];
    const index = findIndex(arrayLabels, ["label", label]);

    if (index >= 0) {
      items[index] = {
        ...items[index],
        disabled: checked,
      };
    }

    return items;
  };

  const handleOnChangeCheckBox = (
    valueCheck: boolean,
    valueLbl: string,
    title: string
  ) => {
    if (title === TRANSACTION_TO_BILLING) {
      const items = [...checkBillItems];
      const index = findIndex(checkBillItems, ["label", valueLbl]);

      items[index] = {
        ...items[index],
        checked: valueCheck,
      };
      setCheckBillItems(items);
      switchSubtract &&
        setCheckSubtractItems(
          getNewValuesChecks(valueLbl, checkSubtractItems, valueCheck)
        );
    } else if (title === TRANSACTION_TO_DECLINED) {
      const items = [...checkDeclinedItems];
      const index = findIndex(checkDeclinedItems, ["label", valueLbl]);

      items[index] = {
        ...items[index],
        checked: valueCheck,
      };
      setCheckDeclinedItems(items);
      switchDeclined;
    } else {
      const items = [...checkSubtractItems];
      const index = findIndex(checkSubtractItems, ["label", valueLbl]);

      items[index] = {
        ...items[index],
        checked: valueCheck,
      };
      setCheckSubtractItems(items);
      switchBilling &&
        setCheckBillItems(
          getNewValuesChecks(valueLbl, checkBillItems, valueCheck)
        );
    }
  };
  const handleOnChangeRadioButton = (value: string) => {
    setShowSection(value === YesNoEnum.YES);
    dispatch(SHOW_SECTION(value === YesNoEnum.YES));
    setOpenLoader(false);
  };

  useEffect(() => {
    if (!firstLoad) {
      if (showSection === false) {
        setCheckBillItems(
          isAvailableCountry(country)
            ? CATALOG_VALUES[country]
            : getValuesByCountry(country as CountriesEnum)
        );

        setCheckDeclinedItems(
          CATALOG_VALUES[SemaphoreTransactionType.DECLINED]
        );
        setCheckSubtractItems(
          CATALOG_VALUES[SemaphoreTransactionType.SUBTRACT]
        );
        setSwitchDeclined(false);
        setSwitchSubtract(false);
      }
      dispatch(SHOW_SECTION(showSection));
    }
    setFirstLoad(false);
  }, [showSection]);

  useEffect(() => {
    return () => {
      dispatch(clearAllValuesBilling());
    };
  }, []);

  useEffect(() => {
    dispatch(
      updateValuesBilling({
        checkBillItems,
        isModify: showSection,
        statusApproved: switchBilling,
      })
    );
    dispatch(
      updateValuesSubtractBilling({
        checkSubtractItems,
        statusApproved: switchSubtract,
      })
    );
    dispatch(
      updateValuesDeclinedBilling({
        checkDeclinedItems,
        statusDeclined: switchDeclined,
      })
    );
  }, [
    checkBillItems,
    checkDeclinedItems,
    checkSubtractItems,
    switchBilling,
    switchDeclined,
    switchSubtract,
    showSection,
  ]);

  return {
    checkBillItems,
    checkDeclinedItems,
    checkSubtractItems,
    handleOnChange: handleOnChangeRadioButton,
    handleOnChangeCheckBox,
    openLoader,
    setSwitchBilling,
    setSwitchDeclined,
    setSwitchSubtract,
    showSection,
    showSnackBar,
    switchBilling,
    switchDeclined,
    switchSubtract,
  };
};
