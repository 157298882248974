import { IconButton, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC, PropsWithChildren } from "react";
import { FLEX_JUSTIFY_BY_ALIGN } from "../../Table/TableBodySimple/constants";
import { ICellDeleteProps } from "./CellDelete.interfaces";
import { cellDeleteStyles } from "./CellDelete.styles";
import useCellDelete from "./useCellDelete";

export const CellDeleteItem: FC<ICellDeleteProps> = (
  props: PropsWithChildren<ICellDeleteProps>
) => {
  const { isDisabled, cellProps, variant, icon, size } = props;
  const {
    handler: { handleClick },
    isLoading,
  } = useCellDelete(props);

  return (
    <Box
      flexDirection={"column"}
      display={"flex"}
      alignItems={FLEX_JUSTIFY_BY_ALIGN[cellProps?.align || "center"]}
      justifyContent={"center"}
    >
      {isLoading ? (
        <Skeleton
          data-testid={"skeleton"}
          variant={"rectangular"}
          sx={cellDeleteStyles.skeletonIcon}
        />
      ) : (
        <IconButton
          data-testid={"delete-icon-button"}
          color={variant}
          onClick={handleClick}
          disabled={isDisabled}
          size={size}
        >
          {icon}
        </IconButton>
      )}
    </Box>
  );
};
