export enum FranchiseEnum {
  PROSA = "Prosa",
  VISA = "Visa",
  MASTERCARD = "MasterCard",
}

export const ALLOWED_FRANCHISES: FranchiseEnum[] = [
  FranchiseEnum.PROSA,
  FranchiseEnum.MASTERCARD,
  FranchiseEnum.VISA,
];
