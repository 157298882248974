import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import axiosInstance from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { routes } from "../../../shared/infrastructure/constants/routes";
import {
  ALL_TABLE_COLUMNS,
  PAYMENT_CELL,
  TAG_CELL,
} from "../../../shared/infrastructure/constants/WebhookNotificationsConstants";
import {
  setNotifications,
  setSearchNodes,
} from "../../../store/actions/actions";
import {
  selectNodeInfo,
  selectNotifications,
  selectSearchNodes,
} from "../../../store/selectors/selectors";
import {
  hit,
  ISearchMerchantNode,
  ISearchResponse,
  IWebhookNotification,
} from "./useWebhookNotification.interface";
import { ITableRowProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { IRowInteractiveProps } from "@kushki/connect-ui/dist/Components/Molecules/Table/RowInteractiveInfo/RowInteractive.interfaces";
import { TEXT_CELL } from "../../../shared/infrastructure/constants/DispersionBlockConstants";
import moment from "moment";
import { getDate } from "../../../shared/utils/date_utils";
import { EntityNameEnum } from "../../../shared/enums/entityName";

const MAX_BRANCH_ESTIMATED: number = 240;

export const useWebhookNotifications = () => {
  const dispatch = useDispatch();

  const nodeInfo = useSelector(selectNodeInfo);

  const isBranch = nodeInfo?.entityName === "BRANCH";

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [rows, setRows] = useState<ITableRowProps[]>([]);

  const webhookNotifications = useSelector(selectNotifications);

  const searchNodes = useSelector(selectSearchNodes);

  const history = useHistory();

  const notificationCall = async (merchantIds?: string[]) => {
    const filter = {
      from:
        moment().subtract(1, "month").format("YYYY-MM-DD").toString() +
        "T00:00:00",
      limit: 5,
      offset: 0,
      to: moment().format("YYYY-MM-DD") + "T23:59:59",
    };

    return await axiosInstance.post<IWebhookNotification>(
      API_ROUTES.WEBHOOK_NOTIFICATION,
      {
        ...filter,
        merchantIds: merchantIds ? merchantIds : [nodeInfo?.merchantId],
      }
    );
  };

  const showNotifications = () => {
    const queryParamsWebhookNotification: string = `?entityName=${nodeInfo?.entityName.toLowerCase()}&path=${
      nodeInfo!.path
    }`;

    history.push({
      pathname: `${routes.WEBHOOK_NOTIFICATIONS}/${nodeInfo?.merchantId}${queryParamsWebhookNotification}`,
    });
  };

  const searchNodeCall = async (offset: number) => {
    return await axiosInstance.post<ISearchResponse>(
      API_ROUTES.SEARCH_MERCHANT_NODE,
      {
        entityName: EntityNameEnum.BRANCH,
        limit: MAX_BRANCH_ESTIMATED,
        offset,
        path: nodeInfo!.path,
      }
    );
  };

  const doSearchForCustomers = async () => {
    let totalNotifications: hit[] = [];
    let totalSearchNodes: ISearchMerchantNode[] = [];
    let offset: number = 0;
    let totalMerchants: number = 0;

    do {
      const merchants = await searchNodeCall(offset);
      const merchantIds = merchants.data.data.map((nodes) => {
        return nodes.merchant_id;
      });
      const notifications = await notificationCall(merchantIds);

      totalSearchNodes.push(...merchants.data.data);
      totalNotifications.push(...notifications.data.hits);

      if (totalNotifications.length >= 5) break;

      totalMerchants = merchants.data.total;
      offset += MAX_BRANCH_ESTIMATED;
    } while (offset <= totalMerchants);
    dispatch(setSearchNodes(totalSearchNodes));
    dispatch(
      setNotifications({
        hits: totalNotifications,
        total: totalNotifications.length,
      })
    );
  };

  const getNotificationsData = async () => {
    if (isBranch) {
      const notifications = await notificationCall();

      dispatch(setNotifications(notifications.data));
    }
    if (nodeInfo?.entityName === EntityNameEnum.CUSTOMER) {
      await doSearchForCustomers();
    }
  };

  const searchMerchantNode = (merchantId: string) => {
    const merchantNode = searchNodes?.find(
      (item) => item.merchant_id === merchantId
    );

    return merchantNode?.name;
  };

  const onClickRow = () => {};

  useEffect(() => {
    if (!nodeInfo) return;
    getNotificationsData();
  }, [nodeInfo]);

  useEffect(() => {
    if (!webhookNotifications) return;

    const valuesToRow = webhookNotifications.hits.map(
      (column: hit, index: number): ITableRowProps => {
        const cells = [
          TEXT_CELL(getDate(column.timestamp, "dd-MM-yyyy")),
          TEXT_CELL(column.alias || "-"),
          PAYMENT_CELL(column.paymentMethod),
          TAG_CELL(column.status),
        ];

        if (!isBranch)
          cells.splice(
            3,
            0,
            TEXT_CELL(searchMerchantNode(column.merchantId), column.merchantId)
          );

        return {
          cells,
          id: `notification${index}`,
          rowProps: {
            color: "default",
            onClick: onClickRow,
          } as IRowInteractiveProps,
        };
      }
    );

    setRows(valuesToRow);
    setIsLoading(false);
  }, [webhookNotifications]);

  return {
    isLoading,
    showNotifications,
    table: {
      columns: ALL_TABLE_COLUMNS(isBranch),
      rows,
    },
  };
};
