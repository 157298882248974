import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { useNavigate } from "react-router-dom";
import { SelectNewItemList } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectNewItem/SelectNewItem.interfaces";
import getMerchantNodes from "../../../store/thunks/nodes/nodes.thunks";
import { find, get } from "lodash";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { IUseInformationForm } from "./useInformationForm.interfaces";
import { LayoutSavedState } from "../../../store/interfaces/nodes/layout.interfaces";
import { setSessionStorageValues } from "../../../store/utils/sessionStorage-utils";
import { TIME_OUT_VALUE } from "../../../shared/constants/time_out_value";
import { SessionStoragePaths } from "../../../shared/enums/sessionStoragePaths";
import { Entities } from "../../../shared/enums/entities";

const ITEM_SELECTED_DEFAULT_STATE = {
  label: "",
  value: "",
};

export const useInformationForm = (): IUseInformationForm => {
  const navigate = useNavigate();
  const list: SelectNewItemList[] = [];

  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [itemSelected, setItemSelected] = useState<SelectNewItemList>(
    ITEM_SELECTED_DEFAULT_STATE
  );
  const [nodeList, setNodeList] = useState<SelectNewItemList[]>([]);
  const dispatch = useAppDispatch();
  const { nodes } = useAppSelector((state) => state.merchantNodes);

  const [merchantNodeTimeOut, setMerchantNodeTimeOut] = useState<any>(0);

  useEffect(() => {
    nodes.data!.map((node) => {
      list.push({ label: node.name!, value: node.node_id! });
    });
    setNodeList(list);
  }, [nodes.data]);

  useEffect(() => {
    dispatch(
      getMerchantNodes({ entityName: Entities.OWNER, limit: 100, value: "" })
    );
  }, []);

  const handleOnClick = () => {
    const createNodeRoute = `${API_ROUTES.CUSTOMER}${itemSelected.value}&hideSideBar=true`;
    const nodeSelected = find(nodes.data, {
      name: itemSelected.label,
      node_id: itemSelected.value,
    });

    const basicMerchantInformation: LayoutSavedState = {
      layout: {
        entityName: get(nodeSelected, "entity_name", ""),
        nodeName: get(nodeSelected, "name", ""),
        origin: "createNode",
        section: "createNode",
      },
    };

    navigate(createNodeRoute);
    setSessionStorageValues(
      basicMerchantInformation,
      SessionStoragePaths.BASIC_INFORMATION
    );
  };

  const handleOnChange = (value: string) => {
    if (merchantNodeTimeOut) {
      clearTimeout(merchantNodeTimeOut);
    }
    if (value.trim().length > 0) {
      setMerchantNodeTimeOut(
        setTimeout(() => {
          dispatch(
            getMerchantNodes({
              entityName: Entities.OWNER,
              limit: 100,
              value: value,
            })
          );
        }, TIME_OUT_VALUE.MILLISECONDS)
      );
    }
  };

  const handleItemSelect = (item: SelectNewItemList | null) => {
    setIsSelected(item !== null);
    setItemSelected(item !== null ? item : ITEM_SELECTED_DEFAULT_STATE);
  };

  return {
    handleItemSelect,
    handleOnChange,
    handleOnClick,
    isSelected,
    nodeList,
  };
};
