/**
 * Catalog Chile
 */
import { Category } from "../constants/information_constants";
import { CatalogsEnum } from "../interfaces/catalogs-enum";
import { CurrencyEnum } from "../interfaces/currency-enum";

export const CatalogCl: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { defaultShareholderDocType: "0", name: "Run", value: "0" },
    { defaultShareholderDocType: "1", name: "Pasaporte", value: "1" },
    { defaultShareholderDocType: "0", name: "Rut", value: "2" },
  ],
  [CatalogsEnum.CategoryCode]: [
    {
      name: "Cultivo de trigo",
      value: "11101",
    },
    {
      name: "Cultivo de maíz",
      value: "11102",
    },
    {
      name: "Cultivo de avena",
      value: "11103",
    },
    {
      name: "Cultivo de cebada",
      value: "11104",
    },
    {
      name: "Cultivo de otros cereales (excepto trigo, maíz, avena y cebada)",
      value: "11105",
    },
    {
      name: "Cultivo de porotos",
      value: "11106",
    },
    {
      name: "Cultivo de lupino",
      value: "11107",
    },
    {
      name: "Cultivo de otras legumbres (excepto porotos y lupino)",
      value: "11108",
    },
    {
      name: "Cultivo de semillas de raps",
      value: "11109",
    },
    {
      name: "Cultivo de semillas de maravilla (girasol)",
      value: "11110",
    },
    {
      name: "Cultivo de semillas de cereales, legumbres y oleaginosas (excepto semillas de raps y maravilla)",
      value: "11111",
    },
    {
      name: "Cultivo de arroz",
      value: "11200",
    },
    {
      name: "Cultivo de papas",
      value: "11301",
    },
    {
      name: "Cultivo de camotes",
      value: "11302",
    },
    {
      name: "Cultivo de otros tubérculos (excepto papas y camotes)",
      value: "11303",
    },
    {
      name: "Cultivo de remolacha azucarera",
      value: "11304",
    },
    {
      name: "Cultivo de semillas de hortalizas",
      value: "11305",
    },
    {
      name: "Cultivo de hortalizas y melones",
      value: "11306",
    },
    {
      name: "Cultivo de caña de azúcar",
      value: "11400",
    },
    {
      name: "Cultivo de tabaco",
      value: "11500",
    },
    {
      name: "Cultivo de plantas de fibra",
      value: "11600",
    },
    {
      name: "Cultivo de flores",
      value: "11901",
    },
    {
      name: "Cultivos forrajeros en praderas mejoradas o sembradas; cultivos suplementarios forrajeros",
      value: "11902",
    },
    {
      name: "Cultivos forrajeros en praderas mejoradas o sembradas; cultivos suplementarios forrajeros",
      value: "11902",
    },
    {
      name: "Cultivos de semillas de flores; cultivo de semillas de plantas forrajeras",
      value: "11903",
    },
    {
      name: "Cultivo de uva destinada a la producción de pisco y aguardiente",
      value: "12111",
    },
    {
      name: "Cultivo de uva destinada a la producción de vino",
      value: "12112",
    },
    {
      name: "Cultivo de uva para mesa",
      value: "12120",
    },
    {
      name: "Cultivo de frutas tropicales y subtropicales (incluye el cultivo de paltas)",
      value: "12200",
    },
    {
      name: "Cultivo de cítricos",
      value: "12300",
    },
    {
      name: "Cultivo de frutas de pepita y de hueso",
      value: "12400",
    },
    {
      name: "Cultivo de semillas de frutas",
      value: "12501",
    },
    {
      name: "Cultivo de otros frutos y nueces de árboles y arbustos",
      value: "12502",
    },
    {
      name: "Cultivo de frutos oleaginosos (incluye el cultivo de aceitunas)",
      value: "12600",
    },
    {
      name: "Cultivo de plantas con las que se preparan bebidas (incluye el cultivo de café, té y mate)",
      value: "12700",
    },
    {
      name: "Cultivo de especias",
      value: "12801",
    },
    {
      name: "Cultivo de plantas aromáticas, medicinales y farmacéuticas",
      value: "12802",
    },
    {
      name: "Cultivo de otras plantas perennes",
      value: "12900",
    },
    {
      name: "Cultivo de plantas vivas incluida la producción en viveros (excepto viveros forestales)",
      value: "13000",
    },
    {
      name: "Cría de ganado bovino para la producción lechera",
      value: "14101",
    },
    {
      name: "Cría de ganado bovino para la producción de carne o como ganado reproductor",
      value: "14102",
    },
    {
      name: "Cría de caballos y otros equinos",
      value: "14200",
    },
    {
      name: "Cría de llamas, alpacas, vicuñas, guanacos y otros camélidos",
      value: "14300",
    },
    {
      name: "Cría de ovejas (ovinos)",
      value: "14410",
    },
    {
      name: "Cría de cabras (caprinos)",
      value: "14420",
    },
    {
      name: "Cría de cerdos",
      value: "14500",
    },
    {
      name: "Cría de aves de corral para la producción de carne",
      value: "14601",
    },
    {
      name: "Cría de aves de corral para la producción de huevos",
      value: "14602",
    },
    {
      name: "Apicultura",
      value: "14901",
    },
    {
      name: "Cría de otros animales n.c.p.",
      value: "14909",
    },
    {
      name: "Cultivo de productos agrícolas en combinación con la cría de animales (explotación mixta)",
      value: "15000",
    },
    {
      name: "Actividades de apoyo a la agricultura",
      value: "16100",
    },
    {
      name: "Actividades de apoyo a la ganadería",
      value: "16200",
    },
    {
      name: "Actividades poscosecha",
      value: "16300",
    },
    {
      name: "Tratamiento de semillas para propagación",
      value: "16400",
    },
    {
      name: "Caza ordinaria y mediante trampas y actividades de servicios conexas",
      value: "17000",
    },
    {
      name: "Explotación de viveros forestales",
      value: "21001",
    },
    {
      name: "Silvicultura y otras actividades forestales (excepto explotación de viveros forestales)",
      value: "21002",
    },
    {
      name: "Extracción de madera",
      value: "22000",
    },
    {
      name: "Recolección de productos forestales distintos de la madera",
      value: "23000",
    },
    {
      name: "Servicios de forestación a cambio de una retribución o por contrata",
      value: "24001",
    },
    {
      name: "Servicios de corta de madera a cambio de una retribución o por contrata",
      value: "24002",
    },
    {
      name: "Servicios de extinción y prevención de incendios forestales",
      value: "24003",
    },
    {
      name: "Otros servicios de apoyo a la silvicultura n.c.p.",
      value: "24009",
    },
    {
      name: "Pesca marítima industrial, excepto de barcos factoría",
      value: "31110",
    },
    {
      name: "Pesca marítima artesanal",
      value: "31120",
    },
    {
      name: "Recolección y extracción de productos marinos",
      value: "31130",
    },
    {
      name: "Servicios relacionados con la pesca marítima",
      value: "31140",
    },
    {
      name: "Pesca de agua dulce",
      value: "31200",
    },
    {
      name: "Cultivo y crianza de peces marinos",
      value: "32110",
    },
    {
      name: "Cultivo, reproducción y manejo de algas marinas",
      value: "32120",
    },
    {
      name: "Reproducción y cría de moluscos, crustáceos y gusanos marinos",
      value: "32130",
    },
    {
      name: "Servicios relacionados con la acuicultura marina",
      value: "32140",
    },
    {
      name: "Acuicultura de agua dulce",
      value: "32200",
    },
    {
      name: "Extracción y procesamiento de cobre",
      value: "40000",
    },
    {
      name: "Extracción de carbón de piedra",
      value: "51000",
    },
    {
      name: "Extracción de lignito",
      value: "52000",
    },
    {
      name: "Extracción de petróleo crudo",
      value: "61000",
    },
    {
      name: "Extracción de gas natural",
      value: "62000",
    },
    {
      name: "Extracción de minerales de hierro",
      value: "71000",
    },
    {
      name: "Extracción de minerales de uranio y torio",
      value: "72100",
    },
    {
      name: "Extracción de oro y plata",
      value: "72910",
    },
    {
      name: "Extracción de zinc y plomo",
      value: "72991",
    },
    {
      name: "Extracción de manganeso",
      value: "72992",
    },
    {
      name: "Extracción de otros minerales metalíferos no ferrosos n.c.p. (excepto zinc, plomo y manganeso)",
      value: "72999",
    },
    {
      name: "Extracción de piedra, arena y arcilla",
      value: "81000",
    },
    {
      name: "Extracción y procesamiento de litio",
      value: "89110",
    },
    {
      name: "Extracción de minerales para la fabricación de abonos y productos químicos n.c.p.",
      value: "89190",
    },
    {
      name: "Extracción de turba",
      value: "89200",
    },
    {
      name: "Extracción de sal",
      value: "89300",
    },
    {
      name: "Explotación de otras minas y canteras n.c.p.",
      value: "89900",
    },
    {
      name: "Actividades de apoyo para la extracción de petróleo y gas natural prestados por empresas",
      value: "91001",
    },
    {
      name: "Actividades de apoyo para la extracción de petróleo y gas natural prestados por empresas",
      value: "91001",
    },
    {
      name: "Actividades de apoyo para la extracción de petróleo y gas natural prestados por profesionales",
      value: "91002",
    },
    {
      name: "Actividades de apoyo para la explotación de otras minas y canteras prestados por empresas",
      value: "99001",
    },
    {
      name: "Actividades de apoyo para la explotación de otras minas y canteras prestados por profesionales",
      value: "99002",
    },
    {
      name: "Explotación de mataderos de bovinos, ovinos, equinos, caprinos, porcinos y camélidos",
      value: "101011",
    },
    {
      name: "Explotación de mataderos de aves y de otros tipos de animales n.c.p.",
      value: "101019",
    },
    {
      name: "Elaboración y conservación de carne y productos cárnicos",
      value: "101020",
    },
    {
      name: "Producción de harina de pescado",
      value: "102010",
    },
    {
      name: "Elaboración y conservación de salmónidos",
      value: "102020",
    },
    {
      name: "Elaboración y conservación de otros pescados, en plantas en tierra (excepto barcos factoría)",
      value: "102030",
    },
    {
      name: "Elaboración y conservación de otros pescados, en plantas en tierra (excepto barcos factoría)",
      value: "102030",
    },
    {
      name: "Elaboración y conservación de crustáceos, moluscos y otros productos acuáticos, en plantas en tierra",
      value: "102040",
    },
    {
      name: "Actividades de elaboración y conservación de pescado, realizadas en barcos factoría",
      value: "102050",
    },
    {
      name: "Elaboración y procesamiento de algas",
      value: "102060",
    },
    {
      name: "Elaboración y conservación de frutas, legumbres y hortalizas",
      value: "103000",
    },
    {
      name: "Elaboración de aceites y grasas de origen vegetal y animal (excepto elaboración de mantequilla)",
      value: "104000",
    },
    {
      name: "Elaboración de aceites y grasas de origen vegetal y animal (excepto elaboración de mantequilla)",
      value: "104000",
    },
    {
      name: "Elaboración de productos lácteos",
      value: "105000",
    },
    {
      name: "Molienda de trigo: producción de harina, sémola y gránulos",
      value: "106101",
    },
    {
      name: "Molienda de arroz; producción de harina de arroz",
      value: "106102",
    },
    {
      name: "Elaboración de otros productos de molinería n.c.p.",
      value: "106109",
    },
    {
      name: "Elaboración de almidones y productos derivados del almidón",
      value: "106200",
    },
    {
      name: "Elaboración de almidones y productos derivados del almidón",
      value: "106200",
    },
    {
      name: "Elaboración de productos de panadería y pastelería",
      value: "107100",
    },
    {
      name: "Elaboración de azúcar",
      value: "107200",
    },
    {
      name: "Elaboración de cacao, chocolate y de productos de confitería",
      value: "107300",
    },
    {
      name: "Elaboración de cacao, chocolate y de productos de confitería",
      value: "107300",
    },
    {
      name: "Elaboración de macarrones, fideos, alcuzcuz y productos farináceos similares",
      value: "107400",
    },
    {
      name: "Elaboración de comidas y platos preparados envasados, rotulados y con información nutricional",
      value: "107500",
    },
    {
      name: "Elaboración de comidas y platos preparados envasados, rotulados y con información nutricional",
      value: "107500",
    },
    {
      name: "Elaboración de té, café, mate e infusiones de hierbas",
      value: "107901",
    },
    {
      name: "Elaboración de levaduras naturales o artificiales",
      value: "107902",
    },
    {
      name: "Elaboración de vinagres, mostazas, mayonesas y condimentos en general",
      value: "107903",
    },
    {
      name: "Elaboración de otros productos alimenticios n.c.p.",
      value: "107909",
    },
    {
      name: "Elaboración de piensos preparados para animales",
      value: "108000",
    },
    {
      name: "Elaboración de pisco (industrias pisqueras)",
      value: "110110",
    },
    {
      name: "Destilación, rectificación y mezclas de bebidas alcohólicas; excepto pisco",
      value: "110120",
    },
    {
      name: "Elaboración de vinos",
      value: "110200",
    },
    {
      name: "Elaboración de bebidas malteadas y de malta",
      value: "110300",
    },
    {
      name: "Elaboración de bebidas no alcohólicas",
      value: "110401",
    },
    {
      name: "Producción de aguas minerales y otras aguas embotelladas",
      value: "110402",
    },
    {
      name: "Elaboración de cigarros y cigarrillos",
      value: "120001",
    },
    {
      name: "Elaboración de otros productos de tabaco n.c.p.",
      value: "120009",
    },
    {
      name: "Preparación e hilatura de fibras textiles",
      value: "131100",
    },
    {
      name: "Tejedura de productos textiles",
      value: "131200",
    },
    {
      name: "Acabado de productos textiles",
      value: "131300",
    },
    {
      name: "Fabricación de tejidos de punto y ganchillo",
      value: "139100",
    },
    {
      name: "Fabricación de artículos confeccionados de materiales textiles, excepto prendas de vestir",
      value: "139200",
    },
    {
      name: "Fabricación de artículos confeccionados de materiales textiles, excepto prendas de vestir",
      value: "139200",
    },
    {
      name: "Fabricación de tapices y alfombras",
      value: "139300",
    },
    {
      name: "Fabricación de cuerdas, cordeles, bramantes y redes",
      value: "139400",
    },
    {
      name: "Fabricación de otros productos textiles n.c.p.",
      value: "139900",
    },
    {
      name: "Fabricación de prendas de vestir de materiales textiles y similares",
      value: "141001",
    },
    {
      name: "Fabricación de prendas de vestir de cuero natural o artificial",
      value: "141002",
    },
    {
      name: "Fabricación de accesorios de vestir",
      value: "141003",
    },
    {
      name: "Fabricación de ropa de trabajo",
      value: "141004",
    },
    {
      name: "Fabricación de artículos de piel",
      value: "142000",
    },
    {
      name: "Fabricación de artículos de punto y ganchillo",
      value: "143000",
    },
    {
      name: "Curtido y adobo de cueros; adobo y teñido de pieles",
      value: "151100",
    },
    {
      name: "Fabricación de maletas, bolsos y artículos similares, artículos de talabartería y guarnicionería",
      value: "151200",
    },
    {
      name: "Fabricación de calzado",
      value: "152000",
    },
    {
      name: "Aserrado y acepilladura de madera",
      value: "161000",
    },
    {
      name: "Fabricación de hojas de madera para enchapado y tableros a base de madera",
      value: "162100",
    },
    {
      name: "Fabricación de partes y piezas de carpintería para edificios y construcciones",
      value: "162200",
    },
    {
      name: "Fabricación de recipientes de madera",
      value: "162300",
    },
    {
      name: "Fabricación de otros productos de madera, de artículos de corcho, paja y materiales trenzables",
      value: "162900",
    },
    {
      name: "Fabricación de otros productos de madera, de artículos de corcho, paja y materiales trenzables",
      value: "162900",
    },
    {
      name: "Fabricación de celulosa y otras pastas de madera",
      value: "170110",
    },
    {
      name: "Fabricación de papel y cartón para su posterior uso industrial n.c.p.",
      value: "170190",
    },
    {
      name: "Fabricación de papel y cartón para su posterior uso industrial n.c.p.",
      value: "170190",
    },
    {
      name: "Fabricación de papel y cartón ondulado y de envases de papel y cartón",
      value: "170200",
    },
    {
      name: "Fabricación de otros artículos de papel y cartón",
      value: "170900",
    },
    {
      name: "Impresión de libros",
      value: "181101",
    },
    {
      name: "Otras actividades de impresión n.c.p.",
      value: "181109",
    },
    {
      name: "Actividades de servicios relacionadas con la impresión",
      value: "181200",
    },
    {
      name: "Reproducción de grabaciones",
      value: "182000",
    },
    {
      name: "Fabricación de productos de hornos de coque",
      value: "191000",
    },
    {
      name: "Fabricación de productos de la refinación del petróleo",
      value: "192000",
    },
    {
      name: "Fabricación de carbón vegetal (excepto activado); fabricación de briquetas de carbón vegetal",
      value: "201101",
    },
    {
      name: "Fabricación de otras sustancias químicas básicas n.c.p.",
      value: "201109",
    },
    {
      name: "Fabricación de abonos y compuestos de nitrógeno",
      value: "201200",
    },
    {
      name: "Fabricación de plásticos y caucho sintético en formas primarias",
      value: "201300",
    },
    {
      name: "Fabricación de plaguicidas y otros productos químicos de uso agropecuario",
      value: "202100",
    },
    {
      name: "Fabricación de pinturas, barnices y productos de revestimiento, tintas de imprenta y masillas",
      value: "202200",
    },
    {
      name: "Fabricación de jabones y detergentes, preparados para limpiar, perfumes y preparados de tocador",
      value: "202300",
    },
    {
      name: "Fabricación de explosivos y productos pirotécnicos",
      value: "202901",
    },
    {
      name: "Fabricación de otros productos químicos n.c.p.",
      value: "202909",
    },
    {
      name: "Fabricación de fibras artificiales",
      value: "203000",
    },
    {
      name: "Fabricación de productos farmacéuticos, sustancias químicas medicinales y productos botánicos",
      value: "210000",
    },
    {
      name: "Fabricación de cubiertas y cámaras de caucho; recauchutado y renovación de cubiertas de caucho",
      value: "221100",
    },
    {
      name: "Fabricación de otros productos de caucho",
      value: "221900",
    },
    {
      name: "Fabricación de productos de plástico",
      value: "222000",
    },
    {
      name: "Fabricación de vidrio plano",
      value: "231001",
    },
    {
      name: "Fabricación de vidrio hueco",
      value: "231002",
    },
    {
      name: "Fabricación de fibras de vidrio",
      value: "231003",
    },
    {
      name: "Fabricación de productos de vidrio n.c.p.",
      value: "231009",
    },
    {
      name: "Fabricación de productos refractarios",
      value: "239100",
    },
    {
      name: "Fabricación de materiales de construcción de arcilla",
      value: "239200",
    },
    {
      name: "Fabricación de otros productos de porcelana y de cerámica",
      value: "239300",
    },
    {
      name: "Fabricación de otros productos de porcelana y de cerámica",
      value: "239300",
    },
    {
      name: "Fabricación de cemento, cal y yeso",
      value: "239400",
    },
    {
      name: "Fabricación de artículos de hormigón, cemento y yeso",
      value: "239500",
    },
    {
      name: "Corte, talla y acabado de la piedra",
      value: "239600",
    },
    {
      name: "Fabricación de otros productos minerales no metálicos n.c.p.",
      value: "239900",
    },
    {
      name: "Industrias básicas de hierro y acero",
      value: "241000",
    },
    {
      name: "Fabricación de productos primarios de cobre",
      value: "242001",
    },
    {
      name: "Fabricación de productos primarios de aluminio",
      value: "242002",
    },
    {
      name: "Fabricación de productos primarios de metales preciosos y de otros metales no ferrosos n.c.p.",
      value: "242009",
    },
    {
      name: "Fabricación de productos primarios de metales preciosos y de otros metales no ferrosos n.c.p.",
      value: "242009",
    },
    {
      name: "Fundición de hierro y acero",
      value: "243100",
    },
    {
      name: "Fundición de metales no ferrosos",
      value: "243200",
    },
    {
      name: "Fabricación de productos metálicos para uso estructural",
      value: "251100",
    },
    {
      name: "Fabricación de recipientes de metal para gases comprimidos o licuados",
      value: "251201",
    },
    {
      name: "Fabricación de tanques, depósitos y recipientes de metal n.c.p.",
      value: "251209",
    },
    {
      name: "Fabricación de generadores de vapor, excepto calderas de agua caliente para calefacción central",
      value: "251300",
    },
    {
      name: "Fabricación de armas y municiones",
      value: "252000",
    },
    {
      name: "Forja, prensado, estampado y laminado de metales; pulvimetalurgia",
      value: "259100",
    },
    {
      name: "Tratamiento y revestimiento de metales; maquinado",
      value: "259200",
    },
    {
      name: "Fabricación de artículos de cuchillería, herramientas de mano y artículos de ferretería",
      value: "259300",
    },
    {
      name: "Fabricación de otros productos elaborados de metal n.c.p.",
      value: "259900",
    },
    {
      name: "Fabricación de otros productos elaborados de metal n.c.p.",
      value: "259900",
    },
    {
      name: "Fabricación de componentes y tableros electrónicos",
      value: "261000",
    },
    {
      name: "Fabricación de computadores y equipo periférico",
      value: "262000",
    },
    {
      name: "Fabricación de equipo de comunicaciones",
      value: "263000",
    },
    {
      name: "Fabricación de aparatos electrónicos de consumo",
      value: "264000",
    },
    {
      name: "Fabricación de equipo de medición, prueba, navegación y control",
      value: "265100",
    },
    {
      name: "Fabricación de equipo de medición, prueba, navegación y control",
      value: "265100",
    },
    {
      name: "Fabricación de relojes",
      value: "265200",
    },
    {
      name: "Fabricación de equipo de irradiación y equipo electrónico de uso médico y terapéutico",
      value: "266000",
    },
    {
      name: "Fabricación de instrumentos ópticos y equipo fotográfico",
      value: "267000",
    },
    {
      name: "Fabricación de instrumentos ópticos y equipo fotográfico",
      value: "267000",
    },
    {
      name: "Fabricación de soportes magnéticos y ópticos",
      value: "268000",
    },
    {
      name: "Fabricación de motores, generadores y transformadores eléctricos, aparatos de distribución y control",
      value: "271000",
    },
    {
      name: "Fabricación de pilas, baterías y acumuladores",
      value: "272000",
    },
    {
      name: "Fabricación de cables de fibra óptica",
      value: "273100",
    },
    {
      name: "Fabricación de otros hilos y cables eléctricos",
      value: "273200",
    },
    {
      name: "Fabricación de dispositivos de cableado",
      value: "273300",
    },
    {
      name: "Fabricación de equipo eléctrico de iluminación",
      value: "274000",
    },
    {
      name: "Fabricación de aparatos de uso doméstico",
      value: "275000",
    },
    {
      name: "Fabricación de otros tipos de equipo eléctrico",
      value: "279000",
    },
    {
      name: "Fabricación de motores y turbinas, excepto para aeronaves, vehículos automotores y motocicletas",
      value: "281100",
    },
    {
      name: "Fabricación de motores y turbinas, excepto para aeronaves, vehículos automotores y motocicletas",
      value: "281100",
    },
    {
      name: "Fabricación de equipo de propulsión de fluidos",
      value: "281200",
    },
    {
      name: "Fabricación de otras bombas, compresores, grifos y válvulas",
      value: "281300",
    },
    {
      name: "Fabricación de cojinetes, engranajes, trenes de engranajes y piezas de transmisión",
      value: "281400",
    },
    {
      name: "Fabricación de hornos, calderas y quemadores",
      value: "281500",
    },
    {
      name: "Fabricación de equipo de elevación y manipulación",
      value: "281600",
    },
    {
      name: "Fabricación de maquinaria y equipo de oficina (excepto computadores y equipo periférico)",
      value: "281700",
    },
    {
      name: "Fabricación de maquinaria y equipo de oficina (excepto computadores y equipo periférico)",
      value: "281700",
    },
    {
      name: "Fabricación de herramientas de mano motorizadas",
      value: "281800",
    },
    {
      name: "Fabricación de otros tipos de maquinaria de uso general",
      value: "281900",
    },
    {
      name: "Fabricación de maquinaria agropecuaria y forestal",
      value: "282100",
    },
    {
      name: "Fabricación de maquinaria para la conformación de metales y de máquinas herramienta",
      value: "282200",
    },
    {
      name: "Fabricación de maquinaria metalúrgica",
      value: "282300",
    },
    {
      name: "Fabricación de maquinaria para la explotación de minas y canteras y para obras de construcción",
      value: "282400",
    },
    {
      name: "Fabricación de maquinaria para la explotación de minas y canteras y para obras de construcción",
      value: "282400",
    },
    {
      name: "Fabricación de maquinaria para la elaboración de alimentos, bebidas y tabaco",
      value: "282500",
    },
    {
      name: "Fabricación de maquinaria para la elaboración de productos textiles, prendas de vestir y cueros",
      value: "282600",
    },
    {
      name: "Fabricación de otros tipos de maquinaria de uso especial",
      value: "282900",
    },
    {
      name: "Fabricación de otros tipos de maquinaria de uso especial",
      value: "282900",
    },
    {
      name: "Fabricación de vehículos automotores",
      value: "291000",
    },
    {
      name: "Fabricación de carrocerías para vehículos automotores; fabricación de remolques y semirremolques",
      value: "292000",
    },
    {
      name: "Fabricación de partes, piezas y accesorios para vehículos automotores",
      value: "293000",
    },
    {
      name: "Construcción de buques, embarcaciones menores y estructuras flotantes",
      value: "301100",
    },
    {
      name: "Construcción de embarcaciones de recreo y de deporte",
      value: "301200",
    },
    {
      name: "Fabricación de locomotoras y material rodante",
      value: "302000",
    },
    {
      name: "Fabricación de aeronaves, naves espaciales y maquinaria conexa",
      value: "303000",
    },
    {
      name: "Fabricación de aeronaves, naves espaciales y maquinaria conexa",
      value: "303000",
    },
    {
      name: "Fabricación de vehículos militares de combate",
      value: "304000",
    },
    {
      name: "Fabricación de motocicletas",
      value: "309100",
    },
    {
      name: "Fabricación de bicicletas y de sillas de ruedas",
      value: "309200",
    },
    {
      name: "Fabricación de otros tipos de equipo de transporte n.c.p.",
      value: "309900",
    },
    {
      name: "Fabricación de muebles principalmente de madera",
      value: "310001",
    },
    {
      name: "Fabricación de colchones; fabricación de otros muebles n.c.p.",
      value: "310009",
    },
    {
      name: "Fabricación de joyas y artículos conexos",
      value: "321100",
    },
    {
      name: "Fabricación de bisutería y artículos conexos",
      value: "321200",
    },
    {
      name: "Fabricación de instrumentos musicales",
      value: "322000",
    },
    {
      name: "Fabricación de artículos de deporte",
      value: "323000",
    },
    {
      name: "Fabricación de juegos y juguetes",
      value: "324000",
    },
    {
      name: "Actividades de laboratorios dentales",
      value: "325001",
    },
    {
      name: "Fabricación de instrumentos y materiales médicos, oftalmológicos y odontológicos n.c.p.",
      value: "325009",
    },
    {
      name: "Otras industrias manufactureras n.c.p.",
      value: "329000",
    },
    {
      name: "Reparación de productos elaborados de metal",
      value: "331100",
    },
    {
      name: "Reparación de maquinaria agropecuaria y forestal",
      value: "331201",
    },
    {
      name: "Reparación de maquinaria metalúrgica, para la minería, extracción de petróleo y para la construcción",
      value: "331202",
    },
    {
      name: "Reparación de maquinaria metalúrgica, para la minería, extracción de petróleo y para la construcción",
      value: "331202",
    },
    {
      name: "Reparación de maquinaria para la elaboración de alimentos, bebidas y tabaco",
      value: "331203",
    },
    {
      name: "Reparación de maquinaria para producir textiles, prendas de vestir, artículos de cuero y calzado",
      value: "331204",
    },
    {
      name: "Reparación de otro tipo de maquinaria y equipos industriales n.c.p.",
      value: "331209",
    },
    {
      name: "Reparación de otro tipo de maquinaria y equipos industriales n.c.p.",
      value: "331209",
    },
    {
      name: "Reparación de equipo de medición, prueba, navegación y control",
      value: "331301",
    },
    {
      name: "Reparación de otros equipos electrónicos y ópticos n.c.p.",
      value: "331309",
    },
    {
      name: "Reparación de otros equipos electrónicos y ópticos n.c.p.",
      value: "331309",
    },
    {
      name: "Reparación de equipo eléctrico (excepto reparación de equipo y enseres domésticos)",
      value: "331400",
    },
    {
      name: "Reparación de equipo eléctrico (excepto reparación de equipo y enseres domésticos)",
      value: "331400",
    },
    {
      name: "Reparación de buques, embarcaciones menores y estructuras flotantes",
      value: "331501",
    },
    {
      name: "Reparación de aeronaves y naves espaciales",
      value: "331502",
    },
    {
      name: "Reparación de otros equipos de transporte n.c.p., excepto vehículos automotores",
      value: "331509",
    },
    {
      name: "Reparación de otros equipos de transporte n.c.p., excepto vehículos automotores",
      value: "331509",
    },
    {
      name: "Reparación de otros tipos de equipo",
      value: "331900",
    },
    {
      name: "Instalación de maquinaria y equipos industriales",
      value: "332000",
    },
    {
      name: "Generación de energía eléctrica en centrales hidroeléctricas",
      value: "351011",
    },
    {
      name: "Generación de energía eléctrica en centrales termoeléctricas",
      value: "351012",
    },
    {
      name: "Generación de energía eléctrica en centrales termoeléctricas",
      value: "351012",
    },
    {
      name: "Generación de energía eléctrica en otras centrales n.c.p.",
      value: "351019",
    },
    {
      name: "Transmisión de energía eléctrica",
      value: "351020",
    },
    {
      name: "Distribución de energía eléctrica",
      value: "351030",
    },
    {
      name: "Regasificación de Gas Natural Licuado (GNL)",
      value: "352010",
    },
    {
      name: "Fabricación de gas; distribución de combustibles gaseosos por tubería, excepto regasificación de GNL",
      value: "352020",
    },
    {
      name: "Suministro de vapor y de aire acondicionado",
      value: "353001",
    },
    {
      name: "Elaboración de hielo (excepto fabricación de hielo seco)",
      value: "353002",
    },
    {
      name: "Captación, tratamiento y distribución de agua",
      value: "360000",
    },
    {
      name: "Evacuación y tratamiento de aguas servidas",
      value: "370000",
    },
    {
      name: "Recogida de desechos no peligrosos",
      value: "381100",
    },
    {
      name: "Recogida de desechos peligrosos",
      value: "381200",
    },
    {
      name: "Tratamiento y eliminación de desechos no peligrosos",
      value: "382100",
    },
    {
      name: "Tratamiento y eliminación de desechos peligrosos",
      value: "382200",
    },
    {
      name: "Recuperación y reciclamiento de desperdicios y desechos metálicos",
      value: "383001",
    },
    {
      name: "Recuperación y reciclamiento de papel",
      value: "383002",
    },
    {
      name: "Recuperación y reciclamiento de vidrio",
      value: "383003",
    },
    {
      name: "Recuperación y reciclamiento de otros desperdicios y desechos n.c.p.",
      value: "383009",
    },
    {
      name: "Actividades de descontaminación y otros servicios de gestión de desechos",
      value: "390000",
    },
    {
      name: "Construcción de edificios para uso residencial",
      value: "410010",
    },
    {
      name: "Construcción de edificios para uso no residencial",
      value: "410020",
    },
    {
      name: "Construcción de carreteras y líneas de ferrocarril",
      value: "421000",
    },
    {
      name: "Construcción de proyectos de servicio público",
      value: "422000",
    },
    {
      name: "Construcción de otras obras de ingeniería civil",
      value: "429000",
    },
    {
      name: "Demolición",
      value: "431100",
    },
    {
      name: "Preparación del terreno",
      value: "431200",
    },
    {
      name: "Instalaciones eléctricas",
      value: "432100",
    },
    {
      name: "Instalaciones de gasfitería, calefacción y aire acondicionado",
      value: "432200",
    },
    {
      name: "Otras instalaciones para obras de construcción",
      value: "432900",
    },
    {
      name: "Terminación y acabado de edificios",
      value: "433000",
    },
    {
      name: "Otras actividades especializadas de construcción",
      value: "439000",
    },
    {
      name: "Venta al por mayor de vehículos automotores",
      value: "451001",
    },
    {
      name: "Venta al por menor de vehículos automotores nuevos o usados (incluye compraventa)",
      value: "451002",
    },
    {
      name: "Servicio de lavado de vehículos automotores",
      value: "452001",
    },
    {
      name: "Mantenimiento y reparación de vehículos automotores",
      value: "452002",
    },
    {
      name: "Venta de partes, piezas y accesorios para vehículos automotores",
      value: "453000",
    },
    {
      name: "Venta de motocicletas",
      value: "454001",
    },
    {
      name: "Venta de partes, piezas y accesorios de motocicletas",
      value: "454002",
    },
    {
      name: "Mantenimiento y reparación de motocicletas",
      value: "454003",
    },
    {
      name: "Corretaje al por mayor de productos agrícolas",
      value: "461001",
    },
    {
      name: "Corretaje al por mayor de ganado",
      value: "461002",
    },
    {
      name: "Otros tipos de corretajes o remates al por mayor n.c.p.",
      value: "461009",
    },
    {
      name: "Venta al por mayor de materias primas agrícolas",
      value: "462010",
    },
    {
      name: "Venta al por mayor de animales vivos",
      value: "462020",
    },
    {
      name: "Venta al por mayor de otras materias primas agropecuarias n.c.p.",
      value: "462090",
    },
    {
      name: "Venta al por mayor de frutas y verduras",
      value: "463011",
    },
    {
      name: "Venta al por mayor de carne y productos cárnicos",
      value: "463012",
    },
    {
      name: "Venta al por mayor de productos del mar (pescados, mariscos y algas)",
      value: "463013",
    },
    {
      name: "Venta al por mayor de productos de confitería",
      value: "463014",
    },
    {
      name: "Venta al por mayor de huevos, lácteos, abarrotes y de otros alimentos n.c.p.",
      value: "463019",
    },
    {
      name: "Venta al por mayor de bebidas alcohólicas y no alcohólicas",
      value: "463020",
    },
    {
      name: "Venta al por mayor de tabaco",
      value: "463030",
    },
    {
      name: "Venta al por mayor de productos textiles, prendas de vestir y calzado",
      value: "464100",
    },
    {
      name: "Venta al por mayor de muebles, excepto muebles de oficina",
      value: "464901",
    },
    {
      name: "Venta al por mayor de artículos eléctricos y electrónicos para el hogar",
      value: "464902",
    },
    {
      name: "Venta al por mayor de artículos de perfumería, de tocador y cosméticos",
      value: "464903",
    },
    {
      name: "Venta al por mayor de artículos de papelería y escritorio",
      value: "464904",
    },
    {
      name: "Venta al por mayor de libros",
      value: "464905",
    },
    {
      name: "Venta al por mayor de diarios y revistas",
      value: "464906",
    },
    {
      name: "Venta al por mayor de productos farmacéuticos y medicinales",
      value: "464907",
    },
    {
      name: "Venta al por mayor de instrumentos científicos y quirúrgicos",
      value: "464908",
    },
    {
      name: "Venta al por mayor de instrumentos científicos y quirúrgicos",
      value: "464908",
    },
    {
      name: "Venta al por mayor de otros enseres domésticos n.c.p.",
      value: "464909",
    },
    {
      name: "Venta al por mayor de computadores, equipo periférico y programas informáticos",
      value: "465100",
    },
    {
      name: "Venta al por mayor de equipo, partes y piezas electrónicos y de telecomunicaciones",
      value: "465200",
    },
    {
      name: "Venta al por mayor de maquinaria, equipo y materiales agropecuarios",
      value: "465300",
    },
    {
      name: "Venta al por mayor de maquinaria metalúrgica, para la minería, extracción de petróleo y construcción",
      value: "465901",
    },
    {
      name: "Venta al por mayor de maquinaria para la elaboración de alimentos, bebidas y tabaco",
      value: "465902",
    },
    {
      name: "Venta al por mayor de maquinaria para la industria textil, del cuero y del calzado",
      value: "465903",
    },
    {
      name: "Venta al por mayor de maquinaria y equipo de oficina; venta al por mayor de muebles de oficina",
      value: "465904",
    },
    {
      name: "Venta al por mayor de equipo de transporte(excepto vehículos automotores, motocicletas y bicicletas)",
      value: "465905",
    },
    {
      name: "Venta al por mayor de otros tipos de maquinaria y equipo n.c.p.",
      value: "465909",
    },
    {
      name: "Venta al por mayor de combustibles sólidos, líquidos y gaseosos y productos conexos",
      value: "466100",
    },
    {
      name: "Venta al por mayor de combustibles sólidos, líquidos y gaseosos y productos conexos",
      value: "466100",
    },
    {
      name: "Venta al por mayor de metales y minerales metalíferos",
      value: "466200",
    },
    {
      name: "Venta al por mayor de madera en bruto y productos primarios de la elaboración de madera",
      value: "466301",
    },
    {
      name: "Venta al por mayor de materiales de construcción, artículos de ferretería, gasfitería y calefacción",
      value: "466302",
    },
    {
      name: "Venta al por mayor de materiales de construcción, artículos de ferretería, gasfitería y calefacción",
      value: "466302",
    },
    {
      name: "Venta al por mayor de productos químicos",
      value: "466901",
    },
    {
      name: "Venta al por mayor de desechos metálicos (chatarra)",
      value: "466902",
    },
    {
      name: "Venta al por mayor de desperdicios, desechos y otros productos n.c.p.",
      value: "466909",
    },
    {
      name: "Venta al por mayor no especializada",
      value: "469000",
    },
    {
      name: "Venta al por menor en comercios de alimentos, bebidas o tabaco (supermercados e hipermercados)",
      value: "471100",
    },
    {
      name: "Venta al por menor en comercios de alimentos, bebidas o tabaco (supermercados e hipermercados)",
      value: "471100",
    },
    {
      name: "Venta al por menor en comercios de vestuario y productos para el hogar (grandes tiendas)",
      value: "471910",
    },
    {
      name: "Otras actividades de venta al por menor en comercios no especializados n.c.p.",
      value: "471990",
    },
    {
      name: "Venta al por menor de alimentos en comercios especializados (almacenes pequeños y minimarket)",
      value: "472101",
    },
    {
      name: "Venta al por menor en comercios especializados de carne y productos cárnicos",
      value: "472102",
    },
    {
      name: "Venta al por menor en comercios especializados de frutas y verduras (verdulerías)",
      value: "472103",
    },
    {
      name: "Venta al por menor en comercios especializados de pescado, mariscos y productos conexos",
      value: "472104",
    },
    {
      name: "Venta al por menor en comercios especializados de productos de panadería y pastelería",
      value: "472105",
    },
    {
      name: "Venta al por menor en comercios especializados de huevos, confites y productos alimenticios n.c.p.",
      value: "472109",
    },
    {
      name: "Venta al por menor de bebidas alcohólicas y no alcohólicas en comercios especializados (botillerías)",
      value: "472200",
    },
    {
      name: "Venta al por menor de tabaco y productos de tabaco en comercios especializados",
      value: "472300",
    },
    {
      name: "Venta al por menor de combustibles para vehículos automotores en comercios especializados",
      value: "473000",
    },
    {
      name: "Venta al por menor de computadores, equipo periférico, programas informáticos y equipo de telecom.",
      value: "474100",
    },
    {
      name: "Venta al por menor de computadores, equipo periférico, programas informáticos y equipo de telecom.",
      value: "474100",
    },
    {
      name: "Venta al por menor de equipo de sonido y de video en comercios especializados",
      value: "474200",
    },
    {
      name: "Venta al por menor de telas, lanas, hilos y similares en comercios especializados",
      value: "475100",
    },
    {
      name: "Venta al por menor de artículos de ferretería y materiales de construcción",
      value: "475201",
    },
    {
      name: "Venta al por menor de pinturas, barnices y lacas en comercios especializados",
      value: "475202",
    },
    {
      name: "Venta al por menor de productos de vidrio en comercios especializados",
      value: "475203",
    },
    {
      name: "Venta al por menor de tapices, alfombras y cubrimientos para paredes y pisos",
      value: "475300",
    },
    {
      name: "Venta al por menor de tapices, alfombras y cubrimientos para paredes y pisos",
      value: "475300",
    },
    {
      name: "Venta al por menor de muebles y colchones en comercios especializados",
      value: "475901",
    },
    {
      name: "Venta al por menor de instrumentos musicales en comercios especializados",
      value: "475902",
    },
    {
      name: "Venta al por menor de aparatos eléctricos, textiles para el hogar y otros enseres domésticos n.c.p.",
      value: "475909",
    },
    {
      name: "Venta al por menor de libros en comercios especializados",
      value: "476101",
    },
    {
      name: "Venta al por menor de diarios y revistas en comercios especializados",
      value: "476102",
    },
    {
      name: "Venta al por menor de artículos de papelería y escritorio en comercios especializados",
      value: "476103",
    },
    {
      name: "Venta al por menor de grabaciones de música y de video en comercios especializados",
      value: "476200",
    },
    {
      name: "Venta al por menor de artículos de caza y pesca en comercios especializados",
      value: "476301",
    },
    {
      name: "Venta al por menor de bicicletas y sus repuestos en comercios especializados",
      value: "476302",
    },
    {
      name: "Venta al por menor de otros artículos y equipos de deporte n.c.p.",
      value: "476309",
    },
    {
      name: "Venta al por menor de juegos y juguetes en comercios especializados",
      value: "476400",
    },
    {
      name: "Venta al por menor de calzado en comercios especializados",
      value: "477101",
    },
    {
      name: "Venta al por menor de prendas y accesorios de vestir en comercios especializados",
      value: "477102",
    },
    {
      name: "Venta al por menor de carteras, maletas y otros accesorios de viaje en comercios especializados",
      value: "477103",
    },
    {
      name: "Venta al por menor de carteras, maletas y otros accesorios de viaje en comercios especializados",
      value: "477103",
    },
    {
      name: "Venta al por menor de productos farmacéuticos y medicinales en comercios especializados",
      value: "477201",
    },
    {
      name: "Venta al por menor de artículos ortopédicos en comercios especializados",
      value: "477202",
    },
    {
      name: "Venta al por menor de artículos de perfumería, de tocador y cosméticos en comercios especializados",
      value: "477203",
    },
    {
      name: "Venta al por menor de gas licuado en bombonas (cilindros) en comercios especializados",
      value: "477310",
    },
    {
      name: "Venta al por menor de alimento y accesorios para mascotas en comercios especializados",
      value: "477391",
    },
    {
      name: "Venta al por menor de armas y municiones en comercios especializados",
      value: "477392",
    },
    {
      name: "Venta al por menor de artículos ópticos en comercios especializados",
      value: "477393",
    },
    {
      name: "Venta al por menor de artículos de joyería, bisutería y relojería en comercios especializados",
      value: "477394",
    },
    {
      name: "Venta al por menor de carbón, leña y otros combustibles de uso doméstico en comercios especializados",
      value: "477395",
    },
    {
      name: "Venta al por menor de recuerdos, artesanías y artículos religiosos en comercios especializados",
      value: "477396",
    },
    {
      name: "Venta al por menor de flores, plantas, arboles, semillas y abonos en comercios especializados",
      value: "477397",
    },
    {
      name: "Venta al por menor de mascotas en comercios especializados",
      value: "477398",
    },
    {
      name: "Venta al por menor de otros productos en comercios especializados n.c.p.",
      value: "477399",
    },
    {
      name: "Venta al por menor de antigüedades en comercios",
      value: "477401",
    },
    {
      name: "Venta al por menor de ropa usada en comercios",
      value: "477402",
    },
    {
      name: "Venta al por menor de otros artículos de segunda mano en comercios n.c.p.",
      value: "477409",
    },
    {
      name: "Venta al por menor de alimentos, bebidas y tabaco en puestos de venta y mercados (incluye ferias)",
      value: "478100",
    },
    {
      name: "Venta al por menor de productos textiles, prendas de vestir y calzado en puestos de venta y mercados",
      value: "478200",
    },
    {
      name: "Venta al por menor de otros productos en puestos de venta y mercados (incluye ferias)",
      value: "478900",
    },
    {
      name: "Venta al por menor por correo, por Internet y vía telefónica",
      value: "479100",
    },
    {
      name: "Venta al por menor por correo, por Internet y vía telefónica",
      value: "479100",
    },
    {
      name: "Venta al por menor realizada por independientes en la locomoción colectiva (Ley 20.388)",
      value: "479901",
    },
    {
      name: "Venta al por menor mediante máquinas expendedoras",
      value: "479902",
    },
    {
      name: "Venta al por menor por comisionistas (no dependientes de comercios)",
      value: "479903",
    },
    {
      name: "Otras actividades de venta por menor no realizadas en comercios, puestos de venta o mercados n.c.p.",
      value: "479909",
    },
    {
      name: "Transporte interurbano de pasajeros por ferrocarril",
      value: "491100",
    },
    {
      name: "Transporte de carga por ferrocarril",
      value: "491200",
    },
    {
      name: "Transporte urbano y suburbano de pasajeros vía metro y metrotren",
      value: "492110",
    },
    {
      name: "Transporte urbano y suburbano de pasajeros vía locomoción colectiva",
      value: "492120",
    },
    {
      name: "Transporte de pasajeros vía taxi colectivo",
      value: "492130",
    },
    {
      name: "Otras actividades de transporte urbano y suburbano de pasajeros por vía terrestre n.c.p.",
      value: "492190",
    },
    {
      name: "Servicios de transporte de escolares",
      value: "492210",
    },
    {
      name: "Servicios de transporte de trabajadores",
      value: "492220",
    },
    {
      name: "Servicios de transporte de pasajeros en taxis libres y radiotaxis",
      value: "492230",
    },
    {
      name: "Servicios de transporte a turistas",
      value: "492240",
    },
    {
      name: "Transporte de pasajeros en buses interurbanos",
      value: "492250",
    },
    {
      name: "Otras actividades de transporte de pasajeros por vía terrestre n.c.p.",
      value: "492290",
    },
    {
      name: "Otras actividades de transporte de pasajeros por vía terrestre n.c.p.",
      value: "492290",
    },
    {
      name: "Transporte de carga por carretera",
      value: "492300",
    },
    {
      name: "Transporte por oleoductos",
      value: "493010",
    },
    {
      name: "Transporte por gasoductos",
      value: "493020",
    },
    {
      name: "Otras actividades de transporte por tuberías n.c.p.",
      value: "493090",
    },
    {
      name: "Transporte de pasajeros marítimo y de cabotaje",
      value: "501100",
    },
    {
      name: "Transporte de carga marítimo y de cabotaje",
      value: "501200",
    },
    {
      name: "Transporte de pasajeros por vías de navegación interiores",
      value: "502100",
    },
    {
      name: "Transporte de carga por vías de navegación interiores",
      value: "502200",
    },
    {
      name: "Transporte de pasajeros por vía aérea",
      value: "511000",
    },
    {
      name: "Transporte de carga por vía aérea",
      value: "512000",
    },
    {
      name: "Explotación de frigoríficos para almacenamiento y depósito",
      value: "521001",
    },
    {
      name: "Otros servicios de almacenamiento y depósito n.c.p.",
      value: "521009",
    },
    {
      name: "Explotación de terminales terrestres de pasajeros",
      value: "522110",
    },
    {
      name: "Explotación de estacionamientos de vehículos automotores y parquímetros",
      value: "522120",
    },
    {
      name: "Servicios prestados por concesionarios de carreteras",
      value: "522130",
    },
    {
      name: "Actividades de servicios vinculadas al transporte terrestre n.c.p.",
      value: "522190",
    },
    {
      name: "Actividades de servicios vinculadas al transporte acuático",
      value: "522200",
    },
    {
      name: "Actividades de servicios vinculadas al transporte aéreo",
      value: "522300",
    },
    {
      name: "Actividades de servicios vinculadas al transporte aéreo",
      value: "522300",
    },
    {
      name: "Manipulación de la carga",
      value: "522400",
    },
    {
      name: "Agencias de aduanas",
      value: "522910",
    },
    {
      name: "Agencias de naves",
      value: "522920",
    },
    {
      name: "Otras actividades de apoyo al transporte n.c.p.",
      value: "522990",
    },
    {
      name: "Actividades postales",
      value: "531000",
    },
    {
      name: "Actividades de mensajería",
      value: "532000",
    },
    {
      name: "Actividades de hoteles",
      value: "551001",
    },
    {
      name: "Actividades de moteles",
      value: "551002",
    },
    {
      name: "Actividades de residenciales para turistas",
      value: "551003",
    },
    {
      name: "Otras actividades de alojamiento para turistas n.c.p.",
      value: "551009",
    },
    {
      name: "Actividades de camping y de parques para casas rodantes",
      value: "552000",
    },
    {
      name: "Actividades de residenciales para estudiantes y trabajadores",
      value: "559001",
    },
    {
      name: "Otras actividades de alojamiento n.c.p.",
      value: "559009",
    },
    {
      name: "Actividades de restaurantes y de servicio móvil de comidas",
      value: "561000",
    },
    {
      name: "Suministro de comidas por encargo (Servicios de banquetería)",
      value: "562100",
    },
    {
      name: "Suministro industrial de comidas por encargo; concesión de servicios de alimentación",
      value: "562900",
    },
    {
      name: "Actividades de discotecas y cabaret (night club), con predominio del servicio de bebidas",
      value: "563001",
    },
    {
      name: "Otras actividades de servicio de bebidas n.c.p.",
      value: "563009",
    },
    {
      name: "Edición de libros",
      value: "581100",
    },
    {
      name: "Edición de directorios y listas de correo",
      value: "581200",
    },
    {
      name: "Edición de diarios, revistas y otras publicaciones periódicas",
      value: "581300",
    },
    {
      name: "Otras actividades de edición",
      value: "581900",
    },
    {
      name: "Edición de programas informáticos",
      value: "582000",
    },
    {
      name: "Actividades de producción de películas cinematográficas, videos y programas de televisión",
      value: "591100",
    },
    {
      name: "Actividades de producción de películas cinematográficas, videos y programas de televisión",
      value: "591100",
    },
    {
      name: "Actividades de postproducción de películas cinematográficas, videos y programas de televisión",
      value: "591200",
    },
    {
      name: "Actividades de distribución de películas cinematográficas, videos y programas de televisión",
      value: "591300",
    },
    {
      name: "Actividades de exhibición de películas cinematográficas y cintas de video",
      value: "591400",
    },
    {
      name: "Actividades de grabación de sonido y edición de música",
      value: "592000",
    },
    {
      name: "Transmisiones de radio",
      value: "601000",
    },
    {
      name: "Programación y transmisiones de televisión",
      value: "602000",
    },
    {
      name: "Telefonía fija",
      value: "611010",
    },
    {
      name: "Telefonía larga distancia",
      value: "611020",
    },
    {
      name: "Televisión de pago por cable",
      value: "611030",
    },
    {
      name: "Otros servicios de telecomunicaciones alámbricas n.c.p.",
      value: "611090",
    },
    {
      name: "Telefonía móvil celular",
      value: "612010",
    },
    {
      name: "Radiocomunicaciones móviles",
      value: "612020",
    },
    {
      name: "Televisión de pago inalámbrica",
      value: "612030",
    },
    {
      name: "Otros servicios de telecomunicaciones inalámbricas n.c.p.",
      value: "612090",
    },
    {
      name: "Telefonía móvil satelital",
      value: "613010",
    },
    {
      name: "Televisión de pago satelital",
      value: "613020",
    },
    {
      name: "Otros servicios de telecomunicaciones por satélite n.c.p.",
      value: "613090",
    },
    {
      name: "Centros de llamados y centros de acceso a Internet",
      value: "619010",
    },
    {
      name: "Otras actividades de telecomunicaciones n.c.p.",
      value: "619090",
    },
    {
      name: "Actividades de programación informática",
      value: "620100",
    },
    {
      name: "Actividades de consultoría de informática y de gestión de instalaciones informáticas",
      value: "620200",
    },
    {
      name: "Otras actividades de tecnología de la información y de servicios informáticos",
      value: "620900",
    },
    {
      name: "Procesamiento de datos, hospedaje y actividades conexas",
      value: "631100",
    },
    {
      name: "Portales web",
      value: "631200",
    },
    {
      name: "Actividades de agencias de noticias",
      value: "639100",
    },
    {
      name: "Otras actividades de servicios de información n.c.p.",
      value: "639900",
    },
    {
      name: "Banca central",
      value: "641100",
    },
    {
      name: "Actividades bancarias",
      value: "641910",
    },
    {
      name: "Otros tipos de intermediación monetaria n.c.p.",
      value: "641990",
    },
    {
      name: "Actividades de sociedades de cartera",
      value: "642000",
    },
    {
      name: "Fondos y sociedades de inversión y entidades financieras similares",
      value: "643000",
    },
    {
      name: "Leasing financiero",
      value: "649100",
    },
    {
      name: "Financieras",
      value: "649201",
    },
    {
      name: "Actividades de crédito prendario",
      value: "649202",
    },
    {
      name: "Cajas de compensación",
      value: "649203",
    },
    {
      name: "Otras actividades de concesión de crédito n.c.p.",
      value: "649209",
    },
    {
      name: "Otras actividades de servicios financieros, excepto las de seguros y fondos de pensiones n.c.p.",
      value: "649900",
    },
    {
      name: "Seguros de vida",
      value: "651100",
    },
    {
      name: "Seguros generales, excepto actividades de Isapres",
      value: "651210",
    },
    {
      name: "Actividades de Isapres",
      value: "651220",
    },
    {
      name: "Reaseguros",
      value: "652000",
    },
    {
      name: "Fondos de pensiones",
      value: "653000",
    },
    {
      name: "Administración de mercados financieros",
      value: "661100",
    },
    {
      name: "Actividades de securitizadoras",
      value: "661201",
    },
    {
      name: "Corredores de bolsa",
      value: "661202",
    },
    {
      name: "Agentes de valores",
      value: "661203",
    },
    {
      name: "Actividades de casas de cambio y operadores de divisa",
      value: "661204",
    },
    {
      name: "Otros servicios de corretaje de valores y commodities n.c.p.",
      value: "661209",
    },
    {
      name: "Actividades de cámaras de compensación",
      value: "661901",
    },
    {
      name: "Administración de tarjetas de crédito",
      value: "661902",
    },
    {
      name: "Empresas de asesoría y consultoría en inversión financiera; sociedades de apoyo al giro",
      value: "661903",
    },
    {
      name: "Actividades de clasificadoras de riesgo",
      value: "661904",
    },
    {
      name: "Otras actividades auxiliares de las actividades de servicios financieros n.c.p.",
      value: "661909",
    },
    {
      name: "Evaluación de riesgos y daños (incluye actividades de liquidadores de seguros)",
      value: "662100",
    },
    {
      name: "Actividades de agentes y corredores de seguros",
      value: "662200",
    },
    {
      name: "Otras actividades auxiliares de las actividades de seguros y fondos de pensiones",
      value: "662900",
    },
    {
      name: "Administradoras de Fondos de Pensiones (AFP)",
      value: "663010",
    },
    {
      name: "Administradoras de fondos de inversión",
      value: "663091",
    },
    {
      name: "Administradoras de fondos mutuos",
      value: "663092",
    },
    {
      name: "Administradoras de fices (fondos de inversión de capital extranjero)",
      value: "663093",
    },
    {
      name: "Administradoras de fondos para la vivienda",
      value: "663094",
    },
    {
      name: "Administradoras de fondos para otros fines n.c.p.",
      value: "663099",
    },
    {
      name: "Alquiler de bienes inmuebles amoblados o con equipos y maquinarias",
      value: "681011",
    },
    {
      name: "Compra, venta y alquiler (excepto amoblados) de inmuebles",
      value: "681012",
    },
    {
      name: "Servicios imputados de alquiler de viviendas",
      value: "681020",
    },
    {
      name: "Actividades inmobiliarias realizadas a cambio de una retribución o por contrata",
      value: "682000",
    },
    {
      name: "Servicios de asesoramiento y representación jurídica",
      value: "691001",
    },
    {
      name: "Servicio notarial",
      value: "691002",
    },
    {
      name: "Conservador de bienes raíces",
      value: "691003",
    },
    {
      name: "Receptores judiciales",
      value: "691004",
    },
    {
      name: "Servicios de arbitraje; síndicos de quiebra y peritos judiciales; otras actividades jurídicas n.c.p.",
      value: "691009",
    },
    {
      name: "Actividades de contabilidad, teneduría de libros y auditoría; consultoría fiscal",
      value: "692000",
    },
    {
      name: "Actividades de oficinas principales",
      value: "701000",
    },
    {
      name: "Actividades de consultoría de gestión",
      value: "702000",
    },
    {
      name: "Servicios de arquitectura (diseño de edificios, dibujo de planos de construcción, entre otros)",
      value: "711001",
    },
    {
      name: "Empresas de servicios de ingeniería y actividades conexas de consultoría técnica",
      value: "711002",
    },
    {
      name: "Empresas de servicios de ingeniería y actividades conexas de consultoría técnica",
      value: "711002",
    },
    {
      name: "Servicios profesionales de ingeniería y actividades conexas de consultoría técnica",
      value: "711003",
    },
    {
      name: "Actividades de plantas de revisión técnica para vehículos automotores",
      value: "712001",
    },
    {
      name: "Otros servicios de ensayos y análisis técnicos (excepto actividades de plantas de revisión técnica)",
      value: "712009",
    },
    {
      name: "Investigaciones y desarrollo experimental en el campo de las ciencias naturales y la ingeniería",
      value: "721000",
    },
    {
      name: "Investigaciones y desarrollo experimental en el campo de las ciencias sociales y las humanidades",
      value: "722000",
    },
    {
      name: "Servicios de publicidad prestados por empresas",
      value: "731001",
    },
    {
      name: "Servicios de publicidad prestados por profesionales",
      value: "731002",
    },
    {
      name: "Estudios de mercado y encuestas de opinión pública",
      value: "732000",
    },
    {
      name: "Actividades de diseño de vestuario",
      value: "741001",
    },
    {
      name: "Actividades de diseño y decoración de interiores",
      value: "741002",
    },
    {
      name: "Otras actividades especializadas de diseño n.c.p.",
      value: "741009",
    },
    {
      name: "Servicios de revelado, impresión y ampliación de fotografías",
      value: "742001",
    },
    {
      name: "Servicios y actividades de fotografía",
      value: "742002",
    },
    {
      name: "Servicios personales de fotografía",
      value: "742003",
    },
    {
      name: "Asesoría y gestión en la compra o venta de pequeñas y medianas empresas",
      value: "749001",
    },
    {
      name: "Servicios de traducción e interpretación prestados por empresas",
      value: "749002",
    },
    {
      name: "Servicios personales de traducción e interpretación",
      value: "749003",
    },
    {
      name: "Actividades de agencias y agentes de representación de actores, deportistas y otras figuras públicas",
      value: "749004",
    },
    {
      name: "Otras actividades profesionales, científicas y técnicas n.c.p.",
      value: "749009",
    },
    {
      name: "Actividades de clínicas veterinarias",
      value: "750001",
    },
    {
      name: "Actividades de veterinarios, técnicos y otro personal auxiliar, prestados de forma independiente",
      value: "750002",
    },
    {
      name: "Alquiler de vehículos automotores sin chofer",
      value: "771000",
    },
    {
      name: "Alquiler y arrendamiento de equipo recreativo y deportivo",
      value: "772100",
    },
    {
      name: "Alquiler de cintas de video y discos",
      value: "772200",
    },
    {
      name: "Alquiler de otros efectos personales y enseres domésticos (incluye mobiliario para eventos)",
      value: "772900",
    },
    {
      name: "Alquiler de equipos de transporte sin operario, excepto vehículos automotores",
      value: "773001",
    },
    {
      name: "Alquiler de maquinaria y equipo agropecuario, forestal, de construcción e ing. civil, sin operarios",
      value: "773002",
    },
    {
      name: "Alquiler de maquinaria y equipo de oficina, sin operarios (sin servicio administrativo)",
      value: "773003",
    },
    {
      name: "Alquiler de otros tipos de maquinarias y equipos sin operario n.c.p.",
      value: "773009",
    },
    {
      name: "Arrendamiento de propiedad intelectual y similares, excepto obras protegidas por derechos de autor",
      value: "774000",
    },
    {
      name: "Actividades de agencias de empleo",
      value: "781000",
    },
    {
      name: "Actividades de agencias de empleo temporal (incluye empresas de servicios transitorios)",
      value: "782000",
    },
    {
      name: "Otras actividades de dotación de recursos humanos",
      value: "783000",
    },
    {
      name: "Actividades de agencias de viajes",
      value: "791100",
    },
    {
      name: "Actividades de operadores turísticos",
      value: "791200",
    },
    {
      name: "Otros servicios de reservas y actividades conexas (incluye venta de entradas para teatro, y otros)",
      value: "799000",
    },
    {
      name: "Servicios de seguridad privada prestados por empresas",
      value: "801001",
    },
    {
      name: "Servicio de transporte de valores en vehículos blindados",
      value: "801002",
    },
    {
      name: "Servicios de seguridad privada prestados por independientes",
      value: "801003",
    },
    {
      name: "Actividades de servicios de sistemas de seguridad (incluye servicios de cerrajería)",
      value: "802000",
    },
    {
      name: "Actividades de investigación (incluye actividades de investigadores y detectives privados)",
      value: "803000",
    },
    {
      name: "Actividades combinadas de apoyo a instalaciones",
      value: "811000",
    },
    {
      name: "Limpieza general de edificios",
      value: "812100",
    },
    {
      name: "Desratización, desinfección y exterminio de plagas no agrícolas",
      value: "812901",
    },
    {
      name: "Otras actividades de limpieza de edificios e instalaciones industriales n.c.p.",
      value: "812909",
    },
    {
      name: "Actividades de paisajismo, servicios de jardinería y servicios conexos",
      value: "813000",
    },
    {
      name: "Actividades de paisajismo, servicios de jardinería y servicios conexos",
      value: "813000",
    },
    {
      name: "Actividades combinadas de servicios administrativos de oficina",
      value: "821100",
    },
    {
      name: "Fotocopiado, preparación de documentos y otras actividades especializadas de apoyo de oficina",
      value: "821900",
    },
    {
      name: "Actividades de call-center",
      value: "822000",
    },
    {
      name: "Organización de convenciones y exposiciones comerciales",
      value: "823000",
    },
    {
      name: "Actividades de agencias de cobro",
      value: "829110",
    },
    {
      name: "Actividades de agencias de calificación crediticia",
      value: "829120",
    },
    {
      name: "Actividades de envasado y empaquetado",
      value: "829200",
    },
    {
      name: "Otras actividades de servicios de apoyo a las empresas n.c.p.",
      value: "829900",
    },
    {
      name: "Actividades de la administración pública en general",
      value: "841100",
    },
    {
      name: "Regulación de las actividades de organismos que prestan servicios sanitarios, educativos, culturales",
      value: "841200",
    },
    {
      name: "Regulación y facilitación de la actividad económica",
      value: "841300",
    },
    {
      name: "Relaciones exteriores",
      value: "842100",
    },
    {
      name: "Actividades de defensa",
      value: "842200",
    },
    {
      name: "Actividades de mantenimiento del orden público y de seguridad",
      value: "842300",
    },
    {
      name: "Fondo Nacional de Salud (FONASA)",
      value: "843010",
    },
    {
      name: "Instituto de Previsión Social (IPS)",
      value: "843020",
    },
    {
      name: "Otros planes de seguridad social de afiliación obligatoria n.c.p.",
      value: "843090",
    },
    {
      name: "Enseñanza preescolar pública",
      value: "850011",
    },
    {
      name: "Enseñanza primaria, secundaria científico humanista y técnico profesional pública",
      value: "850012",
    },
    {
      name: "Enseñanza primaria, secundaria científico humanista y técnico profesional pública",
      value: "850012",
    },
    {
      name: "Enseñanza preescolar privada",
      value: "850021",
    },
    {
      name: "Enseñanza primaria, secundaria científico humanista y técnico profesional privada",
      value: "850022",
    },
    {
      name: "Enseñanza superior en universidades públicas",
      value: "853110",
    },
    {
      name: "Enseñanza superior en universidades privadas",
      value: "853120",
    },
    {
      name: "Enseñanza superior en institutos profesionales",
      value: "853201",
    },
    {
      name: "Enseñanza superior en centros de formación técnica",
      value: "853202",
    },
    {
      name: "Enseñanza deportiva y recreativa",
      value: "854100",
    },
    {
      name: "Enseñanza cultural",
      value: "854200",
    },
    {
      name: "Enseñanza preuniversitaria",
      value: "854901",
    },
    {
      name: "Servicios personales de educación",
      value: "854902",
    },
    {
      name: "Otros tipos de enseñanza n.c.p.",
      value: "854909",
    },
    {
      name: "Actividades de apoyo a la enseñanza",
      value: "855000",
    },
    {
      name: "Actividades de hospitales y clínicas públicas",
      value: "861010",
    },
    {
      name: "Actividades de hospitales y clínicas privadas",
      value: "861020",
    },
    {
      name: "Actividades de centros de salud municipalizados (servicios de salud pública)",
      value: "862010",
    },
    {
      name: "Centros médicos privados (establecimientos de atención ambulatoria)",
      value: "862021",
    },
    {
      name: "Centros de atención odontológica privados (establecimientos de atención ambulatoria)",
      value: "862022",
    },
    {
      name: "Servicios de médicos prestados de forma independiente",
      value: "862031",
    },
    {
      name: "Servicios de odontólogos prestados de forma independiente",
      value: "862032",
    },
    {
      name: "Actividades de laboratorios clínicos y bancos de sangre",
      value: "869010",
    },
    {
      name: "Otros servicios de atención de la salud humana prestados por empresas",
      value: "869091",
    },
    {
      name: "Servicios prestados de forma independiente por otros profesionales de la salud",
      value: "869092",
    },
    {
      name: "Actividades de atención de enfermería en instituciones",
      value: "871000",
    },
    {
      name: "Actividades de atención en instituciones para personas con discapacidad mental y toxicómanos",
      value: "872000",
    },
    {
      name: "Actividades de atención en instituciones para personas de edad y personas con discapacidad física",
      value: "873000",
    },
    {
      name: "Otras actividades de atención en instituciones",
      value: "879000",
    },
    {
      name: "Actividades de asistencia social sin alojamiento para personas de edad y personas con discapacidad",
      value: "881000",
    },
    {
      name: "Otras actividades de asistencia social sin alojamiento",
      value: "889000",
    },
    {
      name: "Servicios de producción de obras de teatro, conciertos, espectáculos de danza, otras prod. escénicas",
      value: "900001",
    },
    {
      name: "Servicios de producción de obras de teatro, conciertos, espectáculos de danza, otras prod. escénicas",
      value: "900001",
    },
    {
      name: "Servicios de producción de obras de teatro, conciertos, espectáculos de danza, otras prod. escénicas",
      value: "900001",
    },
    {
      name: "Actividades artísticas realizadas por bandas de música, compañías de teatro, circenses y similares",
      value: "900002",
    },
    {
      name: "Actividades de artistas realizadas de forma independiente: actores, músicos, escritores, entre otros",
      value: "900003",
    },
    {
      name: "Servicios prestados por periodistas independientes",
      value: "900004",
    },
    {
      name: "Otras actividades creativas, artísticas y de entretenimiento n.c.p.",
      value: "900009",
    },
    {
      name: "Otras actividades creativas, artísticas y de entretenimiento n.c.p.",
      value: "900009",
    },
    {
      name: "Actividades de bibliotecas y archivos",
      value: "910100",
    },
    {
      name: "Actividades de museos, gestión de lugares y edificios históricos",
      value: "910200",
    },
    {
      name: "Actividades de jardines botánicos, zoológicos y reservas naturales",
      value: "910300",
    },
    {
      name: "Actividades de casinos de juegos",
      value: "920010",
    },
    {
      name: "Otras actividades de juegos de azar y apuestas n.c.p.",
      value: "920090",
    },
    {
      name: "Hipódromos",
      value: "931101",
    },
    {
      name: "Gestión de salas de billar; gestión de salas de bolos (bowling)",
      value: "931102",
    },
    {
      name: "Gestión de otras instalaciones deportivas n.c.p.",
      value: "931109",
    },
    {
      name: "Actividades de clubes de fútbol amateur y profesional",
      value: "931201",
    },
    {
      name: "Actividades de otros clubes deportivos n.c.p.",
      value: "931209",
    },
    {
      name: "Promoción y organización de competencias deportivas",
      value: "931901",
    },
    {
      name: "Otras actividades deportivas n.c.p.",
      value: "931909",
    },
    {
      name: "Actividades de parques de atracciones y parques temáticos",
      value: "932100",
    },
    {
      name: "Gestión de salas de pool; gestión (explotación) de juegos electrónicos",
      value: "932901",
    },
    {
      name: "Otras actividades de esparcimiento y recreativas n.c.p.",
      value: "932909",
    },
    {
      name: "Actividades de asociaciones empresariales y de empleadores",
      value: "941100",
    },
    {
      name: "Actividades de asociaciones profesionales",
      value: "941200",
    },
    {
      name: "Actividades de sindicatos",
      value: "942000",
    },
    {
      name: "Actividades de organizaciones religiosas",
      value: "949100",
    },
    {
      name: "Actividades de organizaciones políticas",
      value: "949200",
    },
    {
      name: "Actividades de centros de madres",
      value: "949901",
    },
    {
      name: "Actividades de clubes sociales",
      value: "949902",
    },
    {
      name: "Fundaciones y corporaciones; asociaciones que promueven actividades culturales o recreativas",
      value: "949903",
    },
    {
      name: "Consejo de administración de edificios y condominios",
      value: "949904",
    },
    {
      name: "Actividades de otras asociaciones n.c.p.",
      value: "949909",
    },
    {
      name: "Reparación de computadores y equipo periférico",
      value: "951100",
    },
    {
      name: "Reparación de equipo de comunicaciones (incluye la reparación teléfonos celulares)",
      value: "951200",
    },
    {
      name: "Reparación de aparatos electrónicos de consumo (incluye aparatos de televisión y radio)",
      value: "952100",
    },
    {
      name: "Reparación de aparatos de uso doméstico, equipo doméstico y de jardinería",
      value: "952200",
    },
    {
      name: "Reparación de calzado y de artículos de cuero",
      value: "952300",
    },
    {
      name: "Reparación de muebles y accesorios domésticos",
      value: "952400",
    },
    {
      name: "Reparación de otros efectos personales y enseres domésticos",
      value: "952900",
    },
    {
      name: "Lavado y limpieza, incluida la limpieza en seco, de productos textiles y de piel",
      value: "960100",
    },
    {
      name: "Peluquería y otros tratamientos de belleza",
      value: "960200",
    },
    {
      name: "Servicios funerarios",
      value: "960310",
    },
    {
      name: "Servicios de cementerios",
      value: "960320",
    },
    {
      name: "Servicios de adiestramiento, guardería, peluquería, paseo de mascotas (excepto act. veterinarias)",
      value: "960901",
    },
    {
      name: "Actividades de salones de masajes, baños turcos, saunas, servicio de baños públicos",
      value: "960902",
    },
    {
      name: "Otras actividades de servicios personales n.c.p.",
      value: "960909",
    },
    {
      name: "Actividades de los hogares como empleadores de personal doméstico",
      value: "970000",
    },
    {
      name: "Actividades de organizaciones y órganos extraterritoriales",
      value: "990000",
    },
  ],
  [CatalogsEnum.Departments]: [
    {
      name: "TARAPACA",
      value: "01",
    },
    {
      name: "ANTOFAGASTA",
      value: "02",
    },
    {
      name: "ATACAMA",
      value: "03",
    },
    {
      name: "COQUIMBO",
      value: "04",
    },
    {
      name: "VALPARAÍSO",
      value: "05",
    },
    {
      name: "LIB. GRAL. BERNARDO O'HIGGINS",
      value: "06",
    },
    {
      name: "MAULE",
      value: "07",
    },
    {
      name: "BIOBÍO",
      value: "08",
    },
    {
      name: "LA ARAUCANÍA",
      value: "09",
    },
    {
      name: "LOS LAGOS",
      value: "10",
    },
    {
      name: "AYSÉN DEL GRAL. C. IBÁÑEZ DEL CAMPO",
      value: "11",
    },
    {
      name: "MAGALLANES Y ANTÁRTICA CHILENA",
      value: "12",
    },
    {
      name: "METROPOLITANA DE SANTIAGO",
      value: "13",
    },
    {
      name: "LOS RÍOS",
      value: "14",
    },
    {
      name: "ARICA Y PARINACOTA",
      value: "15",
    },
    {
      name: "ÑUBLE",
      value: "16",
    },
  ],
  [CatalogsEnum.Provinces]: [
    {
      name: "Iquique",
      parent: "01",
      value: "0110",
    },
    {
      name: "Tamarugal",
      parent: "01",
      value: "0140",
    },
    {
      name: "Antofagasta",
      parent: "02",
      value: "0210",
    },
    {
      name: "El Loa",
      parent: "02",
      value: "0220",
    },
    {
      name: "Tocopilla",
      parent: "02",
      value: "0230",
    },
    {
      name: "Copiapó",
      parent: "03",
      value: "0310",
    },
    {
      name: "Chañaral",
      parent: "03",
      value: "0320",
    },
    {
      name: "Huasco",
      parent: "03",
      value: "0330",
    },
    {
      name: "Elqui",
      parent: "04",
      value: "0410",
    },
    {
      name: "Choapa",
      parent: "04",
      value: "0420",
    },
    {
      name: "Limarí",
      parent: "04",
      value: "0430",
    },
    {
      name: "Valparaíso",
      parent: "05",
      value: "0510",
    },
    {
      name: "Isla de Pascua",
      parent: "05",
      value: "0520",
    },
    {
      name: "Los Andes",
      parent: "05",
      value: "0530",
    },
    {
      name: "Petorca",
      parent: "05",
      value: "0540",
    },
    {
      name: "Quillota",
      parent: "05",
      value: "0550",
    },
    {
      name: "San Antonio",
      parent: "05",
      value: "0560",
    },
    {
      name: "San Felipe de Aconcagua",
      parent: "05",
      value: "0570",
    },
    {
      name: "Marga Marga",
      parent: "05",
      value: "0580",
    },
    {
      name: "Cachapoal",
      parent: "06",
      value: "0610",
    },
    {
      name: "Cardenal Caro",
      parent: "06",
      value: "0620",
    },
    {
      name: "Colchagua",
      parent: "06",
      value: "0630",
    },
    {
      name: "Talca",
      parent: "07",
      value: "0710",
    },
    {
      name: "Cauquenes",
      parent: "07",
      value: "0720",
    },
    {
      name: "Curicó",
      parent: "07",
      value: "0730",
    },
    {
      name: "Linares",
      parent: "07",
      value: "0740",
    },
    {
      name: "Concepción",
      parent: "08",
      value: "0810",
    },
    {
      name: "Arauco",
      parent: "08",
      value: "0820",
    },
    {
      name: "Biobío",
      parent: "08",
      value: "0830",
    },
    {
      name: "Cautín",
      parent: "09",
      value: "0910",
    },
    {
      name: "Malleco",
      parent: "09",
      value: "0920",
    },
    {
      name: "Llanquihue",
      parent: "10",
      value: "1010",
    },
    {
      name: "Chiloé",
      parent: "10",
      value: "1020",
    },
    {
      name: "Osorno",
      parent: "10",
      value: "1030",
    },
    {
      name: "Palena",
      parent: "10",
      value: "1040",
    },
    {
      name: "Coyhaique",
      parent: "11",
      value: "1110",
    },
    {
      name: "Aysén",
      parent: "11",
      value: "1120",
    },
    {
      name: "Capitán Prat",
      parent: "11",
      value: "1130",
    },
    {
      name: "General Carrera",
      parent: "11",
      value: "1140",
    },
    {
      name: "Magallanes",
      parent: "12",
      value: "1210",
    },
    {
      name: "Antártica Chilena",
      parent: "12",
      value: "1220",
    },
    {
      name: "Tierra del Fuego",
      parent: "12",
      value: "1230",
    },
    {
      name: "Ultima Esperanza",
      parent: "12",
      value: "1240",
    },
    {
      name: "Santiago",
      parent: "13",
      value: "1310",
    },
    {
      name: "CORDILLERA",
      parent: "13",
      value: "1320",
    },
    {
      name: "Chacabuco",
      parent: "13",
      value: "1330",
    },
    {
      name: "Maipo",
      parent: "13",
      value: "1340",
    },
    {
      name: "Melipilla",
      parent: "13",
      value: "1350",
    },
    {
      name: "Talagante",
      parent: "13",
      value: "1360",
    },
    {
      name: "Valdivia",
      parent: "14",
      value: "1410",
    },
    {
      name: "Ranco",
      parent: "14",
      value: "1420",
    },
    {
      name: "Arica",
      parent: "15",
      value: "1510",
    },
    {
      name: "Parinacota",
      parent: "15",
      value: "1520",
    },
    {
      name: "Ñuble",
      parent: "16",
      value: "1610",
    },
  ],
  [CatalogsEnum.Cities]: [
    {
      name: "IQUIQUE",
      parent: "0110",
      value: "01101",
    },
    {
      name: "ALTO HOSPICIO",
      parent: "0110",
      value: "01107",
    },
    {
      name: "POZO ALMONTE",
      parent: "0140",
      value: "01401",
    },
    {
      name: "CAMIÑA",
      parent: "0140",
      value: "01402",
    },
    {
      name: "COLCHANE",
      parent: "0140",
      value: "01403",
    },
    {
      name: "HUARA",
      parent: "0140",
      value: "01404",
    },
    {
      name: "PICA",
      parent: "0140",
      value: "01405",
    },
    {
      name: "ANTOFAGASTA",
      parent: "0210",
      value: "02101",
    },
    {
      name: "MEJILLONES",
      parent: "0210",
      value: "02102",
    },
    {
      name: "SIERRA GORDA",
      parent: "0210",
      value: "02103",
    },
    {
      name: "TALTAL",
      parent: "0210",
      value: "02104",
    },
    {
      name: "CALAMA",
      parent: "0220",
      value: "02201",
    },
    {
      name: "OLLAGÜE",
      parent: "0220",
      value: "02202",
    },
    {
      name: "SAN PEDRO DE ATACAMA",
      parent: "0220",
      value: "02203",
    },
    {
      name: "TOCOPILLA",
      parent: "0230",
      value: "02301",
    },
    {
      name: "MARÍA ELENA",
      parent: "0230",
      value: "02302",
    },
    {
      name: "COPIAPÓ",
      parent: "0310",
      value: "03101",
    },
    {
      name: "CALDERA",
      parent: "0310",
      value: "03102",
    },
    {
      name: "TIERRA AMARILLA",
      parent: "0310",
      value: "03103",
    },
    {
      name: "CHAÑARAL",
      parent: "0320",
      value: "03201",
    },
    {
      name: "DIEGO DE ALMAGRO",
      parent: "0320",
      value: "03202",
    },
    {
      name: "VALLENAR",
      parent: "0330",
      value: "03301",
    },
    {
      name: "ALTO DEL CARMEN",
      parent: "0330",
      value: "03302",
    },
    {
      name: "FREIRINA",
      parent: "0330",
      value: "03303",
    },
    {
      name: "HUASCO",
      parent: "0330",
      value: "03304",
    },
    {
      name: "LA SERENA",
      parent: "0410",
      value: "04101",
    },
    {
      name: "COQUIMBO",
      parent: "0410",
      value: "04102",
    },
    {
      name: "ANDACOLLO",
      parent: "0410",
      value: "04103",
    },
    {
      name: "LA HIGUERA",
      parent: "0410",
      value: "04104",
    },
    {
      name: "PAIHUANO",
      parent: "0410",
      value: "04105",
    },
    {
      name: "VICUÑA",
      parent: "0410",
      value: "04106",
    },
    {
      name: "ILLAPEL",
      parent: "0420",
      value: "04201",
    },
    {
      name: "CANELA",
      parent: "0420",
      value: "04202",
    },
    {
      name: "LOS VILOS",
      parent: "0420",
      value: "04203",
    },
    {
      name: "SALAMANCA",
      parent: "0420",
      value: "04204",
    },
    {
      name: "OVALLE",
      parent: "0430",
      value: "04301",
    },
    {
      name: "COMBARBALÁ",
      parent: "0430",
      value: "04302",
    },
    {
      name: "MONTE PATRIA",
      parent: "0430",
      value: "04303",
    },
    {
      name: "PUNITAQUI",
      parent: "0430",
      value: "04304",
    },
    {
      name: "RÍO HURTADO",
      parent: "0430",
      value: "04305",
    },
    {
      name: "VALPARAÍSO",
      parent: "0510",
      value: "05101",
    },
    {
      name: "CASABLANCA",
      parent: "0510",
      value: "05102",
    },
    {
      name: "CONCÓN",
      parent: "0510",
      value: "05103",
    },
    {
      name: "JUAN FERNÁNDEZ",
      parent: "0510",
      value: "05104",
    },
    {
      name: "PUCHUNCAVÍ",
      parent: "0510",
      value: "05105",
    },
    {
      name: "QUINTERO",
      parent: "0510",
      value: "05107",
    },
    {
      name: "VIÑA DEL MAR",
      parent: "0510",
      value: "05109",
    },
    {
      name: "ISLA DE PASCUA",
      parent: "0520",
      value: "05201",
    },
    {
      name: "LOS ANDES",
      parent: "0530",
      value: "05301",
    },
    {
      name: "CALLE LARGA",
      parent: "0530",
      value: "05302",
    },
    {
      name: "RINCONADA",
      parent: "0530",
      value: "05303",
    },
    {
      name: "SAN ESTEBAN",
      parent: "0530",
      value: "05304",
    },
    {
      name: "LA LIGUA",
      parent: "0540",
      value: "05401",
    },
    {
      name: "CABILDO",
      parent: "0540",
      value: "05402",
    },
    {
      name: "PAPUDO",
      parent: "0540",
      value: "05403",
    },
    {
      name: "PETORCA",
      parent: "0540",
      value: "05404",
    },
    {
      name: "ZAPALLAR",
      parent: "0540",
      value: "05405",
    },
    {
      name: "QUILLOTA",
      parent: "0550",
      value: "05501",
    },
    {
      name: "LA CALERA",
      parent: "0550",
      value: "05502",
    },
    {
      name: "HIJUELAS",
      parent: "0550",
      value: "05503",
    },
    {
      name: "LA CRUZ",
      parent: "0550",
      value: "05504",
    },
    {
      name: "NOGALES",
      parent: "0550",
      value: "05506",
    },
    {
      name: "SAN ANTONIO",
      parent: "0560",
      value: "05601",
    },
    {
      name: "ALGARROBO",
      parent: "0560",
      value: "05602",
    },
    {
      name: "CARTAGENA",
      parent: "0560",
      value: "05603",
    },
    {
      name: "EL QUISCO",
      parent: "0560",
      value: "05604",
    },
    {
      name: "EL TABO",
      parent: "0560",
      value: "05605",
    },
    {
      name: "SANTO DOMINGO",
      parent: "0560",
      value: "05606",
    },
    {
      name: "SAN FELIPE",
      parent: "0570",
      value: "05701",
    },
    {
      name: "CATEMU",
      parent: "0570",
      value: "05702",
    },
    {
      name: "LLAY-LLAY",
      parent: "0570",
      value: "05703",
    },
    {
      name: "PANQUEHUE",
      parent: "0570",
      value: "05704",
    },
    {
      name: "PUTAENDO",
      parent: "0570",
      value: "05705",
    },
    {
      name: "SANTA MARÍA",
      parent: "0570",
      value: "05706",
    },
    {
      name: "QUILPUÉ",
      parent: "0580",
      value: "05801",
    },
    {
      name: "LIMACHE",
      parent: "0580",
      value: "05802",
    },
    {
      name: "OLMUÉ",
      parent: "0580",
      value: "05803",
    },
    {
      name: "VILLA ALEMANA",
      parent: "0580",
      value: "05804",
    },
    {
      name: "RANCAGUA",
      parent: "0610",
      value: "06101",
    },
    {
      name: "CODEGUA",
      parent: "0610",
      value: "06102",
    },
    {
      name: "COINCO",
      parent: "0610",
      value: "06103",
    },
    {
      name: "COLTAUCO",
      parent: "0610",
      value: "06104",
    },
    {
      name: "DOÑIHUE",
      parent: "0610",
      value: "06105",
    },
    {
      name: "GRANEROS",
      parent: "0610",
      value: "06106",
    },
    {
      name: "LAS CABRAS",
      parent: "0610",
      value: "06107",
    },
    {
      name: "MACHALÍ",
      parent: "0610",
      value: "06108",
    },
    {
      name: "MALLOA",
      parent: "0610",
      value: "06109",
    },
    {
      name: "MOSTAZAL",
      parent: "0610",
      value: "06110",
    },
    {
      name: "OLIVAR",
      parent: "0610",
      value: "06111",
    },
    {
      name: "PEUMO",
      parent: "0610",
      value: "06112",
    },
    {
      name: "PICHIDEGUA",
      parent: "0610",
      value: "06113",
    },
    {
      name: "QUINTA DE TILCOCO",
      parent: "0610",
      value: "06114",
    },
    {
      name: "RENGO",
      parent: "0610",
      value: "06115",
    },
    {
      name: "REQUÍNOA",
      parent: "0610",
      value: "06116",
    },
    {
      name: "SAN VICENTE",
      parent: "0610",
      value: "06117",
    },
    {
      name: "PICHILEMU",
      parent: "0620",
      value: "06201",
    },
    {
      name: "LA ESTRELLA",
      parent: "0620",
      value: "06202",
    },
    {
      name: "LITUECHE",
      parent: "0620",
      value: "06203",
    },
    {
      name: "MARCHIHUE",
      parent: "0620",
      value: "06204",
    },
    {
      name: "NAVIDAD",
      parent: "0620",
      value: "06205",
    },
    {
      name: "PAREDONES",
      parent: "0620",
      value: "06206",
    },
    {
      name: "SAN FERNANDO",
      parent: "0630",
      value: "06301",
    },
    {
      name: "CHÉPICA",
      parent: "0630",
      value: "06302",
    },
    {
      name: "CHIMBARONGO",
      parent: "0630",
      value: "06303",
    },
    {
      name: "LOLOL",
      parent: "0630",
      value: "06304",
    },
    {
      name: "NANCAGUA",
      parent: "0630",
      value: "06305",
    },
    {
      name: "PALMILLA",
      parent: "0630",
      value: "06306",
    },
    {
      name: "PERALILLO",
      parent: "0630",
      value: "06307",
    },
    {
      name: "PLACILLA",
      parent: "0630",
      value: "06308",
    },
    {
      name: "PUMANQUE",
      parent: "0630",
      value: "06309",
    },
    {
      name: "SANTA CRUZ",
      parent: "0630",
      value: "06310",
    },
    {
      name: "TALCA",
      parent: "0710",
      value: "07101",
    },
    {
      name: "CONSTITUCIÓN",
      parent: "0710",
      value: "07102",
    },
    {
      name: "CUREPTO",
      parent: "0710",
      value: "07103",
    },
    {
      name: "EMPEDRADO",
      parent: "0710",
      value: "07104",
    },
    {
      name: "MAULE",
      parent: "0710",
      value: "07105",
    },
    {
      name: "PELARCO",
      parent: "0710",
      value: "07106",
    },
    {
      name: "PENCAHUE",
      parent: "0710",
      value: "07107",
    },
    {
      name: "RÍO CLARO",
      parent: "0710",
      value: "07108",
    },
    {
      name: "SAN CLEMENTE",
      parent: "0710",
      value: "07109",
    },
    {
      name: "SAN RAFAEL",
      parent: "0710",
      value: "07110",
    },
    {
      name: "CAUQUENES",
      parent: "0720",
      value: "07201",
    },
    {
      name: "CHANCO",
      parent: "0720",
      value: "07202",
    },
    {
      name: "PELLUHUE",
      parent: "0720",
      value: "07203",
    },
    {
      name: "CURICÓ",
      parent: "0730",
      value: "07301",
    },
    {
      name: "HUALAÑÉ",
      parent: "0730",
      value: "07302",
    },
    {
      name: "LICANTÉN",
      parent: "0730",
      value: "07303",
    },
    {
      name: "MOLINA",
      parent: "0730",
      value: "07304",
    },
    {
      name: "RAUCO",
      parent: "0730",
      value: "07305",
    },
    {
      name: "ROMERAL",
      parent: "0730",
      value: "07306",
    },
    {
      name: "SAGRADA FAMILIA",
      parent: "0730",
      value: "07307",
    },
    {
      name: "TENO",
      parent: "0730",
      value: "07308",
    },
    {
      name: "VICHUQUÉN",
      parent: "0730",
      value: "07309",
    },
    {
      name: "LINARES",
      parent: "0740",
      value: "07401",
    },
    {
      name: "COLBÚN",
      parent: "0740",
      value: "07402",
    },
    {
      name: "LONGAVÍ",
      parent: "0740",
      value: "07403",
    },
    {
      name: "PARRAL",
      parent: "0740",
      value: "07404",
    },
    {
      name: "RETIRO",
      parent: "0740",
      value: "07405",
    },
    {
      name: "SAN JAVIER",
      parent: "0740",
      value: "07406",
    },
    {
      name: "VILLA ALEGRE",
      parent: "0740",
      value: "07407",
    },
    {
      name: "YERBAS BUENAS",
      parent: "0740",
      value: "07408",
    },
    {
      name: "CONCEPCIÓN",
      parent: "0810",
      value: "08101",
    },
    {
      name: "CORONEL",
      parent: "0810",
      value: "08102",
    },
    {
      name: "CHIGUAYANTE",
      parent: "0810",
      value: "08103",
    },
    {
      name: "FLORIDA",
      parent: "0810",
      value: "08104",
    },
    {
      name: "HUALQUI",
      parent: "0810",
      value: "08105",
    },
    {
      name: "LOTA",
      parent: "0810",
      value: "08106",
    },
    {
      name: "PENCO",
      parent: "0810",
      value: "08107",
    },
    {
      name: "SAN PEDRO DE LA PAZ",
      parent: "0810",
      value: "08108",
    },
    {
      name: "SANTA JUANA",
      parent: "0810",
      value: "08109",
    },
    {
      name: "TALCAHUANO",
      parent: "0810",
      value: "08110",
    },
    {
      name: "TOMÉ",
      parent: "0810",
      value: "08111",
    },
    {
      name: "HUALPÉN",
      parent: "0810",
      value: "08112",
    },
    {
      name: "LEBU",
      parent: "0820",
      value: "08201",
    },
    {
      name: "ARAUCO",
      parent: "0820",
      value: "08202",
    },
    {
      name: "CAÑETE",
      parent: "0820",
      value: "08203",
    },
    {
      name: "CONTULMO",
      parent: "0820",
      value: "08204",
    },
    {
      name: "CURANILAHUE",
      parent: "0820",
      value: "08205",
    },
    {
      name: "LOS ÁLAMOS",
      parent: "0820",
      value: "08206",
    },
    {
      name: "TIRÚA",
      parent: "0820",
      value: "08207",
    },
    {
      name: "LOS ÁNGELES",
      parent: "0830",
      value: "08301",
    },
    {
      name: "ANTUCO",
      parent: "0830",
      value: "08302",
    },
    {
      name: "CABRERO",
      parent: "0830",
      value: "08303",
    },
    {
      name: "LAJA",
      parent: "0830",
      value: "08304",
    },
    {
      name: "MULCHÉN",
      parent: "0830",
      value: "08305",
    },
    {
      name: "NACIMIENTO",
      parent: "0830",
      value: "08306",
    },
    {
      name: "NEGRETE",
      parent: "0830",
      value: "08307",
    },
    {
      name: "QUILACO",
      parent: "0830",
      value: "08308",
    },
    {
      name: "QUILLECO",
      parent: "0830",
      value: "08309",
    },
    {
      name: "SAN ROSENDO",
      parent: "0830",
      value: "08310",
    },
    {
      name: "SANTA BÁRBARA",
      parent: "0830",
      value: "08311",
    },
    {
      name: "TUCAPEL",
      parent: "0830",
      value: "08312",
    },
    {
      name: "YUMBEL",
      parent: "0830",
      value: "08313",
    },
    {
      name: "ALTO BIOBÍO",
      parent: "0830",
      value: "08314",
    },
    {
      name: "TEMUCO",
      parent: "0910",
      value: "09101",
    },
    {
      name: "CARAHUE",
      parent: "0910",
      value: "09102",
    },
    {
      name: "CUNCO",
      parent: "0910",
      value: "09103",
    },
    {
      name: "CURARREHUE",
      parent: "0910",
      value: "09104",
    },
    {
      name: "FREIRE",
      parent: "0910",
      value: "09105",
    },
    {
      name: "GALVARINO",
      parent: "0910",
      value: "09106",
    },
    {
      name: "GORBEA",
      parent: "0910",
      value: "09107",
    },
    {
      name: "LAUTARO",
      parent: "0910",
      value: "09108",
    },
    {
      name: "LONCOCHE",
      parent: "0910",
      value: "09109",
    },
    {
      name: "MELIPEUCO",
      parent: "0910",
      value: "09110",
    },
    {
      name: "NUEVA IMPERIAL",
      parent: "0910",
      value: "09111",
    },
    {
      name: "PADRE LAS CASAS",
      parent: "0910",
      value: "09112",
    },
    {
      name: "PERQUENCO",
      parent: "0910",
      value: "09113",
    },
    {
      name: "PITRUFQUÉN",
      parent: "0910",
      value: "09114",
    },
    {
      name: "PUCÓN",
      parent: "0910",
      value: "09115",
    },
    {
      name: "SAAVEDRA",
      parent: "0910",
      value: "09116",
    },
    {
      name: "TEODORO SCHMIDT",
      parent: "0910",
      value: "09117",
    },
    {
      name: "TOLTÉN",
      parent: "0910",
      value: "09118",
    },
    {
      name: "VILCÚN",
      parent: "0910",
      value: "09119",
    },
    {
      name: "VILLARRICA",
      parent: "0910",
      value: "09120",
    },
    {
      name: "CHOLCHOL",
      parent: "0910",
      value: "09121",
    },
    {
      name: "ANGOL",
      parent: "0920",
      value: "09201",
    },
    {
      name: "COLLIPULLI",
      parent: "0920",
      value: "09202",
    },
    {
      name: "CURACAUTÍN",
      parent: "0920",
      value: "09203",
    },
    {
      name: "ERCILLA",
      parent: "0920",
      value: "09204",
    },
    {
      name: "LONQUIMAY",
      parent: "0920",
      value: "09205",
    },
    {
      name: "LOS SAUCES",
      parent: "0920",
      value: "09206",
    },
    {
      name: "LUMACO",
      parent: "0920",
      value: "09207",
    },
    {
      name: "PURÉN",
      parent: "0920",
      value: "09208",
    },
    {
      name: "RENAICO",
      parent: "0920",
      value: "09209",
    },
    {
      name: "TRAIGUÉN",
      parent: "0920",
      value: "09210",
    },
    {
      name: "VICTORIA",
      parent: "0920",
      value: "09211",
    },
    {
      name: "PUERTO MONTT",
      parent: "1010",
      value: "10101",
    },
    {
      name: "CALBUCO",
      parent: "1010",
      value: "10102",
    },
    {
      name: "COCHAMÓ",
      parent: "1010",
      value: "10103",
    },
    {
      name: "FRESIA",
      parent: "1010",
      value: "10104",
    },
    {
      name: "FRUTILLAR",
      parent: "1010",
      value: "10105",
    },
    {
      name: "LOS MUERMOS",
      parent: "1010",
      value: "10106",
    },
    {
      name: "LLANQUIHUE",
      parent: "1010",
      value: "10107",
    },
    {
      name: "MAULLÍN",
      parent: "1010",
      value: "10108",
    },
    {
      name: "PUERTO VARAS",
      parent: "1010",
      value: "10109",
    },
    {
      name: "CASTRO",
      parent: "1020",
      value: "10201",
    },
    {
      name: "ANCUD",
      parent: "1020",
      value: "10202",
    },
    {
      name: "CHONCHI",
      parent: "1020",
      value: "10203",
    },
    {
      name: "CURACO DE VÉLEZ",
      parent: "1020",
      value: "10204",
    },
    {
      name: "DALCAHUE",
      parent: "1020",
      value: "10205",
    },
    {
      name: "PUQUELDÓN",
      parent: "1020",
      value: "10206",
    },
    {
      name: "QUEILÉN",
      parent: "1020",
      value: "10207",
    },
    {
      name: "QUELLÓN",
      parent: "1020",
      value: "10208",
    },
    {
      name: "QUEMCHI",
      parent: "1020",
      value: "10209",
    },
    {
      name: "QUINCHAO",
      parent: "1020",
      value: "10210",
    },
    {
      name: "OSORNO",
      parent: "1030",
      value: "10301",
    },
    {
      name: "PUERTO OCTAY",
      parent: "1030",
      value: "10302",
    },
    {
      name: "PURRANQUE",
      parent: "1030",
      value: "10303",
    },
    {
      name: "PUYEHUE",
      parent: "1030",
      value: "10304",
    },
    {
      name: "RÍO NEGRO",
      parent: "1030",
      value: "10305",
    },
    {
      name: "SAN JUAN DE LA COSTA",
      parent: "1030",
      value: "10306",
    },
    {
      name: "SAN PABLO",
      parent: "1030",
      value: "10307",
    },
    {
      name: "CHAITÉN",
      parent: "1040",
      value: "10401",
    },
    {
      name: "FUTALEUFÚ",
      parent: "1040",
      value: "10402",
    },
    {
      name: "HUALAIHUÉ",
      parent: "1040",
      value: "10403",
    },
    {
      name: "PALENA",
      parent: "1040",
      value: "10404",
    },
    {
      name: "COYHAIQUE",
      parent: "1110",
      value: "11101",
    },
    {
      name: "LAGO VERDE",
      parent: "1110",
      value: "11102",
    },
    {
      name: "AYSÉN",
      parent: "1120",
      value: "11201",
    },
    {
      name: "CISNES",
      parent: "1120",
      value: "11202",
    },
    {
      name: "GUAITECAS",
      parent: "1120",
      value: "11203",
    },
    {
      name: "COCHRANE",
      parent: "1130",
      value: "11301",
    },
    {
      name: "O'HIGGINS",
      parent: "1130",
      value: "11302",
    },
    {
      name: "TORTEL",
      parent: "1130",
      value: "11303",
    },
    {
      name: "CHILE CHICO",
      parent: "1140",
      value: "11401",
    },
    {
      name: "RÍO IBÁÑEZ",
      parent: "1140",
      value: "11402",
    },
    {
      name: "PUNTA ARENAS",
      parent: "1210",
      value: "12101",
    },
    {
      name: "LAGUNA BLANCA",
      parent: "1210",
      value: "12102",
    },
    {
      name: "RÍO VERDE",
      parent: "1210",
      value: "12103",
    },
    {
      name: "SAN GREGORIO",
      parent: "1210",
      value: "12104",
    },
    {
      name: "CABO DE HORNOS",
      parent: "1220",
      value: "12201",
    },
    {
      name: "ANTÁRTICA",
      parent: "1220",
      value: "12202",
    },
    {
      name: "PORVENIR",
      parent: "1230",
      value: "12301",
    },
    {
      name: "PRIMAVERA",
      parent: "1230",
      value: "12302",
    },
    {
      name: "TIMAUKEL",
      parent: "1230",
      value: "12303",
    },
    {
      name: "NATALES",
      parent: "1240",
      value: "12401",
    },
    {
      name: "TORRES DEL PAINE",
      parent: "1240",
      value: "12402",
    },
    {
      name: "SANTIAGO",
      parent: "1310",
      value: "13101",
    },
    {
      name: "CERRILLOS",
      parent: "1310",
      value: "13102",
    },
    {
      name: "CERRO NAVIA",
      parent: "1310",
      value: "13103",
    },
    {
      name: "CONCHALÍ",
      parent: "1310",
      value: "13104",
    },
    {
      name: "EL BOSQUE",
      parent: "1310",
      value: "13105",
    },
    {
      name: "ESTACIÓN CENTRAL",
      parent: "1310",
      value: "13106",
    },
    {
      name: "HUECHURABA",
      parent: "1310",
      value: "13107",
    },
    {
      name: "INDEPENDENCIA",
      parent: "1310",
      value: "13108",
    },
    {
      name: "LA CISTERNA",
      parent: "1310",
      value: "13109",
    },
    {
      name: "LA FLORIDA",
      parent: "1310",
      value: "13110",
    },
    {
      name: "LA GRANJA",
      parent: "1310",
      value: "13111",
    },
    {
      name: "LA PINTANA",
      parent: "1310",
      value: "13112",
    },
    {
      name: "LA REINA",
      parent: "1310",
      value: "13113",
    },
    {
      name: "LAS CONDES",
      parent: "1310",
      value: "13114",
    },
    {
      name: "LO BARNECHEA",
      parent: "1310",
      value: "13115",
    },
    {
      name: "LO ESPEJO",
      parent: "1310",
      value: "13116",
    },
    {
      name: "LO PRADO",
      parent: "1310",
      value: "13117",
    },
    {
      name: "MACUL",
      parent: "1310",
      value: "13118",
    },
    {
      name: "MAIPÚ",
      parent: "1310",
      value: "13119",
    },
    {
      name: "ÑUÑOA",
      parent: "1310",
      value: "13120",
    },
    {
      name: "PEDRO AGUIRRE CERDA",
      parent: "1310",
      value: "13121",
    },
    {
      name: "PEÑALOLÉN",
      parent: "1310",
      value: "13122",
    },
    {
      name: "PROVIDENCIA",
      parent: "1310",
      value: "13123",
    },
    {
      name: "PUDAHUEL",
      parent: "1310",
      value: "13124",
    },
    {
      name: "QUILICURA",
      parent: "1310",
      value: "13125",
    },
    {
      name: "QUINTA NORMAL",
      parent: "1310",
      value: "13126",
    },
    {
      name: "RECOLETA",
      parent: "1310",
      value: "13127",
    },
    {
      name: "RENCA",
      parent: "1310",
      value: "13128",
    },
    {
      name: "SAN JOAQUÍN",
      parent: "1310",
      value: "13129",
    },
    {
      name: "SAN MIGUEL",
      parent: "1310",
      value: "13130",
    },
    {
      name: "SAN RAMÓN",
      parent: "1310",
      value: "13131",
    },
    {
      name: "VITACURA",
      parent: "1310",
      value: "13132",
    },
    {
      name: "PUENTE ALTO",
      parent: "1320",
      value: "13201",
    },
    {
      name: "PIRQUE",
      parent: "1320",
      value: "13202",
    },
    {
      name: "SAN JOSÉ DE MAIPO",
      parent: "1320",
      value: "13203",
    },
    {
      name: "COLINA",
      parent: "1330",
      value: "13301",
    },
    {
      name: "LAMPA",
      parent: "1330",
      value: "13302",
    },
    {
      name: "TIL TIL",
      parent: "1330",
      value: "13303",
    },
    {
      name: "SAN BERNARDO",
      parent: "1340",
      value: "13401",
    },
    {
      name: "BUIN",
      parent: "1340",
      value: "13402",
    },
    {
      name: "CALERA DE TANGO",
      parent: "1340",
      value: "13403",
    },
    {
      name: "PAINE",
      parent: "1340",
      value: "13404",
    },
    {
      name: "MELIPILLA",
      parent: "1350",
      value: "13501",
    },
    {
      name: "ALHUÉ",
      parent: "1350",
      value: "13502",
    },
    {
      name: "CURACAVÍ",
      parent: "1350",
      value: "13503",
    },
    {
      name: "MARÍA PINTO",
      parent: "1350",
      value: "13504",
    },
    {
      name: "SAN PEDRO",
      parent: "1350",
      value: "13505",
    },
    {
      name: "TALAGANTE",
      parent: "1360",
      value: "13601",
    },
    {
      name: "EL MONTE",
      parent: "1360",
      value: "13602",
    },
    {
      name: "ISLA DE MAIPO",
      parent: "1360",
      value: "13603",
    },
    {
      name: "PADRE HURTADO",
      parent: "1360",
      value: "13604",
    },
    {
      name: "PEÑAFLOR",
      parent: "1360",
      value: "13605",
    },
    {
      name: "VALDIVIA",
      parent: "1410",
      value: "14101",
    },
    {
      name: "CORRAL",
      parent: "1410",
      value: "14102",
    },
    {
      name: "LANCO",
      parent: "1410",
      value: "14103",
    },
    {
      name: "LOS LAGOS",
      parent: "1410",
      value: "14104",
    },
    {
      name: "MÁFIL",
      parent: "1410",
      value: "14105",
    },
    {
      name: "MARIQUINA",
      parent: "1410",
      value: "14106",
    },
    {
      name: "PAILLACO",
      parent: "1410",
      value: "14107",
    },
    {
      name: "PANGUIPULLI",
      parent: "1410",
      value: "14108",
    },
    {
      name: "LA UNIÓN",
      parent: "1420",
      value: "14201",
    },
    {
      name: "FUTRONO",
      parent: "1420",
      value: "14202",
    },
    {
      name: "LAGO RANCO",
      parent: "1420",
      value: "14203",
    },
    {
      name: "RÍO BUENO",
      parent: "1420",
      value: "14204",
    },
    {
      name: "ARICA",
      parent: "1510",
      value: "15101",
    },
    {
      name: "CAMARONES",
      parent: "1510",
      value: "15102",
    },
    {
      name: "PUTRE",
      parent: "1520",
      value: "15201",
    },
    {
      name: "GENERAL LAGOS",
      parent: "1520",
      value: "15202",
    },
    {
      name: "CHILLÁN",
      parent: "1610",
      value: "16101",
    },
    {
      name: "BULNES",
      parent: "1610",
      value: "16102",
    },
    {
      name: "CHILLÁN VIEJO",
      parent: "1610",
      value: "16103",
    },
    {
      name: "EL CARMEN",
      parent: "1610",
      value: "16104",
    },
    {
      name: "PEMUCO",
      parent: "1610",
      value: "16105",
    },
    {
      name: "PINTO",
      parent: "1610",
      value: "16106",
    },
    {
      name: "QUILLÓN",
      parent: "1610",
      value: "16107",
    },
    {
      name: "SAN IGNACIO",
      parent: "1610",
      value: "16108",
    },
    {
      name: "YUNGAY",
      parent: "1610",
      value: "16109",
    },
    {
      name: "QUIRIHUE",
      parent: "1610",
      value: "16201",
    },
    {
      name: "COBQUECURA",
      parent: "1610",
      value: "16202",
    },
    {
      name: "COELEMU",
      parent: "1610",
      value: "16203",
    },
    {
      name: "NINHUE",
      parent: "1610",
      value: "16204",
    },
    {
      name: "PORTEZUELO",
      parent: "1610",
      value: "16205",
    },
    {
      name: "RÁNQUIL",
      parent: "1610",
      value: "16206",
    },
    {
      name: "TREGUACO",
      parent: "1610",
      value: "16207",
    },
    {
      name: "SAN CARLOS",
      parent: "1610",
      value: "16301",
    },
    {
      name: "COIHUECO",
      parent: "1610",
      value: "16302",
    },
    {
      name: "ÑIQUÉN",
      parent: "1610",
      value: "16303",
    },
    {
      name: "SAN FABIÁN",
      parent: "1610",
      value: "16304",
    },
    {
      name: "SAN NICOLÁS",
      parent: "1610",
      value: "16305",
    },
  ],
  [CatalogsEnum.Banks]: [
    {
      name: "Banco BBVA",
      value: "970320008",
    },
    {
      name: "Banco de Chile / Edwards",
      value: "970040005",
    },
    {
      name: "Banco BCI",
      value: "970060006",
    },
    {
      name: "Banco Desarrollo",
      value: "970510001",
    },
    {
      name: "Banco Estado",
      value: "970300007",
    },
    {
      name: "Banco Falabella",
      value: "965096604",
    },
    {
      name: "Banco Itaú",
      value: "76645030K",
    },
    {
      name: "Banco Internacional",
      value: "970110003",
    },
    {
      name: "Banco Ripley",
      value: "979470002",
    },
    {
      name: "Banco Santander-Santiago",
      value: "97036000K",
    },
    {
      name: "Banco Security",
      value: "970530002",
    },
    {
      name: "Banco Bice",
      value: "97080000K",
    },
    {
      name: "Banco Consorcio",
      value: "995004100",
    },
    {
      name: "Banco Coopeuch",
      value: "828789007",
    },
    {
      name: "Corpbanca",
      value: "970230009",
    },
    {
      name: "Banco HSBC",
      value: "979510004",
    },
    {
      name: "Scotiabank",
      value: "970180001",
    },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { name: "Corriente", value: "0" },
    { name: "Vista", value: "1" },
  ],
  [CatalogsEnum.Currency]: [
    { name: "Unidad de Fomento", value: CurrencyEnum.UF },
    { name: "Pesos", value: CurrencyEnum.CLP },
  ],
};
