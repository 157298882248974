import React from "react";

export interface UseTitle {
  onButtonClick: () => void;
  selectedValue: string;
}

export const useTitle = (): UseTitle => {
  const [selectedValue, setSelectedValue] = React.useState<string>("");

  const onButtonClick = (): void => {
    setSelectedValue("");
  };

  return {
    onButtonClick,
    selectedValue,
  };
};
