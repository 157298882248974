/* istanbul ignore file */
import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@material-ui/core";
import { KeyValue } from "../../../types/key_value";
import { clipboard } from "../../shared/clipboard";
import { Copy } from "react-feather";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { SupportWrapperIdEmun } from "../../shared/infrastructure/catalogues/SupportWrapperIdEmun";

export interface IPageCard {
  title: string;
  image: string;
  optionsRadio: KeyValue[];
  defaultRadioValue: string;
  isMedium: boolean;
  accessCode: string;
  showBoxAccessCode: boolean;
  showConnectOptions: boolean;
  loading: boolean;
  handleCodeGenerate?: (timeConnect: string) => void;
  changeShowConnectOptions: (value: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  copyIcon: {
    marginLeft: 10,
    color: "#CBD5E0",
  },
  accessCode: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    paddingLeft: "40px",
  },
}));

export const CardConnect = (props: IPageCard) => {
  const classes = useStyles();
  const [timeConnectRadio, setTimeConnectRadio] = React.useState(
    props.defaultRadioValue
  );
  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.changeShowConnectOptions(event.target.checked);
  };
  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTimeConnectRadio((event.target as HTMLInputElement).value);
  };
  const handleCopy = (text: string | undefined) => () => {
    clipboard(text || "");
  };
  const onGenerateAccessCode = () => {
    if (props.handleCodeGenerate) props.handleCodeGenerate(timeConnectRadio);
  };

  return (
    <React.Fragment>
      <Paper>
        <Box display="flex" alignItems="center" p={1}>
          <Box p={1}>
            <Avatar className={classes.large} src={props.image} />
          </Box>
          <Box p={1} flexGrow={1}>
            <Typography variant="h5" color={"primary"}>
              <b>{props.title}</b>
            </Typography>
          </Box>
          <Box p={1}>
            <Switch
              checked={props.showConnectOptions}
              onChange={handleChangeSwitch}
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </Box>
        </Box>
        <Grid
          container
          spacing={4}
          style={{ paddingLeft: props.isMedium ? 80 : 10 }}
        >
          <Grid item xs={12}>
            <Typography variant="body2" style={{ marginBottom: 20 }}>
              Permite al equipo de Soporte Kushki visualizar la Consola de
              Cliente como tu comercio. Esta opción es solo lectura.
            </Typography>

            {props.showConnectOptions ? (
              <React.Fragment>
                <Typography variant="body2" style={{ marginBottom: 20 }}>
                  Selecciona el periodo de tiempo que soporte tendrá acceso a la
                  vista de tu comercio. Genera la clave de acceso y envíala al
                  equipo de soporte para su ingreso.
                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    name="timeSupport"
                    value={timeConnectRadio}
                    onChange={handleChangeRadio}
                  >
                    {props.optionsRadio.map((option: KeyValue) => (
                      <FormControlLabel
                        key={option.key}
                        value={option.key}
                        control={<Radio color="primary" />}
                        label={option.value}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                <Grid item xs={12}>
                  <ComponentSecurityWrapper
                    componentId={SupportWrapperIdEmun.GENERAR_CLAVE_ACCESO}
                  >
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={onGenerateAccessCode}
                      disabled={props.loading || props.accessCode.length > 0}
                    >
                      Generar clave de acceso
                      {props.loading && <CircularProgress size={16} />}
                    </Button>
                  </ComponentSecurityWrapper>
                </Grid>
                {props.accessCode.length > 0 ? (
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      m={1}
                      p={1}
                      bgcolor="background.paper"
                    >
                      <Box p={2} className={classes.accessCode}>
                        <Typography variant="h5">
                          Código de acceso: {props.accessCode}
                          <IconButton className={classes.copyIcon}>
                            <Copy
                              size={16}
                              onClick={handleCopy(props.accessCode)}
                            />
                          </IconButton>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ) : null}
              </React.Fragment>
            ) : null}
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};
