/* istanbul ignore file */
/* eslint-disable */
export const environment: {
kushkiUrl: string;
kushkiVehiscoreWsUrl: string;
envName: string;
devTools: boolean;
} = {
kushkiUrl: "https://api.kushkipagos.com",
kushkiVehiscoreWsUrl: "wss://api-vehiscore-ws.kushkipagos.com",
envName: "uat",
devTools: false,
};
