import { ReactNode } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, Checkbox, TableSortLabel, Typography } from "@mui/material";
import { defaultTo, get } from "lodash";
import { transactionHeaderStyles } from "./TransactionTable.styles";
import { Conciliation } from "../../../types/get_conciliation_transactions_response";
import { TransactionTooltip } from "../TransactionTooltip/TransactionTooltip";
import { TransactionsTableConstants } from "../../shared/infrastructure/constants/TransactionsTableConstants";
import SortIcon from "../../assets/SortIcon";
import { formatTableDate } from "../../shared/utils/date_utils";
import { formatNumber, getColumns } from "../../shared/utils/amount";
import {
  CHECK_DIALOG,
  REFUND_DIALOG,
  SKIP_DIALOG,
} from "../../shared/constants/dialog_types";
import { MenuOptions } from "../MenuOptions/MenuOptions";
import { Dialogs } from "../Dialogs/Dialogs";
import { ItemTitleEnum } from "../../shared/infrastructure/enums/ItemTitleEnum";
import { ITransactionTableProps } from "./TransactionTable.interfaces";
import { capitalizeText } from "../../shared/utils/string_utils";
import { TabSourceEnum } from "../../shared/infrastructure/enums/TabSourceEnum";
import { ProcessorsEnum } from "../../shared/enums/processors_enum";
import {
  ALLOWED_FRANCHISES,
  FranchiseEnum,
} from "../../shared/enums/franchise_enum";
import { TabsEnum } from "../../shared/enums/tabs_enum";
import { ColorsEnum } from "../../shared/enums/colors_enum";
import ChipConciliationAction from "../common/ChipConciliationAction/ChipConciliactionAction";

const TransactionTable = (props: ITransactionTableProps) => {
  const inReview = (conciliationTrx: Conciliation) => {
    return (
      get(conciliationTrx, "conciliationAction") === "review_kushki" ||
      get(conciliationTrx, "conciliationAction") === "review_processor"
    );
  };

  const payload = JSON.parse(defaultTo(localStorage.getItem("payload"), "{}"));
  const ALLOWED_TABS: string[] = [
    TabSourceEnum.PENDING,
    TabSourceEnum.HISTORIC,
  ];

  const fillCell = (
    value: string | number,
    conciliationTrx: Conciliation,
    field?: string,
    actualTab?: string
  ): ReactNode => {
    if (field === "approvedTransactionAmount")
      return `${formatNumber(value)} ${conciliationTrx.currencyCode}`;
    if (field === "merchantDescription")
      return (
        <Box
          display="block"
          style={{
            color:
              actualTab === TabsEnum.PENDING && inReview(conciliationTrx)
                ? ColorsEnum.GREY
                : ColorsEnum.DARK,
          }}
        >
          {get(conciliationTrx, "merchantName")}{" "}
          <Typography
            variant="subtitle2"
            style={{
              color:
                actualTab === TabsEnum.PENDING && inReview(conciliationTrx)
                  ? ColorsEnum.DISABLED
                  : ColorsEnum.GREY,
            }}
          >
            {get(conciliationTrx, "merchantId")}
          </Typography>
        </Box>
      );
    if (field === "transactionType")
      return capitalizeText(conciliationTrx.transactionType);
    if (field === "conciliationAction")
      return (
        <ChipConciliationAction
          conciliationAction={conciliationTrx.conciliationAction}
        />
      );
    if (field === "created")
      return (
        <Box
          display="block"
          style={{
            color: "#293036",
          }}
        >
          {
            formatTableDate(
              get(conciliationTrx, "created"),
              props.timeZoneRegion
            ).split("T")[0]
          }{" "}
          <Typography
            variant="subtitle2"
            style={{
              color: "#293036",
            }}
          >
            {
              formatTableDate(
                get(conciliationTrx, "created"),
                props.timeZoneRegion
              )
                .split("T")[1]
                .split(".")[0]
            }
          </Typography>
        </Box>
      );

    if (field === "transactionStatusKushki")
      return (
        <TransactionTooltip
          status={get(conciliationTrx, "transactionStatusKushki")}
          message={""}
          disableHover={true}
        />
      );

    if (field === "transactionStatusProcessor")
      return (
        <TransactionTooltip
          status={get(conciliationTrx, "transactionStatusProcessor")}
          message={""}
          disableHover={true}
        />
      );

    return value;
  };

  const defineRowBackgroundStyleByParams = (
    conciliationTrx: Conciliation
  ): string => {
    if (
      props.currentTab === TabsEnum.PENDING &&
      get(conciliationTrx, "inconsistencyCode") != "10"
    ) {
      return ColorsEnum.WARNING_LIGHT3;
    } else if (
      props.currentTab === TabsEnum.PENDING &&
      get(conciliationTrx, "processorName") ===
        ProcessorsEnum.KUSHKI_ACQUIRER &&
      !ALLOWED_FRANCHISES.includes(
        get(conciliationTrx, "franchise", "") as FranchiseEnum
      )
    ) {
      return ColorsEnum.WARNING_LIGHT3;
    } else {
      return ColorsEnum.WHITE;
    }
  };

  return (
    <>
      <TableContainer>
        <Table size={"medium"} aria-label="simple table">
          <TableHead>
            <TableRow
              key="header"
              style={{ background: ColorsEnum.NEUTRAL_200 }}
            >
              {ALLOWED_TABS.includes(props.currentTab) && (
                <TableCell padding={"checkbox"}>
                  <Checkbox
                    onChange={props.handleSelectAll}
                    checked={
                      props.conciliationList.length > 0 &&
                      props.selectedRows.length ===
                        props.conciliationList.length
                    }
                    sx={transactionHeaderStyles.checkBox}
                  />
                </TableCell>
              )}

              {TransactionsTableConstants.sortValuesSelectedColumns(
                props.selectedColumns
              ).map((title: string, index: number) => (
                <TableCell
                  style={{
                    minWidth: 140,
                  }}
                  key={`head_${title}_${index}`}
                >
                  <TableSortLabel
                    IconComponent={SortIcon}
                    sx={transactionHeaderStyles.typography}
                  >
                    {title}
                  </TableSortLabel>
                </TableCell>
              ))}
              {props.currentTab === TabsEnum.PENDING && (
                <TableCell>
                  <TableSortLabel
                    IconComponent={SortIcon}
                    sx={transactionHeaderStyles.typography}
                  >
                    Acciones
                  </TableSortLabel>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.conciliationList.map(
              (conciliationTrx: Conciliation, index: number) => {
                conciliationTrx = {
                  ...conciliationTrx,
                  merchantDescription: `${get(
                    conciliationTrx,
                    "merchantName"
                  )} ${get(conciliationTrx, "merchantId")}`,
                };
                const labelId = `row_${get(conciliationTrx, "conciliationId")}`;

                return (
                  <TableRow
                    id={`row_${get(conciliationTrx, "conciliationId")}`}
                    key={`row_${index}`}
                    style={{
                      background:
                        defineRowBackgroundStyleByParams(conciliationTrx),
                    }}
                  >
                    {ALLOWED_TABS.includes(props.currentTab) && (
                      <TableCell
                        padding={"checkbox"}
                        component="th"
                        scope="row"
                        style={{ width: 10 }}
                        onClick={() =>
                          props.handleClickRow(
                            get(conciliationTrx, "conciliationId"),
                            get(conciliationTrx, "approvedTransactionAmount")
                          )
                        }
                      >
                        <Checkbox
                          checked={props.isSelected(
                            get(conciliationTrx, "conciliationId")
                          )}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          sx={transactionHeaderStyles.checkBox}
                        />
                      </TableCell>
                    )}
                    {getColumns(props.selectedColumns, conciliationTrx).map(
                      (
                        detail: {
                          description: string;
                          type: string;
                        },
                        index: number
                      ) => (
                        <TableCell
                          style={{
                            color:
                              props.currentTab === TabsEnum.PENDING &&
                              inReview(conciliationTrx)
                                ? ColorsEnum.DISABLED
                                : ColorsEnum.DARK,
                          }}
                          key={`column_${index}`}
                          onClick={() =>
                            props.handleOpenSelectedTransactionModal(
                              conciliationTrx
                            )
                          }
                        >
                          {fillCell(
                            detail.description,
                            conciliationTrx,
                            detail.type,
                            props.currentTab
                          )}
                        </TableCell>
                      )
                    )}
                    {props.currentTab === TabsEnum.PENDING && (
                      <TableCell>
                        <Box p={1}>
                          <MenuOptions
                            items={[
                              {
                                handleClick: () =>
                                  props.dialogActions.handleOpen(
                                    conciliationTrx,
                                    REFUND_DIALOG
                                  ),
                                title: ItemTitleEnum.REFUND,
                                type: "primary",
                              },
                              {
                                handleClick: () =>
                                  props.dialogActions.handleOpen(
                                    conciliationTrx,
                                    REFUND_DIALOG
                                  ),
                                title: ItemTitleEnum.LIQUIDATE,
                                type: "primary",
                              },
                              {
                                handleClick: () =>
                                  props.dialogActions.handleOpen(
                                    conciliationTrx,
                                    SKIP_DIALOG
                                  ),
                                title: ItemTitleEnum.OMITTED,
                                type: "primary",
                              },
                              {
                                handleClick: () =>
                                  props.dialogActions.handleOpen(
                                    conciliationTrx,
                                    CHECK_DIALOG
                                  ),
                                title: ItemTitleEnum.REVIEW,
                                type: "primary",
                              },
                            ]}
                            transaction={conciliationTrx}
                            username={get(payload, "cognito:username", "")}
                          />
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialogs
        dialog={props.dialogActions.dialog}
        handleClose={props.dialogActions.handleClose}
        open={props.dialogActions.open}
        transaction={props.dialogActions.transaction}
      />
    </>
  );
};

export default TransactionTable;
