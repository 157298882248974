import { useEffect, useState } from "react";
import { selectNodeInfo } from "../../../store/selectors/selectors";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  IDefaultItemSelectedEvent,
  IUseResumeContainer,
} from "./useResumeContainer.interface";
import { ConfigurationIdEnum, Section } from "../../../shared/enums";
import { QueryParamsEnum } from "../../../shared/enums/queryParamsEnum";
import { routes } from "../../../shared/infrastructure/constants/routes";
import {
  CENTRALIZATION_CONFIGURATIONS,
  ProcessingItems,
  SectionItem,
} from "../../../shared/constants/centralization_configurations";
import { isSectionCentralized } from "../../../shared/utils/isSectionCentralized";
import { defaultTo, get, has, includes, isEmpty, isEqual } from "lodash";
import { INodeInfoResponse } from "../../../../types/node_info_response";
import { IDropdownItemProps } from "@kushki/connect-ui";
import { CountryCodeEnum } from "../../../shared/enums/CountryCodeEnum";
import { EntityNameEnum } from "../../../shared/enums/entityName";
import { BasicPropertiesValueEnum } from "../../../shared/enums/BasicPropertiesEnum";

const useResumeContainer = (section: Section): IUseResumeContainer => {
  const nodeInfoData: INodeInfoResponse | undefined =
    useSelector(selectNodeInfo);
  const history = useHistory();

  const [isCentralized, setIsCentralized] = useState(false);
  const [configurationItems, setConfigurationItems] = useState(
    [] as IDropdownItemProps[]
  );

  useEffect(() => {
    if (!nodeInfoData) {
      return;
    }

    const isCentralized = isSectionCentralized(section, nodeInfoData.configs);

    setIsCentralized(isCentralized);
  }, [nodeInfoData]);

  const onClickControlCentralization = (
    selectedValue?: IDefaultItemSelectedEvent
  ) => {
    if (!nodeInfoData) {
      return;
    }
    const { countryCode, merchantId } = nodeInfoData;
    const configurations: { [k: string]: ConfigurationIdEnum[] } =
      CENTRALIZATION_CONFIGURATIONS[section];

    if (!configurations || !configurations[SectionItem.DEFAULT]) {
      return;
    }

    const selectedId: string = defaultTo(get(selectedValue, "id"), "");
    const configuration: string[] =
      !isEmpty(selectedId) && has(configurations, selectedId)
        ? configurations[selectedId]
        : configurations[countryCode] ?? configurations[SectionItem.DEFAULT];

    history.push({
      pathname: `${routes.CONTROL_CENTRALIZATION}/${merchantId}/centralization`,
      search: `?${QueryParamsEnum.CONFIGURATIONS}=${configuration.join(",")}`,
    });
  };

  useEffect(() => {
    const isColOrChl: boolean = includes(
      [CountryCodeEnum.COLOMBIA, CountryCodeEnum.CHILE],
      get(nodeInfoData, BasicPropertiesValueEnum.COUNTRY_CODE, "")
    );
    const isCustomer: boolean = isEqual(
      get(nodeInfoData, BasicPropertiesValueEnum.ENTITY_NAME, ""),
      EntityNameEnum.CUSTOMER
    );

    let items: IDropdownItemProps[] = [
      {
        id: SectionItem.CONFIG_BUSINESS_RULE,
        text: ProcessingItems.BUSINESS_RULE,
        variant: "text",
      },
      {
        id: SectionItem.CONFIG_DEFERRED,
        text: ProcessingItems.DEFERRED,
        variant: "text",
      },
      {
        id: SectionItem.CONFIG_PROCESSORS,
        text: ProcessingItems.PROCESSOR,
        variant: "text",
      },
    ];

    if (isColOrChl && isCustomer)
      items = items.filter(
        (item: IDropdownItemProps) => item.id !== SectionItem.CONFIG_DEFERRED
      );

    setConfigurationItems(items);
  }, [nodeInfoData]);

  return {
    configurationItems,
    entityName: nodeInfoData?.entityName,
    isCentralized,
    onClickControlCentralization,
  };
};

export default useResumeContainer;
