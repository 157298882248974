import React from "react";
import { Box, Button, Card, CardContent, Tooltip } from "@material-ui/core";
import { useStateApp } from "../../shared/state/useStateApp";
import { useMexicoSection } from "./state/useMexicoSection";
import { ConfigDeferredHeader } from "../ConfigDeferredHeader/ConfigDeferredHeader";
import { makeStyles } from "@material-ui/core/styles";
import { ConfigListBank } from "../ConfigListBank/ConfigListBank";
import { BankModal } from "../commons/BankModal/BankModal";
import { ConfigMonthSection } from "../ConfigMonthSection/ConfigMonthSection";
import { Plus } from "react-feather";
import { VariantDeferredOption } from "../../../types/update_deferred_merchant_request";
import { MexicanVariantSection } from "../MexicanVariantSection/MexicanVariantSection";

const useStyles = makeStyles(() => ({
  cancelButton: {
    background: "#F7FAFC",
    border: "1px solid #023365",
    color: "#023365",
    fontSize: "15px",
    fontWeight: 500,
    lineHeight: "10px",
    paddingBottom: "15px",
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingTop: "15px",
  },
  link: {
    color: "#023365",
    display: "flex",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "16px",
  },
}));

const useStylesBootstrap = makeStyles(() => ({
  tooltip: {
    backgroundColor: "#6D7781",
    color: "#FFFFFF",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16.32px",
  },
}));

function BootstrapTooltip(props: any) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}
export const MexicoSection = () => {
  const classes = useStyles();

  const { isMobile } = useStateApp();
  const {
    canAddVariant,
    type,
    banks,
    saveDefered,
    banksListWeb,
    bankModalIsOpen,
    bankModalWebIsOpen,
    banksModal,
    months,
    monthsOfGrace,
    merchantMonths,
    merchantMonthsOfGrace,
    handleBankModal,
    handleBankWebModal,
    handleCancel,
    handleSave,
    handleSetMonth,
    handleSetMonthOfGrace,
    variants,
    handleDeleteVariant,
    handleSetBank,
    handleDeleteBank,
    handleSetMonthVariant,
    handleSetMonthOfGraceVariant,
    handleSetVariant,
    handleCloseBankModal,
  } = useMexicoSection();

  return (
    <>
      <Card>
        <CardContent>
          <ConfigDeferredHeader
            isMobile={isMobile}
            brandList={["visa", "masterCard", "amex", "diners"]}
            type={type}
          />
          <ConfigListBank
            isMobile={isMobile}
            bankList={banksListWeb}
            handleBankModal={handleBankWebModal}
          />
          <ConfigMonthSection
            mainTittle={"Mensualidades"}
            secondTittle={"Meses de gracia"}
            mainMonths={months}
            secondMonths={monthsOfGrace}
            mainMerchantMonths={merchantMonths}
            secondMerchantMonth={merchantMonthsOfGrace}
            isMobile={isMobile}
            handleSetMonth={handleSetMonth}
            handleSetMonthOfGrace={handleSetMonthOfGrace}
          />
          {variants.map((variant: VariantDeferredOption, index: number) => (
            <MexicanVariantSection
              key={index}
              bankModalIsOpen={bankModalIsOpen}
              handleSetMonthVariant={handleSetMonthVariant}
              handleSetMonthOfGraceVariant={handleSetMonthOfGraceVariant}
              months={months}
              monthsOfGrace={monthsOfGrace}
              merchantMonths={variant.months}
              merchantMonthsOfGrace={variant.monthsOfGrace}
              banks={banks}
              index={index}
              handleDeleteVariant={handleDeleteVariant}
              handleBankModal={handleBankModal}
              handleSetBank={handleSetBank}
              selectedBanks={variant.bank!}
              handleDeleteBank={handleDeleteBank}
              isMobile={isMobile}
              handleCloseBankModal={handleCloseBankModal}
            />
          ))}

          <Box
            pt={3}
            display={"flex"}
            justifyContent={"flex-end"}
            width={"100%"}
          >
            <Box pl={2}>
              <BootstrapTooltip
                title="Para realizar el cambio de la configuración de algún banco en específico puedes utilizar las variantes, cambia las cuotas y meses de gracia de los bancos que elijas."
                aria-label="add"
              >
                <Button
                  className={classes.link}
                  onClick={handleSetVariant}
                  startIcon={<Plus />}
                  disabled={!canAddVariant}
                >
                  Crear variante
                </Button>
              </BootstrapTooltip>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Box pt={3} display={"flex"} justifyContent={"flex-end"} width={"100%"}>
        <Box pl={2}>
          <Button
            variant="outlined"
            size="large"
            onClick={handleCancel}
            className={classes.cancelButton}
          >
            Cancelar
          </Button>
        </Box>
        <Box pl={2}>
          <Button
            size="large"
            variant="contained"
            color={"primary"}
            onClick={handleSave}
            disabled={saveDefered}
          >
            Guardar Diferido
          </Button>
        </Box>
      </Box>

      <BankModal
        open={bankModalWebIsOpen}
        banks={banksModal}
        handleCloseDialog={handleBankWebModal}
      />
    </>
  );
};
