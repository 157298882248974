import { useSectionStyles as styles } from "./AlertDeferredProcessor.styles";
import { Card, Grid, Typography } from "@mui/material";
import { IconMarketing } from "@kushki/connect-ui";
import { DeferredData } from "../Deferred/Deferred.data";
import React from "react";

export interface IAlertDeferredProcessor {
  titleSection: string;
}

export const AlertDeferredProcessor: React.FC<IAlertDeferredProcessor> = ({
  titleSection,
}) => {
  return (
    <Grid container alignItems="center" sx={styles.alertContainer}>
      <Grid item xs={2}>
        <Typography
          variant={"h3"}
          color={"primary"}
          sx={styles.mainMassiveTitle}
        >
          {titleSection}
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Card sx={styles.alertCard}>
          <Grid container sx={styles.alertMassiveMessage}>
            <Grid item xs={0.4}>
              <IconMarketing />
            </Grid>
            <Grid item xs={11.6}>
              <Typography
                variant={"h3"}
                color={"primary"}
                sx={styles.alertMassiveTitle}
              >
                {DeferredData.AlertMassiveDeferred}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};
