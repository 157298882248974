import { useDispatch, useSelector } from "react-redux";
import { parseToEnUSDate } from "../../shared/utils";
import { selectSFilters } from "../../store/selectors/filters/filters";
import { selectSelectedTransactionsIDs } from "../../store/selectors/transactions/transactions";
import downloadTransactions from "../../store/thunks/transactions/downloadTransactions/download_transactions";
import {
  AdminSearchDownloadRequestWS,
  FormatDownloadRequest,
  TransactionsDownloadWS,
} from "../../store/thunks/transactions/downloadTransactions/download_transactions.interfaces";
import { IUseDownloadTransactions } from "./useDownloadTransactions.interfaces";

const useDownloadTransactions = (): IUseDownloadTransactions => {
  const selectedTransactionsIDs = useSelector(selectSelectedTransactionsIDs);
  const filters = useSelector(selectSFilters);

  const dispatch = useDispatch();

  const handleDownloadTransactions = (downloadType: string) => {
    const request: AdminSearchDownloadRequestWS = {
      action: "downloadB2CTransactions",
      data: {
        filter: {
          paymentMethod: filters.general.paymentMethod.join(","),
          status: filters.general.transactionStatus.join(","),
        },
        format: downloadType.toLowerCase() as FormatDownloadRequest,
        from: parseToEnUSDate(filters.date.from),
        limit: filters.pagination.limit,
        offset: filters.pagination.limit * (filters.pagination.page - 1),
        to: parseToEnUSDate(filters.date.to),
      },
    };

    dispatch(
      downloadTransactions({
        data: request,
      })
    );
  };

  const handleDownloadSelectedTransactions = (downloadType: string) => {
    const request: TransactionsDownloadWS = {
      action: "downloadB2CTransactionsById",
      data: {
        field: "id",
        fields: selectedTransactionsIDs,
        format: downloadType.toLowerCase() as FormatDownloadRequest,
      },
    };

    dispatch(
      downloadTransactions({
        data: request,
        isSelectedTransactions: true,
      })
    );
  };

  return {
    actions: {
      handleDownloadSelectedTransactions,
      handleDownloadTransactions,
    },
  };
};

export default useDownloadTransactions;
