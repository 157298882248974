import React from "react";
import {
  createStyles,
  Grid,
  Select,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";

const BootstrapInput = withStyles(() => ({
  input: {
    position: "relative",
    fontSize: 14,
    color: "rgba(46, 61, 74, 0.70)",
  },
}))(InputBase);

export interface PaginationTableProps {
  total: number;
  limit: number;
  page: number;
  isMobile: boolean;
  trxPerPage: number;
  handleChangePage: (_event: React.ChangeEvent<unknown>, value: number) => void;
  handleChangeLimit: (_event: React.ChangeEvent<unknown>) => void;
  size?: "small" | "medium" | "large";
  siblingCount?: number;
}

const PaginationTable: React.FC<PaginationTableProps> = (
  props: PaginationTableProps
) => {
  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        padding: `${props.isMobile ? "2px 0px 4px 4px" : "8px 27px 8px 20px"}`,
        backgroundColor: "#FFFFFF",
        borderRadius: 4,
        boxShadow: "0 0px 0px rgba(0,0,0,0), 0 0px 0px rgba(0,0,0,0)",
      },
      buttonIconPagination: {
        textTransform: "none",
      },
      marginButton: {
        marginTop: -6,
        display: "flex",
      },
      typographyLabelPagination: {
        marginTop: "auto",
        marginBottom: "auto",
        marginRight: 11,
      },
    })
  );
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid container>
          <Grid
            item
            xs={3}
            lg={4}
            xl={4}
            md={4}
            sm={6}
            className={classes.marginButton}
          >
            <Typography
              color={"textSecondary"}
              variant={"body2"}
              className={classes.typographyLabelPagination}
            >
              Asistencias por página
            </Typography>
            <Typography
              color={"textSecondary"}
              variant={"body2"}
              className={classes.typographyLabelPagination}
            >
              <Select
                native
                value={props.limit}
                onChange={props.handleChangeLimit}
                input={<BootstrapInput />}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
              </Select>
            </Typography>
            <Typography
              color={"textSecondary"}
              variant={"body2"}
              className={classes.typographyLabelPagination}
            >
              {`${(props.page - 1) * 10 + 1}-${
                (props.page - 1) * 10 + props.trxPerPage
              } de ${props.total}`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={9}
            lg={8}
            xl={8}
            md={8}
            sm={6}
            container
            justify="flex-end"
            alignItems="flex-start"
            direction="row"
          >
            <Pagination
              count={Math.ceil(Number(props.total / props.limit))}
              onChange={props.handleChangePage}
              page={props.page}
              size={props.size}
              siblingCount={props.siblingCount}
            />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default PaginationTable;
