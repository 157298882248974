import React, { FC } from "react";
import { Box, Button, Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";
import {
  IWalletConfigurationStateProps,
  useWalletConfiguration,
} from "./state/useWalletConfigurationState";
import { RechargeWallet } from "../RechargeWallet/RechargeWallet";

export interface IWalletConfigurationProps
  extends IWalletConfigurationStateProps {}

const useStyles = makeStyles((theme) => ({
  title: {
    padding: "8px 0px",
    fontSize: "20px",
    fontWeight: 300,
    lineHeight: "28px",
  },
  titleBold: {
    padding: "8px 0px",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "28px",
  },
  body: {
    paddingTop: "10px",
    fontSize: "13px",
    fontWeight: 300,
    lineHeight: "15.6px",
    color: theme.palette.primary.main,
  },
  bodyBold: {
    paddingTop: "10px",
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "15.6px",
    color: theme.palette.primary.main,
  },
  cardContainer: {
    width: "100%",
    backgroundColor: "#F7FAFC",
  },
  button: {
    padding: "10px 0px",
    height: "36px",
    width: "222px",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "19.6px",
  },
}));

export const WalletConfiguration: FC<IWalletConfigurationProps> = (
  props: IWalletConfigurationProps
) => {
  const style = useStyles(theme);
  const {
    enableConfiguration,
    walletRechargeConfig,
    itemWalletConfig,
    handlers: { goToConfiguration },
  } = useWalletConfiguration(props);

  return (
    <>
      {enableConfiguration && (
        <Card className={style.cardContainer} elevation={0}>
          {walletRechargeConfig?.walletConfig &&
          itemWalletConfig &&
          itemWalletConfig.automaticRecharge ? (
            <RechargeWallet
              key={"rechargeWallet"}
              merchantId={props.merchantId}
              itemWalletConfig={itemWalletConfig}
            />
          ) : (
            <CardContent>
              <Box
                pt={3}
                pb={3}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
              >
                <Typography
                  className={style.title}
                  align={"center"}
                  data-testid={"title"}
                >
                  Aún no has configurado la{" "}
                  <Typography component={"span"} className={style.titleBold}>
                    automática de wallet
                  </Typography>
                </Typography>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"center"}
                >
                  <Button
                    onClick={() => {
                      goToConfiguration();
                    }}
                    variant={"contained"}
                    color={"primary"}
                    className={style.button}
                    data-testid={"button"}
                  >
                    Configurar recarga wallet
                  </Button>
                </Box>
                <Typography
                  className={style.body}
                  align={"center"}
                  color={"secondary"}
                  data-testid={"body"}
                >
                  Configura la recarga parcial o total del comercio.{" "}
                  <Typography component={"span"} className={style.bodyBold}>
                    Aplica solo si el comercio tiene configurados procesadores
                    Payins y Payouts. *
                  </Typography>
                </Typography>
              </Box>
            </CardContent>
          )}
        </Card>
      )}
    </>
  );
};
