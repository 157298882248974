import { FilterWalletDashboardRequest } from "../../../../types/filter_wallet_dashboard_request";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import {
  getTransactions,
  setFilterWalletDashboardRequest,
} from "../../../store/actionCreators";
import { isEmpty, set, unset } from "lodash";
import { useEffect, useState } from "react";

export interface IUseSearchFieldState {
  handleChangeSearchText: (value: string) => void;
  handleEnterSearchText: (key: string) => void;
  value: string;
}

export const useSearchFieldState = (): IUseSearchFieldState => {
  const dispatch = useDispatch();
  const filterWalletDashboardRequest: FilterWalletDashboardRequest | undefined =
    useSelector((state: IAppState) => state.filterWalletDashboardRequest);
  const [value, setValue] = useState<string>("");

  const handleEnterSearchText = (key: string): void => {
    if (key !== "Enter") return;
    setSearchText();
  };

  const handleChangeSearchText = (value: string): void => {
    setValue(value);
  };

  useEffect(() => {
    if (value === "") setSearchText();
  }, [value]);

  function setSearchText() {
    let filterRequest: FilterWalletDashboardRequest = {
      ...filterWalletDashboardRequest!,
    };

    if (value === "") {
      filterRequest = {
        ...filterWalletDashboardRequest!,
      };
      unset(filterRequest, "filter.origin_ticket_number");
    } else {
      unset(filterRequest, "filter");
      set(filterRequest, "filter.origin_ticket_number", value);
      set(filterRequest, "offset", 0);
    }

    if (!isEmpty(filterRequest)) {
      const merchantIdRequest: string =
        new URLSearchParams(location.search).get("publicMerchantId") || "";

      dispatch(setFilterWalletDashboardRequest(filterRequest));
      dispatch(getTransactions(filterRequest, merchantIdRequest));
    }
  }

  return {
    handleChangeSearchText,
    handleEnterSearchText,
    value,
  };
};
