import { RootState } from "../store.interface";

export const compliancePeople = (state: RootState) =>
  state.app.compliancePeople;

export const selectBillingConfig = (state: RootState) =>
  state.app.billingConfig;

export const selectConfigurationRatesInfo = (state: RootState) =>
  state.app.configurationRatesInfo;

export const selectMerchantCardInfo = (state: RootState) =>
  state.app.merchantCardInfo;

export const selectMerchantCardInfoSandbox = (state: RootState) =>
  state.app.merchantCardInfoSandbox;

export const selectMerchantBillingInfo = (state: RootState) =>
  state.app.merchantBillingInfo;
export const selectMerchantInfo = (state: RootState) => state.app.merchantInfo;

export const selectMerchantDefaultProcessors = (state: RootState) =>
  state.app.merchantDefaultProcessors;

export const selectMerchantDispersionInfo = (state: RootState) =>
  state.app.merchantDispersionInfo;

export const selectMerchantProcessors = (state: RootState) =>
  state.app.merchantProcessors;

export const selectNodeInfo = (state: RootState) => state.app.nodeInfo;

export const selectCredential = (state: RootState) => state.app.credentials;

export const selectRule = (state: RootState) => state.app.rules;

export const selectNotifications = (state: RootState) =>
  state.app.notifications;

export const selectSearchNodes = (state: RootState) => state.app.searchNodes;

export const selectDeferred = (state: RootState) => state.app.deferred;

export const selectContactsData = (state: RootState) => state.app.contactsData;

export const selectMerchantCommission = (state: RootState) =>
  state.app.merchantCommission;

export const selectMerchantCompliance = (state: RootState) =>
  state.app.merchantCompliance;

export const selectAccordionCompanyOpen = (state: RootState) =>
  state.app.mainAccordion.isCompanyOpen;

export const selectAccordionDiscountOpen = (state: RootState) =>
  state.app.mainAccordion.isDiscountOpen;

export const selectAccordionFinancialOpen = (state: RootState) =>
  state.app.mainAccordion.isFinancialOpen;

export const selectAccordionProcessOpen = (state: RootState) =>
  state.app.mainAccordion.isProcessOpen;

export const selectDeveloperProcessOpen = (state: RootState) =>
  state.app.mainAccordion.isDeveloperOpen;

export const selectServiceOpen = (state: RootState) =>
  state.app.mainAccordion.isServiceOpen;
export const selectServiceConfiguration = (state: RootState) =>
  state.app.serviceConfiguration;

export const selectMerchantSettingsInfo = (state: RootState) =>
  state.app.merchantSettings;

export const selectSmartlinkMerchant = (state: RootState) =>
  state.app.smartlinkMerchant;
export const users = (state: RootState) => state.app.users;

export const selectVposMerchant = (state: RootState) => state.app.vposMerchant;

export const selectServiceSubscription = (state: RootState) =>
  state.app.serviceSubscriptions;

export const selectUserOpen = (state: RootState) =>
  state.app.mainAccordion.isUserOpen;

export const selectWebhookList = (state: RootState) => state.app.webhooks;
export const searchNodesList = (state: RootState) => state.app.searchNodes;

export const listCatalog = (state: RootState) => state.catalogs.listCatalogs;
export const listMccCatalog = (state: RootState) =>
  state.catalogs.listMccCatalog;
export const isLoadingCatalog = (state: RootState) =>
  state.catalogs.isLoadingCatalog;

export const selectWalletOpen = (state: RootState) =>
  state.app.mainAccordion.isWalletOpen;
export const selectBalanceData = (state: RootState) => state.app.balanceData;
