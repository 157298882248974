import axios from "../../../shared/axios-util";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { MerchantBillingInfo } from "../../../../types/merchant_billing_info";
import { GetNodeInfoResponse } from "../../../../types/get_node_info_response";
import { ConfigEnum } from "../../../shared/enums/ConfigEnum";
import { MerchantResponse } from "../../../../types/get_merchant_response";
import { MerchantData } from "../../../../types/merchant-data";
import { mapConfiguration } from "../../../shared/utils/mapConfiguration";

export const getNodeInfo = createAsyncThunk<
  GetNodeInfoResponse,
  { publicMerchantId: string; configurations?: string }
>("app/getNodeInfo", async ({ publicMerchantId, configurations }) => {
  const response = await axios.post<GetNodeInfoResponse>(API_ROUTES.NODE_INFO, {
    configIds: configurations || `${ConfigEnum.BASIC_DATA}`,
    publicMerchantId,
  });

  return response.data;
});

export const getMerchantNodeInfo = createAsyncThunk<
  { data: MerchantResponse | MerchantData; configId: ConfigEnum },
  { publicMerchantId: string; configuration: ConfigEnum }
>("app/getMerchantNodeInfo", async ({ publicMerchantId, configuration }) => {
  const response = await axios.post<MerchantResponse | MerchantData>(
    API_ROUTES.MERCHANT_NODE_INFO,
    {
      configId: configuration,
      publicMerchantId,
    }
  );

  return {
    configId: configuration,
    data: mapConfiguration(configuration, response.data),
  };
});

export const getMerchantInfo = createAsyncThunk<
  MerchantBillingInfo,
  { publicMerchantId: string }
>("app/getMerchantInfo", async ({ publicMerchantId }) => {
  const response = await axios.post<MerchantBillingInfo>(
    API_ROUTES.MERCHANT_INFO,
    { publicMerchantId }
  );

  return response.data;
});
