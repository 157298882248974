import { AnyAction, createAsyncThunk, ThunkDispatch } from "@reduxjs/toolkit";
import {
  hideLoading,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../../actions/app/app";
import { IResponseUpdateTrxsStatusWS } from "./update_transactions_status.interfaces";
import { TransactionsConciliationRequest } from "../../../../../types/transactions_conciliation_request";
import { updateTransactionsStatusWS } from "../../../../shared/utils/websockets/updateTransactionsStatus/update_transactions_status_ws";
import { IHandleResponseWS } from "../../../../shared/utils/websockets/updateTransactionsStatus/update_transactions_status_ws.interfaces";
import { WEBSOCKETS_ENDPOINTS } from "../../../../shared/constants/websockets_endpoints";
import { WebsocketStatusEnum } from "../../../../shared/infrastructure/enums/WebsocketStatusEnum";

export const handleResponse = (
  response: MessageEvent<string>,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>
): IHandleResponseWS => {
  const { status }: IResponseUpdateTrxsStatusWS = JSON.parse(response.data);

  dispatch(hideLoading());

  if (status === "put-dynamo" || status === WebsocketStatusEnum.COMPLETE) {
    dispatch(showSuccessSnackbar());

    return {
      closeConnection: true,
    };
  } else {
    dispatch(showErrorSnackbar());

    return {
      closeConnection: false,
    };
  }
};

const updateTransactionsStatus = createAsyncThunk(
  "transactions/updateTransactionsStatus",
  async (data: TransactionsConciliationRequest, thunkAPI) => {
    return await updateTransactionsStatusWS(
      WEBSOCKETS_ENDPOINTS.updateTransactionsState,
      {
        data,
        dispatch: thunkAPI.dispatch,
        handleResponse,
      }
    );
  }
);

export default updateTransactionsStatus;
