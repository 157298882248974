import React, { FC } from "react";
import { useTransactionList } from "./state/useTransactionList";
import { Box } from "@mui/material";
import DashboardTransaction from "../DashboardTransaction/DashboardTransaction";
import { DialogReturnRequest } from "../DialogComponent/DialogReturnRequest/DialogReturnRequest";
import { DialogAlertTimeRefund } from "../DialogComponent/DialogAlertTimeRefund/DialogAlertTimeRefund";
import { ModalLoader } from "../ModalLoader/ModalLoader";
import {
  MODAL_LOADER_DESCRIPTION,
  MODAL_LOADER_TITLE,
} from "../../shared/constants/labels/transaction_list_labels";
import { DialogMessage } from "../DialogComponent/DialogMessage/DialogMessage";
import { ITransactionList } from "./TransactionList.interfaces";

const TransactionList: FC<ITransactionList> = ({
  mechantFilter,
}: ITransactionList) => {
  const {
    showEmptyScreen,
    emptyAddText,
    transactionList,
    entityName,
    pathCustomer,
    dialogAlert,
  } = useTransactionList(mechantFilter);

  return (
    <>
      <Box width={"100%"}>
        <DashboardTransaction
          showEmptyScreen={showEmptyScreen}
          emptyAddText={emptyAddText}
          transactionData={transactionList}
          basicNodeInfo={{
            entityName,
            path: pathCustomer,
          }}
          isBranchTab={false}
          handleOpenDialog={dialogAlert.handleDialogAlertTimeRefund}
          mechantFilter={mechantFilter}
        />
      </Box>
      <ModalLoader
        titleText={MODAL_LOADER_TITLE}
        descriptionText={MODAL_LOADER_DESCRIPTION}
        isOpen={dialogAlert.isLoadingTimeRefund}
      />
      <DialogMessage
        onCancel={dialogAlert.handleCloseDialogVoid}
        openDialog={dialogAlert.openDialogVoid}
        transaction={dialogAlert.trxInfo}
        type={dialogAlert.typeTrx}
        amount={dialogAlert.amountTrx}
        handleOnDelete={dialogAlert.handleOnDelete}
        handleTypeRefund={dialogAlert.handleTypeRefund}
        errorAmount={dialogAlert.errorAmount}
        typeRefund={dialogAlert.typeRefund}
        value={dialogAlert.value}
        handleChangeValue={dialogAlert.handleChangeValue}
        valorRefund={dialogAlert.valorRefund}
      />
      <DialogReturnRequest dialog={dialogAlert.openReturnRequestDialog} />
      <DialogAlertTimeRefund
        openDialog={dialogAlert.openDialogAlertTimeRefund}
        onCancel={dialogAlert.handleCloseDialogAlertTimeRefund}
      />
    </>
  );
};

export default TransactionList;
