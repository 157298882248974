import React from "react";
import { ModalBody } from "@kushki/frontend-molecules/modal-body";
import { get, isEmpty } from "lodash";
import { KindEnum } from "../../shared/infrastructure/KindEnum";
import { InfoTrxProps } from "./ModalBodyTabs";
import { createStyles, Grid, Paper } from "@material-ui/core";
import { ModalBodyContentDetail } from "../ModalTabs/ModalBodyContentDetail";
import {
  subSectionEnum,
  tabEnum,
} from "../../shared/infrastructure/AdvanceConfigModal";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import { makeStyles } from "@material-ui/core/styles";
import { IKshTab, KshTab } from "../Common/KshTab/KshTab";
import {
  DynamoReferenceEnum,
  getDynamoElasticRef,
} from "../../shared/infrastructure/constants/DynamoElasticMap";
import { StatusFlowEnum } from "../../shared/infrastructure/StatusFlowEnum";
import { EmptyStateTab } from "../Common/EmptyStateTab/EmptyStateTab";
import { StatusEnum } from "../../shared/infrastructure/StatusEnum";
import { ModalBodyObservations } from "../ModalTabs/ModalBodyObservations";
import { AdvanceConfigTabDetail } from "../ModalTabs/AdvanceConfigTabDetail/AdvanceConfigTabDetail";
import {
  EmptyAdvanceConfigurationMessage,
  EmptyObservationMessage,
} from "../../shared/infrastructure/constants/MessageEmptyDetailModalInvoice";
import { TimeLineTabHistoricComponent } from "../TabComponent/TimeLineTabHistoricComponent/TimeLineTabHistoricComponent";
import { TextArea } from "./ModalBodyColombia";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      boxShadow: "none !important",
      borderRadius: "0px !important",
      backgroundColor: "#F7FAFC !important",
      marginBottom: "30px !important",
      marginTop: "30px !important",
    },
    grid: {
      paddingTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
    },
  })
);

const PaperContentDetail = (
  props: InfoTrxProps,
  subSection: subSectionEnum,
  isCharge: boolean = false
) => {
  const classes = useStyles();
  const trx = props.trxInfo;

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2} color="secondary" className={classes.grid}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <ModalBodyContentDetail
              tabName={tabEnum.infoTab}
              trxProps={props}
              country={get(trx, "country", CountryEnum.peru)}
              subSection={subSection}
              isMinAmount={props.isMinAmount}
              isCharge={isCharge}
              isHistoric={props.isHistoric}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const renderListTabsHistoric = (
  props: InfoTrxProps,
  isHistoric: boolean
): IKshTab => {
  const kind: KindEnum = get(
    props.trxInfo,
    getDynamoElasticRef(DynamoReferenceEnum.KIND, isHistoric),
    ""
  ) as KindEnum;

  const dataTabs: { title: string; content: JSX.Element }[] = [];

  [KindEnum.INVOICE, KindEnum.VOUCHER].includes(kind)
    ? dataTabs.push({
        title: "Config. avanzada",
        content:
          !isEmpty(get(props.trxInfo, "advance_settings_reference")) ||
          !isEmpty(get(props.trxInfo, "advance_settings_parameters")) ? (
            <AdvanceConfigTabDetail
              InfoTrxProps={props}
              country={props.country as CountryEnum}
              isHistoric={props.isHistoric}
            />
          ) : (
            <EmptyStateTab
              title={EmptyAdvanceConfigurationMessage[kind]}
              subtitle='Para agregarlas, haz click en "Configuración Avanzada"'
            />
          ),
      })
    : null;

  [
    KindEnum.INVOICE,
    KindEnum.VOUCHER,
    KindEnum.CHARGE,
    KindEnum.DISPERSION,
  ].includes(kind)
    ? dataTabs.push({
        title: "Observaciones",
        content:
          get(props.trxInfo, "status") === StatusEnum.REJECTED ? (
            <ModalBodyObservations
              InfoTrxProps={props}
              country={props.country as CountryEnum}
            />
          ) : (
            <EmptyStateTab
              title={EmptyObservationMessage[kind]}
              isProcessData={true}
            />
          ),
      })
    : null;

  [
    KindEnum.CREDIT_NOTE,
    KindEnum.DEBIT_NOTE,
    KindEnum.INVOICE,
    KindEnum.VOUCHER,
    KindEnum.CHARGE,
    KindEnum.DISPERSION,
  ].includes(kind)
    ? dataTabs.push({
        title: "Línea de tiempo",
        content:
          get(props.trxInfo, DynamoReferenceEnum.OLD_TRANSACTIONS, [])
            .length !== 0 ? (
            <TimeLineTabHistoricComponent {...props} />
          ) : (
            <EmptyStateTab title="Esta factura no tiene Línea de Tiempo" />
          ),
      })
    : null;

  const tabs: IKshTab = {
    tabs: [
      {
        title: "Información",
        content: renderGeneralInfo(props),
      },
      ...dataTabs,
    ],
  };

  return tabs;
};

const renderGeneralInfo = (props: InfoTrxProps) => {
  const trx = props.trxInfo;
  const validation: boolean =
    props.statusFlow == StatusFlowEnum.VALIDATOR &&
    get(props.trxInfo, "status", "") === "manual";

  switch (get(trx, "kind", "")) {
    case KindEnum.INVOICE:
      return (
        <>
          {PaperContentDetail(props, subSectionEnum.infoCard1)}
          {PaperContentDetail(props, subSectionEnum.infoCard2)}
          {get(props, "trxInfo.is_ocb", false)
            ? PaperContentDetail(props, subSectionEnum.infoCard4)
            : PaperContentDetail(props, subSectionEnum.infoCard2_0)}
          {PaperContentDetail(props, subSectionEnum.infoCard3)}
        </>
      );
    case KindEnum.CREDIT_NOTE:
      return (
        <>
          {PaperContentDetail(props, subSectionEnum.infoCreditNoteInfo)}
          {PaperContentDetail(props, subSectionEnum.infoCreditNoteBank)}
          {PaperContentDetail(props, subSectionEnum.addressCreditNote3)}
        </>
      );
    case KindEnum.VOUCHER:
      return (
        <>
          {PaperContentDetail(props, subSectionEnum.infoCardVoucher1)}
          {get(props, "trxInfo.is_ocb", false)
            ? PaperContentDetail(props, subSectionEnum.infoCard4)
            : PaperContentDetail(props, subSectionEnum.infoCard2_0)}
          {PaperContentDetail(props, subSectionEnum.infoCardVoucher2)}
        </>
      );
    case KindEnum.DEBIT_NOTE:
      return (
        <>
          {PaperContentDetail(props, subSectionEnum.infoCardDebitNote1)}
          {PaperContentDetail(props, subSectionEnum.infoCardDebitNote2)}
          {PaperContentDetail(props, subSectionEnum.infoCard3)}
        </>
      );
    case KindEnum.DISPERSION:
      return (
        <>
          {PaperContentDetail(props, subSectionEnum.infoCardDispersion1)}
          {PaperContentDetail(props, subSectionEnum.infoCardDispersion2)}
          {get(props, "trxInfo.is_ocb", false)
            ? PaperContentDetail(props, subSectionEnum.infoCard4)
            : PaperContentDetail(props, subSectionEnum.infoCard2_0)}
          {PaperContentDetail(props, subSectionEnum.infoCard3)}
        </>
      );
    case KindEnum.CHARGE:
      return (
        <>
          {PaperContentDetail(props, subSectionEnum.infoCardCharge1, true)}
          {PaperContentDetail(props, subSectionEnum.infoCardCharge2, true)}
          {get(props, "trxInfo.is_ocb", false)
            ? PaperContentDetail(props, subSectionEnum.infoCard4)
            : PaperContentDetail(props, subSectionEnum.infoCard2_0)}
          {PaperContentDetail(props, subSectionEnum.infoCardCharge3, true)}
        </>
      );
    case KindEnum.RECEIVABLE:
      return (
        <>
          {PaperContentDetail(props, subSectionEnum.infoCardReceivable1)}
          {PaperContentDetail(props, subSectionEnum.infoCardReceivable2)}
          {PaperContentDetail(props, subSectionEnum.infoCard3)}

          {validation && <TextArea {...props} />}
        </>
      );
    default:
      return <></>;
  }
};

const ModalBodyPeruInfo = (props: InfoTrxProps) => {
  return (
    <ModalBody>
      <React.Fragment>
        {props.isHistoric ||
        get(
          props.trxInfo,
          getDynamoElasticRef(
            DynamoReferenceEnum.STATUS_FLOW,
            props.isHistoric
          ),
          ""
        ) === StatusFlowEnum.VALIDATOR ? (
          <KshTab {...renderListTabsHistoric(props, props.isHistoric)} />
        ) : (
          renderGeneralInfo(props)
        )}
      </React.Fragment>
    </ModalBody>
  );
};

export default ModalBodyPeruInfo;
