import React from "react";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import {
  IconCheck,
  IconEdit,
  IconSquareCheck,
  IconTrash,
} from "@kushki/connect-ui";
import { ListUrlsStyles as styles } from "./ListUrls.styles";
import { useListUrl } from "./state/UseListUrl";
import { ListUrlsProps } from "./ListUrls.interfaces";
import { IconColorsEnum } from "../../shared/constants/enums/IconColorsEnum";
import { UrlStatusEnum } from "../../shared/constants/enums/UrlStatusEnum";
import { Close } from "@mui/icons-material";

const ListUrls = ({ url, state }: ListUrlsProps) => {
  const {
    urlTemp,
    edit,
    handleEdit,
    handleDelete,
    handleCheck,
    handleCancel,
    handleChangeInput,
  } = useListUrl({ url });

  return (
    <Box sx={styles.container}>
      {edit ? (
        <Box sx={styles.rowContainer}>
          <TextField
            name="urlListInput"
            variant="outlined"
            value={urlTemp}
            onChange={handleChangeInput}
            fullWidth
            sx={styles.textField}
          />
          <Box sx={styles.buttonContainer}>
            <IconButton onClick={handleCheck}>
              <IconCheck fontSize="large" color={IconColorsEnum.SUCCESS} />
            </IconButton>
            <IconButton onClick={handleCancel}>
              <Close fontSize={"medium"} color={IconColorsEnum.ERROR} />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Box sx={styles.rowContainer}>
          <Box sx={styles.urlContainer}>
            <IconSquareCheck
              fontSize="medium"
              color={
                state === UrlStatusEnum.OK
                  ? IconColorsEnum.SUCCESS
                  : IconColorsEnum.ERROR
              }
            />
            <Typography sx={styles.url}>{url}</Typography>
          </Box>
          <Box sx={styles.buttonContainer}>
            <IconButton onClick={handleEdit}>
              <IconEdit fontSize="medium" />
            </IconButton>
            <IconButton onClick={handleDelete}>
              <IconTrash fontSize="medium" />
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ListUrls;
