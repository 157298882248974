import { ModalLoader, SnackbarAlert } from "@kushki/connect-ui";
import * as BILLING_LABELS from "../../shared/constants/labels/billing_form_labels";
import Snackbar from "@mui/material/Snackbar";
import React from "react";
import SummaryMassiveBranch from "../SummaryMassiveBranch/SummaryMassiveBranch";
import { wrapperContainerStyles as styles } from "../WrapperContainer/WrapperContainer.styles";
import { get, isEmpty } from "lodash";
import FooterOptions from "../FooterOptions/FooterOptions";
import { TypeRequestEnum } from "../../shared/enums/typeRequestEnum";
import { Box, Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import WrappedTabs from "../WrappedTabs/WrappedTabs";
import { useWrapperMassiveContainer } from "./state/useWrapperMassiveContainer";
import { IWrapperMassiveContainer } from "./WrapperMassiveContainer.interfaces";
import SelectedBranch from "../Modals/SelectedBranch/SelectedBranch";
import MassiveModalDialog from "../Modals/MassiveModalDialog/MassiveModalDialog";

const WrapperMassiveContainer = ({
  children,
  currentRoute,
  type,
}: IWrapperMassiveContainer) => {
  const {
    activeFooterButton,
    openModalLoader,
    snackbar,
    tabs,
    operationCountry,
    footerButtons,
    securityIdPrimary,
    securityIdSecondary,
    footerLabels,
    handleModalDialog,
    handleOpenSelectedBranch,
    openSelectedBranch,
  } = useWrapperMassiveContainer({
    currentRoute,
    type,
  });

  return (
    <>
      <ModalLoader
        descriptionText={BILLING_LABELS.DESCRIPTION_LOADER}
        isOpen={openModalLoader}
        titleText={BILLING_LABELS.LOADER_TITLE}
      />
      <MassiveModalDialog />
      <Snackbar
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        open={snackbar.showError || snackbar.showSuccess}
        autoHideDuration={3000}
      >
        <SnackbarAlert
          variant={"simple"}
          message={
            snackbar.showSuccess
              ? BILLING_LABELS.SNACKBAR_FETCH_SUCCESS
              : BILLING_LABELS.SNACKBAR_FETCH_ERROR
          }
          color={
            snackbar.showError
              ? "danger"
              : snackbar.showSuccess
              ? "success"
              : "info"
          }
        />
      </Snackbar>
      <Box
        sx={{
          ...styles.containerBody,
        }}
      >
        <Box>
          <Button
            data-testid="idReturnButton"
            sx={styles.returnButton}
            variant="text"
            size="small"
            startIcon={<ArrowBackIosIcon />}
            onClick={() => handleModalDialog(true)}
          >
            {BILLING_LABELS.RETURN_BUTTON_OPERATION_EDIT_LABEL}
          </Button>
        </Box>
        {
          <SummaryMassiveBranch
            handleOpenSelectedBranch={handleOpenSelectedBranch}
          />
        }

        <Box sx={styles.wrappedTabs}>
          <WrappedTabs tabs={tabs} />
        </Box>
        <Box sx={styles.containerForm}>{children}</Box>
      </Box>
      <Box sx={styles.containerFooter}>
        {!isEmpty(operationCountry) && (
          <FooterOptions
            primaryButton={{
              componentIdSecurity: securityIdPrimary!(type!),
              isDisabled: footerButtons.disableContinue,
              isHidden: footerButtons.isHidden,
              onAction: () => activeFooterButton(TypeRequestEnum.FINISHED),
            }}
            secondaryButton={{
              componentIdSecurity: securityIdSecondary!(type!),
              isDisabled: footerButtons.disableSave,
              onAction: () => activeFooterButton(TypeRequestEnum.SAVE),
            }}
            isLoading={false}
            isMassive={true}
            label={{
              editDetail: false,
              isHidden: footerLabels === null,
              modify: get(footerLabels, "date", ""),
              text: get(footerLabels, "author", ""),
            }}
          />
        )}
      </Box>
      {openSelectedBranch && (
        <SelectedBranch
          isOpen={true}
          handleOpenSelectedBranch={handleOpenSelectedBranch}
        />
      )}
    </>
  );
};

export default WrapperMassiveContainer;
