import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import {
  MerchantNodeData,
  SearchMerchantResponse,
} from "../../../../types/search_merchant_response";
import { SearchMerchantRequest } from "../../../../types/search_merchant_request";
import { getJwtAuth } from "../../../shared/utils/getJwtAuth_utils";
import { EntityNameEnum } from "../../../shared/enums/EntityNameEnum";
import {
  changeStatusNodesByIds,
  generateRequiredConfigsLabel,
  nodeInfo,
  searchBranches,
  validateRequiredConfigs,
  validateUserConfig,
} from "../../../shared/utils/verifyStatus.utils";
import { IChangeStatusNodes } from "../../interfaces/customer.interfaces";
import { SearchMerchantByNodeId } from "../../../../types/search_merchant_by_node_id";
import { each, find, get } from "lodash";
import { setIsLoadingFlow } from "../../actions/owner.actions";
import { INodeInfoResponse } from "../../../../types/node_info_response";

export const searchMerchants = createAsyncThunk<
  SearchMerchantResponse,
  SearchMerchantRequest
>("customer/searchMerchant", (request, { dispatch }) => {
  return axios
    .post<SearchMerchantResponse>(API_ROUTES.SEARCH_MERCHANT_NODE, request, {
      headers: {
        Authorization: getJwtAuth(),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      dispatch(setIsLoadingFlow(false));

      return response.data;
    });
});

export const searchMerchantByNode = createAsyncThunk<
  MerchantNodeData,
  SearchMerchantByNodeId
>("customer/searchMerchantByNodeId", (request) =>
  axios
    .get<SearchMerchantResponse>(
      `${API_ROUTES.SEARCH_MERCHANT_NODE_BY_NODE_ID}${request.nodeId}`,
      {
        headers: {
          Authorization: getJwtAuth(),
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => response.data)
);

export const searchMerchantBillingCore = createAsyncThunk<
  MerchantNodeData,
  SearchMerchantByNodeId
>("customer/searchMerchantBillingCore", (request) =>
  axios
    .post<INodeInfoResponse>(
      API_ROUTES.NODE_INFO,
      { publicMerchantId: request.nodeId },
      {
        headers: {
          Authorization: getJwtAuth(),
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => response.data)
);

export const changeStatusNodes = createAsyncThunk<boolean, IChangeStatusNodes>(
  "customer/changeStatusNodes",
  async (request: IChangeStatusNodes, { dispatch }) => {
    dispatch(setIsLoadingFlow(true));
    if (request.entityName === EntityNameEnum.CUSTOMER) {
      const searchAllBranches = await Promise.all(
        request.itemsSelected.map((item) =>
          searchBranches({
            entityName: EntityNameEnum.BRANCH,
            filter: {
              merchantStatus: request.status,
            },
            limit: 500,
            path: item.path,
          })
        )
      );

      const concatResponse: string[] = [];

      await searchAllBranches.forEach((branch) => {
        branch.data.data!.forEach((element) => {
          concatResponse.push(element.merchant_id!);
        });
      });
      await request.itemsSelected.forEach((customer) => {
        concatResponse.push(customer.merchant_id!);
      });

      const changeStatus = await changeStatusNodesByIds(
        request.status,
        concatResponse
      );

      return changeStatus.status !== 200;
    } else {
      const merchantIdsBranches: string[] = request.itemsSelected.map(
        (item) => {
          return item.merchant_id!;
        }
      );

      const changeStatus = await changeStatusNodesByIds(
        request.status,
        merchantIdsBranches
      );

      return changeStatus.status !== 200;
    }
  }
);

export const addAditionalProperties = createAsyncThunk<
  MerchantNodeData[],
  MerchantNodeData[]
>(
  "customer/addCentralized",
  async (request: MerchantNodeData[], { dispatch }) => {
    const nodeInfoResponse = await Promise.all(
      request.map((branch) => nodeInfo(get(branch, "merchant_id", "")))
    );
    const nodes: MerchantNodeData[] = [];

    each(nodeInfoResponse, (branch) => {
      const matchedNode = find(
        request,
        (item) => item.merchant_id === branch.data.merchantId
      );

      if (get(branch, "data.entityName") === EntityNameEnum.CUSTOMER) {
        nodes.push({
          ...matchedNode,
          configs_completed: validateRequiredConfigs(branch.data),
          pending_section: generateRequiredConfigsLabel(branch.data),
          user_config_completed: validateUserConfig(branch.data),
        });
      } else {
        nodes.push({
          ...matchedNode,
          configs_completed: validateRequiredConfigs(branch.data),
          pending_section: generateRequiredConfigsLabel(branch.data),
          user_config_completed: validateUserConfig(branch.data),
        });
      }
    });

    dispatch(setIsLoadingFlow(false));

    return nodes;
  }
);
