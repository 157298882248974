/**
 * Processor Enumeration
 */
export enum ProcessorEnum {
  kushki = "Kushki adquiriente",
  billpocket = "Billpocket",
  elavon = "Elavon",
  prosa = "Prosa (Pasarela)",
  amex = "AMEX",
}

export const PROCESSORS_LIST: string[] = [
  ProcessorEnum.kushki,
  ProcessorEnum.billpocket,
  ProcessorEnum.elavon,
  ProcessorEnum.prosa,
  ProcessorEnum.amex,
];

export enum ProcessoNameEnum {
  kushki = "Kushki Acquirer Processor",
  billpocket = "BillPocket Processor",
  elavon = "Elavon Processor",
  prosa = "Prosa Processor",
  amex = "Amex",
}
