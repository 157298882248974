import { makeStyles } from "@mui/styles";

export const genericAddButtonStyles = makeStyles({
  addButton: {
    marginTop: "10px !important",
    marginBottom: "4px !important",
    color: "#4498EE",
    fontWeight: "bold !important",
    textAlign: "left",
    "&:hover": {
      boxShadow: "1px 1px transparent !important",
      backgroundColor: "#FFFF !important",
    },
  },
});
