import { ConfigIdEnum } from "../constants/initial_state_legal_details";
import {
  Configs,
  GetNodeInfoResponse,
} from "../../../types/get_node_info_response";
import { get, isEmpty } from "lodash";
import { EntityNames } from "../enum/ContactDataStateMX/ContactDataLabelMX";

export const isCentralizedConfig = (
  nodeInfo: GetNodeInfoResponse,
  configType: ConfigIdEnum
) => {
  const config = get(nodeInfo, "configs", []).find(
    (c: Configs) => c.configuration === configType
  );

  return (
    !isEmpty(get(config, "centralizedNodesId", "")) &&
    get(nodeInfo, "entityName") === EntityNames.BRANCH
  );
};
