export enum ActionTypes {
  SET_REQUEST_ERROR = "[BUSINESS-RULES] SET REQUEST ERROR",
  SET_IS_REQUESTING = "[BUSINESS-RULES] SET IS REQUESTING",
  SET_LOADING_MODAL = "[BUSINESS-RULES] SET LOADING MODAL",
  SET_SAVE_RULE_MODAL = "[BUSINESS-RULES] SET SAVE RULE MODAL",
  SET_IS_OPEN_CREATE_MODAL = "[BUSINESS-RULES] SET IS OPEN CREATE MODAL",
  GET_LIST_PROCESSORS = "[BUSINESS-RULES] GET LIST PROCESSORS",
  GET_CONFIG_LIST = "[BUSINESS-RULES] GET CONFIG LIST",
  SET_NEW_RULES = "[BUSINESS-RULES] SET NEW RULES",
  SET_BUSINESS_RULES = "[BUSINESS-RULES] SET BUSINESS RULES",
  GET_DETAIL_RULES = "[BUSINESS-RULES] GET DETAIL RULES",
  GET_GEOGRAPHY_LIST = "[BUSINESS-RULES] GET GEOGRAPHY LIST",
  SET_COUNTRY_RULES = "[BUSINESS-RULES] SET COUNTRIES RULES",
  SET_CURRENCY = "[BUSINESS-RULES] SET CURRENCY",
  GET_SUGGEST_CREDENTIALS = "[BUSINESS-RULES] GET SUGGEST CREDENTIALS",
  GET_CUSTOMER_CREDENTIALS = "[BUSINESS-RULES] GET CUSTOMER CREDENTIALS",
  SET_OPEN_MODAL_INFO = "[RULES] SET OPEN MODAL INFO",
  SET_IS_LOADING_RULES = "[RULES] SET_IS_LOADING_RULES",
  SET_SEARCH_REQUEST_RULES_RESPONSE = "[RULES] SET SEARCH REQUEST RULES RESPONSE",
  SET_IS_LOADING_ONE_RULE = "[RULES] SET IS LOADING ONE RULE",
  SET_SEARCH_REQUEST_BY_ID = "[RULES] SET SEARCH REQUEST BY ID",
  SET_NEW_RULE_STATE = "[RULES] SET NEW RULE STATE",
  SET_OPEN_ALERT = "[BUSINESS-RULES] SET OPEN ALERT",
  SET_SUCCESS_ENABLE_RULE = "[BUSINESS-RULES] SET SUCCESS ENABLE RULE",
  SET_OPEN_CONFIRM_MODAL = "[BUSINESS-RULES] SET OPEN CONFIRM MODAL",
  SET_NODE_INFO = "[BUSINESS-RULES] SET NODE INFO MERCHANT",
  SET_PARENT_MERCHANT_ID = "[BUSINESS-RULES] SET PARENT MERCHANT ID",
  SET_ATOMIC_ID = "[BUSINESS-RULES] SET ATOMIC ID",
  SET_PREFIX_ATOMIC_COUNTER = "[BUSINESS-RULES] SET PREFIX ATOMIC COUNTER",
  SET_SEARCH_FILTER_RULES_RESPONSE = "[RULES] SET SEARCH FILTER RULES RESPONSE",
}
