import { BreadcrumProps } from "../../../components/common/Breadcrumb/Breadcrumb";
import { BreadCrumsItems } from "../../../shared/infrastructure/constants/BreadCrums";
import { useHistory, useLocation } from "react-router-dom";
import { CountryValueEnum } from "../../../shared/infrastructure/Enums/CountryEnum";
import { getCountry } from "../../../shared/infrastructure/constants/MerchantContactConstants";
import { routes } from "../../../shared/infrastructure/routes";
import {
  IvaRetentionEnum,
  RetentionCatalog,
  RetentionDatil,
  RetentionIva,
  RetentionIvaEdit,
  RetentionPercentCodeEnum,
  RetentionType,
} from "../../../shared/infrastructure/constants/RetentionConstants";
import { KeyValue } from "../../../../types/key_value";
import React, { RefObject, useEffect, useState } from "react";
import { FieldError, NestDataObject, useForm } from "react-hook-form";
import {
  createRetentionsConfig,
  getCatalogsList,
  indexTabEditRetentionRender,
  setAddRetention,
  setAddRetentionDirectly,
  setCodeDatil,
  setEditRetention,
  setEditRetentionDashboard,
  setExistRetention,
  setLoadByFormError,
  setLoadingRetentionDashboard,
  setNotification,
  setRetention,
  setValidateRetentionEdit,
  validateCodeDatil,
  validExistRetentionById,
} from "../../../store/actionCreators";
import { defaultTo, get, isEmpty, set } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { RetentionMerchantConfigEc } from "../../../../types/retention_merchant_config_ec";
import { RetentionMerchantConfigDynamo } from "../../../../types/retention_merchant_configuration";
import {
  columnsRetentionIVATable,
  columnsRetentionRENTATable,
  IColumnsTable,
} from "../../../shared/infrastructure/Table/ColumnsTable";
import { GetDatilCodeRequest } from "../../../../types/get_datil_code_request";
import { SecurityWrapperEnum } from "../../../shared/infrastructure/Enums/SecurityWrapperEnum";
import { ISecurityAzureRoles } from "../../../shared/infrastructure/interfaces/ISecurityAzureRoles";
import { CatalogsRequestEnum } from "../../../shared/infrastructure/Enums/CatalogsRequestEnum";
import { CatalogsNameEnum } from "../../../shared/infrastructure/Enums/CatalogsNameEnum";
import { getCatalogDataByCode } from "../../../utils/functions/Catalog";
import { Data } from "../../../../types/catalog";
import { TabEnum } from "../../../shared/infrastructure/Enums/TabEnum";

export interface IUploadFormIndexState {
  breadCrumbs: BreadcrumProps;
  country: CountryValueEnum;
  ivaRetention?: KeyValue[] | undefined;
  retIva?: string;
  textButton?: string;
  methods: {
    onSubmit: () => void;
    returnTo: (route: string) => void;
    handleOnChangeSelection: (
      taxpayer: KeyValue | null,
      identifier: string
    ) => void;
    handleValueRetentionChange: (value: string, identifier: string) => void;
    handleButtonAction: () => void;
    onSaveConfiguration: () => void;
    handleOnChangeTab: (value: number) => void;
  };
  values: {
    taxpayerValue?: KeyValue | null;
    activityValue?: KeyValue | null;
    ivaRetentionValues?: KeyValue | null;
    incomeSourceConceptValue?: KeyValue | null;
    retIvaValue?: string;
    rentRetentionValue?: string;
    isDataSavedLimit?: boolean;
    isDataSaved?: boolean;
    isSaving?: boolean;
    isIVA?: boolean;
  };
  form: {
    errors: NestDataObject<Record<string, any>, FieldError>;
    register: (
      obj: object
    ) => ((instance: any) => void) | RefObject<any> | null | undefined;
    errorOnSubmit: boolean;
  };
  modalDetailProps: {
    openModal: boolean;
    setOpenModalDetail: () => void;
    setCloseModalDetail: () => void;
    returnWithoutSave: () => void;
  };
  data: {
    retentionsData: RetentionMerchantConfigDynamo[];
    retentionColumns: IColumnsTable[];
    isLoading: boolean;
    onHandleEditAction: (row: RetentionMerchantConfigDynamo) => void;
  };
  modalDeleteProps: {
    openDeleteModal: boolean;
    handleAcceptModal: () => void;
    handleOpenModal: (index: number) => void;
    handleCloseModal: () => void;
    isLoadingDelete: boolean;
  };
  actionVerifyRetention: {
    isLoadingVerifyRetention: boolean;
    existRetention: boolean;
    handlerValidateDatilCode: () => void;
    handlerVerifyExistRetentionId: () => void;
    handlerCloseChangeConfigRetention: () => void;
  };
  edit: IEditFrom;
  securityAzure?: ISecurityAzureRoles;
}

export interface IEditFrom {
  isEdit: boolean;
  value: number;
  onSubmitItem: () => void;
  returnNewRetention: () => void;
  openModalEdit: boolean;
  handleNotificationAfter: () => void;
  addRetentionDirectly: boolean;
  isLoadingCreateItem: boolean;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const useFormIndexState = (): IUploadFormIndexState => {
  const stateStore = useSelector<IAppState, IAppState>(
    (state: IAppState) => state
  );
  let [textButton, setTextButton] = useState("Agregar");
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();

  const [ivaRetention, setIvaRetention] = useState<KeyValue[]>();
  const [taxpayerValue, setTaxpayerValue] = React.useState<KeyValue | null>();
  const [activityValue, setActivityValue] = React.useState<KeyValue | null>();
  const [
    ivaRetentionValues,
    setIvaRetentionValues,
  ] = React.useState<KeyValue | null>();
  const [
    incomeSourceConceptValue,
    setIncomeSourceConceptValue,
  ] = React.useState<KeyValue | null>();
  const { register, errors, setError, triggerValidation, clearError } = useForm(
    {
      mode: "onBlur",
      reValidateMode: "onBlur",
    }
  );
  const [rentRetentionValue, setRentRetentionValue] = useState("");
  const [isDataSaved, setIsDataSaved] = useState(false);
  const [isDataSavedLimit, setIsDataSavedLimit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const country: CountryValueEnum = query.get("country") as CountryValueEnum;
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [retentionRow, setRetentionRow] = useState<
    RetentionMerchantConfigDynamo
  >({ country: "ecuador", created: 0, id: "", userName: "" });
  const [isIVA, setIsIVA] = useState(true);
  const [columnsRetentionTable, setColumnsRetentionTable] = useState(
    columnsRetentionIVATable
  );
  const [securityRol, setSecurityRol] = useState<ISecurityAzureRoles>({
    rolFormActions: SecurityWrapperEnum.RETENCIONES_AGREGAR_EDITAR_IVA,
  });

  const assignData = (codeType: any, retentionType: string): KeyValue[] => {
    let dataArray: KeyValue[] = [];
    for (let i in codeType) {
      let tax: KeyValue = {
        key: i,
        value: RetentionCatalog[retentionType][i],
      };
      dataArray.push(tax);
    }
    return dataArray;
  };

  useEffect(() => {
    setIvaRetention(assignData(IvaRetentionEnum, RetentionType.ivaRetention));
    dispatch(setRetention([]));
    dispatch(
      getCatalogsList({
        productLine: CatalogsRequestEnum.PLA,
        configCode: CatalogsRequestEnum.CN003,
        countryCode: CatalogsRequestEnum.ECUADOR,
      })
    );
  }, []);

  const handleOnChangeSelection = (
    taxpayer: KeyValue | null,
    identifier: string
  ) => {
    switch (identifier) {
      case "0":
        setTaxpayerValue(taxpayer);
        break;
      case "1":
        setActivityValue(taxpayer);
        break;
      case "2":
        setIvaRetentionValues(taxpayer);
        break;
      case "3":
        setIncomeSourceConceptValue(taxpayer);
        break;
    }
  };

  const editRetail = (retation: RetentionMerchantConfigDynamo) => {
    setTextButton("Editar");
    const taxpayerTypeId = get(retation, "taxpayerTypeId", "");
    const activityId = get(retation, "activityId", "");
    const ivaRetention = get(retation, "retIva", "");
    const incomeSourceConceptId = get(retation, "incomeSourceConceptId", "");
    const taxpayerTypeData: Data = getCatalogDataByCode(
      defaultTo(stateStore.catalogsList, []),
      CatalogsNameEnum.TAX_PAYER_TYPE,
      taxpayerTypeId
    );
    const activityData: Data = getCatalogDataByCode(
      defaultTo(stateStore.catalogsList, []),
      CatalogsNameEnum.ACTIVITY,
      activityId
    );
    const incomeSourceConceptData: Data = getCatalogDataByCode(
      defaultTo(stateStore.catalogsList, []),
      CatalogsNameEnum.INCOME_SOURCE_CONCEPT,
      incomeSourceConceptId
    );
    const editTaxpayerTypeId: KeyValue = {
      key: taxpayerTypeData.code === "-" ? "" : taxpayerTypeData.code,
      value: taxpayerTypeData.value,
    };
    const editActivityId: KeyValue = {
      key: activityData.code === "-" ? "" : activityData.code,
      value: activityData.value,
    };
    let editIvaRetention: KeyValue = {
      key: ivaRetention,
      value: RetentionCatalog[RetentionType.ivaRetention][ivaRetention],
    };
    if (stateStore.editRetentionDashboard) {
      const retentionPercentCode: string = get(
        retation,
        "retentionPercentCode",
        ""
      );
      editIvaRetention = {
        key: RetentionIvaEdit[retentionPercentCode],
        value:
          RetentionCatalog[RetentionType.ivaRetention][
            RetentionIvaEdit[retentionPercentCode]
          ],
      };
    }

    let editIncomeSourceConceptId: KeyValue = {
      key:
        incomeSourceConceptData.code === "-"
          ? ""
          : incomeSourceConceptData.code,
      value: incomeSourceConceptData.value,
    };

    setTaxpayerValue(editTaxpayerTypeId);
    setActivityValue(editActivityId);
    setIvaRetentionValues(editIvaRetention);
    setIncomeSourceConceptValue(editIncomeSourceConceptId);
    if (stateStore.indexEditRetentionTab !== 0)
      setRentRetentionValue(get(retation, "retFue", 0).toString());
  };

  const handleValueRetentionChange = (value: string, identifier: string) => {
    clearError();
    const valueRetention: string[] = value.split(".");
    if (
      !Number(value) ||
      Number(value) > 100 ||
      get(valueRetention, "[1]", 0).length > 2
    ) {
      value = value.substring(0, value.length - 1);
    }

    if (identifier === "1") setRentRetentionValue(value);
  };

  const getDataRetentionMerchantConfigDynamo = (): RetentionMerchantConfigDynamo => {
    return {
      userName: "",
      created: new Date().getTime(),
      id: `${get(taxpayerValue, "key", "")}${get(
        activityValue,
        "key",
        ""
      )}${get(incomeSourceConceptValue, "key", "")}`.toLowerCase(),
      country: "ecuador",
      taxpayerTypeId: get(taxpayerValue, "key", "") as string,
      activityId: defaultTo(get(activityValue, "key"), "") as string,
      incomeSourceConceptId: get(
        incomeSourceConceptValue,
        "key",
        ""
      ).toString(),
      ivaRetention: IvaRetentionEnum[get(ivaRetentionValues, "key", "")],
      retIva: IvaRetentionEnum[get(ivaRetentionValues, "key", "")],
      retFue: Number(rentRetentionValue),
      retentionPercentCode: isIVA
        ? get(
            defaultTo(
              RetentionDatil[
                IvaRetentionEnum[get(ivaRetentionValues, "key", "")]
              ],
              {}
            ),
            "value",
            ""
          )
        : get(stateStore, "codeDatil"),
      isModify: get(stateStore, "existRetention"),
    };
  };
  const onSubmitItem = async () => {
    await handlerValidateDatilCode();
  };
  const setItemSave = async () => {
    let createRetailsDataTable: RetentionMerchantConfigDynamo = await getDataRetentionMerchantConfigDynamo();
    await dispatch(setRetention([createRetailsDataTable]));
  };
  useEffect(() => {
    (stateStore.editRetentionDashboard || stateStore.addRetentionDirectly) &&
      setItemSave();
  }, [ivaRetentionValues, rentRetentionValue]);

  const onSaveConfiguration = () => {
    setIsSaving(true);
    const retentionsData: RetentionMerchantConfigDynamo[] = get(
      stateStore,
      "retention",
      []
    );
    let createRetailsData: RetentionMerchantConfigEc[] = [];
    for (let i of retentionsData) {
      let createRetailsNewData: RetentionMerchantConfigEc;
      if (isIVA) {
        createRetailsNewData = {
          taxpayerTypeId: get(i, "taxpayerTypeId", "") as string,
          activityId: defaultTo(get(i, "activityId"), ""),
          retIva:
            RetentionIva[IvaRetentionEnum[get(i, "ivaRetention", "")]].value,
          isModify: get(i, "isModify"),
          retentionPercentCode:
            RetentionDatil[IvaRetentionEnum[get(i, "ivaRetention", "")]].value,
        };

        localStorage.setItem("retentionOrigin", String(0));
      } else {
        createRetailsNewData = {
          incomeSourceConceptId: get(i, "incomeSourceConceptId", ""),
          retFue: get(i, "retFue", 0).toString(),
          isModify: get(i, "isModify"),
          retentionPercentCode: get(
            i,
            "retentionPercentCode",
            RetentionPercentCodeEnum.RP303
          ) as RetentionPercentCodeEnum,
        };
        localStorage.setItem("retentionOrigin", String(1));
      }
      createRetailsData.push(createRetailsNewData);
    }
    dispatch(
      createRetentionsConfig({
        country: "ecuador",
        username: "backoffice",
        configs: [...createRetailsData],
      })
    );
  };

  const onSubmit = (): void => {
    triggerValidation().then(() => {
      if (!isEmpty(errors)) return;
      let retentionsData: RetentionMerchantConfigDynamo[] = get(
        stateStore,
        "retention",
        []
      );
      if (!isDataSaved) {
        retentionsData = [];
      }
      setIsDataSaved(true);
      let createRetailsDataTable: RetentionMerchantConfigDynamo = getDataRetentionMerchantConfigDynamo();
      const itemIndex = retentionsData.findIndex(
        (item) => item.id == createRetailsDataTable.id
      );

      if (itemIndex < 0) {
        retentionsData.push(createRetailsDataTable);
      } else {
        retentionsData[itemIndex] = createRetailsDataTable;
      }
      dispatch(setRetention(retentionsData));

      if (get(retentionsData, "length", 0) == 20) {
        setIsDataSavedLimit(true);
      }
      resetData();
      textButton === "Editar"
        ? dispatch(
            setNotification({
              open: true,
              message: "Se ha editado la configuración con éxito",
              type: "dark",
            })
          )
        : null;
    });
  };

  const resetData = () => {
    setRentRetentionValue("");
    setTaxpayerValue(undefined);
    setActivityValue(undefined);
    setIvaRetentionValues(undefined);
    setIncomeSourceConceptValue(undefined);
    setTextButton("Agregar");
    dispatch(setCodeDatil(""));
  };

  const handleButtonAction = () => {
    dispatch(setLoadByFormError(false));
    dispatch(
      setNotification({
        type: "dark",
        message: "No hubo cambios en la configuración",
        open: true,
      })
    );
  };

  const returnTo = (route: string) => {
    history.push(route);
  };

  const [openModal, setOpenModal] = useState<boolean>(false);
  const setOpenModalDetail = () => {
    setOpenModal(true);
  };
  const setCloseModalDetail = () => {
    setOpenModal(false);
  };
  const returnWithoutSave = () => {
    setOpenModal(false);
    handleNotificationAfter();
    window.location.href = `${routes.BASE_PATH_RETENTIONS}${routes.DASHBOARD}?country=${country}`;
    localStorage.setItem(
      "retentionOrigin",
      String(get(stateStore, "indexEditRetentionTab", 0))
    );
  };

  const handleNotificationAfter = () => {
    dispatch(
      setNotification({
        open: true,
        message: "No hubo cambios en la configuracion",
        type: "dark",
      })
    );
  };

  const returnNewRetention = () => {
    dispatch(setEditRetentionDashboard(false));
    dispatch(setValidateRetentionEdit(false));
    dispatch(setLoadingRetentionDashboard(false));
    dispatch(setAddRetentionDirectly(true));
    resetData();
  };

  const onHandleEditAction = (row: RetentionMerchantConfigDynamo) => {
    dispatch(setEditRetention(row));
    editRetail(row);
    history.push(
      `${routes.BASE_PATH_RETENTIONS}${routes.WITH_FORM}?country=${country}`
    );
  };

  //Modal Delete retention logic
  const handleOpenModalDelete = (index: number) => {
    const retentionsData: RetentionMerchantConfigDynamo[] = get(
      stateStore,
      "retention",
      []
    );
    setRetentionRow(retentionsData[index]);
    setOpenDeleteModal(!openDeleteModal);
  };
  const handleCloseModalDelete = () => {
    setOpenDeleteModal(!openDeleteModal);
  };
  const handleAcceptModalDelete = () => {
    const index = getRetentionsData().indexOf(retentionRow, 0);
    if (index > -1) {
      getRetentionsData().splice(index, 1);
    }
    if (isIVA) {
      if (
        retentionRow &&
        retentionRow.activityId &&
        retentionRow.retIva &&
        retentionRow.taxpayerTypeId
      ) {
        dispatch(setRetention(getRetentionsData()));
        setOpenDeleteModal(false);
        if (getRetentionsData().length == 0) {
          setIsDataSaved(false);
        }
        setIsDataSavedLimit(false);
        handleNotificationDelete();
      }
    } else {
      if (retentionRow && retentionRow.incomeSourceConceptId) {
        dispatch(setRetention(getRetentionsData()));
        setOpenDeleteModal(false);
        if (getRetentionsData().length == 0) {
          setIsDataSaved(false);
        }
        setIsDataSavedLimit(false);
        handleNotificationDelete();
      }
    }
  };

  const getRetentionsData = (): RetentionMerchantConfigDynamo[] => {
    return get(stateStore, "retention", []);
  };
  const handleNotificationDelete = () => {
    dispatch(
      setNotification({
        open: true,
        message: "Se ha eliminado la configuracion seleccionada",
        type: "dark",
      })
    );
  };
  const handlerValidateDatilCode = async (): Promise<void> => {
    const isValidate: boolean = await validateRententionForm();
    if (isValidate) {
      if (!isIVA) {
        const payload: GetDatilCodeRequest = {
          incomeSourceConceptId: get(
            incomeSourceConceptValue,
            "key",
            ""
          ).toString(),
          retentionPercent: rentRetentionValue,
        };
        await dispatch(validateCodeDatil(payload, setError));
        if (!isEmpty(errors)) return;
      }
      handlerVerifyExistRetentionId();
    }
  };

  const validateRententionForm = async () => {
    return await triggerValidation().then(() => {
      if (!activityValue && isIVA) {
        setError("activityType", "required", "Debe ingresar la actividad.");
      }
      if (!taxpayerValue && isIVA) {
        setError(
          "taxpayerType",
          "required",
          "Debe ingresar el tipo de contribuyente."
        );
      }
      if (!ivaRetentionValues && isIVA) {
        setError(
          "ivaRetention",
          "required",
          "Debe ingresar el porcentaje de retención IVA."
        );
      }

      if (!incomeSourceConceptValue && !isIVA) {
        setError(
          "incomeSourceConceptType",
          "required",
          "Debe ingresar el concepto de retencion de la renta."
        );
      }
      return isEmpty(errors);
    });
  };

  const handlerVerifyExistRetentionId = () => {
    validateRententionForm().then(() => {
      const originRequest = isIVA ? "iva" : "retention";
      if (!isEmpty(errors)) return;
      handleOriginRequest(originRequest);
    });
  };

  const handlerCloseChangeConfigRetention = () => {
    dispatch(setExistRetention(false));
  };

  const handleOriginRequest = (originRequest: string) => {
    if (originRequest === "iva") {
      dispatch(
        validExistRetentionById(
          {
            taxpayerTypeId: get(taxpayerValue, "key", "") as string,
            activityId: defaultTo(get(activityValue, "key"), "") as string,
          },
          stateStore.editRetentionDashboard!
        )
      );
    } else {
      dispatch(
        validExistRetentionById(
          {
            incomeSourceConceptId: get(
              incomeSourceConceptValue,
              "key",
              ""
            ).toString(),
          },
          stateStore.editRetentionDashboard!
        )
      );
    }
  };

  useEffect(() => {
    stateStore.editRetentionDashboard &&
      stateStore.existRetention &&
      onSaveConfiguration();
  }, [stateStore.existRetention]);

  useEffect(() => {
    if (stateStore.editRetentionDashboard)
      editRetail(stateStore.editRetention!);
  }, [stateStore.editRetention]);

  useEffect(() => {
    if (get(stateStore, "isAddRetention")) {
      dispatch(setAddRetention(false));
      onSubmit();
      handlerCloseChangeConfigRetention();
    }
  }, [stateStore.isAddRetention]);

  const handleSetChangesTabAzureRol = (
    columsTabs: IColumnsTable[],
    azureRol: SecurityWrapperEnum
  ): void => {
    setColumnsRetentionTable(columsTabs);
    setSecurityRol({ rolFormActions: azureRol });
  };

  const handleOnChangeTab = (value: number) => {
    setIsIVA(value === TabEnum.IVA);
    dispatch(setRetention([]));
    set(stateStore, "retention", []);
    dispatch(indexTabEditRetentionRender(value));
    setIsDataSaved(false);
  };

  useEffect(() => {
    setIsIVA(stateStore.indexEditRetentionTab! === TabEnum.IVA);
    dispatch(setRetention([]));
    set(stateStore, "retention", []);
    dispatch(indexTabEditRetentionRender(stateStore.indexEditRetentionTab!));
    setIsDataSaved(false);
  }, [stateStore.indexEditRetentionTab!]);

  useEffect(() => {
    isIVA
      ? handleSetChangesTabAzureRol(
          columnsRetentionIVATable,
          SecurityWrapperEnum.RETENCIONES_AGREGAR_EDITAR_IVA
        )
      : handleSetChangesTabAzureRol(
          columnsRetentionRENTATable,
          SecurityWrapperEnum.RETENCIONES_AGREGAR_EDITAR_RENTA
        );
  }, [isIVA]);

  return {
    country,
    breadCrumbs: {
      items: [
        BreadCrumsItems.GlobalConfig,
        { ...BreadCrumsItems.Country, label: getCountry(country).name },
        {
          label:
            BreadCrumsItems.RetentionsMerchant.label[getCountry(country).value],
          url: `${routes.BASE_PATH_RETENTIONS}${routes.DASHBOARD}?country=${country}`,
        },
      ],
      lastItem: BreadCrumsItems.UploadWithForm.label,
    },
    ivaRetention,
    textButton,
    methods: {
      onSubmit,
      returnTo,
      handleOnChangeSelection,
      handleValueRetentionChange,
      handleButtonAction,
      onSaveConfiguration,
      handleOnChangeTab,
    },
    values: {
      taxpayerValue,
      activityValue,
      ivaRetentionValues,
      incomeSourceConceptValue,
      rentRetentionValue,
      isDataSaved,
      isDataSavedLimit,
      isSaving,
      isIVA,
    },
    form: {
      errors,
      register,
      errorOnSubmit: stateStore.loadByFormError!,
    },
    modalDetailProps: {
      openModal,
      setOpenModalDetail,
      setCloseModalDetail,
      returnWithoutSave: returnWithoutSave,
    },
    data: {
      retentionsData: get(stateStore, "retention", []),
      retentionColumns: columnsRetentionTable,
      isLoading: stateStore.isLoadingRetentionDashboard!,
      onHandleEditAction,
    },
    modalDeleteProps: {
      handleAcceptModal: handleAcceptModalDelete,
      handleOpenModal: handleOpenModalDelete,
      handleCloseModal: handleCloseModalDelete,
      openDeleteModal,
      isLoadingDelete: stateStore.isLoadingDeleteRetailConfig!,
    },
    actionVerifyRetention: {
      isLoadingVerifyRetention: defaultTo(
        get(stateStore, "isLoadingVerifyExistRetention"),
        false
      ),
      existRetention: defaultTo(get(stateStore, "existRetention"), false),
      handlerValidateDatilCode,
      handlerVerifyExistRetentionId,
      handlerCloseChangeConfigRetention,
    },
    edit: {
      isEdit: stateStore.editRetentionDashboard!,
      value: stateStore.indexEditRetentionTab!,
      onSubmitItem,
      openModalEdit: stateStore.validateRetentionEdit!,
      returnNewRetention,
      handleNotificationAfter,
      addRetentionDirectly: stateStore.addRetentionDirectly!,
      isLoadingCreateItem:
        stateStore.isLoading! && stateStore.addRetentionDirectly!,
    },
    securityAzure: securityRol,
  };
};
