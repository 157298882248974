import React from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { DEFAULT_BUTTON_TITTLE } from "../../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { GenericAddButton } from "../GenericAddButton/GenericAddButton";
import { DEFAULT_ITEM_BY_TAG } from "../../../../shared/infrastructure/enums/ItemByTagEnum";
import { GenericLabelConditionSectionList } from "./components/GenericLabelConditionSectionList/GenericLabelConditionSectionList";
import { IGenericLabelConditionSectionProps } from "../../../../shared/infrastructure/interfaces/IGenericLabelConditionSection";
import { useGenericLabelConditionSectionState } from "./state/useGenericLabelConditionSectionState";
import {
  isOperatorEqualOrDifferent,
  retrieveFormError,
} from "../../../../utils/utilsFile";

export const GenericLabelConditionSection = (
  props: IGenericLabelConditionSectionProps
) => {
  const {
    register,
    errors,
    handleOnBlur,
    isDuplicated,
    textFieldList,
    setTextFieldList,
    handleAddTextField,
    handleEditTextField,
    isValidFiledTextField,
    handleOnChangeTextField,
    isInserted,
  } = useGenericLabelConditionSectionState(props);

  return (
    <>
      {textFieldList &&
        textFieldList.map((fieldValue: string, index: number) => (
          <React.Fragment
            key={`genericLabelConditionSectionList-${fieldValue}-${index}`}
          >
            <GenericLabelConditionSectionList
              handleEdit={(value: string) => handleEditTextField(value, index)}
              handleDelete={() =>
                setTextFieldList(
                  textFieldList.filter((item) => item != fieldValue.toString())
                )
              }
              value={fieldValue.toString()}
              tag={props.tag}
              isDisable={props.isDisable}
              keyIndex={index}
              errors={errors}
              register={register}
              isInserted={isInserted}
            />
          </React.Fragment>
        ))}

      {!props.isDisable &&
        !(
          textFieldList.length >= 1 &&
          isOperatorEqualOrDifferent(props.operator)
        ) && (
          <Grid item xs={4}>
            <Typography variant={"h6"} sx={{ my: 1 }}>
              {DEFAULT_ITEM_BY_TAG[props.tag].label}
            </Typography>
            <TextField
              name={DEFAULT_ITEM_BY_TAG[props.tag].name}
              inputRef={register({
                pattern: {
                  message: DEFAULT_ITEM_BY_TAG[props.tag].errorMessage,
                  value: DEFAULT_ITEM_BY_TAG[props.tag].pattern,
                },
              })}
              onChange={handleOnChangeTextField}
              label={DEFAULT_ITEM_BY_TAG[props.tag].label}
              placeholder={DEFAULT_ITEM_BY_TAG[props.tag].placeholder}
              helperText={
                isDuplicated
                  ? DEFAULT_ITEM_BY_TAG[props.tag].duplicatedMessage
                  : retrieveFormError(
                      errors,
                      DEFAULT_ITEM_BY_TAG[props.tag].name
                    )[1]
              }
              variant="outlined"
              fullWidth
              onBlur={handleOnBlur}
              disabled={props.isDisable}
              error={
                retrieveFormError(
                  errors,
                  DEFAULT_ITEM_BY_TAG[props.tag].name
                )[0]
              }
            />
          </Grid>
        )}
      {isValidFiledTextField && !props.isDisable && !isDuplicated && (
        <GenericAddButton
          handleOnClick={handleAddTextField}
          buttonTitle={DEFAULT_BUTTON_TITTLE[props.tag]}
        />
      )}
    </>
  );
};
