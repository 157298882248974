import React, { useCallback, useEffect, useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import {
  Merchant as TableMerchant,
  PaginationTableProps,
  TMoreOptions,
} from "@kushki/frontend-molecules/merchants-table";
import {
  IFilterResponse,
  IFilterTypes,
} from "@kushki/frontend-molecules/filter-container";
import { Merchant } from "../../../../types/merchant";
import { MerchantProps } from "../Merchant";
import { IDeactivateMerchantDialogProps } from "../../../components/DeactivateMerchantDialog";
import { get, isEmpty } from "lodash";
import { LocalStoragePropertiesEnum } from "../../../infrastructure/LocalStoragePropertiesEnum";
import { MerchantStatusEnum } from "../../../infrastructure/MerchantStatusEnum";
import { StatusMerchantEnum } from "../../../shared/infraestructure/constants/StatusMerchantEnum";
import { isAzureToken } from "@kushki/security-wrapper";
import { LABEL_COUNTRY_LIST } from "../../../shared/infraestructure/constants/LabelCountryList";
import { formatCurrencyWallet } from "../../../shared/utils/walletUtils";

export interface IMerchantMenuListFunctionProps {
  handleCredit: (merchant: TableMerchant) => void;
  handleDebit: (merchant: TableMerchant) => void;
  handleActivate: (merchant: TableMerchant) => void;
}

export interface IMerchantState {
  merchantFilters: {
    filters: IFilterTypes;
    handleAppliedFilters: (result: IFilterResponse) => void;
    isMobile: boolean;
    anchorEl: HTMLButtonElement | null;
    expanded: boolean;
    handleClose: () => void;
    handleClearFilters: () => void;
  };
  searchFilter: {
    searchText: string;
    handleSearchClick: (_event) => void;
    handleSearchChange: (event: string) => void;
    handleKeyUp: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  };
  merchantsTable: {
    merchantArr: TableMerchant[];
    pag: PaginationTableProps;
    handleSelectMerchant: (merchant: TableMerchant) => void;
    handleEditMerchant: (merchant: TableMerchant) => void;
    handleMoreOptions:
      | {
          name: string;
          method: (merchant: TableMerchant) => void;
        }[]
      | TMoreOptions;
  };
  actions: {
    loading: boolean;
    totalMerchants: number;
    counterFilters: number;
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleCreateMerchant: () => void;
    handleMerchants: (e) => void;
  };
  deactivateMerchant: IDeactivateMerchantDialogProps;
  openDialog: boolean;
  menuList: IMerchantMenuListFunctionProps;
  isComplianceUser: () => boolean;
}
interface IOptionFilter {
  id: string;
  title: string;
  options: { key: string; label: string; selected: boolean }[];
}

export const useMerchantState = (props: MerchantProps): IMerchantState => {
  const [offset, setOffset] = React.useState(0);
  const [totalMerchants, setTotalMerchants] = React.useState(-1);
  const isMobile: boolean = useMediaQuery("(max-width:767px)");
  const [loading, setLoading] = React.useState(false);

  const [searchText, setSearchText] = React.useState("");
  const [filterCountry, setFilterCountry] = React.useState("");

  const [merchantArr, setMerchantArr] = React.useState([]);

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setFilters(searchText);
      event.preventDefault();
      setCountryFilters(filter);
      setCounterFilters(0);
    }
  };

  const handleSearchChange = (event: string) => {
    setSearchText(event);
  };

  const handleSearchClick = (_event) => {
    setFilters(searchText);
    setCountryFilters(filter);
    setCounterFilters(0);
  };
  const handleSelectMerchant = (merchant: TableMerchant) => {
    const validationDictionary: string =
      LABEL_COUNTRY_LIST[merchant.country] || merchant.country;
    if (isComplianceUser()) {
      window.location.href = `/compliance/merchant/${merchant.merchantId}`;
    } else {
      if (
        merchant.chipClass == StatusMerchantEnum.ACTIVE ||
        merchant.chipClass == StatusMerchantEnum.INACTIVE
      ) {
        localStorage.setItem(
          "merchantBasicInformation",
          JSON.stringify({
            country: validationDictionary,
            publicMerchantId: merchant.merchantId,
            name: merchant.name,
            origin: "configMerchant",
          })
        );
        window.location.href = `/config-merchant/merchant/${merchant.merchantId}`;
      } else {
        localStorage.setItem(
          "merchantBasicInformation",
          JSON.stringify({
            country: validationDictionary,
            publicMerchantId: merchant.merchantId,
            name: merchant.name,
            origin: "configMerchant",
          })
        );
        window.location.href = `/create-merchant/details?publicMerchantId=${merchant.merchantId}&hideSideBar=true`;
      }
    }
  };
  const handleEditMerchant = (merchant: TableMerchant) => {
    if (isComplianceUser()) return;
    localStorage.setItem(
      "merchantInfo",
      JSON.stringify({
        publicMerchantId: merchant.merchantId,
        merchantName: merchant.name,
      })
    );
    localStorage.setItem(
      "merchantPropertiesInfo",
      JSON.stringify({
        publicMerchantId: merchant.merchantId,
        country: merchant.country,
        isEditing: true,
        origin: "configMerchant",
      })
    );
    localStorage.setItem("onEditMerchant", JSON.stringify({ edit: true }));
    localStorage.removeItem("merchantStore");
    window.location.href = "/billing-merchant/invoice";
  };

  const handleCreateMerchant = () => {
    localStorage.removeItem("merchantInfo");
    localStorage.removeItem("onEditMerchant");
    localStorage.removeItem("merchantStore");
    localStorage.removeItem("merchantPropertiesInfo");
    localStorage.removeItem("merchantBasicInformation");
    window.location.href = "/create-merchant?hideSideBar=true";
  };
  const isComplianceUser = (): boolean => {
    if (isAzureToken()) {
      return false;
    }
    return (
      localStorage.getItem(LocalStoragePropertiesEnum.IS_COMPLIANCE_USER) ===
      "true"
    );
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleClose = () => {
    setExpanded(false);
    setAnchorEl(null);
  };
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [counterFilters, setCounterFilters] = React.useState<number>(0);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setExpanded(true);
  };
  const countriesToFilter: string[] = [
    "Brazil",
    "Chile",
    "Colombia",
    "Ecuador",
    "Mexico",
    "Peru",
  ];
  const filter: IOptionFilter[] = [
    {
      id: "countries",
      title: "Paises",
      options: [
        { key: "Brazil", label: "Brasil", selected: false },
        { key: "Chile", label: "Chile", selected: false },
        { key: "Colombia", label: "Colombia", selected: false },
        { key: "Ecuador", label: "Ecuador", selected: false },
        { key: "Mexico", label: "México", selected: false },
        { key: "Peru", label: "Perú", selected: false },
      ],
    },
  ];
  const [countryFilters, setCountryFilters] = React.useState<IOptionFilter[]>(
    filter
  );
  const handleAppliedFilters = (result: IFilterResponse) => {
    setCountryFilters(result.listFilter);
    const selectedItems = result.listFilter[0].options.filter(
      (item) => item.selected
    );
    setCounterFilters(selectedItems.length);
    if (selectedItems.length) {
      if (countriesToFilter.includes(selectedItems[0].key)) {
        setFilterCountry(selectedItems[0].key);
      }
      setFilters(selectedItems[0].key);
    }
    setSearchText("");
    handleClose();
  };
  const filters: IFilterTypes = {
    listFilters: {
      filters: countryFilters,
      multiple: false,
    },
  };

  const setFilters = (text: string) => {
    const country: string =
      text !== "" && text !== undefined && countriesToFilter.includes(text)
        ? text
        : undefined;
    setLoading(true);
    setOffset(0);
    props.getMerchants({
      limit: 5,
      offset: 0,
      text,
      country,
    });
  };
  const pag: PaginationTableProps = {
    total: totalMerchants,
    limit: 5,
    page: offset + 1,
    isMobile: false,
    siblingCount: 10,
    handleChangePage: (_event: React.ChangeEvent<unknown>, value: number) => {
      const country: string =
        filterCountry !== "" && countriesToFilter.includes(filterCountry)
          ? filterCountry
          : undefined;

      if (offset !== value - 1) {
        setLoading(true);
        props.getMerchants({
          limit: 5,
          offset: (value - 1) * 5,
          country,
          text: searchText,
        });
        setOffset(value - 1);
      }
    },
  };

  const handleMoreOptions: {
    name: string;
    method: (merchant: TableMerchant) => void;
  }[] = [
    {
      name: "Recargar Saldo",
      method: (merchant: TableMerchant) => {
        window.location.href = `wallet?merchantId=${merchant.merchantId}&type=CREDIT`;
      },
    },
    {
      name: "Debitar Saldo",
      method: (merchant: TableMerchant) => {
        window.location.href = `wallet?merchantId=${merchant.merchantId}&type=DEBIT`;
      },
    },
    {
      name: "Desactivar comercio",
      method: (merchant: TableMerchant) => {
        setSelectedMerchant(merchant);
        setOpenDialog(true);
      },
    },
  ];
  useEffect(() => {
    const newMerchants: TableMerchant[] = [];
    setLoading(false);
    props.merchants.data.forEach((value: { _source: Merchant }) => {
      const country: string =
        LABEL_COUNTRY_LIST[value._source.country] || value._source.country;
      const balanceValues: string[] = get(value, "_source.balance", "").split(
        " "
      );
      const amount: string = get(balanceValues, "[0]", "");
      const currency: string = get(balanceValues, "[1]", "");

      newMerchants.push({
        country,
        name: value._source.name,
        merchantId: value._source.publicMerchantId,
        status: translateStatue(value._source.status).newStatus,
        balance: `${formatCurrencyWallet(Number(amount), country)} ${currency}`,
        chipClass: translateStatue(value._source.status).chipClass,
      });
    });

    setTotalMerchants(props.merchants.total.value);
    setMerchantArr(newMerchants);
  }, [props.merchants]);

  const translateStatue = (status): { newStatus; chipClass } => {
    let newStatus: string;
    let chipClass: MerchantStatusEnum;
    switch (status) {
      case MerchantStatusEnum.ACTIVE:
        newStatus = "Activo";
        chipClass = MerchantStatusEnum.ACTIVE;
        break;
      case MerchantStatusEnum.INACTIVE:
        newStatus = "Inactivo";
        chipClass = MerchantStatusEnum.INACTIVE;
        break;
      case MerchantStatusEnum.PENDING:
        newStatus = "Pendiente";
        chipClass = MerchantStatusEnum.PENDING;
        break;
      default:
        newStatus = "Pendiente";
        chipClass = MerchantStatusEnum.PENDING;
    }
    return { newStatus, chipClass };
  };

  const handleMerchants = (e) => {
    setSearchText("");
    setFilterCountry("");
    handleSearchClick(e);
  };

  const handleClearFilters = () => {
    setCounterFilters(0);
    setCountryFilters(filter);
    setFilters("");
    handleClose();
  };

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [loadingDialog, setLoadingDialog] = useState<boolean>(false);
  const [
    selectedMerchant,
    setSelectedMerchant,
  ] = useState<TableMerchant | null>(null);
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setLoadingDialog(false);
  };

  const handleDeactiveMerchant = useCallback(() => {
    setOpenDialog(false);
    props.updateMerchantStatus({
      merchants: props.merchants,
      merchantId: selectedMerchant.merchantId,
      state: "inactive",
    });
  }, [selectedMerchant]);

  const handleCredit = (merchant: TableMerchant) => {
    window.location.href = `wallet?merchantId=${merchant.merchantId}&type=CREDIT`;
  };

  const handleDebit = (merchant: TableMerchant) => {
    window.location.href = `wallet?merchantId=${merchant.merchantId}&type=DEBIT`;
  };

  const handleActivate = (merchant: TableMerchant) => {
    const isActive: boolean = merchant.status === "Activo";
    if (isActive) {
      setOpenDialog(true);
      setSelectedMerchant(merchant);
    } else {
      props.updateMerchantStatus({
        merchants: props.merchants,
        merchantId: merchant.merchantId,
        state: "active",
      });
    }
  };

  return {
    merchantFilters: {
      filters,
      handleAppliedFilters,
      isMobile,
      anchorEl,
      expanded,
      handleClose,
      handleClearFilters,
    },
    merchantsTable: {
      merchantArr,
      pag,
      handleSelectMerchant,
      handleEditMerchant,
      handleMoreOptions,
    },
    actions: {
      loading,
      totalMerchants,
      counterFilters,
      handleClick,
      handleCreateMerchant,
      handleMerchants,
    },
    searchFilter: {
      searchText,
      handleSearchClick,
      handleSearchChange,
      handleKeyUp,
    },
    deactivateMerchant: {
      loading: loadingDialog,
      handleClose: handleCloseDialog,
      handleDeactivate: handleDeactiveMerchant,
      merchantName: get(selectedMerchant, "name", ""),
    },
    openDialog,
    menuList: {
      handleActivate,
      handleCredit,
      handleDebit,
    },
    isComplianceUser,
  };
};
