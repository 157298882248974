import React from "react";

export const DevelopersIcon: React.FC = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0006 26.25C15.4789 26.25 13.0138 25.5022 10.9171 24.1012C8.82037 22.7003 7.18617 20.709 6.22115 18.3792C5.25613 16.0495 5.00364 13.4859 5.4956 11.0126C5.98757 8.53935 7.20189 6.26751 8.98501 4.48439C10.7681 2.70127 13.04 1.48695 15.5132 0.994993C17.9865 0.503032 20.5501 0.755524 22.8798 1.72054C25.2096 2.68556 27.2009 4.31976 28.6019 6.41649C30.0028 8.51321 30.7506 10.9783 30.7506 13.5C30.7466 16.8803 29.4021 20.121 27.0118 22.5112C24.6216 24.9015 21.3809 26.246 18.0006 26.25ZM18.0006 3.75001C16.0722 3.75001 14.1872 4.32183 12.5838 5.39318C10.9804 6.46452 9.73075 7.98726 8.99279 9.76884C8.25484 11.5504 8.06175 13.5108 8.43796 15.4021C8.81417 17.2934 9.74276 19.0307 11.1063 20.3943C12.4699 21.7579 14.2072 22.6865 16.0985 23.0627C17.9898 23.4389 19.9502 23.2458 21.7318 22.5078C23.5134 21.7699 25.0361 20.5202 26.1074 18.9168C27.1788 17.3134 27.7506 15.4284 27.7506 13.5C27.7467 10.9154 26.7181 8.43772 24.8905 6.6101C23.0629 4.78248 20.5853 3.75397 18.0006 3.75001Z"
        fill="#023365"
      />
      <path
        d="M12.0887 35.2501C11.8013 35.2493 11.5201 35.166 11.2787 35.0101C11.0674 34.8745 10.8936 34.6882 10.773 34.468C10.6525 34.2479 10.5891 34.001 10.5887 33.7501V23.0701C10.5887 22.6722 10.7467 22.2907 11.028 22.0094C11.3093 21.7281 11.6909 21.5701 12.0887 21.5701C12.4865 21.5701 12.868 21.7281 13.1493 22.0094C13.4306 22.2907 13.5887 22.6722 13.5887 23.0701V31.5001L17.8037 29.6101C17.9848 29.5146 18.1836 29.4572 18.3878 29.4415C18.592 29.4258 18.7972 29.452 18.9908 29.5186C19.1845 29.5852 19.3624 29.6908 19.5137 29.8287C19.665 29.9667 19.7865 30.1342 19.8706 30.3209C19.9547 30.5076 19.9997 30.7096 20.0028 30.9143C20.0059 31.119 19.9671 31.3223 19.8887 31.5114C19.8103 31.7006 19.694 31.8717 19.5469 32.0142C19.3999 32.1567 19.2252 32.2676 19.0337 32.3401L12.7037 35.1901C12.5051 35.2525 12.2956 35.273 12.0887 35.2501Z"
        fill="#023365"
      />
      <path
        d="M24.7475 35.2501C24.5353 35.2491 24.3256 35.2031 24.1325 35.1151L17.8025 32.2651C17.611 32.1926 17.4363 32.0817 17.2893 31.9392C17.1422 31.7967 17.0259 31.6256 16.9475 31.4364C16.8691 31.2473 16.8303 31.044 16.8334 30.8393C16.8365 30.6346 16.8815 30.4326 16.9656 30.2459C17.0497 30.0592 17.1712 29.8917 17.3225 29.7537C17.4738 29.6158 17.6517 29.5102 17.8454 29.4436C18.039 29.377 18.2442 29.3508 18.4484 29.3665C18.6526 29.3822 18.8513 29.4396 19.0325 29.5351L23.2475 31.5001V23.0701C23.2475 22.6722 23.4055 22.2907 23.6868 22.0094C23.9681 21.7281 24.3497 21.5701 24.7475 21.5701C25.1453 21.5701 25.5269 21.7281 25.8082 22.0094C26.0895 22.2907 26.2475 22.6722 26.2475 23.0701V33.7501C26.2471 34.001 26.1837 34.2479 26.0631 34.468C25.9426 34.6882 25.7687 34.8745 25.5575 35.0101C25.3161 35.166 25.0349 35.2493 24.7475 35.2501Z"
        fill="#023365"
      />
    </svg>
  );
};
