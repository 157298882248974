import { get } from "lodash";
import { RedirectionUrlsEnum } from "../enums/RedirectionUrlsEnum";
import { StatusEnum } from "../enums/StatusEnum";

export function generateUrl<T>(
  obj: T,
  hasUrl: boolean = false
): string | undefined {
  const merchant_id: string | undefined = get(obj, "merchant_id");

  const redirectionUrlsObj = {
    [RedirectionUrlsEnum.RESUME]: `/merchant-resume?publicMerchantId=${merchant_id}&mode=edition`,
    [RedirectionUrlsEnum.RESUME_USER]: `/merchant-resume?publicMerchantId=${merchant_id}&mode=edition&users=true`,
    [RedirectionUrlsEnum.SEMAPHORE]: `/merchant/basic-details?publicMerchantId=${merchant_id}&menuItem=true`,
  };

  if (hasUrl && merchant_id) {
    if (
      !get(obj, "user_config_completed") &&
      get(obj, "configs_completed") &&
      get(obj, "merchant_status") !== StatusEnum.ACTIVE
    )
      return redirectionUrlsObj[RedirectionUrlsEnum.RESUME_USER];

    if (!get(obj, "configs_completed"))
      return redirectionUrlsObj[RedirectionUrlsEnum.SEMAPHORE];

    return get(obj, "merchant_status") === StatusEnum.ACTIVE
      ? redirectionUrlsObj[RedirectionUrlsEnum.RESUME]
      : redirectionUrlsObj[RedirectionUrlsEnum.SEMAPHORE];
  }

  return undefined;
}
