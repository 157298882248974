import { makeStyles } from "@material-ui/core/styles";

export const uploadCardStyles = makeStyles({
  gridContainer: {
    background: "#FFFFFF",
  },
  spaceGrid: {
    marginBottom: "18px",
  },
});
