import {
  ItemCategoryItem,
  ItemCategoryProps,
} from "../../components/Filters/ItemCategory/interfaces";
import { TFilterDetailBar } from "@kushki/connect-ui/dist/Components/Molecules/Filters/FilterDetailBar/FilterDetailBar.interfaces";
import { IFilterItem } from "@kushki/connect-ui/dist/Components/Atoms/Filters/FilterDetailItem/FilterDetailItem.interfaces";
import { PaymentMethodEnum } from "../enums/PaymentMethodEnum";
import {
  FILTER_GROUPS,
  PAYMENT_METHOD_ITEMS,
  STATE_ITEM_VALUES,
} from "../constants/labels/header_filters_labels";
import {
  CATALOG_TRANSACTION_TYPES,
  TransactionTypeEnum,
} from "../enums/TransactionTypeEnum";
import { cloneDeep } from "lodash";
import { CategoryTypeFilterEnum } from "../enums/CategoryTypeFilterEnum";
import { FilterSideBarProps } from "../../components/Filters/FilterSideBar/FilterSideBar.interfaces";
import { TransactionStatusLabelEnum } from "../enums/TransactionStatusEnum";
import { emptyFunction } from "./index";
import { FilterTextsEnum } from "../enums/FilterTextsEnum";

export const getItemsFilterDetailBar = (
  itemCategory: ItemCategoryProps[]
): TFilterDetailBar[] => {
  let itemsFilterDetailBar: TFilterDetailBar[] = [];

  itemCategory.map((itemSideBar: ItemCategoryProps) => {
    let itemsFilterBar: IFilterItem[] = [];

    itemSideBar.items.map((itemFilterSideBar: ItemCategoryItem) => {
      return (
        itemFilterSideBar.selected &&
        itemsFilterBar.push({
          label: itemFilterSideBar.label,
          value: itemFilterSideBar.value,
        })
      );
    });
    if (itemsFilterBar.length > 0)
      itemsFilterDetailBar.push({
        itemCategoryType: "bySearchText",
        items: itemsFilterBar,
        title: itemSideBar.groupSection,
      });
  });

  return itemsFilterDetailBar;
};
export const DEFAULT_PAYMENT_METHODS: ItemCategoryItem[] = [
  {
    label: PAYMENT_METHOD_ITEMS[PaymentMethodEnum.CARD],
    selected: false,
    value: PaymentMethodEnum.CARD,
  },
  {
    label: PAYMENT_METHOD_ITEMS[PaymentMethodEnum.CARD_PRESENT],
    selected: false,
    value: PaymentMethodEnum.CARD_PRESENT,
  },
  {
    label: PAYMENT_METHOD_ITEMS[PaymentMethodEnum.TRANSFER],
    selected: false,
    value: PaymentMethodEnum.TRANSFER,
  },
  {
    label: PAYMENT_METHOD_ITEMS[PaymentMethodEnum.CASH],
    selected: false,
    value: PaymentMethodEnum.CASH,
  },
];
export const DEFAULT_TRANSACTION_TYPES: ItemCategoryItem[] = [
  {
    label: CATALOG_TRANSACTION_TYPES[TransactionTypeEnum.SALE],
    selected: false,
    value: TransactionTypeEnum.SALE.toLowerCase(),
  },
  {
    label: CATALOG_TRANSACTION_TYPES[TransactionTypeEnum.VOID],
    selected: false,
    value: TransactionTypeEnum.VOID.toLowerCase(),
  },
  {
    label: CATALOG_TRANSACTION_TYPES[TransactionTypeEnum.REFUND],
    selected: false,
    value: TransactionTypeEnum.REFUND.toLowerCase(),
  },
  {
    label: CATALOG_TRANSACTION_TYPES[TransactionTypeEnum.REVERSE],
    selected: false,
    value: TransactionTypeEnum.REVERSE.toLowerCase(),
  },
];

export const FILTER_SIDE_BAR_INITIAL_STATE: FilterSideBarProps = {
  categoryItems: [
    {
      groupSection: FILTER_GROUPS.PAYMENT_METHODS,
      isMinimize: true,
      items: cloneDeep(DEFAULT_PAYMENT_METHODS),
      selectType: "multiple",
      setRangeAmount: () => {},
      type: CategoryTypeFilterEnum.BY_CHIPS,
    },
    {
      groupSection: FILTER_GROUPS.TYPES,
      isMinimize: true,
      items: cloneDeep(DEFAULT_TRANSACTION_TYPES),
      selectType: "multiple",
      setRangeAmount: () => {},
      type: CategoryTypeFilterEnum.BY_CHIPS,
    },
    {
      groupSection: FILTER_GROUPS.STATE,
      isMinimize: true,
      items: [
        {
          label: TransactionStatusLabelEnum.APPROVAL,
          selected: false,
          value: STATE_ITEM_VALUES[TransactionStatusLabelEnum.APPROVAL],
        },
        {
          label: TransactionStatusLabelEnum.INITIALIZED,
          selected: false,
          value: STATE_ITEM_VALUES[TransactionStatusLabelEnum.INITIALIZED],
        },
        {
          label: TransactionStatusLabelEnum.DECLINED,
          selected: false,
          value: STATE_ITEM_VALUES[TransactionStatusLabelEnum.DECLINED],
        },
      ],
      selectType: "multiple",
      setRangeAmount: () => {},
      type: CategoryTypeFilterEnum.BY_CHIPS,
    },
    {
      groupSection: FILTER_GROUPS.AMOUNTS,
      isMinimize: true,
      items: [],
      selectType: "multiple",
      setRangeAmount: () => {},
      type: CategoryTypeFilterEnum.BY_AMOUNT,
    },
  ],
  filtersCount: 0,
  isMinimized: false,
  isOpen: true,
  onClick: emptyFunction,
  setIsApplyFilter: () => {},
  setRangeAmount: () => {},
  title: FilterTextsEnum.FILTER_BY,
};
