import React from "react";
import SkeletonTableContainer from "../../components/Skeletons/SkeletonTableContainer/SkeletonTableContainer";
import { CardContainer } from "../../components/CardContainer/CardContainer";
import TableSubsection from "../../components/TableSubsection/TableSubsection";
import { useTableRates } from "./state/useTableRates";
import { routes } from "../../shared/infrastructure/constants/routes";

export interface ITableRates {
  merchantId: string;
}

export const TableRates = ({ merchantId }: ITableRates) => {
  const { headerData, renderData, menuControl } = useTableRates(merchantId);

  return (
    <CardContainer
      key={"1"}
      headerCardTitle={"Tarifas agregadas"}
      headerButtonTitle={"Agregar tarifas"}
      headerButtonOnClick={() => menuControl.setMenu(true)}
      menuControl={menuControl}
      footerCardHrefLocation={`${routes.RATES}/${merchantId}`}
      footerTitleHrefLocation={"Edita y revisa todas las tarifas"}
      buttonCreate={true}
      childrenArrayCardComponent={[
        renderData === undefined ? (
          <SkeletonTableContainer key={1} rows={4} cells={7} />
        ) : (
          <TableSubsection
            key={1}
            headerData={headerData}
            renderData={renderData}
          />
        ),
      ]}
    />
  );
};

export default TableRates;
