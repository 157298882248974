import { makeStyles } from "@material-ui/core/styles";

export const chipComponentStyle = makeStyles({
  chip: {
    backgroundColor: "#F7FAFC",
    borderRadius: "4px",
    color: "#677684",
    cursor: "pointer",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    height: "24px",
    lineHeight: "17px",
    padding: "6px 10px",
    width: "auto",
  },
  chipSelected: {
    "&:hover": {
      backgroundColor: "#0DC298",
    },
    backgroundColor: "#0DC298",
    borderRadius: "4px",
    color: "#FFFFFF",
    cursor: "pointer",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    height: "24px",
    lineHeight: "17px",
    padding: "6px 10px",
    width: "auto",
  },
});
