import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import Factura from "../../../assets/images/Factura.svg";
import emptyObservation from "../../../assets/images/empty_observation.svg";
import { useStylesEmptyTab } from "./EmptyStateTabStyles";

export const EmptyStateTab = ({
  title,
  subtitle,
  isProcessData,
}: {
  title: string;
  subtitle?: string;
  isProcessData?: boolean;
}) => {
  const styleClasses = useStylesEmptyTab();
  return (
    <React.Fragment>
      {isProcessData ? (
        <Box className={styleClasses.imageBox}>
          <Grid
            container
            className={styleClasses.paperBackground}
            direction="column"
            spacing={3}
            alignItems="center"
            justify="center"
          >
            <Grid item xs={12} md={12} className={styleClasses.textTitleImage}>
              <Typography
                variant={"h6"}
                className={styleClasses.blueImageTitle}
              >
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <img alt={title} src={emptyObservation} id="imgEmpty" />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          p={1}
          m={1}
          bgcolor="background.paper"
          className={styleClasses.textBox}
        >
          <Box p={1}>
            <Typography variant="subtitle2" color="primary">
              {title}
            </Typography>
            <Grid item xs={12} container alignItems="center" justify={"center"}>
              <img src={Factura} alt={Factura} />
            </Grid>
            <Typography variant="overline" color="primary">
              {subtitle}
            </Typography>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};
