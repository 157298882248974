import { buildStatusItemsCategory } from "../utils/buildItemsCategory";
import { FILTER_GROUPS } from "../enum/FilterLabelUsersEnum";
import { ItemCategoryProps } from "@kushki/connect-ui/dist/Components/Molecules/Filters/ItemCategory/interfaces";

export const categoryItemsInitial: ItemCategoryProps[] = [
  {
    isMinimize: true,
    items: buildStatusItemsCategory(),
    onItemsSelect: () => {},
    placeHolder: FILTER_GROUPS.STATE,
    selectType: "single",
    title: FILTER_GROUPS.STATE,
    type: "byChips",
  },
];
