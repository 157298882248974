import React, { Fragment, useState } from "react";
import {
  AppBar,
  Box,
  Chip,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";

import { BillingTabTitleEnum } from "../../shared/infrastructure/BillingTabTitleEnum";
import { TabPanel } from "../../components/Common/KshTab/KshTab";
import {
  BackofficeTable,
  PaginationTableProps,
  TableHeaders,
} from "@kushki/frontend-molecules/backoffice-table";
import { get } from "lodash";
import { translate } from "../../shared/infrastructure/LanguageCatatog";
import {
  ModalOverUtils,
  UseBillingManualContingency,
} from "./state/useBillingManualContingency";
import { SmartlinkDatePicker } from "../../components/DatePicker/SmartlinkDatePicker";
import { DateRange } from "@material-ui/pickers";
import { Download as IconDownload, Search } from "react-feather";
import imgSettings from "../../assets/images/⚙️settings.svg";
import { ButtonDownloadFile } from "../../components/ButtonDownloadFile/ButtonDownloadFile";
import { ISort } from "../BillingDashboard/state/useBillingDashboardState";
import { ResponseSearchBillingByFilters } from "../../../types/remote/response_search_blling_by_filters";
import { ColumnFilters } from "../../components/DashboardList/ColumnFilters/ColumnFilters";
import { IColumnsTable } from "../../shared/infrastructure/table/IColumnsTable";
import { defaultHeadersManualContingencyHistory } from "../../shared/infrastructure/filter/ManualContingencyValue";
import { ModalOverlay } from "@kushki/frontend-molecules/modal";
import ModalHeaderInfo from "../../components/ModalHeaderInfo/ModalHeaderInfo";
import { ModalBody } from "@kushki/frontend-molecules/modal-body";
import { ModalBodyTabs } from "../../components/ModalBodyInfo/ModalBodyTabs";
import { ModalFooter } from "@kushki/frontend-molecules/modal-footer";
import { IModalFooterProps } from "../../shared/infrastructure/IModalFooterInterface";
import { tabEnum } from "../../shared/infrastructure/CatalogRenderTab";
import { Loader } from "@kushki/frontend-molecules/loader";
import { INotification } from "../../store/reducer";
import { SnackbarAlert } from "../../components/Snackbar/SnackbarAlert";
import { DownloadLedgerDetail } from "../../../types/download_ledger_detail";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { background: "transparent", boxShadow: "none" },
    formControl: {
      width: "70%",
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    textField: {
      padding: 0,
      height: "36px",
      marginTop: "-3px",
      fontSize: 16,
      width: "100%",
    },
    tab: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    menuHistoric: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    tabStyle: {
      textTransform: "none",
    },
    iconButton: {
      "& .MuiIconButton-root": {
        height: "39px",
        width: "39px",
        marginTop: "0px",
        borderStyle: "solid",
        borderColor: "#C4C4C4",
        borderWidth: 1,
        borderRadius: 4,
        backgroundColor: "#FFFFFF",
      },
    },
  })
);

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: "10px!important",
      position: "absolute",
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      "&:focus": {
        borderRadius: "10px!important",
      },
    },
  })
)(InputBase);

export const BillingManualContingency = () => {
  const {
    headers,
    sortProps,
    paginationProps,
    data,
    actions,
    setLoadingHistoric,
    settingsColumns,
    setLoadingManualContingency,
    notificacion,
    handleCloseNotification,
    modalOver,
    selectProps,
  } = UseBillingManualContingency();
  const classes = useStyles();

  const footerPropsDownloadDispersion: Partial<IModalFooterProps> = {
    dropDwLabelButton: "Descargar",
    dropDwButtonVariant: "contained",
    dropDwButtonColor: "primary",
    classNamedrowButtonPrimary: "splitBtn",
    classNamedrowButton: "splitBtn splitArrow",
    separate: true,
    dropDwStyleButton: { minWidth: "8em" },
    dropDwMenuItems: [
      {
        label: "Descargar detalle",
        disable: false,
        menuItemActionButton: () => {
          const selectedTrx = modalOver.selectedRow;
          const getFromTransactionObj = (path: string, defaultValue?: any) =>
            get(selectedTrx, path, defaultValue);

          const params: DownloadLedgerDetail = {
            action: "listLedgerDetail",
            data: {
              startDate: new Date(
                getFromTransactionObj("start_date")
              ).getTime(),
              endDate: new Date(getFromTransactionObj("end_date")).getTime(),
              country: getFromTransactionObj("country"),
              transactionId: getFromTransactionObj("transaction_id"),
              merchantId: getFromTransactionObj("merchant_id"),
            },
          };
          modalOver.downloadPreprocessFileWS(params);
        },
        hidden: false,
      },
    ],
    dropDwActionIcon: <IconDownload xlinkTitle={"Descargar"} size={20} />,
  };
  return (
    <Fragment>
      <Container>
        <HeaderManualContingency classes={classes} />
        <TabManualContingency classes={classes} />
        <HistoricManualContingency
          range={actions.range}
          handleSetDate={actions.handleSetDate}
          classes={classes}
          handleHistoricSearchBar={actions.handleHistoricSearchBar}
          query={actions.query}
          setLoadingManualContingency={setLoadingManualContingency}
          notification={notificacion}
          handleInputChange={actions.handleInputChange}
          handleCloseSnackBar={handleCloseNotification}
          downloadFileState={{
            downloadFile: actions.downloadFileState.downloadFile,
            isLoading: actions.downloadFileState.isLoading,
          }}
          settingsColumns={settingsColumns}
        />
        <TableManualContingency
          data={data}
          headers={headers}
          sort={sortProps}
          paginationProps={paginationProps}
          setLoadingHistoric={setLoadingHistoric}
          modalOver={modalOver}
        />

        <ModalOverlay
          header={
            <ModalHeaderInfo
              trxInfo={modalOver.selectedRow}
              isMinAmount={false}
            />
          }
          body={
            <ModalBody>
              <ModalBodyTabs
                trxInfo={modalOver.selectedRow}
                country={selectProps.country}
                isMinAmount={false}
                handleOpenDialogDetail={modalOver.handleOpenDialogDetail}
                handleCloseDialogDetail={modalOver.handleCloseDialogDetail}
                isHistoric={true}
                tabDefault={tabEnum.information}
              />
            </ModalBody>
          }
          footer={<ModalFooter {...footerPropsDownloadDispersion} />}
          openModal={modalOver.openModal}
          onCloseModal={modalOver.handlerCloseModal}
          overlay={false}
          backdrop={false}
          transition={"left"}
        />
      </Container>
    </Fragment>
  );
};

export const HeaderManualContingency = (props: { classes: object }) => {
  return (
    <Grid
      container
      alignItems="center"
      justify="flex-start"
      direction="row"
      spacing={1}
    >
      <Grid item xs={4} md={8}>
        <Box justifyContent="left" display="flex" paddingRight={2}>
          <Grid item xs={6} md={2}>
            <Typography color={"primary"} variant={"h1"}>
              Billing
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography variant={"h6"} color={"primary"}>
              Las operaciones aquí procesadas, se mandarán a revisión para su
              posterior aprobación.
            </Typography>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={4} md={4}>
        <Box justifyContent="left" display="flex" paddingLeft={15}>
          <FormControl className={get(props, "classes.formControl", {})}>
            <Select
              id="simple-select-outlined"
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              disabled={true}
              input={<BootstrapInput />}
              classes={{ icon: get(props, "classes.icon", {}) }}
              value={"Colombia"}
            >
              <MenuItem value={"Colombia"}>Colombia</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
};
export const HistoricManualContingency = (props: {
  classes: object;
  handleSetDate: (date: DateRange) => void;
  range: DateRange;
  query: string;
  handleCloseSnackBar: () => void;
  notification: INotification;
  setLoadingManualContingency: boolean;
  handleInputChange: (value: string) => void;
  handleHistoricSearchBar: (value: string) => void;
  downloadFileState: { isLoading: boolean; downloadFile: () => void };
  settingsColumns: {
    selectedColumns: string[];
    setSelectedColumns: (data: string[]) => void;
    handleAppliedColumnsFilters: (restore?: boolean) => void;
  };
}) => {
  const handleCloseNotification = () => {
    props.handleCloseSnackBar();
  };
  const [
    anchorColumns,
    setAnchorColumns,
  ] = React.useState<HTMLButtonElement | null>(null);
  const openColumns = Boolean(anchorColumns);
  const idButtonColumns = openColumns ? "filter-popover" : undefined;
  const [selectableColumns] = useState<IColumnsTable[]>(
    defaultHeadersManualContingencyHistory.filter(
      (column: TableHeaders<any>) => column.filter
    ) as IColumnsTable[]
  );
  const handleClickColumns = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorColumns(event.currentTarget);
  };
  const handleCloseColumns = () => {
    setAnchorColumns(null);
  };
  const handleChangeColumnCheck = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const column: string = event.target.name;
    let auxColumnsSelected: string[] = [
      ...props.settingsColumns.selectedColumns,
    ];
    if (!event.target.checked) {
      const index: number = auxColumnsSelected.findIndex(
        (col: string) => col === column
      );

      if (index !== -1) auxColumnsSelected.splice(index, 1);
    } else {
      if (props.settingsColumns.selectedColumns.length > 2) return;
      auxColumnsSelected = [...auxColumnsSelected, column];
    }
    props.settingsColumns.setSelectedColumns(auxColumnsSelected);
  };
  const handleRestoreColumns = () => {
    props.settingsColumns.handleAppliedColumnsFilters(true);
    handleCloseColumns();
  };
  const handleDisplayedColumnUser = () => {
    props.settingsColumns.handleAppliedColumnsFilters();
    handleCloseColumns();
  };
  return (
    <Grid
      container
      alignItems="center"
      justify="flex-start"
      direction="row"
      spacing={1}
      className={get(props, "classes.menuHistoric", {})}
    >
      <Grid item xs={3}>
        <SmartlinkDatePicker
          value={props.range}
          disableFuture={false}
          handleDate={props.handleSetDate}
        />
      </Grid>
      <Grid item xs={4} justify={"flex-start"}>
        <TextField
          value={props.query}
          id="outlined-search"
          key={"outlinedSearch"}
          autoFocus
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search size={16} />
              </InputAdornment>
            ),
          }}
          className={get(props, "classes.textField", {})}
          variant={"outlined"}
          type="search"
          size={"small"}
          placeholder={"TAX ID / Comercio / Razón social"}
          onKeyDown={(event: any) => {
            props.handleHistoricSearchBar(event.key);
          }}
          onChange={(event: any) => {
            props.handleInputChange(event.target.value);
          }}
        />
      </Grid>
      <Grid item xs={1} justify={"flex-start"}>
        <Box className={get(props, "classes.iconButton", {})}>
          <IconButton
            aria-label="settings-columns"
            color="primary"
            onClick={handleClickColumns}
          >
            <img src={imgSettings} />
          </IconButton>
          <ColumnFilters
            openColumns={openColumns}
            idButtonColumns={idButtonColumns}
            anchorColumns={anchorColumns}
            handleCloseColumns={handleCloseColumns}
            selectedColumns={props.settingsColumns.selectedColumns}
            handleChangeColumnCheck={handleChangeColumnCheck}
            handleRestoreColumns={handleRestoreColumns}
            handleDisplayedColumnUser={handleDisplayedColumnUser}
            selectableColumns={selectableColumns}
            title={"Elige hasta 2 columnas para mostrar"}
            admittedColumns={2}
          />
        </Box>
      </Grid>
      <Grid item xs={4} justify={"flex-end"}>
        <Box
          display="flex"
          alignItems={"center"}
          flexWrap={"wrap"}
          justifyContent={"flex-end"}
          style={{ marginRight: "25px" }}
        >
          <ButtonDownloadFile
            buttonV2={true}
            options={[
              {
                text: "Descargar en .xlsx",
                value: "xlsx",
              },
            ]}
            isLoading={props.downloadFileState.isLoading}
            downloadFile={props.downloadFileState.downloadFile}
            isAll={true}
          />
          <Loader
            open={props.setLoadingManualContingency}
            headerText={"Estamos procesando la descarga"}
            bodyText={"Este proceso puede tardar un poco..."}
          />
          <SnackbarAlert
            type={props.notification.type}
            msg={props.notification.message}
            open={props.notification.open && !props.setLoadingManualContingency}
            handlerClose={handleCloseNotification}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export const TabManualContingency = (props: { classes: object }) => {
  return (
    <Grid className={get(props, "classes.tab", {})}>
      <AppBar position="static" className={get(props, "classes.root", {})}>
        <Tabs
          value={0}
          aria-label="billing tabs"
          variant="fullWidth"
          indicatorColor={"primary"}
        >
          <Tab
            className={get(props, "classes.tabStyle", {})}
            label={
              <Typography variant={"h6"} color={"primary"}>
                {BillingTabTitleEnum.BILLING_VALIDATOR}
              </Typography>
            }
          />
        </Tabs>
      </AppBar>
    </Grid>
  );
};

export const TableManualContingency = (props: {
  data: ResponseSearchBillingByFilters;
  paginationProps: PaginationTableProps | undefined;
  headers: TableHeaders<object>[];
  sort: ISort;
  setLoadingHistoric: boolean;
  modalOver: ModalOverUtils;
}) => {
  const defaultCode = (type: string): JSX.Element => {
    return <Chip style={{ backgroundColor: "#F7FAFC" }} label={type} />;
  };

  return (
    <TabPanel value={0} index={0}>
      <BackofficeTable
        items={get(props, "data.records", [])}
        headers={props.headers}
        sort={{
          order: props.sort.order,
          orderBy: props.sort.orderBy,
          handleRequestSort: (
            _event: React.MouseEvent<unknown>,
            property: string
          ) => props.sort.handleRequestSort(_event, property),
        }}
        handleOnRowSelection={props.modalOver.handlerSelectTrxItem}
        skeleton={props.setLoadingHistoric}
        emptyState={{
          title: "No se ha encontrado ninguna coincidencia",
          subtitle: "",
        }}
        customRenderers={{
          cycle: (trx: object) => translate(get(trx, "cycle", "")),
          kind: (trx: object) => defaultCode(translate(get(trx, "kind", ""))),
          amount: (trx: object) => get(trx, "amount", "") + " COP",
        }}
        pagination={props.paginationProps}
      />
    </TabPanel>
  );
};
