import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const ecuadorDeferredInfoStyles = createNamedStyles({
  paperMonth: {
    width: 35,
    height: 23,
  },
  subtitle: {
    color: "#6D7781",
    lineHeight: "140%",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
  },
  imgTemplate: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "3px 12px",
    gap: "10px",
    width: "45px",
    height: "28px",
    background: "#FFFFFF",
    border: "0.5px solid #CBD5E0",
    borderRadius: "4px",
    flex: "none",
    order: "0",
    flexGrow: "0",
  },
  mainGrid: {
    direction: "row",
    spacing: 3,
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
  },
});
