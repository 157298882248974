import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IModules } from "../../interfaces/modules/modules.interfaces";
import {
  changeStatusModule,
  createUpdateModule,
  getAllModules,
  getAllRoles,
} from "../../thunks/modules/modules.thunks";
import { GetAllModulesResponse } from "../../../../types/get_all_modules_response";
import {
  ERROR_ADD_MODULE_MESSAGE,
  SUCCESS_ADD_MODULE_MESSAGE,
  SUCCESS_MODULE_MESSAGE,
} from "../../../shared/constants/labels/modules_container_labels";

export const initialState: IModules = {
  dataForm: { description: "", id: "", roles: [] },
  isLoadingGetModules: true,
  modules: [],
  moduleSelected: {
    description: "22",
    id: "22",
    parent: "11",
    roles: [],
    status: "ENABLE",
    type: "MODULE",
  },
  roles: [],
};

export const modulesSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getAllModules.pending, (state) => {
        state.isLoadingGetModules = true;
      })
      .addCase(
        getAllModules.fulfilled,
        (state, { payload }: PayloadAction<GetAllModulesResponse[]>) => {
          state.modules = payload;
          state.isLoadingGetModules = false;
        }
      )
      .addCase(getAllRoles.fulfilled, (state, { payload }) => {
        state.roles = payload;
      })
      .addCase(getAllModules.rejected, (state) => {
        state.isLoadingGetModules = false;
      })
      .addCase(createUpdateModule.fulfilled, (state, { payload }) => {
        state.modules = payload.modules;
        state.alert = {
          message: payload.isNew
            ? SUCCESS_ADD_MODULE_MESSAGE
            : SUCCESS_MODULE_MESSAGE,
          type: "success",
        };
      })
      .addCase(createUpdateModule.rejected, (state, {}) => {
        state.alert = {
          message: ERROR_ADD_MODULE_MESSAGE,
          type: "error",
        };
      })
      .addCase(changeStatusModule.fulfilled, (state, { payload }) => {
        const updateIndex = state.modules.findIndex(
          (module) => module.id === payload.id
        );

        state.modules[updateIndex] &&
          (state.modules[updateIndex].status = payload.body.status);
        state.alert = {
          message: SUCCESS_MODULE_MESSAGE,
          type: "success",
        };
      });
  },
  initialState,
  name: "modules",
  reducers: {
    clearAlert: (state) => {
      state.alert = undefined;
    },
    clearModule: (state) => {
      state.moduleSelected = undefined;
    },
    setModule: (state, { payload }) => {
      state.moduleSelected = payload;
    },
  },
});

export default modulesSlice.reducer;
