import React, { FC } from "react";
import { Grid } from "@mui/material";
import { Datepicker } from "@kushki/connect-ui";
import { useDatePicker } from "./state/useDatePicker";
export declare type DATE_FORMAT_TYPE =
  | "DD/MMM/YYYY"
  | "YYYY/MMM/DD"
  | "MMM/DD/YYYY";

export interface DatePickerTagProps {
  placeHolder: string;
  onChange(value: string): void;
  format: DATE_FORMAT_TYPE;
  value: string;
}

export const DatePickerComponent: FC<DatePickerTagProps> = (
  props: DatePickerTagProps
) => {
  const { date } = useDatePicker({
    onChange: props.onChange,
    value: props.value,
  });

  return (
    <Grid item xs={12} sm={4}>
      <Datepicker
        disableFuture={false}
        placeholder={props.placeHolder}
        isOpen={false}
        dateFormat={props.format}
        onDateChange={(value: string) => {
          props.onChange(value);
        }}
        defaultValue={date}
      />
    </Grid>
  );
};
