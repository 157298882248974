import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";

export const ContactAreaEnum: ICategory[] = [
  {
    label: "Área Comercial",
    selected: false,
    value: "contactCommercialArea",
  },
  {
    label: "Área de Facturación",
    selected: false,
    value: "contactBillingArea",
  },
  {
    label: "Área Dispersión",
    selected: false,
    value: "contactDispersionArea",
  },
  {
    label: "Área Técnica",
    selected: false,
    value: "contactTechnicalArea",
  },
  {
    label: "Área de Riesgos",
    selected: false,
    value: "contactRiskArea",
  },
];
