export enum LocalStoragePathEnum {
  CUSTOMER_BATCH_INFORMATION = "customerBatchInformation",
  MASSIVE_SERVICE_CONFIGURATIONS = "massiveServiceConfigurations",
  USERNAME = "username",
  EMAIL = "email",
}

export enum SessionStoragePaths {
  BRANCH_EDIT_LIST = "branchEditList",
}
