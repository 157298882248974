/**
 * CountryEnum
 */
export enum CountryNameEnum {
  Colombia = "Colombia",
  Chile = "Chile",
  Ecuador = "Ecuador",
  Mexico = "Mexico",
  Peru = "Perú",
  Brazil = "Brazil",
  ElSalvador = "El Salvador",
  Panama = "Panamá",
  CostaRica = "Costa Rica",
}
export enum CountryValueEnum {
  Colombia = "Colombia",
  Chile = "Chile",
  Ecuador = "Ecuador",
  Mexico = "Mexico",
  Peru = "Peru",
  Brazil = "Brazil",
  ElSalvador = "ElSalvador",
  Panama = "Panama",
  CostaRica = "CostaRica",
}

export enum CountryRetentionEnum {
  colombia = "colombia",
  chile = "chile",
  ecuador = "ecuador",
  mexico = "mexico",
  peru = "peru",
}

export const ALLOW_COUNTRIES_RETENTIONS: Record<string, string> = {
  [CountryRetentionEnum.ecuador]: CountryRetentionEnum.ecuador,
  [CountryRetentionEnum.colombia]: CountryRetentionEnum.colombia,
};
