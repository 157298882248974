import React from "react";
import { Box, Grid, Switch, Typography } from "@mui/material";
import { IInternationalTransferConfigurationProps } from "./InternationalTransferConfiguration.interfaces";
import { addBankAccountDifferentCountryFlowFormStyles as styles } from "../AddBankAccountDifferentCountryFlow/AddBankAccountDifferentCountryFlow.styles";
import { ADD_BANK_ACCOUNT_MODAL_LABELS } from "../../shared/constants/labels/add_bank_account_modal_labels";
import InfoText from "../InfoText/InfoText";
import AlfaNumericInput from "../FormInputFields/AlfaNumericInput/AlfaNumericInput";
import {
  BankAccountFields,
  BankAccountPlaceholders,
} from "../../shared/enums/dispersionsEnum";
import { accountRules } from "../../shared/constants/form_account_labels";
import { IBAN_COUNTRIES } from "../../shared/constants/catalogs/catalog-iban-countries";

const InternationalTransferConfiguration = ({
  banksForm,
  changeIsInternationalTransfer,
  isInternationalTransfer,
  isInvalidCurrency,
  isSameCountry,
  country,
}: IInternationalTransferConfigurationProps) => {
  return (
    <>
      <Box sx={styles.boxContainer}>
        <Box sx={styles.box}>
          <Typography
            sx={isInvalidCurrency ? styles.disabledLabel : styles.label}
            color="text.grey"
            variant="body2"
          >
            {ADD_BANK_ACCOUNT_MODAL_LABELS.principal.titleInternationalTransfer}
          </Typography>
          <Switch
            disabled={isInvalidCurrency}
            size={"small"}
            checked={isInternationalTransfer}
            onChange={(event) => {
              changeIsInternationalTransfer!(event.target.checked);
            }}
          />
        </Box>
        {isInvalidCurrency && (
          <Box sx={styles.boxInfo}>
            <InfoText
              text={ADD_BANK_ACCOUNT_MODAL_LABELS.principal.currencyHelperText}
            />
          </Box>
        )}
      </Box>
      {isInternationalTransfer && (
        <Grid container display={"flex"} flexDirection={"row"}>
          <Grid data-testid="bank-citi-origin-id" item xs={6} sx={{ p: 1 }}>
            <AlfaNumericInput
              control={banksForm.control}
              errors={banksForm.errors}
              name={BankAccountFields.BANK_CITY_OF_ORIGIN}
              label={BankAccountPlaceholders.BANK_CITY_OF_ORIGIN}
              rules={accountRules.bankCityOfOrigin}
            />
          </Grid>
          <Grid
            data-testid="bank-postal-code-origin-id"
            item
            xs={6}
            sx={{ p: 1 }}
          >
            <AlfaNumericInput
              control={banksForm.control}
              errors={banksForm.errors}
              name={BankAccountFields.BANK_POSTAL_CODE_ORIGIN}
              label={BankAccountPlaceholders.BANK_POSTAL_CODE_ORIGIN}
              rules={accountRules.bankPostalCodeOrigin}
            />
          </Grid>
          {isSameCountry && (
            <>
              <Grid item xs={6} sx={{ p: 1 }}>
                <AlfaNumericInput
                  control={banksForm.control}
                  errors={banksForm.errors}
                  name={BankAccountFields.FINANCIAL_INSTITUTION_ADDRESS}
                  label={BankAccountPlaceholders.FINANCIAL_INSTITUTION_ADDRESS}
                  rules={
                    accountRules.financialInstitutionAddressInternationalTransfer
                  }
                />
              </Grid>
              <Grid data-testid="swift-code-id" item xs={6} sx={{ p: 1 }}>
                <AlfaNumericInput
                  control={banksForm.control}
                  errors={banksForm.errors}
                  name={BankAccountFields.SWIFT_CODE}
                  label={BankAccountPlaceholders.SWIFT_CODE}
                  rules={accountRules.swiftCodeInternationalTransfer}
                />
              </Grid>
              {IBAN_COUNTRIES.includes(country) && (
                <Grid item xs={6} sx={{ p: 1 }}>
                  <AlfaNumericInput
                    control={banksForm.control}
                    errors={banksForm.errors}
                    name={BankAccountFields.IBAN_CODE}
                    label={BankAccountPlaceholders.IBAN_CODE}
                    rules={accountRules.ibanCodeInternationalTransfer}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      )}
    </>
  );
};

export default InternationalTransferConfiguration;
