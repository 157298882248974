import {
  COUNTRIES,
  PAYMENT_METHODS,
  PROCESSORS,
  SelectCountry,
  SelectPaymentMethod,
} from "../../../shared/infrastructure/constants/HeaderFilters";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/hooks";
import { selectFiltersState } from "../../../store/selectors/filters/filters";
import { setHeaderFilter } from "../../../store/actions/filters/filters";

export interface IHeaderFilterState {
  countries: SelectCountry[];
  handler: {
    setSelectedCountry: (coutry: string) => void;
    setSelectedPaymentMethod: (paymentMethod: string) => void;
    setSelectedProcessorName: (processorName: string) => void;
  };
  paymentMethods: SelectPaymentMethod[];
  processors: string[];
  selectedCountry: string;
  selectedPaymentMethod: string;
  selectedProcessorName: string;
}

export const useHeaderFilterState = (): IHeaderFilterState => {
  const dispatch = useAppDispatch();
  const filtersState = useAppSelector(selectFiltersState);

  const setSelectedCountry = (country: string): void => {
    dispatch(setHeaderFilter({ ...filtersState.headerFilter, country }));
  };

  const setSelectedPaymentMethod = (paymentMethod: string): void => {
    dispatch(setHeaderFilter({ ...filtersState.headerFilter, paymentMethod }));
  };

  const setSelectedProcessorName = (processorName: string): void => {
    dispatch(setHeaderFilter({ ...filtersState.headerFilter, processorName }));
  };

  return {
    countries: COUNTRIES,
    handler: {
      setSelectedCountry,
      setSelectedPaymentMethod,
      setSelectedProcessorName,
    },
    paymentMethods: PAYMENT_METHODS,
    processors: PROCESSORS,
    selectedCountry: filtersState.headerFilter.country,
    selectedPaymentMethod: filtersState.headerFilter.paymentMethod,
    selectedProcessorName: filtersState.headerFilter.processorName,
  };
};
