export const BRANCH_LABELS = {
  ADVISEMENT: "Máximo permitido: 500 registros.",
  CLIENT_TYPE: "Tipo de cliente: B2B, B2C, PSP",
  CONSTITUCIONAL_COUNTRY:
    "País de Constitución: Deberá coincidir con los catálogos de país que se manejan en la Consola.",
  CREATION_INFORMATION:
    "Ingresa los nombres de los branches, configura sus datos y selecciona cuáles de ellos serán descentralizados.",
  CREATION_TITLE: "Creación de Branches",
  DESCENTRALIZE: "Descentralizado: S para SI o  N para NO",
  DOWNLOAD_FILE: "Descarga este archivo: ",
  DOWNLOAD_FILE_LINK:
    "https://kushki-static.s3.amazonaws.com/branch-templates/nombre+de+archivo.csv",
  DOWNLOAD_FILE_NAME: "  nombre de archivo.csv",
  EDITION_SUBTITLE:
    "Podrás completar la información de los branches descentralizados de forma individual o hacer una edición grupal seleccionando varios branches, siempre y cuando tengan el mismo país de constitución.",
  EDITION_TITLE: "Edición de branches",
  FORMAT_FILE: "Formatos válidos: csv.",
  INSTRUCTIONS: "Instrucciones de llenado",
  LOADING_FILE: "Cargando Archivo...",
  NAME: "Nombre: No deberá contener caracteres especiales.",
  STEP_1: "Paso 1:",
  STEP_1_INFORMATION:
    "Abre el archivo, lee las instrucciones y completa los campos requeridos en el documento. Luego, guárdalo y súbelo a continuación.",
  STEP_2: "Paso 2:",
  TOTAL_BRANCHES: (total: {
    centralize: string;
    descentralize: string;
    total: string;
  }) =>
    `Centralizados: ${total.centralize} de ${total.total} | Descentralizados: ${total.descentralize} de ${total.total}`,
  TOTAL_EDIT_BRANCHES: (total: { incomplete: string; noModify: string }) =>
    `Incompletos: ${total.incomplete} | Sin modificar: ${total.noModify}`,
  TOTAL_EDIT_SELECTED_BRANCHES: (total: { selected: string; total: string }) =>
    `Seleccionados: ${total.selected} de ${total.total}`,
  UPLOAD_AREA_TEXT: "Arrastra el documento hasta aquí",
  UPLOAD_FILE: "Selecciona un archivo",
  VALIDATE_EXTENSION: "csv",
  VALIDATE_EXTENSION_ERROR: "El formato del archivo no es válido.",
  VALIDATE_EXTENSION_ERROR_ADVISEMENT:
    "Comprueba que el archivo tenga un formato .csv",
  VALIDATE_SIZE_ERROR: "El archivo supera el máximo de registros permitidos.",
  VALIDATE_SIZE_ERROR_ADVISEMENT:
    "Comprueba que el archivo tenga máximo 500 registros.",
};
