import React, { FC } from "react";
import { Box, Button, Modal, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useStyles } from "./ModalConfigStyle";
import { IModalConfig } from "../../containers/ProcessorIndex/state/useProcessorIndexState";
import ProcessorSelect from "../ProcessorSelect/ProcessorSelect";
import {
  CONFIGURATION_LABELS,
  LabelEnum,
} from "../../shared/infrastructure/constants/ProcessorsInformationConstant";
import { CountryEnum } from "../../shared/infrastructure/constants/CountryEnum";
import { processorTypeLabelEnum } from "../../shared/constants/filter_component";

export interface IModalConfigProps {
  config: IModalConfig;
  country: string;
  renderPayoutsCard: boolean;
}

export const getOptions = (country: string, labels: string[]) => {
  if (country === CountryEnum.COLOMBIA) {
    return labels;
  }

  return labels.filter((label) => label !== "achTransfer");
};

export const ModalConfig: FC<IModalConfigProps> = ({
  config,
  country,
  renderPayoutsCard,
}: IModalConfigProps) => {
  const classes = useStyles();

  let options: string[] = getOptions(
    country,
    Object.keys(CONFIGURATION_LABELS)
  );

  if (!renderPayoutsCard) {
    options = options.filter(
      (option) => option !== processorTypeLabelEnum.PAYOUTS_CARD
    );
  }

  return (
    <Modal
      open={config.open}
      onClose={() => config.setOpen(false)}
      className={classes.modal}
    >
      <Box className={classes.paper}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography
            variant={"subtitle1"}
            color={"primary"}
            className={classes.bold}
          >
            {LabelEnum.DEFAULT_PROCESSOR_CONFIG}
          </Typography>
          <Close
            className={classes.close}
            onClick={() => config.setOpen(false)}
          />
        </Box>
        <Box mt={5}>
          {options.map((label, index) => (
            <ProcessorSelect
              key={index}
              control={config.form.control}
              name={label}
              options={config.merchantProcessors[label]}
            />
          ))}
        </Box>
        <Box display={"flex"} justifyContent={"flex-end"} mt={6}>
          <Button
            className={classes.bold}
            onClick={() => config.setOpen(false)}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.save}
            disabled={config.showConfigButton}
            onClick={() => {
              config.form.handleSubmit(config.handleSubmitDefaultProcessor)();
            }}
          >
            Configurar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalConfig;
