import { IStyles } from "../../../shared/interfaces/create_named_styles";

export const slideItemStyles = (): IStyles => {
  return {
    container: {
      display: "flex",
      flexDirection: "row",
      gap: 2,
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      width: "50%",
    },
    image: {
      display: "flex",
      justifyContent: "center",
      width: "50%",
    },
    secondaryText: {
      color: "#293036",
      display: "flex",
      justifyContent: "flex-start",
      userSelect: "none",
      paddingRight: {
        sm: 0,
        md: "50px",
      },
    },
    title: {
      color: "#023365",
      display: "flex",
      justifyContent: "flex-start",
      paddingTop: 2,
      userSelect: "none",
    },
  };
};
