import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ToggleButton, ToggleButtonProps } from "@material-ui/lab";
import { DeferredTypeMonthEnum } from "../../shared/infrastructure/constants/DeferredTypeMonthEnum";
import { Box } from "@material-ui/core";
import { isArray } from "lodash";

export interface DeferredMonthsProps extends ToggleButtonProps {
  months?: string[];
  merchantMonths?: string[];
  monthsType: DeferredTypeMonthEnum;
}

const useStyles = makeStyles((theme: Theme) => ({
  grouped: (props: DeferredMonthsProps) => ({
    "&:first-child": {
      "&.MuiToggleButton-root.Mui-selected": {
        backgroundColor:
          props.monthsType === DeferredTypeMonthEnum.MONTHS
            ? "#0DC298"
            : "#023365",
        border: "1px solid",
        borderColor:
          props.monthsType === DeferredTypeMonthEnum.MONTHS
            ? "#0DC298"
            : "#023365",
        borderRadius: "4px",
        color: "white",
        fontSize: "13px",
        height: "10px",
        lineHeight: "100%",
        margin: "4px",
        width: "27px",
      },
      backgroundColor: "#F7FAFC",
      border: "1px solid",
      borderColor: "#CBD5E0",
      borderRadius: "4px",
      color: "black",
      fontSize: "13px",
      height: "10px",
      lineHeight: "100%",
      margin: "4px",
      width: "27px",
    },
    "&:not(:first-child)": {
      "&.MuiToggleButton-root.Mui-selected": {
        backgroundColor:
          props.monthsType === DeferredTypeMonthEnum.MONTHS
            ? "#0DC298"
            : "#023365",
        border: "1px solid",
        borderColor:
          props.monthsType === DeferredTypeMonthEnum.MONTHS
            ? "#0DC298"
            : "#023365",
        borderRadius: "4px",
        color: "white",
        fontSize: "13px",
        height: "10px",
        lineHeight: "100%",
        margin: "4px",
        width: "27px",
      },
      backgroundColor: "#F7FAFC",
      border: "1px solid",
      borderColor: "#CBD5E0",
      borderRadius: "4px",
      color: "black",
      fontSize: "13px",
      height: "10px",
      lineHeight: "100%",
      margin: "4px",
      width: "27px",
    },
    margin: theme.spacing(2),
  }),
}));

const getSelected = (merchantMonth: string, months?: string[]) =>
  isArray(months) && months.includes(merchantMonth);

export const DeferredMonths: React.FC<DeferredMonthsProps> = (
  props: DeferredMonthsProps
) => {
  const classes = useStyles(props);

  const { months, merchantMonths, monthsType } = props;

  return (
    <Box display={"flex"}>
      {isArray(merchantMonths) ? (
        merchantMonths.length > 0 ? (
          merchantMonths.map((merchantMonth) => (
            <ToggleButton
              key={merchantMonth + monthsType}
              value={merchantMonth}
              disabled={true}
              selected={getSelected(merchantMonth, months)}
              className={classes.grouped}
            >
              {merchantMonth}
            </ToggleButton>
          ))
        ) : (
          <ToggleButton className={classes.grouped} disabled={true}>
            -
          </ToggleButton>
        )
      ) : (
        "No aplica"
      )}
    </Box>
  );
};
