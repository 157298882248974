import { CatalogsEnum } from "../interfaces/catalogs-enum";
import { Category } from "../constants/information_constants";

export const CatalogNi: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { defaultShareholderDocType: "0", value: "0", name: "CI" },
    { defaultShareholderDocType: "0", value: "1", name: "NUC" },
  ],
  [CatalogsEnum.Provinces]: [
    { value: "1101", name: "Boaco" },
    { value: "1102", name: "Carazo" },
    { value: "1103", name: "Chinandega" },
    { value: "1104", name: "Chontales" },
    { value: "1105", name: "Estelí" },
    { value: "1106", name: "Granada" },
    { value: "1107", name: "Jinotega" },
    { value: "1108", name: "León" },
    { value: "1109", name: "Madriz" },
    { value: "1110", name: "Managua" },
    { value: "1111", name: "Masaya" },
    { value: "1112", name: "Matagalpa" },
    { value: "1113", name: "Nueva Segovia" },
    { value: "1114", name: "Atlántico Norte" },
    { value: "1115", name: "Atlántico Sur" },
    { value: "1116", name: "Río San Juan" },
    { value: "1117", name: "Rivas" },
  ],

  [CatalogsEnum.Cities]: [
    { value: "11011", name: "Boaco", parent: "1101" },
    { value: "11012", name: "Camoapa", parent: "1101" },
    { value: "11013", name: "San José de los Remates", parent: "1101" },
    { value: "11014", name: "San Lorenzo", parent: "1101" },
    { value: "11015", name: "Santa Lucía", parent: "1101" },
    { value: "11016", name: "Teustepe", parent: "1101" },
    { value: "11021", name: "Diriamba", parent: "1102" },
    { value: "11022", name: "Dolores", parent: "1102" },
    { value: "11023", name: "El Rosario", parent: "1102" },
    { value: "11024", name: "Jinotepe", parent: "1102" },
    { value: "11025", name: "La Conquista", parent: "1102" },
    { value: "11026", name: "La Paz de Carazo", parent: "1102" },
    { value: "11027", name: "San Marcos", parent: "1102" },
    { value: "11028", name: "Santa Teresa", parent: "1102" },
    { value: "11031", name: "Chichigalpa", parent: "1103" },
    { value: "11032", name: "Chinandega", parent: "1103" },
    { value: "11033", name: "Cinco Pinos", parent: "1103" },
    { value: "11034", name: "Corinto", parent: "1103" },
    { value: "11035", name: "El Realejo", parent: "1103" },
    { value: "11036", name: "El Viejo", parent: "1103" },
    { value: "11037", name: "Posoltega", parent: "1103" },
    { value: "11038", name: "Puerto Morazán", parent: "1103" },
    { value: "11039", name: "San Francisco del Norte", parent: "1103" },
    { value: "110310", name: "San Pedro del Norte", parent: "1103" },
    { value: "110311", name: "Santo Tomás del Norte", parent: "1103" },
    { value: "110312", name: "Somotillo", parent: "1103" },
    { value: "110313", name: "Villanueva", parent: "1103" },
    { value: "11041", name: "Acoyapa", parent: "1104" },
    { value: "11042", name: "Comalapa", parent: "1104" },
    { value: "11043", name: "Juigalpa", parent: "1104" },
    { value: "11044", name: "La Libertad", parent: "1104" },
    { value: "11045", name: "San Pedro de Lóvago", parent: "1104" },
    { value: "11046", name: "Santo Domingo", parent: "1104" },
    { value: "11047", name: "Santo Tomás", parent: "1104" },
    { value: "11048", name: "Villa Sandino", parent: "1104" },
    { value: "11049", name: "El Coral", parent: "1104" },
    { value: "110410", name: "San Francisco de Cuapa", parent: "1104" },
    { value: "11051", name: "Condega", parent: "1105" },
    { value: "11052", name: "Estelí", parent: "1105" },
    { value: "11053", name: "La Trinidad", parent: "1105" },
    { value: "11054", name: "Pueblo Nuevo", parent: "1105" },
    { value: "11055", name: "San Juan de Limay", parent: "1105" },
    { value: "11056", name: "San Nicolás", parent: "1105" },
    { value: "11061", name: "Diriá", parent: "1106" },
    { value: "11062", name: "Diriomo", parent: "1106" },
    { value: "11063", name: "Granada", parent: "1106" },
    { value: "11064", name: "Nandaime", parent: "1106" },
    { value: "11071", name: "El Cuá", parent: "1107" },
    { value: "11072", name: "Jinotega", parent: "1107" },
    { value: "11073", name: "La Concordia", parent: "1107" },
    { value: "11074", name: "San Rafael del Norte", parent: "1107" },
    { value: "11075", name: "San Sebastián de Yalí", parent: "1107" },
    { value: "11076", name: "Santa María de Pantasma", parent: "1107" },
    { value: "11077", name: "Wiwilí de Jinotega", parent: "1107" },
    { value: "11081", name: "San José de Achuapa", parent: "1108" },
    { value: "11082", name: "El Sauce", parent: "1108" },
    { value: "11083", name: "El Jicaral", parent: "1108" },
    { value: "11084", name: "La Paz Centro", parent: "1108" },
    { value: "11085", name: "León", parent: "1108" },
    { value: "11086", name: "Larreynaga", parent: "1108" },
    { value: "11087", name: "Nagarote", parent: "1108" },
    { value: "11088", name: "Quezalguaque", parent: "1108" },
    { value: "110810", name: "Santa Rosa del Peñón", parent: "1108" },
    { value: "110811", name: "Telica", parent: "1108" },
    { value: "11091", name: "Las Sabanas", parent: "1109" },
    { value: "11092", name: "Palacagüina", parent: "1109" },
    { value: "11093", name: "San José de Cusmapa", parent: "1109" },
    { value: "11094", name: "San Juan del Río Coco", parent: "1109" },
    { value: "11095", name: "San Lucas", parent: "1109" },
    { value: "11096", name: "Somoto", parent: "1109" },
    { value: "11097", name: "Telpaneca", parent: "1109" },
    { value: "11098", name: "Totogalpa", parent: "1109" },
    { value: "11099", name: "Yalagüina", parent: "1109" },
    { value: "11101", name: "El Crucero", parent: "1110" },
    { value: "11102", name: "Managua", parent: "1110" },
    { value: "11103", name: "Mateare", parent: "1110" },
    { value: "11104", name: "San Francisco Libre", parent: "1110" },
    { value: "11105", name: "San Rafael del Sur", parent: "1110" },
    { value: "11106", name: "Ticuantepe", parent: "1110" },
    { value: "11107", name: "Tipitapa", parent: "1110" },
    { value: "11108", name: "Villa El Carmen", parent: "1110" },
    { value: "11109", name: "Ciudad Sandino", parent: "1110" },
    { value: "11111", name: "Catarina", parent: "1111" },
    { value: "11112", name: "La Concepción", parent: "1111" },
    { value: "11113", name: "Masatepe", parent: "1111" },
    { value: "11114", name: "Masaya", parent: "1111" },
    { value: "11115", name: "Nandasmo", parent: "1111" },
    { value: "11116", name: "Nindirí", parent: "1111" },
    { value: "11117", name: "Niquinohomo", parent: "1111" },
    { value: "11118", name: "San Juan de Oriente", parent: "1111" },
    { value: "11119", name: "Tisma", parent: "1111" },
    { value: "11121", name: "Ciudad Darío", parent: "1112" },
    { value: "11122", name: "El Tuma-La Dalia", parent: "1112" },
    { value: "11123", name: "Esquipulas", parent: "1112" },
    { value: "11124", name: "Matagalpa", parent: "1112" },
    { value: "11125", name: "Matiguás", parent: "1112" },
    { value: "11126", name: "Muy Muy", parent: "1112" },
    { value: "11127", name: "Rancho Grande", parent: "1112" },
    { value: "11128", name: "Río Blanco", parent: "1112" },
    { value: "11129", name: "San Dionisio", parent: "1112" },
    { value: "111210", name: "San Isidro", parent: "1112" },
    { value: "111211", name: "San Ramón", parent: "1112" },
    { value: "111212", name: "Sébaco", parent: "1112" },
    { value: "111213", name: "Terrabona", parent: "1112" },
    { value: "11131", name: "Ciudad Antigua", parent: "1113" },
    { value: "11132", name: "Dipilto", parent: "1113" },
    { value: "11133", name: "El Jícaro", parent: "1113" },
    { value: "11134", name: "Jalapa", parent: "1113" },
    { value: "11135", name: "Macuelizo", parent: "1113" },
    { value: "11136", name: "Mozonte", parent: "1113" },
    { value: "11137", name: "Murra", parent: "1113" },
    { value: "11138", name: "Ocotal", parent: "1113" },
    { value: "11139", name: "Quilalí", parent: "1113" },
    { value: "111310", name: "San Fernando", parent: "1113" },
    { value: "111311", name: "Santa María", parent: "1113" },
    { value: "111312", name: "Wiwilí de Nueva Segovia", parent: "1113" },
    { value: "11141", name: "Bonanza", parent: "1114" },
    { value: "11142", name: "Mulukukú", parent: "1114" },
    { value: "11143", name: "Prinzapolka", parent: "1114" },
    { value: "11144", name: "Puerto Cabezas", parent: "1114" },
    { value: "11145", name: "Rosita", parent: "1114" },
    { value: "11146", name: "Siuna", parent: "1114" },
    { value: "11147", name: "Waslala", parent: "1114" },
    { value: "11148", name: "Waspán", parent: "1114" },
    { value: "11151", name: "Bluefields", parent: "1115" },
    { value: "11152", name: "Paiwas", parent: "1115" },
    { value: "11153", name: "Corn Island", parent: "1115" },
    { value: "11154", name: "Desembocadura de Río Grande", parent: "1115" },
    { value: "11155", name: "El Rama", parent: "1115" },
    { value: "11156", name: "El Tortuguero", parent: "1115" },
    { value: "11157", name: "Kukra Hill", parent: "1115" },
    { value: "11158", name: "La Cruz de Río Grande", parent: "1115" },
    { value: "11159", name: "Laguna de Perlas", parent: "1115" },
    { value: "111510", name: "Muelle de los Bueyes", parent: "1115" },
    { value: "111511", name: "Nueva Guinea", parent: "1115" },
    { value: "111512", name: "El Ayote", parent: "1115" },
    { value: "11161", name: "El Almendro", parent: "1116" },
    { value: "11162", name: "El Castillo", parent: "1116" },
    { value: "11163", name: "Morrito", parent: "1116" },
    { value: "11164", name: "San Carlos", parent: "1116" },
    { value: "11165", name: "San Juan de Nicaragua", parent: "1116" },
    { value: "11166", name: "San Miguelito", parent: "1116" },
    { value: "11171", name: "Altagracia", parent: "1117" },
    { value: "11172", name: "Belén", parent: "1117" },
    { value: "11173", name: "Buenos Aires", parent: "1117" },
    { value: "11174", name: "Cárdenas", parent: "1117" },
    { value: "11175", name: "Moyogalpa", parent: "1117" },
    { value: "11176", name: "Potosí", parent: "1117" },
    { value: "11177", name: "Rivas", parent: "1117" },
    { value: "11178", name: "San Jorge", parent: "1117" },
    { value: "11179", name: "San Juan del Sur", parent: "1117" },
    { value: "111710", name: "Tola", parent: "1117" },
  ],
};
