import * as React from "react";
import { FC, useEffect } from "react";
import { ICellTagProps } from "./CellTag.interfaces";
import { Chip, Skeleton } from "@mui/material";
import { useTableContext } from "../../Table/TableContext";
import { CellTagStyles } from "./CellTag.styles";
import { pushOrReplaceObjectInValueCells } from "../../Table/TableContext/tableUtils";

export const CellTagItem: FC<ICellTagProps> = ({
  id,
  rowIndex,
  text,
  value,
  color,
}: ICellTagProps) => {
  const {
    state: { isLoading, valueCells },
    handler: { handleSetCellValues },
  } = useTableContext();

  useEffect(() => {
    handleSetCellValues(
      pushOrReplaceObjectInValueCells(valueCells, {
        error: false,
        id: `${rowIndex}-${id}`,
        rowIndex,
        value: value,
      })
    );
  }, [text]);

  return (
    <>
      {isLoading ? (
        <Skeleton
          data-testid="skeleton"
          variant="rectangular"
          animation="wave"
          sx={CellTagStyles.skeleton}
        />
      ) : (
        <Chip label={text} size="small" color={color} />
      )}
    </>
  );
};
