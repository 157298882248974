import Rollbar from "rollbar";
import { environment } from "../../../environments/environment";
import packageJson from "../../../../package.json";
import { SpaNameEnum } from "./SpaNameEnum";
import { SPA_BASENAME } from "../../constants/routes";

export const urlToLogRollbar = (): boolean =>
  window.location.pathname.includes(SPA_BASENAME);

export const getRollbarConfig: Rollbar.Configuration = {
  accessToken: environment.rollbarToken,
  addErrorContext: true,
  autoInstrument: true,
  captureUncaught: true,
  captureUnhandledRejections: true,
  checkIgnore: () => !urlToLogRollbar(),
  environment: environment.envName,
  payload: {
    client: {
      javascript: {
        code_version: packageJson.dependencies["@kushki/js"],
        source_map_enabled: true,
      },
    },
    context: { name: SpaNameEnum.CONSOLE_CREATE_NODE },
    environment: environment.envName,
    person: {
      email: localStorage.getItem("email")!,
      id:
        localStorage.getItem("merchantId") || localStorage.getItem("username")!,
      username: localStorage.getItem("username")!,
    },
  },
};
