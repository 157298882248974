export interface IUseWebhookTable {
  tableCellPercentage: (index: number) => string;
}

export const useWebhookTable = (): IUseWebhookTable => {
  const tableCellPercentage = (index: number): string => {
    return index === 0
      ? "10%"
      : index === 1
      ? "18%"
      : index === 2
      ? "35%"
      : index === 3
      ? "15%"
      : "30%";
  };

  return {
    tableCellPercentage,
  };
};
