import React from "react";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { IModalSimpleProps } from "./modalSimple.interfaces";
import { useModalSimpleStyles } from "./modalSimple.styles";

const ModalSimple: React.FC<IModalSimpleProps> = ({
  isOpen,
  closeModal,
  primaryButton,
  secondaryButton,
  title,
  description,
  primaryButtonVariant = "outlined",
}: IModalSimpleProps) => {
  const styles = useModalSimpleStyles(primaryButtonVariant);

  return (
    <Modal open={isOpen}>
      <Box sx={styles.container}>
        <Box sx={styles.content}>
          <Box sx={styles.title}>
            <Typography variant="h3" sx={styles.textColor}>
              {title}
            </Typography>
            <IconButton
              color="secondary"
              onClick={closeModal}
              size="small"
              sx={styles.closeButton}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>
            <Typography variant="body2">{description}</Typography>
          </Box>
        </Box>
        <Box sx={styles.buttonsWrapper}>
          <Button
            variant="text"
            onClick={closeModal}
            sx={styles.secondaryButton}
          >
            {secondaryButton}
          </Button>
          <Button
            variant={primaryButtonVariant}
            onClick={primaryButton.onClick}
            sx={styles.primaryButton}
          >
            {primaryButton.text}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalSimple;
