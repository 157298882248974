import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { RootState } from "../../../store/store";
import { useLegalData } from "../../../shared/utils/state/useLegalData";
import { IMerchantPeopleNodeRequest } from "../../../store/interfaces/LegalDetailsState.interfaces";
import {
  becomeSameLevel,
  constructRequestCompliance,
} from "../../../shared/utils/basic_data_utils";
import { get } from "react-hook-form";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateMassiveBranch } from "../../../store/thunks/massiveBranch/massiveBranchs.thunks";
import { objectSaveMassiveBranch } from "../../../shared/utils/massiveBranchEdit";
import { ConfigIdEnum } from "../../../shared/constants/initial_state_legal_details";
import { TabItemStatusEnum } from "../../../shared/enum/tabItemStatusEnum";
import { StatusSocketEnum } from "../../../shared/enum/socketEnum";
import { setStatusSocket } from "../../../store/actions/massiveBranch/massiveBranch.actions";
import { ActionFooterEnum } from "../../../shared/enums/ActionFooterEnum";
import { redirectPath } from "../../../shared/utils/company_data_utils";
import { setNotificationMassive } from "../../../store/actions/massiveBranch.actions";
import { useSnackbar } from "@kushki/connect-ui";
import { setCompliancePeopleNode } from "../../../store/reducers/legalDetails/legalDetails";
import { CompliancePeopleNodeResponse } from "../../../../types/get_compliance_people_response";
import { PubliclyExposedEnum } from "../../../shared/enums/RadioButtonEnum";

export const useLegalStateMassive = () => {
  const {
    countriesInfo,
    footerLabel,
    headerProps,
    isEdit,
    primaryButton,
    secondaryButton,
    shareHolders,
    merchantNodeInfo,
    nodeInfo,
    legalRepresentative,
    isValidateForm,
    handleChangeStatusNodeInfo,
    isLoadingCompliancePeople,
    form,
    boardMembers,
  } = useLegalData(true);

  const { showSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const dispatchWS = useDispatch();
  const {
    title,
    listMerchants,
    listBranchesMassive,
    nodeInfoMassiveLegalData,
    notificationMassive,
  } = useAppSelector((state: RootState) => ({
    ...state.branchBatch,
    ...state.initialData,
  }));

  const { statusSocket } = useAppSelector(
    (state: RootState) => state.massiveBranch
  );

  const [openModalLoader, setOpenModalLoader] = useState<boolean>(false);
  const [isModalFinalization, setModalFinalization] = useState<boolean>(false);
  const [typeButton, setTypeButton] = useState<undefined | ActionFooterEnum>(
    undefined
  );

  const setFinalization = (stateModal: boolean) => {
    setModalFinalization(stateModal);
  };

  const legalDetailsObject = (): IMerchantPeopleNodeRequest =>
    constructRequestCompliance(
      legalRepresentative,
      becomeSameLevel(shareHolders),
      boardMembers,
      merchantNodeInfo,
      nodeInfo,
      get(nodeInfoMassiveLegalData, "merchantId", ""),
      form.watch("exposed") === PubliclyExposedEnum.YES ? true : false
    );

  const handleNextInfo = async (typeButton: ActionFooterEnum) => {
    setTypeButton(typeButton);
    setOpenModalLoader(true);
    handleChangeStatusNodeInfo(
      !isValidateForm ? TabItemStatusEnum.COMPLETE : TabItemStatusEnum.PENDING,
      ConfigIdEnum.CN015
    );

    const legalMassive: IMerchantPeopleNodeRequest = legalDetailsObject();

    const compliancePeople: CompliancePeopleNodeResponse = {
      boardmembers: get(legalMassive, "boardmembers", []),
      constitutionalCountry: get(legalMassive, "constitutionalCountry", ""),
      country: get(legalMassive, "country", ""),
      legalRepresentatives: get(legalMassive, "legalRepresentatives", []),
      merchantId: get(legalMassive, "merchantId", ""),
      merchantName: get(legalMassive, "merchantName", ""),
      shareholders: get(legalMassive, "shareholders", []),
      socialReason: get(legalMassive, "socialReason", {}),
    };

    dispatch(setCompliancePeopleNode(compliancePeople));

    await dispatchWS(
      updateMassiveBranch(
        objectSaveMassiveBranch(
          legalMassive,
          ConfigIdEnum.CN015,
          listBranchesMassive,
          !isValidateForm
            ? TabItemStatusEnum.COMPLETE
            : TabItemStatusEnum.PENDING
        )
      )
    );
  };

  useEffect(() => {
    if (
      statusSocket === StatusSocketEnum.SUCCESS ||
      statusSocket === StatusSocketEnum.ERROR
    ) {
      setOpenModalLoader(false);

      if (
        typeButton === ActionFooterEnum.CONTINUE &&
        statusSocket === StatusSocketEnum.SUCCESS
      ) {
        setTypeButton(undefined);
        dispatchWS(setStatusSocket(StatusSocketEnum.NONE));
        redirectPath("/create-node/branch/edit");

        return;
      }
      dispatchWS(setStatusSocket(StatusSocketEnum.NONE));
      setTypeButton(undefined);
    }
  }, [statusSocket]);

  useEffect(() => {
    if (notificationMassive) {
      showSnackbar(notificationMassive);
      dispatch(setNotificationMassive(undefined));
    }
  }, [notificationMassive]);

  return {
    countriesInfo,
    footerLabel,
    form,
    headerProps,
    isEdit,
    isLoadingCompliancePeople,
    isModalFinalization,
    numberBranches: `${listMerchants.split(",").length}`,
    openModalLoader,
    primaryButton: {
      ...primaryButton,
      onAction: () => {
        setFinalization(true);
      },
    },
    saveValues: handleNextInfo,
    secondaryButton: {
      ...secondaryButton,
      isDisabled: primaryButton.isDisabled,
      onAction: () => handleNextInfo(ActionFooterEnum.SAVE),
    },
    setModalFinalization,
    title,
  };
};
