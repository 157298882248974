import { IStyles } from "../../shared/interfaces/Styles.interfaces";
import { createStyles, makeStyles } from "@mui/styles";

const ModalDetailInfoStyles: IStyles = {
  modalContainer: {
    alignItems: "flex-start",
    backgroundColor: "#ffffff",
    flexDirection: "column",
    display: "flex",
    margin: "0px 0px",
    padding: "20px 0px 20px 20px",
    top: "7%",
    bottom: "3%",
    right: "0%",
    position: "absolute",
    width: "40%",
  },
  modalInformation: {
    flexDirection: "column",
    display: "flex",
    padding: "8px",
    width: "100%",
    height: "100%",
  },
  iconButton: {
    paddingBottom: "24px",
  },
  detailModal: {
    padding: "24px 8px",
    overflowY: "scroll",
    flexGrow: "1",
    width: "100%",
    height: "100%",
  },
  boxTabs: {
    borderRadius: "4px",
    marginTop: "20px",
    marginBottom: "16px",
    paddingBottom: 0,
  },
  boxTabsNoMargin: {
    borderRadius: "4px",
    marginTop: 0,
    marginBottom: "16px",
    paddingBottom: 0,
  },
  icon: {
    transform: "scaleX(-1)",
  },
};

export const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 16,
    },
    paper: {
      padding: "15px 20px !important",
      backgroundColor: "#F7FAFC !important",
      borderRadius: 3,
      boxShadow: "0 0px 0px rgba(0,0,0,0), 0 0px 0px rgba(0,0,0,0)",
    },
    container: {
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: 24,
      paddingRight: 24,
    },
  })
);

export { ModalDetailInfoStyles };
