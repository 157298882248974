import React, { FC } from "react";
import BillingForm from "../../components/BillingForm/BillingForm";
import FormWrapper from "../../components/FormWrapper/FormWrapper";
import WrapperContainer from "../../components/WrapperContainer/WrapperContainer";
import { ROUTES } from "../../shared/constants/routes";
import { useBillingContainer } from "./state/useBillingContainer";
import BillingContainerSkeleton from "../../components/Skeletons/BillingContainerSkeleton/BillingContainerSkeleton";

const BillingContainer: FC = () => {
  const { form, isLoadingBillingInformation } = useBillingContainer();

  return (
    <WrapperContainer currentRoute={ROUTES.BILLING}>
      {isLoadingBillingInformation ? (
        <BillingContainerSkeleton />
      ) : (
        <FormWrapper title={"Facturación"}>
          <BillingForm control={form.control} errors={form.errors} />
        </FormWrapper>
      )}
    </WrapperContainer>
  );
};

export default BillingContainer;
