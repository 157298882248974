import React, { FC } from "react";
import AccordionBanksAccounts from "../AccordionBanksAccounts/AccordionBanksAccounts";
import ModalDialog from "../Modals/ModalDialog/ModalDialog";
import { useChargeBankAccountState } from "./state/useChargeBankAccountState";
import ChargeBankAccountFormsModal from "../Modals/ChargeBankAccountFormsModal/ChargeBankAccountFormsModal";
import { IBankAccountFormsModalProps } from "../Modals/ChargeBankAccountFormsModal/ChargeBankAccountFormsModal.interfaces";

const ChargeBankAccount: FC<IBankAccountFormsModalProps> = ({
  isCentralizedBranch,
}: IBankAccountFormsModalProps) => {
  const { accordionBanksAccountsProps, modalDialogProperties } =
    useChargeBankAccountState();

  accordionBanksAccountsProps.disabledActions = isCentralizedBranch;

  return (
    <>
      <ChargeBankAccountFormsModal
        isCentralizedBranch={isCentralizedBranch}
        canAddAccounts={accordionBanksAccountsProps.canAddAccounts}
      />
      <AccordionBanksAccounts {...accordionBanksAccountsProps} />
      <ModalDialog {...modalDialogProperties} />
    </>
  );
};

export default ChargeBankAccount;
