import React from "react";
import {
  TextField,
  Grid,
  Select,
  CircularProgress,
  FormControl,
} from "@material-ui/core";
import { useTypedController } from "@hookform/strictly-typed";
import ConnectForm from "../../commons/ConnectForm/ConnectForm";
import { SecurityRulesLabelField } from "../../../shared/infrastructure/constants/SecurityRulesConstants";
import { get, isEmpty } from "lodash";
import { Autocomplete } from "@material-ui/lab";
import { useWatch } from "react-hook-form";
import { IOptionSelection } from "../../../shared/infrastructure/constants/RulesConstants";
import { IBusinessRulesForm } from "../../../shared/infrastructure/interfaces/IBusinessRulesForm";
import { ErrorsEnum } from "../../../shared/infrastructure/enums/ErrorEnum";
import { findName } from "../../../shared/utils";

export interface AutocompleteFieldComponentProps {
  disabled: boolean;
  items: string[];
  options: IOptionSelection[];
  compare: "name" | "value";
  path: "name" | "value";
}

export const AutocompleteFieldComponent: React.FC<AutocompleteFieldComponentProps> =
  (props: AutocompleteFieldComponentProps) => {
    return (
      <ConnectForm<IBusinessRulesForm>>
        {({ control, errors }) => {
          const TypedController = useTypedController<IBusinessRulesForm>({
            control: control,
          });

          const value = useWatch({
            name: "rulesInputValue",
          }) as string;

          return (
            <React.Fragment>
              <TypedController
                name={"rulesInputValue"}
                render={({ onChange }) =>
                  props.disabled ? (
                    <FormControl
                      variant="outlined"
                      fullWidth
                      style={{ height: "100%" }}
                      error={!!errors.processorName}
                    >
                      <Grid container>
                        <Grid item xs={10}>
                          <Select
                            native
                            defaultValue={"-1"}
                            disabled
                            style={{ width: "85%" }}
                          >
                            <option key={"Elegir"} value={"*Elegir"}>
                              Valor
                            </option>
                          </Select>
                        </Grid>
                        <Grid item xs={2} style={{ margin: "auto" }}>
                          <CircularProgress color="inherit" size={20} />
                        </Grid>
                      </Grid>
                    </FormControl>
                  ) : (
                    <Autocomplete
                      data-testid="rulesInputValueWrapper"
                      value={isEmpty(value) ? "" : value}
                      options={props.items}
                      onChange={(_event: any, value: any) => {
                        onChange(value);
                      }}
                      defaultValue={value}
                      getOptionLabel={(option) =>
                        findName(
                          props.options,
                          option,
                          props.compare,
                          props.path
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!get(errors, "rulesInputValue")}
                          label={SecurityRulesLabelField.VALUE}
                          variant="outlined"
                          disabled={props.disabled}
                          fullWidth
                          helperText={
                            !!get(errors, "rulesInputValue") &&
                            ErrorsEnum.REQUIRED_FIELD
                          }
                        />
                      )}
                    />
                  )
                }
              />
            </React.Fragment>
          );
        }}
      </ConnectForm>
    );
  };

export default AutocompleteFieldComponent;
