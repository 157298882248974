import { makeStyles } from "@material-ui/core/styles";

export const useBillingInvoiceStyles = makeStyles(() => ({
  mainTitle: {
    fontSize: "40px",
  },
  titleHeader: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
  },
  titleCardHeader: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    margin: "16px 0px",
  },
  subTitleHeader: {
    fontSize: "16px",
    fontWeight: "normal",
    color: "#293036",
    margin: "16px 0px",
  },
}));
