import { Box } from "@mui/material";
import React, { FC } from "react";
import { IWrapperContainer } from "./WrapperContainer.interfaces";
import { wrapperContainerStyles as styles } from "./WrapperContainer.styles";
import { useWrapperContainer } from "./state/useWrapperContainer";
import WrappedTabs from "../WrappedTabs/WrappedTabs";
import FooterOptions from "../FooterOptions/FooterOptions";
import { defaultTo, get, isEmpty } from "lodash";
import { WebhookModalConfig } from "../../shared/enums/WebhookModals";
import { ModalSimple } from "@kushki/connect-ui";

const WrapperContainer: FC<IWrapperContainer> = ({
  children,
  isCentralized,
}: IWrapperContainer) => {
  const {
    continueToConfigWebhooks,
    openModalRedirect,
    continueToResumeMerchant,
    tabs,
    footerLabels,
    modals,
  } = useWrapperContainer({ isCentralized });

  return (
    <>
      <Box sx={styles.containerBody}>
        <Box>
          <WrappedTabs tabs={tabs} />
        </Box>
        <Box sx={styles.containerForm}>{children}</Box>
      </Box>
      <Box sx={styles.containerFooter}>
        <FooterOptions
          primaryButton={{
            isHidden: false,
            onAction: openModalRedirect,
          }}
          secondaryButton={{
            isHidden: true,
          }}
          isLoading={true}
          label={{
            date: get(footerLabels, "date", ""),
            editDetail: false,
            isHidden: defaultTo(isEmpty(footerLabels), false),
            text: get(footerLabels, "author", ""),
          }}
        />
      </Box>
      <ModalSimple
        onClickPrimary={continueToConfigWebhooks}
        onClickSecondary={continueToResumeMerchant}
        titleText={WebhookModalConfig.TITLE}
        descriptionText={WebhookModalConfig.DESCRIPTION}
        buttonPrimary={WebhookModalConfig.CONFIRM}
        buttonSecondary={WebhookModalConfig.DECLINE}
        isOpen={modals.webhookModalActions.isOpen}
        onClose={modals.webhookModalActions.close}
        typeModal={1}
      />
    </>
  );
};

export default WrapperContainer;
