export enum LabelsDeferred {
  DESCRIPTION = "Una vez realizada podrás visualizar y descargar el Balance GeneralSPLIT y la respuesta del Banco dentro del detalle de la transacción.",
  FILTER = "Filtrar",
  FILTER_BY = "Filtrar por",
  COLLAPSED = "Minimizar todo",
  EXPANDED = "Maximizar todo",
  STATUS = "Estado",
  FEE = "Cuotas a diferir",
  MONTHS = "Meses de gracia",
  PROCESSORS = "Procesadores",
  CLP = "CLP",
  APPLY_FILTER = "Aplicar Filtros",
  CLEAN_FILTER = "Borrar filtros",
  DOWNLOAD = "Descargar",
  DEFERRED_PROGRAMS = "Diferidos programados",
}
export const Activo: string = "Activo";
export const Inactivo: string = "Inactivo";
export const chipStatus: string[] = [Activo, Inactivo];
