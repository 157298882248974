import React, { PropsWithChildren } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import successValidation from "../../assets/images/successValidation.png";
import { requestDispersionOnProcessStyles } from "./RequestDispersionOnProcess.styles";
import { LabelsDispersion } from "../../shared/infrastructure/constants/RequestDispersionConstants";
import { Routes } from "../../shared/infrastructure/routes";
import { RequestDispersionOnProcessProps } from "../../shared/infrastructure/interfaces/RequestDispersionOnProcess.interface";
import { get } from "lodash";

export const RequestDispersionOnProcess = (
  props: PropsWithChildren<RequestDispersionOnProcessProps>
) => {
  const classes = requestDispersionOnProcessStyles();

  return (
    <Grid
      container
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      spacing={3}
    >
      <Grid item xs={12}>
        <Box display={"flex"} justifyContent={"center"}>
          <img
            src={successValidation}
            alt={"success"}
            className={classes.successImage}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.mainTitle}>
          {LabelsDispersion.REQUEST_IN_PROCESS}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.description}>
          {LabelsDispersion.DESCRIPTION.split("SPLIT")[0]}
        </Typography>
        <Typography className={classes.description}>
          {LabelsDispersion.DESCRIPTION.split("SPLIT")[1]}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.resume}>
          {LabelsDispersion.RESUME_DISPERSION}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Grid container direction={"row"} spacing={2}>
          <Grid item xs={6}>
            <Typography className={classes.rowTitle}>
              {LabelsDispersion.N_REQUEST}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.rowContent}>
              {get(props, "processFileDispersionsResponse.referenceNumber", "")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.rowTitle}>
              {LabelsDispersion.AMOUNT}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.rowContent}>
              {`${get(
                props,
                "processFileDispersionsResponse.totalAmount",
                0
              )} ${get(props, "processFileDispersionsResponse.currency", "")}`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.rowTitle}>
              {LabelsDispersion.ITEMS}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.rowContent}>
              {get(props, "processFileDispersionsResponse.rows", 0)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <Button
          variant={"contained"}
          color={"primary"}
          className={classes.button}
          onClick={() => {
            window.location.href = Routes.PAYOUTS_TRANSACTION;
          }}
          fullWidth
        >
          {LabelsDispersion.GO_BACK_TO_TRANSACTIONS}
        </Button>
      </Grid>
    </Grid>
  );
};
