import { useEffect, useState } from "react";
import {
  buildSelectedNodesByEntityName,
  INodeSelectedParsed,
} from "@shared/utils/selectedNodesUtil";
import {
  ISelectedNodesProps,
  IUseSelectedNodes,
} from "@components/SelectedNodes/SelectedNodes.interfaces";
import { isEmpty } from "lodash";
import { INodeRuleSessionStorage } from "@shared/interfaces/INodeRuleSessionStorage";

export const useSelectedNodes = ({
  selectedNodes,
  onClickNode,
}: ISelectedNodesProps): IUseSelectedNodes => {
  const [selectedNodeParsed, setSelectedNodeParsed] =
    useState<INodeSelectedParsed>(
      buildSelectedNodesByEntityName(selectedNodes)
    );

  useEffect(() => {
    setSelectedNodeParsed(buildSelectedNodesByEntityName(selectedNodes));
  }, [selectedNodes]);

  const buildLabel = (nodes: INodeRuleSessionStorage[]): string => {
    const names = nodes.map((node) => node.name);

    if (nodes.length <= 2) {
      return names.join(", ");
    }
    const namesSlice: string[] = names.slice(0, 3);

    namesSlice[2] = `${namesSlice[2].substring(0, 2)}...`;

    return `${namesSlice.join(", ")}(+${nodes.length - 2})`;
  };

  const handleClickNode = (mode: string, actualView: string) => {
    if (!isEmpty(selectedNodeParsed[actualView.toLowerCase()]) && onClickNode) {
      onClickNode(mode, actualView);
    }
  };

  return {
    branchLabel: buildLabel(selectedNodeParsed.branch),
    customerLabel: buildLabel(selectedNodeParsed.customer),
    handleClickNode,
    ownerLabel: buildLabel(selectedNodeParsed.owner),
  };
};
