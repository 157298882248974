import { createNamedStyles } from "../../shared/infrastructure/interfaces/create_named_styles";

export const useDeferredStyles = createNamedStyles({
  mainCard: {
    borderRadius: "16px",
    paddingX: "54px",
    paddingY: "38px",
  },
  mainTitle: {
    marginBottom: "10px",
  },
  gridList: {
    paddingTop: "12px",
  },
  itemCard: {
    paddingX: "4px",
  },
  resumeDivider: {
    border: "1px solid",
    color: "neutral.grey4",
    width: "100%",
    marginTop: "11px",
  },
  resumeRulesTitle: {
    margin: "13px 0 4px 0",
  },
});
