/* istanbul ignore file */
/* eslint-disable */
export const environment: {
    kushkiUrl: string;
    envName: string;
    publicCredentialId: object;
    production: boolean;
    devTools: boolean;
    walletMinAmount: object;
} = {
    kushkiUrl: "https://api.kushkipagos.com",
    envName: "primary",
    publicCredentialId: {"Chile":"319030a7055b41dbb70d5f67a42de774","Mexico":"e95edd9a5be946a681915ab824be1c31"},
    production: true,
    devTools: false,
    walletMinAmount: {"Chile":1000,"Mexico":6},
};
