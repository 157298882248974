import { useEffect, useState } from "react";
import { BreadcrumProps } from "../../../components/common/Breadcrumb/Breadcrumb";
import { routes } from "../../../shared/infrastructure/routes";
import {
  defaultHistoricColumnsSelectable,
  historicColumnsTable,
  IColumnsTable,
} from "../../../shared/infrastructure/Table/ColumnsTable";
import {
  AuthenticationRules,
  ResponseSearchRulesByFilters,
} from "../../../../types/response_search_rules_by_filters";
import { DataTableFiltersProps } from "../../../components/Filters/DataTableFilters";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { IPagination } from "../../RuleApprovalDashboard/state/useRuleApprovalDashboardState";
import {
  DonwloadFile,
  setAuthenticationRulesDataHistoric,
} from "../../../store/actionCreators";
import { DefaultFilter } from "../../../shared/infrastructure/filter/DefaultFilter";
import { cloneDeep, filter, get } from "lodash";
import { AuthenticationElastic } from "../../../../types/remote/authentication_elastic";
import { ProcessorInfoResponse } from "../../../../types/processor_info_response";
import { CredentialInfoResponse } from "../../../../types/credential_info_response";
import { TRuleHistoricDashboardProps } from "../RuleHistoricDashboard";
import { SearchListManagerRequest } from "../../../../types/search_historic_selected_list_request";
import { LoadingFileEnum } from "../../../shared/infrastructure/enums/LoadingFileEnum";
import { AuthenticationRulesDashboard } from "../../../shared/infrastructure/constants/AuthenticationRulesConstants";

export interface IRuleHistoricDashboardState {
  state: IState;
  handlers: {};
  breadCrumbs: BreadcrumProps;
  data: {
    historicRulesDataHistoric: ResponseSearchRulesByFilters;
    isLoading: boolean;
    orderDataHistoric: (records: AuthenticationElastic[]) => void;
  };
  selectProps: {
    isLoading: boolean;
  };
  filterProps: DataTableFiltersProps;
  tableColumns: {
    renderColumnsHistoric: IColumnsTable[];
  };
  pagination: IPagination;
  downloadFileState: {
    isLoading: boolean;
    isLoadingList: boolean;
    downloadFile: (format: string) => void;
    downloadFileList: (format: string) => void;
  };
  checkboxActions: {
    handleCheckboxChange: (index: number) => void;
  };
  checkboxSelection: {
    handleSelectionCheckboxChange: (row: AuthenticationRules) => void;
    handleSelectionCheckboxHeaderChange: () => void;
  };
  selectedRows: AuthenticationRules[];
  modalOver: {
    openModalCard: boolean;
    handlerCloseModal: () => void;
    handlerSelectTrx: (
      row: AuthenticationRules | AuthenticationElastic | undefined,
      type: string
    ) => void;
    trx: AuthenticationRules | AuthenticationElastic;
    processorData: ProcessorInfoResponse[];
    credentialData: CredentialInfoResponse[];
  };
}

export interface IState {}

export const useRuleHistoricDashboardState = (
  props: TRuleHistoricDashboardProps
): IRuleHistoricDashboardState => {
  const stateStore = useSelector<IAppState, IAppState>(
    (state: IAppState) => state
  );
  const dispatch = useDispatch();
  const [openModalCard, setOpenModalCard] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<AuthenticationRules[]>([]);
  const [trx, SetTrx] = useState<AuthenticationRules>({});
  const {
    pagination: { page, pageSize, handleChangePage, handleChangePageSize },
    filterData: {
      queryInput,
      handleInputChange,
      handleSearchBar,
      range,
      handleSetDate,
      filterChips,
      handleSetFilters,
      counter,
    },
    stateUtil,
  } = props.stateUtilTab;
  const [state] = useState<IState>({});
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    defaultHistoricColumnsSelectable
  );

  const {
    authenticationRulesDataHistoric,
    isLoadingAuthenticationRulesHistoric,
  } = stateStore;

  const handleCheckboxChange = (index: number) => {
    updateRowTrx(index);
  };

  const handleAppliedColumnsFilters = (restore?: boolean) => {
    let selectedColumns_aux: string[] = selectedColumns;

    if (restore) {
      setSelectedColumns(defaultHistoricColumnsSelectable);
      selectedColumns_aux = defaultHistoricColumnsSelectable;
    }

    let renderColumns: IColumnsTable[] = historicColumnsTable.filter(
      (column) => {
        return selectedColumns_aux.includes(column.id);
      }
    );
    setRenderColumnsHistoric(
      renderColumns.map((column) => {
        column.view = true;
        return column;
      })
    );
  };

  const [renderColumnsHistoric, setRenderColumnsHistoric] =
    useState<IColumnsTable[]>(historicColumnsTable);
  const handleAppliedColumnsFiltersHistoric = (_restore?: boolean) => {
    let initialcolumns = filter(
      historicColumnsTable,
      (column: IColumnsTable) => column.view
    );

    setRenderColumnsHistoric(initialcolumns);
  };

  const handleSelectionCheckboxChange = (row: AuthenticationRules) => {
    const index: number =
      stateStore.authenticationRulesDataHistoric.records!.findIndex(
        (item: AuthenticationRules) => item.id === row.id
      );
    if (index > -1) updateRowTrx(index);
  };
  const handleSelectionCheckboxHeaderChange = () => {
    let aux_data_historic: AuthenticationRules[] = cloneDeep(
      stateStore.authenticationRulesDataHistoric.records!
    );
    aux_data_historic.forEach((item: AuthenticationRules) => {
      item.isDownload = false;
    });
    updateAuthenticationRulesHistoricData({
      ...stateStore.authenticationRulesDataHistoric,
      records: aux_data_historic,
    });
  };
  const updateRowTrx = (index: number) => {
    let aux_data_historic: ResponseSearchRulesByFilters = cloneDeep(
      stateStore.authenticationRulesDataHistoric
    );
    let aux_item = aux_data_historic.records![index];
    aux_item.isDownload = !aux_item.isDownload;
    updateAuthenticationRulesHistoricData(aux_data_historic);
  };

  const orderDataHistoric = (records: AuthenticationElastic[]) => {
    updateAuthenticationRulesHistoricData({
      records: [...records],
      total: stateStore.authenticationRulesDataHistoric.total,
    });
  };

  const updateAuthenticationRulesHistoricData = (
    data: ResponseSearchRulesByFilters
  ) => {
    const aux_records: AuthenticationRules[] = cloneDeep(
      get(data, "records", [])
    );
    const selecteds = aux_records.filter(
      (item) => get(item, "isDownload", false) === true
    );
    setSelectedRows(selecteds);
    dispatch(setAuthenticationRulesDataHistoric(data));
  };

  const downloadFile = (request: any, param: string) => {
    dispatch(DonwloadFile(request, param));
  };

  const handleLoadingDownloadFile = (format: string) => {
    const requestList: any = {
      ...stateUtil,
      format: format,
    };
    downloadFile(requestList, LoadingFileEnum.DOWNLOAD_FILE_ENUM);
  };

  const handleLoadingDownloadListFile = (format: string) => {
    const requestList: SearchListManagerRequest = {
      isDownloadList: true,
      format: format,
      requestIds: selectedRows.map((rule) => rule.id) as string[],
    };
    downloadFile(requestList, LoadingFileEnum.DOWNLOAD_LIST_FILE_ENUM);
  };

  const handlerSelectTrx = (
    row: AuthenticationRules | AuthenticationElastic | undefined,
    type: string
  ) => {
    if (row !== undefined && type !== "checkbox") {
      setOpenModalCard(true);
      props.getProcessorData(row.public_merchant_id!);
      props.getCredentialData(row.public_merchant_id!);
      SetTrx(row);
    }
  };

  const handlerCloseModal = () => setOpenModalCard(false);

  useEffect(() => {
    handleAppliedColumnsFiltersHistoric();
  }, []);

  return {
    state,
    handlers: {},
    breadCrumbs: {
      items: [
        {
          label: "Inicio",
          url: routes.DASHBOARD,
        },
        {
          label: "Prevención de Fraude",
          url: routes.DASHBOARD,
        },
      ],
      lastItem: AuthenticationRulesDashboard.TITLE,
    },
    data: {
      historicRulesDataHistoric: authenticationRulesDataHistoric,
      isLoading: isLoadingAuthenticationRulesHistoric,
      orderDataHistoric,
    },
    selectProps: {
      isLoading: true,
    },
    filterProps: {
      handleSetFilters,
      handleInputChange,
      handleSearchBar,
      handleSetDate,
      filters: cloneDeep(DefaultFilter),
      filterChips,
      range,
      counter,
      optionTabValue: 2,
      query: queryInput,
      selectedColumns: selectedColumns,
      setSelectedColumns: setSelectedColumns,
      handleAppliedColumnsFilters: handleAppliedColumnsFilters,
    },
    tableColumns: { renderColumnsHistoric },
    pagination: {
      page,
      pageSize,
      handleChangePage,
      handleChangePageSize,
    },
    downloadFileState: {
      isLoading: stateStore.isLoadingDownloadFile,
      isLoadingList: stateStore.isLoadingDownloadListFile,
      downloadFile: handleLoadingDownloadFile,
      downloadFileList: handleLoadingDownloadListFile,
    },
    checkboxActions: {
      handleCheckboxChange,
    },

    modalOver: {
      openModalCard: openModalCard,
      handlerCloseModal: handlerCloseModal,
      handlerSelectTrx: handlerSelectTrx,
      trx,
      processorData: props.processorData,
      credentialData: props.credentialData,
    },
    selectedRows,
    checkboxSelection: {
      handleSelectionCheckboxChange,
      handleSelectionCheckboxHeaderChange,
    },
  };
};
