export enum StatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  DELETED = "DELETED",
  NONE = "NONE",
}

export enum FilterStatusLabel {
  ACTIVE = "Activo",
  INACTIVE = "Inactivo",
  PENDING = "Pendiente",
  INVESTIGATION = "En investigación",
  ROS = "Cerrada ROS",
  NORMAL = "Cerrada normal",
}

export const RuleAlarmStatus: Record<
  StatusEnum,
  { color: string; text: string }
> = {
  [StatusEnum.ACTIVE]: { color: "success", text: "ACTIVO" },
  [StatusEnum.INACTIVE]: { color: "warning", text: "INACTIVO" },
  [StatusEnum.DELETED]: { color: "error", text: "ELIMINADO" },
  [StatusEnum.NONE]: { color: "primary", text: "N/A" },
};

export const TABLE_HEADER = {
  ALIAS: "Alias",
  BRANCHES: "Branches",
  CREATED_DATE: "Fecha de creación",
  FREQUENCY: "Frecuencia",
  STATUS: "Estado",
  VARIABLES: "Variables de alarma",
};

export const FOOTER_TEXT: string = "Alarmas por página";

export const ITEMS_PER_PAGE: number[] = [10, 20, 50];
