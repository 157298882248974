import { IconButton, styled, Typography } from "@mui/material";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import React from "react";
import { IconCircleWarn } from "@kushki/connect-ui";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({}));

export const ArrowRight = () => {
  return (
    <HtmlTooltip
      data-testid="html-tooll-tip"
      placement="right"
      arrow
      title={
        <React.Fragment>
          <Typography data-testid="branch">Branch</Typography>
          {
            "Es la sucursal del customer, este nivel es el establecido para procesar pagos."
          }
        </React.Fragment>
      }
    >
      <IconButton
        style={{
          paddingBottom: "22px",
          paddingRight: "25px",
        }}
      >
        <IconCircleWarn color="primary" fontSize="small"></IconCircleWarn>
      </IconButton>
    </HtmlTooltip>
  );
};
