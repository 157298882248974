import {
  FlagBrasil,
  FlagChile,
  FlagColombia,
  FlagCostaRica,
  FlagEcuador,
  FlagElSalvador,
  FlagGuatemala,
  FlagHonduras,
  FlagMexico,
  FlagNicaragua,
  FlagPanama,
  FlagPeru,
  FlagUsa,
} from "@kushki/connect-ui";
import React from "react";
import IconEmpty from "../../../assets/icons/IconEmpty";

import { CellFlagCountryType } from "./CellFlag.enum";

const TEXT_FLAG: Record<CellFlagCountryType | string, string> = {
  Afganistan: "AFG",
  Albania: "ALB",
  Alemania: "DEU",
  Andorra: "AND",
  Angola: "AGO",
  Anguila: "AIA",
  Antartida: "ATA",
  AntiguayBarbuda: "ATG",
  AntillasNeerlandesas: "ANT",
  ArabiaSaudita: "SAU",
  Argel: "DZA",
  Argentina: "ARG",
  Armenia: "ARM",
  Aruba: "ABW",
  Australia: "AUS",
  Austria: "AUT",
  Azerbaiyan: "AZE",
  Bahamas: "BHS",
  Bahrein: "BHR",
  Bangladesh: "BGD",
  Barbados: "BRB",
  Belarus: "BLR",
  Belgica: "BEL",
  Belice: "BLZ",
  Benin: "BEN",
  Bermudas: "BMU",
  Bhutan: "BTN",
  Bolivia: "BOL",
  BosniayHerzegovina: "BIH",
  Botsuana: "BWA",
  Brasil: "BRA",
  Brazil: "BRA",
  Brunei: "BRN",
  Bulgaria: "BGR",
  BurkinaFaso: "BFA",
  Burundi: "BDI",
  CaboVerde: "CPV",
  Camboya: "KHM",
  Camerun: "CMR",
  Canada: "CAN",
  Chad: "TCD",
  Chile: "CHL",
  China: "CHN",
  Chipre: "CYP",
  CiudaddelVaticano: "VAT",
  Colombia: "COL",
  Comoros: "COM",
  Congo: "COG",
  CoreadelNorte: "PRK",
  CoreadelSur: "KOR",
  CostadeMarfil: "CIV",
  CostaRica: "CRI",
  Croacia: "HRV",
  Cuba: "CUB",
  Dinamarca: "DNK",
  Dominica: "DMA",
  Ecuador: "ECU",
  EEUU: "USA",
  Egipto: "EGY",
  ElSalvador: "SLV",
  EmiratosArabesUnidos: "ARE",
  Eritrea: "ERI",
  Eslovaquia: "SVK",
  Eslovenia: "SVN",
  Espania: "ESP",
  EstadosUnidosdeAmerica: "USA",
  Estonia: "EST",
  Etiopia: "ETH",
  Fiji: "FJI",
  Filipinas: "PHL",
  Finlandia: "FIN",
  Francia: "FRA",
  Gabon: "GAB",
  Gambia: "GMB",
  Georgia: "GEO",
  GeorgiadelSureIslasSandwichdelSur: "SGS",
  Ghana: "GHA",
  Gibraltar: "GIB",
  Granada: "GRD",
  Grecia: "GRC",
  Groenlandia: "GRL",
  Guadalupe: "GLP",
  Guam: "GUM",
  Guatemala: "GTM",
  Guayana: "GUY",
  GuayanaFrancesa: "GUF",
  Guernsey: "GGY",
  Guinea: "GIN",
  GuineaBissau: "GNB",
  GuineaEcuatorial: "GNQ",
  Haiti: "HTI",
  Honduras: "HND",
  HongKong: "HKG",
  Hungria: "HUN",
  India: "IND",
  Indonesia: "IDN",
  Irak: "IRQ",
  Iran: "IRN",
  Irlanda: "IRL",
  IslaBouvet: "BVT",
  IsladeMan: "IMN",
  Islandia: "ISL",
  IslasAland: "ALA",
  IslasCaiman: "CYM",
  IslasChristmas: "CXR",
  IslasCocos: "CCK",
  IslasCook: "COK",
  IslasFaroe: "FRO",
  IslasHeardyMcDonald: "HMD",
  IslasMalvinas: "KLK",
  IslasMarshall: "MHL",
  IslasNorkfolk: "NFK",
  IslasPalaos: "PLW",
  IslasPitcairn: "PCN",
  IslasSolomon: "SLB",
  IslasSvalbardyJanMayen: "SJM",
  IslasTurcasyCaicos: "TCA",
  IslasVirgenesBritanicas: "VGB",
  IslasVirgenesdelosEstadosUnidosdeAmerica: "VIR",
  Israel: "ISR",
  Italia: "ITA",
  Jamaica: "JAM",
  Japon: "JPN",
  Jersey: "JEY",
  Jordania: "JOR",
  Kazajstan: "KAZ",
  Kenia: "KEN",
  Kirguistan: "KGZ",
  Kiribati: "KIR",
  Kuwait: "KWT",
  Laos: "LAO",
  Lesotho: "LSO",
  Letonia: "LVA",
  Libano: "LBN",
  Liberia: "LBR",
  Libia: "LBY",
  Liechtenstein: "LIE",
  Lituania: "LTU",
  Luxemburgo: "LUX",
  Macao: "MAC",
  Macedonia: "MKD",
  Madagascar: "MDG",
  Malasia: "MYS",
  Malawi: "MWI",
  Maldivas: "MDV",
  Mali: "MLI",
  Malta: "MLT",
  Marruecos: "MAR",
  Martinica: "MTQ",
  Mauricio: "MUS",
  Mauritania: "MRT",
  Mayotte: "MYT",
  Mexico: "MEX",
  Micronesia: "FSM",
  Moldova: "MDA",
  Monaco: "MCO",
  Mongolia: "MNG",
  Montenegro: "MNE",
  Montserrat: "MSR",
  Mozambique: "MOZ",
  Myanmar: "MMR",
  Namibia: "NAM",
  Nauru: "NRU",
  Nepal: "NPL",
  Nicaragua: "NIC",
  Niger: "NER",
  Nigeria: "NGA",
  Niue: "NIU",
  Noruega: "NOR",
  NuevaCaledonia: "NCL",
  NuevaZelanda: "NZL",
  Oman: "OMN",
  PaisesBajos: "NLD",
  Pakistan: "PAK",
  Palestina: "PSE",
  Panama: "PAN",
  PapuaNuevaGuinea: "PNG",
  Paraguay: "PRY",
  Peru: "PER",
  PolinesiaFrancesa: "PYF",
  Polonia: "POL",
  Portugal: "PRT",
  PuertoRico: "PRI",
  Qatar: "QAT",
  ReinoUnido: "GBR",
  RepublicaCentroAfricana: "CAF",
  RepublicaCheca: "CZE",
  RepublicaDominicana: "DOM",
  Reunion: "REU",
  Ruanda: "RWA",
  Rumania: "ROU",
  Rusia: "RUS",
  SaharaOccidental: "ESH",
  Samoa: "WSM",
  SamoaAmericana: "ASM",
  SanBartolome: "BLM",
  SanCristobalyNieves: "KNA",
  SanMarino: "SMR",
  SanPedroyMiquelon: "SPM",
  SantaElena: "SHN",
  SantaLucia: "LCA",
  SantoTomeyPrincipe: "STP",
  SanVicenteylasGranadinas: "VCT",
  Senegal: "SEN",
  SerbiayMontenegro: "SRB",
  Seychelles: "SYC",
  SierraLeona: "SLE",
  Singapur: "SGP",
  Siria: "SYR",
  Somalia: "SOM",
  SriLanka: "LKA",
  Suazilandia: "SWZ",
  Sudafrica: "ZAF",
  Sudan: "SDN",
  Suecia: "SWE",
  Suiza: "CHE",
  Surinam: "SUR",
  Tailandia: "THA",
  Taiwan: "TWN",
  Tanzania: "TZA",
  Tayikistan: "TJK",
  TerritorioBritanicodelOceanoIndico: "IOT",
  TerritoriosAustralesFranceses: "ATF",
  TimorLeste: "TLS",
  Togo: "TGO",
  Tokelau: "TKL",
  Tonga: "TON",
  TrinidadyTobago: "TTO",
  Tunez: "TUN",
  Turkmenistan: "TKM",
  Turquia: "TUR",
  Tuvalu: "TUV",
  Ucrania: "UKR",
  Uganda: "UGA",
  Uruguay: "URY",
  Uzbekistan: "UZB",
  Vanuatu: "VUT",
  Venezuela: "VEN",
  Vietnam: "VNM",
  WallisyFutuna: "WLF",
  Yemen: "YEM",
  Yibuti: "DJI",
};

const FLAG_ICON: Record<CellFlagCountryType, React.FC> = {
  Afganistan: IconEmpty,
  Albania: IconEmpty,
  Alemania: IconEmpty,
  Andorra: IconEmpty,
  Angola: IconEmpty,
  Anguila: IconEmpty,
  Antartida: IconEmpty,
  AntiguayBarbuda: IconEmpty,
  AntillasNeerlandesas: IconEmpty,
  ArabiaSaudita: IconEmpty,
  Argel: IconEmpty,
  Argentina: IconEmpty,
  Armenia: IconEmpty,
  Aruba: IconEmpty,
  Australia: IconEmpty,
  Austria: IconEmpty,
  Azerbaiyan: IconEmpty,
  Bahamas: IconEmpty,
  Bahrein: IconEmpty,
  Bangladesh: IconEmpty,
  Barbados: IconEmpty,
  Belarus: IconEmpty,
  Belgica: IconEmpty,
  Belice: IconEmpty,
  Benin: IconEmpty,
  Bermudas: IconEmpty,
  Bhutan: IconEmpty,
  Bolivia: IconEmpty,
  BosniayHerzegovina: IconEmpty,
  Botsuana: IconEmpty,
  Brasil: FlagBrasil,
  Brazil: FlagBrasil,
  Brunei: IconEmpty,
  Bulgaria: IconEmpty,
  BurkinaFaso: IconEmpty,
  Burundi: IconEmpty,
  CaboVerde: IconEmpty,
  Camboya: IconEmpty,
  Camerun: IconEmpty,
  Canada: IconEmpty,
  Chad: IconEmpty,
  Chile: FlagChile,
  China: IconEmpty,
  Chipre: IconEmpty,
  CiudaddelVaticano: IconEmpty,
  Colombia: FlagColombia,
  Comoros: IconEmpty,
  Congo: IconEmpty,
  CoreadelNorte: IconEmpty,
  CoreadelSur: IconEmpty,
  CostadeMarfil: IconEmpty,
  CostaRica: FlagCostaRica,
  Croacia: IconEmpty,
  Cuba: IconEmpty,
  Dinamarca: IconEmpty,
  Dominica: IconEmpty,
  Ecuador: FlagEcuador,
  EEUU: FlagUsa,
  Egipto: IconEmpty,
  ElSalvador: FlagElSalvador,
  EmiratosArabesUnidos: IconEmpty,
  Eritrea: IconEmpty,
  Eslovaquia: IconEmpty,
  Eslovenia: IconEmpty,
  Espania: IconEmpty,
  Estonia: IconEmpty,
  Etiopia: IconEmpty,
  Fiji: IconEmpty,
  Filipinas: IconEmpty,
  Finlandia: IconEmpty,
  Francia: IconEmpty,
  Gabon: IconEmpty,
  Gambia: IconEmpty,
  Georgia: IconEmpty,
  GeorgiadelSureIslasSandwichdelSur: IconEmpty,
  Ghana: IconEmpty,
  Gibraltar: IconEmpty,
  Granada: IconEmpty,
  Grecia: IconEmpty,
  Groenlandia: IconEmpty,
  Guadalupe: IconEmpty,
  Guam: IconEmpty,
  Guatemala: FlagGuatemala,
  Guayana: IconEmpty,
  GuayanaFrancesa: IconEmpty,
  Guernsey: IconEmpty,
  Guinea: IconEmpty,
  GuineaBissau: IconEmpty,
  GuineaEcuatorial: IconEmpty,
  Haiti: IconEmpty,
  Honduras: FlagHonduras,
  HongKong: IconEmpty,
  Hungria: IconEmpty,
  India: IconEmpty,
  Indonesia: IconEmpty,
  Irak: IconEmpty,
  Iran: IconEmpty,
  Irlanda: IconEmpty,
  IslaBouvet: IconEmpty,
  IsladeMan: IconEmpty,
  Islandia: IconEmpty,
  IslasAland: IconEmpty,
  IslasCaiman: IconEmpty,
  IslasChristmas: IconEmpty,
  IslasCocos: IconEmpty,
  IslasCook: IconEmpty,
  IslasFaroe: IconEmpty,
  IslasHeardyMcDonald: IconEmpty,
  IslasMalvinas: IconEmpty,
  IslasMarshall: IconEmpty,
  IslasNorkfolk: IconEmpty,
  IslasPalaos: IconEmpty,
  IslasPitcairn: IconEmpty,
  IslasSolomon: IconEmpty,
  IslasSvalbardyJanMayen: IconEmpty,
  IslasTurcasyCaicos: IconEmpty,
  IslasVirgenesBritanicas: IconEmpty,
  IslasVirgenesdelosEstadosUnidosdeAmerica: IconEmpty,
  Israel: IconEmpty,
  Italia: IconEmpty,
  Jamaica: IconEmpty,
  Japon: IconEmpty,
  Jersey: IconEmpty,
  Jordania: IconEmpty,
  Kazajstan: IconEmpty,
  Kenia: IconEmpty,
  Kirguistan: IconEmpty,
  Kiribati: IconEmpty,
  Kuwait: IconEmpty,
  Laos: IconEmpty,
  Lesotho: IconEmpty,
  Letonia: IconEmpty,
  Libano: IconEmpty,
  Liberia: IconEmpty,
  Libia: IconEmpty,
  Liechtenstein: IconEmpty,
  Lituania: IconEmpty,
  Luxemburgo: IconEmpty,
  Macao: IconEmpty,
  Macedonia: IconEmpty,
  Madagascar: IconEmpty,
  Malasia: IconEmpty,
  Malawi: IconEmpty,
  Maldivas: IconEmpty,
  Mali: IconEmpty,
  Malta: IconEmpty,
  Marruecos: IconEmpty,
  Martinica: IconEmpty,
  Mauricio: IconEmpty,
  Mauritania: IconEmpty,
  Mayotte: IconEmpty,
  Mexico: FlagMexico,
  Micronesia: IconEmpty,
  Moldova: IconEmpty,
  Monaco: IconEmpty,
  Mongolia: IconEmpty,
  Montenegro: IconEmpty,
  Montserrat: IconEmpty,
  Mozambique: IconEmpty,
  Myanmar: IconEmpty,
  Namibia: IconEmpty,
  Nauru: IconEmpty,
  Nepal: IconEmpty,
  Nicaragua: FlagNicaragua,
  Niger: IconEmpty,
  Nigeria: IconEmpty,
  Niue: IconEmpty,
  Noruega: IconEmpty,
  NuevaCaledonia: IconEmpty,
  NuevaZelanda: IconEmpty,
  Oman: IconEmpty,
  PaisesBajos: IconEmpty,
  Pakistan: IconEmpty,
  Palestina: IconEmpty,
  Panama: FlagPanama,
  PapuaNuevaGuinea: IconEmpty,
  Paraguay: IconEmpty,
  Peru: FlagPeru,
  PolinesiaFrancesa: IconEmpty,
  Polonia: IconEmpty,
  Portugal: IconEmpty,
  PuertoRico: IconEmpty,
  Qatar: IconEmpty,
  ReinoUnido: IconEmpty,
  RepublicaCentroAfricana: IconEmpty,
  RepublicaCheca: IconEmpty,
  RepublicaDominicana: IconEmpty,
  Reunion: IconEmpty,
  Ruanda: IconEmpty,
  Rumania: IconEmpty,
  Rusia: IconEmpty,
  SaharaOccidental: IconEmpty,
  Samoa: IconEmpty,
  SamoaAmericana: IconEmpty,
  SanBartolome: IconEmpty,
  SanCristobalyNieves: IconEmpty,
  SanMarino: IconEmpty,
  SanPedroyMiquelon: IconEmpty,
  SantaElena: IconEmpty,
  SantaLucia: IconEmpty,
  SantoTomeyPrincipe: IconEmpty,
  SanVicenteylasGranadinas: IconEmpty,
  Senegal: IconEmpty,
  SerbiayMontenegro: IconEmpty,
  Seychelles: IconEmpty,
  SierraLeona: IconEmpty,
  Singapur: IconEmpty,
  Siria: IconEmpty,
  Somalia: IconEmpty,
  SriLanka: IconEmpty,
  Suazilandia: IconEmpty,
  Sudafrica: IconEmpty,
  Sudan: IconEmpty,
  Suecia: IconEmpty,
  Suiza: IconEmpty,
  Surinam: IconEmpty,
  Tailandia: IconEmpty,
  Taiwan: IconEmpty,
  Tanzania: IconEmpty,
  Tayikistan: IconEmpty,
  TerritorioBritanicodelOceanoIndico: IconEmpty,
  TerritoriosAustralesFranceses: IconEmpty,
  TimorLeste: IconEmpty,
  Togo: IconEmpty,
  Tokelau: IconEmpty,
  Tonga: IconEmpty,
  TrinidadyTobago: IconEmpty,
  Tunez: IconEmpty,
  Turkmenistan: IconEmpty,
  Turquia: IconEmpty,
  Tuvalu: IconEmpty,
  Ucrania: IconEmpty,
  Uganda: IconEmpty,
  Uruguay: IconEmpty,
  Uzbekistan: IconEmpty,
  Vanuatu: IconEmpty,
  Venezuela: IconEmpty,
  Vietnam: IconEmpty,
  WallisyFutuna: IconEmpty,
  Yemen: IconEmpty,
  Yibuti: IconEmpty,
};

export { FLAG_ICON, TEXT_FLAG };
