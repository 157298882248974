import React, { FC } from "react";
import { mainConfigDataCardStyles as styles } from "../ConfigDataCard/ConfigDataCard.styles";
import { Box, Button, Grid, Typography } from "@mui/material";
import { ServiceConfigEnum } from "../../shared/enums/ServiceConfigEnum";
import { footerStyles } from "../FooterOptions/FooterOptions.styles";
import { CardContent } from "../ConfigDataCard/ConfigDataCard.interfaces";
import { IButtonOptions } from "../FooterOptions/FooterOptions.interfaces";
import { SecurityLabelsEnum } from "../../shared/enums/SecurityLabelsEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { isMassivePath } from "../../shared/utils/massiveConfigUtils";
import { InfoOutlined } from "@mui/icons-material";

export interface IConfigButtonSectionProps {
  cardContent: CardContent;
  configureButton: IButtonOptions;
  hideEditBtn: boolean;
  isCentralized?: boolean;
}

export const ConfigButtonSection: FC<IConfigButtonSectionProps> = ({
  cardContent,
  configureButton,
  hideEditBtn,
  isCentralized,
}: IConfigButtonSectionProps) => {
  const { content } = cardContent;
  const { onAction } = configureButton;
  const isEmptyContent: boolean = content.length === 0;

  const renderConfigureBtn = (showButton: boolean) =>
    showButton && (
      <ComponentSecurityWrapper componentId={SecurityLabelsEnum.SERVICE_CONFIG}>
        <Button
          data-testid="idButtonTest"
          variant="outlined"
          sx={footerStyles.buttonStyle}
          onClick={onAction!}
        >
          {ServiceConfigEnum.CONFIGURE}
        </Button>
      </ComponentSecurityWrapper>
    );
  const renderMessage = (message: string) => (
    <>
      <Box sx={styles.infoMessage}>
        <Box display={"flex"} alignItems={"center"}>
          <InfoOutlined />
          <Box ml={1}>
            <Typography sx={styles.typographyInfoMessage}>{message}</Typography>
          </Box>
        </Box>
      </Box>
      <div style={{ height: "24px" }}></div>
    </>
  );

  return (
    <>
      {!hideEditBtn && isEmptyContent && (
        <Grid container sx={styles.emptyContentContainer}>
          <Grid flexDirection={"column"} item xs={10}>
            <Typography color="text.primary" variant="body1">
              {isMassivePath()
                ? ServiceConfigEnum.EMPTY_SERVICE_CONFIG_BRANCH
                : ServiceConfigEnum.EMPTY_SERVICE_CONFIG}
            </Typography>
          </Grid>
          <Grid flexDirection={"column"} item xs={2}>
            {renderConfigureBtn(isEmptyContent)}
          </Grid>
        </Grid>
      )}
      {hideEditBtn &&
        renderMessage(ServiceConfigEnum.SERVICE_PAYOUTS_PROCESSOR)}
      {isCentralized &&
        !hideEditBtn &&
        renderMessage(ServiceConfigEnum.CENTRALIZED_INFO)}
    </>
  );
};

export default ConfigButtonSection;
