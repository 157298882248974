import { useFormContext } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";
import { get, isEmpty, isUndefined, keys } from "lodash";
import { DEFAULT_ITEM_BY_TAG } from "../../../../../shared/infrastructure/enums/ItemByTagEnum";
import { IGenericLabelConditionSectionProps } from "../../../../../shared/infrastructure/interfaces/IGenericLabelConditionSection";
import { IUseGenericLabelConditionSectionState } from "../../../../../shared/infrastructure/interfaces/IUseGenericLabelConditionSectionState";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../../../store/reducer";
import { setTextFieldComponentActions } from "../../../../../store/actionCreators";

export const useGenericLabelConditionSectionState = (
  props: IGenericLabelConditionSectionProps
): IUseGenericLabelConditionSectionState => {
  const { errors, setValue, register } = useFormContext();
  const [isValidFiledTextField, setIsValidFiledTextField] =
    useState<boolean>(false);
  const [isDuplicated, setIsDuplicated] = useState<boolean>(false);
  const [isInserted, setIsInserted] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [textFieldList, setTextFieldList] = useState<string[]>(
    get(props, "defaultListTextField", [])
  );
  const textFieldComponentActions = useSelector(
    (state: IAppState) => state.textFieldComponentActions
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const dataToRetrieve: ICategory[] =
      textFieldList &&
      textFieldList
        .filter((data: string) => !isEmpty(data))
        .map((data) => {
          return {
            label: DEFAULT_ITEM_BY_TAG[props.tag].label,
            value: data,
            selected: true,
          };
        });

    props.setSelectedItems(
      keys(errors).join("|").includes(props.tag) ? [] : dataToRetrieve
    );
  }, [textFieldList]);

  useEffect(() => {
    setInputValue("");
    setIsValidFiledTextField(false);
    if (props.isEditCondition || props.isSpecialCond || props.isFinalCond)
      setTextFieldList([]);
  }, [props.operator]);

  useEffect(() => {
    setTextFieldList(
      props.defaultListTextField.length > 0 ? props.defaultListTextField : []
    );
  }, [props.defaultListTextField]);

  useEffect(() => {
    setIsDuplicated(
      !isEmpty(inputValue) && textFieldList.includes(inputValue.trim())
    );
  }, [inputValue]);

  const handleAddTextField = (): void => {
    setIsInserted(true);
    setInputValue("");
    setValue(DEFAULT_ITEM_BY_TAG[props.tag].name, "");
    setTextFieldList((array) => [...array, inputValue]);
    setIsValidFiledTextField(false);
  };

  const handleEditTextField = (value: string, index: number): void => {
    const newFieldList: string[] = [...textFieldList];

    newFieldList[index] = value;
    setTextFieldList(newFieldList);
  };

  const handleOnChangeTextField = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    const fieldValue: string = get(event, "target.value", "");
    setInputValue(fieldValue);

    if (DEFAULT_ITEM_BY_TAG[props.tag].pattern.test(fieldValue)) {
      setIsValidFiledTextField(true);
    } else {
      setIsValidFiledTextField(false);
    }
  };

  const handleOnBlur = (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>
  ): void => {
    setInputValue(get(event, "target.value", ""));
  };

  useEffect(() => {
    if (!isUndefined(textFieldComponentActions)) {
      if (textFieldComponentActions.label === props.tag) {
        const newValues: string[] = textFieldComponentActions.value.split(",");

        setTextFieldList(newValues);

        dispatch(setTextFieldComponentActions(undefined));
      }
    }
  }, [textFieldComponentActions]);

  return {
    register,
    errors,
    isDuplicated,
    handleOnBlur,
    textFieldList,
    setTextFieldList,
    handleAddTextField,
    handleEditTextField,
    isValidFiledTextField,
    handleOnChangeTextField,
    isInserted,
  };
};
