import { IUseInfoIconsProcessorAccordionState } from "./IUseInfoIconsProcessorAccordionState.interfaces";
import { useAppSelector } from "../../../store/hooks/storeHook";
import { get, includes, isEmpty, isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { IInfoIconsProcessorAccordionProps } from "../IInfoIconsProcessorAccordionProps.interfaces";

export const useInfoIconsProcessorAccordionState = (
  props: IInfoIconsProcessorAccordionProps
): IUseInfoIconsProcessorAccordionState => {
  const {
    defaultProcessor,
    processor,
    defaultPayoutProcessor,
  } = useAppSelector((store) => store.layout);
  const [showTooltipFailover, setShowTooltipFailover] = useState<boolean>(
    false
  );

  useEffect(() => {
    if (!isEmpty(processor)) {
      const allAliases: string[] = processor.map(
        (processor: { alias: string }) => processor.alias
      );
      const failOverProcessor: string = get(
        props,
        "processor.failOverProcessor",
        ""
      );
      const isShowTooltipFailover: boolean =
        !isUndefined(failOverProcessor) &&
        includes(allAliases, failOverProcessor);

      setShowTooltipFailover(isShowTooltipFailover);
    }
  }, [processor]);

  const isDefaultProcessor = (
    processorName: string,
    publicProcessorId: string
  ): boolean => {
    if (defaultPayoutProcessor && publicProcessorId) {
      const isDefaultPayoutsTransfer: boolean =
        get(defaultPayoutProcessor, "defaultProcessor.payoutsTransfer", "") ===
        publicProcessorId;
      const isDefaultPayoutsCard: boolean =
        get(defaultPayoutProcessor, "defaultProcessor.payoutsCard", "") ===
        publicProcessorId;

      return isDefaultPayoutsTransfer || isDefaultPayoutsCard;
    }
    if (defaultProcessor) {
      const defaultProcessors: (string | undefined)[] = Object.values(
        defaultProcessor.defaultProcessor
      );
      return includes(defaultProcessors, processorName);
    }
    return false;
  };

  return {
    isDefaultProcessor,
    showTooltipFailover,
  };
};
