import { isEmpty, isUndefined, trim } from "lodash";
import {
  FocusEventHandler,
  KeyboardEventHandler,
  useEffect,
  useState,
} from "react";
import { ItemChip, ITextFieldChipProps } from "../TextFieldChip.interfaces";
import { IUseTextFieldChip } from "./useTextFieldChip.interfaces";
import { isOperatorEqualOrDifferent } from "../../../utils/utilsFile";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { LabelEnum } from "../../../shared/infrastructure/enums/LabelEnum";
import { setTextFieldComponentActions } from "../../../store/actionCreators";

const useTextFieldChip = ({
  items,
  isDisabled,
  onItemsChange,
  limit,
  operator,
  isEditCondition,
  isFinalCond,
  setBins,
}: ITextFieldChipProps): IUseTextFieldChip => {
  const textFieldComponentActions = useSelector(
    (state: IAppState) => state.textFieldComponentActions
  );
  const dispatch = useDispatch();
  const isOneValue: boolean =
    limit === 1 || isOperatorEqualOrDifferent(operator!);
  const [inputValue, setInputValue] = useState<string>("");
  const [itemState, setItemsState] = useState<ItemChip[]>([
    isOneValue ? items[0] : { value: "" },
  ]);

  const deleteItem = (index: number) => {
    const selectedItems = items.filter(
      (_option, indexFilter) => index !== indexFilter
    );

    onItemsChange(selectedItems);
  };

  const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = (e) => {
    // @ts-ignore
    const value = trim(e.target.value);

    if ((e.key === " " || (e.key === "Enter" && !isOneValue)) && value) {
      onItemsChange([...itemState, { value: value }]);
      setInputValue("");
    }
  };

  const handleSetInputValue = (newInputValue: string) => {
    if (!isNaN(Number(newInputValue)) && newInputValue.length <= 8)
      setInputValue(newInputValue);
  };

  const handleDelete = (index: number) => {
    if (!isDisabled) deleteItem(index);
  };

  const handleOnBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    const value = trim(e.target.value);

    e.target.value = value;
    if (isOneValue) {
      if (!isEmpty(value)) {
        onItemsChange([{ value: value }]);
      } else {
        deleteItem(0);
        setInputValue("");
      }
    } else {
      if (value) {
        onItemsChange([...itemState, { value: value }]);
        setInputValue("");
      }
      onItemsChange(
        itemState.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.value === item.value)
        )
      );
    }
  };

  const handleOnChipDoubleClick = (index: number, item: ItemChip) => {
    if (!isDisabled) {
      deleteItem(index);
      setInputValue(item.value);
    }
  };

  useEffect(() => {
    setItemsState(items);
    if (isOneValue && !isEmpty(items[0])) setInputValue(items[0].value);
  }, [items]);

  useEffect(() => {
    if (
      !isUndefined(textFieldComponentActions) &&
      textFieldComponentActions.label === LabelEnum.BIN
    ) {
      const newValues: string[] = textFieldComponentActions.value.split(",");
      const list: ItemChip[] = newValues.map((item) => {
        return {
          value: item,
        };
      });

      onItemsChange(list);
      setInputValue("");

      dispatch(setTextFieldComponentActions(undefined));
    }
  }, [textFieldComponentActions]);

  useEffect(() => {
    if (isEditCondition || isFinalCond) {
      setBins([]);
      setInputValue("");
      setItemsState([]);
    }
  }, [operator]);

  return {
    handleKeyPress,
    handleDelete,
    handleOnBlur,
    handleSetInputValue,
    handleOnChipDoubleClick,
    inputValue,
    isOneValue,
    itemState,
  };
};

export default useTextFieldChip;
