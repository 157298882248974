import { get } from "lodash";
import { ItemCategoryItem } from "../../components/Filters/ItemCategory/interfaces";
import { EntityNameEnum } from "../enums/EntityNameEnum";

export const getRole = (rol: string): boolean => {
  return get(JSON.parse(localStorage.getItem("roles")!), rol, false);
};

export const verifyRol = () => {
  if (getRole("BackofficeAdmin")) return false;

  return getRole("BackofficeUserMaster") || getRole("BackofficeMasterCustomer");
};

export const buildMerchantRequest = (
  merchantChips: ItemCategoryItem[],
  searchValue?: string
): {
  name?: string;
  merchantId?: string;
  entityName?: string;
} => {
  const entity_name_aux: string = merchantChips
    .filter((chip) => chip.selected)
    .map((chip) => chip.value)
    .join("|");

  return {
    entityName: entity_name_aux,
    name: searchValue,
  };
};

export const getEntityName = (value: string) => {
  if (value === EntityNameEnum.NA) return EntityNameEnum.MERCHANT;

  return value;
};
