import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const mainSubHeaderStyles = createNamedStyles({
  divider: {
    border: "1px solid  #98A8B8",
    marginTop: "2px",
    marginBottom: "4px",
  },
  flagPadding: {
    paddingTop: "2px",
  },
  boxPadding: {
    paddingLeft: "2px",
  },
  gridTitle: {
    paddingBottom: 1.5,
    width: "100%",
    display: "flex",
  },
  gridButtonBack: {
    paddingBottom: 1.5,
    paddingLeft: "1.45em",
    width: "100%",
    display: "flex",
  },
  gridText: {
    paddingRight: 2,
  },
  gridCountry: {
    paddingLeft: 1.7,
    paddingRight: 3,
  },
  link: {
    color: "#4498EE",
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  iconColor: {
    color: "#023365",
  },
  linkText: {
    marginLeft: "7px",
  },
  gridId: {
    paddingLeft: {
      md: 3,
      xs: 1,
    },
    paddingRight: 1.6,
  },
  branchesTitle: {
    width: "80%",
    display: "flex",
  },
  branchesButton: {
    width: "20%",
    justifyContent: "right",
    float: "right",
    display: "flex",
  },
  branchesTitleTypography: {
    color: "text.primary",
    fontSize: "20",
    variant: "h1",
    fontWeight: "700",
  },
});

export const buttonWithoutBorder = createNamedStyles({
  borderNone: {
    border: "none",
    ":hover": {
      border: "none",
      backgroundColor: "transparent",
    },
    "& .MuiButtonBase-root-MuiButton-root": {
      border: "none",
    },
  },
});
