import { ITableConfiguration } from "../../components/TableComponent/TableComponent.interfaces";
import { GetAllModulesResponse } from "../../../types/get_all_modules_response";
import Roles from "../../components/Roles/Roles";
import ChipStatus from "../../components/ChipStatus/ChipStatus";
import { get } from "lodash";
import { StatusEnum } from "../enums/statusEnum";
import React from "react";

export const HEADERS_COMPONENTS: ITableConfiguration<GetAllModulesResponse>[] =
  [
    {
      label: "Descripción",
      value: "description",
    },
    {
      label: "Id del componente",
      value: "id",
    },
    {
      customRender: (row) => <Roles row={row} />,
      label: "Rol",
      value: "roles",
    },
    {
      customRender: (row) => (
        <ChipStatus status={get(row, "status") as StatusEnum} />
      ),
      label: "Estado",
      value: "status",
    },
  ];
