import { IStyles } from "../../shared/interfaces/Styles.interfaces";

export const snackBarAlertStyles: IStyles = {
  alert: {
    alignItems: "center",
    color: "common.white",
    fontWeight: 400,
    borderRadius: "0px",
    "& .MuiSnackbar-root": {
      backgroundColor: "#E24763",
    },
  },

  position: {
    borderRadius: "0px",
    top: (theme) => theme.spacing(10) + "!important",
    whiteSpace: "pre-wrap",
  },
};
