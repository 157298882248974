import { get } from "lodash";
import { IDataWebsockets } from "../../../shared/interfaces/IDataWebSocket";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { ThunkAction } from "redux-thunk";
import { AppState } from "../../interfaces/app.interfaces";
import { buildNotification } from "../../../shared/constants/snackBar";
import { NotificationTypeEnum } from "../../../shared/constants/SnackbarEnum";
import { setUpdateMassiveStatus } from "../../actions/massiveBranch/massiveBranch.actions";
import { setNotification } from "../../actions/sharedActions/shared.actions";
import { Request } from "../../../../types/update_massive_branch_request";
import {
  setIsModifyByBranch,
  setLocalConfigResponse,
} from "../../../shared/utils/massiveBranchUtils";
import { Configs } from "../../../../types/node_info_response";
import { TabItemStatusEnum } from "../../../shared/enums/tabItemStatusEnum";
import { SocketEnum } from "../../../shared/enums/socket_enum";

export type IAppAction = {
  type: string;
};

export const updateMassiveBranch = (
  requestUpdateMassiveBranch: Request,
  configId: string,
  configResponse: Configs[]
): ThunkAction<void, AppState, undefined, IAppAction> => {
  return async (dispatch): Promise<void> => {
    const jwt: string | null = localStorage.getItem("jwt");
    let webSocket: WebSocket | undefined;
    const params: object = {
      action: "updateMassiveBranch",
      data: requestUpdateMassiveBranch,
    };

    try {
      webSocket = new WebSocket(
        `${API_ROUTES.WS_MASSIVE}?Authorization=${jwt}`
      );
      webSocket.onopen = () => webSocket?.send(JSON.stringify(params));

      webSocket.onerror = () => webSocket?.close();

      webSocket.onmessage = (event: MessageEvent) => {
        dispatch(setUpdateMassiveStatus(true));
        const response: IDataWebsockets = JSON.parse(event.data);
        const status: string | undefined = get(response, "status");

        const hasDataError = get(response, "data", []).some(
          (branchUpdateInfo) => get(branchUpdateInfo, "error", false)
        );
        const branchesForUpdate = get(
          requestUpdateMassiveBranch,
          "branches",
          []
        ).map((branch) => branch.merchantId);

        const statusTab = requestUpdateMassiveBranch.status;

        if (status === SocketEnum.COMPLETE) {
          dispatch(setUpdateMassiveStatus(false));
          setLocalConfigResponse(
            dispatch,
            configId,
            configResponse,
            statusTab as TabItemStatusEnum,
            hasDataError
          );

          webSocket?.close();
        }
        if (status === SocketEnum.ERROR) {
          dispatch(
            setNotification(
              buildNotification(NotificationTypeEnum.CUSTOM, {
                color: "danger",
                message: get(response, "data.newError.message"),
                variant: "simple",
                withIcon: false,
              })
            )
          );
          dispatch(setUpdateMassiveStatus(false));
          webSocket?.close();
        }
        setIsModifyByBranch(branchesForUpdate);
      };
    } catch (e) {
      if (webSocket) webSocket.close();
    }
  };
};
