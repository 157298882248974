import React, { Dispatch, SetStateAction } from "react";
import {
  CardList,
  PaginationSimple,
  TextFieldSearchBy,
} from "@kushki/connect-ui";
import { useListCatalogGeneralRulesComponentState } from "./state/useListCatalogGeneralRulesComponentState";
import { Box, Grid, Typography } from "@mui/material";
import { RuleGeneralRequest } from "../../../types/rule_general_request";
import { TextFieldSearchByEnum } from "../../shared/infrastructure/enums/TextFieldSearchByEnum";

export interface IListCatalogGeneralRulesComponent {
  setSelectedRule: Dispatch<SetStateAction<RuleGeneralRequest | undefined>>;
}

export const ListCatalogGeneralRulesComponent: React.FC<
  IListCatalogGeneralRulesComponent
> = (props: IListCatalogGeneralRulesComponent) => {
  const {
    itemsCatalogGeneralRule,
    totalItems,
    isLoading,
    actions,
    getRuleItem,
    ruleCatalogList,
  } = useListCatalogGeneralRulesComponentState(props);
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextFieldSearchBy
            placeholder={TextFieldSearchByEnum.SEARCH_BY_NAME}
            onChange={(selected) => actions.handleSearch(selected)}
            onItemSelected={(selected) => {
              actions.handleFilterRule(selected);
            }}
            items={ruleCatalogList}
          />
        </Grid>
        {itemsCatalogGeneralRule && itemsCatalogGeneralRule.length > 0 ? (
          itemsCatalogGeneralRule.map(
            (item: RuleGeneralRequest, index: number) => (
              <Grid
                key={index}
                item
                xs={12}
                onClick={() => props.setSelectedRule(item)}
              >
                <Box
                  marginBottom={"8px"}
                  paddingBottom={"24px"}
                  borderRadius={"16px"}
                  sx={{
                    "&:hover": {
                      border: "1px solid #023365",
                    },
                  }}
                >
                  <CardList key={index} {...getRuleItem(item)} />
                </Box>
              </Grid>
            )
          )
        ) : (
          <Grid item xs={12}>
            <Typography variant={"h6"}>No existen reglas generales</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <PaginationSimple
            totalItems={totalItems}
            itemsPerPage={5}
            isDisabled={false}
            onPageClick={actions.handleSelectedPage}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
