import React from "react";
import { IUseDropDown } from "../DropDown.interfaces";
import { ROLES } from "../../../shared/constants/RoleNames";
import { defaultTo } from "lodash";

export const useDropDown = (): IUseDropDown => {
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchor);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setAnchor(null);
  };

  function getRolName(rol: string): string {
    const rol_found: { id: string; value: string } | undefined = ROLES.find(
      (rolLoop: { id: string; value: string }) =>
        rolLoop.id.toLowerCase() === defaultTo(rol, "").toLowerCase()
    );

    if (rol_found) return rol_found.value;

    return "";
  }

  return { anchor, getRolName, handleClick, handleClose, open };
};
