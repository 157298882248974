import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { ContentModalBranch } from "../../components/ContentModalBranch/ContentModalBranch";

export const BusinessRulesIndex: React.FC = () => {
  return (
    <Container fixed>
      <Box position={"relative"}>
        <Grid container>
          <Grid container item xs={12} direction={"column"}>
            <Grid item>
              <Typography variant={"h1"} color={"primary"}>
                Business Rules
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} sx={{ height: 900 }}>
            <ContentModalBranch />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
