import { useEffect, useState } from "react";
import { CountryEnum } from "../../enums/CountryEnum";
import {
  IUseCalculateAmount,
  IUseCalculateAmountProps,
} from "./useCalculateAmount.interfaces";
import {
  formatAmount,
  getCountryMerchantFromLocalStorage,
  roundAmount,
} from "../../utils/utils";
import { defaultTo, get, isEmpty, isNil } from "lodash";
import { CurrencyMapByCountry } from "../../enums/CurrencyEnum";
import { REGEX_COMMONS } from "../../constants/regex";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { getIvaValues } from "../../../store/epics/getIvaValues/getIvaValues.epic";
import { selectIvaValues } from "../../../store/selectors/selectors";

export const useCalculateAmount = ({
  setHideLoadingLabels,
  setIsLoading,
}: IUseCalculateAmountProps): IUseCalculateAmount => {
  const dispatch = useAppDispatch();

  const [country, setCountry] = useState(CountryEnum.ECUADOR);
  const [ivaPercentage, setIvaPercentage] = useState(0);
  const [currency, setCurrency] = useState("");

  const calculateIvaAmount = (subtotalIva: number): string => {
    let newSubtotalIva = subtotalIva;

    if (!REGEX_COMMONS.NUMBER.test(subtotalIva.toString())) newSubtotalIva = 0;

    const iva = (newSubtotalIva * ivaPercentage) / 100;

    return roundAmount(iva, currency);
  };

  const calculateTotalAmount = (subtotalIva: number, subtotalIva0: number) => {
    let newSubtotalIva = REGEX_COMMONS.NUMBER.test(subtotalIva.toString())
      ? subtotalIva
      : 0;
    let newSubtotalIva0 = REGEX_COMMONS.NUMBER.test(subtotalIva0.toString())
      ? subtotalIva0
      : 0;

    const iva = Number(calculateIvaAmount(newSubtotalIva));

    return roundAmount(iva + newSubtotalIva0 + newSubtotalIva, currency);
  };

  const getIvaAmountFormat = (subtotalIva: number): string => {
    const iva = Number(calculateIvaAmount(subtotalIva));

    return formatAmount(iva, currency);
  };

  const getTotalAmountFormat = (subtotalIva: number, subtotalIva0: number) => {
    const total = Number(calculateTotalAmount(subtotalIva, subtotalIva0));

    return formatAmount(total, currency);
  };
  const paymenIvaValues = useAppSelector(selectIvaValues);

  useEffect(() => {
    const countryMerchant = getCountryMerchantFromLocalStorage();

    setIvaPercentage(
      defaultTo(get(paymenIvaValues, countryMerchant + ".value"), 0) * 100
    );
  }, [paymenIvaValues]);

  useEffect(() => {
    if (isEmpty(paymenIvaValues)) dispatch(getIvaValues());
  }, []);

  useEffect(() => {
    const updateStates = (
      newCountry: string | null,
      fnClearInterval: () => void
    ) => {
      if (!isNil(newCountry)) {
        setCountry(newCountry as CountryEnum);
        setIvaPercentage(
          defaultTo(get(paymenIvaValues, newCountry + ".value"), 0) * 100
        );
        setCurrency(
          defaultTo(
            CurrencyMapByCountry[newCountry],
            CurrencyMapByCountry[CountryEnum.ECUADOR]
          )
        );
        setIsLoading(false);
        setHideLoadingLabels(false);
        fnClearInterval();
      } else {
        setIsLoading(true);
        setHideLoadingLabels(true);
      }
    };
    const countryMerchant = getCountryMerchantFromLocalStorage();

    updateStates(countryMerchant, () => {});

    const intervalSearchCountryMerchant = setInterval(() => {
      const countryMerchant = getCountryMerchantFromLocalStorage();

      updateStates(countryMerchant, () => {
        clearInterval(intervalSearchCountryMerchant);
      });
    }, 100);
  }, []);

  return {
    calculateIvaAmount,
    calculateTotalAmount,
    getIvaAmountFormat,
    getTotalAmountFormat,
    iva: ivaPercentage,
    merchantInfo: {
      country,
      currency,
    },
  };
};
