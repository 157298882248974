/* istanbul ignore file */

import { IOptionSelection } from "./RuleRequestManagerConstants";

export const BANKS_BRAZIL: IOptionSelection[] = [
  { value: "00000000", label: "BANCO DO BRASIL S.A." },
  { value: "00000208", label: "BRB - BANCO DE BRASILIA S.A." },
  { value: "00360305", label: "CAIXA ECONOMICA FEDERAL" },
  { value: "00416968", label: "BANCO INTER S.A." },
  { value: "00517645", label: "BANCO RIBEIRAO PRETO S.A." },
  {
    value: "00556603",
    label: "BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A.",
  },
  { value: "00558456", label: "BANCO CETELEM S.A." },
  { value: "00795423", label: "BANCO SEMEAR S.A." },
  { value: "00997185", label: "BANCO B3 S.A." },
  { value: "01023570", label: "BANCO RABOBANK INTERNATIONAL BRASIL S.A." },
  { value: "01181521", label: "BANCO COOPERATIVO SICREDI S.A." },
  { value: "01522368", label: "BANCO BNP PARIBAS BRASIL S.A." },
  { value: "01701201", label: "KIRTON BANK S.A. - BANCO MÚLTIPLO" },
  { value: "02038232", label: "BANCO COOPERATIVO DO BRASIL S.A. - BANCOOB" },
  { value: "02318507", label: "BANCO KEB HANA DO BRASIL S.A." },
  { value: "02658435", label: "BANCO CATERPILLAR S.A." },
  { value: "02801938", label: "BANCO MORGAN STANLEY S.A." },
  { value: "02992446", label: "BANCO CNH INDUSTRIAL CAPITAL S.A." },
  { value: "03012230", label: "HIPERCARD BANCO MÚLTIPLO S.A." },
  { value: "03017677", label: "BANCO J. SAFRA S.A." },
  { value: "03215790", label: "BANCO TOYOTA DO BRASIL S.A." },
  { value: "03323840", label: "BANCO ALFA S.A." },
  { value: "03502961", label: "BANCO PSA FINANCE BRASIL S.A." },
  { value: "03532415", label: "BANCO ABN AMRO S.A." },
  { value: "03609817", label: "BANCO CARGILL S.A." },
  { value: "03634220", label: "BANCO HONDA S.A." },
  { value: "04184779", label: "BANCO BRADESCARD S.A." },
  { value: "04332281", label: "GOLDMAN SACHS DO BRASIL BANCO MULTIPLO S.A." },
  { value: "04866275", label: "BANCO INBURSA S.A." },
  { value: "04902979", label: "BANCO DA AMAZONIA S.A." },
  { value: "04913711", label: "BANCO DO ESTADO DO PARÁ S.A." },
  { value: "05040481", label: "BANCO DE LAGE LANDEN BRASIL S.A." },
  { value: "06271464", label: "BANCO BRADESCO BBI S.A." },
  { value: "07207996", label: "BANCO BRADESCO FINANCIAMENTOS S.A." },
  { value: "07237373", label: "BANCO DO NORDESTE DO BRASIL S.A." },
  { value: "07441209", label: "BANCO MONEO S.A." },
  {
    value: "07450604",
    label: "CHINA CONSTRUCTION BANK (BRASIL) BANCO MÚLTIPLO S/A",
  },
  { value: "07656500", label: "BANCO KDB DO BRASIL S.A." },
  { value: "07679404", label: "BANCO TOPÁZIO S.A." },
  { value: "08357240", label: "BANCO CSF S.A." },
  { value: "08609934", label: "MONEYCORP BANCO DE CÂMBIO S.A." },
  { value: "09274232", label: "STATE STREET BRASIL S.A. – BANCO COMERCIAL" },
  { value: "09516419", label: "BANCO ORIGINAL DO AGRONEGÓCIO S.A." },
  { value: "09526594", label: "BANCO VIPAL S.A." },
  { value: "10264663", label: "BANCOSEGURO S.A." },
  { value: "10371492", label: "BANCO YAMAHA MOTOR DO BRASIL S.A." },
  { value: "10664513", label: "BANCO AGIBANK S.A." },
  { value: "10690848", label: "BANCO DA CHINA BRASIL S.A." },
  { value: "10866788", label: "BANCO BANDEPE S.A." },
  { value: "11417016", label: "SCANIA BANCO S.A." },
  { value: "11476673", label: "BANCO RANDON S.A." },
  { value: "11703662", label: "TRAVELEX BANCO DE CÂMBIO S.A." },
  { value: "11758741", label: "BANCO FINAXIS S.A." },
  { value: "13009717", label: "BANCO DO ESTADO DE SERGIPE S.A." },
  { value: "13059145", label: "BEXS BANCO DE CÂMBIO S/A" },
  { value: "13720915", label: "BANCO WESTERN UNION DO BRASIL S.A." },
  { value: "14388334", label: "PARANÁ BANCO S.A." },
  { value: "15114366", label: "BANCO BOCOM BBM S.A." },
  { value: "15173776", label: "BANCO CAPITAL S.A." },
  { value: "15357060", label: "BANCO WOORI BANK DO BRASIL S.A." },
  { value: "17184037", label: "BANCO MERCANTIL DO BRASIL S.A." },
  { value: "17192451", label: "BANCO ITAUCARD S.A." },
  { value: "17298092", label: "BANCO ITAÚ BBA S.A." },
  { value: "17351180", label: "BANCO TRIANGULO S.A." },
  { value: "17453575", label: "ICBC DO BRASIL BANCO MÚLTIPLO S.A." },
  { value: "19307785", label: "MS BANK S.A. BANCO DE CÂMBIO" },
  { value: "23522214", label: "COMMERZBANK BRASIL S.A. - BANCO MÚLTIPLO" },
  { value: "23903068", label: "BANCO KOMATSU DO BRASIL S.A." },
  { value: "27098060", label: "BANCO DIGIO S.A." },
  {
    value: "28127603",
    label: "BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO",
  },
  { value: "28195667", label: "BANCO ABC BRASIL S.A." },
  { value: "28517628", label: "BANCO PACCAR S.A." },
  { value: "28811341", label: "INTL FCSTONE BANCO DE CÂMBIO S.A." },
  { value: "29030467", label: "SCOTIABANK BRASIL S.A. BANCO MÚLTIPLO" },
  { value: "30172491", label: "BANCO HYUNDAI CAPITAL BRASIL S.A." },
  { value: "30306294", label: "BANCO BTG PACTUAL S.A." },
  { value: "30723886", label: "BANCO MODAL S.A." },
  { value: "31597552", label: "BANCO CLASSICO S.A." },
  { value: "31872495", label: "BANCO C6 S.A." },
  { value: "31880826", label: "BANCO GUANABARA S.A." },
  { value: "31895683", label: "BANCO INDUSTRIAL DO BRASIL S.A." },
  { value: "32062580", label: "BANCO CREDIT SUISSE (BRASIL) S.A." },
  { value: "33042151", label: "BANCO DE LA NACION ARGENTINA" },
  { value: "33042953", label: "CITIBANK N.A." },
  { value: "33132044", label: "BANCO CEDULA S.A." },
  { value: "33147315", label: "BANCO BRADESCO BERJ S.A." },
  { value: "33172537", label: "BANCO J.P. MORGAN S.A." },
  { value: "33254319", label: "BANCO LOSANGO S.A. - BANCO MÚLTIPLO" },
  { value: "33264668", label: "BANCO XP S.A." },
  { value: "33466988", label: "BANCO CAIXA GERAL - BRASIL S.A." },
  { value: "33479023", label: "BANCO CITIBANK S.A." },
  { value: "33603457", label: "BANCO RODOBENS S.A." },
  { value: "33644196", label: "BANCO FATOR S.A." },
  { value: "33885724", label: "BANCO ITAÚ CONSIGNADO S.A." },
  { value: "33923798", label: "BANCO MÁXIMA S.A." },
  { value: "34270520", label: "BANCO IBM S.A." },
  { value: "42272526", label: "BNY MELLON BANCO S.A." },
  { value: "43818780", label: "DAYCOVAL LEASING - BANCO MÚLTIPLO S.A." },
  { value: "44189447", label: "BANCO DE LA PROVINCIA DE BUENOS AIRES" },
  { value: "45246410", label: "PLURAL S.A. BANCO MÚLTIPLO" },
  { value: "46518205", label: "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION" },
  { value: "48795256", label: "BANCO ANDBANK (BRASIL) S.A." },
  { value: "49336860", label: "ING BANK N.V." },
  { value: "49925225", label: "BANCO ITAULEASING S.A." },
  { value: "50585090", label: "BCV - BANCO DE CRÉDITO E VAREJO S.A." },
  { value: "51938876", label: "BANCO DE LA REPUBLICA ORIENTAL DEL URUGUAY" },
  { value: "54403563", label: "BANCO ARBI S.A." },
  { value: "55230916", label: "INTESA SANPAOLO BRASIL S.A. - BANCO MÚLTIPLO" },
  { value: "57839805", label: "BANCO TRICURY S.A." },
  { value: "58017179", label: "BANCO VOLVO BRASIL S.A." },
  { value: "58160789", label: "BANCO SAFRA S.A." },
  { value: "58497702", label: "BANCO SMARTBANK S.A." },
  { value: "58616418", label: "BANCO FIBRA S.A." },
  { value: "59109165", label: "BANCO VOLKSWAGEN S.A." },
  { value: "59118133", label: "BANCO LUSO BRASILEIRO S.A." },
  { value: "59274605", label: "BANCO GMAC S.A." },
  { value: "59285411", label: "BANCO PAN S.A." },
  { value: "59588111", label: "BANCO VOTORANTIM S.A." },
  { value: "60394079", label: "BANCO ITAUBANK S.A." },
  { value: "60498557", label: "BANCO MUFG BRASIL S.A." },
  { value: "60518222", label: "BANCO SUMITOMO MITSUI BRASILEIRO S.A." },
  { value: "60701190", label: "ITAÚ UNIBANCO S.A." },
  { value: "60746948", label: "BANCO BRADESCO S.A." },
  { value: "60814191", label: "BANCO MERCEDES-BENZ DO BRASIL S.A." },
  { value: "60850229", label: "OMNI BANCO S.A." },
  { value: "60872504", label: "ITAÚ UNIBANCO HOLDING S.A." },
  { value: "60889128", label: "BANCO SOFISA S.A." },
  { value: "61024352", label: "BANCO INDUSVAL S.A." },
  { value: "61033106", label: "BANCO CREFISA S.A." },
  { value: "61088183", label: "BANCO MIZUHO DO BRASIL S.A." },
  { value: "61182408", label: "BANCO INVESTCRED UNIBANCO S.A." },
  { value: "61186680", label: "BANCO BMG S.A." },
  { value: "61190658", label: "BANCO ITAÚ VEÍCULOS S.A." },
  { value: "61348538", label: "BANCO FICSA S.A." },
  { value: "61533584", label: "BANCO SOCIETE GENERALE BRASIL S.A." },
  { value: "61820817", label: "BANCO PAULISTA S.A." },
  {
    value: "62073200",
    label: "BANK OF AMERICA MERRILL LYNCH BANCO MÚLTIPLO S.A.",
  },
  { value: "62144175", label: "BANCO PINE S.A." },
  { value: "62232889", label: "BANCO DAYCOVAL S.A." },
  { value: "62237425", label: "BANCO FIDIS S/A" },
  { value: "62307848", label: "BANCO RCI BRASIL S.A." },
  { value: "62331228", label: "DEUTSCHE BANK S.A. - BANCO ALEMAO" },
  { value: "62421979", label: "BANCO CIFRA S.A." },
  { value: "68900810", label: "BANCO RENDIMENTO S.A." },
  { value: "71027866", label: "BANCO BS2 S.A." },
  { value: "71371686", label: "BANCO OLÉ BONSUCESSO CONSIGNADO S.A." },
  { value: "74828799", label: "NOVO BANCO CONTINENTAL S.A. - BANCO MÚLTIPLO" },
  { value: "75647891", label: "BANCO CRÉDIT AGRICOLE BRASIL S.A." },
  { value: "76543115", label: "BANCO SISTEMA S.A." },
  { value: "78626983", label: "BANCO VR S.A." },
  { value: "78632767", label: "BANCO OURINVEST S.A." },
  { value: "80271455", label: "BANCO MAXINVEST S.A." },
  { value: "90400888", label: "BANCO SANTANDER (BRASIL) S.A." },
  { value: "90731688", label: "BANCO FORD S.A." },
  { value: "91884981", label: "BANCO JOHN DEERE S.A." },
  { value: "92702067", label: "BANCO DO ESTADO DO RIO GRANDE DO SUL S.A." },
  { value: "92874270", label: "BANCO A.J. RENNER S.A." },
  { value: "92894922", label: "BANCO ORIGINAL S.A." },
];
