import React, { FC } from "react";
import { Box } from "@mui/material";
import { IconCircleFill, IconFileText, TimeLine } from "@kushki/connect-ui";
import { ITimelineTab } from "@components/organism/DetailCardBodyAlarms/state/IUseDetailCardBodyAlarms.interfaces";
import {
  iconStyles,
  timelineTabStyles as styles,
} from "@components/molecule/TimelineTab/TimelineTab.styles";
import { TimelineSectionLabel } from "@shared/enum/AlarmDetailCardEnum";
import { defaultTo } from "lodash";
import { VerifyIfComponentEnable } from "@kushki/security-wrapper";
import { SecurityWrapperRoles } from "@shared/enum/SecurityWrapperRoles";
const TimelineTab: FC<ITimelineTab> = ({
  timelineItems,
  handleRedirectComment,
  handleButtonLink,
}: ITimelineTab) => {
  return (
    <Box sx={styles.container}>
      <TimeLine
        iconCompleted={
          <IconCircleFill color="disabled" fontSize="large" sx={iconStyles} />
        }
        iconIncomplete={<IconCircleFill fontSize="large" sx={iconStyles} />}
        handlerSearchTrxParent={(ticketNumber) =>
          handleRedirectComment(defaultTo(ticketNumber, ""))
        }
        stylesDate={{
          align: "left",
          padding: "0px",
          width: "30%",
        }}
        stylesIconIncomplete={{
          background: "#b5b9be !important",
          borderRadius: "50%",
          fill: "#b5b9be",
          padding: "0px",
        }}
        stylesSeparator={{
          background: "#b5b9be",
        }}
        textButton={TimelineSectionLabel.VIEW_COMMENT}
        timeZoneRegion="America/Guayaquil"
        timelineTrxData={timelineItems}
        buttonLink={{
          buttonProps: {
            disabled: !VerifyIfComponentEnable(
              SecurityWrapperRoles.M_COMPLIANCE_ALARMS_HISTORY_ATTACHED_FILES
            ),
            startIcon: <IconFileText />,
            sx: styles.btnFiles,
          },
          handleButton: handleButtonLink,
          text: "Ver archivos adjuntos",
        }}
      />
    </Box>
  );
};

export default TimelineTab;
