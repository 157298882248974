import { IChoiceButtonStyles } from "./ChoiceButton.interfaces";

export const choiceButtonStyles: IChoiceButtonStyles = {
  formControlLabel: {
    height: "208px",
    width: "222px",
  },
  formControlLabelSmall: {
    height: "122px",
    width: "124px",
  },
  mainBox: {
    paddingRight: 4,
    paddingLeft: 4,
  },
  mainBoxSmall: {},
  stack: {
    alignItems: "center",
    height: "80%",
    justifyContent: "space-around",
  },
};
