import React from "react";
import {
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
import { navigateToUrl } from "single-spa";
import "./CardActivateAccount.css";

const CardActivateAccount = () => {
  const handleClick = (url: string) => {
    return navigateToUrl(url);
  };

  return (
    <React.Fragment>
      <Card className="card">
        <CardContent>
          <Typography className="tittle">Activa tu cuenta</Typography>
          <Typography className="detail">
            Comienza la activación de tu cuenta y empieza a generar
            transacciones reales.
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            className="active-button"
            variant="outlined"
            size={"small"}
            fullWidth
            onClick={() => handleClick("/global-config/activate-account")}
          >
            Activar
          </Button>
        </CardActions>
      </Card>
    </React.Fragment>
  );
};

export default CardActivateAccount;
