import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppState } from "../../interfaces/AppState.interfaces";
import { WebsocketNotifyEnum } from "../../../shared/enums/WebsocketNotifyEnum";
import { QueryMetricsResponse } from "../../../../types/query_channel_transaction_metrics_response";

export const appInitialState: IAppState = {};

export const appSlice = createSlice({
  initialState: appInitialState,
  name: "app",
  reducers: {
    setQueryMetricsResponse: (
      state,
      action: PayloadAction<QueryMetricsResponse | undefined>
    ) => {
      state.queryMetricsResponse = action.payload;
    },
    setQueryMetricsStatus: (
      state,
      action: PayloadAction<WebsocketNotifyEnum>
    ) => {
      state.queryMetricsStatus = action.payload;
    },
  },
});

export default appSlice.reducer;
