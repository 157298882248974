import {
  useAppDispatch,
  useAppSelector,
} from "../../../../store/hooks/storeHook";
import { useEffect, useState } from "react";
import { defaultTo } from "lodash";
import { TypeRequestEnum } from "../../../../shared/enums/typeRequestEnum";
import {
  HANDLE_MASSIVE_MODAL_LOADER,
  SET_FOOTER_BUTTON_CLICKED,
  SET_TYPE_REQUEST,
} from "../../../../store/reducers/app/app.slice";
import { IUseMassiveModalDialog } from "./useMassiveModalDialog.interfaces";
import {
  createNodePaths,
  projectPaths,
} from "../../../../shared/enums/projects_path_enum";

export const useMassiveModalDialog = (): IUseMassiveModalDialog => {
  const dispatch = useAppDispatch();
  const { openMassiveModal, typeRequest: footerButtonType } = useAppSelector(
    (state) => ({
      ...state.app,
    })
  );
  const [footerBtnType, setFooterBtnType] = useState("");

  const handleAcceptMassiveModal = (typeRequest: TypeRequestEnum) => {
    if (typeRequest === TypeRequestEnum.FINISHED) {
      dispatch(HANDLE_MASSIVE_MODAL_LOADER(false));
      dispatch(SET_FOOTER_BUTTON_CLICKED(true));

      return;
    }

    dispatch(SET_FOOTER_BUTTON_CLICKED(false));
    window.location.href = projectPaths.CREATE_NODE.concat(
      createNodePaths.BRANCH_SELECTION
    );
  };

  const handleCloseModal = (isOpen: boolean) => {
    dispatch(HANDLE_MASSIVE_MODAL_LOADER(isOpen));
    dispatch(SET_TYPE_REQUEST(""));
  };

  useEffect(() => {
    if (defaultTo(footerButtonType, "") === TypeRequestEnum.FINISHED) {
      setFooterBtnType(TypeRequestEnum.FINISHED);
    } else {
      setFooterBtnType("");
    }
  }, [footerButtonType]);

  return {
    footerBtnType,
    handleAcceptMassiveModal,
    handleCloseModal,
    openMassiveModal,
  };
};
