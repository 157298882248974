import { Box } from "@mui/material";
import React from "react";
import { B2CRefundTransaction } from "../../../../../../types/b2c_refund_transaction";
import RawDataViewer from "../../../../common/RawDataViewer/RawDataViewer";
import { styles } from "./MetadataTab.styles";

interface IMetadataTabProps {
  transaction: B2CRefundTransaction;
}

const MetadataTab = ({ transaction }: IMetadataTabProps) => {
  return (
    <Box sx={styles.container}>
      <RawDataViewer data={transaction.metadata} />
    </Box>
  );
};

export default MetadataTab;
