import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const legalDetailsBodyStyles = createNamedStyles({
  container: {
    mb: "17vh",
  },
  bodyContainer: {
    borderRadius: 3,
    boxShadow: "0px 0px 10px 5px #6aa7c529",
    margin: 5,
    padding: 5,
  },
  bodyContainerEdit: {
    borderRadius: 3,
    boxShadow: "0px 0px 10px 5px #6aa7c529",
    marginTop: 10,
    marginRight: 23,
    marginLeft: 23,
    marginBottom: 20,
    padding: 10,
  },
  bodyContent: {
    padding: "0px !important",
    display: "flex",
    flexDirection: "column",
    gap: "0.8rem",
  },
  boxContent2: {
    bgcolor: "#FFFFFF",
    width: "100%",
    padding: "48px 64px 48px 64px",
  },
  card: {
    borderRadius: "24px",
    boxShadow: "0px 0px 10px 5px #6aa7c529",
  },
});
