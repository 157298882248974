import React, { FC } from "react";
import { useMerchantDeferredState } from "./state/useMerchantDeferredState";
import { TableDeferred } from "./Table/TableDeferred";
import { BankModal } from "../commons/BankModal/BankModal";
import { CountriesEnum } from "../../shared/infrastructure/constants/CountriesEnum";
import { DEFERRED_TITLE } from "../../shared/infrastructure/constants/DeferredTableConstants";
import DeferredStatusModal from "../DeferredStatusModal/DeferredStatusModal";
import { DeferredOption } from "../../../types/deferred_options";

export interface IDeferredSection {
  merchantDeferred?: DeferredOption[];
  isDeferredLoaded: boolean;
}

export const DeferredSection: FC<IDeferredSection> = (
  props: IDeferredSection
) => {
  const state = useMerchantDeferredState(
    props.merchantDeferred,
    props.isDeferredLoaded
  );

  return (
    <>
      <TableDeferred
        country={state.country}
        dataRows={state.tableMerchantDeferredData}
        dataHeaders={state.dataHeadersTable}
        getCodeToTextDeferred={state.getCodeToTextDeferred}
        handleBankModal={state.handleBankModal}
        title={DEFERRED_TITLE.get(state.country as CountriesEnum)!}
        handleUpdateStatusModal={state.handleOpenUpdateStatusModal}
        changeDialogText={state.changeDialogText}
      />
      <BankModal
        open={state.bankModalIsOpen}
        banks={state.banks}
        handleCloseDialog={state.handleBankModal}
      />
      <DeferredStatusModal
        dialogTexts={state.dialogTexts}
        openDialog={state.updateStatusModalIsOpen}
        handleConfirmChangeStatusDeferred={state.updateDeferredMerchantState}
        handleCloseDialog={state.handleCloseStatusModal}
      />
    </>
  );
};
