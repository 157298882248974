import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const loadingSpinnerStyles = createNamedStyles({
  backSpinner: {
    color: "#BAC6E4",
    left: "50",
    position: "fixed",
  },
  containerAnimation: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    left: "50%",
    position: "fixed",
    top: "50%",
    transform: "translate(-50%,-50%)",
  },
  containerBox: {
    backdropFilter: "blur(5px)",
    background: "rgba(255, 255, 255, 0.5)",
    height: "100%",
    left: "50%",
    position: "fixed",
    top: "50%",
    transform: "translate(-50%,-50%)",
    width: "100%",
    zIndex: 1400,
  },
  frontSpinner: {
    color: "#4767B8",
  },

  messageBox: {
    ".second-line": {
      paddingTop: "5px",
    },
    paddingTop: "20px",
    textAlign: "center",
  },
});
