import { Box, createStyles, IconButton, makeStyles } from "@material-ui/core";
import React from "react";
import SelectableColumns from "../SelectableColumns/SelectableColumns";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import { useColumnsConfigState } from "./state/useColumnsConfigState";

const useStyles = makeStyles(() =>
  createStyles({
    iconButton: {
      "& .MuiIconButton-root": {
        height: "44px",
        width: "44px",
        borderStyle: "solid",
        borderColor: "#C4C4C4",
        borderWidth: 1,
        borderRadius: 4,
      },
    },
  })
);

export interface ColumnsConfigProps {
  origin: string;
  isHistoric?: boolean;
  handleDownloadFile?: () => void;
  loadingFileDownload?: boolean;
}

export const ColumnsConfig: React.FC<ColumnsConfigProps> = (
  props: ColumnsConfigProps
) => {
  const {
    anchorElement,
    handleClickColumns,
    handleChangeColumnCheck,
    handleCloseColumns,
    handleRestoreColumns,
    selectedColumns,
    maxSelection,
    selectableColumns,
    handleDisplayedColumn,
  } = useColumnsConfigState(props);

  const openColumnsSelector = Boolean(anchorElement);
  const idButtonColumnsSelector = openColumnsSelector
    ? "filter-popover"
    : undefined;

  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.iconButton}>
        <IconButton
          aria-label="settings-columns"
          color="primary"
          onClick={handleClickColumns}
        >
          <SettingsOutlinedIcon />
        </IconButton>
        <SelectableColumns
          openColumnsSelector={openColumnsSelector}
          idButtonColumnsSelector={idButtonColumnsSelector}
          anchorElement={anchorElement}
          handleCloseColumns={handleCloseColumns}
          selectedColumns={selectedColumns}
          handleChangeColumnCheck={handleChangeColumnCheck}
          handleRestoreColumns={handleRestoreColumns}
          selectableColumns={selectableColumns}
          maxSelection={maxSelection}
          handleDisplayedColumn={handleDisplayedColumn}
          origin={props.origin}
        />
      </Box>
    </Box>
  );
};
