import React, { FC } from "react";
import { Box } from "@mui/material";
import { layoutStyles as styles } from "./Layout.styles";
import { BoxProps } from "@mui/material";

const Layout: FC<BoxProps> = ({ sx, children }: BoxProps) => {
  const containerLayout = { ...styles.container, ...sx };

  return <Box sx={containerLayout}>{children}</Box>;
};

export default Layout;
