import { branchEditContainerStyles as styles } from "../BranchEditContainer/BranchEditContainer.styles";
import FooterOptions from "../../components/FooterOptions/FooterOptions";
import { INFORMATION_CUSTOMER_LABELS } from "../../shared/constants/labels/information_customer_container_labels";
import { Box } from "@mui/material";
import React from "react";
import SelectSection from "../../components/SelectSection/SelectSection";
import { useSectionContainer } from "./state/useSectionContainer";

const SectionContainer = () => {
  const {
    isDisableFinishBtn,
    handleContinue,
    handleReturn,
    handleChecked,
    sectionValues,
    isInvalidEdition,
  } = useSectionContainer();

  return (
    <>
      <SelectSection
        handleChecked={handleChecked}
        sectionValues={sectionValues}
        isInvalidEdition={isInvalidEdition}
      />
      <Box sx={styles.boxFooter}>
        <FooterOptions
          primaryButton={{
            isDisabled: !isDisableFinishBtn,
            isHidden: false,
            onAction: () => handleContinue(),
          }}
          secondaryButton={{
            isDisabled: false,
            isHidden: false,
            onAction: () => handleReturn(),
          }}
          isLoading={false}
          isValidate={false}
          textBtn={INFORMATION_CUSTOMER_LABELS.BUTTON_CONTINUE}
        />
      </Box>
    </>
  );
};

export default SectionContainer;
