import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { isNil } from "lodash";
import { ICheckBoxProps } from "./CheckBox.interfaces";

function CheckBoxInput<T>({
  label,
  isDisabled,
  field,
  onChange,
  checked,
}: ICheckBoxProps<T>) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          {...field}
          {...(!isNil(checked) ? { checked: checked } : {})}
          disabled={isDisabled}
          onChange={(event) => {
            // eslint-disable-next-line sort-keys
            field.onChange(event);
            if (onChange) {
              // @ts-ignore
              onChange(event, field.name, field);
            }
          }}
        />
      }
      label={label}
    />
  );
}

export default CheckBoxInput;
