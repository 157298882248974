import React, { FC } from "react";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { IWebhookConfig } from "./WebhookConfig.interfaces";
import { webhookConfigStyles } from "./WebhookConfig.styles";
import { ADD_BUTTON_LABEL } from "../../shared/constants/labels/webhook_form_labels";
import { useWebhookConfig } from "./state/useWebhookConfig";
import useResumeContainer from "../ResumeContainer/state/useResumeContainer";
import { EntityNameEnum } from "../../shared/enums/entityName";

export const WebhookConfig: FC<IWebhookConfig> = ({
  title,
  isEditing,
  rows,
  section,
}: IWebhookConfig) => {
  const { handleRedirectUrl } = useWebhookConfig();
  const { isCentralized, entityName } = useResumeContainer(section);
  const hideAddWebhook: boolean =
    entityName === EntityNameEnum.BRANCH ? isCentralized : false;

  return (
    <Paper elevation={0} sx={webhookConfigStyles.paper}>
      <Grid
        container
        spacing={3}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <Typography
            color="primary"
            variant="h6"
            sx={webhookConfigStyles.title}
          >
            {title}
          </Typography>
        </Grid>
        {rows === 0 && isEditing && !hideAddWebhook && (
          <Grid item xs={12}>
            <Button
              data-testid={"addButtonWebhook"}
              variant="contained"
              color="primary"
              onClick={handleRedirectUrl}
            >
              {ADD_BUTTON_LABEL}
            </Button>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
