export const ORIGIN_MICROSERVICE_WALLET_CONSTANTS: Record<string, string> = {
  ["usrv-payouts-cash"]: "Cash out",
  ["usrv-payouts-transfer"]: "Transfer out",
  ["usrv-dispersions"]: "Liquidación",
  ["usrv-dispersion3"]: "Comercio",
};

export const PAYMENT_METHOD_WALLET_CONSTANTS: Record<string, string> = {
  ["manual"]: "Kushki",
  ["automatic"]: "Comercio",
};

export const ORIGIN_MICROSERVICE_WALLET_ORIGIN_TICKER_NUMBER: string[] = [
  "usrv-payouts-cash",
  "usrv-payouts-transfer",
];
