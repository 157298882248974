export enum CatalogsNameEnum {
  ACTIVITY = "activity",
  INCOME_SOURCE_CONCEPT = "incomeSourceConcept",
  TAX_PAYER_TYPE = "taxPayerType",
  BANK_LIST_DISPERSION = "bankListDispersion",
}

export enum CatalogPropertyEnum {
  ACTION = "action",
  CODE = "code",
  DATA = "data",
  ID = "id",
  NAME = "name",
  VALUE = "value",
}
