import { useEffect, useState } from "react";
import { SpecialConditionSectionProps } from "../SpecialConditionSection";
import { useParams } from "react-router-dom";
import { IUseParams } from "../../../shared/infrastructure/interfaces/IUseParams";
import { isFormEditMode } from "../../../utils/utilsFile";
import { Values } from "../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { get, isUndefined } from "lodash";
import {
  ArrayField,
  Control,
  useFieldArray,
  useFormContext,
  UseFormMethods,
} from "react-hook-form";
import { IUseSpecialConditionState } from "../../../shared/infrastructure/interfaces/IUseSpecialConditionState";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { SecurityRuleData } from "../../../../types/security_rule_data";
import {
  Conditions,
  ConfigurationResponse,
} from "../../../../types/configuration_response";
import { LabelEnum } from "../../../shared/infrastructure/enums/LabelEnum";
import { PathsEnum } from "../../../shared/infrastructure/enums/PathsEnum";

export const useSpecialConditionSectionState = (
  props: SpecialConditionSectionProps
): IUseSpecialConditionState => {
  const availableConditions: Conditions[] | undefined = useSelector(
    (state: IAppState) => state.availableConditions
  );
  const configurationValue: ConfigurationResponse | undefined = useSelector(
    (state: IAppState): ConfigurationResponse | undefined => state.configuration
  );
  const { getValues, setValue, control } = useFormContext() as UseFormMethods;
  const { ruleId, ruleName } = useParams<IUseParams>();
  const subKind: string = getValues(PathsEnum.SUB_KIND);
  const [isSpecial, enableSpecial] = useState<boolean>(
    subKind === Values.RULES_KIND_SPECIAL
  );
  const rule: SecurityRuleData | undefined = useSelector(
    (state: IAppState): SecurityRuleData | undefined => state.rule
  );
  const { fields } = useFieldArray({
    control: get(props, "form.control") as Control,
    name: "specialCondition",
  });

  useEffect(() => {
    if (props.form && isFormEditMode(ruleId))
      enableSpecial(subKind === Values.RULES_KIND_SPECIAL);

    if (props.form && isFormEditMode(ruleName)) {
      if (subKind === Values.RULES_KIND_SPECIAL) enableSpecial(true);
    }

    if (props.form && !isUndefined(rule) && isUndefined(ruleName))
      enableSpecial(
        get(rule, PathsEnum.SUB_KIND, "") === Values.RULES_KIND_SPECIAL
      );
  }, [control]);

  useEffect(() => {
    if (!isSpecial) {
      setValue("periodicity", undefined);
      setValue("stateTrx", undefined);
      setValue("trxCount", undefined);
    }

    props.setIsDisabledSpeed(isSpecial);
  }, [isSpecial]);

  useEffect(() => {
    if (!isUndefined(subKind) && subKind !== Values.RULES_KIND_SPECIAL)
      setValue("specialCondition", [
        {
          value: "",
          label: "",
          operator: "",
        },
      ]);

    enableSpecial(subKind === Values.RULES_KIND_SPECIAL);
  }, [subKind]);

  useEffect(() => {
    if (isSpecial) {
      const hasEnabledSpecialCond: boolean =
        props.fieldsCond.findIndex((field: Object) =>
          [LabelEnum.CARD_ID, LabelEnum.MASKED_CARD].includes(
            get(field, "label", "")
          )
        ) !== -1;
      if (!hasEnabledSpecialCond) {
        enableSpecial(false);
        setValue("specialCondition", [
          {
            value: "",
            label: "",
            operator: "",
          },
        ]);
        setValue(PathsEnum.SUB_KIND, Values.RULES_KIND_STRICT);
      }
    }
  }, [props.fieldsCond]);

  const getFields = (): Partial<ArrayField<Record<string, any>, "id">>[] => {
    const defaultFields: Partial<ArrayField<Record<string, any>, "id">>[] = [
      {
        value: "",
        label: "",
        operator: "",
      },
    ];

    return Array.isArray(fields) && fields.length === 0
      ? defaultFields
      : fields;
  };

  return {
    handle: {
      enableSpecial,
    },
    configurationValue,
    isSpecial,
    availableConditions,
    fields: getFields(),
  };
};
