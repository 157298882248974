import React from "react";
import ModalSlider from "./ModalSlider/ModalSlider";
import { useSlider } from "./State/useSlider";
import SlideItem from "./SlideItem/SlideItem";
import SwipeableViews from "react-swipeable-views";
import { Box, Button, IconButton, MobileStepper } from "@mui/material";
import { sliderStyle } from "./slider.styles";
import { IconChevronLeft, IconChevronRight } from "@kushki/connect-ui";
import {
  BUTTON_TEXT,
  REDIRECT_PATH,
} from "../../shared/constants/content_slides";
import { redirectPageUtils } from "../../shared/utils/redirectPage.utils";
import { ISliderProps } from "./slider.interfaces";

const Slider: React.FC<ISliderProps> = ({
  isOpen,
  closeModal,
  queryParam,
}: ISliderProps) => {
  const { slidesContent, nextSlide, index, previousSlide, isDisableBtn } =
    useSlider();

  const styles = sliderStyle();

  return (
    <ModalSlider isOpen={isOpen} closeModal={closeModal}>
      <Box sx={styles.container}>
        <Box sx={styles.sliderWrapper}>
          <Box>
            {index !== 0 && (
              <IconButton
                data-testid={"rightButton"}
                color="primary"
                onClick={previousSlide}
                sx={styles.arrowButton}
              >
                <IconChevronLeft color={"primary"} fontSize={"large"} />
              </IconButton>
            )}
          </Box>
          <SwipeableViews index={index}>
            {slidesContent.map((content) => (
              <SlideItem
                key={content.title}
                image={content.image}
                title={content.title}
                secondaryText={content.secondaryText}
              />
            ))}
          </SwipeableViews>
          <Box>
            {index !== 2 && (
              <IconButton
                data-testid={"rightButton"}
                color="primary"
                onClick={nextSlide}
                sx={styles.arrowButton}
              >
                <IconChevronRight color={"primary"} fontSize={"large"} />
              </IconButton>
            )}
          </Box>
        </Box>

        <Box sx={styles.stepperWrapper}>
          <MobileStepper
            variant="dots"
            steps={slidesContent.length}
            position="static"
            activeStep={index}
            sx={styles.dotStepper}
            nextButton={<Button sx={styles.stepperButton} />}
            backButton={<Button sx={styles.stepperButton} />}
            data-testid="MobileStepper"
          />
          <Button
            variant="contained"
            disabled={isDisableBtn}
            onClick={() =>
              redirectPageUtils(
                `${REDIRECT_PATH}?nodeId=${queryParam}&hideSideBar=true`
              )
            }
          >
            {BUTTON_TEXT}
          </Button>
        </Box>
      </Box>
    </ModalSlider>
  );
};

export default Slider;
