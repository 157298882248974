/**
 * Security Wrapper Enum
 */

export enum SecurityWrapperEnum {
  MODULE_ID = "M_TRANSACTIONS.TRANSACTIONS",
  PAYMENT_DOWNLOAD = "M_TRANSACTIONS.TRANSACTIONS.Cobros.Descargar",
  PAYMENT_DETAIL_DOWNLOAD = "M_TRANSACTIONS.TRANSACTIONS.Cobros.DescagarDetalle",
  CHARGEBACK = "M_TRANSACTIONS.TRANSACTIONS.Cobros.Contracargo",
  START_CHARGEBACK = "M_TRANSACTIONS.TRANSACTIONS.Cobros.IniciarContracargo",
  VIEW_INITIAL_TRANSACTION = "M_TRANSACTIONS.TRANSACTIONS.Cobros.VerTransaccionInicial",
  REQUEST_CHARGEBACK_DOWNLOAD_RECEIPT = "M_TRANSACTIONS.TRANSACTIONS.Cobros.SolicitarContracargoDescargarRecibo",
  REQUEST_REFUND = "M_TRANSACTIONS.TRANSACTIONS.Cobros.SolicitarDevolucion",
  REQUEST_REFUND_DOWNLOAD_RECEIPT = "M_TRANSACTIONS.TRANSACTIONS.Cobros.SolicitarDevolucionDescargarRecibo",
}
