import React, { PropsWithChildren } from "react";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { History } from "../../../types/security_rule";
import { TimeLineConstants } from "../../shared/infrastructure/constants/SecurityRulesConstants";
import { get } from "lodash";

export interface IDialogTimeLineProps {
  open: boolean;
  data: DataModal | undefined;
  handleFinish: () => void;
}

export interface DataModal {
  history: History;
  dateFormat: string;
  hourFormat: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: "#000000",
      paddingRight: 102.75,
      paddingTop: 20,
      paddingBottom: 10,
      width: 540,
    },
    dialogActions: {
      marginRight: "10px",
      marginBottom: "5px",
    },
    primaryButton: {
      fontSize: "16px !important",
      fontStyle: "normal",
      fontWeight: 500,
      color: "#023365 !important",
      padding: "10px 23px 10px 23px !important",
      lineHeight: "100% !important",
    },
    closeButton: {
      position: "absolute!important" as "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    principalText: {
      fontSize: "14px !important",
      lineHeight: "140% !important",
      color: "#46525C !important",
    },
    titleContent: {
      fontSize: "16px !important",
      lineHeight: "150% !important",
      color: "#293036",
      paddingBottom: 5,
    },
    padLeft: {
      paddingLeft: 10,
    },
  })
);

export const DialogTimeLine: React.FC<IDialogTimeLineProps> = (
  props: PropsWithChildren<IDialogTimeLineProps>
) => {
  const classes = useStyles();
  return (
    <Dialog open={props.open} onClose={props.handleFinish}>
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <Typography variant="h5">{`${TimeLineConstants.DISABLED_RULE} ${get(
          props,
          "data.history.userName",
          ""
        )}`}</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={props.handleFinish}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.titleContent}>
              {`${TimeLineConstants.DATE_REQUEST}`}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.padLeft}>
            <Typography
              variant="h6"
              component="span"
              className={classes.principalText}
            >
              {get(props, "data.dateFormat", "")}
            </Typography>
            <Typography className={classes.principalText}>
              {get(props, "data.hourFormat", "")}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 20 }}>
            <Typography className={classes.titleContent}>
              {`${TimeLineConstants.REASON}`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ paddingBottom: 20 }}
            className={classes.padLeft}
          >
            <Typography
              variant="h6"
              component="span"
              className={classes.principalText}
            >
              {get(props, "data.history.reason", "")}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={props.handleFinish} className={classes.primaryButton}>
          {`${TimeLineConstants.OK}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
