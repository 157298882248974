import React from "react";
import { Grid } from "@mui/material";
import { createNamedStyles } from "@shared/interfaces/create_named_styles";
import { defaultTo } from "lodash";

export interface EllipsisTextProps {
  itemsList: string[];
  quantity: number;
  itemsMaxCharacters?: number;
  disabled?: boolean;
  onClickField?: () => void;
}

export const ellipsisStyle = createNamedStyles({
  container: {
    cursor: "pointer",
  },
  disabled: {
    opacity: 0.3,
    pointerEvents: "none",
    userSelect: "none",
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
  },
});

export const EllipsisText: React.FC<EllipsisTextProps> = ({
  onClickField,
  itemsList,
  quantity,
  disabled,
  itemsMaxCharacters,
}: EllipsisTextProps) => {
  const textItemsList: string = defaultTo(itemsList, ["N/A"]).join(", ");

  return (
    <Grid
      data-testid={"id-ellipsis-text"}
      container
      justifyContent="space-between"
      sx={disabled ? ellipsisStyle.disabled : ellipsisStyle.container}
      onClick={onClickField}
    >
      <Grid sx={ellipsisStyle.text} item md={10}>
        {itemsMaxCharacters
          ? textItemsList.substring(0, itemsMaxCharacters)
          : textItemsList}
      </Grid>
      {quantity > 2 ? (
        <Grid item md={2}>
          {`(+ ${quantity})`}
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};
