import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { ResponseSearchBillingByFilters } from "../../../../types/response_search_billing_by_filters";
import { CountryEnum } from "../../../shared/enums/CountryEnum";
import { KindEnum } from "../../../shared/enums/KindEnum";
import { SortEnum } from "../../../shared/enums/SortEnum";
import { FieldSnakeNameEnum } from "../../../shared/enums/FieldNameEnum";
import { AxiosRequestConfig } from "axios";
export const getRetentions = createAsyncThunk<
  ResponseSearchBillingByFilters,
  { merchantId: string; from: string; to: string }
>("retentionApp/getRetentions", async ({ merchantId, from, to }) => {
  const response = await axios.post<ResponseSearchBillingByFilters>(
    API_ROUTES.SEARCH_RETENTION,
    {
      country: CountryEnum.colombia,
      filter: {
        kind: KindEnum.RETENTION_TRX,
        status: "completed_invoice|completed_charge|completed",
      },
      format: "screen",
      from,
      generalField: merchantId,
      limit: 10,
      offset: 0,
      sort: {
        field: FieldSnakeNameEnum.START_DATE,
        order: SortEnum.DESC,
      },
      to,
    }
  );

  return response.data;
});

export const getRetentionsFile = createAsyncThunk<
  ResponseSearchBillingByFilters,
  { transactionId: string; kindRetention: string }
>(
  "retentionApp/getRetentionsFile",
  async ({ transactionId, kindRetention }) => {
    const url: string = API_ROUTES.DOWNLOAD_RENTENTION_FILE(transactionId);
    const config: AxiosRequestConfig = {
      params: {
        kindRetention,
      },
    };

    const response = await axios.get<ResponseSearchBillingByFilters>(
      url,
      config
    );

    return response.data;
  }
);
