import { cloneDeep, isEmpty } from "lodash";
import { ITableRowPropsMassive } from "../../components/Table/TableNodes/interfaces";

export const verifyChangedRowData = (
  setHandledRows: Function,
  calculateIndex: Function,
  handledRows: ITableRowPropsMassive<object>[],
  rowsData: ITableRowPropsMassive<object>[]
) => {
  return (
    (isEmpty(handledRows) || handledRows.length !== rowsData.length) &&
    setHandledRows(calculateIndex(cloneDeep(rowsData)))
  );
};
export const clipboard = async (text: string): Promise<void> => {
  await window.navigator.clipboard.writeText(text);
};

export const isMerchantId = (value: string) => {
  return /^\d+$/.test(value);
};
