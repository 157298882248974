import { TRefundTransactionStatus } from "../types";

export const REFUNDS_STATUS_LABEL_LOOKUP: Record<
  TRefundTransactionStatus,
  string
> = {
  approved: "Reembolsado",
  expired: "Vencido",
};

export const REFUND_TRANSACTION_STATUS_FILTER_OPTIONS: TRefundTransactionStatus[] =
  ["approved", "expired"];
