import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { Color } from "@material-ui/lab/Alert";
import { useSnackBarAlertState } from "./useSnackBarAlertState";
import { makeStyles } from "@material-ui/core/styles";
import { AlertProps } from "@material-ui/lab/Alert/Alert";

export interface SnackbarAlertProps {
  message: string;
  open: boolean;
  type: Color | "dark";
  vertical: "bottom" | "top";
  horizontal: "left" | "center" | "right";
  icon: React.ReactNode;
  hasOnclose: boolean;
  autoHideDuration: number;
  transitionDuration: number;
  handlerClose: (_event?: React.SyntheticEvent, reason?: string) => void;
}

const useStyles = makeStyles(() => ({
  toolbar: {
    backgroundColor: "#687484",
    color: "#ffffff",
  },
}));

export const SnackBarAlert: React.FC<SnackbarAlertProps> = (
  props: SnackbarAlertProps
) => {
  const { open } = useSnackBarAlertState(props);
  const styles = useStyles();
  const severity: Partial<AlertProps> =
    props.type === "dark"
      ? {
          className: styles.toolbar,
        }
      : {
          severity: props.type,
        };
  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: props.vertical,
          horizontal: props.horizontal,
        }}
        open={open}
        autoHideDuration={props.autoHideDuration || 3e3}
        transitionDuration={props.transitionDuration || 1e3}
        onClose={props.handlerClose}
      >
        {props.hasOnclose ? (
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={props.handlerClose}
            {...severity}
          >
            {props.message}
          </MuiAlert>
        ) : (
          <MuiAlert elevation={6} variant="filled" {...severity}>
            {props.message}
          </MuiAlert>
        )}
      </Snackbar>
    </React.Fragment>
  );
};
