import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/hooks/hooks";
import { IGeneralFilter } from "../../../../../store/interfaces/FiltersState.interfaces";
import { setGeneralFilter } from "../../../../../store/actions/filters/filters";
import { selectGeneralFilter } from "../../../../../store/selectors/filters/filters";
import { IUseGeneralFilterState } from "./useGeneralFilterState.interfaces";
import { IFilters } from "../../../../common/FilterBox/FilterBox.interfaces";
import {
  PAYMENT_METHOD_FILTER_OPTIONS,
  TRANSACTION_STATUS_FILTER_OPTIONS,
} from "../../../../../shared/constants";
import {
  PAYMENT_METHOD_LABEL_LOOKUP,
  TPaymentMethod,
  TRANSACTION_STATUS_LABEL_LOOKUP,
  TTransactionStatus,
} from "../../../../../shared/types";

export const useGeneralFilterState = (): IUseGeneralFilterState => {
  const dispatch = useAppDispatch();
  const { paymentMethod, transactionStatus } =
    useAppSelector(selectGeneralFilter);

  const currentFilters: IFilters[] = [
    {
      id: "paymentMethod",
      options: PAYMENT_METHOD_FILTER_OPTIONS.map((method) => ({
        id: method,
        label: PAYMENT_METHOD_LABEL_LOOKUP[method],
        selected: paymentMethod.includes(method),
      })),
      title: "Medios de pago",
    },
    {
      id: "transactionStatus",
      options: TRANSACTION_STATUS_FILTER_OPTIONS.map((status) => ({
        id: status,
        label: TRANSACTION_STATUS_LABEL_LOOKUP[status],
        selected: transactionStatus.includes(status),
      })),
      title: "Estado de la transacción",
    },
  ];

  const handleSetGeneralFilter = (filters: IFilters[]) => {
    const paymentMethod = filters.find(
      (filter) => filter.id === "paymentMethod"
    );

    const transactionStatus = filters.find(
      (filter) => filter.id === "transactionStatus"
    );

    const generalFilters: IGeneralFilter = {
      paymentMethod: (paymentMethod?.options
        .filter((option) => option.selected)
        .map((option) => option.id) ?? []) as TPaymentMethod[],
      transactionStatus: (transactionStatus?.options
        .filter((option) => option.selected)
        .map((option) => option.id) ?? []) as TTransactionStatus[],
    };

    dispatch(setGeneralFilter(generalFilters));
  };

  return {
    currentFilters,
    handleSetGeneralFilter,
  };
};
