import { IDropdownItemProps } from "@kushki/connect-ui/dist/Components/Atoms/List/DropdownItem";
import {
  DropDownID,
  DropDownText,
} from "../../../../shared/enums/DropDownValues";
import { AppRoutes } from "../../../../shared/infrastructure/routes";
import {
  setMerchantDeferredData,
  showDeleteModal,
} from "../../../../store/reducers/layout/layout.slice";
import { DeleteProcessorModal } from "../../../../shared/constants/modal-info";
import React, { ReactNode, useEffect } from "react";
import { getDeferredConfig } from "../../../../store/thunks/layout/layout.thunks";
import { DeleteModalInfo } from "../../../BusinessRules/BusinessRules.interfaces";
import { defaultTo, get, isEmpty } from "lodash";
import { NodeInfoPath } from "../../../../shared/enums/NodeInfoPath";
import { DEFERRED_TYPE } from "../../../../shared/constants/deferred_type";
import { CountriesEnum } from "../../../../shared/infrastructure/enums/CountriesEnum";
import { CENTRO_AMERICA } from "../DeferredList.data";
import { GetNodeInfoResponse } from "../../../../../types/get_node_info_response";
import {
  DeferredOption,
  DeferredOptions,
} from "../../../../../types/get_deferred_admin_merchant_response";
import { isCentralizedBranchDeferred } from "../../../../utils/branch/branch-util";
import { useDispatch } from "react-redux";
import { setBasicMerchantInformation } from "../../../../shared/utils/local-storage/basic-merchant-information";
import { TabIndexNameEnum } from "../../../../shared/enums/TabIndexEnum";
import { isConsoleMerchantMassive } from "../../../../shared/utils/local-storage/console-merchant-massive";
import { LocalStoragePathEnum } from "../../../../shared/infrastructure/enums/LocalStoragePathEnum";
import { MerchantDeferred } from "../../../../../types/merchan_deferred";
import { evaluateDeferredNotSent } from "../../../../utils/utilsFile";
import { useAppSelector } from "../../../../store/hooks/storeHook";
import { ButtonColorEnum } from "../../../../shared/enums/ButtonStylesEnum";

export interface IUseDeferredList {
  getDropDownList: (
    deferredID: string,
    editIcon: ReactNode,
    deleteIcon: ReactNode,
    isSent: boolean
  ) => IDropdownItemProps[] | undefined;
  hasUnsentDeferred?: boolean;
  isMassiveBranches: boolean;
}

export const useDeferredList = (
  deferredList: DeferredOptions,
  nodeInfo: GetNodeInfoResponse,
  deleteModalInfo: DeleteModalInfo
): IUseDeferredList => {
  const dispatch = useDispatch();
  const {
    successSaveMassiveDeferred,
    merchantDeferredData,
    isMassiveBranches,
  } = useAppSelector((store) => store.layout);
  const consoleMerchantMassive: boolean = isConsoleMerchantMassive();
  const [hasUnsentDeferred, setHasUnsentDeferred] = React.useState<boolean>(
    true
  );
  const getDropDownList = (
    deferredID: string,
    editIcon: ReactNode,
    deleteIcon: ReactNode,
    isSent?: boolean
  ): IDropdownItemProps[] | undefined => {
    if (
      isCentralizedBranchDeferred(nodeInfo) ||
      (consoleMerchantMassive && isSent)
    )
      return undefined;

    return [
      {
        id: DropDownID.EDIT,
        text: DropDownText.EDIT,
        variant: "withIcon",
        onSelect: () => {
          setBasicMerchantInformation({
            redirectTab: TabIndexNameEnum.DEFERRED,
          });
          window.location.href = AppRoutes.EDIT_DEFERRED(deferredID);
        },
        icon: editIcon,
      },
      {
        id: DropDownID.DELETE,
        text: DropDownText.DELETE,
        variant: "withIcon",
        onSelect: () => {
          if (!consoleMerchantMassive) {
            deleteModalInfo.setDeferredIDForDelete(deferredID);
            dispatch(
              showDeleteModal(DeleteProcessorModal.DELETE_PROCESSOR_MODAL)
            );
          } else {
            const removeItem: DeferredOptions = deferredList.filter(
              (item: DeferredOption) => item.id !== deferredID
            );
            const merchantDeferredState: MerchantDeferred = JSON.parse(
              defaultTo(
                localStorage.getItem(
                  LocalStoragePathEnum.MERCHANT_DEFERRED_DATA
                ),
                "{}"
              )
            );
            const updateDeferredOption: MerchantDeferred = {
              ...merchantDeferredState,
              deferredOptions: removeItem,
            };
            localStorage.setItem(
              LocalStoragePathEnum.MERCHANT_DEFERRED_DATA,
              JSON.stringify(updateDeferredOption)
            );
            dispatch(setMerchantDeferredData(updateDeferredOption));
          }
        },
        icon: deleteIcon,
        rgbColor: ButtonColorEnum.Pink,
      },
    ];
  };

  useEffect(() => {
    const country: string = get(nodeInfo, NodeInfoPath.InfoCountry, "");
    if (!isEmpty(deferredList) && !isEmpty(country)) {
      const requestCountry = getDeferredConfigCountry(country);
      dispatch(
        getDeferredConfig({
          configType: DEFERRED_TYPE,
          country: requestCountry,
        })
      );
    }
  }, [nodeInfo]);

  useEffect(() => {
    const hasDeferredNotSent: boolean = evaluateDeferredNotSent();
    setHasUnsentDeferred(hasDeferredNotSent);
  }, [successSaveMassiveDeferred, merchantDeferredData]);

  return { getDropDownList, hasUnsentDeferred, isMassiveBranches };
};

const getDeferredConfigCountry = (country: string) => {
  switch (country) {
    case CountriesEnum.COSTA_RICA:
    case CountriesEnum.GUATEMALA:
    case CountriesEnum.PANAMA:
    case CountriesEnum.NICARAGUA:
    case CountriesEnum.HONDURAS:
    case CountriesEnum.EL_SALVADOR:
      return CENTRO_AMERICA;
    default:
      return country;
  }
};
