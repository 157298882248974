export enum SnackbarEnum {
  REQUEST_ERROR = "Ha ocurrido un error. Inténtalo nuevamente",
  REQUEST_SUCCESS = "Has solicitado una devolución con éxito.",
  CHARGEBACK_REQUEST_SUCCESS = "Contracargo realizado con éxito del número de ticket ",
  CHARGEBACK_REQUEST_FAILED = "Contracargo fallido",
}

export enum NotificationTypeEnum {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}
