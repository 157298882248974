/**
 * CatalogCl
 */
import { Category } from "../constants/MerchantInformationConstants";
import { CatalogsEnum } from "../catalogs-enum";
import { CurrencyEnum } from "../CurrencyEnum";

export const CatalogCl: Record<string, Category[]> = {
  [CatalogsEnum.BankAccountTypes]: [
    { value: "0", name: "Corriente" },
    { value: "1", name: "Ahorros" },
  ],
  [CatalogsEnum.Currency]: [
    { value: CurrencyEnum.UF, name: "Unidad de Fomento" },
    { value: CurrencyEnum.CLP, name: "Pesos" },
  ],
};
