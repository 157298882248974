import { Grid, Typography } from "@mui/material";
import { useDropDownStyles as styles } from "../DropDownItem/DropDownItem.styles";
import {
  DROPDOWN_ABA_CODE,
  DROPDOWN_ACCOUNT_NUMBER_LABEL,
  DROPDOWN_ACCOUNT_ORIGIN,
  DROPDOWN_ACCOUNT_TYPE,
  DROPDOWN_BENEFICIARY_NAME,
  DROPDOWN_DESTINY_CURRENCY,
  DROPDOWN_FINANCIAL_INSTITUTION_ADDRESS,
  DROPDOWN_FINANCIAL_INSTITUTION_NAME,
  DROPDOWN_IBAN_CODE,
  DROPDOWN_KEY_ACCOUNT_NUMBER,
  DROPDOWN_ROUTING_NUMBER,
  DROPDOWN_SWIFT_CODE,
} from "../../shared/constants/labels/dropdown_container_labels";
import { getCountryFlag } from "../../shared/constants/flags/countryFlag";
import React from "react";
import { IDropDownItem } from "../DropDownItem/DropDownItem.interfaces";
import { isEmpty, isNil } from "lodash";
import { getLabelCountryFromValue } from "../../shared/utils/bankAccountUtils";

export const SummaryBankAccountDifferentCountry = (item: IDropDownItem) => {
  return (
    <>
      <Grid sx={styles.gridFlexIcon}>
        <Grid item xs={5}>
          <Typography sx={styles.detailHeader}>
            {DROPDOWN_ACCOUNT_ORIGIN}
          </Typography>
        </Grid>
        <Grid item xs={7} sx={styles.gridFlex}>
          {getCountryFlag[item.country] && (
            <Grid sx={styles.flagIcon}>{getCountryFlag[item.country]}</Grid>
          )}
          <Typography sx={styles.detailDescription}>
            {getLabelCountryFromValue(item.country)}
          </Typography>
        </Grid>
      </Grid>
      <Grid sx={styles.gridFlexDesc}>
        <Grid item xs={5}>
          <Typography sx={styles.detailHeader}>
            {DROPDOWN_FINANCIAL_INSTITUTION_NAME}
          </Typography>
        </Grid>
        <Grid item xs={7} sx={styles.gridFlex}>
          <Typography sx={styles.detailDescription}>{item.bankName}</Typography>
        </Grid>
      </Grid>
      {!isEmpty(item.address) && (
        <Grid sx={styles.gridFlexDesc}>
          <Grid item xs={5}>
            <Typography sx={styles.detailHeader}>
              {DROPDOWN_FINANCIAL_INSTITUTION_ADDRESS}
            </Typography>
          </Grid>
          <Grid item xs={7} sx={styles.gridFlex}>
            <Typography sx={styles.detailDescription}>
              {item.address}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid sx={styles.gridFlexDesc}>
        <Grid item xs={5}>
          <Typography sx={styles.detailHeader}>
            {DROPDOWN_DESTINY_CURRENCY}
          </Typography>
        </Grid>
        <Grid item xs={7} sx={styles.gridFlex}>
          <Typography sx={styles.detailDescription}>{item.currency}</Typography>
        </Grid>
      </Grid>
      <Grid sx={styles.gridFlexDesc}>
        <Grid item xs={5}>
          <Typography sx={styles.detailHeader}>
            {DROPDOWN_BENEFICIARY_NAME}
          </Typography>
        </Grid>
        <Grid item xs={7} sx={styles.gridFlex}>
          <Typography sx={styles.detailDescription}>
            {item.nameBeneficiary}
          </Typography>
        </Grid>
      </Grid>
      <Grid sx={styles.gridFlexDesc}>
        <Grid item xs={5}>
          <Typography sx={styles.detailHeader}>
            {DROPDOWN_ACCOUNT_TYPE}
          </Typography>
        </Grid>
        <Grid item xs={7} sx={styles.gridFlex}>
          <Typography sx={styles.detailDescription}>
            {item.accountType}
          </Typography>
        </Grid>
      </Grid>
      <Grid sx={styles.gridFlexDesc}>
        <Grid item xs={5}>
          <Typography sx={styles.detailHeader}>
            {DROPDOWN_ACCOUNT_NUMBER_LABEL}
          </Typography>
        </Grid>
        <Grid item xs={7} sx={styles.gridFlex}>
          <Typography sx={styles.detailDescription}>
            {item.accountNumber}
          </Typography>
        </Grid>
      </Grid>
      {!isNil(item.interbankAccountCode) &&
        !isEmpty(item.interbankAccountCode) && (
          <Grid sx={styles.gridFlexDesc}>
            <Grid item xs={5}>
              <Typography sx={styles.detailHeader}>
                {DROPDOWN_KEY_ACCOUNT_NUMBER}
              </Typography>
            </Grid>
            <Grid item xs={7} sx={styles.gridFlex}>
              <Typography sx={styles.detailDescription}>
                {item.interbankAccountCode}
              </Typography>
            </Grid>
          </Grid>
        )}
      {!isNil(item.codeABA) && (
        <Grid sx={styles.gridFlexDesc}>
          <Grid item xs={5}>
            <Typography sx={styles.detailHeader}>
              {DROPDOWN_ABA_CODE}
            </Typography>
          </Grid>
          <Grid item xs={7} sx={styles.gridFlex}>
            <Typography sx={styles.detailDescription}>
              {item.codeABA}
            </Typography>
          </Grid>
        </Grid>
      )}
      {!isNil(item.routingNumber) && (
        <Grid sx={styles.gridFlexDesc}>
          <Grid item xs={5}>
            <Typography sx={styles.detailHeader}>
              {DROPDOWN_ROUTING_NUMBER}
            </Typography>
          </Grid>
          <Grid item xs={7} sx={styles.gridFlex}>
            <Typography sx={styles.detailDescription}>
              {item.routingNumber}
            </Typography>
          </Grid>
        </Grid>
      )}
      {!isNil(item.codeSwift) && (
        <Grid sx={styles.gridFlexDesc}>
          <Grid item xs={5}>
            <Typography sx={styles.detailHeader}>
              {DROPDOWN_SWIFT_CODE}
            </Typography>
          </Grid>
          <Grid item xs={7} sx={styles.gridFlex}>
            <Typography sx={styles.detailDescription}>
              {item.codeSwift}
            </Typography>
          </Grid>
        </Grid>
      )}
      {!isNil(item.codeIban) && (
        <Grid sx={styles.gridFlexDesc}>
          <Grid item xs={5}>
            <Typography sx={styles.detailHeader}>
              {DROPDOWN_IBAN_CODE}
            </Typography>
          </Grid>
          <Grid item xs={7} sx={styles.gridFlex}>
            <Typography sx={styles.detailDescription}>
              {item.codeIban}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};
