export enum SecurityWrapperRoles {
  M_COMPLIANCE_ROOT = "M_COMPLIANCE",
  M_COMPLIANCE_CREATE_ALARM = "M_COMPLIANCE.ListaDeReglasAlarmas.CrearAlarma",
  M_COMPLIANCE_CREATE_ALARM_CLIENT = "M_COMPLIANCE.ListaDeReglasAlarmas.CrearAlarma.PorCliente",
  M_COMPLIANCE_CREATE_GENERAL_ALARM_CLIENT = "M_COMPLIANCE.ListaDeReglasAlarmas.CrearAlarma.General",
  M_COMPLIANCE_CREATE_ALARM_INDIVIDUAL = "M_COMPLIANCE.CrearAlarmaPorCliente",
  M_COMPLIANCE_CREATE_ALARM_SAVE = "M_COMPLIANCE.CrearAlarmaPorCliente.Guardar",
  M_COMPLIANCE_CREATE_ALARM_CANCEL = "M_COMPLIANCE.CrearAlarmaPorCliente.Cancelar",
  M_COMPLIANCE_SELECT_NODE = "M_COMPLIANCE.SeleccionNodos.SeleccionarTipoDeNodo",
  M_COMPLIANCE_SEARCH = "M_COMPLIANCE.SeleccionNodos.Buscar",
  M_COMPLIANCE_TABLE_NODE = "M_COMPLIANCE.SeleccionNodos",
  M_COMPLIANCE_TABLE_LOCAL_SEARCH = "M_COMPLIANCE.SeleccionNodos.BuscarLocal",
  M_COMPLIANCE_NODE_SELECTION_NEXT = "M_COMPLIANCE.SeleccionNodos.Siguiente",
  M_COMPLIANCE_NODE_SELECTION_CANCEL = "M_COMPLIANCE.SeleccionNodos.Cancelar",
  M_COMPLIANCE_CREATE_ALARM_LIST = "M_COMPLIANCE.ListaDeReglasAlarmas",
  M_COMPLIANCE_CREATE_ALARM_LIST_VARIABLES = "M_COMPLIANCE.ListaDeReglasAlarmas.VerDetalle",
  M_COMPLIANCE_ALARM_RULES_ACTIVATE = "M_COMPLIANCE.ListaDeReglasAlarmas.Activar",
  M_COMPLIANCE_ALARM_RULES_INACTIVATE = "M_COMPLIANCE.ListaDeReglasAlarmas.Inactivar",
  M_COMPLIANCE_ALARM_RULES_DELETE = "M_COMPLIANCE.ListaDeReglasAlarmas.Eliminar",
  M_COMPLIANCE_SEARCH_NODE = "M_COMPLIANCE.ListaDeReglasAlarmas.BuscarNodo",
  M_COMPLIANCE_SEARCH_GENERAL_NODE = "M_COMPLIANCE.ListaDeReglasAlarmas.BuscarNodoGeneral",
  M_COMPLIANCE_FILTER_FREQUENCY = "M_COMPLIANCE.ListaDeReglasAlarmas.Filtros.Frecuencia",
  M_COMPLIANCE_FILTER_STATE = "M_COMPLIANCE.ListaDeReglasAlarmas.Filtros.Estado",
  M_COMPLIANCE_FILTER_CONSTITUTIONAL_COUNTRY = "M_COMPLIANCE.ListaDeReglasAlarmas.Filtros.PaisDeConstitucion",
  M_COMPLIANCE_FILTER_COUNTRY = "M_COMPLIANCE.ListaDeReglasAlarmas.Filtros.PaisDeOperacion",
  M_COMPLIANCE_FILTER_CATEGORY = "M_COMPLIANCE.ListaDeReglasAlarmas.Filtros.Categoria",
  M_COMPLIANCE_ALARM_RULES_FILTER = "M_COMPLIANCE.ListaDeReglasAlarmas.Filtros",
  M_COMPLIANCE_FILTER_DATE_CREATED = "M_COMPLIANCE.ListaDeReglasAlarmas.Filtros.FechaCreacion",
  M_COMPLIANCE_FILTER_BY_DATE = "M_COMPLIANCE.ListaDeReglasAlarmas.FiltrarPorFecha",
  M_COMPLIANCE_ALARM_RULES_EDIT = "M_COMPLIANCE.ListaDeReglasAlarmas.Editar",
  M_COMPLIANCE_SELECTED_NODES = "M_COMPLIANCE.SeleccionNodos.ClientesSeleccionados",
  M_COMPLIANCE_FILTER_MERCHANT_ALARM = "M_COMPLIANCE.ListaAlarmasActivas.Busqueda",
  M_COMPLIANCE_ALARMS_FILTER = "M_COMPLIANCE.ListaAlarmasActivas.filtro",
  M_COMPLIANCE_ALARMS_FILTER_HISTORY = "M_COMPLIANCE.Historico.filtro",
  M_COMPLIANCE_ALARM_ACTIVATE_INITIALIZE_INVESTIGATION = "M_COMPLIANCE.ListaDeAlarmas.IniciarInvestigacion",
  M_COMPLIANCE_ALARM_ACTIVATE_CLOSE_ALARM = "M_COMPLIANCE.ListaDeAlarmas.cerrarAlarma",
  M_COMPLIANCE_ALARMS_ACTIVE_DATE = "M_COMPLIANCE.ListaAlarmasActivas.FechaActivacion",
  M_COMPLIANCE_ALARMS_ACTIVE_CONFIGURATION_BUTTON = "M_COMPLIANCE.ListaAlarmasActivas.BotónConfiguracion",
  M_COMPLIANCE_ALARMS_HISTORY_CONFIGURATION_BUTTON = "M_COMPLIANCE.Historico.Configuracion",
  M_COMPLIANCE_ALARMS_HISTORY_FILTER_SEARCH = "M_COMPLIANCE.Historico.Busqueda",
  M_COMPLIANCE_ALARMS_HISTORY_FILTER_DATE = "M_COMPLIANCE.Historico.FechaActivacion",
  M_COMPLIANCE_ALARMS_HISTORY_DOWNLOAD_FILE = "M_COMPLIANCE.Historico.DescargaArchivo",
  M_COMPLIANCE_ALARMS_ACTIVE_ADD_COMMENT = "M_COMPLIANCE.ListaAlarmasActivas.TarjetaDetalle.BotonIngresarComentarios",
  M_COMPLIANCE_ALARMS_ACTIVE_DOWNLOAD_FILE = "M_COMPLIANCE.ListaAlarmasActivas.DescargaArchivo",
  M_COMPLIANCE_ALARMS_ACTIVE_BTN_INIT_INVESTIGATION = "M_COMPLIANCE.ListaAlarmasActivas.BotonIniciarInvestigacion",
  M_COMPLIANCE_ALARM_ACTIVATE_INITIALIZE_INVESTIGATION_CARD = "M_COMPLIANCE.ListaAlarmasActivas.TarjetaDetalle.BotonIniciarInvestigacion",
  M_COMPLIANCE_ALARM_ACTIVATE_CLOSE_ALARM_CARD = "M_COMPLIANCE.ListaAlarmasActivas.TarjetaDetalle.BotonCerrarAlarma",
  M_COMPLIANCE_ALARMS_ACTIVE_ADD_COMMENT_HISTORIC = "M_COMPLIANCE.Historico.TarjetaDetalle.IngresarComentarios",
  M_COMPLIANCE_ALARMS_ACTIVE_BTN_CLOSE_ALARM = "M_COMPLIANCE.ListaAlarmasActivas.BotonCerrarNormal",
  M_COMPLIANCE_ALARMS_HISTORY_REACTIVATE_ALARM = "M_COMPLIANCE.Historico.ReactivarAlarma",
  M_COMPLIANCE_ALARMS_HISTORY_MASSIVE_REACTIVATE_ALARM = "M_COMPLIANCE.Historico.ReactivarMasivos",
  M_COMPLIANCE_ALARMS_HISTORY_ATTACHED_FILES = "M_COMPLIANCE.Historico.TarjetaDetalle.ArchivosAdjuntos",
  M_COMPLIANCE_EXECUTIVES_DOWNLOAD_FILE = "M_COMPLIANCE.CargaEjecutivos.DescargaArchivo",
  M_COMPLIANCE_COMMERCIAL_EXECUTIVE_CONTINUE_VALID_FILE = "M_COMPLIANCE.CargaEjecutivos.ModalConfirmacion.Continuar",
  M_COMPLIANCE_EXECUTIVES_VALIDATE_FILE = "M_COMPLIANCE.CargaEjecutivos.ValidarArchivo",
}
