import { createAsyncThunk } from "@reduxjs/toolkit";
import { CatalogZipResponse } from "../../../../types/catalog_zip_response";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { getJwtAuth } from "../../../shared/utils/getJwtAuth_utils";
import { MerchantBillingData } from "../../../../types/merchant_billing_data";
import { HierarchyNodeInfoRequest } from "../../../../types/hierarchy_node_info_request";
import { ConfigIdEnum } from "../../../shared/constants/initial_state_legal_details";
import { defaultTo, get, isEmpty } from "lodash";
import { initialState } from "../../reducers/merchantBillingData/merchantBillingData.slice";
import getTime from "date-fns/getTime";

export const getCatalogZip = createAsyncThunk<
  object,
  { zipCode: string; isSat: boolean }
>("merchantBillingData/getCatalogZip", async ({ zipCode, isSat }) => {
  if (!isEmpty(zipCode)) {
    const response = await axios.get<CatalogZipResponse>(
      `${API_ROUTES.CATALOG_ZIP}${zipCode}`
    );

    return {
      data: response.data,
      isSat,
    };
  }

  return {
    data: isSat ? initialState.catalogSatZip : initialState.catalogZip,
    isSat,
  };
});

export const saveBillingData = createAsyncThunk<
  boolean,
  { merchantCompany: MerchantBillingData; nodeId: string; status: string }
>(
  "merchantBillingData/saveBillingData",
  async ({ merchantCompany, nodeId, status }) => {
    const response = await axios.patch<boolean>(
      API_ROUTES.UPDATE_MERCHANT_NODE,
      merchantCompany,
      {
        headers: {
          Authorization: getJwtAuth(),
          "Content-Type": "application/json",
        },
      }
    );

    const hierarchyNodeInfo: HierarchyNodeInfoRequest = {
      configs: [
        {
          configuration: ConfigIdEnum.CN016,
          status,
          updatedAt: getTime(Date.now()),
          updatedBy: defaultTo(localStorage.getItem("username"), ""),
          value: get(merchantCompany, "publicMerchantId", ""),
        },
      ],
      nodeId,
    };

    await axios.put<boolean>(API_ROUTES.HIERARCHY_NODE_INFO, hierarchyNodeInfo);

    return response.data;
  }
);
