import React, { useRef } from "react";
import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IMenuControl } from "../../../../shared/infrastructure/interfaces/IMenuControl";
import { RATES_OPTIONS } from "../../../../shared/infrastructure/constants/CreateMerchantConstants";
import { PopupProcessorMenu } from "../../../CreateMerchantComponents/ProcessorsSection/PopupProcessorMenu/PopupProcessorMenu";

const useStyles = makeStyles(() => ({
  headerTitle: {
    fontSize: "16px",
    fontWeight: "initial",
    padding: 8,
  },
  headerGrid: {
    textAlign: "end",
    "& button": {
      fontSize: "14px",
    },
  },
}));

export interface ICardContainerHeaderProps {
  headerTitle: string;
  buttonTitle: string | undefined;
  menuControl?: IMenuControl;
  isMenuButton?: boolean;
  merchantId?: string;
  country?: string | undefined;
  handlerOnclick?: () => void;
  href?: string;
  buttonCreate?: boolean;
}

export const CardContainerHeader: React.FC<ICardContainerHeaderProps> = (
  props: React.PropsWithChildren<ICardContainerHeaderProps>
) => {
  const style = useStyles();
  const anchorRef = useRef<HTMLButtonElement>(null);
  return (
    <Grid container direction={"row"} alignItems={"center"}>
      <Grid item xs={6} style={{ textAlign: "start" }}>
        <Typography className={style.headerTitle} variant="body1">
          {props.headerTitle}
        </Typography>
      </Grid>
      <Grid item xs={6} className={style.headerGrid}>
        <Box>
          {props.isMenuButton ? (
            <PopupProcessorMenu
              id={props.merchantId!}
              country={props.country}
              buttonTitle={props.buttonTitle}
            />
          ) : (
            <Box>
              {props.buttonCreate && (
                <Button
                  ref={anchorRef}
                  aria-controls={
                    props.menuControl?.openMenu ? "menu-list-grow" : undefined
                  }
                  aria-haspopup="true"
                  onClick={() => props.handlerOnclick!()}
                  variant="contained"
                  color="primary"
                >
                  {props.buttonTitle}
                </Button>
              )}
            </Box>
          )}

          {props.menuControl !== undefined && (
            <Popper
              open={props.menuControl.openMenu}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
            >
              {({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                  <Paper>
                    <ClickAwayListener
                      onClickAway={() => props.menuControl?.setMenu(false)}
                    >
                      <MenuList
                        autoFocusItem={props.menuControl?.openMenu}
                        id="menu-list-grow"
                      >
                        {RATES_OPTIONS.map((type: string) => (
                          <MenuItem
                            key={type}
                            value={type}
                            onClick={() => {
                              props.menuControl?.navigateTo(type);
                            }}
                          >
                            {type}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
