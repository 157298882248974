import { Country } from "../SelectCountry.interfaces";
import { useEffect, useState } from "react";
import {
  IUseSelectCountryState,
  UseSelectCountryStateProps,
} from "./useSelectCountry.interfaces";
import {
  CountryListType,
  CountryOrderType,
} from "../../../shared/constants/select_country_constants";
import {
  COUNTRIES,
  COUNTRIES_KUSHKI,
  COUNTRIES_LATAM,
  USA,
} from "../../../shared/constants/countries_with_flags";

export const useSelectCountryState = ({
  orderBy = CountryOrderType.LATIN_FIRST,
  typeList = CountryListType.KUSHKI,
  onChange,
  value,
}: UseSelectCountryStateProps): IUseSelectCountryState => {
  const sortArray = (countryArray: Country[]): Country[] => {
    return countryArray.sort(
      (firstCountryToCompare, secondCountryToCompare) => {
        const labelFirstCountry = firstCountryToCompare.label.toLowerCase();
        const labelSecondCountry = secondCountryToCompare.label.toLowerCase();

        return labelFirstCountry < labelSecondCountry
          ? -1
          : labelFirstCountry > labelSecondCountry
          ? 1
          : 0;
      }
    );
  };

  const setCountryListByProps = (
    order: CountryOrderType,
    listType: CountryListType
  ): Country[] => {
    switch (listType) {
      case CountryListType.ALL:
        if (order === CountryOrderType.ALPHABETIC_ORDER)
          return sortArray([...COUNTRIES_LATAM, ...COUNTRIES, USA]);
        else
          return [
            ...sortArray(COUNTRIES_LATAM),
            ...sortArray([...COUNTRIES, USA]),
          ];
      case CountryListType.KUSHKI:
        if (order === CountryOrderType.ALPHABETIC_ORDER)
          return sortArray([...COUNTRIES_KUSHKI, USA]);
        else return [...COUNTRIES_KUSHKI, USA];
      case CountryListType.LATINOAMERICA:
        return sortArray(COUNTRIES_LATAM);
    }
  };

  const findCountryByValue = (countryName?: string): Country | null => {
    if (!countryName) return null;
    else {
      const list: Country[] = setCountryListByProps(orderBy, typeList);
      const country = list.find(
        (country: Country) =>
          country.value.toLowerCase() === countryName.toLowerCase()
      );

      return country || null;
    }
  };

  const [countryList, setCountryList] = useState<Country[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(
    findCountryByValue(value)
  );

  const onChangeState = (selectedCountry: Country | null) => {
    setSelectedCountry(selectedCountry);
    if (selectedCountry) onChange(selectedCountry.value);
  };

  useEffect(() => {
    setCountryList(setCountryListByProps(orderBy, typeList));
  }, [orderBy, typeList]);

  return {
    countryList,
    handlers: { onChange: onChangeState },
    value: selectedCountry,
  };
};
