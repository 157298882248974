import { environment } from "../../environments/environment";
import { defaultTo, get, isEmpty } from "lodash";
import { RolEnum } from "../enums/RolEnum";

export const isCustomerRol = (): boolean => {
  const getRole: string = localStorage.getItem("roles")!;
  const environmentRoles: string[] = environment.customerRoles.split(",");

  for (let step = 0; step <= environmentRoles.length; step++) {
    const foundRole: boolean = get(
      JSON.parse(getRole),
      environmentRoles[step],
      false
    );

    if (foundRole) return true;
  }

  return false;
};

export const isRiskRole = (): boolean => {
  const getRole = localStorage.getItem("roles")!;
  const cognitoGroups: string[] = get(
    JSON.parse(defaultTo(localStorage.getItem("payload"), "{}")),
    "[cognito:groups]",
    []
  );
  const environmentRoles = environment.riskRoles.split(",");
  const foundRoles: string[] = [];

  for (let step = 0; step < environmentRoles.length; step++) {
    const foundRole = get(JSON.parse(getRole), environmentRoles[step], false);

    if (foundRole) {
      foundRoles.push(environmentRoles[step]);
    }
  }

  if (!isEmpty(cognitoGroups)) {
    cognitoGroups.forEach((item) => {
      for (let step = 0; step < environmentRoles.length; step++) {
        if (environmentRoles[step] === item) {
          foundRoles.push(environmentRoles[step]);
        }
      }
    });
  }

  return (
    foundRoles.includes(RolEnum.RISK_ANALYST) ||
    foundRoles.includes(RolEnum.RISK_MANAGER) ||
    foundRoles.includes(RolEnum.CONSOLE_TRX_READ)
  );
};
export const isKushkiUser = (): boolean => {
  const getRole = localStorage.getItem("roles")!;
  const adminRoles: string[] = [
    RolEnum.BACKOFFICE_ADMIN,
    RolEnum.RISK_ANALYST,
    RolEnum.RISK_MANAGER,
  ];

  for (let step = 0; step < adminRoles.length; step++) {
    const foundRole = get(JSON.parse(getRole), adminRoles[step], false);

    if (foundRole) {
      return true;
    }
  }

  return false;
};

export const isAdminUser = (): boolean => {
  const getRole: string = localStorage.getItem("roles")!;
  const isAdminUser: boolean = get(
    JSON.parse(getRole),
    RolEnum.BACKOFFICE_ADMIN,
    false
  );

  return isAdminUser;
};
export const isChargebackManager = (): boolean => {
  const getRole: string = localStorage.getItem("roles")!;
  const isChargebackManagerUser: boolean = get(
    JSON.parse(getRole),
    RolEnum.CHARGEBACK_MANAGER,
    false
  );

  return isChargebackManagerUser;
};

export const isBackofficeMasterCustomerRole = (): boolean => {
  const getRole = localStorage.getItem("roles")!;
  const adminRoles: string[] = [RolEnum.BACKOFFICE_MASTER_CUSTOMER];

  for (let step = 0; step < adminRoles.length; step++) {
    const foundRole = get(JSON.parse(getRole), adminRoles[step], false);

    if (foundRole) {
      return true;
    }
  }

  return false;
};
