export enum DeferredOptionPath {
  Created = "created",
  Id = "id",
  MerchantStatus = "merchantStatus",
  Months = "months",
  MerchantMonths = "merchantMonths",
  MonthsOfGrace = "monthsOfGrace",
  ProcessorName = "processorName",
  StartDate = "startDate",
  EndDate = "endDate",
  IsSent = "isSent",
}

export enum DeferredPath {
  DeferredOptions = "deferredOptions",
  ConsoleMerchantMassive = "isConsoleMerchantMassive",
}
