export const snackbarAlertStyle = {
  colorFont: {
    "&.MuiAlert-filledError": {
      backgroundColor: "error.light3",
      color: "error.dark2",
    },
    backgroundColor: "secondary.dark",
    borderRadius: "4px",
    color: "background.default",
  },
  mainContainer: {
    marginBottom: "40px",
    top: "0px !important",
  },
};
