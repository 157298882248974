import React from "react";
import { Transaction, TransactionInfo } from "../../../types/transactions_data";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import TableComponent from "../TableComponent/TableComponent";
import { IColumns } from "../../shared/infrastructure/interfaces/IColumns";
import { DownloadButton } from "../commons/DownloadButton/DownloadButton";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/reducer";
import { SecurityWrapperEnum } from "../../shared/infrastructure/SecurityWrapperEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

export interface IPayoutsTransactionSelectedTableProps {
  payoutsTransactionsSelected: Transaction[];
  selectedColumns: IColumns[];
  handleSelectPayoutsTransaction(checked: boolean, row: Transaction): void;
  handleCheckSelectedPayoutsTransaction(
    payoutsTransactionToCheck: Transaction
  ): boolean;
  handleDownloadSelectedTransactions(format: string): void;
  handleSetPayuotTransactionSelected(
    payoutTransactionSelected: TransactionInfo
  ): void;
}

const useStyles = makeStyles({
  paper: {
    backgroundColor: "#F7FAFC",
    marginBottom: "22px",
    marginTop: "22px",
  },
  paperTable: {
    backgroundColor: "#FFFFFF",
  },
});

export const PayoutsTransactionSelectedTable: React.FC<IPayoutsTransactionSelectedTableProps> =
  (props: IPayoutsTransactionSelectedTableProps) => {
    const classes = useStyles();
    const loadingDownloadById = useSelector(
      (state: IAppState) => state.loadingDownloadById
    );
    return (
      <>
        {props.payoutsTransactionsSelected.length > 0 && (
          <Paper className={classes.paper}>
            <Paper elevation={0} className={classes.paperTable}>
              <TableContainer>
                <TableComponent
                  payoutsTransactions={props.payoutsTransactionsSelected}
                  isSelectedTable={true}
                  selectedColumns={props.selectedColumns}
                  handleSelectPayoutsTransaction={
                    props.handleSelectPayoutsTransaction
                  }
                  handleCheckSelectedPayoutsTransaction={
                    props.handleCheckSelectedPayoutsTransaction
                  }
                  handleSetPayuotTransactionSelected={
                    props.handleSetPayuotTransactionSelected
                  }
                />
              </TableContainer>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                p={1}
              >
                <Box p={1} flexGrow={1}>
                  Transacciones seleccionadas{" "}
                  {props.payoutsTransactionsSelected.length}
                </Box>
                <Box p={1}>
                  <ComponentSecurityWrapper
                    componentId={SecurityWrapperEnum.PAYMENT_DETAIL_DOWNLOAD}
                  >
                    <Box>
                      <DownloadButton
                        handleDownload={
                          props.handleDownloadSelectedTransactions
                        }
                        loadingDownload={loadingDownloadById}
                        shadow={true}
                      />
                    </Box>
                  </ComponentSecurityWrapper>
                </Box>
              </Box>
            </Paper>
          </Paper>
        )}
      </>
    );
  };

export default PayoutsTransactionSelectedTable;
