import { get } from "lodash";
import { ConfigEnum } from "../enums/ConfigEnum";
import { Configs } from "../../../types/get_node_info_response";
import { es } from "date-fns/locale";
import { format } from "date-fns";
import { CountriesEnum } from "../enums/CountriesEnum";
import { CurrencyEnum } from "../enums/CurrencyEnum";
import React from "react";
import {
  EcuadorPeruMerchantDeferredResponse,
  MerchantDeferredResponse,
  MexicoMerchantDeferredResponse,
} from "../../../types/merchant_deferred_response";
import EcuadorDeferredInfo from "../../components/EcuadorDeferredInfo/EcuadorDeferredInfo";
import MexicoDeferredInfo from "../../components/MexicoDeferredInfo/MexicoDeferredInfo";
import PeruDeferredInfo from "../../components/PeruDeferredInfo/PeruDeferredInfo";
import {
  CardAlia,
  CardAmex,
  CardBbva,
  CardCarnet,
  CardDinners,
  CardDiscover,
  CardJbc,
} from "@kushki/connect-ui";
import CardMastercard from "../../assets/cards/mastercard.svg";
import CardVisa from "../../assets/cards/visa.svg";
import { CardIcon } from "../../components/CardIcon/CardIcon";
import BrazilDeferredInfo from "../../components/BrazilDeferredInfo/BrazilDeferredInfo";

export const findConfigurationValue = (
  configurations: Configs[],
  configurationId: ConfigEnum,
  defaultValue: string
): string => {
  const configFound = configurations.find(
    (config: Configs) => config.configuration === configurationId
  );

  return get(configFound, "value") || defaultValue;
};

export const formatDate = (date: number, date_format: string): string => {
  return format(date, date_format, { locale: es });
};

export const validateCurrency = (country: CountriesEnum): string => {
  let currency = "";

  switch (country) {
    case CountriesEnum.ECUADOR:
      currency = CurrencyEnum.USD;
      break;
    case CountriesEnum.COLOMBIA:
      currency = CurrencyEnum.COP;
      break;
    case CountriesEnum.PERU:
      currency = CurrencyEnum.PEN;
      break;
    case CountriesEnum.CHILE:
      currency = CurrencyEnum.CLP;
      break;
    case CountriesEnum.MEXICO:
      currency = CurrencyEnum.MXN;
      break;
    case CountriesEnum.BRAZIL:
      currency = CurrencyEnum.BRL;
      break;
    case CountriesEnum.PANAMA:
      currency = CurrencyEnum.PAB;
      break;
    case CountriesEnum.COSTA_RICA:
      currency = CurrencyEnum.CRC;
      break;
  }

  return currency;
};

export const validateIcon = (brand: string): object => {
  let brandIcon = <></>;

  switch (brand) {
    case "ALIA":
      brandIcon = (
        <CardIcon
          icon={<CardAlia fontSize={"large"} />}
          background={"#77B41F"}
        />
      );

      break;
    case "BBVA":
      brandIcon = <CardIcon icon={<CardBbva fontSize={"large"} />} />;
      break;
    case "DISCOVER":
      brandIcon = <CardIcon icon={<CardDiscover fontSize={"large"} />} />;
      break;
    case "JBC":
      brandIcon = <CardIcon icon={<CardJbc fontSize={"large"} />} />;
      break;
    case "VISA":
      brandIcon = <CardIcon image={CardVisa} />;
      break;
    case "MASTERCARD":
      brandIcon = <CardIcon image={CardMastercard} />;
      break;
    case "DINERSCLUB":
      brandIcon = <CardIcon icon={<CardDinners fontSize={"large"} />} />;
      break;
    case "AMERICANEXPRESS":
      brandIcon = (
        <CardIcon
          icon={<CardAmex fontSize={"large"} />}
          background={"#006FCF"}
        />
      );
      break;
    case "CARNET":
      brandIcon = <CardIcon icon={<CardCarnet fontSize={"large"} />} />;
      break;
  }

  return brandIcon;
};

export const selectDeferredCountry = (
  deferredInfo: MerchantDeferredResponse[],
  country: string
) => {
  switch (country) {
    case CountriesEnum.ECUADOR:
      return (
        <EcuadorDeferredInfo
          deferredInfo={deferredInfo as EcuadorPeruMerchantDeferredResponse[]}
        />
      );
    case CountriesEnum.MEXICO:
      return (
        <MexicoDeferredInfo
          deferredInfo={deferredInfo as MexicoMerchantDeferredResponse[]}
        />
      );
    case CountriesEnum.PERU:
      return (
        <PeruDeferredInfo
          deferredInfo={deferredInfo as EcuadorPeruMerchantDeferredResponse[]}
        />
      );
    case CountriesEnum.BRAZIL:
      return <BrazilDeferredInfo />;
    default:
      return <></>;
  }
};

export const validateMerchantId = (
  configurationsValues: {
    [key: string]: string;
  },
  config: ConfigEnum,
  publicMerchantId: string,
  basicDataMerchantId: string,
  billingMerchantId: string
): string => {
  let merchantId: string = configurationsValues[config];

  if (
    [
      ConfigEnum.BASIC_DATA,
      ConfigEnum.BILLING_DATA,
      ConfigEnum.CONTACT_DATA,
    ].includes(config)
  )
    if (
      billingMerchantId === publicMerchantId &&
      basicDataMerchantId === publicMerchantId
    )
      merchantId = publicMerchantId;
    else if (billingMerchantId !== publicMerchantId)
      merchantId = billingMerchantId;

  return merchantId;
};
