import React, { PropsWithChildren } from "react";
import Dialog from "@mui/material/Dialog";
import {
  Button,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import { simpleModalWithSelectStyles } from "./SimpleModalWithSelect.styles";
import { ProcessorConstants } from "../../shared/constants/ProcessorConstants";
import { ISimpleModalWithSelectProps } from "./ISimpleModalWithSelectProps.interfaces";
import { ButtonVariantEnum } from "../../shared/enums/ButtonStylesEnum";

export const SimpleModalWithSelect: React.FC<ISimpleModalWithSelectProps> = (
  props: PropsWithChildren<ISimpleModalWithSelectProps>
) => {
  const classes = simpleModalWithSelectStyles();

  return (
    <Dialog
      open={props.isOpenFailoverProcessorModal}
      onClose={props.handleOnCloseFailoverProcessorModal}
      maxWidth={"xl"}
    >
      <DialogTitle className={classes.mainDialog}>
        {ProcessorConstants.SELECT_FAILOVER_PROCESSOR_TITLE}
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth className={classes.formControl}>
          <InputLabel>{ProcessorConstants.SELECT_PROCESSOR}</InputLabel>
          <Select
            label={ProcessorConstants.SELECT_PROCESSOR}
            onChange={props.handleOnChangeFailoverProcessorModalSelect}
            value={props.failoverPerDefault}
          >
            {props.failoverProcessors.map(
              (data: { name: string; value: string }, index: number) => (
                <MenuItem
                  key={`${data.value}-${index}-menuItem`}
                  value={data.value}
                >
                  {data.name}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
        <Grid
          container
          direction={"row"}
          spacing={1}
          textAlign={"end"}
          justifyContent={"end"}
          className={classes.mainGrid}
        >
          <Grid item>
            <Button
              variant={"text"}
              size={"small"}
              disableElevation={true}
              className={classes.cancelButton}
              onClick={props.handleOnCloseFailoverProcessorModal}
            >
              {ProcessorConstants.CANCEL}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant={ButtonVariantEnum.Contained}
              color={"primary"}
              size={"small"}
              onClick={props.handleConfigureFailover}
            >
              {ProcessorConstants.CONFIGURE}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
