import React, { PropsWithChildren } from "react";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { DisableRuleDialogConstants } from "../../shared/infrastructure/constants/SecurityRulesConstants";
import { get } from "lodash";
import { useDialogDisabledRuleState } from "./state/useDialogDisableRuleState";
import { SecurityRule } from "../../../types/security_rule";
import {
  INotification,
  RulesNotificationResultEnum,
} from "../../shared/infrastructure/constants/RulesConstants";
import { SnackbarAlert } from "../SnackBarAlert/SnackBarAlert";
import { RulesAction } from "../../store/actionCreator";

export interface IDialogDisableRuleProps {
  data: SecurityRule;
  handleFinish?: () => void;
  handlerUpdateStatusRule: (rule: SecurityRule, ruleId: string) => void;
  open?: boolean;
  notification?: INotification;
  setNotification?: (payload: INotification) => RulesAction;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: "#023365",
      paddingRight: 102.75,
      paddingTop: 20,
      paddingBottom: 10,
    },
    dialogActions: {
      background: "#FAFCFD !important",
      paddingTop: "30px",
      paddingRight: "20px",
      paddingBottom: "20px",
    },
    primaryButton: {
      background: "#64798D !important",
      borderRadius: "10px !important",
      color: "#FFFFFF !important",
      fontSize: "16px !important",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "100% !important",
      padding: "10px 25px 10px 25px !important",
    },
    primaryButtonDisabled: {
      background: "#B7C4D0 !important",
    },
    secondaryButton: {
      color: "#E24763 !important",
      fontSize: "16px !important",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "100% !important",
      padding: "10px 25px 10px 25px !important",
    },
    closeButton: {
      color: theme.palette.grey[500],
      position: "absolute!important" as "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    principalText: {
      color: "#46525C !important",
      fontSize: "16px !important",
      lineHeight: "140% !important",
    },
    titleContent: {
      color: "#293036",
      fontSize: "16px !important",
      lineHeight: "150% !important",
      paddingBottom: 5,
    },
    padLeft: {
      paddingLeft: 10,
    },
    textField: {
      paddingTop: "20px",
    },
  })
);

export const DialogDisableRule: React.FC<IDialogDisableRuleProps> = (
  props: PropsWithChildren<IDialogDisableRuleProps>
) => {
  const classes = useStyles();
  const { reason, error, actions, count } = useDialogDisabledRuleState(props);

  return (
    <Dialog open={get(props, "open", true)} onClose={props.handleFinish}>
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <Typography variant="h5">{`${DisableRuleDialogConstants.TITLE}`}</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={props.handleFinish}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Typography component="span" className={classes.principalText}>
              {DisableRuleDialogConstants.DESCRIPTION}
            </Typography>
          </Grid>
        </Grid>
        <Grid container xs={12} className={classes.textField}>
          <TextField
            id="text-field"
            className={classes.textField}
            error={error}
            fullWidth
            inputProps={{ maxLength: 200 }}
            multiline
            onChange={(textInput) => actions.handleChangeReason(textInput)}
            placeholder={DisableRuleDialogConstants.TEXT_FIELD}
            variant="outlined"
            rows={5}
          />
        </Grid>
        <Grid
          container
          xs={12}
          direction={"row"}
          justify={"flex-end"}
          alignItems={"flex-end"}
        >
          {count}/200
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={props.handleFinish}
          className={classes.secondaryButton}
        >
          {`${DisableRuleDialogConstants.CANCEL_BUTTON_TEXT}`}
        </Button>
        <Button
          id={"update-button"}
          onClick={() => {
            props.handlerUpdateStatusRule(
              actions.disableRule(reason, actions.setUserName(), props.data),
              get(props, "data.id", "")
            );
          }}
          classes={{ disabled: classes.primaryButtonDisabled }}
          className={classes.primaryButton}
          variant="contained"
          disabled={reason.length === 0}
          disableElevation
        >
          {DisableRuleDialogConstants.ACCEPT_BUTTON_TEXT}
        </Button>
      </DialogActions>
      {get(props, "notification.action") !==
        RulesNotificationResultEnum.NO_ACTION && (
        <SnackbarAlert
          type={get(props, "notification.type")}
          msg={get(props, "notification.message")}
          open={get(props, "notification.open")}
          handlerClose={actions.handleCloseNotification}
          data={props.data}
        />
      )}
    </Dialog>
  );
};
