export enum MessageAlertEnum {
  LOADING_MESSAGE_ENABLING = "Habilitando diferido",
  LOADING_MESSAGE_DISABLING = "Inhabilitando diferido",
  LOADING_MESSAGE_ENABLED = "Activando diferido",
  LOADING_MESSAGE_DISABLED = "Desactivando diferido",
  NODE_INFO_RESPONSE_SUCCESS = "Se obtuvo la información con éxito.",
  UPDATE_STATUS_DEFERRED_REJECTED = "Error del sistema al cargar la información. Inténtalo nuevamente.",
  UPDATE_STATUS_DEFERRED_ENABLING_SUCCESS = "Se habilitó con éxito",
  UPDATE_STATUS_DEFERRED_DISABLING_SUCCESS = "Se inhabilitó con éxito",
  UPDATE_STATUS_DEFERRED_ENABLED_SUCCESS = "Se activó con éxito",
  UPDATE_STATUS_DEFERRED_DISABLED_SUCCESS = "Se desactivó con éxito",
  LOADING_MESSAGE_WAITING = "Este proceso puede tardar unos minutos...",
  LOADING_SAVE_MASSIVE_PROCESSOR_DESCRIPTION = "Este proceso puede tardar un poco...",
  LOADING_SAVE_MASSIVE_PROCESSOR_TITLE = "Guardando procesadores",
}

export enum LocalStorageAlertEnum {
  NOTIFY_SUCCESS = "notifySuccessAlert",
  NOTIFY_ERROR = "notifyErrorAlert",
}

export enum DeferredAlertEnum {
  NO_DECENTRALIZED_DEFERRED = "Para realizar esta configuración todos los branches deben estar descentralizados en diferidos",
}
