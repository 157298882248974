import React from "react";
import {
  Grid,
  Icon,
  Link,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
  withStyles,
} from "@material-ui/core";
import clsx from "clsx";
import {
  InitialStatusEnum,
  StatusEnum,
} from "../../../shared/infrastructure/StatusEnum";
import openIcon from "../../../assets/images/open-icon.svg";
import approveIcon from "../../../assets/images/Icon-Aprobada.svg";
import initializedIcon from "../../../assets/images/Icon-Inicializada.svg";
import modifiedIcon from "../../../assets/images/Icon-Modificada.svg";
import rejectIcon from "../../../assets/images/Icon-Declinada.svg";
import sentIcon from "../../../assets/images/Icon-Enviada.svg";
import { InfoTrxProps } from "../../ModalBodyInfo/ModalBodyRetentionHistoric";
import { findLastIndex, get, has, isEmpty, omit, set } from "lodash";
import {
  DynamoReferenceEnum,
  ElasticReferenceEnum,
  getDynamoElasticRef,
} from "../../../shared/infrastructure/constants/DynamoElasticMap";
import { InvoiceRecord } from "../../../../types/remote/response_search_blling_by_filters";
import {
  StepTitleEnum,
  TitleStatusModal,
  TitleStatusModalReprocess,
} from "../../../shared/infrastructure/StepTitleEnum";
import { formatNumber } from "../../DashboardList/Table/DashboardHistoricTable/DashboardHistoricTableRow";
import { IStepRetentionTimeLine } from "../../../shared/infrastructure/interfaces/IStepRetentionTimeLine";
import { sortTrxList, validateDate } from "../../ModalBodyInfo/utilsModalBody";
import {
  useCustomStepIconStyles,
  useStyles,
} from "../../../shared/infrastructure/TableStyles";
import { BackgroundIconColor } from "../../../shared/infrastructure/Colors";
import { ModalDialogDetail } from "../../ModalDialogDetail/ModalDialogDetail";
import { useSelector } from "react-redux";
import { IBillingDashboardState } from "../../../store/reducer";
import { CountryEnum } from "../../../shared/infrastructure/CountryEnum";

export const CustomConnector = withStyles({
  active: {
    "& $lineVertical": {
      borderColor: "#0DC298",
    },
  },
  completed: {
    "& $lineVertical": {
      borderColor: "#0DC298",
    },
  },
  lineVertical: {
    minHeight: "80px !important",
    backgroundColor: "transparent",
    borderRadius: 1,
    borderLeftWidth: "2px !important",
    margin: "0px 20px !important",
    padding: "0px 8px 0px 0px !important",
  },
  vertical: {
    margin: "0px !important",
    padding: "0px 0px 0px 0px !important",
  },
})(StepConnector);

export function OpenIcon() {
  const classes = useStyles();
  return (
    <Icon>
      <img src={openIcon} alt={openIcon} className={classes.icon} />
    </Icon>
  );
}

export function CustomStepIcon(props: StepIconProps) {
  const classes = useCustomStepIconStyles();
  const icon = get(props, "icon", "") as string;
  const backgroundIcon = get(props, "background", "");

  return (
    <div className={clsx(classes.root)}>
      <img
        src={icon}
        alt={icon}
        style={{
          background: backgroundIcon,
          padding: "7px",
          borderRadius: "10%",
        }}
      />
    </div>
  );
}

export const renderStepTimeLine = (
  trxInfo: InvoiceRecord,
  index: number
): IStepRetentionTimeLine[] => {
  const statusFinal = get(
    trxInfo,
    getDynamoElasticRef(DynamoReferenceEnum.STATUS, index === 0)
  );
  const kind = get(
    trxInfo,
    getDynamoElasticRef(DynamoReferenceEnum.KIND, index === 0)
  );
  const initialStatus = get(
    trxInfo,
    getDynamoElasticRef(DynamoReferenceEnum.INITIAL_STATUS, index === 0)
  );
  const modify = get(
    trxInfo,
    getDynamoElasticRef(
      DynamoReferenceEnum.AMOUNT_CHANGE_INFO_COMMENTS,
      index === 0
    )
  );
  const totalAmount = get(
    trxInfo,
    getDynamoElasticRef(DynamoReferenceEnum.AMOUNT, index === 0)
  );
  const backofficeUser = get(
    trxInfo,
    getDynamoElasticRef(DynamoReferenceEnum.BACKOFFICE_USER, index === 0)
  );
  const country = get(
    trxInfo,
    getDynamoElasticRef(DynamoReferenceEnum.COUNTRY, index === 0)
  );
  const statusRow = get(
    trxInfo,
    getDynamoElasticRef(DynamoReferenceEnum.STATUS_ROW, index === 0)
  );
  let response: IStepRetentionTimeLine[] = [];
  let commonData: IStepRetentionTimeLine = {
    viewDetail: false,
    value: trxInfo,
    isHistoric: index === 0,
    description: backofficeUser,
  };
  let title: string =
    statusRow !== (StatusEnum.REPROCESS || undefined)
      ? TitleStatusModal[kind][statusFinal]
      : TitleStatusModalReprocess[kind][statusFinal];

  const verifyModifiedAmountToRenderStep = () => {
    const data: IStepRetentionTimeLine = {
      ...commonData,
      title: StepTitleEnum.modifiedAmount,
      icon: modifiedIcon,
      backgroundIcon: BackgroundIconColor.modifiedIcon,
      viewDetail: true,
    };
    if (
      trxInfo.hasOwnProperty(ElasticReferenceEnum.AMOUNT_CHANGE_INFO) &&
      !isEmpty(
        get(trxInfo, ElasticReferenceEnum.AMOUNT_CHANGE_INFO_MODIFIED_AMOUNT)
      )
    ) {
      data.amount = get(
        trxInfo,
        `${ElasticReferenceEnum.AMOUNT_CHANGE_INFO_MODIFIED_AMOUNT}.${DynamoReferenceEnum.RET_TOTAL_AMOUNT}`,
        0
      );
      response.push(data);
    } else if (
      trxInfo.hasOwnProperty(ElasticReferenceEnum.AMOUNT_MODIFIED) &&
      !isEmpty(
        `${ElasticReferenceEnum.AMOUNT_MODIFIED}.${ElasticReferenceEnum.MODIFY_AMOUNT}`
      )
    ) {
      data.amount = get(
        trxInfo,
        `${ElasticReferenceEnum.AMOUNT_MODIFIED}.${ElasticReferenceEnum.MODIFY_AMOUNT}`,
        0
      );
      response.push(data);
    } else if (
      trxInfo.hasOwnProperty(ElasticReferenceEnum.MODIFY) &&
      get(
        trxInfo,
        `${DynamoReferenceEnum.MODIFIED_AMOUNTS}.${DynamoReferenceEnum.RET_TOTAL_AMOUNT}`
      )
    ) {
      data.amount = get(
        trxInfo,
        `${DynamoReferenceEnum.MODIFIED_AMOUNTS}.${DynamoReferenceEnum.RET_TOTAL_AMOUNT}`,
        0
      );
      response.push(data);
    }
  };
  switch (statusFinal) {
    //Monto inicial procesado
    case StatusEnum.PENDING:
      if (modify === undefined)
        response = [
          {
            ...commonData,
            title,
            amount: totalAmount,
            icon:
              statusRow !== StatusEnum.REPROCESS ? initializedIcon : rejectIcon,
            backgroundIcon:
              statusRow !== StatusEnum.REPROCESS
                ? BackgroundIconColor.initializedIcon
                : BackgroundIconColor.rejectIcon,
            description: "Software",
          },
        ];
      break;
    //Transaccion Procesada - Monto modificado
    case StatusEnum.PRE_PROCESSED:
      response = [
        {
          ...commonData,
          title,
          icon: statusRow !== StatusEnum.REPROCESS ? initializedIcon : sentIcon,
          backgroundIcon: BackgroundIconColor.initializedIcon,
        },
      ];
      verifyModifiedAmountToRenderStep();
      break;
    case StatusEnum.REPROCESS:
      response = [
        {
          ...commonData,
          title,
          icon: initializedIcon,
          backgroundIcon: BackgroundIconColor.initializedIcon,
        },
      ];
      break;
    //Operacion aprobada
    //Transaccion rechazada
    case StatusEnum.REJECTED:
      if (
        [InitialStatusEnum.APPROVE, InitialStatusEnum.REJECT].includes(
          initialStatus
        )
      )
        response = [
          {
            ...commonData,
            title,
            icon: rejectIcon,
            backgroundIcon: BackgroundIconColor.rejectIcon,
            viewDetail: true,
          },
        ];
      break;
    //Transaccion autorizada
    case StatusEnum.COMPLETED:
      response = [
        {
          ...commonData,
          icon: approveIcon,
          backgroundIcon: BackgroundIconColor.approveIcon,
          title,
          amount: totalAmount,
          viewDetail: true,
        },
      ];
      break;
    //Transaccion aprobada parcialmente
    case StatusEnum.PARTIALLY_COMPLETED:
      response = [
        {
          ...commonData,
          icon: modifiedIcon,
          backgroundIcon: BackgroundIconColor.modifiedIcon,
          title,
          amount: totalAmount,
          viewDetail: true,
        },
      ];
      break;
    //Transaccion anulada
    case StatusEnum.DELETED:
      response = [
        {
          ...commonData,
          title,
          icon: rejectIcon,
          backgroundIcon: BackgroundIconColor.rejectIcon,
          viewDetail: true,
        },
      ];
      break;
    //Transaccion omitida
    case StatusEnum.OMITTED:
      response = [
        {
          ...commonData,
          title,
          icon: initializedIcon,
          backgroundIcon: BackgroundIconColor.initializedIcon,
          viewDetail: !!(
            country === CountryEnum.colombia &&
            [InitialStatusEnum.OMITTED].includes(initialStatus)
          ),
        },
      ];
      verifyModifiedAmountToRenderStep();
      break;
    //Transaccion liquidada
    case StatusEnum.FINISHED:
      response = [
        {
          ...commonData,
          title,
          icon: approveIcon,
          backgroundIcon: BackgroundIconColor.approveIcon,
          amount: totalAmount,
        },
      ];
      break;
    //Transaccion manual
    case StatusEnum.MANUAL:
      response = [
        {
          ...commonData,
          title,
          icon: approveIcon,
          backgroundIcon: BackgroundIconColor.modifiedIcon,
        },
      ];
      break;
    //Transaccion failed process
    case StatusEnum.FAILED_PROCESS:
      response = [
        {
          ...commonData,
          icon: rejectIcon,
          backgroundIcon: BackgroundIconColor.rejectIcon,
          title: StepTitleEnum.processFaild,
        },
      ];
      break;
    //Transaccion emitida
    case StatusEnum.EMITTED:
      response = [
        {
          ...commonData,
          title,
          icon: approveIcon,
          backgroundIcon: BackgroundIconColor.approveIcon,
          amount: totalAmount,
        },
      ];
      break;
    //Transaccion en proceso
    case StatusEnum.IN_REVIEW:
      response = [
        {
          ...omit(commonData, ["description"]),
          title,
          icon: approveIcon,
          backgroundIcon: BackgroundIconColor.approveIcon,
        },
      ];
      break;
    default:
      //Transaccion rechazada kushki - detail
      if (initialStatus === InitialStatusEnum.REJECT)
        response = [
          {
            ...commonData,
            icon: rejectIcon,
            backgroundIcon: BackgroundIconColor.rejectIcon,
            title: StepTitleEnum.rejectTransaction,
          },
        ];
  }
  return response;
};

export const TimeLineTabHistoricComponent = (props: InfoTrxProps) => {
  const classes = useStyles();
  const CustomConnector = withStyles({
    completed: {
      "& $lineVertical": {
        borderColor: "#0DC298",
      },
    },
    lineVertical: {
      minHeight: "42px",
      backgroundColor: "transparent",
      borderRadius: 1,
      borderLeftStyle: "dotted",
      borderLeftWidth: "2px",
      margin: "0px 15px",
    },
    vertical: {
      margin: "0px",
      padding: "0px 0px 0px 0px",
    },
  })(StepConnector);
  let renderSteps: IStepRetentionTimeLine[] = [];
  sortTrxList(
    get(props.trxInfo, DynamoReferenceEnum.OLD_TRANSACTIONS, []),
    props.trxInfo,
    true
  ).map((data: InvoiceRecord, index: number) => {
    return renderStepTimeLine(
      data,
      index
    ).map((response: IStepRetentionTimeLine) => renderSteps.push(response));
  });

  const paymentsInitialData = get(props.trxInfo, "payments", []);

  const { openModalDetailTimeLine } = useSelector(
    (state: IBillingDashboardState) => state
  );
  const indexInitialAmount = renderSteps.findIndex(
    (step) => step.title === StepTitleEnum.initialAmountProcessed
  );

  if (
    renderSteps.some(
      (step) => step.title === StepTitleEnum.initialAmountProcessed
    ) &&
    renderSteps.length > 2
  ) {
    const tempSwap = renderSteps[renderSteps.length - 1];
    renderSteps[renderSteps.length - 1] = renderSteps[indexInitialAmount];
    renderSteps[indexInitialAmount] = tempSwap;
  }

  const dateValidation = (
    value: InvoiceRecord | undefined,
    index: number
  ): string => {
    return validateDate(
      get(
        value,
        getDynamoElasticRef(DynamoReferenceEnum.UPDATED_AT, index === 0),
        get(value, ElasticReferenceEnum.UPDATED_AT, "")
      )!
    );
  };
  for (let i = 0; i < renderSteps.length; i++) {
    set(
      renderSteps[i],
      "value.auxDate",
      get(
        renderSteps[i].value,
        "updatedAt",
        get(renderSteps[i].value, "updated_at")
      )
    );
  }
  renderSteps = renderSteps.sort(
    (
      firstObject: IStepRetentionTimeLine,
      secondObject: IStepRetentionTimeLine
    ) =>
      get(firstObject.value, "auxDate") < get(secondObject.value, "auxDate")
        ? 1
        : -1
  );
  return (
    <React.Fragment>
      <Grid container className={classes.grid} xs={12}>
        <Grid item xs={2} className={"statusGrid"}>
          <Stepper
            orientation={"vertical"}
            connector={<CustomConnector />}
            activeStep={findLastIndex(renderSteps, [
              StatusEnum.COMPLETED,
              true,
            ])}
            className={classes.stepper}
          >
            {renderSteps.map(
              (response: IStepRetentionTimeLine, index: number) => (
                <Step key={index}>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <CustomStepIcon
                        {...props}
                        background={response.backgroundIcon}
                      />
                    )}
                    icon={response.icon}
                  />
                </Step>
              )
            )}
          </Stepper>
        </Grid>
        <Grid item xs={5} className={"infoGrid"}>
          <Grid container className={classes.gridPadding}>
            {renderSteps.map(
              (response: IStepRetentionTimeLine, index: number) => (
                <Grid item xs={12} key={index} className={classes.gridHeight}>
                  <Grid item xs={12}>
                    <Typography variant="body2" className={classes.title}>
                      {response.title}
                    </Typography>
                    {has(response, "subtitle") && (
                      <Typography
                        variant="subtitle2"
                        className={classes.subtitle}
                      >
                        {response.subtitle}
                      </Typography>
                    )}
                    {has(response, "description") && (
                      <Typography
                        variant="body2"
                        className={classes.description}
                      >
                        {response.description}
                      </Typography>
                    )}
                    {has(response, "amount") && (
                      <Typography variant="overline" color={"textSecondary"}>
                        Monto total:{" "}
                        {formatNumber(
                          get(response, "amount", 0),
                          get(
                            response.value,
                            "currency_code",
                            get(response.value, "currency", "USD")
                          )
                        )}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              )
            )}
          </Grid>
        </Grid>
        <Grid item xs={2} className={"infoGrid"}>
          <Grid container className={classes.gridPadding}>
            {renderSteps.map(
              (response: IStepRetentionTimeLine, index: number) => (
                <Grid item xs={12} key={index} className={classes.gridHeight}>
                  <Typography variant="body2" className={classes.title}>
                    {dateValidation(response.value, index).split("T")[0]}{" "}
                  </Typography>
                  <Typography variant="body2" className={classes.subtitle}>
                    {
                      dateValidation(response.value, index)
                        .split("T")[1]
                        .split(".")[0]
                    }
                  </Typography>
                </Grid>
              )
            )}
          </Grid>
        </Grid>
        <Grid item xs={3} className={"infoGrid"}>
          <Grid container className={classes.gridPadding}>
            {renderSteps.map(
              (response: IStepRetentionTimeLine, index: number) => (
                <Grid
                  container
                  item
                  xs={12}
                  key={index}
                  className={classes.gridHeight}
                >
                  {get(response, "viewDetail", false) &&
                    paymentsInitialData != 0 && (
                      <Link
                        component="button"
                        variant={"overline"}
                        onClick={() => props.handleOpenDialogDetail!(response)}
                      >
                        <Grid
                          container
                          xs={12}
                          direction={"column"}
                          alignItems={"center"}
                          justify={"flex-start"}
                          className={classes.gridIcon}
                        >
                          <Grid item xs={12}>
                            <OpenIcon />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant={"overline"}>
                              Ver detalle
                            </Typography>
                          </Grid>
                        </Grid>
                      </Link>
                    )}
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      </Grid>
      <ModalDialogDetail
        open={openModalDetailTimeLine}
        handleCloseDialogDetail={props.handleCloseDialogDetail}
        trxInfo={props.trxInfo}
      />
    </React.Fragment>
  );
};
