import {
  ModalContentButtonTypeEnum,
  ModalContentTypeEnum,
} from "@kushki/connect-ui/dist/Components/Molecules/Modal/Content/enums";

export const DIALOG_MESSAGE = {
  BUTTON_CANCEL: "Cancelar",
  BUTTON_SAVE: (type_schema: string) => `Solicitar ${type_schema}`,
  CHARGEBACK_INSTRUCTIONS:
    "Ingresa el correo electrónico del operador del procesador que te ha notificado el contracargo",
  DEFAULT: ModalContentTypeEnum.DEFAULT,
  DIALOG_TITLE_TEXT: (type: string, type_schema: string) =>
    `¿Deseas solicitar ${type} ${type_schema} de esta operación?`,
  DUPLICATED_MAIL: "Correo electrónico duplicado",
  MAIL: "Correo electrónico",
  PRIMARY: ModalContentButtonTypeEnum.PRIMARY,
  REQUIRED_FIELD: "Campo requerido",
  START_CHARGEBACK: "Iniciar contracargo",
  VALID_MAIL: "Por favor, introduce un correo electrónico válido",
};
