export enum DeferredOptionPath {
  MERCHANT_DEFERRED_OPTIONS = "merchantDeferred.deferredOptions",
  DEFERRED_OPTION = "deferredOption",
  DEFERRED_OPTIONS = "deferredOptions",
  GRACE_MONTHS = "monthsOfGrace",
  MERCHANT_MONTHS = "merchantMonths",
  MERCHANT_STATUS = "merchantStatus",
  MONTHS = "months",
  PROCESSOR_NAME = "processorName",
  START_DATE = "startDate",
  END_DATE = "endDate",
}

export enum DeferredTypePath {
  PROCESSOR_NAME = "processorName",
  CODE = "code",
}

export enum DeferredEnum {
  banksProps = "rowData.banks",
}

export enum LenghtPaths {
  localDeferredOptionLength = "localDeferredOptions.length",
}

export enum BreadcrumbValues {
  DEFERRED = "diferido",
  LABEL_DEFERRED = "Diferidos",
  LABEL_MERCHANTS = "Comercios",
}
