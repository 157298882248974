import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./CatalogConfirmDialog.styles";
import { CatalogConfirmDialogProps } from "./CatalogConfirmDialog.interfaces";
import { useCatalogConfirmDialogState } from "./state/useCatalogConfirmDialogState";
import { CatalogsTextEnum } from "../../shared/infrastructure/Enums/CatalogsTextEnum";
import {
  getCatalogPropertyById,
  verifyExistsItemCatalog,
} from "../../utils/functions/Catalog";
import { defaultTo, get } from "lodash";
import {
  ActionEnum,
  ButtonNameCatalogConfirm,
} from "../../shared/infrastructure/Enums/ActionEnum";
import {
  CatalogPropertyEnum,
  CatalogsRecord,
} from "../../shared/infrastructure/Enums/CatalogEnum";
import { CatalogDownloadReport } from "../CatalogDownloadReport/CatalogDownloadReport";

export const CatalogConfirmDialog: React.FC<CatalogConfirmDialogProps> = (
  props: CatalogConfirmDialogProps
) => {
  const classes = useStyles();
  const { catalogRequest, catalogsList, isOpenPopup, isLoading } = props;
  const {
    base64CsvFile,
    handleCancelOption,
    handleUpdateItemCatalog,
    handleClosePopup,
    handleRetentionListByType,
  } = useCatalogConfirmDialogState();
  const action: string = defaultTo(get(catalogRequest, "action"), "");
  const catalogId: string = get(catalogRequest, CatalogPropertyEnum.ID);
  const { itemCatalog } = verifyExistsItemCatalog(
    catalogsList,
    CatalogPropertyEnum.ID,
    catalogId,
    defaultTo(get(catalogRequest, CatalogPropertyEnum.CODE), "")
  );
  const catalogName: string = getCatalogPropertyById(
    catalogsList,
    CatalogPropertyEnum.NAME,
    "",
    catalogId
  );
  const catalogNameLowerCase: string = defaultTo(
    CatalogsRecord[catalogName],
    ""
  ).toLowerCase();

  useEffect(() => {
    if (action === ActionEnum.DELETE) handleRetentionListByType(catalogName);
  }, []);

  return (
    <Dialog
      open={isOpenPopup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"md"}
    >
      {action === ActionEnum.UPDATE && (
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5" color="primary">
                {CatalogsTextEnum.TITLE_CONFIRM}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.subtitle}>
                {CatalogsTextEnum.SUBTITLE_CONFIRM}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" color="primary">
                {CatalogsTextEnum.TEXT_CONFIRM}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.header}>
                {CatalogsTextEnum.CODE}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography className={classes.header}>
                {CatalogsTextEnum.DESCRIPTION}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.description}>
                {defaultTo(get(itemCatalog, CatalogPropertyEnum.CODE), "")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography className={classes.description}>
                {defaultTo(get(itemCatalog, CatalogPropertyEnum.VALUE), "")}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      )}
      {action === ActionEnum.DELETE && (
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5" color="primary">
                ¿Deseas eliminar la opción del catálogo de{" "}
                {catalogNameLowerCase} de la fila seleccionada?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.subtitle}>
                Se eliminará definitivamente. Este paso no se puede revertir.
                <br />
                Recuerde que al eliminar la opción actual del catálogo de{" "}
                {catalogNameLowerCase} se quitará la opción en la configuración
                de comercios.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CatalogDownloadReport base64Csv={base64CsvFile} />
            </Grid>
          </Grid>
        </DialogContent>
      )}

      <DialogActions className={classes.actions}>
        {action === ActionEnum.UPDATE && (
          <Button
            color="primary"
            className={classes.btnChangeOption}
            onClick={handleCancelOption}
            disabled={isLoading}
          >
            {CatalogsTextEnum.CHANGE_OPTION}
          </Button>
        )}
        {action === ActionEnum.DELETE && (
          <Button
            color="primary"
            className={classes.btnChangeOption}
            onClick={handleClosePopup}
            disabled={isLoading}
          >
            {CatalogsTextEnum.CANCEL}
          </Button>
        )}
        <Button
          color={"primary"}
          className={classes.btnReplace}
          onClick={handleUpdateItemCatalog}
          disabled={isLoading}
        >
          {action === ActionEnum.UPDATE
            ? "Reemplazar"
            : ButtonNameCatalogConfirm[action as ActionEnum]}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
