import React, { FC, useState } from "react";
import { Box, TablePagination } from "@mui/material";
import { PaginationSimpleStyle } from "./PaginationSimple.styles";
import { IPaginationSimpleProps } from "./PaginationSimple.interfaces";

/**
 * Custom PaginationSimple component. Do not use directly, use the `PaginationSimple` component instead from `@kushki/connect-ui`
 */
export const PaginationSimple: FC<IPaginationSimpleProps> = (
  props: IPaginationSimpleProps
) => {
  const [page, setPage] = useState(0);

  const onPageChange = (
    //@ts-ignore
    _,
    newPage: number
  ) => {
    if (newPage > page) {
      props.handleNextPage();
    } else {
      props.handlePreviousPage();
    }
    setPage(newPage);
  };

  return (
    <>
      {!(props.isNextDisabled && page === 0) && (
        <Box
          sx={{
            padding: "20px 40px",
          }}
          data-testid="pagination"
        >
          <Box
            data-testid="pagination-simple"
            sx={PaginationSimpleStyle.paginationBox}
          >
            <TablePagination
              sx={PaginationSimpleStyle.tablePagination}
              backIconButtonProps={{
                sx: PaginationSimpleStyle.button,
              }}
              nextIconButtonProps={{
                ...(props.isNextDisabled && { disabled: true }),
                sx: PaginationSimpleStyle.button,
              }}
              component={"div"}
              labelRowsPerPage={""}
              count={-1}
              page={page}
              size={"small"}
              labelDisplayedRows={() => ""}
              onPageChange={onPageChange}
              rowsPerPage={-1}
            />
          </Box>
        </Box>
      )}
    </>
  );
};
