import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const branchManualCreationFormStyles = createNamedStyles({
  boxContent: {
    display: "flex",
    height: "45%",
    alignItems: "center",
    mt: (theme) => theme.spacing(12),
    width: "40%",
  },
  boxContentTable: {
    display: "flex",
    height: "45%",
    alignItems: "center",
    mt: "40px",
    width: "80%",
  },
  contentTable: {
    height: "fit-content",
  },
  boxContentAddBranch: {
    mt: (theme) => theme.spacing(3),
    mb: "17vh",
    display: "flex",
    width: "100%",
  },
  boxTableBranch: {
    maxHeight: "300px !important",
    height: 1,
    display: "inline-table",
    paddingBottom: 20,
  },
  title: {
    fontSize: "24px !important",
    lineHeight: "120%",
    pb: 6,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: "13px !important",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "120%",
    alignSelf: "self-start",
    p: (theme) => theme.spacing(3, 0, 3, 0),
  },
  detail: {
    alignSelf: "self-start",
    fontSize: (theme) => theme.spacing(2),
    fontWeight: 500,
    pb: 5,
  },
  boxUl: {
    color: "#023365",
    fontSize: (theme) => theme.spacing(2),
    fontWeight: 400,
    m: 0,
    p: (theme) => theme.spacing(0, 0, 7.5, 2.8),
  },
  boxSelect: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    width: 1,
  },
  nextButton: {
    fontSize: (theme) => theme.spacing(1.75),
    width: "100%",
  },
  labelTitle: {
    fontSize: "26px !important",
    fontWeight: 600,
    color: "#023365",
  },
  labelSubtitle: {
    fontSize: "16px !important",
    p: (theme) => theme.spacing(0, 0.5, 2, 0),
    color: "#023365",
  },
  boxFooter: {
    zIndex: "1000",
    width: "100%",
    position: "fixed",
    bottom: "0",
  },
  boxConfirmModal: {
    display: "flex",
    justifyContent: "center",
  },
});
