import React from "react";
import ModalPartnerBanks from "./ModalPartnerBanks/ModalPartnerBanks";
import { useDeferredSection } from "./state/useDeferredSection";
import { TableDeferred } from "./Table/TableDeferred";
import { SkeletonTableContainer } from "../Skeletons/SkeletonTableContainer/SkeletonTableContainer";
import { Typography } from "@material-ui/core";
import { CountriesEnum } from "../../shared/infrastructure/constants/countries-enum";

export const DeferredSection: React.FC = () => {
  const state = useDeferredSection();
  return (
    <>
      {state.merchantCountry === CountriesEnum.PERU ? (
        <Typography>Diferidos activados para este comercio</Typography>
      ) : (
        <>
          {" "}
          {state.isDeferredLoaded ? (
            <SkeletonTableContainer rows={2} cells={4} />
          ) : (
            <TableDeferred
              dataRows={state.deferred!}
              dataHeaders={state.dataHeadersTable}
              handleSetBank={state.handleSetBanks}
              setCodeToProcessor={state.setCodeToProcessor}
              setCodeToTextDeferred={state.setCodeToTextDeferred}
              merchantCountry={state.merchantCountry}
            />
          )}
        </>
      )}

      <ModalPartnerBanks
        open={state.openModal}
        handleClose={state.handleOpenModal}
        banks={state.banks}
      />
    </>
  );
};
