import React from "react";
import { Box, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { validationDetailSkeletonStyles } from "./ValidationDetailSkeleton.styles";

export const ValidationDetailSkeleton = () => {
  const classes = validationDetailSkeletonStyles();

  return (
    <Grid container spacing={2} className={classes.mainGrid}>
      <Grid item xs={12}>
        <Grid
          container
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={3}
        >
          <Grid item xs={12}>
            <Box justifyContent={"center"}>
              <Skeleton
                variant="circle"
                animation={"wave"}
                width={250}
                height={250}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Skeleton
              variant="rect"
              animation={"wave"}
              width={150}
              height={20}
            />
          </Grid>

          <Grid item xs={12}>
            <Skeleton
              variant="rect"
              animation={"wave"}
              width={210}
              height={150}
            />
          </Grid>

          <Grid item xs={12}>
            <Skeleton
              variant="rect"
              animation={"wave"}
              width={210}
              height={150}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
