import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/hooks/hooks";
import { setDateFilter } from "../../../../../store/actions/filters/filters";
import { selectDateFilter } from "../../../../../store/selectors/filters/filters";
import { IDateFilter } from "../../../../../store/interfaces/FiltersState.interfaces";
import { IDateTime } from "../../../../common/DateTimeRangePicker/DateTimeRangePicker.interfaces";
import { IUseDateFilterState } from "./useDateFilterState.interfaces";

export const useDateFilterState = (): IUseDateFilterState => {
  const dispatch = useAppDispatch();
  const { from, to } = useAppSelector(selectDateFilter);

  const handleSetDateFilter = ({ from, to }: IDateTime) => {
    const dateFilter: IDateFilter = {
      from,
      timeZone: "",
      to,
    };

    dispatch(setDateFilter(dateFilter));
  };

  return {
    handleSetDateFilter,
    rangeDate: { from, to },
  };
};
