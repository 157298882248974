import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FrequencyEnum } from "../../../shared/enums/frequencyEnum";
import { InvoiceModeEnum } from "../../../shared/enums/InvoiceModeEnum";
import { BillingForm, BillingStore } from "../../interfaces/billing.interfaces";
import {
  getBillingConfig,
  getMerchantNodeInfo,
} from "../../thunks/app/app.thunks";
import { billingConfigInitialState } from "../../../shared/constants/initial_billing_config_state";
import { ICheckboxObject } from "../../../components/TransactionForm/state/useTransactionSection.interfaces";

export const initialState: BillingStore = {
  form: {
    invoiceFrequency: FrequencyEnum.NONE,
    invoiceMode: InvoiceModeEnum.WITH_IVA,
  },
  isLoadingBillingInformation: true,
};

export const billingSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getBillingConfig.fulfilled, (state, { payload }) => {
        state.getBillingConfigInfo = payload;
      })
      .addCase(getBillingConfig.rejected, (state) => {
        state.getBillingConfigInfo = billingConfigInitialState;
      })
      .addCase(getMerchantNodeInfo.fulfilled, (state) => {
        state.isLoadingBillingInformation = false;
      })
      .addCase(getMerchantNodeInfo.pending, (state) => {
        state.isLoadingBillingInformation = true;
      })
      .addCase(getMerchantNodeInfo.rejected, (state) => {
        state.isLoadingBillingInformation = false;
      });
  },
  initialState,
  name: "billing",
  reducers: {
    clearAllValuesBilling: (state) => {
      state.valuesBillingState = undefined;
      state.valuesSubtractBillingState = undefined;
      state.getBillingConfigInfo = undefined;
    },
    setIsLoadingBillingInformation: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLoadingBillingInformation = payload;
    },
    updateForm: (state, { payload }: PayloadAction<BillingForm>) => {
      state.form = payload;
    },
    updateValuesBilling: (
      state,
      {
        payload,
      }: PayloadAction<{
        checkBillItems: ICheckboxObject[];
        isModify: boolean;
        statusApproved: boolean;
      }>
    ) => {
      state.valuesBillingState = payload;
    },
    updateValuesDeclinedBilling: (
      state,
      {
        payload,
      }: PayloadAction<{
        checkDeclinedItems: ICheckboxObject[];
        statusDeclined: boolean;
      }>
    ) => {
      state.valuesDeclinedBillingState = payload;
    },
    updateValuesSubtractBilling: (
      state,
      {
        payload,
      }: PayloadAction<{
        checkSubtractItems: ICheckboxObject[];
        statusApproved: boolean;
      }>
    ) => {
      state.valuesSubtractBillingState = payload;
    },
  },
});

export default billingSlice.reducer;
