export enum ErrorCodeEnum {
  AUT006 = "AUT006",
  CHARGEBACK = "CHARGEBACK",
  DEFAULT = "DEFAULT",
}
export type ErrorCode = {
  code: string;
  message: string;
};

export const errorCodes: ErrorCode[] = [
  {
    code: ErrorCodeEnum.AUT006,
    message: "No es posible realizar ninguna acción en modo Asistencia Remota",
  },
  {
    code: ErrorCodeEnum.CHARGEBACK,
    message:
      "Surgió un problema al iniciar el contracargo. Intenta nuevamente.",
  },
  {
    code: ErrorCodeEnum.DEFAULT,
    message: "¡Algo salió mal! No pudimos eliminar el usuario",
  },
];

export const mapErrors = (code: string): string => {
  switch (code) {
    case ErrorCodeEnum.AUT006:
      return errorCodes[0].message;
    case ErrorCodeEnum.CHARGEBACK:
      return errorCodes[1].message;
    default:
      return errorCodes[errorCodes.length - 1].message;
  }
};
