/**
 * Payment Method Enumeration
 */
export enum PaymentMethodEnum {
  CASH = "Cash",
  CARD = "Card",
  TRANSFER = "Transfer",
}

export const PAYMENT_METHODS_LIST: string[] = [
  PaymentMethodEnum.CASH,
  PaymentMethodEnum.CARD,
  PaymentMethodEnum.TRANSFER,
];
