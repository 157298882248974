import React from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  removeLegalRepresentative,
  setOpenModal,
} from "../../../store/reducers/legalDetails/legalDetails";
import ModalForm from "../ModalForm/ModalForm";
import { RootState } from "../../../store/store";
import { LegalRepresentativeSectionProps } from "./LegalRepresentativeSection.interfaces";
import AccordionItems from "../AccordionItems/AccordionItems";
import { ILegalRepresentative } from "../../../store/interfaces/LegalDetailsState.interfaces";
import { legalRepresentativeSectionStyle as styles } from "./LegalRepresentativeSection.styles";
import { LEGAL_TITLE } from "../../../shared/constants/shareholder_constants";
import SectionTitle from "../../SectionTitle/SectionTitle";
import { ComponentIdsEnum } from "../../../shared/constants/labels/main_containter_labels";
import { CountryEnum } from "../../../shared/countryEnum";
import { get, orderBy } from "lodash";

const LegalRepresentativeSection: React.FC<LegalRepresentativeSectionProps> = ({
  title,
  description,
  buttonText,
  formComponent,
  modalSize,
  isOpenModal,
  isDisable = false,
  personType,
}: LegalRepresentativeSectionProps) => {
  const { legalRepresentative, nodeInfo, disabledInputs } = useSelector(
    (state: RootState) => ({
      ...state.initialData,
      ...state.legalDetails,
    })
  );

  const constitutionalCountry: CountryEnum = get(
    nodeInfo,
    "generalInfo.constitutionalCountry",
    CountryEnum.generic
  );

  const dispatch = useDispatch();

  const handleOpenModal = () => {
    dispatch(setOpenModal(true));
  };

  const removeRepresentative = (item: ILegalRepresentative) => {
    const representatives = [
      ...legalRepresentative
        .filter((representative) => representative.id !== item.id)
        .map((item, index) => (index === 0 ? { ...item, isMain: true } : item)),
    ];

    dispatch(removeLegalRepresentative(representatives));
  };

  return (
    <Box sx={styles.container}>
      <SectionTitle
        buttonText={buttonText}
        componentId={ComponentIdsEnum.M_CLIENTS_ADD_LEGAL_REPRESENTATIVE_BUTTON}
        handleOnClick={handleOpenModal}
        isDisabled={isDisable}
        title={title}
      />
      <Box>
        <Typography variant="caption" color="text.lightGrey">
          {description}
        </Typography>
        {title === LEGAL_TITLE &&
          orderBy(legalRepresentative, ["isMain"], ["desc"]).map(
            (element: ILegalRepresentative) => (
              <Box key={element.documentNumber} padding={2}>
                <AccordionItems
                  constitutionalCountry={constitutionalCountry}
                  items={element}
                  personType={personType}
                  handleRemoveRepresentative={removeRepresentative}
                  disableMenuAction={disabledInputs}
                />
              </Box>
            )
          )}
      </Box>
      <ModalForm modalSize={modalSize} openModal={isOpenModal}>
        {formComponent}
      </ModalForm>
    </Box>
  );
};

export default LegalRepresentativeSection;
