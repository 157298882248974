export const HEADERS_NAME_INPUT_FILE: Record<string, string> = {
  ["A"]: "Rut Beneficiario",

  ["B"]: "Dv",

  ["C"]: "Nombre Beneficiario",

  ["D"]: "Medio de Pago",

  ["E"]: "Banco",

  ["F"]: "Cuenta Corriente o vista",

  ["G"]: "Monto",

  ["H"]: "Descripcion del Pago",

  ["I"]: "Email Beneficiario",

  ["J"]: "Acumula Vale Vista",
};
