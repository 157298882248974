import React from "react";
import { Tooltip } from "@mui/material";
import { TooltipEnum } from "../../shared/constants/CatalogConfigTableBranch";

type CustomTooltipProps = {
  label: string;
};

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  label,
}: CustomTooltipProps) => {
  return (
    <Tooltip
      title={
        <span style={{ color: "#fff", whiteSpace: "pre" }}>
          {TooltipEnum.STATUS}
        </span>
      }
      placement="top"
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: "none",
            textAlign: "left !important",
          },
        },
      }}
    >
      <span>{label}</span>
    </Tooltip>
  );
};

export default CustomTooltip;
