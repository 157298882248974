import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

const TableBodyStyles = createNamedStyles({
  body: {
    tr: {
      backgroundColor: "rgb(255, 255, 255)",
      borderCollapse: "separate",
      borderSpacing: "0px 8px",
      color: "inherit",
      display: "table-row",
      outline: "0px",
      verticalAlign: "middle",
      width: "100%",
    },
    "tr:hover td": {
      borderBottom: "1px solid #3879cd",
      borderBottomStyle: "outset",
      borderTop: "1px solid #3879cd",
      borderTopStyle: "outset",
    },
    "tr:hover td:first-of-type": {
      borderBottom: "1px solid #3879cd",
      borderBottomLeftRadius: "8px",
      borderBottomStyle: "outset",
      borderLeft: "1px solid #3879cd",
      borderLeftStyle: "outset",
      borderTop: "1px solid #3879cd",
      borderTopLeftRadius: "8px",
      borderTopStyle: "outset",
    },
    "tr:hover td:last-child": {
      borderBottom: "1px solid #3879cd",
      borderBottomRightRadius: "8px",
      borderBottomStyle: "outset",
      borderRight: "1px solid #3879cd",
      borderRightStyle: "outset",
      borderTop: "1px solid #3879cd",
      borderTopRightRadius: "8px",
      borderTopStyle: "outset",
    },
  },
  row: {
    "&.MuiTableRow-root": {
      td: {
        borderBottom: "1px solid #a0abba63",
        borderBottomStyle: "outset",
        borderTop: "1px solid #a0abba63",
        borderTopStyle: "outset",
      },
      "td:first-of-type": {
        borderBottom: "1px solid #a0abba63",
        borderBottomLeftRadius: "8px",
        borderBottomStyle: "outset",
        borderLeft: "1px solid #a0abba63",
        borderLeftStyle: "outset",
        borderTop: "1px solid #a0abba63",
        borderTopLeftRadius: "8px",
        borderTopStyle: "outset",
      },
      "td:last-child": {
        borderBottom: "1px solid #a0abba63",
        borderBottomRightRadius: "8px",
        borderBottomStyle: "outset",
        borderRight: "1px solid #a0abba63",
        borderRightStyle: "outset",
        borderTop: "1px solid #a0abba63",
        borderTopRightRadius: "8px",
        borderTopStyle: "outset",
      },
    },
    "&.MuiTableRow-root.Mui-selected": {
      td: {
        backgroundColor: "#fbfcfe",
        borderBottom: "1px solid #3879cd",
        borderBottomStyle: "outset",
        borderTop: "1px solid #3879cd",
        borderTopStyle: "outset",
      },
      "td:first-of-type": {
        borderBottom: "1px solid #3879cd",
        borderBottomLeftRadius: "8px",
        borderBottomStyle: "outset",
        borderLeft: "1px solid #3879cd",
        borderLeftStyle: "outset",
        borderTop: "1px solid #3879cd",
        borderTopLeftRadius: "8px",
        borderTopStyle: "outset",
      },
      "td:last-child": {
        borderBottom: "1px solid #3879cd",
        borderBottomRightRadius: "8px",
        borderBottomStyle: "outset",
        borderRight: "1px solid #3879cd",
        borderRightStyle: "outset",
        borderTop: "1px solid #3879cd",
        borderTopRightRadius: "8px",
        borderTopStyle: "outset",
      },
    },
    "&.MuiTableRow-root.MuiTableRow-hover:hover": {
      backgroundColor: "#fff",
    },
  },
  rowDisabled: {
    "&.MuiTableRow-root": {
      td: {
        backgroundColor: "#efefef",
        borderBottom: "1px solid #a0abba63",
        borderBottomStyle: "outset",
        borderTop: "1px solid #a0abba63",
        borderTopStyle: "outset",
        opacity: "0.5",
      },
      "td:first-of-type": {
        borderBottom: "1px solid #a0abba63",
        borderBottomLeftRadius: "8px",
        borderBottomStyle: "outset",
        borderLeft: "1px solid #a0abba63",
        borderLeftStyle: "outset",
        borderTop: "1px solid #a0abba63",
        borderTopLeftRadius: "8px",
        borderTopStyle: "outset",
      },
      "td:last-child": {
        borderBottom: "1px solid #a0abba63",
        borderBottomRightRadius: "8px",
        borderBottomStyle: "outset",
        borderRight: "1px solid #a0abba63",
        borderRightStyle: "outset",
        borderTop: "1px solid #a0abba63",
        borderTopRightRadius: "8px",
        borderTopStyle: "outset",
      },
    },
    "&.MuiTableRow-root.Mui-selected": {
      td: {
        backgroundColor: "#fbfcfe",
        borderBottom: "1px solid #3879cd",
        borderBottomStyle: "outset",
        borderTop: "1px solid #3879cd",
        borderTopStyle: "outset",
      },
      "td:first-of-type": {
        borderBottom: "1px solid #3879cd",
        borderBottomLeftRadius: "8px",
        borderBottomStyle: "outset",
        borderLeft: "1px solid #3879cd",
        borderLeftStyle: "outset",
        borderTop: "1px solid #3879cd",
        borderTopLeftRadius: "8px",
        borderTopStyle: "outset",
      },
      "td:last-child": {
        borderBottom: "1px solid #3879cd",
        borderBottomRightRadius: "8px",
        borderBottomStyle: "outset",
        borderRight: "1px solid #3879cd",
        borderRightStyle: "outset",
        borderTop: "1px solid #3879cd",
        borderTopRightRadius: "8px",
        borderTopStyle: "outset",
      },
    },
    "&.MuiTableRow-root.MuiTableRow-hover:hover": {
      backgroundColor: "#fff",
    },
  },
  rowHidden: {
    "&.MuiTableRow-root": { display: "none" },
  },
  subtitle: {
    color: "rgb(109, 119, 129)",
    fontFamily: "IBM Plex Sans",
    fontSize: "13px",
    fontWeight: "500",
    hoverflow: "hidden",
    hoverflowWrap: "break-word",
    letterSpacing: "0.0025em",
    lineHeight: "120%",
    margin: "0px",
    texthoverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  title: {
    color: "rgb(41, 48, 54)",
    cursor: "pointer",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontWeight: "500",
    hoverflow: "hidden",
    hoverflowWrap: "break-word",
    letterSpacing: "0.0025em",
    lineHeight: "150%",
    margin: "0px",
    texthoverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

export { TableBodyStyles };
