import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const billingInfoStyles = createNamedStyles({
  boxCurrency: {
    alignItems: "center",
    display: "flex",
    flexWrap: "nowrap",
    width: "100%",
  },
  contentGrid: {
    margin: 0,
    width: "100%",
  },
  divider: {
    marginTop: "16px",
    borderWidth: ".1px",
    borderStyle: "solid",
    borderColor: "neutral.grey4",
    background: "neutral.grey4",
    marginBottom: "16px",
  },
  gridItem: {
    margin: 0,
    paddingTop: "2px",
  },
  gridContentItem: {
    margin: 0,
    paddingTop: "0 !important",
  },
  gridTwoItem: {
    margin: 0,
    paddingTop: "2px",
    display: "grid",
    rowGap: "20px",
  },
  gridInfo: {
    paddingLeft: "40px !important",
  },
  labelCurrency: {
    color: "#293036",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "19.2px",
  },
  labelTitle3: {
    color: "#293036",
    fontSize: "16px",
    fontStyle: "normal",
    marginLeft: 1,
    lineHeight: "24px",
  },
  labelTitle4: {
    color: "#6D7781",
    fontSize: "12px",
    lineHeight: "16.08px",
  },
});
