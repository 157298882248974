import api from "!../node_modules/.pnpm/registry.npmjs.org/style-loader/2.0.0_webpack@5.15.0/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../node_modules/.pnpm/registry.npmjs.org/css-loader/5.0.1_webpack@5.15.0/node_modules/css-loader/dist/cjs.js!../node_modules/.pnpm/registry.npmjs.org/postcss-loader/4.1.0_postcss@8.2.4+webpack@5.15.0/node_modules/postcss-loader/dist/cjs.js!./global.css?modules=false";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};