import { useState } from "react";
import { IUseBoardMembers } from "./useBoardMembers.interfaces";

export const useBoardMembers = (): IUseBoardMembers => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return {
    handlers: {
      handleCloseModal,
      handleOpenModal,
    },
    openModal,
  };
};
