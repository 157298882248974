import { makeStyles, Theme } from "@material-ui/core";

export const usePaginationStyles = makeStyles<Theme>((theme) => ({
  marginButton: {
    display: "flex",
    alignItems: "center",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 14px",
  },
  typographyLabelPagination: {
    marginRight: "25px",
    color: "#6D7781",
    fontWeight: 500,
    fontSize: "14px",
  },
  toEnd: {
    justifyContent: "flex-end",
    alignItems: "center",
  },
  pagination: {
    fontsize: "30px",
  },
}));
