import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import React, { useEffect, useState } from "react";
import { IconDownload } from "@kushki/connect-ui";
import { defaultTo, get } from "lodash";
import {
  getEmailValue,
  getLocalStorageValueByPath,
  getMerchantIdValue,
  isInternalConsole,
} from "../../../shared/utils/localStorageUtils";
import { DEFAULT_DOWNLOAD_BUTTON_ITEMS } from "../../../shared/constants/head_filters";
import { HEAD_FILTERS_LABELS } from "../../../shared/constants/labels/head_filters_labels";
import { ISplitButtonProps } from "@kushki/connect-ui/dist/Components/Molecules/Form/SplitButton/SplitButton.interfaces";
import { RootState } from "../../../store/store";
import { generateReport } from "../../../store/thunks/walletReport/walletReport.thunk";
import { IModalSimpleProps } from "@kushki/connect-ui/dist/Components/Molecules/Modal/ModalSimple/ModalSimple.interfaces";
import {
  WalletAlertDownloadModal,
  WalletSnackBarMessageEnum,
} from "../../../shared/infrastructure/constants/WalletResumeConstants";
import { LocalStoragePath } from "../../../shared/enums/local_storage_path";
import { CountriesEnum } from "../../../shared/enums/countries";
import { ConsoleValuesEnum } from "../../../shared/enums/console_values";
import {
  WalletReportActionEnum,
  WalletReportTypeEnum,
} from "../../../shared/enums/wallet_report";
import { FileExtensionEnum } from "../../../shared/enums/file_extension";
import { DownloadRequest } from "../../../../types/download_request";
import { CreateReportRequest } from "../../../../types/create_report_request";
import { setOpenSnackbar } from "../../../store/actions/app.action";

export const useDownloadButtonComponent = (): {
  additionalActions: ISplitButtonProps;
  modalData: IModalSimpleProps;
} => {
  const dispatch = useAppDispatch();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalData, setModalData] = useState<{
    description: string;
    title: string;
  }>({ description: "", title: "" });

  const { downloadReportState, generateReportState, walletTrxRequest } =
    useAppSelector(({ walletReport, walletTransaction }: RootState) => ({
      ...walletReport,
      ...walletTransaction,
    }));

  const emailUser: string = getEmailValue();
  const basicMerchantInformation: object = getLocalStorageValueByPath(
    LocalStoragePath.BASIC_MERCHANT_INFORMATION
  );
  const merchantInformation: object = getLocalStorageValueByPath(
    LocalStoragePath.MERCHANT_INFO
  );
  const merchantId: string = get(
    basicMerchantInformation,
    "publicMerchantId",
    get(merchantInformation, "publicMerchantId", getMerchantIdValue())
  );

  const getConsoleValue = (): string =>
    isInternalConsole()
      ? ConsoleValuesEnum.INTERNAL
      : ConsoleValuesEnum.EXTERNAL;
  const getSignedUrl = (format: string): void => {
    const downloadRequest: { request: DownloadRequest; type: string } = {
      request: {
        action: WalletReportActionEnum.DOWNLOAD_WALLET_TRX,
        data: {
          document_format: format.toLowerCase(),
          document_type: getConsoleValue() as ConsoleValuesEnum,
          filter: {
            operationType: get(walletTrxRequest, "filter.operationType"),
            origin_ticket_number: get(
              walletTrxRequest,
              "filter.origin_ticket_number"
            ),
            transaction_status: get(
              walletTrxRequest,
              "filter.transaction_status"
            ),
          },
          from: get(walletTrxRequest, "from", ""),
          public_merchant_id: defaultTo(merchantId, ""),
          to: get(walletTrxRequest, "to", ""),
        },
      },
      type: WalletReportTypeEnum.DOWNLOAD_REPORT,
    };

    dispatch(downloadRequest);
  };
  const generateFile = (format: string): void => {
    const createReportRequest: CreateReportRequest = {
      endDate: get(walletTrxRequest, "to", ""),
      fileType: format,
      masterUserEmail: emailUser,
      publicMerchantId: merchantId,
      startDate: get(walletTrxRequest, "from", ""),
    };

    dispatch(generateReport(createReportRequest));
  };

  const onItemSelected = (item: { id: string }): void => {
    const country: CountriesEnum = get(basicMerchantInformation, "country", "");
    const format: string = defaultTo(item.id, FileExtensionEnum.CSV);

    setIsLoading(true);
    [CountriesEnum.MEXICO, CountriesEnum.CHILE].includes(country)
      ? generateFile(format)
      : getSignedUrl(format);
  };

  const downloadFile = (signedUrl: string): void => {
    const anchorElement: HTMLAnchorElement = document.createElement("a");

    anchorElement.href = signedUrl;
    anchorElement.click();
    anchorElement.remove();
  };

  useEffect(() => {
    const { isError, url } = downloadReportState;

    if (isError) {
      dispatch(
        setOpenSnackbar({
          isError: true,
          message: WalletSnackBarMessageEnum.ERROR_DOWNLOAD,
          withIcon: true,
        })
      );
      setIsLoading(false);

      return;
    }
    if (url) {
      dispatch(
        setOpenSnackbar({
          isError: false,
          message: WalletSnackBarMessageEnum.SUCCESS_DOWNLOAD_FILE,
          withIcon: false,
        })
      );
      setIsLoading(false);
      downloadFile(url);
    }
  }, [downloadReportState]);

  useEffect(() => {
    const { isError, openModal } = generateReportState;

    if (!isError && !openModal) return;

    if (openModal) {
      const modalData: { description: string; title: string } = isError
        ? {
            description: WalletAlertDownloadModal.ERROR_DATE_RANGE_DESCRIPTION,
            title: WalletAlertDownloadModal.ERROR_DATE_RANGE_TITLE,
          }
        : {
            description: WalletAlertDownloadModal.SUCCESS_MODAL_DESCRIPTION,
            title: WalletAlertDownloadModal.SUCCESS_MODAL_TITLE,
          };

      setIsOpenModal(openModal);
      setModalData({
        description: modalData.description,
        title: modalData.title,
      });
    } else {
      dispatch(
        setOpenSnackbar({
          isError: true,
          message: WalletSnackBarMessageEnum.ERROR_DOWNLOAD,
          withIcon: true,
        })
      );
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [generateReportState]);

  return {
    additionalActions: {
      icon: <IconDownload />,
      isLoading,
      items: DEFAULT_DOWNLOAD_BUTTON_ITEMS,
      onItemSelected: onItemSelected,
      size: "small",
      text: HEAD_FILTERS_LABELS.downloadButton,
      type: "default",
      variant: "primary",
    },
    modalData: {
      buttonPrimary: WalletAlertDownloadModal.MODAL_BUTTON,
      descriptionText: modalData.description,
      isOpen: isOpenModal,
      onClickPrimary: (): void => setIsOpenModal(false),
      onClickSecondary: (): void => {},
      onClose: (): void => {},
      titleText: modalData.title,
      typeModal: 2,
    },
  };
};
