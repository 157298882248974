/**
 * TransactionResultEnum
 */
export enum TransactionResultEnum {
  CREATED = "created", // Just created
  PAID = "paid", // Paid transactions
  COMPLETED = "completed", // Paid and registration OK
  CANCELED = "canceled", // The paying flow fail
  FAILED = "failed", // Paid but the flow fails
  VOID_PENDING = "voidPending", // Failed but void pending
  REFUND = "refund",
  REQUESTED = "requested",
}

export const TRANSACTION_RESULT_TEXT_ENUM: Record<
  TransactionResultEnum,
  string
> = {
  [TransactionResultEnum.CREATED]: "Creado",
  [TransactionResultEnum.PAID]: "Pagado",
  [TransactionResultEnum.COMPLETED]: "Emitido",
  [TransactionResultEnum.CANCELED]: "Cancelado",
  [TransactionResultEnum.FAILED]: "Fallido",
  [TransactionResultEnum.VOID_PENDING]: "Pendiente de anulación",
  [TransactionResultEnum.REFUND]: "Reembolso",
  [TransactionResultEnum.REQUESTED]: "Solicitado",
};
