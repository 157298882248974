import { ITransactionsTableColumn } from "../interfaces/TransactionsTableColumn.interfaces";

export const TRANSACTIONS_TABLE_COLUMNS: ITransactionsTableColumn[] = [
  {
    id: "selection",
    label: "",
    padding: "checkbox",
    sortable: false,
    type: "checkbox",
  },
  {
    id: "date",
    label: "Fecha",
    padding: "normal",
    sortable: true,
    type: "text",
  },
  {
    id: "ticketNumber",
    label: "Número de ticket",
    padding: "normal",
    sortable: false,
    type: "text",
  },
  {
    id: "email",
    label: "Correo electrónico",
    padding: "normal",
    sortable: false,
    type: "text",
  },
  {
    id: "paymentMethod",
    label: "Medio de pago",
    padding: "normal",
    sortable: false,
    type: "text",
  },
  {
    id: "vehiclePlate",
    label: "N de Placa",
    padding: "normal",
    sortable: false,
    type: "text",
  },
  {
    id: "transactionAmount",
    label: "Monto de la trans.",
    padding: "normal",
    sortable: false,
    type: "text",
  },
  {
    id: "state",
    label: "Estado",
    padding: "normal",
    sortable: false,
    type: "text",
  },
  {
    id: "actions",
    label: "",
    padding: "normal",
    sortable: false,
    type: "text",
  },
];
