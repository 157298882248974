export const MEXICO_CITIES = [
  {
    text: "MÉXICO",
    value: "MÉXICO - DISTRITO FEDERAL",
  },
  {
    text: "AGUASCALIENTES",
    value: "AGUASCALIENTES - AGUASCALIENTES",
  },
  {
    text: "ASIENTOS",
    value: "ASIENTOS - AGUASCALIENTES",
  },
  {
    text: "CALVILLO",
    value: "CALVILLO - AGUASCALIENTES",
  },
  {
    text: "COSÍO",
    value: "COSÍO - AGUASCALIENTES",
  },
  {
    text: "EL LLANO",
    value: "EL LLANO - AGUASCALIENTES",
  },
  {
    text: "JESÚS MARÍA",
    value: "JESÚS MARÍA - AGUASCALIENTES",
  },
  {
    text: "PABELLÓN DE ARTEAGA",
    value: "PABELLÓN DE ARTEAGA - AGUASCALIENTES",
  },
  {
    text: "RINCÓN DE ROMOS",
    value: "RINCÓN DE ROMOS - AGUASCALIENTES",
  },
  {
    text: "SAN FRANCISCO DE LOS ROMO",
    value: "SAN FRANCISCO DE LOS ROMO - AGUASCALIENTES",
  },
  {
    text: "SAN JOSÉ DE GRACIA",
    value: "SAN JOSÉ DE GRACIA - AGUASCALIENTES",
  },
  {
    text: "TEPEZALÁ",
    value: "TEPEZALÁ - AGUASCALIENTES",
  },
  {
    text: "ENSENADA",
    value: "ENSENADA - BAJA CALIFORNIA NORTE",
  },
  {
    text: "MEXICALI",
    value: "MEXICALI - BAJA CALIFORNIA NORTE",
  },
  {
    text: "PLAYAS DE ROSARITO",
    value: "PLAYAS DE ROSARITO - BAJA CALIFORNIA NORTE",
  },
  {
    text: "ROSARITO",
    value: "ROSARITO - BAJA CALIFORNIA NORTE",
  },
  {
    text: "TECATE",
    value: "TECATE - BAJA CALIFORNIA NORTE",
  },
  {
    text: "TIJUANA",
    value: "TIJUANA - BAJA CALIFORNIA NORTE",
  },
  {
    text: "CABO SAN LUCAS",
    value: "CABO SAN LUCAS - BAJA CALIFORNIA SUR",
  },
  {
    text: "CD CONSTITUCION",
    value: "CD CONSTITUCION - BAJA CALIFORNIA SUR",
  },
  {
    text: "COMONDÚ",
    value: "COMONDÚ - BAJA CALIFORNIA SUR",
  },
  {
    text: "LA PAZ",
    value: "LA PAZ - BAJA CALIFORNIA SUR",
  },
  {
    text: "LORETO",
    value: "LORETO - BAJA CALIFORNIA SUR",
  },
  {
    text: "LOS CABOS",
    value: "LOS CABOS - BAJA CALIFORNIA SUR",
  },
  {
    text: "MULEGÉ",
    value: "MULEGÉ - BAJA CALIFORNIA SUR",
  },
  {
    text: "CALAKMUL",
    value: "CALAKMUL - CAMPECHE",
  },
  {
    text: "CALKINÍ",
    value: "CALKINÍ - CAMPECHE",
  },
  {
    text: "CAMPECHE",
    value: "CAMPECHE - CAMPECHE",
  },
  {
    text: "CANDELARIA",
    value: "CANDELARIA - CAMPECHE",
  },
  {
    text: "CARMEN",
    value: "CARMEN - CAMPECHE",
  },
  {
    text: "CD DEL CARMEN",
    value: "CD DEL CARMEN - CAMPECHE",
  },
  {
    text: "CHAMPOTÓN",
    value: "CHAMPOTÓN - CAMPECHE",
  },
  {
    text: "ESCÁRCEGA",
    value: "ESCÁRCEGA - CAMPECHE",
  },
  {
    text: "HECELCHAKÁN",
    value: "HECELCHAKÁN - CAMPECHE",
  },
  {
    text: "HOPELCHÉN",
    value: "HOPELCHÉN - CAMPECHE",
  },
  {
    text: "PALIZADA",
    value: "PALIZADA - CAMPECHE",
  },
  {
    text: "TENABO",
    value: "TENABO - CAMPECHE",
  },
  {
    text: "ABASOLO",
    value: "ABASOLO - COAHUILA",
  },
  {
    text: "ACUNA",
    value: "ACUNA - COAHUILA",
  },
  {
    text: "ALLENDE",
    value: "ALLENDE - COAHUILA",
  },
  {
    text: "ARTEAGA",
    value: "ARTEAGA - COAHUILA",
  },
  {
    text: "CANDELA",
    value: "CANDELA - COAHUILA",
  },
  {
    text: "CASTANOS",
    value: "CASTANOS - COAHUILA",
  },
  {
    text: "CD SABINAS",
    value: "CD SABINAS - COAHUILA",
  },
  {
    text: "CUATRO CIÉNEGAS",
    value: "CUATRO CIÉNEGAS - COAHUILA",
  },
  {
    text: "ESCOBEDO",
    value: "ESCOBEDO - COAHUILA",
  },
  {
    text: "FRANCISCO I MADERO",
    value: "FRANCISCO I MADERO - COAHUILA",
  },
  {
    text: "FRONTERA",
    value: "FRONTERA - COAHUILA",
  },
  {
    text: "GRAL CEPEDA",
    value: "GRAL CEPEDA - COAHUILA",
  },
  {
    text: "GUERRERO",
    value: "GUERRERO - COAHUILA",
  },
  {
    text: "HIDALGO",
    value: "HIDALGO - COAHUILA",
  },
  {
    text: "JIMÉNEZ",
    value: "JIMÉNEZ - COAHUILA",
  },
  {
    text: "JUÁREZ",
    value: "JUÁREZ - COAHUILA",
  },
  {
    text: "LAMADRID",
    value: "LAMADRID - COAHUILA",
  },
  {
    text: "MATAMOROS",
    value: "MATAMOROS - COAHUILA",
  },
  {
    text: "MELCHOR MÚZQUIZ",
    value: "MELCHOR MÚZQUIZ - COAHUILA",
  },
  {
    text: "MONCLOVA",
    value: "MONCLOVA - COAHUILA",
  },
  {
    text: "MORELOS",
    value: "MORELOS - COAHUILA",
  },
  {
    text: "MÚZQUIZ",
    value: "MÚZQUIZ - COAHUILA",
  },
  {
    text: "NADADORES",
    value: "NADADORES - COAHUILA",
  },
  {
    text: "NAVA",
    value: "NAVA - COAHUILA",
  },
  {
    text: "OCAMPO",
    value: "OCAMPO - COAHUILA",
  },
  {
    text: "PARRAS",
    value: "PARRAS - COAHUILA",
  },
  {
    text: "PIEDRAS NEGRAS",
    value: "PIEDRAS NEGRAS - COAHUILA",
  },
  {
    text: "PROGRESO",
    value: "PROGRESO - COAHUILA",
  },
  {
    text: "RAMOS ARIZPE",
    value: "RAMOS ARIZPE - COAHUILA",
  },
  {
    text: "SABINAS",
    value: "SABINAS - COAHUILA",
  },
  {
    text: "SACRAMENTO",
    value: "SACRAMENTO - COAHUILA",
  },
  {
    text: "SALTILLO",
    value: "SALTILLO - COAHUILA",
  },
  {
    text: "SIERRA MOJADA",
    value: "SIERRA MOJADA - COAHUILA",
  },
  {
    text: "SAN BUENAVENTURA",
    value: "SAN BUENAVENTURA - COAHUILA",
  },
  {
    text: "SAN JUAN DE SABINAS",
    value: "SAN JUAN DE SABINAS - COAHUILA",
  },
  {
    text: "SAN PEDRO",
    value: "SAN PEDRO - COAHUILA",
  },
  {
    text: "TORREÓN",
    value: "TORREÓN - COAHUILA",
  },
  {
    text: "VIESCA",
    value: "VIESCA - COAHUILA",
  },
  {
    text: "VILLA UNIÓN",
    value: "VILLA UNIÓN - COAHUILA",
  },
  {
    text: "ZARAGOZA",
    value: "ZARAGOZA - COAHUILA",
  },
  {
    text: "ARMERÍA",
    value: "ARMERÍA - COLIMA",
  },
  {
    text: "COLIMA",
    value: "COLIMA - COLIMA",
  },
  {
    text: "COMALA",
    value: "COMALA - COLIMA",
  },
  {
    text: "COQUIMATLÁN",
    value: "COQUIMATLÁN - COLIMA",
  },
  {
    text: "CUAUHTÉMOC",
    value: "CUAUHTÉMOC - COLIMA",
  },
  {
    text: "IXTLAHUACÁN",
    value: "IXTLAHUACÁN - COLIMA",
  },
  {
    text: "MANZANILLO",
    value: "MANZANILLO - COLIMA",
  },
  {
    text: "MINATITLÁN",
    value: "MINATITLÁN - COLIMA",
  },
  {
    text: "TECOMÁN",
    value: "TECOMÁN - COLIMA",
  },
  {
    text: "VILLA DE ÁLVAREZ",
    value: "VILLA DE ÁLVAREZ - COLIMA",
  },
  {
    text: "ACACOYAGUA",
    value: "ACACOYAGUA - CHIAPAS",
  },
  {
    text: "ACALA",
    value: "ACALA - CHIAPAS",
  },
  {
    text: "ACAPETAHUA",
    value: "ACAPETAHUA - CHIAPAS",
  },
  {
    text: "ALDAMA",
    value: "ALDAMA - CHIAPAS",
  },
  {
    text: "ALTAMIRANO",
    value: "ALTAMIRANO - CHIAPAS",
  },
  {
    text: "AMATÁN",
    value: "AMATÁN - CHIAPAS",
  },
  {
    text: "AMATENANGO DE LA FRONTERA",
    value: "AMATENANGO DE LA FRONTERA - CHIAPAS",
  },
  {
    text: "AMATENANGO DEL VALLE",
    value: "AMATENANGO DEL VALLE - CHIAPAS",
  },
  {
    text: "ANGEL ALBINO CORZO",
    value: "ANGEL ALBINO CORZO - CHIAPAS",
  },
  {
    text: "ARRIAGA",
    value: "ARRIAGA - CHIAPAS",
  },
  {
    text: "BEJUCAL DE OCAMPO",
    value: "BEJUCAL DE OCAMPO - CHIAPAS",
  },
  {
    text: "BELLA VISTA",
    value: "BELLA VISTA - CHIAPAS",
  },
  {
    text: "BENEMÉRITO LAS AMÉRICAS",
    value: "BENEMÉRITO LAS AMÉRICAS - CHIAPAS",
  },
  {
    text: "BERRÍOZÁBAL",
    value: "BERRÍOZÁBAL - CHIAPAS",
  },
  {
    text: "BOCHIL",
    value: "BOCHIL - CHIAPAS",
  },
  {
    text: "CACAHOATÁN",
    value: "CACAHOATÁN - CHIAPAS",
  },
  {
    text: "CATAZAJÁ",
    value: "CATAZAJÁ - CHIAPAS",
  },
  {
    text: "CHALCHIHUITÁN",
    value: "CHALCHIHUITÁN - CHIAPAS",
  },
  {
    text: "CHAMULA",
    value: "CHAMULA - CHIAPAS",
  },
  {
    text: "CHANAL",
    value: "CHANAL - CHIAPAS",
  },
  {
    text: "CHAPULTENANGO",
    value: "CHAPULTENANGO - CHIAPAS",
  },
  {
    text: "CHENALHÓ",
    value: "CHENALHÓ - CHIAPAS",
  },
  {
    text: "CHIAPA DE CORZO",
    value: "CHIAPA DE CORZO - CHIAPAS",
  },
  {
    text: "CHIAPILLA",
    value: "CHIAPILLA - CHIAPAS",
  },
  {
    text: "CHICOASÉN",
    value: "CHICOASÉN - CHIAPAS",
  },
  {
    text: "CHICOMUSELO",
    value: "CHICOMUSELO - CHIAPAS",
  },
  {
    text: "CHILÓN",
    value: "CHILÓN - CHIAPAS",
  },
  {
    text: "CINTALAPA",
    value: "CINTALAPA - CHIAPAS",
  },
  {
    text: "COAPILLA",
    value: "COAPILLA - CHIAPAS",
  },
  {
    text: "COMITÁN DE DOMÍNGUEZ",
    value: "COMITÁN DE DOMÍNGUEZ - CHIAPAS",
  },
  {
    text: "COPAINALÁ",
    value: "COPAINALÁ - CHIAPAS",
  },
  {
    text: "EL BOSQUE",
    value: "EL BOSQUE - CHIAPAS",
  },
  {
    text: "EL PORVENIR",
    value: "EL PORVENIR - CHIAPAS",
  },
  {
    text: "ESCUINTLA",
    value: "ESCUINTLA - CHIAPAS",
  },
  {
    text: "FRANCISCO LEÓN",
    value: "FRANCISCO LEÓN - CHIAPAS",
  },
  {
    text: "FRONTERA COMALAPA",
    value: "FRONTERA COMALAPA - CHIAPAS",
  },
  {
    text: "FRONTERA HIDALGO",
    value: "FRONTERA HIDALGO - CHIAPAS",
  },
  {
    text: "HUEHUETÁN",
    value: "HUEHUETÁN - CHIAPAS",
  },
  {
    text: "HUITIUPÁN",
    value: "HUITIUPÁN - CHIAPAS",
  },
  {
    text: "HUIXTÁN",
    value: "HUIXTÁN - CHIAPAS",
  },
  {
    text: "HUIXTLA",
    value: "HUIXTLA - CHIAPAS",
  },
  {
    text: "IXHUATÁN",
    value: "IXHUATÁN - CHIAPAS",
  },
  {
    text: "IXTACOMITÁN",
    value: "IXTACOMITÁN - CHIAPAS",
  },
  {
    text: "IXTAPA",
    value: "IXTAPA - CHIAPAS",
  },
  {
    text: "IXTAPANGAJOYA",
    value: "IXTAPANGAJOYA - CHIAPAS",
  },
  {
    text: "JIQUIPILAS",
    value: "JIQUIPILAS - CHIAPAS",
  },
  {
    text: "JITOTOL",
    value: "JITOTOL - CHIAPAS",
  },
  {
    text: "JUÁREZ",
    value: "JUÁREZ - CHIAPAS",
  },
  {
    text: "LA CONCORDIA",
    value: "LA CONCORDIA - CHIAPAS",
  },
  {
    text: "LA GRANDEZA",
    value: "LA GRANDEZA - CHIAPAS",
  },
  {
    text: "LA INDÉPENDENCIA",
    value: "LA INDÉPENDENCIA - CHIAPAS",
  },
  {
    text: "LA LIBERTAD",
    value: "LA LIBERTAD - CHIAPAS",
  },
  {
    text: "LA TRINITARIA",
    value: "LA TRINITARIA - CHIAPAS",
  },
  {
    text: "LARRÁINZAR",
    value: "LARRÁINZAR - CHIAPAS",
  },
  {
    text: "LAS MARGARITAS",
    value: "LAS MARGARITAS - CHIAPAS",
  },
  {
    text: "LAS ROSAS",
    value: "LAS ROSAS - CHIAPAS",
  },
  {
    text: "MAPASTEPEC",
    value: "MAPASTEPEC - CHIAPAS",
  },
  {
    text: "MARAVILLA TENEJAPA",
    value: "MARAVILLA TENEJAPA - CHIAPAS",
  },
  {
    text: "MARQUÉS DE COMILLAS",
    value: "MARQUÉS DE COMILLAS - CHIAPAS",
  },
  {
    text: "MAZAPA DE MADERO",
    value: "MAZAPA DE MADERO - CHIAPAS",
  },
  {
    text: "MAZATÁN",
    value: "MAZATÁN - CHIAPAS",
  },
  {
    text: "METAPA",
    value: "METAPA - CHIAPAS",
  },
  {
    text: "MITONTIC",
    value: "MITONTIC - CHIAPAS",
  },
  {
    text: "MONTECRISTO DE GUERRERO",
    value: "MONTECRISTO DE GUERRERO - CHIAPAS",
  },
  {
    text: "MOTOZINTLA",
    value: "MOTOZINTLA - CHIAPAS",
  },
  {
    text: "NICOLÁS RUÍZ",
    value: "NICOLÁS RUÍZ - CHIAPAS",
  },
  {
    text: "OCOSINGO",
    value: "OCOSINGO - CHIAPAS",
  },
  {
    text: "OCOTEPEC",
    value: "OCOTEPEC - CHIAPAS",
  },
  {
    text: "OCOZOCOAUTLA DE ESPINOSA",
    value: "OCOZOCOAUTLA DE ESPINOSA - CHIAPAS",
  },
  {
    text: "OSTUACÁN",
    value: "OSTUACÁN - CHIAPAS",
  },
  {
    text: "OSUMACINTA",
    value: "OSUMACINTA - CHIAPAS",
  },
  {
    text: "OXCHUC",
    value: "OXCHUC - CHIAPAS",
  },
  {
    text: "PALENQUE",
    value: "PALENQUE - CHIAPAS",
  },
  {
    text: "PANTELHÓ",
    value: "PANTELHÓ - CHIAPAS",
  },
  {
    text: "PANTEPEC",
    value: "PANTEPEC - CHIAPAS",
  },
  {
    text: "PICHUCALCO",
    value: "PICHUCALCO - CHIAPAS",
  },
  {
    text: "PIJIJIAPAN",
    value: "PIJIJIAPAN - CHIAPAS",
  },
  {
    text: "PUEBLO NVO SOLISTAHUACÁN",
    value: "PUEBLO NVO SOLISTAHUACÁN - CHIAPAS",
  },
  {
    text: "PUERTO MADERO",
    value: "PUERTO MADERO - CHIAPAS",
  },
  {
    text: "RAYÓN",
    value: "RAYÓN - CHIAPAS",
  },
  {
    text: "REFORMA",
    value: "REFORMA - CHIAPAS",
  },
  {
    text: "SABANILLA",
    value: "SABANILLA - CHIAPAS",
  },
  {
    text: "SALTO DE AGUA",
    value: "SALTO DE AGUA - CHIAPAS",
  },
  {
    text: "SANTIAGO EL PINAR",
    value: "SANTIAGO EL PINAR - CHIAPAS",
  },
  {
    text: "SILTEPEC",
    value: "SILTEPEC - CHIAPAS",
  },
  {
    text: "SIMOJOVEL",
    value: "SIMOJOVEL - CHIAPAS",
  },
  {
    text: "SITALÁ",
    value: "SITALÁ - CHIAPAS",
  },
  {
    text: "SAN ANDRÉS DURAZNAL",
    value: "SAN ANDRÉS DURAZNAL - CHIAPAS",
  },
  {
    text: "SAN CRISTÓBAL DE LAS CASAS",
    value: "SAN CRISTÓBAL DE LAS CASAS - CHIAPAS",
  },
  {
    text: "SAN FERNANDO",
    value: "SAN FERNANDO - CHIAPAS",
  },
  {
    text: "SAN JUAN CANCUC",
    value: "SAN JUAN CANCUC - CHIAPAS",
  },
  {
    text: "SAN LUCAS",
    value: "SAN LUCAS - CHIAPAS",
  },
  {
    text: "SOCOLTENANGO",
    value: "SOCOLTENANGO - CHIAPAS",
  },
  {
    text: "SOLOSUCHIAPA",
    value: "SOLOSUCHIAPA - CHIAPAS",
  },
  {
    text: "SOYALÓ",
    value: "SOYALÓ - CHIAPAS",
  },
  {
    text: "SUCHIAPA",
    value: "SUCHIAPA - CHIAPAS",
  },
  {
    text: "SUCHIATE",
    value: "SUCHIATE - CHIAPAS",
  },
  {
    text: "SUNUAPA",
    value: "SUNUAPA - CHIAPAS",
  },
  {
    text: "TAPACHULA",
    value: "TAPACHULA - CHIAPAS",
  },
  {
    text: "TAPALAPA",
    value: "TAPALAPA - CHIAPAS",
  },
  {
    text: "TAPILULA",
    value: "TAPILULA - CHIAPAS",
  },
  {
    text: "TECPATÁN",
    value: "TECPATÁN - CHIAPAS",
  },
  {
    text: "TENEJAPA",
    value: "TENEJAPA - CHIAPAS",
  },
  {
    text: "TEOPISCA",
    value: "TEOPISCA - CHIAPAS",
  },
  {
    text: "TILA",
    value: "TILA - CHIAPAS",
  },
  {
    text: "TONALÁ",
    value: "TONALÁ - CHIAPAS",
  },
  {
    text: "TOTOLAPA",
    value: "TOTOLAPA - CHIAPAS",
  },
  {
    text: "TUMBALÁ",
    value: "TUMBALÁ - CHIAPAS",
  },
  {
    text: "TUXTLA CHICO",
    value: "TUXTLA CHICO - CHIAPAS",
  },
  {
    text: "TUXTLA GUTIÉRREZ",
    value: "TUXTLA GUTIÉRREZ - CHIAPAS",
  },
  {
    text: "TUZANTÁN",
    value: "TUZANTÁN - CHIAPAS",
  },
  {
    text: "TZIMOL",
    value: "TZIMOL - CHIAPAS",
  },
  {
    text: "UNIÓN JUÁREZ",
    value: "UNIÓN JUÁREZ - CHIAPAS",
  },
  {
    text: "VENUSTIANO CARRANZA",
    value: "VENUSTIANO CARRANZA - CHIAPAS",
  },
  {
    text: "VILLA COMALTITLÁN",
    value: "VILLA COMALTITLÁN - CHIAPAS",
  },
  {
    text: "VILLA CORZO",
    value: "VILLA CORZO - CHIAPAS",
  },
  {
    text: "VILLAFLORES",
    value: "VILLAFLORES - CHIAPAS",
  },
  {
    text: "YAJALÓN",
    value: "YAJALÓN - CHIAPAS",
  },
  {
    text: "ZINACANTÁN",
    value: "ZINACANTÁN - CHIAPAS",
  },
  {
    text: "AHUMADA",
    value: "AHUMADA - CHIHUAHUA",
  },
  {
    text: "ALDAMA",
    value: "ALDAMA - CHIHUAHUA",
  },
  {
    text: "ALLENDE",
    value: "ALLENDE - CHIHUAHUA",
  },
  {
    text: "AQUILES SERDÁN",
    value: "AQUILES SERDÁN - CHIHUAHUA",
  },
  {
    text: "ASCENSIÓN",
    value: "ASCENSIÓN - CHIHUAHUA",
  },
  {
    text: "BACHÍNIVA",
    value: "BACHÍNIVA - CHIHUAHUA",
  },
  {
    text: "BALLEZA",
    value: "BALLEZA - CHIHUAHUA",
  },
  {
    text: "BATOPILAS",
    value: "BATOPILAS - CHIHUAHUA",
  },
  {
    text: "BOCOYNA",
    value: "BOCOYNA - CHIHUAHUA",
  },
  {
    text: "BUENAVENTURA",
    value: "BUENAVENTURA - CHIHUAHUA",
  },
  {
    text: "CAMARGO",
    value: "CAMARGO - CHIHUAHUA",
  },
  {
    text: "CAMARGO",
    value: "CAMARGO - CHIHUAHUA",
  },
  {
    text: "CARICHÍ",
    value: "CARICHÍ - CHIHUAHUA",
  },
  {
    text: "CASAS GRANDES",
    value: "CASAS GRANDES - CHIHUAHUA",
  },
  {
    text: "CD DELICIAS",
    value: "CD DELICIAS - CHIHUAHUA",
  },
  {
    text: "CD JUÁREZ",
    value: "CD JUÁREZ - CHIHUAHUA",
  },
  {
    text: "CHIHUAHUA",
    value: "CHIHUAHUA - CHIHUAHUA",
  },
  {
    text: "CHÍNIPAS",
    value: "CHÍNIPAS - CHIHUAHUA",
  },
  {
    text: "CORONADO",
    value: "CORONADO - CHIHUAHUA",
  },
  {
    text: "COYAME",
    value: "COYAME - CHIHUAHUA",
  },
  {
    text: "CUAUHTÉMOC",
    value: "CUAUHTÉMOC - CHIHUAHUA",
  },
  {
    text: "CUSIHUIRIACHI",
    value: "CUSIHUIRIACHI - CHIHUAHUA",
  },
  {
    text: "DELICIAS",
    value: "DELICIAS - CHIHUAHUA",
  },
  {
    text: "DR BELISARÍO DOMÍNGUEZ",
    value: "DR BELISARÍO DOMÍNGUEZ - CHIHUAHUA",
  },
  {
    text: "EL TULE",
    value: "EL TULE - CHIHUAHUA",
  },
  {
    text: "GALEANA",
    value: "GALEANA - CHIHUAHUA",
  },
  {
    text: "GÓMEZ FARÍAS",
    value: "GÓMEZ FARÍAS - CHIHUAHUA",
  },
  {
    text: "GRAN MORELOS",
    value: "GRAN MORELOS - CHIHUAHUA",
  },
  {
    text: "GUACHOCHI",
    value: "GUACHOCHI - CHIHUAHUA",
  },
  {
    text: "GUADALUPE",
    value: "GUADALUPE - CHIHUAHUA",
  },
  {
    text: "GUADALUPE Y CALVO",
    value: "GUADALUPE Y CALVO - CHIHUAHUA",
  },
  {
    text: "GUAZAPARES",
    value: "GUAZAPARES - CHIHUAHUA",
  },
  {
    text: "GUERRERO",
    value: "GUERRERO - CHIHUAHUA",
  },
  {
    text: "HIDALGO DEL PARRAL",
    value: "HIDALGO DEL PARRAL - CHIHUAHUA",
  },
  {
    text: "HUEJOTITÁN",
    value: "HUEJOTITÁN - CHIHUAHUA",
  },
  {
    text: "IGNACIO ZARAGOZA",
    value: "IGNACIO ZARAGOZA - CHIHUAHUA",
  },
  {
    text: "JANOS",
    value: "JANOS - CHIHUAHUA",
  },
  {
    text: "JIMÉNEZ",
    value: "JIMÉNEZ - CHIHUAHUA",
  },
  {
    text: "JULIMES",
    value: "JULIMES - CHIHUAHUA",
  },
  {
    text: "LA CRUZ",
    value: "LA CRUZ - CHIHUAHUA",
  },
  {
    text: "LÓPEZ",
    value: "LÓPEZ - CHIHUAHUA",
  },
  {
    text: "MADERA",
    value: "MADERA - CHIHUAHUA",
  },
  {
    text: "MAGUARICHI",
    value: "MAGUARICHI - CHIHUAHUA",
  },
  {
    text: "MANUEL BENAVIDES",
    value: "MANUEL BENAVIDES - CHIHUAHUA",
  },
  {
    text: "MATACHÍ",
    value: "MATACHÍ - CHIHUAHUA",
  },
  {
    text: "MATAMOROS",
    value: "MATAMOROS - CHIHUAHUA",
  },
  {
    text: "MEOQUI",
    value: "MEOQUI - CHIHUAHUA",
  },
  {
    text: "MORELOS",
    value: "MORELOS - CHIHUAHUA",
  },
  {
    text: "MORIS",
    value: "MORIS - CHIHUAHUA",
  },
  {
    text: "NAMIQUIPA",
    value: "NAMIQUIPA - CHIHUAHUA",
  },
  {
    text: "NONOAVA",
    value: "NONOAVA - CHIHUAHUA",
  },
  {
    text: "NUEVO CASAS GRANDES",
    value: "NUEVO CASAS GRANDES - CHIHUAHUA",
  },
  {
    text: "OCAMPO",
    value: "OCAMPO - CHIHUAHUA",
  },
  {
    text: "OJINAGA",
    value: "OJINAGA - CHIHUAHUA",
  },
  {
    text: "PRAXEDIS G GUERRERO",
    value: "PRAXEDIS G GUERRERO - CHIHUAHUA",
  },
  {
    text: "RIVA PALACIO",
    value: "RIVA PALACIO - CHIHUAHUA",
  },
  {
    text: "ROSALES",
    value: "ROSALES - CHIHUAHUA",
  },
  {
    text: "ROSARÍO",
    value: "ROSARÍO - CHIHUAHUA",
  },
  {
    text: "SANTA BÁRBARA",
    value: "SANTA BÁRBARA - CHIHUAHUA",
  },
  {
    text: "SANTA ISABEL",
    value: "SANTA ISABEL - CHIHUAHUA",
  },
  {
    text: "SATEVÓ",
    value: "SATEVÓ - CHIHUAHUA",
  },
  {
    text: "SAUCILLO",
    value: "SAUCILLO - CHIHUAHUA",
  },
  {
    text: "SAN FRANCISCO DE BORJA",
    value: "SAN FRANCISCO DE BORJA - CHIHUAHUA",
  },
  {
    text: "SAN FRANCISCO DE CONCHOS",
    value: "SAN FRANCISCO DE CONCHOS - CHIHUAHUA",
  },
  {
    text: "SAN FRANCISCO DEL ORO",
    value: "SAN FRANCISCO DEL ORO - CHIHUAHUA",
  },
  {
    text: "TEMÓSACHIC",
    value: "TEMÓSACHIC - CHIHUAHUA",
  },
  {
    text: "URIQUE",
    value: "URIQUE - CHIHUAHUA",
  },
  {
    text: "URUACHI",
    value: "URUACHI - CHIHUAHUA",
  },
  {
    text: "VALLE DE ZARAGOZA",
    value: "VALLE DE ZARAGOZA - CHIHUAHUA",
  },
  {
    text: "VICENTE GUERRERO",
    value: "VICENTE GUERRERO - DURANGO",
  },
  {
    text: "CANATLÁN",
    value: "CANATLÁN - DURANGO",
  },
  {
    text: "CANELAS",
    value: "CANELAS - DURANGO",
  },
  {
    text: "CD LERDO",
    value: "CD LERDO - DURANGO",
  },
  {
    text: "CONETO DE COMONFORT",
    value: "CONETO DE COMONFORT - DURANGO",
  },
  {
    text: "CUENCAMÉ",
    value: "CUENCAMÉ - DURANGO",
  },
  {
    text: "DURANGO",
    value: "DURANGO - DURANGO",
  },
  {
    text: "EL ORO",
    value: "EL ORO - DURANGO",
  },
  {
    text: "GÓMEZ PALACIO",
    value: "GÓMEZ PALACIO - DURANGO",
  },
  {
    text: "GRAL SIMÓN BOLÍVAR",
    value: "GRAL SIMÓN BOLÍVAR - DURANGO",
  },
  {
    text: "GUADALUPE VICTORIA",
    value: "GUADALUPE VICTORIA - DURANGO",
  },
  {
    text: "GUANACEVÍ",
    value: "GUANACEVÍ - DURANGO",
  },
  {
    text: "HIDALGO",
    value: "HIDALGO - DURANGO",
  },
  {
    text: "INDÉ",
    value: "INDÉ - DURANGO",
  },
  {
    text: "LERDO",
    value: "LERDO - DURANGO",
  },
  {
    text: "MAPIMÍ",
    value: "MAPIMÍ - DURANGO",
  },
  {
    text: "MEZQUITAL",
    value: "MEZQUITAL - DURANGO",
  },
  {
    text: "NAZAS",
    value: "NAZAS - DURANGO",
  },
  {
    text: "NOMBRE DE DIOS",
    value: "NOMBRE DE DIOS - DURANGO",
  },
  {
    text: "NUEVO IDEAL",
    value: "NUEVO IDEAL - DURANGO",
  },
  {
    text: "OCAMPO",
    value: "OCAMPO - DURANGO",
  },
  {
    text: "OTÁEZ",
    value: "OTÁEZ - DURANGO",
  },
  {
    text: "PÁNUCO DE CORONADO",
    value: "PÁNUCO DE CORONADO - DURANGO",
  },
  {
    text: "PENON BLANCO",
    value: "PENON BLANCO - DURANGO",
  },
  {
    text: "POANAS",
    value: "POANAS - DURANGO",
  },
  {
    text: "PUEBLO NUEVO",
    value: "PUEBLO NUEVO - DURANGO",
  },
  {
    text: "RODEO",
    value: "RODEO - DURANGO",
  },
  {
    text: "SANTA CLARA",
    value: "SANTA CLARA - DURANGO",
  },
  {
    text: "SANTIAGO PAPASQUIARO",
    value: "SANTIAGO PAPASQUIARO - DURANGO",
  },
  {
    text: "SAN BERNARDO",
    value: "SAN BERNARDO - DURANGO",
  },
  {
    text: "SAN DIMAS",
    value: "SAN DIMAS - DURANGO",
  },
  {
    text: "SAN JUAN DE GUADALUPE",
    value: "SAN JUAN DE GUADALUPE - DURANGO",
  },
  {
    text: "SAN JUAN DEL RÍO",
    value: "SAN JUAN DEL RÍO - DURANGO",
  },
  {
    text: "SAN LUIS DEL CORDERO",
    value: "SAN LUIS DEL CORDERO - DURANGO",
  },
  {
    text: "SAN PEDRO DEL GALLO",
    value: "SAN PEDRO DEL GALLO - DURANGO",
  },
  {
    text: "SÚCHIL",
    value: "SÚCHIL - DURANGO",
  },
  {
    text: "TAMAZULA",
    value: "TAMAZULA - DURANGO",
  },
  {
    text: "TEPEHUANES",
    value: "TEPEHUANES - DURANGO",
  },
  {
    text: "TLAHUALILO",
    value: "TLAHUALILO - DURANGO",
  },
  {
    text: "TOPIA",
    value: "TOPIA - DURANGO",
  },
  {
    text: "VALLE DE SANTIAGO",
    value: "VALLE DE SANTIAGO - GUANAJUATO",
  },
  {
    text: "VICTORIA",
    value: "VICTORIA - GUANAJUATO",
  },
  {
    text: "VILLAGRAN",
    value: "VILLAGRAN - GUANAJUATO",
  },
  {
    text: "XICHÚ",
    value: "XICHÚ - GUANAJUATO",
  },
  {
    text: "YURIRIA",
    value: "YURIRIA - GUANAJUATO",
  },
  {
    text: "ABASOLO",
    value: "ABASOLO - GUANAJUATO",
  },
  {
    text: "ACÁMBARO",
    value: "ACÁMBARO - GUANAJUATO",
  },
  {
    text: "APASEO EL ALTO",
    value: "APASEO EL ALTO - GUANAJUATO",
  },
  {
    text: "APASEO EL GRANDE",
    value: "APASEO EL GRANDE - GUANAJUATO",
  },
  {
    text: "ATARJEA",
    value: "ATARJEA - GUANAJUATO",
  },
  {
    text: "CELAYA",
    value: "CELAYA - GUANAJUATO",
  },
  {
    text: "COMONFORT",
    value: "COMONFORT - GUANAJUATO",
  },
  {
    text: "CORONEO",
    value: "CORONEO - GUANAJUATO",
  },
  {
    text: "CORTAZAR",
    value: "CORTAZAR - GUANAJUATO",
  },
  {
    text: "CUERÁMARO",
    value: "CUERÁMARO - GUANAJUATO",
  },
  {
    text: "DOLORES HGO",
    value: "DOLORES HGO - GUANAJUATO",
  },
  {
    text: "DR MORA",
    value: "DR MORA - GUANAJUATO",
  },
  {
    text: "GUANAJUATO",
    value: "GUANAJUATO - GUANAJUATO",
  },
  {
    text: "HUANÍMARO",
    value: "HUANÍMARO - GUANAJUATO",
  },
  {
    text: "IRAPUATO",
    value: "IRAPUATO - GUANAJUATO",
  },
  {
    text: "JARAL DEL PROGRESO",
    value: "JARAL DEL PROGRESO - GUANAJUATO",
  },
  {
    text: "JERÉCUARO",
    value: "JERÉCUARO - GUANAJUATO",
  },
  {
    text: "LEÓN DE LOS ALDAMA",
    value: "LEÓN DE LOS ALDAMA - GUANAJUATO",
  },
  {
    text: "LEÓN",
    value: "LEÓN - GUANAJUATO",
  },
  {
    text: "MANUEL DOBLADO",
    value: "MANUEL DOBLADO - GUANAJUATO",
  },
  {
    text: "MOROLEÓN",
    value: "MOROLEÓN - GUANAJUATO",
  },
  {
    text: "OCAMPO",
    value: "OCAMPO - GUANAJUATO",
  },
  {
    text: "PÉNJAMO",
    value: "PÉNJAMO - GUANAJUATO",
  },
  {
    text: "PUEBLO NUEVO",
    value: "PUEBLO NUEVO - GUANAJUATO",
  },
  {
    text: "PURÍSIMA DEL RINCÓN",
    value: "PURÍSIMA DEL RINCÓN - GUANAJUATO",
  },
  {
    text: "ROMITA",
    value: "ROMITA - GUANAJUATO",
  },
  {
    text: "SALAMANCA",
    value: "SALAMANCA - GUANAJUATO",
  },
  {
    text: "SALVATIERRA",
    value: "SALVATIERRA - GUANAJUATO",
  },
  {
    text: "SANTA CATARINA",
    value: "SANTA CATARINA - GUANAJUATO",
  },
  {
    text: "SANTA CRUZ DE JUVENT ROSAS",
    value: "SANTA CRUZ DE JUVENT ROSAS - GUANAJUATO",
  },
  {
    text: "SANTIAGO MARAVATÍO",
    value: "SANTIAGO MARAVATÍO - GUANAJUATO",
  },
  {
    text: "SILAO",
    value: "SILAO - GUANAJUATO",
  },
  {
    text: "SAN DIEGO DE LA UNIÓN",
    value: "SAN DIEGO DE LA UNIÓN - GUANAJUATO",
  },
  {
    text: "SAN FELIPE",
    value: "SAN FELIPE - GUANAJUATO",
  },
  {
    text: "SAN FRANCISCO DEL RINCÓN",
    value: "SAN FRANCISCO DEL RINCÓN - GUANAJUATO",
  },
  {
    text: "SAN JOSÉ ITURBIDE",
    value: "SAN JOSÉ ITURBIDE - GUANAJUATO",
  },
  {
    text: "SAN LUIS DE LA PAZ",
    value: "SAN LUIS DE LA PAZ - GUANAJUATO",
  },
  {
    text: "SAN MIGUEL DE ALLENDE",
    value: "SAN MIGUEL DE ALLENDE - GUANAJUATO",
  },
  {
    text: "TARANDACUAO",
    value: "TARANDACUAO - GUANAJUATO",
  },
  {
    text: "TARIMORO",
    value: "TARIMORO - GUANAJUATO",
  },
  {
    text: "TIERRA BLANCA",
    value: "TIERRA BLANCA - GUANAJUATO",
  },
  {
    text: "URIANGATO",
    value: "URIANGATO - GUANAJUATO",
  },
  {
    text: "XALPATLÁHUAC",
    value: "XALPATLÁHUAC - GUERRERO",
  },
  {
    text: "XOCHIHUEHUETLÁN",
    value: "XOCHIHUEHUETLÁN - GUERRERO",
  },
  {
    text: "XOCHISTLÁHUACA",
    value: "XOCHISTLÁHUACA - GUERRERO",
  },
  {
    text: "ZAPOTITLÁN TABLAS",
    value: "ZAPOTITLÁN TABLAS - GUERRERO",
  },
  {
    text: "ZIHUATANEJO DE AZUETA",
    value: "ZIHUATANEJO DE AZUETA - GUERRERO",
  },
  {
    text: "ZIRÁNDARO",
    value: "ZIRÁNDARO - GUERRERO",
  },
  {
    text: "ZITLALA",
    value: "ZITLALA - GUERRERO",
  },
  {
    text: "ZUMPANGO DEL RÍO",
    value: "ZUMPANGO DEL RÍO - GUERRERO",
  },
  {
    text: "ACAPULCO DE JUÁREZ",
    value: "ACAPULCO DE JUÁREZ - GUERRERO",
  },
  {
    text: "ACAPULCO",
    value: "ACAPULCO - GUERRERO",
  },
  {
    text: "ACATEPEC",
    value: "ACATEPEC - GUERRERO",
  },
  {
    text: "AHUACUOTZINGO",
    value: "AHUACUOTZINGO - GUERRERO",
  },
  {
    text: "AJUCHITLÁN DEL PROGRESO",
    value: "AJUCHITLÁN DEL PROGRESO - GUERRERO",
  },
  {
    text: "ALCOZAUCA DE GUERRERO",
    value: "ALCOZAUCA DE GUERRERO - GUERRERO",
  },
  {
    text: "ALPOYECA",
    value: "ALPOYECA - GUERRERO",
  },
  {
    text: "APAXTLA DE CASTREJON",
    value: "APAXTLA DE CASTREJON - GUERRERO",
  },
  {
    text: "APAXTLA",
    value: "APAXTLA - GUERRERO",
  },
  {
    text: "ARCELIA",
    value: "ARCELIA - GUERRERO",
  },
  {
    text: "ATENANGO DEL RÍO",
    value: "ATENANGO DEL RÍO - GUERRERO",
  },
  {
    text: "ATLAMAJALCINGO DEL MTE",
    value: "ATLAMAJALCINGO DEL MTE - GUERRERO",
  },
  {
    text: "ATLIXTAC",
    value: "ATLIXTAC - GUERRERO",
  },
  {
    text: "ATOYAC DE ÁLVAREZ",
    value: "ATOYAC DE ÁLVAREZ - GUERRERO",
  },
  {
    text: "AYUTLA DE LOS LIBRES",
    value: "AYUTLA DE LOS LIBRES - GUERRERO",
  },
  {
    text: "AZOYÚ",
    value: "AZOYÚ - GUERRERO",
  },
  {
    text: "BENITO JUÁREZ",
    value: "BENITO JUÁREZ - GUERRERO",
  },
  {
    text: "BUENAVISTA DE CUÉLLAR",
    value: "BUENAVISTA DE CUÉLLAR - GUERRERO",
  },
  {
    text: "CHILAPA DE ÁLVAREZ",
    value: "CHILAPA DE ÁLVAREZ - GUERRERO",
  },
  {
    text: "CHILPANCINGO DE LOS BRAVO",
    value: "CHILPANCINGO DE LOS BRAVO - GUERRERO",
  },
  {
    text: "COAHUAYUTLA J MA IZAZAGA",
    value: "COAHUAYUTLA J MA IZAZAGA - GUERRERO",
  },
  {
    text: "COCHOAPA EL GRANDE",
    value: "COCHOAPA EL GRANDE - GUERRERO",
  },
  {
    text: "COCULA",
    value: "COCULA - GUERRERO",
  },
  {
    text: "COPALA",
    value: "COPALA - GUERRERO",
  },
  {
    text: "COPALILLO",
    value: "COPALILLO - GUERRERO",
  },
  {
    text: "COPANATOYAC",
    value: "COPANATOYAC - GUERRERO",
  },
  {
    text: "COYUCA DE BENÍTEZ",
    value: "COYUCA DE BENÍTEZ - GUERRERO",
  },
  {
    text: "COYUCA DE CATALÁN",
    value: "COYUCA DE CATALÁN - GUERRERO",
  },
  {
    text: "CUAJINICUILAPA",
    value: "CUAJINICUILAPA - GUERRERO",
  },
  {
    text: "CUALÁC",
    value: "CUALÁC - GUERRERO",
  },
  {
    text: "CUAUTEPEC",
    value: "CUAUTEPEC - GUERRERO",
  },
  {
    text: "CUETZALA DEL PROGRESO",
    value: "CUETZALA DEL PROGRESO - GUERRERO",
  },
  {
    text: "CUTZAMALA DE PINZÓN",
    value: "CUTZAMALA DE PINZÓN - GUERRERO",
  },
  {
    text: "EDUARDO NERI",
    value: "EDUARDO NERI - GUERRERO",
  },
  {
    text: "FLORENCIO VILLARREAL",
    value: "FLORENCIO VILLARREAL - GUERRERO",
  },
  {
    text: "GRAL CANUTO A NERI",
    value: "GRAL CANUTO A NERI - GUERRERO",
  },
  {
    text: "GRAL HELIODORO CASTILLO",
    value: "GRAL HELIODORO CASTILLO - GUERRERO",
  },
  {
    text: "HUAMUXTITLÁN",
    value: "HUAMUXTITLÁN - GUERRERO",
  },
  {
    text: "HUITZUCO DE LOS FIGUEROA",
    value: "HUITZUCO DE LOS FIGUEROA - GUERRERO",
  },
  {
    text: "IGUALA DE LA INDÉPENDENCIA",
    value: "IGUALA DE LA INDÉPENDENCIA - GUERRERO",
  },
  {
    text: "IGUALAPA",
    value: "IGUALAPA - GUERRERO",
  },
  {
    text: "ILIATENCO",
    value: "ILIATENCO - GUERRERO",
  },
  {
    text: "IXCATEOPAN DE CUAUHTÉMOC",
    value: "IXCATEOPAN DE CUAUHTÉMOC - GUERRERO",
  },
  {
    text: "JUAN R ESCUDERO",
    value: "JUAN R ESCUDERO - GUERRERO",
  },
  {
    text: "JUCHITÁN",
    value: "JUCHITÁN - GUERRERO",
  },
  {
    text: "LA UNIÓN DE ISIDORO MTES",
    value: "LA UNIÓN DE ISIDORO MTES - GUERRERO",
  },
  {
    text: "LA UNIÓN",
    value: "LA UNIÓN - GUERRERO",
  },
  {
    text: "LEÓNARDO BRAVO",
    value: "LEÓNARDO BRAVO - GUERRERO",
  },
  {
    text: "MALINALTEPEC",
    value: "MALINALTEPEC - GUERRERO",
  },
  {
    text: "MARQUELIA",
    value: "MARQUELIA - GUERRERO",
  },
  {
    text: "MÁRTIR DE CUILAPAN",
    value: "MÁRTIR DE CUILAPAN - GUERRERO",
  },
  {
    text: "METLATÓNOC",
    value: "METLATÓNOC - GUERRERO",
  },
  {
    text: "MOCHITLÁN",
    value: "MOCHITLÁN - GUERRERO",
  },
  {
    text: "OLINALÁ",
    value: "OLINALÁ - GUERRERO",
  },
  {
    text: "OMETEPEC",
    value: "OMETEPEC - GUERRERO",
  },
  {
    text: "PEDRO ASCENCIO ALQUISIRAS",
    value: "PEDRO ASCENCIO ALQUISIRAS - GUERRERO",
  },
  {
    text: "PETATLÁN",
    value: "PETATLÁN - GUERRERO",
  },
  {
    text: "PILCAYA",
    value: "PILCAYA - GUERRERO",
  },
  {
    text: "PUNGARABATO",
    value: "PUNGARABATO - GUERRERO",
  },
  {
    text: "QUECHULTENANGO",
    value: "QUECHULTENANGO - GUERRERO",
  },
  {
    text: "SAN LUIS ACATLÁN",
    value: "SAN LUIS ACATLÁN - GUERRERO",
  },
  {
    text: "SAN MARCOS",
    value: "SAN MARCOS - GUERRERO",
  },
  {
    text: "SAN MIGUEL TOTOLAPAN",
    value: "SAN MIGUEL TOTOLAPAN - GUERRERO",
  },
  {
    text: "TAXCO DE ALARCÓN",
    value: "TAXCO DE ALARCÓN - GUERRERO",
  },
  {
    text: "TECOANAPA",
    value: "TECOANAPA - GUERRERO",
  },
  {
    text: "TÉCPAN DE GALEANA",
    value: "TÉCPAN DE GALEANA - GUERRERO",
  },
  {
    text: "TELOLOAPAN",
    value: "TELOLOAPAN - GUERRERO",
  },
  {
    text: "TEPECOACUILCO DE TRUJANO",
    value: "TEPECOACUILCO DE TRUJANO - GUERRERO",
  },
  {
    text: "TETIPAC",
    value: "TETIPAC - GUERRERO",
  },
  {
    text: "TIXTLA DE GUERRERO",
    value: "TIXTLA DE GUERRERO - GUERRERO",
  },
  {
    text: "TLACOACHISTLÁHUACA",
    value: "TLACOACHISTLÁHUACA - GUERRERO",
  },
  {
    text: "TLACOAPA",
    value: "TLACOAPA - GUERRERO",
  },
  {
    text: "TLALCHAPA",
    value: "TLALCHAPA - GUERRERO",
  },
  {
    text: "TLALIXTAQUILLA MALDONADO",
    value: "TLALIXTAQUILLA MALDONADO - GUERRERO",
  },
  {
    text: "TLAPA DE COMONFORT",
    value: "TLAPA DE COMONFORT - GUERRERO",
  },
  {
    text: "TLAPEHUALA",
    value: "TLAPEHUALA - GUERRERO",
  },
  {
    text: "VILLA DE TEZONTEPEC",
    value: "VILLA DE TEZONTEPEC - HIDALGO",
  },
  {
    text: "XOCHIATIPAN",
    value: "XOCHIATIPAN - HIDALGO",
  },
  {
    text: "XOCHICOATLÁN",
    value: "XOCHICOATLÁN - HIDALGO",
  },
  {
    text: "YAHUALICA",
    value: "YAHUALICA - HIDALGO",
  },
  {
    text: "ZACUALTIPÁN DE ÁNGELES",
    value: "ZACUALTIPÁN DE ÁNGELES - HIDALGO",
  },
  {
    text: "ZAPOTLÁN DE JUÁREZ",
    value: "ZAPOTLÁN DE JUÁREZ - HIDALGO",
  },
  {
    text: "ZEMPOALA",
    value: "ZEMPOALA - HIDALGO",
  },
  {
    text: "ZIMAPÁN",
    value: "ZIMAPÁN - HIDALGO",
  },
  {
    text: "ACATLÁN",
    value: "ACATLÁN - HIDALGO",
  },
  {
    text: "ACAXOCHITLAN",
    value: "ACAXOCHITLAN - HIDALGO",
  },
  {
    text: "ACTOPAN",
    value: "ACTOPAN - HIDALGO",
  },
  {
    text: "AGUA BLANCA DE ITURBIDE",
    value: "AGUA BLANCA DE ITURBIDE - HIDALGO",
  },
  {
    text: "AJACUBA",
    value: "AJACUBA - HIDALGO",
  },
  {
    text: "ALFAJAYUCAN",
    value: "ALFAJAYUCAN - HIDALGO",
  },
  {
    text: "ALMOLOYA",
    value: "ALMOLOYA - HIDALGO",
  },
  {
    text: "APAN",
    value: "APAN - HIDALGO",
  },
  {
    text: "ATITALAQUIA",
    value: "ATITALAQUIA - HIDALGO",
  },
  {
    text: "ATLAPEXCO",
    value: "ATLAPEXCO - HIDALGO",
  },
  {
    text: "ATOTONILCO DE TULA",
    value: "ATOTONILCO DE TULA - HIDALGO",
  },
  {
    text: "ATOTONILCO EL GRANDE",
    value: "ATOTONILCO EL GRANDE - HIDALGO",
  },
  {
    text: "CALNALI",
    value: "CALNALI - HIDALGO",
  },
  {
    text: "CARDONAL",
    value: "CARDONAL - HIDALGO",
  },
  {
    text: "CHAPANTONGO",
    value: "CHAPANTONGO - HIDALGO",
  },
  {
    text: "CHAPULHUACÁN",
    value: "CHAPULHUACÁN - HIDALGO",
  },
  {
    text: "CHILCUAUTLA",
    value: "CHILCUAUTLA - HIDALGO",
  },
  {
    text: "CRUZ AZUL",
    value: "CRUZ AZUL - HIDALGO",
  },
  {
    text: "CUAUTEPEC DE HINOJOSA",
    value: "CUAUTEPEC DE HINOJOSA - HIDALGO",
  },
  {
    text: "EL ARENAL",
    value: "EL ARENAL - HIDALGO",
  },
  {
    text: "ELOXOCHITLÁN",
    value: "ELOXOCHITLÁN - HIDALGO",
  },
  {
    text: "EMILIANO ZAPATA",
    value: "EMILIANO ZAPATA - HIDALGO",
  },
  {
    text: "EPAZOYÚCAN",
    value: "EPAZOYÚCAN - HIDALGO",
  },
  {
    text: "FRANCISCO I MADERO",
    value: "FRANCISCO I MADERO - HIDALGO",
  },
  {
    text: "HUASCA DE OCAMPO",
    value: "HUASCA DE OCAMPO - HIDALGO",
  },
  {
    text: "HUAUTLA",
    value: "HUAUTLA - HIDALGO",
  },
  {
    text: "HUAZALINGO",
    value: "HUAZALINGO - HIDALGO",
  },
  {
    text: "HUEHUETLA",
    value: "HUEHUETLA - HIDALGO",
  },
  {
    text: "HUEJUTLA DE REYES",
    value: "HUEJUTLA DE REYES - HIDALGO",
  },
  {
    text: "HUICHAPAN",
    value: "HUICHAPAN - HIDALGO",
  },
  {
    text: "IXMIQUILPAN",
    value: "IXMIQUILPAN - HIDALGO",
  },
  {
    text: "JACALA DE LEDEZMA",
    value: "JACALA DE LEDEZMA - HIDALGO",
  },
  {
    text: "JALTOCÁN",
    value: "JALTOCÁN - HIDALGO",
  },
  {
    text: "JUÁREZ HIDALGO",
    value: "JUÁREZ HIDALGO - HIDALGO",
  },
  {
    text: "LA MISIÓN",
    value: "LA MISIÓN - HIDALGO",
  },
  {
    text: "LOLOTLA",
    value: "LOLOTLA - HIDALGO",
  },
  {
    text: "METEPEC",
    value: "METEPEC - HIDALGO",
  },
  {
    text: "METZTITLÁN",
    value: "METZTITLÁN - HIDALGO",
  },
  {
    text: "MINERAL DE LA REFORMA",
    value: "MINERAL DE LA REFORMA - HIDALGO",
  },
  {
    text: "MINERAL DEL CHICO",
    value: "MINERAL DEL CHICO - HIDALGO",
  },
  {
    text: "MINERAL DEL MTE",
    value: "MINERAL DEL MTE - HIDALGO",
  },
  {
    text: "MIXQUIAHUALA DE JUÁREZ",
    value: "MIXQUIAHUALA DE JUÁREZ - HIDALGO",
  },
  {
    text: "MOLANGO DE ESCAMILLA",
    value: "MOLANGO DE ESCAMILLA - HIDALGO",
  },
  {
    text: "NICOLÁS FLORES",
    value: "NICOLÁS FLORES - HIDALGO",
  },
  {
    text: "NOPALA DE VILLAGRAN",
    value: "NOPALA DE VILLAGRAN - HIDALGO",
  },
  {
    text: "OMITLÁN DE JUÁREZ",
    value: "OMITLÁN DE JUÁREZ - HIDALGO",
  },
  {
    text: "PACHUCA DE SOTO",
    value: "PACHUCA DE SOTO - HIDALGO",
  },
  {
    text: "PACULA",
    value: "PACULA - HIDALGO",
  },
  {
    text: "PISAFLORES",
    value: "PISAFLORES - HIDALGO",
  },
  {
    text: "PROGRESO DE OBREGÓN",
    value: "PROGRESO DE OBREGÓN - HIDALGO",
  },
  {
    text: "SGO TULANTEPEC LUGO GUERRERO",
    value: "SGO TULANTEPEC LUGO GUERRERO - HIDALGO",
  },
  {
    text: "SANTIAGO DE ANAYA",
    value: "SANTIAGO DE ANAYA - HIDALGO",
  },
  {
    text: "SGO TULANTEPEC DE LUGO GUERRERO",
    value: "SGO TULANTEPEC DE LUGO GUERRERO - HIDALGO",
  },
  {
    text: "SINGUILUCAN",
    value: "SINGUILUCAN - HIDALGO",
  },
  {
    text: "SAN AGUSTÍN METZQUITITLÁN",
    value: "SAN AGUSTÍN METZQUITITLÁN - HIDALGO",
  },
  {
    text: "SAN AGUSTÍN TLAXIACA",
    value: "SAN AGUSTÍN TLAXIACA - HIDALGO",
  },
  {
    text: "SAN BARTOLO TUTOTEPEC",
    value: "SAN BARTOLO TUTOTEPEC - HIDALGO",
  },
  {
    text: "SAN FELIPE ORIZATLÁN",
    value: "SAN FELIPE ORIZATLÁN - HIDALGO",
  },
  {
    text: "SAN SALVADOR",
    value: "SAN SALVADOR - HIDALGO",
  },
  {
    text: "TASQUILLO",
    value: "TASQUILLO - HIDALGO",
  },
  {
    text: "TECOZAUTLA",
    value: "TECOZAUTLA - HIDALGO",
  },
  {
    text: "TENANGO DE DORIA",
    value: "TENANGO DE DORIA - HIDALGO",
  },
  {
    text: "TEPEAPULCO",
    value: "TEPEAPULCO - HIDALGO",
  },
  {
    text: "TEPEHUACÁN DE GUERRERO",
    value: "TEPEHUACÁN DE GUERRERO - HIDALGO",
  },
  {
    text: "TEPEJI DEL RÍO DE OCAMPO",
    value: "TEPEJI DEL RÍO DE OCAMPO - HIDALGO",
  },
  {
    text: "TEPETITLÁN",
    value: "TEPETITLÁN - HIDALGO",
  },
  {
    text: "TETEPANGO",
    value: "TETEPANGO - HIDALGO",
  },
  {
    text: "TEZONTEPEC DE ALDAMA",
    value: "TEZONTEPEC DE ALDAMA - HIDALGO",
  },
  {
    text: "TIANGUISTENGO",
    value: "TIANGUISTENGO - HIDALGO",
  },
  {
    text: "TIZAYUCA",
    value: "TIZAYUCA - HIDALGO",
  },
  {
    text: "TLAHUELILPAN",
    value: "TLAHUELILPAN - HIDALGO",
  },
  {
    text: "TLAHUILTEPA",
    value: "TLAHUILTEPA - HIDALGO",
  },
  {
    text: "TLANALAPA",
    value: "TLANALAPA - HIDALGO",
  },
  {
    text: "TLANCHINOL",
    value: "TLANCHINOL - HIDALGO",
  },
  {
    text: "TLAXCOAPAN",
    value: "TLAXCOAPAN - HIDALGO",
  },
  {
    text: "TOLCAYUCA",
    value: "TOLCAYUCA - HIDALGO",
  },
  {
    text: "TULA DE ALLENDE",
    value: "TULA DE ALLENDE - HIDALGO",
  },
  {
    text: "TULANCINGO DE BRAVO",
    value: "TULANCINGO DE BRAVO - HIDALGO",
  },
  {
    text: "TULANCINGO",
    value: "TULANCINGO - HIDALGO",
  },
  {
    text: "VALLE DE JUÁREZ",
    value: "VALLE DE JUÁREZ - JALISCO",
  },
  {
    text: "VILLA CORONA",
    value: "VILLA CORONA - JALISCO",
  },
  {
    text: "VILLA GUERRERO",
    value: "VILLA GUERRERO - JALISCO",
  },
  {
    text: "VILLA HIDALGO",
    value: "VILLA HIDALGO - JALISCO",
  },
  {
    text: "VILLA PURIFICACION",
    value: "VILLA PURIFICACION - JALISCO",
  },
  {
    text: "YAHUALICA DE GZLEZ GALLO",
    value: "YAHUALICA DE GZLEZ GALLO - JALISCO",
  },
  {
    text: "ZACOALCO DE TORRES",
    value: "ZACOALCO DE TORRES - JALISCO",
  },
  {
    text: "ZAPOPAN",
    value: "ZAPOPAN - JALISCO",
  },
  {
    text: "ZAPOTILTIC",
    value: "ZAPOTILTIC - JALISCO",
  },
  {
    text: "ZAPOTITLÁN DE VADILLO",
    value: "ZAPOTITLÁN DE VADILLO - JALISCO",
  },
  {
    text: "ZAPOTLÁN DEL REY",
    value: "ZAPOTLÁN DEL REY - JALISCO",
  },
  {
    text: "ZAPOTLÁN EL GRANDE",
    value: "ZAPOTLÁN EL GRANDE - JALISCO",
  },
  {
    text: "ZAPOTLÁNEJO",
    value: "ZAPOTLÁNEJO - JALISCO",
  },
  {
    text: "ACATIC",
    value: "ACATIC - JALISCO",
  },
  {
    text: "ACATLÁN DE JUÁREZ",
    value: "ACATLÁN DE JUÁREZ - JALISCO",
  },
  {
    text: "AHUALULCO DE MERCADO",
    value: "AHUALULCO DE MERCADO - JALISCO",
  },
  {
    text: "AJIJIC",
    value: "AJIJIC - JALISCO",
  },
  {
    text: "AMACUECA",
    value: "AMACUECA - JALISCO",
  },
  {
    text: "AMATITÁN",
    value: "AMATITÁN - JALISCO",
  },
  {
    text: "AMECA",
    value: "AMECA - JALISCO",
  },
  {
    text: "ARANDAS",
    value: "ARANDAS - JALISCO",
  },
  {
    text: "ATEMAJAC DE BRIZUELA",
    value: "ATEMAJAC DE BRIZUELA - JALISCO",
  },
  {
    text: "ATENGO",
    value: "ATENGO - JALISCO",
  },
  {
    text: "ATENGUILLO",
    value: "ATENGUILLO - JALISCO",
  },
  {
    text: "ATOTONILCO EL ALTO",
    value: "ATOTONILCO EL ALTO - JALISCO",
  },
  {
    text: "ATOYAC",
    value: "ATOYAC - JALISCO",
  },
  {
    text: "AUTLÁN DE NAVARRO",
    value: "AUTLÁN DE NAVARRO - JALISCO",
  },
  {
    text: "AYOTLÁN",
    value: "AYOTLÁN - JALISCO",
  },
  {
    text: "AYUTLA",
    value: "AYUTLA - JALISCO",
  },
  {
    text: "BOLANOS",
    value: "BOLANOS - JALISCO",
  },
  {
    text: "CABO CORRIENTES",
    value: "CABO CORRIENTES - JALISCO",
  },
  {
    text: "CANADAS DE OBREGÓN",
    value: "CANADAS DE OBREGÓN - JALISCO",
  },
  {
    text: "CASIMIRO CASTILLO",
    value: "CASIMIRO CASTILLO - JALISCO",
  },
  {
    text: "CHAPALA",
    value: "CHAPALA - JALISCO",
  },
  {
    text: "CHIMALTITÁN",
    value: "CHIMALTITÁN - JALISCO",
  },
  {
    text: "CHIQUILISTLÁN",
    value: "CHIQUILISTLÁN - JALISCO",
  },
  {
    text: "CIHUATLÁN",
    value: "CIHUATLÁN - JALISCO",
  },
  {
    text: "COCULA",
    value: "COCULA - JALISCO",
  },
  {
    text: "COLOTLAN",
    value: "COLOTLAN - JALISCO",
  },
  {
    text: "CONCEPCIÓN DE BUENOS AIRES",
    value: "CONCEPCIÓN DE BUENOS AIRES - JALISCO",
  },
  {
    text: "CUAUTITLÁN DE GARCÍA BARRAGÁN",
    value: "CUAUTITLÁN DE GARCÍA BARRAGÁN - JALISCO",
  },
  {
    text: "CUAUTLA",
    value: "CUAUTLA - JALISCO",
  },
  {
    text: "CUQUÍO",
    value: "CUQUÍO - JALISCO",
  },
  {
    text: "DEGOLLADO",
    value: "DEGOLLADO - JALISCO",
  },
  {
    text: "EJUTLA",
    value: "EJUTLA - JALISCO",
  },
  {
    text: "EL ARENAL",
    value: "EL ARENAL - JALISCO",
  },
  {
    text: "EL GRULLO",
    value: "EL GRULLO - JALISCO",
  },
  {
    text: "EL LIMÓN",
    value: "EL LIMÓN - JALISCO",
  },
  {
    text: "EL SALTO",
    value: "EL SALTO - JALISCO",
  },
  {
    text: "ENCARNACION DE DÍAZ",
    value: "ENCARNACION DE DÍAZ - JALISCO",
  },
  {
    text: "ETZATLÁN",
    value: "ETZATLÁN - JALISCO",
  },
  {
    text: "GÓMEZ FARÍAS",
    value: "GÓMEZ FARÍAS - JALISCO",
  },
  {
    text: "GUACHINANGO",
    value: "GUACHINANGO - JALISCO",
  },
  {
    text: "GUADALAJARA",
    value: "GUADALAJARA - JALISCO",
  },
  {
    text: "HOSTOTIPAQUILLO",
    value: "HOSTOTIPAQUILLO - JALISCO",
  },
  {
    text: "HUEJÚCAR",
    value: "HUEJÚCAR - JALISCO",
  },
  {
    text: "HUEJUQUILLA EL ALTO",
    value: "HUEJUQUILLA EL ALTO - JALISCO",
  },
  {
    text: "IXTLAHUACÁN DEL RÍO",
    value: "IXTLAHUACÁN DEL RÍO - JALISCO",
  },
  {
    text: "IXTLAHUACÁN LOS MEMBRILLOS",
    value: "IXTLAHUACÁN LOS MEMBRILLOS - JALISCO",
  },
  {
    text: "JALOSTOTITLÁN",
    value: "JALOSTOTITLÁN - JALISCO",
  },
  {
    text: "JAMAY",
    value: "JAMAY - JALISCO",
  },
  {
    text: "JESÚS MARÍA",
    value: "JESÚS MARÍA - JALISCO",
  },
  {
    text: "JILOTLÁN DE LOS DOLORES",
    value: "JILOTLÁN DE LOS DOLORES - JALISCO",
  },
  {
    text: "JOCOTEPEC",
    value: "JOCOTEPEC - JALISCO",
  },
  {
    text: "JUANACATLÁN",
    value: "JUANACATLÁN - JALISCO",
  },
  {
    text: "JUCHITLAN",
    value: "JUCHITLAN - JALISCO",
  },
  {
    text: "LA BARCA",
    value: "LA BARCA - JALISCO",
  },
  {
    text: "LA HUERTA",
    value: "LA HUERTA - JALISCO",
  },
  {
    text: "LA MANZANILLA DE LA PAZ",
    value: "LA MANZANILLA DE LA PAZ - JALISCO",
  },
  {
    text: "LAGOS DE MORENO",
    value: "LAGOS DE MORENO - JALISCO",
  },
  {
    text: "MAGDALENA",
    value: "MAGDALENA - JALISCO",
  },
  {
    text: "MASCOTA",
    value: "MASCOTA - JALISCO",
  },
  {
    text: "MAZAMITLA",
    value: "MAZAMITLA - JALISCO",
  },
  {
    text: "MEXTICACÁN",
    value: "MEXTICACÁN - JALISCO",
  },
  {
    text: "MEZQUITIC",
    value: "MEZQUITIC - JALISCO",
  },
  {
    text: "MIXTLÁN",
    value: "MIXTLÁN - JALISCO",
  },
  {
    text: "OCOTLÁN",
    value: "OCOTLÁN - JALISCO",
  },
  {
    text: "OJUELOS DE JALISCO",
    value: "OJUELOS DE JALISCO - JALISCO",
  },
  {
    text: "PIHUAMO",
    value: "PIHUAMO - JALISCO",
  },
  {
    text: "PONCITLÁN",
    value: "PONCITLÁN - JALISCO",
  },
  {
    text: "PUERTO VALLARTA",
    value: "PUERTO VALLARTA - JALISCO",
  },
  {
    text: "QUITUPAN",
    value: "QUITUPAN - JALISCO",
  },
  {
    text: "SAN IGNACIO CERRO GORDO",
    value: "SAN IGNACIO CERRO GORDO - JALISCO",
  },
  {
    text: "SANTA MARÍA DE LOS ÁNGELES",
    value: "SANTA MARÍA DE LOS ÁNGELES - JALISCO",
  },
  {
    text: "SANTA MARÍA DEL ORO",
    value: "SANTA MARÍA DEL ORO - JALISCO",
  },
  {
    text: "SAYULA",
    value: "SAYULA - JALISCO",
  },
  {
    text: "SAN CRISTÓBAL DE LA BARRANCA",
    value: "SAN CRISTÓBAL DE LA BARRANCA - JALISCO",
  },
  {
    text: "SAN DIEGO DE ALEJANDRÍA",
    value: "SAN DIEGO DE ALEJANDRÍA - JALISCO",
  },
  {
    text: "SAN GABRIEL",
    value: "SAN GABRIEL - JALISCO",
  },
  {
    text: "SAN JUAN DE LOS LAGOS",
    value: "SAN JUAN DE LOS LAGOS - JALISCO",
  },
  {
    text: "SAN JUANITO DE ESCOBEDO",
    value: "SAN JUANITO DE ESCOBEDO - JALISCO",
  },
  {
    text: "SAN JULIÁN",
    value: "SAN JULIÁN - JALISCO",
  },
  {
    text: "SAN MARCOS",
    value: "SAN MARCOS - JALISCO",
  },
  {
    text: "SAN MARTÍN DE BOLAÑOS",
    value: "SAN MARTÍN DE BOLAÑOS - JALISCO",
  },
  {
    text: "SAN MARTÍN HIDALGO",
    value: "SAN MARTÍN HIDALGO - JALISCO",
  },
  {
    text: "SAN MIGUEL EL ALTO",
    value: "SAN MIGUEL EL ALTO - JALISCO",
  },
  {
    text: "SAN SEBASTIÁN DEL OESTE",
    value: "SAN SEBASTIÁN DEL OESTE - JALISCO",
  },
  {
    text: "TALA",
    value: "TALA - JALISCO",
  },
  {
    text: "TALPA DE ALLENDE",
    value: "TALPA DE ALLENDE - JALISCO",
  },
  {
    text: "TAMAZULA DE GORDIANO",
    value: "TAMAZULA DE GORDIANO - JALISCO",
  },
  {
    text: "TAMAZULA",
    value: "TAMAZULA - JALISCO",
  },
  {
    text: "TAPALPA",
    value: "TAPALPA - JALISCO",
  },
  {
    text: "TECALITLÁN",
    value: "TECALITLÁN - JALISCO",
  },
  {
    text: "TECHALUTA DE MONTENEGRO",
    value: "TECHALUTA DE MONTENEGRO - JALISCO",
  },
  {
    text: "TECOLOTLAN",
    value: "TECOLOTLAN - JALISCO",
  },
  {
    text: "TENAMAXTLÁN",
    value: "TENAMAXTLÁN - JALISCO",
  },
  {
    text: "TEOCALTICHE",
    value: "TEOCALTICHE - JALISCO",
  },
  {
    text: "TEOCUITATLÁN DE CORONA",
    value: "TEOCUITATLÁN DE CORONA - JALISCO",
  },
  {
    text: "TEPATITLÁN",
    value: "TEPATITLÁN - JALISCO",
  },
  {
    text: "TEQUILA",
    value: "TEQUILA - JALISCO",
  },
  {
    text: "TEUCHITLÁN",
    value: "TEUCHITLÁN - JALISCO",
  },
  {
    text: "TIZAPÁN EL ALTO",
    value: "TIZAPÁN EL ALTO - JALISCO",
  },
  {
    text: "TLAJOMULCO DE ZUNIGA",
    value: "TLAJOMULCO DE ZUNIGA - JALISCO",
  },
  {
    text: "TLAQUEPAQUE",
    value: "TLAQUEPAQUE - JALISCO",
  },
  {
    text: "TOLIMÁN",
    value: "TOLIMÁN - JALISCO",
  },
  {
    text: "TOMATLÁN",
    value: "TOMATLÁN - JALISCO",
  },
  {
    text: "TONALÁ",
    value: "TONALÁ - JALISCO",
  },
  {
    text: "TONAYA",
    value: "TONAYA - JALISCO",
  },
  {
    text: "TONILA",
    value: "TONILA - JALISCO",
  },
  {
    text: "TOTATICHE",
    value: "TOTATICHE - JALISCO",
  },
  {
    text: "TOTOTLÁN",
    value: "TOTOTLÁN - JALISCO",
  },
  {
    text: "TUXCACUÉSCO",
    value: "TUXCACUÉSCO - JALISCO",
  },
  {
    text: "TUXCUECA",
    value: "TUXCUECA - JALISCO",
  },
  {
    text: "TUXPAN",
    value: "TUXPAN - JALISCO",
  },
  {
    text: "UNIÓN DE SAN ANTONIO",
    value: "UNIÓN DE SAN ANTONIO - JALISCO",
  },
  {
    text: "UNIÓN DE TULA",
    value: "UNIÓN DE TULA - JALISCO",
  },
  {
    text: "VALLE DE GUADALUPE",
    value: "VALLE DE GUADALUPE - JALISCO",
  },
  {
    text: "VILLA DE ALLENDE",
    value: "VILLA DE ALLENDE - MÉXICO",
  },
  {
    text: "VILLA DEL CARBÓN",
    value: "VILLA DEL CARBÓN - MÉXICO",
  },
  {
    text: "VILLA GUERRERO",
    value: "VILLA GUERRERO - MÉXICO",
  },
  {
    text: "VILLA VICTORIA",
    value: "VILLA VICTORIA - MÉXICO",
  },
  {
    text: "XALATLACO",
    value: "XALATLACO - MÉXICO",
  },
  {
    text: "XONACATLÁN",
    value: "XONACATLÁN - MÉXICO",
  },
  {
    text: "ZACAZONAPAN",
    value: "ZACAZONAPAN - MÉXICO",
  },
  {
    text: "ZACUALPAN",
    value: "ZACUALPAN - MÉXICO",
  },
  {
    text: "ZINACANTEPEC",
    value: "ZINACANTEPEC - MÉXICO",
  },
  {
    text: "ZUMPAHUACÁN",
    value: "ZUMPAHUACÁN - MÉXICO",
  },
  {
    text: "ZUMPANGO",
    value: "ZUMPANGO - MÉXICO",
  },
  {
    text: "ACAMBAY",
    value: "ACAMBAY - MÉXICO",
  },
  {
    text: "ACOLMAN",
    value: "ACOLMAN - MÉXICO",
  },
  {
    text: "ACULCO",
    value: "ACULCO - MÉXICO",
  },
  {
    text: "ALMOLOYA DE ALQUISIRAS",
    value: "ALMOLOYA DE ALQUISIRAS - MÉXICO",
  },
  {
    text: "ALMOLOYA DE JUÁREZ",
    value: "ALMOLOYA DE JUÁREZ - MÉXICO",
  },
  {
    text: "ALMOLOYA DEL RÍO",
    value: "ALMOLOYA DEL RÍO - MÉXICO",
  },
  {
    text: "AMANALCO",
    value: "AMANALCO - MÉXICO",
  },
  {
    text: "AMATEPEC",
    value: "AMATEPEC - MÉXICO",
  },
  {
    text: "AMECAMECA",
    value: "AMECAMECA - MÉXICO",
  },
  {
    text: "APAXCO",
    value: "APAXCO - MÉXICO",
  },
  {
    text: "ATENCO",
    value: "ATENCO - MÉXICO",
  },
  {
    text: "ATIZAPÁN DE ZARAGOZA",
    value: "ATIZAPÁN DE ZARAGOZA - MÉXICO",
  },
  {
    text: "ATIZAPÁN",
    value: "ATIZAPÁN - MÉXICO",
  },
  {
    text: "ATLACOMULCO",
    value: "ATLACOMULCO - MÉXICO",
  },
  {
    text: "ATLAUTLA",
    value: "ATLAUTLA - MÉXICO",
  },
  {
    text: "AXAPUSCO",
    value: "AXAPUSCO - MÉXICO",
  },
  {
    text: "AYAPANGO",
    value: "AYAPANGO - MÉXICO",
  },
  {
    text: "CALIMAYA",
    value: "CALIMAYA - MÉXICO",
  },
  {
    text: "CAPULHUAC",
    value: "CAPULHUAC - MÉXICO",
  },
  {
    text: "CHALCO",
    value: "CHALCO - MÉXICO",
  },
  {
    text: "CHAPA DE MOTA",
    value: "CHAPA DE MOTA - MÉXICO",
  },
  {
    text: "CHAPULTEPEC",
    value: "CHAPULTEPEC - MÉXICO",
  },
  {
    text: "CHIAUTLA",
    value: "CHIAUTLA - MÉXICO",
  },
  {
    text: "CHICOLOAPAN",
    value: "CHICOLOAPAN - MÉXICO",
  },
  {
    text: "CHICONCUAC",
    value: "CHICONCUAC - MÉXICO",
  },
  {
    text: "CHIMALHUACÁN",
    value: "CHIMALHUACÁN - MÉXICO",
  },
  {
    text: "COACALCO DE BERRÍOZÁBAL",
    value: "COACALCO DE BERRÍOZÁBAL - MÉXICO",
  },
  {
    text: "COATEPEC HARINAS",
    value: "COATEPEC HARINAS - MÉXICO",
  },
  {
    text: "COCOTITLÁN",
    value: "COCOTITLÁN - MÉXICO",
  },
  {
    text: "COYOTEPEC",
    value: "COYOTEPEC - MÉXICO",
  },
  {
    text: "CUAUTITLÁN",
    value: "CUAUTITLÁN - MÉXICO",
  },
  {
    text: "CUAUTITLÁN IZCALLI",
    value: "CUAUTITLÁN IZCALLI - MÉXICO",
  },
  {
    text: "DONATO GUERRA",
    value: "DONATO GUERRA - MÉXICO",
  },
  {
    text: "ECATEPEC DE MORELOS",
    value: "ECATEPEC DE MORELOS - MÉXICO",
  },
  {
    text: "ECATZINGO",
    value: "ECATZINGO - MÉXICO",
  },
  {
    text: "EL ORO",
    value: "EL ORO - MÉXICO",
  },
  {
    text: "HUEHUETOCA",
    value: "HUEHUETOCA - MÉXICO",
  },
  {
    text: "HUEYPOXTLA",
    value: "HUEYPOXTLA - MÉXICO",
  },
  {
    text: "HUIXQUILUCAN",
    value: "HUIXQUILUCAN - MÉXICO",
  },
  {
    text: "ISIDRO FABELA",
    value: "ISIDRO FABELA - MÉXICO",
  },
  {
    text: "IXTAPALUCA",
    value: "IXTAPALUCA - MÉXICO",
  },
  {
    text: "IXTAPAN DE LA SAL",
    value: "IXTAPAN DE LA SAL - MÉXICO",
  },
  {
    text: "IXTAPAN DEL ORO",
    value: "IXTAPAN DEL ORO - MÉXICO",
  },
  {
    text: "IXTLÁHUACA",
    value: "IXTLÁHUACA - MÉXICO",
  },
  {
    text: "JALTENCO",
    value: "JALTENCO - MÉXICO",
  },
  {
    text: "JILOTEPEC",
    value: "JILOTEPEC - MÉXICO",
  },
  {
    text: "JILOTZINGO",
    value: "JILOTZINGO - MÉXICO",
  },
  {
    text: "JIQUIPILCO",
    value: "JIQUIPILCO - MÉXICO",
  },
  {
    text: "JOCOTITLÁN",
    value: "JOCOTITLÁN - MÉXICO",
  },
  {
    text: "JOQUICINGO",
    value: "JOQUICINGO - MÉXICO",
  },
  {
    text: "JUCHITEPEC",
    value: "JUCHITEPEC - MÉXICO",
  },
  {
    text: "LA PAZ",
    value: "LA PAZ - MÉXICO",
  },
  {
    text: "LERMA",
    value: "LERMA - MÉXICO",
  },
  {
    text: "LUVIANOS",
    value: "LUVIANOS - MÉXICO",
  },
  {
    text: "MALINALCO",
    value: "MALINALCO - MÉXICO",
  },
  {
    text: "MELCHOR OCAMPO",
    value: "MELCHOR OCAMPO - MÉXICO",
  },
  {
    text: "METEPEC",
    value: "METEPEC - MÉXICO",
  },
  {
    text: "MEXICALTZINGO",
    value: "MEXICALTZINGO - MÉXICO",
  },
  {
    text: "MORELOS",
    value: "MORELOS - MÉXICO",
  },
  {
    text: "NAUCALPAN DE JUÁREZ",
    value: "NAUCALPAN DE JUÁREZ - MÉXICO",
  },
  {
    text: "NEXTLALPAN",
    value: "NEXTLALPAN - MÉXICO",
  },
  {
    text: "NEZAHUALCÓYOTL",
    value: "NEZAHUALCÓYOTL - MÉXICO",
  },
  {
    text: "NICOLÁS ROMERO",
    value: "NICOLÁS ROMERO - MÉXICO",
  },
  {
    text: "NOPALTEPEC",
    value: "NOPALTEPEC - MÉXICO",
  },
  {
    text: "OCOYOACAC",
    value: "OCOYOACAC - MÉXICO",
  },
  {
    text: "OCUILAN",
    value: "OCUILAN - MÉXICO",
  },
  {
    text: "OTUMBA",
    value: "OTUMBA - MÉXICO",
  },
  {
    text: "OTZOLOAPAN",
    value: "OTZOLOAPAN - MÉXICO",
  },
  {
    text: "OTZOLOTEPEC",
    value: "OTZOLOTEPEC - MÉXICO",
  },
  {
    text: "OZUMBA",
    value: "OZUMBA - MÉXICO",
  },
  {
    text: "PÁPALOTLA",
    value: "PÁPALOTLA - MÉXICO",
  },
  {
    text: "POLOTITLAN",
    value: "POLOTITLAN - MÉXICO",
  },
  {
    text: "RAYÓN",
    value: "RAYÓN - MÉXICO",
  },
  {
    text: "SANTO TOMÁS",
    value: "SANTO TOMÁS - MÉXICO",
  },
  {
    text: "SAN ANTONIO LA ISLA",
    value: "SAN ANTONIO LA ISLA - MÉXICO",
  },
  {
    text: "SAN FELIPE DEL PROGRESO",
    value: "SAN FELIPE DEL PROGRESO - MÉXICO",
  },
  {
    text: "SAN JOSÉ DEL RINCÓN",
    value: "SAN JOSÉ DEL RINCÓN - MÉXICO",
  },
  {
    text: "SAN MARTÍN DE LAS PIRAMIDES",
    value: "SAN MARTÍN DE LAS PIRAMIDES - MÉXICO",
  },
  {
    text: "SAN MATEO ATENCO",
    value: "SAN MATEO ATENCO - MÉXICO",
  },
  {
    text: "SAN SIMÓN DE GUERRERO",
    value: "SAN SIMÓN DE GUERRERO - MÉXICO",
  },
  {
    text: "SOYANIQUILPAN DE JUÁREZ",
    value: "SOYANIQUILPAN DE JUÁREZ - MÉXICO",
  },
  {
    text: "SULTEPEC",
    value: "SULTEPEC - MÉXICO",
  },
  {
    text: "TECÁMAC",
    value: "TECÁMAC - MÉXICO",
  },
  {
    text: "TEJUPILCO",
    value: "TEJUPILCO - MÉXICO",
  },
  {
    text: "TEMAMATLA",
    value: "TEMAMATLA - MÉXICO",
  },
  {
    text: "TEMASCALAPA",
    value: "TEMASCALAPA - MÉXICO",
  },
  {
    text: "TEMASCALCINGO",
    value: "TEMASCALCINGO - MÉXICO",
  },
  {
    text: "TEMASCALTEPEC",
    value: "TEMASCALTEPEC - MÉXICO",
  },
  {
    text: "TEMOAYA",
    value: "TEMOAYA - MÉXICO",
  },
  {
    text: "TENANCINGO",
    value: "TENANCINGO - MÉXICO",
  },
  {
    text: "TENANGO DEL AIRE",
    value: "TENANGO DEL AIRE - MÉXICO",
  },
  {
    text: "TENANGO DEL VALLE",
    value: "TENANGO DEL VALLE - MÉXICO",
  },
  {
    text: "TEOLOYUCAN",
    value: "TEOLOYUCAN - MÉXICO",
  },
  {
    text: "TEOTIHUACÁN",
    value: "TEOTIHUACÁN - MÉXICO",
  },
  {
    text: "TEPETLAOXTOC",
    value: "TEPETLAOXTOC - MÉXICO",
  },
  {
    text: "TEPETLIXPA",
    value: "TEPETLIXPA - MÉXICO",
  },
  {
    text: "TEPOTZOTLÁN",
    value: "TEPOTZOTLÁN - MÉXICO",
  },
  {
    text: "TEQUIXQUIAC",
    value: "TEQUIXQUIAC - MÉXICO",
  },
  {
    text: "TEXCALTITLÁN",
    value: "TEXCALTITLÁN - MÉXICO",
  },
  {
    text: "TEXCALYACAC",
    value: "TEXCALYACAC - MÉXICO",
  },
  {
    text: "TEXCOCO DE MORA",
    value: "TEXCOCO DE MORA - MÉXICO",
  },
  {
    text: "TEXCOCO",
    value: "TEXCOCO - MÉXICO",
  },
  {
    text: "TEZOYUCA",
    value: "TEZOYUCA - MÉXICO",
  },
  {
    text: "TIANGUISTENCO",
    value: "TIANGUISTENCO - MÉXICO",
  },
  {
    text: "TIMILPAN",
    value: "TIMILPAN - MÉXICO",
  },
  {
    text: "TLALMANALCO",
    value: "TLALMANALCO - MÉXICO",
  },
  {
    text: "TLALNEPANTLA DE BAZ",
    value: "TLALNEPANTLA DE BAZ - MÉXICO",
  },
  {
    text: "TLATLAYA",
    value: "TLATLAYA - MÉXICO",
  },
  {
    text: "TOLUCA DE LERDO",
    value: "TOLUCA DE LERDO - MÉXICO",
  },
  {
    text: "TOLUCA",
    value: "TOLUCA - MÉXICO",
  },
  {
    text: "TONANITLA",
    value: "TONANITLA - MÉXICO",
  },
  {
    text: "TONATICO",
    value: "TONATICO - MÉXICO",
  },
  {
    text: "TULTEPEC",
    value: "TULTEPEC - MÉXICO",
  },
  {
    text: "TULTITLÁN",
    value: "TULTITLÁN - MÉXICO",
  },
  {
    text: "VALLE D CHALCO SOLIDARIDAD",
    value: "VALLE D CHALCO SOLIDARIDAD - MÉXICO",
  },
  {
    text: "VALLE DE BRAVO",
    value: "VALLE DE BRAVO - MÉXICO",
  },
  {
    text: "VILLAMAR",
    value: "VILLAMAR - MICHOACÁN",
  },
  {
    text: "VISTA HERMOSA",
    value: "VISTA HERMOSA - MICHOACÁN",
  },
  {
    text: "YURÉCUARO",
    value: "YURÉCUARO - MICHOACÁN",
  },
  {
    text: "ZACAPU",
    value: "ZACAPU - MICHOACÁN",
  },
  {
    text: "ZAMORA DE HIDALGO",
    value: "ZAMORA DE HIDALGO - MICHOACÁN",
  },
  {
    text: "ZAMORA",
    value: "ZAMORA - MICHOACÁN",
  },
  {
    text: "ZINAPARO",
    value: "ZINAPARO - MICHOACÁN",
  },
  {
    text: "ZINAPÉCUARO",
    value: "ZINAPÉCUARO - MICHOACÁN",
  },
  {
    text: "ZIRACUARETIRO",
    value: "ZIRACUARETIRO - MICHOACÁN",
  },
  {
    text: "ZITÁCUARO",
    value: "ZITÁCUARO - MICHOACÁN",
  },
  {
    text: "ACUITZIO",
    value: "ACUITZIO - MICHOACÁN",
  },
  {
    text: "AGUILILLA",
    value: "AGUILILLA - MICHOACÁN",
  },
  {
    text: "ÁLVARO OBREGÓN",
    value: "ÁLVARO OBREGÓN - MICHOACÁN",
  },
  {
    text: "ANGAMACUTIRO",
    value: "ANGAMACUTIRO - MICHOACÁN",
  },
  {
    text: "ANGANGUEO",
    value: "ANGANGUEO - MICHOACÁN",
  },
  {
    text: "APATZINGÁN",
    value: "APATZINGÁN - MICHOACÁN",
  },
  {
    text: "APORO",
    value: "APORO - MICHOACÁN",
  },
  {
    text: "AQUILA",
    value: "AQUILA - MICHOACÁN",
  },
  {
    text: "ARÍO",
    value: "ARÍO - MICHOACÁN",
  },
  {
    text: "ARTEAGA",
    value: "ARTEAGA - MICHOACÁN",
  },
  {
    text: "BRISENAS",
    value: "BRISENAS - MICHOACÁN",
  },
  {
    text: "BUENAVISTA",
    value: "BUENAVISTA - MICHOACÁN",
  },
  {
    text: "CARÁCUARO",
    value: "CARÁCUARO - MICHOACÁN",
  },
  {
    text: "CD HIDALGO",
    value: "CD HIDALGO - MICHOACÁN",
  },
  {
    text: "CHARAPAN",
    value: "CHARAPAN - MICHOACÁN",
  },
  {
    text: "CHARO",
    value: "CHARO - MICHOACÁN",
  },
  {
    text: "CHAVINDA",
    value: "CHAVINDA - MICHOACÁN",
  },
  {
    text: "CHERÁN",
    value: "CHERÁN - MICHOACÁN",
  },
  {
    text: "CHILCHOTA",
    value: "CHILCHOTA - MICHOACÁN",
  },
  {
    text: "CHINICUILA",
    value: "CHINICUILA - MICHOACÁN",
  },
  {
    text: "CHUCÁNDIRO",
    value: "CHUCÁNDIRO - MICHOACÁN",
  },
  {
    text: "CHURINTZIO",
    value: "CHURINTZIO - MICHOACÁN",
  },
  {
    text: "CHURUMUCO",
    value: "CHURUMUCO - MICHOACÁN",
  },
  {
    text: "COAHUAYANA",
    value: "COAHUAYANA - MICHOACÁN",
  },
  {
    text: "COALCOMÁN DE VÁZQUEZ PALLARES",
    value: "COALCOMÁN DE VÁZQUEZ PALLARES - MICHOACÁN",
  },
  {
    text: "COENEO",
    value: "COENEO - MICHOACÁN",
  },
  {
    text: "COJUMATLÁN DE RÉGULES",
    value: "COJUMATLÁN DE RÉGULES - MICHOACÁN",
  },
  {
    text: "CONTEPEC",
    value: "CONTEPEC - MICHOACÁN",
  },
  {
    text: "COPÁNDARO",
    value: "COPÁNDARO - MICHOACÁN",
  },
  {
    text: "COTIJA",
    value: "COTIJA - MICHOACÁN",
  },
  {
    text: "CUITZEO",
    value: "CUITZEO - MICHOACÁN",
  },
  {
    text: "ECUANDUREO",
    value: "ECUANDUREO - MICHOACÁN",
  },
  {
    text: "EPITACIO HUERTA",
    value: "EPITACIO HUERTA - MICHOACÁN",
  },
  {
    text: "ERONGARÍCUARO",
    value: "ERONGARÍCUARO - MICHOACÁN",
  },
  {
    text: "GABRIEL ZAMORA",
    value: "GABRIEL ZAMORA - MICHOACÁN",
  },
  {
    text: "HIDALGO",
    value: "HIDALGO - MICHOACÁN",
  },
  {
    text: "HUANDACAREO",
    value: "HUANDACAREO - MICHOACÁN",
  },
  {
    text: "HUANIQUEO",
    value: "HUANIQUEO - MICHOACÁN",
  },
  {
    text: "HUETAMO",
    value: "HUETAMO - MICHOACÁN",
  },
  {
    text: "HUIRAMBA",
    value: "HUIRAMBA - MICHOACÁN",
  },
  {
    text: "INDAPARAPEO",
    value: "INDAPARAPEO - MICHOACÁN",
  },
  {
    text: "IRIMBO",
    value: "IRIMBO - MICHOACÁN",
  },
  {
    text: "IXTLÁN",
    value: "IXTLÁN - MICHOACÁN",
  },
  {
    text: "JACONA",
    value: "JACONA - MICHOACÁN",
  },
  {
    text: "JIMÉNEZ",
    value: "JIMÉNEZ - MICHOACÁN",
  },
  {
    text: "JIQUILPAN",
    value: "JIQUILPAN - MICHOACÁN",
  },
  {
    text: "JOSÉ SIXTO VERDUZCO",
    value: "JOSÉ SIXTO VERDUZCO - MICHOACÁN",
  },
  {
    text: "JUÁREZ",
    value: "JUÁREZ - MICHOACÁN",
  },
  {
    text: "JUNGAPEO",
    value: "JUNGAPEO - MICHOACÁN",
  },
  {
    text: "LA HUACANA",
    value: "LA HUACANA - MICHOACÁN",
  },
  {
    text: "LA PIEDAD",
    value: "LA PIEDAD - MICHOACÁN",
  },
  {
    text: "LAGUNILLAS",
    value: "LAGUNILLAS - MICHOACÁN",
  },
  {
    text: "LAS GUACAMAYAS",
    value: "LAS GUACAMAYAS - MICHOACÁN",
  },
  {
    text: "LÁZARO CÁRDENAS",
    value: "LÁZARO CÁRDENAS - MICHOACÁN",
  },
  {
    text: "LOS REYES",
    value: "LOS REYES - MICHOACÁN",
  },
  {
    text: "MADERO",
    value: "MADERO - MICHOACÁN",
  },
  {
    text: "MARAVATÍO",
    value: "MARAVATÍO - MICHOACÁN",
  },
  {
    text: "MARCOS CASTELLANOS",
    value: "MARCOS CASTELLANOS - MICHOACÁN",
  },
  {
    text: "MORELIA",
    value: "MORELIA - MICHOACÁN",
  },
  {
    text: "MORELOS",
    value: "MORELOS - MICHOACÁN",
  },
  {
    text: "MÚGICA",
    value: "MÚGICA - MICHOACÁN",
  },
  {
    text: "NAHUATZEN",
    value: "NAHUATZEN - MICHOACÁN",
  },
  {
    text: "NOCUPÉTARO",
    value: "NOCUPÉTARO - MICHOACÁN",
  },
  {
    text: "NUEVO PARANGARICUTIRO",
    value: "NUEVO PARANGARICUTIRO - MICHOACÁN",
  },
  {
    text: "NUEVO URECHO",
    value: "NUEVO URECHO - MICHOACÁN",
  },
  {
    text: "NUMARÁN",
    value: "NUMARÁN - MICHOACÁN",
  },
  {
    text: "OCAMPO",
    value: "OCAMPO - MICHOACÁN",
  },
  {
    text: "PAJACUARAN",
    value: "PAJACUARAN - MICHOACÁN",
  },
  {
    text: "PANINDÍCUARO",
    value: "PANINDÍCUARO - MICHOACÁN",
  },
  {
    text: "PARACHO",
    value: "PARACHO - MICHOACÁN",
  },
  {
    text: "PARÁCUARO",
    value: "PARÁCUARO - MICHOACÁN",
  },
  {
    text: "PÁTZCUARO",
    value: "PÁTZCUARO - MICHOACÁN",
  },
  {
    text: "PENJAMILLO",
    value: "PENJAMILLO - MICHOACÁN",
  },
  {
    text: "PERIBÁN",
    value: "PERIBÁN - MICHOACÁN",
  },
  {
    text: "PURÉPERO",
    value: "PURÉPERO - MICHOACÁN",
  },
  {
    text: "PURUÁNDIRO",
    value: "PURUÁNDIRO - MICHOACÁN",
  },
  {
    text: "QUERÉNDARO",
    value: "QUERÉNDARO - MICHOACÁN",
  },
  {
    text: "QUIROGA",
    value: "QUIROGA - MICHOACÁN",
  },
  {
    text: "SAHUAYO",
    value: "SAHUAYO - MICHOACÁN",
  },
  {
    text: "SALVADOR ESCALANTE",
    value: "SALVADOR ESCALANTE - MICHOACÁN",
  },
  {
    text: "SANTA ANA MAYA",
    value: "SANTA ANA MAYA - MICHOACÁN",
  },
  {
    text: "SENGUIO",
    value: "SENGUIO - MICHOACÁN",
  },
  {
    text: "SAN LUCAS",
    value: "SAN LUCAS - MICHOACÁN",
  },
  {
    text: "SUSUPUATO",
    value: "SUSUPUATO - MICHOACÁN",
  },
  {
    text: "TACÁMBARO",
    value: "TACÁMBARO - MICHOACÁN",
  },
  {
    text: "TANCÍTARO",
    value: "TANCÍTARO - MICHOACÁN",
  },
  {
    text: "TANGAMANDAPIO",
    value: "TANGAMANDAPIO - MICHOACÁN",
  },
  {
    text: "TANGANCICUARO",
    value: "TANGANCICUARO - MICHOACÁN",
  },
  {
    text: "TANHUATO",
    value: "TANHUATO - MICHOACÁN",
  },
  {
    text: "TARETAN",
    value: "TARETAN - MICHOACÁN",
  },
  {
    text: "TARÍMBARO",
    value: "TARÍMBARO - MICHOACÁN",
  },
  {
    text: "TEPALCATEPEC",
    value: "TEPALCATEPEC - MICHOACÁN",
  },
  {
    text: "TINGAMBATO",
    value: "TINGAMBATO - MICHOACÁN",
  },
  {
    text: "TINGUINDÍN",
    value: "TINGUINDÍN - MICHOACÁN",
  },
  {
    text: "TIQUICHEO DE NICOLÁS ROM",
    value: "TIQUICHEO DE NICOLÁS ROM - MICHOACÁN",
  },
  {
    text: "TLALPUJAHUA",
    value: "TLALPUJAHUA - MICHOACÁN",
  },
  {
    text: "TLAZAZALCA",
    value: "TLAZAZALCA - MICHOACÁN",
  },
  {
    text: "TOCUMBO",
    value: "TOCUMBO - MICHOACÁN",
  },
  {
    text: "TUMBISCATIO",
    value: "TUMBISCATIO - MICHOACÁN",
  },
  {
    text: "TURICATO",
    value: "TURICATO - MICHOACÁN",
  },
  {
    text: "TUXPAN",
    value: "TUXPAN - MICHOACÁN",
  },
  {
    text: "TUZANTLA",
    value: "TUZANTLA - MICHOACÁN",
  },
  {
    text: "TZINTZUNTZAN",
    value: "TZINTZUNTZAN - MICHOACÁN",
  },
  {
    text: "TZITZIO",
    value: "TZITZIO - MICHOACÁN",
  },
  {
    text: "URUAPAN",
    value: "URUAPAN - MICHOACÁN",
  },
  {
    text: "VENUSTIANO CARRANZA",
    value: "VENUSTIANO CARRANZA - MICHOACÁN",
  },
  {
    text: "XOCHITEPEC",
    value: "XOCHITEPEC - MORELOS",
  },
  {
    text: "YAUTEPEC",
    value: "YAUTEPEC - MORELOS",
  },
  {
    text: "YECAPIXTLA",
    value: "YECAPIXTLA - MORELOS",
  },
  {
    text: "ZACATEPEC",
    value: "ZACATEPEC - MORELOS",
  },
  {
    text: "ZACUALPAN",
    value: "ZACUALPAN - MORELOS",
  },
  {
    text: "AMACUZAC",
    value: "AMACUZAC - MORELOS",
  },
  {
    text: "ATLATLAHUCAN",
    value: "ATLATLAHUCAN - MORELOS",
  },
  {
    text: "AXOCHIAPAN",
    value: "AXOCHIAPAN - MORELOS",
  },
  {
    text: "AYALA",
    value: "AYALA - MORELOS",
  },
  {
    text: "COATLAN DEL RÍO",
    value: "COATLAN DEL RÍO - MORELOS",
  },
  {
    text: "CUAUTLA",
    value: "CUAUTLA - MORELOS",
  },
  {
    text: "CUERNAVACA",
    value: "CUERNAVACA - MORELOS",
  },
  {
    text: "EMILIANO ZAPATA",
    value: "EMILIANO ZAPATA - MORELOS",
  },
  {
    text: "GALEANA",
    value: "GALEANA - MORELOS",
  },
  {
    text: "HUITZILAC",
    value: "HUITZILAC - MORELOS",
  },
  {
    text: "JANTETELCO",
    value: "JANTETELCO - MORELOS",
  },
  {
    text: "JIUTEPEC",
    value: "JIUTEPEC - MORELOS",
  },
  {
    text: "JOJUTLA",
    value: "JOJUTLA - MORELOS",
  },
  {
    text: "JONACATEPEC",
    value: "JONACATEPEC - MORELOS",
  },
  {
    text: "MAZATEPEC",
    value: "MAZATEPEC - MORELOS",
  },
  {
    text: "MIACATLÁN",
    value: "MIACATLÁN - MORELOS",
  },
  {
    text: "OCUITUCO",
    value: "OCUITUCO - MORELOS",
  },
  {
    text: "PUENTE DE IXTLA",
    value: "PUENTE DE IXTLA - MORELOS",
  },
  {
    text: "TEMIXCO",
    value: "TEMIXCO - MORELOS",
  },
  {
    text: "TEMOAC",
    value: "TEMOAC - MORELOS",
  },
  {
    text: "TEPALCINGO",
    value: "TEPALCINGO - MORELOS",
  },
  {
    text: "TEPOZTLÁN",
    value: "TEPOZTLÁN - MORELOS",
  },
  {
    text: "TETECALA",
    value: "TETECALA - MORELOS",
  },
  {
    text: "TETELA DEL VOLCÁN",
    value: "TETELA DEL VOLCÁN - MORELOS",
  },
  {
    text: "TLALNEPANTLA",
    value: "TLALNEPANTLA - MORELOS",
  },
  {
    text: "TLALTIZAPÁN",
    value: "TLALTIZAPÁN - MORELOS",
  },
  {
    text: "TLAQUILTENANGO",
    value: "TLAQUILTENANGO - MORELOS",
  },
  {
    text: "TLAYACAPAN",
    value: "TLAYACAPAN - MORELOS",
  },
  {
    text: "TOTOLAPAN",
    value: "TOTOLAPAN - MORELOS",
  },
  {
    text: "XALISCO",
    value: "XALISCO - NAYARIT",
  },
  {
    text: "ACAPONETA",
    value: "ACAPONETA - NAYARIT",
  },
  {
    text: "AHUACATLÁN",
    value: "AHUACATLÁN - NAYARIT",
  },
  {
    text: "AMATLÁN DE CANAS",
    value: "AMATLÁN DE CANAS - NAYARIT",
  },
  {
    text: "BAHÍA DE BANDERAS",
    value: "BAHÍA DE BANDERAS - NAYARIT",
  },
  {
    text: "COMPOSTELA",
    value: "COMPOSTELA - NAYARIT",
  },
  {
    text: "DEL NAYAR",
    value: "DEL NAYAR - NAYARIT",
  },
  {
    text: "HUAJICORI",
    value: "HUAJICORI - NAYARIT",
  },
  {
    text: "IXTLÁN DEL RÍO",
    value: "IXTLÁN DEL RÍO - NAYARIT",
  },
  {
    text: "JALA",
    value: "JALA - NAYARIT",
  },
  {
    text: "LA YESCA",
    value: "LA YESCA - NAYARIT",
  },
  {
    text: "ROSAMORADA",
    value: "ROSAMORADA - NAYARIT",
  },
  {
    text: "RUÍZ",
    value: "RUÍZ - NAYARIT",
  },
  {
    text: "SANTA MARÍA DEL ORO",
    value: "SANTA MARÍA DEL ORO - NAYARIT",
  },
  {
    text: "SANTIAGO IXCUINTLA",
    value: "SANTIAGO IXCUINTLA - NAYARIT",
  },
  {
    text: "SAN BLAS",
    value: "SAN BLAS - NAYARIT",
  },
  {
    text: "SAN PEDRO LAGUNILLAS",
    value: "SAN PEDRO LAGUNILLAS - NAYARIT",
  },
  {
    text: "TECUALA",
    value: "TECUALA - NAYARIT",
  },
  {
    text: "TEPIC",
    value: "TEPIC - NAYARIT",
  },
  {
    text: "TUXPAN",
    value: "TUXPAN - NAYARIT",
  },
  {
    text: "VALLECILLO",
    value: "VALLECILLO - NUEVO LEÓN",
  },
  {
    text: "VILLALDAMA",
    value: "VILLALDAMA - NUEVO LEÓN",
  },
  {
    text: "ABASOLO",
    value: "ABASOLO - NUEVO LEÓN",
  },
  {
    text: "AGUALEGUAS",
    value: "AGUALEGUAS - NUEVO LEÓN",
  },
  {
    text: "ALLENDE",
    value: "ALLENDE - NUEVO LEÓN",
  },
  {
    text: "ANÁHUAC",
    value: "ANÁHUAC - NUEVO LEÓN",
  },
  {
    text: "APODACA",
    value: "APODACA - NUEVO LEÓN",
  },
  {
    text: "ARAMBERRI",
    value: "ARAMBERRI - NUEVO LEÓN",
  },
  {
    text: "BUSTAMANTE",
    value: "BUSTAMANTE - NUEVO LEÓN",
  },
  {
    text: "CADEREYTA JIMÉNEZ",
    value: "CADEREYTA JIMÉNEZ - NUEVO LEÓN",
  },
  {
    text: "CARMEN",
    value: "CARMEN - NUEVO LEÓN",
  },
  {
    text: "CERRALVO",
    value: "CERRALVO - NUEVO LEÓN",
  },
  {
    text: "CHINA",
    value: "CHINA - NUEVO LEÓN",
  },
  {
    text: "CIENEGA DE FLORES",
    value: "CIENEGA DE FLORES - NUEVO LEÓN",
  },
  {
    text: "DR ARROYO",
    value: "DR ARROYO - NUEVO LEÓN",
  },
  {
    text: "DR COSS",
    value: "DR COSS - NUEVO LEÓN",
  },
  {
    text: "DR GONZÁLEZ",
    value: "DR GONZÁLEZ - NUEVO LEÓN",
  },
  {
    text: "GALEANA",
    value: "GALEANA - NUEVO LEÓN",
  },
  {
    text: "GARCÍA",
    value: "GARCÍA - NUEVO LEÓN",
  },
  {
    text: "GRAL BRAVO",
    value: "GRAL BRAVO - NUEVO LEÓN",
  },
  {
    text: "GRAL ESCOBEDO",
    value: "GRAL ESCOBEDO - NUEVO LEÓN",
  },
  {
    text: "GRAL TERÁN",
    value: "GRAL TERÁN - NUEVO LEÓN",
  },
  {
    text: "GRAL TREVINO",
    value: "GRAL TREVINO - NUEVO LEÓN",
  },
  {
    text: "GRAL ZARAGOZA",
    value: "GRAL ZARAGOZA - NUEVO LEÓN",
  },
  {
    text: "GRAL ZUAZUA",
    value: "GRAL ZUAZUA - NUEVO LEÓN",
  },
  {
    text: "GUADALUPE",
    value: "GUADALUPE - NUEVO LEÓN",
  },
  {
    text: "HIDALGO",
    value: "HIDALGO - NUEVO LEÓN",
  },
  {
    text: "HIGUERAS",
    value: "HIGUERAS - NUEVO LEÓN",
  },
  {
    text: "HUALAHUISES",
    value: "HUALAHUISES - NUEVO LEÓN",
  },
  {
    text: "ITURBIDE",
    value: "ITURBIDE - NUEVO LEÓN",
  },
  {
    text: "JUÁREZ",
    value: "JUÁREZ - NUEVO LEÓN",
  },
  {
    text: "LAMPAZOS DE NARANJO",
    value: "LAMPAZOS DE NARANJO - NUEVO LEÓN",
  },
  {
    text: "LINARES",
    value: "LINARES - NUEVO LEÓN",
  },
  {
    text: "LOS ALDAMAS",
    value: "LOS ALDAMAS - NUEVO LEÓN",
  },
  {
    text: "LOS HERRERAS",
    value: "LOS HERRERAS - NUEVO LEÓN",
  },
  {
    text: "LOS RAMONES",
    value: "LOS RAMONES - NUEVO LEÓN",
  },
  {
    text: "MARÍN",
    value: "MARÍN - NUEVO LEÓN",
  },
  {
    text: "MELCHOR OCAMPO",
    value: "MELCHOR OCAMPO - NUEVO LEÓN",
  },
  {
    text: "MIER Y NORIEGA",
    value: "MIER Y NORIEGA - NUEVO LEÓN",
  },
  {
    text: "MINA",
    value: "MINA - NUEVO LEÓN",
  },
  {
    text: "MONTEMORELOS",
    value: "MONTEMORELOS - NUEVO LEÓN",
  },
  {
    text: "MONTERREY",
    value: "MONTERREY - NUEVO LEÓN",
  },
  {
    text: "PARAS",
    value: "PARAS - NUEVO LEÓN",
  },
  {
    text: "PESQUERÍA",
    value: "PESQUERÍA - NUEVO LEÓN",
  },
  {
    text: "RAYÓNES",
    value: "RAYÓNES - NUEVO LEÓN",
  },
  {
    text: "SABINAS HIDALGO",
    value: "SABINAS HIDALGO - NUEVO LEÓN",
  },
  {
    text: "SALINAS VICTORIA",
    value: "SALINAS VICTORIA - NUEVO LEÓN",
  },
  {
    text: "SANTA CATARINA",
    value: "SANTA CATARINA - NUEVO LEÓN",
  },
  {
    text: "SANTIAGO",
    value: "SANTIAGO - NUEVO LEÓN",
  },
  {
    text: "SAN NICOLÁS DE LOS GARZA",
    value: "SAN NICOLÁS DE LOS GARZA - NUEVO LEÓN",
  },
  {
    text: "SAN PEDRO GARZA GARCÍA",
    value: "SAN PEDRO GARZA GARCÍA - NUEVO LEÓN",
  },
  {
    text: "VILLA DE CHILAPA DE DÍAZ",
    value: "VILLA DE CHILAPA DE DÍAZ - OAXACA",
  },
  {
    text: "VILLA DE ETLA",
    value: "VILLA DE ETLA - OAXACA",
  },
  {
    text: "VILLA DE TAMAZULAPAM",
    value: "VILLA DE TAMAZULAPAM - OAXACA",
  },
  {
    text: "VILLA DE ZAACHILA",
    value: "VILLA DE ZAACHILA - OAXACA",
  },
  {
    text: "VILLA DÍAZ ORDAZ",
    value: "VILLA DÍAZ ORDAZ - OAXACA",
  },
  {
    text: "VILLA HIDALGO",
    value: "VILLA HIDALGO - OAXACA",
  },
  {
    text: "VILLA SOLA DE VEGA",
    value: "VILLA SOLA DE VEGA - OAXACA",
  },
  {
    text: "VILLA TALEA DE CASTRO",
    value: "VILLA TALEA DE CASTRO - OAXACA",
  },
  {
    text: "VILLA TEJÚPAM DE LA UNIÓN",
    value: "VILLA TEJÚPAM DE LA UNIÓN - OAXACA",
  },
  {
    text: "VILLA TUTUTEPEC MELCHOR O",
    value: "VILLA TUTUTEPEC MELCHOR O - OAXACA",
  },
  {
    text: "YAXE",
    value: "YAXE - OAXACA",
  },
  {
    text: "YOGANA",
    value: "YOGANA - OAXACA",
  },
  {
    text: "YUTANDUCHI DE GUERRERO",
    value: "YUTANDUCHI DE GUERRERO - OAXACA",
  },
  {
    text: "ZAPOTITLÁN DEL RÍO",
    value: "ZAPOTITLÁN DEL RÍO - OAXACA",
  },
  {
    text: "ZAPOTITLÁN LAGUNAS",
    value: "ZAPOTITLÁN LAGUNAS - OAXACA",
  },
  {
    text: "ZAPOTITLÁN PALMAS",
    value: "ZAPOTITLÁN PALMAS - OAXACA",
  },
  {
    text: "ZIMATLAN DE ÁLVAREZ",
    value: "ZIMATLAN DE ÁLVAREZ - OAXACA",
  },
  {
    text: "ABEJONES",
    value: "ABEJONES - OAXACA",
  },
  {
    text: "ACATLÁN DE PÉREZ FIGUEROA",
    value: "ACATLÁN DE PÉREZ FIGUEROA - OAXACA",
  },
  {
    text: "ÁNIMAS TRUJANO",
    value: "ÁNIMAS TRUJANO - OAXACA",
  },
  {
    text: "ASUNCIÓN CACALOTEPEC",
    value: "ASUNCIÓN CACALOTEPEC - OAXACA",
  },
  {
    text: "ASUNCIÓN CUYOTEPEJI",
    value: "ASUNCIÓN CUYOTEPEJI - OAXACA",
  },
  {
    text: "ASUNCIÓN IXTALTEPEC",
    value: "ASUNCIÓN IXTALTEPEC - OAXACA",
  },
  {
    text: "ASUNCIÓN NOCHIXTLÁN",
    value: "ASUNCIÓN NOCHIXTLÁN - OAXACA",
  },
  {
    text: "ASUNCIÓN OCOTLÁN",
    value: "ASUNCIÓN OCOTLÁN - OAXACA",
  },
  {
    text: "ASUNCIÓN TLACOLULITA",
    value: "ASUNCIÓN TLACOLULITA - OAXACA",
  },
  {
    text: "AYOQUEZCO DE ALDAMA",
    value: "AYOQUEZCO DE ALDAMA - OAXACA",
  },
  {
    text: "AYOTZINTEPEC",
    value: "AYOTZINTEPEC - OAXACA",
  },
  {
    text: "CALIHUALÁ",
    value: "CALIHUALÁ - OAXACA",
  },
  {
    text: "CANDELARIA LOXICHA",
    value: "CANDELARIA LOXICHA - OAXACA",
  },
  {
    text: "CAPULÁLPAM DE MÉNDEZ",
    value: "CAPULÁLPAM DE MÉNDEZ - OAXACA",
  },
  {
    text: "CIUDAD DE EJUTLA DE CRESPO",
    value: "CIUDAD DE EJUTLA DE CRESPO - OAXACA",
  },
  {
    text: "CIUDAD DE HUAJUAPAN DE LEÓN",
    value: "CIUDAD DE HUAJUAPAN DE LEÓN - OAXACA",
  },
  {
    text: "CIUDAD DE JUCHITÁN DE ZARAGOZA",
    value: "CIUDAD DE JUCHITÁN DE ZARAGOZA - OAXACA",
  },
  {
    text: "CIUDAD IXTEPEC",
    value: "CIUDAD IXTEPEC - OAXACA",
  },
  {
    text: "CHAHUITES",
    value: "CHAHUITES - OAXACA",
  },
  {
    text: "CHALCATONGO DE HIDALGO",
    value: "CHALCATONGO DE HIDALGO - OAXACA",
  },
  {
    text: "CHIQUIHUITLÁN DE BENITO J",
    value: "CHIQUIHUITLÁN DE BENITO J - OAXACA",
  },
  {
    text: "COATECAS ALTAS",
    value: "COATECAS ALTAS - OAXACA",
  },
  {
    text: "COICOYÁN DE LAS FLORES",
    value: "COICOYÁN DE LAS FLORES - OAXACA",
  },
  {
    text: "CONCEPCIÓN BUENAVISTA",
    value: "CONCEPCIÓN BUENAVISTA - OAXACA",
  },
  {
    text: "CONCEPCIÓN PÁPALO",
    value: "CONCEPCIÓN PÁPALO - OAXACA",
  },
  {
    text: "CONSTANCIA DEL ROSARÍO",
    value: "CONSTANCIA DEL ROSARÍO - OAXACA",
  },
  {
    text: "COSOLAPA",
    value: "COSOLAPA - OAXACA",
  },
  {
    text: "COSOLTEPEC",
    value: "COSOLTEPEC - OAXACA",
  },
  {
    text: "CRISTÓBAL SUCHIXTLÁHUACA",
    value: "CRISTÓBAL SUCHIXTLÁHUACA - OAXACA",
  },
  {
    text: "CUILAPAM DE GUERRERO",
    value: "CUILAPAM DE GUERRERO - OAXACA",
  },
  {
    text: "CUYAMECALCO VILLA DE ZAR",
    value: "CUYAMECALCO VILLA DE ZAR - OAXACA",
  },
  {
    text: "EL BARRÍO DE LA SOLEDAD",
    value: "EL BARRÍO DE LA SOLEDAD - OAXACA",
  },
  {
    text: "EL ESPINAL",
    value: "EL ESPINAL - OAXACA",
  },
  {
    text: "ELOXOCHITLÁN FLORES MAGON",
    value: "ELOXOCHITLÁN FLORES MAGON - OAXACA",
  },
  {
    text: "FRESNILLO DE TRUJANO",
    value: "FRESNILLO DE TRUJANO - OAXACA",
  },
  {
    text: "GUADALUPE DE RAMÍREZ",
    value: "GUADALUPE DE RAMÍREZ - OAXACA",
  },
  {
    text: "GUADALUPE ETLA",
    value: "GUADALUPE ETLA - OAXACA",
  },
  {
    text: "GUELATAO DE JUÁREZ",
    value: "GUELATAO DE JUÁREZ - OAXACA",
  },
  {
    text: "GUEVEA DE HUMBOLDT",
    value: "GUEVEA DE HUMBOLDT - OAXACA",
  },
  {
    text: "HEROICA CIUDAD DE TLAXIACO",
    value: "HEROICA CIUDAD DE TLAXIACO - OAXACA",
  },
  {
    text: "HEROICA CIUDAD HUAJUAPAN LEÓN",
    value: "HEROICA CIUDAD HUAJUAPAN LEÓN - OAXACA",
  },
  {
    text: "HUAUTEPEC",
    value: "HUAUTEPEC - OAXACA",
  },
  {
    text: "HUAUTLA DE JIMÉNEZ",
    value: "HUAUTLA DE JIMÉNEZ - OAXACA",
  },
  {
    text: "IXPANTEPEC NIEVES",
    value: "IXPANTEPEC NIEVES - OAXACA",
  },
  {
    text: "IXTLÁN DE JUÁREZ",
    value: "IXTLÁN DE JUÁREZ - OAXACA",
  },
  {
    text: "LA COMPAÑÍA",
    value: "LA COMPAÑÍA - OAXACA",
  },
  {
    text: "LA PE",
    value: "LA PE - OAXACA",
  },
  {
    text: "LA REFORMA",
    value: "LA REFORMA - OAXACA",
  },
  {
    text: "LA TRINIDAD VISTA HERMOSA",
    value: "LA TRINIDAD VISTA HERMOSA - OAXACA",
  },
  {
    text: "LOMA BONITA",
    value: "LOMA BONITA - OAXACA",
  },
  {
    text: "MAGDA YODOCONO PORFIRÍO D",
    value: "MAGDA YODOCONO PORFIRÍO D - OAXACA",
  },
  {
    text: "MAGDALENA APASCO",
    value: "MAGDALENA APASCO - OAXACA",
  },
  {
    text: "MAGDALENA JALTEPEC",
    value: "MAGDALENA JALTEPEC - OAXACA",
  },
  {
    text: "MAGDALENA MIXTEPEC",
    value: "MAGDALENA MIXTEPEC - OAXACA",
  },
  {
    text: "MAGDALENA OCOTLÁN",
    value: "MAGDALENA OCOTLÁN - OAXACA",
  },
  {
    text: "MAGDALENA PENASCO",
    value: "MAGDALENA PENASCO - OAXACA",
  },
  {
    text: "MAGDALENA TEITIPAC",
    value: "MAGDALENA TEITIPAC - OAXACA",
  },
  {
    text: "MAGDALENA TEQUISISTLÁN",
    value: "MAGDALENA TEQUISISTLÁN - OAXACA",
  },
  {
    text: "MAGDALENA TLACOTEPEC",
    value: "MAGDALENA TLACOTEPEC - OAXACA",
  },
  {
    text: "MAGDALENA ZAHUATLÁN",
    value: "MAGDALENA ZAHUATLÁN - OAXACA",
  },
  {
    text: "MARISCALA DE JUÁREZ",
    value: "MARISCALA DE JUÁREZ - OAXACA",
  },
  {
    text: "MÁRTIRES DE TACUBAYA",
    value: "MÁRTIRES DE TACUBAYA - OAXACA",
  },
  {
    text: "MATIAS ROMERO",
    value: "MATIAS ROMERO - OAXACA",
  },
  {
    text: "MAZATLÁN VILLA DE FLORES",
    value: "MAZATLÁN VILLA DE FLORES - OAXACA",
  },
  {
    text: "MESONES HIDALGO",
    value: "MESONES HIDALGO - OAXACA",
  },
  {
    text: "MIAHUATLÁN PORFIRÍO DÍAZ",
    value: "MIAHUATLÁN PORFIRÍO DÍAZ - OAXACA",
  },
  {
    text: "MIXISTLÁN DE LA REFORMA",
    value: "MIXISTLÁN DE LA REFORMA - OAXACA",
  },
  {
    text: "MONJAS",
    value: "MONJAS - OAXACA",
  },
  {
    text: "NATIVIDAD",
    value: "NATIVIDAD - OAXACA",
  },
  {
    text: "NAZARENO ETLA",
    value: "NAZARENO ETLA - OAXACA",
  },
  {
    text: "NEJAPA DE MADERO",
    value: "NEJAPA DE MADERO - OAXACA",
  },
  {
    text: "NUEVO ZOQUIÁPAM",
    value: "NUEVO ZOQUIÁPAM - OAXACA",
  },
  {
    text: "OAXACA DE JUÁREZ",
    value: "OAXACA DE JUÁREZ - OAXACA",
  },
  {
    text: "OAXACA",
    value: "OAXACA - OAXACA",
  },
  {
    text: "OCOTLÁN DE MORELOS",
    value: "OCOTLÁN DE MORELOS - OAXACA",
  },
  {
    text: "PINOTEPA DE DON LUIS",
    value: "PINOTEPA DE DON LUIS - OAXACA",
  },
  {
    text: "PLUMA HIDALGO",
    value: "PLUMA HIDALGO - OAXACA",
  },
  {
    text: "PUERTO ESCONDIDO",
    value: "PUERTO ESCONDIDO - OAXACA",
  },
  {
    text: "PUTLA VILLA DE GUERRERO",
    value: "PUTLA VILLA DE GUERRERO - OAXACA",
  },
  {
    text: "REFORMA DE PINEDA",
    value: "REFORMA DE PINEDA - OAXACA",
  },
  {
    text: "REYES ETLA",
    value: "REYES ETLA - OAXACA",
  },
  {
    text: "RÍO GRANDE",
    value: "RÍO GRANDE - OAXACA",
  },
  {
    text: "ROJAS DE CUAUHTÉMOC",
    value: "ROJAS DE CUAUHTÉMOC - OAXACA",
  },
  {
    text: "S J BTISTA TLACOATZINTEPEC",
    value: "S J BTISTA TLACOATZINTEPEC - OAXACA",
  },
  {
    text: "SALINA CRUZ",
    value: "SALINA CRUZ - OAXACA",
  },
  {
    text: "SANTA ANA ATEIXTLÁHUACA",
    value: "SANTA ANA ATEIXTLÁHUACA - OAXACA",
  },
  {
    text: "SANTA ANA CUAUHTÉMOC",
    value: "SANTA ANA CUAUHTÉMOC - OAXACA",
  },
  {
    text: "SANTA ANA DEL VALLE",
    value: "SANTA ANA DEL VALLE - OAXACA",
  },
  {
    text: "SANTA ANA",
    value: "SANTA ANA - OAXACA",
  },
  {
    text: "SANTA ANA TAVELA",
    value: "SANTA ANA TAVELA - OAXACA",
  },
  {
    text: "SANTA ANA TLAPACOYAN",
    value: "SANTA ANA TLAPACOYAN - OAXACA",
  },
  {
    text: "SANTA ANA YARENI",
    value: "SANTA ANA YARENI - OAXACA",
  },
  {
    text: "SANTA ANA ZEGACHE",
    value: "SANTA ANA ZEGACHE - OAXACA",
  },
  {
    text: "SANTA CATALINA QUIERÍ",
    value: "SANTA CATALINA QUIERÍ - OAXACA",
  },
  {
    text: "SANTA CATARINA CUIXTLA",
    value: "SANTA CATARINA CUIXTLA - OAXACA",
  },
  {
    text: "SANTA CATARINA IXTEPEJI",
    value: "SANTA CATARINA IXTEPEJI - OAXACA",
  },
  {
    text: "SANTA CATARINA JUQUILA",
    value: "SANTA CATARINA JUQUILA - OAXACA",
  },
  {
    text: "SANTA CATARINA LACHATAO",
    value: "SANTA CATARINA LACHATAO - OAXACA",
  },
  {
    text: "SANTA CATARINA LOXICHA",
    value: "SANTA CATARINA LOXICHA - OAXACA",
  },
  {
    text: "SANTA CATARINA MECHOACÁN",
    value: "SANTA CATARINA MECHOACÁN - OAXACA",
  },
  {
    text: "SANTA CATARINA MINAS",
    value: "SANTA CATARINA MINAS - OAXACA",
  },
  {
    text: "SANTA CATARINA QUIANÉ",
    value: "SANTA CATARINA QUIANÉ - OAXACA",
  },
  {
    text: "SANTA CATARINA QUIOQUITANI",
    value: "SANTA CATARINA QUIOQUITANI - OAXACA",
  },
  {
    text: "SANTA CATARINA TAYATA",
    value: "SANTA CATARINA TAYATA - OAXACA",
  },
  {
    text: "SANTA CATARINA TICUÁ",
    value: "SANTA CATARINA TICUÁ - OAXACA",
  },
  {
    text: "SANTA CATARINA YOSONOTÚ",
    value: "SANTA CATARINA YOSONOTÚ - OAXACA",
  },
  {
    text: "SANTA CATARINA ZAPOQUILA",
    value: "SANTA CATARINA ZAPOQUILA - OAXACA",
  },
  {
    text: "SANTA CRUZ ACATEPEC",
    value: "SANTA CRUZ ACATEPEC - OAXACA",
  },
  {
    text: "SANTA CRUZ AMILPAS",
    value: "SANTA CRUZ AMILPAS - OAXACA",
  },
  {
    text: "SANTA CRUZ DE BRAVO",
    value: "SANTA CRUZ DE BRAVO - OAXACA",
  },
  {
    text: "SANTA CRUZ ITUNDUJIA",
    value: "SANTA CRUZ ITUNDUJIA - OAXACA",
  },
  {
    text: "SANTA CRUZ MIXTEPEC",
    value: "SANTA CRUZ MIXTEPEC - OAXACA",
  },
  {
    text: "SANTA CRUZ NUNDACO",
    value: "SANTA CRUZ NUNDACO - OAXACA",
  },
  {
    text: "SANTA CRUZ PAPALUTLA",
    value: "SANTA CRUZ PAPALUTLA - OAXACA",
  },
  {
    text: "SANTA CRUZ TACACHE DE MINA",
    value: "SANTA CRUZ TACACHE DE MINA - OAXACA",
  },
  {
    text: "SANTA CRUZ TACAHUA",
    value: "SANTA CRUZ TACAHUA - OAXACA",
  },
  {
    text: "SANTA CRUZ TAYATA",
    value: "SANTA CRUZ TAYATA - OAXACA",
  },
  {
    text: "SANTA CRUZ XITLA",
    value: "SANTA CRUZ XITLA - OAXACA",
  },
  {
    text: "SANTA CRUZ XOXOCOTLÁN",
    value: "SANTA CRUZ XOXOCOTLÁN - OAXACA",
  },
  {
    text: "SANTA CRUZ ZENZONTEPEC",
    value: "SANTA CRUZ ZENZONTEPEC - OAXACA",
  },
  {
    text: "SANTA GERTRUDIS",
    value: "SANTA GERTRUDIS - OAXACA",
  },
  {
    text: "SANTA INÉS DE ZARAGOZA",
    value: "SANTA INÉS DE ZARAGOZA - OAXACA",
  },
  {
    text: "SANTA INÉS DEL MTE",
    value: "SANTA INÉS DEL MTE - OAXACA",
  },
  {
    text: "SANTA INÉS YATZECHE",
    value: "SANTA INÉS YATZECHE - OAXACA",
  },
  {
    text: "SANTA LUCÍA DEL CAMINO",
    value: "SANTA LUCÍA DEL CAMINO - OAXACA",
  },
  {
    text: "SANTA LUCÍA MIAHUATLÁN",
    value: "SANTA LUCÍA MIAHUATLÁN - OAXACA",
  },
  {
    text: "SANTA LUCÍA MONTEVERDE",
    value: "SANTA LUCÍA MONTEVERDE - OAXACA",
  },
  {
    text: "SANTA LUCÍA OCOTLÁN",
    value: "SANTA LUCÍA OCOTLÁN - OAXACA",
  },
  {
    text: "SANTA MA JAL DEL MARQUÉS",
    value: "SANTA MA JAL DEL MARQUÉS - OAXACA",
  },
  {
    text: "SANTA MAGDALENA JICOTLÁN",
    value: "SANTA MAGDALENA JICOTLÁN - OAXACA",
  },
  {
    text: "SANTA MARÍA ALOTEPEC",
    value: "SANTA MARÍA ALOTEPEC - OAXACA",
  },
  {
    text: "SANTA MARÍA APAZCO",
    value: "SANTA MARÍA APAZCO - OAXACA",
  },
  {
    text: "SANTA MARÍA ATZOMPA",
    value: "SANTA MARÍA ATZOMPA - OAXACA",
  },
  {
    text: "SANTA MARÍA CAMOTLÁN",
    value: "SANTA MARÍA CAMOTLÁN - OAXACA",
  },
  {
    text: "SANTA MARÍA CHACHOÁPAM",
    value: "SANTA MARÍA CHACHOÁPAM - OAXACA",
  },
  {
    text: "SANTA MARÍA CHILCHOTLA",
    value: "SANTA MARÍA CHILCHOTLA - OAXACA",
  },
  {
    text: "SANTA MARÍA CHIMALAPA",
    value: "SANTA MARÍA CHIMALAPA - OAXACA",
  },
  {
    text: "SANTA MARÍA COLOTEPEC",
    value: "SANTA MARÍA COLOTEPEC - OAXACA",
  },
  {
    text: "SANTA MARÍA CORTIJO",
    value: "SANTA MARÍA CORTIJO - OAXACA",
  },
  {
    text: "SANTA MARÍA COYOTEPEC",
    value: "SANTA MARÍA COYOTEPEC - OAXACA",
  },
  {
    text: "SANTA MARÍA DEL ROSARÍO",
    value: "SANTA MARÍA DEL ROSARÍO - OAXACA",
  },
  {
    text: "SANTA MARÍA DEL TULE",
    value: "SANTA MARÍA DEL TULE - OAXACA",
  },
  {
    text: "SANTA MARÍA ECATEPEC",
    value: "SANTA MARÍA ECATEPEC - OAXACA",
  },
  {
    text: "SANTA MARÍA GUELACÉ",
    value: "SANTA MARÍA GUELACÉ - OAXACA",
  },
  {
    text: "SANTA MARÍA GUIENAGATI",
    value: "SANTA MARÍA GUIENAGATI - OAXACA",
  },
  {
    text: "SANTA MARÍA HUATULCO",
    value: "SANTA MARÍA HUATULCO - OAXACA",
  },
  {
    text: "SANTA MARÍA HUAZOLOTITLÁN",
    value: "SANTA MARÍA HUAZOLOTITLÁN - OAXACA",
  },
  {
    text: "SANTA MARÍA IPALAPA",
    value: "SANTA MARÍA IPALAPA - OAXACA",
  },
  {
    text: "SANTA MARÍA IXCATLÁN",
    value: "SANTA MARÍA IXCATLÁN - OAXACA",
  },
  {
    text: "SANTA MARÍA JACATEPEC",
    value: "SANTA MARÍA JACATEPEC - OAXACA",
  },
  {
    text: "SANTA MARÍA JALTIANGUIS",
    value: "SANTA MARÍA JALTIANGUIS - OAXACA",
  },
  {
    text: "SANTA MARÍA LA ASUNCIÓN",
    value: "SANTA MARÍA LA ASUNCIÓN - OAXACA",
  },
  {
    text: "SANTA MARÍA LACHIXÍO",
    value: "SANTA MARÍA LACHIXÍO - OAXACA",
  },
  {
    text: "SANTA MARÍA MIXTEQUILLA",
    value: "SANTA MARÍA MIXTEQUILLA - OAXACA",
  },
  {
    text: "SANTA MARÍA NATÍVITAS",
    value: "SANTA MARÍA NATÍVITAS - OAXACA",
  },
  {
    text: "SANTA MARÍA NDUAYACO",
    value: "SANTA MARÍA NDUAYACO - OAXACA",
  },
  {
    text: "SANTA MARÍA OZOLOTEPEC",
    value: "SANTA MARÍA OZOLOTEPEC - OAXACA",
  },
  {
    text: "SANTA MARÍA PÁPALO",
    value: "SANTA MARÍA PÁPALO - OAXACA",
  },
  {
    text: "SANTA MARÍA PENOLES",
    value: "SANTA MARÍA PENOLES - OAXACA",
  },
  {
    text: "SANTA MARÍA PETAPA",
    value: "SANTA MARÍA PETAPA - OAXACA",
  },
  {
    text: "SANTA MARÍA QUIEGOLANI",
    value: "SANTA MARÍA QUIEGOLANI - OAXACA",
  },
  {
    text: "SANTA MARÍA SOLA",
    value: "SANTA MARÍA SOLA - OAXACA",
  },
  {
    text: "SANTA MARÍA TATALTEPEC",
    value: "SANTA MARÍA TATALTEPEC - OAXACA",
  },
  {
    text: "SANTA MARÍA TECOMAVACA",
    value: "SANTA MARÍA TECOMAVACA - OAXACA",
  },
  {
    text: "SANTA MARÍA TEMAXCALAPA",
    value: "SANTA MARÍA TEMAXCALAPA - OAXACA",
  },
  {
    text: "SANTA MARÍA TEMAXCALTEPEC",
    value: "SANTA MARÍA TEMAXCALTEPEC - OAXACA",
  },
  {
    text: "SANTA MARÍA TEOPOXCO",
    value: "SANTA MARÍA TEOPOXCO - OAXACA",
  },
  {
    text: "SANTA MARÍA TEPANTLALI",
    value: "SANTA MARÍA TEPANTLALI - OAXACA",
  },
  {
    text: "SANTA MARÍA TEXCATITLÁN",
    value: "SANTA MARÍA TEXCATITLÁN - OAXACA",
  },
  {
    text: "SANTA MARÍA TLAHUITOLTEPEC",
    value: "SANTA MARÍA TLAHUITOLTEPEC - OAXACA",
  },
  {
    text: "SANTA MARÍA TLALIXTAC",
    value: "SANTA MARÍA TLALIXTAC - OAXACA",
  },
  {
    text: "SANTA MARÍA TONAMECA",
    value: "SANTA MARÍA TONAMECA - OAXACA",
  },
  {
    text: "SANTA MARÍA TOTOLAPILLA",
    value: "SANTA MARÍA TOTOLAPILLA - OAXACA",
  },
  {
    text: "SANTA MARÍA XADANI",
    value: "SANTA MARÍA XADANI - OAXACA",
  },
  {
    text: "SANTA MARÍA YALINA",
    value: "SANTA MARÍA YALINA - OAXACA",
  },
  {
    text: "SANTA MARÍA YAVESÍA",
    value: "SANTA MARÍA YAVESÍA - OAXACA",
  },
  {
    text: "SANTA MARÍA YOLOTEPEC",
    value: "SANTA MARÍA YOLOTEPEC - OAXACA",
  },
  {
    text: "SANTA MARÍA YOSOYÚA",
    value: "SANTA MARÍA YOSOYÚA - OAXACA",
  },
  {
    text: "SANTA MARÍA YUCUHITI",
    value: "SANTA MARÍA YUCUHITI - OAXACA",
  },
  {
    text: "SANTA MARÍA ZACATEPEC",
    value: "SANTA MARÍA ZACATEPEC - OAXACA",
  },
  {
    text: "SANTA MARÍA ZANIZA",
    value: "SANTA MARÍA ZANIZA - OAXACA",
  },
  {
    text: "SANTA MARÍA ZOQUITLÁN",
    value: "SANTA MARÍA ZOQUITLÁN - OAXACA",
  },
  {
    text: "SANTIAGO AMOLTEPEC",
    value: "SANTIAGO AMOLTEPEC - OAXACA",
  },
  {
    text: "SANTIAGO APOALA",
    value: "SANTIAGO APOALA - OAXACA",
  },
  {
    text: "SANTIAGO APÓSTOL",
    value: "SANTIAGO APÓSTOL - OAXACA",
  },
  {
    text: "SANTIAGO ASTATA",
    value: "SANTIAGO ASTATA - OAXACA",
  },
  {
    text: "SANTIAGO ATITLAN",
    value: "SANTIAGO ATITLAN - OAXACA",
  },
  {
    text: "SANTIAGO AYUQUILILLA",
    value: "SANTIAGO AYUQUILILLA - OAXACA",
  },
  {
    text: "SANTIAGO CACALOXTEPEC",
    value: "SANTIAGO CACALOXTEPEC - OAXACA",
  },
  {
    text: "SANTIAGO CAMOTLÁN",
    value: "SANTIAGO CAMOTLÁN - OAXACA",
  },
  {
    text: "SANTIAGO CHAZUMBA",
    value: "SANTIAGO CHAZUMBA - OAXACA",
  },
  {
    text: "SANTIAGO CHOAPAM",
    value: "SANTIAGO CHOAPAM - OAXACA",
  },
  {
    text: "SANTIAGO COMALTEPEC",
    value: "SANTIAGO COMALTEPEC - OAXACA",
  },
  {
    text: "SANTIAGO DEL RÍO",
    value: "SANTIAGO DEL RÍO - OAXACA",
  },
  {
    text: "SANTIAGO HUAJOLOTITLÁN",
    value: "SANTIAGO HUAJOLOTITLÁN - OAXACA",
  },
  {
    text: "SANTIAGO HUAUCLILLA",
    value: "SANTIAGO HUAUCLILLA - OAXACA",
  },
  {
    text: "SANTIAGO IHUITLAN PLUMAS",
    value: "SANTIAGO IHUITLAN PLUMAS - OAXACA",
  },
  {
    text: "SANTIAGO IXCUINTEPEC",
    value: "SANTIAGO IXCUINTEPEC - OAXACA",
  },
  {
    text: "SANTIAGO IXTAYUTLA",
    value: "SANTIAGO IXTAYUTLA - OAXACA",
  },
  {
    text: "SANTIAGO JAMILTEPEC",
    value: "SANTIAGO JAMILTEPEC - OAXACA",
  },
  {
    text: "SANTIAGO JOCOTEPEC",
    value: "SANTIAGO JOCOTEPEC - OAXACA",
  },
  {
    text: "SANTIAGO JUXTLÁHUACA",
    value: "SANTIAGO JUXTLÁHUACA - OAXACA",
  },
  {
    text: "SANTIAGO LACHIGUIRI",
    value: "SANTIAGO LACHIGUIRI - OAXACA",
  },
  {
    text: "SANTIAGO LALOPA",
    value: "SANTIAGO LALOPA - OAXACA",
  },
  {
    text: "SANTIAGO LAOLLAGA",
    value: "SANTIAGO LAOLLAGA - OAXACA",
  },
  {
    text: "SANTIAGO LAXOPA",
    value: "SANTIAGO LAXOPA - OAXACA",
  },
  {
    text: "SANTIAGO LLANO GRANDE",
    value: "SANTIAGO LLANO GRANDE - OAXACA",
  },
  {
    text: "SANTIAGO MATATLÁN",
    value: "SANTIAGO MATATLÁN - OAXACA",
  },
  {
    text: "SANTIAGO MILTEPEC",
    value: "SANTIAGO MILTEPEC - OAXACA",
  },
  {
    text: "SANTIAGO MINAS",
    value: "SANTIAGO MINAS - OAXACA",
  },
  {
    text: "SANTIAGO NACALTEPEC",
    value: "SANTIAGO NACALTEPEC - OAXACA",
  },
  {
    text: "SANTIAGO NEJAPILLA",
    value: "SANTIAGO NEJAPILLA - OAXACA",
  },
  {
    text: "SANTIAGO NILTEPEC",
    value: "SANTIAGO NILTEPEC - OAXACA",
  },
  {
    text: "SANTIAGO NUNDICHE",
    value: "SANTIAGO NUNDICHE - OAXACA",
  },
  {
    text: "SANTIAGO NUYOÓ",
    value: "SANTIAGO NUYOÓ - OAXACA",
  },
  {
    text: "SANTIAGO PINOTEPA NAC",
    value: "SANTIAGO PINOTEPA NAC - OAXACA",
  },
  {
    text: "SANTIAGO SÚCHILQUITONGO",
    value: "SANTIAGO SÚCHILQUITONGO - OAXACA",
  },
  {
    text: "SANTIAGO TAMAZOLA",
    value: "SANTIAGO TAMAZOLA - OAXACA",
  },
  {
    text: "SANTIAGO TAPEXTLA",
    value: "SANTIAGO TAPEXTLA - OAXACA",
  },
  {
    text: "SANTIAGO TENANGO",
    value: "SANTIAGO TENANGO - OAXACA",
  },
  {
    text: "SANTIAGO TEPETLAPA",
    value: "SANTIAGO TEPETLAPA - OAXACA",
  },
  {
    text: "SANTIAGO TETEPEC",
    value: "SANTIAGO TETEPEC - OAXACA",
  },
  {
    text: "SANTIAGO TEXCALCINGO",
    value: "SANTIAGO TEXCALCINGO - OAXACA",
  },
  {
    text: "SANTIAGO TEXTITLÁN",
    value: "SANTIAGO TEXTITLÁN - OAXACA",
  },
  {
    text: "SANTIAGO TILANTONGO",
    value: "SANTIAGO TILANTONGO - OAXACA",
  },
  {
    text: "SANTIAGO TILLO",
    value: "SANTIAGO TILLO - OAXACA",
  },
  {
    text: "SANTIAGO TLAZOYALTEPEC",
    value: "SANTIAGO TLAZOYALTEPEC - OAXACA",
  },
  {
    text: "SANTIAGO XANICA",
    value: "SANTIAGO XANICA - OAXACA",
  },
  {
    text: "SANTIAGO XIACUÍ",
    value: "SANTIAGO XIACUÍ - OAXACA",
  },
  {
    text: "SANTIAGO YAITEPEC",
    value: "SANTIAGO YAITEPEC - OAXACA",
  },
  {
    text: "SANTIAGO YAVEO",
    value: "SANTIAGO YAVEO - OAXACA",
  },
  {
    text: "SANTIAGO YOLOMÉCATL",
    value: "SANTIAGO YOLOMÉCATL - OAXACA",
  },
  {
    text: "SANTIAGO YOSONDÚA",
    value: "SANTIAGO YOSONDÚA - OAXACA",
  },
  {
    text: "SANTIAGO YUCUYACHI",
    value: "SANTIAGO YUCUYACHI - OAXACA",
  },
  {
    text: "SANTIAGO ZACATEPEC",
    value: "SANTIAGO ZACATEPEC - OAXACA",
  },
  {
    text: "SANTIAGO ZOOCHILA",
    value: "SANTIAGO ZOOCHILA - OAXACA",
  },
  {
    text: "SANTO DOMINGO ALBARRADAS",
    value: "SANTO DOMINGO ALBARRADAS - OAXACA",
  },
  {
    text: "SANTO DOMINGO ARMENTA",
    value: "SANTO DOMINGO ARMENTA - OAXACA",
  },
  {
    text: "SANTO DOMINGO CHIHUITAN",
    value: "SANTO DOMINGO CHIHUITAN - OAXACA",
  },
  {
    text: "SANTO DOMINGO DE MORELOS",
    value: "SANTO DOMINGO DE MORELOS - OAXACA",
  },
  {
    text: "SANTO DOMINGO INGENIO",
    value: "SANTO DOMINGO INGENIO - OAXACA",
  },
  {
    text: "SANTO DOMINGO IXCATLÁN",
    value: "SANTO DOMINGO IXCATLÁN - OAXACA",
  },
  {
    text: "SANTO DOMINGO NUXAÁ",
    value: "SANTO DOMINGO NUXAÁ - OAXACA",
  },
  {
    text: "SANTO DOMINGO OZOLOTEPEC",
    value: "SANTO DOMINGO OZOLOTEPEC - OAXACA",
  },
  {
    text: "SANTO DOMINGO PETAPA",
    value: "SANTO DOMINGO PETAPA - OAXACA",
  },
  {
    text: "SANTO DOMINGO ROAYAGA",
    value: "SANTO DOMINGO ROAYAGA - OAXACA",
  },
  {
    text: "SANTO DOMINGO TEHUANTEPEC",
    value: "SANTO DOMINGO TEHUANTEPEC - OAXACA",
  },
  {
    text: "SANTO DOMINGO TEOJOMULCO",
    value: "SANTO DOMINGO TEOJOMULCO - OAXACA",
  },
  {
    text: "SANTO DOMINGO TEPUXTEPEC",
    value: "SANTO DOMINGO TEPUXTEPEC - OAXACA",
  },
  {
    text: "SANTO DOMINGO TLATAYÁPAM",
    value: "SANTO DOMINGO TLATAYÁPAM - OAXACA",
  },
  {
    text: "SANTO DOMINGO TOMALTEPEC",
    value: "SANTO DOMINGO TOMALTEPEC - OAXACA",
  },
  {
    text: "SANTO DOMINGO TONALÁ",
    value: "SANTO DOMINGO TONALÁ - OAXACA",
  },
  {
    text: "SANTO DOMINGO TONALTEPEC",
    value: "SANTO DOMINGO TONALTEPEC - OAXACA",
  },
  {
    text: "SANTO DOMINGO XAGACÍA",
    value: "SANTO DOMINGO XAGACÍA - OAXACA",
  },
  {
    text: "SANTO DOMINGO YANHUITLÁN",
    value: "SANTO DOMINGO YANHUITLÁN - OAXACA",
  },
  {
    text: "SANTO DOMINGO YODOHINO",
    value: "SANTO DOMINGO YODOHINO - OAXACA",
  },
  {
    text: "SANTO DOMINGO ZANATEPEC",
    value: "SANTO DOMINGO ZANATEPEC - OAXACA",
  },
  {
    text: "SANTO TOMÁS JALIEZA",
    value: "SANTO TOMÁS JALIEZA - OAXACA",
  },
  {
    text: "SANTO TOMÁS MAZALTEPEC",
    value: "SANTO TOMÁS MAZALTEPEC - OAXACA",
  },
  {
    text: "SANTO TOMÁS OCOTEPEC",
    value: "SANTO TOMÁS OCOTEPEC - OAXACA",
  },
  {
    text: "SANTO TOMÁS TAMAZULAPAN",
    value: "SANTO TOMÁS TAMAZULAPAN - OAXACA",
  },
  {
    text: "SANTOS REYES NOPALA",
    value: "SANTOS REYES NOPALA - OAXACA",
  },
  {
    text: "SANTOS REYES PÁPALO",
    value: "SANTOS REYES PÁPALO - OAXACA",
  },
  {
    text: "SANTOS REYES TEPEJILLO",
    value: "SANTOS REYES TEPEJILLO - OAXACA",
  },
  {
    text: "SANTOS REYES YUCUNÁ",
    value: "SANTOS REYES YUCUNÁ - OAXACA",
  },
  {
    text: "SILACAYOÁPAM",
    value: "SILACAYOÁPAM - OAXACA",
  },
  {
    text: "SITIO DE XITLAPEHUA",
    value: "SITIO DE XITLAPEHUA - OAXACA",
  },
  {
    text: "SAN AGUSTÍN AMATENGO",
    value: "SAN AGUSTÍN AMATENGO - OAXACA",
  },
  {
    text: "SAN AGUSTÍN ATENANGO",
    value: "SAN AGUSTÍN ATENANGO - OAXACA",
  },
  {
    text: "SAN AGUSTÍN CHAYUCO",
    value: "SAN AGUSTÍN CHAYUCO - OAXACA",
  },
  {
    text: "SAN AGUSTÍN DE LAS JUNTAS",
    value: "SAN AGUSTÍN DE LAS JUNTAS - OAXACA",
  },
  {
    text: "SAN AGUSTÍN ETLA",
    value: "SAN AGUSTÍN ETLA - OAXACA",
  },
  {
    text: "SAN AGUSTÍN LOXICHA",
    value: "SAN AGUSTÍN LOXICHA - OAXACA",
  },
  {
    text: "SAN AGUSTÍN TLACOTEPEC",
    value: "SAN AGUSTÍN TLACOTEPEC - OAXACA",
  },
  {
    text: "SAN AGUSTÍN YATARENI",
    value: "SAN AGUSTÍN YATARENI - OAXACA",
  },
  {
    text: "SAN ANDRÉS CABECERA NUEVA",
    value: "SAN ANDRÉS CABECERA NUEVA - OAXACA",
  },
  {
    text: "SAN ANDRÉS DINICUITI",
    value: "SAN ANDRÉS DINICUITI - OAXACA",
  },
  {
    text: "SAN ANDRÉS HUAXPALTEPEC",
    value: "SAN ANDRÉS HUAXPALTEPEC - OAXACA",
  },
  {
    text: "SAN ANDRÉS HUAYÁPAM",
    value: "SAN ANDRÉS HUAYÁPAM - OAXACA",
  },
  {
    text: "SAN ANDRÉS IXTLÁHUACA",
    value: "SAN ANDRÉS IXTLÁHUACA - OAXACA",
  },
  {
    text: "SAN ANDRÉS LAGUNAS",
    value: "SAN ANDRÉS LAGUNAS - OAXACA",
  },
  {
    text: "SAN ANDRÉS NUXINO",
    value: "SAN ANDRÉS NUXINO - OAXACA",
  },
  {
    text: "SAN ANDRÉS PAXTLÁN",
    value: "SAN ANDRÉS PAXTLÁN - OAXACA",
  },
  {
    text: "SAN ANDRÉS SINAXTLA",
    value: "SAN ANDRÉS SINAXTLA - OAXACA",
  },
  {
    text: "SAN ANDRÉS SOLAGA",
    value: "SAN ANDRÉS SOLAGA - OAXACA",
  },
  {
    text: "SAN ANDRÉS TEOTILÁLPAM",
    value: "SAN ANDRÉS TEOTILÁLPAM - OAXACA",
  },
  {
    text: "SAN ANDRÉS TEPETLAPA",
    value: "SAN ANDRÉS TEPETLAPA - OAXACA",
  },
  {
    text: "SAN ANDRÉS YAÁ",
    value: "SAN ANDRÉS YAÁ - OAXACA",
  },
  {
    text: "SAN ANDRÉS ZABACHE",
    value: "SAN ANDRÉS ZABACHE - OAXACA",
  },
  {
    text: "SAN ANDRÉS ZAUTLA",
    value: "SAN ANDRÉS ZAUTLA - OAXACA",
  },
  {
    text: "SAN ANTONINO CASTILLO VELASCO",
    value: "SAN ANTONINO CASTILLO VELASCO - OAXACA",
  },
  {
    text: "SAN ANTONINO EL ALTO",
    value: "SAN ANTONINO EL ALTO - OAXACA",
  },
  {
    text: "SAN ANTONINO MONTE VERDE",
    value: "SAN ANTONINO MONTE VERDE - OAXACA",
  },
  {
    text: "SAN ANTONIO ACUTLA",
    value: "SAN ANTONIO ACUTLA - OAXACA",
  },
  {
    text: "SAN ANTONIO DE LA CAL",
    value: "SAN ANTONIO DE LA CAL - OAXACA",
  },
  {
    text: "SAN ANTONIO HUITEPEC",
    value: "SAN ANTONIO HUITEPEC - OAXACA",
  },
  {
    text: "SAN ANTONIO NANAHUATÍPAM",
    value: "SAN ANTONIO NANAHUATÍPAM - OAXACA",
  },
  {
    text: "SAN ANTONIO SINICAHUA",
    value: "SAN ANTONIO SINICAHUA - OAXACA",
  },
  {
    text: "SAN ANTONIO TEPETLAPA",
    value: "SAN ANTONIO TEPETLAPA - OAXACA",
  },
  {
    text: "SAN BALTAZAR CHICHICÁPAM",
    value: "SAN BALTAZAR CHICHICÁPAM - OAXACA",
  },
  {
    text: "SAN BALTAZAR LOXICHA",
    value: "SAN BALTAZAR LOXICHA - OAXACA",
  },
  {
    text: "SAN BALTAZAR YATZACHI EL BAJO",
    value: "SAN BALTAZAR YATZACHI EL BAJO - OAXACA",
  },
  {
    text: "SAN BARTOLO COYOTEPEC",
    value: "SAN BARTOLO COYOTEPEC - OAXACA",
  },
  {
    text: "SAN BARTOLO SOYALTEPEC",
    value: "SAN BARTOLO SOYALTEPEC - OAXACA",
  },
  {
    text: "SAN BARTOLO YAUTEPEC",
    value: "SAN BARTOLO YAUTEPEC - OAXACA",
  },
  {
    text: "SAN BARTOLOMÉ AYAUTLA",
    value: "SAN BARTOLOMÉ AYAUTLA - OAXACA",
  },
  {
    text: "SAN BARTOLOMÉ LOXICHA",
    value: "SAN BARTOLOMÉ LOXICHA - OAXACA",
  },
  {
    text: "SAN BARTOLOMÉ QUIALANA",
    value: "SAN BARTOLOMÉ QUIALANA - OAXACA",
  },
  {
    text: "SAN BARTOLOMÉ YUCUANE",
    value: "SAN BARTOLOMÉ YUCUANE - OAXACA",
  },
  {
    text: "SAN BARTOLOMÉ ZOOGOCHO",
    value: "SAN BARTOLOMÉ ZOOGOCHO - OAXACA",
  },
  {
    text: "SAN BERNARDO MIXTEPEC",
    value: "SAN BERNARDO MIXTEPEC - OAXACA",
  },
  {
    text: "SAN BLAS ATEMPA",
    value: "SAN BLAS ATEMPA - OAXACA",
  },
  {
    text: "SAN CARLOS YAUTEPEC",
    value: "SAN CARLOS YAUTEPEC - OAXACA",
  },
  {
    text: "SAN CRISTÓBAL AMATLÁN",
    value: "SAN CRISTÓBAL AMATLÁN - OAXACA",
  },
  {
    text: "SAN CRISTÓBAL AMOLTEPEC",
    value: "SAN CRISTÓBAL AMOLTEPEC - OAXACA",
  },
  {
    text: "SAN CRISTÓBAL LACHIRÍOAG",
    value: "SAN CRISTÓBAL LACHIRÍOAG - OAXACA",
  },
  {
    text: "SAN DIONISIO DEL MAR",
    value: "SAN DIONISIO DEL MAR - OAXACA",
  },
  {
    text: "SAN DIONISIO OCOTEPEC",
    value: "SAN DIONISIO OCOTEPEC - OAXACA",
  },
  {
    text: "SAN DIONISIO OCOTLÁN",
    value: "SAN DIONISIO OCOTLÁN - OAXACA",
  },
  {
    text: "SAN ESTEBAN ATATLAHUCA",
    value: "SAN ESTEBAN ATATLAHUCA - OAXACA",
  },
  {
    text: "SAN FELIPE JALAPA DE DÍAZ",
    value: "SAN FELIPE JALAPA DE DÍAZ - OAXACA",
  },
  {
    text: "SAN FELIPE TEJALÁPAM",
    value: "SAN FELIPE TEJALÁPAM - OAXACA",
  },
  {
    text: "SAN FELIPE USILA",
    value: "SAN FELIPE USILA - OAXACA",
  },
  {
    text: "SAN FRANCISCO CAHUACUÁ",
    value: "SAN FRANCISCO CAHUACUÁ - OAXACA",
  },
  {
    text: "SAN FRANCISCO CAJONOS",
    value: "SAN FRANCISCO CAJONOS - OAXACA",
  },
  {
    text: "SAN FRANCISCO CHAPULAPA",
    value: "SAN FRANCISCO CHAPULAPA - OAXACA",
  },
  {
    text: "SAN FRANCISCO CHINDÚA",
    value: "SAN FRANCISCO CHINDÚA - OAXACA",
  },
  {
    text: "SAN FRANCISCO DEL MAR",
    value: "SAN FRANCISCO DEL MAR - OAXACA",
  },
  {
    text: "SAN FRANCISCO HUEHUETLÁN",
    value: "SAN FRANCISCO HUEHUETLÁN - OAXACA",
  },
  {
    text: "SAN FRANCISCO IXHUATÁN",
    value: "SAN FRANCISCO IXHUATÁN - OAXACA",
  },
  {
    text: "SAN FRANCISCO JALTEPETONGO",
    value: "SAN FRANCISCO JALTEPETONGO - OAXACA",
  },
  {
    text: "SAN FRANCISCO LACHIGOLÓ",
    value: "SAN FRANCISCO LACHIGOLÓ - OAXACA",
  },
  {
    text: "SAN FRANCISCO LOGUECHE",
    value: "SAN FRANCISCO LOGUECHE - OAXACA",
  },
  {
    text: "SAN FRANCISCO NUXANO",
    value: "SAN FRANCISCO NUXANO - OAXACA",
  },
  {
    text: "SAN FRANCISCO OZOLOTEPEC",
    value: "SAN FRANCISCO OZOLOTEPEC - OAXACA",
  },
  {
    text: "SAN FRANCISCO SOLA",
    value: "SAN FRANCISCO SOLA - OAXACA",
  },
  {
    text: "SAN FRANCISCO TELIXTLÁHUACA",
    value: "SAN FRANCISCO TELIXTLÁHUACA - OAXACA",
  },
  {
    text: "SAN FRANCISCO TEOPAN",
    value: "SAN FRANCISCO TEOPAN - OAXACA",
  },
  {
    text: "SAN FRANCISCO TLAPANCINGO",
    value: "SAN FRANCISCO TLAPANCINGO - OAXACA",
  },
  {
    text: "SAN GABRIEL MIXTEPEC",
    value: "SAN GABRIEL MIXTEPEC - OAXACA",
  },
  {
    text: "SAN ILDEFONSO AMATLÁN",
    value: "SAN ILDEFONSO AMATLÁN - OAXACA",
  },
  {
    text: "SAN ILDEFONSO SOLA",
    value: "SAN ILDEFONSO SOLA - OAXACA",
  },
  {
    text: "SAN ILDEFONSO VILLA ALTA",
    value: "SAN ILDEFONSO VILLA ALTA - OAXACA",
  },
  {
    text: "SAN JUAN BAUTISTA ATATLAHUCA",
    value: "SAN JUAN BAUTISTA ATATLAHUCA - OAXACA",
  },
  {
    text: "SAN JUAN BAUTISTA COIXTLÁHUACA",
    value: "SAN JUAN BAUTISTA COIXTLÁHUACA - OAXACA",
  },
  {
    text: "SAN JUAN BAUTISTA JAYACATLÁN",
    value: "SAN JUAN BAUTISTA JAYACATLÁN - OAXACA",
  },
  {
    text: "SAN JUAN BAUTISTA LO DE SOTO",
    value: "SAN JUAN BAUTISTA LO DE SOTO - OAXACA",
  },
  {
    text: "SAN JUAN BAUTISTA SUCHITEPEC",
    value: "SAN JUAN BAUTISTA SUCHITEPEC - OAXACA",
  },
  {
    text: "SAN JUAN BAUTISTA TLACHICHILCO",
    value: "SAN JUAN BAUTISTA TLACHICHILCO - OAXACA",
  },
  {
    text: "SAN JACINTO AMILPAS",
    value: "SAN JACINTO AMILPAS - OAXACA",
  },
  {
    text: "SAN JACINTO TLACOTEPEC",
    value: "SAN JACINTO TLACOTEPEC - OAXACA",
  },
  {
    text: "SAN JERÓNIMO COATLAN",
    value: "SAN JERÓNIMO COATLAN - OAXACA",
  },
  {
    text: "SAN JERÓNIMO SILACAYOAPILLA",
    value: "SAN JERÓNIMO SILACAYOAPILLA - OAXACA",
  },
  {
    text: "SAN JERÓNIMO SOSOLA",
    value: "SAN JERÓNIMO SOSOLA - OAXACA",
  },
  {
    text: "SAN JERÓNIMO TAVICHE",
    value: "SAN JERÓNIMO TAVICHE - OAXACA",
  },
  {
    text: "SAN JERÓNIMO TECOATL",
    value: "SAN JERÓNIMO TECOATL - OAXACA",
  },
  {
    text: "SAN JERÓNIMO TLACOCHAHUAYA",
    value: "SAN JERÓNIMO TLACOCHAHUAYA - OAXACA",
  },
  {
    text: "SAN JORGE NUCHITA",
    value: "SAN JORGE NUCHITA - OAXACA",
  },
  {
    text: "SAN JOSÉ AYUQUILA",
    value: "SAN JOSÉ AYUQUILA - OAXACA",
  },
  {
    text: "SAN JOSÉ CHILTEPEC",
    value: "SAN JOSÉ CHILTEPEC - OAXACA",
  },
  {
    text: "SAN JOSÉ DEL PEÑASCO",
    value: "SAN JOSÉ DEL PEÑASCO - OAXACA",
  },
  {
    text: "SAN JOSÉ DEL PROGRESO",
    value: "SAN JOSÉ DEL PROGRESO - OAXACA",
  },
  {
    text: "SAN JOSÉ ESTANCIA GRANDE",
    value: "SAN JOSÉ ESTANCIA GRANDE - OAXACA",
  },
  {
    text: "SAN JOSÉ INDEPENDENCIA",
    value: "SAN JOSÉ INDEPENDENCIA - OAXACA",
  },
  {
    text: "SAN JOSÉ LACHIGUIRI",
    value: "SAN JOSÉ LACHIGUIRI - OAXACA",
  },
  {
    text: "SAN JOSÉ TENANGO",
    value: "SAN JOSÉ TENANGO - OAXACA",
  },
  {
    text: "SAN JUAN ACHIUTLA",
    value: "SAN JUAN ACHIUTLA - OAXACA",
  },
  {
    text: "SAN JUAN ATEPEC",
    value: "SAN JUAN ATEPEC - OAXACA",
  },
  {
    text: "SAN JUAN BAUTISTA CUICATLÁN",
    value: "SAN JUAN BAUTISTA CUICATLÁN - OAXACA",
  },
  {
    text: "SAN JUAN BAUTISTA GUELACHE",
    value: "SAN JUAN BAUTISTA GUELACHE - OAXACA",
  },
  {
    text: "SAN JUAN BAUTISTA TUXTEPEC",
    value: "SAN JUAN BAUTISTA TUXTEPEC - OAXACA",
  },
  {
    text: "SAN JUAN BAUTISTA VALLE NAC",
    value: "SAN JUAN BAUTISTA VALLE NAC - OAXACA",
  },
  {
    text: "SAN JUAN CACAHUATEPEC",
    value: "SAN JUAN CACAHUATEPEC - OAXACA",
  },
  {
    text: "SAN JUAN CHICOMEZÚCHIL",
    value: "SAN JUAN CHICOMEZÚCHIL - OAXACA",
  },
  {
    text: "SAN JUAN CHILATECA",
    value: "SAN JUAN CHILATECA - OAXACA",
  },
  {
    text: "SAN JUAN CIENEGUILLA",
    value: "SAN JUAN CIENEGUILLA - OAXACA",
  },
  {
    text: "SAN JUAN COATZÓSPAM",
    value: "SAN JUAN COATZÓSPAM - OAXACA",
  },
  {
    text: "SAN JUAN COLORADO",
    value: "SAN JUAN COLORADO - OAXACA",
  },
  {
    text: "SAN JUAN COMALTEPEC",
    value: "SAN JUAN COMALTEPEC - OAXACA",
  },
  {
    text: "SAN JUAN COTZOCÓN",
    value: "SAN JUAN COTZOCÓN - OAXACA",
  },
  {
    text: "SAN JUAN DE LOS CUÉS",
    value: "SAN JUAN DE LOS CUÉS - OAXACA",
  },
  {
    text: "SAN JUAN DEL ESTADO",
    value: "SAN JUAN DEL ESTADO - OAXACA",
  },
  {
    text: "SAN JUAN DEL RÍO",
    value: "SAN JUAN DEL RÍO - OAXACA",
  },
  {
    text: "SAN JUAN DIUXI",
    value: "SAN JUAN DIUXI - OAXACA",
  },
  {
    text: "SAN JUAN EVANGELISTA ANALCO",
    value: "SAN JUAN EVANGELISTA ANALCO - OAXACA",
  },
  {
    text: "SAN JUAN GUELAVÍA",
    value: "SAN JUAN GUELAVÍA - OAXACA",
  },
  {
    text: "SAN JUAN GUICHICOVI",
    value: "SAN JUAN GUICHICOVI - OAXACA",
  },
  {
    text: "SAN JUAN IHUALTEPEC",
    value: "SAN JUAN IHUALTEPEC - OAXACA",
  },
  {
    text: "SAN JUAN JUQUILA MIXES",
    value: "SAN JUAN JUQUILA MIXES - OAXACA",
  },
  {
    text: "SAN JUAN JUQUILA VIJANOS",
    value: "SAN JUAN JUQUILA VIJANOS - OAXACA",
  },
  {
    text: "SAN JUAN LACHAO",
    value: "SAN JUAN LACHAO - OAXACA",
  },
  {
    text: "SAN JUAN LACHIGALLA",
    value: "SAN JUAN LACHIGALLA - OAXACA",
  },
  {
    text: "SAN JUAN LAJARCIA",
    value: "SAN JUAN LAJARCIA - OAXACA",
  },
  {
    text: "SAN JUAN LALANA",
    value: "SAN JUAN LALANA - OAXACA",
  },
  {
    text: "SAN JUAN MAZATLÁN",
    value: "SAN JUAN MAZATLÁN - OAXACA",
  },
  {
    text: "SAN JUAN MIXTEPEC DTO",
    value: "SAN JUAN MIXTEPEC DTO - OAXACA",
  },
  {
    text: "SAN JUAN MIXTEPEC",
    value: "SAN JUAN MIXTEPEC - OAXACA",
  },
  {
    text: "SAN JUAN NUMI",
    value: "SAN JUAN NUMI - OAXACA",
  },
  {
    text: "SAN JUAN OZOLOTEPEC",
    value: "SAN JUAN OZOLOTEPEC - OAXACA",
  },
  {
    text: "SAN JUAN PETLAPA",
    value: "SAN JUAN PETLAPA - OAXACA",
  },
  {
    text: "SAN JUAN QUIAHIJE",
    value: "SAN JUAN QUIAHIJE - OAXACA",
  },
  {
    text: "SAN JUAN QUIOTEPEC",
    value: "SAN JUAN QUIOTEPEC - OAXACA",
  },
  {
    text: "SAN JUAN SAYULTEPEC",
    value: "SAN JUAN SAYULTEPEC - OAXACA",
  },
  {
    text: "SAN JUAN TABAÁ",
    value: "SAN JUAN TABAÁ - OAXACA",
  },
  {
    text: "SAN JUAN TAMAZOLA",
    value: "SAN JUAN TAMAZOLA - OAXACA",
  },
  {
    text: "SAN JUAN TEITA",
    value: "SAN JUAN TEITA - OAXACA",
  },
  {
    text: "SAN JUAN TEITIPAC",
    value: "SAN JUAN TEITIPAC - OAXACA",
  },
  {
    text: "SAN JUAN TEPEUXILA",
    value: "SAN JUAN TEPEUXILA - OAXACA",
  },
  {
    text: "SAN JUAN TEPOSCOLULA",
    value: "SAN JUAN TEPOSCOLULA - OAXACA",
  },
  {
    text: "SAN JUAN YAEÉ",
    value: "SAN JUAN YAEÉ - OAXACA",
  },
  {
    text: "SAN JUAN YATZONA",
    value: "SAN JUAN YATZONA - OAXACA",
  },
  {
    text: "SAN JUAN YUCUITA",
    value: "SAN JUAN YUCUITA - OAXACA",
  },
  {
    text: "SAN LORENZO ALBARRADAS",
    value: "SAN LORENZO ALBARRADAS - OAXACA",
  },
  {
    text: "SAN LORENZO CACAOTEPEC",
    value: "SAN LORENZO CACAOTEPEC - OAXACA",
  },
  {
    text: "SAN LORENZO CUAUNECUILTITLA",
    value: "SAN LORENZO CUAUNECUILTITLA - OAXACA",
  },
  {
    text: "SAN LORENZO",
    value: "SAN LORENZO - OAXACA",
  },
  {
    text: "SAN LORENZO TEXMELÚCAN",
    value: "SAN LORENZO TEXMELÚCAN - OAXACA",
  },
  {
    text: "SAN LORENZO VICTORIA",
    value: "SAN LORENZO VICTORIA - OAXACA",
  },
  {
    text: "SAN LUCAS CAMOTLÁN",
    value: "SAN LUCAS CAMOTLÁN - OAXACA",
  },
  {
    text: "SAN LUCAS OJITLÁN",
    value: "SAN LUCAS OJITLÁN - OAXACA",
  },
  {
    text: "SAN LUCAS QUIAVINÍ",
    value: "SAN LUCAS QUIAVINÍ - OAXACA",
  },
  {
    text: "SAN LUCAS ZOQUIÁPAM",
    value: "SAN LUCAS ZOQUIÁPAM - OAXACA",
  },
  {
    text: "SAN LUIS AMATLÁN",
    value: "SAN LUIS AMATLÁN - OAXACA",
  },
  {
    text: "SAN MARCIAL OZOLOTEPEC",
    value: "SAN MARCIAL OZOLOTEPEC - OAXACA",
  },
  {
    text: "SAN MARCOS ARTEAGA",
    value: "SAN MARCOS ARTEAGA - OAXACA",
  },
  {
    text: "SAN MARTÍN DE LOS CANSECOS",
    value: "SAN MARTÍN DE LOS CANSECOS - OAXACA",
  },
  {
    text: "SAN MARTÍN HUAMELÚLPAM",
    value: "SAN MARTÍN HUAMELÚLPAM - OAXACA",
  },
  {
    text: "SAN MARTÍN ITUNYOSO",
    value: "SAN MARTÍN ITUNYOSO - OAXACA",
  },
  {
    text: "SAN MARTÍN LACHILÁ",
    value: "SAN MARTÍN LACHILÁ - OAXACA",
  },
  {
    text: "SAN MARTÍN PERAS",
    value: "SAN MARTÍN PERAS - OAXACA",
  },
  {
    text: "SAN MARTÍN TILCAJETE",
    value: "SAN MARTÍN TILCAJETE - OAXACA",
  },
  {
    text: "SAN MARTÍN TOXPALAN",
    value: "SAN MARTÍN TOXPALAN - OAXACA",
  },
  {
    text: "SAN MARTÍN ZACATEPEC",
    value: "SAN MARTÍN ZACATEPEC - OAXACA",
  },
  {
    text: "SAN MATEO CAJONOS",
    value: "SAN MATEO CAJONOS - OAXACA",
  },
  {
    text: "SAN MATEO DEL MAR",
    value: "SAN MATEO DEL MAR - OAXACA",
  },
  {
    text: "SAN MATEO ETLATONGO",
    value: "SAN MATEO ETLATONGO - OAXACA",
  },
  {
    text: "SAN MATEO NEJÁPAM",
    value: "SAN MATEO NEJÁPAM - OAXACA",
  },
  {
    text: "SAN MATEO PENASCO",
    value: "SAN MATEO PENASCO - OAXACA",
  },
  {
    text: "SAN MATEO PINAS",
    value: "SAN MATEO PINAS - OAXACA",
  },
  {
    text: "SAN MATEO RÍO HONDO",
    value: "SAN MATEO RÍO HONDO - OAXACA",
  },
  {
    text: "SAN MATEO SINDIHUI",
    value: "SAN MATEO SINDIHUI - OAXACA",
  },
  {
    text: "SAN MATEO TLAPILTEPEC",
    value: "SAN MATEO TLAPILTEPEC - OAXACA",
  },
  {
    text: "SAN MATEO YOLOXOCHITLAN",
    value: "SAN MATEO YOLOXOCHITLAN - OAXACA",
  },
  {
    text: "SAN MELCHOR BETAZA",
    value: "SAN MELCHOR BETAZA - OAXACA",
  },
  {
    text: "SAN MIGUEL ACHIUTLA",
    value: "SAN MIGUEL ACHIUTLA - OAXACA",
  },
  {
    text: "SAN MIGUEL AHUEHUETITLAN",
    value: "SAN MIGUEL AHUEHUETITLAN - OAXACA",
  },
  {
    text: "SAN MIGUEL ALOÁPAM",
    value: "SAN MIGUEL ALOÁPAM - OAXACA",
  },
  {
    text: "SAN MIGUEL ALOÁPAM",
    value: "SAN MIGUEL ALOÁPAM - OAXACA",
  },
  {
    text: "SAN MIGUEL AMATLÁN",
    value: "SAN MIGUEL AMATLÁN - OAXACA",
  },
  {
    text: "SAN MIGUEL CHICAHUA",
    value: "SAN MIGUEL CHICAHUA - OAXACA",
  },
  {
    text: "SAN MIGUEL CHIMALAPA",
    value: "SAN MIGUEL CHIMALAPA - OAXACA",
  },
  {
    text: "SAN MIGUEL COATLAN",
    value: "SAN MIGUEL COATLAN - OAXACA",
  },
  {
    text: "SAN MIGUEL DEL PTO",
    value: "SAN MIGUEL DEL PTO - OAXACA",
  },
  {
    text: "SAN MIGUEL DEL RÍO",
    value: "SAN MIGUEL DEL RÍO - OAXACA",
  },
  {
    text: "SAN MIGUEL EJUTLA",
    value: "SAN MIGUEL EJUTLA - OAXACA",
  },
  {
    text: "SAN MIGUEL EL GRANDE",
    value: "SAN MIGUEL EL GRANDE - OAXACA",
  },
  {
    text: "SAN MIGUEL HUAUTLA",
    value: "SAN MIGUEL HUAUTLA - OAXACA",
  },
  {
    text: "SAN MIGUEL MIXTEPEC",
    value: "SAN MIGUEL MIXTEPEC - OAXACA",
  },
  {
    text: "SAN MIGUEL PANIXTLÁHUACA",
    value: "SAN MIGUEL PANIXTLÁHUACA - OAXACA",
  },
  {
    text: "SAN MIGUEL PERAS",
    value: "SAN MIGUEL PERAS - OAXACA",
  },
  {
    text: "SAN MIGUEL PIEDRAS",
    value: "SAN MIGUEL PIEDRAS - OAXACA",
  },
  {
    text: "SAN MIGUEL QUETZALTEPEC",
    value: "SAN MIGUEL QUETZALTEPEC - OAXACA",
  },
  {
    text: "SAN MIGUEL SNTA FLOR",
    value: "SAN MIGUEL SNTA FLOR - OAXACA",
  },
  {
    text: "SAN MIGUEL SOYALTEPEC",
    value: "SAN MIGUEL SOYALTEPEC - OAXACA",
  },
  {
    text: "SAN MIGUEL SUCHIXTEPEC",
    value: "SAN MIGUEL SUCHIXTEPEC - OAXACA",
  },
  {
    text: "SAN MIGUEL TECOMATLÁN",
    value: "SAN MIGUEL TECOMATLÁN - OAXACA",
  },
  {
    text: "SAN MIGUEL TENANGO",
    value: "SAN MIGUEL TENANGO - OAXACA",
  },
  {
    text: "SAN MIGUEL TEQUIXTEPEC",
    value: "SAN MIGUEL TEQUIXTEPEC - OAXACA",
  },
  {
    text: "SAN MIGUEL TILQUIÁPAM",
    value: "SAN MIGUEL TILQUIÁPAM - OAXACA",
  },
  {
    text: "SAN MIGUEL TLACAMAMA",
    value: "SAN MIGUEL TLACAMAMA - OAXACA",
  },
  {
    text: "SAN MIGUEL TLACOTEPEC",
    value: "SAN MIGUEL TLACOTEPEC - OAXACA",
  },
  {
    text: "SAN MIGUEL TULANCINGO",
    value: "SAN MIGUEL TULANCINGO - OAXACA",
  },
  {
    text: "SAN MIGUEL YOTAO",
    value: "SAN MIGUEL YOTAO - OAXACA",
  },
  {
    text: "SAN NICOLÁS HIDALGO",
    value: "SAN NICOLÁS HIDALGO - OAXACA",
  },
  {
    text: "SAN NICOLÁS",
    value: "SAN NICOLÁS - OAXACA",
  },
  {
    text: "SAN PABLO COATLAN",
    value: "SAN PABLO COATLAN - OAXACA",
  },
  {
    text: "SAN PABLO CUATRO VENADOS",
    value: "SAN PABLO CUATRO VENADOS - OAXACA",
  },
  {
    text: "SAN PABLO ETLA",
    value: "SAN PABLO ETLA - OAXACA",
  },
  {
    text: "SAN PABLO HUITZO",
    value: "SAN PABLO HUITZO - OAXACA",
  },
  {
    text: "SAN PABLO HUIXTEPEC",
    value: "SAN PABLO HUIXTEPEC - OAXACA",
  },
  {
    text: "SAN PABLO MACUILTIANGUIS",
    value: "SAN PABLO MACUILTIANGUIS - OAXACA",
  },
  {
    text: "SAN PABLO TIJALTEPEC",
    value: "SAN PABLO TIJALTEPEC - OAXACA",
  },
  {
    text: "SAN PABLO VILLA DE MITLA",
    value: "SAN PABLO VILLA DE MITLA - OAXACA",
  },
  {
    text: "SAN PABLO YAGANIZA",
    value: "SAN PABLO YAGANIZA - OAXACA",
  },
  {
    text: "SAN PEDRO AMUZGOS",
    value: "SAN PEDRO AMUZGOS - OAXACA",
  },
  {
    text: "SAN PEDRO APÓSTOL",
    value: "SAN PEDRO APÓSTOL - OAXACA",
  },
  {
    text: "SAN PEDRO ATOYAC",
    value: "SAN PEDRO ATOYAC - OAXACA",
  },
  {
    text: "SAN PEDRO CAJONOS",
    value: "SAN PEDRO CAJONOS - OAXACA",
  },
  {
    text: "SAN PEDRO COMITÁNCILLO",
    value: "SAN PEDRO COMITÁNCILLO - OAXACA",
  },
  {
    text: "SAN PEDRO COXCALTEPEC CÁNTAROS",
    value: "SAN PEDRO COXCALTEPEC CÁNTAROS - OAXACA",
  },
  {
    text: "SAN PEDRO EL ALTO",
    value: "SAN PEDRO EL ALTO - OAXACA",
  },
  {
    text: "SAN PEDRO HUAMELULA",
    value: "SAN PEDRO HUAMELULA - OAXACA",
  },
  {
    text: "SAN PEDRO HUILOTEPEC",
    value: "SAN PEDRO HUILOTEPEC - OAXACA",
  },
  {
    text: "SAN PEDRO IXCATLÁN",
    value: "SAN PEDRO IXCATLÁN - OAXACA",
  },
  {
    text: "SAN PEDRO IXTLÁHUACA",
    value: "SAN PEDRO IXTLÁHUACA - OAXACA",
  },
  {
    text: "SAN PEDRO JALTEPETONGO",
    value: "SAN PEDRO JALTEPETONGO - OAXACA",
  },
  {
    text: "SAN PEDRO JICAYÁN",
    value: "SAN PEDRO JICAYÁN - OAXACA",
  },
  {
    text: "SAN PEDRO JOCOTIPAC",
    value: "SAN PEDRO JOCOTIPAC - OAXACA",
  },
  {
    text: "SAN PEDRO JUCHATENGO",
    value: "SAN PEDRO JUCHATENGO - OAXACA",
  },
  {
    text: "SAN PEDRO MÁRTIR",
    value: "SAN PEDRO MÁRTIR - OAXACA",
  },
  {
    text: "SAN PEDRO MÁRTIR QUIECHAPA",
    value: "SAN PEDRO MÁRTIR QUIECHAPA - OAXACA",
  },
  {
    text: "SAN PEDRO MÁRTIR YUCUXACO",
    value: "SAN PEDRO MÁRTIR YUCUXACO - OAXACA",
  },
  {
    text: "SAN PEDRO MIXTEPEC DTO 22",
    value: "SAN PEDRO MIXTEPEC DTO 22 - OAXACA",
  },
  {
    text: "SAN PEDRO MIXTEPEC DTO 26",
    value: "SAN PEDRO MIXTEPEC DTO 26 - OAXACA",
  },
  {
    text: "SAN PEDRO MOLINOS",
    value: "SAN PEDRO MOLINOS - OAXACA",
  },
  {
    text: "SAN PEDRO NOPALA",
    value: "SAN PEDRO NOPALA - OAXACA",
  },
  {
    text: "SAN PEDRO OCOPETATILLO",
    value: "SAN PEDRO OCOPETATILLO - OAXACA",
  },
  {
    text: "SAN PEDRO OCOTEPEC",
    value: "SAN PEDRO OCOTEPEC - OAXACA",
  },
  {
    text: "SAN PEDRO POCHUTLA",
    value: "SAN PEDRO POCHUTLA - OAXACA",
  },
  {
    text: "SAN PEDRO QUIATONI",
    value: "SAN PEDRO QUIATONI - OAXACA",
  },
  {
    text: "SAN PEDRO SOCHIÁPAM",
    value: "SAN PEDRO SOCHIÁPAM - OAXACA",
  },
  {
    text: "SAN PEDRO TAPANATEPEC",
    value: "SAN PEDRO TAPANATEPEC - OAXACA",
  },
  {
    text: "SAN PEDRO TAVICHE",
    value: "SAN PEDRO TAVICHE - OAXACA",
  },
  {
    text: "SAN PEDRO TEOZACOALCO",
    value: "SAN PEDRO TEOZACOALCO - OAXACA",
  },
  {
    text: "SAN PEDRO TEUTILA",
    value: "SAN PEDRO TEUTILA - OAXACA",
  },
  {
    text: "SAN PEDRO TIDAÁ",
    value: "SAN PEDRO TIDAÁ - OAXACA",
  },
  {
    text: "SAN PEDRO TOPILTEPEC",
    value: "SAN PEDRO TOPILTEPEC - OAXACA",
  },
  {
    text: "SAN PEDRO TOTOLÁPAM",
    value: "SAN PEDRO TOTOLÁPAM - OAXACA",
  },
  {
    text: "SAN PEDRO Y SAN PABLO AYUTLA",
    value: "SAN PEDRO Y SAN PABLO AYUTLA - OAXACA",
  },
  {
    text: "SAN PEDRO Y SAN PABLO TEPOSCOLULA",
    value: "SAN PEDRO Y SAN PABLO TEPOSCOLULA - OAXACA",
  },
  {
    text: "SAN PEDRO Y SAN PABLO TEQUIXTEPEC",
    value: "SAN PEDRO Y SAN PABLO TEQUIXTEPEC - OAXACA",
  },
  {
    text: "SAN PEDRO YANERI",
    value: "SAN PEDRO YANERI - OAXACA",
  },
  {
    text: "SAN PEDRO YÓLOX",
    value: "SAN PEDRO YÓLOX - OAXACA",
  },
  {
    text: "SAN PEDRO YUCUNÁMA",
    value: "SAN PEDRO YUCUNÁMA - OAXACA",
  },
  {
    text: "SAN RAYMUNDO JALPAN",
    value: "SAN RAYMUNDO JALPAN - OAXACA",
  },
  {
    text: "SAN SEBASTIÁN TECOMAXTLÁHUACA",
    value: "SAN SEBASTIÁN TECOMAXTLÁHUACA - OAXACA",
  },
  {
    text: "SAN SEBASTIÁN ABASOLO",
    value: "SAN SEBASTIÁN ABASOLO - OAXACA",
  },
  {
    text: "SAN SEBASTIÁN COATLAN",
    value: "SAN SEBASTIÁN COATLAN - OAXACA",
  },
  {
    text: "SAN SEBASTIÁN IXCAPA",
    value: "SAN SEBASTIÁN IXCAPA - OAXACA",
  },
  {
    text: "SAN SEBASTIÁN NICANANDUTA",
    value: "SAN SEBASTIÁN NICANANDUTA - OAXACA",
  },
  {
    text: "SAN SEBASTIÁN RÍO HONDO",
    value: "SAN SEBASTIÁN RÍO HONDO - OAXACA",
  },
  {
    text: "SAN SEBASTIÁN TEITIPAC",
    value: "SAN SEBASTIÁN TEITIPAC - OAXACA",
  },
  {
    text: "SAN SEBASTIÁN TUTLA",
    value: "SAN SEBASTIÁN TUTLA - OAXACA",
  },
  {
    text: "SAN SIMÓN ALMOLONGAS",
    value: "SAN SIMÓN ALMOLONGAS - OAXACA",
  },
  {
    text: "SAN SIMÓN ZAHUATLÁN",
    value: "SAN SIMÓN ZAHUATLÁN - OAXACA",
  },
  {
    text: "SAN VICENTE COATLAN",
    value: "SAN VICENTE COATLAN - OAXACA",
  },
  {
    text: "SAN VICENTE LACHIXÍO",
    value: "SAN VICENTE LACHIXÍO - OAXACA",
  },
  {
    text: "SAN VICENTE NUÑÚ",
    value: "SAN VICENTE NUÑÚ - OAXACA",
  },
  {
    text: "SOLEDAD ETLA",
    value: "SOLEDAD ETLA - OAXACA",
  },
  {
    text: "TAMAZULAPAM DEL ESP SANTO",
    value: "TAMAZULAPAM DEL ESP SANTO - OAXACA",
  },
  {
    text: "TANETZE DE ZARAGOZA",
    value: "TANETZE DE ZARAGOZA - OAXACA",
  },
  {
    text: "TANICHE",
    value: "TANICHE - OAXACA",
  },
  {
    text: "TATALTEPEC DE VALDÉS",
    value: "TATALTEPEC DE VALDÉS - OAXACA",
  },
  {
    text: "TEOCOCUILCO MARCOS PÉREZ",
    value: "TEOCOCUILCO MARCOS PÉREZ - OAXACA",
  },
  {
    text: "TEOTITLÁN DE FLORES MAGON",
    value: "TEOTITLÁN DE FLORES MAGON - OAXACA",
  },
  {
    text: "TEOTITLÁN DEL VALLE",
    value: "TEOTITLÁN DEL VALLE - OAXACA",
  },
  {
    text: "TEOTONGO",
    value: "TEOTONGO - OAXACA",
  },
  {
    text: "TEPELMEME VILLA DE MORELOS",
    value: "TEPELMEME VILLA DE MORELOS - OAXACA",
  },
  {
    text: "TEOTITLÁN DE SEGURA Y LUNA",
    value: "TEOTITLÁN DE SEGURA Y LUNA - OAXACA",
  },
  {
    text: "TLACOLULA DE MATAMOROS",
    value: "TLACOLULA DE MATAMOROS - OAXACA",
  },
  {
    text: "TLACOTEPEC PLUMAS",
    value: "TLACOTEPEC PLUMAS - OAXACA",
  },
  {
    text: "TLALIXTAC DE CABRERA",
    value: "TLALIXTAC DE CABRERA - OAXACA",
  },
  {
    text: "TOTONTEPEC VILLA MORELOS",
    value: "TOTONTEPEC VILLA MORELOS - OAXACA",
  },
  {
    text: "TRINIDAD ZAACHILA",
    value: "TRINIDAD ZAACHILA - OAXACA",
  },
  {
    text: "TUXTEPEC",
    value: "TUXTEPEC - OAXACA",
  },
  {
    text: "UNIÓN HIDALGO",
    value: "UNIÓN HIDALGO - OAXACA",
  },
  {
    text: "VALERÍO TRUJANO",
    value: "VALERÍO TRUJANO - OAXACA",
  },
  {
    text: "VENUSTIANO CARRANZA",
    value: "VENUSTIANO CARRANZA - PUEBLA",
  },
  {
    text: "VICENTE GUERRERO",
    value: "VICENTE GUERRERO - PUEBLA",
  },
  {
    text: "XAYACATLÁN DE BRAVO",
    value: "XAYACATLÁN DE BRAVO - PUEBLA",
  },
  {
    text: "XICOTEPEC",
    value: "XICOTEPEC - PUEBLA",
  },
  {
    text: "XICOTLÁN",
    value: "XICOTLÁN - PUEBLA",
  },
  {
    text: "XIUTETELCO",
    value: "XIUTETELCO - PUEBLA",
  },
  {
    text: "XOCHIAPULCO",
    value: "XOCHIAPULCO - PUEBLA",
  },
  {
    text: "XOCHILTEPEC",
    value: "XOCHILTEPEC - PUEBLA",
  },
  {
    text: "XOCHITLAN DE VTE SUAREZ",
    value: "XOCHITLAN DE VTE SUAREZ - PUEBLA",
  },
  {
    text: "XOCHITLAN TODOS SANTOS",
    value: "XOCHITLAN TODOS SANTOS - PUEBLA",
  },
  {
    text: "YAONÁHUAC",
    value: "YAONÁHUAC - PUEBLA",
  },
  {
    text: "YEHUALTEPEC",
    value: "YEHUALTEPEC - PUEBLA",
  },
  {
    text: "ZACAPALA",
    value: "ZACAPALA - PUEBLA",
  },
  {
    text: "ZACAPOAXTLA",
    value: "ZACAPOAXTLA - PUEBLA",
  },
  {
    text: "ZACATLÁN",
    value: "ZACATLÁN - PUEBLA",
  },
  {
    text: "ZAPOTITLÁN DE MÉNDEZ",
    value: "ZAPOTITLÁN DE MÉNDEZ - PUEBLA",
  },
  {
    text: "ZAPOTITLÁN",
    value: "ZAPOTITLÁN - PUEBLA",
  },
  {
    text: "ZARAGOZA",
    value: "ZARAGOZA - PUEBLA",
  },
  {
    text: "ZAUTLA",
    value: "ZAUTLA - PUEBLA",
  },
  {
    text: "ZIHUATEUTLA",
    value: "ZIHUATEUTLA - PUEBLA",
  },
  {
    text: "ZINACATEPEC",
    value: "ZINACATEPEC - PUEBLA",
  },
  {
    text: "ZONGOZOTLA",
    value: "ZONGOZOTLA - PUEBLA",
  },
  {
    text: "ZOQUIAPAN",
    value: "ZOQUIAPAN - PUEBLA",
  },
  {
    text: "ZOQUITLÁN",
    value: "ZOQUITLÁN - PUEBLA",
  },
  {
    text: "ACAJETE",
    value: "ACAJETE - PUEBLA",
  },
  {
    text: "ACATENO",
    value: "ACATENO - PUEBLA",
  },
  {
    text: "ACATLÁN",
    value: "ACATLÁN - PUEBLA",
  },
  {
    text: "ACATZINGO",
    value: "ACATZINGO - PUEBLA",
  },
  {
    text: "ACTEOPAN",
    value: "ACTEOPAN - PUEBLA",
  },
  {
    text: "AHUACATLÁN",
    value: "AHUACATLÁN - PUEBLA",
  },
  {
    text: "AHUATLAN",
    value: "AHUATLAN - PUEBLA",
  },
  {
    text: "AHUAZOTEPEC",
    value: "AHUAZOTEPEC - PUEBLA",
  },
  {
    text: "AHUEHUETITLA",
    value: "AHUEHUETITLA - PUEBLA",
  },
  {
    text: "AJALPAN",
    value: "AJALPAN - PUEBLA",
  },
  {
    text: "ALBINO ZERTUCHE",
    value: "ALBINO ZERTUCHE - PUEBLA",
  },
  {
    text: "ALJOJUCA",
    value: "ALJOJUCA - PUEBLA",
  },
  {
    text: "ALTEPEXI",
    value: "ALTEPEXI - PUEBLA",
  },
  {
    text: "AMIXTLÁN",
    value: "AMIXTLÁN - PUEBLA",
  },
  {
    text: "AMOZOC",
    value: "AMOZOC - PUEBLA",
  },
  {
    text: "AQUIXTLA",
    value: "AQUIXTLA - PUEBLA",
  },
  {
    text: "ATEMPAN",
    value: "ATEMPAN - PUEBLA",
  },
  {
    text: "ATEXCAL",
    value: "ATEXCAL - PUEBLA",
  },
  {
    text: "ATLEQUIZAYAN",
    value: "ATLEQUIZAYAN - PUEBLA",
  },
  {
    text: "ATLIXCO",
    value: "ATLIXCO - PUEBLA",
  },
  {
    text: "ATOYATEMPAN",
    value: "ATOYATEMPAN - PUEBLA",
  },
  {
    text: "ATZALA",
    value: "ATZALA - PUEBLA",
  },
  {
    text: "ATZITZIHUACÁN",
    value: "ATZITZIHUACÁN - PUEBLA",
  },
  {
    text: "ATZITZINTLA",
    value: "ATZITZINTLA - PUEBLA",
  },
  {
    text: "AXUTLA",
    value: "AXUTLA - PUEBLA",
  },
  {
    text: "AYOTOXCO DE GUERRERO",
    value: "AYOTOXCO DE GUERRERO - PUEBLA",
  },
  {
    text: "CALPAN",
    value: "CALPAN - PUEBLA",
  },
  {
    text: "CALTEPEC",
    value: "CALTEPEC - PUEBLA",
  },
  {
    text: "CAMOCUAUTLA",
    value: "CAMOCUAUTLA - PUEBLA",
  },
  {
    text: "CANADA MORELOS",
    value: "CANADA MORELOS - PUEBLA",
  },
  {
    text: "CAXHUACAN",
    value: "CAXHUACAN - PUEBLA",
  },
  {
    text: "CHALCHICOMULA DE SESMA",
    value: "CHALCHICOMULA DE SESMA - PUEBLA",
  },
  {
    text: "CHAPULCO",
    value: "CHAPULCO - PUEBLA",
  },
  {
    text: "CHIAUTLA",
    value: "CHIAUTLA - PUEBLA",
  },
  {
    text: "CHIAUTZINGO",
    value: "CHIAUTZINGO - PUEBLA",
  },
  {
    text: "CHICHIQUILA",
    value: "CHICHIQUILA - PUEBLA",
  },
  {
    text: "CHICONCUAUTLA",
    value: "CHICONCUAUTLA - PUEBLA",
  },
  {
    text: "CHIETLA",
    value: "CHIETLA - PUEBLA",
  },
  {
    text: "CHIGMECATITLÁN",
    value: "CHIGMECATITLÁN - PUEBLA",
  },
  {
    text: "CHIGNAHUAPAN",
    value: "CHIGNAHUAPAN - PUEBLA",
  },
  {
    text: "CHIGNAUTLA",
    value: "CHIGNAUTLA - PUEBLA",
  },
  {
    text: "CHILA DE LA SAL",
    value: "CHILA DE LA SAL - PUEBLA",
  },
  {
    text: "CHILA",
    value: "CHILA - PUEBLA",
  },
  {
    text: "CHILCHOTLA",
    value: "CHILCHOTLA - PUEBLA",
  },
  {
    text: "CHINANTLA",
    value: "CHINANTLA - PUEBLA",
  },
  {
    text: "COATEPEC",
    value: "COATEPEC - PUEBLA",
  },
  {
    text: "COATZINGO",
    value: "COATZINGO - PUEBLA",
  },
  {
    text: "COHETZALA",
    value: "COHETZALA - PUEBLA",
  },
  {
    text: "COHUECAN",
    value: "COHUECAN - PUEBLA",
  },
  {
    text: "CORONANGO",
    value: "CORONANGO - PUEBLA",
  },
  {
    text: "COXCATLÁN",
    value: "COXCATLÁN - PUEBLA",
  },
  {
    text: "COYOMEAPAN",
    value: "COYOMEAPAN - PUEBLA",
  },
  {
    text: "COYOTEPEC",
    value: "COYOTEPEC - PUEBLA",
  },
  {
    text: "CUAPIAXTLA DE MADERO",
    value: "CUAPIAXTLA DE MADERO - PUEBLA",
  },
  {
    text: "CUAUTEMPAN",
    value: "CUAUTEMPAN - PUEBLA",
  },
  {
    text: "CUAUTINCHÁN",
    value: "CUAUTINCHÁN - PUEBLA",
  },
  {
    text: "CUAUTLÁNCINGO",
    value: "CUAUTLÁNCINGO - PUEBLA",
  },
  {
    text: "CUAYUCA DE ANDRADE",
    value: "CUAYUCA DE ANDRADE - PUEBLA",
  },
  {
    text: "CUETZALAN DEL PROGRESO",
    value: "CUETZALAN DEL PROGRESO - PUEBLA",
  },
  {
    text: "CUYOACO",
    value: "CUYOACO - PUEBLA",
  },
  {
    text: "DOMINGO ARENAS",
    value: "DOMINGO ARENAS - PUEBLA",
  },
  {
    text: "ELOXOCHITLÁN",
    value: "ELOXOCHITLÁN - PUEBLA",
  },
  {
    text: "EPATLÁN",
    value: "EPATLÁN - PUEBLA",
  },
  {
    text: "ESPERANZA",
    value: "ESPERANZA - PUEBLA",
  },
  {
    text: "FRANCISCO Z MENA",
    value: "FRANCISCO Z MENA - PUEBLA",
  },
  {
    text: "GRAL FELIPE ÁNGELES",
    value: "GRAL FELIPE ÁNGELES - PUEBLA",
  },
  {
    text: "GUADALUPE",
    value: "GUADALUPE - PUEBLA",
  },
  {
    text: "GUADALUPE VICTORIA",
    value: "GUADALUPE VICTORIA - PUEBLA",
  },
  {
    text: "HERMENEGILDO GALEANA",
    value: "HERMENEGILDO GALEANA - PUEBLA",
  },
  {
    text: "HONEY",
    value: "HONEY - PUEBLA",
  },
  {
    text: "HUAQUECHULA",
    value: "HUAQUECHULA - PUEBLA",
  },
  {
    text: "HUATLATLAUCA",
    value: "HUATLATLAUCA - PUEBLA",
  },
  {
    text: "HUAUCHINANGO",
    value: "HUAUCHINANGO - PUEBLA",
  },
  {
    text: "HUEHUETLA",
    value: "HUEHUETLA - PUEBLA",
  },
  {
    text: "HUEHUETLÁN EL CHICO",
    value: "HUEHUETLÁN EL CHICO - PUEBLA",
  },
  {
    text: "HUEHUETLÁN EL GRANDE",
    value: "HUEHUETLÁN EL GRANDE - PUEBLA",
  },
  {
    text: "HUEJOTZINGO",
    value: "HUEJOTZINGO - PUEBLA",
  },
  {
    text: "HUEYAPAN",
    value: "HUEYAPAN - PUEBLA",
  },
  {
    text: "HUEYTAMALCO",
    value: "HUEYTAMALCO - PUEBLA",
  },
  {
    text: "HUEYTLALPAN",
    value: "HUEYTLALPAN - PUEBLA",
  },
  {
    text: "HUITZILAN DE SERDÁN",
    value: "HUITZILAN DE SERDÁN - PUEBLA",
  },
  {
    text: "HUITZILTEPEC",
    value: "HUITZILTEPEC - PUEBLA",
  },
  {
    text: "IXCAMILPA DE GUERRERO",
    value: "IXCAMILPA DE GUERRERO - PUEBLA",
  },
  {
    text: "IXCAQUIXTLA",
    value: "IXCAQUIXTLA - PUEBLA",
  },
  {
    text: "IXTACAMAXTITLÁN",
    value: "IXTACAMAXTITLÁN - PUEBLA",
  },
  {
    text: "IXTEPEC",
    value: "IXTEPEC - PUEBLA",
  },
  {
    text: "IZÚCAR DE MATAMOROS",
    value: "IZÚCAR DE MATAMOROS - PUEBLA",
  },
  {
    text: "JALPAN",
    value: "JALPAN - PUEBLA",
  },
  {
    text: "JOLALPAN",
    value: "JOLALPAN - PUEBLA",
  },
  {
    text: "JONOTLA",
    value: "JONOTLA - PUEBLA",
  },
  {
    text: "JOPALA",
    value: "JOPALA - PUEBLA",
  },
  {
    text: "JUAN C BONILLA",
    value: "JUAN C BONILLA - PUEBLA",
  },
  {
    text: "JUAN GALINDO",
    value: "JUAN GALINDO - PUEBLA",
  },
  {
    text: "JUAN N MÉNDEZ",
    value: "JUAN N MÉNDEZ - PUEBLA",
  },
  {
    text: "LAFRAGUA",
    value: "LAFRAGUA - PUEBLA",
  },
  {
    text: "LIBRES",
    value: "LIBRES - PUEBLA",
  },
  {
    text: "LOS REYES DE JUÁREZ",
    value: "LOS REYES DE JUÁREZ - PUEBLA",
  },
  {
    text: "MAGDALENA TLATLAUQUITEPEC",
    value: "MAGDALENA TLATLAUQUITEPEC - PUEBLA",
  },
  {
    text: "MAZAPILTEPEC DE JUÁREZ",
    value: "MAZAPILTEPEC DE JUÁREZ - PUEBLA",
  },
  {
    text: "MIXTLA",
    value: "MIXTLA - PUEBLA",
  },
  {
    text: "MOLCAXAC",
    value: "MOLCAXAC - PUEBLA",
  },
  {
    text: "NAUPAN",
    value: "NAUPAN - PUEBLA",
  },
  {
    text: "NAUZONTLA",
    value: "NAUZONTLA - PUEBLA",
  },
  {
    text: "NEALTICAN",
    value: "NEALTICAN - PUEBLA",
  },
  {
    text: "NICOLÁS BRAVO",
    value: "NICOLÁS BRAVO - PUEBLA",
  },
  {
    text: "NOPALUCAN",
    value: "NOPALUCAN - PUEBLA",
  },
  {
    text: "OCOTEPEC",
    value: "OCOTEPEC - PUEBLA",
  },
  {
    text: "OCOYUCAN",
    value: "OCOYUCAN - PUEBLA",
  },
  {
    text: "OLINTLA",
    value: "OLINTLA - PUEBLA",
  },
  {
    text: "ORIENTAL",
    value: "ORIENTAL - PUEBLA",
  },
  {
    text: "PAHUATLÁN",
    value: "PAHUATLÁN - PUEBLA",
  },
  {
    text: "PALMAR DE BRAVO",
    value: "PALMAR DE BRAVO - PUEBLA",
  },
  {
    text: "PANTEPEC",
    value: "PANTEPEC - PUEBLA",
  },
  {
    text: "PETLALCINGO",
    value: "PETLALCINGO - PUEBLA",
  },
  {
    text: "PIAXTLA",
    value: "PIAXTLA - PUEBLA",
  },
  {
    text: "PUEBLA",
    value: "PUEBLA - PUEBLA",
  },
  {
    text: "QUECHOLAC",
    value: "QUECHOLAC - PUEBLA",
  },
  {
    text: "QUIMIXTLÁN",
    value: "QUIMIXTLÁN - PUEBLA",
  },
  {
    text: "RAFAEL LARA GRAJALES",
    value: "RAFAEL LARA GRAJALES - PUEBLA",
  },
  {
    text: "SAN MARTÍN TEXMELÚCAN",
    value: "SAN MARTÍN TEXMELÚCAN - PUEBLA",
  },
  {
    text: "SANTA CATARINA TLALTEMPAN",
    value: "SANTA CATARINA TLALTEMPAN - PUEBLA",
  },
  {
    text: "SANTA INÉS AHUATEMPAN",
    value: "SANTA INÉS AHUATEMPAN - PUEBLA",
  },
  {
    text: "SANTA ISABEL CHOLULA",
    value: "SANTA ISABEL CHOLULA - PUEBLA",
  },
  {
    text: "SANTIAGO MIAHUATLÁN",
    value: "SANTIAGO MIAHUATLÁN - PUEBLA",
  },
  {
    text: "SANTO TOMÁS HUEYOTLIPAN",
    value: "SANTO TOMÁS HUEYOTLIPAN - PUEBLA",
  },
  {
    text: "SAN ANDRÉS CHOLULA",
    value: "SAN ANDRÉS CHOLULA - PUEBLA",
  },
  {
    text: "SAN ANTONIO CAÑADA",
    value: "SAN ANTONIO CAÑADA - PUEBLA",
  },
  {
    text: "SAN DIEGO LA MESA TOCHIMILTZINGO",
    value: "SAN DIEGO LA MESA TOCHIMILTZINGO - PUEBLA",
  },
  {
    text: "SAN FELIPE TEOTLALCINGO",
    value: "SAN FELIPE TEOTLALCINGO - PUEBLA",
  },
  {
    text: "SAN FELIPE TEPATLÁN",
    value: "SAN FELIPE TEPATLÁN - PUEBLA",
  },
  {
    text: "SAN GABRIEL CHILAC",
    value: "SAN GABRIEL CHILAC - PUEBLA",
  },
  {
    text: "SAN GREGORÍO ATZOMPA",
    value: "SAN GREGORÍO ATZOMPA - PUEBLA",
  },
  {
    text: "SAN JERÓNIMO TECUANIPAN",
    value: "SAN JERÓNIMO TECUANIPAN - PUEBLA",
  },
  {
    text: "SAN JERÓNIMO XAYACATLÁN",
    value: "SAN JERÓNIMO XAYACATLÁN - PUEBLA",
  },
  {
    text: "SAN JOSÉ CHIAPA",
    value: "SAN JOSÉ CHIAPA - PUEBLA",
  },
  {
    text: "SAN JOSÉ MIAHUATLÁN",
    value: "SAN JOSÉ MIAHUATLÁN - PUEBLA",
  },
  {
    text: "SAN JUAN ATENCO",
    value: "SAN JUAN ATENCO - PUEBLA",
  },
  {
    text: "SAN JUAN ATZOMPA",
    value: "SAN JUAN ATZOMPA - PUEBLA",
  },
  {
    text: "SAN MARTÍN TEXMELÚCAN",
    value: "SAN MARTÍN TEXMELÚCAN - PUEBLA",
  },
  {
    text: "SAN MARTÍN TOTOLTEPEC",
    value: "SAN MARTÍN TOTOLTEPEC - PUEBLA",
  },
  {
    text: "SAN MATIAS TLALANCALECA",
    value: "SAN MATIAS TLALANCALECA - PUEBLA",
  },
  {
    text: "SAN MIGUEL IXITLÁN",
    value: "SAN MIGUEL IXITLÁN - PUEBLA",
  },
  {
    text: "SAN MIGUEL XOXTLA",
    value: "SAN MIGUEL XOXTLA - PUEBLA",
  },
  {
    text: "SAN NICOLÁS BUENOS AIRES",
    value: "SAN NICOLÁS BUENOS AIRES - PUEBLA",
  },
  {
    text: "SAN NICOLÁS DE LOS RANCHOS",
    value: "SAN NICOLÁS DE LOS RANCHOS - PUEBLA",
  },
  {
    text: "SAN PABLO ANICANO",
    value: "SAN PABLO ANICANO - PUEBLA",
  },
  {
    text: "SAN PEDRO CHOLULA",
    value: "SAN PEDRO CHOLULA - PUEBLA",
  },
  {
    text: "SAN PEDRO YELOIXTLÁHUACA",
    value: "SAN PEDRO YELOIXTLÁHUACA - PUEBLA",
  },
  {
    text: "SAN SALVADOR EL SECO",
    value: "SAN SALVADOR EL SECO - PUEBLA",
  },
  {
    text: "SAN SALVADOR EL VERDE",
    value: "SAN SALVADOR EL VERDE - PUEBLA",
  },
  {
    text: "SAN SALVADOR HUIXCOLOTLA",
    value: "SAN SALVADOR HUIXCOLOTLA - PUEBLA",
  },
  {
    text: "SAN SEBASTIÁN TLACOTEPEC",
    value: "SAN SEBASTIÁN TLACOTEPEC - PUEBLA",
  },
  {
    text: "SOLTEPEC",
    value: "SOLTEPEC - PUEBLA",
  },
  {
    text: "TECALI DE HERRERA",
    value: "TECALI DE HERRERA - PUEBLA",
  },
  {
    text: "TECÁMACHALCO",
    value: "TECÁMACHALCO - PUEBLA",
  },
  {
    text: "TECOMATLÁN",
    value: "TECOMATLÁN - PUEBLA",
  },
  {
    text: "TEHUACAN",
    value: "TEHUACAN - PUEBLA",
  },
  {
    text: "TEHUITZINGO",
    value: "TEHUITZINGO - PUEBLA",
  },
  {
    text: "TENAMPULCO",
    value: "TENAMPULCO - PUEBLA",
  },
  {
    text: "TEOPANTLAN",
    value: "TEOPANTLAN - PUEBLA",
  },
  {
    text: "TEOTLALCO",
    value: "TEOTLALCO - PUEBLA",
  },
  {
    text: "TEPANCO DE LÓPEZ",
    value: "TEPANCO DE LÓPEZ - PUEBLA",
  },
  {
    text: "TEPANGO DE RODRÍGUEZ",
    value: "TEPANGO DE RODRÍGUEZ - PUEBLA",
  },
  {
    text: "TEPATLAXCO DE HIDALGO",
    value: "TEPATLAXCO DE HIDALGO - PUEBLA",
  },
  {
    text: "TEPEACA",
    value: "TEPEACA - PUEBLA",
  },
  {
    text: "TEPEMAXALCO",
    value: "TEPEMAXALCO - PUEBLA",
  },
  {
    text: "TEPEOJUMA",
    value: "TEPEOJUMA - PUEBLA",
  },
  {
    text: "TEPETZINTLA",
    value: "TEPETZINTLA - PUEBLA",
  },
  {
    text: "TEPEXCO",
    value: "TEPEXCO - PUEBLA",
  },
  {
    text: "TEPEXI DE RODRÍGUEZ",
    value: "TEPEXI DE RODRÍGUEZ - PUEBLA",
  },
  {
    text: "TEPEYAHUALCO DE CUAUHTÉMOC",
    value: "TEPEYAHUALCO DE CUAUHTÉMOC - PUEBLA",
  },
  {
    text: "TEPEYAHUALCO",
    value: "TEPEYAHUALCO - PUEBLA",
  },
  {
    text: "TETELA DE OCAMPO",
    value: "TETELA DE OCAMPO - PUEBLA",
  },
  {
    text: "TETELES DE AVILA CASTILLO",
    value: "TETELES DE AVILA CASTILLO - PUEBLA",
  },
  {
    text: "TEZIUTLÁN",
    value: "TEZIUTLÁN - PUEBLA",
  },
  {
    text: "TIANGUISMANALCO",
    value: "TIANGUISMANALCO - PUEBLA",
  },
  {
    text: "TILAPA",
    value: "TILAPA - PUEBLA",
  },
  {
    text: "TLACHICHUCA",
    value: "TLACHICHUCA - PUEBLA",
  },
  {
    text: "TLACOTEPEC BENITO JUÁREZ",
    value: "TLACOTEPEC BENITO JUÁREZ - PUEBLA",
  },
  {
    text: "TLACUILOTEPEC",
    value: "TLACUILOTEPEC - PUEBLA",
  },
  {
    text: "TLAHUAPAN",
    value: "TLAHUAPAN - PUEBLA",
  },
  {
    text: "TLALTENANGO",
    value: "TLALTENANGO - PUEBLA",
  },
  {
    text: "TLANEPANTLA",
    value: "TLANEPANTLA - PUEBLA",
  },
  {
    text: "TLAOLA",
    value: "TLAOLA - PUEBLA",
  },
  {
    text: "TLAPACOYA",
    value: "TLAPACOYA - PUEBLA",
  },
  {
    text: "TLAPANALÁ",
    value: "TLAPANALÁ - PUEBLA",
  },
  {
    text: "TLATLAUQUITEPEC",
    value: "TLATLAUQUITEPEC - PUEBLA",
  },
  {
    text: "TLAXCO",
    value: "TLAXCO - PUEBLA",
  },
  {
    text: "TOCHIMILCO",
    value: "TOCHIMILCO - PUEBLA",
  },
  {
    text: "TOCHTEPEC",
    value: "TOCHTEPEC - PUEBLA",
  },
  {
    text: "TOTOLTEPEC DE GUERRERO",
    value: "TOTOLTEPEC DE GUERRERO - PUEBLA",
  },
  {
    text: "TULCINGO",
    value: "TULCINGO - PUEBLA",
  },
  {
    text: "TUZAMAPAN DE GALEANA",
    value: "TUZAMAPAN DE GALEANA - PUEBLA",
  },
  {
    text: "TZICATLACOYAN",
    value: "TZICATLACOYAN - PUEBLA",
  },
  {
    text: "QUERÉTARO",
    value: "QUERÉTARO -",
  },
  {
    text: "AMEALCO DE BONFIL",
    value: "AMEALCO DE BONFIL - QUERÉTARO",
  },
  {
    text: "ARROYO SECO",
    value: "ARROYO SECO - QUERÉTARO",
  },
  {
    text: "CADEREYTA DE MONTES",
    value: "CADEREYTA DE MONTES - QUERÉTARO",
  },
  {
    text: "COLÓN",
    value: "COLÓN - QUERÉTARO",
  },
  {
    text: "CORREGIDORA",
    value: "CORREGIDORA - QUERÉTARO",
  },
  {
    text: "EL MARQUÉS",
    value: "EL MARQUÉS - QUERÉTARO",
  },
  {
    text: "EL PUEBLITO",
    value: "EL PUEBLITO - QUERÉTARO",
  },
  {
    text: "EZEQUIEL MONTES",
    value: "EZEQUIEL MONTES - QUERÉTARO",
  },
  {
    text: "HUIMILPAN",
    value: "HUIMILPAN - QUERÉTARO",
  },
  {
    text: "JALPAN DE SERRA",
    value: "JALPAN DE SERRA - QUERÉTARO",
  },
  {
    text: "LANDA DE MATAMOROS",
    value: "LANDA DE MATAMOROS - QUERÉTARO",
  },
  {
    text: "PEDRO ESCOBEDO",
    value: "PEDRO ESCOBEDO - QUERÉTARO",
  },
  {
    text: "PENAMILLER",
    value: "PENAMILLER - QUERÉTARO",
  },
  {
    text: "PINAL DE AMOLES",
    value: "PINAL DE AMOLES - QUERÉTARO",
  },
  {
    text: "SANTIAGO DE QUERÉTARO",
    value: "SANTIAGO DE QUERÉTARO - QUERÉTARO",
  },
  {
    text: "SAN JOAQUÍN",
    value: "SAN JOAQUÍN - QUERÉTARO",
  },
  {
    text: "SAN JUAN DEL RÍO",
    value: "SAN JUAN DEL RÍO - QUERÉTARO",
  },
  {
    text: "TEQUISQUIAPAN",
    value: "TEQUISQUIAPAN - QUERÉTARO",
  },
  {
    text: "TOLIMÁN",
    value: "TOLIMÁN - QUERÉTARO",
  },
  {
    text: "BACALAR",
    value: "BACALAR - QUINTANA ROO",
  },
  {
    text: "BENITO JUÁREZ",
    value: "BENITO JUÁREZ - QUINTANA ROO",
  },
  {
    text: "CANCUN",
    value: "CANCUN - QUINTANA ROO",
  },
  {
    text: "COZUMEL",
    value: "COZUMEL - QUINTANA ROO",
  },
  {
    text: "FELIPE CARRILLO PTO",
    value: "FELIPE CARRILLO PTO - QUINTANA ROO",
  },
  {
    text: "ISLA MUJERES",
    value: "ISLA MUJERES - QUINTANA ROO",
  },
  {
    text: "JOSÉ MARÍA MORELOS",
    value: "JOSÉ MARÍA MORELOS - QUINTANA ROO",
  },
  {
    text: "LÁZARO CÁRDENAS",
    value: "LÁZARO CÁRDENAS - QUINTANA ROO",
  },
  {
    text: "OTHÓN P BLANCO",
    value: "OTHÓN P BLANCO - QUINTANA ROO",
  },
  {
    text: "SOLIDARIDAD",
    value: "SOLIDARIDAD - QUINTANA ROO",
  },
  {
    text: "SOLIDARIDAD",
    value: "SOLIDARIDAD - QUINTANA ROO",
  },
  {
    text: "TULUM",
    value: "TULUM - QUINTANA ROO",
  },
  {
    text: "VENADO",
    value: "VENADO - SAN LUIS POTOSÍ",
  },
  {
    text: "VILLA DE ARISTA",
    value: "VILLA DE ARISTA - SAN LUIS POTOSÍ",
  },
  {
    text: "VILLA DE ARRIAGA",
    value: "VILLA DE ARRIAGA - SAN LUIS POTOSÍ",
  },
  {
    text: "VILLA DE GUADALUPE",
    value: "VILLA DE GUADALUPE - SAN LUIS POTOSÍ",
  },
  {
    text: "VILLA DE LA PAZ",
    value: "VILLA DE LA PAZ - SAN LUIS POTOSÍ",
  },
  {
    text: "VILLA DE RAMOS",
    value: "VILLA DE RAMOS - SAN LUIS POTOSÍ",
  },
  {
    text: "VILLA DE REYES",
    value: "VILLA DE REYES - SAN LUIS POTOSÍ",
  },
  {
    text: "VILLA HIDALGO",
    value: "VILLA HIDALGO - SAN LUIS POTOSÍ",
  },
  {
    text: "VILLA JUÁREZ",
    value: "VILLA JUÁREZ - SAN LUIS POTOSÍ",
  },
  {
    text: "XILITLA",
    value: "XILITLA - SAN LUIS POTOSÍ",
  },
  {
    text: "ZARAGOZA",
    value: "ZARAGOZA - SAN LUIS POTOSÍ",
  },
  {
    text: "AHUALULCO",
    value: "AHUALULCO - SAN LUIS POTOSÍ",
  },
  {
    text: "ALAQUINÉS",
    value: "ALAQUINÉS - SAN LUIS POTOSÍ",
  },
  {
    text: "AQUISMÓN",
    value: "AQUISMÓN - SAN LUIS POTOSÍ",
  },
  {
    text: "ARMADILLO DE LOS INFANTE",
    value: "ARMADILLO DE LOS INFANTE - SAN LUIS POTOSÍ",
  },
  {
    text: "AXTLA DE TERRAZAS",
    value: "AXTLA DE TERRAZAS - SAN LUIS POTOSÍ",
  },
  {
    text: "CÁRDENAS",
    value: "CÁRDENAS - SAN LUIS POTOSÍ",
  },
  {
    text: "CATORCE",
    value: "CATORCE - SAN LUIS POTOSÍ",
  },
  {
    text: "CD DEL MAÍZ",
    value: "CD DEL MAÍZ - SAN LUIS POTOSÍ",
  },
  {
    text: "CD FERNÁNDEZ",
    value: "CD FERNÁNDEZ - SAN LUIS POTOSÍ",
  },
  {
    text: "CD VALLES",
    value: "CD VALLES - SAN LUIS POTOSÍ",
  },
  {
    text: "CEDRAL",
    value: "CEDRAL - SAN LUIS POTOSÍ",
  },
  {
    text: "CERRITOS",
    value: "CERRITOS - SAN LUIS POTOSÍ",
  },
  {
    text: "CERRO DE SAN PEDRO",
    value: "CERRO DE SAN PEDRO - SAN LUIS POTOSÍ",
  },
  {
    text: "CHARCAS",
    value: "CHARCAS - SAN LUIS POTOSÍ",
  },
  {
    text: "COXCATLÁN",
    value: "COXCATLÁN - SAN LUIS POTOSÍ",
  },
  {
    text: "EBANO",
    value: "EBANO - SAN LUIS POTOSÍ",
  },
  {
    text: "EL NARANJO",
    value: "EL NARANJO - SAN LUIS POTOSÍ",
  },
  {
    text: "GUADALCÁZAR",
    value: "GUADALCÁZAR - SAN LUIS POTOSÍ",
  },
  {
    text: "HUEHUETLÁN",
    value: "HUEHUETLÁN - SAN LUIS POTOSÍ",
  },
  {
    text: "LAGUNILLAS",
    value: "LAGUNILLAS - SAN LUIS POTOSÍ",
  },
  {
    text: "MATEHUALA",
    value: "MATEHUALA - SAN LUIS POTOSÍ",
  },
  {
    text: "MATLAPA",
    value: "MATLAPA - SAN LUIS POTOSÍ",
  },
  {
    text: "MEXQUITIC DE CARMONA",
    value: "MEXQUITIC DE CARMONA - SAN LUIS POTOSÍ",
  },
  {
    text: "MOCTEZUMA",
    value: "MOCTEZUMA - SAN LUIS POTOSÍ",
  },
  {
    text: "RAYÓN",
    value: "RAYÓN - SAN LUIS POTOSÍ",
  },
  {
    text: "RÍOVERDE",
    value: "RÍOVERDE - SAN LUIS POTOSÍ",
  },
  {
    text: "SALINAS",
    value: "SALINAS - SAN LUIS POTOSÍ",
  },
  {
    text: "SANTA CATARINA",
    value: "SANTA CATARINA - SAN LUIS POTOSÍ",
  },
  {
    text: "SANTA MARÍA DEL RÍO",
    value: "SANTA MARÍA DEL RÍO - SAN LUIS POTOSÍ",
  },
  {
    text: "SANTO DOMINGO",
    value: "SANTO DOMINGO - SAN LUIS POTOSÍ",
  },
  {
    text: "SAN ANTONIO",
    value: "SAN ANTONIO - SAN LUIS POTOSÍ",
  },
  {
    text: "SAN CIRO DE ACOSTA",
    value: "SAN CIRO DE ACOSTA - SAN LUIS POTOSÍ",
  },
  {
    text: "SAN LUIS POTOSI",
    value: "SAN LUIS POTOSI - SAN LUIS POTOSÍ",
  },
  {
    text: "SAN MARTÍN CHALCHICUAUTLA",
    value: "SAN MARTÍN CHALCHICUAUTLA - SAN LUIS POTOSÍ",
  },
  {
    text: "SAN NICOLÁS TOLENTINO",
    value: "SAN NICOLÁS TOLENTINO - SAN LUIS POTOSÍ",
  },
  {
    text: "SAN VICENTE TANCUAYALAB",
    value: "SAN VICENTE TANCUAYALAB - SAN LUIS POTOSÍ",
  },
  {
    text: "SOLEDAD DE GRACIANO SCHEZ",
    value: "SOLEDAD DE GRACIANO SCHEZ - SAN LUIS POTOSÍ",
  },
  {
    text: "TAMASOPO",
    value: "TAMASOPO - SAN LUIS POTOSÍ",
  },
  {
    text: "TAMAZUNCHALE",
    value: "TAMAZUNCHALE - SAN LUIS POTOSÍ",
  },
  {
    text: "TAMPACÁN",
    value: "TAMPACÁN - SAN LUIS POTOSÍ",
  },
  {
    text: "TAMPAMOLÓN CORONA",
    value: "TAMPAMOLÓN CORONA - SAN LUIS POTOSÍ",
  },
  {
    text: "TAMUÍN",
    value: "TAMUÍN - SAN LUIS POTOSÍ",
  },
  {
    text: "TANCANHUITZ",
    value: "TANCANHUITZ - SAN LUIS POTOSÍ",
  },
  {
    text: "TANLAJÁS",
    value: "TANLAJÁS - SAN LUIS POTOSÍ",
  },
  {
    text: "TANQUIÁN DE ESCOBEDO",
    value: "TANQUIÁN DE ESCOBEDO - SAN LUIS POTOSÍ",
  },
  {
    text: "TIERRA NUEVA",
    value: "TIERRA NUEVA - SAN LUIS POTOSÍ",
  },
  {
    text: "VANEGAS",
    value: "VANEGAS - SAN LUIS POTOSÍ",
  },
  {
    text: "VILLA UNIÓN",
    value: "VILLA UNIÓN - SINALOA",
  },
  {
    text: "AGUARUTO",
    value: "AGUARUTO - SINALOA",
  },
  {
    text: "AHOME",
    value: "AHOME - SINALOA",
  },
  {
    text: "ANGOSTURA",
    value: "ANGOSTURA - SINALOA",
  },
  {
    text: "BADIRAGUATO",
    value: "BADIRAGUATO - SINALOA",
  },
  {
    text: "CHOIX",
    value: "CHOIX - SINALOA",
  },
  {
    text: "CONCORDIA",
    value: "CONCORDIA - SINALOA",
  },
  {
    text: "COSALÁ",
    value: "COSALÁ - SINALOA",
  },
  {
    text: "CULIACÁN",
    value: "CULIACÁN - SINALOA",
  },
  {
    text: "EL FUERTE",
    value: "EL FUERTE - SINALOA",
  },
  {
    text: "EL ROSARÍO",
    value: "EL ROSARÍO - SINALOA",
  },
  {
    text: "ELOTA",
    value: "ELOTA - SINALOA",
  },
  {
    text: "ESCUINAPA",
    value: "ESCUINAPA - SINALOA",
  },
  {
    text: "GUAMUCHIL",
    value: "GUAMUCHIL - SINALOA",
  },
  {
    text: "GUASAVE",
    value: "GUASAVE - SINALOA",
  },
  {
    text: "LOS MOCHIS",
    value: "LOS MOCHIS - SINALOA",
  },
  {
    text: "MAZATLÁN",
    value: "MAZATLÁN - SINALOA",
  },
  {
    text: "MOCORITO",
    value: "MOCORITO - SINALOA",
  },
  {
    text: "NAVOLATO",
    value: "NAVOLATO - SINALOA",
  },
  {
    text: "ROSARÍO",
    value: "ROSARÍO - SINALOA",
  },
  {
    text: "SALVADOR ALVARADO",
    value: "SALVADOR ALVARADO - SINALOA",
  },
  {
    text: "SINALOA DE LEYVA",
    value: "SINALOA DE LEYVA - SINALOA",
  },
  {
    text: "SINALOA",
    value: "SINALOA - SINALOA",
  },
  {
    text: "SAN IGNACIO",
    value: "SAN IGNACIO - SINALOA",
  },
  {
    text: "VILLA HIDALGO",
    value: "VILLA HIDALGO - SONORA",
  },
  {
    text: "VILLA PESQUEIRA",
    value: "VILLA PESQUEIRA - SONORA",
  },
  {
    text: "YÉCORA",
    value: "YÉCORA - SONORA",
  },
  {
    text: "ACONCHI",
    value: "ACONCHI - SONORA",
  },
  {
    text: "AGUA PRIETA",
    value: "AGUA PRIETA - SONORA",
  },
  {
    text: "ÁLAMOS",
    value: "ÁLAMOS - SONORA",
  },
  {
    text: "ALTAR",
    value: "ALTAR - SONORA",
  },
  {
    text: "ARIVECHI",
    value: "ARIVECHI - SONORA",
  },
  {
    text: "ARIZPE",
    value: "ARIZPE - SONORA",
  },
  {
    text: "ATIL",
    value: "ATIL - SONORA",
  },
  {
    text: "BACADÉHUACHI",
    value: "BACADÉHUACHI - SONORA",
  },
  {
    text: "BACANORA",
    value: "BACANORA - SONORA",
  },
  {
    text: "BACERAC",
    value: "BACERAC - SONORA",
  },
  {
    text: "BACOACHI",
    value: "BACOACHI - SONORA",
  },
  {
    text: "BÁCUM",
    value: "BÁCUM - SONORA",
  },
  {
    text: "BANÁMICHI",
    value: "BANÁMICHI - SONORA",
  },
  {
    text: "BAVIÁCORA",
    value: "BAVIÁCORA - SONORA",
  },
  {
    text: "BAVISPE",
    value: "BAVISPE - SONORA",
  },
  {
    text: "BENITO JUÁREZ",
    value: "BENITO JUÁREZ - SONORA",
  },
  {
    text: "BENJAMÍN HILL",
    value: "BENJAMÍN HILL - SONORA",
  },
  {
    text: "CABORCA",
    value: "CABORCA - SONORA",
  },
  {
    text: "CAJEME",
    value: "CAJEME - SONORA",
  },
  {
    text: "CANANEA",
    value: "CANANEA - SONORA",
  },
  {
    text: "CARBO",
    value: "CARBO - SONORA",
  },
  {
    text: "CD OBREGÓN",
    value: "CD OBREGÓN - SONORA",
  },
  {
    text: "CUCURPE",
    value: "CUCURPE - SONORA",
  },
  {
    text: "CUMPAS",
    value: "CUMPAS - SONORA",
  },
  {
    text: "DIVISADEROS",
    value: "DIVISADEROS - SONORA",
  },
  {
    text: "EMPALME",
    value: "EMPALME - SONORA",
  },
  {
    text: "ETCHOJOA",
    value: "ETCHOJOA - SONORA",
  },
  {
    text: "FRONTERAS",
    value: "FRONTERAS - SONORA",
  },
  {
    text: "GRAL PLUTARCO E CALLES",
    value: "GRAL PLUTARCO E CALLES - SONORA",
  },
  {
    text: "GRANADOS",
    value: "GRANADOS - SONORA",
  },
  {
    text: "GUAYMAS",
    value: "GUAYMAS - SONORA",
  },
  {
    text: "HERMOSILLO",
    value: "HERMOSILLO - SONORA",
  },
  {
    text: "HUACHINERA",
    value: "HUACHINERA - SONORA",
  },
  {
    text: "HUÁSABAS",
    value: "HUÁSABAS - SONORA",
  },
  {
    text: "HUATABAMPO",
    value: "HUATABAMPO - SONORA",
  },
  {
    text: "HUÉPAC",
    value: "HUÉPAC - SONORA",
  },
  {
    text: "IMURIS",
    value: "IMURIS - SONORA",
  },
  {
    text: "LA COLORADA",
    value: "LA COLORADA - SONORA",
  },
  {
    text: "MAGDALENA",
    value: "MAGDALENA - SONORA",
  },
  {
    text: "MAZATÁN",
    value: "MAZATÁN - SONORA",
  },
  {
    text: "MOCTEZUMA",
    value: "MOCTEZUMA - SONORA",
  },
  {
    text: "NACO",
    value: "NACO - SONORA",
  },
  {
    text: "NÁCORI CHICO",
    value: "NÁCORI CHICO - SONORA",
  },
  {
    text: "NACOZARI DE GARCÍA",
    value: "NACOZARI DE GARCÍA - SONORA",
  },
  {
    text: "NAVOJOA",
    value: "NAVOJOA - SONORA",
  },
  {
    text: "NOGALES",
    value: "NOGALES - SONORA",
  },
  {
    text: "ONAVAS",
    value: "ONAVAS - SONORA",
  },
  {
    text: "OPODEPE",
    value: "OPODEPE - SONORA",
  },
  {
    text: "OQUITOA",
    value: "OQUITOA - SONORA",
  },
  {
    text: "PITIQUITO",
    value: "PITIQUITO - SONORA",
  },
  {
    text: "PLUTARCO ELÍAS CALLES",
    value: "PLUTARCO ELÍAS CALLES - SONORA",
  },
  {
    text: "PTO PENASCO",
    value: "PTO PENASCO - SONORA",
  },
  {
    text: "QUIRIEGO",
    value: "QUIRIEGO - SONORA",
  },
  {
    text: "RAYÓN",
    value: "RAYÓN - SONORA",
  },
  {
    text: "ROSARÍO",
    value: "ROSARÍO - SONORA",
  },
  {
    text: "SAHUARIPA",
    value: "SAHUARIPA - SONORA",
  },
  {
    text: "SANTA ANA",
    value: "SANTA ANA - SONORA",
  },
  {
    text: "SANTA CRUZ",
    value: "SANTA CRUZ - SONORA",
  },
  {
    text: "SÁRIC",
    value: "SÁRIC - SONORA",
  },
  {
    text: "SAN FELIPE DE JESUS",
    value: "SAN FELIPE DE JESUS - SONORA",
  },
  {
    text: "SAN IGNACIO RÍO MUERTO",
    value: "SAN IGNACIO RÍO MUERTO - SONORA",
  },
  {
    text: "SAN JAVIER",
    value: "SAN JAVIER - SONORA",
  },
  {
    text: "SAN LUIS RÍO COLORADO",
    value: "SAN LUIS RÍO COLORADO - SONORA",
  },
  {
    text: "SAN MIGUEL DE HORCASITAS",
    value: "SAN MIGUEL DE HORCASITAS - SONORA",
  },
  {
    text: "SAN PEDRO DE LA CUEVA",
    value: "SAN PEDRO DE LA CUEVA - SONORA",
  },
  {
    text: "SOYOPA",
    value: "SOYOPA - SONORA",
  },
  {
    text: "SUAQUI GRANDE",
    value: "SUAQUI GRANDE - SONORA",
  },
  {
    text: "TEPACHE",
    value: "TEPACHE - SONORA",
  },
  {
    text: "TRINCHERAS",
    value: "TRINCHERAS - SONORA",
  },
  {
    text: "TUBUTAMA",
    value: "TUBUTAMA - SONORA",
  },
  {
    text: "URES",
    value: "URES - SONORA",
  },
  {
    text: "VILLAHERMOSA",
    value: "VILLAHERMOSA - TABASCO",
  },
  {
    text: "BALANCÁN",
    value: "BALANCÁN - TABASCO",
  },
  {
    text: "CÁRDENAS",
    value: "CÁRDENAS - TABASCO",
  },
  {
    text: "CENTLA",
    value: "CENTLA - TABASCO",
  },
  {
    text: "CENTRO",
    value: "CENTRO - TABASCO",
  },
  {
    text: "COMALCALCO",
    value: "COMALCALCO - TABASCO",
  },
  {
    text: "CUNDUACÁN",
    value: "CUNDUACÁN - TABASCO",
  },
  {
    text: "EMILIANO ZAPATA",
    value: "EMILIANO ZAPATA - TABASCO",
  },
  {
    text: "HUIMANGUILLO",
    value: "HUIMANGUILLO - TABASCO",
  },
  {
    text: "JALAPA",
    value: "JALAPA - TABASCO",
  },
  {
    text: "JALPA DE MÉNDEZ",
    value: "JALPA DE MÉNDEZ - TABASCO",
  },
  {
    text: "JONUTA",
    value: "JONUTA - TABASCO",
  },
  {
    text: "MACUSPANA",
    value: "MACUSPANA - TABASCO",
  },
  {
    text: "NACAJUCA",
    value: "NACAJUCA - TABASCO",
  },
  {
    text: "PARAÍSO",
    value: "PARAÍSO - TABASCO",
  },
  {
    text: "TACOTALPA",
    value: "TACOTALPA - TABASCO",
  },
  {
    text: "TEAPA",
    value: "TEAPA - TABASCO",
  },
  {
    text: "TENOSIQUE",
    value: "TENOSIQUE - TABASCO",
  },
  {
    text: "VALLE HERMOSO",
    value: "VALLE HERMOSO - TAMAULIPAS",
  },
  {
    text: "VILLAGRAN",
    value: "VILLAGRAN - TAMAULIPAS",
  },
  {
    text: "XICOTÉNCATL",
    value: "XICOTÉNCATL - TAMAULIPAS",
  },
  {
    text: "ABASOLO",
    value: "ABASOLO - TAMAULIPAS",
  },
  {
    text: "ALDAMA",
    value: "ALDAMA - TAMAULIPAS",
  },
  {
    text: "ALTAMIRA",
    value: "ALTAMIRA - TAMAULIPAS",
  },
  {
    text: "ANTIGUO MORELOS",
    value: "ANTIGUO MORELOS - TAMAULIPAS",
  },
  {
    text: "BURGOS",
    value: "BURGOS - TAMAULIPAS",
  },
  {
    text: "BUSTAMANTE",
    value: "BUSTAMANTE - TAMAULIPAS",
  },
  {
    text: "CASAS",
    value: "CASAS - TAMAULIPAS",
  },
  {
    text: "CD CAMARGO",
    value: "CD CAMARGO - TAMAULIPAS",
  },
  {
    text: "CD MADERO",
    value: "CD MADERO - TAMAULIPAS",
  },
  {
    text: "CD MANTE",
    value: "CD MANTE - TAMAULIPAS",
  },
  {
    text: "CD VICTORIA",
    value: "CD VICTORIA - TAMAULIPAS",
  },
  {
    text: "CRUILLAS",
    value: "CRUILLAS - TAMAULIPAS",
  },
  {
    text: "EL MANTE",
    value: "EL MANTE - TAMAULIPAS",
  },
  {
    text: "GÓMEZ FARÍAS",
    value: "GÓMEZ FARÍAS - TAMAULIPAS",
  },
  {
    text: "GONZÁLEZ",
    value: "GONZÁLEZ - TAMAULIPAS",
  },
  {
    text: "GUÉMEZ",
    value: "GUÉMEZ - TAMAULIPAS",
  },
  {
    text: "GUERRERO",
    value: "GUERRERO - TAMAULIPAS",
  },
  {
    text: "GUSTAVO DÍAZ ORDAZ",
    value: "GUSTAVO DÍAZ ORDAZ - TAMAULIPAS",
  },
  {
    text: "HIDALGO",
    value: "HIDALGO - TAMAULIPAS",
  },
  {
    text: "JAUMAVE",
    value: "JAUMAVE - TAMAULIPAS",
  },
  {
    text: "JIMÉNEZ",
    value: "JIMÉNEZ - TAMAULIPAS",
  },
  {
    text: "LLERA",
    value: "LLERA - TAMAULIPAS",
  },
  {
    text: "MAINERO",
    value: "MAINERO - TAMAULIPAS",
  },
  {
    text: "MATAMOROS",
    value: "MATAMOROS - TAMAULIPAS",
  },
  {
    text: "MÉNDEZ",
    value: "MÉNDEZ - TAMAULIPAS",
  },
  {
    text: "MIER",
    value: "MIER - TAMAULIPAS",
  },
  {
    text: "MIGUEL ALEMÁN",
    value: "MIGUEL ALEMÁN - TAMAULIPAS",
  },
  {
    text: "MIQUIHUANA",
    value: "MIQUIHUANA - TAMAULIPAS",
  },
  {
    text: "NUEVO LAREDO",
    value: "NUEVO LAREDO - TAMAULIPAS",
  },
  {
    text: "NUEVO MORELOS",
    value: "NUEVO MORELOS - TAMAULIPAS",
  },
  {
    text: "OCAMPO",
    value: "OCAMPO - TAMAULIPAS",
  },
  {
    text: "PADILLA",
    value: "PADILLA - TAMAULIPAS",
  },
  {
    text: "PALMILLAS",
    value: "PALMILLAS - TAMAULIPAS",
  },
  {
    text: "REYNOSA",
    value: "REYNOSA - TAMAULIPAS",
  },
  {
    text: "RÍO BRAVO",
    value: "RÍO BRAVO - TAMAULIPAS",
  },
  {
    text: "SAN CARLOS",
    value: "SAN CARLOS - TAMAULIPAS",
  },
  {
    text: "SAN FERNANDO",
    value: "SAN FERNANDO - TAMAULIPAS",
  },
  {
    text: "SAN NICOLÁS",
    value: "SAN NICOLÁS - TAMAULIPAS",
  },
  {
    text: "SOTO LA MARÍNA",
    value: "SOTO LA MARÍNA - TAMAULIPAS",
  },
  {
    text: "TAMPICO",
    value: "TAMPICO - TAMAULIPAS",
  },
  {
    text: "TULA",
    value: "TULA - TAMAULIPAS",
  },
  {
    text: "XALOZTOC",
    value: "XALOZTOC - TLAXCALA",
  },
  {
    text: "XALTOCAN",
    value: "XALTOCAN - TLAXCALA",
  },
  {
    text: "XICOHTZINCO",
    value: "XICOHTZINCO - TLAXCALA",
  },
  {
    text: "YAUHQUEMECAN",
    value: "YAUHQUEMECAN - TLAXCALA",
  },
  {
    text: "ZACATELCO",
    value: "ZACATELCO - TLAXCALA",
  },
  {
    text: "ZITLALTEPEC DE TRINIDAD S",
    value: "ZITLALTEPEC DE TRINIDAD S - TLAXCALA",
  },
  {
    text: "ACUAMANALA DE MIGUEL HGO",
    value: "ACUAMANALA DE MIGUEL HGO - TLAXCALA",
  },
  {
    text: "ALTZAYANCA",
    value: "ALTZAYANCA - TLAXCALA",
  },
  {
    text: "AMAXAC DE GUERRERO",
    value: "AMAXAC DE GUERRERO - TLAXCALA",
  },
  {
    text: "APETATITLÁN ANT CARVAJAL",
    value: "APETATITLÁN ANT CARVAJAL - TLAXCALA",
  },
  {
    text: "APIZACO",
    value: "APIZACO - TLAXCALA",
  },
  {
    text: "ATLANGATEPEC",
    value: "ATLANGATEPEC - TLAXCALA",
  },
  {
    text: "BENITO JUÁREZ",
    value: "BENITO JUÁREZ - TLAXCALA",
  },
  {
    text: "CALPULALPAN",
    value: "CALPULALPAN - TLAXCALA",
  },
  {
    text: "CHIAUTEMPAN",
    value: "CHIAUTEMPAN - TLAXCALA",
  },
  {
    text: "CONTLA DE JUAN CUAMATZI",
    value: "CONTLA DE JUAN CUAMATZI - TLAXCALA",
  },
  {
    text: "CUAPIAXTLA",
    value: "CUAPIAXTLA - TLAXCALA",
  },
  {
    text: "CUAXOMULCO",
    value: "CUAXOMULCO - TLAXCALA",
  },
  {
    text: "EL CARMEN TEQUEXQUITLA",
    value: "EL CARMEN TEQUEXQUITLA - TLAXCALA",
  },
  {
    text: "EMILIANO ZAPATA",
    value: "EMILIANO ZAPATA - TLAXCALA",
  },
  {
    text: "ESPANITA",
    value: "ESPANITA - TLAXCALA",
  },
  {
    text: "HUAMANTLA",
    value: "HUAMANTLA - TLAXCALA",
  },
  {
    text: "HUEYOTLIPAN",
    value: "HUEYOTLIPAN - TLAXCALA",
  },
  {
    text: "IXTACUIXTLA DE MARÍANO M",
    value: "IXTACUIXTLA DE MARÍANO M - TLAXCALA",
  },
  {
    text: "IXTENCO",
    value: "IXTENCO - TLAXCALA",
  },
  {
    text: "LA MAGDALENA TLALTELULCO",
    value: "LA MAGDALENA TLALTELULCO - TLAXCALA",
  },
  {
    text: "LÁZARO CÁRDENAS",
    value: "LÁZARO CÁRDENAS - TLAXCALA",
  },
  {
    text: "MAZATECOCHCO DE J MA MOR",
    value: "MAZATECOCHCO DE J MA MOR - TLAXCALA",
  },
  {
    text: "MUNOZ DE DOMINGO ARENAS",
    value: "MUNOZ DE DOMINGO ARENAS - TLAXCALA",
  },
  {
    text: "NANACAMILPA DE MARÍANO A",
    value: "NANACAMILPA DE MARÍANO A - TLAXCALA",
  },
  {
    text: "NATÍVITAS",
    value: "NATÍVITAS - TLAXCALA",
  },
  {
    text: "PANOTLA",
    value: "PANOTLA - TLAXCALA",
  },
  {
    text: "PÁPALOTLA DE XICOHTÉNCATL",
    value: "PÁPALOTLA DE XICOHTÉNCATL - TLAXCALA",
  },
  {
    text: "SANCTÓRUM LZRO CÁRDENAS",
    value: "SANCTÓRUM LZRO CÁRDENAS - TLAXCALA",
  },
  {
    text: "SANTA ANA NOPALUCAN",
    value: "SANTA ANA NOPALUCAN - TLAXCALA",
  },
  {
    text: "SANTA APOLONIA TEACALCO",
    value: "SANTA APOLONIA TEACALCO - TLAXCALA",
  },
  {
    text: "SANTA CATARINA AYOMETLA",
    value: "SANTA CATARINA AYOMETLA - TLAXCALA",
  },
  {
    text: "SANTA CRUZ TLAXCALA",
    value: "SANTA CRUZ TLAXCALA - TLAXCALA",
  },
  {
    text: "SANTA ISABEL XILOXOXTLA",
    value: "SANTA ISABEL XILOXOXTLA - TLAXCALA",
  },
  {
    text: "SAN DAMIÁN TEXÓLOC",
    value: "SAN DAMIÁN TEXÓLOC - TLAXCALA",
  },
  {
    text: "SAN FRANCISCO TETLANOHCAN",
    value: "SAN FRANCISCO TETLANOHCAN - TLAXCALA",
  },
  {
    text: "SAN JERÓNIMO ZACUALPAN",
    value: "SAN JERÓNIMO ZACUALPAN - TLAXCALA",
  },
  {
    text: "SAN JOSÉ TEACALCO",
    value: "SAN JOSÉ TEACALCO - TLAXCALA",
  },
  {
    text: "SAN JUAN HUACTZINCO",
    value: "SAN JUAN HUACTZINCO - TLAXCALA",
  },
  {
    text: "SAN LUCAS TECOPILCO",
    value: "SAN LUCAS TECOPILCO - TLAXCALA",
  },
  {
    text: "SAN PABLO DEL MTE",
    value: "SAN PABLO DEL MTE - TLAXCALA",
  },
  {
    text: "TENANCINGO",
    value: "TENANCINGO - TLAXCALA",
  },
  {
    text: "TEOLOCHOLCO",
    value: "TEOLOCHOLCO - TLAXCALA",
  },
  {
    text: "TEPETITLA DE LARDIZÁBAL",
    value: "TEPETITLA DE LARDIZÁBAL - TLAXCALA",
  },
  {
    text: "TEPEYANCO",
    value: "TEPEYANCO - TLAXCALA",
  },
  {
    text: "TERRENATE",
    value: "TERRENATE - TLAXCALA",
  },
  {
    text: "TETLA DE LA SOLIDARIDAD",
    value: "TETLA DE LA SOLIDARIDAD - TLAXCALA",
  },
  {
    text: "TETLATLAHUCA",
    value: "TETLATLAHUCA - TLAXCALA",
  },
  {
    text: "TLAXCALA",
    value: "TLAXCALA - TLAXCALA",
  },
  {
    text: "TLAXCALA",
    value: "TLAXCALA - TLAXCALA",
  },
  {
    text: "TOCATLÁN",
    value: "TOCATLÁN - TLAXCALA",
  },
  {
    text: "TOTOLAC",
    value: "TOTOLAC - TLAXCALA",
  },
  {
    text: "TZOMPANTEPEC",
    value: "TZOMPANTEPEC - TLAXCALA",
  },
  {
    text: "VEGA DE ALATORRE",
    value: "VEGA DE ALATORRE - VERACRUZ",
  },
  {
    text: "VENUSTIANO CARRANZA",
    value: "VENUSTIANO CARRANZA - VERACRUZ",
  },
  {
    text: "VERACRUZ",
    value: "VERACRUZ - VERACRUZ",
  },
  {
    text: "VILLA ALDAMA",
    value: "VILLA ALDAMA - VERACRUZ",
  },
  {
    text: "VILLA YECUATLA",
    value: "VILLA YECUATLA - VERACRUZ",
  },
  {
    text: "XALAPA",
    value: "XALAPA - VERACRUZ",
  },
  {
    text: "XICO",
    value: "XICO - VERACRUZ",
  },
  {
    text: "XOXOCOTLA",
    value: "XOXOCOTLA - VERACRUZ",
  },
  {
    text: "YANGA",
    value: "YANGA - VERACRUZ",
  },
  {
    text: "YECUATLA",
    value: "YECUATLA - VERACRUZ",
  },
  {
    text: "ZACUALPAN",
    value: "ZACUALPAN - VERACRUZ",
  },
  {
    text: "ZARAGOZA",
    value: "ZARAGOZA - VERACRUZ",
  },
  {
    text: "ZENTLA",
    value: "ZENTLA - VERACRUZ",
  },
  {
    text: "ZONGOLICA",
    value: "ZONGOLICA - VERACRUZ",
  },
  {
    text: "ZONTECOMATLÁN",
    value: "ZONTECOMATLÁN - VERACRUZ",
  },
  {
    text: "ZOZOCOLCO DE HIDALGO",
    value: "ZOZOCOLCO DE HIDALGO - VERACRUZ",
  },
  {
    text: "ACAJETE",
    value: "ACAJETE - VERACRUZ",
  },
  {
    text: "ACATLÁN",
    value: "ACATLÁN - VERACRUZ",
  },
  {
    text: "ACAYUCAN",
    value: "ACAYUCAN - VERACRUZ",
  },
  {
    text: "ACTOPAN",
    value: "ACTOPAN - VERACRUZ",
  },
  {
    text: "ACULA",
    value: "ACULA - VERACRUZ",
  },
  {
    text: "ACULTZINGO",
    value: "ACULTZINGO - VERACRUZ",
  },
  {
    text: "AGUA DULCE",
    value: "AGUA DULCE - VERACRUZ",
  },
  {
    text: "ÁLAMO TEMAPACHE",
    value: "ÁLAMO TEMAPACHE - VERACRUZ",
  },
  {
    text: "ALPATLÁHUAC",
    value: "ALPATLÁHUAC - VERACRUZ",
  },
  {
    text: "ALTO LUCERO",
    value: "ALTO LUCERO - VERACRUZ",
  },
  {
    text: "ALTOTONGA",
    value: "ALTOTONGA - VERACRUZ",
  },
  {
    text: "ALVARADO",
    value: "ALVARADO - VERACRUZ",
  },
  {
    text: "ALOÁPAM",
    value: "ALOÁPAM - VERACRUZ",
  },
  {
    text: "AMATLÁN DE LOS REYES",
    value: "AMATLÁN DE LOS REYES - VERACRUZ",
  },
  {
    text: "ANGEL R CABADA",
    value: "ANGEL R CABADA - VERACRUZ",
  },
  {
    text: "APAZAPAN",
    value: "APAZAPAN - VERACRUZ",
  },
  {
    text: "AQUILA",
    value: "AQUILA - VERACRUZ",
  },
  {
    text: "ASTACINGA",
    value: "ASTACINGA - VERACRUZ",
  },
  {
    text: "ATLAHUILCO",
    value: "ATLAHUILCO - VERACRUZ",
  },
  {
    text: "ATOYAC",
    value: "ATOYAC - VERACRUZ",
  },
  {
    text: "ATZACAN",
    value: "ATZACAN - VERACRUZ",
  },
  {
    text: "ATZALAN",
    value: "ATZALAN - VERACRUZ",
  },
  {
    text: "AYAHUALULCO",
    value: "AYAHUALULCO - VERACRUZ",
  },
  {
    text: "BANDERILLA",
    value: "BANDERILLA - VERACRUZ",
  },
  {
    text: "BENITO JUÁREZ",
    value: "BENITO JUÁREZ - VERACRUZ",
  },
  {
    text: "BOCA DEL RÍO",
    value: "BOCA DEL RÍO - VERACRUZ",
  },
  {
    text: "CALCAHUALCO",
    value: "CALCAHUALCO - VERACRUZ",
  },
  {
    text: "CAMARÓN DE TEJEDA",
    value: "CAMARÓN DE TEJEDA - VERACRUZ",
  },
  {
    text: "CAMERINO Z MENDOZA",
    value: "CAMERINO Z MENDOZA - VERACRUZ",
  },
  {
    text: "CARLOS A CARRILLO",
    value: "CARLOS A CARRILLO - VERACRUZ",
  },
  {
    text: "CARRILLO PTO",
    value: "CARRILLO PTO - VERACRUZ",
  },
  {
    text: "CASTILLO DE TEAYO",
    value: "CASTILLO DE TEAYO - VERACRUZ",
  },
  {
    text: "CATEMACO",
    value: "CATEMACO - VERACRUZ",
  },
  {
    text: "CAZONES DE HERRERA",
    value: "CAZONES DE HERRERA - VERACRUZ",
  },
  {
    text: "CERRO AZUL",
    value: "CERRO AZUL - VERACRUZ",
  },
  {
    text: "CHACALTIANGUIS",
    value: "CHACALTIANGUIS - VERACRUZ",
  },
  {
    text: "CHALMA",
    value: "CHALMA - VERACRUZ",
  },
  {
    text: "CHICONAMEL",
    value: "CHICONAMEL - VERACRUZ",
  },
  {
    text: "CHICONQUIACO",
    value: "CHICONQUIACO - VERACRUZ",
  },
  {
    text: "CHICONTEPEC",
    value: "CHICONTEPEC - VERACRUZ",
  },
  {
    text: "CHINAMECA",
    value: "CHINAMECA - VERACRUZ",
  },
  {
    text: "CHINAMPA DE GOROSTIZA",
    value: "CHINAMPA DE GOROSTIZA - VERACRUZ",
  },
  {
    text: "CHOCAMÁN",
    value: "CHOCAMÁN - VERACRUZ",
  },
  {
    text: "CHONTLA",
    value: "CHONTLA - VERACRUZ",
  },
  {
    text: "CHUMATLÁN",
    value: "CHUMATLÁN - VERACRUZ",
  },
  {
    text: "CITLALTÉPETL",
    value: "CITLALTÉPETL - VERACRUZ",
  },
  {
    text: "COACOATZINTLA",
    value: "COACOATZINTLA - VERACRUZ",
  },
  {
    text: "COAHUITLÁN",
    value: "COAHUITLÁN - VERACRUZ",
  },
  {
    text: "COATEPEC",
    value: "COATEPEC - VERACRUZ",
  },
  {
    text: "COATZACOALCOS",
    value: "COATZACOALCOS - VERACRUZ",
  },
  {
    text: "COATZINTLA",
    value: "COATZINTLA - VERACRUZ",
  },
  {
    text: "COETZALA",
    value: "COETZALA - VERACRUZ",
  },
  {
    text: "COLIPA",
    value: "COLIPA - VERACRUZ",
  },
  {
    text: "COMAPA",
    value: "COMAPA - VERACRUZ",
  },
  {
    text: "CÓRDOBA",
    value: "CÓRDOBA - VERACRUZ",
  },
  {
    text: "COSAMALOAPAN DE CARPIO",
    value: "COSAMALOAPAN DE CARPIO - VERACRUZ",
  },
  {
    text: "COSAMALOAPAN",
    value: "COSAMALOAPAN - VERACRUZ",
  },
  {
    text: "COSAUTLÁN DE CARVAJAL",
    value: "COSAUTLÁN DE CARVAJAL - VERACRUZ",
  },
  {
    text: "COSCOMATEPEC",
    value: "COSCOMATEPEC - VERACRUZ",
  },
  {
    text: "COSOLEACAQUE",
    value: "COSOLEACAQUE - VERACRUZ",
  },
  {
    text: "COTAXTLA",
    value: "COTAXTLA - VERACRUZ",
  },
  {
    text: "COXQUIHUI",
    value: "COXQUIHUI - VERACRUZ",
  },
  {
    text: "COYUTLA",
    value: "COYUTLA - VERACRUZ",
  },
  {
    text: "CUICHAPA",
    value: "CUICHAPA - VERACRUZ",
  },
  {
    text: "CUITLÁHUAC",
    value: "CUITLÁHUAC - VERACRUZ",
  },
  {
    text: "EL HIGO",
    value: "EL HIGO - VERACRUZ",
  },
  {
    text: "EMILIANO ZAPATA",
    value: "EMILIANO ZAPATA - VERACRUZ",
  },
  {
    text: "ESPINAL",
    value: "ESPINAL - VERACRUZ",
  },
  {
    text: "FILOMENO MATA",
    value: "FILOMENO MATA - VERACRUZ",
  },
  {
    text: "FORTÍN DE LAS FLORES",
    value: "FORTÍN DE LAS FLORES - VERACRUZ",
  },
  {
    text: "FORTÍN",
    value: "FORTÍN - VERACRUZ",
  },
  {
    text: "GUTIÉRREZ ZAMORA",
    value: "GUTIÉRREZ ZAMORA - VERACRUZ",
  },
  {
    text: "HIDALGOTITLÁN",
    value: "HIDALGOTITLÁN - VERACRUZ",
  },
  {
    text: "HUATUSCO",
    value: "HUATUSCO - VERACRUZ",
  },
  {
    text: "HUAYACOCOTLA",
    value: "HUAYACOCOTLA - VERACRUZ",
  },
  {
    text: "HUEYAPAN DE OCAMPO",
    value: "HUEYAPAN DE OCAMPO - VERACRUZ",
  },
  {
    text: "HUILOAPAN DE CUAUHTÉMOC",
    value: "HUILOAPAN DE CUAUHTÉMOC - VERACRUZ",
  },
  {
    text: "IGNACIO DE LA LLAVE",
    value: "IGNACIO DE LA LLAVE - VERACRUZ",
  },
  {
    text: "ILAMATLÁN",
    value: "ILAMATLÁN - VERACRUZ",
  },
  {
    text: "ISLA",
    value: "ISLA - VERACRUZ",
  },
  {
    text: "IXCATEPEC",
    value: "IXCATEPEC - VERACRUZ",
  },
  {
    text: "IXHUACÁN DE LOS REYES",
    value: "IXHUACÁN DE LOS REYES - VERACRUZ",
  },
  {
    text: "IXHUATLÁN DE MADERO",
    value: "IXHUATLÁN DE MADERO - VERACRUZ",
  },
  {
    text: "IXHUATLÁN DEL CAFE",
    value: "IXHUATLÁN DEL CAFE - VERACRUZ",
  },
  {
    text: "IXHUATLÁN DEL SURESTE",
    value: "IXHUATLÁN DEL SURESTE - VERACRUZ",
  },
  {
    text: "IXHUATLÁNCILLO",
    value: "IXHUATLÁNCILLO - VERACRUZ",
  },
  {
    text: "IXMATLÁHUACAN",
    value: "IXMATLÁHUACAN - VERACRUZ",
  },
  {
    text: "IXTACZOQUITLÁN",
    value: "IXTACZOQUITLÁN - VERACRUZ",
  },
  {
    text: "JALACINGO",
    value: "JALACINGO - VERACRUZ",
  },
  {
    text: "JALCOMULCO",
    value: "JALCOMULCO - VERACRUZ",
  },
  {
    text: "JÁLTIPAN",
    value: "JÁLTIPAN - VERACRUZ",
  },
  {
    text: "JAMAPA",
    value: "JAMAPA - VERACRUZ",
  },
  {
    text: "JESUS CARRANZA",
    value: "JESUS CARRANZA - VERACRUZ",
  },
  {
    text: "JILOTEPEC",
    value: "JILOTEPEC - VERACRUZ",
  },
  {
    text: "JOSÉ AZUETA",
    value: "JOSÉ AZUETA - VERACRUZ",
  },
  {
    text: "JUAN RODRÍGUEZ CLARA",
    value: "JUAN RODRÍGUEZ CLARA - VERACRUZ",
  },
  {
    text: "JUCHIQUE DE FERRER",
    value: "JUCHIQUE DE FERRER - VERACRUZ",
  },
  {
    text: "LA ANTIGUA",
    value: "LA ANTIGUA - VERACRUZ",
  },
  {
    text: "LA PERLA",
    value: "LA PERLA - VERACRUZ",
  },
  {
    text: "LANDERO Y COSS",
    value: "LANDERO Y COSS - VERACRUZ",
  },
  {
    text: "LAS CHOAPAS",
    value: "LAS CHOAPAS - VERACRUZ",
  },
  {
    text: "LAS MINAS",
    value: "LAS MINAS - VERACRUZ",
  },
  {
    text: "LAS VIGAS DE RAMÍREZ",
    value: "LAS VIGAS DE RAMÍREZ - VERACRUZ",
  },
  {
    text: "LERDO DE TEJADA",
    value: "LERDO DE TEJADA - VERACRUZ",
  },
  {
    text: "LOS REYES",
    value: "LOS REYES - VERACRUZ",
  },
  {
    text: "MAGDALENA",
    value: "MAGDALENA - VERACRUZ",
  },
  {
    text: "MALTRATA",
    value: "MALTRATA - VERACRUZ",
  },
  {
    text: "MANLIO FABIO ALTAMIRANO",
    value: "MANLIO FABIO ALTAMIRANO - VERACRUZ",
  },
  {
    text: "MARÍANO ESCOBEDO",
    value: "MARÍANO ESCOBEDO - VERACRUZ",
  },
  {
    text: "MARTÍNEZ DE LA TORRE",
    value: "MARTÍNEZ DE LA TORRE - VERACRUZ",
  },
  {
    text: "MECATLÁN",
    value: "MECATLÁN - VERACRUZ",
  },
  {
    text: "MECAYAPAN",
    value: "MECAYAPAN - VERACRUZ",
  },
  {
    text: "MEDELLÍN",
    value: "MEDELLÍN - VERACRUZ",
  },
  {
    text: "MIAHUATLÁN",
    value: "MIAHUATLÁN - VERACRUZ",
  },
  {
    text: "MINATITLÁN",
    value: "MINATITLÁN - VERACRUZ",
  },
  {
    text: "MISANTLA",
    value: "MISANTLA - VERACRUZ",
  },
  {
    text: "MIXTLA DE ALTAMIRANO",
    value: "MIXTLA DE ALTAMIRANO - VERACRUZ",
  },
  {
    text: "MOLOACÁN",
    value: "MOLOACÁN - VERACRUZ",
  },
  {
    text: "NANCHITAL DE LZRO CÁRDENAS",
    value: "NANCHITAL DE LZRO CÁRDENAS - VERACRUZ",
  },
  {
    text: "NAOLINCO",
    value: "NAOLINCO - VERACRUZ",
  },
  {
    text: "NARANJAL",
    value: "NARANJAL - VERACRUZ",
  },
  {
    text: "NARANJOS AMATLÁN",
    value: "NARANJOS AMATLÁN - VERACRUZ",
  },
  {
    text: "NARANJOS",
    value: "NARANJOS - VERACRUZ",
  },
  {
    text: "NAUTLA",
    value: "NAUTLA - VERACRUZ",
  },
  {
    text: "NOGALES",
    value: "NOGALES - VERACRUZ",
  },
  {
    text: "OLUTA",
    value: "OLUTA - VERACRUZ",
  },
  {
    text: "OMEALCA",
    value: "OMEALCA - VERACRUZ",
  },
  {
    text: "ORIZABA",
    value: "ORIZABA - VERACRUZ",
  },
  {
    text: "OTATITLÁN",
    value: "OTATITLÁN - VERACRUZ",
  },
  {
    text: "OTEAPAN",
    value: "OTEAPAN - VERACRUZ",
  },
  {
    text: "OZULUAMA",
    value: "OZULUAMA - VERACRUZ",
  },
  {
    text: "PAJAPAN",
    value: "PAJAPAN - VERACRUZ",
  },
  {
    text: "PÁNUCO",
    value: "PÁNUCO - VERACRUZ",
  },
  {
    text: "PAPANTLA",
    value: "PAPANTLA - VERACRUZ",
  },
  {
    text: "PASO DE OVEJAS",
    value: "PASO DE OVEJAS - VERACRUZ",
  },
  {
    text: "PASO DEL MACHO",
    value: "PASO DEL MACHO - VERACRUZ",
  },
  {
    text: "PEROTE",
    value: "PEROTE - VERACRUZ",
  },
  {
    text: "PLATON SÁNCHEZ",
    value: "PLATON SÁNCHEZ - VERACRUZ",
  },
  {
    text: "PLAYA VICENTE",
    value: "PLAYA VICENTE - VERACRUZ",
  },
  {
    text: "POZA RICA DE HIDALGO",
    value: "POZA RICA DE HIDALGO - VERACRUZ",
  },
  {
    text: "PUEBLO VIEJO",
    value: "PUEBLO VIEJO - VERACRUZ",
  },
  {
    text: "PUENTE NAC",
    value: "PUENTE NAC - VERACRUZ",
  },
  {
    text: "RAFAEL DELGADO",
    value: "RAFAEL DELGADO - VERACRUZ",
  },
  {
    text: "RAFAEL LUCIO",
    value: "RAFAEL LUCIO - VERACRUZ",
  },
  {
    text: "RÍO BLANCO",
    value: "RÍO BLANCO - VERACRUZ",
  },
  {
    text: "SALTABARRANCA",
    value: "SALTABARRANCA - VERACRUZ",
  },
  {
    text: "SANTIAGO SOCHIAPAN",
    value: "SANTIAGO SOCHIAPAN - VERACRUZ",
  },
  {
    text: "SANTIAGO TUXTLA",
    value: "SANTIAGO TUXTLA - VERACRUZ",
  },
  {
    text: "SAYULA DE ALEMÁN",
    value: "SAYULA DE ALEMÁN - VERACRUZ",
  },
  {
    text: "SIHUAPAN",
    value: "SIHUAPAN - VERACRUZ",
  },
  {
    text: "SAN ANDRÉS TENEJAPAN",
    value: "SAN ANDRÉS TENEJAPAN - VERACRUZ",
  },
  {
    text: "SAN ANDRÉS TUXTLA",
    value: "SAN ANDRÉS TUXTLA - VERACRUZ",
  },
  {
    text: "SAN JUAN EVANGELISTA",
    value: "SAN JUAN EVANGELISTA - VERACRUZ",
  },
  {
    text: "SAN RAFAEL",
    value: "SAN RAFAEL - VERACRUZ",
  },
  {
    text: "SOCHIAPA",
    value: "SOCHIAPA - VERACRUZ",
  },
  {
    text: "SOCONUSCO",
    value: "SOCONUSCO - VERACRUZ",
  },
  {
    text: "SOLEDAD ATZOMPA",
    value: "SOLEDAD ATZOMPA - VERACRUZ",
  },
  {
    text: "SOLEDAD DE DOBLADO",
    value: "SOLEDAD DE DOBLADO - VERACRUZ",
  },
  {
    text: "SOTEAPAN",
    value: "SOTEAPAN - VERACRUZ",
  },
  {
    text: "TAMALÍN",
    value: "TAMALÍN - VERACRUZ",
  },
  {
    text: "TAMIAHUA",
    value: "TAMIAHUA - VERACRUZ",
  },
  {
    text: "TAMPICO ALTO",
    value: "TAMPICO ALTO - VERACRUZ",
  },
  {
    text: "TANCOCO",
    value: "TANCOCO - VERACRUZ",
  },
  {
    text: "TANTIMA",
    value: "TANTIMA - VERACRUZ",
  },
  {
    text: "TANTOYUCA",
    value: "TANTOYUCA - VERACRUZ",
  },
  {
    text: "TATAHUICAPAN DE JUÁREZ",
    value: "TATAHUICAPAN DE JUÁREZ - VERACRUZ",
  },
  {
    text: "TATATILA",
    value: "TATATILA - VERACRUZ",
  },
  {
    text: "TECOLUTLA",
    value: "TECOLUTLA - VERACRUZ",
  },
  {
    text: "TEHUIPANGO",
    value: "TEHUIPANGO - VERACRUZ",
  },
  {
    text: "TEMPOAL",
    value: "TEMPOAL - VERACRUZ",
  },
  {
    text: "TENAMPA",
    value: "TENAMPA - VERACRUZ",
  },
  {
    text: "TENOCHTITLÁN",
    value: "TENOCHTITLÁN - VERACRUZ",
  },
  {
    text: "TEOCELO",
    value: "TEOCELO - VERACRUZ",
  },
  {
    text: "TEPATLAXCO",
    value: "TEPATLAXCO - VERACRUZ",
  },
  {
    text: "TEPETLÁN",
    value: "TEPETLÁN - VERACRUZ",
  },
  {
    text: "TEPETZINTLA",
    value: "TEPETZINTLA - VERACRUZ",
  },
  {
    text: "TEQUILA",
    value: "TEQUILA - VERACRUZ",
  },
  {
    text: "TEXCATEPEC",
    value: "TEXCATEPEC - VERACRUZ",
  },
  {
    text: "TEXHUACÁN",
    value: "TEXHUACÁN - VERACRUZ",
  },
  {
    text: "TEXISTEPEC",
    value: "TEXISTEPEC - VERACRUZ",
  },
  {
    text: "TEZONAPA",
    value: "TEZONAPA - VERACRUZ",
  },
  {
    text: "TIERRA BLANCA",
    value: "TIERRA BLANCA - VERACRUZ",
  },
  {
    text: "TIHUATLÁN",
    value: "TIHUATLÁN - VERACRUZ",
  },
  {
    text: "TLACHICHILCO",
    value: "TLACHICHILCO - VERACRUZ",
  },
  {
    text: "TLACOJALPAN",
    value: "TLACOJALPAN - VERACRUZ",
  },
  {
    text: "TLACOLULAN",
    value: "TLACOLULAN - VERACRUZ",
  },
  {
    text: "TLACOTALPAN",
    value: "TLACOTALPAN - VERACRUZ",
  },
  {
    text: "TLACOTEPEC DE MEJÍA",
    value: "TLACOTEPEC DE MEJÍA - VERACRUZ",
  },
  {
    text: "TLALIXCOYAN",
    value: "TLALIXCOYAN - VERACRUZ",
  },
  {
    text: "TLALNELHUAYOCAN",
    value: "TLALNELHUAYOCAN - VERACRUZ",
  },
  {
    text: "TLALTETELA",
    value: "TLALTETELA - VERACRUZ",
  },
  {
    text: "TLAPACOYAN",
    value: "TLAPACOYAN - VERACRUZ",
  },
  {
    text: "TLAQUILPA",
    value: "TLAQUILPA - VERACRUZ",
  },
  {
    text: "TLILAPAN",
    value: "TLILAPAN - VERACRUZ",
  },
  {
    text: "TOMATLÁN",
    value: "TOMATLÁN - VERACRUZ",
  },
  {
    text: "TONAYÁN",
    value: "TONAYÁN - VERACRUZ",
  },
  {
    text: "TOTUTLA",
    value: "TOTUTLA - VERACRUZ",
  },
  {
    text: "TRES VALLES",
    value: "TRES VALLES - VERACRUZ",
  },
  {
    text: "TUXPAN",
    value: "TUXPAN - VERACRUZ",
  },
  {
    text: "TUXTILLA",
    value: "TUXTILLA - VERACRUZ",
  },
  {
    text: "URSULO GALVÁN",
    value: "URSULO GALVÁN - VERACRUZ",
  },
  {
    text: "UXPANAPA",
    value: "UXPANAPA - VERACRUZ",
  },
  {
    text: "ABALÁ",
    value: "ABALÁ - YUCATÁN",
  },
  {
    text: "ACANCEH",
    value: "ACANCEH - YUCATÁN",
  },
  {
    text: "AKIL",
    value: "AKIL - YUCATÁN",
  },
  {
    text: "BACA",
    value: "BACA - YUCATÁN",
  },
  {
    text: "BOKOBÁ",
    value: "BOKOBÁ - YUCATÁN",
  },
  {
    text: "BUCTZOTZ",
    value: "BUCTZOTZ - YUCATÁN",
  },
  {
    text: "CACALCHÉN",
    value: "CACALCHÉN - YUCATÁN",
  },
  {
    text: "CALOTMUL",
    value: "CALOTMUL - YUCATÁN",
  },
  {
    text: "CANSAHCAB",
    value: "CANSAHCAB - YUCATÁN",
  },
  {
    text: "CANTAMAYEC",
    value: "CANTAMAYEC - YUCATÁN",
  },
  {
    text: "CELESTÚN",
    value: "CELESTÚN - YUCATÁN",
  },
  {
    text: "CENOTILLO",
    value: "CENOTILLO - YUCATÁN",
  },
  {
    text: "CHACSINKÍN",
    value: "CHACSINKÍN - YUCATÁN",
  },
  {
    text: "CHANKOM",
    value: "CHANKOM - YUCATÁN",
  },
  {
    text: "CHAPAB",
    value: "CHAPAB - YUCATÁN",
  },
  {
    text: "CHEMAX",
    value: "CHEMAX - YUCATÁN",
  },
  {
    text: "CHICHIMILÁ",
    value: "CHICHIMILÁ - YUCATÁN",
  },
  {
    text: "CHICXULUB PUEBLO",
    value: "CHICXULUB PUEBLO - YUCATÁN",
  },
  {
    text: "CHIKINDZONOT",
    value: "CHIKINDZONOT - YUCATÁN",
  },
  {
    text: "CHOCHOLÁ",
    value: "CHOCHOLÁ - YUCATÁN",
  },
  {
    text: "CHUMAYEL",
    value: "CHUMAYEL - YUCATÁN",
  },
  {
    text: "CONKAL",
    value: "CONKAL - YUCATÁN",
  },
  {
    text: "CUNCUNUL",
    value: "CUNCUNUL - YUCATÁN",
  },
  {
    text: "CUZAMÁ",
    value: "CUZAMÁ - YUCATÁN",
  },
  {
    text: "DZÁN",
    value: "DZÁN - YUCATÁN",
  },
  {
    text: "DZEMUL",
    value: "DZEMUL - YUCATÁN",
  },
  {
    text: "DZIDZANTÚN",
    value: "DZIDZANTÚN - YUCATÁN",
  },
  {
    text: "DZILAM DE BRAVO",
    value: "DZILAM DE BRAVO - YUCATÁN",
  },
  {
    text: "DZILAM GONZÁLEZ",
    value: "DZILAM GONZÁLEZ - YUCATÁN",
  },
  {
    text: "DZITÁS",
    value: "DZITÁS - YUCATÁN",
  },
  {
    text: "DZONCAUICH",
    value: "DZONCAUICH - YUCATÁN",
  },
  {
    text: "ESPITA",
    value: "ESPITA - YUCATÁN",
  },
  {
    text: "HALACHÓ",
    value: "HALACHÓ - YUCATÁN",
  },
  {
    text: "HOCABÁ",
    value: "HOCABÁ - YUCATÁN",
  },
  {
    text: "HOCTÚN",
    value: "HOCTÚN - YUCATÁN",
  },
  {
    text: "HOMÚN",
    value: "HOMÚN - YUCATÁN",
  },
  {
    text: "HUHÍ",
    value: "HUHÍ - YUCATÁN",
  },
  {
    text: "HUNUCMÁ",
    value: "HUNUCMÁ - YUCATÁN",
  },
  {
    text: "IXIL",
    value: "IXIL - YUCATÁN",
  },
  {
    text: "IZAMAL",
    value: "IZAMAL - YUCATÁN",
  },
  {
    text: "KANASÍN",
    value: "KANASÍN - YUCATÁN",
  },
  {
    text: "KANTUNIL",
    value: "KANTUNIL - YUCATÁN",
  },
  {
    text: "KAUA",
    value: "KAUA - YUCATÁN",
  },
  {
    text: "KINCHIL",
    value: "KINCHIL - YUCATÁN",
  },
  {
    text: "KOPOMÁ",
    value: "KOPOMÁ - YUCATÁN",
  },
  {
    text: "MAMA",
    value: "MAMA - YUCATÁN",
  },
  {
    text: "MANÍ",
    value: "MANÍ - YUCATÁN",
  },
  {
    text: "MAXCANÚ",
    value: "MAXCANÚ - YUCATÁN",
  },
  {
    text: "MAYAPÁN",
    value: "MAYAPÁN - YUCATÁN",
  },
  {
    text: "MÉRIDA",
    value: "MÉRIDA - YUCATÁN",
  },
  {
    text: "MOCOCHÁ",
    value: "MOCOCHÁ - YUCATÁN",
  },
  {
    text: "MOTUL",
    value: "MOTUL - YUCATÁN",
  },
  {
    text: "MUNA",
    value: "MUNA - YUCATÁN",
  },
  {
    text: "MUXUPIP",
    value: "MUXUPIP - YUCATÁN",
  },
  {
    text: "OPICHÉN",
    value: "OPICHÉN - YUCATÁN",
  },
  {
    text: "OXKUTZCAB",
    value: "OXKUTZCAB - YUCATÁN",
  },
  {
    text: "PANABÁ",
    value: "PANABÁ - YUCATÁN",
  },
  {
    text: "PETO",
    value: "PETO - YUCATÁN",
  },
  {
    text: "PROGRESO",
    value: "PROGRESO - YUCATÁN",
  },
  {
    text: "QUINTANA ROO",
    value: "QUINTANA ROO - YUCATÁN",
  },
  {
    text: "RÍO LAGARTOS",
    value: "RÍO LAGARTOS - YUCATÁN",
  },
  {
    text: "SACALUM",
    value: "SACALUM - YUCATÁN",
  },
  {
    text: "SAMAHIL",
    value: "SAMAHIL - YUCATÁN",
  },
  {
    text: "SANAHCAT",
    value: "SANAHCAT - YUCATÁN",
  },
  {
    text: "SANTA ELENA",
    value: "SANTA ELENA - YUCATÁN",
  },
  {
    text: "SEYÉ",
    value: "SEYÉ - YUCATÁN",
  },
  {
    text: "SINANCHÉ",
    value: "SINANCHÉ - YUCATÁN",
  },
  {
    text: "SAN FELIPE",
    value: "SAN FELIPE - YUCATÁN",
  },
  {
    text: "SOTUTA",
    value: "SOTUTA - YUCATÁN",
  },
  {
    text: "SUCILÁ",
    value: "SUCILÁ - YUCATÁN",
  },
  {
    text: "SUDZAL",
    value: "SUDZAL - YUCATÁN",
  },
  {
    text: "SUMA",
    value: "SUMA - YUCATÁN",
  },
  {
    text: "TAHDZIÚ",
    value: "TAHDZIÚ - YUCATÁN",
  },
  {
    text: "TAHMEK",
    value: "TAHMEK - YUCATÁN",
  },
  {
    text: "TEABO",
    value: "TEABO - YUCATÁN",
  },
  {
    text: "TECOH",
    value: "TECOH - YUCATÁN",
  },
  {
    text: "TEKAL DE VENEGAS",
    value: "TEKAL DE VENEGAS - YUCATÁN",
  },
  {
    text: "TEKANTÓ",
    value: "TEKANTÓ - YUCATÁN",
  },
  {
    text: "TEKAX",
    value: "TEKAX - YUCATÁN",
  },
  {
    text: "TEKIT",
    value: "TEKIT - YUCATÁN",
  },
  {
    text: "TEKOM",
    value: "TEKOM - YUCATÁN",
  },
  {
    text: "TELCHAC PTO",
    value: "TELCHAC PTO - YUCATÁN",
  },
  {
    text: "TELCHAC PUEBLO",
    value: "TELCHAC PUEBLO - YUCATÁN",
  },
  {
    text: "TEMAX",
    value: "TEMAX - YUCATÁN",
  },
  {
    text: "TEMOZÓN",
    value: "TEMOZÓN - YUCATÁN",
  },
  {
    text: "TEPAKÁN",
    value: "TEPAKÁN - YUCATÁN",
  },
  {
    text: "TETIZ",
    value: "TETIZ - YUCATÁN",
  },
  {
    text: "TEYA",
    value: "TEYA - YUCATÁN",
  },
  {
    text: "TICUL",
    value: "TICUL - YUCATÁN",
  },
  {
    text: "TIMUCUY",
    value: "TIMUCUY - YUCATÁN",
  },
  {
    text: "TINUM",
    value: "TINUM - YUCATÁN",
  },
  {
    text: "TIXCACALCUPUL",
    value: "TIXCACALCUPUL - YUCATÁN",
  },
  {
    text: "TIXKOKOB",
    value: "TIXKOKOB - YUCATÁN",
  },
  {
    text: "TIXMEHUAC",
    value: "TIXMEHUAC - YUCATÁN",
  },
  {
    text: "TIXPÉHUAL",
    value: "TIXPÉHUAL - YUCATÁN",
  },
  {
    text: "TIZIMÍN",
    value: "TIZIMÍN - YUCATÁN",
  },
  {
    text: "TUNKÁS",
    value: "TUNKÁS - YUCATÁN",
  },
  {
    text: "TZUCACAB",
    value: "TZUCACAB - YUCATÁN",
  },
  {
    text: "UAYMA",
    value: "UAYMA - YUCATÁN",
  },
  {
    text: "UCÚ",
    value: "UCÚ - YUCATÁN",
  },
  {
    text: "UMÁN",
    value: "UMÁN - YUCATÁN",
  },
  {
    text: "VALLADOLID",
    value: "VALLADOLID - YUCATÁN",
  },
  {
    text: "XOCCHEL",
    value: "XOCCHEL - YUCATÁN",
  },
  {
    text: "YAXCABÁ",
    value: "YAXCABÁ - YUCATÁN",
  },
  {
    text: "YAXKUKUL",
    value: "YAXKUKUL - YUCATÁN",
  },
  {
    text: "YOBAÍN",
    value: "YOBAÍN - YUCATÁN",
  },
  {
    text: "APOZOL",
    value: "APOZOL - ZACATECAS",
  },
  {
    text: "APULCO",
    value: "APULCO - ZACATECAS",
  },
  {
    text: "ATOLINGA",
    value: "ATOLINGA - ZACATECAS",
  },
  {
    text: "BENITO JUÁREZ",
    value: "BENITO JUÁREZ - ZACATECAS",
  },
  {
    text: "CALERA",
    value: "CALERA - ZACATECAS",
  },
  {
    text: "CANITAS DE FELIPE PESCADOR",
    value: "CANITAS DE FELIPE PESCADOR - ZACATECAS",
  },
  {
    text: "CHALCHIHUITES",
    value: "CHALCHIHUITES - ZACATECAS",
  },
  {
    text: "CONCEPCIÓN DEL ORO",
    value: "CONCEPCIÓN DEL ORO - ZACATECAS",
  },
  {
    text: "CUAUHTÉMOC",
    value: "CUAUHTÉMOC - ZACATECAS",
  },
  {
    text: "EL PLATEADO JOAQUÍN AMARO",
    value: "EL PLATEADO JOAQUÍN AMARO - ZACATECAS",
  },
  {
    text: "EL SALVADOR",
    value: "EL SALVADOR - ZACATECAS",
  },
  {
    text: "FRESNILLO",
    value: "FRESNILLO - ZACATECAS",
  },
  {
    text: "GENARO CODINA",
    value: "GENARO CODINA - ZACATECAS",
  },
  {
    text: "GRAL ENRIQUE ESTRADA",
    value: "GRAL ENRIQUE ESTRADA - ZACATECAS",
  },
  {
    text: "GRAL FRANCISCO R MURGUIA",
    value: "GRAL FRANCISCO R MURGUIA - ZACATECAS",
  },
  {
    text: "GRAL PÁNFILO NATERA",
    value: "GRAL PÁNFILO NATERA - ZACATECAS",
  },
  {
    text: "GUADALUPE",
    value: "GUADALUPE - ZACATECAS",
  },
  {
    text: "HUANUSCO",
    value: "HUANUSCO - ZACATECAS",
  },
  {
    text: "JALPA",
    value: "JALPA - ZACATECAS",
  },
  {
    text: "JEREZ",
    value: "JEREZ - ZACATECAS",
  },
  {
    text: "JIMÉNEZ DEL TEÚL",
    value: "JIMÉNEZ DEL TEÚL - ZACATECAS",
  },
  {
    text: "JUAN ALDAMA",
    value: "JUAN ALDAMA - ZACATECAS",
  },
  {
    text: "JUCHIPILA",
    value: "JUCHIPILA - ZACATECAS",
  },
  {
    text: "LORETO",
    value: "LORETO - ZACATECAS",
  },
  {
    text: "LUIS MOYA",
    value: "LUIS MOYA - ZACATECAS",
  },
  {
    text: "MAZAPIL",
    value: "MAZAPIL - ZACATECAS",
  },
  {
    text: "MELCHOR OCAMPO",
    value: "MELCHOR OCAMPO - ZACATECAS",
  },
  {
    text: "MEZQUITAL DEL ORO",
    value: "MEZQUITAL DEL ORO - ZACATECAS",
  },
  {
    text: "MIGUEL AUZA",
    value: "MIGUEL AUZA - ZACATECAS",
  },
  {
    text: "MOMAX",
    value: "MOMAX - ZACATECAS",
  },
  {
    text: "MORELOS",
    value: "MORELOS - ZACATECAS",
  },
  {
    text: "MOYAHUA DE ESTRADA",
    value: "MOYAHUA DE ESTRADA - ZACATECAS",
  },
  {
    text: "MTE ESCOBEDO",
    value: "MTE ESCOBEDO - ZACATECAS",
  },
  {
    text: "NOCHISTLAN DE MEJÍA",
    value: "NOCHISTLAN DE MEJÍA - ZACATECAS",
  },
  {
    text: "NORIA DE ÁNGELES",
    value: "NORIA DE ÁNGELES - ZACATECAS",
  },
  {
    text: "OJOCALIENTE",
    value: "OJOCALIENTE - ZACATECAS",
  },
  {
    text: "PÁNUCO",
    value: "PÁNUCO - ZACATECAS",
  },
  {
    text: "PINOS",
    value: "PINOS - ZACATECAS",
  },
  {
    text: "RÍO GRANDE",
    value: "RÍO GRANDE - ZACATECAS",
  },
  {
    text: "SAIN ALTO",
    value: "SAIN ALTO - ZACATECAS",
  },
  {
    text: "SANTA MARÍA DE LA PAZ",
    value: "SANTA MARÍA DE LA PAZ - ZACATECAS",
  },
  {
    text: "SOMBRERETE",
    value: "SOMBRERETE - ZACATECAS",
  },
  {
    text: "SUSTICACÁN",
    value: "SUSTICACÁN - ZACATECAS",
  },
  {
    text: "TABASCO",
    value: "TABASCO - ZACATECAS",
  },
  {
    text: "TEPECHITLÁN",
    value: "TEPECHITLÁN - ZACATECAS",
  },
  {
    text: "TEPETONGO",
    value: "TEPETONGO - ZACATECAS",
  },
  {
    text: "TEUL DE GONZÁLEZ ORTEGA",
    value: "TEUL DE GONZÁLEZ ORTEGA - ZACATECAS",
  },
  {
    text: "TLALTENANGO SÁNCHEZ ROMAN",
    value: "TLALTENANGO SÁNCHEZ ROMAN - ZACATECAS",
  },
  {
    text: "TRANCOSO",
    value: "TRANCOSO - ZACATECAS",
  },
  {
    text: "TRINIDAD GARCÍA LA CADENA",
    value: "TRINIDAD GARCÍA LA CADENA - ZACATECAS",
  },
  {
    text: "VETAGRANDE",
    value: "VETAGRANDE - ZACATECAS",
  },
  {
    text: "VALPARAÍSO",
    value: "VALPARAÍSO - ZACATECAS",
  },
  {
    text: "VILLA DE COS",
    value: "VILLA DE COS - ZACATECAS",
  },
  {
    text: "VILLA GARCÍA",
    value: "VILLA GARCÍA - ZACATECAS",
  },
  {
    text: "VILLA GONZÁLEZ ORTEGA",
    value: "VILLA GONZÁLEZ ORTEGA - ZACATECAS",
  },
  {
    text: "VILLA HIDALGO",
    value: "VILLA HIDALGO - ZACATECAS",
  },
  {
    text: "VILLANUEVA",
    value: "VILLANUEVA - ZACATECAS",
  },
  {
    text: "ZACATECAS",
    value: "ZACATECAS - ZACATECAS",
  },
];
