import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import EmptyImg from "../../../assets/EmptyImg";
import { conciliationTabsStyles } from "../../ConciliationTabs/ConciliationTabs.styles";
import { IEmptyList } from "./EmptyList.interfaces";

export const EmptyList: React.FC<IEmptyList> = ({
  title,
  firstSubtitle,
  secondSubtitle,
}: IEmptyList) => {
  return (
    <>
      <Paper
        elevation={0}
        style={{ backgroundColor: "#f0f4f8", marginTop: "-45px" }}
      >
        <Box
          style={{ backgroundColor: "#f0f4f8" }}
          sx={conciliationTabsStyles.emptyRecords}
          alignItems={"center"}
          alignContent={"center"}
        >
          <Typography
            color={"primary"}
            id={"textTitle"}
            variant={"h4"}
            style={{
              fontSize: "24",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {title}
          </Typography>
          <Box>
            <Grid style={{ margin: "18px 0px" }}>
              <Typography
                color={"gray"}
                id={"firstSubtitle"}
                style={{
                  fontSize: "16",
                  padding: "5px 0px",
                  textAlign: "center",
                }}
              >
                {firstSubtitle}
              </Typography>
              <Typography
                color={"gray"}
                id={"secondSubtitle"}
                style={{
                  fontSize: "16",
                  padding: "5px 0px",
                  textAlign: "center",
                }}
              >
                {secondSubtitle}
              </Typography>
            </Grid>
          </Box>
          <EmptyImg />
        </Box>
      </Paper>
    </>
  );
};
