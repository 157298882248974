import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { useCreateRuleSectionState } from "../CreateRuleSection/state/useCreateRuleSectionState";
import { defaultTo, get } from "lodash";
import { SubtypeComponent } from "../CreateRuleSection/SubtypeComponent/SubtypeComponent";
import { GeneralConditionSection } from "../GeneralConditionSection/GeneralConditionSection";
import {
  SubTitleSection,
  TitleSection,
  WHITELIST_CONDITIONS,
  WHITELIST_OPERATOR,
} from "../../shared/infrastructure/constants/RuleRequestManagerConstants";

import { ConfiguredConditionsComponent } from "../ConfiguredConditionsComponent/ConfiguredConditionsComponent";
import { ICreateWhiteListSection } from "../../shared/infrastructure/interfaces/ICreateWhiteListSection";
import { Conditions, Operators } from "../../../types/configuration_response";
import { generalStyles } from "../../shared/styles/generalStyles.styles";
import { CommerceTypeCountry } from "../CommerceTypeCountry/CommerceTypeCountry";
import { CatalogData } from "../../../types/catalog_data";

export const CreateWhiteListSection: React.FC<ICreateWhiteListSection> = (
  props: ICreateWhiteListSection
) => {
  const {
    setNodes: { setNodesChange },
    showControllerField,
    showCommerceType,
    subType,
    commerceType,
    whitelistConfiguration,
    setIsItemSelectedWithHierarchy,
    setIsItemSelectedWithoutHierarchy,
    commerceConditions,
    setIsItemCountrySelected,
    countryConditions,
    isRenderConfiguredConditionsComponent,
  } = useCreateRuleSectionState();
  const classes = generalStyles();
  const EMPTY_ARRAY: CatalogData[] = [];

  return (
    <>
      <Grid container spacing={1.5} marginBottom={1}>
        <Grid item xs={12}>
          <SubtypeComponent setNodesChange={setNodesChange} />
        </Grid>
        <CommerceTypeCountry
          showControllerField={showControllerField}
          subType={subType}
          setIsItemCountrySelected={setIsItemCountrySelected}
          commerceType={commerceType}
          showCommerceType={showCommerceType}
          setNodesChange={setNodesChange}
          setIsItemSelectedWithHierarchy={setIsItemSelectedWithHierarchy}
          setIsItemSelectedWithoutHierarchy={setIsItemSelectedWithoutHierarchy}
        />
        <Grid item xs={12}>
          {isRenderConfiguredConditionsComponent && (
            <ConfiguredConditionsComponent
              isCommerce={!showControllerField(defaultTo(subType, ""))}
              countryConditions={countryConditions}
              commerceConditions={commerceConditions}
            />
          )}
        </Grid>
      </Grid>
      <Divider sx={{ height: "0px", border: "1px solid #E2E8F0" }} />
      <Grid container spacing={1.5} marginTop={1} marginBottom={1}>
        <Grid item xs={12}>
          <Typography variant={"h5"}>
            {TitleSection.GENERAL_CONDITION}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.subtitleTypography}>
            {SubTitleSection.GENERAL_CONDITION_SUBTITLE}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          {props.fields.map(
            (
              item: Partial<Record<string, any> & Record<"id", string>>,
              index: number
            ) => (
              <React.Fragment key={item.id}>
                <GeneralConditionSection
                  append={() => {}}
                  country={[]}
                  credentials={[]}
                  generalConditions={get(
                    whitelistConfiguration,
                    "conditions",
                    []
                  ).filter((condition: Conditions) =>
                    WHITELIST_CONDITIONS.includes(get(condition, "field", ""))
                  )}
                  generalOperators={get(
                    whitelistConfiguration,
                    "operators",
                    []
                  ).filter((operator: Operators) =>
                    WHITELIST_OPERATOR.includes(get(operator, "value", ""))
                  )}
                  generalTransactionsType={[]}
                  mccList={EMPTY_ARRAY}
                  merchantCategoryList={EMPTY_ARRAY}
                  geographyList={[]}
                  isLoading={false}
                  item={item}
                  key={item.id}
                  processors={[]}
                  remove={() => {}}
                  total={[]}
                  isWhiteList={true}
                  index={index}
                  isSpecialCond={false}
                />
              </React.Fragment>
            )
          )}
        </Grid>
      </Grid>
    </>
  );
};
