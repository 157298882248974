/**
 * catalog Processor names
 */

import { CountriesEnum } from "./constants/CountriesEnum";
import { ProcessorNameEnum, ProcessorsEnum } from "./constants/ProcessorsEnum";

export type Processor = {
  id: string;
  value: string;
};

export const PROCESSOR_NAMES: Record<string, Processor[]> = {
  [CountriesEnum.BRAZIL]: [
    {
      id: "ALL",
      value: ProcessorsEnum.ALL,
    },
    { id: "P01", value: ProcessorNameEnum.FIS },
  ],
};
