import { useSnackbar } from "@kushki/connect-ui";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { UploadFileMessage } from "../../../../types/upload_file_message";
import { BranchResponse } from "../../../../types/validate_branch_response";
import {
  CREATE_BRANCHS,
  INVALID_BRANCHS,
} from "../../../shared/constants/array_option_validate_data";
import { BRANCH_LABELS } from "../../../shared/constants/labels/branch_container_labels";
import { MODAL_VALIDATE } from "../../../shared/constants/labels/buttons_labels";
import {
  buildNotification,
  defaultDataSnackbar,
} from "../../../shared/constants/snackbar";
import { TIME_OUT_VALUE } from "../../../shared/constants/time_out_value";
import { SessionStoragePaths } from "../../../shared/enums/sessionStoragePaths";
import { NotificationTypeEnum } from "../../../shared/enums/SnackbarEnum";
import { KshFile } from "../../../shared/interfaces/KshFile";
import { IValidateModal } from "../../../shared/interfaces/validateModal.interfaces";
import { setProcessBranchFile } from "../../../store/actions/processBranchFiles.actions";
import { useAppSelector } from "../../../store/hooks/storeHook";
import { processBranchFile } from "../../../store/thunks/processBranchFile/processBranchFile.thunks";
import { IButtonOptions } from "../../FooterOptions/FooterOptions.interfaces";
import { IUseListBranchCreationForm } from "./useListBranchCreation.interfaces";
import { get, isEmpty } from "lodash";
import { FileStatus } from "../../../shared/enums/FileStatus";

export const useListBranchCreation = (): IUseListBranchCreationForm => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const [files, setFiles] = React.useState<KshFile[] | undefined>(undefined);
  const [fileInfo, setFileInfo] = useState<{
    fileName: string;
    fileSize: string;
  }>({
    fileName: "",
    fileSize: "",
  });
  const { showSnackbar } = useSnackbar();

  const [disableValidate, setDisableValidate] = useState<boolean>(true);
  const [isPrepareUpload, setIsPrepareUpload] = useState<boolean>(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [openValidateModal, setOpenValidateModal] = useState(false);
  const [contentHeaderModalValidate, setContentHeaderModalValidate] =
    useState<IValidateModal>({
      hasContent: false,
    });
  const [contentBodyModalValidate, setContentBodyModalValidate] =
    useState<IValidateModal>({
      hasContent: false,
    });
  const [messageError, setMessageError] = useState<UploadFileMessage>({
    advisement: "",
    error: "",
  });
  const [allowContinueBranches, setAllowContinueBranches] =
    useState<boolean>(false);
  const uDispatch = useDispatch();
  const query = useQuery();
  const publicMerchantId: string = query.get("publicMerchantId") as string;
  const { validateBranchFileStatus, summary, branchList, messageValidation } =
    useAppSelector((state) => state.processBranchFile);

  const getFileType = (nameFile: string) => {
    const parts: string[] = nameFile.split(".");

    return parts[parts.length - 1];
  };

  const getFileSize = (fileSize: number) => {
    let fileMB: number = fileSize / 1000048;
    let extension: string = " MB";

    if (fileMB < 0.1) {
      extension = " KB";
      fileMB = fileSize / 1024;
    }

    return fileMB.toFixed(2) + extension;
  };

  const handleCaptureFile = (files: File) => {
    setIsPrepareUpload(true);
    const reader = new FileReader();
    const all_files = files;
    const size: string = getFileSize(all_files.size);

    if (getFileType(all_files.name) === BRANCH_LABELS.VALIDATE_EXTENSION) {
      reader.readAsDataURL(files);
      reader.onload = (): void => {
        const base64_file: string | undefined | null = reader
          .result!.toString()
          .split(",")[1];
        const ksh_file: KshFile = {
          base64: base64_file!,
          extension: `.${getFileType(all_files.name)}`,
          id: all_files.name.replace(/ /g, "_"),
          name: all_files.name,
        };

        setFileInfo({
          fileName: all_files.name,
          fileSize: size,
        });
        setFiles([ksh_file]);
      };
    } else {
      setMessageError({
        advisement: BRANCH_LABELS.VALIDATE_EXTENSION_ERROR_ADVISEMENT,
        error: BRANCH_LABELS.VALIDATE_EXTENSION_ERROR,
      });
    }
  };

  const handleDeleteFileUploaded = () => {
    setFiles([]);
    setMessageError({
      advisement: "",
      error: "",
    });
    setDisableValidate(true);
  };

  const buildRedirect = () => {
    sessionStorage.setItem(SessionStoragePaths.BRANCH_LIST, JSON.stringify([]));
    window.location.href = `/create-node/branch/options?publicMerchantId=${publicMerchantId}`;
  };

  const handleOpenModal = (openModal: boolean) => {
    setOpenLoader(openModal);
  };

  const handleOpenValidateModal = (openModal: boolean) => {
    setOpenValidateModal(openModal);
  };

  const validateFile = () => {
    handleOpenModal(true);
    uDispatch(processBranchFile(get(files, "[0].base64", ""), showSnackbar));
  };

  const primaryButton: IButtonOptions = {
    isDisabled: disableValidate,
    isHidden: true,
    onAction: () => validateFile(),
  };
  const secondaryButton: IButtonOptions = {
    isDisabled: false,
    isHidden: false,
    onAction: () => buildRedirect(),
  };

  const allBranchSuccessModal = () => {
    setContentHeaderModalValidate({
      hasContent: true,
      optionsHeader: [
        {
          error: false,
          name: CREATE_BRANCHS,
          value: get(summary, "total", 0),
        },
      ],
      subtitle: MODAL_VALIDATE.TOTAL_BRANCH(
        get(summary, "total", 0).toString()
      ),
    });
    setContentBodyModalValidate({
      hasContent: false,
    });
  };

  const branchResponseModal = () => {
    setContentHeaderModalValidate({
      hasContent: true,
      optionsHeader: [
        {
          error: false,
          name: CREATE_BRANCHS,
          value: get(summary, "totalSuccess", 0),
        },
        {
          error: true,
          name: INVALID_BRANCHS,
          value: get(summary, "totalError", 0),
        },
      ],
      subtitle: MODAL_VALIDATE.TOTAL_BRANCH(
        get(summary, "total", 0).toString()
      ),
    });
    setAllowContinueBranches(
      get(summary, "totalError", 0) === get(summary, "total", 0)
    );
    const validateModalData: Array<{
      rowNumber: string;
      branchName: string;
      errorMessage: string;
    }> = [];

    for (let i in branchList) {
      if (branchList[i].error) {
        validateModalData.push({
          branchName: branchList[i].branchName,
          errorMessage: branchList[i].errorMessage,
          rowNumber: `Fila: ${i}`,
        });
      }
    }

    setContentBodyModalValidate({
      hasContent: true,
      optionsBody: validateModalData,
    });
  };

  const handleContinueClickModal = () => {
    if (branchList) {
      const branchListSuccess: BranchResponse[] = branchList.filter(
        (item: BranchResponse) => !item.error
      );

      sessionStorage.setItem(
        SessionStoragePaths.BRANCH_LIST,
        JSON.stringify(branchListSuccess)
      );
      window.location.href = `/create-node/branch/batch?publicMerchantId=${publicMerchantId}`;
    }
  };
  const handleCancelClickModal = () => {
    handleOpenValidateModal(false);
    uDispatch(setProcessBranchFile({}));
  };

  useEffect(() => {
    if (!isEmpty(files)) {
      setTimeout(() => {
        setIsPrepareUpload(false);
        setDisableValidate(false);
      }, TIME_OUT_VALUE.MILLISECONDS_UPLOAD_FILE);
    }
  }, [files]);

  useEffect(() => {
    if (validateBranchFileStatus != undefined) {
      handleOpenModal(false);
      switch (validateBranchFileStatus) {
        case FileStatus.success:
          handleOpenValidateModal(true);
          if (get(summary, "totalSuccess") === get(summary, "total")) {
            allBranchSuccessModal();
          } else {
            branchResponseModal();
          }
          break;
        case FileStatus.failed:
          handleDeleteFileUploaded();
          setMessageError({
            advisement: "",
            error: messageValidation,
          });

          break;
        default:
          showSnackbar(
            buildNotification(NotificationTypeEnum.FAILED, defaultDataSnackbar)
          );
      }
    }
  }, [validateBranchFileStatus]);

  return {
    allowContinueBranches,
    contentBodyModalValidate,
    contentHeaderModalValidate,
    fileInfo,
    files,
    footerActions: {
      primaryButton,
      saveLoader: false,
      secondaryButton,
    },
    handleCancelClickModal,
    handleCaptureFile,
    handleContinueClickModal,
    handleDeleteFileUploaded,
    handleOpenValidateModal,
    isPrepareUpload,
    messageError,
    openLoader,
    openValidateModal,
  };
};
