import React from "react";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Search } from "@material-ui/icons";
import { InputAdornment } from "@material-ui/core";
import { useSearchFieldState } from "./state/useSearchFieldState";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiFormControl-root": {
        height: "auto",
      },
      "& > * + *": {
        marginTop: theme.spacing(0),
      },
      "& .MuiInputBase-root": {
        height: "auto",
        width: "auto",
        backgroundColor: "#FFFFFF",
      },
      "& .MuiInputBase-input": {
        width: 250,
        [theme.breakpoints.down(769)]: {
          maxWidth: 250,
        },
      },
      [theme.breakpoints.down(769)]: {
        minWidth: "100%",
        "& .MuiInputBase-input": {
          width: "100%",
        },
      },
    },
  })
);

const SearchField = () => {
  const classes = useStyles();
  const { handleEnterSearchText, handleChangeSearchText, value } =
    useSearchFieldState();

  return (
    <TextField
      id="search-field"
      label={"Buscar por ID de la operación"}
      onKeyDown={(event: any) => {
        handleEnterSearchText(event.key);
      }}
      type="search"
      onChange={(event: any) => {
        handleChangeSearchText(event.target.value);
      }}
      className={classes.root}
      variant="outlined"
      size={"small"}
      value={value}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchField;
