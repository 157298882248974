import { defaultTo, get } from "lodash";
import { MerchantResponse } from "../../types/merchant_response";
import { payload } from "./infrastructure/interfaces/IPayload";
import { timeZoneHour } from "./date-util";

export class auth {
  public static getAuthMerchant(): MerchantResponse {
    return JSON.parse(
      defaultTo(localStorage.getItem("merchantBasicInformation"), "{}")
    );
  }

  public static isAdmin(): boolean {
    return (
      auth.isComplianceUser() ||
      get(JSON.parse(localStorage.getItem("roles")!), "BackofficeAdmin", false)
    );
  }

  public static isComplianceUser(): boolean {
    return localStorage.getItem("isComplianceUser") === "true";
  }

  public static publicMerchantId(): string {
    return get(auth._getPayload(), "preferred_username", "");
  }

  public static getColumnsUser = (): payload => {
    const payload = auth._getPayload();

    return {
      name: get(payload, "name", ""),
      familyName: get(payload, "family_name", ""),
      userName: get(payload, "cognito:username", ""),
      email: get(payload, "email", ""),
      publicMerchantId: get(payload, "merchantId", ""),
      group: get(payload, "cognito:groups", []),
      admin: auth.isAdmin(),
      userMaster: get(
        JSON.parse(localStorage.getItem("roles")!),
        "BackofficeUserMaster",
        false
      ),

      dashboardProfile: get(payload, "profile"),
      timeZone: timeZoneHour(localStorage.getItem("timeZone")!, "utc"),
    };
  };

  private static _getPayload(): object {
    return JSON.parse(defaultTo(localStorage.getItem("payload"), "{}"));
  }
}
