import { TimeZoneCatalog } from "../infraestructure/time-zone-catalogues";
import { get } from "lodash";
import { UtcEnum } from "../infraestructure/utc-enum";
import { utcToZonedTime } from "date-fns-tz";
import { UTC } from "../constants/TimeZoneDefault";
import { TransactionTypeEnum } from "../enums/TransactionTypeEnum";
import { TransactionInfo } from "../../../types/transactions_data";
import { TransactionStatusEnum } from "../enums/TransactionStatusEnum";
import { PaymentMethodUpperEnum } from "../enums/PaymentMethodEnum";

export interface ICatalog {
  description: string;
  region?: string;
  timeZone?: string;
  utc?: string;
}

const _timeZoneDefault: string = "America/Guayaquil";
const timeZoneHour = (text: string | undefined, value: string): string => {
  let index: number;

  if (text === undefined)
    return value === UTC ? UtcEnum.utc_05 : _timeZoneDefault;

  index = TimeZoneCatalog.findIndex(
    (data: ICatalog) => data.description === text
  );

  if (index >= 0) {
    const lang: string | undefined = get(
      TimeZoneCatalog[index],
      value,
      undefined
    );

    return lang !== undefined
      ? lang
      : value === UTC
      ? UtcEnum.utc_05
      : _timeZoneDefault;
  }

  return value === UTC ? UtcEnum.utc_05 : _timeZoneDefault;
};

export const timeZoneRegions = (): string => {
  return timeZoneHour(localStorage.getItem("timeZone")!, "region");
};

export const dateZonedTime = (date: number | string | Date): Date => {
  return utcToZonedTime(new Date(date), timeZoneRegions());
};

export const buildDate = (transactionInfo: TransactionInfo): string => {
  if (
    transactionInfo &&
    transactionInfo.transaction_type === TransactionTypeEnum.SALE &&
    [
      <string>TransactionStatusEnum.APPROVED,
      TransactionStatusEnum.DECLINED,
    ].includes(transactionInfo.transaction_status_type!) &&
    transactionInfo.payment_method_type !== PaymentMethodUpperEnum.CARD
  )
    return get(transactionInfo, "complete_timestamp", 0);

  return get(transactionInfo, "create_timestamp", 0);
};
