import { get } from "lodash";

export enum RolesEnum {
  BackofficeRuleOperator = "BackofficeRuleOperator",
  BackofficeRuleAdmin = "BackofficeRuleAdmin",
}

export const validateRole = (role: RolesEnum): boolean => {
  const roles: object = JSON.parse(localStorage.getItem("roles")!);
  return get(roles, role, false);
};
