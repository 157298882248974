/**
 * CountryEnum
 */
export enum MessageEnum {
  SUCCESS_DELETE_CONFIG_RETAIL = "Se ha eliminado la configuración seleccionada.",
  ERROR_DELETE_CONFIG_RETAIL = "No se ha podido eliminar la configuración seleccionada.",
  ERROR_UPDATE_CATALOG_ITEM = "Ha ocurrido un error inesperado. Consulte con soporte.",
  SUCCESS_UPDATE_CATALOG_ITEM = "Catálogo actualizado correctamente.",
  SUCCESS_DOWNLOAD_CATALOG = "Archivo descargado exitosamente.",
  SUCCESS_CONFIG_RETENTIONS = "Se han configurado los impuestos y retenciones con éxito",
  GENERIC_ERROR = "Ha ocurrido un error inesperado.",
  DOWNLOAD_SUCCESS = "Informe descargado correctamente.",
}
