import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Maximize2 from "../../assets/icons/IconMaximize2";
import { ShowDialogBankColombia } from "../ConfirmModal/ShowDialogBankColombia";
import {
  DispersionStatusValue,
  StatusEnum,
} from "../../shared/infrastructure/StatusEnum";
import { themeOptions } from "../../themeui";

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      display: "flex",
      alignItems: "center",
    },
    contentText: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
    body: {
      marginBottom: "32px",
    },
    span: {
      width: "30px",
      marginLeft: "20px",
      cursor: "pointer",
    },
    chipStateStyle: {
      borderRadius: "4px",
      minWidth: "74px",
      height: "25px",
      fontSize: "12px",
      padding: "4px 16px",
      backgroundColor: (props: DetailBankColombiaProps) =>
        props.value === StatusEnum.FAILED_VALIDATE
          ? themeOptions.palette.background.red
          : themeOptions.palette.primary.light3,
      color: (props: DetailBankColombiaProps) =>
        props.value === StatusEnum.FAILED_VALIDATE
          ? themeOptions.palette.text.red2
          : themeOptions.palette.primary.main,
      fontWeight: "bold",
    },
  })
);

export interface DetailBankColombiaProps {
  value: string;
  obs: string | undefined;
}

export const DetailBankColombia: React.FC<DetailBankColombiaProps> = (
  props: React.PropsWithChildren<DetailBankColombiaProps>
) => {
  const classes = useStyles(props);

  const [open, setOpen] = useState<boolean>(false);

  const expandInfo = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid className={classes.content}>
        <span className={classes.chipStateStyle}>
          {DispersionStatusValue[props.value]}
        </span>
        <span onClick={expandInfo} className={classes.span}>
          <Maximize2 />
        </span>
      </Grid>
      <Grid>
        <ShowDialogBankColombia
          open={open}
          titleText={"Integración con Banco de Bogotá"}
          contentText={
            <>
              <Grid container>
                <Grid item md={4} style={{ marginBottom: "30px" }}>
                  <Typography
                    variant={"h6"}
                    color={"textSecondary"}
                    style={{ fontWeight: "bold" }}
                  >
                    Estado:
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <span className={classes.chipStateStyle}>
                    {DispersionStatusValue[props.value]}
                  </span>
                </Grid>
                <Grid item md={4} style={{ marginBottom: "30px" }}>
                  <Typography
                    variant={"h6"}
                    color={"textSecondary"}
                    style={{ fontWeight: "bold" }}
                  >
                    Detalle:
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <Typography variant={"body2"}>{props.obs}</Typography>
                </Grid>
              </Grid>
            </>
          }
          acceptColor="primary"
          handleClose={handleClose}
          maxWidth={"md"}
          fullWidth={false}
        />
      </Grid>
    </>
  );
};
