import React, { FC } from "react";
import {
  ModalContent,
  ModalContentButtonTypeEnum,
  ModalContentTypeEnum,
} from "@kushki/connect-ui";
import { IModalSimpleProps } from "@components/molecule/ModalInfoExecutives/ModalInfoExecutives.interfaces";
import { Box, Typography } from "@mui/material";
import { modalInfoExecutivesStyles as styles } from "@components/molecule/ModalInfoExecutives/ModalInfoExecutives.styles";
import { ModalInfoLabel } from "@shared/enum/ExecutiveTextEnum";
const ModalInfoExecutives: FC<IModalSimpleProps> = ({
  onOpenCloseModal,
  isOpen,
}: IModalSimpleProps) => {
  return (
    <ModalContent
      onClose={onOpenCloseModal}
      isOpen={isOpen}
      type={ModalContentTypeEnum.DEFAULT}
      buttonType={ModalContentButtonTypeEnum.PRIMARY}
      buttonAction={onOpenCloseModal}
      descriptionText={""}
      titleText={ModalInfoLabel.TITLE}
      buttonText={ModalInfoLabel.BUTTON_TEXT}
      buttonPrimaryDisabled={false}
    >
      <>
        <Typography variant={"body2"}>{ModalInfoLabel.INSTRUCTIONS}</Typography>
        <Box component={"ul"} sx={styles.listWrapper}>
          <Box component={"li"}>
            <Typography variant={"body2"}>{ModalInfoLabel.STEP_1}</Typography>
          </Box>
          <Box component={"li"}>
            <Typography variant={"body2"}>{ModalInfoLabel.STEP_2}</Typography>
          </Box>
          <Box component={"li"}>
            <Typography variant={"body2"}>
              {ModalInfoLabel.STEP_3}
              <Box component={"span"} sx={styles.textUnderline}>
                {ModalInfoLabel.EMAIL_EXAMPLE}
              </Box>
            </Typography>
          </Box>
          <Box component={"li"}>
            <Typography variant={"body2"}>{ModalInfoLabel.STEP_4}</Typography>
          </Box>
        </Box>
      </>
    </ModalContent>
  );
};

export default ModalInfoExecutives;
