import React, { FC } from "react";
import { Button, Grid, Typography } from "@mui/material";
import {
  CheckoutContainerLabels,
  LabelButton,
} from "../../shared/labels/CheckoutContainer.labels";
import { CheckoutStepper } from "../../components/CheckoutStepper/CheckoutStepper";
import { checkoutContainerStyles as styles } from "./CheckoutContainerStyles";
import { StepperEnum } from "../../shared/infrastructure/StepperEnum";
import { useCheckoutContainer } from "./state/useCheckoutContainer";

export const CheckoutContainer: FC = () => {
  const { renderContent, handleStepInit, isMobile, step } =
    useCheckoutContainer();

  return (
    <Grid container sx={isMobile ? styles.mainMobile : styles.main}>
      <Grid container spacing={2} sx={styles.contentExpressCheckout}>
        <Grid
          item
          md={12}
          sm={12}
          sx={isMobile ? styles.textAlignCenter : styles.textAlignJustify}
        >
          <Typography variant={"h2"} color={"primary"}>
            {CheckoutContainerLabels.MAIN_TITLE}
          </Typography>
        </Grid>
        <Grid item md={12} sm={12}>
          <Grid container>
            <Grid item md={2} sm={12}>
              <CheckoutStepper />
            </Grid>
            <Grid
              item
              sm={12}
              md={10}
              sx={isMobile ? styles.contentMobile : styles.contentWeb}
            >
              {renderContent(step)}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={12}
          sm={12}
          sx={isMobile ? styles.groupButtonMobile : styles.groupButtonWeb}
        >
          {step === StepperEnum.CODE_QR && (
            <Button
              variant="contained"
              color="primary"
              sx={styles.button}
              onClick={handleStepInit}
            >
              {LabelButton.BUTTON_INICIO}
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
