import { ICellAlign } from "../../TableCell/CellRow/CellRow.interfaces";

enum JustifyContentEnum {
  CENTER = "center",
  LEFT = "flex-start",
  RIGHT = "flex-end",
}

enum TextAlingEnum {
  CENTER = "-webkit-center",
  LEFT = "-webkit-left",
  RIGHT = "-webkit-right",
}

const FLEX_JUSTIFY_BY_ALIGN: Record<ICellAlign, JustifyContentEnum> = {
  center: JustifyContentEnum.CENTER,
  justify: JustifyContentEnum.CENTER,
  left: JustifyContentEnum.LEFT,
  right: JustifyContentEnum.RIGHT,
};

const FLEX_TEXT_BY_ALIGN: Record<ICellAlign, TextAlingEnum> = {
  center: TextAlingEnum.CENTER,
  justify: TextAlingEnum.CENTER,
  left: TextAlingEnum.LEFT,
  right: TextAlingEnum.RIGHT,
};

export { FLEX_JUSTIFY_BY_ALIGN, FLEX_TEXT_BY_ALIGN };
