import { IGenericBreadcrumbProps } from "@components/GenericBreadcrumb/GenericBreadcrumbs.interfaces";
import {
  breadcrumbLabelEnum,
  pathEnum,
} from "@shared/constants/CatalogBreadcrumb";
import { ExecutiveTextEnum } from "@shared/enum/ExecutiveTextEnum";
import { IUseExecutives } from "@containers/Executives/state/useExecutives.interfaces";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/hooks/storeHook";
import {
  downloadExecutiveTemplate,
  getExecutiveTemplate,
  uploadCommercialExecutive,
} from "@store/thunks/executives/executives.thunk";
import { RootState } from "@store/store";
import { get, isNumber } from "lodash";
import { formatTimestampWithTimezone } from "@shared/utils/parseDatesUtils";
import { DateFormatEnum } from "@shared/constants/parseMonths";
import { VerifyIfComponentEnable } from "@kushki/security-wrapper";
import { SecurityWrapperRoles } from "@shared/enum/SecurityWrapperRoles";
import { setIsOpenUploadCommercialExecutivesModal } from "@store/actions/executives.actions";
import { getUserName } from "@shared/utils/userUtils";

export const useExecutives = (): IUseExecutives => {
  const dispatch = useAppDispatch();
  const {
    isLoadingDownloadExecutives,
    executiveConfigResponse,
    isOpenUploadCommercialExecutivesModal,
    isLoadingUploadCommercialExecutives,
    base64File,
    isUploadedSuccessfully,
  } = useAppSelector((state: RootState) => state.executives);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState<boolean>(false);

  const verifyContinueValidFile: boolean = VerifyIfComponentEnable(
    SecurityWrapperRoles.M_COMPLIANCE_COMMERCIAL_EXECUTIVE_CONTINUE_VALID_FILE
  );

  const getBreadcrumbItems = (): IGenericBreadcrumbProps => {
    return {
      breadcrumbItems: [
        { label: breadcrumbLabelEnum.DASHBOARD_LABEL, url: pathEnum.DASHBOARD },
      ],
      lastItem: ExecutiveTextEnum.BREADCRUMB_TITLE,
    };
  };

  const handleOpenCloseModal = () => {
    setIsOpenModalInfo((prevState) => !prevState);
  };

  const handleLastUpdate = (): string => {
    const updated: number | undefined = get(
      executiveConfigResponse,
      "lastDate"
    );

    return isNumber(updated)
      ? formatTimestampWithTimezone(updated, DateFormatEnum.ddmmyyyy)
      : "";
  };

  const handleDownloadExecutives = () => {
    dispatch(
      downloadExecutiveTemplate({
        file: get(executiveConfigResponse, "file", ""),
      })
    );
  };

  useEffect(() => {
    dispatch(getExecutiveTemplate());
  }, []);

  const closeValidFileModal = () => {
    if (isLoadingUploadCommercialExecutives) return;

    dispatch(setIsOpenUploadCommercialExecutivesModal(false));
  };

  const continueUploadExecutives = () => {
    dispatch(
      uploadCommercialExecutive({
        file: base64File,
        username: getUserName(),
      })
    );
  };

  return {
    getBreadcrumbItems,
    handleOpenCloseModal,
    isOpenModalInfo,
    lastUpdatedExecutive: handleLastUpdate(),
    templateExecutivesProps: {
      handleDownloadExecutives,
      isLoadingDownloadExecutives,
    },
    usernameExecutive: get(executiveConfigResponse, "username", ""),
    validFileModal: {
      closeValidFileModal,
      continueUploadExecutives,
      isContinueDisabled:
        !verifyContinueValidFile || isLoadingUploadCommercialExecutives,
      isLoadingUploadCommercialExecutives,
      isOpenValidFileModal: isOpenUploadCommercialExecutivesModal,
      isUploadedSuccessfully,
    },
  };
};
