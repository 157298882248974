export const TOOLTIP_MESSAGES = {
  REFUND_COMPLETED:
    "Esta transacción no cuenta con alguna acción disponible, debido a que el certificado ha sido emitido exitosamente.",
  REFUND_DECLINED:
    "Esta transacción no cuenta con alguna acción disponible, debido a que no se encuentra como aprobada.",
  REFUND_PENDING:
    "Esta transacción no cuenta con alguna acción disponible, debido a que ya existe una solicitud de reembolso en proceso.",
  TRANSACTION_COMPLETED:
    "Esta transacción no cuenta con alguna acción disponible, debido a que el certificado ha sido emitido exitosamente.",
};
