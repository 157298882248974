/**
 * Country Enumeration
 */

export enum CountryEnum {
  ecuador = "Ecuador",
  peru = "Peru",
  colombia = "Colombia",
  chile = "Chile",
  mexico = "Mexico",
}

export enum CountryNameEnum {
  ecuador = "Ecuador",
  peru = "Perú",
  colombia = "Colombia",
  chile = "Chile",
  mexico = "México",
}

export const COUNTRY_LIST: string[] = [
  CountryEnum.ecuador,
  CountryEnum.peru,
  CountryEnum.colombia,
  CountryEnum.chile,
  CountryEnum.mexico,
];
