import { IAppState, StatusStepsState } from "./reducer";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { IAuthInfo } from "../shared/infrastructure/interfaces/IAuthInfo";
import { auth } from "../shared/auth";
import { ActionTypes } from "./actionTypes";
import { INotification } from "../shared/infrastructure/interfaces/INotification";
import {
  getStepProcessorsStatus,
  InitState,
  navigateRoute,
  PaymentMethodEnum,
  StatusSemaphore,
  StepName,
  StepsEnum,
} from "../shared/infrastructure/constants/CreateMerchantConstants";
import { AffiliationLead } from "../../types/affiliation_lead";
import { environment } from "../environments/environment";
import { MerchantCreateUpdateResponse } from "../../types/merchant_create_update_response";
import { SemaphoreData, StatusSteps } from "../../types/remote/semaphore_data";
import { CREATE_MERCHANT_SECTIONS } from "../components/CreateMerchantComponents/MerchantDetailsAllSteps/MerchantDetailsAllSteps";
import { CREATE_MERCHANT_ADDITIONAL_SECTIONS } from "../components/CreateMerchantComponents/MerchantDetailsAdditionalSteps/MerchantDetailsAdditionalSteps";
import { get, isEmpty, orderBy, filter, values, defaultTo } from "lodash";
import axios, { observableInstance } from "../shared/axios-util";
import { AxiosResponse } from "axios";
import { forkJoin, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { MerchantUsers, User } from "../../types/merchant_users";
import { Credential } from "../shared/infrastructure/interfaces/Credential";
import { Webhook } from "../shared/infrastructure/interfaces/Webhook";
import { DiscountResponse } from "../../types/discount_response";
import { RateRequest } from "../../types/rate_request";
import { RateResponse } from "../../types/rate_response";
import { RatesConfigDynamo } from "../../types/rates_config_dynamo";
import { AccountPreferencesResponse } from "../../types/account_preferences_response";
import { MerchantProcessors } from "../../types/merchant_processors";
import { UpdateMerchantRulesRequest } from "../../types/update_merchant_rules_request";
import { DeferredOption } from "../shared/infrastructure/interfaces/DeferredOptions";
import { MerchantProcessorsRules } from "../../types/merchant_processors_rules_response";
import { Color } from "@material-ui/lab/Alert";
import { MerchantActiveNotificationRequest } from "../../types/merchant_active_notification_request";
import { SearchResponse } from "../../types/search_response";
import moment from "moment";
import { CountryEnum } from "../shared/infrastructure/catalogs/CountryEnum";
import { DispersionConfigDynamo } from "../../types/dispersion_config_dynamo";
import { StatusSemaphoreEnum } from "../shared/infrastructure/catalogs/StatusSemaphoreEnum";
import { StepSemaphoreEnum } from "../shared/infrastructure/catalogs/StepSemaphoreEnum";
import { routes } from "../shared/infrastructure/routes";
import { GetAppConfigPaymentMethodsResponse } from "../../types/get_app_config_payment_method_response";
import { ActiveMerchantRequest } from "../../types/active_merchant_request";
import { SubStepStatusEnum } from "../shared/infrastructure/constants/SubStepStatusEnum";

export type IAppAction = { type: string } & IAppState;

export interface IProcessorsType {
  card: MerchantProcessors[];
  cash: MerchantProcessors[];
  cashPayout: MerchantProcessors[];
  transfer: MerchantProcessors[];
  transferPayout: MerchantProcessors[];
  transferSubscriptions: MerchantProcessors[];
  ach: MerchantProcessors[];
}

export interface IDefaultProcessors {
  card: string;
  transfer: string;
  ach: string;
  transferPayout: string;
  payoutsTransfer?: string;
}

export const setAuthInfo = (payload: IAuthInfo): IAppAction => ({
  type: ActionTypes.SET_AUTH_INFO,
  authInfo: payload,
});

export const setMerchantUsers = (payload: MerchantUsers): IAppAction => ({
  type: ActionTypes.SET_MERCHANT_USERS,
  merchantUsers: payload,
});

const setDeferred = (payload: DeferredOption[]): IAppAction => ({
  type: ActionTypes.SET_DEFERRED,
  deferred: payload,
});

const showUnexpectedError = (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>,
  message: string = "Ha ocurrido un error inesperado"
) =>
  dispatch(
    setNotification({
      message,
      open: true,
      type: "error",
    })
  );
export const setProcessorsRules = (
  payload: MerchantProcessorsRules
): IAppAction => ({
  type: ActionTypes.SET_PROCESSORS_RULES,
  processorRules: payload,
});

export const setIsEdit = (payload: boolean) => ({
  type: ActionTypes.SET_IS_EDIT,
  isEdit: payload,
});

export const setNotification = (payload: INotification): IAppAction => ({
  type: ActionTypes.SET_NOTIFICATION,
  notification: payload,
});

export const setAffiliationLeads = (affiliationLeads: AffiliationLead[]) => ({
  type: ActionTypes.SET_AFFILIATION_LEADS,
  affiliationLeads: affiliationLeads,
});

export const setMerchantCredentials = (credentials: Credential[]) => ({
  type: ActionTypes.SET_MERCHANT_CREDENTIALS,
  merchantCredentials: credentials,
});

export const setMerchantWebhooks = (webhooks: Webhook[]) => ({
  type: ActionTypes.SET_MERCHANT_WEBHOOKS,
  merchantWebhooks: webhooks,
});

export const setMerchantAffiliation = (
  merchantResponse: MerchantCreateUpdateResponse
) => ({
  type: ActionTypes.SET_MERCHANT_AFFILIATION,
  merchantAffiliation: merchantResponse,
});

export const setMerchantServices = (merchantServicesResponse: object) => ({
  type: ActionTypes.SET_MERCHANT_SERVICES,
  merchantServices: merchantServicesResponse,
});

export const setRates = (ratesResponse: RatesConfigDynamo[]): IAppAction => ({
  type: ActionTypes.SET_RATES,
  rates: ratesResponse,
});

export const setAccountPreferences = (
  accountPreferencesResponse: AccountPreferencesResponse
): IAppAction => ({
  type: ActionTypes.SET_ACCOUNT_PREFERENCES,
  accountPreferences: accountPreferencesResponse,
});

const setLoadingDefaultProcessors = (value: boolean): IAppAction => ({
  type: ActionTypes.SET_LOADING_DEFAULT_PROCESSORS,
  loadingDefaultProcessors: value,
});

export const showLoading = (): IAppAction => ({
  type: ActionTypes.SHOW_LOADING,
});

const setLoadingFailover = (value: boolean): IAppAction => ({
  type: ActionTypes.SET_LOADING_FAILOVER,
  loadingFailover: value,
});

export const hideLoading = (): IAppAction => ({
  type: ActionTypes.HIDE_LOADING,
});

export const setAppConfigPaymentMethods = (
  payload: GetAppConfigPaymentMethodsResponse
): IAppAction => {
  return {
    type: ActionTypes.SET_APPCONFIG_PAYMENT_METHODS,
    appConfigPaymentMethods: payload,
  };
};

export const getAffiliationLeads = (): ThunkAction<
  void,
  IAppState,
  undefined,
  IAppAction
> => (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
  axios
    .get<AffiliationLead[]>(
      `${environment.kushkiUrl}/affiliations/v1/activeLeads`
    )
    .then((response: AxiosResponse<AffiliationLead[]>) => {
      if (response.status !== 201) return;
      dispatch(setAffiliationLeads(response.data));
    })
    .catch((_err) => showUnexpectedError(dispatch));
};

export const getMerchantCredentials = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): void => {
  const URL = `${environment.kushkiUrl}/payment-credentials/v1/admin/credentials/${merchantId}`;
  axios
    .get<Credential[]>(URL)
    .then((response) => {
      if (response.status !== 200) return;

      let credentials: Credential[] = orderBy(
        get(response, "data", []).filter(
          (credential: Credential) => get(credential, "deleteAt", 0) === 0
        ),
        ["enable", "created"],
        ["desc", "desc"]
      );
      credentials = credentials.slice(0, 5);
      dispatch(setMerchantCredentials(credentials));
    })
    .catch((error) => {
      console.error(error);
      showUnexpectedError(dispatch);
    });
};

export const getMerchantWebhooks = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): void => {
  const URL = `${environment.kushkiUrl}/webhook/v1/list`;
  axios
    .post<{ items: Webhook[] }>(URL, { limit: 5, merchantId: merchantId })
    .then((response) => {
      if (response.status !== 200) return;

      let webhooks: Webhook[] = get(response, "data.items", []);
      dispatch(setMerchantWebhooks(webhooks));
    })
    .catch((error) => {
      console.error(error);
      showUnexpectedError(dispatch);
    });
};

export const createMerchantAffiliation = (
  merchantRequest: MerchantCreateUpdateResponse
): ThunkAction<void, IAppState, undefined, IAppAction> => (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): void => {
  const api_url = environment.kushkiUrl;
  let payload = JSON.parse(localStorage.getItem("payload")!);
  dispatch(showLoading());
  const data: MerchantCreateUpdateResponse = {
    country: merchantRequest.country,
    name: merchantRequest.name,
    merchantLead: merchantRequest.merchantLead,
    isAffiliation: merchantRequest.isAffiliation,
    publicMerchantId: merchantRequest.publicMerchantId,
    userName: `${get(payload, "name")} ${get(payload, "family_name")}`,
    userAlias: get(payload, "cognito:username"),
  };
  if (
    merchantRequest.authorizerRuleRequest &&
    Object.keys(merchantRequest.authorizerRuleRequest).length > 0
  )
    data.authorizerRuleRequest = merchantRequest.authorizerRuleRequest;

  if (!get(data, "merchantLead.merchantInfo.contactPerson")) {
    data.merchantLead!["merchantInfo"]["contactPerson"] = "N/A";
  }
  axios
    .post<MerchantCreateUpdateResponse>(
      `${api_url}/billing-core/v1/merchant/createUpdate`,
      data
    )
    .then((response: AxiosResponse<MerchantCreateUpdateResponse>) => {
      dispatch(hideLoading());
      if (response.status !== 201) return;
      dispatch(
        setMerchantAffiliation({
          ...merchantRequest,
          publicMerchantId: response.data.publicMerchantId,
          status: response.data.status,
        })
      );
    })
    .catch((_err) => {
      dispatch(hideLoading());
      showUnexpectedError(dispatch);
    });
};

export const getRates = (
  publicMerchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): void => {
  const api_url = environment.kushkiUrl;
  axios
    .post<RatesConfigDynamo[]>(`${api_url}/rates/ratesConfigs`, {
      publicMerchantId,
    })
    .then((response: AxiosResponse<RatesConfigDynamo[]>) => {
      if (response.status !== 200) return;
      dispatch(setRates(response.data));
    })
    .catch((_err) => {
      showUnexpectedError(dispatch);
    });
};

export const activateServices = (
  stepName: StepName,
  status: StatusSteps,
  publicMerchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): Promise<void> => {
  const api_url = environment.kushkiUrl;
  const getPrivateMerchantId = await axios.get<AxiosResponse>(
    `${api_url}/webhook/v1/admin/signature/${publicMerchantId}`
  );
  const privateMerchantId = get(getPrivateMerchantId, "data.webhookSignature");

  const activateBoxCards = () => {
    return axios.patch<AxiosResponse>(
      `${api_url}/card/v1/admin/merchant/${publicMerchantId}`,
      {
        whiteList: true,
        sift_science: {
          SandboxApiKey: "",
          ProdApiKey: "",
          SandboxAccountId: "",
          ProdAccountId: "",
          BaconProdApiKey: "",
          BaconSandboxApiKey: "",
        },
        commission: false,
        acceptCreditCards: ["visa", "masterCard"],
        sandboxEnable: false,
      }
    );
  };

  const activateSmartLinks = () => {
    return axios.patch<AxiosResponse>(
      `${api_url}/smartlink/v1/admin/merchant/${publicMerchantId}`,
      { status: true, privateMerchantId: privateMerchantId }
    );
  };

  Promise.all([activateBoxCards(), activateSmartLinks()])
    .then(() => {
      getMerchantservices(publicMerchantId, dispatch);
      dispatch(updateStepStatus(stepName, status, publicMerchantId));
    })
    .catch(() => {
      showUnexpectedError(dispatch);
    });
};

export const getAuthInfo = (): ThunkAction<
  void,
  IAppState,
  undefined,
  IAppAction
> => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): Promise<void> => {
  const authInfo: IAuthInfo = {
    isAdmin: auth.isAdmin(),
  };
  dispatch(setAuthInfo(authInfo));
};

export const setStatusSection = (payload: StatusStepsState[]): IAppAction => ({
  type: ActionTypes.SET_STEPS_STATUS,
  steps: payload,
});

export const updateMultipleChildrenStatus = (
  publicMerchantId: string,
  stepName: StepName,
  childSteps: { childStepName: string; newStatus: StatusSteps }[]
): ThunkAction<void, IAppState, undefined, IAppAction> => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>,
  getState: () => IAppState
): Promise<void> => {
  try {
    const { semaphore, statusProcessor }: IAppState = getState();
    let newSemaphore: SemaphoreData = {
      ...semaphore,
      isNew: false,
      publicMerchantId,
      stepConfigRatesAndInvoice: {
        ...semaphore?.stepConfigRatesAndInvoice,
        processors: statusProcessor,
      },
    };
    dispatch(
      setStepStatus(
        StepSemaphoreEnum.stepConfigRatesAndInvoice,
        setSemaphoreRatesAndInvoice(newSemaphore)
      )
    );
    if (!childSteps) return;
    childSteps.forEach(
      (child: { childStepName: string; newStatus: StatusSteps }) =>
        (newSemaphore[stepName]![child.childStepName] = child.newStatus)
    );
    const semaphoreCreateUpdateURL: string = `${environment.kushkiUrl}/billing-core/v1/semaphore/createUpdate`;
    await axios.post(semaphoreCreateUpdateURL, { ...newSemaphore });
    dispatch(setSemaphoreData(newSemaphore));
  } catch (e) {
    showUnexpectedError(dispatch);
  }
};

const setStatusInvoice = (statusProcessor: object) => {
  if (
    values(statusProcessor).some(
      (processor) => processor === StatusSemaphoreEnum.PENDING
    )
  ) {
    return StatusSemaphoreEnum.IN_PROCESS;
  } else if (
    values(statusProcessor).every(
      (processor) => processor === StatusSemaphoreEnum.OMITTED
    )
  ) {
    return StatusSemaphoreEnum.PENDING;
  } else {
    return StatusSemaphoreEnum.COMPLETE;
  }
};

export const updateStepStatus = (
  stepName: StepName,
  newStatus: StatusSteps,
  publicMerchantId: string,
  subStepName?: string
): ThunkAction<void, IAppState, undefined, IAppAction> => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>,
  getState: () => IAppState
): Promise<void> => {
  const { steps = [], semaphore, statusProcessor }: IAppState = getState();
  const semaphoreData: SemaphoreData = getSemaphoreUpdateData(
    stepName,
    newStatus,
    publicMerchantId,
    semaphore!,
    subStepName
  );
  const semaphoreCreateUpdateURL: string = `${environment.kushkiUrl}/billing-core/v1/semaphore/createUpdate`;
  const payload: SemaphoreData = {
    ...semaphoreData,
    stepConfigRatesAndInvoice: {
      ...semaphoreData.stepConfigRatesAndInvoice,
      processors: statusProcessor,
    },
  };
  dispatch(
    setStepStatus(
      StepSemaphoreEnum.stepConfigRatesAndInvoice,
      setSemaphoreRatesAndInvoice(payload)
    )
  );
  axios
    .post(semaphoreCreateUpdateURL, payload)
    .then((response: AxiosResponse<SemaphoreData>) => {
      if (response.status !== 201) return;

      const requiredSteps = Object.keys(CREATE_MERCHANT_SECTIONS);
      const additionalSteps = Object.keys(CREATE_MERCHANT_ADDITIONAL_SECTIONS);

      const newSteps = requiredSteps
        .concat(additionalSteps)
        .reduce((accum, stepFromList) => {
          const stepFromState = steps.find(
            (stepFromState) => stepFromState.name === stepFromList
          );
          if (stepFromList === stepName) {
            let status: StatusSteps = newStatus;

            switch (stepName) {
              case StepSemaphoreEnum.stepProcessor:
                status = getStepProcessorsStatus(response.data);
                break;
              case StepSemaphoreEnum.stepConfigRatesAndInvoice:
                if (
                  subStepName &&
                  subStepName === "statusDiscount" &&
                  newStatus === StatusSemaphore.omitted
                ) {
                  status = StatusSemaphore.inProcess;
                } else if (
                  subStepName &&
                  subStepName === "statusWallet" &&
                  newStatus === StatusSemaphore.omitted
                ) {
                  status = StatusSemaphore.inProcess;
                } else {
                  status = newStatus;
                }
                break;
              default:
                status = newStatus;
            }
            accum.push({
              name: stepName as StepsEnum,
              status,
            });
          } else {
            accum.push(stepFromState!);
          }

          return accum;
        }, [] as StatusStepsState[]);

      dispatch(setSemaphoreData(semaphoreData));
      dispatch(setStatusSection(newSteps));

      if (
        (newStatus === StatusSemaphore.pending ||
          newStatus === StatusSemaphore.complete) &&
        subStepName === "statusWallet"
      )
        navigateRoute(
          `${routes.BILLING_MERCHANT}${routes.WALLET}/${publicMerchantId}&hideSideBar=true`
        );
    })
    .catch(() => showUnexpectedError(dispatch));
};

const getSemaphoreUpdateData = (
  stepName: StepName,
  newStatus: StatusSteps,
  publicMerchantId: string,
  semaphoreData: SemaphoreData,
  subStepStatus?: string
) => {
  let semaphore: SemaphoreData = {
    ...semaphoreData,
    isNew: false,
    publicMerchantId,
  };
  if (stepName === StepsEnum.stepServices)
    semaphore.stepServices = { status: newStatus };
  if (
    stepName === StepsEnum.stepConfigRatesAndInvoice &&
    defaultTo(subStepStatus, "") !== SubStepStatusEnum.statusWallet
  )
    semaphore.stepConfigRatesAndInvoice = {
      ...semaphore.stepConfigRatesAndInvoice,
      statusDiscount: newStatus,
    };

  if (!subStepStatus) return semaphore;

  semaphore[stepName]![subStepStatus] = newStatus;

  return semaphore;
};

export const setSemaphoreData = (payload: SemaphoreData): IAppAction => ({
  type: ActionTypes.SET_SEMAPHORE_DATA,
  semaphore: payload,
});

const setActionDefaultProcessors = (defaultProcessor: IDefaultProcessors) => ({
  type: ActionTypes.SET_DEFAULT_PROCESSOR,
  defaultProcessor: defaultProcessor,
});

export const setDefaultPayoutsTransferProcessor = (
  payload: IDefaultProcessors
): IAppAction => {
  return {
    type: ActionTypes.SET_DEFAULT_PAYOUTS_TRANSFER_PROCESSOR,
    defaultPayoutTransferProcessor: payload,
  };
};

const setDefaultProcessor = (payload: object): IAppAction => {
  const defaultProcessors: IDefaultProcessors = {
    card: get(payload, "defaultProcessor.card", ""),
    transfer: get(payload, "defaultProcessor.transfer", ""),
    ach: get(payload, "defaultProcessor.achTransfer", ""),
    transferPayout: get(payload, "defaultProcessor.payoutsTransfer", ""),
  };

  return setActionDefaultProcessors(defaultProcessors);
};

const getDefaultProcessor = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): Promise<void> => {
  const url: string = `${environment.kushkiUrl}/rules/v1/admin/merchant/${merchantId}`;
  try {
    const axios_response = await axios.get<AxiosResponse>(url);
    const response: object = get(axios_response, "data");
    dispatch(setDefaultProcessor(response));
  } catch (_) {
    showUnexpectedError(dispatch);
  }
};

export const getDefaultPayoutsTransferProcessor = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): Promise<void> => {
  const url: string = `${environment.kushkiUrl}/payouts-rules/v1/admin/merchant/${merchantId}`;

  try {
    const axios_response = await axios.get<AxiosResponse>(url);
    const response: IDefaultProcessors | undefined = get(
      axios_response,
      "data.defaultProcessor"
    );

    if (response) dispatch(setDefaultPayoutsTransferProcessor(response));
  } catch (_) {
    showUnexpectedError(dispatch);
  }
};

export const saveFailoverProcessor = (
  merchantId: string,
  publicProcessorId: string,
  failover: {
    failoverAlias: string;
  }
): ThunkAction<void, IAppState, undefined, IAppAction> => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>,
  getState: () => IAppState
): Promise<void> => {
  try {
    const { processors, defaultProcessor }: IAppState = getState();
    if (!processors) return;

    get(processors, "card", []).find(
      (processor: MerchantProcessors) =>
        processor.publicProcessorId === publicProcessorId
    )!.failOverProcessor = failover.failoverAlias;

    dispatch(setLoadingFailover(true));
    const url: string = `${environment.kushkiUrl}/rules/v1/admin/processor/${merchantId}/${publicProcessorId}`;

    await axios.patch(url, failover);
    dispatch(setProcessorsStore(processors));
    dispatch(setActionDefaultProcessors({ ...defaultProcessor! }));
    dispatch(setLoadingFailover(false));
  } catch (_) {
    showUnexpectedError(dispatch);
    const { processors }: IAppState = getState();
    if (!processors) return;

    dispatch(setProcessorsStore(processors));
  }
};

export const saveDefaultProcessor = (
  payload: UpdateMerchantRulesRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): Promise<void> => {
  try {
    dispatch(setLoadingDefaultProcessors(true));
    const url: string = `${environment.kushkiUrl}/rules/v1/admin/merchant/${payload.publicMerchantId}`;

    await axios.patch(url, payload);
    dispatch(setDefaultProcessor(payload));
    dispatch(setLoadingDefaultProcessors(false));
  } catch (_) {
    showUnexpectedError(dispatch);
    dispatch(setLoadingDefaultProcessors(false));
  }
};

export const saveDefaultPayoutsTransferProcessor = (
  payload: UpdateMerchantRulesRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): Promise<void> => {
  try {
    dispatch(setLoadingDefaultProcessors(true));
    const url: string = `${environment.kushkiUrl}/payouts-rules/v1/admin/merchant/${payload.publicMerchantId}`;

    await axios.post(url, payload);
    dispatch(getDefaultPayoutsTransferProcessor(payload.publicMerchantId));
    dispatch(setLoadingDefaultProcessors(false));
  } catch (_) {
    showUnexpectedError(dispatch);
    dispatch(setLoadingDefaultProcessors(false));
  }
};

const setDeferredSwitch = (payload: boolean): IAppAction => ({
  type: ActionTypes.SET_DEFERREDSIWCHT,
  deferredSwitch: payload,
});

const handlePeruDeferred = async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>,
  merchantId: string
) => {
  const response: AxiosResponse<any> = await axios.get(
    `${environment.kushkiUrl}/card/v1/admin/deferred/${merchantId}`
  );
  dispatch(setDeferredSwitch(Boolean(response.data.deferredOptions)));
};

const handleGenericDeferred = async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>,
  merchantId: string,
  semaphore: SemaphoreData | undefined
) => {
  const response: AxiosResponse<any> = await axios.get(
    `${environment.kushkiUrl}/deferred/v1/admin/deferred/${merchantId}`
  );
  const statusDeferredKey: string = "statusDeferred";

  const deferredStatus: string = get(
    semaphore,
    `${StepsEnum.stepProcessor}.${statusDeferredKey}`,
    StatusSemaphore.omitted
  );

  const isNotOmittedNorPending: boolean =
    deferredStatus !== StatusSemaphore.omitted &&
    deferredStatus !== StatusSemaphore.pending;

  const isDeferredEmpty: boolean = isEmpty(response.data.deferredOptions);

  const isCompleted: boolean = deferredStatus === StatusSemaphore.complete;

  if (!isDeferredEmpty) {
    dispatch(setDeferred(response.data.deferredOptions));

    if (!isCompleted) {
      dispatch(
        updateMultipleChildrenStatus(merchantId, StepsEnum.stepProcessor, [
          {
            childStepName: statusDeferredKey,
            newStatus: StatusSemaphore.complete,
          },
        ])
      );
    }
  }

  if (isDeferredEmpty && isNotOmittedNorPending) {
    dispatch(
      updateMultipleChildrenStatus(merchantId, StepsEnum.stepProcessor, [
        {
          childStepName: statusDeferredKey,
          newStatus: StatusSemaphore.pending,
        },
      ])
    );
  }
};

export const getDeferred = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>,
  getState: () => IAppState
): Promise<void> => {
  try {
    const {
      semaphore,
      merchantAffiliation,
      processors,
    }: IAppState = getState();
    const isDeferredForPeru: boolean =
      get(merchantAffiliation, "country", "") === CountryEnum.PERU &&
      !isEmpty(get(processors, "card", []));

    if (isDeferredForPeru) await handlePeruDeferred(dispatch, merchantId);
    else await handleGenericDeferred(dispatch, merchantId, semaphore);
  } catch (e) {
    dispatch(setDeferred([]));
    showUnexpectedError(dispatch);
  }
};

export const activateMerchant = (
  publicMerchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): Promise<void> => {
  const payload: ActiveMerchantRequest = { state: InitState.ACTIVE };

  try {
    await axios.patch(
      `${environment.kushkiUrl}/merchant/v1/admin/merchant/${publicMerchantId}/status`,
      payload
    );
    dispatch(
      setNotification({
        message: "Activated",
        open: false,
        type: "success",
      })
    );
  } catch (e) {
    dispatch(
      setNotification({
        message: "Activation failed",
        open: false,
        type: "error",
      })
    );
    showUnexpectedError(dispatch);
  }
};

export const notifyMerchant = (
  merchantData: MerchantActiveNotificationRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): Promise<void> => {
  try {
    await axios.post(
      `${environment.kushkiUrl}/billing-core/v1/notify/merchantActive`,
      {
        ...merchantData,
      }
    );
    dispatch(
      setNotification({
        message: "Se envió la notificación al comercio.",
        open: true,
        type: "black" as Color,
      })
    );
  } catch (e) {
    showUnexpectedError(dispatch);
  }
};

const setProcessors = (payload: MerchantProcessors[]) => {
  const processorsByType: IProcessorsType = {
    card: filterProcessorByPaymentType(payload, PaymentMethodEnum.CARD),
    cash: filterProcessorByPaymentType(payload, PaymentMethodEnum.CASH),
    cashPayout: filterProcessorByPaymentType(
      payload,
      PaymentMethodEnum.CASH_PAYOUT
    ),
    transfer: filterProcessorByPaymentType(payload, PaymentMethodEnum.TRANSFER),
    transferPayout: filterProcessorByPaymentType(
      payload,
      PaymentMethodEnum.TRANSFER_PAYOUT
    ),
    ach: filterProcessorByPaymentType(payload, PaymentMethodEnum.ACH_TRANSFER),
    transferSubscriptions: filterProcessorByPaymentType(
      payload,
      PaymentMethodEnum.TRANSFER_SUBSCRIPTION
    ),
  };

  return setProcessorsStore(processorsByType);
};

const setProcessorsStore = (processors: IProcessorsType) => ({
  type: ActionTypes.SET_PROCESSORS,
  processors: {
    ...processors,
    ach: [...processors.transferSubscriptions, ...processors.ach],
  },
});

export const setStatusProcessors = (statusProcessor: object) => ({
  type: ActionTypes.SET_STATUS_PROCESSOR_RATES,
  statusProcessor: statusProcessor,
});

const getProcessors = (
  publicMerchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): Promise<void> => {
  const url: string = `${environment.kushkiUrl}/merchant/v1/admin/merchant/${publicMerchantId}/processors`;
  try {
    const processors: AxiosResponse<MerchantProcessors[]> = await axios.get(
      url
    );

    dispatch(setProcessors(processors.data));
  } catch (_) {
    showUnexpectedError(
      dispatch,
      "Lo sentimos, no pudimos obtener procesadores de este comercio, inténtalo más tarde"
    );
  }
};

export const getProcessorsRules = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): Promise<void> => {
  try {
    const body = {
      merchantId: merchantId,
      limit: 5,
    };
    const response: AxiosResponse<MerchantProcessorsRules> = await axios.post(
      `${environment.kushkiUrl}/rules/v1/admin/business-type/rules`,
      body
    );
    if (!isEmpty(response.data))
      if (!response.data.items)
        response.data = {
          items: [],
          lastEvaluatedKey: response.data.lastEvaluatedKey,
        };
    dispatch(setProcessorsRules(response.data));
  } catch (e) {
    showUnexpectedError(dispatch);
  }
};

const filterProcessorByPaymentType = (
  processors: MerchantProcessors[],
  paymentType: string
): MerchantProcessors[] =>
  processors.filter(
    (x: MerchantProcessors) => get(x, "paymentMethod", "") === paymentType
  );

export const getMerchantAndSemaphore = (
  publicMerchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): Promise<void> => {
  try {
    dispatch(showLoading());

    const merchantInformation: AxiosResponse<MerchantCreateUpdateResponse> = await axios.post<MerchantCreateUpdateResponse>(
      `${environment.kushkiUrl}/billing-core/v1/merchant/merchantInfo`,
      {
        publicMerchantId,
      }
    );

    if (merchantInformation?.data?.status !== "pending") {
      window.location.href = `/config-merchant/merchant/${publicMerchantId}`;
    }

    const taxId: string = merchantInformation.data.taxId!;

    dispatch(setMerchantAffiliation(merchantInformation.data));
    dispatch(getMerchantDispersionData(taxId, merchantInformation.data));

    localStorage.setItem(
      "merchantBasicInformation",
      JSON.stringify(merchantInformation.data)
    );

    localStorage.setItem(
      "merchantInfo",
      JSON.stringify({
        country: merchantInformation.data.country,
        isEditing: true,
        merchantName: merchantInformation.data.name,
        publicMerchantId: merchantInformation.data.publicMerchantId,
      })
    );

    const url_api: string = environment.kushkiUrl;

    const statusSemaphore: AxiosResponse<SemaphoreData> = await axios.get<SemaphoreData>(
      `${url_api}/billing-core/v1/getSemaphore/${publicMerchantId}`
    );

    const semaphore: SemaphoreData = statusSemaphore.data;

    dispatch(
      setStepStatus(
        "stepBasicData",
        get(semaphore, "stepBasicData.status", StatusSemaphore.pending)
      )
    );
    dispatch(
      setStepStatus(
        "stepServices",
        get(semaphore, "stepServices.status", StatusSemaphore.pending)
      )
    );
    dispatch(
      setStepStatus(
        "stepUsers",
        get(semaphore, "stepUsers.status", StatusSemaphore.pending)
      )
    );
    dispatch(
      setStepStatus(
        "stepConfigRatesAndInvoice",
        setSemaphoreRatesAndInvoice(semaphore)
      )
    );
    dispatch(getMerchantUsers(publicMerchantId));
    dispatch(setSemaphoreData(semaphore));
    dispatch(getRateMerchant({ merchantId: publicMerchantId }));
    dispatch(getDiscountInfo(publicMerchantId));
    dispatch(getRates(publicMerchantId));
    dispatch(getAccountPreferencesMerchant(publicMerchantId));
    getMerchantservices(publicMerchantId, dispatch);
    await dispatch(getProcessorsData(publicMerchantId));
    dispatch(getDeferred(publicMerchantId));
    dispatch(merchantRetriesAction(publicMerchantId));
    dispatch(hideLoading());
  } catch (e) {
    showUnexpectedError(dispatch, get(e, "response.data.message"));
    dispatch(hideLoading());
  }
};

const getProcessorsData = (
  publicMerchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): Promise<void> => {
  try {
    await dispatch(getProcessors(publicMerchantId));
    await dispatch(getDefaultProcessor(publicMerchantId));
    await dispatch(getDefaultPayoutsTransferProcessor(publicMerchantId));
    await dispatch(getProcessorsRules(publicMerchantId));
    await dispatch(getAppConfigPaymentMethods());
  } catch (_) {
    showUnexpectedError(dispatch);
  }
};

export const getMerchantUsers = (
  publicMerchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): Promise<void> => {
  const url: string = `${environment.kushkiUrl}/authorizer/v1/user?limit=15&searchSupport=true&merchantId=${publicMerchantId}`;
  try {
    const merchantUsers: AxiosResponse<MerchantUsers> = await axios.get(url);
    const userValide = filter(
      get(merchantUsers.data, "Users"),
      (value: User) => value.Enabled == true
    );
    merchantUsers.data.Users = userValide;
    if (merchantUsers.data.Users.length !== 0)
      dispatch(updateStepStatus("stepUsers", "complete", publicMerchantId));
    dispatch(setMerchantUsers(merchantUsers.data));
  } catch (_) {
    showUnexpectedError(dispatch);
  }
};

const getMerchantservices = (
  publicMerchantId: string,
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): void => {
  of(1)
    .pipe(
      mergeMap(() =>
        forkJoin([
          observableInstance
            .get<object>(
              `${environment.kushkiUrl}/card/v1/admin/merchant/${publicMerchantId}`
            )
            .pipe(
              catchError((e) => {
                console.log(e);

                return of({ error: e });
              })
            ),
          observableInstance
            .get<object>(
              `${environment.kushkiUrl}/vpos/v1/admin/merchant/${publicMerchantId}`
            )
            .pipe(
              catchError((e) => {
                console.log(e);

                return of({ error: e });
              })
            ),
          observableInstance
            .get<object>(
              `${environment.kushkiUrl}/smartlink/v1/admin/merchant/${publicMerchantId}`
            )
            .pipe(
              catchError((e) => {
                console.log(e);

                return of({ error: e });
              })
            ),
          observableInstance
            .get<object>(
              `${environment.kushkiUrl}/cash/v1/admin/merchant/${publicMerchantId}`
            )
            .pipe(
              catchError((e) => {
                console.log(e);

                return of({ error: e });
              })
            ),
          observableInstance
            .get<object>(
              `${environment.kushkiUrl}/commission/v1/admin/merchant/${publicMerchantId}`
            )
            .pipe(
              catchError((e) => {
                console.log(e);

                return of({ error: e });
              })
            ),
          observableInstance
            .get<object>(
              `${environment.kushkiUrl}/v1/subscriptions/admin/merchant/${publicMerchantId}`
            )
            .pipe(
              catchError((e) => {
                console.log(e);

                return of({ error: e });
              })
            ),
        ])
      )
    )
    .subscribe((response) => {
      dispatch(
        setMerchantServices({
          hasBeenCalled: true,
          cardResponse: get(response[0], "data"),
          vposResponse: get(response[1], "data"),
          webcheckoutResponse: get(response[2], "data"),
          cashResponse: get(response[3], "data"),
          commissionResponse: get(response[4], "data"),
          subscriptionsResponse: get(response[5], "data"),
        })
      );
    });
};

export const getAccountPreferencesMerchant = (
  publicMerchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): Promise<void> => {
  const url: string = `${environment.kushkiUrl}/merchant/v1/customization/${publicMerchantId}`;
  try {
    const accountPreferencesResponse: AxiosResponse<AccountPreferencesResponse> = await axios.get(
      url
    );
    dispatch(
      setAccountPreferences(get(accountPreferencesResponse, "data", {}))
    );
  } catch (e) {
    const statusCode: number = get(e, "response.status", 500);
    if (statusCode > 499)
      showUnexpectedError(
        dispatch,
        "Lo sentimos, no pudimos obtener preferencias de cuenta"
      );
  }
};

export const setDiscountInfo = (payload: DiscountResponse): IAppAction => {
  return {
    type: ActionTypes.SET_DISCOUNT_INFO,
    discountInfo: payload,
  };
};

export const getDiscountInfo = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
) => {
  const url: string = `${environment.kushkiUrl}/rates/merchantDiscount/${merchantId}`;
  axios
    .get(url)
    .then((axios_response: AxiosResponse<DiscountResponse>) => {
      dispatch(setDiscountInfo(axios_response.data));
    })
    .catch((e) => {
      console.log(e);
    });
};

export const setRateMerchant = (payload: RateResponse): IAppAction => {
  return {
    type: ActionTypes.SET_RATE_MERCHANT,
    rate: payload,
  };
};

export const getRateMerchant = (
  payload: RateRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): void => {
  axios
    .post<RateResponse>(`${environment.kushkiUrl}/costs/costs/configRates`, {
      merchantId: payload.merchantId,
    })
    .then((axios_response: AxiosResponse<RateResponse>) => {
      if (!isEmpty(axios_response.data))
        dispatch(setRateMerchant(axios_response.data));
    })
    .catch((e) => {
      console.error(e);
    });
};

export const setStepStatus = (
  updatedStepName: StepName,
  newStatus: StatusSteps
): ThunkAction<void, IAppState, undefined, IAppAction> => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>,
  getState: () => IAppState
): Promise<void> => {
  const { steps = [] } = getState();
  const newUpdatedStep: StatusStepsState = {
    name: updatedStepName as StepsEnum,
    status: newStatus,
  };

  const requiredSteps = Object.keys(CREATE_MERCHANT_SECTIONS);
  const additionalSteps = Object.keys(CREATE_MERCHANT_ADDITIONAL_SECTIONS);

  const newSteps = requiredSteps
    .concat(additionalSteps)
    .reduce((accum, stepFromList) => {
      const stepFromState = steps.find(
        (stepFromState) => stepFromState.name === stepFromList
      );
      if (stepFromList === updatedStepName) accum.push(newUpdatedStep);
      else accum.push(stepFromState!);

      return accum;
    }, [] as StatusStepsState[]);

  dispatch(setStatusSection(newSteps));
};

const setSemaphoreRatesAndInvoice = (
  semaphore: SemaphoreData
): StatusSemaphore => {
  const semaphoreInvoice: string = get(
    semaphore,
    "stepConfigRatesAndInvoice.statusInvoice",
    StatusSemaphore.pending
  );
  const semaphoreRates: string = get(
    semaphore,
    "stepConfigRatesAndInvoice.statusRates",
    StatusSemaphore.pending
  );
  const semaphoreDiscount: string = get(
    semaphore,
    "stepConfigRatesAndInvoice.statusDiscount",
    StatusSemaphore.pending
  );
  const semaphoreStatusProcessors: string = setStatusInvoice(
    get(semaphore, "stepConfigRatesAndInvoice.processors", {})
  );
  if (
    semaphoreInvoice === StatusSemaphore.pending &&
    semaphoreRates === StatusSemaphore.pending &&
    semaphoreDiscount === StatusSemaphore.pending &&
    semaphoreStatusProcessors === StatusSemaphore.pending
  )
    return StatusSemaphore.pending;
  else if (
    semaphoreInvoice === StatusSemaphore.complete &&
    semaphoreRates === StatusSemaphore.complete &&
    semaphoreStatusProcessors === StatusSemaphore.complete &&
    (semaphoreDiscount === StatusSemaphore.complete ||
      semaphoreDiscount === StatusSemaphore.omitted)
  )
    return StatusSemaphore.complete;
  else return StatusSemaphore.inProcess;
};

export const setloaderDeferred = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_LOADER_DEFERRED,
    isloaderDeferred: payload,
  };
};

export const merchantIdDeferredAction = (
  publicMerchantId: string,
  deferredOptions: boolean
) => async (dispatch: ThunkDispatch<IAppState, any, IAppAction>) => {
  const url: string = `${environment.kushkiUrl}/card/v1/admin/merchant/${publicMerchantId}`;
  const body = {
    deferredOptions,
  };
  try {
    dispatch(setloaderDeferred(true));
    await axios.patch(url, body);
    await dispatch(getDeferred(publicMerchantId));
    dispatch(setloaderDeferred(false));
  } catch (_) {
    dispatch(setloaderDeferred(false));
    showUnexpectedError(dispatch);
  }
};
export const setRetries = (payload: SearchResponse): IAppAction => ({
  type: ActionTypes.SET_RETRIES,
  retries: payload,
});

export const merchantRetriesAction = (publicMerchantId: string) => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>,
  getState: () => IAppState
) => {
  const url: string = `${environment.kushkiUrl}/payments-analytics/v1/admin/retry/rules/merchant`;
  //"https://billing-core.getsandbox.com/reintento";
  const state = getState();
  try {
    const formatDate = "YYYY-MM-DDTHH:mm:ss";
    const data = {
      from: moment(state.merchantAffiliation?.created).format(formatDate),
      limit: 10,
      offset: 0,
      text: publicMerchantId,
      timeZone: moment().format("Z"),
      to: moment().format(formatDate),
    };
    const res = await axios.post<SearchResponse>(url, data);
    dispatch(setRetries(res.data));
  } catch (_) {
    showUnexpectedError(dispatch);
  }
};

export const getMerchantDispersionData = (
  taxId: string,
  merchantResponse: MerchantCreateUpdateResponse
): ThunkAction<void, IAppState, undefined, IAppAction> => (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): void => {
  if (isEmpty(taxId)) return;
  const url: string = `${environment.kushkiUrl}/dispersions/v1/dispersion/configuration/${taxId}`;
  axios
    .get<DispersionConfigDynamo>(url)
    .then((axios_response: AxiosResponse<DispersionConfigDynamo>) => {
      if (!isEmpty(axios_response.data)) {
        dispatch(
          setMerchantAffiliation({
            ...merchantResponse,
            dispersionFrequency: axios_response.data?.frequency,
            walletConfig: get(axios_response, "data.walletConfig", []),
          })
        );
        merchantResponse.dispersionFrequency = axios_response.data?.frequency;
        localStorage.setItem(
          "merchantBasicInformation",
          JSON.stringify(merchantResponse)
        );
      }
    })
    .catch((e) => {
      console.error(e);
    });
};
export const getAppConfigPaymentMethods = (): ThunkAction<
  void,
  IAppState,
  undefined,
  IAppAction
> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    try {
      const url: string = `${environment.kushkiUrl}/config-manager/v1/admin/configProfile?appName=HiddenPaymentMethods&env=develop&profileName=generalConfig`;
      const axiosResponse: AxiosResponse<GetAppConfigPaymentMethodsResponse> = await axios.get(
        url
      );
      const response: GetAppConfigPaymentMethodsResponse = get(
        axiosResponse,
        "data"
      );
      dispatch(setAppConfigPaymentMethods(response));
    } catch (e) {
      dispatch(setAppConfigPaymentMethods([]));
    }
  };
};
