import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  createStyles,
  Grid,
  Menu,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowDropDown } from "@material-ui/icons";
import { AccordionPopupProcessorMenuPayIn } from "./PopupProcessorMenu/AccordionPopupProcessorMenuPayIn/AccordionPopupProcessorMenuPayIn";
import { AccordionPopupProcessorMenuPayOut } from "./PopupProcessorMenu/AccordionPopupProcessorMenuPayOut/AccordionPopupProcessorMenuPayOut";
import { get } from "lodash";
import { navigateWithMerchantInfoStorage } from "../../../shared/infrastructure/constants/CreateMerchantConstants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(2, 0),
      marginBottom: 15,
      paddingTop: 35,
      paddingBottom: 35,
    },
    note: {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(2, 0),
      paddingTop: 5,
      paddingBottom: 10,
    },
    font: {
      fontWeight: "lighter",
      paddingBottom: 10,
      marginTop: 5,
    },
    omitButton: {
      color: "rgba(109, 119, 129, 1)",
    },
    marginBottom10: {
      marginBottom: 10,
    },
    marginOmmitButton: {
      marginBottom: 5,
    },
    copyIcon: {
      marginLeft: "4px",
      cursor: "pointer",
    },
    stepIcon: {
      cursor: "pointer",
      backgroundColor: "#F0F4F9",
      borderRadius: "4px",
    },
    selectButton: {
      borderLeft: "1px solid #bdbdbd",
    },
    disabledBtn: {
      "&.mui-ripple-circle": { display: "none" },
      "&:hover": {
        background: theme.palette.primary.main,
      },
    },
  })
);

export type IProcessorsPendingSection = {
  to: string;
  buttonText: string;
  title: string | React.ReactNode;
  note: string | React.ReactNode;
  omitButtonText?: string;
  helperText?: string;
  omitButtonAction?(): void;
  id?: string;
  country?: string;
  isPopUp?: boolean;
  isLoadingOmitAction?: boolean;
};

export const ProcessorsPendingSection: React.FC<
  Partial<IProcessorsPendingSection>
> = (props: Partial<IProcessorsPendingSection>) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [typeAcordion, setTypeAcordion] = useState<string>("");

  const handleCloseEl = (): void => {
    setAnchorEl(null);
    setTypeAcordion("");
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleChangeActiveAcordion = (type: string): void => {
    setTypeAcordion(type);
  };

  return (
    <Grid
      container
      direction="column"
      component={Paper}
      elevation={0}
      className={classes.paper}
      justify="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Typography
          variant="subtitle2"
          align="center"
          color="textPrimary"
          className={classes.note}
        >
          {props.note}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          align="center"
          color="textPrimary"
          className={classes.font}
        >
          {props.title}
        </Typography>
      </Grid>
      {get(props, "isPopUp", false) ? (
        <Grid item xs={12}>
          <ButtonGroup variant="contained" color="primary">
            <Button
              disableElevation
              className={classes.disabledBtn}
              disableTouchRipple
              disableRipple
              disableFocusRipple
            >
              {props.buttonText}
            </Button>
            <Button
              id={"openMenu"}
              color="primary"
              size="small"
              onClick={handleClick}
              className={classes.selectButton}
            >
              <ArrowDropDown />
            </Button>
          </ButtonGroup>
          <Menu
            id="simple-menu-processor"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            elevation={1}
            getContentAnchorEl={null}
          >
            <AccordionPopupProcessorMenuPayIn
              id={get(props, "id", "")}
              country={get(props, "country", "")}
              setActivePayIn={(type: string) =>
                handleChangeActiveAcordion(type)
              }
              expanded={typeAcordion === "payin"}
            />
            <AccordionPopupProcessorMenuPayOut
              id={get(props, "id", "")}
              country={get(props, "country", "")}
              setActivePayOut={(type: string) =>
                handleChangeActiveAcordion(type)
              }
              expanded={typeAcordion === "payout"}
            />
          </Menu>
        </Grid>
      ) : (
        <React.Fragment>
          <Grid item xs={12}>
            <Button
              id="btn-redirect"
              variant="contained"
              color="primary"
              onClick={() => navigateWithMerchantInfoStorage(props.to!)}
              className={classes.marginBottom10}
            >
              {props.buttonText}
            </Button>
          </Grid>
          {props.omitButtonText && (
            <Grid item xs={12}>
              <Button
                id="btn-redirect"
                variant="text"
                color="default"
                onClick={props.omitButtonAction}
                className={classes.marginOmmitButton}
              >
                {props.omitButtonText}
                {props.isLoadingOmitAction && (
                  <CircularProgress
                    style={{ marginLeft: 5 }}
                    color="inherit"
                    size={20}
                  />
                )}
              </Button>
            </Grid>
          )}
          {props.helperText && (
            <Grid item xs={12}>
              <Typography
                variant="body2"
                align="center"
                color="textPrimary"
                className={classes.font}
              >
                {props.helperText}
              </Typography>
            </Grid>
          )}
        </React.Fragment>
      )}
    </Grid>
  );
};
