import { Box, Button, Typography } from "@mui/material";
import React, { FC } from "react";
import { IProcessRefundsButton } from "../../shared/interfaces/IProcessRefunds";
import { SnackbarAlert } from "../common/SnackbarAlert/SnackbarAlert";
import ProcessingConfirmationModal from "../modals/ProcessingConfirmationModal/ProcessingConfirmationModal";
import { ProcessRefundsButtonStyles as styles } from "./ProcessRefundsButton.styles";
import { useProcessRefundsButtonState } from "./state/ProcessRefundsButton.state";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { SecurityWrapperEnum } from "../../shared/enums/SecurityWrapperEnum";

const ProcessRefundsButton: FC<IProcessRefundsButton> = ({
  totalRefunds,
  refundsForProcessing,
}: IProcessRefundsButton) => {
  const {
    actions: { handleClick, handleClose, handleCloseAlert, handleConfirm },
    alertType,
    message,
    open,
    showAlert,
  } = useProcessRefundsButtonState(refundsForProcessing);

  return (
    <Box sx={styles.container}>
      <ComponentSecurityWrapper componentId={SecurityWrapperEnum.PROCESS}>
        <Button
          sx={styles.button}
          onClick={handleClick}
          disabled={refundsForProcessing.length < 1}
          size="small"
        >
          <Typography sx={styles.text}>Procesar</Typography>
        </Button>
      </ComponentSecurityWrapper>
      <ProcessingConfirmationModal
        open={open}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        totalRefunds={totalRefunds}
        numRefundsForProcessing={refundsForProcessing.length}
      />
      <SnackbarAlert
        open={showAlert}
        message={message}
        type={alertType}
        onClose={handleCloseAlert}
      />
    </Box>
  );
};

export default ProcessRefundsButton;
