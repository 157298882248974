import React from "react";
import { Box, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export interface GenericRectSkeletonProps {
  width: string;
  name: string;
}

export const GenericRectSkeleton: React.FC<GenericRectSkeletonProps> = (
  props: GenericRectSkeletonProps
) => {
  return (
    <React.Fragment>
      <div style={{ padding: "20px 22px" }}>
        <Box pb={2}>
          <Skeleton variant="rect" width={props.width} height={20} />
        </Box>
        <Grid container direction={"row"} spacing={3}>
          {[...Array(3)].map((i: number) => {
            return (
              <Grid item xs={2} key={`grid${props.name}${i}`}>
                <Skeleton
                  key={`skeleton${props.name}${i}`}
                  animation="wave"
                  variant="rect"
                  width={"100%"}
                  height={70}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </React.Fragment>
  );
};
