import { getLocalStorage } from "@shared/utils/localStorageUtils";
import { get } from "lodash";

const getUserName = () => {
  const payload: object = JSON.parse(getLocalStorage("payload") || "{}");
  const is_azure: boolean = get(payload, "iss", "").includes("sts.windows");
  const username: string = is_azure
    ? get(payload, "email", "")
    : getLocalStorage("username") || "";

  return username;
};

export { getUserName };
