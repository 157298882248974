import { useEffect, useState } from "react";
import { isEqual, isNaN } from "lodash";
import { PlaceHolders } from "../../../../../shared/enums/BusinessRuleEnum";
import { IUseAmountResponseInterface } from "../../../../../shared/interfaces/use_amount_response";
import { IUseAmountRequestInterface } from "../../../../../shared/interfaces/use_amount_request";
import { RuleEnum } from "../../../../../shared/enums/RuleEnum";
import { useFormContext } from "react-hook-form";

export const useAmountConditionState = (
  props: IUseAmountRequestInterface
): IUseAmountResponseInterface => {
  const { operator, name } = props;
  const [placeHolder, setPlaceHolder] = useState<string>("");

  const { setValue, errors, control, register, clearErrors, watch } =
    useFormContext();
  const value = watch(name);

  const getPlaceHolder = (): void => {
    setPlaceHolder(PlaceHolders.INPUT_VALUE);
  };

  const getValues = (eventValue: string): string[] => {
    props.setSelectedItems([
      {
        label: RuleEnum.AMOUNT,
        selected: true,
        value: [eventValue].toString(),
      },
    ]);

    return [eventValue];
  };

  const getOtherPlaceholder = (): void => {
    setPlaceHolder(PlaceHolders.AMOUNT_SINGLE_VALUE);
  };

  const keyDownHandler = (e: KeyboardEvent): void => {
    const key: string = e.key;
    const isNotNumber: boolean = isNaN(Number(key));
    const isPoint: boolean = isEqual(key, ".");
    const isBackspace: boolean = isEqual(key, "Backspace");

    if (isNotNumber && !isPoint && !isBackspace) e.preventDefault();
  };

  const clearData = (): void => {
    setPlaceHolder("");
    clearErrors(RuleEnum.AMOUNT);
    setValue(name, "");
  };

  useEffect(() => {
    clearData();
    getPlaceHolder();
  }, [operator]);

  useEffect(() => {
    if (props.defaultValueList.length != 0) {
      setValue(name, props.defaultValueList[0]);
      props.setSelectedItems([
        {
          label: RuleEnum.AMOUNT,
          selected: true,
          value: props.defaultValueList[0],
        },
      ]);
    }
  }, [props.defaultValueList]);

  useEffect(() => {
    props.setSelectedItems([
      {
        label: RuleEnum.AMOUNT,
        selected: true,
        value: [value].toString(),
      },
    ]);
  }, [value]);

  return {
    actions: {
      getOtherPlaceholder,
      getPlaceHolder,
      getValues,
      keyDownHandler,
      register,
      setValue,
    },
    control,
    errors,
    placeHolder,
    value,
  };
};
