import React, { useEffect, useState } from "react";
import {
  IUseFilterDashboard,
  IUseFilterDashboardProps,
} from "./useDashboard.interfaces";
import { SearchMerchantRequest } from "../../../../types/search_merchant_request";
import { cloneDeep, get, isEmpty, set, unset } from "lodash";
import { MerchantNodeData } from "../../../../types/search_merchant_response";
import { ITableRowPropsMassive } from "../../Table/TableNodes/interfaces";
import { builderRows } from "../../../shared/utils/constants";
import {
  CatalogConfigTableBranch,
  CatalogConfigTableRoleCustomerMaster,
} from "../../../shared/constants/CatalogConfigTableBranch";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { setPaginationData } from "../../../store/actions/generalData.actions";
import { FilterActionTypeEnum } from "../../../shared/enum/FilterActionTypeEnum";
import { EntityEnum } from "../../../shared/enum/EntityEnum";
import { isMerchantId } from "../../../shared/utils/tableUtils";
import {
  ConfigsEnum,
  ConfigStatusEnum,
} from "../../../shared/enum/ConfigsEnum";
import { searchMerchantNodeInfo } from "../../../store/thunks/general/general.thunk";
import { isBackofficeMasterCustomer } from "../../../shared/utils/getRolesUtils";

export const useDashboard = ({
  nodeData,
  showEmptyScreen,
}: IUseFilterDashboardProps): IUseFilterDashboard => {
  const dispatch = useAppDispatch();
  const { pathCustomer, isLoadingMerchants } = useAppSelector(
    (state) => state.generalData
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const totalData: number = get(nodeData, "total", 0);

  const [filterDashboardState, setFilterDashboardState] =
    useState<SearchMerchantRequest>({
      config: {
        configId: ConfigsEnum.USER_CONFIG,
        status: ConfigStatusEnum.ALL,
      },
      entityName: EntityEnum.BRANCH,
      limit: rowsPerPage,
      offset: rowsPerPage * page,
      strictSearch: false,
    });

  const [itemsSelected, setItemsSelected] = useState<MerchantNodeData[]>([]);
  const [resetFilters] = useState<boolean>();
  const [hasFilters, setHasFilters] = useState<boolean>(false);
  const [countData, setCountData] = useState<number>(0);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
    setFilterDashboardState((prevState: SearchMerchantRequest) => ({
      ...prevState,
      offset: rowsPerPage * newPage,
    }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilterDashboardState((prevState: SearchMerchantRequest) => ({
      ...prevState,
      limit: parseInt(event.target.value, 10),
      offset: 0,
    }));
  };

  const handleResetPage = () => {
    setPage(() => 0);
    setRowsPerPage(() => 10);
  };

  const rows: ITableRowPropsMassive[] = builderRows<MerchantNodeData>(
    get(nodeData, "data", []),
    isBackofficeMasterCustomer()
      ? CatalogConfigTableRoleCustomerMaster
      : CatalogConfigTableBranch,
    page.toString()
  );

  const handleOnFilterMerchants = async (
    type: FilterActionTypeEnum,
    value: string
  ) => {
    const searchMerchantRequest = cloneDeep(filterDashboardState);

    switch (type) {
      case FilterActionTypeEnum.SEARCH:
        if (isMerchantId(value) && !isEmpty(value)) {
          set(searchMerchantRequest, "filter.merchantId", value);
          unset(searchMerchantRequest, "name");
        } else {
          set(searchMerchantRequest, "name", value);
          unset(searchMerchantRequest, "filter");
        }

        break;
      case FilterActionTypeEnum.STATUS:
        if (!isEmpty(value)) {
          set(
            searchMerchantRequest,
            "config.configId",
            ConfigsEnum.USER_CONFIG
          );
          set(searchMerchantRequest, "config.status", value);
        } else {
          set(searchMerchantRequest, "config.status", ConfigStatusEnum.ALL);
          set(
            searchMerchantRequest,
            "config.configId",
            ConfigsEnum.USER_CONFIG
          );
        }
        break;
    }

    setFilterDashboardState(searchMerchantRequest);
  };

  useEffect(() => {
    if (!isEmpty(pathCustomer)) {
      dispatch(
        searchMerchantNodeInfo({
          ...filterDashboardState,
          limit: rowsPerPage,
          offset: rowsPerPage * page,
          path: pathCustomer,
        })
      );
    }
  }, [filterDashboardState]);

  const handleSelectedRows = (rowsSelected: MerchantNodeData[]) => {
    setItemsSelected(rowsSelected);
  };

  useEffect(() => {
    if (!hasFilters) {
      setCountData(get(nodeData, "total", 0));
      setHasFilters(true);
    }
  }, [nodeData]);

  useEffect(() => {
    dispatch(
      setPaginationData({
        page,
        rowsPerPage,
        totalData,
      })
    );
  }, [page, rowsPerPage, totalData]);

  return {
    countData,
    handleOnFilterMerchants,
    handleResetPage,
    handleSelectedRows,
    isLoadingMerchants,
    itemsSelected,
    paginationProps: {
      handleChangePage,
      handleChangeRowsPerPage,
      page,
      rowsPerPage,
      totalData,
    },
    resetFilters,
    rows,
    showEmptyScreen,
  };
};
