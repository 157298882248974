import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { ISelectedBranch } from "./SelectedBranch.interfaces";
import { SELECTED_BRANCHES } from "../../../shared/constants/labels/ModalDialogConstants";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import List from "@mui/material/List";
import { SelectedBranchStyles } from "./SelectedBranch.styles";
import { useSelectedBranch } from "./state/useSelectedBranch";

const SelectedBranch: React.FC<ISelectedBranch> = ({
  isOpen,
  handleOpenSelectedBranch,
}: ISelectedBranch) => {
  const { totalBranches, filtered, handleFilter } = useSelectedBranch();

  return (
    <Dialog sx={SelectedBranchStyles.dialogContainerParent} open={isOpen!}>
      <DialogTitle sx={SelectedBranchStyles.dialogTitle}>
        <Grid sx={SelectedBranchStyles.mainHeader}>
          <Typography sx={SelectedBranchStyles.titleSpan}>
            {SELECTED_BRANCHES}
          </Typography>
          <IconButton
            sx={SelectedBranchStyles.closeIcon}
            onClick={() => handleOpenSelectedBranch(false)}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid sx={SelectedBranchStyles.subHeader}>
          <Typography variant="caption" color="text.grey">
            {`Total de branches: ${totalBranches}`}
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent sx={SelectedBranchStyles.dialogContent}>
        <FormControl sx={SelectedBranchStyles.inputField}>
          <TextField
            size="small"
            placeholder={"Buscar por nombre..."}
            variant="outlined"
            onChange={(e) => {
              handleFilter(e.target.value.toLowerCase());
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <Box sx={SelectedBranchStyles.boxItems}>
          {filtered.map((item: any, index: number) => {
            return (
              <List sx={SelectedBranchStyles.branchItems} key={index}>
                {item.name}
                <Box sx={SelectedBranchStyles.clientTypeText} component="span">
                  {` -  ${item.client_type}`}
                </Box>
              </List>
            );
          })}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SelectedBranch;
