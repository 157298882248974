import { transactionsSlice } from "../../reducers/transactions/transactions";

export const {
  addComment,
  toggleTransactionSelection,
  clearSelectedTrasactionTable,
  orderSelectedTransactionTable,
  closeSelectedModal,
  deselectAllCurrentTransactions,
  selectAllCurrentTransactions,
  setCurrentActionTransaction,
  setTransactionData,
  toggleClickTransaction,
} = transactionsSlice.actions;
