import React from "react";
import { filter, get } from "lodash";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { DocumentationSectionProps } from "./DocumentationSectionModal.interfaces";
import { useStyles } from "./DocumentationSectionModal.styles";
import { Documentation } from "../../../../types/chargeback";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { ChargebackStatusEnum } from "../../../shared/enums/ChargebackStatusEnum";
import { InformativeText, LabelField } from "../../../shared/enums/LabelsEnum";
import { ChargebackRoles } from "../../../shared/constants/ChargebackConstants";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { formatDate } from "../../ChargebackDetail/ChargebackDetail";

const DocumentationSection: React.FC<DocumentationSectionProps> = (
  props: DocumentationSectionProps
) => {
  const classes = useStyles();

  const doc_files: Documentation[] = props.isMongo
    ? get(props, "data.doc_file_object", [])
    : get(props, "data.doc_files", []);

  const letter: Documentation[] = filter(doc_files, "isLetter");
  const docs: Documentation[] = filter(doc_files, ["isLetter", false]);

  const uploadDate: string | number = props.isMongo
    ? get(props, "data.upload_document_trace_info_object.operationTime")
    : get(props.data, "upload_documents_trace_info.operation_time", 0);
  const uploadUser: string = props.isMongo
    ? get(props, "data.upload_document_trace_info_object.userName")
    : get(props.data, "upload_documents_trace_info.user_name", "");

  return (
    <React.Fragment>
      {get(props, "data.transaction_status") ===
      ChargebackStatusEnum.EXPIRED ? (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body2" color={"error"}>
              {InformativeText.EXPIRED_DOCS}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant={"body2"} color={"text.grey"}>
              {LabelField.UPLOAD_USER}
            </Typography>
            <Typography variant={"body2"} color={"text.grey"}>
              {uploadUser}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"body2"} color={"text.grey"}>
              {LabelField.UPLOAD_DATE}
            </Typography>
            <Typography variant={"body2"} color={"text.grey"}>
              {uploadDate != 0 ? formatDate(uploadDate, "dd-MM-yyyy") : ""}
            </Typography>
            <Typography variant={"body2"} color={"text.grey"}>
              {uploadDate != 0 ? formatDate(uploadDate, "HH:mm:ss") : ""}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="body2" color={"text.grey"}>
                  Carta de establecimiento
                </Typography>
              </Grid>
              {letter.length === 0 ? (
                <Grid item xs={12}>
                  <Typography variant="body2">
                    {InformativeText.WITHOUT_LETTERS}
                  </Typography>
                </Grid>
              ) : (
                <ComponentSecurityWrapper
                  componentId={`${ChargebackRoles.M_TRANSACTIONS}.${ChargebackRoles.CHARGEBACK_DETAIL_DOWNLOAD_LETTER}`}
                >
                  <Grid container spacing={1}>
                    {letter.map((item: Documentation, index: number) => (
                      <Grid item xs={9} key={index}>
                        <Box className={classes.box}>
                          <Grid
                            container
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Grid item>
                              <Typography
                                variant="body2"
                                color={"text.grey"}
                                className={classes.buttonDownload}
                              >
                                {item.fileName}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <a
                                href={item.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <IconButton aria-label="download">
                                  <FileDownloadOutlinedIcon />
                                </IconButton>
                              </a>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </ComponentSecurityWrapper>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="body2" color={"text.grey"}>
                  Documentos
                </Typography>
              </Grid>
              {docs.length === 0 ? (
                <Grid item xs={12}>
                  <Typography variant="body2">
                    {InformativeText.WITHOUT_DOCS}
                  </Typography>
                </Grid>
              ) : (
                <ComponentSecurityWrapper
                  componentId={`${ChargebackRoles.M_TRANSACTIONS}.${ChargebackRoles.CHARGEBACK_DETAIL_DOWNLOAD_DOC}`}
                >
                  <Grid container spacing={1}>
                    {docs.map((item: Documentation, index: number) => (
                      <Grid item xs={9} key={index}>
                        <Box className={classes.box}>
                          <Grid
                            key={index}
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography
                                className={classes.buttonDownload}
                                variant="body2"
                                color={"text.grey"}
                              >
                                {item.fileName}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <a
                                href={item.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <IconButton aria-label="download">
                                  <FileDownloadOutlinedIcon />
                                </IconButton>
                              </a>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </ComponentSecurityWrapper>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default DocumentationSection;
