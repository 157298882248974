import * as React from "react";
import { FC } from "react";
import { IModalLoaderProps } from "./ModalLoader.interfaces";
import { Box, Grid, Modal, Typography } from "@mui/material";
import { ModalLoaderStyles } from "./ModalLoader.styles";
import Lottie from "lottie-react";
import animationData from "../../assets/animation/loader.json";

export const ModalLoader: FC<IModalLoaderProps> = (
  props: IModalLoaderProps
) => {
  return (
    <div>
      <Modal
        open={props.isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container sx={ModalLoaderStyles.modalContainer}>
          <Grid item sx={ModalLoaderStyles.itemLottie}>
            <Box sx={ModalLoaderStyles.boxLottie}>
              <Lottie
                animationData={animationData}
                autoPlay={true}
                loop={true}
                style={{ height: "100%", width: "100%" }}
                rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
              />
            </Box>
          </Grid>
          <Box>
            <Typography id="modal-modal-title" variant="h3" color="primary">
              {props.titleText}
            </Typography>
            <Typography
              id="modal-modal-description"
              variant="body2"
              color="text.dark"
            >
              {props.descriptionText}
            </Typography>
          </Box>
        </Grid>
      </Modal>
    </div>
  );
};
