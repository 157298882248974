import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { Color } from "@material-ui/lab/Alert";
import { useSnackBarAlertState } from "./useSnackBarAlertState";
import { makeStyles } from "@material-ui/core";

export interface SnackbarAlertProps {
  msg: string;
  open: boolean;
  type: Color;
  handlerClose: (_event?: React.SyntheticEvent, reason?: string) => void;
  customColor?: string;
}

const useStyles = makeStyles(() => ({
  blackSnackColor: {
    fontSize: "1.5rem",
    color: "black",
    opacity: 0.75,
    background: "black",
    borderRadius: "5px",
    "& .MuiPaper-root .MuiAlert-message": {
      color: "white",
    },
  },
}));

export const SnackBarAlert: React.FC<SnackbarAlertProps> = (
  props: SnackbarAlertProps
) => {
  const { open } = useSnackBarAlertState(props);
  const classes = useStyles();
  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={4000}
        onClose={props.handlerClose}
        className={
          props.type === ("black" as Color)
            ? classes.blackSnackColor
            : undefined
        }
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={props.handlerClose}
          severity={props.type}
        >
          {props.msg}
        </MuiAlert>
      </Snackbar>
    </React.Fragment>
  );
};
