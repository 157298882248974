import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { FieldValues, SubmitHandler } from "react-hook-form";
import {
  editBoardMember,
  setBoardMember,
  setEditedBoardMember,
} from "../../../../../store/reducers/legalDetails/legalDetails";
import { IBoardMember } from "../../../../../store/interfaces/LegalDetailsState.interfaces";
import { v4 } from "uuid";
import { get, set } from "lodash";
import { CountryEnum } from "../../../../../shared/countryEnum";

interface UseBoardMemberFormBody {
  closeModal: () => void;
  reset: Function;
}

export const useBoardMemberForm = ({
  closeModal,
  reset,
}: UseBoardMemberFormBody) => {
  const { isEditedBoardMember, nodeInfo } = useSelector((state: RootState) => ({
    ...state.legalDetails,
    ...state.initialData,
  }));

  const constitutionalCountry: CountryEnum = get(
    nodeInfo,
    "generalInfo.constitutionalCountry",
    CountryEnum.generic
  );

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    reset();
    closeModal();
    dispatch(setEditedBoardMember({ id: "", value: false }));
  };

  const onSubmit: SubmitHandler<FieldValues> = (data: FieldValues) => {
    set(data, "personCategory", "BOARD_MEMBER");

    if (isEditedBoardMember.value) {
      dispatch(setEditedBoardMember({ id: "", value: false }));
      closeModal();

      dispatch(editBoardMember(data as IBoardMember));
    } else {
      data.id = v4();
      dispatch(setBoardMember(data as IBoardMember));
      closeModal();
    }

    reset();
  };

  return {
    constitutionalCountry,
    handleCloseModal,
    isEditedBoardMember,
    onSubmit,
  };
};
