export enum RefundState {
  CREATED = "created", // Just created
  PAID = "paid", // Paid transactions
  COMPLETED = "completed", // Paid and registration OK
  CANCELED = "canceled", // The paying flow fail
  FAILED = "failed", // Paid but the flow fails
  VOID_PENDING = "voidPending", // Failed but void pending
  REFUND = "refund",
  REQUESTED = "requested",
}
