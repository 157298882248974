import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { GetNodeInfoResponse } from "../../../../types/get_node_info_response";
import { get } from "lodash";
import { CreateMerchantNodeResponse } from "../../../../types/create_merchant_node_response";
import { CreateMerchantNodeRequest } from "../../../../types/create_merchant_node_request";

export const createBranch = createAsyncThunk<
  CreateMerchantNodeResponse,
  CreateMerchantNodeRequest
>("resumeNode/createBranch", async (body, { rejectWithValue }) => {
  try {
    const response = await axios.post<GetNodeInfoResponse>(
      API_ROUTES.CREATE_MERCHANT,
      body
    );

    return response.data;
  } catch (error) {
    return rejectWithValue(get(error, "response.data"));
  }
});
