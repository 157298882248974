import { webhookSlice } from "../../reducers/webhook/webhook.slice";

export const {
  addEvent,
  updateAddWebhookForm,
  closeWebhookModal,
  closeWebhookSnackbar,
  resetWebhook,
  addWebhook,
  openSubmitModal,
  resetTemp,
  deleteWebhook,
  setEditUrl,
  setPreauthEnabled,
} = webhookSlice.actions;
