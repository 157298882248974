import React, { FC } from "react";
import { IButtonCardProps } from "./ButtonCard.interfaces";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { CheckCircleIcon } from "../../assets/icons/CheckCircleIcon";
import { OutlinedCircleIcon } from "../../assets/icons/OutlinedCircleIcon";
import {
  buttonCard,
  buttonContainer,
  choiceBox,
  choiceRadio,
  buttonCardStyles as styles,
} from "./ButtonCard.styles";
import { useButtonCard } from "./state/useButtonCard";
import {
  TOOLTIP_DESCRIPTION_END,
  TOOLTIP_DESCRIPTION_START,
  TOOLTIP_TITLE,
} from "../../shared/constants/labels/button_card_labels";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { COMPONENTS_IDS } from "../../shared/constants/labels/main_containter_labels";

const ButtonCard: FC<IButtonCardProps> = ({
  title,
  enableIcon,
  disabledIcon,
  isSelected,
  isDisabled,
  checked,
  url,
  id,
}: IButtonCardProps) => {
  const { handleOnClick, isTextButton } = useButtonCard(url, checked);

  return (
    <ComponentSecurityWrapper componentId={COMPONENTS_IDS[id]}>
      <Tooltip
        style={{ padding: "0px" }}
        placement={"right"}
        arrow
        disableHoverListener={!isDisabled}
        title={
          <Box sx={styles.tooltip}>
            <Typography>{TOOLTIP_TITLE}</Typography>
            <Box>{TOOLTIP_DESCRIPTION_START}</Box>
            <Box>{TOOLTIP_DESCRIPTION_END}</Box>
          </Box>
        }
      >
        <Box sx={buttonContainer(isTextButton)}>
          <FormControlLabel
            sx={buttonCard(isSelected, isTextButton)}
            disabled={isDisabled}
            checked={checked}
            onClick={isDisabled ? () => {} : handleOnClick}
            control={
              <Radio
                hidden={isTextButton}
                sx={choiceRadio(isTextButton, title)}
                icon={<OutlinedCircleIcon />}
                checkedIcon={<CheckCircleIcon />}
              />
            }
            labelPlacement="bottom"
            label={
              <Stack sx={choiceBox(isTextButton)}>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  hidden={isTextButton}
                >
                  {isDisabled ? disabledIcon : enableIcon}
                </Box>
                <Typography color="text.primary" sx={styles.txtCard}>
                  {title}
                </Typography>
              </Stack>
            }
          />
        </Box>
      </Tooltip>
    </ComponentSecurityWrapper>
  );
};

export default ButtonCard;
