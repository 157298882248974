import { WebsocketStatusEnum } from "../../../enums/websocket_status_enum";

const areThereErrors = (response: any) => {
  const payloadResponse:
    | {
        error: boolean;
        message: string;
      }
    | undefined = response?.payload;

  return (
    response.message === "Forbidden" ||
    payloadResponse?.error ||
    response.message === "ERROR"
  );
};

export const callbackMessage =
  <T>(
    webSocket: WebSocket,
    resolve: (value: T | PromiseLike<T | null> | null) => void
  ) =>
  (message: MessageEvent) => {
    const response = JSON.parse(message.data);

    if (areThereErrors(response)) {
      webSocket.close();
      resolve(null);

      return;
    }

    if (
      response.status === WebsocketStatusEnum.COMPLETED_SUCCESS ||
      response.status === WebsocketStatusEnum.COMPLETED_EMPTY
    ) {
      webSocket.close();
      resolve({ ...response, url: response?.payload?.url });
    }
  };

export const wsToPromise = <T>(url: string, data: object) =>
  new Promise<T | null>((resolve) => {
    try {
      const webSocket = new WebSocket(url);

      webSocket.addEventListener("open", () => {
        const dataToSend = JSON.stringify(data);

        webSocket.send(dataToSend);
      });

      webSocket.addEventListener("error", () => {
        webSocket.close();
        resolve(null);
      });

      webSocket.addEventListener(
        "message",
        callbackMessage(webSocket, resolve)
      );
    } catch (error) {
      resolve(null);
    }
  });
