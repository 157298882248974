import BuildableForm, { FormItem } from "../../Forms/Generics/BuildableForm";
import React, { FC } from "react";
import BoardMemberForm from "../../Forms/BoardMemberForm/BoardMemberForm";
import { Control, FieldValues } from "react-hook-form";

interface BoardMemberMoralFormProps {
  personalInformationMoral: FormItem[];
}

interface BoardMemberMoralProps {
  handleSubmit: Function;
  personalInformationMoral: FormItem[];
  reset: Function;
  closeModal: () => void;
  control?: Control<FieldValues, Object>;
}

export const BoardMemberMoralForm: FC<BoardMemberMoralFormProps> = ({
  personalInformationMoral,
}: BoardMemberMoralFormProps) => (
  <div data-testid="BoardMemberMoralForm">
    <BuildableForm formItems={personalInformationMoral} />
  </div>
);

export const BoardMemberMoral: FC<BoardMemberMoralProps> = ({
  handleSubmit,
  reset,
  control,
  closeModal,
  personalInformationMoral,
}: BoardMemberMoralProps) => (
  <BoardMemberForm
    data-testid="MoralBoardMemberForm"
    handleSubmit={handleSubmit}
    reset={reset}
    closeModal={closeModal}
    control={control}
    formNode={
      <BoardMemberMoralForm
        personalInformationMoral={personalInformationMoral}
      />
    }
  />
);
