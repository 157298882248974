import {
  NOTIFY_SUCCESS_AND_ERROR_ALERT_ENUM,
  ProcessorConstants,
} from "../../constants/ProcessorConstants";

export enum SnackBarTextEnum {
  ERROR_DEFERRED = "Ha ocurrido un error al eliminar el diferido.",
  SUCCESS_DELETED_DEFERRED = "El diferido ha sido eliminado con exito.",
  SUCCESS_CREATED_DEFERRED = "Se agregó el diferido con éxito.",
  SUCCESS_EDITED_DEFERRED = "Se actualizó el diferido con éxito.",
  SUCCESS_DELETED_PROCESSOR = "El procesador ha sido eliminado con exito.",
  ERROR_DELETED_PROCESSOR = "El procesador no ha podido eliminarse.",
  ERROR_MASSIVE_CENTRALIZE = "Se presentó un error inesperado. Inténtalo nuevamente.",
  SUCCESS_FAILOVER_CONFIG = "Se ha configurado el failover con éxito.",
  ERROR_FAILOVER_CONFIG = "No se ha podido configurar el failover.",
}

export const successMessages = new Map<string, string>([
  [
    NOTIFY_SUCCESS_AND_ERROR_ALERT_ENUM.CREATE_PROCESSOR,
    ProcessorConstants.PROCESSOR_ADDED_SUCCESSFUL,
  ],
  [
    NOTIFY_SUCCESS_AND_ERROR_ALERT_ENUM.EDIT_PROCESSOR,
    ProcessorConstants.PROCESSOR_UPDATED_SUCCESSFUL,
  ],
  [
    NOTIFY_SUCCESS_AND_ERROR_ALERT_ENUM.CREATE_DEFERRED,
    SnackBarTextEnum.SUCCESS_CREATED_DEFERRED,
  ],
  [
    NOTIFY_SUCCESS_AND_ERROR_ALERT_ENUM.EDIT_DEFERRED,
    SnackBarTextEnum.SUCCESS_EDITED_DEFERRED,
  ],
]);
