import { ICellTitleCopyComponent } from "./CellTitleCopy.interfaces";
import { FC } from "react";
import { Grid, IconButton, Link, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { TableBodyStyles } from "../../Table/TableBody/TableBody.style";
import { clipboard } from "../../../shared/utils/tableUtils";
import { IconCopy } from "@kushki/connect-ui";

export const CellTitleCopyItem: FC<ICellTitleCopyComponent> = ({
  line1,
  line2,
}: ICellTitleCopyComponent) => {
  let styles = TableBodyStyles;

  return (
    <Grid container xs={12} md={12} sx={styles.containerTitleCopy}>
      <Grid item xs={9} md={9} sx={styles.itemTitleCopy}>
        <Tooltip title={`${line2!}`} placement="top">
          <Typography sx={styles.title}>
            <Link underline={"none"}>{line1!}</Link>
          </Typography>
        </Tooltip>
        <Tooltip title={`${line2!}`} placement="top">
          <Typography sx={styles.subtitleTitleCopy}>{line2!}</Typography>
        </Tooltip>
      </Grid>
      <Grid item xs={3} md={3} marginTop={"15px"}>
        <IconButton
          onClick={(e) => {
            clipboard(line2!);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <IconCopy fontSize={"medium"} />
        </IconButton>
      </Grid>
    </Grid>
  );
};
