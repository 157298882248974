import { Timeline } from "../../../types/alarm";
import { TimelineTrxData } from "@kushki/connect-ui/dist/Components/Molecules/TimeLine/TimeLine.interfaces";
import { ActiveAlarmStatus } from "@shared/enum/ActiveAlarmStatusEnum";
import { get, isEqual, set, unset } from "lodash";
import { TimelineSectionLabel } from "@shared/enum/AlarmDetailCardEnum";

export const buildTimelineItems = (
  timeline: Timeline,
  title: string,
  buttonLink: boolean
): TimelineTrxData => {
  const timelineItem: TimelineTrxData = {
    buttonLink,
    completed: true,
    date: new Date(timeline.createdAt).toISOString(),
    link: false,
    subtitle1: timeline.fullName,
    ticketNumber: timeline._id,
    title,
  };

  if (
    timeline.status === ActiveAlarmStatus.PENDING &&
    title !== TimelineSectionLabel.RE_ACTIVE
  )
    unset(timelineItem, "subtitle1");
  if (
    timeline.isComment ||
    isEqual(timeline.status, ActiveAlarmStatus.NORMAL) ||
    isEqual(timeline.status, ActiveAlarmStatus.ROS)
  )
    set(timelineItem, "link", true);

  return timelineItem;
};

export const scrollToElement = (timelineId: string) => {
  let children: HTMLElement | null = document.getElementById(timelineId);
  let parent: HTMLElement | null = document.getElementById("commentsWrapper");

  if (children && parent) {
    parent.scrollTop =
      get(children, "offsetTop", 0) - get(parent, "offsetTop", 0);
  }
};
