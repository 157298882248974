import React, { FC } from "react";
import { Box, Divider, Skeleton, Typography } from "@mui/material";
import { skeletonEditFormStyles as styles } from "@components/SkeletonEditForm/SkeletonEditForm.styles";
import { IconCircleCross } from "@kushki/connect-ui";

const SkeletonEditForm: FC = () => {
  return (
    <>
      <Typography variant={"subtitle2"} sx={styles.subtitle}>
        Información de la alarma
      </Typography>

      <Box sx={styles.alarmInfoInputs}>
        <Skeleton sx={styles.skeleton} />
        <Skeleton sx={styles.skeleton} />
      </Box>
      <Divider sx={styles.divider} />

      <Typography variant={"subtitle2"} sx={styles.subtitle}>
        Variables
      </Typography>

      <Box>
        <Box sx={styles.variablesWrapper}>
          <Skeleton sx={styles.skeleton} />
          <IconCircleCross fontSize={"large"} color={"disabled"} />
        </Box>
        <Box sx={styles.variablesWrapper}>
          <Skeleton sx={styles.skeleton} />
          <Skeleton sx={styles.skeleton} />
        </Box>
      </Box>
    </>
  );
};

export default SkeletonEditForm;
