import { ConfigTypeEnum } from "../enums/configEnum";

export const requiredConfigs: ConfigTypeEnum[] = [
  ConfigTypeEnum.cn001,
  ConfigTypeEnum.cn002,
  ConfigTypeEnum.cn003,
  ConfigTypeEnum.cn004,
  ConfigTypeEnum.cn005,
  ConfigTypeEnum.cn007,
  ConfigTypeEnum.cn015,
  ConfigTypeEnum.cn016,
  ConfigTypeEnum.cn017,
  ConfigTypeEnum.cn018,
];

export const labelRequiredConfigs: ConfigTypeEnum[] = [
  ...requiredConfigs,
  ConfigTypeEnum.cn019,
];
