/**
 * Catalog CostRica
 */
import { CatalogsEnum } from "../infrastructure/constants/catalogs-enum";
import { Category } from "../infrastructure/constants/MerchantInformationConstant";

export const CatalogCr: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { value: "0", name: "CI" },
    { value: "1", name: "Pasaporte" },
  ],
  [CatalogsEnum.Provinces]: [
    { value: "A", name: "Alajuela" },
    { value: "C", name: "Cartago" },
    { value: "G", name: "Guanacaste" },
    { value: "H", name: "Heredia" },
    { value: "L", name: "Limón" },
    { value: "P", name: "Puntarenas" },
    { value: "SJ", name: "San José" },
  ],
  [CatalogsEnum.Banks]: [
    { value: "11100006", name: "BANCO DE COSTA RICA" },
    { value: "11100001", name: "BANCO BAC SAN JOSE" },
    { value: "11100002", name: "BANCO BCT S.A." },
    { value: "11100003", name: "BANCO CATHAY DE COSTA RICA S.A." },
    { value: "11100004", name: "BANCO CENTRAL DE COSTA RICA" },
    { value: "11100005", name: "BANCO CITIBANK DE COSTA RICA S.A." },
    { value: "11100007", name: "BANCO GENERAL DE COSTA RICA S.A." },
    { value: "11100008", name: "BANCO HSBC DE COSTA RICA S.A." },
    { value: "11100009", name: "BANCO IMPROSA S.A." },
    { value: "11100010", name: "BANCO LAFISE S.A." },
    { value: "11100011", name: "BANCO NACIONAL DE COSTA RICA" },
    { value: "11100012", name: "BANCO PROMERICA" },
    { value: "11100013", name: "SCOTIABANK DE COSTA RICA" },
    { value: "11100014", name: "COSTA RICA" },
    { value: "11100015", name: "BANCO INTERFIN DE COSTA RICA" },
    { value: "11100016", name: "BANCO INTERNACIONAL DE COSTA RICA" },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { value: "0", name: "Corriente" },
    { value: "1", name: "Ahorros" },
  ],
  [CatalogsEnum.Currency]: [{ value: "CRC", name: "CRC" }],
};
