import React from "react";
import { TransactionInfo } from "../../../../types/transactions_data";
import { CashDetail } from "../CashDetail/CashDetail";
import { TransferDetail } from "../TransferDetail/TransferDetail";
import { get } from "lodash";

export interface IContactDetail {
  document_type: string;
  document_number: string;
  name: string;
  phone: string;
  email: string;
}

export interface IPayoutTransactionInfo {
  transaction: TransactionInfo;
}

export const TransactionBody: React.FC<IPayoutTransactionInfo> = (
  props: IPayoutTransactionInfo
) => {
  const { transaction } = props;
  return (
    <React.Fragment>
      {transaction.payment_method === "cash" && (
        <CashDetail
          currencyCode={transaction.currency_code!}
          merchantId={transaction.merchant_id!}
          merchantName={transaction.merchant_name!}
          paymentMethod={transaction.payment_method!}
          pin={transaction.pin!}
          amount={transaction.approved_transaction_amount!}
          description={get(transaction, "description", "-")}
          contactDetails={transaction.contact_details}
        />
      )}
      {transaction.payment_method === "transfer" && (
        <TransferDetail transaction={transaction} />
      )}
    </React.Fragment>
  );
};
