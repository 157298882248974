import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import {
  setQueryMetricsResponse,
  setQueryMetricsStatus,
} from "../../../store/actions/app.actions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { ItemCategoryItem } from "@kushki/connect-ui/dist/Components/Molecules/Filters/ItemCategory/interfaces";
import {
  IMetricsFilters,
  IRangeDateFilter,
} from "../../../store/interfaces/AppState.interfaces";
import { useMobileState } from "../../../shared/hooks/useMobileState/useMobileState";
import { getChannelMetrics } from "../../../store/epics/channelMetricsEpic/channelMetrics.epic";
import { selectMetricsStatus } from "../../../store/selectors/selectors";
import { PaymentTypeEnum } from "../../../shared/enums/PaymentTypeEnum";
import { PlatformValueEnum } from "../../../shared/enums/PlatformValueEnum";
import { StatusValueEnum } from "../../../shared/enums/StatusValueEnum";
import { CountryValueEnum } from "../../../shared/enums/CountryEnum";
import { WebsocketNotifyEnum } from "../../../shared/enums/WebsocketNotifyEnum";
import { EmptyStateLabels } from "../../../shared/labels/EmptyState.labels";

const { createContext, useContext } = React;

export interface IChannelMetricsContextProps {}
export type TChannelMetricsContextProps = IChannelMetricsContextProps;

export interface IChannelMetricsContext {
  changeCountryFilter: (country: string) => void;
  changeProductFilter: (selectedItems: ItemCategoryItem[]) => void;
  changePaymentTypeFilter: (selectedItems: ItemCategoryItem[]) => void;
  changeStatusFilter: (selectedItems: ItemCategoryItem[]) => void;
  changeRangeDateFilter: (range: IRangeDateFilter) => void;
  metricsFilters: IMetricsFilters;
  isEmptyResults: boolean;
  emptyResultMessage: EmptyStateLabels;
  isLoading: boolean;
  isMobile: boolean;
  showChartBar: boolean;
}
export type TChannelMetricsContext = IChannelMetricsContext;

const ChannelMetricsContext = createContext<TChannelMetricsContext>(undefined!);

export const ChannelMetricsProvider: FC<TChannelMetricsContextProps> = ({
  children,
}: PropsWithChildren<TChannelMetricsContextProps>) => {
  const dispatch = useAppDispatch();
  const metricsStatus = useAppSelector(selectMetricsStatus);

  const [countryFilter, setCountryFilter] = useState<string>("");
  const [rangeDateFilter, setRangeDateFilter] = useState<IRangeDateFilter>();
  const [productFilter, setProductFilter] = useState<ItemCategoryItem[]>([]);
  const [paymentTypeFilter, setPaymentTypeFilter] = useState<
    ItemCategoryItem[]
  >([]);
  const [statusFilter, setStatusFilter] = useState<ItemCategoryItem[]>([]);

  const [isEmptyResults, setIsEmptyResults] = useState<boolean>(true);
  const [emptyResultMessage, setEmptyResultMessage] =
    useState<EmptyStateLabels>(EmptyStateLabels.INITIAL_DESCRIPTION);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showChartBar, setShowChartBar] = useState<boolean>(false);

  const { isMobile } = useMobileState();

  const changeCountryFilter = (country: string) => {
    setCountryFilter(country);
  };
  const changeProductFilter = (selectedItems: ItemCategoryItem[]) => {
    setProductFilter(selectedItems);
  };
  const changePaymentTypeFilter = (selectedItems: ItemCategoryItem[]) => {
    setPaymentTypeFilter(selectedItems);
  };
  const changeStatusFilter = (selectedItems: ItemCategoryItem[]) => {
    setStatusFilter(selectedItems);
  };

  const changeRangeDateFilter = (range: IRangeDateFilter) => {
    setRangeDateFilter(range);
  };

  const mapFilters = (filters: ItemCategoryItem[]): string[] =>
    filters.map((item) => item.value);

  useEffect(() => {
    if (countryFilter && rangeDateFilter) {
      dispatch(setQueryMetricsResponse(undefined));
      dispatch(setQueryMetricsStatus(WebsocketNotifyEnum.PROCESSING));
      dispatch(
        getChannelMetrics({
          action: "queryChannelTransactionMetrics",
          country: countryFilter as CountryValueEnum,
          filters: {
            paymentMethods: mapFilters(paymentTypeFilter) as PaymentTypeEnum[],
            platforms: mapFilters(productFilter) as PlatformValueEnum[],
            status: mapFilters(statusFilter) as StatusValueEnum[],
          },
          rangeDate: {
            from: rangeDateFilter.startDate,
            to: rangeDateFilter.finishDate,
          },
        })
      );
    }
  }, [
    countryFilter,
    paymentTypeFilter,
    productFilter,
    rangeDateFilter,
    statusFilter,
  ]);

  useEffect(() => {
    switch (metricsStatus) {
      case WebsocketNotifyEnum.COMPLETED_SUCCESS:
        setIsEmptyResults(false);
        setIsLoading(false);
        setShowChartBar(true);

        return;
      case WebsocketNotifyEnum.COMPLETED_EMPTY:
        setIsEmptyResults(true);
        setIsLoading(false);
        setShowChartBar(false);
        setEmptyResultMessage(EmptyStateLabels.EMPTY_DESCRIPTION);

        return;
      case WebsocketNotifyEnum.PROCESSING:
        setIsEmptyResults(false);
        setShowChartBar(false);
        setIsLoading(true);

        return;
      case WebsocketNotifyEnum.ERROR:
        setIsEmptyResults(true);
        setIsLoading(false);
        setShowChartBar(false);
        setEmptyResultMessage(EmptyStateLabels.ERROR_DESCRIPTION);

        return;
    }
  }, [metricsStatus]);

  useEffect(() => {
    dispatch(setQueryMetricsResponse(undefined));
    setShowChartBar(false);
    setIsEmptyResults(true);
    setEmptyResultMessage(EmptyStateLabels.INITIAL_DESCRIPTION);
  }, []);

  const value: TChannelMetricsContext = {
    changeCountryFilter,
    changePaymentTypeFilter,
    changeProductFilter,
    changeRangeDateFilter,
    changeStatusFilter,
    emptyResultMessage,
    isEmptyResults,
    isLoading,
    isMobile,
    metricsFilters: {
      countryFilter,
      paymentTypeFilter,
      productFilter,
      rangeDateFilter,
      statusFilter,
    },
    showChartBar,
  };

  return (
    <ChannelMetricsContext.Provider value={value}>
      {children}
    </ChannelMetricsContext.Provider>
  );
};

export const useChannelMetricsContext = () => {
  return useContext(ChannelMetricsContext);
};
