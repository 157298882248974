import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, TextField } from "@material-ui/core";
import { MerchantInfo } from "../../../../types/merchant-info";
import { Autocomplete } from "@material-ui/lab";
import { defaultTo, get } from "lodash";
import { MerchantsData } from "../../../store/actionCreators";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiFormControl-root": {
        height: "auto",
      },
      "& > * + *": {
        marginTop: theme.spacing(0),
      },
      "& .MuiInputBase-root": {
        height: "auto",
        width: "auto",
        backgroundColor: "#FFFFFF",
        minWidth: "210px",
        maxWidth: "210px",
      },
    },
    searchComponent: {
      "& .MuiInputBase-input": {
        textAlign: "left !important",
      },
    },
  })
);

export interface AutocompleteTextFieldProps {
  merchantsList: MerchantsData;
  handleChangeMerchants: (
    _event: unknown,
    value: string,
    reason: string
  ) => void;
  handleChangeMerchant: (
    _event: object,
    value: { _source: MerchantInfo }[],
    _reason: string
  ) => void;
}

const AutocompleteTextField: React.FC<AutocompleteTextFieldProps> = (
  props: AutocompleteTextFieldProps
) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container>
        <Grid item>
          <Autocomplete
            id={"autocomplete"}
            size="small"
            className={classes.root}
            multiple
            onChange={props.handleChangeMerchant}
            onInputChange={props.handleChangeMerchants}
            options={get(props.merchantsList!, "data", [])}
            getOptionSelected={(
              option: { _source: MerchantInfo },
              value: { _source: MerchantInfo }
            ) => option._source.name === value._source.name}
            getOptionLabel={(option: { _source: MerchantInfo }) =>
              defaultTo(option._source.name, "")
            }
            renderInput={(params) => (
              <TextField
                className={classes.searchComponent}
                {...params}
                label="Comercios"
                variant="outlined"
                margin="dense"
              />
            )}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AutocompleteTextField;
