import React from "react";
import { SkeletonTable } from "./SkeletonTable";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  paper: {
    backgroundColor: "#F7FAFC",
  },
});

export const SkeletonListContainer: React.FC = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <SkeletonTable />
      </Paper>
    </React.Fragment>
  );
};
