import React, { FC } from "react";
import { useBranchList } from "./state/useBranchList";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { branchListStyles as styles } from "./BranchList.styles";
import {
  BranchEnum,
  ConfigUserModalEnum,
  VariantButtonEnum,
} from "../../shared/enums/BranchEnum";
import { IconCircleInformation } from "@kushki/connect-ui";
import { displayUtils } from "../../shared/utils/DisplayUtils";
import { TableSkeleton } from "../../components/TableSkeleton/TableSkeleton";
import {
  CATALOG_HEADS_NODE,
  NodeTypeEnum,
} from "../../shared/catalogs/CatalogConfigTable";
import ModalSimple from "../../components/ModalSimple/ModalSimple";
import DashboardBranch from "../DashboardBranch/DashboardBranch";

const BranchList: FC = () => {
  const {
    showEmptyScreen,
    emptyAddText,
    branchList,
    entityName,
    pathCustomer,
    modalStatusDashboard,
    handleChangeStatus,
    merchantCustomer,
    isLoadingMerchants,
    modalConfigUser,
  } = useBranchList();

  return (
    <>
      <Box display={displayUtils(!isLoadingMerchants)}>
        <TableSkeleton
          headTable={CATALOG_HEADS_NODE[NodeTypeEnum.BRANCH_OWNER]}
          rowsPerPage={10}
        />
      </Box>

      <Box display={displayUtils(isLoadingMerchants)}>
        <Box>
          <Box sx={styles.createCustomerWrapper}>
            <Box sx={styles.boxIcon}>
              <Typography variant="h5" color="text.dark">
                {BranchEnum.HEADER_TITLE}
              </Typography>
              <Tooltip title={BranchEnum.MESSAGE_TOOLTIP} placement={"top"}>
                <IconButton>
                  <IconCircleInformation />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
        <DashboardBranch
          showEmptyScreen={showEmptyScreen}
          emptyAddText={emptyAddText}
          nodeData={branchList}
          basicNodeInfo={{
            entityName,
            path: pathCustomer,
          }}
          modalStatusDashboard={modalStatusDashboard}
          handleChangeStatus={handleChangeStatus}
          customerNode={merchantCustomer}
          isBranchTab={true}
        />
      </Box>

      <ModalSimple
        isOpen={modalConfigUser.isOpen}
        closeModal={modalConfigUser.closeConfigUserModal}
        title={ConfigUserModalEnum.TITLE}
        description={ConfigUserModalEnum.DESCRIPTION}
        primaryButton={{
          onClick: modalConfigUser.redirectCreateMassiveUsers,
          text: ConfigUserModalEnum.PRIMARY_BUTTON,
        }}
        secondaryButton={ConfigUserModalEnum.SECONDARY_BUTTON}
        primaryButtonVariant={VariantButtonEnum.CONTAINED}
      />
    </>
  );
};

export default BranchList;
