import { DependencyList, useEffect, useState } from "react";

export type State<TData> = {
  loading: boolean;
  data?: TData;
  error?: unknown;
};

const init = {
  loading: true,
};

const useFetchData = <TData>(
  callback: () => Promise<TData>,
  deps?: DependencyList
) => {
  const [state, setState] = useState<State<TData>>(init);

  useEffect(() => {
    (async () => {
      try {
        const data = await callback();

        setState({
          data,
          loading: false,
        });
      } catch (error) {
        setState({
          error,
          loading: false,
        });
      }
    })();
  }, deps);

  return state;
};

export default useFetchData;
