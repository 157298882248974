import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const commentSectionStyles = createNamedStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    backgroundColor: "#F0F4F8",
    borderRadius: "8px",
    padding: "1rem",
  },
  commentInfo: {
    display: "flex",
    justifyContent: "space-between",
  },
  textLightGray: {
    color: "#6D7781",
  },
  comment: {
    color: "#293036",
    textAlign: "justify",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "flex-end",
  },
});
