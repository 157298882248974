import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const background_color = "#F0F4F8";

export const infoModalStyles = createNamedStyles({
  container: {
    backgroundColor: background_color,
    padding: "24px !important",
  },
  itemWrapper: {
    display: "flex",
    flexDirection: "column",
    width: 1,
    paddingRight: "8px",
    paddingBottom: "8px",
  },
  itemTitle: {
    fontSize: "15px !important",
    lineHeight: "144% !important",
  },
  title: {
    fontSize: "16px",
    fontWeight: 500,
    padding: "24px 24px 0 24px",
    color: "#293036",
  },
});
