import React, { useState } from "react";
import { Grid } from "@mui/material";
import { TextFieldCard } from "@kushki/connect-ui";
import { PlaceHolders } from "../../../../../shared/infrastructure/constants/BusinessRuleConstants";
import { FabButtonsContainer } from "../../FabButtonsContainer/FabButtonsContainer";
import { IMaskedCardFieldProps } from "../../../../../shared/infrastructure/interfaces/IMaskedCardFieldsProps";

const MaskedCardField = (props: IMaskedCardFieldProps) => {
  const [editCard, setEditCard] = useState<boolean>(false);

  return (
    <Grid container direction={"row"} sx={{ mt: 1 }} spacing={1}>
      <Grid item xs={4} sx={{ mb: 2 }}>
        <TextFieldCard
          maskedCard="middle"
          value={props.value}
          isDisabled={!editCard}
          onBlur={(cardNumber) => {
            setEditCard(false);
            props.onEdit(cardNumber);
          }}
          placeholder={PlaceHolders.NUMBER}
        />
      </Grid>
      <FabButtonsContainer
        handleEditFabButton={() => {
          setEditCard(true);
        }}
        handleDeleteFabButton={props.onDelete}
      />
    </Grid>
  );
};

export default MaskedCardField;
