import { HeadCell } from "../../components/Table/TableHead/TableHead.interfaces";
import { headCells } from "./CatalogConfigTableBranch";

export enum NodeTypeEnum {
  BRANCH = "BRANCH",
}

export const HEADSTABLE: Record<NodeTypeEnum, HeadCell[]> = {
  [NodeTypeEnum.BRANCH]: headCells,
};
