import React, { useEffect, useState } from "react";
import { IActionButtonsProps } from "../../shared/infrastructure/interfaces/IActionButtonsProps";
import { Box, Button, Grid } from "@mui/material";
import { LabelEnum } from "../../shared/infrastructure/enums/LabelEnum";
import { defaultTo, isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import { BusinessRuleStatus } from "../../shared/infrastructure/constants/BusinessRuleConstants";
import { get } from "lodash";
import { useFormContext } from "react-hook-form";
import { FormFieldEnum } from "../../shared/infrastructure/enums/FormFieldEnum";
import { IConditionItem } from "../../shared/infrastructure/interfaces/IConditionItem";
import {
  AlertEnum,
  ERROR_MESSAGE_ALERT,
} from "../../shared/infrastructure/enums/AlertEnum";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import { StylesEnum } from "../../shared/infrastructure/constants/StyleEnum";
import { useSnackbar } from "@kushki/connect-ui";
import { getIsMassiveRules } from "../../shared/utils/localStorage";

export const ActionButtons: React.FC<IActionButtonsProps> = (
  props: IActionButtonsProps
) => {
  const { ruleId } = useParams();
  const { watch, getValues } = useFormContext();
  const watchProcessorName = watch(FormFieldEnum.RULE_PROCESSOR_NAME);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const { showSnackbar } = useSnackbar();
  useEffect(() => {
    setIsButtonDisabled(
      isEmpty(watchProcessorName) || props.fields.length === 0
    );
  }, [watchProcessorName, props.fields]);

  const getPrimaryLabel = (): string => {
    if (isEmpty(ruleId)) {
      return LabelEnum.BTN_ADD_RULE;
    }
    if (get(props, "editRule.status", "") === BusinessRuleStatus.ENABLE)
      return LabelEnum.BTN_EDIT_RULE_ENABLE_PRIMARY;
    if (get(props, "editRule.rule.status", "") === BusinessRuleStatus.DISABLE)
      return LabelEnum.BTN_ENABLE_RULE_DRAFT_PRIMARY;
    return LabelEnum.BTN_EDIT_RULE_DRAFT_PRIMARY;
  };

  const saveDraftConditionRender = (): boolean => {
    return (
      isEmpty(ruleId) ||
      get(props, "editRule.status", "") === BusinessRuleStatus.DRAFT
    );
  };

  const saveForm = (): void => {
    const conditions: IConditionItem[] = defaultTo(
      getValues()["condition"],
      []
    );

    for (const condition of conditions) {
      if (isEmpty(condition.value) || get(condition, "value.[0]", "") === "") {
        showSnackbar({
          message: ERROR_MESSAGE_ALERT[AlertEnum.ORIGIN_EMPTY],
          variant: "simple",
          color: "danger",
          icon: (
            <ErrorOutlinedIcon
              fontSize={StylesEnum.INHERIT}
              color={StylesEnum.INHERIT}
            />
          ),
          withIcon: true,
        });
        return;
      }
    }
    props.onAddRule();
  };

  const isMassiveRules = getIsMassiveRules();

  return (
    <Grid item xs={12}>
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-end"
        direction={"row"}
      >
        {saveDraftConditionRender() && (
          <Box mr={3} pt={4}>
            <Button
              color="secondary"
              variant="outlined"
              size="small"
              onClick={
                isMassiveRules ? props.onRedirectPath : props.onSaveDraft
              }
            >
              {isMassiveRules ? LabelEnum.BACK : LabelEnum.BTN_SAVE_DRAFT}
            </Button>
          </Box>
        )}

        <Box pt={4}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={saveForm}
            disabled={isButtonDisabled}
          >
            {getPrimaryLabel()}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
