import {
  INodeRule,
  IVariableRule,
} from "@containers/AlarmConfig/AlarmConfig.interfaces";
import {
  FieldOptionValue,
  InputNameForm,
  OperatorOptionValue,
  TypeEnum,
} from "@shared/constants/AlarmConfigConstants";
import {
  FieldValues,
  UseFieldArrayRemove,
  UseFieldArrayReturn,
} from "react-hook-form";
import { get, isEmpty, unset } from "lodash";
import { getItemSessionStorage } from "@shared/utils/sessionStorageUtil";
import { SessionStorageKey } from "@shared/enum/sessionStorageKeyEnum";
import { INodeRuleSessionStorage } from "@shared/interfaces/INodeRuleSessionStorage";
import { parseDateToSave } from "@shared/utils/filterUtils";
import { SearchRuleAlarmRequest } from "../../../types/search_rule_alarm_request";
import { FilterKey } from "@shared/enum/FilterKeyEnum";

export const removeVariableSection = (
  filterSection: IVariableRule[],
  variables: IVariableRule[],
  remove: UseFieldArrayRemove
): void => {
  filterSection.forEach((section, index) => {
    if (section.operator === OperatorOptionValue.EQUAL) {
      const sectionPosition = variables.findIndex(
        (item) =>
          item.field === filterSection[index].field &&
          item.operator !== filterSection[index].operator
      );

      remove(sectionPosition);
    }
  });
};

export const operationIsEqual = (filterSection: IVariableRule[]): boolean => {
  for (const section of filterSection) {
    if (section.operator === OperatorOptionValue.EQUAL) {
      return true;
    }
  }

  return false;
};

export function findDuplicatedOperator(
  filterSection: IVariableRule[]
): boolean {
  for (let i = 0; i < filterSection.length; i++) {
    for (let j = i + 1; j < filterSection.length; j++) {
      if (filterSection[i].operator === filterSection[j].operator) {
        return true;
      }
    }
  }

  return false;
}

export const verifyEmptyProperties = (variables: IVariableRule[]): boolean => {
  for (const variable of variables) {
    if (
      isEmpty(`${variable.field}`) ||
      isEmpty(`${variable.operator}`) ||
      isEmpty(`${variable.value}`)
    ) {
      return true;
    }
  }

  return false;
};

export const getFieldByOperatorEqual = (
  variables: IVariableRule[]
): string[] => {
  return variables.map((item: IVariableRule) => {
    if (
      [OperatorOptionValue.EQUAL].includes(item.operator as OperatorOptionValue)
    ) {
      return item.field;
    }

    return "";
  });
};

export const buildNodesRules = (): INodeRule[] => {
  const nodesSessionStorage = getItemSessionStorage<INodeRuleSessionStorage[]>(
    SessionStorageKey.SELECTED_NODES,
    "[]"
  );

  return nodesSessionStorage.map((node: INodeRuleSessionStorage) => ({
    constitutionalCountry: get(node, "constitutionalCountry", ""),
    country: get(node, "country", ""),
    entityName: get(node, "entityName", ""),
    merchantId: get(node, "merchantId", ""),
    name: get(node, "name", ""),
    path: get(node, "path", ""),
  }));
};

export const parseVariables = (variables: IVariableRule[]) => {
  return variables.map((variable: IVariableRule) => {
    if (
      [
        FieldOptionValue.TPV,
        FieldOptionValue.AVERAGE_TICKET,
        FieldOptionValue.STANDARD_DEVIATION,
      ].includes(variable.field as FieldOptionValue)
    ) {
      return { ...variable, value: Number(variable.value) };
    }

    if (variable.field === FieldOptionValue.CONSTITUTION_DATE) {
      return {
        ...variable,
        value: parseDateToSave(variable.value as string),
      };
    }

    return variable;
  });
};

export const removeSectionsStandardDeviation = (
  variablesFields: UseFieldArrayReturn<
    FieldValues,
    InputNameForm.VARIABLES,
    string
  >
) => {
  variablesFields.remove();
  variablesFields.update(0, { field: FieldOptionValue.STANDARD_DEVIATION });
};

export const deleteEmptyFiltersAlarmConfig = (
  requestRulesAlarms: SearchRuleAlarmRequest,
  tabType: string,
  searchValue: string
) => {
  if (tabType === TypeEnum.INDIVIDUAL || isEmpty(searchValue))
    unset(requestRulesAlarms, "filter.alias");

  for (const key in requestRulesAlarms.filter) {
    if ([FilterKey.TO, FilterKey.FROM].includes(key as FilterKey)) continue;
    if (isEmpty(requestRulesAlarms.filter[key]))
      delete requestRulesAlarms.filter[key];
  }

  if (
    !get(requestRulesAlarms, "filter.from") ||
    !get(requestRulesAlarms, "filter.to")
  ) {
    unset(requestRulesAlarms, "filter.from");
    unset(requestRulesAlarms, "filter.to");
  }
};
