export enum PaymentMethodEnum {
  CARD = "card",
  CARD_PRESENT = "card_present",
  TRANSFER = "transfer",
  CASH = "cash",
}
export enum PaymentMethodUpperEnum {
  CARD = "CARD",
  TRANSFER = "TRANSFER",
  CASH = "CASH",
}

export enum ServicesEnum {
  OTP = "otp",
  SIFTSCIENCE = "siftScience",
  _3DS = "3ds",
  TRANSUNION = "transUnion",
}

export enum PaymentMethodLabelEnum {
  CARD = "Tarjeta Online",
  CARD_PRESENT = "Tarjeta Presencial",
  TRANSFER = "Transferencia",
  CASH = "Efectivo",
  TRANSACTIONS = "Transacciones",
}

export const CATALOG_PAYMENT_METHOD: Record<
  PaymentMethodEnum,
  PaymentMethodLabelEnum
> = {
  [PaymentMethodEnum.CASH]: PaymentMethodLabelEnum.CASH,
  [PaymentMethodEnum.CARD]: PaymentMethodLabelEnum.CARD,
  [PaymentMethodEnum.CARD_PRESENT]: PaymentMethodLabelEnum.CARD_PRESENT,
  [PaymentMethodEnum.TRANSFER]: PaymentMethodLabelEnum.TRANSFER,
};
