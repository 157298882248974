import { Accordion, IconBank, IconCircleCheck } from "@kushki/connect-ui";
import { Grid } from "@mui/material";
import { ShareholderAccordionContent } from "../ShareholderAccordionContent/ShareholderAccordionContent";
import { CustomStyles } from "../../../shared/infrastructure/interfaces/CustomStyles";
import { Shareholder } from "../../../shared/infrastructure/interfaces/Shareholder";

interface Props {
  shareholder: Shareholder;
}

export const styles: CustomStyles = {
  accordion: {
    paddingTop: "40px",
  },
};

export const ShareholderAccordion: React.FC<Props> = ({ shareholder }) => {
  const { text, open, text2, badgeText, shareholderInfo } = shareholder;

  return (
    <Grid sx={styles.accordion}>
      <Accordion
        badgeColor="secondary"
        badgeText={`${badgeText}%`}
        divider
        iconLeft={<IconBank />}
        iconRight={<IconCircleCheck />}
        nesting={1}
        nestingLabel=""
        tagColor={shareholderInfo.isMoral ? "success" : "primary"}
        tagText={shareholderInfo.type!}
        text={text}
        text2={text2}
        isOpen={open}
      >
        <ShareholderAccordionContent shareholder={shareholder} />
      </Accordion>
    </Grid>
  );
};
