import { createAction } from "@reduxjs/toolkit";
import { catchError, EMPTY, ignoreElements, switchMap, tap } from "rxjs";
import { filter } from "rxjs/operators";
import { EpicCustom } from "../epics.interfaces";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import axios from "../../../shared/axios-util";
import {
  setCreateExpressCheckoutStatus,
  setExpressCheckout,
} from "../../actions/app.actions";
import { RequestStatusEnum } from "../../../shared/enums/RequestStatusEnum";
import { CreateExpressCheckoutRequest } from "../../../../types/create_express_checkout_request";
import { CreateExpressCheckoutResponse } from "../../../../types/create_express_checkout_response";

export const createExpressCheckout = createAction<CreateExpressCheckoutRequest>(
  "CREATE_EXPRESS_CHECKOUT"
);

export const createExpressCheckoutEpic: EpicCustom = ({ action$, dispatch }) =>
  action$.pipe(
    filter(createExpressCheckout.match),
    tap(() =>
      dispatch(setCreateExpressCheckoutStatus(RequestStatusEnum.PENDING))
    ),
    switchMap((body: { payload: CreateExpressCheckoutRequest }) =>
      axios
        .post<CreateExpressCheckoutResponse>(
          API_ROUTES.CREATE_EXPRESS_CHECKOUT,
          body.payload
        )
        .pipe(
          tap(({ data }: { data: CreateExpressCheckoutResponse }) => {
            if (data.webcheckoutId) {
              dispatch(setExpressCheckout(data));
              dispatch(
                setCreateExpressCheckoutStatus(RequestStatusEnum.SUCCESS)
              );
            } else
              dispatch(setCreateExpressCheckoutStatus(RequestStatusEnum.ERROR));
          }),
          catchError(() => {
            dispatch(setCreateExpressCheckoutStatus(RequestStatusEnum.ERROR));

            return EMPTY;
          })
        )
    ),
    ignoreElements()
  );
