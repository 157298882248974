import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { IMassiveActionsUsersProps } from "./MassiveActionsUsers.interfaces";
import { massiveActionsUsersStyles as styles } from "./MassiveActionsUsers.styles";
import KeyIcon from "../../assets/icons/KeyIcon";
import TrashIcon from "../../assets/icons/TrashIcon";
import { useMassiveActionsUsers } from "./state/useMassiveActionsUsers";
import { ActionsUserTypeEnum } from "../../shared/enum/UsersTableEnum";

const MassiveActionsUsers: React.FC<IMassiveActionsUsersProps> = ({
  totalSelected,
}: IMassiveActionsUsersProps) => {
  const { handleOpenModalMassive } = useMassiveActionsUsers();

  return (
    <Box sx={styles.container}>
      <Box>
        <Typography sx={styles.totalDescription}>
          Customers seleccionados:{" "}
          <Box component={"span"} sx={styles.total}>
            {totalSelected}
          </Box>
        </Typography>
      </Box>
      <Box sx={styles.buttonsContainer}>
        <Button
          variant={"outlined"}
          startIcon={<KeyIcon />}
          sx={styles.button}
          onClick={() =>
            handleOpenModalMassive(
              totalSelected,
              ActionsUserTypeEnum.MASSIVE_PASSWORD
            )
          }
        >
          Restablecer contraseña
        </Button>
        <Button
          variant={"outlined"}
          sx={styles.button}
          color={"error"}
          startIcon={<TrashIcon color={"#E24763"} />}
          onClick={() =>
            handleOpenModalMassive(
              totalSelected,
              ActionsUserTypeEnum.MASSIVE_DELETE
            )
          }
        >
          Eliminar ({totalSelected})
        </Button>
      </Box>
    </Box>
  );
};

export default MassiveActionsUsers;
