import React, { FC } from "react";
import { Box } from "@mui/material";
import GreyCardContainer from "@components/layout/GreyCardContainer/GreyCardContainer";
import { AlarmDetailCardEnum } from "@shared/enum/AlarmDetailCardEnum";
import { IInformationTab } from "@components/organism/DetailCardBodyAlarms/state/IUseDetailCardBodyAlarms.interfaces";
import FieldValueDetailCard from "@components/atom/FieldValueDetailCard/FieldValueDetailCard";
import { informationTabStyles as styles } from "@components/molecule/InformationTab/InformationTab.styles";
import GeneralConditionContainer from "@components/DetailCardBody/GeneralConditionContainer/GeneralConditionContainer";

const InformationTab: FC<IInformationTab> = ({
  informationSection,
  familySection,
  amountDeviationSection,
  transactionDeviationSection,
  variables,
  showAmount,
  showTransactions,
  showFamily,
  rowData,
  mccCatalog,
}: IInformationTab) => {
  return (
    <Box sx={styles.container}>
      <GreyCardContainer title={AlarmDetailCardEnum.ALARM_INFORMATION}>
        {informationSection.map((item) => (
          <FieldValueDetailCard key={item.label} data={item} />
        ))}
      </GreyCardContainer>
      {showFamily && (
        <GreyCardContainer title={AlarmDetailCardEnum.CUSTOMER_FAMILY}>
          {familySection.map((item) => (
            <FieldValueDetailCard key={item.label} data={item} />
          ))}
        </GreyCardContainer>
      )}
      {showAmount && (
        <GreyCardContainer title={AlarmDetailCardEnum.AMOUNT_DEVIATION}>
          {amountDeviationSection.map((item) => (
            <FieldValueDetailCard key={item.label} data={item} />
          ))}
        </GreyCardContainer>
      )}
      {showTransactions && (
        <GreyCardContainer title={AlarmDetailCardEnum.TRANSACTION_DEVIATION}>
          {transactionDeviationSection.map((item) => (
            <FieldValueDetailCard key={item.label} data={item} />
          ))}
        </GreyCardContainer>
      )}
      <GreyCardContainer title={AlarmDetailCardEnum.ALARM_VARIABLE}>
        <GeneralConditionContainer
          variables={variables}
          isAlarmVariable={true}
          rowData={rowData}
          mccCatalog={mccCatalog}
        />
      </GreyCardContainer>
    </Box>
  );
};

export default InformationTab;
