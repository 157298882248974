import React, { FC } from "react";
import { mainSubHeaderStyles as styles } from "./Subheader.styles";
import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";

import { getCountryFlag } from "../../shared/constants/flags/countryFlag";
import { ISubheaderProps } from "./Subheader.interfaces";
import { IconEdit } from "@kushki/connect-ui";

export const Subheader: FC<ISubheaderProps> = ({
  title,
  constitutionalCountry,
  id,
}: ISubheaderProps) => {
  return (
    <Box>
      <Grid container alignItems="center" sx={styles.gridTitle}>
        <Grid item md={12} sx={styles.gridSubTitle}>
          <Grid item md={6} sx={styles.gridSubTitle}>
            <Typography sx={styles.title}>{title}</Typography>
            <IconButton>
              <IconEdit color="primary" fontSize="medium" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item container xs={12} md={"auto"} alignItems="center">
          <Grid item sx={styles.gridText}>
            <Typography variant="caption" color="text.primary">
              País de operación:{" "}
            </Typography>
          </Grid>
          <Grid item>
            <Box>{getCountryFlag[constitutionalCountry]}</Box>
          </Grid>
          <Grid item sx={styles.gridCountry}>
            <Typography variant="body1" color="text.primary">
              {constitutionalCountry}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={"auto"} alignItems="center">
          <Divider orientation="vertical" flexItem sx={styles.divider} />
          <Grid item sx={styles.gridId}>
            <Typography variant="body2" color="text.primary">
              ID:{" "}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" color="text.primary">
              {id}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Subheader;
