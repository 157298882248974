import {
  ActionReducerMapBuilder,
  createSlice,
  Draft,
  PayloadAction,
} from "@reduxjs/toolkit";
import { CreateMerchantNodeResponse } from "../../../../types/create_merchant_node_response";
import { ICreateNode } from "../../interfaces/createNode/nodes.interfaces";
import createMerchantNode, {
  getNodeInfo,
  searchMerchantByNode,
} from "../../thunks/createNode/createNode.thunks";
import { GetNodeInfoResponse } from "../../../../types/get_node_info_response";
import { NotificationTypeEnum } from "../../../shared/enums/SnackbarEnum";
import {
  buildNotification,
  defaultDataSnackbar,
} from "../../../shared/constants/snackbar";
import { get } from "lodash";
import { ISnackBar } from "@kushki/connect-ui/dist/Components/Atoms/DataDisplay/SnackBar/SnackBar.interface";
import { ErrorResponse } from "../../../../types/error_response";

export const initialState: ICreateNode = {
  getNodeInfoResponse: {
    configCoreId: "",
    configs: [{}],
    countryCode: "",
    entityName: "",
    generalInfo: {
      clientType: "",
      country: "",
      name: "",
      operationalCountry: "",
      publicMerchantId: "",
    },
    merchantId: "",
    nodeId: "",
    path: "",
    rootId: "",
  },
  notification: undefined,
  response: {
    clientType: "",
    configCoreId: "",
    country: "",
    countryCode: "",
    created: 0,
    description: "",
    entityName: "",
    name: "",
    nodeId: "",
    nodeType: "",
    operationalCountry: "",
    publicMerchantId: "",
    rootId: "",
    status: "",
    updatedAt: 0,
    userAlias: "",
    userName: "",
  },
};

export const createMerchantNodeSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(
        createMerchantNode.fulfilled,
        (state, { payload }: PayloadAction<CreateMerchantNodeResponse>) => {
          state.response = payload;
          state.notification = buildNotification(
            NotificationTypeEnum.SUCCESS,
            get(state, "notification", defaultDataSnackbar)
          );
        }
      )
      .addCase(createMerchantNode.rejected, (state, { payload }) => {
        state.response = <ErrorResponse>payload;
        state.notification = buildNotification(
          NotificationTypeEnum.FAILED,
          get(state, "notification", defaultDataSnackbar)
        );
      });
  },
  initialState,
  name: "createMerchantNode",
  reducers: {
    setNotification: (
      state: Draft<ICreateNode>,
      { payload }: PayloadAction<ISnackBar | undefined>
    ) => {
      state.notification = payload;
    },
  },
});
export const getNodeInfoSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(
      getNodeInfo.fulfilled,
      (state, { payload }: PayloadAction<GetNodeInfoResponse>) => {
        state.getNodeInfoResponse = payload;
      }
    );
  },
  initialState,
  name: "getNodeInfo",
  reducers: {},
});
export const searchMerchantByNodeSlice = createSlice({
  extraReducers: (builder: ActionReducerMapBuilder<ICreateNode>) => {
    builder.addCase(
      searchMerchantByNode.fulfilled,
      (state, { payload }: PayloadAction<GetNodeInfoResponse>) => {
        state.getNodeInfoResponse = payload;
      }
    );
  },
  initialState,
  name: "searchMerchantByNode",
  reducers: {},
});

export default createMerchantNodeSlice.reducer;
