import { FrequencyEnum } from "../enums/frequencyEnum";

export const INVOICE_FREQUENCY: { name: string; value: string }[] = [
  { name: "Ninguno", value: FrequencyEnum.NONE },
  { name: "Diario", value: FrequencyEnum.DAILY },
  { name: "Semanal", value: FrequencyEnum.WEEKLY },
  { name: "Mar-Vie", value: FrequencyEnum.TWO_WEEKLY },
  { name: "Quincenal", value: FrequencyEnum.BI_WEEKLY },
  { name: "Lun-Mie-Vie", value: FrequencyEnum.THREE_WEEKLY },
  { name: "Mensual", value: FrequencyEnum.MONTHLY },
];
