import React from "react";
import {
  FilterDetailBar,
  FilterSideBar,
  RangeDatePicker,
  SelectCountry,
} from "@kushki/connect-ui";
import { Grid } from "@mui/material";
import { metricsFilterBarStyles as styles } from "./MetricsFilterBarStyles";
import { useMetricsFilterBarState } from "./state/useMetricsFilterBarState";
import { CategoryFiltersItems } from "../../shared/constants/MetricsFilterBar/FilterItems";
import { MetricsContainersLabels } from "../../shared/labels/MetricsContainers.labels";
import { DateFormatEnum } from "../../shared/enums/DateFormatEnum";

export const MetricsFilterBar = () => {
  const {
    onSaveFilterSideBar,
    onDeleteItemFilterBar,
    onSelectCountry,
    onSelectRangeDate,
    filterDetail,
    minDate,
  } = useMetricsFilterBarState();

  return (
    <Grid container spacing={2} data-testid="filters-container">
      <Grid item sm={10} md={3}>
        <SelectCountry
          onChange={onSelectCountry}
          placeHolder={MetricsContainersLabels.COUNTRY_FILTER_TITLE}
          typeList={"top"}
        />
      </Grid>
      <Grid item sm={2} md={2} sx={styles.filterSideBarButtonBox}>
        <FilterSideBar
          isMinimized={false}
          isOpen={false}
          categoryItems={CategoryFiltersItems}
          filtersCount={0}
          onClick={onSaveFilterSideBar}
          title={MetricsContainersLabels.SIDE_BAR_FILTER_TITLE}
        />
      </Grid>
      <Grid item sm={12} md={7} sx={styles.datePickerBox}>
        <RangeDatePicker
          dateFormat={DateFormatEnum.yyyyMMdd}
          onDateSelect={onSelectRangeDate}
          placeholder={MetricsContainersLabels.RANGE_DATE_FILTER_TITLE}
          isOpen={false}
          isFilled={false}
          disabledFutureDate={true}
          disabledOldDate={false}
          minDate={minDate}
        />
      </Grid>
      <Grid item md={12}>
        <FilterDetailBar
          filters={filterDetail}
          onChangeFilters={onDeleteItemFilterBar}
        />
      </Grid>
    </Grid>
  );
};
