import moment from "moment";

export const START_UTC_SUFFIX: string = "T00:00:00";
export const END_UTC_SUFFIX: string = "T23:59:59";

export const DATE_VALUES = {
  HOURS_IN_A_DAY: 24,
  MINUTES_PER_HOUR: 60,
};

export const FORMAT_DATE = {
  FORMAT_DATE_FILTER: "YYYY-MM-DD",
  FORMAT_DATE_RANGE_PICKER: "DD/MMM/YYYY",
};

export const MOMENT_LANGUAGE = "es-es";

export const QUERY_DATE_START = `${moment()
  .subtract(1, "month")
  .format(FORMAT_DATE.FORMAT_DATE_FILTER)}${START_UTC_SUFFIX}`;

export const QUERY_DATE_END = `${moment().format(
  FORMAT_DATE.FORMAT_DATE_FILTER
)}${END_UTC_SUFFIX}`;

export const START_PICKER = `${moment()
  .subtract(1, "month")
  .format(FORMAT_DATE.FORMAT_DATE_RANGE_PICKER)}`;

export const END_PICKER = `${moment().format(
  FORMAT_DATE.FORMAT_DATE_RANGE_PICKER
)}`;
