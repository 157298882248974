import { useEffect, useState } from "react";
import { SnackBarAlertProps } from "./SnackBarAlert";

export interface SnackBarAlertState {
  open: boolean;
}

export const useSnackBarAlertState = (
  props: SnackBarAlertProps
): SnackBarAlertState => {
  const [open, setOpen] = useState(props.open);

  useEffect(() => setOpen(props.open), [props.open]);

  return { open };
};
