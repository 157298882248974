export enum ModelValueEnum {
  B2C = "4",
  gateway = "3",
  aggregator_formal = "1",
  aggregator_gateway = "5",
  aggregator_collection = "2",
}

export enum ModelEnum {
  B2C = "B2C",
  gateway = "gateway",
  aggregator_formal = "aggregator_formal",
  aggregator_gateway = "aggregator_gateway",
  aggregator_collection = "aggregator_collection",
}

export enum ModelsEnum {
  GATEWAY = "B2B_PAYIN_GATEWAY",
  AGGREGATOR = "B2B_PAYIN_AGGREGATOR",
  PAYOUT = "B2B_PAYOUT",
}

export const MODEL_NAME: Record<ModelValueEnum, ModelEnum> = {
  [ModelValueEnum.B2C]: ModelEnum.B2C,
  [ModelValueEnum.gateway]: ModelEnum.gateway,
  [ModelValueEnum.aggregator_formal]: ModelEnum.aggregator_formal,
  [ModelValueEnum.aggregator_gateway]: ModelEnum.aggregator_gateway,
  [ModelValueEnum.aggregator_collection]: ModelEnum.aggregator_collection,
};
