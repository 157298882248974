import { ICellRow } from "./CellRow.interfaces";
import { TableCell, Theme } from "@mui/material";
import React, { FC, PropsWithChildren, ReactElement } from "react";
import { IStyles } from "../../../shared/interfaces/Styles.interfaces";
import { CellHeaderAlignEnum } from "../CellHeader/enums";
import { useMobileState } from "../../../shared/state/useMobileState";

const CellRowItem: FC<ICellRow> = ({
  align = CellHeaderAlignEnum.CENTER,
  children,
  spacing = 0,
}: PropsWithChildren<ICellRow>) => {
  const CellRowStyles: IStyles = {
    cellRow: (theme: Theme) => ({
      border: "none",
      height: "70px",
      padding: `0px ${theme.spacing(Number(spacing) + 2)}`,
      textAlign: align,
    }),
    cellRowMobile: (theme: Theme) => ({
      border: "none",
      height: "min-content",
      paddingLeft: theme.spacing(Number(spacing) + 2),
      paddingRight: theme.spacing(Number(spacing) + 2),
      textAlign: "left",
    }),
  };

  const { isMobile } = useMobileState();

  return (
    <TableCell
      align={isMobile ? "left" : align}
      sx={isMobile ? CellRowStyles.cellRowMobile : CellRowStyles.cellRow}
    >
      {children}
    </TableCell>
  );
};

export const CellRow: FC<ICellRow> = (props: ICellRow): ReactElement => {
  return <CellRowItem {...props} />;
};
