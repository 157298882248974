import React from "react";
import { Breadcrumbs, Link } from "@mui/material";
import { IGenericBreadcrumbProps } from "../../shared/infrastructure/interfaces/IGenericBreadcrumbProps";
import { ColorConstants } from "../../shared/infrastructure/constants/ColorConstants";

export const GenericBreadcrumb: React.FC<IGenericBreadcrumbProps> = ({
  breadcrumbItems,
}: IGenericBreadcrumbProps) => {
  return (
    <Breadcrumbs color={ColorConstants.GRAY} aria-label="breadcrumb">
      {breadcrumbItems.map(
        ({ color = ColorConstants.GRAY, underline, url, label }, i) => (
          <Link
            key={`bcItem-${i}`}
            underline={underline}
            color={color}
            href={url}
            data-testid={"link-breadcrum"}
          >
            {label}
          </Link>
        )
      )}
    </Breadcrumbs>
  );
};
