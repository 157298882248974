import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/system";

export const Spinner = () => (
  <Box sx={{ position: "relative" }}>
    <CircularProgress
      variant="determinate"
      size={50}
      sx={{
        color: "primary.light3",
      }}
      value={100}
    />
    <CircularProgress
      size={50}
      sx={{
        color: "primary.light2",
        left: 0,
        position: "absolute",
      }}
    />
  </Box>
);
