import { createNamedStyles } from "../../shared/infrastructure/interfaces/create_named_styles";

export const useFooterStyles = createNamedStyles({
  containerFooter: {
    backgroundColor: "#FAFCFD",
    bottom: 0,
    position: "fixed",
    width: 1,
    padding: "32px 56px",
    marginLeft: 0,
    boxShadow: "rgba(106, 167, 197, 0.075) 0px -8px 17px",
  },
  gridFooter: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttonsContainer: {
    display: "flex",
    paddingLeft: "44px",
  },
  labelContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "290px",
  },
  button: {
    fontWeight: 500,
  },
  saveButton: {
    marginRight: "40px",
  },
  nextButton: {
    backgroundColor: "#4499EE",
    color: "#FFFFFF",
    borderColor: "#4499EE",
    "&:hover": {
      backgroundColor: "#BFDFFA",
      color: "#FFFFFF",
      borderColor: "#BFDFFA",
    },
    "&:disabled": {
      backgroundColor: "#BFDFFA",
      color: "#FFFFFF",
      borderColor: "#BFDFFA",
    },
  },
  saveButtonBatch: {
    borderColor: "#4499EE",
    "&:hover": {
      backgroundColor: "#BFDFFA",
      color: "#FFFFFF",
      borderColor: "#BFDFFA",
    },
  },
  saveButtonDeferred: {
    backgroundColor: "#FFFFFF",
    color: "#4499EE",
    borderColor: "#4499EE",
    marginRight: "10px",
    "&:hover": {
      backgroundColor: "#F0F4F9",
      color: "#CBD5E0",
      borderColor: "#CBD5E0",
    },
    "&:disabled": {
      backgroundColor: "#F0F4F9",
      color: "#CBD5E0",
      borderColor: "#F0F4F9",
    },
  },
});
