import { addSeconds, fromUnixTime, getUnixTime, isBefore } from "date-fns";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { Data } from "../../../../../types/filter_wallet_dashboard_response";
import { FileExpirationEnum } from "../../../../shared/infrastructure/enums/FileExpirationEnum";
import { FilterEnum } from "../../../../shared/infrastructure/enums/FilterEnum";

export interface IUseTransactionFooter {
  downloadFile: () => void;
  isVisible: boolean;
}

export const useTransactionFooter = (
  transaction: Data
): IUseTransactionFooter => {
  const [isVisible, setVisible] = useState<boolean>(false);

  const downloadFile = () => {
    const evidence: string = get(transaction, "metadata.evidence", "");

    const link = document.createElement("a");

    link.href = evidence;
    link.click();
  };

  useEffect(() => {
    const transaction_type = get(transaction, "transaction_type", "");
    const created = get(transaction, "created", "");

    if (transaction_type === FilterEnum.CREDIT) {
      const today = fromUnixTime(getUnixTime(new Date()));
      const expiration_day = fromUnixTime(
        getUnixTime(addSeconds(new Date(created), FileExpirationEnum["7D"]))
      );

      if (isBefore(today, expiration_day)) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [transaction]);

  return {
    downloadFile,
    isVisible,
  };
};
