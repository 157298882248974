import { createAsyncThunk, ThunkAction } from "@reduxjs/toolkit";
import { THUNK_PREFIXES } from "../../../shared/constants/thunk_prefixes";
import axios from "axios";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { getJwtAuth } from "../../../shared/utils/getJwtAuth_utils";
import { MassiveUserRequest } from "../../../../types/massive_user_request";
import { MassiveUserResponse } from "../../../../types/massive_user_response";
import { IDataWebsockets } from "./massiveUserForm.interfaces";
import { get } from "lodash";
import { SocketEnum } from "../../../shared/enum/SocketEnum";
import { buildNotification } from "../../../shared/constants/snackbar";
import { NotificationTypeEnum } from "../../../shared/enum/SnackbarEnum";
import { MassiveUserForm } from "../../interfaces/massiveUserForm.interfaces";
import {
  setFileValidated,
  setIsLoadingSocket,
  setNotification,
} from "../../reducers/massiveUserForm/massiveUserForm.slice";
import { MessageError } from "../../../shared/enum/FileUploadEnum";

export type IAppAction = {
  type: string;
};
export const downloadMassiveUser = createAsyncThunk<
  MassiveUserResponse,
  MassiveUserRequest
>(THUNK_PREFIXES.DOWNLOAD_MASSIVE_USER, async (request: MassiveUserRequest) => {
  return axios.post(API_ROUTES.DOWNLOAD_MASSIVE_USER, request, {
    headers: {
      Authorization: getJwtAuth(),
      "Content-Type": "application/json",
    },
  });
});

/* istanbul ignore next */
export const validateFileUserSocket = (
  payload: string
): ThunkAction<void, MassiveUserForm, undefined, IAppAction> => {
  return async (dispatch): Promise<void> => {
    const jwt: string | null = localStorage.getItem("jwt");
    let webSocket: WebSocket | undefined;
    const params: object = {
      action: "validateMassiveUser",
      data: {
        file: payload,
      },
    };

    try {
      webSocket = new WebSocket(
        `${API_ROUTES.VALIDATE_USER}?Authorization=${jwt}`
      );
      webSocket.onopen = () => {
        webSocket?.send(JSON.stringify(params));
      };

      webSocket.onerror = () => {
        dispatch(
          setNotification(
            buildNotification(NotificationTypeEnum.FAILED, {
              color: "danger",
              message: MessageError.SNACK_BAR_VALIDATION,
              variant: "simple",
              withIcon: false,
            })
          )
        );
        webSocket?.close();
      };

      webSocket.onmessage = (event: MessageEvent) => {
        const response: IDataWebsockets = JSON.parse(event.data);
        const status: string | undefined = get(response, "status");
        const data = get(response, "data", {});

        dispatch(setIsLoadingSocket(true));

        if (status === SocketEnum.COMPLETE) {
          dispatch(setIsLoadingSocket(false));
          dispatch(setFileValidated(data));

          webSocket?.close();

          return;
        }
        if (status === SocketEnum.ERROR) {
          dispatch(setIsLoadingSocket(false));
          dispatch(
            setNotification(
              buildNotification(NotificationTypeEnum.FAILED, {
                color: "danger",
                message: MessageError.SNACK_BAR_VALIDATION,
                variant: "simple",
                withIcon: false,
              })
            )
          );
          webSocket?.close();

          return;
        }
      };
    } catch (e) {
      dispatch(
        setNotification(
          buildNotification(NotificationTypeEnum.FAILED, {
            color: "danger",
            message: MessageError.SNACK_BAR_VALIDATION,
            variant: "simple",
            withIcon: false,
          })
        )
      );
      if (webSocket) webSocket.close();
    }
  };
};
