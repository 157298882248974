import { MerchantResponse } from "../../types/merchant_response";
import { UpdateMerchantInfoRequest } from "../../types/update_merchant_info_request";
import { CreateMerchantPeopleRequest } from "../shared/Interfaces/CreateMerchantPeopleRequest";
import { ActionTypes } from "./actionTypes";
import { IAppState } from "./reducer";
import { INotification } from "../shared/infrastructure/interfaces/INotification";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AxiosResponse } from "axios";
import axios from "../shared/utils/axios-util";
import { environment } from "../environments/environment";
import { MerchantPeople } from "../../types/merchant_people";
import { isEmpty } from "lodash";

export type IAppAction = { type: string } & Partial<IAppState>;

export const setNotification = (payload: INotification) => ({
  notification: payload,
  type: ActionTypes.SET_NOTIFICATION,
});

export const setMerchantInformation = (
  payload: MerchantResponse
): IAppAction => ({
  merchantInformation: payload,
  type: ActionTypes.SET_MERCHANT_INFORMATION,
});

export const setCompliancePeople = (payload: MerchantPeople): IAppAction => ({
  compliancePeople: payload,
  type: ActionTypes.SET_COMPLIANCE_PEOPLE,
});

export const setLoading = (): IAppAction => ({
  type: ActionTypes.SET_LOADING,
});

export const hideLoading = (): IAppAction => ({
  type: ActionTypes.HIDE_LOADING,
});

export const setCompleteSave = (payload: boolean): IAppAction => ({
  completeSave: payload,
  type: ActionTypes.SET_COMPLETE_SAVE,
});

export const setCompleteGetCompliance = (payload: boolean): IAppAction => ({
  completeGetCompliance: payload,
  type: ActionTypes.SET_COMPLETE_GET_COMPLIANCE,
});

export const getMerchantInformation =
  (
    publicMerchantId: string
  ): ThunkAction<void, IAppState, undefined, IAppAction> =>
  async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    try {
      const merchantInformation: AxiosResponse<MerchantResponse> =
        await axios.post<MerchantResponse>(
          `${environment.kushkiUrl}/billing-core/v1/merchant/merchantInfo`,
          {
            publicMerchantId,
          }
        );

      const resp = merchantInformation.data;

      dispatch(setMerchantInformation(resp));
    } catch (e) {}
  };

export const getCompliancePeople =
  (
    publicMerchantId: string
  ): ThunkAction<void, IAppState, undefined, IAppAction> =>
  async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    try {
      const compliancePeople: AxiosResponse<MerchantPeople> = await axios.get(
        `${environment.kushkiUrl}/compliance/v1/compliance-people/${publicMerchantId}`
      );

      dispatch(setCompliancePeople(compliancePeople.data));
      dispatch(setCompleteGetCompliance(true));
    } catch (e) {
      dispatch(setCompleteGetCompliance(false));
    }
  };

export const saveMerchantPeople = (
  bodyMerchant: UpdateMerchantInfoRequest,
  bodyPeople: CreateMerchantPeopleRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    try {
      dispatch(setLoading());
      const pathMerchant = `${environment.kushkiUrl}/billing-core/v1/merchant/createUpdate`;
      const pathCompliancePeople = `${environment.kushkiUrl}/compliance/v1/compliance-people `;

      if (!isEmpty(bodyMerchant.ciiu) && !isEmpty(bodyMerchant.mcc))
        await axios.post(pathMerchant, bodyMerchant);

      await axios.post(pathCompliancePeople, bodyPeople);
      dispatch(setCompleteSave(true));
      dispatch(hideLoading());
    } catch (e) {
      dispatch(hideLoading());
    }
  };
};
