import { useState } from "react";
import { REFUNDS_TABLE_COLUMNS } from "../../../shared/constants/refunds_table_columns";
import { IUseRefundsTableState } from "../../../shared/interfaces/RefundsTable.interfaces";
import {
  addSelectedRefund,
  unSelectRefund,
} from "../../../store/actions/refunds/refunds";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import {
  refundsSelector,
  selectedRefundsSelector,
} from "../../../store/selectors/refunds/refunds.selectors";

import useRefundsData from "./useRefundsData/useRefundsData";

export const useRefundsTableState = (): IUseRefundsTableState => {
  const {
    isEmptyTable,
    isLoadingTable,
    refunds,
    selectedRefund,
    setRawRefund,
    toggleSortState,
    totalRemoteRefunds,
  } = useRefundsData();

  const dispatch = useAppDispatch();
  const selectedRefunds = useAppSelector(selectedRefundsSelector);
  const refundsTransaction = useAppSelector(refundsSelector);

  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);

  const getColumns = () => {
    return REFUNDS_TABLE_COLUMNS.map((column) => {
      if (column.type === "checkbox") {
        return {
          ...column,
        };
      }
      if (column.sortable) {
        return {
          ...column,
          onSortClick: toggleSortState,
        };
      }

      return column;
    });
  };

  /* istanbul ignore next */
  const onCheckboxClick = (indexRefund: string) => {
    if (selectedRefunds.includes(refundsTransaction[indexRefund])) {
      dispatch(unSelectRefund(refundsTransaction[indexRefund]));
    } else {
      dispatch(addSelectedRefund(refundsTransaction[indexRefund]));
    }
  };

  const onRowClick = (id: string) => {
    setRawRefund(id);
    setIsRefundModalOpen(true);
  };

  const onCloseRefundModal = () => {
    setIsRefundModalOpen(false);
  };

  return {
    getColumns,
    isEmptyTable,
    isLoadingTable,
    isRefundModalOpen,
    onCheckboxClick,
    onCloseRefundModal,
    onRowClick,
    refunds,
    selectedRefund,
    totalRemoteRefunds,
  };
};
