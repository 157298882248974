import { IBarChartProp } from "@kushki/connect-ui/dist/Components/Molecules/DataVisualization/BarChart";
import { useAppSelector } from "../../../store/hooks/storeHook";
import { selectMetricsResponse } from "../../../store/selectors/selectors";
import { get, isEmpty } from "lodash";
import { useChannelMetricsContext } from "../../../containers/MetricsContainer/context/useChannelMetricsContext";
import { ChannelNames } from "../../../shared/constants/ChannelNames";
import { ChannelsDataDefault } from "../../../shared/constants/ChannelsDataDefault";
import { ChannelProps } from "../../../../types/query_channel_transaction_metrics_response";
import { IUseResumeChartBarState } from "../ResumeChartBar.interfaces";

const useResumeChartBar = (): IUseResumeChartBarState => {
  const {
    isMobile,
    metricsFilters: { productFilter },
  } = useChannelMetricsContext();
  const metrics = useAppSelector(selectMetricsResponse);

  const scrollDefault: boolean =
    isEmpty(productFilter) || productFilter.length > 12;
  const scrollMobile: boolean =
    isEmpty(productFilter) || productFilter.length > 6;
  const isScrollX: boolean = isMobile ? scrollMobile : scrollDefault;

  const metricsChanelFilter: IBarChartProp[] = Object.entries(
    get(metrics, "channels", [])
  ).map((item) => {
    const channelProps: ChannelProps = item[1];

    return {
      label: ChannelNames[channelProps.platformName],
      quantity: channelProps.totalTransactions,
    };
  });

  const metricsChanelDefault: IBarChartProp[] = ChannelsDataDefault.map((i) => {
    const labelMathIndex: number = metricsChanelFilter.findIndex(
      (index) => index.label === i.label
    );
    const isLabelMatch = labelMathIndex !== -1;

    if (isLabelMatch)
      return {
        label: i.label,
        quantity: metricsChanelFilter[labelMathIndex].quantity,
      };

    return {
      label: i.label,
      quantity: i.quantity,
    };
  });

  const dataChannelMetrics: IBarChartProp[] = isEmpty(productFilter)
    ? metricsChanelDefault
    : metricsChanelFilter;

  return {
    data: dataChannelMetrics,
    isMobile,
    isScrollX,
  };
};

export { useResumeChartBar };
