import React, { FC } from "react";
import { ITransferInternationalProps } from "./InternationalTransferComponent.interfaces";
import SectionInfoComponent from "./SectionInfoComponent/SectionInfoComponent";
import ProcessorBasicInfoComponent from "./ProcessorBasicInfoComponent/ProcessorBasicInfoComponent";
import PaymentProcessorComponent from "./PaymentProcessorComponent/PaymentProcessorComponent";
import ProcessorInfoComponent from "./ProcessorInfoComponent/ProcessorInfoComponent";
import { useInternationalTransfer } from "./useState/useInternationalTransfer";
import { transferInternationalProcessorContainerStyles } from "../../containers/InternationalContainer/InternationalContainer.styles";
import { Button, Grid } from "@mui/material";
import { PROCESSOR_PAYMENT_METHOD } from "../../shared/constants/transferProcessorOptions";
import { LoadingDialog } from "../Dialog/LoadingDialog";
import { ProcessorDialog } from "../Dialog/ProcessorDialog";
import { InternationalTransferEnum } from "../../shared/constants/InternationalTransferEnum";
import TitleComponent from "../TitleComponent/TitleComponent";
const InternationalTransferComponent: FC<ITransferInternationalProps> = () => {
  const {
    aliasError,
    aliasValue,
    handleRedirectNewProcessor,
    headerTitle,
    isDefaultProcessor,
    isEdit,
    isFinalModal,
    isLoading,
    isProcessorModal,
    onAliasChange,
    onBackPage,
    onCloseFinalModal,
    onDefaultTransferChecked,
    onSubmitProcessor,
    processorCategory,
    processorModels,
    processorSubmitModalInfo,
    processorTypes,
    setProcessorCategory,
    setProcessorModels,
    setProcessorTypes,
  } = useInternationalTransfer();

  return (
    <React.Fragment>
      <TitleComponent isLoading={isLoading} headerTitle={headerTitle} />

      <SectionInfoComponent
        title={InternationalTransferEnum.paymentProcessorTitle}
        description={InternationalTransferEnum.paymentProcessorDesc}
      >
        <PaymentProcessorComponent
          chipTitle={InternationalTransferEnum.paymentProcessorChipHeader}
          isLoading={isLoading}
          processorsTypes={PROCESSOR_PAYMENT_METHOD}
          processorsOptions={{
            info: {
              items: processorTypes,
              name: "ProcessorTypeRadioButton",
              updateList: setProcessorTypes,
            },
          }}
        />
      </SectionInfoComponent>
      <SectionInfoComponent
        title={InternationalTransferEnum.processorBasicInfoTitle}
        description={InternationalTransferEnum.processorBasicInfoDesc}
        hidden={true}
      >
        <ProcessorBasicInfoComponent
          isLoading={isLoading}
          processorModelOption={{
            info: {
              items: processorModels,
              name: "ProcessorModelRadioButton",
              updateList: setProcessorModels,
            },
            titleInfo: "Modelo",
          }}
          processorsCategoryOptions={{
            info: {
              items: processorCategory,
              name: "ProcessorsCategoryRadioButton",
              updateList: setProcessorCategory,
            },
            titleInfo: "Categoría",
          }}
        />
      </SectionInfoComponent>
      <SectionInfoComponent
        title={InternationalTransferEnum.processorInfoTitle}
        description={InternationalTransferEnum.processorInfoDesc}
      >
        <ProcessorInfoComponent
          isEdit={isEdit}
          isLoading={isLoading}
          aliasError={aliasError}
          aliasValue={aliasValue}
          checkDescription={InternationalTransferEnum.processorInfoCheckDesc}
          isDefaultTransferProcessor={isDefaultProcessor}
          onAliasChange={onAliasChange}
          onDefaultTransferChecked={onDefaultTransferChecked}
          title={InternationalTransferEnum.processorInfoHeader}
        />
      </SectionInfoComponent>
      <Grid sx={transferInternationalProcessorContainerStyles.buttons}>
        <Button
          sx={transferInternationalProcessorContainerStyles.submitButton}
          variant="contained"
          color="primary"
          onClick={onSubmitProcessor}
        >
          Aceptar
        </Button>
        <Button
          sx={transferInternationalProcessorContainerStyles.backButton}
          color="secondary"
          variant="outlined"
          size="small"
          onClick={onBackPage}
        >
          Regresar
        </Button>
      </Grid>
      <LoadingDialog open={isFinalModal} isEdit={isEdit} />
      <ProcessorDialog
        open={isProcessorModal}
        redirectObject={processorSubmitModalInfo}
        onClose={onCloseFinalModal}
        handleRedirectNewProcessor={handleRedirectNewProcessor}
      />
    </React.Fragment>
  );
};

export default InternationalTransferComponent;
