import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { IconCircleWarn, IconPlus } from "@kushki/connect-ui";
import { globalInformationComponentStyles } from "../GlobalInformationComponent/GlobalInformationComponent.styles";
import { LabelEnum } from "../../shared/infrastructure/enums/LabelEnum";
import { IConditionsComponentProps } from "../../shared/infrastructure/interfaces/IConditionsComponentProps";
import { ConditionItemComponent } from "./components/ConditionItemComponent/ConditionItemComponent";
import { SuggestCredentialsResponse } from "../../../types/suggest_credentials_response";
import { ICategory } from "../../shared/infrastructure/interfaces/ICategory";
import { isEmpty, get } from "lodash";
import { useAppSelector } from "../../store/hooks/store-hook";
import { CustomerCredentialsResponse } from "../../../types/customer_credentials_response";
import {
  CategoryData,
  ItemsCategoryData,
} from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectSearchBy/SelectSearchBy.interfaces";

export const ConditionsComponent = (props: IConditionsComponentProps) => {
  const classes = globalInformationComponentStyles();
  const { suggestCredentials, customerCredentials, geographyList } =
    useAppSelector((store) => store.layout);

  return (
    <Grid item xs={12} className={classes.paddingTop32}>
      <Card elevation={2} className={classes.rootCard}>
        <CardContent className={classes.cardContent}>
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Typography variant="h3" color="text.primary">
                {LabelEnum.CONDITIONS_TITLE}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.paddingTop20}>
              <Box sx={{ paddingLeft: "34px" }}>
                <Button
                  startIcon={
                    <IconPlus className={"buttonLink"} fontSize="medium" />
                  }
                  className={"buttonLink"}
                  color="primary"
                  onClick={() => props.actions.handleAddCondition()}
                >
                  {LabelEnum.ADD_CONDITION}
                </Button>
              </Box>
            </Grid>
            {props.fields.map(
              (
                item: Partial<Record<string, any> & Record<"id", string>>,
                index: number
              ) => (
                <Grid
                  key={item.id}
                  item
                  xs={12}
                  className={classes.paddingTop20}
                >
                  <ConditionItemComponent
                    geographyList={geographyList}
                    handleRemoveCondition={props.actions.handleRemoveCondition}
                    index={index}
                    key={item.id}
                    item={item}
                    credentials={suggestCredentialsToCategory(
                      suggestCredentials
                    )}
                    customerCredentials={mapCustomerCredentials(
                      customerCredentials
                    )}
                    fieldsLength={props.fields.length}
                  />
                </Grid>
              )
            )}
            <Grid item xs={12} className={classes.paddingTop16}>
              <Typography variant="body2" color="text.grey">
                <IconCircleWarn fontSize="medium" />
                {` ${LabelEnum.DESCRIPTION_CREDENTIAL}`}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

const suggestCredentialsToCategory = (
  suggestCredentials: SuggestCredentialsResponse[] | undefined
): ICategory[] => {
  if (isEmpty(suggestCredentials)) {
    return [];
  }

  return suggestCredentials!.map((credential): ICategory => {
    const alias = get(credential, "_source.alias", "");
    return { name: alias, value: credential._source.credentialId };
  });
};

const mapCustomerCredentials = (
  customerCredentials: CustomerCredentialsResponse[] | undefined
): CategoryData[] => {
  if (isEmpty(customerCredentials)) {
    return [];
  }

  const listMerchants: (string | undefined)[] = [
    ...new Set(customerCredentials!.map((credential) => credential.merchantId)),
  ];

  return listMerchants!.map(
    (merchantId: string | undefined, index): CategoryData => {
      return {
        categoryId: merchantId!,
        categoryName: "BRANCH " + (index + 1),
        items: buildItems(
          customerCredentials!.filter(
            (credential: CustomerCredentialsResponse) =>
              credential.merchantId! === merchantId
          )
        ),
      };
    }
  );
};

const buildItems = (
  itemCredential: CustomerCredentialsResponse[] | undefined
): ItemsCategoryData[] => {
  return itemCredential!.map((item: CustomerCredentialsResponse) => {
    return {
      id: item.credentialId,
      text: item.alias,
      secondaryText: item.credentialId,
    };
  });
};
