import { IColumn } from "../interfaces/Columns.interfaces";

const Columns: IColumn[] = [
  {
    key: "approvalCode",
    value: "Código de Aprobación",
  },
  {
    key: "approvedTransactionAmount",
    value: "Monto",
  },
  { key: "country", value: "País" },
  {
    key: "issuingBank",
    value: "Banco Emisor",
  },
  {
    key: "maskedCreditCard",
    value: "Número de Tarjeta",
  },
  {
    key: "merchantDescription",
    value: "Nombre y ID de comercio",
  },
  {
    key: "paymentMethod",
    value: "Medio de pago",
  },
  {
    key: "processorName",
    value: "Procesador",
  },
  {
    key: "purchaseNumber",
    value: "Número de Compra",
  },
  {
    key: "transactionId",
    value: "ID Transacción",
  },
  {
    key: "transactionReference",
    value: "Referencia",
  },
  {
    key: "transactionType",
    value: "Tipo de Transacción",
  },
  {
    key: "status",
    value: "Estado de la Transacción",
  },
  {
    key: "transactionStatusKushki",
    value: "Estado Kushki",
  },
  {
    key: "transactionStatusProcessor",
    value: "Estado Procesador",
  },
  {
    key: "created",
    value: "Fecha del Estado de la Transacción",
  },
  {
    key: "conciliationCreation",
    value: "Fecha de Conciliación",
  },
];

const HistoricColumns: IColumn[] = [
  {
    key: "conciliationAction",
    value: "Acción y Respuesta",
  },
];

const DefaultColumns: string[] = [
  "created",
  "merchantDescription",
  "transactionType",
  "approvedTransactionAmount",
  "transactionStatusKushki",
  "transactionStatusProcessor",
];

const DefaultHistoricColumns: string[] = [
  "created",
  "merchantDescription",
  "transactionType",
  "approvedTransactionAmount",
  "conciliationAction",
  "transactionStatusKushki",
  "transactionStatusProcessor",
];

const FixedHistoricColumns: string[] = ["created", "conciliationAction"];

const FixedColumns: string[] = [];

export {
  Columns,
  DefaultColumns,
  DefaultHistoricColumns,
  FixedColumns,
  FixedHistoricColumns,
  HistoricColumns,
};
