import React, { FC } from "react";
import { useDashboard } from "./state/useDashboard";
import { IDashboardProps } from "./Dashboard.interfaces";
import { TableNodes } from "../Table/TableNodes/TableNodes";
import {
  CATALOG_HEADS_NODE,
  EntityEnum,
  RoleEnum,
} from "../../shared/enum/EntityEnum";
import { HeaderFilters } from "../HeaderFilters/HeaderFilters";
import { branchListContainerStyles } from "../../containers/BranchListContainer/BranchListContainer.styles";
import { Box, Typography } from "@mui/material";
import {
  branchCountDescription,
  DashboardLabels,
} from "../../shared/constants/labels/dashboardLabels";
import { headerFiltersStyles as styles } from "../HeaderFilters/HeaderFilters.styles";
import TableSkeleton from "../Skeletons/TableSkeleton/TableSkeleton";
import { isBackofficeMasterCustomer } from "../../shared/utils/getRolesUtils";

const Dashboard: FC<IDashboardProps> = ({
  showEmptyScreen,
  nodeData,
}: IDashboardProps) => {
  const {
    handleOnFilterMerchants,
    handleResetPage,
    handleSelectedRows,
    paginationProps: {
      handleChangePage,
      handleChangeRowsPerPage,
      page,
      rowsPerPage,
      totalData,
    },
    rows,
    resetFilters,
    itemsSelected,
    isLoadingMerchants,
  } = useDashboard({
    nodeData,
    showEmptyScreen,
  });

  return (
    <React.Fragment>
      <Typography variant="h3" sx={styles.title}>
        Selecciona los branches a los que les crearás un usuario
      </Typography>
      <Typography variant="body2" sx={styles.subTitle}>
        * Solo se permitirán seleccionar los branches que se encuentren
        completos en su configuración (solo se consideran las secciones
        obligatorias)
      </Typography>
      <HeaderFilters
        handleOnFilterMerchants={handleOnFilterMerchants}
        resetFilters={resetFilters}
        handleResetPage={handleResetPage}
      />
      {!isBackofficeMasterCustomer() && (
        <Typography sx={branchListContainerStyles.description} mt={1}>
          {branchCountDescription}
          <Box component="span" data-testid="selectedItemsTotal">
            {itemsSelected.length} de {totalData}
          </Box>
        </Typography>
      )}
      {isLoadingMerchants ? (
        <TableSkeleton rows={10} columns={3} />
      ) : (
        <TableNodes
          handleSelectedRows={handleSelectedRows}
          headTable={
            !isBackofficeMasterCustomer()
              ? CATALOG_HEADS_NODE[EntityEnum.BRANCH]
              : CATALOG_HEADS_NODE[RoleEnum.CUSTOMER_MASTER_ROLL]
          }
          rows={rows}
          labelRowsPerPage={DashboardLabels.TRANSACTIONS_PER_PAGE}
          paginationProps={{
            handleChangePage,
            handleChangeRowsPerPage,
            page,
            rowsPerPage,
            totalData,
          }}
        />
      )}
    </React.Fragment>
  );
};

export default Dashboard;
