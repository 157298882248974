import React from "react";
import { Controller } from "react-hook-form";
import { IMultipleFormValues } from "./FormControl.interfaces";
import InputsForm from "./InputsForm/InputsForm";
import ConnectForm from "./ConnectForm";
import { IOnChangeFunction } from "../../shared/interfaces/Generic";

type Props<T> = {
  inputComponent: string;
  name: string;
  label: string;
  rules?: object;
  isDisable?: boolean;
  values?: IMultipleFormValues[];
  onInputChange?: any;
  listObject?: any;
  pathInfo?: string;
  onChange?: IOnChangeFunction<T>;
  checked?: boolean;
  pathId?: string;
};
export default function FormControl<T extends {}, J>({
  name,
  label,
  rules = {},
  isDisable = false,
  inputComponent,
  values,
  onInputChange,
  pathInfo,
  listObject,
  onChange,
  checked,
  pathId,
}: Props<J>) {
  return (
    <ConnectForm<T>>
      {({ control }) => {
        return (
          <Controller
            name={name}
            control={control}
            rules={rules}
            {...(inputComponent === "checkbox" ? { defaultValue: false } : {})}
            render={({ field, fieldState: { error } }) => (
              <InputsForm
                inputComponent={inputComponent}
                inputProps={{
                  checked: checked,
                  error,
                  field,
                  isDisable,
                  label,
                  options: values,
                }}
                onChange={onChange}
                onInputChange={onInputChange}
                pathInfo={pathInfo}
                listOptions={listObject}
                pathId={pathId}
              />
            )}
          />
        );
      }}
    </ConnectForm>
  );
}
