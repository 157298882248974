// istanbul ignore file
import { get } from "lodash";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { ThunkAction } from "redux-thunk";

import {
  setMassiveBranchResponse,
  setStatusSocket,
  setUpdateMassiveStatus,
} from "../../actions/massiveBranch/massiveBranch.actions";
import { Request } from "../../../../types/update_massive_branch_request";
import { IDataWebsockets } from "../../interfaces/IDataWebSocket";
import { buildNotification } from "../../../shared/constants/snackbar";
import { NotificationTypeEnum } from "../../../shared/enums/SnackbarEnum";
import { IAppState } from "../../interfaces/AppState.interfaces";
import {
  ResponseWSUpdateMassiveBranch,
  setIsModifyByBranch,
  updateEditStatusInBranchEditList,
} from "../../../shared/utils/company_data_utils";
import { SocketEnum, StatusSocketEnum } from "../../../shared/enum/socketEnum";
import { setNotificationMassive } from "../../actions/massiveBranch.actions";

export type IAppAction = {
  type: string;
};

export const updateMassiveBranch = (
  reqUpdateMassiveBranch: Request
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (dispatch): Promise<void> => {
    const jwt: string | null = localStorage.getItem("jwt");
    let webSocket: WebSocket | undefined;
    const params: object = {
      action: "updateMassiveBranch",
      data: reqUpdateMassiveBranch,
    };

    const branchesForUpdate = get(reqUpdateMassiveBranch, "branches", []).map(
      (branch) => branch.merchantId
    );

    try {
      dispatch(setUpdateMassiveStatus(true));

      webSocket = new WebSocket(
        `${API_ROUTES.WS_MASSIVE}?Authorization=${jwt}`
      );
      webSocket.onopen = () => {
        webSocket?.send(JSON.stringify(params));
      };

      webSocket.onerror = () => {
        webSocket?.close();
      };

      webSocket.onmessage = (event: MessageEvent) => {
        const response: IDataWebsockets = JSON.parse(event.data);
        const status: string | undefined = get(response, "status");
        const data = get(response, "data", []);
        const hasDataError = data.some((branchUpdateInfo) =>
          get(branchUpdateInfo, "error", false)
        );

        if (status === SocketEnum.COMPLETE && !hasDataError) {
          if (!get(response, "error")) {
            updateEditStatusInBranchEditList(
              get(response, "data", []) as ResponseWSUpdateMassiveBranch[]
            );
          }

          dispatch(setUpdateMassiveStatus(false));

          dispatch(
            setMassiveBranchResponse(
              get(response, "data", []) as ResponseWSUpdateMassiveBranch[]
            )
          );

          dispatch(setStatusSocket(StatusSocketEnum.SUCCESS));

          webSocket?.close();

          setIsModifyByBranch(branchesForUpdate);
          dispatch(
            setNotificationMassive(
              buildNotification(NotificationTypeEnum.SUCCESS, {
                color: "success",
                message: "Actualization massive",
                variant: "simple",
                withIcon: false,
              })
            )
          );

          return;
        }
        if (status === SocketEnum.ERROR || hasDataError) {
          dispatch(
            setNotificationMassive(
              buildNotification(NotificationTypeEnum.FAILED, {
                color: "danger",
                message: get(response, "data.newError.message"),
                variant: "simple",
                withIcon: false,
              })
            )
          );
          dispatch(setUpdateMassiveStatus(false));
          dispatch(setStatusSocket(StatusSocketEnum.ERROR));
          webSocket?.close();

          setIsModifyByBranch(branchesForUpdate);

          return;
        }

        setIsModifyByBranch(branchesForUpdate);
      };
    } catch (e) {
      if (webSocket) webSocket.close();
    }
  };
};
