export enum ContactDataLabelMX {
  NAME_LASTNAME = "Nombre(s) y apellido(s)",
  EMAIL = "Correo electrónico",
  SELECT_AREA = "Selecciona un área",
  CONTACT_DATA_TITLE = "Datos de Contacto",
  CONTACT_DATA_SUBTITLE = "Personas de contacto del customer",
  KUSHKI_EXECUTIVES = "Ejecutivo de Kushki",
  EXECUTIVES_DATA = "Datos comerciales de Kushki",
  INDICATIONS = "Agrega al menos una persona de contacto por área y tres como máximo.",
  MERCHANT_CATEGORIZATION = "Categorización del comercio",
}

export enum ButtonTextMX {
  ADD_CONTACT = "Agregar persona de contacto",
  ADD = "Agregar",
  MODIFY = "Modificar",
  SAVE = "GUARDAR",
}

export enum ModalAddContactMX {
  TITLE_MODAL_ADD = "Agregar persona de contacto",
  TITLE_MODAL_EDIT = "Editar persona de contacto",
}

export enum EntityNames {
  CUSTOMER = "CUSTOMER",
  BRANCH = "BRANCH",
}
