import React, { FC } from "react";
import {
  Box,
  Checkbox,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { TableNodesStyle } from "./TableNodes.styles";
import {
  TABLE_HEAD_DISABLE_SX,
  TableHeadStyles,
} from "../TableHead/TableHead.style";
import { TableBodyStyles } from "../TableBody/TableBody.style";
import { TableFooterStyles } from "../TableFooter/TableFooter.style";
import {
  CELL_COMPONENTS_BY_TABLE_ENUM,
  TableBodyCellMassiveEnum,
} from "../../../shared/utils/constants";
import {
  EnhancedTableProps,
  ITableCellPropsMassive,
  ITableRowPropsMassive,
} from "./interfaces";
import { get, set } from "lodash";
import { useTableNodes } from "./state/useTableNodes";
import { ITableNodesProps } from "./TableNodes.interfaces";
import IconSortArrow from "../../../shared/utils/functions/IconSortArrow/IconSortArrow";
import { MerchantNodeData } from "../../../../types/search_merchant_response";
import { HeadCellsLabels } from "../../../shared/constants/CatalogConfigTableBranch";
import CustomTooltip from "../../CustomTooltip/CustomTooltip";
import { isBackofficeMasterCustomer } from "../../../shared/utils/getRolesUtils";

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    headCells,
    onSelectAllClick,
    order,
    numSelected,
    rowCount,
    handleOrderByHeader,
    allChecked,
  } = props;

  return (
    <TableHead sx={TABLE_HEAD_DISABLE_SX[`${!!false}`]}>
      <TableRow sx={TableHeadStyles.row}>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={allChecked}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            sx={TableHeadStyles.label}
            key={headCell.id}
            align={headCell.align ? headCell.align : "center"}
            sortDirection={order}
          >
            {headCell.label.includes(HeadCellsLabels.STATUS) ? (
              <Box sx={{ display: "inline-block" }}>
                <CustomTooltip label={headCell.label} />
                <IconSortArrow
                  onClick={handleOrderByHeader}
                  valueOrder={headCell.label}
                />
              </Box>
            ) : (
              <Box sx={{ display: "inline-block" }}>
                {headCell.label}
                <IconSortArrow
                  onClick={handleOrderByHeader}
                  valueOrder={headCell.label}
                />
              </Box>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export const TableNodes: FC<ITableNodesProps> = ({
  handleSelectedRows,
  headTable,
  labelRowsPerPage,
  rows,
  paginationProps: {
    handleChangePage,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
    totalData,
  },
}: ITableNodesProps) => {
  const {
    handleOrderByHeader,
    headerProps: { order, orderBy },
    rowsProps: {
      check: {
        handleClick,
        handleSelectAllClick,
        isSelected,
        selected,
        allChecked,
      },
    },
    totalPages,
    handledRows,
    handleCalculateRowsCount,
  } = useTableNodes({
    handleSelectedRows,
    rows,
    rowsPerPage,
    totalData,
  });

  return (
    <Box sx={TableNodesStyle.centerContainer}>
      <TableContainer>
        <Box>
          <Table sx={TableNodesStyle.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              headCells={headTable}
              sx={TableHeadStyles}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              rowCount={handleCalculateRowsCount(handledRows)}
              handleOrderByHeader={handleOrderByHeader}
              allChecked={allChecked}
            />
            {totalData > 0 && (
              <TableBody sx={TableBodyStyles.body}>
                {handledRows.map(
                  (
                    row: ITableRowPropsMassive<MerchantNodeData>,
                    index: number
                  ) => {
                    const configsComplete = get(
                      row.info,
                      "configs_complete",
                      false
                    );
                    const isItemSelected = isSelected(
                      row.info.merchant_id!,
                      configsComplete
                    );

                    return (
                      <TableRow
                        aria-label={`table-row-${row.id}`}
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        sx={
                          configsComplete
                            ? TableBodyStyles.row
                            : isBackofficeMasterCustomer()
                            ? TableBodyStyles.rowHidden
                            : TableBodyStyles.rowDisabled
                        }
                        selected={isItemSelected}
                      >
                        {row.cells.map(
                          (
                            { type, props }: ITableCellPropsMassive,
                            cellIndex: number
                          ) => {
                            const CellComponent =
                              CELL_COMPONENTS_BY_TABLE_ENUM[type];

                            if (type === TableBodyCellMassiveEnum.CHECK) {
                              set(props, "isChecked", isItemSelected);
                              set(props, "handleClick", handleClick);
                              set(props, "id", row.info);
                              set(props, "configsComplete", configsComplete);
                            }

                            return (
                              <TableCell
                                key={`row-${index}-cell-${cellIndex}`}
                                scope="row"
                                padding="none"
                                align={get(props, "align", "center")}
                              >
                                <CellComponent
                                  {...props}
                                  key={`row-${index}-cell-${cellIndex}-component-${type}`}
                                />
                              </TableCell>
                            );
                          }
                        )}
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            )}
          </Table>
        </Box>
      </TableContainer>
      {totalData > 0 && (
        <Grid
          sx={TableNodesStyle.tableFooter}
          marginTop={-2}
          container
          spacing={1}
        >
          <Grid display="flex" justifyContent="left" item xs={6}>
            {!isBackofficeMasterCustomer() && (
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={totalData}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={labelRowsPerPage}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count}`
                }
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                align="left"
              />
            )}
          </Grid>
          <Grid display="flex" justifyContent="right" item xs={6}>
            <Pagination
              sx={TableFooterStyles.pagination}
              count={totalPages}
              page={page + 1}
              onChange={(event: unknown, newPage: number) => {
                handleChangePage(event, newPage - 1);
              }}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
