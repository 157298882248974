import { useSelector } from "react-redux";
import { IMerchantNodeInfoResponse } from "../../../../types/merchant_node_info_response";
import { Shareholder } from "../../../shared/infrastructure/interfaces/Shareholder";
import { IShareHolder } from "../../../store/interfaces/LegalDetailsState.interfaces";
import {
  getNestingLabel,
  getTotalShareholderNumber,
  getUsedPercentage,
  isMoralShareholder,
} from "../../../shared/utils/shareholderUtils";
import {
  isAvailableCountry,
  PERSON_TYPE_BY_COUNTRY_VALUES,
} from "../../../shared/services/CatalogPersonTypeByCountry";
import { CountryEnum } from "../../../shared/countryEnum";
import { IMultipleFormValues } from "../../../shared/constants/initial_state_legal_details";
import { get } from "lodash";
import { ShareholderFields } from "../../../shared/constants/shareholder_constants";
import { getCountryName } from "../../../shared/constants/Countries";
import {
  selectMerchantInfo,
  selectNodeInfo,
} from "../../../store/selectors/selectors";

export const useShareholders = (shareholders: IShareHolder[]) => {
  let totalParticipation = 0;
  const nodeInfo = useSelector(selectNodeInfo);
  const merchantInfoData: IMerchantNodeInfoResponse | undefined =
    useSelector(selectMerchantInfo);

  if (shareholders)
    shareholders.forEach(
      (s) => (totalParticipation += +s.participationPercentage)
    );

  const getPersonType = (country: string, value: string) => {
    const countryAux: string = isAvailableCountry(country)
      ? country
      : CountryEnum.generic;

    return PERSON_TYPE_BY_COUNTRY_VALUES[countryAux]
      .filter((item: IMultipleFormValues) => item.value === value)
      .map((item: IMultipleFormValues) => item.name.toLowerCase());
  };

  const buildDataByPersonType = (
    shareholderInfo: IShareHolder,
    index: string
  ) => {
    const isMoral = isMoralShareholder(shareholderInfo.personType);
    let buildData: Shareholder = {} as Shareholder;

    isMoral
      ? (buildData = {
          badgeText: shareholderInfo.participationPercentage,
          id: shareholderInfo.id,
          nesting: index,
          nextNesting: `${index}-${
            get(shareholderInfo, ShareholderFields.CHILDREN, []).length
          }`,
          open: false,
          shareholderInfo: {
            country: getCountryName(get(shareholderInfo, "country", "")),
            date: shareholderInfo.constitutionDate!,
            id: shareholderInfo.id,
            isMoral,
            name: `${get(shareholderInfo, "name", "")} ${get(
              shareholderInfo,
              "lastName",
              ""
            )}`,
            participationPercentage: `${getUsedPercentage(
              get(shareholderInfo, ShareholderFields.CHILDREN, [])
            )}%`,
            registeredShareholders: `${
              get(shareholderInfo, ShareholderFields.CHILDREN, []).length
            }`,
            socialReason: shareholderInfo.socialReason,
            type: getPersonType(get(nodeInfo, "generalInfo.country", ""), "02"),
          },
          text: `Accionista ${getNestingLabel(index)}`,
          text2: `Accionista ${getPersonType(
            get(nodeInfo, "generalInfo.country", ""),
            "02"
          )}`,
        })
      : (buildData = {
          badgeText: shareholderInfo.participationPercentage,
          id: shareholderInfo.id,
          nesting: index,
          nextNesting: `${index}-${
            get(shareholderInfo, ShareholderFields.CHILDREN, []).length
          }`,
          open: false,
          shareholderInfo: {
            country: getCountryName(get(shareholderInfo, "country", "")),
            date: shareholderInfo.birthdate!,
            id: shareholderInfo.id,
            isMoral,
            name: `${get(shareholderInfo, "name", "")} ${get(
              shareholderInfo,
              "lastName",
              ""
            )}`,
            participationPercentage: `${getUsedPercentage(
              get(shareholderInfo, ShareholderFields.CHILDREN, [])
            )}%`,
            registeredShareholders: "0",
            type: getPersonType(get(nodeInfo, "generalInfo.country", ""), "01"),
          },
          text: `Accionista ${getNestingLabel(index)}`,
          text2: `Accionista ${getPersonType(
            getPersonType(get(nodeInfo, "generalInfo.country", ""), "02"),
            "01"
          )}`,
        });

    return buildData;
  };

  const flatShareholders = (sd: IShareHolder[]): Shareholder[] => {
    let holdersData: Shareholder[] = [];

    if (sd) {
      holdersData = sd.map((sh, index) => {
        return buildDataByPersonType(sh, String(index));
      });
    }

    return holdersData;
  };

  const shareholdersParticipation = `${totalParticipation}%`;

  const flattenedShareholders: Shareholder[] = flatShareholders(shareholders);

  return {
    flattenedShareholders,
    merchantType: get(merchantInfoData, "merchantType", "01 "),
    registeredShareholders: getTotalShareholderNumber(shareholders),
    shareholdersParticipation,
  };
};
