import React, { PropsWithChildren } from "react";
import { Box, Grid } from "@material-ui/core";
import { chipComponentStyle } from "./ChipComponent.style";
import { ChipComponentProps } from "../../../../shared/infrastructure/interfaces/ChipComponent.interface";

export const ChipComponent = (props: PropsWithChildren<ChipComponentProps>) => {
  const classes = chipComponentStyle();

  return (
    <Grid container direction={"row"} spacing={1}>
      {props.chip.map((data: string, idx: number) => (
        <Grid key={`grid-${data}-${idx}`} item>
          <Box
            className={
              props.isChipSelected(data) ? classes.chipSelected : classes.chip
            }
            component="span"
            onClick={() => {
              props.handleClick(data);
            }}
          >
            {data}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
