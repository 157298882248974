import { IconButton, Menu, MenuItem } from "@mui/material";
import React, { FC } from "react";
import { IActionTransactionButton } from "./ActionTransactionButton.interfaces";
import { actionTransactionButtonStyles } from "./ActionTransactionButton.styles";
import { useActionTransactionButtonState } from "./state/useActionTransactionButtonState";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const ActionTransactionButton: FC<IActionTransactionButton> = (
  props: IActionTransactionButton
) => {
  const { payMethod, transaction } = props;
  const {
    anchorEl,
    actions: {
      handleClickButton,
      handleClose,
      handleGenerateCertificate,
      handleResendRefundRequest,
    },
    disabledButton,
    disabledGenerateCertificate,
    open,
  } = useActionTransactionButtonState(payMethod, transaction);

  return (
    <>
      <IconButton
        data-testid="iconButton"
        onClick={handleClickButton}
        sx={actionTransactionButtonStyles.button}
        disabled={disabledButton}
        {...props}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        data-testid="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          data-testid="menuItemCertificate"
          sx={actionTransactionButtonStyles.menu}
          onClick={handleGenerateCertificate}
          disabled={disabledGenerateCertificate}
        >
          Generar Certificado
        </MenuItem>
        <MenuItem
          data-testid="menuItemRefund"
          sx={actionTransactionButtonStyles.menu}
          onClick={handleResendRefundRequest}
        >
          Reenviar Solic. Reembolso
        </MenuItem>
      </Menu>
    </>
  );
};

export default ActionTransactionButton;
