import { RefObject, useEffect, useState } from "react";
import { BreadcrumProps } from "../../../components/common/Breadcrumb/Breadcrumb";
import { TCreateMerchantIndexProps } from "../CreateMerchantIndex";
import { routes } from "../../../shared/infrastructure/routes";
import { Control, FieldError, NestDataObject, useForm } from "react-hook-form";
import { defaultTo, get, isEmpty } from "lodash";
import { AffiliationLead } from "../../../../types/affiliation_lead";
import {
  not_apply,
  NotApplyOption,
} from "../../../shared/infrastructure/constants/CreateMerchantConstants";
import { MerchantCreateUpdateResponse } from "../../../../types/merchant_create_update_response";
import { useHistory } from "react-router-dom";

export interface ICreateMerchantIndexState {
  state: IState;
  breadCrumbs: BreadcrumProps;
  form: {
    errors: NestDataObject<Record<string, any>, FieldError>;
    register: (
      obj: object
    ) => ((instance: any) => void) | RefObject<any> | null | undefined;
    checkboxChange: (
      supplier: string,
      authenticationRule: string,
      status: boolean
    ) => void;
    submit: () => void;
    formChange: (name: string, value: string | boolean | number) => void;
    getValues: (payload?: { nest: boolean } | undefined) => Record<string, any>;
    control: Control<Record<string, any>>;
  };
}

export interface IState {
  currentLead?: AffiliationLead;
  leads: AffiliationLead[];
  country?: string;
  merchantName?: string;
  isEdit?: boolean;
  publicMerchantId?: string;
  countryDisabled?: boolean;
  authorizerRuleRequest?: {
    cybersource?: string[];
    kushki?: string[];
  };
}

export const useCreateMerchantIndexState = (
  props: TCreateMerchantIndexProps
): ICreateMerchantIndexState => {
  const history = useHistory();
  const [state, setState] = useState<IState>({
    leads: [],
  });
  const { register, errors, triggerValidation, getValues, control } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const allAffiliationLeads = (): AffiliationLead[] =>
    [NotApplyOption].concat(defaultTo(props.state.affiliationLeads, []));

  const updateStateMerchantAffiliation = (
    merchantAffiliation: MerchantCreateUpdateResponse
  ) => {
    const merchantLead: AffiliationLead = merchantAffiliation.merchantLead as AffiliationLead;

    setState({
      currentLead: merchantLead,
      leads: allAffiliationLeads(),
      merchantName: merchantAffiliation.name,
      country: merchantAffiliation.country,
      publicMerchantId: merchantAffiliation.publicMerchantId,
      countryDisabled: false,
    });
  };

  useEffect(() => {
    if (props.state.merchantAffiliation) {
      updateStateMerchantAffiliation(props.state.merchantAffiliation);
      return;
    }
    props.getAffiliationLeads();
  }, []);

  useEffect(() => {
    if (!props.state.merchantAffiliation) return;
    updateStateMerchantAffiliation(props.state.merchantAffiliation);
    if (props.state.isEdit) return;
    history.push(
      `${routes.BASE_PATH_CREATE_MERCHANT}${routes.DETAILS}?publicMerchantId=${props.state.merchantAffiliation.publicMerchantId}&hideSideBar=true`
    );
  }, [props.state.merchantAffiliation]);

  const handleFormChange = (
    name: string,
    value: string | boolean | number
  ): void => {
    if (name === "lead") {
      const currentLead: AffiliationLead | undefined = getAffiliationLead(
        value.toString()
      );
      let merchantName = "";
      let countryDisabled = false;
      if (value.toString() === not_apply && state.currentLead == undefined) {
        merchantName = get(state, merchantName);
      } else if (value.toString() != not_apply) {
        merchantName = get(currentLead, "merchantInfo.name");
        countryDisabled = true;
      }
      setState({
        ...state,
        currentLead,
        country: currentLead?.merchantInfo?.country,
        merchantName: merchantName,
        countryDisabled: countryDisabled,
      });
      return;
    }
    setState({ ...state, [name]: value });
  };

  const handleCheckboxChange = (
    supplier: string,
    authenticationRule: string,
    status: boolean
  ): void => {
    const authorizerRuleRequest = state.authorizerRuleRequest || {};
    if (status) {
      authorizerRuleRequest[supplier] = (
        authorizerRuleRequest[supplier] || []
      ).concat(authenticationRule);

      setState((prevState) => ({
        ...prevState,
        authorizerRuleRequest: { ...authorizerRuleRequest },
      }));
    } else if (authorizerRuleRequest[supplier]) {
      const newValues = authorizerRuleRequest[supplier].filter(
        (v: string) => v !== authenticationRule
      );
      authorizerRuleRequest[supplier] = newValues;
      if (authorizerRuleRequest[supplier].length === 0)
        delete authorizerRuleRequest[supplier];

      setState((prevState) => ({
        ...prevState,
        authorizerRuleRequest: { ...authorizerRuleRequest },
      }));
    }
  };

  const onSubmit = (): void => {
    triggerValidation().then(() => {
      if (!isEmpty(errors)) return;
      const merchantInfo: MerchantCreateUpdateResponse = {
        country: defaultTo(state.country, "Ecuador")!,
        name: state.merchantName!,
        merchantLead: {
          ...state.currentLead,
          merchantInfo: {
            ...get(state.currentLead, "merchantInfo", {}),
            merchantType: defaultTo(
              get(state.currentLead, "merchantInfo.merchantType", ""),
              "01"
            ),
          },
        },
        publicMerchantId: state.publicMerchantId,
        isAffiliation: state.currentLead?.id !== not_apply,
        authorizerRuleRequest: state.authorizerRuleRequest,
      };
      const merchantInfoWithOrigin: object = merchantInfo
        ? { ...merchantInfo, origin: "createMerchant" }
        : { origin: "createMerchant" };
      localStorage.setItem(
        "merchantBasicInformation",
        JSON.stringify(merchantInfoWithOrigin)
      );
      props.createMerchantAffiliation(merchantInfo);
    });
  };

  const getAffiliationLead = (id: string): AffiliationLead | undefined =>
    allAffiliationLeads().find((lead: AffiliationLead) => lead.id === id);

  return {
    state: {
      ...state,
      leads: allAffiliationLeads(),
    },
    breadCrumbs: {
      items: [
        {
          label: "Comercios",
          url: routes.MERCHANTS,
        },
      ],
      lastItem: "Crear comercio",
    },
    form: {
      errors,
      register,
      getValues,
      submit: onSubmit,
      formChange: handleFormChange,
      control: control,
      checkboxChange: handleCheckboxChange,
    },
  };
};
