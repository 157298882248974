import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { ContactData } from "../../../../types/contact_data";
import { IContactData } from "../../interfaces/contactData/contactData.interfaces";
import {
  buildNotification,
  defaultDataSnackbar,
} from "../../../shared/constants/snackbar";
import { NotificationTypeEnum } from "../../../shared/enums/SnackbarEnum";
import { get } from "lodash";
import { ISnackBar } from "@kushki/connect-ui/dist/Components/Atoms/DataDisplay/SnackBar/SnackBar.interface";
import { saveContactDataMerchant } from "../../thunks/contactData/contactData.thunks";
import { getMerchantInfo } from "../../thunks/general/general.thunk";
import { MerchantResponse } from "../../../../types/get_merchant_response";

export const initialState: IContactData = {
  contactData: {
    categoryMerchant: "",
    contactBillingArea: [],
    contactCommercialArea: [],
    contactDispersionArea: [],
    contactRiskArea: [],
    contactTechnicalArea: [],
    merchantRank: "",
    merchantSize: "",
  } as ContactData,
  isDisableAddPersonBtn: false,
  isLoadingContactData: true,
  isSaved: false,
  loaderModalOpen: false,
  merchantInfo: {
    companyType: "",
    configId: "",
    constitutionDate: 0,
    country: "",
    documentType: "",
    economicActivityId: "",
    gracePeriod: 0,
    industryType: "",
    mcc: "",
    merchantType: "02",
    name: "",
    naturalPerson: {
      lastname: "",
      name: "",
    },
    publicMerchantId: "",
    socialReason: "",
    taxId: "",
    webSite: "",
  },
  notification: undefined,
  typeButton: "none",
};

export const contactDataSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(saveContactDataMerchant.pending, (state) => {
        state.isSaved = false;
      })
      .addCase(saveContactDataMerchant.fulfilled, (state) => {
        state.notification = buildNotification(
          NotificationTypeEnum.SUCCESS,
          get(state, "notification", defaultDataSnackbar)
        );
        state.isSaved = true;
        state.loaderModalOpen = false;
      })
      .addCase(saveContactDataMerchant.rejected, (state) => {
        state.notification = buildNotification(
          NotificationTypeEnum.FAILED,
          get(state, "notification", defaultDataSnackbar)
        );
        state.isSaved = false;
        state.loaderModalOpen = false;
      })
      .addCase(getMerchantInfo.fulfilled, (state, { payload }) => {
        state.merchantInfo = payload.data as MerchantResponse;
        state.isLoadingContactData = false;
      })
      .addCase(getMerchantInfo.pending, (state) => {
        state.isLoadingContactData = true;
      })
      .addCase(getMerchantInfo.rejected, (state) => {
        state.isLoadingContactData = false;
      });
  },
  initialState,
  name: "contactData",
  reducers: {
    contactDataStore: (
      state: Draft<ContactData>,
      { payload }: PayloadAction<ContactData>
    ) => {
      state.contactData = payload;
    },
    setDisableAddPersonBtn: (
      state: Draft<IContactData>,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isDisableAddPersonBtn = payload;
    },
    setIsLoadingContactData: (
      state: Draft<ContactData>,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLoadingContactData = payload;
    },
    setLoaderModalOpen: (
      state: Draft<ContactData>,
      { payload }: PayloadAction<boolean>
    ) => {
      state.loaderModalOpen = payload;
    },
    setNotificationCD: (
      state: Draft<IContactData>,
      { payload }: PayloadAction<ISnackBar | undefined>
    ) => {
      state.notification = payload;
    },
    setTypeButtonContactData: (state, { payload }: PayloadAction<string>) => {
      state.typeButton = payload;
    },
  },
});

export default contactDataSlice.reducer;
