import { ModalLoader } from "@kushki/connect-ui";
import React, { FC } from "react";
import { Box } from "@mui/material";
import { ContactDataCard } from "../../components/ContactDataCard/ContactDataCard";
import { contactDataContainerStyles as styles } from "./ContactDataContainer.styles";
import { LayoutStepContainer } from "../LayoutStepContainer/LayoutStepContainer";
import { get } from "lodash";
import { Routes } from "../../shared/constants/routes";
import { useContactDataContainerGeneral } from "../../shared/utils/state/useContactDataGeneral";
import {
  LOADER_DESCRIPTION,
  LOADER_TITLE,
} from "../../shared/constants/labels/ModalConstants";
import { ContactContainerSkeleton } from "../../components/Skeletons/ContactContainerSkeleton/ContactContainerSkeleton";

const ContactDataContainer: FC = () => {
  const {
    categoryAreas,
    commercialExecutive,
    contactDataAreasProps,
    contactDataModal,
    countriesInfo: { country },
    isEdit,
    dataCompleted,
    dataNoErrors,
    executiveList,
    footerActions,
    form,
    handleCancelModal,
    handleOpenModal,
    headerProps,
    isAreasSelected,
    isEditing,
    merchantSize,
    nameValidation,
    onSubmit,
    openModal,
    openModalLoader,
    isCentralizedBranch,
    isDisableAddPersonBtn,
    handleFormChange,
    isLoadingContactData,
  } = useContactDataContainerGeneral(false, true);

  const publicMerchantId: string = get(headerProps, "nodeInfo.merchantId", "");

  return (
    <>
      <LayoutStepContainer
        headerProps={{
          apiUrl: `${Routes.CREATE_NODE}?publicMerchantId=${publicMerchantId}`,
          country: country,
          id: publicMerchantId,
          isEdit,
          subTitle: "País de operación: ",
          tabsInfo: headerProps.tabsNodeInfo,
          title: get(headerProps.nodeInfo, "generalInfo.name"),
        }}
        renderComponent={
          <Box sx={isEdit ? styles.boxContentEdit : styles.boxContent}>
            {isLoadingContactData ? (
              <ContactContainerSkeleton />
            ) : (
              <>
                <ContactDataCard
                  handleOpenModal={handleOpenModal}
                  handleCancelModal={handleCancelModal}
                  openModal={openModal}
                  commercialExecutive={commercialExecutive}
                  handleFormChange={handleFormChange}
                  form={form}
                  commercialExecutiveList={executiveList}
                  merchantSize={merchantSize}
                  isEditing={isEditing}
                  contactDataModal={contactDataModal}
                  onSubmit={onSubmit}
                  categoryAreas={categoryAreas}
                  dataCompleted={dataCompleted}
                  dataNoErrors={dataNoErrors}
                  nameValidation={nameValidation}
                  isAreasSelected={isAreasSelected}
                  contactDataAreasProps={contactDataAreasProps}
                  isCentralizedBranch={isCentralizedBranch}
                  isDisableAddPersonBtn={isDisableAddPersonBtn}
                />
                <ModalLoader
                  descriptionText={LOADER_DESCRIPTION}
                  titleText={LOADER_TITLE}
                  isOpen={openModalLoader}
                />
              </>
            )}
          </Box>
        }
        footerProps={{
          isEdit,
          isLoading: footerActions.saveLoader,
          label: footerActions.footerLabel,
          primaryButton: footerActions.primaryButton,
          secondaryButton: footerActions.secondaryButton,
        }}
      />
    </>
  );
};

export default ContactDataContainer;
