import React, { FC } from "react";
import { Box } from "@mui/material";
import { customerContainerStyles as styles } from "./CustomerContainer.styles";
import InformationCustomerForm from "../../components/InformationCustomerForm/InformationCustomerForm";

const CustomerContainer: FC = () => {
  return (
    <Box sx={styles.boxContainer}>
      <InformationCustomerForm />
    </Box>
  );
};

export default CustomerContainer;
