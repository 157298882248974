import { Grid, Pagination } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { get, set } from "lodash";
import React, { FC } from "react";
import { ArrowDown, ArrowUp } from "react-feather";
import { TableBodyStyles } from "../TableBody/TableBody.style";
import { TableFooterStyles } from "../TableFooter/TableFooter.style";

import {
  TABLE_HEAD_DISABLE_SX,
  TableHeadStyles,
} from "../TableHead/TableHead.style";
import { CELL_COMPONENTS_BY_TABLE_ENUM, TableBodyCellEnum } from "./constants";
import {
  EnhancedTableProps,
  ITableCellProps,
  ITableRowProps,
  ITableSimpleProps,
  Order,
} from "./interfaces";
import { TableSimpleStyle } from "./TableSimple.styles";
import { useTableSimple } from "./state/useTableSimple";
import { HeadCell } from "../TableHead/TableHead.interfaces";

function descendingComparator<T>(
  headTableCells: HeadCell[],
  a: T,
  b: T,
  orderBy: string
) {
  const orderPosition: number =
    headTableCells.findIndex((value) => value.id === (orderBy as string)) + 1;

  orderBy = get(headTableCells[orderPosition - 1], "orderBy", "");

  if (
    get(b, `cells[${orderPosition}].props.${orderBy}`, "") <
    get(a, `cells[${orderPosition}].props.${orderBy}`, "")
  ) {
    return -1;
  }
  if (
    get(b, `cells[${orderPosition}].props.${orderBy}`, "") >
    get(a, `cells[${orderPosition}].props.${orderBy}`, "")
  ) {
    return 1;
  }

  return 0;
}

function getComparator(
  headCells: HeadCell[],
  order: Order,
  orderBy: string
): (a: ITableRowProps, b: ITableRowProps) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(headCells, a, b, orderBy)
    : (a, b) => -descendingComparator(headCells, a, b, orderBy);
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    headCells,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead sx={TABLE_HEAD_DISABLE_SX[`${!!false}`]}>
      <TableRow sx={TableHeadStyles.row}>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            sx={TableHeadStyles.label}
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              sx={TableHeadStyles.arrow}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              IconComponent={() =>
                orderBy === headCell.id ? (
                  order === "desc" ? (
                    <ArrowUp size={20} color={"#023365"} />
                  ) : (
                    <ArrowDown size={20} color={"#023365"} />
                  )
                ) : (
                  <ArrowDown size={20} color={"#023365"} />
                )
              }
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export const TableSimple: FC<ITableSimpleProps> = ({
  handleSelectedRows,
  headTable,
  labelRowsPerPage,
  rows,
  emptyRows,
  paginationProps: {
    handleChangePage,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
    totalData,
  },
  rowsSelected,
}: ITableSimpleProps) => {
  const {
    headerProps: { order, orderBy },
    rowsProps: {
      check: {
        handleClick,
        handleRequestSort,
        handleSelectAllClick,
        isSelected,
        selected,
      },
    },
    totalPages,
  } = useTableSimple({
    handleSelectedRows,
    rows,
    rowsPerPage,
    rowsSelected,
    totalData,
  });

  function fakeEmptyRow(totalEmptyRows: number) {
    const rowsEmpties: any[] = [];

    for (let i = 0; i < totalEmptyRows; i++) {
      rowsEmpties.push(
        <TableRow sx={TableBodyStyles.hiddenRow}>
          <TableCell sx={TableBodyStyles.hiddenRow} colSpan={6} />
        </TableRow>
      );
    }

    return rowsEmpties;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer>
        <Box>
          <Table sx={TableSimpleStyle.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              headCells={headTable}
              sx={TableHeadStyles}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody sx={TableBodyStyles.body}>
              {rows
                .slice()
                .sort(getComparator(headTable, order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: ITableRowProps, index: number) => {
                  const isItemSelected = isSelected(row.id);
                  //const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      aria-label={`table-row-${row.id}`}
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      sx={TableBodyStyles.row}
                      selected={isItemSelected}
                    >
                      {row.cells.map(
                        (
                          { type, props }: ITableCellProps,
                          cellIndex: number
                        ) => {
                          const CellComponent =
                            CELL_COMPONENTS_BY_TABLE_ENUM[type];

                          if (type === TableBodyCellEnum.CHECK)
                            set(props, "isChecked", isItemSelected);

                          return (
                            <TableCell
                              key={`row-${index}-cell-${cellIndex}`}
                              scope="row"
                              padding="none"
                              align={"center"}
                            >
                              <CellComponent
                                {...props}
                                key={`row-${index}-cell-${cellIndex}-component-${type}`}
                              />
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 &&
                fakeEmptyRow(emptyRows).map((rowEmpty) => rowEmpty)}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
      <Grid
        sx={TableSimpleStyle.tableFooter}
        marginTop={-2}
        container
        spacing={1}
      >
        <Grid display="flex" justifyContent="left" item xs={6}>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={labelRowsPerPage}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            align="left"
          />
        </Grid>
        <Grid display="flex" justifyContent="right" item xs={6}>
          <Pagination
            sx={TableFooterStyles.pagination}
            count={totalPages}
            page={page + 1}
            onChange={(event: unknown, newPage: number) => {
              handleChangePage(event, newPage - 1);
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
