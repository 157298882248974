import * as React from "react";
import { SvgIcon as MuiSvgIcon, styled, SvgIconProps } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../Themes/themekushki";

const SvgIcon = styled(MuiSvgIcon, {
  name: "MopeimIcon",
  shouldForwardProp: (prop) => prop !== "fill",
})<SvgIconProps>(({ color, fontSize }) => ({
  fill: color + "!important",
  fontSize: fontSize,
}));

SvgIcon.defaultProps = {
  "aria-hidden": "true",
  focusable: "false",
  viewBox: "0 0 24 24",
};

const IconMore: React.FunctionComponent<SvgIconProps> = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <SvgIcon
        {...props}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M20.001 9C20.5943 9 21.1743 9.17595 21.6677 9.50559C22.161 9.83524 22.5456 10.3038 22.7726 10.8519C22.9997 11.4001 23.0591 12.0033 22.9433 12.5853C22.8276 13.1672 22.5419 13.7018 22.1223 14.1213C21.7027 14.5409 21.1682 14.8266 20.5862 14.9424C20.0043 15.0581 19.4011 14.9987 18.8529 14.7716C18.3047 14.5446 17.8362 14.1601 17.5066 13.6667C17.1769 13.1734 17.001 12.5933 17.001 12C17.001 11.2044 17.317 10.4413 17.8797 9.87868C18.4423 9.31607 19.2053 9 20.001 9ZM20.001 13C20.1988 13 20.3921 12.9413 20.5565 12.8315C20.721 12.7216 20.8492 12.5654 20.9249 12.3827C21.0005 12.2 21.0203 11.9989 20.9818 11.8049C20.9432 11.6109 20.8479 11.4327 20.7081 11.2929C20.5682 11.153 20.39 11.0578 20.1961 11.0192C20.0021 10.9806 19.801 11.0004 19.6183 11.0761C19.4356 11.1518 19.2794 11.28 19.1695 11.4444C19.0596 11.6089 19.001 11.8022 19.001 12C19.001 12.2652 19.1063 12.5196 19.2939 12.7071C19.4814 12.8946 19.7358 13 20.001 13Z" />
        <path d="M12 9C12.5933 9 13.1734 9.17595 13.6667 9.50559C14.1601 9.83524 14.5446 10.3038 14.7716 10.8519C14.9987 11.4001 15.0581 12.0033 14.9424 12.5853C14.8266 13.1672 14.5409 13.7018 14.1213 14.1213C13.7018 14.5409 13.1672 14.8266 12.5853 14.9424C12.0033 15.0581 11.4001 14.9987 10.852 14.7716C10.3038 14.5446 9.83524 14.1601 9.50559 13.6667C9.17595 13.1734 9 12.5933 9 12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2043 9 12 9ZM12 13C12.1978 13 12.3911 12.9413 12.5556 12.8315C12.72 12.7216 12.8482 12.5654 12.9239 12.3827C12.9996 12.2 13.0194 11.9989 12.9808 11.8049C12.9422 11.6109 12.847 11.4327 12.7071 11.2929C12.5673 11.153 12.3891 11.0578 12.1951 11.0192C12.0011 10.9806 11.8 11.0004 11.6173 11.0761C11.4346 11.1518 11.2784 11.28 11.1685 11.4444C11.0586 11.6089 11 11.8022 11 12C11 12.2652 11.1054 12.5196 11.2929 12.7071C11.4804 12.8946 11.7348 13 12 13Z" />
        <path d="M3.99902 9C4.59236 9 5.17238 9.17595 5.66573 9.50559C6.15908 9.83524 6.5436 10.3038 6.77066 10.8519C6.99772 11.4001 7.05713 12.0033 6.94138 12.5853C6.82562 13.1672 6.5399 13.7018 6.12034 14.1213C5.70078 14.5409 5.16624 14.8266 4.58429 14.9424C4.00235 15.0581 3.39915 14.9987 2.85097 14.7716C2.30279 14.5446 1.83425 14.1601 1.50461 13.6667C1.17497 13.1734 0.999019 12.5933 0.999019 12C0.999019 11.2044 1.31509 10.4413 1.8777 9.87868C2.44031 9.31607 3.20337 9 3.99902 9ZM3.99902 13C4.1968 13 4.39014 12.9413 4.55459 12.8315C4.71904 12.7216 4.84721 12.5654 4.9229 12.3827C4.99859 12.2 5.01839 11.9989 4.97981 11.8049C4.94122 11.6109 4.84598 11.4327 4.70613 11.2929C4.56628 11.153 4.38809 11.0578 4.19411 11.0192C4.00013 10.9806 3.79906 11.0004 3.61634 11.0761C3.43361 11.1518 3.27743 11.28 3.16755 11.4444C3.05767 11.6089 2.99902 11.8022 2.99902 12C2.99902 12.2652 3.10438 12.5196 3.29191 12.7071C3.47945 12.8946 3.7338 13 3.99902 13Z" />
      </SvgIcon>
    </ThemeProvider>
  );
};

export default IconMore;
