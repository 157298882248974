import { createSlice } from "@reduxjs/toolkit";
import {
  GeneratedReportState,
  WalletReportState,
} from "../../interfaces/walletReport.interfaces";
import { generateReport } from "../../thunks/walletReport/walletReport.thunk";
import { get } from "lodash";
import { ErrorCodeEnum } from "../../../shared/enums/error_code";

export const initialState: WalletReportState = {
  downloadReportState: {
    errorMessage: "",
    isError: false,
    url: "",
  },
  generateReportState: {
    isError: false,
    openModal: false,
  },
};
export const walletReportSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(generateReport.fulfilled, (state) => {
        state.generateReportState = { isError: false, openModal: true };
      })
      .addCase(generateReport.rejected, (state, { payload }) => {
        const generateReportState: GeneratedReportState = {
          isError: true,
          openModal: false,
        };

        if (get(payload, "data.code", "") === ErrorCodeEnum.WLT044) {
          generateReportState.openModal = true;
        }

        state.generateReportState = generateReportState;
      });
  },
  initialState,
  name: "walletReport",
  reducers: {
    setSocketMessage: (state, action) => {
      state.downloadReportState = action.payload;
    },
  },
});

export const { setSocketMessage } = walletReportSlice.actions;
export default walletReportSlice.reducer;
