import { theme } from "@kushki/connect-ui";
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { store } from "@store/store";
import Normalize from "react-normalize";
import { Provider } from "react-redux";
import { StyledEngineProvider } from "@mui/material";
import MainContainer from "@containers/MainContainer/MainContainer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
import { environment } from "./environments/environment";
import { ROUTES } from "@shared/constants/api_routes";
import { SPA_BASENAME } from "@shared/constants/base";
import { SecurityWrapperEnum } from "@shared/enum/SecurityWrapperEnum";
import DevelopersContainer from "@containers/Developers/DevelopersContainer";

const Root = () => {
  return (
    <StyledEngineProvider>
      <React.StrictMode>
        <Normalize />
        <style>
          {
            "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&display=swap');"
          }
        </style>
        <style>
          {
            "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500&display=swap');"
          }
        </style>

        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <BrowserRouter basename={SPA_BASENAME}>
              <ModuleSecurityWrapper
                basePath={environment.kushkiUrl}
                componentId={SecurityWrapperEnum.MODULE_ID}
              >
                <Routes>
                  <Route index element={<MainContainer />} />
                  <Route
                    path={ROUTES.DEVELOPERS}
                    element={<DevelopersContainer />}
                  />
                </Routes>
              </ModuleSecurityWrapper>
            </BrowserRouter>
          </ThemeProvider>
        </Provider>
      </React.StrictMode>
    </StyledEngineProvider>
  );
};

export default Root;
