import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../../../assets/icons/CloseIcon";
import InfoIcon from "../../../assets/icons/InfoIcon";
import { refundModalStyles } from "./RefundModal.styles";
import { RefundModalProps } from "./RefundModal.interfaces";
import { ColorsEnum } from "../../../shared/enums/colors_enum";

export const RefundModal = (props: RefundModalProps) => {
  return (
    <React.Fragment>
      <Dialog
        open={props.openModal}
        onClose={() => props.handlers.handleOpenCloseModal()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={refundModalStyles.dialog}
        maxWidth={"md"}
      >
        <Box display="flex" flexGrow={1} style={{ padding: "24px 32px" }}>
          <Box display="flex" alignItems="center" flexGrow={1}>
            <Typography
              color="primary"
              style={{ fontSize: "20px", fontWeight: 500, lineHeight: "28px" }}
            >
              Enviar varias transacciones a reembolsar
            </Typography>
          </Box>
          <Box display="flex" alignItems="flex-start">
            <IconButton onClick={() => props.handlers.handleOpenCloseModal()}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <DialogContent style={{ padding: "24px 32px" }}>
          <div id="liquidationAmount_controller">
            <Typography
              variant={"h6"}
              style={{ color: ColorsEnum.DARK, paddingBottom: "24px" }}
            >
              <b>
                {"Se enviarán un total de "} {props.selectedRowsCounter}
                {" transacciones."}
              </b>
            </Typography>
            <Typography
              style={{
                color: ColorsEnum.DARK,
                fontSize: "15px",
                fontWeight: 400,
                paddingBottom: "27px",
              }}
            >
              {
                "La transacción pasará al módulo de reembolsos para ser evaluada por un analista,"
              }
              <br />
              {"¿quieres realizar esta acción?"}
            </Typography>
          </div>
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ paddingBottom: "3px" }}
          >
            <InfoIcon />
            <Typography
              style={{
                color: ColorsEnum.DARK,
                fontSize: "15px",
                fontWeight: 400,
                lineHeight: "120%",
              }}
            >
              {"Te quedan "}
              {props.slopesCounter} {" retenciones pendientes por revisar."}
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: "24px 32px" }}>
          <Button
            id={"cancelBtn"}
            onClick={() => props.handlers.handleOpenCloseModal()}
            sx={refundModalStyles.cancelBtn}
          >
            <Typography sx={refundModalStyles.buttonsText}>
              {"Cancelar"}
            </Typography>
          </Button>
          <Button
            id={"sendBtn"}
            onClick={() => {
              props.handlers.handleUpdateConciliationStatus();
              props.handlers.handleOpenCloseModal();
            }}
            autoFocus
            color="primary"
            variant="contained"
            sx={refundModalStyles.sendBtn}
          >
            <Typography sx={refundModalStyles.buttonsText}>
              {"Enviar"}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
