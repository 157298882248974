import { StatusDeferred } from "../components/Deferred/Deferred.data";
import { MessageAlertEnum } from "../shared/enums/AlertEnum";
import { DeferredOption } from "../../types/get_deferred_admin_merchant_response";
import { format } from "date-fns";
import { defaultTo } from "lodash";
import { CountriesEnum } from "../shared/infrastructure/enums/CountriesEnum";

export const getMessageSnackBar = (
  currentDeferredOption: DeferredOption,
  deferredOption: DeferredOption
): string => {
  let message: string = "";

  if (
    currentDeferredOption.status === StatusDeferred.STATUS_DISABLED &&
    deferredOption.status === StatusDeferred.STATUS_ENABLED
  ) {
    message = MessageAlertEnum.UPDATE_STATUS_DEFERRED_ENABLING_SUCCESS;
  } else if (
    currentDeferredOption.status === StatusDeferred.STATUS_ENABLED &&
    deferredOption.status === StatusDeferred.STATUS_DISABLED
  ) {
    message = MessageAlertEnum.UPDATE_STATUS_DEFERRED_DISABLING_SUCCESS;
  } else if (
    currentDeferredOption.merchantStatus === StatusDeferred.STATUS_DISABLED &&
    deferredOption.merchantStatus === StatusDeferred.STATUS_ENABLED
  ) {
    message = MessageAlertEnum.UPDATE_STATUS_DEFERRED_ENABLED_SUCCESS;
  } else if (
    currentDeferredOption.merchantStatus === StatusDeferred.STATUS_ENABLED &&
    deferredOption.merchantStatus === StatusDeferred.STATUS_DISABLED
  ) {
    message = MessageAlertEnum.UPDATE_STATUS_DEFERRED_DISABLED_SUCCESS;
  }

  return message;
};

export const calculateDateUtc = (
  dateTimestamp: number,
  isStart: boolean
): number => {
  const date = new Date(dateTimestamp);
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    isStart ? 0 : 23,
    isStart ? 0 : 59,
    isStart ? 0 : 59
  ).getTime();
};

export const convertFormatDate = (date: number, formatDate: string): string => {
  return format(date, formatDate);
};
export enum ProcessorNameEnum {
  FIS = "FIS PROCESSOR",
}
export const TRANSLATE_PROCESSOR: Record<string, string> = {
  [ProcessorNameEnum.FIS]: "Worldpay (by Fis)",
};
export const translateProcessor = (text: string): string => {
  return defaultTo(TRANSLATE_PROCESSOR[text.toUpperCase()], text);
};

const names = {
  BRASIL: "Brasil",
  COSTA_RICA: "Costa Rica",
  EL_SALVADOR: "El Salvador",
};

export const convertCountryName = (country: CountriesEnum) => {
  switch (country) {
    case CountriesEnum.COSTA_RICA:
      return names.COSTA_RICA;
    case CountriesEnum.EL_SALVADOR:
      return names.EL_SALVADOR;
    case CountriesEnum.BRAZIL:
      return names.BRASIL;
    default:
      return country;
  }
};
