/**
 * TransactionStatus
 */
export enum TransactionStatusEnum {
  APPROVAL = "APPROVAL",
  DECLINED = "DECLINED",
  INITIALIZED = "INITIALIZED",
  PROCESS = "IN PROCESS",
  VOID = "VOID",
}

export const getTransactionStatusTranslate = (value: string): string => {
  switch (value) {
    case TransactionStatusEnum.APPROVAL:
      return "Aprobada";
    case TransactionStatusEnum.DECLINED:
      return "Declinada";
    case TransactionStatusEnum.INITIALIZED:
      return "Inicializada";
    case TransactionStatusEnum.PROCESS:
      return "En proceso";
    case TransactionStatusEnum.VOID:
      return "";
    default:
      return "";
  }
};
