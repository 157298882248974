import { ServiceConfigEnum } from "../enums/ServiceConfigEnum";

export const MASSIVE_SERVICES_MODAL = {
  CONFIRM_DEFAULT: {
    acceptButtonText: ServiceConfigEnum.FINISH,
    cancelButtonText: ServiceConfigEnum.CANCEL,
    descriptionText: ServiceConfigEnum.MASSIVE_DEFAULT_MODAL_DESCRIPTION,
    titleText: ServiceConfigEnum.MASSIVE_MODAL_TITLE,
  },
  CONFIRM_EDITION: {
    acceptButtonText: ServiceConfigEnum.FINISH,
    cancelButtonText: ServiceConfigEnum.CANCEL,
    descriptionText: ServiceConfigEnum.MASSIVE_MODAL_DESCRIPTION,
    titleText: ServiceConfigEnum.MASSIVE_MODAL_TITLE,
  },
  SAVE_SERVICES: {
    acceptButtonText: ServiceConfigEnum.SAVE,
    cancelButtonText: ServiceConfigEnum.CANCEL,
    descriptionText: ServiceConfigEnum.MASSIVE_SERVICES_DESCRIPTION,
    titleText: ServiceConfigEnum.MASSIVE_SERVICES_TITLE,
  },
};
