import { ActionTypes } from "./actionTypes";
import { IAppAction } from "./actionCreators";
import { IAppState } from "../shared/infrastructure/interfaces/IAppState";

export const INITIAL_STATE: IAppState = {
  credentialsForBox: [],
  credentialsList: { data: [], total: 0 },
  credentialsListModal: { data: [], total: 0 },
  isLoading: true,
  isLoadingCredentials: true,
  isOpenCredentialModal: false,
  isOpenCustomModal: false,
  nodeInfo: {},
  openAlert: { open: false, isError: false },
  requestError: false,
  branchesData: { data: [], total: 0 },
  parentMerchantId: "",
};

export const reducer = (
  state: IAppState = INITIAL_STATE,
  action: IAppAction
): IAppState => {
  switch (action.type) {
    case ActionTypes.SET_CREDENTIALS_LIST:
      return {
        ...state,
        credentialsList: action.credentialsList,
      };
    case ActionTypes.SET_CREDENTIALS_LIST_MODAL:
      return {
        ...state,
        credentialsListModal: action.credentialsListModal,
      };
    case ActionTypes.SET_IS_LOADING_CREDENTIALS:
      return {
        ...state,
        isLoadingCredentials: action.isLoadingCredentials,
      };
    case ActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case ActionTypes.SET_OPEN_ALERT:
      return {
        ...state,
        openAlert: action.openAlert,
      };
    case ActionTypes.SET_PARENT_MERCHANT_ID:
      return {
        ...state,
        parentMerchantId: action.parentMerchantId,
      };
    case ActionTypes.SET_REQUEST_ERROR:
      return {
        ...state,
        requestError: action.requestError,
      };
    case ActionTypes.SET_IS_OPEN_CUSTOM_MODAL:
      return {
        ...state,
        isOpenCustomModal: action.isOpenCustomModal,
      };
    case ActionTypes.SET_IS_OPEN_CREDENTIAL_MODAL:
      return {
        ...state,
        isOpenCredentialModal: action.isOpenCredentialModal,
      };
    case ActionTypes.SET_CREDENTIALS_FOR_BOX:
      return {
        ...state,
        credentialsForBox: action.credentialsForBox,
      };
    case ActionTypes.SET_NODE_INFO:
      return {
        ...state,
        nodeInfo: action.nodeInfo,
      };
    case ActionTypes.SET_BRANCHES_DATA:
      return {
        ...state,
        branchesData: action.branchesData,
      };
    default:
      return state;
  }
};
