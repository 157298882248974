import { LabelEnum } from "./LabelEnum";

export enum TableFieldsEnum {
  CREATED = "created",
  COUNTRY = "country",
  STATUS = "status",
  ALIAS = "rule.name",
  CATEGORY = "category",
  PROCESSOR = "rule.processor_name",
  TITLE = "title",
  FIELD = "field",
  OPERATORS = "operators",
  PROCESSOR_RULE = "rule.processorName",
}

export enum TableBodyCellEnum {
  TITLE = "TITLE",
  TEXT = "TEXT",
  TAG = "TAG",
  OPTIONS = "OPTIONS",
}

export enum StatusEnum {
  ENABLED = "Habilitado",
  DISABLED = "Deshabilitado",
  DRAFT = "Borrador",
}

export const BUSINESS_RULES_COLUMNS: string[] = [
  TableFieldsEnum.CREATED,
  TableFieldsEnum.ALIAS,
  TableFieldsEnum.CATEGORY,
  TableFieldsEnum.PROCESSOR,
  TableFieldsEnum.STATUS,
];

export const BUSINESS_RULES_STORAGE_COLUMNS: string[] = [
  TableFieldsEnum.CREATED,
  TableFieldsEnum.ALIAS,
  TableFieldsEnum.CATEGORY,
  TableFieldsEnum.PROCESSOR_RULE,
  TableFieldsEnum.STATUS,
];

export const CONDITIONS_COLUMNS: string[] = [
  TableFieldsEnum.TITLE,
  TableFieldsEnum.FIELD,
  TableFieldsEnum.OPERATORS,
];

export const COLUMNS_BY_TABS: Record<string, string[]> = {
  [LabelEnum.BUSINESS_RULES]: BUSINESS_RULES_COLUMNS,
  [LabelEnum.CONDITIONS]: CONDITIONS_COLUMNS,
};

export const COLUMNS_BY_TABS_STORAGE: Record<string, string[]> = {
  [LabelEnum.BUSINESS_RULES]: BUSINESS_RULES_STORAGE_COLUMNS,
  [LabelEnum.CONDITIONS]: CONDITIONS_COLUMNS,
};

export const STATUS_SETTINGS: Record<string, string> = {
  [StatusEnum.ENABLED]: "rule.processor_name",
  [StatusEnum.DISABLED]: "rule.processor_name",
  [StatusEnum.DRAFT]: "rule.processor_name",
};
