import { useAppSelector } from "../../../../../store/hooks/hooks";
import { selectTransactionsLoading } from "../../../../../store/selectors/transactions/transactions";
import { useDateFilterState } from "../useDateFilterState/useDateFilterState";
import { useGeneralFilterState } from "../useGeneralFilterState/useGeneralFilterState";
import { useSearchFilterState } from "../useSearchFilterState/useSearchFilterState";
import { IUseTableFiltersState } from "./useTableFiltersState.interfaces";

export const useTableFiltersState = (): IUseTableFiltersState => {
  const areTransactionsLoading = useAppSelector(selectTransactionsLoading);

  return {
    areTransactionsLoading,
    dateState: useDateFilterState(),
    generalState: useGeneralFilterState(),
    searchState: useSearchFilterState(),
  };
};
