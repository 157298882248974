export enum BackofficeRolesEnum {
  BackofficeAdmin = "Administrador",
  BackofficeBank = "Usuario Banco",
  BackofficeUserMaker = "Creador de usuarios",
  BackofficeUserMaster = "Usuario Maestro",
  BackofficeUserReader = "Usuario de lectura",
  BackofficeMonitoring = "Monitoreo",
  WebcheckoutBasic = "Smartlink",
  WebcheckoutMaster = "Maestro Smartlink",
  VirtualPOS = "Virtual POS",
  MguayaquilReviewer = "Revisor MGuayaquil",
  BackofficeGlobalReports = "Usuario Global",
  BackofficeCompliance = "Operador de Cumplimiento",
  SnrCoordinator = "Coordinador SNR",
  SnrAgent = "Agente SNR",
  ExpressCheckoutMaster = "Express Checkout",
}
