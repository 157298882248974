/**
 * Security Wrapper Enum
 */

export enum SecurityWrapperEnum {
  MODULE_ID = "M_TRANSACTIONS.PAYOUTS_TRANSACTION",
  PAYMENT_DOWNLOAD = "M_TRANSACTIONS.PAYOUTS_TRANSACTION.Pagos.Descargar",
  PAYMENT_DETAIL_DOWNLOAD = "M_TRANSACTIONS.PAYOUTS_TRANSACTION.Pagos.DescargarDetalle",
  PAYMENT_RECEIPE_DOWNLOAD = "M_TRANSACTIONS.PAYOUTS_TRANSACTION.Pagos.DescargarRecibo",
}
