/* eslint-disable no-trailing-spaces */

import React, { SVGProps } from "react";

const SvgCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: "10px" }}
    {...props}
  >
    <path
      d="M16.0552 0.198242H2.09621C1.08383 0.198242 0.259521 1.02255 0.259521 2.03497V10.1165C0.259521 11.1288 1.08383 11.9532 2.09621 11.9532H16.0551C17.0675 11.9532 17.8918 11.1288 17.8918 10.1165V2.03497C17.8919 1.02255 17.0676 0.198242 16.0552 0.198242ZM17.1572 10.1164C17.1572 10.724 16.6628 11.2185 16.0552 11.2185H2.09621C1.48862 11.2185 0.994191 10.724 0.994191 10.1164V2.03497C0.994191 1.42738 1.48862 0.932946 2.09621 0.932946H16.0551C16.6627 0.932946 17.1572 1.42738 17.1572 2.03497V10.1164H17.1572Z"
      fill="#1E65AE"
    />
    <path
      d="M17.5245 2.40234H0.626873C0.424101 2.40234 0.259521 2.56692 0.259521 2.7697V4.97374C0.259521 5.17651 0.424101 5.34109 0.626873 5.34109H17.5245C17.7273 5.34109 17.8919 5.17651 17.8919 4.97374V2.7697C17.8919 2.56692 17.7273 2.40234 17.5245 2.40234ZM17.1572 4.60639H0.994191V3.13701H17.1572V4.60639H17.1572Z"
      fill="#1E65AE"
    />
    <path
      d="M7.239 7.54492H2.83091C2.62814 7.54492 2.46356 7.7095 2.46356 7.91227C2.46356 8.11505 2.62814 8.27959 2.83091 8.27959H7.239C7.44177 8.27959 7.60635 8.11501 7.60635 7.91224C7.60635 7.70947 7.44177 7.54492 7.239 7.54492Z"
      fill="#1E65AE"
    />
    <path
      d="M7.239 9.01465H2.83091C2.62814 9.01465 2.46356 9.17923 2.46356 9.382C2.46356 9.58477 2.62814 9.74935 2.83091 9.74935H7.239C7.44177 9.74935 7.60635 9.58477 7.60635 9.382C7.60632 9.17923 7.44177 9.01465 7.239 9.01465Z"
      fill="#1E65AE"
    />
    <path
      d="M14.5859 6.81055H13.8512C13.2436 6.81055 12.7492 7.30498 12.7492 7.91257V8.64724C12.7492 9.25483 13.2436 9.74926 13.8512 9.74926H14.5859C15.1935 9.74926 15.6879 9.25483 15.6879 8.64724V7.91257C15.6879 7.30501 15.1935 6.81055 14.5859 6.81055ZM14.9532 8.64727C14.9532 8.85004 14.7887 9.01462 14.5859 9.01462H13.8512C13.6485 9.01462 13.4839 8.85004 13.4839 8.64727V7.9126C13.4839 7.70983 13.6485 7.54525 13.8512 7.54525H14.5859C14.7887 7.54525 14.9532 7.70983 14.9532 7.9126V8.64727Z"
      fill="#1E65AE"
    />
  </svg>
);

export default SvgCard;
