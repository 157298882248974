import React, { FC } from "react";
import { Box, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Subheader from "../../components/Subheader/Subheader";
import { mainContainerStyles as styles } from "./MainContainer.styles";
import Buttons from "../../shared/constants/labels/buttons_labels";
import { useMainContainer } from "./state/useMainContainer";
import DashboardComponent from "../../components/DashboardComponent/DashboardComponent";
import { isInternalConsole } from "../../shared/utils/localStorageUtils";

const MainContainer: FC = () => {
  const { country, subheaderInfo, publicMerchantId, redirectPath } =
    useMainContainer();

  return (
    <Box sx={styles.boxContainer}>
      {isInternalConsole() && (
        <IconButton sx={styles.returnButton} onClick={redirectPath}>
          <ArrowBackIosIcon />
          {Buttons.RETURN_BUTTON}
        </IconButton>
      )}
      <Subheader {...subheaderInfo} />
      <DashboardComponent
        publicMerchantId={publicMerchantId}
        country={country}
      />
    </Box>
  );
};

export default MainContainer;
