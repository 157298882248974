import React from "react";
import { InputAdornment, TextField } from "@mui/material";
import { IInputNumberProps } from "@components/InputsForm/Inputs/InputNumber/InputNumber.interfaces";
import { inputNumberStyles as styles } from "@components/InputsForm/Inputs/InputNumber/InputNumber.styles";
import { isEmpty } from "lodash";
import { PATTERNS } from "@shared/constants/formRules";
import { FieldOptionValue } from "@shared/constants/AlarmConfigConstants";

const InputNumber: React.FC<IInputNumberProps> = ({
  label,
  error,
  field,
  fieldName,
}: IInputNumberProps) => {
  return (
    <TextField
      sx={styles.input}
      helperText={error ? error.message : ""}
      error={!!error}
      type={"text"}
      placeholder={label}
      value={field.value}
      onChange={(e) => {
        const value: string = e.target.value;

        if (new RegExp(PATTERNS.numeric).test(value) || isEmpty(value)) {
          field.onChange(value);
        }
      }}
      InputProps={{
        endAdornment: [
          FieldOptionValue.TPV,
          FieldOptionValue.AVERAGE_TICKET,
        ].includes(fieldName as FieldOptionValue) ? (
          <InputAdornment position="end">USD</InputAdornment>
        ) : (
          <span></span>
        ),
      }}
    />
  );
};

export default InputNumber;
