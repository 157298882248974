import React from "react";
import { Button } from "@mui/material";
import { IconPlus } from "@kushki/connect-ui";
import { ADD_USERS } from "../../shared/constants/labels/UserConfigurationConstants";
import { IAddUserButtonProps } from "./AddUserButton.interfaces";

const AddUserButton: React.FC<IAddUserButtonProps> = ({
  redirectLink,
}: IAddUserButtonProps) => {
  const handleRedirect = () => {
    window.location.href = redirectLink;
  };

  return (
    <Button
      variant="outlined"
      color="secondary"
      startIcon={<IconPlus />}
      onClick={handleRedirect}
    >
      {ADD_USERS}
    </Button>
  );
};

export default AddUserButton;
