import { defaultTo, get } from "lodash";
import { OcbEnum } from "../infrastructure/OcbEnum";

export const getValueWithDefault = (
  trx: object | undefined,
  path: string,
  defaultValue: string
) => defaultTo(get(trx, path), defaultValue);

export const isOcbValidate = (
  isOcb: boolean,
  customerId: string,
  merchantId: string
): string => {
  const validateId: boolean = customerId === merchantId;
  return isOcb
    ? validateId
      ? OcbEnum.DECENTRALIZED
      : OcbEnum.CENTRALIZED
    : OcbEnum.OCB_2_0;
};

export const isOcbValidateOwnerCustomer = (
  isOcb: boolean,
  item: object,
  path: string,
  message: string
) => {
  return isOcb ? get(item, path, "") : message;
};
