import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const headerFiltersStyles = createNamedStyles({
  gridFilter: {
    display: "flex",
    justifyContent: "left",
  },
  button: {
    alignItems: "center",
    display: "flex",
    justifyContent: "right",
  },
  buttonItem: {
    padding: "5px 20px",
    gap: "7px",
  },
  itemLottie: {
    height: "25px",
    margin: "0",
    padding: "0",
    width: "25px",
  },
  boxLottie: {
    height: "84px",
    position: "relative",
    top: { md: "2px", xs: "0px" },
    width: "84px",
  },
  gridFilterCard: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    "& .css-79elbk": {
      flexWrap: "wrap",
      width: "100%",
    },
  },
});
