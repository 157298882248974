import { Catalog, Data } from "../../../types/catalog_response";
import { defaultTo, get } from "lodash";

export const getCatalogByName = (
  listCatalogs: Catalog[],
  name: string
): Data[] => {
  const list: Catalog | undefined = listCatalogs.find(
    (catalog: Catalog) => catalog.name.toUpperCase() === name.toUpperCase()
  );

  return defaultTo(get(list, "data"), []);
};
