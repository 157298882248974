import React from "react";
import {
  Box,
  Checkbox,
  Chip,
  createStyles,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { InvoiceRecord } from "../../../../../types/remote/response_search_blling_by_filters";
import { defaultTo, floor, get, isEmpty, isUndefined } from "lodash";
import {
  setChipLabel,
  setChipLabelKind,
} from "../DashboardBillingTable/DashboardTableRow/DashboardTableRow";
import NumberFormat from "react-number-format";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IBillingDashboardState } from "../../../../store/reducer";
import {
  BillingActions,
  setBillingHistoricDataRow,
  transactionIdFilterHistory,
} from "../../../../store/actionCreators";
import { connect } from "react-redux";
import { useDashboardHistoricTableRowState } from "./state/useDashboardHistoricTableRowState";
import { translate } from "../../../../shared/infrastructure/LanguageCatatog";
import { CountryEnum } from "../../../../shared/infrastructure/CountryEnum";
import {
  InitialStatusEnum,
  StatusEnum,
} from "../../../../shared/infrastructure/StatusEnum";
import { themeOptions } from "../../../../themeui";
import {
  getCatalogFinalStatusInfo,
  IChipProps,
} from "../../../../shared/infrastructure/CatalogFinalStatus";
import { ChipStatus } from "../../../ChipStatus/ChipStatus";
import {
  ColumnsEnum,
  IColumnsTable,
} from "../../../../shared/infrastructure/table/IColumnsTable";
import { getCatalogInitialStatusInfo } from "../../../../shared/infrastructure/InititalStatusCatalog";
import { validateDate } from "../../../ModalBodyInfo/utilsModalBody";
import ManualIcon from "../../../../assets/images/manual_type_icon";
import { MessageTooltipEnum } from "../../../../shared/MessageTooltipEnum";
import { KindEnum } from "../../../../shared/infrastructure/KindEnum";
import { InvoiceModeEnum } from "../../../../shared/infrastructure/InvoiceModeEnum";
import BackRegisterIcon from "../../../../assets/images/backRegisterIcon";
import { HistoricTooltipValuesEnum } from "../../../../shared/infrastructure/enum/HistoricTooltipValuesEnum";
import { formatDate } from "../../../../shared/utils/date-time-util";

import {
  RetentionCatalog,
  RetentionType,
} from "../../../../shared/infrastructure/constants/RetentionConstants";
import { isOcbValidate } from "../../../../shared/utils/utils";

export interface DashboardHistoricTableRowState {
  billingInitialStatus: string;
  billingState: string;
  historicBillingData: InvoiceRecord | undefined;
  handlerOpenRow: (row: InvoiceRecord | undefined) => void;
  country: string;
  renderColumns: IColumnsTable[];
  index: number;
  modalBackRetention: {
    openModalBackRetention: boolean;
    handlerOpenModalBackRetention: (row: InvoiceRecord | undefined) => void;
    handlerCloseModalBackRetention: () => void;
  };
}

export interface DashboardHistoricTableRowFunctionsProps {
  setBillingHistoricDataRow: (billingDataRow: InvoiceRecord) => void;
  transactionIdFilterHistory: () => void;
}
export type DashboardHistoricTableRowProps = DashboardHistoricTableRowState &
  DashboardHistoricTableRowFunctionsProps;

const styles = makeStyles((theme) =>
  createStyles({
    cycleDate: {
      color: `${themeOptions.palette.text.secondary}`,
    },
    backButton: {
      "&:hover": {
        background: "none",
      },
    },
    chipStyle: {
      backgroundColor: "#F7FAFC",
      borderRadius: "4px",
      width: "96px",
      height: "26px",
    },
    chipStateStyle: {
      width: "120px",
      height: " fit-content",
      padding: "5px",
      textAlign: "center",
      backgroundColor: (props: DashboardHistoricTableRowProps) => {
        switch (props.billingState) {
          case "completed":
            return get(props.historicBillingData, "kind") ===
              KindEnum.RETENTION_EC
              ? `${themeOptions.palette.success.light}`
              : `${themeOptions.palette.info.light}`;
          case "finished":
            return `${themeOptions.palette.success.light}`;
          case "successful":
          case "processed":
          case "rejected":
            return `${themeOptions.palette.success.light}`;
          case "omit":
          case "omitted":
            return `${themeOptions.palette.info.light}`;
          case "emitted":
            return `${themeOptions.palette.info.light}`;
          case "pending":
            return get(props.historicBillingData, "kind") ===
              KindEnum.ANNUL_INVOICE
              ? "#FEECF8"
              : `${themeOptions.palette.warning.light}`;
          case "pre_processed":
            return `${themeOptions.palette.warning.light}`;
          case "manual":
            return `${themeOptions.palette.warning.light}`;
          case "failed":
          case "expired":
          case "annulled":
          case "reject":
          case "annul":
          case "deleted":
            return `${themeOptions.palette.error.light}`;
          case "pending_verify":
            return `${themeOptions.palette.info.light}`;
          default:
            return "inherit";
        }
      },
    },
    chipInitialStateStyle: {
      borderRadius: "4px",
      backgroundColor: (props: DashboardHistoricTableRowProps) => {
        switch (props.billingInitialStatus) {
          case InitialStatusEnum.APPROVE:
            return `${themeOptions.palette.secondary.light3}`;
          case InitialStatusEnum.OMIT:
          case InitialStatusEnum.OMITTED:
          case InitialStatusEnum.EMITTED:
            return `${themeOptions.palette.primary.light3}`;
          case InitialStatusEnum.PENDING:
            return `${themeOptions.palette.alternate.light3}`;
          case InitialStatusEnum.REJECT:
          case InitialStatusEnum.EXPIRED:
            return `${themeOptions.palette.error.light3}`;
          default:
            return "inherit";
        }
      },
      color: (props: DashboardHistoricTableRowProps) => {
        switch (props.billingInitialStatus) {
          case InitialStatusEnum.APPROVE:
            return `${themeOptions.palette.success.dark}`;
          case InitialStatusEnum.OMIT:
          case InitialStatusEnum.OMITTED:
          case InitialStatusEnum.EMITTED:
            return `${themeOptions.palette.primary.main}`;
          case InitialStatusEnum.PENDING:
            return `${themeOptions.palette.alternate.dark2}`;
          case InitialStatusEnum.REJECT:
          case InitialStatusEnum.EXPIRED:
            return `${themeOptions.palette.error.dark2}`;
          default:
            return "inherit";
        }
      },
    },
    tooltipTitle: {
      fontSize: theme.spacing(1.5),
      maxWidth: "none",
      backgroundColor: "#6D7781",
    },
    tooltip: {
      fontSize: theme.spacing(1.75),
      maxWidth: theme.spacing(21.5),
      backgroundColor: "#677684",
    },
  })
);

export const formatNumber = (value: number, currency: string) => {
  return (
    <NumberFormat
      value={value}
      thousandSeparator
      displayType={"text"}
      decimalScale={2}
      suffix={` ${currency}`}
    />
  );
};

const FORMAT_DATE: string = "dd/MM/yyyy";
const FORMAT_TIME: string = "HH:mm";

const getCursorStyle = (row?: InvoiceRecord) => {
  if (row) return "pointer";

  return "default";
};

const cursorPointerStyle = (row?: InvoiceRecord) => ({
  cursor: getCursorStyle(row),
});

const getDocumentNumber = (trx: InvoiceRecord | undefined) => {
  if (get(trx, "kind", " ") === KindEnum.RETENTION_EC)
    return get(trx, "retention_number", " ");

  if (get(trx, "country", " ") === CountryEnum.ecuador)
    return get(trx, "invoice_number", " ");
  else return get(trx, "invoice_id", " ");
};

const getTotalAmount = (trx: InvoiceRecord | undefined) => {
  const country: string = get(trx, "country");

  if (
    get(trx, "kind", "") === KindEnum.RETENTION ||
    (get(trx, "country", "") === CountryEnum.colombia &&
      get(trx, "kind", "") === KindEnum.RETENTION_CO)
  )
    return formatNumber(
      get(trx, "ret_total_amount", 0),
      get(trx, "currency_code", "USD")
    );

  if (
    get(trx, "country", "") === CountryEnum.mexico &&
    get(trx, "invoice_mode", "") === InvoiceModeEnum.WITHOUT_IVA
  )
    return formatNumber(
      get(trx, "amount", 0),
      get(trx, "currency_code", "USD")
    );

  if (get(trx, "kind", "") === KindEnum.INVOICE) {
    const amount: number =
      country === CountryEnum.colombia
        ? floor(get(trx, "invoice_amount_total", 0), 2)
        : get(trx, "invoice_amount_total", 0);

    return formatNumber(amount, get(trx, "currency_code", "USD"));
  }

  return formatNumber(get(trx, "amount", 0), get(trx, "currency_code", "USD"));
};

const getKindStatusRetention = (trx: InvoiceRecord | undefined) =>
  get(trx, "initial_status", "") === InitialStatusEnum.REJECT &&
  get(trx, "kind", "") === KindEnum.RETENTION_EC;

export const DashboardHistoricTableRow: React.FC<DashboardHistoricTableRowProps> = (
  props: DashboardHistoricTableRowProps
) => {
  const { actions } = useDashboardHistoricTableRowState(props);
  const calculateCycle = get(props.historicBillingData, "calculate_cycle", 1);
  const finalStatusInfo: IChipProps =
    get(props.historicBillingData, "initial_status", "") ===
    InitialStatusEnum.REJECT
      ? getCatalogFinalStatusInfo(
          get(props.historicBillingData, "kind", ""),
          get(props.historicBillingData, "country", ""),
          StatusEnum.REJECTED
        )
      : getCatalogFinalStatusInfo(
          get(props.historicBillingData, "kind", ""),
          get(props.historicBillingData, "country", ""),
          get(props.historicBillingData, "status", "")
        );
  const initialStatus: IChipProps = getCatalogInitialStatusInfo(
    get(props.historicBillingData, "kind", ""),
    get(props.historicBillingData, "country", ""),
    get(props, "billingInitialStatus", "")
  );

  const classes = styles(props);
  const getProcessorType = (processorType: string) => {
    const modelLabel =
      RetentionCatalog[RetentionType.processorType][
        defaultTo(processorType, "")
      ];
    let splitted = ["", ""];
    if (!isEmpty(modelLabel)) splitted = modelLabel.split(" ");
    return (
      <>
        <Typography>{splitted[0]}</Typography>
        <Typography>{splitted[1]}</Typography>
      </>
    );
  };
  return (
    <TableRow
      onClick={(e) => {
        props.handlerOpenRow(props.historicBillingData);
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {props.renderColumns.map((column: IColumnsTable) => {
        switch (column.id) {
          case ColumnsEnum.STATUS_ALL:
            return (
              <TableCell>
                <Checkbox
                  id={"checkFieldStatusAll"}
                  checked={get(props.historicBillingData, "statusAll", false)}
                  onChange={(e) => {
                    actions.handleChangeCheckboxAll(
                      e,
                      props.historicBillingData,
                      false
                    );
                  }}
                  onClick={(e) => e.stopPropagation()}
                  color={"primary"}
                  inputProps={{ "aria-label": "statusAll" }}
                />
              </TableCell>
            );
          case ColumnsEnum.TAX_ID:
            return (
              <TableCell key={column.id}>
                {get(props.historicBillingData, "tax_id", "")}
              </TableCell>
            );
          case ColumnsEnum.MERCHANT_NAME:
            return (
              <TableCell key={column.id}>
                <Box>{get(props.historicBillingData, "merchant_name", "")}</Box>
                <Typography variant={"body2"} className={classes.cycleDate}>
                  <Box fontSize={12}>
                    {get(props.historicBillingData, "merchant_id", "-")}
                  </Box>
                </Typography>
              </TableCell>
            );
          case ColumnsEnum.MUNICIPALITY:
            return (
              <TableCell key={column.id}>
                {
                  RetentionCatalog[RetentionType.city][
                    get(props.historicBillingData, "city_id", "")
                  ]
                }
              </TableCell>
            );
          case ColumnsEnum.KUSHKI_TOTAL_RETENTION:
            return (
              <TableCell key={column.id}>
                {get(props.historicBillingData, "kind", "") ===
                KindEnum.RETENTION_CO
                  ? formatNumber(
                      get(props.historicBillingData, "ret_total_amount", 0),
                      get(props.historicBillingData, "currency_code", "USD")
                    )
                  : formatNumber(
                      get(props.historicBillingData, "amount", 0),
                      get(props.historicBillingData, "currency_code", "USD")
                    )}
              </TableCell>
            );
          case ColumnsEnum.DAVIVIENDA_TOTAL_RETENTION:
            return (
              <TableCell key={column.id}>
                {get(props.historicBillingData, "kind", "") ===
                  KindEnum.RETENTION_CO &&
                  formatNumber(
                    get(props.historicBillingData, "ret_total_amount_davi", 0),
                    get(props.historicBillingData, "currency_code", "USD")
                  )}
              </TableCell>
            );
          case ColumnsEnum.MODEL:
            return (
              <TableCell key={column.id}>
                {getProcessorType(
                  get(props.historicBillingData, "processor_type", "")
                )}
              </TableCell>
            );
          case ColumnsEnum.MERCHANT_ID:
            return (
              <TableCell key={column.id}>
                <Typography>
                  {get(props.historicBillingData, "merchant_id", "")}
                </Typography>
              </TableCell>
            );
          case ColumnsEnum.OWNER_NAME:
            return (
              <TableCell key={column.id}>
                <Box>
                  <Typography>
                    {get(props.historicBillingData, "owner_name", "")}
                  </Typography>
                </Box>
                <Typography variant="body2" className={classes.cycleDate}>
                  {get(props.historicBillingData, "owner_id", "")}
                </Typography>
              </TableCell>
            );
          case ColumnsEnum.CUSTOMER_NAME:
            return (
              <TableCell key={column.id}>
                <Box>
                  <Typography>
                    {get(props.historicBillingData, "customer_name", "")}
                  </Typography>
                </Box>
                <Typography variant="body2" className={classes.cycleDate}>
                  {get(props.historicBillingData, "customer_id_hierarchy", "")}
                </Typography>
              </TableCell>
            );
          case ColumnsEnum.IS_OCB:
            return (
              <TableCell key={column.id}>
                {isOcbValidate(
                  get(props.historicBillingData, "is_ocb", ""),
                  get(props.historicBillingData, "customer_id", ""),
                  get(props.historicBillingData, "merchant_id", "")
                )}
              </TableCell>
            );
          case ColumnsEnum.SOCIAL_REASON:
            return (
              <TableCell key={column.id}>
                {get(props.historicBillingData, "social_reason", "")}
              </TableCell>
            );
          case ColumnsEnum.CYCLE:
            return (
              <TableCell key={column.id}>
                <Box>
                  {defaultTo(
                    get(props.historicBillingData, "is_exclusive"),
                    false
                  )
                    ? translate("exclusive")
                    : translate(get(props.historicBillingData, "cycle", ""))}
                </Box>
                <Typography variant="body2" className={classes.cycleDate}>
                  <Box fontSize={12}>
                    {formatDate(
                      get(props.historicBillingData, "start_date", 1),
                      FORMAT_DATE
                    )}{" "}
                    -{" "}
                    {formatDate(
                      get(props.historicBillingData, "end_date", 1),
                      FORMAT_DATE
                    )}
                  </Box>
                </Typography>
              </TableCell>
            );
          case ColumnsEnum.INVOICE_DOCUMENT_NUMBER:
            return (
              <TableCell key={column.id} align={"center"}>
                {getDocumentNumber(props.historicBillingData)}
              </TableCell>
            );
          case ColumnsEnum.AMOUNT:
            return (
              <TableCell key={column.id}>
                {getTotalAmount(props.historicBillingData)}
              </TableCell>
            );
          case ColumnsEnum.KIND:
            return (
              <TableCell key={column.id} align={"center"}>
                <Box display={"flex"} flexDirection={"row"}>
                  {actions.showManualIcon(
                    get(props.historicBillingData, "kind", ""),
                    get(props.historicBillingData, "transaction_type", "")
                  ) && (
                    <Tooltip
                      arrow
                      title={MessageTooltipEnum.manualMessage}
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Box pr={0.5}>
                        <ManualIcon />
                      </Box>
                    </Tooltip>
                  )}
                  <Chip
                    style={cursorPointerStyle(props.historicBillingData)}
                    className={classes.chipStyle}
                    label={setChipLabel(
                      get(props.historicBillingData, "kind", ""),
                      get(props.historicBillingData, "country"),
                      get(props.historicBillingData, "invoice_mode", "")
                    )}
                    size="small"
                  />
                </Box>
              </TableCell>
            );
          case ColumnsEnum.TRANSACTION_TYPE:
            return (
              <TableCell key={column.id} align={"center"}>
                {isUndefined(
                  get(props.historicBillingData, "transaction_type")
                ) ? (
                  "-"
                ) : (
                  <Chip
                    style={cursorPointerStyle(props.historicBillingData)}
                    className={classes.chipStyle}
                    label={setChipLabelKind(
                      get(
                        props.historicBillingData,
                        "transaction_type",
                        ""
                      ).toLowerCase()
                    )}
                    size="small"
                  />
                )}
              </TableCell>
            );
          case ColumnsEnum.INITIAL_STATUS:
            return (
              <TableCell key={column.id} align={"center"}>
                <ChipStatus statusInfo={initialStatus} />
              </TableCell>
            );
          case ColumnsEnum.PROCESSING_DATE:
            return (
              <TableCell key={column.id}>
                <Box>
                  {formatDate(
                    get(
                      props.historicBillingData,
                      "processing_date",
                      calculateCycle
                    ),
                    FORMAT_DATE
                  )}
                </Box>
                <Typography variant="body2" className={classes.cycleDate}>
                  <Box fontSize={12}>
                    {formatDate(
                      get(
                        props.historicBillingData,
                        "processing_date",
                        calculateCycle
                      ),
                      FORMAT_TIME
                    )}
                  </Box>
                </Typography>
              </TableCell>
            );
          case ColumnsEnum.STATUS:
            return (
              <TableCell key={column.id}>
                {getKindStatusRetention(props.historicBillingData) ? (
                  <Typography align={"center"}>{"-"}</Typography>
                ) : (
                  <ChipStatus statusInfo={finalStatusInfo} />
                )}
              </TableCell>
            );
          case ColumnsEnum.INTERBANK_ACCOUNT_CODE:
            return (
              <TableCell key={column.id}>
                {get(props.historicBillingData, "interbank_account_code", "")}
              </TableCell>
            );
          case ColumnsEnum.CREATED:
            return (
              <TableCell key={column.id}>
                <Box>
                  {formatDate(
                    get(props.historicBillingData, "created", calculateCycle),
                    FORMAT_DATE
                  )}
                </Box>
                <Typography variant="body2" className={classes.cycleDate}>
                  <Box fontSize={12}>
                    {
                      validateDate(
                        get(
                          props.historicBillingData,
                          "created",
                          calculateCycle
                        )
                      )
                        .split("T")[1]
                        .split(".")[0]
                    }
                  </Box>
                </Typography>
              </TableCell>
            );
          case ColumnsEnum.BACK_BUTTON:
            const initialStatusOmit =
              get(props.historicBillingData, "initial_status", "") ===
                InitialStatusEnum.OMITTED &&
              get(props.historicBillingData, "kind", "") ===
                KindEnum.RETENTION_CO;
            return (
              <TableCell
                onClick={(e) => {
                  initialStatusOmit &&
                    (props.modalBackRetention.handlerOpenModalBackRetention(
                      props.historicBillingData
                    ),
                    e.preventDefault(),
                    e.stopPropagation());
                }}
                key={column.id}
                align={"center"}
              >
                {initialStatusOmit && (
                  <Tooltip
                    arrow
                    title={HistoricTooltipValuesEnum.backRegisterTextTex}
                    placement="top"
                    classes={{ tooltip: classes.tooltipTitle }}
                  >
                    <IconButton className={classes.backButton}>
                      <BackRegisterIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
            );
          default:
            return null;
        }
      })}
    </TableRow>
  );
};
export const mapDispatchToProps: (
  dispatch: Dispatch
) => DashboardHistoricTableRowFunctionsProps = (
  dispatch: ThunkDispatch<IBillingDashboardState, undefined, BillingActions>
): DashboardHistoricTableRowFunctionsProps => ({
  setBillingHistoricDataRow: (billingDataRow: InvoiceRecord): void =>
    dispatch(setBillingHistoricDataRow(billingDataRow)),
  transactionIdFilterHistory: (): void => {
    dispatch(transactionIdFilterHistory());
  },
});

export default connect(null, mapDispatchToProps)(DashboardHistoricTableRow);
