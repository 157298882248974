import React from "react";
import { SkeletonTable } from "../commons/Skeleton/SkeletonTable";
import { EmptyCard } from "../commons/EmptyCard/EmptyCard";
import { TableComponent } from "../TableComponent/TableComponent";
import { ProcessorFetch } from "../../shared/infrastructure/interfaces/ProcessorFetch";
import { IDefaultProcessorForm } from "../../shared/infrastructure/interfaces/IDefaultProcessorForm";
import TablePagination, {
  TablePaginationProps,
} from "../TablePagination/TablePagination";
import { IUpdateProcessor } from "../../shared/infrastructure/interfaces/IUpdateProcessor";

export interface IProcessorTable {
  processors?: ProcessorFetch[];
  defaultProcessors?: IDefaultProcessorForm;
  defaultPayoutsTransferProcessor?: IDefaultProcessorForm;
  limit: number;
  handleOpenDialogFailOver(processor: ProcessorFetch): void;
  handleOpenModalDetail(processor: ProcessorFetch): void;
  handleRefreshProcessors(): void;
  handleUpdateProcessor(processor: IUpdateProcessor): void;
  pagination: TablePaginationProps;
}

const ProcessorTable: React.FC<IProcessorTable> = (props: IProcessorTable) => {
  return (
    <React.Fragment>
      {!props.processors ? (
        <SkeletonTable columns={5} rows={props.limit!} />
      ) : props.processors?.length === 0 ? (
        <EmptyCard title="Aún no has agregado ningún procesador." />
      ) : (
        <>
          <TableComponent
            defaultProcessors={props.defaultProcessors}
            defaultPayoutsTransferProcessor={
              props.defaultPayoutsTransferProcessor
            }
            processors={props.processors}
            handleOpenDialogFailOver={props.handleOpenDialogFailOver}
            handleOpenModalDetail={props.handleOpenModalDetail}
            handleRefreshProcessors={props.handleRefreshProcessors}
            handleUpdateProcessor={props.handleUpdateProcessor}
          />
          <TablePagination {...props.pagination} />
        </>
      )}
    </React.Fragment>
  );
};

export default ProcessorTable;
