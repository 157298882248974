import { HeadCell } from "../../components/Table/TableHead/TableHead.interfaces";
import {
  headCells,
  HeadCellsLabels,
} from "../constants/CatalogConfigTableBranch";
import { reject } from "lodash";
export enum EntityEnum {
  OWNER = "OWNER",
  CUSTOMER = "CUSTOMER",
  BRANCH = "BRANCH",
}

export enum RoleEnum {
  CUSTOMER_MASTER_ROLL = "CUSTOMER_MASTER_ROLL",
}
export const CATALOG_HEADS_NODE: Record<string, HeadCell[]> = {
  [EntityEnum.BRANCH]: headCells,
  [RoleEnum.CUSTOMER_MASTER_ROLL]: reject(headCells, [
    "label",
    HeadCellsLabels.STATUS,
  ]),
};
