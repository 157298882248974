import jwtDecode, { JwtPayload } from "jwt-decode";
import { TypeIssuerEnum } from "../infrastructure/constants/TypeIssuerEnum";
import { JwtI } from "../../types/jwt_payload";
import { IKushkiMenu } from "../infrastructure/interfaces/IKushkiMenu";
import { flatten, sortBy, union, uniqBy, uniqWith } from "lodash";
import { MENU_BACKOFFICE_AZURE } from "../infrastructure/constants/KushkiMenu";
import { AxiosResponse } from "axios";
import axios from "../shared/axios-util";
import { environment } from "../environments/environment";
import { GetAllModulesResponseI } from "../../types/get_all_modules_response";
import { StateModuleEnum } from "../infrastructure/constants/StateModuleEnum";

export class TokenService {
  static _decodeToken() {
    return jwtDecode(localStorage.getItem("jwt")!);
  }

  static _getTokenIssuer() {
    const objectJwt = jwtDecode<JwtPayload>(localStorage.getItem("jwt")!);
    return this._findTypeJwt("cognito", objectJwt.iss!)
      ? TypeIssuerEnum.COGNITO
      : TypeIssuerEnum.AZURE;
  }

  static _mergeMenusAzure(menus: IKushkiMenu[]) {
    let menu: IKushkiMenu[];
    menu = flatten(menus);
    menu = uniqWith(menu, function (arr1, arr2) {
      if (arr1.title === arr2.title) {
        arr2.list = union(arr1.list, arr2.list);
        arr2.list = uniqBy(arr2.list, "title");
      }
      return arr1.title === arr2.title;
    });
    menu = sortBy(menu, "priority");
    return menu;
  }

  static async _getAzureMenus(): Promise<IKushkiMenu[]> {
    let listMenu: IKushkiMenu[] = MENU_BACKOFFICE_AZURE;
    const getModules = this._filterModules(await this.getAzureMenu());

    listMenu.map((index) => {
      getModules.map((indexModule) => {
        if (index.componentId === indexModule.id)
          index.requiredRol = indexModule.roles![0];
        if (index.list != undefined) {
          index.list.map((indexList) => {
            if (indexList.componentId === indexModule.id)
              indexList.requiredRol = indexModule.roles![0];
          });
        }
      });
    });
    let newListMenu: IKushkiMenu[] = this._buildListMenu(listMenu);

    return this._mergeMenusAzure(newListMenu);
  }

  private static _getTokenRoles() {
    const tokenRoles = jwtDecode<JwtI>(localStorage.getItem("jwt")!);

    return tokenRoles.roles;
  }

  private static async getAzureMenu(): Promise<GetAllModulesResponseI> {
    const response: AxiosResponse<GetAllModulesResponseI> = await axios.get<
      GetAllModulesResponseI
    >(`${environment.kushkiUrl}/api/v1/security/modules`);

    return await response.data;
  }

  private static _findTypeJwt(type: string, issuer: string) {
    return RegExp("\\b" + type + "\\b").test(issuer);
  }

  private static _filterModules(modules: GetAllModulesResponseI) {
    let modulesEnabled: GetAllModulesResponseI = [];
    modules.map((module) => {
      if (
        module.status === StateModuleEnum.ENABLED &&
        module.roles!.length > 0
      ) {
        modulesEnabled.push(module);
      }
    });

    return modulesEnabled;
  }

  private static _buildListMenu(listMenu: IKushkiMenu[]) {
    let filteredMenus: IKushkiMenu[] = [];
    const roles = this._getTokenRoles();

    if (roles === undefined || roles.length === 0) return [];

    filteredMenus = listMenu.filter((menu) => {
      return (
        roles?.filter(
          (singleRol: string | undefined) => singleRol === menu.requiredRol
        ).length > 0
      );
    });
    filteredMenus = filteredMenus.map((menu) => {
      menu.list = menu.list?.filter(
        (singleMenu) => singleMenu.requiredRol === menu.requiredRol
      );
      return menu;
    });

    return filteredMenus;
  }
}
