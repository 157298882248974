import {
  IErrorUpload,
  IFileUpload,
} from "../../components/FileUploadContainer/FileUploadContainer.interfaces";

export const fileSelectedInit: IFileUpload = {
  name: "",
  size: 0,
  type: "",
};

export const errorUploadingFileInit: IErrorUpload = {
  errorFile: null,
  message: "",
};
