import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { KshDateRangePicker } from "@kushki/frontend-molecules/date-range-picker";

export interface DateRangePickerProps {
  handleDate?: (dateRange: any) => void;
  disableFuture?: boolean;
  value?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  datePicker: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: "inherit",
      marginRight: "inherit",
      border: "none",
    },
  },

  gridContainer: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    width: "300px",
    height: "40px",
    borderRadius: 4,
    [theme.breakpoints.between(0, 321)]: {
      border: "none",
      borderRadius: 0,
      width: "180px",
      "& .MuiInputBase-input": {
        fontSize: 9,
      },
    },
    [theme.breakpoints.between(321, 376)]: {
      border: "none",
      borderRadius: 0,
      width: "235px",
      "& .MuiInputBase-input": {
        fontSize: 11,
      },
    },
    [theme.breakpoints.between(376, 768)]: {
      border: "none",
      borderRadius: 0,
      width: "280px",
      "& .MuiInputBase-input": {
        fontSize: 13,
      },
    },

    "& .button-date-range-picker-wrap": {
      float: "left",
      margin: "auto",
      marginLeft: "-9px",
      marginBottom: "8px",
    },
  },
}));

export const DateRangePicker: React.FC<DateRangePickerProps> = (
  props: DateRangePickerProps
) => {
  const classes = useStyles();

  return (
    <div className={classes.datePicker}>
      <Grid container>
        <Grid className={classes.gridContainer} item xs={12}>
          <KshDateRangePicker
            handleDate={props.handleDate!}
            disableFuture={props.disableFuture!}
            value={props.value!}
            enableTimePicker
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default DateRangePicker;
