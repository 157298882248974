export enum TitlesEnum {
  RETENTION_DETAIL = "Detalle de retención",
  RETENTION_DETAIL_EC = "Detalle de retención",
  INVOICE_WITH_IVA = "Detalle de factura con IVA",
  INVOICE_WITHOUT_IVA = "Detalle de factura sin IVA",
  ADITIONAL_SERVICE_THREE_DS = "Detalle de factura 3DS",
  INVOICE_DETAIL = "Detalle de factura",
  CHARGE_DETAIL = "Detalle de cobro",
  CREDIT_NOTE_DETAIL = "Detalle de Nota de Crédito",
  DEBIT_NOTE_DETAIL = "Detalle de Nota de débito",
  DISPERSION_DETAIL = "Detalle de dispersión",
  VOUCHER_DETAIL = "Detalle de boleta",
  ANNUL_INVOICE_WITH_IVA = "Detalle de factura con IVA",
  ANNUL_INVOICE_WITHOUT_IVA = "Detalle de factura sin IVA",
  RECEIVABLE_DETAIL = "Detalle de Cuenta por cobrar",
}
