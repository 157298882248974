/**
 * Catalog ElSalvador
 */
import { Category } from "../constants/MerchantInformationConstants";
import { CatalogsEnum } from "../enums/CatalogsEnum";

export const CatalogEl: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { name: "DUI", value: "0" },
    { name: "Pasaporte", value: "1" },
  ],
  [CatalogsEnum.Provinces]: [
    { name: "Ahuachapán", value: "AH" },
    { name: "Cabañas", value: "CA" },
    { name: "Chalatenango", value: "CH" },
    { name: "Cuscatlán", value: "CU" },
    { name: "La Libertad", value: "LI" },
    { name: "La Paz", value: "PA" },
    { name: "La Unión", value: "UN" },
    { name: "Morazán", value: "MO" },
    { name: "San Miguel", value: "SM" },
    { name: "San Salvador", value: "SS" },
    { name: "San Vicente", value: "SV" },
    { name: "Santa Ana", value: "SA" },
    { name: "Sonsonate", value: "SO" },
    { name: "Usulután", value: "US" },
  ],
  [CatalogsEnum.Banks]: [
    { name: "BANCO CITIBANK DE EL SALVADOR S.A.", value: "10100003" },
    { name: "BANCO AGRICOLA S.A.", value: "10100001" },
    { name: "BANCO CENTRAL DE RESERVA DE EL SALVADOR", value: "10100002" },
    { name: "BANCO DE AMERICA CENTRAL S.A.", value: "10100004" },
    { name: "BANCO HIPOTECARIO DE EL SALVADOR S.A.", value: "10100005" },
    { name: "BANCO HSBC SALVADORENO S.A.", value: "10100006" },
    { name: "BANCO MULTISECTORIAL DE INVERSIONES", value: "10100007" },
    { name: "BANCO PROCREDIT S.A.", value: "10100008" },
    { name: "SCOTIABANK EL SALVADOR S.A.", value: "10100009" },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { name: "Corriente", value: "0" },
    { name: "Ahorros", value: "1" },
  ],
};
