import React, { FC } from "react";
import { IDetailCardBodyProps } from "@components/DetailCardBody/DetailCardBody.interfaces";
import { Box, Typography } from "@mui/material";
import { TypeEnum } from "@shared/constants/AlarmConfigConstants";
import { detailCardBodyStyles as styles } from "@components/DetailCardBody/DetailCardBody.styles";
import { AlarmDetailCardEnum } from "@shared/enum/AlarmDetailCardEnum";
import { useDetailCardBody } from "@components/DetailCardBody/state/useDetailCardBody";
import NodesContainer from "@components/DetailCardBody/NodesContainer/NodesContainer";
import GeneralConditionContainer from "@components/DetailCardBody/GeneralConditionContainer/GeneralConditionContainer";
import GreyCardContainer from "@components/layout/GreyCardContainer/GreyCardContainer";
import { isEmpty } from "lodash";

const DetailCardBody: FC<IDetailCardBodyProps> = ({
  tabType,
}: IDetailCardBodyProps) => {
  const { frequencyLabel, nodes, handleRedirect, variables, mccCatalog } =
    useDetailCardBody(tabType);

  return (
    <Box sx={styles.container}>
      <GreyCardContainer title={AlarmDetailCardEnum.ALARM_INFORMATION}>
        <Box sx={styles.informationContainer}>
          <Box sx={styles.information}>
            <Typography variant={"caption"} sx={styles.textLight}>
              {AlarmDetailCardEnum.FREQUENCY}
            </Typography>
            <Typography variant={"caption"} sx={styles.textDark}>
              {frequencyLabel}
            </Typography>
          </Box>
          {tabType === TypeEnum.GENERAL && (
            <Box sx={styles.information}>
              <Typography variant={"caption"} sx={styles.textLight}>
                {AlarmDetailCardEnum.MERCHANTS}
              </Typography>
              <Typography variant={"caption"} sx={styles.textDark}>
                {AlarmDetailCardEnum.ACTIVE_MERCHANTS}
              </Typography>
            </Box>
          )}
        </Box>
      </GreyCardContainer>
      {tabType === TypeEnum.INDIVIDUAL && (
        <GreyCardContainer title={AlarmDetailCardEnum.AGGREGATED_CUSTOMERS}>
          <Box sx={styles.nodesSection}>
            {nodes.map((node) =>
              !isEmpty(node.merchants) ? (
                <NodesContainer
                  key={node.label}
                  label={node.label}
                  names={node.merchants}
                />
              ) : null
            )}
            <Box sx={styles.redirectText} onClick={handleRedirect}>
              {AlarmDetailCardEnum.VIEW_MORE}
            </Box>
          </Box>
        </GreyCardContainer>
      )}
      <GreyCardContainer title={AlarmDetailCardEnum.GENERAL_CONDITION}>
        <GeneralConditionContainer
          variables={variables}
          mccCatalog={mccCatalog}
        />
      </GreyCardContainer>
    </Box>
  );
};

export default DetailCardBody;
