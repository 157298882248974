import React from "react";
import { FormControl, Grid, Typography } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { IOptionImageSelection } from "../../shared/infrastructure/constants/RulesConstants";
import { get } from "lodash";

export interface OptionImageSelectionProps {
  items: IOptionImageSelection[];
  property: string;
  onChange: (item: MouseEvent) => void;
  value: string | undefined;
  disabled: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: "12px",
  },
  toggleButtonGroupRoot: {
    display: "inline",
  },
  toggleButtonRoot: {
    textTransform: "none",
    background: "#F7FAFC",
    height: 80,
    width: 120,
    borderRadius: "4px !important",
    border: "none",
    marginRight: 16,
    marginBottom: 16,
    color: theme.palette.text.primary,
    padding: 24,
    "&.Mui-selected": {
      border: "1px solid #0DC298",
      background: "#F7FAFC",
      color: theme.palette.text.primary,
    },
    "&.Mui-selected:hover": {
      background: "#F7FAFC",
      color: theme.palette.text.primary,
    },
  },
}));

export const OptionImageSelection: React.FC<OptionImageSelectionProps> = (
  props: OptionImageSelectionProps
) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormControl component="fieldset" fullWidth>
            <ToggleButtonGroup
              value={props.value}
              exclusive
              classes={{ root: classes.toggleButtonGroupRoot }}
              aria-label={`${props.property}`}
              onChange={(_e, value) => value !== null && props.onChange(value)}
            >
              {props.items.map((item, index: number) => (
                <ToggleButton
                  key={index}
                  style={{ textTransform: "none" }}
                  value={item.value}
                  aria-label={item.value}
                  classes={{ root: classes.toggleButtonRoot }}
                  disabled={!get(item, "valid", true) || props.disabled}
                >
                  <Grid
                    container
                    spacing={1}
                    direction={"row"}
                    justify={"center"}
                  >
                    <Grid item>
                      <img
                        src={item.image}
                        alt={"1"}
                        style={
                          !get(item, "valid", true) || props.disabled
                            ? { opacity: 0.5 }
                            : {}
                        }
                      />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.title}>
                        {item.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </FormControl>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
