import { docTypeEC } from "./FieldsBasicDataEC";

export interface ICiuuMcc {
  ciiu: string;
  mcc: string;
}

export const tipoEmpresaEC: Array<string> = [
  "Compañía en Nombre Colectivo",
  "Companía en Comandita Simple",
  "Compañía en Comandita por Acciones",
  "Compañía de Responsabilidad Limitada",
  "Sociedad Anónima",
  "Compañía de Economía Mixta (pública y privada)",
];

export const TypeOfTaxIdList = docTypeEC.map((item) => item.name);

export const CatalogEC = {
  ["CIUU"]: [
    { ciuuInt: ["1104", "1103"], id: "C110", label: "ELABORACIÓN DE BEBIDAS." },
    {
      ciuuInt: ["1391", "1392", "1393", "1394", "1399", "1399"],
      id: "C139",
      label: "FABRICACIÓN DE OTROS PRODUCTOS TEXTILES.",
    },
    {
      ciuuInt: ["1410"],
      id: "C141",
      label: "FABRICACIÓN DE PRENDAS DE VESTIR, EXCEPTO PRENDAS DE PIEL.",
    },
    {
      ciuuInt: ["1420"],
      id: "C142",
      label: "FABRICACIÓN DE ARTÍCULOS DE PIEL.",
    },
    {
      ciuuInt: ["1430"],
      id: "C143",
      label: "FABRICACIÓN DE ARTÍCULOS DE PUNTO Y GANCHILLO.",
    },
    {
      ciuuInt: ["1511"],
      id: "C151",
      label:
        "CURTIDO Y ADOBO DE CUEROS; FABRICACIÓN DE MALETAS, BOLSOS DE MANO Y ARTÍCULOS DE TALABARTERÍA Y GUARNICIONERÍA; ADOBO Y TEÑIDO DE PIELES.",
    },
    { ciuuInt: ["1520", "1521"], id: "C152", label: "FABRICACIÓN DE CALZADO." },
    {
      ciuuInt: ["1610"],
      id: "C161",
      label: "ASERRADO Y ACEPILLADURA DE MADERA.",
    },
    {
      ciuuInt: ["1621"],
      id: "C162",
      label:
        "FABRICACIÓN DE HOJAS DE MADERA PARA ENCHAPADO Y TABLEROS A BASE DE MADERA.",
    },
    {
      ciuuInt: ["1701", "1702", "1703"],
      id: "C170",
      label: "FABRICACIÓN DE PAPEL Y DE PRODUCTOS DE PAPEL.",
    },
    {
      ciuuInt: ["1811"],
      id: "C181",
      label:
        "IMPRESIÓN Y ACTIVIDADES DE SERVICIOS RELACIONADOS CON LA IMPRESIÓN.",
    },
    { ciuuInt: ["1820"], id: "C182", label: "REPRODUCCIÓN DE GRABACIONES." },
    {
      ciuuInt: ["1910"],
      id: "C191",
      label: "FABRICACIÓN DE PRODUCTOS DE HORNO DE COQUE.",
    },
    {
      ciuuInt: ["1920"],
      id: "C192",
      label: "FABRICACIÓN DE PRODUCTOS DE LA REFINACIÓN DEL PETRÓLEO.",
    },
    {
      ciuuInt: ["6201"],
      id: "C201",
      label:
        "FABRICACIÓN DE SUSTANCIAS QUÍMICAS BÁSICAS, DE ABONOS Y COMPUESTOS DE NITRÓGENO Y DE PLÁSTICOS Y CAUCHO SINTÉTICO EN FORMAS PRIMARIAS.",
    },
    {
      ciuuInt: ["6202"],
      id: "C202",
      label: "FABRICACIÓN DE OTROS PRODUCTOS QUÍMICOS.",
    },
    {
      ciuuInt: ["2030"],
      id: "C203",
      label: "FABRICACIÓN DE FIBRAS ARTIFICIALES.",
    },
    {
      ciuuInt: ["0210"],
      id: "C210",
      label:
        "FABRICACIÓN DE PRODUCTOS FARMACÉUTICOS, SUSTANCIAS QUÍMICAS MEDICINALES Y PRODUCTOS BOTÁNICOS DE USO FARMACÉUTICO.",
    },
    {
      ciuuInt: ["5221"],
      id: "C221",
      label: "FABRICACIÓN DE PRODUCTOS DE CAUCHO.",
    },
    {
      ciuuInt: ["5222"],
      id: "C222",
      label: "FABRICACIÓN DE PRODUCTOS DE PLÁSTICO.",
    },
    {
      ciuuInt: ["2310"],
      id: "C231",
      label: "FABRICACIÓN DE VIDRIO Y PRODUCTOS DE VIDRIO.",
    },
    {
      ciuuInt: ["2399"],
      id: "C239",
      label: "FABRICACIÓN DE PRODUCTOS MINERALES NO METÁLICOS N.C.P.",
    },
    {
      ciuuInt: ["2410"],
      id: "C241",
      label: "INDUSTRIAS BÁSICAS DE HIERRO Y ACERO.",
    },
    {
      ciuuInt: ["2420"],
      id: "C242",
      label:
        "FABRICACIÓN DE PRODUCTOS PRIMARIOS DE METALES PRECIOSOS Y METALES NO FERROSOS.",
    },
    { ciuuInt: ["2432", "2431"], id: "C243", label: "FUNDICIÓN DE METALES." },
    {
      ciuuInt: ["2512"],
      id: "C251",
      label:
        "FABRICACIÓN DE PRODUCTOS METÁLICOS PARA USO ESTRUCTURAL, TANQUES, DEPÓSITOS, RECIPIENTES DE METAL Y GENERADORES DE VAPOR.",
    },
    {
      ciuuInt: ["2520"],
      id: "C252",
      label: "FABRICACIÓN DE ARMAS Y MUNICIONES.",
    },
    {
      ciuuInt: ["2599"],
      id: "C259",
      label:
        "FABRICACIÓN DE OTROS PRODUCTOS ELABORADOS DE METAL, ACTIVIDADES DE TRABAJOS DE METALES.",
    },
    {
      ciuuInt: ["2610"],
      id: "C261",
      label: "FABRICACIÓN DE COMPONENTES Y TABLEROS ELECTRÓNICOS.",
    },
    {
      ciuuInt: ["2620"],
      id: "C262",
      label: "FABRICACIÓN DE ORDENADORES Y EQUIPO PERIFÉRICO.",
    },
    {
      ciuuInt: ["2630"],
      id: "C263",
      label: "FABRICACIÓN DE EQUIPO DE COMUNICACIONES.",
    },
    {
      ciuuInt: ["2640"],
      id: "C264",
      label: "FABRICACIÓN DE APARATOS ELECTRÓNICOS DE CONSUMO.",
    },
    {
      ciuuInt: ["2651"],
      id: "C265",
      label:
        "FABRICACIÓN DE EQUIPOS DE MEDICIÓN, PRUEBA, NAVEGACIÓN, CONTROL Y DE RELOJES.",
    },
    {
      ciuuInt: ["2660"],
      id: "C266",
      label:
        "FABRICACIÓN DE EQUIPO DE IRRADIACIÓN, Y EQUIPO ELECTRÓNICO DE USO MEDICO Y TERAPÉUTICO.",
    },
    {
      ciuuInt: ["2670"],
      id: "C267",
      label: "FABRICACIÓN DE INSTRUMENTOS ÓPTICOS Y EQUIPO FOTOGRÁFICOS.",
    },
    {
      ciuuInt: ["2680"],
      id: "C268",
      label: "FABRICACIÓN DE SOPORTES MAGNÉTICOS Y ÓPTICOS.",
    },
    {
      ciuuInt: ["2710"],
      id: "C271",
      label:
        "FABRICACIÓN DE MOTORES, GENERADORES, TRANSFORMADORES ELÉCTRICOS Y APARATOS DE DISTRIBUCIÓN Y CONTROL DE LA ENERGÍA ELÉCTRICA.",
    },
    {
      ciuuInt: ["2720"],
      id: "C272",
      label: "FABRICACIÓN DE PILAS, BATERÍAS Y ACUMULADORES.",
    },
    {
      ciuuInt: ["2731", "2732", "2733"],
      id: "C273",
      label: "FABRICACIÓN DE CABLES Y DISPOSITIVOS DE CABLEADO.",
    },
    {
      ciuuInt: ["2740"],
      id: "C274",
      label: "FABRICACIÓN DE EQUIPO ELÉCTRICO DE ILUMINACIÓN.",
    },
    {
      ciuuInt: ["2750"],
      id: "C275",
      label: "FABRICACIÓN DE APARATOS DE USO DOMÉSTICO.",
    },
    {
      ciuuInt: ["2790"],
      id: "C279",
      label: "FABRICACIÓN DE OTROS TIPOS DE EQUIPO ELÉCTRICO.",
    },
    {
      ciuuInt: ["2811"],
      id: "C281",
      label:
        "FABRICACIÓN DE MOTORES Y TURBINAS, EXCEPTO MOTORES PARA AERONAVES, VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS.",
    },
    {
      ciuuInt: ["2829"],
      id: "C282",
      label: "FABRICACIÓN DE MAQUINARIA DE USO ESPECIAL.",
    },
    {
      ciuuInt: ["2910"],
      id: "C291",
      label: "FABRICACIÓN DE VEHÍCULOS AUTOMOTORES.",
    },
    {
      ciuuInt: ["2920"],
      id: "C292",
      label:
        "FABRICACIÓN DE CARROCERÍAS PARA VEHÍCULOS AUTOMOTORES; FABRICACIÓN DE REMOLQUES Y SEMIRREMOLQUES.",
    },
    {
      ciuuInt: ["2930"],
      id: "C293",
      label:
        "FABRICACIÓN DE PARTES, PIEZAS Y ACCESORIOS PARA VEHÍCULOS AUTOMOTORES.",
    },
    {
      ciuuInt: ["3011"],
      id: "C301",
      label: "CONSTRUCCIÓN DE BUQUES Y OTRAS EMBARCACIONES.",
    },
    {
      ciuuInt: ["3020"],
      id: "C302",
      label: "FABRICACIÓN DE LOCOMOTORAS Y MATERIAL RODANTE.",
    },
    {
      ciuuInt: ["3030"],
      id: "C303",
      label: "FABRICACIÓN DE AERONAVES Y NAVES ESPACIALES Y MAQUINARIA CONEXA.",
    },
    {
      ciuuInt: ["3040"],
      id: "C304",
      label: "FABRICACIÓN DE VEHÍCULOS MILITARES DE COMBATE.",
    },
    {
      ciuuInt: ["3099"],
      id: "C309",
      label: "FABRICACIÓN DE EQUIPO DE TRANSPORTE N.C.P.",
    },
    { ciuuInt: ["2310"], id: "C310", label: "FABRICACIÓN DE MUEBLES." },
    {
      ciuuInt: ["3210"],
      id: "C321",
      label: "FABRICACIÓN DE JOYAS, BISUTERÍA Y ARTÍCULOS CONEXOS.",
    },
    {
      ciuuInt: ["0322"],
      id: "C322",
      label: "FABRICACIÓN DE INSTRUMENTOS MUSICALES.",
    },
    {
      ciuuInt: ["3230"],
      id: "C323",
      label: "FABRICACIÓN DE ARTÍCULOS DE DEPORTE.",
    },
    {
      ciuuInt: ["3240"],
      id: "C324",
      label: "FABRICACIÓN DE JUEGOS Y JUGUETES.",
    },
    {
      ciuuInt: ["3250"],
      id: "C325",
      label:
        "FABRICACIÓN DE INSTRUMENTOS Y MATERIALES MÉDICOS Y ODONTOLÓGICOS.",
    },
    {
      ciuuInt: ["4329"],
      id: "C329",
      label: "OTRAS INDUSTRIAS MANUFACTURERAS N.C.P.",
    },
    {
      ciuuInt: ["3311"],
      id: "C331 ",
      label:
        "REPARACIÓN DE PRODUCTOS ELABORADOS DE METAL, MAQUINARIA Y EQUIPO.",
    },
    {
      ciuuInt: ["3320"],
      id: "C332",
      label: "INSTALACIÓN DE MAQUINARIA Y EQUIPO INDUSTRIALES.",
    },
    {
      ciuuInt: ["3510", "3511"],
      id: "D351",
      label: "GENERACIÓN, TRANSMISIÓN Y DISTRIBUCIÓN DE ENERGÍA ELÉCTRICA.",
    },
    {
      ciuuInt: ["3600"],
      id: "D352",
      label:
        "FABRICACIÓN DE GAS; DISTRIBUCIÓN DE COMBUSTIBLES GASEOSOS POR TUBERÍAS.",
    },
    {
      ciuuInt: ["3530"],
      id: "D353",
      label: "SUMINISTRO DE VAPOR Y DE AIRE ACONDICIONADO.",
    },
    {
      ciuuInt: ["3600"],
      id: "E360",
      label: "CAPTACIÓN, TRATAMIENTO Y DISTRIBUCIÓN DE AGUA.",
    },
    { ciuuInt: ["3700"], id: "E370", label: "EVACUACIÓN DE AGUAS RESIDUALES." },
    {
      ciuuInt: ["3811", "3812"],
      id: "E381",
      label: "RECOLECCIÓN DE DESECHOS.",
    },
    {
      ciuuInt: ["3821-3822"],
      id: "E382",
      label: "TRATAMIENTO Y ELIMINACIÓN DE DESECHOS.",
    },
    { ciuuInt: ["3830"], id: "E383", label: "RECUPERACIÓN DE MATERIALES." },
    {
      ciuuInt: ["4390"],
      id: "E390",
      label:
        "ACTIVIDADES DE DESCONTAMINACIÓN Y OTROS SERVICIOS DE  GESTIÓN DE DESECHOS.",
    },
    { ciuuInt: ["4100"], id: "F410", label: "CONSTRUCCIÓN DE EDIFICIOS." },
    {
      ciuuInt: ["4210"],
      id: "F421",
      label: "CONSTRUCCIÓN DE CARRETERAS Y LÍNEAS DE FERROCARRIL.",
    },
    {
      ciuuInt: ["4220"],
      id: "F422",
      label: "CONSTRUCCIÓN DE PROYECTOS DE SERVICIOS PÚBLICOS.",
    },
    {
      ciuuInt: ["4290"],
      id: "F429",
      label: "CONSTRUCCIÓN DE OTRAS OBRAS DE INGENIERÍA CIVIL.",
    },
    {
      ciuuInt: ["4311"],
      id: "F431",
      label: "DEMOLICIÓN Y PREPARACIÓN DEL TERRENO.",
    },
    {
      ciuuInt: ["4321"],
      id: "F432",
      label:
        "INSTALACIONES ELÉCTRICAS Y DE FONTANERÍA Y OTRAS INSTALACIONES PARA OBRAS DE CONSTRUCCIÓN.",
    },
    {
      ciuuInt: ["4330"],
      id: "F433",
      label: "TERMINACIÓN Y ACABADO DE EDIFICIOS.",
    },
    {
      ciuuInt: ["4390"],
      id: "F439",
      label: "OTRAS ACTIVIDADES ESPECIALIZADAS DE CONSTRUCCIÓN.",
    },
    { ciuuInt: ["4510"], id: "G451", label: "VENTA DE VEHÍCULOS AUTOMOTORES." },
    {
      ciuuInt: ["4520"],
      id: "G452",
      label: "MANTENIMIENTO Y REPARACIÓN DE VEHÍCULOS AUTOMOTORES.",
    },
    {
      ciuuInt: ["4530"],
      id: "G453",
      label: "VENTA DE PARTES, PIEZAS Y ACCESORIOS PARA VEHÍCULOS AUTOMOTORES.",
    },
    {
      ciuuInt: ["4540"],
      id: "G454",
      label:
        "VENTA, MANTENIMIENTO Y REPARACIÓN DE MOTOCICLETAS Y DE SUS PARTES, PIEZAS Y ACCESORIOS.",
    },
    {
      ciuuInt: ["4610"],
      id: "G461",
      label: "VENTA AL POR MAYOR A CAMBIO DE UNA COMISIÓN O POR CONTRATO.",
    },
    {
      ciuuInt: ["4620"],
      id: "G462",
      label:
        "VENTA AL POR MAYOR DE MATERIAS PRIMAS AGROPECUARIAS Y ANIMALES VIVOS.",
    },
    {
      ciuuInt: ["4630"],
      id: "G463",
      label: "VENTA AL POR MAYOR DE ALIMENTOS, BEBIDAS Y TABACO.",
    },
    {
      ciuuInt: ["4649"],
      id: "G464",
      label: "VENTA AL POR MAYOR DE ENSERES DOMÉSTICOS.",
    },
    {
      ciuuInt: ["4653"],
      id: "G465",
      label: "VENTA AL POR MAYOR DE MAQUINARIAS EQUIPOS Y MATERIALES.",
    },
    {
      ciuuInt: ["4669", "4661", "4662", "4663"],
      id: "G466",
      label: "OTRAS ACTIVIDADES DE VENTA AL POR MAYOR ESPECIALIZADA.",
    },
    {
      ciuuInt: ["4690"],
      id: "G469",
      label: "VENTA AL POR MAYOR DE OTROS PRODUCTOS NO ESPECIALIZADOS.",
    },
    {
      ciuuInt: ["4719"],
      id: "G471",
      label: "VENTA AL POR MENOR EN COMERCIOS NO ESPECIALIZADOS.",
    },
    {
      ciuuInt: ["4723"],
      id: "G472",
      label:
        "VENTA AL POR MENOR DE ALIMENTOS, BEBIDAS Y TABACO EN COMERCIOS ESPECIALIZADOS.",
    },
    {
      ciuuInt: ["4730"],
      id: "G473",
      label:
        "VENTA AL POR MENOR DE COMBUSTIBLES PARA VEHÍCULOS AUTOMOTORES EN COMERCIOS ESPECIALIZADOS.",
    },
    {
      ciuuInt: ["4742"],
      id: "G474",
      label:
        "VENTA AL POR MENOR DE EQUIPO DE INFORMACIÓN Y DE COMUNICACIONES EN COMERCIOS ESPECIALIZADOS.",
    },
    {
      ciuuInt: ["4759"],
      id: "G475",
      label:
        "VENTA AL POR MENOR DE OTROS ENSERES DOMÉSTICOS EN COMERCIOS ESPECIALIZADOS.",
    },
    {
      ciuuInt: ["4769"],
      id: "G476",
      label:
        "VENTA AL POR MENOR DE PRODUCTOS CULTURALES Y RECREATIVOS EN COMERCIOS ESPECIALIZADOS.",
    },
    {
      ciuuInt: ["4773"],
      id: "G477",
      label:
        "VENTA AL POR MENOR DE OTROS PRODUCTOS EN COMERCIOS ESPECIALIZADOS.",
    },
    {
      ciuuInt: ["4789"],
      id: "G478",
      label: "VENTA AL POR MENOR EN PUESTOS DE VENTA Y MERCADOS.",
    },
    {
      ciuuInt: ["4799"],
      id: "G479",
      label:
        "VENTA AL POR MENOR NO REALIZADA EN COMERCIOS, PUESTOS DE VENTA O MERCADOS.",
    },
    {
      ciuuInt: ["4911", "4912"],
      id: "H491",
      label: "TRANSPORTE POR FERROCARRIL.",
    },
    {
      ciuuInt: ["4922"],
      id: "H492",
      label: "OTRAS ACTIVIDADES DE TRANSPORTE POR VÍA TERRESTRE.",
    },
    { ciuuInt: ["4930"], id: "H493", label: "TRANSPORTE POR TUBERÍAS." },
    {
      ciuuInt: ["5011", "5012"],
      id: "H501",
      label: "TRANSPORTE MARÍTIMO Y DE CABOTAJE.",
    },
    {
      ciuuInt: ["5021", "5022"],
      id: "H502",
      label: "TRANSPORTE POR VÍAS DE NAVEGACIÓN INTERIORES.",
    },
    {
      ciuuInt: ["5110"],
      id: "H511",
      label: "TRANSPORTE DE PASAJEROS POR VÍA AÉREA.",
    },
    {
      ciuuInt: ["5120"],
      id: "H512",
      label: "TRANSPORTE DE CARGA POR VÍA AÉREA.",
    },
    { ciuuInt: ["5210"], id: "H521", label: "ALMACENAMIENTO Y DEPÓSITO." },
    {
      ciuuInt: ["5229"],
      id: "H522",
      label: "ACTIVIDADES DE APOYO AL TRANSPORTE.",
    },
    {
      ciuuInt: ["5310"],
      id: "H531",
      label: "ACTIVIDADES POSTALES Y DE MENSAJERÍA.",
    },
    { ciuuInt: ["5320"], id: "H532", label: "ACTIVIDADES DE MENSAJERÍA." },
    {
      ciuuInt: ["5510"],
      id: "I551",
      label: "ACTIVIDADES DE ALOJAMIENTO PARA ESTANCIAS CORTAS.",
    },
    {
      ciuuInt: ["5520"],
      id: "I552",
      label:
        "ACTIVIDADES DE CAMPAMENTOS, PARQUES DE VEHÍCULOS DE RECREO Y PARQUES DE CARAVANAS.",
    },
    {
      ciuuInt: ["5590"],
      id: "I559",
      label: "OTRAS ACTIVIDADES DE ALOJAMIENTO.",
    },
    {
      ciuuInt: ["5610"],
      id: "I561",
      label: "ACTIVIDADES DE RESTAURANTES Y DE SERVICIO MÓVIL DE COMIDAS.",
    },
    {
      ciuuInt: ["5621"],
      id: "I562",
      label:
        "SUMINISTRO DE COMIDAS POR ENCARGO Y OTRAS ACTIVIDADES DE SERVICIO DE COMIDAS.",
    },
    {
      ciuuInt: ["5630"],
      id: "I563",
      label: "ACTIVIDADES DE SERVICIO DE BEBIDAS.",
    },
    {
      ciuuInt: ["5811", "5812", "5813", "5819"],
      id: "J581",
      label:
        "PUBLICACIÓN DE LIBROS, PERIÓDICOS Y OTRAS ACTIVIDADES DE PUBLICACIÓN.",
    },
    {
      ciuuInt: ["5820"],
      id: "J582",
      label: "PUBLICACIÓN DE PROGRAMAS INFORMÁTICOS.",
    },
    {
      ciuuInt: ["5911"],
      id: "J591",
      label:
        "ACTIVIDADES DE PRODUCCIÓN DE PELÍCULAS CINEMATOGRÁFICAS, VÍDEOS Y PROGRAMAS DE TELEVISIÓN.",
    },
    {
      ciuuInt: ["2592"],
      id: "J592",
      label: "ACTIVIDADES DE GRABACIÓN DE SONIDO Y EDICIÓN DE MÚSICA.",
    },
    { ciuuInt: ["6010"], id: "J601", label: "TRANSMISIONES DE RADIO." },
    {
      ciuuInt: ["6020"],
      id: "J602",
      label: "PROGRAMACIÓN Y TRANSMISIONES DE TELEVISIÓN.",
    },
    {
      ciuuInt: ["6110"],
      id: "J611",
      label: "ACTIVIDADES DE TELECOMUNICACIONES ALÁMBRICA.",
    },
    {
      ciuuInt: ["6120"],
      id: "J612",
      label: "ACTIVIDADES DE TELECOMUNICACIONES INALÁMBRICAS.",
    },
    {
      ciuuInt: ["6130"],
      id: "J613",
      label: "ACTIVIDADES DE TELECOMUNICACIONES POR SATÉLITE.",
    },
    {
      ciuuInt: ["6190"],
      id: "J619",
      label: "OTRAS ACTIVIDADES DE TELECOMUNICACIONES.",
    },
    {
      ciuuInt: ["6209"],
      id: "J620",
      label:
        "ACTIVIDADES DE PROGRAMACIÓN INFORMÁTICA Y DE CONSULTORÍA DE INFORMÁTICA Y ACTIVIDADES CONEXAS.",
    },
    {
      ciuuInt: ["6311"],
      id: "J631",
      label:
        "PROCESAMIENTO DE DATOS, HOSPEDAJE Y ACTIVIDADES CONEXAS; PORTALES WEB.",
    },
    {
      ciuuInt: ["6399"],
      id: "J639",
      label: "OTRAS ACTIVIDADES DE SERVICIOS DE INFORMACIÓN.",
    },
    { ciuuInt: ["6419"], id: "K641", label: "INTERMEDIACIÓN MONETARIA." },
    {
      ciuuInt: ["6420"],
      id: "K642",
      label: "ACTIVIDADES DE SOCIEDADES DE CARTERA.",
    },
    {
      ciuuInt: ["6430", "6431"],
      id: "K643",
      label:
        "FONDOS Y SOCIEDADES DE INVERSIÓN Y ENTIDADES FINANCIERAS SIMILARES.",
    },
    {
      ciuuInt: ["6499"],
      id: "K649",
      label:
        "OTRAS ACTIVIDADES DE SERVICIOS FINANCIEROS, EXCEPTO LAS DE SEGUROS Y FONDOS DE PENSIONES.",
    },
    { ciuuInt: ["6511", "6512"], id: "K651", label: "SEGUROS." },
    { ciuuInt: ["6520"], id: "K652", label: "REASEGUROS." },
    { ciuuInt: ["6530"], id: "K653", label: "FONDOS DE PENSIÓN." },
    {
      ciuuInt: ["6619"],
      id: "K661",
      label:
        "ACTIVIDADES AUXILIARES DE LAS ACTIVIDADES DE SERVICIOS FINANCIEROS, EXCEPTO LAS DE SEGUROS Y FONDOS DE PENSIONES.",
    },
    {
      ciuuInt: ["6629"],
      id: "K662",
      label: "ACTIVIDADES AUXILIARES DE SEGUROS Y FONDOS DE PENSIONES.",
    },
    {
      ciuuInt: ["6630"],
      id: "K663",
      label: "ACTIVIDADES DE GESTIÓN DE FONDOS.",
    },
    {
      ciuuInt: ["6810"],
      id: "L681",
      label:
        "ACTIVIDADES INMOBILIARIAS REALIZADAS CON BIENES PROPIOS O ARRENDADOS.",
    },
    {
      ciuuInt: ["6820"],
      id: "L682",
      label:
        "ACTIVIDADES INMOBILIARIAS REALIZADAS A CAMBIO DE UNA RETRIBUCIÓN O POR CONTRATO.",
    },
    { ciuuInt: ["6910"], id: "M691", label: "ACTIVIDADES JURÍDICAS." },
    {
      ciuuInt: ["6920"],
      id: "M692",
      label:
        "ACTIVIDADES DE CONTABILIDAD, TENEDURÍA DE LIBROS Y AUDITORIA; CONSULTORÍA FISCAL.",
    },
    {
      ciuuInt: ["7010"],
      id: "M701",
      label: "ACTIVIDADES DE OFICINAS PRINCIPALES.",
    },
    {
      ciuuInt: ["7020"],
      id: "M702",
      label: "ACTIVIDADES DE CONSULTORÍA DE GESTIÓN.",
    },
    {
      ciuuInt: ["7110"],
      id: "M711",
      label:
        "ACTIVIDADES DE ARQUITECTURA E INGENIERÍA Y ACTIVIDADES CONEXAS DE CONSULTORÍA TÉCNICA.",
    },
    { ciuuInt: ["7120"], id: "M712", label: "ENSAYOS Y ANÁLISIS TÉCNICOS." },
    {
      ciuuInt: ["7210"],
      id: "M721",
      label:
        "INVESTIGACIONES Y DESARROLLO EXPERIMENTAL EN EL CAMPO DE LAS CIENCIAS NATURALES Y LA INGENIERÍA.",
    },
    {
      ciuuInt: ["7220"],
      id: "M722",
      label:
        "INVESTIGACIONES Y DESARROLLO EXPERIMENTAL EN EL CAMPO DE LAS CIENCIAS SOCIALES Y LAS HUMANIDADES.",
    },
    { ciuuInt: ["7310"], id: "M731", label: "PUBLICIDAD." },
    {
      ciuuInt: ["7320"],
      id: "M732",
      label: "ESTUDIOS DE MERCADO Y ENCUESTAS DE OPINIÓN PÚBLICA.",
    },
    {
      ciuuInt: ["7410"],
      id: "M741",
      label: "ACTIVIDADES ESPECIALIZADAS DE DISEÑO.",
    },
    { ciuuInt: ["7420"], id: "M742", label: "ACTIVIDADES DE FOTOGRAFÍA." },
    {
      ciuuInt: ["7490"],
      id: "M749",
      label: "OTRAS ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS N.C.P.",
    },
    { ciuuInt: ["7500"], id: "M750", label: "ACTIVIDADES VETERINARIAS." },
    {
      ciuuInt: ["7710"],
      id: "N771",
      label: "ALQUILER DE VEHÍCULOS AUTOMOTORES.",
    },
    {
      ciuuInt: ["7729"],
      id: "N772",
      label: "ALQUILER DE EFECTOS PERSONALES Y ARTÍCULOS DE USO DOMÉSTICO.",
    },
    {
      ciuuInt: ["7730"],
      id: "N773",
      label:
        "ALQUILER DE OTROS TIPOS DE MAQUINARIA, EQUIPO Y BIENES TANGIBLES.",
    },
    {
      ciuuInt: ["7740"],
      id: "N774",
      label:
        "ARRENDAMIENTO DE PROPIEDAD INTELECTUAL Y PRODUCTOS SIMILARES, EXCEPTO OBRAS PROTEGIDAS POR DERECHOS DE AUTOR.",
    },
    {
      ciuuInt: ["7810"],
      id: "N781",
      label: "ACTIVIDADES DE AGENCIAS DE EMPLEO.",
    },
    {
      ciuuInt: ["7820"],
      id: "N782",
      label: "ACTIVIDADES DE AGENCIAS DE EMPLEO TEMPORAL.",
    },
    {
      ciuuInt: ["7830"],
      id: "N783",
      label: "OTRAS ACTIVIDADES DE DOTACIÓN DE RECURSOS HUMANOS.",
    },
    {
      ciuuInt: ["7912", "7911"],
      id: "N791",
      label: "ACTIVIDADES DE AGENCIAS DE VIAJES Y OPERADORES TURÍSTICOS.",
    },
    {
      ciuuInt: ["7990"],
      id: "N799",
      label: "OTROS SERVICIOS DE RESERVAS Y ACTIVIDADES CONEXAS.",
    },
    {
      ciuuInt: ["8010"],
      id: "N801",
      label: "ACTIVIDADES DE SEGURIDAD PRIVADA.",
    },
    {
      ciuuInt: ["8020"],
      id: "N802",
      label: "ACTIVIDADES DE SERVICIOS DE SISTEMAS DE SEGURIDAD.",
    },
    { ciuuInt: ["8030"], id: "N803", label: "ACTIVIDADES DE INVESTIGACIÓN." },
    {
      ciuuInt: ["8110"],
      id: "N811",
      label: "ACTIVIDADES COMBINADAS DE APOYO A INSTALACIONES.",
    },
    { ciuuInt: ["8129"], id: "N812", label: "ACTIVIDADES DE LIMPIEZA." },
    {
      ciuuInt: ["8130"],
      id: "N813",
      label: "ACTIVIDADES DE PAISAJISMO Y SERVICIOS DE MANTENIMIENTO CONEXOS.",
    },
    {
      ciuuInt: ["8219"],
      id: "N821",
      label: "ACTIVIDADES ADMINISTRATIVAS Y DE APOYO DE OFICINA.",
    },
    {
      ciuuInt: ["8220"],
      id: "N822",
      label: "ACTIVIDADES DE CENTROS DE LLAMADAS.",
    },
    {
      ciuuInt: ["8230"],
      id: "N823",
      label: "ORGANIZACIÓN DE CONVENCIONES Y EXPOSICIONES COMERCIALES.",
    },
    {
      ciuuInt: ["8299"],
      id: "N829",
      label: "ACTIVIDADES DE SERVICIOS DE APOYO A LAS EMPRESAS N.C.P.",
    },
    {
      ciuuInt: ["8411"],
      id: "O841",
      label:
        "ADMINISTRACIÓN DEL ESTADO Y APLICACIÓN DE LA POLÍTICA ECONÓMICA Y SOCIAL DE LA COMUNIDAD.",
    },
    {
      ciuuInt: ["8423"],
      id: "O842",
      label: "PRESTACIÓN DE SERVICIOS A LA COMUNIDAD EN GENERAL.",
    },
    {
      ciuuInt: ["8430"],
      id: "O843",
      label:
        "ACTIVIDADES DE PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA.",
    },
    {
      ciuuInt: ["8510"],
      id: "P851",
      label: "ENSEÑANZA PREPRIMARIA Y PRIMARIA.",
    },
    { ciuuInt: ["8521", "8522"], id: "P852", label: "ENSEÑANZA SECUNDARIA." },
    { ciuuInt: ["8530"], id: "P853", label: "ENSEÑANZA SUPERIOR." },
    { ciuuInt: ["8549"], id: "P854", label: "OTROS TIPOS DE ENSEÑANZA." },
  ],
  ["MCC"]: [
    { id: "5933", label: "Casas de empeño" },
    { id: "7276", label: "Servicios Aduaneros" },
    { id: "0742", label: "Veterinarias" },
    { id: "0763", label: "Cooperativas agrícolas" },
    { id: "0780", label: "Cooperativas agrícolas" },
    { id: "0780", label: "Servicios de jardinería y horticultura" },
    { id: "1520", label: "Construcción de edificios y obras civiles" },
    {
      id: "1711",
      label: "Talleres de reparación de aire acondicionado y refrigeración",
    },
    { id: "1750", label: "Contratistas de carpintería" },
    {
      id: "1799",
      label:
        "Contratistas comerciales especiales (no clasificados bajo otros epígrafes)",
    },
    {
      id: "1799",
      label: "Contratistas de calefacción, plomería y aire acondicionado ",
    },
    { id: "2741", label: "Publicaciones e impresiones diversas" },
    { id: "2741", label: "Publicación e impresión diversas" },
    { id: "2842", label: "Preparaciones especiales de limpieza" },
    {
      id: "2842",
      label: "Servicios públicos: electricidad, gas, agua y sanitarios",
    },
    {
      id: "4111",
      label:
        "Transporte de pasajeros de cercanías locales y suburbanos, incluidos ferries",
    },
    { id: "4111", label: "Ferrocarriles" },
    {
      id: "4111",
      label:
        "Tiendas de alimentos misceláneas: tiendas de conveniencia y mercados especializados",
    },
    {
      id: "4214",
      label:
        "Autotransportistas y camiones: locales y de larga distancia, empresas de mudanzas y almacenamiento, y servicios de entrega local",
    },
    {
      id: "4215",
      label:
        "Aerolíneas y transportistas aéreos (no clasificados en otra parte)",
    },
    {
      id: "4215",
      label: "Servicios de mensajería - Aéreos, terrestres y transitarios.",
    },
    {
      id: "4215",
      label:
        "Autotransportistas y camiones: locales y de larga distancia, empresas de mudanzas y almacenamiento, y servicios de entrega local",
    },
    {
      id: "4411",
      label: "Puertos deportivos, servicio marítimo y suministros",
    },
    { id: "4411", label: "Buques de vapor y cruceros" },
    { id: "4468", label: "Buques de vapor y cruceros" },
    { id: "4491", label: "Contratistas de carpintería" },
    {
      id: "4511",
      label:
        "Aerolíneas y transportistas aéreos (no clasificados en otra parte)",
    },
    { id: "4511", label: "Aerolineas" },
    { id: "4722", label: "Agencias de viajes y operadores turísticos" },
    { id: "4812", label: "Venta de equipos de telecomunicaciones y telefonía" },
    {
      id: "4814",
      label:
        "Servicios de telecomunicaciones, incluidas llamadas locales y de larga distancia, llamadas con tarjeta de crédito, llamadas mediante el uso de teléfonos con lectura de banda magnética y servicio de fax",
    },
    { id: "4816", label: "Servicios de información de redes informáticas" },
    { id: "4829", label: "Transferencia de dinero" },
    {
      id: "4899",
      label:
        "Cable, satélite y otros servicios de televisión / radio / transmisión de pago",
    },
    {
      id: "4900",
      label: "Servicios públicos: electricidad, gas, agua y sanitarios",
    },
    {
      id: "5013",
      label:
        "Suministros de vehículos de motor y piezas nuevas - Motor Vehicle Supplies and New Parts",
    },
    { id: "5013", label: "Suministros de vehículos de motor y piezas nuevas" },
    {
      id: "5013",
      label:
        "Concesionarios de automóviles y camiones (nuevos y usados) Ventas, servicio, reparaciones, repuestos y arrendamiento",
    },
    {
      id: "5021",
      label:
        "Mobiliario de oficina y comercial - Office and Commercial Furniture",
    },
    {
      id: "5039",
      label: "Materiales de construcción (no clasificados en otra parte)",
    },
    {
      id: "5039",
      label: "Materiales de construcción (no clasificados en otra parte) ",
    },
    {
      id: "5044",
      label: "Photographic, Photocopy, Microfilm Equipment and Supplies",
    },
    {
      id: "5044",
      label: "Equipo y suministros fotográficos, de fotocopias y microfilmes",
    },
    {
      id: "5045",
      label:
        "Computadoras y equipos y software periféricos de computadora - Computers and Computer Peripheral Equipment and Software",
    },
    {
      id: "5047",
      label:
        "Equipos y suministros médicos, dentales, oftálmicos y hospitalarios Medical, Dental, Ophthalmic and Hospital Equipment and Supplies",
    },
    { id: "5058", label: "Suministros industriales" },
    { id: "5065", label: "Piezas y equipos eléctricos" },
    { id: "5072", label: "Hardware, equipo y suministros" },
    { id: "5085", label: "Suministros industriales" },
    { id: "5085", label: "Piezas y equipos eléctricos" },
    { id: "5099", label: "Equipos y suministros de fontanería y calefacción" },
    { id: "5099", label: "Tiendas de ropa para deportes y equitación" },
    {
      id: "5122",
      label:
        "(Drogas, propiedad de medicamentos, artículos diversos de boticario)",
    },
    { id: "5139", label: "Calzado comercial ........" },
    {
      id: "5169",
      label: "Químicos y productos afines (no clasificados en otra parte)",
    },
    {
      id: "5169",
      label: "Químicos y productos afines (no clasificados en otra parte",
    },
    { id: "5172", label: "Petróleo y productos derivados del petróleo" },
    { id: "5192", label: "Distribuidores de noticias y quioscos" },
    {
      id: "5193",
      label: "Suministros de floristería, material de vivero y flores",
    },
    {
      id: "5199",
      label: "Bienes no duraderos (no clasificados en otra parte)",
    },
    { id: "5200", label: "Almacén de suministros para el hogar" },
    { id: "5211", label: "Tiendas de madera y materiales de construcción" },
    { id: "5231", label: "Tiendas de vidrio" },
    { id: "5251", label: "Ferreterías" },
    {
      id: "5261",
      label: "Viveros y tiendas de suministros para césped y jardín",
    },
    { id: "5311", label: "Grandes almacenes" },
    {
      id: "5311",
      label:
        "Tiendas de mascotas, Tiendas de alimentos y suministros para mascotas",
    },
    {
      id: "5361",
      label: "Tiendas especializadas y de accesorios para mujeres",
    },
    {
      id: "5422",
      label: "Suministradores de carne para congeladores y casilleros",
    },
    { id: "5451", label: "Tiendas de productos lácteos" },
    { id: "5462", label: "Tiendas de abarrotes y supermercados" },
    {
      id: "5499",
      label:
        "Tiendas de alimentos misceláneas: tiendas de conveniencia y mercados especializados",
    },
    {
      id: "5511",
      label:
        "Suministros de vehículos de motor y piezas nuevas - Motor Vehicle Supplies and New Parts",
    },
    {
      id: "5511",
      label:
        "Concesionarios de automóviles y camiones (nuevos y usados) Ventas, servicio, reparaciones, repuestos y arrendamiento",
    },
    {
      id: "5571",
      label:
        "Tiendas y concesionarios de motocicletas - Motorcycle Shops and Dealers",
    },
    {
      id: "5599",
      label:
        "Distribuidores diversos de maquinaria automotriz, aeronáutica y agrícola (no clasificados en otra parte)",
    },
    {
      id: "5599",
      label:
        "Suministros de vehículos de motor y piezas nuevas - Motor Vehicle Supplies and New Parts",
    },
    { id: "5599", label: "Venta de equipos de telecomunicaciones y telefonía" },
    { id: "5651", label: "Tiendas de ropa familiar" },
    { id: "5651", label: "Tiendas diversas de ropa y accesorios" },
    { id: "5661", label: "Shoe Stores " },
    { id: "5681", label: "Peleteros y peleterías" },
    { id: "5691", label: "Tiendas de ropa para hombres y mujeres" },
    { id: "5699", label: "Tiendas diversas de ropa y accesorios" },
    { id: "5699", label: "Tiendas de ropa para hombres y mujeres" },
    { id: "5699", label: "Tiendas de cigarros y puestos" },
    {
      id: "5712",
      label:
        "Tiendas de muebles, muebles para el hogar y equipos, excepto electrodomésticos",
    },
    { id: "5713", label: "Tiendas de revestimientos de suelos" },
    { id: "5719", label: "Hogar misceláneo - Miscellaneous Home Furnishing" },
    { id: "5719", label: "Grandes almacenes" },
    {
      id: "5722",
      label: "Tiendas especializadas de decoración del hogar misceláneas",
    },
    { id: "5732", label: "Tiendas de equipos electricos" },
    {
      id: "5733",
      label:
        "Tiendas de música: instrumentos musicales, pianos y partituras Music Stores ",
    },
    { id: "5735", label: "Tiendas de discos" },
    { id: "5811", label: "Lugares para comer y restaurantes" },
    { id: "5811", label: "Catering" },
    { id: "5812", label: "Fast Food Restaurants" },
    { id: "5812", label: "Restaurantes de comida rápida" },
    { id: "5812", label: "Lugares para comer y restaurantes" },
    {
      id: "5813",
      label:
        "Lugares para beber (bebidas alcohólicas): bares, tabernas, clubes nocturnos, salones de cócteles y discotecas",
    },
    { id: "5814", label: "Lugares para comer y restaurantes" },
    { id: "5814", label: "Fast Food Restaurants" },
    { id: "5814", label: "Dairy Products Stores" },
    { id: "5816", label: "Bienes digitales - Juegos" },
    { id: "5912", label: "Droguerías y farmacias" },
    { id: "5921", label: "Tiendas de paquetes: cerveza" },
    { id: "5931", label: "Mercancía usada y tiendas de segunda mano" },
    { id: "5941", label: "Tiendas de artículos deportivos" },
    { id: "5941", label: "Tiendas de armas" },
    { id: "5942", label: "Librerias" },
    {
      id: "5943",
      label: "Papelerías, Tiendas de útiles escolares y de oficina",
    },
    { id: "5944", label: "joyerías, relojería, relojería y platería " },
    {
      id: "5945",
      label:
        "Clubes de membresía (deportes, Recreación, Atlético), Clubes de campo, y campos de golf privados",
    },
    {
      id: "5945",
      label:
        "Tiendas de pasatiempos, juguetes y juegos Hobby, Toy, and Game Shops",
    },
    { id: "5947", label: "Tiendas de regalos" },
    {
      id: "5947",
      label: "Tiendas de regalos, tarjetas, novedades y souvenirs",
    },
    { id: "5948", label: "Tiendas de equipaje y marroquinería" },
    { id: "5948", label: "Tiendas de equipaje y marroquinería " },
    { id: "5950", label: "Cristalería / Tiendas de cristal " },
    { id: "5962", label: "Servicios de organización relacionados con viajes" },
    { id: "5963", label: "Ventas puerta a puerta" },
    {
      id: "5966",
      label: "Marketing directo - Merchan de telemarketing saliente",
    },
    {
      id: "5967",
      label: "Marketing directo: comerciante de teleservicios entrantes",
    },
    { id: "5967", label: "Servicios de jardinería y horticultura" },
    {
      id: "5967",
      label:
        "Contenido para adultos / pornografía infantil / bestialidad, violación, mutilación, zoofilia y reconstrucción de un crimen.",
    },
    { id: "5971", label: "Galerías y marchantes de arte" },
    { id: "5972", label: "Tiendas de sellos y monedas" },
    { id: "5993", label: "Tiendas de cigarros y puestos" },
    {
      id: "5994",
      label:
        "Servicios de programación informática, procesamiento de datos y diseño de sistemas integrados",
    },
    { id: "5995", label: "Tiendas de mascotas" },
    {
      id: "5995",
      label: "Marketing directo: comerciante de teleservicios entrantes",
    },
    {
      id: "5999",
      label:
        "Distribuidores diversos de maquinaria automotriz, aeronáutica y agrícola (no clasificados en otra parte)",
    },
    { id: "5999", label: "Tiendas minoristas diversas y especializadas" },
    {
      id: "6010",
      label: "Instituciones financieras: desembolsos de efectivo manuales",
    },
    {
      id: "6012",
      label: "Instituciones financieras: desembolsos de efectivo automatizados",
    },
    {
      id: "6012",
      label:
        "Instituciones financieras: mercadería, servicios y pago de deudas",
    },
    {
      id: "6051",
      label:
        "Instituciones no financieras - Moneda extranjera, no fiat   Moneda (por ejemplo: criptomoneda), giros postales (no   Transferencia de dinero), Financiamiento de la cuenta (no carga de valor almacenado), Cheques de viajero y Pago de deudas",
    },
    {
      id: "6051",
      label:
        "Instituciones no financieras - Moneda extranjera, no fiat  Moneda (por ejemplo: criptomoneda), giros postales (no  Transferencia de dinero), Financiamiento de la cuenta (no carga de valor almacenado), Cheques de viajero y Pago de deudas",
    },
    {
      id: "6051",
      label:
        "Instituciones no financieras - Moneda extranjera, no fiat Moneda (por ejemplo: criptomoneda), giros postales (no Transferencia de dinero), Financiamiento de la cuenta (no carga de valor almacenado), Cheques de viajero y Pago de deudas",
    },
    {
      id: "6051",
      label:
        "Instituciones no financieras - Moneda extranjera, no fiat  Moneda (por ejemplo: criptomoneda), giros postales (no   Transferencia de dinero), Financiamiento de la cuenta (no carga de valor almacenado), Cheques de viajero y Pago de deudas",
    },
    { id: "6211", label: "Corredores / distribuidores de seguridad (" },
    { id: "6211", label: "Transferencia de dinero" },
    { id: "6300", label: "Ventas, suscripción y primas de seguros" },
    { id: "6513", label: "Real Estate Agents and Managers" },
    {
      id: "6540",
      label: "Tarjetas de servicios, crédito o prepagadas no financieras",
    },
    {
      id: "7011",
      label:
        "Alojamiento: hoteles, moteles, centros turísticos, sistemas centrales de reserva (no clasificados en otra parte)",
    },
    { id: "7012", label: "Tiempos Compartidos" },
    { id: "7032", label: "Campamentos deportivos y recreativos" },
    { id: "7210", label: "Servicios de lavandería, limpieza y confección" },
    { id: "7221", label: "Estudios Fotográficos" },
    { id: "7261", label: "Servicios Funerarios" },
    { id: "7273", label: "Servicios de citas" },
    {
      id: "7277",
      label: "Servicios de asesoramiento: deudas, matrimoniales y personales",
    },
    { id: "7298", label: "Spas de salud y belleza" },
    {
      id: "7299",
      label: "Servicios: filtración de agua, purificación, ablandamiento",
    },
    { id: "7311", label: "Publicidad" },
    { id: "7333", label: "Fotografía comercial, arte y gráficos" },
    { id: "7342", label: "Servicios de exterminio y desinfección" },
    { id: "7349", label: "Servicios de limpieza, mantenimiento y limpieza" },
    { id: "7361", label: "Agencias de empleo y servicios de ayuda temporal" },
    {
      id: "7372",
      label:
        "Servicios de programación informática, procesamiento de datos y diseño de sistemas integrados",
    },
    { id: "7372", label: "Publicación e impresión diversas" },
    {
      id: "7372",
      label:
        "Servicios, reparación y mantenimiento de computadoras (no clasificados en otra parte)",
    },
    { id: "7375", label: "Servicios de recuperación de información" },
    {
      id: "7379",
      label:
        "Servicios, reparación y mantenimiento de computadoras (no clasificados en otra parte)",
    },
    {
      id: "7392",
      label: "Servicios de gestión, consultoría y relaciones públicas",
    },
    {
      id: "7393",
      label:
        "Agencias de detectives, servicios de protección y servicios de seguridad, incluidos vehículos blindados y perros guardianes",
    },
    {
      id: "7394",
      label:
        "Alquiler y arrendamiento de equipos, herramientas, muebles y electrodomésticos",
    },
    {
      id: "7399",
      label: "Servicios comerciales (no clasificados en otra parte)",
    },
    {
      id: "7534",
      label:
        "Talleres de reparación y recauchutado de neumáticos - Tire Retreading and Repair Shops",
    },
    { id: "7534", label: "Talleres de reparación de electrónica" },
    { id: "7538", label: "Talleres de servicio automotriz (no distribuidor)" },
    {
      id: "7623",
      label: "Contratistas de calefacción, plomería y aire acondicionado ",
    },
    {
      id: "7623",
      label: "Talleres de reparación de aire acondicionado y refrigeración",
    },
    {
      id: "7629",
      label:
        "Talleres de reparación de electrodomésticos pequeños y eléctricos",
    },
    { id: "7631", label: "Reparación de relojería" },
    { id: "7631", label: "Reparación de relojería, relojería y joyería" },
    {
      id: "7699",
      label: "Talleres de reparación diversos y servicios relacionados",
    },
    { id: "780", label: "Servicios de limpieza, mantenimiento y limpieza" },
    {
      id: "7800",
      label: "Juegos de apuestas: loterías propiedad del gobierno",
    },
    {
      id: "7800",
      label:
        "Carreras de caballos / perros con licencia del gobierno (solo en la región de EE. UU.)",
    },
    {
      id: "7800",
      label: "Loterías propiedad del gobierno (región fuera de EE. UU.)",
    },
    {
      id: "7801",
      label:
        "Casinos en línea con licencia del gobierno (juegos de apuestas en línea)",
    },
    {
      id: "7802",
      label:
        "Carreras de caballos / perros con licencia del gobierno (solo en la región de EE. UU.)",
    },
    {
      id: "7829",
      label: "Producción y distribución de películas y cintas de video",
    },
    { id: "7832", label: "Teatros cinematográficos" },
    { id: "7841", label: "Tiendas de alquiler de cintas de vídeo / DVD" },
    { id: "7941", label: "Tiendas de artículos deportivos" },
    {
      id: "7991",
      label:
        "Servicios de telecomunicaciones, incluidas llamadas locales y de larga distancia, llamadas con tarjeta de crédito, llamadas mediante el uso de teléfonos con lectura de banda magnética y servicio de fax",
    },
    { id: "7991", label: "Atracciones turísticas y exhibiciones" },
    { id: "7993", label: "Suministros de videojuegos de entretenimiento" },
    { id: "7994", label: "Video juegos operados por maquinas traga monedas" },
    {
      id: "7995",
      label:
        "Apuestas, incluidos billetes de lotería, fichas de juegos de casino, apuestas fuera de pista, apuestas en hipódromos y juegos de azar para ganar premios de valor monetario",
    },
    {
      id: "7996",
      label: "Parques de atracciones, circos, carnavales y adivinos",
    },
    {
      id: "7997",
      label:
        "Clubes de membresía (deportes, recreación, atletismo), clubes de campo y campos de golf privados",
    },
    {
      id: "7998",
      label: "Acuarios, acuarios marinos, delfinarios y zoológicos",
    },
    {
      id: "7999",
      label: "Servicios de recreación (no clasificados en otra parte)",
    },
    { id: "8011", label: "Dentistas y ortodoncistas" },
    { id: "8050", label: "Centros de enfermería y cuidado personal" },
    { id: "8062", label: "Hospitales" },
    {
      id: "8062",
      label: "Médicos y médicos (no clasificados bajo otros epígrafes)",
    },
    { id: "8111", label: "Servicios legales y abogados" },
    { id: "8211", label: "Escuelas primarias y secundarias" },
    {
      id: "8220",
      label:
        "Colegios, universidades, escuelas profesionales y colegios universitarios",
    },
    { id: "8244", label: "Escuelas de negocios y secretariado" },
    {
      id: "8299",
      label: "Escuelas y servicios educativos (no clasificados en otra parte)",
    },
    {
      id: "8398",
      label: "Servicios de gestión, consultoría y relaciones públicas",
    },
    { id: "8398", label: "Organizaciones benéficas de servicios sociales" },
    { id: "8651", label: "Orgamizaciones Politicas" },
    { id: "8661", label: "Contratistas de carpintería" },
    { id: "8661", label: "Organizaciones Religiosas" },
    { id: "8699", label: "Otras organizaciones" },
    { id: "8911", label: "Architectural, Engineering, and Surveying Services" },
    { id: "8931", label: "Servicios contables de cobranza o calificación " },
    {
      id: "8999",
      label: "Servicios profesionales (no clasificados en otra parte)",
    },
    {
      id: "9399",
      label: "Servicios gubernamentales (no clasificados en otra parte)",
    },
    { id: "0000", label: "NO APLICA A CE" },
  ],
};

export enum YES_NO_VALUES {
  YES = "si",
  NO = "no",
}
