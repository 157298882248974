import { AxiosResponse } from "axios";
import { environment } from "../environments/environment";
import { ActionTypes } from "./actionTypes";
import { IAppState, IDeferredState } from "./reducer";
import { INotification } from "../shared/infrastructure/interfaces/INotification";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { MerchantResponse } from "../../types/merchant_response";
import { auth } from "../shared/auth";
import { get, isEmpty } from "lodash";
import { UpdateDeferredRequest } from "../components/EditConfigDeferred/useEditConfigDeferredState/useEditConfigDeferredState";
import axios from "../shared/axios-util";
import { DeferredOption } from "../../types/deferred_options";
import { StatusEnum } from "../shared/infrastructure/constants/statusEnum";
import {
  ERROR_UPDATE_DEFERRED_MESSAGE,
  STATUS_DISABLED_CHANGE_SUCCESS_MESSAGE,
  STATUS_ENABLED_CHANGE_SUCCESS_MESSAGE,
  UPDATE_DEFERRED_CENTRAL_AMERICA,
  UPDATE_DEFERRED_FAIL_MESSAGE,
  UPDATE_DEFERRED_SUCCESS_BRAZIL,
  UPDATE_DEFERRED_SUCCESS_MESSAGE,
} from "../shared/infrastructure/constants/CreateDeferredConstants";
import { UpdateDeferredMerchantRequest } from "../../types/update_deferred_merchant_request";
import { CountriesEnum } from "../shared/infrastructure/constants/CountriesEnum";
import { GetNodeInfoResponse } from "../../types/get_node_info_response";
import { GetNodeInfoRequest } from "../../types/get_node_info_request";

export type IAppAction = { type: string } & Partial<IAppState>;

export const setNotification = (payload: INotification) => ({
  notification: payload,
  type: ActionTypes.SET_NOTIFICATION,
});

export const showLoading = (): IAppAction => ({
  loading: true,
  type: ActionTypes.SHOW_LOADING,
});

export const hideLoading = (): IAppAction => ({
  loading: false,
  type: ActionTypes.HIDE_LOADING,
});
export const setMerchant = (payload: MerchantResponse): IAppAction => {
  return {
    merchant: payload,
    type: ActionTypes.SET_MERCHANT,
  };
};

export const setIsDeferredLoaded = (payload: boolean): IAppAction => {
  return {
    isDeferredLoaded: payload,
    type: ActionTypes.SET_IS_DEFERRED_LOADED,
  };
};

export const setErrorChangeStatusDeferred = (errorChangeStatus: {
  id: string;
  status: boolean;
}): IAppAction => ({
  errorChangeStatus,
  type: ActionTypes.SET_ERROR_CHANGE_STATUS,
});

export const setCanceledChangeStatus = (canceledChangeStatus: {
  id: string;
  status: boolean;
}): IAppAction => ({
  canceledChangeStatus,
  type: ActionTypes.SET_CANCELED_CHANGE_STATUS,
});

export const setDeferred = (payload: DeferredOption[]): IAppAction => {
  return {
    merchantDeferred: payload,
    type: ActionTypes.SET_MERCHANT_DEFERRED,
  };
};

export const setMerchantDeferred = (payload: DeferredOption[]): IAppAction => {
  return {
    merchantDeferredOriginal: payload,
    type: ActionTypes.SET_MERCHANT_ORIGINAL_DEFERRED,
  };
};
export const setNodeInfo = (payload: GetNodeInfoResponse): IAppAction => {
  return {
    nodeInfoMerchant: payload,
    type: ActionTypes.SET_NODE_INFO,
  };
};
export const getAuthMerchant = (): ThunkAction<
  void,
  IAppState,
  undefined,
  IAppAction
> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    const authMerchant: MerchantResponse = auth.getAuthMerchant();

    !isEmpty(authMerchant) && dispatch(setMerchant(authMerchant));
  };
};

export const updateDeferred =
  (
    request: UpdateDeferredRequest
  ): ThunkAction<void, IAppState, any, IAppAction> =>
  async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    try {
      dispatch(showLoading());
      const url = `${environment.kushkiUrl}/deferred/v1/admin/deferred/merchant/${request.merchantId}/${request.id}`;

      await axios.post(url, {
        merchantMonths: request.merchantMonths,
        merchantStatus: request.merchantStatus,
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoading());
    }
  };

export const getDeferredMerchant = (): ThunkAction<
  void,
  IAppState,
  undefined,
  IAppAction
> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    try {
      const response: AxiosResponse<DeferredOption[]> = await axios.get(
        `${environment.kushkiUrl}/deferred/v1/list-merchant`
      );

      dispatch(setDeferred(response.data));
      dispatch(setMerchantDeferred(response.data));
      dispatch(setIsDeferredLoaded(true));
    } catch (e) {
      dispatch(setIsDeferredLoaded(true));
      dispatch(
        setNotification({
          message: "Hubo un error al consultar el comercio",
          open: true,
          type: "error",
        })
      );
    }
  };
};

export const updateDeferredStatusMerchant = (
  country: CountriesEnum,
  deferredId: string,
  status: StatusEnum,
  merchantId: string,
  notificationTheme?: INotification
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    try {
      const payload = {
        country,
        deferredId,
        status,
      };

      dispatch(showLoading());

      const path = `${environment.kushkiUrl}/deferred/v1/deferred/merchant/${merchantId}`;

      await axios.patch(path, payload);

      let NotificationProps: INotification = {
        customColor: "#293036",
        icon: false,
        message:
          payload.status === StatusEnum.ENABLED
            ? STATUS_ENABLED_CHANGE_SUCCESS_MESSAGE
            : STATUS_DISABLED_CHANGE_SUCCESS_MESSAGE,
        open: true,
        type: "success",
      };

      if (notificationTheme) NotificationProps = notificationTheme;

      dispatch(setNotification(NotificationProps));
      dispatch(getDeferredMerchant());
      dispatch(hideLoading());
    } catch (e) {
      dispatch(
        setErrorChangeStatusDeferred({
          id: deferredId,
          status: true,
        })
      );
      dispatch(
        setNotification({
          customColor: "#293036",
          icon: false,
          message: ERROR_UPDATE_DEFERRED_MESSAGE,
          open: true,
          type: "error",
        })
      );
      dispatch(hideLoading());
    }
  };
};
export const getNodeInfo = (
  body: GetNodeInfoRequest
): ThunkAction<void, IDeferredState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IDeferredState, any, IAppAction>): void => {
    dispatch(showLoading());
    axios
      .post<GetNodeInfoResponse>(
        `${environment.kushkiUrl}/billing-core-node/v1/node/info`,
        body
      )
      .then((axios_response: AxiosResponse<GetNodeInfoResponse>) => {
        const response: GetNodeInfoResponse = axios_response.data;

        dispatch(setNodeInfo(response));
        dispatch(hideLoading());
      })
      .catch(() => {
        dispatch(hideLoading());
      });
  };
};
export const updateDeferredMerchant = (
  payload: UpdateDeferredMerchantRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    try {
      const path = `${environment.kushkiUrl}/deferred/v1/merchant/deferred`;

      await axios.post(path, payload);

      if (
        [CountriesEnum.ECUADOR, CountriesEnum.MEXICO].includes(
          payload.country as CountriesEnum
        )
      ) {
        dispatch(getDeferredMerchant());
        dispatch(
          setNotification({
            message: UPDATE_DEFERRED_SUCCESS_MESSAGE,
            open: true,
            type: "success",
          })
        );
      } else {
        dispatch(
          setNotification({
            message:
              get(payload, "deferredOptions[0].status") === StatusEnum.ENABLED
                ? STATUS_ENABLED_CHANGE_SUCCESS_MESSAGE
                : STATUS_DISABLED_CHANGE_SUCCESS_MESSAGE,
            open: true,
            type: "success",
          })
        );
      }
    } catch (e) {
      dispatch(
        setErrorChangeStatusDeferred({
          id: get(payload, "deferredOptions[0].id"),
          status: true,
        })
      );
      if (payload.country === CountriesEnum.ECUADOR)
        dispatch(
          setNotification({
            message: UPDATE_DEFERRED_FAIL_MESSAGE,
            open: true,
            type: "error",
          })
        );
      else
        dispatch(
          setNotification({
            message: "Hubo un error al cambiar el estado",
            open: true,
            type: "error",
          })
        );
    }
  };
};

export const updateDeferredMerchantCentralAmerica =
  (
    publicMerchantId: string,
    body: UpdateDeferredMerchantRequest
  ): ThunkAction<void, IAppState, any, IAppAction> =>
  async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    try {
      const url = `${environment.kushkiUrl}/deferred/v1/admin/deferred/merchant/${publicMerchantId}`;

      await axios.post(url, {
        ...body,
      });

      dispatch(getDeferredMerchant());
      dispatch(
        setNotification({
          message: UPDATE_DEFERRED_CENTRAL_AMERICA,
          open: true,
          type: "success",
        })
      );
    } catch (error) {
      dispatch(
        setNotification({
          message: "Hubo un error al cambiar el estado",
          open: true,
          type: "error",
        })
      );
    }
  };

export const updateDeferredMerchantBrazil =
  (
    publicMerchantId: string,
    payload: UpdateDeferredMerchantRequest
  ): ThunkAction<void, IAppState, never, IAppAction> =>
  async (
    dispatch: ThunkDispatch<IAppState, never, IAppAction>
  ): Promise<void> => {
    try {
      const url = `${environment.kushkiUrl}/deferred/v1/admin/deferred/merchant/${publicMerchantId}`;

      await axios.post(url, payload);

      dispatch(getDeferredMerchant());
      dispatch(
        setNotification({
          message: UPDATE_DEFERRED_SUCCESS_BRAZIL,
          open: true,
          type: "success",
        })
      );
    } catch (e) {
      dispatch(
        setNotification({
          message: UPDATE_DEFERRED_FAIL_MESSAGE,
          open: true,
          type: "error",
        })
      );
    }
  };
