import { CountryEnum } from "../countryEnum";
import { TextfieldPhoneCountryEnum } from "@kushki/connect-ui";
import { get, isEmpty, isNil } from "lodash";

export const CatalogCountryPhoneCode = {
  [CountryEnum.mexico]: TextfieldPhoneCountryEnum.MEXICO,
  [CountryEnum.ecuador]: TextfieldPhoneCountryEnum.ECUADOR,
  [CountryEnum.brasil]: TextfieldPhoneCountryEnum.BRASIL,
  [CountryEnum.usa]: TextfieldPhoneCountryEnum.ESTADOS_UNIDOS_DE_AMERICA,
  [CountryEnum.chile]: TextfieldPhoneCountryEnum.CHILE,
  [CountryEnum.panama]: TextfieldPhoneCountryEnum.PANAMA,
  [CountryEnum.peru]: TextfieldPhoneCountryEnum.PERU,
  [CountryEnum.colombia]: TextfieldPhoneCountryEnum.COLOMBIA,
  [CountryEnum.guatemala]: TextfieldPhoneCountryEnum.GUATEMALA,
  [CountryEnum.honduras]: TextfieldPhoneCountryEnum.HONDURAS,
  [CountryEnum.nicaragua]: TextfieldPhoneCountryEnum.NICARAGUA,
  [CountryEnum.el_salvador]: TextfieldPhoneCountryEnum.EL_SALVADOR,
  [CountryEnum.costa_rica]: TextfieldPhoneCountryEnum.COSTA_RICA,
  [CountryEnum.AFGANISTAN]: TextfieldPhoneCountryEnum.AFGANISTAN,
  [CountryEnum.ALBANIA]: TextfieldPhoneCountryEnum.ALBANIA,
  [CountryEnum.ALEMANIA]: TextfieldPhoneCountryEnum.ALEMANIA,
  [CountryEnum.ANDORRA]: TextfieldPhoneCountryEnum.ANDORRA,
  [CountryEnum.ANGOLA]: TextfieldPhoneCountryEnum.ANGOLA,
  [CountryEnum.ANGUILA]: TextfieldPhoneCountryEnum.ANGUILA,
  [CountryEnum.ANTARTIDA]: TextfieldPhoneCountryEnum.ANTARTIDA,
  [CountryEnum.ANTIGUA_Y_BARBUDA]: TextfieldPhoneCountryEnum.ANTIGUA_Y_BARBUDA,
  [CountryEnum.ARABIASAUDITA]: TextfieldPhoneCountryEnum.ARABIASAUDITA,
  [CountryEnum.ARGELIA]: TextfieldPhoneCountryEnum.ARGELIA,
  [CountryEnum.ARGENTINA]: TextfieldPhoneCountryEnum.ARGENTINA,
  [CountryEnum.ARMENIA]: TextfieldPhoneCountryEnum.ARMENIA,
  [CountryEnum.ARUBA]: TextfieldPhoneCountryEnum.ARUBA,
  [CountryEnum.AUSTRALIA]: TextfieldPhoneCountryEnum.AUSTRALIA,
  [CountryEnum.AUSTRIA]: TextfieldPhoneCountryEnum.AUSTRIA,
  [CountryEnum.AZERBAIYAN]: TextfieldPhoneCountryEnum.AZERBAIYAN,
  [CountryEnum.BELGICA]: TextfieldPhoneCountryEnum.BELGICA,
  [CountryEnum.BAHAMAS]: TextfieldPhoneCountryEnum.BAHAMAS,
  [CountryEnum.BAHREIN]: TextfieldPhoneCountryEnum.BAHREIN,
  [CountryEnum.BANGLADESH]: TextfieldPhoneCountryEnum.BANGLADESH,
  [CountryEnum.BARBADOS]: TextfieldPhoneCountryEnum.BARBADOS,
  [CountryEnum.BELICE]: TextfieldPhoneCountryEnum.BELICE,
  [CountryEnum.BENIN]: TextfieldPhoneCountryEnum.BENIN,
  [CountryEnum.BHUTAN]: TextfieldPhoneCountryEnum.BHUTAN,
  [CountryEnum.BIELORRUSIA]: TextfieldPhoneCountryEnum.BIELORRUSIA,
  [CountryEnum.BIRMANIA]: TextfieldPhoneCountryEnum.BIRMANIA,
  [CountryEnum.BOLIVIA]: TextfieldPhoneCountryEnum.BOLIVIA,
  [CountryEnum.BOSNIA_Y_HERZEGOVIA]:
    TextfieldPhoneCountryEnum.BOSNIA_Y_HERZEGOVIA,
  [CountryEnum.BOTSUANA]: TextfieldPhoneCountryEnum.BOTSUANA,
  [CountryEnum.BRUNEI]: TextfieldPhoneCountryEnum.BRUNEI,
  [CountryEnum.BURKINA_FASO]: TextfieldPhoneCountryEnum.BURKINA_FASO,
  [CountryEnum.BURUNDI]: TextfieldPhoneCountryEnum.BURUNDI,
  [CountryEnum.CABO_VERDE]: TextfieldPhoneCountryEnum.CABO_VERDE,
  [CountryEnum.CAMBOYA]: TextfieldPhoneCountryEnum.CAMBOYA,
  [CountryEnum.CAMERUN]: TextfieldPhoneCountryEnum.CAMERUN,
  [CountryEnum.CANADA]: TextfieldPhoneCountryEnum.CANADA,
  [CountryEnum.CHAD]: TextfieldPhoneCountryEnum.CHAD,
  [CountryEnum.CHINA]: TextfieldPhoneCountryEnum.CHINA,
  [CountryEnum.CHIPRE]: TextfieldPhoneCountryEnum.CHIPRE,
  [CountryEnum.CIUDAD_DEL_VATICANO]:
    TextfieldPhoneCountryEnum.CIUDAD_DEL_VATICANO,
  [CountryEnum.COMORAS]: TextfieldPhoneCountryEnum.COMORAS,
  [CountryEnum.REPUBLICA_DEL_CONGO]:
    TextfieldPhoneCountryEnum.REPUBLICA_DEL_CONGO,
  [CountryEnum.REPUBLICA_DEMOCRATICA_DEL_CONGO]:
    TextfieldPhoneCountryEnum.REPUBLICA_DEMOCRATICA_DEL_CONGO,
  [CountryEnum.COREA_DEL_NORTE]: TextfieldPhoneCountryEnum.COREA_DEL_NORTE,
  [CountryEnum.COREA_DEL_SUR]: TextfieldPhoneCountryEnum.COREA_DEL_SUR,
  [CountryEnum.COSTA_DE_MARFIL]: TextfieldPhoneCountryEnum.COSTA_DE_MARFIL,
  [CountryEnum.CROACIA]: TextfieldPhoneCountryEnum.CROACIA,
  [CountryEnum.CUBA]: TextfieldPhoneCountryEnum.CUBA,
  [CountryEnum.CURAZAO]: TextfieldPhoneCountryEnum.CURAZAO,
  [CountryEnum.DINAMARCA]: TextfieldPhoneCountryEnum.DINAMARCA,
  [CountryEnum.DOMINICA]: TextfieldPhoneCountryEnum.DOMINICA,
  [CountryEnum.EGIPTO]: TextfieldPhoneCountryEnum.EGIPTO,
  [CountryEnum.EMIRATOS_ARABES_UNIDOS]:
    TextfieldPhoneCountryEnum.EMIRATOS_ARABES_UNIDOS,
  [CountryEnum.ERITREA]: TextfieldPhoneCountryEnum.ERITREA,
  [CountryEnum.ESLOVAQUIA]: TextfieldPhoneCountryEnum.ESLOVAQUIA,
  [CountryEnum.ESLOVENIA]: TextfieldPhoneCountryEnum.ESLOVENIA,
  [CountryEnum.ESPANIA]: TextfieldPhoneCountryEnum.ESPANIA,
  [CountryEnum.ESTONIA]: TextfieldPhoneCountryEnum.ESTONIA,
  [CountryEnum.ESTIOPIA]: TextfieldPhoneCountryEnum.ESTIOPIA,
  [CountryEnum.FILIPINAS]: TextfieldPhoneCountryEnum.FILIPINAS,
  [CountryEnum.FINLANDIA]: TextfieldPhoneCountryEnum.FINLANDIA,
  [CountryEnum.FIYI]: TextfieldPhoneCountryEnum.FIYI,
  [CountryEnum.FRANCIA]: TextfieldPhoneCountryEnum.FRANCIA,
  [CountryEnum.GABON]: TextfieldPhoneCountryEnum.GABON,
  [CountryEnum.GAMBIA]: TextfieldPhoneCountryEnum.GAMBIA,
  [CountryEnum.GEORGIA]: TextfieldPhoneCountryEnum.GEORGIA,
  [CountryEnum.GHANA]: TextfieldPhoneCountryEnum.GHANA,
  [CountryEnum.GIBRALTAR]: TextfieldPhoneCountryEnum.GIBRALTAR,
  [CountryEnum.GRANADA]: TextfieldPhoneCountryEnum.GRANADA,
  [CountryEnum.GRECIA]: TextfieldPhoneCountryEnum.GRECIA,
  [CountryEnum.GROENLANDIA]: TextfieldPhoneCountryEnum.GROENLANDIA,
  [CountryEnum.GUADALUPE]: TextfieldPhoneCountryEnum.GUADALUPE,
  [CountryEnum.GUAM]: TextfieldPhoneCountryEnum.GUAM,
  [CountryEnum.GUAYANA_FRANCESA]: TextfieldPhoneCountryEnum.GUAYANA_FRANCESA,
  [CountryEnum.GUERNSEY]: TextfieldPhoneCountryEnum.GUERNSEY,
  [CountryEnum.GUINEA]: TextfieldPhoneCountryEnum.GUINEA,
  [CountryEnum.GUINEA_ECUATORIAL]: TextfieldPhoneCountryEnum.GUINEA_ECUATORIAL,
  [CountryEnum.GUINEA_BISSAU]: TextfieldPhoneCountryEnum.GUINEA_BISSAU,
  [CountryEnum.GUYANA]: TextfieldPhoneCountryEnum.GUYANA,
  [CountryEnum.HAITI]: TextfieldPhoneCountryEnum.HAITI,
  [CountryEnum.HONG_KONG]: TextfieldPhoneCountryEnum.HONG_KONG,
  [CountryEnum.HUNGRIA]: TextfieldPhoneCountryEnum.HUNGRIA,
  [CountryEnum.INDIA]: TextfieldPhoneCountryEnum.INDIA,
  [CountryEnum.INDONESIA]: TextfieldPhoneCountryEnum.INDONESIA,
  [CountryEnum.IRAN]: TextfieldPhoneCountryEnum.IRAN,
  [CountryEnum.IRAK]: TextfieldPhoneCountryEnum.IRAK,
  [CountryEnum.IRLANDA]: TextfieldPhoneCountryEnum.IRLANDA,
  [CountryEnum.ISLA_BOUVET]: TextfieldPhoneCountryEnum.ISLA_BOUVET,
  [CountryEnum.ISLA_DE_MAN]: TextfieldPhoneCountryEnum.ISLA_DE_MAN,
  [CountryEnum.ISLA_DE_NAVIDAD]: TextfieldPhoneCountryEnum.ISLA_DE_NAVIDAD,
  [CountryEnum.ISLA_NORFOLK]: TextfieldPhoneCountryEnum.ISLA_NORFOLK,
  [CountryEnum.ISLANDIA]: TextfieldPhoneCountryEnum.ISLANDIA,
  [CountryEnum.ISLAS_BERMUDAS]: TextfieldPhoneCountryEnum.ISLAS_BERMUDAS,
  [CountryEnum.ISLAS_CAIMAN]: TextfieldPhoneCountryEnum.ISLAS_CAIMAN,
  [CountryEnum.ISLAS_COCOS]: TextfieldPhoneCountryEnum.ISLAS_COCOS,
  [CountryEnum.ISLAS_COOK]: TextfieldPhoneCountryEnum.ISLAS_COOK,
  [CountryEnum.ISLAS_DE_ALAND]: TextfieldPhoneCountryEnum.ISLAS_DE_ALAND,
  [CountryEnum.ISLAS_FEROE]: TextfieldPhoneCountryEnum.ISLAS_FEROE,
  [CountryEnum.ISLAS_GEORGIAS_Y_SANDWICH]:
    TextfieldPhoneCountryEnum.ISLAS_GEORGIAS_Y_SANDWICH,
  [CountryEnum.ISLAS_HEARD_Y_MCDONALD]:
    TextfieldPhoneCountryEnum.ISLAS_HEARD_Y_MCDONALD,
  [CountryEnum.ISLAS_MALDIVAS]: TextfieldPhoneCountryEnum.ISLAS_MALDIVAS,
  [CountryEnum.ISLAS_MALVINAS]: TextfieldPhoneCountryEnum.ISLAS_MALVINAS,
  [CountryEnum.ISLAS_MARIANAS_DEL_NORTE]:
    TextfieldPhoneCountryEnum.ISLAS_MARIANAS_DEL_NORTE,
  [CountryEnum.ISLAS_MARSHALL]: TextfieldPhoneCountryEnum.ISLAS_MARSHALL,
  [CountryEnum.ISLAS_PITCAIRN]: TextfieldPhoneCountryEnum.ISLAS_PITCAIRN,
  [CountryEnum.ISLAS_SALOMON]: TextfieldPhoneCountryEnum.ISLAS_SALOMON,
  [CountryEnum.ISLAS_TURCAS_Y_CAICOS]:
    TextfieldPhoneCountryEnum.ISLAS_TURCAS_Y_CAICOS,
  [CountryEnum.ISLAS_ULTRAMARINAS_MENORES]:
    TextfieldPhoneCountryEnum.ISLAS_ULTRAMARINAS_MENORES,
  [CountryEnum.ISLAS_VIRGENES_BRITANICAS]:
    TextfieldPhoneCountryEnum.ISLAS_VIRGENES_BRITANICAS,
  [CountryEnum.ISLAS_VIRGENES_DE_LOS_ESTADOS_UNIDOS]:
    TextfieldPhoneCountryEnum.ISLAS_VIRGENES_DE_LOS_ESTADOS_UNIDOS,
  [CountryEnum.ISRAEL]: TextfieldPhoneCountryEnum.ISRAEL,
  [CountryEnum.ITALIA]: TextfieldPhoneCountryEnum.ITALIA,
  [CountryEnum.JAMAICA]: TextfieldPhoneCountryEnum.JAMAICA,
  [CountryEnum.JAPON]: TextfieldPhoneCountryEnum.JAPON,
  [CountryEnum.JERSEY]: TextfieldPhoneCountryEnum.JERSEY,
  [CountryEnum.JORDANIA]: TextfieldPhoneCountryEnum.JORDANIA,
  [CountryEnum.KAZAJISTAN]: TextfieldPhoneCountryEnum.KAZAJISTAN,
  [CountryEnum.KENIA]: TextfieldPhoneCountryEnum.KENIA,
  [CountryEnum.KIRGUISTAN]: TextfieldPhoneCountryEnum.KIRGUISTAN,
  [CountryEnum.KIRIBATI]: TextfieldPhoneCountryEnum.KIRIBATI,
  [CountryEnum.KUWAIT]: TextfieldPhoneCountryEnum.KUWAIT,
  [CountryEnum.LIBANO]: TextfieldPhoneCountryEnum.LIBANO,
  [CountryEnum.LAOS]: TextfieldPhoneCountryEnum.LAOS,
  [CountryEnum.LESOTO]: TextfieldPhoneCountryEnum.LESOTO,
  [CountryEnum.LETONIA]: TextfieldPhoneCountryEnum.LETONIA,
  [CountryEnum.LIBERIA]: TextfieldPhoneCountryEnum.LIBERIA,
  [CountryEnum.LIBIA]: TextfieldPhoneCountryEnum.LIBIA,
  [CountryEnum.LIECHTENSTEIN]: TextfieldPhoneCountryEnum.LIECHTENSTEIN,
  [CountryEnum.LITUANIA]: TextfieldPhoneCountryEnum.LITUANIA,
  [CountryEnum.LUXEMBURGO]: TextfieldPhoneCountryEnum.LUXEMBURGO,
  [CountryEnum.MONACO]: TextfieldPhoneCountryEnum.MONACO,
  [CountryEnum.MACAO]: TextfieldPhoneCountryEnum.MACAO,
  [CountryEnum.MACEDONIA]: TextfieldPhoneCountryEnum.MACEDONIA,
  [CountryEnum.MADAGASCAR]: TextfieldPhoneCountryEnum.MADAGASCAR,
  [CountryEnum.MALASIA]: TextfieldPhoneCountryEnum.MALASIA,
  [CountryEnum.MALAWI]: TextfieldPhoneCountryEnum.MALAWI,
  [CountryEnum.MALI]: TextfieldPhoneCountryEnum.MALI,
  [CountryEnum.MALTA]: TextfieldPhoneCountryEnum.MALTA,
  [CountryEnum.MARRUECOS]: TextfieldPhoneCountryEnum.MARRUECOS,
  [CountryEnum.MARTINICA]: TextfieldPhoneCountryEnum.MARTINICA,
  [CountryEnum.MAURICIO]: TextfieldPhoneCountryEnum.MAURICIO,
  [CountryEnum.MAURITANIA]: TextfieldPhoneCountryEnum.MAURITANIA,
  [CountryEnum.MAYOTTE]: TextfieldPhoneCountryEnum.MAYOTTE,
  [CountryEnum.MICRONESIA]: TextfieldPhoneCountryEnum.MICRONESIA,
  [CountryEnum.MOLDAVIA]: TextfieldPhoneCountryEnum.MOLDAVIA,
  [CountryEnum.MONGOLIA]: TextfieldPhoneCountryEnum.MONGOLIA,
  [CountryEnum.MONTENEGRO]: TextfieldPhoneCountryEnum.MONTENEGRO,
  [CountryEnum.MONTSERRAT]: TextfieldPhoneCountryEnum.MONTSERRAT,
  [CountryEnum.MOZAMBIQUE]: TextfieldPhoneCountryEnum.MOZAMBIQUE,
  [CountryEnum.NAMIBIA]: TextfieldPhoneCountryEnum.NAMIBIA,
  [CountryEnum.NAURU]: TextfieldPhoneCountryEnum.NAURU,
  [CountryEnum.NEPAL]: TextfieldPhoneCountryEnum.NEPAL,
  [CountryEnum.NIGER]: TextfieldPhoneCountryEnum.NIGER,
  [CountryEnum.NIGERIA]: TextfieldPhoneCountryEnum.NIGERIA,
  [CountryEnum.NIUE]: TextfieldPhoneCountryEnum.NIUE,
  [CountryEnum.NORUEGA]: TextfieldPhoneCountryEnum.NORUEGA,
  [CountryEnum.NUEVA_CALEDONIA]: TextfieldPhoneCountryEnum.NUEVA_CALEDONIA,
  [CountryEnum.NUEVA_ZELANDA]: TextfieldPhoneCountryEnum.NUEVA_ZELANDA,
  [CountryEnum.OMAN]: TextfieldPhoneCountryEnum.OMAN,
  [CountryEnum.PAISES_BAJOS]: TextfieldPhoneCountryEnum.PAISES_BAJOS,
  [CountryEnum.PAKISTAN]: TextfieldPhoneCountryEnum.PAKISTAN,
  [CountryEnum.PALAU]: TextfieldPhoneCountryEnum.PALAU,
  [CountryEnum.PALESTINA]: TextfieldPhoneCountryEnum.PALESTINA,
  [CountryEnum.PAPUA_NUEVA_GUINEA]:
    TextfieldPhoneCountryEnum.PAPUA_NUEVA_GUINEA,
  [CountryEnum.PARAGUAY]: TextfieldPhoneCountryEnum.PARAGUAY,
  [CountryEnum.POLINESIA_FRANCESA]:
    TextfieldPhoneCountryEnum.POLINESIA_FRANCESA,
  [CountryEnum.POLONIA]: TextfieldPhoneCountryEnum.POLONIA,
  [CountryEnum.PORTUGAL]: TextfieldPhoneCountryEnum.PORTUGAL,
  [CountryEnum.PUERTO_RICO]: TextfieldPhoneCountryEnum.PUERTO_RICO,
  [CountryEnum.QATAR]: TextfieldPhoneCountryEnum.QATAR,
  [CountryEnum.REINO_UNIDO]: TextfieldPhoneCountryEnum.REINO_UNIDO,
  [CountryEnum.REPUBLICA_CENTROAFRICANA]:
    TextfieldPhoneCountryEnum.REPUBLICA_CENTROAFRICANA,
  [CountryEnum.REPUBLICA_CHECA]: TextfieldPhoneCountryEnum.REPUBLICA_CHECA,
  [CountryEnum.REPUBLICA_DOMINICANA]:
    TextfieldPhoneCountryEnum.REPUBLICA_DOMINICANA,
  [CountryEnum.REPUBLICA_DE_SUDAN_DEL_SUR]:
    TextfieldPhoneCountryEnum.REPUBLICA_DE_SUDAN_DEL_SUR,
  [CountryEnum.REUNION]: TextfieldPhoneCountryEnum.REUNION,
  [CountryEnum.RUANDA]: TextfieldPhoneCountryEnum.RUANDA,
  [CountryEnum.RUMANIA]: TextfieldPhoneCountryEnum.RUMANIA,
  [CountryEnum.RUSIA]: TextfieldPhoneCountryEnum.RUSIA,
  [CountryEnum.SAHARA_OCCIDENTAL]: TextfieldPhoneCountryEnum.SAHARA_OCCIDENTAL,
  [CountryEnum.SAMOA]: TextfieldPhoneCountryEnum.SAMOA,
  [CountryEnum.SAMOA_AMERICA]: TextfieldPhoneCountryEnum.SAMOA_AMERICA,
  [CountryEnum.SAN_BARTOLOME]: TextfieldPhoneCountryEnum.SAN_BARTOLOME,
  [CountryEnum.SAN_CRISTOBLA_Y_NIEVES]:
    TextfieldPhoneCountryEnum.SAN_CRISTOBLA_Y_NIEVES,
  [CountryEnum.SAN_MARINO]: TextfieldPhoneCountryEnum.SAN_MARINO,
  [CountryEnum.SAN_MARTIN]: TextfieldPhoneCountryEnum.SAN_MARTIN,
  [CountryEnum.SAN_PEDRO_Y_MIQUELON]:
    TextfieldPhoneCountryEnum.SAN_PEDRO_Y_MIQUELON,
  [CountryEnum.SAN_VICENTE_Y_LAS_GRANADINAS]:
    TextfieldPhoneCountryEnum.SAN_VICENTE_Y_LAS_GRANADINAS,
  [CountryEnum.SANTA_ELENA]: TextfieldPhoneCountryEnum.SANTA_ELENA,
  [CountryEnum.SANTA_LUCIA]: TextfieldPhoneCountryEnum.SANTA_LUCIA,
  [CountryEnum.SANTO_TOME_Y_PRINCIPE]:
    TextfieldPhoneCountryEnum.SANTO_TOME_Y_PRINCIPE,
  [CountryEnum.SENEGAL]: TextfieldPhoneCountryEnum.SENEGAL,
  [CountryEnum.SERBIA]: TextfieldPhoneCountryEnum.SERBIA,
  [CountryEnum.SEYCHELLES]: TextfieldPhoneCountryEnum.SEYCHELLES,
  [CountryEnum.SIERRA_LEONA]: TextfieldPhoneCountryEnum.SIERRA_LEONA,
  [CountryEnum.SINGAPUR]: TextfieldPhoneCountryEnum.SINGAPUR,
  [CountryEnum.SINT_MAARTEN]: TextfieldPhoneCountryEnum.SINT_MAARTEN,
  [CountryEnum.SIRIA]: TextfieldPhoneCountryEnum.SIRIA,
  [CountryEnum.SOMALIA]: TextfieldPhoneCountryEnum.SOMALIA,
  [CountryEnum.SRI_LANKA]: TextfieldPhoneCountryEnum.SRI_LANKA,
  [CountryEnum.SUDAFRICA]: TextfieldPhoneCountryEnum.SUDAFRICA,
  [CountryEnum.SUDAN]: TextfieldPhoneCountryEnum.SUDAN,
  [CountryEnum.SUECIA]: TextfieldPhoneCountryEnum.SUECIA,
  [CountryEnum.SUIZA]: TextfieldPhoneCountryEnum.SUIZA,
  [CountryEnum.SURINAM]: TextfieldPhoneCountryEnum.SURINAM,
  [CountryEnum.SVALBARD_Y_JAN_MAYEN]:
    TextfieldPhoneCountryEnum.SVALBARD_Y_JAN_MAYEN,
  [CountryEnum.SWAZILANDIA]: TextfieldPhoneCountryEnum.SWAZILANDIA,
  [CountryEnum.TAYIKISTAN]: TextfieldPhoneCountryEnum.TAYIKISTAN,
  [CountryEnum.TAILANDIA]: TextfieldPhoneCountryEnum.TAILANDIA,
  [CountryEnum.TAIWAN]: TextfieldPhoneCountryEnum.TAIWAN,
  [CountryEnum.TANZANIA]: TextfieldPhoneCountryEnum.TANZANIA,
  [CountryEnum.TERRITORIO_BRITANICO_DEL_OCEANO_INDICO]:
    TextfieldPhoneCountryEnum.TERRITORIO_BRITANICO_DEL_OCEANO_INDICO,
  [CountryEnum.TERRITORIOS_AUSTRALES_Y_ANTARTICAS_FRANCESES]:
    TextfieldPhoneCountryEnum.TERRITORIOS_AUSTRALES_Y_ANTARTICAS_FRANCESES,
  [CountryEnum.TIMOR_ORIENTAL]: TextfieldPhoneCountryEnum.TIMOR_ORIENTAL,
  [CountryEnum.TOGO]: TextfieldPhoneCountryEnum.TOGO,
  [CountryEnum.TOKELAU]: TextfieldPhoneCountryEnum.TOKELAU,
  [CountryEnum.TONGA]: TextfieldPhoneCountryEnum.TONGA,
  [CountryEnum.TRINIDAD_Y_TOBAGO]: TextfieldPhoneCountryEnum.TRINIDAD_Y_TOBAGO,
  [CountryEnum.TUNEZ]: TextfieldPhoneCountryEnum.TUNEZ,
  [CountryEnum.TURKMENISTAN]: TextfieldPhoneCountryEnum.TURKMENISTAN,
  [CountryEnum.TURQUIA]: TextfieldPhoneCountryEnum.TURQUIA,
  [CountryEnum.TUVALU]: TextfieldPhoneCountryEnum.TUVALU,
  [CountryEnum.UCRANIA]: TextfieldPhoneCountryEnum.UCRANIA,
  [CountryEnum.UGANDA]: TextfieldPhoneCountryEnum.UGANDA,
  [CountryEnum.URUGUAY]: TextfieldPhoneCountryEnum.URUGUAY,
  [CountryEnum.UZBEKISTAN]: TextfieldPhoneCountryEnum.UZBEKISTAN,
  [CountryEnum.VANUATU]: TextfieldPhoneCountryEnum.VANUATU,
  [CountryEnum.VENEZUELA]: TextfieldPhoneCountryEnum.VENEZUELA,
  [CountryEnum.VIETNAM]: TextfieldPhoneCountryEnum.VIETNAM,
  [CountryEnum.WALLIS_Y_FUTUNA]: TextfieldPhoneCountryEnum.WALLIS_Y_FUTUNA,
  [CountryEnum.YEMEN]: TextfieldPhoneCountryEnum.YEMEN,
  [CountryEnum.YIBUTI]: TextfieldPhoneCountryEnum.YIBUTI,
  [CountryEnum.ZAMBIA]: TextfieldPhoneCountryEnum.ZAMBIA,
  [CountryEnum.ZIMBABUE]: TextfieldPhoneCountryEnum.ZIMBABUE,
};

export const CatalogPhoneCodeCountry = {
  [TextfieldPhoneCountryEnum.MEXICO.split("(+")[1].split(")")[0]]:
    CountryEnum.mexico,
  [TextfieldPhoneCountryEnum.ECUADOR.split("(+")[1].split(")")[0]]:
    CountryEnum.ecuador,
  [TextfieldPhoneCountryEnum.BRASIL.split("(+")[1].split(")")[0]]:
    CountryEnum.brasil,
  [TextfieldPhoneCountryEnum.ESTADOS_UNIDOS_DE_AMERICA.split("(+")[1].split(
    ")"
  )[0]]: CountryEnum.usa,
  [TextfieldPhoneCountryEnum.CHILE.split("(+")[1].split(")")[0]]:
    CountryEnum.chile,
  [TextfieldPhoneCountryEnum.PANAMA.split("(+")[1].split(")")[0]]:
    CountryEnum.panama,
  [TextfieldPhoneCountryEnum.PERU.split("(+")[1].split(")")[0]]:
    CountryEnum.peru,
  [TextfieldPhoneCountryEnum.COLOMBIA.split("(+")[1].split(")")[0]]:
    CountryEnum.colombia,
  [TextfieldPhoneCountryEnum.GUATEMALA.split("(+")[1].split(")")[0]]:
    CountryEnum.guatemala,
  [TextfieldPhoneCountryEnum.HONDURAS.split("(+")[1].split(")")[0]]:
    CountryEnum.honduras,
  [TextfieldPhoneCountryEnum.NICARAGUA.split("(+")[1].split(")")[0]]:
    CountryEnum.nicaragua,
  [TextfieldPhoneCountryEnum.EL_SALVADOR.split("(+")[1].split(")")[0]]:
    CountryEnum.el_salvador,
  [TextfieldPhoneCountryEnum.COSTA_RICA.split("(+")[1].split(")")[0]]:
    CountryEnum.costa_rica,
};

export const getCountryCodeMessage = (
  message: string | undefined,
  value: string,
  country: CountryEnum,
  constitutionalCountry?: CountryEnum
) => {
  const fieldValue = value?.split(" ")[0];

  if (!isEmpty(message)) return message;
  else if (
    country === constitutionalCountry ||
    (CatalogPhoneCodeCountry[fieldValue] === country &&
      isNil(constitutionalCountry))
  ) {
    return "El teléfono corresponde al país de constitución";
  } else return "";
};

export const getCountryPhoneCode = (
  country: CountryEnum
): TextfieldPhoneCountryEnum | undefined => {
  return get(CatalogCountryPhoneCode, `[${country}]`, undefined);
};
