export const genericLoaderStyle = {
  circularColor: {
    color: "primary.main",
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "130px",
    justifyContent: "center",
    marginX: 8,
    marginY: 4,
    width: {
      md: "450px",
      sm: "auto",
    },
  },
  textContainer: {
    color: "text.dark",
    marginTop: "32px",
  },
} as const;
