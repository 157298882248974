import { IBusinessRules, IRule, IRules } from "./useBusinessRules.interface";
import { ITableRowProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { useEffect, useState } from "react";
import {
  TAG_CELL,
  TEXT_CELL,
} from "../../../shared/infrastructure/constants/DispersionBlockConstants";
import { IRowInteractiveProps } from "@kushki/connect-ui";
import {
  ALL_TABLE_COLUMNS,
  Categories,
  PayoutRuleCategories,
} from "../../../shared/infrastructure/constants/BusinessRulesConstants";
import axiosInstance from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { environment } from "../../../environments/environment";
import { useHistory } from "react-router";
import useFetchData from "../../../hooks/useFecthData/useFetchData";
import { useDispatch, useSelector } from "react-redux";
import { setRulesInfoResponse } from "../../../store/actions/actions";
import { selectNodeInfo, selectRule } from "../../../store/selectors/selectors";
import { LOCAL_STORAGE_ITEMS } from "../../../shared/constants/local_storage_items";
import moment from "moment";
import {
  getConfig,
  isSectionCentralized,
} from "../../../shared/utils/isSectionCentralized";
import { Section } from "../../../shared/enums";
import { BusinessRulesStatusEnum } from "../../../shared/enums/BusinessRulesEnum";
import { defaultTo, get, isUndefined } from "lodash";
import { getPayoutsRules } from "../../../store/thunks/app/app.thunks";
import { RequestPayoutRules } from "../../../../types/request_payout_rules";
import { useAppSelector } from "../../../store/hooks/hooks";
import { RootState } from "../../../store/store.interface";
import { Condition, Rule } from "../../../../types/payout_rules";

export const useBusinessRules = (): IBusinessRules => {
  const dispatch = useDispatch();
  const rules = useSelector(selectRule);
  const { payoutRules } = useAppSelector((state: RootState) => state.app);

  const nodeInfo = useSelector(selectNodeInfo);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isCentralized, setIsCentralized] = useState(false);
  const [getEntityName, setEntityName] = useState("");

  const [rows, setRows] = useState<ITableRowProps[]>([]);
  const [payoutRulesRows, setPayoutRulesRows] = useState<ITableRowProps[]>([]);

  const history = useHistory();
  const setLocalStorage = () => {
    const item = localStorage.getItem(
      LOCAL_STORAGE_ITEMS.BASIC_MERCHANT_INFORMATION
    );

    let basicMerchantInformation = { layout: {} };

    if (item) {
      basicMerchantInformation = JSON.parse(item);
    }

    localStorage.setItem(
      LOCAL_STORAGE_ITEMS.BASIC_MERCHANT_INFORMATION,
      JSON.stringify({
        ...basicMerchantInformation,
        publicMerchantId: nodeInfo!.merchantId,
      })
    );
  };

  const configRules = (path: string) => {
    setLocalStorage();
    history.push({
      pathname: path,
    });
  };

  const editRules = (path: string) => {
    setLocalStorage();
    history.push({
      pathname: path + nodeInfo?.merchantId,
    });
  };

  const mapCategory = (column: IRule): string => {
    const { rule } = column;

    let categoryLabels = "";

    Categories.forEach((category: { key: string; label: string }) => {
      categoryLabels = rule[category.key]
        ? category.label + ", " + categoryLabels
        : categoryLabels;
    });

    return categoryLabels.replace(/,\s*$/, "");
  };

  const mapCategoryPayoutRules = (conditions: Condition[]): string => {
    let labelCategories: string = "";
    const separator: string = ", ";

    for (const condition of conditions) {
      const category: { key: string; label: string } | undefined =
        PayoutRuleCategories.find(
          (item: { key: string; label: string }) => item.key === condition.field
        );

      if (isUndefined(category)) continue;

      labelCategories =
        get(category, "label", "") + separator + labelCategories;
    }

    return labelCategories.replace(/,\s*$/, "");
  };

  const rulesInfoResponse = useFetchData(() => {
    const merchantRequest: string = !isSectionCentralized(
      Section.PROCESSING_BUSINESS_RULES,
      nodeInfo!.configs
    )
      ? nodeInfo!.merchantId
      : defaultTo(
          getConfig(Section.PROCESSING_BUSINESS_RULES, nodeInfo!.configs)
            ?.value,
          ""
        );

    const req = { limit: 5, merchantId: merchantRequest, scan: true };

    localStorage.setItem(
      LOCAL_STORAGE_ITEMS.BASIC_MERCHANT_INFORMATION,
      JSON.stringify({
        ...JSON.parse(
          localStorage.getItem(
            LOCAL_STORAGE_ITEMS.BASIC_MERCHANT_INFORMATION
          ) || "{}"
        ),
        country: nodeInfo?.generalInfo.country,
        entityName: nodeInfo?.entityName,
        merchantId: nodeInfo?.merchantId,
        name: nodeInfo?.generalInfo.name,
        origin: "merchantResume",
        redirectPath: nodeInfo?.path,
      })
    );

    return axiosInstance.request<IRules>({
      data: req,
      method: "POST",
      url: `${environment.kushkiUrl}${API_ROUTES.RULES}`,
    });
  }, [nodeInfo]);

  const onClickRow = () => {};

  useEffect(() => {
    if (rules?.items === null || !rules?.items?.length) {
      setIsLoading(false);

      return;
    }
    const { items } = rules;

    const valuesToRow = items.map(
      (column: IRule, index: number): ITableRowProps => {
        const { createdAt } = column;

        const cells = [
          TEXT_CELL(
            moment.unix(createdAt).format("DD-MM-YYYY"),
            moment.unix(createdAt).format("HH:mm:ss")
          ),
          TEXT_CELL(column.rule.name),
          TEXT_CELL(mapCategory(column)),
          TEXT_CELL(column.rule.processorName || ""),
          TAG_CELL(
            get(column, "status", BusinessRulesStatusEnum.ENABLED).toLowerCase()
          ),
        ];

        return {
          cells,
          id: "rules" + index,
          rowProps: {
            color: "default",
            onClick: onClickRow,
          } as IRowInteractiveProps,
        };
      }
    );

    setRows(valuesToRow);
    setIsLoading(false);
  }, [rules]);

  useEffect(() => {
    if (payoutRules.data === null || payoutRules.data.length === 0) {
      setIsLoading(false);

      return;
    }
    const { data } = payoutRules;

    const valuesToRow = data.map(
      (column: Rule, index: number): ITableRowProps => {
        const { createdAt } = column;
        const date: number = new Date().getTime();
        const cells = [
          TEXT_CELL(
            moment.unix(defaultTo(createdAt, date)).format("DD-MM-YYYY"),
            moment.unix(defaultTo(createdAt, date)).format("HH:mm:ss")
          ),
          TEXT_CELL(column.name),
          TEXT_CELL(mapCategoryPayoutRules(column.conditions)),
          TEXT_CELL(column.processorName),
          TAG_CELL(column.status.toLowerCase()),
        ];

        return {
          cells,
          id: "rules" + index,
          rowProps: {
            color: "default",
            onClick: onClickRow,
          } as IRowInteractiveProps,
        };
      }
    );

    setPayoutRulesRows(valuesToRow);
    setIsLoading(false);
  }, [payoutRules]);

  useEffect(() => {
    if (rulesInfoResponse.data) {
      dispatch(setRulesInfoResponse(rulesInfoResponse.data.data));
    }
  }, [rulesInfoResponse.data]);

  useEffect(() => {
    if (nodeInfo) {
      const isCentralized = isSectionCentralized(
        Section.PROCESSING_BUSINESS_RULES,
        nodeInfo.configs
      );

      setIsCentralized(isCentralized);
      setEntityName(nodeInfo?.entityName);

      const merchantId: string = !isCentralized
        ? nodeInfo.merchantId
        : defaultTo(
            getConfig(Section.PROCESSING_BUSINESS_RULES, nodeInfo.configs)
              ?.value,
            ""
          );

      const request: RequestPayoutRules = {
        limit: 5,
        merchantId: merchantId,
        offset: 0,
      };

      dispatch(getPayoutsRules(request));
    } else return;
  }, [nodeInfo]);

  return {
    configRules,
    editRules,
    getEntityName,
    isCentralized,
    isLoading,
    payoutRulesTable: {
      columns: ALL_TABLE_COLUMNS,
      rows: payoutRulesRows,
    },
    table: {
      columns: ALL_TABLE_COLUMNS,
      rows,
    },
  };
};
