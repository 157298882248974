import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { get } from "lodash";
import {
  TabItemStatusEnum,
  TabItemTitleEnum,
} from "../../../../shared/enums/tabItemStatusEnum";
import { StatusType } from "../TabItem.interfaces";

export const useTabItem = (
  status: StatusType,
  isDisabled: boolean,
  redirectPath: string
) => {
  const [selected, setSelected] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  const processTab = () => {
    if (selected) return TabItemTitleEnum.EN_PROCESO;
    else return TabItemTitleEnum.PENDIENTE;
  };

  const statusText = () => {
    if (isDisabled) {
      return TabItemTitleEnum.NO_DISPONIBLE;
    } else if (status === TabItemStatusEnum.COMPLETE) {
      return TabItemTitleEnum.COMPLETADO;
    } else return processTab();
  };
  const processStateButton = () => {
    return status === TabItemStatusEnum.COMPLETE
      ? "text.secondary"
      : "text.grey";
  };
  const statusColor: string = processStateButton();

  useEffect(() => {
    if (get(location, "pathname", "") === redirectPath) {
      setSelected(true);
    }
  }, [selected]);

  const redirectTo = () => {
    navigate(`${redirectPath}${location.search}`);
  };

  return { redirectTo, selected, statusColor, statusText };
};
