import React, { FC } from "react";
import { Box } from "@mui/material";
import { headerAlarmsTableStyles as styles } from "@components/HeaderAlarmsTable/HeaderAlarmsTable.styles";
import {
  HeaderAlarmsSecurity,
  IHeaderAlarmsTableProps,
} from "@components/HeaderAlarmsTable/HeaderAlarmsTable.interfaces";
import { useHeaderAlarmsTable } from "@components/HeaderAlarmsTable/state/useHeaderAlarmsTable";
import {
  ColumnsPicker,
  FilterSideBar,
  RangeDatePicker,
  TextFieldSearchBy,
} from "@kushki/connect-ui";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { SecurityWrapperRoles } from "@shared/enum/SecurityWrapperRoles";
import { AlarmLabels } from "@shared/constants/labels/alarmLabels";
import { FilterTitle, RangeDatePickerEnum } from "@shared/enum/FilterLabelEnum";
import {
  alarmsFilterCategoryItems,
  alarmsFilterHistoryItems,
} from "@shared/constants/filterAlarmItems";
import { DateFormatEnum } from "@shared/constants/parseMonths";
import { SourceEnum } from "@shared/utils/activeAlarmsEnum";
import { SecurityWrapper } from "@components/atom/SecurityWrapper/SecurityWrapper";

const HeaderAlarmsTable: FC<IHeaderAlarmsTableProps> = ({
  onChangeFilter,
  onChangeColumns,
  selectedColumns,
  pathParamType,
}: IHeaderAlarmsTableProps) => {
  const { handleApplyFilters, propsFilterSearch, handleSearchDate } =
    useHeaderAlarmsTable({
      onChangeFilter,
      pathParamType,
    });

  const tagByModule: Record<SourceEnum, HeaderAlarmsSecurity> = {
    [SourceEnum.ALARMS]: {
      columnsConfig:
        SecurityWrapperRoles.M_COMPLIANCE_ALARMS_ACTIVE_CONFIGURATION_BUTTON,
      rangeDate: SecurityWrapperRoles.M_COMPLIANCE_ALARMS_ACTIVE_DATE,
      textFilter: SecurityWrapperRoles.M_COMPLIANCE_FILTER_MERCHANT_ALARM,
    },
    [SourceEnum.HISTORY]: {
      columnsConfig:
        SecurityWrapperRoles.M_COMPLIANCE_ALARMS_HISTORY_CONFIGURATION_BUTTON,
      rangeDate: SecurityWrapperRoles.M_COMPLIANCE_ALARMS_HISTORY_FILTER_DATE,
      textFilter:
        SecurityWrapperRoles.M_COMPLIANCE_ALARMS_HISTORY_FILTER_SEARCH,
    },
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.search}>
        <SecurityWrapper
          tagByModule={tagByModule}
          source={pathParamType}
          component={"textFilter"}
        >
          <Box>
            <TextFieldSearchBy
              placeholder={AlarmLabels.FILTER_SEARCH_TEXT}
              items={propsFilterSearch.itemsSearchBy}
              onChange={propsFilterSearch.handleInputChangeSearchBy}
              onItemSelected={propsFilterSearch.handleSelectedSearchBy}
            />
          </Box>
        </SecurityWrapper>
      </Box>
      <SecurityWrapper
        tagByModule={tagByModule}
        source={pathParamType}
        component={"rangeDate"}
      >
        <Box id="rangeDateAlarm" sx={styles.rangeDatePicker}>
          <RangeDatePicker
            placeholder={RangeDatePickerEnum.RangeDateLabelActiveAlarms}
            isOpen={false}
            isFilled={false}
            dateFormat={DateFormatEnum.dd_MMM_yyyy}
            onDateSelect={(startDate: string, finishDate: string) => {
              handleSearchDate(startDate, finishDate);
            }}
            disabledFutureDate={false}
            disabledOldDate={false}
            showClearDatesButton={true}
          />
        </Box>
      </SecurityWrapper>
      <SecurityWrapper
        tagByModule={tagByModule}
        source={pathParamType}
        component={"columnsConfig"}
      >
        <Box>
          <ColumnsPicker
            items={selectedColumns}
            maxColumns={7}
            onActionButtons={(items) => onChangeColumns(items)}
          />
        </Box>
      </SecurityWrapper>
      {pathParamType === SourceEnum.ALARMS ? (
        <ComponentSecurityWrapper
          componentId={SecurityWrapperRoles.M_COMPLIANCE_ALARMS_FILTER}
        >
          <Box>
            <FilterSideBar
              categoryItems={alarmsFilterCategoryItems}
              filtersCount={1}
              isMinimized={false}
              isOpen={false}
              onClick={(selected) => {
                handleApplyFilters(selected);
              }}
              title={FilterTitle.Title}
            />
          </Box>
        </ComponentSecurityWrapper>
      ) : (
        <ComponentSecurityWrapper
          componentId={SecurityWrapperRoles.M_COMPLIANCE_ALARMS_FILTER_HISTORY}
        >
          <Box>
            <FilterSideBar
              categoryItems={alarmsFilterHistoryItems}
              filtersCount={1}
              isMinimized={false}
              isOpen={false}
              onClick={(selected) => {
                handleApplyFilters(selected);
              }}
              title={FilterTitle.Title}
            />
          </Box>
        </ComponentSecurityWrapper>
      )}
    </Box>
  );
};

export default HeaderAlarmsTable;
