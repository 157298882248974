import React from "react";
import { ISnackBar } from "@kushki/connect-ui/dist/Components/Atoms/DataDisplay/SnackBar/SnackBar.interface";
import { IconCheck, IconCircleWarn } from "@kushki/connect-ui";

export const MAX_RECORDS_OVER_500: ISnackBar = {
  color: "danger",
  icon: <IconCircleWarn />,
  message: "Superaste el máximo de 500 registros",
  variant: "simple",
  withIcon: true,
};

export const DELETE_ROW_SUCCESS: ISnackBar = {
  color: "success",
  icon: <IconCheck />,
  message: "Se eliminó el nombre del branch correctamente",
  variant: "simple",
  withIcon: true,
};

export const EDIT_AND_CREATION_SUCCESS: ISnackBar = {
  color: "info",
  icon: <IconCheck />,
  message: "Se crearon y editaron los branches con éxito.",
  variant: "simple",
  withIcon: true,
};

export const WARMING_CHANGE_CONSTITUTION_COUNTRY_TITLE =
  "Al cambiar el país de constitución, deberás completar las secciones correspondientes al país escogido.";

export const WARMING_EDIT_CHECK_TITLE =
  "Se mantendrá la información del último branch modificado de los seleccionados.";
