import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  defineAttFilterStateCo,
  downloadTransactionTableFile,
  downloadTransactionUrlRol,
  getCatalogsList,
  getRetentionTrxPreProccessed,
  processRetentionsData,
  setOpenModalDetailTimeLine,
  setOpenModalModifyInconsistence,
  setPathLoadingRetention,
  setProcessData,
  setProcessLoader,
  setRetentionData,
  setStepTimeLineData,
} from "../../../store/actionCreators";
import { IBillingDashboardState } from "../../../store/reducer";
import { RentetionData } from "../../../../types/retention_data";
import { CountryEnum } from "../../../shared/infrastructure/CountryEnum";
import { RentetionDataRequest } from "../../../../types/retention_data_request";
import {
  cloneDeep,
  defaultTo,
  get,
  indexOf,
  isEmpty,
  isNil,
  keys,
  omit,
  omitBy,
  pick,
  set,
  sortBy,
  uniq,
  without,
} from "lodash";
import { TransactionDynamo } from "../../../../types/remote/transaction_dynamo";
import { OrderEnum } from "../../../shared/infrastructure/enum/OrderEnum";
import { ISortable } from "../../../shared/infrastructure/interfaces/ISortable";
import { CellTypeEnum } from "../../../shared/infrastructure/enum/CellTypeEnum";
import { format } from "date-fns-tz";
import { StatusEnum } from "../../../shared/infrastructure/StatusEnum";
import {
  StatusFlowEnum,
  StatusFlowPath,
} from "../../../shared/infrastructure/StatusFlowEnum";
import { IModifyRetentionAmountForm } from "../../../shared/infrastructure/interfaces/IModifyRetentionAmountForm";
import { useForm, UseFormMethods } from "react-hook-form";
import { IModifyRetentionValues } from "../../../shared/infrastructure/interfaces/IModifyRetentionValues";
import {
  defaultRetentionColumnsSelectable,
  defaultRetentionColumnsSelectableColombia,
} from "../../../shared/infrastructure/table/IColumnsTable";
import { RangeAmount } from "../../../../types/range_amount";
import {
  IChipSelect,
  IOptionFilter,
} from "../../DashboardList/BoxFilter/BoxFilter";

import { TransactionTable } from "../../../../types/transaction_table";
import {
  ModalInfo,
  ModalInfoQuantity,
  ModalTypeEnum,
} from "../../../shared/infrastructure/constants/ModalInfo";
import { IModalInfo } from "../../../shared/infrastructure/interfaces/IModalInfo";
import {
  DefaultFilterRetentionExecutor,
  DefaultFilterRetentionExecutorEcuador,
  DefaultFilterRetentionValidator,
} from "../../../shared/infrastructure/filter/DefaultFilterRetention";
import { DateRange } from "@material-ui/pickers";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/src/typings/date";
import { UserRolesEnum } from "../../../shared/infrastructure/UserRolesEnum";
import { KindEnum } from "../../../shared/infrastructure/KindEnum";
import { ListRetentionRequest } from "../../../../types/get_list_retention_request";
import { getUserRole } from "../../../shared/infrastructure/constants/UserRoleConstants";
import { ColombiaFilterRetentionValidator } from "../../../shared/infrastructure/constants/ColombiaFilterOptions";
import { IStepRetentionTimeLine } from "../../../shared/infrastructure/interfaces/IStepRetentionTimeLine";
import { ProductLineEnum } from "../../../shared/infrastructure/ProductLineEnum";
import { ConfigCodeEnum } from "../../../shared/infrastructure/ConfigCodeEnum";
import { CountryCodeEnum } from "../../../shared/infrastructure/CountryCodeEnum";
import { CatalogsNameEnum } from "../../../shared/infrastructure/enum/CatalogsNameEnum";

export interface IRetentionDashboardState {
  retentionData: RentetionData;
  queryInput: string;
  handleInputChange: (value: string) => void;
  handleSetDate: (dateRange: DateRange) => void;
  rangeDate: DateRange;
  handleSearchBar: (value: string) => void;
  handleChangeChecbox: (
    type: CellTypeEnum,
    status: string,
    row?: object
  ) => void;
  modalOverReject: {
    openRejectModal: boolean;
    handleAcceptRejectModal: (reason: string) => void;
    handleCloseRejectModal: () => void;
    modalInfo: IModalInfo;
  };
  sort: ISortable;
  onEdit: (row: TransactionDynamo) => void;
  columnsSettings: {
    selectedColumns: string[];
    setSelectedColumns: (data: string[]) => void;
    restoreRetention: () => void;
  };
  filter: IRetentionFilter;
  modalOverRetention: {
    openModalRetention: boolean;
    handlerCloseModalTrxRetention: () => void;
    handlerSelectTrxRetention: (
      row: TransactionTable | undefined,
      index: number
    ) => void;
    trxRetention: { trx: TransactionTable | undefined; index: number };
  };
  modalHandlers: {
    handleOpenEditRetention: (state: boolean) => void;
    openEditRetentionModal: boolean;
    retentionRowToEdit: Partial<TransactionDynamo>;
  };
  modalOverProcessRetention: {
    openProcessRetentionModal: boolean;
    handleCloseProcessRetentionsModal: () => void;
    handleAcceptProcessTransactions: () => void;
    handleProcessRetentionData: () => void;
    handleCloseProcessLoader: () => void;
    retentionsDataCounter: { counter: number; total: number };
    processLoader: boolean;
  };
  modalOverDetail: {
    handleOpenDialogDetail?: (data: IStepRetentionTimeLine) => void;
    handleCloseDialogDetail?: () => void;
  };
  editForm: UseFormMethods<IModifyRetentionAmountForm>;
  onSubmit: (data: IModifyRetentionAmountForm) => void;
  isProcessData: boolean;
  downloadFileRetention: {
    isLoading: boolean;
    downloadFile: () => void;
  };
  openModalModifyInconsistence?: boolean;
  handleModalModifyInconsistence?: (value: boolean) => void;
}

export interface IRetentionFilter {
  filtersDashboard: IOptionFilter[];
  rangeAmount: RangeAmount;
  handleGetFilters: (
    optionsChip: IChipSelect[],
    rangeAmount?: RangeAmount
  ) => void;
}

const calculateCycleField: string = "calculateCycle";
const statusField: string = "status";
const getCalculateCycle = () => {
  return format(new Date(), "yyyy-MM-dd");
};
export const useRetentionDashboardState = (): IRetentionDashboardState => {
  const dispatch = useDispatch();
  const {
    retentionData,
    country,
    processLoader,
    statusFlow,
    retentionsDataCounter,
    pathLoadingRetention,
    processData,
    transactionTableFileDownload,
  } = useSelector((state: IBillingDashboardState) => state);
  const isBackofficeAdmin: boolean = getUserRole(UserRolesEnum.BackofficeAdmin);

  const isBackofficeFinExecutor: boolean =
    getUserRole(UserRolesEnum.BackofficeFinExecutor) ||
    getUserRole(UserRolesEnum.BackofficeCoreAccountingExecutor);
  const isBackofficeFinValidator: boolean =
    getUserRole(UserRolesEnum.BackofficeFinValidator) ||
    getUserRole(UserRolesEnum.BackofficeCoreAccountingValidator);

  const getFilterOptions = (country: string, statusFlow: string) => {
    switch (country) {
      case CountryEnum.colombia:
        return statusFlow === StatusFlowEnum.EXECUTOR
          ? DefaultFilterRetentionExecutor
          : ColombiaFilterRetentionValidator;
      default:
        return statusFlow === StatusFlowEnum.EXECUTOR
          ? DefaultFilterRetentionExecutorEcuador
          : DefaultFilterRetentionValidator;
    }
  };

  const [filtersDashboard, setFilterDashboard] = useState(
    getFilterOptions(country!, statusFlow!)
  );

  const [range, setRange] = useState<RangeAmount>({
    type: "min",
    min: undefined,
    max: undefined,
    value: undefined,
    eq: undefined,
  });

  const getCurrentStatus = (statusFlow: StatusFlowEnum): string[] => {
    switch (country) {
      case CountryEnum.colombia:
        return defineAttFilterStateCo(statusFlow);

      default:
        return statusFlow === StatusFlowEnum.VALIDATOR
          ? [StatusEnum.FAILED, StatusEnum.PRE_PROCESSED]
          : [StatusEnum.PENDING];
    }
  };

  const [
    isLoadingTransactionTableDownloadFile,
    setIsLoadingTransactionTableDownloadFile,
  ] = useState<boolean>(false);

  const [queryInput, setQueryInput] = useState<string>("");
  const [checkModalStatus, setCheckModalStatus] = useState("");
  const [openEditRetentionModal, setOpenEditRetentionModal] = useState<boolean>(
    false
  );
  const [retentionRowToEdit, setRetentionRowToEdit] = useState<
    Partial<TransactionDynamo>
  >({});
  const [openRejectModal, setOpenRejectModal] = useState<boolean>(false);
  const [
    openProcessRetentionModal,
    setOpenProcessRetentionModal,
  ] = React.useState<boolean>(false);
  const [checkSelectedRow, setCheckSelectedRow] = useState<object>();
  const [modalInfo, setModalInfo] = useState<IModalInfo>({
    title: "",
    body: "",
    confirm: "",
    accept: "",
    label: "",
  });
  const [params, setParams] = useState<RentetionDataRequest>({
    action: "listRetentions",
    data: {
      country: "",
      statusFlow: "",
      type: [],
    },
  });
  const [sort, setSort] = useState<Partial<ISortable>>({
    order: OrderEnum.asc,
    orderBy: "merchantName",
  });
  const [trxRetention, SetTrxBilling] = useState<{
    trx: TransactionTable | undefined;
    index: number;
  }>({ trx: undefined, index: 0 });
  const [openModalRetention, SetOpenModalRetention] = useState<boolean>(false);
  const handlerCloseModalTrxRetention = () => {
    SetOpenModalRetention(false);
    SetTrxBilling({ trx: undefined, index: 0 });
  };
  const handlerSelectTrxRetention = (
    row: TransactionTable | undefined,
    index: number
  ) => {
    SetOpenModalRetention(true);
    SetTrxBilling({ trx: row, index });
  };
  const handleModalModifyInconsistence = (value: boolean) => {
    dispatch(setOpenModalModifyInconsistence(value));
  };

  const { openModalModifyInconsistence } = useSelector(
    (state: IBillingDashboardState) => state
  );

  const getUserRoleName = (): UserRolesEnum[] => {
    let roles: UserRolesEnum[] = [];

    if (isBackofficeFinExecutor)
      roles.push(UserRolesEnum.BackofficeFinExecutor);
    if (isBackofficeFinValidator)
      roles.push(UserRolesEnum.BackofficeFinValidator);
    if (isBackofficeAdmin) roles.push(UserRolesEnum.BackofficeAdmin);
    return roles;
  };

  const getDefaultFormValues = (): IModifyRetentionAmountForm => {
    return {
      modifyReason: get(retentionRowToEdit, "modify.reason_change_amount"),
      retentionFue: get(retentionRowToEdit, "modify.modifyAmount.retFue"),
      retentionIva: get(retentionRowToEdit, "modify.modifyAmount.retIva"),
      retentionTotal: get(
        retentionRowToEdit,
        "modify.modifyAmount.retTotalAmount"
      ),
    };
  };

  const editForm: UseFormMethods<IModifyRetentionAmountForm> = useForm<IModifyRetentionAmountForm>(
    {
      defaultValues: {
        ...getDefaultFormValues(),
      },
      mode: "onBlur",
      reValidateMode: "onBlur",
    }
  );

  const findDataRetention = (payload: Partial<RentetionDataRequest>) => {
    let paramsSearch: RentetionDataRequest | ListRetentionRequest = {
      ...params,
      ...payload,
    };
    dispatch(getRetentionTrxPreProccessed(validateParams(paramsSearch)));
  };
  const isFirstRunDate = useRef(true);
  const [rangeDate, setRangeDate] = useState<DateRange>([
    startOfDay(subDays(new Date(), 1)),
    endOfDay(new Date()),
  ]);
  const handleSetDate = (dateRange: DateRange) => {
    setRangeDate(dateRange);
    const [firstDate, secondDate]: [
      MaterialUiPickersDate,
      MaterialUiPickersDate
    ] = dateRange;
    const startDate: Date = new Date(firstDate?.toISOString()!);
    const endDate: Date = new Date(secondDate?.toISOString()!);

    const dateParams: RentetionDataRequest = {
      ...params,
      data: {
        ...params.data,
        from: format(startOfDay(startDate), "yyyy-MM-dd"),
        to: format(endOfDay(endDate), "yyyy-MM-dd"),
      },
    };

    dateParams.data.type = get(params, "data.type", []);
    dateParams.data.type?.push("dateRange");

    setParams(dateParams);
  };

  const handleSearchBar = (value: string) => {
    let aux_params = cleanParameters();
    if (!isEmpty(value))
      isColombia(country as CountryEnum)
        ? (aux_params = {
            ...aux_params,
            data: {
              ...aux_params.data,
              merchantIdSocialName: value,
              type: defaultTo(aux_params.data.type, []).concat([
                "merchantIdSocialName",
              ]),
            },
          })
        : (aux_params = {
            ...aux_params,
            data: {
              ...aux_params.data,
              merchantTax: value,
              type: defaultTo(aux_params.data.type, []).concat(["merchantTax"]),
            },
          });
    dispatch(setProcessData(true));

    setParams(aux_params);
  };

  const handleInputChange = (value: string) => {
    dispatch(setProcessData(false));
    setQueryInput(value);
    if (isEmpty(value)) handleSearchBar("");
  };

  const handleGetFilters = (
    optionsChip: IChipSelect[],
    rangeAmount?: RangeAmount
  ) => {
    dispatch(setProcessData(false));
    const chips_selected: IChipSelect[] = optionsChip.filter(
      (chip: IChipSelect) => chip.selected
    );
    let export_filter_selected: {
      filter: object;
      rangeAmount?: { min?: number; max?: number; eq?: number };
    } = { filter: {} };
    filtersDashboard.forEach((filter: IOptionFilter) => {
      const filter_parent: IChipSelect[] = chips_selected.filter(
        (f: IChipSelect) => f.filter === filter.id
      );
      const filter_keys: string[] = filter_parent.map(
        (f: IChipSelect) => f.key
      );
      filter.options.forEach(
        (option: { key: string; label: string; selected: boolean }) =>
          (option.selected = filter_keys.includes(option.key))
      );
      export_filter_selected.filter[filter.id] = filter_keys.join("|");
    });
    handleSearchFilter(export_filter_selected, filtersDashboard, rangeAmount);
  };
  const handleSearchFilter = (
    _filters: { filter: object },
    filtersChips: IOptionFilter[],
    rangeAmount?: RangeAmount
  ) => {
    let aux_params: RentetionDataRequest = cloneDeep(params);
    setRange(rangeAmount!);
    setFilterDashboard(filtersChips);

    if (get(rangeAmount, "value", undefined) === undefined) {
      setRange(
        (rangeAmount = {
          type: "min",
          min: undefined,
          max: undefined,
          value: undefined,
          eq: undefined,
        })
      );
    }

    let aux = omitBy(_filters.filter, isEmpty);

    let type: string[] = uniq([...keys(aux)]);

    let data: object = pick(aux_params.data, [...keys(aux)]);

    aux_params = {
      ...aux_params,
      data: {
        ...data,
        ...aux,
        type: type,
        country: country as string,
        statusFlow: statusFlow as string,
      },
    };

    if (get(rangeAmount, "value") !== undefined)
      set(aux_params, "data.rangeAmount", rangeAmount);
    setParams(aux_params);
  };

  const updateSelectedTrx = (
    trx: TransactionDynamo,
    selectedStatus: string,
    reason?: string
  ): TransactionDynamo => {
    if (selectedStatus === StatusEnum.OMIT) {
      set(trx, "omit.reason", reason);
    } else {
      trx = omit(trx, "reject") as TransactionDynamo;
    }
    if (selectedStatus === StatusEnum.REJECT) {
      set(trx, "reject.reason", reason);
    }
    set(trx, "selectedStatus", selectedStatus);
    return trx;
  };

  const updateTrxSelectedStatus = (
    type: CellTypeEnum,
    status: string,
    row?: object,
    reason?: string
  ) => {
    let aux_data: RentetionData = cloneDeep(retentionData);
    let selectedStatus: string = status;
    if (
      isEmpty(status) &&
      get(row, "status", "") !== StatusEnum.FAILED &&
      statusFlow === StatusFlowEnum.VALIDATOR
    ) {
      selectedStatus = StatusEnum.PENDING;
    }

    if (isEmpty(status) && statusFlow === StatusFlowEnum.EXECUTOR) {
      selectedStatus = "";
    }

    aux_data.records = aux_data.records.map((trx: TransactionDynamo) => {
      if (
        type === CellTypeEnum.cell &&
        trx.transactionId === get(row, "transactionId")
      ) {
        trx = updateSelectedTrx(trx, selectedStatus, reason);
      }
      if (type === CellTypeEnum.header) {
        trx = updateSelectedTrx(trx, selectedStatus, reason);
      }
      return trx;
    });
    dispatch(setRetentionData({ ...aux_data }));
  };

  const rejectModalInfo = (type: CellTypeEnum, status: StatusEnum) => {
    let modalInf: IModalInfo =
      ModalInfo[ModalTypeEnum.REJECT_MODAL_RETENTION][
        defaultTo(country, CountryEnum.ecuador)
      ][
        type === CellTypeEnum.cell
          ? ModalInfoQuantity.SINGLE
          : ModalInfoQuantity.MULTIPLE
      ];
    if (status === StatusEnum.OMIT) {
      modalInf =
        ModalInfo[ModalTypeEnum.OMIT_MODAL_RETENTION][
          type === CellTypeEnum.cell
            ? ModalInfoQuantity.SINGLE
            : ModalInfoQuantity.MULTIPLE
        ];
    }
    setModalInfo(modalInf);
  };

  const handleChangeChecbox = (
    type: CellTypeEnum,
    status: string,
    row?: object
  ) => {
    setCheckModalStatus(status);
    if (status === StatusEnum.REJECT) {
      rejectModalInfo(type, status);
      setOpenRejectModal(true);
      setCheckSelectedRow({
        type,
        data: row,
      });
      return;
    }
    if (status === StatusEnum.OMIT) {
      rejectModalInfo(type, status);
      setOpenRejectModal(true);
      setCheckSelectedRow({
        type,
        data: row,
      });
      return;
    }
    updateTrxSelectedStatus(type, status, row);
  };

  const handleSort = (order: OrderEnum, field: string) => {
    let aux_data: RentetionData = cloneDeep(retentionData);
    setSort({ ...sort, order, orderBy: field });
    set(
      aux_data,
      "records",
      order === OrderEnum.asc
        ? sortBy(aux_data.records, [field])
        : sortBy(aux_data.records, [field]).reverse()
    );
    dispatch(setRetentionData({ ...aux_data }));
  };
  const validateParams = (paramsData: RentetionDataRequest) => {
    let aux_params: RentetionDataRequest = cloneDeep(paramsData);
    let set_status: boolean = true;

    if (
      indexOf(defaultTo(aux_params.data.type, []), calculateCycleField) ===
        -1 &&
      ![CountryEnum.colombia].includes(country as CountryEnum)
    ) {
      set(aux_params, `data.${calculateCycleField}`, getCalculateCycle());
      set(
        aux_params,
        "data.type",
        defaultTo(aux_params.data.type, []).concat([calculateCycleField])
      );
    }

    if (indexOf(defaultTo(aux_params.data.type, []), statusField) === -1) {
      set(aux_params, `data.${statusField}`, getCurrentStatus(statusFlow!));
      set(
        aux_params,
        "data.type",
        defaultTo(aux_params.data.type, []).concat([statusField])
      );
      set_status = false;
    }
    if (set_status)
      set(aux_params, "data.status", get(aux_params, "data.status", ""));
    return aux_params;
  };
  const isColombia = (country: CountryEnum) => {
    return CountryEnum.colombia === country;
  };
  const cleanParameters = () => {
    let aux_params = cloneDeep(params);
    aux_params = {
      ...aux_params,
      data: {
        ...aux_params.data,
        type: without(
          defaultTo(aux_params.data.type, []),
          "merchantTax",
          "merchantIdSocialName"
        ),
      },
    };
    set(aux_params, "data", {
      ...omit(aux_params.data, "merchantTax", "merchantIdSocialName"),
    });
    return aux_params;
  };
  const handleCloseRejectModal = () => {
    setOpenRejectModal(false);
  };
  const handleAcceptRejectModal = (reason: string) => {
    updateTrxSelectedStatus(
      get(checkSelectedRow, "type"),
      checkModalStatus,
      get(checkSelectedRow, "data"),
      reason
    );
    setOpenRejectModal(false);
  };

  const handleCloseProcessLoader = () => {
    dispatch(setProcessLoader(false));
  };

  const handleAcceptProcessTransactions = () => {
    let aux_data: RentetionData = cloneDeep(retentionData);

    aux_data.records = aux_data.records
      .map((trx: TransactionDynamo) => {
        set(trx, "status", trx.selectedStatus);
        return trx;
      })
      .filter((trx: TransactionDynamo) => !isEmpty(trx.status));

    setOpenProcessRetentionModal(false);
    dispatch(setProcessLoader(true));
    dispatch(processRetentionsData(aux_data, false, true));
  };

  const handleCloseProcessRetentionsModal = () => {
    setOpenProcessRetentionModal(false);
  };

  const handleProcessRetentionData = () => {
    setOpenProcessRetentionModal(true);
  };

  const onEdit = (_row: TransactionDynamo) => {
    setRetentionRowToEdit(_row);
    handleOpenEditRetention(true);
  };
  const onSubmit = (dataForm: IModifyRetentionAmountForm) => {
    let aux_data: RentetionData = cloneDeep(retentionData);
    const { index, recordToReplace } = findTransactionToReplace(
      aux_data.records,
      retentionRowToEdit
    );

    if (index >= 0 && recordToReplace) {
      aux_data.records[index] = setNewRetentionValues(
        dataForm,
        recordToReplace
      );
      dispatch(setRetentionData({ ...aux_data }));
    }
    handleOpenEditRetention(false);
  };

  const setNewRetentionValues = (
    dataForm: IModifyRetentionAmountForm,
    recordToReplace: TransactionDynamo
  ): TransactionDynamo => {
    const initialAmount: IModifyRetentionValues = {
      retIva: defaultTo(Number(recordToReplace.retIva), 0),
      retFue: defaultTo(Number(recordToReplace.retFue), 0),
      retTotalAmount: defaultTo(Number(recordToReplace.retTotalAmount), 0),
    };
    const modifyAmount: IModifyRetentionValues = {
      retIva: Number(dataForm.retentionIva),
      retFue: Number(dataForm.retentionFue),
      retTotalAmount: Number(dataForm.retentionTotal),
    };
    return {
      ...recordToReplace,
      ...modifyAmount,
      modify: {
        initialAmount,
        modifyAmount,
        reason_change_amount: dataForm.modifyReason,
      },
      isEdited: true,
    };
  };

  const findTransactionToReplace = (
    records: TransactionDynamo[],
    transaction: Partial<TransactionDynamo>
  ): { index: number; recordToReplace?: TransactionDynamo } => {
    let index = -1;
    const recordToReplace: TransactionDynamo | undefined = records.find(
      (element: TransactionDynamo, i: number) => {
        const conditionToBreak: boolean =
          element.transactionId === transaction.transactionId;
        if (conditionToBreak) {
          index = i;
        }
        return conditionToBreak;
      }
    );
    return { index, recordToReplace };
  };

  const handleOpenEditRetention = (state: boolean): void => {
    setOpenEditRetentionModal(state);
  };

  const [selectedColumnsRetention, setSelectedColumnsRetention] = useState<
    string[]
  >(
    country === CountryEnum.colombia
      ? defaultRetentionColumnsSelectableColombia
      : defaultRetentionColumnsSelectable
  );

  const restoreRetention = () => {
    setSelectedColumnsRetention(
      country === CountryEnum.colombia
        ? defaultRetentionColumnsSelectableColombia
        : defaultRetentionColumnsSelectable
    );
  };

  const downloadFile = () => {
    const filters: number = get(params, "data.type", []).length;

    if (filters !== 0 && country === CountryEnum.colombia) {
      setIsLoadingTransactionTableDownloadFile(true);
      const rolesName: UserRolesEnum[] = getUserRoleName();
      dispatch(
        downloadTransactionUrlRol(
          CountryEnum.colombia,
          format(new Date(), "yyyy-MM-dd"),
          statusFlow!,
          rolesName,
          params
        )
      );
    } else {
      setIsLoadingTransactionTableDownloadFile(true);
      dispatch(
        downloadTransactionTableFile(
          country ? country : CountryEnum.ecuador,
          format(new Date(), "yyyy-MM-dd"),
          statusFlow!,
          undefined,
          KindEnum.RETENTION_EC
        )
      );
    }
  };

  const downLoadFileDashboard = (
    name: string,
    extension: string,
    base64Dada: string
  ) => {
    let a = document.createElement("a");
    a.href = `data:aplication/${extension};base64,` + base64Dada;
    a.download = name;
    a.click();
    a.remove();
  };

  const handleOpenDialogDetail = (data: IStepRetentionTimeLine) => {
    dispatch(setStepTimeLineData(data));
    dispatch(setOpenModalDetailTimeLine(true));
  };

  const handleCloseDialogDetail = () => {
    dispatch(setOpenModalDetailTimeLine(false));
  };

  useEffect(() => {
    if (!isNil(country)) {
      setRangeDate([startOfDay(subDays(new Date(), 1)), endOfDay(new Date())]);
      isFirstRunDate.current = true;
      let index: number | undefined = params.data.type?.indexOf("dateRange");
      let indexRejected: number | undefined = params.data.status?.indexOf(
        "rejected"
      );
      if (params.data.from) delete params.data.from;
      if (params.data.to) delete params.data.to;
      if (indexRejected) params.data.status?.splice(indexRejected, 1);
      if (index) params.data.type?.splice(index, 1);
      setParams({
        ...params,
        data: {
          ...params.data,
          country: country as string,
          statusFlow: statusFlow as string,
        },
      });
    }
    setFilterDashboard(getFilterOptions(country!, statusFlow!));
    if (country === CountryEnum.colombia)
      setSelectedColumnsRetention(defaultRetentionColumnsSelectableColombia);
    else setSelectedColumnsRetention(defaultRetentionColumnsSelectable);
  }, [country, statusFlow]);
  useEffect(() => {
    if (
      [CountryEnum.ecuador, CountryEnum.colombia].includes(
        country as CountryEnum
      ) &&
      !isNil(country) &&
      params.data.country === country &&
      params.data.statusFlow === statusFlow
    ) {
      findDataRetention(params);
    }
  }, [params]);

  useEffect(() => {
    if (pathLoadingRetention === StatusFlowPath.RELOAD) {
      findDataRetention(params);
      dispatch(setPathLoadingRetention(StatusFlowPath.DEFAULT));
    }
  }, [pathLoadingRetention]);

  useEffect(() => {
    if (isLoadingTransactionTableDownloadFile) {
      setIsLoadingTransactionTableDownloadFile(false);
      if (transactionTableFileDownload.base64.length > 0)
        downLoadFileDashboard(
          `retentions_${format(new Date(), "yyyy-MM-dd")}.csv`,
          "csv",
          transactionTableFileDownload.base64
        );
      transactionTableFileDownload.base64 = "";
    }
  }, [transactionTableFileDownload]);

  useEffect(() => {
    if (country === CountryEnum.ecuador)
      dispatch(
        getCatalogsList({
          productLine: ProductLineEnum.PLA,
          configCode: ConfigCodeEnum.BILLING,
          countryCode: CountryCodeEnum.EC,
        })
      );
    if (country === CountryEnum.chile)
      dispatch(
        getCatalogsList({
          productLine: ProductLineEnum.PLA,
          configCode: ConfigCodeEnum.DISPERSION,
          countryCode: CountryCodeEnum.CL,
          name: CatalogsNameEnum.BANK_LIST_DISPERSION,
        })
      );
  }, [country]);

  return {
    retentionData,
    handleSearchBar,
    handleInputChange,
    handleSetDate,
    rangeDate,
    queryInput: queryInput,
    handleChangeChecbox,
    editForm,
    onSubmit,
    sort: { ...sort, handleSort } as ISortable,
    onEdit,
    openModalModifyInconsistence,
    handleModalModifyInconsistence,
    columnsSettings: {
      selectedColumns: selectedColumnsRetention,
      setSelectedColumns: setSelectedColumnsRetention,
      restoreRetention: restoreRetention,
    },
    filter: {
      filtersDashboard,
      rangeAmount: range,
      handleGetFilters,
    },
    modalHandlers: {
      handleOpenEditRetention,
      openEditRetentionModal,
      retentionRowToEdit,
    },
    modalOverRetention: {
      openModalRetention,
      handlerCloseModalTrxRetention,
      handlerSelectTrxRetention,
      trxRetention,
    },
    modalOverReject: {
      openRejectModal,
      handleAcceptRejectModal,
      handleCloseRejectModal,
      modalInfo,
    },
    modalOverProcessRetention: {
      openProcessRetentionModal,
      handleCloseProcessRetentionsModal,
      handleAcceptProcessTransactions,
      handleProcessRetentionData,
      handleCloseProcessLoader,
      retentionsDataCounter,
      processLoader,
    },
    modalOverDetail: {
      handleCloseDialogDetail,
      handleOpenDialogDetail,
    },
    isProcessData: processData,
    downloadFileRetention: {
      isLoading: isLoadingTransactionTableDownloadFile,
      downloadFile,
    },
  };
};
