import { ConfigurationIdEnum, Section } from "../enums";

export const SECTION_CONFIGURATIONS: {
  [key in Section]: ConfigurationIdEnum[];
} = {
  [Section.DEVELOPERS]: [ConfigurationIdEnum.CN014],
  [Section.COMPANY]: [
    ConfigurationIdEnum.CN001,
    ConfigurationIdEnum.CN002,
    ConfigurationIdEnum.CN015,
    ConfigurationIdEnum.CN016,
  ],
  [Section.USERS]: [ConfigurationIdEnum.CN019],
  [Section.FINANCIAL]: [
    ConfigurationIdEnum.CN003,
    ConfigurationIdEnum.CN017,
    ConfigurationIdEnum.CN004,
  ],
  [Section.PROCESSING]: [ConfigurationIdEnum.CN007],
  [Section.PROCESSING_BUSINESS_RULES]: [ConfigurationIdEnum.CN008],
  [Section.PROCESSING_DEFERRED]: [ConfigurationIdEnum.CN010],
  [Section.SERVICES]: [ConfigurationIdEnum.CN011],
  [Section.RATES_DISCOUNTS]: [
    ConfigurationIdEnum.CN005,
    ConfigurationIdEnum.CN006,
  ],
  [Section.WALLET]: [],
};
