import { useEffect, useState } from "react";
import {
  sendRuleRequest,
  setDetailCatalogGeneralRules,
  setIsLoading,
  setSuccessfulEndpointCall,
} from "../../../store/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import { RuleGeneralRequest } from "../../../../types/search_rule_catalog_response";
import { IAppState } from "../../../store/reducer";
import { defaultTo, get, isEmpty, isUndefined, set } from "lodash";
import { Routes } from "../../../shared/infrastructure/routes";
import { IConditionForm } from "../../../shared/infrastructure/interfaces/ISecurityRuleForm";
import {
  buildGenerateConditions,
  buildObjectToResetForm,
  mapRules,
  transformCardName,
} from "../../../utils/utilsFile";
import { RulesSchema } from "../../../shared/infrastructure/enums/CreateRuleConstans";
import { RuleRequest } from "../../../../types/rule_request";
import { FieldsRule } from "../../../shared/infrastructure/constants/RuleRequestConstants";
import {
  ACTION_VALUE,
  GeneralActionEnum,
  VALID_GENERAL_ACTIONS,
  Values,
} from "../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import {
  HierarchyPathsEnum,
  PathsEnum,
} from "../../../shared/infrastructure/enums/PathsEnum";
import { RuleDetail } from "../../../../types/rule_detail";
import { Condition } from "../../../../types/security_rule_data";
import { useHistory } from "react-router";

export interface IUseCreateRuleIndexState {
  handle: {
    handleDeleteSelectedRule: () => void;
    handleCancel: () => void;
    handleSendToReview: () => void;
  };
  isSelectedRule: boolean;
  selectedRule: RuleGeneralRequest | undefined;
  fields: Object[] | undefined;
}

export const useCreateRuleIndexState = (): IUseCreateRuleIndexState => {
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedRule: RuleGeneralRequest | undefined = useSelector(
    (state: IAppState) => state.detailCatalogGeneralRules
  );
  const [isSelectedRule, setIsSelectedRule] = useState<boolean>(false);
  const [fields, setFields] = useState<Condition[]>([
    { value: [""], label: "", condition: "" },
  ]);
  const [specialConditionFields, setSpecialConditionFields] = useState<
    Condition[]
  >([{ value: [""], label: "", condition: "" }]);
  const ruleDetails: RuleDetail | undefined = useSelector(
    (state: IAppState) => state.ruleDetails
  );

  const successfulEndpointCall: boolean = useSelector((state: IAppState) =>
    defaultTo(state.successfulEndpointCall, false)
  );

  const handleDeleteSelectedRule = (): void => {
    dispatch(setDetailCatalogGeneralRules(undefined));
    setIsSelectedRule(false);
  };

  const handleCancel = (): void => {
    window.location.href = Routes.BASE_PATH_RULE_REQUEST_MANAGER;
  };
  const handleSendToReview = (): void => {
    dispatch(setIsLoading(true));

    const maxAmount: string = get(
      selectedRule,
      PathsEnum.MAX_AMOUNT,
      ""
    ).toString();
    const request: RuleRequest = {
      status: FieldsRule.STATUS_PENDING_CREATED,
      requestType: FieldsRule.REQUEST_TYPE_CREATION,
      entityName: get(ruleDetails, "entityName", "").toLowerCase(),
      configType: FieldsRule.CONFIG_TYPE,
      merchantName: get(ruleDetails, "merchantName", ""),
      merchantId: get(ruleDetails, "merchantId", ""),
      country: get(ruleDetails, "country", []),
      regions: [],
      continents: [],
      subType: get(ruleDetails, "subType", ""),
      service: get(ruleDetails, "service", ""),
      rule: {
        action:
          ACTION_VALUE[
            `${defaultTo(get(selectedRule, "rule.generalAction"), "")}${
              defaultTo(get(selectedRule, "rule.generalAction"), "") ===
              GeneralActionEnum.AUTHENTICATE
                ? `_${defaultTo(get(selectedRule, PathsEnum.PROVIDER), "")}`
                : ""
            }`
          ],
        generalAction: defaultTo(get(selectedRule, "rule.generalAction"), ""),
        code: defaultTo(get(selectedRule, "rule.code"), ""),
        message: defaultTo(get(selectedRule, "rule.message"), ""),
        name: defaultTo(get(selectedRule, "rule.name"), ""),
        kind: defaultTo(
          get(selectedRule, "rule.kind"),
          Values.RULES_KIND_STRICT
        ),
        hasSpecialCond: get(selectedRule, PathsEnum.HAS_SPECIAL_COND, false),
        limit: get(selectedRule, PathsEnum.LIMIT, "").toString(),
        maxAmount: maxAmount !== "" ? maxAmount : undefined,
        maxAmountCurrency: defaultTo(
          get(selectedRule, PathsEnum.MAX_AMOUNT_CURRENCY),
          ""
        ),
        periodicitySum: defaultTo(
          get(selectedRule, PathsEnum.PERIODICITY_SUM),
          ""
        ),
        periodicityCount: defaultTo(
          get(selectedRule, PathsEnum.PERIODICITY_COUNT),
          ""
        ),
        transactionStatus: defaultTo(
          get(selectedRule, "speedCondition.stateTrx"),
          ""
        ),
        transactionStatusCount: defaultTo(
          get(selectedRule, PathsEnum.TRANSACTION_STATUS_COUNT),
          ""
        ),
        transactionStatusSum: defaultTo(
          get(selectedRule, PathsEnum.TRANSACTION_STATUS_SUM),
          ""
        ),
        ignore3DS: Boolean(get(selectedRule, "ignore3DS")),
      },
    };

    const generalAction: string = defaultTo(
      get(selectedRule, "rule.generalAction"),
      ""
    );

    if (VALID_GENERAL_ACTIONS.includes(generalAction as GeneralActionEnum))
      set(
        request,
        PathsEnum.PROVIDER,
        defaultTo(get(selectedRule, PathsEnum.PROVIDER), "")
      );

    if (!isUndefined(get(ruleDetails, "entityName")))
      request.entityName = get(ruleDetails, "entityName", "").toLowerCase();

    if (!isUndefined(get(ruleDetails, "nodeId")))
      request.nodeId = get(ruleDetails, "nodeId");

    if (!isUndefined(get(ruleDetails, "rootId")))
      request.rootId = get(ruleDetails, "rootId");

    if (get(request, PathsEnum.RULE_KIND) != Values.RULES_KIND_SPEED) {
      delete request[PathsEnum.LIMIT];
      delete request[PathsEnum.MAX_AMOUNT];
    }

    if (specialConditionFields.length > 0)
      fields.push(...specialConditionFields);

    const ruleRequestToSend: RuleRequest = mapRules(fields, request);
    const cardHolderName: string = get(
      ruleRequestToSend,
      PathsEnum.TRANSACTION_CARD_HOLDER_NAME
    );
    if (!isEmpty(cardHolderName)) {
      const splitCardHolderName: string[] = cardHolderName.split("|");
      set(
        ruleRequestToSend,
        PathsEnum.TRANSACTION_CARD_HOLDER_NAME,
        defaultTo(splitCardHolderName[0], "") +
          "|" +
          concatenateCardHolderName(defaultTo(splitCardHolderName[1], ""))
      );
    }
    dispatch(sendRuleRequest(ruleRequestToSend));
  };
  const concatenateCardHolderName = (cardHolderName: string): string => {
    const cardHolderNames: string[] = cardHolderName.split(",");

    return cardHolderNames
      .map((name: string) => transformCardName(name))
      .join(",");
  };

  useEffect(() => {
    setIsSelectedRule(!isEmpty(selectedRule));
  }, [selectedRule]);

  useEffect(() => {
    if (selectedRule) {
      const generatedCondition: IConditionForm[] = [];
      const generatedSpecialCondition: IConditionForm[] = [];
      const ruleSchema: RulesSchema = RulesSchema.RULEGENERAL;

      buildGenerateConditions(
        selectedRule,
        generatedCondition,
        ruleSchema,
        generatedSpecialCondition
      );
      buildObjectToResetForm(
        selectedRule,
        generatedCondition,
        generatedSpecialCondition,
        "ruleName",
        ruleSchema,
        undefined,
        undefined,
        undefined,
        HierarchyPathsEnum.SECURITY_RULE
      );
      generatedCondition.push({ label: "", operator: "", value: "" });
      const conditions: Condition[] = generatedCondition.map((item) => {
        return {
          operator: item.operator,
          label: item.label,
          value: typeof item.value !== "string" ? item.value : [item.value],
        };
      });

      setFields(conditions);

      const specialConditions: Condition[] = generatedSpecialCondition.map(
        (item: IConditionForm) => {
          return {
            operator: item.operator,
            label: item.label,
            value: item.value as string[],
          };
        }
      );

      setSpecialConditionFields(specialConditions);
    }
  }, [selectedRule]);

  useEffect(() => {
    if (successfulEndpointCall) {
      history.push(Routes.BASE_PATH_RULE_REQUEST_MANAGER);
    }
    dispatch(setSuccessfulEndpointCall(false));
  }, [successfulEndpointCall]);
  return {
    handle: {
      handleDeleteSelectedRule,
      handleCancel,
      handleSendToReview,
    },
    isSelectedRule,
    selectedRule,
    fields,
  };
};
