import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import { environment } from "./environments/environment";
import { ComponentIdEnum } from "./shared/enums/componentIdEnum";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
import InternationalContainer from "./containers/InternationalContainer/InternationalContainer";

const Navigation = () => {
  return (
    <>
      <BrowserRouter>
        <ModuleSecurityWrapper
          basePath={environment.kushkiUrl}
          componentId={ComponentIdEnum.M_TEST}
        >
          <Routes>
            <Route path={"*"} element={<InternationalContainer />} />
          </Routes>
        </ModuleSecurityWrapper>
      </BrowserRouter>
    </>
  );
};

export default connect()(Navigation);
