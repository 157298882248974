import { ModalLoader } from "@kushki/connect-ui";
import React, { FC } from "react";
import { Box } from "@mui/material";
import { ContactDataCard } from "../../components/ContactDataCard/ContactDataCard";
import { contactDataContainerStyles as styles } from "./ContactDataContainer.styles";
import { useContactDataContainerMassive } from "./state/useContactDataContainerMassive";
import { LayoutStepContainerBranch } from "../LayoutStepContainerBranch/LayoutStepContainerBranch";
import {
  LOADER_DESCRIPTION,
  LOADER_TITLE,
} from "../../shared/constants/labels/ModalConstants";
import { ContactContainerSkeleton } from "../../components/Skeletons/ContactContainerSkeleton/ContactContainerSkeleton";

const ContactDataContainerMassive: FC = () => {
  const {
    categoryAreas,
    commercialExecutive,
    contactDataAreasProps,
    contactDataModal,
    countriesInfo: { country },
    isEdit,
    dataCompleted,
    dataNoErrors,
    executiveList,
    footerActions,
    form,
    handleCancelModal,
    handleOpenModal,
    headerProps,
    isAreasSelected,
    isEditing,
    merchantSize,
    nameValidation,
    onSubmit,
    openModal,
    openModalLoader,
    isCentralizedBranch,
    isDisableAddPersonBtn,
    handleFormChange,
    isModalFinalization,
    setFinalization,
    saveValues,
    title,
    numberBranches,
    isLoadingContactData,
  } = useContactDataContainerMassive();

  return (
    <>
      <LayoutStepContainerBranch
        headerProps={{
          country: country,
          isEdit,
          numberBranches,
          tabsInfo: headerProps.tabsNodeInfo,
          title: `${title}`,
        }}
        renderComponent={
          <Box sx={styles.boxContent}>
            {isLoadingContactData ? (
              <ContactContainerSkeleton />
            ) : (
              <>
                <ContactDataCard
                  handleOpenModal={handleOpenModal}
                  handleCancelModal={handleCancelModal}
                  openModal={openModal}
                  commercialExecutive={commercialExecutive}
                  handleFormChange={handleFormChange}
                  form={form}
                  commercialExecutiveList={executiveList}
                  merchantSize={merchantSize}
                  isEditing={isEditing}
                  contactDataModal={contactDataModal}
                  onSubmit={onSubmit}
                  categoryAreas={categoryAreas}
                  dataCompleted={dataCompleted}
                  dataNoErrors={dataNoErrors}
                  nameValidation={nameValidation}
                  isAreasSelected={isAreasSelected}
                  contactDataAreasProps={contactDataAreasProps}
                  isCentralizedBranch={isCentralizedBranch}
                  isDisableAddPersonBtn={isDisableAddPersonBtn}
                />
                <ModalLoader
                  descriptionText={LOADER_DESCRIPTION}
                  titleText={LOADER_TITLE}
                  isOpen={openModalLoader}
                />
              </>
            )}
          </Box>
        }
        footerProps={{
          isEdit,
          isLoading: footerActions.saveLoader,
          label: footerActions.footerLabel,
          primaryButton: footerActions.primaryButton,
          secondaryButton: footerActions.secondaryButton,
        }}
        modalProps={{
          isModalFinalization: isModalFinalization,
          saveValues: saveValues!,
          setFinalization: setFinalization,
        }}
      />
    </>
  );
};

export default ContactDataContainerMassive;
