export const ADD_WEBHOOK_TITLE = "Agregar webhook";
export const TOOL_TIP_TITLE = "Medio de pago o producto";
export const EDIT_WEBHOOK_TITLE = "Editar webhook";
export const ADD_WEBHOOK_SUBTITLE =
  "Agrega las URLs que desees para la notificación de eventos por cada método de pago o producto.";
export const IDENTIFICATION_LABEL = "Identificación de webhook";
export const ADD_URLS_LABEL = "Agregar URLs";
export const ADD_URLS_INPUT_LABEL = "URLs";
export const ADD_URL_SUBTITLE = "Agrega los URL que apliquen al webhook.";
export const ADD_URL_INFO =
  "Enviaremos una notificacion. Si esta es rechazada, intentaremos nuevamente en intervalos de 30 minutos y máximo de 4 veces. Podrás ver el detalle de cada evento en tu log de webhook.";
export const TEXT_FIELD_LABEL = "Alias";
export const CANCEL_BUTTON_LABEL = "Cancelar";
export const SAVE_BUTTON_LABEL = "Guardar";
export const RETURN_BUTTON_LABEL = "Regresar";
export const MODAL_WEBHOOK_LOADING_LABELS = {
  description: "Este proceso puede tardar unos minutos...",
  title: "Estamos probando las URLs",
};
export const MODAL_WEBHOOK_MODAL_LABELS = {
  buttonClose: "Cancelar",
  buttonSave: "Guardar",
  description:
    "Hemos realizado con éxito las pruebas de la URL que fue configurada para este webhook.",
  title: "Pruebas de URL - Webhooks",
  webhook: "URL",
};
export const MODAL_WEBHOOK_SUBMIT_LABELS = {
  buttonClose: "Cancelar",
  buttonSave: "Confirmar",
  description:
    "*Puedes guardar el Webhook aunque existan URL con un estado de conexión fallida",
  title:
    "Antes de continuar, revisa y confirma los estados de las URL del Webhook",
  webhook: "URL",
};

export const ADD_BUTTON_LABEL = "Agregar";
export const ADVANCED_CONFIGURATION_TITLE = "Configuración avanzada";
export const ADVANCED_CONFIGURATION_SUBTITLE = "Configura los encabezados.";
export const DESCRIPTION_TITLE = "Encabezados";
export const DESCRIPTION =
  "Agrega encabezados personalizados. Configura un label y un string para cada encabezado que añadas. Puedes agregar máximo 4.";
export const LABEL_INPUT_LBL = "Label";
export const VALUE_INPUT_LBL = "Value";
export const COUNTRY_LABEL = "País de operación:";
export const ID_LABEL = "ID:";

export const SNACKBAR = {
  error: "Algo ha fallado, por favor inténtalo nuevamente",
  success: "Se ha creado el webhook exitosamente",
};
