import {
  createStyles,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ModifiedIcon from "../../assets/images/modified-icon.png";
import PendingIcon from "../../assets/images/pending-icon.png";
import React from "react";
import { StatusFlowEnum } from "../../shared/infrastructure/enums/StatusFlowEnum";

const styles = makeStyles((theme: Theme) =>
  createStyles({
    indicatorStyles: {
      minWidth: `${theme.spacing(2)}px`,
    },
    root: {
      marginTop: theme.spacing(3),
    },
  })
);

export interface IIndicatorProps {
  statusFlow: StatusFlowEnum;
}

export const Indicators: React.FC<IIndicatorProps> = (
  props: IIndicatorProps
) => {
  const classes = styles();

  return (
    <React.Fragment>
      <Grid container direction="row" spacing={0} className={classes.root}>
        {props.statusFlow === StatusFlowEnum.EXECUTOR && (
          <Grid item>
            <ListItem disableGutters>
              <ListItemIcon className={classes.indicatorStyles}>
                <img src={ModifiedIcon} alt="modified-icon" />
              </ListItemIcon>
              <ListItemText
                primary={"Transacción con fecha límite cercana para reembolsar"}
              />
            </ListItem>
          </Grid>
        )}
        {props.statusFlow === StatusFlowEnum.EXECUTOR && (
          <Grid item>
            <ListItem>
              <ListItemIcon className={classes.indicatorStyles}>
                <img src={PendingIcon} alt="pending icon" />
              </ListItemIcon>
              <ListItemText
                primary={
                  "La transacción sobrepasa la fecha límite para reembolsar"
                }
              />
            </ListItem>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};
