import React, { FC } from "react";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { ActionButtonsProps } from "./ActionButtons.interfaces";

export const ActionButtons: FC<ActionButtonsProps> = (
  props: ActionButtonsProps
) => {
  return (
    <>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-end"
          direction={"row"}
        >
          <Box mr={3} pt={4}>
            <Button
              size={props.sizeButtons}
              color="primary"
              onClick={props.onCancel}
              disabled={props.isLoading}
            >
              <Typography sx={{ color: "#6D7781" }}>
                {props.backButtonText}
              </Typography>
            </Button>
          </Box>
          <Box pt={4}>
            <Button
              disabled={props.disabled}
              size={props.sizeButtons}
              fullWidth
              variant="contained"
              color="primary"
              onClick={props.onSave}
            >
              {props.saveButtonText}
              {props.isLoading && (
                <Box ml={1} display="flex" alignItems="center">
                  <CircularProgress color="inherit" size={20} />
                </Box>
              )}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
