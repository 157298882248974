import React from "react";
import { ThunkDispatch } from "redux-thunk";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import {
  IRulesState,
  MerchantsData,
  RulesAction,
  getMerchants,
  setIsLoading,
  setNotification,
  createWhitelistRule,
  setMerchants,
  getWhitelistRuleMerchant,
} from "../../store/actionCreator";
import { SnackbarAlert } from "../../components/SnackBarAlert/SnackBarAlert";
import { get } from "lodash";
import {
  INotification,
  RulesNotificationResultEnum,
} from "../../shared/infrastructure/constants/RulesConstants";
import useWhitelistRulesState from "./state/useWhitelistRulesState";
import { FormProvider } from "react-hook-form";
import { WhitelistCardSection } from "../../components/WhitelistCardSection/WhitelistCardSection";
import GeneralInformationSection from "../../components/WhitelistRulesSection/GeneralInformationSection";
import { defaultTo } from "lodash";
import { WhitelistRules } from "../../../types/whitelist_rules";
import { MerchantInformation } from "../../../types/merchant_information";
import { GeneralInformationSkeleton } from "../../components/SecurityRulesSection/Skeletons/GeneralInformationSkeleton";
import { WhitelistRulesTitleSection } from "../../shared/infrastructure/constants/WhitelistRulesConstants";
import { makeStyles } from "@material-ui/core/styles";
import { IWhitelistRuleRequest } from "../../shared/infrastructure/interfaces/IWhitelistRulesForm";

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 300,
    fontStyle: "normal",
    fontSize: "40px",
  },
}));

export interface WhitelistRulesEditStateProps {
  isLoading: boolean | undefined;
  isLoadingGetMerchants: boolean | undefined;
  isLoadingGetRule: boolean | undefined;
  isLoadingGetMerchant: boolean | undefined;
  notification: INotification | undefined;
  merchants: MerchantsData | undefined;
  currentWhitelistRule: WhitelistRules | undefined;
  currentMerchant: MerchantInformation | undefined;
}

export interface WhitelistRulesEditFunctionsProps {
  setIsLoading: (payload: boolean) => RulesAction;
  setNotification: (payload: INotification) => RulesAction;
  setMerchants: (payload: MerchantsData) => RulesAction;
  createWhitelistRule: (payload: IWhitelistRuleRequest) => void;
  searchMerchantsList: (payload: {
    offset: number;
    text?: string;
    limit: number;
  }) => void;
  getWhitelistRuleMerchant: (payload: string) => void;
}

const renderPaddingCardSection = (element: JSX.Element): JSX.Element => {
  return (
    <Grid container style={{ padding: "20px 22px" }}>
      <Grid item xs={12}>
        {element}
      </Grid>
    </Grid>
  );
};

export type WhitelistRulesEditProps = WhitelistRulesEditStateProps &
  WhitelistRulesEditFunctionsProps;

export const WhitelistRulesEdit: React.FC<WhitelistRulesEditProps> = (
  props: WhitelistRulesEditProps
) => {
  const { form, actions, isEdit, textButton, merchantName } =
    useWhitelistRulesState(props);

  const classes = useStyles();

  return (
    <React.Fragment>
      <Container fixed>
        <Box pt={1} pb={3}>
          {!isEdit ? (
            <Typography className={classes.title} color="primary">
              {WhitelistRulesTitleSection.CREATE_WHITELIST_RULES}
            </Typography>
          ) : (
            <Typography className={classes.title} color="primary">
              {merchantName}
            </Typography>
          )}
        </Box>
        <FormProvider {...form}>
          <WhitelistCardSection
            isLoading={defaultTo(props.isLoading, false)}
            sectionComponent={renderPaddingCardSection(
              <GeneralInformationSection
                isLoading={defaultTo(props.isLoading, false)}
                isEdit={isEdit}
                textButton={textButton}
                merchantList={props.merchants!}
                isLoadingMerchantList={defaultTo(
                  props.isLoadingGetMerchants,
                  true
                )}
                handleSetSearchTextMerchantList={
                  actions.handleSetSearchTextMerchantList
                }
                handleEnterSearchTextMerchantList={
                  actions.handleEnterSearchTextMerchantList
                }
                setFormValue={actions.setFormValue}
                setMerchantInformation={actions.setMerchantInformation}
                onCancel={actions.handleCancelAction}
                onAdd={form.handleSubmit(actions.handleSubmitForm)}
              />
            )}
            sectionSkeletonComponent={renderPaddingCardSection(
              <GeneralInformationSkeleton />
            )}
          />
        </FormProvider>
        {get(props, "notification.action") !==
          RulesNotificationResultEnum.NO_ACTION && (
          <SnackbarAlert
            type={get(props, "notification.type")}
            msg={get(props, "notification.message")}
            open={get(props, "notification.open")}
            handlerClose={actions.handleCloseNotification}
          />
        )}
      </Container>
    </React.Fragment>
  );
};

export const mapStateToProps: (
  state: IRulesState
) => WhitelistRulesEditStateProps = (
  state: IRulesState
): WhitelistRulesEditStateProps => ({
  isLoading: state.isLoading,
  isLoadingGetMerchants: state.isLoadingGetMerchants,
  isLoadingGetRule: state.isLoadingGetRule,
  isLoadingGetMerchant: state.isLoadingGetMerchant,
  notification: state.notification,
  merchants: state.merchants,
  currentWhitelistRule: state.currentWhitelistRule,
  currentMerchant: state.currentMerchant,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => WhitelistRulesEditFunctionsProps = (
  dispatch: ThunkDispatch<IRulesState, undefined, RulesAction>
): WhitelistRulesEditFunctionsProps => ({
  createWhitelistRule: (payload: IWhitelistRuleRequest): void =>
    dispatch(createWhitelistRule(payload)),
  searchMerchantsList: (payload: {
    offset: number;
    text?: string;
    limit: number;
  }): void => dispatch(getMerchants(payload)),
  setMerchants: (payload: MerchantsData): RulesAction =>
    dispatch(setMerchants(payload)),
  setIsLoading: (payload: boolean): RulesAction =>
    dispatch(setIsLoading(payload)),
  setNotification: (payload: INotification): RulesAction =>
    dispatch(setNotification(payload)),
  getWhitelistRuleMerchant: (payload: string): void =>
    dispatch(getWhitelistRuleMerchant(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WhitelistRulesEdit);
