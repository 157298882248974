import { makeStyles } from "@mui/styles";

export const processorsStyles = makeStyles({
  mainCard: {
    borderRadius: "15px",
    marginBottom: "100px",
  },

  titleSkeleton: { marginLeft: 47, width: 190, height: 70 },
  subTitleSkeleton: { marginLeft: 47, width: 450, height: 20 },
  subTitleEndSkeleton: { marginLeft: 47, width: 200, height: 20 },
  boxButton: { display: "flex", flexDirection: "row-reverse" },
  buttonProcessorSkeleton: { marginLeft: 20, width: 270, height: 70 },
  linkSkeleton: { marginRight: 47, width: 250, height: 20 },
  accordionSkeleton: {
    marginLeft: 47,
    marginBottom: 5,
    width: "100%",
    height: 130,
  },
  marginBottom: {
    marginBottom: "2rem",
  },
});
