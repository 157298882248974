import { Grid, TextField, Typography } from "@material-ui/core";
import { ConfirmModal } from "../../ConfirmModal/ConfirmModal";
import { dashboardTabsStyles } from "../../DashboardTabs/DashboardTabs.styles";
import { ModalMassiveEnum } from "../../../shared/infrastructure/enums/ModalMassiveEnum";
import React from "react";
import { TransactionData } from "../../../../types/transaction_data";
export interface MassiveTransactionModalProps {
  open: boolean;
  handleClose: () => void;
  handleAccept: () => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  responseParameter: string;
  value: string;
  refundTransactions: TransactionData[];
}

export const MassiveTransactionModal: React.FC<MassiveTransactionModalProps> = (
  props: MassiveTransactionModalProps
) => {
  const {
    open,
    handleClose,
    handleAccept,
    handleChange,
    value,
    responseParameter,
    refundTransactions,
  } = props;
  const classes = dashboardTabsStyles(0);
  const trxNumber = refundTransactions.length;

  return (
    <ConfirmModal
      open={open}
      titleText={ModalMassiveEnum.title}
      contentText={""}
      acceptText={"Guardar"}
      handleClose={handleClose}
      handleAccept={handleAccept}
      acceptColor="primary"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={"body1"} color={"textPrimary"}>
            {responseParameter}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"body1"} color={"textPrimary"}>
            {`${trxNumber} transacciones seleccionadas`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"body2"} color={"textSecondary"}>
            {ModalMassiveEnum.response}
          </Typography>
        </Grid>
        <Grid item xs={12} xl={12} className={classes.textField}>
          <TextField
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            error={String(value).length >= 200 || String(value).length === 0}
            className={classes.ksh_multiline}
            autoComplete="off"
            variant="outlined"
            fullWidth
            label={"Motivo"}
            value={value}
            id="alias"
            multiline
            helperText={
              String(value).length === 0
                ? "campo requerido"
                : `${String(value).length}/200`
            }
            onChange={handleChange}
            rows={3}
          />
        </Grid>
      </Grid>
    </ConfirmModal>
  );
};
