import { IBreadcrumbItem } from "../interfaces/BreadcrumbItem.interfaces";

export const BREADCRUMBS_PAGES: IBreadcrumbItem[] = [
  {
    title: "Inicio",
    to: "/",
  },
  {
    title: "Control de Reembolsos",
    to: "",
  },
];
