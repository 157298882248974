import { createSlice } from "@reduxjs/toolkit";
import { IAppState } from "../../interfaces/AppState.interfaces";
import {
  getMerchantInfo,
  getMerchantNodeInfo,
  getNodeInfo,
} from "../../thunks/app/app.thunks";

export const initialState: IAppState = {
  isLoadingMerchantInfo: false,
  isLoadingNodeInfo: false,
  merchantInfo: {
    accountNumber: "",
    accountType: "0",
    address: "",
    bankId: "",
    chargeFrequency: "none",
    chargeMin: "",
    chargeMinAmount: 0,
    city: "",
    contactPerson: "",
    country: "",
    documentType: "0",
    email: "",
    invoiceFrequency: "none",
    name: "",
    phoneNumber: "",
    province: "",
    publicMerchantId: "",
    socialReason: "",
    taxId: "",
    webSite: "",
    zipCode: "",
  },
  nodeInfo: {
    configCoreId: "",
  },
};

export const appSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getMerchantInfo.pending, (state) => {
        state.isLoadingMerchantInfo = true;
      })
      .addCase(getMerchantInfo.fulfilled, (state, action) => {
        state.merchantInfo = action.payload;
        state.isLoadingMerchantInfo = false;
      })
      .addCase(getMerchantInfo.rejected, (state) => {
        state.isLoadingMerchantInfo = false;
      });
    builder.addCase(getMerchantNodeInfo.fulfilled, (state, action) => {
      state[action.payload.configId] = action.payload.data;
    });
    builder
      .addCase(getNodeInfo.pending, (state) => {
        state.isLoadingNodeInfo = true;
      })
      .addCase(getNodeInfo.fulfilled, (state, action) => {
        state.nodeInfo = action.payload;
        state.isLoadingNodeInfo = false;
      })
      .addCase(getNodeInfo.rejected, (state) => {
        state.isLoadingNodeInfo = false;
      });
  },
  initialState,
  name: "app",
  reducers: {},
});

export default appSlice.reducer;
