import { cloneDeep, isNil, set } from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getCatalog } from "../../../../shared/constants/dispersions/dispersions";
import { CatalogsEnum } from "../../../../shared/enums/CatalogsEnum";
import { CountriesEnum } from "../../../../shared/enums/countriesEnum";
import { BankAccountFields } from "../../../../shared/enums/dispersionsEnum";
import { Category } from "../../../../shared/interfaces/category";
import {
  addBankAccount,
  editBankAccount,
  updateBankAccountIndexOnFocus,
} from "../../../../store/actions/charge/charge.actions";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../store/hooks/storeHook";
import { IBankAccount } from "../../../../store/interfaces/dispersion.interfaces";
import { IUseChargeBankAccountFormsModal } from "./useChargeBankAccountFormsModalState.interfaces";
import {
  getChargeBankAccountOrder,
  getInitialChargeBankAccountValues,
  validateIfHasMainAccount,
} from "../../../../shared/utils/chargeBankAccountUtils";
import { cleanBankAccountFields } from "../../../../shared/utils/bankAccountUtils";

export const useChargeBankAccountFormsModalState =
  (): IUseChargeBankAccountFormsModal => {
    const dispatch = useAppDispatch();
    const {
      banksAccounts: bankAccountsStore,
      constitutionalCountry,
      beneficiaryNameFromBasicDetails,
      bankAccountIndexOnFocus,
    } = useAppSelector((state) => state.charge);

    const {
      control,
      formState: { errors },
      getValues,
      resetField,
      setValue,
      reset,
      handleSubmit,
      clearErrors,
    } = useForm<IBankAccount>({
      defaultValues: getInitialChargeBankAccountValues({
        bankAccounts: bankAccountsStore,
        beneficiaryNameFromBasicDetails,
        constitutionalCountry,
      }),
      mode: "onBlur",
      reValidateMode: "onBlur",
    });
    const [isDifferentConstitutionCountry, setisDifferentConstitutionCountry] =
      useState<boolean>(false);
    const [isEditingForm, setIsEditingForm] = useState(false);
    const [hasMainAccount, setHasMainAccount] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [bankAccountIndex, setBankAccountIndex] = useState<number>(
      bankAccountsStore.length
    );
    const [openBankAccountModal, setOpenBankAccountModal] =
      useState<boolean>(false);
    const [country, setCountry] = useState<string>(constitutionalCountry);

    const getCatalogToRender = (country: string, Catalog: CatalogsEnum) => {
      return getCatalog(country as CountriesEnum, Catalog);
    };

    const [banksList, setBanksList] = useState<Category[]>(
      getCatalogToRender(country, CatalogsEnum.Banks)
    );

    const [currenciesList, setCurrenciesList] = useState<Category[]>(
      getCatalogToRender(country, CatalogsEnum.Currency)
    );

    const changeCountry = (country: string) => {
      setCountry(country);
      setValue(BankAccountFields.KEY_ACCOUNT_NUMBER, null as never);
      setValue(BankAccountFields.SWIFT_CODE, null as never);
      setValue(BankAccountFields.IBAN_CODE, null as never);
      setValue(BankAccountFields.ROUTING_NUMBER, null as never);
      setValue(BankAccountFields.ABA_CODE, null as never);
      setValue(BankAccountFields.BANK_ID, null as never);
      setValue(BankAccountFields.FINANCIAL_INSTITUTION_ADDRESS, null as never);
    };

    const countryIs = (countryExpected: CountriesEnum) =>
      country.toUpperCase() === countryExpected.toUpperCase();

    const [orderAccountState, setOrderAccountState] = useState(
      getChargeBankAccountOrder(bankAccountsStore)
    );

    const handleEditBankAccount = (index: number) => {
      setIsEditingForm(true);
      setBankAccountIndex(index);
      setCountry(bankAccountsStore[`${index}`].country);
      reset(bankAccountsStore[index]);
      setOrderAccountState(bankAccountsStore[`${index}`].accountOrder);
      setOpenBankAccountModal(true);
    };

    const saveBankAccount = (data: IBankAccount) => {
      const cleanData: IBankAccount = cleanBankAccountFields(data);

      setIsLoading(true);

      if (isEditingForm) {
        setIsEditingForm(false);
        const newBanks = set(
          cloneDeep(bankAccountsStore),
          `[${bankAccountIndex}]`,
          cleanData
        );

        dispatch(editBankAccount(newBanks));
        dispatch(updateBankAccountIndexOnFocus(undefined));
      } else {
        dispatch(
          addBankAccount({
            ...cleanData,
            accountOrder: getChargeBankAccountOrder(bankAccountsStore),
          })
        );
      }

      setOpenBankAccountModal(false);
      reset(
        getInitialChargeBankAccountValues({
          bankAccounts: bankAccountsStore,
          beneficiaryNameFromBasicDetails,
          constitutionalCountry,
        })
      );
      setIsLoading(false);
    };

    const closeBankAccountModal = () => {
      setOpenBankAccountModal(false);
      reset();
      setIsEditingForm(false);
      dispatch(updateBankAccountIndexOnFocus(undefined));
    };

    const handleOpenBankAccountModal = () => {
      setOrderAccountState(getChargeBankAccountOrder(bankAccountsStore));
      setCountry(constitutionalCountry);
      if (!isEditingForm) {
        reset(
          getInitialChargeBankAccountValues({
            accountType: "0",
            bankAccounts: bankAccountsStore,
            beneficiaryNameFromBasicDetails,
            constitutionalCountry,
          })
        );
      }
      setOpenBankAccountModal(true);
    };

    useEffect(() => {
      const currenciesNewList: Category[] = getCatalogToRender(
        country,
        CatalogsEnum.Currency
      );
      const banksNewList: Category[] = getCatalogToRender(
        country,
        CatalogsEnum.Banks
      );

      setCurrenciesList(currenciesNewList);
      setBanksList(banksNewList);
      setValue(BankAccountFields.CURRENCY, currenciesNewList[0].value as never);
      setValue(BankAccountFields.COUNTRY, country as never);

      if (
        country.toUpperCase().trim() !==
        constitutionalCountry.toUpperCase().trim()
      ) {
        setisDifferentConstitutionCountry(true);
        if (!isEditingForm) {
          setValue!(BankAccountFields.BENEFICIARY_NAME, "" as never);
        }

        return;
      }

      setisDifferentConstitutionCountry(false);
    }, [country]);

    useEffect(() => {
      if (isNil(bankAccountIndexOnFocus)) return;

      handleEditBankAccount(bankAccountIndexOnFocus);
    }, [bankAccountIndexOnFocus]);

    useEffect(() => {
      setHasMainAccount(validateIfHasMainAccount(bankAccountsStore));
    }, [bankAccountsStore]);

    return {
      addBankAccountSameCountryProps: {
        banksForm: {
          clearErrors,
          control,
          errors,
          getValues,
          resetField,
          setValue,
        },
        banksList,
        changeCountry,
        country,
        countryIs,
        currenciesList,
        getCatalogToRender,
        hasMainAccount,
        orderAccountState,
      },
      closeBankAccountModal,
      handleOpenBankAccountModal,
      isDifferentConstitutionCountry,
      isEditingForm,
      isLoading,
      openBankAccountModal,
      saveBankAccount: handleSubmit(saveBankAccount),
    };
  };
