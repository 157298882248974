import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./reducer/app";
import catalogsReducer from "./reducer/catalogs/catalogs.slice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    catalogs: catalogsReducer,
  },
});
