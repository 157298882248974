import { useAppSelector } from "../../../store/hooks/storeHook";
import {
  COMPONENT,
  MODULE,
} from "../../../shared/constants/labels/modules_container_labels";
import { IModalDialog } from "../ModalDialog.interfaces";
import { TypeEnum } from "../../../shared/enums/typeEnum";

export const useModalDialog = (props: IModalDialog) => {
  const { moduleSelected, roles } = useAppSelector((state) => state.modules);
  const { currentModuleRole } = useAppSelector((state) => state.components);

  const filterRole = currentModuleRole.split(".").slice(0, 2).join(".");
  const filteredRoles =
    props.type == TypeEnum.MODULE
      ? roles!
      : roles!.filter((role) => role.indexOf(filterRole) > -1);

  const labelId =
    "Id del " + `${props.type === TypeEnum.MODULE ? MODULE : COMPONENT}`;
  const labelRolModule = "¿Que rol podrá tener acceso a este módulo?";
  const labelRolComponent = "¿Que roles podrán tener acceso a este componente?";

  return {
    filteredRoles,
    labelId,
    labelRolComponent,
    labelRolModule,
    moduleSelected,
  };
};
