import React from "react";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Container, useMediaQuery, useTheme } from "@material-ui/core";
import { CardSupportUser } from "../../components/CardSupportUser/CardSupportUser";
import { IAppState, INotification } from "../../store/reducer";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  IAppAction,
  redirectZendesk,
  setNotification,
  setRedirectModal,
} from "../../store/actionCreators";
import { connect } from "react-redux";
import { useUserMasterIndexState } from "./state/useUserMasterIndexState";
import { Loader } from "@kushki/frontend-molecules/loader";
import { RedirectModal } from "../../components/RedirectModal/RedirectModal";
import { get } from "lodash";
import zendeskRedirectImage from "../../assets/images/zendeskRedirectImage.svg";
import NotificationSnackbar from "../../components/Notification/NotificationSnackbar";

export interface IUserMasterStateProps {
  state: IAppState;
}

export interface IUserMasterFunctionsProps {
  setNotification: (notify: INotification) => IAppAction;
  redirectZendesk: () => void;
  setRedirectModal: (payload: { show: boolean; url: string }) => IAppAction;
}

export type UserMasterIndexProps = IUserMasterStateProps &
  IUserMasterFunctionsProps;

export const UserMasterIndex: React.FC<UserMasterIndexProps> = (
  props: UserMasterIndexProps
) => {
  const {
    cards,
    handleCloseAccept,
    closeNotification,
  } = useUserMasterIndexState(props);

  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  cards.isMedium = isMedium;

  return (
    <React.Fragment>
      <NotificationSnackbar
        msg={props.state.notification!.message}
        open={props.state.notification!.show}
        type={props.state.notification!.type}
        handlerClose={closeNotification}
      />
      <Container fixed>
        <PageHeader title="Soporte" />
        <CardSupportUser {...cards} />
      </Container>

      <Loader
        {...{
          open: props.state.isLoading || false,
          headerText: "Estamos validando la información",
          bodyText: "Este proceso puede tardar un poco...",
        }}
      />
      <RedirectModal
        open={get(props.state, "redirectModal.show", false)}
        titleText={"Zendesk"}
        subTitleText={""}
        acceptText="Continuar"
        contentText={"Serás redirigido al inicio de Zendesk"}
        handleClose={() => handleCloseAccept("")}
        handleAccept={() =>
          handleCloseAccept(get(props.state, "redirectModal.url", ""))
        }
        src={zendeskRedirectImage}
      />
    </React.Fragment>
  );
};

export const mapStateToProps: (state: IAppState) => IUserMasterStateProps = (
  state: IAppState
): IUserMasterStateProps => ({
  state: state,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => IUserMasterFunctionsProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): IUserMasterFunctionsProps => ({
  setNotification: (notify: INotification): IAppAction =>
    dispatch(setNotification(notify)),
  redirectZendesk: (): void => dispatch(redirectZendesk()),
  setRedirectModal: (payload: { show: boolean; url: string }): IAppAction =>
    dispatch(setRedirectModal(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserMasterIndex);
