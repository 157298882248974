import { createNamedStyles } from "../../../shared/utils/create_named_styles";

export const downloadButtonStyles = createNamedStyles({
  group: {
    background: "text.white",
    boxShadow: 2,
    alignItems: "center",
  },
  containerButton: {
    textTransform: "none",
    fontFamily: "IBM Plex Sans",
    border: "none !important",
    borderRightWidth: "1px",
    borderStyle: "solid",
    borderColor: "text.dark",
    borderRight: "1px solid !important",
    color: "primary.main",
    paddingX: "20px",
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
  },
  paper: {
    width: "150px",
  },
  iconButton: {
    padding: 0,
  },
  containerIconButton: {
    border: "none",
    height: " 44px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    padding: "0px !important",
    minWidth: "30px !important",
  },
});
