import { IOptionFilter } from "../interfaces/IOptionFilter";
import { FilterLabelEnum } from "../FilterLabelEnum";
import { FilterTitleEnum } from "../../FilterTitleEnum";
import { KeyStatusEnum } from "../../KeyStatusEnum";
import { IDFilterEnum } from "../IDFilterEnum";

export const DefaultConciliationHistoricFilter: IOptionFilter[] = [
  {
    id: IDFilterEnum.movementType,
    title: FilterTitleEnum.MOVEMENT_TYPE,
    options: [
      {
        key: KeyStatusEnum.PAYMENT_MOVEMENT_TYPE,
        label: FilterLabelEnum.PAYMENT,
        selected: false,
      },
      {
        key: KeyStatusEnum.CHARGE_MOVEMENT_TYPE,
        label: FilterLabelEnum.CHARGE,
        selected: false,
      },
    ],
  },
  {
    id: IDFilterEnum.cardBrand,
    title: FilterTitleEnum.CARD_BRAND,
    options: [
      {
        key: KeyStatusEnum.MASTERCARD_CARD_BRAND,
        label: FilterLabelEnum.MASTERCARD,
        selected: false,
      },
      {
        key: KeyStatusEnum.VISA_CARD_BRAND,
        label: FilterLabelEnum.VISA,
        selected: false,
      },
      {
        key: KeyStatusEnum.AMEX_CARD_BRAND,
        label: FilterLabelEnum.AMEX,
        selected: false,
      },
      {
        key: KeyStatusEnum.CARNET_CARD_BRAND,
        label: FilterLabelEnum.CARNET,
        selected: false,
      },
    ],
  },
  {
    id: IDFilterEnum.cardType,
    title: FilterTitleEnum.CARD_TYPE,
    options: [
      {
        key: KeyStatusEnum.DEBIT_CARD_TYPE,
        label: FilterLabelEnum.DEBIT,
        selected: false,
      },
      {
        key: KeyStatusEnum.CREDIT_CARD_TYPE,
        label: FilterLabelEnum.CREDIT,
        selected: false,
      },
    ],
  },
  {
    id: IDFilterEnum.status,
    title: FilterTitleEnum.BANK_CONCILIATION_STATUS,
    options: [
      {
        key: KeyStatusEnum.CONCILIATION_BANK_CONCILIATION_STATUS,
        label: FilterLabelEnum.CONCILIATION,
        selected: false,
      },
      {
        key: KeyStatusEnum.OMIT_BANK_CONCILIATION_STATUS,
        label: FilterLabelEnum.OMIT,
        selected: false,
      },
      {
        key: KeyStatusEnum.NO_CONCILIATION_BANK_CONCILIATION_STATUS,
        label: FilterLabelEnum.NO_CONCILIATION,
        selected: false,
      },
    ],
  },
];
