import { ActionTypes } from "./actionTypes";
import { IAppAction, MerchantsData } from "./actionCreators";
import { TransactionData } from "../../types/transactions_data";
import { INotification } from "../shared/infrastructure/interfaces/INotification";

export interface IAppState {
  loading: boolean;
  transactionData: TransactionData;
  merchantsList?: MerchantsData;
  loadingDownload?: boolean;
  loadingDownloadById?: boolean;
  notification?: INotification;
  receipt?: Buffer;
  openModal: boolean;
  loadingReceipt?: boolean;
}

export const INITIAL_STATE: IAppState = {
  notification: {
    type: "success",
    open: false,
    message: "",
  },
  loading: false,
  transactionData: {
    total: undefined,
  },
  loadingDownload: false,
  loadingDownloadById: false,
  openModal: false,
  loadingReceipt: false,
};

export const reducer = (
  state: IAppState = INITIAL_STATE,
  action: IAppAction
): IAppState => {
  switch (action.type) {
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.loading!,
      };
    case ActionTypes.SET_TRANSACTIONS:
      return {
        ...state,
        transactionData: action.transactionData!,
      };
    case ActionTypes.SET_MERCHANTS_LIST:
      return {
        ...state,
        merchantsList: action.merchantsList!,
      };
    case ActionTypes.SET_RECEIPT:
      return {
        ...state,
        receipt: action.receipt,
      };
    case ActionTypes.SET_LOADING_DOWNLOAD:
      return {
        ...state,
        loadingDownload: action.loadingDownload,
      };
    case ActionTypes.SET_LOADING_DOWNLOAD_BY_ID:
      return {
        ...state,
        loadingDownloadById: action.loadingDownloadById,
      };
    case ActionTypes.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.notification,
      };
    case ActionTypes.SET_LOADING_RECEIPT:
      return {
        ...state,
        loadingReceipt: action.loadingReceipt,
      };
    default:
      return state;
  }
};
