/* istanbul ignore file */

import { IOptionSelection } from "./RulesConstants";
import { CurrencyEnum } from "../enums/CurrencyEnum";

export const BANKS_SALVADOR: IOptionSelection[] = [
  { value: "10100003", name: "BANCO CITIBANK DE EL SALVADOR S.A." },
  { value: "10100001", name: "BANCO AGRICOLA S.A." },
  { value: "10100002", name: "BANCO CENTRAL DE RESERVA DE EL SALVADOR" },
  { value: "10100004", name: "BANCO DE AMERICA CENTRAL S.A." },
  { value: "10100005", name: "BANCO HIPOTECARIO DE EL SALVADOR S.A." },
  { value: "10100006", name: "BANCO HSBC SALVADORENO S.A." },
  { value: "10100007", name: "BANCO MULTISECTORIAL DE INVERSIONES" },
  { value: "10100008", name: "BANCO PROCREDIT S.A." },
  { value: "10100009", name: "SCOTIABANK EL SALVADOR S.A." },
];

export const CURRENCY_SALVADOR: IOptionSelection[] = [
  { value: CurrencyEnum.USD, name: CurrencyEnum.USD },
];
