import { Order } from "../interfaces";
import {
  IUseTableNodesInterfaces,
  IUseTableNodesRequest,
} from "./IUseTableNodes.interfaces";
import React from "react";
import { useAppSelector } from "../../../../store/hooks/storeHook";
import { RootState } from "../../../../store/store";

export const useTableNodes = ({
  rowsPerPage,
  totalData,
}: IUseTableNodesRequest): IUseTableNodesInterfaces => {
  const [order] = React.useState<Order>("asc");
  const [orderBy] = React.useState<string>("line112343242");
  const totalPages = totalData > 0 ? Math.ceil(totalData / rowsPerPage) : 0;

  const { isLoadingMerchants } = useAppSelector((state: RootState) => ({
    ...state.app,
    ...state.branch,
  }));

  return {
    headerProps: {
      order,
      orderBy,
    },
    isLoadingMerchants,
    totalPages,
  };
};
