import React, { FC } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { IProcessorBasicInfoProps } from "./ProcessorBasicInfoComponent.interfaces";
import { paymentProcessorComponentStyles } from "../PaymentProcessorComponent/PaymentProcessorComponent.styles";
import { get } from "lodash";
import RadioGroupComponent from "../../RadioGroupComponent/RadioGroupComponent";
import { processorBasicInfoComponentStyles } from "./ProcessorBasicInfoComponent.styles";
import { MaskSkeleton } from "../../MaskSkeleton/MaskSkeleton";

const ProcessorBasicInfoComponent: FC<IProcessorBasicInfoProps> = (
  props: IProcessorBasicInfoProps
) => {
  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={processorBasicInfoComponentStyles.radioContainer}
            >
              <Grid item xs={12} sx={paymentProcessorComponentStyles.titleChip}>
                <Typography>
                  {get(props, "processorModelOption.titleInfo", "")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <MaskSkeleton
                  sx={paymentProcessorComponentStyles.radioSkeleton}
                  isLoading={props.isLoading}
                >
                  <RadioGroupComponent
                    updateList={props.processorModelOption.info.updateList}
                    name={props.processorModelOption.info.name}
                    items={props.processorModelOption.info.items}
                  />
                </MaskSkeleton>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={processorBasicInfoComponentStyles.radioContainer}
            >
              <Grid item xs={12} sx={paymentProcessorComponentStyles.titleChip}>
                <Typography>
                  {get(props, "processorsCategoryOptions.titleInfo", "")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <MaskSkeleton
                  isLoading={props.isLoading}
                  sx={paymentProcessorComponentStyles.radioSkeleton}
                >
                  <RadioGroupComponent
                    updateList={props.processorsCategoryOptions.info.updateList}
                    name={props.processorsCategoryOptions.info.name}
                    items={props.processorsCategoryOptions.info.items}
                  />
                </MaskSkeleton>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default ProcessorBasicInfoComponent;
