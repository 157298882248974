import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  labelTitle: {
    color: "#023365",
    fontSize: "20px",
    fontWeight: 500,
    fontStyle: "normal",
  },
  roundedAccordion: {
    borderRadius: "7px",
    padding: "7px",
  },
}));

export interface IAccordionContainerProps {
  icon: string;
  title: string;
  hidden?: boolean;
  isBackgroundTransparent?: boolean;
  childrenArrayComponent: JSX.Element[];
}

export const AccordionContainer: React.FC<IAccordionContainerProps> = (
  props: React.PropsWithChildren<IAccordionContainerProps>
) => {
  const style = useStyles();

  return (
    <Accordion className={style.roundedAccordion} defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Grid container spacing={2}>
          <Grid item>
            <Avatar src={props.icon} />
          </Grid>
          <Grid item>
            <Box mt={1}>
              <Typography className={style.labelTitle}>
                {props.title}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction={"row"} spacing={2}>
          {props.childrenArrayComponent.map((component, index) => (
            <Grid item xs={12} key={index}>
              <Paper
                elevation={0}
                style={{
                  backgroundColor: props.isBackgroundTransparent
                    ? "transparent"
                    : "#F7FAFC",
                  width: "100%",
                }}
              >
                <React.Fragment>{component}</React.Fragment>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
