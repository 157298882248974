import { useState } from "react";
import {
  TFilterDetailBar,
  TOnChangeFilters,
} from "@kushki/connect-ui/dist/Components/Molecules/Filters/FilterDetailBar/FilterDetailBar.interfaces";

import { get, set } from "lodash";
import { HeaderFiltersProps } from "../HeaderFilters.interfaces";
import {
  FILTER_SIDE_BAR_INITIAL_STATE,
  getItemsFilterDetailBar,
} from "../HeaderFilters.constants";
import { IUseHeaderFiltersState } from "./useHeaderFiltersState.interfaces";
import { FilterSideBarMassiveProps } from "../../Filters/FilterSideBar/FilterSideBar.interfaces";
import {
  ItemCategoryItemMassive,
  ItemCategoryMassiveProps,
} from "../../Filters/ItemCategory/interfaces";
import { FilterActionTypeEnum } from "../../../shared/enum/FilterActionTypeEnum";

export const useHeaderFiltersState = ({
  handleOnFilterMerchants,
  handleResetPage,
}: HeaderFiltersProps): IUseHeaderFiltersState => {
  const [filterSideBarProps, setFilterSideBarProps] =
    useState<FilterSideBarMassiveProps>(FILTER_SIDE_BAR_INITIAL_STATE);
  const [itemsDetailBar, setItemsDetailBar] = useState<TFilterDetailBar[]>([]);

  const onClickFilterSideBar = (itemCategory: ItemCategoryMassiveProps[]) => {
    const filterSelected = getItemsFilterDetailBar(itemCategory);

    filterSideBarProps.onClick(itemCategory);
    setItemsDetailBar(filterSelected);
    handleResetPage();
    handleOnFilterMerchants(
      FilterActionTypeEnum.STATUS,
      get(filterSelected, "[0].items[0].value")
    );
  };

  const changeCategoryItems = (
    newCategoryItems: ItemCategoryMassiveProps[]
  ) => {
    setFilterSideBarProps({
      ...filterSideBarProps,
      categoryItems: newCategoryItems,
    });
  };

  const onChangeFiltersDetailBar = (changes: TOnChangeFilters) => {
    let itemsSideBar: ItemCategoryMassiveProps[] = get(
      filterSideBarProps,
      "categoryItems"
    );

    let itemsSideBarFinal: ItemCategoryMassiveProps[] = [];

    itemsSideBar.forEach((itemSideBar: ItemCategoryMassiveProps) => {
      let itemsFilterSideBar: ItemCategoryItemMassive[] = [];

      itemSideBar.items.forEach(
        (itemFilterSideBar: ItemCategoryItemMassive) => {
          if (
            changes.affected.itemDeleted.label === itemFilterSideBar.label &&
            changes.affected.title === itemSideBar.groupSection
          )
            set(itemFilterSideBar, "selected", false);

          itemsFilterSideBar.push(itemFilterSideBar);
        }
      );
      set(itemSideBar, "items", itemsFilterSideBar);
      itemsSideBarFinal.push(itemSideBar);
    });
    changeCategoryItems(itemsSideBarFinal);
    onClickFilterSideBar(itemsSideBarFinal);
  };

  return {
    filterSideBarProps,
    itemsDetailBar,
    onChangeFiltersDetailBar,
    onClickFilterSideBar,
  };
};
