import { format, parse, sub } from "date-fns";
import es from "date-fns/locale/es";
import { StatusEnumMonth } from "../enums/MonthEnum";
import { TimeEnum } from "../enums/TimeEnum";
import { DateEnum } from "../enums/TransactionEnum";

const MONTHS_ABREVIATIONS_REGEX =
  /ene|feb|mar|abr|may|jun|jul|ago|sep|oct|nov|dic/;

export const CatalogTranslateMonth: Record<StatusEnumMonth, string> = {
  [StatusEnumMonth.Enero]: "01",
  [StatusEnumMonth.Febrero]: "02",
  [StatusEnumMonth.Marzo]: "03",
  [StatusEnumMonth.Abril]: "04",
  [StatusEnumMonth.Mayo]: "05",
  [StatusEnumMonth.Junio]: "06",
  [StatusEnumMonth.Julio]: "07",
  [StatusEnumMonth.Agosto]: "08",
  [StatusEnumMonth.Septiembre]: "09",
  [StatusEnumMonth.Sept]: "09",
  [StatusEnumMonth.Octubre]: "10",
  [StatusEnumMonth.Noviembre]: "11",
  [StatusEnumMonth.Diciembre]: "12",
};

export const getDate = (timeStamp: number): string => {
  return format(timeStamp, "yyyy-MM-dd");
};
export const getHour = (timeStamp: number): string => {
  return format(timeStamp, "HH:mm:ss");
};

export const getDateWithCustomFormat = (
  date: string | number,
  customFormat: string
): string => {
  return format(new Date(date), customFormat, {
    locale: es,
  });
};

export const getSimplifiedFormatedDate = (date: string): string => {
  return format(new Date(date), "dd MMM. yyyy HH:mm", {
    locale: es,
  }).replace(
    MONTHS_ABREVIATIONS_REGEX,
    (value) => `${value[0].toUpperCase()}${value.substring(1)}`
  );
};

export const getFormattedDateTime = (date: Date): string =>
  format(date, "dd/MM/yyyy HH:mm", { locale: es });

export const parseDateTime = (dateTime: string): Date =>
  parse(dateTime, "dd/MM/yyyy HH:mm", new Date(), {
    locale: es,
  });

export const subOneDay = (date: Date): Date => sub(date, { days: 1 });

export const getEndDate = () => {
  const date = new Date();
  const day = date.getDate().toString().padStart(2, "0");
  const month = date.toLocaleString("es", { month: "short" });
  const year = date.getFullYear();

  try {
    return `${day}/${month.toLowerCase().replace("sept", "sep")}/${year}`;
  } catch (e) {
    return "";
  }
};

export const getStartDate = () => {
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // Number of milliseconds in one day
  const previousDayTimestamp = new Date().getTime() - oneDayInMilliseconds;
  const date = new Date(previousDayTimestamp);
  const day = date.getDate().toString().padStart(2, "0");
  const month = date.toLocaleString("es", { month: "short" });
  const year = date.getFullYear();

  try {
    return `${day}/${month.toLowerCase().replace("sept", "sep")}/${year}`;
  } catch (e) {
    return "";
  }
};

export const getMinDate = () => {
  const date = new Date();
  const year = date.getFullYear();

  try {
    return `${DateEnum.DAY_DEFAULT}/${DateEnum.MONTH_DEFAULT}/${year - 1}`;
  } catch (e) {
    return "";
  }
};

export const convertDateCorrectFormat = (
  date: string,
  from: boolean = false
): string => {
  try {
    const regexHourMin = /\d{2}:\d{2}/;
    const isDateHour: boolean = regexHourMin.test(date);
    const newDate: string = isDateHour ? date.split(" ")[0] : date;
    const parts = date.split(" ");
    const day = newDate.split("/")[0];
    const month = CatalogTranslateMonth[newDate.split("/")[1]];
    const year = newDate.split("/")[2];

    const seconds: string = from
      ? TimeEnum.ceroSeconds
      : TimeEnum.fiftyNineSeconds;

    const hour: string = isDateHour
      ? `T${parts[1].trim()}:${seconds}.000`
      : from
      ? TimeEnum.initialTHour
      : TimeEnum.finalTHour;

    return `${year}-${month}-${day}${hour}`;
  } catch (e) {
    return "";
  }
};
