import { ProcessorTypeEnum } from "../enums/ProcessorTypeEnum";
import {
  AGGREGATOR_LEGEND,
  AGGREGATOR_NAME,
  GATEWAY_LEGEND,
  GATEWAY_NAME,
} from "../constants/ProcessorContants";

export const getProcessorType = (
  processor:
    | ProcessorTypeEnum.AGGREGATOR
    | ProcessorTypeEnum.GATEWAY
    | ProcessorTypeEnum.GATEWAY_AGGREGATOR
) => {
  switch (processor) {
    case ProcessorTypeEnum.GATEWAY:
      return {
        legend: GATEWAY_LEGEND,
        name: GATEWAY_NAME,
      };
    case ProcessorTypeEnum.AGGREGATOR:
      return {
        legend: AGGREGATOR_LEGEND,
        name: AGGREGATOR_NAME,
      };
    case ProcessorTypeEnum.GATEWAY_AGGREGATOR:
      return {
        legend: "",
        name: "",
      };
  }
};
