import { B2CRefundTransaction } from "../../../../../../types/b2c_refund_transaction";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/hooks/storeHook";

import {
  isAllRefundSelectedSelector,
  refundsSelector,
  selectedRefundsSelector,
} from "../../../../../store/selectors/refunds/refunds.selectors";
import {
  addSelectedRefund,
  unSelectRefund,
} from "../../../../../store/actions/refunds/refunds";

export const useStateTableBody = () => {
  const dispatch = useAppDispatch();
  const isAllRefundSelected = useAppSelector(isAllRefundSelectedSelector);
  const selectedRefunds = useAppSelector(selectedRefundsSelector);
  const refunds = useAppSelector(refundsSelector);

  const handleChangeSelectionRefund = (refundId: string) => {
    const tempRefund: B2CRefundTransaction[] = refunds.filter(
      (refund) => refund.transaction_reference === refundId
    );

    if (tempRefund.length > 0 && selectedRefunds.includes(tempRefund[0])) {
      dispatch(unSelectRefund(tempRefund[0]));
    } else {
      dispatch(addSelectedRefund(tempRefund[0]));
    }
  };

  const handleIsChecked = (refundId: string) => {
    const tempRefund: B2CRefundTransaction[] = selectedRefunds.filter(
      (refund) => refund.transaction_reference === refundId
    );

    return tempRefund.length > 0;
  };

  return {
    handleChangeSelectionRefund,
    handleIsChecked,
    isAllRefundSelected,
    refunds,
    selectedRefunds,
  };
};
