export enum CountryEnum {
  mexico = "México",
  ecuador = "Ecuador",
  brasil = "Brasil",
  usa = "Estados Unidos",
  chile = "Chile",
  panama = "Panamá",
  peru = "Peru",
  guatemala = "Guatemala",
  colombia = "Colombia",
  costa_rica = "Costa Rica",
}
