import React, { FC } from "react";
import { Box } from "@mui/material";
import { IDashboardComponent } from "./DashboardComponent.interfaces";
import { dashboardComponentStyles as styles } from "./DashboardComponent.styles";
import BalanceInfo from "../BalanceInfo/BalanceInfo";
import { useDashboardComponent } from "./state/useDashboardComponent";
import GlobalTableComponent from "../GlobalTableComponent/GlobalTableComponent";

const DashboardComponent: FC<IDashboardComponent> = ({
  country,
  publicMerchantId,
}: IDashboardComponent) => {
  const { balanceData } = useDashboardComponent({ publicMerchantId });

  return (
    <Box sx={styles.boxContainer}>
      <BalanceInfo balanceData={balanceData} country={country} />
      <GlobalTableComponent country={country} merchantId={publicMerchantId} />
    </Box>
  );
};

export default DashboardComponent;
