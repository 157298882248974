import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

export const modalFormStyle = createNamedStyles({
  container: {
    padding: "2rem",
    backgroundColor: "background.default",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: "1.25rem",
    borderBottom: "1px solid #E2E8F0",
  },
  footer: {
    backgroundColor: "background.paper",
    padding: "1.5rem 2rem",
    width: "100%",
  },
  shareHolders: {
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        maxWidth: "35%",
        width: "100%",
      },
    },
  },
});
