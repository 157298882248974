import { Box, Typography } from "@mui/material";
import ChoiceButton from "../ChoiceButton/ChoiceButton";
import React, { FC } from "react";
import {
  ConditionsEditionEnum,
  conditionsEditList,
  SECTION_SELECT_INITIAL_MESSAGE,
} from "../../shared/constants/labels/section_select_labels";
import { selectSectionContainerStyles as styles } from "./SelectSection.styles";
import { ISelectSectionProps } from "./SelectSection.interfaces";

const SelectSection: FC<ISelectSectionProps> = ({
  sectionValues,
  handleChecked,
  isInvalidEdition,
}: ISelectSectionProps) => {
  return (
    <Box sx={styles.container}>
      <Typography color="text.primary" variant="h3" mb={2}>
        {SECTION_SELECT_INITIAL_MESSAGE}
      </Typography>
      {isInvalidEdition && (
        <Typography
          data-testid={"InvalidEdition"}
          color="text.primary"
          variant="body2"
          sx={styles.centered}
        >
          {ConditionsEditionEnum.SUBTITLE}
          <Box component={"ul"} sx={styles.ulCentered}>
            {conditionsEditList.map((item, idx) => (
              <li key={`condition-edit-${idx}`}>{item}</li>
            ))}
          </Box>
          {ConditionsEditionEnum.PLEASE_REVIEW}
        </Typography>
      )}
      <Box sx={styles.optionsContainer}>
        {sectionValues.map((choice) => (
          <ChoiceButton
            {...choice}
            key={choice.title}
            onClick={() => handleChecked(choice.title)}
            isSmall={true}
          />
        ))}
      </Box>
    </Box>
  );
};

export default SelectSection;
