import { isString } from "lodash";
import { parseStringToBoolean } from "../../constants/dispersions/dispersions";

export const getBooleanValue = (value: string | boolean): boolean =>
  isString(value) ? parseStringToBoolean[value] || false : value;

export const removeAccents = (text: string = ""): string =>
  text
    .normalize("NFD")
    .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
    .normalize();
