import CreditCardIcon from "../../../assets/images/credit-card.png";
import TransferIcon from "../../../assets/images/transfer.png";
import CashIcon from "../../../assets/images/cash-icon.png";

export enum PaymentMethodEnum {
  CARD = "card",
  TRANSFER = "transfer",
  CASH = "cash",
  PAYOUTS_TRANSFER = "payouts-transfer",
  PAYOUTS_CASH = "payouts-cash",
  TRANSFER_SUBSCRIPTIONS = "transfer-subscriptions",
}

interface IPaymentMethod {
  label: string;
  icon: string;
  height: number;
  width: number;
  paddingBottom: number;
}

export const mapPaymentMethods = (paymentMethod: string): IPaymentMethod => {
  switch (paymentMethod) {
    case PaymentMethodEnum.CARD:
      return {
        label: "Tarjeta",
        icon: CreditCardIcon,
        height: 20,
        width: 20,
        paddingBottom: 1,
      };
    case PaymentMethodEnum.TRANSFER:
      return {
        label: "Transferencia",
        icon: TransferIcon,
        height: 15,
        width: 19,
        paddingBottom: 1,
      };
    case PaymentMethodEnum.CASH:
      return {
        label: "Efectivo",
        icon: CashIcon,
        height: 15,
        width: 20,
        paddingBottom: 2,
      };
    default:
      return { label: "", icon: "", height: 0, width: 0, paddingBottom: 0 };
  }
};
