/* istanbul ignore file */
import { defaultTo, get } from "lodash";
import { MerchantResponse } from "../../types/merchant_response";
import { timeZoneHour } from "../containers/HistoryIndex/state/useHistoryIndexState";
import { SessionSupport } from "../../types/session_support";

export class auth {
  public static getAuthMerchant(): MerchantResponse {
    return JSON.parse(
      defaultTo(localStorage.getItem("merchantBasicInformation"), "{}")
    );
  }

  public static publicMerchantId(): string {
    return get(auth.getPayload(), "preferred_username", "");
  }
  public static getTimeZone(value: string): string {
    return timeZoneHour(localStorage.getItem("timeZone")!, value);
  }
  public static isAdmin(): boolean {
    return get(auth.getPayload(), "[cognito:groups]", []).includes(
      "BackofficeAdmin"
    );
  }
  public static isUserMaster(): boolean {
    return get(auth.getPayload(), "[cognito:groups]", []).includes(
      "BackofficeUserMaster"
    );
  }
  public static getPayload(): object {
    return JSON.parse(defaultTo(localStorage.getItem("payload"), "{}"));
  }
  public static getFullName(): string {
    return defaultTo(localStorage.getItem("fullName"), "");
  }
  public static getUserName(): string {
    return defaultTo(localStorage.getItem("username"), "");
  }

  public static setSessionSupport(sessionSupport: SessionSupport | undefined) {
    const cache = JSON.parse(defaultTo(localStorage.getItem("cache"), "{}"));
    let _cache = { ...cache, sessionSupport };

    localStorage.setItem("cache", JSON.stringify(_cache));
  }

  public static getSessionSupport() {
    const cache = JSON.parse(defaultTo(localStorage.getItem("cache"), "{}"));
    return get(cache, "sessionSupport", undefined);
  }

  public static removeSessionSupport() {
    localStorage.removeItem("accessCodeSupport");
    this.setSessionSupport(undefined);
  }

  public static getExpiredDateSession(timeConnect: string): Date {
    const number = Number(timeConnect.substr(0, timeConnect.length - 1));
    const timeType = timeConnect.substr(-1);
    let expiredDate = new Date();
    switch (timeType) {
      case "h":
        expiredDate.setHours(expiredDate.getHours() + number);
        break;
      case "m":
      default:
        expiredDate.setMinutes(expiredDate.getMinutes() + number);
        break;
    }
    return expiredDate;
  }
}
