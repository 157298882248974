import React from "react";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { IconEye, ModalSimple } from "@kushki/connect-ui";
import SelectedBranch from "../../SelectedBranch/SelectedBranch";
import { RETURN_MASSIVE_EDITION_MODAL } from "../../../shared/constants/modal-info";
import { useMassiveHeader } from "./state/useMassiveHeader";
import { MassiveHeaderStyles } from "./MassiveHeader.styles";
import { ButtonVariantEnum } from "../../../shared/enums/ButtonStylesEnum";
import { MassiveHeaderData } from "./MassiveHeader.data";

export const MassiveHeader: React.FC = () => {
  const {
    handleOpenSearchBranchModal,
    handleCloseSearchBranchModal,
    handleOpenReturnModal,
    handleCloseReturnModal,
    handlePrimaryButtonModal,
    openReturnModal,
    openSearchBranchModal,
    totalBranches,
    branchesTitle,
  } = useMassiveHeader();

  return (
    <Box>
      <Grid container alignItems="center">
        <Grid
          container
          alignItems="center"
          sx={MassiveHeaderStyles.massiveReturnButton}
        >
          <IconButton
            data-testid={"iconButton"}
            style={{ color: "#4399ED" }}
            onClick={handleOpenReturnModal}
          >
            <ArrowBackIosIcon />
            {MassiveHeaderData.ReturnButtonText}
          </IconButton>
        </Grid>
        <Grid container alignItems="center">
          <Grid item container xs={12} md={"auto"} alignItems="center">
            <Typography
              variant="body1"
              color="text.primary"
              fontSize={16}
              fontWeight={500}
            >
              {`${totalBranches} ${MassiveHeaderData.BranchesToEditText}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          sx={MassiveHeaderStyles.massiveGridTitle}
        >
          <Grid item sx={MassiveHeaderStyles.massiveBranchesTitle}>
            <Typography sx={MassiveHeaderStyles.massiveBranchesTitleTypography}>
              {branchesTitle}
            </Typography>
          </Grid>
          <Grid item sx={MassiveHeaderStyles.massiveBranchesButton}>
            <Button
              data-testid={"buttonBack"}
              color="secondary"
              variant={ButtonVariantEnum.Outlined}
              startIcon={<IconEye fontSize="small" />}
              sx={MassiveHeaderStyles.massiveButtonBorderNone}
              onClick={handleOpenSearchBranchModal}
              size="small"
            >
              {MassiveHeaderData.ViewMoreButtonText}
            </Button>
            {openSearchBranchModal && (
              <SelectedBranch
                isOpen={true}
                closeBranchModal={handleCloseSearchBranchModal}
              />
            )}
          </Grid>
        </Grid>
        <ModalSimple
          onClickPrimary={handlePrimaryButtonModal}
          onClickSecondary={handleCloseReturnModal}
          buttonPrimary={RETURN_MASSIVE_EDITION_MODAL.buttonPrimaryText}
          buttonSecondary={RETURN_MASSIVE_EDITION_MODAL.buttonSecondaryText}
          descriptionText={RETURN_MASSIVE_EDITION_MODAL.descriptionText}
          isOpen={openReturnModal}
          onClose={handleCloseReturnModal}
          titleText={RETURN_MASSIVE_EDITION_MODAL.titleText}
          typeModal={1}
        />
      </Grid>
    </Box>
  );
};
