import dayjs from "dayjs";
import("dayjs/locale/es");
import utc from "dayjs/plugin/utc";
import updateLocale from "dayjs/plugin/updateLocale";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  SPANISH_MONTHS,
  SPANISH_MONTHS_SHORT,
} from "../catalogs/spanishMonths";

dayjs.extend(utc);
dayjs.extend(updateLocale);
dayjs.extend(customParseFormat);
dayjs.locale("es");
dayjs.updateLocale("es", {
  months: SPANISH_MONTHS,
  monthsShort: SPANISH_MONTHS_SHORT,
});

const MONTHS_ABREVIATIONS_REGEX =
  /ene|feb|mar|abr|may|jun|jul|ago|sep|oct|nov|dic/;

export const transformUnixTimestampToFormatDate = (
  timestamp: number,
  formatDate: string
): string => {
  return dayjs(new Date(timestamp))
    .utc()
    .locale("es")
    .format(formatDate)
    .replace(
      MONTHS_ABREVIATIONS_REGEX,
      (value) => `${value[0].toUpperCase()}${value.substring(1)}`
    );
};

export const transformDateToTimestamp = (
  date: string,
  formatDate: string
): number => {
  return dayjs(date, formatDate, "es").utc().valueOf();
};
