import React, { FC, PropsWithChildren } from "react";
import { IAlertPanelProps } from "./AlertPanel.interface";
import { defaultTo } from "lodash";
import { AlertPanelType } from "./AlertPanel.data";
import { Alert } from "@mui/material";
import {
  AlertPanelStyles,
  AlertPanelStylesBackground,
} from "./AlertPanel.styles";

export const AlertPanel: FC<IAlertPanelProps> = (
  props: PropsWithChildren<IAlertPanelProps>
) => {
  const { text, type, icon } = props;
  const { container } =
    type == AlertPanelType.info
      ? AlertPanelStylesBackground()
      : AlertPanelStyles();

  return (
    <Alert
      icon={icon}
      severity={defaultTo(type, AlertPanelType.info)}
      className={container}
    >
      <span dangerouslySetInnerHTML={{ __html: defaultTo(text, "") }}></span>
    </Alert>
  );
};
