export enum ServiceEnum {
  CARD = "card",
  TRANSFER = "transfer",
}

export enum SubTypeEnum {
  COMMERCE = "commerce",
  COUNTRY = "country",
  SECURITY_COMMERCE = "securityCommerce",
  SECURITY_COUNTRY = "securityCountry",
  COMMERCE_WITH_HIERARCHY = "commerce with hierarchy",
  COMMERCE_WITHOUT_HIERARCHY = "commerce without hierarchy",
  RULE_NAME_WHITELIST_WITHOUT_HIERARCHY = "whitelist_rule_commerce_without_hierarchy",
  RULE_NAME_WHITELIST_WITH_HIERARCHY = "whitelist_rule_commerce_with_hierarchy",
  RULE_NAME_WHITELIST_COUNTRY = "whitelist_rule_country",
  WHITELIST = "whitelist",
}

export enum GroupEnum {
  OWNER = "OWNER",
  BRANCH = "BRANCH",
  CUSTOMER = "CUSTOMER",
  WITHOUT_HIERARCHY = "WITHOUT_HIERARCHY",
}

export enum ButtonTypeLabelEnum {
  CARD = "Tarjeta",
  TRANSFER = "Transferencia",
}

export enum ServiceLabelEnum {
  COMMERCE = "Comercio",
  COUNTRY = "País",
  COMMERCE_WITH_HIERARCHY = "Comercio con jerarquía",
  COMMERCE_WITHOUT_HIERARCHY = "Comercio sin jerarquía",
}

export enum IssuerCardLabelEnum {
  NATIONAL = "Nacional",
  INTERNATIONAL = "Internacional",
}

export enum CardTypeValueEnum {
  CREDIT = "Crédito",
  DEBIT = "Débito",
}

export enum CardTypeValueRequestEnum {
  CREDIT = "true",
  DEBIT = "false",
}

export enum TransactionTypeValueEnum {
  token = "Token",
  subscriptionToken = "Token de suscripción",
  charge = "Cobro",
  deferred = "Diferido",
  preauthorization = "Preautorización",
  subscriptionCharge = "Cobro de suscripción",
  subscriptionPreauthorization = "Preautorización de subscripción",
  subscriptionOnDemandToken = "Token de suscripción bajo demanda",
}

export enum RulesSchema {
  RULEMONITOR = "monitor",
  RULEGENERAL = "general",
}

export const TypeToSubtypeRules: Record<string, string> = {
  [SubTypeEnum.SECURITY_COMMERCE]: SubTypeEnum.COMMERCE,
  [SubTypeEnum.SECURITY_COUNTRY]: SubTypeEnum.COUNTRY,
};
