import React from "react";
import { IAlertModal } from "../../shared/infrastructure/interfaces/AlertModal.interface";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { alertModalStyle } from "./AlertModal.style";

export const AlertModal = ({
  buttonText,
  description,
  open,
  title,
  handleOpenModal,
}: IAlertModal) => {
  const classes = alertModalStyle();

  return (
    <Dialog className={classes.dialogContainer} open={open}>
      <Box className={classes.boxContainer}>
        <DialogTitle disableTypography style={{ padding: "0px" }}>
          <Typography variant={"h5"} color={"primary"}>
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.descriptionText}>
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={handleOpenModal}
            className={classes.button}
          >
            {buttonText}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
