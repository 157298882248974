export enum ActionTypes {
  SHOW_LOADING = "[SUBSCRIPTION] SHOW_LOADING",
  HIDE_LOADING = "[SUBSCRIPTION] HIDE_LOADING",
  SET_NOTIFICATION = "[CONFIGURATION] SET_NOTIFICATION",
  SET_MERCHANT = "[CONFIGURATION] SET_MERCHANT",
  SET_IS_DEFERRED_LOADED = "[CONFIGURATION] SET_IS_DEFERRED_LOADED",
  SET_MERCHANT_DEFERRED = "[CONFIGURATION] SET_MERCHANT_DEFERRED",
  SET_MERCHANT_ORIGINAL_DEFERRED = "[CONFIGURATION] SET_MERCHANT_ORIGINAL_DEFERRED",
  SET_ERROR_CHANGE_STATUS = "[CONFIGURATION] SET_ERROR_CHANGE_STATUS",
  SET_CANCELED_CHANGE_STATUS = "[CONFIGURATION] SET_CANCELED_CHANGE_STATUS",
  SET_NODE_INFO = "[CONFIGURATION] SET_NODE_INFO",
}
