import React, { FC } from "react";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { resumeBoxStyles as styles } from "./ResumeBox.styles";
import { IResumeBoxProps } from "./ResumeBox.interfaces";

const ResumeBox: FC<IResumeBoxProps> = ({
  title,
  tooltip,
  styleBox,
  children,
}: IResumeBoxProps) => {
  return (
    <Grid item xs={12} md={4} lg={3} xl={3}>
      <Box sx={styleBox || styles.box}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h5" color={"primary"}>
              {title}
              <Tooltip
                title={
                  <Grid textAlign="left">
                    <b>{title}:</b> <br />
                    {tooltip}
                  </Grid>
                }
                placement="top"
              >
                <InfoOutlined fontSize="small" sx={styles.icon} />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export { ResumeBox };
