export enum ComponentsSecurity {
  CREATE_CUSTOMER = "M_CLIENTS.Customers.Create",
  CUSTOMERS_ACTIVAR = "M_CLIENTS.Customers.Activar",
  CUSTOMERS_DESACTIVAR = "M_CLIENTS.Customers.Desactivar",
  CUSTOMERS_ACTIVAR_MENU = "M_CLIENTS.Customers.ActivarMenu",
  CREAR_OWNER = "M_CLIENTS.Owner.Crear",
  CREAR_BRANCH = "M_CLIENTS.Branches.Crear",
  CREDIT = "M_CLIENTS.Wallet.Acreditar",
  DEBIT = "M_CLIENTS.Wallet.Debitar",
}
