import { CatalogsEnum } from "../../enums/CatalogsEnum";
import { Category } from "../../interfaces/category";
import { TYPE_BANK_ACCOUNT_GENERIC } from "./catalog-type-account-generic";
import { EXTRA_CURRENCIES_CATALOG } from "./catalog-extra-currencies";

export const CatalogWorld: Record<string, Category[]> = {
  [CatalogsEnum.Banks]: [],
  [CatalogsEnum.BankAccountTypes]: TYPE_BANK_ACCOUNT_GENERIC,
  [CatalogsEnum.Currency]: EXTRA_CURRENCIES_CATALOG,
};
