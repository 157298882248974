export const DEFERRED_TYPE: { code: string; text: string }[] = [
  { code: "001", text: "Diferido corriente - Datafast" },
  { code: "002", text: "Diferido con intereses - Datafast" },
  { code: "003", text: "Diferido sin intereses - Datafast" },
  { code: "004", text: "Tipo de Comercio Gasolinera - Datafast" },
  { code: "007", text: "Diferido especial con interés - Datafast" },
  { code: "008", text: "Tarjeta descuento - Datafast" },
  { code: "009", text: "Diferido especial sin interés - Datafast" },
  { code: "021", text: "Diferido Plus Cuotas - Datafast" },
  { code: "022", text: "Diferido Plus - Datafast" },
  { code: "01", text: "Diferido Cuota Fija con intereses - Credimatic" },
  {
    code: "02",
    text: "Diferido con meses de gracia con intereses - Credimatic",
  },
  { code: "03", text: "Diferido Pago Mes a Mes con intereses - Credimatic" },
  { code: "04", text: "Diferido Cuota Fija sin intereses - Credimatic" },
  {
    code: "05",
    text: "Diferido con meses de gracia sin intereses - Credimatic",
  },
  { code: "06", text: "Diferido Pago Mes a Mes sin intereses - Credimatic" },
  { code: "07", text: "Diferido especial sin intereses - Credimatic" },
  { code: "50", text: "Diferido promociones Supermaxi - Credimatic" },
];
export enum CodeDeferredTypeEnum {
  "001" = "001",
  "002" = "002",
  "003" = "003",
  "004" = "004",
  "007" = "007",
  "008" = "008",
  "009" = "009",
  "021" = "021",
  "022" = "022",
  "01" = "01",
  "02" = "02",
  "03" = "03",
  "04" = "04",
  "05" = "05",
  "06" = "06",
  "07" = "07",
  "_50" = "50",
}

export enum ProcessorsEnum {
  DATAFAST = "Datafast",
  CREDIMATIC = "Credimatic",
  FIS = "Fis Processor",
}

export enum ProcessorNameEnum {
  FIS = "Worldpay (by Fis) Processor",
}

export const TRANSLATE_PROCESSOR: Record<string, string> = {
  [ProcessorsEnum.FIS]: ProcessorNameEnum.FIS,
};

export const PROCESSOR_DATAFAST: string[] = [
  CodeDeferredTypeEnum["001"],
  CodeDeferredTypeEnum["002"],
  CodeDeferredTypeEnum["003"],
  CodeDeferredTypeEnum["004"],
  CodeDeferredTypeEnum["007"],
  CodeDeferredTypeEnum["008"],
  CodeDeferredTypeEnum["009"],
  CodeDeferredTypeEnum["021"],
  CodeDeferredTypeEnum["022"],
];

export const PROCESSOR_CREDIMATIC: string[] = [
  CodeDeferredTypeEnum["01"],
  CodeDeferredTypeEnum["02"],
  CodeDeferredTypeEnum["03"],
  CodeDeferredTypeEnum["04"],
  CodeDeferredTypeEnum["05"],
  CodeDeferredTypeEnum["06"],
  CodeDeferredTypeEnum["07"],
  CodeDeferredTypeEnum._50,
];
