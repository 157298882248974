import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const headerFilterUserStyles = createNamedStyles({
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  detailBar: {
    marginTop: "15px",
  },
});
