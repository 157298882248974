import { TPaymentMethod, TTransactionStatus } from "../types";

export const PAYMENT_METHOD_FILTER_OPTIONS: TPaymentMethod[] = [
  "card",
  "transfer",
];

export const TRANSACTION_STATUS_FILTER_OPTIONS: TTransactionStatus[] = [
  "approved",
  "declined",
  "pending",
];
