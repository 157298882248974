export enum StatusFlowEnum {
  VALIDATOR = "validator",
  EXECUTOR = "executor",
  VALIDATOR_HISTORY = "validatorHistory",
  EXECUTOR_HISTORY = "executorHistory",
  VIEWER = "viewer",
}

export enum StatusFlowValidatorExecutor {
  VALIDATOR = "validator",
  EXECUTOR = "executor",
  VIEWER = "viewer",
}
