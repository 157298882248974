import React, { FC } from "react";
import {
  TransactionLabelsEnum,
  TransactionTabValueEnum,
} from "../../shared/enums/transactionLabelsEnum";
import { useModalDetailInfo } from "./state/useModalDetailInfo";
import { IModalDetailInfo } from "./ModalDetailInfo.interfaces";
import { get, has, orderBy } from "lodash";
import {
  getColorByStatus,
  isApproval,
  renderTabsByTransaction,
} from "../../shared/utils/general_utils";
import {
  CATALOG_TRANSACTION_STATUS,
  TransactionStatusEnum,
} from "../../shared/enums/TransactionStatusEnum";
import { CardInformation, CardTax } from "../CardInformation/CardInformation";
import { showMetadataCredentials } from "../../shared/utils/metaData_utils";
import {
  buildCardInformation,
  timeZoneHour,
} from "../../shared/utils/card_tabs_utils";
import { renderButton } from "../../shared/constants/ModalButtons";
import { ITabInfo } from "../GenericTabs/GenericTabs.interfaces";
import GenericTabs from "../GenericTabs/GenericTabs";
import { ModalDetailCard } from "@kushki/connect-ui/dist/Components/Organism/ModalDetailCard";
import { GenericTabsStyles } from "../GenericTabs/GenericTabs.styles";
import { PaymentMethodsEnum } from "../../shared/infraestructure/payment-method";
import { format } from "date-fns-tz";
import { dateZonedTime } from "../../shared/utils/timeZoneRegion_utils";
import { DEAFULT_DATE } from "../../shared/constants/TimeZoneDefault";
import { IconCircleCheck, IconClock, TimeLine } from "@kushki/connect-ui";
import {
  TimeLineCommonData,
  TimelineTrxData,
} from "../../shared/interfaces/TimeLine.interfaces";
import { CardInformationEnum } from "../../shared/enums/CardInformationEnum";
import { TransactionInfo } from "../../../types/transaction";
import { TransactionTypeEnum } from "../../shared/enums/TransactionTypeEnum";
import { PaymentMethodEnum } from "../../shared/enums/PaymentMethodEnum";

const ModalDetailInfo: FC<IModalDetailInfo> = (props: IModalDetailInfo) => {
  const { handleClose, transactionInfo } = useModalDetailInfo(props);
  const { modal } = props;

  const timeLineData: TimeLineCommonData = {
    iconCompleted: <IconCircleCheck color={"success"} fontSize={"large"} />,
    iconInCompleted: <IconClock fontSize={"large"} />,
    stylesDate: {
      align: "left",
      padding: "0 0 80px 0",
      width: "30%",
    },
    stylesIconIncomplete: {
      background: "#B4B9BE !important",
      borderRadius: "50%",
      fill: "white !important",
      padding: "2px",
    },
  };

  const buildTimeLine = (
    transactionInfo: TransactionInfo
  ): TimelineTrxData[] => {
    const data: TimelineTrxData[] = [];
    const array_status_cash_timeline: string[] = [
      TransactionStatusEnum.APPROVED,
      TransactionStatusEnum.APPROVAL,
      TransactionStatusEnum.INITIALIZED,
      TransactionStatusEnum.DECLINED,
    ];
    const payment_method: string = get(transactionInfo, "payment_method", "");
    const transaction_status: string = get(
      transactionInfo,
      "transaction_status",
      ""
    );
    const transaction_type: string = get(
      transactionInfo,
      "transaction_type",
      ""
    );
    const transaction_created: string = get(transactionInfo, "created", "");
    const is_approval: boolean = isApproval(transaction_status);

    if (
      get(transactionInfo, "create_timestamp", 0) &&
      payment_method !== PaymentMethodsEnum.CARD &&
      transaction_type === TransactionTypeEnum.PAYOUT &&
      array_status_cash_timeline.includes(transaction_status)
    ) {
      data.push({
        completed: true,
        date: transaction_created,
        link: false,
        title: CardInformationEnum.INITIALIZED,
      });
      if (
        is_approval ||
        transaction_status === TransactionStatusEnum.DECLINED
      ) {
        const transaction_completed: string = get(
          transactionInfo,
          "completed",
          ""
        );

        data.push({
          completed: true,
          date: transaction_completed,
          link: false,
          title: is_approval
            ? CardInformationEnum.APPROVAL
            : CardInformationEnum.DECLINED,
        });
      }
    }

    return orderBy(data, "date", "asc");
  };

  const buildDate = (): string => {
    const transactionData = { ...transactionInfo };

    if (
      get(transactionData, "create_timestamp", 0) &&
      transactionData.transaction_type === TransactionTypeEnum.PAYOUT &&
      [TransactionStatusEnum.APPROVED, TransactionStatusEnum.DECLINED].includes(
        transactionData.transaction_status_type
      ) &&
      transactionData.payment_method !== PaymentMethodEnum.CARD
    )
      return get(transactionData, "completed", "");

    return get(transactionData, "created", DEAFULT_DATE);
  };
  const isMongo = has(transactionInfo, "create_timestamp");
  const informationTab: ITabInfo = {
    content: (
      <CardInformation
        data={buildCardInformation(transactionInfo)}
        transactionInfo={transactionInfo}
      />
    ),
    defaultRender: true,
    disable: false,
    label: TransactionLabelsEnum.MODAL_DETAIL_TAB_1,
    sx: GenericTabsStyles.boxTabs,
    type: TransactionTabValueEnum.INFORMATION,
    value: 0,
    variant: "primary",
  };
  const timeLineTab: ITabInfo = {
    content: (
      <TimeLine
        handlerSearchTrxParent={props.handlerSearchTrxParent}
        isLoading={props.isLoadingTransaction}
        iconCompleted={timeLineData.iconCompleted}
        iconIncomplete={timeLineData.iconInCompleted}
        stylesDate={timeLineData.stylesDate}
        stylesIconIncomplete={timeLineData.stylesIconIncomplete}
        stylesSeparator={{ background: "#B4B9BE" }}
        timeZoneRegion={timeZoneHour()}
        timelineTrxData={buildTimeLine(transactionInfo)}
        textButton={CardInformationEnum.SEE}
      />
    ),
    defaultRender: false,
    disable: false,
    label: TransactionLabelsEnum.MODAL_DETAIL_TAB_2,
    sx: GenericTabsStyles.boxTabsNoMargin,
    type: TransactionTabValueEnum.TIME_LINE,
    value: 1,
    variant: "primary",
  };
  const metadataTab: ITabInfo = {
    content: <CardTax data={showMetadataCredentials(transactionInfo)} />,
    defaultRender: false,
    disable:
      get(transactionInfo, "payment_method", "") === PaymentMethodsEnum.CASH,
    label: TransactionLabelsEnum.MODAL_DETAIL_TAB_3,
    sx: GenericTabsStyles.boxTabs,
    type: TransactionTabValueEnum.METADATA,
    value: 2,
    variant: "primary",
  };
  const defaultTabList: ITabInfo[] = [informationTab];

  if (isMongo) defaultTabList.push(timeLineTab);
  defaultTabList.push(metadataTab);

  return (
    <ModalDetailCard
      bottom={"0"}
      slideDirection="left"
      isOpen={modal.isOpenModalDetailInfo}
      header={{
        buttonRow: renderButton(props, transactionInfo),
        secondaryText: TransactionLabelsEnum.MODAL_DETAIL_DATE.concat(
          format(
            dateZonedTime(buildDate()),
            TransactionLabelsEnum.MODAL_DETAIL_DATE_FORMAT
          )
        ),
        tags: [
          {
            color: getColorByStatus(
              get(transactionInfo, "transaction_status")!
            ),
            text: CATALOG_TRANSACTION_STATUS[
              get(transactionInfo, "transaction_status")!
            ],
          },
        ],
        title: TransactionLabelsEnum.MODAL_DETAIL_TITLE,
        titleDescription: get(transactionInfo, "ticket_number")!,
      }}
      onClose={handleClose}
      right={"0"}
      top={"150px"}
      variant={"full"}
      widthCard={"576px"}
    >
      <GenericTabs
        orientation={"horizontal"}
        tabList={renderTabsByTransaction(
          get(props, "transactionInfo._source", {}),
          defaultTabList
        )}
        variant={"fullWidth"}
      />
    </ModalDetailCard>
  );
};

export default ModalDetailInfo;
