import React from "react";
import { TableInfo } from "@kushki/connect-ui";
import { Box, Grid, Typography } from "@mui/material";
import { styles } from "./DispersionBlock.styles";
import { useDispersionBlock } from "./state/useDispersionBlock";
import { TextBlock } from "../commons/TextBlock/TextBlock";
import { MissingInfoAlert } from "../MissingInfoAlert/MissingInfoAlert";

const DispersionBlock = () => {
  const { table, isComplete, isLoading, dispersionBlock, fraudBlock } =
    useDispersionBlock();

  return (
    <>
      {isComplete === false ? (
        <MissingInfoAlert />
      ) : (
        <Box sx={styles.container}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <TextBlock
                title={dispersionBlock.title}
                subtitle={dispersionBlock.subtitle}
                isLoading={isLoading}
              />
            </Grid>

            {fraudBlock && (
              <Grid item xs={12} md={6}>
                <TextBlock
                  title={fraudBlock.title}
                  subtitle={fraudBlock.subtitle}
                />
              </Grid>
            )}
          </Grid>

          <Box>
            <Typography color="text.dark" variant="subtitle2" mb="16px">
              Cuentas bancarias
            </Typography>
            <Box
              sx={{
                overflowX: "auto",
              }}
            >
              <TableInfo
                isLoading={isLoading}
                header={{
                  cells: table.columns,
                  headerProps: {
                    isDisable: false,
                  },
                }}
                rows={table.rows}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default DispersionBlock;
