import { createAction } from "@reduxjs/toolkit";
import { catchError, EMPTY, ignoreElements, switchMap, tap } from "rxjs";
import { filter } from "rxjs/operators";
import { EpicCustom } from "../epics.interfaces";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import axios from "../../../shared/axios-util";
import {
  setIvaValues,
  setPaymentMethodsStatus,
} from "../../actions/app.actions";
import { RequestStatusEnum } from "../../../shared/enums/RequestStatusEnum";

export const getIvaValues = createAction("GET_IVA_VALUES");

export const getIvaValuesEpic: EpicCustom = ({ action$, dispatch }) =>
  action$.pipe(
    filter(getIvaValues.match),
    switchMap(() =>
      axios.get<object>(API_ROUTES.GET_IVA_VALUES).pipe(
        tap(({ data }: { data: object }) => {
          dispatch(setIvaValues(data));
        }),
        catchError(() => {
          dispatch(setPaymentMethodsStatus(RequestStatusEnum.ERROR));

          return EMPTY;
        })
      )
    ),
    ignoreElements()
  );
