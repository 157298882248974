import React, { SyntheticEvent } from "react";
import { InputsPropsComponent } from "../FormControl.interfaces";
import { InputTypeEnum } from "../../../shared/enum/InputTypeEnum";
import { Autocomplete, TextField } from "@mui/material";
import RadioForm from "../../common/FormComponents/RadioForm/RadioForm";
import { defaultTo, get } from "lodash";
import CheckBoxInput from "../../common/FormComponents/CheckBox/CheckBox";

function InputsForm<T>({
  inputComponent,
  inputProps,
  onInputChange,
  listOptions,
  pathInfo,
  onChange,
  pathId,
}: InputsPropsComponent<T>) {
  const { field, label, error, isDisable, options, checked } = inputProps;

  switch (inputComponent) {
    case InputTypeEnum.TEXT:
      return (
        <TextField
          {...field}
          value={field.value}
          label={label}
          variant="outlined"
          disabled={isDisable}
          fullWidth
          helperText={error ? error.message : null}
          error={!!error}
          onChange={(v) => {
            field.onChange(v);
            if (onChange) {
              onChange(v, v.target.value as T);
            }
          }}
        />
      );
    case InputTypeEnum.RADIO:
      return (
        <RadioForm
          field={field}
          options={options}
          disable={isDisable}
          error={error}
        />
      );
    case InputTypeEnum.EMAIL:
      return (
        <TextField
          {...field}
          value={field.value}
          label={label}
          variant="outlined"
          type={"email"}
          disabled={isDisable}
          fullWidth
          helperText={error ? error.message : null}
          error={!!error}
          onChange={field.onChange}
        />
      );
    case InputTypeEnum.AUTOCOMPLETE:
      return (
        <Autocomplete
          {...field}
          id="AssociateTrade"
          size={"small"}
          noOptionsText={"Sin coincidencias"}
          onInputChange={(_e, v, reason) => {
            if (reason !== "reset") {
              onInputChange(v);
            }
          }}
          value={
            listOptions.find(
              (option: any) =>
                String(get(option, pathId!, "")) === String(field.value)
            ) || null
          }
          getOptionLabel={(option: any) =>
            get(option, defaultTo(pathInfo, ""), "")
          }
          onChange={(e: SyntheticEvent, v) => {
            field.onChange(get(v, pathId!, ""));
            if (onChange) {
              onChange(e, v);
            }
          }}
          options={listOptions!}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!error}
              helperText={error ? error.message : null}
              label={label}
              margin="normal"
              variant="outlined"
            />
          )}
        />
      );
    case InputTypeEnum.CHECKBOX:
      return (
        <CheckBoxInput
          field={field}
          isDisabled={isDisable}
          label={label}
          onChange={onChange}
          checked={checked}
        />
      );
    default:
      return <></>;
  }
}

export default InputsForm;
