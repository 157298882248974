/* istanbul ignore file */

import {
  CurrencyEnum,
  IOptionSelection,
  PeriodicityTypeEnum,
  RuleSchemaEnum,
} from "./RuleRequestConstants";

export const BUSINESS_RULES_RULES: IOptionSelection[] = [
  { value: "Banco", name: RuleSchemaEnum.transactionBank },
  { value: "Marca de tarjeta", name: RuleSchemaEnum.transactionBrand },
  { value: "Diferido", name: RuleSchemaEnum.transactionIsDeferred },
  { value: "Procesador", name: RuleSchemaEnum.transactionProcessor },
  { value: "País", name: RuleSchemaEnum.transactionCountry },
  { value: "Monto", name: RuleSchemaEnum.transactionTotalAmount },
  { value: "Ip", name: RuleSchemaEnum.transactionIP },
  { value: "Tipo de transacción", name: RuleSchemaEnum.transactionType },
  { value: "Credencial", name: RuleSchemaEnum.transactionCredential },
  { value: "Bin", name: RuleSchemaEnum.transactionBIN },
  { value: "Tipo de tarjeta", name: RuleSchemaEnum.transactionIsCreditCard },
  {
    value: "Tarjeta enmascarada",
    name: RuleSchemaEnum.transactionMaskedCreditCard,
  },
  {
    value: "Kushki ID",
    name: RuleSchemaEnum.transactionCardId,
  },
  {
    value: "Emisión de la tarjeta",
    name: RuleSchemaEnum.transactionCardIssuer,
  },
];

export const BUSINESS_RULES_ROUTING: IOptionSelection[] = [
  ...BUSINESS_RULES_RULES,
  { value: "Moneda", name: "transactionCurrency" },
];

export const STRICT_CONDITIONS_BASIC: IOptionSelection[] = [
  { name: "=", value: "Igual" },
  { name: "!=", value: "Diferente" },
];
export const STRICT_CONDITIONS_CREDENTIALS: IOptionSelection[] = [
  { name: "=", value: "Igual" },
];

export const STRICT_CONDITIONS_CONTAINS: IOptionSelection[] = [
  { name: "contain", value: "Contiene" },
  { name: "!contain", value: "No Contiene" },
];

export const STRICT_CONDITIONS: IOptionSelection[] = [
  ...STRICT_CONDITIONS_BASIC,
  ...STRICT_CONDITIONS_CONTAINS,
];

export const STRICT_CONDITIONS_EXTENDED: IOptionSelection[] = [
  ...STRICT_CONDITIONS,
  { name: ">", value: "Mayor que" },
  { name: "<", value: "Menor que" },
];

export const TRANSACTION_DEFERRED_CONDITION_VALUES: IOptionSelection[] = [
  { name: "true", value: "Si" },
  { name: "false", value: "No" },
];

export const CURRENCIES: IOptionSelection[] = [
  { name: CurrencyEnum.USD, value: CurrencyEnum.USD },
  { name: CurrencyEnum.COP, value: CurrencyEnum.COP },
  { name: CurrencyEnum.PEN, value: CurrencyEnum.PEN },
  { name: CurrencyEnum.CLP, value: CurrencyEnum.CLP },
  { name: CurrencyEnum.UF, value: CurrencyEnum.UF },
  { name: CurrencyEnum.MXN, value: CurrencyEnum.MXN },
  { name: CurrencyEnum.BRL, value: CurrencyEnum.BRL },
];

export const CREDIT_CARD_OPTIONS: IOptionSelection[] = [
  { value: "Crédito", name: "credit" },
  { value: "Débito", name: "debit" },
];

export const PAYMENT_BRANDS_OPTIONS: IOptionSelection[] = [
  { name: "Visa", value: "Visa" },
  { name: "Mastercard", value: "Mastercard" },
  { name: "Amex", value: "Amex" },
  { name: "American Express", value: "american express" },
  { name: "Diners", value: "Diners" },
  { name: "Diners Club", value: "diners club" },
  { name: "Discover", value: "Discover" },
  { name: "Redcompra", value: "Redcompra" },
  { name: "Alia", value: "Alia" },
  { name: "Credencial", value: "Credencial" },
  { name: "Maestro", value: "maestro" },
  { name: "Propia", value: "propia" },
  { name: "Cmrfalabella", value: "Cmrfalabella" },
  { name: "JCB", value: "JCB" },
  { name: "Serfinanza", value: "Serfinanza" },
  { name: "Unionpay", value: "unionpay" },
];

export const MAP_TYPE_CARD: Record<string, string> = {
  ["=|debit"]: "false",
  ["=|credit"]: "true",
  ["=|true"]: "true",
  ["=|false"]: "false",
  ["=|Crédito"]: "true",
  ["=|Débito"]: "false",
};

export const PERIODICITY_TYPE: Record<PeriodicityTypeEnum, string> = {
  [PeriodicityTypeEnum.MINUTE]: "Por minuto",
  [PeriodicityTypeEnum.HOUR]: "Por hora",
  [PeriodicityTypeEnum.DAY]: "Diaria",
  [PeriodicityTypeEnum.WEEK]: "Semanal",
  [PeriodicityTypeEnum.BIWEEKLY]: "Quincenal",
  [PeriodicityTypeEnum.MONTH]: "Mensual",
  [PeriodicityTypeEnum.QUARTERLY]: "Trimestral",
  [PeriodicityTypeEnum.BIANNUAL]: "Semestral",
};

export const TRANSACTION_STATUS_TYPE: Record<string, string> = {
  ["approved"]: "Aprobadas",
  ["declined"]: "Declinadas",
  ["all"]: "Todos",
};

export const SPEED_TRANSACTION_STATUS: Record<string, string> = {
  ["APPROVAL"]: "Aprobadas",
  ["DECLINED"]: "Declinadas",
  ["ALL"]: "Todas",
};

export const SPEED_PERIODICITY_VALUES: IOptionSelection[] = [
  { value: "Por minuto", name: "minute" },
  { value: "Por hora", name: "hour" },
  { value: "Diaria", name: "day" },
  { value: "Semanal", name: "week" },
  { value: "Quincenal", name: "fortnight" },
  { value: "Mensual", name: "month" },
  { value: "Trimestral", name: "quarterly" },
  { value: "Semestral", name: "biannual" },
];
