import React from "react";
import { ModalOverlay } from "@kushki/frontend-molecules/modal";
import ModalHeader from "../ModalHeader/ModalHeader";
import ModalBody from "../ModalBody/ModalBody";
import { SecurityRule } from "../../../types/security_rule";
import ModalFooter from "../ModalFooter/ModalFooter";
import { MerchantsData, RulesAction } from "../../store/actionCreator";
import {
  ButtonText,
  INotification,
} from "../../shared/infrastructure/constants/RulesConstants";

export type ModalContainerType = "commerce" | "country";

export interface ModalContainerProps {
  data: SecurityRule;
  handlerCloseModalInformation: () => void;
  handlerDeleteRule: (ruleId: string) => void;
  handlerEditRule: (ruleId: string) => void;
  handlerUpdateStatusRule: (rule: SecurityRule, ruleId: string) => void;
  isMobile: boolean;
  isRequest: boolean;
  merchant: MerchantsData;
  notification?: INotification;
  open: boolean;
  setNotification?: (payload: INotification) => RulesAction;
  type: ModalContainerType;
}

const ModalContainer: React.FC<ModalContainerProps> = (
  props: ModalContainerProps
) => {
  return (
    <React.Fragment>
      {props.data !== undefined ? (
        <ModalOverlay
          header={<ModalHeader data={props.data} type={props.type} />}
          body={
            <ModalBody
              isMobile={props.isMobile}
              data={props.data}
              type={props.type}
              merchant={props.merchant}
            />
          }
          footer={
            <ModalFooter
              data={props.data}
              handlerDeleteRule={props.handlerDeleteRule}
              handlerEditRule={props.handlerEditRule}
              handlerUpdateStatusRule={props.handlerUpdateStatusRule}
              mainTextButton={"Editar regla"}
              secondaryTextButton={ButtonText.DELETE}
              isRequest={props.isRequest}
              notification={props.notification}
              setNotification={props.setNotification}
            />
          }
          onCloseModal={props.handlerCloseModalInformation}
          openModal={props.open}
          overlay={false}
          backdrop={false}
          transition={"left"}
        />
      ) : null}
    </React.Fragment>
  );
};

export default ModalContainer;
