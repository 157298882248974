import { useRuleHistoricDashboardState } from "./state/useRuleHistoricDashboardState";
import React from "react";
import { DataTableFilters } from "../../components/Filters/DataTableFilters";
import { IUtilDashboard } from "../Utils/useUtilDashboard";
import { DashboardTable } from "../../components/Table/DashboardTable/DashboardTable";
import { DashboardTableSelection } from "../../components/Table/DashboardTableSelection/DashboardTableSelection";
import { TRuleDashBoardIndexProps } from "../RuleDashboardIndex/RuleDashboardIndex";

export interface RuleHistoricDashboardProps {
  stateUtilTab: IUtilDashboard;
}

export type TRuleHistoricDashboardProps = RuleHistoricDashboardProps &
  TRuleDashBoardIndexProps;

export const RuleHistoricDashboard: React.FC<TRuleHistoricDashboardProps> = (
  props: TRuleHistoricDashboardProps
) => {
  const {
    data,
    pagination,
    filterProps,
    tableColumns,
    checkboxActions,
    downloadFileState,
    modalOver,
    selectedRows,
    checkboxSelection,
  } = useRuleHistoricDashboardState(props);

  return (
    <React.Fragment>
      <DataTableFilters
        {...filterProps}
        downloadFileState={downloadFileState}
      />
      {selectedRows.length > 0 && (
        <DashboardTableSelection
          handleCheckboxChange={checkboxSelection.handleSelectionCheckboxChange}
          data={selectedRows}
          renderColumns={[
            {
              id: "checkField",
              label: "",
              view: true,
              filter: false,
              checkboxHeader: {
                name: "selection_header",
                checked: true,
                handleChange:
                  checkboxSelection.handleSelectionCheckboxHeaderChange,
              },
            },
            ...tableColumns.renderColumnsHistoric,
            { id: "status", label: "Estado actual", view: true, filter: false },
          ]}
          downloadFileState={downloadFileState}
        />
      )}
      <DashboardTable
        isAnalyst={false}
        hideFilters={true}
        historicRulesData={data.historicRulesDataHistoric}
        page={pagination.page}
        pageSize={pagination.pageSize}
        handleChangePage={pagination.handleChangePage}
        handleChangePageSize={pagination.handleChangePageSize}
        hideNext={false}
        hidePrevious={false}
        filterProps={filterProps}
        tableColumns={{
          renderColumns: [
            {
              id: "checkField",
              label: "",
              view: true,
              filter: false,
            },
            ...tableColumns.renderColumnsHistoric,
            { id: "status", label: "Estado actual", view: true, filter: false },
          ],
        }}
        modalOver={modalOver}
        isLoading={data.isLoading}
        orderDataDashboard={data.orderDataHistoric}
        paginationV2={true}
        checkboxActions={checkboxActions}
      />
    </React.Fragment>
  );
};
