import { TransactionType } from "../enums/transaction_types";
import {
  MODAL_CREDIT_DATA_TEXT,
  MODAL_DEBIT_DATA_TEXT,
} from "../constants/modal_data_text";
import { IGetModalInfo } from "../../components/WalletBalanceForm/state/useWalletBalanceForm.interfaces";

export const CATALOG_MODAL_CONFIRM_INFO: Record<
  TransactionType,
  IGetModalInfo
> = {
  [TransactionType.DEBIT]: {
    description: (currency: string, value: string) =>
      MODAL_DEBIT_DATA_TEXT.DESCRIPTION(currency, value),
    title: MODAL_DEBIT_DATA_TEXT.TITLE,
  },
  [TransactionType.CREDIT]: {
    description: (currency: string, value: string) =>
      MODAL_CREDIT_DATA_TEXT.DESCRIPTION(currency, value),
    title: MODAL_CREDIT_DATA_TEXT.TITLE,
  },
};
