import React from "react";
import { Grid, Typography } from "@mui/material";
import FormControl from "../../../FormControl/FormControl";
import { physicalPersonRules } from "../../../../shared/constants/form_labels_legal_details";
import { Control } from "react-hook-form";
import { CountryEnum } from "../../../../shared/countryEnum";

type Props = {
  control: Control;
  rules?: object;
  country: CountryEnum;
  constitutionalCountry?: CountryEnum;
};
const MoralForm: React.FC<Props> = ({
  control,
  country,
  constitutionalCountry,
}: Props) => {
  return (
    <Grid container rowSpacing={2} columnSpacing={1}>
      <Grid item xs={12}>
        <Typography variant={"body2"} color={"text.dark"}>
          Información personal
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl
          control={control}
          inputComponent="text"
          label="Razón social"
          name="socialReason"
          rules={physicalPersonRules.razonSocial}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl
          control={control}
          inputComponent="countries"
          label="País de domicilio de la empresa"
          name="merchantAdress"
          rules={physicalPersonRules.country}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl
          control={control}
          inputComponent="text"
          label="Número de TAX ID"
          name="taxId"
          rules={physicalPersonRules.documentNumberMoral}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl
          control={control}
          inputComponent="date"
          label="Fecha de constitución"
          name="constitutionDate"
          rules={physicalPersonRules.constitutionDate}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"body2"} color={"text.dark"}>
          Datos de contacto
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl
          control={control}
          inputComponent="email"
          label="Correo electrónico"
          name="email"
          rules={physicalPersonRules.email}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl
          control={control}
          country={country}
          constitutionalCountry={constitutionalCountry}
          inputComponent="phone"
          label="Celular"
          name="cellphoneNumber"
          rules={physicalPersonRules.phone}
        />
      </Grid>
    </Grid>
  );
};

export default MoralForm;
