import {
  ActionApprovalEnum,
  ActionEnum,
  ActionHistoricEnum,
  ActionKeyEnum,
  ActionRefundEnum,
  ActionRefundSubTitleEnum,
  ActionRefundTitleEnum,
  defaultMenuOptions,
  HistoricTitleEnum,
  MAP_ACTION_VALIDATOR,
  MODAL_ACTIONS_DESCRIPTION,
  MODAL_ACTIONS_REQUEST,
} from "../../../shared/infrastructure/enums/ActionEnum";
import {
  buildFilterByRequestBody,
  getDataRequestBody,
} from "../../../shared/utils/buildFiltersObject";
import {
  checkBoxHeader,
  defaultColumns,
  defaultHeaders,
} from "../../../shared/infrastructure/headers/defaultHeaders";
import {
  cloneDeep,
  defaultTo,
  filter,
  findIndex,
  get,
  isEmpty,
  pullAllBy,
  set,
} from "lodash";

import {
  filterTrx,
  switchTrxRefund,
} from "../../../shared/infrastructure/constants/Filter";
import {
  getCurrencyByCountry,
  PendingFilters,
  PendingFiltersBackOfficeRefundApprover,
} from "../../../shared/infrastructure/filters/PendingFilters";
import {
  getCurrencyByCountryHistory,
  HistoryFilters,
} from "../../../shared/infrastructure/filters/HistoryFilters";
import { getCurrentDay, getYesterday } from "../../../shared/utils/date";
import {
  getDetailsCardChips,
  getDetailsCardTabs,
} from "../../../components/DetailsCard/DetailsCard.utils";
import {
  getRefundDataTransactions,
  getRefundHistoryData,
  processTransactions,
  setActionMassiveTransaction,
  setActiveTabAction,
  setLoadingMassive,
  setNotification,
  setRefundDataRowTransaction,
  setRefundHistoryData,
  updateAsyncResponseRefund,
  updateRefundTransaction,
} from "../../../store/actionCreators";

import {
  IConciliationDashboardState,
  IFilter,
  IState,
} from "./useRefundIndexState.interfaces";
import {
  IHandleOption,
  Options,
} from "@kushki/frontend-molecules/backoffice-table";
import { IOptionFilter, OptionItem } from "../../../../types/option_filter";
import React, { useEffect, useRef, useState } from "react";
import {
  TransactionRefundMassiveRequest,
  Transactions,
} from "../../../../types/process_transaction_refund_request";
import { useDispatch, useSelector } from "react-redux";

import { ActionWsEnum } from "../../../shared/infrastructure/enums/ActionWsEnum";
import { auth } from "../../../shared/utils/auth";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { CountryEnum } from "../../../shared/infrastructure/enums/CountryEnum";
import { CountryList } from "../../../shared/infrastructure/types/CountryType";
import { DateRange } from "@material-ui/pickers";
import { defaultStateRefund } from "../../../shared/infrastructure/enums/StatusRefundEnum";
import { FilterTypeEnum } from "../../../shared/infrastructure/enums/FilterTypeEnum";
import { format } from "date-fns-tz";
import { IAppState } from "../../../store/reducer";
import { IHeaderTable } from "../../../../types/header_table";
import { INotification } from "../../../shared/infrastructure/interfaces/INotification";
import { KeyboardKeyEnum } from "../../../shared/infrastructure/enums/KeyboardKeyEnum";
import { ModalData } from "../../../components/DashboardTabs/DashboardTabs.interfaces";
import { MoreHorizontal } from "react-feather";
import { OrderSortEnum } from "../../../shared/infrastructure/constants/DashboardTableConstant";
import { PaymentMethodEnum } from "../../../shared/infrastructure/enums/PaymentMethodEnum";
import { RangeAmount } from "../../../../types/range_amount";
import { RangeAmountDefault } from "../../../shared/infrastructure/filters/RangeAmountDefaultFilters";
import { routes } from "../../../shared/infrastructure/routes";
import { StatusFlowEnum } from "../../../shared/infrastructure/enums/StatusFlowEnum";
import { TabConstants } from "../../../shared/infrastructure/constants/TabConstants";
import { TableOptionTitleEnum } from "../../../shared/infrastructure/enums/TableOptionTitleEnum";
import { TransactionData } from "../../../../types/transaction_data";
import { TransactionTable } from "../../../../types/transaction_table";
import { useLocation } from "react-router";

export const useRefundIndexState = (): IConciliationDashboardState => {
  const dispatch = useDispatch();

  const statusFlow: StatusFlowEnum = useLocation()
    .pathname.split("/")
    .reverse()[0] as StatusFlowEnum;
  const getMinDate = (): Date => {
    const now = new Date();

    now.setDate(now.getDate() - 180);

    return now;
  };
  const [state] = useState<IState>({});
  const { activeTab, wsResponse } = useSelector((state: IAppState) => state);
  const isFirstRunCountry = useRef(true);
  const isFirstRunPaymentMethod = useRef(true);
  const isFirstRunLimit = useRef(true);
  const isFirstRunFilter = useRef(true);
  const isFirstRunDate = useRef(true);
  const isFirstRunQuery = useRef(true);

  const { refundData } = useSelector((state: IAppState) => state);
  const { refundHistoryData } = useSelector((state: IAppState) => state);

  state.isLoadingRefund = useSelector(
    (state: IAppState) => state.isLoadingRefundData!
  );
  state.loadingMassive = useSelector(
    (state: IAppState) => state.loadingMassive!
  );
  const [stateRefund, setStateRefund] = useState(
    defaultStateRefund[statusFlow]
  );
  const [country, setCountry] = useState<CountryList>(CountryEnum.mexico);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodEnum>(
    PaymentMethodEnum.CARD
  );
  const [selectedCheck, setSelectedCheck] = useState<boolean>(false);
  const [addedCheck, setAddedCheck] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [actionRefundEnum, setActionRefundEnum] = useState<ActionRefundEnum>(
    ActionRefundEnum.NOAPPROVE
  );
  const [subTitle, setSubTitle] = useState<string>("");
  const [noSelectedCheck, setNoSelectedCheck] = useState<boolean>(false);
  const [order, setOrder] = useState<OrderSortEnum>(OrderSortEnum.ASC);
  const [orderBy, setOrderBy] = useState<string>("created");
  const [trxSelectedCheckRefund, setTrxSelectedCheckRefund] = useState<
    TransactionRefundMassiveRequest[]
  >([]);
  const [trxSelectedCheckCancel, setTrxSelectedCheckCancel] = useState<
    TransactionRefundMassiveRequest[]
  >([]);
  const [trxSelectedCheckOmit, setTrxSelectedCheckOmit] = useState<
    TransactionRefundMassiveRequest[]
  >([]);
  const [trxSelectedCheckGiveBack, settrxSelectedCheckGiveBack] = useState<
    TransactionRefundMassiveRequest[]
  >([]);
  const [limit, setLimit] = useState<number>(25);
  const [page, setPage] = useState<number>(1);
  const [filtersDashboard, setFilterDashboard] = useState<IOptionFilter[]>(
    getCurrencyByCountry(cloneDeep(PendingFilters), country)
  );
  const [rangeAmount, setRangeAmount] =
    useState<RangeAmount>(RangeAmountDefault);
  const [selectedColumns, setSelectedColumns] = useState<IHeaderTable[]>(
    defaultColumns[statusFlow]
  );
  const [range, setDateRange] = useState<DateRange<Date>>([
    getYesterday(),
    getCurrentDay(),
  ]);
  const [fromDate, setStartDate] = useState<string>(
    format(getYesterday(), "yyyy-MM-dd")
  );
  const [toDate, setEndDate] = useState<string>(
    format(getCurrentDay(), "yyyy-MM-dd")
  );
  const [query, setQuery] = useState<string>("");
  const [disabledButtonsAction, setDisableButtonsAction] =
    useState<boolean>(true);

  const roleBackOffice = auth.isBackOfficeRefundApprover();

  const [showColumnsFilter, setShowColumnsFilter] = useState(false);
  const [trxSelectedCheck, setTrxSelectedCheck] = useState<TransactionData[]>(
    []
  );
  const [allTrxCheckHistory, setAllTrxCheckHistory] = useState<boolean>(false);
  const [errorCondition, setErrorCondition] = useState<boolean>(false);
  const [errorConditionRefund, setErrorConditionRefund] =
    useState<boolean>(false);
  const [columnsFilterAnchorEl, setColumnsFilterAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [selectedColumnsIds, setSelectedColumnsIds] = useState<string[]>(
    selectedColumns.map((column: IHeaderTable) => column.id)
  );
  const [modalRefundData, setModalRefundData] = useState<ModalData>({
    actionApproval: "",
    context: "",
    description: "",
    hideContext: true,
    openDialog: false,
    title: "",
  });
  const [value, setValue] = useState<string | number | undefined>("");
  const [valueRefund, setValueRefund] = useState<string | number | undefined>(
    ""
  );
  const [showCard, setShowCard] = useState(false);
  const [openMassiveModal, setOpenMassiveModal] = useState(false);
  const [massiveResponse, setMassiveResponse] = useState("");
  const [valueMassiveModal, setValueMassiveModal] = useState("");
  const [succesTrx, setSuccessTrx] = useState(0);
  const [failedTrx, setFailedTrx] = useState(0);
  const [openMassiveModalResult, setOpenMassiveModalResult] = useState(false);
  const [rowTransaction, setRowTransaction] = useState<TransactionData>({});
  const [tableOptions, setTableOptions] = useState<Options<TransactionData>[]>(
    []
  );
  const [selectedTab, setSelectedTab] = useState(0);
  const [minDate] = useState<Date>(getMinDate);

  const handleSelectTab = (_: React.ChangeEvent<{}>, tabIndex: number) => {
    setSelectedTab(tabIndex);
  };
  const handleShowCard = (row: TransactionData) => {
    setRowTransaction(row);
    setShowCard(true);
  };
  const handleCloseCard = () => {
    setSelectedTab(0);
    setShowCard(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(get(event, "target.value", "").slice(0, 200));
    setErrorCondition(false);
  };
  const handleChangeRefund = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueRefund(get(event, "target.value", "").slice(0, 200));
    setErrorConditionRefund(false);
  };
  const handleCloseProcessTransactionsModal = () => {
    setModalRefundData({ ...modalRefundData, openDialog: false });
    setValue("");
  };

  const sendData = () => {
    const validator_action: ActionApprovalEnum =
      MAP_ACTION_VALIDATOR[
        `${defaultTo(modalRefundData.actionApproval, "")}/${defaultTo(
          get(modalRefundData, "trxData.action", ""),
          ""
        )}`
      ];

    dispatch(
      updateRefundTransaction({
        action: defaultTo(
          MODAL_ACTIONS_REQUEST[validator_action],
          MODAL_ACTIONS_REQUEST[modalRefundData.title]
        ),
        franchise: get(modalRefundData, "trxData.franchise", ""),
        observation: value,
        transactionId: get(modalRefundData, "trxData.transactionId", ""),
      })
    );
    dispatch(
      getRefundDataTransactions(
        getDataRequestBody({
          country,
          from: fromDate,
          paymentMethod,
          stateRefund,
          to: toDate,
        })
      )
    );
    setModalRefundData({ ...modalRefundData, openDialog: false });
    setValue("");
  };
  const handleAcceptProcessTransactions = () => {
    if (roleBackOffice) {
      sendData();
    } else if (!isEmpty(value)) {
      sendData();
    }
  };

  const filterTrxSelectedAction = (
    transactionSelectedData: TransactionData[],
    actionKey: ActionKeyEnum
  ): TransactionRefundMassiveRequest[] => {
    const trxFiltered: TransactionData[] = filterTrx(
      transactionSelectedData,
      actionKey
    );

    return trxFiltered.map((trx) => ({
      franchise: get(trx, "franchise", ""),
      processorName: get(trx, "processorName", ""),
      transactionId: get(trx, "transactionId", ""),
      transactionRefundId: get(trx, "transactionRefundId", ""),
    }));
  };

  const actionHandler = (actionRefundEnum: ActionRefundEnum) => {
    setTitle(ActionRefundTitleEnum[actionRefundEnum]);
    setSubTitle(ActionRefundSubTitleEnum[actionRefundEnum]);
    setActionRefundEnum(actionRefundEnum);
    if (
      [ActionRefundEnum.APPROVE, ActionRefundEnum.NOAPPROVE].includes(
        actionRefundEnum
      )
    ) {
      setTrxSelectedCheckCancel(
        filterTrxSelectedAction(trxSelectedCheck, ActionKeyEnum.CNCL)
      );
      setTrxSelectedCheckRefund(
        filterTrxSelectedAction(trxSelectedCheck, ActionKeyEnum.RPY)
      );
      setTrxSelectedCheckOmit(
        filterTrxSelectedAction(trxSelectedCheck, ActionKeyEnum.OMT)
      );
      settrxSelectedCheckGiveBack(
        filterTrxSelectedAction(trxSelectedCheck, ActionKeyEnum.GBCK)
      );
    }

    setOpenDialog(true);
  };

  const handleAcceptRefundTransactions = () => {
    if (!isEmpty(valueRefund) || statusFlow === StatusFlowEnum.VALIDATOR) {
      const transactions: Transactions[] = [];

      if (
        [
          ActionRefundEnum.REFUND,
          ActionRefundEnum.OMIT,
          ActionRefundEnum.CANCEL,
          ActionRefundEnum.GIVEBACK,
        ].includes(actionRefundEnum)
      ) {
        const itemsTransaction: TransactionRefundMassiveRequest[] =
          trxSelectedCheck.map((trx: TransactionData) => ({
            franchise: get(trx, "franchise", ""),
            processorName: get(trx, "processorName", ""),
            transactionId: get(trx, "transactionId", ""),
            transactionRefundId: get(trx, "transactionRefundId", ""),
          }));

        switch (actionRefundEnum) {
          case ActionRefundEnum.REFUND:
            transactions.push({ RPY: itemsTransaction });
            break;
          case ActionRefundEnum.OMIT:
            transactions.push({ OMT: itemsTransaction });
            break;
          case ActionRefundEnum.CANCEL:
            transactions.push({ CNCL: itemsTransaction });
            break;
          case ActionRefundEnum.GIVEBACK:
            transactions.push({ GBCK: itemsTransaction });
            break;
        }
      }
      if (
        [ActionRefundEnum.APPROVE, ActionRefundEnum.NOAPPROVE].includes(
          actionRefundEnum
        )
      ) {
        switchTrxRefund(
          actionRefundEnum,
          transactions,
          trxSelectedCheckCancel,
          trxSelectedCheckRefund,
          trxSelectedCheckGiveBack,
          trxSelectedCheckOmit
        );
      }
      dispatch(
        processTransactions(
          {
            action: ActionWsEnum.REFUND_MASSIVE_TRANSACTION,
            data: {
              observation: valueRefund as string,
              transactions: transactions,
            },
          },
          actionRefundEnum
        )
      );
      setValueRefund("");
      setOpenDialog(false);
      dispatch(
        getRefundDataTransactions(
          getDataRequestBody({
            country,
            from: fromDate,
            paymentMethod,
            stateRefund,
            to: toDate,
          })
        )
      );
    } else setErrorConditionRefund(true);
  };

  const handleCloseRefundTransactionsModal = () => {
    setValueRefund("");
    setOpenDialog(false);
  };

  const handleHistoricAcceptProcessTransactions = () => {
    if (!isEmpty(value)) {
      dispatch(
        updateAsyncResponseRefund({
          action: ActionWsEnum.UPDATE_ASYNC_RESPONSE_REFUND,
          data: {
            action: get(modalRefundData, "trxData.action", ""),
            observation: value,
            response: modalRefundData.context,
            transaction: [get(modalRefundData, "trxData.transactionId", "")],
          },
        })
      );
      setModalRefundData({ ...modalRefundData, openDialog: false });
      setValue("");
    } else setErrorCondition(true);
  };

  const handleShowColumnsFilter = (element: HTMLButtonElement) => {
    setColumnsFilterAnchorEl(element);
    setShowColumnsFilter(!showColumnsFilter);
  };

  const handleClose = () => {
    setShowColumnsFilter(false);
  };

  const handleSelectedColumns = (columns: IHeaderTable[]) => {
    setSelectedColumns(columns);
  };
  const handleColumnCheckChange = (columnId: string) => {
    if (selectedColumnsIds.includes(columnId))
      setSelectedColumnsIds(
        selectedColumnsIds.filter((colId) => colId !== columnId)
      );
    else setSelectedColumnsIds([...selectedColumnsIds, columnId]);
  };

  const handleCheckboxChange = (
    rowValue: TransactionData,
    selected: boolean
  ): void => {
    dispatch(
      setRefundDataRowTransaction({
        ...rowValue,
        selected,
      })
    );
  };
  const updateRowTrx = (
    rowValue: TransactionData,
    selected: boolean
  ): TransactionTable => {
    const trxIndex = findIndex(refundHistoryData.data!, {
      transactionId: rowValue.transactionId,
    });

    const auxTrx: TransactionData[] = pullAllBy(
      refundHistoryData.data!,
      [{ transactionId: rowValue.transactionId }],
      "transactionId"
    );

    set(rowValue, "selected", selected);

    auxTrx.splice(trxIndex, 0, rowValue);

    return {
      data: auxTrx,
      total: auxTrx.length,
    };
  };

  const handleCheckboxChangeHistoric = (
    rowValue: TransactionData,
    selected: boolean
  ): void => {
    const newHistoryData = updateRowTrx(rowValue, selected);

    dispatch(setRefundHistoryData(newHistoryData));
  };
  const handleCheckboxChangeAllHistory = (value: TransactionData[]) => {
    let newHistoryData: TransactionData = [];

    setAllTrxCheckHistory(!allTrxCheckHistory);
    value.map((item) => {
      newHistoryData = updateRowTrx(item, !allTrxCheckHistory);
    });
    dispatch(setRefundHistoryData(newHistoryData));
  };

  const handleCheckboxChangeAll = (value: TransactionData[]) => {
    value.map((item) => {
      dispatch(
        setRefundDataRowTransaction({
          ...item,
          selected: !selectedCheck,
        })
      );
    });

    setSelectedCheck(!selectedCheck);
    setNoSelectedCheck(selectedCheck);
  };

  const handleChangeLimit = (newLimit: number): void => {
    setPage(1);
    setLimit(newLimit);
  };

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: string
  ) => {
    setOrder(
      order === OrderSortEnum.ASC ? OrderSortEnum.DESC : OrderSortEnum.ASC
    );
    setOrderBy(property);
  };

  const handleDashboardOptions = (
    trx: TransactionData,
    option: string,
    title: string
  ): void => {
    setModalRefundData!({
      actionApproval: option,
      context: "",
      description: MODAL_ACTIONS_DESCRIPTION[option],
      hideContext: true,
      openDialog: true,
      title,
      trxData: {
        action: get(trx, "action"),
        amount: trx.requestAmount,
        currency: trx.currencyCode,
        franchise: trx.franchise,
        merchantId: trx.merchantId,
        merchantName: trx.merchantName,
        transactionId: get(trx, "transactionId", ""),
      },
    });
  };

  const handleHistoricDashboardOptions = (
    trx: TransactionData,
    option: string
  ): void => {
    const actionApproval = option.concat(
      get(trx, "history[0].action", ActionKeyEnum.RPY)
    );

    setModalRefundData!({
      actionApproval,
      context: option,
      description: MODAL_ACTIONS_DESCRIPTION[option],
      hideContext: true,
      openDialog: true,
      title: HistoricTitleEnum.historic_title,
      trxData: {
        action: get(trx, "action", ""),
        merchantId: get(trx, "merchantId", ""),
        merchantName: get(trx, "merchantName", ""),
        socialReason: get(trx, "socialReason", ""),
        transactionId: get(trx, "transactionId", ""),
      },
    });
  };

  function getOptions(): IHandleOption<TransactionData>[] {
    let currentStatusFlow = statusFlow;

    if (activeTab)
      currentStatusFlow =
        statusFlow === StatusFlowEnum.EXECUTOR
          ? StatusFlowEnum.EXECUTOR_HISTORY
          : StatusFlowEnum.VALIDATOR_HISTORY;

    return defaultMenuOptions[currentStatusFlow].map((action: object) => ({
      fontColor: "default",
      method: (trx: TransactionData) =>
        activeTab
          ? handleHistoricDashboardOptions(trx, get(action, "value"))
          : handleDashboardOptions(
              trx,
              get(action, "value"),
              get(action, "title", get(action, "label"))
            ),
      name: get(action, "label"),
    }));
  }

  const validateRefundRoles = () => {
    return (
      auth.isBackOfficeRefundApprover() || auth.isBackOfficeRefundExecutor()
    );
  };

  const getColumns = (): IHeaderTable[] => {
    let columns: IHeaderTable[];

    columns = defaultColumns[statusFlow];
    if (statusFlow === StatusFlowEnum.VALIDATOR) {
      if (activeTab === 0) {
        columns = defaultColumns[StatusFlowEnum.VALIDATOR];
      }
      if (activeTab === 1) {
        columns = defaultColumns[StatusFlowEnum.VALIDATOR_HISTORY];
      }
    }
    if (statusFlow === StatusFlowEnum.EXECUTOR) {
      if (activeTab === 0) {
        columns = defaultColumns[StatusFlowEnum.EXECUTOR];
      }
      if (activeTab === 1) {
        columns = defaultColumns[StatusFlowEnum.EXECUTOR_HISTORY];
      }
    }
    if (statusFlow === StatusFlowEnum.VIEWER) {
      columns = defaultColumns[StatusFlowEnum.VIEWER];
    }

    return columns;
  };

  const getHeaders = (): IHeaderTable[] => {
    let headers: IHeaderTable[];

    headers = defaultHeaders[statusFlow];
    if (statusFlow === StatusFlowEnum.VALIDATOR) {
      if (activeTab === 0) {
        headers = defaultHeaders[StatusFlowEnum.VALIDATOR];
      }
      if (activeTab === 1) {
        headers = defaultHeaders[StatusFlowEnum.VALIDATOR_HISTORY];
      }
    }
    if (statusFlow === StatusFlowEnum.EXECUTOR) {
      if (activeTab === 0) {
        headers = defaultHeaders[StatusFlowEnum.EXECUTOR];
      }
      if (activeTab === 1) {
        headers = defaultHeaders[StatusFlowEnum.EXECUTOR_HISTORY];
      }
    }

    return headers;
  };

  const getTableOptions = (): Options<object>[] => {
    if (statusFlow === StatusFlowEnum.VIEWER || !validateRefundRoles()) {
      return [];
    }

    if (statusFlow === StatusFlowEnum.VALIDATOR && activeTab === 1) return [];

    const option = {
      handleOnMoreOptions: getOptions(),
      icon: <MoreHorizontal />,
      title:
        activeTab === 0
          ? TableOptionTitleEnum.OPTION
          : TableOptionTitleEnum.RESPONSE,
    };

    return [option];
  };

  const handleConciliationData = (
    request: object,
    activeTabAux: number
  ): void => {
    activeTabAux === TabConstants.HISTORY
      ? dispatch(getRefundHistoryData(request))
      : dispatch(getRefundDataTransactions(request));
  };

  const handleTabsChange = (
    _event: Partial<React.ChangeEvent<{}>>,
    newValue: number
  ) => {
    dispatch(setActiveTabAction(newValue));
  };

  const handleSelectCountryChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const countrySelected = get(event, "target.value");

    setCountry(countrySelected);
  };

  const handleSelectPaymentMethodChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const paymentMethodSelected = get(event, "target.value");

    setPaymentMethod(paymentMethodSelected);
  };

  const handleSetFilters = (filters: IFilter, range?: RangeAmount) => {
    const filtersDashboardDefault = cloneDeep(filtersDashboard);

    filtersDashboardDefault.map((filter) => {
      filter.options.map((option) => {
        option.selected = filters[option.key];
      });
    });
    setFilterDashboard(filtersDashboardDefault);
    setRangeAmount(range && range.value ? range : RangeAmountDefault);
  };

  const handleCloseAlertModal = () => {
    dispatch(setLoadingMassive(false));
  };
  const getAction = (action: string) => {
    switch (action) {
      case ActionEnum.refund:
        return "refund";
      case ActionEnum.cancel:
        return "cancel";
      case ActionEnum.giveback:
        return "return";
      case ActionEnum.skip:
      default:
        return "omit";
    }
  };

  const handleSetActionFilter = (action: string, label: string) => {
    if (!addedCheck) {
      if (action && statusFlow === StatusFlowEnum.EXECUTOR && activeTab === 1) {
        defaultColumns[StatusFlowEnum.EXECUTOR_HISTORY].unshift(checkBoxHeader);
        setSelectedColumns(defaultColumns[StatusFlowEnum.EXECUTOR_HISTORY]);
        setAddedCheck(true);
        const splitted = label.split("-");
        const actionString = getAction(splitted[0].trim());

        dispatch(setActionMassiveTransaction(actionString));
      }
    } else {
      if (
        isEmpty(action) &&
        statusFlow === StatusFlowEnum.EXECUTOR &&
        activeTab === 1
      ) {
        defaultColumns[StatusFlowEnum.EXECUTOR_HISTORY].shift();
        setSelectedColumns(defaultColumns[StatusFlowEnum.EXECUTOR_HISTORY]);
        setAddedCheck(false);
        dispatch(setActionMassiveTransaction(""));
      }
    }
  };
  const getActionFilterKey = (): string => {
    let action: string = "";

    filtersDashboard.map((item: IOptionFilter) => {
      item.options.map((option: OptionItem) => {
        if (option.selected) {
          if (item.id === FilterTypeEnum.actionResponse) {
            action = option.key;
          }
        }
      });
    });

    return action;
  };
  const handleApproved = () => {
    switch (getActionFilterKey()) {
      case "SNDRFN":
        setMassiveResponse(ActionHistoricEnum.approved_by_processor);
        break;
      default:
        setMassiveResponse(ActionHistoricEnum.approved_fin_ops);
        break;
    }
  };
  const handleDeclined = () => {
    switch (getActionFilterKey()) {
      case "SNDRFN":
        setMassiveResponse(ActionHistoricEnum.declined_by_processor);
        break;
      default:
        setMassiveResponse(ActionHistoricEnum.declined_fin_ops);
        break;
    }
  };
  const validateDisabledActionButton = (filters: object): boolean => {
    const keysFilters: string[] = Object.getOwnPropertyNames(filters);
    const hasFilterProcessor: boolean = keysFilters.includes(
      FilterTypeEnum.processorName
    );

    if (activeTab === 0) return !hasFilterProcessor;

    const hasFilterResponse: boolean = keysFilters.includes(
      FilterTypeEnum.response
    );

    if (!hasFilterProcessor || !hasFilterResponse) return true;

    return ![ActionHistoricEnum.send_by_fin_ops].includes(
      get(filters, FilterTypeEnum.response)
    );
  };

  const handleChangePage = (
    _event: React.ChangeEvent<unknown> | undefined,
    value: number,
    trxData: TransactionData[]
  ): void => {
    const filters = buildFilterByRequestBody(filtersDashboard, rangeAmount);

    trxData.map((item) => {
      dispatch(
        setRefundDataRowTransaction({
          ...item,
          selected: false,
        } as TransactionData)
      );
    });
    setSelectedCheck(false);
    setNoSelectedCheck(true);
    setPage(value);

    handleConciliationData(
      getDataRequestBody({
        country,
        filter: filters,
        from: fromDate,
        limit,
        offset: limit * (value - 1),
        paymentMethod,
        stateRefund,
        to: toDate,
      }),
      activeTab!
    );
  };

  const handleSetDate = (dateRange: DateRange<Date>) => {
    setDateRange(dateRange);
    const selectedStartDate: Date = new Date(dateRange[0]?.toISOString()!);
    const selectedEndDate: Date = new Date(dateRange[1]?.toISOString()!);

    setStartDate(format(selectedStartDate, "yyyy-MM-dd"));
    setEndDate(format(selectedEndDate, "yyyy-MM-dd"));
    setPage(1);
  };

  const handleInputChange = (event: string) => {
    setQuery(event);
  };

  const handleSlopesSearchBar = (value: string) => {
    if (value !== KeyboardKeyEnum.ENTER) return;
    setPage(1);
    const filters = buildFilterByRequestBody(filtersDashboard, rangeAmount);

    handleConciliationData(
      getDataRequestBody({
        country,
        filter: filters,
        from: fromDate,
        generalField: query,
        limit,
        offset: 0,
        paymentMethod,
        stateRefund,
        to: toDate,
      }),
      activeTab!
    );
  };

  /* custom massiveTransactionModal +/ */

  const getSelectedTransactions = () => {
    const refundHData = get(refundHistoryData, "data", []);

    return refundHData.filter((item) => get(item, "selected", false));
  };

  const handleCloseMassiveTransactionModal = () => {
    setOpenMassiveModal(false);
    setMassiveResponse("");
    setValueMassiveModal("");
  };

  const handleChangeMassiveTransactionModal = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValueMassiveModal(event.target.value);
  };
  const handleAcceptMassiveTransactionModal = () => {
    if (!isEmpty(valueMassiveModal)) {
      const transactionIds = getSelectedTransactions().map(
        (trx) => trx.transactionId!
      );
      const actions = getSelectedTransactions().map((trx) => trx.action!);

      const requestParams = {
        action: ActionWsEnum.UPDATE_ASYNC_RESPONSE_REFUND,
        data: {
          action: actions[0],
          observation: valueMassiveModal,
          response: massiveResponse,
          transaction: transactionIds,
        },
      };

      dispatch(updateAsyncResponseRefund(requestParams, true));
      setValueMassiveModal("");
      setMassiveResponse("");
      setOpenMassiveModal(false);
    }
  };

  /* -------------------- +/*/

  /* custom massiveTransactionModalResponse +/ */

  const handleCloseMassiveResponseModal = () => {
    setOpenMassiveModalResult(false);
  };

  /* -------------------- +/*/

  useEffect(() => {
    if (wsResponse != "") {
      const error_message: INotification = {
        horizontal: "right",
        icon: CloseIcon,
        message:
          "Ha ocurrido un error al ingresar la respuesta. Intentalo Nuevamente",
        open: true,
        type: "error",
        vertical: "top",
      };

      const message = JSON.parse(wsResponse!);
      const success = get(message, "successfulRecords", 0);
      const failed = get(message, "failedRecords", 0);

      setSuccessTrx(success);
      setFailedTrx(failed);

      if (success > 0 && failed > 0) {
        setOpenMassiveModalResult(true);
      }
      if (success === 0 && failed > 0) {
        dispatch(setNotification(error_message));
      }
      if (success > 0 && failed === 0) {
        dispatch(
          setNotification({
            horizontal: "center",
            icon: CheckIcon,
            message: "Respuesta ingresada",
            open: true,
            type: "success",
            vertical: "top",
          })
        );
      }
    }
  }, [wsResponse]);

  useEffect(() => {
    if (!isEmpty(massiveResponse)) {
      const trxH = getSelectedTransactions().length;

      if (trxH > 0) {
        setOpenMassiveModal(true);
      }
    }
  }, [massiveResponse]);
  useEffect(() => {
    setQuery("");
    setRangeAmount(RangeAmountDefault);
    setDateRange([getYesterday(), getCurrentDay()]);
    setSelectedColumns(getColumns());
    setTableOptions(getTableOptions());
    if (roleBackOffice && statusFlow === StatusFlowEnum.VALIDATOR) {
      if (activeTab === 0) {
        setFilterDashboard(
          getCurrencyByCountry(
            cloneDeep(PendingFiltersBackOfficeRefundApprover),
            country
          )
        );
      }
      if (activeTab === 1) {
        setFilterDashboard(
          getCurrencyByCountryHistory(cloneDeep(HistoryFilters), country)
        );
      }
    } else if (roleBackOffice && statusFlow === StatusFlowEnum.EXECUTOR) {
      if (activeTab === 0) {
        setFilterDashboard(
          getCurrencyByCountry(cloneDeep(PendingFilters), country)
        );
      }
      if (activeTab === 1) {
        setFilterDashboard(
          getCurrencyByCountryHistory(cloneDeep(HistoryFilters), country)
        );
      }
    } else if (roleBackOffice && statusFlow === StatusFlowEnum.VIEWER) {
      if (activeTab === 1) {
        setFilterDashboard(
          getCurrencyByCountryHistory(cloneDeep(HistoryFilters), country)
        );
      }
    }
  }, [activeTab, statusFlow]);

  useEffect(() => {
    if (statusFlow !== StatusFlowEnum.VIEWER) {
      const columns: IHeaderTable[] = getColumns();

      setSelectedColumns(columns);
      setSelectedColumnsIds(columns.map((col: IHeaderTable) => col.id));
    }
    setStateRefund(defaultStateRefund[statusFlow]);
    setTableOptions(getTableOptions());
  }, [statusFlow]);

  useEffect(() => {
    if (isFirstRunCountry.current) {
      isFirstRunCountry.current = false;

      return;
    }
    const filters = buildFilterByRequestBody(filtersDashboard, rangeAmount);

    handleConciliationData(
      getDataRequestBody({
        country,
        filter: filters,
        from: fromDate,
        limit,
        offset: 0,
        paymentMethod,
        stateRefund,
        to: toDate,
      }),
      activeTab!
    );
  }, [country]);

  useEffect(() => {
    if (isFirstRunPaymentMethod.current) {
      isFirstRunPaymentMethod.current = false;

      return;
    }
    const filters = buildFilterByRequestBody(filtersDashboard, rangeAmount);

    handleConciliationData(
      getDataRequestBody({
        country,
        filter: filters,
        from: fromDate,
        limit,
        offset: 0,
        paymentMethod,
        stateRefund,
        to: toDate,
      }),
      activeTab!
    );
  }, [paymentMethod]);

  useEffect(() => {
    if (isFirstRunFilter.current) {
      isFirstRunFilter.current = false;

      return;
    }
    const filters = buildFilterByRequestBody(filtersDashboard, rangeAmount);
    const disabledButtonActions: boolean =
      validateDisabledActionButton(filters);

    setDisableButtonsAction(disabledButtonActions);

    handleConciliationData(
      getDataRequestBody({
        country,
        filter: filters,
        from: fromDate,
        limit,
        offset: 0,
        paymentMethod,
        stateRefund,
        to: toDate,
      }),
      activeTab!
    );
  }, [filtersDashboard]);

  useEffect(() => {
    if (isFirstRunLimit.current) {
      isFirstRunLimit.current = false;

      return;
    }
    const filters = buildFilterByRequestBody(filtersDashboard, rangeAmount);

    setPage(1);
    handleConciliationData(
      getDataRequestBody({
        country,
        filter: filters,
        from: fromDate,
        limit,
        offset: limit * (page - 1),
        paymentMethod,
        stateRefund,
        to: toDate,
      }),
      activeTab!
    );
  }, [limit]);

  useEffect(() => {
    if (isFirstRunDate.current) {
      isFirstRunDate.current = false;

      return;
    }
    const filters = buildFilterByRequestBody(filtersDashboard, rangeAmount);

    handleConciliationData(
      getDataRequestBody({
        country,
        filter: filters,
        from: fromDate,
        generalField: query,
        limit,
        offset: 0,
        paymentMethod,
        stateRefund,
        to: toDate,
      }),
      activeTab!
    );
  }, [range]);

  // //historic search
  useEffect(() => {
    if (isFirstRunQuery.current) {
      isFirstRunQuery.current = false;

      return;
    }
    const filters = buildFilterByRequestBody(filtersDashboard, rangeAmount);

    setPage(1);
    if (isEmpty(query))
      handleConciliationData(
        getDataRequestBody({
          country,
          filter: filters,
          from: fromDate,
          generalField: query,
          limit,
          offset: 0,
          paymentMethod,
          stateRefund,
          to: toDate,
        }),
        activeTab!
      );
  }, [query]);

  useEffect(() => {
    const trxCheck: TransactionData[] = filter(
      refundData.data,
      (item: TransactionData) => get(item, "selected", 0) === true
    ) as TransactionData[];

    setTrxSelectedCheck(trxCheck);
  }, [refundData.data]);

  return {
    actionNavBarExecutor: {
      disabledButtonsAction,
      handleApproved,
      handleDeclined,
    },
    actions: {
      handleCheckboxChange,
      handleCheckboxChangeAll,
      handleCheckboxChangeAllHistory,
      handleCheckboxChangeHistoric,
      handleSelectCountryChange,
      handleSelectPaymentMethodChange,
    },
    breadCrumbs: {
      items: [
        {
          label: "Inicio",
          url: routes.DASHBOARD,
        },
      ],
      lastItem: "Reembolsos",
    },
    check: {
      noSelectedCheck,
      selectedCheck,
    },
    checkHistory: {
      noSelectedCheck: allTrxCheckHistory,
      selectedCheck: allTrxCheckHistory,
    },
    country,
    data: {
      refundData,
      refundHistoryData,
    },
    detailsCard: {
      cardTitleFrom: "merchantName",
      chips: getDetailsCardChips(statusFlow, rowTransaction),
      created: get(rowTransaction, "createAt", "2022-06-10T00:00:00Z"),
      handleCloseCard,
      handleSelectTab,
      handleShowCard,
      selectedTab,
      showCard,
      statusFlow: statusFlow,
      tabs: getDetailsCardTabs(statusFlow, rowTransaction),
      transaction: rowTransaction,
    },
    filterColumns: {
      allColumns: getHeaders(),
      columnsFilterAnchorEl,
      defaultColumns: getColumns(),
      handleClose,
      handleColumnCheckChange,
      handleSelectedColumns,
      handleShowColumnsFilter,
      maxColumns: 6,
      minColumns: 6,
      selectedColumns,
      selectedColumnsIds,
      setSelectedColumnsIds,
      showColumnsFilter,
    },
    filterProps: {
      filters: filtersDashboard,
      handleInputChange: handleInputChange,
      handleSearchBar: handleSlopesSearchBar,
      handleSetDate: handleSetDate,
      handleSetFilters: handleSetFilters,
      query,
      range,
      rangeAmount,
    },
    handleCloseAlertModal,
    massiveTransactionModal: {
      handleAccept: handleAcceptMassiveTransactionModal,
      handleChange: handleChangeMassiveTransactionModal,
      handleClose: handleCloseMassiveTransactionModal,
      open: openMassiveModal,
      refundTransactions: getSelectedTransactions(),
      responseParameter: massiveResponse,
      value: valueMassiveModal,
    },
    massiveTransactionModalResponse: {
      handleClose: handleCloseMassiveResponseModal,
      open: openMassiveModalResult,
      wsResponseFailed: succesTrx,
      wsResponseSuccess: failedTrx,
    },
    menuOptions: defaultMenuOptions[statusFlow],
    minDate,
    modal: {
      errorCondition,
      handleAcceptProcessTransactions,
      handleChange,
      handleCloseProcessTransactionsModal,
      handleHistoricAcceptProcessTransactions,
      modalRefundData,
      setModalRefundData,
      setValue,
      value,
    },
    orderSort: {
      handleRequestSort,
      order,
      orderBy,
    },
    pagination: {
      handleChangeLimit,
      handleChangePage,
      isMobile: false,
      limit: Number(limit),
      page,
      siblingCount: 0,
      size: "small",
      text: "Transacciones por página",
      trxPerPage: Number(limit),
    },
    paymentMethod,
    refundBox: {
      actionHandler,
      modalRefundData: {
        errorConditionRefund,
        handleAcceptRefundTransactions,
        handleChangeRefund,
        handleCloseRefundTransactionsModal,
        openDialog,
        setValueRefund,
        subTitle,
        title,
        trxSelectedCheckCancel,
        trxSelectedCheckGiveBack,
        trxSelectedCheckOmit,
        trxSelectedCheckRefund,
        valueRefund,
      },
      statusFlow,
      trxSelectedCheck,
    },
    setActionFilter: handleSetActionFilter,
    state,
    statusFlow,
    tableOptions,
    tabs: {
      handleTabsChange,
      value: activeTab!,
    },
  };
};
