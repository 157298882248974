import { IAuthInfo } from "../shared/infrastructure/interfaces/IAuthInfo";
import {
  IAppAction,
  IDefaultProcessors,
  IProcessorsType,
} from "./actionCreators";
import { INotification } from "../shared/infrastructure/interfaces/INotification";
import { ActionTypes } from "./actionTypes";
import {
  AdditionalStepsEnum,
  StepsEnum,
} from "../shared/infrastructure/constants/CreateMerchantConstants";
import { AffiliationLead } from "../../types/affiliation_lead";
import { MerchantCreateUpdateResponse } from "../../types/merchant_create_update_response";
import { SemaphoreData, StatusSteps } from "../../types/remote/semaphore_data";
import { MerchantUsers } from "../../types/merchant_users";
import { Credential } from "../shared/infrastructure/interfaces/Credential";
import { Webhook } from "../shared/infrastructure/interfaces/Webhook";
import { RatesConfigDynamo } from "../../types/rates_config_dynamo";
import { DiscountResponse } from "../../types/discount_response";
import { RateResponse } from "../../types/rate_response";
import { MerchantProcessors } from "../../types/merchant_processors";
import { AccountPreferencesResponse } from "../../types/account_preferences_response";
import { DeferredOption } from "../shared/infrastructure/interfaces/DeferredOptions";
import { MerchantProcessorsRules } from "../../types/merchant_processors_rules_response";
import { StatusSemaphoreEnum } from "../shared/infrastructure/catalogs/StatusSemaphoreEnum";
import { SearchResponse } from "../../types/search_response";
import { GetAppConfigPaymentMethodsResponse } from "../../types/get_app_config_payment_method_response";

export type StatusStepsState = {
  name: StepsEnum | AdditionalStepsEnum;
  status: StatusSteps;
};

export interface IAppState {
  authInfo?: IAuthInfo;
  discountInfo?: DiscountResponse;
  notification?: INotification;
  loading?: boolean;
  loadingDefaultProcessors?: boolean;
  loadingFailover?: boolean;
  affiliationLeads?: AffiliationLead[];
  merchantAffiliation?: MerchantCreateUpdateResponse;
  isEdit?: boolean;
  rate?: RateResponse;
  steps?: StatusStepsState[];
  semaphore?: SemaphoreData;
  merchantServices?: object;
  merchantUsers?: MerchantUsers;
  deferred?: DeferredOption[];
  processorRules?: MerchantProcessorsRules;
  merchantCredentials?: Credential[];
  merchantWebhooks?: Webhook[];
  processors?: IProcessorsType;
  merchantProcessors?: MerchantProcessors[];
  defaultProcessor?: IDefaultProcessors;
  defaultPayoutTransferProcessor?: IDefaultProcessors;
  rates?: RatesConfigDynamo[];
  accountPreferences?: AccountPreferencesResponse;
  isloaderDeferred?: boolean;
  deferredSwitch?: boolean;
  statusProcessor?: object;
  retries?: SearchResponse;
  appConfigPaymentMethods?: GetAppConfigPaymentMethodsResponse;
}

export const INITIAL_STATE: IAppState = {
  notification: {
    type: "success",
    open: false,
    message: "",
  },
  loading: false,
  steps: [
    { name: StepsEnum.stepBasicData, status: "pending" },
    { name: StepsEnum.stepProcessor, status: "pending" },
    { name: StepsEnum.stepServices, status: "pending" },
    { name: StepsEnum.stepConfigRatesAndInvoice, status: "pending" },
    { name: StepsEnum.stepUsers, status: "pending" },
    { name: AdditionalStepsEnum.developers, status: "omitted" },
    { name: AdditionalStepsEnum.accountPreferences, status: "omitted" },
  ],
  isloaderDeferred: false,
  statusProcessor: {
    card: StatusSemaphoreEnum.OMITTED,
    transfer: StatusSemaphoreEnum.OMITTED,
    cash: StatusSemaphoreEnum.OMITTED,
  },
  appConfigPaymentMethods: [],
};

export const reducer = (
  state: IAppState = INITIAL_STATE,
  action: IAppAction
): IAppState => {
  switch (action.type) {
    case ActionTypes.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.notification,
      };
    case ActionTypes.SHOW_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.HIDE_LOADING:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.SET_LOADING_DEFAULT_PROCESSORS:
      return {
        ...state,
        loadingDefaultProcessors: action.loadingDefaultProcessors,
      };
    case ActionTypes.SET_LOADING_FAILOVER:
      return {
        ...state,
        loadingFailover: action.loadingFailover,
      };
    case ActionTypes.SET_AUTH_INFO:
      return {
        ...state,
        authInfo: action.authInfo,
      };
    case ActionTypes.SET_AFFILIATION_LEADS:
      return {
        ...state,
        affiliationLeads: action.affiliationLeads,
      };
    case ActionTypes.SET_MERCHANT_AFFILIATION:
      return {
        ...state,
        merchantAffiliation: action.merchantAffiliation,
        isEdit: false,
      };
    case ActionTypes.SET_IS_EDIT:
      return {
        ...state,
        isEdit: action.isEdit,
      };
    case ActionTypes.SET_STEPS_STATUS:
      return {
        ...state,
        steps: action.steps,
      };
    case ActionTypes.SET_SEMAPHORE_DATA:
      return {
        ...state,
        semaphore: action.semaphore,
      };
    case ActionTypes.SET_MERCHANT_SERVICES:
      return {
        ...state,
        merchantServices: action.merchantServices,
      };
    case ActionTypes.SET_DISCOUNT_INFO:
      return {
        ...state,
        discountInfo: action.discountInfo,
      };
    case ActionTypes.SET_RATE_MERCHANT:
      return {
        ...state,
        rate: action.rate,
      };
    case ActionTypes.SET_MERCHANT_USERS:
      return {
        ...state,
        merchantUsers: action.merchantUsers,
      };
    case ActionTypes.SET_MERCHANT_CREDENTIALS:
      return {
        ...state,
        merchantCredentials: action.merchantCredentials,
      };
    case ActionTypes.SET_MERCHANT_WEBHOOKS:
      return {
        ...state,
        merchantWebhooks: action.merchantWebhooks,
      };
    case ActionTypes.SET_DEFERRED:
      return {
        ...state,
        deferred: action.deferred,
      };
    case ActionTypes.SET_RATES:
      return {
        ...state,
        rates: action.rates,
      };
    case ActionTypes.SET_PROCESSORS:
      return {
        ...state,
        processors: action.processors,
      };
    case ActionTypes.SET_DEFAULT_PROCESSOR:
      return {
        ...state,
        defaultProcessor: action.defaultProcessor,
      };
    case ActionTypes.SET_DEFAULT_PAYOUTS_TRANSFER_PROCESSOR:
      return {
        ...state,
        defaultPayoutTransferProcessor: action.defaultPayoutTransferProcessor,
      };
    case ActionTypes.SET_ACCOUNT_PREFERENCES:
      return {
        ...state,
        accountPreferences: action.accountPreferences,
      };
    case ActionTypes.SET_PROCESSORS_RULES:
      return {
        ...state,
        processorRules: action.processorRules,
      };
    case ActionTypes.SET_LOADER_DEFERRED:
      return {
        ...state,
        isloaderDeferred: action.isloaderDeferred,
      };
    case ActionTypes.SET_DEFERREDSIWCHT:
      return {
        ...state,
        deferredSwitch: action.deferredSwitch,
      };
    case ActionTypes.SET_STATUS_PROCESSOR_RATES:
      return {
        ...state,
        statusProcessor: action.statusProcessor,
      };
    case ActionTypes.SET_RETRIES:
      return {
        ...state,
        retries: action.retries,
      };
    case ActionTypes.SET_APPCONFIG_PAYMENT_METHODS:
      return {
        ...state,
        appConfigPaymentMethods: action.appConfigPaymentMethods,
      };
    default:
      return state;
  }
};
