/**
 * Catalog ElSalvador
 */
import { Category } from "../constants/MerchantInformationConstants";
import { CatalogsEnum } from "../infrastructure/catalogs-enum";
import { CurrencyEnum } from "../infrastructure/currency-enum";

export const CatalogEl: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { name: "DUI", value: "0" },
    { name: "NIT", value: "1" },
  ],
  [CatalogsEnum.Banks]: [
    { name: "BANCO CITIBANK DE EL SALVADOR S.A.", value: "10100003" },
    { name: "BANCO AGRICOLA S.A.", value: "10100001" },
    { name: "BANCO CENTRAL DE RESERVA DE EL SALVADOR", value: "10100002" },
    { name: "BANCO DE AMERICA CENTRAL S.A.", value: "10100004" },
    { name: "BANCO HIPOTECARIO DE EL SALVADOR S.A.", value: "10100005" },
    { name: "BANCO HSBC SALVADORENO S.A.", value: "10100006" },
    { name: "BANCO MULTISECTORIAL DE INVERSIONES", value: "10100007" },
    { name: "BANCO PROCREDIT S.A.", value: "10100008" },
    { name: "SCOTIABANK EL SALVADOR S.A.", value: "10100009" },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { name: "Corriente", value: "0" },
    { name: "Ahorros", value: "1" },
  ],
  [CatalogsEnum.Currency]: [{ name: "Dólares", value: CurrencyEnum.USD }],
  [CatalogsEnum.Provinces]: [
    { name: "Ahuachapán", value: "01" },
    { name: "Santa Ana", value: "02" },
    { name: "Sonsonate", value: "03" },
    { name: "Chalatenango", value: "04" },
    { name: "La Libertad", value: "05" },
    { name: "San Salvador", value: "06" },
    { name: "Cuscatlán", value: "07" },
    { name: "La Paz", value: "08" },
    { name: "Cabañas", value: "09" },
    { name: "San Vicente", value: "10" },
    { name: "Usulután", value: "11" },
    { name: "San Miguel", value: "12" },
    { name: "Morazán", value: "13" },
    { name: "La Unión", value: "14" },
  ],
  [CatalogsEnum.Cities]: [
    { name: "Ahuachapán", parent: "01", value: "0101" },
    { name: "Apaneca", parent: "01", value: "0102" },
    { name: "Atiquizaya", parent: "01", value: "0103" },
    { name: "Concepción de Ataco", parent: "01", value: "0104" },
    { name: "El Refugio", parent: "01", value: "0105" },
    { name: "Guaymango", parent: "01", value: "0106" },
    { name: "Jujutla", parent: "01", value: "0107" },
    { name: "San Francisco Menéndez", parent: "01", value: "0108" },
    { name: "San Lorenzo", parent: "01", value: "0109" },
    { name: "San Pedro Puxtla", parent: "01", value: "0110" },
    { name: "Tacuba", parent: "01", value: "0111" },
    { name: "Turín", parent: "01", value: "0112" },
    { name: "Candelaria de la Frontera", parent: "02", value: "0201" },
    { name: "Chalchuapa", parent: "02", value: "0202" },
    { name: "Coatepeque", parent: "02", value: "0203" },
    { name: "El Congo", parent: "02", value: "0204" },
    { name: "El Porvenir", parent: "02", value: "0205" },
    { name: "Masahuat", parent: "02", value: "0206" },
    { name: "Metapán", parent: "02", value: "0207" },
    { name: "San Antonio Pajonal", parent: "02", value: "0208" },
    { name: "San Sebastián Salitrillo", parent: "02", value: "0209" },
    { name: "Santa Ana", parent: "02", value: "0210" },
    { name: "Santa Rosa Guachipilín", parent: "02", value: "0211" },
    { name: "Santiago de la Frontera", parent: "02", value: "0212" },
    { name: "Texistepeque", parent: "02", value: "0213" },
    { name: "Acajutla", parent: "03", value: "0301" },
    { name: "Armenia", parent: "03", value: "0302" },
    { name: "Caluco", parent: "03", value: "0303" },
    { name: "Cuisnahuat", parent: "03", value: "0304" },
    { name: "Santa Isabel Ishuatán", parent: "03", value: "0305" },
    { name: "Izalco", parent: "03", value: "0306" },
    { name: "Juayúa", parent: "03", value: "0307" },
    { name: "Nahuizalco", parent: "03", value: "0308" },
    { name: "Nahulingo", parent: "03", value: "0309" },
    { name: "Salcoatitán", parent: "03", value: "0310" },
    { name: "San Antonio del Monte", parent: "03", value: "0311" },
    { name: "San Julián", parent: "03", value: "0312" },
    { name: "Santa Catarina Masahuat", parent: "03", value: "0313" },
    { name: "Santo Domingo de Guzmán", parent: "03", value: "0314" },
    { name: "Sonsonate", parent: "03", value: "0315" },
    { name: "Sonzacate", parent: "03", value: "0316" },
    { name: "Agua Caliente", parent: "04", value: "0401" },
    { name: "Arcatao", parent: "04", value: "0402" },
    { name: "Azacualpa", parent: "04", value: "0403" },
    { name: "Citalá", parent: "04", value: "0404" },
    { name: "Comalapa", parent: "04", value: "0405" },
    { name: "Concepción Quezaltepeque", parent: "04", value: "0406" },
    { name: "Chalatenango", parent: "04", value: "0407" },
    { name: "Dulce Nombre de María", parent: "04", value: "0408" },
    { name: "El Carrizal", parent: "04", value: "0409" },
    { name: "El Paraíso", parent: "04", value: "0410" },
    { name: "La Laguna", parent: "04", value: "0411" },
    { name: "La Palma", parent: "04", value: "0412" },
    { name: "La Reina", parent: "04", value: "0413" },
    { name: "Las Vueltas", parent: "04", value: "0414" },
    { name: "Nombre de Jesús", parent: "04", value: "0415" },
    { name: "Nueva Concepción", parent: "04", value: "0416" },
    { name: "Nueva Trinidad", parent: "04", value: "0417" },
    { name: "Ojos de Agua", parent: "04", value: "0418" },
    { name: "Potonico", parent: "04", value: "0419" },
    { name: "San Antonio de la Cruz", parent: "04", value: "0420" },
    { name: "San Antonio los Ranchos", parent: "04", value: "0421" },
    { name: "San Fernando", parent: "04", value: "0422" },
    { name: "San Francisco Lempa", parent: "04", value: "0423" },
    { name: "San Francisco Morazán", parent: "04", value: "0424" },
    { name: "San Ignacio", parent: "04", value: "0425" },
    { name: "San Isidro Labrador", parent: "04", value: "0426" },
    { name: "San José Cancasque", parent: "04", value: "0427" },
    { name: "Las Flores", parent: "04", value: "0428" },
    { name: "San Luis del Carmen", parent: "04", value: "0429" },
    { name: "San Miguel de Mercedes", parent: "04", value: "0430" },
    { name: "San Rafael", parent: "04", value: "0431" },
    { name: "Santa Rita", parent: "04", value: "0432" },
    { name: "Tejutla", parent: "04", value: "0433" },
    { name: "Antiguo Cuscatlán", parent: "05", value: "0501" },
    { name: "Ciudad Arce", parent: "05", value: "0502" },
    { name: "Colón", parent: "05", value: "0503" },
    { name: "Comasagua", parent: "05", value: "0504" },
    { name: "Chiltiupán", parent: "05", value: "0505" },
    { name: "Huizúcar", parent: "05", value: "0506" },
    { name: "Jayaque", parent: "05", value: "0507" },
    { name: "Jicalapa", parent: "05", value: "0508" },
    { name: "La Libertad", parent: "05", value: "0509" },
    { name: "Nuevo Cuscatlán", parent: "05", value: "0510" },
    { name: "Santa Tecla", parent: "05", value: "0511" },
    { name: "Quezaltepeque", parent: "05", value: "0512" },
    { name: "Sacacoyo", parent: "05", value: "0513" },
    { name: "San José Villanueva", parent: "05", value: "0514" },
    { name: "San Juan Opico", parent: "05", value: "0515" },
    { name: "San Matías", parent: "05", value: "0516" },
    { name: "San Pablo Tacachico", parent: "05", value: "0517" },
    { name: "Tamanique", parent: "05", value: "0518" },
    { name: "Talnique", parent: "05", value: "0519" },
    { name: "Teotepeque", parent: "05", value: "0520" },
    { name: "Tepecoyo", parent: "05", value: "0521" },
    { name: "Zaragoza", parent: "05", value: "0522" },
    { name: "Aguilares", parent: "06", value: "0601" },
    { name: "Apopa", parent: "06", value: "0602" },
    { name: "Ayutuxtepeque", parent: "06", value: "0603" },
    { name: "Cuscatancingo", parent: "06", value: "0604" },
    { name: "El Paisnal", parent: "06", value: "0605" },
    { name: "Guazapa", parent: "06", value: "0606" },
    { name: "Ilopango", parent: "06", value: "0607" },
    { name: "Mejicanos", parent: "06", value: "0608" },
    { name: "Nejapa", parent: "06", value: "0609" },
    { name: "Panchimalco", parent: "06", value: "0610" },
    { name: "Rosario de Mora", parent: "06", value: "0611" },
    { name: "San Marcos", parent: "06", value: "0612" },
    { name: "San Martín", parent: "06", value: "0613" },
    { name: "San Salvador", parent: "06", value: "0614" },
    { name: "Santiago Texacuangos", parent: "06", value: "0615" },
    { name: "Santo Tomás", parent: "06", value: "0616" },
    { name: "Soyapango", parent: "06", value: "0617" },
    { name: "Tonacatepeque", parent: "06", value: "0618" },
    { name: "Delgado", parent: "06", value: "0619" },
    { name: "Candelaria", parent: "07", value: "0701" },
    { name: "Cojutepeque", parent: "07", value: "0702" },
    { name: "El Carmen", parent: "07", value: "0703" },
    { name: "El Rosario", parent: "07", value: "0704" },
    { name: "Monte San Juan", parent: "07", value: "0705" },
    { name: "Oratorio de Concepción", parent: "07", value: "0706" },
    { name: "San Bartolomé Perulapía", parent: "07", value: "0707" },
    { name: "San Cristóbal", parent: "07", value: "0708" },
    { name: "San José Guayabal", parent: "07", value: "0709" },
    { name: "San Pedro Perulapán", parent: "07", value: "0710" },
    { name: "San Rafael Cedros", parent: "07", value: "0711" },
    { name: "San Ramón", parent: "07", value: "0712" },
    { name: "Santa Cruz Analquito", parent: "07", value: "0713" },
    { name: "Santa Cruz Michapa", parent: "07", value: "0714" },
    { name: "Suchitot", parent: "07", value: "0715" },
    { name: "Tenancingo", parent: "07", value: "0716" },
    { name: "Cuyultitán", parent: "08", value: "0801" },
    { name: "El Rosario", parent: "08", value: "0802" },
    { name: "Jerusalén", parent: "08", value: "0803" },
    { name: "Mercedes La Ceiba", parent: "08", value: "0804" },
    { name: "Olocuilta", parent: "08", value: "0805" },
    { name: "Paraíso de Osorio", parent: "08", value: "0806" },
    { name: "San Antonio Masahuat", parent: "08", value: "0807" },
    { name: "San Emigdio", parent: "08", value: "0808" },
    { name: "San Francisco Chinameca", parent: "08", value: "0809" },
    { name: "San Juan Nonualco", parent: "08", value: "0810" },
    { name: "San Juan Talpa", parent: "08", value: "0811" },
    { name: "San Juan Tepezontes", parent: "08", value: "0812" },
    { name: "San Luis Talpa", parent: "08", value: "0813" },
    { name: "San Miguel Tepezontes", parent: "08", value: "0814" },
    { name: "San Pedro Masahuat", parent: "08", value: "0815" },
    { name: "San Pedro Nonualco", parent: "08", value: "0816" },
    { name: "San Rafael Obrajuelo", parent: "08", value: "0817" },
    { name: "Santa María Ostuma", parent: "08", value: "0818" },
    { name: "Santiago Nonualco", parent: "08", value: "0819" },
    { name: "Tapalhuaca", parent: "08", value: "0820" },
    { name: "Zacatecoluca", parent: "08", value: "0821" },
    { name: "San Luis La Herradura", parent: "08", value: "0822" },
    { name: "Cinquera", parent: "09", value: "0901" },
    { name: "Guacotecti", parent: "09", value: "0902" },
    { name: "Ilobasco", parent: "09", value: "0903" },
    { name: "Jutiapa", parent: "09", value: "0904" },
    { name: "San Isidro", parent: "09", value: "0905" },
    { name: "Sensuntepeque", parent: "09", value: "0906" },
    { name: "Tejutepeque", parent: "09", value: "0907" },
    { name: "Victoria", parent: "09", value: "0908" },
    { name: "Dolores", parent: "09", value: "0909" },
    { name: "Apastepeque", parent: "10", value: "1001" },
    { name: "Guadalupe", parent: "10", value: "1002" },
    { name: "San Cayetano Istepeque", parent: "10", value: "1003" },
    { name: "Santa Clara", parent: "10", value: "1004" },
    { name: "Santo Domingo", parent: "10", value: "1005" },
    { name: "San Esteban Catarina", parent: "10", value: "1006" },
    { name: "San Ildefonso", parent: "10", value: "1007" },
    { name: "San Lorenzo", parent: "10", value: "1008" },
    { name: "San Sebastián", parent: "10", value: "1009" },
    { name: "San Vicente", parent: "10", value: "1010" },
    { name: "Tecoluca", parent: "10", value: "1011" },
    { name: "Tepetitán", parent: "10", value: "1012" },
    { name: "Verapaz", parent: "10", value: "1013" },
    { name: "Alegría", parent: "11", value: "1101" },
    { name: "Berlín", parent: "11", value: "1102" },
    { name: "California", parent: "11", value: "1103" },
    { name: "Concepción Batres", parent: "11", value: "1104" },
    { name: "El Triunfo", parent: "11", value: "1105" },
    { name: "Ereguayquín", parent: "11", value: "1106" },
    { name: "Estanzuelas", parent: "11", value: "1107" },
    { name: "Jiquilisco", parent: "11", value: "1108" },
    { name: "Jucuapa", parent: "11", value: "1109" },
    { name: "Jucuarán", parent: "11", value: "1110" },
    { name: "Mercedes Umaña", parent: "11", value: "1111" },
    { name: "Nueva Granada", parent: "11", value: "1112" },
    { name: "Ozatlán", parent: "11", value: "1113" },
    { name: "Puerto El Triunfo", parent: "11", value: "1114" },
    { name: "San Agustín", parent: "11", value: "1115" },
    { name: "San Buenaventura", parent: "11", value: "1116" },
    { name: "San Dionisio", parent: "11", value: "1117" },
    { name: "Santa Elena", parent: "11", value: "1118" },
    { name: "San Francisco Javier", parent: "11", value: "1119" },
    { name: "Santa María", parent: "11", value: "1120" },
    { name: "Santiago de María", parent: "11", value: "1121" },
    { name: "Tecapán", parent: "11", value: "1122" },
    { name: "Usulután", parent: "11", value: "1123" },
    { name: "Carolina", parent: "12", value: "1201" },
    { name: "Ciudad Barrios", parent: "12", value: "1202" },
    { name: "Comacarán", parent: "12", value: "1203" },
    { name: "Chapeltique", parent: "12", value: "1204" },
    { name: "Chinameca", parent: "12", value: "1205" },
    { name: "Chirilagua", parent: "12", value: "1206" },
    { name: "El Tránsito", parent: "12", value: "1207" },
    { name: "Lolotique", parent: "12", value: "1208" },
    { name: "Moncagua", parent: "12", value: "1209" },
    { name: "Nueva Guadalupe", parent: "12", value: "1210" },
    { name: "Nuevo Edén de San Juan", parent: "12", value: "1211" },
    { name: "Quelepa", parent: "12", value: "1212" },
    { name: "San Antonio del Mosco", parent: "12", value: "1213" },
    { name: "San Gerardo", parent: "12", value: "1214" },
    { name: "San Jorge", parent: "12", value: "1215" },
    { name: "San Luis de la Reina", parent: "12", value: "1216" },
    { name: "San Miguel", parent: "12", value: "1217" },
    { name: "San Rafael Oriente", parent: "12", value: "1218" },
    { name: "Sesori", parent: "12", value: "1219" },
    { name: "Uluazapa", parent: "12", value: "1220" },
    { name: "Arambala", parent: "13", value: "1301" },
    { name: "Cacaopera", parent: "13", value: "1302" },
    { name: "Corinto", parent: "13", value: "1303" },
    { name: "Chilanga", parent: "13", value: "1304" },
    { name: "Delicias de Concepción", parent: "13", value: "1305" },
    { name: "El Divisadero", parent: "13", value: "1306" },
    { name: "El Rosario", parent: "13", value: "1307" },
    { name: "Gualococti", parent: "13", value: "1308" },
    { name: "Guatajiagua", parent: "13", value: "1309" },
    { name: "Joateca", parent: "13", value: "1310" },
    { name: "Jocoaitique", parent: "13", value: "1311" },
    { name: "Jocoro", parent: "13", value: "1312" },
    { name: "Lolotiquillo", parent: "13", value: "1313" },
    { name: "Meanguera", parent: "13", value: "1314" },
    { name: "Osicala", parent: "13", value: "1315" },
    { name: "Perquín", parent: "13", value: "1316" },
    { name: "San Carlos", parent: "13", value: "1317" },
    { name: "San Fernando", parent: "13", value: "1318" },
    { name: "San Francisco Gotera", parent: "13", value: "1319" },
    { name: "San Isidro", parent: "13", value: "1320" },
    { name: "San Simón", parent: "13", value: "1321" },
    { name: "Sensembra", parent: "13", value: "1322" },
    { name: "Sociedad", parent: "13", value: "1323" },
    { name: "Torola", parent: "13", value: "1324" },
    { name: "Yamabal", parent: "13", value: "1325" },
    { name: "Yoloaiquín", parent: "13", value: "1326" },
    { name: "Anamoros", parent: "14", value: "1401" },
    { name: "Bolívar", parent: "14", value: "1402" },
    { name: "Concepcion de Oriente", parent: "14", value: "1403" },
    { name: "Conchagua", parent: "14", value: "1404" },
    { name: "El Carmen", parent: "14", value: "1405" },
    { name: "El Sauce", parent: "14", value: "1406" },
    { name: "Intipuca", parent: "14", value: "1407" },
    { name: "La Unión", parent: "14", value: "1408" },
    { name: "Lislique", parent: "14", value: "1409" },
    { name: "Meanguera del Golfo", parent: "14", value: "1410" },
    { name: "Nueva Esparta", parent: "14", value: "1411" },
    { name: "Pasaquina", parent: "14", value: "1412" },
    { name: "Polorós", parent: "14", value: "1413" },
    { name: "San Alejo", parent: "14", value: "1414" },
    { name: "San José", parent: "14", value: "1415" },
    { name: "Santa Rosa de Lima", parent: "14", value: "1416" },
    { name: "Yayantique", parent: "14", value: "1417" },
    { name: "Yucuaiquín", parent: "14", value: "1418" },
  ],
  [CatalogsEnum.PersonTypes]: [
    { name: "Física", value: "01" },
    { name: "Moral", value: "02" },
  ],
};
