import { createSlice } from "@reduxjs/toolkit";
import { IPaymentMethodObject } from "../../../shared/infrastructure/interface/IPaymentMethodObject";
import { getServices } from "../../thunks/processors/processors.thunks";
import { PaymentMethodEnum } from "../../../shared/enums/PaymentMethodEnum";
import { StatusTypeEnum } from "../../../shared/enums/StatusTypeEnum";
import { IProcessorFetch } from "../../../../types/processor_fetch_scheme";
import { ProcessorTypeEnum } from "../../../shared/enums/ProcessorTypeEnum";
import { cloneDeep } from "lodash";
import { PaymentMethodObject } from "../../../shared/constants/PaymentMethodConstants";

export interface Processors {
  processors?: IPaymentMethodObject;
  isLoadingServices?: boolean;
}

export const initialStateProcessors: Processors = {
  isLoadingServices: true,
};

export const processorsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getServices.fulfilled, (state, action) => {
      let data: IProcessorFetch[] = [...action.payload];

      let paymentMethod: IPaymentMethodObject = cloneDeep(PaymentMethodObject);

      data.map((processor: IProcessorFetch) => {
        let processorName =
          processor.paymentMethod === PaymentMethodEnum.TRANSFER_SUBSCRIPTIONS
            ? PaymentMethodEnum.TRANSFER
            : processor.paymentMethod;

        if (
          paymentMethod[processorName.replace("-", "_")].processorType !== "" &&
          paymentMethod[processorName.replace("-", "_")].processorType !==
            processor.processorType
        ) {
          paymentMethod[processorName.replace("-", "_")].processorType =
            ProcessorTypeEnum.GATEWAY_AGGREGATOR;
        } else {
          paymentMethod[processorName.replace("-", "_")].processorType =
            processor.processorType;
        }
        paymentMethod[processorName.replace("-", "_")].status =
          StatusTypeEnum.ACTIVE;
      });

      for (let value in paymentMethod) {
        if (paymentMethod[value].processorType === "") {
          paymentMethod[value].processorType = ProcessorTypeEnum.GATEWAY;
        }
      }

      state.processors = paymentMethod;

      state.isLoadingServices = false;
    });
    builder.addCase(getServices.pending, (state) => {
      state.isLoadingServices = true;
    });
    builder.addCase(getServices.rejected, (state) => {
      state.isLoadingServices = true;
    });
  },
  initialState: initialStateProcessors,
  name: " processors",
  reducers: {},
});

export default processorsSlice.reducer;
