export enum InternationalTransferEnum {
  paymentProcessorTitle = "Procesador de pago",
  paymentProcessorDesc = "Selecciona el procesador que vas a agregar según el método de pago elegido.",
  paymentProcessorChipHeader = "Procesador para:",
  processorBasicInfoTitle = "Información básica",
  processorBasicInfoDesc = "Elige el modelo y categoría para el procesador que vas a agregar.",
  processorInfoTitle = "Información del procesador",
  processorInfoDesc = "Ingresa los datos principales del procesador.",
  processorInfoHeader = "Datos del procesador",
  processorInfoCheckDesc = "Configurar este procesador por defecto para Tranf. Internacional",
  AddProcessorHeader = "Agregar Procesador",
  EditProcessorHeader = "Editar Procesador",
  ModalBodyDialog = "Puedes seguir agregando procesadores para otros medios de pago desde el panel de configuración",
  RedirectLabel = "Volver al panel de configuración",
}
