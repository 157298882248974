import {
  Configs,
  GetNodeInfoResponse,
} from "../../../types/get_node_info_response";
import { findConfigById } from "../../containers/ProcessingConfigIndex/state/useOnInitProcessing";
import {
  ConfigurationCodeEnum,
  EntityNameEnum,
} from "../../shared/enums/ProcessingStepEnum";
import { NodeInfoPath } from "../../shared/enums/NodeInfoPath";
import { get, isEmpty, isEqual } from "lodash";
import { Data } from "../../../types/branch_edit_list";
import { StatusStepEnum } from "../../shared/enums/StatusStepEnum";
import { BranchPathEnum } from "../../shared/infrastructure/enums/SessionStoragePathEnum";

const detectType = (value: any[] | string, entityName: string): boolean => {
  switch (typeof value) {
    case "string":
      return (
        !isEmpty(value) && entityName.toUpperCase() === EntityNameEnum.BRANCH
      );
    default:
      const isArray: boolean = Array.isArray(value);
      if (isArray) {
        return (
          value.length > 0 && entityName.toUpperCase() === EntityNameEnum.BRANCH
        );
      }
      return false;
  }
};

export const isCentralizedBranchDeferred = (
  nodeInfo: GetNodeInfoResponse
): boolean => {
  const deferredConfig = findConfigById(
    ConfigurationCodeEnum.DEFERRED,
    get(nodeInfo, NodeInfoPath.Configs, [])
  );

  const centralizedNodeID = get(
    deferredConfig,
    NodeInfoPath.CentralizedNodesId,
    []
  );

  return detectType(centralizedNodeID, get(nodeInfo, "entityName", ""));
};

export const isDecentralizedBranch = (
  configCode: ConfigurationCodeEnum,
  branch: Data
): boolean => {
  const configDeferredBranch: Configs = findConfigById(
    configCode,
    get(branch, NodeInfoPath.Configs, [])
  );
  return (
    isEmpty(
      get(configDeferredBranch, BranchPathEnum.CENTRALIZED_NODES_ID, "")
    ) && !isEmpty(get(configDeferredBranch, BranchPathEnum.VALUE, ""))
  );
};

export const getDecentralizedBranchStatus = (
  configCode: ConfigurationCodeEnum,
  branch: Data
): boolean => {
  const configDeferredBranch: Configs = findConfigById(
    configCode,
    get(branch, NodeInfoPath.Configs, [])
  );
  return isEqual(
    get(configDeferredBranch, BranchPathEnum.STATUS, ""),
    StatusStepEnum.COMPLETE
  );
};

export const changeConfigBranchStatus = (
  branch: Data,
  type: ConfigurationCodeEnum,
  status: string
): Configs => {
  const branchConfigs: Configs[] = get(branch, NodeInfoPath.Configs, []);
  const branchConfigsUpdate: Configs[] = branchConfigs.filter(
    (config: Configs) => config.configuration !== type
  );
  const branchConfig: Configs = findConfigById(type, branchConfigs);

  branchConfigsUpdate.push({
    ...branchConfig,
    status,
  });

  return { ...branch, configs: branchConfigsUpdate };
};
