import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { setNotification } from "../../../store/actionCreators";
import { INotification } from "../../../shared/infrastructure/interfaces/INotification";

export interface IUseNotification {
  notification: INotification;
  handlers: {
    handleCloseSnackbar: () => void;
  };
}

export const useNotification = (): IUseNotification => {
  const dispatch = useDispatch();
  const notification = useSelector((state: IAppState) => state.notification!);
  const handleCloseSnackbar = () => {
    dispatch(
      setNotification({
        open: false,
        message: notification.message,
        type: notification.type,
      })
    );
  };

  return {
    notification,
    handlers: {
      handleCloseSnackbar,
    },
  };
};
