import React from "react";
import { Box, Skeleton } from "@mui/material";
import { myMerchantContainerStyles as styles } from "../../../containers/MyMerchantContainer/MyMerchantContainer.styles";

export const SubHeaderSkeleton: React.FC = () => {
  return (
    <Box sx={styles.boxSubHeader} data-testid="sub-header-skeleton">
      <Box alignSelf={"flex-start"}>
        <Box sx={styles.boxSubHeaderMerchant}>
          <Skeleton variant="rectangular" sx={styles.skeleton} />
        </Box>
      </Box>
      <Box sx={styles.boxSubHeaderMerchant}>
        <Skeleton variant="rectangular" sx={styles.skeleton} />
      </Box>
    </Box>
  );
};
