import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { SPA_BASENAME } from "./shared/constants/routes";
import { store } from "./store/store";
import { MerchantMigrationIndex } from "./containers/MerchantMigrationIndex/MerchantMigrationIndex";
import theme from "./theme";
import { ThemeProvider } from "@material-ui/core/styles";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
import { environment } from "./environments/environment";
import { SecurityWrapperEnum } from "./shared/constants/SecurityWrapperEnum";

const Root = () => {
  return (
    <React.StrictMode>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&display=swap');"
        }
      </style>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500&display=swap');"
        }
      </style>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ModuleSecurityWrapper
            basePath={environment.kushkiUrl}
            componentId={SecurityWrapperEnum.MODULE_ID}
          >
            <BrowserRouter basename={SPA_BASENAME}>
              <Routes>
                <Route index element={<MerchantMigrationIndex />} />
              </Routes>
            </BrowserRouter>
          </ModuleSecurityWrapper>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
};

export default Root;
