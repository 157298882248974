import { createNamedStyles } from "../../shared/infrastructure/interfaces/create_named_styles";

export const mainSubHeaderStyles = createNamedStyles({
  divider: {
    border: "1px solid #98A8B8",
  },
  flagPadding: {
    paddingX: 2,
  },
  gridTitle: {
    paddingBottom: 1.5,
  },
  gridText: {
    paddingRight: 2,
  },
  gridCountry: {
    paddingLeft: 1.7,
    paddingRight: 3,
  },
  gridId: {
    paddingLeft: {
      md: 3,
      xs: 1,
    },
    paddingRight: 1.7,
  },
  copyButton: {
    color: "#023365",
    "&:hover": {
      backgroundColor: "#ECF0F1",
    },
    "&:active": {
      backgroundColor: "#F7F9F9",
    },
  },
  maskTitle: {
    width: 250,
    height: 55,
  },
  maskCountryTitle: {
    width: 160,
    height: 20,
  },
  maskCountryFlag: {
    width: 30,
    height: 30,
  },
  maskCountry: {
    width: 30,
    height: 30,
  },
  maskIdTitle: {
    width: 30,
    height: 20,
  },
  maskId: {
    width: 200,
    height: 20,
  },
});
