import React from "react";
import {
  CircularProgress,
  createStyles,
  Grid,
  Icon,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { get } from "lodash";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  createFilterOptions,
} from "@material-ui/lab";
import { Search } from "react-feather";

export interface AutocompleteFilterTableProps {
  property: string;
  label: string;
  merchantName: string;
  options: object[];
  pathValue: string;
  pathName: string;
  isLoading: boolean;
  handleSearchText: (key: string) => void;
  handleSetMerchantName: (searchText: string) => void;
  handleSearch: (merchantId: string) => void;
  handlerSetMerchantNameMigrateRules: (merchantName: string) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    filterTable: {
      padding: "7px",
      backgroundColor: "#F7FAFC",
      color: "#293036",
      maxHeight: "75px",
    },
    textField: {
      marginTop: 6,
      backgroundColor: "white",
    },
    icon: {
      position: "absolute",
      width: 20,
      height: 25,
      top: 15,
      right: 15,
      cursor: "pointer",
    },
    hide: {
      display: "none",
    },
  })
);

export const AutocompleteFilterTable: React.FC<AutocompleteFilterTableProps> = (
  props: AutocompleteFilterTableProps
) => {
  const classes = useStyles();

  const filterOptions = createFilterOptions({
    stringify: (option) =>
      get(option, props.pathName, "") + " " + get(option, props.pathValue, ""),
  });

  let showIcon: boolean = true;

  return (
    <React.Fragment>
      <Grid container className={classes.filterTable}>
        <Grid item xs={12} md={6} lg={3}>
          <Autocomplete
            id={"autocomplete-rule"}
            onKeyDown={(event) => props.handleSearchText(event.key)}
            inputValue={props.merchantName}
            options={props.options}
            onInputChange={(_event: any, newInputValue) => {
              props.handleSetMerchantName(newInputValue);
            }}
            filterOptions={filterOptions}
            onChange={(_event: any, value: any) => {
              props.handlerSetMerchantNameMigrateRules(
                get(value, "_source.name", "")
              );
              props.handleSearch(get(value, "_source.publicMerchantId"));
            }}
            getOptionLabel={(option) => get(option, props.pathName, "")}
            selectOnFocus
            onFocus={() => (showIcon = false)}
            onPointerLeave={() => (showIcon = true)}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <React.Fragment>
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {props.isLoading ? (
                          <CircularProgress color="primary" size={18} />
                        ) : null}
                      </React.Fragment>
                    ),
                  }}
                  className={classes.textField}
                  label={props.label}
                  variant="outlined"
                />
                <Icon style={{ position: "relative" }}>
                  <Search
                    id={"searchIcon"}
                    data-testid="icon"
                    className={showIcon ? classes.icon : classes.hide}
                    onClick={(_event: any) => {
                      props.handleSearchText("Enter");
                    }}
                  ></Search>
                </Icon>
              </React.Fragment>
            )}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
