/* istanbul ignore file */
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { IAppAction, setNotification } from "./store/actionCreators";
import React, { Suspense } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { environment } from "./environments/environment";
import { IAppState } from "./store/reducer";
import { INotification } from "./shared/infrastructure/interfaces/INotification";
import { ModuleIdEnum } from "./shared/infrastructure/constants/ModuleIdEnum";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
// @ts-ignore
import Normalize from "react-normalize";
import { RefundIndex } from "./containers/RefundIndex/RefundIndex";
import { routes } from "./shared/infrastructure/routes";
import { SnackBarAlert } from "./components/common/SnackBar/SnackBarAlert";
import { ThunkDispatch } from "redux-thunk";

interface INavigationFunctionsProps {
  setNotification(payload: INotification): void;
}

interface INavigationStateProps {
  notification: INotification;
}

type TNavigationProps = INavigationFunctionsProps & INavigationStateProps;
const Navigation: React.FC<TNavigationProps> = (props: TNavigationProps) => {
  const handleCloseSnackbar = () => {
    props.setNotification({
      horizontal: props.notification.horizontal,
      icon: props.notification.icon,
      message: props.notification.message,
      open: false,
      type: props.notification.type,
      vertical: props.notification.vertical,
    });
  };

  return (
    <>
      <Normalize />
      <BrowserRouter>
        <Suspense fallback={<div />}>
          <Switch>
            <Route
              path={`${routes.BASE_PATH_REFUND}${routes.PATH_EXECUTOR}`}
              exact
            >
              <ModuleSecurityWrapper
                basePath={environment.kushkiUrl}
                componentId={ModuleIdEnum.REFUND_DASHBOARD_EXECUTOR}
              >
                <RefundIndex />
              </ModuleSecurityWrapper>
            </Route>

            <Route
              path={`${routes.BASE_PATH_REFUND}${routes.PATH_VALIDATOR}`}
              exact
            >
              <ModuleSecurityWrapper
                basePath={environment.kushkiUrl}
                componentId={ModuleIdEnum.REFUND_DASHBOARD_VALIDATOR}
              >
                <RefundIndex />
              </ModuleSecurityWrapper>
            </Route>

            <Route
              path={`${routes.BASE_PATH_REFUND}${routes.PATH_VIEWER}`}
              exact
            >
              <ModuleSecurityWrapper
                basePath={environment.kushkiUrl}
                componentId={ModuleIdEnum.REFUND_DASHBOARD_VIEWER}
              >
                <RefundIndex />
              </ModuleSecurityWrapper>
            </Route>
          </Switch>
        </Suspense>
        <SnackBarAlert
          type={props.notification.type}
          message={props.notification.message}
          vertical={props.notification.vertical!}
          icon={props.notification.icon}
          horizontal={props.notification.horizontal!}
          open={props.notification.open}
          handlerClose={handleCloseSnackbar}
          hasOnclose={true}
          autoHideDuration={6000}
          transitionDuration={500}
        />
      </BrowserRouter>
    </>
  );
};

export const mapStateToProps: (state: IAppState) => INavigationStateProps = (
  state: IAppState
): INavigationStateProps => ({
  notification: state.notification!,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => INavigationFunctionsProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): INavigationFunctionsProps => ({
  setNotification: (payload: INotification) =>
    dispatch(setNotification(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
