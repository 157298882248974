import { ConciliationActionEnum } from "../enums/concilation_action_enum";
import { MessageAlertEnum } from "../enums/message_alert_enum";

export const getMessageConciliationAction = (
  conciliationAction: ConciliationActionEnum,
  isSuccess: boolean
) => {
  switch (conciliationAction) {
    case ConciliationActionEnum.PENDING:
      return isSuccess ? MessageAlertEnum.CONCILIATION_STATUS_UPDATED : "";
    case ConciliationActionEnum.OMITTED:
      return isSuccess
        ? MessageAlertEnum.SKIP_SUCCESS
        : MessageAlertEnum.SKIP_ERROR;
    case ConciliationActionEnum.PENDING_REFUND:
    default:
      return isSuccess
        ? MessageAlertEnum.PENDING_REFUND_SUCCESS
        : MessageAlertEnum.PENDING_REFUND_ERROR;
  }
};
