import { GetProcessorsResponse } from "../../../types/get_processors_response";
import { MerchantSettingsResponse } from "../../../types/merchant_settings_response";
import { get } from "lodash";

export const mutateProcessorsSettings = (
  processorsData: GetProcessorsResponse[]
): MerchantSettingsResponse => {
  const merchantSettings: Partial<MerchantSettingsResponse> = {
    processors: {
      "ach transfer": [],
      card: [],
      cash: [],
      "payouts-cash": [],
      "payouts-transfer": [],
      transfer: [],
      "transfer-subscriptions": [],
    },
  };

  processorsData.map((processorItem) => {
    const paymentMethod: string = get(processorItem, "paymentMethod", "");
    const processorName: string = get(processorItem, "processorName", "");

    merchantSettings.processors![paymentMethod].push({
      processorName,
    });
  });

  return <MerchantSettingsResponse>merchantSettings;
};
