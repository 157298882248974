import { combineEpics } from "redux-observable";
import { EpicCustom } from "./epics.interfaces";
import { getProcessorsEpic } from "./getProcessorsEpic/getProcessors.epic";
import { createExpressCheckoutEpic } from "./createExpressCheckout/createExpressCheckout.epic";
import { getIvaValuesEpic } from "./getIvaValues/getIvaValues.epic";

export default combineEpics(
  // @ts-ignore
  createExpressCheckoutEpic,
  getProcessorsEpic,
  getIvaValuesEpic
) as unknown as EpicCustom;
