import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CardIcon from "../../../assets/images/payment_methods_card.svg";
import CashIcon from "../../../assets/images/payment_methods_cash.svg";
import TransferIcon from "../../../assets/images/payment_methods_transfer.svg";
import { IAppState } from "../../../store/reducer";
import { useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import { PayInOutPopupConstants } from "../../../shared/infrastructure/constants/PaymentMethodEnum";
import { CountriesEnum } from "../../../shared/constants/countries-enum";
import {
  ALLOWED_INTERNATIONAL_TRANSFER_COUNTRY,
  centralcounties,
} from "../../../shared/utils";
import { filterLabels } from "../../../shared/constants/filter_component";
import { PaymentMethodEnum } from "../../../shared/infrastructure/constants/PaymentMethodEnum";
import { routes } from "../../../shared/infrastructure/constants/routes";
import { LocalStoragePropertiesEnum } from "../../../shared/infrastructure/constants/LocalStoragePropertiesEnum";

const useStyles = makeStyles(() => ({
  handlerButton: {
    fontWeight: 500,
    fontSize: "14px",
    height: "40px",
    marginLeft: "4px",
  },
  subTitle: { color: "#6D7781", paddingLeft: 10, paddingTop: 5 },
}));

export interface IHeaderMenu {
  id: string | null;
  country: string;
  key: string;
  renderPayoutsCard: boolean;
}

export const HeaderMenu: React.FC<IHeaderMenu> = (props: IHeaderMenu) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();

  const handleCloseEl = (): void => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const [enabledList, setEnabledList] = useState([]);
  const countryConfigProfile = useSelector((state: IAppState) =>
    get(state, "appConfigPaymentMethods", [])
  );

  const showItem = (country: string, payName: string) => {
    if (centralcounties.includes(country)) {
      country = CountriesEnum.CENTRO_AMERICA;
    }
    return !(enabledList[country] || []).includes(payName);
  };

  const isAllowedRenderInternationalTransferOption = (
    country: string
  ): boolean => {
    return ALLOWED_INTERNATIONAL_TRANSFER_COUNTRY.includes(country);
  };

  useEffect(() => {
    if (!isEmpty(countryConfigProfile)) {
      setEnabledList(countryConfigProfile[0]);
    }
  }, [countryConfigProfile]);

  const redirectToProcessor = (paymentMethod: string) => {
    let url: string = "/processor-card/";
    switch (paymentMethod) {
      case PaymentMethodEnum.TRANSFER:
        url = "/processor-transfer/";
        break;
      case PaymentMethodEnum.CASH:
        url = "/processor-cash/";
        break;
      case PaymentMethodEnum.PAYOUTS_TRANSFER:
        url = "/processor-payouts-transfer/";
        break;
      case PaymentMethodEnum.PAYOUTS_CASH:
        url = "/processor-payouts-cash/";
        break;
      case PaymentMethodEnum.PAYOUTS_CARD:
        url = "/processor-payouts-card/";
        break;
      case PaymentMethodEnum.TRANSFER_SUBSCRIPTION:
        url = "/processor-transfer-subscriptions/";
        break;
      case PaymentMethodEnum.INTERNATIONAL_TRANSFER:
        url = "/processor-international-transfer/";
        break;
      default:
        break;
    }
    window.location.href = `${url}${props.id}`;
  };

  const setInternationalRedirectUrl = () => {
    localStorage.setItem(
      LocalStoragePropertiesEnum.INTERNATIONAL_REDIRECT_URL,
      `${routes.PROCESSOR_LIST}${props.id}`
    );
  };

  return (
    <React.Fragment>
      <Button
        color="primary"
        variant="contained"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        className={classes.handlerButton}
      >
        Agregar procesador
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        elevation={1}
        getContentAnchorEl={null}
      >
        {!showItem(props.country, PayInOutPopupConstants.CARD) && (
          <MenuItem
            key={PayInOutPopupConstants.CARD}
            onClick={() => redirectToProcessor(PaymentMethodEnum.CARD)}
            disabled={showItem(props.country, PayInOutPopupConstants.CARD)}
          >
            <ListItemIcon>
              <img alt={"card"} src={CardIcon} />
            </ListItemIcon>
            <ListItemText>{filterLabels.CARD}</ListItemText>
          </MenuItem>
        )}
        <Divider variant="fullWidth" />
        <Typography className={classes.subTitle}>Pay in</Typography>
        {!showItem(props.country, PayInOutPopupConstants.TRANSFER) && (
          <MenuItem
            key={PayInOutPopupConstants.TRANSFER}
            onClick={() => redirectToProcessor(PaymentMethodEnum.TRANSFER)}
            disabled={showItem(props.country, PayInOutPopupConstants.TRANSFER)}
          >
            <ListItemIcon>
              <img alt={"inTransfer"} src={TransferIcon} />
            </ListItemIcon>
            <ListItemText>{filterLabels.TRANSFER}</ListItemText>
          </MenuItem>
        )}
        {!showItem(props.country, PayInOutPopupConstants.RECURRENT_DEBIT) && (
          <MenuItem
            key={PayInOutPopupConstants.RECURRENT_DEBIT}
            onClick={() =>
              redirectToProcessor(PaymentMethodEnum.TRANSFER_SUBSCRIPTION)
            }
            disabled={showItem(
              props.country,
              PayInOutPopupConstants.RECURRENT_DEBIT
            )}
          >
            <ListItemIcon>
              <img alt={"inDebit"} src={TransferIcon} />
            </ListItemIcon>
            <ListItemText>Débito recurrente</ListItemText>
          </MenuItem>
        )}
        {!showItem(props.country, PayInOutPopupConstants.CASH) && (
          <MenuItem
            key={PayInOutPopupConstants.CASH}
            onClick={() => redirectToProcessor(PaymentMethodEnum.CASH)}
            disabled={showItem(props.country, PayInOutPopupConstants.CASH)}
          >
            <ListItemIcon>
              <img alt={"inCash"} src={CashIcon} />
            </ListItemIcon>
            <ListItemText>{filterLabels.CASH}</ListItemText>
          </MenuItem>
        )}
        <Divider variant="fullWidth" />
        <Typography className={classes.subTitle}>Pay out</Typography>
        {props.renderPayoutsCard &&
          !showItem(props.country, PayInOutPopupConstants.CARD_PAY_OUT) && (
            <MenuItem
              key={PayInOutPopupConstants.CARD}
              onClick={() =>
                redirectToProcessor(PaymentMethodEnum.PAYOUTS_CARD)
              }
              disabled={showItem(
                props.country,
                PayInOutPopupConstants.CARD_PAY_OUT
              )}
            >
              <ListItemIcon>
                <img alt={"card"} src={CardIcon} />
              </ListItemIcon>
              <ListItemText>{filterLabels.CARD}</ListItemText>
            </MenuItem>
          )}
        {!showItem(props.country, PayInOutPopupConstants.TRANSFER_PAY_OUT) && (
          <MenuItem
            key={PayInOutPopupConstants.TRANSFER_PAY_OUT}
            onClick={() =>
              redirectToProcessor(PaymentMethodEnum.PAYOUTS_TRANSFER)
            }
            disabled={showItem(
              props.country,
              PayInOutPopupConstants.TRANSFER_PAY_OUT
            )}
          >
            <ListItemIcon>
              <img alt={"outTransfer"} src={TransferIcon} />
            </ListItemIcon>
            <ListItemText>{filterLabels.TRANSFER}</ListItemText>
          </MenuItem>
        )}
        {!showItem(props.country, PayInOutPopupConstants.CASH_PAY_OUT) && (
          <MenuItem
            key={PayInOutPopupConstants.CASH_PAY_OUT}
            onClick={() => redirectToProcessor(PaymentMethodEnum.PAYOUTS_CASH)}
            disabled={showItem(
              props.country,
              PayInOutPopupConstants.CASH_PAY_OUT
            )}
          >
            <ListItemIcon>
              <img alt={"outCash"} src={CashIcon} />
            </ListItemIcon>
            <ListItemText>{filterLabels.CASH}</ListItemText>
          </MenuItem>
        )}
        <Divider variant="fullWidth" />
        {isAllowedRenderInternationalTransferOption(props.country) && (
          <MenuItem
            key={PayInOutPopupConstants.INTERNATIONAL_TRANSFER}
            onClick={() => {
              redirectToProcessor(PaymentMethodEnum.INTERNATIONAL_TRANSFER);
              setInternationalRedirectUrl();
            }}
            disabled={
              !isAllowedRenderInternationalTransferOption(props.country)
            }
          >
            <ListItemIcon>
              <img alt={"outTransfer"} src={TransferIcon} />
            </ListItemIcon>
            <ListItemText>{filterLabels.TRANSF_INTERNATIONAL}</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  );
};
