import { LabelEnum } from "./LabelEnum";

export enum TableFieldsEnum {
  CREATED = "createdAt",
  STATUS = "status",
  ALIAS = "name",
  CATEGORY = "category",
  PROCESSOR = "processorName",
  TITLE = "title",
  FIELD = "field",
  OPERATORS = "operators",
}

export enum TableBodyCellEnum {
  TITLE = "TITLE",
  TEXT = "TEXT",
  TAG = "TAG",
  OPTIONS = "OPTIONS",
}

export const BUSINESS_RULES_COLUMNS: string[] = [
  TableFieldsEnum.CREATED,
  TableFieldsEnum.ALIAS,
  TableFieldsEnum.CATEGORY,
  TableFieldsEnum.PROCESSOR,
  TableFieldsEnum.STATUS,
];

export const CONDITIONS_COLUMNS: string[] = [
  TableFieldsEnum.TITLE,
  TableFieldsEnum.FIELD,
  TableFieldsEnum.OPERATORS,
];

export const COLUMNS_BY_TABS: Record<string, string[]> = {
  [LabelEnum.BUSINESS_RULES]: BUSINESS_RULES_COLUMNS,
  [LabelEnum.CONDITIONS]: CONDITIONS_COLUMNS,
};
