import React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { MerchantResponse } from "../../../types/merchant_response";
import { get } from "lodash";
import {
  getCatalog,
  INVOICE_FRECUENCY,
} from "../../shared/infrastructure/constants/MerchantInformationConstant";
import { CatalogsEnum } from "../../shared/infrastructure/constants/catalogs-enum";
import { makeStyles } from "@material-ui/core/styles";
import { SkeletonInformation } from "../Skeletons/SkeletonInformation/SkeletonInformation";
import { Edit } from "react-feather";

export interface DispersionSectionProps {
  onClickEdit: () => void;
  merchant: MerchantResponse;
  isLoading: boolean;
}

const useStyles = makeStyles(() => ({
  labelTitle2: {
    color: "#023365",
    fontSize: "17px",
    fontWeight: 500,
    fontStyle: "normal",
  },
  labelTitle3: {
    color: "#46525C",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  labelColumns: {
    color: "#6D7781",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
  },
  labelValues: {
    color: "#293036",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  editBtn: {
    width: "99px",
    height: "32px",
  },
}));

export const DispersionSection: React.FC<DispersionSectionProps> = (
  props: DispersionSectionProps
) => {
  const style = useStyles();

  return (
    <React.Fragment>
      {props.isLoading ? (
        <SkeletonInformation />
      ) : (
        <Grid container spacing={3} alignItems="baseline">
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Box display={"flex"} alignItems={"center"}>
                <Box flexGrow={1}>
                  <Typography className={style.labelTitle2}>
                    Dispersión
                  </Typography>
                </Box>
                <Box>
                  <Button
                    className={style.editBtn}
                    variant={"outlined"}
                    color={"default"}
                    startIcon={<Edit xlinkTitle={"editar"} size={16} />}
                    onClick={props.onClickEdit}
                  >
                    Editar
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography className={style.labelTitle3}>
                Indica la frecuencia y datos de cuenta para la dispersión.
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Grid
              container
              spacing={2}
              justify={"flex-start"}
              alignItems={"baseline"}
            >
              <Grid item xs={12} md={4}>
                <Typography className={style.labelColumns}>
                  Frecuencia
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography align={"left"} className={style.labelValues}>
                  {get(
                    INVOICE_FRECUENCY.filter(
                      (frequency) =>
                        frequency.value === props.merchant.dispersionFrequency
                    )[0],
                    "name",
                    "N/A"
                  )}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography className={style.labelColumns}>
                  Valor de Dispersión
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography align={"left"} className={style.labelValues}>
                  {props.merchant.dispersion?.keepCommission
                    ? "Activado"
                    : "Desactivado"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography className={style.labelColumns}>
                  Cobro mínimo
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography align={"left"} className={style.labelValues}>
                  {props.merchant.chargeMin
                    ? props.merchant.chargeMinAmount
                    : "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Grid
              container
              spacing={2}
              justify={"flex-start"}
              alignItems={"baseline"}
            >
              {props.merchant.dispersion?.accountInfo?.map((account, index) => (
                <Grid
                  item
                  key={index}
                  hidden={index !== 0 && account.accountNumber === undefined}
                  container
                  spacing={2}
                >
                  <Grid item xs={12} md={4}>
                    <Typography className={style.labelColumns}>
                      Banco
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography align={"left"} className={style.labelValues}>
                      {get(
                        getCatalog(
                          props.merchant.country,
                          CatalogsEnum.Banks
                        ).filter(
                          (bank) => bank.value === get(account, "bankId")
                        )[0],
                        "name",
                        "N/A"
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography className={style.labelColumns}>
                      Cta. Bancaria
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography align={"left"} className={style.labelValues}>
                      {account.accountNumber === "N/A" ||
                      account.accountNumber === undefined ||
                      account.accountNumber === null
                        ? account.accountNumber
                        : `****${account!.accountNumber.substr(-4)}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography className={style.labelColumns}>
                      Tipo de Cuenta
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography align={"left"} className={style.labelValues}>
                      {get(
                        getCatalog(
                          props.merchant!.country,
                          CatalogsEnum.BankAccountTypes
                        ).filter(
                          (accountType) =>
                            accountType.value === get(account, "accountType")
                        )[0],
                        "name",
                        "N/A"
                      )}
                      {"  "}
                      {get(account, "currency")}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};
