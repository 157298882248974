import { StatusFlowEnum } from "./StatusFlowEnum";
import { CountryEnum } from "./CountryEnum";
import { KindEnum } from "./KindEnum";

export enum tabEnum {
  advanceConfigTab = "advanceConfigTab",
  infoTab = "infoTab",
}

export enum subSectionEnum {
  reference = "reference",
  parameters = "parameters",
  infoCard1 = "infoCard1",
  infoCard2 = "infoCard2",
  infoCard3 = "infoCard3",
  infoCard4 = "infoCard4",
  infoCard2_0 = "infoCard2_0",
  infoCreditNoteInfo = "infoCreditNoteInfo",
  infoCreditNoteBank = "infoCreditNoteBank",
  addressCreditNote3 = "addressCreditNote3",
  creditNoteDate = "creditNoteDate",
  infoCardDispersion1 = "infoCardDispersion1",
  infoCardDispersion2 = "infoCardDispersion2",
  infoCardReceivable1 = "infoCardReceivable1",
  infoCardReceivable2 = "infoCardReceivable2",
  infoCardCharge1 = "infoCardCharge1",
  infoCardCharge2 = "infoCardCharge2",
  infoCardCharge3 = "infoCardCharge3",
  infoCardVoucher1 = "infoCardVoucher1",
  infoCardVoucher2 = "infoCardVoucher2",
  infoCardDebitNote1 = "infoCardDebitNote1",
  infoCardDebitNote2 = "infoCardDebitNote2",
  infoCardVoucherBilling1 = "infoCardVoucherBilling1",
  infoCardVoucherBilling2 = "infoCardVoucherBilling2",
  infoCardChargeBilling1 = "infoCardChargeBilling1",
  infoCardChargeBilling2 = "infoCardChargeBilling2",
  infoCardChargeBilling3 = "infoCardChargeBilling3",
  infoCardChargeBilling4 = "infoCardChargeBilling4",
}
export enum fieldEnum {
  commentLabel = "comment",
  expirationDateLabel = "expirationDate",
  paymentMethodLabel = "paymentMethod",
  paymentDateLabel = "paymentDate",
  hesOcLabel = "hes/oc",
}

export enum fielInfoEnum {
  createdLabel = "created",
  processingDateLabel = "processing_date",
  amountLabel = "amount",
  amountLabelDispersion = "amount_dispersion",
  invoiceAmountIvaLabel = "invoice_amount_iva",
  invoiceAmountTotalLabel = "invoice_amount_total",
  chargeMethodLabel = "charge_method",
  totalTransactionsLabel = "total_number_transactions",
  totalAmountTransactions = "total_amount_dispersion_trx",
  typeLabel = "type",
  typeLabel2 = "type2",
  transactionType = "transaction_type",
  descriptionLabel = "description",
  currencyLabel = "currency_code",
  bankLabel = "bank_id",
  modelLabel = "processor_type",
  categoryLabel = "total_by_model",
  accountTypeLabel = "account_type",
  accountNumberLabel = "account_bank",
  accountNumberCCILabel = "interbank_account_code_cci",
  accountNumberCIILabel = "interbank_account_code_cii",
  documentNumberLabel = "document_number_bank",
  merchantNameLabel = "merchant_name",
  taxIdLabel = "tax_id",
  emailLabel = "email",
  addressLabel = "address",
  counterpartLabel = "counterpart",
  liquidationType = "liquidation_type",
  totalTransactions = "total_number_transactions_other",
  totalAmountTotal = "total_amount_trx",
  motivo = "status_flow",
  ivaKushkiCommissionLabel = "iva_kushki_commission",
  referenceLabel = "invoice_number",
  itemLabel = "item",
  transactionId = "transaction_id",
  kushkiCommission = "kushki_commission",
  retencionesRiesgos = "amount_retention",
  retencionesLiberadas = "amount_sum_charge_pending",
  folioFiscal = "invoice_number",
  nuevoFolioFiscal = "uuid",
  status = "status",
  initialStatusLabel = "initial_status",
  rechargeAmount = "wallet_config.rechargeAmount",
  automaticRecharge = "wallet_config.automaticRecharge",
  rechargePercentage = "wallet_config.rechargePercentage",
  typeWalletConfig = "wallet_config.type",
  transactionIdWalletConfig = "wallet_config.transactionId",
  statusWalletConfig = "wallet_config.status",
  rechargeFinal = "wallet_config.final",
  rechargetotalAmountTotal = "wallet_config.AmountTotal",
  customerIdHierarchy = "customer_id_hierarchy",
  customerName = "customer_name",
  ownerId = "owner_id",
  ownerName = "owner_name",
  isOcb = "is_ocb",
}

export enum fieldInfoEnumCamel {
  processingDateLabel = "processingdate",
  amountLabel = "amount",
  bankLabel = "bankId",
  typeLabel = "type",
  typelabel2 = "type2",
  transactionType = "transactionType",
  descriptionLabel = "description",
  merchantNameLabel = "merchantName",
  taxIdLabel = "taxId",
  emailLabel = "email",
  addressLabel = "address",
  liquidationType = "liquidationType",
  totalTransactions = "totalNumberTransactions",
  createdLabel = "created",
  chargeMethodLabel = "chargeMethod",
  currencyLabel = "currencyCode",
  itemLabel = "item",
  accountTypeLabel = "accountType",
  accountNumberLabel = "accountBank",
  accountNumberCCILabel = "interbank_account_code_cci",
  accountNumberCIILabel = "interbank_account_code_cii",
  invoiceAmountTotalLabel = "invoiceAmountTotal",
  customerIdHierarchy = "customerIdHierarchy",
  customerName = "customerName",
  ownerId = "ownerId",
  ownerName = "ownerName",
  isOcb = "isOcb",
}

const infoCard1List = [
  fielInfoEnum.createdLabel,
  fielInfoEnum.processingDateLabel,
  fielInfoEnum.amountLabel,
  fielInfoEnum.invoiceAmountIvaLabel,
  fielInfoEnum.invoiceAmountTotalLabel,
  fielInfoEnum.chargeMethodLabel,
  fielInfoEnum.referenceLabel,
  fielInfoEnum.totalTransactionsLabel,
  fielInfoEnum.typeLabel,
  fielInfoEnum.descriptionLabel,
];

const infoCard1ListMX = [
  fielInfoEnum.createdLabel,
  fielInfoEnum.processingDateLabel,
  fielInfoEnum.amountLabel,
  fielInfoEnum.invoiceAmountIvaLabel,
  fielInfoEnum.invoiceAmountTotalLabel,
  fielInfoEnum.chargeMethodLabel,
  fielInfoEnum.totalTransactionsLabel,
  fielInfoEnum.referenceLabel,
  fielInfoEnum.nuevoFolioFiscal,
  fielInfoEnum.typeLabel,
  fielInfoEnum.descriptionLabel,
];

const infoCard2List = [
  fielInfoEnum.bankLabel,
  fielInfoEnum.modelLabel,
  fielInfoEnum.categoryLabel,
  fielInfoEnum.accountTypeLabel,
  fielInfoEnum.accountNumberLabel,
  fielInfoEnum.accountNumberCIILabel,
  fielInfoEnum.accountNumberCCILabel,
  fielInfoEnum.documentNumberLabel,
];

const infoCard2ListMX = [
  fielInfoEnum.bankLabel,
  fielInfoEnum.modelLabel,
  fielInfoEnum.categoryLabel,
  fielInfoEnum.accountTypeLabel,
  fielInfoEnum.accountNumberLabel,
  fielInfoEnum.accountNumberCIILabel,
  fielInfoEnum.accountNumberCCILabel,
  fielInfoEnum.documentNumberLabel,
];

const infoCard3List = [
  fielInfoEnum.merchantNameLabel,
  fielInfoEnum.taxIdLabel,
  fielInfoEnum.emailLabel,
  fielInfoEnum.addressLabel,
];

const infoCard4List = [
  fielInfoEnum.ownerId,
  fielInfoEnum.ownerName,
  fielInfoEnum.isOcb,
  fielInfoEnum.customerIdHierarchy,
  fielInfoEnum.customerName,
];

const infoCard2_0_List = [
  fielInfoEnum.ownerId,
  fielInfoEnum.ownerName,
  fielInfoEnum.customerIdHierarchy,
  fielInfoEnum.customerName,
];

export const infoCreditNoteInfoPerCo = [
  fielInfoEnum.invoiceAmountTotalLabel,
  fielInfoEnum.createdLabel,
  fielInfoEnum.typeLabel2,
  fielInfoEnum.totalTransactions,
  fielInfoEnum.transactionType,
  fielInfoEnum.motivo,
  fielInfoEnum.descriptionLabel,
];

export const infoCreditNoteBankCol = [
  fielInfoEnum.bankLabel,
  fielInfoEnum.accountTypeLabel,
  fielInfoEnum.accountNumberLabel,
  fielInfoEnum.documentNumberLabel,
];

export const infoCreditNoteBankPerMex = [
  fielInfoEnum.bankLabel,
  fielInfoEnum.accountTypeLabel,
  fielInfoEnum.accountNumberLabel,
  fielInfoEnum.accountNumberCIILabel,
  fielInfoEnum.documentNumberLabel,
];

export const addressCreditNote3 = [
  fielInfoEnum.merchantNameLabel,
  fielInfoEnum.taxIdLabel,
  fielInfoEnum.emailLabel,
  fielInfoEnum.addressLabel,
];

export const dateAmountMX = [
  fielInfoEnum.createdLabel,
  fielInfoEnum.processingDateLabel,
  fielInfoEnum.totalAmountTotal,
  fielInfoEnum.totalTransactions,
  fielInfoEnum.motivo,
];

const infoCard1ListDispersion = [
  fielInfoEnum.createdLabel,
  fielInfoEnum.invoiceAmountTotalLabel,
  fielInfoEnum.ivaKushkiCommissionLabel,
  fielInfoEnum.processingDateLabel,
  fielInfoEnum.totalAmountTransactions,
  fielInfoEnum.retencionesRiesgos,
  fielInfoEnum.totalTransactions,
  fielInfoEnum.retencionesLiberadas,
  fielInfoEnum.amountLabelDispersion,
  fielInfoEnum.kushkiCommission,
  fielInfoEnum.invoiceAmountIvaLabel,
];

const infoCard1ListDispersionPeru = [
  fielInfoEnum.createdLabel,
  fielInfoEnum.invoiceAmountTotalLabel,
  fielInfoEnum.ivaKushkiCommissionLabel,
  fielInfoEnum.processingDateLabel,
  fielInfoEnum.totalAmountTransactions,
  fielInfoEnum.retencionesRiesgos,
  fielInfoEnum.totalTransactions,
  fielInfoEnum.retencionesLiberadas,
  fielInfoEnum.amountLabelDispersion,
  fielInfoEnum.kushkiCommission,
  fielInfoEnum.invoiceAmountIvaLabel,
  fielInfoEnum.rechargetotalAmountTotal,
  fielInfoEnum.transactionIdWalletConfig,
  fielInfoEnum.rechargePercentage,
  fielInfoEnum.rechargeAmount,
  fielInfoEnum.rechargeFinal,
];
const infoCard1ListDispersionColombia = [
  fielInfoEnum.createdLabel,
  fielInfoEnum.invoiceAmountTotalLabel,
  fielInfoEnum.ivaKushkiCommissionLabel,
  fielInfoEnum.processingDateLabel,
  fielInfoEnum.totalAmountTransactions,
  fielInfoEnum.retencionesRiesgos,
  fielInfoEnum.totalTransactions,
  fielInfoEnum.retencionesLiberadas,
  fielInfoEnum.amountLabelDispersion,
  fielInfoEnum.kushkiCommission,
  fielInfoEnum.invoiceAmountIvaLabel,
  fielInfoEnum.rechargetotalAmountTotal,
  fielInfoEnum.transactionIdWalletConfig,
  fielInfoEnum.rechargePercentage,
  fielInfoEnum.rechargeAmount,
  fielInfoEnum.rechargeFinal,
];

const infoCard1ListDispersionChile = [
  fielInfoEnum.createdLabel,
  fielInfoEnum.invoiceAmountTotalLabel,
  fielInfoEnum.ivaKushkiCommissionLabel,
  fielInfoEnum.processingDateLabel,
  fielInfoEnum.totalAmountTransactions,
  fielInfoEnum.retencionesRiesgos,
  fielInfoEnum.totalTransactions,
  fielInfoEnum.retencionesLiberadas,
  fielInfoEnum.amountLabelDispersion,
  fielInfoEnum.kushkiCommission,
  fielInfoEnum.invoiceAmountIvaLabel,
  fielInfoEnum.liquidationType,
];

const infoCard2ListDispersion = [
  fielInfoEnum.bankLabel,
  fielInfoEnum.accountTypeLabel,
  fielInfoEnum.accountNumberLabel,
  fielInfoEnum.documentNumberLabel,
  fielInfoEnum.initialStatusLabel,
];

const infoCard2ListDispersionMx = [
  fielInfoEnum.bankLabel,
  fielInfoEnum.accountTypeLabel,
  fielInfoEnum.accountNumberLabel,
  fielInfoEnum.accountNumberCCILabel,
  fielInfoEnum.documentNumberLabel,
];
const infoCard2ListDispersionCl = [
  fielInfoEnum.bankLabel,
  fielInfoEnum.accountTypeLabel,
  fielInfoEnum.accountNumberLabel,
  fielInfoEnum.accountNumberCIILabel,
];
const infoCard2ListDispersionPe = [
  ...infoCard2ListDispersionCl,
  fielInfoEnum.documentNumberLabel,
];

const infoCardCharge1List = [
  fielInfoEnum.createdLabel,
  fielInfoEnum.processingDateLabel,
  fielInfoEnum.chargeMethodLabel,
  fielInfoEnum.amountLabel,
  fielInfoEnum.currencyLabel,
  fielInfoEnum.typeLabel,
  fielInfoEnum.descriptionLabel,
];

const infoCardCharge2List = [
  fielInfoEnum.counterpartLabel,
  fielInfoEnum.bankLabel,
  fielInfoEnum.accountTypeLabel,
  fielInfoEnum.accountNumberLabel,
];

const infoCardCharge3List = [
  fielInfoEnum.merchantNameLabel,
  fielInfoEnum.taxIdLabel,
  fielInfoEnum.emailLabel,
  fielInfoEnum.addressLabel,
];
const infoCardVoucher1List = [
  fielInfoEnum.ivaKushkiCommissionLabel,
  fielInfoEnum.descriptionLabel,
];
const infoCardVoucher2List = [
  fielInfoEnum.merchantNameLabel,
  fielInfoEnum.taxIdLabel,
  fielInfoEnum.emailLabel,
  fielInfoEnum.addressLabel,
];

const infoCard1ListDebitNote = [
  fielInfoEnum.referenceLabel,
  fielInfoEnum.createdLabel,
  fielInfoEnum.processingDateLabel,
  fielInfoEnum.invoiceAmountTotalLabel,
  fielInfoEnum.chargeMethodLabel,
  fielInfoEnum.itemLabel,
  fielInfoEnum.transactionId,
  fielInfoEnum.descriptionLabel,
];
const infoCard2ListDebitNote = [
  fielInfoEnum.bankLabel,
  fielInfoEnum.accountTypeLabel,
  fielInfoEnum.accountNumberLabel,
  fielInfoEnum.accountNumberCIILabel,
  fielInfoEnum.documentNumberLabel,
];

const infoCardVoucherBilling1 = [
  fieldInfoEnumCamel.amountLabel,
  fieldInfoEnumCamel.descriptionLabel,
];

const infoCardVoucherBilling2 = [
  fieldInfoEnumCamel.merchantNameLabel,
  fieldInfoEnumCamel.taxIdLabel,
  fieldInfoEnumCamel.emailLabel,
  fieldInfoEnumCamel.addressLabel,
];

const infoCardChargeListBilling1 = [
  fieldInfoEnumCamel.createdLabel,
  fieldInfoEnumCamel.invoiceAmountTotalLabel,
  fieldInfoEnumCamel.typeLabel,
  fieldInfoEnumCamel.descriptionLabel,
];

const infoCardChargeListBilling2 = [
  fieldInfoEnumCamel.itemLabel,
  fieldInfoEnumCamel.bankLabel,
  fieldInfoEnumCamel.accountTypeLabel,
  fieldInfoEnumCamel.accountNumberLabel,
  fieldInfoEnumCamel.accountNumberCIILabel,
  fieldInfoEnumCamel.accountNumberCCILabel,
];

const infoCardChargeListBilling3 = [
  fieldInfoEnumCamel.merchantNameLabel,
  fieldInfoEnumCamel.taxIdLabel,
  fieldInfoEnumCamel.emailLabel,
  fieldInfoEnumCamel.addressLabel,
];

const infoCardChargeListBilling4 = [
  fieldInfoEnumCamel.ownerId,
  fieldInfoEnumCamel.ownerName,
  fieldInfoEnumCamel.isOcb,
  fieldInfoEnumCamel.customerIdHierarchy,
  fieldInfoEnumCamel.customerName,
];

// Voucher & invoice are the same - only left in code for scalability purposes
export const AdvanceConfigModalGeneric = {
  [KindEnum.INVOICE]: {
    [subSectionEnum.reference]: {
      [StatusFlowEnum.EXECUTOR]: {
        [CountryEnum.mexico]: [fieldEnum.commentLabel],
        [CountryEnum.colombia]: [fieldEnum.commentLabel],
        [CountryEnum.chile]: [fieldEnum.hesOcLabel, fieldEnum.commentLabel],
        [CountryEnum.ecuador]: [fieldEnum.commentLabel],
        [CountryEnum.peru]: [fieldEnum.commentLabel],
      },
      [StatusFlowEnum.VALIDATOR]: {
        [CountryEnum.mexico]: [fieldEnum.commentLabel],
        [CountryEnum.colombia]: [fieldEnum.commentLabel],
        [CountryEnum.chile]: [fieldEnum.hesOcLabel, fieldEnum.commentLabel],
        [CountryEnum.ecuador]: [fieldEnum.commentLabel],
        [CountryEnum.peru]: [fieldEnum.commentLabel],
      },
    },
    [subSectionEnum.parameters]: {
      [StatusFlowEnum.EXECUTOR]: {
        [CountryEnum.mexico]: [fieldEnum.paymentMethodLabel],
        [CountryEnum.colombia]: [
          fieldEnum.expirationDateLabel,
          fieldEnum.paymentMethodLabel,
        ],
        [CountryEnum.chile]: [
          fieldEnum.paymentDateLabel,
          fieldEnum.expirationDateLabel,
          fieldEnum.paymentMethodLabel,
        ],
        [CountryEnum.ecuador]: [fieldEnum.paymentMethodLabel],
        [CountryEnum.peru]: [fieldEnum.expirationDateLabel],
      },
      [StatusFlowEnum.VALIDATOR]: {
        [CountryEnum.mexico]: [fieldEnum.paymentMethodLabel],
        [CountryEnum.colombia]: [
          fieldEnum.expirationDateLabel,
          fieldEnum.paymentMethodLabel,
        ],
        [CountryEnum.chile]: [
          fieldEnum.paymentDateLabel,
          fieldEnum.expirationDateLabel,
          fieldEnum.paymentMethodLabel,
        ],
        [CountryEnum.ecuador]: [fieldEnum.paymentMethodLabel],
        [CountryEnum.peru]: [fieldEnum.expirationDateLabel],
      },
    },
  },
  [KindEnum.VOUCHER]: {
    [subSectionEnum.reference]: {
      [StatusFlowEnum.EXECUTOR]: {
        [CountryEnum.mexico]: [fieldEnum.commentLabel],
        [CountryEnum.colombia]: [fieldEnum.commentLabel],
        [CountryEnum.chile]: [fieldEnum.hesOcLabel, fieldEnum.commentLabel],
        [CountryEnum.ecuador]: [fieldEnum.commentLabel],
        [CountryEnum.peru]: [fieldEnum.commentLabel],
      },
      [StatusFlowEnum.VALIDATOR]: {
        [CountryEnum.mexico]: [fieldEnum.commentLabel],
        [CountryEnum.colombia]: [fieldEnum.commentLabel],
        [CountryEnum.chile]: [fieldEnum.hesOcLabel, fieldEnum.commentLabel],
        [CountryEnum.ecuador]: [fieldEnum.commentLabel],
        [CountryEnum.peru]: [fieldEnum.commentLabel],
      },
    },
    [subSectionEnum.parameters]: {
      [StatusFlowEnum.EXECUTOR]: {
        [CountryEnum.mexico]: [fieldEnum.paymentMethodLabel],
        [CountryEnum.colombia]: [
          fieldEnum.expirationDateLabel,
          fieldEnum.paymentMethodLabel,
        ],
        [CountryEnum.chile]: [
          fieldEnum.paymentDateLabel,
          fieldEnum.expirationDateLabel,
          fieldEnum.paymentMethodLabel,
        ],
        [CountryEnum.ecuador]: [fieldEnum.paymentMethodLabel],
        [CountryEnum.peru]: [fieldEnum.expirationDateLabel],
      },
      [StatusFlowEnum.VALIDATOR]: {
        [CountryEnum.mexico]: [fieldEnum.paymentMethodLabel],
        [CountryEnum.colombia]: [
          fieldEnum.expirationDateLabel,
          fieldEnum.paymentMethodLabel,
        ],
        [CountryEnum.chile]: [
          fieldEnum.paymentDateLabel,
          fieldEnum.expirationDateLabel,
          fieldEnum.paymentMethodLabel,
        ],
        [CountryEnum.ecuador]: [fieldEnum.paymentMethodLabel],
        [CountryEnum.peru]: [fieldEnum.expirationDateLabel],
      },
    },
  },
};

export const InfoModalGeneric = {
  [subSectionEnum.infoCard1]: {
    [StatusFlowEnum.EXECUTOR]: {
      [CountryEnum.mexico]: infoCard1ListMX,
      [CountryEnum.colombia]: infoCard1List,
      [CountryEnum.chile]: infoCard1List,
      [CountryEnum.ecuador]: infoCard1List,
      [CountryEnum.peru]: infoCard1List,
    },
    [StatusFlowEnum.VALIDATOR]: {
      [CountryEnum.mexico]: infoCard1ListMX,
      [CountryEnum.colombia]: infoCard1List,
      [CountryEnum.chile]: infoCard1List,
      [CountryEnum.ecuador]: infoCard1List,
      [CountryEnum.peru]: infoCard1List,
    },
  },
  [subSectionEnum.infoCardCharge1]: {
    [StatusFlowEnum.EXECUTOR]: {
      [CountryEnum.mexico]: infoCardCharge1List,
      [CountryEnum.colombia]: infoCardCharge1List,
      [CountryEnum.chile]: infoCardCharge1List,
      [CountryEnum.ecuador]: infoCardCharge1List,
      [CountryEnum.peru]: infoCardCharge1List,
    },
    [StatusFlowEnum.VALIDATOR]: {
      [CountryEnum.mexico]: infoCardCharge1List,
      [CountryEnum.colombia]: infoCardCharge1List,
      [CountryEnum.chile]: infoCardCharge1List,
      [CountryEnum.ecuador]: infoCardCharge1List,
      [CountryEnum.peru]: infoCardCharge1List,
    },
  },
  [subSectionEnum.infoCard2]: {
    [StatusFlowEnum.EXECUTOR]: {
      [CountryEnum.mexico]: infoCard2ListMX,
      [CountryEnum.colombia]: infoCard2List,
      [CountryEnum.chile]: infoCard2List,
      [CountryEnum.ecuador]: infoCard2List,
      [CountryEnum.peru]: infoCard2List,
    },
    [StatusFlowEnum.VALIDATOR]: {
      [CountryEnum.mexico]: infoCard2ListMX,
      [CountryEnum.colombia]: infoCard2List,
      [CountryEnum.chile]: infoCard2List,
      [CountryEnum.ecuador]: infoCard2List,
      [CountryEnum.peru]: infoCard2List,
    },
  },
  [subSectionEnum.infoCardCharge2]: {
    [StatusFlowEnum.EXECUTOR]: {
      [CountryEnum.mexico]: infoCardCharge2List,
      [CountryEnum.colombia]: infoCardCharge2List,
      [CountryEnum.chile]: infoCardCharge2List,
      [CountryEnum.ecuador]: infoCardCharge2List,
      [CountryEnum.peru]: infoCardCharge2List,
    },
    [StatusFlowEnum.VALIDATOR]: {
      [CountryEnum.mexico]: infoCardCharge2List,
      [CountryEnum.colombia]: infoCardCharge2List,
      [CountryEnum.chile]: infoCardCharge2List,
      [CountryEnum.ecuador]: infoCardCharge2List,
      [CountryEnum.peru]: infoCardCharge2List,
    },
  },
  [subSectionEnum.infoCard3]: {
    [StatusFlowEnum.EXECUTOR]: {
      [CountryEnum.mexico]: infoCard3List,
      [CountryEnum.colombia]: infoCard3List,
      [CountryEnum.chile]: infoCard3List,
      [CountryEnum.ecuador]: infoCard3List,
      [CountryEnum.peru]: infoCard3List,
    },
    [StatusFlowEnum.VALIDATOR]: {
      [CountryEnum.mexico]: infoCard3List,
      [CountryEnum.colombia]: infoCard3List,
      [CountryEnum.chile]: infoCard3List,
      [CountryEnum.ecuador]: infoCard3List,
      [CountryEnum.peru]: infoCard3List,
    },
  },

  [subSectionEnum.infoCard4]: {
    [StatusFlowEnum.EXECUTOR]: {
      [CountryEnum.mexico]: infoCard4List,
      [CountryEnum.colombia]: infoCard4List,
      [CountryEnum.chile]: infoCard4List,
      [CountryEnum.ecuador]: infoCard4List,
      [CountryEnum.peru]: infoCard4List,
    },
    [StatusFlowEnum.VALIDATOR]: {
      [CountryEnum.mexico]: infoCard4List,
      [CountryEnum.colombia]: infoCard4List,
      [CountryEnum.chile]: infoCard4List,
      [CountryEnum.ecuador]: infoCard4List,
      [CountryEnum.peru]: infoCard4List,
    },
  },

  [subSectionEnum.infoCard2_0]: {
    [StatusFlowEnum.EXECUTOR]: {
      [CountryEnum.mexico]: infoCard2_0_List,
      [CountryEnum.colombia]: infoCard2_0_List,
      [CountryEnum.chile]: infoCard2_0_List,
      [CountryEnum.ecuador]: infoCard2_0_List,
      [CountryEnum.peru]: infoCard2_0_List,
    },
    [StatusFlowEnum.VALIDATOR]: {
      [CountryEnum.mexico]: infoCard2_0_List,
      [CountryEnum.colombia]: infoCard2_0_List,
      [CountryEnum.chile]: infoCard2_0_List,
      [CountryEnum.ecuador]: infoCard2_0_List,
      [CountryEnum.peru]: infoCard2_0_List,
    },
  },

  [subSectionEnum.infoCardDispersion1]: {
    [StatusFlowEnum.EXECUTOR]: {
      [CountryEnum.mexico]: infoCard1ListDispersion,
      [CountryEnum.colombia]: infoCard1ListDispersionColombia,
      [CountryEnum.chile]: infoCard1ListDispersionChile,
      [CountryEnum.ecuador]: infoCard1ListDispersion,
      [CountryEnum.peru]: infoCard1ListDispersionPeru,
    },
    [StatusFlowEnum.VALIDATOR]: {
      [CountryEnum.mexico]: infoCard1ListDispersion,
      [CountryEnum.colombia]: infoCard1ListDispersionColombia,
      [CountryEnum.chile]: infoCard1ListDispersionChile,
      [CountryEnum.ecuador]: infoCard1ListDispersion,
      [CountryEnum.peru]: infoCard1ListDispersionPeru,
    },
  },
  [subSectionEnum.infoCardReceivable1]: {
    [StatusFlowEnum.EXECUTOR]: {
      [CountryEnum.mexico]: infoCard1ListDispersion,
      [CountryEnum.colombia]: infoCard1ListDispersionColombia,
      [CountryEnum.chile]: infoCard1ListDispersionChile,
      [CountryEnum.ecuador]: infoCard1ListDispersion,
      [CountryEnum.peru]: infoCard1ListDispersionPeru,
    },
    [StatusFlowEnum.VALIDATOR]: {
      [CountryEnum.mexico]: infoCard1ListDispersion,
      [CountryEnum.colombia]: infoCard1ListDispersionColombia,
      [CountryEnum.chile]: infoCard1ListDispersionChile,
      [CountryEnum.ecuador]: infoCard1ListDispersion,
      [CountryEnum.peru]: infoCard1ListDispersionPeru,
    },
  },
  [subSectionEnum.infoCardDispersion2]: {
    [StatusFlowEnum.EXECUTOR]: {
      [CountryEnum.mexico]: infoCard2ListDispersionMx,
      [CountryEnum.colombia]: infoCard2ListDispersion,
      [CountryEnum.chile]: infoCard2ListDispersionCl,
      [CountryEnum.ecuador]: infoCard2ListDispersion,
      [CountryEnum.peru]: infoCard2ListDispersionPe,
    },
    [StatusFlowEnum.VALIDATOR]: {
      [CountryEnum.mexico]: infoCard2ListDispersionMx,
      [CountryEnum.colombia]: infoCard2ListDispersion,
      [CountryEnum.chile]: infoCard2ListDispersionCl,
      [CountryEnum.ecuador]: infoCard2ListDispersion,
      [CountryEnum.peru]: infoCard2ListDispersionPe,
    },
  },
  [subSectionEnum.infoCardReceivable2]: {
    [StatusFlowEnum.EXECUTOR]: {
      [CountryEnum.mexico]: infoCard2ListDispersionMx,
      [CountryEnum.colombia]: infoCard2ListDispersion,
      [CountryEnum.chile]: infoCard2ListDispersionCl,
      [CountryEnum.ecuador]: infoCard2ListDispersion,
      [CountryEnum.peru]: infoCard2ListDispersionPe,
    },
    [StatusFlowEnum.VALIDATOR]: {
      [CountryEnum.mexico]: infoCard2ListDispersionMx,
      [CountryEnum.colombia]: infoCard2ListDispersion,
      [CountryEnum.chile]: infoCard2ListDispersionCl,
      [CountryEnum.ecuador]: infoCard2ListDispersion,
      [CountryEnum.peru]: infoCard2ListDispersionPe,
    },
  },
  [subSectionEnum.infoCardCharge3]: {
    [StatusFlowEnum.EXECUTOR]: {
      [CountryEnum.mexico]: infoCardCharge3List,
      [CountryEnum.colombia]: infoCardCharge3List,
      [CountryEnum.chile]: infoCardCharge3List,
      [CountryEnum.ecuador]: infoCardCharge3List,
      [CountryEnum.peru]: infoCardCharge3List,
    },
    [StatusFlowEnum.VALIDATOR]: {
      [CountryEnum.mexico]: infoCardCharge3List,
      [CountryEnum.colombia]: infoCardCharge3List,
      [CountryEnum.chile]: infoCardCharge3List,
      [CountryEnum.ecuador]: infoCardCharge3List,
      [CountryEnum.peru]: infoCardCharge3List,
    },
  },
  [subSectionEnum.infoCardDebitNote1]: {
    [StatusFlowEnum.EXECUTOR]: {
      [CountryEnum.colombia]: infoCard1ListDebitNote,
      [CountryEnum.peru]: infoCard1ListDebitNote,
    },
    [StatusFlowEnum.VALIDATOR]: {
      [CountryEnum.colombia]: infoCard1ListDebitNote,
      [CountryEnum.peru]: infoCard1ListDebitNote,
    },
  },
  [subSectionEnum.infoCardDebitNote2]: {
    [StatusFlowEnum.EXECUTOR]: {
      [CountryEnum.colombia]: infoCard2ListDebitNote,
      [CountryEnum.peru]: infoCard2ListDebitNote,
    },
    [StatusFlowEnum.VALIDATOR]: {
      [CountryEnum.colombia]: infoCard2ListDebitNote,
      [CountryEnum.peru]: infoCard2ListDebitNote,
    },
  },
  [subSectionEnum.infoCreditNoteInfo]: {
    [StatusFlowEnum.EXECUTOR]: {
      [CountryEnum.colombia]: infoCreditNoteInfoPerCo,
      [CountryEnum.peru]: infoCreditNoteInfoPerCo,
    },
    [StatusFlowEnum.VALIDATOR]: {
      [CountryEnum.colombia]: infoCreditNoteInfoPerCo,
      [CountryEnum.peru]: infoCreditNoteInfoPerCo,
    },
  },
  [subSectionEnum.infoCreditNoteBank]: {
    [StatusFlowEnum.EXECUTOR]: {
      [CountryEnum.mexico]: infoCreditNoteBankPerMex,
      [CountryEnum.colombia]: infoCreditNoteBankCol,
      [CountryEnum.peru]: infoCreditNoteBankPerMex,
    },
    [StatusFlowEnum.VALIDATOR]: {
      [CountryEnum.mexico]: infoCreditNoteBankPerMex,
      [CountryEnum.colombia]: infoCreditNoteBankCol,
      [CountryEnum.chile]: infoCard3List,
      [CountryEnum.ecuador]: infoCard3List,
      [CountryEnum.peru]: infoCreditNoteBankPerMex,
    },
  },
  [subSectionEnum.addressCreditNote3]: {
    [StatusFlowEnum.EXECUTOR]: {
      [CountryEnum.mexico]: addressCreditNote3,
      [CountryEnum.colombia]: addressCreditNote3,
      [CountryEnum.peru]: infoCard3List,
    },
    [StatusFlowEnum.VALIDATOR]: {
      [CountryEnum.mexico]: addressCreditNote3,
      [CountryEnum.colombia]: addressCreditNote3,
      [CountryEnum.peru]: infoCard3List,
    },
  },
  [subSectionEnum.creditNoteDate]: {
    [StatusFlowEnum.EXECUTOR]: {
      [CountryEnum.mexico]: dateAmountMX,
    },
    [StatusFlowEnum.VALIDATOR]: {
      [CountryEnum.mexico]: dateAmountMX,
    },
  },
  [subSectionEnum.infoCardVoucher1]: {
    [StatusFlowEnum.EXECUTOR]: {
      [CountryEnum.peru]: infoCardVoucher1List,
    },
    [StatusFlowEnum.VALIDATOR]: {
      [CountryEnum.peru]: infoCardVoucher1List,
    },
  },
  [subSectionEnum.infoCardVoucher2]: {
    [StatusFlowEnum.EXECUTOR]: {
      [CountryEnum.peru]: infoCardVoucher2List,
    },
    [StatusFlowEnum.VALIDATOR]: {
      [CountryEnum.peru]: infoCardVoucher2List,
    },
  },
  [subSectionEnum.infoCardVoucherBilling1]: {
    [StatusFlowEnum.EXECUTOR]: {
      [CountryEnum.peru]: infoCardVoucherBilling1,
    },
    [StatusFlowEnum.VALIDATOR]: {
      [CountryEnum.peru]: infoCardVoucherBilling1,
    },
  },
  [subSectionEnum.infoCardVoucherBilling2]: {
    [StatusFlowEnum.EXECUTOR]: {
      [CountryEnum.peru]: infoCardVoucherBilling2,
    },
    [StatusFlowEnum.VALIDATOR]: {
      [CountryEnum.peru]: infoCardVoucherBilling2,
    },
  },
  [subSectionEnum.infoCardChargeBilling1]: {
    [StatusFlowEnum.EXECUTOR]: {
      [CountryEnum.mexico]: infoCardChargeListBilling1,
      [CountryEnum.colombia]: infoCardChargeListBilling1,
      [CountryEnum.chile]: infoCardChargeListBilling1,
      [CountryEnum.ecuador]: infoCardChargeListBilling1,
      [CountryEnum.peru]: infoCardChargeListBilling1,
    },
    [StatusFlowEnum.VALIDATOR]: {
      [CountryEnum.mexico]: infoCardChargeListBilling1,
      [CountryEnum.colombia]: infoCardChargeListBilling1,
      [CountryEnum.chile]: infoCardChargeListBilling1,
      [CountryEnum.ecuador]: infoCardChargeListBilling1,
      [CountryEnum.peru]: infoCardChargeListBilling1,
    },
  },
  [subSectionEnum.infoCardChargeBilling2]: {
    [StatusFlowEnum.EXECUTOR]: {
      [CountryEnum.mexico]: infoCardChargeListBilling2,
      [CountryEnum.colombia]: infoCardChargeListBilling2,
      [CountryEnum.chile]: infoCardChargeListBilling2,
      [CountryEnum.ecuador]: infoCardChargeListBilling2,
      [CountryEnum.peru]: infoCardChargeListBilling2,
    },
    [StatusFlowEnum.VALIDATOR]: {
      [CountryEnum.mexico]: infoCardChargeListBilling2,
      [CountryEnum.colombia]: infoCardChargeListBilling2,
      [CountryEnum.chile]: infoCardChargeListBilling2,
      [CountryEnum.ecuador]: infoCardChargeListBilling2,
      [CountryEnum.peru]: infoCardChargeListBilling2,
    },
  },
  [subSectionEnum.infoCardChargeBilling3]: {
    [StatusFlowEnum.EXECUTOR]: {
      [CountryEnum.mexico]: infoCardChargeListBilling3,
      [CountryEnum.colombia]: infoCardChargeListBilling3,
      [CountryEnum.chile]: infoCardChargeListBilling3,
      [CountryEnum.ecuador]: infoCardChargeListBilling3,
      [CountryEnum.peru]: infoCardChargeListBilling3,
    },
    [StatusFlowEnum.VALIDATOR]: {
      [CountryEnum.mexico]: infoCardChargeListBilling3,
      [CountryEnum.colombia]: infoCardChargeListBilling3,
      [CountryEnum.chile]: infoCardChargeListBilling3,
      [CountryEnum.ecuador]: infoCardChargeListBilling3,
      [CountryEnum.peru]: infoCardChargeListBilling3,
    },
  },
  [subSectionEnum.infoCardChargeBilling4]: {
    [StatusFlowEnum.EXECUTOR]: {
      [CountryEnum.ecuador]: infoCardChargeListBilling4,
      [CountryEnum.mexico]: infoCardChargeListBilling4,
      [CountryEnum.colombia]: infoCardChargeListBilling4,
      [CountryEnum.chile]: infoCardChargeListBilling4,
      [CountryEnum.peru]: infoCardChargeListBilling4,
    },
    [StatusFlowEnum.VALIDATOR]: {
      [CountryEnum.ecuador]: infoCardChargeListBilling4,
      [CountryEnum.mexico]: infoCardChargeListBilling4,
      [CountryEnum.colombia]: infoCardChargeListBilling4,
      [CountryEnum.chile]: infoCardChargeListBilling4,
      [CountryEnum.peru]: infoCardChargeListBilling4,
    },
  },
};
