import theme from "../../../theme";
import { IStyles } from "../../../shared/infrastructure/interfaces/Styles.interfaces";

export const InformativeModalStyles: IStyles = {
  dialog: {
    "&.MuiPaper-root-MuiDialog-paper": {
      width: "636px",
      backgroundColor: "#023365",
      fontWeight: "bold",
    },
  },
  dialogTitle: {
    margin: 0,
    padding: 2,
    width: "650px",
  },
  sendBtn: {
    "&:hover, &:focus, &:active": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default,
      boxShadow: "none !important",
      border: 0,
    },
    backgroundColor: theme.palette.primary.main,
    border: 0,
    borderRadius: "4px",
    color: theme.palette.background.default,
    fontFamily: "IBM Plex Sans,sans-serif !important",
    fontSize: "16px !important",
  },
  cancelBtn: {
    "&:hover": {
      color: "#293036 !important",
      boxShadow: "none !important",
      border: 1,
      borderColor: "#e5e9ef",
    },
    border: 1,
    borderRadius: "4px",
    borderColor: "#e5e9ef",
    color: "#293036",
    fontFamily: "IBM Plex Sans,sans-serif !important",
    fontSize: "16px !important",
  },
  titleText: {
    fontSize: "16px",
    marginTop: "3px",
    color: "#293036",
  },
  dialogTitleText: {
    fontSize: "24px",
  },
  subTitleText: {
    fontSize: "16px",
    marginBottom: "10px",
    color: "#293036",
  },
  subTitleTextBtn: {
    fontSize: "16px",
    marginTop: "25px",
    color: "#293036",
  },
};
