import React, { useEffect } from "react";
import {
  ModeEnum,
  PROCESSOR_CATEGORY_RADIO_ITEMS,
  PROCESSOR_MODEL_RADIO_ITEMS,
  PROCESSOR_OPTIONS_RADIO_ITEMS,
} from "../../../shared/constants/transferProcessorOptions";
import { RadioInfoItems } from "../../RadioGroupComponent/RadioGroupComponent.interfaces";
import {
  cloneDeep,
  defaultTo,
  filter,
  get,
  isEmpty,
  isUndefined,
} from "lodash";
import { useAppSelector } from "../../../store/hooks/storeHook";
import { RootState } from "../../../store/store";
import {
  closeModal,
  getMerchantBasicInfo,
} from "../../../store/actions/layout/layout.actions";
import { LayoutState } from "../../../store/interfaces/layout/layout.interfaces";
import { ProcessorBasicInfo } from "../../../../types/processor_basic_info";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  createProcessor,
  getAllProcessorInfo,
  getProcessorDefaults,
  getProcessorInfo,
  updateDefaultProcessor,
  updateProcessor,
} from "../../../store/thunks/layout/layout.thunks";
import { RedirectObject } from "../../../shared/constants/processorConstants";
import { InternationalTransferEnum } from "../../../shared/constants/InternationalTransferEnum";
import { API_ROUTES } from "../../../shared/constants/api_routes";

export interface IModeSubmit {
  submit: (processor: string, value: ProcessorBasicInfo) => void;
}

export interface UseInternationalTransfer {
  aliasError: boolean;
  aliasValue: string;
  isDefaultProcessor: boolean;
  isFinalModal: boolean;
  isProcessorModal: boolean;
  processorSubmitModalInfo: RedirectObject;
  headerTitle: string;
  onAliasChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBackPage: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onCloseFinalModal: () => void;
  handleRedirectNewProcessor: () => void;
  onDefaultTransferChecked: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  isEdit: boolean;
  isLoading: boolean;

  onSubmitProcessor: (event: React.MouseEvent<HTMLButtonElement>) => void;
  merchantId: string;
  processorCategory: RadioInfoItems[];
  processorModels: RadioInfoItems[];
  processorTypes: RadioInfoItems[];
  setProcessorCategory: (value: RadioInfoItems[]) => void;
  setProcessorModels: (value: RadioInfoItems[]) => void;
  setProcessorTypes: (value: RadioInfoItems[]) => void;
  modes: Record<ModeEnum, IModeSubmit>;
}

export const useInternationalTransfer = (): UseInternationalTransfer => {
  // Start Component Values
  const params = useParams();
  const dispatch = useDispatch();
  const [headerTitle, setHeaderTitle] = React.useState<string>(
    InternationalTransferEnum.AddProcessorHeader
  );

  //Start Field Data
  const [processorTypes, setProcessorTypes] = React.useState<RadioInfoItems[]>(
    PROCESSOR_OPTIONS_RADIO_ITEMS
  );
  let dataEvent: string = "";
  const [aliasValue, setAliasValue] = React.useState<string>(
    PROCESSOR_OPTIONS_RADIO_ITEMS[0].value
  );
  const [aliasError, setAliasError] = React.useState<boolean>(false);

  // Modals
  const [isFinalModal, setIsFinalModal] = React.useState<boolean>(false);
  const [isProcessorModal, setIsProcessorModal] =
    React.useState<boolean>(false);
  const [processorSubmitModalInfo, setProcessorSubmitModalInfo] =
    React.useState<RedirectObject | undefined>();

  // ProcessorInfo
  const [isDefaultProcessor, setIsDefaultProcessor] =
    React.useState<boolean>(false);
  const [processorModels, setProcessorModels] = React.useState<
    RadioInfoItems[]
  >(PROCESSOR_MODEL_RADIO_ITEMS);
  const [processorCategory, setProcessorCategory] = React.useState<
    RadioInfoItems[]
  >(PROCESSOR_CATEGORY_RADIO_ITEMS);

  // Start State Data
  const {
    merchantBasicInfo,
    processorMerchantValidator,
    processorInfo,
    configProcessor,
    successSubmit,
    isLoading,
    onBackUrl,
  }: LayoutState = useAppSelector((store: RootState) => store.layout);

  const [processorId, setProcessorId] = React.useState<string>("");
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [mode, setMode] = React.useState<ModeEnum>(ModeEnum.CREATE);

  const [merchantId, setMerchantId] = React.useState<string>("");

  //Functions Private

  const getDataParams = (): void => {
    const data_params: string[] = get(params, "*", "").split("/");
    const processor_id: string = defaultTo(data_params[2], "");
    const is_edit: boolean = !isEmpty(processor_id);

    setMerchantId(data_params[1]);
    setProcessorId(processor_id);
    setIsEdit(is_edit);
  };

  const getSelectedRadioValue = (values: RadioInfoItems[]): RadioInfoItems => {
    return filter(values, (item: RadioInfoItems) =>
      get(item, "selected", false)
    )[0];
  };

  const _setDefaultProcessor = (isDefault: boolean, alias: string): void => {
    dispatch(
      updateDefaultProcessor({
        merchantId,
        payload: {
          defaultProcessor: {
            ...configProcessor!.defaultProcessor!,
            internationalTransfer: isDefault ? alias : "",
          },
        },
      })
    );
  };

  const _createProcessor = (
    processor: string,
    value: ProcessorBasicInfo
  ): void => {
    setIsFinalModal(true);
    dataEvent = processor;
    _setDefaultProcessor(isDefaultProcessor, aliasValue);

    dispatch(createProcessor(value));
  };

  const _updateProcessor = (
    processor: string,
    value: ProcessorBasicInfo
  ): void => {
    setIsFinalModal(true);
    _setDefaultProcessor(isDefaultProcessor, aliasValue);

    dispatch(
      updateProcessor({
        payload: value,
        processorId: processor,
      })
    );
  };

  const getMode = (): Record<ModeEnum, IModeSubmit> => {
    return {
      [ModeEnum.CREATE]: { submit: _createProcessor },
      [ModeEnum.EDIT]: { submit: _updateProcessor },
    };
  };

  const _onSubmit = (): void => {
    const processor_type: RadioInfoItems =
      getSelectedRadioValue(processorTypes);

    const processorSaved: ProcessorBasicInfo = {
      advancedInfo: {
        notificationEnable: true,
      },
      merchantId,
      processorInfo: {
        alias: aliasValue,
        isDefaultTransferInternationalProcessor: isDefaultProcessor,
        processorAlias: processor_type.value,
      },
      processorName: processor_type.value,
    };
    const mode_form: IModeSubmit = getMode()[mode];

    mode_form.submit(processorId, processorSaved);
  };

  const _loadProcessor = (): void => {
    setMode(ModeEnum.EDIT);
    setHeaderTitle(InternationalTransferEnum.EditProcessorHeader);
    dispatch(getProcessorInfo(processorId));
  };

  const _loadRadioButtons = (
    radios: RadioInfoItems[],
    processorPath: string
  ): RadioInfoItems[] => {
    return cloneDeep(radios).map((radio: RadioInfoItems, index: number) => {
      const is_zero: boolean = index === 0;

      if (is_zero) radio.selected = false;

      const is_find: boolean =
        radio.value === get(processorInfo, processorPath, "");

      radio.selected = is_find ? is_find : radio.selected;

      return radio;
    });
  };

  const _loadEditForm = (): void => {
    const alias: string = get(processorInfo, "processorInfo.alias", "");

    setProcessorTypes(
      _loadRadioButtons(processorTypes, "processorInfo.processorAlias")
    );
    setProcessorModels(
      _loadRadioButtons(processorModels, "basicInfo.processorType")
    );
    setProcessorCategory(
      _loadRadioButtons(processorCategory, "basicInfo.categoryModel")
    );
    setAliasValue(alias);
    setIsDefaultProcessor(
      get(
        processorInfo,
        "processorInfo.isDefaultTransferInternationalProcessor",
        false
      )
    );
    setAliasError(isEmpty(alias));
  };

  const _matchSpacing = (alias: string): boolean => {
    const firstChar: string = alias.charAt(0).trim();
    const lastChar: string = alias.slice(-1).trim();

    return isEmpty(firstChar) || isEmpty(lastChar);
  };

  const _validateError = (): boolean => {
    let alias_processor_find: ProcessorBasicInfo | undefined = defaultTo(
      cloneDeep(processorMerchantValidator),
      []
    ).find(
      (processor: ProcessorBasicInfo) =>
        get(processor, "alias", "") === aliasValue
    );

    alias_processor_find =
      get(processorInfo, "processorInfo.alias") !==
      get(alias_processor_find, "alias", "")
        ? alias_processor_find
        : undefined;

    return isEmpty(aliasValue) || !isUndefined(alias_processor_find);
  };

  const _loadSubmit = (): void => {
    setIsFinalModal(false);
    setIsProcessorModal(true);
    dispatch(closeModal());
    setProcessorSubmitModalInfo({
      bodyDialog: InternationalTransferEnum.ModalBodyDialog,
      redirectButtonLabel: InternationalTransferEnum.RedirectLabel,
      redirectPath: defaultTo(onBackUrl, API_ROUTES.REDIRECT_URL(merchantId)),
    });
  };

  // Event Functions
  const onSubmitProcessor = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    dataEvent = get(event, "buttons", dataEvent).toString();
    const find_other_alias: boolean = _validateError();

    const existSpacing: boolean = _matchSpacing(aliasValue);

    setAliasError(find_other_alias || existSpacing);

    if (!find_other_alias && !existSpacing) {
      _onSubmit();
    }
  };
  const onAliasChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value: string = get(event, "target.value", "");
    const find_other_alias: boolean = _validateError();

    setAliasError(find_other_alias);

    setAliasValue(value);
  };

  const redirect = (url: string): void => {
    window.location.href = url;
  };

  const onCloseFinalModal = (): void => {
    redirect(get(processorSubmitModalInfo, "redirectPath", ""));
  };

  const handleRedirectNewProcessor = () => {
    const url: string = `/processor-international-transfer/${merchantId}`;

    redirect(url);
  };

  const onDefaultTransferChecked = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    dataEvent = get(event, "target.value", "");

    setIsDefaultProcessor(!isDefaultProcessor);
  };

  const onBackPage = (event: React.MouseEvent<HTMLButtonElement>): void => {
    dataEvent = String(event.buttons);
    redirect(defaultTo(onBackUrl, API_ROUTES.REDIRECT_URL(merchantId)));
  };

  //Effects

  useEffect(() => {
    dispatch(getMerchantBasicInfo());
  }, []);

  useEffect(() => {
    getDataParams();
  }, [params]);

  useEffect(() => {
    if (isEdit) _loadProcessor();
  }, [isEdit]);

  useEffect(() => {
    if (!isUndefined(processorInfo)) _loadEditForm();
  }, [processorInfo]);

  useEffect(() => {
    if (successSubmit && !isUndefined(successSubmit)) {
      _loadSubmit();
    }
  }, [successSubmit]);

  useEffect(() => {
    if (!isEmpty(merchantId)) {
      dispatch(getProcessorDefaults(merchantId));
      dispatch(getAllProcessorInfo(merchantId));
    }
  }, [merchantBasicInfo, merchantId]);

  return {
    aliasError,
    aliasValue,
    handleRedirectNewProcessor,
    headerTitle,
    isDefaultProcessor,
    isEdit,
    isFinalModal,
    isLoading: isLoading!,
    isProcessorModal,
    merchantId,
    modes: getMode(),
    onAliasChange,
    onBackPage,
    onCloseFinalModal,
    onDefaultTransferChecked,
    onSubmitProcessor,
    processorCategory,
    processorModels,
    processorSubmitModalInfo: processorSubmitModalInfo!,
    processorTypes,
    setProcessorCategory,
    setProcessorModels,
    setProcessorTypes,
  };
};
