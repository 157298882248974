import { IConfigurationItem } from "../../../types/node_info_response";
import { SECTION_CONFIGURATIONS } from "../constants/section_configurations";
import { ConfigurationIdEnum, Section } from "../enums";
import _ from "lodash";

export const isSectionCentralized = (
  section: Section,
  configs: IConfigurationItem[]
): boolean => {
  const sectionConfigurations = SECTION_CONFIGURATIONS[section] ?? [];

  const configuration =
    sectionConfigurations.length != 0
      ? configs?.find((config) => {
          return sectionConfigurations.includes(
            <ConfigurationIdEnum>config.configuration
          );
        })
      : undefined;

  const isEmpty = configuration
    ? _.isEmpty(configuration.centralizedNodesId)
    : true;

  return !isEmpty;
};

export const getConfig = (
  section: Section,
  configs: IConfigurationItem[]
): IConfigurationItem | undefined => {
  const sectionConfigurations = SECTION_CONFIGURATIONS[section] ?? [];

  return sectionConfigurations.length != 0
    ? configs?.find((config) => {
        return sectionConfigurations.includes(
          <ConfigurationIdEnum>config.configuration
        );
      })
    : undefined;
};
