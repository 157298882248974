export enum CountryEnum {
  ECUADOR = "Ecuador",
}
export enum CountriesEnum {
  BRAZIL = "Brazil",
  CENTRO_AMERICA = "CentroAmerica",
  CHILE = "Chile",
  COLOMBIA = "Colombia",
  COSTA_RICA = "CostaRica",
  ECUADOR = "Ecuador",
  EL_SALVADOR = "ElSalvador",
  ESTADOS_UNIDOS = "EEUU",
  GUATEMALA = "Guatemala",
  HONDURAS = "Honduras",
  MEXICO = "Mexico",
  NICARAGUA = "Nicaragua",
  PANAMA = "Panama",
  PERU = "Peru",
}

export const CENTRO_AMERICA_COUNTRIES: CountriesEnum[] = [
  CountriesEnum.COSTA_RICA,
  CountriesEnum.EL_SALVADOR,
  CountriesEnum.GUATEMALA,
  CountriesEnum.HONDURAS,
  CountriesEnum.NICARAGUA,
  CountriesEnum.PANAMA,
];

export const DEFERRED_COUNTRIES: CountriesEnum[] = [
  CountriesEnum.ECUADOR,
  CountriesEnum.MEXICO,
  CountriesEnum.PERU,
  ...CENTRO_AMERICA_COUNTRIES,
];
