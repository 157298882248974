import React, { FC } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "@kushki/connect-ui";
import { Outlet } from "react-router";
import { Provider } from "react-redux";
import { store } from "../../store/store";

const App: FC = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Outlet />
      </ThemeProvider>
    </Provider>
  );
};

export { App };
