import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export interface LoadingDialogProps {
  open: boolean;
  isEdit: boolean;
}

export const loadingDialogComponentStyles = createNamedStyles({
  title: {
    color: "#023365",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "120%",
  },
  subtitle: {
    color: "#293036",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "150%",
  },
});
