import React from "react";
import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  BasicInformationLabels,
  BasicInformationTitles,
} from "../../shared/labels/BasicInformation.labels";
import {
  inputsBasicInformation,
  useBasicInformation,
  validationsForm,
} from "./state/useBasicInformation";
import { IBasicInformationProps } from "./BasicInformation.interfaces";
import { stylesBasicInformation as styles } from "./BasicInformation.styles";
import { LabelButton } from "../../shared/labels/CheckoutContainer.labels";
import { validateKeyForInput } from "../../shared/utils/utils";

const BasicInformation: React.FC<IBasicInformationProps> = (
  props: IBasicInformationProps
) => {
  const {
    basicInformationForm,
    handleContinueSubmit,
    data,
    isMobile,
    ivaPercentage,
    getIvaAmountFormat,
    getTotalAmountFormat,
    enableBtnSubmit,
  } = useBasicInformation(props);

  return (
    <form id="basicInformationForm" onSubmit={handleContinueSubmit}>
      <Grid container spacing={2} sx={styles.container}>
        <Grid item sm={12} md={12}>
          <Typography color={"primary"} variant="h6">
            {BasicInformationTitles.COMPLETE_INFO_CLIENT}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            label={BasicInformationLabels.FULLNAMES}
            name={inputsBasicInformation.NAME}
            fullWidth
            inputRef={basicInformationForm.register(
              validationsForm[inputsBasicInformation.NAME]
            )}
            variant="outlined"
            error={!!basicInformationForm.errors[inputsBasicInformation.NAME]}
            helperText={
              basicInformationForm.errors[inputsBasicInformation.NAME]?.message
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            label={BasicInformationLabels.EMAIL}
            name={inputsBasicInformation.EMAIL}
            fullWidth
            inputRef={basicInformationForm.register(
              validationsForm[inputsBasicInformation.EMAIL]
            )}
            variant="outlined"
            error={!!basicInformationForm.errors[inputsBasicInformation.EMAIL]}
            helperText={
              basicInformationForm.errors[inputsBasicInformation.EMAIL]?.message
            }
          />
        </Grid>
        <Grid item sm={12} md={12}>
          <Typography color={"primary"} variant="h6">
            {BasicInformationTitles.SUBTOTALS}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            label={BasicInformationLabels.SUBTOTAL_IVA}
            name={inputsBasicInformation.SUBTOTAL_IVA}
            fullWidth
            type="number"
            inputRef={basicInformationForm.register(
              validationsForm[inputsBasicInformation.SUBTOTAL_IVA]
            )}
            variant="outlined"
            error={
              !!basicInformationForm.errors[inputsBasicInformation.SUBTOTAL_IVA]
            }
            helperText={
              basicInformationForm.errors[inputsBasicInformation.SUBTOTAL_IVA]
                ?.message
            }
            value={data.subtotalIva ? data.subtotalIva : ""}
            onKeyDown={validateKeyForInput}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            label={BasicInformationLabels.SUBTOTAL_IVA_0}
            name={inputsBasicInformation.SUBTOTAL_IVA_0}
            fullWidth
            type="number"
            variant="outlined"
            inputRef={basicInformationForm.register(
              validationsForm[inputsBasicInformation.SUBTOTAL_IVA]
            )}
            error={
              !!basicInformationForm.errors[
                inputsBasicInformation.SUBTOTAL_IVA_0
              ]
            }
            helperText={
              basicInformationForm.errors[inputsBasicInformation.SUBTOTAL_IVA_0]
                ?.message
            }
            value={data.subtotalIva0 ? data.subtotalIva0 : ""}
            onKeyDown={validateKeyForInput}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Typography color={"primary"} variant="h6">
            {`${BasicInformationTitles.IVA}${ivaPercentage}%`}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Typography color={"primary"} textAlign="right" variant="h6">
            {getIvaAmountFormat(data.subtotalIva)}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Typography color={"primary"} variant="h6">
            {BasicInformationTitles.TOTAL}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Typography color={"primary"} textAlign="right" variant="h6">
            {getTotalAmountFormat(data.subtotalIva, data.subtotalIva0)}
          </Typography>
        </Grid>
        <Grid item sm={12} md={12}>
          <TextField
            label={BasicInformationLabels.DESCRIPTION}
            name={inputsBasicInformation.DESCRIPTION}
            multiline
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={styles.inputAdornmentDescription}
                >
                  {data.description.length}/500
                </InputAdornment>
              ),
            }}
            fullWidth
            inputRef={basicInformationForm.register(
              validationsForm[inputsBasicInformation.DESCRIPTION]
            )}
            error={
              !!basicInformationForm.errors[inputsBasicInformation.DESCRIPTION]
            }
            helperText={
              basicInformationForm.errors[inputsBasicInformation.DESCRIPTION]
                ?.message
            }
          />
        </Grid>
        <Grid item sm={12} md={12} textAlign={isMobile ? "center" : "right"}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={enableBtnSubmit() ? {} : styles.btnSubmit}
            onClick={handleContinueSubmit}
          >
            {LabelButton.BUTTON_CONTINUE}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default BasicInformation;
