import { Grid, Paper, Typography } from "@material-ui/core";
import { BodyTabField } from "../../ModalBodyTab/ModalBodyTab.interfaces";
import { get } from "lodash";
import React from "react";
import { TabContentBlockProps } from "../TabContentBlock.interfaces";
import { useStyles } from "../../DetailsCard.styles";

export const SimpleInfoBlock = (props: TabContentBlockProps) => {
  const classes = useStyles();

  const { selectedTab, tabIndex } = props;
  const lines: BodyTabField[] = get(props, "lines", []);

  return (
    <Paper
      className={classes.paper}
      hidden={selectedTab !== tabIndex}
      id={`scrollable-prevent-tabpanel-${tabIndex}`}
      aria-labelledby={`scrollable-prevent-tab-${tabIndex}`}
    >
      <Grid container xs={12} spacing={2}>
        {lines.map((line: BodyTabField) => (
          <>
            <Grid item md={6} spacing={2}>
              <Typography variant="body2" color="textSecondary">
                {line.label}
              </Typography>
            </Grid>
            <Grid item md={6} spacing={2}>
              <Typography variant="body2">
                {line.valueFromField
                  ? get(props, `transaction.${line.valueFromField}`, "")
                  : line.value}
              </Typography>
            </Grid>
          </>
        ))}
      </Grid>
    </Paper>
  );
};
