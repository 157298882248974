import { ExpandMore } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import React, { FC } from "react";
import { ADMIN_LABEL } from "../../shared/constants/labels/header_labels";
import { headerStyles as styles } from "./Header.styles";
import { useHeader } from "./state/useHeader";
import { IconCircleCross } from "@kushki/connect-ui";
import { EntityName } from "../../shared/enums/entityName";
import { ToolTipEnum } from "../../shared/enums/toolTipEnum";
import { HeaderEnum } from "../../shared/infrastructure/HeaderEnum";
import { defaultTo } from "lodash";

const Header: FC = () => {
  const {
    fullName,
    handleOpenModal,
    showAdminChip,
    title,
    titleAccent,
    tooltip,
    addCloseButton,
    redirectPage,
    nameCustomer,
    isUserPath,
  } = useHeader();

  const delimeter: string = defaultTo(title, "").length > 0 ? "- " : "";
  const decomposedTitle: string[] = defaultTo(title, "").split("/");

  const renderTitle = (text: string, func?: () => void) => {
    return (
      <Typography
        sx={styles.firstTitleHeader}
        onClick={() => (func ? func() : {})}
      >
        {text}
      </Typography>
    );
  };

  return (
    <Box sx={styles.headerBox}>
      <Box sx={styles.boxTitle}>
        {addCloseButton && (
          <IconButton
            color="secondary"
            onClick={handleOpenModal}
            sx={styles.iconButton}
          >
            <IconCircleCross fontSize="large" />
          </IconButton>
        )}
        <Box sx={styles.boxSecondTitle}>
          {isUserPath ? (
            <>
              <Typography sx={styles.firstTitleHeader}>
                {nameCustomer}
              </Typography>
              <Typography sx={styles.secondTitleHeader}>
                {delimeter}
                {HeaderEnum.CONFIG_USER}
              </Typography>
            </>
          ) : (
            <>
              <Tooltip title={tooltip} arrow>
                {tooltip === ToolTipEnum.OWNER_CUSTOMER &&
                title.includes(ToolTipEnum.DIVIDER) ? (
                  <Box sx={styles.boxTitleTwo}>
                    {renderTitle(decomposedTitle[0], () =>
                      redirectPage(EntityName.OWNER)
                    )}
                    {renderTitle(ToolTipEnum.DIVIDER)}
                    {renderTitle(decomposedTitle[1], () =>
                      redirectPage(EntityName.CUSTOMER)
                    )}
                  </Box>
                ) : (
                  renderTitle(title)
                )}
              </Tooltip>
              {nameCustomer.length > 0 && (
                <Typography sx={styles.firstTitleHeader}>
                  {nameCustomer}
                </Typography>
              )}
              {titleAccent && (
                <Typography sx={styles.secondTitleHeader}>
                  {delimeter}
                  {titleAccent}
                </Typography>
              )}
            </>
          )}
        </Box>
      </Box>
      <Box sx={styles.boxUser}>
        {showAdminChip && <Box sx={styles.boxChip}>{ADMIN_LABEL}</Box>}
        <Box sx={styles.boxSelect}>
          <Typography sx={styles.titleChip}>{fullName}</Typography>
          <ExpandMore />
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
