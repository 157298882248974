import { ICellTextCopyComponent } from "./CellTextCopy.interfaces";
import { FC } from "react";
import { Box, IconButton } from "@mui/material";
import * as React from "react";
import { clipboard } from "../../../shared/utils/tableUtils";
import { Copy } from "react-feather";

export const CellTextCopy: FC<ICellTextCopyComponent> = ({
  title,
  textToCopy,
  align,
}: ICellTextCopyComponent) => {
  return (
    <Box
      sx={{ alignItems: "center", display: "flex" }}
      justifyContent={align || "center"}
    >
      {title}
      <IconButton
        onClick={(e) => {
          clipboard(textToCopy);
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Copy />
      </IconButton>
    </Box>
  );
};
