import { IIsValidField } from "../../interfaces/InputFieldsForm.interfaces";
import { isNil } from "lodash";
import { getInvalidErrorMessages } from "../errorMessages/error_messages";
import { NumberFormatValues } from "react-number-format";

export const isValidField: IIsValidField = (props, value) => {
  return isNil(props.validate) || props.validate(value)
    ? true
    : getInvalidErrorMessages(
        props.errorMessage.label,
        props.errorMessage.messages
      );
};

export const numberFormatOnChange = (
  onChange: (params: object) => void,
  name: string,
  values: NumberFormatValues
): void => {
  onChange({
    target: {
      name: name,
      value: values.value,
    },
  });
};
