//import React from "react";

import {
  IGlobalTableComponent,
  IUseGlobalTableComponent,
} from "../../../shared/infrastructure/interfaces/IGlobalTableComponent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilterHierarchyResponse,
  getFilterResponse,
} from "../../../store/actionCreators";
import { IAppState } from "../../../store/reducer";
import { SearchRuleResponse } from "../../../../types/search_rule_response";
import { defaultTo, get, map } from "lodash";
import { useSnackbar } from "@kushki/connect-ui";
import { ErrorsEnum } from "../../../shared/infrastructure/enums/ErrorEnum";
import { TextFieldSearchByItem } from "@kushki/connect-ui/dist/Components/Molecules/Form/TextFieldSearchBy/TextFieldSearchBy.interfaces";
import { useFilterSearch } from "../../../shared/hooks/filter-search/useFilterSearch";

export const useGlobalTableComponentState =
  ({}: IGlobalTableComponent): IUseGlobalTableComponent => {
    const { updateMerchant, updateLimit, filterSearch } = useFilterSearch();
    const dispatch = useDispatch();
    const { showSnackbar } = useSnackbar();
    const [filterSelectList, setFilterSelectList] = useState<
      TextFieldSearchByItem[]
    >([]);
    const searchRuleResponse: SearchRuleResponse | undefined = useSelector(
      (state: IAppState) => state.searchRequestRulesResponse
    );
    const requestError: boolean | undefined = useSelector((state: IAppState) =>
      defaultTo(state.requestError, false)
    );
    const filterList: object | undefined = useSelector(
      (state: IAppState) => state.filterList
    );
    const handleDataChange = (
      textSent: string,
      searchWithHierarchy: boolean
    ): void => {
      if (searchWithHierarchy) dispatch(getFilterHierarchyResponse(textSent));
      else
        dispatch(
          getFilterResponse({
            text: textSent,
            limit: 10,
            offset: 0,
          })
        );
    };

    const handleSelectItem = (selectedItem: TextFieldSearchByItem): void => {
      updateMerchant(get(selectedItem, "secondaryText", ""));
    };

    useEffect(() => {
      setFilterSelectList(
        map(filterList, (item: object) => ({
          text: get(item, "name", ""),
          secondaryText: get(item, "merchantId", ""),
          id: get(item, "merchantId", ""),
        }))
      );
    }, [filterList]);

    useEffect(() => {
      if (requestError) {
        showSnackbar({
          message: ErrorsEnum.REQUEST_ERROR,
          variant: "simple",
          color: "danger",
          withIcon: false,
        });
      }
    }, [requestError]);

    const handleItemsPerPage = (value: number) => {
      updateLimit(value);
    };
    const emptyFilterSelectList = (): void => {
      setFilterSelectList([]);
    };

    return {
      searchRuleResponse,
      itemsPerPage: filterSearch.request.limit,
      actions: {
        handleItemsPerPage,
        handleSelectItem,
        handleDataChange,
        emptyFilterSelectList,
      },
      filterSelectList,
    };
  };
