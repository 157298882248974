/* istanbul ignore file */
import axios from "axios";
import { defaultTo } from "lodash";

export const authorization: string = defaultTo(localStorage.getItem("jwt"), "");
export const defaultHeaders = {
  Authorization: authorization,
  "Content-type": "application/json",
};
const instance = axios.create({
  headers: {
    ...defaultHeaders,
  },
});

export default instance;
