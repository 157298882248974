import { TabIndex } from "./TabIndexEnum";
export enum ProcessingStepEnum {
  PROCESSOR = "statusProcessor",
  BUSINESS_RULES = "statusBusinessRules",
  DEFERRED = "statusDeferred",
}

export enum ConfigurationCodeEnum {
  PROCESSOR = "cn007",
  BUSINESS_RULES = "cn008",
  DEFERRED = "cn010",
}

export enum EntityNameEnum {
  BRANCH = "BRANCH",
}

export const tabIndexToProcessingStep = new Map<
  TabIndex,
  ConfigurationCodeEnum
>([
  [TabIndex.PROCESSORS, ConfigurationCodeEnum.PROCESSOR],
  [TabIndex.BUSINESS_RULES, ConfigurationCodeEnum.BUSINESS_RULES],
  [TabIndex.DEFERRED, ConfigurationCodeEnum.DEFERRED],
]);
