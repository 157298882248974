import { useEffect } from "react";
import {
  setPaginationFilter,
  setTransactionsLimit,
} from "../../../../../store/actions/filters/filters";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/hooks/hooks";
import { IPaginationFilter } from "../../../../../store/interfaces/FiltersState.interfaces";
import { selectTotalConciliation } from "../../../../../store/selectors/app/app";
import { selectPaginationFilter } from "../../../../../store/selectors/filters/filters";
import { selectTransactionsLoading } from "../../../../../store/selectors/transactions/transactions";
import { IUseTransactionTableFooterLimitSelectState } from "./useTransactionTableFooterLimitSelectState.interfaces";

export const useTransactionTableFooterLimitSelectState =
  (): IUseTransactionTableFooterLimitSelectState => {
    const dispatch = useAppDispatch();
    const areTransactionsLoading = useAppSelector(selectTransactionsLoading);
    const paginationFilter: IPaginationFilter = useAppSelector(
      selectPaginationFilter
    );
    const totalConciliations = useAppSelector(selectTotalConciliation);

    const handleSetContentLimit = (limit: number): void => {
      dispatch(setTransactionsLimit(limit));
    };

    const { limit, page, total } = paginationFilter;

    const stepRange = page > 0 ? limit * (page - 1) : limit * page;
    const startRange = stepRange + 1;
    const endRange =
      stepRange + (stepRange + limit > total ? total - stepRange : limit);
    const pagesRange = `${startRange}-${endRange}`;

    useEffect(() => {
      dispatch(
        setPaginationFilter({
          ...paginationFilter,
          total: totalConciliations,
        })
      );
    }, [totalConciliations]);

    return {
      areTransactionsLoading,
      handleSetContentLimit,
      limit,
      pagesRange,
      total,
    };
  };
