export enum StatusEnum {
  PENDING = "pending",
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export const CatalogTranslateTextAction: Record<string, string> = {
  [StatusEnum.INACTIVE]: "Activar",
  [StatusEnum.ACTIVE]: "Desactivar",
};

export enum FinalStatusEnum {
  ACTIVAR = "activar",
  DESACTIVAR = "desactivar",
  ACTIVATED = "activado",
  DEACTIVATED = "desactivado",
}

export enum StatusConfigEnum {
  COMPLETE = "complete",
  OMITTED = "omitted",
}
