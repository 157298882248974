import { auth } from "../../../shared/auth";
import { get, isEmpty, uniq } from "lodash";
import { CountriesEnum } from "../../../shared/infrastructure/constants/CountriesEnum";
import { useHistory } from "react-router";
import { routes } from "../../../shared/infrastructure/constants/routes";
import { IUseContainerIndexState } from "./useContainerIndexState.interfaces";
import { DeferredOption } from "../../../../types/deferred_options";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { useEffect, useState } from "react";
import { getDeferredMerchant } from "../../../store/actionCreators";

export const useContainerIndexState = (): IUseContainerIndexState => {
  const dispatch = useDispatch();
  const [hasCustomList, setHasCustomList] = useState(false);
  const merchantInformation = auth.getAuthMerchant();
  const country = get(merchantInformation, "country");
  const history = useHistory();
  const merchantState = useSelector((state: IAppState) => state.merchant);
  const merchant = { ...merchantState, name: merchantState?.name };
  let fee: string[] = [];

  const isDeferredLoaded: boolean = useSelector(
    (state: IAppState) => state.isDeferredLoaded
  );

  const merchantDeferred: DeferredOption[] | undefined = useSelector(
    (state: IAppState) => state.merchantDeferred
  );
  const merchantDeferredOriginal: DeferredOption[] | undefined = useSelector(
    (state: IAppState) => state.merchantDeferredOriginal
  );

  useEffect(() => {
    if (country === CountriesEnum.BRAZIL) {
      setHasCustomList(true);
    }
  }, [country]);
  useEffect(() => {
    dispatch(getDeferredMerchant());
  }, [isDeferredLoaded]);

  if (country === CountriesEnum.PERU) {
    history.push(routes.CREATE_DEFERRED);
  }

  if (!isEmpty(merchantDeferredOriginal)) {
    merchantDeferredOriginal?.map((deffOption) => {
      fee.push(get(deffOption, "merchantMonths"));
    });
    fee = uniq(([] as string[]).concat.apply([], fee)).sort(
      (firstItem: string, secondItem: string) => {
        const firstItemToNumber = parseInt(firstItem);
        const secondItemToNumber = parseInt(secondItem);

        return firstItemToNumber - secondItemToNumber;
      }
    );
  }

  return {
    brazilDeferredOption: fee,
    deferredOption: merchantDeferred,
    hasCustomList,
    isDeferredLoaded: isDeferredLoaded,
    merchant,
    merchantDeferred: merchantDeferredOriginal,
  };
};
