import {
  BreadCrumbLabel,
  BreadcrumbPathEnum,
} from "@shared/enum/breadcrumbPathEnum";
import { ItemsBreadcrumbs } from "@components/Breadcrumbs/Breadcrumbs.interfaces";
import { IUseDevelopersContainer } from "@containers/Developers/state/IUseDevelopersContainer.interfaces";
import { useEffect, useState } from "react";
import {
  allRolesSections,
  backofficeMasterCredentialsSections,
  backofficeMasterCustomerSections,
} from "@shared/constants/developersSections";
import { RolesEnum } from "@shared/enum/rolesEnum";
import { IDevelopersSection } from "@components/DeveloperSection/DeveloperSections.interfaces";

export const useDevelopersContainer = (): IUseDevelopersContainer => {
  const roles: object = JSON.parse(localStorage.getItem("roles") || "{}");
  const publicMerchantId: string = localStorage.getItem("merchantId") || "";
  const breadcrumList: ItemsBreadcrumbs[] = [
    {
      label: BreadCrumbLabel.DEVELOPERS,
      path: BreadcrumbPathEnum.DEVELOPERS,
    },
  ];
  const [sections, setSections] = useState<IDevelopersSection[]>([]);

  useEffect(() => {
    if (roles[RolesEnum.BackofficeMasterCustomer]) {
      setSections(backofficeMasterCustomerSections(publicMerchantId));
    } else if (
      roles[RolesEnum.BackofficeMasterCredentials] &&
      !roles[RolesEnum.BackofficeMasterCustomer] &&
      !roles[RolesEnum.BackofficeUserMaster]
    ) {
      setSections(backofficeMasterCredentialsSections(publicMerchantId));
    } else {
      setSections(allRolesSections(publicMerchantId));
    }
  }, []);

  return {
    breadcrumList,
    sections,
  };
};
