import React from "react";
import {
  Button,
  ButtonGroup,
  MenuList,
  MenuItem,
  Popover,
  Typography,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import imgDownload from "../../assets/images/⬇️📄download.svg";
import { RuleRequestRoles } from "../../shared/infrastructure/constants/RuleRequestConstants";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
export interface ButtonDownloadFileProps {
  isLoading: boolean;
  options?: { text: string; value: string }[];
  downloadFile: (format: string) => void;
}
const useStyles = makeStyles({
  btn: {
    border: "0.5px solid #C4C4C4",
    backgroundColor: "#fff",
    "&:hover": {
      border: "0.5px solid #C4C4C4",
    },
  },
  defaultColor: {
    color: "#0E1E2E",
  },
});
export const ButtonDownloadFile: React.FC<ButtonDownloadFileProps> = (
  props: ButtonDownloadFileProps
) => {
  const classes = useStyles();
  const options: { text: string; value: string }[] = props.options || [
    {
      text: "CSV",
      value: "csv",
    },
    {
      text: "JSON",
      value: "json",
    },
  ];
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <ComponentSecurityWrapper
        componentId={`${RuleRequestRoles.M_FRAUD_PREVENTION}.${RuleRequestRoles.RULE_REQUEST_AUTH_RULES_DOWNLOAD}`}
      >
        <div onClick={handleClick}>
          <ButtonGroup
            variant="outlined"
            color="primary"
            size={"medium"}
            disabled={props.isLoading}
            style={{ display: "flex" }}
          >
            <Button
              className={classes.btn}
              startIcon={<img src={imgDownload} />}
            >
              <Typography className={classes.defaultColor} variant={"body2"}>
                Descargar
              </Typography>
            </Button>
            <Button
              className={classes.btn}
              id="button_arrow"
              onClick={handleClick}
            >
              {props.isLoading ? (
                <CircularProgress size={20} color={"primary"} />
              ) : (
                <ArrowDropDownIcon className={classes.defaultColor} />
              )}
            </Button>
          </ButtonGroup>
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuList id="split-button-menu">
            {options.map((option) => (
              <MenuItem
                key={option.value}
                onClick={() => {
                  setAnchorEl(null);
                  props.downloadFile(option.value);
                }}
              >
                {option.text}
              </MenuItem>
            ))}
          </MenuList>
        </Popover>
      </ComponentSecurityWrapper>
    </React.Fragment>
  );
};
