/**
 * Catalog Guatemala
 */
import { Category } from "../constants/information_constants";
import { CatalogsEnum } from "../interfaces/catalogs-enum";
import { CurrencyEnum } from "../interfaces/currency-enum";

export const CatalogGt: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { defaultShareholderDocType: "0", value: "0", name: "DPI" },
    { defaultShareholderDocType: "0", value: "1", name: "NIT" },
  ],

  [CatalogsEnum.BankAccountTypes]: [
    { value: "0", name: "Corriente" },
    { value: "1", name: "Ahorros" },
  ],
  [CatalogsEnum.Currency]: [
    { value: CurrencyEnum.GTQ, name: "Quetzales" },
    { value: CurrencyEnum.USD, name: "Dólares" },
  ],

  [CatalogsEnum.Banks]: [
    { value: "3001", name: "AGROMERCANTIL" },
    { value: "3002", name: "BANCO AZTECA" },
    { value: "3003", name: "BANCO DE ANTIGUA" },
    { value: "3004", name: "BANCO BAC" },
    { value: "3005", name: "BANCO CITI" },
    { value: "3006", name: "BANCO CREDITO HIPOTECARIO" },
    { value: "3007", name: "BANCO BANTRAB" },
    { value: "3008", name: "BANCO G&T CONTINENTAL" },
    { value: "3009", name: "BANCO INDUSTRIAL" },
    { value: "3010", name: "BANCO INMOBILIARIO" },
    { value: "3011", name: "BANCO INTERNACIONAL" },
    { value: "3012", name: "BANCO FICOHSA" },
    { value: "3013", name: "BANCO REFORMADOR" },
    { value: "3014", name: "BANCO VIVIBANCO" },
  ],

  [CatalogsEnum.Provinces]: [
    { value: "01", name: "Guatemala" },
    { value: "02", name: "El Progreso" },
    { value: "03", name: "Sacatepéquez" },
    { value: "04", name: "Chimaltenango" },
    { value: "05", name: "Escuintla" },
    { value: "06", name: "Santa Rosa" },
    { value: "07", name: "Sololá" },
    { value: "08", name: "Totonicapán" },
    { value: "09", name: "Quetzaltenango" },
    { value: "10", name: "Suchitepéquez" },
    { value: "11", name: "Retalhuleu" },
    { value: "12", name: "San Marcos" },
    { value: "13", name: "Huehuetenango" },
    { value: "14", name: "Quiché" },
    { value: "15", name: "Baja Verapaz" },
    { value: "16", name: "Alta Verapaz" },
    { value: "17", name: "Petén" },
    { value: "18", name: "Izabal" },
    { value: "19", name: "Zacapa" },
    { value: "20", name: "Chiquimula" },
    { value: "21", name: "Jalapa" },
    { value: "22", name: "Jutiapa" },
  ],

  [CatalogsEnum.Cities]: [
    { value: "0101", name: "Guatemala", parent: "01" },
    { value: "0102", name: "Santa Catarina Pinula", parent: "01" },
    { value: "0103", name: "San José Pinula", parent: "01" },
    { value: "0104", name: "San José del Golfo", parent: "01" },
    { value: "0105", name: "Palencia", parent: "01" },
    { value: "0106", name: "Chinautla", parent: "01" },
    { value: "0107", name: "San Pedro Ayampuc", parent: "01" },
    { value: "0108", name: "Mixco", parent: "01" },
    { value: "0109", name: "San Pedro Sacatepéquez", parent: "01" },
    { value: "0110", name: "San Juan Sacatepéquez", parent: "01" },
    { value: "0111", name: "San Raymundo", parent: "01" },
    { value: "0112", name: "Chuarrancho", parent: "01" },
    { value: "0113", name: "Fraijanes", parent: "01" },
    { value: "0114", name: "Amatitlán", parent: "01" },
    { value: "0115", name: "Villa Nueva", parent: "01" },
    { value: "0116", name: "Villa Canales", parent: "01" },
    { value: "0117", name: "San Miguel Petapa", parent: "01" },
    { value: "0201", name: "Guastatoya", parent: "02" },
    { value: "0202", name: "Morazán", parent: "02" },
    { value: "0203", name: "San Agustín Acasaguastlán", parent: "02" },
    { value: "0204", name: "San Cristóbal Acasaguastlán", parent: "02" },
    { value: "0205", name: "El Jícaro", parent: "02" },
    { value: "0206", name: "Sansare", parent: "02" },
    { value: "0207", name: "Sanarate", parent: "02" },
    { value: "0208", name: "San Antonio La Paz", parent: "02" },
    { value: "0301", name: "Antigua Guatemala", parent: "03" },
    { value: "0302", name: "Jocotenango", parent: "03" },
    { value: "0303", name: "Pastores", parent: "03" },
    { value: "0304", name: "Sumpango", parent: "03" },
    { value: "0305", name: "Santo Domingo Xenacoj", parent: "03" },
    { value: "0306", name: "Santiago Sacatepéquez", parent: "03" },
    { value: "0307", name: "San Bartolomé Milpas Altas", parent: "03" },
    { value: "0308", name: "San Lucas Sacatepéquez", parent: "03" },
    { value: "0309", name: "Santa Lucía Milpas Altas", parent: "03" },
    { value: "0310", name: "Magdalena Milpas Altas", parent: "03" },
    { value: "0311", name: "Santa María de Jesús", parent: "03" },
    { value: "0312", name: "Ciudad Vieja", parent: "03" },
    { value: "0313", name: "San Miguel Dueñas", parent: "03" },
    { value: "0314", name: "Alotenango", parent: "03" },
    { value: "0315", name: "San Antonio Aguas Calientes", parent: "03" },
    { value: "0316", name: "Santa Catarina Barahona", parent: "03" },
    { value: "0401", name: "Chimaltenango", parent: "04" },
    { value: "0402", name: "San José Poaquil", parent: "04" },
    { value: "0403", name: "San Martín Jilotepeque", parent: "04" },
    { value: "0404", name: "San Juan Comalapa", parent: "04" },
    { value: "0405", name: "Santa Apolonia", parent: "04" },
    { value: "0406", name: "Tecpán Guatemala", parent: "04" },
    { value: "0407", name: "Patzún", parent: "04" },
    { value: "0408", name: "San Miguel Pochuta", parent: "04" },
    { value: "0409", name: "Patzicía", parent: "04" },
    { value: "0410", name: "Santa Cruz Balanyá", parent: "04" },
    { value: "0411", name: "Acatenango", parent: "04" },
    { value: "0412", name: "San Pedro Yepocapa", parent: "04" },
    { value: "0413", name: "Parramos", parent: "04" },
    { value: "0413", name: "San Andrés Itzapa", parent: "04" },
    { value: "0414", name: "Zaragoza", parent: "04" },
    { value: "0415", name: "El Tejar", parent: "04" },
    { value: "0501", name: "Escuintla", parent: "05" },
    { value: "0502", name: "Santa Lucía Cotzumalguapa", parent: "05" },
    { value: "0503", name: "La Democracia", parent: "05" },
    { value: "0504", name: "Siquinalá", parent: "05" },
    { value: "0505", name: "Masagua", parent: "05" },
    { value: "0506", name: "Tiquistate", parent: "05" },
    { value: "0507", name: "La Gomera", parent: "05" },
    { value: "0508", name: "Guanagazapa", parent: "05" },
    { value: "0509", name: "San José", parent: "05" },
    { value: "0510", name: "Iztapa", parent: "05" },
    { value: "0511", name: "Palín", parent: "05" },
    { value: "0512", name: "San Vicente Pacaya", parent: "05" },
    { value: "0513", name: "Nueva Concepción", parent: "05" },
    { value: "0601", name: "Cuilapa", parent: "06" },
    { value: "0602", name: "Barberena", parent: "06" },
    { value: "0603", name: "Santa Rosa de Lima", parent: "06" },
    { value: "0604", name: "Casillas", parent: "06" },
    { value: "0605", name: "San Rafael las Flores", parent: "06" },
    { value: "0606", name: "Oratorio", parent: "06" },
    { value: "0607", name: "San Juan Tecuaco", parent: "06" },
    { value: "0608", name: "Chiquimulilla", parent: "06" },
    { value: "0609", name: "Taxisco", parent: "06" },
    { value: "0610", name: "Santa María Ixhuatán", parent: "06" },
    { value: "0611", name: "Guazacapán", parent: "06" },
    { value: "0612", name: "Santa Cruz Naranjo", parent: "06" },
    { value: "0613", name: "Pueblo Nuevo Viñas", parent: "06" },
    { value: "0614", name: "Nueva Santa Rosa", parent: "06" },
    { value: "0701", name: "Sololá", parent: "07" },
    { value: "0702", name: "San José Chacayá", parent: "07" },
    { value: "0703", name: "Santa María Visitación", parent: "07" },
    { value: "0704", name: "Santa Lucía Utatlán", parent: "07" },
    { value: "0705", name: "Nahualá", parent: "07" },
    { value: "0706", name: "Santa Catarina Ixtahuacan", parent: "07" },
    { value: "0707", name: "Santa Clara La Laguna", parent: "07" },
    { value: "0708", name: "Concepción", parent: "07" },
    { value: "0709", name: "San Andrés Semetabaj", parent: "07" },
    { value: "0710", name: "Panajachel", parent: "07" },
    { value: "0711", name: "Santa Caratina Palopó", parent: "07" },
    { value: "0712", name: "San Antonio Palopó", parent: "07" },
    { value: "0713", name: "San Lucas Tolimán", parent: "07" },
    { value: "0714", name: "Santa Cruz La Laguna", parent: "07" },
    { value: "0715", name: "San Pablo La Laguna", parent: "07" },
    { value: "0716", name: "San Marcos La Laguna", parent: "07" },
    { value: "0717", name: "San Juan La Laguna", parent: "07" },
    { value: "0718", name: "San Pedro La Laguna", parent: "07" },
    { value: "0719", name: "Santiago Atitlán", parent: "07" },
    { value: "0801", name: "Totonicapán", parent: "08" },
    { value: "0802", name: "San Cristóbal Totonicapán", parent: "08" },
    { value: "0803", name: "San Francisco El Alto", parent: "08" },
    { value: "0804", name: "San Andrés Xecul", parent: "08" },
    { value: "0805", name: "Momostenango", parent: "08" },
    { value: "0806", name: "Santa María Chiquimula", parent: "08" },
    { value: "0807", name: "Santa Lucía La Reforma", parent: "08" },
    { value: "0808", name: "San Bartolo", parent: "08" },
    { value: "0901", name: "Quetzaltenango", parent: "09" },
    { value: "0902", name: "Salcajá", parent: "09" },
    { value: "0903", name: "Olintepeque", parent: "09" },
    { value: "0904", name: "San Carlos Sija", parent: "09" },
    { value: "0905", name: "Sibilia", parent: "09" },
    { value: "0906", name: "Cabricán", parent: "09" },
    { value: "0907", name: "Cajolá", parent: "09" },
    { value: "0908", name: "San Miguel Sigüilá", parent: "09" },
    { value: "0909", name: "San Juan Ostuncalco", parent: "09" },
    { value: "0910", name: "San Mateo", parent: "09" },
    { value: "0911", name: "Concepción Chiquirichapa", parent: "09" },
    { value: "0912", name: "San Martín Sacatepéquez", parent: "09" },
    { value: "0913", name: "Almolonga", parent: "09" },
    { value: "0914", name: "Cantel", parent: "09" },
    { value: "0915", name: "Huitán", parent: "09" },
    { value: "0916", name: "Zunil", parent: "09" },
    { value: "0917", name: "Colomba", parent: "09" },
    { value: "0918", name: "San Francisco La Unión", parent: "09" },
    { value: "0919", name: "El Palmar", parent: "09" },
    { value: "0920", name: "Coatepeque", parent: "09" },
    { value: "0921", name: "Génova", parent: "09" },
    { value: "0922", name: "Flores Costa Cuca", parent: "09" },
    { value: "0923", name: "La Esperanza", parent: "09" },
    { value: "0924", name: "Palestina de los Altos", parent: "09" },
    { value: "1001", name: "Mazatenango", parent: "10" },
    { value: "1002", name: "Cuyotenango", parent: "10" },
    { value: "1003", name: "San Francisco Zapotitlán", parent: "10" },
    { value: "1004", name: "San Bernardino", parent: "10" },
    { value: "1005", name: "San José El Idolo", parent: "10" },
    { value: "1006", name: "Santo Domingo Suchitepéquez", parent: "10" },
    { value: "1007", name: "San Lorenzo", parent: "10" },
    { value: "1008", name: "Samayac", parent: "10" },
    { value: "1009", name: "San Pablo Jocopilas", parent: "10" },
    { value: "1010", name: "San Antonio Suchitepéquez", parent: "10" },
    { value: "1011", name: "San Miguel Panán", parent: "10" },
    { value: "1012", name: "San Gabriel", parent: "10" },
    { value: "1013", name: "Chicacao", parent: "10" },
    { value: "1014", name: "Patulul", parent: "10" },
    { value: "1015", name: "Santa Bárbara", parent: "10" },
    { value: "1016", name: "San Juan Bautista", parent: "10" },
    { value: "1017", name: "Santo Tomás la Unión", parent: "10" },
    { value: "1018", name: "Zunilito", parent: "10" },
    { value: "1019", name: "Pueblo Nuevo", parent: "10" },
    { value: "1020", name: "Río Bravo", parent: "10" },
    { value: "1101", name: "Retalhuleu", parent: "11" },
    { value: "1102", name: "San Sebastián", parent: "11" },
    { value: "1103", name: "Santa Cruz Muluá", parent: "11" },
    { value: "1104", name: "San Martín Zapotitlán", parent: "11" },
    { value: "1105", name: "San Felipe", parent: "11" },
    { value: "1106", name: "San Andrés Villa Seca", parent: "11" },
    { value: "1107", name: "Champerico", parent: "11" },
    { value: "1108", name: "Nuevo San Carlos", parent: "11" },
    { value: "1109", name: "El Asintal", parent: "11" },
    { value: "1201", name: "San Marcos", parent: "12" },
    { value: "1202", name: "San Pedro Sacatepéquez", parent: "12" },
    { value: "1203", name: "San Antonio Sacatepéquez", parent: "12" },
    { value: "1204", name: "Comitancillo", parent: "12" },
    { value: "1205", name: "San Miguel Ixtahuacán", parent: "12" },
    { value: "1206", name: "Concepción Tutuapa", parent: "12" },
    { value: "1207", name: "Tacaná", parent: "12" },
    { value: "1208", name: "Sibinal", parent: "12" },
    { value: "1209", name: "Tajumulco", parent: "12" },
    { value: "1210", name: "Tejutla", parent: "12" },
    { value: "1211", name: "San Rafael Pie de la Cuesta", parent: "12" },
    { value: "1212", name: "Nuevo Progreso", parent: "12" },
    { value: "1213", name: "El Tumbador", parent: "12" },
    { value: "1214", name: "El Rodeo", parent: "12" },
    { value: "1215", name: "Malacatán", parent: "12" },
    { value: "1216", name: "Catarina", parent: "12" },
    { value: "1217", name: "Ayutla", parent: "12" },
    { value: "1218", name: "Ocós", parent: "12" },
    { value: "1219", name: "San Pablo", parent: "12" },
    { value: "1220", name: "El Quetzal", parent: "12" },
    { value: "1221", name: "La Reforma", parent: "12" },
    { value: "1222", name: "Pajapita", parent: "12" },
    { value: "1223", name: "Ixchigán", parent: "12" },
    { value: "1224", name: "San José Ojetenam", parent: "12" },
    { value: "1225", name: "San Cristóbal Cucho", parent: "12" },
    { value: "1226", name: "Sipacapa", parent: "12" },
    { value: "1227", name: "Esquipulas Palo Gordo", parent: "12" },
    { value: "1228", name: "Río Blanco", parent: "12" },
    { value: "1229", name: "San Lorenzo", parent: "12" },
    { value: "1301", name: "Huehuetenango", parent: "13" },
    { value: "1302", name: "Chiantla", parent: "13" },
    { value: "1303", name: "Malacatancito", parent: "13" },
    { value: "1304", name: "Cuilco", parent: "13" },
    { value: "1305", name: "Nentón", parent: "13" },
    { value: "1306", name: "San Pedro Necta", parent: "13" },
    { value: "1307", name: "Jacaltenango", parent: "13" },
    { value: "1308", name: "San Pedro Soloma", parent: "13" },
    { value: "1309", name: "San Ildefonso Ixtahuacán", parent: "13" },
    { value: "1310", name: "Santa Bárbara", parent: "13" },
    { value: "1311", name: "La Libertad", parent: "13" },
    { value: "1312", name: "La Democracia", parent: "13" },
    { value: "1313", name: "San Miguel Acatán", parent: "13" },
    { value: "1314", name: "San Rafael la Independencia", parent: "13" },
    { value: "1315", name: "Todos Santos Cuchumatán", parent: "13" },
    { value: "1316", name: "San Juan Atitán", parent: "13" },
    { value: "1317", name: "Santa Eulalia", parent: "13" },
    { value: "1318", name: "San Mateo Ixtatán", parent: "13" },
    { value: "1319", name: "Colotenango", parent: "13" },
    { value: "1320", name: "Santiago Chimaltenango", parent: "13" },
    { value: "1320", name: "San Sebastián Huehuetenango", parent: "13" },
    { value: "1321", name: "Tectitán", parent: "13" },
    { value: "1322", name: "Concepción Huista", parent: "13" },
    { value: "1323", name: "San Juan Ixcoy", parent: "13" },
    { value: "1324", name: "San Antonio Huista", parent: "13" },
    { value: "1325", name: "San Sebastián Coatán", parent: "13" },
    { value: "1326", name: "Santa Cruz Barillas", parent: "13" },
    { value: "1327", name: "Aguacatán", parent: "13" },
    { value: "1328", name: "San Rafael Petzal", parent: "13" },
    { value: "1329", name: "San Gaspar Ixchil", parent: "13" },
    { value: "1331", name: "Unión Cantil", parent: "13" },
    { value: "1331", name: "Santa Ana Huista", parent: "13" },
    { value: "1401", name: "Santa Cruz del Quiché", parent: "14" },
    { value: "1402", name: "Chiché", parent: "14" },
    { value: "1403", name: "Chinique", parent: "14" },
    { value: "1404", name: "Zacualpa", parent: "14" },
    { value: "1405", name: "Chajul", parent: "14" },
    { value: "1406", name: "Chichicastenango", parent: "14" },
    { value: "1407", name: "Patzité", parent: "14" },
    { value: "1408", name: "San Antonio Ilotenango", parent: "14" },
    { value: "1409", name: "San Pedro Jocopilas", parent: "14" },
    { value: "1410", name: "Cunén", parent: "14" },
    { value: "1411", name: "San Juan Cotzal", parent: "14" },
    { value: "1412", name: "Joyabaj", parent: "14" },
    { value: "1413", name: "Nebaj", parent: "14" },
    { value: "1414", name: "San Andrés Sajcabajá", parent: "14" },
    { value: "1415", name: "Uspantán", parent: "14" },
    { value: "1416", name: "Sacapulas", parent: "14" },
    { value: "1417", name: "San Bartolomé Jocotenango", parent: "14" },
    { value: "1418", name: "Canilla", parent: "14" },
    { value: "1419", name: "Chicamán", parent: "14" },
    { value: "1420", name: "Ixcán", parent: "14" },
    { value: "1421", name: "Pachalúm", parent: "14" },
    { value: "1501", name: "Salamá", parent: "15" },
    { value: "1502", name: "San Miguel Chicaj", parent: "15" },
    { value: "1503", name: "Rabinal", parent: "15" },
    { value: "1504", name: "Cubulco", parent: "15" },
    { value: "1505", name: "Granados", parent: "15" },
    { value: "1506", name: "Santa Cruz el Chol", parent: "15" },
    { value: "1507", name: "San Jerónimo", parent: "15" },
    { value: "1508", name: "Purulhá", parent: "15" },
    { value: "1601", name: "Cobán", parent: "16" },
    { value: "1602", name: "Santa Cruz Verapaz", parent: "16" },
    { value: "1603", name: "San Cristóbal Verapaz", parent: "16" },
    { value: "1604", name: "Tactic", parent: "16" },
    { value: "1605", name: "Tamahú", parent: "16" },
    { value: "1606", name: "Tucurú", parent: "16" },
    { value: "1607", name: "Panzós", parent: "16" },
    { value: "1608", name: "Senahú", parent: "16" },
    { value: "1609", name: "San Pedro Carchá", parent: "16" },
    { value: "1610", name: "San Juan Chamelco", parent: "16" },
    { value: "1611", name: "Lanquín", parent: "16" },
    { value: "1612", name: "Santa María Cahabón", parent: "16" },
    { value: "1613", name: "Chisec", parent: "16" },
    { value: "1614", name: "Chahal", parent: "16" },
    { value: "1615", name: "Fray Bartolomé de las Casas", parent: "16" },
    { value: "1616", name: "La Tinta", parent: "16" },
    { value: "1617", name: "Raxruhá", parent: "16" },
    { value: "1701", name: "Flores", parent: "17" },
    { value: "1702", name: "San José", parent: "17" },
    { value: "1703", name: "San Benito", parent: "17" },
    { value: "1704", name: "San Andrés", parent: "17" },
    { value: "1705", name: "La Libertad", parent: "17" },
    { value: "1706", name: "San Francisco", parent: "17" },
    { value: "1707", name: "Santa Ana", parent: "17" },
    { value: "1708", name: "Dolores", parent: "17" },
    { value: "1709", name: "San Luis", parent: "17" },
    { value: "1710", name: "Sayaxche", parent: "17" },
    { value: "1711", name: "Melchor de Mencos", parent: "17" },
    { value: "1712", name: "Poptun", parent: "17" },
    { value: "1801", name: "Puerto Barrios", parent: "18" },
    { value: "1802", name: "Livingston", parent: "18" },
    { value: "1803", name: "El Estor", parent: "18" },
    { value: "1804", name: "Morales", parent: "18" },
    { value: "1805", name: "Los Amates", parent: "18" },
    { value: "1901", name: "Zacapa", parent: "19" },
    { value: "1902", name: "Estanzuela", parent: "19" },
    { value: "1903", name: "Río Hondo", parent: "19" },
    { value: "1904", name: "Gualán", parent: "19" },
    { value: "1905", name: "Teculután", parent: "19" },
    { value: "1906", name: "Usumatlán", parent: "19" },
    { value: "1907", name: "Cabañas", parent: "19" },
    { value: "1908", name: "San Diego", parent: "19" },
    { value: "1909", name: "La Unión", parent: "19" },
    { value: "1910", name: "Huité", parent: "19" },
    { value: "2001", name: "Chiquimula", parent: "20" },
    { value: "2002", name: "San José La Arada", parent: "20" },
    { value: "2003", name: "San Juan La Ermita", parent: "20" },
    { value: "2004", name: "Jocotán", parent: "20" },
    { value: "2005", name: "Camotán", parent: "20" },
    { value: "2006", name: "Olopa", parent: "20" },
    { value: "2007", name: "Esquipulas", parent: "20" },
    { value: "2008", name: "Concepción Las Minas", parent: "20" },
    { value: "2009", name: "Quezaltepeque", parent: "20" },
    { value: "2010", name: "San Jacinto", parent: "20" },
    { value: "2011", name: "Ipala", parent: "20" },
    { value: "2101", name: "Jalapa", parent: "21" },
    { value: "2102", name: "San Pedro Pinula", parent: "21" },
    { value: "2103", name: "San Luis Jilotepeque", parent: "21" },
    { value: "2104", name: "San Manuel Chaparrón", parent: "21" },
    { value: "2105", name: "San Carlos Alzatate", parent: "21" },
    { value: "2106", name: "Monjas", parent: "21" },
    { value: "2107", name: "Mataquescuintla", parent: "21" },
    { value: "2201", name: "Jutiapa", parent: "22" },
    { value: "2202", name: "El Progreso", parent: "22" },
    { value: "2203", name: "Santa Catarina Mita", parent: "22" },
    { value: "2204", name: "Agua Blanca", parent: "22" },
    { value: "2205", name: "Asunción Mita", parent: "22" },
    { value: "2206", name: "Yupiltepeque", parent: "22" },
    { value: "2207", name: "Atescatempa", parent: "22" },
    { value: "2208", name: "Jérez", parent: "22" },
    { value: "2209", name: "El Adelanto", parent: "22" },
    { value: "2210", name: "Zapotitlán", parent: "22" },
    { value: "2211", name: "Comapa", parent: "22" },
    { value: "2212", name: "Jalpatagua", parent: "22" },
    { value: "2213", name: "Conguaco", parent: "22" },
    { value: "2214", name: "Moyuta", parent: "22" },
    { value: "2215", name: "Pasaco", parent: "22" },
    { value: "2216", name: "San José Acatempa", parent: "22" },
    { value: "2217", name: "Quesada", parent: "22" },
  ],
};
