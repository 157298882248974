/**
 * Transaction Status Enumeration
 */
export enum TransactionStatusEnum {
  sale = "Sale",
  refund = "Refund",
  void = "Void",
}

export const TRANSACTION_STATUS_LIST: string[] = [
  TransactionStatusEnum.sale,
  TransactionStatusEnum.void,
  TransactionStatusEnum.refund,
];
