import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { useDeferredStyles as styles } from "../../Deferred.styles";
import {
  BodyDeferredSetting,
  StatusDeferred,
  StatusDeferredLabel,
  StatusMerchantDeferred,
  StatusMerchantDeferredLabel,
  subTitleCardBrands,
  titleConfigurationInformation,
} from "../../Deferred.data";
import { useDeferredSettingPEState } from "./state/useDeferredSettingPE";
import { getCreditCards } from "../../../../shared/infrastructure/constants/cards/CreditCards";
import { Alert } from "@kushki/connect-ui";
import { RulesInformationData } from "../../../BusinessRules/RulesInformation/RulesInformation.data";
export interface DeferredSettingsPEProps {
  acceptCreditCards: string[];
}

export const DeferredSettingsPE: React.FC<DeferredSettingsPEProps> = ({
  acceptCreditCards,
}) => {
  const {
    statusDeferred,
    actions: { selectStatusDeferred, selectStatusMerchantDeferred },
    statusMerchantDeferred,
    isBranchCentralized,
    isMassiveBranches,
    hasUnsentDeferred,
  } = useDeferredSettingPEState();

  const isEnabled: boolean = statusDeferred === StatusDeferred.STATUS_ENABLED;
  const hasAcceptedCreditCards: boolean = acceptCreditCards.length > 0;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      sx={styles.gridList}
    >
      <Grid item xs={12}>
        {isMassiveBranches && hasUnsentDeferred && (
          <Grid item display={"flex"} alignItems={"center"} bgcolor={"#FFF1D0"}>
            <Alert
              title={RulesInformationData.SaveAlertMessage}
              type="warning"
            />
          </Grid>
        )}
        <RadioGroup
          aria-label="statusDeferred"
          name="statusDeferred"
          defaultValue={statusDeferred}
          value={statusDeferred}
          onChange={selectStatusDeferred}
          row
        >
          <FormControlLabel
            value={StatusDeferred.STATUS_DISABLED}
            control={
              <Radio
                color="primary"
                name={StatusDeferred.STATUS_DISABLED}
                disabled={isBranchCentralized}
              />
            }
            label={StatusDeferredLabel.STATUS_DISABLED}
          />
          <FormControlLabel
            value={StatusDeferred.STATUS_ENABLED}
            control={
              <Radio
                color="primary"
                name={StatusDeferred.STATUS_ENABLED}
                disabled={isBranchCentralized}
              />
            }
            label={StatusDeferredLabel.STATUS_ENABLED}
          />
        </RadioGroup>
      </Grid>
      {isEnabled && (
        <Grid item xs={12}>
          <Typography variant={"h5"} color={"primary"} sx={styles.mainTitle}>
            {titleConfigurationInformation}
          </Typography>
          {hasAcceptedCreditCards && (
            <Grid item xs={12}>
              <Typography variant={"h5"} color={"gray"} sx={styles.mainTitle}>
                {subTitleCardBrands}
              </Typography>
              <Typography color="text.grey" variant={"body2"}>
                {BodyDeferredSetting.BodyCardBrands}
              </Typography>
              <Grid container spacing={6}>
                {acceptCreditCards.map((card, index) => {
                  return (
                    <Grid key={index} item xs={1}>
                      {getCreditCards[card.toUpperCase()]}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          )}
          <Typography variant={"h5"} color={"gray"} sx={styles.mainTitle}>
            {BodyDeferredSetting.QuestionStatusDeferredMerchant}
          </Typography>
          <Typography color="text.grey" variant={"body2"}>
            {BodyDeferredSetting.BodyQuestionStatusDeferredMerchant}
          </Typography>
          <RadioGroup
            aria-label="statusDeferred"
            name="statusDeferred"
            defaultValue={statusMerchantDeferred}
            value={statusMerchantDeferred}
            onChange={selectStatusMerchantDeferred}
            row
          >
            <FormControlLabel
              value={StatusMerchantDeferred.STATUS_DISABLED}
              control={<Radio color="primary" disabled={isBranchCentralized} />}
              label={StatusMerchantDeferredLabel.STATUS_DISABLED}
            />
            <FormControlLabel
              value={StatusMerchantDeferred.STATUS_ENABLED}
              control={<Radio color="primary" disabled={isBranchCentralized} />}
              label={StatusMerchantDeferredLabel.STATUS_ENABLED}
            />
          </RadioGroup>
        </Grid>
      )}
    </Grid>
  );
};
