export enum Routes {
  BASE_PATH_RULE_REQUEST_MANAGER = "/rule-request-manager/rules",
  CREATE_RULE_REQUEST = "/rule-request-manager/rules/create",
  CREATE_RULE_DETAIL = "/rule-request-manager/rules/create/detail",
  EDIT_RULE_DETAIL = "/rule-request-manager/rules/edit/detail/:ruleName",
  CREATE_GENERAL_RULE = "/rule-request-manager/general-rules/create",
  RULE_UPDATE = "/rule-request-manager/rules/update/:ruleId/:status",
  CREATE_WHITELIST_RULE = "/rule-request-manager/rules/whitelist",
  GENERAL_RULE_UPDATE = "/rule-request-manager/general-rules/update/:ruleId",
  UPDATE_WHITELIST_RULE = "/rule-request-manager/rules/whitelist/:id/:requestType",
  INDEX = "/",
}
