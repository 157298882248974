import React, { FC } from "react";
import { useBranchContainer } from "./state/useBranchContainer";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { get } from "lodash";
import { branchContainerStyles as styles } from "./BranchContainer.styles";
import AddIcon from "@mui/icons-material/Add";
import {
  BranchEnum,
  ConfigUserModalEnum,
  FormatDateEnum,
  VariantButtonEnum,
} from "../../shared/enums/BranchEnum";
import Dashboard from "../../components/Dashboard/Dashboard";
import { IconCircleInformation } from "@kushki/connect-ui";
import { getCountryFlag } from "../../shared/constants/countryFlag";
import { CellChipStatus } from "../../components/TableCells/CellChip/CellChipStatus";
import moment from "moment";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ComponentsSecurity } from "../../shared/enums/SecurityWrapperEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { displayUtils } from "../../shared/utils/DisplayUtils";
import HeaderSkeleton from "../../components/HeaderSkeleton/HeaderSkeleton";
import { TableSkeleton } from "../../components/TableSkeleton/TableSkeleton";
import { CATALOG_HEADS_NODE } from "../../shared/catalogs/CatalogConfigTable";
import { EntityNameEnum } from "../../shared/enums/EntityNameEnum";
import ModalSimple from "../../components/ModalSimple/ModalSimple";

const BranchContainer: FC = () => {
  const {
    createBranch,
    showEmptyScreen,
    emptyAddText,
    customerList,
    entityName,
    pathCustomer,
    modalStatusDashboard,
    handleChangeStatus,
    merchantCustomer,
    merchantByNodeId,
    handleReturn,
    isLoadingMerchants,
    merchantBillingCore,
    modalConfigUser,
  } = useBranchContainer();

  return (
    <React.Fragment>
      <Box display={displayUtils(!isLoadingMerchants)}>
        <HeaderSkeleton isBranchContainer={true} />
        <TableSkeleton
          headTable={CATALOG_HEADS_NODE[EntityNameEnum.BRANCH]}
          rowsPerPage={10}
        />
      </Box>

      <Box display={displayUtils(isLoadingMerchants)}>
        <Grid container alignItems="center">
          <Grid item marginLeft={3} marginBottom={3}>
            <IconButton style={{ color: "#4399ED" }} onClick={handleReturn}>
              <ArrowBackIosIcon />
              {"Regresar"}
            </IconButton>
          </Grid>
        </Grid>
        <Grid container alignItems="center" sx={styles.gridTitle}>
          <Grid item>
            <Typography color="text.primary" variant="h3">
              {merchantCustomer.name}
            </Typography>
          </Grid>
          <Grid item xs={1} sx={styles.gridCountry}>
            <CellChipStatus
              text={get(merchantBillingCore, "generalInfo.status", "")}
            />
          </Grid>
          <Grid item sx={styles.gridCountry}>
            <Typography color="text.disabled" variant="caption">
              Creado :{" "}
              {moment(merchantCustomer.created).format(
                FormatDateEnum.D_MMM_YYY
              )}
            </Typography>
          </Grid>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={styles.divider}
          />
          <Grid item sx={styles.gridCountry}>
            <Typography color="text.disabled" variant="caption">
              Modificado :{" "}
              {moment(merchantCustomer.updated_at).format(
                FormatDateEnum.D_MMM_YYY
              )}
            </Typography>
          </Grid>
        </Grid>

        <Grid container alignItems="center">
          <Grid item container xs={12} md={"auto"} alignItems="center">
            <Grid item sx={styles.gridText}>
              <Typography variant="caption" color="text.primary">
                País de operación:{" "}
              </Typography>
            </Grid>
            <Grid item sx={styles.gridText}>
              <Box>
                {getCountryFlag[get(merchantCustomer, "countryCode", "")]}
              </Box>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={"auto"} alignItems="center">
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={styles.divider}
            />
            <Grid item sx={styles.gridId}>
              <Typography variant="caption" color="text.primary">
                Customer ID:{" "}
              </Typography>
            </Grid>
            <Grid item sx={styles.gridText}>
              <Typography variant="caption" color="text.primary">
                {get(merchantCustomer, "merchantId", "")}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={"auto"} alignItems="center">
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={styles.divider}
            />
            <Grid item sx={styles.gridId}>
              <Typography variant="caption" color="text.primary">
                Owner:{" "}
              </Typography>
            </Grid>
            <Grid item sx={styles.gridText}>
              <Typography variant="caption" color="text.primary">
                {merchantByNodeId.name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={styles.divider} />
        <Box>
          <Box sx={styles.createCustomerWrapper}>
            <Box sx={styles.boxIcon}>
              <Typography variant="h5" color="text.dark">
                {BranchEnum.HEADER_TITLE}
              </Typography>
              <Tooltip title={BranchEnum.MESSAGE_TOOLTIP} placement={"top"}>
                <IconButton>
                  <IconCircleInformation />
                </IconButton>
              </Tooltip>
            </Box>
            <ComponentSecurityWrapper
              componentId={ComponentsSecurity.CREAR_BRANCH}
            >
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={createBranch}
                disabled={isLoadingMerchants}
              >
                {BranchEnum.CREATE_BRANCH}
              </Button>
            </ComponentSecurityWrapper>
          </Box>
        </Box>
        <Dashboard
          showEmptyScreen={showEmptyScreen}
          emptyAddText={emptyAddText}
          nodeData={customerList}
          basicNodeInfo={{
            entityName,
            path: pathCustomer,
          }}
          modalStatusDashboard={modalStatusDashboard}
          handleChangeStatus={handleChangeStatus}
          customerNode={merchantCustomer}
        />
      </Box>

      <ModalSimple
        isOpen={modalConfigUser.isOpen}
        closeModal={modalConfigUser.closeConfigUserModal}
        title={ConfigUserModalEnum.TITLE}
        description={ConfigUserModalEnum.DESCRIPTION}
        primaryButton={{
          onClick: modalConfigUser.redirectCreateMassiveUsers,
          text: ConfigUserModalEnum.PRIMARY_BUTTON,
        }}
        secondaryButton={ConfigUserModalEnum.SECONDARY_BUTTON}
        primaryButtonVariant={VariantButtonEnum.CONTAINED}
      />
    </React.Fragment>
  );
};

export default BranchContainer;
