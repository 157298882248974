import React, { PropsWithChildren } from "react";
import { FormControl, InputLabel } from "@mui/material";
import { Controller } from "react-hook-form";
import { get } from "lodash";
import { FormHelperTextComponent } from "../FormHelperTextComponent/FormHelperTextComponent";
import { IGenericSelectComponentProps } from "../../../../shared/infrastructure/interfaces/IGenericSelectComponentProps";
import { BasicSelect } from "./components/BasicSelect/BasicSelect";

export const GenericSelectComponent: React.FC<IGenericSelectComponentProps> = (
  props: PropsWithChildren<IGenericSelectComponentProps>
) => {
  const conditionName: string = props.isSpecialCond
    ? "specialCondition"
    : "condition";

  return (
    <>
      <FormControl variant="outlined" fullWidth>
        <InputLabel>{props.placeHolder}</InputLabel>
        <Controller
          name={`${conditionName}.${props.mainProps.index}.${props.name}`}
          control={props.control}
          defaultValue={get(props, `mainProps.item.${props.name}`, "")}
          rules={props.isRuleRequired}
          render={({ ...selectProps }) => (
            <BasicSelect selectProps={selectProps} mainProps={props} />
          )}
        />
        <FormHelperTextComponent
          className={props.className}
          errors={props.errors}
          path={`${conditionName}.${props.mainProps.index}.${props.name}`}
        />
      </FormControl>
    </>
  );
};
