import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { FC } from "react";

const FilterIcon: FC<SvgIconProps> = ({ sx, ...rest }: SvgIconProps) => (
  <SvgIcon viewBox="0 0 22 20" sx={{ p: "1px", ...sx }} {...rest}>
    <path
      d="M21 1H1L9 10.46V17L13 19V10.46L21 1Z"
      fill="transparent"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default FilterIcon;
