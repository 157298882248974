import React, { FC } from "react";
import { useNodeSelection } from "@containers/NodeSelection/state/useNodeSelection";
import GenericHeader from "@components/GenericHeader/GenericHeader";
import { NodeSelectionLabels } from "@shared/constants/labels/nodeSelectionLabels";
import { Box, Container, Typography } from "@mui/material";
import SearchInput from "@components/SearchInput/SearchInput";
import SelectNodeInput from "@components/SelectNodeInput/SelectNodeInput";
import { selectNodeOptions } from "@shared/constants/selectNode";
import { NodeSelectionStyles as styles } from "@containers/NodeSelection/NodeSelection.styles";
import SearchResultTitle from "@components/SearchResultTitle/SearchResultTitle";
import { SecurityWrapperRoles } from "@shared/enum/SecurityWrapperRoles";
import GenericTable from "@components/GenericTable/GenericTable";
import {
  NODE_SELECTION_SORT_CATALOG,
  SortCellProp,
  SortCellPropertyColumn,
  TABLE_COLUMNS,
} from "@shared/constants/tableHeaderConstants";
import { tableTitles } from "@shared/utils/buildTableUtils";
import Footer from "@components/Footer/Footer";
import SelectedNodes from "@components/SelectedNodes/SelectedNodes";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

const NodeSelection: FC = () => {
  const {
    searchInputProps: {
      searchValue,
      handleChangeSearchValue,
      handleSubmitSearchInput,
      label,
    },
    handleChangeNodeInput,
    searchResultTitle,
    showSearchResult,
    handleReturn,
    rows,
    onSelectedRows,
    onSelectedRow,
    actualView,
    onChangeLocalInput,
    isLoadingTable,
    footer,
    nodeType,
    selectedNodes,
    localSearchValue,
    tableFooter,
    handleSelectNodeView,
    handleClearSelectedNodes,
    showSelectedNodesSection,
  } = useNodeSelection();

  return (
    <React.Fragment>
      <GenericHeader
        hasBoldText={true}
        title={NodeSelectionLabels.HEADER_TITLE}
      />

      <Container sx={styles.containerTable}>
        <Typography variant={"h3"} sx={styles.title}>
          {NodeSelectionLabels.TITLE}
        </Typography>
        <Box sx={styles.inputWrapper}>
          <SearchInput
            handleSubmitSearchInput={handleSubmitSearchInput}
            searchValue={searchValue}
            handleChangeSearchValue={handleChangeSearchValue}
            label={label}
          />
          <SelectNodeInput
            value={nodeType}
            handleChangeNode={handleChangeNodeInput}
            options={selectNodeOptions}
          />
        </Box>
        {showSelectedNodesSection && (
          <ComponentSecurityWrapper
            componentId={SecurityWrapperRoles.M_COMPLIANCE_SELECTED_NODES}
          >
            <Box>
              <SelectedNodes
                selectedNodes={selectedNodes}
                onClickNode={handleSelectNodeView}
                showClearButton={true}
                onClearNodes={handleClearSelectedNodes}
              />
            </Box>
          </ComponentSecurityWrapper>
        )}
        <GenericTable<SortCellPropertyColumn, SortCellProp>
          allowSelection
          headerProps={{
            isDisable: false,
          }}
          inputPlaceholder={NodeSelectionLabels.LOCAL_SEARCH}
          rows={rows}
          tableColumns={TABLE_COLUMNS[actualView]}
          showTitle={true}
          title={
            showSearchResult ? (
              <SearchResultTitle
                total={searchResultTitle.total}
                nodeType={searchResultTitle.entityName}
                onReturn={handleReturn}
              />
            ) : (
              tableTitles[actualView]
            )
          }
          showInput={true}
          sortCatalog={NODE_SELECTION_SORT_CATALOG}
          onSelectedRows={onSelectedRows}
          onSelectedRow={onSelectedRow}
          onChangeInput={onChangeLocalInput}
          isLoading={isLoadingTable}
          searchInputValue={localSearchValue}
          tableFooter={tableFooter}
        />
      </Container>
      <Footer
        primaryButtonDisabled={selectedNodes.length === 0}
        handlePrimaryButton={footer.handleNextButton}
        componentIdPrimaryButton={
          SecurityWrapperRoles.M_COMPLIANCE_NODE_SELECTION_NEXT
        }
        primaryButtonText={"Siguiente"}
        handleSecondaryButton={footer.handleCancelButton}
        componentIdSecondaryButton={
          SecurityWrapperRoles.M_COMPLIANCE_NODE_SELECTION_CANCEL
        }
        secondaryButtonText={"Cancelar"}
        primaryButtonLoading={false}
      />
    </React.Fragment>
  );
};

export default NodeSelection;
