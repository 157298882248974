import { configureStore } from "@reduxjs/toolkit";
import billingReducer from "./reducers/billing/billing.slice";
import chargeReducer from "./reducers/charge/charge.slice";
import dispersionReducer from "./reducers/dispersion/dispersion.slice";
import massiveBranchReducer from "./reducers/massiveMerchant/massiveMerchant";
import initialDataReducer from "./reducers/initialData/initialData.slice";
import catalogsReducer from "./reducers/catalogs/catalogs.slice";
import appReducer from "./reducers/app/app.slice";

export const store = configureStore({
  devTools: process.env.NEXT_PUBLIC_ENV_NAME !== "primary",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    app: appReducer,
    billing: billingReducer,
    catalogs: catalogsReducer,
    charge: chargeReducer,
    dispersion: dispersionReducer,
    initialData: initialDataReducer,
    massiveBranch: massiveBranchReducer,
  },
});
