import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAlarmConfigState } from "@store/interfaces/AlarmConfigState.interfaces";
import {
  getRuleAlarm,
  getRuleAlarms,
} from "@store/thunks/alarmConfig/alarmConfig.thunk";

export const alarmConfigState: IAlarmConfigState = {
  isDisabledButton: false,
  isLoading: false,
  isLoadingEditForm: false,
  ruleData: { records: [], total: 0 },
};

export const alarmConfigSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getRuleAlarms.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ruleData = action.payload.data;
      })
      .addCase(getRuleAlarms.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRuleAlarm.fulfilled, (state, action) => {
        state.alarmRule = action.payload;
        state.isLoading = false;
        state.isLoadingEditForm = false;
      })
      .addCase(getRuleAlarm.pending, (state) => {
        state.isLoading = true;
        state.isLoadingEditForm = true;
      })
      .addCase(getRuleAlarm.rejected, (state) => {
        state.isLoadingEditForm = true;
      });
  },
  initialState: alarmConfigState,
  name: "alarmConfig",
  reducers: {
    setIsLoadingButton: (state, action: PayloadAction<boolean>) => {
      state.isDisabledButton = action.payload;
    },
  },
});

export default alarmConfigSlice.reducer;

export const { setIsLoadingButton } = alarmConfigSlice.actions;
