import { useSelector } from "react-redux";
import { defaultTo, get, isEmpty } from "lodash";
import { Data, Catalog } from "../../../types/catalog";
import { KeyValue } from "../../../types/key_value";
import { IAppState } from "../../store/reducer";
import { CatalogsNameEnum } from "../../shared/infrastructure/Enums/CatalogsNameEnum";
import {
  CatalogPropertyEnum,
  RegExpressionCatalogs,
  RetentionCatalogId,
} from "../../shared/infrastructure/Enums/CatalogEnum";
import { IFindItemCatalog } from "../../components/CatalogConfirmDialog/CatalogConfirmDialog.interfaces";
import { RetentionMerchantConfigDynamo } from "../../../types/retention_merchant_configuration";
import { TypeRetentionEnum } from "../../shared/infrastructure/constants/RetentionConstants";
import { CatalogRequest } from "../../../types/catalog_request";

export const catalogsDataToKeyValues = (catalogsData: Data[]): KeyValue[] => {
  return catalogsData.map((item) => ({
    key: item.code,
    value: item.value,
  }));
};

export const catalogsDataToFilterOptions = (
  catalogsData: Data[]
): { key: string; label: string; selected: boolean }[] => {
  return catalogsData.map((item) => ({
    key: item.code,
    label: concatCodeAndValue(item.code, item.value),
    selected: false,
  }));
};

export const codesFromCatalogData = (catalogsData: Data[]): string[] =>
  catalogsData.map((item: Data) => item.code);

export const getDataFromCatalogByName = (
  catalogList: Catalog[] | undefined,
  catalogName: string
): Data[] =>
  get(
    defaultTo(catalogList, []).find(
      (catalog: Catalog) =>
        get(catalog, CatalogPropertyEnum.NAME) === catalogName
    ),
    CatalogPropertyEnum.DATA,
    []
  );

export const getCatalogDataByCode = (
  catalogsList: Catalog[] | undefined,
  catalogName: string,
  code: string
): Data =>
  defaultTo(
    getDataFromCatalogByName(catalogsList, catalogName).find(
      (catalog) => catalog.code === code
    ),
    { code: "-", value: "-" }
  );

export const retentionCatalogsLabelText = (
  retentionCatalogType: string,
  columnIdCode: string
): string => {
  const catalogsList: Catalog[] | undefined = useSelector(
    (state: IAppState) => state.catalogsList
  );

  const retentionCatalogSelectedByCode: Data = getCatalogDataByCode(
    catalogsList,
    retentionCatalogType,
    columnIdCode
  );

  return concatCodeAndValue(
    retentionCatalogSelectedByCode.code,
    retentionCatalogSelectedByCode.value
  );
};

const concatCodeAndValue = (
  code: string,
  value: string,
  separator: string = " - "
): string => `${code}${separator}${value}`;

export const verifyExistsItemCatalog = (
  catalogsData: Catalog[],
  property: string,
  value: string,
  code: string
): IFindItemCatalog => {
  const catalog: Catalog | undefined = catalogsData.find(
    (item: Catalog) => get(item, property) === value
  );
  const result: Data | undefined = defaultTo(
    get(catalog, CatalogPropertyEnum.DATA),
    []
  ).find((item: Data) => get(item, CatalogPropertyEnum.CODE) === code);

  return {
    id: defaultTo(get(catalog, CatalogPropertyEnum.ID), ""),
    itemCatalog: result,
  };
};

export const getCatalogPropertyById = (
  catalogList: Catalog[] | undefined,
  property: string,
  defaultValue: string,
  id: string
): string => {
  const catalog: Catalog | undefined = defaultTo(catalogList, []).find(
    (catalog: Catalog) => get(catalog, CatalogPropertyEnum.ID) === id
  );
  return defaultTo(get(catalog, property), defaultValue);
};

export const validateCodeExpression = (
  catalog: CatalogsNameEnum,
  value: string
): boolean => {
  const regExpresion = RegExpressionCatalogs[catalog];
  const validate = new RegExp(RegExpressionCatalogs[catalog]);
  return regExpresion || !isEmpty(value) ? validate.test(value) : false;
};

export const verifyRetentionConfig = (
  retentionList: RetentionMerchantConfigDynamo[],
  catalogName: string,
  itemCatalogCode: string
): boolean => {
  const result: RetentionMerchantConfigDynamo | undefined = retentionList.find(
    (retention: RetentionMerchantConfigDynamo) =>
      get(retention, RetentionCatalogId[catalogName]) === itemCatalogCode
  );
  return !isEmpty(result);
};

export const getRetentionsConfig = (
  retentions: RetentionMerchantConfigDynamo[],
  catalogName: CatalogsNameEnum,
  code: string
): RetentionMerchantConfigDynamo[] => {
  return retentions.filter(
    (data: RetentionMerchantConfigDynamo) =>
      get(data, RetentionCatalogId[catalogName]) === code
  );
};

const isRetentionIVA = (catalogName: string): boolean =>
  catalogName === CatalogsNameEnum.ACTIVITY ||
  catalogName === CatalogsNameEnum.TAX_PAYER_TYPE;

export const getRetentionType = (catalogName: string): TypeRetentionEnum =>
  isRetentionIVA(catalogName)
    ? TypeRetentionEnum.IVA
    : TypeRetentionEnum.RETENTION;

export const verifyItemCatalogsConfig = (
  catalogsList: Catalog[],
  retentions: RetentionMerchantConfigDynamo[],
  catalogRequest: CatalogRequest
): boolean => {
  const catalogName: string = getCatalogPropertyById(
    catalogsList!,
    CatalogPropertyEnum.NAME,
    "",
    defaultTo(get(catalogRequest, CatalogPropertyEnum.ID), "")
  );

  return verifyRetentionConfig(
    retentions!,
    catalogName,
    defaultTo(get(catalogRequest, CatalogPropertyEnum.CODE), "")
  );
};
