import { IUseDispersionTableState } from "../../../shared/infrastructure/interfaces/useDispersionTableState.interface";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { FilterDispersionResponse } from "../../../../types/filter_dispersion_response";
import { FilterEnum } from "../../../shared/infrastructure/constants/FilterEnum";
import {
  DownloadType,
  LabelsDispersion,
} from "../../../shared/infrastructure/constants/RequestDispersionConstants";
import { DownloadResponseFilesRequest } from "../../../../types/download_response_files_request";
import { downloadResponseFiles } from "../../../store/actionCreators";
import { Routes } from "../../../shared/infrastructure/routes";
import { ValidationDetailsConstants } from "../../../shared/infrastructure/constants/ValidationDetailsConstants";
import { useHistory } from "react-router";
import { ChipClasses } from "../../../shared/infrastructure/interfaces/ChipComponent.interface";

export const useDispersionTableState = (
  classes: Record<
    | "title"
    | "date"
    | "textItem"
    | "downloadButton"
    | "greyChip"
    | "greyChipText"
    | "blueChip"
    | "blueChipText"
    | "redChipInvalid"
    | "redChipInvalidText"
    | "yellowChipPartial"
    | "yellowChipInvalidText",
    string
  >
): IUseDispersionTableState => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoadingGetDispersionFile: boolean | undefined = useSelector(
    (state: IAppState) => state.isLoadingGetDispersionFile
  );
  const getFileDispersionResponse: FilterDispersionResponse | undefined =
    useSelector((state: IAppState) => state.getFileDispersionResponse);

  const retrieveWithTable = (index: number): string => {
    switch (index) {
      case 3:
        return "20%";
      case 4:
        return "20%";

      default:
        return "15%";
    }
  };

  const renderStatusText = (status: string): string => {
    switch (status) {
      case FilterEnum.PROCESSING:
        return LabelsDispersion.ON_PROCESS;

      case FilterEnum.PROCESSED:
        return LabelsDispersion.PROCESSED;

      case FilterEnum.FAILED:
        return LabelsDispersion.INVALID;

      case FilterEnum.PARTIALLY_PROCESSED:
        return LabelsDispersion.PARTIALLY_PROCESSED;

      default:
        return LabelsDispersion.INITIALIZED;
    }
  };

  const buildDate = (created: string | number, renderDate: boolean): string => {
    if (renderDate) {
      return created.toString().split("T")[0];
    } else {
      return created.toString().split("T")[1].split(".")[0];
    }
  };

  const handleBank = (referenceNumber: string): void => {
    const downloadResponseFileRequest: DownloadResponseFilesRequest = {
      referenceNumber,
      type: DownloadType.BANK,
    };

    dispatch(downloadResponseFiles(downloadResponseFileRequest));
  };

  const handleBalance = (referenceNumber: string): void => {
    const downloadResponseFileRequest: DownloadResponseFilesRequest = {
      referenceNumber,
      type: DownloadType.BALANCE,
    };

    dispatch(downloadResponseFiles(downloadResponseFileRequest));
  };

  const handleRedirectToValidationDetails = (referenceNumber: string): void => {
    history.push(
      `${Routes.BASE_PATH_DISPERSION}/${ValidationDetailsConstants.REFERENCE_NUMBER}/${referenceNumber}`
    );
  };

  const getChipClass = (status: string): ChipClasses => {
    switch (status) {
      case FilterEnum.PROCESSING:
        return { chip: classes.greyChip, text: classes.greyChipText };
      case FilterEnum.FAILED:
        return {
          chip: classes.redChipInvalid,
          text: classes.redChipInvalidText,
        };
      case FilterEnum.PARTIALLY_PROCESSED:
        return {
          chip: classes.yellowChipPartial,
          text: classes.yellowChipInvalidText,
        };

      default:
        return { chip: classes.blueChip, text: classes.blueChipText };
    }
  };

  return {
    retrieveWithTable,
    isLoadingGetDispersionFile,
    getFileDispersionResponse,
    renderStatusText,
    buildDate,
    handleBank,
    handleBalance,
    handleRedirectToValidationDetails,
    getChipClass,
  };
};
