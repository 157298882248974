import { IStyles } from "../../shared/interfaces/Styles.interfaces";

export const inputResendCertificateStyles: IStyles = {
  main: {
    backgroundColor: "inherit",
  },
  divider: {
    borderColor: "neutral.grey5",
    marginBottom: "22px",
  },
  input: {
    backgroundColor: "neutral.white",
    filter:
      "drop-shadow(0px 1px 4px rgba(2, 51, 101, 0.08)) drop-shadow(0px 0px 0px rgba(14, 30, 46, 0.1)) drop-shadow(0px 1px 2px rgba(14, 30, 46, 0.2))",
  },
  button: {
    marginLeft: "11px",
    borderRadius: "4px",
    backgroundColor: "primary.main",
    color: "neutral.white",
    borderColor: "primary.main",
    minWidth: "140px",
    fontSize: "16px",
    lineHeight: "22.4px",
    fontWeight: "500",
    "&:hover": {
      backgroundColor: "primary.main",
      color: "neutral.white",
      borderColor: "primary.main",
    },
    "&:focus": {
      backgroundColor: "primary.main",
      color: "neutral.white",
      borderColor: "primary.main",
    },
  },
};
