import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { useEffect } from "react";
import {
  getFileStateDispersionByMerchant,
  setShowAlertOnDispersionsValidation,
} from "../../../store/actionCreators";
import { FileStateDispersionResponse } from "../../../../types/file_state_dispersion_response";
import { defaultTo } from "lodash";

export interface IRequestDispersionState {
  fileStateDispersionResponse: FileStateDispersionResponse | undefined;
  showAlertOnDispersionsValidation: boolean | undefined;
  handleClose: () => void;
}

export const useRequestDispersionState = (): IRequestDispersionState => {
  const dispatch = useDispatch();
  const merchantId: string = defaultTo(localStorage.getItem("merchantId"), "");
  let fileStateDispersionResponse: FileStateDispersionResponse | undefined =
    useSelector((state: IAppState) => state.fileStateDispersionDetails);
  let showAlertOnDispersionsValidation: boolean | undefined = useSelector(
    (state: IAppState) => state.showAlertOnDispersionsValidation
  );

  useEffect(() => {
    dispatch(getFileStateDispersionByMerchant(merchantId));
  }, [dispatch]);

  useEffect(() => {
    if (showAlertOnDispersionsValidation) {
      setTimeout(() => {
        dispatch(setShowAlertOnDispersionsValidation(false));
      }, 2500);
    }
  }, [showAlertOnDispersionsValidation]);

  const handleClose = () => {
    dispatch(setShowAlertOnDispersionsValidation(false));
  };

  return {
    fileStateDispersionResponse,
    showAlertOnDispersionsValidation,
    handleClose,
  };
};
