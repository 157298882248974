import React from "react";
import { isEmpty, isNil } from "lodash";
import { IModalDetailInfo } from "../../components/ModalDetailInfo/ModalDetailInfo.interfaces";
import { TransactionInfo } from "../../../types/transaction";
import { PaymentMethodEnum } from "../enums/PaymentMethodEnum";
import { IconDownload } from "@kushki/connect-ui";
import { TransactionLabelsEnum } from "../enums/transactionLabelsEnum";
import { OverridableStringUnion } from "@mui/types";
import { ChipPropsColorOverrides } from "@mui/material/Chip/Chip";
import { ButtonPropsVariantOverrides } from "@mui/material/Button/Button";
import { isApproval } from "../utils/general_utils";

type Color = OverridableStringUnion<
  | "default"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning",
  ChipPropsColorOverrides
>;
export type Button = {
  text: string;
  onClick: () => void;
  isDisabled?: boolean;
  color?: Color;
  startIcon?: React.ReactNode;
  variant?: OverridableStringUnion<
    "text" | "outlined" | "contained",
    ButtonPropsVariantOverrides
  >;
};

export const modalHeaderButtons = (
  props: IModalDetailInfo,
  trx: TransactionInfo
): Button | null => {
  if (isEmpty(trx.ticket_number)) return null;

  if (isApproval(trx.transaction_status ?? "")) {
    switch (trx.payment_method) {
      case PaymentMethodEnum.TRANSFER:
        return {
          color: "primary",
          onClick: () => props.modalButtonActions.downloadButton(trx),
          startIcon: <IconDownload />,
          text: TransactionLabelsEnum.MODAL_DETAIL_BUTTON_SECONDARY,
          variant: "contained",
        };
      default:
        return null;
    }
  }

  return null;
};

export const renderButton = (
  props: IModalDetailInfo,
  trx: TransactionInfo
): Button[] => {
  const button: Button | null = modalHeaderButtons(props, trx)!;

  return !isNil(button)
    ? [
        {
          ...button,
        },
      ]
    : [];
};
