import { useParams } from "react-router-dom";
import { MerchantResponse } from "../../../../types/merchant_response";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { get } from "lodash";
import { routes } from "../../../shared/infrastructure/constants/routes";

export interface IUseDeveloperSection {
  merchantId: string;
  handleHrefPaymentCredentials: () => void;
  handleHrefWebhook: () => void;
}

export const useDeveloperSection = (): IUseDeveloperSection => {
  const { merchantId } = useParams();
  const merchant: MerchantResponse = useSelector(
    (state: IAppState) => state.merchant!
  );

  const handleHrefPaymentCredentials = (): void => {
    window.location.href = `${routes.MERCHANT_CREDENTIALS}/${merchantId}`;
  };

  const handleHrefWebhook = (): void => {
    window.location.href = `${routes.WEBHOOKS}/${merchantId}`;
  };

  return {
    merchantId: get(merchant, "publicMerchantId", merchantId),
    handleHrefPaymentCredentials,
    handleHrefWebhook,
  };
};
