import React from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { isRoleActive } from "../../shared/infrastructure/RolesUtil";
import { UrlsEnum } from "../../shared/infrastructure/enums/UrlsEnum";
import {
  MonitoringConstantIndex,
  MonitoringRoles,
} from "../../shared/infrastructure/constants/MonitoringConstantIndex";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

const useStyles = makeStyles(() => ({
  labelTitle: {
    color: "#023365",
    fontSize: "20px",
    fontWeight: 500,
    fontStyle: "normal",
  },
  innerLabel: {
    color: "#6D7781",
    fontSize: "14px",
    fontWeight: 500,
    fontStyle: "normal",
    paddingBottom: "6px",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 20,
    textAlign: "center",
    color: "#777",
  },
  wrapper: {
    position: "relative",
  },
  label: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "170%",
    color: "#677784",
    padding: "12px",
    paddingLeft: "0px",
  },
  button: {
    margin: "16px",
    marginRight: "0px",
    paddingInline: "20px",
  },
}));

export const MonitoringIndex: React.FC = () => {
  const classes = useStyles();
  const isRiskManager: boolean = isRoleActive("RiskManager");

  return (
    <React.Fragment>
      <Container fixed>
        <Breadcrumb
          items={[{ label: "Inicio", url: "/dashboard" }]}
          lastItem={"Prevención de Fraude"}
        />
        <Box pt={2} pb={3}>
          <Typography variant={"h1"}>Prevención de Fraude</Typography>
        </Box>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <ComponentSecurityWrapper
                  componentId={`${MonitoringRoles.M_FRAUD_PREVENTION}.${MonitoringRoles.MONITORING_SECURITY_RULES}`}
                >
                  <Link
                    className={classes.labelTitle}
                    to={UrlsEnum.RULES_3_ROUTE}
                  >
                    Reglas de seguridad
                  </Link>
                </ComponentSecurityWrapper>
                <Box pt={1}>
                  <Typography
                    variant={"subtitle2"}
                    color={"textPrimary"}
                    className={classes.innerLabel}
                  >
                    {MonitoringConstantIndex.RULES_3_SUBTITLE}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <ComponentSecurityWrapper
                  componentId={`${MonitoringRoles.M_FRAUD_PREVENTION}.${MonitoringRoles.MONITORING_SIFTSCIENCE}`}
                >
                  <Link
                    className={classes.labelTitle}
                    to="/monitoring/siftscience"
                  >
                    SiftScience
                  </Link>
                </ComponentSecurityWrapper>
                <Box pt={1}>
                  <Typography
                    variant={"subtitle2"}
                    color={"textPrimary"}
                    className={classes.innerLabel}
                  >
                    Aquí podrás buscar y elegir un comercio en específico para
                    después poder ingresar las credenciales de Sift Science.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              {isRiskManager && (
                <Grid item xs={6}>
                  <ComponentSecurityWrapper
                    componentId={`${MonitoringRoles.M_FRAUD_PREVENTION}.${MonitoringRoles.MONITORING_BALANCER_SAVE}`}
                  >
                    <Link
                      className={classes.labelTitle}
                      to={UrlsEnum.BALANCER_ROUTE}
                    >
                      Balanceador
                    </Link>
                  </ComponentSecurityWrapper>
                  <Box pt={1}>
                    <Typography
                      variant={"subtitle2"}
                      color={"textPrimary"}
                      className={classes.innerLabel}
                    >
                      {MonitoringConstantIndex.BALANCER_RULES_SUBTITLE}
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </React.Fragment>
  );
};
