import { Checkbox } from "@mui/material";
import React, { FC, PropsWithChildren } from "react";
import { ICellCheckProps } from "./CellCheck.interfaces";

export const CellCheckItem: FC<ICellCheckProps> = (
  props: PropsWithChildren<ICellCheckProps>
) => {
  const { isChecked, id } = props;

  return (
    <Checkbox
      color="primary"
      checked={isChecked}
      inputProps={{
        "aria-labelledby": id,
      }}
    />
  );
};
