import { TransactionType } from "../../enums/transaction_types";

export const M_WALLET = "M_WALLET";

const CREDIT_TITLE = "Recarga de balance";
const DEBIT_TITLE = "Débito de balance";

export const WALLET_TITLE: Record<string, string> = {
  [TransactionType.CREDIT]: CREDIT_TITLE,
  [TransactionType.DEBIT]: DEBIT_TITLE,
};
