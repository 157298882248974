import { ActionTypes } from "./actionTypes";
import { IAppAction } from "./actionCreators";
import { INotification } from "../shared/infrastructure/interfaces/INotification";
import { ProcessorFetch } from "../shared/infrastructure/interfaces/ProcessorFetch";
import { IDefaultProcessorForm } from "../shared/infrastructure/interfaces/IDefaultProcessorForm";
import { MerchantProperties } from "../../types/merchants";
import { GetAppConfigPaymentMethodsResponse } from "../../types/get_app_config_payment_method_response";

export interface IAppState {
  notification?: INotification;
  processors?: ProcessorFetch[];
  loading?: boolean;
  dialogClosed?: boolean;
  loadingDialog?: boolean;
  refreshProcessors?: boolean;
  defaultProcessor?: IDefaultProcessorForm;
  defaultPayoutsTransferProcessor?: IDefaultProcessorForm;
  merchant?: MerchantProperties;
  totalProcessors?: number;
  allProcessors?: ProcessorFetch[];
  appConfigPaymentMethods?: GetAppConfigPaymentMethodsResponse;
}

export const INITIAL_STATE: IAppState = {
  notification: {
    type: "success",
    open: false,
    message: "",
  },
  loading: false,
  loadingDialog: false,
  refreshProcessors: false,
  dialogClosed: false,
  defaultProcessor: {
    card: "",
    transfer: "",
    achTransfer: "",
    payoutsCard: "",
    payoutsTransfer: "",
  },
  merchant: undefined,
  appConfigPaymentMethods: [],
};

export const reducer = (
  state: IAppState = INITIAL_STATE,
  action: IAppAction
): IAppState => {
  switch (action.type) {
    case ActionTypes.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.notification,
      };
    case ActionTypes.SET_PROCESSORS:
      return {
        ...state,
        processors: action.processors,
      };
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ActionTypes.SET_LOADING_DIALOG:
      return {
        ...state,
        loadingDialog: action.loadingDialog,
      };
    case ActionTypes.SET_REFRESH_PROCESSOR:
      return {
        ...state,
        refreshProcessors: action.refreshProcessors,
      };
    case ActionTypes.SET_CLOSE_DIALOG:
      return {
        ...state,
        dialogClosed: action.dialogClosed,
      };
    case ActionTypes.GET_DEFAULT_PROCESSOR:
      return {
        ...state,
        defaultProcessor: action.defaultProcessor,
      };
    case ActionTypes.GET_DEFAULT_PAYOUTS_TRANSFER_PROCESSOR:
      return {
        ...state,
        defaultPayoutsTransferProcessor: action.defaultPayoutsTransferProcessor,
      };
    case ActionTypes.SET_MERCHANT:
      return {
        ...state,
        merchant: action.merchant,
      };
    case ActionTypes.SET_TOTAL_PROCESSORS:
      return {
        ...state,
        totalProcessors: action.totalProcessors,
      };
    case ActionTypes.GET_ALL_PROCESSORS:
      return {
        ...state,
        allProcessors: action.allProcessors,
      };
    case ActionTypes.SET_APPCONFIG_PAYMENT_METHODS:
      return {
        ...state,
        appConfigPaymentMethods: action.appConfigPaymentMethods,
      };
    default:
      return state;
  }
};
