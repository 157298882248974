export const MODAL_TABS: string[] = [
  "Información",
  "Configuración",
  "Línea de Tiempo",
];

export enum ConfigurationSectionsEnum {
  GENERAL_DATA = "Datos generales",
  ALIAS = "Alias",
  PROVIDER_NAME = "Nombre de proveedor",
  PROVIDER_3DS = "Cybersource",
  PROVIDER_OTP = "Kushki",
  AUTHENTICATION_TYPE = "Tipo de autenticación",
  AUTHENTICATION_RULES = "Reglas de autenticación N° 1",
  GENERAL_CONDITIONS = "Condiciones generales",
  SPEED_CONDITIONS = "Condiciones de velocidad",
  ADDITIONAL_SETTINGS = "Configuración adicional",
  ASSUME_TRANSACTIONAL_RISK = "Asumir riesgo transaccional",
}
