import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { IActionsModal, IUsersState } from "../../interfaces/users.interfaces";
import {
  getFirstParent,
  getNodeInfoByMerchantId,
  getUserList,
  searchMerchants,
} from "../../thunks/users/users.thunk";
import { ITableRowProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { ActionsUserTypeEnum } from "../../../shared/enum/UsersTableEnum";
import { IUserList } from "../../../shared/interfaces/IUseUsersContainerState";
import { SearchMerchantResponse } from "../../../../types/search_merchant_response";

export const initialState: IUsersState = {
  actionsModal: {
    cancelButton: "",
    confirmButton: "",
    description: "",
    openModal: false,
    title: "",
    type: ActionsUserTypeEnum.EMPTY,
    userSelected: {} as IUserList,
  },
  firstParent: {},
  isDisabledTable: false,
  isLoadedInfo: false,
  isLoadingTable: false,
  nodeInfoCustomer: {},
  rowsTable: [],
  searchMerchant: {},
  userList: {
    PaginationToken: "",
    Users: [],
  },
};

export const usersSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getUserList.fulfilled, (state, { payload }) => {
        state.userList = payload.response;
        state.rowsTable = payload.rows;
        state.isLoadingTable = false;
      })
      .addCase(getUserList.pending, (state) => {
        state.isLoadingTable = true;
      })
      .addCase(getUserList.rejected, (state) => {
        state.rowsTable = [];
        state.isLoadingTable = false;
      });
    builder
      .addCase(getFirstParent.fulfilled, (state, { payload }) => {
        state.firstParent = payload;
        state.isLoadedInfo = true;
      })
      .addCase(getFirstParent.rejected, (state) => {
        state.firstParent = {};
        state.isLoadedInfo = false;
      });
    builder.addCase(getNodeInfoByMerchantId.fulfilled, (state, action) => {
      state.nodeInfoCustomer = action.payload;
    });

    builder.addCase(searchMerchants.fulfilled, (state, action) => {
      state.searchMerchant = action.payload;
    });
  },
  initialState,
  name: "users",
  reducers: {
    setActionsModal: (
      state: Draft<IUsersState>,
      action: PayloadAction<IActionsModal>
    ) => {
      state.actionsModal = action.payload;
    },
    setIsDisabledTable: (state, action) => {
      state.isDisabledTable = action.payload;
    },
    setIsLoadingTable: (
      state: Draft<IUsersState>,
      action: PayloadAction<boolean>
    ) => {
      state.isLoadingTable = action.payload;
    },
    setRowstable: (
      state: Draft<IUsersState>,
      action: PayloadAction<ITableRowProps[]>
    ) => {
      state.rowsTable = action.payload;
    },
    setRowstableCustomer: (
      state: Draft<IUsersState>,
      action: PayloadAction<ITableRowProps[]>
    ) => {
      state.rowsTable = [...state.rowsTable, ...action.payload];
    },
    setSearchMerchant: (
      state: Draft<IUsersState>,
      action: PayloadAction<SearchMerchantResponse>
    ) => {
      state.searchMerchant = action.payload;
    },
    setUserList: (state, action) => {
      state.userList.Users = [...state.userList.Users, ...action.payload.Users];
    },
  },
});

export default usersSlice.reducer;
export const {
  setActionsModal,
  setRowstable,
  setIsLoadingTable,
  setSearchMerchant,
  setUserList,
  setRowstableCustomer,
  setIsDisabledTable,
} = usersSlice.actions;
