import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  labelTitle: {
    color: "#023365",
    fontSize: "16px",
    marginBottom: "18px",
    marginTop: 25,
  },
  labelDescription: {
    color: "#6D7781",
    fontSize: "12px",
    lineHeight: "136%",
    marginBottom: "8px",
  },
  icon: {
    width: 40,
    fontWeight: 600,
    color: "#023365",
  },
  button: {
    display: "flex",
    width: "100%",
    backgroundColor: "white",
    borderRadius: "4px",
    boxShadow: "0px 1px 3px #0000004D;",
    marginTop: "24px",
    padding: 26,
  },
  saveShopifyButton: {
    borderRadius: 0,
    backgroundColor: "white",
    height: 48,
    marginLeft: 5,
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
      borderRadius: 5,
      marginTop: 7,
      marginBottom: 7,
    },
  },
  checkoutButton: {
    borderRadius: "4px",
    height: 48,
    fontWeight: 600,
    backgroundColor: "#B4B9BE",
    color: "white !important",
    border: "none",
    width: "100%",
    lineHeight: "normal",
  },
  checkoutButtonActive: {
    borderRadius: "4px",
    height: 48,
    fontWeight: 600,
    backgroundColor: "#023365",
    color: "white !important",
    border: "none",
    width: "100%",
    "&:hover": {
      backgroundColor: "rgb(2,51,101,0.8)",
    },
    lineHeight: "normal",
  },
  labelInfo: {
    color: "#6D7781",
    fontSize: "12px",
    lineHeight: "136%",
    marginLeft: "8px",
    flex: 1,
  },
  containerInfo: {
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
  },
  dialogDomain: {
    width: "635px",
    minHeight: "170px",
    padding: "10px",
  },
  dialogTitle: {
    color: "#023365",
    fontSize: "20px",
    fontWeight: 500,
  },
  dialogContainer: {
    fontSize: "15px",
  },
  helperText: {
    marginLeft: "-14px",
    color: "#6D7781",
    display: "flex",
    alignItems: "center",
    marginBottom: 7,
    marginTop: 7,
  },
  helperTextIcon: {
    color: "transparent",
    marginRight: "8px",
  },
  inputLabel: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
    },
  },
  inputTextCredential: {
    marginRight: "10px",
  },
  credentialsSection: {
    marginTop: "36px",
  },
  copyIcon: {
    marginLeft: 10,
    padding: "0px",
    color: "#CBD5E0",
  },
}));
