import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const metricsFilterBarStyles = createNamedStyles({
  datePickerBox: {
    ".MuiButton-root": {
      width: { md: "320px", sm: "-webkit-fill-available" },
    },
    display: "flex",
    justifyContent: "end",
  },
  filterSideBarButtonBox: {
    display: "flex",
    justifyContent: { md: "start", sm: "end" },
  },
  selectedFiltersBox: {
    width: "-webkit-fit-avaliable",
  },
});
