export enum SiftScienceSubtitleSection {
  MERCHANT_HEADER = "Ingrese el nombre o ID del comercio",
}

export enum LabelField {
  API_KEY_PROD = "Sift Science API Key Prod",
  API_KEY_SANDBOX = "Sift Science API Key SandBox",
  PROD_ACCOUNT_ID = "Sift Science Prod Account ID",
  SANDBOX_ACCOUNT_ID = "Sift Science SandBox Account ID",
  BACON_PROD_API_KEY = "Sift Science Bacon Prod API Key",
  BACON_SANDBOX_API_KEY = "Sift Science Bacon SandBox API Key",
  EXIT_NOT_SAVE = "Estas a punto de abandonar este formulario, los cambios que hayas realizado no serán guardados.",
  EXIT_NOT_SAVE_TITLE = "¿Deseas salir sin guardar?",
  SAVE = "Al guardar esta configuración se creará una nueva regla de Sift Science, correspondiente al negocio que elegiste. Podrás visualizar o editar esta regla en el modulo 'Reglas de Seguridad'",
  BLANK_SPACES = " ",
}

export enum ButtonText {
  CANCEL = "Cancelar",
  SAVE = "Guardar",
  NOT_SAVE = "Salir sin guardar",
}

export enum SiftScienceNotificationResultEnum {
  SIFTSCIENCE_REQUEST_SUCCESS = "SIFTSCIENCE_REQUEST_SUCCESS",
  SIFTSCIENCE_REQUEST_ERROR = "SIFTSCIENCE_REQUEST_ERROR",
  VALIDATION_ERROR = "VALIDATION_ERROR",
  NO_ACTION = "NO_ACTION",
  DEFAULT_MESSAGE_ERROR_GET_MERCHANTS = "Error en la obtención de los merchants.",
  DEFAULT_MESSAGE_ERROR_GET_CREDENTIALS = "Error en la obtención de las credenciales.",
  DEFAULT_MESSAGE_SAVE_CREDENTIALS = "Error en actualizar las credenciales",
  SUCCESS = "SUCCESS",
  SIFTSCIENCE_REQUEST_MIGRATE_SUCCESS = "Los cambios han sido guardados de manera exitosa",
  MIGRATE_SIFTSCIENCE_MESSAGE = "Las credenciales siftscience ya fueron migradas para este comercio",
  MIGRATE_SIFTSCIENCE_SUCCESS = "Credenciales migradas exitosamente",
  NOT_CREDENTIALS_MESSAGE = "merchant doesn't exist",
  REQUIRED_FIELD = "Campo requerido",
}
