export enum TransactionTypeEnum {
  PAYIN_COMISSION = "B2B_PAYIN",
  PAYOUT_COMISSION = "B2B_PAYOUT",
  MINIMAL_COMISSION = "minimalCommission",
  MINIMAL_COMMISSION_FIXED = "MINIMAL_COMMISSION_FIXED",
  MINIMAL_COMMISSION_DEDUCTIBLE = "MINIMAL_COMMISSION_DEDUCTIBLE",
  ADDITIONAL_SERVICE = "Servicio adicional",
  THREE_DS = "3ds",
}

export const TRADUCE_TYPE = {
  PAYIN: "Pay - In",
  PAYOUT: "Pay - Out",
  COMMISSION: "Mínimo Comisional",
  ADITIONAL_SERVICE: "Servicio Adicional",
};
