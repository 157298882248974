import { get } from "lodash";
import { IDataWebsockets } from "../../../shared/interfaces/IDataWebSocket";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { ThunkAction } from "redux-thunk";
import { IAppState } from "../../interfaces/AppState.interfaces";
import {
  setMassiveResponse,
  setStatusComplete,
  setStatusError,
} from "../../actions/massiveBranch.actions";
import { BranchCreation } from "../../../components/BranchManualCreationForm/state/useBranchManualCreation.interfaces";
import { buildNotification } from "../../../shared/constants/snackbar";
import { NotificationTypeEnum } from "../../../shared/enums/SnackbarEnum";
import { setNotification } from "../../actions/createMerchantNode.actions";
import { IMassiveBranchResponse } from "../../interfaces/massiveBranch/massiveBranch.interfaces";

export type IAppAction = {
  type: string;
};

export const createMassiveBranch = (
  branches: BranchCreation[],
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (dispatch): Promise<void> => {
    const jwt: string | null = localStorage.getItem("jwt");
    let webSocket: WebSocket | undefined;
    const params: object = {
      action: "createMassiveBranch",
      data: {
        auth: jwt,
        branches: branches,
        merchantId: merchantId,
      },
    };

    try {
      webSocket = new WebSocket(
        `${API_ROUTES.WS_MASSIVE}?Authorization=${jwt}`
      );
      webSocket.onopen = () => {
        webSocket?.send(JSON.stringify(params));
      };

      webSocket.onerror = () => {
        webSocket?.close();
      };

      webSocket.onmessage = (event: MessageEvent) => {
        const response: IDataWebsockets = JSON.parse(event.data);
        const status: string | undefined = get(response, "status");

        if (status === "complete") {
          if (!get(response, "error")) {
            dispatch(
              setMassiveResponse(
                get(response, "data", []) as IMassiveBranchResponse[]
              )
            );
            dispatch(setStatusComplete());
          }
          webSocket?.close();
        }
        if (status === "error") {
          dispatch(
            setNotification(
              buildNotification(NotificationTypeEnum.CUSTOM, {
                color: "danger",
                message: get(response, "data.newError.message"),
                variant: "simple",
                withIcon: false,
              })
            )
          );
          dispatch(setStatusError());
          webSocket?.close();
        }
      };
    } catch (e) {
      if (webSocket) webSocket.close();
    }
  };
};
