/* eslint-disable no-trailing-spaces */

import React, { SVGProps } from "react";

const SvgDiscover = (props: SVGProps<SVGSVGElement>) => (
  <svg
    role="img"
    aria-label="discover card icon"
    width="1em"
    height="1em"
    viewBox="0 0 37 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clipDiscover)">
      <path
        d="M36.428 23.357A2.141 2.141 0 0134.29 25.5H2.383a2.14 2.14 0 01-2.139-2.143V2.643A2.14 2.14 0 012.383.5H34.29a2.14 2.14 0 012.139 2.143v20.714z"
        fill="#fff"
      />
      <path
        d="M2.15 2.41l33.261-.003.001 21.185H2.152L2.152 2.41z"
        fill="#fff"
      />
      <path
        d="M35.288 14.19s-9.087 6.411-25.73 9.283h25.73V14.19z"
        fill="#D68500"
      />
      <path
        d="M19.575 7.725c-1.365 0-2.473 1.066-2.473 2.383 0 1.4 1.06 2.445 2.473 2.445 1.378 0 2.466-1.06 2.466-2.418 0-1.35-1.08-2.41-2.466-2.41z"
        fill="url(#paint0_radial)"
      />
      <path
        d="M5.537 7.812h-1.33v4.637H5.53c.702 0 1.209-.166 1.654-.535a2.333 2.333 0 00.842-1.781c0-1.37-1.023-2.321-2.49-2.321zm1.057 3.483c-.285.257-.653.369-1.239.369h-.243V8.597h.243c.585
        0 .94.104 1.24.375.312.278.499.708.499 1.154 0 .446-.187.891-.5 1.169zM9.346 7.812h-.903v4.637h.903V7.812zM11.559 9.59c-.543-.201-.703-.334-.703-.583 0-.292.284-.515.674-.515.271 0
        .494.111.73.374l.473-.617a2.028 2.028 0 00-1.363-.516c-.82 0-1.445.57-1.445 1.328 0 .64.291.967 1.14 1.273.354.125.535.208.626.264.18.119.27.285.27.48 0 .376-.298.653-.702.653-.431 
        0-.779-.215-.987-.618l-.584.564c.417.61.917.882 1.607.882.938 0 1.599-.626 1.599-1.523-.001-.736-.306-1.07-1.335-1.446zM13.178 10.133c0 1.363 1.071 2.42 2.449 2.42.39 0 .722-.077 
        1.134-.271v-1.064c-.362.362-.683.507-1.093.507-.91 0-1.558-.66-1.558-1.6 0-.89.667-1.591 1.517-1.591.43 0 .757.152 1.134.521V7.991c-.396-.2-.724-.283-1.114-.283-1.37-.001-2.469 
        1.077-2.469 2.425zM24.088 10.927L22.85 7.812h-.987l1.968 
        4.755h.486l2.003-4.755h-.98l-1.252 3.115zM26.732 12.45h2.565v-.786h-1.66V10.41h1.596v-.784h-1.597v-1.03h1.661v-.785h-2.565v4.637zM32.878 
        9.18c0-.869-.597-1.368-1.64-1.368h-1.343v4.637h.905v-1.864h.119l1.25 1.864h1.113l-1.461-1.953c.681-.14 1.057-.606 1.057-1.316zm-1.816.766H30.8V8.541h.278c.564 0 .87.237.87.689 0 
        .465-.306.716-.885.716zM33.515 8.157c0-.08-.055-.125-.154-.125h-.131v.408h.097v-.159l.114.16h.12l-.135-.17a.112.112 0 00.089-.114zm-.17.056h-.018v-.106h.018c.049 0 .073.017.073.052 0 
        .035-.025.054-.074.054z"
        fill="#000"
      />
      <path
        d="M33.381 7.878a.357.357 0 000 .714c.194 0 .352-.16.352-.357a.355.355 0 00-.352-.357zm0 .65a.288.288 0 01-.286-.291c0-.164.127-.293.285-.293.154 0 .281.132.281.293 0 
        .16-.126.291-.28.291zM12.313 13.668l1.21 1.267v-1.201h.174v1.62l-1.21-1.264v1.195h-.173v-1.617z"
        fill="#000"
      />
      <path
        d="M12.297 13.667v1.634h.205V14.13l1.21 1.265v-1.677h-.205v1.177c-.053-.057-1.21-1.268-1.21-1.268v.04zm.033.04l1.21 1.268V13.75h.14v1.566l-1.21-1.266v1.22h-.14v-1.561zM14.535 
        13.734h.803v.16h-.63v.461h.612v.16h-.613v.609h.631v.16h-.803v-1.55z"
        fill="#000"
      />
      <path
        d="M15.338 13.718h-.82v1.584h.836v-.194h-.63v-.577h.611v-.191h-.612v-.428h.631v-.194h-.016zm-.016.031v.129h-.632v.493h.613v.129h-.613v.64h.632v.13h-.772v-1.52h.772zM16.56 
        15.285h-.174v-1.391h-.374v-.16h.925v.16h-.379v1.391h.001z"
        fill="#000"
      />
      <path
        d="M16.937 13.718h-.942v.194h.375v1.39h.205v-1.39h.378v-.194h-.016zm-.016.031v.129h-.379v1.391h-.14v-1.391h-.375v-.129h.894zM18.094 14.896l.502-1.243.502 1.243.407-1.162h.188l-.595 
        1.631-.502-1.25-.502 1.25-.595-1.631h.187l.408 1.162z"
        fill="#000"
      />
      <path
        d="M18.581 13.647l-.486 1.203-.397-1.133h-.222l.618 1.694.502-1.251c.027.07.502 1.251.502 1.251l.617-1.694h-.221l-.397 1.133-.501-1.24-.015.037zm-.473 1.255l.488-1.205c.027.07.503 
        1.246.503 1.246l.418-1.194h.152c-.014.042-.544 1.496-.572 1.572l-.501-1.247-.502 1.247-.572-1.572h.152l.419 1.194.015-.04zM21.927 14.511c0 .447-.366.801-.808.801a.805.805 0 
        01-.81-.8.809.809 0 011.618 0zm-1.444-.002c0 .35.284.642.636.642a.641.641 0 00.636-.642.638.638 0 00-.636-.643.64.64 0 00-.636.643z"
        fill="#000"
      />
      <path
        d="M20.294 14.512c0 .45.37.817.825.817a.822.822 0 00.825-.817c0-.453-.37-.82-.825-.82a.822.822 0 00-.825.82zm.032 0a.79.79 0 01.793-.788c.436 0 .792.353.792.788a.79.79 0 
        01-.792.784.79.79 0 01-.793-.784z"
        fill="#000"
      />
      <path
        d="M20.466 14.509c0 .363.293.658.652.658.36 0 .652-.294.652-.658a.655.655 0 00-.651-.658.657.657 0 00-.653.658zm.033 0c0-.345.277-.626.62-.626.342 0 .618.28.618.626a.623.623 0 
        01-.618.625.622.622 0 01-.62-.625zM22.85 15.285h-.173v-1.551h.225c.293 0 .567.078.567.428 0 .239-.149.408-.389.428l.494.696h-.212l-.467-.681h-.044v.68h-.001zm0-.834h.053c.195 0 
        .394-.037.394-.277 0-.254-.188-.28-.395-.28h-.052v.557z"
        fill="#000"
      />
      <path
        d="M22.677 13.718h-.016v1.584h.205v-.682h.027c0 .012.456.674.456.674l.225.008h.032l-.499-.703a.418.418 0 00.378-.437c0-.3-.19-.444-.583-.444h-.225zm.224.031c.376 0 .551.131.551.412 
        0 .231-.146.394-.374.412l-.029.003.493.692h-.182c0-.011-.456-.674-.456-.674l-.056-.007h-.016v.681h-.14v-1.52l.209.001z"
        fill="#000"
      />
      <path
        d="M22.85 13.877h-.017v.59h.07c.19 0 .41-.033.41-.293 0-.274-.212-.297-.411-.297h-.052zm.05.034c.2 0 .379.019.379.263 0 .23-.19.261-.376.261h-.037v-.523l.035-.001zM25.14 
        13.734h.232l-.728.723.753.828h-.24l-.635-.712-.043.043v.67h-.173v-1.552h.173v.664l.66-.664z"
        fill="#000"
      />
      <path
        d="M25.372 13.718h-.238l-.639.642v-.642h-.204v1.584h.204v-.684a.113.113 0 
        00.025-.021c.022.023.625.7.625.7l.25.005h.038s-.747-.823-.769-.845l.745-.74h-.037zm-.04.031l-.71.707.739.813h-.202c0-.008-.624-.706-.624-.706l-.011-.014
        -.056.056-.004.664h-.14v-1.52h.14v.689l.683-.689h.186z"
        fill="#000"
      />
    </g>
    <defs>
      <radialGradient
        id="paint0_radial"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(19.572 10.139) scale(2.44202)"
      >
        <stop offset={0.006} stopColor="#FBF6EB" />
        <stop offset={0.097} stopColor="#FAF1E3" />
        <stop offset={0.246} stopColor="#F5E5CC" />
        <stop offset={0.434} stopColor="#EED1A5" />
        <stop offset={0.653} stopColor="#E5B576" />
        <stop offset={0.897} stopColor="#DA933C" />
        <stop offset={1} stopColor="#D68500" />
      </radialGradient>
      <clipPath id="clipDiscover">
        <path
          fill="#fff"
          transform="translate(.244 .5)"
          d="M0 0h36.184v25H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgDiscover;
