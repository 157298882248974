/* istanbul ignore file */
/* eslint-disable */
export const environment: {
kushkiUrl: string;
rollbarToken: string;
kushkiUrlWS: string;
envName: string;
devTools: boolean;
} = {
kushkiUrl: "https://api.kushkipagos.com",
envName: "primary",
devTools: false,
rollbarToken: "a330e8c1a7ed46dba19fc293de93eec4",
kushkiUrlWS:""
};
