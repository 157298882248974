import React, { useEffect } from "react";
import {
  Button,
  Grid,
  Link,
  Typography,
  Theme,
  useTheme,
} from "@material-ui/core";
import { HeaderMenu } from "../commons/HeaderMenu/HeaderMenu";
import { routes } from "../../shared/infrastructure/constants/routes";
import Breadcrumb, { BreadcrumItem } from "../Breadcrumb/Breadcrumb";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import { useStyles } from "./PageHeaderStyle";
import { IModalConfig } from "../../containers/ProcessorIndex/state/useProcessorIndexState";
import ModalConfig from "../ModalConfig/ModalConfig";
import { defaultTo } from "lodash";
import { MerchantProperties } from "../../../types/merchants";
import { useProcessorComponentsState } from "../../shared/hooks/ProcessorComponents/useProcessorComponentsState";
import { getAppConfigPaymentMethods } from "../../store/actionCreators";
import { useDispatch } from "react-redux";
import { LabelEnum } from "../../shared/infrastructure/constants/ProcessorsInformationConstant";

export interface IPageHeader {
  title: string;
  merchantId: string;
  country: string;
  modalConfig: IModalConfig;
  origin: string;
  merchantInfo: MerchantProperties;
  renderPayoutsCard: boolean;
}

const styles = (theme: Theme) => ({
  configurationButton: {
    border: "1px solid #CBD5E0",
    borderRadius: "4px",
    padding: "5px 25px",
    textDecoration: "none",
    color: "#293036",
    fontWeight: 500,
    fontSize: "14px",
    height: "40px",

    [theme.breakpoints.up("md")]: {
      marginRight: "4px",
      marginLeft: "4px",
    },
  },
});

export const PageHeader: React.FC<IPageHeader> = ({
  merchantId,
  origin,
  title,
  modalConfig,
  country,
  merchantInfo,
  renderPayoutsCard,
}: IPageHeader) => {
  const {
    backButtonText,
    merchantRoute,
    isAddButton,
    isConfigButton,
    isBackButton,
    isBreadcrumb,
  } = useProcessorComponentsState();

  const inlineStyles = styles(useTheme());
  const classes = useStyles();
  const pendingMerchantStatus =
    merchantInfo._source.status === "pending" || origin === "createMerchant";

  const itemsCreateMerchant: BreadcrumItem[] = [
    { label: "Inicio", url: routes.DASHBOARD },
    {
      label: "Crear comercio",
      url: merchantRoute,
    },
  ];
  const itemsConfigMerchant: BreadcrumItem[] = [
    { label: "Comercios", url: routes.MERCHANT_LIST },
    {
      label: merchantInfo._source.name,
      url: `${routes.INDEX}/merchant/${merchantId}`,
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAppConfigPaymentMethods());
  }, []);

  return (
    <React.Fragment>
      {isBreadcrumb && (
        <Breadcrumb
          items={
            pendingMerchantStatus ? itemsCreateMerchant : itemsConfigMerchant
          }
          lastItem={"Procesadores"}
        />
      )}

      <Grid container className={classes.container}>
        <Grid item container sm={12} lg={5}>
          <Typography
            className={classes.typography}
            color={"primary"}
            variant={"h1"}
          >
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          container
          sm={12}
          lg={7}
          justify={"flex-end"}
          alignContent={"center"}
        >
          {(isBackButton || pendingMerchantStatus) && (
            <Link
              onClick={() => {
                window.location.href = defaultTo(merchantRoute, "");
              }}
              className={classes.backButton}
            >
              {backButtonText}
            </Link>
          )}
          {isConfigButton && !modalConfig.showConfigButton && (
            <>
              <Button
                onClick={() => {
                  modalConfig.setOpen(true);
                }}
                style={inlineStyles.configurationButton}
                endIcon={<SettingsOutlinedIcon />}
              >
                {LabelEnum.PROCESSOR_CONFIG}
              </Button>
              <ModalConfig
                config={modalConfig}
                country={country}
                renderPayoutsCard={renderPayoutsCard}
              />
            </>
          )}
          {isAddButton && (
            <HeaderMenu
              key={"header-menu"}
              id={merchantId}
              country={country}
              renderPayoutsCard={renderPayoutsCard}
            />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
