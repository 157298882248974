import { Box, Button, Grid } from "@mui/material";
import React, { FC } from "react";
import { buttonStyles, footerStyles } from "./FooterOptions.styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { IFooterOptions } from "./FooterOptions.interfaces";
import {
  CONTINUE_TEXT,
  DEFAULT_IS_DISABLE_BUTTON,
  DEFAULT_IS_HIDDEN_BUTTON,
  RETURN_TEXT,
} from "../../shared/constants/labels/footerOptionsConstants";
import { get } from "lodash";

const FooterOptions: FC<IFooterOptions> = ({
  primaryButton = {
    isDisabled: DEFAULT_IS_DISABLE_BUTTON,
    isHidden: DEFAULT_IS_HIDDEN_BUTTON,
  },
  secondaryButton = {
    isDisabled: DEFAULT_IS_DISABLE_BUTTON,
    isHidden: DEFAULT_IS_HIDDEN_BUTTON,
  },
}: IFooterOptions) => {
  const colSize: number = get(
    buttonStyles(secondaryButton.isHidden!),
    "columnSize.columns",
    12
  );

  return (
    <Box sx={footerStyles.footer}>
      <Grid container spacing={0}>
        <Grid item xs={colSize} lg={colSize}>
          <Box>
            <Button
              data-testid="idButtonTest"
              variant="outlined"
              sx={buttonStyles(secondaryButton.isHidden!).returnButton}
              disabled={secondaryButton.isDisabled}
              onClick={secondaryButton.onAction!}
            >
              {RETURN_TEXT}
            </Button>

            <Button
              data-testid="idButtonContinue"
              variant="contained"
              disabled={primaryButton.isDisabled}
              onClick={primaryButton.onAction!}
              sx={buttonStyles(primaryButton.isHidden!).continueButton}
              endIcon={!secondaryButton.isHidden && <ArrowForwardIcon />}
            >
              {CONTINUE_TEXT}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FooterOptions;
