import { createNamedStyles } from "../../shared/interfaces/create_named_styles";
import { theme } from "@kushki/connect-ui";

export const usersContainerStyles = createNamedStyles({
  container: { paddingTop: "48px" },
  floatRight: { float: "right" },
  pagination: {
    "&#pagination-users .MuiToolbar-root.MuiToolbar-gutters": {
      display: "grid",
      gridTemplateColumns: {
        sm: "100px repeat(2,40px)",
        md: "130px 80px repeat(5,1fr)",
      },
    },
    "&#pagination-users .MuiToolbar-root.MuiToolbar-gutters .MuiTablePagination-spacer":
      {
        display: "none",
      },
    "&#pagination-users .MuiToolbar-root.MuiToolbar-gutters .MuiTablePagination-selectLabel":
      {
        fontSize: "13px",
      },
    "&#pagination-users .MuiToolbar-root.MuiToolbar-gutters .MuiInputBase-root":
      {
        justifySelf: "self-start",
        margin: "0px",
        fontSize: "13px",
      },
    "&#pagination-users .MuiToolbar-root.MuiToolbar-gutters .MuiTablePagination-displayedRows":
      {
        fontSize: "13px",
        display: "none",
      },
    "&#pagination-users .MuiToolbar-root.MuiToolbar-gutters .MuiTablePagination-actions":
      {
        gridColumn: "7 / 8",
        justifySelf: "self-end",
        display: "flex",
        columnGap: "8px",
        paddingRight: "24px",
      },
    "&#pagination-users .MuiToolbar-root.MuiToolbar-gutters .MuiTablePagination-actions button":
      {
        boxShadow:
          "0px 8px 17px rgba(106, 167, 197, 0.15), 0px 1.6px 2.7625px rgba(106, 167, 197, 0.075)",
        borderRadius: "4px",
        backgroundColor: "#ffffff",
        width: "32px",
        height: "32px",
      },
  },
  backBtnNewConsole: {
    color: "#4498EE",
    textTransform: "capitalize",
    margin: "20px 0 15px 0",
    fontSize: "16px !important",
  },
  searchInput: {
    width: "400px",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    margin: "0px",
  },
  filterContainer: {
    marginBottom: "20px",
    marginTop: "5px",
  },
  actionsMassive: {
    width: "100%",
  },
  tableContainer: {
    overflowX: "scroll",
  },
  filter: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
});

export const errorStyle = { color: theme.palette.error.main, display: "block" };
