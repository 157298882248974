import { Box } from "@mui/material";
import { Alert } from "@kushki/connect-ui";
import {
  DEFAULT_DESCRIPTION,
  DEFAULT_TEXT_BUTTON,
  DEFAULT_TITLE,
} from "../../shared/constants/labels/UserConfigurationConstants";
import { UserConfigurationStyles } from "./UserConfiguration.styles";
import React from "react";
import { MissingInfoAlertEnum } from "../MissingInfoAlert/MissingInfoAlert";

export interface UserConfigurationProps {
  isDisabled: boolean;
  handleActionUsersBtn: () => void;
}

const UserConfiguration: React.FC<UserConfigurationProps> = ({
  isDisabled,
  handleActionUsersBtn,
}) => (
  <>
    {isDisabled && (
      <Alert
        buttonText=""
        description=""
        title={MissingInfoAlertEnum.MISSING_USER}
        type="warning"
      />
    )}
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      mt={1}
      width={"100%"}
      sx={isDisabled ? UserConfigurationStyles.btnDisabled : {}}
    >
      <Alert
        buttonText={DEFAULT_TEXT_BUTTON}
        description={DEFAULT_DESCRIPTION}
        onButtonClick={handleActionUsersBtn}
        title={DEFAULT_TITLE}
        type="informative"
      />
    </Box>
  </>
);

export default UserConfiguration;
