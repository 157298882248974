import { WritableDraft } from "immer/dist/internal";
import { IAppState } from "../interfaces/AppState.interfaces";
import { DEFAULT_LOADING_MODAL } from "../../shared/utils/initialStates";
import { set } from "lodash";

export const dismissLoader = (state: WritableDraft<IAppState>) => {
  state.pendingRequest -= 1;
  if (state.pendingRequest <= 0) {
    state.loadingModal = DEFAULT_LOADING_MODAL;
  }
};

export const showLoader = (state: WritableDraft<IAppState>) => {
  set(state, "loadingModal.isLoading", true);
  state.pendingRequest += 1;
};
