import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { processTransactions } from "../../../store/actionCreators";
import { IAppState } from "../../../store/reducer";
import { camelCase, get, sumBy } from "lodash";
import { ActionModalContentEnum } from "../../../shared/infrastructure/ActionModalContentEnum";
import { StateEnum } from "../../../shared/infrastructure/StateEnum";
import { UpdateTransactionRequest } from "../../../../types/process_transaction_request";
import { ActionEnum } from "../../../shared/infrastructure/interfaces/ActionEnum";
import {
  IActionModalProps,
  IUser,
} from "../../common/ConcilationModal/state/useConciliationState";
import { TransactionData } from "../../../../types/transaction_data";
import { ConfirmModalProps } from "../OmittedTransactionModal";
import { OriginEnum } from "../../../shared/infrastructure/OriginEnum";
import { auth } from "../../../shared/auth";

export interface IConciliationState {
  openAlertModal: boolean;
  submit: () => void;
  handleCloseAlertModal: () => void;
  handleAlertModalSubmit: () => void;
  actionModalProps: IActionModalProps;
  setComment: (message: string) => void;
  count: number;
  totalCheck: number;
  message: string;
}

export const useOmittedTransactionModal = ({
  trxSelectedCheck,
  handleClose,
  conciliation,
}: ConfirmModalProps): IConciliationState => {
  const dispatch = useDispatch();

  const store = useSelector((state: IAppState) => state);
  const [message, setMessage] = React.useState<string>("");
  const count: number = message.length;
  const totalCheck: number = sumBy(
    trxSelectedCheck,
    (item: TransactionData) => get(item, "net_amount", 0)!
  );

  const setComment = (message: string) => {
    setMessage(message);
  };

  const decodedJWT = auth.getJwtAuth()
    ? JSON.parse(atob(auth.getJwtAuth().split(".")[1]))
    : {};

  const user: IUser = {
    userName: get(decodedJWT, "username"),
    userId: get(decodedJWT, "client_id"),
    userRole: get(decodedJWT, "cognito:groups", "").toString(),
  };

  const convertToCamelCase = (transaction: object): TransactionData => {
    return Object.entries(transaction).reduce((acc, curr) => {
      const keyInCamel = camelCase(curr[0]);
      acc[keyInCamel] = curr[1];
      return acc;
    }, {});
  };

  const buildData = (item: any) => {
    return {
      id: get(item, "id", ""),
      conciliationCreated: get(item, "conciliationCreated"),
      country: get(item, "country"),
      paymentMethod: get(item, "paymentMethod"),
      processorName: get(item, "processorName"),
      currencyCode: get(item, "currencyCode"),
      netAmountTotal: get(item, "netAmountTotal"),
      bankConciliationStatus: get(item, "bankConciliationStatus"),
      comment: get(item, "comment"),
      liquidationAmount: get(item, "liquidationAmount"),
      path: get(item, "path"),
      fileName: get(item, "fileName"),
      fileSize: get(item, "fileSize"),
      userId: get(item, "userId"),
      userName: get(item, "userName"),
      userRole: get(item, "userRole"),
      actionStatus: get(item, "actionStatus"),
      source: get(item, "source"),
      created: get(item, "created"),
      updatedAt: get(item, "updatedAt"),
      deletedAt: get(item, "deletedAt"),
    };
  };

  const requestObject = {
    state: StateEnum.OMIT,
    comment: message,
    source: "pending",
    path: store.conciliationFileKey!,
    userName: user.userName,
    userId: user.userId,
    userRole: user.userRole,
    ...(conciliation === OriginEnum.SUMMARY && {
      liquidationAmount: 0,
    }),
    data: get(store.modalOmit, "transactions", [])
      .map((item: object) =>
        conciliation === OriginEnum.SUMMARY
          ? buildData(convertToCamelCase(item))
          : convertToCamelCase(item)
      )
      .map((item: any) => ({
        ...item,
        created: new Date(item.created || 0).getTime(),
        conciliationCreated: new Date(
          get(item, "conciliationCreated", 0)
        ).getTime(),
        ...(conciliation === OriginEnum.TRANSACTIONS
          ? {
              bankConciliationDate: new Date(
                get(item, "bankConciliationDate", 0)
              ).getTime(),
              transactionCreated: new Date(
                get(item, "transactionCreated", 0)
              ).getTime(),
            }
          : {}),
      })),
  };

  const [openAlertModal, setOpenAlertModal] = React.useState(false);
  const [actionModalProps, setActionModalProps] = useState<IActionModalProps>({
    title: "",
    buttonLbl: "",
  });

  const handleAlertModalSubmit = () => {
    const isMassive: boolean = get(store.modalOmit, "omitMasive", false);
    const requestObj: UpdateTransactionRequest = {
      action:
        conciliation === OriginEnum.TRANSACTIONS
          ? ActionEnum.PROCESS_TRANSACTIONS
          : ActionEnum.PROCESS_SUMMARY_TRANSACTIONS,
      data: requestObject,
    };
    dispatch(processTransactions(requestObj, isMassive, conciliation));
    setOpenAlertModal(false);
  };

  const handleCloseAlertModal = () => {
    setOpenAlertModal(false);
  };

  const submit = () => {
    setActionModalProps({
      ...actionModalProps,
      title:
        conciliation === OriginEnum.SUMMARY
          ? ActionModalContentEnum.depositOmitTitle
          : store.modalOmit?.omitMasive
          ? ActionModalContentEnum.omitMassiveTittle
          : ActionModalContentEnum.omitTittle,
      buttonLbl: store.modalOmit?.omitMasive
        ? ActionModalContentEnum.confirmButton
        : ActionModalContentEnum.omitButton,
    });
    setOpenAlertModal(!openAlertModal);
    handleClose();
  };

  return {
    handleCloseAlertModal,
    handleAlertModalSubmit,
    openAlertModal,
    submit,
    actionModalProps,
    setComment,
    count,
    totalCheck,
    message,
  };
};
