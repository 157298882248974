export enum CurrencyEnum {
  COP = "COP",
  USD = "USD",
  PEN = "PEN",
  CLP = "CLP",
  MXN = "MXN",
  BRL = "BRL",
  PAB = "PAB",
  CRC = "CRC",
}
