import React from "react";
import { Grid } from "@material-ui/core";
import { CardContainer } from "../CardContainer/CardContainer";
import { CredentialsGrid } from "../DevelopersGrid/CredentialsGrid/CredentialsGrid";
import { WebhooksGrid } from "../DevelopersGrid/WebhooksGrid/WebhooksGrid";
import { useDeveloperSection } from "./state/useDeveloperSection";
import {
  DevelopersCredentialSectionConstants,
  DevelopersWebhookSectionConstants,
} from "../../shared/infrastructure/constants/DeveloperConstants";
import { routes } from "../../shared/infrastructure/constants/routes";
import { TitleSubtitle } from "../../containers/TitleSubtitleBox.tsx/TitleSubtitleBox";

export const DeveloperSection = () => {
  const {
    merchantId,
    handleHrefPaymentCredentials,
    handleHrefWebhook,
  } = useDeveloperSection();

  return (
    <Grid container direction={"row"} spacing={3}>
      <Grid item xs={6}>
        <CardContainer
          buttonCreate={true}
          headerCardTitle={DevelopersCredentialSectionConstants.TITLE}
          headerButtonTitle={DevelopersCredentialSectionConstants.BUTTON_TITLE}
          footerCardHrefLocation={`${routes.MERCHANT_CREDENTIALS}/${merchantId}`}
          footerTitleHrefLocation={
            DevelopersCredentialSectionConstants.FOOTER_TITLE
          }
          headerButtonOnClick={handleHrefPaymentCredentials}
          childrenArrayCardComponent={[
            <CredentialsGrid key={DevelopersCredentialSectionConstants.KEY} />,
          ]}
        />
      </Grid>
      <Grid item xs={6}>
        <CardContainer
          buttonCreate={true}
          headerCardTitle={DevelopersWebhookSectionConstants.TITLE}
          headerButtonTitle={DevelopersWebhookSectionConstants.BUTTON_TITLE}
          footerCardHrefLocation={`${routes.WEBHOOKS}/${merchantId}`}
          footerTitleHrefLocation={
            DevelopersWebhookSectionConstants.FOOTER_TITLE
          }
          headerButtonOnClick={handleHrefWebhook}
          childrenArrayCardComponent={[
            <WebhooksGrid key={DevelopersWebhookSectionConstants.KEY} />,
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <TitleSubtitle
          key={"notificationWebhook"}
          title={"Notificaciones de webhooks"}
          subtitle={
            "Haz seguimiento de tus URLs de notificaciones de tus webhooks y los datos de envío y respuesta."
          }
          IndexHref={`${routes.NOTIFICATIONS}/${merchantId}`}
        />
      </Grid>
    </Grid>
  );
};
