import React from "react";
import { ProcessorFetch } from "../../../shared/infrastructure/interfaces/ProcessorFetch";
import { ModalBody } from "@kushki/frontend-molecules/modal-body";
import { Paper, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import {
  formatDate,
  formatModel,
  formatPaymentMethod,
} from "../../TableComponent/TableComponent";
import { get } from "lodash";
import { PaymentMethodEnum } from "../../../shared/infrastructure/constants/PaymentMethodEnum";
import {
  getTranslatedProcessor,
  ProcessorsEnum,
} from "../../../shared/infrastructure/constants/ProcessorsEnum";
import { ProcessorName } from "../../../shared/infrastructure/constants/ProcessorNameEnum";

export interface IProcessorBodyInfo {
  processorInfo?: ProcessorFetch;
  isCountryIncluded?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      boxShadow: "none !important",
      backgroundColor: "#F7FAFC !important",
    },
    typography: {
      color: "#293036",
      fontSize: "14px",
    },
  })
);

const formatCategory = (category: string): string => {
  switch (category) {
    case "COLLECTION":
      return "Recaudo directo";
    case "GATEWAY":
      return "Pasarela";
    default:
      return "Formal";
  }
};

export const ModalBodyInfo = (props: IProcessorBodyInfo) => {
  const classes = useStyles();
  return (
    <ModalBody>
      <Grid container spacing={2} color="secondary">
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="body2" color="textSecondary">
                  Método de pago:
                </Typography>
                <Typography variant="body2">
                  {
                    formatPaymentMethod(
                      get(props.processorInfo, "paymentMethod", "-")
                    ).type
                  }
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body2" color="textSecondary">
                  Procesador:
                </Typography>
                <Typography variant="body2">
                  {getTranslatedProcessor(
                    get(props.processorInfo, "processorName", "")
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="body2" color="textSecondary">
                  Modelo:
                </Typography>
                <Typography variant="body2">
                  {formatModel(get(props.processorInfo, "processorType", "-"))}
                </Typography>
              </Grid>
              {get(props.processorInfo, "processorType", "") !== "GATEWAY" ? (
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" color="textSecondary">
                    Categoría:
                  </Typography>
                  <Typography variant="body2">
                    {formatCategory(
                      get(props.processorInfo, "categoryModel", "")
                    )}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
            {get(props.processorInfo, "processorName") ===
              ProcessorName.KushkiTransfer && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Typography variant="body2" color="textSecondary">
                    Entidades financieras disponibles para operar:
                  </Typography>
                  <Typography variant="body2">
                    {get(props.processorInfo, "bankList", []).join(",")}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="body2" color="textSecondary">
                  Fecha de creación:
                </Typography>
                <Typography variant="body2">
                  {props.processorInfo?.created!
                    ? formatDate(
                        Number(props.processorInfo?.created!),
                        "dd/MM/yyyy"
                      )
                    : ""}
                </Typography>
              </Grid>
              {get(props, "processorInfo.updatedAt", 0) != 0 ? (
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" color="textSecondary">
                    Fecha de actualización:
                  </Typography>
                  <Typography variant="body2">
                    {props.processorInfo?.created!
                      ? formatDate(
                          Number(get(props, "processorInfo.updatedAt", 0)),
                          "dd/MM/yyyy"
                        )
                      : ""}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </Paper>
        </Grid>
        {get(props.processorInfo, "paymentMethod", "") ===
          PaymentMethodEnum.CASH ||
        get(props.processorInfo, "paymentMethod", "") ===
          PaymentMethodEnum.PAYOUTS_CASH ||
        (get(props.processorInfo, "paymentMethod", "") ===
          PaymentMethodEnum.CARD &&
          props.isCountryIncluded) ? (
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} spacing={2}>
                  <Typography variant="body2" color="textSecondary">
                    Alias:
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} spacing={2}>
                  <Typography variant="body2">
                    {get(
                      props.processorInfo,
                      "alias",
                      get(props.processorInfo, "processorName", "")
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} spacing={2}>
                  <Typography variant="body2" color="textSecondary">
                    ID del Comercio:
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} spacing={2}>
                  <Typography variant="body2">
                    {props.processorInfo?.merchantId}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ) : null}

        {get(props.processorInfo, "paymentMethod", "") ===
          PaymentMethodEnum.CARD && !props.isCountryIncluded ? (
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} spacing={2}>
                  <Typography variant="body2" color="textSecondary">
                    Fail Over:
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} spacing={2}>
                  <Typography variant="body2">
                    {get(props.processorInfo, "failOver", "NINGUNO")}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            {get(props.processorInfo, "processorName") ===
              ProcessorsEnum.NIUBIZ ||
            get(props.processorInfo, "processorName") ===
              ProcessorsEnum.VISANET ? (
              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} spacing={2}>
                    <Typography variant="body2" color="textSecondary">
                      Codigo Patrocinador:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} spacing={2}>
                    <Typography variant="body2">
                      {get(props.processorInfo, "processorCode", "NINGUNO")}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            ) : null}
          </Grid>
        ) : null}

        {get(props.processorInfo, "paymentMethod", "") ===
          PaymentMethodEnum.TRANSFER_SUBSCRIPTION ||
        get(props.processorInfo, "paymentMethod", "") ===
          PaymentMethodEnum.TRANSFER ||
        get(props.processorInfo, "paymentMethod", "") ===
          PaymentMethodEnum.PAYOUTS_TRANSFER ? (
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} spacing={2}>
                  <Typography variant="body2" color="textSecondary">
                    Alias:
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} spacing={2}>
                  <Typography variant="body2">
                    {get(props.processorInfo, "alias", "")}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ) : null}
      </Grid>
    </ModalBody>
  );
};
