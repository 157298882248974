import { get } from "lodash";
import { IUseMerchantCompanyInfo } from "../hooks/merchantCompanyInfo/useMerchantCompanyInfo.interfaces";
import { useMerchantCompanyInfo } from "../hooks/merchantCompanyInfo/useMerchantCompanyInfo";
import { useLocation } from "react-router";
import { SessionStoragePaths } from "../constants/Session_storage_paths";
import { BranchEditItem } from "../../../types/branch_edit_item";

export interface ResponseWSUpdateMassiveBranch {
  error: boolean;
  errorMessage: string;
  publicMerchantId: string;
}

export const objectMerchantCompanyInfo = (
  massive?: boolean
): IUseMerchantCompanyInfo => {
  return useMerchantCompanyInfo(massive);
};

export const getQueryParams = (parameter: string): string => {
  const query = new URLSearchParams(useLocation().search);

  return query.get(parameter) as string;
};
export const getBranchEditListFromSessionStorage = (item: string) => {
  return JSON.parse(sessionStorage.getItem(item) || "[]");
};
export const getListSessionStorage = (): any => {
  const branchEditList = getBranchEditListFromSessionStorage("branchEditList");

  return get(branchEditList, "data", [])
    .filter((branch: any) => branch.edit)
    .map((branch: any) => branch.public_merchant_id);
};

export function checkIsUpdated(arr: string[], val: string) {
  return arr.some((arrVal: string) => val === arrVal);
}

export const verifyErrorInBranch = (
  updatedBranches: ResponseWSUpdateMassiveBranch[]
): boolean => {
  const correctUpdatedBranches: ResponseWSUpdateMassiveBranch[] =
    updatedBranches.filter((updatedBranch) => updatedBranch.error);

  return correctUpdatedBranches.length > 0;
};

export const updateEditStatusInBranchEditList = (
  updatedBranches: ResponseWSUpdateMassiveBranch[]
) => {
  const correctUpdatedBranches = updatedBranches
    .filter((updatedBranch) => !updatedBranch.error)
    .map((updatedBranch) => updatedBranch.publicMerchantId);

  const branchEditList = getBranchEditListFromSessionStorage("branchEditList");

  const updatedEditList = get(branchEditList, "data", []).map((branch: any) => {
    if (checkIsUpdated(correctUpdatedBranches, branch.merchant_id!)) {
      branch.editStatus = "complete";
    }

    return branch;
  });
  const test = { data: updatedEditList, total: updatedEditList.length };

  sessionStorage.setItem("branchEditList", JSON.stringify(test));
};

export const redirectPath = (path: string) => {
  window.location.href = `${path}`;
};

const checkIsModified = (values: string[], valueToValidate: string) =>
  values.some((value: string) => valueToValidate === value);

export const setIsModifyByBranch = (branchesForUpdate: string[]) => {
  const branchEditList = getBranchEditListFromSessionStorage(
    SessionStoragePaths.BRANCH_EDIT_LIST
  );

  const branchEditListWithModify = get(branchEditList, "data", []).map(
    (branchItem: BranchEditItem) => ({
      ...branchItem,
      isModify:
        branchItem.isModify ||
        checkIsModified(branchesForUpdate, branchItem.merchant_id!),
    })
  );

  sessionStorage.setItem(
    SessionStoragePaths.BRANCH_EDIT_LIST,
    JSON.stringify({
      data: branchEditListWithModify,
      total: branchEditListWithModify.length,
    })
  );
};
