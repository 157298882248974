/* istanbul ignore file */

import { IOptionSelection } from "./RuleRequestManagerConstants";

export const BANKS_CHILE: IOptionSelection[] = [
  { value: "970320008", label: "Banco BBVA" },
  { value: "970040005", label: "Banco de Chile" },
  { value: "970060006", label: "Banco BCI" },
  { value: "970040005", label: "Banco Edwards" },
  { value: "970510001", label: "Banco Desarrollo" },
  { value: "970300007", label: "Banco Estado" },
  { value: "965096604", label: "Banco Falabella" },
  { value: "76645030K", label: "Banco Itaú" },
  { value: "970110003", label: "Banco Internacional" },
  { value: "979470002", label: "Banco Ripley" },
  { value: "97036000K", label: "Banco Santander-Santiago" },
  { value: "970530002", label: "Banco Security" },
  { value: "97080000K", label: "Banco Bice" },
  { value: "995004100", label: "Banco Consorcio" },
  { value: "828789007", label: "Banco Coopeuch" },
  { value: "970230009", label: "Corpbanca" },
  { value: "979510004", label: "Banco HSBC" },
  { value: "970180001", label: "Scotiabank" },
];
