/**
 * Catalog CostRica
 */
import { Category } from "../constants/MerchantInformationConstants";
import { CatalogsEnum } from "../infrastructure/catalogs-enum";
import { CurrencyEnum } from "../infrastructure/currency-enum";

export const CatalogCr: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { name: "CI", value: "0" },
    { name: "NITE", value: "1" },
  ],
  [CatalogsEnum.Banks]: [
    { name: "BANCO DE COSTA RICA", value: "11100006" },
    { name: "BANCO BAC SAN JOSE", value: "11100001" },
    { name: "BANCO BCT S.A.", value: "11100002" },
    { name: "BANCO CATHAY DE COSTA RICA S.A.", value: "11100003" },
    { name: "BANCO CENTRAL DE COSTA RICA", value: "11100004" },
    { name: "BANCO CITIBANK DE COSTA RICA S.A.", value: "11100005" },
    { name: "BANCO GENERAL DE COSTA RICA S.A.", value: "11100007" },
    { name: "BANCO HSBC DE COSTA RICA S.A.", value: "11100008" },
    { name: "BANCO IMPROSA S.A.", value: "11100009" },
    { name: "BANCO LAFISE S.A.", value: "11100010" },
    { name: "BANCO NACIONAL DE COSTA RICA", value: "11100011" },
    { name: "BANCO PROMERICA", value: "11100012" },
    { name: "SCOTIABANK DE COSTA RICA", value: "11100013" },
    { name: "COSTA RICA", value: "11100014" },
    { name: "BANCO INTERFIN DE COSTA RICA", value: "11100015" },
    { name: "BANCO INTERNACIONAL DE COSTA RICA", value: "11100016" },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { name: "Corriente", value: "0" },
    { name: "Ahorros", value: "1" },
  ],
  [CatalogsEnum.Currency]: [
    { name: "Colones", value: CurrencyEnum.CRC },
    { name: "Dólares", value: CurrencyEnum.USD },
  ],

  [CatalogsEnum.Departments]: [
    { name: "San José", value: "1" },
    { name: "Alajuela", value: "2" },
    { name: "Cartago", value: "3" },
    { name: "Heredia", value: "4" },
    { name: "Guanacaste", value: "5" },
    { name: "Puntarenas", value: "6" },
    { name: "Limón", value: "7" },
  ],
  [CatalogsEnum.Provinces]: [
    { name: "San José", parent: "1", value: "101" },
    { name: "Escazú", parent: "1", value: "102" },
    { name: "Desamparados", parent: "1", value: "103" },
    { name: "Puriscal", parent: "1", value: "104" },
    { name: "Tarrazú", parent: "1", value: "105" },
    { name: "Aserrí", parent: "1", value: "106" },
    { name: "Mora", parent: "1", value: "107" },
    { name: "Goicoechea", parent: "1", value: "108" },
    { name: "Santa Ana", parent: "1", value: "109" },
    { name: "Alajuelita", parent: "1", value: "110" },
    { name: "Vázquez de Coronado", parent: "1", value: "111" },
    { name: "Acosta", parent: "1", value: "112" },
    { name: "Tibás", parent: "1", value: "113" },
    { name: "Moravia", parent: "1", value: "114" },
    { name: "Montes de Oca", parent: "1", value: "115" },
    { name: "Turrubares", parent: "1", value: "116" },
    { name: "Dota", parent: "1", value: "117" },
    { name: "Curridabat", parent: "1", value: "118" },
    { name: "Pérez Zeledón", parent: "1", value: "119" },
    { name: "León Cortés Castro", parent: "1", value: "120" },
    { name: "Alajuela", parent: "2", value: "201" },
    { name: "San Ramón", parent: "2", value: "202" },
    { name: "Grecia", parent: "2", value: "203" },
    { name: "San Mateo", parent: "2", value: "204" },
    { name: "Atenas", parent: "2", value: "205" },
    { name: "Naranjo", parent: "2", value: "206" },
    { name: "Palmares", parent: "2", value: "207" },
    { name: "Poás", parent: "2", value: "208" },
    { name: "Orotina", parent: "2", value: "209" },
    { name: "San Carlos", parent: "2", value: "210" },
    { name: "Zarcero", parent: "2", value: "211" },
    { name: "Sarchí", parent: "2", value: "212" },
    { name: "Upala", parent: "2", value: "213" },
    { name: "Los Chiles", parent: "2", value: "214" },
    { name: "Guatuso", parent: "2", value: "215" },
    { name: "Río Cuarto", parent: "2", value: "216" },
    { name: "Cartago", parent: "3", value: "301" },
    { name: "Paraíso", parent: "3", value: "302" },
    { name: "La Unión", parent: "3", value: "303" },
    { name: "Jiménez", parent: "3", value: "304" },
    { name: "Turrialba", parent: "3", value: "305" },
    { name: "Alvarado", parent: "3", value: "306" },
    { name: "Oreamuno", parent: "3", value: "307" },
    { name: "El Guarco", parent: "3", value: "308" },
    { name: "Heredia", parent: "4", value: "401" },
    { name: "Barva", parent: "4", value: "402" },
    { name: "Santo Domingo", parent: "4", value: "403" },
    { name: "Santa Bárbara", parent: "4", value: "404" },
    { name: "San Rafael", parent: "4", value: "405" },
    { name: "San Isidro", parent: "4", value: "406" },
    { name: "Belén", parent: "4", value: "407" },
    { name: "Flores", parent: "4", value: "408" },
    { name: "San Pablo", parent: "4", value: "409" },
    { name: "Sarapiquí", parent: "4", value: "410" },
    { name: "Liberia", parent: "5", value: "501" },
    { name: "Nicoya", parent: "5", value: "502" },
    { name: "Santa Cruz", parent: "5", value: "503" },
    { name: "Bagaces", parent: "5", value: "504" },
    { name: "Carrillo", parent: "5", value: "505" },
    { name: "Cañas", parent: "5", value: "506" },
    { name: "Abangares", parent: "5", value: "507" },
    { name: "Tilarán", parent: "5", value: "508" },
    { name: "Nandayure", parent: "5", value: "509" },
    { name: "La Cruz", parent: "5", value: "510" },
    { name: "Hojancha", parent: "5", value: "511" },
    { name: "Puntarenas", parent: "6", value: "601" },
    { name: "Esparza", parent: "6", value: "602" },
    { name: "Buenos Aires", parent: "6", value: "603" },
    { name: "Montes de Oro", parent: "6", value: "604" },
    { name: "Osa", parent: "6", value: "605" },
    { name: "Quepos", parent: "6", value: "606" },
    { name: "Golfito", parent: "6", value: "607" },
    { name: "Coto Brus", parent: "6", value: "608" },
    { name: "Parrita", parent: "6", value: "609" },
    { name: "Corredores", parent: "6", value: "610" },
    { name: "Garabito", parent: "6", value: "611" },
    { name: "Limón", parent: "7", value: "701" },
    { name: "Pococí", parent: "7", value: "702" },
    { name: "Siquirres", parent: "7", value: "703" },
    { name: "Talamanca", parent: "7", value: "704" },
    { name: "Matina", parent: "7", value: "705" },
    { name: "Guácimo", parent: "7", value: "706" },
  ],

  [CatalogsEnum.Cities]: [
    { name: "Carmen", parent: "101", value: "10101" },
    { name: "Merced", parent: "101", value: "10102" },
    { name: "Hospital", parent: "101", value: "10103" },
    { name: "Catedral", parent: "101", value: "10104" },
    { name: "Zapote", parent: "101", value: "10105" },
    { name: "San Francisco de Dos Ríos", parent: "101", value: "10106" },
    { name: "La Uruca", parent: "101", value: "10107" },
    { name: "Mata Redonda", parent: "101", value: "10108" },
    { name: "Pavas", parent: "101", value: "10109" },
    { name: "Hatillo", parent: "101", value: "10110" },
    { name: "San Sebastián", parent: "101", value: "10111" },
    { name: "Escazú", parent: "102", value: "10201" },
    { name: "San Antonio", parent: "102", value: "10202" },
    { name: "San Rafael", parent: "102", value: "10203" },
    { name: "Desamparados", parent: "103", value: "10301" },
    { name: "San Miguel", parent: "103", value: "10302" },
    { name: "San Juan de Dios", parent: "103", value: "10303" },
    { name: "San Rafael Arriba", parent: "103", value: "10304" },
    { name: "San Antonio", parent: "103", value: "10305" },
    { name: "Frailes", parent: "103", value: "10306" },
    { name: "Patarrá", parent: "103", value: "10307" },
    { name: "San Cristóbal", parent: "103", value: "10308" },
    { name: "Rosario", parent: "103", value: "10309" },
    { name: "Damas", parent: "103", value: "10310" },
    { name: "San Rafael Abajo", parent: "103", value: "10311" },
    { name: "Gravilias", parent: "103", value: "10312" },
    { name: "Los Guido", parent: "103", value: "10313" },
    { name: "Santiago", parent: "104", value: "10401" },
    { name: "Mercedes Sur", parent: "104", value: "10402" },
    { name: "Barbacoas", parent: "104", value: "10403" },
    { name: "Grifo Alto", parent: "104", value: "10404" },
    { name: "San Rafael", parent: "104", value: "10405" },
    { name: "Candelarita", parent: "104", value: "10406" },
    { name: "Desamparaditos", parent: "104", value: "10407" },
    { name: "San Antonio", parent: "104", value: "10408" },
    { name: "Chires", parent: "104", value: "10409" },
    { name: "San Marcos", parent: "105", value: "10501" },
    { name: "San Lorenzo", parent: "105", value: "10502" },
    { name: "San Carlos", parent: "105", value: "10503" },
    { name: "Aserrí", parent: "106", value: "10601" },
    { name: "Tarbaca", parent: "106", value: "10602" },
    { name: "Vuelta de Jorco", parent: "106", value: "10603" },
    { name: "San Gabriel", parent: "106", value: "10604" },
    { name: "Legua", parent: "106", value: "10605" },
    { name: "Monterrey", parent: "106", value: "10606" },
    { name: "Salitrillos", parent: "106", value: "10607" },
    { name: "Colón", parent: "107", value: "10701" },
    { name: "Guayabo", parent: "107", value: "10702" },
    { name: "Tabarcia", parent: "107", value: "10703" },
    { name: "Piedras Negras", parent: "107", value: "10704" },
    { name: "Picagres", parent: "107", value: "10705" },
    { name: "Jaris", parent: "107", value: "10706" },
    { name: "Quitirrisí", parent: "107", value: "10707" },
    { name: "Guadalupe", parent: "108", value: "10801" },
    { name: "San Francisco", parent: "108", value: "10802" },
    { name: "Calle Blancos", parent: "108", value: "10803" },
    { name: "Mata de Plátano", parent: "108", value: "10804" },
    { name: "Ipís", parent: "108", value: "10805" },
    { name: "Rancho Redondo", parent: "108", value: "10806" },
    { name: "Purral", parent: "108", value: "10807" },
    { name: "Santa Ana", parent: "109", value: "10901" },
    { name: "Salitral", parent: "109", value: "10902" },
    { name: "Pozos", parent: "109", value: "10903" },
    { name: "Uruca", parent: "109", value: "10904" },
    { name: "Piedades", parent: "109", value: "10905" },
    { name: "Brasil", parent: "109", value: "10906" },
    { name: "Alajuelita", parent: "110", value: "11001" },
    { name: "San Josecito", parent: "110", value: "11002" },
    { name: "San Antonio", parent: "110", value: "11003" },
    { name: "Concepción", parent: "110", value: "11004" },
    { name: "San Felipe", parent: "110", value: "11005" },
    { name: "San Isidro", parent: "111", value: "11101" },
    { name: "San Rafael", parent: "111", value: "11102" },
    { name: "Dulce Nombre de Jesús", parent: "111", value: "11103" },
    { name: "Patalillo", parent: "111", value: "11104" },
    { name: "Cascajal", parent: "111", value: "11105" },
    { name: "San Ignacio", parent: "112", value: "11201" },
    { name: "Guaitil", parent: "112", value: "11202" },
    { name: "Palmichal", parent: "112", value: "11203" },
    { name: "Cangrejal", parent: "112", value: "11204" },
    { name: "Sabanillas", parent: "112", value: "11205" },
    { name: "San Juan", parent: "113", value: "11301" },
    { name: "Cinco Esquinas", parent: "113", value: "11302" },
    { name: "Anselmo Llorente", parent: "113", value: "11303" },
    { name: "León XIII", parent: "113", value: "11304" },
    { name: "Colima", parent: "113", value: "11305" },
    { name: "San Vicente", parent: "114", value: "11401" },
    { name: "San Jerónimo", parent: "114", value: "11402" },
    { name: "La Trinidad", parent: "114", value: "11403" },
    { name: "San Pedro", parent: "115", value: "11501" },
    { name: "Sabanilla", parent: "115", value: "11502" },
    { name: "Mercedes", parent: "115", value: "11503" },
    { name: "San Rafael", parent: "115", value: "11504" },
    { name: "San Pablo", parent: "116", value: "11601" },
    { name: "San Pedro", parent: "116", value: "11602" },
    { name: "San Juan de Mata", parent: "116", value: "11603" },
    { name: "San Luis", parent: "116", value: "11604" },
    { name: "Carara", parent: "116", value: "11605" },
    { name: "Santa María", parent: "117", value: "11701" },
    { name: "Jardín", parent: "117", value: "11702" },
    { name: "Copey", parent: "117", value: "11703" },
    { name: "Curridabat", parent: "118", value: "11801" },
    { name: "Granadilla", parent: "118", value: "11802" },
    { name: "Sánchez", parent: "118", value: "11803" },
    { name: "Tirrases", parent: "118", value: "11804" },
    { name: "San Isidro de El General", parent: "119", value: "11901" },
    { name: "El General", parent: "119", value: "11902" },
    { name: "Daniel Flores", parent: "119", value: "11903" },
    { name: "Rivas", parent: "119", value: "11904" },
    { name: "San Pedro", parent: "119", value: "11905" },
    { name: "Platanares", parent: "119", value: "11906" },
    { name: "Pejibaye", parent: "119", value: "11907" },
    { name: "Cajón", parent: "119", value: "11908" },
    { name: "Barú", parent: "119", value: "11909" },
    { name: "Río Nuevo", parent: "119", value: "11910" },
    { name: "Páramo", parent: "119", value: "11911" },
    { name: "La Amistad", parent: "119", value: "11912" },
    { name: "San Pablo", parent: "120", value: "12001" },
    { name: "San Andrés", parent: "120", value: "12002" },
    { name: "Llano Bonito", parent: "120", value: "12003" },
    { name: "San Isidro", parent: "120", value: "12004" },
    { name: "Santa Cruz", parent: "120", value: "12005" },
    { name: "San Antonio", parent: "120", value: "12006" },
    { name: "Alajuela", parent: "201", value: "20101" },
    { name: "San José", parent: "201", value: "20102" },
    { name: "Carrizal", parent: "201", value: "20103" },
    { name: "San Antonio", parent: "201", value: "20104" },
    { name: "Guácima", parent: "201", value: "20105" },
    { name: "San Isidro", parent: "201", value: "20106" },
    { name: "Sabanilla", parent: "201", value: "20107" },
    { name: "San Rafael", parent: "201", value: "20108" },
    { name: "Río Segundo", parent: "201", value: "20109" },
    { name: "Desamparados", parent: "201", value: "20110" },
    { name: "Turrúcares", parent: "201", value: "20111" },
    { name: "Tambor", parent: "201", value: "20112" },
    { name: "Garita", parent: "201", value: "20113" },
    { name: "Sarapiquí", parent: "201", value: "20114" },
    { name: "San Ramón", parent: "202", value: "20201" },
    { name: "Santiago", parent: "202", value: "20202" },
    { name: "San Juan", parent: "202", value: "20203" },
    { name: "Piedades Norte", parent: "202", value: "20204" },
    { name: "Piedades Sur", parent: "202", value: "20205" },
    { name: "San Rafael", parent: "202", value: "20206" },
    { name: "San Isidro", parent: "202", value: "20207" },
    { name: "Ángeles", parent: "202", value: "20208" },
    { name: "Alfaro", parent: "202", value: "20209" },
    { name: "Volio", parent: "202", value: "20210" },
    { name: "Concepción", parent: "202", value: "20211" },
    { name: "Zapotal", parent: "202", value: "20212" },
    { name: "Peñas Blancas", parent: "202", value: "20213" },
    { name: "San Lorenzo", parent: "202", value: "20214" },
    { name: "Grecia", parent: "203", value: "20301" },
    { name: "San Isidro", parent: "203", value: "20302" },
    { name: "San José", parent: "203", value: "20303" },
    { name: "San Roque", parent: "203", value: "20304" },
    { name: "Tacares", parent: "203", value: "20305" },
    { name: "Puente de Piedra", parent: "203", value: "20307" },
    { name: "Bolívar", parent: "203", value: "20308" },
    { name: "San Mateo", parent: "204", value: "20401" },
    { name: "Desmonte", parent: "204", value: "20402" },
    { name: "Jesús María", parent: "204", value: "20403" },
    { name: "Labrador", parent: "204", value: "20404" },
    { name: "Atenas", parent: "205", value: "20501" },
    { name: "Jesús", parent: "205", value: "20502" },
    { name: "Mercedes", parent: "205", value: "20503" },
    { name: "San Isidro", parent: "205", value: "20504" },
    { name: "Concepción", parent: "205", value: "20505" },
    { name: "San José", parent: "205", value: "20506" },
    { name: "Santa Eulalia", parent: "205", value: "20507" },
    { name: "Escobal", parent: "205", value: "20508" },
    { name: "Naranjo", parent: "206", value: "20601" },
    { name: "San Miguel", parent: "206", value: "20602" },
    { name: "San José", parent: "206", value: "20603" },
    { name: "Cirrí Sur", parent: "206", value: "20604" },
    { name: "San Jerónimo", parent: "206", value: "20605" },
    { name: "San Juan", parent: "206", value: "20606" },
    { name: "El Rosario", parent: "206", value: "20607" },
    { name: "Palmitos", parent: "206", value: "20608" },
    { name: "Palmares", parent: "207", value: "20701" },
    { name: "Zaragoza", parent: "207", value: "20702" },
    { name: "Buenos Aires", parent: "207", value: "20703" },
    { name: "Santiago", parent: "207", value: "20704" },
    { name: "Candelaria", parent: "207", value: "20705" },
    { name: "Esquipulas", parent: "207", value: "20706" },
    { name: "La Granja", parent: "207", value: "20707" },
    { name: "San Pedro", parent: "208", value: "20801" },
    { name: "San Juan", parent: "208", value: "20802" },
    { name: "San Rafael", parent: "208", value: "20803" },
    { name: "Carrillos", parent: "208", value: "20804" },
    { name: "Sabana Redonda", parent: "208", value: "20805" },
    { name: "Orotina", parent: "209", value: "20901" },
    { name: "El Mastate", parent: "209", value: "20902" },
    { name: "Hacienda Vieja", parent: "209", value: "20903" },
    { name: "Coyolar", parent: "209", value: "20904" },
    { name: "La Ceiba", parent: "209", value: "20905" },
    { name: "Quesada", parent: "210", value: "21001" },
    { name: "Florencia", parent: "210", value: "21002" },
    { name: "Buenavista", parent: "210", value: "21003" },
    { name: "Aguas Zarcas", parent: "210", value: "21004" },
    { name: "Venecia", parent: "210", value: "21005" },
    { name: "Pital", parent: "210", value: "21006" },
    { name: "La Fortuna", parent: "210", value: "21007" },
    { name: "La Tigra", parent: "210", value: "21008" },
    { name: "La Palmera", parent: "210", value: "21009" },
    { name: "Venado", parent: "210", value: "21010" },
    { name: "Cutris", parent: "210", value: "21011" },
    { name: "Monterrey", parent: "210", value: "21012" },
    { name: "Pocosol", parent: "210", value: "21013" },
    { name: "Zarcero", parent: "211", value: "21101" },
    { name: "Laguna", parent: "211", value: "21102" },
    { name: "Tapesco", parent: "211", value: "21103" },
    { name: "Guadalupe", parent: "211", value: "21104" },
    { name: "Palmira", parent: "211", value: "21105" },
    { name: "Zapote", parent: "211", value: "21106" },
    { name: "Brisas", parent: "211", value: "21107" },
    { name: "Sarchí Norte", parent: "212", value: "21201" },
    { name: "Sarchí Sur", parent: "212", value: "21202" },
    { name: "Toro Amarillo", parent: "212", value: "21203" },
    { name: "San Pedro", parent: "212", value: "21204" },
    { name: "Rodríguez", parent: "212", value: "21205" },
    { name: "Upala", parent: "213", value: "21301" },
    { name: "Aguas Claras", parent: "213", value: "21302" },
    { name: "San José", parent: "213", value: "21303" },
    { name: "Bijagua", parent: "213", value: "21304" },
    { name: "Delicias", parent: "213", value: "21305" },
    { name: "Canalete", parent: "213", value: "21306" },
    { name: "Dos Ríos", parent: "213", value: "21306" },
    { name: "Yolillal", parent: "213", value: "21307" },
    { name: "Los Chiles", parent: "214", value: "21401" },
    { name: "Caño Negro", parent: "214", value: "21402" },
    { name: "El Amparo", parent: "214", value: "21403" },
    { name: "San Jorge", parent: "214", value: "21404" },
    { name: "San Rafael", parent: "215", value: "21501" },
    { name: "Buenavista", parent: "215", value: "21502" },
    { name: "Cote", parent: "215", value: "21503" },
    { name: "Katira", parent: "215", value: "21504" },
    { name: "Río Cuarto", parent: "216", value: "21601" },
    { name: "Santa Rita", parent: "216", value: "21602" },
    { name: "Santa Isabel", parent: "216", value: "21603" },
    { name: "Oriental", parent: "301", value: "30101" },
    { name: "Occidental", parent: "301", value: "30102" },
    { name: "Carmen", parent: "301", value: "30103" },
    { name: "San Nicolás", parent: "301", value: "30104" },
    { name: "Aguacaliente", parent: "301", value: "30105" },
    { name: "Guadalupe", parent: "301", value: "30106" },
    { name: "Corralillo", parent: "301", value: "30107" },
    { name: "Tierra Blanca", parent: "301", value: "30108" },
    { name: "Dulce Nombre", parent: "301", value: "30109" },
    { name: "Llano Grande", parent: "301", value: "30110" },
    { name: "Quebradilla", parent: "301", value: "30111" },
    { name: "Paraíso", parent: "302", value: "30201" },
    { name: "Santiago", parent: "302", value: "30202" },
    { name: "Orosi", parent: "302", value: "30203" },
    { name: "Cachí", parent: "302", value: "30204" },
    { name: "Llanos de Santa Lucía", parent: "302", value: "30205" },
    { name: "Tres Ríos", parent: "303", value: "30301" },
    { name: "San Diego", parent: "303", value: "30302" },
    { name: "San Juan", parent: "303", value: "30303" },
    { name: "San Rafael", parent: "303", value: "30304" },
    { name: "Concepción", parent: "303", value: "30305" },
    { name: "Dulce Nombre", parent: "303", value: "30306" },
    { name: "San Ramón", parent: "303", value: "30307" },
    { name: "Río Azul", parent: "303", value: "30308" },
    { name: "Juan Viñas", parent: "304", value: "30401" },
    { name: "Tucurrique", parent: "304", value: "30402" },
    { name: "Pejibaye", parent: "304", value: "30403" },
    { name: "Turrialba", parent: "305", value: "30501" },
    { name: "La Suiza", parent: "305", value: "30502" },
    { name: "Peralta", parent: "305", value: "30503" },
    { name: "Santa Cruz", parent: "305", value: "30504" },
    { name: "Santa Teresita", parent: "305", value: "30505" },
    { name: "Pavones", parent: "305", value: "30506" },
    { name: "Tuis", parent: "305", value: "30507" },
    { name: "Tayutic", parent: "305", value: "30508" },
    { name: "Santa Rosa", parent: "305", value: "30509" },
    { name: "Tres Equis", parent: "305", value: "30510" },
    { name: "La Isabel", parent: "305", value: "30511" },
    { name: "Chirripó", parent: "305", value: "30512" },
    { name: "Pacayas", parent: "306", value: "30601" },
    { name: "Cervantes", parent: "306", value: "30602" },
    { name: "Capellades", parent: "306", value: "30603" },
    { name: "San Rafael", parent: "307", value: "30701" },
    { name: "Cot", parent: "307", value: "30702" },
    { name: "Potrero Cerrado", parent: "307", value: "30703" },
    { name: "Cipreses", parent: "307", value: "30704" },
    { name: "Santa Rosa", parent: "307", value: "30705" },
    { name: "El Tejar", parent: "308", value: "30801" },
    { name: "San Isidro", parent: "308", value: "30802" },
    { name: "Tobosi", parent: "308", value: "30803" },
    { name: "Patio de Agua", parent: "308", value: "30804" },
    { name: "Heredia", parent: "401", value: "40101" },
    { name: "Mercedes", parent: "401", value: "40102" },
    { name: "San Francisco", parent: "401", value: "40103" },
    { name: "Ulloa", parent: "401", value: "40104" },
    { name: "Varablanca", parent: "401", value: "40105" },
    { name: "Barva", parent: "402", value: "40201" },
    { name: "San Pedro", parent: "402", value: "40202" },
    { name: "San Pablo", parent: "402", value: "40203" },
    { name: "San Roque", parent: "402", value: "40204" },
    { name: "Santa Lucía", parent: "402", value: "40205" },
    { name: "San José de la Montaña", parent: "402", value: "40206" },
    { name: "Santo Domingo", parent: "403", value: "40301" },
    { name: "San Vicente", parent: "403", value: "40302" },
    { name: "San Miguel", parent: "403", value: "40303" },
    { name: "Paracito", parent: "403", value: "40304" },
    { name: "Santo Tomás", parent: "403", value: "40305" },
    { name: "Santa Rosa", parent: "403", value: "40306" },
    { name: "Tures", parent: "403", value: "40307" },
    { name: "Pará", parent: "403", value: "40308" },
    { name: "Santa Bárbara", parent: "404", value: "40401" },
    { name: "San Pedro", parent: "404", value: "40402" },
    { name: "San Juan", parent: "404", value: "40403" },
    { name: "Jesús", parent: "404", value: "40404" },
    { name: "Santo Domingo", parent: "404", value: "40405" },
    { name: "Purabá", parent: "404", value: "40406" },
    { name: "San Rafael", parent: "405", value: "40501" },
    { name: "San Josecito", parent: "405", value: "40502" },
    { name: "Santiago", parent: "405", value: "40503" },
    { name: "Ángeles", parent: "405", value: "40504" },
    { name: "Concepción", parent: "405", value: "40505" },
    { name: "San Isidro", parent: "406", value: "40601" },
    { name: "San José", parent: "406", value: "40602" },
    { name: "Concepción", parent: "406", value: "40603" },
    { name: "San Francisco", parent: "406", value: "40604" },
    { name: "San Antonio", parent: "407", value: "40701" },
    { name: "La Ribera", parent: "407", value: "40702" },
    { name: "La Asunción", parent: "407", value: "40703" },
    { name: "San Joaquín", parent: "408", value: "40801" },
    { name: "Barrantes", parent: "408", value: "40802" },
    { name: "Llorente", parent: "408", value: "40803" },
    { name: "San Pablo", parent: "409", value: "40901" },
    { name: "Rincón de Sabanilla", parent: "409", value: "40902" },
    { name: "Puerto Viejo", parent: "410", value: "41001" },
    { name: "La Virgen", parent: "410", value: "41002" },
    { name: "Las Horquetas", parent: "410", value: "41003" },
    { name: "Llanuras del Gaspar", parent: "410", value: "41004" },
    { name: "Cureña", parent: "410", value: "41005" },
    { name: "Liberia", parent: "501", value: "50101" },
    { name: "Cañas Dulces", parent: "501", value: "50102" },
    { name: "Mayorga", parent: "501", value: "50103" },
    { name: "Nacascolo", parent: "501", value: "50104" },
    { name: "Curubandé", parent: "501", value: "50105" },
    { name: "Nicoya", parent: "502", value: "50201" },
    { name: "Mansión", parent: "502", value: "50202" },
    { name: "San Antonio", parent: "502", value: "50203" },
    { name: "Quebrada Honda", parent: "502", value: "50204" },
    { name: "Sámara", parent: "502", value: "50205" },
    { name: "Nosara", parent: "502", value: "50206" },
    { name: "Belén de Nosarita", parent: "502", value: "50207" },
    { name: "Santa Cruz", parent: "503", value: "50301" },
    { name: "Bolsón", parent: "503", value: "50302" },
    { name: "Veintisiete de Abril", parent: "503", value: "50303" },
    { name: "Tempate", parent: "503", value: "50304" },
    { name: "Cartagena", parent: "503", value: "50305" },
    { name: "Cuajiniquil", parent: "503", value: "50306" },
    { name: "Diriá", parent: "503", value: "50307" },
    { name: "Cabo Velas", parent: "503", value: "50308" },
    { name: "Tamarindo", parent: "503", value: "50309" },
    { name: "Bagaces", parent: "504", value: "50401" },
    { name: "La Fortuna", parent: "504", value: "50402" },
    { name: "Mogote", parent: "504", value: "50403" },
    { name: "Río Naranjo", parent: "504", value: "50404" },
    { name: "Filadelfia", parent: "505", value: "50501" },
    { name: "Palmira", parent: "505", value: "50502" },
    { name: "Sardinal", parent: "505", value: "50503" },
    { name: "Belén", parent: "505", value: "50504" },
    { name: "Cañas", parent: "506", value: "50601" },
    { name: "Palmira", parent: "506", value: "50602" },
    { name: "San Miguel", parent: "506", value: "50603" },
    { name: "Bebedero", parent: "506", value: "50604" },
    { name: "Porozal", parent: "506", value: "50605" },
    { name: "Las Juntas", parent: "507", value: "50701" },
    { name: "Sierra", parent: "507", value: "50702" },
    { name: "San Juan", parent: "507", value: "50703" },
    { name: "Colorado", parent: "507", value: "50704" },
    { name: "Tilarán", parent: "508", value: "50801" },
    { name: "Quebrada Grande", parent: "508", value: "50802" },
    { name: "Tronadora", parent: "508", value: "50803" },
    { name: "Santa Rosa", parent: "508", value: "50804" },
    { name: "Líbano", parent: "508", value: "50805" },
    { name: "Tierras Morenas", parent: "508", value: "50806" },
    { name: "Arenal", parent: "508", value: "50807" },
    { name: "Cabeceras", parent: "508", value: "50808" },
    { name: "Carmona", parent: "509", value: "50901" },
    { name: "Santa Rita", parent: "509", value: "50902" },
    { name: "Zapotal", parent: "509", value: "50903" },
    { name: "San Pablo", parent: "509", value: "50904" },
    { name: "Porvenir", parent: "509", value: "50905" },
    { name: "Bejuco", parent: "509", value: "50906" },
    { name: "La Cruz", parent: "510", value: "51001" },
    { name: "Santa Cecilia", parent: "510", value: "51002" },
    { name: "La Garita", parent: "510", value: "51003" },
    { name: "Santa Elena", parent: "510", value: "51004" },
    { name: "Hojancha", parent: "511", value: "51101" },
    { name: "Monte Romo", parent: "511", value: "51102" },
    { name: "Puerto Carrillo", parent: "511", value: "51103" },
    { name: "Huacas", parent: "511", value: "51104" },
    { name: "Matambú", parent: "511", value: "51105" },
    { name: "Puntarenas", parent: "601", value: "60101" },
    { name: "Pitahaya", parent: "601", value: "60102" },
    { name: "Chomes", parent: "601", value: "60103" },
    { name: "Lepanto", parent: "601", value: "60104" },
    { name: "Paquera", parent: "601", value: "60105" },
    { name: "Manzanillo", parent: "601", value: "60106" },
    { name: "Guacimal", parent: "601", value: "60107" },
    { name: "Barranca", parent: "601", value: "60108" },
    { name: "Monte Verde", parent: "601", value: "60109" },
    { name: "Isla del Coco", parent: "601", value: "60110" },
    { name: "Cóbano", parent: "601", value: "60111" },
    { name: "Chacarita", parent: "601", value: "60112" },
    { name: "Chira", parent: "601", value: "60113" },
    { name: "Acapulco", parent: "601", value: "60114" },
    { name: "El Roble", parent: "601", value: "60115" },
    { name: "Arancibia", parent: "601", value: "60116" },
    { name: "Espíritu Santo", parent: "602", value: "60201" },
    { name: "San Juan Grande", parent: "602", value: "60202" },
    { name: "Macacona", parent: "602", value: "60203" },
    { name: "San Rafael", parent: "602", value: "60204" },
    { name: "San Jerónimo", parent: "602", value: "60205" },
    { name: "Caldera", parent: "602", value: "60206" },
    { name: "Buenos Aires", parent: "603", value: "60301" },
    { name: "Volcán", parent: "603", value: "60302" },
    { name: "Potrero Grande", parent: "603", value: "60303" },
    { name: "Boruca", parent: "603", value: "60304" },
    { name: "Pilas", parent: "603", value: "60305" },
    { name: "Colinas", parent: "603", value: "60306" },
    { name: "Chánguena", parent: "603", value: "60307" },
    { name: "Biolley", parent: "603", value: "60308" },
    { name: "Brunka", parent: "603", value: "60309" },
    { name: "Miramar", parent: "604", value: "60401" },
    { name: "La Unión", parent: "604", value: "60402" },
    { name: "San Isidro", parent: "604", value: "60403" },
    { name: "Bahía Ballena", parent: "605", value: "60504" },
    { name: "Puerto Cortés", parent: "605", value: "60501" },
    { name: "Palmar", parent: "605", value: "60502" },
    { name: "Sierpe", parent: "605", value: "60503" },
    { name: "Piedras Blancas", parent: "605", value: "60504" },
    { name: "Bahía Drake", parent: "605", value: "60506" },
    { name: "Quepos", parent: "606", value: "60601" },
    { name: "Savegre", parent: "606", value: "60602" },
    { name: "Naranjito", parent: "606", value: "60603" },
    { name: "Golfito", parent: "607", value: "60701" },
    { name: "Puerto Jiménez", parent: "607", value: "60702" },
    { name: "Guaycará", parent: "607", value: "60703" },
    { name: "Pavón", parent: "607", value: "60704" },
    { name: "San Vito", parent: "608", value: "60801" },
    { name: "Sabalito", parent: "608", value: "60802" },
    { name: "Aguabuena", parent: "608", value: "60803" },
    { name: "Limoncito", parent: "608", value: "60804" },
    { name: "Pittier", parent: "608", value: "60805" },
    { name: "Gutiérrez Braun", parent: "608", value: "60806" },
    { name: "Parrita", parent: "609", value: "60901" },
    { name: "Corredor", parent: "610", value: "61001" },
    { name: "La Cuesta", parent: "610", value: "61002" },
    { name: "Canoas", parent: "610", value: "61003" },
    { name: "Laurel", parent: "610", value: "61004" },
    { name: "Jacó", parent: "611", value: "61101" },
    { name: "Tárcoles", parent: "611", value: "61102" },
    { name: "Limón", parent: "701", value: "70101" },
    { name: "Valle La Estrella", parent: "701", value: "70102" },
    { name: "Río Blanco", parent: "701", value: "70103" },
    { name: "Matama", parent: "701", value: "70104" },
    { name: "Guápiles", parent: "702", value: "70201" },
    { name: "Jiménez", parent: "702", value: "70202" },
    { name: "La Rita", parent: "702", value: "70203" },
    { name: "Roxana", parent: "702", value: "70204" },
    { name: "Cariari", parent: "702", value: "70205" },
    { name: "Colorado", parent: "702", value: "70206" },
    { name: "La Colonia", parent: "702", value: "70207" },
    { name: "Siquirres", parent: "703", value: "70301" },
    { name: "Pacuarito", parent: "703", value: "70302" },
    { name: "Florida", parent: "703", value: "70303" },
    { name: "Germania", parent: "703", value: "70304" },
    { name: "El Cairo", parent: "703", value: "70305" },
    { name: "Alegría", parent: "703", value: "70306" },
    { name: "Reventazón", parent: "703", value: "70307" },
    { name: "Bratsi", parent: "704", value: "70401" },
    { name: "Sixaola", parent: "704", value: "70402" },
    { name: "Cahuita", parent: "704", value: "70403" },
    { name: "Telire", parent: "704", value: "70404" },
    { name: "Matina", parent: "705", value: "70501" },
    { name: "Batán", parent: "705", value: "70502" },
    { name: "Carrandi", parent: "705", value: "70503" },
    { name: "Guácimo", parent: "706", value: "70601" },
    { name: "Mercedes", parent: "706", value: "70602" },
    { name: "Pocora", parent: "706", value: "70603" },
    { name: "Río Jiménez", parent: "706", value: "70604" },
    { name: "Duacarí", parent: "706", value: "70605" },
  ],

  [CatalogsEnum.PersonTypes]: [
    { name: "Física", value: "01" },
    { name: "Jurídica", value: "02" },
  ],
};
