import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import theme from "../../../theme";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty } from "lodash";

const useStyles = makeStyles(() => ({
  medium: {},
  errorLabel: {
    color: theme.palette.error.main,
    fontSize: 20,
    marginBottom: 10,
  },
  label: { fontSize: 20, marginBottom: 5 },
  small: {
    height: "30px",
    "& .MuiSelect-outlined": {
      paddingTop: "8px",
      paddingBottom: "8px",
    },
  },
  formControl: {
    marginBottom: theme.spacing(4),
    minWidth: "150px",
    display: "block",
  },
}));
export interface IKshSelectInput {
  id: string;
  disableValue?: string;
  label?: string;
  options: { key: string; value: string }[];
  error?: boolean;
  errorMessage?: string;
  value?: string;
  onChange(value: any): void;
  onBlur?(value: React.FocusEvent<{ name?: string; value: unknown }>): void;
  styleSelect?: object;
  size?: "small" | "medium";
  isDefaultProcessor?: boolean;
}

export const KshSelectInput: React.FC<IKshSelectInput> = (
  props: IKshSelectInput
) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {props.isDefaultProcessor ? (
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            value={props.value}
            style={{ width: "100%" }}
            onChange={props.onChange}
            disabled={isEmpty(props.options)}
            variant="outlined"
            select
            label={props.label}
          >
            <MenuItem value="" disabled>
              {props.disableValue || ""}
            </MenuItem>
            {props.options.map((option: { key: string; value: string }) => (
              <MenuItem key={option.key} value={option.key}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      ) : (
        <FormControl fullWidth className={classes[props.size || "medium"]}>
          <Select
            variant="outlined"
            id={props.id}
            value={props.value}
            onChange={props.onChange}
            error={props.error}
            name={props.id}
            onBlur={props.onBlur}
            style={props.styleSelect || { marginRight: 40, marginBottom: 20 }}
            displayEmpty
          >
            <MenuItem value="" disabled>
              {props.disableValue || ""}
            </MenuItem>
            {props.options.map((option: { key: string; value: string }) => (
              <MenuItem key={option.key} value={option.key}>
                {option.value}
              </MenuItem>
            ))}
          </Select>
          {props.error && (
            <Typography
              id="typography_error"
              variant="caption"
              color="error"
              display="block"
              gutterBottom
              style={{ marginTop: 3 }}
            >
              {props.errorMessage}
            </Typography>
          )}
        </FormControl>
      )}
    </React.Fragment>
  );
};
