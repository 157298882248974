import { ISiftScienceState, SiftScienceAction } from "./actionCreators";
import { ActionTypes } from "./actionTypes";

export const SIFT_SCIENCE_INITIAL_STATE: ISiftScienceState = {
  isLoading: false,
  disabled: false,
  merchants: {
    data: [],
  },
  queryMerchants: {
    data: [],
  },
  credentials: {
    siftCredentials: {},
  },
  isMigrated: undefined,
  showModalCreate: false,
  isVisible: false,
  showButtonMigrated: false,
};

export const siftScienceReducer = (
  state: ISiftScienceState = SIFT_SCIENCE_INITIAL_STATE,
  action: SiftScienceAction
): ISiftScienceState => {
  switch (action.type) {
    case ActionTypes.SET_MERCHANTS:
      return {
        ...state,
        merchants: action.merchants!,
      };
    case ActionTypes.SET_QUERY_MERCHANTS:
      return {
        ...state,
        queryMerchants: action.queryMerchants!,
      };
    case ActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case ActionTypes.SET_DISABLED:
      return {
        ...state,
        disabled: action.disabled,
      };
    case ActionTypes.SET_SIFTSCIENCE_CREDENTIALS:
      return {
        ...state,
        credentials: action.credentials!,
      };
    case ActionTypes.SET_IS_MIGRATED:
      return {
        ...state,
        isMigrated: action.isMigrated,
      };
    case ActionTypes.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.notification,
      };
    case ActionTypes.SET_SHOW_MODAL_CREATE:
      return {
        ...state,
        showModalCreate: action.showModalCreate,
      };

    case ActionTypes.SET_IS_VISIBLE:
      return {
        ...state,
        isVisible: action.isVisible,
      };

    case ActionTypes.SET_SHOW_BUTTON_MIGRATED:
      return {
        ...state,
        showButtonMigrated: action.showButtonMigrated,
      };

    case ActionTypes.SET_MIGRATE_SIFTSCIENCE_RESPONSE:
      return {
        ...state,
        migrateSiftScienceResponse: action.migrateSiftScienceResponse,
      };

    default:
      return state;
  }
};
