export enum StatusStepEnum {
  PENDING = "pending",
  IN_PROCESS = "inProcess",
  COMPLETE = "complete",
  OMITTED = "omitted",
  BLOCKED = "blocked",
  NOT_AVAILABLE = "notAvailable",
}

export enum statusTabInfo {
  PENDING = "Pendiente",
  IN_PROCESS = "En proceso",
  COMPLETE = "Completado",
  NOT_AVAILABLE = "No disponible",
}

export const statusStepsToTabInfo = new Map<StatusStepEnum, statusTabInfo>([
  [StatusStepEnum.PENDING, statusTabInfo.PENDING],
  [StatusStepEnum.IN_PROCESS, statusTabInfo.IN_PROCESS],
  [StatusStepEnum.COMPLETE, statusTabInfo.COMPLETE],
  [StatusStepEnum.NOT_AVAILABLE, statusTabInfo.NOT_AVAILABLE],
]);
