import React from "react";
import { Divider, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useWebhooksGrid } from "./state/useWebhooksGrid";
import { SkeletonTableProcessors } from "../../Skeletons/SkeletonTableProcessors/SkeletonTableProcessors";
import {
  PaymentMethods,
  PaymentMethodsSpanish,
} from "../../../shared/infrastructure/constants/MerchantConfigConstants";

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "14px",
  },
}));

export const WebhooksGrid = () => {
  const style = useStyles();
  const {
    webhookList,
    isLoadingWebhookList,
    handleOnlyUniqueWebhookService,
  } = useWebhooksGrid();

  return isLoadingWebhookList ? (
    <SkeletonTableProcessors rows={3} cells={2} />
  ) : (
    <Grid container direction={"column"} spacing={2}>
      {webhookList?.items.slice(0, 5).map((data, index) => (
        <Grid key={`${2222}-${index}`} item xs={12}>
          <Grid container direction={"row"}>
            <Grid item xs={6} style={{ textAlign: "start" }}>
              <Typography className={style.title}>{data.alias}</Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "start" }}>
              <Typography noWrap className={style.title}>
                {data.events
                  .map((data) =>
                    data.service === PaymentMethods.CARD
                      ? PaymentMethodsSpanish.CARD
                      : data.service === PaymentMethods.CASH
                      ? PaymentMethodsSpanish.CASH
                      : data.service === PaymentMethods.SUBSCRIPTIONS
                      ? PaymentMethodsSpanish.SUBSCRIPTIONS
                      : PaymentMethodsSpanish.TRANSFER
                  )
                  .filter(handleOnlyUniqueWebhookService)
                  .toString()
                  .split(",")
                  .join(", ")}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
        </Grid>
      ))}
    </Grid>
  );
};
