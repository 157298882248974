export enum SnackbarEnum {
  REQUEST_ERROR = "Ha ocurrido un error. Inténtalo nuevamente",
  REQUEST_SUCCESS = "Descarga de archivo completa",
}

export enum NotificationTypeEnum {
  CUSTOM = "CUSTOM",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}
