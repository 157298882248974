import { ContentTaxes } from "../../components/CardInformation/CardInformation.interfaces";
import { jsonToString } from "./card_tabs_utils";
import { MetadataTitleEnum } from "../enums/MetadataTitleEnum";
import { Chargeback } from "../../../types/chargeback";
import { copyToClipboard } from "./misc_utils";

export const showMetadataCredentials = (
  transactionInfo: Chargeback
): ContentTaxes[] => {
  let listMetadata: ContentTaxes[] = [
    {
      body: jsonToString(transactionInfo).metadata,
      onSelected: () => copyToClipboard(jsonToString(transactionInfo).metadata),
      title: MetadataTitleEnum.metadata_transaction,
    },
  ];

  return listMetadata;
};
