export enum SecurityWrapperRoles {
  USERS_RESET_PASSWORD = "M_USERS.ResetearContraseña",
  USERS_EDIT = "M_USERS.Editar",
  USERS_DELETE = "M_USERS.Eliminar",
  USERS_ADD = "M_USERS.AgregarUsuario",
  M_USERS_ROOT = "M_USERS",
  M_USERS_EDIT = "M_USERS.Datosempresa.Editar",
  M_USERS_USER_CREATE = "M_USERS.AgregarUsuario",
  M_USERS_USER_EDIT = "M_USERS.Editar",
}
