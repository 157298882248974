import { DashboardHistoricTableRowProps } from "../DashboardHistoricTableRow";
import { InvoiceRecord } from "../../../../../../types/remote/response_search_blling_by_filters";

import React from "react";
import { KindEnum } from "../../../../../shared/infrastructure/KindEnum";
import { BillingTransactionTypeEnum } from "../../../../../shared/infrastructure/BillingTransactionTypeEnum";

export interface DashboardHistoricTableRowState {
  actions: {
    handleChangeCheckboxAll: (
      e: React.ChangeEvent<HTMLInputElement>,
      transaction: InvoiceRecord | undefined,
      all: boolean
    ) => void;
    showManualIcon: (
      kind: KindEnum,
      transactionType: BillingTransactionTypeEnum
    ) => boolean;
  };
}

export const useDashboardHistoricTableRowState = (
  props: DashboardHistoricTableRowProps
): DashboardHistoricTableRowState => {
  const handleChangeCheckboxAll = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.checked) {
      props.setBillingHistoricDataRow({
        ...props.historicBillingData,
        statusAll: false,
      });
      props.transactionIdFilterHistory();
      return;
    } else {
      props.setBillingHistoricDataRow({
        ...props.historicBillingData,
        statusAll: true,
      });
      props.transactionIdFilterHistory();
      return;
    }
  };

  const showManualIcon = (
    kind: KindEnum,
    transactionType: BillingTransactionTypeEnum
  ): boolean => {
    return (
      transactionType === BillingTransactionTypeEnum.MANUAL_FROM_FILE &&
      (kind === KindEnum.INVOICE ||
        kind === KindEnum.CREDIT_NOTE ||
        kind === KindEnum.DEBIT_NOTE)
    );
  };

  return {
    actions: {
      handleChangeCheckboxAll: handleChangeCheckboxAll,
      showManualIcon,
    },
  };
};
