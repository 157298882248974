import { ICellFlag } from "./CellFlag.interfaces";
import { Box, Typography } from "@mui/material";
import React, { FC } from "react";
import { FLAG_ICON, TEXT_FLAG } from "./CellFlag.constants";
import { cellFlagStyles } from "./CellFlag.styles";
import { defaultTo, get } from "lodash";
import IconEmpty from "../../../assets/icons/IconEmpty";

interface IFlagValues {
  countryCode: string;
  countryText: string;
  IconFlag: FC;
}

const getCountryByCode = (code_country: string): string => {
  const code_countries: string[] = Object.values(TEXT_FLAG);
  const traduction_countries: string[] = Object.keys(TEXT_FLAG);
  const position: number = code_countries.indexOf(code_country);

  return get(traduction_countries, `[${position}]`, "");
};

const getFlagValues = (isCode: boolean, country_value: string): IFlagValues => {
  let country_text: string = country_value;
  let code_country: string = country_value;

  if (isCode) {
    country_text = getCountryByCode(country_value);
  } else {
    code_country = TEXT_FLAG[country_text];
  }

  return {
    countryCode: code_country,
    countryText: country_text,
    IconFlag: defaultTo(FLAG_ICON[country_text], IconEmpty),
  };
};

export const CellFlagItem: FC<ICellFlag> = ({
  type,
  country,
  align,
  isCodeCountry = false,
}: ICellFlag) => {
  const { IconFlag, countryCode }: IFlagValues = getFlagValues(
    isCodeCountry,
    country
  );
  const twoLines: boolean = type === "twolines";

  return (
    <Box sx={cellFlagStyles.container} alignItems={align || "center"}>
      <>
        <IconFlag />
        {twoLines && (
          <Typography
            color="text.grey"
            variant="overline"
            component="div"
            sx={cellFlagStyles.textFlag}
          >
            {countryCode}
          </Typography>
        )}
      </>
    </Box>
  );
};
