import { ITableRowPropsMassive, Order } from "../interfaces";
import {
  IUseTableNodesInterfaces,
  IUseTableNodesRequest,
} from "./IUseTableNodes.interfaces";
import React, { useEffect } from "react";
import {
  cloneDeep,
  filter,
  get,
  isEmpty,
  isEqual,
  orderBy as orderByFn,
  remove,
  set,
  uniqBy,
} from "lodash";
import { MerchantNodeData } from "../../../../../types/search_merchant_response";
import { setItem } from "../../../../shared/utils/SessionStorageUtils";
import { SessionStorageEnum } from "../../../../shared/enum/SessionStorageEnum";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../store/hooks/storeHook";
import { setSelectedData } from "../../../../store/actions/generalData.actions";
import { HeadCellsLabels } from "../../../../shared/constants/CatalogConfigTableBranch";
import { RootState } from "../../../../store/interfaces/store.interfaces";
import { selectedDataUtils } from "../../../../shared/utils/SelectedDataUtils";
import { verifyChangedRowData } from "../../../../shared/utils/tableUtils";

export const useTableNodes = ({
  handleSelectedRows,
  rows,
  rowsPerPage,
  totalData,
}: IUseTableNodesRequest): IUseTableNodesInterfaces => {
  const dispatch = useAppDispatch();
  const { selectedData } = useAppSelector(
    (state: RootState) => state.generalData
  );
  const [order] = React.useState<Order>("asc");
  const [orderBy] = React.useState<string>("line112343242");
  const totalPages = totalData > 0 ? Math.ceil(totalData / rowsPerPage) : 0;
  const [handledRows, setHandledRows] =
    React.useState<ITableRowPropsMassive[]>(rows);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected: MerchantNodeData[] = [];

      rows.forEach((n: ITableRowPropsMassive<MerchantNodeData>) => {
        if (get(n, "info.configs_complete")) {
          newSelected.push(n.info);
        }
      });

      dispatch(
        setSelectedData(uniqBy([...selectedData, ...newSelected], "name"))
      );
      setItem(
        SessionStorageEnum.BRANCH_LIST,
        uniqBy([...selectedData, ...newSelected], "name")
      );

      return;
    }
    dispatch(setSelectedData([]));
    setItem(SessionStorageEnum.BRANCH_LIST, []);
  };

  const handleClick = (
    _event: React.MouseEvent<unknown>,
    id: MerchantNodeData
  ) => {
    const newSelected = selectedDataUtils(id, selectedData);

    dispatch(setSelectedData(newSelected));
    setItem(SessionStorageEnum.BRANCH_LIST, newSelected);
  };

  const calculateIndex = (
    rowsCalculateIndex: ITableRowPropsMassive[]
  ): ITableRowPropsMassive[] => {
    rowsCalculateIndex.map((row, index) =>
      row.cells.map((cell) => set(cell, "props.rowIndex", index))
    );

    return rowsCalculateIndex;
  };

  const processOrder = (order: string, pathValue: string) => {
    let rowsCopy: ITableRowPropsMassive[] = [];

    rowsCopy = orderByFn(rows, pathValue, order as "asc" | "desc");
    setHandledRows(calculateIndex(cloneDeep(rowsCopy)));
  };

  const checkRowsInfoEquality = (): boolean => {
    verifyChangedRowData(setHandledRows, calculateIndex, handledRows, rows);

    return !handledRows.every((rowVal) =>
      rows.find((handledRowVal) => isEqual(handledRowVal.info, rowVal.info))
    );
  };

  useEffect(() => {
    if (checkRowsInfoEquality())
      setHandledRows(calculateIndex(cloneDeep(rows)));
  }, [rows]);

  const processOrderByValue = (valueOrder: string, order: string) => {
    switch (valueOrder) {
      case HeadCellsLabels.NAME:
        processOrder(order, "cells[1].props.line1");
        break;
      case HeadCellsLabels.BRANCH_ID:
        processOrder(order, "cells[2].props.textToCopy");
        break;
      case HeadCellsLabels.STATUS:
        processOrder(order, "cells[3].props.text");
        break;
      default:
        processOrder(order, "cells[1].props.line1");
        break;
    }
  };

  const handleOrderByHeader = (
    valueOrder: string,
    order: string,
    numberOfClick: number
  ) => {
    if (!isEmpty(order)) {
      processOrderByValue(valueOrder, order);
    } else {
      if (numberOfClick === 0) {
        processOrderByValue(valueOrder, "asc");
      }
    }
  };

  const isSelected = (id: string, configComplete: boolean): boolean => {
    if (configComplete)
      return selectedData.findIndex((el) => el.merchant_id === id) !== -1;

    return false;
  };

  useEffect(() => {
    handleSelectedRows(selectedData);
  }, [selectedData]);

  const handleCalculateRowsCount = (
    branches: ITableRowPropsMassive[]
  ): number => {
    const list = cloneDeep(branches);

    remove(list, (item) => {
      return !get(item, "info.configs_complete");
    });

    return list.length;
  };

  const checkAllSelected = (): boolean => {
    const branchesAvailable: string[] = filter(
      handledRows,
      (branch: ITableRowPropsMassive) => get(branch, "info.configs_complete")
    ).map((f) => get(f, "info.merchant_id"));

    const selectedBranches = filter(selectedData, (s: MerchantNodeData) =>
      branchesAvailable.includes(get(s, "merchant_id", ""))
    ).map((f) => get(f, "merchant_id"));

    return handleCalculateRowsCount(handledRows) === selectedBranches.length;
  };

  useEffect(() => {
    checkAllSelected();
  }, [handledRows]);

  return {
    handleCalculateRowsCount,
    handledRows,
    handleOrderByHeader,
    headerProps: {
      order,
      orderBy,
    },
    rowsProps: {
      check: {
        allChecked: checkAllSelected(),
        handleClick,
        handleSelectAllClick,
        isSelected,
        selected: selectedData,
      },
    },
    totalPages,
  };
};
