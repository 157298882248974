import { IRowInteractiveProps } from "@kushki/connect-ui";
import {
  ITableCellProps,
  ITableRowProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { useEffect, useState } from "react";
import axios from "../../../shared/axios-util";
import { CatalogsEnum } from "../../../shared/infrastructure/catalogs-enum";
import {
  AccountProperties,
  ALL_TABLE_COLUMNS,
  TABLE_ROW,
  TEXT_CELL,
} from "../../../shared/infrastructure/constants/ChargesBlockConstants";
import {
  getCatalog,
  INVOICE_FRECUENCY,
} from "../../../shared/constants/MerchantInformationConstants";
import { CountriesEnum } from "../../../shared/infrastructure/countries-enum";
import { ITextBlock } from "../../../shared/infrastructure/interfaces/ITextBlock";
import { IChargesBlock } from "./useChargesBlock.interface";
import _, { get } from "lodash";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import {
  IAccountInfo,
  IGetBillingCoreMerchantNodeInfo,
} from "../../../../types/billing_core_merchant_node_info";
import { ConfigurationIdEnum } from "../../../shared/enums";
import { selectNodeInfo } from "../../../store/selectors/selectors";
import { useSelector } from "react-redux";
import {
  getSectionInfo,
  statusNodeInfo,
} from "../../../shared/utils/node_info";
import { EntityNameEnum } from "../../../shared/enums/entityName";

export const onRenderValue = (
  key: string,
  columnValue: string,
  country: CountriesEnum
) => {
  const renderValues = {
    [AccountProperties.BANK_ID]: () => {
      const result = getCatalog(country, CatalogsEnum.Banks).find(
        (bank) => bank.value === columnValue
      );

      return result ? result.name : "";
    },

    [AccountProperties.ACCOUNT_TYPE]: () => {
      const result = getCatalog(country, CatalogsEnum.BankAccountTypes).find(
        (accountType) => accountType.value === columnValue
      );

      return result ? result.name : "";
    },

    default: () => columnValue,
  };

  return get(renderValues, key, renderValues.default)();
};

const getColumns = (
  object: IAccountInfo,
  fullObject: IGetBillingCoreMerchantNodeInfo | undefined,
  country: CountriesEnum
): ITableCellProps[] => {
  return ALL_TABLE_COLUMNS.map((column) => {
    let value;

    if (column.key === AccountProperties.BANK_OF_ORIGIN) {
      let columnValue = get(fullObject, column.key) || "";

      value = onRenderValue(column.key, columnValue, country);
    } else {
      let columnValue = get(object, column.key);

      value = onRenderValue(column.key, columnValue, country);
    }

    const tableCell = get(TABLE_ROW, column.cellType, TEXT_CELL);

    return tableCell(value) as ITableCellProps;
  });
};

export const useChargesBlock = (): IChargesBlock => {
  const [rows, setRows] = useState<ITableRowProps[]>([]);
  const [chargesBlock, setChargesBlock] = useState<ITextBlock>({
    subtitle: "",
    title: "",
  });
  const [isComplete, setIsComplete] = useState<boolean | undefined>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [billingCoreMerchantNodeInfo, setBillingCoreMerchantNodeInfo] =
    useState<IGetBillingCoreMerchantNodeInfo>();

  const onClickRow = () => {};
  const nodeInfoData = useSelector(selectNodeInfo);

  const getData = async (publicMerchantId: string) => {
    setIsLoading(true);

    try {
      let result = await axios.post<IGetBillingCoreMerchantNodeInfo>(
        API_ROUTES.BILLING_CORE_MERCHANT_NODE_INFO,
        {
          configId: ConfigurationIdEnum.CN017,
          publicMerchantId,
        }
      );

      setBillingCoreMerchantNodeInfo(result.data);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (nodeInfoData) {
      if (
        nodeInfoData.entityName === EntityNameEnum.CUSTOMER ||
        nodeInfoData.entityName === EntityNameEnum.BRANCH
      ) {
        let sectionInfo = getSectionInfo(
          ConfigurationIdEnum.CN017,
          nodeInfoData.configs
        );

        if (sectionInfo) {
          if (sectionInfo.status === statusNodeInfo.COMPLETE) {
            getData(sectionInfo.value);
            setIsComplete(true);
          } else {
            setIsComplete(false);
          }
        } else {
          setIsComplete(false);
        }
      } else {
        setIsComplete(false);
      }
    } else {
      setIsComplete(false);
    }
  }, [nodeInfoData]);

  useEffect(() => {
    const principalAccount: IAccountInfo | undefined =
      billingCoreMerchantNodeInfo?.charge?.accountInfo;

    principalAccount &&
      setRows([
        {
          cells: getColumns(
            principalAccount,
            billingCoreMerchantNodeInfo,
            CountriesEnum.ECUADOR
          ),
          id: "charges",
          rowProps: {
            color: "default",
            onClick: onClickRow,
          } as IRowInteractiveProps,
        },
      ]);

    const invFreq =
      _.find(INVOICE_FRECUENCY, {
        value: billingCoreMerchantNodeInfo?.chargeFrequency,
      })?.name ?? "";

    setChargesBlock({
      subtitle: invFreq,
      title: "Frecuencia de Cobro",
    });

    setIsLoading(false);
  }, [billingCoreMerchantNodeInfo]);

  return {
    chargesBlock,
    isComplete,
    isLoading,
    onClickRow,
    table: {
      columns: ALL_TABLE_COLUMNS,
      rows: rows,
    },
  };
};
