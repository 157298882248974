export enum PathsEnum {
  ID = "id",
  STATUS = "status",
  SEARCH_RULE_TOTAL = "total",
  CREATED = "createdAt",
  RULE_NAME = "name",
}

export enum FilterPathsEnum {
  BUSINESS_CONDITIONS = "filter.category",
  STATUS = "filter.status",
}
