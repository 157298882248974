import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";
import { get } from "lodash";
import { IUserData } from "../../components/TransactionList/state/IUseTransactionList.interfaces";
import { TimeZoneDefault } from "../constants/TimeZoneDefault";
import {
  ICatalog,
  TimeZoneCatalog,
} from "../infraestructure/time-zone-catalogues";
import { IFilterItem } from "@kushki/connect-ui/dist/Components/Atoms/Filters/FilterDetailItem/FilterDetailItem.interfaces";
import { isComplianceUser } from "../complianceUser";
import { ChargebackStatusEnum } from "../enums/ChargebackStatusEnum";
import { RolEnum } from "../enums/RolEnum";
import { isChargebackManager } from "./roles";
import { PlaceholderEnum } from "../enums/PlaceholderEnum";

export const joinItemList = (
  listSelect: ICategory[],
  separator: string,
  lastChar: string = "",
  isDescription: boolean = false
): string => {
  return (
    listSelect
      .map((data) => (isDescription ? get(data, "description") : data.label))
      .join(separator) + lastChar
  );
};

export const timeZoneHour = (
  text: string | undefined,
  value: string
): string => {
  let index: number;

  if (text === undefined) return value === "utc" ? "-05:00" : TimeZoneDefault;

  index = TimeZoneCatalog.findIndex(
    (data: ICatalog) => data.description === text
  );

  if (index >= 0) {
    const lang: string | undefined = get(
      TimeZoneCatalog[index],
      value,
      undefined
    );

    return lang !== undefined
      ? lang
      : value === "utc"
      ? "-05:00"
      : TimeZoneDefault;
  }

  return value === "utc" ? "-05:00" : TimeZoneDefault;
};

export const getColumnsUser = (): IUserData => {
  const chargebackManager = isChargebackManager();

  return {
    admin:
      isComplianceUser() ||
      get(
        JSON.parse(localStorage.getItem("roles")!),
        RolEnum.BACKOFFICE_ADMIN,
        false
      ) ||
      chargebackManager,
    publicMerchantId: get(localStorage.getItem("merchantId"), ""),
    searchLabel: chargebackManager
      ? PlaceholderEnum.LABEL_BRANCHES
      : PlaceholderEnum.LABEL_SEARCH_BRANCHES,
    timeZone: timeZoneHour(localStorage.getItem("timeZone")!, "utc"),
  };
};

export function buildStringFilter(filterItems: IFilterItem[]): string {
  const separator: string = "|";
  const specialCases: Record<string, string> = {
    [ChargebackStatusEnum.WON]: `${ChargebackStatusEnum.WON}${separator}${ChargebackStatusEnum.APPROVAL}`,
    [ChargebackStatusEnum.LOST]: `${ChargebackStatusEnum.LOST}${separator}${ChargebackStatusEnum.DECLINED}`,
  };
  const filterArray: string[] = filterItems.map(
    (item: IFilterItem) => specialCases[item.value] || item.value
  );

  return filterArray.join(separator);
}
