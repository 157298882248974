import { ErrorMessageEnum } from "../enum/error_messages_form";

type Patterns = {
  alfa1To40: string;
  email: string;
  alfa1To40WithoutSpace: string;
};
export const PATTERNS: Patterns = {
  alfa1To40: "^[a-zA-Z0-9 ]{1,40}$",
  alfa1To40WithoutSpace: "^[a-zA-Z0-9]{1,40}$",
  email:
    "^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])$",
};
export const FormUserRules = {
  email: {
    pattern: {
      message: ErrorMessageEnum.EMAIL,
      value: new RegExp(PATTERNS.email),
    },
    required: {
      message: ErrorMessageEnum.REQUIRED,
      value: true,
    },
  },
  familyName: {
    pattern: {
      message: ErrorMessageEnum.PATTERN_ALPHA,
      value: new RegExp(PATTERNS.alfa1To40),
    },
    required: {
      message: ErrorMessageEnum.REQUIRED,
      value: true,
    },
  },
  merchant: {
    required: {
      message: ErrorMessageEnum.REQUIRED,
      value: true,
    },
  },
  name: {
    pattern: {
      message: ErrorMessageEnum.PATTERN_ALPHA,
      value: new RegExp(PATTERNS.alfa1To40),
    },
    required: {
      message: ErrorMessageEnum.REQUIRED,
      value: true,
    },
  },
  userName: {
    pattern: {
      message: ErrorMessageEnum.USERNAME_PATTERN,
      value: new RegExp(PATTERNS.alfa1To40WithoutSpace),
    },
    required: {
      message: ErrorMessageEnum.REQUIRED,
      value: true,
    },
  },
};
