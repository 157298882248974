export enum ErrorMessageCO {
  MAX_1_50_CHAR = "El máximo permitido de caracteres es 1-50",
  PF_RAZON_SOCIAL = "Por favor, ingresa la razón social",
  LIMIT_NIT = "El número de identificación debe tener 10 caracteres",
  PF_NUM_ID = "Por favor, ingresa el número de identificación",
  PF_NOMBRE = "Por favor, ingresa el nombre",
  PF_APELLIDO = "Por favor, ingresa el apellido",
  LETRAS = "Solo se aceptan letras",
  PF_MCC = "Por favor, selecciona el MCC",
  PF_ACT_COMER = "Por favor, selecciona el tipo de actividad económica  o giro comercial",
  PF_WEB = "Por favor, ingresa un Sitio Web",
}

export enum BasicDataLabelsCO {
  RAZON_SOCIAL = "Razón Social",
  NIT = "NIT",
  TIPO_EMPRESA = "Tipo de empresa o sociedad mercantil",
  TIPO_INDUSTRIA = "Tipo de industria",
  MCC = "MCC",
  FECHA_CONST = "Fecha de constitución",
  ICA_RETENTIONS = "Actividad general para retenciones ICA",
  WEB = "Sitio web",
  NOMBRE = "Nombres(s)",
  APELLIDO = "Apellido(s)",
}

export enum TypeElement {
  TEXT = "text",
  TEXT_CAPITAL = "textCapital",
  WEB = "web",
  DATE_PICKER = "datePicker",
  COMBO = "combo",
  COMBO_ARRAY = "comboArray",
}
