/**
 * CountryEnum
 */
import { PayInOutPopupConstants } from "../constants/CreateMerchantConstants";

export enum CountryEnum {
  ECUADOR = "Ecuador",
  COLOMBIA = "Colombia",
  PERU = "Peru",
  CHILE = "Chile",
  MEXICO = "Mexico",
  BRAZIL = "Brazil",
  EL_SALVADOR = "ElSalvador",
  PANAMA = "Panama",
  COSTA_RICA = "CostaRica",
  NICARAGUA = "Nicaragua",
  HONDURAS = "Honduras",
  GUATEMALA = "Guatemala",
  CENTRO_AMERICA = "CentroAmerica",
}

export const COUNTRY_WALLET: string[] = [
  CountryEnum.PERU,
  CountryEnum.COLOMBIA,
];

export const COUNTRY_DEFAULT_DEFERRED: string[] = [
  CountryEnum.ECUADOR,
  CountryEnum.MEXICO,
  CountryEnum.BRAZIL,
];

export const COUNTRY_PAY_DISABLED: Record<string, string[]> = {
  [CountryEnum.BRAZIL]: [
    PayInOutPopupConstants.TRANSFER,
    PayInOutPopupConstants.CASH,
  ],
};
