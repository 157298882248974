export enum WalletTransactionPath {
  CREATED = "created",
  ORIGIN = "origin",
  PAYMENT_METHOD = "payment_method",
  TOTAL_AMOUNT = "total_amount",
  CURRENCY = "currency",
  BALANCE = "balance",
  TRX_STATUS = "transaction_status",
  TRX_TYPE = "transaction_type",
  OPERATION_TYPE = "operationType",
  TRX_ID = "transaction_id",
  DATA = "data",
  MERCHANT_NAME = "merchant_name",
  ORIGIN_TICKET_NUMBER = "origin_ticket_number",
  DESCRIPTION = "description",
  KUSHKI_FEE = "kushki_fee",
  TICKET_NUMBER = "origin_ticket_number",
  FILTER = "filter",
}
