import { Box, Chip, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useDropDown } from "./state/useDropDown";
import { IDropDownProps } from "./DropDown.interfaces";
import { IconChevronDown } from "@kushki/connect-ui";
import { dropDownStyles as styles } from "./DropDown.styles";
const DropDrown: React.FC<IDropDownProps> = ({ items }: IDropDownProps) => {
  const { open, handleClose, handleClick, anchor, getRolName } = useDropDown();

  return (
    <Box sx={styles.container}>
      <Chip
        label={getRolName(items[0]).toUpperCase()}
        onClick={handleClick}
        icon={<IconChevronDown color={"primary"} fontSize={"small"} />}
        sx={styles.chip}
      />
      <Menu id="roles-menu" anchorEl={anchor} open={open} onClose={handleClose}>
        {items.map((item: string, index: number) => (
          <MenuItem key={index} onClick={handleClose} sx={styles.menuItems}>
            {getRolName(item).toUpperCase()}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default DropDrown;
