import { CustomStyles } from "../../shared/infrastructure/interfaces/CustomStyles";

export const RatesAndDiscountResumeStyles: CustomStyles = {
  papper: {
    borderRadius: "8px",
    padding: "16px 24px",
  },
  discountText: {
    minWidth: "180px",
    fontSize: "15px",
    lineHeight: "144%",
    fontWeight: "400",
  },
  discountDescriptionText: {
    fontSize: "15px",
    lineHeight: "144%",
    fontWeight: "400",
    ml: "8px",
  },
};
