// @ts-nocheck
import { createTheme } from "@material-ui/core/styles";
import { themeOptions } from "./themeui";

const theme = createTheme({
  ...themeOptions,
  palette: {
    ...themeOptions.palette,
  },
  typography: {
    ...themeOptions.typography,
  },

  overrides: {
    ...themeOptions.overrides,
  },
});

export default theme;
