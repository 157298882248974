import { SvgIconProps, SvgIcon } from "@mui/material";
import React, { FC } from "react";

const GeneratPenIcon: FC<SvgIconProps> = ({ sx, ...rest }) => (
  <SvgIcon
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ ...sx }}
    {...rest}
  >
    <rect y="0.25" width="32" height="32" rx="4" fill="#EEF6FF" />
    <path
      d="M16 22.25H22.75"
      stroke="#023365"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      fill="none"
      d="M19.375 9.87517C19.6734 9.5768 20.078 9.40918 20.5 9.40918C20.7089 9.40918 20.9158 9.45033 21.1088 9.53029C21.3019 9.61024 21.4773 9.72743 21.625 9.87517C21.7727 10.0229 21.8899 10.1983 21.9699 10.3913C22.0498 10.5844 22.091 10.7912 22.091 11.0002C22.091 11.2091 22.0498 11.416 21.9699 11.609C21.8899 11.802 21.7727 11.9774 21.625 12.1252L12.25 21.5002L9.25 22.2502L10 19.2502L19.375 9.87517Z"
      stroke="#023365"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default GeneratPenIcon;
