import { IStyles } from "../../../shared/infrastructure/interfaces/create_named_styles";
import { Theme } from "@mui/material";
import theme from "../../../theme";

export const ShareHolderAccordionContentStyles: IStyles = {
  accordion: {
    "&.MuiAccordion-root": {
      "&.Mui-expanded": {
        backgroundColor: "neutral.grey2",
        marginTop: "0px",
      },
    },
    marginBottom: "0px",
  },
  addButton: {
    ":hover": { borderColor: "#4498EE" },
    borderColor: "#4498EE",
    color: "#4498EE",
    fontWeight: theme.typography.fontWeightMedium,
  },
  boxIcon: {
    alignSelf: "center",
    padding: "0px 16px",
  },
  content: {
    justifyContent: "center",
    ml: 0,
    padding: "8px 16px 16px !important",
    width: "100%",
  },
  divider: {
    border: "1px solid",
    color: "neutral.grey4",
  },
  gridFlex: {
    display: "flex",
    flexWrap: "wrap",
  },
  gridFlexDivider: {
    maxWidth: "1%",
    pr: 2,
  },
  iconAccordionDown: {
    transform: "rotate(-90deg)",
  },
  iconAccordionUp: {
    transform: "rotate(90deg)",
  },
  nestedBadgeComponent: {
    "& .MuiBadge-badge": {
      backgroundColor: "primary.light2",
      color: theme.palette.common.white,
      height: "21px",
      minWidth: "32px",
    },
  },
  nestedBadgeComponentLvl1: {
    "& .MuiBadge-badge": {
      backgroundColor: "primary.main",
      color: theme.palette.common.white,
      height: "21px",
      minWidth: "32px",
    },
  },
  nestedLine: {
    borderColor: "primary.main",
    borderLeft: "2px solid",
    height: "32px",
  },
  nestedLineDown: {
    borderColor: "primary.light2",
    borderLeft: "2px solid",
    height: "12px",
  },
  nestedLineUp: {
    borderColor: "primary.light2",
    borderLeft: "2px solid",
    height: "32px",
  },
  normalBox: {
    marginLeft: "10px",
    padding: "0px 16px",
  },
  normalValue: {
    marginLeft: "5px",
  },
  optionList: (theme: Theme) => ({
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(1),
      boxShadow:
        "0px 36px 65px rgba(106, 167, 197, 0.16), 0px 18.225px 28.3359px rgba(106, 167, 197, 0.12), 0px 7.2px 10.5625px rgba(106, 167, 197, 0.08), 0px 1.575px 3.75781px rgba(106, 167, 197, 0.03)",
      padding: "0px 0px 0px 0px",
    },
  }),
  summaryAccordionDown: {
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    flexDirection: "row-reverse",
  },
  summaryAccordionUp: {
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(-90deg)",
    },
    flexDirection: "row-reverse",
  },
  typographyLabel: {
    color: "#868686",
    fontSize: "14px",
  },
  typographyLabelMain: {
    color: "#000000",
    fontSize: "14px",
  },
};
