import { makeStyles } from "@material-ui/core/styles";

export const requestDispersionStyleError = makeStyles({
  mainGridStatusDispersion: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    textAlign: "center",
    paddingLeft: "25%",
    paddingRight: "25%",
    paddingBottom: "20px",
    backgroundColor: "white",
    borderRadius: "5px",
  },
  textValidationBody: {
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "15px",
    color: "#677784",
    marginTop: "20px",
  },
  textValidationTitle: {
    fontWeight: 5,
    fontStyle: "normal",
    fontSize: "20px",
    color: "#023365",
    marginTop: "20px",
  },
  textValidationRequest: {
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: "15px",
    color: "#023365",
    marginTop: "20px",
  },
  buttonValidation: {
    marginTop: "30px",
  },
  resultImage: {
    width: "300px",
    marginTop: 25,
    marginBottom: 20,
  },
});
