import React, { useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { BranchEntity } from "./BranchEntity/BranchEntity";
import { CustomerEntity } from "./CustomerEntity/CustomerEntity";
import { OwnerEntity } from "./OwnerEntity/OwnerEntity";
import { useStyles } from "./EntityStyles/EntityStyles";
import { EntityNameEnum } from "../../shared/enums/EntityNameEnum";
import { MerchantNodeData } from "../../../types/search_merchant_response";
import { useAppDispatch, useAppSelector } from "../../store/hooks/storeHook";
import { RootState } from "../../store/store";
import { searchMerchantNodeInfo } from "../../store/thunks/mainContainer.thunk";
import CloseIcon from "@mui/icons-material/Close";
import { defaultTo, get, isNil } from "lodash";
import { searchMerchantByNode } from "../../store/thunks/customer/searchMerchants.thunk";
import { ModalFamilySkeleton } from "../Skeleton/ModalFamilySkeleton/ModalFamilySkeleton";
import { VerFamilia } from "../../shared/constants/labels/modal_family_labels";

export interface IModalFamily {
  open: boolean;
  handleClose: () => void;
  entityName: EntityNameEnum;
  ownerData: MerchantNodeData;
  customerData: MerchantNodeData;
  nodeDataSelected: MerchantNodeData;
  path: string;
  isLoadingMerchant: boolean;
}

export const ModalFamily: React.FC<IModalFamily> = ({
  entityName,
  ownerData,
  customerData,
  path,
  open,
  handleClose,
  nodeDataSelected,
  isLoadingMerchant,
}: IModalFamily) => {
  const { searchData, isLoadingMerchantNodeInfo } = useAppSelector(
    (state: RootState) => state.mainContainer
  );
  const dispatch = useAppDispatch();
  const styles = useStyles();

  const customerValidation = () =>
    defaultTo(customerData.entity_name, get(customerData, "entityName")) ===
    EntityNameEnum.CUSTOMER;

  useEffect(() => {
    if (open) {
      dispatch(
        searchMerchantNodeInfo({
          entityName: EntityNameEnum.BRANCH,
          limit: 3,
          offset: 0,
          path: !isNil(get(customerData, "entityName"))
            ? customerData.path!
            : path,
          strictSearch: false,
        })
      );
      dispatch(
        searchMerchantByNode({
          nodeId: get(nodeDataSelected, "path", "").split(":")[1],
        })
      );
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: styles.paper }}
    >
      <DialogTitle sx={{ padding: "40px" }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography className={styles.titleModal} variant="inherit">
            {VerFamilia}
          </Typography>
          <Box
            data-testid="close-modalFamily"
            onClick={handleClose}
            className={styles.closeButton}
          >
            <CloseIcon fontSize="small" />
          </Box>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ padding: "40px" }}>
        {isLoadingMerchant ? (
          <>
            <ModalFamilySkeleton entityName={EntityNameEnum.OWNER} />
            <ModalFamilySkeleton entityName={EntityNameEnum.CUSTOMER} />
          </>
        ) : (
          <>
            <OwnerEntity
              selected={entityName === EntityNameEnum.OWNER}
              owner={ownerData}
            />
            {customerValidation() && (
              <CustomerEntity
                selected={entityName === EntityNameEnum.CUSTOMER}
                customer={customerData}
              />
            )}
          </>
        )}
        {isLoadingMerchantNodeInfo ? (
          <ModalFamilySkeleton entityName={EntityNameEnum.CUSTOMER} />
        ) : (
          <BranchEntity
            nodeIdOwner={defaultTo(get(ownerData, "node_id"), "")}
            nodeIdCustomer={defaultTo(
              get(customerData, "node_id"),
              get(customerData, "nodeId")
            )}
            data={defaultTo(get(searchData, "data"), [])}
            total={defaultTo(get(searchData, "total"), 0)}
            selected={entityName === EntityNameEnum.BRANCH}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
