import { useEffect } from "react";
import { setSortOrder } from "../../../store/actions/filters/filters";
import {
  deselectAllCurrentTransactions,
  selectAllCurrentTransactions,
} from "../../../store/actions/transactions/transactions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/hooks";
import { selectFiltersState } from "../../../store/selectors/filters/filters";
import {
  selectAreAllCurrentTransactionsSelected,
  selectSelectedTransactionsIDs,
  selectTransactions,
  selectTransactionsLoading,
} from "../../../store/selectors/transactions/transactions";
import getTransactions from "../../../store/thunks/transactions/getTransactions/getTransactions";
import { IUseTransactionsTableState } from "./useTransactionsTableState.interfaces";

export const useTransactionsTableState = (): IUseTransactionsTableState => {
  const dispatch = useAppDispatch();
  const filtersState = useAppSelector(selectFiltersState);
  const transactions = useAppSelector(selectTransactions);
  const areTransactionsLoading = useAppSelector(selectTransactionsLoading);
  const selectedTransactionsIDs = useAppSelector(selectSelectedTransactionsIDs);
  const areAllCurrentTransactionsSelected = useAppSelector(
    selectAreAllCurrentTransactionsSelected
  );

  const handleOnChangeSelection = () => {
    if (areAllCurrentTransactionsSelected) {
      dispatch(deselectAllCurrentTransactions());

      return;
    }
    dispatch(selectAllCurrentTransactions());
  };

  const handleOnClickSortDate = () => {
    dispatch(setSortOrder());
  };

  useEffect(() => {
    dispatch(getTransactions(filtersState));
  }, [
    filtersState.general.paymentMethod,
    filtersState.general.transactionStatus,
    filtersState.date.from,
    filtersState.date.to,
    filtersState.search.text,
    filtersState.pagination.limit,
    filtersState.pagination.page,
    filtersState.sortOrder,
  ]);

  return {
    areAllCurrentTransactionsSelected,
    areTransactionsLoading,
    handleOnChangeSelection,
    handleOnClickSortDate,
    selectedTransactionsIDs,
    transactions,
  };
};
