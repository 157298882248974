export const DROPDOWN_TITLE = "Cuenta bancaria";
export const DROPDOWN_PRINCIPAL = "PRINCIPAL";
export const DROPDOWN_SECONDARY = "ALTERNO";
export const DROPDOWN_ACCOUNT_NUMBER_LABEL = "Número de la cuenta bancaria:";
export const DROPDOWN_ACCOUNT_ORIGIN = "País de origen de la cuenta bancaria:";
export const DROPDOWN_BENEFICIARY_NAME = "Nombre del beneficiario:";
export const DROPDOWN_FINANCIAL_INSTITUTION_NAME =
  "Nombre de la institución financiera:";
export const DROPDOWN_FINANCIAL_INSTITUTION_NATIONAL =
  "Nombre de la institución financiera nacional:";
export const DROPDOWN_FINANCIAL_INSTITUTION_ADDRESS =
  "Dirección de la institución financiera:";
export const DROPDOWN_ACCOUNT_TYPE = "Tipo de cuenta bancaria:";
export const DROPDOWN_KEY_ACCOUNT_NUMBER = "Número de la cuenta CLABE:";
export const DROPDOWN_CURRENCY_TYPE = "Tipo de moneda:";
export const DROPDOWN_PRINCIPAL_COLOR = "success";
export const DROPDOWN_SECONDARY_COLOR = "info";
export const DROPDOWN_DESTINY_CURRENCY = "Moneda de la cuenta de destino";
export const DROPDOWN_ABA_CODE = "Código ABA";
export const DROPDOWN_ROUTING_NUMBER = "Número de enrutamiento:";
export const DROPDOWN_SWIFT_CODE = "Código SWIFT";
export const DROPDOWN_IBAN_CODE = "Código IBAN";
