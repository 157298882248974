export enum ErrorMessageContactDataMX {
  COMMERCIAL_EXECUTIVE = "Por favor, selecciona el ejecutivo de Kushki.",
  MERCHANT_SIZE = "Por favor, selecciona la categorización",
  EMAIL_VALIDATION = "Por favor, introduce un correo electrónico válido",
  FIELD_REQUIRED = "Campo Obligatorio",
  REPEATED_NAME = "Ya existe un dato de contacto con este nombre. Por favor, utiliza una información diferente",
  EMAIL_REQUIRED = "Por favor, introduce un correo electrónico",
  NAME_REQUIRED = "Por favor, ingresa el nombre(s) y apellido(s)",
  NAME_CORRECT = "Por favor, ingresa el nombre(s) y apellido(s)",
  NAME_MESSAGE = "Debe tener entre 1 y 50 caracteres alfanuméricos",
  BILLING_AREA = "Por favor, selecciona al menos un Área de Facturación",
}
