import { defaultTo, get, includes } from "lodash";
import { useMemo, useRef, useState } from "react";
import { routes } from "../../infrastructure/constants/routes";
import { IUseProcessorComponentsState } from "../../infrastructure/interfaces/ProcessorComponents";
import { OriginEnum } from "../../infrastructure/constants/OriginEnum";
import { EntityNameEnum } from "../../infrastructure/constants/EntityNameEnum";
import { LocalStoragePropertiesEnum } from "../../infrastructure/constants/LocalStoragePropertiesEnum";
import { useLocation } from "react-router-dom";
import { CountryEnum } from "../../infrastructure/constants/CountryEnum";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const useProcessorComponentsState = (): IUseProcessorComponentsState => {
  const basicInformation = useRef(
    JSON.parse(
      defaultTo(
        localStorage.getItem(LocalStoragePropertiesEnum.MERCHANT_BASIC_INFO),
        "{}"
      )
    )
  );
  const country = get(basicInformation, "current.country", "");
  const merchantIdParam = useQuery().get("merchantId");

  const [isAddButton, setIsAddButton] = useState<boolean>(true);
  const [isConfigButton, setIsConfigButton] = useState<boolean>(true);
  const [isActionButton, setIsActionButton] = useState<boolean>(true);
  const [isBackButton, setIsBackButton] = useState<boolean>(false);

  const [isBreadcrumb, setIsBreadcrumb] = useState<boolean>(false);
  const [backButtonText, setBackButtonText] = useState<string>("");
  const [merchantRoute, setMerchantRoute] = useState<string>("");

  const isCountryIncluded = [CountryEnum.BRAZIL].includes(
    country as CountryEnum
  );

  const buildMerchantRoute = (
    publicMerchantId: string,
    origin: string,
    entityName?: string
  ): void => {
    let mode: string = "";

    if (entityName === EntityNameEnum.CUSTOMER) {
      mode = "&mode=edition";
    }

    switch (origin) {
      case OriginEnum.CREATE_MERCHANT_V3:
        setMerchantRoute(
          `${routes.MERCHANT_PROCESSING}?menuItem=true&publicMerchantId=${merchantIdParam}`
        );
        return;
      case OriginEnum.MERCHANT_RESUME:
        setMerchantRoute(
          `${routes.MERCHANT_RESUME}?publicMerchantId=${merchantIdParam}${mode}`
        );
        return;
      case OriginEnum.BATCH_PROCESSING:
        setMerchantRoute(`${routes.BATCH_PROCESSING}?menuItem=true`);
        return;
      default:
        setMerchantRoute(
          `${routes.CREATE_MERCHANT}?publicMerchantId=${publicMerchantId}&hideSideBar=true`
        );
        return;
    }
  };

  const isCustomerOrBranch = (entityName: string): boolean => {
    return (
      entityName === EntityNameEnum.CUSTOMER ||
      entityName === EntityNameEnum.BRANCH
    );
  };

  const isEditOrCreate = (origin: string): boolean => {
    return includes(
      [
        OriginEnum.MERCHANT_RESUME,
        OriginEnum.CREATE_MERCHANT_V3,
        OriginEnum.BATCH_PROCESSING,
      ],
      origin
    );
  };

  const buildRenderButtons = (
    entityName: string,
    origin: string,
    isCentralized: boolean
  ): void => {
    if (
      isCustomerOrBranch(entityName) &&
      isEditOrCreate(origin) &&
      !isCentralized
    ) {
      setIsBackButton(true);
    } else if (
      entityName === EntityNameEnum.CUSTOMER &&
      isEditOrCreate(origin)
    ) {
      setIsBackButton(true);
    } else if (
      entityName === EntityNameEnum.BRANCH &&
      isEditOrCreate(origin) &&
      isCentralized
    ) {
      setIsAddButton(false);
      setIsConfigButton(false);
      setIsActionButton(false);
      setIsBackButton(true);
    }
  };

  const buildComponents = (entityName: string, origin: string): void => {
    if (isCustomerOrBranch(entityName) && isEditOrCreate(origin)) {
      setBackButtonText("Ver Resumen");
      setIsBreadcrumb(false);
    } else {
      setBackButtonText("Volver a la creación de comercio");
      setIsBreadcrumb(true);
    }
  };

  const buildProcessorComponents = (): void => {
    const entityName: string = get(basicInformation, "current.entityName", "");
    const origin: string = get(basicInformation, "current.origin", "");
    const isCentralized: boolean = get(
      basicInformation,
      "current.isCentralized",
      false
    );
    const publicMerchantId: string = get(
      basicInformation,
      "current.publicMerchantId",
      ""
    );

    buildMerchantRoute(publicMerchantId, origin, entityName);
    buildRenderButtons(entityName, origin, isCentralized);
    buildComponents(entityName, origin);
  };

  useMemo(() => buildProcessorComponents(), [basicInformation]);

  return {
    backButtonText,
    merchantRoute,
    isAddButton,
    isActionButton,
    isConfigButton,
    isBackButton,
    isBreadcrumb,
    isCountryIncluded,
  };
};
