import { Box } from "@mui/material";
import React, { ReactNode } from "react";
import { useStyles } from "../EntityStyles/EntityStyles";

export type ICardEntity = {
  children: ReactNode;
  selected: boolean;
};

export const CardEntityName: React.FC<ICardEntity> = ({
  selected,
  children,
}: ICardEntity) => {
  const styles = useStyles();
  const cardEntityStyle = selected
    ? styles.cardEntitySelected
    : styles.cardEntity;

  return <Box className={cardEntityStyle}>{children}</Box>;
};
