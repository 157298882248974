import { IStyles } from "../../shared/interfaces/create_named_styles";

export const headerCardStyles: IStyles = {
  breadCrumb: {
    "nav ol li span": {
      color: "primary.main",
    },
  },
  containerCard: {
    paddingTop: "20px",
  },
  containerTitle: {
    fontSize: "40px!important",
    fontWeight: "200",
    marginBottom: "24px",
  },
};
