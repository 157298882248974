import { ITableColumn } from "../interfaces/Table.interfaces";

export const REFUNDS_TABLE_COLUMNS: ITableColumn[] = [
  {
    id: "date",
    label: "F. de inicio",
    padding: "normal",
    sortable: true,
    subId: "hour",
    type: "text",
  },
  {
    id: "merchantName",
    label: "Comercios",
    padding: "normal",
    sortable: false,
    type: "text",
  },
  {
    id: "originalTransactionReference",
    label: "Referencia de la transacción original",
    padding: "normal",
    sortable: false,
    type: "text",
  },
  {
    id: "transactionAmount",
    label: "Monto total",
    padding: "normal",
    sortable: false,
    type: "text",
  },
  {
    id: "aproved",
    label: "Aprobar",
    padding: "checkbox",
    sortable: false,
    type: "checkbox",
  },
];
