import React from "react";
import { Box, Container } from "@material-ui/core";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { WebhookTable } from "../../components/WebhookTable/WebhookTable";
import { Webhook, WebhookResponse } from "../../../types/webhook_response";
import { IAppState } from "../../store/reducer";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  deleteWebhook,
  getMerchantInfo,
  getNodeInfo,
  getWebhookList,
  getWebhookSignature,
  IAppAction,
  setNotification,
  setWebhookSelected,
  testConnectionWebhook,
} from "../../store/actionCreators";
import { connect } from "react-redux";
import { useWebhookList } from "./state/useWebhookList";
import { WebhookListRequest } from "../../../types/webhook_list_request";
import { ConfirmMessage } from "../../components/ConfirmMessage/ConfirmMessage";
import { ModalTestConnection } from "../../components/ModalTestConnection/ModalTestConnection";
import { SkeletonWebhookList } from "../../components/SkeletonWebhookList/SkeletonWebhookList";
import { INotification } from "../../shared/infrastructure/interfaces/INotification";
import { SnackBarAlert } from "../../components/Snackbar/SnackBarAlert";
import { ModalOverlay } from "@kushki/frontend-molecules/modal";
import { HeaderDetail } from "../../components/WebhookDetail/HeaderDetail";
import { BodyDetail } from "../../components/WebhookDetail/BodyDetail";
import { FooterDetail } from "../../components/WebhookDetail/FooterDetail";

export interface IWebhookIndexStateProps {
  webhookList: WebhookResponse;
  notification: INotification;
  webhookSignature: string;
}

export interface IWebhookIndexFunctionsProps {
  getWebhookList: (listRequest: WebhookListRequest) => void;
  deleteWebhook: (webhookId: string) => void;
  testConnection: (bodyTestConn: {
    service: string;
    kindTransaction: string;
    body: string;
    urls: string[];
  }) => void;
  setWebhookSelected: (webhookSelected: Webhook) => void;
  setNotification: (notification: INotification) => void;
  getMerchantInfo: (publicMerchantId: string) => void;
  getWebhookSignature: (publicMerchantId: string) => void;
  getNodeInfo: (publicMerchantId: string) => void;
}

export type WebhookIndexProps = IWebhookIndexStateProps &
  IWebhookIndexFunctionsProps;

export const WebhookIndex: React.FC<WebhookIndexProps> = (
  props: WebhookIndexProps
) => {
  const {
    webhookList,
    handler,
    pagination,
    confirmMessage,
    modalTestConnection,
    breadcrumbs,
    merchantName,
    merchantId,
    isAdmin,
    snackbar,
    hasLinkAlertNotification,
    webhookSelected,
    openDetail,
    merchantStatusIsPending,
    enableEdit,
  } = useWebhookList(props);

  return (
    <Container>
      <SnackBarAlert
        msg={snackbar.message}
        open={snackbar.open}
        type={snackbar.type}
        handlerClose={handler.handlerClose}
        hasLink={hasLinkAlertNotification}
      />
      <Box pb={3}>
        <PageHeader
          isAdmin={isAdmin}
          title={"Webhooks"}
          isWebhookList={true}
          breadcrumbs={breadcrumbs}
          handleNotification={handler.handleOpenNotification}
          merchantId={merchantId}
          merchantName={merchantName}
          navigateCreate={handler.navigateCreate}
          webhookSignature={props.webhookSignature}
          returnCreateMerchant={merchantStatusIsPending}
        />
      </Box>
      {webhookList === undefined ? (
        <SkeletonWebhookList limit={pagination.currentPages} />
      ) : (
        <>
          <WebhookTable
            isAdmin={isAdmin}
            handleOpenTestConnection={handler.handleOpenTestConnection}
            webhookList={webhookList}
            handleOnDelete={handler.handleOnDelete}
            pagination={pagination}
            handleEdit={handler.handleEdit}
            handleOpenDetail={handler.handleOpenDetail}
            enableEdit={enableEdit}
          />
          <ModalTestConnection {...modalTestConnection} />
          <ConfirmMessage {...confirmMessage} />
          {webhookSelected ? (
            <ModalOverlay
              header={<HeaderDetail webhook={webhookSelected!} />}
              body={
                <BodyDetail
                  webhook={webhookSelected}
                  handleCopyTextMetadata={handler.handleCopyTextMetadata}
                />
              }
              footer={
                <FooterDetail
                  webhookSelected={webhookSelected}
                  handleEdit={handler.handleEdit}
                  handleOnDelete={handler.handleOnDelete}
                  enableEdit={enableEdit}
                />
              }
              openModal={openDetail}
              onCloseModal={handler.handleCloseDetail}
            />
          ) : null}
        </>
      )}
    </Container>
  );
};

export const mapStateToProps: (state: IAppState) => IWebhookIndexStateProps = (
  state: IAppState
): IWebhookIndexStateProps => ({
  webhookList: state.webhookList!,
  notification: state.notification!,
  webhookSignature: state.webhookSignature!,
});
export const mapDispatchToProps: (
  dispatch: Dispatch
) => IWebhookIndexFunctionsProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): IWebhookIndexFunctionsProps => ({
  getWebhookList: (listRequest: WebhookListRequest): void =>
    dispatch(getWebhookList(listRequest)),
  deleteWebhook: (webhookId: string): void =>
    dispatch(deleteWebhook(webhookId)),
  getMerchantInfo: (publicMerchantId: string): void =>
    dispatch(getMerchantInfo(publicMerchantId)),
  testConnection: (bodyTestConn: {
    service: string;
    kindTransaction: string;
    body: string;
    urls: string[];
  }) => dispatch(testConnectionWebhook(bodyTestConn)),
  setWebhookSelected: (webhookSelected: Webhook) =>
    dispatch(setWebhookSelected(webhookSelected)),
  setNotification: (notification: INotification) =>
    dispatch(setNotification(notification)),
  getWebhookSignature: (publicMerchantId: string) =>
    dispatch(getWebhookSignature(publicMerchantId)),
  getNodeInfo: (publicMerchantId: string) =>
    dispatch(getNodeInfo(publicMerchantId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(WebhookIndex);
