import { HistoryIndexProps } from "../HistoryIndex";
import React, { useEffect, useState } from "react";
import { auth } from "../../../shared/auth";
import { DateRange } from "@material-ui/pickers";
import { IMerchantsResponse } from "../../../store/reducer";
import { MerchantForm } from "../../../../types/merchant_form";
import { useMediaQuery } from "@material-ui/core";
import { PaginationTableProps } from "../../../components/ListHistory/Pagination/PaginationTable";
import { get } from "lodash";
import { utcToZonedTime } from "date-fns-tz";
import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format,
  set,
  sub,
} from "date-fns";
import {
  ICatalog,
  TimeZoneCatalog,
} from "../../../shared/infrastructure/catalogues/time-zone-catalogues";
import { useHistory } from "react-router-dom";
import { routes } from "../../../shared/infrastructure/constants/routes";
import { RequestQuerySearch } from "../../../../types/request_query_search";

const _timeZoneDefault: string = "America/Guayaquil";

export interface IHistoryIndexState {
  data: {
    historyData: object[] | undefined;
  };
  state: {
    isAdmin: boolean;
    isLoading: boolean;
    isEmptyHistory: boolean;
    timeZoneRegion: string;
    handlerReturnPageSupport: () => void;
  };
  filters: {
    autocomplete: {
      handleChargeMerchants: (
        _event: unknown,
        value: string,
        _reason: unknown
      ) => void;
      handleChangeMerchant: (
        _event: object,
        value: { _source: MerchantForm }[],
        _reason: string
      ) => void;
      merchants: IMerchantsResponse | undefined;
    };
    filterComponent: {
      text: string;
      filters: IOptionFilter[];
      isMobile: boolean;
      onApplyFilter: (
        search: { filter: object },
        filters: IOptionFilter[],
        text: string
      ) => void;
    };
    handleDate: (dateRange: DateRange) => void;
    selectedDate: DateRange;
  };
  pagination: PaginationTableProps;
}

export interface IState {
  isAdmin: boolean;
  isLoading: boolean;
  isEmptyHistory: boolean;
  multipleMerchant: { name: string; id?: string }[];
  filters: IOptionFilter[];
  sessionData: object[];
  text: string;
  timeZoneRegion: string;
}

export interface ISearch {
  offset: number;
  limit: number;
  from: string;
  to: string;
  publicMerchantId: string;
  text: string;
  filter: object;
  timeZone: string;
  userType: string;
}

export interface IOptionFilter {
  id: string;
  title: string;
  options: { key: string; label: string; selected: boolean }[];
}

export const defaultFilters: IOptionFilter[] = [
  {
    id: "codeDuration",
    title: "Duración código",
    options: [
      { key: "30m", label: "30 minutos", selected: false },
      { key: "1h", label: "1 hora", selected: false },
      { key: "2h", label: "2 horas", selected: false },
      { key: "4h", label: "4 horas", selected: false },
    ],
  },
];

export function validateDate(date: string, timeZone: string): string {
  return format(utcToZonedTime(date, timeZone), "yyyy-MM-dd'T'HH:mm:ss");
}

export function timeConnection(start: string, end: string): string {
  return `${differenceInHours(
    new Date(start),
    new Date(end)
  )}:${differenceInMinutes(
    new Date(start),
    new Date(end)
  )}:${differenceInSeconds(new Date(start), new Date(end))}`;
}

export const timeZoneHour = (
  text: string | undefined,
  value: string
): string => {
  let index: number;

  if (text === undefined) return value === "utc" ? "-05:00" : _timeZoneDefault;

  index = TimeZoneCatalog.findIndex(
    (data: ICatalog) => data.description === text
  );

  if (index >= 0) {
    const lang: string | undefined = get(
      TimeZoneCatalog[index],
      value,
      undefined
    );

    return lang !== undefined
      ? lang
      : value === "utc"
      ? "-05:00"
      : _timeZoneDefault;
  }

  return value === "utc" ? "-05:00" : _timeZoneDefault;
};

export const useHistoryIndexState = (
  props: HistoryIndexProps
): IHistoryIndexState => {
  const [state, setState] = React.useState<IState>({
    isAdmin: auth.isAdmin(),
    isLoading: true,
    isEmptyHistory: false,
    multipleMerchant: [],
    filters: defaultFilters,
    sessionData: [],
    text: "",
    timeZoneRegion: auth.getTimeZone("region"),
  });
  const [search, setSearch] = React.useState<ISearch>({
    from: format(
      set(sub(new Date(), { days: 1 }), { hours: 0, minutes: 0, seconds: 0 }),
      "yyyy-MM-dd'T'HH:mm:ss"
    ),
    to: format(
      set(new Date(), { hours: 23, minutes: 59, seconds: 59 }),
      "yyyy-MM-dd'T'HH:mm:ss"
    ),
    offset: 0,
    limit: 10,
    publicMerchantId: auth.publicMerchantId(),
    text: "",
    filter: {
      codeDuration: "",
    },
    timeZone: auth.getTimeZone("utc"),
    userType: auth.isAdmin() ? "admin" : "merchant",
  });
  const [pagination, setPagination] = useState<PaginationTableProps>({
    limit: 10,
    page: 1,
    total: 0,
    isMobile: false,
    handleChangePage: () => {},
    handleChangeLimit: () => {},
    trxPerPage: 0,
  });
  // date picker
  const [selectedDate, handleDateChange] = useState<DateRange>([
    set(sub(new Date(), { days: 1 }), { hours: 0, minutes: 0, seconds: 0 }),
    set(new Date(), { hours: 23, minutes: 59, seconds: 59 }),
  ]);
  const [refTimeOut, setRefTimeOut] = React.useState<NodeJS.Timeout>(
    setTimeout(() => {}, 0)
  );
  const isMobile = useMediaQuery("(max-width:769px)");
  const history = useHistory();
  useEffect(() => {
    searchTrxList();
  }, [search]);
  useEffect(() => {
    if (
      props.state.historyData!.data !== undefined &&
      props.state.historyData!.data !== null
    ) {
      if (props.state.historyData!.data.length !== 0) {
        setState({
          ...state,
          isLoading: false,
          sessionData: props.state.historyData!.data,
        });
        setPagination({
          ...pagination,
          total: get(props, "state.historyData.total", 0),
        });
      } else {
        setState({
          ...state,
          isEmptyHistory: true,
          isLoading: false,
        });
      }
    }
  }, [props.state.historyData]);
  useEffect(() => {
    const [firstDate, endDate]: [Date | null, Date | null] = selectedDate;
    if (firstDate === null || endDate === null) return;
    const start: string = format(firstDate, "yyyy-MM-dd'T'HH:mm:ss");
    const end: string = format(endDate, "yyyy-MM-dd'T'HH:mm:ss");

    setSearch({
      ...search,
      from: start,
      to: end,
    });
    setPagination({
      ...pagination,
      page: 1,
    });
    setState({
      ...state,
      isLoading: true,
    });
  }, [selectedDate]);

  function searchTrxList(): void {
    const request: RequestQuerySearch = Object.assign({}, search);

    setState({ ...state, isLoading: true, isEmptyHistory: false });
    props.getHistorySessionData(request);
  }

  const handleDate = (date: DateRange | MouseEvent): void => {
    handleDateChange(date as [Date, Date]);
  };

  const handleChargeMerchants = (
    _event: unknown,
    value: string,
    _reason: unknown
  ): void => {
    clearTimeout(refTimeOut);
    const time_out: NodeJS.Timeout = setTimeout(() => {
      props.getMerchants({
        offset: 0,
        text: value,
      });
    }, 2000);
    setRefTimeOut(time_out);
  };

  const handleChangeMerchant = (
    _event: object,
    value: { _source: MerchantForm }[],
    _reason: string
  ) => {
    const multiple_public_merchant: { name: string; id?: string }[] = value.map(
      (merchant: { _source: MerchantForm }) => ({
        name: merchant._source.name,
        id: merchant._source.publicMerchantId,
      })
    );
    const multiple_public_merchant_id: string = multiple_public_merchant
      .map((merchant: { name: string; id?: string }) => merchant.id)
      .join("|");
    setState({ ...state, multipleMerchant: multiple_public_merchant });
    setSearch({
      ...search,
      publicMerchantId: multiple_public_merchant_id,
      offset: 0,
    });
    setPagination({
      ...pagination,
      page: 1,
    });
  };

  const handleChangePage = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setState({
      ...state,
      isLoading: true,
    });
    setPagination({
      ...pagination,
      page: value,
    });
    setSearch({
      ...search,
      offset: (value - 1) * 10,
    });
  };

  const handleChangeLimit = (_event: React.ChangeEvent<unknown>) => {
    setPagination({
      ...pagination,
      limit: Number(get(_event, "target.value")),
      page: 1,
    });
    setSearch({
      ...search,
      limit: Number(get(_event, "target.value")),
      offset: 0,
    });
  };

  //Methods for popover filters
  const handleSearchFilter = (
    filters: { filter: object },
    filtersChips: IOptionFilter[],
    text: string
  ) => {
    setState({ ...state, text: text, filters: filtersChips });
    setSearch({
      ...search,
      text: text,
      filter: filters.filter,
      offset: 0,
    });
    setPagination({
      ...pagination,
      page: 1,
    });
  };

  const handlerReturnPageSupport = () => {
    handleSearchFilter({ filter: {} }, defaultFilters, "");
    history.push(routes.SUPPORT_INDEX);
  };
  return {
    data: {
      historyData: state.sessionData,
    },
    state: {
      isAdmin: state.isAdmin,
      isLoading: state.isLoading,
      isEmptyHistory: state.isEmptyHistory,
      timeZoneRegion: state.timeZoneRegion,
      handlerReturnPageSupport,
    },
    filters: {
      autocomplete: {
        handleChangeMerchant,
        handleChargeMerchants,
        merchants: props.state.merchants,
      },
      filterComponent: {
        text: state.text,
        isMobile,
        onApplyFilter: handleSearchFilter,
        filters: state.filters,
      },
      handleDate: handleDate,
      selectedDate: selectedDate,
    },
    pagination: {
      ...pagination,
      handleChangePage: handleChangePage,
      handleChangeLimit: handleChangeLimit,
      trxPerPage: state.sessionData.length,
    },
  };
};
