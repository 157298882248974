export enum LegalRepresentativeTypeEnum {
  main = "main",
  secundary = "secundary",
}

export enum LegalRepresentativeEnum {
  MAIN = "Principal",
  ALTERNATE = "Alterno",
}

export enum TypeLegalRepresentative {
  LEGAL = "Representante Legal",
}
