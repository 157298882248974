import { Reducer, useReducer } from "react";
import { IFilters } from "../FilterBox.interfaces";
import { clearFilterChips } from "../utils/functions";
import { IAction, UseChipState } from "./useChipState.interfaces";

/* istanbul ignore next */
const reducer: Reducer<IFilters[], IAction> = (state, action) => {
  switch (action.type) {
    case "chip_clicked":
      return state.map((f) => {
        if (f.title !== action.filterTitle) {
          return f;
        }

        const newOptions = f.options.map((o) => {
          if (o.label !== action.optionLabel) {
            return o;
          }

          return {
            ...o,
            selected: !o.selected,
          };
        });

        return {
          ...f,
          options: newOptions,
        };
      });
    default:
      return state;
  }
};

const useChipState: UseChipState = (initialState) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleChipClicked = (filterTitle: string, optionLabel: string) =>
    dispatch({ filterTitle, optionLabel, type: "chip_clicked" });

  const clearAllChips = () => clearFilterChips(state);

  return { clearAllChips, handleChipClicked, internalFilters: state };
};

export default useChipState;
