import { Checkbox, FormControlLabel, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC, PropsWithChildren } from "react";
import { FLEX_JUSTIFY_BY_ALIGN } from "../../Table/TableBodySimple/constants";
import { ICellCheckProps } from "./CellCheck.interfaces";
import { cellCheckStyles } from "./CellCheck.styles";
import useCellCheck from "./useCellCheck";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";

export const CellCheckItem: FC<ICellCheckProps> = (
  props: PropsWithChildren<ICellCheckProps>
) => {
  const { isChecked, isDisabled, label } = props;
  const {
    handler: { handleChange },
    isLoading,
  } = useCellCheck(props);

  return (
    <Box
      flexDirection={"column"}
      display={"flex"}
      alignItems={FLEX_JUSTIFY_BY_ALIGN[props.cellProps.align || "center"]}
      justifyContent={"center"}
    >
      {isLoading ? (
        <Skeleton
          data-testid={"skeleton"}
          variant={"rectangular"}
          sx={cellCheckStyles.skeletonIcon}
        />
      ) : (
        <FormControlLabel
          label={label ? (isChecked ? label.check : label.unCheck) : ""}
          control={
            <Checkbox
              onClick={(e) => e.stopPropagation()}
              checkedIcon={<CheckBoxOutlinedIcon />}
              disabled={isDisabled}
              checked={isChecked}
              onChange={handleChange}
            />
          }
        />
      )}
    </Box>
  );
};
