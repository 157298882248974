/* istanbul ignore file */
import { Color } from "@material-ui/lab";
import { BreadcrumItem } from "../../../components/Breadcrumb/Breadcrumb";
import { defaultTo, get } from "lodash";

export enum RulesNotificationResultEnum {
  RULES_REQUEST_SUCCESS = "RULES_REQUEST_SUCCESS",
  RULES_REQUEST_ERROR = "RULES_REQUEST_ERROR",
  VALIDATION_ERROR = "VALIDATION_ERROR",
  NO_ACTION = "NO_ACTION",
  DEFAULT_MESSAGE_ERROR_GET_RULES = "Error en la obtención de las reglas.",
  DEFAULT_MESSAGE_MIGRATE_RULES = "Error en la migración de las reglas",
  SUCCESS = "SUCCESS",
  RULE_DELETE = "Regla eliminada.",
  RULE_REQUEST_MIGRATE_SUCCESS = "Reglas migradas con éxito",
}

export interface IOptionSelection {
  name: string;
  value: string;
}

export interface IOptionImageSelection {
  name: string;
  value: string;
  image: string;
  valid?: boolean;
}

export interface INotification {
  open: boolean;
  message: string;
  type: Color;
  action: RulesNotificationResultEnum;
  metadata?: object;
}

export enum TitleSection {
  BUSINESS_RULES_CONFIGURATION = "Configuración de la regla",
  BUSINESS_RULES_CONFIGURATION_ACTION = "Acción General",
  BUSINESS_RULES_CONFIGURATION_CONDITIONS = "Condiciones",
  BUSINESS_RULES_INFORMATION = "Datos Generales",
  BUSINESS_RULES = "Reglas de negocio",
  ADD_BUSINESS_RULES = "Agregar regla de negocio",
}

export enum DescriptionSection {
  BUSINESS_RULES_CONFIGURATION = "Configura la información básica de la regla a crear",
  BUSINESS_RULES_INFORMATION = "Configura la información básica de la regla a crear",
  BUSINESS_RULES_GENERAL_INFORMATION = "Genera un alias que te permita identificar la regla",
}

export enum LabelField {
  ALIAS = "Alias",
  CODE = "Código",
  MESSAGE = "Mensaje",
  SAVE_RULE = "Agregar regla",
  RETURN = "Regresar",
  MERCHANTS = "Comercios",
  ADD_RULE = "Agregar regla",
  CONFIGURATION = "Configuración",
  TRANSACTION_PER_PAGE = "Transacciones por página",
  DEFAULT_PROCESSOR = "Configuración de procesador por defecto",
  SELECT_PROCESSOR = "Seleccionar procesador",
  DELETE_RULE_MESSAGE = "Si eliminas esta regla, las condiciones configuradas dejarán de tener efecto en las transacciones.",
  DELETE_RULE_TITLE = "¿Deseas eliminar esta regla?",
  PROCESSOR_CARD = "Tarjeta",
  PROCESSOR_TRANSFER = "Transfer In",
  PROCESSOR_ACH = "Transfer Subscriptions",
  ADD_WHITELIST = "Agregar Lista Blanca",
  RETURN_CREATE_COMMERCE = "Volver a la creación de comercio",
}

export enum ButtonText {
  DELETE_RULE = "Eliminar regla",
  CANCEL = "Cancelar",
  SAVE = "Guardar",
  EDIT = "Editar regla",
  ENABLE = "Habilitar",
  DISABLE = "Deshabilitar",
  DELETE = "Eliminar",
}

export enum Countries {
  ECUADOR = "Ecuador",
  COLOMBIA = "Colombia",
  PERU = "Peru",
  CHILE = "Chile",
  MEXICO = "Mexico",
  BRAZIL = "Brazil",
}

export enum CurrencyEnum {
  USD = "USD",
  COP = "COP",
  CLP = "CLP",
  UF = "UF",
  PEN = "PEN",
  MXN = "MXN",
  BRL = "BRL",
}

export enum RuleState {
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
  PENDING = "PENDING",
}

export enum RuleType {
  OTP = "otp",
  THREEDS = "3dsecure",
}

export enum MessageDisabledIconDelete {
  DELETE = "No se puede eliminar una regla de autenticación",
}

export enum MessageDisabledIconEdit {
  DISABLED = "La regla se encuentra deshabilitada",
  PENDING = "La regla se encuentra en proceso de actualización",
}

export enum RuleSchemaEnum {
  transactionBank = "transactionBank",
  kind = "kind",
  transactionBrand = "transactionBrand",
  transactionIsDeferred = "transactionIsDeferred",
  transactionProcessor = "transactionProcessor",
  transactionCountry = "transactionCountry",
  transactionTotalAmount = "transactionTotalAmount",
  transactionIP = "transactionIP",
  transactionType = "transactionType",
  transactionCredential = "transactionCredential",
  transactionBIN = "transactionBIN",
  transactionIsCreditCard = "transactionIsCreditCard",
  transactionMaskedCreditCard = "transactionMaskedCreditCard",
  transactionCardIssuer = "transactionCardIssuer",
  transactionCurrency = "transactionCurrency",
  transactionCreated = "transactionCreated",
  transactionCardId = "transactionCardId",
  transactionStatusCount = "transactionStatusCount",
  transactionStatusSum = "transactionStatusSum",
  periodicityCount = "periodicityCount",
  periodicitySum = "periodicitySum",
  limit = "limit",
  maxAmount = "maxAmount",
}

export enum TabsTitle {
  COMERCIOS = "Comercio",
  PAISES = "Países",
  LISTA_BLANCA = "Lista blanca",
}

export enum TableTitle {
  CREADO = "Creado",
  ALIAS = "Alias",
  ACCION = "Acción",
  MEDIO_PAGO = "Medio de pago",
  NOMBRE_COMERCIO = "Nombre del comercio",
  ID_COMERCIO = "ID del Comercio",
  PAIS = "País",
  C_TARJETAS = "Cantidad de tarjetas",
}

export enum Titles {
  SECURITY_RULES = "Reglas de Seguridad",
}

export enum ButtonTitles {
  NEXT = "Siguiente",
  BACK = "Anterior",
  MIGRATE_RULES = "Migrar Reglas",
}

export enum AlertDialog {
  TITLE = "¿Desea eliminar esta regla?",
  BODY = "Si eliminas esta regla, las condiciones configuradas dejarán de tener efecto en las transacciones.",
}

export enum PathRoutes {
  SECURITY = "/rules/security/rule",
  WHITELIST = "/rules/whitelist/rule",
}

export enum Messages {
  MIGRATE_RULES_MESSAGE = "Antes de continuar, es necesario migrar las reglas de este comercio a la nueva versión",
}

export interface NextStepObject {
  path: string;
  buttonLabel: string;
}

export enum OriginEnum {
  createMerchant = "createMerchant",
  configMerchant = "configMerchant",
  rulesList = "rulesList",
}

export enum NextStepEnum {
  deferred = "deferred",
}

const NEXT_STEP: Record<string, NextStepObject> = {
  [NextStepEnum.deferred]: {
    path: "/deferred",
    buttonLabel: "Agregar Diferidos",
  },
};

export interface RedirectObject {
  redirectPath: string;
  redirectButtonLabel: string;
  breadcrumb: BreadcrumItem[];
  nextStepPath?: string;
  nextStepButtonLabel?: string;
  bodyDialog: string;
}

export const getRulesListInfo = (
  merchantId: string,
  merchantName: string
): RedirectObject => {
  return {
    redirectPath: `/rules/business/${merchantId}`,
    redirectButtonLabel: "Agregar diferidos",
    breadcrumb: [
      { label: LabelField.MERCHANTS, url: "/merchants-list" },
      {
        label: merchantName,
        url: `/config-merchant/merchant/${merchantId}`,
      },
      {
        label: "Reglas de negocio",
        url: `/rules/business/${merchantId}`,
      },
    ],
    bodyDialog: "",
  };
};

export const getMerchantInfo = (
  merchantId: string,
  merchantName: string,
  nextStep?: string
): Record<string, RedirectObject> => {
  const next_step_obj: NextStepObject | undefined =
    NEXT_STEP[defaultTo(nextStep, NextStepEnum.deferred)];

  return {
    [OriginEnum.createMerchant]: {
      redirectPath: `/create-merchant/details?publicMerchantId=${merchantId}&hideSideBar=true`,
      redirectButtonLabel: "Volver al formulario de creación",
      breadcrumb: [
        { label: LabelField.MERCHANTS, url: "/merchants-list" },
        {
          label: "Crear comercio",
          url: `/create-merchant/details?publicMerchantId=${merchantId}&hideSideBar=true`,
        },
      ],
      nextStepButtonLabel: get(next_step_obj, "buttonLabel"),
      nextStepPath: get(next_step_obj, "path"),
      bodyDialog:
        "Sugerimos que configures Diferidos para tu nuevo comercio, ¿qué quieres hacer?",
    },

    [OriginEnum.configMerchant]: {
      redirectPath: `/config-merchant/merchant/${merchantId}`,
      redirectButtonLabel: "Volver al panel de configuración",
      breadcrumb: [
        { label: LabelField.MERCHANTS, url: "/merchants-list" },
        {
          label: merchantName,
          url: `/config-merchant/merchant/${merchantId}`,
        },
      ],
      bodyDialog: "",
    },

    [OriginEnum.rulesList]: getRulesListInfo(merchantId, merchantName),
  };
};
