import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import React, { FC } from "react";

import { get } from "lodash";
import { IInputsFormProps } from "../IInputsFromProps.interfaces";
import { textInputStyles } from "./TextInput.style";

const TextInput: FC<IInputsFormProps> = ({
  name,
  control,
  controlName = name,
  errors,
  label,
  defaultValue = "",
  onBlur,
  isRequired,
  disabled,
  rules,
  regExp,
}: IInputsFormProps): JSX.Element => {
  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue as string}
      render={({ field }: any) => (
        <TextField
          {...field}
          sx={textInputStyles.error1}
          required={isRequired}
          label={label}
          variant="outlined"
          fullWidth
          error={get(errors, controlName) !== undefined}
          helperText={get(errors, controlName)?.message ?? ""}
          onBlurCapture={onBlur}
          disabled={disabled}
          onChange={(e) => {
            if (
              e.target.value === "" ||
              new RegExp(regExp!).test(e.target.value)
            )
              field.onChange(e.target.value);
          }}
        />
      )}
    />
  );
};

export default TextInput;
