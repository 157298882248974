import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { defaultTo, get } from "lodash";
import { API_ROUTES } from "../../../shared/constants/routes/api_routes";
import { LocalStoragePath } from "../../../shared/enums/local_storage_path";
import { setSocketMessage } from "../../actions/walletReport.action";
import { SocketStatus } from "../../../shared/enums/socket_props";
import { WalletReportTypeEnum } from "../../../shared/enums/wallet_report";
import { DownloadRequest } from "../../../../types/download_request";
import { CreateReportRequest } from "../../../../types/create_report_request";

export const generateReport = createAsyncThunk<boolean, CreateReportRequest>(
  "walletReport/generateReport",
  async (request: CreateReportRequest, { rejectWithValue }) => {
    try {
      return await axios
        .post<object>(API_ROUTES.CREATE_WALLET_REPORT, request)
        .then(() => true);
    } catch (error) {
      return rejectWithValue({ data: get(error, "response.data", "") });
    }
  }
);

export const socketMiddleware =
  (store: any) =>
  (next: any) =>
  (action: { request: DownloadRequest; type: string }) => {
    if (action.type === WalletReportTypeEnum.DOWNLOAD_REPORT) {
      try {
        const jwt: string = defaultTo(
          localStorage.getItem(LocalStoragePath.JWT),
          ""
        );
        const webSocket = new WebSocket(API_ROUTES.DOWNLOAD_WALLET_REPORT(jwt));

        webSocket.onopen = (): void => {
          webSocket.send(JSON.stringify(action.request));
        };

        webSocket.onerror = (): void => {
          store.dispatch(
            setSocketMessage({
              isError: true,
            })
          );
        };

        webSocket.onmessage = (event: MessageEvent) => {
          const response = JSON.parse(event.data);
          const isError: boolean = get(response, "payload.error", true);

          switch (isError) {
            case true:
              store.dispatch(
                setSocketMessage({
                  isError: true,
                })
              );
              break;
            case false:
              const status: string = get(response, "status", "");

              if (status === SocketStatus.COMPLETED_EMPTY) {
                store.dispatch(
                  setSocketMessage({
                    isError: true,
                  })
                );
                break;
              }
              if (status === SocketStatus.PROCESSING) {
                store.dispatch(
                  setSocketMessage({
                    isError: false,
                  })
                );
                break;
              } else {
                store.dispatch(
                  setSocketMessage({
                    isError: false,
                    url: get(response, "payload.url", ""),
                  })
                );
              }
              break;
          }
        };
      } finally {
      }
    }
    next(action);
  };
