/* eslint-disable */
export const environment: {
    production:boolean;
    envName: string;
    kushkiUrl: string;
    customerRoles: string;
    rollbarToken: string;
    firebaseConfig: {
        apiKey: string;
        authDomain: string;
        databaseURL: string;
        projectId: string;
        storageBucket: string;
        messagingSenderId: string;
    };
    disableDownloadMIDs: string;
} = {
    production:true,
    envName: "primary",
    kushkiUrl: "https://api.kushkipagos.com",
    customerRoles: "BackofficeMasterCustomer",
    rollbarToken: '221ca51e6f994910bd4b3b862f597c22',
    firebaseConfig: {
        apiKey:"AIzaSyAXtQlq6sXC3-cVMCUvwp6DUC1RteuT4VA",
        authDomain:"kushki-97eba.firebaseapp.com",
        databaseURL: "https://kushki-97eba.firebaseio.com",
        projectId: "kushki-97eba",
        storageBucket: "kushki-97eba.appspot.com",
        messagingSenderId: "821114948879"
    },
    disableDownloadMIDs: "20000000103018110000;20000000108308046000;1012311467587061847915161445539820000000106533736000;20000000106027920000;20000000104864790000;20000000102411346000;20000000100587360000;20000000105698470000;20000000101475852000;20000000105029198000;20000000106036660000;20000000102277136000;20000000101228143000;20000000100587360000;20000000100103905000;20000000106831330000;20000000106932030000;20000000107481883000;20000000105920537000;20000000104191980000;10123114697881639736151759761218;20000000101888710000",
};

