import React, { FC } from "react";
import { Box } from "@mui/material";
import TestModal from "../../components/TestModals";
import Filter from "../../components/Filter/Filter";
import {
  CatalogFilterEnum,
  FilterCatalog,
} from "../../shared/constants/labels/filter_labels";

const MainContainer: FC = () => {
  return (
    <Box>
      <div>centralization</div>
      <Filter
        data-testid={"basic_filter"}
        dataItems={FilterCatalog[CatalogFilterEnum.BASIC_CATALOG]}
        handleOnClick={() => {}}
      />
      <Filter
        data-testid={"complete_filter"}
        dataItems={FilterCatalog[CatalogFilterEnum.COMPLETE_CATALOG]}
        handleOnClick={() => {}}
      />
      <TestModal />
    </Box>
  );
};

export default MainContainer;
