import { refundsSlice } from "../../reducers/refunds/refunds";
export const {
  addSelectedRefund,
  clearSelectedRefunds,
  selectAllRefunds,
  setDateTimeFilter,
  setEmptyTable,
  setHistoricalRefunds,
  setIsAllSelectedRefunds,
  setLoadingMoreItems,
  setLoadingTable,
  setRefund,
  setRefunds,
  setTransactionReferenceFilter,
  toggleSortFilter,
  toggleSortHistoricalFilter,
  unSelectRefund,
} = refundsSlice.actions;
