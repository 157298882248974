import React from "react";
import { ISelectedNodesProps } from "@components/SelectedNodes/SelectedNodes.interfaces";
import { useSelectedNodes } from "@components/SelectedNodes/state/useSelectedNodes";
import { Box, IconButton, Typography } from "@mui/material";
import { selectedNodesStyles as styles } from "@components/SelectedNodes/SelectedNodes.styles";
import { ModeEnum } from "@shared/enum/modeEnum";
import { ActualViewEnum } from "@shared/enum/actualViewEnum";
import { IconCircleCross } from "@kushki/connect-ui";
const SelectedNodes: React.FC<ISelectedNodesProps> = ({
  selectedNodes,
  onClickNode,
  onClearNodes,
  showClearButton,
}: ISelectedNodesProps) => {
  const { customerLabel, ownerLabel, branchLabel, handleClickNode } =
    useSelectedNodes({
      onClearNodes,
      onClickNode,
      selectedNodes,
      showClearButton,
    });

  return (
    <Box sx={styles.container}>
      <Box
        sx={styles.wrapper}
        onClick={() => handleClickNode(ModeEnum.SELECTED, ActualViewEnum.OWNER)}
      >
        <Typography sx={styles.entityName}>Owner: </Typography>
        <Typography sx={styles.label}>{ownerLabel}</Typography>
      </Box>
      <Box
        sx={styles.wrapper}
        onClick={() =>
          handleClickNode(ModeEnum.SELECTED, ActualViewEnum.CUSTOMER)
        }
      >
        <Typography sx={styles.entityName}>Customer(s): </Typography>
        <Typography sx={styles.label}>{customerLabel}</Typography>
      </Box>
      <Box
        sx={styles.wrapper}
        onClick={() =>
          handleClickNode(ModeEnum.SELECTED, ActualViewEnum.BRANCH)
        }
      >
        <Typography sx={styles.entityName}>Branch(es): </Typography>
        <Typography sx={styles.label}>{branchLabel}</Typography>
      </Box>

      {showClearButton && (
        <IconButton sx={styles.crossIcon} onClick={onClearNodes}>
          <IconCircleCross />
        </IconButton>
      )}
    </Box>
  );
};

export default SelectedNodes;
