import React from "react";
import { Box } from "@mui/material";
import { FileUploadContainerStyles as styles } from "./FileUploadContainer.styles";
import { useFileUploadContainer } from "./state/useFileUploadContainer";
import FileUpload from "./Components/FileUpload";
import LoadingFiles from "./Components/LoadingFile";
import { ErrorAlertEnum, FileStatusEnum } from "@shared/enum/FileUploadEnum";
import FileName from "./Components/FileName";
import AlertComponent from "../Alert/AlertComponent";
import { FileUploadLabels } from "@shared/constants/labels/fileUploadLabels";
import { IFileUploadContainerProps } from "@components/FileUploadContainer/FileUploadContainer.interfaces";

const FileUploadContainer: React.FC<IFileUploadContainerProps> = ({
  maxSize,
}: IFileUploadContainerProps) => {
  const {
    handlers: { handleChange, handleDrag, handleDrop, handleCloseFile },
    state: { filesSelected, dragActive, errorUploadingFile, fileStatus },
  } = useFileUploadContainer({ totalMaxSize: maxSize });

  return (
    <Box sx={styles.wrapper}>
      {errorUploadingFile.errorFile && (
        <AlertComponent
          title={ErrorAlertEnum.TITLE}
          description={errorUploadingFile.message}
        />
      )}
      <Box component="div" sx={styles.container}>
        {(fileStatus === FileStatusEnum.DEFAULT ||
          fileStatus === FileStatusEnum.WITH_FILE) && (
          <FileUpload
            dragActive={dragActive}
            handleDrag={handleDrag}
            handleDrop={handleDrop}
            handleChange={handleChange}
            size={maxSize}
          />
        )}
        {fileStatus === FileStatusEnum.UPLOADING && (
          <LoadingFiles infoText={`${FileUploadLabels.LOADING_FILE}`} />
        )}
        {fileStatus === FileStatusEnum.WITH_FILE && (
          <Box sx={styles.filesContainer}>
            {filesSelected.map((fileSelected, i) => (
              <FileName
                key={`${i}-filename`}
                name={fileSelected.name}
                handleCloseFile={handleCloseFile}
              />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FileUploadContainer;
