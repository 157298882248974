import React from "react";
import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import { Plus } from "react-feather";
import { get, defaultTo } from "lodash";
import {
  INotification,
  LabelField,
  RulesNotificationResultEnum,
  Titles,
} from "../../shared/infrastructure/constants/RulesConstants";
import {
  deleteSecurityRule,
  deleteSecurityRulesByCountry,
  deleteWhiteListRules,
  getSecurityRulesByCountry,
  getSecurityRulesByCommerce,
  getWhitelistRules,
  IRulesState,
  RulesAction,
  setIsLoading,
  setIsLoadingRulesByType,
  setIsLoadingWhitelist,
  setIsRequest,
  setNotification,
  setRequestError,
  getMerchants,
  MerchantsData,
  getMigrateRules,
  setShowMigrationRule,
  getSearchKeyCredentials,
  updateSecurityRule,
} from "../../store/actionCreator";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { SecurityRulesByType } from "../../../types/security_rules_by_type";
import { connect } from "react-redux";
import { useRuleIndexState } from "./state/useRulesIndexState";
import { SecurityRulesTabs } from "../../components/SecurityRulesTabs/SecurityRulesTabs";
import { SnackbarAlert } from "../../components/SnackBarAlert/SnackBarAlert";
import ModalContainer from "../../components/ModalContainer/ModalContainer";
import { WhitelistRules } from "../../../types/whitelist_rules";
import { makeStyles } from "@material-ui/core/styles";
import { SecurityRule } from "../../../types/security_rule";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

export interface RulesIndexStateProps {
  isLoading: boolean | undefined;
  isRequest: boolean | undefined;
  requestError: boolean | undefined;
  isLoadingRulesByType: boolean | undefined;
  isLoadingWhiteList: boolean | undefined;
  isLoadingGetSuggestedCredentials: boolean | undefined;
  notification: INotification | undefined;
  rulesByType: SecurityRulesByType | undefined;
  commerceRules: SecurityRulesByType | undefined;
  whitelistRules: WhitelistRules | undefined;
  merchants: MerchantsData | undefined;
  isLoadingGetMerchants: boolean | undefined;
  showMigrationRule: boolean | undefined;
}

export interface RulesIndexFunctionsProps {
  setIsLoading: (payload: boolean) => RulesAction;
  setIsRequest: (payload: boolean) => RulesAction;
  setRequestError: (payload: boolean) => RulesAction;
  deleteSecurityRule: (payload: string) => void;
  setIsLoadingRulesByType: (payload: boolean) => RulesAction;
  setIsLoadingWhitelist: (payload: boolean) => RulesAction;
  setNotification: (payload: INotification) => RulesAction;
  getRulesByType: (payload: SecurityRulesByType) => void;
  getCommerceRules: (payload: SecurityRulesByType) => void;
  deleteRule: (payload: string) => void;
  updateSecurityRuleStatus: (payload: {
    rule: SecurityRule;
    ruleId: string;
    updatingStatus?: boolean;
  }) => void;
  getWhitelistRules: (payload: WhitelistRules) => void;
  deleteWhitelistRule: (payload: string) => void;
  searchMerchantsList: (payload: {
    offset: number;
    text?: string;
    limit: number;
  }) => void;
  setShowMigrationRule: (payload: boolean) => void;
  getMigrateRules: (
    payload: SecurityRulesByType,
    merchantName: string,
    merchantId: string
  ) => void;
  getSearchKeyCredentials: (merchantId: string, searchTerm: string) => void;
}

const useStyles = makeStyles(() => ({
  title: {
    fontSize: "40px",
    color: "rgba(2,51,101,1)",
    fontWeight: 100,
    fontStyle: "normal",
  },
}));

export type RulesIndexProps = RulesIndexStateProps & RulesIndexFunctionsProps;

export const RulesIndex: React.FC<RulesIndexProps> = (
  props: RulesIndexProps
) => {
  const classes = useStyles();
  const {
    isMobile,
    showModal,
    rule,
    ruleType,
    rulesByType,
    commerceRules,
    whitelistRules,
    merchantName,
    actions,
    handlerSetIndexRedirect,
    labelRedirect,
    routeRedirect,
  } = useRuleIndexState(props);
  return (
    <Container>
      <Breadcrumb
        items={[
          { label: "Inicio", url: "/dashboard" },
          { label: "Prevención de Fraude", url: "/monitoring" },
        ]}
        lastItem={"Reglas de seguridad"}
      />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography className={classes.title} variant={"h1"}>
            {Titles.SECURITY_RULES}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              startIcon={<Plus />}
              type="submit"
              variant="contained"
              color="primary"
              disableElevation
              onClick={routeRedirect}
              disabled={
                props.showMigrationRule && labelRedirect === LabelField.ADD_RULE
              }
            >
              {labelRedirect}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <SecurityRulesTabs
        isLoading={props.isLoadingRulesByType}
        isLoadingWhiteList={props.isLoadingWhiteList}
        securityRuleItems={rulesByType}
        commerceRulesItems={commerceRules}
        whitelistRules={whitelistRules}
        showMigrationRule={props.showMigrationRule}
        nextAction={actions.handleNextAction}
        backAction={actions.handleBackAction}
        nextActionCommerce={actions.handleNextActionCommerceRules}
        backActionCommerce={actions.handleBackActionCommerceRules}
        nextActionWhitelist={actions.handleNextActionWhitelist}
        backActionWhitelist={actions.handleBackActionWhitelist}
        deleteRule={actions.handleDeleteRule}
        deleteWhitelistRule={actions.handleDeleteWhitelistRule}
        handlerShowModal={actions.handlerShowModal}
        merchantName={merchantName}
        merchants={props.merchants}
        isLoadingMerchantList={defaultTo(props.isLoadingGetMerchants, true)}
        handleSetSearchTextMerchantList={actions.handleSetMerchantName}
        handleSearchTextMerchantList={actions.handleSearchTextMerchantList}
        handleSearchSecurityRulesCommerce={
          actions.handleSearchSecurityRulesCommerce
        }
        handleSearchWhitelistRule={actions.handleSearchWhitelistRule}
        handlerShowModalRuleByType={actions.handlerShowModalRuleByType}
        handlerSetIndexRedirect={handlerSetIndexRedirect}
        handlerMigrateRules={actions.handlerMigrateRules}
        handlerSetMerchantNameMigrateRules={
          actions.handlerSetMerchantNameMigrateRules
        }
      />
      <ModalContainer
        isRequest={get(props, "isRequest", false)}
        isMobile={isMobile}
        data={rule}
        handlerEditRule={actions.handlerEditRule}
        handlerDeleteRule={actions.handlerDeleteRule}
        handlerUpdateStatusRule={actions.handlerUpdateStateRule}
        type={ruleType}
        open={showModal}
        handlerCloseModalInformation={actions.handlerShowModal}
        merchant={props.merchants!}
        notification={props.notification}
        setNotification={props.setNotification}
      />
      {props.notification?.action ==
        RulesNotificationResultEnum.RULES_REQUEST_ERROR ||
        (RulesNotificationResultEnum.SUCCESS && (
          <SnackbarAlert
            type={props.notification?.type!}
            msg={props.notification?.message!}
            open={props.notification?.open!}
            handlerClose={actions.handleCloseNotification}
          />
        ))}
    </Container>
  );
};

export const mapStateToProps: (state: IRulesState) => RulesIndexStateProps = (
  state: IRulesState
): RulesIndexStateProps => ({
  commerceRules: state.commerceRules,
  isLoading: state.isLoading,
  isRequest: state.isRequest,
  requestError: state.requestError,
  isLoadingRulesByType: state.isLoadingRulesByType,
  isLoadingWhiteList: state.isLoadingWhiteList,
  isLoadingGetSuggestedCredentials: state.isLoadingGetSuggestedCredentials,
  notification: state.notification,
  rulesByType: state.rulesByType,
  whitelistRules: state.whitelistRules,
  merchants: state.merchants,
  isLoadingGetMerchants: state.isLoadingGetMerchants,
  showMigrationRule: state.showMigrationRule,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => RulesIndexFunctionsProps = (
  dispatch: ThunkDispatch<IRulesState, undefined, RulesAction>
): RulesIndexFunctionsProps => ({
  setIsLoading: (payload: boolean): RulesAction =>
    dispatch(setIsLoading(payload)),
  setIsRequest: (payload: boolean): RulesAction =>
    dispatch(setIsRequest(payload)),
  setRequestError: (payload: boolean): RulesAction =>
    dispatch(setRequestError(payload)),
  deleteSecurityRule: (payload: string): void =>
    dispatch(deleteSecurityRule(payload)),
  setIsLoadingRulesByType: (payload: boolean): RulesAction =>
    dispatch(setIsLoadingRulesByType(payload)),
  setIsLoadingWhitelist: (payload: boolean): RulesAction =>
    dispatch(setIsLoadingWhitelist(payload)),
  setNotification: (payload: INotification): RulesAction =>
    dispatch(setNotification(payload)),
  getRulesByType: (payload: SecurityRulesByType) =>
    dispatch(getSecurityRulesByCountry(payload)),
  getCommerceRules: (payload: SecurityRulesByType) =>
    dispatch(getSecurityRulesByCommerce(payload)),
  deleteRule: (payload: string) =>
    dispatch(deleteSecurityRulesByCountry(payload)),
  updateSecurityRuleStatus: (payload: {
    rule: SecurityRule;
    ruleId: string;
    updatingStatus?: boolean;
  }): void => dispatch(updateSecurityRule(payload)),
  getWhitelistRules: (payload: WhitelistRules) =>
    dispatch(getWhitelistRules(payload)),
  deleteWhitelistRule: (payload: string) =>
    dispatch(deleteWhiteListRules(payload)),
  searchMerchantsList: (payload: {
    offset: number;
    text?: string;
    limit: number;
  }): void => dispatch(getMerchants(payload)),
  setShowMigrationRule: (payload: boolean) =>
    dispatch(setShowMigrationRule(payload)),
  getMigrateRules: (
    payload: SecurityRulesByType,
    merchantName: string,
    merchantId: string
  ) => dispatch(getMigrateRules(payload, merchantName, merchantId)),
  getSearchKeyCredentials: (merchantId: string, searchTerm: string) =>
    dispatch(getSearchKeyCredentials(merchantId, searchTerm)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RulesIndex);
