import { ActionTypes } from "../actionTypes";
import { Middleware } from "redux";
import { IAppState } from "../reducer";
import { IAppAction } from "../actionCreators";
import { get, set } from "lodash";

interface InformationPersist {
  payloadKey: string;
  storageKey: string;
}

type ResumeAction =
  | ActionTypes.SET_MERCHANT_AFFILIATION
  | ActionTypes.SET_RATES
  | ActionTypes.SET_PROCESSORS
  | ActionTypes.SET_DEFAULT_PROCESSOR
  | ActionTypes.SET_DEFAULT_PAYOUTS_TRANSFER_PROCESSOR
  | ActionTypes.SET_RATE_MERCHANT;

const RESUME_INFO: Record<ResumeAction, InformationPersist> = {
  [ActionTypes.SET_MERCHANT_AFFILIATION]: {
    payloadKey: "merchantAffiliation",
    storageKey: "merchantInfo",
  },
  [ActionTypes.SET_RATES]: {
    payloadKey: "rates",
    storageKey: "rates",
  },
  [ActionTypes.SET_PROCESSORS]: {
    payloadKey: "processors",
    storageKey: "processors",
  },
  [ActionTypes.SET_DEFAULT_PROCESSOR]: {
    payloadKey: "defaultProcessor",
    storageKey: "defaultProcessor",
  },
  [ActionTypes.SET_DEFAULT_PAYOUTS_TRANSFER_PROCESSOR]: {
    payloadKey: "defaultPayoutTransferProcessor",
    storageKey: "defaultPayoutTransferProcessor",
  },
  [ActionTypes.SET_RATE_MERCHANT]: {
    payloadKey: "rate",
    storageKey: "ratesConfig",
  },
};

const MERCHANT_RESUME_KEY: string = "merchantResume";

const resumeStorageMiddleware: Middleware<{}, IAppState> = () => {
  return (next) => (action: IAppAction) => {
    const returnValue = next(action);

    const infoToPersist: InformationPersist | undefined =
      RESUME_INFO[action.type];

    if (!infoToPersist) return returnValue;

    const merchant_resume: object = JSON.parse(
      localStorage.getItem(MERCHANT_RESUME_KEY) || "{}"
    );

    set(
      merchant_resume,
      infoToPersist.storageKey,
      get(action, infoToPersist.payloadKey)
    );

    localStorage.setItem(MERCHANT_RESUME_KEY, JSON.stringify(merchant_resume));

    return returnValue;
  };
};

export { resumeStorageMiddleware };
