import { ITableRowProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { useEffect, useState } from "react";
import {
  ALL_TABLE_COLUMNS,
  BASE_CELL,
  CHIPS_CELL,
  CustomHeaderCellProps,
  TAG_CELL,
  TEXT_ACTION_CELL,
  TEXT_CELL,
} from "../../../shared/infrastructure/constants/DeferredListConstants";
import {
  IDeferred,
  IDeferredConfig,
  IDeferredItem,
  IDeferredList,
  IMerchantInfo,
  ORIGIN,
} from "./useDeferredList.interface";
import { IconCalendar, IRowInteractiveProps } from "@kushki/connect-ui";
import { defaultTo, get, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDeferred,
  selectMerchantCardInfo,
  selectMerchantProcessors,
  selectNodeInfo,
} from "../../../store/selectors/selectors";
import { useLocation } from "react-router";
import { LOCAL_STORAGE_ITEMS } from "../../../shared/constants/local_storage_items";
import { PathEnum } from "../../../shared/enums/pathEnum";
import {
  CentroAmericaCountriesEnum,
  CountriesEnum,
  DeferredCountries,
  DeferredCountriesEnum,
} from "../../../shared/infrastructure/countries-enum";
import { ITableCellProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableInfo/interfaces";
import useFetchData from "../../../hooks/useFecthData/useFetchData";
import {
  setDeferredConfig,
  setDeferredList,
} from "../../../store/actions/actions";
import { QueryParamsEnum } from "../../../shared/enums/queryParamsEnum";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { IGetMerchantCardInfoResponse } from "../../../../types/get_merchant_card_info_response";
import { setMerchantCardInformation } from "../../../store/actions/actions";
import { AxiosResponse } from "axios";
import { DeferredModalType } from "../../DeferredListModal/DeferredListModal";
import { ConfigurationIdEnum, Section } from "../../../shared/enums";
import { isSectionCentralized } from "../../../shared/utils/isSectionCentralized";
import { DeferredStatusEnum } from "../../../shared/enums/DeferredStatusEnum";
import { EntityNameEnum } from "../../../shared/enums/entityName";
import { BrandCell } from "../../BrandCell/BrandCell";
import { formatDate } from "./useDeferredList.utils";
import { translateProcessor } from "../../../shared/enums/ProcessorsEnum";
import { GetMerchantProcessorsResponse } from "../../../../types/get_merchant_processors_response";
import { WebhookServiceEnum } from "../../../shared/enums/OptionsEnums";

export const useDeferredList = (): IDeferred => {
  const [rows, setRows] = useState<ITableRowProps[]>([]);
  const [modal, setModal] = useState<{
    items: string[];
    type: DeferredModalType | null;
  }>({
    items: [],
    type: null,
  });
  const deferred = useSelector(selectDeferred);
  const nodeInfo = useSelector(selectNodeInfo);
  const merchantCards = useSelector(selectMerchantCardInfo);
  const merchantProcessors = useSelector(selectMerchantProcessors);
  const merchantInfo: IMerchantInfo | null = JSON.parse(
    defaultTo(
      localStorage.getItem(LOCAL_STORAGE_ITEMS.BASIC_MERCHANT_INFORMATION),
      "null"
    )
  );
  const [deferredOptions, setDeferredOptions] = useState<boolean>(false);
  const [isCentralized, setIsCentralized] = useState<boolean>(false);
  const [merchantSettingsInfo, setMerchantSettingsInfo] = useState(null);
  const [showConfigDeferred, setShowConfigDeferred] = useState<boolean>(false);
  const [hasCardProcessor, setHasCardProcessor] = useState<boolean>(false);
  const [getEntityName, setEntityName] = useState("");
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const publicMerchantId: string | null = searchParams.get(
    QueryParamsEnum.PUBLIC_MERCHANT_ID
  );
  const dispatch = useDispatch();

  const handleEdit = (redirectToCreate?: boolean): void => {
    const merchantBasicInfo: IMerchantInfo = {
      country: nodeInfo!.generalInfo!.country,
      name: nodeInfo?.generalInfo.name,
      nodeId: nodeInfo?.nodeId,
      origin: ORIGIN.MERCHANT_RESUME,
      publicMerchantId: nodeInfo?.generalInfo.publicMerchantId,
    };

    localStorage.setItem(
      LOCAL_STORAGE_ITEMS.MERCHANT_BASIC_INFORMATION,
      JSON.stringify(merchantBasicInfo)
    );

    redirectToCreate
      ? (window.location.href = `${PathEnum.DEFERRED_CREATE}`)
      : (window.location.href = `${PathEnum.DEFERRED}`);
  };

  const handleModals = {
    close: () =>
      setModal({
        items: [],
        type: null,
      }),
    open: (type: DeferredModalType, items: string[]) =>
      setModal({
        items,
        type,
      }),
  };

  const validCardProcessors = () => {
    return merchantProcessors!.some(
      (item: GetMerchantProcessorsResponse) =>
        item.paymentMethod === WebhookServiceEnum.card
    );
  };
  const getColumns = (
    country: string = DeferredCountriesEnum.ECUADOR
  ): CustomHeaderCellProps[] => {
    if (CentroAmericaCountriesEnum[country.toUpperCase()])
      country = DeferredCountriesEnum.CENTROAMERICA;

    return ALL_TABLE_COLUMNS[country];
  };

  const getCells = (
    column: IDeferredItem,
    country: string = DeferredCountriesEnum.ECUADOR
  ): ITableCellProps[] => {
    if (CentroAmericaCountriesEnum[country.toUpperCase()])
      country = DeferredCountriesEnum.CENTROAMERICA;
    switch (country) {
      case DeferredCountriesEnum.BRAZIL:
        return [
          TEXT_ACTION_CELL(
            defaultTo(translateProcessor[column.processorName], ""),
            column.startDate && column.endDate ? <IconCalendar /> : <></>
          ),
          BASE_CELL(<BrandCell processorName={column.processorName} />),
          CHIPS_CELL(column.months!, () =>
            handleModals.open("payments", column.months!)
          ),
          TEXT_CELL(
            formatDate(get(column, "startDate", 0)).concat(
              " - ",
              formatDate(get(column, "endDate", 0), true)
            )
          ),
          TAG_CELL(defaultTo(column.merchantStatus, "")),
        ];
      case DeferredCountriesEnum.CENTROAMERICA:
        return [
          TEXT_ACTION_CELL(
            defaultTo(column.processorName, ""),
            column.startDate && column.endDate ? <IconCalendar /> : <></>
          ),
          TEXT_CELL(column.deferredType[0]),
          CHIPS_CELL(column.months!, () =>
            handleModals.open("payments", column.months!)
          ),
          TAG_CELL(defaultTo(column.merchantStatus, "")),
        ];
      case DeferredCountriesEnum.MEXICO:
        return [
          TEXT_ACTION_CELL(
            defaultTo(column.processorName, ""),
            column.startDate && column.endDate ? <IconCalendar /> : <></>
          ),
          TEXT_CELL(defaultTo(column.entity, "")),
          TEXT_CELL(column.deferredType[0]),
          CHIPS_CELL(column.months!, () =>
            handleModals.open("payments", column.months!)
          ),
          CHIPS_CELL(column.monthsOfGrace!, () =>
            handleModals.open("grace", column.monthsOfGrace!)
          ),
          TAG_CELL(defaultTo(column.merchantStatus, "")),
        ];
      case DeferredCountriesEnum.ECUADOR:
      default:
        return [
          TEXT_ACTION_CELL(
            defaultTo(column.processorName, ""),
            column.startDate && column.endDate ? <IconCalendar /> : <></>
          ),
          TEXT_CELL(get(column, "deferredType[0]", "")),
          CHIPS_CELL(column.months!, () => {
            handleModals.open("payments", column.months!);
          }),
          CHIPS_CELL(column.monthsOfGrace!, () => {
            handleModals.open("grace", column.monthsOfGrace!);
          }),
          TAG_CELL(defaultTo(column.merchantStatus, "")),
        ];
    }
  };

  const getMerchantSettingsInfo = async (): Promise<any> => {
    try {
      const settingsResponse = await axios.get(
        `${API_ROUTES.MERCHANT_SETTINGS_ADMIN}/${publicMerchantId}`
      );

      return settingsResponse;
    } catch (err) {
      /* istanbul ignore next */
      console.warn(err);
    }
  };

  useEffect(() => {
    getMerchantSettingsInfo().then((response) =>
      setMerchantSettingsInfo(response.data)
    );
  }, []);

  const getMerchantCentralized = (isCentralized: boolean): string | null => {
    const configCN010 = nodeInfo!.configs?.find(
      (config) => config.configuration === ConfigurationIdEnum.CN010
    );

    if (isCentralized && configCN010) return configCN010.value;

    return publicMerchantId;
  };

  const getDeferredList = useFetchData(() => {
    if (nodeInfo) {
      const isCentralized = isSectionCentralized(
        Section.PROCESSING_DEFERRED,
        nodeInfo!.configs
      );

      setEntityName(nodeInfo?.entityName);
      setIsCentralized(isCentralized);
      const merchantCentralized = getMerchantCentralized(isCentralized);

      return axios.get<IDeferredList>(
        `${API_ROUTES.GET_DEFERRED_LIST}/${merchantCentralized}`
      );
    }

    return axios.get<IDeferredList>(
      `${API_ROUTES.GET_DEFERRED_LIST}/${publicMerchantId}`
    );
  }, [nodeInfo]);

  const buildRequestCountry = (): string => {
    if (
      CentroAmericaCountriesEnum[
        defaultTo(merchantInfo?.country, "").toUpperCase()
      ]
    )
      return CountriesEnum.CENTRO_AMERICA;

    return get(merchantInfo, "country", "");
  };

  const isGetDeferredConfig = (): boolean => {
    const requestCountry = buildRequestCountry();

    return DeferredCountries.includes(requestCountry);
  };

  const isDeferredAvailable = (): boolean => {
    if (
      isEmpty(get(merchantSettingsInfo, "processors.card")) &&
      isEmpty(get(getDeferredList, "data.data.deferredOptions"))
    ) {
      return false;
    }

    return true;
  };

  const getDeferredConfig = async (): Promise<IDeferredConfig[]> => {
    try {
      const response: AxiosResponse<IDeferredConfig[]> = await axios.post<
        IDeferredConfig[]
      >(`${API_ROUTES.GET_DEFERRED_CONFIG}`, {
        configType: "DEFERRED_TYPE",
        country: buildRequestCountry(),
      });

      return response.data;
    } finally {
    }
  };

  const getFirstDeferredItems = (list: IDeferredList): IDeferredItem[] => {
    if (list.deferredOptions!.length > 5) {
      return list.deferredOptions!.slice(0, 5);
    }

    return list.deferredOptions!;
  };

  const getMerchantCardInfo =
    async (): Promise<IGetMerchantCardInfoResponse> => {
      try {
        const merchantCentralized = getMerchantCentralized(isCentralized);

        const response: AxiosResponse<IGetMerchantCardInfoResponse> =
          await axios.get<IGetMerchantCardInfoResponse>(
            `${API_ROUTES.MERCHANT_CARD_INFO}/${merchantCentralized}`
          );

        return response.data;
      } finally {
      }
    };

  const validateDeferredPeru = (deferredList: any) => {
    if (
      !isEmpty(deferredList) &&
      get(deferredList, "[0].status") === DeferredStatusEnum.ENABLED
    ) {
      getMerchantCardInfo().then((response: IGetMerchantCardInfoResponse) => {
        dispatch(setMerchantCardInformation(response));
      });
      setDeferredOptions(true);

      return;
    }

    setDeferredOptions(false);
  };

  useEffect(() => {
    if (!isEmpty(merchantProcessors)) {
      setHasCardProcessor(validCardProcessors());
    }
  }, [merchantProcessors]);

  useEffect(() => {
    if (getDeferredList.data) {
      const deferredList = getFirstDeferredItems(getDeferredList.data.data);

      if (isGetDeferredConfig()) {
        setShowConfigDeferred(
          get(merchantInfo, "entityName", "") === EntityNameEnum.CUSTOMER ||
            (get(merchantInfo, "entityName", "") === EntityNameEnum.BRANCH &&
              !isSectionCentralized(
                Section.PROCESSING_DEFERRED,
                nodeInfo!.configs
              ))
        );
        if (isDeferredAvailable()) {
          setDeferredOptions(true);
          getDeferredConfig().then((response: IDeferredConfig[]) => {
            deferredList.map((item) => {
              const filteredTypes = response.filter(
                (value) => value.code === item.deferredType[0]
              );

              item.deferredType[0] =
                filteredTypes.length > 0
                  ? filteredTypes[0].name
                  : item.deferredType[0];
            });
            dispatch(setDeferredConfig(response));
            dispatch(setDeferredList(deferredList));
          });

          return;
        }

        setDeferredOptions(false);
      } else {
        if (merchantInfo?.country === CountriesEnum.PERU) {
          validateDeferredPeru(deferredList);
        }
      }
    }
  }, [merchantSettingsInfo]);

  useEffect(() => {
    if (
      deferred?.deferredOptions === null ||
      !deferred?.deferredOptions?.length
    ) {
      return;
    }
    const { deferredOptions } = deferred;

    const valuesToRow: ITableRowProps[] = deferredOptions!.map(
      (column: IDeferredItem, index: number): ITableRowProps => {
        const cells = getCells(column, merchantInfo?.country);

        return {
          cells,
          id: "rules" + index,
          rowProps: {
            color: "default",
          } as IRowInteractiveProps,
        };
      }
    );

    setRows(valuesToRow);
  }, [deferred]);

  return {
    deferredOptions,
    getEntityName,
    handleEdit,
    hasCardProcessor,
    isCentralized,
    merchantCards,
    merchantInfo,
    modal: {
      close: handleModals.close,
      data: modal.items,
      type: modal.type,
    },
    showConfigDeferred,
    table: {
      columns: getColumns(merchantInfo?.country),
      rows,
    },
  };
};
