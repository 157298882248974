export enum AlarmConfigLabel {
  HELPER_CARD = "Si alguna de las reglas configuradas en esta alarma se cruza  a nivel de lógica, el sistema tomará automáticamente la más reciente (o la que técnicamente sea más fácil).",
  NODES_TITLE = "Clientes seleccionados",
  NODES_EDIT = "Editar",
}

export enum AlarmConfigModalLabel {
  PRIMARY_BUTTON = "Regresar",
  SECONDARY_BUTTON = "Cancelar",
  DESCRIPTION = "Al cancelar esta alarma se perderá la información ingresada.",
  TITLE = "¿Estás seguro de que deseas cancelar esta alarma?",
}
