import React, { FC } from "react";
import { Chip } from "@mui/material";
import { ITransactionStatusChipProps } from "./TransactionStatusChip.interfaces";
import {
  TRANSACTION_STATUS_COLOR_LOOKUP,
  TRANSACTION_STATUS_LABEL_LOOKUP,
} from "../../../shared/types";
import { transactionStatusChipStyles } from "./TransactionStatusChip.styles";

const TransactionStatusChip: FC<ITransactionStatusChipProps> = ({
  status,
}: ITransactionStatusChipProps) => (
  <Chip
    color={TRANSACTION_STATUS_COLOR_LOOKUP[status]}
    label={TRANSACTION_STATUS_LABEL_LOOKUP[status]}
    sx={transactionStatusChipStyles.chip}
  />
);

export default TransactionStatusChip;
