import React from "react";
import MuiTimeline from "@mui/lab/Timeline";
import TimeLineConnector from "./components/TimeLineConnector/TimeLineConnector";
import TimeLineIcon from "./components/TimeLineIcon/TimeLineIcon";
import TimeLineSpot from "./components/TimeLineSpot/TimeLineSpot";
import TimeLineItemContent from "./components/TimeLineItemContent/TimeLineItemContent";
import TimeLineItem from "./components/TimeLineItem/TimeLineItem";
import { ChildrenProps } from "./Timeline.interfaces";

const Timeline = ({ children }: ChildrenProps) => {
  return (
    <MuiTimeline aria-label="custom-kushki-time-line">{children}</MuiTimeline>
  );
};

Timeline.Item = TimeLineItem;
Timeline.ItemSpot = TimeLineSpot;
Timeline.ItemContent = TimeLineItemContent;
Timeline.ItemSpotIcon = TimeLineIcon;
Timeline.Connector = TimeLineConnector;

export default Timeline;
