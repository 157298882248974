import { IDeferredType } from "../interfaces/IDeferredType";
import { ProcessorsEnum } from "./ProcessorsEnum";

export enum DeferredCodesCentralAmerica {
  WITHOUT_INTERESTS = "01",
  WITH_INTERESTS_MINI_DUES = "04",
}

export const DEFERRED_TYPE_CENTRAL_AMERICA: IDeferredType[] = [
  {
    code: DeferredCodesCentralAmerica.WITHOUT_INTERESTS,
    hasMonthsOfGrace: false,
    processor: ProcessorsEnum.CREDOMATIC,
    text: "Tasa cero, sin intereses",
  },
  {
    code: DeferredCodesCentralAmerica.WITH_INTERESTS_MINI_DUES,
    hasMonthsOfGrace: false,
    processor: ProcessorsEnum.CREDOMATIC,
    text: "Minicuotas con intereses",
  },
];

export const DEFAULT_DEFERRED_TYPE_CENTRAL = "Diferido";
