import { IUseGenericTabs } from "./useGenericTabs.interfaces";
import React, { useEffect } from "react";
import { ITabInfo } from "../GenericTabs.interfaces";

export const useGenericTabs = (tabList: ITabInfo[]): IUseGenericTabs => {
  const [tabNumber, setTabNumber] = React.useState(0);
  const handleChange = (newTabNumber: number) => {
    setTabNumber(newTabNumber);
  };

  useEffect(() => setTabNumber(0), [tabList]);

  return {
    handleChange,
    tabNumber,
  };
};
