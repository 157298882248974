import { TransactionType } from "../enums/transaction_types";
import {
  LOADER_TITLE_CREDIT_WALLET,
  LOADER_TITLE_DEBIT_WALLET,
} from "../constants/labels/modal_labels";

export const CATALOG_MODAL_LOADER_TITLE: Record<TransactionType, String> = {
  [TransactionType.DEBIT]: LOADER_TITLE_DEBIT_WALLET,
  [TransactionType.CREDIT]: LOADER_TITLE_CREDIT_WALLET,
};
