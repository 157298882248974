import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const paymentMethodStyles = createNamedStyles({
  gridContainer: {
    display: "grid",
    padding: "10px",
    rowGap: "24px",
  },

  gridBasic: {
    width: "100%",
    display: "flex",
    flexWrap: "nowrap",
    columnGap: "50px",
  },
  gridBasic2: {
    width: "100%",
    display: "flex",
    columnGap: "50px",
    rowGap: "8px",
  },

  gridBasic2Skeleton: {
    width: "100%",
    display: "flex",
    flexWrap: "nowrap",
    columnGap: "50px",
  },

  gridBasic3: {
    backgroundColor: "#F7FAFC",
    width: "100%",
    display: "grid",
    padding: "16px 16px 16px 16px",
    rowGap: "8px",
  },

  gridBasic3Skeleton: {
    width: "100%",
    display: "grid",
    rowGap: "8px",
  },
  box: {
    width: "100%",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
  iconPadding: {
    marginRight: "9px",
  },
  box2: {
    width: "100%",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  box3: {
    justifyContent: "space-between",
    display: "inline-flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  grid: {
    rowGap: 20,
  },
  gridRow: {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  titleCard: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    color: "#293036",
  },
  title: {
    color: "#293036",
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    paddingLeft: "9px",
    width: "100%",
  },
  secondaryTitle: {
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px",
  },
  icon: {
    color: "#023365",
  },
  textAgregator: {
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#293036",
  },
  iconInfo: {
    position: "absolute",
    width: "1.33px",
    height: "1.35px",
  },

  titleAgregator: {
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "150%",
    color: "#293036",
  },
  iconCircleInformation: {
    width: "14.67px",
    height: "14.67px",
    marginTop: "7px",
    marginLeft: "30px",
  },
});
