import { ItemCategoryItem } from "../../components/Filters/ItemCategory/interfaces";

export enum PaymentMethodEnum {
  CARD = "card",
  TRANSFER = "transfer",
  CASH = "cash",
}

export enum PaymentSubMethodTypeEnum {
  CARD_NO_PRESENT = "CHARGEBACK CARD NO PRESENT",
  CARD_PRESENT = "CHARGEBACK CARD PRESENT",
}

export enum ChargebackPaymentMethodLabelEnum {
  CARD = "Tarjeta Online",
  CARD_PRESENT = "Tarjeta Presencial",
}

export enum ServicesEnum {
  OTP = "otp",
  SIFTSCIENCE = "siftScience",
  _3DS = "3ds",
  TRANSUNION = "transUnion",
}

export enum PaymentMethodLabelEnum {
  CARD = "Tarjeta",
  TRANSFER = "Transferencia",
  CASH = "Efectivo",
  TRANSACTIONS = "Transacciones",
}

export const CATALOG_PAYMENT_METHOD: Record<
  PaymentMethodEnum,
  PaymentMethodLabelEnum
> = {
  [PaymentMethodEnum.CASH]: PaymentMethodLabelEnum.CASH,
  [PaymentMethodEnum.CARD]: PaymentMethodLabelEnum.CARD,
  [PaymentMethodEnum.TRANSFER]: PaymentMethodLabelEnum.TRANSFER,
};

export const CATALOG_PAYMENT_SUB_METHOD_TYPE: Record<
  PaymentSubMethodTypeEnum,
  ChargebackPaymentMethodLabelEnum
> = {
  [PaymentSubMethodTypeEnum.CARD_NO_PRESENT]:
    ChargebackPaymentMethodLabelEnum.CARD,
  [PaymentSubMethodTypeEnum.CARD_PRESENT]:
    ChargebackPaymentMethodLabelEnum.CARD_PRESENT,
};

export const DEFAULT_PAYMENT_SUB_METHODS_TYPE: ItemCategoryItem[] = [
  {
    label:
      CATALOG_PAYMENT_SUB_METHOD_TYPE[PaymentSubMethodTypeEnum.CARD_NO_PRESENT],
    selected: false,
    value: PaymentSubMethodTypeEnum.CARD_NO_PRESENT,
  },
  {
    label:
      CATALOG_PAYMENT_SUB_METHOD_TYPE[PaymentSubMethodTypeEnum.CARD_PRESENT],
    selected: false,
    value: PaymentSubMethodTypeEnum.CARD_PRESENT,
  },
];
