export enum AlarmDetailCardEnum {
  TITLE = "Detalle de la Alarma",
  CREATED_BY = "Creado por",
  LAST_UPDATED_BY = "Última modificación por",
  INACTIVE = "Inactivar",
  EDIT = "Editar",
  ALARM_INFORMATION = "Información de la alarma",
  AGGREGATED_CUSTOMERS = "Clientes agregados:",
  GENERAL_CONDITION = "Condición general",
  FREQUENCY = "Frecuencia:",
  MERCHANTS = "Comercios:",
  OWNER = "Owner:",
  CUSTOMER = "Customer(s):",
  BRANCH = "Branch(es):",
  VIEW_MORE = "Ver más...",
  ACTIVE_MERCHANTS = "Comercios activos",
  CLOSE_ALARM = "Cerrar alarma",
  INIT_INVESTIGATION = "Iniciar investigación",
  AMOUNT_DEVIATION = "Desviaciones por monto",
  CUSTOMER_FAMILY = "Familia del cliente",
  TRANSACTION_DEVIATION = "Desviaciones por transacción",
  ALARM_VARIABLE = "Variable(s) por la cúal se desplegó la alarma",
  CLOSE_DATE = "Fecha de cierre:",
}

export enum TitleType {
  CREATE = "create",
  UPDATE = "update",
}

export enum AlarmTabLabel {
  INFORMATION = "Información",
  TIMELINE = "Línea del tiempo",
  COMMENTS = "Comentarios",
}

export enum InformationSectionAlarm {
  ID_MERCHANT = "ID comercio",
  FREQUENCY = "Frecuencia",
  COMERCIAL_EXECUTIVE = "Asesor comercial",
  CATEGORY = "Categoria del comercio",
  OPERATIONAL_COUNTRY = "País de operación",
  PAYMENT_METHOD = "Método de pago",
  MODEL = "Modelo",
  TPV = "TPV Mensual",
  OWNER = "Owner:",
  CUSTOMER = "Customer:",
  BRANCH = "Branch:",
  AVERAGE_AMOUNT = "Monto Promedio #frequency:",
  DEVIATION = "Desviación:",
  UPPER_LIMIT = "Límite superior:",
  TOTAL_AMOUNT_TRANSACTIONS_DAY = "Monto total transacciones procesadas el día anterior:",
  TOTAL_AMOUNT_PRECESSED = "Monto total procesado rango de tiempo configurado:",
  NO_TRANSACTIONS_AVERAGE = "Nº trans. promedio #frequency:",
  NO_MAX_TRANSACTIONS = "Nº máx. de transacción:",
  NO_TRANS_PREVIOUS_DAY = "Total transacciones día anterior:",
  NO_TRANSACTIONS = "Número de transacciones en el rango de tiempo configurado:",
}

export enum CommentSectionLabel {
  ADD_COMMENT = "Agregar comentario",
  ADD_COMMENTS = "Agregar comentarios",
  PLACEHOLDER = "Agrega tus comentarios",
  EMPTY = "Aún no hay comentarios registrados",
  LOADER_TITLE = "Actualizando alarma",
  LOADER_DESCRIPTION = "Espera un momento por favor...",
}

export enum TimelineSectionLabel {
  ACTIVE_ALARM = "Alarma activa",
  INIT_INVESTIGATION = "Investigación iniciada",
  NORMAL = "Cerrada como normal",
  ROS = "Cerrada como ROS",
  COMMENT = "Comentario",
  VIEW_COMMENT = "Ver comentario",
  RE_ACTIVE = "Alarma re-activada",
  BLOCKED = "Bloqueo de comercio",
  IN_PROCESS = "Reporte de Comercio",
  UNLOCK = "Desbloqueo de comercio",
}

export enum TitleDescription {
  COLOR = "text.grey",
  VARIANT = "overline",
  JUSTIFY = "flex-start",
}
