import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const transferInternationalProcessorContainerStyles = createNamedStyles({
  titleInfo: {
    marginBottom: "40px",
  },
  sectionTitleComponent:{
    marginRight:"40px"
  },
  principalContainer: {
    padding: "1px 0px 1px 276px",
    height: "85vh !important",
  },
  backButton:{
    color:"#023365",
    borderColor:"#023365",
    marginRight:"4px"
  },
  submitButton:{
    backgroundColor:"#023365",
    marginRight:"4px"
  },
  buttons:{
    direction:"rtl"
  }
});
