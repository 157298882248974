export const INPUT_ERROR_KEYS = {
  target: "__TARGET__",
};

export const INPUT_ERROR_MESSAGES = {
  invalid: `${INPUT_ERROR_KEYS.target} inválido`,
  maxLength: "El campo no cumple con el máximo de caracteres",
  minLength: "El campo no cumple con el mínimo de caracteres",
  pattern: "El campo no cumple con el patron requerido",
  required: "Campo requerido",
};
