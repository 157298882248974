import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const executivesStyles = createNamedStyles({
  card: {
    padding: "3rem 4rem",
    borderRadius: "1rem",
    width: "100%",
    mt: "2rem",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  textButton: {
    cursor: "pointer",
    textDecoration: "underline",
    fontWeight: "500",
  },
});
