import { defaultTo, get } from "lodash";
import { AuthEnum } from "./enums/AuthEnum";

export class auth {
  public static getAuthMerchant(): object {
    return JSON.parse(
      defaultTo(localStorage.getItem(AuthEnum.MERCHANT_BASIC_INFORMATION), "{}")
    );
  }

  public static isComplianceUser(): boolean {
    return localStorage.getItem(AuthEnum.IS_COMPLIANCE_USER) === "true";
  }

  public static isAdmin(): boolean {
    return (
      auth.isComplianceUser() ||
      get(
        JSON.parse(localStorage.getItem(AuthEnum.ROLES)!),
        AuthEnum.BACKOFFICE_ADMIN,
        false
      )
    );
  }

  public static publicMerchantId(): string {
    return get(auth._getPayload(), AuthEnum.PREFERRED_USERNAME, "");
  }

  private static _getPayload(): object {
    return JSON.parse(defaultTo(localStorage.getItem(AuthEnum.PAYLOAD), "{}"));
  }
}
