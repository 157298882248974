import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const dropDownStyles = createNamedStyles({
  chip: {
    backgroundColor: "#EEF6FF",
    color: "#023365",
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "center",
    maxWidth: "80%",
    borderRadius: "4px !important",
    "&.MuiChip-root .MuiChip-icon": {
      color: "#023365 !important",
    },
    "&.MuiChip-root .MuiChip-label": {
      fontSize: "10px",
    },
  },
  icon: {
    color: "#023365",
  },
  menuItems: {
    fontSize: "12px !important",
  },
  container: {
    width: "100%",
    display: "block",
    justifyContent: "center",
  },
});
