import { useEffect, useState } from "react";
import { RadioInfoItems } from "../../../components/RadioGroupComponent/RadioGroupComponent.interfaces";
import {
  CATEGORY_OPTIONS_RADIO_ITEMS,
  MODEL_OPTIONS_RADIO_ITEMS,
  PROCESSOR_OPTIONS_RADIO_ITEMS,
} from "../../../shared/constants/payoutsCardProcessorOptions";
import { defaultTo, get, isEmpty, set } from "lodash";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import {
  createProcessor,
  getBillingCoreNodeResponse,
  getMerchantProcessorInfo,
  getMerchantProcessors,
  getProcessor,
  setAsDefaultProcessor,
  updateProcessor,
} from "../../../store/thunks/app/app.thunks";
import { RootState } from "../../../store/store";
import { BillingCoreNodeRequest } from "../../../../types/billing_core_node_request";
import { DefaultProcessor } from "../../../../types/default_processor";
import { useForm, UseFormMethods } from "react-hook-form";
import { CreateProcessor } from "../../../../types/create_processor";
import { CreateProcessorRequest } from "../../../../types/create_processor_request";
import {
  InputNameEnum,
  OriginEnum,
  RedirectObject,
  StatusEnum,
} from "../../../shared/constants/processorConstants";
import {
  ILoadingModal,
  ISnackbarError,
} from "../../../shared/interfaces/ILoadingModal";
import { MerchantInfo } from "../../../../types/merchant_info";
import {
  dismissFinalModal,
  dismissSnackbar,
} from "../../../store/reducers/app/app";
import { SetDefaultProcessorRequest } from "../../../../types/set_default_processor_request";
import { MerchantProcessor } from "../../../../types/merchant_processor";
import { TextEnum } from "../../../shared/constants/textEnum";

export interface UsePayoutsCardProcessorState {
  processorTypes: RadioInfoItems[];
  setProcessorTypes: (value: RadioInfoItems[]) => void;
  modelOptions: RadioInfoItems[];
  setModelOptions: (value: RadioInfoItems[]) => void;
  categoryOptions: RadioInfoItems[];
  setCategoryOptions: (value: RadioInfoItems[]) => void;
  form: UseFormMethods<CreateProcessor>;
  handleSave: () => void;
  loadingModal: ILoadingModal;
  showCreatingProcessorModal: boolean;
  registeredProcessorAlias: string[];
  merchantId: string;
  merchantInfo: MerchantInfo;
  handleCloseFinalModal: (url: string) => void;
  showFinalModal: boolean;
  defaultProcessor: DefaultProcessor;
  isEdit: boolean;
  isDefaultProcessor: boolean;
  handleChangeShowSnackbar: () => void;
  handleOnReturn: () => void;
  disableCheckbox: boolean;
  snackbarError: ISnackbarError;
  getMerchantInfo: (merchantId: string) => RedirectObject;
}

export const usePayoutsCardProcessorState =
  (): UsePayoutsCardProcessorState => {
    const {
      merchantProcessorInfo,
      billingCoreNodeResponse,
      loadingModal,
      showCreatingProcessorModal,
      merchantProcessors,
      showFinalModal,
      createProcessorResponse,
      getProcessorResponse,
      setDefaultProcessorResponse,
      processorDataSaved,
      snackbarError,
    } = useAppSelector((state: RootState) => ({
      ...state.app,
    }));

    const dispatch = useAppDispatch();
    const { merchantId, processorId } = useParams();
    const [isDefaultProcessor, setIsDefaultProcessor] =
      useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [disableCheckbox, setDisableCheckbox] = useState<boolean>(false);
    const [registeredProcessorAlias, setRegisteredProcessorAlias] = useState<
      string[]
    >([]);
    const [defaultProcessor, setDefaultProcessor] = useState<DefaultProcessor>({
      processorAlias: "",
      publicProcessorId: "",
    });
    const [processorTypes, setProcessorTypes] = useState<RadioInfoItems[]>(
      PROCESSOR_OPTIONS_RADIO_ITEMS
    );
    const [modelOptions, setModelOptions] = useState<RadioInfoItems[]>(
      MODEL_OPTIONS_RADIO_ITEMS
    );
    const [categoryOptions, setCategoryOptions] = useState<RadioInfoItems[]>(
      CATEGORY_OPTIONS_RADIO_ITEMS
    );
    const [merchantInfo, setMerchantInfo] = useState<MerchantInfo>({
      country: "",
      merchantName: "",
      origin: "",
    });
    const [redirectUrl, setRedirectUrl] = useState<string>("");

    const handleCloseFinalModal = (url: string): void => {
      window.location.href = url;
      dispatch(dismissFinalModal);
    };

    const merchant: MerchantInfo = defaultTo(
      JSON.parse(`${window.localStorage.getItem("merchantBasicInformation")}`),
      {}
    );

    const form = useForm<CreateProcessor>({
      defaultValues: {
        defaultProcessor: false,
        merchantCategory: CATEGORY_OPTIONS_RADIO_ITEMS[0].value,
        merchantModel: MODEL_OPTIONS_RADIO_ITEMS[0].value,
        processorAlias: PROCESSOR_OPTIONS_RADIO_ITEMS[0].value,
        processorName: PROCESSOR_OPTIONS_RADIO_ITEMS[0].value,
      },
      mode: "all",
    });

    const getUsedProcessorAlias = (applyFilter: boolean) => {
      let usedAliasList: string[] = merchantProcessors.map(
        (merchant: MerchantProcessor) => merchant.alias
      );

      if (applyFilter) {
        usedAliasList = usedAliasList.filter(
          (alias) => alias !== getProcessorResponse.processorInfo!.alias
        );
      }

      setRegisteredProcessorAlias(usedAliasList);
    };

    function loadData() {
      const billingCoreNodeRequest: BillingCoreNodeRequest = {
        configId: "cn001",
        publicMerchantId: merchantId!,
      };

      dispatch(
        getMerchantProcessorInfo({
          merchantId: merchantId!,
        })
      );
      dispatch(
        getMerchantProcessors({
          merchantId: merchantId!,
        })
      );
      dispatch(getBillingCoreNodeResponse(billingCoreNodeRequest));
    }

    function getRedirectPath(): string {
      const createMerchantOrigins: string[] = [
        OriginEnum.consoleMerchant,
        OriginEnum.createMerchantV3,
      ];

      if (createMerchantOrigins.includes(merchantInfo.origin)) {
        return `/processing/?publicMerchantId=${merchantId}&menuItem=true`;
      } else {
        return `/merchant-resume?publicMerchantId=${merchantId}&mode=edition`;
      }
    }

    useEffect(() => {
      const merchantMcc: string = get(billingCoreNodeResponse, "mcc", "");

      if (!loadingModal.isLoading && !isEmpty(merchantMcc)) {
        form.setValue(InputNameEnum.MCC, merchantMcc);
      }
    }, [loadingModal, billingCoreNodeResponse]);

    useEffect(() => {
      const defaultProcessorId: string = get(
        merchantProcessorInfo,
        "defaultProcessor.payoutsCard",
        ""
      );

      if (
        !loadingModal.isLoading &&
        !loadingModal.firstTimeLoad &&
        !isEdit &&
        defaultProcessorId === ""
      ) {
        form.setValue("defaultProcessor", true);
        setDisableCheckbox(true);
      }
    }, [isEdit, loadingModal, merchantProcessorInfo]);

    useEffect(() => {
      if (
        !loadingModal.isLoading &&
        !isEmpty(merchantProcessors) &&
        !isEmpty(merchantProcessorInfo)
      ) {
        const defaultProcessorId: string = get(
          merchantProcessorInfo,
          "defaultProcessor.payoutsCard",
          ""
        );
        const processorIndex: number = merchantProcessors.findIndex(
          (processor) => processor.publicProcessorId === defaultProcessorId
        );
        const defaultProcessorAlias: string = get(
          merchantProcessors,
          `[${processorIndex}].alias`,
          ""
        );

        setDefaultProcessor({
          processorAlias: defaultProcessorAlias,
          publicProcessorId: defaultProcessorId,
        });
      }
    }, [loadingModal, merchantProcessors, merchantProcessorInfo]);

    useEffect(() => {
      if (
        isEdit &&
        !isEmpty(merchantProcessors) &&
        !isEmpty(getProcessorResponse) &&
        !loadingModal.isLoading
      ) {
        getUsedProcessorAlias(true);
      }

      if (
        !isEmpty(merchantProcessors) &&
        isEmpty(getProcessorResponse) &&
        !loadingModal.isLoading
      ) {
        getUsedProcessorAlias(false);
      }

      if (
        !isEmpty(getProcessorResponse) &&
        !isEmpty(merchantProcessorInfo) &&
        !loadingModal.isLoading
      ) {
        const defaultProcessorId: string = get(
          merchantProcessorInfo,
          "defaultProcessor.payoutsCard",
          ""
        );
        const isDefaultProcessor: boolean = defaultProcessorId === processorId;
        const processorData: CreateProcessor = {
          currency: get(getProcessorResponse, "processorInfo.currency", ""),
          defaultProcessor: isDefaultProcessor,
          mcc: get(getProcessorResponse, "mcc", ""),
          merchantCategory: CATEGORY_OPTIONS_RADIO_ITEMS[0].value,
          merchantModel: MODEL_OPTIONS_RADIO_ITEMS[0].value,
          processorAlias: get(getProcessorResponse, "processorInfo.alias", ""),
          processorName: PROCESSOR_OPTIONS_RADIO_ITEMS[0].value,
        };

        form.reset(processorData);
        setIsDefaultProcessor(isDefaultProcessor);
      }
    }, [
      isEdit,
      merchantProcessors,
      merchantProcessorInfo,
      getProcessorResponse,
      loadingModal,
    ]);

    useEffect(() => {
      if (processorId && processorId != "") {
        setIsEdit(true);
        dispatch(getProcessor({ processorId: processorId! }));
      }

      if (merchantId && merchantId != "") {
        loadData();
        setMerchantInfo({
          country: get(merchant, "country"),
          merchantName: get(merchant, "name"),
          origin: get(merchant, "origin"),
        });
      }
    }, []);

    useEffect(() => {
      if (merchantInfo.origin != "") setRedirectUrl(getRedirectPath());
    }, [merchantInfo]);

    const handleSave = () => {
      const formValues = form.getValues();

      const payload: CreateProcessorRequest = {
        basicInfo: {
          categoryModel: get(formValues, InputNameEnum.MERCHANT_CATEGORY, ""),
          processorType: get(formValues, InputNameEnum.MERCHANT_MODEL, ""),
        },
        mcc: get(formValues, InputNameEnum.MCC, ""),
        merchantCountry: get(billingCoreNodeResponse, "country", ""),
        merchantId: defaultTo(merchantId, ""),
        processorInfo: {
          alias: get(formValues, InputNameEnum.PROCESSOR_ALIAS, ""),
          currency: get(formValues, InputNameEnum.CURRENCY, ""),
        },
        processorName: get(formValues, InputNameEnum.PROCESSOR_NAME, ""),
      };

      if (isEdit) {
        set(payload, "status", StatusEnum.ENABLED);
        dispatch(
          updateProcessor({
            payload,
            processorId: processorId!,
          })
        );
      } else {
        dispatch(createProcessor(payload));
      }
    };

    useEffect(() => {
      if (!isEmpty(createProcessorResponse.publicProcessorId)) {
        const formValues = form.getValues();
        const defaultProcessorId: string = get(
          merchantProcessorInfo,
          "defaultProcessor.payoutsTransfer",
          ""
        );

        if (formValues.defaultProcessor) {
          const setDefaultProcessorPayload: SetDefaultProcessorRequest = {
            defaultProcessor: {
              merchantId,
              payoutsCard: createProcessorResponse.publicProcessorId,
              payoutsTransfer: defaultProcessorId,
            },
          };

          dispatch(setAsDefaultProcessor(setDefaultProcessorPayload));
        }
      }
    }, [createProcessorResponse]);

    useEffect(() => {
      if (isEdit && !isEmpty(setDefaultProcessorResponse.message)) {
        window.location.href = redirectUrl;
      }
    }, [setDefaultProcessorResponse, isEdit]);

    useEffect(() => {
      if (isEdit && !showCreatingProcessorModal && processorDataSaved) {
        window.location.href = redirectUrl;
      }
    }, [isEdit, showCreatingProcessorModal, processorDataSaved]);

    const handleChangeShowSnackbar = () => {
      dispatch(dismissSnackbar());
    };

    const handleOnReturn = () => {
      window.location.href = redirectUrl;
    };

    const getMerchantInfo = (merchantId: string): RedirectObject => {
      return {
        redirectAddProcessorLabel: "Agregar nuevo procesador",
        redirectButtonLabel: TextEnum.finishAddProcessor,
        redirectPath: redirectUrl,
        redirectPathAddProcessor: `/processor-payouts-card/${merchantId}`,
      };
    };

    return {
      categoryOptions,
      defaultProcessor,
      disableCheckbox,
      form,
      getMerchantInfo,
      handleChangeShowSnackbar,
      handleCloseFinalModal,
      handleOnReturn,
      handleSave,
      isDefaultProcessor,
      isEdit,
      loadingModal,
      merchantId: defaultTo(merchantId, ""),
      merchantInfo,
      modelOptions,
      processorTypes,
      registeredProcessorAlias,
      setCategoryOptions,
      setModelOptions,
      setProcessorTypes,
      showCreatingProcessorModal,
      showFinalModal,
      snackbarError,
    };
  };
