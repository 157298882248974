import React, { useState } from "react";
import { AppBar, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { MODAL_TABS } from "../../../shared/infrastructure/constants/ModalTabs";
import { ModalBodyProps } from "../ModalBody";
import InformationComponent from "../InformationComponent/InformationComponent";
import TimeLineComponent from "../TimeLineComponent/TimeLineComponent";
import ConfigurationComponent from "../ConfigurationComponent/ConfigurationComponent";

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      color: "#293036 !important",
      lineHeight: "170% !important",
      fontStyle: "normal !important",
      fontWeight: "normal !important" as "normal",
      fontSize: "15px !important",
      textTransform: "none" as "none",
    },
    title: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 16,
      lineHeight: "100",
    },
  })
);

const TabComponent = (props: ModalBodyProps): JSX.Element => {
  const classes = useStyles();
  const [valueTab, setValueTab] = useState<number>(0);

  const tabsProps = (index: any) => {
    return {
      id: `scrollable-prevent-tab-${index}`,
      "aria-controls": `scrollable-prevent-tabpanel-${index}`,
      value: index,
    };
  };

  const handleChangeTab = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setValueTab(newValue);
  };

  return (
    <div style={{ marginLeft: "-25px", marginRight: "-25px" }}>
      <AppBar position="sticky" color="transparent">
        <Tabs
          value={valueTab}
          variant="fullWidth"
          onChange={handleChangeTab}
          indicatorColor={"primary"}
          centered={true}
        >
          {MODAL_TABS.map((value: string, index: number) => (
            <Tab
              key={index}
              label={
                <Typography variant="subtitle2" className={classes.label}>
                  {value}
                </Typography>
              }
              {...tabsProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>

      <Paper
        square
        hidden={valueTab !== 0}
        id={`scrollable-prevent-tabpanel-${0}`}
        aria-labelledby={`scrollable-prevent-tab-${0}`}
      >
        <InformationComponent {...props} />
      </Paper>
      <div
        hidden={valueTab !== 1}
        id={`scrollable-prevent-tabpanel-${1}`}
        aria-labelledby={`scrollable-prevent-tab-${1}`}
      >
        <ConfigurationComponent {...props} />
      </div>
      <div
        hidden={valueTab !== 2}
        id={`scrollable-prevent-tabpanel-${2}`}
        aria-labelledby={`scrollable-prevent-tab-${2}`}
      >
        <TimeLineComponent {...props} />
      </div>
    </div>
  );
};

export default TabComponent;
