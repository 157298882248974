import React from "react";
import { StatusSteps } from "../../../../types/remote/semaphore_data";
import { BasicDataPendingSection } from "./BasicDataPendingSection";

export const BasicDataSection: React.FC<any> = (
  _: string,
  _0: (step: string, status: StatusSteps) => void
) => {
  return (
    <React.Fragment>
      <BasicDataPendingSection />
    </React.Fragment>
  );
};
