import React, { PropsWithChildren, useState } from "react";
import { Grid, TextField } from "@mui/material";
import { DEFAULT_ITEM_BY_TAG } from "../../../../../shared/infrastructure/enums/ItemByTagEnum";
import { ErrorsEnum } from "../../../../../shared/infrastructure/enums/ErrorEnum";
import { defaultTo, get } from "lodash";
import { retrieveFormError } from "../../../../../utils/utilsFile";
import { FabButtonsContainer } from "../../FabButtonsContainer/FabButtonsContainer";
import { useFormContext } from "react-hook-form";
import { ITextFieldCardIdSectionListProps } from "../../../../../shared/infrastructure/interfaces/ITextFieldCardIdSectionList";

export const TextFieldCardIdSectionList: React.FC<
  ITextFieldCardIdSectionListProps
> = (props: PropsWithChildren<ITextFieldCardIdSectionListProps>) => {
  const [isEditCardId, setIsEditCardId] = useState<boolean>(true);
  const [isRenderCardId, setIsRenderCardId] = useState<boolean>(true);
  const keyName: string = `${DEFAULT_ITEM_BY_TAG[props.tag].name}-${
    props.keyIndex
  }`;
  const { watch, setValue } = useFormContext();
  const inputText: string = watch(keyName);
  const [flagEditCardId, setFlagEditCardId] = useState<boolean>(false);

  if (inputText === "" && !flagEditCardId) setValue(keyName, props.value);

  return (
    <Grid container direction={"row"} spacing={1.2}>
      <Grid item xs={4.1} sx={{ mb: 1 }}>
        <TextField
          id={keyName}
          name={keyName}
          defaultValue={props.value}
          placeholder={DEFAULT_ITEM_BY_TAG[props.tag].placeholder}
          variant="outlined"
          fullWidth
          disabled={!isEditCardId}
          inputRef={props.register({
            required: {
              message: ErrorsEnum.REQUIRED_FIELD,
              value: defaultTo(
                DEFAULT_ITEM_BY_TAG[props.tag].isRequiredEdit,
                false
              ),
            },
            pattern: {
              message: props.errMsg,
              value: DEFAULT_ITEM_BY_TAG[props.tag].pattern,
            },
          })}
          onBlur={(
            event: React.FocusEvent<
              HTMLInputElement | HTMLTextAreaElement,
              Element
            >
          ) => {
            const targetValue: string = get(event, "target.value", "");
            if (DEFAULT_ITEM_BY_TAG[props.tag].pattern.test(targetValue)) {
              props.handleEdit(get(event, "target.value", ""));
              setIsEditCardId(false);
            } else {
              setIsEditCardId(true);
            }
          }}
          onChange={(
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setValue(keyName, get(event, "target.value", ""));
          }}
          error={get(props.errors, keyName)}
          helperText={retrieveFormError(props.errors, keyName)[1]}
          autoFocus={true}
          onSelect={() => {
            if (isRenderCardId) {
              setIsEditCardId(false);
              setIsRenderCardId(false);
            }
          }}
        />
      </Grid>
      {!props.isDisable && (
        <FabButtonsContainer
          handleEditFabButton={() => {
            setFlagEditCardId(true);
            setIsEditCardId(true);
          }}
          handleDeleteFabButton={() => {
            setValue(keyName, "");
            props.handleDelete();
          }}
        />
      )}
    </Grid>
  );
};
