/* istanbul ignore file */
import { IOptionSelection } from "./infrastructure/constants/RulesConstants";
import { get } from "lodash";
import axios from "./axios-util";
import { CIIU_CATALOG } from "./infrastructure/constants/ciiu-catalog";
import { MCC_CATALOG } from "./infrastructure/constants/mcc-catalog";
import { IComplianceResponse } from "../../types/compliance_response";
import { environment } from "../environments/environment";
import { CountriesEnum } from "./infrastructure/constants/countries-enum";

export const translateRuleKeys = (
  text: string,
  targets: IOptionSelection[]
): string => {
  if (text === undefined) return "";
  const index: number = targets.findIndex(
    (detail: IOptionSelection) => detail.value === text
  );

  if (index >= 0) {
    const translation: string | undefined = get(targets, `[${index}].name`);

    return translation !== undefined ? translation : text;
  }

  return text;
};

export const getCIIU = (ciiu?: string): string => {
  if (ciiu) {
    return get(CIIU_CATALOG, ciiu, "");
  }

  return "";
};

export const getMCC = (mcc?: string): string => {
  if (mcc) {
    return get(MCC_CATALOG, mcc, "");
  }

  return "";
};

export const getComplianceResponse = async (
  publicMerchantId: string
): Promise<IComplianceResponse> => {
  const { data } = await axios.get<IComplianceResponse>(
    `${environment.kushkiUrl}/compliance/v1/compliance-people/${publicMerchantId}`
  );

  return data;
};

export const concatObjectValuesFromObject = <T>(object: T) => {
  return Object.values(object).filter(Boolean).join(" ");
};

export const centralcounties: string[] = [
  CountriesEnum.PANAMA,
  CountriesEnum.EL_SALVADOR,
  CountriesEnum.PANAMA,
  CountriesEnum.COSTA_RICA,
  CountriesEnum.NICARAGUA,
  CountriesEnum.HONDURAS,
  CountriesEnum.GUATEMALA,
];

export const calculateDateUtc = (
  dateTimestamp: number,
  isStart: boolean
): number => {
  const date = new Date(dateTimestamp);

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    isStart ? 0 : 23,
    isStart ? 0 : 59,
    isStart ? 0 : 59
  ).getTime();
};
