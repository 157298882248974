import { MessageEnum } from "../Enums/MessageEnum";

export enum ErrorUploadCatalogMessageEnumEnglish {
  SHEET_NAME = "The name of a sheet is not correct",
  FILE_EMPTY = "The file is empty",
  BAD_FORMAT_ACTIVITY = "The data in sheet contains errors, sheet: activity",
  BAD_FORMAT_TAXPAYERTYPE = "The data in sheet contains errors, sheet: taxPayerType",
  BAD_FORMAT_INCOMESOURCECONCEPT = "The data in sheet contains errors, sheet: incomeSourceConcept",
  UPLOAD_CATALOG_ERROR_PREFIX = "Archivo inválido - ",
}

export enum ErrorUploadCatalogMessageEnumSpanish {
  SHEET_NAME = "El nombre de una hoja no es correcto.",
  FILE_EMPTY = "El archivo esta vacío.",
  BAD_FORMAT_ACTIVITY = "Los datos en la hoja contienen errores, hoja: Actividad",
  BAD_FORMAT_TAXPAYERTYPE = "Los datos en la hoja contienen errores, hoja: TipoContribuyente",
  BAD_FORMAT_INCOMESOURCECONCEPT = "Los datos en la hoja contienen errores, hoja: Concepto",
}

export const ErrorUploadCatalog: Record<string, string> = {
  [ErrorUploadCatalogMessageEnumEnglish.BAD_FORMAT_ACTIVITY]:
    ErrorUploadCatalogMessageEnumSpanish.BAD_FORMAT_ACTIVITY,
  [ErrorUploadCatalogMessageEnumEnglish.BAD_FORMAT_TAXPAYERTYPE]:
    ErrorUploadCatalogMessageEnumSpanish.BAD_FORMAT_TAXPAYERTYPE,
  [ErrorUploadCatalogMessageEnumEnglish.BAD_FORMAT_INCOMESOURCECONCEPT]:
    ErrorUploadCatalogMessageEnumSpanish.BAD_FORMAT_INCOMESOURCECONCEPT,
  [ErrorUploadCatalogMessageEnumEnglish.FILE_EMPTY]:
    ErrorUploadCatalogMessageEnumSpanish.FILE_EMPTY,
  [ErrorUploadCatalogMessageEnumEnglish.SHEET_NAME]:
    ErrorUploadCatalogMessageEnumSpanish.SHEET_NAME,
  [MessageEnum.ERROR_UPDATE_CATALOG_ITEM]:
    MessageEnum.ERROR_UPDATE_CATALOG_ITEM,
};
