import { Icon } from "@material-ui/core";
import pendingIcon from "../../../../assets/images/PNDNG.svg";
import React from "react";
import { useStyles } from "../../DetailsCard.styles";

export const PNDNGIcon = () => {
  const classes = useStyles();

  return (
    <Icon className={classes.tlIcon}>
      <img alt={"pending icon"} src={pendingIcon} />
    </Icon>
  );
};
