import { IModalContainerBranch } from "./DataTableContainerBranch.interface";
import { useModalContainerBranch } from "./state/useModalContainerBranch";
import { ModalDetail } from "@kushki/connect-ui";
import React from "react";
import { ContentModalDetailBranch } from "../ContentModalDetail/ContentModalDetailBranch";
import { ModalDetailHeaderBranch } from "../ModalDetailHeaderBranch/ModalDetailHeaderBranch";
import { ConditionsBranch } from "../ConditionsModal/ConditionsBranch";
import { ElementModalComponent } from "../ModalContainerBranch/ElementModalComponent/ElementModalComponent";

export const ModalContainer: React.FC<IModalContainerBranch> = () => {
  const { onChangeModal, header, isOpenModal, data, isLoadingOneRule } =
    useModalContainerBranch();

  return (
    <React.Fragment>
      <ModalDetail
        header={header}
        onClose={onChangeModal}
        variant={"content"}
        isOpen={isOpenModal}
        isLoading={isLoadingOneRule}
      >
        {!isLoadingOneRule && (
          <ContentModalDetailBranch>
            <ModalDetailHeaderBranch {...data.ruleModalHeader} />
            <ElementModalComponent {...data.ruleSetting} isVisible={true} />
            <ConditionsBranch />
          </ContentModalDetailBranch>
        )}
      </ModalDetail>
    </React.Fragment>
  );
};
