import { useEffect, useState } from "react";
import { MerchantData } from "../../../../types/merchant-data";
import {
  IButtonOptions,
  ILabel,
} from "../../../components/FooterOptions/FooterOptions.interfaces";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { ConfigIdEnum } from "../../../shared/constants/initial_state_legal_details";
import { ActionFooterEnum } from "../../../shared/enums/ActionFooterEnum";
import { addDefaultCommercialExecutive } from "../../../shared/utils/contact_data_utils";
import {
  setLoaderModalOpen,
  setTypeButtonContactData,
} from "../../../store/actions/contactData.actions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { RootState } from "../../../store/store";
import { IContactDataState } from "./useContactDataContainer.interfaces";
import { get, isEmpty } from "lodash";
import { TabItemStatusEnum } from "../../../shared/enum/tabItemStatusEnum";
import { format } from "date-fns";

import {
  createSemaphoreBranches,
  listBranchesSelected,
  objectSaveMassiveBranch,
} from "../../../shared/utils/massiveBranchEdit";
import {
  setBranchBatch,
  setListBranchMassiveTitle,
  setNotificationMassive,
} from "../../../store/actions/massiveBranch.actions";
import { updateMassiveBranch } from "../../../store/thunks/massiveBranch/massiveBranchs.thunks";
import { useDispatch } from "react-redux";
import { setStatusSocket } from "../../../store/actions/massiveBranch/massiveBranch.actions";
import { redirectPath } from "../../../shared/utils/company_data_utils";
import { useContactDataContainerGeneral } from "../../../shared/utils/state/useContactDataGeneral";
import {
  IComplianceData,
  IContactDataMassive,
} from "../../../shared/interfaces/IContactDataMassive.interface";
import { useSnackbar } from "@kushki/connect-ui";
import { StatusSocketEnum } from "../../../shared/enum/socketEnum";
import getCommercialExecutives from "../../../store/thunks/commercialExecutiveList/commercialExecutiveList.thunks";
import { MERCHANT_VALUE } from "../../../shared/enum/ContactDataTextsEnum";

export const useContactDataContainerMassive = (): IContactDataState => {
  const dispatch = useAppDispatch();
  const {
    handleChangeStatusNodeInfo,
    countriesInfo,
    commercialExecutiveList,
    setExecutiveList,
    executiveList,
    merchantSize,
    commercialExecutive,
    commercialExecutiveEmail,
    merchantComplianceData,
    isUpdate,
    isContinueAction,
    handleSaveData,
    handleIsContinueAction,
    isContinueDisabled,
    handleSaveLoader,
    handleCancelModal,
    isCentralizedBranch,
    isSaveDisabled,
    isEdit,
    saveLoader,
    setIsEditing,
    anchorEl,
    headerProps,
    categoryAreas,
    contactAreas,
    name,
    valuesItem,
    email,
    isDataNoErrors,
    handleClosePopover,
    handleOpenEdit,
    handleOpenModalDelete,
    handleOpenPopover,
    handlerDeleteContactPerson,
    handleSetValuesItem,
    openModalDelete,
    handleIsDataCompleted,
    isDataCompleted,
    onSubmit,
    openModal,
    isEditing,
    handleIsDataNoErrors,
    handleEditContact,
    handleFormChange,
    handleOpenModal,
    isAreasSelected,
    handleIsNameValid,
    isNameValid,
    form,
    configsInfo,
    isLoadingContactData,
  } = useContactDataContainerGeneral(true);

  const { showSnackbar } = useSnackbar();
  const { contactData, isSaved, isDisableAddPersonBtn, typeButton } =
    useAppSelector((state: RootState) => state.contactData);

  const {
    branchBatch,
    title,
    listMerchants,
    listBranchesMassive,
    notificationMassive,
  } = useAppSelector((state: RootState) => state.branchBatch);

  const { updateMassiveBranchStatus, statusSocket } = useAppSelector(
    (state: RootState) => state.massiveBranch
  );
  const dispatchWS = useDispatch();

  const [isModalFinalization, setModalFinalization] = useState<boolean>(false);

  const setFinalization = (stateModal: boolean) => {
    setModalFinalization(stateModal);
  };

  const saveContactDataDB = async (status: TabItemStatusEnum) => {
    const getEmails: object[] = executiveList.filter(
      (item) => item.id == commercialExecutive
    );

    const new_merchant_compliance: IComplianceData = {
      categoryMerchant: merchantSize,
      commercialExecutive: get(getEmails, "[0].commercialName", ""),
      commercialExecutiveEmail: get(
        getEmails,
        "[0].commercialEmail",
        commercialExecutiveEmail
      ),
      constitutionalCountry: countriesInfo.constitutionalCountry,
      country: countriesInfo.country,
      kind: get(
        merchantComplianceData,
        "merchantComplianceData.kind",
        MERCHANT_VALUE
      ),
      merchantName: get(
        merchantComplianceData,
        "merchantComplianceData.merchantName",
        ""
      ),
      status: get(merchantComplianceData, "merchantComplianceData.status", ""),
    };

    const general_contact_data = {
      ...contactData,
      contactCommercial: {
        email: get(contactData, "contactCommercialArea[0].email"),
        name: get(contactData, "contactCommercialArea[0].name"),
      },
      contactPerson: get(contactData, "contactBillingArea[0].name", ""),
      contactTechnical: {
        email: get(contactData, "contactTechnicalArea[0].email"),
        name: get(contactData, "contactTechnicalArea[0].name"),
      },
      email: get(contactData, "contactBillingArea[0].email"),
    };
    const update_data: Partial<MerchantData> = {
      ...general_contact_data,
      categoryMerchant: merchantSize,
      configId: ConfigIdEnum.CN002,
      constitutionalCountry: countriesInfo.constitutionalCountry,
      country: countriesInfo.country,
      merchantRank: merchantSize,
      merchantSize: merchantSize,
    };

    await dispatchWS(
      updateMassiveBranch(
        objectSaveMassiveBranch(
          {
            complianceData: new_merchant_compliance,
            contactData: update_data,
          } as IContactDataMassive,
          ConfigIdEnum.CN002,
          listBranchesMassive,
          status
        )
      )
    );
  };

  const changeStatusNodeInfo = (status: TabItemStatusEnum) => {
    handleChangeStatusNodeInfo(
      status,
      ConfigIdEnum.CN002,
      get(branchBatch[2], "publicMerchantId")
    );
    dispatch(setTypeButtonContactData(StatusSocketEnum.NONE));
    dispatchWS(setStatusSocket(StatusSocketEnum.NONE));
  };

  const saveValues = async (typeButton: ActionFooterEnum) => {
    await handleSaveData();

    switch (typeButton) {
      case ActionFooterEnum.SAVE:
        handleIsContinueAction(false);
        const status: TabItemStatusEnum = !isContinueDisabled
          ? TabItemStatusEnum.COMPLETE
          : TabItemStatusEnum.PENDING;

        dispatch(setLoaderModalOpen(true));
        handleSaveLoader(true);
        await saveContactDataDB(status);
        dispatch(setLoaderModalOpen(false));
        break;
      case ActionFooterEnum.CONTINUE:
        dispatch(setLoaderModalOpen(true));
        dispatch(setTypeButtonContactData(ActionFooterEnum.CONTINUE));
        await saveContactDataDB(TabItemStatusEnum.COMPLETE);
        handleIsContinueAction(true);

        break;
    }
  };

  const primaryButton: IButtonOptions = {
    isDisabled: isContinueDisabled,
    isHidden: true,
    onAction: () => setFinalization(true),
  };
  const secondaryButton: IButtonOptions = {
    isDisabled: saveLoader || isSaveDisabled || (isCentralizedBranch && isEdit),
    isHidden: true,
    onAction: () => saveValues(ActionFooterEnum.SAVE),
  };
  const footerLabel: ILabel = {
    date: format(
      get(configsInfo, `${ConfigIdEnum.CN002}.updatedAt`, 0),
      "dd/MM/yyyy '-' HH:mm"
    ),
    editDetail: false,
    isHidden: true,
    text:
      "Modificado por " +
      get(configsInfo, `${ConfigIdEnum.CN002}.updatedBy`, ""),
  };

  const handlerOnCancelEdit = () => {
    setIsEditing(false);
    handleCancelModal(false);
  };

  const openPopover = Boolean(anchorEl);

  useEffect(() => {
    dispatch(getCommercialExecutives());
    if (isEmpty(branchBatch)) {
      dispatch(setBranchBatch(createSemaphoreBranches()));
      dispatch(setListBranchMassiveTitle(listBranchesSelected()));
    }
  }, []);

  useEffect(() => {
    addDefaultCommercialExecutive(commercialExecutiveList, setExecutiveList);
  }, [commercialExecutiveList]);

  useEffect(() => {
    if (isUpdate && isSaved && isContinueAction) {
      window.location.href = API_ROUTES.FINANCIAL_CONFIGURATION(
        get(branchBatch[2], "publicMerchantId")
      );
    }
  }, [isUpdate, isSaved, isContinueAction]);

  useEffect(() => {
    if (statusSocket === StatusSocketEnum.SUCCESS) {
      const status: TabItemStatusEnum = !isContinueDisabled
        ? TabItemStatusEnum.COMPLETE
        : TabItemStatusEnum.PENDING;

      changeStatusNodeInfo(status);
      if (typeButton === ActionFooterEnum.CONTINUE) {
        redirectPath("/create-node/branch/edit");
      }
    }
  }, [statusSocket, typeButton]);

  useEffect(() => {
    if (notificationMassive) {
      showSnackbar(notificationMassive);
      dispatch(setNotificationMassive(undefined));
    }
  }, [notificationMassive]);

  return {
    categoryAreas,
    commercialExecutive,
    contactAreas,
    contactDataAreasProps: {
      anchorEl,
      handleClosePopover,
      handleOpenEdit,
      handleOpenModalDelete,
      handleOpenPopover,
      handlerDeleteContactPerson,
      handlerOnCancelEdit,
      handleSetValuesItem,
      openModalDelete,
      openPopover,
      valuesItem,
    },
    contactDataModal: {
      email,
      name,
    },
    countriesInfo: {
      constitutionalCountry: countriesInfo.constitutionalCountry,
      country: countriesInfo.country,
    },
    dataCompleted: {
      handleIsDataCompleted,
      isDataCompleted,
    },

    dataNoErrors: {
      handleIsDataNoErrors,
      isDataNoErrors,
    },
    executiveList,
    footerActions: {
      footerLabel: !isEmpty(get(configsInfo, `${ConfigIdEnum.CN002}.updatedBy`))
        ? footerLabel
        : {
            date: "",
            editDetail: false,
            isHidden: true,
            text: "",
          },
      handleSaveLoader,
      primaryButton,
      saveLoader,
      secondaryButton,
    },
    form,
    handleCancelModal,
    handleChangeStatusNodeInfo,
    handleEditContact,
    handleFormChange,
    handleIsContinueAction,
    handleOpenModal,
    headerProps,
    isAreasSelected,
    isCentralizedBranch,
    isDisableAddPersonBtn,
    isEdit,
    isEditing,
    isLoadingContactData,
    isModalFinalization,
    merchantSize,
    nameValidation: {
      handleIsNameValid,
      isNameValid,
    },
    numberBranches: `${listMerchants.split(",").length}`,
    onSubmit,
    openModal,

    openModalLoader: updateMassiveBranchStatus!,
    saveValues,
    setFinalization,
    title,
  };
};
