export enum HeaderTableLabel {
  ACTIVATION_DATE = "Fecha de activación",
  BRANCH_MERCHANT = "Branch/Merchant",
  ALIAS = "Alias",
  OWNER = "Owner",
  CUSTOMER = "Customer",
  COUNTRY = "País de operación",
  VARIABLES = "Variables",
  STATE = "Estado",
  CATEGORY = "Categoría",
  CLOSE_DATE = "Fecha de cierre",
  FREQUENCY = "Frecuencia",
}
