import React, { RefObject } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  MenuItem,
  Switch,
  TextField,
  Typography,
  RadioGroup,
  Radio,
  InputLabel,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  CURRENCY_COUNTRY,
  getCatalog,
  INVOICE_FRECUENCY,
} from "../../shared/infrastructure/constants/MerchantInformationConstant";
import { CatalogsEnum } from "../../shared/infrastructure/constants/catalogs-enum";
import { MerchantResponse } from "../../../types/merchant_response";
import { BillingConfig } from "../../../types/billing_config";
import { ParameterizationBillingEnum } from "../../shared/infrastructure/constants/parameterization-enum";
import { CountriesEnum } from "../../shared/infrastructure/constants/countries-enum";
import {
  Control,
  Controller,
  FieldError,
  NestDataObject,
} from "react-hook-form";
import { PatternEnum } from "../../shared/infrastructure/constants/pattern-enum";

export interface BillingEditSectionProps {
  merchant: MerchantResponse | undefined;
  billingConfig: BillingConfig;
  handleFormChange: (
    state: string,
    name: string,
    value: string | boolean | number
  ) => void;
  control: Control<Record<string, any>>;
  register: (
    obj: object
  ) => ((instance: any) => void) | RefObject<any> | null | undefined;
  errors: NestDataObject<Record<string, any>, FieldError>;
}

const useStyles = makeStyles(() => ({
  labelTitle2: {
    color: "#023365",
    fontSize: "17px",
    fontWeight: 500,
    fontStyle: "normal",
  },
  labelTitle3: {
    color: "#46525C",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  labelColumns: {
    color: "#6D7781",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
  },
  labelValues: {
    color: "#293036",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  styleSelect: {
    width: "60%",
  },
}));

export const BillingEditSection: React.FC<BillingEditSectionProps> = (
  props: BillingEditSectionProps
) => {
  const style = useStyles();

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item container>
          <Grid item xs={12}>
            <Box display={"flex"} alignItems={"center"}>
              <Box flexGrow={1}>
                <Typography className={style.labelTitle2}>
                  Facturación
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className={style.labelTitle3}>
              Datos que utilizamos para emitir facturas al comercio
            </Typography>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid
            container
            spacing={2}
            justify={"flex-start"}
            alignItems={"baseline"}
          >
            <Grid item xs={12} md={6}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                  <Typography className={style.labelColumns}>
                    Razón social
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography className={style.labelValues}>
                    {props.merchant?.socialReason}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography className={style.labelColumns}>TAX ID</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography className={style.labelValues}>
                    {props.merchant?.taxId}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={2}>
              <Typography className={style.labelColumns}>Frecuencia</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth variant={"outlined"}>
                <InputLabel htmlFor="invoiceFrequency">Frecuencia</InputLabel>
                <Controller
                  control={props.control}
                  id="invoiceFrequency"
                  name="invoiceFrequency"
                  as={
                    <Select
                      label="Frecuencia"
                      value={props.merchant?.invoiceFrequency}
                    >
                      {INVOICE_FRECUENCY.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  defaultValue={props.merchant?.invoiceFrequency}
                  valueName="invoiceFrequencySel"
                  onChange={([invoiceFrequencySel]) =>
                    props.handleFormChange(
                      "merchant",
                      "invoiceFrequency",
                      invoiceFrequencySel.target.value as string
                    )
                  }
                />
                {!!props.errors.invoiceFrequency && (
                  <FormHelperText>
                    {props.errors.invoiceFrequency.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12} md={2}>
              <Typography className={style.labelColumns}>Email</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                id="email"
                name="email"
                label="Email"
                type="text"
                fullWidth
                value={props.merchant?.email}
                onChange={(e) =>
                  props.handleFormChange("merchant", "email", e.target.value)
                }
                variant="outlined"
                inputRef={props.register({
                  required: "Debe ingresar un email",
                  pattern: {
                    value: new RegExp(PatternEnum.email),
                    message: "Email inválido",
                  },
                })}
                helperText={
                  props.errors.email ? props.errors.email.message : ""
                }
                error={!!props.errors.email}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12} md={2}>
              <Typography className={style.labelColumns}>Dirección</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                id="address"
                name="address"
                label="Dirección"
                type="text"
                fullWidth
                value={props.merchant?.address}
                onChange={(e) =>
                  props.handleFormChange("merchant", "address", e.target.value)
                }
                variant="outlined"
                inputRef={props.register({
                  required: "Debe ingresar una dirección",
                })}
                helperText={
                  props.errors.address ? props.errors.address.message : ""
                }
                error={!!props.errors.address}
              />
            </Grid>
            <Grid item xs={1} />

            <Grid item xs={12} md={2}>
              <Typography className={style.labelColumns}>Teléfono</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                id="phoneNumber"
                name="phoneNumber"
                label="N° de Teléfono"
                type="text"
                fullWidth
                value={props.merchant?.phoneNumber}
                onChange={(e) =>
                  props.handleFormChange(
                    "merchant",
                    "phoneNumber",
                    e.target.value
                  )
                }
                variant="outlined"
                inputRef={props.register({
                  required: "Debe ingresar un teléfono",
                  pattern: {
                    value: new RegExp(PatternEnum.phone),
                    message: "Debe tener entre 1 y 50 caracteres alfanuméricos",
                  },
                })}
                helperText={
                  props.errors.phoneNumber
                    ? props.errors.phoneNumber.message
                    : ""
                }
                error={!!props.errors.phoneNumber}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12} md={2}>
              <Typography className={style.labelColumns}>
                Departamento
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth variant={"outlined"}>
                <InputLabel htmlFor="province">Departamento</InputLabel>
                <Controller
                  control={props.control}
                  id="province"
                  name="province"
                  as={
                    <Select
                      label="Departamento"
                      value={props.merchant?.province}
                    >
                      {getCatalog(
                        props.merchant!.country,
                        CatalogsEnum.Provinces
                      ).map((option) => (
                        <MenuItem key={option.value} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  defaultValue={props.merchant?.province}
                  valueName="provinceSel"
                  onChange={([provinceSel]) =>
                    props.handleFormChange(
                      "merchant",
                      "province",
                      provinceSel.target.value as string
                    )
                  }
                />
                {!!props.errors.province && (
                  <FormHelperText>
                    {props.errors.province.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={1} />
            <Grid item md={6} />
            <Grid item xs={12} md={2}>
              <Typography className={style.labelColumns}>Ciudad</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                id="city"
                name="city"
                label="Ciudad"
                type="text"
                fullWidth
                variant="outlined"
                onChange={(e) =>
                  props.handleFormChange("merchant", "city", e.target.value)
                }
                value={props.merchant?.city}
                inputRef={props.register({
                  required: "Debe ingresar una ciudad",
                })}
                helperText={props.errors.city ? props.errors.city.message : ""}
                error={!!props.errors.city}
              />
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Grid>

        <Grid item container>
          <Grid item xs={12}>
            <Box display={"flex"} alignItems={"center"}>
              <Box flexGrow={1}>
                <Typography className={style.labelTitle2}>Cobros</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className={style.labelTitle3}>
              Configura como cobramos nuestras comisiones
            </Typography>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid
            container
            spacing={2}
            alignContent={"center"}
            justify={"flex-start"}
            alignItems={"baseline"}
          >
            <Grid item xs={12} md={2}>
              <Typography className={style.labelColumns}>Frecuencia</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl variant={"outlined"} fullWidth>
                <InputLabel htmlFor="chargeFrequency">Frecuencia</InputLabel>
                <Controller
                  control={props.control}
                  id="chargeFrequency"
                  name="chargeFrequency"
                  as={
                    <Select
                      label="Frecuencia"
                      value={props.merchant?.chargeFrequency}
                    >
                      {INVOICE_FRECUENCY.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  defaultValue={props.merchant?.chargeFrequency}
                  valueName="chargeFrequencySel"
                  onChange={([chargeFrequencySel]) =>
                    props.handleFormChange(
                      "merchant",
                      "chargeFrequency",
                      chargeFrequencySel.target.value as string
                    )
                  }
                />
                {!!props.errors.chargeFrequency && (
                  <FormHelperText>
                    {props.errors.chargeFrequency.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12} md={2}>
              <Typography className={style.labelColumns}>Banco</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth variant={"outlined"}>
                <InputLabel htmlFor="bankId">Banco</InputLabel>
                <Controller
                  control={props.control}
                  id="bankId"
                  name="bankId"
                  as={
                    <Select label="Banco" value={props.merchant?.bankId}>
                      {getCatalog(
                        props.merchant!.country,
                        CatalogsEnum.Banks
                      ).map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  defaultValue={props.merchant?.bankId}
                  valueName="bankIdSel"
                  onChange={([bankIdSel]) =>
                    props.handleFormChange(
                      "merchant",
                      "bankId",
                      bankIdSel.target.value as string
                    )
                  }
                />
                {!!props.errors.chargeFrequency && (
                  <FormHelperText>
                    {props.errors.chargeFrequency.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12} md={2}>
              <Typography className={style.labelColumns}>
                Retener comisión
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="position"
                  name="chargeMin"
                  value={props.merchant?.chargeMin}
                  onChange={(e) =>
                    props.handleFormChange(
                      "merchant",
                      "chargeMin",
                      e.target.value === "true" ? true : false
                    )
                  }
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio color="primary" />}
                    label="Sí"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12} md={2}>
              <Typography className={style.labelColumns}>
                Cta. Bancaria
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                id="accountNumber"
                name="accountNumber"
                label="N. de cuenta"
                type="text"
                fullWidth
                value={props.merchant?.accountNumber}
                onChange={(e) =>
                  props.handleFormChange(
                    "merchant",
                    "accountNumber",
                    e.target.value
                  )
                }
                variant="outlined"
                inputRef={props.register({
                  required: "Debe ingresar una cuenta bancaria",
                  pattern: {
                    value:
                      props.merchant!.country === CountriesEnum.COLOMBIA
                        ? new RegExp(PatternEnum.numeric5to20)
                        : "",
                    message:
                      props.merchant!.country === CountriesEnum.COLOMBIA
                        ? "Deber tener entre 5 y 20 dígitos"
                        : "",
                  },
                })}
                helperText={
                  props.errors.accountNumber
                    ? props.errors.accountNumber.message
                    : ""
                }
                error={!!props.errors.accountNumber}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12} md={2}>
              <Typography
                hidden={!props.merchant?.chargeMin}
                className={style.labelColumns}
              >
                Cobro mínimo
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={props.merchant?.country !== CountriesEnum.CHILE ? 3 : 2}
            >
              {props.merchant?.chargeMin ? (
                <TextField
                  id="chargeMinAmount"
                  name="chargeMinAmount"
                  label="Monto"
                  type="number"
                  fullWidth
                  value={props.merchant?.chargeMinAmount}
                  onChange={(e) =>
                    props.handleFormChange(
                      "merchant",
                      "chargeMinAmount",
                      Number(e.target.value)
                    )
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        hidden={props.merchant?.country === CountriesEnum.CHILE}
                      >
                        {CURRENCY_COUNTRY.get(props.merchant!.country)}
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  inputRef={props.register({
                    required: "Debe ingresar un cobro mínimo",
                  })}
                  helperText={
                    props.errors.chargeMinAmount
                      ? props.errors.chargeMinAmount.message
                      : ""
                  }
                  error={!!props.errors.chargeMinAmount}
                />
              ) : null}
            </Grid>
            {props.merchant?.country === CountriesEnum.CHILE ? (
              <Grid item xs={12} md={2}>
                {props.merchant!.chargeMin ? (
                  <FormControl
                    className={style.styleSelect}
                    variant={"outlined"}
                  >
                    <InputLabel htmlFor="chargeCurrency">Moneda</InputLabel>
                    <Controller
                      control={props.control}
                      id="chargeCurrency"
                      name="chargeCurrency"
                      as={
                        <Select
                          label="Moneda"
                          value={props.merchant?.chargeCurrency}
                        >
                          {getCatalog(
                            props.merchant!.country,
                            CatalogsEnum.Currency
                          ).map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      defaultValue={props.merchant?.chargeCurrency}
                      valueName="chargeCurrencySel"
                      onChange={([chargeCurrencySel]) =>
                        props.handleFormChange(
                          "merchant",
                          "chargeCurrency",
                          chargeCurrencySel.target.value as string
                        )
                      }
                    />
                    {!!props.errors.invoiceFrequency && (
                      <FormHelperText>
                        {props.errors.invoiceFrequency.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                ) : null}
              </Grid>
            ) : null}
            <Grid
              item
              xs={1}
              hidden={props.merchant?.country === CountriesEnum.CHILE}
            />
            <Grid item xs={12} md={2}>
              <Typography className={style.labelColumns}>
                Tipo de cuenta
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth variant={"outlined"}>
                <InputLabel htmlFor="accountType">Tipo cuenta</InputLabel>
                <Controller
                  control={props.control}
                  id="accountType"
                  name="accountType"
                  as={
                    <Select
                      label="Tipo cuenta"
                      value={props.merchant?.accountType}
                    >
                      {getCatalog(
                        props.merchant!.country,
                        CatalogsEnum.BankAccountTypes
                      ).map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  defaultValue={props.merchant?.accountType}
                  valueName="accountTypeSel"
                  onChange={([accountTypeSel]) =>
                    props.handleFormChange(
                      "merchant",
                      "accountType",
                      accountTypeSel.target.value as string
                    )
                  }
                />
                {!!props.errors.invoiceFrequency && (
                  <FormHelperText>
                    {props.errors.invoiceFrequency.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Grid>

        <Grid item container>
          <Grid item xs={12}>
            <Box display={"flex"} alignItems={"center"}>
              <Box flexGrow={1}>
                <Typography className={style.labelTitle2}>
                  Valores a facturar por transacciones y servicios adicionales
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className={style.labelTitle3}>
              Son los valores que verás facturados en tus comprobantes de pago.
            </Typography>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid
            container
            spacing={2}
            alignContent={"center"}
            justify={"flex-start"}
            alignItems={"baseline"}
          >
            <Grid item container xs={12} md={6}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={props.billingConfig.valuesBilling.statusApproved}
                      onChange={(e) =>
                        props.handleFormChange(
                          "billingConfig",
                          "valuesBilling.statusApproved",
                          e.target.value
                        )
                      }
                      name="statusApprovedBilling"
                      color="primary"
                    />
                  }
                  label="Aprobado"
                />
              </Grid>
              <Grid item>
                <FormControl component="fieldset">
                  <FormGroup>
                    <Grid container>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color={"primary"}
                              disabled={
                                !props.billingConfig.valuesBilling
                                  .statusApproved
                              }
                              checked={props.billingConfig.valuesBilling.elementsApproved.includes(
                                ParameterizationBillingEnum.SALE
                              )}
                              onChange={(e) =>
                                props.handleFormChange(
                                  "billingConfig",
                                  "valuesBilling.elementsApproved",
                                  e.target.name
                                )
                              }
                              name={ParameterizationBillingEnum.SALE}
                            />
                          }
                          label="Ventas"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color={"primary"}
                              disabled={
                                !props.billingConfig.valuesBilling
                                  .statusApproved ||
                                props.billingConfig.valuesSubtractBilling.elementsApproved.includes(
                                  ParameterizationBillingEnum.CHARGEBACK
                                )
                              }
                              checked={
                                props.billingConfig.valuesBilling.elementsApproved.includes(
                                  ParameterizationBillingEnum.CHARGEBACK
                                ) &&
                                !props.billingConfig.valuesSubtractBilling.elementsApproved.includes(
                                  ParameterizationBillingEnum.CHARGEBACK
                                )
                              }
                              onChange={(e) =>
                                props.handleFormChange(
                                  "billingConfig",
                                  "valuesBilling.elementsApproved",
                                  e.target.name
                                )
                              }
                              name={ParameterizationBillingEnum.CHARGEBACK}
                            />
                          }
                          label="Contracargos"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color={"primary"}
                              disabled={
                                !props.billingConfig.valuesBilling
                                  .statusApproved
                              }
                              checked={props.billingConfig.valuesBilling.elementsApproved.includes(
                                ParameterizationBillingEnum.DEFFERED
                              )}
                              onChange={(e) =>
                                props.handleFormChange(
                                  "billingConfig",
                                  "valuesBilling.elementsApproved",
                                  e.target.name
                                )
                              }
                              name={ParameterizationBillingEnum.DEFFERED}
                            />
                          }
                          label="Diferidos"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color={"primary"}
                              disabled={
                                !props.billingConfig.valuesBilling
                                  .statusApproved ||
                                props.billingConfig.valuesSubtractBilling.elementsApproved.includes(
                                  ParameterizationBillingEnum.REFUND
                                )
                              }
                              checked={
                                props.billingConfig.valuesBilling.elementsApproved.includes(
                                  ParameterizationBillingEnum.REFUND
                                ) &&
                                !props.billingConfig.valuesSubtractBilling.elementsApproved.includes(
                                  ParameterizationBillingEnum.REFUND
                                )
                              }
                              onChange={(e) =>
                                props.handleFormChange(
                                  "billingConfig",
                                  "valuesBilling.elementsApproved",
                                  e.target.name
                                )
                              }
                              name={ParameterizationBillingEnum.REFUND}
                            />
                          }
                          label="Reembolsos"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color={"primary"}
                              disabled={
                                !props.billingConfig.valuesBilling
                                  .statusApproved ||
                                props.billingConfig.valuesSubtractBilling.elementsApproved.includes(
                                  ParameterizationBillingEnum.VOID
                                )
                              }
                              checked={
                                props.billingConfig.valuesBilling.elementsApproved.includes(
                                  ParameterizationBillingEnum.VOID
                                ) &&
                                !props.billingConfig.valuesSubtractBilling.elementsApproved.includes(
                                  ParameterizationBillingEnum.VOID
                                )
                              }
                              onChange={(e) =>
                                props.handleFormChange(
                                  "billingConfig",
                                  "valuesBilling.elementsApproved",
                                  e.target.name
                                )
                              }
                              name={ParameterizationBillingEnum.VOID}
                            />
                          }
                          label="Devoluciones"
                        />
                      </Grid>
                    </Grid>
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={6}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={props.billingConfig.valuesBilling.statusDeclined}
                      onChange={(e) =>
                        props.handleFormChange(
                          "billingConfig",
                          "valuesBilling.statusDeclined",
                          e.target.value
                        )
                      }
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Declinado"
                />
              </Grid>
              <Grid item>
                <FormControl component="fieldset">
                  <FormGroup>
                    <Grid container>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color={"primary"}
                              disabled={
                                !props.billingConfig.valuesBilling
                                  .statusDeclined
                              }
                              checked={props.billingConfig.valuesBilling.elementsDeclined.includes(
                                ParameterizationBillingEnum.SALE
                              )}
                              onChange={(e) =>
                                props.handleFormChange(
                                  "billingConfig",
                                  "valuesBilling.elementsDeclined",
                                  e.target.name
                                )
                              }
                              name={ParameterizationBillingEnum.SALE}
                            />
                          }
                          label="Ventas"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color={"primary"}
                              disabled={
                                !props.billingConfig.valuesBilling
                                  .statusDeclined ||
                                props.billingConfig.valuesSubtractBilling.elementsDeclined.includes(
                                  ParameterizationBillingEnum.CHARGEBACK
                                )
                              }
                              checked={
                                props.billingConfig.valuesBilling.elementsDeclined.includes(
                                  ParameterizationBillingEnum.CHARGEBACK
                                ) &&
                                !props.billingConfig.valuesSubtractBilling.elementsDeclined.includes(
                                  ParameterizationBillingEnum.CHARGEBACK
                                )
                              }
                              onChange={(e) =>
                                props.handleFormChange(
                                  "billingConfig",
                                  "valuesBilling.elementsDeclined",
                                  e.target.name
                                )
                              }
                              name={ParameterizationBillingEnum.CHARGEBACK}
                            />
                          }
                          label="Contracargos"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color={"primary"}
                              disabled={
                                !props.billingConfig.valuesBilling
                                  .statusDeclined
                              }
                              checked={props.billingConfig.valuesBilling.elementsDeclined.includes(
                                ParameterizationBillingEnum.DEFFERED
                              )}
                              onChange={(e) =>
                                props.handleFormChange(
                                  "billingConfig",
                                  "valuesBilling.elementsDeclined",
                                  e.target.name
                                )
                              }
                              name={ParameterizationBillingEnum.DEFFERED}
                            />
                          }
                          label="Diferidos"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color={"primary"}
                              disabled={
                                !props.billingConfig.valuesBilling
                                  .statusDeclined ||
                                props.billingConfig.valuesSubtractBilling.elementsDeclined.includes(
                                  ParameterizationBillingEnum.REFUND
                                )
                              }
                              checked={
                                props.billingConfig.valuesBilling.elementsDeclined.includes(
                                  ParameterizationBillingEnum.REFUND
                                ) &&
                                !props.billingConfig.valuesSubtractBilling.elementsDeclined.includes(
                                  ParameterizationBillingEnum.REFUND
                                )
                              }
                              onChange={(e) =>
                                props.handleFormChange(
                                  "billingConfig",
                                  "valuesBilling.elementsDeclined",
                                  e.target.name
                                )
                              }
                              name={ParameterizationBillingEnum.REFUND}
                            />
                          }
                          label="Reembolsos"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color={"primary"}
                              disabled={
                                !props.billingConfig.valuesBilling
                                  .statusDeclined ||
                                props.billingConfig.valuesSubtractBilling.elementsDeclined.includes(
                                  ParameterizationBillingEnum.VOID
                                )
                              }
                              checked={
                                props.billingConfig.valuesBilling.elementsDeclined.includes(
                                  ParameterizationBillingEnum.VOID
                                ) &&
                                !props.billingConfig.valuesSubtractBilling.elementsDeclined.includes(
                                  ParameterizationBillingEnum.VOID
                                )
                              }
                              onChange={(e) =>
                                props.handleFormChange(
                                  "billingConfig",
                                  "valuesBilling.elementsDeclined",
                                  e.target.name
                                )
                              }
                              name={ParameterizationBillingEnum.VOID}
                            />
                          }
                          label="Devoluciones"
                        />
                      </Grid>
                    </Grid>
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container>
          <Grid item xs={12}>
            <Box display={"flex"} alignItems={"center"}>
              <Box flexGrow={1}>
                <Typography className={style.labelTitle2}>
                  Valores a restar por transacciones y servicios adicionales
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className={style.labelTitle3}>
              Son los valores que verás devueltos en tus comprobantes de pago.
            </Typography>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid
            container
            spacing={2}
            alignContent={"center"}
            justify={"flex-start"}
            alignItems={"baseline"}
          >
            <Grid item container xs={12} md={6}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        props.billingConfig.valuesSubtractBilling.statusApproved
                      }
                      onChange={(e) =>
                        props.handleFormChange(
                          "billingConfig",
                          "valuesSubtractBilling.statusApproved",
                          e.target.value
                        )
                      }
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Aprobado"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color={"primary"}
                          disabled={
                            !props.billingConfig.valuesSubtractBilling
                              .statusApproved ||
                            props.billingConfig.valuesBilling.elementsApproved.includes(
                              ParameterizationBillingEnum.VOID
                            )
                          }
                          checked={
                            props.billingConfig.valuesSubtractBilling.elementsApproved.includes(
                              ParameterizationBillingEnum.VOID
                            ) &&
                            !props.billingConfig.valuesBilling.elementsApproved.includes(
                              ParameterizationBillingEnum.VOID
                            )
                          }
                          onChange={(e) =>
                            props.handleFormChange(
                              "billingConfig",
                              "valuesSubtractBilling.elementsApproved",
                              e.target.name
                            )
                          }
                          name={ParameterizationBillingEnum.VOID}
                        />
                      }
                      label="Devoluciones"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color={"primary"}
                          disabled={
                            !props.billingConfig.valuesSubtractBilling
                              .statusApproved ||
                            props.billingConfig.valuesBilling.elementsApproved.includes(
                              ParameterizationBillingEnum.CHARGEBACK
                            )
                          }
                          checked={
                            props.billingConfig.valuesSubtractBilling.elementsApproved.includes(
                              ParameterizationBillingEnum.CHARGEBACK
                            ) &&
                            !props.billingConfig.valuesBilling.elementsApproved.includes(
                              ParameterizationBillingEnum.CHARGEBACK
                            )
                          }
                          onChange={(e) =>
                            props.handleFormChange(
                              "billingConfig",
                              "valuesSubtractBilling.elementsApproved",
                              e.target.name
                            )
                          }
                          name={ParameterizationBillingEnum.CHARGEBACK}
                        />
                      }
                      label="Contracargos"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color={"primary"}
                          disabled={
                            !props.billingConfig.valuesSubtractBilling
                              .statusApproved ||
                            props.billingConfig.valuesBilling.elementsApproved.includes(
                              ParameterizationBillingEnum.REFUND
                            )
                          }
                          checked={
                            props.billingConfig.valuesSubtractBilling.elementsApproved.includes(
                              ParameterizationBillingEnum.REFUND
                            ) &&
                            !props.billingConfig.valuesBilling.elementsApproved.includes(
                              ParameterizationBillingEnum.REFUND
                            )
                          }
                          onChange={(e) =>
                            props.handleFormChange(
                              "billingConfig",
                              "valuesSubtractBilling.elementsApproved",
                              e.target.name
                            )
                          }
                          name={ParameterizationBillingEnum.REFUND}
                        />
                      }
                      label="Reembolsos"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={6}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        props.billingConfig.valuesSubtractBilling.statusDeclined
                      }
                      onChange={(e) =>
                        props.handleFormChange(
                          "billingConfig",
                          "valuesSubtractBilling.statusDeclined",
                          e.target.value
                        )
                      }
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Declinado"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color={"primary"}
                          disabled={
                            !props.billingConfig.valuesSubtractBilling
                              .statusDeclined ||
                            props.billingConfig.valuesBilling.elementsDeclined.includes(
                              ParameterizationBillingEnum.VOID
                            )
                          }
                          checked={
                            props.billingConfig.valuesSubtractBilling.elementsDeclined.includes(
                              ParameterizationBillingEnum.VOID
                            ) &&
                            !props.billingConfig.valuesBilling.elementsDeclined.includes(
                              ParameterizationBillingEnum.VOID
                            )
                          }
                          onChange={(e) =>
                            props.handleFormChange(
                              "billingConfig",
                              "valuesSubtractBilling.elementsDeclined",
                              e.target.name
                            )
                          }
                          name={ParameterizationBillingEnum.VOID}
                        />
                      }
                      label="Devoluciones"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color={"primary"}
                          disabled={
                            !props.billingConfig.valuesSubtractBilling
                              .statusDeclined ||
                            props.billingConfig.valuesBilling.elementsDeclined.includes(
                              ParameterizationBillingEnum.CHARGEBACK
                            )
                          }
                          checked={
                            props.billingConfig.valuesSubtractBilling.elementsDeclined.includes(
                              ParameterizationBillingEnum.CHARGEBACK
                            ) &&
                            !props.billingConfig.valuesBilling.elementsDeclined.includes(
                              ParameterizationBillingEnum.CHARGEBACK
                            )
                          }
                          onChange={(e) =>
                            props.handleFormChange(
                              "billingConfig",
                              "valuesSubtractBilling.elementsDeclined",
                              e.target.name
                            )
                          }
                          name={ParameterizationBillingEnum.CHARGEBACK}
                        />
                      }
                      label="Contracargos"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color={"primary"}
                          disabled={
                            !props.billingConfig.valuesSubtractBilling
                              .statusDeclined ||
                            props.billingConfig.valuesBilling.elementsDeclined.includes(
                              ParameterizationBillingEnum.REFUND
                            )
                          }
                          checked={
                            props.billingConfig.valuesSubtractBilling.elementsDeclined.includes(
                              ParameterizationBillingEnum.REFUND
                            ) &&
                            !props.billingConfig.valuesBilling.elementsDeclined.includes(
                              ParameterizationBillingEnum.REFUND
                            )
                          }
                          onChange={(e) =>
                            props.handleFormChange(
                              "billingConfig",
                              "valuesSubtractBilling.elementsDeclined",
                              e.target.name
                            )
                          }
                          name={ParameterizationBillingEnum.REFUND}
                        />
                      }
                      label="Reembolsos"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
