export enum LabelsEnum {
  SAVING_PROCESSOR_LABEL = "Creando procesador",
  UPDATING_PROCESSOR_LABEL = "Actualizando procesador",
  PLEASE_WAIT = "Por favor, espera...",
  successLabel = "El procesador fue creado con éxito, ¿Deseas agregar otro procesador?",
  newProcessorLabel = "Agregar nuevo procesador",
  incomplete = "¿Deseas salir sin completar el registro del procesador?",
  notsavedLabel = "Al salir sin completar el registro, no podrás conservar los datos ya cargados.",
  cancelLabel = "Cancelar",
  invalidAlias = "El alias registrado anteriormente o es invalido.",
  exitLabel = "Salir",
}
