import React, { useState } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useTypedController } from "@hookform/strictly-typed";
import ConnectForm from "../../commons/ConnectForm/ConnectForm";
import { ISecurityRuleForm } from "../../../shared/infrastructure/interfaces/ISecurityRuleForm";
import { SecurityRulesLabelField } from "../../../shared/infrastructure/constants/SecurityRulesConstants";
import { get } from "lodash";
import { SCHEDULES } from "../../../shared/infrastructure/constants/BusinessRulesEnum";

export interface SelectFieldScheduleComponentProps {
  disabled: boolean;
  index: number;
}

export const SelectFieldScheduleComponent: React.FC<SelectFieldScheduleComponentProps> =
  (props: SelectFieldScheduleComponentProps) => {
    const [items] = useState(SCHEDULES);

    return (
      <ConnectForm<ISecurityRuleForm>>
        {({ control, errors, getErrorMessage }) => {
          const TypedController = useTypedController<ISecurityRuleForm>({
            control,
          });

          return (
            <div style={{ display: "flex" }}>
              <TypedController
                name={["strictCondition", props.index, "from"]}
                rules={{ required: true }}
                render={(properties) => (
                  <>
                    <FormControl
                      variant="outlined"
                      error={
                        !!get(
                          errors,
                          `strictCondition.${props.index}.from.value`
                        )
                      }
                      style={{ margin: "auto .5rem", width: "50%" }}
                    >
                      <InputLabel>
                        {SecurityRulesLabelField.SCHEDULE_FROM}
                      </InputLabel>
                      <Select
                        {...properties}
                        value={properties.value}
                        disabled={props.disabled}
                        variant="outlined"
                        label={SecurityRulesLabelField.SCHEDULE_FROM}
                      >
                        {items.map(
                          (
                            actionValue: { name: string; value: string },
                            index: number
                          ) => (
                            <MenuItem key={index} value={actionValue.value}>
                              {actionValue.name}
                            </MenuItem>
                          )
                        )}
                      </Select>
                      <FormHelperText
                        error={
                          !!get(
                            errors,
                            `strictCondition.${props.index}.from.value`
                          )
                        }
                      >
                        {!!get(
                          errors,
                          `strictCondition.${props.index}.from.value`
                        ) &&
                          getErrorMessage(
                            get(
                              errors,
                              `strictCondition.${props.index}.from.value`
                            )
                          )}
                      </FormHelperText>
                    </FormControl>
                  </>
                )}
              />
              <TypedController
                name={["strictCondition", props.index, "until"]}
                rules={{ required: true }}
                render={(properties) => (
                  <>
                    <FormControl
                      variant="outlined"
                      error={
                        !!get(
                          errors,
                          `strictCondition.${props.index}.until.value`
                        )
                      }
                      style={{ margin: "auto .5rem", width: "50%" }}
                    >
                      <InputLabel>
                        {SecurityRulesLabelField.SCHEDULE_UNTIL}
                      </InputLabel>
                      <Select
                        {...properties}
                        value={properties.value}
                        disabled={props.disabled}
                        label={SecurityRulesLabelField.SCHEDULE_UNTIL}
                      >
                        {items.map(
                          (
                            actionValue: { name: string; value: string },
                            index: number
                          ) => (
                            <MenuItem key={index} value={actionValue.value}>
                              {actionValue.name}
                            </MenuItem>
                          )
                        )}
                      </Select>
                      <FormHelperText
                        error={
                          !!get(
                            errors,
                            `strictCondition.${props.index}.until.value`
                          )
                        }
                      >
                        {!!get(
                          errors,
                          `strictCondition.${props.index}.until.value`
                        ) &&
                          getErrorMessage(
                            get(
                              errors,
                              `strictCondition.${props.index}.until.value`
                            )
                          )}
                      </FormHelperText>
                    </FormControl>
                  </>
                )}
              />
            </div>
          );
        }}
      </ConnectForm>
    );
  };

export default SelectFieldScheduleComponent;
