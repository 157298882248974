import * as React from "react";
import { SvgIcon as MuiSvgIcon, styled, SvgIconProps } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "@kushki/connect-ui";

const SvgIcon = styled(MuiSvgIcon, {
  name: "ServiceIcon",
  shouldForwardProp: (prop) => prop !== "fill",
})<SvgIconProps>(({ color, fontSize }) => ({
  fill: color + "!important",
  fontSize: fontSize,
}));

const IconServiceTitle: React.FunctionComponent<SvgIconProps> = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <SvgIcon
        {...props}
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_334_144470)">
          <circle cx="18" cy="18" r="18" fill="#023365" />
          <g clip-path="url(#clip1_334_144470)">
            <path
              d="M9.53843 17.3053C9.26693 17.3053 9.08594 17.1263 9.08594 16.8579V9.7C9.08594 8.80526 9.80992 8 10.8054 8H25.1945C26.19 8 26.914 8.80526 26.914 9.7V12.6526C26.914 12.9211 26.733 13.1 26.4615 13.1C26.19 13.1 26.009 12.9211 26.009 12.6526V9.7C26.009 9.25263 25.647 8.89474 25.1945 8.89474H10.8054C10.3529 8.89474 9.99091 9.25263 9.99091 9.7V16.8579C9.99091 17.1263 9.71942 17.3053 9.53843 17.3053Z"
              fill="white"
            />
            <path
              d="M26.4614 13.1C26.3709 13.1 26.1899 13.0105 26.0994 13.0105L25.0134 11.9369C24.8324 11.7579 24.8324 11.4895 25.0134 11.3105C25.1944 11.1316 25.4659 11.1316 25.6469 11.3105L26.3709 12.0263L27.0949 11.3105C27.2759 11.1316 27.5474 11.1316 27.7284 11.3105C27.9094 11.4895 27.9094 11.7579 27.7284 11.9369L26.6424 13.0105C26.7329 13.1 26.6424 13.1 26.4614 13.1Z"
              fill="white"
            />
            <path
              d="M25.1945 25.8947H10.8054C9.80992 25.8947 9.08594 25.0895 9.08594 24.1947V21.2421C9.08594 20.9737 9.26693 20.7947 9.53843 20.7947C9.80992 20.7947 9.99091 20.9737 9.99091 21.2421V24.1947C9.99091 24.6421 10.3529 25 10.8054 25H25.1945C25.647 25 26.009 24.6421 26.009 24.1947V17.0368C26.009 16.7684 26.19 16.5895 26.4615 16.5895C26.733 16.5895 26.914 16.7684 26.914 17.0368V24.1947C26.914 25.179 26.19 25.8947 25.1945 25.8947Z"
              fill="white"
            />
            <path
              d="M10.534 22.7632C10.4435 22.7632 10.2625 22.7632 10.172 22.6737L9.44805 21.9579L8.72406 22.6737C8.54307 22.8526 8.27158 22.8526 8.09058 22.6737C7.90959 22.4947 7.90959 22.2263 8.09058 22.0474L9.17655 20.9737C9.35755 20.7947 9.62904 20.7947 9.81004 20.9737L10.896 22.0474C11.077 22.2263 11.077 22.4947 10.896 22.6737C10.8055 22.6737 10.715 22.7632 10.534 22.7632Z"
              fill="white"
            />
            <path
              d="M19.946 19.5421H15.2401C14.9686 19.5421 14.7876 19.3632 14.7876 19.0948C14.7876 18.8263 14.9686 18.6474 15.2401 18.6474H19.946C20.2175 18.6474 20.3985 18.8263 20.3985 19.0948C20.3985 19.3632 20.2175 19.5421 19.946 19.5421Z"
              fill="white"
            />
            <path
              d="M17.5026 18.1105C17.4121 18.1105 17.2311 18.1105 17.2311 18.0211L14.8781 15.7842C14.6971 15.6053 14.6066 15.3369 14.7876 15.1579C14.9686 14.979 15.2401 14.979 15.4211 15.1579L17.4121 17.1263L21.575 12.9211C21.7559 12.7421 22.0274 12.7421 22.2084 12.9211C22.3894 13.1 22.3894 13.3684 22.2084 13.5474L17.6836 18.1105C17.774 18.1105 17.5931 18.1105 17.5026 18.1105Z"
              fill="white"
            />
            <path
              d="M17.9549 23.0316C14.6065 23.0316 11.8916 20.3474 11.8916 17.0369C11.8916 13.7263 14.6065 11.0421 17.9549 11.0421C21.3034 11.0421 24.0183 13.7263 24.0183 17.0369C24.0183 20.3474 21.3939 23.0316 17.9549 23.0316ZM17.9549 11.8474C15.059 11.8474 12.7966 14.1737 12.7966 16.9474C12.7966 19.7211 15.1495 22.0474 17.9549 22.0474C20.7604 22.0474 23.1133 19.7211 23.1133 16.9474C23.1133 14.1737 20.8509 11.8474 17.9549 11.8474Z"
              fill="white"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_334_144470">
            <rect width="36" height="36" fill="white" />
          </clipPath>
          <clipPath id="clip1_334_144470">
            <rect
              width="20"
              height="17.8947"
              fill="white"
              transform="translate(8 8)"
            />
          </clipPath>
        </defs>
      </SvgIcon>
    </ThemeProvider>
  );
};

export default IconServiceTitle;
