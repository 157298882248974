import React from "react";
import { CardList } from "@kushki/connect-ui";
import { Box } from "@mui/material";
import { IElementModalComponentProps } from "../../../shared/infrastructure/interfaces/IModalContainer";
import { defaultTo, isEmpty } from "lodash";
import { CardListOrientationEnum } from "../../../shared/infrastructure/enums/ModalEnum";

export const ElementModalComponent: React.FC<IElementModalComponentProps> = (
  props: IElementModalComponentProps
) => {
  return (
    <React.Fragment>
      {(props.isVisible || props.isLoading) && (
        <Box display="flex">
          <Box
            display="flex"
            flexDirection="column"
            marginRight="8px"
            width="100%"
          >
            <Box
              bgcolor="neutral.grey3"
              borderRadius="4px"
              marginBottom="16px"
              paddingBottom="24px"
            >
              <CardList
                items={props.body}
                orientation={CardListOrientationEnum.HORIZONTAL}
                title={props.title}
                isLoading={props.isLoading}
                separator={!isEmpty(props.secondSectionBody)}
              />
              {props.secondSectionBody && (
                <CardList
                  items={props.secondSectionBody}
                  orientation={defaultTo(
                    props.secondSectionOrientation,
                    CardListOrientationEnum.HORIZONTAL
                  )}
                />
              )}
            </Box>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};
