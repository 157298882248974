// istanbul ignore file
import Rollbar from "rollbar";
import { environment } from "../environments/environment";

export const RollbarErrorTracking = (() => {
  const RollbarObj = new Rollbar({
    accessToken: environment.rollbarToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: environment.envName,
    },
  });
  const logErroInfo = (info) => {
    RollbarObj.info(info);
  };
  const logErrorInRollbar = (error) => {
    throw new Error(error);
  };
  return { logErroInfo, logErrorInRollbar };
})();
export default RollbarErrorTracking;
