export enum TypeCatalog {
  CIUU = "CIUU",
  MCC = "MCC",
}

export enum ErrorMessageGeneric {
  MAX_1_50_CHAR = "El máximo permitido de caracteres es 1-50",
  MAX150 = "El máximo permitido de caracteres es 1-50",
  PF_RAZON_SOCIAL = "Por favor, ingresa la razón social",
  LIM_IDEN = "El número de identificación debe tener entre 12 y 13 caracteres",
  PF_NUM_ID = "Por favor, ingresa el número de identificación",
  PF_NOMBRE = "Por favor, ingresa el nombre",
  PF_APELLIDO = "Por favor, ingresa el apellido",
  LETRAS = "Solo se aceptan letras",
  PF_MCC = "Por favor, selecciona un sector comercial",
  PF_INDUSTRIA = "Por favor, selecciona el tipo de industria",
  PF_COMPANY = "Por favor, selecciona el tipo de empresa o sociedad",
  PF_ACT_COMER = "Por favor, selecciona la actividad comercial",
  PF_TIPO_PERSONA = "Por favor, elige un tipo de persona",
  PF_WEB = "Por favor, escribe el sitio web",
  DATE_PICKER = "Por favor, ingresa una fecha",
}
