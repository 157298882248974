/**
 * CatalogCl
 */
import { CatalogsEnum } from "../infrastructure/constants/catalogs-enum";
import { Category } from "../infrastructure/constants/MerchantInformationConstant";

export const CatalogCl: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { value: "0", name: "Run" },
    { value: "1", name: "Pasaporte" },
    { value: "2", name: "Rut" },
  ],
  [CatalogsEnum.Provinces]: [
    { value: "AP", name: "Arica y Parinacota" },
    { value: "TA", name: "Tarapacá" },
    { value: "AN", name: "Antofagasta" },
    { value: "AT", name: "Atacama" },
    { value: "CO", name: "Coquimbo" },
    { value: "VS", name: "Valparaíso" },
    { value: "LI", name: "Región del Libertador Gral. Bernardo O'Higgins" },
    { value: "ML", name: "Región del Maule" },
    { value: "BI", name: "Región del BioBío" },
    { value: "AR", name: "Región de la Araucanía" },
    { value: "LR", name: "Región de los Ríos" },
    { value: "LL", name: "Región de los Lagos" },
    { value: "AI", name: "Región Aisén del Gral. Carlos Ibañez del Campo" },
    { value: "MA", name: "Región de Magallanes y de la Antártica Chilena" },
    { value: "RM", name: "Región Metropolitana de Santiago" },
  ],
  [CatalogsEnum.Banks]: [
    { value: "970320008", name: "Banco BBVA" },
    { value: "970040005", name: "Banco de Chile" },
    { value: "970060006", name: "Banco BCI" },
    { value: "970040005", name: "Banco Edwards" },
    { value: "970510001", name: "Banco Desarrollo" },
    { value: "970300007", name: "Banco Estado" },
    { value: "965096604", name: "Banco Falabella" },
    { value: "76645030K", name: "Banco Itaú" },
    { value: "970110003", name: "Banco Internacional" },
    { value: "979470002", name: "Banco Ripley" },
    { value: "97036000K", name: "Banco Santander-Santiago" },
    { value: "970530002", name: "Banco Security" },
    { value: "97080000K", name: "Banco Bice" },
    { value: "995004100", name: "Banco Consorcio" },
    { value: "828789007", name: "Banco Coopeuch" },
    { value: "970230009", name: "Corpbanca" },
    { value: "979510004", name: "Banco HSBC" },
    { value: "970180001", name: "Scotiabank" },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { value: "0", name: "Corriente" },
    { value: "1", name: "Vista" },
  ],
  [CatalogsEnum.Currency]: [
    { value: "CLP", name: "CLP" },
    { value: "UF", name: "UF" },
  ],
  [CatalogsEnum.MerchantTypes]: [
    { value: "01", name: "Natural" },
    { value: "02", name: "Jurídica" },
  ],
};
