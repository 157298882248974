import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const mainConfigDataCardStyles = createNamedStyles({
  gridTitle: {
    paddingBottom: 1.5,
  },
  outerPadding: {
    padding: "1% 4% 1% 4%",
  },
  marginChips: {
    marginRight: "1%",
    marginTop: "1%",
  },
  mb1: {
    marginBottom: "16px",
  },
  emptyContentContainer: {
    justifyContent: "space-between",
    padding: "1% 1% 1% 1%",
  },
  alertColors: {
    background: "#F0F4F8 !important",
    marginTop: "24px",
    marginBottom: "24px",
  },
  infoMessage: {
    backgroundColor: "neutral.grey3",
    padding: "8px 24px 8px 24px",
    borderRadius: "8px",
  },
  typographyInfoMessage: {
    fontSize: "13px",
    fontWeight: 400,
    color: "neutral.grey6",
  },
});
