import { CustomStyles } from "../../shared/infrastructure/interfaces/CustomStyles";

export const styles: CustomStyles = {
  accordionTitle: {
    width: "100%",
  },
  container: {
    flexDirection: "row",
  },
  skeletonContainer: {
    alignItems: "center",
    background: "#FFFFFF",
    borderRadius: "1rem",
    boxShadow:
      "0 36px 65px rgba(106, 167, 197, 0.16), 0 18.225px 28.3359px rgba(106, 167, 197, 0.12), 0 7.2px 10.5625px rgba(106, 167, 197, 0.08), 0 1.575px 3.75781px rgba(106, 167, 197, 0.03)",
    display: "flex",
    gap: "10px",
    padding: "12px 24px",
    width: "100%",
  },
};
