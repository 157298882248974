import { useState } from "react";
import { get } from "lodash";
import * as BUSINESS_RULES from "../../../../shared/infrastructure/constants/BusinessRulesEnum";
import { IOptionSelection } from "../../../../shared/infrastructure/constants/RulesConstants";
import { ModalBodyProps } from "../../ModalBody";

export interface ConfigurationState {
  businessRulesGeneral: {};
  rule: {};
}

export const useConfigurationState = (
  props: ModalBodyProps
): ConfigurationState => {
  const [rule] = useState(get(props.data, "rule", {}));
  const businessRulesGeneral = Object.keys(BUSINESS_RULES).reduce(
    (objGlobal, variable) => {
      let objTemp: object = { ...BUSINESS_RULES[variable] };
      if (Array.isArray(BUSINESS_RULES[variable])) {
        objTemp = BUSINESS_RULES[variable].reduce(
          (obj: object, p: IOptionSelection) => ({
            ...obj,
            [p.value]: p.name,
          }),
          {}
        );
      }
      return { ...objGlobal, ...objTemp };
    },
    {}
  );

  return {
    businessRulesGeneral,
    rule,
  };
};
