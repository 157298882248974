import React from "react";
import { TextField } from "@mui/material";
import { PlaceHolders } from "../../../../shared/enums/BusinessRuleEnum";
import { Controller } from "react-hook-form";
import { IDefaultComponentProps } from "../../../../shared/interfaces/IDefaultComponentProps";
import { useDefaultConditionState } from "./state/useDefaultConditionComponent";
import { ErrorEnum } from "../../../../shared/enums/ErrorEnum";

export const DefaultConditionComponent: React.FC<IDefaultComponentProps> = (
  props: IDefaultComponentProps
) => {
  const { control, defaultValue, setValue, defaultValueWatch } =
    useDefaultConditionState(props);

  return (
    <React.Fragment>
      <Controller
        control={control}
        name={defaultValue}
        defaultValue={""}
        rules={{
          required: {
            message: ErrorEnum.REQUIRED_FIELD,
            value: false,
          },
        }}
        render={() => (
          <TextField
            key={defaultValue}
            id={defaultValue}
            name={defaultValue}
            label={`${PlaceHolders.INPUT_VALUE}`}
            variant="outlined"
            fullWidth
            onChange={(event): void => {
              setValue(defaultValue, [event.target.value]);
            }}
            value={defaultValueWatch}
          />
        )}
      />
    </React.Fragment>
  );
};
