import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMerchantResponse } from "../../interfaces/merchantResponse/merchantResponse.interfaces";
import { SearchMerchantResponse } from "../../../../types/search_merchant_response";
import searchMerchantNodes from "../../thunks/merchantResponse/merchantResponse.thunks";
import { get, set } from "lodash";
import { getItemFromSessionStorage } from "../../../shared/utils/session_storage_utils";
import { SessionStoragePaths } from "../../../shared/enums/sessionStoragePaths";
import { BranchEditItem } from "../../../../types/branch_edit_item";
import { editStatusPath } from "../../../shared/constants/edit_container_paths";

export const initialState: IMerchantResponse = {
  branchEditList: {
    data: [],
    total: 0,
  },
};

function checkIsModified(modifiedMerchantsId: string[], merchantId: string) {
  return modifiedMerchantsId.some((arrVal: string) => merchantId === arrVal);
}

export const merchantResponseSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(
      searchMerchantNodes.fulfilled,
      (state, { payload }: PayloadAction<SearchMerchantResponse>) => {
        const data = get(payload, "data", []);
        const total: number = get(payload, "total", 0);

        const branchEditListModify = get(
          getItemFromSessionStorage(SessionStoragePaths.BRANCH_EDIT_LIST),
          "data",
          []
        )
          .filter((branch: BranchEditItem) => branch.isModify)
          .map((branch: BranchEditItem) => branch.merchant_id);

        data.map((item) => {
          set(item, editStatusPath, get(item, editStatusPath));
          set(
            item,
            "isModify",
            checkIsModified(branchEditListModify, get(item, "merchant_id", ""))
          );
        });

        state.branchEditList = { data, total };
      }
    );
  },
  initialState,
  name: "merchantResponse",
  reducers: {
    setBranchEditList: (
      state,
      { payload }: PayloadAction<SearchMerchantResponse>
    ) => {
      state.branchEditList = payload;
    },
  },
});

export default merchantResponseSlice.reducer;
