import { FC } from "react";
import { Alert, Snackbar } from "@mui/material";
import { ISnackbarAlertProps, snackBarAlertStyles } from "./index";
import CheckIcon from "@mui/icons-material/Check";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";

const SnackBarAlert: FC<ISnackbarAlertProps> = ({
  message,
  open,
  type,
  handlerClose,
}: ISnackbarAlertProps) => (
  <Snackbar
    key={"id-snackbar"}
    open={open}
    anchorOrigin={{ horizontal: "right", vertical: "top" }}
    autoHideDuration={6000}
    onClose={handlerClose}
    sx={snackBarAlertStyles.position}
  >
    <Alert
      elevation={6}
      variant="filled"
      onClose={handlerClose}
      severity={type}
      iconMapping={{
        error: <DoDisturbAltIcon />,
        success: <CheckIcon />,
      }}
      sx={snackBarAlertStyles.alert}
    >
      {message}
    </Alert>
  </Snackbar>
);

export default SnackBarAlert;
