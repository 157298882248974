import { GetRuleResponse } from "../../../types/get_rule_response";
import { isEmpty } from "lodash";
import { RuleItem } from "../../../types/get_business_rules_response";
import { RuleEnum } from "../infrastructure/enums/RuleEnum";
import { IConditionItem } from "../infrastructure/interfaces/IConditionItem";
import { get } from "lodash";

const isValidAlias = (
  value: string,
  getRuleById: GetRuleResponse | undefined,
  businessRules: RuleItem[] | undefined
): boolean => {
  if (!isEmpty(businessRules)) {
    if (getRuleById && value === get(getRuleById, "rule.rule.name", "")) {
      return true;
    }
    const existAlias = businessRules!.some((rule) => rule.rule.name === value);
    return !existAlias;
  }
  return true;
};

export const validateAlias = (
  value: string,
  getRuleById: GetRuleResponse | undefined,
  businessRules: RuleItem[] | undefined
): boolean => isValidAlias(value, getRuleById, businessRules);

// setConditionsFromRule function set condition from GetRuleResponse , if some condition value has ","
// it's going to be split as value
// example of rule: "contains|Bank 1, Bank 2,New Fate,Bank"
// value =["Blank 1","Blank 2","New Fate","Bank"]
// it could be a good idea use other separator like ; in a future
export const setConditionsFromRule = (
  rule: GetRuleResponse | undefined,
  path: string
): IConditionItem[] => {
  if (isEmpty(rule)) {
    return [];
  }

  return Object.values(RuleEnum)
    .map((condition): IConditionItem => {
      const conditionRule = get(rule, `${path}.${condition}`, "");
      if (conditionRule !== "") {
        const item = conditionRule.split("|");
        const values = item[1].split(",");
        const operator = item[0];
        return { operator: operator, value: values, label: condition };
      }
      return { operator: "", value: [], label: "" };
    })
    .filter((condition) => condition.operator !== "");
};
