import { createSlice } from "@reduxjs/toolkit";
import {
  MerchantNodeData,
  SearchMerchantResponse,
} from "../../../../types/search_merchant_response";
import { ISnackBar, ISnackBarWithAction } from "@kushki/connect-ui";
import { searchBranchMerchants } from "../../thunks/branch/searchMerchants.thunk";
import { changeStatusNodes } from "../../thunks/customer/searchMerchants.thunk";
import {
  buildGenericNotification,
  buildMessage,
} from "../../../shared/utils/snackbar";
import { NotificationTypeEnum } from "../../../shared/enums/SnackbarEnum";
import { get } from "lodash";
import { getFilteredBalanceFromBranchList } from "../../thunks/balance/balance.thunk";
import { addBalanceValueText } from "../../../shared/utils/dataUtils";

export interface IChangeStatus {
  entityName: string;
  status: string;
}

export interface BranchData {
  branchList: SearchMerchantResponse;
  changeStatus?: IChangeStatus;
  notifyChangeStatus?: ISnackBar | ISnackBarWithAction;
  merchantByNodeId: MerchantNodeData;
  loadEdit: boolean;
  isDisabledEditBtn: boolean;
  isDisabledActiveBtn: boolean;
  isLoadingMerchants: boolean;
}

export const initialState: BranchData = {
  branchList: {
    data: [],
    total: 0,
  },
  isDisabledActiveBtn: false,
  isDisabledEditBtn: false,
  isLoadingMerchants: false,
  loadEdit: false,
  merchantByNodeId: {},
  notifyChangeStatus: undefined,
};

export const branchDataSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(searchBranchMerchants.pending, (state) => {
        state.isLoadingMerchants = true;
      })
      .addCase(searchBranchMerchants.fulfilled, (state, action) => {
        state.branchList = action.payload;
        state.loadEdit = !state.loadEdit;
        state.isLoadingMerchants = false;
      })
      .addCase(searchBranchMerchants.rejected, (state) => {
        state.branchList = initialState.branchList;
        state.isLoadingMerchants = false;
      })
      .addCase(changeStatusNodes.fulfilled, (state) => {
        state.notifyChangeStatus = buildGenericNotification(
          NotificationTypeEnum.SUCCESS,
          {
            message: buildMessage(
              get(state, "changeStatus.entityName", ""),
              get(state, "changeStatus.status", "")
            ),
            variant: "simple",
            withIcon: true,
          }
        );
      })
      .addCase(changeStatusNodes.rejected, (state) => {
        state.notifyChangeStatus = buildGenericNotification(
          NotificationTypeEnum.FAILED,
          {
            message: buildMessage(
              get(state, "changeStatus.entityName", ""),
              get(state, "changeStatus.status", ""),
              "error"
            ),
          }
        );
      })
      .addCase(getFilteredBalanceFromBranchList.fulfilled, (state, action) => {
        state.branchList = addBalanceValueText(
          state.branchList,
          action.payload
        );
      });
  },
  initialState,
  name: "BranchData",
  reducers: {
    resetBranchList: (state) => {
      state.branchList = {
        data: [],
        total: 0,
      };
    },
    setChangeStatusBranch: (state, action) => {
      state.changeStatus = action.payload;
    },
    setDisabledActiveBtn: (state, { payload }) => {
      state.isDisabledActiveBtn = payload;
    },
    setIsDisabledEditBtn: (state, action) => {
      state.isDisabledEditBtn = action.payload;
    },
  },
});

export default branchDataSlice.reducer;
