import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { StatusSemaphore } from "../../../shared/infrastructure/constants/CreateMerchantConstants";
import { StatusSteps } from "../../../../types/remote/semaphore_data";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { get } from "lodash";
import RateOptions from "../RateOptions/RateOptions";

export interface RateSectionPendingProps {
  status: StatusSteps;
  publicMerchantId: string;
}

export const RateSectionPending: React.FC<
  Partial<RateSectionPendingProps>
> = () => {
  const { status, publicMerchantId }: RateSectionPendingProps = useSelector(
    (state: IAppState) => ({
      status: get(
        state,
        "semaphore.stepConfigRatesAndInvoice.statusRates",
        "complete"
      ),
      publicMerchantId: get(state, "merchantAffiliation.publicMerchantId", ""),
    })
  );

  return status === StatusSemaphore.pending ? (
    <Grid item xs={12}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        bgcolor="background.default"
        borderRadius={4}
        py={3}
        mb={2}
      >
        <Typography variant="h5" gutterBottom>
          <Box fontWeight={300} display="inline">
            Aún no has agregado{" "}
          </Box>
          <Box fontWeight={400} display="inline">
            tarifas
          </Box>
        </Typography>
        <RateOptions publicMerchantId={publicMerchantId} />
      </Box>
    </Grid>
  ) : null;
};
