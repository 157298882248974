import { CountriesEnum } from "../infrastructure/countries-enum";
import { CurrencyEnum } from "../infrastructure/currency-enum";
import { defaultTo } from "lodash";

export const CURRENCY_COUNTRY: Map<string, string> = new Map()
  .set(`${CountriesEnum.COLOMBIA}`, CurrencyEnum.COP)
  .set(`${CountriesEnum.ECUADOR}`, CurrencyEnum.USD)
  .set(`${CountriesEnum.PERU}`, CurrencyEnum.PEN)
  .set(`${CountriesEnum.CHILE}`, CurrencyEnum.CLP)
  .set(`${CountriesEnum.MEXICO}`, CurrencyEnum.MXN)
  .set(`${CountriesEnum.BRAZIL}`, CurrencyEnum.BRL)
  .set(`${CountriesEnum.EL_SALVADOR}`, CurrencyEnum.USD)
  .set(`${CountriesEnum.PANAMA}`, CurrencyEnum.USD)
  .set(`${CountriesEnum.COSTA_RICA}`, CurrencyEnum.CRC);

export const currencyFormat = (
  number: number,
  country: string,
  currency?: string
): string => {
  const currencyUF: boolean = currency === CurrencyEnum.UF;
  const newCurrency: string | undefined = currencyUF
    ? CurrencyEnum.CLF
    : currency;

  const currencyFormatter = Intl.NumberFormat("de-DE", {
    currency: defaultTo(newCurrency, CURRENCY_COUNTRY.get(country)),
    currencyDisplay: "code",
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    style: "currency",
  }).format(number);

  return currencyUF
    ? currencyFormatter.replace(CurrencyEnum.CLF, CurrencyEnum.UF)
    : currencyFormatter;
};
