/* istanbul ignore file */

import { IOptionSelection } from "./RuleRequestConstants";
import { CurrencyEnum } from "../enums/CurrencyEnum";

export const BANKS_BRAZIL: IOptionSelection[] = [
  { value: "00000000", name: "BANCO DO BRASIL S.A." },
  { value: "00000208", name: "BRB - BANCO DE BRASILIA S.A." },
  { value: "00360305", name: "CAIXA ECONOMICA FEDERAL" },
  { value: "00416968", name: "BANCO INTER S.A." },
  { value: "00517645", name: "BANCO RIBEIRAO PRETO S.A." },
  {
    value: "00556603",
    name: "BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A.",
  },
  { value: "00558456", name: "BANCO CETELEM S.A." },
  { value: "00795423", name: "BANCO SEMEAR S.A." },
  { value: "00997185", name: "BANCO B3 S.A." },
  { value: "01023570", name: "BANCO RABOBANK INTERNATIONAL BRASIL S.A." },
  { value: "01181521", name: "BANCO COOPERATIVO SICREDI S.A." },
  { value: "01522368", name: "BANCO BNP PARIBAS BRASIL S.A." },
  { value: "01701201", name: "KIRTON BANK S.A. - BANCO MÚLTIPLO" },
  { value: "02038232", name: "BANCO COOPERATIVO DO BRASIL S.A. - BANCOOB" },
  { value: "02318507", name: "BANCO KEB HANA DO BRASIL S.A." },
  { value: "02658435", name: "BANCO CATERPILLAR S.A." },
  { value: "02801938", name: "BANCO MORGAN STANLEY S.A." },
  { value: "02992446", name: "BANCO CNH INDUSTRIAL CAPITAL S.A." },
  { value: "03012230", name: "HIPERCARD BANCO MÚLTIPLO S.A." },
  { value: "03017677", name: "BANCO J. SAFRA S.A." },
  { value: "03215790", name: "BANCO TOYOTA DO BRASIL S.A." },
  { value: "03323840", name: "BANCO ALFA S.A." },
  { value: "03502961", name: "BANCO PSA FINANCE BRASIL S.A." },
  { value: "03532415", name: "BANCO ABN AMRO S.A." },
  { value: "03609817", name: "BANCO CARGILL S.A." },
  { value: "03634220", name: "BANCO HONDA S.A." },
  { value: "04184779", name: "BANCO BRADESCARD S.A." },
  { value: "04332281", name: "GOLDMAN SACHS DO BRASIL BANCO MULTIPLO S.A." },
  { value: "04866275", name: "BANCO INBURSA S.A." },
  { value: "04902979", name: "BANCO DA AMAZONIA S.A." },
  { value: "04913711", name: "BANCO DO ESTADO DO PARÁ S.A." },
  { value: "05040481", name: "BANCO DE LAGE LANDEN BRASIL S.A." },
  { value: "06271464", name: "BANCO BRADESCO BBI S.A." },
  { value: "07207996", name: "BANCO BRADESCO FINANCIAMENTOS S.A." },
  { value: "07237373", name: "BANCO DO NORDESTE DO BRASIL S.A." },
  { value: "07441209", name: "BANCO MONEO S.A." },
  {
    value: "07450604",
    name: "CHINA CONSTRUCTION BANK (BRASIL) BANCO MÚLTIPLO S/A",
  },
  { value: "07656500", name: "BANCO KDB DO BRASIL S.A." },
  { value: "07679404", name: "BANCO TOPÁZIO S.A." },
  { value: "08357240", name: "BANCO CSF S.A." },
  { value: "08609934", name: "MONEYCORP BANCO DE CÂMBIO S.A." },
  { value: "09274232", name: "STATE STREET BRASIL S.A. – BANCO COMERCIAL" },
  { value: "09516419", name: "BANCO ORIGINAL DO AGRONEGÓCIO S.A." },
  { value: "09526594", name: "BANCO VIPAL S.A." },
  { value: "10264663", name: "BANCOSEGURO S.A." },
  { value: "10371492", name: "BANCO YAMAHA MOTOR DO BRASIL S.A." },
  { value: "10664513", name: "BANCO AGIBANK S.A." },
  { value: "10690848", name: "BANCO DA CHINA BRASIL S.A." },
  { value: "10866788", name: "BANCO BANDEPE S.A." },
  { value: "11417016", name: "SCANIA BANCO S.A." },
  { value: "11476673", name: "BANCO RANDON S.A." },
  { value: "11703662", name: "TRAVELEX BANCO DE CÂMBIO S.A." },
  { value: "11758741", name: "BANCO FINAXIS S.A." },
  { value: "13009717", name: "BANCO DO ESTADO DE SERGIPE S.A." },
  { value: "13059145", name: "BEXS BANCO DE CÂMBIO S/A" },
  { value: "13720915", name: "BANCO WESTERN UNION DO BRASIL S.A." },
  { value: "14388334", name: "PARANÁ BANCO S.A." },
  { value: "15114366", name: "BANCO BOCOM BBM S.A." },
  { value: "15173776", name: "BANCO CAPITAL S.A." },
  { value: "15357060", name: "BANCO WOORI BANK DO BRASIL S.A." },
  { value: "17184037", name: "BANCO MERCANTIL DO BRASIL S.A." },
  { value: "17192451", name: "BANCO ITAUCARD S.A." },
  { value: "17298092", name: "BANCO ITAÚ BBA S.A." },
  { value: "17351180", name: "BANCO TRIANGULO S.A." },
  { value: "17453575", name: "ICBC DO BRASIL BANCO MÚLTIPLO S.A." },
  { value: "19307785", name: "MS BANK S.A. BANCO DE CÂMBIO" },
  { value: "23522214", name: "COMMERZBANK BRASIL S.A. - BANCO MÚLTIPLO" },
  { value: "23903068", name: "BANCO KOMATSU DO BRASIL S.A." },
  { value: "27098060", name: "BANCO DIGIO S.A." },
  {
    value: "28127603",
    name: "BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO",
  },
  { value: "28195667", name: "BANCO ABC BRASIL S.A." },
  { value: "28517628", name: "BANCO PACCAR S.A." },
  { value: "28811341", name: "INTL FCSTONE BANCO DE CÂMBIO S.A." },
  { value: "29030467", name: "SCOTIABANK BRASIL S.A. BANCO MÚLTIPLO" },
  { value: "30172491", name: "BANCO HYUNDAI CAPITAL BRASIL S.A." },
  { value: "30306294", name: "BANCO BTG PACTUAL S.A." },
  { value: "30723886", name: "BANCO MODAL S.A." },
  { value: "31597552", name: "BANCO CLASSICO S.A." },
  { value: "31872495", name: "BANCO C6 S.A." },
  { value: "31880826", name: "BANCO GUANABARA S.A." },
  { value: "31895683", name: "BANCO INDUSTRIAL DO BRASIL S.A." },
  { value: "32062580", name: "BANCO CREDIT SUISSE (BRASIL) S.A." },
  { value: "33042151", name: "BANCO DE LA NACION ARGENTINA" },
  { value: "33042953", name: "CITIBANK N.A." },
  { value: "33132044", name: "BANCO CEDULA S.A." },
  { value: "33147315", name: "BANCO BRADESCO BERJ S.A." },
  { value: "33172537", name: "BANCO J.P. MORGAN S.A." },
  { value: "33254319", name: "BANCO LOSANGO S.A. - BANCO MÚLTIPLO" },
  { value: "33264668", name: "BANCO XP S.A." },
  { value: "33466988", name: "BANCO CAIXA GERAL - BRASIL S.A." },
  { value: "33479023", name: "BANCO CITIBANK S.A." },
  { value: "33603457", name: "BANCO RODOBENS S.A." },
  { value: "33644196", name: "BANCO FATOR S.A." },
  { value: "33885724", name: "BANCO ITAÚ CONSIGNADO S.A." },
  { value: "33923798", name: "BANCO MÁXIMA S.A." },
  { value: "34270520", name: "BANCO IBM S.A." },
  { value: "42272526", name: "BNY MELLON BANCO S.A." },
  { value: "43818780", name: "DAYCOVAL LEASING - BANCO MÚLTIPLO S.A." },
  { value: "44189447", name: "BANCO DE LA PROVINCIA DE BUENOS AIRES" },
  { value: "45246410", name: "PLURAL S.A. BANCO MÚLTIPLO" },
  { value: "46518205", name: "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION" },
  { value: "48795256", name: "BANCO ANDBANK (BRASIL) S.A." },
  { value: "49336860", name: "ING BANK N.V." },
  { value: "49925225", name: "BANCO ITAULEASING S.A." },
  { value: "50585090", name: "BCV - BANCO DE CRÉDITO E VAREJO S.A." },
  { value: "51938876", name: "BANCO DE LA REPUBLICA ORIENTAL DEL URUGUAY" },
  { value: "54403563", name: "BANCO ARBI S.A." },
  { value: "55230916", name: "INTESA SANPAOLO BRASIL S.A. - BANCO MÚLTIPLO" },
  { value: "57839805", name: "BANCO TRICURY S.A." },
  { value: "58017179", name: "BANCO VOLVO BRASIL S.A." },
  { value: "58160789", name: "BANCO SAFRA S.A." },
  { value: "58497702", name: "BANCO SMARTBANK S.A." },
  { value: "58616418", name: "BANCO FIBRA S.A." },
  { value: "59109165", name: "BANCO VOLKSWAGEN S.A." },
  { value: "59118133", name: "BANCO LUSO BRASILEIRO S.A." },
  { value: "59274605", name: "BANCO GMAC S.A." },
  { value: "59285411", name: "BANCO PAN S.A." },
  { value: "59588111", name: "BANCO VOTORANTIM S.A." },
  { value: "60394079", name: "BANCO ITAUBANK S.A." },
  { value: "60498557", name: "BANCO MUFG BRASIL S.A." },
  { value: "60518222", name: "BANCO SUMITOMO MITSUI BRASILEIRO S.A." },
  { value: "60701190", name: "ITAÚ UNIBANCO S.A." },
  { value: "60746948", name: "BANCO BRADESCO S.A." },
  { value: "60814191", name: "BANCO MERCEDES-BENZ DO BRASIL S.A." },
  { value: "60850229", name: "OMNI BANCO S.A." },
  { value: "60872504", name: "ITAÚ UNIBANCO HOLDING S.A." },
  { value: "60889128", name: "BANCO SOFISA S.A." },
  { value: "61024352", name: "BANCO INDUSVAL S.A." },
  { value: "61033106", name: "BANCO CREFISA S.A." },
  { value: "61088183", name: "BANCO MIZUHO DO BRASIL S.A." },
  { value: "61182408", name: "BANCO INVESTCRED UNIBANCO S.A." },
  { value: "61186680", name: "BANCO BMG S.A." },
  { value: "61190658", name: "BANCO ITAÚ VEÍCULOS S.A." },
  { value: "61348538", name: "BANCO FICSA S.A." },
  { value: "61533584", name: "BANCO SOCIETE GENERALE BRASIL S.A." },
  { value: "61820817", name: "BANCO PAULISTA S.A." },
  {
    value: "62073200",
    name: "BANK OF AMERICA MERRILL LYNCH BANCO MÚLTIPLO S.A.",
  },
  { value: "62144175", name: "BANCO PINE S.A." },
  { value: "62232889", name: "BANCO DAYCOVAL S.A." },
  { value: "62237425", name: "BANCO FIDIS S/A" },
  { value: "62307848", name: "BANCO RCI BRASIL S.A." },
  { value: "62331228", name: "DEUTSCHE BANK S.A. - BANCO ALEMAO" },
  { value: "62421979", name: "BANCO CIFRA S.A." },
  { value: "68900810", name: "BANCO RENDIMENTO S.A." },
  { value: "71027866", name: "BANCO BS2 S.A." },
  { value: "71371686", name: "BANCO OLÉ BONSUCESSO CONSIGNADO S.A." },
  { value: "74828799", name: "NOVO BANCO CONTINENTAL S.A. - BANCO MÚLTIPLO" },
  { value: "75647891", name: "BANCO CRÉDIT AGRICOLE BRASIL S.A." },
  { value: "76543115", name: "BANCO SISTEMA S.A." },
  { value: "78626983", name: "BANCO VR S.A." },
  { value: "78632767", name: "BANCO OURINVEST S.A." },
  { value: "80271455", name: "BANCO MAXINVEST S.A." },
  { value: "90400888", name: "BANCO SANTANDER (BRASIL) S.A." },
  { value: "90731688", name: "BANCO FORD S.A." },
  { value: "91884981", name: "BANCO JOHN DEERE S.A." },
  { value: "92702067", name: "BANCO DO ESTADO DO RIO GRANDE DO SUL S.A." },
  { value: "92874270", name: "BANCO A.J. RENNER S.A." },
  { value: "92894922", name: "BANCO ORIGINAL S.A." },
];

export const CURRENCY_BRAZIL: IOptionSelection[] = [
  { value: CurrencyEnum.BRL, name: CurrencyEnum.BRL },
];
