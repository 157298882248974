import { IRootState } from "../../interfaces/RootState.interfaces";

export const isAllRefundSelectedSelector = (state: IRootState) =>
  state.refunds.isAllRefundSelected;

export const refundsSelector = (state: IRootState) => state.refunds.refunds;
export const totalRefundsSelector = (state: IRootState) =>
  state.refunds.totalRemoteRefunds;
export const selectedRefundsSelector = (state: IRootState) =>
  state.refunds.selectedRefunds;

export const selectIsLoadingTable = (state: IRootState) =>
  state.refunds.isLoadingTable;
