import { IStyles } from "../../../../shared/interfaces/Styles.interfaces";

export const DetailFooterStyles: IStyles = {
  box: {
    backgroundColor: "#F7FAFC",
    display: "flex",
    height: "68px",
    paddingBottom: "32px",
    paddingTop: "16px",
    paddingRight: "24px",
    width: "100%",
    justifyContent: "right",
  },

  box_left: {
    backgroundColor: "#F7FAFC",
    display: "flex",
    height: "68px",
    paddingBottom: "32px",
    paddingTop: "16px",
    paddingRight: "24px",
    width: "100%",
    justifyContent: "left",
  },
};
