export enum StylesEnum {
  TOP = "top",
  CENTER = "center",
  ERROR = "error",
  INFO = "info",
  SUCCESS = "success",
  FILLED = "filled",
  INHERIT = "inherit",
  PRIMARY = "primary",
  ACTION = "action",
  DISABLED = "disabled",
}

export enum RowColorsEnum {
  DEFAULT = "default",
  YELLOW = "yellow",
}

export enum ColorsEnum {
  DANGER = "danger",
  INFO = "info",
  WARNING = "warning",
  SUCCESS = "success",
}

export enum OptionListTypeEnum {
  TYPE1 = "type1",
  TYPE2 = "type2",
}

export enum SnackBarVariantsEnum {
  SIMPLE = "simple",
}

export enum ModalTypesEnum {
  PRIMARY = "primary",
  ERROR = "error",
}
