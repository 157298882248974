import { Grid, Typography } from "@material-ui/core";
import { AlertCircle } from "react-feather";
import React from "react";
import { DeferredAdvancedSettingsSectionConstants } from "../../shared/infrastructure/constants/DeferredConstants";
import { alertInformationStyles } from "./AlertInformation.style";

export interface IAlertInformation {
  showMessage?: boolean;
  message: JSX.Element;
  messageSecondLine?: JSX.Element;
}

export const AlertInformation: React.FC<IAlertInformation> = (
  props: IAlertInformation
) => {
  const classes = alertInformationStyles();

  return (
    <Grid container xs={12} className={classes.contentAlert}>
      <Grid container spacing={1}>
        <Grid item>
          <AlertCircle className={classes.alertIcon} />
        </Grid>
        <Grid item>
          <Typography className={classes.titleAlert}>
            {
              DeferredAdvancedSettingsSectionConstants.DEFERRED_SCHEDULE_INFORMATION
            }
          </Typography>
        </Grid>
      </Grid>
      {props.message}
      {props.showMessage && props.messageSecondLine}
    </Grid>
  );
};
