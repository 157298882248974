import * as React from "react";
import { styled, SvgIcon as MuiSvgIcon, SvgIconProps } from "@mui/material";

const SvgIcon = styled(MuiSvgIcon, {
  name: "IconCash",
  shouldForwardProp: (prop) => prop !== "fill",
})<SvgIconProps>(({ color }) => ({
  fill: color + "!important",
  fontSize: "22px !important",
}));

SvgIcon.defaultProps = {
  viewBox: "0 0 24 24",
  focusable: "false",
  "aria-hidden": "true",
};

const IconCashLocal: React.FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={"cashIcon"}
    >
      <path
        d="M21 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V11C5 10.4696 5.21071 9.96086 5.58579 9.58579C5.96086 9.21071 6.46957 9 7 9H21C21.5304 9 22.0391 9.21071 22.4142 9.58579C22.7893 9.96086 23 10.4696 23 11V19C23 19.5304 22.7893 20.0391 22.4142 20.4142C22.0391 20.7893 21.5304 21 21 21ZM7 11V19H21V11H7Z"
        fill="#023365"
      />
      <path
        d="M6 17H3C2.46957 17 1.96086 16.7893 1.58579 16.4142C1.21071 16.0391 1 15.5304 1 15V7C1 6.46957 1.21071 5.96086 1.58579 5.58579C1.96086 5.21071 2.46957 5 3 5H17C17.5304 5 18.0391 5.21071 18.4142 5.58579C18.7893 5.96086 19 6.46957 19 7V10C19 10.2652 18.8946 10.5196 18.7071 10.7071C18.5196 10.8946 18.2652 11 18 11C17.7348 11 17.4804 10.8946 17.2929 10.7071C17.1054 10.5196 17 10.2652 17 10V7H3V15H6C6.26522 15 6.51957 15.1054 6.70711 15.2929C6.89464 15.4804 7 15.7348 7 16C7 16.2652 6.89464 16.5196 6.70711 16.7071C6.51957 16.8946 6.26522 17 6 17Z"
        fill="#023365"
      />
      <path
        d="M14 18C13.4067 18 12.8266 17.8241 12.3333 17.4944C11.8399 17.1648 11.4554 16.6962 11.2284 16.1481C11.0013 15.5999 10.9419 14.9967 11.0576 14.4147C11.1734 13.8328 11.4591 13.2982 11.8787 12.8787C12.2982 12.4591 12.8328 12.1734 13.4147 12.0576C13.9967 11.9419 14.5999 12.0013 15.1481 12.2284C15.6962 12.4554 16.1648 12.8399 16.4944 13.3333C16.8241 13.8266 17 14.4067 17 15C17 15.7957 16.6839 16.5587 16.1213 17.1213C15.5587 17.6839 14.7957 18 14 18ZM14 14C13.8022 14 13.6089 14.0587 13.4444 14.1685C13.28 14.2784 13.1518 14.4346 13.0761 14.6173C13.0004 14.8 12.9806 15.0011 13.0192 15.1951C13.0578 15.3891 13.153 15.5673 13.2929 15.7071C13.4327 15.847 13.6109 15.9422 13.8049 15.9808C13.9989 16.0194 14.2 15.9996 14.3827 15.9239C14.5654 15.8482 14.7216 15.72 14.8315 15.5556C14.9414 15.3911 15 15.1978 15 15C15 14.7348 14.8946 14.4804 14.7071 14.2929C14.5196 14.1054 14.2652 14 14 14Z"
        fill="#023365"
      />
    </SvgIcon>
  );
};

export default IconCashLocal;
