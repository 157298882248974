import { IStyles } from "../../../shared/infrastructure/interfaces/Styles.interfaces";

export const customBreadcrumbsStyles: IStyles = {
  main: {
    color: "#677784",
  },
  item: {
    color: "#677784",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "170%",
  },
};
