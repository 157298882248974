import {
  TabContentBlockProps,
  TabContentBlockType,
} from "./TabContentBlock.interfaces";
import React from "react";
import { SimpleInfoBlock } from "./SimpleInfoBlock/SimpleInfoBlock";
import { TimeLineRowBlock } from "./TimeLineBlock/TimeLineRowBlock";

export const TabContentBlock = (props: TabContentBlockProps) => {
  if (props.blockType === TabContentBlockType.TIME_LINE_ROW)
    return <TimeLineRowBlock {...props} />;

  if (props.blockType === TabContentBlockType.SIMPLE_INFO)
    return <SimpleInfoBlock {...props} />;

  return <div />;
};
