import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { ICustomModalProps } from "../../shared/infrastructure/interfaces/ICustomModalProps";
import { useCustomModalState } from "./state/useCustomModalState";

export const CustomModal: React.FC<ICustomModalProps> = (
  props: ICustomModalProps
) => {
  const { isOpenCustomModal } = useCustomModalState();
  return (
    <Modal
      open={isOpenCustomModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid sx={ModalSimpleStyles.modalContainer}>
        <Box className="modalHeader">
          <Grid container className="itemsTop">
            <Typography id="modal-modal-title" variant="h3" color="primary">
              {props.titleText}
            </Typography>
            <Grid item height={36} width={36}>
              <IconButton
                color="secondary"
                onClick={props.onClose}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid className="itemsBottom">
            <Typography
              id="modal-modal-description"
              variant="body2"
              color="text.dark"
            >
              {props.descriptionText}
            </Typography>
          </Grid>
        </Box>
        <Box className="modalFooter">
          <Grid container className="itemsButtons">
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={props.actions.handleLeftButtonAction}
              sx={{ mr: 2 }}
            >
              {props.leftButtonText}
            </Button>
            <Button
              size="small"
              variant="contained"
              color={props.isError ? "error" : "primary"}
              onClick={props.actions.handleRightButtonAction}
              disabled={props.isRightButtonDisabled}
            >
              {props.rightButtonText}
            </Button>
          </Grid>
        </Box>
      </Grid>
    </Modal>
  );
};

const ModalSimpleStyles = {
  modalContainer: {
    alignItems: "center",
    backgroundColor: "background.default",
    borderRadius: "8px",
    flexDirection: "row",
    left: "50%",
    margin: "0px 0px",
    minHeight: "auto",
    minWidth: { md: "775px", sm: "370px", xs: "280px" },
    position: "absolute" as "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    ".MuiTypography-h3": {
      width: "80%",
    },
    ".modalHeader": {
      padding: { md: "40px", xs: "24px" },
      paddingBottom: { md: "45px" },
      ".itemsTop": {
        alignItems: "center",
        direction: "row",
        justifyContent: "space-between",
      },
      ".itemsBottom": {
        paddingTop: { md: "18px", xs: "16px" },
      },
    },
    ".modalFooter": {
      backgroundColor: "background.paper",
      borderRadius: "8px",
      padding: "16px",
      ".itemsButtons": {
        alignItems: "center",
        direction: "row",
        justifyContent: "flex-end",
      },
    },
  },
};
