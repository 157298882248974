import React from "react";
import { SnackBarAlert } from "../Snackbar/SnackBarAlert";
import { useNotification } from "./useNotification";

export const Notification: React.FC = () => {
  const {
    notification,
    handlers: { handleCloseSnackbar },
  } = useNotification();
  return (
    <>
      <SnackBarAlert
        type={notification.type}
        msg={notification.message}
        open={notification.open}
        handlerClose={handleCloseSnackbar}
      />
    </>
  );
};
