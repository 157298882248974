import { Transaction } from "../../../types/transactions_data";
import { CellCheckItem } from "../../components/TableCells/CellCheck/CellCheck";
import { FC } from "react";
import { CellTitleItem } from "../../components/TableCells/CellTitle/CellTitle";
import { CellTextCopy } from "../../components/TableCells/CellTextCopy/CellTextCopy";
import { CellFlagItem } from "../../components/TableCells/CellFlag/CellFlag";
import { CellChipStatus } from "../../components/TableCells/CellChip/CellChipStatus";
import { CellTitleCopyItem } from "../../components/TableCells/CellTitleCopy/CellTitleCopy";
import { defaultTo, get, isEmpty } from "lodash";
import { CATALOG_TRANSACTION_TYPES } from "../enums/TransactionTypeEnum";
import { CATALOG_PAYMENT_METHOD } from "../enums/PaymentMethodEnum";
import {
  ITableCellProps,
  ITableRowProps,
} from "../../components/Table/TableTransactions/TableTransactions.interfaces";
import { generateUrl } from "../utils/urlUtils";
import { formatNumber } from "../utils/table_transactions_utils";
import { TransactionStatusEnum } from "../enums/TransactionStatusEnum";
import { dateZonedTime } from "../utils/timeZoneRegion_utils";
import { format } from "date-fns-tz";
import { HeaderColumnIdEnum } from "../enums/HeaderColumnIdEnum";

enum TableBodyCellEnum {
  CHECK = "CHECK",
  TITLE = "TITLE",
  TEXT_COPY = "TEXT_COPY",
  TAG = "TAG",
  FLAG = "FLAG",
  TITLE_COPY = "TITLE_COPY",
}

interface IConfig {
  //Valor por defecto en caso que no se obtenga un valor del objeto con el path.
  defaultValue?: any;
  //Path para obtener el valor del objeto.
  path: string;
  //Text que se mostrara en la tabla, se agrega $var para mostrar la posición donde estara el valor obtenido del objeto.
  text: string;
  isDate?: boolean;
  hasUrl?: boolean;
  isType?: boolean;
  isPaymentMethod?: boolean;
  isAmount?: boolean;
}

export interface IConfigCellBasic {
  configMain?: IConfig;
  configSecondary?: IConfig;
  type: TableBodyCellEnum;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  tooltip?: boolean;
  isActive: boolean;
}

interface IConfigCellCheck extends IConfigCellBasic {
  isChecked: boolean;
}

interface IConfigCellFlag extends IConfigCellBasic {
  isCodeCountry?: boolean;
}

export type IConfigCells =
  | IConfigCellBasic
  | IConfigCellCheck
  | IConfigCellFlag;

export interface IAdditionalProps {
  handleGetRowData?: () => Transaction;
}

export enum RowsPerPage {
  DEFAULT = 10,
}

export enum SourceData {
  SOURCE = "_source.",
}

const CELL_COMPONENTS_BY_TABLE_ENUM: Record<TableBodyCellEnum, FC<any>> = {
  [TableBodyCellEnum.CHECK]: CellCheckItem,
  [TableBodyCellEnum.TITLE]: CellTitleItem,
  [TableBodyCellEnum.TEXT_COPY]: CellTextCopy,
  [TableBodyCellEnum.FLAG]: CellFlagItem,
  [TableBodyCellEnum.TAG]: CellChipStatus,
  [TableBodyCellEnum.TITLE_COPY]: CellTitleCopyItem,
};

export const formatDate = (
  dateString: string,
  formatString: string = "yyyy-MM-dd"
): string => {
  let formattedDate: string = "";

  try {
    formattedDate = format(dateZonedTime(dateString), formatString);
  } catch (_) {}

  return formattedDate;
};

function getAmountValueByTransactionStatus<T = object>(obj: T): string {
  const currency: string = get(
    obj,
    SourceData.SOURCE.concat("currency_code"),
    ""
  );
  const transactionStatus: string = get(
    obj,
    SourceData.SOURCE.concat("transaction_status"),
    ""
  );

  const value: string =
    transactionStatus === TransactionStatusEnum.DECLINED ||
    transactionStatus === TransactionStatusEnum.INITIALIZED
      ? defaultTo(get(obj, SourceData.SOURCE.concat("request_amount")), "0")
      : defaultTo(
          get(obj, SourceData.SOURCE.concat("approved_transaction_amount")),
          "0"
        );

  const amount: string = formatNumber(value).concat(" ").concat(currency);

  return amount;
}

function generateText<T = object>(
  obj: T,
  text: string = "",
  path: string,
  defaultValue?: string,
  isDate: boolean = false,
  isType: boolean = false,
  isPaymentMethod: boolean = false,
  isAmount: boolean = false,
  isSecondary: boolean = false
): string {
  const completePath: string = SourceData.SOURCE.concat(path);
  const required_value = get(obj, completePath, defaultTo(defaultValue, ""));

  if (isDate) {
    const status = get(obj, `${SourceData.SOURCE}transaction_status`, "");
    let formattedDate: string = "";

    if (
      HeaderColumnIdEnum.COMPLETED === path &&
      status === TransactionStatusEnum.INITIALIZED
    )
      formattedDate = "-";
    else
      formattedDate = isSecondary
        ? formatDate(required_value, "HH:mm:ss")
        : formatDate(required_value);

    return text.replace("$var", formattedDate);
  }

  if (isType) {
    const typeTransactionLabel: string =
      CATALOG_TRANSACTION_TYPES[required_value];

    return text.replace("$var", typeTransactionLabel);
  }

  if (isPaymentMethod) {
    const paymentMethodLabel: string = CATALOG_PAYMENT_METHOD[required_value];

    return text.replace("$var", paymentMethodLabel);
  }

  if (isAmount) {
    return text.replace("$var", getAmountValueByTransactionStatus(obj));
  }

  return text.replace("$var", required_value);
}

export function builderRows<T = object>(
  data: T[],
  configRows: IConfigCells[],
  page: string = ""
): ITableRowProps<T>[] {
  const rows_props: ITableRowProps<T>[] = [];
  let table_cells: ITableCellProps[];
  let cell: ITableCellProps;

  data.map((obj: T, index: number) => {
    table_cells = [];
    configRows.map((config: IConfigCells) => {
      switch (config.type) {
        case TableBodyCellEnum.CHECK:
          cell = {
            props: {
              align: get(config, "align"),
              id: new Date().getTime().toString(),
              isChecked: false,
            },
            type: TableBodyCellEnum.CHECK,
          };
          break;
        case TableBodyCellEnum.TITLE:
          cell = {
            props: {
              align: get(config, "align"),
              line1: generateText<T>(
                obj,
                get(config, "configMain.text"),
                get(config, "configMain.path"),
                get(config, "configMain.defaultValue"),
                get(config, "configMain.isDate", false),
                get(config, "configMain.isType", false),
                get(config, "configMain.isPaymentMethod", false),
                get(config, "configMain.isAmount", false)
              ),
              line2: generateText<T>(
                obj,
                get(config, "configSecondary.text"),
                get(config, "configSecondary.path"),
                get(config, "configSecondary.defaultValue"),
                get(config, "configSecondary.isDate", false),
                get(config, "configSecondary.isType", false),
                get(config, "configSecondary.isPaymentMethod", false),
                get(config, "configSecondary.isAmount", false),
                true
              ),
              tooltip: get(config, "tooltip", false),
              type: !isEmpty(get(config, "configSecondary.path"))
                ? "twoLines"
                : "oneLine",
              url: generateUrl(obj, get(config, "configMain.hasUrl")),
            },
            type: TableBodyCellEnum.TITLE,
          };
          break;
        case TableBodyCellEnum.TITLE_COPY:
          cell = {
            props: {
              align: get(config, "align"),
              line1: generateText<T>(
                obj,
                get(config, "configMain.text"),
                get(config, "configMain.path"),
                get(config, "configMain.defaultValue"),
                get(config, "configMain.isDate", false),
                get(config, "configMain.isType", false),
                get(config, "configMain.isPaymentMethod", false),
                get(config, "configMain.isAmount", false)
              ),
              line2: generateText<T>(
                obj,
                get(config, "configSecondary.text"),
                get(config, "configSecondary.path"),
                get(config, "configSecondary.defaultValue"),
                get(config, "configSecondary.isDate", false),
                get(config, "configSecondary.isType", false),
                get(config, "configSecondary.isPaymentMethod", false),
                get(config, "configSecondary.isAmount", false),
                true
              ),
              type: !isEmpty(get(config, "configSecondary.path"))
                ? "twoLines"
                : "oneLine",
              url: generateUrl(obj, get(config, "configMain.hasUrl")),
            },
            type: TableBodyCellEnum.TITLE_COPY,
          };
          break;
        case TableBodyCellEnum.TEXT_COPY:
          const info = generateText<T>(
            obj,
            get(config, "configMain.text"),
            get(config, "configMain.path"),
            get(config, "configMain.defaultValue")
          );

          cell = {
            props: {
              align: get(config, "align"),
              textToCopy: info,
              title: info,
            },
            type: TableBodyCellEnum.TEXT_COPY,
          };
          break;
        case TableBodyCellEnum.FLAG:
          cell = {
            props: {
              align: get(config, "align"),
              country: generateText<T>(
                obj,
                get(config, "configMain.text"),
                get(config, "configMain.path"),
                get(config, "configMain.defaultValue")
              ),
              isCodeCountry: get(config, "isCodeCountry", false),
              type: "twolines",
            },
            type: TableBodyCellEnum.FLAG,
          };
          break;
        case TableBodyCellEnum.TAG:
          cell = {
            props: {
              align: get(config, "align"),
              text: generateText<T>(
                obj,
                get(config, "configMain.text"),
                get(config, "configMain.path"),
                get(config, "configMain.defaultValue")
              ),
              textTooltipMain: generateText<T>(
                obj,
                get(config, "configSecondary.text"),
                get(config, "configSecondary.path"),
                get(config, "configSecondary.defaultValue")
              ),
            },
            type: TableBodyCellEnum.TAG,
          };
          break;
      }
      table_cells.push(cell);
    });
    rows_props.push({
      cells: table_cells,
      id: index.toString().concat(`.${page}`),
      info: obj,
    });
  });

  return rows_props;
}

export { TableBodyCellEnum, CELL_COMPONENTS_BY_TABLE_ENUM };
