import {
  ActionsUserTypeEnum,
  ModalActionLabels,
} from "../../../shared/enum/UsersTableEnum";
import { useAppDispatch } from "../../../store/hooks/storeHook";
import { setActionsModal } from "../../../store/reducers/users/users.slice";
import { IUserList } from "../../../shared/interfaces/IUseUsersContainerState";
import { useLocation } from "react-router-dom";
import { defaultTo } from "lodash";
import { IUseUseUserActions } from "../UserActions.interfaces";
import { setUser } from "../../../store/reducers/userForm/userForm.slice";
import { CatalogDisabledStatus } from "../../../shared/enum/StatusUserEnum";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const useUserActions = (user: IUserList): IUseUseUserActions => {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const isResetPasswordDisabled = (user: IUserList): boolean => {
    return CatalogDisabledStatus.includes(user.status) || !user.enabled;
  };
  const handleModalActions = (type: ActionsUserTypeEnum, user: IUserList) => {
    if (type === ActionsUserTypeEnum.PASSWORD) {
      const description = ModalActionLabels.DESCRIPTION_PASSWORD.replace(
        "__MAIL__",
        user.email
      ).replace("__USER__", user.userName);

      dispatch(
        setActionsModal({
          cancelButton: ModalActionLabels.CANCEL_BUTTON,
          confirmButton: ModalActionLabels.BUTTON_PASSWORD,
          description,
          openModal: true,
          title: ModalActionLabels.TITLE_PASSWORD,
          type,
          userSelected: user,
        })
      );
    } else {
      dispatch(
        setActionsModal({
          cancelButton: ModalActionLabels.CANCEL_BUTTON,
          confirmButton: ModalActionLabels.BUTTON_DELETE,
          description: ModalActionLabels.DESCRIPTION_DELETE,
          openModal: true,
          title: ModalActionLabels.TITLE_DELETE,
          type,
          userSelected: user,
        })
      );
    }
  };

  const handleEditAction = (user: IUserList) => {
    dispatch(
      setUser({
        email: user.email,
        familyName: user.familyName,
        group: user.roles,
        name: user.name,
        publicMerchantId: user.publicMerchantId,
        userName: user.userName,
      })
    );
  };

  const handleUserEnabled = (): boolean => user.enabled;

  return {
    handleEditAction,
    handleModalActions,
    hideSideBar: defaultTo(query.get("hideSideBar"), ""),
    isResetPasswordDisabled,
    isUserEnabled: handleUserEnabled(),
    merchantName: defaultTo(query.get("merchantName"), ""),
  };
};
