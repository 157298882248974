export enum PaymentMethodEnum {
  CARD = "card",
  CASH = "cash",
  PAYOUTS_TRANSFER = "payouts-transfer",
  PAYOUTS_CARD = "payouts-card",
  TRANSFER = "transfer",
  PAYOUTS_CASH = "payouts-cash",
  TRANSFER_SUBSCRIPTIONS = "transfer-subscriptions",
  TRANSFER_INTERNATIONAL = "internationalTransfer",
}
export const PaymentMethodAlias: Record<string, string> = {
  [PaymentMethodEnum.TRANSFER_SUBSCRIPTIONS]: "debit",
};
