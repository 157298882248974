/**
 * Notification Information Enum
 */

export enum NotificationMessagesEnum {
  UPDATE_PROCESSOR_ERROR = "Lo sentimos, no se pudo actualizar el procesador, inténtalo más tarde.",
  UPDATE_PROCESSOR_SUCCESS = "Procesador actualizado con éxito.",
  DISABLE_PROCESSOR_SUCCESS = "Se deshabilitó el procesador correctamente.",
  ENABLE_PROCESSOR_SUCCESS = "Se habilitó el procesador correctamente.",
  UPDATE_STATUS_PROCESSOR_ERROR = "Se presentó un error inesperado. Inténtalo nuevamente.",
}
