import React from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Container,
  Typography,
} from "@mui/material";
import { useDevelopersContainer } from "./state/useDevelopersContainerState";
import BreadcrumbsComponent from "@components/Breadcrumbs/Breadcrumbs";
import { developersContainerStyles as styles } from "@containers/Developers/DevelopersContainer.styles";
import { DevelopersImage } from "../../assets/image/DevelopersImage";
import DeveloperSections from "@components/DeveloperSection/DeveloperSections";

const DevelopersContainer: React.FC = () => {
  const { breadcrumList, sections } = useDevelopersContainer();

  return (
    <Container>
      <BreadcrumbsComponent items={breadcrumList} />
      <Typography variant={"h1"} sx={styles.title}>
        Desarrolladores
      </Typography>
      <Card sx={styles.card}>
        <CardHeader
          sx={styles.cardSpacing}
          avatar={
            <Avatar sx={styles.avatar}>
              <DevelopersImage />
            </Avatar>
          }
          title={
            <Typography variant="h5" color={"primary"}>
              Desarrolladores
            </Typography>
          }
        />
        <CardContent sx={styles.cardSpacing}>
          <DeveloperSections items={sections} />
        </CardContent>
      </Card>
    </Container>
  );
};

export default DevelopersContainer;
