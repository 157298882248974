import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { IStyles } from "../../../shared/interfaces/Styles.interfaces";

const TableHeadStyles: IStyles = {
  arrow: {
    svg: {
      marginLeft: "15px",
    },
  },
  disabledHead: {
    opacity: 0.3,
  },
  enabledHead: {
    opacity: 1,
  },
  iconColor: {
    color: "#023365",
  },
  label: {
    "span:hover": {
      color: "#023365",
    },
  },
  row: {
    "&.MuiTableRow-root": {
      "th:first-of-type": {
        borderBottomLeftRadius: "8px",
        borderTopLeftRadius: "8px",
      },
      "th:last-of-type": {
        borderBottomRightRadius: "8px",
        borderTopRightRadius: "8px",
      },
    },
  },
};

const TABLE_HEAD_DISABLE_SX: Record<string, SxProps<Theme>> = {
  false: TableHeadStyles.enabledHead,
  true: TableHeadStyles.disabledHead,
};

export { TableHeadStyles, TABLE_HEAD_DISABLE_SX };
