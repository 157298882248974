import { IStyles } from "../../../../shared/interfaces/Styles.interfaces";

export const DetailHeaderStyles: IStyles = {
  clipboardGrid: {
    paddingLeft: "30px",
  },
  container: {},
  copyURLButton: {
    color: "text.primary",
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "140%",
    padding: "0 0 7px 0",
    typography: "subtitle2",
  },
  headerLine: {
    color: "neutral.grey7",
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: "120%",
    typography: "h4",
  },
  subheaderLine: {
    color: "text.grey",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "140%",
    margin: "8px 0px",
  },
  tab: {
    color: "neutral.grey8",
    fontFamily: "IBM Plex Sans, Helvetica, sans-serif",
    fontSize: "14px",
    lineHeight: "170%",
    width: "33.33%",
  },
  tabsContainer: {
    borderBottom: 1,
    borderColor: "neutral.grey5",
  },
  title: {
    color: "text.grey",
    fontWeight: "normal",
    lineHeight: "136%",
    margin: "4px 0px",
    typography: "caption",
    //@ts-ignore
    fontSize: "12px !important",
  },
  titleContainer: {
    padding: "16px 24px 9px 24px",
  },
};
