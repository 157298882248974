import { BoxProps, Grid } from "@mui/material";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import SearchIcon from "../../assets/icons/SearchIcon";
import { selectedRefundsSelector } from "../../store/selectors/refunds/refunds.selectors";
import DateTimeButton from "../common/DateTimeButton/DateTimeButton";
import DateTimeRangePicker from "../common/DateTimeRangePicker/DateTimeRangePicker";
import LayoutActionBar from "../common/LayoutActionBar/LayoutActionBar";
import SearchInput from "../common/SearchInput/SearchInput";
import ProcessRefundsButton from "../ProcessRefundsButton/ProcessRefundsButton";
import { useRefundsTableState } from "../RefundsTable/state/useRefundsTableState";
import { refundsActionBarStyles as styles } from "./RefundsActionBar.styles";
import useRefundsActionBarState from "./state/useRefundsActionBarState";

const RefundsActionBar: FC<BoxProps> = (props: BoxProps) => {
  const { dateTimeFrom, setFromTo, dateTimeTo, setDebounceSearchFilter } =
    useRefundsActionBarState();

  const timeStyle = { ...styles.baseBtn, ...styles.btnTime };
  const { totalRemoteRefunds } = useRefundsTableState();
  const selected = useSelector(selectedRefundsSelector).map(
    (refund) => refund.id
  );

  return (
    <LayoutActionBar {...props}>
      <Grid container>
        <Grid item md={4}>
          <DateTimeRangePicker
            disableFuture
            from={dateTimeFrom}
            to={dateTimeTo}
            onChange={setFromTo}
            renderComponent={(props) => (
              <DateTimeButton {...props} sx={timeStyle} />
            )}
          />
        </Grid>
        <Grid item md={4}>
          <SearchInput
            placeholder="Buscar por número de transferencias"
            sx={styles.input}
            endAdornment={<SearchIcon sx={styles.iconSearch} />}
            onChange={setDebounceSearchFilter}
          />
        </Grid>
        <Grid item md={4}>
          <ProcessRefundsButton
            totalRefunds={totalRemoteRefunds}
            refundsForProcessing={selected}
          />
        </Grid>
      </Grid>
    </LayoutActionBar>
  );
};

export default RefundsActionBar;
