export enum ActionTypes {
  SET_NOTIFICATION = "[RULE-REQUEST-MANAGER] SET_NOTIFICATION",
  SET_CONFIGURATION = "[RULES] SET CONFIGURATION",
  SET_GEOGRAPHY_LIST = "[RULES] SET GEOGRAPHY LIST",
  SET_RULE_DATA = "[RULES] SET RULE DATA",
  SET_LOADING_CONFIGURATION = "[RULES] SET LOADING CONFIGURATION",
  SET_IS_REQUESTING = "[RULES] SET IS REQUESTING",
  SET_IS_PAYMENT_METHOD = "[RULES] SET_IS_PAYMENT_METHOD",
  SET_REQUEST_ERROR = "[RULES] SET REQUEST ERROR",
  SET_IS_LOADING = "[RULES] SET_IS_LOADING",
  SET_IS_LOADING_REQUEST = "[RULES] SET_IS_LOADING_REQUEST",
  SET_IS_LOADING_RULES = "[RULES] SET_IS_LOADING_RULES",
  SET_VALIDATING_FILE = "[RULES] SET_VALIDATING_FILE",
  SET_TXT_FIELD_ACTIONS = "[RULES] SET_TXT_FIELD_ACTIONS",
  SET_VERIFY_MASSIVE_CONDITION_RESPONSE = "[RULES] SET_VERIFY_MASSIVE_CONDITION_RESPONSE",
  SET_MERCHANT_CREDENTIALS = "[RULES] SET MERCHANT CREDENTIALS",
  SET_HIERARCHY_NODES = "[RULES] SET HIERARCHY NODES",
  SET_AVAILABLE_CONDITIONS = "[RULES] SET AVAILABLE CONDITIONS",
  SET_MERCHANT_PROCESSORS = "[RULES] SET MERCHANT PROCESSORS",
  SET_COUNTRY_RULES = "[RULES] SET COUNTRIES RULES",
  SET_CURRENCY = "[RULES] SET CURRENCY",
  SET_MERCHANT_HIERARCHY = "[RULES] SET MERCHANT HIERARCHY",
  SET_RULE_DETAILS = "[RULES] SET RULE DETAILS",
  SET_IS_EDITED_RULE = "[RULES] SET IS EDITED RULE",
  SET_GENERAL_RULE = "[RULES] SET GENERAL RULE",
  SET_RULE_CATALOG = "[RULES] SET RULE CATALOG",
  SET_OPEN_ALERT = "[RULES] SET OPEN ALERT",
  SET_SAVING_RULE = "[RULES] SET SAVING RULE",
  SET_OPEN_CONFIRM_MODAL = "[RULES] SET OPEN CONFIRM MODAL",
  SET_OPEN_CONFIRM_GENERAL_RULE_MODAL = "[RULES] SET OPEN CONFIRM GENERAL RULE MODAL",
  SET_SEARCH_REQUEST_RULES_RESPONSE = "[RULES] SET SEARCH REQUEST RULES RESPONSE",
  SET_SEARCH_REQUEST_BY_ID = "[RULES] SET SEARCH REQUEST BY ID",
  SET_IS_LOADING_ONE_RULE = "[RULES] SET IS LOADING ONE RULE",
  SET_OPEN_MODAL_INFO = "[RULES] SET OPEN MODAL INFO",
  SET_FILTER_RESPONSE = "[RULES] SET FILTER RESPONSE",
  SET_SEARCH_RULE_BY_ID = "[RULES] SET SEARCH RULE BY ID",
  SET_SEARCH_REQUEST_RULE_BY_ID = "[RULES] SET SEARCH REQUEST RULE BY ID",
  SET_OPEN_MODAL_INFO_REJECTED = "[RULES] SET OPEN MODAL REJECTED RULE",
  SET_SELECTED_TAB_INDEX = "[RULES] SET_SELECTED_TAB_INDEX",
  SET_SELECTED_FILTER = "[RULES] SET_SELECTED_FILTER",
  SET_SEARCH_FILTER = "[RULES] SET_SEARCH_FILTER",
  SET_GO_BACK = "[RULES] SET_GO_BACK",
  SET_LAST_LIMIT = "[RULES] SET_LAST_LIMIT",
  SET_SUCCESSFUL_ENDPOINT_CALL = "[RULES] SET_SUCCESS_ENDPOINT_CALL",
  SET_IS_LOADING_MODAL_CHANGE = "[RULES] SET_IS_LOADING_MODAL_CHANGE",
  SET_REFRESH_TABLE = "[RULES] SET REFRESH TABLE",
  SET_WHITE_LIST_BY_COUNTRY = "[RULES] SET_WHITE_LIST_BY_COUNTRY",
  SET_SECURITY_RULE_BY_TYPE = "[RULES] SET_SECURITY_RULE_BY_TYPE",
  SET_MERCHANT_INFORMATION = "[RULES] SET_MERCHANT_INFORMATION",
  SET_LIST_COMMERCE_WITHOUT_HIERACHY = "[RULES] SET_LIST_COMMERCE_WITHOUT_HIERACHY",
  SET_OPEN_MODAL_CATALOG_GENERAL_RULES = "[RULES] SET OPEN MODAL CATALOG GENERAL RULES",
  SET_DETAIL_CATALOG_GENERAL_RULES = "[RULES] SET DETAIL CATALOG GENERAL RULES",
  SET_ACTION_TYPE = "[RULES] SET ACTION TYPE",
  SET_IS_LOADING_PROCESSOR = "[RULES] SET_IS_LOADING_PROCESSOR",
  SET_COMMERCE_TYPE = "[RULES] SET COMMERCE TYPE",
  SET_IS_LOADING_CATALOG = "[RULES] SET IS LOADING CATALOG",
  SET_LIST_MCC_CATALOG = "[RULES] SET IS LIST MCC CATALOG",
}
