import React, { FC } from "react";
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { IProcessorInfoComponent } from "./ProcessorInfoComponent.interfaces";
import { get } from "lodash";
import { processorInfoComponentStyles } from "./ProcessorInfoComponent.styles";
import { LabelsEnum } from "../../../shared/constants/LabelsEnum";
import { MaskSkeleton } from "../../MaskSkeleton/MaskSkeleton";

const ProcessorInfoComponent: FC<IProcessorInfoComponent> = (
  props: IProcessorInfoComponent
) => {
  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item xs={12} sx={processorInfoComponentStyles.basicContainer}>
              <Grid item xs={12} sx={processorInfoComponentStyles.titleBasic}>
                <Typography>{get(props, "title", "")}</Typography>
              </Grid>
              <Grid item xs={12} sx={processorInfoComponentStyles.titleBasic}>
                <Typography>
                  {get(props, "processorModelOption.titleInfo", "")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={processorInfoComponentStyles.formContainer}
              >
                <Grid item xs={6} sx={processorInfoComponentStyles.field}>
                  <MaskSkeleton
                    sx={processorInfoComponentStyles.fieldSkeleton}
                    isLoading={props.isLoading}
                  >
                    <TextField
                      id="alias"
                      label="Alias"
                      variant="outlined"
                      error={props.aliasError}
                      fullWidth
                      value={props.aliasValue}
                      helperText={
                        props.aliasError ? LabelsEnum.invalidAlias : ""
                      }
                      onChange={props.onAliasChange}
                    />
                  </MaskSkeleton>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <MaskSkeleton
                  sx={processorInfoComponentStyles.checkSkeleton}
                  isLoading={props.isLoading}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={processorInfoComponentStyles.checkBox}
                        onChange={props.onDefaultTransferChecked}
                        checked={props.isDefaultTransferProcessor}
                      />
                    }
                    label={props.checkDescription!}
                  />
                </MaskSkeleton>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default ProcessorInfoComponent;
