import React, { FC } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  AdditionalStepName,
  AdditionalStepsEnum,
  StepData,
  StepName,
  StepsEnum,
} from "../../../shared/infrastructure/constants/CreateMerchantConstants";
import { MerchantStepSection } from "../MerchantStepSection/MerchantStepSection";
import { ExpandedSection } from "../../../containers/MerchantDetailsIndex/state/useMerchantDetailsIndex";
import { StatusStepsState } from "../../../store/reducer";
import { StatusSteps } from "../../../../types/remote/semaphore_data";
import { AccountPreferencesAdditional } from "../AccountPreferencesAdditionalSection/AccountPreferencesAdditional";
import { DevelopersSection } from "../DevelopersSection/DevelopersSection";
const useSectionsStyles = makeStyles({
  root: {
    marginTop: "53px",
  },
  header: {
    marginBottom: "19px",
  },
});

export const CREATE_MERCHANT_ADDITIONAL_SECTIONS: {
  [key in AdditionalStepName]: StepData;
} = {
  [AdditionalStepsEnum.developers]: {
    label: "Desarrolladores",
    content: function DevsSection() {
      return <DevelopersSection />;
    },
  },
  [AdditionalStepsEnum.accountPreferences]: {
    label: "Preferencias de Cuenta",
    content: AccountPreferencesAdditional,
  },
};

export type MerchantAdditionalStepsProps = {
  steps: StatusStepsState[];
  isNewMerchant: boolean;
  isDisableUserStep: boolean;
  expandedSection: ExpandedSection;
  handleSectionExpansion: (
    step: StepsEnum
  ) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  changeStepStatus: (step: StepName, status: StatusSteps) => void;
};

export const MerchantDetailsAdditionalSteps: FC<MerchantAdditionalStepsProps> = (
  props
) => {
  const {
    isNewMerchant,
    isDisableUserStep,
    expandedSection,
    handleSectionExpansion,
    steps,
    changeStepStatus,
  } = props;
  const classes = useSectionsStyles();

  return (
    <div className={classes.root}>
      <Typography
        variant={"h4"}
        className={classes.header}
        data-testid="additional-steps-label"
      >
        Configuración Adicional
      </Typography>
      {steps.map((step: StatusStepsState, index: number) => {
        const stepData = CREATE_MERCHANT_ADDITIONAL_SECTIONS[step.name];
        if (!stepData) {
          return null;
        }

        return (
          <MerchantStepSection
            disabled={
              (isNewMerchant && step.name !== StepsEnum.stepBasicData) ||
              (!isDisableUserStep && step.name === StepsEnum.stepUsers)
            }
            stepStatus={step}
            sectionData={stepData}
            expanded={step.name === expandedSection}
            handleChange={handleSectionExpansion(step.name as StepsEnum)}
            key={index}
            sectionNumber={index + 1}
          >
            {stepData.content(step.name, changeStepStatus)}
          </MerchantStepSection>
        );
      })}
    </div>
  );
};
