import { makeStyles } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/core";
import theme from "../../theme";

export const alertModalStyle = makeStyles(() =>
  createStyles({
    boxContainer: {
      width: "100%",
      height: "100%",
    },
    button: {
      height: theme.spacing(4),
      width: theme.spacing(13.875),
    },
    descriptionText: {
      color: "#6D7781",
      fontSize: theme.spacing(1.875),
      fontWeight: 400,
      lineHeight: "18px",
    },
    dialogContainer: {
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          padding: theme.spacing(3, 4, 3, 4),
          fontSize: theme.spacing(2.5),
          width: "100%",
          maxWidth: theme.spacing(79.25),
        },
      },
      margin: "auto",
    },
    dialogContent: {
      padding: theme.spacing(3, 0, 1.5, 0),
    },
    dialogActions: {
      padding: "0px",
    },
  })
);
