import { IMultipleFormValues } from "../constants/initial_state_legal_details";
import { CountryEnum } from "../countryEnum";
import { get, isEmpty } from "lodash";

const VALUES_FISICA_MORAL: IMultipleFormValues[] = [
  { name: "Física", value: "01" },
  { name: "Moral", value: "02" },
];

const VALUES_FISICA_JURIDICA: IMultipleFormValues[] = [
  { name: "Jurídica", value: "02" },
  { name: "Física", value: "01" },
];
const VALUES_NATURAL_JURIDICA: IMultipleFormValues[] = [
  { name: "Jurídica", value: "02" },
  { name: "Natural", value: "01" },
];

export const PERSON_TYPE_BY_COUNTRY_VALUES = {
  [CountryEnum.mexico]: VALUES_FISICA_MORAL,
  [CountryEnum.el_salvador]: VALUES_FISICA_MORAL,
  [CountryEnum.honduras]: VALUES_FISICA_MORAL,
  [CountryEnum.guatemala]: VALUES_FISICA_MORAL,
  [CountryEnum.peru]: VALUES_NATURAL_JURIDICA,
  [CountryEnum.colombia]: VALUES_NATURAL_JURIDICA,
  [CountryEnum.chile]: VALUES_NATURAL_JURIDICA,
  [CountryEnum.ecuador]: VALUES_NATURAL_JURIDICA,
  [CountryEnum.panama]: VALUES_NATURAL_JURIDICA,
  [CountryEnum.usa]: VALUES_NATURAL_JURIDICA,
  [CountryEnum.generic]: VALUES_NATURAL_JURIDICA,
  [CountryEnum.nicaragua]: VALUES_FISICA_JURIDICA,
  [CountryEnum.costa_rica]: VALUES_FISICA_JURIDICA,
  [CountryEnum.brasil]: VALUES_FISICA_JURIDICA,
};

export const isAvailableCountry = (country: string) =>
  Object.keys(PERSON_TYPE_BY_COUNTRY_VALUES).some(
    (catalogCountry) => catalogCountry === country
  );

export const getPersonByCountry = (
  country: CountryEnum
): IMultipleFormValues[] => {
  return get(
    PERSON_TYPE_BY_COUNTRY_VALUES,
    `[${country}]`,
    VALUES_NATURAL_JURIDICA
  );
};

export const validateCountryEmpty = (country: string): string => {
  return isEmpty(country)
    ? country
    : isAvailableCountry(country)
    ? country
    : CountryEnum.generic;
};
