import React from "react";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Search } from "@material-ui/icons";
import { InputAdornment } from "@material-ui/core";

export interface SearchFieldProps {
  label: string;
  value?: string;
  handleSearchField: (key: string) => void;
  handleChangeSearchField: (value: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiFormControl-root": {
        height: "auto",
      },
      "& > * + *": {
        marginTop: theme.spacing(0),
      },
      "& .MuiInputBase-root": {
        height: "auto",
        width: "auto",
        backgroundColor: "#FFFFFF",
      },
      "& .MuiInputBase-input": {
        width: 350,
        [theme.breakpoints.down(769)]: {
          maxWidth: 350,
        },
      },
      [theme.breakpoints.down(769)]: {
        minWidth: "100%",
        "& .MuiInputBase-input": {
          width: "100%",
        },
      },
    },
  })
);

const SearchField = (props: SearchFieldProps) => {
  const classes = useStyles();

  return (
    <TextField
      id="search-field"
      label={props.label}
      onKeyDown={(event: any) => {
        props.handleSearchField(event.key);
      }}
      type="search"
      onChange={(event: any) => {
        props.handleChangeSearchField(event.target.value);
      }}
      className={classes.root}
      variant="outlined"
      size={"small"}
      value={props.value}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchField;
