enum WalletTypeEnum {
  TOTAL = "total",
  PARTIAL = "partial",
}

const WALLET_TYPE_MAPPED: Record<WalletTypeEnum, string> = {
  [WalletTypeEnum.PARTIAL]: "Parcial",
  [WalletTypeEnum.TOTAL]: "Total",
};

export { WalletTypeEnum, WALLET_TYPE_MAPPED };
