import React, { ChangeEvent, useEffect } from "react";
import { ConfirmModal } from "../ConfirmModal";
import { TransactionTable } from "../../../../types/transaction_table";
import { OmitModalEnum } from "../../../shared/infrastructure/OmitModalEnum";
import { ModalBodyOmit } from "../../ModalBodyOmit/ModalBodyOmit";
import {
  createStyles,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { get } from "lodash";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) =>
  createStyles({
    warningText: {
      marginBottom: 24,
    },
    toogle: {
      color: theme.palette.primary.main,
      "&.Mui-selected": {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        "&:hover": {
          backgroundColor: "rgba(2,51,101,1) !important",
        },
      },
      "&:hover": {
        backgroundColor: "rgba(212,234,255,0.2) !important",
      },
      borderColor: "#023365",
      fontSize: "10px",
      lineHeight: "140%",
      fontWeight: 500,
      maxHeight: "30px",
      minWidth: "70px",
      fontStyle: "normal",
    },
  })
);

export interface OmitConfirmModalProps {
  open: boolean;
  handleCloseOmit: () => void;
  handleAcceptOmit: (omitReason: string) => void;
  isIndividual?: boolean;
  rowData?: TransactionTable | undefined;
  externalProps?: {
    externalType: string | null;
    isExternal: boolean;
    handleChangeExternalType: (
      _event: React.MouseEvent<HTMLElement>,
      newExternal: string | null
    ) => void;
    handleChangeExternal: (value: boolean) => void;
  };
}

export const OmitConfirmModal: React.FC<OmitConfirmModalProps> = (
  props: React.PropsWithChildren<OmitConfirmModalProps>
) => {
  const classes = styles(props);
  const [omitReason, setOmitReason] = React.useState("");
  const [openModalOmit, setOpenModalOmit] = React.useState(props.open);

  useEffect(() => {
    setOmitReason("");
    setOpenModalOmit(props.open);
  }, [props.open]);

  const handleCloseOmit = () => () => {
    props.handleCloseOmit();
  };

  const handleAcceptOmit = () => () => {
    props.handleAcceptOmit(omitReason);
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOmitReason(event.target.value);
  };
  return props.isIndividual ? (
    <ConfirmModal
      open={openModalOmit}
      titleText={`¿Deseas omitir la operación de ${props.rowData?.transaction?.socialReason}?`}
      contentText={OmitModalEnum.IndividualOmit}
      acceptText="Si, omitir"
      acceptColor="primary"
      maxWidth={"md"}
      adjustedSize={true}
      handleClose={handleCloseOmit()}
      handleAccept={handleAcceptOmit()}
    >
      <Grid container>
        <Grid item xs={12}>
          {get(props.rowData, "transaction.kind", "") === "charge" && (
            <React.Fragment>
              <Grid container spacing={3}>
                <Typography variant="h6" style={{ marginLeft: "10px" }}>
                  ¿Este cobro ya fue realizado por otro medio?
                </Typography>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      value={props.externalProps?.isExternal}
                      onChange={(e) =>
                        props.externalProps?.handleChangeExternal(
                          e.target.value === "true"
                        )
                      }
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio color="primary" />}
                        label="Si"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio color="primary" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {props.externalProps?.isExternal && (
                  <Grid item xs={12}>
                    <ToggleButtonGroup
                      value={props.externalProps.externalType}
                      onChange={props.externalProps.handleChangeExternalType}
                      size="small"
                      exclusive
                      className={classes.warningText}
                    >
                      <ToggleButton
                        value="check"
                        aria-label="left aligned"
                        color="primary"
                        className={classes.toogle}
                      >
                        Cheque
                      </ToggleButton>
                      <ToggleButton
                        value="cash"
                        aria-label="left aligned"
                        color="primary"
                        className={classes.toogle}
                      >
                        Efectivo
                      </ToggleButton>
                      <ToggleButton
                        value="other"
                        aria-label="left aligned"
                        color="primary"
                        className={classes.toogle}
                      >
                        Otro
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                )}
              </Grid>
            </React.Fragment>
          )}
        </Grid>
        <Grid item xs={12}>
          <ModalBodyOmit value={omitReason} handleChange={handleChange} />
        </Grid>
      </Grid>
    </ConfirmModal>
  ) : (
    <ConfirmModal
      open={openModalOmit}
      titleText={"¿Deseas omitir todas las operaciones?"}
      contentText={OmitModalEnum.MassiveOmit}
      acceptText="Si, omitir"
      acceptColor="primary"
      maxWidth={"md"}
      adjustedSize={true}
      handleClose={handleCloseOmit()}
      handleAccept={handleAcceptOmit()}
    >
      <Grid container>
        <Grid item xs={12}>
          <ModalBodyOmit value={omitReason} handleChange={handleChange} />
        </Grid>
      </Grid>
    </ConfirmModal>
  );
};
