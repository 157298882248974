export type Catalog = { code: string; text: string };

export enum ProcessorsEnum {
  CREDIMATIC = "Credimatic Processor",
  DATAFAST = "Datafast Processor",
  PROSA = "Prosa Processor",
  BILLPOCKET = "Billpocket Processor",
  KUSHKI_ACQ = "KushkiAcq Processor",
  FIS_PROCESSOR = "Fis Processor",
}

export enum ProcessorsLabelEnum {
  FIS_PROCESSOR = "WorldPay (by FIS) Processor",
}

export const DEFERRED_TYPE: {
  [k: string]: Catalog[];
} = {
  [ProcessorsEnum.DATAFAST]: [
    { code: "001", text: "Diferido corriente" },
    { code: "002", text: "Diferido con intereses" },
    { code: "003", text: "Diferido sin intereses" },
    { code: "004", text: "Tipo de Comercio Gasolinera" },
    { code: "007", text: "Diferido especial con interés" },
    { code: "008", text: "Tarjeta descuento" },
    { code: "009", text: "Diferido especial sin interés" },
    { code: "021", text: "Diferido Plus Cuotas" },
    { code: "022", text: "Diferido Plus" },
  ],
  [ProcessorsEnum.CREDIMATIC]: [
    { code: "01", text: "Diferido Cuota Fija con intereses" },
    {
      code: "02",
      text: "Diferido con meses de gracia con intereses",
    },
    { code: "03", text: "Diferido Pago Mes a Mes con intereses" },
    { code: "04", text: "Diferido Cuota Fija sin intereses" },
    {
      code: "05",
      text: "Diferido con meses de gracia sin intereses",
    },
    { code: "06", text: "Diferido Pago Mes a Mes sin intereses" },
    { code: "07", text: "Diferido especial sin intereses" },
    { code: "50", text: "Diferido promociones Supermaxi" },
  ],
  [ProcessorsEnum.PROSA]: [
    { code: "00", text: "Sin promoción" },
    {
      code: "03",
      text: "Sin intereses al tarjetahabiente",
    },
    {
      code: "05",
      text: "Con intereses para el tarjetahabiente",
    },
    { code: "07", text: "Compre hoy y Pague después (Skip Payment Puro)" },
  ],
  [ProcessorsEnum.BILLPOCKET]: [
    { code: "all", text: "Diferido meses sin intereses" },
  ],
  [ProcessorsEnum.KUSHKI_ACQ]: [
    { code: "00", text: "Sin promoción" },
    {
      code: "03",
      text: "Sin intereses al tarjeta habiente",
    },
    {
      code: "05",
      text: "Con intereses para el tarjeta habiente",
    },
    { code: "07", text: "Compre hoy y Pague después (Skip Payment Puro)" },
  ],
};

export enum CodeDeferredTypeEnum {
  "001" = "001",
  "002" = "002",
  "003" = "003",
  "004" = "004",
  "007" = "007",
  "008" = "008",
  "009" = "009",
  "021" = "021",
  "022" = "022",
  "01" = "01",
  "02" = "02",
  "03" = "03",
  "04" = "04",
  "05" = "05",
  "06" = "06",
  "07" = "07",
  "_50" = "50",
}

export const PROCESSOR_DATAFAST: string[] = [
  CodeDeferredTypeEnum["001"],
  CodeDeferredTypeEnum["002"],
  CodeDeferredTypeEnum["003"],
  CodeDeferredTypeEnum["004"],
  CodeDeferredTypeEnum["007"],
  CodeDeferredTypeEnum["008"],
  CodeDeferredTypeEnum["009"],
  CodeDeferredTypeEnum["021"],
  CodeDeferredTypeEnum["022"],
];

export const PROCESSOR_CREDIMATIC: string[] = [
  CodeDeferredTypeEnum["01"],
  CodeDeferredTypeEnum["02"],
  CodeDeferredTypeEnum["03"],
  CodeDeferredTypeEnum["04"],
  CodeDeferredTypeEnum["05"],
  CodeDeferredTypeEnum["06"],
  CodeDeferredTypeEnum["07"],
  CodeDeferredTypeEnum._50,
];
