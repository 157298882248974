import { KindEnum } from "../KindEnum";
import { CycleEnum } from "../CycleEnum";
import { InitialStatusEnum } from "../StatusEnum";
import { InvoiceModeEnum } from "../InvoiceModeEnum";
import { TransactionTypeEnum } from "../TransactionTypeEnum";
import { KindModelEnum } from "../KindModelEnum";
import { BillingTransactionTypeEnum } from "../BillingTransactionTypeEnum";

export const HistoricTransactionTypeValues = [
  TransactionTypeEnum.PAYIN_COMISSION,
  TransactionTypeEnum.PAYOUT_COMISSION,
  BillingTransactionTypeEnum.MANUAL_FROM_FILE,
  BillingTransactionTypeEnum.FROM_CONSOLE,
  TransactionTypeEnum.MINIMAL_COMISSION,
];

export const HistoricKindValues = [
  KindEnum.INVOICE,
  KindEnum.VOUCHER,
  KindEnum.CHARGE,
  KindEnum.DISPERSION,
  KindEnum.RETENTION,
  KindEnum.RETENTION_EC,
  KindEnum.CREDIT_NOTE,
  KindEnum.DEBIT_NOTE,
  KindEnum.RECEIVABLE,
  KindEnum.ANNUL_INVOICE,
];

export const HistoricValuesModelCo = [KindModelEnum.AGP, KindModelEnum.AGF];

export const HistoricInvoiceModeValues = [
  InvoiceModeEnum.WITHOUT_IVA,
  InvoiceModeEnum.WITH_IVA,
];

export const HistoricStatusValues = [
  "completed",
  "finished",
  "emitted",
  "pending",
  "manual",
  "processed",
  "omitted",
  "expired",
  "rejected",
  "annulled",
  "approved",
  "deleted",
  "pre_processed",
  "pending_verify",
  "completed_charge",
  "completed_dispersion",
  "completed_invoice",
  "failed",
  "partially_completed",
];

export const HistoricCycleValues = [
  CycleEnum.DAILY,
  CycleEnum.THREEWEEKLY,
  CycleEnum.TWOWEEKLY,
  CycleEnum.BIWEEKLY,
  CycleEnum.MONTHLY,
  CycleEnum.WEEKLY,
  CycleEnum.QUARTERLY,
  CycleEnum.ANNUAL,
  CycleEnum.THREEWEEKLY,
  CycleEnum.TWOWEEKLY,
];

export const HistoricInitialStatusValues = [
  InitialStatusEnum.APPROVE,
  InitialStatusEnum.REJECT,
  InitialStatusEnum.OMITTED_HIS,
];
