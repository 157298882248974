import { KindEnum } from "./KindEnum";
import { StatusEnum } from "./StatusEnum";

export enum StepTitleEnum {
  canceledRetention = "Retención anulada",
  autorizedRetention = "Retención Autorizada",
  processRetention = "Retención Procesada",
  modifiedAmount = "Monto modificado",
  rejectRetention = "Retención Rechazada",
  returnedRetention = "Retención Regresada",
  canceledTransaction = "Transacción anulada",
  autorizedTransaction = "Transacción Autorizada",
  partiallyCompleted = "Transacción Aprobada Parcialmente",
  processTransaction = "Transacción Procesada",
  rejectTransaction = "Cuenta por Cobrar Rechazada",
  finishedTransaction = "Transacción liquidada",
  emittedTransaction = "Estado final: Procesado",
  rejectTransactionTime = "Estado final: Rechazado",
  inReviewTransaction = "En proceso",
  manualTransaction = "Estado Final: Transacción manual",
  processFaild = "Estado Final: Proceso fallido",
  omittedTransaction = "Cuenta por Cobrar Omitida",
  omittedTransactionTime = "Estado final: Omitido",
  omittedRetention = "Retención Omitida",
  approveOperation = "Operación aprobada",
  initialAmountProcessed = "Monto inicial procesado",
  processVoucher = "Boleta procesada",
  approveVoucher = "Boleta aprobada",
  rejectedVoucher = "Boleta rechazada",
  omittedVoucher = "Boleta omitida",
  completedCNote = "N. de Crédito emitida",
  approveCNote = "N. de Crédito aprobada",
  processCNote = "N. de Crédito procesada",
  rejetedCNote = "N. de Crédito rechazada",
  manualCNote = "N. de Crédito manual",
  pendingCNote = "Nueva N. de Crédito emitida",
  omittedCNote = "N. de Débito omitida",
  completedDNote = "N. de Débito emitida",
  approveDNote = "N. de Débito aprobada",
  processDNote = "N. de Débito procesada",
  pendingDNote = "Nueva N. de Débito emitida",
  omittedDNote = "N. de Débito omitida",
  rejetedDNote = "N. de Débito rechazada",
  manualDNote = "N. de Débito manual",
  reprocessPending = "Reproceso fallido",
  reprocessApprove = "Transacción enviada a reprocesar",
  reprocessDone = "Transacción reprocesada",
  reprocessReject = "Reproceso rechazado",
  reprocessOmited = "Reproceso omitido",
  repocessManual = "Transacción reprocesada manual",
  reprocessInitialized = "Transacción reprocesada aprobada",
  reprocessFailed = "Reproceso fallido",
  reprocessReprocess = "Transacción solicitada para reprocesar",
}

const StandardTitle = {
  [StatusEnum.PENDING]: StepTitleEnum.initialAmountProcessed,
  [StatusEnum.PRE_PROCESSED]: StepTitleEnum.processTransaction,
  [StatusEnum.INITIALIZED]: StepTitleEnum.approveOperation,
  [StatusEnum.REJECTED]: StepTitleEnum.rejectTransactionTime,
  [StatusEnum.COMPLETED]: StepTitleEnum.emittedTransaction,
  [StatusEnum.DELETED]: StepTitleEnum.canceledTransaction,
  [StatusEnum.OMITTED]: StepTitleEnum.omittedTransactionTime,
  [StatusEnum.FINISHED]: StepTitleEnum.finishedTransaction,
  [StatusEnum.MANUAL]: StepTitleEnum.manualTransaction,
  [StatusEnum.EMITTED]: StepTitleEnum.emittedTransaction,
  [StatusEnum.IN_REVIEW]: StepTitleEnum.inReviewTransaction,
  [StatusEnum.PARTIALLY_COMPLETED]: StepTitleEnum.partiallyCompleted,
};

export const TitleStatusModal = {
  [KindEnum.INVOICE]: StandardTitle,
  [KindEnum.DISPERSION]: StandardTitle,
  [KindEnum.CHARGE]: StandardTitle,
  [KindEnum.RETENTION]: StandardTitle,
  [KindEnum.RETENTION_EC]: StandardTitle,
  [KindEnum.TO_CHARGE]: StandardTitle,
  [KindEnum.RECEIVABLE]: StandardTitle,
  [KindEnum.VOUCHER]: {
    [StatusEnum.PENDING]: StepTitleEnum.processVoucher,
    [StatusEnum.PRE_PROCESSED]: StepTitleEnum.approveVoucher,
    [StatusEnum.COMPLETED]: StepTitleEnum.emittedTransaction,
    [StatusEnum.REJECTED]: StepTitleEnum.rejectedVoucher,
    [StatusEnum.OMITTED]: StepTitleEnum.omittedVoucher,
  },
  [KindEnum.CREDIT_NOTE]: {
    [StatusEnum.PENDING]: StepTitleEnum.pendingCNote,
    [StatusEnum.PRE_PROCESSED]: StepTitleEnum.processCNote,
    [StatusEnum.INITIALIZED]: StepTitleEnum.approveCNote,
    [StatusEnum.COMPLETED]: StepTitleEnum.completedCNote,
    [StatusEnum.IN_REVIEW]: StepTitleEnum.approveCNote,
    [StatusEnum.OMITTED]: StepTitleEnum.omittedCNote,
    [StatusEnum.REJECTED]: StepTitleEnum.rejetedCNote,
    [StatusEnum.MANUAL]: StepTitleEnum.manualCNote,
  },
  [KindEnum.DEBIT_NOTE]: {
    [StatusEnum.PENDING]: StepTitleEnum.pendingDNote,
    [StatusEnum.PRE_PROCESSED]: StepTitleEnum.processDNote,
    [StatusEnum.INITIALIZED]: StepTitleEnum.approveDNote,
    [StatusEnum.COMPLETED]: StepTitleEnum.completedDNote,
    [StatusEnum.OMITTED]: StepTitleEnum.omittedDNote,
    [StatusEnum.REJECTED]: StepTitleEnum.rejetedDNote,
    [StatusEnum.MANUAL]: StepTitleEnum.manualDNote,
  },
};

const statusTextKind = {
  [StatusEnum.PENDING]: StepTitleEnum.reprocessPending,
  [StatusEnum.IN_REVIEW]: StepTitleEnum.inReviewTransaction,
  [StatusEnum.PRE_PROCESSED]: StepTitleEnum.reprocessApprove,
  [StatusEnum.COMPLETED]: StepTitleEnum.reprocessDone,
  [StatusEnum.REJECTED]: StepTitleEnum.reprocessReject,
  [StatusEnum.OMITTED]: StepTitleEnum.reprocessOmited,
  [StatusEnum.MANUAL]: StepTitleEnum.repocessManual,
  [StatusEnum.INITIALIZED]: StepTitleEnum.reprocessInitialized,
  [StatusEnum.FAILED]: StepTitleEnum.reprocessFailed,
  [StatusEnum.REPROCESS]: StepTitleEnum.reprocessReprocess,
};
export const TitleStatusModalReprocess = {
  [KindEnum.INVOICE]: statusTextKind,
  [KindEnum.CHARGE]: statusTextKind,
  [KindEnum.DISPERSION]: statusTextKind,
};
