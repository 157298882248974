import { useMediaQuery, useTheme } from "@mui/material";
import { IUseMobileState } from "./useMobileState.interfaces";

export const useMobileState = (): IUseMobileState => {
  const theme = useTheme();

  return {
    isMobile: useMediaQuery(theme.breakpoints.down("sm")),
  };
};
