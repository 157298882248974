import React from "react";
import { Title } from "@kushki/connect-ui";
import { TitleSection } from "../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { get } from "lodash";
import { ITitleProps } from "@kushki/connect-ui/dist/Components/Organism/Main/Title/Title.interfaces";

export const CustomBreadcrumb = (props: ITitleProps) => {
  const text_button: string = get(props, "textButton", TitleSection.ADD);
  const title: string = get(props, "title", TitleSection.RULES_CREATE);

  return <Title {...props} textButton={text_button} title={title} />;
};
