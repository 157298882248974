export const tipoEmpresaPE: Array<string> = [
  "Sociedad Anónima (S.A.)",
  "Sociedad Anónima cerrada (S.A.C.)",
  "Sociedad Comercial de Responsabilidad Limitada (S.R.L.)",
  "Empresario Individual de Responsabilidad Limitada (E.I.R.L.)",
  "Sociedad Anónima Abierta (S.A.A.)",
  "Sociedad Colectiva",
  "Sociedad Comandita Simple",
  "Sociedades Comandita por acción",
  "Sociedad Civil Ordinaria",
  "Sociedad Civil de Responsabilidad Limitada",
];
