import { createStyles, makeStyles } from "@mui/styles";

export const filterTableComponentStyles = makeStyles(() =>
  createStyles({
    "@global": {
      ".css-lpnzq9": {
        "margin-bottom": "70px",
      },
      ".MuiDrawer-paper": {
        top: "70px !important",
      },
    },
  })
);
