import { createNamedStyles } from "../../shared/utils/create_named_styles";

export const refundsActionBarStyles = createNamedStyles({
  baseBtn: {
    border: 1,
    borderColor: "neutral.grey5",
    borderRadius: "4px",
    borderStyle: "solid",
    height: "36px",
  },
  btnTime: {
    color: "neutral.grey7",
    px: 1,
    "&:hover": {
      border: 1,
      borderColor: "primary.main",
      borderStyle: "solid",
      color: "text.primary",
    },
    "&:focus": {
      border: 1,
      borderColor: "primary.main",
      borderStyle: "solid",
      color: "text.primary",
    },
  },
  container: {
    bgcolor: "neutral.grey2",
    borderRadius: "4px 4px 0px 0px",
    height: "58px",
    p: (theme) => `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  },
  iconSearch: {
    ml: 1,
    p: "4px",
  },
  input: {
    width: "380px",
    borderRadius: 1,
    ml: 2,
    "&.Mui-disabled": {
      opacity: "33%",
    },
    "&:hover": {
      border: 1,
      borderColor: "primary.main",
      borderStyle: "solid",
      color: "text.primary",
    },
    "&:focus-within": {
      border: 1,
      borderColor: "primary.main",
      borderStyle: "solid",
      color: "text.primary",
    },
  },
  btnFilter: {
    color: "text.dark",
    px: 2.5,
    "&:hover": {
      border: 1,
      borderColor: "primary.main",
      borderStyle: "solid",
      color: "text.primary",
    },
    "&:focus": {
      border: 1,
      borderColor: "primary.main",
      borderStyle: "solid",
      color: "text.primary",
    },
    mr: 1,
  },
  componentContainer: {
    justifyContent: "space-between",
  },
  gridTime: {
    minWidth: 400,
  },
});
