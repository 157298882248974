export enum FilterEnum {
  APPROVAL = "APPROVAL",
  DECLINED = "DECLINED",
  DEBIT = "DEBIT",
  CREDIT = "CREDIT",
  CASH_OUT = "usrv-payouts-cash",
  LIQUIDACION = "usrv-dispersions",
  TRANSFER_OUT = "usrv-payouts-transfer",
  MANUAL = "manual",
  AUTOMATIC = "automatic",
  ASC = "asc",
  DESC = "desc",
}

export enum FilterSortEnum {
  CREATED = "created",
  ASC = "asc",
  DESC = "desc",
}

export enum FilterValuesEnum {
  PM_MANUAL = "manual",
  PM_AUTOMATIC = "automatic",
  TT_CREDIT = "CREDIT",
  TT_DEBIT = "DEBIT",
  USRV_DISPERSION = "usrv-dispersions",
  USRV_PAYOUTS_CASH = "usrv-payouts-cash",
  USRV_PAYOUTS_TRANSFER = "usrv-payouts-transfer",
}
