import { useAppSelector } from "../../../store/hooks/storeHook";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IChargeForm } from "../../../store/interfaces/charge.interfaces";
import { MerchantResponse } from "../../../../types/merchant_response";
import { getDispersionRatesConfig } from "../../../store/thunks/app/app.thunks";
import { get, isEmpty, isNil, omit } from "lodash";
import {
  addBankAccount,
  editBankAccount,
} from "../../../store/actions/charge/charge.actions";
import {
  getConstitutionalCountry,
  getRatesPayload,
  handleDispatchBasicDataResponse,
  requestMerchantNodeInfo,
} from "../../../shared/utils/dispersionUtils";
import { NodeInfoResponse } from "../../../../types/node_info_response";
import {
  getAccountInfoToSave,
  getBankAccounts,
  getConfigsCharge,
  isBasicDataResponse,
  isChargeDataResponse,
} from "../../../shared/utils/chargeUtils";
import {
  disableContinueButton,
  disableSaveButton,
  updateConstitutionalCountry,
} from "../../../store/actions/dispersion/dispersion.actions";
import { validateIfHasMainAccount } from "../../../shared/utils/bankAccountUtils";
import {
  getBranchesToUpdate,
  handleCloseModalAndSave,
} from "../../../shared/utils/massiveBranchUtils";
import {
  Branch,
  Request,
} from "../../../../types/update_massive_branch_request";
import { updateMassiveBranch } from "../../../store/thunks/massiveBranch/massiveBranchs.thunks";
import { useDispatch } from "react-redux";
import { ConfigIdCharge } from "../../../shared/enums/dispersionsEnum";
import { IDispersionForm } from "../../../store/interfaces/dispersion.interfaces";
import { CountriesEnum } from "../../../shared/enums/countriesEnum";
import {
  HANDLE_MODAL_LOADER_WS,
  SET_FOOTER_BUTTON_CLICKED,
  SET_TYPE_REQUEST,
} from "../../../store/reducers/app/app.slice";
import { ConfigIdEnum } from "../../../shared/constants/node_config_enums";
import { TypeRequestEnum } from "../../../shared/enums/typeRequestEnum";
import {
  createNodePaths,
  projectPaths,
} from "../../../shared/enums/projects_path_enum";
import { editStatusEnum } from "../../../shared/constants/massiveEdit/massiveEditEnums";
import { ChargeFields } from "../../../shared/enums/chargeEnum";

export const useMassiveChargeContainer = () => {
  const dispatch = useDispatch();

  const {
    constitutionalCountry,
    frequencyAndFraudForm,
    merchantNodeInfo,
    banksAccounts,
    footerButtonsClicked,
    loading: { loadingForm, loadingRatesConfig },
    updateMassiveBranchStatus,
    typeRequest: footerButtonType,
    configsResponse,
    massiveMostCurrentBranches: { charge: nodeInfo },
  } = useAppSelector((state) => ({
    ...state.app,
    ...state.charge,
    ...state.massiveBranch,
  }));

  const [isBankDataUpdated, setBankDataUpdated] = useState<boolean>(false);
  const {
    control,
    resetField,
    reset,
    formState: { errors, isValid },
    getValues,
  } = useForm<IChargeForm>({
    defaultValues: { ...frequencyAndFraudForm },
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const [isBeneficiaryNameUpdated, setIsBeneficiaryNameUpdated] =
    useState(false);

  const handleBasicDataResponse = async (response: MerchantResponse) => {
    await handleDispatchBasicDataResponse(response, dispatch);
    setIsBeneficiaryNameUpdated(true);
  };

  const requestChargeData = (configId: string, merchantId: string) =>
    requestMerchantNodeInfo(configId, merchantId, dispatch);

  const requestBasicData = (configId: string, merchantId: string) =>
    requestMerchantNodeInfo(configId, merchantId, dispatch);

  const requestChargeRatesConfig = (value: string) =>
    dispatch(getDispersionRatesConfig({ merchantId: value }));

  const requestDataFromNodeInfo = (nodeInfo: NodeInfoResponse) => {
    const { configCn001, configCn017 } = getConfigsCharge(nodeInfo);

    if (configCn001)
      requestBasicData(configCn001.configuration, configCn001.value);

    if (!configCn017) {
      setBankDataUpdated(true);

      return;
    }

    requestChargeData(configCn017.configuration, nodeInfo.merchantId);
    requestChargeRatesConfig(nodeInfo.merchantId);
  };

  const handleChargeDataResponse = async (response: MerchantResponse) => {
    const accountInfo = get(response, "charge.accountInfo");

    if (!isEmpty(accountInfo))
      dispatch(
        addBankAccount({
          ...accountInfo,
          accountOrder: "principal",
          country: "Ecuador",
        })
      );

    setBankDataUpdated(true);
    await dispatch(editBankAccount(getBankAccounts(response)));
  };

  const saveMassiveCharge = () => {
    const branches: Branch[] = getBranchesToUpdate();
    const ratesPayload = omit(
      getRatesPayload({
        bankAccounts: banksAccounts,
        clientType: get(nodeInfo, "generalInfo.clientType", ""),
        constitutionalCountry,
        country: CountriesEnum.ECUADOR,
        dispersionForm: frequencyAndFraudForm as IDispersionForm,
        publicMerchantId: nodeInfo!.merchantId,
      }),
      ["merchantId"]
    );
    const accountInfo = getAccountInfoToSave(banksAccounts[0]);

    const requestUpdateMassiveCharge: Request = {
      branches: branches,
      configId: ConfigIdCharge.CN017,
      data: {
        chargePayload: {
          charge: {
            ...(!isEmpty(accountInfo) && { accountInfo }),
          },
          chargeFrequency: getValues(ChargeFields.FREQUENCY),
          configId: ConfigIdCharge.CN017,
        },
        constitutionalCountry: get(
          nodeInfo,
          "generalInfo.constitutionalCountry"
        ),
        country: get(nodeInfo, "generalInfo.country"),
        ratesPayload: omit(ratesPayload, ["constitutionalCountry", "country"]),
      },
      status:
        isValid && isEmpty(accountInfo)
          ? editStatusEnum.PENDING
          : editStatusEnum.COMPLETE,
      userAlias: localStorage.getItem("username") ?? "backoffice",
      userName: localStorage.getItem("username") ?? "backoffice",
    };

    dispatch(
      updateMassiveBranch(
        requestUpdateMassiveCharge,
        ConfigIdEnum.CN017,
        configsResponse!
      )
    );
    dispatch(HANDLE_MODAL_LOADER_WS(true));
  };

  useEffect(() => {
    const isAllDataLoaded =
      !(loadingForm && loadingRatesConfig) && isBankDataUpdated;

    dispatch(
      disableContinueButton(
        !(isAllDataLoaded && isValid && validateIfHasMainAccount(banksAccounts))
      )
    );
    dispatch(disableSaveButton(!(isAllDataLoaded && isValid)));
  }, [
    isValid,
    banksAccounts,
    loadingForm,
    loadingRatesConfig,
    isBankDataUpdated,
  ]);

  useEffect(() => {
    if (!merchantNodeInfo) return;

    if (isBasicDataResponse(merchantNodeInfo)) {
      handleBasicDataResponse(merchantNodeInfo).then();
    }

    if (isChargeDataResponse(merchantNodeInfo))
      handleChargeDataResponse(merchantNodeInfo).then();
  }, [merchantNodeInfo]);

  useEffect(() => {
    const isChargeDataLoaded = !(loadingForm && loadingRatesConfig);
    const values: object = {
      ...frequencyAndFraudForm,
      frequency: frequencyAndFraudForm.frequency,
      keepFraud: frequencyAndFraudForm.keepFraud.toString(),
    };

    if (isChargeDataLoaded) {
      reset(values);
    }
  }, [loadingForm, loadingRatesConfig]);

  useEffect(() => {
    dispatch(disableContinueButton(!isValid || isEmpty(banksAccounts)));
    dispatch(disableSaveButton(!isValid));
  }, [banksAccounts]);

  useEffect(() => {
    dispatch(disableSaveButton(!isValid));
  }, [isValid]);

  useEffect(() => {
    if (isEmpty(nodeInfo)) return;

    dispatch(updateConstitutionalCountry(getConstitutionalCountry(nodeInfo!)));
    requestDataFromNodeInfo(nodeInfo!);
  }, [nodeInfo]);

  useEffect(() => {
    if (footerButtonsClicked && nodeInfo && nodeInfo.merchantId) {
      if (footerButtonType === TypeRequestEnum.SAVE) {
        saveMassiveCharge();
        dispatch(HANDLE_MODAL_LOADER_WS(true));
      }
      if (footerButtonType === TypeRequestEnum.FINISHED) {
        saveMassiveCharge();
      }
    }
  }, [footerButtonsClicked]);

  useEffect(() => {
    handleCloseModalAndSave(updateMassiveBranchStatus, dispatch);
    if (!isNil(updateMassiveBranchStatus) && !updateMassiveBranchStatus) {
      dispatch(SET_FOOTER_BUTTON_CLICKED(false));
      if (footerButtonType === TypeRequestEnum.FINISHED) {
        window.location.href = projectPaths.CREATE_NODE.concat(
          createNodePaths.BRANCH_EDIT
        );
      }
      dispatch(SET_TYPE_REQUEST(""));
    }
    dispatch(SET_FOOTER_BUTTON_CLICKED(false));
  }, [updateMassiveBranchStatus]);

  return {
    form: {
      control,
      errors,
      reset,
      resetField,
    },
    loading: { loadingForm, loadingRatesConfig },
    showBankData: isBankDataUpdated && isBeneficiaryNameUpdated,
  };
};
