import React from "react";
import { Container, Grid } from "@mui/material";
import HeaderPage from "../../components/HeaderPage/HeaderPage";
import { useKshRedux } from "../../store/useKshRedux/useKshRedux";
import { TAB_SECTIONS } from "../../shared/infrastructure/constants/tab_sections";
import { GenericLoader } from "../../components/common/GenericLoader/GenericLoader";
import { SnackbarAlert } from "../../components/common/SnackbarAlert/SnackbarAlert";

export const B2CRefunds: React.FC = () => {
  const {
    store: {
      app: { loading, valueTab, showAlert, messageAlert, typeAlert },
    },
    actions: { handleCloseAlert },
  } = useKshRedux();
  const Content: React.FC = TAB_SECTIONS[valueTab];

  return (
    <>
      <Container fixed>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <HeaderPage />
          </Grid>
          <Grid item xs={12}>
            <Content />
          </Grid>
        </Grid>
      </Container>
      <GenericLoader showLoading={loading} />
      <SnackbarAlert
        open={showAlert}
        message={messageAlert}
        type={typeAlert}
        onClose={handleCloseAlert}
      />
    </>
  );
};
