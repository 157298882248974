import { useHistory } from "react-router";
import { ICredentialHeaderProps } from "../CredentialHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  getParentMerchantId,
  setIsOpenCredentialModal,
} from "../../../store/actionCreators";
import {
  FlagChile,
  FlagColombia,
  FlagEcuador,
  FlagMexico,
  FlagPeru,
} from "@kushki/connect-ui";
import React, { useEffect, useState } from "react";
import { Country } from "../../../shared/infrastructure/constants/CredentialManagerConstants";
import { LabelEnum } from "../../../shared/infrastructure/enums/LabelEnum";
import { IAppState } from "../../../shared/infrastructure/interfaces/IAppState";
import { defaultTo, isEmpty, has, omit, get } from "lodash";
import { IMerchantCategory } from "../../../shared/infrastructure/interfaces/IMerchantCategory";
import { IItemSelected } from "../../../shared/infrastructure/interfaces/IItemSelected";
import { GetCredentialsRequest } from "../../../../types/get_credentials_request";

export interface IUseCredentialHeader {
  actions: {
    handleReturnAction: () => void;
    handleOpenModalAddCredential: () => void;
    handleCloseModalAddCredential: () => void;
    getCountryFlag: () => JSX.Element | undefined;
  };
  parentMerchantID: string | undefined;
  reloadCredentials: () => void;
  merchantCredentials: IMerchantCategory[];
  filterCredential: (itemSelected: IItemSelected) => void;
  onChangeFilterSidebar: (request: Partial<GetCredentialsRequest>) => void;
  path: string;
}

export const useCredentialHeader = (
  props: ICredentialHeaderProps
): IUseCredentialHeader => {
  const dispatch = useDispatch();
  const history = useHistory();
  const country: string = props.country;

  const merchantCredentials: IMerchantCategory[] = useSelector(
    (state: IAppState) => defaultTo(state.credentialsForBox, [])
  );

  const parentMerchantID: string | undefined = useSelector(
    (state: IAppState) => state.parentMerchantId
  );

  const stateGlobal = useSelector((state: IAppState) => state);
  const [filtersSearchRequest, setFiltersSearchRequest] = useState<
    Partial<GetCredentialsRequest>
  >({});

  const handleReturnAction = (): void => {
    history.go(-1);
  };

  const onChangeFilterSidebar = (request: Partial<GetCredentialsRequest>) => {
    const filter_request = {
      ...filtersSearchRequest.filter,
      ...request.filter,
    };
    setFiltersSearchRequest({
      ...filtersSearchRequest,
      ...request,
      filter: omit(filter_request, !has(request, "filter") ? ["enable"] : []),
      merchantId: request.merchantId,
    });
  };

  useEffect(() => {
    if (!isEmpty(props.merchantId) && props.isNodeLayout) {
      dispatch(getParentMerchantId(props.merchantId));
    }
  }, []);

  const reloadCredentials = (): void => {
    setFiltersSearchRequest({
      ...filtersSearchRequest,
      filter: {},
      limit: 5,
      offset: 0,
    });
  };

  const handleOpenModalAddCredential = (): void => {
    props.changeCredentialModalProps({
      isEdit: false,
      leftButtonText: LabelEnum.CANCEL,
      rightButtonText: LabelEnum.ADD,
      titleText: LabelEnum.DESCRIPTION_MODAL_ADD_CREDENTIAL,
    });
    dispatch(setIsOpenCredentialModal(true));
  };

  const handleCloseModalAddCredential = (): void => {
    dispatch(setIsOpenCredentialModal(false));
  };

  const getCountryFlag = (): JSX.Element | undefined => {
    switch (country) {
      case Country.MEXICO:
        return <FlagMexico fontSize="medium" />;
      case Country.ECUADOR:
        return <FlagEcuador fontSize="medium" />;
      case Country.CHILE:
        return <FlagChile fontSize="medium" />;
      case Country.COLOMBIA:
        return <FlagColombia fontSize="medium" />;
      case Country.PERU:
        return <FlagPeru fontSize="medium" />;
      default:
        return undefined;
    }
  };

  const filterCredential = (itemSelected: IItemSelected) => {
    if (itemSelected) {
      setFiltersSearchRequest({
        ...filtersSearchRequest,
        filter: {
          [itemSelected.categoryId]: itemSelected.id,
        },
      });
    }
  };

  useEffect(() => {
    if (!isEmpty(filtersSearchRequest))
      props.onChangeFilters(filtersSearchRequest);
  }, [filtersSearchRequest]);

  return {
    actions: {
      handleReturnAction,
      handleOpenModalAddCredential,
      handleCloseModalAddCredential,
      getCountryFlag,
    },
    parentMerchantID,
    merchantCredentials,
    filterCredential,
    reloadCredentials,
    onChangeFilterSidebar,
    path: get(stateGlobal, "nodeInfo.path", ""),
  };
};
