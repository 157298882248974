import { TransactionTable } from "../../../types/transaction_table";

export interface IButtonDownloadFile {
  refundData: TransactionTable;
}

export interface Headers {
  key: string;
  label: string;
  default?: string;
}

export const CSV_HEADERS: Headers[] = [
  { key: "updateAt", label: "F. de solicitud del reembolso" },
  { key: "createAt", label: "F. de la transacción original" },
  { key: "merchantName", label: "Nombre del Comercio" },
  { key: "merchantId", label: "ID del comercio" },
  { key: "transactionId", label: "ID Transacción original" },
  { key: "transactionType", label: "Tipo de la transacción original" },
  { key: "transactionStatus", label: "Estado de la transacción original" },
  { key: "responseCode", label: "Código de respuesta" },
  { key: "responseText", label: "Mensaje de respuesta" },
  { key: "processorName", label: "Procesador" },
  { key: "recap", label: "Recap" },
  { key: "approvalCode", label: "Código de aprobación" },
  { key: "ticketNumber", label: "Número de ticket" },
  { key: "country", label: "País" },
  { key: "paymentMethod", label: "Medios de Pago" },
  { key: "cardHolderName", label: "Cliente" },
  { key: "issuingBank", label: "Banco emisor" },
  { key: "cardBrand", label: "Marca" },
  { key: "maskedCreditCard", label: "Número de tarjeta" },
  { key: "action", label: "Acción Ejecutor" },
  { key: "stateRefund", label: "Estado del Análisis" },
  {
    key: "transactionRefundId",
    label: "ID reembolso declinado",
  },
  { key: "currencyCode", label: "Moneda" },
  {
    default: "0",
    key: "approvedTransactionAmount",
    label: "Monto de la transacción original",
  },
  { default: "0", key: "requestAmount", label: "Monto" },
  { default: "0", key: "subtotalIva", label: "subtotal_iva" },
  { default: "0", key: "subtotalIva0", label: "subtotal_iva0" },
  { default: "0", key: "iceValue", label: "iceValue" },
  { default: "0", key: "ivaValue", label: "ivaValue" },
  { key: "taxes", label: "taxes" },
  { key: "subscriptionId", label: "subscription_id" },
];
