import {
  defaultTo,
  get,
  has,
  isEmpty,
  isEqual,
  isNil,
  set,
  without,
} from "lodash";
import { MerchantCompanyRequest } from "../../../types/merchant_company_request";
import { CountryEnum } from "../countryEnum";
import { FieldsEnum } from "../enums/FieldsEnum";
import {
  MerchantBillingData,
  SatMerchantCompany,
} from "../../../types/merchant_billing_data";
import { TAX_REGIME } from "../enums/TaxRegimeEnum";
import { RegisterOptions, UseFormReturn } from "react-hook-form";
import { MerchantResponse } from "../../../types/get_merchant_response";
import { ConfigIdEnum } from "../constants/initial_state_legal_details";
import { CatalogsEnum } from "../interfaces/catalogs-enum";
import { CatalogCo } from "../services/catalog-co";
import { ErrorBillingData } from "../enum/BasicStateMX/ErrorMessageMX";
import {
  CatalogEC,
  YES_NO_VALUES,
} from "../enum/BasicStateEC/CatalogBasicDataEC";
import { GetNodeInfoResponse } from "../../../types/get_node_info_response";
import { TypeCatalog } from "../enum/BasicStateGeneral/ErrorMessage";
import { getPhoneInputRules } from "../constants/billing_form_constants";
import { isAvailableCountry } from "../services/CatalogPersonTypeByCountry";
import { ErrorEnum } from "../enums/ErrorEnum";
import { CatalogZipResponse } from "../../../types/catalog_zip_response";
import { debounce } from "@mui/material";
import { getLocationCatalog } from "./getLocationCatalog";
import { FieldsNamesRender } from "../enum/FieldsEnumRenderEnum";
import { validCountries } from "../constants/validCountries";

export const traslateBooleanToString = (value: string | boolean) => {
  if (typeof value === "string") {
    return value === YES_NO_VALUES.YES;
  }

  return value ? YES_NO_VALUES.YES : YES_NO_VALUES.NO;
};

const cleanPhoneNumber = (value: string) => {
  return value.replace("-", "");
};

export const getBillingDataInitialValue = (
  initData: Partial<MerchantCompanyRequest>
): Partial<MerchantCompanyRequest> => {
  const request: Partial<MerchantCompanyRequest> = {
    activityId: get(initData, FieldsEnum.activityId, ""),
    address: get(initData, FieldsEnum.address, ""),
    areaCode: get(initData, FieldsEnum.areaCode, ""),
    city: get(initData, FieldsEnum.city, ""),
    cologne: get(initData, FieldsEnum.cologne, ""),
    countryCode: get(initData, FieldsEnum.countryCode, ""),
    department: get(initData, FieldsEnum.department, ""),
    economicActivitySRI: get(initData, FieldsEnum.economicActivitySRI, ""),
    incomeSourceConceptId: get(initData, FieldsEnum.incomeSourceConceptId, ""),
    isPaymentAggregator: traslateBooleanToString(
      get(initData, FieldsEnum.isPaymentAggregator, "")
    ),
    municipality: get(initData, FieldsEnum.municipality, ""),
    obligation: get(initData, FieldsEnum.obligation, ""),
    phoneNumber: cleanPhoneNumber(get(initData, FieldsEnum.phoneNumber, "")),
    populationCode: get(initData, FieldsEnum.populationCode, ""),
    province: get(initData, FieldsEnum.province, ""),
    retentionICA: get(initData, FieldsEnum.activityId, ""),
    stateCode: get(initData, FieldsEnum.stateCode, ""),
    taxpayerTypeId: get(initData, FieldsEnum.taxpayerTypeId, ""),
    taxRegime: get(initData, FieldsEnum.taxRegime, ""),
    taxRegimeId: get(initData, FieldsEnum.taxRegimeId, ""),
    useSameInfoSat: get(initData, FieldsEnum.useSameInfoSat, true),
    zipCode: get(initData, FieldsEnum.zipCode, ""),
  };

  let requestSata: Partial<MerchantCompanyRequest> = {};

  requestSata = {
    satAddress: initData.useSameInfoSat
      ? ""
      : get(initData.sat, FieldsEnum.address, ""),
    satAreaCode: initData.useSameInfoSat
      ? ""
      : get(initData.sat, FieldsEnum.areaCode, ""),
    satCologne: initData.useSameInfoSat
      ? ""
      : get(initData.sat, FieldsEnum.cologne, ""),
    satCountryCode: initData.useSameInfoSat
      ? ""
      : get(initData.sat, FieldsEnum.countryCode, ""),
    satPhoneNumber: initData.useSameInfoSat
      ? ""
      : get(initData.sat, FieldsEnum.phoneNumber, ""),
    satPopulationCode: initData.useSameInfoSat
      ? ""
      : get(initData.sat, FieldsEnum.populationCode, ""),
    satStateCode: initData.useSameInfoSat
      ? ""
      : get(initData.sat, FieldsEnum.stateCode, ""),
    satZipCode: initData.useSameInfoSat
      ? ""
      : get(initData.sat, FieldsEnum.zipCode, ""),
  };

  return {
    ...request,
    ...requestSata,
  };
};

export const getBillingData = (
  payload: MerchantResponse
): MerchantBillingData => {
  return {
    activityId: get(payload, FieldsEnum.activityId, ""),
    address: get(payload, FieldsEnum.address, ""),
    areaCode: get(payload, FieldsEnum.areaCode, 0),
    city: get(payload, FieldsEnum.city, ""),
    cologne: get(payload, FieldsEnum.cologne, ""),
    countryCode: get(payload, FieldsEnum.countryCode, ""),
    department: get(payload, FieldsEnum.department, ""),
    economicActivitySRI: get(payload, FieldsEnum.economicActivitySRI, ""),
    incomeSourceConceptId: get(payload, FieldsEnum.incomeSourceConceptId, ""),
    isPaymentAggregator: get(payload, FieldsEnum.isPaymentAggregator, false),
    municipality: get(payload, FieldsEnum.municipality, ""),
    obligation: get(payload, FieldsEnum.obligation, ""),
    phoneNumber: get(payload, FieldsEnum.phoneNumber, ""),
    populationCode: get(payload, FieldsEnum.populationCode, ""),
    province: get(payload, FieldsEnum.province, ""),
    retentionICA: get(payload, FieldsEnum.activityId, ""),
    sat: {
      address: get(payload.sat, FieldsEnum.address, ""),
      areaCode: get(payload.sat, FieldsEnum.areaCode, ""),
      cologne: get(payload.sat, FieldsEnum.cologne, ""),
      countryCode: get(payload.sat, FieldsEnum.countryCode, ""),
      phoneNumber: get(payload.sat, FieldsEnum.phoneNumber, ""),
      populationCode: get(payload.sat, FieldsEnum.populationCode, ""),
      stateCode: get(payload.sat, FieldsEnum.stateCode, ""),
      zipCode: get(payload.sat, FieldsEnum.zipCode, ""),
    },
    stateCode: get(payload, FieldsEnum.stateCode, 0),
    taxpayerTypeId: get(payload, FieldsEnum.taxpayerTypeId, ""),
    taxRegime: get(payload, FieldsEnum.taxRegime, ""),
    taxRegimeId: get(payload, FieldsEnum.taxRegimeId, ""),
    useSameInfoSat: get(payload, FieldsEnum.useSameInfoSat, true),
    zipCode: get(payload, FieldsEnum.zipCode, ""),
  } as MerchantBillingData;
};

const getCountryCode = (phoneNumber: string) => {
  return phoneNumber.split(" ")[0];
};

const getFormValue = (
  form: UseFormReturn<MerchantCompanyRequest>,
  value: string,
  defaultValue: string | boolean | number
) => defaultTo(form.getValues(value), defaultValue);

const buildSatData = (
  form: UseFormReturn<MerchantCompanyRequest>,
  useSatInfo: boolean,
  areaCode: string,
  satAreaCode: string,
  populationCode: string,
  satPopulationCode: string,
  stateCode: string,
  satStateCode: string
) => {
  const satMerchantCompany: SatMerchantCompany = {
    address: useSatInfo
      ? getFormValue(form, "address", "")
      : getFormValue(form, "satAddress", ""),
    areaCode: useSatInfo ? areaCode : satAreaCode,
    cologne: useSatInfo
      ? getFormValue(form, "cologne", "")
      : getFormValue(form, "satCologne", ""),
    countryCode: useSatInfo
      ? getCountryCode(getFormValue(form, "phoneNumber", ""))
      : getCountryCode(getFormValue(form, "satPhoneNumber", "")),
    phoneNumber: useSatInfo
      ? getFormValue(form, "phoneNumber", "")
      : getFormValue(form, "satPhoneNumber", ""),
    populationCode: useSatInfo ? populationCode : satPopulationCode,
    stateCode: useSatInfo ? stateCode : satStateCode,
    zipCode: useSatInfo
      ? getFormValue(form, "zipCode", "")
      : getFormValue(form, "satZipCode", ""),
  };

  return satMerchantCompany;
};

export const buildBillingData = (
  form: UseFormReturn<MerchantCompanyRequest>,
  useSatInfo: boolean,
  infoBilling: {
    areaCode: string;
    satAreaCode: string;
    populationCode: string;
    satPopulationCode: string;
    stateCode: string;
    satStateCode: string;
    municipality: string;
    city: string;
    province: string;
  },
  publicMerchantId: string,
  country: string,
  constitutionalCountry: string
): MerchantBillingData => {
  let satMerchantCompany: SatMerchantCompany = {
    address: "",
    areaCode: "",
    cologne: "",
    countryCode: "",
    phoneNumber: "",
    populationCode: "",
    stateCode: "",
    zipCode: "",
  };
  let taxRegimeId: string = "";
  let retentionIca: string = "";
  let economicActivityIdSRI: { economicActivityId?: string } = {};

  switch (constitutionalCountry) {
    case CountryEnum.mexico:
      satMerchantCompany = buildSatData(
        form,
        useSatInfo,
        infoBilling.areaCode,
        infoBilling.satAreaCode,
        infoBilling.populationCode,
        infoBilling.satPopulationCode,
        infoBilling.stateCode,
        infoBilling.satStateCode
      );
      taxRegimeId = TAX_REGIME.filter(
        (regime) => regime.value === getFormValue(form, "taxRegime", "")
      )[0].key;
      break;
    case CountryEnum.colombia:
      taxRegimeId = get(CatalogCo, `${CatalogsEnum.Regime}`, [])
        .filter(
          (regime) => regime.name === getFormValue(form, "taxRegime", "")
        )[0]
        .value.toString();
      retentionIca = getFormValue(form, FieldsNamesRender.retention_ICA, "");
      break;
    case CountryEnum.ecuador:
      economicActivityIdSRI = {
        economicActivityId: getFormValue(form, "economicActivityId", ""),
      };
      break;
  }

  const isOtherCountry: boolean = !validCountries.includes(
    constitutionalCountry
  );

  const merchantCompany: MerchantBillingData = {
    activityId: getFormValue(form, FieldsEnum.activityId, ""),
    address: getFormValue(form, "address", ""),
    areaCode: infoBilling.areaCode,
    city: getFormValue(form, "city", infoBilling.city),
    cityDescription: isOtherCountry
      ? getFormValue(form, "city", infoBilling.city)
      : getLocationCatalog(
          constitutionalCountry,
          getFormValue(form, "city", infoBilling.city),
          CatalogsEnum.Cities
        ),
    cologne: getFormValue(form, "cologne", ""),
    configId: ConfigIdEnum.CN016,
    constitutionalCountry,
    country,
    countryCode: getCountryCode(getFormValue(form, "phoneNumber", "")),
    department: getFormValue(form, "department", ""),
    departmentDescription: getLocationCatalog(
      constitutionalCountry,
      getFormValue(form, "department", ""),
      CatalogsEnum.Departments
    ),
    ...economicActivityIdSRI,
    incomeSourceConceptId: getFormValue(
      form,
      FieldsEnum.incomeSourceConceptId,
      ""
    ),
    isPaymentAggregator:
      country === CountryEnum.ecuador
        ? traslateBooleanToString(
            getFormValue(form, FieldsEnum.isPaymentAggregator, "")
          )
        : undefined,
    municipality: getFormValue(form, "municipality", infoBilling.municipality),
    obligation: getFormValue(form, "obligation", ""),
    phoneNumber: getFormValue(form, "phoneNumber", ""),
    populationCode: infoBilling.populationCode,
    province: getFormValue(form, "province", infoBilling.province),
    provinceDescription: getLocationCatalog(
      constitutionalCountry,
      getFormValue(form, "province", infoBilling.province),
      CatalogsEnum.Provinces
    ),
    publicMerchantId,
    regime: taxRegimeId,
    sat: satMerchantCompany,
    stateCode: infoBilling.stateCode,
    taxpayerTypeId: getFormValue(form, FieldsEnum.taxpayerTypeId, ""),
    taxRegime: getFormValue(form, "taxRegime", ""),
    taxRegimeId,
    useSameInfoSat: getFormValue(form, "useSameInfoSat", false),
    zipCode: getFormValue(form, "zipCode", ""),
  };

  switch (constitutionalCountry) {
    case CountryEnum.mexico:
      set(merchantCompany, "city", infoBilling.city);
      set(merchantCompany, "cityDescription", infoBilling.city);
      set(merchantCompany, "municipality", infoBilling.municipality);
      set(merchantCompany, "province", infoBilling.province);
      set(merchantCompany, "provinceDescription", infoBilling.province);
      break;
    case CountryEnum.ecuador:
      set(
        merchantCompany,
        "economicActivitySRI",
        getFormValue(form, FieldsEnum.economicActivitySRI, "")
      );
      set(merchantCompany, "useSameInfoSat", false);
      break;
    case CountryEnum.colombia:
      set(merchantCompany, "regime", taxRegimeId);
      set(merchantCompany, FieldsEnum.activityId, retentionIca);
      set(
        merchantCompany,
        FieldsEnum.cityId,
        getFormValue(form, "city", infoBilling.city)
      );
      break;
  }

  return merchantCompany;
};

export const validatePhoneNumber = (value: string | undefined) => {
  const parts = (value && value.split(" ")) || [];

  if (value && value.includes("-")) {
    const splitLada = value && value.split("-");

    return splitLada.length > 1 && splitLada[1] !== ""
      ? true
      : ErrorBillingData.PHONE_NUMBER;
  }

  return parts.length > 1 && parts[1] !== ""
    ? true
    : ErrorBillingData.PHONE_NUMBER;
};

export const validatePhoneNumberDifferentMX = (
  value: string | undefined,
  min: number,
  max: number
) => {
  let parts = (value && value.split(" ")) || [];

  parts = without(parts, "");
  if (parts.length === 1) return ErrorBillingData.ADD_PHONE_NUMBER;
  if (parts.length > 1) {
    const phone: string = parts[1].replace(" ", "");
    const phone_length: number = phone.length;

    if (phone_length < min || phone_length > max) {
      return ErrorBillingData.VALID_PHONE_NUMBER;
    }

    return true;
  }

  return true;
};
export const validateZipCodeLength = (
  value: string | undefined,
  min: number
) => {
  return defaultTo(value, "").length === min
    ? true
    : `${ErrorBillingData.MAX_ZIPCODE} ${min}`;
};
export const getOnlyNumbers = (value: string) => {
  const regNum = /^[0-9]*$/gm;
  const numericoValido = regNum.test(value);

  return numericoValido ? value : value.slice(0, value.length - 1);
};
export const sanitizeInput = (value: string) => {
  return value.replace(/\s+/g, "").replace(/[^0-9]/g, "");
};
export const getValuesPhoneNumber = (value: string) => {
  const phone_length: number = value.length;
  const split_phone: string[] = value.split(" ");
  const clean_code_phone: string[] = without(split_phone, "");
  const code_phone_length: number = defaultTo(clean_code_phone[0], "").length;

  return {
    clean_code_phone,
    code_phone_length,
    is_dirty: phone_length - 1 > code_phone_length,
    phone_length,
  };
};

export const findEconomicActivitySRI = (
  economicActivityId?: string,
  economicActivitySRI?: string
): object | undefined => {
  if (!economicActivitySRI) {
    return CatalogEC[TypeCatalog.CIUU].find((ciiu: object) =>
      get(ciiu, "ciuuInt", []).includes(economicActivityId)
    );
  }

  return CatalogEC[TypeCatalog.CIUU].find(
    (ciiu: object) => get(ciiu, "id", "") === economicActivitySRI
  );
};

export const getConfigById = (
  nodeInfo: GetNodeInfoResponse,
  configId: ConfigIdEnum
) =>
  get(nodeInfo, "configs", []).find(
    (c) =>
      c.configuration === configId &&
      !isEmpty(c.value) &&
      !isEqual(c.value, "null")
  );

export const addValidationPhone = (
  constitutionalCountry: string,
  form: UseFormReturn<MerchantCompanyRequest, object>
) => {
  if (constitutionalCountry !== CountryEnum.mexico) {
    const validations: RegisterOptions<MerchantCompanyRequest> =
      getPhoneInputRules({})[
        isAvailableCountry(constitutionalCountry)
          ? constitutionalCountry
          : CountryEnum.generic
      ] as RegisterOptions<MerchantCompanyRequest>;

    form.register(FieldsEnum.phoneNumber, {
      ...validations,
    });
    setTimeout(() => {
      form.trigger(FieldsEnum.phoneNumber);
    }, 300);

    return;
  }
  form.register(FieldsEnum.phoneNumber, {
    required: true,
    validate: validatePhoneNumber.bind(null),
  });
};

const getError = (
  value: string,
  type: string,
  form: UseFormReturn<MerchantCompanyRequest, object>
) => {
  const formValue: string = defaultTo(form.getValues(value), "");

  if (formValue.length <= 0) {
    form.register(value, { required: true });
    form.setError(value, { type: type });
  }
};

export const validateFormCountry = (
  useSatInfo: boolean,
  constitutionalCountry: string,
  form: UseFormReturn<MerchantCompanyRequest, object>
): Promise<boolean> => {
  addValidationPhone(constitutionalCountry, form);
  switch (constitutionalCountry) {
    case CountryEnum.mexico:
      getError(FieldsEnum.taxRegime, ErrorEnum.required, form);
      getError(FieldsEnum.zipCode, ErrorEnum.required, form);
      getError(FieldsEnum.address, ErrorEnum.required, form);
      getError(FieldsEnum.phoneNumber, ErrorEnum.required, form);

      if (!useSatInfo) {
        getError(FieldsEnum.satAddress, ErrorEnum.required, form);
        getError(FieldsEnum.satPhoneNumber, ErrorEnum.required, form);
        getError(FieldsEnum.satZipCode, ErrorEnum.required, form);
      }

      return form.trigger();
    case CountryEnum.peru:
      getError(FieldsEnum.zipCode, ErrorEnum.required, form);
      getError(FieldsEnum.address, ErrorEnum.required, form);
      getError(FieldsEnum.phoneNumber, ErrorEnum.required, form);
      getError(FieldsEnum.department, ErrorEnum.required, form);
      getError(FieldsEnum.city, ErrorEnum.required, form);
      getError(FieldsEnum.province, ErrorEnum.required, form);

      return form.trigger();
    case CountryEnum.colombia:
      getError(FieldsEnum.city, ErrorEnum.required, form);
      getError(FieldsEnum.province, ErrorEnum.required, form);
      getError(FieldsEnum.zipCode, ErrorEnum.required, form);
      getError(FieldsEnum.address, ErrorEnum.required, form);
      getError(FieldsEnum.phoneNumber, ErrorEnum.required, form);
      getError(FieldsEnum.taxRegime, ErrorEnum.required, form);
      getError(FieldsEnum.obligation, ErrorEnum.required, form);

      return form.trigger();
    case CountryEnum.el_salvador:
      getError(FieldsEnum.province, ErrorEnum.required, form);
      getError(FieldsEnum.city, ErrorEnum.required, form);
      getError(FieldsEnum.zipCode, ErrorEnum.required, form);
      getError(FieldsEnum.address, ErrorEnum.required, form);
      getError(FieldsEnum.phoneNumber, ErrorEnum.required, form);

      return form.trigger();
    case CountryEnum.chile:
      getError(FieldsEnum.department, ErrorEnum.required, form);
      getError(FieldsEnum.province, ErrorEnum.required, form);
      getError(FieldsEnum.city, ErrorEnum.required, form);
      getError(FieldsEnum.zipCode, ErrorEnum.required, form);
      getError(FieldsEnum.address, ErrorEnum.required, form);
      getError(FieldsEnum.phoneNumber, ErrorEnum.required, form);

      return form.trigger();
    case CountryEnum.brasil:
      getError(FieldsEnum.province, ErrorEnum.required, form);
      getError(FieldsEnum.city, ErrorEnum.required, form);
      getError(FieldsEnum.zipCode, ErrorEnum.required, form);
      getError(FieldsEnum.address, ErrorEnum.required, form);
      getError(FieldsEnum.phoneNumber, ErrorEnum.required, form);

      return form.trigger();
    case CountryEnum.guatemala:
      getError(FieldsEnum.province, ErrorEnum.required, form);
      getError(FieldsEnum.city, ErrorEnum.required, form);
      getError(FieldsEnum.zipCode, ErrorEnum.required, form);
      getError(FieldsEnum.address, ErrorEnum.required, form);
      getError(FieldsEnum.phoneNumber, ErrorEnum.required, form);

      return form.trigger();
    case CountryEnum.ecuador:
      getError(FieldsEnum.province, ErrorEnum.required, form);
      getError(FieldsEnum.city, ErrorEnum.required, form);
      getError(FieldsEnum.zipCode, ErrorEnum.required, form);
      getError(FieldsEnum.address, ErrorEnum.required, form);

      return form.trigger();
    default:
      return form.trigger();
  }
};

export const isFormProgress = (
  form: UseFormReturn<MerchantCompanyRequest, object>
): boolean =>
  !form.formState.isValid || form.getValues(FieldsEnum.phoneNumber)!.length < 5;

export const setLadaPhone = (
  value: string,
  catalog: CatalogZipResponse,
  form: UseFormReturn<MerchantCompanyRequest, object>
) => {
  const satCode = !isEmpty(catalog.areaCode)
    ? catalog.areaCode + "-"
    : catalog.areaCode;

  if (
    !isNil(form.getValues(value)) &&
    defaultTo(form.getValues(value).split(" ")[1], "").length === 0
  ) {
    if (form.getValues(value).split(" ")[0].toString() === "52") {
      if (defaultTo(form.getValues(value), "").split("-").length === 1)
        form.setValue(value, form.getValues(value) + satCode);
      else form.setValue(value, form.getValues(value).split(" ")[0] + satCode);
    }
  }
};

export const revalidateCologne = (
  field: FieldsEnum,
  form: UseFormReturn<MerchantCompanyRequest, object>
) => {
  setTimeout(() => {
    if (!isEmpty(form.getValues(field))) form.trigger(field);
  }, 200);
};

interface setSatValuesForm {
  setZipCodeSatCatalogs: (data: string[]) => void;
  setSatCity: (data: string) => void;
  setSatProvince: (data: string) => void;
  setSatMunicipality: (data: string) => void;
  setSatStateCode: (data: string) => void;
  setSatPopulationCode: (data: string) => void;
  setSatAreaCode: (data: string) => void;
}

export const setSatValuesForm = (
  colognes: string[],
  {
    setZipCodeSatCatalogs,
    setSatCity,
    setSatProvince,
    setSatMunicipality,
    setSatStateCode,
    setSatPopulationCode,
    setSatAreaCode,
  }: setSatValuesForm,
  form: UseFormReturn<MerchantCompanyRequest, object>,
  catalogSatZip: CatalogZipResponse
) => {
  const firstCologne: string = colognes.length <= 0 ? "" : colognes[0];

  setZipCodeSatCatalogs(defaultTo(colognes, []));
  if (isEmpty(form.getValues(FieldsEnum.satCologne)))
    form.setValue(FieldsEnum.satCologne, firstCologne);
  revalidateCologne(FieldsEnum.satCologne, form);
  setLadaPhone(FieldsEnum.satPhoneNumber, catalogSatZip, form);
  setSatCity(catalogSatZip.city);
  setSatProvince(catalogSatZip.state);
  setSatMunicipality(catalogSatZip.municipality);
  setSatStateCode(defaultTo(catalogSatZip.stateCode, ""));
  setSatPopulationCode(defaultTo(catalogSatZip.populationCode, ""));
  setSatAreaCode(defaultTo(catalogSatZip.areaCode, ""));
};

interface setValuesForm {
  setZipCodeCatalogs: (data: string[]) => void;
  setCity: (data: string) => void;
  setProvince: (data: string) => void;
  setMunicipality: (data: string) => void;
  setStateCode: (data: string) => void;
  setPopulationCode: (data: string) => void;
  setAreaCode: (data: string) => void;
}

export const setValuesForm = (
  colognes: string[],
  {
    setZipCodeCatalogs,
    setCity,
    setProvince,
    setMunicipality,
    setStateCode,
    setPopulationCode,
    setAreaCode,
  }: setValuesForm,
  form: UseFormReturn<MerchantCompanyRequest, object>,
  catalogZip: CatalogZipResponse
) => {
  const firstCologne: string = colognes.length <= 0 ? "" : colognes[0];

  setZipCodeCatalogs(defaultTo(colognes, []));
  if (isEmpty(form.getValues(FieldsEnum.cologne)) || colognes.length <= 0)
    form.setValue(FieldsEnum.cologne, firstCologne);
  revalidateCologne(FieldsEnum.cologne, form);
  setLadaPhone(FieldsEnum.phoneNumber, catalogZip, form);
  setCity(defaultTo(catalogZip.city, ""));
  setProvince(defaultTo(catalogZip.state, ""));
  setMunicipality(defaultTo(catalogZip.municipality, ""));
  setStateCode(defaultTo(catalogZip.stateCode, ""));
  setPopulationCode(defaultTo(catalogZip.populationCode, ""));
  setAreaCode(defaultTo(catalogZip.areaCode, ""));
};

export const handleShowSave = (setShowSave: (data: boolean) => void) =>
  debounce(() => {
    setShowSave(false);
  }, 3000);

export const validateFormByField = (
  form: UseFormReturn<MerchantCompanyRequest, object>,
  setActiveSaveButton: (data: boolean) => void
) => {
  if (form.formState.isValid) {
    setActiveSaveButton(true);

    return;
  }
  if (Object.keys(form.formState.dirtyFields).length === 0) {
    setActiveSaveButton(false);

    return;
  }
  const { is_dirty } = getValuesPhoneNumber(
    form.getValues(FieldsEnum.phoneNumber)!
  );

  if (
    Object.keys(form.formState.dirtyFields).length === 1 &&
    has(form.formState.dirtyFields, FieldsEnum.phoneNumber)
  ) {
    setActiveSaveButton(is_dirty);

    return;
  } else if (Object.keys(form.formState.dirtyFields).length > 0) {
    setActiveSaveButton(true);
  }
};
