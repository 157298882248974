import { CellFlagCountryType } from "@kushki/connect-ui/dist/Components/Atoms/Table/CellFlag/CellFlag.enum";

export const flagCatalog: Record<string, CellFlagCountryType> = {
  Brasil: "Brazil",
  Chile: "Chile",
  Colombia: "Colombia",
  CostaRica: "CostaRica",
  Ecuador: "Ecuador",
  EEUU: "EEUU",
  ELSalvador: "ElSalvador",
  Guatemala: "Guatemala",
  Honduras: "Honduras",
  Mexico: "Mexico",
  Nicaragua: "Nicaragua",
  Panama: "Panama",
  Peru: "Peru",
};

export const translateAlarmStatus: Record<string, string> = {
  investigation: "En investigación",
  normal: "Cerrada Normal",
  pending: "Pendiente",
  ros: "Cerrada ROS",
};
