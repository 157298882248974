export enum ChargebackStatusEnum {
  DECLINED = "DECLINED",
  INITIALIZED = "INITIALIZED",
  REVIEW = "REVIEW",
  APPROVAL = "APPROVAL",
  APPROVED = "APPROVED",
  FAILED = "FAILED",
  DOCSENT = "DOCSENT",
  EXPIRED = "EXPIRED",
  PENDING = "PENDING",
  LOST = "LOST",
  WON = "WON",
}

export enum ChargebackStatusLabelEnum {
  APPROVAL = "Aprobado",
  APPROVED = "Aprobado",
  DECLINED = "Declinado",
  INITIALIZED = "Inicializado",
  FAILED = "Fallido",
  REVIEW = "En revisión",
  EXPIRED = "Expirado",
  PENDING = "Pendiente",
  LOST = "Perdido",
  WON = "Ganado",
  DOCSENT = "Documentación enviada",
}

export const CATALOG_CHARGEBACK_STATUS: Record<
  ChargebackStatusEnum,
  ChargebackStatusLabelEnum
> = {
  [ChargebackStatusEnum.DECLINED]: ChargebackStatusLabelEnum.DECLINED,
  [ChargebackStatusEnum.INITIALIZED]: ChargebackStatusLabelEnum.INITIALIZED,
  [ChargebackStatusEnum.APPROVAL]: ChargebackStatusLabelEnum.APPROVAL,
  [ChargebackStatusEnum.APPROVED]: ChargebackStatusLabelEnum.APPROVED,
  [ChargebackStatusEnum.EXPIRED]: ChargebackStatusLabelEnum.EXPIRED,
  [ChargebackStatusEnum.WON]: ChargebackStatusLabelEnum.WON,
  [ChargebackStatusEnum.FAILED]: ChargebackStatusLabelEnum.FAILED,
  [ChargebackStatusEnum.REVIEW]: ChargebackStatusLabelEnum.REVIEW,
  [ChargebackStatusEnum.LOST]: ChargebackStatusLabelEnum.LOST,
  [ChargebackStatusEnum.DOCSENT]: ChargebackStatusLabelEnum.DOCSENT,
  [ChargebackStatusEnum.PENDING]: ChargebackStatusLabelEnum.PENDING,
};
