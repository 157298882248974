import { IStyles } from "../../shared/Interfaces/Styles.interfaces";

export const merchantPeopleHeaderStyles: IStyles = {
  padding: {
    paddingBottom: "32px",
  },
  typography: {
    fontWeight: 300,
  },
  typographyMobile: {
    fontWeight: 200,
  },
};
