import { Box } from "@mui/material";
import React, { FC } from "react";
import { transactionsTableFooterStyles } from "./TransactionsTableFooter.styles";
import TransactionsTableLimitSelect from "./TransactionsTableLimitSelect/TransactionsTableLimitSelect";
import TransactionsTablePagination from "./TransactionsTablePagination/TransactionsTablePagination";

const TransactionsTableFooter: FC = () => (
  <Box sx={transactionsTableFooterStyles.container}>
    <TransactionsTableLimitSelect />
    <TransactionsTablePagination />
  </Box>
);

export default TransactionsTableFooter;
