import { Button, ButtonGroup, Popper } from "@mui/material";
import React, { FC } from "react";
import DownIcon from "../../../assets/DownIcon";
import DownloadIcon from "../../../assets/DownloadIcon";
import { DownloadButtonProps } from "./DownloadButton.interfaces";
import { downloadButtonStyles } from "./DownloadButton.styles";
import useDownloadButton from "./useDownloadButton/useDownloadButton";
import { callbackListDownload } from "./callbackListDownload/callbackListDownload";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { SecurityWrapperEnum } from "../../../shared/enums/SecurityWrapperEnum";

const DownloadButton: FC<DownloadButtonProps> = ({
  options,
  title = "Descargar",
  handleClick,
  disabled,
}: DownloadButtonProps) => {
  const {
    actions: {
      handleClose,
      handleMainButton,
      handleMenuItemClick,
      handleToggle,
    },
    anchorRef,
    ariaControls,
    ariaExpanded,
    open,
    selectedIndex,
  } = useDownloadButton({ handleClick, options });

  return (
    <>
      <ComponentSecurityWrapper componentId={SecurityWrapperEnum.DOWNLOAD}>
        <ButtonGroup
          ref={anchorRef}
          variant="text"
          aria-label="text button group"
          sx={downloadButtonStyles.group}
          disabled={disabled}
        >
          <Button
            onClick={handleMainButton}
            startIcon={<DownloadIcon />}
            sx={downloadButtonStyles.containerButton}
          >
            {title}
          </Button>
          <Button
            size="small"
            aria-controls={ariaControls}
            aria-expanded={ariaExpanded}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
            sx={downloadButtonStyles.containerIconButton}
          >
            <DownIcon />
          </Button>
        </ButtonGroup>
      </ComponentSecurityWrapper>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {callbackListDownload({
          handleClose,
          handleMenuItemClick,
          id: "split-button-menu",
          options,
          selectedIndex,
        })}
      </Popper>
    </>
  );
};

export default DownloadButton;
