import React, { PropsWithChildren } from "react";
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import {
  LabelsDispersion,
  menuItem,
  MenuItemAmount,
} from "../../../../shared/infrastructure/constants/RequestDispersionConstants";
import { SelectComponentProps } from "../../../../shared/infrastructure/interfaces/SelectComponent.interface";
import { selectComponentStyle } from "./SelectComponent.style";

export const SelectComponent = (
  props: PropsWithChildren<SelectComponentProps>
) => {
  const classes = selectComponentStyle();

  return (
    <Grid container direction={"row"} spacing={3} className={classes.mainGrid}>
      <Grid item>
        <FormControl>
          <Select
            value={props.amountValue}
            onChange={props.handleOnchangeSelect}
          >
            {menuItem.map((data: MenuItemAmount, index: number) => (
              <MenuItem key={`${index}-${data.value}`} value={data.value}>
                {data.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        {props.amountValue === menuItem[3].value ? (
          <Grid container direction={"row"} spacing={2}>
            <Grid item>
              <Box>
                <TextField
                  size="small"
                  type="number"
                  value={props.eqAmountValueMin}
                  onChange={props.handleEqMinAmount}
                  className={classes.textFieldSize}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {LabelsDispersion.CLP}
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <p className={classes.box}>y</p>
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <TextField
                  size="small"
                  type="number"
                  value={props.eqAmountValueMax}
                  onChange={props.handleEqMaxAmount}
                  className={classes.textFieldSize}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {LabelsDispersion.CLP}
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Box>
            <TextField
              type="number"
              size="small"
              value={props.generalAmountValue}
              onChange={props.handleOnchangeGeneralAmount}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {LabelsDispersion.CLP}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
