export const upperFirstLetter = (text: string): string => {
  return `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;
};

export const getStyleByStringLength = (
  text: string,
  styles: string[],
  length: number
): string => {
  if (text.length > length) {
    return styles[1];
  }

  return styles[0];
};
