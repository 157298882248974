import { environment } from "../../environments/environment";

export const API_ROUTES = {
  COMPONENTS: "/components",
  GET_ALL_MODULES: `${environment.kushkiUrl}/api/v1/security/modules`,
  GET_ALL_ROLES: `${environment.kushkiUrl}/api/v1/security/roles`,
  GET_COMPONENTS_BY_MODULE: `${environment.kushkiUrl}/api/v1/security/modules/`,
  HISTORICAL_REFUNDS: `${environment.kushkiUrl}/b2c-analytics/v1/refund-transaction-list`,
  POST_ALL_MODULES: `${environment.kushkiUrl}/api/v1/security/modules/components`,
  UPDATE_MODULES: (id: string) =>
    `${environment.kushkiUrl}/api/v1/security/modules/${id}`,
};
