import { ChangeEvent, useState } from "react";
import { IUseListUrl, useListUrlProps } from "./UseListUrl.interfaces";
import { createWebhookConnection } from "../../../store/thunks/webhook/webhook.thunks";
import { useAppDispatch } from "../../../store/hooks/storeHook";
import { WebhookServiceEnum } from "../../../shared/constants/enums/WebhookServiceEnum";
import {
  deleteWebhook,
  setEditUrl,
} from "../../../store/actions/webhook/webhook.actions";

export const useListUrl = ({ url }: useListUrlProps): IUseListUrl => {
  const [edit, setEdit] = useState(false);
  const [urlTemp, setUrlTemp] = useState(url);
  const dispatch = useAppDispatch();

  const handleEdit = () => {
    setUrlTemp(url);
    setEdit(true);
    dispatch(setEditUrl(url));
  };

  const handleDelete = () => {
    dispatch(deleteWebhook(url));
  };

  const handleCheck = () => {
    setEdit(false);
    dispatch(
      createWebhookConnection({
        webhookData: [
          {
            service: WebhookServiceEnum.GENERAL,
            urls: [urlTemp],
          },
        ],
      })
    );
  };

  const handleCancel = () => {
    setEdit(false);
    setUrlTemp(url);
    dispatch(setEditUrl(""));
  };

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    setUrlTemp(event.target.value);
  };

  return {
    edit,
    handleCancel,
    handleChangeInput,
    handleCheck,
    handleDelete,
    handleEdit,
    urlTemp,
  };
};
