import { AnyAction, createAsyncThunk, ThunkDispatch } from "@reduxjs/toolkit";
import { downloadTransactionsWS } from "../../../../shared/utils/websockets/downloadTransactions/download_transactions_ws";
import { IHandleResponseWS } from "../../../../shared/utils/websockets/downloadTransactions/download_transactions_ws.interfaces";
import {
  IDownloadTransactions,
  IResponseDownloadWS,
} from "./download_transactions.interfaces";
import { get } from "lodash";
import {
  setDownloadFile,
  setLoadingDownload,
} from "../../../actions/app_actions";
import { WEBSOCKETS_ENDPOINTS } from "../../../../shared/constants/websockets_endpoints";

export const handleResponse = (
  response: MessageEvent<string>,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>
): IHandleResponseWS => {
  dispatch(setDownloadFile(""));

  const { payload, status }: IResponseDownloadWS = JSON.parse(response.data);
  const messageError = get(payload, "message") ?? "Download failed";

  if (status === "COMPLETED_EMPTY" || payload.error) {
    dispatch(setDownloadFile("ERROR"));
    dispatch(setLoadingDownload(false));

    return {
      closeConnection: true,
      messageError,
    };
  }

  if (status === "COMPLETED_SUCCESS") {
    if (!payload.url) {
      dispatch(setDownloadFile("ERROR"));
      dispatch(setLoadingDownload(false));

      return {
        closeConnection: true,
        messageError,
      };
    }
    try {
      dispatch(setDownloadFile("SUCCESS"));
      window.open(payload.url, "_blank", "noreferrer");
      dispatch(setLoadingDownload(false));
    } catch (e) {}

    return {
      closeConnection: true,
    };
  }

  return {
    closeConnection: false,
  };
};

const downloadTransactions = createAsyncThunk(
  "transactions/downloadTransactions",
  async ({ data }: IDownloadTransactions, thunkAPI) => {
    return await downloadTransactionsWS(
      WEBSOCKETS_ENDPOINTS.updateTransactionsState,
      {
        data,
        dispatch: thunkAPI.dispatch,
        handleResponse,
      }
    );
  }
);

export default downloadTransactions;
