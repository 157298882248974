import React, { FC } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./Magentov2.styles";
import { SaveIcon } from "../../Icons/Icons";
import useMagentov2State from "./state/useMagentov2State";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { IconApproved } from "../../../assets/svgs/IconApproved";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import DialogSuccess from "../../DialogSuccess/DialogSuccess";
import MessageDialog from "../../MessageDialog/MessageDialog";
import NotificationSnackbar from "../../Notification/NotificationSnackbar";
import { isEmpty } from "lodash";

const Magentov2: FC = () => {
  const classes = useStyles();
  const {
    handleClickShowBlurredText,
    handleOnCloseNotification,
    handleCloseSuccessDialog,
    handleSubmit,
    handleOnCloseNotificationSuccess,
    blockSaveButton,
    handleChange,
    hasCredentials,
    isValid,
    loading,
    messageDialog,
    notification,
    privateCredential,
    publicCredential,
    showBlurredText,
    showSuccessDialog,
    magentov2Domain,
    notificationSuccess,
  } = useMagentov2State();

  const isNotValidAndThereAreNotCharacteres =
    !isValid.validDomain &&
    isEmpty(isValid.httpErrorMessage) &&
    magentov2Domain.trim().length !== 0;

  const renderHelperText = () => {
    const helperText =
      isEmpty(isValid.httpErrorMessage) && isNotValidAndThereAreNotCharacteres
        ? "Ingresa un dominio válido"
        : isValid.httpErrorMessage;

    if (isNotValidAndThereAreNotCharacteres) {
      return <div className={classes.errorText}>{helperText}</div>;
    }
    if (
      isValid.validDomain ||
      (!isValid.validDomain && !isEmpty(isValid.httpErrorMessage))
    ) {
      return (
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Typography className={classes.alertLabelDescription}>
              {helperText}
            </Typography>
          </Grid>
          <Grid className={classes.helperText} item xs={12}>
            <IconApproved className={classes.helperTextIcon} />
            {magentov2Domain}
          </Grid>
        </Grid>
      );
    }

    return undefined;
  };
  return (
    <Grid container item xs={12}>
      <Grid item xs={3} md={1}>
        {""}
      </Grid>
      <Grid xs={9} md={11}>
        <Grid container className={classes.body}>
          {" "}
          <Grid item xs={12}>
            <Typography className={classes.labelTitle}>Dominio</Typography>
            <Typography className={classes.labelDescription}>
              Dominio Magento es la <strong>dirección URL</strong> de tu tienda,
              la puedes encontrar en tu Perfil
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} md={7}>
              <TextField
                id="magentov2Domain"
                name="magentov2Domain"
                placeholder="Ejemplo: mitienda.magento.com"
                type="text"
                fullWidth
                variant="outlined"
                error={isNotValidAndThereAreNotCharacteres}
                value={magentov2Domain}
                onChange={handleChange}
                helperText={renderHelperText()}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                variant="outlined"
                color="default"
                id={"button-save"}
                className={classes.saveButton}
                startIcon={
                  loading ? <CircularProgress size={12} /> : <SaveIcon />
                }
                onClick={handleSubmit}
                disabled={
                  (!isValid.validDomain && isEmpty(isValid.httpErrorMessage)) ||
                  blockSaveButton
                }
              >
                Guardar
              </Button>
            </Grid>
            <Grid item xs={12} md={4}>
              {""}
            </Grid>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            direction="row"
            className={classes.containerInfo}
          >
            <InfoIcon />
            <Typography className={classes.labelInfo}>
              Para activar el proceso de integración necesitamos conectar tu
              dominio con Kushki.
            </Typography>
          </Grid>
          {hasCredentials && publicCredential !== null && (
            <Grid container item xs={12} className={classes.credentialsSection}>
              <Grid item xs={12} md={5} className={classes.inputTextCredential}>
                <TextField
                  id="publicCredential"
                  name="publicCredential"
                  label="Public API Key"
                  disabled
                  value={publicCredential}
                  type="text"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  id="privateCredential"
                  name="privateCredential"
                  label="Private API Key"
                  type="text"
                  value={privateCredential}
                  disabled
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    type: showBlurredText ? "text" : "password",
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          id={"show-obfuscated-text-button"}
                          onClick={handleClickShowBlurredText}
                          edge="end"
                        >
                          {!showBlurredText ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <DialogSuccess
        title={"Dominio guardado con éxito"}
        description={
          "Las credenciales de tu dominio se han creado con éxito bajo el alias Adobe Commerce"
        }
        open={showSuccessDialog}
        onClose={handleCloseSuccessDialog}
      />
      <MessageDialog
        open={messageDialog.open}
        onClose={messageDialog.onClose}
        title={messageDialog.title}
        description={messageDialog.description}
      />
      <NotificationSnackbar
        msg={notification.message}
        open={notification.open}
        type={notification.type}
        handlerClose={handleOnCloseNotification}
      />
      <NotificationSnackbar
        msg={notificationSuccess.message}
        open={notificationSuccess.open}
        type={notificationSuccess.type}
        handlerClose={handleOnCloseNotificationSuccess}
      />
    </Grid>
  );
};

export default Magentov2;
