import { ChangeEvent, useEffect, useState } from "react";
import { IBranchItem } from "../SelectedBranch.interfaces";
import { IUseSelectedBranchInterfaces } from "./IUseSelectedBranch.interfaces";
import { useAppSelector } from "../../../store/hooks/storeHook";
import { get, isEmpty } from "lodash";

export const useSelectedBranch = (): IUseSelectedBranchInterfaces => {
  const {
    massiveInformation: { listBranches },
  } = useAppSelector((store) => store.layout);
  const [filterText, setFilter] = useState("");
  const [filtered, setFiltered] = useState(listBranches);

  const handleFilter = (
    valueToEval: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setFilter(get(valueToEval, "target.value", "").toLowerCase());
  };

  useEffect(() => {
    setFiltered(
      listBranches.filter((branch: IBranchItem) => {
        return (
          isEmpty(filterText) ||
          branch.name.toLowerCase().indexOf(filterText) > -1
        );
      })
    );
  }, [filterText]);

  return {
    filtered,
    handleFilter,
    totalBranches: listBranches.length,
  };
};
