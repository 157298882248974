import { useState } from "react";
import { format, set, sub } from "date-fns";
import { FilterDispersionRequest } from "../../../../types/filter_dispersion_request";
import {
  getProcessFileDispersion,
  setFilters,
} from "../../../store/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { isUndefined } from "lodash";
import { LabelsDispersion } from "../../../shared/infrastructure/constants/RequestDispersionConstants";

export interface IUseDateRangePickerState {
  handleDate: (dateRange: any) => void;
  value: any;
}

export const useDateRangePickerState = (): IUseDateRangePickerState => {
  const dispatch = useDispatch();
  const [selectedDateRange, setSelectedDateRange] = useState<any>([
    set(sub(new Date(), { days: 1 }), { hours: 0, minutes: 0, seconds: 0 }),
    set(new Date(), { hours: 23, minutes: 59, seconds: 59 }),
  ]);

  const filterDispersionRequestState: FilterDispersionRequest | undefined =
    useSelector((state: IAppState) => state.filterDispersionRequest);

  const handleChangeDateRange = (date: any): void => {
    setSelectedDateRange(date);
    const [fromDate, toDate] = date;

    if (!isUndefined(filterDispersionRequestState)) {
      const addDateRangeFilter: FilterDispersionRequest = {
        ...filterDispersionRequestState,
        from: format(fromDate, LabelsDispersion.DATE_FORMAT),
        to: format(toDate, LabelsDispersion.DATE_FORMAT),
      };
      dispatch(setFilters(addDateRangeFilter));
      dispatch(getProcessFileDispersion(addDateRangeFilter));
    }
  };

  return {
    value: selectedDateRange,
    handleDate: handleChangeDateRange,
  };
};
