import React from "react";
import {
  Chip,
  createStyles,
  Grid,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { get } from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      boxShadow: "none !important",
      borderRadius: "0px !important",
      backgroundColor: "#F7FAFC !important",
      marginBottom: "20px",
    },
    chip: {
      color: "#293036",
      fontWeight: 400,
      marginBottom: "5px",
      marginRight: "5px",
      backgroundColor: "#F0F4F9 !important",
    },
  })
);

export interface informationFirstSectionProps {
  supportAdmin: object | undefined;
  isAdmin: boolean;
}

const InformationFirstSection: React.FC<informationFirstSectionProps> = (
  props: informationFirstSectionProps
) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant={"body2"} color={"textSecondary"}>
                  {props.isAdmin ? "Nombre:" : "Usuario solicitante:"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant={"body2"} color={"textSecondary"}>
                  Nombre de usuario:
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant={"body2"} color={"textPrimary"}>
                  {get(props.supportAdmin, "_source.user_full_name", "N/A")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant={"body2"} color={"textPrimary"}>
                  {get(props.supportAdmin, "_source.user_name", "N/A")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {props.isAdmin && (
                <Grid item xs={6}>
                  <Typography variant={"body2"} color={"textSecondary"}>
                    Comercio asociado:
                  </Typography>
                </Grid>
              )}
              <Grid item xs={6}>
                <Typography variant={"body2"} color={"textSecondary"}>
                  Roles de usuario:
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {props.isAdmin && (
                <Grid item xs={6}>
                  <Typography variant={"body2"} color={"textPrimary"}>
                    {get(props.supportAdmin, "_source.merchant_name", "N/A")}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={props.isAdmin ? 6 : 12}>
                <Typography variant={"body2"} color={"textPrimary"}>
                  {get(props.supportAdmin, "_source.rol", []).map(
                    (rol: string) => (
                      <Chip key={rol} label={rol} className={classes.chip} />
                    )
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export default InformationFirstSection;
