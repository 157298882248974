import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import instance from "../../../shared/axios-util";
import {
  IBreadCrumbItemProps,
  IconCircleWarn,
  IRowInteractiveProps,
  ItemCategoryItem,
  ItemCategoryProps,
  TextFieldSearchByProps,
  useSnackbar,
} from "@kushki/connect-ui";
import { get, isEmpty } from "lodash";
import {
  ALL_TABLE_COLUMNS_NOTIFICATIONS_BRANCH,
  ALL_TABLE_COLUMNS_NOTIFICATIONS_CUSTOMER,
  NotificationsProperties,
  TABLE_ROW,
  TEXT_CELL,
} from "../../../shared/constants/notifications_container";
import {
  ITableCellProps,
  ITableRowProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import {
  IHits,
  IPostWebhooksListNotifications,
} from "../../../../types/post_webhooks_listNotifications_response";
import { IWebhooksNotification } from "../../../../types/post_webhooks_notification_response";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import moment from "moment";
import {
  END_UTC_SUFFIX,
  FORMAT_DATE,
  MOMENT_LANGUAGE,
  QUERY_DATE_END,
  QUERY_DATE_START,
  START_UTC_SUFFIX,
} from "../../../shared/constants/date_values";
import {
  OPTIONS_FILTER_PAYMENT_METHOD,
  OPTIONS_FILTER_STATUS,
} from "../../../shared/constants/options_filter_notifications";
import { NotificationDetailsBCEnum } from "../../../shared/constants/enums/BreadCrumbEnum";
import { ROUTES } from "../../../shared/constants/routes_tab";
import {
  ISearchMerchantNode,
  ISearchResponse,
} from "./useNotificationsContainer.interface";
import { EntityName } from "../../../shared/enums/entityName";
import {
  formatDDMMYYYY,
  formatHourMinutesSeconds,
} from "../../../shared/utils/date_utils";
import { CustomHeaderCellProps } from "../../../shared/constants/types/CustomHeaderCellProps.type";
import { PagesEnum } from "../../../shared/enums/PagesEnum";

export type TextFieldSearchBy = {
  secondaryText: string;
  text: string;
};

export type MerchantInfoSearch = {
  merchantId: string;
  name: string;
};

const MAX_BRANCH_ESTIMATED: number = 240;

export const useNotificationsContainer = () => {
  const [rows, setRows] = useState<ITableRowProps[]>([]);
  const [columns, setColumns] = useState<CustomHeaderCellProps[]>([]);
  const [isLoaderModalOpen, setIsLoaderModalOpen] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedPage, setSelectedPage] = useState(PagesEnum.FIRST);
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [textFieldSearchBy, setTextFieldSearchBy] =
    useState<TextFieldSearchByProps>();
  const [fromElastic, setFromElastic] = useState<string>();
  const [toElastic, setToElastic] = useState<string>();
  const [merchantsIds, setMerchantsIds] = useState<any[]>([]);
  const [merchantFind, setMerchantFind] = useState<string>("");
  const [itemsPerPage, setItemsPerPage] = useState<number>(5);
  const [paymentMethodsFilter, setPaymentMethodsFilter] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<string>("");
  const [filterPaymentMethods, setFilterPaymentMethods] = useState<
    ItemCategoryItem[]
  >(OPTIONS_FILTER_PAYMENT_METHOD);
  const [filterStatusOptions, setFilterStatusOptions] = useState<
    ItemCategoryItem[]
  >(OPTIONS_FILTER_STATUS);

  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { showSnackbar } = useSnackbar();
  const entityName = searchParams.get("entityName");
  const path = searchParams.get("path");
  const { publicMerchantId } = useParams();

  moment.locale(MOMENT_LANGUAGE);

  const buildBreadCrumbItems = (): IBreadCrumbItemProps[] => [
    {
      id: NotificationDetailsBCEnum.LEVEL0,
      locationRef: true,
      text: NotificationDetailsBCEnum.LEVEL0,
      url: `${ROUTES.MERCHANT_LIST}`,
    },
    {
      id: NotificationDetailsBCEnum.LEVEL1,
      text: NotificationDetailsBCEnum.LEVEL1,
      url: `/..${ROUTES.RESUME}/?publicMerchantId=${publicMerchantId}`,
    },
    {
      id: NotificationDetailsBCEnum.CURRENT,
      text: NotificationDetailsBCEnum.CURRENT,
      url: `${publicMerchantId}/${ROUTES.NOTIFICATIONS}`,
    },
  ];

  const redirectNotificationDetail = (publicMerchantId: string, item: IHits) =>
    navigate(`/${publicMerchantId}/notifications/detail`, {
      state: { entityNameParam: entityName, item: item, pathParam: path },
    });

  const handlePageClick = (page: number) => {
    setSelectedPage(page);
  };

  const filterItemsSelected = (
    filter: ItemCategoryItem[],
    positionArrayFilter: number,
    itemsSelected: ItemCategoryProps[]
  ): ItemCategoryItem[] => {
    return filter.map((item: ItemCategoryItem) => {
      if (
        itemsSelected[positionArrayFilter].items
          .map((i: ItemCategoryItem) => i.value)
          .includes(item.value)
      )
        return { ...item, selected: true };

      return item;
    });
  };

  const handleApplyFilter = (itemsSelected: ItemCategoryProps[]) => {
    const paymentMethods: string = itemsSelected[0].items
      .map((item: ItemCategoryItem) => item.value)
      .join("|");
    const status: string = itemsSelected[1].items
      .map((item: ItemCategoryItem) => item.value)
      .join("|");

    setFilterPaymentMethods(
      filterItemsSelected(filterPaymentMethods, 0, itemsSelected)
    );
    setFilterStatusOptions(
      filterItemsSelected(filterStatusOptions, 1, itemsSelected)
    );

    setPaymentMethodsFilter(paymentMethods);
    setStatusFilter(status);
  };

  const mockFn = () => {};

  const postWebhooksNotification = async (object: IHits) => {
    setIsLoaderModalOpen(true);
    try {
      await instance.post<IWebhooksNotification>(
        API_ROUTES.WEBHOOKS_NOTIFICATION,
        {
          alias: object.alias,
          created: object.created,
          event: object.event,
          merchantId: object.merchantId,
          origin: object.origin,
          paymentMethod: object.paymentMethod,
          requestBody: object.requestBody,
          responseBody: object.responseBody,
          status: object.status,
          timestamp: object.timestamp,
          url: object.url,
          webhookId: object.webhookId,
        }
      );
      showSnackbar({
        color: "success",
        message: "Se reenvió la notificación exitosamente",
        variant: "simple",
      });
    } catch (error) {
      showSnackbar({
        color: "danger",
        icon: <IconCircleWarn />,
        message: "Hubo un error al momentos de reenviar la notificación",
        variant: "simple",
        withIcon: true,
      });
    } finally {
      setIsLoaderModalOpen(false);
    }
  };

  const onRenderValue = (key: string, columnValue: string, object: IHits) => {
    const renderValues = {
      default: () => ({ line1: columnValue }),
      [NotificationsProperties.DATE_AND_TIME]: () => ({
        line1: formatDDMMYYYY(columnValue),
        line2: formatHourMinutesSeconds(columnValue),
      }),
      [NotificationsProperties.BRANCH_ID]: () => ({
        line1: get(object, "name", ""),
        line2: columnValue,
      }),
      [NotificationsProperties.OPTION]: () => ({
        onClick: () => postWebhooksNotification(object),
      }),
      [NotificationsProperties.PAYMENT_METHOD]: () => ({
        line1: columnValue[0],
      }),
    };

    return get(renderValues, key, renderValues.default)();
  };

  const getColumns = (object: IHits, columns: CustomHeaderCellProps[]) => {
    return columns.map((column) => {
      const value = onRenderValue(column.key, get(object, column.key), object);

      const tableCell = get(TABLE_ROW, column.cellType, TEXT_CELL);

      return tableCell(value) as ITableCellProps;
    });
  };

  const createRows = (apiResponse: IHits[]) => {
    const columns =
      entityName === "customer"
        ? ALL_TABLE_COLUMNS_NOTIFICATIONS_CUSTOMER
        : ALL_TABLE_COLUMNS_NOTIFICATIONS_BRANCH;
    const merchantInfo: TextFieldSearchBy[] = [];
    const rows = apiResponse.map((item, i) => {
      merchantInfo.push({
        secondaryText: get(item, "name", ""),
        text: item.merchantId,
      });

      item.alias = item.alias || "-";

      return {
        cells: getColumns(item, columns),
        id: `${i}`,
        rowProps: {
          color: "default",
          onClick: () => redirectNotificationDetail(item.merchantId, item),
        } as IRowInteractiveProps,
      };
    });

    setMerchantsIds(
      merchantInfo.filter(
        (person: TextFieldSearchBy, index: number) =>
          index ===
          merchantInfo.findIndex(
            (other: TextFieldSearchBy) =>
              person.secondaryText === other.secondaryText
          )
      )
    );
    setRows(rows);
  };

  const validateMerchantsToSearch = (
    customerMerchantList?: string[]
  ): string[] => {
    if (!isEmpty(merchantFind)) return [merchantFind];
    else if (!isEmpty(customerMerchantList)) return customerMerchantList!;
    else return [publicMerchantId!];
  };

  const postWebhooksListNotifications = async (merchantsToFind?: string[]) => {
    setIsLoading(true);
    try {
      return await instance.post<IPostWebhooksListNotifications>(
        API_ROUTES.WEBHOOKS_LIST_NOTIFICATIONS,
        {
          filter: { paymentMethod: paymentMethodsFilter, status: statusFilter },
          from: fromElastic ?? QUERY_DATE_START,
          limit: itemsPerPage,
          merchantIds: validateMerchantsToSearch(merchantsToFind),
          offset: (selectedPage - 1) * itemsPerPage,
          to: toElastic ?? QUERY_DATE_END,
        }
      );
    } catch (error) {
      setIsLoading(false);
      setIsInitialLoading(false);

      return { data: { hits: [], total: 0 } };
    }
  };

  const getDateRangePicker = (startDate: string, endDate: string) => {
    const dateStart = moment(startDate, FORMAT_DATE.FORMAT_DATE_RANGE_PICKER);
    const dateEnd = moment(endDate, FORMAT_DATE.FORMAT_DATE_RANGE_PICKER);

    setFromElastic(
      `${dateStart.format(FORMAT_DATE.FORMAT_DATE_FILTER)}${START_UTC_SUFFIX}`
    );
    setToElastic(
      `${dateEnd.format(FORMAT_DATE.FORMAT_DATE_FILTER)}${END_UTC_SUFFIX}`
    );
  };

  const changeItemsPerPage = (items: number) => {
    setSelectedPage(PagesEnum.FIRST);
    setItemsPerPage(items);
  };

  const searchNodeCall = async (offset: number) => {
    return await instance.post<ISearchResponse>(
      API_ROUTES.SEARCH_MERCHANT_NODE,
      {
        entityName: EntityName.BRANCH,
        limit: MAX_BRANCH_ESTIMATED,
        offset,
        path: path,
      }
    );
  };

  const buildNotifications = (
    totalNotifications: IHits[],
    nameBranches: MerchantInfoSearch[]
  ): IHits[] => {
    return totalNotifications.map((item) => {
      let name: string = "";

      if (
        nameBranches.some((i) => {
          name = i.name;

          return i.merchantId === get(item, "merchantId", "");
        })
      ) {
        return {
          ...item,
          name,
        };
      }

      return { ...item, name: "" };
    });
  };

  const searchNotifyForCustomer = async () => {
    let totalNotifications: IHits[] = [];
    let totalSearchNodes: ISearchMerchantNode[] = [];
    let nameBranches: MerchantInfoSearch[] = [];
    let offset: number = 0;
    let totalMerchants: number = 0;
    let totalNotificationsLength: number = 0;
    let notificationsFind: number = 0;

    do {
      const merchants = await searchNodeCall(offset);
      const merchantIds = merchants.data.data.map((nodes) => {
        return nodes.merchant_id;
      });
      const notifications = await postWebhooksListNotifications(merchantIds);

      nameBranches.push(
        ...merchants.data.data.map((i) => {
          return { merchantId: i.merchant_id, name: i.name };
        })
      );
      totalSearchNodes.push(...merchants.data.data);
      totalNotifications.push(...notifications.data.hits);

      totalMerchants = merchants.data.total;
      totalNotificationsLength += notifications.data.total;
      offset += MAX_BRANCH_ESTIMATED;
      if (!isEmpty(merchantFind)) notificationsFind += 1;
    } while (offset <= totalMerchants && notificationsFind < 1);

    createRows(buildNotifications(totalNotifications, nameBranches));
    setTotalItems(totalNotificationsLength);
    setIsLoading(false);
    setIsInitialLoading(false);
  };

  const searchNotifyForBranch = async () => {
    const { data } = await postWebhooksListNotifications();

    createRows(data.hits);
    setTotalItems(data.total);
    setIsLoading(false);
    setIsInitialLoading(false);
  };

  useEffect(() => {
    setTextFieldSearchBy(
      entityName === "customer"
        ? {
            items: merchantsIds,
            onItemSelected: (inf) => {
              setSelectedPage(PagesEnum.FIRST);
              !isEmpty(inf) ? setMerchantFind(inf.text) : setMerchantFind("");
            },
            placeholder: "Buscar por Branch ID o nombre de Branch",
          }
        : undefined
    );

    entityName === "customer"
      ? setColumns(ALL_TABLE_COLUMNS_NOTIFICATIONS_CUSTOMER)
      : setColumns(ALL_TABLE_COLUMNS_NOTIFICATIONS_BRANCH);
  }, [merchantsIds]);

  useEffect(() => {
    setIsLoading(true);
    if (entityName?.toUpperCase() == EntityName.BRANCH) {
      searchNotifyForBranch();
    } else if (entityName?.toUpperCase() == EntityName.CUSTOMER) {
      searchNotifyForCustomer();
    }
  }, [
    selectedPage,
    toElastic,
    fromElastic,
    merchantFind,
    itemsPerPage,
    paymentMethodsFilter,
    statusFilter,
  ]);

  useMemo(() => {
    if (!paymentMethodsFilter && !statusFilter) {
      const paymentMethods: string = filterPaymentMethods
        .filter((item: ItemCategoryItem) => item.selected)
        .map((item: ItemCategoryItem) => item.value)
        .join("|");
      const status: string = filterStatusOptions
        .filter((item: ItemCategoryItem) => item.selected)
        .map((item: ItemCategoryItem) => item.value)
        .join("|");

      if (paymentMethods || status) {
        setPaymentMethodsFilter(paymentMethods);
        setStatusFilter(status);
      }
    }
  }, [filterPaymentMethods, filterStatusOptions]);

  return {
    buildBreadCrumbItems,
    changeItemsPerPage,
    columns,
    createRows,
    entityName,
    filterPaymentMethods,
    filterStatusOptions,
    getDateRangePicker,
    handleApplyFilter,
    handlePageClick,
    isInitialLoading,
    isLoaderModalOpen,
    isLoading,
    itemsPerPage,
    mockFn,
    postWebhooksNotification,
    redirectNotificationDetail,
    rows,
    selectedPage,
    textFieldSearchBy,
    totalItems,
  };
};
