import { PayMethodEnum } from "../enums/pay_method";

export type TPaymentMethod = "card" | "transfer";

export const PAYMENT_METHOD_LABEL_LOOKUP: Record<TPaymentMethod, string> = {
  card: "Tarjeta",
  transfer: "Transferencia",
};

export const PAYMENT_METHOD_FROM_RESPONSE_LOOKUP: Record<
  string,
  TPaymentMethod
> = {
  card: "card",
  transfer: "transfer",
};

export const PAYMENT_METHOD_ENUM_LOOKUP: Record<TPaymentMethod, string> = {
  card: PayMethodEnum.CREDIT_CARD,
  transfer: PayMethodEnum.TRANSFER,
};
