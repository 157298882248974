import Axios from "axios-observable";
import { environment } from "../environments/environment";
import { defaultTo } from "lodash";

const instance = Axios.create({
  baseURL: environment.kushkiUrl,
  headers: {
    Authorization: defaultTo(localStorage.getItem("jwt"), ""),
    "Content-type": "application/json",
  },
});

export default instance;
