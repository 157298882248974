import { DATE_VALUES } from "../constants/date_values";
import { DateEnum } from "../enums/DateEnum";
import getTimeZoneInHours from "./current_timezone";
import { CatalogTranslateMonth } from "../enums/TransactionEnum";
import Rollbar from "rollbar";
import { TimeEnum } from "../enums/TimeEnum";
import { isNil } from "lodash";

export const getFormattedTimezoneOffset = (timezoneOffsetInMin: number) => {
  const sign = timezoneOffsetInMin < 0 ? "+" : "-";

  const timezoneAbs = Math.abs(timezoneOffsetInMin);

  const timezoneOffsetInHours = timezoneAbs / 60;

  const formattedTimezone = timezoneOffsetInHours.toLocaleString("es-ES", {
    minimumIntegerDigits: 2,
    useGrouping: true,
  });

  return `${sign}${formattedTimezone}:00`;
};

export const setCurrentTimeZone = (time: string) => {
  const currentHour = parseInt(time.split(":")[0]) - getTimeZoneInHours();
  const currentHourNumber =
    currentHour < 0 ? DATE_VALUES.HOURS_IN_A_DAY + currentHour : currentHour;
  const currentHourNumberDigits =
    currentHourNumber < 10 ? "0" + currentHourNumber : currentHourNumber;

  return currentHourNumberDigits + time.slice(2);
};

export const getStartDate = (rollbar: Rollbar, date_search?: string) => {
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // Number of milliseconds in one day
  const previousDayTimestamp = new Date().getTime() - oneDayInMilliseconds;
  const date = isNil(date_search)
    ? new Date(previousDayTimestamp)
    : new Date(date_search);
  const day = date.getDate().toString().padStart(2, "0");
  const month = date.toLocaleString("es", { month: "short" });
  const year = date.getFullYear();

  try {
    return `${day}/${month.toLowerCase().replace("sept", "sep")}/${year}`;
  } catch (e) {
    rollbar.info("getStartDate", { date, month, year });

    return "";
  }
};

export const getFinishDate = (rollbar: Rollbar) => {
  const date = new Date();
  const day = date.getDate().toString().padStart(2, "0");
  const month = date.toLocaleString("es", { month: "short" });
  const year = date.getFullYear();

  try {
    return `${day}/${month.toLowerCase().replace("sept", "sep")}/${year}`;
  } catch (e) {
    rollbar.info("getFinishDate", { date, month, year });

    return "";
  }
};

export const getMinDate = (rollbar: Rollbar) => {
  const date = new Date();
  const year = date.getFullYear();

  try {
    return `${DateEnum.DAY_DEFAULT}/${DateEnum.MONTH_DEFAULT}/${year - 1}`;
  } catch (e) {
    rollbar.info("getMinDate", { date });

    return "";
  }
};

export const convertDateCorrectFormat = (
  date: string,
  from: boolean = false,
  rollbar: Rollbar
): string => {
  try {
    const regexHourMin = /\d{2}:\d{2}/;
    const isDateHour: boolean = regexHourMin.test(date);
    const newDate: string = isDateHour ? date.split(" ")[0] : date;
    const parts = date.split(" ");
    const day = newDate.split("/")[0];
    const month = CatalogTranslateMonth[newDate.split("/")[1]];
    const year = newDate.split("/")[2];

    const seconds: string = from
      ? TimeEnum.ceroSeconds
      : TimeEnum.fiftyNineSeconds;

    const hour: string = isDateHour
      ? `T${parts[1].trim()}:${seconds}.000`
      : from
      ? TimeEnum.initialTHour
      : TimeEnum.finalTHour;

    return `${year}-${month}-${day}${hour}`;
  } catch (e) {
    rollbar.info("convertDateCorrectFormat", date);

    return "";
  }
};
