import {
  ItemCategoryItemMassive,
  ItemCategoryMassiveProps,
} from "../Filters/ItemCategory/interfaces";
import { TFilterDetailBar } from "@kushki/connect-ui/dist/Components/Molecules/Filters/FilterDetailBar/FilterDetailBar.interfaces";
import { IFilterItem } from "@kushki/connect-ui";

import { FilterSideBarMassiveProps } from "../Filters/FilterSideBar/FilterSideBar.interfaces";
import {
  FILTER_GROUPS,
  STATE_ITEM_VALUES,
  STATE_LABEL_ITEM,
} from "../../shared/constants/header_filters_labels";
import { FilterTextsEnum } from "../../shared/enum/FilterTextsEnum";

export const getItemsFilterDetailBar = (
  itemCategory: ItemCategoryMassiveProps[]
): TFilterDetailBar[] => {
  let itemsFilterDetailBar: TFilterDetailBar[] = [];

  itemCategory.map((itemSideBar: ItemCategoryMassiveProps) => {
    let itemsFilterBar: IFilterItem[] = [];

    itemSideBar.items.map((itemFilterSideBar: ItemCategoryItemMassive) => {
      return (
        itemFilterSideBar.selected &&
        itemsFilterBar.push({
          label: itemFilterSideBar.label,
          value: itemFilterSideBar.value,
        })
      );
    });
    if (itemsFilterBar.length > 0)
      itemsFilterDetailBar.push({
        items: itemsFilterBar,
        title: itemSideBar.groupSection,
      });
  });

  return itemsFilterDetailBar;
};

export const FILTER_SIDE_BAR_INITIAL_STATE: FilterSideBarMassiveProps = {
  categoryItems: [
    {
      groupSection: FILTER_GROUPS.STATE,
      isMinimize: true,
      items: [
        {
          label: STATE_LABEL_ITEM.COMPLETE,
          selected: false,
          value: STATE_ITEM_VALUES[STATE_LABEL_ITEM.COMPLETE],
        },
        {
          label: STATE_LABEL_ITEM.PENDING,
          selected: false,
          value: STATE_ITEM_VALUES[STATE_LABEL_ITEM.PENDING],
        },
      ],
      selectType: "single",
      type: "byChips",
    },
  ],
  filtersCount: 0,
  isMinimized: false,
  isOpen: true,
  onClick: () => {},
  title: FilterTextsEnum.FILTER_BY,
};
