import React from "react";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Paper, Button } from "@material-ui/core";
import { DeferredOption } from "../../../../../shared/infrastructure/interfaces/DeferredOptions";
import {
  DEFERRED_TYPE,
  PROCESSOR_CREDIMATIC,
  PROCESSOR_DATAFAST,
  ProcessorsEnum,
} from "../../../../../shared/infrastructure/catalogs/DeferredTypeEcuadorEnum";
import { get } from "lodash";
import { CountryEnum } from "../../../../../shared/infrastructure/catalogs/CountryEnum";
import moment from "moment";
import { translateProcessor } from "../../../../../shared/utils";

export interface TableProps {
  dataRows: DeferredOption[];
  dataHeaders: string[];
  maxElements: number;
  handleSetBank(data: string[]): void;
  country: string;
}

export const StyledTableCell = withStyles(
  createStyles({
    head: {
      backgroundColor: "transparent",
      fontWeight: "bold",
    },
    body: {
      fontSize: 14,
      "font-weight": 500,
    },
  })
)(TableCell);
const useStyles = makeStyles(() =>
  createStyles({
    TableContainer: {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    button: {
      fontWeight: "bold",
    },
    tableCell: { paddingTop: "5px", paddingBottom: "5px" },
  })
);
export const TableDeferred = (props: TableProps) => {
  const classes = useStyles();

  const setCodeToProcessor = (code: string[]) => {
    let textProcessor: string = "";

    for (let i = 0; i < PROCESSOR_DATAFAST.length; i++) {
      if (PROCESSOR_DATAFAST[i] === code[0])
        textProcessor = ProcessorsEnum.DATAFAST;
    }
    for (let x = 0; x < PROCESSOR_CREDIMATIC.length; x++) {
      if (PROCESSOR_CREDIMATIC[x] === code[0])
        textProcessor = ProcessorsEnum.CREDIMATIC;
    }

    return textProcessor || code[0];
  };

  const setCodeToTextDeferred = (data: DeferredOption) => {
    let textDeferredType: string = "";
    const code = data.deferredType;

    if (code[0] === "Prosa")
      return get(data, "type", "Meses de gracia con intereses");
    if (code[0] === "Billpocket")
      return "Diferidos con intereses, sin meses de gracias";
    if (code[0] === "E Global") return "-";

    for (let z = 0; z < DEFERRED_TYPE.length; z++) {
      if (DEFERRED_TYPE[z].code === code[0])
        textDeferredType = DEFERRED_TYPE[z].text;
    }

    return textDeferredType || code[0];
  };

  const isBrazil: boolean = props.country === CountryEnum.BRAZIL;

  const getScheduledDate = (deferredOption: DeferredOption) => {
    return (
      timestampToDate(get(deferredOption, "startDate", 0)) +
      " - " +
      timestampToDate(get(deferredOption, "endDate", 0))
    );
  };

  const timestampToDate = (time: number) => {
    if (time === 0) return "";
    return moment.unix(time).format("DD/MM/YYYY");
  };

  return (
    <React.Fragment>
      <TableContainer component={Paper} className={classes.TableContainer}>
        <Table aria-label="merchant-configuration-table">
          <TableHead>
            <TableRow>
              {props.dataHeaders.map((val: string, index: number) => {
                return <StyledTableCell key={index}>{val}</StyledTableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.dataRows
              .filter((_, index: number) => index < props.maxElements)
              .map((data: DeferredOption, index: number) => (
                <TableRow key={index}>
                  <TableCell className={classes.tableCell}>
                    {isBrazil
                      ? translateProcessor(get(data, "processorName", ""))
                      : setCodeToProcessor(data.deferredType)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {isBrazil
                      ? getScheduledDate(data)
                      : setCodeToTextDeferred(data)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {data.months.join(", ")}
                  </TableCell>
                  <TableCell align="right" className={classes.tableCell}>
                    {data.deferredType[0] === "Prosa" && (
                      <Button
                        className={classes.button}
                        variant="outlined"
                        size="small"
                        onClick={() => props.handleSetBank(data.bank)}
                      >
                        Ver bancos asociados
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};
