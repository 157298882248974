import { combineReducers, configureStore } from "@reduxjs/toolkit";
import rulesAlarmSlice from "@store/reducers/rulesAlarm/rulesAlarms.slice";
import nodeSelectionSlice from "@store/reducers/nodeSelection/nodeSelection.slice";
import alarmConfig from "@store/reducers/alarmConfig/alarmConfig.slice";
import generalReducer from "@store/reducers/general/general.slice";
import alarmsReducer from "@store/reducers/alarms/alarms.slice";
import executivesSlice from "@store/reducers/executives/executives.slice";
import catalogsReducer from "@store/reducers/catalogs/catalogs.slice";

export const rootReducer = combineReducers({
  alarmConfig: alarmConfig,
  alarms: alarmsReducer,
  catalogs: catalogsReducer,
  executives: executivesSlice,
  general: generalReducer,
  nodeSelection: nodeSelectionSlice,
  rulesAlarm: rulesAlarmSlice,
});
export const store = configureStore({
  devTools: process.env.NEXT_PUBLIC_ENV_NAME !== "primary",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
