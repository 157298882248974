import { useFormContext } from "react-hook-form";
import { IForm } from "./FormControl.interfaces";

const ConnectForm = <T extends {}>({
  children,
}: {
  children: (form: IForm) => JSX.Element;
}) => {
  const formMethods = useFormContext<T>();

  return children({
    ...formMethods,
  });
};

export default ConnectForm;
