import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { IGenericHeaderProps } from "@components/GenericHeader/GenericHeader.interfaces";
import { NodeSelectionStyles as styles } from "@containers/NodeSelection/NodeSelection.styles";
import { convertStringBolds } from "@shared/utils/boldTextUtils";

const GenericHeader: React.FC<IGenericHeaderProps> = ({
  title,
  children,
  hasBoldText,
}: IGenericHeaderProps) => {
  return (
    <Box sx={styles.headerBox}>
      <Grid container alignItems={"center"}>
        <Grid item xs={6}>
          <Box>
            <Typography variant={"h6"} color="text.primary">
              {convertStringBolds(title, hasBoldText).map((itemTxt, idx) => (
                <React.Fragment key={`titleidx-${idx}`}>
                  {itemTxt}
                </React.Fragment>
              ))}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {children}
    </Box>
  );
};

export default GenericHeader;
