import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { get } from "lodash";
import { Skeleton, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { getCatalog } from "../../shared/infrastructure/constants/MerchantInformationConstants";
import { CatalogsEnum } from "../../shared/infrastructure/catalogs-enum";
import React from "react";
import { useBillingInvoiceBodyStyles } from "../BillingInvoiceBody/BillingInvoiceBody.style";
import { InvoiceBilling } from "../../../types/invoice_billing";
import NumberFormat from "react-number-format";

export interface IBillingInvoiceBody {
  invoiceBilling: InvoiceBilling;
  formChange: (
    name: string,
    value: string | boolean | number,
    isUpdated?: boolean
  ) => void;
  isLoadingInvoice: boolean;
}
const numberFormat = (props: any) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: "",
            value: values.value,
          },
        });
      }}
      thousandsGroupStyle="thousand"
      decimalSeparator="."
      thousandSeparator={" "}
      allowNegative={false}
    />
  );
};

export const BillingInvoiceBody = (props: IBillingInvoiceBody) => {
  const { invoiceBilling, formChange } = props;
  const classes = useBillingInvoiceBodyStyles();

  return (
    <React.Fragment>
      <Box
        mt={2}
        p={4}
        style={{
          backgroundColor: "#F7FAFC",
        }}
      >
        <Grid container spacing={3} direction={"column"}>
          <Grid
            container
            item
            spacing={2}
            xs={12}
            direction={"row"}
            justify={"flex-end"}
          >
            <Grid item container alignItems={"center"} xs={1}>
              <Typography
                style={{
                  fontSize: "12px",
                }}
              >
                Moneda
              </Typography>
            </Grid>
            <Grid item>
              <ToggleButtonGroup
                value={get(invoiceBilling, "selected_currency", "")}
                exclusive
                aria-label="text formatting"
                onChange={(_, value: string | null) => {
                  if (value !== null) formChange("selected_currency", value);
                }}
              >
                {getCatalog(
                  get(invoiceBilling, "country", ""),
                  CatalogsEnum.Currency
                ).map((currency) => (
                  <ToggleButton
                    key={currency.value}
                    selected={
                      get(invoiceBilling, "selected_currency", "") ===
                      currency.value
                    }
                    value={currency.value}
                    aria-label="bold"
                    className={classes.buttonToggle}
                  >
                    {currency.value}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <Grid container item spacing={2} xs={12}>
            <Grid item xs={6}>
              Item
            </Grid>
            <Grid item xs={6}>
              Subtotal (valor sin IGV)
            </Grid>
          </Grid>
          {props.isLoadingInvoice ? (
            <Grid item container spacing={2} style={{ marginBottom: "10px" }}>
              <Grid item xs={12}>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={"100%"}
                  height={35}
                />
              </Grid>
              <Grid item xs={12}>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={"100%"}
                  height={35}
                />
              </Grid>
            </Grid>
          ) : (
            <React.Fragment>
              {invoiceBilling.items.map((invoice: any, index: number) => (
                <Grid key={index} container item spacing={2} xs={12}>
                  <Grid item xs={6}>
                    <FormControl fullWidth={true}>
                      <TextField
                        fullWidth={true}
                        variant={"outlined"}
                        type={"text"}
                        value={invoice.description}
                        onChange={(e) =>
                          formChange(
                            `items[${index}].description`,
                            e.target.value
                          )
                        }
                        className={classes.textFieldStyle}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth={true}>
                      <TextField
                        fullWidth={true}
                        variant={"outlined"}
                        value={invoice.unitPrice}
                        onChange={(e) =>
                          formChange(
                            `items[${index}].unitPrice`,
                            Number(e.target.value),
                            true
                          )
                        }
                        className={classes.textFieldStyle}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {get(invoiceBilling, "selected_currency", "")}
                            </InputAdornment>
                          ),
                          inputComponent: numberFormat as any,
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              ))}
            </React.Fragment>
          )}

          <Grid container item spacing={2} xs={12}>
            <Grid item xs={12}>
              <Divider
                style={{
                  marginTop: "-20px",
                }}
              />
              <Typography className={classes.invoiceTitle}>
                Resumen de la factura
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={7}>
                <Typography className={classes.invoiceSubtitle}>
                  Subtotal
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  align={"right"}
                  style={{
                    fontSize: "15px",
                    color: "#677684",
                  }}
                >
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    value={get(invoiceBilling, "subTotal", 0).toFixed(2)}
                    decimalSeparator="."
                    displayType="text"
                    thousandSeparator={" "}
                    allowNegative={false}
                  />
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography align={"right"} className={classes.invoiceCurrency}>
                  {get(invoiceBilling, "selected_currency", "")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={7}>
                <Typography className={classes.invoiceSubtitle}>
                  Impuestos
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align={"right"} className={classes.invoiceNumber}>
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    value={get(invoiceBilling, "taxes", 0).toFixed(2)}
                    decimalSeparator="."
                    displayType="text"
                    thousandSeparator={" "}
                    allowNegative={false}
                  />
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography align={"right"} className={classes.invoiceCurrency}>
                  {get(invoiceBilling, "selected_currency", "")}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={7}>
                <Typography className={classes.invoiceTotal}>Total</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align={"right"} className={classes.invoiceNumber}>
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    value={get(invoiceBilling, "total").toFixed(2)}
                    decimalSeparator="."
                    displayType="text"
                    thousandSeparator={" "}
                    allowNegative={false}
                  />
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography align={"right"} className={classes.invoiceCurrency}>
                  {get(invoiceBilling, "selected_currency", "")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};
