import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const massiveActionsUsersStyles = createNamedStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    width: "100%",
    "@media (min-width: 960px)": {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  totalDescription: {
    fontSize: "15px",
    color: "#023365",
    "@media (max-width: 959px)": {
      textAlign: "center",
    },
  },
  total: {
    fontWeight: "bold",
  },
  buttonsContainer: {
    display: "flex",
    gap: "10px",
    "@media (max-width: 959px)": {
      flexDirection: "column",
      alignItems: "center",
      marginTop: "10px",
    },
  },
  button: {
    fontSize: "14px",
    fontWeight: "500",
  },
});
