import { IStyles } from "../../../../../../../shared/interfaces/Styles.interfaces";

export const styles: IStyles = {
  container: {
    flexDirection: "row",
  },
  text: {
    color: "text.grey",
    typography: "caption",
  },
  value: {
    color: "text.dark",
    typography: "caption",
  },
};
