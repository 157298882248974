import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    filterButton: {
      marginTop: "3px",
      backgroundColor: "#FFFFFF",
      padding: "7px 30px",
    },
    defaultColor: {
      color: "#023365",
    },
    filterPopover: {
      "&.MuiPopover-root .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "2px solid #0DC298",
      },
      "&.MuiPopover-root .MuiInput-underline:focus:not(.Mui-disabled):before": {
        borderBottom: "2px solid #0DC298",
      },
      "&.MuiPopover-root .MuiInput-underline.Mui-focused:before": {
        borderBottom: "2px solid #0DC298",
      },
      "&.MuiPopover-root .MuiInput-underline.Mui-focused:after": {
        borderBottom: "2px solid #0DC298",
      },
      /// Change to size chip
      "&.MuiPopover-root .MuiChip-label": {
        width: "33em",
      },
      "&.MuiPopover-root .MuiChip-clickable": {
        width: "33em",
      },
    },
  })
);

export default useStyles;
