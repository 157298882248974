import { IStyles } from "../../../../shared/infrastructure/interfaces/Styles.interfaces";
import theme from "../../../../theme";

export const RefundsPaginationStyles: IStyles = {
  buttonIconPagination: {
    textTransform: "none",
  },
  marginButton: {
    display: "flex",
    alignItems: "center",
  },
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 14px",
  },
  typographyLabelPagination: {
    marginRight: "0.7rem",
    color: "rgba(0, 0, 0, 0.6)",
    fontWeight: 500,
  },
  selectPages: {
    color: "rgba(0, 0, 0, 0.6)",
    marginRight: "11px",
    padding: "0",
    fontSize: "14px",
    "& fieldset": {
      border: "transparent",
    },
  },
};
