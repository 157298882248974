import { createNamedStyles } from "../../../../shared/utils";

export const pickerStyles = createNamedStyles({
  button: {
    mx: 2,
    my: 3,
    gridColumn: "1 / 3",
  },
  footer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
  input: {
    ml: 4,
    textAlign: "center",
  },
  inputContainer: {
    mx: 2,
  },
});
