import React, { FC } from "react";
import { Box, Container } from "@material-ui/core";
import Title from "../../components/Title/Title";
import { FormProvider } from "react-hook-form";
import TableHeader from "../../components/TableHeader/TableHeader";
import {
  BUTTON_TEXT,
  EMPTY_TABLE_SUBTITLE,
  EMPTY_TABLE_TITLE,
  TABLE_HEADER_TITLE,
} from "../../shared/constants/labels/modules_container_labels";
import TableComponent from "../../components/TableComponent/TableComponent";
import { useModulesContainer } from "./state/useModulesContainer";
import SkeletonTable from "../../components/SkeletonTable/SkeletonTable";
import { GetAllModulesResponse } from "../../../types/get_all_modules_response";
import EmptyTable from "../../components/EmptyTable/EmptyTable";
import { StatusEnum } from "../../shared/enums/statusEnum";
import TableEndOptions from "../../components/TableEndOptions/TableEndOptions";
import SnackBarAlertComponent from "../../components/SnackBarAlertComponent/SnackBarAlertComponent";
import { HEADERS_MODULES } from "../../shared/constants/modules_container_constants";
import { ModalDialog } from "../../components/ModalDialog/ModalDialog";
import PaginationComponent from "../../components/PaginationComponent/PaginationComponent";
import { TypeEnum } from "../../shared/enums/typeEnum";

const ModulesContainer: FC = () => {
  const {
    form,
    modalDialogProps,
    handleClickAddButton,
    handleChangeSwitch,
    handleClickEditButton,
    handleClickRow,
    isLoadingGetModules,
    modules,
    pagination,
  } = useModulesContainer();

  const endOptions = (row: GetAllModulesResponse) => (
    <TableEndOptions<GetAllModulesResponse>
      checkedCondition={(value) => value.status === StatusEnum.ENABLED}
      handleChangeSwitch={handleChangeSwitch}
      handleClickEditButton={handleClickEditButton}
      row={row}
      isModule={true}
    />
  );

  return (
    <>
      <Container maxWidth={false}>
        <Title />
        <TableHeader
          title={TABLE_HEADER_TITLE}
          buttonText={BUTTON_TEXT}
          handleButtonClick={handleClickAddButton}
          isModule={true}
        />
        <Box pt={3}>
          {isLoadingGetModules ? (
            <SkeletonTable columns={5} rows={6} />
          ) : modules.length === 0 ? (
            <EmptyTable
              title={EMPTY_TABLE_TITLE}
              subtitle={EMPTY_TABLE_SUBTITLE}
            />
          ) : (
            <>
              <TableComponent<GetAllModulesResponse>
                data={modules}
                headers={HEADERS_MODULES}
                endOptions={endOptions}
                handleClickRow={handleClickRow}
              />
              <PaginationComponent {...pagination} />
            </>
          )}
        </Box>
        <FormProvider {...form}>
          <ModalDialog
            handleClose={modalDialogProps.handlerCloseModalDialog}
            handleCreateOrEdit={modalDialogProps.handlerCreateOrEdit}
            isNew={modalDialogProps.isNew}
            open={modalDialogProps.openModalDialog}
            handlerDeleteRoles={modalDialogProps.handlerDeleteRoles}
            type={TypeEnum.MODULE}
          />
        </FormProvider>
      </Container>
      <SnackBarAlertComponent rootStoreKey={"modules"} />
    </>
  );
};

export default ModulesContainer;
