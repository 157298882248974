import * as React from "react";
import { Button, makeStyles, Modal, Typography } from "@material-ui/core";

export interface CustomRedirectionModalProps {
  open: boolean;
  handleClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  headerText: string;
  bodyText: string;
  firstButtonText?: string;
  onClickFirstButton?: () => void;
  secondButtonText?: string;
  onClickSecondButton?: () => void;
}

const useStyles = makeStyles(() => ({
  redirectionModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  redirectionModalContainer: {
    padding: "24px 32px",
    background: "#FFFFFF",
    boxShadow:
      "0 4px 6px rgba(50, 50, 93, 0.12), 0 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    outline: "none",
    width: "40%",
  },
  redirectionModalBody: {
    paddingTop: "10px",
    paddingBottom: "23px",
  },
  redirectionModalButtonGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  redirectionModalButton1: {
    textTransform: "none",
    paddingRight: "22px",
  },
  redirectionModalButton2: {
    textTransform: "none",
  },
  textStrong: {
    fontWeight: 600,
  },
  redStrong: {
    fontWeight: 600,
    color: "#E34762",
  },
}));

const CustomRedirectionModal: React.FC<CustomRedirectionModalProps> = (
  props
) => {
  const {
    open,
    handleClose,
    headerText,
    bodyText,
    firstButtonText,
    onClickFirstButton,
    secondButtonText,
    onClickSecondButton,
  } = props;
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.redirectionModal}
    >
      <div className={classes.redirectionModalContainer}>
        <Typography
          variant={"h6"}
          color="primary"
          className={classes.textStrong}
        >
          {headerText}
        </Typography>
        <Typography
          className={classes.redirectionModalBody}
          variant={"body1"}
          color={"textSecondary"}
        >
          {bodyText}
        </Typography>
        <div>
          <div className={classes.redirectionModalButtonGroup}>
            {firstButtonText && (
              <Button
                className={classes.redirectionModalButton1}
                onClick={onClickFirstButton}
              >
                <Typography variant={"body1"} className={classes.textStrong}>
                  {firstButtonText}
                </Typography>
              </Button>
            )}
            {secondButtonText && (
              <Button
                className={classes.redirectionModalButton2}
                onClick={onClickSecondButton}
                color={"primary"}
              >
                <Typography variant={"body1"} className={classes.redStrong}>
                  {secondButtonText}
                </Typography>
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CustomRedirectionModal;
