/* istanbul ignore file */
import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "./store/reducer";
import { getAuthMerchant, setNotification } from "./store/actionCreators";
import { SnackBarAlert } from "./components/commons/Snackbar/SnackBarAlert";
import { ContainerIndex } from "./containers/ContainerIndex/ContainerIndex";
import { EditDeferred } from "./containers/EditDeferred/EditDeferred";
import { routes } from "./shared/infrastructure/constants/routes";
import CreateDeferred from "./containers/CreateDeferred/CreateDeferred";

export const Navigation: React.FC = () => {
  const dispatch = useDispatch();
  const notification = useSelector((state: IAppState) => state.notification);

  const handleCloseSnackbar = () => {
    const payload = { ...notification!, open: false };

    dispatch(setNotification(payload));
  };

  useEffect(() => {
    dispatch(setNotification(notification!));
    dispatch(getAuthMerchant());
  }, []);

  return (
    <>
      <BrowserRouter basename={"merchant-deferred"}>
        <Switch>
          <Route exact path="/" component={ContainerIndex} />
          <Route exact path={routes.CONFIG_DEFERRED} component={EditDeferred} />
          <Route
            exact
            path={routes.CREATE_DEFERRED}
            component={CreateDeferred}
          />
        </Switch>
      </BrowserRouter>
      <SnackBarAlert
        type={notification!.type}
        msg={notification!.message}
        open={notification!.open}
        customColor={notification?.customColor}
        icon={notification?.icon}
        handlerClose={handleCloseSnackbar}
      />
    </>
  );
};

export default Navigation;
