export const NOTIFICATION_DETAIL_TITLE = "Notificaciones de Webhooks";
export const NOTIFICATION_DATE = "Hora:";
export const NOTIFICATION_ORIGIN = "Origen:";
export const NOTIFICATION_DESTINY = "Destino:";
export const NOTIFICATION_PAYMENT_METHOD = "Metodo de pago:";
export const NOTIFICATION_EVENT = "Evento:";
export const NOTIFICATION_STATUS = "Estado:";
export const NOTIFICATION_CONFIG = "Configuración:";
export const NOTIFICATION_ALIAS = "Alias:";
export const NOTIFICATION_BODY_REQUEST = "Cuerpo de envío";
export const NOTIFICATION_BODY_RESPONSE = "Cuerpo de la respuesta";
