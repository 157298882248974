import React, { useEffect, useRef, useState } from "react";
import {
  IFiltersDashboard,
  IUseFilterDashboard,
  IUseFilterDashboardProps,
  IUserData,
} from "./useDashboardTransaction.interfaces";
import { TFilterDetailBar } from "@kushki/connect-ui/dist/Components/Molecules/Filters/FilterDetailBar/FilterDetailBar.interfaces";
import {
  cloneDeep,
  get,
  has,
  isEmpty,
  isEqual,
  isNil,
  omitBy,
  set,
} from "lodash";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { FILTER_LABELS } from "../../../shared/constants/labels/filter_labels";
import { SearchTransactionRequest } from "../../../../types/search_transaction_request";
import {
  Transaction,
  TransactionInfo,
} from "../../../../types/transactions_data";
import {
  getPaymentReceipt,
  notifySelectCheckChipsChange,
  searchMerchant,
} from "../../../store/thunks/app/app.thunks";
import { TrxElasticField, TrxMongoField } from "../../../shared/enums/DbEnum";
import { EntityNameEnum } from "../../../shared/enums/EntityNameEnum";
import { FILTER_GROUPS } from "../../../shared/constants/labels/header_filters_labels";
import { RangeAmount } from "../../AmountFilter/state/useAmountFilter.interfaces";
import {
  builderRows,
  IConfigCells,
} from "../../../shared/constants/table_transactions";
import { ITableRowProps } from "../../Table/TableTransactions/TableTransactions.interfaces";
import {
  buildBranchesIds,
  buildStringFilter,
} from "../../../shared/utils/dashboard_transaction_utils";
import { ViewTypeEnum } from "../../../shared/catalogs/CatalogConfigTable";
import { joinItemList } from "../../../shared/utils/joinItemList";
import { base64ToBlob } from "../../../shared/utils/general_utils";
import { environment } from "../../../environments/environment";
import { CognitoRolesEnum } from "../../../shared/infraestructure/CognitoRolesEnum";
import { TransactionsRequest } from "../../../../types/transactions_request";
import { PAY_OUTS_LIST_FILTERS_INITIAL_STATE } from "../../../shared/constants/PayoutsListConstants";
import { downloadTransactions } from "../../../shared/utils/download_utils";
import { TransactionLabelsEnum } from "../../../shared/enums/transactionLabelsEnum";
import { setIsLoadingDownload } from "../../../store/actions/app.actions";
import { ColumnsItem } from "@kushki/connect-ui";
import {
  ActionHeader,
  AdminHeaderTableFixed,
  AdminLeftRowFixed,
  HeaderTableFixed,
  LeftRowFixed,
  StatusHeader,
} from "../../../shared/catalogs/CatalogConfigTableTransaction";
import {
  buildSelectedHead,
  column_selected,
  getCustomRow,
} from "../../../shared/utils/column_selected";
import { HeadCell } from "../../Table/TableHead/TableHead.interfaces";
import {
  convertDateCorrectFormat,
  getFinishDate,
  getStartDate,
} from "../../../shared/utils/timezone";
import Rollbar from "rollbar";
import { useRollbar } from "@rollbar/react";
import { getColumnsUser } from "../../../shared/utils/transaction_list_utils";
import { isCustomerRol } from "../../../shared/utils";

export const useDashboardTransaction = ({
  entityName,
  path,
  transactionData,
  showEmptyScreen,
  mechantFilter,
}: IUseFilterDashboardProps): IUseFilterDashboard => {
  const {
    selectItems,
    nodeInfo,
    listButton,
    isLoadingDownload,
    receipt,
    retrieveChildren,
    isTransactionSearch,
    selectCheckChipsChange,
  } = useAppSelector((state) => ({
    ...state.app,
    ...state.customerReducer,
  }));
  const rollbar: Rollbar = useRollbar();
  const user_profile: IUserData = getColumnsUser();

  const [subTitle, setSubTitle] = useState<string>("");
  const dispatchApp = useAppDispatch();
  const [page, setPage] = useState(0);
  const [isApplyFilter, setIsApplyFilter] = React.useState<boolean>(false);
  const [itemsSelected, setItemsSelected] = useState<TransactionInfo[]>([]);
  const [transactionInfo, setTransactionInfo] = useState<Transaction>({});
  const [selected, setSelected] = useState<ColumnsItem[]>(
    cloneDeep(
      user_profile.admin
        ? column_selected
        : [column_selected[0], ...column_selected.slice(3)]
    )
  );
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const totalData: number = get(transactionData, "total.value", 0);
  const [initialFilters] = React.useState<IFiltersDashboard>({
    filter: {},
    headerFilters: {
      dateRangeFilter: {
        from: convertDateCorrectFormat(getStartDate(rollbar), true, rollbar),
        to: convertDateCorrectFormat(getFinishDate(rollbar), false, rollbar),
      },
    },
    paginationFilters: {
      offset: rowsPerPage * page,
      rowsPerPage: rowsPerPage,
    },
    sort: {
      field: "created",
      order: "asc",
    },
    text: "",
    timeZone: user_profile.timeZone,
    type: ViewTypeEnum.PAYOUT,
  });
  const [filterDashboard, setFilterDashboard] =
    useState<IFiltersDashboard>(initialFilters);
  const filterRef = useRef({});
  const [resetFilters, setResetFilters] = useState<boolean>();
  const [rangeAmountInfo, setRangeAmountInfo] = useState<RangeAmount>();
  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const [resetItemChecked, setResetItemChecked] = useState<boolean>();
  const [hasFilters, setHasFilters] = useState<boolean>(false);
  const [countData, setCountData] = useState<number>(0);
  const [disableDownload, setDisableDownload] = useState<boolean>(false);
  const searchRequest: TransactionsRequest = {
    ...PAY_OUTS_LIST_FILTERS_INITIAL_STATE,
  };
  const [isOpenModalDetailInfo, setIsOpenModalDetailInfo] =
    useState<boolean>(false);

  const handleSelectedRows = (rowsSelected: TransactionInfo[]) =>
    setItemsSelected(rowsSelected);

  const handleData = (items: ColumnsItem[]) => setSelected(items);

  const handleChangePage = (_event: unknown, newPage: number) => {
    const pageSelect: number = newPage - 1;

    setPage(pageSelect);
    setFilterDashboard({
      ...filterDashboard,
      paginationFilters: {
        ...filterDashboard.paginationFilters,
        offset: rowsPerPage * pageSelect,
      },
    });
  };

  const filterSelected = (): HeadCell[] => {
    const getHeadSelected: HeadCell[] = buildSelectedHead(
      selected.filter((item: ColumnsItem) => item.isSelected)
    );
    const fixedHead: HeadCell[] = isCustomerRol()
      ? HeaderTableFixed
      : AdminHeaderTableFixed;

    getHeadSelected.push(StatusHeader, ActionHeader);

    return fixedHead.concat(getHeadSelected);
  };

  const buildRows = (): IConfigCells[] => {
    const selectedItems: ColumnsItem[] = selected.filter(
      (item: ColumnsItem) => item.isSelected
    );
    const getRowsFixed: IConfigCells[] = isCustomerRol()
      ? LeftRowFixed
      : AdminLeftRowFixed;
    const fixedRows: IConfigCells[] = getRowsFixed.filter(
      (item) => item.isActive
    );

    return fixedRows.concat(getCustomRow(selectedItems));
  };

  const rows: ITableRowProps[] = builderRows<Transaction>(
    get(transactionData, "data", []),
    buildRows(),
    page.toString()
  );

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilterDashboard({
      ...filterDashboard,
      paginationFilters: {
        ...filterDashboard.paginationFilters,
        offset: 0,
        rowsPerPage: parseInt(event.target.value, 10),
      },
    });
  };

  const handleChangeFilterDetails = (
    selectedFilters: TFilterDetailBar[]
  ): void => {
    const filterRequest = {};

    selectedFilters.map((filter: TFilterDetailBar) => {
      switch (filter.title) {
        case FILTER_GROUPS.PAYMENT_METHODS:
          set(filterRequest, "paymentMethod", buildStringFilter(filter.items));
          break;
        case FILTER_GROUPS.STATE:
          set(
            filterRequest,
            "transactionStatus",
            buildStringFilter(filter.items)
          );
          break;
        case FILTER_LABELS.TICKET_NUMBER:
          set(filterDashboard, "text", buildStringFilter(filter.items));
          break;
        case FILTER_LABELS.RANGE_DATE_FROM:
          set(
            filterDashboard,
            "headerFilters.dateRangeFilter.from",
            buildStringFilter(filter.items)
          );
          break;
        case FILTER_LABELS.RANGE_DATE_TO:
          set(
            filterDashboard,
            "headerFilters.dateRangeFilter.to",
            buildStringFilter(filter.items)
          );
          break;
      }
    });

    setPage(0);
    setFilterDashboard({
      ...filterDashboard,
      filter: filterRequest,
      paginationFilters: {
        ...filterDashboard.paginationFilters,
        offset: 0,
      },
    });
  };

  const setDisableDownloadByMerchantId = (): void => {
    const roles = JSON.parse(localStorage.getItem("roles")!);
    const merchant_ids: string[] = environment.disableDownloadMIDs.split(";");

    if (get(roles, CognitoRolesEnum.BACK_OFFICE_READER, false))
      setDisableDownload(
        merchant_ids.includes(localStorage.getItem("merchantId")!)
      );
  };

  const handleDownloadReceipt = (transactionInfo: TransactionInfo) => {
    dispatchApp(
      getPaymentReceipt({
        body: nodeInfo,
        transactionInfo,
      })
    );
  };

  useEffect(() => {
    if (receipt) {
      const blob: Blob = base64ToBlob(receipt.toString());

      const url: string = window.URL.createObjectURL(blob);

      window.open(url, "_blank");
      window.URL.revokeObjectURL(url);
    }
  }, [receipt]);

  const getMerchantId = () => {
    const merchant_filter_aux: string = retrieveChildren
      .map((item) => get(item, "publicMerchantId", []).join())
      .filter((item) => !isEmpty(item))
      .join("|")
      .split(",")
      .join("|");

    if (
      !isEmpty(retrieveChildren) &&
      !isEmpty(mechantFilter.itemsList) &&
      !isEmpty(merchant_filter_aux)
    )
      return merchant_filter_aux;

    if (isEmpty(mechantFilter.itemsList) && user_profile.admin)
      return undefined;

    return PAY_OUTS_LIST_FILTERS_INITIAL_STATE.publicMerchantId;
  };

  const handleSortQuery = (fetchedQuery: any) => {
    setFilterDashboard((prevState) => ({
      ...prevState,
      sort: fetchedQuery,
    }));
  };

  const builderSearchTransactionRequest = (
    filters: IFiltersDashboard
  ): SearchTransactionRequest => {
    return {
      customerFilter: get(filters, "customerFilter"),
      filter: get(filters, "filter"),
      from: get(filters, "headerFilters.dateRangeFilter.from"),
      limit: get(filters, "paginationFilters.rowsPerPage"),
      offset: get(filters, "paginationFilters.offset"),
      publicMerchantId: getMerchantId(),
      rangeAmount: rangeAmountInfo,
      sort: {
        field: get(filters, "sort.field"),
        order: get(filters, "sort.order"),
      },
      text: get(filters, "text"),
      timeZone: get(filters, "timeZone"),
      to: get(filters, "headerFilters.dateRangeFilter.to"),
      type: get(filters, "type"),
    };
  };

  const handleSelect = (event: any) => {
    dispatchApp(setIsLoadingDownload(true));

    const format: string = event.id;
    const filters: TransactionsRequest = {
      ...searchRequest,
      filter: get(filterDashboard, "filter"),
      format,
      from: filterDashboard.headerFilters?.dateRangeFilter?.from!,
      limit: filterDashboard.paginationFilters.rowsPerPage!,
      offset: 0,
      publicMerchantId: getMerchantId(),
      rangeAmount: rangeAmountInfo,
      sort: {
        field: get(filterDashboard, "sort.field"),
        order: get(filterDashboard, "sort.order"),
      },
      text: filterDashboard.text,
      to: filterDashboard.headerFilters?.dateRangeFilter?.to!,
      type: ViewTypeEnum.PAYOUT,
    };

    if (entityName === EntityNameEnum.CUSTOMER) {
      let children_ids: string = get(
        filterDashboard,
        "customerFilter.childrenIds",
        ""
      );

      if (children_ids.length > 0) {
        const list = listButton.map((item) => item.description);

        children_ids = list.join("|");
      }

      set(filterDashboard, "customerFilter.childrenIds", children_ids);
    }

    if (itemsSelected.length > 0) {
      const is_db_mongo: boolean = has(itemsSelected[0], TrxMongoField.ID);
      const target_field_name: string = is_db_mongo
        ? TrxMongoField.ID
        : TrxElasticField.TRANSACTION_ID;
      const field_values: string[] = itemsSelected.map((trx: TransactionInfo) =>
        get(trx, target_field_name)
      );
      const ticket_field_values: string[] = itemsSelected.map(
        (trx: TransactionInfo) => get(trx, "ticket_code")
      );

      downloadTransactions(
        dispatchApp,
        {
          ...filters,
          field: target_field_name,
          fields: field_values,
          ticket_fields: ticket_field_values,
        },
        entityName === EntityNameEnum.CUSTOMER,
        true,
        {
          childrenIds: buildBranchesIds(filterDashboard, selectItems!),
          path,
        }
      );
    } else {
      downloadTransactions(
        dispatchApp,
        filters,
        entityName === EntityNameEnum.CUSTOMER,
        false,
        {
          childrenIds: buildBranchesIds(filterDashboard, selectItems!),
          path,
        }
      );
    }
  };

  const searchFilters = (filters: SearchTransactionRequest) => {
    const clean_filters: object = omitBy(
      filters,
      (item) => isNil(item) || isEmpty(item)
    );

    filterRef.current = cloneDeep(filters);

    setIsFiltering(
      has(clean_filters, "filter") ||
        has(clean_filters, "name") ||
        has(clean_filters, "from") ||
        has(clean_filters, "to")
    );

    let can_search = true;

    if (entityName === EntityNameEnum.CUSTOMER) {
      let children_ids: string = get(filters, "customerFilter.childrenIds", "");

      if (children_ids.length <= 0) {
        const list = listButton.map((item) => item.description);

        children_ids = list.join("|");
      }

      can_search = children_ids.length > 0;

      set(filters, "customerFilter.path", path);
      set(filters, "customerFilter.childrenIds", children_ids);
    }
    filters.userType = user_profile.admin
      ? TransactionLabelsEnum.ADMIN
      : TransactionLabelsEnum.MERCHANT;

    if (can_search) {
      dispatchApp(
        searchMerchant({
          body: filters,
          isSecondSearch: false,
        })
      );
    }
  };

  const firstUpdate = useRef(true);

  useEffect(() => {
    setDisableDownloadByMerchantId();
  }, []);

  useEffect(() => {
    const filters: SearchTransactionRequest =
      builderSearchTransactionRequest(filterDashboard);

    if (
      firstUpdate.current ||
      (isEmpty(entityName) && !user_profile.admin) ||
      isEqual(filterRef.current, filters)
    ) {
      firstUpdate.current = false;

      return;
    }
    searchFilters(filters);
  }, [filterDashboard]);

  useEffect(() => {
    if (!isEmpty(retrieveChildren) && !isTransactionSearch) {
      const cloneFilterDashboard = { ...filterDashboard };

      if (selectCheckChipsChange) {
        dispatchApp(
          notifySelectCheckChipsChange({
            hasChange: false,
          })
        );
        setPage(0);
        cloneFilterDashboard.paginationFilters.offset = 0;
      }

      searchFilters(builderSearchTransactionRequest(cloneFilterDashboard));
    }
  }, [retrieveChildren]);

  useEffect(() => {
    if (!isEmpty(path)) {
      setHasFilters(false);
      setCountData(0);
      setFilterDashboard({
        ...initialFilters,
      });
    }
    setResetFilters(!resetFilters);
  }, [path, entityName]);

  useEffect(() => {
    setResetItemChecked(!resetItemChecked);
  }, [path, entityName, page]);

  useEffect(() => {
    if (!hasFilters) {
      setCountData(get(transactionData, "total.value", 0));
      setHasFilters(true);
    }
  }, [transactionData]);

  useEffect(() => {
    if (!isNil(selectItems)) {
      const branches: string = joinItemList(selectItems!, "|", "", true);

      setPage(0);
      setFilterDashboard({
        ...filterDashboard,
        customerFilter: {
          childrenIds: branches,
          path: nodeInfo.path,
        },
        paginationFilters: {
          ...filterDashboard.paginationFilters,
          offset: 0,
        },
      });

      setSubTitle(joinItemList(selectItems!, ", ", "."));
    }
  }, [selectItems]);

  return {
    countData,
    dataPicker: {
      handleData,
      headerCell: filterSelected(),
      items: selected,
    },
    downloadOptions: {
      disableDownload,
      handleSelect,
      loadingDownload: isLoadingDownload,
    },
    handleChangeFilterDetails,
    handleDownloadReceipt,
    handleSelectedRows,
    handleSortQuery,
    isApplyFilter,
    itemsSelected,
    modalDetailInfo: { isOpenModalDetailInfo, setIsOpenModalDetailInfo },
    paginationProps: {
      handleChangePage,
      handleChangeRowsPerPage,
      page,
      rowsPerPage,
      totalData,
    },
    rangeAmount: isApplyFilter ? rangeAmountInfo : undefined,
    resetFilters,
    rows,
    setIsApplyFilter,
    setRangeAmount: setRangeAmountInfo,
    setTransactionInfo,
    showEmptyScreen: isFiltering ? false : showEmptyScreen,
    subTitle,
    transactionInfo,
  };
};
