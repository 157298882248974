import React from "react";
import { PaymentMethodEnum } from "../enums/PaymentMethodEnum";
import { RefreshOutlined, Visibility } from "@mui/icons-material";
import { ModalDetailInfoStyles } from "../../components/ModalDetailInfo/ModalDetailInfo.styles";
import IconRefundSecondary from "../../assets/icons/IconRefundSecondary";
import {
  TransactionLabelsEnum,
  TransactionTabValueEnum,
} from "../enums/transactionLabelsEnum";
import {
  Button,
  IModalActions,
  IModalDetailInfo,
} from "../../components/ModalDetailInfo/ModalDetailInfo.interfaces";
import { TransactionInfo } from "../../../types/transactions_data";
import { validateTooltipVoid } from "../utils/tooltip_refund";
import {
  buildCardInformation,
  jsonToString,
  timeZoneHour,
} from "../utils/card_tabs_utils";
import {
  IconCircleCheck,
  IconClock,
  IconDownload,
  TimeLine,
} from "@kushki/connect-ui";
import { TransactionStatusEnum } from "../enums/TransactionStatusEnum";
import {
  transactionTypeEnum,
  TransactionTypeEnum,
} from "../enums/TransactionTypeEnum";
import { ITabInfo } from "../../components/GenericTabs/GenericTabs.interfaces";
import {
  CardInformation,
  CardTax,
} from "../../components/CardInformation/CardInformation";
import { CardInformationEnum } from "../enums/CardInformationEnum";
import { showMetadataCredentials } from "../utils/metaData_utils";
import {
  TimeLineCommonData,
  TimelineTrxData,
} from "../interfaces/TimeLine.interfaces";
import { GenericTabsStyles } from "../../components/GenericTabs/GenericTabs.styles";
import {
  renderSectionsTaxesByTransaction,
  renderTabsByTransaction,
} from "../utils/general_utils";
import { CircularProgress } from "@mui/material";
import { get, isEmpty } from "lodash";
import { auth } from "../auth";
import { isPosTransaction } from "../utils/transaction_table_utils";

const getRefundVoidPrimaryButton = (
  isDisabled: boolean,
  modalButtonActions: IModalActions,
  trx: TransactionInfo,
  text: string,
  tooltipTitle: string
): Button => {
  return {
    color: "secondary",
    isDisabled:
      isDisabled ||
      auth.isAdmin() ||
      isPosTransaction({
        _source: trx,
      }),
    onClick: () =>
      modalButtonActions.refundButton(
        {
          _source: trx,
        },
        trx.transaction_type!,
        trx.approved_transaction_amount!
      ),
    startIcon:
      isDisabled || auth.isAdmin() ? (
        <RefreshOutlined sx={ModalDetailInfoStyles.icon} />
      ) : (
        <IconRefundSecondary />
      ),
    text,
    tooltip: {
      disableHoverListener: false,
      titleTooltip: tooltipTitle,
    },
    variant: "outlined",
  };
};

const getDownloadSecondaryButton = (
  modalButtonActions: IModalActions,
  trx: TransactionInfo
): Button => {
  return {
    color: "primary",
    isDisabled: false,
    onClick: () => modalButtonActions.downloadButton(trx),
    startIcon: <IconDownload />,
    text: TransactionLabelsEnum.MODAL_DETAIL_BUTTON_SECONDARY,
    variant: "contained",
  };
};

const getTransactionPrimaryButton = (
  modalButtonActions: IModalActions,
  trx: TransactionInfo,
  isLoadingOriginalTrx: boolean,
  isDisableLoading: boolean
): Button => {
  return {
    color: "secondary",
    endIcon: <CircularProgress size={16} />,
    isDisabled: isDisableLoading,
    isLoading: isLoadingOriginalTrx,
    onClick: () => modalButtonActions.searchOriginalTrx(trx),
    startIcon: <Visibility />,
    text: TransactionLabelsEnum.MODAL_DETAIL_BUTTON_TRX_PRIMARY,
    variant: "outlined",
  };
};

export const modalHeaderButtons = (
  modalButtonActions: IModalActions,
  trx: TransactionInfo,
  isLoadingOriginalTrx: boolean,
  isDisableLoading: boolean
): Button[] => {
  if (isEmpty(get(trx, "merchant_id", ""))) return [];

  const conditionVoid: {
    child: {
      hasChild: boolean;
      typeChild: string;
      amount: number;
    };
    disable: boolean;
    message: string;
  } = validateTooltipVoid(
    {
      _source: trx,
    },
    timeZoneHour()
  );
  const text: string =
    conditionVoid.child.typeChild === transactionTypeEnum.refund
      ? TransactionLabelsEnum.MODAL_DETAIL_BUTTON_REFUND_PRIMARY
      : TransactionLabelsEnum.MODAL_DETAIL_BUTTON_VOID_PRIMARY;

  const approval_state = {
    [TransactionTypeEnum.SALE]: {
      [PaymentMethodEnum.CARD]: [
        getRefundVoidPrimaryButton(
          conditionVoid.disable,
          modalButtonActions,
          trx,
          text,
          conditionVoid.message
        ),
        getDownloadSecondaryButton(modalButtonActions, trx),
      ],
      [PaymentMethodEnum.TRANSFER]: [
        getRefundVoidPrimaryButton(
          conditionVoid.disable,
          modalButtonActions,
          trx,
          text,
          conditionVoid.message
        ),
        getDownloadSecondaryButton(modalButtonActions, trx),
      ],
      [PaymentMethodEnum.CASH]: [
        getRefundVoidPrimaryButton(
          conditionVoid.disable,
          modalButtonActions,
          trx,
          text,
          conditionVoid.message
        ),
      ],
    },
    [TransactionTypeEnum.CAPTURE]: {
      [PaymentMethodEnum.CARD]: [
        getRefundVoidPrimaryButton(
          conditionVoid.disable,
          modalButtonActions,
          trx,
          text,
          conditionVoid.message
        ),
        getDownloadSecondaryButton(modalButtonActions, trx),
      ],
    },
    [TransactionTypeEnum.PREAUTHORIZATION]: {
      [PaymentMethodEnum.CARD]: [
        getRefundVoidPrimaryButton(
          conditionVoid.disable,
          modalButtonActions,
          trx,
          text,
          conditionVoid.message
        ),
        getDownloadSecondaryButton(modalButtonActions, trx),
      ],
    },
    [TransactionTypeEnum.DEFFERRED]: {
      [PaymentMethodEnum.CARD]: [
        getRefundVoidPrimaryButton(
          conditionVoid.disable,
          modalButtonActions,
          trx,
          text,
          conditionVoid.message
        ),
        getDownloadSecondaryButton(modalButtonActions, trx),
      ],
    },
    [TransactionTypeEnum.VOID]: {
      [PaymentMethodEnum.CARD]: [
        getTransactionPrimaryButton(
          modalButtonActions,
          trx,
          isLoadingOriginalTrx,
          isDisableLoading
        ),
        getDownloadSecondaryButton(modalButtonActions, trx),
      ],
    },
    [TransactionTypeEnum.VOID_PARTIAL]: {
      [PaymentMethodEnum.CARD]: [
        getTransactionPrimaryButton(
          modalButtonActions,
          trx,
          isLoadingOriginalTrx,
          isDisableLoading
        ),
        getDownloadSecondaryButton(modalButtonActions, trx),
      ],
    },
    [TransactionTypeEnum.REFUND]: {
      [PaymentMethodEnum.CARD]: [
        getTransactionPrimaryButton(
          modalButtonActions,
          trx,
          isLoadingOriginalTrx,
          isDisableLoading
        ),
      ],
    },
    [TransactionTypeEnum.REFUND_TOTAL]: {
      [PaymentMethodEnum.CARD]: [
        getTransactionPrimaryButton(
          modalButtonActions,
          trx,
          isLoadingOriginalTrx,
          isDisableLoading
        ),
      ],
    },
    [TransactionTypeEnum.REFUND_PARTIAL]: {
      [PaymentMethodEnum.CARD]: [
        getTransactionPrimaryButton(
          modalButtonActions,
          trx,
          isLoadingOriginalTrx,
          isDisableLoading
        ),
      ],
    },
    [TransactionTypeEnum.REVERSE]: {
      [PaymentMethodEnum.CARD]: [
        getTransactionPrimaryButton(
          modalButtonActions,
          trx,
          isLoadingOriginalTrx,
          isDisableLoading
        ),
        getDownloadSecondaryButton(modalButtonActions, trx),
      ],
    },
  };

  const button = {
    [TransactionStatusEnum.APPROVAL]: approval_state,
    [TransactionStatusEnum.APPROVED]: approval_state,
    [TransactionStatusEnum.INITIALIZED]: {
      [TransactionTypeEnum.VOID]: {
        [PaymentMethodEnum.CARD]: [
          getTransactionPrimaryButton(
            modalButtonActions,
            trx,
            isLoadingOriginalTrx,
            isDisableLoading
          ),
        ],
        [PaymentMethodEnum.CASH]: [
          getTransactionPrimaryButton(
            modalButtonActions,
            trx,
            isLoadingOriginalTrx,
            isDisableLoading
          ),
        ],
      },
    },
    [TransactionStatusEnum.DECLINED]: {
      [TransactionTypeEnum.VOID]: {
        [PaymentMethodEnum.CASH]: [
          getTransactionPrimaryButton(
            modalButtonActions,
            trx,
            isLoadingOriginalTrx,
            isDisableLoading
          ),
          getDownloadSecondaryButton(modalButtonActions, trx),
        ],
      },
    },
  };

  if (
    !isEmpty(
      get(
        button,
        `${trx.transaction_status}.${[trx.transaction_type]}.${[
          trx.payment_method,
        ]}`,
        ""
      )
    )
  )
    return button[trx.transaction_status!][trx.transaction_type!][
      trx.payment_method!
    ];

  return [];
};

export const modalTabs = (
  props: IModalDetailInfo,
  availableSectionSummary: boolean,
  transactionInfoTimeLine: TimelineTrxData[],
  trx: TransactionInfo,
  trxinfo: TransactionInfo
): ITabInfo[] => {
  if (isEmpty(get(trx, "merchant_id", ""))) return [];

  const timeLineData: TimeLineCommonData = {
    iconCompleted: <IconCircleCheck color={"success"} fontSize={"large"} />,
    iconInCompleted: <IconClock fontSize={"large"} />,
    stylesDate: {
      align: "left",
      padding: "0 0 80px 0",
      width: "30%",
    },
    stylesIconIncomplete: {
      background: "#B4B9BE !important",
      borderRadius: "50%",
      fill: "white !important",
      padding: "2px",
    },
  };

  const defaultTabList: ITabInfo[] = [
    {
      content: availableSectionSummary ? (
        <CardInformation
          data={buildCardInformation(transactionInfoTimeLine, true)}
          columnDirection={true}
          transactionInfo={trxinfo}
        />
      ) : (
        <CardInformation
          data={buildCardInformation(trxinfo)}
          transactionInfo={trxinfo}
          taxSection={renderSectionsTaxesByTransaction(trxinfo, [
            {
              body: jsonToString(trxinfo).taxes,
            },
          ])}
        />
      ),
      defaultRender: true,
      label: TransactionLabelsEnum.MODAL_DETAIL_TAB_1,
      sx: GenericTabsStyles.boxTabs,
      type: TransactionTabValueEnum.INFORMATION,
      variant: "primary",
    },
    {
      content: (
        <TimeLine
          handlerSearchTrxParent={props.handlerSearchTrxParent}
          isLoading={props.isLoadingTransaction}
          iconCompleted={timeLineData.iconCompleted}
          iconIncomplete={timeLineData.iconInCompleted}
          stylesDate={timeLineData.stylesDate}
          stylesIconIncomplete={timeLineData.stylesIconIncomplete}
          stylesSeparator={{ background: "#B4B9BE" }}
          timeZoneRegion={timeZoneHour()}
          timelineTrxData={transactionInfoTimeLine}
          textButton={CardInformationEnum.SEE}
        />
      ),
      defaultRender: false,
      label: TransactionLabelsEnum.MODAL_DETAIL_TAB_2,
      sx: GenericTabsStyles.boxTabsNoMargin,
      type: TransactionTabValueEnum.TIME_LINE,
      variant: "primary",
    },
    {
      content: !availableSectionSummary ? (
        <CardTax
          data={showMetadataCredentials(
            jsonToString(trxinfo).metadataCredentials,
            trxinfo
          )}
        />
      ) : (
        <CardTax
          data={showMetadataCredentials(
            jsonToString(transactionInfoTimeLine).metadataCredentials,
            transactionInfoTimeLine
          )}
        />
      ),
      defaultRender: false,
      label: TransactionLabelsEnum.MODAL_DETAIL_TAB_3,
      sx: GenericTabsStyles.boxTabs,
      type: TransactionTabValueEnum.METADATA,
      variant: "primary",
    },
  ];

  return renderTabsByTransaction(trx, defaultTabList);
};
