import React from "react";
import { Box, Grid, Link, Typography } from "@mui/material";
import { CardEntityName } from "../CardEntity/CardEntityName";
import { useStyles } from "../EntityStyles/EntityStyles";
import { MerchantNodeData } from "../../../../types/search_merchant_response";
import { BranchItem } from "./BranchItem";
import { useNavigate } from "react-router-dom";

export interface IBranchEntity {
  nodeIdOwner: string;
  nodeIdCustomer: string;
  selected: boolean;
  data: MerchantNodeData[];
  total: number;
}

export const BranchEntity: React.FC<IBranchEntity> = ({
  nodeIdOwner,
  nodeIdCustomer,
  selected,
  data,
  total,
}: IBranchEntity) => {
  let navigate = useNavigate();
  const styles = useStyles();

  return (
    <CardEntityName selected={selected}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        columns={2}
      >
        <Typography className={styles.primaryTitle} variant="inherit">
          Branch
        </Typography>
        {!selected && (
          <Link
            data-testid="show-all-branch"
            component="button"
            onClick={() => {
              navigate(`/${nodeIdOwner}/customers/${nodeIdCustomer}`);
            }}
            className={styles.link}
          >
            {`Ver todo (${total})`}
          </Link>
        )}
      </Grid>
      <Box sx={{ paddingBottom: "10px", paddingTop: "10px" }}>
        <Typography className={styles.text} variant="inherit">
          Recientes:
        </Typography>
      </Box>
      {data.map((item, index) => (
        <BranchItem key={index} branch={item} />
      ))}
    </CardEntityName>
  );
};
