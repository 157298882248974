export enum FileStatusDownload {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export const FILE_STATUS_TEXT_ENUM: Record<FileStatusDownload, string> = {
  [FileStatusDownload.PENDING]: "Pendiente",
  [FileStatusDownload.COMPLETED]: "Exitoso",
  [FileStatusDownload.FAILED]: "Fallido",
};
