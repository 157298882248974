import { FC } from "react";
import { Chip, ChipProps, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  TRANSACTION_STATUS_TRANSLATE,
  TransactionStatus,
} from "../../shared/infrastructure/constants/TransactionStatus";
import { ColorsEnum } from "../../shared/enums/colors_enum";

export interface TransactionTooltipProps extends ChipProps {
  status: string | undefined;
  message: string;
  disableHover: boolean;
}

const useStyles = makeStyles(() => ({
  chip: {
    "&.MuiChip-root": {
      backgroundColor: (props: TransactionTooltipProps) => {
        switch (props.status?.toUpperCase()) {
          case TransactionStatus.DECLINED:
            return "#FFEAEE";
          case TransactionStatus.APPROVAL:
          case TransactionStatus.APPROVED:
            return "#E0FFF8";
          default:
            return ColorsEnum.DISABLED;
        }
      },
      color: (props: TransactionTooltipProps) => {
        switch (props.status?.toUpperCase()) {
          case TransactionStatus.DECLINED:
            return "#AD0C2A";
          case TransactionStatus.APPROVAL:
          case TransactionStatus.APPROVED:
            return "#0DC298";
          default:
            return "grey.A700";
        }
      },
    },
    borderRadius: "4px",

    fontSize: "9px",
  },
}));

export const TransactionTooltip: FC<TransactionTooltipProps> = (
  props: TransactionTooltipProps
) => {
  const classes = useStyles(props);

  return (
    <>
      <Tooltip disableHoverListener={props.disableHover} title={props.message}>
        <Chip
          label={TRANSACTION_STATUS_TRANSLATE[props.status!.toUpperCase()]}
          className={classes.chip}
          sx={{ fontWeight: 600 }}
          {...props}
        />
      </Tooltip>
    </>
  );
};
