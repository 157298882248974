import React, { FC, SyntheticEvent } from "react";
import { Autocomplete, AutocompleteValue, TextField } from "@mui/material";
import { IInputAutocompleteProps } from "@components/InputsForm/Inputs/InputAutocomplete/InputAutocomplete.interfaces";
import { ISelectOption } from "@components/InputsForm/Inputs/InputSelect/InputSelect.interfaces";
import { get } from "lodash";
import { inputSelectStyles as styles } from "@components/InputsForm/Inputs/InputSelect/InputSelect.styles";
import { AutcompleteEnum } from "@shared/enum/AutocompleteEnum";

const InputAutocomplete: FC<IInputAutocompleteProps> = ({
  options,
  label,
  error,
  field,
}: IInputAutocompleteProps) => {
  const getValue = (id: string) => {
    return options.find((opt) => id === opt.value);
  };

  return (
    <Autocomplete
      {...field}
      key={field.value}
      value={getValue(field.value)}
      options={options}
      noOptionsText={AutcompleteEnum.EMPTY}
      onChange={(
        _: SyntheticEvent,
        newValue: AutocompleteValue<ISelectOption | string, any, any, any>
      ) => {
        field.onChange(get(newValue, "value", ""));
      }}
      sx={styles.input}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          helperText={error ? error.message : ""}
          error={!!error}
        />
      )}
    />
  );
};

export default InputAutocomplete;
