import { CustomStyles } from "../../../shared/infrastructure/interfaces/CustomStyles";

export const styles: CustomStyles = {
  childrenContainer: {
    padding: 1,
    paddingTop: 0,
  },
  mainDataContainer: {
    width: "100%",
  },

  headerContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  flexContainer: {
    display: "flex",
    padding: 1,
    paddingBottom: 0,
    alignItems: "center",
    justifyContent: "space-between",
  },
  boxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px",
  },
  boxContainerWalletButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px",
    flexGrow: 1,
  },
  boxContainerUser: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px",
  },
  editButton: {
    color: "primary.main",
    cursor: "default",
    justifyContent: "flex-start",
    padding: 0,
    border: "none !important",
    "&:hover": {
      backgroundColor: "#F7FAFC",
      color: "primary.main",
      boxShadow: "none !important",
      border: "none !important",
    },
  },
  editTextButton: {
    alignItems: "flex-start",
    backgroundColor: "none",
    padding: 0,
    width: "15rem",
    height: "1rem",
    marginBottom: "1.5rem",
    transition: "none",
    WebkitTransition: "none",
    "&:hover": {
      backgroundColor: "none !important",
      boxShadow: "none",
    },
  },
  iconEdit: {
    cursor: "pointer",
  },
  linkButton: {
    fontSize: "14px",
    fontWeight: "500",
    width: "max-content",
    justifyContent: "flex-start",
  },
  normalBox: {
    padding: "0px 0px 0px 16px",
    display: "flex",
  },
  flexContainerAndChip: {
    alignItems: "center",
    display: "flex",
    padding: 1,
  },
  typographyWallet: {
    fontWeight: "light",
  },
};
