import React from "react";
import { Box, Divider, Grid, Tooltip, Typography } from "@mui/material";
import { walletSectionStyles as styles } from "./WalletSection.styles";
import { InfoOutlined } from "@mui/icons-material";
import {
  WalletDetailTitleEnum,
  WalletDetailTooltipTextEnum,
} from "../../shared/infrastructure/constants/WalletResumeConstants";
import { CURRENCY_COUNTRY } from "../../shared/utils/currencyFormat";
import { defaultTo } from "lodash";
import { IWalletData, IWalletDetail } from "./WalletSection.interface";
import { formatCurrencyWallet } from "../../shared/utils/walletUtils";

const WalletDetail: React.FC<IWalletDetail> = ({
  title,
  tooltipText,
  value,
  currency,
}: IWalletDetail) => {
  return (
    <Grid container item spacing={1} sx={styles.containerGrid}>
      <Grid item sx={styles.itemContainer}>
        <Tooltip title={tooltipText} placement={"top"} arrow>
          <InfoOutlined fontSize="small"></InfoOutlined>
        </Tooltip>
      </Grid>
      <Grid item sx={styles.itemContainer}>
        <Typography variant="h6" display={"flex"} flexDirection={"row"}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {value} {currency}
        </Typography>
      </Grid>
    </Grid>
  );
};

const WalletSection: React.FC<IWalletData> = ({
  balanceData,
  country,
}: IWalletData) => {
  return (
    <Box sx={styles.container}>
      <WalletDetail
        title={WalletDetailTitleEnum.BALANCE}
        tooltipText={WalletDetailTooltipTextEnum.BALANCE}
        value={formatCurrencyWallet(defaultTo(balanceData.balance, 0), country)}
        currency={defaultTo(CURRENCY_COUNTRY.get(country), "")}
      />
      <Divider orientation="vertical" flexItem />
      <WalletDetail
        title={WalletDetailTitleEnum.COUNTABLE_BALANCE}
        tooltipText={WalletDetailTooltipTextEnum.COUNTABLE_BALANCE}
        value={formatCurrencyWallet(
          defaultTo(balanceData.countableBalance, 0),
          country
        )}
        currency={defaultTo(CURRENCY_COUNTRY.get(country), "")}
      />
      <Divider orientation="vertical" flexItem />
      <WalletDetail
        title={WalletDetailTitleEnum.UNPROCESSED_BALANCE}
        tooltipText={WalletDetailTooltipTextEnum.UNPROCESSED_BALANCE}
        value={formatCurrencyWallet(
          defaultTo(balanceData.unprocessedBalance, 0),
          country
        )}
        currency={defaultTo(CURRENCY_COUNTRY.get(country), "")}
      />
    </Box>
  );
};

export default WalletSection;
