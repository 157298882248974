import React, { ChangeEvent, DragEvent, useEffect, useState } from "react";
import {
  IErrorUploadExecutives,
  IFileUploadExecutives,
} from "../FileUploadExecutives.interfaces";
import { IUseFileUploadExecutives } from "./useFileUploadExecutives.interfaces";
import {
  FileExecutiveStatusEnum,
  MessageErrorExecutive,
} from "@shared/enum/FileUploadExecutivesEnum";
import { DragTypeEnum } from "@shared/enum/FileUploadEnum";
import {
  errorUploadingFileInitExecutive,
  fileSelectedInitExecutive,
  FileValidationExecutive,
} from "@shared/constants/FileUploadExecutives";
import { useAppDispatch, useAppSelector } from "@store/hooks/storeHook";
import { uploadingFileDelay } from "@shared/utils/uploadFileDelay";
import {
  setBase64File,
  setFileValidated,
} from "@store/reducers/executives/executives.slice";
import { getBase64File } from "@shared/utils/getBase64File";
import { validateCommercialExecutives } from "@store/thunks/executives/executives.thunk";

export const useFileUploadExecutives = (): IUseFileUploadExecutives => {
  const [dragActive, setDragActive] = React.useState(false);
  const [fileSelected, setFileSelected] = useState<IFileUploadExecutives>(
    fileSelectedInitExecutive
  );
  const [fileStatus, setFileStatus] = useState<string>(
    FileExecutiveStatusEnum.DEFAULT
  );
  const [errorUploadingFile, setErrorUploadingFile] =
    useState<IErrorUploadExecutives>(errorUploadingFileInitExecutive);
  const [showValidateBtn, setShowValidateBtn] = useState(false);

  const dispatch = useAppDispatch();
  const { base64File, fileValidated } = useAppSelector(
    (state) => state.executives
  );

  const changeUploadFileProcess = async (file: File) => {
    setErrorUploadingFile(errorUploadingFileInitExecutive);
    setFileStatus(FileExecutiveStatusEnum.UPLOADING);
    await uploadingFileDelay(1000);

    const type = file.type.split("/")[1];
    const size = file.size / 1000000;
    const name = file.name;

    if (type !== FileValidationExecutive.TYPE) {
      setErrorUploadingFile({
        errorFile: true,
        message: MessageErrorExecutive.TYPE,
      });

      return setFileStatus(FileExecutiveStatusEnum.DEFAULT);
    } else if (size > FileValidationExecutive.MAX_SIZE) {
      setErrorUploadingFile({
        errorFile: true,
        message: MessageErrorExecutive.MAX_SIZE,
      });

      return setFileStatus(FileExecutiveStatusEnum.DEFAULT);
    }

    setFileSelected({ name, size, type });
    getBase64File(file, dispatch);
    setFileStatus(FileExecutiveStatusEnum.WITH_FILE);
    setErrorUploadingFile({ errorFile: false, message: "" });
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!e.target.files || !e.target.files[0]) {
      setFileStatus(FileExecutiveStatusEnum.DEFAULT);

      return setFileSelected(fileSelectedInitExecutive);
    }
    const file = e.target.files[0];

    await changeUploadFileProcess(file);
  };

  const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (!e.dataTransfer.files || !e.dataTransfer.files[0]) {
      setFileStatus(FileExecutiveStatusEnum.DEFAULT);

      return setFileSelected(fileSelectedInitExecutive);
    }
    const file = e.dataTransfer.files[0];

    await changeUploadFileProcess(file);
  };

  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === DragTypeEnum.ENTER || e.type === DragTypeEnum.OVER) {
      setDragActive(true);
    } else if (e.type === DragTypeEnum.LEAVE) {
      setDragActive(false);
    }
  };

  const handleCloseFile = () => {
    setFileStatus(FileExecutiveStatusEnum.DEFAULT);
    dispatch(setBase64File(""));
    dispatch(setFileValidated(null));
  };
  const handleValidateFile = () => {
    dispatch(validateCommercialExecutives({ file: base64File }));
    setFileStatus(FileExecutiveStatusEnum.VALIDATING);
  };

  useEffect(() => {
    setShowValidateBtn(
      errorUploadingFile.errorFile === false &&
        fileStatus === FileExecutiveStatusEnum.WITH_FILE
    );
  }, [fileStatus, fileValidated, errorUploadingFile]);

  useEffect(() => {
    if (fileValidated) setFileStatus(FileExecutiveStatusEnum.WITH_FILE);
    else if (fileValidated === false) {
      setFileStatus(FileExecutiveStatusEnum.DEFAULT);
      setErrorUploadingFile({
        errorFile: true,
        message: MessageErrorExecutive.VALIDATION,
      });
    }
  }, [fileValidated]);

  return {
    handlers: {
      handleChange,
      handleCloseFile,
      handleDrag,
      handleDrop,
      handleValidateFile,
    },
    state: {
      dragActive,
      errorUploadingFile,
      fileSelected,
      fileStatus,
      showValidateBtn,
    },
  };
};
