export enum SnackbarEnum {
  REQUEST_ERROR = "Ha ocurrido un error al momento de crear un Owner. Inténtalo nuevamente.",
  REQUEST_SUCCESS = "Owner creado con éxito. Puedes visualizarlo en la sección Clientes.",
  BUTTON_TEXT = "Entiendo",
  CHANGE_STATUS_SUCCESS = "$entity $status con éxito",
  CHANGE_STATUS_FAILED = "Ha ocurrido un error al $status los $entitys. Inténtalo nuevamente.",
}

export enum NotificationTypeEnum {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}
