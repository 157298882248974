import { FormControl, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Controller } from "react-hook-form";
import React, { FC } from "react";
import { IInputsFormProps } from "../InputsFormProps.interfaces";
import { toggleInputStyles } from "./ToggleInput.styles";

const ToggleInput: FC<IInputsFormProps> = ({
  name,
  control,
  defaultValue = "",
  onBlur,
  items,
  onChange,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue as string}
      render={({ field }) => (
        <FormControl component="fieldset" fullWidth>
          <ToggleButtonGroup
            {...field}
            onChange={(event) => {
              onChange && onChange(event);
              field.onChange(event);
            }}
            onBlurCapture={onBlur}
            color="primary"
            sx={toggleInputStyles.toggleInputDefaultStyles}
            exclusive
          >
            {items!.map((item, i) => (
              <ToggleButton
                key={`${name}-${i}`}
                value={item.value}
                sx={toggleInputStyles.toggleButton}
              >
                {item.text}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </FormControl>
      )}
    />
  );
};

export default ToggleInput;
