import React, { useEffect, useState } from "react";
import {
  Paper,
  Card,
  CardContent,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  Container,
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  FormControl,
  FormGroup,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IAppState } from "../../store/reducer";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  getWebhookNotify,
  IAppAction,
  setWebhookNotify,
} from "../../store/actionCreators";
import { useHistory, useParams } from "react-router-dom";
import { INotificationState } from "../../shared/infrastructure/interfaces/INotificationState";
import { defaultTo } from "lodash";
import { INotificationResponse } from "../../shared/infrastructure/interfaces/INotificationResponse";
import { SecurityWrapperEnum } from "../../shared/infrastructure/constants/SecurityWrapperEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

const useStyles = makeStyles(() => ({
  indexTitle: {
    color: "#023365",
    marginBottom: 5,
    paddingTop: 5,
    paddingBottom: 24,
    fontStyle: "normal",
    fontWeight: 200,
    fontSize: "40px",
  },
  labelTitle: {
    color: "#023365",
    fontSize: "20px",
    fontWeight: 500,
    fontStyle: "normal",
  },
  labelTitle2: {
    color: "#293036",
    fontSize: "14px",
    fontWeight: 510,
    fontStyle: "normal",
  },
  labelTitle3: {
    color: "#6D7781",
    fontSize: "14px",
    fontStyle: "normal",
  },
  loading: {
    marginLeft: "8px",
  },
  root: {
    display: "flex",
  },
}));

export interface NotificationsIndexStateProps {
  webCheckout?: INotificationResponse;
  isLoading?: boolean;
  loadingConfig?: boolean;
}

export interface NotificationsIndexFunctionProps {
  getWebhookNotify?: (merchantId: string) => void;
  setWebhookNotify?: (
    merchantId: string,
    sendNotify: INotificationState
  ) => void;
}

export type NotificationsIndexProps = NotificationsIndexStateProps &
  NotificationsIndexFunctionProps;

export const NotificationsIndex: React.FC<NotificationsIndexProps> = (
  props: NotificationsIndexProps
) => {
  const { merchantId } = useParams<{ merchantId: string }>();
  const history = useHistory();
  const style = useStyles();
  // const [notificationsActive, setNotificationsActive] = useState<boolean>(
  //   get(props.merchant, "notifications", false)
  // );
  const [
    notificationsState,
    setNotificationsState,
  ] = useState<INotificationState>({
    Webcheckout: false,
    API: false,
    WebcheckoutPlus: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotificationsState({
      ...notificationsState,
      [event.target.name]: event.target.checked,
    });
  };
  const { WebcheckoutPlus, API, Webcheckout } = notificationsState;

  useEffect(() => {
    props.getWebhookNotify!(merchantId);
  }, []);
  useEffect(() => {
    if (props.webCheckout) {
      setNotificationOptions(props.webCheckout.sendEmailNotification);
    }
  }, [props.webCheckout]);

  const setNotificationOptions = (data: string[] | boolean) => {
    if (typeof data === "boolean")
      setNotificationsState({
        API: data,
        WebcheckoutPlus: data,
        Webcheckout: data,
      });
    else {
      const notifications = { ...notificationsState };
      data.map((item: string) => {
        notifications[item] = true;
      });
      setNotificationsState({ ...notifications });
    }
  };

  const continueHandler = () => {
    props.setWebhookNotify!(defaultTo(merchantId, ""), notificationsState);
  };

  const returnHandler = () => {
    history.goBack();
  };

  return (
    <React.Fragment>
      <Container fixed>
        <Breadcrumbs>
          <Typography
            variant="h5"
            color="primary"
            style={{ color: "#023365", marginBottom: 5 }}
          >
            <Link>Mi comercio</Link>
          </Typography>
          <Typography
            variant="h5"
            color="primary"
            style={{ color: "#023365", marginBottom: 5 }}
          >
            <Link>Notificaciones para tus clientes</Link>
          </Typography>
        </Breadcrumbs>

        <Typography className={style.indexTitle}>
          Notificaciones para tus clientes
        </Typography>

        <Paper>
          <Card>
            <CardContent style={{ paddingRight: 90, paddingBottom: 30 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className={style.labelTitle}>
                    Envío de recibo para transacciones exitosas
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    paddingTop: 16,
                    paddingBottom: 8,
                  }}
                >
                  <Typography variant="h6">
                    Quiero que Kushki envíe a mis clientes un correo electrónico
                    con el comprobante de pago.
                  </Typography>
                </Grid>
                {!props.loadingConfig ? (
                  <div className={style.root}>
                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="Webcheckout"
                              color="primary"
                              checked={Webcheckout}
                              onChange={handleChange}
                            />
                          }
                          label={
                            <Typography className={style.labelTitle2}>
                              Smartlinks
                            </Typography>
                          }
                        />
                        <Typography className={style.labelTitle3}>
                          Mostraremos un campo de email en el formulario de pago
                          de tus smartlinks. Enviaremos el recibo al email
                          designado por tu cliente en este campo.
                        </Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="API"
                              color="primary"
                              checked={API}
                              onChange={handleChange}
                            />
                          }
                          label={
                            <Typography className={style.labelTitle2}>
                              API
                            </Typography>
                          }
                        />
                        <Typography className={style.labelTitle3}>
                          Enviaremos el recibo al email que nos envíes en la
                          información de detalle de contacto por el API.
                          Asegúrate de conseguir un e-mail válido donde podamos
                          enviar el recibo.{" "}
                          <strong>
                            Esta opción aplica para la integración de Cajita
                            Kushki.
                          </strong>
                        </Typography>
                        <Typography
                          className={style.labelTitle2}
                          style={{ paddingTop: 8 }}
                        >
                          Plugins:
                        </Typography>
                        <Typography
                          className={style.labelTitle3}
                          style={{ paddingBottom: 24 }}
                        >
                          Enviaremos el recibo al email designado por el cliente
                          en su sesión de usuario o información de checkout.
                          Aplica para plugins de WooComerce, Vtex, Magento y
                          Prestashop.
                        </Typography>
                        <FormControlLabel
                          id="webCheckoutPlus"
                          control={
                            <Checkbox
                              name="WebcheckoutPlus"
                              color="primary"
                              checked={WebcheckoutPlus}
                              onChange={handleChange}
                            />
                          }
                          label={
                            <Typography className={style.labelTitle2}>
                              Botón de Pagos
                            </Typography>
                          }
                        />
                        <Typography className={style.labelTitle3}>
                          Enviaremos el recibo al email designado por el cliente
                          en su sesión de usuario o información de checkout.
                          Asegúrate de conseguir un e-mail válido donde podamos
                          enviar el recibo.
                        </Typography>
                      </FormGroup>
                    </FormControl>
                  </div>
                ) : (
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                      <CircularProgress color="primary" size={150} />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Paper>

        <Box display={"flex"} justifyContent={"flex-end"} pt={2}>
          <Box px={1}>
            <Button
              id="returnBtn"
              onClick={returnHandler}
              disabled={props.isLoading}
            >
              Regresar
            </Button>
          </Box>
          <Box px={1}>
            <ComponentSecurityWrapper
              componentId={SecurityWrapperEnum.SAVE_NOTIFICATIONS}
            >
              <Button
                id="continueBtn"
                variant="contained"
                color="primary"
                disabled={props.isLoading || props.loadingConfig}
                onClick={continueHandler}
              >
                Guardar
                {(props.isLoading || props.loadingConfig) && (
                  <CircularProgress
                    className={style.loading}
                    color="secondary"
                    size={16}
                  />
                )}
              </Button>
            </ComponentSecurityWrapper>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export const mapStateToProps: (state: IAppState) => IAppState = (
  state: IAppState
): IAppState => ({
  ...state,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => NotificationsIndexFunctionProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): NotificationsIndexFunctionProps => ({
  getWebhookNotify: (merchantId: string): void =>
    dispatch(getWebhookNotify(merchantId)),
  setWebhookNotify: (
    merchantId: string,
    sendNotify: INotificationState
  ): void => dispatch(setWebhookNotify(merchantId, sendNotify)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsIndex);
