/* istanbul ignore file */
import React from "react";
import {
  Card,
  CardContent,
  createStyles,
  Grid,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useWatch } from "react-hook-form";
import ConnectForm from "../commons/ConnectForm/ConnectForm";
import { useTypedController } from "@hookform/strictly-typed";
import { TWebhookForm } from "../../containers/WebhookFormIndex/state/useWebhookForm";
import { TextForm } from "../TextForm/TextForm";
import { PaymentsUrl } from "../PaymentUrl/PaymentUrl";
import { PaymentMethodCheckList } from "../PaymentMethodCheckList/PaymentMethodCheckList";
import { WebhookEventsCheckList } from "../WebhookEventsCheckList/WebhookEventsCheckList";
import { Info } from "react-feather";
import { ISelectedProduct } from "../../shared/infrastructure/interfaces/ISelectedProduct";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    events: {
      fontSize: "12px",
      color: "#AD0C2A",
    },
    chip: {
      margin: 2,
      backgroundColor: "#F7FAFC",
      borderRadius: "4px",
      color: "#293036",
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
    rowContent: {
      padding: 8,
    },
    itemStyle: {
      backgroundColor: "#F7FAFC",
      padding: theme.spacing(1),
      color: "#6D7781",
    },
    notifyText: {
      fontSize: "12px",
      weight: 400,
      color: "#6D7781",
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    iconItem: {
      maxWidth: "30px",
    },
    urlSection: {
      marginTop: "16px",
    },
  })
);

export const WebhookBody: React.FC = () => {
  const classes = useStyles();

  return (
    <ConnectForm<TWebhookForm>>
      {({ control, errors, getErrorMessage }) => {
        const TypedController = useTypedController<TWebhookForm>({
          control: control,
        });

        const selectedOption = useWatch({ name: "selectedOption" }) as boolean;
        const events: ISelectedProduct[] = useWatch({
          name: "events",
          defaultValue: [],
        });

        return (
          <Grid container spacing={3}>
            <Grid item xs={12} md={3} lg={3}>
              <Typography variant={"h5"} color="primary" gutterBottom>
                Webhook
              </Typography>

              <Typography
                variant={"body2"}
                color={"textSecondary"}
                gutterBottom
              >
                Agrega las URLs que desees para la notificación de eventos por
                cada método de pago o producto.
              </Typography>
            </Grid>
            <Grid item xs={12} md={9} lg={9}>
              <Card>
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h6" color="primary">
                        Identificación de webhook
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TypedController
                        name={"alias"}
                        rules={{ required: true }}
                        render={(props) => (
                          <TextField
                            {...props}
                            id="alias"
                            label="Alias"
                            variant="outlined"
                            required
                            error={!!errors.alias}
                            fullWidth
                            margin="normal"
                            helperText={
                              !!errors.alias && getErrorMessage(errors.alias)
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" color="primary">
                        Medios de pago
                      </Typography>
                    </Grid>

                    <PaymentMethodCheckList />
                    <WebhookEventsCheckList />

                    {events.length < 1 && (
                      <Grid item xs={12}>
                        <Typography className={classes.events}>
                          Es necesario seleccionar al menos un evento para
                          configurar el webhook.
                        </Typography>
                      </Grid>
                    )}

                    <Grid
                      container
                      item
                      xs={12}
                      spacing={1}
                      alignItems="center"
                      className={classes.urlSection}
                    >
                      <Grid item xs={6}>
                        <Typography variant="h6" color="primary" gutterBottom>
                          Agregar URLs
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="subtitle2">
                        Agrega los URL que apliquen al webhook:
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {selectedOption ? <TextForm /> : <PaymentsUrl />}
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid className={classes.iconItem} item xs>
                        <Info size={18} color="#6D7781" />
                      </Grid>
                      <br />
                      <Grid item xs>
                        <Typography className={classes.notifyText}>
                          Enviaremos una notificacion. Si esta es rechazada,
                          intentaremos nuevamente en intervalos de 30 minutos y
                          máximo de 4 veces. Podrás ver el detalle de cada
                          evento en tu log de webhook.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        );
      }}
    </ConnectForm>
  );
};
