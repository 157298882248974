import React from "react";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ratesInfoStyles as style } from "./RatesInfo.styles";
import { TableNodesStyle } from "../../components/Table/TableNodes/TableNodes.styles";
import {
  TABLE_HEAD_DISABLE_SX,
  TableHeadStyles,
} from "../../components/Table/TableHead/TableHead.style";
import { TableBodyStyles } from "../../components/Table/TableBody/TableBody.style";
import { HeadCell } from "../../components/Table/TableHead/TableHead.interfaces";
import { RatesTableRow } from "./RatesTable/RatesTableRow";
import { RatesConfigDynamo } from "../../../types/rates_config_dynamo";
import { displayUtils } from "../../shared/utils/DisplayUtils";
import { TableSkeleton } from "../../components/Skeleton/TableSkeleton/TableSkeleton";
import {
  CATALOG_HEADS,
  TableTypeEnum,
} from "../../shared/catalogs/CatalogConfigTable";
import { CountriesEnum } from "../../shared/enums/CountriesEnum";
import { MerchantBillingInfo } from "../../../types/merchant_billing_info";
import { MinAmount } from "./RatesTable/MinAmount/MinAmount";

export interface IRatesInfoInterface {
  ratesInfo: RatesConfigDynamo[];
  isLoadingRates: boolean;
}

export interface RatesInfoProps {
  ratesProps: IRatesInfoInterface;
  country?: CountriesEnum;
  merchantInfo?: MerchantBillingInfo;
}

export const RatesInfo: React.FC<RatesInfoProps> = (props: RatesInfoProps) => {
  const {
    ratesProps: { isLoadingRates, ratesInfo },
    country,
    merchantInfo,
  } = props;

  const headTable: HeadCell[] = [
    { disablePadding: false, id: "1", label: "Fecha de creación" },
    { disablePadding: false, id: "2", label: "Medio de pago / Servicio" },
    { disablePadding: false, id: "3", label: "Monto fijo" },
    { disablePadding: false, id: "4", label: "Variable" },
    { disablePadding: false, id: "5", label: "Monto mín." },
    { disablePadding: false, id: "6", label: "Monto máx." },
    { disablePadding: false, id: "7", label: "" },
  ];
  const EnhancedTableHead = (props: any) => {
    const { headCells } = props;

    return (
      <TableHead sx={TABLE_HEAD_DISABLE_SX[`${!!false}`]}>
        <TableRow sx={TableHeadStyles.row}>
          {headCells.map((headCell: any) => (
            <TableCell
              sx={TableHeadStyles.label}
              key={headCell.id}
              align={"center"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  return (
    <>
      <Box display={displayUtils(!isLoadingRates)}>
        <TableSkeleton
          headTable={CATALOG_HEADS[TableTypeEnum.BRANCH]}
          rowsPerPage={5}
        />
      </Box>
      <Box
        display={displayUtils(isLoadingRates)}
        sx={{ overflowX: "scroll", overflowY: "hidden" }}
      >
        <Grid container spacing={3} sx={style.contentGrid}>
          <Box sx={{ width: "100%" }}>
            <TableContainer data-testid="Table-Container">
              <Box>
                <Table sx={TableNodesStyle.table} aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    headCells={headTable}
                    sx={TableHeadStyles}
                  />

                  <TableBody sx={TableBodyStyles.body}>
                    {ratesInfo.map((rate: RatesConfigDynamo, index: number) => (
                      <RatesTableRow
                        key={rate.id}
                        id={rate.id}
                        index={index}
                        columns={headTable.length}
                        country={country as CountriesEnum}
                        rate={rate}
                        merchantInfo={merchantInfo}
                        currency={rate.currency ?? ""}
                      />
                    ))}
                  </TableBody>
                </Table>
                <Grid item sm={12} sx={TableBodyStyles.minCommissionContainer}>
                  <MinAmount merchantInfo={merchantInfo} />
                </Grid>
              </Box>
            </TableContainer>
          </Box>
        </Grid>
      </Box>
    </>
  );
};
