import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { ChromePicker } from "react-color";
import { useStyleKshColorPicker } from "./styles/KshColorPickerStyles";

export interface KshColorPickerProps {
  prop: string;
  label: string;
  color: string;
  setValueHandler: (value: string, kind: string) => void;
  selectedPicker: string | undefined;
  setSelectedPicker: (value: string) => void;
}

export const KshColorPicker: React.FC<KshColorPickerProps> = (
  props: KshColorPickerProps
) => {
  const styles = useStyleKshColorPicker();
  const closeColorPicker = () => {
    props.setSelectedPicker("");
  };
  const showPickerHandler = () => {
    props.setSelectedPicker(props.prop);
  };

  return (
    <React.Fragment>
      <Typography className={styles.title}>{props.label}</Typography>
      <Button
        className={styles.buttonColorPicker}
        id={props.prop}
        onClick={showPickerHandler}
      >
        <Box display={"flex"} style={{ width: "100%", height: "100%" }}>
          <div
            className={styles.boxColor}
            style={{
              background: props.color,
            }}
          />
          <Typography align="left" className={styles.buttonColorPickerLabel}>
            {props.color.toUpperCase()}
          </Typography>
        </Box>
      </Button>
      {props.selectedPicker === props.prop ? (
        <div>
          <div className={styles.popover}>
            <div
              id="coverDiv"
              className={styles.cover}
              onClick={closeColorPicker}
            />
          </div>
          <div style={{ position: "absolute", zIndex: 1000 }}>
            <ChromePicker
              color={props.color}
              onChange={(color) => {
                props.setValueHandler(color.hex, props.prop);
              }}
            />
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};
