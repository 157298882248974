import React, { FC } from "react";
import { Box } from "@mui/material";
import MenuItem from "../MenuItem/MenuItem";
import { useSideBar } from "./useState/useSideBar";
import { sideBarStyles } from "./SideBar.styles";

const SideBar: FC = () => {
  const { showSideBar } = useSideBar();

  return (
    <>
      {showSideBar ? (
        <Box sx={sideBarStyles.sideBar} data-testid={"SideBar"}>
          <MenuItem />
        </Box>
      ) : null}
    </>
  );
};

export default SideBar;
