import {
  ITimeLineRowBlockState,
  TimelineStateProps,
} from "./TimeLineRowBlock.interfaces";
import { TimelineIconEnum } from "../TabContentBlock.interfaces";
import { useState } from "react";

export const useTimelineState = (
  props: TimelineStateProps
): ITimeLineRowBlockState => {
  const { icon, user } = props;

  const titles = {
    [TimelineIconEnum.DCLRFN]: `Reembolso no autorizado por ${user}`,
    [TimelineIconEnum.APRRFN_DECLINED]: `Reembolso declinado por ${user}`,
    [TimelineIconEnum.OMT]: `Transacción omitida por ${user}`,
    [TimelineIconEnum.GBCK]: `Acción devolver por ${user}`,
  };
  const titleDialog = titles[icon];

  const dateTitles = {
    [TimelineIconEnum.DCLRFN]: "Fecha de No autorización",
    [TimelineIconEnum.APRRFN_DECLINED]: "Fecha de declinación",
    [TimelineIconEnum.GBCK]: "Fecha de Devolución",
    [TimelineIconEnum.OMT]: "Fecha de Omisión",
  };
  const dateTitle = dateTitles[icon];

  const [openDialog, setOpenDialog] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleOpen = () => {
    setOpenDialog(true);
  };

  return {
    dateTitle,
    handleClose,
    handleOpen,
    openDialog,
    titleDialog,
  };
};
