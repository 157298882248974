import { CustomStyles } from "../../../shared/infrastructure/interfaces/CustomStyles";

export const styles: CustomStyles = {
  branchName: {
    color: "#293036",
    flex: "none",
    flexGrow: "1",
    fontFamily: "IBM Plex Sans",
    fontSize: "15px !important",
    fontStyle: "normal",
    fontWeight: "400px",
    height: "22px",
    lineHeight: "144%",
    margin: "8px",
    padding: "0",
    minWidth: "140px !important",
  },
  created: {
    color: "#6D7781",
    flex: "none",
    flexGrow: "1",
    fontSize: "13px !important",
    fontWeight: "400px",
    height: "22px",
    letterSpacing: "0.01em",
    lineHeight: "144%",
    margin: "8px",
    paddingLeft: "32px",
    width: "237px !important",
  },
  chip: {
    alignItems: "center",
    backgroundColor: "#E0FFF8 !important",
    color: "#3B9D86 !important",
    display: "flex",
    flexDirection: "row",
    fontSize: "9px !important",
    fontWeight: "500",
    height: "22px",
    borderRadius: "4px !important",
    margin: "8px",
    padding: "0",
    minWidth: "80px",
  },
  chipPending: {
    alignItems: "center",
    backgroundColor: "rgb(251, 199, 72,0.3)",
    color: "rgb(207, 113, 26)",
    flexDirection: "row",
    display: "flex",
    fontSize: "9px !important",
    fontWeight: "500",
    height: "22px",
    borderRadius: "4px !important",
    margin: "8px",
    padding: "0",
    minWidth: "80px",
  },
};
