import React from "react";
import {
  FlagBrasil,
  FlagChile,
  FlagColombia,
  FlagCostaRica,
  FlagEcuador,
  FlagElSalvador,
  FlagGuatemala,
  FlagHonduras,
  FlagMexico,
  FlagNicaragua,
  FlagPanama,
  FlagPeru,
} from "@kushki/connect-ui";
import { CountriesEnum } from "../../enums/CountriesEnum";

export const getCountryFlag: Record<string, JSX.Element> = {
  [CountriesEnum.BRAZIL]: <FlagBrasil fontSize="inherit" />,
  [CountriesEnum.CHILE]: <FlagChile fontSize="inherit" />,
  [CountriesEnum.COLOMBIA]: <FlagColombia fontSize="inherit" />,
  [CountriesEnum.COSTA_RICA]: <FlagCostaRica fontSize="inherit" />,
  [CountriesEnum.ECUADOR]: <FlagEcuador fontSize="inherit" />,
  [CountriesEnum.EL_SALVADOR]: <FlagElSalvador fontSize="inherit" />,
  [CountriesEnum.GUATEMALA]: <FlagGuatemala fontSize="inherit" />,
  [CountriesEnum.HONDURAS]: <FlagHonduras fontSize="inherit" />,
  [CountriesEnum.MEXICO]: <FlagMexico fontSize="inherit" />,
  [CountriesEnum.NICARAGUA]: <FlagNicaragua fontSize="inherit" />,
  [CountriesEnum.PANAMA]: <FlagPanama fontSize="inherit" />,
  [CountriesEnum.PERU]: <FlagPeru fontSize="inherit" />,
};
