import { StatusEnum } from "../StatusEnum";

export const stylesChip = (type: string) => ({
  backgroundColor: colorBackground(type),
  color: colorText(type),
});
const colorBackground = (type: string) => {
  switch (type) {
    case StatusEnum.PENDING:
      return "#FEECF8";
    case StatusEnum.PROCESS:
      return "#F0F4F9";
    case StatusEnum.FAILED:
      return "#FFDFE5";
    default:
      return "#F0F4F8";
  }
};
const colorText = (type: string) => {
  switch (type) {
    case StatusEnum.FAILED:
      return "#AD0C2A";
    case StatusEnum.PENDING:
      return "#8F4F8B";
    case StatusEnum.PROCESS:
      return "#6D7781";
    default:
      return "black";
  }
};
