import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { withStyles, InputBase } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "70%",
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  })
);

export const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      "&:focus": {
        borderRadius: 4,
      },
      border: "1px solid #ced4da",
      borderRadius: 4,
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      position: "relative",
    },
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
  })
)(InputBase);
