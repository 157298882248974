export enum ibanCountriesEnum {
  ALABANIA = "Albania",
  ANDORRA = "Andorra",
  AZERBAIYAN = "Azerbaiyan",
  BAHERIN = "Bahrein",
  BELGICA = "Belgica",
  BOSNIA = "Bosnia y Herzegovina",
  BRAZIL = "Brazil",
  ISLAS_VIRGENES_BRITANICAS = "Islas Virgenes Britanicas",
  BULGARIA = "Bulgaria",
  COSTA_RICA = "CostaRica",
  DINAMARCA = "Dinamarca",
  ALEMANIA = "Alemania",
  REPUBLICA_DOMININCANA = "Republica Dominicana",
  EL_SALVADOR = "ElSalvador",
  ESTONIA = "Estonia",
  ISLAS_FEROE = "Islas Feroe",
  FINLANDIA = "Finlandia",
  FRANCIA = "Francia",
  GEORGIA = "Georgia",
  GILBRALTAR = "Gibraltar",
  GRECIA = "Grecia",
  GROENLANDIA = "Groenlandia",
  REINO_UNIDO = "Reino Unido",
  GUATEMALA = "Guatemala",
  IRAK = "Irak",
  IRLANDA = "Irlanda",
  ISLANDIA = "Islandia",
  ISRAEL = "Israel",
  ITALIA = "Italia",
  JORDANIA = "Jordania",
  KAZAJISTAN = "Kazajistan",
  QATAR = "Qatar",
  KOSOVO = "Kosovo",
  CROACIA = "Croacia",
  KUWAIT = "Kuwait",
  LETONIA = "Letonia",
  LIBANO = "Libano",
  LIECHTENSTEIN = "Liechtenstein",
  LITUANIA = "Lituania",
  LUXEMBURGO = "Luxemburgo",
  MALTA = "Malta",
  MAURITANA = "Mauritania",
  MAURICIO = "Mauricio",
  MACEDONIA = "Macedonia",
  MOLDAVIA = "Moldavia",
  MONACO = "Monaco",
  MONTENEGRO = "Montenegro",
  PAISES_BAJOS = "Paises Bajos",
  NORUGEA = "Noruega",
  AUTSTRIA = "Austria",
  PAKISTAN = "Pakistan",
  PALESTINA = "Palestina",
  POLONIA = "Polonia",
  PORTUGAL = "Portugal",
  RUMANIA = "Rumania",
  SANTA_LUCIA = "Santa Lucia",
  SAN_MARINO = "San Marino",
  SANTO_TOME_PRINCIPE = "Santo Tome y Principe",
  ARABIA_SAUDITA = "Arabia Saudita",
  SUECIA = "Suecia",
  SUIZA = "Suiza",
  SERBIAR = "Serbia",
  SEYCHELESS = "Seychelles",
  ESLOVENIA = "Eslovenia",
  ESLOVAQUIA = "Eslovaquia",
  ESPANA = "España",
  TIMOR_ORIENTAL = "Timor Oriental",
  TURQUIA = "Turquia",
  REPUBLICA_CHECA = "Republica Checa",
  TUNEZ = "Tunez",
  UCRANAIA = "Ucrania",
  HUNGRIA = "Hungria",
  EMIRATOS_ARABES_UNIDOS = "Emiratos Arabes Unidos",
  BELARUS = "Belarus",
  CHIPRE = "Chipre",
}
