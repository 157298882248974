import { IStyles } from "../../shared/Interfaces/Styles.interfaces";

export const companyInformationStyles: IStyles = {
  boxContainerForm: {
    width: 1,
  },
  boxContainer: { display: "flex", columnGap: 7.5 },
  boxTitle: {
    display: "flex",
    gridRowGap: (theme) => theme.spacing(1),
    flexDirection: "column",
    minWidth: 275,
  },
  title: {
    color: "primary.main",
    fontWeight: 500,
    fontSize: 20,
  },
  subtitle: {
    color: "#6D7781",
    fontWeight: 400,
    fontSize: 15,
  },
};
