import React, { useState } from "react";
import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import { SecurityRulesByType } from "../../../types/security_rules_by_type";
import { get } from "lodash";
import { DataTableRules } from "../DataTableRules/DataTableRules";
import { SkeletonTable } from "../Skeletons/DataTableSkeleton";
import { TabsTitle } from "../../shared/infrastructure/constants/RulesConstants";
import { DataTableWhiteList } from "../DataTableWhiteList/DataTableWhiteList";
import { WhitelistRules } from "../../../types/whitelist_rules";
import { DataTableCommerce } from "../DataTableBusiness/DataTableCommerce";
import { MerchantsData } from "../../store/actionCreator";
import { AutocompleteFilterTable } from "../AutocompleteFilterTable/AutocompleteFilterTable";
import { SecurityRulesSubtitleSection } from "../../shared/infrastructure/constants/SecurityRulesConstants";

export interface SecurityRulesTabsProps {
  isLoading: boolean | undefined;
  isLoadingWhiteList: boolean | undefined;
  securityRuleItems: SecurityRulesByType | undefined;
  commerceRulesItems: SecurityRulesByType | undefined;
  whitelistRules: WhitelistRules | undefined;
  showMigrationRule: boolean | undefined;
  nextAction: () => void;
  backAction: () => void;
  nextActionCommerce: () => void;
  backActionCommerce: () => void;
  nextActionWhitelist: () => void;
  backActionWhitelist: () => void;
  deleteRule: (ruleId: string) => void;
  deleteWhitelistRule: (merchantId: string) => void;
  handlerShowModal: () => void;
  merchantName: string;
  merchants: MerchantsData | undefined;
  isLoadingMerchantList: boolean;
  handleSetSearchTextMerchantList: (value: string) => void;
  handleSearchTextMerchantList: (key: string) => void;
  handleSearchSecurityRulesCommerce: (merchantIdParam: string) => void;
  handleSearchWhitelistRule: (merchantIdParam: string) => void;
  handlerShowModalRuleByType: (ruleId: string, ruleType: string) => void;
  handlerSetIndexRedirect: (index: number) => void;
  handlerMigrateRules: () => void;
  handlerSetMerchantNameMigrateRules: (merchantName: string) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function tabProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const SecurityRulesTabs: React.FC<SecurityRulesTabsProps> = (
  props: SecurityRulesTabsProps
) => {
  const [value, setValue] = useState<number>(0);
  const handleTabsChange = (
    _event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setValue(newValue);
    props.handlerSetIndexRedirect(newValue);
  };
  return (
    <>
      <Box mt={5}>
        <Tabs
          indicatorColor={"primary"}
          value={value}
          onChange={handleTabsChange}
          aria-label="security-rules-tabs"
          centered={true}
          variant={"fullWidth"}
        >
          <Tab
            label={TabsTitle.COMERCIOS}
            {...tabProps(0)}
            style={{ textTransform: "none" }}
          />
          <Tab
            label={TabsTitle.PAISES}
            {...tabProps(1)}
            style={{ textTransform: "none" }}
          />
          <Tab
            label={TabsTitle.LISTA_BLANCA}
            {...tabProps(2)}
            style={{ textTransform: "none" }}
          />
        </Tabs>
      </Box>
      {value !== 1 && (
        <Box mt={3}>
          <Typography>
            <AutocompleteFilterTable
              property={"merchantId"}
              label={SecurityRulesSubtitleSection.FILTER_HEADER}
              merchantName={props.merchantName}
              options={get(props, "merchants.data", [])}
              pathValue={"_source.publicMerchantId"}
              pathName={"_source.name"}
              isLoading={props.isLoadingMerchantList}
              handleSearchText={props.handleSearchTextMerchantList}
              handleSearch={
                value === 0
                  ? props.handleSearchSecurityRulesCommerce
                  : props.handleSearchWhitelistRule
              }
              handleSetMerchantName={props.handleSetSearchTextMerchantList}
              handlerSetMerchantNameMigrateRules={
                props.handlerSetMerchantNameMigrateRules
              }
            />
          </Typography>
        </Box>
      )}
      <TabPanel index={0} value={value}>
        {get(props, "isLoading") ? (
          <SkeletonTable />
        ) : (
          <DataTableCommerce
            isLoading={props.isLoading}
            securityRuleItems={props.commerceRulesItems}
            showMigrationRule={props.showMigrationRule}
            nextAction={props.nextActionCommerce}
            backAction={props.nextActionCommerce}
            deleteRule={props.deleteRule}
            handlerShowModal={props.handlerShowModal}
            merchantName={props.merchantName}
            merchantList={props.merchants}
            isLoadingMerchantList={props.isLoadingMerchantList}
            handleSetSearchTextMerchantList={
              props.handleSetSearchTextMerchantList
            }
            handleSearchTextMerchantList={props.handleSearchTextMerchantList}
            handleSearchSecurityRulesCommerce={
              props.handleSearchSecurityRulesCommerce
            }
            handlerShowModalRuleByType={props.handlerShowModalRuleByType}
            handlerMigrateRules={props.handlerMigrateRules}
            handlerSetMerchantNameMigrateRules={
              props.handlerSetMerchantNameMigrateRules
            }
          />
        )}
      </TabPanel>
      <TabPanel index={1} value={value}>
        {get(props, "isLoading") ? (
          <SkeletonTable />
        ) : (
          <DataTableRules
            isLoading={props.isLoading}
            securityRuleItems={props.securityRuleItems}
            nextAction={props.nextAction}
            backAction={props.backAction}
            deleteRule={props.deleteRule}
            handlerShowModal={props.handlerShowModal}
            handlerShowModalRuleByType={props.handlerShowModalRuleByType}
          />
        )}
      </TabPanel>
      <TabPanel index={2} value={value}>
        {get(props, "isLoadingWhiteList") ? (
          <SkeletonTable />
        ) : (
          <DataTableWhiteList
            whitelistRules={props.whitelistRules}
            nextWhitelistAction={props.nextActionWhitelist}
            backWhitelistAction={props.backActionWhitelist}
            deleteWhitelistRule={props.deleteWhitelistRule}
            merchantName={props.merchantName}
            merchantList={props.merchants}
            isLoadingMerchantList={props.isLoadingMerchantList}
            handleSetSearchTextMerchantList={
              props.handleSetSearchTextMerchantList
            }
            handleSearchTextMerchantList={props.handleSearchTextMerchantList}
            handleSearchWhitelistRule={props.handleSearchWhitelistRule}
            handlerSetMerchantNameMigrateRules={
              props.handlerSetMerchantNameMigrateRules
            }
          />
        )}
      </TabPanel>
    </>
  );
};
