import { navigateToUrl } from "single-spa";
import { useEffect, useState } from "react";
import {
  ALL_TABLE_COLUMNS,
  onRenderValue,
} from "../../../shared/constants/customer_webhooks";
import {
  ICustomerWebhooksTable,
  IUseCustomerWebhooks,
} from "./useCustomerWebhooks.interfaces";
import { useSelector } from "react-redux";
import {
  selectNodeInfo,
  selectWebhookList,
} from "../../../store/selectors/selectors";
import { getWebhookList } from "../../../store/thunks/app/app.thunks";
import { Section } from "../../../shared/enums";
import { useAppDispatch } from "../../../store/hooks/hooks";
import { isSectionCentralized } from "../../../shared/utils/isSectionCentralized";
import { EntityNameEnum } from "../../../shared/enums/entityName";
import { routes } from "../../../shared/infrastructure/constants/routes";
import { ModeEnum } from "../../../shared/enums/ModeEnum";
import { QueryParamsEnum } from "../../../shared/enums/queryParamsEnum";
import { useLocation } from "react-router";

export const useCustomerWebhooks = (
  mode: string | null
): IUseCustomerWebhooks => {
  const dispatch = useAppDispatch();
  const [rows, setRows] = useState<ICustomerWebhooksTable[]>();
  const [isEditing, setIsEditing] = useState<boolean>(
    mode === ModeEnum.EDITION
  );
  const nodeInfoData = useSelector(selectNodeInfo);
  const webhooks = useSelector(selectWebhookList);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const publicMerchantId: string | null = searchParams.get(
    QueryParamsEnum.PUBLIC_MERCHANT_ID
  );

  const getIsCentralized = (): boolean => {
    let isCentralized: boolean = false;

    isCentralized = isSectionCentralized(
      Section.DEVELOPERS,
      nodeInfoData ? nodeInfoData!.configs : []
    );

    return isCentralized;
  };

  const handleRedirectOnClick = () => {
    let isCentralizedParam: string = "";

    if (nodeInfoData && nodeInfoData.entityName === EntityNameEnum.BRANCH)
      isCentralizedParam = `?isCentralized=${getIsCentralized()}`;

    navigateToUrl(
      `${routes.MERCHANT_WEBHOOKS}/${
        nodeInfoData!.merchantId
      }${isCentralizedParam}`
    );
  };

  useEffect(() => {
    if (nodeInfoData?.entityName === EntityNameEnum.CUSTOMER) {
      setIsEditing(true);
    } else {
      setIsEditing(!getIsCentralized());
    }
  }, [nodeInfoData]);

  useEffect(() => {
    if (webhooks) {
      let values = webhooks.items.map((item) => {
        return {
          ...item,
        } as ICustomerWebhooksTable;
      });

      setRows(values);
    }
  }, [webhooks]);

  useEffect(() => {
    dispatch(
      getWebhookList({
        limit: 5,
        merchantId: publicMerchantId!,
      })
    );
  }, []);

  return {
    handleRedirectOnClick,
    isEditing,
    table: {
      columns: ALL_TABLE_COLUMNS,
      onRenderValue,
      rows: rows ?? [],
    },
  };
};
