import React from "react";
import {
  Configuration,
  RatesConfigDynamo,
} from "../../../../../types/rates_config_dynamo";
import { Grid, Typography } from "@mui/material";
import { defaultTo, get } from "lodash";
import { RankIndicatorsIndexEnum } from "../../../../shared/infrastructure/RankIndicatorIndex-enum";
import { SET_RANK_INDICATOR } from "../../../../shared/utils/setRankIndicator";
import { ratesInfoStyles as style } from "../../RatesInfo.styles";
import { RatesRangesTable } from "./RatesRangesTable";
import { CountriesEnum } from "../../../../shared/enums/CountriesEnum";
import { RatesRangesLabelEnum } from "../../../../shared/enums/RatesRangesLabelEnum";

export interface RatesRangesPropsInterface {
  country: CountriesEnum;
  rate: RatesConfigDynamo;
}

export const RatesRanges: React.FC<RatesRangesPropsInterface> = (
  props: RatesRangesPropsInterface
) => {
  const { rate, country } = props;

  const configurations: Configuration[] = get(rate, "configuration", []);
  const haveRanges: boolean = configurations.length >= 2;
  const ratesType = get(rate, "ratesType");
  const currencyCountry = defaultTo(rate.currency, "");

  return (
    <Grid container spacing={2} data-testid={"rates-ranges"}>
      <Grid item xs={12}>
        <Typography sx={style.rangesLabelResp}>
          {RatesRangesLabelEnum.RANKS}
        </Typography>
      </Grid>

      <Grid container sx={style.textTable}>
        <Grid item xs={6}>
          <Typography sx={style.rangesLabelSubTitle}>
            {RatesRangesLabelEnum.INDICATOR}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={style.rangesLabelResp}>
            {defaultTo(SET_RANK_INDICATOR[ratesType], "-")}
          </Typography>
        </Grid>
      </Grid>
      {ratesType !== RankIndicatorsIndexEnum.TRES && (
        <Grid container sx={style.textTable}>
          <Grid item xs={6}>
            <Typography sx={style.rangesLabelSubTitle}>
              {RatesRangesLabelEnum.PERIOD_TO_EVALUATE}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={style.rangesLabelResp}>
              {`${get(rate, "evaluatePeriod", 0)} meses`}
            </Typography>
          </Grid>
        </Grid>
      )}
      {haveRanges && (
        <Grid
          sx={{
            marginTop: "16px",
            paddingLeft: "16px",
          }}
        >
          <RatesRangesTable
            configuration={configurations}
            country={country}
            ratesType={ratesType}
            currency={currencyCountry}
          />
        </Grid>
      )}
    </Grid>
  );
};
