import { IStyles } from "../../../shared/interfaces/Styles.interfaces";

export const CreateCommentModalStyles: IStyles = {
  boxContainer: {
    backgroundColor: "white",
    borderRadius: "4px",
    boxShadow: "0px 3px 6px 0px rgba(0, 0, 0, 0.24)",
    left: "50%",
    padding: "24px",
    paddingBottom: "15px",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "592px",
  },
  buttonRow: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cancelButton: {
    typography: "body2",
    color: "error.dark",
    fontWeight: 500,
  },
  closeButton: {
    position: "absolute",
    top: "8px",
    right: "20px",
    color: "#293036",
  },
  heading: {
    typography: "h6",
    fontWeight: 500,
  },
  saveButton: {
    color: "#023365",
    typography: "body2",
    fontWeight: 500,
  },
  textField: {
    color: "text.grey",
    typography: "h2",
    width: "544px",
    fontSize: "14px",
    fontWeight: 400,
  },
};
