import { useState } from "react";
import { useDispatch } from "react-redux";
import { TransactionsConciliationRequest } from "../../../types/transactions_conciliation_request";
import { ConciliationActionEnum } from "../../shared/enums/concilation_action_enum";
import { setConciliationAction } from "../../store/actions/app/app";
import updateTransactionsStatus from "../../store/thunks/transactions/updateTransactionsStatus/update_transactions_status";

const defaultRequest: TransactionsConciliationRequest = {
  action: "processConciliationTransactions",
  data: {
    conciliationAction: ConciliationActionEnum.OMITTED,
    data: [],
    source: "pending",
  },
};

export const useSkipButton = () => {
  const dispatch = useDispatch();
  const [openSkipModal, setOpenSkipModal] = useState(false);
  const [reason, setReason] = useState("");
  const [isSkipButtonDisabled, setIsSkipButtonDisabled] = useState(false);
  const [selectedRows, setSelectRowsSkipModal] = useState<string[]>([]);

  const handleOpenSkipModal = () => setOpenSkipModal(true);

  const handleCloseSkipModal = () => {
    setReason("");
    setOpenSkipModal(false);
  };

  const handleConfirm = () => {
    const newDataForRequest: { conciliationId: string }[] = [];

    selectedRows.map((element) => {
      newDataForRequest.push({ conciliationId: element });
    });
    const request = {
      ...defaultRequest,
      data: {
        ...defaultRequest.data,
        data: newDataForRequest,
        observationInfo: { reason },
      },
    };

    setOpenSkipModal(false);
    setReason("");
    dispatch(setConciliationAction(ConciliationActionEnum.OMITTED));
    dispatch(updateTransactionsStatus(request));
  };

  const handleChangeInputReason = (currentReason: string) =>
    setReason(currentReason);

  return {
    counterTransactions: selectedRows.length,
    handleChangeInputReason,
    handleCloseSkipModal,
    handleConfirm,
    handleOpenSkipModal,
    isSkipButtonDisabled,
    openSkipModal,
    reason,
    setIsSkipButtonDisabled,
    setSelectRowsSkipModal,
  };
};
