import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

const TableBodyStyles = createNamedStyles({
  body: {
    tr: {
      backgroundColor: "rgb(255, 255, 255)",
      borderCollapse: "separate",
      borderSpacing: "0px 8px",
      color: "inherit",
      display: "table-row",
      outline: "0px",
      verticalAlign: "middle",
      width: "100%",
    },
    "tr:hover td": {
      backgroundColor: "#fbfcfe",
      borderBottom: "1px solid #3879cd",
      borderBottomStyle: "outset",
      borderTop: "1px solid #3879cd",
      borderTopStyle: "outset",
    },
    "tr:hover td:first-of-type": {
      borderBottom: "1px solid #3879cd",
      borderBottomLeftRadius: "8px",
      borderBottomStyle: "outset",
      borderLeft: "1px solid #3879cd",
      borderLeftStyle: "outset",
      borderTop: "1px solid #3879cd",
      borderTopLeftRadius: "8px",
      borderTopStyle: "outset",
    },
    "tr:hover td:last-child": {
      borderBottom: "1px solid #3879cd",
      borderBottomRightRadius: "8px",
      borderBottomStyle: "outset",
      borderRight: "1px solid #3879cd",
      borderRightStyle: "outset",
      borderTop: "1px solid #3879cd",
      borderTopRightRadius: "8px",
      borderTopStyle: "outset",
    },
  },
  containerTitleCopy: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  itemTitleCopy: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  row: {
    "&.MuiTableRow-root": {
      td: {
        borderBottom: "1px solid #a0abba63",
        borderBottomStyle: "outset",
        borderTop: "1px solid #a0abba63",
        borderTopStyle: "outset",
      },
      "td:first-of-type": {
        borderBottom: "1px solid #a0abba63",
        borderBottomLeftRadius: "8px",
        borderBottomStyle: "outset",
        borderLeft: "1px solid #a0abba63",
        borderLeftStyle: "outset",
        borderTop: "1px solid #a0abba63",
        borderTopLeftRadius: "8px",
        borderTopStyle: "outset",
      },
      "td:last-child": {
        borderBottom: "1px solid #a0abba63",
        borderBottomRightRadius: "8px",
        borderBottomStyle: "outset",
        borderRight: "1px solid #a0abba63",
        borderRightStyle: "outset",
        borderTop: "1px solid #a0abba63",
        borderTopRightRadius: "8px",
        borderTopStyle: "outset",
      },
    },
    "&.MuiTableRow-root.Mui-selected": {
      td: {
        backgroundColor: "#fbfcfe",
        borderBottom: "1px solid #3879cd",
        borderBottomStyle: "outset",
        borderTop: "1px solid #3879cd",
        borderTopStyle: "outset",
      },
      "td:first-of-type": {
        borderBottom: "1px solid #3879cd",
        borderBottomLeftRadius: "8px",
        borderBottomStyle: "outset",
        borderLeft: "1px solid #3879cd",
        borderLeftStyle: "outset",
        borderTop: "1px solid #3879cd",
        borderTopLeftRadius: "8px",
        borderTopStyle: "outset",
      },
      "td:last-child": {
        borderBottom: "1px solid #3879cd",
        borderBottomRightRadius: "8px",
        borderBottomStyle: "outset",
        borderRight: "1px solid #3879cd",
        borderRightStyle: "outset",
        borderTop: "1px solid #3879cd",
        borderTopRightRadius: "8px",
        borderTopStyle: "outset",
      },
    },
  },
  subtitle: {
    color: "rgb(109, 119, 129)",
    fontFamily: "IBM Plex Sans",
    fontSize: "13px",
    fontWeight: "500",
    letterSpacing: "0.0025em",
    lineHeight: "120%",
    margin: "0",
    whiteSpace: "nowrap",
  },
  subtitleTitleCopy: {
    color: "rgb(109, 119, 129)",
    fontFamily: "IBM Plex Sans",
    fontSize: "13px",
    fontWeight: "500",
    letterSpacing: "0.0025em",
    lineHeight: "120%",
    margin: "0",
    overflow: "hidden",
    overflowWrap: "break-word",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "95%",
  },
  title: {
    color: "rgb(41, 48, 54)",
    cursor: "pointer",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "0.0025em",
    lineHeight: "150%",
    margin: "0px",
    overflow: "hidden",
    overflowWrap: "break-word",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "95%",
  },
});

export { TableBodyStyles };
