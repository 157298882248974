export enum SnackbarEnum {
  REQUEST_ERROR = "Ha ocurrido un error. Inténtalo nuevamente",
  REQUEST_SUCCESS = "Se ha guardado la información con éxito.",
  CENTRALIZE_SUCCES = "Se centralizó los 8 Branches con éxito",
  DECENTRALIZE_IN_PROGRESS = "Estamos descentralizando tus branches, verifícalos en un momento.",
  CENTRALIZE_IN_PROGRESS = "Estamos centralizando tus branches, verifícalos en un momento.",
  IN_PROGRESS = "Se está ejecutando el proceso",
}

export enum NotificationTypeEnum {
  SUCCESS = "SUCCESS",
  CENTRALIZE_SUCCES = "SUCCESS CEN",
  DESCENTRALIZE_SUCCESS = "SUCCESS DES",
  FAILED = "FAILED",
}

export enum SnackbarValuesEnum {
  INFO = "info",
  SUCCESS = "success",
  SIMPLE = "simple",
}
