import { IOptionFilter } from "../interfaces/IOptionFilter";
import { FilterLabelEnum } from "../FilterLabelEnum";
import { FilterTitleEnum } from "../../FilterTitleEnum";
import { KeyStatusEnum } from "../../KeyStatusEnum";
import { IDFilterEnum } from "../IDFilterEnum";

export const DefaultSummaryHistoricFilter: IOptionFilter[] = [
  {
    id: IDFilterEnum.status,
    title: FilterTitleEnum.BANK_CONCILIATION_STATUS,
    options: [
      {
        key: KeyStatusEnum.CONCILIATION_BANK_CONCILIATION_STATUS,
        label: FilterLabelEnum.CONCILIATION,
        selected: false,
      },
      {
        key: KeyStatusEnum.OMIT_BANK_CONCILIATION_STATUS,
        label: FilterLabelEnum.OMIT,
        selected: false,
      },
      {
        key: KeyStatusEnum.NO_CONCILIATION_BANK_CONCILIATION_STATUS,
        label: FilterLabelEnum.NO_CONCILIATION,
        selected: false,
      },
    ],
  },
];
