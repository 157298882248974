import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";

import {
  Data,
  FilterWalletDashboardResponse,
} from "../../../../types/filter_wallet_dashboard_response";
import {
  ISort,
  IUseWalletTableState,
} from "../../../shared/infrastructure/interfaces/useWalletTableState.interface";
import { defaultTo, get, isEmpty } from "lodash";
import { useState } from "react";
import { format, startOfMonth } from "date-fns";
import { FilterWalletDashboardRequest } from "../../../../types/filter_wallet_dashboard_request";
import {
  getTransactions,
  setFilterWalletDashboardRequest,
  setTablePage,
  setTableSize,
} from "../../../store/actionCreators";
import {
  FilterEnum,
  FilterSortEnum,
} from "../../../shared/infrastructure/enums/FilterEnum";
import { LabelsWallet } from "../../../shared/infrastructure/enums/LabelWalletEnum";
import { SearchTypeEnum } from "../../../shared/infrastructure/enums/SearchTypeEnum";

export const useWalletTableState = (): IUseWalletTableState => {
  const dispatch = useDispatch();

  const isLoadingGetTransactions: boolean | undefined = useSelector(
    (state: IAppState) => state.isLoadingGetTransactions
  );
  const filterWalletDashboardRequest: FilterWalletDashboardRequest | undefined =
    useSelector((state: IAppState) => state.filterWalletDashboardRequest);
  const getTransactionsResponse: FilterWalletDashboardResponse | undefined =
    useSelector((state: IAppState) => state.getTransactionsResponse);
  const sizeTable: number = useSelector((state: IAppState) =>
    defaultTo(state.tableSize, 10)
  );
  const pageTable: number = useSelector((state: IAppState) =>
    defaultTo(state.currentPage, 1)
  );
  const totalRecordsTable: number = useSelector((state: IAppState) =>
    defaultTo(state.totalRecords, 0)
  );
  const searchType: SearchTypeEnum = useSelector((state: IAppState) =>
    defaultTo(state.searchType, SearchTypeEnum.DATE_RANGE)
  );

  const [sort, setSort] = useState<ISort>({ direction: "asc" });

  const retrieveWithTable = (index: number): string => {
    switch (index) {
      case 2:
        return "18%";
      case 3:
        return "18%";
      default:
        return "13%";
    }
  };

  const renderStatusText = (status: string): string => {
    switch (status) {
      case FilterEnum.APPROVAL:
        return LabelsWallet.APPROVAL;
      case FilterEnum.DECLINED:
        return LabelsWallet.DECLINED;
      default:
        return LabelsWallet.DECLINED;
    }
  };

  const buildDate = (created: string | number, renderDate: boolean): string => {
    if (renderDate) {
      return format(new Date(created), "yyyy-MM-dd");
    } else {
      return format(new Date(created), "HH:mm:ss");
    }
  };

  const renderTransactionTypeText = (transactionType: string): string => {
    switch (transactionType) {
      case FilterEnum.DEBIT:
        return LabelsWallet.DEBIT;
      case FilterEnum.CREDIT:
        return LabelsWallet.CREDIT;
      default:
        return LabelsWallet.DEBIT;
    }
  };

  const renderOriginText = (item: Data): string => {
    if (get(item, "origin")) {
      switch (item.origin) {
        case FilterEnum.CASH_OUT:
          return LabelsWallet.CASH_OUT;
        case FilterEnum.LIQUIDACION:
          return LabelsWallet.LIQUIDACION;
        case FilterEnum.TRANSFER_OUT:
          return LabelsWallet.TRANSFER_OUT;
        default:
          return LabelsWallet.TRANSFER_OUT;
      }
    } else {
      switch (item.payment_method) {
        case FilterEnum.MANUAL:
          return LabelsWallet.KUSHKI;
        case FilterEnum.AUTOMATIC:
          return LabelsWallet.COMMERCE;
        default:
          return LabelsWallet.KUSHKI;
      }
    }
  };

  const sortColumn = (): void => {
    const startDate: string = format(startOfMonth(new Date()), "yyyy-MM-dd");
    const endDate: string = format(new Date(), "yyyy-MM-dd");
    const merchantId: string =
      new URLSearchParams(location.search).get("publicMerchantId") || "";

    let filterRequest: FilterWalletDashboardRequest = {
      ...filterWalletDashboardRequest!,
    };

    if (!isEmpty(filterRequest)) {
      filterRequest = {
        ...filterRequest,
        sort: {
          field: FilterSortEnum.CREATED,
          order:
            sort.direction === FilterEnum.ASC
              ? FilterEnum.DESC
              : FilterEnum.ASC,
        },
      };
      dispatch(setFilterWalletDashboardRequest(filterRequest));
      dispatch(getTransactions(filterRequest, merchantId));
    } else {
      const filterTransactionsRequest: FilterWalletDashboardRequest = {
        from: `${startDate}T00:00:00`,
        limit: 10,
        offset: 0,
        sort: {
          field: FilterSortEnum.CREATED,
          order:
            sort.direction === FilterEnum.ASC
              ? FilterEnum.DESC
              : FilterEnum.ASC,
        },
        to: `${endDate}T23:59:59`,
      };

      dispatch(setFilterWalletDashboardRequest(filterTransactionsRequest));
      dispatch(getTransactions(filterTransactionsRequest, merchantId));
    }

    setSort({
      direction:
        sort.direction === FilterEnum.ASC ? FilterEnum.DESC : FilterEnum.ASC,
    });
  };

  const handleChangePageSize = (pageSize: number): void => {
    const filterRequest: FilterWalletDashboardRequest = {
      ...filterWalletDashboardRequest!,
      offset: 0,
      limit: pageSize,
    };

    dispatch(setTableSize(pageSize));
    dispatch(setTablePage(1));
    dispatch(setFilterWalletDashboardRequest(filterRequest));
    dispatch(
      getTransactions(
        filterRequest,
        new URLSearchParams(location.search).get("publicMerchantId") || ""
      )
    );
  };

  const handleChangePage = (page: number): void => {
    const filterRequest: FilterWalletDashboardRequest = {
      ...filterWalletDashboardRequest!,
      offset: (page - 1) * sizeTable,
    };

    dispatch(setTablePage(page));
    dispatch(setFilterWalletDashboardRequest(filterRequest));
    dispatch(
      getTransactions(
        filterRequest,
        new URLSearchParams(location.search).get("publicMerchantId") || ""
      )
    );
  };

  return {
    buildDate,
    getTransactionsResponse,
    isLoadingGetTransactions,
    searchType,
    renderOriginText,
    renderStatusText,
    renderTransactionTypeText,
    retrieveWithTable,
    sort,
    sortColumn,
    handleChangePageSize,
    handleChangePage,
    size: sizeTable,
    page: pageTable,
    total: totalRecordsTable,
  };
};
