import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import {
  Operators,
  TextFieldsNames,
} from "../../../../shared/infrastructure/constants/BusinessRuleConstants";
import { useFormContext } from "react-hook-form";
import { useAmountConditionState } from "./state/useAmountConditionState";
import { defaultTo, get } from "lodash";
import { createStyles, makeStyles } from "@mui/styles";
import { IAmountConditionProps } from "../../../../shared/infrastructure/interfaces/IAmountConditionProps";
import { TextFieldComponent } from "./components/TextFieldComponent";
import { RuleEnum } from "../../../../shared/infrastructure/enums/RuleEnum";

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      paddingTop: 16,
    },
  })
);

export const AmountConditionComponent: React.FC<IAmountConditionProps> = (
  props: IAmountConditionProps
) => {
  const classes = useStyles();
  const { operator, index } = props;
  const { watch, setValue, errors, control, register, clearErrors } =
    useFormContext();
  const name: string = `condition.${index}.value`;
  const firstValue: string = defaultTo(watch(TextFieldsNames.FirstAmount), "");

  useEffect(() => {
    if (props.defaultValueList.length != 0) {
      setValue(TextFieldsNames.FirstAmount, props.defaultValueList[0]);
      props.setSelectedItems([
        {
          label: RuleEnum.AMOUNT,
          value: props.defaultValueList[0],
          selected: true,
        },
      ]);
    }
  }, [props.defaultValueList]);

  useEffect(() => {
    props.setSelectedItems([
      {
        label: RuleEnum.AMOUNT,
        value: firstValue,
        selected: true,
      },
    ]);
  }, [firstValue]);

  const secondValue: string = defaultTo(
    watch(TextFieldsNames.SecondAmount),
    ""
  );

  const { firstPlaceHolder, secondPlaceHolder, actions } =
    useAmountConditionState({
      operator,
      firstValue,
      secondValue,
      setValue,
      name,
      clearErrors,
      setSelectedItems: props.setSelectedItems,
      defaultValueList: props.defaultValueList,
    });

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextFieldComponent
            value={{
              actions,
              id: TextFieldsNames.FirstAmount,
              name,
              placeholder: firstPlaceHolder,
              value: firstValue,
              setValue,
              errors,
              control,
              register,
              compareValue: secondValue,
            }}
            defaultValueList={[get(props, "defaultValueList[0]")]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} className={classes.textField}>
        {operator === Operators.BETWEEN && (
          <Grid item xs={12}>
            <TextFieldComponent
              value={{
                actions,
                id: TextFieldsNames.SecondAmount,
                name,
                placeholder: secondPlaceHolder,
                value: secondValue,
                setValue,
                errors,
                control,
                register,
                compareValue: firstValue,
              }}
              defaultValueList={[get(props, "defaultValueList[1]")]}
            />
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};
