import { IBasicMerchantInformation } from "../interfaces/IBasicMerchantInformation";

import { defaultTo, findIndex, isEmpty } from "lodash";
import { LocalStoragePathEnum } from "../enums/LocalStoragePathEnum";
import { IMerchantInfo } from "../interfaces/IMerchantInfo";
import {
  GetPayoutsRulesResponse,
  Rule,
} from "../../../types/get_payouts_rules_response";

export const defaultBasicMerchantInformation: IBasicMerchantInformation = {
  constitutionalCountry: "",
  country: "",
  name: "",
  nodeId: "",
  origin: "",
  publicMerchantId: "",
  redirectPath: "",
};

const defaultMerchantInfo: IMerchantInfo = {
  country: "",
  isEditing: false,
  merchantName: "",
  publicMerchantId: "",
};

const defaultBusinessRules: GetPayoutsRulesResponse = {
  data: [],
  total: 0,
};

export const getBasicMerchantInformation = (): IBasicMerchantInformation => {
  const storageMerchant: string | null = window.localStorage.getItem(
    LocalStoragePathEnum.BASIC_MERCHANT_INFORMATION
  );

  if (isEmpty(storageMerchant)) return defaultBasicMerchantInformation;
  const actualValues = JSON.parse(
    defaultTo(storageMerchant, JSON.stringify(defaultBasicMerchantInformation))
  );

  return defaultTo(actualValues, defaultBasicMerchantInformation);
};

export const setBasicMerchantInformation = (
  newValue: Partial<IBasicMerchantInformation>
): void => {
  const oldValue: IBasicMerchantInformation = getBasicMerchantInformation();
  const newItem: IBasicMerchantInformation = { ...oldValue, ...newValue };

  window.localStorage.setItem(
    LocalStoragePathEnum.BASIC_MERCHANT_INFORMATION,
    JSON.stringify(newItem)
  );
};

export const getMerchantInfo = (): IMerchantInfo => {
  const storageMerchant: string | null = window.localStorage.getItem(
    LocalStoragePathEnum.MERCHANT_INFO
  );
  const actualValues = JSON.parse(
    defaultTo(storageMerchant, JSON.stringify(defaultMerchantInfo))
  );

  return actualValues;
};

export const getBusinessRulesStorage = (): GetPayoutsRulesResponse => {
  const businessRules = window.localStorage.getItem(
    LocalStoragePathEnum.BUSINESS_RULES_BATCH
  );

  if (isEmpty(businessRules)) return defaultBusinessRules;
  const actualValues = JSON.parse(
    defaultTo(businessRules, JSON.stringify(defaultBusinessRules))
  );

  return defaultTo(actualValues, defaultBusinessRules);
};

export const upsertBusinessRuleLocalStorage = (rule: Rule): void => {
  const businessRules: GetPayoutsRulesResponse = getBusinessRulesStorage();
  const matchIndex: number = findIndex(businessRules.data, {
    id: rule.id,
  });

  if (matchIndex >= 0) {
    businessRules.data.splice(matchIndex, 1, rule);
  } else {
    businessRules.data.push(rule);
  }
  businessRules.total = businessRules.data.length;
  localStorage.setItem(
    LocalStoragePathEnum.BUSINESS_RULES_BATCH,
    JSON.stringify(businessRules)
  );
};

export const isMassiveRules = (): boolean => {
  const item: string = defaultTo(
    window.localStorage.getItem(LocalStoragePathEnum.IS_MASSIVE_RULES),
    "false"
  );

  return JSON.parse(item);
};
