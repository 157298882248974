import { useEffect, useState } from "react";
import {
  buildBillingData,
  isFormProgress,
} from "../../../shared/utils/billing_data_utils";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { defaultTo, get, isEmpty } from "lodash";
import { ConfigIdEnum } from "../../../shared/constants/initial_state_legal_details";
import { FieldsEnum } from "../../../shared/enums/FieldsEnum";
import { setTypeButtonBilling } from "../../../store/actions/merchantBillingData.actions";
import { EntityNameEnum } from "../../../shared/enum/EntityNameEnum";

import { RootState } from "../../../store/store";
import {
  createSemaphoreBranches,
  listBranchesSelected,
  objectSaveMassiveBranch,
} from "../../../shared/utils/massiveBranchEdit";
import {
  setBranchBatch,
  setListBranchMassiveTitle,
  setNotificationMassive,
} from "../../../store/actions/massiveBranch.actions";
import { updateMassiveBranch } from "../../../store/thunks/massiveBranch/massiveBranchs.thunks";
import { useDispatch } from "react-redux";
import { setStatusSocket } from "../../../store/actions/massiveBranch/massiveBranch.actions";
import { TabItemStatusEnum } from "../../../shared/enum/tabItemStatusEnum";
import { ActionFooterEnum } from "../../../shared/enums/ActionFooterEnum";
import { redirectPath } from "../../../shared/utils/company_data_utils";
import { useBillingDataGeneral } from "../../../shared/utils/state/useBillingDataGeneral";
import { setTypeButton } from "../../../store/actions/basicDataForm.action";
import { NONE, StatusSocketEnum } from "../../../shared/enum/socketEnum";
import { useSnackbar } from "@kushki/connect-ui";

export const useBillingDataMassive = () => {
  const {
    isEdit,
    form,
    countriesInfo,
    nodeInfo,
    infoBilling,
    areaCode,
    satAreaCode,
    satStateCode,
    country,
    typeButton,
    setIsContinue,
    isLoadingBillingData,
    isLoadingGetNodeInfo,
    handleChangeStatusNodeInfo,
    handleShowSave,
    showSave,
    validateForm,
    isContinue,
    merchantNodeInfo,
    citiesList,
    footerLabel,
    headerProps,
    zipCodeCatalogs,
    zipCodeSatCatalogs,
    newEconomicActivitySRI,
    isCentralizedNode,
    provincesList,
    activeSaveButton,
    handleGetCatalogZipcode,
    handleUseSameInfoSat,
    configsInfo,
  } = useBillingDataGeneral(true);
  const { showSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const dispatchWS = useDispatch();

  const {
    branchBatch,
    title,
    listMerchants,
    listBranchesMassive,
    notificationMassive,
  } = useAppSelector((state: RootState) => ({
    ...state.branchBatch,
    ...state.merchantCompany,
  }));

  const { updateMassiveBranchStatus, statusSocket } = useAppSelector(
    (state: RootState) => state.massiveBranch
  );

  const constitutionalCountry = get(
    nodeInfo,
    "generalInfo.constitutionalCountry"
  );
  const [isModalFinalization, setModalFinalization] = useState<boolean>(false);

  const setFinalization = (stateModal: boolean) => {
    setModalFinalization(stateModal);
  };

  const saveData = async (validate: boolean, status: TabItemStatusEnum) => {
    const useSatInfo: boolean = defaultTo(
      form.getValues(FieldsEnum.useSameInfoSat),
      false
    );

    const validForm: boolean = validate ? await validateForm(useSatInfo) : true;

    if (validForm) {
      const billingData = buildBillingData(
        form,
        useSatInfo,
        {
          areaCode,
          city: infoBilling.city,
          municipality: infoBilling.municipality,
          populationCode: infoBilling.populationCode,
          province: infoBilling.province,
          satAreaCode,
          satPopulationCode: infoBilling.satPopulationCode,
          satStateCode,
          stateCode: infoBilling.stateCode,
        },
        get(nodeInfo, "merchantId", ""),
        country,
        constitutionalCountry
      );

      await dispatchWS(
        updateMassiveBranch(
          objectSaveMassiveBranch(
            billingData,
            ConfigIdEnum.CN016,
            listBranchesMassive,
            status
          )
        )
      );
    }
  };
  const changeStatusNodeInfo = (status: TabItemStatusEnum) => {
    handleChangeStatusNodeInfo(
      status,
      ConfigIdEnum.CN016,
      get(branchBatch[1], "publicMerchantId")
    );
  };

  const handleSaveInfo = async () => {
    const status = isFormProgress(form)
      ? TabItemStatusEnum.IN_PROCESS
      : TabItemStatusEnum.COMPLETE;

    await saveData(
      true,
      status === TabItemStatusEnum.IN_PROCESS
        ? TabItemStatusEnum.PENDING
        : status
    );
    changeStatusNodeInfo(status);
  };

  const handleNextInfo = async () => {
    dispatch(setTypeButtonBilling(ActionFooterEnum.CONTINUE));
    setIsContinue(true);
    await saveData(true, TabItemStatusEnum.COMPLETE);
    changeStatusNodeInfo(TabItemStatusEnum.COMPLETE);
  };

  useEffect(() => {
    if (isEmpty(branchBatch)) {
      dispatch(setBranchBatch(createSemaphoreBranches()));
      dispatch(setListBranchMassiveTitle(listBranchesSelected()));
    }
  }, []);
  useEffect(() => {
    if (showSave && !isLoadingBillingData) {
      handleShowSave();
    } else if (!showSave && !isLoadingBillingData && isContinue) {
      setIsContinue(false);
    }
  }, [isLoadingBillingData]);
  useEffect(() => {
    if (constitutionalCountry !== country) {
      form.setValue(FieldsEnum.city, "");
      form.setValue(FieldsEnum.province, "");
      form.setValue(FieldsEnum.department, "");
    }
  }, [isLoadingGetNodeInfo]);

  const redirectFinal = () => {
    dispatch(setTypeButton(NONE));
    dispatch(setTypeButtonBilling("NONE"));
    redirectPath("/create-node/branch/edit");
  };

  useEffect(() => {
    if (
      statusSocket === StatusSocketEnum.SUCCESS &&
      typeButton === ActionFooterEnum.CONTINUE
    ) {
      redirectFinal();
    }
    if (statusSocket === StatusSocketEnum.SUCCESS) {
      dispatch(setTypeButton(NONE));
      dispatchWS(setStatusSocket(StatusSocketEnum.NONE));
    }
  }, [statusSocket, typeButton]);

  useEffect(() => {
    if (notificationMassive) {
      showSnackbar(notificationMassive);
      dispatch(setNotificationMassive(undefined));
    }
  }, [notificationMassive]);

  return {
    citiesList,
    clientType: get(merchantNodeInfo, "clientType", ""),
    countriesInfo: {
      constitutionalCountry: countriesInfo.constitutionalCountry,
      country: countriesInfo.country,
    },
    economicActivityId: merchantNodeInfo.economicActivityId,
    footerLabel: !isEmpty(get(configsInfo, `${ConfigIdEnum.CN016}.updatedBy`))
      ? footerLabel
      : {
          date: "",
          editDetail: false,
          isHidden: true,
          text: "",
        },
    form,
    handleGetCatalogZipcode,
    handleUseSameInfoSat,
    headerProps,
    infoBilling: {
      city: infoBilling.city,
      constitutionalCountry,
      country,
      municipality: infoBilling.municipality,
      populationCode: infoBilling.populationCode,
      province: infoBilling.province,
      satCity: infoBilling.satCity,
      satMunicipality: infoBilling.satMunicipality,
      satPopulationCode: infoBilling.satPopulationCode,
      satProvince: infoBilling.satProvince,
      satStateCode: infoBilling.stateCode,
      stateCode: infoBilling.stateCode,
      useSameInfoSat: infoBilling.useSameInfoSat,
    },
    isCentralizedNode,
    isDisableEconomicActivity:
      nodeInfo.entityName === EntityNameEnum.CUSTOMER || isCentralizedNode,
    isEdit,
    isLoadingBillingData: updateMassiveBranchStatus,
    isLoadingGetNodeInfo,
    isModalFinalization,
    newEconomicActivitySRI,
    numberBranches: `${defaultTo(listMerchants, "").split(",").length}`,
    primaryButton: {
      isDisabled: isFormProgress(form),
      isHidden: true,
      onAction: () => setFinalization(true),
    },
    provincesList,
    saveValues: handleNextInfo,
    secondaryButton: {
      isDisabled: !activeSaveButton || isCentralizedNode,
      isHidden: true,
      onAction: handleSaveInfo,
    },
    setFinalization,
    showSave,
    title,
    zipCodeCatalogs,
    zipCodeSatCatalogs,
  };
};
