import {
  BasicDataLabels,
  ErrorMessageMX,
  RegularExp,
  TypeElement,
} from "../BasicStateMX/ErrorMessageMX";
import {
  PersonTypeInterface,
  PersonTypeObjectInterface,
} from "../BasicStateGeneral/FieldsBasicData";
import { PersonTypeHNEnum } from "../BasicStateGeneral/PersonType";
import { FieldsNamesRender } from "../FieldsEnumRenderEnum";
import { ErrorMessageCR } from "../BasicStateCR/ErrorMessageCR";
import { ErrorMessageGeneric } from "../BasicStateGeneral/ErrorMessage";
import { WEB_SITE_REGEX } from "../../constants/billing_form_constants";
import { ErrorMessageHN } from "./ErrorMessageHN";

export const PersonTypeHNList: Array<PersonTypeObjectInterface> = [
  { name: "Moral", value: "02" },
  { name: "Física", value: "01" },
];

export const docTypeHon: PersonTypeObjectInterface[] = [
  { id: "0", name: "RNP", value: "0" },
  { id: "1", name: "RTN", selected: true, value: "1" },
];

export const fieldsRenderHN: Array<PersonTypeInterface> = [
  {
    filter: PersonTypeHNEnum.MORAL,
    label: BasicDataLabels.RAZON_SOCIAL,
    name: FieldsNamesRender.social_reason,
    regExp: RegularExp.CAPITAL_LETTER_NUMBERS,
    rules: {
      maxLength: {
        message: ErrorMessageGeneric.MAX150,
        value: 150,
      },
      minLength: {
        message: ErrorMessageMX.MAX_1_50_CHAR,
        value: 1,
      },

      required: {
        message: ErrorMessageMX.PF_RAZON_SOCIAL,
        value: true,
      },
    },
    type: TypeElement.TEXT_CAPITAL,
    value: 12,
  },
  {
    filter: PersonTypeHNEnum.MORAL,
    label: BasicDataLabels.TIPO_TAXID,
    name: "typeOfTaxId",
    regExp: RegularExp.CAPITAL_LETTER_NUMBERS,
    rules: {
      required: {
        message: ErrorMessageMX.PF_TIPO_TAX_ID2,
        value: true,
      },
    },
    type: TypeElement.TEXT_COMBO,
    typeArray: TypeElement.COMBO_ARRAY,
    value: 6,
  },
  {
    filter: PersonTypeHNEnum.MORAL,
    label: "Número de TAX ID",
    name: FieldsNamesRender.rfc,
    needCombo: true,
    regExp: RegularExp.ONLY_NUMBERS,
    rules: {
      maxLength: {
        message: ErrorMessageHN.MAX_1_50_CHAR,
        value: 50,
      },
      minLength: {
        message: ErrorMessageHN.MAX_1_50_CHAR,
        value: 1,
      },
      required: {
        message: ErrorMessageCR.PF_NUM_NITE,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 6,
  },
  {
    filter: PersonTypeHNEnum.MORAL,
    label: BasicDataLabels.TIPO_EMPRESA,
    name: FieldsNamesRender.type_company,
    rules: {
      required: {
        message: ErrorMessageMX.PF_COMPANY,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    value: 6,
  },
  {
    filter: PersonTypeHNEnum.MORAL,
    label: BasicDataLabels.TIPO_INDUSTRIA,
    name: FieldsNamesRender.type_industry,
    rules: {
      required: {
        message: ErrorMessageMX.PF_INDUSTRIA,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    value: 6,
  },
  {
    filter: PersonTypeHNEnum.MORAL,
    label: BasicDataLabels.MCC,
    name: FieldsNamesRender.mcc,
    rules: {
      required: {
        message: ErrorMessageMX.PF_MCC,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    typeArray: TypeElement.COMBO_ARRAY,
    value: 6,
  },
  {
    filter: PersonTypeHNEnum.MORAL,
    label: BasicDataLabels.FECHA_CONST,
    name: FieldsNamesRender.date,
    type: TypeElement.DATE_PICKER,
    value: 6,
  },
  {
    filter: PersonTypeHNEnum.MORAL,
    label: BasicDataLabels.WEB,
    name: FieldsNamesRender.web,
    regExp: RegularExp.WEB,
    rules: {
      pattern: {
        message: ErrorMessageMX.REG_EXP_WEB,
        value: WEB_SITE_REGEX,
      },
      required: {
        message: ErrorMessageMX.PF_WEB,
        value: true,
      },
    },
    type: TypeElement.WEB,
    value: 6,
  },

  {
    filter: PersonTypeHNEnum.FISICA,
    label: BasicDataLabels.NOMBRE,
    name: FieldsNamesRender.names,
    regExp: RegularExp.ONLY_LETTERS,
    rules: {
      maxLength: {
        message: ErrorMessageMX.MAX_1_50_CHAR,
        value: 50,
      },
      minLength: {
        message: ErrorMessageMX.MAX_1_50_CHAR,
        value: 1,
      },
      pattern: {
        message: ErrorMessageMX.LETRAS,
        value: new RegExp(RegularExp.ONLY_LETTERS),
      },
      required: {
        message: ErrorMessageMX.PF_NOMBRE,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 6,
  },
  {
    filter: PersonTypeHNEnum.FISICA,
    label: BasicDataLabels.APELLIDO,
    name: FieldsNamesRender.surname,
    regExp: RegularExp.ONLY_LETTERS,
    rules: {
      maxLength: {
        message: ErrorMessageMX.MAX_1_50_CHAR,
        value: 50,
      },
      minLength: {
        message: ErrorMessageMX.MAX_1_50_CHAR,
        value: 1,
      },
      pattern: {
        message: ErrorMessageMX.LETRAS,
        value: new RegExp(RegularExp.ONLY_LETTERS),
      },
      required: {
        message: ErrorMessageMX.PF_APELLIDO,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 6,
  },
  {
    filter: PersonTypeHNEnum.FISICA,
    label: BasicDataLabels.TIPO_TAXID,
    name: "typeOfTaxId",
    regExp: RegularExp.CAPITAL_LETTER_NUMBERS,
    rules: {
      required: {
        message: ErrorMessageMX.PF_TIPO_TAX_ID2,
        value: true,
      },
    },
    type: TypeElement.TEXT_COMBO,
    typeArray: TypeElement.COMBO_ARRAY,
    value: 6,
  },

  {
    filter: PersonTypeHNEnum.FISICA,
    label: "Número de TAX ID",
    name: FieldsNamesRender.rfc,
    needCombo: true,
    regExp: RegularExp.ONLY_NUMBERS,
    rules: {
      maxLength: {
        message: ErrorMessageHN.MAX_1_50_CHAR,
        value: 50,
      },
      minLength: {
        message: ErrorMessageHN.MAX_1_50_CHAR,
        value: 1,
      },
      required: {
        message: ErrorMessageCR.PF_NUM_NITE,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 6,
  },

  {
    filter: PersonTypeHNEnum.FISICA,
    label: BasicDataLabels.TIPO_INDUSTRIA,
    name: FieldsNamesRender.type_industry,
    rules: {
      required: {
        message: ErrorMessageMX.PF_INDUSTRIA,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    value: 6,
  },
  {
    filter: PersonTypeHNEnum.FISICA,
    label: BasicDataLabels.MCC,
    name: FieldsNamesRender.mcc,
    rules: {
      required: {
        message: ErrorMessageMX.PF_MCC,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    typeArray: TypeElement.COMBO_ARRAY,
    value: 6,
  },
  {
    filter: PersonTypeHNEnum.FISICA,
    label: BasicDataLabels.FECHA_CONST,
    name: FieldsNamesRender.date,
    type: TypeElement.DATE_PICKER,
    value: 6,
  },
  {
    filter: PersonTypeHNEnum.FISICA,
    label: BasicDataLabels.WEB,
    name: FieldsNamesRender.web,
    regExp: RegularExp.WEB,
    rules: {
      pattern: {
        message: ErrorMessageMX.REG_EXP_WEB,
        value: WEB_SITE_REGEX,
      },
      required: {
        message: ErrorMessageMX.PF_WEB,
        value: true,
      },
    },
    type: TypeElement.WEB,
    value: 12,
  },
];
