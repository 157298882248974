import React from "react";
import { IAppState } from "../../store/reducer";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  getHistorySessionData,
  getMerchantsRequest,
  IAppAction,
} from "../../store/actionCreators";
import { connect } from "react-redux";
import { useHistoryIndexState } from "./state/useHistoryIndexState";
import { Button, Container } from "@material-ui/core";
import { ListHistoryContainer } from "../../components/ListHistory/ListHistoryContainer";
import { HeaderHistory } from "../../components/HeaderHistory/Title/HeaderHistory";
import { FiltersHistory } from "../../components/HeaderHistory/Filters/FiltersHistory";
import { RequestQuerySearch } from "../../../types/request_query_search";

export interface IHistoryStateProps {
  state: IAppState;
}

export interface IHistoryFunctionsProps {
  getHistorySessionData: (request: RequestQuerySearch) => void;
  getMerchants: (payload: {
    offset: number;
    text: string;
    merchant_id?: string;
  }) => void;
}

export type HistoryIndexProps = IHistoryStateProps & IHistoryFunctionsProps;

export const HistoryIndex: React.FC<HistoryIndexProps> = (
  props: HistoryIndexProps
) => {
  const { data, state, filters, pagination } = useHistoryIndexState(props);
  return (
    <React.Fragment>
      <Container fixed>
        <HeaderHistory isAdmin={state.isAdmin} />
        <FiltersHistory
          isAdmin={state.isAdmin}
          handleDate={filters.handleDate}
          value={filters.selectedDate}
          autocomplete={filters.autocomplete}
          filterComponent={filters.filterComponent}
        />
        <ListHistoryContainer
          historyDashboard={data.historyData}
          isAdmin={state.isAdmin}
          pagination={pagination}
          isLoading={state.isLoading}
          isEmptyHistory={state.isEmptyHistory}
          timeZoneRegion={state.timeZoneRegion}
        />
        <Button
          style={{ fontWeight: 500, marginTop: "20px" }}
          variant="outlined"
          onClick={state.handlerReturnPageSupport}
        >
          Regresar
        </Button>
      </Container>
    </React.Fragment>
  );
};

export const mapStateToProps: (state: IAppState) => IHistoryStateProps = (
  state: IAppState
): IHistoryStateProps => ({
  state: state,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => IHistoryFunctionsProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): IHistoryFunctionsProps => ({
  getHistorySessionData: (request: RequestQuerySearch): void =>
    dispatch(getHistorySessionData(request)),
  getMerchants: (payload: {
    offset: number;
    text: string;
    merchant_id?: string;
  }): void => dispatch(getMerchantsRequest(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HistoryIndex);
