import { ChangeEvent, useEffect, useState } from "react";
import { DashboardTableRowProps } from "../DashboardTableRow";
import { defaultTo, get, includes, unset } from "lodash";
import {
  RowStatusEnum,
  StatusEnum,
  StatusFlowEnum,
  StatusType,
} from "../../../../../../shared/infrastructure/StatusEnum";
import { TransactionTable } from "../../../../../../../types/transaction_table";
import { KindEnum } from "../../../../../../shared/infrastructure/KindEnum";

export interface DashboardTableRowState {
  selectProps: {
    openAmountChangeModal: boolean;
    reasonChangeAmountValue: string | undefined;
    amountEditValue: string;
    openOmit: boolean;
    external: boolean;
    externalType: string | null;
    omitReason: string;
    rejectModalOpen: boolean;
    rejectReason: string;
  };
  actions: {
    handleTransactionAmountChange: () => (value: string) => void;
    handleClose: () => () => void;
    handleAccept: () => () => void;
    handleInputReasonChangeAmount: () => (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    handleChangeCheckbox: (
      event: React.ChangeEvent<HTMLInputElement>,
      type: StatusEnum,
      statusFlow: StatusFlowEnum
    ) => void;
    handleChangeCheckboxAll: (
      e: React.ChangeEvent<HTMLInputElement>,
      transaction: TransactionTable | undefined,
      all: boolean
    ) => void;
    handleCloseOmit: () => void;
    handleChangeExternal: (value: boolean) => void;
    handleChangeExternalType: (
      _event: React.MouseEvent<HTMLElement>,
      newExternal: string | null
    ) => void;
    handleChangeOmitReason: () => (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    handleAcceptOmit: (reason: string) => void;
    handleAcceptReject: () => () => void;
    handleCloseReject: () => () => void;
    handleChangeRejectReason: () => (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    transactionIdFilter?: () => void;
  };
}

export const useDashboardTableRowState = (
  props: DashboardTableRowProps
): DashboardTableRowState => {
  const [openAmountChangeModal, setOpenAmountChangeModal] = useState<boolean>(
    false
  );
  const [openOmit, setOpenOmit] = useState<boolean>(false);
  const [external, setExternal] = useState<boolean>(false);
  const [typeBeforeCheck, setTypeBeforeCheck] = useState<string>("process");
  const [reasonChangeAmountValue, setReasonChangeAmountValue] = useState<
    string | undefined
  >(props.billingData?.modify?.reason_change_amount);
  const [amountEditValue, setAmountEditValue] = useState<string>("");
  const [externalType, setExternalType] = useState<string | null>("cash");
  const [omitReason, setOmitReason] = useState<string>(
    get(props.billingData, "omit.reason", "")
  );
  const [rejectModalOpen, setRejectModalOpen] = useState<boolean>(false);
  const [rejectReason, setRejectReason] = useState<string>(
    get(props.billingData, "reject.reason", "")
  );
  const updateStatusBilling = (
    type: StatusEnum,
    statusFlow?: StatusFlowEnum,
    deleteOmit?: boolean,
    statusRow?: string
  ) => {
    let dataRow = props.billingData;
    if (deleteOmit) {
      unset(dataRow, "omit");
    }
    props.setBillingDataRow({
      ...dataRow,
      status: type as StatusType,
      statusRow: includes(
        [
          KindEnum.INVOICE,
          KindEnum.VOUCHER,
          KindEnum.CHARGE,
          KindEnum.DISPERSION,
          KindEnum.RECEIVABLE,
        ],
        get(props.billingData, "transaction.kind", "")
      )
        ? statusRow
        : "",
    });

    if (!props.billingData?.transaction?.stillReprocessing) {
      props.setBillingDataRow({
        ...props.billingData,
        status: type as StatusType,
        statusRow: includes(
          [
            KindEnum.INVOICE,
            KindEnum.VOUCHER,
            KindEnum.CHARGE,
            KindEnum.DISPERSION,
            KindEnum.RECEIVABLE,
          ],
          get(props.billingData, "transaction.kind", "")
        )
          ? statusRow
          : "",
      });
      if (statusFlow === StatusFlowEnum.VALIDATOR)
        props.validateCheckboxByTaxId(props.billingData!, type);
    }
  };

  useEffect(() => {
    switch (props.statusRows) {
      case StatusEnum.PROCESS:
        updateStatusBilling(StatusEnum.PROCESS, undefined, true);
        break;
      case StatusEnum.PENDING:
        updateStatusBilling(StatusEnum.PENDING, undefined, true);
        break;
      case StatusEnum.REJECT:
        setRejectReason(props.reasonCheckBox);
        updateStatusBilling(StatusEnum.REJECT);
        break;
      case StatusEnum.OMIT:
        setOmitReason(props.reasonCheckBox);
        updateStatusBilling(StatusEnum.OMIT);
        break;
      case StatusEnum.REPROCESS:
        updateStatusBilling(
          StatusEnum.PROCESS,
          undefined,
          true,
          RowStatusEnum.Reprocess
        );
        break;
    }
  }, [props.statusRows]);

  const handleTransactionAmountChange = () => (value: string) => {
    setOpenAmountChangeModal(true);
    setAmountEditValue(value);
  };

  const handleClose = () => () => {
    setOpenAmountChangeModal(false);
    !props.billingData?.modify && setReasonChangeAmountValue("");
  };

  const handleAccept = () => () => {
    setOpenAmountChangeModal(false);
    props.setBillingDataRow({
      ...props.billingData,
      transaction: {
        ...props.billingData!.transaction!,
        amount: Number(amountEditValue),
      },
      modify: {
        value: true,
        reason_change_amount: reasonChangeAmountValue,
      },
    });
  };

  const handleInputReasonChangeAmount = () => (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setReasonChangeAmountValue(e.target.value);
  };

  // Methods for omitHTMLInputElement
  const handleChangeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: StatusEnum,
    statusFlow: StatusFlowEnum
  ) => {
    setTypeBeforeCheck(type);
    if (!event.target.checked && statusFlow === StatusFlowEnum.VALIDATOR) {
      updateStatusBilling(StatusEnum.PENDING, statusFlow);
      props.handleDefaultValuesRows();
      return;
    }
    if (type === StatusEnum.REJECT) {
      setRejectModalOpen(true);
      return;
    }
    if (type === StatusEnum.PROCESS && !event.target.checked) {
      updateStatusBilling(
        StatusEnum.OMIT,
        statusFlow,
        false,
        RowStatusEnum.Omit
      );
      props.handleDefaultValuesRows();
      setOpenOmit(true);
      return;
    }
    if (
      (type === StatusEnum.PENDING || type === StatusEnum.REPROCESS) &&
      !event.target.checked
    ) {
      updateStatusBilling(
        StatusEnum.OMIT,
        statusFlow,
        false,
        RowStatusEnum.Omit
      );
      props.handleDefaultValuesRows();
      setOpenOmit(true);
      return;
    }
    if (type === StatusEnum.REPROCESS) {
      updateStatusBilling(
        StatusEnum.PROCESS,
        statusFlow,
        false,
        RowStatusEnum.Reprocess
      );
      props.handleDefaultValuesRows();
      return;
    }

    if (event.target.checked) updateStatusBilling(type, statusFlow, true);
    props.handleDefaultValuesRows();
  };

  const handleChangeCheckboxAll = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.checked) {
      props.setBillingDataRow({
        ...props.billingData,
        statusAll: false,
      });
      props.transactionIdFilter();
      return;
    }
    if (event.target.checked) {
      props.setBillingDataRow({
        ...props.billingData,
        statusAll: true,
      });
    }
    props.transactionIdFilter();
  };

  const handleCloseOmit = () => {
    setOpenOmit(false);
    updateStatusBilling(typeBeforeCheck as StatusEnum);
  };

  const handleCloseReject = () => () => {
    setRejectModalOpen(false);
    setRejectReason("");
  };

  const handleChangeExternal = (value: boolean) => {
    setExternal(value);
  };

  const handleChangeExternalType = (
    _event: React.MouseEvent<HTMLElement>,
    newExternal: string | null
  ) => {
    if (!newExternal) return;
    setExternalType(defaultTo(newExternal, "other"));
  };

  const handleChangeOmitReason = () => (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setOmitReason(e.target.value);
  };

  const handleChangeRejectReason = () => (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRejectReason(e.target.value);
  };

  const handleAcceptOmit = (reason: string) => {
    const type: string = get(props.billingData, "transaction.kind", "");
    setOpenOmit(false);
    props.setBillingDataRow({
      ...props.billingData,
      status: "omit",
      omit: {
        external: type === "charge" ? external : undefined,
        external_detail: type === "charge" ? reason : undefined,
        external_type: type === "charge" ? externalType! : undefined,
        reason: type !== "charge" ? reason : undefined,
      },
    });
    props.handleDefaultValuesRows();
  };

  const handleAcceptReject = () => () => {
    setRejectModalOpen(false);
    props.setBillingDataRow({
      ...props.billingData,
      status: StatusEnum.REJECT,
      reject: {
        reason: rejectReason,
      },
    });
    props.validateCheckboxByTaxId(props.billingData!, StatusEnum.REJECT);
    props.handleDefaultValuesRows();
  };

  return {
    selectProps: {
      openAmountChangeModal,
      amountEditValue,
      reasonChangeAmountValue,
      openOmit,
      external,
      externalType,
      omitReason,
      rejectModalOpen,
      rejectReason,
    },
    actions: {
      handleAccept: handleAccept,
      handleClose: handleClose,
      handleInputReasonChangeAmount: handleInputReasonChangeAmount,
      handleTransactionAmountChange: handleTransactionAmountChange,
      handleChangeCheckbox: handleChangeCheckbox,
      handleChangeCheckboxAll: handleChangeCheckboxAll,
      handleCloseOmit: handleCloseOmit,
      handleChangeExternal: handleChangeExternal,
      handleChangeExternalType: handleChangeExternalType,
      handleChangeOmitReason: handleChangeOmitReason,
      handleAcceptOmit: handleAcceptOmit,
      handleAcceptReject: handleAcceptReject,
      handleCloseReject: handleCloseReject,
      handleChangeRejectReason: handleChangeRejectReason,
    },
  };
};
