export enum AccordionLabelsEnum {
  BASIC_DATA = "Datos Básicos",
  BILLING = "Facturación",
  BILLING_DATA = "Datos de Facturación",
  BUSINESS_RULES = "Reglas de negocio",
  CHARGES = "Cobros",
  COMPANY_DATA = "Datos de la Empresa",
  CONFIGURED_INFORMATION = "Información configurada",
  CONTACT_DATA = "Datos de Contacto",
  DEFERRED = "Diferidos",
  DEVELOPERS = "Desarrolladores",
  DISPERSION = "Dispersión",
  FINANCIAL_SETUP = "Configuración financiera",
  LEGAL_DATA = "Datos legales",
  PROSECUTION = "Procesamiento",
  RATES_AND_DISCOUNTS = "Tarifas y descuentos",
  SERVICES = "Servicios",
  USERS = "Usuarios",
  WALLET = "Billetera",
}

export enum AccordionRulesLabelsEnum {
  BUSINESS_RULES_CARD = "Reglas de tarjeta",
  BUSINESS_RULES_PAYOUTS = "Reglas de payouts",
  BUSINESS_RULES_BTN_TEXT = "Edita y revisa todas las reglas",
  ALERT_BTN_TEXT = "Configurar",
  ALERT_TITLE = "Aún no has agregado reglas del negocio",
  ALERT_TYPE = "informative",
}
