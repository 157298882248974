export enum ConfigurationIdEnum {
  CN001 = "cn001",
  CN002 = "cn002",
  CN003 = "cn003",
  CN004 = "cn004",
  CN005 = "cn005",
  CN006 = "cn006",
  CN010 = "cn010",
  CN011 = "cn011",
  CN015 = "cn015",
  CN016 = "cn016",
  CN017 = "cn017",
  CN007 = "cn007",
  CN018 = "cn018",
  CN014 = "cn014",
}
