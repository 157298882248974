export enum RuleEnum {
  ACCOUNT_TYPE = "accountType",
  AMOUNT = "totalAmount",
  BANK = "bankId",
  DOCUMENT_TYPE = "documentType",
  VALIDATE_ENROLL = "enroll",
}

export enum PriorityEnum {
  LAST = "LAST",
}
