import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { GetAllModulesResponse } from "../../../../types/get_all_modules_response";
import { ChangeStatusModuleRequest } from "../../../../types/change_status_module_request";
import { IChangeStatusModule } from "../../../shared/interfaces/IChangeStatusModule";
import { TypeEnum } from "../../../shared/enums/typeEnum";

export const getAllModules = createAsyncThunk<GetAllModulesResponse[]>(
  "modules/getAllModules",
  async () => {
    const response = await axios.get<GetAllModulesResponse[]>(
      API_ROUTES.GET_ALL_MODULES
    );

    return response.data.sort((a, b) => a.id.localeCompare(b.id));
  }
);
export const getAllRoles = createAsyncThunk<
  string[],
  { type: TypeEnum; currentRole?: string }
>("modules/getAllRoles", async (data) => {
  const response = await axios.get<string[]>(API_ROUTES.GET_ALL_ROLES);

  if (data.type == TypeEnum.MODULE) {
    return response.data.filter((singleRol) =>
      singleRol.toLowerCase().includes("read")
    );
  }

  if (data.type == TypeEnum.COMPONENT && data.currentRole !== undefined) {
    return response.data.filter((singleRol) =>
      singleRol.includes(
        `${data.currentRole?.split["."][0]}.${data.currentRole?.split["."][1]}`
      )
    );
  }

  return response.data;
});
export const createUpdateModule = createAsyncThunk<
  {
    isNew: boolean;
    body: GetAllModulesResponse;
    modules: GetAllModulesResponse[];
  },
  { isNew: boolean; body: GetAllModulesResponse }
>("modules/createModule", async (data) => {
  await axios.post<GetAllModulesResponse>(
    API_ROUTES.POST_ALL_MODULES,
    data.body
  );
  const response = await axios.get<GetAllModulesResponse[]>(
    API_ROUTES.GET_ALL_MODULES
  );

  return {
    ...data,
    modules: response.data.sort((a, b) => a.id.localeCompare(b.id)),
  };
});

export const changeStatusModule = createAsyncThunk<
  IChangeStatusModule,
  IChangeStatusModule
>("modules/changeStatusModule", async (payload) => {
  await axios.put<ChangeStatusModuleRequest>(
    API_ROUTES.UPDATE_MODULES(payload.id),
    payload.body
  );

  return payload;
});
