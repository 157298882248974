import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import React, { useEffect, useState } from "react";
import {
  createUpdateComponent,
  GetComponents,
  UpdateStatus,
} from "../../../store/thunks/components/components.thunks";
import { useParams } from "react-router-dom";
import { cloneDeep, defaultTo, get, remove, set } from "lodash";
import { GetComponentsResponse } from "../../../../types/get_components_response";
import {
  handleActionPagination,
  handlePagination,
} from "../../../store/utils/component.utils";
import { StatusEnum } from "../../../shared/enums/statusEnum";
import { updateComponent } from "../../../store/actions/components/components.actions";
import { GetAllModulesResponse } from "../../../../types/get_all_modules_response";
import {
  changeStatusModule,
  getAllRoles,
} from "../../../store/thunks/modules/modules.thunks";
import { useForm, UseFormReturn } from "react-hook-form";
import { CreateEditRequest } from "../../../../types/create_edit_request";
import { FieldsEnum } from "../../../shared/enums/fieldsEnun";
import {
  clearModule,
  setModule,
} from "../../../store/actions/modules/modules.actions";
import { TypeEnum } from "../../../shared/enums/typeEnum";

export const useComponentsContainer = () => {
  const dispatch = useAppDispatch();
  const { isLoadingGetComponents, components, dataForm } = useAppSelector(
    (state) => state.components
  );
  const { moduleId } = useParams();

  const [openModalDialog, setOpenModalDialog] = useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(5);
  const [total, setTotal] = useState<number>(0);
  const [isAction, setIsAction] = useState<boolean>(false);
  const getInitialValue = (
    initData: Partial<CreateEditRequest>
  ): Partial<CreateEditRequest> => {
    return {
      description: get(initData, FieldsEnum.description, ""),
      id: get(initData, FieldsEnum.id, ""),
      roles: get(initData, FieldsEnum.roles, []),
    };
  };
  const form: UseFormReturn<CreateEditRequest> = useForm<CreateEditRequest>({
    defaultValues: {
      ...getInitialValue(dataForm),
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const [componentsSized, setComponentsSized] = useState<
    GetComponentsResponse[]
  >([]);

  const handleChangePage = (newPage: number) => {
    setComponentsSized(
      handlePagination(newPage, limit, page, limit, components)
    );
    setPage(newPage);
  };
  const handleChangePageSize = (newLimitPage: number) => {
    setComponentsSized(
      handlePagination(page, newLimitPage, page, limit, components)
    );
    setLimit(newLimitPage);
  };

  const handlerOpenModalDialog = (edit?: boolean, parentId?: String) => {
    if (!edit) {
      form.setValue("id", parentId ? `${parentId}.` : "");
      form.setValue("description", "");
      form.setValue("roles", []);
    }
    setOpenModalDialog(true);
  };
  const handleClickAddButton = () => {
    dispatch(clearModule());
    setIsNew(true);
    handlerOpenModalDialog(false, moduleId);
  };
  const handleChangeSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    row: GetComponentsResponse
  ) => {
    dispatch(
      changeStatusModule({
        body: {
          roles: row.roles,
          status: event.target.checked
            ? StatusEnum.ENABLED
            : StatusEnum.DISABLED,
        },
        id: row.id,
      })
    );
    setIsAction(true);
    const component: GetComponentsResponse = cloneDeep(row);
    const auxComponents: GetComponentsResponse[] = cloneDeep(components);

    const index: number = components.findIndex(
      (component: GetComponentsResponse) => component.id === row.id
    );

    set(
      component,
      "status",
      event.target.checked ? StatusEnum.ENABLED : StatusEnum.DISABLED
    );

    auxComponents[index] = component;

    dispatch(updateComponent(auxComponents));
    dispatch(
      UpdateStatus({
        componentId: component.id,
        componentUpdate: { roles: component.roles, status: component.status },
      })
    );
  };

  const validateForm = () => {
    if (defaultTo(form.getValues("id"), "").length === 0) {
      form.register("id", { required: true });
      form.setError("id", { type: "required" });
    }
    if (defaultTo(form.getValues("description"), "").length === 0) {
      form.register("description", { required: true });
      form.setError("description", { type: "required" });
    }
    if (defaultTo(form.getValues("roles"), []).length === 0) {
      form.register("roles", { required: true });
      form.setError("roles", { type: "required" });
    }

    return form.trigger();
  };

  const handlerCloseModalDialog = () => {
    setOpenModalDialog(false);
    setIsNew(true);
    form.clearErrors();
  };
  const handleClickEditButton = (row: GetAllModulesResponse) => {
    form.setValue("id", row.id);
    form.setValue("description", row.description);
    form.setValue("roles", row.roles);
    dispatch(setModule(row));
    setIsNew(false);
    handlerOpenModalDialog(true);
  };
  const handlerCreateOrEdit = async (data: Partial<GetAllModulesResponse>) => {
    const validForm: boolean = await validateForm();

    if (validForm) {
      setOpenModalDialog(false);
      const id = form.getValues("id") as string;
      const description = form.getValues("description") as string;
      const roles = form.getValues("roles") as string[];

      dispatch(
        createUpdateComponent({
          body: {
            description,
            id,
            parent: moduleId || "",
            roles,
            status: isNew ? StatusEnum.ENABLED : (data.status as StatusEnum),
            type: data.type!,
          },
          isNew: isNew,
        })
      );
    }
  };
  const handlerDeleteRoles = (e: React.MouseEvent, chipToDelete: string) => {
    form.setValue(
      "roles",
      remove(form.getValues("roles") || [], (value) => value !== chipToDelete)
    );
    e.stopPropagation();
  };

  useEffect(() => {
    dispatch(getAllRoles({ type: TypeEnum.COMPONENT }));
    dispatch(GetComponents({ moduleId }));
  }, []);

  useEffect(() => {
    if (!isAction) {
      setComponentsSized(components.slice(0, limit));
      setTotal(components.length);
    } else {
      setComponentsSized(handleActionPagination(page, limit, components));
    }
    setIsAction(false);
  }, [components]);

  return {
    components: componentsSized,
    form,
    handleChangePage,
    handleChangePageSize,
    handleChangeSwitch,
    handleClickAddButton,
    handleClickEditButton,
    handlerOpenModalDialog,
    isLoadingGetComponents,
    limit,
    modalDialogProps: {
      handlerCloseModalDialog,
      handlerCreateOrEdit,
      handlerDeleteRoles,
      isNew,
      openModalDialog,
    },
    page,
    total,
  };
};
