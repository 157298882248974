import React from "react";
import {
  ModalContent,
  ModalContentButtonTypeEnum,
  ModalContentTypeEnum,
} from "@kushki/connect-ui";
import { ConfirmModalEnum } from "../../shared/infrastructure/enums/ConfirmModalEnum";
import { Grid, Typography } from "@mui/material";
import { GroupEnum } from "../../shared/infrastructure/enums/CreateRuleConstans";
import { defaultTo, get } from "lodash";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/reducer";
import { RuleDetail } from "../../../types/rule_detail";
import { GeneralActionEnum } from "../../shared/infrastructure/constants/RuleRequestManagerConstants";

export interface ConfirmModalProps {
  acceptButtonText?: string;
  cancelButtonText?: string;
  modalTitle: string;
  modalContentText: string;
  branch: number;
  customer: number;
  fullWidth?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  onClose: () => void;
  onSubmit: () => void;
  isFormEditMode: boolean;
  isLoading: boolean;
  tabName?: string;
  setAlertSuccessMsg?: (value: string) => void;
  setAlertErrorMsg?: (value: string) => void;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = (
  props: ConfirmModalProps
) => {
  const openModal: boolean | undefined = useSelector(
    (state: IAppState) => state.openConfirmModal
  );
  const ruleDetails: RuleDetail | undefined = useSelector(
    (state: IAppState) => state.ruleDetails
  );

  return (
    <ModalContent
      buttonType={"primary" as ModalContentButtonTypeEnum}
      titleText={props.modalTitle}
      descriptionText={""}
      isOpen={!!openModal}
      buttonText={defaultTo(
        props.acceptButtonText,
        ConfirmModalEnum.SEND_BUTTON
      )}
      type={"Default" as ModalContentTypeEnum}
      buttonAction={props.onSubmit}
      onClose={props.onClose}
      buttonPrimaryDisabled={props.isLoading}
      buttonSecondaryDisabled={props.isLoading}
      buttonSecondaryText={defaultTo(
        props.cancelButtonText,
        GeneralActionEnum.RETURN
      )}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body1" color="textPrimary">
            {props.modalContentText}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {get(ruleDetails, "entityName") === GroupEnum.BRANCH ? null : (
            <Typography
              variant="body1"
              color="textPrimary"
              fontWeight={550}
              paddingTop={1}
            >
              {`${ConfirmModalEnum.CUSTOMER}${props.customer}`}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            color="textPrimary"
            fontWeight={550}
            paddingTop={1}
          >
            {`${ConfirmModalEnum.BRANCH}${props.branch}`}
          </Typography>
        </Grid>
      </Grid>
    </ModalContent>
  );
};

ConfirmModal.defaultProps = {
  customer: 0,
  branch: 0,
  fullWidth: true,
  maxWidth: "md",
};
