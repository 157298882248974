enum CellHeaderTypeEnum {
  CHECK = "check",
  DEFAULT = "default",
}

enum CellHeaderCheckStateEnum {
  CHECKED = "checked",
  UNCHECKED = "unChecked",
  INDETERMINATE = "indeterminate",
}

enum CellHeaderAlignEnum {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export { CellHeaderTypeEnum, CellHeaderAlignEnum, CellHeaderCheckStateEnum };
