import React from "react";
import {
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from "@material-ui/core";

export const ValidatorUrl = () => {
  const theme = useTheme();
  return (
    <Grid container spacing={1} justify="center" alignItems="center">
      <Grid item xs={12}>
        <Typography variant="body2" color={"textPrimary"} gutterBottom>
          Estamos probando las URLs de tus webhooks.{" "}
        </Typography>
      </Grid>
      <Grid
        style={{ marginBottom: theme.spacing(1) }}
        item
        xs={12}
        container
        justify="center"
      >
        <CircularProgress size={60} thickness={5} color={"primary"} />
      </Grid>
    </Grid>
  );
};
