import { ErrorMessageEnum } from "../enums/error_message_enum";

export enum RegularExp {
  DEFAULT = "^[a-zA-Z0-9ñÑÁÉÍÓÚáéíóúÜü. ]*$",
  ALFA_NUMERIC = "^[a-z0-9]*$",
  CAPITAL_ALFA_NUMERIC = "^[a-zA-Z0-9]*$",
  SIMPLE_ADDRESS = "^[#.0-9a-zA-ZñÑÁÉÍÓÚáéíóúÜü\\s,-]+$",
}

export const accountRules = {
  abaCode: {
    maxLength: {
      message: `${ErrorMessageEnum.MAX_CHARACTERS} 9 caracteres`,
      value: 9,
    },
    pattern: {
      message: ErrorMessageEnum.ALFA_NUMERIC,
      value: new RegExp(RegularExp.ALFA_NUMERIC),
    },
    required: {
      message: ErrorMessageEnum.ABA_REQUIRED,
      value: true,
    },
  },
  bankCityOfOrigin: {
    maxLength: {
      message: `${ErrorMessageEnum.MAX_CHARACTERS} de 35`,
      value: 35,
    },
    pattern: {
      message: ErrorMessageEnum.BANK_CITY_OF_ORIGIN_VALID,
      value: new RegExp(RegularExp.SIMPLE_ADDRESS),
    },
    required: {
      message: ErrorMessageEnum.BANK_CITY_OF_ORIGIN_REQ,
      value: true,
    },
  },
  bankPostalCodeOrigin: {
    maxLength: {
      message: `${ErrorMessageEnum.MAX_CHARACTERS} de 16`,
      value: 16,
    },
    pattern: {
      message: ErrorMessageEnum.BANK_POSTAL_CODE_ORIGIN_VALID,
      value: new RegExp(RegularExp.SIMPLE_ADDRESS),
    },
    required: {
      message: ErrorMessageEnum.BANK_POSTAL_CODE_ORIGIN_REQ,
      value: true,
    },
  },
  beneficiaryName: {
    pattern: {
      message: ErrorMessageEnum.BENEFICIARY_NAME_VALID,
      value: new RegExp(RegularExp.SIMPLE_ADDRESS),
    },
    required: {
      message: ErrorMessageEnum.BENEFICIARY_NAME_REQ,
      value: true,
    },
  },
  beneficiaryNameInternationalTransfer: {
    maxLength: {
      message: `${ErrorMessageEnum.MAX_CHARACTERS} de 140`,
      value: 140,
    },
    pattern: {
      message: ErrorMessageEnum.BENEFICIARY_NAME_VALID,
      value: new RegExp(RegularExp.SIMPLE_ADDRESS),
    },
    required: {
      message: ErrorMessageEnum.BENEFICIARY_NAME_REQ,
      value: true,
    },
  },
  financialInstitutionAddress: {
    pattern: {
      message: ErrorMessageEnum.FINANCIAL_INSTITUTION_ADDRESS_VALID,
      value: new RegExp(RegularExp.SIMPLE_ADDRESS),
    },
    required: {
      message: ErrorMessageEnum.FINANCIAL_INSTITUTION_ADDRESS_REQ,
      value: true,
    },
  },
  financialInstitutionAddressInternationalTransfer: {
    maxLength: {
      message: `${ErrorMessageEnum.MAX_CHARACTERS} de 70`,
      value: 70,
    },
    pattern: {
      message: ErrorMessageEnum.FINANCIAL_INSTITUTION_ADDRESS_VALID,
      value: new RegExp(RegularExp.SIMPLE_ADDRESS),
    },
    required: {
      message: ErrorMessageEnum.FINANCIAL_INSTITUTION_ADDRESS_REQ,
      value: true,
    },
  },
  financialInstitutionName: {
    pattern: {
      message: ErrorMessageEnum.FINANCIAL_INSTITUTION_NAME_VALID,
      value: new RegExp(RegularExp.DEFAULT),
    },
    required: {
      message: ErrorMessageEnum.FINANCIAL_INSTITUTION_NAME_REQ,
      value: true,
    },
  },
  financialInstitutionNameInternationalTransfer: {
    maxLength: {
      message: `${ErrorMessageEnum.MAX_CHARACTERS} de 140`,
      value: 140,
    },
    pattern: {
      message: ErrorMessageEnum.FINANCIAL_INSTITUTION_NAME_VALID,
      value: new RegExp(RegularExp.DEFAULT),
    },
    required: {
      message: ErrorMessageEnum.FINANCIAL_INSTITUTION_NAME_REQ,
      value: true,
    },
  },
  ibanCode: {
    maxLength: {
      message: `${ErrorMessageEnum.MAX_CHARACTERS} 50 caracteres`,
      value: 50,
    },
    pattern: {
      message: ErrorMessageEnum.ALFA_NUMERIC,
      value: new RegExp(RegularExp.CAPITAL_ALFA_NUMERIC),
    },
    required: {
      message: ErrorMessageEnum.IBAN_REQUIRED,
      value: true,
    },
  },
  ibanCodeInternationalTransfer: {
    maxLength: {
      message: `${ErrorMessageEnum.MAX_CHARACTERS} 50 caracteres`,
      value: 50,
    },
    pattern: {
      message: ErrorMessageEnum.ALFA_NUMERIC,
      value: new RegExp(RegularExp.CAPITAL_ALFA_NUMERIC),
    },
    required: {
      message: ErrorMessageEnum.IBAN_REQUIRED,
      value: true,
    },
  },
  routingNumber: {
    maxLength: {
      message: `${ErrorMessageEnum.MAX_CHARACTERS} 50 caracteres`,
      value: 50,
    },
    pattern: {
      message: ErrorMessageEnum.ALFA_NUMERIC,
      value: new RegExp(RegularExp.ALFA_NUMERIC),
    },
    required: {
      message: ErrorMessageEnum.ROUTING_NUMBER_REQUIRED,
      value: false,
    },
  },
  swiftCode: {
    maxLength: {
      message: `${ErrorMessageEnum.MAX_CHARACTERS} 11 caracteres`,
      value: 11,
    },
    pattern: {
      message: ErrorMessageEnum.ALFA_NUMERIC,
      value: new RegExp(RegularExp.CAPITAL_ALFA_NUMERIC),
    },
    required: {
      message: ErrorMessageEnum.SWIFT_REQUIRED,
      value: true,
    },
  },
  swiftCodeInternationalTransfer: {
    maxLength: {
      message: `${ErrorMessageEnum.MAX_CHARACTERS} 11 caracteres`,
      value: 11,
    },
    pattern: {
      message: ErrorMessageEnum.ALFA_NUMERIC,
      value: new RegExp(RegularExp.CAPITAL_ALFA_NUMERIC),
    },
    required: {
      message: ErrorMessageEnum.SWIFT_REQUIRED,
      value: true,
    },
  },
};
