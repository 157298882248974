export enum DashboardTableTypeMessageEnum {
  TITLE = "Title",
  SUBTITLE = "Subtitle",
}

export enum DashboardTableEmptyTypeEnum {
  EMPTY_FILTER = "Filter",
  EMPTY_CALENDAR = "Calendar",
}

export const DashboardTableMessage: Record<
  DashboardTableEmptyTypeEnum,
  object
> = {
  [DashboardTableEmptyTypeEnum.EMPTY_FILTER]: {
    [DashboardTableTypeMessageEnum.TITLE]:
      "No se ha encontrado ninguna coincidencia",
    [DashboardTableTypeMessageEnum.SUBTITLE]: "",
  },
  [DashboardTableEmptyTypeEnum.EMPTY_CALENDAR]: {
    [DashboardTableTypeMessageEnum.TITLE]:
      "No se ha encontrado ninguna coincidencia con la fecha seleccionada",
    [DashboardTableTypeMessageEnum.SUBTITLE]: "Escoge otro rango de fecha",
  },
};
