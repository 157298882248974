/**
 * TransactionStatus
 */
export enum TransactionStatusEnum {
  APPROVAL = "APPROVAL",
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  INITIALIZED = "INITIALIZED",
  PROCESS = "IN PROCESS",
}

export enum TransactionStatusLabelEnum {
  APPROVAL = "Aprobada",
  DECLINED = "Declinada",
  INITIALIZED = "Inicializada",
  PROCESS = "En Proceso",
}

export const CATALOG_TRANSACTION_STATUS: Record<
  TransactionStatusEnum,
  TransactionStatusLabelEnum
> = {
  [TransactionStatusEnum.DECLINED]: TransactionStatusLabelEnum.DECLINED,
  [TransactionStatusEnum.INITIALIZED]: TransactionStatusLabelEnum.INITIALIZED,
  [TransactionStatusEnum.APPROVAL]: TransactionStatusLabelEnum.APPROVAL,
  [TransactionStatusEnum.APPROVED]: TransactionStatusLabelEnum.APPROVAL,
  [TransactionStatusEnum.PROCESS]: TransactionStatusLabelEnum.PROCESS,
};
