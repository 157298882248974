import React from "react";
import { Grid } from "@mui/material";
import {
  Operators,
  PlaceHolders,
} from "../../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { DatePickerComponent } from "./DatePickerComponent/DatePickerComponent";
import { useDateComponentSection } from "./state/useDateComponentSection";

export interface IDateComponentSectionProps {
  operator: string;
  setDate(value: string[]): void;
  value: string[];
  isDisable: boolean;
}

export const DateComponentSection: React.FC<IDateComponentSectionProps> = (
  props: IDateComponentSectionProps
) => {
  const { concatenateTimeStamp } = useDateComponentSection({
    onChange: props.setDate,
  });

  return (
    <Grid pt={1.5} container spacing={2}>
      <DatePickerComponent
        placeHolder={PlaceHolders.START_DATE}
        onChange={(value) => {
          concatenateTimeStamp({ value, type: "start" });
        }}
        value={props.value[0]}
        format={"DD/MMM/YYYY"}
      />
      {props.operator === Operators.BETWEEN_DATE && (
        <DatePickerComponent
          placeHolder={PlaceHolders.END_DATE}
          onChange={(value) => {
            concatenateTimeStamp({ value, type: "end" });
          }}
          value={props.value[1]}
          format={"DD/MMM/YYYY"}
        />
      )}
    </Grid>
  );
};
