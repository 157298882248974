import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { MerchantResponse } from "../../../../types/merchant_response";
import { IAuthInfo } from "../../../shared/infrastructure/interfaces/IAuthInfo";
import { ProcessorFetch } from "../../../shared/infrastructure/interfaces/ProcessorFetch";
import { useEffect, useState } from "react";
import { defaultTo, get, isEmpty } from "lodash";
import { environment } from "../../../environments/environment";
import { AxiosResponse } from "axios";
import {
  CountriesEnum,
  COUNTRY_DEFERRED,
} from "../../../shared/infrastructure/constants/countries-enum";
import { WebhookListFetch } from "../../../shared/interfaces/WebhookListFetch";
import axios from "../../../shared/axios-util";

export interface IUseMerchantConfiguration {
  merchant: MerchantResponse;
  authInfo: IAuthInfo;
  merchant_processors?: ProcessorFetch[];
  showDeferred: boolean;
  haveWebcheckout: boolean;
}

export const useMerchantConfiguration = (): IUseMerchantConfiguration => {
  const merchant: MerchantResponse = useSelector(
    (state: IAppState) => state.merchant!
  );

  const authInfo: IAuthInfo = useSelector(
    (state: IAppState) => state.authInfo!
  );

  const merchant_processors: ProcessorFetch[] = useSelector(
    (state: IAppState) => state.processors!
  );

  const showDeferred: boolean = COUNTRY_DEFERRED.includes(
    get(merchant, "country", "") as CountriesEnum
  );
  const [haveWebcheckout, setHaveWebheckout] = useState<boolean>(false);

  const getWebcheckoutConfiguration = async () => {
    try {
      const url: string = `${environment.kushkiUrl}/smartlink/v1/webcheckout/getConfiguration`;
      const axiosResponse: AxiosResponse<WebhookListFetch> = await axios.get(
        url
      );
      setHaveWebheckout(!!axiosResponse);
    } catch (e) {
      setHaveWebheckout(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(localStorage.getItem("merchantBasicInformation"))) {
      const merchantInfo: object = JSON.parse(
        defaultTo(localStorage.getItem("merchantBasicInformation"), "{}")
      );
      localStorage.setItem(
        "merchantBasicInformation",
        JSON.stringify({
          ...merchantInfo,
          origin: "configMerchant",
        })
      );
    }
    getWebcheckoutConfiguration();
  }, []);

  return {
    haveWebcheckout,
    merchant,
    authInfo,
    merchant_processors,
    showDeferred,
  };
};
