import React, { FC } from "react";
import { Box, Grid } from "@mui/material";
import { layoutStepContainerStyles as styles } from "./LayoutStepContainer.styles";
import FooterOptions from "../../components/FooterOptions/FooterOptions";
import {
  IFooterOptions,
  IModalOptions,
} from "../../components/FooterOptions/FooterOptions.interfaces";
import { ModalSimple } from "@kushki/connect-ui";
import WrappedTabs from "../../components/WrappedTabs/WrappedTabs";
import { IHeaderMassiveProps } from "./LayoutStepContainer.interfaces";
import SubheaderBranchMassive from "../../components/SubHeaderBranchMassive/SubheaderBranchMassive";
import {
  CANCELAR,
  EM_DESCRIPTION,
  EM_FINALIZAR_BUTTON,
  EM_TITLE,
} from "../../shared/constants/labels/ModalConstants";
import { ActionFooterEnum } from "../../shared/enums/ActionFooterEnum";
import { useLayoutStepContainerBranch } from "./state/useLayoutStepContainerBranch";
import { get } from "lodash";

export interface ILayoutStepContainerMassive {
  headerProps: IHeaderMassiveProps;
  renderComponent: JSX.Element;
  footerProps: IFooterOptions;
  modalProps: IModalOptions;
}

export const LayoutStepContainerBranch: FC<ILayoutStepContainerMassive> = (
  props: ILayoutStepContainerMassive
) => {
  useLayoutStepContainerBranch();

  return (
    <React.Fragment>
      <Box sx={styles.headerStyle}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <SubheaderBranchMassive
              country={props.headerProps.country}
              title={props.headerProps.title}
              isEdit={props.headerProps.isEdit}
              numberBranches={props.headerProps.numberBranches}
            />
          </Grid>

          <Grid item xs={12}>
            <WrappedTabs tabs={props.headerProps.tabsInfo.tabs} />
          </Grid>
        </Grid>
      </Box>

      <React.Fragment>{props.renderComponent}</React.Fragment>

      <Box sx={styles.boxFooter}>
        <FooterOptions
          primaryButton={props.footerProps.primaryButton}
          secondaryButton={props.footerProps.secondaryButton}
          isLoading={props.footerProps.isLoading}
          label={props.footerProps.label}
          isEdit={props.footerProps.isEdit}
          isMassive={true}
        />
      </Box>
      <ModalSimple
        onClickPrimary={() => {
          props.modalProps.setFinalization(false);
          props.modalProps.saveValues(ActionFooterEnum.CONTINUE);
        }}
        onClickSecondary={() => {
          props.modalProps.setFinalization(false);
        }}
        buttonPrimary={EM_FINALIZAR_BUTTON}
        buttonSecondary={CANCELAR}
        descriptionText={EM_DESCRIPTION}
        isOpen={get(props.modalProps, "isModalFinalization", false)}
        onClose={() => {
          props!.modalProps!.setFinalization(false);
        }}
        titleText={EM_TITLE}
        typeModal={1}
      />
    </React.Fragment>
  );
};
