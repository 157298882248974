/**
 * Security Wrapper ENUM
 */
export enum SecurityWrapperEnum {
  MODULE_ID = "M_MERCHANTS",
  TEST_CONNECTION = "M_MERCHANTS.DesarrolladoresWebhooks.ProbarConexion",
  DELETE = "M_MERCHANTS.DesarrolladoresWebhooks.Eliminar",
  EDIT_DELETE_WEBHOOK = "M_MERCHANTS.DesarrolladoresWebhooks.EditarEliminarWebhook",
  SAVE_WEBHOOK = "M_MERCHANTS.DesarrolladoresWebhooks.GuardarWebhook",
}
