import { createStyles, makeStyles } from "@mui/styles";
import { FilterBar } from "@kushki/connect-ui";
import React from "react";
import { useFilterTableComponentState } from "./state/useFilterTableComponentState";
import { get, isEmpty, isUndefined } from "lodash";
import { IFilterTableComponent } from "../../shared/infrastructure/interfaces/IFilterTableProps";
import { LabelEnum } from "../../shared/infrastructure/enums/LabelEnum";
import { getIsMassiveRules } from "../../shared/utils/localStorage";

const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      ".MuiDrawer-paper": {
        top: "70px !important",
      },
      ".css-lpnzq9": {
        "margin-bottom": "70px",
      },
    },
  })
);

export const FilterTableComponent: React.FC<IFilterTableComponent> = (
  props: IFilterTableComponent
) => {
  useStyles();
  const { categoryItems, setRequestFilter, changeFilter } =
    useFilterTableComponentState(props);

  if (!isUndefined(categoryItems) && !getIsMassiveRules()) {
    return (
      <FilterBar
        filterDetailBar={{
          filters: [],
          onChangeFilters: changeFilter,
        }}
        filterSideBar={{
          categoryItems,
          filtersCount: 0,
          isMinimized: false,
          isOpen: true,
          onClick: setRequestFilter,
          title: LabelEnum.FILTER_MODAL_TITLE,
        }}
        textFieldSearchBy={{
          onChange: (event) => {
            if (!isEmpty(get(event, "target.value", "")))
              props.handleDataChange(event.target.value);
          },
          items: props.filterSelectList,
          onItemSelected: (selected) => props.handleSelectItem(selected),
          placeholder: "Buscar por alias o procesador",
        }}
      />
    );
  } else {
    return null;
  }
};
