import React from "react";
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { DeferredSectionsConstants } from "../../shared/infrastructure/constants/DeferredConstants";
import {
  BRAZIL_PROCESSORS,
  ProcessorToShow,
} from "../../shared/infrastructure/constants/ProcessorsEnum";
import { basicInformationStyles } from "./BasicInformation.style";

export interface IBasicInformation {
  processorValue: string;
}

export const BasicInformation = (properties: IBasicInformation) => {
  const classes = basicInformationStyles();

  return (
    <div className={classes.paddingContent}>
      <Grid container>
        <Box mt={2}>
          <Typography className={classes.titleContent}>
            {DeferredSectionsConstants.PROCESSOR_TITTLE}
          </Typography>
          <RadioGroup
            aria-label="processor"
            name="processor"
            value={properties.processorValue}
            row
          >
            {BRAZIL_PROCESSORS.map((item: ProcessorToShow, index: number) => (
              <FormControlLabel
                disabled={true}
                key={index}
                value={item.value}
                control={<Radio color="primary" />}
                label={item.label}
              />
            ))}
          </RadioGroup>
        </Box>
      </Grid>
    </div>
  );
};
