import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  labelTitle: {
    color: "#023365",
    fontSize: "20px",
    fontWeight: 500,
    fontStyle: "normal",
  },
  large: {
    width: 36,
    height: 36,
  },
}));

interface IConfigSectionProps {
  icon: string;
  title: string;
  hidden?: boolean;
}

export const ConfigPanel: React.FC<IConfigSectionProps> = (
  props: React.PropsWithChildren<IConfigSectionProps>
) => {
  const style = useStyles();

  return (
    <>
      {props.hidden ? null : (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Box display="flex" flexGrow={1} mt={1} alignItems="flex-start">
                  <Box display="flex" flexGrow={0} pl={1}>
                    <Avatar className={style.large} src={props.icon} />
                  </Box>
                  <Box
                    display="flex"
                    flexGrow={1}
                    pl={3}
                    pt={1}
                    alignItems="flex-start"
                  >
                    <Box display="flex" flexGrow={1} flexDirection="column">
                      <Box mb={2}>
                        <Typography className={style.labelTitle}>
                          {props.title}
                        </Typography>
                      </Box>
                      <Grid container>{props.children}</Grid>
                    </Box>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};
