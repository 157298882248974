import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const useDropDownStyles = createNamedStyles({
  accordion: {
    backgroundColor: "#F7FAFC",
    padding: "10px",
    paddingLeft: "40px",
    marginBottom: "5px",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(min(100%,185px),1fr))",
  },
  gridFlex: {
    display: "flex",
  },
  gridFlexDesc: {
    display: "flex",
    marginBottom: "8px",
  },
  gridFlexIcon: {
    display: "flex",
    marginBottom: "5px",
  },
  gridMainTitle: {
    marginRight: "10px",
  },
  title: {
    fontWeight: 500,
    fontSize: "20px",
  },
  gridTitleStatus: {
    backgroundColor: "#E0FFF8",
    borderRadius: "4px",
    color: "#3B9D86",
    marginTop: "3px",
    paddingTop: "7px",
    paddingBottom: "3px",
    paddingLeft: "11px",
    paddingRight: "11px",
  },
  gridTitleStatusSecondary: {
    backgroundColor: "#E2E8F0",
    borderRadius: "4px",
    color: "#000000",
    marginTop: "3px",
    paddingTop: "7px",
    paddingBottom: "3px",
    paddingLeft: "11px",
    paddingRight: "11px",
  },
  bankPriority: {
    fontSize: "9px",
    fontWeight: 500,
  },
  flagIcon: {
    marginRight: "10px",
  },
  detailHeader: {
    color: "#6D7781",
    fontSize: "14px",
    fontWeight: "400",
  },
  detailDescription: {
    color: "#293036",
    fontSize: "15px",
    fontWeight: "400",
  },
});
