import { IUseAttachFile } from "./useAttachFile.interfaces";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { OPERATORS } from "../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { AttachFileProps } from "../AttachFile.interfaces";
import { LabelEnum } from "../../../shared/infrastructure/enums/LabelEnum";
import { useDispatch, useSelector } from "react-redux";
import {
  setTextFieldComponentActions,
  setVerifyMassiveConditionResponse,
  verifyMassiveConditionValueFile,
} from "../../../store/actionCreators";
import { IAppState } from "../../../store/reducer";
import { VerifyMassiveConditionResponse } from "../../../../types/verify_massive_condition_response";
import { defaultTo, get, isUndefined } from "lodash";

export const useAttachFile = ({
  label,
  operator,
}: AttachFileProps): IUseAttachFile => {
  const labels = {
    [LabelEnum.IP]: true,
    [LabelEnum.CARD_HOLDER]: true,
    [LabelEnum.CARD_ID]: true,
    [LabelEnum.MASKED_CARD]: true,
    [LabelEnum.BIN]: true,
  };
  const renderByOperator = {
    [OPERATORS.NOT_CONTAIN]: labels,
    [OPERATORS.CONTAIN]: labels,
    [OPERATORS.MULTIPLE_MATCH]: labels,
  };
  const errorMessages = {
    incorrectValues:
      "Existen errores en el formato de las filas, favor revise la fila: ",
    incorrectFileFormat:
      "El formato del archivo no está permitido. Intenta nuevamente",
    valueLimitExceeded:
      "El formato del archivo no está permitido, el número de filas excede el máximo permitido (1000)",
  };
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [base64, setBase64] = useState("");
  const [renderComponent, setRenderComponent] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const dispatch = useDispatch();
  const isLoading: boolean | undefined = useSelector(
    (state: IAppState) => state.validatingFile
  );
  const verifyResponse: VerifyMassiveConditionResponse | undefined =
    useSelector((state: IAppState) => state.verifyMassiveConditionResponse);
  const uploadInputRef = useRef<HTMLInputElement | null>(null);
  const removeDataSchema = (base64Data: string): string => {
    const [, encodedData] = base64Data.split(",");
    return encodedData;
  };
  const getFileExtension = (file: File): string => {
    const fileName = get(file, "name", "");
    const extension = fileName.split(".").pop();

    return defaultTo(extension, "");
  };
  const getFileNameWithoutExtension = (file: File): string => {
    const fileName = file.name;
    const dotIndex = fileName.lastIndexOf(".");
    if (dotIndex !== -1) {
      return fileName.slice(0, dotIndex);
    }
    return fileName;
  };
  const transformRowNumbers = (value: string): string => {
    const rowNumbers = value.trim();
    const arrayRowNumbers = rowNumbers.split(",");

    if (rowNumbers.includes(",") && arrayRowNumbers.length > 1) {
      const lastNumber = arrayRowNumbers.pop();

      return `${arrayRowNumbers.join(", ")} y ${lastNumber}`;
    }

    return rowNumbers;
  };
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result as string;

        setBase64(base64Data);
      };
      reader.readAsDataURL(file);
      setSelectedFile(file);
    }
  };
  const selectFile = () => {
    uploadInputRef.current!.click();
  };
  const deleteFile = () => {
    setSelectedFile(null);
    setBase64("");
    setErrorMessage("");
  };
  const toggleModal = (value: boolean) => {
    setOpenModal(value);
  };
  const validate = () => {
    if (getFileExtension(selectedFile!) === "csv") {
      dispatch(
        verifyMassiveConditionValueFile({
          contentFile: removeDataSchema(base64),
          label,
        })
      );
    } else {
      setErrorMessage(errorMessages.incorrectFileFormat);
    }
  };

  useEffect(() => {
    setRenderComponent(Boolean(renderByOperator[operator]?.[label]));
  }, [label, operator]);

  useEffect(() => {
    if (!isUndefined(verifyResponse)) {
      const status = verifyResponse.status;
      if (status in errorMessages) {
        const errorMessage = errorMessages[status];
        if (status === "incorrectValues") {
          const incorrectRows = get(
            verifyResponse,
            "metadata.incorrectRows",
            ""
          );
          setErrorMessage(errorMessage + transformRowNumbers(incorrectRows));
        } else {
          setErrorMessage(errorMessage);
        }
      } else {
        dispatch(
          setTextFieldComponentActions({
            label: get(verifyResponse, "label", ""),
            value: get(verifyResponse, "metadata.correctValues", ""),
          })
        );
        toggleModal(false);
      }
      dispatch(setVerifyMassiveConditionResponse(undefined));
    }
  }, [verifyResponse]);

  return {
    deleteFile,
    errorMessage,
    getFileExtension,
    getFileNameWithoutExtension,
    handleFileChange,
    isLoading: Boolean(isLoading),
    openModal,
    renderComponent,
    selectedFile,
    selectFile,
    toggleModal,
    uploadInputRef,
    validate,
  };
};
