import {
  Button,
  MenuList,
  MenuItem,
  Popover,
  Typography,
  Badge,
} from "@material-ui/core";
import {
  CSV_HEADERS,
  IButtonDownloadFile,
} from "./ButtonDownloadFile.interfaces";
import { get, set } from "lodash";
import CheckIcon from "@material-ui/icons/Check";
import { Download } from "react-feather";
import { Error } from "@material-ui/icons";
import { FORMAT_SEPARATED } from "../../shared/infrastructure/enums/FormatEnum";
import { FormatEnum } from "../../shared/infrastructure/enums/FormatEnum";
import { Headers } from "./ButtonDownloadFile.interfaces";
import React from "react";
import { setNotification } from "../../store/actionCreators";
import { TransactionData } from "../../../types/transaction_table";
import { useDispatch } from "react-redux";
import { useStyles } from "./ButtonDownloadFile.styles";

export const getValue = (row: TransactionData, header: Headers): string =>
  `${row[header.key] || header.default || ""}`;

export const arrayToCsv = (
  headers: Headers[],
  rows: TransactionData[],
  separated: string
) => {
  const csvRows = [];
  const headerValues = headers.map((header) => header.label);

  csvRows.push(headerValues.join(separated));
  for (const row of rows) {
    const rowValues = headers.map((header) => {
      const escaped = ("" + getValue(row, header)).replace(/\"/g, "\\");

      return `"${escaped}"`;
    });

    csvRows.push(rowValues.join(separated));
  }

  return csvRows.join("\n");
};

export const handleDownload = (
  data: ArrayBufferView | ArrayBuffer | Blob | string,
  Format: string
) => {
  const blob = new Blob([data], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");

  a.href = url;
  a.hidden = true;
  a.download = "Reembolsos." + Format;

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const ButtonDownloadFile: React.FC<IButtonDownloadFile> = (
  props: IButtonDownloadFile
) => {
  const options: { text: string; value: string }[] = [
    { text: "CSV", value: FormatEnum.CSV },
    { text: "JSON", value: FormatEnum.JSON },
    { text: "TXT", value: FormatEnum.TXT },
  ];
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const data = get(props, "refundData.data", []);
  let selectedData: TransactionData[] = [];
  let dataMap: TransactionData[] = [];

  if (data != undefined) {
    dataMap = data.map((item: TransactionData) => {
      return { ...item, action: get(item, "history[0].action") };
    });
    selectedData = dataMap?.filter((item: TransactionData) => item.selected);
    if (!selectedData.length) selectedData = dataMap;
  }

  const handleDownloadFile = (format: string) => {
    if (selectedData.length) {
      for (const data of selectedData) {
        const history = get(data, "history", []).slice(-1);
        const action = get(history, "[0].action", "");

        set(data, "action", action);
      }

      let fileData = JSON.stringify(selectedData);

      if (format != FormatEnum.JSON) {
        fileData = arrayToCsv(
          CSV_HEADERS,
          selectedData,
          FORMAT_SEPARATED[format]
        );
      }
      handleDownload(fileData, format);
      dispatch(
        setNotification({
          horizontal: "center",
          icon: CheckIcon,
          message: "Descarga exitosa",
          open: true,
          type: "success",
          vertical: "top",
        })
      );
    } else {
      dispatch(
        setNotification({
          horizontal: "center",
          icon: Error,
          message: "Ha ocurrido un error en la descarga. Inténtalo nuevamente",
          open: true,
          type: "error",
          vertical: "top",
        })
      );
    }
  };

  return (
    <React.Fragment>
      <div id="btn_download_file" onClick={handleClick}>
        <Button
          className={`${classes.buttonStyled}`}
          startIcon={<Download size={18} />}
          variant="outlined"
        >
          <Badge
            color="secondary"
            style={{
              color: "#023365",
              paddingBottom: "5px",
              paddingTop: "5px",
            }}
          >
            <Typography>Descargar</Typography>
          </Badge>
        </Button>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
      >
        <MenuList id="split-button-menu">
          {options.map((option) => (
            <MenuItem
              key={option.value}
              onClick={() => {
                setAnchorEl(null);
                handleDownloadFile(option.value);
              }}
            >
              {option.text}
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </React.Fragment>
  );
};
