import React from "react";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { Download } from "react-feather";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

export interface IButtonDownloadFile {
  downloadFile: () => void;
  loading: boolean;
  componentId: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "44px",
    marginRight: theme.spacing(1),
  },
}));

export const ButtonDownloadFile: React.FC<IButtonDownloadFile> = (
  props: React.PropsWithChildren<IButtonDownloadFile>
) => {
  const classes = useStyles();
  const { componentId, downloadFile, loading } = props;
  return (
    <Grid container justify={"flex-end"}>
      <ComponentSecurityWrapper componentId={componentId}>
        <Button
          className={classes.container}
          variant={"outlined"}
          size={"large"}
          startIcon={<Download size={20} />}
          onClick={downloadFile}
          endIcon={loading && <CircularProgress size={17} />}
          disabled={loading}
        >
          Descargar CSV
        </Button>
      </ComponentSecurityWrapper>
    </Grid>
  );
};
