import React from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import {
  IOptionSelection,
  RuleSchemaEnum,
} from "../../shared/infrastructure/constants/RuleRequestConstants";
import ConnectForm from "../common/ConnectForm/ConnectForm";
import {
  IRuleAuthenticationForm,
  IRuleSchema,
  IStrictCondition,
} from "../../shared/infrastructure/interfaces/IRuleAuthenticationForm";
import {
  ArrayField,
  Controller,
  useFieldArray,
  useWatch,
} from "react-hook-form";
import {
  AuthenticationRulesLabelField,
  getLabelValues,
  getOperatorValues,
} from "../../shared/infrastructure/constants/AuthenticationRulesConstants";
import { defaultTo, get } from "lodash";
import { ComponentText } from "../ComponentText/ComponentText";
import StrictConditionsSection from "./StrictConditionsSection";
import { LabelEnum } from "../../shared/infrastructure/enums/LabelEnum";
import { BuildField } from "./ValuesFields/BuildField";
import { Plus } from "react-feather";
import { OperatorsEnum } from "../../shared/infrastructure/constants/OperatorsEnum";

export interface ConfigurationInformationSectionProps {
  processors: IOptionSelection[];
  credentials: IOptionSelection[];
  index: number;
}

export const ConfigurationInformationSection: React.FC<ConfigurationInformationSectionProps> =
  (props: ConfigurationInformationSectionProps) => {
    return (
      <ConnectForm<IRuleAuthenticationForm>>
        {({ control, errors, getErrorMessage, getValues, watch }) => {
          const { fields, append, remove } = useFieldArray<IStrictCondition>({
            control,
            name: `strictCondition[${props.index}].authRules`,
          });
          const label: string = watch(
            `strictCondition[${props.index}].label`
          ) as string;

          const operator: string = watch(
            `strictCondition[${props.index}].operator`
          ) as string;

          const value: string = watch(
            `strictCondition[${props.index}].value`
          ) as string;
          const backupOtherValues: string = watch(
            `strictCondition.${props.index}.backupOtherValues`
          ) as string;
          const from: string = watch(
            `strictCondition[${props.index}].from`
          ) as string;
          const to: string = watch(
            `strictCondition[${props.index}].to`
          ) as string;
          const conditionsArray: IRuleSchema[] = watch(
            `strictCondition[${props.index}].authRules`
          );
          const countries = useWatch({
            name: "country",
          }) as string[];
          const service = useWatch({
            name: "service",
          }) as string;

          const ruleType = useWatch({
            name: "type",
          }) as string;

          return (
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>
                      {AuthenticationRulesLabelField.LABEL}
                    </InputLabel>
                    <Controller
                      control={control}
                      name={`strictCondition[${props.index}].label`}
                      defaultValue={label}
                      error={
                        !!get(
                          errors,
                          `strictCondition[${props.index}].label`,
                          null
                        )
                      }
                      render={(field) => (
                        <Select
                          data-testid={"SelectLabelValues"}
                          {...field}
                          label={AuthenticationRulesLabelField.LABEL}
                        >
                          {getLabelValues(service, ruleType).map(
                            (
                              actionValue: { name: string; value: string },
                              index: number
                            ) => (
                              <MenuItem key={index} value={actionValue.value}>
                                {actionValue.name}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      )}
                    />
                    <FormHelperText
                      error={
                        !!get(
                          errors,
                          `strictCondition[${props.index}].label`,
                          null
                        )
                      }
                    >
                      {!!get(
                        errors,
                        `strictCondition[${props.index}].label`,
                        null
                      ) &&
                        getErrorMessage(
                          get(
                            errors,
                            `strictCondition[${props.index}].label`,
                            null
                          )
                        )}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  {label !== RuleSchemaEnum.transactionCreated && (
                    <FormControl
                      variant="outlined"
                      error={
                        !!get(
                          errors,
                          `strictCondition[${props.index}].operator`
                        )
                      }
                      fullWidth
                    >
                      <InputLabel>
                        {AuthenticationRulesLabelField.OPERATOR}
                      </InputLabel>
                      <Controller
                        control={control}
                        name={`strictCondition[${props.index}].operator`}
                        defaultValue={operator}
                        error={
                          !!get(
                            errors,
                            `strictCondition[${props.index}].operator`,
                            null
                          )
                        }
                        render={(field) => (
                          <Select
                            data-testid={"SelectOperatorValues"}
                            {...field}
                            label={AuthenticationRulesLabelField.OPERATOR}
                          >
                            {getOperatorValues(label).map(
                              (
                                actionValue: { name: string; value: string },
                                index: number
                              ) => (
                                <MenuItem key={index} value={actionValue.value}>
                                  {actionValue.name}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        )}
                      />
                      <FormHelperText
                        error={
                          !!get(
                            errors,
                            `strictCondition[${props.index}].operator`,
                            null
                          )
                        }
                      >
                        {!!get(
                          errors,
                          `strictCondition[${props.index}].operator`,
                          null
                        ) &&
                          getErrorMessage(
                            get(
                              errors,
                              `strictCondition.${props.index}.operator`
                            )
                          )}
                      </FormHelperText>
                    </FormControl>
                  )}
                  {label === RuleSchemaEnum.transactionCreated && (
                    <Controller
                      control={control}
                      name={`strictCondition[${props.index}].from`}
                      rules={{ required: true }}
                      defaultValue={from}
                      render={(field) => (
                        <TextField
                          {...field}
                          type={"time"}
                          label="Desde"
                          variant="outlined"
                          fullWidth
                          id={`strictCondition[${props.index}].from`}
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Controller
                    control={control}
                    defaultValue={backupOtherValues}
                    name={`strictCondition.${props.index}.backupOtherValues`}
                    render={() => <></>}
                  />
                  {label !== RuleSchemaEnum.transactionCreated &&
                    (operator !== OperatorsEnum.EQUAL_ALL ? (
                      <Controller
                        control={control}
                        name={`strictCondition.${props.index}.value`}
                        defaultValue={value}
                        render={(field) => (
                          <BuildField
                            credentials={props.credentials}
                            countriesGeneral={defaultTo(countries, [])}
                            operator={operator}
                            value={get(
                              getValues(),
                              `strictCondition[${props.index}].value`,
                              ""
                            )}
                            defaultValue={get(
                              getValues(),
                              `strictCondition[${props.index}].value`,
                              ""
                            )}
                            index={props.index}
                            label={label as LabelEnum}
                            properties={field}
                            name={`strictCondition[${props.index}].value`}
                            helperText={
                              !!get(
                                errors,
                                `strictCondition[${props.index}].value`
                              ) &&
                              getErrorMessage(
                                get(
                                  errors,
                                  `strictCondition[${props.index}].value`
                                )
                              )
                            }
                            error={
                              !!get(
                                errors,
                                `strictCondition[${props.index}].value`
                              )
                            }
                          />
                        )}
                      />
                    ) : (
                      <></>
                    ))}
                  {label === RuleSchemaEnum.transactionCreated && (
                    <Controller
                      control={control}
                      name={`strictCondition[${props.index}].to`}
                      defaultValue={to}
                      rules={{ required: true }}
                      render={(field) => (
                        <TextField
                          {...field}
                          type={"time"}
                          label="Hasta"
                          variant="outlined"
                          fullWidth
                          id={`strictCondition[${props.index}].to`}
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={2}>
                  <Grid container direction={"row"} justifyContent={"flex-end"}>
                    <Grid item>
                      <IconButton
                        color="primary"
                        disabled={
                          conditionsArray.findIndex(
                            (itemCondition: IRuleSchema) =>
                              itemCondition.label === label
                          ) > -1
                        }
                        onClick={() => {
                          append({
                            label,
                            operator,
                            value: get(
                              getValues(),
                              `strictCondition[${props.index}].value`,
                              ""
                            ),
                            from,
                            to,
                          });
                        }}
                      >
                        <Plus />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "20px" }}>
                <Grid item xs={12}>
                  <ComponentText
                    label={"Condiciones de autenticación agregadas"}
                    type={"normal"}
                    params={{ variant: "body1" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  {fields.map(
                    (
                      item: Partial<ArrayField<IStrictCondition>>,
                      index: number
                    ) => {
                      return (
                        <React.Fragment key={item.id}>
                          <StrictConditionsSection
                            item={item}
                            index={index}
                            indexStrict={props.index}
                            disabled={false}
                            processors={props.processors}
                            credentials={props.credentials}
                            remove={remove}
                            service={service}
                            countries={countries}
                            ruleType={ruleType}
                          />
                        </React.Fragment>
                      );
                    }
                  )}
                </Grid>
              </Grid>
            </React.Fragment>
          );
        }}
      </ConnectForm>
    );
  };

export default ConfigurationInformationSection;
