import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  createStyles,
  Grid,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { get } from "lodash";

export interface InformationModulesVisitedProps {
  supportInfo: object | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      boxShadow: "none !important",
      borderRadius: "0px !important",
      backgroundColor: "#F7FAFC !important",
      marginBottom: "20px",
    },
    typography: {
      color: "#293036",
      fontSize: "14px",
    },
    box: {
      color: "#293036",
      fontWeight: 400,
    },
  })
);

const InformationModulesVisited: React.FC<InformationModulesVisitedProps> = (
  props: InformationModulesVisitedProps
) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body2">
                  <Box className={classes.box}>Módulos visitados</Box>
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                {get(props.supportInfo, "_source.modules_visited", ["N/A"]).map(
                  (module: string, index: number) => (
                    <Typography
                      id={`module_${index}`}
                      key={module}
                      variant={"body2"}
                      color={"textSecondary"}
                      style={{ marginTop: "5px" }}
                    >
                      {module}
                    </Typography>
                  )
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export default InformationModulesVisited;
