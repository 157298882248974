import { useDispatch } from "react-redux";
import { RootState } from "../../../../../store/store";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import {
  setOpenModal,
  setOpenModalSh,
  setShareHolder,
} from "../../../../../store/reducers/legalDetails/legalDetails";
import { get } from "lodash";
import { IShareHolder } from "../../../../../store/interfaces/LegalDetailsState.interfaces";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../store/hooks/storeHook";
import {
  DEFAULT_PERCENTAGE,
  ShareholderFields,
} from "../../../../../shared/constants/shareholder_constants";
import {
  IMultipleFormValues,
  PersonTypeEnum,
} from "../../../../../shared/constants/initial_state_legal_details";
import {
  getAvailablePercentageByPosition,
  getPathOfShareholder,
  getSourceShareholderName,
  insertShareholderByPosition,
} from "../../../../../shared/utils/shareholderUtils";
import {
  isAvailableCountry,
  PERSON_TYPE_BY_COUNTRY_VALUES,
} from "../../../../../shared/services/CatalogPersonTypeByCountry";
import { CountryEnum } from "../../../../../shared/countryEnum";

export const useShareholderFormState = (position: string) => {
  const { shareHolders, initialData } = useAppSelector((state: RootState) => ({
    ...state.legalDetails,
    ...state,
  }));
  const [sourceName, setSourceName] = useState("");
  const [valuesPersonType, setValuesPersonType] = useState<
    IMultipleFormValues[]
  >([]);
  const availablePercentage = getAvailablePercentageByPosition(
    position,
    shareHolders
  );

  const [editPersonType, setEditPersonType] = useState("");
  const [openModalDialogEdit, setOpenModalDialogEdit] = useState(false);
  const handleOpenModalDialogEdit = () => {
    setOpenModalDialogEdit(true);
  };

  const handleCloseModalDialogEdit = () => {
    setOpenModalDialogEdit(false);
  };

  useEffect(() => {
    const constitutionalCountry = get(
      initialData,
      "nodeInfo.generalInfo.constitutionalCountry",
      ""
    );

    if (isAvailableCountry(constitutionalCountry))
      setValuesPersonType(PERSON_TYPE_BY_COUNTRY_VALUES[constitutionalCountry]);
    else
      setValuesPersonType(PERSON_TYPE_BY_COUNTRY_VALUES[CountryEnum.generic]);
  }, []);

  let path_to_shareholder = getPathOfShareholder(position);
  let shareholder: IShareHolder = get(shareHolders, path_to_shareholder);
  const isEdited = shareholder !== undefined;

  const defaultValuesForm = () => {
    if (isEdited) {
      return shareholder.personType === PersonTypeEnum.FISICA
        ? {
            birthdate: get(shareholder, ShareholderFields.BIRTHDATE),
            children: get(shareholder, ShareholderFields.CHILDREN, []),
            country: get(shareholder, ShareholderFields.COUNTRY),
            documentNumber: get(shareholder, ShareholderFields.DOCUMENT_NUMBER),
            documentType: get(shareholder, ShareholderFields.DOCUMENT_TYPE),
            id: get(shareholder, ShareholderFields.ID),
            lastName: get(shareholder, ShareholderFields.LAST_NAME),
            name: get(shareholder, ShareholderFields.NAME),
            participationPercentage: get(
              shareholder,
              ShareholderFields.PARTICIPATION_PERCENTAGE,
              ""
            ),
            personType: PersonTypeEnum.FISICA,
          }
        : {
            children: get(shareholder, ShareholderFields.CHILDREN, []),
            companyCountry: get(shareholder, ShareholderFields.COMPANY_COUNTRY),
            id: get(shareholder, ShareholderFields.ID),
            merchantCountry: get(
              shareholder,
              ShareholderFields.MERCHANT_COUNTRY
            ),
            participationPercentage: get(
              shareholder,
              ShareholderFields.PARTICIPATION_PERCENTAGE,
              ""
            ),
            personType: PersonTypeEnum.MORAL,
            socialReason: get(shareholder, ShareholderFields.SOCIAL_REASON),
            taxId: get(shareholder, ShareholderFields.TAX_ID),
          };
    }

    return {
      documentType: "",
      participationPercentage: "",
      personType: PersonTypeEnum.MORAL,
    };
  };

  const dispatch = useDispatch();
  const form = useForm<FieldValues>({
    defaultValues: { ...defaultValuesForm() },
  });

  const onChangeRadioButton = (value: string) => {
    if (isEdited) {
      setEditPersonType(value);
      setOpenModalDialogEdit(true);
    } else {
      form.setValue("personType", value);
    }
  };

  const handleAcceptModalDialogEdit = () => {
    form.setValue("personType", editPersonType);
    setOpenModalDialogEdit(false);
  };

  useEffect(() => {
    form.reset({ ...defaultValuesForm() });
    path_to_shareholder = getPathOfShareholder(position);
    shareholder = get(shareHolders, path_to_shareholder);
    setSourceName(getSourceShareholderName(position, shareHolders));
  }, [shareHolders]);

  const closeModal = () => {
    dispatch(setOpenModalSh(false));
    dispatch(setOpenModal(false));
  };

  const onSubmit: SubmitHandler<FieldValues> = (data: FieldValues) => {
    const new_shareholders = insertShareholderByPosition(
      position,
      shareHolders,
      data as IShareHolder
    );

    dispatch(setShareHolder(new_shareholders));
    dispatch(setOpenModalSh(false));
  };

  const documentTypeWatch = form.watch(ShareholderFields.PERSON_TYPE);

  useEffect(() => {
    form.clearErrors();
  }, [documentTypeWatch]);

  const isPhysical = documentTypeWatch === PersonTypeEnum.FISICA;

  return {
    availablePercentage: DEFAULT_PERCENTAGE - availablePercentage,
    closeModal,
    form,
    isEdited,
    isPhysical,
    modalDialogEdit: {
      handleAcceptModalDialogEdit,
      handleCloseModalDialogEdit,
      handleOpenModalDialogEdit,
      onChangeRadioButton,
      openModalDialogEdit,
    },
    onSubmit,
    sourceName,
    valuesPersonType,
  };
};
