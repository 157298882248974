import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { ItemCategoryStyles } from "./styles";
import { useItemCategoryState } from "./state/useItemCategoryState";
import { ItemCategoryItem, ItemCategoryProps } from "./interfaces";
import React, { FC } from "react";
import { FilterTextsEnum } from "../../../shared/enums/FilterTextsEnum";
import { CategoryTypeFilterEnum } from "../../../shared/enums/CategoryTypeFilterEnum";
import AmountFilter from "../../AmountFilter/AmountFilter";

const ItemCategory: FC<ItemCategoryProps> = ({
  groupSection,
  isMinimize,
  items,
  selectType,
  type,
  setRangeAmount,
  setIsApplyFilter,
  isResetFilter,
}: ItemCategoryProps) => {
  const props: ItemCategoryProps = {
    groupSection,
    isMinimize,
    items,
    selectType,
    setRangeAmount,
    type,
  };
  const { expanded, toggleAcordion, listFromItems, handleOnByChipsChange } =
    useItemCategoryState(props);

  const RenderFilterByCategory = (category: string) => {
    switch (category) {
      case CategoryTypeFilterEnum.BY_CHIPS:
        return (
          <Box sx={ItemCategoryStyles.formChip}>
            {listFromItems.map((item: ItemCategoryItem, index: number) => (
              <FormControlLabel
                key={index + groupSection}
                id={index + groupSection}
                className="filterChipCheck"
                sx={ItemCategoryStyles.formChip}
                control={
                  <Checkbox
                    id={index + groupSection}
                    size="medium"
                    onChange={() => handleOnByChipsChange(item)}
                    checked={item.selected}
                    value={item.value}
                    color="primary"
                    disabled={item.isDisabled ?? false}
                  />
                }
                label={item.label}
              />
            ))}
          </Box>
        );
      case CategoryTypeFilterEnum.BY_AMOUNT:
        return (
          <AmountFilter
            setRangeAmount={setRangeAmount!}
            setIsApplyFilter={setIsApplyFilter!}
            isResetFilter={isResetFilter!}
          />
        );
      default:
        return;
    }
  };

  return (
    <Accordion expanded={expanded} sx={ItemCategoryStyles.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMore sx={ItemCategoryStyles.acordionExpandIcon} />}
        sx={ItemCategoryStyles.summary}
        onClick={() => toggleAcordion()}
      >
        <Typography color="text.grey" variant={"h6"}>
          {groupSection || FilterTextsEnum.GROUP_NAME}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={ItemCategoryStyles.acordionDetail}>
        {RenderFilterByCategory(type)}
      </AccordionDetails>
    </Accordion>
  );
};

export { ItemCategory };
