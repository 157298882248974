import { BreadCrumsItems } from "../../../shared/infrastructure/constants/BreadCrums";
import { useHistory, useLocation } from "react-router-dom";
import {
  ALLOW_COUNTRIES_RETENTIONS,
  CountryRetentionEnum,
  CountryValueEnum,
} from "../../../shared/infrastructure/Enums/CountryEnum";
import { getCountry } from "../../../shared/infrastructure/constants/MerchantContactConstants";
import { routes } from "../../../shared/infrastructure/routes";
import { useDispatch, useSelector } from "react-redux";
import {
  base64toXlsx,
  downloadTemplateExampleCsv,
  getRetentionsConfigFile,
  setCsvTemplate,
  setErrorCsv,
  setFileSent,
  setNotification,
  uploadRetentionsConfig,
} from "../../../store/actionCreators";
import { IAppState } from "../../../store/reducer";
import React, { useEffect, useState } from "react";
import { KshFile } from "../../../components/common/FileUpload/FileUpload";
import { FileInfo } from "../../../../types/file_info";
import { get, set } from "lodash";
import { RetentionEnum } from "../../../shared/infrastructure/Enums/RetentionEnum";
import { TypeRetentionEnum } from "../../../shared/infrastructure/constants/RetentionConstants";
import { RetailsConfigurationsFile } from "../../../../types/retails_configurations_file";
import { SecurityWrapperEnum } from "../../../shared/infrastructure/Enums/SecurityWrapperEnum";
import { ISecurityAzureRoles } from "../../../shared/infrastructure/interfaces/ISecurityAzureRoles";
import { UploadFileRetentionRequest } from "../../../../types/upload_file_retention_request";
import { IFileUploadFormIndexState } from "./useFileUploadFormIndexState.interfaces";
import { MessageEnum } from "../../../shared/infrastructure/Enums/MessageEnum";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const useFileUploadFormIndexState = (): IFileUploadFormIndexState => {
  const stateStore = useSelector<IAppState, IAppState>(
    (state: IAppState) => state
  );
  const query = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();
  const [openError, setOpenError] = useState<boolean>(false);
  const isErrorCsv = useSelector((state: IAppState) => state.errorCsv) || false;
  const { isLoading, templateXslxData, indexEditRetentionTab } = useSelector(
    (state: IAppState) => state
  );
  const errorCsvMessage =
    useSelector((state: IAppState) => state.errorCsvMessage) || "";
  const isFileSent: boolean =
    useSelector((state: IAppState) => state.fileSent) || false;

  const retentionConfigFile: RetailsConfigurationsFile = useSelector(
    (state: IAppState) => state.retentionsConfigFile
  ) || { hasFile: false };

  const country: CountryValueEnum = query.get("country") as CountryValueEnum;
  const payload = JSON.parse(localStorage.getItem("payload")!);

  /* File Uploader*/
  const [files, setFiles] = useState<KshFile[] | undefined>(undefined);
  const [fileInfo, setFileInfo] = useState<FileInfo>();
  const [error, setError] = useState<boolean>(true);
  const setFile = (file: KshFile, property: string) => {
    const newFile = {
      id: property,
      name: file.name,
      base64: file.base64,
      extension: file.extension,
    };
    setFileInfo({
      [property]: {
        name: file.name,
        file: file.base64,
        extension: file.extension,
      },
    });
    setFiles([newFile]);
  };

  const [openModal, setOpenModal] = useState<boolean>(false);
  const setOpenModalDetail = () => {
    if (files) setOpenModal(true);
    else returnWithoutSave();
  };
  const setCloseModalDetail = () => {
    setOpenModal(false);
  };
  const returnWithoutSave = () => {
    setOpenModal(false);
    window.location.href = `${routes.BASE_PATH_RETENTIONS}${routes.DASHBOARD}?country=${country}`;
    localStorage.setItem(
      "retentionOrigin",
      String(get(stateStore, "indexEditRetentionTab", 0))
    );
  };
  const [securityRol, setSecurityRol] = useState<ISecurityAzureRoles>({
    rolUploadFile: SecurityWrapperEnum.RETENCIONES_CARGAR_ARCHIVO_IVA_ICA,
    rolDownloadExcel:
      SecurityWrapperEnum.RETENCIONES_DESCARGAR_DATOS_EXCEL_IVA_ICA,
    rolDownloadTemplate:
      SecurityWrapperEnum.RETENCIONES_DESCARGAR_PLANTILLA_EXCEL_IVA_ICA,
  });
  const [value, setValue] = useState<number>(indexEditRetentionTab!);
  const handleTabsChange = (
    _event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setValue(newValue);
    setFiles(undefined);
    dispatch(
      setCsvTemplate({ isLoading: false, isLoadingFileData: false, s3Url: "" })
    );
    downloadRetentionsConfigFile(newValue, country);
    newValue === 0
      ? setSecurityRol({
          rolUploadFile: SecurityWrapperEnum.RETENCIONES_CARGAR_ARCHIVO_IVA_ICA,
          rolDownloadExcel:
            SecurityWrapperEnum.RETENCIONES_DESCARGAR_DATOS_EXCEL_IVA_ICA,
          rolDownloadTemplate:
            SecurityWrapperEnum.RETENCIONES_DESCARGAR_PLANTILLA_EXCEL_IVA_ICA,
        })
      : setSecurityRol({
          rolUploadFile: SecurityWrapperEnum.RETENCIONES_CARGAR_ARCHIVO_RENTA,
          rolDownloadExcel:
            SecurityWrapperEnum.RETENCIONES_DESCARGAR_DATOS_EXCEL_RENTA,
          rolDownloadTemplate:
            SecurityWrapperEnum.RETENCIONES_DESCARGAR_PLANTILLA_EXCEL_RENTA,
        });
  };

  const handlerCaptureFile = (value: { prop: string; files?: KshFile }) => {
    if (value.files) {
      setFile(value.files, value.prop);
    }
  };
  const handlerDelete = (resultFiles: { prop: string; files?: KshFile }) => {
    setFileInfo({
      ...fileInfo,
      [resultFiles.prop]: undefined,
    });
    setFiles(undefined);
  };
  /* ---- */

  const onSubmit = () => {
    const countryRetention: CountryRetentionEnum = country.toLowerCase() as CountryRetentionEnum;

    if (!ALLOW_COUNTRIES_RETENTIONS[countryRetention]) {
      dispatch(
        setNotification({
          type: "error",
          message: "País no disponible para retenciones",
          open: true,
        })
      );
      return;
    }

    const retentionConfig: UploadFileRetentionRequest = {
      country: countryRetention,
      username: payload["cognito:username"],
      file: get(files![0], "base64", ""),
    };

    if (countryRetention === CountryRetentionEnum.ecuador)
      set(
        retentionConfig,
        "kind",
        value == 0 ? RetentionEnum.IVA : RetentionEnum.RENTA
      );

    dispatch(uploadRetentionsConfig(retentionConfig));
  };
  const handleOpenError = () => {
    setOpenError(true);
    setFiles(undefined);
    dispatch(setErrorCsv(false, ""));
  };
  const handleCloseError = () => {
    setOpenError(false);
  };
  const handleSetError = (payload: boolean) => {
    setError(payload);
  };

  const returnTo = (route: string) => {
    history.push(route);
  };

  const downloadTemplateCsv = () => {
    const kind: string = value == 0 ? RetentionEnum.IVA.toUpperCase() : "RENT";

    dispatch(downloadTemplateExampleCsv(country, kind));
  };

  const downloadRetentionsConfigFile = (tabIndex: number, country: string) => {
    if (country === CountryValueEnum.Ecuador.toLocaleLowerCase()) {
      switch (tabIndex) {
        case 0:
          dispatch(getRetentionsConfigFile(TypeRetentionEnum.IVA, country));
          break;
        case 1:
          dispatch(
            getRetentionsConfigFile(TypeRetentionEnum.RETENTION, country)
          );
          break;
      }
    } else if (country === CountryValueEnum.Colombia.toLocaleLowerCase()) {
      dispatch(getRetentionsConfigFile(TypeRetentionEnum.ICA, country));
    }
  };
  const downloadTemplateExcel = async () => {
    dispatch(
      base64toXlsx(
        get(retentionConfigFile, "file", ""),
        "Configuraciones_IVA_Renta.xlsx"
      )
    );
  };

  const handleSuccessRedirection = () => {
    returnTo(
      `${routes.BASE_PATH_RETENTIONS}${routes.DASHBOARD}?country=${country}`
    );
    dispatch(setFileSent(false));
    dispatch(
      setNotification({
        type: "dark",
        message:
          country === CountryValueEnum.Colombia.toLocaleLowerCase()
            ? "se han configurado las retenciones ICA con éxito"
            : MessageEnum.SUCCESS_CONFIG_RETENTIONS,
        open: true,
      })
    );
  };

  useEffect(() => {
    downloadRetentionsConfigFile(indexEditRetentionTab!, country);
  }, [retentionConfigFile.hasFile]);

  return {
    openError,
    country,
    downloadTemplateCsv,
    downloadTemplateExcel,
    downloadRetentionsConfigFile,
    isErrorCsv,
    handleOpenError,
    handleCloseError,
    onSubmit,
    errorCsvMessage,
    returnTo,
    fileUploader: {
      files,
      handlerCaptureFile,
      handlerDelete,
      handleSetError,
    },
    stateFile: {
      isLoading: isLoading as boolean,
      error,
    },
    breadCrumbs: {
      items: [
        BreadCrumsItems.GlobalConfig,
        { ...BreadCrumsItems.Country, label: getCountry(country).name },
        {
          label:
            BreadCrumsItems.RetentionsMerchant.label[getCountry(country).value],
          url: `${routes.BASE_PATH_RETENTIONS}${routes.DASHBOARD}?country=${country}`,
        },
      ],
      lastItem:
        BreadCrumsItems.UploadFileTextByCountry[getCountry(country).name],
    },
    handleSuccessRedirection,
    isFileSent,
    isLoadingDownloadFile: templateXslxData!.isLoading,
    tab: {
      value: value,
      handleTabsChange: handleTabsChange,
    },
    modalDetailProps: {
      openModal,
      setOpenModalDetail,
      setCloseModalDetail,
      returnWithoutSave: returnWithoutSave,
    },
    securityAzure: securityRol,
  };
};
