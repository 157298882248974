import {
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  Typography,
} from "@mui/material";
import {
  BankAccountFields,
  BankAccountPlaceholders,
} from "../../shared/enums/dispersionsEnum";
import InfoText from "../InfoText/InfoText";
import { ADD_BANK_ACCOUNT_MODAL_LABELS } from "../../shared/constants/labels/add_bank_account_modal_labels";
import SelectItemsField from "../FormInputFields/SelectItemsField/SelectItemsField";
import { CatalogsEnum } from "../../shared/enums/CatalogsEnum";
import { Category } from "../../shared/interfaces/category";
import { dispersionFormStyles as styles } from "../DispersionForm/DispersionForm.styles";
import { ACCOUNT_TYPE_LABEL } from "../../shared/constants/labels/dispersion_container_labels";
import RadioButtonField from "../FormInputFields/RadioButtonField/RadioButtonField";
import NumericInputField from "../FormInputFields/NumericInputField/NumericInputField";
import { CountriesEnum } from "../../shared/enums/countriesEnum";
import React from "react";
import { IAddBankAccountSameCountryFlowProps } from "./AddBankAccountSameCountryFlow.interfaces";
import { SelectCountry } from "../SelectCountry/SelectCountry";
import { CountryListType } from "../../shared/constants/select_country_constants";
import { accountRules } from "../../shared/constants/form_account_labels";
import AlfaNumericInput from "../FormInputFields/AlfaNumericInput/AlfaNumericInput";
import { useAddBankAccountSameCountryFlow } from "./state/useAddBankAccountSameCountryFlow";
import InternationalTransferConfiguration from "../InternationalTranferConfiguration/InternationalTransferConfiguration";
import SelectCurrency from "../FormInputFields/SelectCurrency/SelectCurrency";
import { defaultTo } from "lodash";

const AddBankAccountSameCountryFlow = ({
  changeCountry,
  country,
  countryIs,
  currenciesList,
  getCatalogToRender,
  banksForm,
  changeIsInternationalTransfer,
  isInternationalTransfer,
  isDispersionTab,
}: IAddBankAccountSameCountryFlowProps) => {
  const {
    isCountryInKushkiNation,
    isCountryWithRoutingNumber,
    isInvalidCurrency,
    dynamicRules,
    onChangeCurrency,
  } = useAddBankAccountSameCountryFlow({
    banksForm,
    changeIsInternationalTransfer,
    country,
    isDispersionTab,
    isInternationalTransfer,
  });

  const sortBankAccounts: Category[] = defaultTo(
    getCatalogToRender(country, CatalogsEnum.Banks),
    []
  ).sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Grid container display={"flex"} flexDirection={"row"} xs={12}>
      <Grid sx={{ p: 1 }} item xs={6}>
        <SelectCountry
          onChange={changeCountry}
          isDisabled={false}
          placeHolder={"País de origen de la cuenta bancaria"}
          typeList={CountryListType.ALL}
          value={country}
        />
      </Grid>
      <Grid item xs={6} sx={{ p: 1 }}>
        <AlfaNumericInput
          control={banksForm.control}
          errors={banksForm.errors}
          name={BankAccountFields.BENEFICIARY_NAME}
          label={BankAccountPlaceholders.BENEFICIARY_NAME}
          rules={dynamicRules.beneficiaryName}
          disabled={false}
        />
      </Grid>
      <Grid item xs={6}>
        <InfoText
          text={ADD_BANK_ACCOUNT_MODAL_LABELS.principal.countryHelperText}
        />
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={6} sx={{ p: 1 }}>
        {isCountryInKushkiNation ? (
          <SelectItemsField
            name={BankAccountFields.BANK_ID}
            control={banksForm.control}
            errors={banksForm.errors}
            label={"Nombre de la institución financiera"}
            items={sortBankAccounts.map((item: Category, index: number) => (
              <MenuItem key={`df-${index}`} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          />
        ) : (
          <AlfaNumericInput
            control={banksForm.control}
            errors={banksForm.errors}
            name={BankAccountFields.BANK_NAME}
            label={BankAccountPlaceholders.BANK_NAME}
            rules={dynamicRules.financialInstitutionName}
          />
        )}
      </Grid>
      {isCountryWithRoutingNumber && (
        <Grid item xs={6} sx={{ p: 1 }}>
          <AlfaNumericInput
            control={banksForm.control}
            errors={banksForm.errors}
            name={BankAccountFields.ROUTING_NUMBER}
            label={BankAccountPlaceholders.ROUTING_NUMBER}
            rules={accountRules.routingNumber}
          />
        </Grid>
      )}
      {isDispersionTab && (
        <Grid item xs={12}>
          <InternationalTransferConfiguration
            banksForm={banksForm}
            changeIsInternationalTransfer={changeIsInternationalTransfer}
            isInternationalTransfer={isInternationalTransfer}
            isInvalidCurrency={isInvalidCurrency}
            isSameCountry={true}
            country={country}
          />
        </Grid>
      )}

      {!isInternationalTransfer && (
        <>
          <Grid item xs={12} sx={{ pt: 1, px: 1 }}>
            <Typography color="text.grey" variant="body2" sx={styles.label}>
              {ACCOUNT_TYPE_LABEL}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: 3, px: 1 }}>
            <RadioButtonField
              name={BankAccountFields.ACCOUNT_TYPE}
              control={banksForm.control}
              errors={banksForm.errors}
              items={getCatalogToRender(
                country,
                CatalogsEnum.BankAccountTypes
              ).map((option: Category) => (
                <FormControlLabel
                  key={`rb-${option.value}`}
                  value={option.value}
                  control={<Radio />}
                  label={
                    <Typography color="text.dark" variant="caption">
                      {option.name}
                    </Typography>
                  }
                />
              ))}
            />
          </Grid>
        </>
      )}

      <Grid item xs={6} sx={{ p: 1 }}>
        <NumericInputField
          control={banksForm.control}
          customErrorMessages={{
            required: "Debe ingresar el número de cuenta.",
          }}
          maxLengthValue={34}
          decimalScale={0}
          errors={banksForm.errors}
          allowLeadingZeros={true}
          helperText={""}
          isNumericString={true}
          isNumeric={isCountryInKushkiNation}
          label={"Número de la cuenta bancaria"}
          name={BankAccountFields.ACCOUNT_NUMBER}
          thousandSeparator={""}
        />
      </Grid>
      {(countryIs(CountriesEnum.MEXICO) || countryIs(CountriesEnum.PERU)) &&
        !isInternationalTransfer && (
          <Grid item xs={6} sx={{ p: 1 }}>
            <NumericInputField
              control={banksForm.control}
              customErrorMessages={{
                required: `Debe ingresar el número de cuenta ${
                  countryIs(CountriesEnum.MEXICO) ? "CLABE/" : ""
                }Interbancaria de su cuenta de cobro.`,
              }}
              decimalScale={0}
              errors={banksForm.errors}
              allowLeadingZeros={true}
              helperText={""}
              isNumericString={true}
              label={
                countryIs(CountriesEnum.PERU)
                  ? "Número de cuenta Interbancario"
                  : "Número de la cuenta CLABE"
              }
              name={BankAccountFields.KEY_ACCOUNT_NUMBER}
              thousandSeparator={""}
            />
          </Grid>
        )}
      <Grid item xs={6} sx={{ p: 1 }}>
        <SelectCurrency
          name={BankAccountFields.CURRENCY}
          control={banksForm.control}
          errors={banksForm.errors}
          onChange={onChangeCurrency}
          label={"Tipo de moneda"}
          defaultValue={currenciesList[0].value}
          disabled={currenciesList.length === 1}
          items={currenciesList.map((item: Category, index: number) => (
            <MenuItem key={`df-${index}`} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        />
      </Grid>
    </Grid>
  );
};

export default AddBankAccountSameCountryFlow;
