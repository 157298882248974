export const companyTypeCHI: Array<string> = [
  "Sociedad por Acciones",
  "Empresa Individual de Responsabilidad Limitada (EIRL)",
  "Sociedad de Responsabilidad Limitada",
  "Sociedad en Comandita Simple",
  "Sociedad Anónima S.A. (Abierta)",
  "Sociedad Anónima S.A. (Cerrada)",
  "Sociedad en Comandita por Acciones",
  "Sociedad Colectiva Comercial",
];
