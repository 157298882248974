import { environment } from "../../environments/environment";

export const API_ROUTES = {
  DOWNLOAD_FILE: `${environment.kushkiUrl}/billing-core-node/v1/customer/children-download`,
  DOWNLOAD_RENTENTION_FILE: (id: string) =>
    `${environment.kushkiUrl}/billing-core/v1/retention/receipt/${id}`,
  GET_PROCESSORS: (id: string) =>
    `${environment.kushkiUrl}/merchant/v1/admin/merchant/${id}/processors`,
  GET_SMARTLINKS: (id: string) =>
    `${environment.kushkiUrl}/smartlink/v1/admin/merchant/${id}`,
  LIST_MERCHANT: `${environment.kushkiUrl}/deferred/v1/list-merchant`,
  MERCHANT: `${environment.kushkiUrl}/deferred/v1/merchant`,
  MERCHANT_INFO: `${environment.kushkiUrl}/billing-core/v1/merchant/merchantInfo`,
  MERCHANT_NODE_INFO: `${environment.kushkiUrl}/billing-core-node/v1/merchant-node/info`,
  NODE_INFO: `${environment.kushkiUrl}/billing-core-node/v1/node/info`,
  RATES_INFO: `${environment.kushkiUrl}/rates/ratesConfigs`,
  SEARCH_MERCHANT_NODE: `${environment.kushkiUrl}/billing-core-node/v1/merchant-node/search`,
  SEARCH_MERCHANT_NODE_BY_NODE_ID: `${environment.kushkiUrl}/hierarchy/v1/node/`,
  SEARCH_RETENTION: `${environment.kushkiUrl}/billing-core/searchBilling`,
  VPOS: (id: string) => `${environment.kushkiUrl}/vpos/v1/admin/merchant/${id}`,
};
