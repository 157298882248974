import { DIALOG_MESSAGE } from "../constants/labels/dialog_message_labels";
import { TransactionInfo } from "../../../types/transactions_data";
import { get } from "lodash";
import {
  PaymentMethodsEnum,
  PaymentSubMethodsEnum,
} from "../infraestructure/payment-method";

export const isDuplicated = (
  email: string,
  inputIndex: number,
  values: { email: string }[]
) => {
  if (
    values.find(
      (item: { email: string }, index: number) =>
        item.email === email && inputIndex !== index
    )
  ) {
    return DIALOG_MESSAGE.DUPLICATED_MAIL;
  }

  return true;
};

export const getEmails = (emailsForm: { email: string }[]): string[] => {
  let emails: string[] = [];

  emailsForm.map((email) => emails.push(email.email));

  return emails;
};

export const getPaymentMethod = (transaction: TransactionInfo): string => {
  const payment_method: string = get(transaction, "payment_method", "");
  const getPaymentSubMethod: string = get(
    transaction,
    "channel",
    get(transaction, "payment_submethod_type", "")
  );
  const isCardPresent: boolean = [
    PaymentSubMethodsEnum.CARD_PRESENT as string,
    PaymentSubMethodsEnum.POS,
  ].includes(getPaymentSubMethod);

  return isCardPresent ? PaymentMethodsEnum.CARD_PRESENT : payment_method;
};
