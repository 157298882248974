import React, { useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Tooltip,
  Paper,
  Typography,
  Tab,
  Tabs,
  AppBar,
  Container,
  Grid,
  ClickAwayListener,
  IconButton,
} from "@material-ui/core";
import { get } from "lodash";
import { Copy } from "react-feather";
import { ModalBody as ModalBodyMolecules } from "@kushki/frontend-molecules/modal-body";
import { auth } from "../../../shared/auth";
import { mapPaymentMethods } from "../../../shared/infrastructure/constants/payment-method-enum";
import { TransactionContainer } from "../TransactionContainer/TransactionContainer";
import { TransactionInfo } from "../../../../types/transactions_data";
import { getTransactionTypeTranslate } from "../../../shared/infrastructure/constants/transaction-type-enum";
import { formatNumber } from "../../../shared/format-number";

const useStyles = makeStyles((theme: Theme) => ({
  scroll: {
    marginBottom: "20px",
  },
  container: {
    paddingBottom: "20px",
    marginTop: "20px",
    background: "#F7FAFC",
    borderRadius: "8px",
    borderBottom: "0px !important",
  },
  label: {
    color: "#293036 !important",
    lineHeight: "170% !important",
    fontStyle: "normal !important",
    fontWeight: "normal !important" as "normal",
    fontSize: "16px !important",
    textTransform: "none" as "none",
  },
  item: {
    paddingTop: "20px",
  },
  subtitle: {
    color: theme.palette.grey["600"],
    fontSize: "14px !important",
  },
  title: {
    paddingTop: "4px",
    fontSize: "14px !important",
    color: "#293036",
  },
  paper: {
    padding: "0 !important",
    backgroundColor: "#F7FAFC !important",
    borderRadius: 2,
    boxShadow:
      "0px 0px 0px rgba(0,0,0,0), 0px 0px 0px rgba(0,0,0,0) !important",
  },
  gridContainer: {
    "&..MuiContainer-root": {
      padding: "0 !important",
    },
    display: "block",
    position: "relative",
  },
  spanButton: {
    width: "auto",
  },
  positionButton: {
    right: "-23px",
    position: "relative",
    zIndex: 999,
  },
  copyButton: {
    "&.MuiIconButton-root": {
      backgroundColor: "#FFFFFF",
      borderColor: "#E2E8F0",
      borderStyle: "solid",
      borderWidth: 1,
      borderRadius: "0px 8px",
    },
  },
  paperContainer: {
    borderRadius: "8px",
    border: "0px !important",
    boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 20%) !important",
  },
  jsoncontainer: {
    width: "100%",
    overflowX: "auto",
    overflowY: "auto",
  },
  paymentMethodIcon: {
    paddingBottom: (paddingBottom: number) => {
      return paddingBottom + "px !important";
    },
  },
  tabs: {
    marginLeft: "-25px",
    marginRight: "-25px",
  },
  boxShadowTab: {
    boxShadow:
      "0px 0px 5px 0px rgb(0 0 0 / 4%), 0px 0px 2px 0px rgb(0 0 0 / 2%), 0px 1px 10px 0px rgb(0 0 0 / 4%) !important",
  },
}));

const jsonToString = (objeto: object): string => {
  return JSON.stringify(objeto, null, " ");
};

export interface IPayoutTransactionProps {
  transaction: TransactionInfo;
}

export const TransferDetail: React.FC<IPayoutTransactionProps> = (
  props: IPayoutTransactionProps
) => {
  const [valueTab, setValueTab] = useState<number>(0);
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);

  const isBackOfficeAdmin: boolean = auth.isAdmin();

  const tabsProps = (index: any) => {
    return {
      id: `scrollable-prevent-tab-${index}`,
      "aria-controls": `scrollable-prevent-tabpanel-${index}`,
      value: index,
    };
  };

  const { transaction } = props;

  const { height, width, icon, paddingBottom, label } = mapPaymentMethods(
    transaction.payment_method!
  );

  const classes = useStyles(paddingBottom);

  const handleChangeTab = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setValueTab(newValue);
  };

  const handleCopyTextCredentialMetadata = (objeto: object) => {
    navigator.clipboard.writeText(JSON.stringify(objeto));
    setOpenTooltip(true);
  };

  return (
    <ModalBodyMolecules>
      <div className={classes.tabs}>
        <AppBar
          position="sticky"
          color="transparent"
          className={classes.boxShadowTab}
        >
          <Tabs
            value={valueTab}
            variant="fullWidth"
            onChange={handleChangeTab}
            indicatorColor={"primary"}
            centered={true}
          >
            <Tab
              label={
                <Typography className={classes.label}>Información</Typography>
              }
              {...tabsProps(0)}
            />
            <Tab
              label={
                <Typography className={classes.label}>Metadata</Typography>
              }
              {...tabsProps(2)}
            />
          </Tabs>
        </AppBar>
      </div>
      <Paper
        square
        hidden={valueTab !== 0}
        id={`scrollable-prevent-tabpanel-${0}`}
        aria-labelledby={`scrollable-prevent-tab-${0}`}
        className={classes.paperContainer}
      >
        <div className={classes.scroll}>
          <Container className={classes.container}>
            <Grid container>
              <Grid
                item
                xs={8}
                className={classes.item}
                hidden={!isBackOfficeAdmin}
              >
                <Typography variant="body2" className={classes.subtitle}>
                  Comercio:
                </Typography>
                <Typography variant="h6" className={classes.title}>
                  {transaction.merchant_name}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                className={classes.item}
                hidden={!isBackOfficeAdmin}
              >
                <Typography variant="body2" className={classes.subtitle}>
                  Merchant ID:
                </Typography>
                <Typography variant="h6" className={classes.title}>
                  {transaction.merchant_id}
                </Typography>
              </Grid>
              <Grid item xs={8} className={classes.item}>
                <Typography variant="body2" className={classes.subtitle}>
                  Número de Documento:
                </Typography>
                <Typography variant="h6" className={classes.title}>
                  {transaction.document_number}
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.item}>
                <Typography variant="body2" className={classes.subtitle}>
                  Tipo:
                </Typography>
                <Typography variant="h6" className={classes.title}>
                  {getTransactionTypeTranslate(transaction.transaction_type!)}
                </Typography>
              </Grid>
              <Grid item xs={8} className={classes.item}>
                <Typography variant="body2" className={classes.subtitle}>
                  Medio de pago:
                </Typography>
                <Typography variant="h6" className={classes.title}>
                  <img
                    height={height}
                    width={width}
                    className={classes.paymentMethodIcon}
                    src={icon}
                  />{" "}
                  {label}
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.item}>
                <Typography variant="body2" className={classes.subtitle}>
                  Banco:
                </Typography>
                <Typography variant="h6" className={classes.title}>
                  {get(transaction, "bank_name", "-")}
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.item}>
                <Typography variant="body2" className={classes.subtitle}>
                  Subtotal:
                </Typography>
                <Typography variant="h6" className={classes.title}>
                  {formatNumber(transaction.subtotal_iva0!.toString())}{" "}
                  {transaction.currency_code}
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.item}>
                <Typography variant="body2" className={classes.subtitle}>
                  Impuestos:
                </Typography>
                <Typography variant="h6" className={classes.title}>
                  {formatNumber(transaction.iva_value!.toString())}{" "}
                  {transaction.currency_code}
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.item}>
                <Typography variant="body2" className={classes.subtitle}>
                  Total:
                </Typography>
                <Typography variant="h6" className={classes.title}>
                  {transaction.request_amount
                    ? formatNumber(transaction.request_amount!.toString())
                    : formatNumber(
                        transaction.approved_transaction_amount!.toString()
                      )}{" "}
                  {transaction.currency_code}
                </Typography>
              </Grid>
            </Grid>
          </Container>
          <TransactionContainer
            labels={[
              [
                "Subtotal IVA:",
                `${formatNumber(
                  get(transaction, "subtotal_iva", 0).toString()
                )} ${transaction.currency_code}`,
              ],
              [
                "Subtotal IVA 0:",
                `${formatNumber(
                  get(transaction, "subtotal_iva0", 0).toString()
                )} ${transaction.currency_code}`,
              ],
              [
                "Monto del IVA",
                `${formatNumber(get(transaction, "iva_value", 0).toString())} ${
                  transaction.currency_code
                }`,
              ],
              [
                "Monto del ICE",
                `${formatNumber(get(transaction, "ice_value", 0).toString())} ${
                  transaction.currency_code
                }`,
              ],
              [
                "Monto aprobado",
                `${formatNumber(
                  get(transaction, "request_amount", 0).toString()
                )}
                ${transaction.currency_code}`,
              ],
            ]}
          />
          {!isBackOfficeAdmin && (
            <Container className={classes.container}>
              <Box
                display="flex"
                flexDirection="row-reverse"
                position="absolute"
                className={classes.positionButton}
              >
                <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
                  <Tooltip
                    title="Copiado"
                    arrow
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setOpenTooltip(false)}
                    open={openTooltip}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                  >
                    <span>
                      <IconButton className={classes.copyButton}>
                        <Copy
                          size={18}
                          onClick={() =>
                            handleCopyTextCredentialMetadata({
                              taxes: get(transaction, "taxes", {}),
                            })
                          }
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                </ClickAwayListener>
              </Box>
              <Grid
                container
                color="secondary"
                className={classes.gridContainer}
              >
                <pre className={classes.jsoncontainer}>
                  {jsonToString(get(transaction, "taxes"))}
                </pre>
              </Grid>
            </Container>
          )}
        </div>
      </Paper>
      <Paper
        square
        hidden={valueTab !== 2}
        id={`scrollable-prevent-tabpanel-${2}`}
        aria-labelledby={`scrollable-prevent-tab-${2}`}
        className={classes.paperContainer}
      >
        <div className={classes.scroll}>
          <Container className={classes.container}>
            <Box
              display="flex"
              flexDirection="row-reverse"
              position="absolute"
              className={classes.positionButton}
            >
              <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
                <Tooltip
                  title="Copiado"
                  arrow
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={() => setOpenTooltip(false)}
                  open={openTooltip}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                >
                  <span>
                    <IconButton className={classes.copyButton}>
                      <Copy
                        size={18}
                        onClick={() =>
                          handleCopyTextCredentialMetadata({
                            metadata: get(transaction, "metadata", {}),
                            subscriptionMetadata: get(
                              transaction,
                              "subscriptionMetadata",
                              {}
                            ),
                          })
                        }
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              </ClickAwayListener>
            </Box>
            <Grid container color="secondary" className={classes.gridContainer}>
              <pre className={classes.jsoncontainer}>
                {jsonToString({
                  metadata: get(transaction, "metadata", {}),
                  subscriptionMetadata: get(
                    transaction,
                    "subscriptionMetadata",
                    {}
                  ),
                })}
              </pre>
            </Grid>
          </Container>
        </div>
      </Paper>
    </ModalBodyMolecules>
  );
};
