import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const addBankAccountDifferentCountryFlowFormStyles = createNamedStyles({
  box: {
    alignItems: "center",
    columnGap: (theme) => theme.spacing(1),
    display: "flex",
    pl: 1,
  },
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: (theme) => theme.spacing(3),
    rowGap: (theme) => theme.spacing(0.75),
  },
  boxInfo: {
    display: "flex",
    maxWidth: "70%",
  },
  label: {
    fontWeight: 500,
    paddingBottom: 1,
    paddingTop: 1,
  },
  disabledLabel: {
    color: "#B4B9BE",
    fontWeight: 500,
    paddingBottom: 1,
    paddingTop: 1,
  },
});
