import { createNamedStyles } from "../../../shared/utils";

export const tableFiltersStyles = createNamedStyles({
  baseBtn: {
    border: 1,
    borderColor: "neutral.grey5",
    borderRadius: "4px",
    borderStyle: "solid",
    height: "36px",
  },
  btnFilter: {
    color: "text.dark",
    px: 2.5,
    "&:hover": {
      border: 1,
      borderColor: "primary.main",
      borderStyle: "solid",
      color: "text.primary",
    },
    "&:focus": {
      border: 1,
      borderColor: "primary.main",
      borderStyle: "solid",
      color: "text.primary",
    },
  },
  btnTime: {
    color: "neutral.grey7",
    ml: 2,
    px: 1,
    "&:hover": {
      border: 1,
      borderColor: "primary.main",
      borderStyle: "solid",
      color: "text.primary",
    },
    "&:focus": {
      border: 1,
      borderColor: "primary.main",
      borderStyle: "solid",
      color: "text.primary",
    },
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
  },
  iconCalendar: {
    p: "2px",
  },
  iconSearch: {
    ml: 1,
    p: "4px",
  },
  input: {
    borderRadius: 1,
    ml: 2,
    "&.Mui-disabled": {
      opacity: "33%",
    },
    "&:hover": {
      border: 1,
      borderColor: "primary.main",
      borderStyle: "solid",
      color: "text.primary",
    },
    "&:focus-within": {
      border: 1,
      borderColor: "primary.main",
      borderStyle: "solid",
      color: "text.primary",
    },
  },
});
