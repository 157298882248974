import { IStyles } from "../../../shared/interfaces/Styles.interfaces";

const CellHeaderStyles: IStyles = {
  skeleton: {
    height: "20px",
    minWidth: "30px",
    width: "100%",
  },
  skeletonCheck: {
    borderRadius: "4px !important",
    height: "30px",
    margin: "0 auto",
    width: "18px",
  },
};

export { CellHeaderStyles };
