enum paths {
  billingMerchantInvoice = "/billing-merchant/invoice",
  createMerchant = "/create-merchant",
  editMerchant = "/create-merchant/",
  createMerchantDetails = "/create-merchant/details",
  consoleCompliance = "/console-compliance",
}

export const InvalidTabModalPaths = [
  paths.billingMerchantInvoice,
  paths.createMerchant,
  paths.createMerchantDetails,
  paths.editMerchant,
];
export const InvalidTabModalPartialPath = [paths.consoleCompliance];
