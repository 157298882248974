import { useSnackbar } from "@kushki/connect-ui";
import {
  defaultTo,
  find,
  get,
  includes,
  isEmpty,
  isEqual,
  isNil,
  map,
} from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { GetNodeInfoResponse } from "../../../../types/get_node_info_response";
import { NodeEnum } from "../../../shared/enums/NodeEnum";
import {
  CentralizeNodeRequest,
  Configs,
} from "../../../shared/interfaces/centralizeNodeRequest.interface";
import { Filters } from "../../../shared/interfaces/filter.interfaces";
import { filterInterface } from "../../../shared/interfaces/searchMerchantNodeRequest.interface";
import {
  setIsLoadingModal,
  setNotification,
  setSearchMerchantNodeStatus,
  setSelectedRows,
} from "../../../store/actions/infoBranches.action";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { MerchantNodeData } from "../../../store/reducers/infoBranches/infoBranches.slice";
import { RootState } from "../../../store/store";
import { getNodeInfo } from "../../../store/thunks/general/general.thunk";
import {
  asyncMassiveAction,
  centralizeBranch,
  getNodeInfoBranch,
  removeMerchantPeople,
  searchMerchantNode,
} from "../../../store/thunks/infoBranches/infoBranches.thunk";
import {
  IPaginationProps,
  ITableRowProps,
} from "../../../components/Table/TableSimple/interfaces";
import { builderRows } from "../../../components/Table/TableSimple/constants";
import { CatalogConfigTableBranch } from "../../../shared/catalogs/CatalogConfigTableBranch";
import { useForm, UseFormReturn } from "react-hook-form";
import { StatusEnum } from "../../../shared/constants/labels/statusEnum";
import { CountryEnum } from "../../../shared/enums/countryEnum";
import {
  ASYNC_DECENTRALIZATION_CONFIGS,
  ConfigurationIdEnum,
} from "../../../shared/enums/ConfigurationIdEnum";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import {
  SnackbarEnum,
  SnackbarValuesEnum,
} from "../../../shared/enums/SnackbarEnum";
import { CentralizeProcessorsRequest } from "../../../shared/interfaces/centralizeProcessorsRequest.interface";
import { centralizeProcessors } from "../../../store/thunks/processors/processors.thunk";
import { AsyncMassiveActionRequest } from "../../../../types/async_massive_action_request";
import { CentralizationTypeEnum } from "../../../shared/constants/CentralizationTypeEnum";
import {
  getCentralizeModalText,
  getDescentralizeModalText,
} from "../../../shared/constants/labels/main_containter_labels";
import { ISnackBar } from "@kushki/connect-ui/dist/Components/Atoms/DataDisplay/SnackBar/SnackBar.interface";
import {
  MASSIVE_SERVICE_ACTIONS,
  WEBSOCKET_ACTIONS,
} from "../../../shared/constants/websocket_actions";
import {
  decentralizeGeneralWebsocket,
  massiveServicesEditionWebsocket,
} from "../../../shared/utils/websocket-utils";
import { calculateEmptyRows } from "../../../shared/utils/centralization_utils";
import {
  getErrorMessage,
  getLoadingMessage,
  getSuccessMessage,
  MASSIVE_SERVICES_LENGTH,
} from "../../../shared/utils/messages_utils";

export interface IMerchantCentralization {
  searchParameter: string;
}

export interface IUseBasicMerchantCentralization {
  centralizationModalText: string;
  handleSelectedRows: (rowsSelected: MerchantNodeData[]) => void;
  emptyRows: number;
  selectRow: string[];
  isCentralized: boolean;
  isDescentralized: boolean;
  disableDecentralization: boolean;
  isLoadingDecentralized: { isLoading: boolean; message: string };
  handleCentralizeDecentralize: (action: string) => void;
  decentralizationModalText: string;
  isLoadingModal: boolean;
  setSelectedRows: (row: number) => void;
  openModalCentralization: boolean;
  openModalDecentralization: boolean;
  paginationProps: IPaginationProps;
  rows: ITableRowProps[];
  searchMerchantNodeFilter: (filters: Filters[]) => void;
  setOpenModalCentralization: (alertState: boolean) => void;
  setOpenModalDecentralization: (alertState: boolean) => void;
  numberBranchesSelected: number;
  customerInfo: {
    constitutionalCountry: string;
    publicMerchantId: string;
    name: string;
  };
  filterByNameBranchId: () => void;
  form: UseFormReturn<IMerchantCentralization>;
  isCustomerComplete: boolean;
  setIsDecentralized: (state: boolean) => void;
  setIncrementDecentralize: (state: number) => void;
}

export interface Branch {
  nodeId: string;
  merchantId: string;
  configs: Configs[];
}

export const useBasicMerchantCentralization =
  (): IUseBasicMerchantCentralization => {
    const { showSnackbar } = useSnackbar();
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const { infoBranches, app } = useAppSelector((state: RootState) => state);
    const { merchantId } = useParams();
    const [selectRow, setSelectRow] = useState<string[]>([]);
    const [rowsDecentralized, setRowsDecentralized] = useState<Branch[]>([]);
    const [selectECNodeId, setSelectECNodeId] = React.useState<string[]>([]);
    const [selectedMerchantIds, setSelectedMerchantIds] = React.useState<
      string[]
    >([]);
    const [filtersBar, setFiltersBar] = useState({});
    const [filtersBarGroup, setFiltersBarGroup] = useState({});
    const [isCentralized, setIsCentralized] = useState<boolean>(false);
    const [isDescentralized, setIsDescentralized] = useState<boolean>(false);
    const nodesToDecentralize = useRef<string[]>([]);
    const [disableDecentralization, setDisableDecentralization] =
      useState<boolean>(false);
    const [isLoadingDecentralized, setIsLoadingDecentralized] = useState<{
      isLoading: boolean;
      message: string;
    }>({ isLoading: false, message: "" });
    const [openModalCentralization, setOpenModalCentralization] =
      useState(false);
    const [openModalDecentralization, setOpenModalDecentralization] =
      useState(false);
    const [incrementDecentralize, setIncrementDecentralize] =
      useState<number>(0);
    const [isDecentralized, setIsDecentralized] = useState<boolean>(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [totalData, setTotalData] = useState<number>(
      get(infoBranches, "searchMerchantNode.length", 0)
    );
    const [emptyRows, setEmptyRows] = useState<number>(
      calculateEmptyRows(page, rowsPerPage, totalData)
    );

    const [isCustomerComplete, setIsCustomerComplete] =
      useState<boolean>(false);

    const form = useForm<IMerchantCentralization>({
      defaultValues: {
        searchParameter: "",
      },
      mode: "all",
    });

    const handleSelectedRows = (rowsSelected: MerchantNodeData[]) => {
      const newChecked: string[] = [];
      const newCheckedEC: string[] = [];
      const getRows: Branch[] = [];
      const merchantIds: string[] = [];

      rowsSelected.map((element) => {
        merchantIds.push(get(element, "merchant_id", ""));
        newChecked.push(get(element, "node_id", "0"));
        getRows.push({
          configs: get(element, "configs", []),
          merchantId: get(element, "merchant_id", ""),
          nodeId: get(element, "node_id", "0"),
        });
        if (element.constitutional_country === CountryEnum.ecuador)
          newCheckedEC.push(get(element, "node_id", "0"));
      });

      setRowsDecentralized(getRows);
      setSelectedMerchantIds(merchantIds);
      setSelectRow(newChecked);
      setSelectECNodeId(newCheckedEC);
      setSelectedRows(newChecked.length);
      setIsCentralized(false);
      setIsDescentralized(false);
    };

    const handleChangePage = (_event: unknown, newPage: number) => {
      setPage(newPage);
      setEmptyRows(calculateEmptyRows(newPage, rowsPerPage, totalData));
    };

    const [rows, setRows] = useState<ITableRowProps[]>([]);

    const configsIds: string | null = searchParams.get("configurations");

    const configIdsUrl = (): string[] => {
      return configsIds!.split(",");
    };

    const callGetnodeInfoBranch = () => {
      infoBranches.searchMerchantNode.map((branch) => {
        if (branch.merchant_id)
          dispatch(
            getNodeInfoBranch({
              configIds: configsIds!,
              publicMerchantId: branch.merchant_id,
            })
          );
      });
    };

    const getInfoBranches = (searchMode: boolean) => {
      if (searchMode) {
        callGetnodeInfoBranch();

        return;
      }

      if (
        !isEmpty(infoBranches.searchMerchantNode) &&
        configsIds &&
        infoBranches.searchMerchantNodeStatus === "FINALIZE"
      ) {
        callGetnodeInfoBranch();
      }
    };

    const callSeacrhMerchantNode = async (
      filter?: filterInterface,
      name?: string | undefined
    ) => {
      const nodeInfo: GetNodeInfoResponse | undefined = app.nodeInfo;

      setSelectRow([]);
      setRowsDecentralized([]);
      setSelectECNodeId([]);
      setPage(0);
      dispatch(setSelectedRows(0));
      await dispatch(
        searchMerchantNode({
          entityName: NodeEnum.branch,
          filter,
          generalFilter: !isNil(name) ? name : undefined,
          limit: 240,
          offset: 0,
          path: nodeInfo?.path!,
          strictSearch: false,
        })
      );

      await getInfoBranches(false);
    };

    const filterByNameBranchId = async () => {
      const filterSelected = {
        constitutionalCountry: get(
          filtersBar,
          "constitutionalCountry",
          undefined
        ),
        status: get(filtersBar, "status", undefined),
      };

      await setFiltersBar(filterSelected);

      await callSeacrhMerchantNode(
        get(filterSelected, "constitutionalCountry", undefined) === undefined &&
          get(filterSelected, "status", undefined) === undefined
          ? undefined
          : filterSelected,
        form.getValues("searchParameter") != ""
          ? form.getValues("searchParameter")
          : undefined
      );

      setSelectRow([]);
      setRowsDecentralized([]);
      setSelectECNodeId([]);
      setPage(0);
      setRows([]);
      dispatch(setSelectedRows(0));
    };

    const searchMerchantNodeFilter = async (filters: Filters[]) => {
      const objectFilter = {};

      filters.map((filter) => {
        Object.assign(objectFilter, {
          [filter.placeHolder]: filter.items,
        });
      });

      setFiltersBarGroup(objectFilter);

      const filterSelected = {
        constitutionalCountry:
          map(get(objectFilter, "País de Constitución"), "value").join("|") ||
          undefined,
        status:
          map(get(objectFilter, "Estado"), "value").join("|") || undefined,
      };

      await setFiltersBar(filterSelected);

      await callSeacrhMerchantNode(
        get(filterSelected, "constitutionalCountry", undefined) === undefined &&
          get(filterSelected, "status", undefined) === undefined
          ? undefined
          : filterSelected,
        form.getValues("searchParameter") != ""
          ? form.getValues("searchParameter")
          : undefined
      );
    };

    const buildConfigsNodeId = (): Configs[] => {
      const configs: Configs[] = [];

      configIdsUrl().map((configId) => {
        if (
          configId === ConfigurationIdEnum.CN017 &&
          selectECNodeId.length > 0
        ) {
          configs.push({
            centralizeschildsNodeIds: selectECNodeId,
            configuration: configId,
          });
        } else {
          configs.push({
            centralizeschildsNodeIds: selectRow,
            configuration: configId,
          });
        }
      });
      if (selectECNodeId.length === 0) {
        return configs.filter(
          (item) => item.configuration !== ConfigurationIdEnum.CN017
        );
      }

      return configs;
    };

    const isInProcessorConfig = (): boolean => {
      return isEqual(configsIds, ConfigurationIdEnum.CN007);
    };

    const requestProcessorsCentralization = async () => {
      const request: CentralizeProcessorsRequest = {
        merchantIds: selectedMerchantIds,
      };

      await dispatch(centralizeProcessors(request));
    };

    const buildBodyCentralization = async () => {
      const objectCentralize: CentralizeNodeRequest = {
        configs: buildConfigsNodeId(),
        nodeId: get(app.nodeInfo!, "nodeId", ""),
      };

      const legalDataConfig: string | undefined = configIdsUrl().find(
        (configId) => configId === ConfigurationIdEnum.CN015
      );

      showSnackbar({
        color: SnackbarValuesEnum.INFO,
        message: SnackbarEnum.CENTRALIZE_IN_PROGRESS,
        variant: SnackbarValuesEnum.SIMPLE,
        withIcon: false,
      });

      if (legalDataConfig) {
        for (const merchantIdItem of defaultTo(selectedMerchantIds, [])) {
          await dispatch(removeMerchantPeople(defaultTo(merchantIdItem, "")));
        }
      }

      await dispatch(centralizeBranch(objectCentralize));
      await getInfoBranches(true);
      await setSelectRow([]);
      await setRowsDecentralized([]);
      await setSelectECNodeId([]);
      await setIsDecentralized(false);
      dispatch(setSelectedRows(0));

      if (isInProcessorConfig()) await requestProcessorsCentralization();
    };

    const isCustomerStatus = (configs: Configs[]) => {
      const validStatuses = [StatusEnum.COMPLETE, StatusEnum.OMITTED];
      const configurationsPending = configs.filter(
        (item) => !validStatuses.includes(get(item, "status") as StatusEnum)
      );
      const areConfigurationsCompleted = configurationsPending.length <= 0;

      setIsCustomerComplete(areConfigurationsCompleted);
    };

    useEffect(() => {
      if (!isEmpty(merchantId)) {
        dispatch(
          getNodeInfo({
            configIds: configsIds!,
            publicMerchantId: merchantId!,
          })
        );
      }
    }, [merchantId]);

    useEffect(() => {
      const nodeInfo: GetNodeInfoResponse | undefined = app.nodeInfo;

      if (!isEmpty(nodeInfo) && !isEmpty(get(nodeInfo, "path"))) {
        callSeacrhMerchantNode();

        isCustomerStatus(get(nodeInfo, "configs", []));
      }
    }, [app.nodeInfo]);

    useEffect(() => {
      if (selectRow.length <= 0) {
        setIsCentralized(true);
        setIsDescentralized(true);

        return;
      }

      const branches: MerchantNodeData[] = [];

      infoBranches.searchMerchantNode.map((branch: MerchantNodeData) => {
        if (selectRow.includes(branch.node_id!)) branches.push(branch);
      });

      const isCentralize = isNil(find(branches, { is_centralized: true }));
      const isDecentralize = isNil(find(branches, { is_centralized: false }));

      setIsCentralized(!isCentralize);
      setIsDescentralized(!isDecentralize);

      dispatch(setSelectedRows(selectRow.length));
    }, [selectRow]);

    useEffect(() => {
      if (infoBranches.searchMerchantNodeStatus === "FINALIZE") {
        getInfoBranches(false);
        dispatch(setSearchMerchantNodeStatus("NONE"));
      }
    }, [
      infoBranches.searchMerchantNode.length,
      app.nodeInfo,
      infoBranches.searchMerchantNodeStatus,
    ]);

    useEffect(() => {
      setEmptyRows(0);
      const filters_centralized: [any] = get(filtersBarGroup, "Grupo", []);

      if (filters_centralized.length > 0) {
        const searchMerchantNodes: MerchantNodeData[] = [];

        infoBranches.searchMerchantNode.forEach((node) => {
          let centralized: boolean = false;
          let decentralized: boolean = false;

          filters_centralized.map((filter_applied) => {
            if (get(filter_applied, "label", "") === "Centralizado")
              centralized = true;
            if (get(filter_applied, "label", "") === "Descentralizado")
              decentralized = true;
          });
          const statusNode: boolean = get(node, "is_centralized", false);

          if (centralized && statusNode) searchMerchantNodes.push(node);
          if (decentralized && !statusNode) searchMerchantNodes.push(node);
        });
        setRows(
          builderRows<MerchantNodeData>(
            searchMerchantNodes,
            CatalogConfigTableBranch
          )
        );
        setTotalData(searchMerchantNodes.length);
      } else {
        setRows(
          builderRows<MerchantNodeData>(
            get(infoBranches, "searchMerchantNode", []),
            CatalogConfigTableBranch
          )
        );
        setTotalData(get(infoBranches, "searchMerchantNode.length", 0));
      }
      setEmptyRows(calculateEmptyRows(page, rowsPerPage, totalData));
    }, [infoBranches, filtersBar]);

    useEffect(() => {
      if (infoBranches.notification) {
        showSnackbar(infoBranches.notification);
        dispatch(setNotification(undefined));
      }
    }, [infoBranches.notification]);

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      setEmptyRows(0);
    };

    const resetVariables = () => {
      setSelectRow([]);
      setRowsDecentralized([]);
      setSelectECNodeId([]);
      dispatch(setIsLoadingModal(false));
      setIsLoadingDecentralized({ isLoading: false, message: "" });
      dispatch(setNotification(undefined));
    };

    const showErrorNotification = (errorMessage: string) => {
      showSnackbar({
        color: "danger",
        message: errorMessage,
        variant: "simple",
        withIcon: false,
      });
    };

    const decentralizeBranch = (
      massiveEditionType: string,
      isCn011Config: boolean
    ) => {
      const jwt: string | null = localStorage.getItem("jwt");
      const customerMerchantId = get(app.nodeInfo, "merchantId", "");
      const websocketMessageData = {
        branches: rowsDecentralized,
        customer: {
          merchantId: customerMerchantId,
          nodeId: get(app.nodeInfo, "nodeId", ""),
        },
      };
      const extraMessageData = isCn011Config
        ? { type: massiveEditionType }
        : { configurations: configIdsUrl() };
      const paramsWS: { action: string; data: object } = {
        action: isCn011Config
          ? WEBSOCKET_ACTIONS.massive_service_edittion
          : WEBSOCKET_ACTIONS.decentralizeMassiveMerchantNodes,
        data: {
          ...websocketMessageData,
          ...extraMessageData,
        },
      };
      const errorMessage = getErrorMessage(massiveEditionType);
      const successMessage = getSuccessMessage(massiveEditionType);
      const loadingModalMessage = getLoadingMessage(massiveEditionType);

      let webSocket: WebSocket | undefined;

      try {
        setIsLoadingDecentralized({
          isLoading: true,
          message: loadingModalMessage,
        });
        dispatch(setIsLoadingModal(true));
        webSocket = new WebSocket(
          `${API_ROUTES.WS_DECENTRALIZATION}?Authorization=${jwt}`
        );
        if (isCn011Config) {
          massiveServicesEditionWebsocket.connect(
            {
              errorMessage,
              resetVariables,
              showSnackbar,
            },
            {
              customerMerchantId,
              dispatch,
              getInfoBranches,
              nodesToDecentralize,
              resetVariables,
              showSnackbar,
              successMessage,
            },
            {
              nodesToDecentralize,
              paramsWS,
              rowsDecentralized,
            },
            webSocket
          );

          return;
        }
        decentralizeGeneralWebsocket.connect(
          {
            errorMessage,
            resetVariables,
            showSnackbar,
          },
          { dispatch, getInfoBranches, paramsWS, resetVariables, showSnackbar },
          {
            paramsWS,
          },
          webSocket
        );
      } catch (e) {
        showErrorNotification(errorMessage);
        resetVariables();
        if (!isEmpty(webSocket)) webSocket!.close();
      }
    };

    const shouldDecentralizeAsync = (): boolean => {
      return includes(ASYNC_DECENTRALIZATION_CONFIGS, configsIds);
    };

    useEffect(() => {
      if (isDecentralized)
        selectedMerchantIds.map((merchantIdUpdate: string) => {
          const filterState: ITableRowProps = rows.filter(
            (branch: ITableRowProps) =>
              get(branch, "info.merchant_id", "") === merchantIdUpdate
          )[0];

          if (get(filterState, "info.is_centralized", false)) {
            dispatch(
              getNodeInfoBranch({
                configIds: configsIds!,
                publicMerchantId: merchantIdUpdate,
              })
            );
          } else {
            const filteredArray: string[] = selectedMerchantIds.filter(
              (e: string) => e !== merchantIdUpdate
            );

            setSelectedMerchantIds(filteredArray);
          }
        });
    }, [incrementDecentralize]);

    const updateStatusDecentralize = (): void => {
      setIncrementDecentralize((prevCount: number) => prevCount + 1);
    };

    const decentralizeAsync = async (): Promise<void> => {
      const decentralizeRequest: AsyncMassiveActionRequest = {
        childNodesId: selectRow,
        configuration: defaultTo(configsIds, ""),
        managementType: CentralizationTypeEnum.DECENTRALIZE,
        nodeId: get(app, "nodeInfo.nodeId", ""),
      };

      const snackBarData: ISnackBar = isEqual(
        configsIds,
        ConfigurationIdEnum.CN008
      )
        ? {
            color: SnackbarValuesEnum.SUCCESS,
            message: SnackbarEnum.IN_PROGRESS,
            variant: SnackbarValuesEnum.SIMPLE,
            withIcon: true,
          }
        : {
            color: SnackbarValuesEnum.INFO,
            message: SnackbarEnum.DECENTRALIZE_IN_PROGRESS,
            variant: SnackbarValuesEnum.SIMPLE,
            withIcon: false,
          };

      showSnackbar(snackBarData);
      await dispatch(asyncMassiveAction(decentralizeRequest));

      if (isEqual(configsIds, ConfigurationIdEnum.CN008)) {
        setDisableDecentralization(true);
        setTimeout(() => {
          getInfoBranches(true);
          setDisableDecentralization(false);
        }, 10000);
      } else {
        await setSelectRow([]);
        await setRowsDecentralized([]);
        await setSelectECNodeId([]);
        dispatch(setSelectedRows(0));
        await setIsDecentralized(true);
        if (incrementDecentralize === 0) {
          setInterval(async () => {
            await updateStatusDecentralize();
          }, 5000);
        }
      }
    };

    const handleCentralizeDecentralize = (action: string) => {
      const isCn011Config: boolean =
        configIdsUrl().length === MASSIVE_SERVICES_LENGTH &&
        configIdsUrl().includes(ConfigurationIdEnum.CN011);

      if (!isCn011Config && action === MASSIVE_SERVICE_ACTIONS.centralize) {
        buildBodyCentralization();

        return;
      }

      if (shouldDecentralizeAsync()) {
        decentralizeAsync();

        return;
      }

      decentralizeBranch(action, isCn011Config);
    };

    return {
      centralizationModalText: getCentralizeModalText(
        defaultTo(configsIds, "")
      ),
      customerInfo: {
        constitutionalCountry: get(app.nodeInfo, "generalInfo.country", ""),
        name: get(app.nodeInfo, "generalInfo.name", ""),
        publicMerchantId: get(app.nodeInfo, "generalInfo.publicMerchantId", ""),
      },
      decentralizationModalText: getDescentralizeModalText(
        defaultTo(configsIds, "")
      ),
      disableDecentralization,
      emptyRows,
      filterByNameBranchId,
      form,
      handleCentralizeDecentralize,
      handleSelectedRows,
      isCentralized,
      isCustomerComplete,
      isDescentralized,
      isLoadingDecentralized,
      isLoadingModal: infoBranches.isLoadingModal,
      numberBranchesSelected: infoBranches.selectRows,
      openModalCentralization,
      openModalDecentralization,
      paginationProps: {
        handleChangePage,
        handleChangeRowsPerPage,
        page,
        rowsPerPage,
        totalData,
      },
      rows,
      searchMerchantNodeFilter,
      selectRow,
      setIncrementDecentralize,
      setIsDecentralized,
      setOpenModalCentralization,
      setOpenModalDecentralization,
      setSelectedRows,
    };
  };
