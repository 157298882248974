import React from "react";
import EmptyTable from "../common/EmptyTable/EmptyTable";
import HistoricalTable from "./components/HistoricalTable/HistoricalTable";
import { useHistoricalTable } from "./hooks/useHistoricalTable";
import RefundDetailModal from "../modals/RefundDetailsModal/RefundDetailModal";
import HistoricalRefundsActionBar from "../HistoricalRefundsActionBar/HistoricalRefundsActionBar";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Table } from "../common/Table";

const HistoricalList = () => {
  const {
    getColumns,
    data,
    isLoadingTable,
    total,
    isRefundModalOpen,
    onCloseRefundModal,
    onRowClick,
  } = useHistoricalTable();

  const historialRefound = useSelector(
    (state: RootState) => state.refunds.selectedHistorical
  );

  return (
    <React.Fragment>
      <HistoricalRefundsActionBar />
      {isLoadingTable ? (
        <Table columns={getColumns()} data={[]} rowId="refundId" loading />
      ) : data.length === 0 ? (
        <EmptyTable />
      ) : (
        <React.Fragment>
          <HistoricalTable
            headers={getColumns()}
            data={data}
            total={total}
            onRowClick={onRowClick}
          />
          <RefundDetailModal
            refundTransaction={historialRefound}
            isOpen={isRefundModalOpen}
            isHistoricRefundTransaction={true}
            onCloseModal={onCloseRefundModal}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default HistoricalList;
