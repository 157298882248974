import { environment } from "../../environments/environment";

export const API_ROUTES = {
  CATALOG_ZIP: `${environment.kushkiUrl}/billing-core/v1/catalogs/`,
  CATALOGS: `${environment.kushkiUrl}/catalog/v1/list-catalog`,
  COMMERCIAL_EXECUTIVES: `${environment.kushkiUrl}/compliance/v1/commercialsExecutives`,
  COMPLIANCE_PEOPLE_GET: (merchantId: string) =>
    `${environment.kushkiUrl}/compliance/v1/node/compliance-people/${merchantId}`,
  FINANCIAL_CONFIGURATION: (publicMerchantId: string) =>
    `/financial-configuration/billing?publicMerchantId=${publicMerchantId}&menuItem=true`,
  HIERARCHY_NODE_INFO: `${environment.kushkiUrl}/hierarchy/v1/merchant/v1/node/config`,
  HISTORICAL_REFUNDS: `${environment.kushkiUrl}/b2c-analytics/v1/refund-transaction-list`,
  MERCHANT_COMPLIANCE_GET: (publicMerchantId: string) =>
    `${environment.kushkiUrl}/compliance/v1/merchantCompliance/${publicMerchantId}`,
  MERCHANT_COMPLIANCE_POST: `${environment.kushkiUrl}/compliance/v1/merchantCompliance`,
  MERCHANT_NODE_INFO: `${environment.kushkiUrl}/billing-core-node/v1/merchant-node/info`,
  MIN_AMOUNT_CREATE_UPDATE: `${environment.kushkiUrl}/billing-core/v1/minAmount/createUpdate`,
  NODE_INFO: `${environment.kushkiUrl}/billing-core-node/v1/node/info`,
  SAVE_MERCHANT_PEOPLE_NODE: `${environment.kushkiUrl}/compliance/v1/node/compliance-people`,
  SAVE_SEMAPHORE: `${environment.kushkiUrl}/billing-core-node/v1/merchant-node/semaphore`,
  SEARCH_MERCHANTS: `${environment.kushkiUrl}/billing-core-node/v1/merchant-node/search-merchants`,
  UPDATE_MERCHANT_NODE: `${environment.kushkiUrl}/billing-core-node/v1/merchant-node/details`,
  WS_MASSIVE: `wss://${environment.kushkiUrlWS}/billingcorenodews`,
};
