import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { get } from "lodash";

import { StatusType } from "../TabItem.interfaces";
import {
  TabItemStatusEnum,
  TabItemTitleEnum,
} from "../../../../shared/constants/enums/tabItemStatusEnum";

export const useTabItem = (
  status: StatusType,
  isDisabled: boolean,
  redirectPath: string
) => {
  const [selected, setSelected] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const statusText = () => {
    if (isDisabled) {
      return TabItemTitleEnum.NO_DISPONIBLE;
    } else if (
      [
        TabItemStatusEnum.PENDING,
        TabItemStatusEnum.IN_PROCESS,
        TabItemStatusEnum.OMITTED,
      ].includes(status) &&
      selected
    ) {
      return TabItemTitleEnum.EN_PROCESO;
    } else if (status === TabItemStatusEnum.COMPLETE) {
      return TabItemTitleEnum.COMPLETADO;
    } else {
      return TabItemTitleEnum.PENDIENTE;
    }
  };
  const statusColor =
    status === TabItemStatusEnum.COMPLETE ? "text.secondary" : "text.grey";

  useEffect(() => {
    if (get(location, "pathname", "") === redirectPath) {
      setSelected(true);
    }
  }, []);

  const redirectTo = () => {
    navigate(`${redirectPath}${location.search}`);
  };

  return { redirectTo, selected, statusColor, statusText };
};
