export enum MassiveUserContentEnum {
  DOUBTS_QUESTION = "¿Dudas sobre la configuración masiva de usuarios? ",
  PROMPT_HERE = "Consulta aquí",
  UNDERSTOOD = "Entendido",
  MODAL_DOUBT_TITLE = "¿Cómo configurar usuarios de forma masiva?",
  INSTRUCTION = "Instrucciones",
  PROCESSING_DESCRIPTION = "Este proceso puede tardar un poco, al momento en que finalice la configuración, se le notificará por correo electrónico.",
  CONFIGURING_USERS = "Estamos configurando los usuarios...",
}

export const MassiveUserInstructions: string[] = [
  "En el campo “roles” deberá indicarse el valor proporcionado en el catálogo que se encuentra en el mismo archivo",
  "No se deberá exceder de los 500 registros",
  "En caso de querer agregar más de un usuario para un mismo merchant, deberá duplicar ese registro",
  "Si el usuario tiene más de un rol, se deberá separar por comas",
  "El nombre de usuario debe tener de 1 a 32 caracteres, no se permiten caracteres especiales, no se permiten espacios",
  "Si tu archivo se generó con algún error, antes de volver a cargarlo deberás eliminar la columna de validación",
];
