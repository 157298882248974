import { createSlice } from "@reduxjs/toolkit";
import { NodeInfoResponse } from "../../../../types/node_info_response";
import { IInitialData } from "../../../shared/interfaces/initialData.interfaces";
import { getNodeInfoInitialData } from "../../thunks/app/app.thunks";

export const initialState: IInitialData = {
  nodeInitialInfo: {} as NodeInfoResponse,
};

export const initialData = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getNodeInfoInitialData.fulfilled, (state, action) => {
      state.nodeInitialInfo = action.payload;
    });
  },
  initialState,
  name: "initialData",
  reducers: {
    CLEAN_NODE_INITIAL_INFO: (state: IInitialData) => {
      state.nodeInitialInfo = undefined;
    },
  },
});

export const { CLEAN_NODE_INITIAL_INFO } = initialData.actions;

export default initialData.reducer;
