import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";
import { get } from "lodash";
import {
  ITEM_SIMPLE_CONDITION,
  ValuesConditionsProps,
} from "../../../../../shared/infrastructure/enums/ItemByTagEnum";
import { useParams } from "react-router-dom";
import { IUseParams } from "../../../../../shared/infrastructure/interfaces/IUseParams";
import { isFormEditMode } from "../../../../../utils/utilsFile";

export interface IUseConditionSimpleLabelState {
  selectSimpleCondition: (_event: React.ChangeEvent<{}>, value: string) => void;
  control: any;
  simpleCondition: string | undefined;
}

export const useConditionSimpleLabelState = (
  props: ValuesConditionsProps
): IUseConditionSimpleLabelState => {
  const { control } = useFormContext();
  const { ruleId, ruleName } = useParams<IUseParams>();
  const defaultValueRadio: string[] = props.defaultValueList;
  const [simpleCondition, setSimpleCondition] = useState<string | undefined>(
    undefined
  );

  const selectSimpleCondition = (
    _event: React.ChangeEvent<{}>,
    value: string
  ): void => {
    setSimpleCondition(value);
  };

  useEffect(() => {
    if (simpleCondition) {
      const valueToRetrieve: ICategory[] = [
        {
          label: get(ITEM_SIMPLE_CONDITION[props.tag], "label", ""),
          value: simpleCondition,
          selected: true,
        },
      ];

      props.setSelectedItems(valueToRetrieve);
    }
  }, [simpleCondition, ruleId, ruleName]);

  useEffect(() => {
    if (defaultValueRadio.length > 0 && isFormEditMode(ruleId || ruleName)) {
      setSimpleCondition(defaultValueRadio[0].toString());
    }
  }, [defaultValueRadio, ruleId, ruleName]);

  return {
    selectSimpleCondition,
    control,
    simpleCondition,
  };
};
