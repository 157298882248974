export enum FilterEnum {
  MIN = "min",
  MAX = "max",
  RANGE = "range",
  EQ = "eq",
  PROCESSING = "PROCESSING",
  PROCESSED = "PROCESSED",
  FAILED = "FAILED",
  INITIALIZED = "INITIALIZED",
  PARTIALLY_PROCESSED = "PARTIALLY_PROCESSED",
}
