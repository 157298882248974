import { defaultTo, get, isEqual, map, set } from "lodash";
import { ItemCategoryItem, ItemCategoryProps } from "@kushki/connect-ui";
import { useEffect, useState } from "react";
import { TOnChangeFilters } from "@kushki/connect-ui/dist/Components/Molecules/Filters/FilterDetailBar/FilterDetailBar.interfaces";
import { ConfigurationResponse } from "../../../../types/configuration_response";
import {
  IFilterTableComponent,
  IUseFilterDataTableComponent,
} from "../../../shared/interfaces/IFilterTableProps";
import { useAppSelector } from "../../../store/hooks/storeHook";
import { RootState } from "../../../store/store";
import { GetPayoutsRulesRequest } from "../../../../types/get_payouts_rules_request";
import {
  FilterEnum,
  FiltersGroup,
  FiltersRequestName,
} from "../../../shared/enums/FilterEnum";
import { initialState } from "../../../store/reducers/app/app";

export const useFilterTableComponentState = (
  props: IFilterTableComponent
): IUseFilterDataTableComponent => {
  const defaultFilters: GetPayoutsRulesRequest = {
    limit: 0,
    merchantId: "",
    offset: 0,
  };

  const [categoryItems, setCategoryItems] = useState<ItemCategoryProps[]>();
  const { configList } = useAppSelector((state: RootState) => ({
    ...state.app,
  }));

  const addFilters = (
    searchRuleRequest: GetPayoutsRulesRequest,
    itemCategoryProps: ItemCategoryProps
  ): void => {
    const filters: string[] = [];

    itemCategoryProps.items.forEach((item: ItemCategoryItem) => {
      filters.push(item.value);
    });
    set(
      searchRuleRequest,
      FiltersRequestName[itemCategoryProps.placeHolder].filterPath,
      filters.join("|")
    );
  };

  const getFilterItem = (filterEnum: FilterEnum): ItemCategoryProps => {
    let items: ConfigurationResponse = get(
      configList,
      FiltersRequestName[filterEnum].selectorType,
      []
    );

    if (filterEnum === FilterEnum.STATUS) {
      items = [];
      items.push({ name: "ENABLED", title: "Habilitado" });
      items.push({ name: "DISABLE", title: "Deshabilitado" });
      items.push({ name: "DRAFT", title: "Borrador" });
    }

    return {
      isMinimize: true,
      items: map(items, (element) => {
        return {
          label: element.title,
          selected: false,
          value:
            filterEnum === FilterEnum.STATUS ? element.name : element.field,
        };
      }),
      onItemsSelect: () => {},
      placeHolder: filterEnum,
      selectType: "multiple",
      type: FiltersRequestName[filterEnum].categoryItem,
    };
  };

  const getFilters = (): ItemCategoryProps[] => {
    return [
      getFilterItem(FilterEnum.STATUS),
      getFilterItem(FilterEnum.BUSINESS_CONDITIONS),
    ];
  };

  const setRequestFilter = (selected: ItemCategoryProps[]) => {
    if (selected.length > 0) {
      const search: GetPayoutsRulesRequest = {
        ...defaultTo(props.searchRuleRequest, defaultFilters),
      };

      selected.forEach((item: ItemCategoryProps) => {
        if (FiltersGroup.find((name: string) => name === item.placeHolder)) {
          addFilters(search, item);
        }
      });
      props.setOffset(0);
      props.setSearchRuleRequest(search);
    }
  };

  const changeFilter = (change: TOnChangeFilters) => {
    const search: GetPayoutsRulesRequest = {
      ...defaultTo(props.searchRuleRequest, defaultFilters),
    };

    FiltersGroup.find((name: string) => {
      if (name === change.affected.title) {
        const filter: [] = get(search, FiltersRequestName[name].filterPath, [])
          .split("|")
          .filter((item: any) => item !== change.affected.itemDeleted.value);

        set(search, FiltersRequestName[name].filterPath, filter.join("|"));
      }
    });
    props.setSearchRuleRequest(search);
  };

  useEffect(() => {
    if (!isEqual(configList, initialState.configList)) {
      setCategoryItems(getFilters());
    }
  }, [configList]);

  return {
    categoryItems,
    changeFilter,
    setRequestFilter,
  };
};
