import React from "react";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { TableSkeleton } from "../../Skeleton/TableSkeleton/TableSkeleton";
import { infoContentTableStyles } from "./InfoContentTable.styles";
import { BillingInfoLabelsEnum } from "../../../shared/enums/BillingInfoLabelsEnum";
import { ITableCellProps, ITableRowProps } from "../TableNodes/interfaces";
import { get } from "lodash";
import { CELL_COMPONENTS_BY_TABLE_ENUM } from "../TableNodes/constants";
import {
  TABLE_HEAD_DISABLE_SX,
  TableHeadStyles,
} from "../TableHead/TableHead.style";
import {
  CATALOG_HEADS,
  TableTypeEnum,
} from "../../../shared/catalogs/CatalogConfigTable";
import { TableBodyStyles } from "../TableBody/TableBody.style";
import { IRetentionInfoInterface } from "../../../containers/MyMerchantContainer/state/useMyMerchant.interfaces";
import { useTableRetention } from "./state/useTableRetention";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { ModalPdfViewer } from "@kushki/connect-ui";

export const TableRetention: React.FC<IRetentionInfoInterface> = (
  props: IRetentionInfoInterface
) => {
  const {
    handleMinimizeTable,
    isLoadingRetention,
    isMinimizeTable,
    retentionData,
    totalRetentionData,
    handleRequestSort,
    downloadFile,
  } = useTableRetention(props);

  const EnhancedTableHead = (props: any) => {
    const { headCells, onRequestSort } = props;

    return (
      <TableHead sx={TABLE_HEAD_DISABLE_SX[`${!!false}`]}>
        <TableRow sx={TableHeadStyles.row}>
          {headCells.map((headCell: any) => (
            <TableCell
              sx={TableHeadStyles.label}
              key={headCell.id}
              align={headCell.align}
            >
              {headCell.isSort ? (
                <Grid
                  container
                  alignItems={"center"}
                  onClick={() => onRequestSort(headCell.id)}
                  sx={infoContentTableStyles.sortGroup}
                >
                  <Grid item> {headCell.label}</Grid>
                  <Grid item>
                    <UnfoldMoreIcon sx={infoContentTableStyles.sortIcon} />
                  </Grid>
                </Grid>
              ) : (
                headCell.label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  return (
    <>
      {isLoadingRetention ? (
        <TableSkeleton
          headTable={CATALOG_HEADS[TableTypeEnum.RETENTION]}
          rowsPerPage={3}
          data-testid="TableSkeleton"
        />
      ) : (
        <Grid item>
          {retentionData.length > 0 ? (
            <Box>
              <TableContainer data-testid="Table-Container">
                <Box>
                  <Table sx={infoContentTableStyles.table}>
                    <EnhancedTableHead
                      headCells={CATALOG_HEADS[TableTypeEnum.RETENTION]}
                      sx={TableHeadStyles}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody sx={TableBodyStyles.body}>
                      {retentionData.map(
                        (row: ITableRowProps, index: number) => {
                          return (
                            <TableRow
                              key={row.id}
                              aria-label={`table-row-${row.id}`}
                              hover
                            >
                              {row.cells.map(
                                (
                                  { type, props }: ITableCellProps,
                                  cellIndex: number
                                ) => {
                                  const CellComponent =
                                    CELL_COMPONENTS_BY_TABLE_ENUM[type];

                                  return (
                                    <TableCell
                                      key={`row-${index}-cell-${cellIndex}`}
                                      scope="row"
                                      padding="none"
                                      align={get(props, "align", "center")}
                                    >
                                      <CellComponent
                                        {...props}
                                        key={`row-${index}-cell-${cellIndex}-component-${type}`}
                                      />
                                    </TableCell>
                                  );
                                }
                              )}
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </Box>
              </TableContainer>
              {totalRetentionData > 3 && (
                <Grid container>
                  <Typography
                    onClick={handleMinimizeTable}
                    sx={infoContentTableStyles.viewMoreText}
                    data-testid="showMore"
                  >
                    {isMinimizeTable
                      ? BillingInfoLabelsEnum.SHOW_MORE
                      : BillingInfoLabelsEnum.SHOW_LESS}
                  </Typography>
                </Grid>
              )}
            </Box>
          ) : (
            <Grid
              container
              justifyContent={"center"}
              sx={infoContentTableStyles.emptySearchContainer}
            >
              <Grid item>
                <Typography sx={infoContentTableStyles.emptySearchText}>
                  {BillingInfoLabelsEnum.RETENTION_EMPTY_LEGEND}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      <ModalPdfViewer
        isOpen={downloadFile.openModal}
        fileName={downloadFile.fileName}
        base64File={downloadFile.fileData}
        handleCloseModal={downloadFile.handleCloseModal}
      />
    </>
  );
};
