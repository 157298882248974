import { MenuItemNamesEnum } from "../infrastructure/MenuItemNamesEnum";
import {
  IconBank,
  IconCardSwipe,
  IconChartSmall,
  IconMixer,
  IconStickyFilled,
} from "@kushki/connect-ui";
import React from "react";
import { MenuItemStepsEnum } from "../infrastructure/MenuItemStepsEnum";
import { MenuItemMassivePathEnum } from "../infrastructure/MenuItemPathsEnum";

export const getSideMenuActiveIconByTitle = new Map([
  [
    MenuItemNamesEnum.MERCHANT_DATA,
    <IconBank
      key={MenuItemNamesEnum.MERCHANT_DATA}
      className="iconChoice"
      color="primary"
      fontSize="large"
    />,
  ],
  [
    MenuItemNamesEnum.BILLING_CONFIGURATION,
    <IconChartSmall
      key={MenuItemNamesEnum.BILLING_CONFIGURATION}
      className="iconChoice"
      color="primary"
      fontSize="large"
    />,
  ],
  [
    MenuItemNamesEnum.RATES_AND_DISCOUNTS,
    <IconStickyFilled
      key={MenuItemNamesEnum.RATES_AND_DISCOUNTS}
      className="iconChoice"
      color="primary"
      fontSize="large"
    />,
  ],
  [
    MenuItemNamesEnum.PROCESSING,
    <IconCardSwipe
      key={MenuItemNamesEnum.PROCESSING}
      className="iconChoice"
      color="primary"
      fontSize="large"
    />,
  ],
  [
    MenuItemNamesEnum.SERVICES,
    <IconMixer
      key={MenuItemNamesEnum.SERVICES}
      className="iconChoice"
      color="primary"
      fontSize="large"
    />,
  ],
]);

export const getSideMenuItemStepByTitle = new Map([
  [MenuItemNamesEnum.MERCHANT_DATA, MenuItemStepsEnum.BASIC_DATA],
  [
    MenuItemNamesEnum.BILLING_CONFIGURATION,
    MenuItemStepsEnum.CONFIG_RATES_INVOICE,
  ],
]);

export const getMassiveUrlByTitle = new Map([
  [MenuItemNamesEnum.MERCHANT_DATA, MenuItemMassivePathEnum.MERCHANT_DATA],
  [
    MenuItemNamesEnum.BILLING_CONFIGURATION,
    MenuItemMassivePathEnum.BILLING_CONFIGURATION,
  ],
  [
    MenuItemNamesEnum.RATES_AND_DISCOUNTS,
    MenuItemMassivePathEnum.RATES_DISCOUNTS_MASSIVE,
  ],
  [MenuItemNamesEnum.PROCESSING, MenuItemMassivePathEnum.PROCESSING],
  [MenuItemNamesEnum.SERVICES, MenuItemMassivePathEnum.SERVICES],
]);
