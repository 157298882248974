import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CommercialExecutiveList } from "../../../../types/commercial_executive_list";
import { ICommercialExecutive } from "../../interfaces/commercialExecutive/commercialExecutiveList.interfaces";
import getCommercialExecutives from "../../thunks/commercialExecutiveList/commercialExecutiveList.thunks";

export const initialState: ICommercialExecutive = {
  commercialExecutiveList: [] as CommercialExecutiveList[],
};

export const commercialExecutiveSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(
      getCommercialExecutives.fulfilled,
      (state, { payload }: PayloadAction<CommercialExecutiveList[]>) => {
        state.commercialExecutiveList = payload;
      }
    );
  },
  initialState,
  name: "commercialExecutiveList",
  reducers: {},
});

export default commercialExecutiveSlice.reducer;
