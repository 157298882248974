import React, { FC } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Controller } from "react-hook-form";
import { IRadioButton } from "./RadioButtonRow.interfaces";
import { radioButtonRowStyles } from "./RadioButtonRow.style";

export const RadioButtonRow: FC<IRadioButton> = ({
  name,
  control,
  defaultValue = "",
  options,
  rules,
  isDisable,
}: IRadioButton) => {
  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue as string}
      render={({ field }: any) => {
        return (
          <RadioGroup {...field} aria-label="gender" row>
            {options.map((user) => (
              <FormControlLabel
                sx={radioButtonRowStyles.buttonRow}
                value={user.value}
                label={user.name}
                key={user.value}
                control={<Radio color="primary" />}
                disabled={isDisable}
              />
            ))}
          </RadioGroup>
        );
      }}
    />
  );
};
