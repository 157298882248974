import React from "react";
import {
  Accordion,
  AccordionActions,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import { boardMembersAccordionStyles as styles } from "./BoardMembers.styles";
import { IconChevronDown, IconMore } from "@kushki/connect-ui";
import { Boardmembers } from "../../../../types/get_compliance_people_response";
import { personTypeEnum } from "../../../shared/enums/personType";
import BoardMemberContent from "./BoardMemberContent/BoardMemberContent";

interface IBoardMemberAccordionProps {
  boardMember: Boardmembers;
  showMenuActions: boolean;
}

const BoardMemberAccordion: React.FC<IBoardMemberAccordionProps> = ({
  boardMember,
  showMenuActions,
}: IBoardMemberAccordionProps) => {
  return (
    <Accordion sx={styles.accordion}>
      <AccordionSummary
        expandIcon={<IconChevronDown color="primary" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={styles.summary}
      >
        <Box>
          <Typography variant="subtitle2">
            {boardMember.personType === personTypeEnum.NATURAL
              ? `${boardMember.names} ${boardMember.lastNames}`
              : `${boardMember.socialReason}`}
          </Typography>
          <Typography variant="caption" sx={styles.subtitle}>
            Board member
          </Typography>
        </Box>
        {showMenuActions && (
          <AccordionActions>
            <IconButton
              data-testid="menuAction"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IconMore />
            </IconButton>
          </AccordionActions>
        )}
      </AccordionSummary>
      <BoardMemberContent boardMember={boardMember} />
    </Accordion>
  );
};

export default BoardMemberAccordion;
