import React from "react";
import {
  Box,
  Button,
  Chip,
  createStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { get } from "lodash";
import { IFilterResponse } from "@kushki/frontend-molecules/filter-container";

export interface IBoxFilter {
  filters: IOptionFilter[];
  onApplyFilter: (response: IFilterResponse, listFilter?: boolean) => void;
  handleClearFilters: () => void;
}

export interface IDataOption {
  key: string;
  label: string;
  selected: boolean;
}

interface IOptionFilter {
  id: string;
  title: string;
  options: IDataOption[];
}

enum backgroundColorEnum {
  primary = "#F7FAFC",
  secondary = "#B4B9BE",
}

enum textColorEnum {
  primary = "#6D7781",
  secondary = "white",
}

interface IChipSelect {
  key: string;
  selected: boolean;
  color: backgroundColorEnum;
  filter: string;
  label: string;
  textColor: textColorEnum;
}

const useStyles = makeStyles(() =>
  createStyles({
    kshExpand: {
      border: "none",
      borderTop: "1px solid rgba(0, 0, 0, 0.08)",
      margin: "0 !important",
      "& .MuiExpansionPanelSummary-root": {
        padding: "0 5px",
        minHeight: 40,
      },
      "& .MuiExpansionPanelDetails-root": {
        padding: "8px 0px 15px",
        display: "flow-root",
      },
      "& .MuiExpansionPanelSummary-content": {
        margin: "10px 0",
      },
    },
    btnClearFilter: {
      marginTop: 10,
      width: "auto",
    },
  })
);

const expandText: string = "Maximizar todo";
const minText: string = "Minimizar todo";

const populateOptionChips = (
  options: IOptionFilter[]
): { [key: string]: IChipSelect } => {
  let result: { [key: string]: IChipSelect } = {};
  let chips: IChipSelect[] = [];

  options.forEach((option: IOptionFilter) => {
    chips = chips.concat(
      option.options.map(
        (op: { key: string; label: string; selected: boolean }) => ({
          color: op.selected
            ? backgroundColorEnum.secondary
            : backgroundColorEnum.primary,
          filter: option.id,
          key: op.key,
          label: op.label,
          selected: op.selected,
          textColor: op.selected
            ? textColorEnum.secondary
            : textColorEnum.primary,
        })
      )
    );
  });
  chips.forEach((chip: IChipSelect) => (result[chip.key] = chip));
  return result;
};

const getAllAccordionId = (
  filters: IOptionFilter[]
): { [key: string]: boolean } => {
  let result: { [key: string]: boolean } = {};
  filters.forEach((filter: IOptionFilter) => (result[filter.id] = false));
  return result;
};

const BoxFilter = (props: IBoxFilter): JSX.Element => {
  const classes = useStyles();
  const [optionsChip, setOptionsChip] = React.useState<{
    [key: string]: IChipSelect;
  }>(populateOptionChips(props.filters));
  const [allExpanded, setAllExpanded] = React.useState<boolean>(false);
  const [textAllExpanded, setTextAllExpanded] = React.useState<string>(
    expandText
  );
  const [expandId, setExpandId] = React.useState<{ [key: string]: boolean }>(
    getAllAccordionId(props.filters)
  );

  const handleClickChip = (option: string): void => {
    optionsChip[option].color =
      optionsChip[option].color === backgroundColorEnum.secondary
        ? backgroundColorEnum.primary
        : backgroundColorEnum.secondary;
    optionsChip[option].selected = !optionsChip[option].selected;
    optionsChip[option].textColor =
      optionsChip[option].color === backgroundColorEnum.secondary
        ? textColorEnum.secondary
        : textColorEnum.primary;
    setOptionsChip({ ...optionsChip });
  };

  const isAllExpanded = (): boolean => {
    const keys: string[] = Object.keys(expandId);
    return keys.length === keys.filter((key: string) => expandId[key]).length;
  };

  const setExpandAllId = (expand: boolean): void => {
    Object.keys(expandId).forEach((key: string) => {
      expandId[key] = expand;
    });
  };

  const handleChange = (panel: string): void => {
    expandId[panel] = !expandId[panel];
    setAllExpanded(false);
    setExpandId({ ...expandId });
    if (isAllExpanded()) {
      setAllExpanded(true);
      setTextAllExpanded(minText);
    } else setTextAllExpanded(expandText);
  };

  const expandAll = (): void => {
    setExpandAllId(!allExpanded);
    setAllExpanded(!allExpanded);
    setTextAllExpanded(allExpanded ? expandText : minText);
  };

  const setFilterChange = (): IFilterResponse => {
    props.filters.map((filter: IOptionFilter) =>
      filter.options.map(
        (option: IDataOption) =>
          (option.selected = get(optionsChip[option.key], "selected", false))
      )
    );
    return {
      listFilter: props.filters,
    };
  };

  return (
    <React.Fragment>
      <Box display="flex" flexWrap="wrap" marginBottom={1}>
        <Box flexGrow={1}>Filtrar por</Box>
        <Box>
          <Button id="buttonOnExpandAccordion" onClick={expandAll}>
            {textAllExpanded}
          </Button>
        </Box>
      </Box>

      {props.filters.map((filter: IOptionFilter) => (
        <ExpansionPanel
          className={classes.kshExpand}
          key={filter.id}
          variant="outlined"
          expanded={expandId[filter.id]}
          onChange={() => handleChange(filter.id)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography>{filter.title}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            {filter.options.map(
              (option: { key: string; label: string; selected: boolean }) => (
                <React.Fragment key={option.key}>
                  <Chip
                    variant="default"
                    size="small"
                    label={option.label}
                    onClick={() => handleClickChip(option.key)}
                    style={{
                      margin: 5,
                      borderRadius: 6,
                      color: optionsChip[option.key].textColor,
                      background: optionsChip[option.key].color,
                    }}
                  />
                </React.Fragment>
              )
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}

      <Box display="flex" flexWrap="wrap" marginBottom={1}>
        <Box flexGrow={1}>
          <Button
            id="buttonOnClearFilter"
            variant="outlined"
            size="medium"
            onClick={props.handleClearFilters}
          >
            Restablecer
          </Button>
        </Box>
        <Box>
          <Button
            id="buttonOnApplyFilter"
            variant="contained"
            size="medium"
            color={"primary"}
            className={classes.btnClearFilter}
            onClick={() => props.onApplyFilter(setFilterChange(), true)}
          >
            Aplicar
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default BoxFilter;
