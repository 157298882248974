import React from "react";

import { Container, Typography } from "@material-ui/core";
import { ChipTransactionsStatus } from "../../ChipTransactionsStatus/ChipTransactionsStatus";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { getTransactionStatusTranslate } from "../../../shared/infrastructure/constants/transaction-status-enum";
import { format } from "date-fns-tz";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: "20px",
    paddingBottom: "10px",
  },
  h2: {
    marginTop: "2px",
    marginBottom: "0px !important",
    color: "46525C",
  },
  subtitle: {
    color: theme.palette.grey.A200,
    fontSize: "13px !important",
  },
  dateStyle: {
    color: theme.palette.grey["600"],
    fontSize: "16px !important",
    marginBottom: "4px !important",
  },
}));

export interface IPayoutsTransactionHeader {
  ticketNumber: string;
  status: string;
  created: string;
}

export const TransactionHeader: React.FC<IPayoutsTransactionHeader> = (
  props: IPayoutsTransactionHeader
) => {
  const classes = useStyles();
  const { ticketNumber, status, created } = props;

  return (
    <React.Fragment>
      <Container className={classes.container}>
        <Typography variant="body2" className={classes.subtitle}>
          Detalle de transacción
        </Typography>
        <h1 className={classes.h2}>{ticketNumber}</h1>
        <Typography className={classes.dateStyle}>
          {format(new Date(created), "dd MMM'.' yyyy") +
            " " +
            format(new Date(created), "HH:mm")}
        </Typography>
        <ChipTransactionsStatus
          status={status}
          message={""}
          disableHover={true}
          text={getTransactionStatusTranslate(status)}
        />
      </Container>
    </React.Fragment>
  );
};
