import { IBreadCrumbItemProps } from "@kushki/connect-ui/dist/Components/Atoms/BreadCrumbItem/BreadCrumbItem.interfaces";

export enum NOTIFICATION_CONFIG_TYPE {
  API = "api",
  CONSOLE = "console",
}

export const NOTIFICATION_CONFIG_TYPE_TEXT: {
  [K in NOTIFICATION_CONFIG_TYPE]: string;
} = {
  [NOTIFICATION_CONFIG_TYPE.API]: "API",
  [NOTIFICATION_CONFIG_TYPE.CONSOLE]: "consola",
};
export const NOTIFICATION_CONFIG_DEFAULT = NOTIFICATION_CONFIG_TYPE.CONSOLE;

export interface IStatusChip {
  color: "success" | "error" | "info";
  statusText: string;
}

export interface INotificationDetailData {
  date: string;
  origin: string;
  destiny: string;
  paymentMethod: string;
  event: string;
  config: string;
  alias?: string;
  statusChip: IStatusChip;
  bodyRequest: string;
  bodyResponse: string;
}

export interface INotificationState {
  notificationDetail: INotificationDetailData;
  breadCrumbItems: IBreadCrumbItemProps[];
  backButtonURL: string;
}
