import { makeStyles } from "@material-ui/core/styles";
import { createStyles, Theme } from "@material-ui/core";

export const useWalletPendingStyles = makeStyles((theme: Theme) =>
  createStyles({
    containedButton: {
      fontSize: "16px",
    },
    button: {
      color: "#6D7781",
      fontSize: "16px",
    },
    buttonOmitted: {
      color: "#6D7781",
      fontSize: "16px",
      border: "1px solid #CBD5E0",
      borderRadius: "4px",
    },
    text: {
      display: "inline",
      fontWeight: 300,
    },
    textSubtitle: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px !important",
      lineHeight: "140%",
      color: "#293036",
    },
    textBolder: { display: "inline", fontWeight: 500 },
    box: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      borderRadius: 4,
    },
    boxSecondConfig: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      borderRadius: 4,
    },
    subtitleBox: {
      marginTop: "8px",
      marginBottom: "8px",
    },
    stepIcon: {
      cursor: "pointer",
      backgroundColor: "#F0F4F9",
      borderRadius: "4px",
    },
    chip: {
      backgroundColor: "rgba(240, 244, 249, 1)",
      color: theme.palette.text.primary,
      borderRadius: "5px",
    },
    buttonEdit: {
      backgroundColor: "#F0F4F9",
      marginLeft: theme.spacing(2),
      borderRadius: "5px",
      color: theme.palette.text.primary,
    },
    grid: {
      padding: "8px !important",
    },
  })
);
