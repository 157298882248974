import { StatusChipEnum, StatusEnum } from "../enums/StatusEnum";

export const getColorByStatus = (status: string) => {
  switch (status) {
    case StatusEnum.ACTIVE:
      return "success";
    case StatusEnum.INACTIVE:
      return "error";
    case StatusEnum.PENDING:
      return "warning";
    default:
      return "info";
  }
};

export const getBackgroundChipColorByStatus = (status: string) => {
  switch (status) {
    case StatusChipEnum.ACTIVE:
      return "#0DC298";
    case StatusChipEnum.INACTIVE:
      return "#EEF6FF";
    default:
      return "info";
  }
};

export const getColorChipByStatus = (status: string) => {
  switch (status) {
    case StatusChipEnum.ACTIVE:
    case StatusEnum.ACTIVE:
      return "white";
    case StatusEnum.INACTIVE:
      return "#6D7781";
    case StatusEnum.PENDING:
      return "warning";
    default:
      return "info";
  }
};
