import { InputAdornment, TextField } from "@mui/material";
import { Control, Controller, DeepMap, FieldError } from "react-hook-form";
import { ErrorsEnum } from "../../../shared/infrastructure/enums/ErrorEnum";
import { LabelEnum } from "../../../shared/infrastructure/enums/LabelEnum";
import { get } from "lodash";
import { validateAlias } from "../../../shared/utils/form-utils";
import { RuleItem } from "../../../../types/get_business_rules_response";
import React from "react";
import { FormFieldEnum } from "../../../shared/infrastructure/enums/FormFieldEnum";
import { GetRuleResponse } from "../../../../types/get_rule_response";

export interface AliasComponentProps {
  control: Control<Record<string, any>>;
  getRuleById: GetRuleResponse | undefined;
  businessRules: RuleItem[] | undefined;
  formErrors: [boolean, string];
  errors: DeepMap<Record<string, any>, FieldError>;
  prefixAtomicCounter: string | undefined;
}

export const AliasComponent = ({
  control,
  getRuleById,
  businessRules,
  errors,
  formErrors,
  prefixAtomicCounter,
}: AliasComponentProps) => {
  return (
    <Controller
      control={control}
      name={FormFieldEnum.RULE_NAME}
      defaultValue=""
      rules={{
        required: ErrorsEnum.REQUIRED_FIELD,
        validate: (value) =>
          validateAlias(value, getRuleById, businessRules) ||
          ErrorsEnum.ALIAS_REPEATED,
      }}
      render={(field) => (
        <TextField
          {...field}
          name={FormFieldEnum.RULE_NAME}
          label={LabelEnum.ALIAS}
          margin="normal"
          variant="outlined"
          fullWidth
          error={get(errors, FormFieldEnum.RULE_NAME)}
          helperText={formErrors[1]}
          InputProps={{
            startAdornment: prefixAtomicCounter ? (
              <InputAdornment position="start">
                {prefixAtomicCounter}
              </InputAdornment>
            ) : (
              <div />
            ),
          }}
        />
      )}
    />
  );
};
