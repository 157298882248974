import { MerchantNodeData } from "../../../../types/search_merchant_response";
import { CountryEnum } from "../../enum/CountryEnum";
import { ConfigTypeEnum } from "../../enum/configEnum";
import { get, remove } from "lodash";
import { requiredConfigs } from "../../constants/requiredConfigs";
import { StatusConfigEnum, StatusNodeEnum } from "../../enum/statusConfigEnum";
import { Configs } from "../../../../types/search_merchant_node_response";

export const validateRequiredConfigs = (
  merchant: MerchantNodeData
): boolean => {
  if (get(merchant, "country", "") !== CountryEnum.ecuador) {
    remove(
      requiredConfigs,
      (data: ConfigTypeEnum) => data === ConfigTypeEnum.cn017
    );
  }

  return requiredConfigs.every((requiredConfig: string) => {
    return get(merchant, "configs", []).some(
      (config: Configs) =>
        config.configuration === requiredConfig &&
        (config.status === StatusConfigEnum.COMPLETE ||
          config.status === StatusConfigEnum.OMITTED)
    );
  });
};
export const addMerchantIdByConfigsRequired = (
  branch: MerchantNodeData,
  customer: MerchantNodeData
): string[] => {
  const isValidBranch: boolean = validateRequiredConfigs(branch);
  const isValidCustomer: boolean = validateRequiredConfigs(customer);

  return isValidBranch && isValidCustomer
    ? [get(branch, "merchant_id", ""), get(customer, "merchant_id", "")]
    : !isValidCustomer && isValidBranch
    ? [get(branch, "merchant_id", "")]
    : [];
};

export const validateStatusMerchant = (
  merchantIdsToActivate: string[],
  merchantsInfo: MerchantNodeData[]
) => {
  const filterMerchants = merchantsInfo.filter(
    (m: MerchantNodeData) => m.merchant_status !== StatusNodeEnum.PENDING
  );

  filterMerchants.forEach((merchant: MerchantNodeData) => {
    remove(merchantIdsToActivate, (m: string) => merchant.merchant_id === m);
  });

  return merchantIdsToActivate;
};
