/* istanbul ignore file */
import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

export interface ElementIndexProps {
  title: string;
  image: string;
  elements: {
    subtitle: string;
    description: string;
    redirect: string;
  }[];
  isMedium: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  subtitle: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "120%",
    paddingBottom: "8px",
  },
}));

export const ElementIndex = (props: ElementIndexProps) => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <React.Fragment>
      <Card>
        <CardHeader
          avatar={<Avatar className={classes.large} src={props.image} />}
          title={
            <Typography variant="h5" color={"primary"}>
              {props.title}
            </Typography>
          }
        />
        <CardContent>
          <Grid
            container
            spacing={4}
            style={{ paddingLeft: props.isMedium ? 67 : 0 }}
          >
            {props.elements.map(
              (element: {
                subtitle: string;
                description: string;
                redirect: string;
              }) => (
                <Grid item xs={12} md={6} key={1}>
                  <Box
                    onClick={() => {
                      history.push(element.redirect);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <Typography className={classes.subtitle} color={"primary"}>
                      {element.subtitle}
                    </Typography>
                    <Typography variant="body2">
                      {element.description}
                    </Typography>
                  </Box>
                </Grid>
              )
            )}
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};
