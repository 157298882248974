import React from "react";
import { Accordion, Alert, IconEdit, IconMore } from "@kushki/connect-ui";
import { Box, Grid } from "@mui/material";
import { DeferredOptions } from "../../../../types/get_deferred_admin_merchant_response";
import { DeferredResume } from "./DeferredResume/DeferredResume";
import { DeferredStatusEnum } from "../../../shared/enums/DeferredStatusEnum";
import { TagColorEnum } from "../../../shared/enums/TagColorEnum";
import { get, defaultTo, isEmpty } from "lodash";
import {
  ProcessorNameDeferred,
  ProcessorNameLabel,
} from "./DeferredResume/DeferredResume.data";
import { DeferredStatusData } from "./DeferredList.data";
import { NodeInfoPath } from "../../../shared/enums/NodeInfoPath";
import { DeferredConfigResponse } from "../../../../types/get_deferred_config_response";
import { GetNodeInfoResponse } from "../../../../types/get_node_info_response";
import { DeleteModalInfo } from "../../BusinessRules/BusinessRules.interfaces";
import { useDeferredList } from "./state/useDeferredList";
import { RulesInformationData } from "../../BusinessRules/RulesInformation/RulesInformation.data";
import { Trash } from "react-feather";

export interface DeferredListProps {
  deferredList: DeferredOptions;
  deferredConfig: DeferredConfigResponse;
  nodeInfo: GetNodeInfoResponse;
  deleteModalInfo: DeleteModalInfo;
}

export const DeferredList: React.FC<DeferredListProps> = ({
  deferredList,
  deferredConfig,
  nodeInfo,
  deleteModalInfo,
}: DeferredListProps) => {
  const {
    getDropDownList,
    hasUnsentDeferred,
    isMassiveBranches,
  } = useDeferredList(deferredList, nodeInfo, deleteModalInfo);

  return (
    <>
      {hasUnsentDeferred && isMassiveBranches && (
        <Grid>
          <Grid item display={"flex"} alignItems={"center"} bgcolor={"#FFF1D0"}>
            <Alert
              title={RulesInformationData.SaveAlertMessage}
              type="warning"
            />
          </Grid>
          &nbsp;
        </Grid>
      )}
      {deferredList.slice(0, 5).map((deferred, index) => {
        const merchantStatus: string = get(deferred, "merchantStatus", "");
        const deferredStatus: string = get(deferred, "status", merchantStatus);
        const status: string = isEmpty(deferredStatus)
          ? merchantStatus
          : deferredStatus;
        return (
          <Box key={index} sx={{ paddingBottom: "15px" }}>
            <Accordion
              text={mapProcessorNameToLabel(
                defaultTo(deferred.processorName, "")
              )}
              divider={false}
              isOpen={false}
              nesting={1}
              nestingLabel={""}
              tagText={getDeferredTextTag(status)}
              tagColor={getDeferredColorTag(status)}
              iconButton={<IconMore />}
              listDropdown={getDropDownList(
                deferred.id,
                <IconEdit />,
                <Trash />,
                get(deferred, "isSent", true)
              )}
            >
              <DeferredResume
                deferredInfo={deferred}
                deferredConfig={deferredConfig}
                country={get(nodeInfo, NodeInfoPath.InfoCountry, "")}
              />
            </Accordion>
          </Box>
        );
      })}
    </>
  );
};

const getDeferredTextTag = (status: string): string => {
  switch (status) {
    case DeferredStatusEnum.ENABLED:
      return DeferredStatusData.ENABLED;
    case DeferredStatusEnum.DISABLED:
      return DeferredStatusData.DISABLED;
    default:
      return DeferredStatusData.UNKNOWN;
  }
};

const getDeferredColorTag = (status: string) => {
  switch (status) {
    case DeferredStatusEnum.ENABLED:
      return TagColorEnum.Success;
    case DeferredStatusEnum.DISABLED:
      return TagColorEnum.Error;
    default:
      return TagColorEnum.Warning;
  }
};

export const mapProcessorNameToLabel = (processorName: string): string => {
  switch (processorName) {
    case ProcessorNameDeferred.BILLPOCKET:
      return ProcessorNameLabel.BILLPOCKET;
    case ProcessorNameDeferred.CREDIMATIC:
      return ProcessorNameLabel.CREDIMATIC;
    case ProcessorNameDeferred.DATAFAST:
      return ProcessorNameLabel.DATAFAST;
    case ProcessorNameDeferred.KUSHKI_ACQ:
      return ProcessorNameLabel.KUSHKI_ACQ;
    case ProcessorNameDeferred.PROSA:
      return ProcessorNameLabel.PROSA;
    case ProcessorNameDeferred.CREDOMATIC:
      return ProcessorNameLabel.CREDOMATIC;
    case ProcessorNameDeferred.FIS:
      return ProcessorNameLabel.FIS;

    default:
      return processorName;
  }
};
