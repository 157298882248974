import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AdminSearchDownloadRequestWS,
  IDownloadHistoricRefunds,
} from "./download_historic_refunds.interfaces";
import { wsToPromise } from "../../../shared/utils/websockets/wsToPromise/ws_to_promise";
import { WebsocketStatusEnum } from "../../../shared/enums/websocket_status_enum";
import { API_ROUTES } from "../../../shared/constants/api_routes";

export const downloadHistoricRefunds = createAsyncThunk(
  "transactions/downloadHistoricRefunds",
  async ({ data }: IDownloadHistoricRefunds): Promise<boolean> => {
    const payload: AdminSearchDownloadRequestWS = {
      action: "downloadB2CRefunds",
      data,
    };

    const response = await wsToPromise<{ status: string; url: string }>(
      API_ROUTES.DOWNLOAD_HISTORIC_REFUNDS,
      payload
    );

    if (!response || response?.status === WebsocketStatusEnum.COMPLETED_EMPTY) {
      throw new Error("Error Exists");
    }

    try {
      window.open(response.url, "_blank", "noreferrer");
    } catch (e) {}

    return true;
  }
);
