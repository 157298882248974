/**
 * Catalog Peru
 * Catalog Peru
 */
import { Category } from "../constants/MerchantInformationConstants";
import { CatalogsEnum } from "../enums/CatalogsEnum";

export const CatalogPe: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { name: "DNI", value: "0" },
    { name: "Pasaporte", value: "1" },
    { name: "Ruc", value: "2" },
    { name: "Carnet Ext", value: "3" },
  ],
  [CatalogsEnum.Provinces]: [
    { name: "CHACHAPOYAS", value: "0101" },
    { name: "BAGUA", value: "0102" },
    { name: "BONGARA", value: "0103" },
    { name: "CONDORCANQUI", value: "0104" },
    { name: "LUYA", value: "0105" },
    { name: "RODRIGUEZ DE MENDOZA", value: "0106" },
    { name: "UTCUBAMBA", value: "0107" },
    { name: "HUARAZ", value: "0201" },
    { name: "AIJA", value: "0202" },
    { name: "ANTONIO RAYMONDI", value: "0203" },
    { name: "ASUNCION", value: "0204" },
    { name: "BOLOGNESI", value: "0205" },
    { name: "CARHUAZ", value: "0206" },
    { name: "CARLOS FERMIN FITZCARRALD", value: "0207" },
    { name: "CASMA", value: "0208" },
    { name: "CORONGO", value: "0209" },
    { name: "HUARI", value: "0210" },
    { name: "HUARMEY", value: "0211" },
    { name: "HUAYLAS", value: "0212" },
    { name: "MARISCAL LUZURIAGA", value: "0213" },
    { name: "OCROS", value: "0214" },
    { name: "PALLASCA", value: "0215" },
    { name: "POMABAMBA", value: "0216" },
    { name: "RECUAY", value: "0217" },
    { name: "SANTA", value: "0218" },
    { name: "SIHUAS", value: "0219" },
    { name: "YUNGAY", value: "0220" },
    { name: "ABANCAY", value: "0301" },
    { name: "ANDAHUAYLAS", value: "0302" },
    { name: "ANTABAMBA", value: "0303" },
    { name: "AYMARAES", value: "0304" },
    { name: "COTABAMBAS", value: "0305" },
    { name: "CHINCHEROS", value: "0306" },
    { name: "GRAU", value: "0307" },
    { name: "AREQUIPA", value: "0401" },
    { name: "CAMANA", value: "0402" },
    { name: "CARAVELI", value: "0403" },
    { name: "CASTILLA", value: "0404" },
    { name: "CAYLLOMA", value: "0405" },
    { name: "CONDESUYOS", value: "0406" },
    { name: "ISLAY", value: "0407" },
    { name: "LA UNION", value: "0408" },
    { name: "HUAMANGA", value: "0501" },
    { name: "CANGALLO", value: "0502" },
    { name: "HUANCA SANCOS", value: "0503" },
    { name: "HUANTA", value: "0504" },
    { name: "LA MAR", value: "0505" },
    { name: "LUCANAS", value: "0506" },
    { name: "PARINACOCHAS", value: "0507" },
    { name: "PAUCAR DEL SARA SARA", value: "0508" },
    { name: "SUCRE", value: "0509" },
    { name: "VICTOR FAJARDO", value: "0510" },
    { name: "VILCAS HUAMAN", value: "0511" },
    { name: "CAJAMARCA", value: "0601" },
    { name: "CAJABAMBA", value: "0602" },
    { name: "CELENDIN", value: "0603" },
    { name: "CHOTA", value: "0604" },
    { name: "CONTUMAZA", value: "0605" },
    { name: "CUTERVO", value: "0606" },
    { name: "HUALGAYOC", value: "0607" },
    { name: "JAEN", value: "0608" },
    { name: "SAN IGNACIO", value: "0609" },
    { name: "SAN MARCOS", value: "0610" },
    // tslint:disable-next-line:no-duplicate-string
    { name: "SAN MIGUEL", value: "0611" },
    { name: "SAN PABLO", value: "0612" },
    // tslint:disable-next-line:no-duplicate-string
    { name: "SANTA CRUZ", value: "0613" },
    { name: "PROV. CONST. DEL CALLAO", value: "0701" },
    { name: "CUSCO", value: "0801" },
    { name: "ACOMAYO", value: "0802" },
    { name: "ANTA", value: "0803" },
    { name: "CALCA", value: "0804" },
    { name: "CANAS", value: "0805" },
    { name: "CANCHIS", value: "0806" },
    { name: "CHUMBIVILCAS", value: "0807" },
    { name: "ESPINAR", value: "0808" },
    { name: "LA CONVENCION", value: "0809" },
    { name: "PARURO", value: "0810" },
    { name: "PAUCARTAMBO", value: "0811" },
    { name: "QUISPICANCHI", value: "0812" },
    { name: "URUBAMBA", value: "0813" },
    { name: "HUANCAVELICA", value: "0901" },
    { name: "ACOBAMBA", value: "0902" },
    { name: "ANGARAES", value: "0903" },
    { name: "CASTROVIRREYNA", value: "0904" },
    { name: "CHURCAMPA", value: "0905" },
    { name: "HUAYTARA", value: "0906" },
    { name: "TAYACAJA", value: "0907" },
    { name: "HUANUCO", value: "1001" },
    { name: "AMBO", value: "1002" },
    { name: "DOS DE MAYO", value: "1003" },
    { name: "HUACAYBAMBA", value: "1004" },
    { name: "HUAMALIES", value: "1005" },
    { name: "LEONCIO PRADO", value: "1006" },
    { name: "MARAÑON", value: "1007" },
    { name: "PACHITEA", value: "1008" },
    { name: "PUERTO INCA", value: "1009" },
    { name: "LAURICOCHA", value: "1010" },
    { name: "YAROWILCA", value: "1011" },
    { name: "ICA", value: "1101" },
    { name: "CHINCHA", value: "1102" },
    { name: "NAZCA", value: "1103" },
    { name: "PALPA", value: "1104" },
    { name: "PISCO", value: "1105" },
    { name: "HUANCAYO", value: "1201" },
    { name: "CONCEPCION", value: "1202" },
    { name: "CHANCHAMAYO", value: "1203" },
    { name: "JAUJA", value: "1204" },
    { name: "JUNIN", value: "1205" },
    { name: "SATIPO", value: "1206" },
    { name: "TARMA", value: "1207" },
    { name: "YAULI", value: "1208" },
    { name: "CHUPACA", value: "1209" },
    { name: "TRUJILLO", value: "1301" },
    { name: "ASCOPE", value: "1302" },
    { name: "BOLIVAR", value: "1303" },
    { name: "CHEPEN", value: "1304" },
    { name: "JULCAN", value: "1305" },
    { name: "OTUZCO", value: "1306" },
    { name: "PACASMAYO", value: "1307" },
    { name: "PATAZ", value: "1308" },
    { name: "SANCHEZ CARRION", value: "1309" },
    { name: "SANTIAGO DE CHUCO", value: "1310" },
    { name: "GRAN CHIMU", value: "1311" },
    { name: "VIRU", value: "1312" },
    { name: "CHICLAYO", value: "1401" },
    { name: "FERREÑAFE", value: "1402" },
    { name: "LAMBAYEQUE", value: "1403" },
    { name: "LIMA", value: "1501" },
    { name: "BARRANCA", value: "1502" },
    { name: "CAJATAMBO", value: "1503" },
    { name: "CANTA", value: "1504" },
    { name: "CAÑETE", value: "1505" },
    { name: "HUARAL", value: "1506" },
    { name: "HUAROCHIRI", value: "1507" },
    { name: "HUAURA", value: "1508" },
    { name: "OYON", value: "1509" },
    { name: "YAUYOS", value: "1510" },
    { name: "MAYNAS", value: "1601" },
    { name: "ALTO AMAZONAS", value: "1602" },
    { name: "LORETO", value: "1603" },
    { name: "MARISCAL RAMON CASTILLA", value: "1604" },
    { name: "REQUENA", value: "1605" },
    { name: "UCAYALI", value: "1606" },
    { name: "DATEM DEL MARAÑON", value: "1607" },
    { name: "TAMBOPATA", value: "1701" },
    { name: "MANU", value: "1702" },
    { name: "TAHUAMANU", value: "1703" },
    { name: "MARISCAL NIETO", value: "1801" },
    { name: "GENERAL SANCHEZ CERRO", value: "1802" },
    { name: "ILO", value: "1803" },
    { name: "PASCO", value: "1901" },
    { name: "DANIEL ALCIDES CARRION", value: "1902" },
    { name: "OXAPAMPA", value: "1903" },
    { name: "PIURA", value: "2001" },
    { name: "AYABACA", value: "2002" },
    { name: "HUANCABAMBA", value: "2003" },
    { name: "MORROPON", value: "2004" },
    { name: "PAITA", value: "2005" },
    { name: "SULLANA", value: "2006" },
    { name: "TALARA", value: "2007" },
    { name: "SECHURA", value: "2008" },
    { name: "PUNO", value: "2101" },
    { name: "AZANGARO", value: "2102" },
    { name: "CARABAYA", value: "2103" },
    { name: "CHUCUITO", value: "2104" },
    { name: "EL COLLAO", value: "2105" },
    { name: "HUANCANE", value: "2106" },
    { name: "LAMPA", value: "2107" },
    { name: "MELGAR", value: "2108" },
    { name: "MOHO", value: "2109" },
    { name: "SAN ANTONIO DE PUTINA", value: "2110" },
    { name: "SAN ROMAN", value: "2111" },
    { name: "SANDIA", value: "2112" },
    { name: "YUNGUYO", value: "2113" },
    { name: "MOYOBAMBA", value: "2201" },
    { name: "BELLAVISTA", value: "2202" },
    { name: "EL DORADO", value: "2203" },
    { name: "HUALLAGA", value: "2204" },
    { name: "LAMAS", value: "2205" },
    // tslint:disable-next-line:no-duplicate-string
    { name: "MARISCAL CACERES", value: "2206" },
    { name: "PICOTA", value: "2207" },
    { name: "RIOJA", value: "2208" },
    { name: "SAN MARTIN", value: "2209" },
    { name: "TOCACHE", value: "2210" },
    { name: "TACNA", value: "2301" },
    { name: "CANDARAVE", value: "2302" },
    { name: "JORGE BASADRE", value: "2303" },
    { name: "TARATA", value: "2304" },
    { name: "TUMBES", value: "2401" },
    { name: "CONTRALMIRANTE VILLAR", value: "2402" },
    { name: "ZARUMILLA", value: "2403" },
    { name: "CORONEL PORTILLO", value: "2501" },
    { name: "ATALAYA", value: "2502" },
    { name: "PADRE ABAD", value: "2503" },
    { name: "PURUS", value: "2504" },
  ],
  [CatalogsEnum.Cities]: [
    { name: "CHACHAPOYAS", value: "010101" },
    { name: "ASUNCION", value: "010102" },
    { name: "BALSAS", value: "010103" },
    { name: "CHETO", value: "010104" },
    { name: "CHILIQUIN", value: "010105" },
    { name: "CHUQUIBAMBA", value: "010106" },
    { name: "GRANADA", value: "010107" },
    { name: "HUANCAS", value: "010108" },
    { name: "LA JALCA", value: "010109" },
    { name: "LEIMEBAMBA", value: "010110" },
    { name: "LEVANTO", value: "010111" },
    { name: "MAGDALENA", value: "010112" },
    { name: "MARISCAL CASTILLA", value: "010113" },
    { name: "MOLINOPAMPA", value: "010114" },
    { name: "MONTEVIDEO", value: "010115" },
    { name: "OLLEROS", value: "010116" },
    { name: "QUINJALCA", value: "010117" },
    { name: "SAN FRANCISCO DE DAGUAS", value: "010118" },
    { name: "SAN ISIDRO DE MAINO", value: "010119" },
    { name: "SOLOCO", value: "010120" },
    { name: "SONCHE", value: "010121" },
    { name: "BAGUA", value: "010201" },
    { name: "ARAMANGO", value: "010202" },
    { name: "COPALLIN", value: "010203" },
    { name: "EL PARCO", value: "010204" },
    { name: "IMAZA", value: "010205" },
    { name: "LA PECA", value: "010206" },
    { name: "JUMBILLA", value: "010301" },
    { name: "CHISQUILLA", value: "010302" },
    { name: "CHURUJA", value: "010303" },
    { name: "COROSHA", value: "010304" },
    { name: "CUISPES", value: "010305" },
    { name: "FLORIDA", value: "010306" },
    { name: "JAZAN", value: "010307" },
    { name: "RECTA", value: "010308" },
    { name: "SAN CARLOS", value: "010309" },
    { name: "SHIPASBAMBA", value: "010310" },
    { name: "VALERA", value: "010311" },
    { name: "YAMBRASBAMBA", value: "010312" },
    { name: "NIEVA", value: "010401" },
    { name: "EL CENEPA", value: "010402" },
    { name: "RIO SANTIAGO", value: "010403" },
    { name: "LAMUD", value: "010501" },
    { name: "CAMPORREDONDO", value: "010502" },
    { name: "COCABAMBA", value: "010503" },
    { name: "COLCAMAR", value: "010504" },
    { name: "CONILA", value: "010505" },
    { name: "INGUILPATA", value: "010506" },
    { name: "LONGUITA", value: "010507" },
    { name: "LONYA CHICO", value: "010508" },
    { name: "LUYA", value: "010509" },
    { name: "LUYA VIEJO", value: "010510" },
    { name: "MARIA", value: "010511" },
    { name: "OCALLI", value: "010512" },
    { name: "OCUMAL", value: "010513" },
    { name: "PISUQUIA", value: "010514" },
    { name: "PROVIDENCIA", value: "010515" },
    // tslint:disable-next-line:no-duplicate-string
    { name: "SAN CRISTOBAL", value: "010516" },
    { name: "SAN FRANCISCO DEL YESO", value: "010517" },
    // tslint:disable-next-line:no-duplicate-string
    { name: "SAN JERONIMO", value: "010518" },
    { name: "SAN JUAN DE LOPECANCHA", value: "010519" },
    { name: "SANTA CATALINA", value: "010520" },
    // tslint:disable-next-line:no-duplicate-string
    { name: "SANTO TOMAS", value: "010521" },
    { name: "TINGO", value: "010522" },
    { name: "TRITA", value: "010523" },
    { name: "SAN NICOLAS", value: "010601" },
    { name: "CHIRIMOTO", value: "010602" },
    { name: "COCHAMAL", value: "010603" },
    { name: "HUAMBO", value: "010604" },
    { name: "LIMABAMBA", value: "010605" },
    { name: "LONGAR", value: "010606" },
    { name: "MARISCAL BENAVIDES", value: "010607" },
    { name: "MILPUC", value: "010608" },
    { name: "OMIA", value: "010609" },
    // tslint:disable-next-line:no-duplicate-string
    { name: "SANTA ROSA", value: "010610" },
    { name: "TOTORA", value: "010611" },
    { name: "VISTA ALEGRE", value: "010612" },
    { name: "BAGUA GRANDE", value: "010701" },
    { name: "CAJARURO", value: "010702" },
    { name: "CUMBA", value: "010703" },
    { name: "EL MILAGRO", value: "010704" },
    { name: "JAMALCA", value: "010705" },
    { name: "LONYA GRANDE", value: "010706" },
    { name: "YAMON", value: "010707" },
    { name: "HUARAZ", value: "020101" },
    { name: "COCHABAMBA", value: "020102" },
    { name: "COLCABAMBA", value: "020103" },
    { name: "HUANCHAY", value: "020104" },
    { name: "INDEPENDENCIA", value: "020105" },
    { name: "JANGAS", value: "020106" },
    { name: "LA LIBERTAD", value: "020107" },
    { name: "OLLEROS", value: "020108" },
    { name: "PAMPAS", value: "020109" },
    { name: "PARIACOTO", value: "020110" },
    { name: "PIRA", value: "020111" },
    { name: "TARICA", value: "020112" },
    { name: "AIJA", value: "020201" },
    { name: "CORIS", value: "020202" },
    { name: "HUACLLAN", value: "020203" },
    { name: "LA MERCED", value: "020204" },
    { name: "SUCCHA", value: "020205" },
    { name: "LLAMELLIN", value: "020301" },
    { name: "ACZO", value: "020302" },
    { name: "CHACCHO", value: "020303" },
    { name: "CHINGAS", value: "020304" },
    { name: "MIRGAS", value: "020305" },
    { name: "SAN JUAN DE RONTOY", value: "020306" },
    { name: "CHACAS", value: "020401" },
    { name: "ACOCHACA", value: "020402" },
    { name: "CHIQUIAN", value: "020501" },
    { name: "ABELARDO PARDO LEZAMETA", value: "020502" },
    { name: "ANTONIO RAYMONDI", value: "020503" },
    { name: "AQUIA", value: "020504" },
    { name: "CAJACAY", value: "020505" },
    { name: "CANIS", value: "020506" },
    { name: "COLQUIOC", value: "020507" },
    { name: "HUALLANCA", value: "020508" },
    { name: "HUASTA", value: "020509" },
    { name: "HUAYLLACAYAN", value: "020510" },
    { name: "LA PRIMAVERA", value: "020511" },
    { name: "MANGAS", value: "020512" },
    { name: "PACLLON", value: "020513" },
    { name: "SAN MIGUEL DE CORPANQUI", value: "020514" },
    { name: "TICLLOS", value: "020515" },
    { name: "CARHUAZ", value: "020601" },
    { name: "ACOPAMPA", value: "020602" },
    { name: "AMASHCA", value: "020603" },
    { name: "ANTA", value: "020604" },
    { name: "ATAQUERO", value: "020605" },
    { name: "MARCARA", value: "020606" },
    { name: "PARIAHUANCA", value: "020607" },
    { name: "SAN MIGUEL DE ACO", value: "020608" },
    { name: "SHILLA", value: "020609" },
    { name: "TINCO", value: "020610" },
    { name: "YUNGAR", value: "020611" },
    { name: "SAN LUIS", value: "020701" },
    { name: "SAN NICOLAS", value: "020702" },
    { name: "YAUYA", value: "020703" },
    { name: "CASMA", value: "020801" },
    { name: "BUENA VISTA ALTA", value: "020802" },
    { name: "COMANDANTE NOEL", value: "020803" },
    { name: "YAUTAN", value: "020804" },
    { name: "CORONGO", value: "020901" },
    { name: "ACO", value: "020902" },
    { name: "BAMBAS", value: "020903" },
    { name: "CUSCA", value: "020904" },
    { name: "LA PAMPA", value: "020905" },
    { name: "YANAC", value: "020906" },
    { name: "YUPAN", value: "020907" },
    { name: "UARI", value: "021001" },
    { name: "ANRA", value: "021002" },
    { name: "CAJAY", value: "021003" },
    { name: "CHAVIN DE HUANTAR", value: "021004" },
    { name: "HUACACHI", value: "021005" },
    { name: "HUACCHIS", value: "021006" },
    { name: "HUACHIS", value: "021007" },
    { name: "HUANTAR", value: "021008" },
    { name: "MASIN", value: "021009" },
    { name: "PAUCAS", value: "021010" },
    { name: "PONTO", value: "021011" },
    { name: "RAHUAPAMPA", value: "021012" },
    { name: "RAPAYAN", value: "021013" },
    { name: "SAN MARCOS", value: "021014" },
    { name: "SAN PEDRO DE CHANA", value: "021015" },
    { name: "UCO", value: "021016" },
    { name: "HUARMEY", value: "021101" },
    { name: "COCHAPETI", value: "021102" },
    { name: "CULEBRAS", value: "021103" },
    { name: "HUAYAN", value: "021104" },
    { name: "MALVAS", value: "021105" },
    { name: "CARAZ", value: "021201" },
    { name: "HUALLANCA", value: "021202" },
    { name: "HUATA", value: "021203" },
    { name: "HUAYLAS", value: "021204" },
    { name: "MATO", value: "021205" },
    { name: "PAMPAROMAS", value: "021206" },
    { name: "PUEBLO LIBRE / 1", value: "021207" },
    { name: "SANTA CRUZ", value: "021208" },
    { name: "SANTO TORIBIO", value: "021209" },
    { name: "YURACMARCA", value: "021210" },
    { name: "PISCOBAMBA", value: "021301" },
    { name: "CASCA", value: "021302" },
    { name: "ELEAZAR GUZMAN BARRON", value: "021303" },
    { name: "FIDEL OLIVAS ESCUDERO", value: "021304" },
    { name: "LLAMA", value: "021305" },
    { name: "LLUMPA", value: "021306" },
    { name: "LUCMA", value: "021307" },
    { name: "MUSGA", value: "021308" },
    { name: "OCROS", value: "021401" },
    { name: "ACAS", value: "021402" },
    { name: "CAJAMARQUILLA", value: "021403" },
    { name: "CARHUAPAMPA", value: "021404" },
    { name: "COCHAS", value: "021405" },
    { name: "CONGAS", value: "021406" },
    { name: "LLIPA", value: "021407" },
    { name: "SAN CRISTOBAL DE RAJAN", value: "021408" },
    { name: "SAN PEDRO", value: "021409" },
    { name: "SANTIAGO DE CHILCAS", value: "021410" },
    { name: "CABANA", value: "021501" },
    { name: "BOLOGNESI", value: "021502" },
    { name: "CONCHUCOS", value: "021503" },
    { name: "HUACASCHUQUE", value: "021504" },
    { name: "HUANDOVAL", value: "021505" },
    { name: "LACABAMBA", value: "021506" },
    { name: "LLAPO", value: "021507" },
    { name: "PALLASCA", value: "021508" },
    { name: "PAMPAS", value: "021509" },
    { name: "SANTA ROSA", value: "021510" },
    { name: "TAUCA", value: "021511" },
    { name: "POMABAMBA", value: "021601" },
    { name: "HUAYLLAN", value: "021602" },
    { name: "PAROBAMBA", value: "021603" },
    { name: "QUINUABAMBA", value: "021604" },
    { name: "RECUAY", value: "021701" },
    { name: "CATAC", value: "021702" },
    { name: "COTAPARACO", value: "021703" },
    { name: "HUAYLLAPAMPA", value: "021704" },
    { name: "LLACLLIN", value: "021705" },
    { name: "MARCA", value: "021706" },
    { name: "PAMPAS CHICO", value: "021707" },
    { name: "PARARIN", value: "021708" },
    { name: "TAPACOCHA", value: "021709" },
    { name: "TICAPAMPA", value: "021710" },
    { name: "CHIMBOTE", value: "021801" },
    { name: "CACERES DEL PERU", value: "021802" },
    { name: "COISHCO", value: "021803" },
    { name: "MACATE", value: "021804" },
    { name: "MORO", value: "021805" },
    { name: "NEPEÑA", value: "021806" },
    { name: "SAMANCO", value: "021807" },
    { name: "SANTA", value: "021808" },
    { name: "NUEVO CHIMBOTE", value: "021809" },
    { name: "SIHUAS", value: "021901" },
    { name: "ACOBAMBA", value: "021902" },
    { name: "ALFONSO UGARTE", value: "021903" },
    { name: "CASHAPAMPA", value: "021904" },
    { name: "CHINGALPO", value: "021905" },
    { name: "HUAYLLABAMBA", value: "021906" },
    { name: "QUICHES", value: "021907" },
    { name: "RAGASH", value: "021908" },
    { name: "SAN JUAN", value: "021909" },
    { name: "SICSIBAMBA", value: "021910" },
    { name: "YUNGAY", value: "022001" },
    { name: "CASCAPARA", value: "022002" },
    { name: "MANCOS", value: "022003" },
    { name: "MATACOTO", value: "022004" },
    { name: "QUILLO", value: "022005" },
    { name: "RANRAHIRCA", value: "022006" },
    { name: "SHUPLUY", value: "022007" },
    { name: "YANAMA", value: "022008" },
    { name: "ABANCAY", value: "030101" },
    { name: "CHACOCHE", value: "030102" },
    { name: "CIRCA", value: "030103" },
    { name: "CURAHUASI", value: "030104" },
    { name: "HUANIPACA", value: "030105" },
    { name: "LAMBRAMA", value: "030106" },
    { name: "PICHIRHUA", value: "030107" },
    { name: "SAN PEDRO DE CACHORA", value: "030108" },
    { name: "TAMBURCO", value: "030109" },
    { name: "ANDAHUAYLAS", value: "030201" },
    { name: "ANDARAPA", value: "030202" },
    { name: "CHIARA", value: "030203" },
    { name: "HUANCARAMA", value: "030204" },
    { name: "HUANCARAY", value: "030205" },
    { name: "HUAYANA", value: "030206" },
    { name: "KISHUARA", value: "030207" },
    { name: "PACOBAMBA", value: "030208" },
    { name: "PACUCHA", value: "030209" },
    { name: "PAMPACHIRI", value: "030210" },
    { name: "POMACOCHA", value: "030211" },
    { name: "SAN ANTONIO DE CACHI", value: "030212" },
    { name: "SAN JERONIMO", value: "030213" },
    { name: "SAN MIGUEL DE CHACCRAMPA", value: "030214" },
    { name: "SANTA MARIA DE CHICMO", value: "030215" },
    { name: "TALAVERA", value: "030216" },
    { name: "TUMAY HUARACA", value: "030217" },
    { name: "TURPO", value: "030218" },
    { name: "KAQUIABAMBA", value: "030219" },
    { name: "ANTABAMBA", value: "030301" },
    { name: "EL ORO", value: "030302" },
    { name: "HUAQUIRCA", value: "030303" },
    { name: "JUAN ESPINOZA MEDRANO", value: "030304" },
    { name: "OROPESA", value: "030305" },
    { name: "PACHACONAS", value: "030306" },
    { name: "SABAINO", value: "030307" },
    { name: "CHALHUANCA", value: "030401" },
    { name: "CAPAYA", value: "030402" },
    { name: "CARAYBAMBA", value: "030403" },
    { name: "CHAPIMARCA", value: "030404" },
    { name: "COLCABAMBA", value: "030405" },
    { name: "COTARUSE", value: "030406" },
    { name: "HUAYLLO", value: "030407" },
    { name: "JUSTO APU SAHUARAURA", value: "030408" },
    { name: "LUCRE", value: "030409" },
    { name: "POCOHUANCA", value: "030410" },
    { name: "SAN JUAN DE CHACÑA", value: "030411" },
    { name: "SAÑAYCA", value: "030412" },
    { name: "SORAYA", value: "030413" },
    { name: "TAPAIRIHUA", value: "030414" },
    { name: "TINTAY", value: "030415" },
    { name: "TORAYA", value: "030416" },
    { name: "YANACA", value: "030417" },
    { name: "TAMBOBAMBA", value: "030501" },
    { name: "COTABAMBAS", value: "030502" },
    { name: "COYLLURQUI", value: "030503" },
    { name: "HAQUIRA", value: "030504" },
    { name: "MARA", value: "030505" },
    { name: "CHALLHUAHUACHO", value: "030506" },
    { name: "CHINCHEROS", value: "030601" },
    { name: "ANCO-HUALLO", value: "030602" },
    { name: "COCHARCAS", value: "030603" },
    { name: "HUACCANA", value: "030604" },
    { name: "OCOBAMBA", value: "030605" },
    { name: "ONGOY", value: "030606" },
    { name: "URANMARCA", value: "030607" },
    { name: "RANRACANCHA", value: "030608" },
    { name: "CHUQUIBAMBILLA", value: "030701" },
    { name: "CURPAHUASI", value: "030702" },
    { name: "GAMARRA", value: "030703" },
    { name: "HUAYLLATI", value: "030704" },
    { name: "MAMARA", value: "030705" },
    { name: "MICAELA BASTIDAS", value: "030706" },
    { name: "PATAYPAMPA", value: "030707" },
    { name: "PROGRESO", value: "030708" },
    // tslint:disable-next-line:no-duplicate-string
    { name: "SAN ANTONIO", value: "030709" },
    { name: "SANTA ROSA", value: "030710" },
    { name: "TURPAY", value: "030711" },
    { name: "VILCABAMBA", value: "030712" },
    { name: "VIRUNDO", value: "030713" },
    { name: "CURASCO", value: "030714" },
    { name: "AREQUIPA", value: "040101" },
    { name: "ALTO SELVA ALEGRE", value: "040102" },
    { name: "CAYMA", value: "040103" },
    { name: "CERRO COLORADO", value: "040104" },
    { name: "CHARACATO", value: "040105" },
    { name: "CHIGUATA", value: "040106" },
    { name: "JACOBO HUNTER", value: "040107" },
    { name: "LA JOYA", value: "040108" },
    { name: "MARIANO MELGAR", value: "040109" },
    { name: "MIRAFLORES", value: "040110" },
    { name: "MOLLEBAYA", value: "040111" },
    { name: "PAUCARPATA", value: "040112" },
    { name: "POCSI", value: "040113" },
    { name: "POLOBAYA", value: "040114" },
    { name: "QUEQUEÑA", value: "040115" },
    { name: "SABANDIA", value: "040116" },
    { name: "SACHACA", value: "040117" },
    { name: "SAN JUAN DE SIGUAS /1", value: "040118" },
    { name: "SAN JUAN DE TARUCANI", value: "040119" },
    { name: "SANTA ISABEL DE SIGUAS", value: "040120" },
    { name: "SANTA RITA DE SIGUAS", value: "040121" },
    { name: "SOCABAYA", value: "040122" },
    { name: "TIABAYA", value: "040123" },
    { name: "UCHUMAYO", value: "040124" },
    { name: "VITOR", value: "040125" },
    { name: "YANAHUARA", value: "040126" },
    { name: "YARABAMBA", value: "040127" },
    { name: "YURA", value: "040128" },
    { name: "JOSE LUIS BUSTAMANTE Y RIVERO", value: "040129" },
    { name: "CAMANA", value: "040201" },
    { name: "JOSE MARIA QUIMPER", value: "040202" },
    { name: "MARIANO NICOLAS VALCARCEL", value: "040203" },
    { name: "MARISCAL CACERES", value: "040204" },
    { name: "NICOLAS DE PIEROLA", value: "040205" },
    { name: "OCOÑA", value: "040206" },
    { name: "QUILCA", value: "040207" },
    { name: "SAMUEL PASTOR", value: "040208" },
    { name: "CARAVELI", value: "040301" },
    { name: "ACARI", value: "040302" },
    { name: "ATICO", value: "040303" },
    { name: "ATIQUIPA", value: "040304" },
    { name: "BELLA UNION", value: "040305" },
    { name: "CAHUACHO", value: "040306" },
    { name: "CHALA", value: "040307" },
    { name: "CHAPARRA", value: "040308" },
    { name: "HUANUHUANU", value: "040309" },
    { name: "JAQUI", value: "040310" },
    { name: "LOMAS", value: "040311" },
    { name: "QUICACHA", value: "040312" },
    { name: "YAUCA", value: "040313" },
    { name: "APLAO", value: "040401" },
    { name: "ANDAGUA", value: "040402" },
    { name: "AYO", value: "040403" },
    { name: "CHACHAS", value: "040404" },
    { name: "CHILCAYMARCA", value: "040405" },
    { name: "CHOCO", value: "040406" },
    { name: "HUANCARQUI", value: "040407" },
    { name: "MACHAGUAY", value: "040408" },
    { name: "ORCOPAMPA", value: "040409" },
    { name: "PAMPACOLCA", value: "040410" },
    { name: "TIPAN", value: "040411" },
    { name: "UÑON", value: "040412" },
    { name: "URACA", value: "040413" },
    { name: "VIRACO", value: "040414" },
    { name: "CHIVAY", value: "040501" },
    { name: "ACHOMA", value: "040502" },
    { name: "CABANACONDE", value: "040503" },
    { name: "CALLALLI", value: "040504" },
    { name: "CAYLLOMA", value: "040505" },
    { name: "COPORAQUE", value: "040506" },
    { name: "HUAMBO", value: "040507" },
    { name: "HUANCA", value: "040508" },
    { name: "ICHUPAMPA", value: "040509" },
    { name: "LARI", value: "040510" },
    { name: "LLUTA", value: "040511" },
    { name: "MACA", value: "040512" },
    { name: "MADRIGAL", value: "040513" },
    { name: "SAN ANTONIO DE CHUCA 2/", value: "040514" },
    { name: "SIBAYO", value: "040515" },
    { name: "TAPAY", value: "040516" },
    { name: "TISCO", value: "040517" },
    { name: "TUTI", value: "040518" },
    { name: "YANQUE", value: "040519" },
    { name: "MAJES", value: "040520" },
    { name: "CHUQUIBAMBA", value: "040601" },
    { name: "ANDARAY", value: "040602" },
    { name: "CAYARANI", value: "040603" },
    { name: "CHICHAS", value: "040604" },
    { name: "IRAY", value: "040605" },
    { name: "RIO GRANDE", value: "040606" },
    { name: "SALAMANCA", value: "040607" },
    { name: "YANAQUIHUA", value: "040608" },
    { name: "MOLLENDO", value: "040701" },
    { name: "COCACHACRA", value: "040702" },
    { name: "DEAN VALDIVIA", value: "040703" },
    { name: "ISLAY", value: "040704" },
    { name: "MEJIA", value: "040705" },
    { name: "PUNTA DE BOMBON", value: "040706" },
    { name: "COTAHUASI", value: "040801" },
    { name: "ALCA", value: "040802" },
    { name: "CHARCANA", value: "040803" },
    { name: "HUAYNACOTAS", value: "040804" },
    { name: "PAMPAMARCA", value: "040805" },
    { name: "PUYCA", value: "040806" },
    { name: "QUECHUALLA", value: "040807" },
    { name: "SAYLA", value: "040808" },
    { name: "TAURIA", value: "040809" },
    { name: "TOMEPAMPA", value: "040810" },
    { name: "TORO", value: "040811" },
    { name: "AYACUCHO", value: "050101" },
    { name: "ACOCRO", value: "050102" },
    { name: "ACOS VINCHOS", value: "050103" },
    { name: "CARMEN ALTO", value: "050104" },
    { name: "CHIARA", value: "050105" },
    { name: "OCROS", value: "050106" },
    { name: "PACAYCASA", value: "050107" },
    { name: "QUINUA", value: "050108" },
    { name: "SAN JOSE DE TICLLAS", value: "050109" },
    // tslint:disable-next-line:no-duplicate-string
    { name: "SAN JUAN BAUTISTA", value: "050110" },
    { name: "SANTIAGO DE PISCHA", value: "050111" },
    { name: "SOCOS", value: "050112" },
    { name: "TAMBILLO", value: "050113" },
    { name: "VINCHOS", value: "050114" },
    { name: "JESUS NAZARENO", value: "050115" },
    { name: "CANGALLO", value: "050201" },
    { name: "CHUSCHI", value: "050202" },
    { name: "LOS MOROCHUCOS", value: "050203" },
    { name: "MARIA PARADO DE BELLIDO", value: "050204" },
    { name: "PARAS", value: "050205" },
    { name: "TOTOS", value: "050206" },
    { name: "SANCOS", value: "050301" },
    { name: "CARAPO", value: "050302" },
    { name: "SACSAMARCA", value: "050303" },
    { name: "SANTIAGO DE LUCANAMARCA", value: "050304" },
    { name: "HUANTA", value: "050401" },
    { name: "SAN JOSE DE USHUA", value: "050809" },
    { name: "SARA SARA", value: "050810" },
    { name: "QUEROBAMBA", value: "050901" },
    { name: "BELEN", value: "050902" },
    { name: "CHALCOS", value: "050903" },
    { name: "CHILCAYOC", value: "050904" },
    { name: "HUACAÑA", value: "050905" },
    { name: "MORCOLLA", value: "050906" },
    { name: "PAICO", value: "050907" },
    { name: "SAN PEDRO DE LARCAY", value: "050908" },
    { name: "SAN SALVADOR DE QUIJE", value: "050909" },
    { name: "SANTIAGO DE PAUCARAY", value: "050910" },
    { name: "SORAS", value: "050911" },
    { name: "HUANCAPI", value: "051001" },
    { name: "ALCAMENCA", value: "051002" },
    { name: "APONGO", value: "051003" },
    { name: "ASQUIPATA", value: "051004" },
    { name: "CANARIA", value: "051005" },
    { name: "CAYARA", value: "051006" },
    { name: "COLCA", value: "051007" },
    { name: "HUAMANQUIQUIA", value: "051008" },
    { name: "HUANCARAYLLA", value: "051009" },
    { name: "HUAYA", value: "051010" },
    { name: "SARHUA", value: "051011" },
    { name: "VILCANCHOS", value: "051012" },
    { name: "VILCAS HUAMAN", value: "051101" },
    { name: "ACCOMARCA", value: "051102" },
    { name: "CARHUANCA", value: "051103" },
    { name: "CONCEPCION", value: "051104" },
    { name: "HUAMBALPA", value: "051105" },
    { name: "INDEPENDENCIA /1", value: "051106" },
    { name: "SAURAMA", value: "051107" },
    { name: "VISCHONGO", value: "051108" },
    { name: "CAJAMARCA", value: "060101" },
    { name: "ASUNCION", value: "060102" },
    { name: "CHETILLA", value: "060103" },
    { name: "COSPAN", value: "060104" },
    { name: "ENCAÑADA", value: "060105" },
    { name: "JESUS", value: "060106" },
    { name: "LLACANORA", value: "060107" },
    { name: "LOS BAÑOS DEL INCA", value: "060108" },
    { name: "MAGDALENA", value: "060109" },
    { name: "MATARA", value: "060110" },
    { name: "NAMORA", value: "060111" },
    { name: "SAN JUAN", value: "060112" },
    { name: "CAJABAMBA", value: "060201" },
    { name: "CACHACHI", value: "060202" },
    { name: "CONDEBAMBA", value: "060203" },
    { name: "SITACOCHA", value: "060204" },
    { name: "CELENDIN", value: "060301" },
    { name: "CHUMUCH", value: "060302" },
    { name: "CORTEGANA", value: "060303" },
    { name: "HUASMIN", value: "060304" },
    { name: "JORGE CHAVEZ", value: "060305" },
    { name: "JOSE GALVEZ", value: "060306" },
    { name: "MIGUEL IGLESIAS", value: "060307" },
    { name: "OXAMARCA", value: "060308" },
    { name: "SOROCHUCO", value: "060309" },
    { name: "SUCRE", value: "060310" },
    { name: "UTCO", value: "060311" },
    { name: "LA LIBERTAD DE PALLAN", value: "060312" },
    { name: "CHOTA", value: "060401" },
    { name: "ANGUIA", value: "060402" },
    { name: "CHADIN", value: "060403" },
    { name: "CHIGUIRIP", value: "060404" },
    { name: "CHIMBAN", value: "060405" },
    { name: "CHOROPAMPA", value: "060406" },
    { name: "COCHABAMBA", value: "060407" },
    { name: "CONCHAN", value: "060408" },
    { name: "HUAMBOS", value: "060409" },
    { name: "LAJAS", value: "060410" },
    { name: "LLAMA", value: "060411" },
    { name: "MIRACOSTA", value: "060412" },
    { name: "PACCHA", value: "060413" },
    { name: "PION", value: "060414" },
    { name: "QUEROCOTO", value: "060415" },
    { name: "SAN JUAN DE LICUPIS", value: "060416" },
    { name: "TACABAMBA", value: "060417" },
    { name: "TOCMOCHE", value: "060418" },
    { name: "CHALAMARCA", value: "060419" },
    { name: "CONTUMAZA", value: "060501" },
    { name: "CHILETE", value: "060502" },
    { name: "CUPISNIQUE", value: "060503" },
    { name: "GUZMANGO", value: "060504" },
    { name: "SAN BENITO", value: "060505" },
    { name: "SANTA CRUZ DE TOLED", value: "060506" },
    { name: "TANTARICA", value: "060507" },
    { name: "YONAN", value: "060508" },
    { name: "CUTERVO", value: "060601" },
    { name: "CALLAYUC", value: "060602" },
    { name: "CHOROS", value: "060603" },
    { name: "CUJILLO", value: "060604" },
    { name: "LA RAMADA", value: "060605" },
    { name: "PIMPINGOS", value: "060606" },
    { name: "QUEROCOTILLO", value: "060607" },
    { name: "SAN ANDRES DE CUTERVO", value: "060608" },
    { name: "SAN JUAN DE CUTERVO", value: "060609" },
    { name: "SAN LUIS DE LUCMA", value: "060610" },
    { name: "SANTA CRUZ", value: "060611" },
    { name: "SANTO DOMINGO DE LA CAPILLA", value: "060612" },
    { name: "SANTO TOMAS", value: "060613" },
    { name: "SOCOTA", value: "060614" },
    { name: "TORIBIO CASANOVA", value: "060615" },
    { name: "BAMBAMARCA", value: "060701" },
    { name: "CHUGUR", value: "060702" },
    { name: "HUALGAYOC", value: "060703" },
    { name: "JAEN", value: "060801" },
    { name: "BELLAVISTA", value: "060802" },
    { name: "CHONTALI", value: "060803" },
    { name: "COLASAY", value: "060804" },
    { name: "HUABAL", value: "060805" },
    { name: "LAS PIRIAS", value: "060806" },
    { name: "POMAHUACA", value: "060807" },
    { name: "PUCARA", value: "060808" },
    { name: "SALLIQUE", value: "060809" },
    { name: "SAN FELIPE", value: "060810" },
    { name: "SAN JOSE DEL ALTO", value: "060811" },
    { name: "SANTA ROSA", value: "060812" },
    { name: "SAN IGNACIO", value: "060901" },
    { name: "CHIRINOS", value: "060902" },
    { name: "HUARANGO", value: "060903" },
    { name: "LA COIPA", value: "060904" },
    { name: "NAMBALLE", value: "060905" },
    { name: "SAN JOSE DE LOURDES", value: "060906" },
    { name: "TABACONAS", value: "060907" },
    { name: "PEDRO GALVEZ", value: "061001" },
    { name: "CHANCAY", value: "061002" },
    { name: "EDUARDO VILLANUEVA", value: "061003" },
    { name: "GREGORIO PITA", value: "061004" },
    { name: "ICHOCAN", value: "061005" },
    { name: "JOSE MANUEL QUIROZ", value: "061006" },
    { name: "JOSE SABOGAL", value: "061007" },
    { name: "SAN MIGUEL", value: "061101" },
    { name: "BOLIVAR", value: "061102" },
    { name: "CALQUIS", value: "061103" },
    { name: "CATILLUC", value: "061104" },
    { name: "EL PRADO", value: "061105" },
    { name: "LA FLORIDA", value: "061106" },
    { name: "LLAPA", value: "061107" },
    { name: "NANCHOC", value: "061108" },
    { name: "NIEPOS", value: "061109" },
    { name: "SAN GREGORIO", value: "061110" },
    { name: "SAN SILVESTRE DE COCHAN", value: "061111" },
    { name: "TONGOD", value: "061112" },
    { name: "UNION AGUA BLANCA", value: "061113" },
    { name: "SAN PABLO", value: "061201" },
    { name: "SAN BERNARDINO", value: "061202" },
    { name: "SAN LUIS", value: "061203" },
    { name: "TUMBADEN", value: "061204" },
    { name: "SANTA CRUZ", value: "061301" },
    { name: "ANDABAMBA", value: "061302" },
    { name: "CATACHE", value: "061303" },
    { name: "CHANCAYBAÑOS", value: "061304" },
    { name: "LA ESPERANZA", value: "061305" },
    { name: "NINABAMBA", value: "061306" },
    { name: "PULAN", value: "061307" },
    { name: "SAUCEPAMPA", value: "061308" },
    { name: "SEXI", value: "061309" },
    { name: "UTICYACU", value: "061310" },
    { name: "YAUYUCAN", value: "061311" },
    { name: "CALLAO", value: "070101" },
    { name: "BELLAVISTA", value: "070102" },
    { name: "CARMEN DE LA LEGUA REYNOSO", value: "070103" },
    { name: "LA PERLA", value: "070104" },
    { name: "LA PUNTA", value: "070105" },
    { name: "VENTANILLA", value: "070106" },
    { name: "CUSCO", value: "080101" },
    { name: "CCORCA", value: "080102" },
    { name: "POROY", value: "080103" },
    { name: "SAN JERONIMO", value: "080104" },
    { name: "SAN SEBASTIAN", value: "080105" },
    { name: "SANTIAGO", value: "080106" },
    { name: "SAYLLA", value: "080107" },
    { name: "WANCHAQ", value: "080108" },
    { name: "ACOMAYO", value: "080201" },
    { name: "ACOPIA", value: "080202" },
    { name: "ACOS", value: "080203" },
    { name: "MOSOC LLACTA", value: "080204" },
    { name: "POMACANCHI", value: "080205" },
    { name: "RONDOCAN", value: "080206" },
    { name: "SANGARARA", value: "080207" },
    { name: "ANTA", value: "080301" },
    { name: "ANCAHUASI", value: "080302" },
    { name: "CACHIMAYO", value: "080303" },
    { name: "CHINCHAYPUJIO", value: "080304" },
    { name: "HUAROCONDO", value: "080305" },
    { name: "LIMATAMBO", value: "080306" },
    { name: "MOLLEPATA", value: "080307" },
    { name: "PUCYURA", value: "080308" },
    { name: "ZURITE", value: "080309" },
    { name: "CALCA", value: "080401" },
    { name: "COYA", value: "080402" },
    { name: "LAMAY", value: "080403" },
    { name: "LARES", value: "080404" },
    { name: "PISAC", value: "080405" },
    { name: "SAN SALVADOR", value: "080406" },
    { name: "TARAY", value: "080407" },
    { name: "YANATILE", value: "080408" },
    { name: "YANAOCA", value: "080501" },
    { name: "CHECCA", value: "080502" },
    { name: "KUNTURKANKI", value: "080503" },
    { name: "LANGUI", value: "080504" },
    { name: "LAYO", value: "080505" },
    { name: "PAMPAMARCA", value: "080506" },
    { name: "QUEHUE", value: "080507" },
    { name: "TUPAC AMARU", value: "080508" },
    { name: "SICUANI", value: "080601" },
    { name: "CHECACUPE", value: "080602" },
    { name: "COMBAPATA", value: "080603" },
    { name: "MARANGANI", value: "080604" },
    { name: "PITUMARCA", value: "080605" },
    { name: "SAN PABLO", value: "080606" },
    { name: "SAN PEDRO", value: "080607" },
    { name: "TINTA", value: "080608" },
    { name: "SANTO TOMAS", value: "080701" },
    { name: "CAPACMARCA", value: "080702" },
    { name: "CHAMACA", value: "080703" },
    { name: "COLQUEMARCA", value: "080704" },
    { name: "LIVITACA", value: "080705" },
    { name: "LLUSCO", value: "080706" },
    { name: "QUIÑOTA", value: "080707" },
    { name: "VELILLE", value: "080708" },
    { name: "ESPINAR", value: "080801" },
    { name: "CONDOROMA", value: "080802" },
    { name: "COPORAQUE", value: "080803" },
    { name: "OCORURO", value: "080804" },
    { name: "PALLPATA", value: "080805" },
    { name: "PICHIGUA", value: "080806" },
    { name: "SUYCKUTAMBO 3/", value: "080807" },
    { name: "ALTO PICHIGUA", value: "080808" },
    { name: "SANTA ANA", value: "080901" },
    { name: "ECHARATE", value: "080902" },
    { name: "HUAYOPATA /1", value: "080903" },
    { name: "MARANURA", value: "080904" },
    { name: "OCOBAMBA /2", value: "080905" },
    { name: "QUELLOUNO", value: "080906" },
    { name: "KIMBIRI", value: "080907" },
    { name: "SANTA TERESA", value: "080908" },
    { name: "VILCABAMBA", value: "080909" },
    { name: "PICHARI", value: "080910" },
    { name: "PARURO", value: "081001" },
    { name: "ACCHA", value: "081002" },
    { name: "CCAPI", value: "081003" },
    { name: "COLCHA", value: "081004" },
    { name: "HUANOQUITE", value: "081005" },
    { name: "OMACHA", value: "081006" },
    { name: "PACCARITAMBO", value: "081007" },
    { name: "PILLPINTO", value: "081008" },
    { name: "YAURISQUE", value: "081009" },
    { name: "PAUCARTAMBO", value: "081101" },
    { name: "CAICAY", value: "081102" },
    { name: "CHALLABAMBA", value: "081103" },
    { name: "COLQUEPATA", value: "081104" },
    { name: "HUANCARANI", value: "081105" },
    { name: "KOSÑIPATA", value: "081106" },
    { name: "URCOS", value: "081201" },
    { name: "ANDAHUAYLILLAS", value: "081202" },
    { name: "CAMANTI", value: "081203" },
    { name: "CCARHUAYO", value: "081204" },
    { name: "CCATCA", value: "081205" },
    { name: "CUSIPATA", value: "081206" },
    { name: "HUARO", value: "081207" },
    { name: "LUCRE", value: "081208" },
    { name: "MARCAPATA", value: "081209" },
    { name: "OCONGATE", value: "081210" },
    { name: "OROPESA", value: "081211" },
    { name: "QUIQUIJANA", value: "081212" },
    { name: "URUBAMBA", value: "081301" },
    { name: "CHINCHERO", value: "081302" },
    { name: "HUAYLLABAMBA", value: "081303" },
    { name: "MACHUPICCHU", value: "081304" },
    { name: "MARAS", value: "081305" },
    { name: "OLLANTAYTAMBO", value: "081306" },
    { name: "YUCAY", value: "081307" },
    { name: "HUANCAVELICA", value: "090101" },
    { name: "ACOBAMBILLA", value: "090102" },
    { name: "ACORIA", value: "090103" },
    { name: "CONAYCA", value: "090104" },
    { name: "CUENCA", value: "090105" },
    { name: "HUACHOCOLPA", value: "090106" },
    { name: "HUAYLLAHUARA", value: "090107" },
    { name: "IZCUCHACA", value: "090108" },
    { name: "LARIA", value: "090109" },
    { name: "MANTA", value: "090110" },
    { name: "MARISCAL CACERES", value: "090111" },
    { name: "MOYA", value: "090112" },
    { name: "NUEVO OCCORO", value: "090113" },
    { name: "PALCA", value: "090114" },
    { name: "PILCHACA", value: "090115" },
    { name: "VILCA", value: "090116" },
    { name: "YAULI", value: "090117" },
    { name: "ASCENSION", value: "090118" },
    { name: "HUANDO", value: "090119" },
    { name: "ACOBAMBA", value: "090201" },
    { name: "ANDABAMBA", value: "090202" },
    { name: "ANTA", value: "090203" },
    { name: "CAJA", value: "090204" },
    { name: "MARCAS", value: "090205" },
    { name: "PAUCARA", value: "090206" },
    { name: "POMACOCHA", value: "090207" },
    { name: "ROSARIO", value: "090208" },
    { name: "LIRCAY", value: "090301" },
    { name: "ANCHONGA", value: "090302" },
    { name: "CALLANMARCA", value: "090303" },
    { name: "CCOCHACCASA", value: "090304" },
    { name: "CHINCHO", value: "090305" },
    { name: "CONGALLA", value: "090306" },
    { name: "HUANCA-HUANCA", value: "090307" },
    { name: "HUAYLLAY GRANDE", value: "090308" },
    { name: "JULCAMARCA", value: "090309" },
    { name: "SAN ANTONIO DE ANTAPARCO", value: "090310" },
    { name: "SANTO TOMAS DE PATA", value: "090311" },
    { name: "SECCLLA", value: "090312" },
    { name: "CASTROVIRREYNA", value: "090401" },
    { name: "ARMA", value: "090402" },
    { name: "AURAHUA", value: "090403" },
    { name: "CAPILLAS", value: "090404" },
    { name: "CHUPAMARCA", value: "090405" },
    { name: "COCAS", value: "090406" },
    { name: "HUACHOS", value: "090407" },
    { name: "HUAMATAMBO", value: "090408" },
    { name: "MOLLEPAMPA", value: "090409" },
    { name: "SAN JUAN", value: "090410" },
    { name: "SANTA ANA", value: "090411" },
    { name: "TANTARA", value: "090412" },
    { name: "TICRAPO", value: "090413" },
    { name: "CHURCAMPA", value: "090501" },
    { name: "ANCO", value: "090502" },
    { name: "CHINCHIHUASI", value: "090503" },
    { name: "EL CARMEN", value: "090504" },
    { name: "LA MERCED", value: "090505" },
    { name: "LOCROJA", value: "090506" },
    { name: "PAUCARBAMBA", value: "090507" },
    { name: "SAN MIGUEL DE MAYOCC", value: "090508" },
    { name: "SAN PEDRO DE CORIS", value: "090509" },
    { name: "PACHAMARCA", value: "090510" },
    { name: "HUAYTARA", value: "090601" },
    { name: "AYAVI", value: "090602" },
    { name: "CORDOVA", value: "090603" },
    { name: "HUAYACUNDO ARMA", value: "090604" },
    { name: "LARAMARCA", value: "090605" },
    { name: "OCOYO", value: "090606" },
    { name: "PILPICHACA", value: "090607" },
    { name: "QUERCO", value: "090608" },
    { name: "QUITO-ARMA", value: "090609" },
    { name: "SAN ANTONIO DE CUSICANCHA", value: "090610" },
    { name: "SAN FRANCISCO DE SANGAYAICO", value: "090611" },
    { name: "SAN ISIDRO", value: "090612" },
    { name: "SANTIAGO DE CHOCORVOS", value: "090613" },
    { name: "SANTIAGO DE QUIRAHUARA", value: "090614" },
    { name: "SANTO DOMINGO DE CAPILLAS", value: "090615" },
    { name: "TAMBO", value: "090616" },
    { name: "PAMPAS", value: "090701" },
    { name: "ACOSTAMBO", value: "090702" },
    { name: "ACRAQUIA", value: "090703" },
    { name: "AHUAYCHA", value: "090704" },
    { name: "COLCABAMBA", value: "090705" },
    { name: "DANIEL HERNANDEZ", value: "090706" },
    { name: "HUACHOCOLPA", value: "090707" },
    { name: "HUARIBAMBA", value: "090709" },
    { name: "ÑAHUIMPUQUIO", value: "090710" },
    { name: "PAZOS", value: "090711" },
    { name: "QUISHUAR", value: "090713" },
    { name: "SALCABAMBA", value: "090714" },
    { name: "SALCAHUASI", value: "090715" },
    { name: "SAN MARCOS DE ROCCHAC", value: "090716" },
    { name: "SURCUBAMBA", value: "090717" },
    { name: "TINTAY PUNCU", value: "090718" },
    { name: "HUANUCO", value: "100101" },
    { name: "AMARILIS", value: "100102" },
    { name: "CHINCHAO", value: "100103" },
    { name: "CHURUBAMBA", value: "100104" },
    { name: "MARGOS", value: "100105" },
    { name: "QUISQUI", value: "100106" },
    { name: "SAN FRANCISCO DE CAYRAN", value: "100107" },
    { name: "SAN PEDRO DE CHAULAN", value: "100108" },
    { name: "SANTA MARIA DEL VALLE", value: "100109" },
    { name: "YARUMAYO", value: "100110" },
    { name: "PILLCO MARCA", value: "100111" },
    { name: "AMBO", value: "100201" },
    { name: "CAYNA", value: "100202" },
    { name: "COLPAS", value: "100203" },
    { name: "CONCHAMARCA", value: "100204" },
    { name: "HUACAR", value: "100205" },
    { name: "SAN FRANCISCO", value: "100206" },
    { name: "SAN RAFAEL", value: "100207" },
    { name: "TOMAY KICHWA", value: "100208" },
    { name: "LA UNION", value: "100301" },
    { name: "CHUQUIS", value: "100307" },
    { name: "MARIAS", value: "100311" },
    { name: "PACHAS", value: "100313" },
    { name: "QUIVILLA", value: "100316" },
    { name: "RIPAN", value: "100317" },
    { name: "SHUNQUI", value: "100321" },
    { name: "SILLAPATA", value: "100322" },
    { name: "YANAS", value: "100323" },
    { name: "HUACAYBAMBA", value: "100401" },
    { name: "CANCHABAMBA", value: "100402" },
    { name: "COCHABAMBA", value: "100403" },
    { name: "PINRA", value: "100404" },
    { name: "LLATA", value: "100501" },
    { name: "ARANCAY", value: "100502" },
    { name: "CHAVIN DE PARIARCA", value: "100503" },
    { name: "JACAS GRANDE", value: "100504" },
    { name: "JIRCAN", value: "100505" },
    { name: "MIRAFLORES", value: "100506" },
    { name: "MONZON", value: "100507" },
    { name: "PUNCHAO", value: "100508" },
    { name: "PUÑOS", value: "100509" },
    { name: "SINGA", value: "100510" },
    { name: "TANTAMAYO", value: "100511" },
    { name: "RUPA-RUPA", value: "100601" },
    { name: "DANIEL ALOMIA ROBLES", value: "100602" },
    { name: "HERMILIO VALDIZAN", value: "100603" },
    { name: "JOSE CRESPO Y CASTILLO", value: "100604" },
    { name: "LUYANDO 1/", value: "100605" },
    { name: "MARIANO DAMASO BERAUN", value: "100606" },
    { name: "HUACRACHUCO", value: "100701" },
    { name: "CHOLON", value: "100702" },
    { name: "SAN BUENAVENTURA", value: "100703" },
    { name: "PANAO", value: "100801" },
    { name: "CHAGLLA", value: "100802" },
    { name: "MOLINO", value: "100803" },
    { name: "UMARI", value: "100804" },
    { name: "PUERTO INCA", value: "100901" },
    { name: "CODO DEL POZUZO", value: "100902" },
    { name: "HONORIA", value: "100903" },
    { name: "TOURNAVISTA", value: "100904" },
    { name: "YUYAPICHIS", value: "100905" },
    { name: "JESUS", value: "101001" },
    { name: "BAÑOS", value: "101002" },
    { name: "JIVIA", value: "101003" },
    { name: "QUEROPALCA", value: "101004" },
    { name: "RONDOS", value: "101005" },
    { name: "SAN FRANCISCO DE ASIS", value: "101006" },
    { name: "SAN MIGUEL DE CAURI", value: "101007" },
    { name: "CHAVINILLO", value: "101101" },
    { name: "CAHUAC", value: "101102" },
    { name: "CHACABAMBA", value: "101103" },
    { name: "APARICIO POMARES", value: "101104" },
    { name: "JACAS CHICO", value: "101105" },
    { name: "OBAS", value: "101106" },
    { name: "PAMPAMARCA", value: "101107" },
    { name: "CHORAS", value: "101108" },
    { name: "ICA", value: "110101" },
    { name: "LA TINGUIÑA", value: "110102" },
    { name: "LOS AQUIJES", value: "110103" },
    { name: "OCUCAJE", value: "110104" },
    { name: "PACHACUTEC", value: "110105" },
    { name: "PARCONA", value: "110106" },
    // tslint:disable-next-line:no-duplicate-string
    { name: "PUEBLO NUEVO", value: "110107" },
    { name: "SALAS", value: "110108" },
    { name: "SAN JOSE DE LOS MOLINOS", value: "110109" },
    { name: "SAN JUAN BAUTISTA", value: "110110" },
    { name: "SANTIAGO", value: "110111" },
    { name: "SUBTANJALLA", value: "110112" },
    { name: "TATE", value: "110113" },
    { name: "YAUCA DEL ROSARIO 1/", value: "110114" },
    { name: "CHINCHA ALTA", value: "110201" },
    { name: "ALTO LARAN", value: "110202" },
    { name: "CHAVIN", value: "110203" },
    { name: "CHINCHA BAJA", value: "110204" },
    { name: "EL CARMEN", value: "110205" },
    { name: "GROCIO PRADO", value: "110206" },
    { name: "PUEBLO NUEVO", value: "110207" },
    { name: "SAN JUAN DE YANAC", value: "110208" },
    { name: "SAN PEDRO DE HUACARPANA", value: "110209" },
    { name: "SUNAMPE", value: "110210" },
    { name: "TAMBO DE MORA", value: "110211" },
    { name: "NAZCA", value: "110301" },
    { name: "CHANGUILLO", value: "110302" },
    { name: "EL INGENIO", value: "110303" },
    { name: "MARCONA", value: "110304" },
    { name: "VISTA ALEGRE", value: "110305" },
    { name: "PALPA", value: "110401" },
    { name: "LLIPATA", value: "110402" },
    { name: "RIO GRANDE", value: "110403" },
    { name: "SANTA CRUZ", value: "110404" },
    { name: "TIBILLO", value: "110405" },
    { name: "PISCO", value: "110501" },
    { name: "HUANCANO", value: "110502" },
    { name: "HUMAY", value: "110503" },
    { name: "INDEPENDENCIA", value: "110504" },
    { name: "PARACAS", value: "110505" },
    { name: "SAN ANDRES", value: "110506" },
    { name: "SAN CLEMENTE", value: "110507" },
    { name: "TUPAC AMARU INCA", value: "110508" },
    { name: "HUANCAYO", value: "120101" },
    { name: "CARHUACALLANGA", value: "120104" },
    { name: "CHACAPAMPA", value: "120105" },
    { name: "CHICCHE", value: "120106" },
    { name: "CHILCA", value: "120107" },
    { name: "CHUPURO", value: "120111" },
    { name: "CHONGOS ALTO", value: "120108" },
    { name: "COLCA", value: "120112" },
    { name: "CULLHUAS", value: "120113" },
    { name: "EL TAMBO", value: "120114" },
    { name: "HUACRAPUQUIO", value: "120116" },
    { name: "HUALHUAS", value: "120117" },
    { name: "HUANCAN", value: "120119" },
    { name: "HUASICANCHA", value: "120120" },
    { name: "HUAYUCACHI", value: "120121" },
    { name: "INGENIO", value: "120122" },
    { name: "PARIAHUANCA 1/", value: "120124" },
    { name: "PILCOMAYO", value: "120125" },
    { name: "PUCARA", value: "120126" },
    { name: "QUICHUAY", value: "120127" },
    { name: "QUILCAS", value: "120128" },
    { name: "SAN AGUSTIN", value: "120129" },
    { name: "SAN JERONIMO DE TUNAN", value: "120130" },
    { name: "SAÑO", value: "120132" },
    { name: "SAPALLANGA", value: "120133" },
    { name: "SICAYA", value: "120134" },
    { name: "SANTO DOMINGO DE ACOBAMBA", value: "120135" },
    { name: "VIQUES", value: "120136" },
    { name: "CONCEPCION", value: "120201" },
    { name: "ACO", value: "120202" },
    { name: "ANDAMARCA", value: "120203" },
    { name: "CHAMBARA", value: "120204" },
    { name: "COCHAS", value: "120205" },
    { name: "COMAS", value: "120206" },
    { name: "HEROINAS TOLEDO", value: "120207" },
    { name: "MANZANARES", value: "120208" },
    { name: "MARISCAL CASTILLA", value: "120209" },
    { name: "MATAHUASI", value: "120210" },
    { name: "MITO", value: "120211" },
    { name: "NUEVE DE JULIO", value: "120212" },
    { name: "ORCOTUNA", value: "120213" },
    { name: "SAN JOSE DE QUERO", value: "120214" },
    { name: "SANTA ROSA DE OCOPA", value: "120215" },
    { name: "CHANCHAMAYO", value: "120301" },
    { name: "PERENE", value: "120302" },
    { name: "PICHANAQUI", value: "120303" },
    { name: "SAN LUIS DE SHUARO", value: "120304" },
    { name: "SAN RAMON", value: "120305" },
    { name: "VITOC", value: "120306" },
    { name: "JAUJA", value: "120401" },
    { name: "ACOLLA", value: "120402" },
    { name: "APATA", value: "120403" },
    { name: "ATAURA", value: "120404" },
    { name: "CANCHAYLLO", value: "120405" },
    { name: "CURICACA", value: "120406" },
    { name: "EL MANTARO", value: "120407" },
    { name: "HUAMALI", value: "120408" },
    { name: "HUARIPAMPA", value: "120409" },
    { name: "HUERTAS", value: "120410" },
    { name: "JANJAILLO", value: "120411" },
    { name: "JULCAN", value: "120412" },
    { name: "LEONOR ORDOÑEZ", value: "120413" },
    { name: "LLOCLLAPAMPA", value: "120414" },
    { name: "MARCO", value: "120415" },
    { name: "MASMA", value: "120416" },
    { name: "MASMA CHICCHE", value: "120417" },
    { name: "MOLINOS", value: "120418" },
    { name: "MONOBAMBA", value: "120419" },
    { name: "MUQUI", value: "120420" },
    { name: "MUQUIYAUYO", value: "120421" },
    { name: "PACA", value: "120422" },
    { name: "PACCHA", value: "120423" },
    { name: "PANCAN", value: "120424" },
    { name: "PARCO", value: "120425" },
    { name: "POMACANCHA", value: "120426" },
    { name: "RICRAN", value: "120427" },
    { name: "SAN LORENZO", value: "120428" },
    { name: "SAN PEDRO DE CHUNAN", value: "120429" },
    { name: "SAUSA", value: "120430" },
    { name: "SINCOS", value: "120431" },
    { name: "TUNAN MARCA", value: "120432" },
    { name: "YAULI", value: "120433" },
    { name: "YAUYOS", value: "120434" },
    { name: "JUNIN", value: "120501" },
    { name: "CARHUAMAYO", value: "120502" },
    { name: "ONDORES", value: "120503" },
    { name: "ULCUMAYO", value: "120504" },
    { name: "SATIPO", value: "120601" },
    { name: "COVIRIALI", value: "120602" },
    { name: "LLAYLLA", value: "120603" },
    { name: "MAZAMARI", value: "120604" },
    { name: "PAMPA HERMOSA", value: "120605" },
    { name: "PANGOA", value: "120606" },
    { name: "RIO NEGRO", value: "120607" },
    { name: "RIO TAMBO", value: "120608" },
    { name: "TARMA", value: "120701" },
    { name: "ACOBAMBA", value: "120702" },
    { name: "HUARICOLCA", value: "120703" },
    { name: "HUASAHUASI", value: "120704" },
    { name: "LA UNION", value: "120705" },
    { name: "PALCA", value: "120706" },
    { name: "PALCAMAYO", value: "120707" },
    { name: "SAN PEDRO DE CAJAS", value: "120708" },
    { name: "TAPO", value: "120709" },
    { name: "LA OROYA", value: "120801" },
    { name: "CHACAPALPA", value: "120802" },
    { name: "HUAY-HUAY", value: "120803" },
    { name: "MARCAPOMACOCHA", value: "120804" },
    { name: "MOROCOCHA", value: "120805" },
    { name: "PACCHA", value: "120806" },
    { name: "SANTA BARBARA DE CARHUACAYAN", value: "120807" },
    { name: "SANTA ROSA DE SACCO", value: "120808" },
    { name: "SUITUCANCHA", value: "120809" },
    { name: "YAULI", value: "120810" },
    { name: "CHUPACA", value: "120901" },
    { name: "AHUAC", value: "120902" },
    { name: "CHONGOS BAJO", value: "120903" },
    { name: "HUACHAC", value: "120904" },
    { name: "HUAMANCACA CHICO", value: "120905" },
    { name: "SAN JUAN DE ISCOS", value: "120906" },
    { name: "SAN JUAN DE JARPA", value: "120907" },
    { name: "TRES DE DICIEMBRE", value: "120908" },
    { name: "YANACANCHA", value: "120909" },
    { name: "TRUJILLO", value: "130101" },
    { name: "EL PORVENIR", value: "130102" },
    { name: "FLORENCIA DE MORA", value: "130103" },
    { name: "HUANCHACO", value: "130104" },
    { name: "LA ESPERANZA", value: "130105" },
    { name: "LAREDO", value: "130106" },
    { name: "MOCHE", value: "130107" },
    { name: "POROTO", value: "130108" },
    { name: "SALAVERRY", value: "130109" },
    { name: "SIMBAL", value: "130110" },
    { name: "VICTOR LARCO HERRERA", value: "130111" },
    { name: "ASCOPE", value: "130201" },
    { name: "CHICAMA", value: "130202" },
    { name: "CHOCOPE", value: "130203" },
    { name: "MAGDALENA DE CAO", value: "130204" },
    { name: "PAIJAN", value: "130205" },
    { name: "RAZURI", value: "130206" },
    { name: "SANTIAGO DE CAO", value: "130207" },
    { name: "CASA GRANDE", value: "130208" },
    { name: "BOLIVAR", value: "130301" },
    { name: "BAMBAMARCA", value: "130302" },
    { name: "CONDORMARCA /1", value: "130303" },
    { name: "LONGOTEA", value: "130304" },
    { name: "UCHUMARCA", value: "130305" },
    { name: "UCUNCHA", value: "130306" },
    { name: "CHEPEN", value: "130401" },
    { name: "PACANGA", value: "130402" },
    { name: "PUEBLO NUEVO", value: "130403" },
    { name: "JULCAN", value: "130501" },
    { name: "CALAMARCA", value: "130502" },
    { name: "CARABAMBA", value: "130503" },
    { name: "HUASO", value: "130504" },
    { name: "OTUZCO", value: "130601" },
    { name: "AGALLPAMPA", value: "130602" },
    { name: "CHARAT", value: "130604" },
    { name: "HUARANCHAL", value: "130605" },
    { name: "LA CUESTA", value: "130606" },
    { name: "ACHE", value: "130608" },
    { name: "PARANDAY", value: "130610" },
    { name: "SALPO", value: "130611" },
    { name: "SINSICAP", value: "130613" },
    { name: "USQUIL", value: "130614" },
    { name: "SAN PEDRO DE LLOC", value: "130701" },
    { name: "GUADALUPE", value: "130702" },
    { name: "JEQUETEPEQUE", value: "130703" },
    { name: "PACASMAYO", value: "130704" },
    { name: "SAN JOSE", value: "130705" },
    { name: "TAYABAMBA", value: "130801" },
    { name: "BULDIBUYO", value: "130802" },
    { name: "CHILLIA", value: "130803" },
    { name: "HUANCASPATA", value: "130804" },
    { name: "HUAYLILLAS", value: "130805" },
    { name: "HUAYO", value: "130806" },
    { name: "ONGON", value: "130807" },
    { name: "PARCOY", value: "130808" },
    { name: "PATAZ", value: "130809" },
    { name: "PIAS", value: "130810" },
    { name: "SANTIAGO DE CHALLAS", value: "130811" },
    { name: "TAURIJA", value: "130812" },
    { name: "URPAY", value: "130813" },
    { name: "HUAMACHUCO", value: "130901" },
    { name: "CHUGAY", value: "130902" },
    { name: "COCHORCO", value: "130903" },
    { name: "CURGOS", value: "130904" },
    { name: "MARCABAL", value: "130905" },
    { name: "SANAGORAN", value: "130906" },
    { name: "SARIN", value: "130907" },
    { name: "SARTIMBAMBA", value: "130908" },
    { name: "SANTIAGO DE CHUCO", value: "131001" },
    { name: "ANGASMARCA", value: "131002" },
    { name: "CACHICADAN", value: "131003" },
    { name: "MOLLEBAMBA", value: "131004" },
    { name: "MOLLEPATA", value: "131005" },
    { name: "QUIRUVILCA", value: "131006" },
    { name: "SANTA CRUZ DE CHUCA", value: "131007" },
    { name: "SITABAMBA", value: "131008" },
    { name: "CASCAS", value: "131101" },
    { name: "LUCMA", value: "131102" },
    { name: "COMPIN", value: "131103" },
    { name: "SAYAPULLO", value: "131104" },
    { name: "VIRU", value: "131201" },
    { name: "CHAO", value: "131202" },
    { name: "GUADALUPITO", value: "131203" },
    { name: "CHICLAYO", value: "140101" },
    { name: "CHONGOYAPE", value: "140102" },
    { name: "ETEN", value: "140103" },
    { name: "ETEN PUERTO", value: "140104" },
    { name: "JOSE LEONARDO ORTIZ", value: "140105" },
    { name: "LA VICTORIA", value: "140106" },
    { name: "LAGUNAS", value: "140107" },
    { name: "MONSEFU", value: "140108" },
    { name: "NUEVA ARICA", value: "140109" },
    { name: "OYOTUN", value: "140110" },
    { name: "PICSI", value: "140111" },
    { name: "PIMENTEL", value: "140112" },
    { name: "REQUE", value: "140113" },
    { name: "SANTA ROSA", value: "140114" },
    { name: "SAÑA", value: "140115" },
    { name: "CAYALTI", value: "140116" },
    { name: "PATAPO", value: "140117" },
    { name: "POMALCA", value: "140118" },
    { name: "PUCALA", value: "140119" },
    { name: "TUMAN", value: "140120" },
    { name: "FERREÑAFE", value: "140201" },
    { name: "CAÑARIS", value: "140202" },
    { name: "INCAHUASI", value: "140203" },
    { name: "MANUEL ANTONIO MESONES MURO", value: "140204" },
    { name: "PITIPO", value: "140205" },
    { name: "PUEBLO NUEVO", value: "140206" },
    { name: "LAMBAYEQUE", value: "140301" },
    { name: "CHOCHOPE", value: "140302" },
    { name: "ILLIMO", value: "140303" },
    { name: "JAYANCA", value: "140304" },
    { name: "MOCHUMI", value: "140305" },
    { name: "MORROPE", value: "140306" },
    { name: "MOTUPE", value: "140307" },
    { name: "OLMOS", value: "140308" },
    { name: "PACORA", value: "140309" },
    { name: "SALAS", value: "140310" },
    { name: "SAN JOSE", value: "140311" },
    { name: "TUCUME", value: "140312" },
    { name: "LIMA", value: "150101" },
    { name: "ANCON", value: "150102" },
    { name: "ATE", value: "150103" },
    { name: "BARRANCO", value: "150104" },
    { name: "BREÑA", value: "150105" },
    { name: "CARABAYLLO", value: "150106" },
    { name: "CHACLACAYO", value: "150107" },
    { name: "CHORRILLOS", value: "150108" },
    { name: "CIENEGUILLA", value: "150109" },
    { name: "COMAS", value: "150110" },
    { name: "EL AGUSTINO", value: "150111" },
    { name: "INDEPENDENCIA", value: "150112" },
    { name: "JESUS MARIA", value: "150113" },
    { name: "LA MOLINA", value: "150114" },
    { name: "LA VICTORIA", value: "150115" },
    { name: "LINCE", value: "150116" },
    { name: "LOS OLIVOS", value: "150117" },
    { name: "LURIGANCHO", value: "150118" },
    { name: "LURIN", value: "150119" },
    { name: "MAGDALENA DEL MAR", value: "150120" },
    { name: "PUEBLO LIBRE", value: "150121" },
    { name: "MIRAFLORES", value: "150122" },
    { name: "PACHACAMAC", value: "150123" },
    { name: "PUCUSANA", value: "150124" },
    { name: "PUENTE PIEDRA", value: "150125" },
    { name: "PUNTA HERMOSA", value: "150126" },
    { name: "PUNTA NEGRA", value: "150127" },
    { name: "RIMAC", value: "150128" },
    { name: "SAN BARTOLO", value: "150129" },
    { name: "SAN BORJA", value: "150130" },
    { name: "SAN ISIDRO", value: "150131" },
    { name: "SAN JUAN DE LURIGANCHO", value: "150132" },
    { name: "SAN JUAN DE MIRAFLORES", value: "150133" },
    { name: "SAN LUIS", value: "150134" },
    { name: "SAN MARTIN DE PORRES", value: "150135" },
    { name: "SAN MIGUEL", value: "150136" },
    { name: "SANTA ANITA", value: "150137" },
    { name: "SANTA MARIA DEL MAR", value: "150138" },
    { name: "SANTA ROSA", value: "150139" },
    { name: "SANTIAGO DE SURCO", value: "150140" },
    { name: "SURQUILLO", value: "150141" },
    { name: "VILLA EL SALVADOR", value: "150142" },
    { name: "VILLA MARIA DEL TRIUNFO", value: "150143" },
    { name: "BARRANCA", value: "150201" },
    { name: "PARAMONGA", value: "150202" },
    { name: "PATIVILCA", value: "150203" },
    { name: "SUPE", value: "150204" },
    { name: "SUPE PUERTO", value: "150205" },
    { name: "CAJATAMBO", value: "150301" },
    { name: "COPA", value: "150302" },
    { name: "GORGOR", value: "150303" },
    { name: "HUANCAPON", value: "150304" },
    { name: "MANAS", value: "150305" },
    { name: "CANTA", value: "150401" },
    { name: "ARAHUAY", value: "150402" },
    { name: "HUAMANTANGA", value: "150403" },
    { name: "HUAROS", value: "150404" },
    { name: "LACHAQUI", value: "150405" },
    { name: "SAN BUENAVENTURA", value: "150406" },
    { name: "SANTA ROSA DE QUIVES", value: "150407" },
    { name: "SAN VICENTE DE CAÑETE", value: "150501" },
    { name: "ASIA", value: "150502" },
    { name: "CALANGO", value: "150503" },
    { name: "CERRO AZUL", value: "150504" },
    { name: "CHILCA", value: "150505" },
    { name: "COAYLLO", value: "150506" },
    { name: "IMPERIAL", value: "150507" },
    { name: "LUNAHUANA", value: "150508" },
    { name: "MALA", value: "150509" },
    { name: "NUEVO IMPERIAL", value: "150510" },
    { name: "PACARAN", value: "150511" },
    { name: "QUILMANA", value: "150512" },
    { name: "SAN ANTONIO", value: "150513" },
    { name: "SAN LUIS", value: "150514" },
    { name: "SANTA CRUZ DE FLORES", value: "150515" },
    { name: "ZUÑIGA", value: "150516" },
    { name: "HUARAL", value: "150601" },
    { name: "ATAVILLOS ALTO", value: "150602" },
    { name: "ATAVILLOS BAJO", value: "150603" },
    { name: "AUCALLAMA", value: "150604" },
    { name: "CHANCAY", value: "150605" },
    { name: "IHUARI", value: "150606" },
    { name: "LAMPIAN", value: "150607" },
    { name: "PACARAOS", value: "150608" },
    { name: "SAN MIGUEL DE ACOS", value: "150609" },
    { name: "SANTA CRUZ DE ANDAMARCA", value: "150610" },
    { name: "SUMBILCA", value: "150611" },
    { name: "VEINTISIETE DE NOVIEMBRE", value: "150612" },
    { name: "MATUCANA", value: "150701" },
    { name: "ANTIOQUIA", value: "150702" },
    { name: "CALLAHUANCA", value: "150703" },
    { name: "CARAMPOMA", value: "150704" },
    { name: "CHICLA", value: "150705" },
    { name: "CUENCA", value: "150706" },
    { name: "HUACHUPAMPA", value: "150707" },
    { name: "HUANZA", value: "150708" },
    { name: "HUAROCHIRI", value: "150709" },
    { name: "LAHUAYTAMBO", value: "150710" },
    { name: "LANGA", value: "150711" },
    { name: "LARAOS", value: "150712" },
    { name: "MARIATANA", value: "150713" },
    { name: "RICARDO PALMA", value: "150714" },
    { name: "SAN ANDRES DE TUPICOCHA", value: "150715" },
    { name: "SAN ANTONIO", value: "150716" },
    { name: "SAN BARTOLOME", value: "150717" },
    { name: "SAN DAMIAN", value: "150718" },
    { name: "SAN JUAN DE IRIS", value: "150719" },
    { name: "SAN JUAN DE TANTARANCHE", value: "150720" },
    { name: "SAN LORENZO DE QUINTI", value: "150721" },
    { name: "SAN MATEO", value: "150722" },
    { name: "SAN MATEO DE OTAO", value: "150723" },
    { name: "SAN PEDRO DE CASTA", value: "150724" },
    { name: "SAN PEDRO DE HUANCAYRE", value: "150725" },
    { name: "SANGALLAYA", value: "150726" },
    { name: "SANTA CRUZ DE COCACHACRA", value: "150727" },
    { name: "SANTA EULALIA", value: "150728" },
    { name: "SANTIAGO DE ANCHUCAYA", value: "150729" },
    { name: "SANTIAGO DE TUNA", value: "150730" },
    { name: "SANTO DOMINGO DE LOS OLLEROS", value: "150731" },
    { name: "SURCO", value: "150732" },
    { name: "HUACHO", value: "150801" },
    { name: "AMBAR", value: "150802" },
    { name: "CALETA DE CARQUIN", value: "150803" },
    { name: "CHECRAS", value: "150804" },
    { name: "HUALMAY", value: "150805" },
    { name: "HUAURA", value: "150806" },
    { name: "LEONCIO PRADO", value: "150807" },
    { name: "PACCHO", value: "150808" },
    { name: "SANTA LEONOR", value: "150809" },
    { name: "SANTA MARIA", value: "150810" },
    { name: "SAYAN", value: "150811" },
    { name: "VEGUETA", value: "150812" },
    { name: "OYON", value: "150901" },
    { name: "ANDAJES", value: "150902" },
    { name: "CAUJUL", value: "150903" },
    { name: "COCHAMARCA", value: "150904" },
    { name: "NAVAN", value: "150905" },
    { name: "PACHANGARA", value: "150906" },
    { name: "YAUYOS", value: "151001" },
    { name: "ALIS", value: "151002" },
    { name: "ALLAUCA", value: "151003" },
    { name: "AYAVIRI", value: "151004" },
    { name: "AZANGARO", value: "151005" },
    { name: "CACRA", value: "151006" },
    { name: "CARANIA", value: "151007" },
    { name: "CATAHUASI", value: "151008" },
    { name: "CHOCOS", value: "151009" },
    { name: "COCHAS", value: "151010" },
    { name: "COLONIA", value: "151011" },
    { name: "HONGOS", value: "151012" },
    { name: "HUAMPARA", value: "151013" },
    { name: "HUANCAYA", value: "151014" },
    { name: "HUANGASCAR", value: "151015" },
    { name: "HUANTAN", value: "151016" },
    { name: "HUAÑEC", value: "151017" },
    { name: "LARAOS", value: "151018" },
    { name: "LINCHA", value: "151019" },
    { name: "MADEAN", value: "151020" },
    { name: "MIRAFLORES", value: "151021" },
    { name: "OMAS", value: "151022" },
    { name: "PUTINZA", value: "151023" },
    { name: "QUINCHES", value: "151024" },
    { name: "QUINOCAY", value: "151025" },
    { name: "SAN JOAQUIN", value: "151026" },
    { name: "SAN PEDRO DE PILAS", value: "151027" },
    { name: "TANTA", value: "151028" },
    { name: "TAURIPAMPA", value: "151029" },
    { name: "TOMAS", value: "151030" },
    { name: "TUPE", value: "151031" },
    { name: "VIÑAC", value: "151032" },
    { name: "VITIS", value: "151033" },
    { name: "IQUITOS", value: "160101" },
    { name: "ALTO NANAY", value: "160102" },
    { name: "FERNANDO LORES", value: "160103" },
    { name: "INDIANA", value: "160104" },
    { name: "LAS AMAZONAS", value: "160105" },
    { name: "MAZAN", value: "160106" },
    { name: "NAPO", value: "160107" },
    { name: "PUNCHANA", value: "160108" },
    { name: "PUTUMAYO", value: "160109" },
    { name: "TORRES CAUSANA", value: "160110" },
    { name: "BELEN", value: "160112" },
    { name: "SAN JUAN BAUTISTA", value: "160113" },
    { name: "TENIENTE MANUEL CLAVERO", value: "160114" },
    { name: "YURIMAGUAS", value: "160201" },
    { name: "BALSAPUERTO", value: "160202" },
    { name: "JEBEROS", value: "160205" },
    { name: "LAGUNAS", value: "160206" },
    { name: "SANTA CRUZ", value: "160210" },
    { name: "TENIENTE CESAR LOPEZ ROJAS", value: "160211" },
    { name: "NAUTA", value: "160301" },
    { name: "PARINARI", value: "160302" },
    { name: "TIGRE", value: "160303" },
    { name: "TROMPETEROS", value: "160304" },
    { name: "URARINAS", value: "160305" },
    { name: "RAMON CASTILLA", value: "160401" },
    { name: "PEBAS", value: "160402" },
    { name: "YAVARI /1", value: "160403" },
    { name: "SAN PABLO", value: "160404" },
    { name: "REQUENA", value: "160501" },
    { name: "ALTO TAPICHE", value: "160502" },
    { name: "CAPELO", value: "160503" },
    { name: "EMILIO SAN MARTIN", value: "160504" },
    { name: "MAQUIA", value: "160505" },
    { name: "PUINAHUA", value: "160506" },
    { name: "SAQUENA", value: "160507" },
    { name: "SOPLIN", value: "160508" },
    { name: "TAPICHE", value: "160509" },
    { name: "JENARO HERRERA", value: "160510" },
    { name: "YAQUERANA", value: "160511" },
    { name: "CONTAMANA", value: "160601" },
    { name: "INAHUAYA", value: "160602" },
    { name: "PADRE MARQUEZ", value: "160603" },
    { name: "PAMPA HERMOSA", value: "160604" },
    { name: "SARAYACU", value: "160605" },
    { name: "VARGAS GUERRA", value: "160606" },
    { name: "BARRANCA", value: "160701" },
    { name: "CAHUAPANAS", value: "160702" },
    { name: "MANSERICHE", value: "160703" },
    { name: "MORONA", value: "160704" },
    { name: "PASTAZA", value: "160705" },
    { name: "ANDOAS", value: "160706" },
    { name: "TAMBOPATA", value: "170101" },
    { name: "INAMBARI", value: "170102" },
    { name: "LAS PIEDRAS", value: "170103" },
    { name: "LABERINTO", value: "170104" },
    { name: "MANU", value: "170201" },
    { name: "FITZCARRALD", value: "170202" },
    { name: "MADRE DE DIOS", value: "170203" },
    { name: "HUEPETUHE", value: "170204" },
    { name: "IÑAPARI", value: "170301" },
    { name: "IBERIA", value: "170302" },
    { name: "TAHUAMANU", value: "170303" },
    { name: "MOQUEGUA", value: "180101" },
    { name: "CARUMAS", value: "180102" },
    { name: "CUCHUMBAYA", value: "180103" },
    { name: "SAMEGUA", value: "180104" },
    { name: "SAN CRISTOBAL", value: "180105" },
    { name: "TORATA", value: "180106" },
    { name: "OMATE", value: "180201" },
    { name: "CHOJATA", value: "180202" },
    { name: "COALAQUE", value: "180203" },
    { name: "ICHUÑA", value: "180204" },
    { name: "LA CAPILLA", value: "180205" },
    { name: "LLOQUE", value: "180206" },
    { name: "MATALAQUE", value: "180207" },
    { name: "PUQUINA", value: "180208" },
    { name: "QUINISTAQUILLAS", value: "180209" },
    { name: "UBINAS", value: "180210" },
    { name: "YUNGA", value: "180211" },
    { name: "ILO", value: "180301" },
    { name: "EL ALGARROBAL", value: "180302" },
    { name: "PACOCHA", value: "180303" },
    { name: "CHAUPIMARCA", value: "190101" },
    { name: "HUACHON", value: "190102" },
    { name: "HUARIACA", value: "190103" },
    { name: "HUAYLLAY", value: "190104" },
    { name: "NINACACA", value: "190105" },
    { name: "PALLANCHACRA", value: "190106" },
    { name: "PAUCARTAMBO", value: "190107" },
    { name: "SAN FRANCISCO DE ASIS DE YARUSYACAN", value: "190108" },
    { name: "SIMON BOLIVAR", value: "190109" },
    { name: "TICLACAYAN", value: "190110" },
    { name: "TINYAHUARCO", value: "190111" },
    { name: "VICCO", value: "190112" },
    { name: "YANACANCHA", value: "190113" },
    { name: "ANAHUANCA", value: "190201" },
    { name: "CHACAYAN", value: "190202" },
    { name: "GOYLLARISQUIZGA", value: "190203" },
    { name: "PAUCAR", value: "190204" },
    { name: "SAN PEDRO DE PILLAO", value: "190205" },
    { name: "SANTA ANA DE TUSI", value: "190206" },
    { name: "TAPUC", value: "190207" },
    { name: "VILCABAMBA", value: "190208" },
    { name: "OXAPAMPA", value: "190301" },
    { name: "CHONTABAMBA", value: "190302" },
    { name: "HUANCABAMBA", value: "190303" },
    { name: "PALCAZU", value: "190304" },
    { name: "POZUZO", value: "190305" },
    { name: "PUERTO BERMUDEZ", value: "190306" },
    { name: "VILLA RICA", value: "190307" },
    { name: "PIURA", value: "200101" },
    { name: "CASTILLA", value: "200104" },
    { name: "CATACAOS", value: "200105" },
    { name: "CURA MORI", value: "200107" },
    { name: "EL TALLAN", value: "200108" },
    { name: "LA ARENA", value: "200109" },
    { name: "LA UNION", value: "200110" },
    { name: "LAS LOMAS", value: "200111" },
    { name: "TAMBO GRANDE", value: "200114" },
    { name: "AYABACA", value: "200201" },
    { name: "FRIAS", value: "200202" },
    { name: "JILILI", value: "200203" },
    { name: "LAGUNAS", value: "200204" },
    { name: "MONTERO", value: "200205" },
    { name: "PACAIPAMPA", value: "200206" },
    { name: "PAIMAS", value: "200207" },
    { name: "SAPILLICA", value: "200208" },
    { name: "SICCHEZ", value: "200209" },
    { name: "SUYO", value: "200210" },
    { name: "HUANCABAMBA", value: "200301" },
    { name: "CANCHAQUE", value: "200302" },
    { name: "EL CARMEN DE LA FRONTERA", value: "200303" },
    { name: "HUARMACA", value: "200304" },
    { name: "LALAQUIZ", value: "200305" },
    { name: "SAN MIGUEL DE EL FAIQUE", value: "200306" },
    { name: "SONDOR", value: "200307" },
    { name: "SONDORILLO", value: "200308" },
    { name: "CHULUCANAS", value: "200401" },
    { name: "BUENOS AIRES", value: "200402" },
    { name: "CHALACO", value: "200403" },
    { name: "LA MATANZA", value: "200404" },
    { name: "MORROPON", value: "200405" },
    { name: "SALITRAL", value: "200406" },
    { name: "SAN JUAN DE BIGOTE", value: "200407" },
    { name: "SANTA CATALINA DE MOSSA", value: "200408" },
    { name: "SANTO DOMINGO", value: "200409" },
    { name: "YAMANGO", value: "200410" },
    { name: "PAITA", value: "200501" },
    { name: "AMOTAPE", value: "200502" },
    { name: "ARENAL", value: "200503" },
    { name: "COLAN", value: "200504" },
    { name: "LA HUACA", value: "200505" },
    { name: "TAMARINDO", value: "200506" },
    { name: "VICHAYAL", value: "200507" },
    { name: "SULLANA", value: "200601" },
    { name: "BELLAVISTA", value: "200602" },
    { name: "IGNACIO ESCUDERO", value: "200603" },
    { name: "LANCONES", value: "200604" },
    { name: "MARCAVELICA", value: "200605" },
    { name: "MIGUEL CHECA", value: "200606" },
    { name: "QUERECOTILLO", value: "200607" },
    { name: "SALITRAL", value: "200608" },
    { name: "PARIÑAS", value: "200701" },
    { name: "EL ALTO", value: "200702" },
    { name: "LA BREA", value: "200703" },
    { name: "LOBITOS", value: "200704" },
    { name: "LOS ORGANOS", value: "200705" },
    { name: "MANCORA", value: "200706" },
    { name: "SECHURA", value: "200801" },
    { name: "BELLAVISTA DE LA UNION", value: "200802" },
    { name: "BERNAL", value: "200803" },
    { name: "CRISTO NOS VALGA", value: "200804" },
    { name: "VICE", value: "200805" },
    { name: "RINCONADA LLICUAR", value: "200806" },
    { name: "PUNO", value: "210101" },
    { name: "ACORA", value: "210102" },
    { name: "AMANTANI", value: "210103" },
    { name: "ATUNCOLLA", value: "210104" },
    { name: "CAPACHICA", value: "210105" },
    { name: "CHUCUITO", value: "210106" },
    { name: "COATA", value: "210107" },
    { name: "HUATA", value: "210108" },
    { name: "MAÑAZO", value: "210109" },
    { name: "PAUCARCOLLA", value: "210110" },
    { name: "PICHACANI", value: "210111" },
    { name: "PLATERIA", value: "210112" },
    { name: "SAN ANTONIO /1", value: "210113" },
    { name: "TIQUILLACA", value: "210114" },
    { name: "VILQUE", value: "210115" },
    { name: "AZANGARO", value: "210201" },
    { name: "ACHAYA", value: "210202" },
    { name: "ARAPA", value: "210203" },
    { name: "ASILLO", value: "210204" },
    { name: "CAMINACA", value: "210205" },
    { name: "CHUPA", value: "210206" },
    { name: "JOSE DOMINGO CHOQUEHUANCA", value: "210207" },
    { name: "MUÑANI", value: "210208" },
    { name: "POTONI", value: "210209" },
    { name: "SAMAN", value: "210210" },
    { name: "SAN ANTON", value: "210211" },
    { name: "SAN JOSE", value: "210212" },
    { name: "SAN JUAN DE SALINAS", value: "210213" },
    { name: "SANTIAGO DE PUPUJA", value: "210214" },
    { name: "TIRAPATA", value: "210215" },
    { name: "MACUSANI", value: "210301" },
    { name: "AJOYANI", value: "210302" },
    { name: "COASA", value: "210304" },
    { name: "AYAPATA", value: "210303" },
    { name: "CORANI", value: "210305" },
    { name: "CRUCERO", value: "210306" },
    { name: "ITUATA 2/", value: "210307" },
    { name: "OLLACHEA", value: "210308" },
    { name: "SAN GABAN", value: "210309" },
    { name: "USICAYOS", value: "210310" },
    { name: "JULI", value: "210401" },
    { name: "DESAGUADERO", value: "210402" },
    { name: "HUACULLANI", value: "210403" },
    { name: "KELLUYO", value: "210404" },
    { name: "PISACOMA", value: "210405" },
    { name: "POMATA", value: "210406" },
    { name: "ZEPITA", value: "210407" },
    { name: "ILAVE", value: "210501" },
    { name: "CAPAZO", value: "210502" },
    { name: "PILCUYO", value: "210503" },
    { name: "SANTA ROSA", value: "210504" },
    { name: "CONDURIRI", value: "210505" },
    { name: "HUANCANE", value: "210601" },
    { name: "COJATA", value: "210602" },
    { name: "HUATASANI", value: "210603" },
    { name: "INCHUPALLA", value: "210604" },
    { name: "PUSI", value: "210605" },
    { name: "ROSASPATA", value: "210606" },
    { name: "TARACO", value: "210607" },
    { name: "VILQUE CHICO", value: "210608" },
    { name: "LAMPA", value: "210701" },
    { name: "CABANILLA", value: "210702" },
    { name: "CALAPUJA", value: "210703" },
    { name: "NICASIO", value: "210704" },
    { name: "OCUVIRI", value: "210705" },
    { name: "PALCA", value: "210706" },
    { name: "PARATIA", value: "210707" },
    { name: "PUCARA", value: "210708" },
    { name: "SANTA LUCIA", value: "210709" },
    { name: "VILAVILA", value: "210710" },
    { name: "AYAVIRI", value: "210801" },
    { name: "ANTAUTA", value: "210802" },
    { name: "CUPI", value: "210803" },
    { name: "LLALLI", value: "210804" },
    { name: "MACARI", value: "210805" },
    { name: "NUÑOA", value: "210806" },
    { name: "ORURILLO", value: "210807" },
    { name: "SANTA ROSA", value: "210808" },
    { name: "UMACHIRI", value: "210809" },
    { name: "MOHO", value: "210901" },
    { name: "CONIMA", value: "210902" },
    { name: "HUAYRAPATA", value: "210903" },
    { name: "TILALI", value: "210904" },
    { name: "PUTINA", value: "211001" },
    { name: "ANANEA", value: "211002" },
    { name: "PEDRO VILCA APAZA", value: "211003" },
    { name: "QUILCAPUNCU", value: "211004" },
    { name: "SINA", value: "211005" },
    { name: "JULIACA", value: "211101" },
    { name: "CABANA", value: "211102" },
    { name: "CABANILLAS", value: "211103" },
    { name: "CARACOTO", value: "211104" },
    { name: "SANDIA", value: "211201" },
    { name: "CUYOCUYO", value: "211202" },
    { name: "LIMBANI", value: "211203" },
    { name: "PATAMBUCO", value: "211204" },
    { name: "PHARA", value: "211205" },
    { name: "QUIACA", value: "211206" },
    { name: "SAN JUAN DEL ORO", value: "211207" },
    { name: "YANAHUAYA", value: "211208" },
    { name: "ALTO INAMBARI", value: "211209" },
    { name: "SAN PEDRO DE PUTINA PUNCO", value: "211210" },
    { name: "YUNGUYO", value: "211301" },
    { name: "ANAPIA", value: "211302" },
    { name: "COPANI", value: "211303" },
    { name: "CUTURAPI", value: "211304" },
    { name: "OLLARAYA", value: "211305" },
    { name: "TINICACHI", value: "211306" },
    { name: "UNICACHI", value: "211307" },
    { name: "MOYOBAMBA", value: "220101" },
    { name: "CALZADA", value: "220102" },
    { name: "HABANA", value: "220103" },
    { name: "JEPELACIO", value: "220104" },
    { name: "SORITOR", value: "220105" },
    { name: "YANTALO", value: "220106" },
    { name: "BELLAVISTA", value: "220201" },
    { name: "ALTO BIAVO", value: "220202" },
    { name: "BAJO BIAVO", value: "220203" },
    { name: "HUALLAGA", value: "220204" },
    { name: "SAN PABLO", value: "220205" },
    { name: "SAN RAFAEL", value: "220206" },
    { name: "SAN JOSE DE SISA", value: "220301" },
    { name: "AGUA BLANCA", value: "220302" },
    { name: "SAN MARTIN", value: "220303" },
    { name: "SANTA ROSA", value: "220304" },
    { name: "SHATOJA", value: "220305" },
    { name: "SAPOSOA", value: "220401" },
    { name: "ALTO SAPOSOA", value: "220402" },
    { name: "EL ESLABON", value: "220403" },
    { name: "PISCOYACU", value: "220404" },
    { name: "SACANCHE", value: "220405" },
    { name: "TINGO DE SAPOSOA", value: "220406" },
    { name: "LAMAS", value: "220501" },
    { name: "ALONSO DE ALVARADO", value: "220502" },
    { name: "BARRANQUITA", value: "220503" },
    { name: "CAYNARACHI 1/", value: "220504" },
    { name: "CUÑUMBUQUI", value: "220505" },
    { name: "PINTO RECODO", value: "220506" },
    { name: "RUMISAPA", value: "220507" },
    { name: "SAN ROQUE DE CUMBAZA", value: "220508" },
    { name: "SHANAO", value: "220509" },
    { name: "TABALOSOS", value: "220510" },
    { name: "ZAPATERO", value: "220511" },
    { name: "JUANJUI", value: "220601" },
    { name: "CAMPANILLA", value: "220602" },
    { name: "HUICUNGO", value: "220603" },
    { name: "PACHIZA", value: "220604" },
    { name: "PAJARILLO", value: "220605" },
    { name: "PICOTA", value: "220701" },
    { name: "BUENOS AIRES", value: "220702" },
    { name: "CASPISAPA", value: "220703" },
    { name: "PILLUANA", value: "220704" },
    { name: "PUCACACA", value: "220705" },
    { name: "SAN CRISTOBAL", value: "220706" },
    { name: "SAN HILARION", value: "220707" },
    { name: "SHAMBOYACU", value: "220708" },
    { name: "TINGO DE PONASA", value: "220709" },
    { name: "TRES UNIDOS", value: "220710" },
    { name: "RIOJA", value: "220801" },
    { name: "AWAJUN", value: "220802" },
    { name: "ELIAS SOPLIN VARGAS", value: "220803" },
    { name: "NUEVA CAJAMARCA", value: "220804" },
    { name: "PARDO MIGUEL", value: "220805" },
    { name: "POSIC", value: "220806" },
    { name: "SAN FERNANDO", value: "220807" },
    { name: "YORONGOS", value: "220808" },
    { name: "YURACYACU", value: "220809" },
    { name: "TARAPOTO", value: "220901" },
    { name: "ALBERTO LEVEAU", value: "220902" },
    { name: "CACATACHI", value: "220903" },
    { name: "CHAZUTA", value: "220904" },
    { name: "CHIPURANA", value: "220905" },
    { name: "EL PORVENIR", value: "220906" },
    { name: "HUIMBAYOC", value: "220907" },
    { name: "JUAN GUERRA", value: "220908" },
    { name: "LA BANDA DE SHILCAYO", value: "220909" },
    { name: "MORALES", value: "220910" },
    { name: "PAPAPLAYA", value: "220911" },
    { name: "SAN ANTONIO", value: "220912" },
    { name: "SAUCE", value: "220913" },
    { name: "SHAPAJA", value: "220914" },
    { name: "TOCACHE", value: "221001" },
    { name: "NUEVO PROGRESO", value: "221002" },
    { name: "POLVORA", value: "221003" },
    { name: "SHUNTE 2/", value: "221004" },
    { name: "UCHIZA", value: "221005" },
    { name: "TACNA", value: "230101" },
    { name: "ALTO DE LA ALIANZA", value: "230102" },
    { name: "CALANA", value: "230103" },
    { name: "CIUDAD NUEVA", value: "230104" },
    { name: "INCLAN", value: "230105" },
    { name: "PACHIA", value: "230106" },
    { name: "PALCA", value: "230107" },
    { name: "POCOLLAY", value: "230108" },
    { name: "SAMA", value: "230109" },
    { name: "CORONEL GREGORIO ALBARRACIN LANCHIPA", value: "230110" },
    { name: "CANDARAVE", value: "230201" },
    { name: "CAIRANI", value: "230202" },
    { name: "CAMILACA", value: "230203" },
    { name: "CURIBAYA", value: "230204" },
    { name: "HUANUARA", value: "230205" },
    { name: "QUILAHUANI", value: "230206" },
    { name: "LOCUMBA", value: "230301" },
    { name: "ILABAYA", value: "230302" },
    { name: "ITE", value: "230303" },
    { name: "TARATA", value: "230401" },
    { name: "HEROES ALBARRACIN", value: "230402" },
    { name: "ESTIQUE", value: "230403" },
    { name: "ESTIQUE-PAMPA", value: "230404" },
    { name: "SITAJARA", value: "230405" },
    { name: "SUSAPAYA", value: "230406" },
    { name: "TARUCACHI", value: "230407" },
    { name: "TICACO", value: "230408" },
    { name: "TUMBES", value: "240101" },
    { name: "CORRALES", value: "240102" },
    { name: "LA CRUZ", value: "240103" },
    { name: "PAMPAS DE HOSPITAL", value: "240104" },
    { name: "SAN JACINTO", value: "240105" },
    { name: "SAN JUAN DE LA VIRGEN", value: "240106" },
    { name: "ZORRITOS", value: "240201" },
    { name: "CASITAS", value: "240202" },
    { name: "CANOAS DE PUNTA SAL", value: "240203" },
    { name: "ZARUMILLA", value: "240301" },
    { name: "AGUAS VERDES", value: "240302" },
    { name: "MATAPALO", value: "240303" },
    { name: "PAPAYAL", value: "240304" },
    { name: "CALLERIA", value: "250101" },
    { name: "CAMPOVERDE", value: "250102" },
    { name: "IPARIA", value: "250103" },
    { name: "MASISEA", value: "250104" },
    { name: "YARINACOCHA", value: "250105" },
    { name: "NUEVA REQUENA", value: "250106" },
    { name: "MANANTAY", value: "250107" },
    { name: "RAYMONDI", value: "250201" },
    { name: "SEPAHUA", value: "250202" },
    { name: "TAHUANIA", value: "250203" },
    { name: "YURUA", value: "250204" },
    { name: "PADRE ABAD", value: "250301" },
    { name: "IRAZOLA", value: "250302" },
    { name: "CURIMANA", value: "250303" },
    { name: "PURUS", value: "250401" },
  ],
  [CatalogsEnum.Banks]: [
    { name: "Banco de Crédito del Perú", value: "0002" },
    { name: "Interbank", value: "0003" },
    { name: "Citibank del Perú S.A.", value: "0007" },
    { name: "Scotiabank Perú S.A.A.", value: "0009" },
    { name: "Banco Continental", value: "0011" },
    { name: "Banco de la Nación", value: "0018" },
    { name: "Banco de Comercio", value: "0023" },
    { name: "Banco Financiero del Perú", value: "0035" },
    { name: "Banco Interamericano de Finanzas (BIF)", value: "0038" },
    { name: "Banco Falabella", value: "0053" },
    { name: "Banco Ripley", value: "0055" },
    { name: "Banco Santander Perú", value: "0056" },
    { name: "Banco Azteca", value: "0058" },
    { name: "Banco GNB Perú S.A.", value: "0054" },
    { name: "Caja Municipal de Ahorro y Crédito Trujillo", value: "0802" },
    { name: "Caja Municipal de Ahorro y Crédito Arequipa", value: "0803" },
    { name: "Caja Municipal de Ahorro y Crédito Sullana", value: "0805" },
    { name: "Caja Municipal de Ahorro y Crédito Huancayo", value: "0808" },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { name: "Corriente", value: "0" },
    { name: "Ahorros", value: "1" },
  ],
};
