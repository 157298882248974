import { SelectCheckProps } from "../SelectCheck";
import { useEffect, useState } from "react";
import * as React from "react";
import {
  AutocompleteChangeReason,
  AutocompleteValue,
} from "@mui/base/AutocompleteUnstyled/useAutocomplete";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";
import { IOptionSelection } from "../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { AutocompleteChangeDetails } from "@mui/material";
import { isEmpty } from "lodash";

export const useSelectCheck = (props: SelectCheckProps) => {
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState<ICategory[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState<ICategory[]>([]);
  const [check, setCheck] = useState<boolean>(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleChange = (
    _event: React.SyntheticEvent,
    _value: AutocompleteValue<ICategory[], undefined, true, undefined>,
    _reason: AutocompleteChangeReason,
    selectedItem: AutocompleteChangeDetails<ICategory> | undefined
  ) => {
    setItems((prev: ICategory[]) => {
      return prev.map((item: ICategory) =>
        selectedItem
          ? JSON.stringify(item) === JSON.stringify(selectedItem.option)
            ? { ...item, selected: !item.selected }
            : item
          : {
              ...item,
              selected: false,
            }
      );
    });
  };

  const handleChangeInputValue = (value: string) => {
    setInputValue(value);
    if (props.changeTextField) props.changeTextField(value);
  };

  const handleCheck = () => {
    setCheck(!check);
    setItems((prev: ICategory[]) =>
      prev.map((item: ICategory) => ({ ...item, selected: !check }))
    );
  };

  useEffect(() => {
    setValue(items.filter(({ selected }) => selected));
    props.setSelectedItems(items.filter(({ selected }) => selected));
  }, [items]);

  useEffect(() => {
    if (props.isEditCondition || props.isFinalCond)
      setItems(
        props.items.map((item: IOptionSelection) => {
          return { label: item.label, value: item.value, selected: false };
        })
      );
  }, [props.operator]);

  useEffect(() => {
    if (!isEmpty(items)) return;

    setItems(
      props.items.map((item: IOptionSelection) => {
        if (
          props.selected.includes(item.value) ||
          props.selected.includes(item.label)
        )
          return { label: item.label, value: item.value, selected: true };
        return { label: item.label, value: item.value, selected: false };
      })
    );
  }, [props.items]);

  return {
    check,
    handleOpen,
    handleChange,
    handleChangeInputValue,
    handleCheck,
    handleClose,
    inputValue,
    items,
    open,
    value,
  };
};
