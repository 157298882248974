import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/api/api_routes";
import { CheckOutTokenRequest } from "../../../../types/checkout_token_request";
import {
  ICheckoutInitResponse,
  ICheckoutTokenData,
} from "../../../shared/interfaces/RechargeWalletData.interfaces";
import { InitRequest } from "../../../../types/checkout_init_request";

export const postCheckOutTokenRequest = createAsyncThunk<
  ICheckoutTokenData,
  CheckOutTokenRequest
>("checkout/token", async (payload) => {
  const response = await axios.post(API_ROUTES.POST_CHECKOUT_TOKEN, payload);

  return response.data;
});

export const postCheckOutInitRequest = createAsyncThunk<
  ICheckoutInitResponse,
  InitRequest
>("checkout/init", async (payload) => {
  const response = await axios.post(API_ROUTES.POST_CHECKOUT_INIT, payload);

  return response.data;
});
