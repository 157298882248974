import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Inbox } from "react-feather";

export const EmptyList: React.FC = () => {
  return (
    <React.Fragment>
      <Grid container style={{ background: "#FFF" }}>
        <Grid
          style={{ height: 400 }}
          item
          xs={12}
          container
          alignItems="center"
          justify="center"
          direction="row"
        >
          <div>
            <Grid container>
              <Grid
                item
                xs={12}
                md={8}
                container
                alignItems="center"
                justify="flex-start"
                direction="row"
              >
                <div>
                  <Typography variant="h6">
                    No se ha encontrado ninguna coincidencia
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <Inbox size={150} color="rgba(226,232,240,1)" />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
