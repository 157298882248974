import { DeferredMonths } from "../../../shared/infrastructure/interfaces/IDeferredMonths";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { DateRange } from "@material-ui/pickers";
import { useHistory } from "react-router";
import {
  BrazilDeferredOption,
  DeferredOptionMerchant,
  UpdateDeferredMerchantRequest,
} from "../../../../types/update_deferred_merchant_request";
import { auth } from "../../../shared/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsDeferredLoaded,
  updateDeferredMerchantBrazil,
} from "../../../store/actionCreators";
import {
  calculateDateUtc,
  calculateDateUtcNumber,
  isEnabledStatus,
} from "../../../shared/utils/utils";
import {
  IBrazilSectionState,
  ILocationBrazilSection,
} from "./useBrazilSection.interface";
import { IAppState } from "../../../store/reducer";
import { DeferredOption } from "../../../../types/deferred_options";
import { DeferredStatusEnum } from "../../../shared/infrastructure/constants/DeferredStatusEnum";

export const useBrazilSection = (): IBrazilSectionState => {
  const history = useHistory();
  const dispatch = useDispatch();
  const merchant = auth.getAuthMerchant();
  const location = useLocation<ILocationBrazilSection>();
  const allDeferred = useSelector((state: IAppState) => state.merchantDeferred);
  const {
    processorName,
    merchantMonths,
    months,
    id,
    endDate,
    startDate,
    merchantStatus,
  } = location.state;
  const [selectedMonths, setSelectedMonths] = useState<DeferredMonths[]>([]);
  const [stateSwitch, setStateSwitch] = useState<boolean>(false);
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);
  const [rangeSchedule, setRangeSchedule] = useState<DateRange<Date>>([
    null,
    null,
  ]);

  useEffect(() => {
    setSelectedMonths(
      months.map((month: string) => {
        return {
          selected: merchantMonths.includes(month),
          value: parseInt(month),
        };
      })
    );

    if (startDate && endDate) {
      setStateSwitch(true);
      setRangeSchedule([
        new Date(calculateDateUtc(startDate * 1000, true)),
        new Date(calculateDateUtc(endDate * 1000, false)),
      ]);
    }
  }, []);

  useEffect(() => {
    setDisableSaveButton(
      !selectedMonths.some((month: DeferredMonths) => month.selected)
    );
  }, [selectedMonths]);

  const handleSelectedMonth = (
    value: number,
    index: number,
    selected: boolean
  ): void => {
    let new_deferred_months: DeferredMonths[] = [...selectedMonths];

    new_deferred_months.splice(index, 1, {
      selected,
      value,
    });

    setSelectedMonths([...new_deferred_months]);
  };

  const handleDeleteSelectedMonth = (value: number): void => {
    let new_deferred_months: DeferredMonths[] = [...selectedMonths];
    const index: number = new_deferred_months.findIndex(
      (month: DeferredMonths) => month.value === value
    );

    new_deferred_months.splice(index, 1, {
      selected: false,
      value,
    });

    setSelectedMonths([...new_deferred_months]);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleSave = () => {
    const newDeferred: BrazilDeferredOption = {
      deferredType: ["0"],
      id,
      merchantMonths: selectedMonths
        .filter((filter: DeferredMonths) => filter.selected)
        .map((map: DeferredMonths) => map.value.toString()),
      merchantStatus,
      months,
      processorName,
    };
    const [startDate, endDate] = rangeSchedule;

    if (stateSwitch && startDate && endDate) {
      newDeferred.startDate = Math.trunc(
        calculateDateUtcNumber(startDate, true) / 1000
      );
      newDeferred.endDate = Math.trunc(
        calculateDateUtcNumber(endDate, false) / 1000
      );

      newDeferred.merchantStatus = isEnabledStatus(merchant.country, startDate)
        ? DeferredStatusEnum.ENABLED
        : DeferredStatusEnum.DISABLED;
    }

    const deferredOptions = allDeferred!.map((deferred: DeferredOption) =>
      deferred.id === id ? newDeferred : deferred
    );

    const merchantDeferred: UpdateDeferredMerchantRequest = {
      country: merchant.country,
      deferredOptions: deferredOptions as DeferredOptionMerchant[],
    };

    dispatch(
      updateDeferredMerchantBrazil(merchant.publicMerchantId, merchantDeferred)
    );
    dispatch(setIsDeferredLoaded(false));
    handleCancel();
  };

  const handleChangeSwitch = (value: boolean) => {
    setStateSwitch(value);
  };

  const handleRangeDate = (value: DateRange<Date>) => {
    setRangeSchedule(value);
  };

  return {
    disableSaveButton,
    handleCancel,
    handleChangeSwitch,
    handleDeleteSelectedMonth,
    handleRangeDate,
    handleSave,
    handleSelectedMonth,
    processorName,
    rangeSchedule,
    selectedMonths,
    stateSwitch,
  };
};
