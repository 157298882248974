import { useRef, useState } from "react";
import { UseDownloadButton } from "./useDownloadButton.interfaces";

const useDownloadButton: UseDownloadButton = ({ handleClick, options }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selectedOption, setSelectedOption] = useState<string>(options[0]);

  const anchorRef = useRef<HTMLDivElement>(null);

  const handleMenuItemClick = (index: number, option: string) => () => {
    setSelectedIndex(index);
    setOpen(false);
    handleClick(option);
    setSelectedOption(option);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef?.current &&
      anchorRef?.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleMainButton = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return {
    actions: {
      handleClose,
      handleMainButton,
      handleMenuItemClick,
      handleToggle,
    },
    anchorRef,
    ariaControls: open ? "split-button-menu" : undefined,
    ariaExpanded: open ? "true" : undefined,
    currentOption: selectedOption,
    open,
    selectedIndex,
  };
};

export default useDownloadButton;
