import { makeStyles } from "@material-ui/core/styles";

const baseColor: string = "#6D7781";
const baseBgColor: string = "#F7FAFC";
const baseErrColor: string = "#AD0C2A";
const baseGreenColor: string = "#0DC298";

export const deferredTableStyle = makeStyles((theme) => ({
  bankListMB: {
    display: "inline-block",
    marginBottom: "5px",
    marginRight: "25px",
    position: "relative",
  },
  bankStyle: {
    cursor: "pointer",
  },
  buttonDeleteMF: {
    color: "#D62C4B",
  },
  calendarStyle: {
    display: "inline-flex",
    margin: "0 5px",
  },
  centerStyle: {
    textAlign: "center",
  },
  chip: {
    background: baseBgColor,
    borderRadius: "4px",
    color: "theme.palette.text.primary",
    fontSize: "14px",
    height: "20px",
    marginBottom: "6px",
    marginRight: "8px",
  },
  containerMainMF: {
    backgroundColor: "#F0F4F9",
  },
  containerMB: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 24,
  },
  containerMF: {
    margin: "15px 15px 15px 0px",
  },
  greenChip: {
    backgroundColor: "#E0FFF8",
    borderRadius: "4px",
    height: "25px",
    width: "83px",
  },
  greenChipText: {
    color: "#3B9D86",
    fontSize: "9px",
    fontStyle: "normal",
    fontWeight: "bold",
    paddingTop: "5px",
    textAlign: "center",
  },
  image: {
    paddingRight: "10px",
  },
  linkStyle: {
    fontSize: "16px",
    textDecoration: "underline",
  },
  menuButton: {
    backgroundColor: "rgb(234,237,242)",
    height: 32,
    minWidth: 32,
    padding: "0px 3px 0px 3px",
    width: 32,
  },
  menuStyle: {
    borderRadius: "4px",
    filter:
      "drop-shadow(0px 4px 6px rgba(50, 50, 93, 0.12)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.08))",
  },
  merchantMonthsOfGraceStyle: {
    backgroundColor: "#023365",
    borderRadius: "4px",
    color: "white",
    fontSize: "14px",
    height: "20px",
    marginBottom: "6px",
    marginRight: "8px",
  },
  merchantMonthsStyle: {
    "&:focus": {
      backgroundColor: baseGreenColor,
    },
    backgroundColor: baseGreenColor,
    borderRadius: "4px",
    color: "white",
    fontSize: "14px",
    height: "20px",
    marginBottom: "6px",
    marginRight: "8px",
  },
  merchantMonthsStyleMB: {
    border: "`1px solid ${baseGreenColor}`",
    borderRadius: "4px",
    color: baseColor,
    fontSize: "14px",
    height: "20px",
    marginBottom: "6px",
    marginRight: "8px",
  },
  modalCancelBtn: {
    color: theme.palette.primary.dark,
    marginRight: "2rem",
  },
  noDeferredStyle: {
    color: "#023365",
    flex: "none",
    flexGrow: 1,
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    letterSpacing: "0.0015em",
    lineHeight: "120%",
    order: 0,
    textAlign: "center",
  },
  nowrapText: {
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  paperMB: {
    backgroundColor: "`${baseBgColor} !important`",
    borderRadius: 3,
    paddingBottom: 15,
    width: "100%",
  },
  redChip: {
    backgroundColor: "#FFEAEE",
    borderRadius: "4px",
    height: "25px",
    width: "83px",
  },
  redChipText: {
    color: baseErrColor,
    fontSize: "9px",
    fontStyle: "normal",
    fontWeight: "bold",
    paddingTop: "5px",
    textAlign: "center",
  },
  secondTextMB: {
    color: baseColor,
    fontSize: "15px",
  },
  subMenu: {
    paddingRight: "70px",
  },
  subMenuRed: {
    color: baseErrColor,
    paddingRight: "70px",
  },
  tableCellStyle: {
    backgroundColor: baseBgColor,
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  tableHeaderCard: {
    minWidth: "250px",
  },
  tableStyle: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    color: "#46525C",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "bold",
  },
  tittleMB: {
    color: "#023365",
    fontWeight: 500,
    paddingBottom: "10px",
    paddingTop: "10px",
  },
  variantChip: {
    backgroundColor: "#EEF6FF",
    borderRadius: "4px",
    color: "#023365",
    fontSize: "9px",
    fontStyle: "normal",
    fontWeight: 500,
    height: "15px",
    lineHeight: "170%",
    width: "auto",
  },
}));
