import React from "react";
import { ISlideItem } from "../../components/Slider/SlideItem/slideItem.interfaces";
import { AsociarOwner } from "../../assets/images/AsociarOwner";
import { CrearPrimerBranch } from "../../assets/images/CrearPrimerBranch";
import { SeccionesCentralizadas } from "../../assets/images/SeccionesCentralizadas";

enum TitlesEnum {
  ASOCIAR_OWNER = "Asociar un owner",
  PRIMER_BRANCH = "Crear primer branch",
  SECCIONES_CENTRALIZADAS = "Secciones centralizadas",
}

enum DescriptionsEnum {
  ASOCIAR_OWNER = "Antes de comenzar la configuración del customer deberás asociar un owner nuevo o existente.",
  PRIMER_BRANCH = "Al terminar la creación del customer, deberás crear su primer branch para que el customer sea activado.",
  SECCIONES_CENTRALIZADAS = "El primer branch replicará automáticamente la configuración del customer. A partir del segundo branch podrás activar o desactivar la centralización.",
}

export const slidesContent: ISlideItem[] = [
  {
    image: <AsociarOwner />,
    secondaryText: DescriptionsEnum.ASOCIAR_OWNER,
    title: TitlesEnum.ASOCIAR_OWNER,
  },
  {
    image: <CrearPrimerBranch />,
    secondaryText: DescriptionsEnum.PRIMER_BRANCH,
    title: TitlesEnum.PRIMER_BRANCH,
  },
  {
    image: <SeccionesCentralizadas />,
    secondaryText: DescriptionsEnum.SECCIONES_CENTRALIZADAS,
    title: TitlesEnum.SECCIONES_CENTRALIZADAS,
  },
];

export const BUTTON_TEXT = "Entendido";

export const REDIRECT_PATH = "/create-node/customer";

export const REDIRECT_PATH_EDIT_MASSIVE_BRANCH = "/create-node/branch/edit";

export const REDIRECT_PATH_BRANCH =
  "/create-node/branch/options?publicMerchantId=";
