import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MerchantResponse } from "../../../../../types/merchant_response";
import { IAppState } from "../../../../store/reducer";
import { useEffect } from "react";
import { getWebhookList } from "../../../../store/actionCreators";
import { get } from "lodash";
import { WebhookListFetch } from "../../../../shared/interfaces/WebhookListFetch";

export interface IUseWebhooksGrid {
  webhookList: WebhookListFetch | undefined;
  isLoadingWebhookList: boolean | undefined;
  handleOnlyUniqueWebhookService: (
    value: string,
    index: number,
    self: string[]
  ) => boolean;
}

export const useWebhooksGrid = (): IUseWebhooksGrid => {
  const { merchantId } = useParams();
  const dispatch = useDispatch();
  const merchant: MerchantResponse = useSelector(
    (state: IAppState) => state.merchant!
  );
  const webhookList = useSelector((state: IAppState) => state.webhookList);
  const isLoadingWebhookList: boolean | undefined = useSelector(
    (state: IAppState) => state.isLoadingWebhookList
  );

  useEffect(() => {
    dispatch(getWebhookList(get(merchant, "publicMerchantId", merchantId)));
  }, [merchant]);

  const handleOnlyUniqueWebhookService = (
    value: string,
    index: number,
    self: string[]
  ): boolean => {
    return self.indexOf(value) === index;
  };

  return {
    webhookList,
    isLoadingWebhookList,
    handleOnlyUniqueWebhookService,
  };
};
