import { WebhookNotification } from "../../../../types/webhook_notification";

export enum NOTIFICATION_CONFIG_TYPE {
  API = "api",
  CONSOLE = "console",
}

export const NOTIFICATION_CONFIG_TYPE_TEXT: {
  [K in NOTIFICATION_CONFIG_TYPE]: string;
} = {
  [NOTIFICATION_CONFIG_TYPE.API]: "API",
  [NOTIFICATION_CONFIG_TYPE.CONSOLE]: "consola",
};
export const NOTIFICATION_CONFIG_DEFAULT = NOTIFICATION_CONFIG_TYPE.CONSOLE;

export interface INotificationDetailState {
  dateFormat: string;
  onHighLight: (code: any) => string;
  isMobile: boolean;
  select: {
    webhookNotification: WebhookNotification;
    merchantId: string;
  };
  actions: {
    handleGoBack: () => void;
  };
}
