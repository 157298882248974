import { createSlice } from "@reduxjs/toolkit";
import { INotification } from "../../../containers/ChargebackManageFiles/ChargebackManageFiles";
import { RetrieveChildResultEnum } from "../../../shared/enums/ConfigEnum";
import { SearchRetrieveChildRequest } from "../../../../types/search_retrive_child_request";
import { SearchRetrieveChildResponse } from "../../../../types/search_retrive_child_response";

export interface RetriveChildState {
  retrieveChildResponse: SearchRetrieveChildResponse;
  retrieveChildRequest: SearchRetrieveChildRequest;
  isLoading: boolean;
  isWaitingForRequest: boolean;
  notification: INotification;
}

export const retrieveChildInitialState: RetriveChildState = {
  isLoading: false,
  isWaitingForRequest: false,
  notification: {
    action: RetrieveChildResultEnum.RETRIEVE_CHILD_REQUEST_ERROR,
    message: "",
    open: false,
  },
  retrieveChildRequest: {
    Items: [
      {
        entityName: "",
        publicMerchantId: "",
      },
    ],
  },
  retrieveChildResponse: {
    items: [{}],
  },
};
export const retrieveChildSlice = createSlice({
  initialState: retrieveChildInitialState,
  name: "chargeback",
  reducers: {
    resetRetrieveChild(state) {
      state.retrieveChildRequest =
        retrieveChildInitialState.retrieveChildRequest;
      state.retrieveChildResponse =
        retrieveChildInitialState.retrieveChildResponse;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsWaitingForRequestChild(state, action) {
      state.isWaitingForRequest = action.payload;
    },
    setNotification(state, action) {
      state.notification = action.payload;
    },
    setRetrieveChildRequest(state, action) {
      state.retrieveChildRequest = action.payload;
    },
    setRetrieveChildResponse(state, action) {
      state.retrieveChildResponse = action.payload;
    },
  },
});

export default retrieveChildSlice.reducer;
