import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useTypedController } from "@hookform/strictly-typed";
import ConnectForm from "../../commons/ConnectForm/ConnectForm";
import { ISecurityRuleForm } from "../../../shared/infrastructure/interfaces/ISecurityRuleForm";
import { SecurityRulesLabelField } from "../../../shared/infrastructure/constants/SecurityRulesConstants";
import { get } from "lodash";
import { IOptionSelection } from "../../../shared/infrastructure/constants/RulesConstants";
import { MAP_TYPE_ISCREDIT } from "../../../shared/infrastructure/constants/BusinessRulesEnum";

export interface SelectFieldComponentProps {
  index: number;
  items: IOptionSelection[];
  disabled: boolean;
  label: String;
}

export const SelectFieldComponent: React.FC<SelectFieldComponentProps> = (
  props: SelectFieldComponentProps
) => {
  return (
    <ConnectForm<ISecurityRuleForm>>
      {({ control, errors, getErrorMessage }) => {
        const TypedController = useTypedController<ISecurityRuleForm>({
          control: control,
        });

        return (
          <React.Fragment>
            <TypedController
              name={["strictCondition", props.index, "value"]}
              rules={{ required: true }}
              defaultValue={props.items[0].value}
              render={(properties) => (
                <FormControl
                  variant="outlined"
                  error={!!get(errors, `strictCondition.${props.index}.value`)}
                  fullWidth
                >
                  <InputLabel>{SecurityRulesLabelField.VALUE}</InputLabel>
                  <Select
                    {...properties}
                    value={
                      props.label === "transactionIsCreditCard"
                        ? MAP_TYPE_ISCREDIT[`${String(properties.value)}`]
                        : properties.value
                    }
                    label={SecurityRulesLabelField.VALUE}
                    disabled={props.disabled}
                  >
                    {props.items.map(
                      (
                        actionValue: { name: string; value: string },
                        index: number
                      ) => (
                        <MenuItem key={index} value={actionValue.value}>
                          {actionValue.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                  <FormHelperText
                    error={
                      !!get(errors, `strictCondition.${props.index}.value`)
                    }
                  >
                    {!!get(errors, `strictCondition.${props.index}.value`) &&
                      getErrorMessage(
                        get(errors, `strictCondition.${props.index}.value`)
                      )}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </React.Fragment>
        );
      }}
    </ConnectForm>
  );
};

export default SelectFieldComponent;
