import React from "react";
import { Filter } from "react-feather";
import { Badge, Button, createStyles, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Filter as FilterKushki } from "@kushki/frontend-molecules/filter";
import { IFilterResponse } from "@kushki/frontend-molecules/filter-container";
import { IOptionFilter } from "../../shared/infrastructure/interfaces/IOptionFilter";

const useStyles = makeStyles(() =>
  createStyles({
    filterButton: {
      width: "135px",
      height: "44px",
      backgroundColor: "#f0f4f8F",
      padding: "7px 30px",
    },
    defaultColor: {
      color: "#023365",
    },
    filterPopover: {
      "&.MuiPopover-root .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "2px solid #0DC298",
      },
      "&.MuiPopover-root .MuiInput-underline:focus:not(.Mui-disabled):before": {
        borderBottom: "2px solid #0DC298",
      },
      "&.MuiPopover-root .MuiInput-underline.Mui-focused:before": {
        borderBottom: "2px solid #0DC298",
      },
      "&.MuiPopover-root .MuiInput-underline.Mui-focused:after": {
        borderBottom: "2px solid #0DC298",
      },
    },
  })
);

export interface PopoverFilterProps {
  handleSetFilters: (filters: { [key: string]: boolean }) => void;
  filterChips?: { [key: string]: boolean };
  counterFilter: number;
  filters: IOptionFilter[];
}
export const PopoverFilter: React.FC<PopoverFilterProps> = (
  props: PopoverFilterProps
) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleFilterResponse = (response: IFilterResponse) => {
    props.handleSetFilters(mapFilterResponse(response.listFilter));
    handleClose();
  };

  const mapFilterResponse = (filters: IOptionFilter[]) => {
    let result: { [key: string]: boolean } = {};

    filters.map((option) => {
      option.options.map((item) => {
        result[item.key] = item.selected;
      });
    });
    return result;
  };

  const handleClearFilters = () => {
    props.filters.map((filter) => {
      filter.options.map((option) => {
        option.selected = false;
      });
    });
    const filters = mapFilterResponse(props.filters);
    props.handleSetFilters(filters);
    handleClose();
  };

  return (
    <>
      <Button
        className={classes.filterButton}
        aria-describedby={id}
        size={"medium"}
        startIcon={<Filter className={classes.defaultColor} size={20} />}
        variant="outlined"
        onClick={handleClick}
      >
        <Badge
          color="secondary"
          className={classes.defaultColor}
          badgeContent={props.counterFilter}
        >
          <Typography variant="body1">Filtrar</Typography>
        </Badge>
      </Button>

      <FilterKushki
        idPopover={id}
        openPopover={open}
        isMobile={false}
        openModal={false}
        anchorPopover={anchorEl}
        handleClearFilters={handleClearFilters}
        className={classes.filterPopover}
        styles={{
          applyButtonStyle: "contained",
          removeButtonText: "Restablecer",
        }}
        filters={{
          listFilters: {
            filters: props.filters,
            multiple: true,
          },
        }}
        handleApplyFilters={handleFilterResponse}
        handleClose={handleClose}
      />
    </>
  );
};
