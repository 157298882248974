/**
 * Catalog Nicaragua
 */
import { Category } from "../constants/MerchantInformationConstants";
import { CatalogsEnum } from "../infrastructure/catalogs-enum";
import { CurrencyEnum } from "../infrastructure/currency-enum";

export const CatalogNi: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { name: "CI", value: "0" },
    { name: "NUC", value: "1" },
  ],
  [CatalogsEnum.Banks]: [
    { name: "BANCO DE LA PRODUCCIÓN (BANPRO)", value: "2001" },
    { name: "BANCO DE CRÉDITO CENTROAMERICANO (BANCENTRO)", value: "2002" },
    { name: "BANCO DE AMÉRICA CENTRAL (BAC)", value: "2003" },
    { name: "BANCO DE FINANZAS (BDF)", value: "2004" },
    { name: "BANCO FICOHSA DE NICARAGUA", value: "2005" },
    { name: "BANCO AVANZ", value: "2006" },
    { name: "FINANCIERA FAMA", value: "2007" },
  ],
  [CatalogsEnum.Currency]: [
    { name: "Córdobas", value: CurrencyEnum.NIO },
    { name: "Dólares", value: CurrencyEnum.USD },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { name: "Corriente", value: "0" },
    { name: "Ahorros", value: "1" },
  ],
  [CatalogsEnum.Provinces]: [
    { name: "Boaco", value: "1101" },
    { name: "Carazo", value: "1102" },
    { name: "Chinandega", value: "1103" },
    { name: "Chontales", value: "1104" },
    { name: "Estelí", value: "1105" },
    { name: "Granada", value: "1106" },
    { name: "Jinotega", value: "1107" },
    { name: "León", value: "1108" },
    { name: "Madriz", value: "1109" },
    { name: "Managua", value: "1110" },
    { name: "Masaya", value: "1111" },
    { name: "Matagalpa", value: "1112" },
    { name: "Nueva Segovia", value: "1113" },
    { name: "Atlántico Norte", value: "1114" },
    { name: "Atlántico Sur", value: "1115" },
    { name: "Río San Juan", value: "1116" },
    { name: "Rivas", value: "1117" },
  ],
  [CatalogsEnum.Cities]: [
    { name: "Boaco", parent: "1101", value: "11011" },
    { name: "Camoapa", parent: "1101", value: "11012" },
    { name: "San José de los Remates", parent: "1101", value: "11013" },
    { name: "San Lorenzo", parent: "1101", value: "11014" },
    { name: "Santa Lucía", parent: "1101", value: "11015" },
    { name: "Teustepe", parent: "1101", value: "11016" },
    { name: "Diriamba", parent: "1102", value: "11021" },
    { name: "Dolores", parent: "1102", value: "11022" },
    { name: "El Rosario", parent: "1102", value: "11023" },
    { name: "Jinotepe", parent: "1102", value: "11024" },
    { name: "La Conquista", parent: "1102", value: "11025" },
    { name: "La Paz de Carazo", parent: "1102", value: "11026" },
    { name: "San Marcos", parent: "1102", value: "11027" },
    { name: "Santa Teresa", parent: "1102", value: "11028" },
    { name: "Chichigalpa", parent: "1103", value: "11031" },
    { name: "Chinandega", parent: "1103", value: "11032" },
    { name: "Cinco Pinos", parent: "1103", value: "11033" },
    { name: "Corinto", parent: "1103", value: "11034" },
    { name: "El Realejo", parent: "1103", value: "11035" },
    { name: "El Viejo", parent: "1103", value: "11036" },
    { name: "Posoltega", parent: "1103", value: "11037" },
    { name: "Puerto Morazán", parent: "1103", value: "11038" },
    { name: "San Francisco del Norte", parent: "1103", value: "11039" },
    { name: "San Pedro del Norte", parent: "1103", value: "110310" },
    { name: "Santo Tomás del Norte", parent: "1103", value: "110311" },
    { name: "Somotillo", parent: "1103", value: "110312" },
    { name: "Villanueva", parent: "1103", value: "110313" },
    { name: "Acoyapa", parent: "1104", value: "11041" },
    { name: "Comalapa", parent: "1104", value: "11042" },
    { name: "Juigalpa", parent: "1104", value: "11043" },
    { name: "La Libertad", parent: "1104", value: "11044" },
    { name: "San Pedro de Lóvago", parent: "1104", value: "11045" },
    { name: "Santo Domingo", parent: "1104", value: "11046" },
    { name: "Santo Tomás", parent: "1104", value: "11047" },
    { name: "Villa Sandino", parent: "1104", value: "11048" },
    { name: "El Coral", parent: "1104", value: "11049" },
    { name: "San Francisco de Cuapa", parent: "1104", value: "110410" },
    { name: "Condega", parent: "1105", value: "11051" },
    { name: "Estelí", parent: "1105", value: "11052" },
    { name: "La Trinidad", parent: "1105", value: "11053" },
    { name: "Pueblo Nuevo", parent: "1105", value: "11054" },
    { name: "San Juan de Limay", parent: "1105", value: "11055" },
    { name: "San Nicolás", parent: "1105", value: "11056" },
    { name: "Diriá", parent: "1106", value: "11061" },
    { name: "Diriomo", parent: "1106", value: "11062" },
    { name: "Granada", parent: "1106", value: "11063" },
    { name: "Nandaime", parent: "1106", value: "11064" },
    { name: "El Cuá", parent: "1107", value: "11071" },
    { name: "Jinotega", parent: "1107", value: "11072" },
    { name: "La Concordia", parent: "1107", value: "11073" },
    { name: "San Rafael del Norte", parent: "1107", value: "11074" },
    { name: "San Sebastián de Yalí", parent: "1107", value: "11075" },
    { name: "Santa María de Pantasma", parent: "1107", value: "11076" },
    { name: "Wiwilí de Jinotega", parent: "1107", value: "11077" },
    { name: "San José de Achuapa", parent: "1108", value: "11081" },
    { name: "El Sauce", parent: "1108", value: "11082" },
    { name: "El Jicaral", parent: "1108", value: "11083" },
    { name: "La Paz Centro", parent: "1108", value: "11084" },
    { name: "León", parent: "1108", value: "11085" },
    { name: "Larreynaga", parent: "1108", value: "11086" },
    { name: "Nagarote", parent: "1108", value: "11087" },
    { name: "Quezalguaque", parent: "1108", value: "11088" },
    { name: "Santa Rosa del Peñón", parent: "1108", value: "110810" },
    { name: "Telica", parent: "1108", value: "110811" },
    { name: "Las Sabanas", parent: "1109", value: "11091" },
    { name: "Palacagüina", parent: "1109", value: "11092" },
    { name: "San José de Cusmapa", parent: "1109", value: "11093" },
    { name: "San Juan del Río Coco", parent: "1109", value: "11094" },
    { name: "San Lucas", parent: "1109", value: "11095" },
    { name: "Somoto", parent: "1109", value: "11096" },
    { name: "Telpaneca", parent: "1109", value: "11097" },
    { name: "Totogalpa", parent: "1109", value: "11098" },
    { name: "Yalagüina", parent: "1109", value: "11099" },
    { name: "El Crucero", parent: "1110", value: "11101" },
    { name: "Managua", parent: "1110", value: "11102" },
    { name: "Mateare", parent: "1110", value: "11103" },
    { name: "San Francisco Libre", parent: "1110", value: "11104" },
    { name: "San Rafael del Sur", parent: "1110", value: "11105" },
    { name: "Ticuantepe", parent: "1110", value: "11106" },
    { name: "Tipitapa", parent: "1110", value: "11107" },
    { name: "Villa El Carmen", parent: "1110", value: "11108" },
    { name: "Ciudad Sandino", parent: "1110", value: "11109" },
    { name: "Catarina", parent: "1111", value: "11111" },
    { name: "La Concepción", parent: "1111", value: "11112" },
    { name: "Masatepe", parent: "1111", value: "11113" },
    { name: "Masaya", parent: "1111", value: "11114" },
    { name: "Nandasmo", parent: "1111", value: "11115" },
    { name: "Nindirí", parent: "1111", value: "11116" },
    { name: "Niquinohomo", parent: "1111", value: "11117" },
    { name: "San Juan de Oriente", parent: "1111", value: "11118" },
    { name: "Tisma", parent: "1111", value: "11119" },
    { name: "Ciudad Darío", parent: "1112", value: "11121" },
    { name: "El Tuma-La Dalia", parent: "1112", value: "11122" },
    { name: "Esquipulas", parent: "1112", value: "11123" },
    { name: "Matagalpa", parent: "1112", value: "11124" },
    { name: "Matiguás", parent: "1112", value: "11125" },
    { name: "Muy Muy", parent: "1112", value: "11126" },
    { name: "Rancho Grande", parent: "1112", value: "11127" },
    { name: "Río Blanco", parent: "1112", value: "11128" },
    { name: "San Dionisio", parent: "1112", value: "11129" },
    { name: "San Isidro", parent: "1112", value: "111210" },
    { name: "San Ramón", parent: "1112", value: "111211" },
    { name: "Sébaco", parent: "1112", value: "111212" },
    { name: "Terrabona", parent: "1112", value: "111213" },
    { name: "Ciudad Antigua", parent: "1113", value: "11131" },
    { name: "Dipilto", parent: "1113", value: "11132" },
    { name: "El Jícaro", parent: "1113", value: "11133" },
    { name: "Jalapa", parent: "1113", value: "11134" },
    { name: "Macuelizo", parent: "1113", value: "11135" },
    { name: "Mozonte", parent: "1113", value: "11136" },
    { name: "Murra", parent: "1113", value: "11137" },
    { name: "Ocotal", parent: "1113", value: "11138" },
    { name: "Quilalí", parent: "1113", value: "11139" },
    { name: "San Fernando", parent: "1113", value: "111310" },
    { name: "Santa María", parent: "1113", value: "111311" },
    { name: "Wiwilí de Nueva Segovia", parent: "1113", value: "111312" },
    { name: "Bonanza", parent: "1114", value: "11141" },
    { name: "Mulukukú", parent: "1114", value: "11142" },
    { name: "Prinzapolka", parent: "1114", value: "11143" },
    { name: "Puerto Cabezas", parent: "1114", value: "11144" },
    { name: "Rosita", parent: "1114", value: "11145" },
    { name: "Siuna", parent: "1114", value: "11146" },
    { name: "Waslala", parent: "1114", value: "11147" },
    { name: "Waspán", parent: "1114", value: "11148" },
    { name: "Bluefields", parent: "1115", value: "11151" },
    { name: "Paiwas", parent: "1115", value: "11152" },
    { name: "Corn Island", parent: "1115", value: "11153" },
    { name: "Desembocadura de Río Grande", parent: "1115", value: "11154" },
    { name: "El Rama", parent: "1115", value: "11155" },
    { name: "El Tortuguero", parent: "1115", value: "11156" },
    { name: "Kukra Hill", parent: "1115", value: "11157" },
    { name: "La Cruz de Río Grande", parent: "1115", value: "11158" },
    { name: "Laguna de Perlas", parent: "1115", value: "11159" },
    { name: "Muelle de los Bueyes", parent: "1115", value: "111510" },
    { name: "Nueva Guinea", parent: "1115", value: "111511" },
    { name: "El Ayote", parent: "1115", value: "111512" },
    { name: "El Almendro", parent: "1116", value: "11161" },
    { name: "El Castillo", parent: "1116", value: "11162" },
    { name: "Morrito", parent: "1116", value: "11163" },
    { name: "San Carlos", parent: "1116", value: "11164" },
    { name: "San Juan de Nicaragua", parent: "1116", value: "11165" },
    { name: "San Miguelito", parent: "1116", value: "11166" },
    { name: "Altagracia", parent: "1117", value: "11171" },
    { name: "Belén", parent: "1117", value: "11172" },
    { name: "Buenos Aires", parent: "1117", value: "11173" },
    { name: "Cárdenas", parent: "1117", value: "11174" },
    { name: "Moyogalpa", parent: "1117", value: "11175" },
    { name: "Potosí", parent: "1117", value: "11176" },
    { name: "Rivas", parent: "1117", value: "11177" },
    { name: "San Jorge", parent: "1117", value: "11178" },
    { name: "San Juan del Sur", parent: "1117", value: "11179" },
    { name: "Tola", parent: "1117", value: "111710" },
  ],
  [CatalogsEnum.PersonTypes]: [
    { name: "Física", value: "01" },
    { name: "Jurídica", value: "02" },
  ],
};
