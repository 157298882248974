import React from "react";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Copy } from "react-feather";
import { flags } from "../../../shared/infrastructure/constants/flags";
import { IAppState } from "../../../store/reducer";
import { useStateApp } from "../../../state/useStateApp";
import { defaultTo } from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
  copyIcon: {
    color: "#CBD5E0",
    marginLeft: 10,
  },
  countryFlag: {
    height: theme.spacing(4),
    marginBottom: "auto",
    marginLeft: 15,
    marginRight: 15,
    marginTop: "auto",
    width: theme.spacing(4),
  },
  mediumTitle: {
    color: theme.palette.primary.dark,
    fontSize: 14,
    fontWeight: 500,
  },
  merchantName: {
    fontWeight: 500,
  },
  normalTitle: {
    color: theme.palette.primary.dark,
    fontSize: 14,
  },
  padding: {
    paddingBottom: "15px",
  },
  statusBadge: {
    borderRadius: "4px",
    marginBottom: 24,
    marginTop: 8,
    padding: "4px 8px",
    textAlign: "center",
    width: 75,
  },
  statusLabel: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "100%",
  },
  typography: {
    fontWeight: 100,
  },
  typographyMobile: {
    fontWeight: 200,
  },
}));

export interface IDeferredHeaderProps extends IAppState {
  loading?: boolean;
  title: string;
  merchant?: any;
  isActive?: boolean;
  hasDeferred?: boolean;
}

export const DeferredHeader: React.FC<IDeferredHeaderProps> = ({
  title,
  merchant,
  hasDeferred,
}: IDeferredHeaderProps) => {
  const classes = useStyles();
  const { isMobile } = useStateApp();

  return (
    <React.Fragment>
      <Grid container spacing={1} className={classes.padding}>
        <Grid
          item
          xs={6}
          md={6}
          container
          alignItems="center"
          justify="flex-start"
          direction="row"
        >
          <Typography
            className={isMobile ? classes.typographyMobile : classes.typography}
            color={"primary"}
            variant={isMobile ? "h3" : "h1"}
          >
            {title}
          </Typography>
        </Grid>
      </Grid>
      {defaultTo(hasDeferred, true) && (
        <Toolbar disableGutters>
          <Box display="flex" flexGrow={1}>
            <Box>
              <Typography
                className={classes.merchantName}
                variant={isMobile ? "h6" : "h2"}
                color={"primary"}
              >
                {merchant.name}
              </Typography>
            </Box>
            <Avatar
              className={classes.countryFlag}
              src={
                flags[merchant.country.trim().toLowerCase().replace(/ /g, "")]
              }
            />
          </Box>
          <Box display="flex" flexDirection="column">
            <Box className={classes.mediumTitle}>ID de Comercio</Box>
            <Box
              display="flex"
              alignItems="center"
              className={classes.normalTitle}
            >
              {merchant.publicMerchantId}
              <IconButton className={classes.copyIcon}>
                <Copy
                  size={16}
                  onClick={() => {
                    window.open(
                      merchant.webSite.includes("http") ||
                        merchant.webSite.includes("https")
                        ? merchant.webSite
                        : "https://" + merchant.webSite
                    );
                  }}
                />
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
      )}
    </React.Fragment>
  );
};

export default DeferredHeader;
