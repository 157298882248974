import { DateRange } from "@material-ui/pickers";
import { get, set } from "lodash";
import { useState } from "react";
import {
  IUseDeferredScheduled,
  IUseDeferredScheduledState,
} from "./useDeferredScheduledState.interface";

export const useDeferredScheduledState = (
  props: IUseDeferredScheduled
): IUseDeferredScheduledState => {
  const [showMessage, setShowMessage] = useState<boolean>(false);

  const handleRangeDate = (value: DateRange<Date>): void => {
    setShowMessage(false);
    const nowDate: number = Date.now();
    const startDate: number = get(value, "[0]", new Date()).getTime();
    const endDate: number = get(value, "[1]", new Date()).getTime();

    if (startDate < nowDate) {
      set(value, "[0]", new Date(nowDate));
      setShowMessage(true);
    }
    if (endDate < nowDate) {
      set(value, "[1]", new Date(nowDate));
      setShowMessage(true);
    }
    props.handleRangeDate(value);
  };

  return { actions: { handleRangeDate }, showMessage };
};
