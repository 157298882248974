import React, { PropsWithChildren, useEffect, useState } from "react";
import { Grid, TextField } from "@mui/material";
import { DEFAULT_ITEM_BY_TAG } from "../../../../../../shared/infrastructure/enums/ItemByTagEnum";
import { FabButtonsContainer } from "../../../FabButtonsContainer/FabButtonsContainer";
import { IGenericLabelConditionSectionListProps } from "../../../../../../shared/infrastructure/interfaces/IGenericLabelConditionSectionListProps";
import { defaultTo, get, isEqual } from "lodash";
import {
  isFormEditMode,
  retrieveFormError,
} from "../../../../../../utils/utilsFile";
import { ErrorsEnum } from "../../../../../../shared/infrastructure/enums/ErrorEnum";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { IUseParams } from "../../../../../../shared/infrastructure/interfaces/IUseParams";
import { LabelEnum } from "../../../../../../shared/infrastructure/enums/LabelEnum";

export const GenericLabelConditionSectionList: React.FC<
  IGenericLabelConditionSectionListProps
> = (props: PropsWithChildren<IGenericLabelConditionSectionListProps>) => {
  const { watch, setValue } = useFormContext();
  const { ruleId, ruleName, id } = useParams<IUseParams>();
  const isFormEdit: boolean =
    isFormEditMode(ruleId || ruleName) || isFormEditMode(id);
  const isSpecialCond: boolean = isEqual(props.tag, LabelEnum.PROCESSOR_CODE);
  const [isEditField, setIsEditField] = useState<boolean>(
    isSpecialCond ? true : !isFormEdit
  );
  const keyName: string = `${DEFAULT_ITEM_BY_TAG[props.tag].name}-${
    props.keyIndex
  }`;
  const inputText: string = watch(keyName);
  const [flagEdit, setFlagEdit] = useState<boolean>(false);

  if (inputText === "" && !flagEdit) setValue(keyName, props.value);

  useEffect(() => {
    if (!isFormEdit) setIsEditField(false);
  }, [props.isInserted]);

  return (
    <Grid container direction={"row"} spacing={1.2}>
      <Grid item xs={4.1} sx={{ mb: 1 }}>
        <TextField
          id={keyName}
          name={keyName}
          defaultValue={props.value}
          placeholder={DEFAULT_ITEM_BY_TAG[props.tag].placeholder}
          variant="outlined"
          fullWidth
          disabled={!isEditField}
          inputRef={props.register({
            required: {
              message: ErrorsEnum.REQUIRED_FIELD,
              value: defaultTo(
                DEFAULT_ITEM_BY_TAG[props.tag].isRequiredEdit,
                false
              ),
            },
            pattern: {
              message: DEFAULT_ITEM_BY_TAG[props.tag].errorMessage,
              value: DEFAULT_ITEM_BY_TAG[props.tag].pattern,
            },
          })}
          onBlur={(
            event: React.FocusEvent<
              HTMLInputElement | HTMLTextAreaElement,
              Element
            >
          ) => {
            const targetValue: string = get(event, "target.value", "");
            if (DEFAULT_ITEM_BY_TAG[props.tag].pattern.test(targetValue)) {
              props.handleEdit(get(event, "target.value", ""));
              setIsEditField(false);
            } else {
              setIsEditField(true);
            }
          }}
          onChange={(
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setValue(keyName, get(event, "target.value", ""));
          }}
          error={get(props.errors, keyName)}
          helperText={retrieveFormError(props.errors, keyName)[1]}
          autoFocus={true}
        />
      </Grid>
      {!props.isDisable && (
        <FabButtonsContainer
          handleEditFabButton={() => {
            setFlagEdit(true);
            setIsEditField(true);
          }}
          handleDeleteFabButton={() => {
            setValue(keyName, "");
            props.handleDelete();
          }}
        />
      )}
    </Grid>
  );
};
