import React from "react";
import { Box, Container, Grid } from "@mui/material";
import {
  breadcrumbWhitelistCreationValues,
  breadcrumbWhitelistUpdateValues,
  SubTitleSection,
  TitleSection,
} from "../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { CardSection } from "../../components/CardSection/CardSection";
import { FormProvider } from "react-hook-form";
import { ActionButtons } from "../../components/ActionButtons/ActionButtons";
import { CustomBreadcrumb } from "../../components/common/CustomBreadcrumb/CustomBreadcrumb";
import { isFormEditMode } from "../../utils/utilsFile";
import { useWhiteListState } from "./state/useWhiteListState";
import { CreateWhiteListSection } from "../../components/CreateWhiteListSection/CreateWhiteListSection";
import { ConfirmModal } from "../../components/ConfirmModal/ConfirmModal";
import { ConfirmModalEnum } from "../../shared/infrastructure/enums/ConfirmModalEnum";

export const WhiteList: React.FC = () => {
  const {
    form,
    fields,
    handle,
    id,
    actions,
    branchValue,
    customerValue,
    isLoading,
  } = useWhiteListState();

  return (
    <Container fixed>
      <Box pb={2}>
        <CustomBreadcrumb
          items={
            isFormEditMode(id)
              ? breadcrumbWhitelistUpdateValues
              : breadcrumbWhitelistCreationValues
          }
          splitButton={undefined}
          type={"titleBase"}
          title={
            isFormEditMode(id)
              ? TitleSection.UPDATE_WHITELIST
              : TitleSection.CREATE_WHITELIST
          }
        />
      </Box>
      <FormProvider {...form}>
        <Grid container spacing={2}>
          <CardSection
            sectionComponent={<CreateWhiteListSection fields={fields} />}
            sectionTitle={TitleSection.WHITELIST}
            sectionDescription={SubTitleSection.WHITELIST_CREATE_SUBTITLE}
          />
          <ActionButtons
            onCancel={actions.handleCancelAction}
            form={form}
            fields={fields}
            handleSendToReview={handle.handleSendToReview}
          />
          <ConfirmModal
            modalTitle={
              isFormEditMode(id)
                ? ConfirmModalEnum.TITLE_EDIT
                : ConfirmModalEnum.TITLE
            }
            modalContentText={
              isFormEditMode(id)
                ? ConfirmModalEnum.BODY_EDIT
                : ConfirmModalEnum.BODY
            }
            branch={branchValue}
            customer={customerValue}
            isFormEditMode={isFormEditMode(id)}
            onClose={handle.handleCancelModal}
            onSubmit={handle.handleSendToReview}
            isLoading={isLoading}
          />
        </Grid>
      </FormProvider>
    </Container>
  );
};
