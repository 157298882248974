import { useEffect, useState } from "react";
import { IUseChargeBankAccountState } from "./useChargeBankAccountState.interfaces";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { cloneDeep } from "lodash";
import {
  editBankAccount,
  updateBankAccountIndexOnFocus,
} from "../../../store/actions/charge/charge.actions";
import { IModalDialog } from "../../Modals/ModalDialog/ModalDialog.interfaces";
import {
  ACCEPT_BTN_TXT,
  AccountOrder,
  CANCEL_BTN_TXT,
  DESCRIPTION_TXT,
  MODAL_TITLE_TXT,
} from "../../../shared/constants/dispersions/account_bank_constants";

export const useChargeBankAccountState = (): IUseChargeBankAccountState => {
  const dispatch = useAppDispatch();
  const { banksAccounts: bankAccountsStore } = useAppSelector(
    (state) => state.charge
  );
  const [indexDelete, setIndexDelete] = useState(0);
  const [openModalDialog, setOpenModalDialog] = useState(false);
  const [canAddAccounts, setCanAddAccounts] = useState(true);

  const deleteBankAccount = () => {
    const newBanksAccount = cloneDeep(bankAccountsStore);

    newBanksAccount.splice(indexDelete, 1);

    dispatch(editBankAccount(newBanksAccount));
    setOpenModalDialog(false);
  };

  const handleCloseOpenDialog = () => {
    setOpenModalDialog(false);
  };

  const modalDialogProperties: IModalDialog = {
    acceptButtonText: ACCEPT_BTN_TXT,
    cancelButtonText: CANCEL_BTN_TXT,
    descriptionText: DESCRIPTION_TXT,
    onClickAcceptButton: deleteBankAccount,
    onClickCancelButton: handleCloseOpenDialog,
    onClose: handleCloseOpenDialog,
    openModalDialog,
    titleText: MODAL_TITLE_TXT,
  };

  const handleDeleteBankAccount = (index: number) => {
    setIndexDelete(index);
    setOpenModalDialog(true);
  };

  const handleEditBankAccount = (index: number) => {
    dispatch(updateBankAccountIndexOnFocus(index));
  };

  useEffect(() => {
    if (
      bankAccountsStore.filter(
        (account) => account.accountOrder === AccountOrder.ALTERNATE
      ).length > 2
    )
      setCanAddAccounts(false);
  }, [bankAccountsStore]);

  return {
    accordionBanksAccountsProps: {
      banksAccounts: bankAccountsStore,
      canAddAccounts,
      handleDeleteBankAccount,
      handleEditBankAccount,
    },
    modalDialogProperties,
  };
};
