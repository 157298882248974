import { ItemCategoryItem } from "@kushki/connect-ui";
import { FrequencyLabel, FrequencyValue } from "@shared/enum/FrequencyEnum";
import { CountryEnum, CountryTitleEnum } from "@shared/enum/CountryEnum";
import { CategoryLabel, CategoryValue } from "@shared/enum/CategoryEnum";
import {
  FilterStatusLabel,
  StatusEnum,
} from "@shared/constants/AlarmTableConstants";
import { cloneDeep } from "lodash";

interface IFilterItems {
  category: ItemCategoryItem[];
  constitutionalCountry: ItemCategoryItem[];
  country: ItemCategoryItem[];
  created: ItemCategoryItem[];
  frequency: ItemCategoryItem[];
  status: ItemCategoryItem[];
}

export const filterCountries: ItemCategoryItem[] = [
  {
    label: CountryTitleEnum.colombia,
    selected: false,
    value: CountryEnum.colombia,
  },
  {
    label: CountryTitleEnum.chile,
    selected: false,
    value: CountryEnum.chile,
  },
  {
    label: CountryTitleEnum.costa_rica,
    selected: false,
    value: CountryEnum.costa_rica,
  },
  {
    label: CountryTitleEnum.ecuador,
    selected: false,
    value: CountryEnum.ecuador,
  },
  {
    label: CountryTitleEnum.el_salvador,
    selected: false,
    value: CountryEnum.el_salvador,
  },
  {
    label: CountryTitleEnum.guatemala,
    selected: false,
    value: CountryEnum.guatemala,
  },
  {
    label: CountryTitleEnum.honduras,
    selected: false,
    value: CountryEnum.honduras,
  },
  {
    label: CountryTitleEnum.mexico,
    selected: false,
    value: CountryEnum.mexico,
  },
  {
    label: CountryTitleEnum.nicaragua,
    selected: false,
    value: CountryEnum.nicaragua,
  },
  {
    label: CountryTitleEnum.panama,
    selected: false,
    value: CountryEnum.panama,
  },
  {
    label: CountryTitleEnum.peru,
    selected: false,
    value: CountryEnum.peru,
  },
];

export const allFilterCountries: ItemCategoryItem[] = Object.keys(
  CountryTitleEnum
).map((key) => ({
  label: CountryTitleEnum[key],
  selected: false,
  value: CountryEnum[key],
}));

export const filterItems: IFilterItems = {
  category: [
    {
      label: CategoryLabel.SMALL,
      selected: false,
      value: CategoryValue.SMALL,
    },
    {
      label: CategoryLabel.MEDIUM,
      selected: false,
      value: CategoryValue.MEDIUM,
    },
    {
      label: CategoryLabel.ENTERPRISE,
      selected: false,
      value: CategoryValue.ENTERPRISE,
    },
  ],
  constitutionalCountry: cloneDeep(allFilterCountries),
  country: cloneDeep(filterCountries),
  created: [
    {
      label: "Date",
      selected: false,
      value: "",
    },
  ],
  frequency: [
    {
      label: FrequencyLabel.DIARIA,
      selected: false,
      value: FrequencyValue.DAILY,
    },
    {
      label: FrequencyLabel.SEMANAL,
      selected: false,
      value: FrequencyValue.WEEKLY,
    },
    {
      label: FrequencyLabel.QUINCENAL,
      selected: false,
      value: FrequencyValue.BIWEEKLY,
    },
    {
      label: FrequencyLabel.MENSUAL,
      selected: false,
      value: FrequencyValue.MONTHLY,
    },
    {
      label: FrequencyLabel.TRIMESTRAL,
      selected: false,
      value: FrequencyValue.QUARTERLY,
    },

    {
      label: FrequencyLabel.SEMESTRAL,
      selected: false,
      value: FrequencyValue.BIANNUAL,
    },
  ],
  status: [
    {
      label: FilterStatusLabel.ACTIVE,
      selected: false,
      value: StatusEnum.ACTIVE,
    },
    {
      label: FilterStatusLabel.INACTIVE,
      selected: false,
      value: StatusEnum.INACTIVE,
    },
  ],
};

export const filterItemsGeneral: Partial<IFilterItems> = {
  frequency: [
    {
      label: FrequencyLabel.DIARIA,
      selected: false,
      value: FrequencyValue.DAILY,
    },
    {
      label: FrequencyLabel.SEMANAL,
      selected: false,
      value: FrequencyValue.WEEKLY,
    },
    {
      label: FrequencyLabel.QUINCENAL,
      selected: false,
      value: FrequencyValue.BIWEEKLY,
    },
    {
      label: FrequencyLabel.MENSUAL,
      selected: false,
      value: FrequencyValue.MONTHLY,
    },
    {
      label: FrequencyLabel.TRIMESTRAL,
      selected: false,
      value: FrequencyValue.QUARTERLY,
    },

    {
      label: FrequencyLabel.SEMESTRAL,
      selected: false,
      value: FrequencyValue.BIANNUAL,
    },
  ],
  status: [
    {
      label: FilterStatusLabel.ACTIVE,
      selected: false,
      value: StatusEnum.ACTIVE,
    },
    {
      label: FilterStatusLabel.INACTIVE,
      selected: false,
      value: StatusEnum.INACTIVE,
    },
  ],
};
