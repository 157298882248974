import React from "react";
import { Box } from "@mui/material";
import { TimelineConnector as MuiTimelineConnector } from "@mui/lab";
import { ChildrenProps } from "../../Timeline.interfaces";
import { styles } from "./TimeLineConnector.styles";
import { TimeLineConnectorProps } from "./TimeLineConnector.interfaces";

const TimeLineConnector = ({
  invisible,
  children,
}: TimeLineConnectorProps & ChildrenProps) => {
  return children ? (
    <Box sx={styles.genericContainer}>{children}</Box>
  ) : (
    <MuiTimelineConnector
      aria-label="defaut-time-line-connector"
      sx={
        invisible ? styles.defaultConnectorTransparent : styles.defaultConnector
      }
    />
  );
};

export default TimeLineConnector;
