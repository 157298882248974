import { RefObject, useEffect, useRef, useState } from "react";
import { IAccordionProps } from "../Accordion.interfaces";

export interface IUseAccordion {
  anchorEl: HTMLButtonElement | null;
  iconRef: RefObject<HTMLButtonElement>;
  openAccordion: boolean;
  openOptionList: boolean;
  handleClick: () => void;
  handleClose: () => void;
  handleClickAccordion: () => void;
}

const useAccordion = ({ isOpen }: IAccordionProps): IUseAccordion => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const iconRef = useRef<HTMLButtonElement>(null);
  const [openOptionList, setOpenOptionList] = useState<boolean>(false);
  const [openAccordion, setOpenAccordion] = useState<boolean>(isOpen);

  useEffect(() => {
    iconRef.current && setAnchorEl(iconRef.current);
  }, [iconRef]);

  const handleClick = (): void => setOpenOptionList(!openOptionList);
  const handleClose = (): void => setOpenOptionList(false);

  const handleClickAccordion = (): void => setOpenAccordion(!openAccordion);

  return {
    anchorEl,
    handleClick,
    handleClickAccordion,
    handleClose,
    iconRef,
    openAccordion,
    openOptionList,
  };
};

export { useAccordion };
