import { TransactionStatusEnum } from "../enums/TransactionStatusEnum";
import { get } from "lodash";
import { ITabInfo } from "../../components/GenericTabs/GenericTabs.interfaces";
import { catalogTabListByTransaction } from "../catalogs/CatalogTabListByTransaction";
import { TransactionInfo } from "../../../types/transaction";
import { ContentCard } from "../../components/CardInformation/CardInformation.interfaces";
import { catalogSectionFieldListByTransaction } from "../catalogs/CatalogSectionFieldListByTransaction";

export const getColorByStatus = (status: string) => {
  switch (status) {
    case TransactionStatusEnum.APPROVAL:
    case TransactionStatusEnum.APPROVED:
      return "success";
    case TransactionStatusEnum.DECLINED:
      return "error";
    default:
      return "info";
  }
};

export const isApproval = (status: string): boolean => {
  return [
    TransactionStatusEnum.APPROVED,
    TransactionStatusEnum.APPROVAL,
  ].includes(status as TransactionStatusEnum);
};

export const base64ToBlob = (base64: string): Blob => {
  const byteArray: Uint8Array = Uint8Array.from(
    atob(base64)
      .split("")
      .map((char) => char.charCodeAt(0))
  );

  return new Blob([byteArray], { type: "application/pdf" });
};

export const renderTabsByTransaction = (
  transaction: TransactionInfo,
  tabList: ITabInfo[]
): ITabInfo[] => {
  const tabsByType: Record<string, object> =
    catalogTabListByTransaction(tabList);

  return tabsByType[get(transaction, "transaction_status", "")][
    get(transaction, "payment_method", "")
  ];
};
export const renderSectionFieldListByTransaction = (
  transaction: TransactionInfo,
  contentCard: ContentCard
): ContentCard => {
  const sectionFieldList: Record<string, object> =
    catalogSectionFieldListByTransaction(contentCard.body);

  return {
    ...contentCard,
    body: sectionFieldList[get(transaction, "transaction_status", "")][
      get(transaction, "payment_method", "")
    ][get(contentCard, "type", "")],
  };
};
