import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const configDataWrapperStyles = createNamedStyles({
  configDataWrapper: {
    backgroundColor: "neutral.white",
    borderRadius: 4,
    mb: 15,
    minHeight: 349,
    px: 8,
    py: 6,
    width: "90%",
  },
  divider: {
    borderStyle: "solid",
    borderWidth: "0px 0px thin",
    mb: 4,
    mt: 2,
  },
  gridTitle: {
    paddingBottom: 1.5,
  },
  marginEditBtn: {
    marginLeft: "auto",
  },
});
