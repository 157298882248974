import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.error.dark,
    fontSize: "16px",
  },
  buttonClose: {
    color: theme.palette.grey[900],
    fontSize: 16,
  },
  chip: {
    backgroundColor: "#EEF6FF",
    border: 0,
    borderRadius: "4px",
    color: "#1E65AE",
    display: "DataType.DisplayInternal",
    flexWrap: "wrap",
    margin: 2,
  },
  chips: {
    backgroundColor: "#FFFFFF",
    border: 0,
    borderRadius: "4px",
    display: "flex",
    flexWrap: "wrap",
  },
  closeButton: {
    "&.MuiIconButton-root": {
      marginBottom: 12,
      marginLeft: 12,
      marginRight: 0,
      marginTop: 0,
      padding: 3,
    },
    color: theme.palette.primary.dark,
  },
  dialogId: {
    border: 1,
    borderColor: "#1E65AE",
    borderRadius: 8,
    position: "relative",
    width: "570px!important",
  },
  dialogSelect: {
    border: 1,
    borderColor: "red",
    borderRadius: 8,
    width: "570px!important",
  },
  dialogTitle: {
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 16,
  },
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
  text: {
    align: "justify",
    color: theme.palette.grey["500"],
  },
}));
