/* eslint-disable no-trailing-spaces */
import React, { SVGProps } from "react";

const SvgAmex = (props: SVGProps<SVGSVGElement>) => (
  <svg
    role="img"
    aria-label="amex card icon"
    width="37"
    height="25"
    viewBox="0 0 37 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clipAmex)">
      <path
        d="M36.553 22.857C36.553 24.0406 35.5954 25 34.4141 25H2.50756C1.32626 25.0001 0.368652 24.0406 0.368652 22.857V2.1429C0.368652 0.959369 1.32626 0 2.50756
        0H34.4141C35.5954 0 36.553 0.959439 36.553 2.1429V22.857Z"
        fill="#006FCF"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="5"
        y="0"
        width="26"
        height="25"
      >
        <path
          d="M5 12.5C5 5.59644 10.5964 0 17.5 0H18.5C25.4036 0 31 5.59644 31 12.5V12.5C31 19.4036 25.4036 25 18.5 25H17.5C10.5964 25 5 19.4036 5 12.5V12.5Z"
          fill="#016FD0"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M5 0H31V13.4957L29.7131 15.4292L31 17.15V25H5V12.2776L5.80433 11.3882L5 10.5375V0Z"
          fill="#016FD0"
        />
        <path
          d="M10.0471 17.4207V13.4957H14.3692L14.8329 14.077L15.3119 13.4957H30.9999V17.15C30.9999 17.15 30.5897 17.4168 30.1152 17.4207H21.4284L20.9056
          16.802V17.4207H19.1924V16.3645C19.1924 16.3645 18.9583 16.512 18.4524 16.512H17.8692V17.4207H15.2753L14.8122 16.827L14.3421 17.4207H10.0471Z"
          fill="white"
        />
        <path
          d="M5 10.5375L5.97464 8.35266H7.66018L8.2133 9.57652V8.35266H10.3086L10.6379 9.23723L10.9571 8.35266H20.3627V8.79736C20.3627 8.79736 20.8572
          8.35266 21.6698 8.35266L24.7215 8.36292L25.2651 9.57076V8.35266H27.0186L27.5012 9.04647V8.35266H29.2707V12.2776H27.5012L27.0387 11.5816V12.2776H24.4625L24.2034
          11.6589H23.5108L23.256 12.2776H21.5089C20.8097 12.2776 20.3627 11.842 20.3627 11.842V12.2776H17.7285L17.2057 11.6589V12.2776H7.41048L7.15159 11.6589H6.46123L6.20416
          12.2776H5V10.5375Z"
          fill="white"
        />
        <path
          d="M6.31958 8.83655L5.005 11.7755H5.86086L6.10342 11.1869H7.51351L7.75481 11.7755H8.62952L7.3162 8.83655H6.31958ZM6.80595 9.52052L7.23577 10.5489H6.37488L6.80595 9.52052V9.52052Z"
          fill="#016FD0"
        />
        <path
          d="M8.71997 11.775V8.83606L9.93619 8.8404L10.6436 10.7352L11.334 8.83606H12.5405V11.775H11.7764V9.60946L10.9665 11.775H10.2963L9.48409 9.60946V11.775H8.71997Z"
          fill="#016FD0"
        />
        <path
          d="M13.0635 11.775V8.83606H15.5569V9.49345H13.8356V9.99615H15.5167V10.6149H13.8356V11.1369H15.5569V11.775H13.0635Z"
          fill="#016FD0"
        />
        <path
          d="M15.9993 8.83655V11.7755H16.7634V10.7314H17.0851L18.0013 11.7755H18.9351L17.9297 10.6927C18.3423 10.6592 18.7679 10.3187 18.7679 9.79C18.7679 9.17155
          18.2631 8.83655 17.6997 8.83655H15.9993V8.83655ZM16.7634 9.49393H17.6368C17.8464 9.49393 17.9988 9.65153 17.9988 9.80329C17.9988 9.99854 17.8013 10.1127
          17.6481 10.1127H16.7634V9.49393V9.49393Z"
          fill="#016FD0"
        />
        <path d="M19.86 11.775H19.0798V8.83606H19.86V11.775Z" fill="#016FD0" />
        <path
          d="M21.71 11.775H21.5416C20.7268 11.775 20.2321 11.1577 20.2321 10.3176C20.2321 9.45674 20.7212 8.83606 21.7502 8.83606H22.5948V9.53211H21.7194C21.3016 9.53211
          21.0062 9.84556 21.0062 10.3248C21.0062 10.894 21.344 11.133 21.8307 11.133H22.0317L21.71 11.775Z"
          fill="#016FD0"
        />
        <path
          d="M23.3727 8.83655L22.0581 11.7755H22.914L23.1565 11.1869H24.5666L24.8079 11.7755H25.6826L24.3693 8.83655H23.3727ZM23.859 9.52052L24.2889 10.5489H23.428L23.859 9.52052Z"
          fill="#016FD0"
        />
        <path
          d="M25.7719 11.775V8.83606H26.7433L27.9838 10.6825V8.83606H28.7479V11.775H27.8078L26.536 9.88014V11.775H25.7719Z"
          fill="#016FD0"
        />
        <path
          d="M10.5699 16.918V13.9791H13.0634V14.6365H11.3421V15.1392H13.0232V15.7579H11.3421V16.28H13.0634V16.918H10.5699Z"
          fill="#016FD0"
        />
        <path
          d="M22.7877 16.918V13.9791H25.2811V14.6365H23.5599V15.1392H25.2329V15.7579H23.5599V16.28H25.2811V16.918H22.7877Z"
          fill="#016FD0"
        />
        <path
          d="M13.1601 16.918L14.3742 15.4667L13.1312 13.9791H14.0939L14.8341 14.8987L15.5769 13.9791H16.5019L15.2753 15.4486L16.4915 16.918H15.529L14.8103 16.0129L14.109 16.918H13.1601Z"
          fill="#016FD0"
        />
        <path
          d="M16.5824 13.9796V16.9185H17.3666V15.9904H18.1709C18.8515 15.9904 19.3674 15.6433 19.3674 14.9681C19.3674 14.4088 18.9628 13.9796 18.2702 13.9796H16.5824V13.9796ZM17.3666
          14.6443H18.2137C18.4335 14.6443 18.5907 14.7738 18.5907 14.9826C18.5907 15.1788 18.4343 15.321 18.2112 15.321H17.3666V14.6443Z"
          fill="#016FD0"
        />
        <path
          d="M19.6991 13.9791V16.918H20.4632V15.8739H20.7849L21.7011 16.918H22.6349L21.6295 15.8353C22.0421 15.8018 22.4678 15.4613 22.4678 14.9326C22.4678 14.3141 21.9629 13.9791
          21.3995 13.9791H19.6991V13.9791ZM20.4632 14.6365H21.3367C21.5462 14.6365 21.6986 14.7941 21.6986 14.9459C21.6986 15.1411 21.5011 15.2552 21.348 15.2552H20.4632V14.6365V14.6365Z"
          fill="#016FD0"
        />
        <path
          d="M25.6351 16.918V16.28H27.1643C27.3906 16.28 27.4886 16.1624 27.4886 16.0335C27.4886 15.9099 27.3909 15.785 27.1643 15.785H26.4733C25.8726 15.785 25.5381 15.4331 25.5381
          14.9048C25.5381 14.4336 25.8444 13.9791 26.737 13.9791H28.2251L27.9033 14.6404H26.6164C26.3704 14.6404 26.2947 14.7645 26.2947 14.883C26.2947 15.0049 26.3882 15.1392 26.5762
          15.1392H27.3001C27.9697 15.1392 28.2603 15.5044 28.2603 15.9827C28.2603 16.4969 27.9365 16.918 27.2636 16.918H25.6351Z"
          fill="#016FD0"
        />
        <path
          d="M28.4396 16.918V16.28H29.9688C30.1951 16.28 30.293 16.1624 30.293 16.0335C30.293 15.9099 30.1954 15.785 29.9688 15.785H29.2777C28.6771 15.785 28.3425 15.4331 28.3425
          14.9048C28.3425 14.4336 28.6489 13.9791 29.5415 13.9791H31.0295L30.7078 14.6404H29.4208C29.1748 14.6404 29.0991 14.7645 29.0991 14.883C29.0991 15.0049 29.1927 15.1392
          29.3806 15.1392H30.1045C30.7741 15.1392 31.0647 15.5044 31.0647 15.9827C31.0647 16.4969 30.7409 16.918 30.0681 16.918H28.4396Z"
          fill="#016FD0"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clipAmex">
        <rect
          width="36.1843"
          height="25"
          fill="white"
          transform="translate(0.368652)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgAmex;
