import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { ConciliationDownloadRequest } from "../../../../types/conciliation_download_request";
import { getDownloadLink, setLoadStatus } from "../../../store/actionCreators";
import { LoadStatusEnum } from "../../../shared/infrastructure/LoadStatus";

export interface IUseDownloadFile {
  loadStatus: LoadStatusEnum;
  handleClick: (request: ConciliationDownloadRequest) => void;
  handleCloseSnackbar: () => void;
}

export const getLoadStatus = (state: IAppState) =>
  state.loadStatus || LoadStatusEnum.NONE;

export const useDownloadFile = (): IUseDownloadFile => {
  const dispatch = useDispatch();
  const loadStatus: LoadStatusEnum = useSelector(getLoadStatus);
  const handleCloseSnackbar = () => {
    dispatch(setLoadStatus(LoadStatusEnum.NONE));
  };

  const handleClick = (request: ConciliationDownloadRequest) => {
    dispatch(getDownloadLink(request));
  };

  return {
    loadStatus,
    handleClick,
    handleCloseSnackbar,
  };
};
