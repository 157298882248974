import React, { FC } from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import { WALLET_TITLE } from "../../shared/constants/labels/main_containter_labels";
import Buttons from "../../shared/constants/labels/buttons_labels";
import { IWallet } from "./Wallet.interfaces";
import { walletStyles as styles } from "./Wallet.styles";
import { WalletBalanceForm } from "../WalletBalanceForm/WalletBalanceForm";
import { defaultTo } from "lodash";
import { useWalletBalanceForm } from "../WalletBalanceForm/state/useWalletBalanceForm";
import { OptionsEnum } from "../../shared/constants/modal_data_text";
import { ModalLoader, ModalSimple } from "@kushki/connect-ui";
import { ModalType } from "../../shared/enums/modal_type";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { ComponentsSecurity } from "../../shared/enums/SecurityWrappersEnum";

const Wallet: FC<IWallet> = ({
  currency,
  publicMerchantId,
  transactionType,
}: IWallet) => {
  const { modalConfirm, modalLoader, closeAlert, form, onSubmit } =
    useWalletBalanceForm({ currency, publicMerchantId, transactionType });

  return (
    <Box sx={styles.boxContainer}>
      <Typography color="text.primary" variant="h3">
        {WALLET_TITLE[transactionType]}
      </Typography>
      <Paper elevation={2} sx={styles.boxFormPaper}>
        <WalletBalanceForm
          form={form}
          currency={defaultTo(currency, "")}
          publicMerchantId={defaultTo(publicMerchantId, "")}
          transactionType={transactionType}
          closeAlert={closeAlert}
        />
      </Paper>
      <Box sx={styles.boxButtons}>
        <ComponentSecurityWrapper componentId={ComponentsSecurity.DEBIT_CREDIT}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={onSubmit}
          >
            {Buttons.ACCEPT_BUTTON_TEXT[transactionType]}
          </Button>
        </ComponentSecurityWrapper>
        <Button
          size="small"
          variant="contained"
          color="secondary"
          onClick={() => window.history.go(-1)}
        >
          {Buttons.CANCEL_BUTTON_TEXT}
        </Button>
      </Box>
      <ModalSimple
        onClickPrimary={modalConfirm.handleConfirmModal}
        onClickSecondary={modalConfirm.handleCloseModal}
        buttonPrimary={OptionsEnum.CONFIRM}
        buttonSecondary={OptionsEnum.CANCEL}
        descriptionText={modalConfirm.description}
        isOpen={modalConfirm.openConfirmModal}
        onClose={modalConfirm.handleCloseModal}
        titleText={modalConfirm.title}
        typeModal={ModalType.TYPE01}
        buttonPrimaryDisabled={false}
      />
      <ModalLoader
        descriptionText={modalLoader.description}
        titleText={modalLoader.title}
        isOpen={modalLoader.openLoadingModal}
      />
    </Box>
  );
};

export default Wallet;
