import { makeStyles } from "@material-ui/core/styles";

export const uploadFileDispersionStyles = makeStyles({
  title: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#293036",
    marginBottom: "35px",
  },
  processButton: {
    textAlign: "center",
    height: "44px",
    width: "127px",
    marginRight: "60px",
    marginTop: "35px",
    fontSize: "16px",
  },
  processButtonDisabled: {
    textAlign: "center",
    height: "44px",
    width: "127px",
    marginRight: "60px",
    marginTop: "35px",
    fontSize: "16px",
    opacity: "55%",
  },
  secondGrid: {
    marginTop: "35px",
  },
});
