import { CustomStyles } from "../../../shared/interfaces/CustomStyles";

export const modalBoardMembersStyles: CustomStyles = {
  container: {
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: 24,
    left: "50%",
    padding: "2.5rem",
    position: "absolute" as "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "90%", md: "850px" },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem",
  },

  closeModal: {
    boxShadow:
      "0px 10px 80px rgba(106, 167, 197, 0.1), 0px 5.0625px 34.875px rgba(106, 167, 197, 0.05), 0px 2px 13px rgba(106, 167, 197, 0.03), 0px 0.4375px 4.625px rgba(106, 167, 197, 0.02)",
    borderRadius: "50%",
  },
  accordionContainer: {
    height: "400px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
};
