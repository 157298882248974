import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      marginBottom: "24px",
    },
    subtitle: {
      color: "#6D7781",
      padding: "24px 0",
      paddingTop: "0",
    },
    selectLabel: {
      paddingLeft: "20px",
    },
    actions: {
      paddingTop: "16px",
      paddingRight: "24px",
      paddingBottom: "24px",
    },
    btnAdd: {
      padding: "10px 40px",
      backgroundColor: "#023365",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#023365",
        color: "#fff",
      },
      "&:disabled": {
        backgroundColor: "#FAFAFA",
      },
    },
    btnChangeOption: {
      padding: "10px 40px",
      color: "#293036",
      "&:hover": {
        backgroundColor: "#fff",
        color: "#293036",
      },
      "&:disabled": {
        backgroundColor: "#FAFAFA",
      },
    },
    error: {
      color: "#fe3206",
      paddingLeft: "8px",
      fontSize: "10px",
    },
  })
);

export default useStyles;
