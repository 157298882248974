import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { CountryValueEnum } from "../../../shared/infrastructure/Enums/CountryEnum";
import { ButtonOptionRetentions } from "../ButtonOptionRetentions/ButtonOptionRetentions";
import { useHistory } from "react-router-dom";
import { capitalize, get, includes } from "lodash";
import { routes } from "../../../shared/infrastructure/routes";
import { ISubTitlePageProps } from "./SubTitlePage.interfaces";
import { useSubTitlePageStyles } from "./SubTitlePage.styles";

export const SubTitlePage: React.FC<ISubTitlePageProps> = (
  props: ISubTitlePageProps
) => {
  const history = useHistory();
  const { country, isCatalogTab } = props;
  const classes = useSubTitlePageStyles();

  return (
    <Grid
      className={
        includes([CountryValueEnum.Ecuador], capitalize(country!))
          ? classes.root
          : classes.rootCo
      }
      container
      justifyContent={"center"}
      alignItems={"flex-start"}
      direction={"row"}
    >
      <Grid item xs={8}>
        {includes([CountryValueEnum.Colombia], capitalize(country!)) && (
          <Typography
            style={{ fontStyle: "25px" }}
            id={`${country}_title`}
            variant={"h6"}
            color={"textSecondary"}
          >
            Para actualizar todas las configuraciones de retenciones ICA realiza
            nuevamente la carga del archivo. <br />
            <b>
              Recuerda que el archivo cargado sustituirá las configuraciones
              anteriores.
            </b>
          </Typography>
        )}
        {includes([CountryValueEnum.Ecuador], capitalize(country!)) && (
          <Typography
            variant={"h5"}
            color={"textSecondary"}
            id={`${country}_title_catalog_${isCatalogTab}`}
          >
            {isCatalogTab ? (
              <>
                Edita o agrega las opciones de los catálogos de impuesto y
                retenciones <b>subiendo un archivo .xlsx</b> o de manera manual
                llenando un formulario
              </>
            ) : (
              <>
                Edita o agrega impuestos y retenciones{" "}
                <b>subiendo un archivo .csv </b> (sin límite de registros) o de
                manera manual <b>llenando un formulario</b> (máximo 20 registros
                por carga).
              </>
            )}
          </Typography>
        )}
      </Grid>
      {includes([CountryValueEnum.Ecuador], capitalize(country!)) && (
        <ButtonOptionRetentions
          country={get(props, "country", "") as CountryValueEnum}
          isCatalogTab={isCatalogTab}
        />
      )}
      <Grid item xs={4} className={classes.btnContain}>
        {includes([CountryValueEnum.Colombia], capitalize(country!)) && (
          <Grid item className={`${get(classes, "btnContain", "")}`}>
            <Button
              id={"btnContain"}
              fullWidth
              className={classes.btnChildCol}
              onClick={() => {
                history.push(
                  `${routes.BASE_PATH_RETENTIONS}${routes.WITH_CSV}?country=${country}`
                );
              }}
            >
              <Typography variant="h6">Actualizar configuraciones</Typography>
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
