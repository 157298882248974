import { Box, Typography } from "@mui/material";
import React, { FC } from "react";
import ShareholdersForm from "../ShareholdersForm/ShareholdersForm";
import { IShareholdersProps } from "./Shareholders.interfaces";
import { shareholdersStyles } from "./Shareholders.styles";

const Shareholders: FC<IShareholdersProps> = (props: IShareholdersProps) => {
  return (
    <Box sx={shareholdersStyles.boxContainer}>
      <Box sx={shareholdersStyles.boxTitle}>
        <Typography sx={shareholdersStyles.title}>
          Accionistas / Socios
        </Typography>
        <Typography sx={shareholdersStyles.subtitle}>
          Ingresa los datos de Accionistas y socios
        </Typography>
      </Box>
      <Box sx={shareholdersStyles.boxContainerForm}>
        <ShareholdersForm {...props} />
      </Box>
    </Box>
  );
};

export default Shareholders;
