/**
 * TransactionStatus
 */
export enum TransactionStatusEnum {
  INITIALIZED = "INITIALIZED",
  ON_REVISION = "REVIEW",
  WON = "WON",
  DOCUMENTATION_SENT = "DOCSENT",
  LOST = "LOST",
  EXPIRED = "EXPIRED",
  FAILED = "FAILED",
  APPROVAL = "APPROVAL",
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  PENDING = "PENDING",
}

export enum TransactionStatusLabelEnum {
  INITIALIZED = "Iniciado",
  ON_REVISION = "En revisión",
  WON = "Ganado",
  DOCUMENTATION_SENT = "Documentación enviada",
  LOST = "Perdido",
  EXPIRED = "Vencido",
  FAILED = "Fallido",
  APPROVAL = "Ganado",
  APPROVED = "Ganado",
  DECLINED = "Perdido",
  PENDING = "Pendiente",
}
export const CATALOG_TRANSACTION_STATUS: Record<
  TransactionStatusEnum,
  TransactionStatusLabelEnum
> = {
  [TransactionStatusEnum.DECLINED]: TransactionStatusLabelEnum.DECLINED,
  [TransactionStatusEnum.ON_REVISION]: TransactionStatusLabelEnum.ON_REVISION,
  [TransactionStatusEnum.INITIALIZED]: TransactionStatusLabelEnum.INITIALIZED,
  [TransactionStatusEnum.APPROVAL]: TransactionStatusLabelEnum.APPROVAL,
  [TransactionStatusEnum.APPROVED]: TransactionStatusLabelEnum.APPROVED,
  [TransactionStatusEnum.WON]: TransactionStatusLabelEnum.WON,
  [TransactionStatusEnum.DOCUMENTATION_SENT]:
    TransactionStatusLabelEnum.DOCUMENTATION_SENT,
  [TransactionStatusEnum.LOST]: TransactionStatusLabelEnum.LOST,
  [TransactionStatusEnum.EXPIRED]: TransactionStatusLabelEnum.EXPIRED,
  [TransactionStatusEnum.FAILED]: TransactionStatusLabelEnum.FAILED,
  [TransactionStatusEnum.PENDING]: TransactionStatusLabelEnum.PENDING,
};
