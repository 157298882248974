import { IStyles } from "../../../../../../../shared/interfaces/Styles.interfaces";

export const styles: IStyles = {
  informationContainer: {
    width: "100%",
  },
  paymentInfoContainer: {
    paddingTop: "20px",
  },
  text: {
    color: "text.grey",
    paddingBottom: "4px",
    typography: "caption",
  },
  value: {
    color: "text.dark",
    typography: "caption",
  },
};
