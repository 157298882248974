import { ItemCategoryItem, ItemCategoryProps } from "@kushki/connect-ui";
import { cloneDeep } from "lodash";
import { filterCountries } from "@shared/constants/categoryItems";
import { FilterStatusLabel } from "@shared/constants/AlarmTableConstants";
import { ActiveAlarmStatus } from "@shared/enum/ActiveAlarmStatusEnum";
import {
  FieldOptionValue,
  VariableLabels,
} from "@shared/constants/AlarmConfigConstants";
import { FilterTitle } from "@shared/enum/FilterLabelEnum";
import { buildCountriesFullName } from "@shared/utils/filterUtils";
import { MerchantCategoryEnum } from "@shared/enum/merchantCategoryEnum";

export interface IFilterAlarmItems {
  status: ItemCategoryItem[];
  statusHistory: ItemCategoryItem[];
  country: ItemCategoryItem[];
  variables: ItemCategoryItem[];
  countryFullName: ItemCategoryItem[];
  category: ItemCategoryItem[];
}

const filterAlarmItems: IFilterAlarmItems = {
  category: [
    {
      label: MerchantCategoryEnum.SMALL,
      selected: false,
      value: MerchantCategoryEnum.SMALL,
    },
    {
      label: MerchantCategoryEnum.MEDIUM,
      selected: false,
      value: MerchantCategoryEnum.MEDIUM,
    },
    {
      label: MerchantCategoryEnum.ENTERPRISE,
      selected: false,
      value: MerchantCategoryEnum.ENTERPRISE,
    },
  ],
  country: cloneDeep(filterCountries),
  countryFullName: cloneDeep(buildCountriesFullName()),
  status: [
    {
      label: FilterStatusLabel.PENDING,
      selected: false,
      value: ActiveAlarmStatus.PENDING,
    },
    {
      label: FilterStatusLabel.INVESTIGATION,
      selected: false,
      value: ActiveAlarmStatus.INVESTIGATION,
    },
  ],
  statusHistory: [
    {
      label: FilterStatusLabel.PENDING,
      selected: false,
      value: ActiveAlarmStatus.PENDING,
    },
    {
      label: FilterStatusLabel.ROS,
      selected: false,
      value: ActiveAlarmStatus.ROS,
    },
    {
      label: FilterStatusLabel.INVESTIGATION,
      selected: false,
      value: ActiveAlarmStatus.INVESTIGATION,
    },
    {
      label: FilterStatusLabel.NORMAL,
      selected: false,
      value: ActiveAlarmStatus.NORMAL,
    },
  ],
  variables: [
    {
      label: VariableLabels.PEP,
      selected: false,
      value: FieldOptionValue.PEP,
    },
    {
      label: VariableLabels.TPV,
      selected: false,
      value: FieldOptionValue.TPV,
    },

    {
      label: VariableLabels.MCC,
      selected: false,
      value: FieldOptionValue.MCC,
    },
    {
      label: VariableLabels.MODEL,
      selected: false,
      value: FieldOptionValue.MODEL,
    },
    {
      label: VariableLabels.AVERAGE_TICKET,
      selected: false,
      value: FieldOptionValue.AVERAGE_TICKET,
    },
    {
      label: VariableLabels.CONSTITUTION_COUNTRY,
      selected: false,
      value: FieldOptionValue.CONSTITUTIONAL_COUNTRY,
    },
    {
      label: VariableLabels.CATEGORY,
      selected: false,
      value: FieldOptionValue.CATEGORY,
    },
    {
      label: VariableLabels.CONSTITUTIONAL_DATE,
      selected: false,
      value: FieldOptionValue.CONSTITUTION_DATE,
    },
    {
      label: VariableLabels.STANDARD_DEVIATION,
      selected: false,
      value: FieldOptionValue.STANDARD_DEVIATION,
    },
  ],
};

export const alarmsFilterCategoryItems: ItemCategoryProps[] = [
  {
    isDisabled: false,
    isMinimize: true,
    items: cloneDeep(filterAlarmItems.status),
    onItemsSelect: () => {},
    placeHolder: FilterTitle.Status,
    selectType: "multiple",
    type: "byChips",
  },
  {
    isDisabled: false,
    isMinimize: true,
    items: cloneDeep(filterAlarmItems.country),
    onItemsSelect: () => {},
    placeHolder: FilterTitle.CountryAlarm,
    selectType: "multiple",
    type: "byChips",
  },
  {
    isDisabled: false,
    isMinimize: true,
    items: cloneDeep(filterAlarmItems.variables),
    onItemsSelect: () => {},
    placeHolder: FilterTitle.Variables,
    selectType: "multiple",
    type: "byChips",
  },
];

export const alarmsFilterHistoryItems: ItemCategoryProps[] = [
  {
    isDisabled: false,
    isMinimize: true,
    items: filterAlarmItems.statusHistory,
    onItemsSelect: () => {},
    placeHolder: FilterTitle.Status,
    selectType: "multiple",
    type: "byChips",
  },
  {
    isDisabled: false,
    isMinimize: true,
    items: cloneDeep(filterCountries),
    onItemsSelect: () => {},
    placeHolder: FilterTitle.CountryAlarm,
    selectType: "multiple",
    type: "byChips",
  },

  {
    isDisabled: false,
    isMinimize: true,
    items: filterAlarmItems.countryFullName,
    onItemsSelect: () => {},
    onlyPlaceholderInput: true,
    persistInputText: true,
    placeHolder: FilterTitle.Select_Constitutional_Country,
    selectType: "multiple",
    title: FilterTitle.Constitutional_Country_History,
    type: "bySelectCheck",
    useFilteredSelection: true,
  },
  {
    isDisabled: false,
    isMinimize: true,
    items: filterAlarmItems.category,
    onItemsSelect: () => {},
    placeHolder: FilterTitle.Category_History,
    selectType: "multiple",
    type: "byChips",
  },
  {
    isDisabled: false,
    isMinimize: true,
    items: cloneDeep(filterAlarmItems.variables),
    onItemsSelect: () => {},
    placeHolder: FilterTitle.Variables,
    selectType: "multiple",
    type: "byChips",
  },
];
