import React from "react";
import { MerchantCreateUpdateResponse } from "../../../../types/merchant_create_update_response";
import { GridPaper } from "../../common/GridPaper/GridPaper";
import {
  Chip,
  createStyles,
  Grid,
  IconButton,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Edit } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";
import {
  navigateRoute,
  StatusSemaphore,
} from "../../../shared/infrastructure/constants/CreateMerchantConstants";
import { LANGUAGE_CATALOG } from "../../../shared/infrastructure/constants/LanguageCatalog";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { StatusSteps } from "../../../../types/remote/semaphore_data";
import { CountryEnum } from "../../../shared/infrastructure/catalogs/CountryEnum";
import { INVOICE_MODE_CATALOG } from "../../../shared/infrastructure/constants/InvoiceModeCatalog";

export interface InvoiceConfigResumeProps {
  merchant: MerchantCreateUpdateResponse;
  keepFraud: boolean;
  status: StatusSteps;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      backgroundColor: "rgba(240, 244, 249, 1)",
      color: theme.palette.text.primary,
      borderRadius: "5px",
    },
    button: {
      backgroundColor: "rgba(240, 244, 249, 1)",
      marginLeft: theme.spacing(1),
      borderRadius: "5px",
      color: theme.palette.text.primary,
    },
  })
);

export const InvoiceConfigResume: React.FC<
  Partial<InvoiceConfigResumeProps>
> = (_: Partial<InvoiceConfigResumeProps>) => {
  const classes = useStyles();
  const {
    merchant,
    keepFraud,
    status,
  }: Partial<InvoiceConfigResumeProps> = useSelector((state: IAppState) => ({
    merchant: get(state, "merchantAffiliation", undefined),
    keepFraud: state.rate?.keepFraud,
    status: get(
      state,
      "semaphore.stepConfigRatesAndInvoice.statusInvoice",
      undefined
    ),
  }));

  const shouldDisplayResume = (status: StatusSteps) => {
    return [StatusSemaphore.inProcess, StatusSemaphore.complete].includes(
      get(StatusSemaphore, status, StatusSemaphore.pending)
    );
  };

  if (!shouldDisplayResume(status!)) {
    return null;
  }

  const CustomLabel = ({ label }: Record<string, any>) => (
    <Grid item xs={3}>
      <Typography variant={"body2"} color={"textSecondary"}>
        {label}
      </Typography>
    </Grid>
  );

  const CustomChip = ({ label }: Record<string, any>) => (
    <Grid item xs={9}>
      <Chip label={label} className={classes.chip} size={"small"} />
    </Grid>
  );

  const redirectConfiguration = () => {
    const publicMerchantId: string = get(merchant, "publicMerchantId", "");

    if (publicMerchantId)
      navigateRoute(
        `/billing-merchant/charge/${publicMerchantId}?hideSideBar=true`
      );
  };

  const getFrequency: (frequency?: string) => string = (frequency?: string) => {
    if (!frequency) return "";

    return get(LANGUAGE_CATALOG, `frequency.${frequency.trim()}`, "");
  };

  const getInvoiceMode = (invoiceMode: string) => {
    return get(INVOICE_MODE_CATALOG, `invoiceMode.${invoiceMode.trim()}`, "");
  };

  const getFraud: (keepFraud?: boolean) => string = (keepFraud?: boolean) => {
    if (keepFraud === undefined) return "";

    return keepFraud ? "Sí" : "No";
  };
  return (
    <GridPaper direction={"row"}>
      <Grid item xs={12}>
        <Typography
          variant={"subtitle1"}
          color={"textPrimary"}
          display={"inline"}
        >
          Facturación, cobro y dispersión
        </Typography>
        <Tooltip title={"Editar"}>
          <IconButton
            onClick={redirectConfiguration}
            size={"small"}
            className={classes.button}
          >
            <Edit />
          </IconButton>
        </Tooltip>
      </Grid>
      <CustomLabel label={"Frecuencia de facturación:"} />
      <CustomChip label={getFrequency(get(merchant, "invoiceFrequency"))} />
      {get(merchant, "country", "") === CountryEnum.MEXICO &&
        get(merchant, "invoiceMode") && (
          <>
            <CustomLabel label={"Modalidad de facturación:"} />
            <CustomChip label={getInvoiceMode(get(merchant, "invoiceMode"))} />
          </>
        )}
      <CustomLabel label={"Frecuencia de cobro:"} />
      <CustomChip label={getFrequency(get(merchant, "chargeFrequency"))} />
      <CustomLabel label={"Frecuencia de dispersión:"} />
      <CustomChip label={getFrequency(get(merchant, "dispersionFrequency"))} />
      <CustomLabel label={"Frecuencia de fraude:"} />
      <CustomChip label={getFraud(keepFraud)} />
    </GridPaper>
  );
};
