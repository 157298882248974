import { IGeneralState } from "@store/interfaces/GeneralState.interfaces";
import { createSlice } from "@reduxjs/toolkit";
import { MainPrefix } from "@shared/constants/thunkPrefixes";
import { getMerchantsCore } from "@store/thunks/general/general.thunk";

export const generalState: IGeneralState = {
  dataSearchMerchant: {
    data: [],
    total: 0,
  },
  notification: null,
  timezoneProps: {
    regionTz: "America/Guayaquil",
    utcTimeZone: "-05:00",
  },
};

export const generalSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getMerchantsCore.fulfilled, (state, action) => {
      state[action.payload.nameVariable] = action.payload.data;
    });
  },
  initialState: generalState,
  name: MainPrefix.GENERAL,
  reducers: {
    setDataSearch: (state, { payload }) => {
      state.dataSearchMerchant = payload;
    },
    setNotification: (state, { payload }) => {
      state.notification = payload;
    },
    setTimezoneProps: (state, { payload }) => {
      state.timezoneProps = payload;
    },
  },
});

export default generalSlice.reducer;

export const { setNotification, setDataSearch, setTimezoneProps } =
  generalSlice.actions;
