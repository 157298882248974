import React, { FC } from "react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { ModalHeaderStyles } from "./ModalDetailInfoHeader.styles";
import Chip from "@mui/material/Chip";
import { IModalDetailInfoHeader } from "./ModalDetailInfoHeader.interfaces";

const ModalDetailInfoHeader: FC<IModalDetailInfoHeader> = (
  props: IModalDetailInfoHeader
) => {
  const { tags, secondaryText, title, subtitle } = props;

  return (
    <Box sx={ModalHeaderStyles.boxContainer} data-testid={"headerContainer"}>
      <Box sx={ModalHeaderStyles.boxTitle}>
        <Typography
          pr={2}
          color={"primary"}
          variant={"h3"}
          sx={ModalHeaderStyles.tooltipText}
        >
          {title}
        </Typography>

        {subtitle && (
          <Box display="flex" gap={1} alignItems="center">
            <Typography variant={"body2"} color={"primary"}>
              {subtitle}
            </Typography>
          </Box>
        )}
        {secondaryText && (
          <Typography variant={"overline"} color={"text.grey"}>
            {secondaryText}
          </Typography>
        )}
      </Box>
      <Box sx={ModalHeaderStyles.boxTitle}>
        <Typography pr={2} color={"primary"} variant={"h3"}>
          {tags &&
            tags.map((item, index) => (
              <Chip key={index} label={item.text} color={item.color} />
            ))}
        </Typography>
      </Box>
    </Box>
  );
};

export { ModalDetailInfoHeader };
