import { ActionTypes } from "./actionTypes";
import { IAppAction } from "./actionCreators";
import { INotification } from "../shared/infrastructure/interfaces/INotification";
import { MerchantResponse } from "../../types/merchant_response";
import { IAuthInfo } from "../shared/infrastructure/interfaces/IAuthInfo";
import { DeferredOption } from "../../types/deferred_options";
import { GetNodeInfoResponse } from "../../types/get_node_info_response";

export interface IDeferredState {
  waitingForRequest?: boolean;
  notification?: INotification;
  showFinalModal?: boolean;
  tableSize?: number;
  currentPage?: number;
  totalRecords?: number;
  node010Value?: string;
  nodeError?: boolean;
  localDeferredOptions?: DeferredOption[];
  isLoading?: boolean;
  openDialog?: boolean;
  disableSave: boolean;
  snackbarAlert?: boolean;
  origin?: string;
}

export const DEFERRED_INITIAL_STATE: IDeferredState = {
  disableSave: false,
  isLoading: false,
  localDeferredOptions: [],
  node010Value: "",
  nodeError: false,
  notification: {
    message: "",
    open: false,
    type: "success",
  },
};

export interface IAppState {
  isDeferredLoaded: boolean;
  notification?: INotification;
  loading?: boolean;
  merchant?: MerchantResponse;
  authInfo?: IAuthInfo;
  merchantDeferred?: DeferredOption[];
  merchantDeferredOriginal?: DeferredOption[];
  errorChangeStatus?: {
    status: boolean;
    id: string;
  };
  canceledChangeStatus?: {
    status: boolean;
    id: string;
  };
  nodeInfoMerchant?: GetNodeInfoResponse;
}

export const INITIAL_STATE: IAppState = {
  canceledChangeStatus: {
    id: "",
    status: false,
  },
  errorChangeStatus: {
    id: "",
    status: false,
  },
  isDeferredLoaded: false,
  loading: false,
  merchant: {
    accountNumber: "",
    accountType: "0",
    address: "",
    bankId: "",
    chargeCurrency: "USD",
    chargeFrequency: "daily",
    chargeMin: false,
    chargeMinAmount: 0,
    city: "",
    contactPerson: "",
    country: "Ecuador",
    documentType: "",
    email: "",
    invoiceFrequency: "threeWeekly",
    name: "",
    phoneNumber: "",
    province: "",
    publicMerchantId: "",
    socialReason: "",
    taxId: "",
    webSite: "",
    zipCode: "",
  },
  merchantDeferred: [],
  merchantDeferredOriginal: [],
  notification: {
    message: "",
    open: false,
    type: "success",
  },
};

export const reducer = (
  state: IAppState = INITIAL_STATE,
  action: IAppAction
): IAppState => {
  switch (action.type) {
    case ActionTypes.SET_MERCHANT:
      return {
        ...state,
        merchant: action.merchant,
      };
    case ActionTypes.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.notification,
      };
    case ActionTypes.SHOW_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.HIDE_LOADING:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.SET_MERCHANT_DEFERRED:
      return {
        ...state,
        merchantDeferred: action.merchantDeferred,
      };
    case ActionTypes.SET_IS_DEFERRED_LOADED:
      return {
        ...state,
        isDeferredLoaded: action.isDeferredLoaded!,
      };
    case ActionTypes.SET_ERROR_CHANGE_STATUS:
      return {
        ...state,
        errorChangeStatus: action.errorChangeStatus!,
      };
    case ActionTypes.SET_NODE_INFO:
      return {
        ...state,
        nodeInfoMerchant: action.nodeInfoMerchant,
      };
    case ActionTypes.SET_CANCELED_CHANGE_STATUS:
      return {
        ...state,
        canceledChangeStatus: action.canceledChangeStatus!,
      };
    case ActionTypes.SET_MERCHANT_ORIGINAL_DEFERRED:
      return {
        ...state,
        merchantDeferredOriginal: action.merchantDeferredOriginal,
      };
    default:
      return state;
  }
};
