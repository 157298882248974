import TransactionTable from "../../components/TransactionTable/TransactionTable";
import { useTransactionTableState } from "../../components/TransactionTable/state/useTransactionTableState";
import {
  ICatalog,
  TimeZoneCatalog,
} from "../../shared/infrastructure/enums/time-zone-catalogues";
import { get, isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store.interfaces";
import { Conciliation } from "../../../types/get_conciliation_transactions_response";
import {
  ITransactionListProps,
  payload,
} from "./TransactionConciliationList.interfaces";
import React, { useEffect } from "react";

const TransactionConciliationList = (
  props: ITransactionListProps
): JSX.Element => {
  const _timeZoneDefault: string = "America/Guayaquil";

  const timeZoneHour = (text: string | undefined, value: string): string => {
    let index: number;

    if (isEmpty(text)) return value === "utc" ? "-05:00" : _timeZoneDefault;

    index = TimeZoneCatalog.findIndex(
      (data: ICatalog) => data.description === text
    );

    if (index >= 0) {
      const lang: string | undefined = get(
        TimeZoneCatalog[index],
        value,
        undefined
      );

      if (!isEmpty(lang)) return lang!;
    }

    return value === "utc" ? "-05:00" : _timeZoneDefault;
  };

  const getColumnsUser = (): payload => {
    return {
      timeZone: timeZoneHour(localStorage.getItem("timeZone")!, "utc"),
      timeZoneRegion: timeZoneHour(localStorage.getItem("timeZone")!, "region"),
    };
  };

  const user_profile: payload = getColumnsUser();
  let conciliationList: Conciliation[] = useSelector(
    (state: RootState) => state.app.conciliationList
  );

  const {
    state,
    selectedRows,
    isSelected,
    handleSelectAll,
    handleClickRow,
    handleOpenSelectedTransactionModal,
    SetAccumulatedAmount,
    setSelected,
  } = useTransactionTableState({
    conciliationList,
    setConciliationListAux: props.setConciliationListAux,
    setConciliationSelectModal: props.setConciliationSelectModal,
    setIsRefundButtonDisabled: props.setIsRefundButtonDisabled,
    setIsSkipButtonDisabled: props.setIsSkipButtonDisabled,
    setIsTooltipDisabled: props.setIsTooltipDisabled,
    setOpenSelectedTransactionModal: props.setOpenSelectedTransactionModal,
    setSelectedRows: props.setSelectedRows,
    setSelectedTransactionModal: props.setSelectedTransactionModal,
    setSelectRowsSkipModal: props.setSelectRowsSkipModal,
    setSlopesCounter: props.setSlopesCounter,
  });

  useEffect(() => {
    setSelected([]);
    SetAccumulatedAmount(0);
  }, [props.country]);

  return (
    <>
      <TransactionTable
        dialogActions={props.dialogActions!}
        currentTab={props.currentTab}
        conciliationList={conciliationList}
        headerColumns={[...state.headerColumns]}
        selectedColumns={props.selectedColumns}
        timeZoneRegion={user_profile.timeZoneRegion}
        selectedRows={selectedRows}
        isSelected={isSelected}
        handleClickRow={handleClickRow}
        handleSelectAll={handleSelectAll}
        handleOpenSelectedTransactionModal={handleOpenSelectedTransactionModal}
      />
    </>
  );
};

export default TransactionConciliationList;
