import { IOptionFilter } from "../../../components/common/BoxFilter/BoxFilter";
import { FilterTypeEnum } from "../FilterTypeEnum";
import { CountryNameEnum, CountryValueEnum } from "../constants/CountrysEnum";
import { SizeNameEnum, SizeValueEnum } from "../constants/SizeEnum";
import {
  AuthenticatorNameEnum,
  AuthenticatorValueEnum,
} from "../constants/AuthenticatorEnum";
import { StatusEnum, StatusNameEnum } from "../enums/StatusEnum";

export const DefaultFilter: IOptionFilter[] = [
  {
    id: FilterTypeEnum.country,
    title: "País",
    options: [
      {
        key: CountryValueEnum.Ecuador,
        label: CountryNameEnum.Ecuador,
        selected: false,
      },
      {
        key: CountryValueEnum.Chile,
        label: CountryNameEnum.Chile,
        selected: false,
      },
      {
        key: CountryValueEnum.Colombia,
        label: CountryNameEnum.Colombia,
        selected: false,
      },
      {
        key: CountryValueEnum.México,
        label: CountryNameEnum.México,
        selected: false,
      },
      {
        key: CountryValueEnum.Perú,
        label: CountryNameEnum.Perú,
        selected: false,
      },
    ],
  },
  {
    id: FilterTypeEnum.size,
    title: "Tamaño",
    options: [
      {
        key: SizeValueEnum.Entrepreneur,
        label: SizeNameEnum.Entrepreneur,
        selected: false,
      },
      {
        key: SizeValueEnum.Mediana,
        label: SizeNameEnum.Mediana,
        selected: false,
      },
      {
        key: SizeValueEnum.Grande,
        label: SizeNameEnum.Grande,
        selected: false,
      },
    ],
  },
  {
    id: FilterTypeEnum.authenticator,
    title: "Autenticador",
    options: [
      {
        key: AuthenticatorValueEnum.D3S,
        label: AuthenticatorNameEnum.D3S,
        selected: false,
      },
      {
        key: AuthenticatorValueEnum.OTP,
        label: AuthenticatorNameEnum.OTP,
        selected: false,
      },
    ],
  },
  {
    id: FilterTypeEnum.status,
    title: "Estado",
    options: [
      {
        key: StatusEnum.PENDING,
        label: StatusNameEnum.PENDING,
        selected: false,
      },
      {
        key: StatusEnum.SENT,
        label: StatusNameEnum.SENT,
        selected: false,
      },
      {
        key: StatusEnum.REJECT,
        label: StatusNameEnum.REJECT,
        selected: false,
      },
      {
        key: StatusEnum.IN_PROCESS,
        label: StatusNameEnum.IN_PROCESS,
        selected: false,
      },
    ],
  },
];
