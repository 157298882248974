import { ActionTypes } from "./actionTypes";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { IAppState, INotification } from "./reducer";
import { RoleService } from "../services/RoleService";
import { IRoleInfo } from "../infrastructure/interfaces/IRoleInfo";
import { IMerchantForm } from "../infrastructure/interfaces/IMerchantForm";
import { environment } from "../environments/environment";
import axios from "../shared/axios-util";
import { databaseRef } from "../shared/firebase";
import { IStatusSessionSupport } from "../infrastructure/interfaces/IStatusSessionSupport";
import { SessionService } from "../services/SessionService";
import { defaultTo, findIndex, get, isEmpty, isEqual, set } from "lodash";
import { HmacSHA256, WordArray } from "crypto-js";
import { AxiosResponse } from "axios";
import { MerchantProcessors } from "../../types/merchant_processors";
import { PAYMENT_METHODS_ENUM } from "../infrastructure/constants/PaymentMethodEnum";
import { IKushkiMenu } from "../infrastructure/interfaces/IKushkiMenu";
import { routes } from "../infrastructure/constants/Routes";
import { LOCALSTORAGE } from "../infrastructure/constants/LocalStorage_Enum";
import { MENU_BACKOFFICE_USER_MASTER } from "../infrastructure/constants/KushkiMenu";
export type IAppAction = { type: string } & IAppState;
export const setRoleInfo = (payload: IRoleInfo): IAppAction => {
  return {
    type: ActionTypes.SET_ROLE_INFO,
    roleInfo: payload,
  };
};

interface IServicesInfo {
  has_smartlinks: boolean;
  has_cash: boolean;
  has_card: boolean;
  has_transfer: boolean;
  has_payouts: boolean;
}

export const setMerchant = (payload: IMerchantForm | null): IAppAction => {
  return {
    type: ActionTypes.SET_MERCHANT,
    merchant: payload,
  };
};

export const setActiveRoute = (payload: string): IAppAction => {
  return {
    type: ActionTypes.SET_ACTIVE_ROUTE,
    activeRoute: payload,
  };
};

export const setAppLoaded = (): IAppAction => {
  return {
    type: ActionTypes.SET_APP_LOADED,
  };
};

export const setNotification = (notify: INotification): IAppAction => {
  return {
    type: ActionTypes.SET_NOTIFICATION,
    notification: notify,
  };
};

export const initializeAppInfo = (): ThunkAction<
  void,
  IAppState,
  undefined,
  IAppAction
> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    dispatch(setActiveRoute(window.location.pathname.replace(/\/$/, "")));
    const roleInfo = await RoleService.getRoleInfo();
    dispatch(setRoleInfo(roleInfo));
    window.localStorage.setItem("isAdmin", `${roleInfo.isAdmin}`);
    window.localStorage.setItem("isComplianceUser", `${roleInfo.isCompliance}`);

    const merchantId = RoleService._getMerchantId();

    if (merchantId !== "" && !(roleInfo.isAdmin || roleInfo.isCompliance)) {
      const merchant = await RoleService.getMerchantInfo(environment.envName);
      const walletMenuIndex: number = findIndex(
        roleInfo.kushkiMenu,
        (menuItem: IKushkiMenu) =>
          menuItem.title === MENU_BACKOFFICE_USER_MASTER[7].title
      );
      if (walletMenuIndex >= 0 && isConsoleV3()) {
        set(
          roleInfo.kushkiMenu,
          `[${walletMenuIndex}].link`,
          `${routes.CONSOLE_WALLET_DASHBOARD}?publicMerchantId=${merchant.publicMerchantId}`
        );
        dispatch(setRoleInfo(roleInfo));
      }
      dispatch(setMerchant(merchant));
      RoleService.setTimeZone(merchant.country);
      if (environment.envName === "primary" || environment.envName === "uat") {
        if (!localStorage.getItem("servicesInfo")) {
          await dispatch(
            getServicesInfo(get(merchant, "publicMerchantId", ""))
          );
        }

        zendeskCallback();
      }
    } else {
      RoleService.setTimeZone("Ecuador");
    }

    dispatch(setAppLoaded());
  };
};

const zendeskCallback = () => {
  (function () {
    var w = window;
    var d = document;
    var l = function () {
      var s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.id = "ze-snippet";
      s.src =
        "https://static.zdassets.com/ekr/snippet.js?key=75f661db-d01a-49e4-997f-fe7805507548";
      var x = d.getElementsByTagName("script")[0];
      // @ts-ignore
      x.parentNode.insertBefore(s, x);
    };

    // @ts-ignore
    if (w.attachEvent) {
      // @ts-ignore
      w.attachEvent("onload", l);
    } else {
      l();
    }
  })();
};

export const setMerchantInfo = (
  merchant: IMerchantForm | null
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setMerchant(merchant));
  };
};

const getServicesInfo = (
  publicMerchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (): Promise<void> => {
    let servicesInfo: IServicesInfo = {
      has_smartlinks: false,
      has_cash: false,
      has_card: false,
      has_transfer: false,
      has_payouts: false,
    };
    try {
      const response: AxiosResponse<any> = await axios.get(
        `${environment.kushkiUrl}/smartlink/v1/admin/merchant/${publicMerchantId}`
      );
      const url: string = `${environment.kushkiUrl}/merchant/v1/admin/merchant/${publicMerchantId}/processors`;
      const processors: AxiosResponse<MerchantProcessors[]> = await axios.get(
        url
      );
      const has_smartlinks = defaultTo(response.data.status, false);
      servicesInfo = {
        ...servicesInfo,
        has_cash: hasPaymentMethod(processors.data, PAYMENT_METHODS_ENUM.CASH),
        has_card: hasPaymentMethod(processors.data, PAYMENT_METHODS_ENUM.CARD),
        has_transfer: hasPaymentMethod(
          processors.data,
          PAYMENT_METHODS_ENUM.TRANSFER
        ),
        has_payouts: hasPaymentMethod(
          processors.data,
          PAYMENT_METHODS_ENUM.PAYOUTS
        ),
        has_smartlinks,
      };
      localStorage.setItem("servicesInfo", JSON.stringify(servicesInfo));
    } catch (e) {}
  };
};

export const logoutSupportSessionRequest = (
  data: object,
  closeBackoffice?: boolean
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    axios
      .put(`${environment.kushkiUrl}/authorizer/v1/closeSessionSupport`, data)
      .then(() => {
        SessionService.removeSessionSupport();
        window.location.reload();
      })
      .catch((err) => {
        console.log("error al salir de la sesión de soporte", err);
        dispatch(
          setNotification({
            show: true,
            message: "Error",
            type: "error",
          })
        );
      })
      .finally(() => {
        if (closeBackoffice) RoleService.logout();
      });
  };
};

export const statusCodeSupport = (
  payload: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    databaseRef
      .child(`/${environment.envName}/authorizer/session/${payload}`)
      .on("value", (snapshot) => {
        const status: IStatusSessionSupport | undefined = snapshot.val();
        if (status !== undefined) {
          dispatch({
            type: ActionTypes.SET_STATUS_SESSION_VALUES,
            statusSession: snapshot.val(),
          });
        }
      });
  };
};

export const setHover = (
  link: string | null
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>,
    getState: () => IAppState
  ): void => {
    dispatch(
      setRoleInfo({
        ...getState().roleInfo!,
        kushkiMenu: getState().roleInfo!.kushkiMenu.map((kushkiMenu) => ({
          ...kushkiMenu,
          hover: kushkiMenu.link === link,
        })),
      })
    );
  };
};

const hasPaymentMethod = (
  processors: MerchantProcessors[],
  paymentMethod: PAYMENT_METHODS_ENUM
) => {
  const processorWithPaymentMethod = processors.filter(
    (processor) => get(processor, "paymentMethod", "") === paymentMethod
  );
  return processorWithPaymentMethod.length > 0;
};

const isConsoleV3 = (): boolean => {
  const merchantBasicInformation: object = JSON.parse(
    defaultTo(
      localStorage.getItem(LOCALSTORAGE.MERCHANT_BASIC_INFORMATION),
      "{}"
    )
  );
  const nodeId: string = get(merchantBasicInformation, "nodeId", "");

  return !isEmpty(nodeId) && !isEqual(nodeId, "N/A");
};
