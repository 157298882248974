import { isEqual } from "lodash";
import { TimeZoneByCountry } from "../infrastructure/constants/CountriesEnum";

export const calculateDateUtcNumber = (
  date: Date,
  isStart: boolean
): number => {
  return Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    isStart ? 0 : 23,
    isStart ? 0 : 59,
    isStart ? 0 : 59
  );
};

export const calculateDateUtc = (
  dateTimestamp: number,
  isStart: boolean
): number => {
  const date = new Date(dateTimestamp);

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    isStart ? 0 : 23,
    isStart ? 0 : 59,
    isStart ? 0 : 59
  ).getTime();
};

export const convertTZ = (date: Date, tzString: string): Date => {
  return new Date(date.toLocaleString("en-US", { timeZone: tzString }));
};

export const isEnabledStatus = (
  merchantCountry: string,
  startDate: Date
): boolean => {
  const now_date_in_timezone: Date = convertTZ(
    new Date(),
    TimeZoneByCountry[merchantCountry]
  );

  const date_str_server: string =
    now_date_in_timezone.getFullYear() +
    "/" +
    now_date_in_timezone.getMonth() +
    "/" +
    now_date_in_timezone.getDate();

  const now_date_in_timezone_item: Date = new Date(startDate);

  const date_str: string =
    now_date_in_timezone_item.getFullYear() +
    "/" +
    now_date_in_timezone_item.getMonth() +
    "/" +
    now_date_in_timezone_item.getDate();

  return isEqual(date_str, date_str_server);
};
