import { useState } from "react";
import { BreadcrumProps } from "../../../components/common/Breadcrumb/Breadcrumb";
import { BreadCrumsItems } from "../../../shared/infrastructure/constants/BreadCrums";
import { useLocation } from "react-router-dom";
import { CountryValueEnum } from "../../../shared/infrastructure/Enums/CountryEnum";
import { getCountry } from "../../../shared/infrastructure/constants/MerchantContactConstants";
export interface IRetentionsIndexState {
  state: IState;
  handlers: {};
  breadCrumbs: BreadcrumProps;
  country: CountryValueEnum | null;
}

export interface IState {}
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const useRetentionsIndexState = (): IRetentionsIndexState => {
  const [state] = useState<IState>({});
  const query = useQuery();
  const country: CountryValueEnum = query.get("country") as CountryValueEnum;
  return {
    state,
    handlers: {},
    country,
    breadCrumbs: {
      items: [
        BreadCrumsItems.GlobalConfig,
        { ...BreadCrumsItems.Country, label: getCountry(country).name },
      ],
      lastItem:
        BreadCrumsItems.RetentionsMerchant.label[getCountry(country).value],
    },
  };
};
