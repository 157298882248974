import { IconPlus } from "@kushki/connect-ui";
import { DeleteOutline } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React, { FC } from "react";
import { Box, Grid } from "@mui/material";
import { BRANCH_LABELS } from "../../shared/constants/labels/branch_container_labels";
import {
  ADD_BRANCH,
  CANCEL_TEXT,
  CONTINUE_TEXT,
  DESCRIPTION_MODAL_CONFIRM,
  MODAL_CONFIRM,
} from "../../shared/constants/labels/buttons_labels";
import { TooltipTitleEnums } from "../../shared/enums/TooltipTitleEnums";
import FooterOptions from "../FooterOptions/FooterOptions";
import { branchManualCreationFormStyles as styles } from "./BranchManualCreationForm.styles";
import { useBranchManualCreation } from "./state/useBranchManualCreation";
import { HeaderTable } from "../HeaderTable/HeaderTable";
import { TableSimple } from "../Table/TableSimple/TableSimple";
import { InfoCentralizedTooltipComponent } from "../InfoCentralizedTooltip/InfoCentralizedTooltipComponent";
import {
  CENTRALIZE,
  DESCENTRALIZE,
} from "../../shared/constants/array_option_confirm_data";
import {
  LOADER_CREATE_BRANCHES_DESCRIPTION,
  LOADER_CREATE_BRANCHES_TITLE,
} from "../../shared/constants/labels/branches_labels";
import { ModalLoader } from "../ModalLoader/ModalLoader";
import { IConfirmModal } from "../../shared/interfaces/confirmModal.interfaces";
import { ModalConfirm } from "../ModalConfirm/ModalConfirm";
import { TEXTS_BRANCH_MANUAL } from "../../shared/constants/labels/branch_manual_creation_labels";
import {
  BRANCH_NAME,
  CLIENT_TYPE_LABEL,
  COUNTRY_CONSTITUTION,
  DEFAULT,
} from "../../shared/constants/labels/table_labels";
import { INFORMATION_CUSTOMER_LABELS } from "../../shared/constants/labels/information_customer_container_labels";
import ModalActiveFilter from "../ModalActiveFilter/ModalActiveFilter";
import { TableBodyCellEnum } from "../Table/TableSimple/constants";
import { INITIAL_CREATE_FILTERS } from "../../shared/constants/initial_filters_side_bar";
import { WARMING_CHANGE_CONSTITUTION_COUNTRY_TITLE } from "../../shared/constants/snackbar_messages";
import ModalErrorBranches from "../ModalErrorBranches/ModalErrorBranches";

const BranchManualCreationForm: FC = () => {
  const {
    handleOnSearch,
    handleOnFilter,
    footerActions,
    getRowValues,
    itemsPerPage,
    onItemsPerPageChange,
    onPageClick,
    rows,
    selectedPage,
    total,
    handleDeleteRowHook,
    openConfirmModal,
    showConfirmModal,
    saveBranches,
    openLoader,
    filterCountries,
    addRowInitialValues,
    addButtonDisable,
    totalItems,
    showAlert,
    showAlertAction,
    modalActiveFilter,
    setCountFilters,
    customerInfo,
    isInitialLoading,
    openModalError,
    handleCloseModalError,
  } = useBranchManualCreation();
  const CELL_HEADER_SPACING = 1;

  const contentModal: IConfirmModal = {
    hasContent: true,
    options: [
      {
        name: CENTRALIZE,
        value: Number(total.centralize),
      },
      {
        name: DESCENTRALIZE,
        value: Number(total.descentralize),
      },
    ],
    subtitle: MODAL_CONFIRM.TOTAL_BRANCH(total.total),
  };

  return (
    <>
      <Box sx={styles.boxContentTable}>
        <Grid container>
          <Grid item xs={12}>
            <HeaderTable
              initialFiltersSideBars={INITIAL_CREATE_FILTERS}
              setCountFilters={setCountFilters}
              disableEditButton={addButtonDisable}
              handleBtn={addRowInitialValues}
              total={total}
              filterCountries={filterCountries}
              handleOnSearch={handleOnSearch}
              handleOnFilter={handleOnFilter}
              hasToolTipHeader={true}
              iconBtn={<IconPlus />}
              title={BRANCH_LABELS.CREATION_TITLE}
              titleBtn={ADD_BRANCH}
              textValueAlert={WARMING_CHANGE_CONSTITUTION_COUNTRY_TITLE}
              subtitle={BRANCH_LABELS.CREATION_INFORMATION}
              additionalAlertMessage={BRANCH_LABELS.ADVISEMENT}
              showAlert={showAlert}
              showAlertAction={showAlertAction}
            />
          </Grid>
          <Grid item xs={12} sx={styles.contentTable}>
            <Box sx={styles.boxContentAddBranch}>
              <TableSimple
                allowDeletion
                allowSelection
                getRowValues={getRowValues}
                isInitialLoading={isInitialLoading}
                header={{
                  cells: [
                    {
                      align: "left",
                      spacing: CELL_HEADER_SPACING,
                      text: BRANCH_NAME,
                      type: DEFAULT,
                    },
                    {
                      align: "center",
                      component: (
                        <InfoCentralizedTooltipComponent
                          icon={<InfoOutlinedIcon />}
                          title={TooltipTitleEnums.CONSTITUTION_COUNTRY}
                          placement={"top"}
                        />
                      ),
                      text: COUNTRY_CONSTITUTION,
                      type: DEFAULT,
                    },
                    {
                      align: "center",
                      spacing: CELL_HEADER_SPACING,
                      text: CLIENT_TYPE_LABEL,
                      type: DEFAULT,
                    },
                  ],
                  headerProps: {
                    isDisable: false,
                    labelCheck: "Seleccionar Todo",
                  },
                }}
                deletionProps={{
                  handleDeleteRow: (rowId: number) => {
                    handleDeleteRowHook(rowId);
                  },
                  icon: <DeleteOutline />,
                  isDisabled: false,
                  variant: "inherit",
                }}
                footerPagination={{
                  isDisable: false,
                  itemsPerPage,
                  onItemsPerPageChange,
                  onPageClick,
                  paginationText: TEXTS_BRANCH_MANUAL.TRANSACTION_PER_PAGE,
                  selectedPage,
                  textFieldSelector: [10, 20, 50],
                  totalItems: totalItems,
                }}
                rows={rows}
                showPagination={rows.length > 0}
                typesCellsToBlock={[TableBodyCellEnum.SELECT_ITEM]}
                valuesCellsToBlock={[
                  {
                    defaultValue: customerInfo.constitutionalCountry,
                    id: "country",
                  },
                  {
                    defaultValue: customerInfo.clientType,
                    id: "client",
                  },
                ]}
              />
            </Box>
          </Grid>
        </Grid>
        <Box sx={styles.boxConfirmModal}>
          <ModalLoader
            descriptionText={LOADER_CREATE_BRANCHES_DESCRIPTION}
            titleText={LOADER_CREATE_BRANCHES_TITLE}
            isOpen={openLoader}
          />
        </Box>
        <Box sx={styles.boxConfirmModal}>
          <ModalConfirm
            data-testid={"ModalConfirm"}
            onClickPrimary={() => {
              saveBranches();
              showConfirmModal(false);
            }}
            onClickSecondary={() => {
              showConfirmModal(false);
            }}
            buttonPrimary={CONTINUE_TEXT}
            buttonSecondary={CANCEL_TEXT}
            descriptionText={DESCRIPTION_MODAL_CONFIRM}
            isOpen={openConfirmModal}
            onClose={() => {
              showConfirmModal(false);
            }}
            titleText={MODAL_CONFIRM.TITLE_MODAL_CONFIRM(total.total)}
            contentModal={contentModal}
          />
        </Box>
      </Box>
      <Box sx={styles.boxFooter}>
        <FooterOptions
          primaryButton={footerActions.primaryButton}
          secondaryButton={footerActions.secondaryButton}
          isLoading={footerActions.saveLoader}
          textBtn={INFORMATION_CUSTOMER_LABELS.BUTTON_CONTINUE}
          isValidate={false}
        />
      </Box>
      <ModalActiveFilter {...modalActiveFilter} />
      <ModalErrorBranches
        isOpen={openModalError}
        handleCloseModalError={handleCloseModalError}
      />
    </>
  );
};

export default BranchManualCreationForm;
