import React from "react";
import { Box, Theme, Typography } from "@material-ui/core";
import notDeferred from "../../assets/images/logos/notDeferred.svg";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  contactText: {
    color: "#46525C",
    textAlign: "center",
  },
  containerComponent: {
    paddingTop: `${theme.spacing(75 / 8)}px`,
  },
  containerContent: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    maxWidth: `${theme.spacing(339 / 8)}px`,
  },
  containerImage: {
    paddingBottom: `${theme.spacing(2)}px`,
    paddingTop: `${theme.spacing(2)}px`,
  },
  emailText: {
    color: "#6d7780",
    textDecoration: "underline",
  },
  grayTitle: {
    color: "#6D7781",
    fontFamily: "IBM Plex Sans,sans-serif",
    fontSize: 13,
    fontStyle: "normal",
    fontWeight: "normal",
    textAlign: "center",
    textTransform: "uppercase",
  },
  title: {
    color: "#023365",
    fontFamily: "IBM Plex Sans,sans-serif",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 500,
    letterSpacing: "0.0015em",
    lineHeight: "120%",
    paddingLeft: `${theme.spacing(1)}px`,
    paddingRight: `${theme.spacing(1)}px`,
    textAlign: "center",
  },
}));

export const NotDeferred: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.containerComponent}>
      <Box className={classes.containerContent}>
        <Typography className={classes.grayTitle}>
          No tienes diferidos disponibles
        </Typography>
        <Typography className={classes.title}>
          Para mayor información contacta a tu ejecutivo comercial o soporte
        </Typography>
        <Box className={classes.containerImage}>
          <img src={notDeferred} alt={"notDeferred-image"} />
        </Box>
        <Box>
          <Typography className={classes.contactText}>
            Contacto:
            <Typography variant={"inherit"} className={classes.emailText}>
              {" "}
              soporte@kushkipagos.com
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
