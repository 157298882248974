import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export interface SkeletonWebhookListProps {
  limit: number;
}

export const SkeletonWebhookList: React.FC<SkeletonWebhookListProps> = (
  props: SkeletonWebhookListProps
) => {
  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {[...Array(props.limit)].map((key: number, index: number) => (
              <TableRow key={`row_${index}`}>
                <TableCell
                  key={`cell1_${index}`}
                  component={"th"}
                  scope={"row"}
                >
                  <Skeleton
                    key={`skt1_${index}`}
                    variant={"text"}
                    animation={"wave"}
                  />
                </TableCell>
                <TableCell
                  key={`cell2_${index}`}
                  component={"th"}
                  scope={"row"}
                >
                  <Skeleton
                    key={`skt2_${key}`}
                    variant={"text"}
                    animation={"wave"}
                  />
                </TableCell>
                <TableCell
                  key={`cell3_${index}`}
                  component={"th"}
                  scope={"row"}
                >
                  <Skeleton
                    key={`skt3_${index}`}
                    variant={"text"}
                    animation={"wave"}
                  />
                </TableCell>
                <TableCell
                  key={`cell4_${index}`}
                  component={"th"}
                  scope={"row"}
                >
                  <Skeleton
                    key={`skt4_${key}`}
                    variant={"text"}
                    animation={"wave"}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};
