import React from "react";
import { IAppState, INotification } from "../../store/reducer";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import { BillingSection } from "../../components/BillingSection/BillingSection";
import { PaymentSection } from "../../components/PaymentSection/PaymentSection";
import { ParameterizationSection } from "../../components/ParameterizationSection/ParameterizationSection";
import { DispersionSection } from "../../components/DispersionSection/DispersionSection";
import { connect } from "react-redux";
import { useBillingIndexState } from "./state/useBillingIndexState";
import { BillingEditSection } from "../../components/BillingEditSection/BillingEditSection";
import { MerchantResponse } from "../../../types/merchant_response";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  IAppAction,
  setNotification,
  updateDispersion,
  updateMerchantAndBillingConfig,
} from "../../store/actionCreators";
import { BillingConfig } from "../../../types/billing_config";
import { DispersionEditSection } from "../../components/DispersionEditSection/DispersionEditSection";
import { routes } from "../../shared/infrastructure/constants/routes";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { SkeletonInformation } from "../../components/Skeletons/SkeletonInformation/SkeletonInformation";
import NotificationSnackbar from "../../components/Notification/NotificationSnackbar";

export interface BillingIndexStateProps {
  state: IAppState;
}
export interface BillingIndexFunctionProps {
  updateMerchantAndBillingConfig: (
    merchant: MerchantResponse,
    billingConfig: BillingConfig
  ) => void;
  updateDispersion: (payload: MerchantResponse) => void;
  setNotification: (notify: INotification) => IAppAction;
}
export type BillingIndexProps = BillingIndexStateProps &
  BillingIndexFunctionProps;

export const BillingIndex: React.FC<BillingIndexProps> = (
  props: BillingIndexProps
) => {
  const {
    prop,
    merchant,
    billingConfig,
    merchantCountryWithDispersion,
    handler,
    form,
  } = useBillingIndexState(props);

  return (
    <>
      <Container>
        <NotificationSnackbar
          msg={props.state.notification!.message}
          open={props.state.notification!.open}
          type={props.state.notification!.type}
          handlerClose={handler.close}
        />
        <Box pb={2}>
          <Breadcrumb
            items={[
              { label: "Comercios", url: routes.MERCHANT_LIST },
              {
                label: "Configuración",
                url: `${routes.INDEX}/merchant/${merchant!.publicMerchantId}`,
              },
            ]}
            lastItem={"Facturación y Cobro"}
          />
        </Box>
        <Grid container spacing={5}>
          <Grid
            item
            xs={6}
            md={6}
            container
            alignItems="center"
            justify="flex-start"
            direction="row"
          >
            <Typography
              color={"primary"}
              variant={"h3"}
              style={{
                fontWeight: 300,
              }}
            >
              Facturación y Cobro
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent style={{ padding: "32px" }}>
                {!prop.isEditBilling ? (
                  <Grid container spacing={5}>
                    <Grid item xs={12}>
                      {prop.isLoading ? (
                        <SkeletonInformation />
                      ) : (
                        <BillingSection
                          merchant={merchant}
                          onClickEdit={handler.editBilling}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {prop.isLoading ? (
                        <SkeletonInformation />
                      ) : (
                        <PaymentSection merchant={merchant} />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {prop.isLoading ? (
                        <SkeletonInformation />
                      ) : (
                        <ParameterizationSection
                          billingConfig={billingConfig}
                        />
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <form>
                    <BillingEditSection
                      register={form.register}
                      errors={form.errors}
                      control={form.control}
                      merchant={merchant}
                      billingConfig={billingConfig}
                      handleFormChange={form.formChange}
                    />
                  </form>
                )}
              </CardContent>
            </Card>
          </Grid>
          {!prop.isEditBilling && merchantCountryWithDispersion() ? (
            <Grid item xs={12}>
              <Card>
                <CardContent style={{ padding: "32px" }}>
                  {!prop.isEditDispersion ? (
                    <DispersionSection
                      isLoading={prop.isLoading}
                      merchant={merchant}
                      onClickEdit={handler.editDispersion}
                    />
                  ) : (
                    <DispersionEditSection
                      addAccount={prop.addAccount}
                      onAddAccount={handler.onAddAccount}
                      register={form.register}
                      errors={form.errors}
                      merchant={merchant}
                      handleFormChange={form.formChange}
                      control={form.control}
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Grid container justify={"flex-start"} spacing={2}>
              <Grid item xs={12} md={2}>
                <Button
                  size="large"
                  fullWidth
                  onClick={handler.onPreview}
                  color="default"
                >
                  Regresar
                </Button>
              </Grid>
              <Grid item xs={12} md={2}>
                {prop.isEditBilling || prop.isEditDispersion ? (
                  <Button
                    size="large"
                    fullWidth
                    variant="contained"
                    onClick={
                      prop.isEditBilling
                        ? form.submitBilling
                        : form.submitDispersion
                    }
                    color="primary"
                  >
                    Guardar
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export const mapStateToProps: (state: IAppState) => BillingIndexStateProps = (
  state: IAppState
): BillingIndexStateProps => ({
  state: state,
});
export const mapDispatchToProps: (
  dispatch: Dispatch
) => BillingIndexFunctionProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): BillingIndexFunctionProps => ({
  updateMerchantAndBillingConfig: (
    merchant: MerchantResponse,
    billingConfig: BillingConfig
  ): void => dispatch(updateMerchantAndBillingConfig(merchant, billingConfig)),
  updateDispersion: (payload: MerchantResponse): void =>
    dispatch(updateDispersion(payload)),
  setNotification: (notify: INotification): IAppAction =>
    dispatch(setNotification(notify)),
});
export default connect(mapStateToProps, mapDispatchToProps)(BillingIndex);
