import * as React from "react";
import {
  Box,
  SvgIcon as MuiSvgIcon,
  styled,
  SvgIconProps,
} from "@mui/material";

const SvgIcon = styled(MuiSvgIcon, {
  name: "MopeimIcon",
  shouldForwardProp: (prop) => prop !== "fill",
})<SvgIconProps>(({ color, fontSize }) => ({
  fill: color + "!important",
  fontSize: fontSize,
}));

SvgIcon.defaultProps = {
  "aria-hidden": "true",
  focusable: "false",
  viewBox: "0 0 24 24",
};

const FlagDefault: React.FunctionComponent<SvgIconProps> = (props) => {
  return (
    <Box data-testId="flag-default">
      <SvgIcon
        {...props}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11.7062 10.8866H4.29292C3.91383 10.8866 3.53846 10.812 3.18824 10.6669C2.83801 10.5218 2.51979 10.3092 2.25174 10.0411C1.71038 9.49979 1.40625 8.76555 1.40625 7.99996V3.55329C1.40625 2.7877 1.71038 2.05347 2.25174 1.51211C2.79309 0.970756 3.52732 0.666626 4.29292 0.666626H11.7062C12.0853 0.666626 12.4607 0.741292 12.8109 0.88636C13.1612 1.03143 13.4794 1.24406 13.7474 1.51211C14.0155 1.78016 14.2281 2.09839 14.3732 2.44861C14.5182 2.79884 14.5929 3.17421 14.5929 3.55329V7.99996C14.5929 8.37904 14.5182 8.75441 14.3732 9.10464C14.2281 9.45487 14.0155 9.77309 13.7474 10.0411C13.4794 10.3092 13.1612 10.5218 12.8109 10.6669C12.4607 10.812 12.0853 10.8866 11.7062 10.8866ZM4.29292 1.99996C3.88149 2.00171 3.48741 2.16593 3.19648 2.45686C2.90556 2.74779 2.74134 3.14186 2.73958 3.55329V7.99996C2.73958 8.41193 2.90324 8.80702 3.19454 9.09833C3.48585 9.38964 3.88095 9.55329 4.29292 9.55329H11.7062C11.9102 9.55329 12.1122 9.51311 12.3007 9.43505C12.4891 9.35699 12.6604 9.24257 12.8046 9.09833C12.9489 8.95409 13.0633 8.78285 13.1413 8.59439C13.2194 8.40593 13.2596 8.20395 13.2596 7.99996V3.55329C13.2578 3.14186 13.0936 2.74779 12.8027 2.45686C12.5118 2.16593 12.1177 2.00171 11.7062 1.99996H4.29292Z" />
        <path d="M2.07292 15.3333C1.89611 15.3333 1.72654 15.2631 1.60151 15.1381C1.47649 15.0131 1.40625 14.8435 1.40625 14.6667V5.78001C1.40625 5.6032 1.47649 5.43363 1.60151 5.3086C1.72654 5.18358 1.89611 5.11334 2.07292 5.11334C2.24973 5.11334 2.4193 5.18358 2.54432 5.3086C2.66935 5.43363 2.73958 5.6032 2.73958 5.78001V14.6667C2.73958 14.8435 2.66935 15.0131 2.54432 15.1381C2.4193 15.2631 2.24973 15.3333 2.07292 15.3333Z" />
      </SvgIcon>
    </Box>
  );
};

export default FlagDefault;
