import React, { FC } from "react";
import {
  CardInformationProps,
  ContentCard,
  ContentTaxes,
  ICardTabsProps,
  ICardTabsTaxProps,
} from "./CardInformation.interfaces";
import { Box, IconButton, Typography } from "@mui/material";
import { CardInformationStyles as styles } from "./CardInformation.styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { hasIn } from "lodash";

export const CardInformation: FC<CardInformationProps> = (
  props: CardInformationProps
) => {
  const { data, taxSection, columnDirection } = props;

  return (
    <>
      {data.map((item: ContentCard, index: number) => {
        return (
          <CardTabs
            key={`k-${index}`}
            items={item.body}
            orientation="horizontal"
            title={item.title}
            columnDirection={columnDirection}
          />
        );
      })}
      {taxSection && <CardTax key={"taxes"} data={taxSection} />}
    </>
  );
};

const CardTabs: FC<ICardTabsProps> = (props: ICardTabsProps) => {
  return (
    <Box sx={styles.mainBox}>
      {props.title && (
        <Typography variant={"h6"} color={"text.dark"} sx={styles.title}>
          {props.title}
        </Typography>
      )}

      <Box sx={styles.c}>
        {props.items.map((item, index) => {
          return (
            <Box
              sx={
                !props.columnDirection
                  ? styles.descriptionVertical
                  : styles.descriptionVerticalColumn
              }
              key={index}
            >
              {item.label && (
                <Typography
                  color={"text.grey"}
                  sx={styles.descriptionLabelVertical}
                >
                  {item.label}
                  {":"}
                </Typography>
              )}

              {!item.isArray ? (
                <>
                  {item.text && (
                    <Typography
                      color={"text.dark"}
                      variant={"caption"}
                      sx={styles.descriptionTextVertical}
                    >
                      {item.text}
                    </Typography>
                  )}
                </>
              ) : (
                <Box sx={styles.itemsText}>
                  {item.itemText!.map((payment: string) => {
                    return (
                      <Typography
                        key={payment}
                        color={"text.dark"}
                        variant={"caption"}
                        sx={styles.descriptionTextVertical}
                      >
                        {payment}
                      </Typography>
                    );
                  })}
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export const CardTax: FC<ICardTabsTaxProps> = (props: ICardTabsTaxProps) => {
  return (
    <>
      <Box>
        <Typography
          variant={"h6"}
          color={"text.dark"}
          sx={styles.titleTaxes}
        ></Typography>
      </Box>
      {props.data.map((item: ContentTaxes, index: number) => (
        <Box sx={styles.mainBoxCard} key={`box-${index}`}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {item.title && (
              <Typography variant="h6" color="text.dark" sx={styles.titleTaxes}>
                {item.title}
              </Typography>
            )}
            {hasIn(item, "onSelected") && (
              <IconButton sx={{ marginLeft: "auto" }}>
                <ContentCopyIcon
                  data-testid="custom-icon"
                  onClick={() => item?.onSelected && item.onSelected()}
                />
              </IconButton>
            )}
          </Box>

          {item.body && <Typography sx={styles.tax}>{item.body}</Typography>}
        </Box>
      ))}
    </>
  );
};
