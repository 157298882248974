import React, { FC } from "react";
import { Box } from "@mui/material";
import { ModalLoader, Tab, Tabs } from "@kushki/connect-ui";
import {
  AlarmTabLabel,
  CommentSectionLabel,
} from "@shared/enum/AlarmDetailCardEnum";
import { useDetailCardBodyAlarms } from "@components/organism/DetailCardBodyAlarms/state/useDetailCardBodyAlarms";
import InformationTab from "@components/molecule/InformationTab/InformationTab";
import { detailCardBodyAlarmsStyles as styles } from "@components/organism/DetailCardBodyAlarms/DetailCardBodyAlarms.styles";
import CommentsTab from "@components/molecule/CommentsTab/CommentsTab";
import { IDetailCardBodyAlarms } from "@components/organism/DetailCardBodyAlarms/IDetailCardBodyAlarms.interfaces";
import TimelineTab from "@components/molecule/TimelineTab/TimelineTab";
import ModalDownloadFiles from "@components/molecule/ModalDownloadFiles/ModalDownloadFiles";
const DetailCardBodyAlarms: FC<IDetailCardBodyAlarms> = ({
  searchAlarmsRequest,
}: IDetailCardBodyAlarms) => {
  const {
    handleChangeTab,
    tabIndex,
    informationTab,
    commentsTab,
    isLoadingSetAlarmAction,
    timelineTab,
    modalFileProps,
  } = useDetailCardBodyAlarms({ searchAlarmsRequest });

  return (
    <Box sx={styles.container}>
      <Tabs
        onChange={handleChangeTab}
        orientation="horizontal"
        variant="fullWidth"
        valueTab={tabIndex}
      >
        <Tab
          colorTextSecondary="success"
          text={AlarmTabLabel.INFORMATION}
          variant="primary"
        />
        <Tab
          colorTextSecondary="success"
          text={AlarmTabLabel.TIMELINE}
          variant="primary"
        />
        <Tab
          colorTextSecondary="success"
          text={`${AlarmTabLabel.COMMENTS}(${commentsTab.comments.length})`}
          variant="primary"
        />
      </Tabs>
      <Box sx={styles.containerBody}>
        {tabIndex === 0 && <InformationTab {...informationTab} />}
        {tabIndex === 1 && <TimelineTab {...timelineTab} />}
        {tabIndex === 2 && <CommentsTab {...commentsTab} />}
      </Box>
      <ModalLoader
        isOpen={isLoadingSetAlarmAction}
        descriptionText={CommentSectionLabel.LOADER_DESCRIPTION}
        titleText={CommentSectionLabel.LOADER_TITLE}
      />
      <ModalDownloadFiles {...modalFileProps} />
    </Box>
  );
};

export default DetailCardBodyAlarms;
