import { TransactionInfo } from "../../../types/transactions_data";
import { ContentTaxes } from "../../components/CardInformation/CardInformation.interfaces";
import { jsonToString } from "./card_tabs_utils";
import { MetadataTitleEnum } from "../enums/MetadataTitleEnum";

export const showMetadataCredentials = (
  data: string,
  transactionInfo: TransactionInfo
): ContentTaxes[] => {
  const metadata_obj = {
    metadata: {},
  };

  const showCredentials: boolean =
    data !== JSON.stringify(metadata_obj, null, " ");

  let listMetadata: ContentTaxes[] = [
    {
      body: jsonToString(transactionInfo).metadata,
      title: MetadataTitleEnum.metadata_transaction,
    },
  ];

  if (showCredentials) {
    listMetadata.push({
      body: jsonToString(transactionInfo).metadataCredentials,
      title: MetadataTitleEnum.metadata_credentials,
    });
  }

  return listMetadata;
};
