import { IRootState } from "../../interfaces/RootState.interfaces";

export const selectSFilters = (state: IRootState) => state.filters;

export const selectGeneralFilter = (state: IRootState) => state.filters.general;

export const selectDateFilter = (state: IRootState) => state.filters.date;

export const selectPaginationFilter = (state: IRootState) =>
  state.filters.pagination;

export const selectSearchFilter = (state: IRootState) => state.filters.search;

export const selectAreThereFiltersSelected = ({ filters }: IRootState) =>
  filters.general.paymentMethod.length > 0 ||
  filters.general.transactionStatus.length > 0 ||
  filters.search.text !== "";

export const selectFiltersState = (state: IRootState) => state.filters;
