import React from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { FileUploadExecutivesStyles as styles } from "@components/organism/FileUploadExecutives/FileUploadExecutives.styles";
import { IconFileText } from "@kushki/connect-ui";
import { FileUploadExecutivesProps } from "@components/organism/FileUploadExecutives/FileUploadExecutives.interfaces";
import { FileUploadExecutiveLabel } from "@shared/enum/FileUploadExecutivesEnum";

const FileUpload: React.FC<FileUploadExecutivesProps> = ({
  handleDrag,
  handleDrop,
  handleChange,
  dragActive,
}: FileUploadExecutivesProps) => {
  return (
    <Box onDragEnter={handleDrag} sx={styles.fileUploadContainer}>
      <Box sx={styles.uploadContainer}>
        <Box sx={styles.dragUpload}>
          <IconFileText color={"disabled"} />
          <Typography sx={styles.textUpload}>
            {FileUploadExecutiveLabel.DRAG_FILE}
          </Typography>
        </Box>
        <Divider sx={styles.divider}>
          <Typography sx={styles.textUpload}>o</Typography>
        </Divider>
        <Button variant="outlined" component="label" color={"secondary"}>
          {FileUploadExecutiveLabel.SELECT_FILE}
          <input hidden accept=".xlsx" type="file" onChange={handleChange} />
        </Button>
        <Typography sx={styles.validFormat}>
          {FileUploadExecutiveLabel.VALID_FORMAT}
        </Typography>
      </Box>
      {dragActive && (
        <Box
          component="div"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          sx={styles.dragContainer}
        ></Box>
      )}
    </Box>
  );
};

export default FileUpload;
