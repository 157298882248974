import {
  IconCircleCross,
  IconDownload,
  IconFileText,
} from "@kushki/connect-ui";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import React, { FC } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { BRANCH_LABELS } from "../../shared/constants/labels/branch_container_labels";
import {
  LOADER_CHECKING_FILE_DESCRIPTION,
  LOADER_CHECKING_FILE_TITLE,
} from "../../shared/constants/labels/branches_labels";
import {
  CANCEL_TEXT,
  CONTINUE_TEXT,
  DESCRIPTION_MODAL_VALIDATE,
  MODAL_VALIDATE,
} from "../../shared/constants/labels/buttons_labels";
import { INFORMATION_CUSTOMER_LABELS } from "../../shared/constants/labels/information_customer_container_labels";
import { ComponentIdsEnum } from "../../shared/constants/labels/main_containter_labels";
import FooterOptions from "../FooterOptions/FooterOptions";
import { ModalLoader } from "../ModalLoader/ModalLoader";
import { ModalValidate } from "../ModalValidate/ModalValidate";
import {
  listBranchCreationFormStyles,
  listBranchCreationFormStyles as styles,
  useStyles,
} from "./ListBranchCreationForm.styles";
import { useListBranchCreation } from "./state/useListBranchCreation";
import { isEmpty } from "lodash";
import Lottie from "lottie-react";
import animationData from "../../assets/animation/loader.json";

const ListBranchCreationForm: FC = () => {
  const {
    allowContinueBranches,
    fileInfo,
    files,
    footerActions,
    isPrepareUpload,
    handleDeleteFileUploaded,
    openLoader,
    handleCaptureFile,
    messageError,
    openValidateModal,
    contentHeaderModalValidate,
    contentBodyModalValidate,
    handleContinueClickModal,
    handleCancelClickModal,
  } = useListBranchCreation();

  const { getRootProps, getInputProps } = useDropzone({
    accept: "text/*",
    onDrop: (acceptedFiles: any) => {
      handleCaptureFile(acceptedFiles[0]);
    },
  });

  const classes = useStyles();

  const messages: string[] = messageError
    .error!.split("|")
    .map((item: string) => item.trim());

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} sx={styles.boxContent}>
        <Typography sx={listBranchCreationFormStyles.title} color={"primary"}>
          {BRANCH_LABELS.CREATION_TITLE}
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <Box sx={styles.boxSection}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography sx={styles.labelStep} color={"primary"}>
                    {BRANCH_LABELS.STEP_1}
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Grid container>
                    <Grid item xs={5}>
                      <Typography sx={styles.labelDownloadFile}>
                        {BRANCH_LABELS.DOWNLOAD_FILE}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Link
                        href={BRANCH_LABELS.DOWNLOAD_FILE_LINK}
                        underline="none"
                        sx={styles.downloadFileNameArea}
                      >
                        <Grid container>
                          <Grid item xs={2}>
                            <IconDownload />
                          </Grid>
                          <Grid item xs={10}>
                            <Typography sx={styles.downloadFileName}>
                              {BRANCH_LABELS.DOWNLOAD_FILE_NAME}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={styles.labelSubtitle}>
                        {BRANCH_LABELS.STEP_1_INFORMATION}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={styles.boxSection}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography sx={styles.labelStep} color={"primary"}>
                    {BRANCH_LABELS.STEP_2}
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  {isEmpty(files) ? (
                    <Box sx={styles.boxTableBranch}>
                      <Grid
                        className="file-uploader"
                        sx={styles.uploadFilesSection}
                      >
                        <div {...getRootProps()}>
                          <input
                            data-testid="drop-input"
                            {...getInputProps()}
                          />
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            sx={styles.uploadFileAreaDrop}
                          >
                            <IconFileText />
                            <Typography
                              sx={listBranchCreationFormStyles.fileDropArea}
                              color={"primary"}
                            >
                              {BRANCH_LABELS.UPLOAD_AREA_TEXT}
                            </Typography>
                          </Box>
                        </div>
                      </Grid>
                      <Grid sx={styles.uploadFilesDivider}>
                        <Divider sx={styles.divider}>
                          <Typography
                            sx={listBranchCreationFormStyles.fileDropArea}
                            color={"primary"}
                          >
                            {"o"}
                          </Typography>
                        </Divider>
                      </Grid>
                      <Grid sx={styles.uploadFilesSection}>
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          sx={styles.uploadFileArea}
                        >
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <ComponentSecurityWrapper
                              componentId={
                                ComponentIdsEnum.M_CLIENTS_BRANCH_MANUAL_NUEVO
                              }
                            >
                              <Button
                                variant="outlined"
                                size="medium"
                                color="secondary"
                                sx={listBranchCreationFormStyles.nextButton}
                              >
                                {BRANCH_LABELS.UPLOAD_FILE}
                              </Button>
                            </ComponentSecurityWrapper>
                          </div>
                          <Typography
                            sx={listBranchCreationFormStyles.subtitle_error}
                            color={"red"}
                          >
                            {messages.length === 1 ? (
                              <div>{messages[0]}</div>
                            ) : (
                              <List>
                                {messages.map((message, i) => {
                                  return (
                                    <ListItem
                                      key={i}
                                      sx={listBranchCreationFormStyles.item}
                                    >
                                      {message}
                                    </ListItem>
                                  );
                                })}
                              </List>
                            )}
                          </Typography>
                          <Typography
                            sx={listBranchCreationFormStyles.subtitle_error}
                            color={"red"}
                          >
                            {messageError.advisement}
                          </Typography>
                          <Box sx={listBranchCreationFormStyles.labelContainer}>
                            <Typography
                              sx={listBranchCreationFormStyles.subtitle}
                              color={"primary"}
                            >
                              {BRANCH_LABELS.INSTRUCTIONS}
                            </Typography>
                            <Typography
                              sx={listBranchCreationFormStyles.subtitle}
                              color={"primary"}
                            >
                              {BRANCH_LABELS.ADVISEMENT}
                            </Typography>
                            <Typography
                              sx={listBranchCreationFormStyles.subtitle}
                              color={"primary"}
                            >
                              {BRANCH_LABELS.NAME}
                            </Typography>
                            <Typography
                              sx={listBranchCreationFormStyles.subtitle}
                              color={"primary"}
                            >
                              {BRANCH_LABELS.CLIENT_TYPE}
                            </Typography>{" "}
                            <Typography
                              sx={listBranchCreationFormStyles.subtitle}
                              color={"primary"}
                            >
                              {BRANCH_LABELS.DESCENTRALIZE}
                            </Typography>
                            <Typography
                              sx={listBranchCreationFormStyles.subtitle}
                              color={"primary"}
                            >
                              {BRANCH_LABELS.CONSTITUCIONAL_COUNTRY}
                            </Typography>
                            <Typography
                              sx={listBranchCreationFormStyles.fileFormat}
                              color={"primary"}
                            >
                              {BRANCH_LABELS.FORMAT_FILE}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Box>
                  ) : !isEmpty(files) && isPrepareUpload ? (
                    <Box sx={styles.boxTableBranch}>
                      <Grid sx={styles.uploadFilesSection}>
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          sx={styles.uploadFileArea}
                        >
                          <Lottie
                            animationData={animationData}
                            autoPlay={true}
                            loop={true}
                            className={classes.lottie}
                            rendererSettings={{
                              preserveAspectRatio: "xMidYMid slice",
                            }}
                          />
                          <Typography
                            sx={styles.labelLoadingText}
                            color={"primary"}
                          >
                            {BRANCH_LABELS.LOADING_FILE}
                          </Typography>
                        </Box>
                      </Grid>
                    </Box>
                  ) : (
                    !isEmpty(files) &&
                    !isPrepareUpload && (
                      <Box sx={styles.boxTableBranch}>
                        <Grid sx={styles.uploadFilesSection}>
                          <Grid
                            className="file-uploader-list"
                            sx={styles.uploadFilesSection}
                          >
                            <Box
                              display={"flex"}
                              flexDirection={"column"}
                              sx={styles.uploadFileAreaDrop}
                            >
                              <IconFileText />
                            </Box>
                          </Grid>

                          <Grid container sx={styles.gridFileUploaded}>
                            <Grid item xs={9}>
                              <Typography
                                sx={styles.labelFileUploaded}
                                color={"primary"}
                              >
                                {fileInfo.fileName} ({fileInfo.fileSize})
                              </Typography>
                            </Grid>
                            <Grid item xs={1} sx={styles.deleteFileUploaded}>
                              <IconCircleCross
                                onClick={handleDeleteFileUploaded}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    )
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box sx={styles.boxFooter}>
        <FooterOptions
          primaryButton={footerActions.primaryButton}
          secondaryButton={footerActions.secondaryButton}
          isLoading={footerActions.saveLoader}
          isValidate={true}
          textBtn={INFORMATION_CUSTOMER_LABELS.BUTTON_VALIDATE}
        />
        <ModalLoader
          descriptionText={LOADER_CHECKING_FILE_DESCRIPTION}
          titleText={LOADER_CHECKING_FILE_TITLE}
          isOpen={openLoader}
        />
        <ModalValidate
          onClickPrimary={() => {
            handleContinueClickModal();
          }}
          onClickSecondary={() => {
            handleCancelClickModal();
          }}
          buttonPrimary={CONTINUE_TEXT}
          buttonPrimaryDisabled={allowContinueBranches}
          buttonSecondary={CANCEL_TEXT}
          descriptionText={DESCRIPTION_MODAL_VALIDATE}
          isOpen={openValidateModal}
          onClose={() => {
            handleCancelClickModal();
          }}
          titleText={MODAL_VALIDATE.TITLE_MODAL_VALIDATE}
          contentHeaderModal={contentHeaderModalValidate}
          contentBodyModal={contentBodyModalValidate}
        />
      </Box>
    </>
  );
};

export default ListBranchCreationForm;
