import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export const SkeletonTable: React.FC = () => {
  return (
    <React.Fragment>
      <Paper>
        <TableContainer style={{ marginTop: "16px" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {[...Array(5)].map((_: string, index: number) => (
                  <TableCell key={index} align={"left"}>
                    <Skeleton variant="text" />
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {[...Array(4)].map((_: string, index: number) => (
                <TableRow key={index}>
                  {[...Array(5)].map((_: string, innerIndex: number) => (
                    <TableCell key={innerIndex} align={"left"}>
                      <div>
                        <Skeleton variant="text" />
                      </div>
                      <div>
                        <Skeleton variant="text" />
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display="flex" style={{ width: "100%" }}>
          <Box flexGrow={1}>
            <Box display="flex" alignItems="center" />
          </Box>
          <Box p={1}>
            <div>
              <Skeleton
                width={"150px"}
                height={"60px"}
                style={{ marginRight: "30px" }}
                variant="text"
              />
            </div>
          </Box>
        </Box>
      </Paper>
    </React.Fragment>
  );
};
