import {
  IModalHeaderTemplate,
  IModalTemplateInfo,
} from "../interfaces/IModalContainer";
import { StatusEnum } from "./StatusEnum";
import { LabelEnum } from "./LabelEnum";
import { RequestTypeEnum } from "./RequestTypeEnum";

export enum FieldModelEnum {
  UPDATED_AT = "updatedAt",
  TRANSACTION_DATE = "transactionDate",
  ENTITY_NAME = "entityName",
  TYPE_MERCHANT = "typeMerchant",
  MERCHANT_NAME = "merchantName",
  USER_NAME = "userNameRequest",
  COUNTRY = "country",
  SERVICE = "service",
  SUBTYPE = "subType",
  ACTION = "action",
  PROVIDER = "provider",
  PARTNER = "partnerValidator",
  IGNORE_3DS = "ignore3DS",
  SECURE_3DS = "3dsecure",
  LIST_NAME = "rule.name",
}

export enum TakeRiskStatusEnum {
  ENABLED = "Activado",
  DISABLED = "Desactivado",
}

export enum TransactionTypeEnum {
  TOKEN = "token",
  SUBSCRIPTION_TOKEN = "subscriptionToken",
  CHARGE = "charge",
  DEFERRED = "deferred",
  PRE_AUTHORIZATION = "preauthorization",
  SUBSCRIPTION_CHARGE = "subscriptionCharge",
  SUBSCRIPTION_PRE_AUTHORIZATION = "subscriptionPreauthorization",
  SUBSCRIPTION_ON_DEMAND_TOKEN = "subscriptionOnDemandToken",
}

export enum ValueEnum {
  OWNER = "owner",
  BRANCH = "branch",
  CUSTOMER = "customer",
  COMMERCE = "commerce",
  CARD = "card",
  TRANSFER = "transfer",
  WARNING = "warning",
  VALIDATE = "validate",
  AUTHENTICATE = "authenticate",
  COUNT = "count",
  SUM = "sum",
  HOUR = "hour",
  MINUTE = "minute",
  SECOND = "second",
  DAY = "day",
  WEEK = "week",
  FORTNIGHT = "fortnight",
  MONTH = "month",
  QUARTERLY = "quarterly",
  BIANNUAL = "biannual",
  APPROVAL = "APPROVAL",
  DECLINED = "DECLINED",
  ALL = "ALL",
  ALL_CONDITION = "Todos",
  WITHOUT_HIERARCHY = "whitelist_rule_commerce_without_hierarchy",
  WITH_HIERARCHY = "whitelist_rule_commerce_with_hierarchy",
}

export const TEXT_TRANSFORM_MODAL: Record<string, string> = {
  [ValueEnum.OWNER]: "Owner",
  [ValueEnum.BRANCH]: "Branch",
  [ValueEnum.CUSTOMER]: "Customer",
  [ValueEnum.COMMERCE]: "Comercio",
  [ValueEnum.CARD]: "Tarjeta",
  [ValueEnum.TRANSFER]: "Transferencia",
  [ValueEnum.WARNING]: "Alertar",
  [ValueEnum.VALIDATE]: "Validar",
  [ValueEnum.AUTHENTICATE]: "Autenticar",
  [ValueEnum.COUNT]: "Contar",
  [ValueEnum.SUM]: "Sumar",
  [ValueEnum.HOUR]: "Hora",
  [ValueEnum.MINUTE]: "Minuto",
  [ValueEnum.SECOND]: "Segundo",
  [ValueEnum.DAY]: "Diario",
  [ValueEnum.WEEK]: "Semanal",
  [ValueEnum.FORTNIGHT]: "Quincenal",
  [ValueEnum.MONTH]: "Mes",
  [ValueEnum.QUARTERLY]: "Trimestral",
  [ValueEnum.BIANNUAL]: "Semestral",
  [ValueEnum.APPROVAL]: "Aprobada",
  [ValueEnum.DECLINED]: "Declinada",
  [ValueEnum.ALL]: "Ambos",
  [ValueEnum.WITHOUT_HIERARCHY]: "Comercio sin jerarquía",
  [ValueEnum.WITH_HIERARCHY]: "Comercio con jerarquía",
};

export const MODAL_HEADER_TEMPLATE: Record<string, IModalHeaderTemplate> = {
  [`${LabelEnum.REJECTED_APPLICATIONS}_${StatusEnum.REJECTED}`]: {
    tags: [{ text: LabelEnum.REJECTED_CHIP, color: "error" }],
  },
  [`${LabelEnum.FIRST_LEVEL_RULES}_${StatusEnum.DISABLE}`]: {
    tags: [{ text: LabelEnum.DISABLE_CHIP, color: "info" }],
  },
  [`${LabelEnum.FIRST_LEVEL_RULES}_${StatusEnum.ENABLE}`]: {
    tags: [{ text: LabelEnum.ENABLE_CHIP, color: "success" }],
  },
  [`${LabelEnum.PENDING_REVIEW}_${StatusEnum.PENDING}`]: {
    tags: [{ text: LabelEnum.PENDING_REVIEW, color: "info" }],
  },
  [`${LabelEnum.PENDING_REVIEW}_${RequestTypeEnum.CREATION}`]: {
    tags: [{ text: LabelEnum.NEW_REQUEST, color: "primary" }],
  },
  [`${LabelEnum.PENDING_REVIEW}_${RequestTypeEnum.EDITION}`]: {
    tags: [{ text: LabelEnum.EDITION, color: "primary" }],
  },
  [`${LabelEnum.PENDING_REVIEW}_${RequestTypeEnum.ACTIVATION}`]: {
    tags: [{ text: LabelEnum.REACTIVATION, color: "primary" }],
  },
  [`${LabelEnum.PENDING_REVIEW}_${LabelEnum.IS_FIXED}`]: {
    tags: [{ text: LabelEnum.IS_FIXED, color: "primary" }],
  },
  [`${RequestTypeEnum.CREATION}_${StatusEnum.PENDING}`]: {
    tags: [{ text: LabelEnum.NEW_REQUEST, color: "error" }],
  },
  [`${RequestTypeEnum.EDITION}_${StatusEnum.PENDING}`]: {
    tags: [{ text: LabelEnum.EDITION, color: "error" }],
  },
  [`${RequestTypeEnum.ACTIVATION}_${StatusEnum.PENDING}`]: {
    tags: [{ text: LabelEnum.REACTIVATION, color: "error" }],
  },
  [`${RequestTypeEnum.IS_FIXED}_${StatusEnum.PENDING}`]: {
    tags: [{ text: LabelEnum.IS_FIXED, color: "error" }],
  },
  // WhiteLists
  [`${LabelEnum.WHITE_LISTS}_${StatusEnum.DISABLE}`]: {
    tags: [{ text: LabelEnum.DISABLE_CHIP, color: "warning" }],
  },
  [`${LabelEnum.WHITE_LISTS}_${StatusEnum.ENABLE}`]: {
    tags: [{ text: LabelEnum.ENABLE_CHIP, color: "success" }],
  },
};

export const MODAL_TEMPLATE: Record<string, IModalTemplateInfo> = {
  [`${LabelEnum.REJECTED_APPLICATIONS}`]: {
    generalCountryInfo: {
      title: LabelEnum.GENERAL_INFO_RULES,
      columns: {
        country: LabelEnum.GENERAL_INFO_RULES_COUNTRY,
        service: LabelEnum.GENERAL_INFO_RULES_PAYMENT_METHOD,
        userNameRequest: LabelEnum.GENERAL_INFO_RULES_CREATED_BY,
      },
      status: StatusEnum.REJECTED,
    },
    generalMerchantOwnerInfo: {
      title: LabelEnum.GENERAL_INFO_RULES,
      columns: {
        entityName: LabelEnum.GENERAL_INFO_RULES_TYPE_MERCHANT,
        merchantName: LabelEnum.GENERAL_INFO_RULES_NAME_MERCHANT,
        userName: LabelEnum.GENERAL_INFO_RULES_CREATED_BY,
      },
      status: StatusEnum.REJECTED,
    },
    generalMerchantHierarchyInfo: {
      title: LabelEnum.GENERAL_INFO_RULES,
      columns: {
        entityName: LabelEnum.GENERAL_INFO_RULES_TYPE_MERCHANT,
        merchantName: LabelEnum.GENERAL_INFO_RULES_NAME_MERCHANT,
        userName: LabelEnum.GENERAL_INFO_RULES_CREATED_BY,
      },
      status: StatusEnum.REJECTED,
    },
    generalMerchantCustomerInfo: {
      title: LabelEnum.GENERAL_INFO_RULES,
      columns: {
        entityName: LabelEnum.GENERAL_INFO_RULES_TYPE_MERCHANT,
        merchantName: LabelEnum.GENERAL_INFO_RULES_NAME_MERCHANT,
        merchantId: LabelEnum.GENERAL_INFO_RULES_MERCHANT_ID,
        userName: LabelEnum.GENERAL_INFO_RULES_CREATED_BY,
      },
      status: StatusEnum.REJECTED,
    },
    generalMerchantBranchInfo: {
      title: LabelEnum.GENERAL_INFO_RULES,
      columns: {
        entityName: LabelEnum.GENERAL_INFO_RULES_TYPE_MERCHANT,
        merchantName: LabelEnum.GENERAL_INFO_RULES_NAME_MERCHANT,
        merchantId: LabelEnum.GENERAL_INFO_RULES_MERCHANT_ID,
        service: LabelEnum.GENERAL_INFO_RULES_PAYMENT_METHOD,
        userName: LabelEnum.GENERAL_INFO_RULES_CREATED_BY,
      },
      status: StatusEnum.REJECTED,
    },
    generalConfigInfo: {
      title: LabelEnum.CONFIG_INFO_TITLE,
      columns: {
        action: LabelEnum.CONFIG_INFO_ACTION,
        provider: LabelEnum.CONFIG_INFO_PROVIDER,
        code: LabelEnum.CONFIG_INFO_CODE,
        message: LabelEnum.CONFIG_INFO_MESSAGE,
      },
      status: StatusEnum.REJECTED,
    },
    generalConfigInfoWarning: {
      title: LabelEnum.CONFIG_INFO_TITLE,
      columns: {
        action: LabelEnum.CONFIG_INFO_ACTION,
        code: LabelEnum.CONFIG_INFO_CODE,
        message: LabelEnum.CONFIG_INFO_MESSAGE,
      },
      status: StatusEnum.REJECTED,
    },
    generalRuleInfo: {
      title: LabelEnum.REJECTED_RULES,
      columns: {
        reason: LabelEnum.REJECTED_REASON,
        userName: LabelEnum.REJECTED_BY,
        updatedAt: LabelEnum.REJECTED_LIST_DATE,
      },
      status: StatusEnum.REJECTED,
    },
  },
  [`${LabelEnum.FIRST_LEVEL_RULES}`]: {
    generalRuleInfo: {
      title: LabelEnum.DISABLE_RULE,
      columns: {
        reason: LabelEnum.DISABLE_REASON,
        userName: LabelEnum.DISABLE_BY,
        updatedAt: LabelEnum.DISABLE_DATE,
      },
      status: StatusEnum.DISABLE,
    },
    generalCountryInfo: {
      title: LabelEnum.GENERAL_INFO_RULES,
      columns: {
        country: LabelEnum.GENERAL_INFO_RULES_COUNTRY,
        service: LabelEnum.GENERAL_INFO_RULES_PAYMENT_METHOD,
        userNameRequest: LabelEnum.GENERAL_INFO_RULES_LAST_EDITION,
        userName: LabelEnum.APPROVED_BY,
        updatedAt: LabelEnum.GENERAL_INFO_RULES_APPROVAL_DATE,
      },
      status: StatusEnum.DISABLE,
    },
    generalMerchantInfo: {
      title: LabelEnum.GENERAL_INFO_RULES,
      columns: {
        merchantName: LabelEnum.GENERAL_INFO_RULES_NAME_MERCHANT,
        merchantId: LabelEnum.GENERAL_INFO_RULES_MERCHANT_ID,
        service: LabelEnum.GENERAL_INFO_RULES_PAYMENT_METHOD,
        userNameRequest: LabelEnum.GENERAL_INFO_RULES_LAST_EDITION,
        userName: LabelEnum.APPROVED_BY,
        updatedAt: LabelEnum.GENERAL_INFO_RULES_APPROVAL_DATE,
      },
      status: StatusEnum.REJECTED,
    },
    generalMerchantHierarchyInfo: {
      title: LabelEnum.GENERAL_INFO_RULES,
      columns: {
        entityName: LabelEnum.GENERAL_INFO_RULES_TYPE_MERCHANT,
        merchantName: LabelEnum.GENERAL_INFO_RULES_NAME_MERCHANT,
        merchantId: LabelEnum.GENERAL_INFO_RULES_MERCHANT_ID,
        service: LabelEnum.GENERAL_INFO_RULES_PAYMENT_METHOD,
        userNameRequest: LabelEnum.GENERAL_INFO_RULES_LAST_EDITION,
        userName: LabelEnum.APPROVED_BY,
        updatedAt: LabelEnum.GENERAL_INFO_RULES_APPROVAL_DATE,
      },
      status: StatusEnum.REJECTED,
    },
    generalConfigInfo: {
      title: LabelEnum.CONFIG_INFO_TITLE,
      columns: {
        action: LabelEnum.CONFIG_INFO_ACTION,
        provider: LabelEnum.CONFIG_INFO_PROVIDER,
        code: LabelEnum.CONFIG_INFO_CODE,
        message: LabelEnum.CONFIG_INFO_MESSAGE,
      },
      status: StatusEnum.REJECTED,
    },
    generalConfigInfoWarning: {
      title: LabelEnum.CONFIG_INFO_TITLE,
      columns: {
        action: LabelEnum.CONFIG_INFO_ACTION,
        code: LabelEnum.CONFIG_INFO_CODE,
        message: LabelEnum.CONFIG_INFO_MESSAGE,
      },
      status: StatusEnum.REJECTED,
    },
  },
  [`${LabelEnum.PENDING_REVIEW}`]: {
    generalCountryInfo: {
      title: LabelEnum.GENERAL_INFO_RULES,
      columns: {
        country: LabelEnum.GENERAL_INFO_RULES_COUNTRY,
        service: LabelEnum.GENERAL_INFO_RULES_PAYMENT_METHOD,
        userNameRequest: LabelEnum.GENERAL_INFO_RULES_CREATED_BY,
      },
      status: StatusEnum.PENDING,
    },
    generalMerchantHierarchyInfo: {
      title: LabelEnum.GENERAL_INFO_RULES,
      columns: {
        entityName: LabelEnum.GENERAL_INFO_RULES_TYPE_MERCHANT,
        merchantName: LabelEnum.GENERAL_INFO_RULES_NAME_MERCHANT,
        userName: LabelEnum.GENERAL_INFO_RULES_CREATED_BY,
      },
      status: StatusEnum.PENDING,
    },
    generalMerchantOwnerInfo: {
      title: LabelEnum.GENERAL_INFO_RULES,
      columns: {
        entityName: LabelEnum.GENERAL_INFO_RULES_TYPE_MERCHANT,
        merchantName: LabelEnum.GENERAL_INFO_RULES_NAME_MERCHANT,
        userName: LabelEnum.GENERAL_INFO_RULES_CREATED_BY,
      },
      status: StatusEnum.PENDING,
    },
    generalMerchantCustomerInfo: {
      title: LabelEnum.GENERAL_INFO_RULES,
      columns: {
        entityName: LabelEnum.GENERAL_INFO_RULES_TYPE_MERCHANT,
        merchantName: LabelEnum.GENERAL_INFO_RULES_NAME_MERCHANT,
        merchantId: LabelEnum.GENERAL_INFO_RULES_MERCHANT_ID,
        userName: LabelEnum.GENERAL_INFO_RULES_CREATED_BY,
      },
      status: StatusEnum.PENDING,
    },
    generalMerchantBranchInfo: {
      title: LabelEnum.GENERAL_INFO_RULES,
      columns: {
        entityName: LabelEnum.GENERAL_INFO_RULES_TYPE_MERCHANT,
        merchantName: LabelEnum.GENERAL_INFO_RULES_NAME_MERCHANT,
        merchantId: LabelEnum.GENERAL_INFO_RULES_MERCHANT_ID,
        service: LabelEnum.GENERAL_INFO_RULES_PAYMENT_METHOD,
        userName: LabelEnum.GENERAL_INFO_RULES_CREATED_BY,
      },
      status: StatusEnum.PENDING,
    },
    generalConfigInfo: {
      title: LabelEnum.CONFIG_INFO_TITLE,
      columns: {
        action: LabelEnum.CONFIG_INFO_ACTION,
        provider: LabelEnum.CONFIG_INFO_PROVIDER,
        code: LabelEnum.CONFIG_INFO_CODE,
        message: LabelEnum.CONFIG_INFO_MESSAGE,
      },
      status: StatusEnum.PENDING,
    },
    generalConfigInfoWarning: {
      title: LabelEnum.CONFIG_INFO_TITLE,
      columns: {
        action: LabelEnum.CONFIG_INFO_ACTION,
        code: LabelEnum.CONFIG_INFO_CODE,
        message: LabelEnum.CONFIG_INFO_MESSAGE,
      },
      status: StatusEnum.PENDING,
    },
  },
  [`${LabelEnum.WHITE_LISTS}_${StatusEnum.DISABLE}`]: {
    generalRuleInfo: {
      title: LabelEnum.DISABLED_LIST,
      columns: {
        reason: LabelEnum.DISABLE_REASON,
        userName: LabelEnum.DISABLE_BY,
        updatedAt: LabelEnum.DISABLE_DATE,
      },
      status: StatusEnum.DISABLE,
    },
    generalCountryInfo: {
      title: LabelEnum.GENERAL_INFO_RULES,
      columns: {
        country: LabelEnum.GENERAL_INFO_RULES_COUNTRY,
        service: LabelEnum.GENERAL_INFO_RULES_PAYMENT_METHOD,
        userNameRequest: LabelEnum.GENERAL_INFO_WHITELIST_APPROVED_BY,
        userName: LabelEnum.APPROVED_BY,
      },
      status: StatusEnum.DISABLE,
    },
    generalMerchantInfo: {
      title: LabelEnum.GENERAL_INFO_RULES,
      columns: {
        entityName: LabelEnum.GENERAL_INFO_RULES_TYPE_MERCHANT,
        merchantName: LabelEnum.GENERAL_INFO_RULES_NAME_MERCHANT,
        userNameRequest: LabelEnum.CREATED_BY,
      },
      status: StatusEnum.DISABLE,
    },
    generalMerchantHierarchyInfo: {
      title: LabelEnum.GENERAL_INFO_RULES,
      columns: {
        entityName: LabelEnum.GENERAL_INFO_RULES_TYPE_MERCHANT,
        merchantName: LabelEnum.GENERAL_INFO_RULES_NAME_MERCHANT,
        userNameRequest: LabelEnum.CREATED_BY,
      },
      status: StatusEnum.DISABLE,
    },
  },
  [`${LabelEnum.WHITE_LISTS}_${StatusEnum.ENABLE}`]: {
    generalCountryInfo: {
      title: LabelEnum.GENERAL_INFO_RULES,
      columns: {
        subType: LabelEnum.LIST_TYPE,
        country: LabelEnum.GENERAL_INFO_RULES_COUNTRY,
        userNameRequest: LabelEnum.GENERAL_INFO_WHITELIST_APPROVED_BY,
        userName: LabelEnum.APPROVED_BY,
        updatedAt: LabelEnum.GENERAL_INFO_RULES_APPROVAL_DATE,
      },
      status: StatusEnum.ENABLE,
    },
    generalMerchantInfo: {
      title: LabelEnum.GENERAL_INFO_RULES,
      columns: {
        entityName: LabelEnum.GENERAL_INFO_RULES_TYPE_MERCHANT,
        merchantName: LabelEnum.GENERAL_INFO_RULES_NAME_MERCHANT,
        merchantId: LabelEnum.GENERAL_INFO_RULES_MERCHANT_ID,
        service: LabelEnum.GENERAL_INFO_RULES_PAYMENT_METHOD,
        updatedAt: LabelEnum.GENERAL_INFO_RULES_APPROVAL_DATE,
        userName: LabelEnum.APPROVED_BY,
        userNameRequest: LabelEnum.GENERAL_INFO_RULES_LAST_EDITION,
      },
      status: StatusEnum.ENABLE,
    },
    generalMerchantHierarchyInfo: {
      title: LabelEnum.GENERAL_INFO_RULES,
      columns: {
        entityName: LabelEnum.GENERAL_INFO_RULES_TYPE_MERCHANT,
        merchantName: LabelEnum.GENERAL_INFO_RULES_NAME_MERCHANT,
        merchantId: LabelEnum.GENERAL_INFO_RULES_MERCHANT_ID,
        service: LabelEnum.GENERAL_INFO_RULES_PAYMENT_METHOD,
        updatedAt: LabelEnum.GENERAL_INFO_RULES_APPROVAL_DATE,
        userName: LabelEnum.APPROVED_BY,
        userNameRequest: LabelEnum.GENERAL_INFO_RULES_LAST_EDITION,
      },
      status: StatusEnum.ENABLE,
    },
  },
};

export const WHITELIST_MODAL_TEMPLATE: Record<string, IModalTemplateInfo> = {
  [`${LabelEnum.REJECTED_APPLICATIONS}`]: {
    generalRuleInfo: {
      title: LabelEnum.REJECTED_RULES,
      columns: {
        reason: LabelEnum.REJECTED_REASON,
        userName: LabelEnum.REJECTED_BY,
        updatedAt: LabelEnum.REJECTED_LIST_DATE,
      },
      status: StatusEnum.REJECTED,
    },
    generalCountryInfo: {
      title: LabelEnum.GENERAL_INFO_RULES,
      columns: {
        country: LabelEnum.GENERAL_INFO_RULES_COUNTRY,
        userName: LabelEnum.GENERAL_INFO_RULES_LAST_EDITION,
      },
      status: StatusEnum.REJECTED,
    },
    generalMerchantInfo: {
      title: LabelEnum.GENERAL_INFO_RULES,
      columns: {
        entityName: LabelEnum.GENERAL_INFO_RULES_TYPE_MERCHANT,
        merchantName: LabelEnum.GENERAL_INFO_RULES_NAME_MERCHANT,
        userName: LabelEnum.GENERAL_INFO_RULES_LAST_EDITION,
      },
      status: StatusEnum.REJECTED,
    },
    generalMerchantHierarchyInfo: {
      title: LabelEnum.GENERAL_INFO_RULES,
      columns: {
        entityName: LabelEnum.GENERAL_INFO_RULES_TYPE_MERCHANT,
        merchantName: LabelEnum.GENERAL_INFO_RULES_NAME_MERCHANT,
        userName: LabelEnum.GENERAL_INFO_RULES_LAST_EDITION,
      },
      status: StatusEnum.REJECTED,
    },
  },
  [`${LabelEnum.PENDING_REVIEW}`]: {
    generalCountryInfo: {
      title: LabelEnum.GENERAL_INFO_RULES,
      columns: {
        country: LabelEnum.GENERAL_INFO_RULES_COUNTRY,
        service: LabelEnum.GENERAL_INFO_RULES_PAYMENT_METHOD,
        userName: LabelEnum.GENERAL_INFO_RULES_CREATED_BY,
      },
      status: StatusEnum.ENABLE,
    },
    generalMerchantInfo: {
      title: LabelEnum.GENERAL_INFO_RULES,
      columns: {
        typeMerchant: LabelEnum.GENERAL_INFO_RULES_TYPE_MERCHANT,
        merchantName: LabelEnum.GENERAL_INFO_RULES_NAME_MERCHANT,
        merchantId: LabelEnum.GENERAL_INFO_RULES_MERCHANT_ID,
        service: LabelEnum.GENERAL_INFO_RULES_PAYMENT_METHOD,
        userNameRequest: LabelEnum.GENERAL_INFO_RULES_CREATED_BY,
      },
      status: StatusEnum.REJECTED,
    },
    generalMerchantHierarchyInfo: {
      title: LabelEnum.GENERAL_INFO_RULES,
      columns: {
        typeMerchant: LabelEnum.GENERAL_INFO_RULES_TYPE_MERCHANT,
        merchantName: LabelEnum.GENERAL_INFO_RULES_NAME_MERCHANT,
        merchantId: LabelEnum.GENERAL_INFO_RULES_MERCHANT_ID,
        service: LabelEnum.GENERAL_INFO_RULES_PAYMENT_METHOD,
        userNameRequest: LabelEnum.GENERAL_INFO_RULES_CREATED_BY,
      },
      status: StatusEnum.REJECTED,
    },
  },
};

export enum ModalUpdateStatusPath {
  ACTION_BUTTON = "actionButton",
  DESCRIPTION = "description",
  TITLE = "title",
  PLACEHOLDER = "placeholder",
  BUTTON_SECONDARY_TEXT = "buttonSecondaryText",
}

export enum ModalContentButtonTypeEnum {
  PRIMARY = "primary",
}

export enum ModalContentTypeEnum {
  DEFAULT = "Default",
}

export enum CardListOrientationEnum {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}

export enum ValueProvidersEnum {
  TITLE = "title",
}
