import React from "react";
import { Box, Container, Typography } from "@material-ui/core";
import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
import { TitlePage } from "../../components/common/TitlePage/TitlePage";
import { UploadActionButtonsComponent } from "../../components/UploadActionButtonsComponent/UploadActionButtonsComponent";
import { routes } from "../../shared/infrastructure/routes";
import { FormUploadCardComponent } from "../../components/FormEditComponent/FormUploadCardComponent/FormUploadCardComponent";
import { useFormEditIndexState } from "./state/useFormEditIndexState";
import { ConfirmModal } from "../../components/ConfirmModal/ConfirmModal";
import { get } from "lodash";

export const FormEditIndex: React.FC = () => {
  const {
    breadCrumbs,
    country,
    retentionConfig,
    methods,
    values,
    form,
    modalDialogConfigAdd,
  } = useFormEditIndexState();
  return (
    <React.Fragment>
      <Container fixed>
        <Breadcrumb {...breadCrumbs} />
        <TitlePage title={"Editar configuraciones"} country={country!} />
        <form>
          <FormUploadCardComponent
            data={retentionConfig}
            methods={methods}
            values={values}
            errors={form.errors}
            register={form.register}
          />
          <Box mb={2} mt={2}>
            <UploadActionButtonsComponent
              onSubmit={methods.onSubmit}
              handleBack={() =>
                methods.returnTo(
                  `${routes.BASE_PATH_RETENTIONS}${routes.DASHBOARD}?country=${country}`
                )
              }
              buttonText={"Editar"}
              isLoading={values.isDataSaved}
            />
          </Box>
        </form>
        <ConfirmModal
          open={get(modalDialogConfigAdd, "openModalConfigAdd", false)}
          titleText={
            <Typography variant="h6" color="textSecondary">
              Las configuraciones que deseas editar ya no existen.
            </Typography>
          }
          contentText={
            <Typography variant="h6" color="primary">
              Agregalas nuevamente.
            </Typography>
          }
          cancelText={"Salir"}
          acceptColor={"primary"}
          acceptText={"Agregar"}
          handleClose={modalDialogConfigAdd.handleCloseModalConfigAdd}
          handleAccept={() => {}}
          isLoadingRequest={false}
          isAddConfig={true}
        />
      </Container>
    </React.Fragment>
  );
};
