import React, { FC } from "react";
import { Box } from "@mui/material";
import { transactionContainerStyles as styles } from "./TransactionContainer.styles";
import { Header } from "../../components/Header/Header";
import { LabelsEnum } from "../../shared/enums/LabelsEnum";
import TransactionList from "../../components/TransactionList/TransactionList";
import { useTransactionContainer } from "./state/useTransactionContainer";
import { SelectCheck, SelectCheckChips } from "@kushki/connect-ui";
import { SelectCheckTypeEnum } from "../../shared/enums/SelectCheckTypeEnum";
import { TransactionLabelsEnum } from "../../shared/enums/transactionLabelsEnum";
import { listChips } from "../../shared/constants/DefaultColumns";
import { auth } from "../../shared/auth";

export const TransactionContainer: FC = () => {
  const {
    isMasterCustomer,
    setBranches,
    listBranches,
    isCustomer,
    selectCheckChips,
  } = useTransactionContainer();

  return (
    <Box sx={styles.main}>
      <Header
        label={LabelsEnum.PAYOUTS}
        component={
          isMasterCustomer && isCustomer ? (
            <SelectCheck
              isActiveSelectAll={true}
              type={SelectCheckTypeEnum.NO_CATEGORY}
              placeholder={TransactionLabelsEnum.LABEL_BRANCHES}
              items={listBranches}
              onItemSelect={setBranches}
              onChangeTextField={() => {}}
              hasDescription={true}
            />
          ) : (
            auth.isAdmin() && (
              <Box sx={styles.checkChips}>
                <SelectCheckChips
                  placeholder={TransactionLabelsEnum.LABEL_ADMIN}
                  isActiveSelectAll={true}
                  isLoading={selectCheckChips.loadingMerchants}
                  hasDescription={true}
                  items={selectCheckChips.merchantsName}
                  listChips={listChips}
                  onChangeTextField={selectCheckChips.handleSearchText}
                  onItemSelect={selectCheckChips.handleItemSelected}
                  onChipChange={selectCheckChips.handleChipChange}
                  totalFilters={selectCheckChips.handleTotalFilters}
                  disableFilterSearch={true}
                />
              </Box>
            )
          )
        }
      />
      <TransactionList mechantFilter={selectCheckChips.mechantFilter} />
    </Box>
  );
};
