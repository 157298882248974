import { IStyles } from "../../../../../../shared/interfaces/Styles.interfaces";

export const styles: IStyles = {
  entryDate: {
    color: "neutral.grey7",
    fontFamily: "IBM Plex Sans, Helvetica !important",
    fontWeight: "400 !important",
    lineHeight: "140% !important",
  },
  entryTime: {
    color: "text.grey",
    fontFamily: "IBM Plex Sans, Helvetica !important",
    fontWeight: "400 !important",
    lineHeight: "140% !important",
  },
};
