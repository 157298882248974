import { Box, Divider, Paper, Typography } from "@mui/material";
import React, { FC } from "react";
import { formWrapperStyles as styles } from "./FormWrapper.interfaces";
import Button from "@mui/material/Button";
import { useFormWrapper } from "./state/useFormWrapper";
import { ADD_BUTTON_LABEL } from "../../shared/constants/labels/webhook_form_labels";

export interface IFormWrapper {
  children: JSX.Element;
  title: string;
}

const FormWrapper: FC<IFormWrapper> = ({ children, title }: IFormWrapper) => {
  const { handleRedirectOnClick, isThereWebhooks, isCentralized } =
    useFormWrapper();

  return (
    <Paper elevation={2} sx={styles.formWrapper}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography color="text.primary" variant="h3">
          {title}
        </Typography>
        {isThereWebhooks && !isCentralized && (
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleRedirectOnClick}
            sx={styles.buttonTitle}
          >
            {ADD_BUTTON_LABEL}
          </Button>
        )}
      </Box>
      <Divider sx={styles.divider} />
      {children}
    </Paper>
  );
};

export default FormWrapper;
