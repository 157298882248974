export enum DeferredResumeData {
  Banks = "Bancos:",
  Consortium = "Consorcio:",
  DeferredType = "Tipo de diferido:",
  Fees = "Cuotas:",
  MonthsOfGrace = "Meses de gracia:",
  Processor = "Procesador:",
  Created = "Creado:",
  Cards = "Tarjetas:",
  Schedule = "Programación:",
  Rule = "Regla",
}

export enum ProcessorNameDeferred {
  BILLPOCKET = "BillPocket Processor",
  DATAFAST = "Datafast Processor",
  KUSHKI_ACQ = "Kushki Acquirer Processor",
  PROSA = "Prosa Processor",
  CREDIMATIC = "Credimatic Processor",
  CREDOMATIC = "Credomatic Processor",
  FIS = "Fis Processor",
}

export enum ProcessorNameLabel {
  BILLPOCKET = "Billpocket",
  DATAFAST = "Datafast",
  KUSHKI_ACQ = "Kushki Adquirente",
  PROSA = "Prosa",
  CREDIMATIC = "Credimatic",
  CREDOMATIC = "Credomatic",
  FIS = "WorldPay (by Fis)",
}

export const MexicoFields = (): string[] => {
  return [
    DeferredResumeData.Created,
    DeferredResumeData.Processor,
    DeferredResumeData.DeferredType,
    DeferredResumeData.Banks,
    DeferredResumeData.Fees,
    DeferredResumeData.MonthsOfGrace,
    DeferredResumeData.Consortium,
    DeferredResumeData.Schedule,
  ];
};

export const EcuadorFields = (): string[] => {
  return [
    DeferredResumeData.Created,
    DeferredResumeData.Processor,
    DeferredResumeData.DeferredType,
    DeferredResumeData.Banks,
    DeferredResumeData.Fees,
    DeferredResumeData.MonthsOfGrace,
    DeferredResumeData.Schedule,
  ];
};

export const BrazilFields = (): string[] => {
  return [
    DeferredResumeData.Processor,
    DeferredResumeData.Cards,
    DeferredResumeData.Fees,
    DeferredResumeData.Schedule,
  ];
};

export const CentroAmericaFields = (): string[] => {
  return [
    DeferredResumeData.Created,
    DeferredResumeData.Processor,
    DeferredResumeData.DeferredType,
    DeferredResumeData.Fees,
    DeferredResumeData.Schedule,
  ];
};
