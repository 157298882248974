import { useEffect, useState } from "react";
import {
  RolesEnum,
  validateRole,
} from "../../../shared/infrastructure/enums/RolesEnum";
import { routes } from "../../../shared/infrastructure/routes";
import { BreadcrumProps } from "../../../components/common/Breadcrumb/Breadcrumb";
import { IUtilDashboard, useUtilDashboard } from "../../Utils/useUtilDashboard";
import { COUNTRIES } from "../../../shared/infrastructure/CountryEnum";
import { SIZES_VALUES } from "../../../shared/infrastructure/constants/SizeEnum";
import { AUTHENTICATOR_VALUES } from "../../../shared/infrastructure/constants/AuthenticatorEnum";
import { SearchAuthenticationRulesRequest } from "../../../../types/search_authentication_rules_request";
import { getAuthenticationRulesDataTabs } from "../../../store/actionCreators";
import { useDispatch } from "react-redux";
import { StatusEnum } from "../../../shared/infrastructure/enums/StatusEnum";
import { set } from "lodash";
import { AuthenticationRulesDashboard } from "../../../shared/infrastructure/constants/AuthenticationRulesConstants";

export interface IRuleDashboardIndexState {
  state: IState;
  isAdmin: boolean;
  breadCrumbs: BreadcrumProps;
  stateUtilTabApproval: IUtilDashboard;
  stateUtilTabRequest: IUtilDashboard;
  stateUtilTab2: IUtilDashboard;
}
interface IState {}

export const useRuleDashboardIndexState = (): IRuleDashboardIndexState => {
  const [state] = useState<IState>({});
  const [isAdmin] = useState<boolean>(validateRole(RolesEnum.BackofficeAdmin));
  const stateUtilTabApproval: IUtilDashboard = useUtilDashboard({
    filtersToBuild: [
      {
        key: "country",
        data: COUNTRIES,
      },
      {
        key: "merchant_size",
        data: SIZES_VALUES,
      },
      {
        key: "kind",
        data: AUTHENTICATOR_VALUES,
      },
    ],
  });
  const stateUtilTabRequest: IUtilDashboard = useUtilDashboard({
    filtersToBuild: [
      {
        key: "country",
        data: COUNTRIES,
      },
      {
        key: "merchant_size",
        data: SIZES_VALUES,
      },
      {
        key: "kind",
        data: AUTHENTICATOR_VALUES,
      },
    ],
  });
  const stateUtilTab2: IUtilDashboard = useUtilDashboard({
    filtersToBuild: [
      {
        key: "country",
        data: COUNTRIES,
      },
      {
        key: "merchant_size",
        data: SIZES_VALUES,
      },
      {
        key: "kind",
        data: AUTHENTICATOR_VALUES,
      },
    ],
  });
  const dispatch = useDispatch();
  const initialStatusApproval: string = `${StatusEnum.SENT}`;
  const initialStatusRequest: string = `${StatusEnum.IN_PROCESS}|${StatusEnum.PENDING}|${StatusEnum.REJECT}`;
  const initialStatusHistoric: string = `${StatusEnum.COMPLETED}|${StatusEnum.REJECT}`;
  const executePetition = (
    params: Partial<SearchAuthenticationRulesRequest>,
    tab: number
  ) => {
    dispatch(
      getAuthenticationRulesDataTabs(
        tab,
        params as SearchAuthenticationRulesRequest
      )
    );
  };
  useEffect(() => {
    set(
      stateUtilTabApproval.stateUtil.filter!,
      "status",
      initialStatusApproval
    );
    executePetition(stateUtilTabApproval.stateUtil, 0);
  }, [stateUtilTabApproval.stateUtil]);

  useEffect(() => {
    set(stateUtilTabRequest.stateUtil.filter!, "status", initialStatusRequest);
    executePetition(stateUtilTabRequest.stateUtil, 1);
  }, [stateUtilTabRequest.stateUtil]);

  useEffect(() => {
    set(stateUtilTab2.stateUtil.filter!, "status", initialStatusHistoric);
    executePetition(stateUtilTab2.stateUtil, 2);
  }, [stateUtilTab2.stateUtil]);
  return {
    state: {
      ...state,
    },
    isAdmin,
    breadCrumbs: {
      items: [
        {
          label: "Inicio",
          url: routes.DASHBOARD,
        },
        {
          label: "Prevención de Fraude",
          url: routes.MONITORING,
        },
      ],
      lastItem: AuthenticationRulesDashboard.TITLE,
    },
    stateUtilTabApproval,
    stateUtilTabRequest,
    stateUtilTab2,
  };
};
