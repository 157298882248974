import { makeStyles } from "@material-ui/core";

export const alertInformationStyles = makeStyles({
  alertIcon: {
    color: "#4498EE",
    height: "14.67",
    width: "16.67px",
  },
  contentAlert: {
    background: "#EEF6FF",
    border: "1px solid #4498EE",
    borderRadius: "4px",
    padding: "12px",
  },
  titleAlert: {
    color: "#4498EE",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "150%",
  },
});
