import { getBranchEditListFromSessionStorage } from "../../../shared/utils/massiveBranchUtils";
import { slice } from "lodash";
import { IUseSummaryMassiveBranchInterfaces } from "./useSummaryMassiveBranch.interfaces";
import { BranchEditItem } from "../../../../types/branch_edit_item";
import { SessionStoragePaths } from "../../../shared/constants/Session_storage_paths";

export const useSummaryMassiveBranch =
  (): IUseSummaryMassiveBranchInterfaces => {
    const branchList = getBranchEditListFromSessionStorage(
      SessionStoragePaths.BRANCH_EDIT_LIST
    ).filter((branch: BranchEditItem) => branch.edit);
    const names = slice(
      branchList.map((bl: any) => bl.name),
      0,
      3
    ).join(", ");

    return {
      names,
      totalBranches: branchList.length,
    };
  };
