import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { IInputFieldsFormProps } from "../../../shared/interfaces/InputFieldsForm.interfaces";
import {
  getErrorMessagesMaxLength,
  getErrorMessagesMinLength,
  getRequiredErrorMessages,
} from "../../../shared/utils/errorMessages/error_messages";
import { TextField } from "@mui/material";
import { get, isUndefined } from "lodash";
import { INumericInputFieldProps } from "./NumericInputField.interfaces";
import NumberFormatInput from "../NumberFormatInput/NumberFormatInput";
import { isValidField } from "../../../shared/utils/formValidations/form_validations";
import { numericInputFieldFormStyles as styles } from "./NumericInputField.styles";

const NumericInputField: FC<
  IInputFieldsFormProps & INumericInputFieldProps
> = ({
  name,
  control,
  errors,
  defaultValue,
  label,
  endIcon,
  startIcon,
  customErrorMessages,
  validate,
  helperText,
  minLengthValue,
  maxLengthValue,
  isNumeric = true,
  ...numericInputProps
}: IInputFieldsFormProps & INumericInputFieldProps) => {
  return (
    <Controller
      name={name}
      rules={{
        ...(minLengthValue && {
          minLength: {
            message: getErrorMessagesMinLength(customErrorMessages),
            value: minLengthValue,
          },
        }),
        ...(maxLengthValue && {
          maxLength: {
            message: getErrorMessagesMaxLength(customErrorMessages),
            value: maxLengthValue,
          },
        }),
        required: getRequiredErrorMessages(customErrorMessages),
        validate: isValidField.bind(null, {
          errorMessage: { label: label!, messages: customErrorMessages },
          validate: validate!,
        }),
      }}
      control={control}
      render={({ field }) => (
        <TextField
          sx={styles.texField}
          {...field}
          label={label}
          variant="outlined"
          fullWidth
          defaultValue={defaultValue}
          error={!isUndefined(errors[name])}
          FormHelperTextProps={{ sx: styles.root }}
          helperText={get(errors, `${name}.message`, "") || helperText}
          InputProps={{
            endAdornment: !isUndefined(endIcon) && endIcon,
            inputComponent: isNumeric ? NumberFormatInput : undefined,
            inputProps: {
              ...numericInputProps,
            },
            startAdornment: !isUndefined(startIcon) && startIcon,
          }}
          onChange={(e) => {
            if (!isNumeric) {
              e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
            }
            field.onChange(e);
          }}
        />
      )}
    />
  );
};

export default NumericInputField;
