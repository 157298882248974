import * as React from "react";
import { SvgIcon as MuiSvgIcon, styled, SvgIconProps } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../Themes/themekushki";

const SvgIcon = styled(MuiSvgIcon, {
  shouldForwardProp: (prop) => prop !== "fill",
})<SvgIconProps>(({ color, fontSize }) => ({
  fill: color + "!important",
  fontSize: fontSize,
}));

SvgIcon.defaultProps = {
  "aria-hidden": "true",
  focusable: "false",
  viewBox: "0 0 24 24",
};

const IconCheck: React.FunctionComponent<SvgIconProps> = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <SvgIcon
        {...props}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7.33386 11.9972C7.24612 11.9977 7.15915 11.9809 7.07792 11.9477C6.9967 11.9145 6.92282 11.8657 6.86053 11.8039L4.19386 9.1372C4.06969 9.01229 4 8.84332 4 8.6672C4 8.49107 4.06969 8.32211 4.19386 8.1972C4.25584 8.13471 4.32957 8.08512 4.41081 8.05127C4.49205 8.01742 4.57919 8 4.6672 8C4.7552 8 4.84234 8.01742 4.92358 8.05127C5.00482 8.08512 5.07855 8.13471 5.14053 8.1972L7.33386 10.3905L12.5272 5.1972C12.5892 5.13471 12.6629 5.08512 12.7441 5.05127C12.8254 5.01743 12.9125 5 13.0005 5C13.0885 5 13.1757 5.01743 13.2569 5.05127C13.3382 5.08512 13.4119 5.13471 13.4739 5.1972C13.598 5.32211 13.6677 5.49107 13.6677 5.6672C13.6677 5.84332 13.598 6.01229 13.4739 6.1372L7.8072 11.8039C7.7449 11.8657 7.67102 11.9145 7.5898 11.9477C7.50857 11.9809 7.4216 11.9977 7.33386 11.9972Z" />
      </SvgIcon>
    </ThemeProvider>
  );
};

export default IconCheck;
