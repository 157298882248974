import React, { FC } from "react";
import { Tooltip, Zoom } from "@mui/material";
import { IInfoToolTipProps } from "./InfoCentralizedTooltipComponent.interfaces";

export const InfoCentralizedTooltipComponent: FC<IInfoToolTipProps> = ({
  icon,
  title,
  placement,
}: IInfoToolTipProps) => {
  return (
    <>
      <Tooltip
        TransitionComponent={Zoom}
        title={title}
        placement={placement}
        arrow
      >
        {icon}
      </Tooltip>
    </>
  );
};
