import { Badge, Box, Divider, Grid, Typography } from "@mui/material";
import { Shareholder } from "../../../shared/infrastructure/interfaces/Shareholder";
import { ShareHolderAccordionContentStyles } from "./ShareHolderAccordionContent.style";
import { get } from "lodash";
import {
  BIRTH_DATE_TEXT,
  BUSINESS_COUNTRY_TEXT,
  CONSTITUTION_DATE_TEXT,
  NAME_TEXT,
  NID_TEXT,
  PARTICIPATION_SHAREHOLDERS_TEXT,
  RECIDENCE_COUNTRY_TEXT,
  REGISTERED_SHAREHOLDERS_TEXT,
  SOCIAL_REASON_TEXT,
} from "../../../shared/constants/labels/AccordionConstants";

interface Props {
  shareholder: Shareholder;
}

export const ShareholderAccordionContent: React.FC<Props> = ({
  shareholder,
}) => {
  const { shareholderInfo } = shareholder;

  return (
    <Box display="grid" padding={2}>
      <Grid
        container
        spacing={2}
        sx={ShareHolderAccordionContentStyles.content}
      >
        <Grid item xs={5}>
          <Grid
            item
            xs={12}
            marginBottom={2}
            sx={ShareHolderAccordionContentStyles.gridFlex}
          >
            <Box>
              <Typography
                sx={ShareHolderAccordionContentStyles.typographyLabel}
              >
                {get(shareholderInfo, "isMoral", false)
                  ? SOCIAL_REASON_TEXT
                  : NAME_TEXT}
              </Typography>
            </Box>
            <Box sx={ShareHolderAccordionContentStyles.normalValue}>
              {get(shareholderInfo, "isMoral", false)
                ? get(shareholderInfo, "socialReason", "")
                : get(shareholderInfo, "name", "")}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            marginBottom={2}
            sx={ShareHolderAccordionContentStyles.gridFlex}
          >
            <Box>
              <Typography
                sx={ShareHolderAccordionContentStyles.typographyLabel}
              >
                {NID_TEXT}
              </Typography>
            </Box>
            <Box sx={ShareHolderAccordionContentStyles.normalValue}>
              {get(shareholderInfo, "id", "")}
            </Box>
          </Grid>
          {get(shareholderInfo, "isMoral", false) && (
            <Grid
              item
              xs={12}
              marginBottom={2}
              sx={ShareHolderAccordionContentStyles.gridFlex}
            >
              <Box>
                <Typography
                  sx={ShareHolderAccordionContentStyles.typographyLabel}
                >
                  {BUSINESS_COUNTRY_TEXT}
                </Typography>
              </Box>
              <Box sx={ShareHolderAccordionContentStyles.normalValue}>
                {get(shareholderInfo, "country", "")}
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid
          sx={ShareHolderAccordionContentStyles.gridFlexDivider}
          item
          xs={0.5}
        >
          <Divider
            sx={ShareHolderAccordionContentStyles.divider}
            variant={"fullWidth"}
            orientation={"vertical"}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid
            item
            xs={12}
            marginBottom={2}
            sx={ShareHolderAccordionContentStyles.gridFlex}
          >
            <Box>
              <Typography
                sx={ShareHolderAccordionContentStyles.typographyLabel}
              >
                {get(shareholderInfo, "isMoral", false)
                  ? REGISTERED_SHAREHOLDERS_TEXT
                  : RECIDENCE_COUNTRY_TEXT}
              </Typography>
            </Box>
            <Box>
              {get(shareholderInfo, "isMoral", false) ? (
                <Box sx={ShareHolderAccordionContentStyles.normalBox}>
                  <Badge
                    badgeContent={get(
                      shareholderInfo,
                      "registeredShareholders",
                      ""
                    )}
                    sx={
                      ShareHolderAccordionContentStyles.nestedBadgeComponentLvl1
                    }
                  />
                </Box>
              ) : (
                <Box sx={ShareHolderAccordionContentStyles.normalValue}>
                  {get(shareholderInfo, "country", "")}
                </Box>
              )}
            </Box>
          </Grid>
          {get(shareholderInfo, "isMoral", false) && (
            <Grid
              item
              xs={12}
              marginBottom={2}
              sx={ShareHolderAccordionContentStyles.gridFlex}
            >
              <Box>
                <Typography
                  sx={ShareHolderAccordionContentStyles.typographyLabel}
                >
                  {PARTICIPATION_SHAREHOLDERS_TEXT}
                </Typography>
              </Box>
              <Box>
                <Box sx={ShareHolderAccordionContentStyles.normalBox}>
                  <Badge
                    color={"secondary"}
                    badgeContent={get(
                      shareholderInfo,
                      "participationPercentage",
                      ""
                    )}
                  />
                </Box>
              </Box>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            marginBottom={2}
            sx={ShareHolderAccordionContentStyles.gridFlex}
          >
            <Box>
              <Typography
                sx={ShareHolderAccordionContentStyles.typographyLabel}
              >
                {get(shareholderInfo, "isMoral", false)
                  ? CONSTITUTION_DATE_TEXT
                  : BIRTH_DATE_TEXT}
              </Typography>
            </Box>
            <Box sx={ShareHolderAccordionContentStyles.normalValue}>
              {get(shareholderInfo, "date", "")}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
