export enum FrequencyValue {
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
  QUARTERLY = "quarterly",
  BIWEEKLY = "biweekly",
  BIANNUAL = "biannual",
}

export enum FrequencyLabel {
  DIARIA = "Diaria",
  SEMANAL = "Semanal",
  MENSUAL = "Mensual",
  TRIMESTRAL = "Trimestral",
  QUINCENAL = "Quincenal",
  SEMESTRAL = "Semestral",
}
