import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetRefundTransactionListRequest } from "../../../../types/get-refund-transaction-list-request";
import { GetRefundTransactionListResponse as Response } from "../../../../types/get-refund-transaction-list-response";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import {
  setRefunds,
  setScrollId,
} from "../../../store/reducers/refunds/refunds";
import { RootState } from "../../../store/store";
import {
  clearSelectedRefunds,
  setIsAllSelectedRefunds,
} from "../../../store/actions/refunds/refunds";

const useRefundsLoadMoreButtonState = () => {
  const refunds = useSelector((state: RootState) => state.refunds.refunds);

  const totalRemoteRefunds = useSelector(
    (state: RootState) => state.refunds.totalRemoteRefunds
  );
  const scrollId = useSelector((state: RootState) => state.refunds.scrollId);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const handleLoadMoreClick = () => {
    dispatch(clearSelectedRefunds());
    dispatch(setIsAllSelectedRefunds(false));

    (async () => {
      try {
        setLoading(true);

        const body: GetRefundTransactionListRequest = { scrollId };

        const { data } = await axios.post<Response>(API_ROUTES.REFUNDS, body);

        const newScrollId = data.scroll;

        const newRefunds = [...refunds, ...data.data];

        dispatch(setScrollId(newScrollId));
        dispatch(setRefunds(newRefunds));
      } catch (error) {
      } finally {
        setLoading(false);
      }
    })();
  };

  return {
    handleLoadMoreClick,
    loading,
    showButton: refunds.length < totalRemoteRefunds,
  };
};

export default useRefundsLoadMoreButtonState;
