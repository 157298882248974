import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { ROUTES, SPA_BASENAME } from "./shared/constants/routes";
import { store } from "./store/store";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
import { environment } from "./environments/environment";
import { M_ROLES_CONTROL } from "./shared/constants/labels/main_containter_labels";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import ModulesContainer from "./containers/ModulesContainer/ModulesContainer";
import ComponentsContainer from "./containers/ComponentsContainer/ComponentsContainer";

const Root = () => {
  return (
    <React.StrictMode>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&display=swap');"
        }
      </style>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500&display=swap');"
        }
      </style>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter basename={SPA_BASENAME}>
            <ModuleSecurityWrapper
              basePath={environment.kushkiUrl}
              componentId={M_ROLES_CONTROL}
            >
              <Routes>
                <Route path={ROUTES.MODULES} element={<ModulesContainer />} />
                <Route
                  path={ROUTES.COMPONENTS}
                  element={<ComponentsContainer />}
                />
              </Routes>
            </ModuleSecurityWrapper>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
};

export default Root;
