import React from "react";
import { CardContainer } from "../CardContainer/CardContainer";
import { SkeletonTableProcessors } from "../Skeletons/SkeletonTableProcessors/SkeletonTableProcessors";
import { ProcessorsTable } from "../ProcessorsTable/ProcessorsTable";
import { MerchantResponse } from "../../../types/merchant_response";
import { MerchantProcessorFetch } from "../../shared/interfaces/MerchantProcessorFetch";
import { ProcessingSectionConstants } from "../../shared/infrastructure/constants/MerchantConfigConstants";
import { routes } from "../../shared/infrastructure/constants/routes";
import { GetAppConfigPaymentMethodsResponse } from "../../../types/get_app_config_payment_method_response";

export interface IProcessingSectionProps {
  merchant: MerchantResponse;
  isLoadingGetMerchantProcessors: boolean | undefined;
  cardProcessors: MerchantProcessorFetch[] | undefined;
  transferProcessors: MerchantProcessorFetch[] | undefined;
  cashProcessors: MerchantProcessorFetch[] | undefined;
  cashOutProcessors: MerchantProcessorFetch[] | undefined;
  transferOutProcessors: MerchantProcessorFetch[] | undefined;
  transferSubscriptionProcessors: MerchantProcessorFetch[] | undefined;
  transferInternationalProcessors: MerchantProcessorFetch[] | undefined;
  defaultProcessors: object | undefined;
  appConfigPaymentMethods: GetAppConfigPaymentMethodsResponse;
}

export const ProcessingSection: React.FC<IProcessingSectionProps> = (
  props: React.PropsWithChildren<IProcessingSectionProps>
) => {
  return (
    <React.Fragment>
      <CardContainer
        key={ProcessingSectionConstants.KEY_CONTAINER}
        isMenuButton={true}
        merchantId={props.merchant.publicMerchantId}
        country={props.merchant.country}
        headerCardTitle={ProcessingSectionConstants.HEADER_CARD_TITLE}
        headerButtonTitle={ProcessingSectionConstants.HEADER_BUTTON_TITLE}
        footerCardHrefLocation={`${routes.PROCESSOR_LIST}=${props.merchant.publicMerchantId}`}
        footerTitleHrefLocation={ProcessingSectionConstants.FOOTER_TITLE}
        childrenArrayCardComponent={[
          props.isLoadingGetMerchantProcessors ? (
            <SkeletonTableProcessors
              key={ProcessingSectionConstants.KEY_SKELETON}
              rows={2}
              cells={5}
            />
          ) : (
            <ProcessorsTable
              key={ProcessingSectionConstants.KEY_TABLE_CARD}
              captionTableTitle={ProcessingSectionConstants.CAPTION_TABLE_CARD}
              isHiddenTableHead={false}
              processorList={props.cardProcessors}
              defaultProcessors={props.defaultProcessors}
            />
          ),
          props.isLoadingGetMerchantProcessors ? (
            <SkeletonTableProcessors
              key={ProcessingSectionConstants.KEY_SKELETON}
              rows={2}
              cells={5}
            />
          ) : (
            <ProcessorsTable
              key={ProcessingSectionConstants.KEY_TABLE_TRANSFER}
              captionTableTitle={
                ProcessingSectionConstants.CAPTION_TABLE_TRANSFER_IN
              }
              isHiddenTableHead={true}
              processorList={props.transferProcessors}
              defaultProcessors={props.defaultProcessors}
            />
          ),
          props.isLoadingGetMerchantProcessors ? (
            <SkeletonTableProcessors
              key={ProcessingSectionConstants.KEY_SKELETON}
              rows={2}
              cells={5}
            />
          ) : (
            <ProcessorsTable
              key={ProcessingSectionConstants.KEY_TABLE_CASH}
              captionTableTitle={ProcessingSectionConstants.CAPTION_TABLE_CASH}
              isHiddenTableHead={true}
              processorList={props.cashProcessors}
            />
          ),
          props.isLoadingGetMerchantProcessors ? (
            <SkeletonTableProcessors
              key={ProcessingSectionConstants.KEY_SKELETON}
              rows={2}
              cells={5}
            />
          ) : (
            <ProcessorsTable
              key={ProcessingSectionConstants.KEY_TABLE_CASH_OUT}
              captionTableTitle={
                ProcessingSectionConstants.CAPTION_TABLE_CASH_OUT
              }
              isHiddenTableHead={true}
              processorList={props.cashOutProcessors}
            />
          ),
          props.isLoadingGetMerchantProcessors ? (
            <SkeletonTableProcessors
              key={ProcessingSectionConstants.KEY_SKELETON}
              rows={2}
              cells={5}
            />
          ) : (
            <ProcessorsTable
              key={ProcessingSectionConstants.KEY_TABLE_TRANSFER_OUT}
              captionTableTitle={
                ProcessingSectionConstants.CAPTION_TABLE_TRANSFER_OUT
              }
              isHiddenTableHead={true}
              processorList={props.transferOutProcessors}
              defaultProcessors={props.defaultProcessors}
            />
          ),
          props.isLoadingGetMerchantProcessors ? (
            <SkeletonTableProcessors
              key={ProcessingSectionConstants.KEY_SKELETON}
              rows={2}
              cells={5}
            />
          ) : (
            <ProcessorsTable
              key={ProcessingSectionConstants.KEY_TABLE_TRANSFER_SUBSCRIPTIONS}
              captionTableTitle={
                ProcessingSectionConstants.CAPTION_TABLE_TRANSFER_SUBSCRIPTIONS
              }
              isHiddenTableHead={true}
              processorList={props.transferSubscriptionProcessors}
              defaultProcessors={props.defaultProcessors}
            />
          ),
          props.isLoadingGetMerchantProcessors ? (
            <SkeletonTableProcessors
              key={ProcessingSectionConstants.KEY_SKELETON}
              rows={2}
              cells={5}
            />
          ) : (
            <ProcessorsTable
              key={ProcessingSectionConstants.KEY_TABLE_INTERNATIONAL_TRANSFER}
              captionTableTitle={
                ProcessingSectionConstants.CAPTION_TABLE_INTERNATIONAL_TRANSFER
              }
              isHiddenTableHead={true}
              processorList={props.transferInternationalProcessors}
              defaultProcessors={props.defaultProcessors}
            />
          ),
        ]}
      />
    </React.Fragment>
  );
};
