import React from "react";
import { Chip, ChipProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ChipTypeEnum } from "../../../shared/infrastructure/constants/ChipTypeEnum";

export interface LabelChipProps extends ChipProps {
  type: ChipTypeEnum;
  text: string;
}

const useStyles = makeStyles(() => ({
  chip: {
    borderRadius: "4px !important",
    padding: "1px !important",
  },
  chipTheme: (props: LabelChipProps) => {
    switch (props.type) {
      case ChipTypeEnum.BANK:
        return {
          background: "#F7FAFC",
          color: "#293036",
        };
      case ChipTypeEnum.VARIANT:
        return {
          background: "#EEF6FF",
          color: "#023365",
        };
      default:
        return {
          background: "#F7FAFC",
          color: "#293036",
        };
    }
  },
}));

export const LabelChip: React.FC<LabelChipProps> = (props: LabelChipProps) => {
  const classes = useStyles(props);

  return (
    <Chip
      size="small"
      label={props.text}
      className={`${classes.chip} ${classes.chipTheme}`}
    />
  );
};
