import { HeadCell } from "../../components/Table/TableHead/TableHead.interfaces";
import { CatalogConfigTable, headCells } from "./CatalogConfigTableTransaction";
import { IConfigCells } from "../constants/table_transactions";

export enum ViewTypeEnum {
  PAYOUT = "payout",
}

export const CATALOG_HEADS: Record<ViewTypeEnum, HeadCell[]> = {
  [ViewTypeEnum.PAYOUT]: headCells,
};

export const CATALOG_ROWS: Record<ViewTypeEnum, IConfigCells[]> = {
  [ViewTypeEnum.PAYOUT]: CatalogConfigTable,
};
