import { defaultTo, get } from "lodash";
import { StorageKeyEnum } from "../enum/StorageKeyEnum";
import { UserRolesEnum } from "../enum/UserRolesEnum";

export const getRolesUtils = (): object =>
  JSON.parse(defaultTo(localStorage.getItem(StorageKeyEnum.ROLES), "{}"));

export const isBackofficeMasterCustomer = () =>
  get(getRolesUtils(), UserRolesEnum.BO_MASTER_CUSTOMER, false);

export const isAdmin = () => get(getRolesUtils(), UserRolesEnum.ADMIN, false);

export const isUserRiskManager = () =>
  get(getRolesUtils(), UserRolesEnum.USER_RISK_MANAGER, false);

export const isUserBackofficeUserMaker = () =>
  get(getRolesUtils(), UserRolesEnum.BACKOFFICE_USER_MAKER, false);

export const isUserBackofficeUserMaster = () =>
  get(getRolesUtils(), UserRolesEnum.BACKOFFICE_USER_MASTER, false);

export const isUserBackofficeUserParent = () =>
  get(getRolesUtils(), UserRolesEnum.BACKOFFICE_USER_PARENT, false);
