import { createNamedStyles } from "../../shared/interfaces/create_named_styles";
import { SxProps, Theme } from "@mui/material";

const selectNewItemStyles = createNamedStyles({
  addButtonTextBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "0px",
    padding: "0px",
    backgroundColor: "#primary.light3",
  },
  autocompleteSelect: {
    "&:MuiInputBase-root": {
      color: "red",
    },
    width: "100%",
  },
  addButtonTextButton: {
    width: "100%",
    margin: "8px 8px",
    border: "1px solid",
    "&:disabled": {
      border: "none",
      backgroundColor: "primary.light3",
    },
    "&:hover": {
      borderColor: "primary.light2",
      color: "primary.light2",
      height: "34px",
      marginTop: "9px",
      marginBottom: "9px",
      transition: "0.2s",
    },
  },
  textField: {
    ".MuiAutocomplete-input": {
      color: "#4499EE",
    },
  },
  boxOptionList: {
    height: (theme) => theme.spacing(5),
    borderBottom: `1px solid neutral.grey4`,
    boxSizing: "borderBox",
    ":hover": {
      backgroundColor: `#primary.light3 !important`,
    },
    "&[role=option]": {
      "&:hover, &[aria-selected=true], &[aria-selected=true].Mui-focused, &[aria-selected=false].Mui-focused":
        {
          backgroundColor: "primary.light3",
        },
    },
  },
});

export const renderOption = (highlight: boolean): SxProps<Theme> => {
  return {
    color: () => (highlight ? "primary.light2" : "text.dark"),
  };
};

export { selectNewItemStyles };
