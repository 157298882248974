/* istanbul ignore file */
import { get } from "lodash";
import {
  ICatalog,
  TimeZoneCatalog,
} from "../infrastructure/constants/time-zone-catalogues";

const _timeZoneDefault: string = "America/Guayaquil";

export class timeZoneMapper {
  public static timeZoneHour(text: string | undefined, value: string): string {
    if (text === undefined)
      return value === "utc" ? "-05:00" : _timeZoneDefault;

    const index: number = TimeZoneCatalog.findIndex(
      (data: ICatalog) => data.description === text
    );

    if (index >= 0) {
      const lang: string | undefined = get(
        TimeZoneCatalog[index],
        value,
        undefined
      );

      return lang !== undefined
        ? lang
        : value === "utc"
        ? "-05:00"
        : _timeZoneDefault;
    }

    return value === "utc" ? "-05:00" : _timeZoneDefault;
  }
}
