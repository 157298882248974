import { CellHeaderProps } from "@kushki/connect-ui/dist/Components/Atoms/Table/CellHeader";
import { ISort } from "@kushki/connect-ui/dist/Shared/Utils/IconSortArrow/IconSortArrow.interfaces";
import { SortColumnsItem } from "@shared/constants/SelectedColumns";

export const buildHeaderTable = (
  selectedColumns: SortColumnsItem[],
  onClickSort: Function,
  actualSort: ISort,
  numClick: number
): CellHeaderProps[] => {
  const headerTableCells: CellHeaderProps[] = [];

  selectedColumns.forEach((column: SortColumnsItem) => {
    if (column.isSelected) {
      headerTableCells.push({
        actualSort,
        align: "left",
        field: column.sortField,
        isSort: column.isSort,
        numClick,
        onClickSort,
        spacing: 0,
        text: column.label,
        type: "default",
        width: 300,
      });
    }
  });

  headerTableCells.push({
    align: "left",
    spacing: 0,
    text: "",
    type: "default",
  });

  return headerTableCells;
};
