import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";

export const joinItemList = (
  listSelect: ICategory[],
  separator: string,
  lastChar: string = "",
  isDescription: boolean = false
): string => {
  return (
    listSelect
      .map((data) => (isDescription ? data.description : data.label))
      .join(separator) + lastChar
  );
};
