import { FormControl, MenuItem, Select } from "@material-ui/core";
import React from "react";

export interface IKshSelectInput {
  id: string;
  label?: string;
  options: { key: string; value: string }[];
  value?: string;
  onChange(value: any): void;
  onBlur?(value: React.FocusEvent<{ name?: string; value: unknown }>): void;
  defaultValue?: string;
}

export const KshSelectInput: React.FC<IKshSelectInput> = (
  props: IKshSelectInput
) => {
  return (
    <React.Fragment>
      <FormControl
        variant="outlined"
        fullWidth
        style={{
          marginTop: "8px",
          marginBottom: "4px",
        }}
      >
        <Select
          id={props.id}
          value={props.value}
          defaultValue={props.defaultValue}
          displayEmpty={true}
          onChange={props.onChange}
          name={props.id}
          margin="dense"
          onBlur={props.onBlur}
          style={{ height: "auto" }}
        >
          <MenuItem value="cash">
            <em>{props.label}</em>
          </MenuItem>
          {props.options.map((option: { key: string; value: string }) => (
            <MenuItem key={option.key} value={option.key}>
              {option.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};
