import { ICellTextComponent } from "./CellTitle.interfaces";
import { FC } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { TableBodyStyles } from "../../Table/TableBody/TableBody.style";

export const CellTitleItem: FC<ICellTextComponent> = ({
  line1,
  line2,
  align,
  tooltip,
}: ICellTextComponent) => {
  let styles = TableBodyStyles;

  return (
    <Box alignItems={align}>
      {tooltip ? (
        <Tooltip
          title={
            <>
              {line1!}
              <br />
              {line2 !== "-" && line2!}
            </>
          }
          placement="top"
        >
          <Typography sx={styles.title}>{line1!}</Typography>
        </Tooltip>
      ) : (
        <Typography sx={styles.title}>{line1!}</Typography>
      )}

      {line2 !== "-" && <Typography sx={styles.subtitle}>{line2!}</Typography>}
    </Box>
  );
};
