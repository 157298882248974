import {
  Box,
  Button,
  createStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  configType,
  navigateRoute,
  StatusSemaphore,
  StepsEnum,
} from "../../../shared/infrastructure/constants/CreateMerchantConstants";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { StatusSteps } from "../../../../types/remote/semaphore_data";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { get } from "lodash";
import { updateStepStatus } from "../../../store/actionCreators";
import { Dispatch } from "redux";

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    containedButton: {
      fontSize: "16px",
    },
    button: {
      color: "#6D7781",
      fontSize: "16px",
    },
  })
);

export interface DiscountPendingProps {
  status: StatusSteps;
  publicMerchantId: string;
}

export const DiscountPending: React.FC<Partial<DiscountPendingProps>> = () => {
  const classes = useStyles();
  const { status, publicMerchantId }: DiscountPendingProps = useSelector(
    (state: IAppState) => ({
      status: get(
        state,
        "semaphore.stepConfigRatesAndInvoice.statusDiscount",
        "complete"
      ),
      publicMerchantId: get(state, "merchantAffiliation.publicMerchantId", ""),
    })
  );

  const dispatch: Dispatch<any> = useDispatch();

  const omitDiscountStep = () => {
    dispatch(
      updateStepStatus(
        StepsEnum.stepConfigRatesAndInvoice,
        "omitted",
        publicMerchantId,
        "statusDiscount"
      )
    );
  };

  const redirectConfiguration = () => {
    if (publicMerchantId)
      navigateRoute(
        `/rate/${publicMerchantId}?configType=${configType.configDiscount}&hideSideBar=true`
      );
  };

  return status === StatusSemaphore.pending ? (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      bgcolor="background.default"
      borderRadius={4}
      py={3}
      mb={2}
    >
      <Typography variant="h5" gutterBottom>
        <Box fontWeight={300} display="inline">
          Aún no has agregado{" "}
        </Box>
        <Box fontWeight={400} display="inline">
          descuento
        </Box>
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.containedButton}
        size="medium"
        onClick={redirectConfiguration}
      >
        Agregar descuento
      </Button>
      <Button
        onClick={() => {
          omitDiscountStep();
        }}
        variant="text"
        className={classes.button}
        size="medium"
      >
        Omitir este paso
      </Button>
      <Typography variant="subtitle2" gutterBottom>
        También podrás agregar descuento una vez terminado de configurar el
        comercio en la sección de Configuración
      </Typography>
    </Box>
  ) : null;
};
