import { ActionTypes } from "./actionTypes";
import { ITableAction, ITableState } from "./interfaces";

export const TABLE_INITIAL_STATE: ITableState = {
  allowDeletion: false,
  allowSelection: false,
  isInitialLoading: false,
  isLoading: false,
  selectAllState: "unChecked",
  selectedRows: [],
  valueCells: [],
  valuesCellsToBlock: [],
};

export const tableReducer = (
  state: ITableState = TABLE_INITIAL_STATE,
  action: ITableAction
): ITableState => {
  switch (action.type) {
    case ActionTypes.SET_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: action.selectedRows!,
      };
    case ActionTypes.SET_CELL_VALUES:
      return {
        ...state,
        valueCells: action.valueCells!,
      };
    default:
      return state;
  }
};
