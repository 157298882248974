import { useEffect, useState } from "react";
import { defaultTo, get, isEmpty, isNil, last, split, union } from "lodash";
import {
  IChargebackForm,
  IUploadFile,
} from "../../../shared/interfaces/IChargebackForm";
import { SubmitHandler, useForm } from "react-hook-form";
import { uploadChargebackFiles } from "../../../store/thunks/chargeback/chargeback.thunks";
import { setDisableActionButtons } from "../../../store/actions/chargeback.actions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { selectChargeback } from "../../../store/selectors/selectors";
import { useParams } from "react-router";
import {
  IChargebackFilesState,
  ITargetBuildFile,
  IUploadFileState,
  UploadFilesRequest,
} from "./useChargebackManageFiles.interfaces";
import { REDIRECT_CHARGEBACK_STATUS } from "../../../shared/constants/ChargebackConstants";
import { ROUTES } from "../../../shared/constants/routes";

export const useChargebackManageFiles = (): IChargebackFilesState => {
  const dispatch = useAppDispatch();
  const {
    chargebackTransaction,
    isLoading,
    notification,
    isWaitingForRequest,
    disableActionButtons,
  } = useAppSelector(selectChargeback);
  const { transactionReference } = useParams();

  const targetsBuildFiles: ITargetBuildFile[] = [
    { referenceValue: true, target: "letterFiles" },
    { referenceValue: false, target: "additionalFiles" },
  ];
  const targetsValidations: string[] = ["letterFiles", "additionalFiles"];
  const form = useForm<IChargebackForm>({
    defaultValues: {
      additionalFiles: [],
      letterFiles: [],
    },
    mode: "all",
  });
  const { clearErrors, setValue } = form;
  const [letterFilesState, setStateLetterFiles] = useState<IUploadFileState>({
    files: [],
    key: "",
  });
  const [additionalFilesState, setStateAdditionalFiles] =
    useState<IUploadFileState>({
      files: [],
      key: "",
    });
  const buildRequest = (
    formData: IChargebackForm,
    transactionReference: string
  ): UploadFilesRequest => {
    const request: UploadFilesRequest = {
      files: [],
      transactionReference,
      uploadDocumentsTraceInfo: {
        operationTime: Date.now(),
        userName: localStorage.getItem("username")!,
      },
    };

    targetsBuildFiles.forEach((value: ITargetBuildFile) => {
      formData[value.target].forEach((item: IUploadFile) => {
        request.files.push({
          data: get(item, "value", ""),
          fileName: get(item, "name", ""),
          isLetter: value.referenceValue,
        });
      });
    });

    return request;
  };

  const validateSizeFiles = (formData: IChargebackForm): boolean => {
    let counter: number = 0;

    targetsValidations.forEach((target: string) => {
      formData[target].forEach((item: IUploadFile) => {
        counter += get(item, "size", 0);
      });
    });

    return counter > 24 ? false : true;
  };

  const validateUploadFields = (formData: IChargebackForm): boolean => {
    let counter: number = 0;

    targetsValidations.forEach((target: string) => {
      if (formData[target].length === 0) {
        counter++;
      }
    });

    return counter > 0 ? false : true;
  };

  const handleSubmitForm: SubmitHandler<IChargebackForm> = async (formData) => {
    const sizeValidation: boolean = validateSizeFiles(formData);

    if (validateUploadFields(formData) && sizeValidation) {
      dispatch(setDisableActionButtons(true));
      dispatch(
        await uploadChargebackFiles(
          buildRequest(formData, transactionReference!)
        )
      );
    }
  };

  const handleFilesUploaded = (event: any, key: string): void => {
    if (!isNil(get(event, "target.files[0].name"))) {
      let files = event.target.files;
      let fileValues: IUploadFile[] = [];
      let sizeFile: number = 0;

      for (let index = 0; index < files.length; index++) {
        let reader = new FileReader();

        const fileName: string = event.target.files[index].name;

        sizeFile = event.target.files[index].size / 1024 / 1024;
        reader.onload = () => {
          const value: string = defaultTo(
            last(split(String(reader.result), ",")),
            ""
          );

          if (key === "letterFiles") {
            fileValues = [{ name: fileName, size: sizeFile, value }];
            setStateLetterFiles({ files: fileValues, key });
            clearErrors("letterFiles");
          } else {
            fileValues.push({ name: fileName, size: sizeFile, value });
            fileValues = union(additionalFilesState.files, fileValues);
            setStateAdditionalFiles({ files: fileValues, key });
            clearErrors("additionalFiles");
          }
        };
        reader.readAsDataURL(files[index]);
      }
    }
  };
  const handleRemoveFile = (key: string, index: number): void => {
    let fileValues: IUploadFile[];

    if (key === "letterFiles") {
      fileValues = [];
      setStateLetterFiles({ files: fileValues, key });
    } else {
      fileValues = [...additionalFilesState.files];
      fileValues.splice(index, 1);
      setStateAdditionalFiles({ files: fileValues, key });
    }
  };

  const handleCancelAction = (): void => {
    window.location.href = ROUTES.CHARGEBACK;
  };

  useEffect(() => {
    if (!isEmpty(letterFilesState.key))
      setValue("letterFiles", get(letterFilesState, "files", []));

    if (isEmpty(letterFilesState.files) || isEmpty(additionalFilesState.files))
      dispatch(setDisableActionButtons(true));
    else dispatch(setDisableActionButtons(false));
  }, [letterFilesState]);

  useEffect(() => {
    if (!isEmpty(additionalFilesState.key))
      setValue("additionalFiles", get(additionalFilesState, "files", []));

    if (isEmpty(additionalFilesState.files) || isEmpty(letterFilesState.files))
      dispatch(setDisableActionButtons(true));
    else dispatch(setDisableActionButtons(false));
  }, [additionalFilesState]);

  useEffect(() => {
    const transaction_status: string = get(
      chargebackTransaction,
      "transaction_status",
      ""
    );

    if (
      !isEmpty(transaction_status) &&
      !REDIRECT_CHARGEBACK_STATUS.includes(transaction_status)
    ) {
      handleCancelAction();
    }
  }, []);

  return {
    actions: {
      handleCancelAction,
      handleFilesUploaded,
      handleRemoveFile,
      handleSubmitForm,
    },
    disableButtons: disableActionButtons,
    form,
    isLoading: isLoading,
    isWaitingForRequest: isWaitingForRequest,
    notification: notification,
    transactionDetails: chargebackTransaction,
  };
};
