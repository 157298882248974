/* istanbul ignore file */
/* eslint-disable */
export const environment: {
    kushkiUrl: string;
    envName: string;
    production:boolean;
    downloadWSPreprocess: string;
    processWSBillingData: string;
    firebase_env: string;
    devTools: boolean;
    ivaMexico: string;
    ivaColombia: string;
    ivaEC: string;
    ivaChile: string;
    ivaPeru: string;
    firebaseConfig: {
        apiKey: string;
        authDomain: string;
        databaseURL: string;
        projectId: string;
        storageBucket: string;
        messagingSenderId: string;
    };
} = {
    kushkiUrl: "https://api.kushkipagos.com",
    envName: "primary",
    production:true,
    downloadWSPreprocess: "wss://api-ws.kushkipagos.com/billing-reports",
    processWSBillingData: "wss://api-ws.kushkipagos.com/billing-core",
    firebase_env: 'uat',
    devTools: false,
    ivaMexico: "16",
    ivaColombia: "19",
    ivaEC: "15",
    ivaChile: "19",
    ivaPeru: "18",
    firebaseConfig: {
        apiKey:'AIzaSyAXtQlq6sXC3-cVMCUvwp6DUC1RteuT4VA',
        authDomain:'kushki-97eba.firebaseapp.com',
        databaseURL: 'https://kushki-97eba.firebaseio.com',
        projectId: 'kushki-97eba',
        storageBucket: 'kushki-97eba.appspot.com',
        messagingSenderId: '821114948879'
    },
};
