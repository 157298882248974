import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "rgba(2,51,101,1)",
      dark: "rgba(17,43,69,1)",
      light: "rgba(30,101,174,1)",
    },
    secondary: {
      main: "rgba(0,230,178,1)",
      dark: "rgba(13,194,152,1)",
      light: "rgba(222,255,247,1)",
    },
    info: {
      main: "rgba(68,152,238,1)",
      dark: "rgba(2,51,101,1)",
      light: "rgba(212,234,255,0.4)",
    },
    error: {
      main: "rgba(226,71,99,1)",
      dark: "rgba(173,12,42,1)",
      light: "rgba(255,223,229,0.3)",
    },
    warning: {
      main: "rgba(255,215,64,1)",
      dark: "rgba(180,120,0,1)",
      light: "rgba(252,235,193,0.3)",
    },
    success: {
      main: "rgba(0,230,178,1)",
      dark: "rgba(13,194,152,1)",
      light: "rgba(210,255,244,0.3)",
    },
    background: {
      paper: "#fff",
      default: "rgba(240,244,249,1)",
    },
    text: {
      primary: "rgba(41,48,54,1)",
      secondary: "rgba(46, 61, 74, 0.70)",
      disabled: "rgba(46, 61, 74, 0.36)",
    },
    grey: {
      A400: "rgba(41,48,54,1)",
      A700: "rgba(70,82,92,1)",
    },
  },
  typography: {
    fontFamily: "IBM Plex Sans,sans-serif",
    h1: {
      fontSize: "40px",
      fontWeight: 200,
      lineHeight: "140%",
      fontStyle: "normal",
    },
    h2: {
      fontSize: "32px",
      fontWeight: 500,
      lineHeight: "140%",
      fontStyle: "normal",
    },
    h3: {
      fontSize: "28px",
      fontWeight: 500,
      lineHeight: "140%",
      fontStyle: "normal",
    },
    h4: {
      fontSize: "24px",
      fontWeight: 500,
      lineHeight: "140%",
      fontStyle: "normal",
    },
    h5: {
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "140%",
      fontStyle: "normal",
      color: "#023365",
    },
    h6: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "140%",
      fontStyle: "normal",
    },
    subtitle1: {
      fontSize: "13px",
      color: "#293036",
    },
    subtitle2: {
      fontSize: "12px",
      fontWeight: 350,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        paddingLeft: 25,
        paddingRight: 25,
      },
      outlined: {
        paddingLeft: 25,
        paddingRight: 25,
      },
    },
    MuiCardHeader: {
      root: {
        paddingLeft: 24,
        paddingRight: 24,
      },
    },
    MuiCardContent: {
      root: {
        paddingLeft: 24,
        paddingRight: 24,
      },
    },
    MuiFormHelperText: {
      root: {
        marginLeft: "0px !important",
      },
    },
  },
});
export default theme;
