import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { TextfieldAmountInput } from "../FormInputs/TextfieldAmountInput/TextfieldAmountInput";
import { TextAreaInput } from "../FormInputs/TextAreaInput/TextAreaInput";
import { UploadFileInput } from "../FormInputs/UploadFileInput/UploadFileInput";
import { FieldNames } from "../../shared/constants/Form";
import { IWalletBalanceFormProps } from "./WalletBalanceForm.interfaces";
import { RECHARGE_FORM_TEXTS } from "../../shared/constants/labels/form_labels";
import { WalletBalanceFormStyles } from "./WalletBalanceForm.styles";
import { validateFile } from "../../store/utils/store-utils";

export const WalletBalanceForm = ({
  currency,
  transactionType,
  form,
  closeAlert,
}: IWalletBalanceFormProps) => {
  return (
    <Box>
      <Box>
        <Typography color="text.primary" variant="h5">
          {RECHARGE_FORM_TEXTS.title}
        </Typography>
        <Divider sx={WalletBalanceFormStyles.divider} />
      </Box>
      <Box>
        <Typography
          sx={WalletBalanceFormStyles.text}
          variant={"subtitle1"}
          color={"text.grey"}
        >
          {RECHARGE_FORM_TEXTS.amountLabel}
        </Typography>
        <TextfieldAmountInput
          name={FieldNames.TOTAL_AMOUNT}
          register={form.register}
          rules={{
            min: {
              message: RECHARGE_FORM_TEXTS.amountValueErrorMessage,
              value: 1,
            },
            pattern: {
              message: RECHARGE_FORM_TEXTS.amountValueDecimalsLimit,
              value: /^(\d{1,3}(\,\d{3})*|(\d+))(\.\d{1,2})?$/,
            },
            required: RECHARGE_FORM_TEXTS.amountErrorMessage,
          }}
          errors={form.formState.errors}
          currency={currency}
        />
        <Typography
          sx={WalletBalanceFormStyles.text}
          variant={"subtitle1"}
          color={"text.grey"}
        >
          {RECHARGE_FORM_TEXTS.description}
        </Typography>
        <TextAreaInput
          name={FieldNames.DESCRIPTION}
          register={form.register}
          rules={{
            required: RECHARGE_FORM_TEXTS.textAreaRequiredMessage,
          }}
          errors={form.formState.errors}
          value={form.watch(FieldNames.DESCRIPTION) as string}
          maxLength={500}
        />
        <UploadFileInput
          name={FieldNames.FILE}
          rules={{
            required: RECHARGE_FORM_TEXTS.uploadFileErrorMessage,
            validate: validateFile,
          }}
          resetFile={form.resetField}
          trigger={form.trigger}
          errors={form.formState.errors}
          register={form.register}
          value={form.watch(FieldNames.FILE) as object}
          transactionType={transactionType}
          closeAlert={closeAlert}
        />
      </Box>
    </Box>
  );
};
