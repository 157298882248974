import React from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  headerTableItem,
  LabelsDispersion,
} from "../../shared/infrastructure/constants/RequestDispersionConstants";
import { dispersionsTableStyle } from "./DispersionsTable.style";
import { Download } from "react-feather";
import { useDispersionTableState } from "./state/useDispersionTableState";
import { TableSkeleton } from "../TableSkeleton/TableSkeleton";
import { defaultTo, get } from "lodash";
import { Data } from "../../../types/filter_dispersion_response";
import { FilterEnum } from "../../shared/infrastructure/constants/FilterEnum";
import Pagination from "../Pagination/Pagination";
import { formatDateWithTimezone } from "../../shared/utils/date-utils";

export const DispersionsTable = () => {
  const classes = dispersionsTableStyle();
  const {
    retrieveWithTable,
    isLoadingGetDispersionFile,
    getFileDispersionResponse,
    renderStatusText,
    handleBank,
    handleRedirectToValidationDetails,
    getChipClass,
  } = useDispersionTableState(classes);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headerTableItem.map((data: string, index: number) => (
              <TableCell
                key={`${data}-${index}`}
                className={classes.title}
                style={{ width: retrieveWithTable(index) }}
              >
                {data}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingGetDispersionFile ? (
            <TableSkeleton />
          ) : (
            defaultTo(getFileDispersionResponse!.data, []).map(
              (item: Data, index: number) => (
                <TableRow key={`${item.created}-${index}`}>
                  <TableCell component="th" scope="row">
                    <Box className={classes.date}>
                      {formatDateWithTimezone(
                        String(get(item, "created", "")),
                        "yyyy-MM-dd"
                      )}
                      <Typography
                        variant={"subtitle2"}
                        className={classes.date}
                      >
                        {formatDateWithTimezone(
                          String(get(item, "created", "")),
                          "HH:mm:ss"
                        )}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell className={classes.textItem}>
                    {item.referenceNumber}
                  </TableCell>
                  <TableCell className={classes.textItem}>
                    {(item.status === FilterEnum.PROCESSING ||
                      item.status === FilterEnum.PROCESSED ||
                      item.status === FilterEnum.FAILED) &&
                    !isNaN(item.totalAmount)
                      ? `${item.totalAmount} ${item.currency}`
                      : "-"}
                  </TableCell>
                  <TableCell>
                    <Button
                      disabled={item.status !== FilterEnum.PROCESSED}
                      startIcon={<Download size={18} />}
                      variant={"outlined"}
                      className={classes.downloadButton}
                      onClick={() => {
                        handleBank(item.referenceNumber);
                      }}
                    >
                      {LabelsDispersion.DOWNLOAD}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Box
                      onClick={
                        item.status === FilterEnum.FAILED ||
                        item.status === FilterEnum.PARTIALLY_PROCESSED
                          ? () =>
                              handleRedirectToValidationDetails(
                                item.referenceNumber
                              )
                          : () => {}
                      }
                      className={getChipClass(item.status).chip}
                    >
                      <Typography className={getChipClass(item.status).text}>
                        {renderStatusText(item.status)}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )
            )
          )}
        </TableBody>
      </Table>
      <Pagination total={getFileDispersionResponse!.total} />
    </TableContainer>
  );
};
