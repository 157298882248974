export const enum StatusEnum {
  REJECTED = "REJECTED",
  ENABLE = "ENABLED",
  DISABLE = "DISABLE",
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DRAFT = "DRAFT",
  DELETE = "DELETE",
}

export const STATUS_SPANISH: Record<string, string> = {
  [StatusEnum.REJECTED]: "rechazado",
  [StatusEnum.ENABLE]: "habilitado",
  [StatusEnum.DISABLE]: "deshabilitado",
  [StatusEnum.PENDING]: "pendiente",
  [StatusEnum.APPROVED]: "aprobado",
  [StatusEnum.DRAFT]: "borrador",
};
