export enum FilterLabelEnum {
  APPROVED = "Aprobado",
  DECLINED = "Declinado",
  NOT_EXIST = "No existe",
  SALE = "Sale",
  REFUND = "Refund",
  VOID = "Void",
  CONCILIATED = "Conciliado",
  OMITTED = "Omitido",
  APPROVED_REFUND = "Reembolso Aprobado",
  REFUSED_REFUND = "Reembolso Rechazado",
  PENDING_REFUND = "Reembolso Pendiente",
  MXN_CURRENCY = "USD",
}
