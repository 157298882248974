import { Box, MenuItem, Select } from "@mui/material";
import { useHeaderFilterState } from "./state/useHeaderFilterState";
import {
  SelectCountry,
  SelectPaymentMethod,
} from "../../shared/infrastructure/constants/HeaderFilters";
import { HeaderFilterStyles as styles } from "./HeaderFilter.styles";

export const HeaderFilter: React.FC = () => {
  const {
    countries,
    handler: {
      setSelectedProcessorName,
      setSelectedPaymentMethod,
      setSelectedCountry,
    },
    paymentMethods,
    processors,
    selectedProcessorName,
    selectedPaymentMethod,
    selectedCountry,
  } = useHeaderFilterState();

  return (
    <Box
      display={"flex"}
      justifyContent={"flex-start"}
      width={"100%"}
      pt={4}
      pb={4}
    >
      <Select
        inputProps={{ "data-testid": "country" }}
        defaultValue={selectedCountry}
        onChange={(event) => {
          setSelectedCountry(event.target.value);
        }}
        sx={styles.select}
      >
        {countries.map((country: SelectCountry, index: number) => (
          <MenuItem key={index} value={country.value} sx={styles.menuItem}>
            {country.label}
          </MenuItem>
        ))}
      </Select>
      <Select
        inputProps={{ "data-testid": "paymentMethod" }}
        defaultValue={selectedPaymentMethod}
        onChange={(event) => {
          setSelectedPaymentMethod(event.target.value);
        }}
        sx={styles.select}
      >
        {paymentMethods.map(
          (paymentMethod: SelectPaymentMethod, index: number) => (
            <MenuItem
              key={index}
              value={paymentMethod.value}
              sx={styles.menuItem}
            >
              {paymentMethod.label}
            </MenuItem>
          )
        )}
      </Select>
      <Select
        inputProps={{ "data-testid": "processorName" }}
        defaultValue={selectedProcessorName}
        onChange={(event) => {
          setSelectedProcessorName(event.target.value);
        }}
        sx={styles.select}
      >
        {processors.map((processor: string, index: number) => (
          <MenuItem key={index} value={processor} sx={styles.menuItem}>
            {processor}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
