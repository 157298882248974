import { ITableHeaderProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { CellHeaderProps } from "@kushki/connect-ui/dist/Components/Atoms/Table/CellHeader";
import { TypeEnum } from "@shared/constants/AlarmConfigConstants";

const individualTableCells: CellHeaderProps[] = [
  {
    align: "left",
    spacing: 0,
    text: "Fecha de creación",
    type: "default",
    width: 250,
  },
  {
    align: "left",
    spacing: 0,
    text: "Alias",
    type: "default",
    width: 150,
  },
  {
    align: "left",
    spacing: 0,
    text: "Frecuencia",
    type: "default",
    width: 150,
  },
  {
    align: "left",
    spacing: 0,
    text: "Branches/Merchant",
    type: "default",
    width: 250,
  },
  {
    align: "left",
    spacing: 0,
    text: "Variables de alarma",
    type: "default",
    width: 250,
  },
  {
    align: "left",
    spacing: 0,
    text: "Estado",
    type: "default",
    width: 100,
  },
  {
    align: "left",
    spacing: 0,
    text: "",
    type: "default",
  },
];

const generalTableCells: CellHeaderProps[] = [
  {
    align: "left",
    spacing: 0,
    text: "Fecha de creación",
    type: "default",
    width: 300,
  },
  {
    align: "left",
    spacing: 0,
    text: "Alias",
    type: "default",
    width: 300,
  },
  {
    align: "left",
    spacing: 0,
    text: "Variables de alarma",
    type: "default",
    width: 300,
  },
  {
    align: "left",
    spacing: 0,
    text: "Estado",
    type: "default",
    width: 300,
  },
  {
    align: "left",
    spacing: 0,
    text: "",
    type: "default",
  },
];

const cellByType: { [key: string]: CellHeaderProps[] } = {
  [TypeEnum.INDIVIDUAL]: individualTableCells,
  [TypeEnum.GENERAL]: generalTableCells,
};

const DEFAULT_TABLE_CELLS = (type: string): ITableHeaderProps => ({
  cells: cellByType[type],
  headerProps: {
    isDisable: false,
  },
});

export { DEFAULT_TABLE_CELLS };
