import { EntityName } from "@shared/enum/entityNameEnum";
import { INodeRuleSessionStorage } from "@shared/interfaces/INodeRuleSessionStorage";
import { get } from "lodash";
import { Merchant } from "../../../types/search_merchants_response";

export interface INodeSelectedParsed {
  owner: INodeRuleSessionStorage[];
  customer: INodeRuleSessionStorage[];
  branch: INodeRuleSessionStorage[];
}
export const buildSelectedNodesByEntityName = (
  selectedNodes: INodeRuleSessionStorage[]
): INodeSelectedParsed => {
  const nodeSelectedParsed: INodeSelectedParsed = {
    branch: [],
    customer: [],
    owner: [],
  };

  selectedNodes.forEach((node) => {
    if (node.entityName === EntityName.OWNER) {
      nodeSelectedParsed.owner.push(node);
    } else if (node.entityName === EntityName.CUSTOMER) {
      nodeSelectedParsed.customer.push(node);
    } else {
      nodeSelectedParsed.branch.push(node);
    }
  });

  return nodeSelectedParsed;
};

export const buildBranchData = (
  data: INodeRuleSessionStorage[]
): Partial<Merchant>[] => {
  const response: Partial<Merchant>[] = [];

  data.forEach((item) => {
    response.push({
      categoryMerchant: item.category,
      constitutionalCountry: item.constitutionalCountry,
      country: get(item, "country", ""),
      entityName: item.entityName,
      name: item.name,
      nodeId: item.nodeId,
      path: item.path,
      publicMerchantId: get(item, "merchantId", ""),
    });
  });

  return response;
};
