import React from "react";
import { Container, Drawer, Grid, Box } from "@mui/material";
import { CustomerHeader } from "../../components/CustomerHeader/CustomerHeader";
import { FilterTable } from "../../components/FilterTable/FilterTable";
import { IconChevronLeft } from "@kushki/connect-ui";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useBusinessRulesListIndexState } from "./state/useBusinessRulesListIndexState";
import { get } from "lodash";
import { NodeInfoPathEnum } from "../../shared/infrastructure/enums/NodeInfoPathEnum";
import { LabelEnum } from "../../shared/infrastructure/enums/LabelEnum";
import { isCentralizedBranch } from "../../utils/utilsFile";
import { ModalContainer } from "../../components/ModalContainerBranch/ModalContainerBranch";

export interface IBusinessRulesListIndexProps {
  isBatch?: boolean;
}

export const BusinessRulesListIndex: React.FC<IBusinessRulesListIndexProps> = ({
  isBatch,
}: IBusinessRulesListIndexProps) => {
  const navigate = useNavigate();
  const { nodeInfoMerchant, isOpenModal } = useBusinessRulesListIndexState();

  return (
    <Container fixed>
      <Grid container>
        <Drawer anchor="right" open={isOpenModal}>
          <Box sx={{ width: "750px" }}>
            <ModalContainer tabName={LabelEnum.BUSINESS_RULES} />
          </Box>
        </Drawer>
        <Grid container item xs={12} direction={"column"}>
          <Grid item>
            <Button
              onClick={() => {
                navigate(-1);
              }}
              startIcon={<IconChevronLeft />}
              className={"buttonLink"}
              color="primary"
            >
              {LabelEnum.BACK}
            </Button>
          </Grid>

          <Grid item>
            {!isBatch && (
              <CustomerHeader
                title={get(nodeInfoMerchant, NodeInfoPathEnum.INFO_NAME, "")}
                tagLabel={LabelEnum.ACTIVE.toUpperCase()}
                tagColor={"success"}
                subtitle={LabelEnum.CONSTITUTIONAL_COUNTRY}
                data={get(nodeInfoMerchant, NodeInfoPathEnum.MERCHANT_ID, "")}
                flag={get(
                  nodeInfoMerchant,
                  NodeInfoPathEnum.INFO_CONSTITUTIONAL_COUNTRY,
                  ""
                )}
                constitutionalCountry={get(
                  nodeInfoMerchant,
                  NodeInfoPathEnum.INFO_CONSTITUTIONAL_COUNTRY,
                  ""
                )}
              />
            )}
            <FilterTable
              addRuleButton={!isCentralizedBranch()}
              title={LabelEnum.BUSINESS_RULES}
              isBatch={isBatch}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
