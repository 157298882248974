import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import MerchantInformation from "../../containers/MerchantInformation/MerchantInformation";
import BasicInformation from "../../containers/BasicInformation/BasicInformation";
import { useBasicSectionStyles } from "./BasicSection.styles";

const BasicSection: FC = () => {
  const styles = useBasicSectionStyles();

  return (
    <Grid container className={styles.container}>
      <Grid container item xs={12} lg={12} className={styles.item}>
        <Grid item xs={12} lg={6} className={styles.item}>
          <BasicInformation title="Datos del comercio" />
        </Grid>
        <Grid item xs={12} lg={6} className={styles.item}>
          <MerchantInformation />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BasicSection;
