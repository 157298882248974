import React from "react";
import { FormControl, Grid } from "@mui/material";
import { Controller } from "react-hook-form";
import { useConditionSimpleLabelState } from "./state/useConditionSimpleLabelState";
import { ITEM_SIMPLE_CONDITION } from "../../../../shared/infrastructure/enums/ItemByTagEnum";
import { get } from "lodash";
import { IValuesConditionsProps } from "../../../../shared/infrastructure/interfaces/IValuesConditionsProps";
import { RadioGroupContainer } from "./components/RadioGroupContainer/RadioGroupContainer";

export const ConditionSimpleLabel: React.FC<IValuesConditionsProps> = (
  props: IValuesConditionsProps
) => {
  const { selectSimpleCondition, control, simpleCondition } =
    useConditionSimpleLabelState(props);

  return (
    <Grid item xs={12}>
      <FormControl variant="outlined" fullWidth>
        <Controller
          name={get(ITEM_SIMPLE_CONDITION[props.tag], "name", "")}
          control={control}
          rules={{
            required: false,
          }}
          render={({ ...selectProps }) => (
            <RadioGroupContainer
              tag={props.tag}
              simpleCondition={simpleCondition}
              selectSimpleCondition={selectSimpleCondition}
              selectProps={selectProps}
            />
          )}
        />
      </FormControl>
    </Grid>
  );
};
