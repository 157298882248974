import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const branchManualCreationContainerStyles = createNamedStyles({
  boxContainer: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
});
