import { makeStyles } from "@mui/styles";

export const ConditionsComponentStyles = makeStyles({
  sectionOne: {
    paddingTop: 25,
  },
  sectionTwo: {
    paddingTop: 18.5,
  },
  sectionThree: {
    paddingTop: 35,
  },
  paddingLeft24: {
    paddingLeft: 24,
  },
  paddingTop4: {
    paddingTop: 4,
  },
  paddingTop16: {
    paddingTop: 16,
  },
  paddingTop20: {
    paddingTop: 20,
  },
  paddingTop32: {
    paddingTop: 32,
  },
  paddingTop35: {
    paddingTop: 35,
  },
  paddingTop48: {
    paddingTop: 48,
  },
  paddingLeft64: {
    paddingLeft: 64,
  },
  divider: {
    borderColor: "#98A8B8",
    borderStyle: "solid",
    borderWidth: "0",
    width: 2,
  },
  rootCard: {
    borderRadius: "16px !important",
    paddingBottom: 24,
    paddingLeft: 64,
    paddingTop: 48,
    paddingRight: 62,
  },
  cardContent: {
    padding: 0,
  },
  itemCondition: {
    paddingTop: 34.5,
  },
  noInputPadding: {
    "& input": {
      padding: "0 !important",
    },
  },
  errorColor: {
    color: "rgba(226,71,99,1)",
  },
  subTitleConditions: {
    fontSize: "16px",
    paddingBottom: "14px",
  },
});
