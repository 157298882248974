import { createAction } from "@reduxjs/toolkit";
import { catchError, EMPTY, ignoreElements, switchMap, tap } from "rxjs";
import { filter } from "rxjs/operators";
import { EpicCustom } from "../epics.interfaces";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import axios from "../../../shared/axios-util";
import { GetProcessorsResponse } from "../../../../types/get_processors_response";
import { PaymentMethodEnum } from "../../../shared/enums/PaymentMethodEnum";
import {
  filterPaymentMethods,
  validateChileProcessors,
} from "../../../shared/utils/getProcessors.utils";
import {
  setPaymentMethods,
  setPaymentMethodsStatus,
} from "../../actions/app.actions";
import { RequestStatusEnum } from "../../../shared/enums/RequestStatusEnum";

export const getProcessors = createAction<string>("GET_PROCESSORS");

export const getProcessorsEpic: EpicCustom = ({ action$, dispatch }) =>
  action$.pipe(
    filter(getProcessors.match),
    tap(() => dispatch(setPaymentMethodsStatus(RequestStatusEnum.PENDING))),
    switchMap((country: { payload: string }) =>
      axios.get<GetProcessorsResponse[]>(API_ROUTES.GET_PROCESSORS).pipe(
        tap(({ data }: { data: GetProcessorsResponse[] }) => {
          const paymentMethods: PaymentMethodEnum[] =
            filterPaymentMethods(data);

          validateChileProcessors(country.payload, paymentMethods, data);
          if (paymentMethods.length > 0) {
            dispatch(setPaymentMethods(paymentMethods));
            dispatch(setPaymentMethodsStatus(RequestStatusEnum.SUCCESS));
          } else dispatch(setPaymentMethodsStatus(RequestStatusEnum.ERROR));
        }),
        catchError(() => {
          dispatch(setPaymentMethodsStatus(RequestStatusEnum.ERROR));

          return EMPTY;
        })
      )
    ),
    ignoreElements()
  );
