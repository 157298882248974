import { TransactionInfo } from "../../../types/transactions_data";
import { ContentTaxes } from "../../components/CardInformation/CardInformation.interfaces";
import { get } from "lodash";

const jsonToStringMetaData = (objeto: object): string => {
  return JSON.stringify(objeto, null, " ");
};

export const showMetadataCredentials = (
  transactionInfo: TransactionInfo
): ContentTaxes[] => {
  return [
    {
      body: jsonToStringMetaData({
        metadata: get(transactionInfo, "metadata", {}),
        subscriptionMetadata: get(transactionInfo, "subscriptionMetadata", {}),
      }),
    },
  ];
};
