import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import { ChargebackData } from "../../../../types/chargeback_data";
import { SearchChargebackRequest } from "../../../../types/search_chargeback_request";
import { Chargeback } from "../../../../types/chargeback";
import { INotification } from "../../../containers/ChargebackManageFiles/ChargebackManageFiles";
import { ChargebackResultEnum } from "../../../shared/enums/ConfigEnum";
import { ISnackBar } from "@kushki/connect-ui/dist/Components/Atoms/DataDisplay/SnackBar/SnackBar.interface";
import {
  convertDateCorrectFormat,
  getEndDate,
  getStartDate,
} from "../../../shared/utils/date_utils";
import { TimeEnum } from "../../../shared/enums/TimeEnum";
import { timeZoneHour } from "../../../shared/utils/lists_utils";

export interface ChargebackState {
  chargebackTransaction: Chargeback;
  searchingChargeback: boolean;
  searchChargebackResponse: ChargebackData;
  searchChargebackRequest: SearchChargebackRequest;
  isLoading: boolean;
  isLoadingStatus: boolean;
  isMongo: boolean;
  isWaitingForRequest: boolean;
  notification: INotification;
  notificationStatus: ISnackBar | undefined;
  disableActionButtons: boolean;
}

export const chargebackInitialState: ChargebackState = {
  chargebackTransaction: { created: "01 Jan 1970 00:00:00 GMT" },
  disableActionButtons: true,
  isLoading: false,
  isLoadingStatus: false,
  isMongo: false,
  isWaitingForRequest: false,
  notification: {
    action: ChargebackResultEnum.CHARGEBACK_REQUEST_ERROR,
    message: "",
    open: false,
  },
  notificationStatus: undefined,
  searchChargebackRequest: {
    filter: {
      transactionStatus: "",
    },
    from: convertDateCorrectFormat(
      getStartDate() + ` ${TimeEnum.defaultInitialTime}`,
      true
    ),
    limit: 10,
    offset: 0,
    sort: { field: "created", order: "desc" },
    text: "",
    timeZone: timeZoneHour(localStorage.getItem("timeZone")!, "utc"),
    to: convertDateCorrectFormat(
      getEndDate() + ` ${TimeEnum.defaultFinalTime}`
    ),
  },
  searchChargebackResponse: {
    data: [],
    total: { value: 0 },
  },
  searchingChargeback: false,
};
export const chargebackSlice = createSlice({
  initialState: chargebackInitialState,
  name: "chargeback",
  reducers: {
    resetChargebackRequest(state) {
      state.searchChargebackRequest =
        chargebackInitialState.searchChargebackRequest;
      state.searchChargebackResponse =
        chargebackInitialState.searchChargebackResponse;
    },
    setChargebackTransaction(state, action) {
      state.chargebackTransaction = action.payload;
    },
    setDisableActionButtons(state, action) {
      state.disableActionButtons = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsWaitingForRequest(state, action) {
      state.isWaitingForRequest = action.payload;
    },
    setNotification(state, action) {
      state.notification = action.payload;
    },
    setNotificationStatus(state, action) {
      state.notificationStatus = action.payload;
    },
    setSearchChargebackRequest(state, action) {
      state.searchChargebackRequest = action.payload;
    },
    setSearchChargebackResponse(state, action) {
      state.searchChargebackResponse = get(action.payload, "data");
      state.isMongo = get(action.payload, "is_mongo", false);
    },
    setSearchingChargeback(state, action) {
      state.searchingChargeback = action.payload;
    },
    setSetIsLoadingStatus(state, action) {
      state.isLoadingStatus = action.payload;
    },
  },
});

export default chargebackSlice.reducer;
