import { makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { TextareaAutosize } from "@material-ui/core";
import { StatusEnum } from "../../shared/infrastructure/enums/StatusEnum";

const useStyles = makeStyles((theme: Theme) => ({
  textArea: {
    minWidth: "100%",
    border: "1px solid #d2d2d2",
    borderRadius: "5px",
    padding: "10px",
  },
  dialogPD1: {
    minWidth: "580px !important",
    padding: "8px 14px",
  },
  dialogPD2: {
    minWidth: "580px !important",
    padding: "0px 16px",
  },
  approveData: {
    fontWeight: 600,
    marginBottom: "25px",
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: 600,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export interface ModalApproveRejectProps {
  title: string;
  handleClose: () => void;
  handleAccept: () => void;
  open: boolean;
  actionModal: StatusEnum;
  description: string;
  handleDescription: (value: string) => void;
}

export const ModalApproveReject: React.FC<ModalApproveRejectProps> = (
  props: ModalApproveRejectProps
) => {
  const classes = useStyles();
  const {
    title,
    handleClose,
    handleAccept,
    open,
    actionModal,
    description,
    handleDescription,
  } = props;
  const [actionApprove, setActionApprove] = useState<boolean>(false);

  useEffect(() => {
    setActionApprove(actionModal === StatusEnum.APPROVE);
  }, [actionModal]);
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <MuiDialogTitle
          disableTypography
          className={classes.root}
          id={"dialog-title"}
        >
          <Typography variant="h6" className={classes.title} id={"title_ty"}>
            {title}
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
            id={"btn-x"}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <MuiDialogContent
          id="dialog-content"
          className={actionApprove ? classes.dialogPD2 : classes.dialogPD1}
        >
          {actionApprove && (
            <Typography
              gutterBottom
              className={classes.approveData}
              id={"approve-subtitle"}
            >
              ¿Deseas continuar?
            </Typography>
          )}
          {!actionApprove && (
            <TextareaAutosize
              id={"txt_desc"}
              className={classes.textArea}
              value={description}
              aria-label="Descripción"
              placeholder="Descripción"
              rowsMin={4}
              onChange={(e) => handleDescription(e.target.value)}
            />
          )}
        </MuiDialogContent>
        <MuiDialogActions id="dialog-actions">
          <Button
            id={"btn-cancel"}
            autoFocus
            onClick={handleClose}
            color="inherit"
            variant="text"
            style={{ marginRight: "3em" }}
          >
            <Typography variant={"h6"}>Cancelar</Typography>
          </Button>
          <Button
            id={"btn-ok"}
            onClick={handleAccept}
            color="primary"
            variant="text"
          >
            <Typography variant={"h6"}>
              {actionApprove ? "Sí, continuar" : "Guardar observación"}
            </Typography>
          </Button>
        </MuiDialogActions>
      </Dialog>
    </div>
  );
};
