import React from "react";
import { Grid, MenuItem } from "@mui/material";

import { get } from "lodash";
import { BillingDataFormLabelsEnum } from "../../shared/enums/BillingDataFormLabelsEnum";
import { ErrorBillingData } from "../../shared/enum/BasicStateMX/ErrorMessageMX";
import { FieldsEnum } from "../../shared/enums/FieldsEnum";
import PhoneInputField from "../commonInputs/PhoneInputField/PhoneInputField";
import AddressInputField from "../commonInputs/AddressInputField/AddressInputField";
import { CountryEnum } from "../../shared/countryEnum";
import SelectInputField from "../commonInputs/SelectInputField/SelectInputField";
import { IBillingDataFormNI } from "../../containers/BillingData/BillingDataContainer.interfaces";
import { CatalogNi } from "../../shared/services/catalog-ni";
import { CatalogsEnum } from "../../shared/interfaces/catalogs-enum";
import { Category } from "../../shared/constants/information_constants";
import { ZipCodeComponent } from "../ZipCodeComponent/ZipCodeComponent";

export const BillingDataFormCountriesNI: React.FC<IBillingDataFormNI> = ({
  form,
  cityCatalogNI,
  isCentralizedNode,
  constitutionalCountry,
  country,
}: IBillingDataFormNI) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SelectInputField
            name={FieldsEnum.province}
            control={form.control}
            rules={{ required: ErrorBillingData.STATE }}
            errors={form.formState.errors}
            defaultValue={form.watch(FieldsEnum.province)}
            label={BillingDataFormLabelsEnum.department}
            itemsSelect={get(CatalogNi, `${CatalogsEnum.Provinces}`, []).map(
              (item: Category) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.name}
                </MenuItem>
              )
            )}
            disabled={isCentralizedNode}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInputField
            name={FieldsEnum.city}
            control={form.control}
            rules={{ required: ErrorBillingData.MUNICIPALITYBR }}
            errors={form.formState.errors}
            defaultValue={form.watch(FieldsEnum.city)}
            label={BillingDataFormLabelsEnum.municipality}
            itemsSelect={cityCatalogNI.map((item: Category) => (
              <MenuItem key={item.value} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
            disabled={isCentralizedNode}
          />
        </Grid>
        <Grid item xs={6}>
          <ZipCodeComponent
            {...form}
            watch={form.watch}
            country={CountryEnum.nicaragua}
            control={form.control}
            errors={form.formState.errors}
            disabled={isCentralizedNode}
          />
        </Grid>
        <Grid item xs={6}>
          <AddressInputField
            control={form.control}
            errors={form.formState.errors}
            disabled={isCentralizedNode}
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneInputField
            fieldName={FieldsEnum.phoneNumber}
            labelName={BillingDataFormLabelsEnum.merchant_phone}
            {...form}
            errors={form.formState.errors}
            control={form.control}
            watch={form.watch}
            rules={{
              required: { message: ErrorBillingData.PHONE_NUMBER_EMPTY },
            }}
            country={constitutionalCountry}
            countryCustomer={country}
            disabled={isCentralizedNode}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default BillingDataFormCountriesNI;
