import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConciliationActionEnum } from "../../../shared/enums/concilation_action_enum";
import { IAppState } from "../../interfaces/AppState.interfaces";
import {
  Conciliation,
  Summary,
} from "../../../../types/get_conciliation_transactions_response";
import { INotification } from "../../../shared/infrastructure/interfaces/INotification";

export const initialState: IAppState = {
  accumulatedAmount: 0,
  activeColumns: [],
  conciliationAction: ConciliationActionEnum.OMITTED,
  conciliationList: [],
  downloadFile: "",
  isSuccessUpdate: false,
  loading: false,
  loadingDownload: false,
  loadingText: "",
  notification: {
    message: "Test",
    open: false,
    type: "success",
  },
  openErrorSnackbar: false,
  openSuccessSnackbar: false,
  rowsSelected: [],
  summary: {},
  totalConciliations: 0,
  valueTab: "1",
};

export const appSlice = createSlice({
  initialState,
  name: "app",
  reducers: {
    hideLoading: (state) => {
      state.loading = false;
    },
    hideSnackbar: (state) => {
      state.openSuccessSnackbar = false;
      state.openErrorSnackbar = false;
    },
    setAccumulatedAmount: (state, { payload }: PayloadAction<number>) => {
      state.accumulatedAmount = payload;
    },
    setActiveColumns: (state, action: PayloadAction<string[]>) => {
      state.activeColumns = action.payload;
    },
    setConciliationAction: (
      state,
      action: PayloadAction<ConciliationActionEnum>
    ) => {
      state.conciliationAction = action.payload;
    },
    setConciliationList: (state, action: PayloadAction<Conciliation[]>) => {
      state.conciliationList = action.payload;
    },
    setDownloadFile: (state, action: PayloadAction<string>) => {
      state.downloadFile = action.payload;
    },
    setLoadingDownload: (state, action: PayloadAction<boolean>) => {
      state.loadingDownload = action.payload;
    },
    setLoadingText: (state, action: PayloadAction<string>) => {
      state.loadingText = action.payload;
    },
    setNotification: (state, action: PayloadAction<INotification>) => {
      if (!action.payload.open) {
        action.payload.message = state.notification.message;
        action.payload.type = state.notification.type;
      }
      state.notification = action.payload;
    },
    setRowsSelected: (state, action: PayloadAction<string[]>) => {
      state.rowsSelected = action.payload;
    },
    setSuccessUpdate: (state, action: PayloadAction<boolean>) => {
      state.isSuccessUpdate = action.payload;
    },
    setSummary: (state, action: PayloadAction<Summary>) => {
      state.summary = action.payload;
    },
    setTotalConciliations: (state, action: PayloadAction<number>) => {
      state.totalConciliations = action.payload;
    },
    setValueTab: (state, action: PayloadAction<string>) => {
      state.valueTab = action.payload;
    },

    showErrorSnackbar: (state) => {
      state.openErrorSnackbar = true;
    },
    showLoading: (state) => {
      state.loading = true;
    },
    showSuccessSnackbar: (state) => {
      state.openSuccessSnackbar = true;
    },
  },
});

export default appSlice.reducer;
