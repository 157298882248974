import { Options as LottieOptions } from "react-lottie";
import animationData from "../loader.json";

export const loaderModalOptions: LottieOptions = {
  animationData: animationData,
  autoplay: true,
  loop: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
