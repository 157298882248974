import { get, sortBy as sortbyLodash } from "lodash";

export const sortObjectArray = <T,>(
  array: T[],
  path: string,
  sortBy: "asc" | "desc"
) => {
  if (sortBy === "desc") {
    return sortbyLodash(array, (a: T) =>
      get(a, path, "").toLowerCase()
    ).reverse();
  } else {
    return sortbyLodash(array, (a: T) => get(a, path, "").toLowerCase());
  }
};
