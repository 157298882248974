import React from "react";
import { Box, Button } from "@mui/material";
import { FileUploadContainerStyles as styles } from "./FileUploadContainer.styles";
import { useFileUploadContainer } from "./state/useFileUploadContainer";
import FileUpload from "./Components/FileUpload";
import LoadingFiles from "./Components/LoadingFile";
import {
  ErrorAlertEnum,
  FileStatusEnum,
} from "../../shared/enum/FileUploadEnum";
import FileName from "./Components/FileName";
import AlertComponent from "../Alert/AlertComponent";
import TotalRecords from "../TotalRecords/TotalRecords";

const FileUploadContainer: React.FC = () => {
  const {
    handlers: {
      handleChange,
      handleDrag,
      handleDrop,
      handleCloseFile,
      handleValidateFile,
    },
    state: {
      fileSelected,
      dragActive,
      errorUploadingFile,
      fileStatus,
      showValidateBtn,
      showTotalRecords,
    },
  } = useFileUploadContainer();

  return (
    <Box sx={styles.wrapper}>
      {errorUploadingFile.errorFile && (
        <AlertComponent
          title={ErrorAlertEnum.TITLE}
          description={errorUploadingFile.message}
        />
      )}
      <Box component="div" sx={styles.container}>
        {fileStatus === FileStatusEnum.DEFAULT && (
          <FileUpload
            dragActive={dragActive}
            handleDrag={handleDrag}
            handleDrop={handleDrop}
            handleChange={handleChange}
          />
        )}
        {fileStatus === FileStatusEnum.UPLOADING && (
          <LoadingFiles infoText={"Cargando archivo"} />
        )}
        {fileStatus === FileStatusEnum.WITH_FILE && (
          <FileName
            name={fileSelected.name}
            size={fileSelected.size}
            handleCloseFile={handleCloseFile}
          />
        )}
        {fileStatus === FileStatusEnum.VALIDATING && (
          <LoadingFiles infoText={"Validando archivo"} />
        )}
      </Box>
      <Box>
        {showValidateBtn && (
          <Button
            variant="contained"
            sx={styles.validateButton}
            onClick={handleValidateFile}
          >
            Validar archivo
          </Button>
        )}
        {showTotalRecords && <TotalRecords />}
      </Box>
    </Box>
  );
};

export default FileUploadContainer;
