import React from "react";
import { Grid } from "@material-ui/core";
import { processedDispersionStyle } from "./ProcessedDispersion.style";
import { ProcessedDispersionsHeader } from "../ProcessedDispersionsHeader/ProcessedDispersionsHeader";
import { FilterComponent } from "../FilterComponent/FilterComponent";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { LabelsDispersion } from "../../shared/infrastructure/constants/RequestDispersionConstants";
import { DispersionsTable } from "../DispersionsTable/DispersionsTable";
import { useProcessedDispersionState } from "./state/useProcessedDispersionState";
import { SearchField } from "../SearchField/SearchField";
import DateRangePickerComponent from "../DateRangePickerComponent/DateRangePickerComponent";

export const ProcessedDispersions = () => {
  const classes = processedDispersionStyle();
  const { showAlertOnGetDispersionFileError } = useProcessedDispersionState();

  return (
    <>
      {showAlertOnGetDispersionFileError && (
        <Alert
          icon={<CloseIcon fontSize="inherit" />}
          className={classes.errorAlert}
        >
          {LabelsDispersion.ERROR_PROCESSING_FILES}
        </Alert>
      )}
      <Grid container direction={"column"} className={classes.mainGrid}>
        <Grid item xs={12}>
          <ProcessedDispersionsHeader
            filterComponent={<FilterComponent />}
            pickerComponent={<DateRangePickerComponent />}
            searchComponent={<SearchField />}
          />
        </Grid>
        <Grid item xs={12}>
          <DispersionsTable />
        </Grid>
      </Grid>
    </>
  );
};
