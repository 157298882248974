import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { FC } from "react";

const CheckmarkIcon: FC<SvgIconProps> = ({ sx, ...rest }: SvgIconProps) => (
  <SvgIcon
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    sx={{ p: "1px", ...sx }}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.04078 16.4999C8.78778 16.4999 8.54578 16.3954 8.37253 16.2111L3.91478 11.4637C3.56737 11.0952 3.58662 10.515 3.95512 10.1685C4.32453 9.82196 4.90478 9.8403 5.25037 10.2088L9.03162 14.2339L16.7389 5.79871C17.0818 5.4238 17.6611 5.39905 18.0351 5.74005C18.4082 6.08105 18.4339 6.6613 18.0929 7.03438L9.71728 16.201C9.54587 16.3899 9.30203 16.498 9.0472 16.4999H9.04078Z"
      fill="#231F20"
    />
    <mask
      id="mask0_33_257569"
      maskUnits="userSpaceOnUse"
      x="3"
      y="5"
      width="16"
      height="12"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.04078 16.4999C8.78778 16.4999 8.54578 16.3954 8.37253 16.2111L3.91478 11.4637C3.56737 11.0952 3.58662 10.515 3.95512 10.1685C4.32453 9.82196 4.90478 9.8403 5.25037 10.2088L9.03162 14.2339L16.7389 5.79871C17.0818 5.4238 17.6611 5.39905 18.0351 5.74005C18.4082 6.08105 18.4339 6.6613 18.0929 7.03438L9.71728 16.201C9.54587 16.3899 9.30203 16.498 9.0472 16.4999H9.04078Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_33_257569)">
      <rect width="22" height="22" fill="white" />
    </g>
  </SvgIcon>
);
export default CheckmarkIcon;
