import React from "react";
import { Box, Container, Drawer, Grid } from "@mui/material";
import { FilterTable } from "../../components/FilterTable/FilterTable";
import { IconChevronLeft } from "@kushki/connect-ui";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useBusinessRulesListIndexState } from "./state/useBusinessRulesListIndexState";
import { LabelEnum } from "../../shared/enums/LabelEnum";
import { isCentralizedBranch } from "../../shared/utils/utilsFile";
import { MerchantBasicInfoComponent } from "../../components/MerchantBasicInfoComponent/MerchantBasicInfoComponent";
import { ModalContainer } from "../../components/ModalContainer/ModalContainer";
import { IBusinessRulesListIndexProps } from "../../shared/interfaces/IBusinessRulesListIndexProps";

export const BusinessRulesListIndex: React.FC<IBusinessRulesListIndexProps> = (
  props: IBusinessRulesListIndexProps
) => {
  const navigate = useNavigate();
  const { isOpenModal } = useBusinessRulesListIndexState();

  return (
    <Container fixed>
      <Grid container>
        <Drawer anchor="right" open={isOpenModal}>
          <Box sx={{ width: "750px" }}>
            <ModalContainer tabName={LabelEnum.BUSINESS_RULES} />
          </Box>
        </Drawer>
        <Grid container item xs={12} direction={"column"}>
          <Grid item>
            <Button
              onClick={() => {
                navigate(-1);
              }}
              startIcon={<IconChevronLeft />}
              className={"buttonLink"}
              color="primary"
            >
              {LabelEnum.BACK}
            </Button>
          </Grid>
          <Grid item>
            {!props.isBatch && <MerchantBasicInfoComponent />}
            <FilterTable
              addRuleButton={!isCentralizedBranch()}
              title={LabelEnum.BUSINESS_RULES}
              isBatch={props.isBatch}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
