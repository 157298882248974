import React from "react";
import { get } from "lodash";
import { ModalHeader as ModalHeaderMolecules } from "@kushki/frontend-molecules/modal-header";
import { SecurityRule } from "../../../types/security_rule";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  formatDate,
  formatDateAndHour,
  translateRuleKeys,
} from "../../shared/utils";
import {
  getChipBGValue,
  getChipValue,
  MAP_STATUS_SECURITY_RULE,
  MAP_TYPE_SECURITY_RULE,
  SecurityRuleActionValue,
  SecurityRuleStatusValue,
} from "../../shared/infrastructure/constants/SecurityRulesConstants";
import { ModalContainerType } from "../ModalContainer/ModalContainer";

export interface ModalHeaderProps {
  data: SecurityRule;
  type: ModalContainerType;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      "& .modal-header-container .modal-header-status-value .MuiChip-root": {
        borderRadius: "4px !important",
      },
    },
  })
);

const ModalHeader: React.FC<ModalHeaderProps> = (props: ModalHeaderProps) => {
  const classes = useStyles();
  const securityRuleCondition: boolean =
    props.data.rule!.action === SecurityRuleActionValue["3DS"] ||
    props.data.rule!.action === SecurityRuleActionValue.OTP;
  const date: string = securityRuleCondition
    ? formatDateAndHour(get(props.data, "createdAt", 0))
    : formatDate(get(props.data, "createdAt", 0));
  const status: string = get(
    props,
    "data.status",
    SecurityRuleStatusValue.ENABLED
  );
  const action: string = get(props, "data.rule.action", "");

  return (
    <React.Fragment>
      <div className={classes.container}>
        <ModalHeaderMolecules
          highlightValue={get(props.data, "rule.name", "N/A")}
          tittle={"Detalle de regla de seguridad"}
          secondaryValue={`Creación: ${date}`}
          statusValue={
            securityRuleCondition
              ? translateRuleKeys(status, MAP_STATUS_SECURITY_RULE)
              : translateRuleKeys(get(props, "type"), MAP_TYPE_SECURITY_RULE)
          }
          chipColor={getChipValue(action, status)}
          chipBackGround={getChipBGValue(action, status)}
        />
      </div>
    </React.Fragment>
  );
};

export default ModalHeader;
