import { createSlice } from "@reduxjs/toolkit";
import { ConfigIdEnum } from "../../../shared/constants/initial_state_legal_details";
import { IInitialData } from "../../interfaces/initialData/initialData.interfaces";
import { getNodeInfoInitialData } from "../../thunks/general/general.thunk";

export const initialState: IInitialData = {
  configsInfo: {
    cn001: {},
    cn002: {},
    cn015: {},
    cn016: {},
  },
  nodeInfo: {
    configCoreId: "",
  },
  nodeInfoMassiveBasicData: { configCoreId: "" },
  nodeInfoMassiveBilling: {
    configCoreId: "",
  },
  nodeInfoMassiveContact: {
    configCoreId: "",
  },
  nodeInfoMassiveLegalData: {
    configCoreId: "",
  },
};

export const initialData = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getNodeInfoInitialData.fulfilled, (state, action) => {
      action.payload.configs?.map((data) => {
        switch (data.configuration) {
          case ConfigIdEnum.CN001:
            state.configsInfo.cn001 = data;
            state.nodeInfoMassiveBasicData = action.payload;
            break;
          case ConfigIdEnum.CN002:
            state.configsInfo.cn002 = data;
            state.nodeInfoMassiveContact = action.payload;
            break;
          case ConfigIdEnum.CN016:
            state.configsInfo.cn016 = data;
            state.nodeInfoMassiveBilling = action.payload;
            break;
          case ConfigIdEnum.CN015:
            state.configsInfo.cn015 = data;
            state.nodeInfoMassiveLegalData = action.payload;
            break;
        }

        state.nodeInfo = action.payload;
      });
    });
  },
  initialState,
  name: "initialData",
  reducers: {
    setConfigInfo: (state, action) => {
      state.configsInfo = action.payload;
    },
  },
});

export default initialData.reducer;
