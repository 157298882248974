import React, { FC } from "react";
import { TableTransactions } from "../Table/TableTransactions/TableTransactions";
import { HeaderFilters } from "../HeaderFilters/HeaderFilters";
import { EntityNameEnum } from "../../shared/enums/EntityNameEnum";
import { TRANSACTION_PER_PAGE } from "../../shared/constants/labels/label_rows_per_page";
import { useDashboardTransaction } from "./state/useDashboardTransaction";
import EmptyResults from "../EmptyResults/EmptyResults";
import { IDashboardProps } from "./DashboardTransaction.interfaces";
import { TransactionEnum } from "../../shared/enums/TransactionEnum";
import ModalDetailInfo from "../ModalDetailInfo/ModalDetailInfo";

const DashboardTransaction: FC<IDashboardProps> = ({
  showEmptyScreen,
  emptyAddText,
  basicNodeInfo: { entityName, path },
  transactionData,
  isBranchTab,
  mechantFilter,
}: IDashboardProps) => {
  const {
    countData,
    dataPicker,
    downloadOptions,
    handleChangeFilterDetails,
    paginationProps: {
      handleChangePage,
      handleChangeRowsPerPage,
      page,
      rowsPerPage,
      totalData,
    },
    rows,
    handleSelectedRows,
    resetFilters,
    showEmptyScreen: showEmptyScreenHook,
    subTitle,
    setRangeAmount,
    handleSortQuery,
    rangeAmount,
    setIsApplyFilter,
    isApplyFilter,
    setTransactionInfo,
    modalDetailInfo,
    transactionInfo,
    handleDownloadReceipt,
  } = useDashboardTransaction({
    entityName,
    mechantFilter,
    path,
    showEmptyScreen,
    transactionData,
  });

  return (
    <>
      <HeaderFilters
        entityNameType={entityName as EntityNameEnum}
        getItemsSelected={handleChangeFilterDetails}
        resetFilters={resetFilters}
        isBranchListTab={true}
        subTitle={subTitle!}
        setRangeAmount={setRangeAmount}
        rangeAmountInfo={rangeAmount}
        setIsApplyFilter={setIsApplyFilter}
        isApplyFilter={isApplyFilter}
        downloadFileOptions={downloadOptions}
        dataPicker={dataPicker}
      />
      {showEmptyScreenHook && countData < 1 ? (
        <EmptyResults addText={emptyAddText} title={TransactionEnum.NO_DATA} />
      ) : (
        <TableTransactions
          handleSelectedRows={handleSelectedRows}
          headTable={dataPicker.headerCell}
          handleSortQuery={handleSortQuery}
          rows={rows}
          labelRowsPerPage={TRANSACTION_PER_PAGE}
          paginationProps={{
            handleChangePage,
            handleChangeRowsPerPage,
            page,
            rowsPerPage,
            totalData,
          }}
          isBranchTab={isBranchTab}
          setTransactionInfo={setTransactionInfo}
          setIsOpenModalDetailInfo={modalDetailInfo.setIsOpenModalDetailInfo}
        />
      )}
      {modalDetailInfo.isOpenModalDetailInfo && (
        <ModalDetailInfo
          transactionInfo={transactionInfo}
          modal={modalDetailInfo}
          modalButtonActions={{
            downloadButton: handleDownloadReceipt,
          }}
        />
      )}
    </>
  );
};

export default DashboardTransaction;
