import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import {
  CURRENCY_COUNTRY,
  PAYMENT_METHOD,
  PAYMENT_TYPE,
} from "../../../shared/infrastructure/constants/MerchantInformationConstant";
import { useState } from "react";
import { take } from "lodash";
import { useHistory } from "react-router-dom";
import { routes } from "../../../shared/infrastructure/constants/routes";
import { ResponseTrxRates } from "../../../../types/response_trx_rates";

export interface IMenuControl {
  openMenu: boolean;
  setMenu: (condition: boolean) => void;
  navigateTo: (option: string) => void;
}

export interface ICellData {
  text: string;
  align?: "left" | "right" | "inherit" | "center" | "justify" | undefined;
}

export interface IUseTableRates {
  headerData: ICellData[];
  renderData: ICellData[][] | undefined;
  menuControl: IMenuControl;
}

export const useTableRates = (id: string): IUseTableRates => {
  const history = useHistory();
  const [openMenu, setMenu] = useState<boolean>(false);

  const rateData = useSelector(
    (state: IAppState) => state.transactionRatesData
  );

  const merchantData = useSelector((state: IAppState) => state.merchant);

  const formatNumberAmount = (number: number | undefined): string =>
    number === undefined || merchantData === undefined
      ? ""
      : number.toFixed(2) + ` ${CURRENCY_COUNTRY.get(merchantData.country)}`;

  const mapRateData = (register: ResponseTrxRates): ICellData[] => [
    { text: register.alias },
    { text: PAYMENT_TYPE.get(register.paymentType) || "" },
    {
      text: register.paymentMethod
        .map((method) => PAYMENT_METHOD.get(method))
        .join(", "),
    },
    {
      text: formatNumberAmount(register.configuration[0].fixedAmount),
      align: "right",
    },
    { text: register.configuration[0].variableAmount + "%", align: "center" },
    { text: formatNumberAmount(register.minimumAmount), align: "right" },
    { text: formatNumberAmount(register.maximumAmount), align: "right" },
  ];

  const navigateTo = (option: string): void => {
    const path = `${routes.RATES}/${id}/create/?hideSideBar=false&type=`;

    history.push(path + option);
  };

  const renderData =
    rateData === undefined ? undefined : take(rateData, 5).map(mapRateData);

  const headerData: ICellData[] = [
    { text: "Alias" },
    { text: "Tipo Tarifa" },
    { text: "M. de pago/Serv." },
    { text: "Monto Fijo", align: "right" },
    { text: "M. Variable", align: "center" },
    { text: "M. Mínimo", align: "right" },
    { text: "M. Máximo", align: "right" },
  ];

  return {
    headerData,
    renderData,
    menuControl: {
      setMenu,
      openMenu,
      navigateTo,
    },
  };
};
