import React, { FC, memo } from "react";

const EmptyTableIcon: FC = () => {
  return (
    <svg
      width="270"
      height="269"
      viewBox="0 0 270 269"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.6514 179.154H32.5625V181.066H34.4736V182.978H36.3847V181.066H38.2959V179.154H40.207V177.242H38.2959V175.33H36.3847V173.418H34.4736V175.33H32.5625V177.242H30.6514V179.154ZM34.4736 177.242H36.3847V179.154H34.4736V177.242Z"
        fill="#00DFA9"
      />
      <path
        d="M135.143 268.95C209.397 268.95 269.593 208.755 269.593 134.5C269.593 60.2452 209.397 0.0498047 135.143 0.0498047C60.8878 0.0498047 0.692383 60.2452 0.692383 134.5C0.692383 208.755 60.8878 268.95 135.143 268.95Z"
        fill="#FBFCFE"
      />
      <path
        d="M267.903 153.926C253.086 147.453 233.794 144.354 221.737 148.872C210.441 153.106 212.249 161.514 200.643 164.991C181.922 170.595 168.956 151.171 141.095 152.094C116.308 152.914 107.698 168.915 88.9913 164.991C77.3124 162.535 75.1023 155.169 66.7875 149.943C52.8592 141.216 26.1176 141.304 0.407227 146.299C2.67063 178.895 16.7634 209.541 40.036 232.476C65.432 237.172 93.0775 239.681 120.006 238.679C167.365 236.926 215.284 224.24 247.89 209.138C258.574 192.446 265.41 173.587 267.903 153.926Z"
        fill="url(#paint0_linear_245_70601)"
        fillOpacity="0.5"
      />
      <g filter="url(#filter0_dd_245_70601)">
        <rect
          width="150"
          height="103"
          transform="translate(56.999 86.7656) rotate(-4.4982)"
          fill="white"
        />
        <path
          d="M76.5999 104.283L67.2953 105.015C66.8384 105.05 66.4351 104.706 66.3992 104.249L65.6672 94.9445C65.6312 94.4876 65.9757 94.0844 66.4326 94.0484L75.7372 93.3164C76.1941 93.2805 76.5973 93.6249 76.6333 94.0818L77.3653 103.386C77.4012 103.843 77.0568 104.247 76.5999 104.283Z"
          stroke="#B4C5D2"
          strokeWidth="1.5"
        />
        <path
          d="M108.469 90.7415L90.5248 92.1532C89.4237 92.2398 88.6012 93.2027 88.6879 94.3039L89.08 99.2885C89.1666 100.39 90.1295 101.212 91.2307 101.125L109.175 99.7138C110.276 99.6272 111.099 98.6643 111.012 97.5631L110.62 92.5785C110.533 91.4773 109.571 90.6549 108.469 90.7415Z"
          fill="#D9E5EE"
        />
        <path
          d="M197.195 83.7615L124.42 89.4867C123.319 89.5733 122.496 90.5362 122.583 91.6374L122.975 96.622C123.062 97.7232 124.025 98.5456 125.126 98.459L197.901 92.7337C199.002 92.6471 199.825 91.6842 199.738 90.5831L199.346 85.5985C199.259 84.4973 198.296 83.6748 197.195 83.7615Z"
          fill="#D9E5EE"
        />
        <path
          d="M78.0901 123.224L68.7855 123.956C68.3286 123.992 67.9253 123.647 67.8894 123.191L67.1574 113.886C67.1215 113.429 67.4659 113.026 67.9228 112.99L77.2274 112.258C77.6843 112.222 78.0876 112.566 78.1235 113.023L78.8555 122.328C78.8915 122.785 78.547 123.188 78.0901 123.224Z"
          stroke="#B4C5D2"
          strokeWidth="1.5"
        />
        <path
          d="M109.96 109.683L92.0151 111.095C90.9139 111.181 90.0915 112.144 90.1781 113.245L90.5702 118.23C90.6569 119.331 91.6198 120.154 92.7209 120.067L110.665 118.655C111.767 118.569 112.589 117.606 112.502 116.504L112.11 111.52C112.024 110.419 111.061 109.596 109.96 109.683Z"
          fill="#D9E5EE"
        />
        <path
          d="M198.685 102.703L125.91 108.428C124.809 108.515 123.987 109.478 124.073 110.579L124.465 115.563C124.552 116.665 125.515 117.487 126.616 117.4L199.391 111.675C200.493 111.589 201.315 110.626 201.228 109.524L200.836 104.54C200.75 103.439 199.787 102.616 198.685 102.703Z"
          fill="#D9E5EE"
        />
        <path
          d="M79.5801 142.165L70.2755 142.897C69.8186 142.933 69.4153 142.589 69.3794 142.132L68.6474 132.827C68.6114 132.37 68.9559 131.967 69.4128 131.931L78.7174 131.199C79.1743 131.163 79.5776 131.508 79.6135 131.965L80.3455 141.269C80.3814 141.726 80.037 142.129 79.5801 142.165Z"
          stroke="#B4C5D2"
          strokeWidth="1.5"
        />
        <path
          d="M111.45 128.624L93.5051 130.036C92.4039 130.123 91.5815 131.086 91.6681 132.187L92.0602 137.171C92.1469 138.272 93.1098 139.095 94.2109 139.008L112.155 137.597C113.257 137.51 114.079 136.547 113.992 135.446L113.6 130.461C113.514 129.36 112.551 128.538 111.45 128.624Z"
          fill="#D9E5EE"
        />
        <path
          d="M200.175 121.644L127.4 127.37C126.299 127.456 125.477 128.419 125.563 129.52L125.955 134.505C126.042 135.606 127.005 136.428 128.106 136.342L200.881 130.617C201.983 130.53 202.805 129.567 202.718 128.466L202.326 123.481C202.24 122.38 201.277 121.558 200.175 121.644Z"
          fill="#D9E5EE"
        />
        <path
          d="M81.0703 161.107L71.7657 161.839C71.3088 161.875 70.9056 161.53 70.8696 161.073L70.1376 151.769C70.1017 151.312 70.4461 150.909 70.903 150.873L80.2076 150.141C80.6645 150.105 81.0678 150.449 81.1037 150.906L81.8357 160.211C81.8717 160.668 81.5272 161.071 81.0703 161.107Z"
          stroke="#B4C5D2"
          strokeWidth="1.5"
        />
        <path
          d="M112.94 147.566L94.9953 148.977C93.8941 149.064 93.0717 150.027 93.1583 151.128L93.5505 156.113C93.6371 157.214 94.6 158.036 95.7012 157.95L113.646 156.538C114.747 156.451 115.569 155.488 115.483 154.387L115.091 149.403C115.004 148.302 114.041 147.479 112.94 147.566Z"
          fill="#D9E5EE"
        />
        <path
          d="M201.666 140.586L128.891 146.311C127.789 146.398 126.967 147.36 127.054 148.462L127.446 153.446C127.532 154.547 128.495 155.37 129.596 155.283L202.372 149.558C203.473 149.471 204.295 148.508 204.209 147.407L203.816 142.423C203.73 141.322 202.767 140.499 201.666 140.586Z"
          fill="#D9E5EE"
        />
        <path
          d="M82.5603 180.048L73.2557 180.78C72.7988 180.816 72.3956 180.472 72.3596 180.015L71.6276 170.71C71.5917 170.253 71.9361 169.85 72.393 169.814L81.6976 169.082C82.1545 169.046 82.5578 169.391 82.5937 169.847L83.3257 179.152C83.3617 179.609 83.0172 180.012 82.5603 180.048Z"
          stroke="#B4C5D2"
          strokeWidth="1.5"
        />
        <path
          d="M114.43 166.507L96.4853 167.919C95.3841 168.005 94.5617 168.968 94.6483 170.07L95.0404 175.054C95.1271 176.155 96.09 176.978 97.1911 176.891L115.136 175.479C116.237 175.393 117.059 174.43 116.973 173.329L116.581 168.344C116.494 167.243 115.531 166.42 114.43 166.507Z"
          fill="#D9E5EE"
        />
        <path
          d="M203.156 159.527L130.381 165.252C129.279 165.339 128.457 166.302 128.544 167.403L128.936 172.388C129.022 173.489 129.985 174.311 131.086 174.225L203.862 168.499C204.963 168.413 205.785 167.45 205.699 166.349L205.306 161.364C205.22 160.263 204.257 159.44 203.156 159.527Z"
          fill="#D9E5EE"
        />
      </g>
      <path
        d="M235.152 36.6754H207.866C207.017 36.676 206.177 36.5094 205.393 36.1851C204.609 35.8608 203.897 35.3851 203.297 34.7852C202.698 34.1854 202.222 33.4732 201.898 32.6894C201.573 31.9055 201.407 31.0654 201.407 30.2171C201.406 29.3684 201.572 28.5278 201.896 27.7434C202.22 26.959 202.696 26.2462 203.296 25.6458C203.896 25.0455 204.608 24.5693 205.392 24.2447C206.176 23.9201 207.017 23.7533 207.866 23.7539H235.152C236.002 23.752 236.844 23.9177 237.63 24.2417C238.416 24.5656 239.13 25.0414 239.732 25.6417C240.334 26.2421 240.811 26.9552 241.137 27.7404C241.463 28.5255 241.63 29.3671 241.63 30.2171C241.629 31.9304 240.947 33.5731 239.736 34.7841C238.524 35.9951 236.88 36.6754 235.167 36.6754H235.152Z"
        fill="#D9E5EE"
      />
      <path
        d="M228.698 28.1152C229.952 22.8539 226.703 17.5727 221.441 16.3193C216.18 15.0659 210.899 18.3149 209.645 23.5762C208.392 28.8374 211.641 34.1186 216.902 35.3721C222.164 36.6255 227.445 33.3765 228.698 28.1152Z"
        fill="#D9E5EE"
      />
      <path
        d="M16.9816 185.998C14.2514 185.998 11.999 188.251 11.999 190.983C11.999 193.714 14.2514 195.968 16.9816 195.968C19.7118 195.968 21.9642 193.714 21.9642 190.983C21.9642 188.251 19.7118 185.998 16.9816 185.998ZM16.9816 187.364C18.961 187.364 20.5991 189.003 20.5991 190.983C20.5991 192.963 18.961 194.602 16.9816 194.602C15.0022 194.602 13.3641 192.963 13.3641 190.983C13.3641 189.003 14.934 187.364 16.9816 187.364Z"
        fill="#00D29C"
      />
      <defs>
        <filter
          id="filter0_dd_245_70601"
          x="21.999"
          y="55.0015"
          width="227.616"
          height="184.447"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_245_70601"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="15" />
          <feGaussianBlur stdDeviation="17.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.235294 0 0 0 0 0.258824 0 0 0 0 0.341176 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_245_70601"
            result="effect2_dropShadow_245_70601"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_245_70601"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_245_70601"
          x1="134.153"
          y1="226.123"
          x2="129.249"
          y2="142.755"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEFEFD" />
          <stop offset="0.916375" stopColor="#D9E5EE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default memo(EmptyTableIcon);
