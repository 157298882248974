import { QueryParamEnum } from "../enum/QueryParamEnum";
import { BaseUrlEnum } from "../enum/baseUrlEnum";

export const handleSetUrl = (
  merchantInfoId: string | null,
  merchantName?: string | null
) => {
  let baseUrl: string = BaseUrlEnum.USER;
  let queryParam: string = "";
  const urlSearchParams: URLSearchParams = new URLSearchParams(
    window.location.search
  );

  const hasConsoleQueryParam: boolean =
    urlSearchParams.get(QueryParamEnum.CONSOLE) === "3";

  if (hasConsoleQueryParam) {
    queryParam = `${merchantInfoId || merchantName ? "&" : "?"}console=3`;
  }

  return { baseUrl, hasConsoleQueryParam, queryParam };
};

export const setUrl = (
  userName: string,
  merchantName: string | null,
  isHideSideBar: string | null,
  publicMerchantId: string
) => {
  const { baseUrl, queryParam, hasConsoleQueryParam } = handleSetUrl(
    null,
    merchantName
  );

  if (merchantName && hasConsoleQueryParam)
    return `${baseUrl}${userName}?publicMerchantId=${publicMerchantId}&merchantName=${merchantName}&hideSideBar=${isHideSideBar}${queryParam}`;
  else if (merchantName)
    return `${baseUrl}${userName}?merchantName=${merchantName}&hideSideBar=${isHideSideBar}${queryParam}`;

  return `${baseUrl}${userName}${queryParam}`;
};
