import React from "react";
import {
  BackofficeTable,
  TableHeaders,
} from "@kushki/frontend-molecules/backoffice-table";
import { Paper, TableContainer } from "@material-ui/core";
import { TableEnum } from "../../../../shared/infrastructure/TableEnum";
import { TransactionData } from "../../../../../types/transaction_data";
import { get } from "lodash";
import NumberFormat from "react-number-format";
import { TransactionTable } from "../../../../../types/transaction_table";
import { StatusFlowEnum } from "../../../../shared/infrastructure/StatusFlowEnum";
import { CountryEnum } from "../../../../shared/infrastructure/CountryEnum";
import {
  ModalOverUtils,
  trxReconcileSummaryProps,
} from "../../../../containers/DepositDashboard/state/useDepositDashboardState";
import {
  FiltersProps,
  TrxReconcileProps,
} from "../../../../containers/ConciliationDashboard/state/useConciliationDashboardState";
import { OrderSortEnum } from "../../../../shared/infrastructure/constants/StatusFlowDashboardConstants";
import { DateRange } from "@material-ui/pickers";
import { TransactionsSummaryFilters } from "../../../Filters/TransactionsSummaryFilters/TransactionsSummaryFilters";
import { PendingFiltersProps } from "../../../Filters/PendingFilters/PendingFilters";
import { formatDate } from "../../../../shared/infrastructure/utils/date";
import { STATUS_LABEL } from "../../../../shared/infrastructure/constants/BankConciliationConstans";
import { FormatDate } from "../../../../shared/infrastructure/constants/FormatDate";

export interface IPagination {
  page: number;
  limit: number;
  trxPerPage: number;
  siblingCount: number;
  isMobile: boolean;
  size: TableEnum;
  handleChangePage: (
    _event: React.ChangeEvent<unknown> | undefined,
    value: number,
    trxData: TransactionData[]
  ) => void;
  handleChangeLimit: (newLimit: number) => void;
  text: string;
}

export interface DashboardTableProps {
  trxReconcile?: TrxReconcileProps;
  tab?: number;
  country: CountryEnum;
  statusFlow: StatusFlowEnum;
  isLoading: boolean;
  pagination: IPagination;
  check?: {
    selectedCheck: boolean;
    noSelectedCheck: boolean;
  };
  filterProps: FiltersProps;
  orderSort: {
    order: OrderSortEnum;
    orderBy: string;
    handleRequestSort: (
      _event: React.MouseEvent<unknown>,
      property: string
    ) => void;
  };
  handleCheckboxChange?: (rowValue: TransactionData, selected: boolean) => void;
  handleCheckboxChangeAll?: (value: TransactionData[]) => void;
  modalOver: ModalOverUtils;
  pendingFiltersProps: PendingFiltersProps;
  handleDateRangeChange?: (dateRange: DateRange<Date>) => void;
  trxReconcileSummary?: trxReconcileSummaryProps;
  handleDownloadFile?: () => void;
  loadingFileDownload?: boolean;
  titleNoData?: string;
  sbtNoData?: string;
}

export interface TableStateProps {
  conciliationData: TransactionTable;
}

export type TableComponentProps = DashboardTableProps & TableStateProps;
export const formatNumber = (value: number, currency: string): JSX.Element => {
  return (
    <NumberFormat
      value={value}
      thousandSeparator
      displayType={"text"}
      decimalScale={2}
      suffix={` ${currency}`}
    />
  );
};

export const DashboardConciliationHistoricTable = (
  props: TableComponentProps
): JSX.Element => {
  const {
    pagination,
    orderSort,
    statusFlow,
    loadingFileDownload,
    pendingFiltersProps,
    handleDownloadFile,
  } = props;

  const handleLimit = (newLimit: string): void => {
    pagination.handleChangeLimit(Number(newLimit));
  };
  const handlePage = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ): void => {
    pagination.handleChangePage(_event, value, props.conciliationData.data!);
  };

  const netAmount = (trx: TransactionData): JSX.Element => {
    return formatNumber(
      Number(get(trx, "net_amount", 0)),
      get(trx, "currencyCode", "")
    );
  };

  const defaultCode = (trx: TransactionData, type: string): JSX.Element => {
    return get(trx, type, "");
  };
  const defaultCodeDate = (trx: TransactionData, type: string): string => {
    return formatDate(get(trx, type, "1"), FormatDate.DMY_slash);
  };

  const bankConciliationStatus = (trx: TransactionData): string => {
    const status = get(trx, "bank_conciliation_status", "");
    return STATUS_LABEL[status] || status;
  };
  return (
    <>
      <TransactionsSummaryFilters
        origin={statusFlow}
        pendingFiltersProps={pendingFiltersProps}
        loadingFileDownload={loadingFileDownload}
        handleDownloadFile={handleDownloadFile}
        isHistoric
      />
      <TableContainer component={Paper} style={{ marginTop: "10px" }}>
        <BackofficeTable
          items={props.conciliationData.data! as TransactionData[]}
          headers={
            props.filterProps
              .filterHeaders!() as TableHeaders<TransactionData>[]
          }
          handleOnRowSelection={props.modalOver.handlerSelectTrxItem}
          skeleton={props.isLoading}
          sort={{
            order: orderSort.order,
            orderBy: orderSort.orderBy,
            handleRequestSort: orderSort.handleRequestSort,
          }}
          customRenderers={{
            netAmount,
            transactionCreated: (trx: TransactionData) =>
              defaultCodeDate(trx, "transaction_created"),
            transactionId: (trx: TransactionData) =>
              defaultCode(trx, "transaction_id"),
            merchantName: (trx: TransactionData) =>
              defaultCode(trx, "merchant_name"),
            merchantId: (trx: TransactionData) =>
              defaultCode(trx, "merchant_id"),
            approvedTransactionAmount: (trx: TransactionData) =>
              defaultCode(trx, "approved_transaction_amount"),
            bankConciliationStatus,
          }}
          emptyState={{
            title: get(props, "titleNoData", ""),
            subtitle: get(props, "sbtNoData", ""),
          }}
          pagination={{
            ...props.pagination,
            total: props.conciliationData.total!,
            handleChangeLimit: handleLimit,
            handleChangePage: handlePage,
          }}
        />
      </TableContainer>
    </>
  );
};
