import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SearchMerchantResponse } from "../../../../types/search_merchant_response";
import {
  branchesSelectedList,
  listBranchesTitle,
} from "../../../shared/utils/massiveBranchEdit";
import { ISnackBar } from "@kushki/connect-ui/dist/Components/Atoms/DataDisplay/SnackBar/SnackBar.interface";

export interface BranchBatch {
  branchBatch: object[];
  listBranchesMassive: branchesSelectedList[];
  searchMerchantResponse?: SearchMerchantResponse;
  nodeIds: string[];
  title: string;
  listMerchants: string;
  notificationMassive?: ISnackBar | undefined;
}

export const initialState: BranchBatch = {
  branchBatch: [],
  listBranchesMassive: [],
  listMerchants: "",
  nodeIds: [],
  title: "",
};

export const massiveBranchSlice = createSlice({
  initialState,
  name: "massiveBranch",
  reducers: {
    setBranchBatch: (state, { payload }: PayloadAction<object[]>) => {
      return {
        ...state,
        branchBatch: [...payload],
      };
    },
    setListBranchMassiveTitle: (
      state,
      { payload }: PayloadAction<listBranchesTitle>
    ) => {
      return {
        ...state,
        listBranchesMassive: [...payload.listBranches],
        listMerchants: payload.publicMerchantsIds,
        nodeIds: [...payload.nodeIds],
        title: payload.title,
      };
    },
    setNotificationMassive: (
      state,
      { payload }: PayloadAction<ISnackBar | undefined>
    ) => {
      state.notificationMassive = payload;
    },
  },
});

export default massiveBranchSlice.reducer;
