/* istanbul ignore file */
/* eslint-disable */
export const environment: {
kushkiUrl: string;
envName: string;
devTools: boolean;
configIdBilling: string;
configIdDispersion: string;
configIdCharge: string;
kushkiUrlWS: string;
rollbarToken: string;
} = {
kushkiUrl: "https://api.kushkipagos.com",
envName: "primary",
devTools: false,
configIdBilling: "cn003",
configIdDispersion: "cn001,cn004",
rollbarToken: "1450f4c8e30f413c9d9d0da011be11ee",
configIdCharge: "cn001,cn017",
kushkiUrlWS: "api.kushkipagos.com",
};
