export enum CatalogsTextEnum {
  TITLE_CONFIRM = "Se ha encontrado una coincidencia con la opción de catálogo ingresada",
  SUBTITLE_CONFIRM = "¿Deseas reemplazar con la opción de catálogo actual?",
  TEXT_CONFIRM = "Opción de catálogo a reemplazar:",
  CODE = "Código",
  DESCRIPTION = "Descripción",
  CHANGE_OPTION = "Cambiar opción",
  CANCEL = "Cancelar",
  AGREED = "Entendido",
  LOAD_CATALOG_FILE = "Carga de catálogos con archivo",
}
