import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";
import { makeStyles } from "@mui/styles";

const CellActionOptionMenuStyles = createNamedStyles({
  menuBox: {
    bgcolor: "white",
    borderRadius: 2,
    boxShadow: 2,
    minWidth: 250,
    p: 1,
  },
});

export { CellActionOptionMenuStyles };

export const divStyles = makeStyles(() => ({
  menuContainer: { display: "flex", justifyContent: "center" },
  menuBotton: {
    backgroundColor: "none",
    "&:hover, &:focus": {
      backgroundColor: "none",
      background: "none",
      borderColor: "none",
      boxShadow: "none",
      border: "none",
    },
    border: "none",
    boxShadow: "none",
    borderColor: "none",
    padding: 0,
  },
}));
