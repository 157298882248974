export enum PaymentMethodEnum {
  CARD = "card",
  TRANSFER = "transfer",
  CASH = "cash",
  PAYOUTS_TRANSFER = "payouts-transfer",
  PAYOUTS_CASH = "payouts-cash",
  PAYOUTS_CARD = "payouts-card",
  TRANSFER_SUBSCRIPTIONS = "transfer-subscriptions",
}

export enum CardPaymentTypeEnum {
  DEFERRED = "deferred",
  CURRENT = "current",
}

export enum PaymentMethodTitle {
  PAY_IN = "Pay In (cobro)",
  PAY_OUT = "Pay out (pagos)",
}

export enum ServicesEnum {
  OTP = "otp",
  SIFTSCIENCE = "siftScience",
  _3DS = "3ds",
  TRANSUNION = "transUnion",
}

export enum RatePaymentTypeEnum {
  PAYIN = "payIn",
  PAYOUT = "payOut",
  ADDITIONALSERVICE = "additionalServices",
}

export const getPaymentMethodForProcessor = (paymentMethod: string): string => {
  switch (paymentMethod) {
    case PaymentMethodEnum.CARD:
      return "tarjeta";
    case PaymentMethodEnum.TRANSFER:
      return "transferencia";
    case PaymentMethodEnum.CASH:
      return "efectivo";
    default:
      return "";
  }
};

export enum CardRegionEnum {
  NATIONAL = "national",
  INTERNATIONAL = "international",
}

export enum CardTypeEnum {
  CREDIT = "credit",
  DEBIT = "debit",
}
export enum CardBrandsEnum {
  VISA = "Visa",
  MASTERCARD = "Mastercard",
  AMERICANEXPRESS = "Americanexpress",
  DINERS = "Diners",
  DISCOVER = "Discover",
  ALIA = "Alia",
}
