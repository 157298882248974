import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import {
  IDetailCard,
  IModalActionProps,
  IRulesAlarmState,
} from "@store/interfaces/RulesAlarm.interfaces";
import { MainPrefix } from "@shared/constants/thunkPrefixes";
import { initialModalActionProps } from "@shared/constants/initialModalActionProps";
import { getRuleAlarms } from "@store/thunks/alarmConfig/alarmConfig.thunk";

export const rulesAlarmState: IRulesAlarmState = {
  detailCard: { isOpen: false, selectedRule: null },
  isLoading: false,
  modalActionProps: initialModalActionProps,
  ruleData: { records: [], total: 0 },
};

export const rulesAlarmSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getRuleAlarms.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ruleData = action.payload.data;
      })
      .addCase(getRuleAlarms.pending, (state) => {
        state.isLoading = true;
      });
  },
  initialState: rulesAlarmState,
  name: MainPrefix.RULES_ALARM,
  reducers: {
    setDetailCard: (state, action: PayloadAction<IDetailCard>) => {
      state.detailCard = action.payload;
    },
    setModalActionProps: (
      state: Draft<IRulesAlarmState>,
      { payload }: PayloadAction<IModalActionProps>
    ) => {
      state.modalActionProps = payload;
    },
  },
});

export default rulesAlarmSlice.reducer;
