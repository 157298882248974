import React from "react";
import { IModalCloseAlarmProps } from "@components/ModalCloseAlarm/ModalCloseAlarm.interfaces";
import {
  ModalContent,
  ModalContentButtonTypeEnum,
  ModalContentTypeEnum,
  TextArea,
} from "@kushki/connect-ui";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { alarmsStyles as styles } from "@containers/Alarms/Alarms.styles";
import { Controller } from "react-hook-form";
import FileUploadContainer from "@components/FileUploadContainer/FileUploadContainer";
import { CloseModalLabels } from "@shared/constants/labels/fileUploadLabels";

const ModalCloseAlarm: React.FC<IModalCloseAlarmProps> = ({
  control,
  handleHideCloseAlarmModal,
  isOpenCloseAlarmModal,
  handleSaveCloseAlarm,
  isDisabledCloseAlarm,
  handleCommentsChange,
}: IModalCloseAlarmProps) => {
  return (
    <ModalContent
      onClose={handleHideCloseAlarmModal}
      isOpen={isOpenCloseAlarmModal}
      type={ModalContentTypeEnum.DEFAULT}
      buttonType={ModalContentButtonTypeEnum.PRIMARY}
      buttonAction={handleSaveCloseAlarm}
      descriptionText={""}
      titleText={CloseModalLabels.TITLE}
      buttonSecondaryText={CloseModalLabels.CANCEL}
      buttonText={CloseModalLabels.CLOSE_ALARM}
      buttonPrimaryDisabled={isDisabledCloseAlarm}
    >
      <Box sx={styles.modalContent}>
        <FormControl component="fieldset">
          <Box>
            <Typography sx={styles.textMargin} variant={"body1"}>
              {CloseModalLabels.MODAL_BODY}
            </Typography>
          </Box>
          <Box sx={styles.textMargin}>
            <Controller
              rules={{ required: true }}
              control={control}
              name="action"
              render={({ field }) => (
                <FormControl>
                  <RadioGroup row {...field}>
                    <FormControlLabel
                      value="normal"
                      control={<Radio />}
                      label={CloseModalLabels.NORMAL_OPTION}
                    />
                    <FormControlLabel
                      value="ros"
                      control={<Radio />}
                      label={CloseModalLabels.ROS_OPTION}
                    />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Box>
          <Box>
            <Typography sx={styles.textMargin} variant={"body1"}>
              {CloseModalLabels.ATTACHMENTS}
            </Typography>
          </Box>
          <Box>
            <FileUploadContainer />
          </Box>
          <Box sx={styles.commentsBox}>
            <Typography sx={styles.textMargin} variant={"body1"}>
              {CloseModalLabels.ADD_COMMENTS}
            </Typography>
            <Box sx={styles.textArea}>
              <Controller
                rules={{ required: true }}
                control={control}
                name="comment"
                render={() => (
                  <FormControl>
                    <TextArea
                      onChange={handleCommentsChange}
                      height={130}
                      maxCounter={500}
                      name="comment"
                      placeholder={CloseModalLabels.COMMENTS_PLACEHOLDER}
                      ref={React.createRef()}
                    />
                  </FormControl>
                )}
              />
            </Box>
          </Box>
        </FormControl>
      </Box>
    </ModalContent>
  );
};

export default ModalCloseAlarm;
