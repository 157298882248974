import { Box, Button } from "@mui/material";
import React, { FC } from "react";
import { useWrapperContainer } from "./state/useWrapperContainer";
import { IWrapperContainer } from "./WrapperContainer.interfaces";
import { wrapperContainerStyles as styles } from "./WrapperContainer.styles";
import Subheader from "../Subheader/Subheader";
import { IconChevronLeft } from "@kushki/connect-ui";
import { RETURN } from "../../shared/constants/labels/main_containter_labels";

const WrapperContainer: FC<IWrapperContainer> = ({
  children,
}: IWrapperContainer) => {
  const { subHeaderTexts, handleReturn } = useWrapperContainer();

  return (
    <>
      <Box sx={styles.containerBody}>
        <Box sx={styles.returnBox}>
          <Button
            className={"buttonLink"}
            startIcon={<IconChevronLeft />}
            onClick={handleReturn}
          >
            {RETURN}
          </Button>
          <Subheader {...subHeaderTexts} />
        </Box>
        <Box sx={styles.containerForm}>{children}</Box>
      </Box>
    </>
  );
};

export default WrapperContainer;
