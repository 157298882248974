import React, { FC, PropsWithChildren } from "react";
import { Box, Grid } from "@mui/material";
import {
  Alert,
  ColumnsPicker,
  FilterDetailBar,
  IconDownload,
  SplitButton,
} from "@kushki/connect-ui";
import { headerFiltersStyles as styles } from "./HeaderFilters.styles";
import { FilterSideBar } from "../Filters/FilterSideBar/FilterSideBar";
import { HeaderFiltersProps } from "./HeaderFilters.interfaces";
import { useHeaderFiltersState } from "./state/useHeaderFiltersState";
import SearchText from "../SearchText/SearchText";
import { TransactionEnum } from "../../shared/enums/TransactionEnum";
import { TransactionLabelsEnum } from "../../shared/enums/transactionLabelsEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { FileFormatEnum } from "../../shared/enums/FileFormatEnum";
import { SecurityWrapperEnum } from "../../shared/infraestructure/SecurityWrapperEnum";
import { getMaxColumns } from "../../shared/utils/column_selected";

export const HeaderFilters: FC<HeaderFiltersProps> = (
  props: PropsWithChildren<HeaderFiltersProps>
) => {
  const {
    filterSideBarProps,
    itemsDetailBar,
    onChangeFiltersDetailBar,
    onClickFilterSideBar,
    onEnterName,
    onDateSelected,
    valueSearch,
    onChange,
    showRangePicker,
    defaultStartDate,
    defaultFinishDate,
    minDate,
  } = useHeaderFiltersState(props);

  return (
    <Grid container>
      <Grid item container xs={12}>
        <Grid item xs={7} sx={styles.gridSearch}>
          <SearchText
            defaultFinishDate={defaultFinishDate}
            defaultStartDate={defaultStartDate}
            onEnter={onEnterName}
            onDateSelected={onDateSelected}
            searchMessage={TransactionEnum.SEARCH_MESSAGE}
            value={valueSearch}
            onChange={onChange}
            entityName={props.entityNameType}
            showRangePicker={showRangePicker}
            minDate={minDate}
          />
        </Grid>
        <Grid item xs={1} sx={styles.gridFilter}>
          <ColumnsPicker
            maxColumns={getMaxColumns()}
            items={props.dataPicker.items}
            onActionButtons={props.dataPicker.handleData}
          />
        </Grid>
        <Grid item xs={1} sx={styles.gridFilter}>
          <Box mt={2} mb={2}>
            <FilterSideBar
              data-testid={"filterBar"}
              {...filterSideBarProps}
              categoryItems={filterSideBarProps.categoryItems}
              onClick={onClickFilterSideBar}
              resetFilters={props.resetFilters}
              setRangeAmount={props.setRangeAmount}
              setIsApplyFilter={props.setIsApplyFilter}
              rangeAmountInfo={props.rangeAmountInfo!}
              isApplyFilter={props.isApplyFilter}
            />
          </Box>
        </Grid>
        {!props.downloadFileOptions.disableDownload && (
          <Grid item xs={3} sx={styles.button}>
            <ComponentSecurityWrapper
              componentId={SecurityWrapperEnum.PAYMENT_DOWNLOAD}
            >
              <SplitButton
                variant="primary"
                disabled={props.downloadFileOptions.loadingDownload}
                isLoading={props.downloadFileOptions.loadingDownload}
                onItemSelected={props.downloadFileOptions?.handleSelect}
                items={[
                  {
                    id: "csv",
                    text: FileFormatEnum.CSV,
                    variant: "text",
                  },
                  {
                    id: "xlsx",
                    text: FileFormatEnum.EXCEL,
                    variant: "text",
                  },
                  {
                    id: "json",
                    text: FileFormatEnum.JSON,
                    variant: "text",
                  },
                ]}
                size={"small"}
                text={TransactionEnum.BUTTON_DOWNLOAD_TITLE}
                type={"default"}
                icon={<IconDownload />}
              />
            </ComponentSecurityWrapper>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} sx={styles.gridFilterCard}>
        {props.subTitle.length > 1 && (
          <Box sx={styles.boxAlert}>
            <Alert
              title={TransactionLabelsEnum.TITLE_ALERT}
              type="informative"
              subTitle={props.subTitle}
            />
          </Box>
        )}
      </Grid>
      <Grid item xs={12} sx={styles.gridFilterCard}>
        <FilterDetailBar
          filters={itemsDetailBar}
          onChangeFilters={onChangeFiltersDetailBar}
        />
      </Grid>
    </Grid>
  );
};
