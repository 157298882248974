import React, { FC } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import { merchantCentralizationStyles as styles } from "./MerchantCentralization.styles";
import { LayoutStepContainer } from "../LayoutStepContainer/LayoutStepContainer";
import { CountryEnum } from "../../shared/enums/countryEnum";
import {
  CANCELAR,
  CENTRALIZAR,
  CENTRALIZE_MESSAGE,
  CENTRALIZE_MESSAGE2,
  CENTRALIZE_MESSAGE3,
  CENTRALIZE_MESSAGE4,
  CENTRALIZE_TITLE,
  CENTRALIZE_TOOLTIP_CENTRALIZE,
  CENTRALIZE_TOOLTIP_DESCENTRALIZE,
  ComponentIdsEnum,
  DESCENTRALIZAR,
  DESCENTRALIZE_TITLE,
  LIST_BRANCHES,
  SELECT_BRANCHES,
  TITLE_ALERT_BRANCH_CEN,
} from "../../shared/constants/labels/main_containter_labels";
import {
  Alert,
  IconLock,
  IconSearch,
  IconUnLock,
  ModalLoader,
  ModalSimple,
} from "@kushki/connect-ui";
import {
  CatalogFilterEnum,
  FilterCatalog,
} from "../../shared/constants/labels/filter_labels";
import Filter from "../../components/Filter/Filter";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { useBasicMerchantCentralization } from "./state/useBasicMerchantCentralization";
import { HEADSTABLE } from "../../shared/catalogs/CatalogConfigTable";
import { TRANSACTIONS_PER_PAGE } from "../../shared/constants/labels/table_labels";
import { TableSimple } from "../../components/Table/TableSimple/TableSimple";
import { Controller } from "react-hook-form";
import { MASSIVE_SERVICE_ACTIONS } from "../../shared/constants/websocket_actions";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#122b45",
    border: "1px solid #dadde9",
    color: "rgba(253,253,253)",
    fontSize: theme.typography.pxToRem(12),
    maxWidth: 360,
    textAlign: "left",
  },
}));

const renderToolTipElement = (
  element: JSX.Element,
  title: string,
  subtitle: String,
  disbledHover: boolean
): JSX.Element => {
  return (
    <HtmlTooltip
      role={"tooltipContentButton"}
      disableHoverListener={disbledHover}
      placement="bottom"
      arrow
      title={
        <React.Fragment>
          <Typography>{title}</Typography>
          {subtitle}
        </React.Fragment>
      }
    >
      <Grid>{element}</Grid>
    </HtmlTooltip>
  );
};

const MerchantCentralization: FC = () => {
  const {
    selectRow,
    isCentralized,
    isDescentralized,
    disableDecentralization,
    handleCentralizeDecentralize,
    isLoadingModal,
    setSelectedRows,
    centralizationModalText,
    decentralizationModalText,
    setOpenModalCentralization,
    openModalDecentralization,
    setOpenModalDecentralization,
    openModalCentralization,
    searchMerchantNodeFilter,
    handleSelectedRows,
    isLoadingDecentralized,
    paginationProps: {
      handleChangePage,
      handleChangeRowsPerPage,
      page,
      rowsPerPage,
      totalData,
    },
    rows,
    customerInfo,
    numberBranchesSelected,
    filterByNameBranchId,
    form,
    isCustomerComplete,
  } = useBasicMerchantCentralization();

  const showToolTip: boolean = !(
    isCentralized &&
    isDescentralized &&
    selectRow.length > 1
  );

  return (
    <>
      <LayoutStepContainer
        headerProps={{
          apiUrl: "/centralize",
          country: customerInfo.constitutionalCountry as CountryEnum,
          id: customerInfo.publicMerchantId,
          subTitle: "País de operación: ",
          title: customerInfo.name,
        }}
        renderComponent={
          <Box sx={styles.boxContent}>
            <>
              <Divider flexItem sx={styles.divider} />
              <Typography variant="h4" color="text.primary" fontWeight={500}>
                {LIST_BRANCHES}
              </Typography>

              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={4}>
                  <Box mt={2} mb={2}>
                    <Controller
                      name={"searchParameter"}
                      control={form.control}
                      defaultValue={"" as string}
                      render={({ field }: any) => (
                        <TextField
                          data-testid={"text_filter2"}
                          {...field}
                          placeholder="Buscar por nombre de Branch o Branch ID"
                          margin="normal"
                          fullWidth
                          className="solidTextfield"
                          variant="outlined"
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              filterByNameBranchId();
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <IconButton
                                  data-testid={"b1Search"}
                                  onClick={filterByNameBranchId}
                                >
                                  <IconSearch
                                    color="primary"
                                    fontSize="medium"
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={8} sx={styles.gridFilter}>
                  <Box mt={2} mb={2}>
                    <Filter
                      data-testid={"complete_filter"}
                      dataItems={
                        FilterCatalog[CatalogFilterEnum.COMPLETE_CATALOG]
                      }
                      handleOnClick={(filters) => {
                        searchMerchantNodeFilter(filters);
                      }}
                    />
                  </Box>
                </Grid>
                {!isCustomerComplete && (
                  <Grid item xs={12} alignItems="center">
                    <Alert
                      buttonText=""
                      data-testid={"b2AlertButtonCEN"}
                      title={TITLE_ALERT_BRANCH_CEN}
                      type="warning"
                    />
                  </Grid>
                )}
              </Grid>

              <Divider flexItem sx={styles.divider2} />
              <Grid container alignItems="center">
                <Grid item xs={6}>
                  <Typography
                    color="text.primary"
                    fontSize={15}
                    fontWeight={400}
                    display="inline"
                  >
                    {CENTRALIZE_MESSAGE}{" "}
                  </Typography>
                  <Typography
                    color="text.primary"
                    fontSize={15}
                    fontWeight={600}
                    display="inline"
                  >
                    {CENTRALIZE_MESSAGE2}
                  </Typography>
                  <Typography
                    color="text.primary"
                    fontSize={15}
                    fontWeight={400}
                    display="inline"
                  >
                    {CENTRALIZE_MESSAGE3}
                  </Typography>
                  <Typography
                    color="text.primary"
                    fontSize={15}
                    fontWeight={600}
                  >
                    {CENTRALIZE_MESSAGE4}
                  </Typography>
                  {"        "}
                  {"        "}
                  <br />
                  <Typography
                    color="text.primary"
                    fontSize={15}
                    fontWeight={400}
                    display="inline"
                  >
                    {SELECT_BRANCHES}
                  </Typography>
                  <Typography
                    color="text.primary"
                    fontSize={15}
                    fontWeight={600}
                    display="inline"
                  >
                    {selectRow.length}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent={"flex-end"}
                    spacing={3}
                    direction={"row"}
                  >
                    <ComponentSecurityWrapper
                      componentId={ComponentIdsEnum.M_CLIENTS_EDIT_DECENTRALIZE}
                    >
                      {renderToolTipElement(
                        <Button
                          disabled={isDescentralized || disableDecentralization}
                          color="primary"
                          variant="contained"
                          data-testid={"d1OpenModalDecentralization"}
                          startIcon={<IconUnLock />}
                          onClick={() => setOpenModalDecentralization(true)}
                        >
                          {DESCENTRALIZAR}
                        </Button>,
                        DESCENTRALIZAR,
                        CENTRALIZE_TOOLTIP_CENTRALIZE,
                        showToolTip
                      )}
                    </ComponentSecurityWrapper>
                    <ComponentSecurityWrapper
                      componentId={ComponentIdsEnum.M_CLIENTS_EDIT_CENTRALIZE}
                    >
                      {renderToolTipElement(
                        <Button
                          disabled={isCentralized || !isCustomerComplete}
                          data-testid={"d2OpenModalCentralization"}
                          color="primary"
                          variant="contained"
                          sx={styles.buttonColor}
                          startIcon={<IconLock />}
                          onClick={() => {
                            setOpenModalCentralization(true);
                          }}
                        >
                          {CENTRALIZAR}
                        </Button>,
                        CENTRALIZAR,
                        CENTRALIZE_TOOLTIP_DESCENTRALIZE,
                        showToolTip
                      )}
                    </ComponentSecurityWrapper>
                  </Grid>
                </Grid>
              </Grid>

              <Divider flexItem sx={styles.divider2} />
              <Grid>
                <TableSimple
                  handleSelectedRows={handleSelectedRows}
                  headTable={HEADSTABLE.BRANCH}
                  rows={rows}
                  emptyRows={0}
                  rowsSelected={numberBranchesSelected}
                  labelRowsPerPage={TRANSACTIONS_PER_PAGE}
                  handleRequestSort={() => {}}
                  paginationProps={{
                    handleChangePage,
                    handleChangeRowsPerPage,
                    page,
                    rowsPerPage,
                    totalData,
                  }}
                />
              </Grid>
              <ModalSimple
                onClickPrimary={() => {
                  setOpenModalCentralization(false);
                  setSelectedRows(selectRow.length);
                  handleCentralizeDecentralize(
                    MASSIVE_SERVICE_ACTIONS.centralize
                  );
                }}
                onClickSecondary={() => {
                  setOpenModalCentralization(false);
                }}
                buttonPrimary={CENTRALIZAR}
                buttonSecondary={CANCELAR}
                descriptionText={centralizationModalText}
                isOpen={openModalCentralization}
                onClose={() => setOpenModalCentralization(false)}
                titleText={CENTRALIZE_TITLE(selectRow.length)}
                typeModal={1}
              />
              <ModalSimple
                onClickPrimary={() => {
                  setOpenModalDecentralization(false);
                  setSelectedRows(selectRow.length);
                  handleCentralizeDecentralize(
                    MASSIVE_SERVICE_ACTIONS.decentralize
                  );
                }}
                onClickSecondary={() => setOpenModalDecentralization(false)}
                buttonPrimary={DESCENTRALIZAR}
                buttonSecondary={CANCELAR}
                descriptionText={decentralizationModalText}
                isOpen={openModalDecentralization}
                onClose={() => setOpenModalDecentralization(false)}
                titleText={DESCENTRALIZE_TITLE(selectRow.length)}
                typeModal={1}
              />

              <ModalLoader
                isOpen={isLoadingModal}
                descriptionText="Este proceso puede tardar unos minutos..."
                titleText={
                  isLoadingDecentralized.isLoading
                    ? isLoadingDecentralized.message
                    : "Estamos creando tu solicitud"
                }
              />
            </>
          </Box>
        }
        footerProps={{
          isLoading: false,
          primaryButton: {
            isDisabled: false,
            isHidden: false,
          },
          secondaryButton: {
            isDisabled: false,
            isHidden: true,
          },
        }}
      />
    </>
  );
};

export default MerchantCentralization;
