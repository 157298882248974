import {
  CardAlia,
  CardAmex,
  CardDinners,
  CardJbc,
  CardMastercard,
  CardVisa,
  CardDiscover,
} from "@kushki/connect-ui";
import React from "react";
import { CreditCardsEnum } from "../../enums/CreditCardsEnum";

export const getCreditCards: Record<string, JSX.Element> = {
  [CreditCardsEnum.ALIA]: <CardAlia fontSize="medium" />,
  [CreditCardsEnum.AMEX]: <CardAmex fontSize="medium" />,
  [CreditCardsEnum.DINERS]: <CardDinners fontSize="medium" />,
  [CreditCardsEnum.DISCOVER]: <CardDiscover fontSize="medium" />,
  [CreditCardsEnum.JCB]: <CardJbc fontSize="medium" />,
  [CreditCardsEnum.MASTERCARD]: <CardMastercard fontSize="medium" />,
  [CreditCardsEnum.VISA]: <CardVisa fontSize="medium" />,
};
