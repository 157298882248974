import React from "react";
import { ModalContent } from "@kushki/connect-ui";
import { useMassiveStatusInstructionsModal } from "./state/useMassiveStatusInstructionsModal";
import {
  MassiveStatusModalButtons,
  MassiveStatusModalConstants,
} from "../../shared/infraestructure/MassiveStatusModalConstants";
import { Grid } from "@mui/material";
import { useStyles } from "../MassiveStatusInstructionsModal/MassiveStatusInstructionsModal.style";

export enum ModalContentButtonTypeEnum {
  PRIMARY = "primary",
}

export enum ModalContentTypeEnum {
  DEFAULT = "Default",
}

export const MassiveStatusInstructionsModal = () => {
  const { onCloseModal, isOpen } = useMassiveStatusInstructionsModal();
  const classes = useStyles();

  return (
    <>
      <ModalContent
        buttonText={MassiveStatusModalButtons.UNDERSTOOD_BUTTON}
        buttonType={ModalContentButtonTypeEnum.PRIMARY}
        descriptionText={""}
        onClose={onCloseModal}
        titleText={
          MassiveStatusModalConstants.INSTRUCTIONS_MASSIVE_STATUS_TITTLE
        }
        type={ModalContentTypeEnum.DEFAULT}
        buttonAction={onCloseModal}
        buttonPrimaryDisabled={false}
        isOpen={isOpen}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={12}
            style={{ paddingRight: "20px", paddingTop: 0 }}
          >
            <li>El formato del archivo deberá ser .csv</li>
            <li>El archivo no debe superar 1 MB.</li>
            <li>
              Solo deberá permitir como máximo 500 números de registros por cada
              carga de archivo.
            </li>
            <li>No hay restricción de horarios.</li>
            <li>
              El nombre del archivo deberá cumplir con el siguiente formato:
            </li>
            <li className={classes.list}>EstadosContracargosMasivoInPut.csv</li>
            <li>Validar que el archivo contenga los siguientes campos:</li>
            <li className={classes.list}>Ticket_number</li>
            <li className={classes.list}>Ticket_number_contracargo</li>
            <li className={classes.list}>
              Estado_resolucion (EN REVISION, INICIALIZADO, GANADO, PERDIDO,
              FALLIDO, VENCIDO, PENDIENTE)
            </li>
          </Grid>
        </Grid>
      </ModalContent>
    </>
  );
};
