import { createStyles, Grid, Paper, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: "rgba(247, 250, 252, 1)",
      padding: theme.spacing(2, 2),
    },
  })
);

export const GridPaper: React.FC<Record<string, any>> = (
  props: Record<string, any>
) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction={"column"}
      component={Paper}
      elevation={0}
      className={classes.paper}
      justify={"flex-start"}
      alignItems={"flex-start"}
      spacing={1}
      {...props}
    />
  );
};
