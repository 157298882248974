import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { IDiscountResponse } from "../../../../types/discount_response";
import { get } from "lodash";

export interface IuseDiscountData {
  dis: number;
  start: number;
  end: number;
}

export const useDiscountData = (): IuseDiscountData => {
  const discount: IDiscountResponse = useSelector(
    (state: IAppState) => state.discountInfo!
  );
  const dis = get(discount, "discount", 0);
  const start = get(discount, "start", 0);
  const end = get(discount, "end", 0);

  return {
    dis,
    start,
    end,
  };
};
