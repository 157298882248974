import { createStyles, makeStyles } from "@material-ui/core/styles";

const alertColor: string = "#023365";

const useStyles = makeStyles(() =>
  createStyles({
    subtitle: {
      color: "#6D7781",
      padding: "24px 0",
    },
    header: {
      color: "#293036",
      padding: "24px 0",
      fontSize: "13px",
    },
    description: {
      color: "#6D7781",
      paddingBottom: "16px",
      fontSize: "13px",
    },
    btnReplace: {
      marginTop: "10px",
      padding: "10px 40px",
      backgroundColor: alertColor,
      color: "#fff",
      "&:hover": {
        backgroundColor: alertColor,
        color: "#fff",
      },
      "&:disabled": {
        backgroundColor: "#FAFAFA",
      },
    },
    btnChangeOption: {
      marginTop: "10px",
      padding: "10px 40px",
      color: "#293036",
      "&:hover": {
        backgroundColor: "#fff",
        color: "#293036",
      },
      "&:disabled": {
        backgroundColor: "#FAFAFA",
      },
    },
    actions: {
      paddingRight: "24px",
      paddingBottom: "24px",
    },
  })
);

export { useStyles, alertColor };
