import { TransactionStatusEnum } from "../enums/TransactionStatusEnum";
import { PaymentMethodsEnum } from "../infraestructure/payment-method";
import { ContentInformation } from "../../components/CardInformation/CardInformation.interfaces";
import {
  CardSectionValueEnum,
  FieldBillingDataSectionValueEnum,
  FieldMerchantDataSectionValueEnum,
} from "../enums/CardInformationEnum";

const getContentInformationByFilter = (
  sectionList: ContentInformation[],
  filters: string[]
): ContentInformation[] =>
  sectionList.filter((section) =>
    filters.find((filter) => section.type === filter)
  );

export const catalogSectionFieldListByTransaction = (
  sectionList: ContentInformation[]
): Record<string, object> => {
  const sectionFieldListTransferMerchantData: ContentInformation[] =
    getContentInformationByFilter(sectionList, [
      FieldMerchantDataSectionValueEnum.MERCHANT,
      FieldMerchantDataSectionValueEnum.MERCHANT_ID,
      FieldMerchantDataSectionValueEnum.DOC_NUMBER,
      FieldMerchantDataSectionValueEnum.TYPE,
      FieldMerchantDataSectionValueEnum.PAYMENT_METHOD,
      FieldMerchantDataSectionValueEnum.BANK,
      FieldMerchantDataSectionValueEnum.SUBTOTAL,
      FieldMerchantDataSectionValueEnum.TAXES,
      FieldMerchantDataSectionValueEnum.TOTAL,
    ]);

  const sectionFieldListTransferBillingData: ContentInformation[] =
    getContentInformationByFilter(sectionList, [
      FieldBillingDataSectionValueEnum.SUBTOTAL_IVA,
      FieldBillingDataSectionValueEnum.SUBTOTAL_IVA_0,
      FieldBillingDataSectionValueEnum.IVA_AMOUNT,
      FieldBillingDataSectionValueEnum.ICE_AMOUNT,
      FieldBillingDataSectionValueEnum.APPROVAL_AMOUNT,
    ]);

  const sectionFieldListCashMerchantData: ContentInformation[] =
    getContentInformationByFilter(sectionList, [
      FieldMerchantDataSectionValueEnum.MERCHANT,
      FieldMerchantDataSectionValueEnum.MERCHANT_ID,
      FieldMerchantDataSectionValueEnum.PAYMENT_METHOD,
      FieldMerchantDataSectionValueEnum.TOTAL,
      FieldMerchantDataSectionValueEnum.PAYMENT_PIN,
      FieldMerchantDataSectionValueEnum.DESCRIPTION,
    ]);

  const sectionFieldListCashBillingData: ContentInformation[] =
    getContentInformationByFilter(sectionList, [
      FieldBillingDataSectionValueEnum.CLIENT,
      FieldBillingDataSectionValueEnum.IDENTIFICATION_TYPE,
      FieldBillingDataSectionValueEnum.IDENTIFICATION_NUMBER,
      FieldBillingDataSectionValueEnum.EMAIL,
      FieldBillingDataSectionValueEnum.PHONE_NUMBER,
    ]);

  const approval_state = {
    [PaymentMethodsEnum.CASH]: {
      [CardSectionValueEnum.MERCHANT_DATA]: sectionFieldListCashMerchantData,
      [CardSectionValueEnum.BILLING_DATA]: sectionFieldListCashBillingData,
    },
    [PaymentMethodsEnum.TRANSFER]: {
      [CardSectionValueEnum.MERCHANT_DATA]:
        sectionFieldListTransferMerchantData,
      [CardSectionValueEnum.BILLING_DATA]: sectionFieldListTransferBillingData,
    },
  };

  const fieldList: Record<string, object> = {
    [TransactionStatusEnum.APPROVAL]: approval_state,
    [TransactionStatusEnum.APPROVED]: approval_state,
    [TransactionStatusEnum.INITIALIZED]: {
      [PaymentMethodsEnum.CASH]: {
        [CardSectionValueEnum.MERCHANT_DATA]: sectionFieldListCashMerchantData,
        [CardSectionValueEnum.BILLING_DATA]: sectionFieldListCashBillingData,
      },
      [PaymentMethodsEnum.TRANSFER]: {
        [CardSectionValueEnum.MERCHANT_DATA]:
          sectionFieldListTransferMerchantData,
        [CardSectionValueEnum.BILLING_DATA]:
          sectionFieldListTransferBillingData,
      },
    },
    [TransactionStatusEnum.DECLINED]: {
      [PaymentMethodsEnum.CASH]: {
        [CardSectionValueEnum.MERCHANT_DATA]: sectionFieldListCashMerchantData,
        [CardSectionValueEnum.BILLING_DATA]: sectionFieldListCashBillingData,
      },
      [PaymentMethodsEnum.TRANSFER]: {
        [CardSectionValueEnum.MERCHANT_DATA]:
          sectionFieldListTransferMerchantData,
        [CardSectionValueEnum.BILLING_DATA]:
          sectionFieldListTransferBillingData,
      },
    },
  };

  return fieldList;
};
