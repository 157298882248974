import { IStyles } from "../../shared/interfaces/Styles.interfaces";

const dropdownListStyle: IStyles = {
  list: {
    overflow: "auto",
    overflowY: "visible",
    p: 0,
  },
  listItem: {
    p: 0,
  },
  paper: {
    bgcolor: "white",
    borderRadius: "8px",
    padding: "0px 4px 0px 0px",
    color: "black",
  },
  textSearch: {
    m: 0,
    p: ({ spacing }) => spacing(1, 3, 1, 2),
  },
};

const getDropdownListStyles = (maxHeight?: number, minWidth?: number) => {
  const styleOption: IStyles = {
    default: {
      maxHeight,
      minWidth: minWidth ? ({ spacing }) => spacing(28.125) : minWidth,
      borderRadius: "8px",
      "&::-webkit-scrollbar": {
        width: 7,
        borderRadius: "8px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "neutral.grey5",
        borderRadius: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "8px",
        backgroundColor: "neutral.grey6",
      },
      ...dropdownListStyle.list,
    },
  };

  return styleOption.default;
};

export { dropdownListStyle, getDropdownListStyles };
