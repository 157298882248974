export interface IActions {
  value: string;
  name: string;
}

export const Actions: IActions[] = [
  {
    value: "sumar",
    name: "Sumar",
  },
  {
    value: "contar",
    name: "Contar",
  },
];

export interface IPeriodicities {
  value: string;
  name: string;
}

export const Periodicities: IPeriodicities[] = [
  {
    value: "per_minute",
    name: "Por minuto",
  },
  {
    value: "per_hour",
    name: "Por hora",
  },
  {
    value: "daily",
    name: "Diaria",
  },
  {
    value: "weekly",
    name: "Semanal",
  },
  {
    value: "biweekly",
    name: "Quincenal",
  },
  {
    value: "monthly",
    name: "Mensual",
  },
  {
    value: "quarterly",
    name: "Trimestral",
  },
  {
    value: "biannual",
    name: "Semestral",
  },
];

export const Transactions: ITransactions[] = [
  {
    value: "approved",
    name: "Aprobada",
  },
  {
    value: "declined",
    name: "Declinada",
  },
  {
    value: "all",
    name: "Todas",
  },
];

export interface ITransactions {
  value: string;
  name: string;
}
