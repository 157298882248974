/**
 * Processors Enum
 */

import { defaultTo } from "lodash";

export enum ProcessorsEnum {
  NIUBIZ = "Niubiz",
  VISANET = "VisaNet Processor",
  FIS = "Fis Processor",
  BANCO_DE_BOGOTA = "BancoBogota",
}

export enum ChipProcessorStatusEnum {
  ENABLED = "HABILITADO",
  DISABLED = "DESHABILITADO",
}

export enum ProcessorStatusEnum {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export enum ProcessorNameEnum {
  FIS = "Worldpay (by Fis) Processor",
}

export const TRANSLATE_PROCESSOR: Record<string, string> = {
  [ProcessorsEnum.FIS]: ProcessorNameEnum.FIS,
};

export const getTranslatedProcessor = (processorName: string): string => {
  return defaultTo(TRANSLATE_PROCESSOR[processorName], processorName);
};
