import { useEffect, useState } from "react";
import { SnackbarAlertProps } from "./SnackbarAlert";

export interface SnackBarAlertState {
  open: boolean;
}

export const useSnackBarAlertState = (
  props: SnackbarAlertProps
): SnackBarAlertState => {
  const [open, setOpen] = useState(props.open);

  useEffect(() => setOpen(props.open), [props.open]);

  return { open };
};
