import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { footerNewConsoleStyles as styles } from "./FooterNewConsole.styles";
import { IFooterNewConsoleProps } from "../../shared/interfaces/IFooterNewConsoleProps";

const FooterNewConsole: React.FC<IFooterNewConsoleProps> = ({
  date,
  modifyName,
  buttonLabel,
  handleClick,
}: IFooterNewConsoleProps) => {
  return (
    <Box sx={styles.footer}>
      <Button
        sx={styles.footerButton}
        variant="contained"
        onClick={handleClick}
      >
        {buttonLabel}
      </Button>
      <Box sx={styles.footerTextWrapper}>
        <Typography sx={styles.footerText} variant="body2">
          Modificado por: {modifyName}
        </Typography>
        <Typography sx={styles.footerText} variant="body2">
          {date}
        </Typography>
      </Box>
    </Box>
  );
};

export default FooterNewConsole;
