import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { ITableRowProps } from "../../../components/Table/TableSimple/interfaces";
import { ITableData } from "../../interfaces/tableData/tableData.interfaces";

export const initialState: ITableData = {};

export const tableDataSlice = createSlice({
  initialState,
  name: "tableData",
  reducers: {
    handleSetSelectedRows: (
      state: Draft<ITableData>,
      { payload }: PayloadAction<ITableRowProps[]>
    ) => {
      state.selectedRowsStore = payload;
    },
  },
});

export default tableDataSlice.reducer;
