import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetNodeInfoResponse } from "../../../../types/get_node_info_response";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { getJwtAuth } from "../../../shared/utils/getJwtAuth_utils";
import { MerchantResponse } from "../../../../types/get_merchant_response";
import { ConfigIdEnum } from "../../../shared/constants/initial_state_legal_details";
import { MerchantData } from "../../../../types/merchant-data";
import { HierarchyNodeInfoRequest } from "../../../../types/hierarchy_node_info_request";

export const getNodeInfoInitialData = createAsyncThunk<
  GetNodeInfoResponse,
  { configIds?: string; publicMerchantId: string }
>("general/nodeInfoInitialData", async ({ configIds, publicMerchantId }) => {
  const response = await axios.post<GetNodeInfoResponse>(
    API_ROUTES.NODE_INFO,
    { configIds: configIds, publicMerchantId },
    {
      headers: {
        Authorization: getJwtAuth(),
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
});

export const getMerchantInfo = createAsyncThunk<
  { data: MerchantResponse | MerchantData; configId: string },
  { configId: ConfigIdEnum; publicMerchantId: string }
>("general/merchantNodeInfo", async ({ configId, publicMerchantId }) => {
  const response = await axios.post<MerchantResponse | MerchantData>(
    API_ROUTES.MERCHANT_NODE_INFO,
    { configId, publicMerchantId },
    {
      headers: {
        Authorization: getJwtAuth(),
        "Content-Type": "application/json",
      },
    }
  );

  return { configId, data: response.data };
});
export const updateHierarchyNodeInfo = createAsyncThunk<
  boolean,
  HierarchyNodeInfoRequest
>("general/postHierarchyNode", async (payload: HierarchyNodeInfoRequest) => {
  const response = await axios.put<boolean>(
    API_ROUTES.HIERARCHY_NODE_INFO,
    payload
  );

  return response.data;
});
