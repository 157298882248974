import { defaultTo, get } from "lodash";
import { IUseBoardMemberContent } from "./useBoardmembersContent.interfaces";
import { IBoardMember } from "../../../../../store/interfaces/LegalDetailsState.interfaces";
import { PersonTypeEnum } from "../../../../../shared/enum/personTypeEnum";
import { BoardMemberLabel } from "../../../../../shared/enum/BoardMembersEnum";
import { DOCUMENT_TYPE_CATALOG } from "../../../../../shared/catalogs/documentTypeCatalog";
import { useEffect, useRef, useState } from "react";
import { documentTypeBM } from "../../../../../shared/constants/initial_state_legal_details";

export const useBoardMemberContent = (
  boardMember: IBoardMember
): IUseBoardMemberContent => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const iconRef = useRef<HTMLButtonElement>(null);
  const [openOptionList, setOpenOptionList] = useState<boolean>(false);

  const isPersonTypeNatural =
    boardMember.personType === PersonTypeEnum.PHYSICAL;

  const getDocumentType = (): string =>
    defaultTo(DOCUMENT_TYPE_CATALOG[get(boardMember, "documentType", "")], "");

  const handleClick = (): void => setOpenOptionList(!openOptionList);
  const handleClose = (): void => setOpenOptionList(false);

  useEffect(() => {
    iconRef.current && setAnchorEl(iconRef.current);
  }, [iconRef]);

  return {
    anchorEl,
    birthdateLabel: isPersonTypeNatural
      ? BoardMemberLabel.BIRTHDATE_NATURAL
      : BoardMemberLabel.DATE_LEGAL,
    country: isPersonTypeNatural
      ? get(boardMember, "residencyCountry", "")
      : get(boardMember, "addressCountry", ""),
    countryLabel: isPersonTypeNatural
      ? BoardMemberLabel.COUNTRY_NATURAL
      : BoardMemberLabel.COUNTRY_LEGAL,
    date: isPersonTypeNatural
      ? get(boardMember, "birthdate", "")
      : get(boardMember, "constitutionDate", 0),
    dateLabel: isPersonTypeNatural
      ? BoardMemberLabel.DATE_NATURAL
      : BoardMemberLabel.DATE_LEGAL,
    document: isPersonTypeNatural
      ? `${getDocumentType()} ${get(boardMember, "documentNumber", "")}`
      : get(boardMember, "documentNumber", ""),
    documentLabel: BoardMemberLabel.DOCUMENT,
    documentSecondary: `${get(boardMember, "documentNumber", "")}`,
    handleClick,
    handleClose,
    iconRef,
    idType: isPersonTypeNatural
      ? get(
          documentTypeBM.find(
            (docType) => docType.value === get(boardMember, "documentType", "")
          ),
          "name",
          ""
        )
      : "",
    idTypeLabel: isPersonTypeNatural ? BoardMemberLabel.ID_TYPE : "",
    name: isPersonTypeNatural
      ? `${get(boardMember, "names", "")} ${get(boardMember, "lastNames", "")}`
      : `${get(boardMember, "socialReason", "")}`,
    nameLabel: isPersonTypeNatural
      ? BoardMemberLabel.NAME
      : BoardMemberLabel.SOCIAL_REASON,
    openOptionList,
  };
};
