/**
 * DialogEnum
 */

export enum DialogEnum {
  TITLE_ENABLED = "Activar diferido",
  CONTENT_ENABLED = "El comercio podrá ofrecer pagos en cuotas si activas éste diferido.",
  TITLE_DISABLED = "Desactivar diferido",
  CONTENT_DISABLED = "Al desactivar el diferido, las condiciones configuradas se desactivarán temporalmente hasta cambiar su estado a `Activo`.",
  TITLE_DELETE = "Eliminar diferido",
  CONTENT_DELETE = "Si eliminas este diferido el comercio no podrá ofrecer pagos en cuotas a sus clientes.",
}

export enum ButtonTextEnum {
  ENABLED = "Activar",
  DISABLED = "Desactivar",
  DELETE = "Eliminar",
  EDIT = "Editar",
  ACTIVATE = "Activar",
  DEACTIVATE = "Desactivar",
}

export enum ButtonColorEnum {
  PRIMARY = "primary",
  ERROR = "error",
}

export enum UpdateDeferredStatusEnum {
  SUCCESS_MESSAGE_STATUS = "Se actualizó el diferido con éxito.",
  SUCCESS_MESSAGE_STATUS_ENABLED = "Se activó el diferido con éxito.",
  SUCCESS_MESSAGE_STATUS_DISABLED = "Se desactivó el diferido con éxito.",
  ERROR_MESSAGE_STATUS = "Se presentó un error inesperado. Inténtalo nuevamente.",
  CREATE_MESSAGE_STATUS = "Se agregó el diferido con éxito.",
  DELETE_MESSAGE_STATUS = "Se eliminó el diferido con éxito.",
}

export enum TypeDeferredStatusEnum {
  SUCCESS = "success",
  CANCEL = "cancel",
  ERROR = "error",
}

export enum LoadingDialogEnum {
  CREATE = "Creando diferido",
  ENABLED = "Activando diferido",
  DISABLED = "Desactivando diferido",
  DELETE = "Eliminando diferido",
  FINISH = "Diferido creado",
  UPDATE = "Actualizando diferido",
  MESSAGE = "Por favor, espera...",
}

export enum EventNotificationEnum {
  ENABLED = "enabled",
  DISABLED = "disabled",
  DELETE = "delete",
  FINISH = "FINISH",
  UPDATE = "update",
  CREATE = "create",
}

export enum LocalStorageEnum {
  CREATE_DEFERRED = "createDeferred",
  EDIT_DEFERRED = "editDeferred",
}
