/* istanbul ignore file */
import React, { Suspense, useEffect } from "react";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { IAppState } from "./store/reducer";
import {
  getAuthInfo,
  IAppAction,
  setNotification,
} from "./store/actionCreators";
import CreateMerchantIndex from "./containers/CreateMerchantIndex/CreateMerchantIndex";
import MerchantDetailsIndex from "./containers/MerchantDetailsIndex/MerchantDetailsIndex";
import { SnackBarAlert } from "./components/common/SnackBar/SnackBarAlert";
import { INotification } from "./shared/infrastructure/interfaces/INotification";
import { routes } from "./shared/infrastructure/routes";

// @ts-ignore
import Normalize from "react-normalize";

interface INavigationFunctionsProps {
  getAuthInfo(): void;
  setNotification(payload: INotification): void;
}

interface INavigationStateProps {
  notification: INotification;
}

type TNavigationProps = INavigationFunctionsProps & INavigationStateProps;
const Navigation: React.FC<TNavigationProps> = (props: TNavigationProps) => {
  useEffect(() => {
    props.getAuthInfo();
  }, []);

  const handleCloseSnackbar = () => {
    props.setNotification({
      open: false,
      message: props.notification.message,
      type: props.notification.type,
    });
  };

  return (
    <>
      <Normalize />
      <BrowserRouter>
        <Suspense fallback={<div />}>
          <Switch>
            <Route
              path={`${routes.BASE_PATH_CREATE_MERCHANT}${routes.INDEX}`}
              exact
              component={CreateMerchantIndex}
            />
            <Route
              path={`${routes.BASE_PATH_CREATE_MERCHANT}${routes.DETAILS}`}
              exact
              component={MerchantDetailsIndex}
            />
          </Switch>
        </Suspense>
        <SnackBarAlert
          type={props.notification.type}
          msg={props.notification.message}
          open={props.notification.open}
          handlerClose={handleCloseSnackbar}
        />
      </BrowserRouter>
    </>
  );
};

export const mapStateToProps: (state: IAppState) => INavigationStateProps = (
  state: IAppState
): INavigationStateProps => ({
  notification: state.notification!,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => INavigationFunctionsProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): INavigationFunctionsProps => ({
  setNotification: (payload: INotification) =>
    dispatch(setNotification(payload)),
  getAuthInfo: (): void => dispatch(getAuthInfo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
