export enum TabIndex {
  PROCESSORS = 0,
  BUSINESS_RULES = 1,
  DEFERRED = 2,
}

export enum TabIndexNameEnum {
  PROCESSORS = "processors",
  BUSINESS_RULES = "business_rules",
  DEFERRED = "deferred",
}

export const TabNameRecord: Record<number, string> = {
  [TabIndex.PROCESSORS]: TabIndexNameEnum.PROCESSORS,
  [TabIndex.BUSINESS_RULES]: TabIndexNameEnum.BUSINESS_RULES,
  [TabIndex.DEFERRED]: TabIndexNameEnum.DEFERRED,
};

export const TabIndexRecord: Record<string, number> = {
  [TabIndexNameEnum.PROCESSORS]: TabIndex.PROCESSORS,
  [TabIndexNameEnum.BUSINESS_RULES]: TabIndex.BUSINESS_RULES,
  [TabIndexNameEnum.DEFERRED]: TabIndex.DEFERRED,
};
