import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { get } from "lodash";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cancelAction: {
      color: theme.palette.primary.dark,
    },
    closeButton: {
      "&.MuiIconButton-root": {
        marginBottom: 12,
        marginLeft: 24,
        marginRight: 0,
        marginTop: 0,
        padding: 3,
      },
      color: theme.palette.primary.dark,
    },
    dialogActions: {
      paddingBottom: 24,
      paddingRight: 34,
    },
    dialogContentText: {
      marginTop: -10,
      paddingLeft: 34,
      paddingRight: 34,
    },
    dialogTitle: {
      paddingBottom: 24,
      paddingLeft: 34,
      paddingRight: 34,
      paddingTop: 24,
    },
    errorColor: {
      color: theme.palette.error.main,
    },
    primaryColor: {
      color: theme.palette.primary.contrastText,
      padding: "8px 30px 8px 30px !important",
    },
  })
);

export interface ConfirmModalProps {
  open: boolean;
  titleText: string;
  contentText?: string | React.ReactElement;
  acceptText?: string;
  cancelText?: string;
  acceptColor?: "primary" | "error";
  fullWidth?: boolean;
  disableAccept?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  hideCancelButton?: boolean;
  handleClose: () => void;
  handleAccept: () => void;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = (
  props: React.PropsWithChildren<ConfirmModalProps>
) => {
  const classes = useStyles(props);
  const disableButton: boolean = get(props, "disableAccept", false);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth={props.fullWidth}
      maxWidth={props.maxWidth}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box className={classes.dialogTitle} display="flex" flexGrow={1}>
        <Box display="flex" alignItems="center" flexGrow={1}>
          <Typography variant="h5" color="primary">
            {props.titleText}
          </Typography>
        </Box>
      </Box>
      <DialogContent className={classes.dialogContentText}>
        {props.contentText && (
          <DialogContentText id="alert-dialog-description">
            <Typography variant="h6" color="textPrimary">
              {props.contentText}
            </Typography>
          </DialogContentText>
        )}
        {props.children}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={props.handleClose} className={classes.cancelAction}>
          {props.cancelText}
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={disableButton}
          onClick={props.handleAccept}
          className={
            props.acceptColor === "error"
              ? classes.errorColor
              : classes.primaryColor
          }
          autoFocus
        >
          {props.acceptText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmModal.defaultProps = {
  acceptColor: "error",
  acceptText: "Aceptar",
  cancelText: "Cancelar",
  fullWidth: true,
  maxWidth: "sm",
};
