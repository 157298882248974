import React, { FC } from "react";
import { Card, CardContent, Chip, Grid, Typography } from "@mui/material";
import {
  IPaymentInfoProps,
  ProcessorTypeChipInfo,
} from "./PaymentProcessorComponent.interfaces";
import { paymentProcessorComponentStyles } from "./PaymentProcessorComponent.styles";
import { get } from "lodash";
import RadioGroupComponent from "../../RadioGroupComponent/RadioGroupComponent";
import { MaskSkeleton } from "../../MaskSkeleton/MaskSkeleton";
const PaymentProcessorComponent: FC<IPaymentInfoProps> = (
  props: IPaymentInfoProps
) => {
  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item sx={paymentProcessorComponentStyles.titleChip}>
              <Typography key={`chiptitle_${Date.now().toString()}`}>
                {props.chipTitle}
              </Typography>
            </Grid>
            <Grid
              key={`gridChip_${Date.now().toString()}`}
              item
              sx={paymentProcessorComponentStyles.chipContainer}
            >
              {get(props, "processorsTypes", []).map(
                (type: ProcessorTypeChipInfo, index: number) => (
                  <>
                    <Chip
                      key={`chip_${index}`}
                      sx={paymentProcessorComponentStyles.chip}
                      label={type.name}
                    />
                  </>
                )
              )}
            </Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid xs={12} sx={paymentProcessorComponentStyles.titleChip}>
              <Typography>
                {get(props, "processorsOptions.info.titleInfo", "")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <MaskSkeleton
                sx={paymentProcessorComponentStyles.radioSkeleton}
                isLoading={props.isLoading}
              >
                <RadioGroupComponent
                  updateList={props.processorsOptions.info.updateList}
                  name={props.processorsOptions.info.name}
                  items={props.processorsOptions.info.items}
                />
              </MaskSkeleton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default PaymentProcessorComponent;
