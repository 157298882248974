import { theme } from "@kushki/connect-ui";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { ROUTES, SPA_BASENAME } from "./shared/constants/routes";
import { store } from "./store/store";
import { MetricsContainer } from "./containers/MetricsContainer/MetricsContainer";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
import { environment } from "./environments/environment";
import { SecurityWrapperEnum } from "./shared/enums/SecurityWrapperEnum";
import { ChannelMetricsProvider } from "./containers/MetricsContainer/context/useChannelMetricsContext";

const Root = () => {
  return (
    <React.StrictMode>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&display=swap');"
        }
      </style>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500&display=swap');"
        }
      </style>

      <Provider store={store}>
        <ChannelMetricsProvider>
          <ThemeProvider theme={theme}>
            <BrowserRouter basename={SPA_BASENAME}>
              <ModuleSecurityWrapper
                basePath={environment.kushkiUrl}
                componentId={SecurityWrapperEnum.M_DASHBOARD_METRICS}
              >
                <Routes>
                  <Route path={ROUTES.INDEX} element={<MetricsContainer />} />
                </Routes>
              </ModuleSecurityWrapper>
            </BrowserRouter>
          </ThemeProvider>
        </ChannelMetricsProvider>
      </Provider>
    </React.StrictMode>
  );
};

export default Root;
