export enum AnalyticsStatusEnum {
  authorizer = "Autorizado",
  declined = "No autorizado",
  sent = "Enviado",
  pending = "Pendiente",
  reentry = "Reingreso",
}

export const ANALYTICS_STATUS_LIST: string[] = [
  AnalyticsStatusEnum.authorizer,
  AnalyticsStatusEnum.declined,
  AnalyticsStatusEnum.sent,
  AnalyticsStatusEnum.pending,
  AnalyticsStatusEnum.reentry,
];
