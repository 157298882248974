import {
  ItemCategoryItem,
  ItemCategoryProps,
} from "@kushki/connect-ui/dist/Components/Molecules/Filters/ItemCategory/interfaces";
import { useChannelMetricsContext } from "../../../containers/MetricsContainer/context/useChannelMetricsContext";
import { FiltersTitleEnum } from "../../../shared/enums/FiltersTitleEnum";
import { useEffect, useState } from "react";
import {
  TFilterDetailBar,
  TOnChangeFilters,
} from "@kushki/connect-ui/dist/Components/Molecules/Filters/FilterDetailBar/FilterDetailBar.interfaces";
import {
  PaymentTypeItemsFilter,
  ProductsItemsFilter,
  StatusItemsFilter,
} from "../../../shared/constants/MetricsFilterBar/FilterItems";
import { IUseMetricsFilterBar } from "./useMetricsFilterBarState.interfaces";
import { differenceInMonths, sub } from "date-fns";

const buildDetailItem = (
  filters: ItemCategoryItem[],
  title: string
): TFilterDetailBar => ({
  itemCategoryType: "byChips",
  items: filters.map((item: ItemCategoryItem) => ({
    label: item.label,
    value: item.value,
  })),
  title: title,
});

const buildFilterDetailBar = (
  filters: ItemCategoryItem[],
  title: string,
  detail: TFilterDetailBar[]
) => {
  if (filters.length > 0) detail.push(buildDetailItem(filters, title));
};

const uncheckFilters = (
  sideBarFilterList: ItemCategoryItem[],
  stateFilterList: ItemCategoryItem[],
  value: string,
  setFilterCallback: (newFilters: ItemCategoryItem[]) => void
) => {
  sideBarFilterList.find((item) => item.value === value)!.selected = false;
  setFilterCallback(stateFilterList.filter((item) => item.value != value));
};

export const useMetricsFilterBarState = (): IUseMetricsFilterBar => {
  const {
    changeProductFilter,
    changePaymentTypeFilter,
    changeStatusFilter,
    changeCountryFilter,
    changeRangeDateFilter,
    metricsFilters,
  } = useChannelMetricsContext();
  const [filterDetail, setFilterDetail] = useState<TFilterDetailBar[]>([]);
  const currentDate = new Date();
  const initialMinDate = new Date(2023, 3, 1);
  const [minDate] = useState<Date>(
    differenceInMonths(currentDate, initialMinDate) > 6
      ? sub(currentDate, { months: 6 })
      : initialMinDate
  );

  const onSaveFilterSideBar = (selectedItems: ItemCategoryProps[]) => {
    selectedItems.forEach((item: ItemCategoryProps) => {
      switch (item.placeHolder) {
        case FiltersTitleEnum.PRODUCT:
          changeProductFilter(item.items);

          return;
        case FiltersTitleEnum.PAYMENT_TYPE:
          changePaymentTypeFilter(item.items);

          return;
        case FiltersTitleEnum.STATUS:
          changeStatusFilter(item.items);

          return;
      }
    });
  };

  const onDeleteItemFilterBar = ({ affected }: TOnChangeFilters) => {
    switch (affected.title) {
      case FiltersTitleEnum.PRODUCT:
        uncheckFilters(
          ProductsItemsFilter,
          metricsFilters.productFilter,
          affected.itemDeleted.value,
          changeProductFilter
        );

        return;
      case FiltersTitleEnum.PAYMENT_TYPE:
        uncheckFilters(
          PaymentTypeItemsFilter,
          metricsFilters.paymentTypeFilter,
          affected.itemDeleted.value,
          changePaymentTypeFilter
        );

        return;
      case FiltersTitleEnum.STATUS:
        uncheckFilters(
          StatusItemsFilter,
          metricsFilters.statusFilter,
          affected.itemDeleted.value,
          changeStatusFilter
        );

        return;
    }
  };

  const onSelectCountry = (selectedCountry: string) => {
    changeCountryFilter(selectedCountry.toUpperCase());
  };

  const onSelectRangeDate = (startDate: string, finishDate: string) => {
    changeRangeDateFilter({ finishDate, startDate });
  };

  useEffect(() => {
    const detail: TFilterDetailBar[] = [];

    buildFilterDetailBar(
      metricsFilters.productFilter,
      FiltersTitleEnum.PRODUCT,
      detail
    );
    buildFilterDetailBar(
      metricsFilters.paymentTypeFilter,
      FiltersTitleEnum.PAYMENT_TYPE,
      detail
    );
    buildFilterDetailBar(
      metricsFilters.statusFilter,
      FiltersTitleEnum.STATUS,
      detail
    );

    setFilterDetail(detail);
  }, [
    metricsFilters.productFilter,
    metricsFilters.paymentTypeFilter,
    metricsFilters.statusFilter,
  ]);

  useEffect(() => {
    ProductsItemsFilter.forEach((i) => {
      i.selected = false;
    });
    setFilterDetail([]);
  }, []);

  return {
    filterDetail,
    minDate,
    onDeleteItemFilterBar,
    onSaveFilterSideBar,
    onSelectCountry,
    onSelectRangeDate,
  };
};
