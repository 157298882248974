export enum MassiveConfigPathEnum {
  SMARTLINKS = "serviceState.smartlinks",
  VPOS = "serviceState.vpos",
  WEBCHECKOUT = "serviceState.webcheckoutStatus",
  COMMISSION_STATUS = "commissionState.status",
  CARDS = "selectedCards",
  VALIDATION_CHARGE = "validationChargeState.validationCharge",
  SANDBOX_CARD_PRESENT = "advancedConfigState.sandboxCardPresent",
  SANDBOX_CARD_NOT_PRESENT = "advancedConfigState.sandboxCardNotPresent",
}
