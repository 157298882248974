import { DetailsCardProps } from "./DetailsCard.interfaces";
import { ModalBodyTab } from "./ModalBodyTab/ModalBodyTab";
import { ModalHeaderInfo } from "./ModalHeaderInfo/ModalHeaderInfo";
import { ModalOverlay } from "@kushki/frontend-molecules/modal";
import React from "react";

export const DetailsCard = (props: DetailsCardProps) => {
  const { showCard, handleCloseCard } = props;

  return (
    <>
      <ModalOverlay
        header={<ModalHeaderInfo {...props} />}
        body={<ModalBodyTab {...props} />}
        openModal={showCard}
        onCloseModal={handleCloseCard}
        overlay={false}
        backdrop={false}
        transition={"left"}
      />
    </>
  );
};
