import { IStyles } from "../../shared/interfaces/Styles.interfaces";

const dropdownItemStyle: IStyles = {
  icon: {
    ":hover": {
      color: "primary.main",
    },
    color: "text.dark",
    minWidth: ({ spacing }) => spacing(0.625),
    p: ({ spacing }) => spacing(0.375, 1, 0.375, 0.375),
  },
  checkBox: {
    minWidth: ({ spacing }) => spacing(0.625),
    p: ({ spacing }) => spacing(0.375, 1, 0.375, 0.375),
  },
  primaryText: {
    color: "neutral.800",
    lineHeight: "150%",
  },
  root: {
    ":hover": {
      backgroundColor: "neutral.grey3",
      color: "primary.main",
      cursor: "pointer",
    },
    flexWrap: "wrap",
    width: "fit-content",
  },
  rowBox: {
    display: "flex",
    flexDirection: "row",
    margin: "0 auto",
  },
  rowColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  secondaryText: {
    color: "text.grey",
    fontStyle: "normal",
    fontWeight: 400,
    letterSpacing: "0.01em",
  },
};

export { dropdownItemStyle };
