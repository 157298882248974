/* istanbul ignore file */
import { defaultTo } from "lodash";
import { CountriesEnum } from "./constants/countries-enum";

export enum ProcessorNameEnum {
  FIS = "FIS PROCESSOR",
}
export const TRANSLATE_PROCESSOR: Record<string, string> = {
  [ProcessorNameEnum.FIS]: "Worldpay (by Fis)",
};
export const translateProcessor = (text: string): string => {
  return defaultTo(TRANSLATE_PROCESSOR[text.toUpperCase()], text);
};
export const centralcounties: string[] = [
  CountriesEnum.PANAMA,
  CountriesEnum.EL_SALVADOR,
  CountriesEnum.PANAMA,
  CountriesEnum.COSTA_RICA,
  CountriesEnum.NICARAGUA,
  CountriesEnum.HONDURAS,
  CountriesEnum.GUATEMALA,
];

export const ALLOWED_INTERNATIONAL_TRANSFER_COUNTRY: string[] = [
  CountriesEnum.ECUADOR,
  CountriesEnum.COLOMBIA,
  CountriesEnum.PERU,
  CountriesEnum.MEXICO,
];
