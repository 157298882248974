import React from "react";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { TableRowContainer } from "./TableRow";
import { DataTableHeader } from "../../../../types/data_table_header";
import { DataTableBody } from "../../../../types/data_table_body";
export interface TableProps {
  dataRows: DataTableBody;
  dataCell: DataTableHeader;
  headerFlag: boolean;
}
export const StyledTableCell = withStyles(
  createStyles({
    head: {
      backgroundColor: "#F7FAFC",
      color: "#6D7781",
    },
    body: {
      fontSize: 14,
      "font-weight": 500,
    },
  })
)(TableCell);
const useStyles = makeStyles(() =>
  createStyles({
    TableContainer: {
      backgroundColor: "#F7FAFC",
      boxShadow: "none",
    },
  })
);
export const ContentTable = (props: TableProps) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <TableContainer component={Paper} className={classes.TableContainer}>
        <Table aria-label="merchant-configuration-table">
          <TableHead>
            <TableRow>
              {props.dataCell.map((val: object) =>
                Object.keys(val).map((value: string, index: number) => (
                  <StyledTableCell key={index}>{value}</StyledTableCell>
                ))
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.dataRows.map((data: object, index: number) => {
              return (
                <TableRow key={index}>
                  <TableRowContainer data={data} />
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};
