export enum ErrorMessageEnum {
  NAME_REQUIRED = "Por favor, ingresa el nombre(s)",
  LAST_NAME_REQUIRED = "Por favor, ingresa el apellido(s)",
  MAX_CHARACTERS = "El máximo permitido de caracteres es ",
  MIN_CHARACTERS = "El mínimo de caracteres es ",
  BIRTHDATE = "Por favor, ingresa la fecha de nacimiento",
  NACIONALITY = "Por favor, selecciona la nacionalidad",
  RESIDENCE = "Por favor, selecciona el país de residencia",
  COUNTRY = "Por favor, selecciona el país de origen del negocio",
  COUNTRY_TWO = "Por favor, selecciona el país de domicilio de la empresa",
  COUNTRY_MORAL = "Por favor, selecciona el país de origen del negocio",
  DOCUMENT_TYPE = "Por favor, selecciona un tipo de identificación",
  DOCUMENT_NUMBER_REQUIRED = "Por favor, ingresa el número de identificación",
  DOCUMENT_NUMBER_MORAL = "Por favor, ingresa el número de TAX ID",
  DOCUMENT_NUMBER_MAX_MIN = "El número de identificación debe tener entre ",
  EMAIL_REQUIRED = "Por favor, introduce un correo electrónico",
  EMAIL = "Por favor, introduce un correo electrónico válido",
  RAZON_SOCIAL = "Por favor, ingresa la razón social",
  DOCUMENT_NUMBER_VALID = "Por favor, verifica que el número de identificación sea válido",
  TAX_ID_VALID = "Por favor, verifica que el número TAX ID sea válido",
  PHONE = "Teléfono inválido. Verifica que el número sea correcto.",
  SOCIAL_REASON_REQUIRED = "Por favor, ingresa la razón social",
  TAX_ID_REQUIRED = "Por favor, ingresa el número de TAX ID",
  TAX_ID_VALIDATE = "Por favor, verifica que el número de TAX ID sea válido",
  DATE = "Por favor, ingresa la fecha válida",
  CONSTITUTION_DATE = "Por favor, ingresa la fecha de constitución",
  CHARCTERS = "No se permiten caracteres especiales",
  NUMBERS = "Este campo solo permite números",
  PERCENTAGE = "Por favor, ingresa una cantidad válida",
  RADIO = "Por favor, elige un tipo de persona",
}
