export enum BillingDataFormLabelsEnum {
  address = "Dirección",
  city = "Ciudad",
  commune = "Comuna",
  distric = "Distrito",
  cologne = "Colonia",
  department = "Departamento",
  fiscal_direction = "Dirección fiscal",
  fiscal_phone = "Número telefónico fiscal",
  fiscal_population_key = "Clave de la población fiscal",
  fiscal_postal_code = "Código postal fiscal",
  merchant_phone = "Teléfono de la empresa",
  municipality = "Municipio",
  municipality_br = "Ciudad",
  obligations = "Obligaciones",
  population_key = "Clave de la población",
  postal_code = "Código Postal",
  province = "Provincia",
  canton = "Canton",
  regime = "Régimen",
  region = "Región",
  state = "Estado",
  state_key = "Clave del estado",
  trade_tax_colony = "Colonia fiscal del comercio",
  tax_regime = "Régimen Fiscal",
  tax_status_key = "Clave del estado fiscal",
  zipCode = "Código Postal",
  zipCodeBr = "Código Postal (CEP)",
  activitySRI = "Actividad económica para SRI",
  taxpayerTypeId = "Tipo de contribuyente",
  activityEC = "Actividad",
  conceptRetentionRent = "Concepto retención de la renta",
  checkUseBasicData = "Se utilizará la misma información que se configuró para actividad económica en la sección de datos básicos.",
}
