import { TableInfo } from "@kushki/connect-ui";
import { Box, Divider, Typography } from "@mui/material";
import BaseDataContainer from "../commons/BaseDataContainer/BaseDataContainer";
import { useCredentials } from "./state/useCredentials";
import { webhookNotificationsStyles as styles } from "../WebhookNotifications/Webhooknotification.styles";
import React from "react";

const Credentials = () => {
  const { isLoading, showCredentials, table } = useCredentials();

  return (
    <>
      <Divider sx={styles.divider} />
      <BaseDataContainer
        title="Credenciales"
        onClickEdit={showCredentials}
        textEditButton={"Ver todas las credenciales"}
      >
        <Box
          bgcolor="neutral.grey3"
          borderRadius="8px"
          marginBottom="16px"
          p={3}
        >
          <Typography color="neutral.grey8" sx={styles.titleText} mb={2}>
            Credenciales creadas
          </Typography>
          <Box overflow={"auto"}>
            <TableInfo
              isLoading={isLoading}
              header={{
                cells: table.columns,
                headerProps: {
                  isDisable: false,
                },
              }}
              rows={table.rows}
            />
          </Box>
        </Box>
      </BaseDataContainer>
    </>
  );
};

export default Credentials;
