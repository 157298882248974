import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import {
  camelCase,
  chunk,
  cloneDeep,
  defaultTo,
  filter,
  get,
  includes,
  isArray,
  isEmpty,
  isEqual,
  isUndefined,
  join,
  map,
  omit,
  pick,
  remove,
  set,
  slice,
  split,
  unset,
  upperCase,
} from "lodash";
import {
  EndpointsPathsEnum,
  ModalPathsEnum,
  PathsEnum,
} from "../../../shared/infrastructure/enums/PathsEnum";
import {
  SecurityContext,
  VerifyIfComponentEnable,
} from "@kushki/security-wrapper";
import {
  COMPONENT_ID_BY_MODAL,
  TableStatusEnum,
  TableValueEnum,
  TEXT_TRANSFORM_COLUMNS,
  TEXT_TRANSFORM_SUBTYPE_COLUMNS,
} from "../../../shared/infrastructure/enums/DataTableEnum";
import {
  GENERAL_CONDITIONS_WITH_COMMA_SEPARATOR,
  LabelEnum,
} from "../../../shared/infrastructure/enums/LabelEnum";
import {
  ObservationInfo,
  SearchRequestRuleByIdResponse,
} from "../../../../types/search_request_rule_by_id_response";
import { SearchRuleByIdResponse } from "../../../../types/search_rule_by_id_response";
import { useContext, useEffect, useState } from "react";
import { IModalHeaderProps } from "@kushki/connect-ui/dist/Components/Molecules/ModalDetail/ModalHeader/ModalHeader.interfaces";
import {
  addPlusToPhoneNumber,
  convertTimestampToDate,
  convertTimestampToDateS,
  convertTimestampToDateTime,
  convertTimestampToTime,
  getSecureServiceName,
  validateProviderPath,
} from "../../../utils/utilsFile";
import {
  Header,
  ICardTableColumnValues,
  IElementModalBody,
  IElementModalComponentProps,
  IElementModalTableComponentProps,
  IModalContainer,
  IModalHeaderTemplate,
  IModalInformation,
  IModalProperties,
  IModalTemplateInfo,
  IUseModalContainer,
  ModalActionButtons,
  Row,
} from "../../../shared/infrastructure/interfaces/IModalContainer";
import {
  deleteRule,
  deleteRuleRequest,
  getHierarchyNodes,
  getMerchantHierarchy,
  IAppAction,
  setMerchantHierarchy,
  setMerchantInformation,
  setOpenConfirmModal,
  setOpenModalInfo,
  setOpenModalRejected,
  setRequestRuleById,
  setSearchRequestRuleById,
  setSearchRuleById,
  updateRuleRequest,
} from "../../../store/actionCreators";
import { format } from "date-fns-tz";
import { FormatDateEnum } from "../../../shared/infrastructure/enums/FormatDateEnum";
import {
  CardListOrientationEnum,
  FieldModelEnum,
  MODAL_HEADER_TEMPLATE,
  MODAL_TEMPLATE,
  TakeRiskStatusEnum,
  TEXT_TRANSFORM_MODAL,
  ValueEnum,
  ValueProvidersEnum,
  WHITELIST_MODAL_TEMPLATE,
} from "../../../shared/infrastructure/enums/ModalEnum";
import {
  GENERAL_CONDITIONS_HEADERS,
  Labels,
  Operators as GeneralConditionOperator,
  SPEED_CONDITIONS_HEADERS,
  TitleSection,
  Values,
} from "../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { StatusEnum } from "../../../shared/infrastructure/enums/StatusEnum";
import { AllRequestType } from "../../../shared/infrastructure/constants/RequestType";
import { ConfigurationTypeEnum } from "../../../shared/infrastructure/enums/ConfigurationTypeEnum";
import { ThunkAction } from "redux-thunk";
import {
  CardTypeValueEnum,
  GroupEnum,
  SubTypeEnum,
  TransactionTypeValueEnum,
} from "../../../shared/infrastructure/enums/CreateRuleConstans";
import {
  ConfigurationResponse,
  Operators,
  Providers,
} from "../../../../types/configuration_response";
import { UpdateRuleRequest } from "../../../../types/update_rule_request";
import { ConfirmModalEnum } from "../../../shared/infrastructure/enums/ConfirmModalEnum";
import { TabIndexEnum } from "../../../shared/infrastructure/enums/TabIndexEnum";
import { RequestTypeEnum } from "../../../shared/infrastructure/enums/RequestTypeEnum";
import {
  ConditionEnum,
  ConditionLabels,
  ConditionOperators,
} from "../../../shared/infrastructure/enums/ConditionEnum";
import { MerchantHierarchyResponse } from "../../../../types/merchant_hierarchy_response";
import { GetRuleByIdResponse } from "../../../../types/get_rule_by_id";
import { FieldsRule } from "../../../shared/infrastructure/constants/RuleRequestConstants";
import { ProcessorsInformation } from "../../../../types/processors_list";
import { useHistory } from "react-router";

export const useModalContainerState = (
  props: IModalContainer
): IUseModalContainer => {
  const dispatch = useDispatch();
  const securityContextValue = useContext(SecurityContext);
  const isLoadingOneRule: boolean | undefined = useSelector(
    (state: IAppState) => state.isLoadingOneRule
  );
  const isLoadingRequest: boolean | undefined = useSelector(
    (state: IAppState) => state.isLoadingRequest
  );
  const history = useHistory();
  const configurations: ConfigurationResponse = useSelector(
    (state: IAppState) => defaultTo(state.configuration, {})
  );
  let requestRuleById:
    | SearchRequestRuleByIdResponse
    | SearchRuleByIdResponse
    | undefined = useSelector((state: IAppState) => state.requestRule);

  const processorList: ProcessorsInformation[] | undefined = useSelector(
    (state: IAppState) => state.processorsList
  );

  const requestRuleMonitorById: GetRuleByIdResponse | undefined = useSelector(
    (state: IAppState) => state.searchRuleById
  );

  const [modalHeader, setModalHeader] =
    useState<Omit<IModalHeaderProps, "isLoading">>();
  const [ruleGeneralInfo, setRuleGeneralInfo] = useState<
    IElementModalComponentProps | undefined
  >();
  const [ruleConfigInfo, setRuleConfigInfo] = useState<
    IElementModalComponentProps | undefined
  >();
  const [configSecondSection, setConfigSecondSection] = useState<
    IElementModalBody[] | undefined
  >();
  const [speedConditions, setSpeedConditions] =
    useState<IElementModalTableComponentProps>({
      headers: [],
      isLoading: false,
      isVisible: false,
      rows: [],
      title: "",
    });

  const [ruleGeneralConditionsInfo, setRuleGeneralConditionsInfo] =
    useState<IElementModalTableComponentProps>({
      headers: [],
      isLoading: false,
      isVisible: false,
      rows: [],
      title: "",
    });
  const [ruleSpecialConditionsInfo, setRuleSpecialConditionsInfo] =
    useState<IElementModalTableComponentProps>({
      headers: [],
      isLoading: false,
      isVisible: false,
      rows: [],
      title: "",
    });
  const [ruleGeneralCountryMerchantInfo, setRuleGeneralCountryMerchantInfo] =
    useState<IElementModalComponentProps | undefined>();
  const [ruleAdditionalInfo, setRuleAdditionalInfo] = useState<
    IElementModalComponentProps | undefined
  >();
  const requestModalError: boolean = useSelector((state: IAppState) =>
    defaultTo(state.requestError, false)
  );
  const isOpenModal: boolean = useSelector((state: IAppState) =>
    defaultTo(state.openModalInfo, false)
  );
  const approveRuleRequest: UpdateRuleRequest = {
    status: StatusEnum.APPROVED,
  };
  const defaultMerchantHierarchy: MerchantHierarchyResponse = {
    dataBranch: { nodes: [], count: 0 },
    dataCustomer: { nodes: [], count: 0 },
  };

  useEffect(() => {
    setRuleGeneralInfo(undefined);
    setRuleConfigInfo(undefined);
    setModalHeader(undefined);
    setRuleGeneralCountryMerchantInfo(undefined);
    setRuleAdditionalInfo(undefined);
    buildModalInfo(props.tabName);
  }, [requestRuleById, processorList, requestRuleMonitorById]);

  let ruleStatus: string;
  let requestType: string;
  let configType: string;
  let labelTag: string;
  let tabName: string;

  const getModalTemplate = (
    tabName: string,
    configType: string
  ): IModalTemplateInfo => {
    if (
      isEqual(configType, ConfigurationTypeEnum.WHITELIST) &&
      !isUndefined(WHITELIST_MODAL_TEMPLATE[`${tabName}`])
    ) {
      return WHITELIST_MODAL_TEMPLATE[`${tabName}`];
    } else if (
      (isEqual(tabName, LabelEnum.PENDING_REVIEW) ||
        isEqual(tabName, LabelEnum.REJECTED_APPLICATIONS)) &&
      !isEqual(
        get(requestRuleById, PathsEnum.REQUEST_TYPE, ""),
        RequestTypeEnum.CREATION
      )
    ) {
      const tempModalTemplate: Record<string, IModalTemplateInfo> =
        cloneDeep(MODAL_TEMPLATE);
      const entityName: string = get(
        requestRuleById,
        PathsEnum.ENTITY_NAME,
        ""
      );

      if (
        isEqual(
          get(requestRuleById, PathsEnum.SUB_TYPE, ""),
          SubTypeEnum.COUNTRY
        )
      )
        set(
          tempModalTemplate[`${tabName}`],
          ModalPathsEnum.COUNTRY_INFO_USER_NAME,
          LabelEnum.GENERAL_INFO_RULES_LAST_EDITION
        );
      else
        switch (upperCase(entityName)) {
          case GroupEnum.OWNER:
            set(
              tempModalTemplate[`${tabName}`],
              ModalPathsEnum.COMMERCE_OWNER_INFO_USER_NAME,
              LabelEnum.GENERAL_INFO_RULES_LAST_EDITION
            );
            break;
          case GroupEnum.CUSTOMER:
            set(
              tempModalTemplate[`${tabName}`],
              ModalPathsEnum.COMMERCE_CUSTOMER_INFO_USER_NAME,
              LabelEnum.GENERAL_INFO_RULES_LAST_EDITION
            );
            break;
          case GroupEnum.BRANCH:
            set(
              tempModalTemplate[`${tabName}`],
              ModalPathsEnum.COMMERCE_BRANCH_INFO_USER_NAME,
              LabelEnum.GENERAL_INFO_RULES_LAST_EDITION
            );
            break;
        }

      return tempModalTemplate[`${tabName}`];
    } else {
      return tabName === LabelEnum.WHITE_LISTS
        ? cloneDeep(MODAL_TEMPLATE[`${tabName}_${configType}`])
        : cloneDeep(MODAL_TEMPLATE[`${tabName}`]);
    }
  };

  const buildModalInfo = (tab: string): void => {
    const isFirstLevel_WhiteList: boolean = [
      LabelEnum.FIRST_LEVEL_RULES,
      LabelEnum.WHITE_LISTS,
    ].includes(props.tabName as LabelEnum);

    ruleStatus = isFirstLevel_WhiteList
      ? get(requestRuleById, PathsEnum.RULE_STATUS, "")
      : get(requestRuleById, PathsEnum.STATUS, "");

    if (isFirstLevel_WhiteList && !isUndefined(requestRuleMonitorById)) {
      requestRuleById = requestRuleMonitorById.rule;
      ruleStatus = get(requestRuleById, PathsEnum.RULE_STATUS, "");
    }

    requestType = get(requestRuleById, PathsEnum.REQUEST_TYPE, "");
    configType = isFirstLevel_WhiteList
      ? get(requestRuleById, PathsEnum.TYPE_RULE, "")
      : get(requestRuleById, PathsEnum.CONFIGURATION_TYPE, "");
    const entityName: string = get(requestRuleById, PathsEnum.ENTITY_NAME, "");
    tabName = tab;
    labelTag = getLabelTag(tabName);

    const isRoleEnable: boolean = isComponentEnable();
    const modalTemplate: IModalTemplateInfo = getModalTemplate(
      tabName === LabelEnum.WHITE_LISTS ? `${tabName}_${ruleStatus}` : tabName,
      configType
    );

    const disableRejectedInfo: IModalInformation | undefined =
      updateModalTemplateStatus(
        ruleStatus,
        ModalPathsEnum.DISABLE_REJECTED_INFO,
        modalTemplate
      );

    const generalCountryInfo: IModalInformation | undefined =
      updateModalTemplateStatus(
        ruleStatus,
        ModalPathsEnum.GENERAL_COUNTRY_INFO,
        modalTemplate
      );

    const generalMerchantInfo: IModalInformation | undefined =
      getGeneralMerchantInfo(entityName, modalTemplate);

    const generalConfigInfo: IModalInformation | undefined =
      getGeneralConfigInfo(modalTemplate);

    if (!isUndefined(disableRejectedInfo)) {
      const disableRejectedColumns: IModalProperties =
        disableRejectedInfo.columns;

      const rule: IElementModalBody[] = Object.keys(disableRejectedColumns).map(
        (property: string) => ({
          label: get(disableRejectedColumns, property, ""),
          secondaryText: "",
          text: getInformation(property, requestRuleById),
        })
      );

      if (isEqual(ruleStatus, StatusEnum.DISABLE)) {
        ruleStatus = StatusEnum.RULES_DISABLED;
      }
      setRuleGeneralInfo({
        title: get(disableRejectedInfo, "title", ""),
        body: [...rule],
        isVisible:
          disableRejectedInfo.status === labelTag &&
          ((isRoleEnable && isEqual(tabName, LabelEnum.PENDING_REVIEW)) ||
            isEqual(tabName, LabelEnum.REJECTED_APPLICATIONS) ||
            isEqual(tabName, LabelEnum.WHITE_LISTS) ||
            (isEqual(tabName, LabelEnum.FIRST_LEVEL_RULES) &&
              isEqual(ruleStatus, StatusEnum.RULES_DISABLED))) &&
          !requestModalError,
      });
    }

    if (!isUndefined(generalCountryInfo)) {
      let templateObj: IModalTemplateInfo = {
        ...modalTemplate,
        generalCountryInfo,
      };
      let columnsRuleGeneralCountryMerchantInfo: string =
        ModalPathsEnum.GENERAL_COUNTRY_INFO;
      let titleRuleGeneralCountryMerchantInfo: string =
        generalCountryInfo.title;

      const rule_name: ValueEnum = get(requestRuleById, "rule.name", "");

      if (
        (requestRuleById?.type === SubTypeEnum.SECURITY_COMMERCE ||
          [ValueEnum.WITHOUT_HIERARCHY, ValueEnum.WITH_HIERARCHY].includes(
            rule_name
          )) &&
        !isUndefined(generalMerchantInfo)
      ) {
        columnsRuleGeneralCountryMerchantInfo =
          ModalPathsEnum.GENERAL_MERCHANTS_INFO;
        templateObj.generalMerchantInfo = generalMerchantInfo;
        titleRuleGeneralCountryMerchantInfo = generalMerchantInfo!.title;
        if (
          !isUndefined(requestRuleById?.entityName) ||
          ValueEnum.WITH_HIERARCHY === rule_name
        ) {
          columnsRuleGeneralCountryMerchantInfo =
            ModalPathsEnum.GENERAL_MERCHANTS_HIERARCHY_INFO;
        }
      }

      if (
        get(requestRuleById, "requestType", "") === RequestTypeEnum.CREATION &&
        (isEqual(tabName, LabelEnum.FIRST_LEVEL_RULES) ||
          isEqual(tabName, LabelEnum.PENDING_REVIEW))
      ) {
        set(
          templateObj,
          `${columnsRuleGeneralCountryMerchantInfo}.columns.userNameRequest`,
          LabelEnum.GENERAL_INFO_RULES_CREATED_BY
        );
        templateObj = omit(templateObj, [
          `${columnsRuleGeneralCountryMerchantInfo}.columns.userName`,
        ]);
      }

      if (ruleStatus === StatusEnum.RULES_DISABLED) {
        const omitColumns: string[] = isEqual(
          get(requestRuleById, "subType", ""),
          SubTypeEnum.COMMERCE
        )
          ? [`${columnsRuleGeneralCountryMerchantInfo}.columns.service`]
          : [];

        templateObj = omit(templateObj, [
          ...omitColumns,
          `${columnsRuleGeneralCountryMerchantInfo}.columns.merchantId`,
          `${columnsRuleGeneralCountryMerchantInfo}.columns.userName`,
          `${columnsRuleGeneralCountryMerchantInfo}.columns.updatedAt`,
        ]);
      }

      const generalInfo: IElementModalBody[] = Object.keys(
        get(templateObj, `${columnsRuleGeneralCountryMerchantInfo}.columns`)
      ).map((property: string) => ({
        label: get(
          templateObj,
          `${columnsRuleGeneralCountryMerchantInfo}.columns.${property}`,
          ""
        ),
        secondaryText: "",
        text: getInformation(property, requestRuleById),
      }));

      setRuleGeneralCountryMerchantInfo({
        title: titleRuleGeneralCountryMerchantInfo,
        body: [...generalInfo],
        isVisible: true,
      });
    }

    if (!isUndefined(requestRuleById)) {
      if (tab === LabelEnum.WHITE_LISTS) {
        const conditions: ICardTableColumnValues[] =
          getWhiteListCondition(requestRuleById);
        setRuleGeneralConditionsInfo({
          title: LabelEnum.GENERAL_CONDITION,
          headers: [
            { title: LabelEnum.CONDITION_TAG },
            { title: LabelEnum.CONDITION_OPERATOR },
            { title: LabelEnum.CONDITION_VALUE },
          ],
          rows: [
            [
              { text: get(conditions, "[0].tag", "") },
              { text: get(conditions, "[0].operator", "") },
              { text: get(conditions, "[0].value", "") },
            ],
          ],
          isVisible: tab === LabelEnum.WHITE_LISTS,
        });
      } else {
        getAllGeneralConditions(requestRuleById);
      }
    }

    if (!isUndefined(generalConfigInfo)) {
      const configInfoColumns: IModalProperties = generalConfigInfo.columns;
      const columnKeys: string[] = Object.keys(configInfoColumns);

      let sectionColumnKeys: string[][];

      if (columnKeys.length > 3) {
        sectionColumnKeys = chunk(columnKeys, 2);
      } else {
        sectionColumnKeys = [slice(columnKeys, 0, 1), slice(columnKeys, 1, 3)];
      }

      const firstSectionBody: IElementModalBody[] = sectionColumnKeys[0].map(
        (property: string) => ({
          label: get(configInfoColumns, property, ""),
          secondaryText: "",
          text: getInformation(property, requestRuleById),
        })
      );
      const secondSectionBody: IElementModalBody[] = sectionColumnKeys[1].map(
        (property: string) => ({
          label: get(configInfoColumns, property, ""),
          secondaryText: "",
          text: getInformation(property, requestRuleById),
        })
      );

      setRuleConfigInfo({
        title: get(generalConfigInfo, "title", ""),
        body: [...firstSectionBody],
        isVisible: true,
        secondSectionOrientation: CardListOrientationEnum.VERTICAL,
      });
      setConfigSecondSection(secondSectionBody);
    }

    let headerTemplate: IModalHeaderTemplate | undefined =
      MODAL_HEADER_TEMPLATE[`${tab}_${getLabelTag(tabName)}`];
    if (ruleStatus === StatusEnum.PENDING)
      headerTemplate = MODAL_HEADER_TEMPLATE[`${requestType}_${ruleStatus}`];

    if (isFirstLevel_WhiteList) {
      ruleStatus = get(
        requestRuleMonitorById,
        `rule.rule.${PathsEnum.STATUS}`,
        ""
      );
      requestType = get(
        requestRuleMonitorById,
        `rule.${PathsEnum.TYPE_RULE}`,
        ""
      );
      configType = get(
        requestRuleMonitorById,
        `rule.${PathsEnum.CONFIGURATION_TYPE}`,
        ""
      );
      headerTemplate = MODAL_HEADER_TEMPLATE[`${tab}_${getLabelTag(tabName)}`];
    }

    if (!isUndefined(headerTemplate)) {
      setActionButtons(headerTemplate, tab, ruleStatus, configType);
    }
  };

  const isComponentEnable = () => {
    return VerifyIfComponentEnable(
      COMPONENT_ID_BY_MODAL[`${props.tabName}_${labelTag}`],
      securityContextValue
    );
  };

  const transformGeneralData = (
    condition: Operators,
    value: string,
    label: string
  ): [string, string] => {
    const operator: string = get(condition, "value", "");
    const title: string = get(condition, "title", "");
    let splitValue: string[] = split(defaultTo(value, ""), ",");

    if (isEqual(value.toLowerCase(), GeneralConditionOperator.ALL))
      return [ValueEnum.ALL_CONDITION, "-"];
    switch (operator) {
      case GeneralConditionOperator.BETWEEN_DATE_OUT:
      case GeneralConditionOperator.BETWEEN_DATE:
        if (label === ConditionEnum.DATE) {
          const dateValue = join(
            split(value, ",").map(convertTimestampToDateS),
            " - "
          );
          return [title, dateValue];
        } else {
          const dateValue = join(
            split(value, ",").map(convertTimestampToTime),
            " - "
          );
          return [title, dateValue];
        }
      case GeneralConditionOperator.BETWEEN:
        return [title, split(value, ",").join(" - ")];
      case GeneralConditionOperator.ALL:
        return [ValueEnum.ALL_CONDITION, "-"];
      default:
        const separator: string =
          GENERAL_CONDITIONS_WITH_COMMA_SEPARATOR.includes(label) ? ", " : "\n";

        if (isEqual(label, LabelEnum.PHONE_NUMBER))
          splitValue = splitValue.map(addPlusToPhoneNumber);

        if (isEqual(label, LabelEnum.TRANSACTION_DATE)) {
          const dateValue: string = join(
            split(value, ",").map(convertTimestampToDateS),
            " "
          );
          return [title, dateValue];
        }

        if (isEqual(label, LabelEnum.PROCESSOR)) {
          let processorName: string = "";

          if (!isUndefined(processorList) && !isEmpty(processorList)) {
            processorName = get(
              processorList.filter(
                (processor) =>
                  processor.publicProcessorId === join(splitValue, separator)
              )[0],
              "processorName",
              ""
            );
          }

          return [title, processorName];
        }

        if (isEqual(label, LabelEnum.CARD_TYPE))
          splitValue = JSON.parse(splitValue[0])
            ? [CardTypeValueEnum.CREDIT]
            : [CardTypeValueEnum.DEBIT];

        if (isEqual(label, LabelEnum.TRANSACTION_TYPE))
          splitValue = splitValue.map((item) => {
            return TransactionTypeValueEnum[item];
          });

        return [title, join(splitValue, separator)];
    }
  };

  const getSpeedConditionsHeaders = (speedAction: string): Header[] => {
    const actionCountHeaders: Header[] = cloneDeep(SPEED_CONDITIONS_HEADERS);
    remove(
      actionCountHeaders,
      (item: Header) => item.title === Labels.CURRENCY
    );

    return isEqual(speedAction, ValueEnum.SUM)
      ? SPEED_CONDITIONS_HEADERS
      : actionCountHeaders;
  };

  const getSpeedConditionsRows = (
    ruleRequest:
      | SearchRequestRuleByIdResponse
      | SearchRuleByIdResponse
      | undefined,
    speedAction: string
  ): Row[][] => {
    const speedConditions: Row[][] = [];

    if (isEqual(speedAction, ValueEnum.COUNT)) {
      speedConditions.push([
        { text: defaultTo(TEXT_TRANSFORM_MODAL[speedAction], "") },
        {
          text: defaultTo(
            TEXT_TRANSFORM_MODAL[
              get(ruleRequest, PathsEnum.PERIODICITY_COUNT, "")
            ],
            ""
          ),
        },
        {
          text: defaultTo(get(ruleRequest, PathsEnum.LIMIT, ""), ""),
        },
        {
          text: defaultTo(
            TEXT_TRANSFORM_MODAL[
              get(ruleRequest, PathsEnum.TRANSACTION_STATUS_COUNT, "")
            ],
            ""
          ),
        },
      ]);
    } else {
      speedConditions.push([
        { text: defaultTo(TEXT_TRANSFORM_MODAL[speedAction], "") },
        {
          text: defaultTo(
            TEXT_TRANSFORM_MODAL[
              get(ruleRequest, PathsEnum.PERIODICITY_SUM, "")
            ],
            ""
          ),
        },
        {
          text: defaultTo(get(ruleRequest, PathsEnum.MAX_AMOUNT, ""), ""),
        },
        {
          text: defaultTo(
            get(ruleRequest, PathsEnum.MAX_AMOUNT_CURRENCY, ""),
            ""
          ),
        },
        {
          text: defaultTo(
            TEXT_TRANSFORM_MODAL[
              get(ruleRequest, PathsEnum.TRANSACTION_STATUS_SUM, "")
            ],
            ""
          ),
        },
      ]);
    }

    return speedConditions;
  };

  const getGeneralMerchantInfo = (
    entityName: string,
    modalTemplate: IModalTemplateInfo | undefined
  ): IModalInformation | undefined => {
    let generalMerchantInfo: IModalInformation | undefined;

    switch (upperCase(entityName)) {
      case GroupEnum.OWNER:
        generalMerchantInfo = updateModalTemplateStatus(
          ruleStatus,
          ModalPathsEnum.GENERAL_MERCHANTS_OWNER_INFO,
          modalTemplate
        );
        break;
      case GroupEnum.CUSTOMER:
        generalMerchantInfo = updateModalTemplateStatus(
          ruleStatus,
          ModalPathsEnum.GENERAL_MERCHANTS_CUSTOMER_INFO,
          modalTemplate
        );
        break;
      case GroupEnum.BRANCH:
        generalMerchantInfo = updateModalTemplateStatus(
          ruleStatus,
          ModalPathsEnum.GENERAL_MERCHANTS_BRANCH_INFO,
          modalTemplate
        );
        break;
    }

    if (isUndefined(generalMerchantInfo))
      generalMerchantInfo = updateModalTemplateStatus(
        ruleStatus,
        ModalPathsEnum.GENERAL_MERCHANTS_INFO,
        modalTemplate
      );

    return generalMerchantInfo;
  };

  const getGeneralConfigInfo = (
    modalTemplate: IModalTemplateInfo | undefined
  ): IModalInformation | undefined => {
    return updateModalTemplateStatus(
      ruleStatus,
      ModalPathsEnum.GENERAL_CONFIG_INFO,
      modalTemplate
    );
  };

  const getLabelTag = (tab: string): string => {
    if (tab === LabelEnum.PENDING_REVIEW && requestRuleById?.isFixed) {
      return LabelEnum.IS_FIXED;
    }
    if (tab === LabelEnum.PENDING_REVIEW) {
      return requestType;
    }

    if (isEqual(ruleStatus, TableStatusEnum.ENABLED)) {
      ruleStatus = TableValueEnum.ENABLE;
    }

    if (isEqual(ruleStatus, TableStatusEnum.DISABLED)) {
      ruleStatus = TableValueEnum.DISABLE;
    }
    return ruleStatus;
  };

  const lastRuleInfoUpdate: ObservationInfo | undefined = (() => {
    const observationInfo: ObservationInfo[] | undefined = defaultTo(
      get(requestRuleById, "observationInfo"),
      get(requestRuleMonitorById, "rule.observationInfo")
    );
    if (isUndefined(observationInfo)) return undefined;

    const sortedObservationInfo = observationInfo.sort(
      (a: ObservationInfo, b: ObservationInfo) =>
        Number(b.updatedAt) - Number(a.updatedAt)
    );

    return sortedObservationInfo.find(Boolean);
  })();

  const updateModalTemplateStatus = (
    status: string,
    path: string,
    template: IModalTemplateInfo | undefined
  ): IModalInformation | undefined => {
    const sectionTemplate: IModalInformation | undefined = get(
      cloneDeep(template),
      path,
      undefined
    );

    if (isUndefined(sectionTemplate)) return sectionTemplate;
    if (
      isUndefined(lastRuleInfoUpdate) &&
      tabName === LabelEnum.PENDING_REVIEW
    ) {
      unset(sectionTemplate, `${ModalPathsEnum.CREATED_BY_INFO}`);
      set(
        sectionTemplate,
        `${ModalPathsEnum.CREATED_BY_INFO}`,
        LabelEnum.GENERAL_INFO_RULES_LAST_EDITION
      );
      unset(sectionTemplate, `${ModalPathsEnum.UPDATED_BY_INFO}`);
    }
    if (StatusEnum[upperCase(status)]) set(sectionTemplate, "status", status);

    return sectionTemplate;
  };

  const setActionButtons = (
    template: IModalHeaderTemplate,
    tab: string,
    status: string,
    configType: string
  ): void => {
    const roleEnableButtonRight: boolean = VerifyIfComponentEnable(
      COMPONENT_ID_BY_MODAL[
        `${props.tabName}_${status}_${LabelEnum.RIGHT_BUTTON}`
      ],
      securityContextValue
    );

    const roleEnableButtonLeft: boolean = VerifyIfComponentEnable(
      COMPONENT_ID_BY_MODAL[
        `${props.tabName}_${status}_${LabelEnum.LEFT_BUTTON}`
      ],
      securityContextValue
    );

    const { buttonPrimary, buttonSecondary } = buildActionButton(
      tab,
      status,
      roleEnableButtonRight,
      roleEnableButtonLeft,
      configType
    );

    let createRq: string;
    if (
      isEqual(tab, LabelEnum.FIRST_LEVEL_RULES) ||
      isEqual(tab, LabelEnum.WHITE_LISTS)
    ) {
      createRq = `${LabelEnum.DATE_CREATED}: ${format(
        new Date(get(requestRuleMonitorById, "rule.createdAt", "") * 1000),
        FormatDateEnum.DD_MM_YY_HH_MM
      )}`;
    } else {
      createRq = `${LabelEnum.DATE_CREATED}: ${format(
        new Date(get(requestRuleById, PathsEnum.CREATED, "")),
        FormatDateEnum.DD_MM_YY_HH_MM_SS
      )}`;
    }

    setModalHeader({
      title: getModalHeaderTitle(tab, configType),
      secondaryText: createRq,
      tags: template.tags,
      buttonPrimary: canSelectRightButton(
        roleButtonsForWhiteList(
          tab,
          roleEnableButtonRight,
          roleEnableButtonLeft
        ),
        requestRuleById!
      )
        ? buttonPrimary
        : undefined,
      buttonSecondary: roleButtonsForWhiteList(
        tab,
        roleEnableButtonLeft,
        roleEnableButtonRight
      )
        ? buttonSecondary
        : undefined,
    });
  };

  const roleButtonsForWhiteList = (
    tab: string,
    originalRole: boolean,
    intendedRole: boolean
  ): boolean => {
    return tab === LabelEnum.WHITE_LISTS ? intendedRole : originalRole;
  };

  const getModalHeaderTitle = (tabName: string, configType: string): string => {
    const isWhiteListRule: boolean =
      tabName === LabelEnum.WHITE_LISTS ||
      configType === ConfigurationTypeEnum.WHITELIST;
    const subType =
      TEXT_TRANSFORM_SUBTYPE_COLUMNS[
        get(requestRuleById, FieldModelEnum.LIST_NAME, "")
      ];

    const path: string = isWhiteListRule
      ? subType === SubTypeEnum.COUNTRY
        ? PathsEnum.COUNTRY
        : subType === SubTypeEnum.COMMERCE
        ? camelCase(PathsEnum.MERCHANT_NAME)
        : PathsEnum.RULE_NAME
      : PathsEnum.RULE_NAME;
    const headerTitle = get(requestRuleById, path, "");

    return isArray(headerTitle) ? headerTitle.join(", ") : headerTitle;
  };

  const getInformation = (
    property: string,
    requestRuleById?:
      | SearchRequestRuleByIdResponse
      | SearchRuleByIdResponse
      | GetRuleByIdResponse
  ): string => {
    const itemValue = get(lastRuleInfoUpdate, `${property}`);
    switch (property) {
      case FieldModelEnum.UPDATED_AT:
        const ruleSubtype = get(requestRuleById, FieldModelEnum.SUBTYPE, "");
        let updatedValue: string = "";
        if (itemValue) {
          updatedValue =
            (tabName === LabelEnum.WHITE_LISTS &&
              ruleSubtype === FieldModelEnum.COUNTRY) ||
            (tabName === LabelEnum.WHITE_LISTS &&
              ruleSubtype === ValueEnum.COMMERCE) ||
            tabName === LabelEnum.REJECTED_APPLICATIONS
              ? convertTimestampToDateTime(itemValue)
              : convertTimestampToDate(itemValue);
          return updatedValue;
        }
        return updatedValue;
      case FieldModelEnum.ENTITY_NAME:
        const isRejectedWhitelistWithoutHierarchy: boolean =
          isEqual(tabName, LabelEnum.REJECTED_APPLICATIONS) &&
          isEqual(
            get(requestRuleById, PathsEnum.CONFIGURATION_TYPE, ""),
            FieldsRule.WHITE_LIST
          ) &&
          isEqual(
            get(requestRuleById, FieldModelEnum.LIST_NAME, ""),
            ValueEnum.WITHOUT_HIERARCHY
          );

        return TEXT_TRANSFORM_MODAL[
          isRejectedWhitelistWithoutHierarchy
            ? get(requestRuleById, FieldModelEnum.LIST_NAME, "")
            : get(requestRuleById, FieldModelEnum.ENTITY_NAME, "")
        ];
      case FieldModelEnum.TYPE_MERCHANT:
        return TEXT_TRANSFORM_MODAL[
          get(requestRuleById, FieldModelEnum.LIST_NAME, "")
        ];
      case FieldModelEnum.MERCHANT_NAME:
        return get(requestRuleById, FieldModelEnum.MERCHANT_NAME, "");
      case FieldModelEnum.USER_NAME:
        return get(requestRuleById, FieldModelEnum.USER_NAME, "");
      case FieldModelEnum.COUNTRY:
        return defaultTo(
          get(requestRuleById, FieldModelEnum.COUNTRY),
          []
        ).toString();
      case FieldModelEnum.SERVICE:
        return TEXT_TRANSFORM_MODAL[
          get(requestRuleById, FieldModelEnum.SERVICE, "")
        ];
      case FieldModelEnum.SUBTYPE:
        let subtype: string = get(requestRuleById, FieldModelEnum.SUBTYPE, "");
        if (isEqual(tabName, LabelEnum.WHITE_LISTS))
          subtype = buildListType(
            get(requestRuleById, FieldModelEnum.LIST_NAME, "")
          );

        return subtype === FieldModelEnum.COUNTRY ? Labels.COUNTRY : subtype;
      case FieldModelEnum.ACTION:
        return TEXT_TRANSFORM_MODAL[
          get(requestRuleById, PathsEnum.GENERAL_ACTION_DYNAMO, "")
        ];

      case FieldModelEnum.PROVIDER:
        const provider: string = get(
          requestRuleById,
          validateProviderPath(
            get(requestRuleById, PathsEnum.GENERAL_ACTION_DYNAMO)
          ),
          ""
        );
        const searchProvider: Providers = get(
          configurations,
          "providers",
          []
        ).filter((providers: Providers) =>
          providers.name.includes(provider)
        )[0];

        return get(searchProvider, ValueProvidersEnum.TITLE, "");
      default:
        let finalValue: string = itemValue;

        if (isEmpty(itemValue))
          finalValue = get(requestRuleById, `${property}`, "");

        if (isEmpty(finalValue))
          finalValue = get(requestRuleById, `rule.${property}`, "");

        return finalValue;
    }
  };

  const getApproveBtnActions = (
    entityName: string,
    subType: string
  ): (IAppAction | ThunkAction<void, IAppState, undefined, IAppAction>)[] => {
    dispatch(setMerchantHierarchy(defaultMerchantHierarchy));
    if (
      isEqual(subType, SubTypeEnum.COMMERCE) &&
      (isEqual(upperCase(entityName), GroupEnum.OWNER) ||
        isEqual(upperCase(entityName), GroupEnum.CUSTOMER))
    ) {
      return [
        getHierarchyNodes({
          entityName: get(requestRuleById, "entityName", "").toUpperCase(),
          nodeId: get(requestRuleById, "nodeId", ""),
          rootId: get(requestRuleById, "rootId", ""),
        }),
        setOpenConfirmModal(true),
      ];
    } else {
      props.setAlertSuccessMsg(ConfirmModalEnum.SUCCESS_APPROVED_RULE);
      props.setAlertErrorMsg(ConfirmModalEnum.ERROR_APPROVED_RULE);
      return [
        updateRuleRequest(
          approveRuleRequest,
          defaultTo(requestRuleById?.id, ""),
          TabIndexEnum.PENDING_REVIEW_INDEX
        ),
      ];
    }
  };

  const buildListType = (nameList: string): string => {
    return TEXT_TRANSFORM_COLUMNS[nameList];
  };

  const buildActionButton = (
    tabName: string,
    status: string,
    rightButtonEnable: boolean,
    leftButtonEnable: boolean,
    configType: string
  ): ModalActionButtons => {
    const subtype: string = get(requestRuleById, "subType", "");
    const entityName: string = get(requestRuleById, "entityName", "");
    const name: string = get(requestRuleById, PathsEnum.RULE_NAME, "");

    const _updateRequestRule: UpdateRuleRequest = {
      status: StatusEnum.APPROVED,
      rule: get(requestRuleById, "rule", {
        generalAction: "",
        code: "",
        name: "",
        message: "",
      }),
    };

    switch (tabName) {
      case LabelEnum.PENDING_REVIEW:
        switch (configType) {
          case ConfigurationTypeEnum.WHITELIST:
            props.setAlertErrorMsg(ConfirmModalEnum.ERROR_MSG_ALERT);
            props.setAlertSuccessMsg(
              ConfirmModalEnum.SUCCESS_MSG_APPROVED_ALERT
            );
            return buildButtonProperties(
              LabelEnum.REJECT_RULE,
              setOpenModalRejected(true),
              LabelEnum.APPROVE_RULE,
              [
                updateRuleRequest(
                  _updateRequestRule,
                  defaultTo(requestRuleById?.id, ""),
                  TabIndexEnum.PENDING_REVIEW_INDEX
                ),
              ]
            );
        }
        return buildButtonProperties(
          LabelEnum.REJECT_RULE,
          setOpenModalRejected(true),
          LabelEnum.APPROVE_RULE,
          getApproveBtnActions(entityName, subtype)
        );
      case LabelEnum.FIRST_LEVEL_RULES:
        if (isEqual(status, StatusEnum.DISABLE)) {
          return {
            buttonPrimary: {
              text: LabelEnum.DELETE_RULE_BUTTON,
              onClick: () => {
                props.setAlertErrorMsg(
                  ConfirmModalEnum.REJECT_DELETED_RULE_MSG_ALERT
                );
                props.setAlertSuccessMsg(
                  ConfirmModalEnum.SUCCESS_DELETED_RULE_MSG_ALERT
                );
                const id: string = get(requestRuleById, "id", "");

                if (!isEmpty(id)) dispatch(deleteRule(id));
              },
              isDisabled: !leftButtonEnable,
            },
            buttonSecondary: {
              text: TitleSection.RULE_ENABLE,
              onClick: () => {
                history.push(
                  `${EndpointsPathsEnum.RULES_EDIT}/${get(
                    requestRuleById,
                    "id",
                    ""
                  )}/${get(requestRuleById, PathsEnum.RULE_STATUS, "")}`
                );
              },
            },
          };
        }

        return {
          buttonPrimary: {
            text: LabelEnum.BUTTON_DISABLE_RULE,
            onClick: () => {
              if (
                subtype === SubTypeEnum.COUNTRY ||
                entityName.toUpperCase() === GroupEnum.BRANCH ||
                isEmpty(entityName)
              ) {
                dispatch(setOpenModalRejected(true));
              } else {
                dispatch(
                  getMerchantHierarchy(
                    get(requestRuleMonitorById, "merchantName")
                  )
                );
                dispatch(setOpenConfirmModal(true));
              }
            },
          },
          buttonSecondary: {
            text: LabelEnum.BUTTON_EDIT_RULE,
            onClick: () => {
              dispatch(setSearchRequestRuleById(undefined));
              dispatch(setSearchRuleById(undefined));
              dispatch(setMerchantHierarchy(undefined));

              history.push(
                `${EndpointsPathsEnum.RULES_EDIT}/${get(
                  requestRuleMonitorById,
                  "rule.id"
                )}/${get(requestRuleMonitorById, "rule.rule.status", "")}`
              );
            },
          },
        };
      case LabelEnum.REJECTED_APPLICATIONS:
        return {
          buttonPrimary: isEqual(configType, ConfigurationTypeEnum.WHITELIST)
            ? undefined
            : {
                isDisabled: !leftButtonEnable,
                text: LabelEnum.DELETE_RULE_BUTTON,
                onClick: () => {
                  props.setAlertErrorMsg(
                    ConfirmModalEnum.REJECT_DELETED_RULE_MSG_ALERT
                  );
                  props.setAlertSuccessMsg(
                    ConfirmModalEnum.SUCCESS_DELETED_RULE_MSG_ALERT
                  );
                  const id: string = get(requestRuleById, "id", "");

                  if (!isEmpty(id)) {
                    const update_request: UpdateRuleRequest = {
                      status: StatusEnum.DELETED,
                      observationInfo: [
                        {
                          reason: "",
                          eventName: StatusEnum.DELETED,
                          updatedAt: Date.now(),
                          userName: defaultTo(
                            localStorage.getItem("email"),
                            ""
                          ),
                        },
                      ],
                    };

                    dispatch(deleteRuleRequest(update_request, id));
                  }
                },
              },
          buttonSecondary: {
            text: isEqual(configType, ConfigurationTypeEnum.WHITELIST)
              ? LabelEnum.BUTTON_EDIT_LIST
              : LabelEnum.BUTTON_EDIT_RULE,
            onClick: () => {
              isEqual(configType, ConfigurationTypeEnum.WHITELIST)
                ? handleEditionWhiteList()
                : history.push(
                    `${EndpointsPathsEnum.RULES_EDIT}/${get(
                      requestRuleById,
                      "id",
                      ""
                    )}/${get(requestRuleById, "status", "")}`
                  );
            },
          },
        };
      case LabelEnum.WHITE_LISTS:
        if (status === StatusEnum.DISABLE) {
          return {
            buttonSecondary: {
              text: TitleSection.ENABLE,
              onClick: () => {
                dispatch(setSearchRequestRuleById(undefined));
                dispatch(setSearchRuleById(undefined));
                dispatch(setMerchantHierarchy(undefined));
                dispatch(setMerchantInformation(undefined));

                history.push(
                  `${EndpointsPathsEnum.WHITE_LIST}/${get(
                    requestRuleById,
                    "id",
                    ""
                  )}/${RequestTypeEnum.DEACTIVATION}`
                );
              },
            },
          };
        }
        return getWhiteListEnableButtons(
          rightButtonEnable,
          leftButtonEnable,
          name
        );
      default:
        return {
          buttonSecondary: {
            text: LabelEnum.BUTTON_EDIT_RULE,
            onClick: () => {},
          },
        };
    }
  };

  const handleEditionWhiteList = (): void => {
    history.push(
      `${EndpointsPathsEnum.WHITE_LIST}/${get(requestRuleById, "id", "")}/${
        RequestTypeEnum.EDITION
      }`
    );
  };

  const showDisableModal = (name: string) => {
    if (isEqual(name, SubTypeEnum.RULE_NAME_WHITELIST_WITH_HIERARCHY)) {
      dispatch(getMerchantHierarchy(get(requestRuleById, "merchantName", "")));
      dispatch(setOpenConfirmModal(true));
    } else {
      dispatch(setOpenModalRejected(true));
    }
  };

  const getWhiteListEnableButtons = (
    rightButtonEnable: boolean,
    leftButtonEnable: boolean,
    name: string
  ): ModalActionButtons => {
    // Admin
    if (leftButtonEnable && rightButtonEnable) {
      return {
        buttonPrimary: {
          text: TitleSection.RULE_DISABLE,
          onClick: () => showDisableModal(name),
        },
        buttonSecondary: {
          text: TitleSection.EDIT_LIST,
          onClick: () => {
            dispatch(setSearchRequestRuleById(undefined));
            dispatch(setSearchRuleById(undefined));
            dispatch(setMerchantHierarchy(undefined));
            dispatch(setMerchantInformation(undefined));

            history.push(
              `${EndpointsPathsEnum.WHITE_LIST}/${get(
                requestRuleById,
                "id",
                ""
              )}/${RequestTypeEnum.EDITION}`
            );
          },
        },
      };
    }
    //Operator
    if (!leftButtonEnable && rightButtonEnable) {
      return {
        buttonSecondary: {
          text: TitleSection.EDIT_LIST,
          onClick: () => {
            window.location.href = `${EndpointsPathsEnum.WHITE_LIST}/${get(
              requestRuleById,
              "id",
              ""
            )}/${RequestTypeEnum.EDITION}`;
          },
        },
      };
    }
    //Approver
    if (leftButtonEnable && !rightButtonEnable) {
      return {
        buttonPrimary: {
          text: TitleSection.RULE_DISABLE,
          onClick: () => showDisableModal(name),
        },
      };
    }

    //Read only
    return { buttonPrimary: undefined, buttonSecondary: undefined };
  };

  const buildButtonProperties = (
    primaryLabel: string,
    primaryAction:
      | IAppAction
      | ThunkAction<void, IAppState, undefined, IAppAction>,
    secondaryLabel: string,
    secondaryAction: (
      | IAppAction
      | ThunkAction<void, IAppState, undefined, IAppAction>
    )[]
  ): object => ({
    buttonPrimary: {
      text: primaryLabel,
      onClick: () => {
        dispatch(primaryAction);
      },
    },
    buttonSecondary: {
      text: secondaryLabel,
      onClick: () => {
        secondaryAction.forEach(
          (
            action:
              | IAppAction
              | ThunkAction<void, IAppState, undefined, IAppAction>
          ) => dispatch(action)
        );
      },
    },
  });

  const canSelectRightButton = (
    enableRole: boolean,
    rule: SearchRequestRuleByIdResponse
  ) => {
    if (get(rule, PathsEnum.STATUS, "") === StatusEnum.PENDING) {
      const requestType = get(rule, PathsEnum.REQUEST_TYPE, "");
      const isFixed = get(rule, PathsEnum.IS_FIXED, false);

      return (AllRequestType.includes(requestType) || isFixed) && enableRole;
    }

    return enableRole;
  };
  const onChangeModal = (): void => {
    dispatch(setRequestRuleById(undefined));
    dispatch(setOpenModalInfo(false));
  };

  const getAllGeneralConditions = (
    requestRuleById: SearchRequestRuleByIdResponse | SearchRuleByIdResponse
  ) => {
    const conditionsKeys: string[] = Object.keys(
      defaultTo(requestRuleById.rule, [])
    ).filter((ruleKey: string) =>
      includes(get(requestRuleById, `rule.${ruleKey}`, ""), "|")
    );
    const generalConditions: Row[][] = [];
    const specialConditions: Row[][] = [];

    conditionsKeys.forEach((key: string) => {
      const keyTitle: string = defaultTo(
        map(filter(configurations.conditions, ["field", key]), "title")[0],
        ""
      );
      const keyValues: string[] = get(requestRuleById, `rule.${key}`, "").split(
        "|"
      );
      const condition: Operators | undefined = defaultTo(
        configurations.operators,
        []
      ).find((operator: Operators) => operator.value === keyValues[0]);
      const conditionValue: string | undefined = defaultTo(
        keyValues[1],
        undefined
      );

      if (!isUndefined(conditionValue) && !isUndefined(condition)) {
        const [title, value]: [string, string] = transformGeneralData(
          condition,
          conditionValue,
          key
        );
        const isSecureService: boolean = isEqual(
          keyTitle,
          TitleSection.SECURE_SERVICE
        );

        if (isEqual(keyTitle, TitleSection.PROCESSOR_CODE)) {
          specialConditions.push([
            { text: keyTitle },
            { text: title },
            { text: value },
          ]);
        } else {
          generalConditions.push([
            { text: keyTitle },
            { text: title },
            {
              text: isSecureService ? getSecureServiceName(value, "\n") : value,
            },
          ]);
        }
      }
    });

    if (
      specialConditions.length > 0 &&
      get(requestRuleById, PathsEnum.HAS_SPECIAL_COND, false)
    ) {
      setRuleSpecialConditionsInfo({
        headers: GENERAL_CONDITIONS_HEADERS,
        title: LabelEnum.SPECIAL_CONDITION_RULES,
        rows: specialConditions,
        isVisible: true,
      });
    } else {
      setRuleSpecialConditionsInfo({
        ...ruleSpecialConditionsInfo,
        isVisible: false,
      });
    }

    setRuleGeneralConditionsInfo({
      headers: GENERAL_CONDITIONS_HEADERS,
      title: LabelEnum.GENERAL_CONDITION_RULES,
      rows: generalConditions,
      isVisible: true,
    });

    const provider = get(requestRuleById, `rule.${FieldModelEnum.PARTNER}`, "");
    const ignore3DS = get(
      requestRuleById,
      `rule.${FieldModelEnum.IGNORE_3DS}`,
      false
    );
    const rule: IElementModalBody[] = [
      {
        label: LabelEnum.GENERAL_ADDITIONAL_TAKE_RISK,
        secondaryText: "",
        text: ignore3DS
          ? TakeRiskStatusEnum.ENABLED
          : TakeRiskStatusEnum.DISABLED,
      },
    ];

    setRuleAdditionalInfo({
      title: LabelEnum.GENERAL_ADDITIONAL_CONFIG,
      body: [...rule],
      isVisible: provider === FieldModelEnum.SECURE_3DS,
    });

    if (
      isEqual(
        get(requestRuleById, PathsEnum.RULE_KIND, ""),
        Values.RULES_KIND_SPEED
      ) &&
      !get(requestRuleById, PathsEnum.HAS_SPECIAL_COND, false) &&
      (isEqual(configType, ConfigurationTypeEnum.FIRST_LEVEL) ||
        !isEqual(configType, FieldsRule.WHITE_LIST)) &&
      (isEqual(tabName, LabelEnum.PENDING_REVIEW) ||
        isEqual(tabName, LabelEnum.FIRST_LEVEL_RULES) ||
        isEqual(tabName, LabelEnum.REJECTED_APPLICATIONS))
    ) {
      const speedAction: string =
        parseInt(get(requestRuleById, "rule.maxAmount")) > 0
          ? ValueEnum.SUM
          : ValueEnum.COUNT;

      setSpeedConditions({
        headers: getSpeedConditionsHeaders(speedAction),
        title: LabelEnum.SPEED_CONDITION_RULES,
        rows: getSpeedConditionsRows(requestRuleById, speedAction),
        isVisible: true,
      });
    } else {
      setSpeedConditions({ ...speedConditions, isVisible: false });
    }
  };

  const getWhiteListCondition = (
    rule:
      | SearchRequestRuleByIdResponse
      | SearchRuleByIdResponse
      | GetRuleByIdResponse
  ): ICardTableColumnValues[] => {
    const conditionList: object = pick(rule.rule, Object.values(ConditionEnum));
    const response: ICardTableColumnValues[] = [];

    Object.keys(conditionList).forEach((condition) => {
      const arr: string[] = get(conditionList, condition, "").split("|");
      const operator: string = arr[0];
      const value: string[] = arr[1].split(",");

      if (condition == ConditionEnum.DATE) {
        let dateValue = join(
          split(arr[1], ",").map(convertTimestampToDateS),
          " - "
        );
        if (isEqual(value[1], "")) {
          dateValue = join(split(arr[1], ",").map(convertTimestampToDateS), "");
        }

        response.push({
          tag: ConditionLabels[condition],
          operator: ConditionOperators[operator.toLowerCase()],
          value: dateValue,
        });
      }
      if (condition == ConditionEnum.SCHEDULE) {
        let scheduleValue = join(
          split(arr[1], ",").map(convertTimestampToTime),
          " - "
        );
        if (isEqual(value[1], "")) {
          scheduleValue = join(
            split(arr[1], ",").map(convertTimestampToTime),
            ""
          );
        }

        response.push({
          tag: ConditionLabels[condition],
          operator: ConditionOperators[operator.toLowerCase()],
          value: scheduleValue,
        });
      } else {
        response.push({
          tag: ConditionLabels[condition],
          operator: ConditionOperators[operator.toLowerCase()],
          value: value.toString(),
        });
      }
    });

    return response;
  };

  return {
    onChangeModal,
    header: modalHeader!,
    isOpenModal,
    data: {
      speedConditions,
      generalInfo: ruleGeneralInfo!,
      generalConditions: ruleGeneralConditionsInfo,
      specialConditions: ruleSpecialConditionsInfo,
      generalCountryMerchantInfo: ruleGeneralCountryMerchantInfo!,
      configInfo: {
        firstSection: ruleConfigInfo!,
        secondSection: configSecondSection!,
      },
      generalAdditionalInfo: ruleAdditionalInfo!,
    },
    isLoading: isLoadingOneRule,
    isLoadingRequest: isLoadingRequest,
  };
};
