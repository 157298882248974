import React from "react";
import { DeferredInformationData } from "./DeferredInformation.data";
import { Button, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import { AppRoutes } from "../../../../shared/infrastructure/routes";
import { setBasicMerchantInformation } from "../../../../shared/utils/local-storage/basic-merchant-information";
import { TabIndexNameEnum } from "../../../../shared/enums/TabIndexEnum";

export interface DeferredInformationProps {
  showMoreDeferred: boolean;
}

export const DeferredInformation: React.FC<DeferredInformationProps> = ({
  showMoreDeferred,
}) => {
  const location = useLocation();
  const handleNavigate = () => {
    setBasicMerchantInformation({
      redirectPath: location.pathname.concat(location.search),
      redirectTab: TabIndexNameEnum.DEFERRED,
    });

    window.location.href = AppRoutes.DEFERRED;
  };
  return (
    <Grid container justifyContent="flex-end">
      <Grid paddingRight={"1rem"} sx={{ marginBottom: "1rem" }} item>
        {showMoreDeferred && (
          <Button
            className={"buttonLink"}
            color="primary"
            onClick={handleNavigate}
          >
            {DeferredInformationData.MoreDeferredMessage}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
