import { useEffect, useState } from "react";
import { MerchantNodeData } from "../../../../types/search_merchant_response";
import { defaultTo, find, get, isEmpty } from "lodash";
import { redirectPageUtils } from "../../../shared/utils/redirectPage.utils";
import { REDIRECT_PATH } from "../../../shared/constants/content_slides";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { RootState } from "../../../store/store";
import { SelectNewItemList } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectNewItem/SelectNewItem.interfaces";
import { setOwnerDetail } from "../../../store/actions/owner.actions";
import { CreateNodeResponse } from "../../../../types/create_node_response";
import { environment } from "../../../environments/environment";
import { EntityNameEnum } from "../../../shared/enums/EntityNameEnum";
import {
  ModalStatusTextCustomer,
  ModalStatusTextCustomers,
} from "../../../shared/enums/DashboardEnum";
import { IModalStatusText } from "../CustomerHeader.interfaces";
import { StatusEnum } from "../../../shared/enums/StatusEnum";
import { changeStatusNodes } from "../../../store/thunks/customer/searchMerchants.thunk";
import {
  verifyIsPending,
  verifyStatus,
} from "../../../shared/utils/verifyStatus.utils";
import { resetCustomerList } from "../../../store/actions/customer.action";
import { resetBranchList } from "../../../store/actions/branch.action";

export const useCustomerHeader = () => {
  const {
    customerList,
    ownerDetail,
    listOwner,
    isLoadingMerchants,
    isLoadingFlow,
  } = useAppSelector((state: RootState) => ({
    ...state.customerReducer,
    ...state.ownerReducer,
  }));

  const dispatch = useAppDispatch();
  const [pathCustomer, setPathCustomer] = useState<string>("");
  const [entityName] = useState<EntityNameEnum>(EntityNameEnum.CUSTOMER);
  const [defaultValue, setDefaultValue] = useState({} as SelectNewItemList);
  const [modalStatusText, setModalStatusText] = useState<IModalStatusText>({
    description: "",
    title: "",
  });

  const kushkiOwner = environment.kushkiOwner;

  const listOwners: SelectNewItemList[] = get(listOwner, "data", []).map(
    (node: MerchantNodeData) => {
      return {
        label: get(node, "name", ""),
        value: get(node, "node_id", ""),
      };
    }
  );
  const handleSearchNode = (selected: any) => {
    window.history.replaceState(null, "", `/clients/${selected.value}`);
    dispatch(
      setOwnerDetail(
        find(listOwner.data, ["node_id", selected.value]) as CreateNodeResponse
      )
    );
  };

  const getOwnerNodeId = () => location.pathname.split("/")[2];

  useEffect(() => {
    const node_id = getOwnerNodeId();
    const owner = find(listOwner.data, ["node_id", node_id]);
    const defaultOwner = find(listOwners, [
      "label",
      kushkiOwner,
    ]) as SelectNewItemList;

    if (!isEmpty(listOwners)) {
      if (isEmpty(node_id) || !owner) {
        if (defaultOwner) {
          setDefaultValue(defaultOwner);
          dispatch(
            setOwnerDetail(
              find(listOwner.data, ["name", kushkiOwner]) as CreateNodeResponse
            )
          );
        } else {
          const firstOwner = get(listOwner, "data[0]", {});

          dispatch(setOwnerDetail(firstOwner as CreateNodeResponse));
          setDefaultValue({
            label: get(firstOwner, "name", ""),
            value: get(firstOwner, "node_id", ""),
          });
        }
      }
      if (!isEmpty(node_id) && owner) {
        dispatch(setOwnerDetail(owner as CreateNodeResponse));
        setDefaultValue({
          label: get(owner, "name", ""),
          value: get(owner, "node_id", ""),
        });
      }
    }
  }, [location.pathname, listOwner]);

  const [isOpenSlider, setIsOpenSlider] = useState<boolean>(false);

  const closeSlider = () => {
    setIsOpenSlider(false);
  };

  const createCustomer = () => {
    isEmpty(get(customerList, "data", []))
      ? setIsOpenSlider(true)
      : redirectPageUtils(
          `${REDIRECT_PATH}?nodeId=${defaultTo(
            getOwnerNodeId(),
            ""
          )}&hideSideBar=true`
        );
  };

  const changeTab = (currentTab: number) => {
    currentTab === 0
      ? dispatch(resetCustomerList())
      : dispatch(resetBranchList());
  };

  useEffect(() => {
    if (get(ownerDetail, "path", false)) {
      setPathCustomer(
        defaultTo(ownerDetail.nodeId, get(ownerDetail, "node_id"))
      );
    }
  }, [ownerDetail]);

  const checkModalStatusText = (name?: string, total?: number) => {
    if (name) {
      setModalStatusText({
        description: ModalStatusTextCustomer.DESCRIPTION(name),
        title: ModalStatusTextCustomer.TITLE,
      });
    } else {
      setModalStatusText({
        description: ModalStatusTextCustomers.DESCRIPTION,
        title: ModalStatusTextCustomers.TITLE(total!),
      });
    }
  };

  const handleChangeStatus = (
    itemsSelected: MerchantNodeData[],
    itemsStatus: StatusEnum
  ) => {
    const verifyValidStatus =
      verifyStatus(itemsSelected, itemsStatus) &&
      !verifyIsPending(itemsSelected);

    if (verifyValidStatus) {
      dispatch(
        changeStatusNodes({
          entityName: EntityNameEnum.CUSTOMER,
          itemsSelected: itemsSelected,
          status: itemsStatus,
        })
      );
      dispatch(resetCustomerList());
    }
  };

  return {
    changeTab,
    closeSlider,
    createCustomer,
    customerList,
    defaultValue,
    entityName,
    handleChangeStatus,
    handleSearchNode,
    isLoadingFlow,
    isLoadingMerchants,
    isOpenSlider,
    listOwners,
    modalStatusDashboard: {
      checkModalStatusText,
      modalStatusText,
    },
    pathCustomer,
    queryParam: getOwnerNodeId(),
  };
};
