import React from "react";
import { Box, Divider, Grid, Skeleton, Typography } from "@mui/material";
import { headerSkeletonStyles as styles } from "./HeaderSkeleton.styles";
import { branchContainerStyles } from "../../containers/BranchContainer/BranchContainer.styles";
import { displayUtils } from "../../shared/utils/DisplayUtils";

interface IHeaderSkeletonProps {
  isBranchContainer?: boolean;
}

const HeaderSkeleton: React.FC<IHeaderSkeletonProps> = ({
  isBranchContainer,
}: IHeaderSkeletonProps) => {
  return (
    <>
      <Box display={displayUtils(!isBranchContainer!)}>
        <Grid container alignItems="center">
          <Grid item marginBottom={3}>
            <Skeleton
              variant="rectangular"
              sx={styles.returnBtn}
              animation="wave"
            />
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          sx={branchContainerStyles.gridTitle}
        >
          <Grid item>
            <Skeleton
              variant="rectangular"
              sx={styles.branchName}
              animation="wave"
            />
          </Grid>
          <Grid item xs={1} sx={branchContainerStyles.gridCountry}>
            <Skeleton
              variant="rectangular"
              sx={styles.statusChip}
              animation="wave"
            />
          </Grid>
          <Grid item sx={branchContainerStyles.gridCountry}>
            <Skeleton
              variant="rectangular"
              sx={styles.dateText}
              animation="wave"
            />
          </Grid>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={branchContainerStyles.divider}
          />
          <Grid item sx={branchContainerStyles.gridCountry}>
            <Skeleton
              variant="rectangular"
              sx={styles.dateText}
              animation="wave"
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item container xs={12} md={"auto"} alignItems="center">
            <Grid item sx={branchContainerStyles.gridText}>
              <Skeleton
                variant="rectangular"
                sx={styles.countryText}
                animation="wave"
              />
            </Grid>
            <Grid item sx={branchContainerStyles.gridText}>
              <Box>
                <Skeleton
                  variant="rectangular"
                  sx={styles.countryIcon}
                  animation="wave"
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={"auto"} alignItems="center">
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={branchContainerStyles.divider}
            />
            <Grid item sx={branchContainerStyles.gridId}>
              <Skeleton
                variant="rectangular"
                sx={styles.customerIdText}
                animation="wave"
              />
            </Grid>
            <Grid item sx={branchContainerStyles.gridText}>
              <Skeleton
                variant="rectangular"
                sx={styles.customerIdValue}
                animation="wave"
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} md={"auto"} alignItems="center">
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={branchContainerStyles.divider}
            />
            <Grid item sx={branchContainerStyles.gridId}>
              <Skeleton
                variant="rectangular"
                sx={styles.ownerIdText}
                animation="wave"
              />
            </Grid>
            <Grid item sx={branchContainerStyles.gridText}>
              <Skeleton
                variant="rectangular"
                sx={styles.ownerValue}
                animation="wave"
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={branchContainerStyles.divider} />
      </Box>
      <Box>
        <Box
          display={displayUtils(isBranchContainer!)}
          sx={styles.wrapperSelect}
        >
          <Box>
            <Skeleton
              variant="rectangular"
              sx={styles.selectInput}
              animation="wave"
            />
          </Box>
          <Divider sx={styles.divider} />
        </Box>
        <Box display={displayUtils(isBranchContainer!)} sx={styles.wrapperTabs}>
          <Box>
            <Skeleton variant="rectangular" sx={styles.tabs} animation="wave" />
          </Box>
        </Box>
        <Box sx={styles.wrapperCreate}>
          <Typography variant="h5">
            <Skeleton variant="text" sx={styles.text} animation="wave" />
          </Typography>
          <Box>
            <Skeleton variant="rectangular" sx={styles.button} />
          </Box>
        </Box>
        <Box sx={styles.wrapperFilters}>
          <Box sx={styles.wrapperSearch}>
            <Skeleton
              variant="rectangular"
              sx={styles.input}
              animation="wave"
            />
            <Skeleton variant="rectangular" sx={styles.input} />
          </Box>
          <Skeleton
            variant="circular"
            sx={styles.circleButton}
            animation="wave"
          />
        </Box>
      </Box>
    </>
  );
};

export default HeaderSkeleton;
