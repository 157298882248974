import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const customerWebhooksStyles = createNamedStyles({
  boxMain: {
    backgroundColor: "neutral.grey3",
    borderRadius: "8px",
    margin: "8px",
    padding: "24px",
    width: "-webkit-fill-available",
  },
  boxEdit: {
    backgroundColor: "neutral.grey2",
    borderRadius: "8px",
    padding: "16px 8px",
    width: "100%",
  },
  boxAddWebhook: {
    display: "flex",
  },
  box: {
    marginRight: "auto",
  },
  buttonTitle: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    height: "auto",
    lineHeight: "140%",
    padding: 0,
  },
  titleMain: {
    color: "text.primary",
    fontWeight: "500",
    fontSize: "20px",
  },
  title: {
    color: "text.dark",
    marginBottom: "16px",
  },
  divider: {
    marginTop: "15px",
    borderWidth: ".1px",
    borderStyle: "solid",
    marginBottom: "5px",
  },
});
