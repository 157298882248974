export const tipoEmpresaMX: Array<string> = [
  "Sociedad en Comandita Simple",
  "Sociedad en Comandita por Acciones",
  "Sociedades de Responsabilidad Limitada",
  "Sociedad Anómima",
  "Sociedad Cooperativa",
  "Sociedades Cooperativas de Ahorro y Préstamos",
  "Sociedad en Nombre Colectivo",
  "Sociedad Anónima de Capital Variable",
  "Sociedad por Acciones Simplificada",
  "Sociedad Anónima Promotora de Inversión (SAPI)",
  "Sociedad Anónima Promotora de Inversión de Capital Variable (SAPI de CV)",
  "Sociedad Anónima Promotora de Inversión Bursátil",
  "Sociedad Anónima Bursátil (SAB)",
  "Sociedad Civil (SC) y Accionistas (AC)",
  "Las Sociedades Financieras de Objeto Múltiple, Entidad No Regulada (SOFOMES)",
  "Bancos",
];
