import { useState } from "react";

export interface ICountRowsValidation {
  expandedDetailRows: boolean;
  handleExpandedDetailRows: () => void;
}

export const useCountRowsValidation = (): ICountRowsValidation => {
  const [expandedDetailRows, setExpandedDetailRows] = useState<boolean>(false);

  const handleExpandedDetailRows = (): void => {
    setExpandedDetailRows(!expandedDetailRows);
  };

  return {
    expandedDetailRows,
    handleExpandedDetailRows,
  };
};
