import React, { PropsWithChildren, useState } from "react";
import { Grid, TextField } from "@mui/material";
import { DEFAULT_ITEM_BY_TAG } from "../../../../../../shared/infrastructure/enums/ItemByTagEnum";
import { FabButtonsContainer } from "../../../FabButtonsContainer/FabButtonsContainer";
import { IGenericLabelConditionSectionListProps } from "../../../../../../shared/infrastructure/interfaces/IGenericLabelConditionSectionListProps";
import { get } from "lodash";

export const GenericLabelConditionSectionList: React.FC<
  IGenericLabelConditionSectionListProps
> = (props: PropsWithChildren<IGenericLabelConditionSectionListProps>) => {
  const [isEditField, setIsEditField] = useState<boolean>(false);
  const [isValidFiledTextField, setIsValidFiledTextField] =
    useState<boolean>(true);

  return (
    <Grid item xs={12} container direction={"row"} spacing={1.2}>
      <Grid item xs={4.1} sx={{ mb: 1 }}>
        <TextField
          defaultValue={props.value}
          disabled={!isEditField}
          onBlur={(
            event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            const targetValue: string = get(event, "target.value", "");
            if (DEFAULT_ITEM_BY_TAG[props.tag].pattern.test(targetValue)) {
              props.handleEdit(get(event, "target.value", ""));
              setIsEditField(false);
              setIsValidFiledTextField(true);
              return;
            }
            setIsEditField(true);
            setIsValidFiledTextField(false);
          }}
          placeholder={DEFAULT_ITEM_BY_TAG[props.tag].placeholder}
          helperText={
            !isValidFiledTextField &&
            DEFAULT_ITEM_BY_TAG[props.tag].errorMessage
          }
          variant="outlined"
          fullWidth
          autoFocus={true}
        />
      </Grid>
      <FabButtonsContainer
        handleEditFabButton={() => {
          setIsEditField(true);
        }}
        handleDeleteFabButton={props.handleDelete}
      />
    </Grid>
  );
};
