import React from "react";
import Grid from "@material-ui/core/Grid";
import { BRANDS } from "../../shared/infrastructure/constants/CardBrands";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(() => ({
  cardLogos: {
    height: "32px",
    paddingRight: "8px",
  },
}));

export const BrandCell: React.FC<{
  processorName: string;
  country: string;
}> = (props: { processorName: string; country: string }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3} direction="row">
      {!BRANDS[props.processorName] ? (
        <a> No disponible </a>
      ) : (
        BRANDS[props.processorName].map((brandImage: string, index: number) => (
          <img
            src={brandImage}
            key={index}
            alt={brandImage}
            className={classes.cardLogos}
          />
        ))
      )}
    </Grid>
  );
};
