import * as React from "react";
import IconArrows from "../../../../assets/icons/IconArrows";
import { FC, useEffect, useState } from "react";
import { ISortArrowProps } from "./IconSortArrow.interfaces";
import { IconButton } from "@mui/material";

const IconSortArrow: FC<ISortArrowProps> = (props: ISortArrowProps) => {
  const [order, setOrder] = useState<string>("");
  const [colorArrowTop, setColorArrowTop] = useState<string>("#B4B9BE");
  const [colorArrowBottom, setColorArrowBottom] = useState<string>("#B4B9BE");
  const [count, setCount] = useState(0);

  const tittleTooltipValue = (valueNumber: number) => {
    switch (valueNumber) {
      case 1:
        setOrder("asc");
        setColorArrowTop("#B4B9BE");
        setColorArrowBottom("#FFFFF");
        break;
      case 2:
        setOrder("desc");
        setColorArrowTop("#FFFFF");
        setColorArrowBottom("#B4B9BE");
        break;
      default:
        setOrder("");
        setColorArrowTop("#B4B9BE");
        setColorArrowBottom("#B4B9BE");
        if (count >= 3) setCount(0);
        break;
    }
  };

  const changeOrder = () => {
    setCount(count + 1);
  };

  useEffect(() => {
    if (count >= 3) setCount(0);
    tittleTooltipValue(count);
    props.onClick(props.valueOrder, order, count);
  }, [count, order]);

  return (
    <IconButton sx={{ display: "inline-block" }} onClick={changeOrder}>
      <IconArrows
        colorArrowTop={colorArrowTop}
        colorArrowBottom={colorArrowBottom}
      />
    </IconButton>
  );
};

export default IconSortArrow;
