export enum CurrencyEnum {
  COP = "COP",
  USD = "USD",
  PEN = "PEN",
  UF = "UF",
  MXN = "MXN",
  BRL = "BRL",
  PAB = "PAB",
  CLP = "CLP",
  GTQ = "GTQ",
  CRC = "CRC",
  NIO = "NIO",
  HNL = "HNL",
}
