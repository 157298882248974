import { IUseConditionItemComponentState } from "../../../../../shared/infrastructure/interfaces/IUseConditionItemComponentState";
import {
  Conditions,
  Operators,
} from "../../../../../../types/configuration_response";
import { IConditionItemComponentProps } from "../../../../../shared/infrastructure/interfaces/IConditionItemComponentProps";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { defaultTo, get, isEmpty } from "lodash";
import { RuleEnum } from "../../../../../shared/infrastructure/enums/RuleEnum";
import { TextFieldContainer } from "../../TextFieldContainer/TextFieldContainer";
import { IUseConditionItemComponentStateProps } from "../../../../../shared/infrastructure/interfaces/IUseConditionItemComponentStateProps";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";
import { useAppSelector } from "../../../../../store/hooks/store-hook";
import { getBasicMerchantInformation } from "../../../../../shared/utils/localStorage";
import { maskCard } from "../../../../../utils/utilsFile";
import { EntityNameEnum } from "../../../../../shared/infrastructure/enums/NodeInfoEnum";
import { CategoryData } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectSearchBy/SelectSearchBy.interfaces";
import { OptionsSelectSearchBy } from "@kushki/connect-ui";

export const useConditionItemComponentState = (
  props: PropsWithChildren<IUseConditionItemComponentStateProps>
): IUseConditionItemComponentState => {
  const { configList, currencyByCountry, nodeInfoMerchant, getRuleById } =
    useAppSelector((store) => store.layout);

  const basePathProps: IConditionItemComponentProps = get(
    props,
    "conditionsItemComponentProps"
  );

  const { errors, control, watch, setValue, register } = useFormContext();
  const [selectedItems, setSelectedItems] = useState<ICategory[]>([]);
  const [maskedCardList, setMaskedCardList] = useState<string[]>([]);
  const [availableConditions, setAvailableConditions] = useState<Conditions[]>(
    []
  );

  useEffect(() => {
    const businessConditions: Conditions[] = get(
      configList,
      "businessConditions",
      []
    );
    if (
      !get(nodeInfoMerchant, "hasChilds", false) &&
      get(nodeInfoMerchant, "entityName", "") === EntityNameEnum.CUSTOMER
    ) {
      setAvailableConditions(
        businessConditions.filter(
          (condition: Conditions) => condition.field !== RuleEnum.CREDENTIAL
        )
      );
      return;
    }
    setAvailableConditions(businessConditions);
  }, [configList, nodeInfoMerchant]);

  const rule: string = defaultTo(
    watch(`condition.${basePathProps.index}.label`),
    ""
  );
  const operator: string = defaultTo(
    watch(`condition.${basePathProps.index}.operator`),
    get(basePathProps, "item.condition", "")
  );

  const getMaskedCardList = (maskedCardList: string[]): string[] => {
    return maskedCardList
      .map((el: string) => el.replace(/ /g, ""))
      .map((el: string) => maskCard(el));
  };

  const saveInArray = (): string[] => {
    switch (rule) {
      case RuleEnum.MASKED_CARD:
        return getMaskedCardList(maskedCardList);
      case RuleEnum.BANK:
        return selectedItems.map((x: ICategory) => x.label);
      default:
        return selectedItems.map((x: ICategory) => x.value);
    }
  };
  const value: string[] = saveInArray();

  const merchantInformation = getBasicMerchantInformation();

  useEffect(() => {
    register(`condition.${basePathProps.index}.value`);
  }, [basePathProps.index]);

  useEffect(() => {
    setValue(`condition.${basePathProps.index}.value`, value);
  }, [value]);

  const getOperatorsByConditions = (): Operators[] => {
    const action: Conditions | undefined = get(
      configList,
      "businessConditions",
      []
    ).find((conditions: Conditions) => {
      return conditions.field === rule;
    });
    const newInfo: Operators[] = [];

    if (action !== undefined) {
      get(action, "operators", "")
        .split(",")
        .forEach((itemOperator: string) => {
          const operator = get(configList, "operators", []).find(
            (item: Operators) => item.value === itemOperator
          );
          if (operator) newInfo.push(operator);
        });
    }

    if (!isEmpty(newInfo)) {
      return newInfo;
    }
    return [];
  };

  const isFilledTypeOperator = (): boolean => {
    return operator === "all"
      ? false
      : (operator != "all" && operator != "") ||
          rule === RuleEnum.COUNTRY ||
          !isEmpty(get(basePathProps, "item.operator", ""));
  };

  let defaultValueSelect: OptionsSelectSearchBy;

  if (!isEmpty(getRuleById)) {
    const idSelectedCredential: string = get(
      getRuleById,
      "rule.rule.transactionCredential",
      ""
    ).split("|")[1];

    props.conditionsItemComponentProps.customerCredentials?.map(
      (credential: CategoryData) =>
        credential.items.map((item) => {
          if (item.secondaryText === idSelectedCredential) {
            defaultValueSelect = {
              categoryName: credential.categoryName,
              categoryId: credential.categoryId,
              ...item,
            };
          }
        })
    );
  }

  const retrieveTextFieldsByRuleAndOperator = (): JSX.Element => (
    <TextFieldContainer
      operatorValue={operator}
      className={props.classes.subTitleConditions}
      mainProps={props}
      setSelectedItems={setSelectedItems}
      setMaskedCardList={setMaskedCardList}
      rule={rule}
      currencies={currencyByCountry}
      generalTransactionsType={get(configList, "transactionType", [])}
      country={[merchantInformation.country]}
      entity={get(nodeInfoMerchant, "entityName", "")}
      defaultCustomerCredential={defaultValueSelect}
    />
  );

  return {
    control,
    errors,
    selectConditions: availableConditions,
    getOperatorsByConditions,
    isFilledTypeOperator,
    retrieveTextFieldsByRuleAndOperator,
  };
};
