import React from "react";
import { defaultTo, get, includes } from "lodash";
import { IDialogMessageProps } from "./DialogMessage.interfaces";
import { useStyles } from "./DialogMessage.styles";
import { ModalContent } from "@kushki/connect-ui";
import { DIALOG_MESSAGE } from "../../../shared/constants/labels/dialog_message_labels";
import {
  Box,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import {
  countriesWithPartialRefund,
  validationMXProcessorKushki,
} from "../../../shared/utils/transaction_list_utils";

export const DialogMessage: React.FC<IDialogMessageProps> = (
  props: IDialogMessageProps
) => {
  const classes = useStyles(props);

  const valor_trx: number = get(
    props,
    "transaction._source.approved_transaction_amount"
  );
  const valor: number =
    props.type === "refund" ? valor_trx - valor_trx : valor_trx;
  const type_schema: string =
    props.type === "void" ? "devolución" : "reembolso";
  const country: string = get(props, "transaction._source.country", "");

  const getCurrency = () => {
    const convertedCurrency = get(
      props,
      "transaction._source.converted_currency"
    );

    if (convertedCurrency) return convertedCurrency;

    return get(props, "transaction._source.currency_code", "USD");
  };
  const currency: string = getCurrency();

  return (
    <ModalContent
      titleText={DIALOG_MESSAGE.DIALOG_TITLE_TEXT(
        props.type === "void" ? "la" : "el",
        type_schema
      )}
      descriptionText={""}
      isOpen={props.openDialog}
      onClose={props.onCancel}
      buttonAction={() => props.handleOnDelete(props.type, props.transaction)}
      buttonText={DIALOG_MESSAGE.BUTTON_SAVE(type_schema)}
      buttonSecondaryText={DIALOG_MESSAGE.BUTTON_CANCEL}
      buttonType={DIALOG_MESSAGE.PRIMARY}
      type={DIALOG_MESSAGE.DEFAULT}
    >
      <Box>
        <Typography>
          Al continuar, enviarás la solicitud de {type_schema} al equipo de
          Kushki. {<br />}
          Este proceso puede durar hasta 45 días.
        </Typography>
        <Typography style={{ fontSize: "14px", marginTop: "25px" }}>
          Monto inicial de la transacción: {defaultTo(valor_trx, 0).toFixed(2)}{" "}
          {currency}
        </Typography>
        {!includes(countriesWithPartialRefund, country) && (
          <Typography>¿Qué tipo de reembolso deseas solicitar?</Typography>
        )}

        {!validationMXProcessorKushki(props.transaction!) && (
          <Box>
            <RadioGroup
              row
              name="Devolucion"
              value={props.typeRefund}
              onChange={props.handleTypeRefund}
            >
              <FormControlLabel
                value={"total"}
                control={<Radio color="primary" />}
                label="Total"
              />
              <FormControlLabel
                value={"partial"}
                control={<Radio color="primary" />}
                label="Parcial"
              />
            </RadioGroup>
          </Box>
        )}
        {props.typeRefund === "partial" && (
          <Box>
            <Typography
              hidden={!includes(countriesWithPartialRefund, country)}
              className={classes.styleText}
            >
              Monto Disponible{" "}
              {props.type === "void"
                ? valor_trx
                : defaultTo(valor, 0).toFixed(2)}{" "}
              {currency}
            </Typography>
            <TextField
              label={`Monto de ${type_schema} a solicitar`}
              variant="outlined"
              defaultValue={props.value}
              error={props.errorAmount}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{currency}</InputAdornment>
                ),
              }}
              helperText={
                props.errorAmount
                  ? "El valor del monto supero lo permitido a devolver"
                  : ""
              }
              onChange={props.handleChangeValue}
              disabled={
                !includes(
                  countriesWithPartialRefund,
                  get(props, "transaction._source.country", "")
                ) || validationMXProcessorKushki(props.transaction!)
              }
            />
          </Box>
        )}
      </Box>
    </ModalContent>
  );
};
