export enum AuthRuleFormFields {
  REQUEST_TYPE_CREATE = "CREATE",
  STATUS_SENT = "sent",
  STATUS_PENDING = "PENDING",
  THREE_DS = "3DS",
  OTP = "OTP",
  PROVIDER_CYBERSOURCE = "cybersource",
  PROVIDER_KUSHKI = "kushki",
  KIND_STRICT = "strict",
}
