import React from "react";
import { RatesConfigDynamo } from "../../../../types/rates_config_dynamo";
import { Grid } from "@mui/material";
import { ratesInfoStyles as style } from "../RatesInfo.styles";
import { RatesConditions } from "./RatesConditions/RatesConditions";
import { RatesRanges } from "./RatesRanges/RatesRanges";
import { CountriesEnum } from "../../../shared/enums/CountriesEnum";
import { MerchantBillingInfo } from "../../../../types/merchant_billing_info";

export interface RatesCollapsePropsInterface {
  country: CountriesEnum;
  rate: RatesConfigDynamo;
  merchantInfo?: MerchantBillingInfo;
}

export const RatesCollapse: React.FC<RatesCollapsePropsInterface> = (
  props: RatesCollapsePropsInterface
) => {
  const { rate } = props;

  return (
    <Grid container spacing={3} data-testid={"collapse"}>
      <Grid item sm={12} md={6}>
        <RatesConditions rate={rate} />
      </Grid>
      <Grid item sm={12} md={6} sx={style.collapseContainer}>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <RatesRanges {...props} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
