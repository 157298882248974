import { PaymentMethodEnum } from "../enums/PaymentMethodEnum";
import { Button } from "../../components/ModalDetailInfo/ModalDetailInfoHeader/ModalDetailInfoHeader.interfaces";
import { IModalDetailInfo } from "../../components/ModalDetailInfo/ModalDetailInfo.interfaces";
import { TransactionStatusEnum } from "../enums/TransactionStatusEnum";
import { TransactionTypeEnum } from "../enums/TransactionTypeEnum";
import { isEmpty } from "lodash";
import { Chargeback } from "../../../types/chargeback";

export const modalHeaderButtons = (
  _props: IModalDetailInfo,
  trx: Chargeback
): {
  primaryButton: Button | undefined;
  secondaryButton: Button | undefined;
} => {
  if (isEmpty(trx.ticket_number))
    return {
      primaryButton: undefined,
      secondaryButton: undefined,
    };

  const button = {
    [TransactionTypeEnum.SALE]: {
      [PaymentMethodEnum.CARD]: {},
      [PaymentMethodEnum.TRANSFER]: {},
      [PaymentMethodEnum.CASH]: {},
    },
    [TransactionTypeEnum.CAPTURE]: {
      [PaymentMethodEnum.CARD]: {},
    },
    [TransactionTypeEnum.PREAUTHORIZATION]: {
      [PaymentMethodEnum.CARD]: {},
    },
    [TransactionTypeEnum.DEFERRED]: {
      [PaymentMethodEnum.CARD]: {},
    },
    [TransactionTypeEnum.VOID]: {
      [PaymentMethodEnum.CARD]: {},
    },
    [TransactionTypeEnum.VOID_PARTIAL]: {
      [PaymentMethodEnum.CARD]: {},
    },
    [TransactionTypeEnum.REFUND_TOTAL]: {
      [PaymentMethodEnum.CARD]: {},
    },
    [TransactionTypeEnum.REFUND_PARTIAL]: {
      [PaymentMethodEnum.CARD]: {},
    },
  };

  if (trx.transaction_status === TransactionStatusEnum.APPROVAL) {
    return button[trx.transaction_type!][trx.payment_method!];
  }

  return {
    primaryButton: undefined,
    secondaryButton: undefined,
  };
};
