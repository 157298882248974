import React from "react";
import { HeaderPros } from "./Header.interfaces";
import { Box, Typography } from "@mui/material";
import { headerStyles as style } from "./Header.styles";

export const Header = (props: HeaderPros) => {
  return (
    <Box sx={style.boxContent}>
      <Typography variant="h2" sx={style.label}>
        {props.label}
      </Typography>
      <Box sx={style.boxChild}>{props.component}</Box>
    </Box>
  );
};
