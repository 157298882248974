import { useLocation, useNavigate } from "react-router-dom";

export const useAppNavigate = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return {
    navigate: <S = unknown>(route: string, state?: S) =>
      navigate(`${route}${location.search}`, { state }),
  };
};
