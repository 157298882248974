import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { IProcessBranchFile } from "../../interfaces/processBranchFile/processBranchFile.interfaces";

export const initialState: IProcessBranchFile = {};

export const processBranchSlice = createSlice({
  initialState,
  name: "processBranch",
  reducers: {
    setProcessBranchFile: (
      state: Draft<IProcessBranchFile>,
      { payload }: PayloadAction<IProcessBranchFile>
    ) => {
      state.branchList = payload.branchList;
      state.messageValidation = payload.messageValidation;
      state.summary = payload.summary;
      state.validateBranchFileStatus = payload.validateBranchFileStatus;
    },
  },
});

export default processBranchSlice.reducer;
