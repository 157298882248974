import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

const SortIcon: FC<SvgIconProps> = ({ sx, ...rest }: SvgIconProps) => (
  <SvgIcon
    width="24"
    height="24"
    fill={"none"}
    viewBox="0 0 24 24"
    sx={{ p: "1px", ...sx }}
    {...rest}
  >
    <path
      d="M7 14L12 19L17 14"
      stroke="#023365"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 10L12 5L7 10"
      stroke="#023365"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default SortIcon;
