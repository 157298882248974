import { IStyles } from "../../../shared/interfaces/Styles.interfaces";

const CellTagStyles: IStyles = {
  skeleton: {
    display: "inline-flex",
    width: "64px",
    height: "21px",
  },
};

export { CellTagStyles };
