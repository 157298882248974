import { Category } from "../BasicStateGeneral/CatalogBasicData";

export const companyTypeHN: Array<string> = [
  "Comerciante Individual",
  "Sociedades de Responsabilidad Limitada",
  "Sociedad Anónima",
  "Sociedad Colectiva",
  "Sociedad en Comandita Simple",
  "Sociedad Cooperativa",
];

export const TypeOfTaxIdListHN: Array<Category> = [
  { id: "0", label: "RNP" },
  { id: "1", label: "RTN" },
];

export enum TypeOfTaxIdEnumHN {
  RNP = "0",
  RTN = "1",
}
