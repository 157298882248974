import { Box, Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { footerStyles } from "./FooterOptions.styles";
import { IFooterOptions } from "./FooterOptions.interfaces";
import {
  DEFAULT_IS_EDIT_LABEL,
  DEFAULT_IS_HIDDEN_LABEL,
} from "../../shared/constants/labels/FooterOptionsConstants";

const FooterOptions: FC<IFooterOptions> = ({
  label = {
    date: "",
    editDetail: DEFAULT_IS_EDIT_LABEL,
    isHidden: DEFAULT_IS_HIDDEN_LABEL,
    text: "",
  },
}: IFooterOptions) => {
  return (
    <Box sx={footerStyles.footer}>
      <Grid container spacing={0}>
        <Grid item xs={12} lg={6}>
          <Box sx={footerStyles.buttonContainer}></Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box sx={footerStyles.labelContainer}>
            <Box sx={footerStyles.labelBox}>
              <Typography sx={footerStyles.label}>{label.text}</Typography>
            </Box>
            <Box sx={footerStyles.labelBox}>
              <Typography sx={footerStyles.label}>{label.date}</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FooterOptions;
