/**
 * DynamoDB's Index enum
 */
export enum StatusNameEnum {
  Aprobada = "Aprobada",
  Rechazada = "Rechazada",
}
export enum StatusValueEnum {
  Aprobada = "approval",
  Rechazada = "rejected",
  PENDING = "pending",
  REJECT = "rejected",
  SENT = "sent",
  IN_PROCESS = "in_process",
}
export const STATUS_VALUES = [
  StatusValueEnum.PENDING,
  StatusValueEnum.REJECT,
  StatusValueEnum.SENT,
  StatusValueEnum.IN_PROCESS,
];
