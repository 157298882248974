export enum CountryEnum {
  generic = "generic",
  mexico = "Mexico",
  ecuador = "Ecuador",
  brasil = "Brazil",
  usa = "EEUU",
  chile = "Chile",
  panama = "Panama",
  peru = "Peru",
  el_salvador = "ElSalvador",
  honduras = "Honduras",
  guatemala = "Guatemala",
  nicaragua = "Nicaragua",
  costa_rica = "CostaRica",
  colombia = "Colombia",
  AFGANISTAN = "Afganistan",
  ALBANIA = "Albania",
  ALEMANIA = "Alemania",
  ANDORRA = "Andorra",
  ANGOLA = "Angola",
  ANGUILA = "Anguila",
  ANTARTIDA = "Antartida",
  ANTIGUA_Y_BARBUDA = "Antigua y Barbuda",
  ARABIASAUDITA = "Arabia Saudita",
  ARGELIA = "Argelia",
  ARGENTINA = "Argentina",
  ARMENIA = "Armenia",
  ARUBA = "Aruba",
  AUSTRALIA = "Australia",
  AUSTRIA = "Austria",
  AZERBAIYAN = "Azerbaiyan",
  BELGICA = "Belgica",
  BAHAMAS = "Bahamas",
  BAHREIN = "Bahrein",
  BANGLADESH = "Bangladesh",
  BARBADOS = "Barbados",
  BELICE = "Belice",
  BENIN = "Benin",
  BHUTAN = "Bhutan",
  BIELORRUSIA = "Bielorrusia",
  BIRMANIA = "Birmania",
  BOLIVIA = "Bolivia",
  BOSNIA_Y_HERZEGOVIA = "Bosnia y Herzegovina",
  BOTSUANA = "Botsuana",
  BRUNEI = "Brunei",
  BULGARIA = "Bulgaria",
  BURKINA_FASO = "Burkina Faso",
  BURUNDI = "Burundi",
  CABO_VERDE = "Cabo Verde",
  CAMBOYA = "Camboya",
  CAMERUN = "Camerun",
  CANADA = "Canada",
  CHAD = "Chad",
  CHINA = "China",
  CHIPRE = "Chipre",
  CIUDAD_DEL_VATICANO = "Ciudad del Vaticano",
  COMORAS = "Comoras",
  REPUBLICA_DEL_CONGO = "Republica del Congo",
  REPUBLICA_DEMOCRATICA_DEL_CONGO = "Republica Democratica del Congo",
  COREA_DEL_NORTE = "Corea del Norte",
  COREA_DEL_SUR = "Corea del Sur",
  COSTA_DE_MARFIL = "Costa de Marfil",
  CROACIA = "Croacia",
  CUBA = "Cuba",
  CURAZAO = "Curazao",
  DINAMARCA = "Dinamarca",
  DOMINICA = "Dominica",
  EGIPTO = "Egipto",
  EMIRATOS_ARABES_UNIDOS = "Emiratos Arabes Unidos",
  ERITREA = "Eritrea",
  ESLOVAQUIA = "Eslovaquia",
  ESLOVENIA = "Eslovenia",
  ESPANIA = "Espania",
  ESTONIA = "Estonia",
  ESTIOPIA = "Etiopia",
  FILIPINAS = "Filipinas",
  FINLANDIA = "Finlandia",
  FIYI = "Fiyi",
  FRANCIA = "Francia",
  GABON = "Gabon",
  GAMBIA = "Gambia",
  GEORGIA = "Georgia",
  GHANA = "Ghana",
  GIBRALTAR = "Gibraltar",
  GRANADA = "Granada",
  GRECIA = "Grecia",
  GROENLANDIA = "Groenlandia",
  GUADALUPE = "Guadalupe",
  GUAM = "Guam",
  GUAYANA_FRANCESA = "Guayana Francesa",
  GUERNSEY = "Guernsey",
  GUINEA = "Guinea",
  GUINEA_ECUATORIAL = "Guinea Ecuatorial",
  GUINEA_BISSAU = "Guinea-Bissau",
  GUYANA = "Guyana",
  HAITI = "Haiti",
  HONG_KONG = "Hong kong",
  HUNGRIA = "Hungria",
  INDIA = "India",
  INDONESIA = "Indonesia",
  IRAN = "Iran",
  IRAK = "Irak (+964)",
  IRLANDA = "Irlanda",
  ISLA_BOUVET = "Isla Bouvet",
  ISLA_DE_MAN = "Isla de Man",
  ISLA_DE_NAVIDAD = "Isla de Navidad",
  ISLA_NORFOLK = "Isla Norfolk",
  ISLANDIA = "Islandia (+354)",
  ISLAS_BERMUDAS = "Islas Bermudas ",
  ISLAS_CAIMAN = "Islas Caiman",
  ISLAS_COCOS = "Islas Cocos (Keeling)",
  ISLAS_COOK = "Islas Cook",
  ISLAS_DE_ALAND = "Islas de Aland",
  ISLAS_FEROE = "Islas Feroe",
  ISLAS_GEORGIAS_Y_SANDWICH = "Islas Georgias del Sur y Sandwich del Sur",
  ISLAS_HEARD_Y_MCDONALD = "Islas Heard y McDonald",
  ISLAS_MALDIVAS = "Islas Maldivas",
  ISLAS_MALVINAS = "Islas Malvinas",
  ISLAS_MARIANAS_DEL_NORTE = "Islas Marianas del Norte",
  ISLAS_MARSHALL = "Islas Marshall",
  ISLAS_PITCAIRN = "Islas Pitcairn",
  ISLAS_SALOMON = "Islas Salomon",
  ISLAS_TURCAS_Y_CAICOS = "Islas Turcas y Caicos",
  ISLAS_ULTRAMARINAS_MENORES = "Islas Ultramarinas Menores de Estados Unidos",
  ISLAS_VIRGENES_BRITANICAS = "Islas Virgenes Britaicas",
  ISLAS_VIRGENES_DE_LOS_ESTADOS_UNIDOS = "Islas Virgenes de los Estados Unidos",
  ISRAEL = "Israel",
  ITALIA = "Italia",
  JAMAICA = "Jamaica",
  JAPON = "Japon",
  JERSEY = "Jersey",
  JORDANIA = "Jordania",
  KAZAJISTAN = "Kazajistan",
  KENIA = "Kenia (+254)",
  KIRGUISTAN = "Kirguistan",
  KIRIBATI = "Kiribati",
  KUWAIT = "Kuwait",
  LIBANO = "Libano",
  LAOS = "Laos",
  LESOTO = "Lesoto",
  LETONIA = "Letonia",
  LIBERIA = "Liberia",
  LIBIA = "Libia",
  LIECHTENSTEIN = "Liechtenstein",
  LITUANIA = "Lituania",
  LUXEMBURGO = "Luxemburgo",
  MONACO = "Monaco",
  MACAO = "Macao",
  MACEDONIA = "Macedonia",
  MADAGASCAR = "Madagascar",
  MALASIA = "Malasia",
  MALAWI = "Malawi",
  MALI = "Mali",
  MALTA = "Malta",
  MARRUECOS = "Marruecos",
  MARTINICA = "Martinica",
  MAURICIO = "Mauricio",
  MAURITANIA = "Mauritania",
  MAYOTTE = "Mayotte",
  MICRONESIA = "Micronesia",
  MOLDAVIA = "Moldavia",
  MONGOLIA = "Mongolia",
  MONTENEGRO = "Montenegro",
  MONTSERRAT = "Montserrat",
  MOZAMBIQUE = "Mozambique",
  NAMIBIA = "Namibia",
  NAURU = "Nauru",
  NEPAL = "Nepal",
  NIGER = "Niger",
  NIGERIA = "Nigeria",
  NIUE = "Niue",
  NORUEGA = "Noruega",
  NUEVA_CALEDONIA = "Nueva Caledonia",
  NUEVA_ZELANDA = "Nueva Zelanda",
  OMAN = "Oman",
  PAISES_BAJOS = "Paises Bajos",
  PAKISTAN = "Pakistan",
  PALAU = "Palau",
  PALESTINA = "Palestina",
  PAPUA_NUEVA_GUINEA = "Papua Nueva Guinea",
  PARAGUAY = "Paraguay",
  POLINESIA_FRANCESA = "Polinesia Francesa",
  POLONIA = "Polonia",
  PORTUGAL = "Portugal",
  PUERTO_RICO = "Puerto Rico",
  QATAR = "Qatar",
  REINO_UNIDO = "Reino Unido",
  REPUBLICA_CENTROAFRICANA = "Republica Centroafricana",
  REPUBLICA_CHECA = "Republica Checa",
  REPUBLICA_DOMINICANA = "Republica Dominicana",
  REPUBLICA_DE_SUDAN_DEL_SUR = "Republica de Sudan del Sur",
  REUNION = "Reunion",
  RUANDA = "Ruanda",
  RUMANIA = "Rumania",
  RUSIA = "Rusia",
  SAHARA_OCCIDENTAL = "Sahara Occidental",
  SAMOA = "Samoa",
  SAMOA_AMERICA = "Samoa Americana",
  SAN_BARTOLOME = "San Bartolome",
  SAN_CRISTOBLA_Y_NIEVES = "San Cristobal y Nieves",
  SAN_MARINO = "San Marino",
  SAN_MARTIN = "San Martin",
  SAN_PEDRO_Y_MIQUELON = "San Pedro y Miquelon",
  SAN_VICENTE_Y_LAS_GRANADINAS = "San Vicente y las Granadinas",
  SANTA_ELENA = "Santa Elena",
  SANTA_LUCIA = "Santa Lucia",
  SANTO_TOME_Y_PRINCIPE = "Santo Tome y Principe",
  SENEGAL = "Senegal",
  SERBIA = "Serbia",
  SEYCHELLES = "Seychelles",
  SIERRA_LEONA = "Sierra Leona",
  SINGAPUR = "Singapur",
  SINT_MAARTEN = "Sint Maarten",
  SIRIA = "Siria",
  SOMALIA = "Somalia",
  SRI_LANKA = "Sri lanka",
  SUDAFRICA = "Sudafrica",
  SUDAN = "Sudan",
  SUECIA = "Suecia",
  SUIZA = "Suiza",
  SURINAM = "Surinam",
  SVALBARD_Y_JAN_MAYEN = "Svalbard y Jan Mayen",
  SWAZILANDIA = "Swazilandia",
  TAYIKISTAN = "Tayikistan",
  TAILANDIA = "Tailandia",
  TAIWAN = "Taiwan",
  TANZANIA = "Tanzania",
  TERRITORIO_BRITANICO_DEL_OCEANO_INDICO = "Territorio Britanico del Oce9ano Indico",
  TERRITORIOS_AUSTRALES_Y_ANTARTICAS_FRANCESES = "Territorios Australes y Antarticas Franceses",
  TIMOR_ORIENTAL = "Timor Oriental",
  TOGO = "Togo",
  TOKELAU = "Tokelau",
  TONGA = "Tonga",
  TRINIDAD_Y_TOBAGO = "Trinidad y Tobago",
  TUNEZ = "Tunez",
  TURKMENISTAN = "Turkmenistan",
  TURQUIA = "Turquia",
  TUVALU = "Tuvalu",
  UCRANIA = "Ucrania",
  UGANDA = "Uganda",
  URUGUAY = "Uruguay",
  UZBEKISTAN = "Uzbekistan",
  VANUATU = "Vanuatu",
  VENEZUELA = "Venezuela",
  VIETNAM = "Vietnam",
  WALLIS_Y_FUTUNA = "Wallis y Futuna",
  YEMEN = "Yemen",
  YIBUTI = "Yibuti",
  ZAMBIA = "Zambia",
  ZIMBABUE = "Zimbabue",
}

export enum CountryTitleEnum {
  mexico = "México",
  panama = "Panamá",
  brasil = "Brasil",
  peru = "Perú",
  costa_rica = "Costa Rica",
  nicaragua = "Nicaragua",
  guatemala = "Guatemala",
  el_salvador = "El Salvador",
  AFGANISTAN = "Afganist\u00E1n",
  ALBANIA = "Albania",
  ALEMANIA = "Alemania",
  ANDORRA = "Andorra",
  ANGOLA = "Angola",
  ANGUILA = "Anguila",
  ANTARTIDA = "Ant\u00E1rtida",
  ANTIGUA_Y_BARBUDA = "Antigua y Barbuda",
  ARABIASAUDITA = "Arabia Saudita",
  ARGELIA = "Argelia",
  ARGENTINA = "Argentina",
  ARMENIA = "Armenia",
  ARUBA = "Aruba",
  AUSTRALIA = "Australia",
  AUSTRIA = "Austria",
  AZERBAIYAN = "Azerbaiy\u00E1n",
  BELGICA = "B\u00E9lgica",
  BAHAMAS = "Bahamas",
  BAHREIN = "Bahrein",
  BANGLADESH = "Bangladesh",
  BARBADOS = "Barbados",
  BELICE = "Belice",
  BENIN = "Ben\u00EDn",
  BHUTAN = "Bhut\u00E1n",
  BIELORRUSIA = "Bielorrusia",
  BIRMANIA = "Birmania",
  BOLIVIA = "Bolivia",
  BOSNIA_Y_HERZEGOVIA = "Bosnia y Herzegovina",
  BOTSUANA = "Botsuana",
  BRUNEI = "Brun\u00E9i",
  BULGARIA = "Bulgaria",
  BURKINA_FASO = "Burkina Faso",
  BURUNDI = "Burundi",
  CABO_VERDE = "Cabo Verde",
  CAMBOYA = "Camboya",
  CAMERUN = "Camer\u00FAn",
  CANADA = "Canad\u00E1",
  CHAD = "Chad",
  CHINA = "China",
  CHIPRE = "Chipre",
  CIUDAD_DEL_VATICANO = "Ciudad del Vaticano",
  COMORAS = "Comoras",
  REPUBLICA_DEL_CONGO = "Rep\u00FAblica del Congo",
  REPUBLICA_DEMOCRATICA_DEL_CONGO = "Rep\u00FAblica Democr\u00E1tica del Congo",
  COREA_DEL_NORTE = "Corea del Norte",
  COREA_DEL_SUR = "Corea del Sur",
  COSTA_DE_MARFIL = "Costa de Marfil",
  CROACIA = "Croacia",
  CUBA = "Cuba",
  CURAZAO = "Curazao",
  DINAMARCA = "Dinamarca",
  DOMINICA = "Dominica",
  EGIPTO = "Egipto",
  EMIRATOS_ARABES_UNIDOS = "Emiratos \u00C1rabes Unidos",
  ERITREA = "Eritrea",
  ESLOVAQUIA = "Eslovaquia",
  ESLOVENIA = "Eslovenia",
  ESPANIA = "Espa\u00F1a",
  ESTONIA = "Estonia",
  ESTIOPIA = "Etiop\u00EDa",
  FILIPINAS = "Filipinas",
  FINLANDIA = "Finlandia",
  FIYI = "Fiyi",
  FRANCIA = "Francia",
  GABON = "Gab\u00F3n",
  GAMBIA = "Gambia",
  GEORGIA = "Georgia",
  GHANA = "Ghana",
  GIBRALTAR = "Gibraltar",
  GRANADA = "Granada",
  GRECIA = "Grecia",
  GROENLANDIA = "Groenlandia",
  GUADALUPE = "Guadalupe",
  GUAM = "Guam",
  GUAYANA_FRANCESA = "Guayana Francesa",
  GUERNSEY = "Guernsey",
  GUINEA = "Guinea",
  GUINEA_ECUATORIAL = "Guinea Ecuatorial",
  GUINEA_BISSAU = "Guinea-Bissau",
  GUYANA = "Guyana",
  HAITI = "Hait\u00ED",
  HONG_KONG = "Hong kong",
  HUNGRIA = "Hungr\u00EDa",
  INDIA = "India",
  INDONESIA = "Indonesia",
  IRAN = "Ir\u00E1n",
  IRAK = "Irak (+964)",
  IRLANDA = "Irlanda",
  ISLA_BOUVET = "Isla Bouvet",
  ISLA_DE_MAN = "Isla de Man",
  ISLA_DE_NAVIDAD = "Isla de Navidad",
  ISLA_NORFOLK = "Isla Norfolk",
  ISLANDIA = "Islandia (+354)",
  ISLAS_BERMUDAS = "Islas Bermudas ",
  ISLAS_CAIMAN = "Islas Caim\u00E1n",
  ISLAS_COCOS = "Islas Cocos (Keeling)",
  ISLAS_COOK = "Islas Cook",
  ISLAS_DE_ALAND = "Islas de \u00C5land",
  ISLAS_FEROE = "Islas Feroe",
  ISLAS_GEORGIAS_Y_SANDWICH = "Islas Georgias del Sur y Sandwich del Sur",
  ISLAS_HEARD_Y_MCDONALD = "Islas Heard y McDonald",
  ISLAS_MALDIVAS = "Islas Maldivas",
  ISLAS_MALVINAS = "Islas Malvinas",
  ISLAS_MARIANAS_DEL_NORTE = "Islas Marianas del Norte",
  ISLAS_MARSHALL = "Islas Marshall",
  ISLAS_PITCAIRN = "Islas Pitcairn",
  ISLAS_SALOMON = "Islas Salom\u00F3n",
  ISLAS_TURCAS_Y_CAICOS = "Islas Turcas y Caicos",
  ISLAS_ULTRAMARINAS_MENORES = "Islas Ultramarinas Menores de Estados Unidos",
  ISLAS_VIRGENES_BRITANICAS = "Islas V\u00EDrgenes Brit\u00E1nicas",
  ISLAS_VIRGENES_DE_LOS_ESTADOS_UNIDOS = "Islas V\u00EDrgenes de los Estados Unidos",
  ISRAEL = "Israel",
  ITALIA = "Italia",
  JAMAICA = "Jamaica",
  JAPON = "Jap\u00F3n",
  JERSEY = "Jersey",
  JORDANIA = "Jordania",
  KAZAJISTAN = "Kazajist\u00E1n",
  KENIA = "Kenia (+254)",
  KIRGUISTAN = "Kirguist\u00E1n",
  KIRIBATI = "Kiribati",
  KUWAIT = "Kuwait",
  LIBANO = "L\u00EDbano",
  LAOS = "Laos",
  LESOTO = "Lesoto",
  LETONIA = "Letonia",
  LIBERIA = "Liberia",
  LIBIA = "Libia",
  LIECHTENSTEIN = "Liechtenstein",
  LITUANIA = "Lituania",
  LUXEMBURGO = "Luxemburgo",
  MONACO = "M\u00F3naco",
  MACAO = "Macao",
  MACEDONIA = "Maced\u00F4nia",
  MADAGASCAR = "Madagascar",
  MALASIA = "Malasia",
  MALAWI = "Malawi",
  MALI = "Mali",
  MALTA = "Malta",
  MARRUECOS = "Marruecos",
  MARTINICA = "Martinica",
  MAURICIO = "Mauricio",
  MAURITANIA = "Mauritania",
  MAYOTTE = "Mayotte",
  MICRONESIA = "Micronesia",
  MOLDAVIA = "Moldavia",
  MONGOLIA = "Mongolia",
  MONTENEGRO = "Montenegro",
  MONTSERRAT = "Montserrat",
  MOZAMBIQUE = "Mozambique",
  NAMIBIA = "Namibia",
  NAURU = "Nauru",
  NEPAL = "Nepal",
  NIGER = "Niger",
  NIGERIA = "Nigeria",
  NIUE = "Niue",
  NORUEGA = "Noruega",
  NUEVA_CALEDONIA = "Nueva Caledonia",
  NUEVA_ZELANDA = "Nueva Zelanda",
  OMAN = "Om\u00E1n",
  PAISES_BAJOS = "Pa\u00EDses Bajos",
  PAKISTAN = "Pakist\u00E1n",
  PALAU = "Palau",
  PALESTINA = "Palestina",
  PAPUA_NUEVA_GUINEA = "Pap\u00FAa Nueva Guinea",
  PARAGUAY = "Paraguay",
  POLINESIA_FRANCESA = "Polinesia Francesa",
  POLONIA = "Polonia",
  PORTUGAL = "Portugal",
  PUERTO_RICO = "Puerto Rico",
  QATAR = "Qatar",
  REINO_UNIDO = "Reino Unido",
  REPUBLICA_CENTROAFRICANA = "Rep\u00FAblica Centroafricana",
  REPUBLICA_CHECA = "Rep\u00FAblica Checa",
  REPUBLICA_DOMINICANA = "Rep\u00FAblica Dominicana",
  REPUBLICA_DE_SUDAN_DEL_SUR = "Rep\u00FAblica de Sud\u00E1n del Sur",
  REUNION = "Reuni\u00F3n",
  RUANDA = "Ruanda",
  RUMANIA = "Ruman\u00EDa",
  RUSIA = "Rusia",
  SAHARA_OCCIDENTAL = "Sahara Occidental",
  SAMOA = "Samoa",
  SAMOA_AMERICA = "Samoa Americana",
  SAN_BARTOLOME = "San Bartolom\u00E9",
  SAN_CRISTOBLA_Y_NIEVES = "San Crist\u00F3bal y Nieves",
  SAN_MARINO = "San Marino",
  SAN_MARTIN = "San Mart\u00EDn",
  SAN_PEDRO_Y_MIQUELON = "San Pedro y Miquel\u00F3n",
  SAN_VICENTE_Y_LAS_GRANADINAS = "San Vicente y las Granadinas",
  SANTA_ELENA = "Santa Elena",
  SANTA_LUCIA = "Santa Luc\u00EDa",
  SANTO_TOME_Y_PRINCIPE = "Santo Tom\u00E9 y Pr\u00EDncipe",
  SENEGAL = "Senegal",
  SERBIA = "Serbia",
  SEYCHELLES = "Seychelles",
  SIERRA_LEONA = "Sierra Leona",
  SINGAPUR = "Singapur",
  SINT_MAARTEN = "Sint Maarten",
  SIRIA = "Siria",
  SOMALIA = "Somalia",
  SRI_LANKA = "Sri lanka",
  SUDAFRICA = "Sud\u00E1frica",
  SUDAN = "Sud\u00E1n",
  SUECIA = "Suecia",
  SUIZA = "Suiza",
  SURINAM = "Surin\u00E1m",
  SVALBARD_Y_JAN_MAYEN = "Svalbard y Jan Mayen",
  SWAZILANDIA = "Swazilandia",
  TAYIKISTAN = "Tayikist\u00E1n",
  TAILANDIA = "Tailandia",
  TAIWAN = "Taiw\u00E1n",
  TANZANIA = "Tanzania",
  TERRITORIO_BRITANICO_DEL_OCEANO_INDICO = "Territorio Brit\u00E1nico del Oc\u00E9ano \u00CDndico",
  TERRITORIOS_AUSTRALES_Y_ANTARTICAS_FRANCESES = "Territorios Australes y Ant\u00E1rticas Franceses",
  TIMOR_ORIENTAL = "Timor Oriental",
  TOGO = "Togo",
  TOKELAU = "Tokelau",
  TONGA = "Tonga",
  TRINIDAD_Y_TOBAGO = "Trinidad y Tobago",
  TUNEZ = "Tunez",
  TURKMENISTAN = "Turkmenist\u00E1n",
  TURQUIA = "Turqu\u00EDa",
  TUVALU = "Tuvalu",
  UCRANIA = "Ucrania",
  UGANDA = "Uganda",
  URUGUAY = "Uruguay",
  UZBEKISTAN = "Uzbekist\u00E1n",
  VANUATU = "Vanuatu",
  VENEZUELA = "Venezuela",
  VIETNAM = "Vietnam",
  WALLIS_Y_FUTUNA = "Wallis y Futuna",
  YEMEN = "Yemen",
  YIBUTI = "Yibuti",
  ZAMBIA = "Zambia",
  ZIMBABUE = "Zimbabue",
}
