import { IStyles } from "../../shared/interfaces/create_named_styles";

export const checkoutAlertStyles: IStyles = {
  alertContainer: {
    ".MuiSnackbar-root": {
      top: "70px",
      left: "55%",
      transform: "translateX(-55%)",
      ".MuiPaper-root": {
        height: "48px",
        width: "570px",
        padding: "0px 16px",
        boxShadow: "none",
        ".MuiAlert-icon": {
          padding: "12px 0",
          svg: {
            width: "20px",
            height: "20px",
          },
        },
        ".MuiAlert-message": {
          fontFamily: "IBM Plex Sans",
          fontSize: "16px",
          fontWeight: "400",
          padding: "12px 0",
        },
        ".MuiAlert-action": {
          marginRight: "0",
          paddingLeft: "0",
          button: {
            width: "20px",
            height: "20px",
          },
        },
      },
    },
  },
};

export const backgroundColor = (type: string): string => {
  if (type === "success") return "#0DC298";
  else if (type == "error") return "#EF4444";
  else return "";
};
