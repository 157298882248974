/**
 * Catalog Colombia
 */
import { Category } from "../infrastructure/constants/MerchantInformationConstant";
import { CatalogsEnum } from "../infrastructure/constants/catalogs-enum";

export const CatalogCo: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { value: "0", name: "Cédula" },
    { value: "1", name: "Pasaporte" },
    { value: "3", name: "NIT" },
  ],
  [CatalogsEnum.Provinces]: [
    { value: "AMA", name: "Amazonas" },
    { value: "ANT", name: "Antioquia" },
    { value: "ARA", name: "Arauca" },
    { value: "SAP", name: "San Andres y Providencia" },
    { value: "ATL", name: "Atlantico" },
    { value: "BOL", name: "Bolivar" },
    { value: "BOY", name: "Boyaca" },
    { value: "CAL", name: "Caldas" },
    { value: "CAQ", name: "Caqueta" },
    { value: "CAS", name: "Casanare" },
    { value: "CAU", name: "Cauca" },
    { value: "CES", name: "Cesar" },
    { value: "CHO", name: "Choco" },
    { value: "COR", name: "Cordoba" },
    { value: "CUN", name: "Cundinamarca" },
    { value: "GUA", name: "Guainia" },
    { value: "GUV", name: "Guaviare" },
    { value: "HUI", name: "Huila" },
    { value: "LAG", name: "La Guajira" },
    { value: "MAG", name: "Magdalena" },
    { value: "MET", name: "Meta" },
    { value: "NAR", name: "Narino" },
    { value: "NSA", name: "Norte de Santander" },
    { value: "PUT", name: "Putumayo" },
    { value: "QUI", name: "Quindio" },
    { value: "RIS", name: "Risaralda" },
    { value: "SAN", name: "Santander" },
    { value: "SUC", name: "Sucre" },
    { value: "TOL", name: "Tolima" },
    { value: "VAC", name: "Valle de Cauca" },
    { value: "VAU", name: "Vaupes" },
    { value: "VID", name: "Vichada" },
  ],
  [CatalogsEnum.Banks]: [
    { value: "000048", name: "Aliadas" },
    { value: "000086", name: "ASOCAJAS" },
    { value: "000005", name: "Bancafe" },
    { value: "000050", name: "Banco Agrario" },
    { value: "000037", name: "Banco Boston" },
    { value: "000032", name: "Banco Caja Social" },
    { value: "000067", name: "BANCO COMPARTIR" },
    { value: "000061", name: "BANCO COOMEVA" },
    { value: "000066", name: "BANCO COOPERATIVO COOPCENTRAL" },
    { value: "000052", name: "Banco de AVVILLAS" },
    { value: "000001", name: "Banco de Bogotá" },
    { value: "000000", name: "Banco de la Republica" },
    { value: "000023", name: "Banco de Occidente" },
    { value: "000555", name: "Banco de Prueba" },
    { value: "000998", name: "BANCO DE PRUEBAS" },
    { value: "000062", name: "Banco Falabella S.A." },
    { value: "000063", name: "Banco Finandina S.A." },
    { value: "000016", name: "Banco Gasparin" },
    { value: "000012", name: "Banco GNB Sudameris" },
    { value: "000999", name: "Banco Modula Facturador" },
    { value: "000064", name: "BANCO MULTIBANK" },
    { value: "000002", name: "Banco Popular" },
    { value: "000058", name: "Banco PROCREDIT Colombia" },
    { value: "000065", name: "Banco Santander de Neg Col SA" },
    { value: "000034", name: "Banco Superior" },
    { value: "000029", name: "Banco Tequendema" },
    { value: "000022", name: "Banco Union" },
    { value: "000031", name: "BANCOLDEX" },
    { value: "000007", name: "Bancolombia" },
    { value: "000013", name: "BBVA" },
    { value: "000042", name: "BNP PARIBAS" },
    { value: "000888", name: "CHEQUES" },
    { value: "000009", name: "Citibank" },
    { value: "000057", name: "Colmena" },
    { value: "000019", name: "Colpatria" },
    { value: "000370", name: "COLTEFINANCIERA" },
    { value: "000121", name: "COMPANIA FIN JURISCOOP" },
    { value: "000083", name: "COMPENSAR" },
    { value: "000055", name: "Conavi" },
    { value: "000292", name: "CONFIAR" },
    { value: "000810", name: "COOMEVA" },
    { value: "000283", name: "Coop. Financiera de Antioquia" },
    { value: "000076", name: "COOPCENTRAL" },
    { value: "000090", name: "Corficolombiana" },
    { value: "000006", name: "Corpbanca" },
    { value: "000289", name: "COTRAFA COOPERATIVA FINANCIERA" },
    { value: "000051", name: "Davivienda" },
    { value: "000550", name: "DECEVAL S.A." },
    { value: "000089", name: "ENLACE OPERATIVO" },
    { value: "000087", name: "FEDECAJAS" },
    { value: "000296", name: "Financiera Juriscoop" },
    { value: "000054", name: "Granahorrar" },
    { value: "000050", name: "GRANBANCO" },
    { value: "000014", name: "Helm Bank S.A." },
    { value: "000010", name: "HSBC" },
    { value: "000060", name: "Inversora Pichincha S.A." },
    { value: "000041", name: "JPMORGAN CORPORACION FINANCIERA" },
    { value: "000036", name: "MEGABANCO" },
    { value: "000685", name: "MINHACIENDA REGALIAS" },
    { value: "000507", name: "Nequi" },
    { value: "000201", name: "prueba creación" },
    { value: "000101", name: "PSE" },
    { value: "000008", name: "Scotian" },
    { value: "000342", name: "SERFINANSA" },
    { value: "000088", name: "SIMPLE S.A." },
    { value: "000502", name: "SKANDIA" },
    { value: "000024", name: "Standard Chartered Colombia" },
    { value: "000683", name: "Tesoro NAL" },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { value: "0", name: "Corriente" },
    { value: "1", name: "Ahorros" },
  ],
  [CatalogsEnum.Currency]: [{ value: "COP", name: "COP" }],
  [CatalogsEnum.MerchantTypes]: [
    { value: "01", name: "Natural" },
    { value: "02", name: "Jurídica" },
  ],
};
