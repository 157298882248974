import { makeStyles } from "@material-ui/core/styles";

export const selectComponentStyle = makeStyles({
  textFieldSize: {
    width: "125px",
  },
  box: {
    marginTop: 30,
  },
  mainGrid: {
    padding: "8px",
  },
});
