import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { MerchantData } from "../../../../types/merchant-data";
import { MinAmountDynamo } from "../../../../types/min_amount_dynamo";
import { ConfigIdEnum } from "../../../shared/constants/initial_state_legal_details";
import { prefixes } from "../../../shared/constants/prefixes";
import {
  HierarchyNodeConfigInterfaces,
  HierarchyNodeConfigMassiveInterfaces,
} from "../../../shared/interfaces/hierarchyNodeConfig.interfaces";
import { defaultTo, get, isEmpty, set } from "lodash";
import { HierarchyNodeInfoRequest } from "../../../../types/hierarchy_node_info_request";
import { AxiosResponse } from "axios";

export interface saveMerchantInterface {
  country: string;
  publicMerchantId: string;
  taxId: string;
  objectToSave: MerchantData;
  configuration: string;
  nodeId: string;
  isTaxIdChange: boolean;
  isNewUser: boolean;
  status: string;
  updatedBy: string;
  updatedAt: number;
}
export const updateMerchant = createAsyncThunk<MerchantData, MerchantData>(
  "basicDataForm/updateMerchant",
  async (objectUpdate) => {
    const response = await axios.patch<MerchantData>(
      API_ROUTES.UPDATE_MERCHANT_NODE,
      objectUpdate
    );

    return response.data;
  }
);
export const saveBasicDataMerchant = createAsyncThunk<
  boolean,
  saveMerchantInterface
>(
  "basicDataForm/saveBasicData",
  async (data: saveMerchantInterface, thunkAPI) => {
    const publicMerchant = data.publicMerchantId;
    const defaultValueForGracePeriod = 3;
    const minAmountDefault = {
      amount: 0,
      gracePeriod: defaultValueForGracePeriod,
      type: "notCharge",
    };
    let minAmountResponse: AxiosResponse<MinAmountDynamo> = <
      AxiosResponse<MinAmountDynamo>
    >{};

    const dataSave: MerchantData = {
      ...data.objectToSave,
      companyType: defaultTo(data.objectToSave.companyType, ""),
      constitutionDate: defaultTo(data.objectToSave.constitutionDate, 0),
      economicActivityId: defaultTo(data.objectToSave.economicActivityId, ""),
      industryType: defaultTo(data.objectToSave.industryType, ""),
      mcc: defaultTo(data.objectToSave.mcc, ""),
      naturalPerson: {
        lastname: defaultTo(data.objectToSave.naturalPerson?.lastname, ""),
        name: defaultTo(data.objectToSave.naturalPerson?.name, ""),
      },
      socialReason: defaultTo(data.objectToSave.socialReason, ""),
      webSite: defaultTo(data.objectToSave.webSite, ""),
    };

    if (data.taxId) {
      minAmountResponse = await axios.post<MinAmountDynamo>(
        API_ROUTES.MERCHANT_NODE_INFO,
        {
          configId: ConfigIdEnum.CN018,
          publicMerchantId: data.publicMerchantId,
        }
      );

      if (isEmpty(get(minAmountResponse, "data.minAmount", {}))) {
        set(dataSave, "minAmount", minAmountDefault);
        set(dataSave, "gracePeriod", defaultValueForGracePeriod);
      }
    }

    thunkAPI.dispatch(updateMerchant(dataSave));

    const hierarchyRequest: HierarchyNodeInfoRequest = {
      configs: [
        {
          configuration: ConfigIdEnum.CN001,
          status: data.status,
          updatedAt: data.updatedAt,
          updatedBy: data.updatedBy,
          value: publicMerchant,
        },
      ],
      nodeId: data.nodeId,
    };

    if (isEmpty(get(minAmountResponse, "data.minAmount", {}))) {
      hierarchyRequest.configs.push({
        configuration: ConfigIdEnum.CN018,
        updatedAt: data.updatedAt,
        updatedBy: data.updatedBy,
        value: publicMerchant,
      });
    }
    await axios.put<boolean>(API_ROUTES.HIERARCHY_NODE_INFO, hierarchyRequest);

    return true;
  }
);

export const hierarchyNode = createAsyncThunk<
  boolean,
  HierarchyNodeConfigInterfaces
>(prefixes.HIERARCHY_NODE, async (objectUpdate) => {
  const response = await axios.put<boolean>(
    API_ROUTES.HIERARCHY_NODE_INFO,
    objectUpdate
  );

  return response.data;
});

export const hierarchyNodeMassive = createAsyncThunk<
  boolean,
  HierarchyNodeConfigMassiveInterfaces
>(prefixes.HIERARCHY_NODE, async (objectUpdate) => {
  for (let i = 0; i < objectUpdate.nodeId.length; i++) {
    await axios.put<boolean>(API_ROUTES.HIERARCHY_NODE_INFO, {
      configs: [
        {
          configuration: objectUpdate.configs.configuration,
          status: objectUpdate.configs.status,
          value: objectUpdate.merchants[i],
        },
      ],
      nodeId: objectUpdate.nodeId[i],
    });
  }

  return true;
});
