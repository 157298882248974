import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  createStyles,
  Grid,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { get } from "lodash";

export interface InformationAgentSupportProps {
  supportInfo: object | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      boxShadow: "none !important",
      borderRadius: "0px !important",
      backgroundColor: "#F7FAFC !important",
      marginBottom: "20px",
    },
    typography: {
      color: "#293036",
      fontSize: "14px",
    },
  })
);

const InformationAgentSupport: React.FC<InformationAgentSupportProps> = (
  props: InformationAgentSupportProps
) => {
  const classes = useStyles();
  const status: string = get(props.supportInfo, "_source.status", "EXPIRED");

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant={"body2"} color={"textSecondary"}>
                  Agente Soporte:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant={"body2"} color={"textSecondary"}>
                  Nombre de usuario:
                </Typography>
              </Grid>
            </Grid>
            {status === "COMPLETED" ? (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant={"body2"} color={"textPrimary"}>
                    {get(
                      props.supportInfo,
                      "_source.user_agent_full_name",
                      "N/A"
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant={"body2"} color={"textPrimary"}>
                    {get(props.supportInfo, "_source.user_agent", "N/A")}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant={"body2"} color={"textPrimary"}>
                    El código de acceso fue generado pero ningún agente se
                    conectó a su comercio
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export default InformationAgentSupport;
