import {
  ActionRefundEnum,
  ActionRefundTitleEnum,
} from "../../../shared/infrastructure/enums/ActionEnum";
import {
  Box,
  Button,
  Grid,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { ButtonDropDownAction } from "../../ButtonDropDownAction/ButtonDropDownAction";
import { ConfirmModal } from "../../ConfirmModal/ConfirmModal";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { staticText } from "../../../shared/infrastructure/staticText";
import { StatusFlowEnum } from "../../../shared/infrastructure/enums/StatusFlowEnum";
import { TransactionRefundMassiveRequest } from "../../../../types/process_transaction_refund_request";

const useStyles = makeStyles((theme: Theme) => ({
  buttonRefund: {
    "&:disabled": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default,
      marginBottom: theme.spacing(3),
      opacity: 0.5,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default,
    },
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    Height: "36px",
    marginLeft: theme.spacing(1),
    Width: " 117px",
  },
  buttonSkip: {
    "&:disabled": {
      marginBottom: theme.spacing(3),
    },
    backgroundColor: theme.palette.background.default,
    Height: " 20px",
    Width: " 41px",
  },
  container: {
    borderRadius: "5px",
    marginBottom: "10px",
    marginRight: theme.spacing(3),
  },
  containerAction: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  containerTitle: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  detail: {
    fontSize: "15px",
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
  },
  helperText: {
    alignSelf: "end",
    marginRight: 0,
  },
  ksh_multiline: {
    "& > label+div": {
      height: "auto",
    },
  },
  subTitle: {
    fontSize: "15px",
    fontWeight: 500,
    marginTop: theme.spacing(2),
  },
  td: {
    width: "280px",
  },
  textField: {
    paddingBottom: 5,
    paddingTop: 0,
  },
  title: {
    color: "#023365",
    fontSize: "16px",
    fontWeight: 500,
    marginTop: theme.spacing(2),
  },
  typography: {
    fontWeight: 200,
  },
}));

export interface RefundBoxProps {
  numberItems: number;
  actionHandler: (actionEnum: ActionRefundEnum) => void;
  statusFlow: StatusFlowEnum;
  modalRefundData: {
    openDialog: boolean;
    title: string;
    subTitle: string;
    handleAcceptRefundTransactions: () => void;
    handleCloseRefundTransactionsModal: () => void;
    trxSelectedCheckRefund: TransactionRefundMassiveRequest[];
    trxSelectedCheckCancel: TransactionRefundMassiveRequest[];
    trxSelectedCheckGiveBack: TransactionRefundMassiveRequest[];
    trxSelectedCheckOmit: TransactionRefundMassiveRequest[];
    handleChangeRefund: (event: React.ChangeEvent<HTMLInputElement>) => void;
    valueRefund: string | number | undefined;
    setValueRefund: (value: string | number | undefined) => void;
    errorConditionRefund: boolean;
  };
  disabledButtonsAction: boolean;
}

export const RefundBox = (props: RefundBoxProps) => {
  const classes = useStyles();
  const loadProcessResume = () => {
    return (
      <>
        <Typography
          className={classes.detail}
          variant="body2"
          color="textPrimary"
          hidden={
            ![
              ActionRefundTitleEnum[ActionRefundEnum.GIVEBACK],
              ActionRefundTitleEnum[ActionRefundEnum.CANCEL],
              ActionRefundTitleEnum[ActionRefundEnum.OMIT],
              ActionRefundTitleEnum[ActionRefundEnum.REFUND],
            ].includes(props.modalRefundData.title)
          }
        >
          <Box fontSize={15} id={"merchantId"} sx={{ fontWeight: "bold" }}>
            {"Varios comercios seleccionados:"}
          </Box>
        </Typography>
        <Box id={"numbersTrx"} fontSize={15}>
          {"Número de transacciones seleccionadas: " + props.numberItems}
        </Box>
        <Grid
          hidden={
            ![
              ActionRefundTitleEnum[ActionRefundEnum.APPROVE],
              ActionRefundTitleEnum[ActionRefundEnum.NOAPPROVE],
            ].includes(props.modalRefundData.title)
          }
        >
          <Typography
            className={classes.detail}
            variant="body2"
            color="textPrimary"
          >
            <Box fontSize={15} id={"merchantId"} sx={{ fontWeight: "bold" }}>
              {"Detalles:"}
            </Box>
          </Typography>
          <table>
            <>
              <tr>
                <td className={classes.td}>
                  <Typography variant="h6">
                    Transacciones para Devolver :
                  </Typography>
                </td>
                <td>
                  <Typography variant="h6">
                    {props.modalRefundData.trxSelectedCheckGiveBack.length}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td className={classes.td}>
                  <Typography variant="h6">
                    Transacciones para Reembolsar:
                  </Typography>
                </td>
                <td>
                  <Typography variant="h6">
                    {props.modalRefundData.trxSelectedCheckRefund.length}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td className={classes.td}>
                  <Typography variant="h6">
                    Transacciones para Cancelar:
                  </Typography>
                </td>
                <td>
                  <Typography variant="h6">
                    {props.modalRefundData.trxSelectedCheckCancel.length}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td className={classes.td}>
                  <Typography variant="h6">
                    Transacciones para Omitir:
                  </Typography>
                </td>
                <td>
                  <Typography variant="h6">
                    {props.modalRefundData.trxSelectedCheckOmit.length}
                  </Typography>
                </td>
              </tr>
            </>
          </table>
        </Grid>
        <Box mt={2} id={"reason"} fontSize={15}>
          {props.modalRefundData.subTitle}
        </Box>
        <br />
        <Grid item xs={12} xl={12} className={classes.textField}>
          <TextField
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            error={
              String(props.modalRefundData.valueRefund).length >= 200 ||
              props.modalRefundData.errorConditionRefund
            }
            className={classes.ksh_multiline}
            autoComplete="off"
            variant="outlined"
            fullWidth
            label={"Motivo..."}
            value={props.modalRefundData.valueRefund}
            id="alias"
            multiline
            helperText={
              props.modalRefundData.errorConditionRefund
                ? "Campo requerido"
                : `${String(props.modalRefundData.valueRefund).length}/200`
            }
            onChange={props.modalRefundData.handleChangeRefund}
            rows={3}
          />
        </Grid>
      </>
    );
  };

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item xs={8} className={classes.containerTitle}>
          <Typography className={classes.title}>{staticText.TITLE}</Typography>
          <Typography className={classes.title}>
            {`${props.numberItems} ${staticText.SUBTITLE}`}
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.containerAction}>
          {props.statusFlow === StatusFlowEnum.VALIDATOR && (
            <>
              <Button
                id={"ButtonNoApproved"}
                variant="contained"
                disabled={props.disabledButtonsAction}
                className={classes.buttonSkip}
                onClick={() => props.actionHandler(ActionRefundEnum.NOAPPROVE)}
              >
                No autorizar
              </Button>
              <Button
                id={"ButtonApproved"}
                variant="contained"
                disabled={props.disabledButtonsAction}
                className={classes.buttonRefund}
                onClick={() => props.actionHandler(ActionRefundEnum.APPROVE)}
              >
                Autorizar
              </Button>
            </>
          )}
          {props.statusFlow === StatusFlowEnum.EXECUTOR && (
            <>
              <ButtonDropDownAction
                disabledButtonsAction={props.disabledButtonsAction}
                options={[
                  {
                    text: "Reembolsar",
                    value: ActionRefundEnum.REFUND,
                  },
                  {
                    text: "Devolver",
                    value: ActionRefundEnum.GIVEBACK,
                  },
                  {
                    text: "Cancelar",
                    value: ActionRefundEnum.CANCEL,
                  },
                  {
                    text: "Omitir",
                    value: ActionRefundEnum.OMIT,
                  },
                ]}
                actionHandler={props.actionHandler}
              />
            </>
          )}
        </Grid>
      </Grid>
      <Grid container item>
        <ConfirmModal
          open={props.modalRefundData.openDialog}
          titleText={props.modalRefundData.title}
          contentText={""}
          acceptText={"Enviar"}
          handleClose={props.modalRefundData.handleCloseRefundTransactionsModal}
          handleAccept={props.modalRefundData.handleAcceptRefundTransactions}
          acceptColor="primary"
          disableAccept={
            props.statusFlow == StatusFlowEnum.VALIDATOR
              ? false
              : String(props.modalRefundData.valueRefund).length === 0
          }
        >
          {loadProcessResume()}
        </ConfirmModal>
      </Grid>
    </>
  );
};
