export enum WalletMessage {
  WalletNote = "Configura la recarga automática de wallet parcial o total del comercio. ",
  WalletNoteBolder = "Aplica solo si el comercio tiene configurados procesadores Payins y Payouts.*",
  Configuration = "Abrir configuración",
  Omit = "Omitir este paso",
  WalletButton = "Configurar recarga wallet",
  FIRST_CONFIG = "Configura tu primera ",
  PARTIAL = "Parcial",
  TOTAL = "Total",
  TITLE_RESUME = "Configuración de recarga automática de Wallet",
  WALLET_AUTOMATIC_RECHARGE = "recarga automática de wallet",
}
