import React from "react";
import { Container } from "@material-ui/core";
import { IAppState } from "../../store/reducer";
import imgCreateMerchant from "../../assets/image/img-create-merchant.svg";
import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
import { useCreateMerchantIndexState } from "./state/useCreateMerchantIndexState";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  createMerchantAffiliation,
  getAffiliationLeads,
  IAppAction,
} from "../../store/actionCreators";
import { connect } from "react-redux";
import { CreateMerchantCard } from "../../components/CreateMerchantComponents/InitCard/CreateMerchantCard";
import { defaultTo, get } from "lodash";
import { MerchantCreateUpdateResponse } from "../../../types/merchant_create_update_response";

export interface ICreateMerchantStateProps {
  state: IAppState;
}

export interface ICreateMerchantFunctionsProps {
  getAffiliationLeads(): void;
  createMerchantAffiliation(
    merchantRequest: MerchantCreateUpdateResponse
  ): void;
}

export type TCreateMerchantIndexProps = ICreateMerchantStateProps &
  ICreateMerchantFunctionsProps;

export const CreateMerchantIndex: React.FC<TCreateMerchantIndexProps> = (
  props: TCreateMerchantIndexProps
) => {
  const { breadCrumbs, form, state } = useCreateMerchantIndexState(props);

  return (
    <React.Fragment>
      <Container fixed>
        <Breadcrumb items={breadCrumbs.items} lastItem={breadCrumbs.lastItem} />
        <CreateMerchantCard
          isLoading={!!props.state.loading}
          autocomplete={{
            options: state.leads,
            currentLead: state.currentLead,
          }}
          merchantData={{
            country: defaultTo(state.country, "Ecuador"),
            merchantName: state.merchantName,
          }}
          countryDisabled={get(state, "countryDisabled", false)}
          isEdit={!!props.state.isEdit}
          publicMerchantId={props.state.semaphore?.publicMerchantId}
          src={imgCreateMerchant}
          errors={form.errors}
          control={form.control}
          register={form.register}
          handleFormChange={form.formChange}
          handleCheckboxChange={form.checkboxChange}
          onSubmit={form.submit}
        />
      </Container>
    </React.Fragment>
  );
};

export const mapStateToProps: (
  state: IAppState
) => ICreateMerchantStateProps = (
  state: IAppState
): ICreateMerchantStateProps => ({ state });

export const mapDispatchToProps: (
  dispatch: Dispatch
) => ICreateMerchantFunctionsProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): ICreateMerchantFunctionsProps => ({
  getAffiliationLeads: (): void => dispatch(getAffiliationLeads()),
  createMerchantAffiliation: (
    merchantRequest: MerchantCreateUpdateResponse
  ): void => dispatch(createMerchantAffiliation(merchantRequest)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateMerchantIndex);
