import React from "react";
import { StatusSteps } from "../../../../types/remote/semaphore_data";
import { UserPendingSection } from "./UserPendingSection";

export const UserSection: React.FC<any> = (
  _: string,
  _0: (step: string, status: StatusSteps) => void
) => {
  return (
    <React.Fragment>
      <UserPendingSection />
    </React.Fragment>
  );
};
