import { IAppState } from "./reducer";
import { ActionTypes } from "./actionTypes";
import { INotification } from "../shared/infrastructure/interfaces/INotification";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import axios from "../shared/axios-util";
import { AxiosResponse } from "axios";
import { environment } from "../environments/environment";
import { UploadFileRetentionRequest } from "../../types/upload_file_retention_request";
import { GetIdRetailsConfigEcRequest } from "../../types/get_id_retails_config_ec_request";
import { RetentionMerchantConfigDynamo } from "../../types/retention_merchant_configuration";
import { MessageEnum } from "../shared/infrastructure/Enums/MessageEnum";
import { ICsvTemplateData } from "../shared/infrastructure/interfaces/ICsvTemplate";
import { CreateRetailsConfigRequest } from "../../types/create_retails_config_request";
import { routes } from "../shared/infrastructure/routes";
import { capitalize, defaultTo, get, isEmpty } from "lodash";
import { ErrorsEnum } from "../shared/infrastructure/Enums/ErrorsEnum";
import { TypeRetentionEnum } from "../shared/infrastructure/constants/RetentionConstants";
import {
  columnsRetentionIVATable,
  columnsRetentionRENTATable,
} from "../shared/infrastructure/Table/ColumnsTable";
import { GetDatilCodeRequest } from "../../types/get_datil_code_request";
import { RetentionEquivalenceDynamo } from "../../types/retention_equivalence_dynamo";
import { CountryNameEnum } from "../shared/infrastructure/Enums/CountryEnum";
import { RetailsConfigurationsFile } from "../../types/retails_configurations_file";
import { Catalog } from "../../types/catalog";
import { RetentionEnum } from "../shared/infrastructure/Enums/RetentionEnum";
import { CatalogRequest } from "../../types/catalog_request";
import { UploadRetentionCatalogRequest } from "../../types/upload_retention_catalog_request";
import { FileNameEnum } from "../shared/infrastructure/Enums/FileNameEnum";
import { CatalogsRequestEnum } from "../shared/infrastructure/Enums/CatalogsRequestEnum";
import {
  ErrorUploadCatalog,
  ErrorUploadCatalogMessageEnumEnglish,
} from "../shared/infrastructure/constants/ErrorConstant";

export type IAppAction = { type: string } & IAppState;

export const setNotification = (payload: INotification) => ({
  type: ActionTypes.SET_NOTIFICATION,
  notification: payload,
});
export const setNotificationConfig = (payload: INotification) => ({
  type: ActionTypes.SET_NOTIFICATION_CONFIG,
  notificationConfig: payload,
});
export const setLoading = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_LOADING,
    isLoading: payload,
  };
};
export const setLoadingRetentionDashboard = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_LOADING_RETENTION_DASHBOARD,
    isLoadingRetentionDashboard: payload,
  };
};
export const setRetention = (
  payload: RetentionMerchantConfigDynamo[]
): IAppAction => {
  return {
    type: ActionTypes.SET_RETENTION,
    retention: payload,
  };
};
export const setEditRetention = (
  payload: RetentionMerchantConfigDynamo
): IAppAction => {
  return {
    type: ActionTypes.SET_EDIT_RETENTION,
    editRetention: payload,
  };
};
export const setRetentionConfig = (
  payload: RetentionMerchantConfigDynamo
): IAppAction => {
  return {
    type: ActionTypes.SET_RETENTION_CONFIG,
    retentionConfig: payload,
  };
};
export const setCsvTemplate = (payload: ICsvTemplateData): IAppAction => {
  return {
    type: ActionTypes.SET_CSV_TEMPLATE,
    templateXslxData: payload,
  };
};
export const setOpenModalConfirmAddConfig = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_OPEN_MODAL_CONFIRM_ADD_CONFIG,
    isOpenModalConfirmAddConfig: payload,
  };
};
export const setLoadingDeleteRetailConfig = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_LOADING_DELETE_RETAIL_CONFIG,
    isLoadingDeleteRetailConfig: payload,
  };
};
export const setCompletedDeleteRetailConfig = (
  payload: boolean
): IAppAction => {
  return {
    type: ActionTypes.SET_COMPLETED_DELETE_RETAIL_CONFIG,
    isCompletedDelete: payload,
  };
};
export const setLoadByFormError = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_LOAD_BY_FORM_ERROR,
    loadByFormError: payload,
  };
};

export const setExistRetention = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.EXIST_RETENTION,
    existRetention: payload,
  };
};

export const setLoadingVerifyExistRetention = (
  payload: boolean
): IAppAction => {
  return {
    type: ActionTypes.SET_LOADING_VERIFY_EXIST_RETENTION,
    isLoadingVerifyExistRetention: payload,
  };
};

export const setAddRetention = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_ADD_RETENTION,
    isAddRetention: payload,
  };
};

export const setIsEditRetention = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_IS_EDIT_RETENTION,
    isEditRetention: payload,
  };
};

export const setAddRetentionDirectly = (payload: boolean): IAppAction => ({
  type: ActionTypes.SET_ADD_RETENTION_DIRECTLY,
  addRetentionDirectly: payload,
});

export const setColumnsConfigRetentionEc = (
  payload: TypeRetentionEnum
): IAppAction => {
  if (payload === TypeRetentionEnum.RETENTION)
    return {
      type: ActionTypes.SET_COLUMNS_CONFIG_RETENTION_EC,
      columnsConfigRetentionEc: columnsRetentionRENTATable,
    };
  return {
    type: ActionTypes.SET_COLUMNS_CONFIG_RETENTION_EC,
    columnsConfigRetentionEc: columnsRetentionIVATable,
  };
};

export const setIsOpenCatalogFormPopup = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_IS_OPEN_CATALOG_FORM_POPUP,
    isOpenCatalogFormPopup: payload,
  };
};

export const setIsOpenCatalogConfirmPopup = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_IS_OPEN_CATALOG_CONFIRM_POPUP,
    isOpenCatalogConfirmPopup: payload,
  };
};

export const setIsOpenCatalogInfoPopup = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_IS_OPEN_CATALOG_INFO_POPUP,
    isOpenCatalogInfoPopup: payload,
  };
};

export const setRetentionsConfigFile = (
  payload: RetailsConfigurationsFile
): IAppAction => {
  return {
    type: ActionTypes.SET_RETENTIONS_CONFIG_FILE,
    retentionsConfigFile: payload,
  };
};

const buildAxiosRetentionEc = (
  country: string,
  payload: TypeRetentionEnum | undefined
): Promise<AxiosResponse<RetentionMerchantConfigDynamo[]>> => {
  return axios.post(
    `${environment.kushkiUrl}/retails/retentionConfigByCountry`,
    {
      country: country,
      typeIvaRetention: payload,
    }
  );
};

export const getRetentionByCountry = (
  payload: string,
  options?: {
    typeRetention?: TypeRetentionEnum;
    bothRetention?: boolean;
  }
): ThunkAction<void, IAppState, undefined, IAppAction> => (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): void => {
  dispatch(setLoadingRetentionDashboard(true));

  if (get(options, "bothRetention", false)) {
    dispatch(getBothRetentionEc(payload));
    return;
  }
  buildAxiosRetentionEc(
    payload,
    defaultTo(get(options, "typeRetention"), undefined)
  )
    .then((axios_response: AxiosResponse<RetentionMerchantConfigDynamo[]>) => {
      dispatch(setRetention(axios_response.data));
      dispatch(
        setColumnsConfigRetentionEc(
          defaultTo(get(options, "typeRetention"), TypeRetentionEnum.IVA)
        )
      );
      dispatch(setLoadingRetentionDashboard(false));
    })
    .catch((_e) => {
      dispatch(setLoadingRetentionDashboard(false));
      dispatch(
        setNotification({
          type: "error",
          message: MessageEnum.GENERIC_ERROR,
          open: true,
        })
      );
    });
};
export const setErrorCsv = (
  payload: boolean,
  messageError: string
): IAppAction => {
  return {
    type: ActionTypes.SET_ERROR_CSV,
    errorCsv: payload,
    errorCsvMessage: messageError,
  };
};

export const setFileSent = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_FILE_SENT,
    fileSent: payload,
  };
};
export const setFileSentCatalog = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_FILE_SENT_CATALOG,
    fileSentCatalog: payload,
  };
};

export const uploadRetentionsConfig = (
  payload: UploadFileRetentionRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): void => {
  dispatch(setLoading(true));
  axios
    .post<boolean>(
      `${environment.kushkiUrl}/retails/uploadRetentionsConfig`,
      payload
    )
    .then(() => {
      dispatch(setLoading(false));
      dispatch(setFileSent(true));
    })
    .catch((error) => {
      dispatch(setLoading(false));
      dispatch(setErrorCsv(true, get(error, "response.data.message", "")));
    });
};

export const uploadRetentionCatalog = (
  payload: UploadRetentionCatalogRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): void => {
  dispatch(setLoading(true));
  axios
    .post<boolean>(
      `${environment.kushkiUrl}/retails/v1/uploadRetentionCatalog`,
      payload
    )
    .then(() => {
      dispatch(setLoading(false));
      dispatch(setFileSentCatalog(true));
    })
    .catch((error) => {
      dispatch(setLoading(false));
      dispatch(
        setNotification({
          type: "error",
          message:
            ErrorUploadCatalog[
              get(
                error,
                "response.data.message",
                MessageEnum.ERROR_UPDATE_CATALOG_ITEM
              ).replace(
                ErrorUploadCatalogMessageEnumEnglish.UPLOAD_CATALOG_ERROR_PREFIX,
                ""
              )
            ],
          open: true,
        })
      );
    });
};

export const base64toXlsx = (
  base64_file: string,
  nameFile: string
): ThunkAction<void, IAppState, undefined, IAppAction> => (): void => {
  if (!isEmpty(base64_file)) {
    let bufferFile = new Buffer(base64_file, "base64");
    const fileToDownload: Blob = new Blob([bufferFile], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url: string = window.URL.createObjectURL(fileToDownload);
    const target: HTMLAnchorElement = document.createElement("a");

    if (target.style !== undefined) target.style.display = "none";
    document.body.appendChild<HTMLAnchorElement>(target);
    target.href = url;
    target.download = nameFile;
    target.click();
    setTimeout(function () {
      document.body.removeChild(target);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
};

export const downloadTemplateExampleCsv = (
  country: string,
  kind: string
): ThunkAction<void, IAppState, undefined, IAppAction> => (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): void => {
  const baseUrl: string = `${environment.kushkiUrl}/retails/getTemplateRetailsConfig?country=${country}`;
  const url: string =
    capitalize(country) === CountryNameEnum.Ecuador
      ? `${baseUrl}&source=${kind}`
      : baseUrl;

  dispatch(
    setCsvTemplate({ isLoading: true, isLoadingFileData: false, s3Url: "" })
  );

  const fileName: string =
    capitalize(country) === CountryNameEnum.Colombia
      ? FileNameEnum.RETENTION_ICA_CO
      : kind === RetentionEnum.IVA.toUpperCase()
      ? FileNameEnum.CONFIG_IVA_EC
      : kind === RetentionEnum.RENTA.toUpperCase()
      ? FileNameEnum.CONFIG_RENT_EC
      : FileNameEnum.CONFIG_CATALOG_EC;

  axios
    .get<string>(url)
    .then((axios_response: AxiosResponse<string>) => {
      dispatch(
        setCsvTemplate({
          isLoading: false,
          isLoadingFileData: false,
          s3Url: "",
        })
      );
      dispatch(base64toXlsx(get(axios_response, "data", ""), fileName));
      dispatch(
        setNotification({
          type: "success",
          message: MessageEnum.SUCCESS_DOWNLOAD_CATALOG,
          open: true,
        })
      );
    })
    .catch((_e) => {
      dispatch(
        setCsvTemplate({
          isLoading: false,
          isLoadingFileData: false,
          s3Url: "",
        })
      );
      dispatch(
        setNotification({
          type: "error",
          message: MessageEnum.ERROR_UPDATE_CATALOG_ITEM,
          open: true,
        })
      );
    });
};

export const downloadCatalogTemplateWithData = (): ThunkAction<
  void,
  IAppState,
  undefined,
  IAppAction
> => (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
  const baseUrl: string = `${environment.kushkiUrl}/retails/v1/downloadRetentionCatalog`;
  const timestamp: number = new Date().getTime();

  dispatch(
    setCsvTemplate({ isLoadingFileData: true, isLoading: false, s3Url: "" })
  );

  axios
    .get<string>(baseUrl)
    .then((axios_response: AxiosResponse<string>) => {
      dispatch(
        setCsvTemplate({
          isLoadingFileData: false,
          isLoading: false,
          s3Url: "",
        })
      );
      dispatch(
        base64toXlsx(
          get(axios_response, "data.file", ""),
          "Configuraciones_Catalogo_" + timestamp + ".xlsx"
        )
      );
      dispatch(
        setNotification({
          type: "success",
          message: MessageEnum.SUCCESS_DOWNLOAD_CATALOG,
          open: true,
        })
      );
    })
    .catch((_e) => {
      dispatch(
        setCsvTemplate({
          isLoadingFileData: false,
          isLoading: false,
          s3Url: "",
        })
      );
      dispatch(
        setNotification({
          type: "error",
          message: MessageEnum.ERROR_UPDATE_CATALOG_ITEM,
          open: true,
        })
      );
    });
};

export const getIdRetailConfigEc = (
  payload: GetIdRetailsConfigEcRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): Promise<void> => {
  dispatch(setLoadingDeleteRetailConfig(true));
  dispatch(setCompletedDeleteRetailConfig(false));
  try {
    const getRetailIdUrl = `${environment.kushkiUrl}/retails/v1/getRetailsConfigIdEc`;
    const { data } = await axios.post<string>(getRetailIdUrl, payload);
    const deleteConfigUrl = `${environment.kushkiUrl}/retails/v1/deleteRetailsConfig/${data}`;
    await axios.delete<boolean>(deleteConfigUrl);
    dispatch(
      setNotification({
        type: "success",
        message: MessageEnum.SUCCESS_DELETE_CONFIG_RETAIL,
        open: true,
      })
    );
    dispatch(setLoadingDeleteRetailConfig(false));
    dispatch(setCompletedDeleteRetailConfig(true));
  } catch (e) {
    dispatch(setLoadingDeleteRetailConfig(false));
    dispatch(setCompletedDeleteRetailConfig(false));
    dispatch(
      setNotification({
        type: "error",
        message: MessageEnum.ERROR_DELETE_CONFIG_RETAIL,
        open: true,
      })
    );
  }
};

export const createUpdateRetailsConfig = (
  idRetailConfig: string,
  payload: object
): ThunkAction<void, IAppState, undefined, IAppAction> => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): Promise<void> => {
  dispatch(setOpenModalConfirmAddConfig(false));
  try {
    const getRetailConfigUrl: string = `${environment.kushkiUrl}/retails/getIDRetailsConfig/${idRetailConfig}`;
    const createUpdateConfigUrl: string = `${environment.kushkiUrl}/retails/createUpdateRetailsConfig`;
    const responseGet = await axios.get<object>(getRetailConfigUrl);
    if (isEmpty(responseGet.data)) dispatch(setOpenModalConfirmAddConfig(true));
    else {
      const responseCreateUpdate = await axios.post<boolean>(
        createUpdateConfigUrl,
        payload
      );
      dispatch(setOpenModalConfirmAddConfig(false));
      dispatch(
        setNotificationConfig({
          message: MessageEnum.SUCCESS_CONFIG_RETENTIONS,
          open: responseCreateUpdate.data,
          type: "dark",
        })
      );
    }
  } catch (e) {
    dispatch(setOpenModalConfirmAddConfig(false));
  }
};

export const createRetentionsConfig = (
  payload: CreateRetailsConfigRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): void => {
  dispatch(setLoading(true));
  axios
    .post<boolean>(
      `${environment.kushkiUrl}/retails/createUpdateRetailsConfig`,
      payload
    )
    .then((axios_response: AxiosResponse<boolean>) => {
      dispatch(
        setNotification({
          open: true,
          message: MessageEnum.SUCCESS_CONFIG_RETENTIONS,
          type: "dark",
        })
      );
      dispatch(setLoading(!axios_response.data));
      setInterval(
        (() => {
          window.location.href = `${routes.BASE_PATH_RETENTIONS}${routes.DASHBOARD}?country=${payload.country}`;
        }) as TimerHandler,
        1000
      );
    })
    .catch((_e) => {
      dispatch(setLoadByFormError(true));
      dispatch(setLoading(false));
    });
};

export const validExistRetentionById = (
  payload: GetIdRetailsConfigEcRequest,
  edit?: boolean
): ThunkAction<void, IAppState, undefined, IAppAction> => (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): void => {
  dispatch(setLoadingVerifyExistRetention(true));
  const url: string = `${environment.kushkiUrl}/retails/v1/getRetailsConfigIdEc`;
  axios
    .post<string>(url, payload)
    .then(() => {
      if (edit) {
        dispatch(setValidateRetentionEdit(false));
      }
      dispatch(setExistRetention(true));
      dispatch(setLoadingVerifyExistRetention(false));
      dispatch(setLoadingRetentionDashboard(false));
    })
    .catch((error) => {
      dispatch(setLoadingVerifyExistRetention(false));
      if (
        get(error, "response.data.message", "") !== ErrorsEnum.EXIST_RETENTION
      ) {
        dispatch(
          setNotification({
            type: "error",
            message: MessageEnum.GENERIC_ERROR,
            open: true,
          })
        );
        return;
      }
      if (edit) {
        dispatch(setValidateRetentionEdit(true));
        return;
      }
      dispatch(setExistRetention(false));
      dispatch(setAddRetention(true));
      dispatch(setLoadingRetentionDashboard(false));
    });
};

export const validateCodeDatil = (
  payload: GetDatilCodeRequest,
  setError: any
): ThunkAction<void, IAppState, undefined, IAppAction> => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): Promise<void> => {
  try {
    dispatch(setLoadingVerifyExistRetention(true));
    const axios_response: AxiosResponse<RetentionEquivalenceDynamo> = await buildAxiosCodeDatil(
      payload
    );
    dispatch(setCodeDatil(axios_response.data.retentionPercentCode));
    dispatch(setLoadingVerifyExistRetention(false));
  } catch {
    dispatch(setCodeDatil(""));
    setError("rentRetention", "required", "Verifica el % ingresado.");
    dispatch(setLoadingVerifyExistRetention(false));
  }
};

export const buildAxiosCodeDatil = (
  payload: GetDatilCodeRequest
): Promise<AxiosResponse<RetentionEquivalenceDynamo>> => {
  return axios.post(
    `${environment.kushkiUrl}/retails/v1/getCodeDatil`,
    payload
  );
};

const getBothRetentionEc = (
  country: string
): ThunkAction<void, IAppState, undefined, IAppAction> => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): Promise<void> => {
  Promise.all([
    buildAxiosRetentionEc(country, TypeRetentionEnum.IVA),
    buildAxiosRetentionEc(country, TypeRetentionEnum.RETENTION),
  ])
    .then((axios_response) => {
      const configRetentionIva: RetentionMerchantConfigDynamo[] = get(
        axios_response[0],
        "data",
        []
      );
      const confiRetentionRent: RetentionMerchantConfigDynamo[] = get(
        axios_response[1],
        "data",
        []
      );
      if (!isEmpty(configRetentionIva)) {
        dispatch(setRetention(configRetentionIva));
        dispatch(setColumnsConfigRetentionEc(TypeRetentionEnum.IVA));
      } else {
        dispatch(setRetention(confiRetentionRent));
        dispatch(setColumnsConfigRetentionEc(TypeRetentionEnum.RETENTION));
      }
      dispatch(setLoadingRetentionDashboard(false));
    })
    .catch((_error) => {
      dispatch(setLoadingRetentionDashboard(false));
      dispatch(
        setNotification({
          type: "error",
          message: MessageEnum.GENERIC_ERROR,
          open: true,
        })
      );
    });
};

export const setIndexTabEditRetention = (payload: number): IAppAction => {
  return {
    type: ActionTypes.SET_INDEX_TAB_EDIT_RETENTION,
    indexEditRetentionTab: payload,
  };
};

export const setEditRetentionDashboard = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_EDIT_RETENTION_DASHBOARD,
    editRetentionDashboard: payload,
  };
};

export const setValidateRetentionEdit = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_EDIT_RETENTION_VALIDATE,
    validateRetentionEdit: payload,
  };
};

export const setCodeDatil = (payload: string): IAppAction => {
  return {
    type: ActionTypes.SET_CODE_DATIL,
    codeDatil: payload,
  };
};

export const indexTabEditRetentionRender = (
  payload: number
): ThunkAction<void, IAppState, undefined, IAppAction> => (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): void => {
  dispatch(setIndexTabEditRetention(payload));
};

const buildDownloadRetailConfigurationFile = (
  country: string,
  typeRetention: TypeRetentionEnum
): Promise<AxiosResponse<RetailsConfigurationsFile>> => {
  return axios.post(
    `${environment.kushkiUrl}/retails/downloadRetailsConfiguration`,
    {
      country,
      typeRetention,
    }
  );
};

export const getRetentionsConfigFile = (
  typeRetention: TypeRetentionEnum,
  country: string
): ThunkAction<void, IAppState, undefined, IAppAction> => (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): void => {
  dispatch(setLoadingRetentionDashboard(true));
  buildDownloadRetailConfigurationFile(country, typeRetention)
    .then((axios_response: AxiosResponse<RetailsConfigurationsFile>) => {
      dispatch(setRetentionsConfigFile(axios_response.data));
      dispatch(setLoadingRetentionDashboard(false));
    })
    .catch((_e) => {
      dispatch(setLoadingRetentionDashboard(false));
      dispatch(
        setNotification({
          type: "error",
          message:
            "Ha ocurrido un error inesperado al descargar configuraciones actuales.",
          open: true,
        })
      );
    });
};

export const setCatalogsList = (payload: Catalog[]): IAppAction => {
  const formatRequest: Catalog[] = payload.map((catalog) => ({
    name: catalog.name,
    configCode: catalog.configCode,
    countryCode: catalog.countryCode,
    PK1: catalog.PK1,
    data: catalog.data,
    id: catalog.id,
  }));
  return {
    type: ActionTypes.SET_CATALOGS_LIST,
    catalogsList: formatRequest,
  };
};

export const setCatalogRequest = (
  payload: CatalogRequest | undefined
): IAppAction => {
  return {
    type: ActionTypes.SET_CATALOG_REQUEST,
    catalogRequest: payload,
  };
};

export const getCatalogsList = (
  payload: Object
): ThunkAction<void, IAppState, undefined, IAppAction> => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): Promise<void> => {
  axios
    .post<Catalog[]>(
      `${environment.kushkiUrl}/catalog/v1/list-catalog`,
      payload
    )
    .then((response: AxiosResponse<Catalog[]>) => {
      if (response.status !== 200) dispatch(setCatalogsList([]));
      if (!isEmpty(response.data)) dispatch(setCatalogsList(response.data));
      dispatch(setLoading(false));
    })
    .catch((_err) => {
      dispatch(setLoading(false));
    });
};

export const updateCatalogItem = (
  payload: CatalogRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): Promise<void> => {
  dispatch(setLoading(true));
  axios
    .patch<Catalog[]>(`${environment.kushkiUrl}/catalog/v1/item`, payload)
    .then((response: AxiosResponse<object>) => {
      dispatch(setLoading(false));
      if (response.status === 200) {
        dispatch(
          setCatalogRequest({ action: "", code: "", id: "", value: "" })
        );
        dispatch(setIsOpenCatalogFormPopup(false));
        dispatch(setIsOpenCatalogConfirmPopup(false));
        dispatch(
          setNotification({
            type: "success",
            message: get(
              response.data,
              "message",
              MessageEnum.SUCCESS_UPDATE_CATALOG_ITEM
            ),
            open: true,
          })
        );
        dispatch(
          getCatalogsList({
            productLine: CatalogsRequestEnum.PLA,
            configCode: CatalogsRequestEnum.CN003,
            countryCode: CatalogsRequestEnum.ECUADOR,
          })
        );
      } else {
        dispatch(
          setNotification({
            type: "error",
            message: get(
              response.data,
              "message",
              MessageEnum.ERROR_UPDATE_CATALOG_ITEM
            ),
            open: true,
          })
        );
      }
    })
    .catch((_err) => {
      dispatch(setLoading(false));
      dispatch(
        setNotification({
          type: "error",
          message: MessageEnum.ERROR_UPDATE_CATALOG_ITEM,
          open: true,
        })
      );
    });
};

export const getRetentionCatalogsByCountry = (
  country: string,
  typeRetention: TypeRetentionEnum
): ThunkAction<void, IAppState, undefined, IAppAction> => (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): void => {
  dispatch(setLoading(true));
  buildAxiosRetentionEc(country, typeRetention)
    .then((axios_response: AxiosResponse<RetentionMerchantConfigDynamo[]>) => {
      dispatch(setRetention(axios_response.data));
      dispatch(setLoading(false));
    })
    .catch((_e) => {
      dispatch(setLoading(false));
      dispatch(
        setNotification({
          type: "error",
          message: MessageEnum.GENERIC_ERROR,
          open: true,
        })
      );
    });
};

export const downloadCsvFileOfMerchantConcernedByCatalog = (
  payload: Object
): ThunkAction<void, IAppState, undefined, IAppAction> => async (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): Promise<void> => {
  axios
    .post<string>(
      `${environment.kushkiUrl}/billing-core/v1/downloadMerchantRetention`,
      payload
    )
    .then((response: AxiosResponse<string>) => {
      if (response.status === 201)
        dispatch(
          setBase64CatalogsMatchWithMerchantRetentionConfig(response.data)
        );
      dispatch(setLoading(false));
    })
    .catch((_err) => {
      dispatch(setLoading(false));
    });
};

export const setBase64CatalogsMatchWithMerchantRetentionConfig = (
  base64: string
): IAppAction => {
  return {
    type: ActionTypes.SET_BASE64_CSV,
    base64Csv: base64,
  };
};
