import React, { FC } from "react";
import { FilterSideBarMassiveProps } from "./FilterSideBar.interfaces";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { ItemCategory } from "../ItemCategory/ItemCategory";
import Badge from "@mui/material/Badge";
import { Filter } from "react-feather";
import { useFilterSideBarState } from "./state/useFilterSideBarState";
import {
  ItemCategoryItemMassive,
  ItemCategoryMassiveProps,
} from "../ItemCategory/interfaces";
import { FilterSideBarStyles } from "./FilterSideBar.styles";
import { FilterTextsEnum } from "../../../shared/enum/FilterTextsEnum";

const FilterSideBarMassive: FC<FilterSideBarMassiveProps> = (
  props: FilterSideBarMassiveProps
) => {
  const {
    filterCount,
    handleApplyClick,
    handleDeleteFilters,
    handleMinimizeEverything,
    handleOnCloseSideBar,
    handleOpenSideBar,
    handleOnOpenSideBar,
    list,
    minimizeAll,
    open,
  } = useFilterSideBarState(props);

  function updateFilterCount(itemCategory: ItemCategoryMassiveProps[]) {
    let count: number = 0;

    itemCategory.map((category: ItemCategoryMassiveProps) => {
      category.items.map((subItem: ItemCategoryItemMassive) => {
        if (subItem.selected) {
          count++;
        }
      });
    });

    return count;
  }

  return (
    <>
      <IconButton
        data-testid={"filterBtn"}
        color={"secondary"}
        size={"medium"}
        onClick={handleOpenSideBar}
      >
        <Badge
          badgeContent={updateFilterCount(list)}
          color="primary"
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
          max={9}
          sx={FilterSideBarStyles.badge}
        >
          <Filter size={24} color={"#112B45"} />
        </Badge>
      </IconButton>
      {/*// @ts-ignore*/}
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={handleOnCloseSideBar}
        onOpen={handleOnOpenSideBar}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        sx={FilterSideBarStyles.filterSideBar}
      >
        <Box sx={FilterSideBarStyles.filterSideBarHeader}>
          <Box sx={FilterSideBarStyles.typographyBox}>
            <Typography color="text.dark" variant="h5">
              {props.title}
            </Typography>
          </Box>
          <Box sx={FilterSideBarStyles.linkBox}>
            <Link
              component="button"
              onClick={handleMinimizeEverything}
              sx={FilterSideBarStyles.minimizeButton}
              color={"text.primary"}
              variant={"body2"}
            >
              {minimizeAll
                ? FilterTextsEnum.MAXIMIZE_ALL
                : FilterTextsEnum.MINIMIZE_ALL}
            </Link>
          </Box>
        </Box>
        <Divider sx={FilterSideBarStyles.divider} />
        <Box sx={FilterSideBarStyles.filterSideBarBody}>
          {list.map((item, index) => {
            return (
              <div key={index + item.groupSection}>
                <ItemCategory
                  key={index + item.groupSection}
                  groupSection={item.groupSection}
                  isMinimize={item.isMinimize}
                  items={item.items}
                  selectType={item.selectType}
                  type={item.type}
                />
                {index !== list.length - 1 && (
                  <Divider
                    data-testid={"divider"}
                    sx={FilterSideBarStyles.dividerBorder}
                  />
                )}
              </div>
            );
          })}
        </Box>
        <Box sx={FilterSideBarStyles.filterSideBarFooter}>
          <Button
            size="small"
            variant="contained"
            color="error"
            onClick={handleDeleteFilters}
            disabled={filterCount === 0}
          >
            {FilterTextsEnum.DELETE_FILTERS}
          </Button>
          <Button
            id={"btn-id"}
            size="small"
            variant="contained"
            sx={FilterSideBarStyles.btnApply}
            onClick={handleApplyClick}
          >
            {FilterTextsEnum.APPLY}
          </Button>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export { FilterSideBarMassive };
