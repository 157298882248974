import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { getDeferred } from "../../../store/actionCreators";
import { DeferredOption } from "../../../shared/infrastructure/interfaces/DeferredOptions";
import { defaultTo, get, isEmpty } from "lodash";
import {
  Catalog,
  DEFERRED_TYPE,
  PROCESSOR_CREDIMATIC,
  PROCESSOR_DATAFAST,
  ProcessorsEnum,
  ProcessorsLabelEnum,
} from "../../../shared/infrastructure/constants/DeferredTypeEcuadorEnum";
import { CountriesEnum } from "../../../shared/infrastructure/constants/countries-enum";

export interface IDeferredSection {
  openModal: boolean;
  handleOpenModal: () => void;
  handleGetDeferred: () => void;
  dataHeadersTable: string[];
  deferred: DeferredOption[] | undefined;
  banks: string[];
  handleSetBanks: (data: string[]) => void;
  isDeferredLoaded: boolean | undefined;
  merchantCountry: CountriesEnum;
  setCodeToProcessor: (code: string[]) => string;
  setCodeToTextDeferred: (data: DeferredOption) => string;
}

export const PROCESSOR_NAME_LABEL = {
  [ProcessorsEnum.FIS_PROCESSOR]: ProcessorsLabelEnum.FIS_PROCESSOR,
};

export const useDeferredSection = (): IDeferredSection => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [banks, setBanks] = useState<string[]>([]);
  const [dataHeadersTable, setDataHeadersTable] = useState<string[]>([]);
  const [merchantCountry, setMerchantCountry] = useState<CountriesEnum>(
    CountriesEnum.ECUADOR
  );

  const deferred: DeferredOption[] | undefined = useSelector(
    (state: IAppState) =>
      defaultTo(state.deferred, []).map((d) => {
        return {
          ...d,
          processorName: !isEmpty(
            PROCESSOR_NAME_LABEL[get(d, "processorName", "")]
          )
            ? PROCESSOR_NAME_LABEL[get(d, "processorName", "")]
            : get(d, "processorName", ""),
        };
      })
  );

  const isDeferredLoaded: boolean | undefined = useSelector(
    (state: IAppState) => state.isDeferredLoaded
  );

  const merchantBasicInformation: string = defaultTo(
    localStorage.getItem("merchantBasicInformation"),
    // eslint-disable-next-line
    `{"country":"","publicMerchantId":"0000000","name":"sin merchant"}`
  );
  const parseMerchantBasicInformation = JSON.parse(merchantBasicInformation);

  const dispatch = useDispatch();

  const handleOpenModal = () => setOpenModal(!openModal);

  const handleGetDeferred = () => {
    const publicMerchantId: string =
      parseMerchantBasicInformation.publicMerchantId;
    dispatch(getDeferred(publicMerchantId));
  };

  const getDataHeadersTableByCountry = (country: string): string[] => {
    return country === CountriesEnum.BRAZIL
      ? ["Procesador", "Fecha Programada", "Cuotas", ""]
      : ["Procesador", "Tipo", "Cuotas", ""];
  };

  const setCountry = () => {
    if (parseMerchantBasicInformation.country) {
      setDataHeadersTable(
        getDataHeadersTableByCountry(parseMerchantBasicInformation.country)
      );
      setMerchantCountry(parseMerchantBasicInformation.country);
    }
  };

  const handleSetBanks = (data: string[]) => {
    setBanks(data);
    setOpenModal(true);
  };

  const setCodeToTextDeferred = (data: DeferredOption) => {
    const processorName = get(data, "processorName", "");

    const textDeferredType = DEFERRED_TYPE[processorName]?.find(
      (deferredType: Catalog) =>
        deferredType.code === data.deferredType![0] ||
        processorName === ProcessorsEnum.BILLPOCKET
    );

    return textDeferredType?.text.split("-")[0] || "";
  };

  const setCodeToProcessor = (code: string[]) => {
    let textProcessor: string = "";

    for (let i = 0; i < PROCESSOR_DATAFAST.length; i++) {
      if (PROCESSOR_DATAFAST[i] === code[0])
        textProcessor = ProcessorsEnum.DATAFAST;
    }
    for (let x = 0; x < PROCESSOR_CREDIMATIC.length; x++) {
      if (PROCESSOR_CREDIMATIC[x] === code[0])
        textProcessor = ProcessorsEnum.CREDIMATIC;
    }

    return textProcessor;
  };

  useEffect(() => {
    handleGetDeferred();
    setCountry();
  }, []);

  return {
    openModal,
    handleOpenModal,
    handleGetDeferred,
    dataHeadersTable,
    deferred,
    banks,
    handleSetBanks,
    isDeferredLoaded,
    merchantCountry,
    setCodeToProcessor,
    setCodeToTextDeferred,
  };
};
