import { environment } from "../../environments/environment";

export const API_ROUTES = {
  DOWNLOAD_ANALYTICS: `${environment.kushkiUrl}/analytics/v1/admin/admin/download`,
  DOWNLOAD_FILE_LIST: `${environment.kushkiUrl}/analytics/v1/admin/admin/downloadFileByIdList`,
  DOWNLOAD_MERCHANT_ANALYTICS: `${environment.kushkiUrl}/analytics/v1/admin/merchant/download`,
  DOWNLOAD_TRANSACTIONS: `${environment.envName}/usrv-analytics/file/transaction/`,
  MERCHANT_INFO: `${environment.kushkiUrl}/billing-core/v1/merchant/merchantInfo`,
  MERCHANT_NODE_SEARCH: `${environment.kushkiUrl}/billing-core-node/v1/childrenMerchants`,
  MERCHANTS_ANALYTICS: `${environment.kushkiUrl}/billing-core-node/v1/merchants`,
  NODE_INFO: `${environment.kushkiUrl}/billing-core-node/v1/node/info`,
  REFUND_CONCILIATION_CONFIGURATION: `${environment.kushkiUrl}/refund-conciliation/v1/processor/configuration`,
  RETRIEVE_CHILDREN: `${environment.kushkiUrl}/hierarchy/v1/node/retrieveChildren`,
  SEARCH_MERCHANT_ANALYTYCS: `${environment.kushkiUrl}/analytics/v1/admin/$param/search`,
  USER_PARAMS: `${environment.envName}/authorizer/v1/userParams`,
  VALIDATE_RECEIVABLE: `${environment.kushkiUrl}/dispersions/v1/dispersion/validateReceivable/`,
  WEBHOOK_RECEIPT: `${environment.kushkiUrl}/analytics/v1/receipt`,
};
