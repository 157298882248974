import { ActionTypes } from "./actionTypes";
import { TransactionTable } from "../../types/transaction_table";
import {
  BillingActions,
  ITransactionDownloadFileHistoric,
} from "./actionCreators";
import {
  InvoiceRecord,
  ResponseSearchBillingByFilters,
} from "../../types/remote/response_search_blling_by_filters";
import { ITransactionFile } from "../../types/transaction_file";
import { Color } from "@material-ui/lab";
import { ITransactionDownloadFile } from "../../types/transaction_download_file";
import {
  StatusFlowEnum,
  StatusFlowPath,
} from "../shared/infrastructure/StatusFlowEnum";
import { RangeAmount } from "../../types/range_amount";
import { DownloadInvoicePdf } from "../../types/download_invoice_pdf";
import { RentetionData } from "../../types/retention_data";
import { IStepRetentionTimeLine } from "../shared/infrastructure/interfaces/IStepRetentionTimeLine";
import { InvoiceTransaction } from "../../types/invoice_transaction";
import { Catalog } from "../../types/catalog_response";

export interface INotification {
  open: boolean;
  message: string;
  type: Color | "dark" | "gray";
  metadata?: object;
}

export interface IListBillingRetentions {
  cityId: string;
  country: string;
  processorType: string;
  transactionId: string;
  amount: number;
  backofficerUser: string;
  kind: string;
  amountRetIva: number;
  amountRetSource: number;
  amountRetIca: number;
  baseAmountIva: number;
  baseAmountSource: number;
  baseAmountIca: number;
  amountRetIvaDavi: number;
  amountRetSourceDavi: number;
  amountRetIcaDavi: number;
  baseAmountIvaDavi: number;
  baseAmountSourceDavi: number;
  baseAmountIcaDavi: number;
  retTotalAmount: number;
  retTotalAmountDavi: number;
  status: string;
  statusFlow: string;
  type: string;
}

export interface IBillingDashboardState {
  billingDataCounter: { contador: number; total: number };
  billingData: TransactionTable[];
  historicBillingData: ResponseSearchBillingByFilters;
  historicTrx: InvoiceRecord;
  invoiceTrx: InvoiceTransaction;
  isLoadingBilling: boolean;
  isLoadingInvoice: boolean;
  pathLoadingProcessBilling: StatusFlowPath;
  pathLoadingRetention: StatusFlowPath;
  isLoadingHistoric: boolean;
  isLoadingUpdateDispersion: boolean;
  isLoadingDownloadFile: ITransactionDownloadFileHistoric;
  pagination: object[];
  firebaseId: string | null;
  transactionTableFileDownload: ITransactionDownloadFile;
  transactionsBillingFile: ITransactionFile | null;
  country?: string;
  notification: INotification;
  fileDownloadEfact: string;
  isLoadingFileDownloadEfact: boolean;
  fileDownloadDetail: string;
  fileDownloadManualContingency: string;
  isLoadingFileDownloadDetail: boolean;
  isLoadingFileDownloadManualContingency: boolean;
  statusFlow?: StatusFlowEnum;
  rangeAmount?: RangeAmount;
  reprocessCount?: number;
  downloadPdf?: DownloadInvoicePdf;
  downloadPdfLoading: boolean;
  isLoadingDeleteRetention?: boolean;
  retentionData: RentetionData;
  activeTabExecutor: number;
  stepTimeLineData: IStepRetentionTimeLine;
  openModalDetailTimeLine: boolean;
  openModalModifyInconsistence: boolean;
  retentionsDataCounter: { counter: number; total: number };
  processLoader: boolean;
  processData: boolean;
  openAnnulModal?: boolean;
  openCreditDebitNoteModal?: boolean;
  redirectCreateInvoice?: boolean;
  downloadFileEvidence?: object;
  transactionIdCollections?: string[];
  transactionTableCollections?: TransactionTable[];
  transactionTableCollectionsHistory?: InvoiceRecord;
  dispersionId?: string;
  chargeId?: string;
  annulDeadLineDate?: string;
  annulDeadLineValidate?: object;
  countryList?: string[];
  isAzure?: boolean;
  isLoading: boolean;
  openModalBackRetention: boolean;
  pdfRetention?: string;
  catalogsList?: Catalog[];
}

export const INITIAL_STATE: IBillingDashboardState = {
  billingDataCounter: { contador: 0, total: 0 },
  billingData: [],
  fileDownloadManualContingency: "",
  activeTabExecutor: 0,
  historicBillingData: { records: [], total: 0 },
  historicTrx: {},
  invoiceTrx: {},
  isLoadingBilling: true,
  isLoadingInvoice: true,
  pathLoadingProcessBilling: StatusFlowPath.DEFAULT,
  pathLoadingRetention: StatusFlowPath.DEFAULT,
  isLoadingHistoric: true,
  isLoadingUpdateDispersion: false,
  pagination: [],
  transactionTableFileDownload: {
    base64: "",
    isDownloading: false,
    isDownloadingCheck: false,
  },
  firebaseId: null,
  transactionsBillingFile: null,
  notification: {
    type: "success",
    open: false,
    message: "",
  },
  isLoadingDownloadFile: {
    isLoading: false,
    isDownloadingCheck: false,
  },
  fileDownloadEfact: "",
  isLoadingFileDownloadEfact: false,
  fileDownloadDetail: "",
  isLoadingFileDownloadDetail: false,
  isLoadingFileDownloadManualContingency: false,
  statusFlow: StatusFlowEnum.EXECUTOR,
  downloadPdf: {},
  downloadPdfLoading: false,
  isLoadingDeleteRetention: undefined,
  retentionData: {
    isLoading: false,
    records: [],
  },
  stepTimeLineData: { icon: "", title: "", viewDetail: false, value: {} },
  openModalDetailTimeLine: false,
  openModalModifyInconsistence: false,
  retentionsDataCounter: { counter: 0, total: 0 },
  processLoader: false,
  processData: false,
  openAnnulModal: false,
  openCreditDebitNoteModal: false,
  redirectCreateInvoice: false,
  downloadFileEvidence: {},
  transactionIdCollections: [],
  transactionTableCollections: [],
  chargeId: "",
  dispersionId: "",
  pdfRetention: "",
  isLoading: false,
  openModalBackRetention: false,
  countryList: [],
  catalogsList: [],
};

export const reducer = (
  state: IBillingDashboardState = INITIAL_STATE,
  action: BillingActions
): IBillingDashboardState => {
  switch (action.type) {
    case ActionTypes.SET_COUNTRY_LIST:
      return {
        ...state,
        countryList: action.countryList!,
        isAzure: true,
      };
    case ActionTypes.SET_RETENTIONS_PROCESS_LOADER:
      return {
        ...state,
        processLoader: action.processLoader!,
      };
    case ActionTypes.SET_BILLING_DATA:
      return {
        ...state,
        billingData: action.billingData!,
      };
    case ActionTypes.SET_BILLING_DATA_COUNTER:
      return {
        ...state,
        billingDataCounter: action.counter!,
      };
    case ActionTypes.SET_LOADING_BILLING:
      return {
        ...state,
        isLoadingBilling: action.setLoadingBilling!,
      };
    case ActionTypes.SET_LOADING_INVOICE:
      return {
        ...state,
        isLoadingInvoice: action.setLoadingInvoice!,
      };
    case ActionTypes.SET_PATH_LOADING_PROCESS_BILLING:
      return {
        ...state,
        pathLoadingProcessBilling: action.setPathLoadingProcessBilling!,
      };
    case ActionTypes.SET_PATH_LOADING_RETENTION:
      return {
        ...state,
        pathLoadingRetention: action.setPathLoadingRetention!,
      };
    case ActionTypes.SET_LOADING_HISTORIC:
      return {
        ...state,
        isLoadingHistoric: action.setLoadingHistoric!,
      };
    case ActionTypes.SET_BILLING_TRX_DATA:
      return {
        ...state,
        billingData: action.billingData!,
      };
    case ActionTypes.SET_HISTORIC_DATA:
      return {
        ...state,
        historicBillingData: action.historicBillingData!,
      };
    case ActionTypes.SET_INVOICE_TRX:
      return {
        ...state,
        invoiceTrx: action.invoiceTrx!,
      };
    case ActionTypes.SET_HISTORIC_TRX:
      return {
        ...state,
        historicTrx: action.historicTrx!,
      };
    case ActionTypes.SET_FIREBASE_ID:
      return {
        ...state,
        firebaseId: action.firebaseId!,
      };
    case ActionTypes.SET_TRANSACTION_TABLE_DOWNLOAD_FILE:
      return {
        ...state,
        transactionTableFileDownload: action.transactionTableFileDownload!,
      };
    case ActionTypes.SET_TRANSACTIONS_FILE:
      return {
        ...state,
        transactionsBillingFile: action.transactionsBillingFile!,
      };
    case ActionTypes.SET_PAGINATION:
      return {
        ...state,
        pagination: action.pagination!,
      };
    case ActionTypes.SET_COUNTRY:
      return {
        ...state,
        country: action.country!,
      };
    case ActionTypes.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.notification!,
      };
    case ActionTypes.SET_DOWNLOADFILE:
      return {
        ...state,
        fileDownloadEfact: action.fileDownloadEfact!,
      };
    case ActionTypes.SET_LOADINGDOWNLOADFILE:
      return {
        ...state,
        isLoadingFileDownloadEfact: action.setLoadingDownloadEfact!,
      };
    case ActionTypes.SET_ISLOADINGDOWNLOADFILE:
      return {
        ...state,
        isLoadingDownloadFile: action.setIsLoadingDownloadFile!,
      };
    case ActionTypes.SET_LOADINGDOWNLOAD_PREPROCESS:
      return {
        ...state,
        isLoadingFileDownloadDetail: action.setLoadingDownloadPreProcess!,
      };
    case ActionTypes.SET_LOADINGDOWNLOADMANUALCONTINGECY_PREPROCESS:
      return {
        ...state,
        isLoadingFileDownloadManualContingency: action.setLoadingDownloadManualContingencyPreProcess!,
      };
    case ActionTypes.SET_DOWNLOAD_FILE_MANUAL_CONTINGENCY:
      return {
        ...state,
        fileDownloadManualContingency: action.fileDownloadManualContingency!,
      };
    case ActionTypes.SET_DOWNLOAD_FILE_DETAIL:
      return {
        ...state,
        fileDownloadDetail: action.fileDownloadDetail!,
      };
    case ActionTypes.SET_STATUS_FLOW:
      return {
        ...state,
        statusFlow: action.statusFlow,
      };
    case ActionTypes.SET_DOWNLOAD_PDF:
      return {
        ...state,
        downloadPdf: action.downloadPdf,
      };
    case ActionTypes.SET_DOWNLOAD_PDF_LOADING:
      return {
        ...state,
        downloadPdfLoading: action.downloadPdfLoading!,
      };
    case ActionTypes.SET_IS_LOADING_DELETE_RETENTION:
      return {
        ...state,
        isLoadingDeleteRetention: action.isLoadingDeleteRetention!,
      };
    case ActionTypes.SET_RETENTION_DATA:
      return {
        ...state,
        retentionData: action.retentionData!,
      };
    case ActionTypes.SET_ACTIVE_TAB_EXECUTOR:
      return {
        ...state,
        activeTabExecutor: action.activeTabExecutor!,
      };
    case ActionTypes.SET_RETENTIONS_DATA_COUNTER:
      return {
        ...state,
        retentionsDataCounter: action.retentionsDataCounter!,
      };
    case ActionTypes.SET_STEP_TIME_LINE_DATA:
      return {
        ...state,
        stepTimeLineData: action.stepTimeLineData!,
      };
    case ActionTypes.SET_OPEN_MODAL_DETAIL_TIME_LINE:
      return {
        ...state,
        openModalDetailTimeLine: action.openModalDetailTimeLine!,
      };
    case ActionTypes.SET_OPEN_MODAL_MODIFY_INCONSISTENCE:
      return {
        ...state,
        openModalModifyInconsistence: action.openModalModifyInconsistence!,
      };
    case ActionTypes.SET_PROCESS_DATA:
      return {
        ...state,
        processData: action.processData!,
      };
    case ActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading!,
      };
    case ActionTypes.SET_OPEN_MODAL_BACK_RETENTION:
      return {
        ...state,
        openModalBackRetention: action.openModalBackRetention!,
      };
    case ActionTypes.SET_OPEN_MODAL_ANNUL:
      return {
        ...state,
        openAnnulModal: Boolean(action.openModalAnnul),
      };
    case ActionTypes.SET_OPEN_MODAL_CREDIT_DEBIT_NOTE:
      return {
        ...state,
        openCreditDebitNoteModal: Boolean(action.openModalCreditDebitNote),
      };
    case ActionTypes.SET_REDIRECT_CREATE_INVOICE:
      return {
        ...state,
        redirectCreateInvoice: Boolean(action.redirectCreateInvoice),
      };
    case ActionTypes.SET_DOWNLOAD_FILE_EVIDENCE:
      return {
        ...state,
        downloadFileEvidence: action.downloadFileEvidence!,
      };
    case ActionTypes.SET_TRANSACTIONID_ADD:
      return {
        ...state,
        transactionTableCollections: action.transaction?.data,
        transactionIdCollections: action.transaction?.id,
      };
    case ActionTypes.SET_TRANSACTIONIDHISTORY_ADD:
      return {
        ...state,
        transactionTableCollectionsHistory: action.transactionHistory?.data,
        transactionIdCollections: action.transactionHistory?.id,
      };
    case ActionTypes.SET_TRANSACTIONIDHISTORY_DEFAULT:
      return {
        ...state,
        transactionTableCollectionsHistory: [],
        transactionIdCollections: [],
      };
    case ActionTypes.SET_REPROCESS_COUNT:
      return {
        ...state,
        reprocessCount: action.reprocessCount,
      };
    case ActionTypes.SET_DISPERSION_ID:
      return {
        ...state,
        dispersionId: action.dispersionId,
      };
    case ActionTypes.SET_CHARGE_ID:
      return {
        ...state,
        chargeId: action.chargeId,
      };
    case ActionTypes.SET_ANNUL_DEAD_LINE:
      return {
        ...state,
        annulDeadLineDate: action.annulDeadLineDate,
      };
    case ActionTypes.SET_ANNUL_DEAD_LINE_VALIDATE:
      return {
        ...state,
        annulDeadLineValidate: action.annulDeadLineValidate,
      };
    case ActionTypes.SET_PDF_RETENTION:
      return {
        ...state,
        pdfRetention: action.pdfRetention,
      };
    case ActionTypes.SET_LOADING_UPDATE_DISPERSION:
      return {
        ...state,
        isLoadingUpdateDispersion: action.setLoadingUpdateDisperion!,
      };
    case ActionTypes.SET_CATALOG_LIST:
      return {
        ...state,
        catalogsList: action.catalogsList,
      };
    default:
      return state;
  }
};
