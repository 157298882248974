import React from "react";
import { Grid, Typography } from "@mui/material";
import { ILastModify } from "./LastModify.interfaces";
import { useLastModifyStyles as styles } from "./LastModify.styles";
import { isEmpty } from "lodash";
import { format } from "date-fns";
import { FormatDateEnum } from "../../../shared/enums/FormatDateEnum";

export const LastModify: React.FC<ILastModify> = (item: ILastModify) => {
  return (
    <Grid item xs={12} sx={styles.containerLastModify}>
      <Grid sx={styles.modifyBy}>
        {!isEmpty(item.author) && (
          <Typography sx={styles.text} data-testid="typography">
            Modificado Por: {item.author}
          </Typography>
        )}
      </Grid>
      <Grid>
        {item.date !== 0 && (
          <Typography sx={styles.text} data-testid="typography">
            {getDate(item.date)}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

function getDate(dateUnix: number): string {
  return format(new Date(dateUnix), FormatDateEnum.ddMMyyyyHHmm);
}

export default LastModify;
