import React from "react";
import { Grid, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { searchTextStyles as styles } from "./SearchText.styles";
import { FilterActionTypeEnum } from "../../shared/enum/FilterActionTypeEnum";
import { useSearchText } from "./state/useSearchText";

export interface searchTextProps {
  handleFilterMerchants: (type: FilterActionTypeEnum, value: string) => void;
  handleResetPage: () => void;
}

export const SearchText: React.FC<searchTextProps> = (
  props: searchTextProps
) => {
  const { formValue, handleSubmit, handleChange } = useSearchText(props);

  return (
    <Grid container item xs={10} sx={{ alignItems: "center" }}>
      <Grid item xs={8}>
        <form onSubmit={handleSubmit}>
          <TextField
            placeholder={"Buscar por nombre y ID..."}
            sx={styles.searchText}
            value={formValue}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon color={"primary"} />
                </InputAdornment>
              ),
            }}
            size="medium"
          />
        </form>
      </Grid>
    </Grid>
  );
};

export default SearchText;
