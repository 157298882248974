import { Box, Grid, Typography } from "@mui/material";
import { Spinner } from "../Spinner/Spinner";

export const LoadingComponent = () => (
  <Grid
    container
    sx={{
      alignItems: "center",
      flexDirection: "row",
      flexWrap: "nowrap",
      gap: "2rem",
      paddingTop: "3rem",
    }}
  >
    <Box sx={{ paddingLeft: "1rem" }}>
      <Spinner />
    </Box>
    <Typography>
      Estamos realizando el proceso de simulación de tu webhook
    </Typography>
  </Grid>
);
