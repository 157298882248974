import React from "react";
import {
  ColorSnackBarEnum,
  NotificationTypeEnum,
  Snackbar,
} from "../enums/snackbar";
import { isEmpty } from "lodash";
import { IconCheck, IconCircleWarn, ISnackBar } from "@kushki/connect-ui";
import { TransactionType } from "../enums/transaction_types";

export const successData: Partial<ISnackBar> = {
  color: ColorSnackBarEnum.SUCCESS,
  icon: <IconCheck />,
  message: Snackbar.REQUEST_SUCCESS,
  withIcon: true,
};

const failedData: Partial<ISnackBar> = {
  color: ColorSnackBarEnum.DANGER,
  icon: <IconCircleWarn />,
  message: Snackbar.REQUEST_ERROR,
  withIcon: true,
};

const CATALOG_SNACKBAR_DATA: Record<string, Partial<ISnackBar>> = {
  [NotificationTypeEnum.SUCCESS]: successData,
  [NotificationTypeEnum.FAILED]: failedData,
  [`${TransactionType.DEBIT}_${NotificationTypeEnum.SUCCESS}`]: {
    ...successData,
    message: Snackbar.REQUEST_DEBIT_SUCCESS,
  },
  [`${TransactionType.CREDIT}_${NotificationTypeEnum.SUCCESS}`]: {
    ...successData,
    message: Snackbar.REQUEST_CREDIT_SUCCESS,
  },
  [`${TransactionType.DEBIT}_${NotificationTypeEnum.FAILED}`]: {
    ...failedData,
    message: Snackbar.REQUEST_DEBIT_FAILED,
  },
  [`${TransactionType.CREDIT}_${NotificationTypeEnum.FAILED}`]: {
    ...failedData,
    message: Snackbar.REQUEST_CREDIT_FAILED,
  },
};

export const defaultDataSnackbar: ISnackBar = {
  color: ColorSnackBarEnum.SUCCESS,
  message: Snackbar.REQUEST_SUCCESS,
  variant: "simple",
  withIcon: false,
};

export const buildNotificationAddBalance = (
  notificationType: NotificationTypeEnum,
  transactionType?: TransactionType | "",
  messageValue?: string
): ISnackBar => {
  const path_index: string = `${
    isEmpty(transactionType) ? "" : transactionType + "_"
  }${notificationType}`;
  const notification_response: ISnackBar = {
    ...defaultDataSnackbar,
    ...CATALOG_SNACKBAR_DATA[path_index],
  } as ISnackBar;

  if (!isEmpty(messageValue))
    return { ...notification_response, message: messageValue! };

  return notification_response;
};
