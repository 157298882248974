import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Box,
  Button,
  createStyles,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import imgSettings from "../../../assets/images/⚙️settings.svg";
import { Download, Filter, Search } from "react-feather";
import { ButtonDownloadFile } from "../../ButtonDownloadFile/ButtonDownloadFile";
import { StatusFlowEnum } from "../../../shared/infrastructure/StatusFlowEnum";
import { ColumnFilters } from "../../DashboardList/ColumnFilters/ColumnFilters";
import { IColumnDefinition } from "../../../shared/infrastructure/interfaces/IColumnDefinition";
import { cloneDeep, get, has } from "lodash";
import BoxFilter, {
  IChipSelect,
} from "../../DashboardList/BoxFilter/BoxFilter";
import { IRetentionFilter } from "../../RetentionDashboard/state/useRetentionDashboardState";
import { ConfirmRetentionModal } from "../../DashboardList/ConfirmRetentionModal/ConfirmRetentionModal";
import { IDefault } from "../../../shared/infrastructure/interfaces/IDefault";
import { StatusEnum } from "../../../shared/infrastructure/StatusEnum";
import theme from "../../../theme";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { LoaderModal } from "../../LoaderModal/LoaderModal";
import {
  admittedColumnsEnum,
  IColumnsTable,
} from "../../../shared/infrastructure/table/IColumnsTable";
import { SmartlinkDatePicker } from "../../DatePicker/SmartlinkDatePicker";
import { DateRange } from "@material-ui/pickers";
import { RangeAmount } from "../../../../types/range_amount";
import { CountryList } from "../../DashboardList/Table/DashboardBillingTable/DashboardTableRow/DashboardTableRow";
import { CountryEnum } from "../../../shared/infrastructure/CountryEnum";
import {
  LoaderRetentionModalEnum,
  RetentionButtonLabelsEnum,
  StatusRetentionModalEnum,
} from "../../../shared/infrastructure/enum/RetentionTextValuesEnum";
import { ConfirmRetentionModalConstants } from "../../../shared/infrastructure/constants/ConfirmRetentionModalConstants";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { RolesRetentionEnum } from "../../../shared/infrastructure/enum/Roles/RolesEnum";

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      padding: 0,
      height: "36px",
      marginTop: "-1px",
      backgroundColor: "#fff",
      border: "none",
    },
    filterBtn: {
      backgroundColor: "#FFFFFF",
      color: "#023365",
      padding: "12px !important",
      minWidth: "9em",
    },
    marginLeft: {
      marginLeft: "18px",
      color: "#293036",
    },
    settingsBtn: {
      backgroundColor: "#fff",
      border: "none",
      maxWidth: "3em",
      minWidth: "3em",
      height: "36px",
    },
    settingsIcon: {
      color: "gray",
    },
    appBar: {
      backgroundColor: "#F7FAFC",
      boxShadow: "none",
    },
    toolbar: {
      padding: "0 12px !important",
    },
    noBorder: {
      border: "none",
    },
    td: {
      paddingTop: 0,
      paddingBottom: 0,
      width: 225,
    },
    divider: {
      width: 250,
      marginTop: 5,
      marginBottom: 3,
    },
    textTotal: {
      fontWeight: 600,
      color: "#293036",
    },
    description: {
      fontWeight: 400,
      fontSize: theme.spacing(1.875),
      color: "#293036",
      marginTop: "24px",
    },
    processModalInfo: {
      marginTop: theme.spacing(2),
      "&.MuiGrid-root .MuiSvgIcon-root": {
        margin: `0px ${theme.spacing(1)}px`,
      },
    },
    executeButton: {
      width: theme.spacing(14.25),
    },
  })
);

export interface IRetentionFiltersProps {
  queryInput: string;
  handleInputChange: (value: string) => void;
  handleSearchBar: (value: string) => void;
  statusFlow?: StatusFlowEnum;
  columnsSettings: {
    selectedColumns: string[];
    setSelectedColumns: (data: string[]) => void;
    handleAppliedColumnsFilters: (restore?: boolean) => void;
  };
  columns: IColumnDefinition[];
  filter: IRetentionFilter;
}

export interface RetentionsFilterComponentProps {
  retentionData: IDefault[];
  modalOverProcessRetention: {
    openProcessRetentionModal: boolean;
    handleCloseProcessRetentionsModal: () => void;
    handleAcceptProcessTransactions: () => void;
    handleProcessRetentionData: () => void;
    handleCloseProcessLoader: () => void;
    retentionsDataCounter: { counter: number; total: number };
    processLoader: boolean;
  };
  downloadFileRetention: {
    isLoading: boolean;
    downloadFile: () => void;
  };
  hasDateRange: boolean;
  handleSetDate?: (dateRange: DateRange) => void;
  range?: DateRange;
  country?: CountryList;
}

export type RetentionsFilterProps = IRetentionFiltersProps &
  RetentionsFilterComponentProps;

export const RetentionFilters: React.FC<RetentionsFilterProps> = (
  props: RetentionsFilterProps
) => {
  const classes = useStyles();
  const [
    anchorColumns,
    setAnchorColumns,
  ] = React.useState<HTMLButtonElement | null>(null);
  const handleClickColumns = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorColumns(event.currentTarget);
  };
  const handleCloseColumns = () => {
    setAnchorColumns(null);
  };
  const openColumns = Boolean(anchorColumns);
  const idButtonColumns = openColumns ? "filter-popover" : undefined;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const [auxColumnsSelected, setAuxColumnsSelected] = React.useState<string[]>(
    props.columnsSettings.selectedColumns
  );

  const [defaultSelectedColumns] = useState<string[]>(
    props.columnsSettings.selectedColumns
  );

  const {
    statusFlow,
    retentionData,
    modalOverProcessRetention: {
      retentionsDataCounter,
      processLoader,
      handleCloseProcessLoader,
      openProcessRetentionModal,
      handleAcceptProcessTransactions,
      handleCloseProcessRetentionsModal,
      handleProcessRetentionData,
    },
    downloadFileRetention,
  } = props;

  const openPopover = Boolean(anchorEl);
  const idPopover = openPopover ? "simple-popover" : undefined;

  const confirmModalTextValues = get(
    ConfirmRetentionModalConstants,
    statusFlow!
  );

  const [
    retentionsDataCounterCasted,
    setRetentionsDataCounterCasted,
  ] = useState<{ contador: number; total: number }>({
    contador: 0,
    total: 0,
  });
  useEffect(() => {
    setRetentionsDataCounterCasted({
      contador: retentionsDataCounter.counter,
      total: retentionsDataCounter.total,
    });
  }, [retentionsDataCounter]);
  useEffect(() => {
    setAuxColumnsSelected(props.columnsSettings.selectedColumns);
  }, [props.columnsSettings.selectedColumns]);
  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleChangeColumnCheck = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (props.country === CountryEnum.colombia) {
      if (event.target.checked) {
        setAuxColumnsSelected([...auxColumnsSelected, event.target.name]);
      } else {
        setAuxColumnsSelected([
          ...auxColumnsSelected.filter(
            (columnSelected) => columnSelected !== event.target.name
          ),
        ]);
      }
    } else {
      const column: string = event.target.name;
      let auxColumnsSelected: string[] = [
        ...props.columnsSettings.selectedColumns,
      ];
      const aux_selectable: IColumnsTable[] = cloneDeep(selectableColumns);
      if (!event.target.checked) {
        const index: number = auxColumnsSelected.findIndex(
          (col: string) => col === column
        );
        if (index !== -1) auxColumnsSelected.splice(index, 1);
      } else {
        if (
          props.columnsSettings.selectedColumns.length >
          admittedColumnsEnum.admittedColumnRetention
        )
          return;
        auxColumnsSelected = aux_selectable
          .filter((s) => {
            return (
              (props.columnsSettings.selectedColumns.includes(s.id) &&
                s.disabledFilter) ||
              (!s.disabledFilter && s.id === column)
            );
          })
          .map((x) => x.id);
      }
      setAuxColumnsSelected(auxColumnsSelected);
    }
  };
  const handleDisplayedColumnUser = () => {
    handleCloseColumns();
    props.columnsSettings.setSelectedColumns(auxColumnsSelected);
    props.columnsSettings.handleAppliedColumnsFilters();
  };
  const handleRestoreColumns = () => {
    handleCloseColumns();
    props.columnsSettings.handleAppliedColumnsFilters(true);
    setAuxColumnsSelected(defaultSelectedColumns);
  };
  const [selectableColumns] = useState<IColumnsTable[]>(
    props.columns
      .filter((column: IColumnDefinition) => column.filter)
      .map((column: IColumnDefinition) => {
        return {
          id: column.field,
          label: column.headerName,
          view: column.view,
          filter: column.filter,
          orderBy: "",
          disabled: false,
          disabledFilter: column.disabledFilter,
        } as IColumnsTable;
      })
  );

  const loadProcessResume = () => {
    let modify = retentionData.filter((value) => get(value, "isEdited", false))
      .length;
    const pending = retentionData.filter(
      (value) =>
        value.selectedStatus === StatusEnum.PENDING ||
        value.selectedStatus === StatusEnum.OMIT
    ).length;

    const process = retentionData.filter(
      (value) => value.selectedStatus === StatusEnum.PROCESS
    ).length;

    const reject = retentionData.filter(
      (value) => value.selectedStatus === StatusEnum.REJECT
    ).length;

    const slops = retentionData.filter(
      (value) =>
        value.selectedStatus === StatusEnum.PENDING ||
        value.selectedStatus === StatusEnum.OMIT ||
        value.selectedStatus === "" ||
        !has(value, "selectedStatus")
    ).length;
    return (
      <>
        <div>
          {statusFlow === StatusFlowEnum.VALIDATOR && (
            <>
              <table>
                <tr>
                  <td className={classes.td}>
                    <Typography variant="caption">
                      Retenciones aprobadas:
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="caption">{process}</Typography>
                  </td>
                </tr>
                <tr>
                  <td className={classes.td}>
                    <Typography variant="caption">
                      Retenciones rechazadas:
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="caption">{reject}</Typography>
                  </td>
                </tr>
                <tr>
                  <td className={classes.td}>
                    <Typography variant="caption">
                      Retenciones pendientes:
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="caption">{pending}</Typography>
                  </td>
                </tr>
              </table>
              <Divider className={classes.divider} />
              <table>
                <tr>
                  <td className={classes.td}>
                    <Typography variant="caption" className={classes.textTotal}>
                      Total a procesar:
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="caption" className={classes.textTotal}>
                      {process + reject + pending}
                    </Typography>
                  </td>
                </tr>
              </table>
              <Grid
                item
                justify="flex-start"
                alignItems="center"
                className={classes.description}
              >
                {StatusRetentionModalEnum.VALIDATOR_ALERT_MESSAGE}
              </Grid>
            </>
          )}
          {statusFlow === StatusFlowEnum.EXECUTOR && (
            <>
              <table>
                <tr>
                  <td className={classes.td}>
                    <Typography variant="caption">
                      Retenciones modificadas:
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="caption">{modify}</Typography>
                  </td>
                </tr>
                <tr>
                  <td className={classes.td}>
                    <Typography variant="caption">
                      Retenciones omitidas:
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="caption">{pending}</Typography>
                  </td>
                </tr>
              </table>
              <Divider className={classes.divider} />
              <table>
                <tr>
                  <td className={classes.td}>
                    <Typography variant="caption" className={classes.textTotal}>
                      Total a procesar:
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="caption" className={classes.textTotal}>
                      {process}
                    </Typography>
                  </td>
                </tr>
              </table>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                className={classes.processModalInfo}
              >
                <InfoOutlinedIcon htmlColor="#FBC748" />
                <Typography
                  variant={"overline"}
                  color={"secondary"}
                  align="justify"
                  className={classes.marginLeft}
                >
                  Te queda(n) {slops} retencion(es) pendiente(s) por revisar.
                </Typography>
              </Grid>
            </>
          )}
        </div>
      </>
    );
  };
  const handleDate = (date: DateRange | MouseEvent): void => {
    if (get(date, "movementX") === 0) {
      if (props.handleSetDate) props.handleSetDate([null, null]);
      return;
    }
    if (props.handleSetDate) props.handleSetDate(date as DateRange);
  };

  return (
    <Box style={{ flexGrow: 1 }}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Button
                    id={"Filter"}
                    startIcon={<Filter size={14} />}
                    variant="outlined"
                    className={classes.filterBtn}
                    size="small"
                    onClick={handleOpenPopover}
                  >
                    <Typography variant="body2">Filtrar</Typography>
                  </Button>
                  <BoxFilter
                    idPopover={idPopover}
                    openPopover={openPopover}
                    anchorEl={anchorEl}
                    filters={props.filter.filtersDashboard}
                    rangeAmount={props.filter.rangeAmount}
                    handleApplyFilters={(
                      optionsChip: IChipSelect[],
                      rangeAmount?: RangeAmount
                    ) => {
                      props.filter.handleGetFilters(optionsChip, rangeAmount);
                      handleClosePopover();
                    }}
                    handleClosePopover={handleClosePopover}
                    showAmount={props.country === CountryEnum.ecuador}
                  />
                </Grid>
                {props.hasDateRange ? (
                  <Grid item xs={5}>
                    <SmartlinkDatePicker
                      value={props.range}
                      disableFuture={false}
                      handleDate={handleDate}
                    />
                  </Grid>
                ) : (
                  <div />
                )}

                <Grid item xs={props.hasDateRange ? 4 : 5}>
                  <TextField
                    id="outlined-search"
                    value={props.queryInput}
                    key={"outlinedSearch"}
                    autoFocus
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Search
                            id="icon-outlined-search"
                            size={16}
                            onClick={() => {
                              props.handleSearchBar(props.queryInput);
                            }}
                            cursor={"pointer"}
                          />
                        </InputAdornment>
                      ),
                      classes: { notchedOutline: classes.noBorder },
                    }}
                    onKeyDown={(event: any) => {
                      if (event.key !== "Enter") return;
                      props.handleSearchBar(props.queryInput);
                    }}
                    onChange={(event: any) => {
                      props.handleInputChange(event.target.value);
                    }}
                    className={classes.textField}
                    variant={"outlined"}
                    type="search"
                    size={"small"}
                    margin={"none"}
                    placeholder={"Buscar..."}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={props.hasDateRange ? 1 : 2}>
                  <Button
                    id={"buttonSettings"}
                    className={classes.settingsBtn}
                    onClick={handleClickColumns}
                  >
                    <img src={imgSettings} />
                  </Button>
                  <ColumnFilters
                    openColumns={openColumns}
                    idButtonColumns={idButtonColumns}
                    anchorColumns={anchorColumns}
                    handleCloseColumns={handleCloseColumns}
                    selectedColumns={auxColumnsSelected}
                    handleChangeColumnCheck={handleChangeColumnCheck}
                    handleRestoreColumns={handleRestoreColumns}
                    handleDisplayedColumnUser={handleDisplayedColumnUser}
                    selectableColumns={selectableColumns}
                    title={`Elige ${admittedColumnsEnum.admittedColumnRetention} columnas para mostrar`}
                    admittedColumns={
                      admittedColumnsEnum.admittedColumnRetention
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={2} justify={"flex-end"}>
                <Grid item>
                  <ButtonDownloadFile
                    buttonV2={true}
                    icon={<Download size={18} />}
                    isLoading={downloadFileRetention.isLoading}
                    downloadFile={downloadFileRetention.downloadFile}
                    statusFlow={statusFlow}
                    isHistoric={false}
                    isRetention={true}
                  />
                </Grid>
                <Grid item>
                  <ComponentSecurityWrapper
                    componentId={RolesRetentionEnum.M_BILLING_RETENTION_EXECUTE}
                  >
                    <Button
                      variant={"contained"}
                      color={"primary"}
                      className={classes.executeButton}
                      onClick={handleProcessRetentionData}
                    >
                      {RetentionButtonLabelsEnum.EXECUTE}
                    </Button>
                  </ComponentSecurityWrapper>
                  <ConfirmRetentionModal
                    open={openProcessRetentionModal}
                    handleClose={handleCloseProcessRetentionsModal}
                    handleAccept={handleAcceptProcessTransactions}
                    titleText={confirmModalTextValues.title}
                    contentText={confirmModalTextValues.contentText}
                    acceptColor="primary"
                    acceptText={confirmModalTextValues.acceptText}
                  >
                    {loadProcessResume()}
                  </ConfirmRetentionModal>
                  <LoaderModal
                    open={processLoader}
                    message={LoaderRetentionModalEnum.LOADER_MAIN_MESSAGE}
                    message1={LoaderRetentionModalEnum.LOADER_SECONDARY_MESSAGE}
                    billingDataCounter={retentionsDataCounterCasted}
                    handleClose={handleCloseProcessLoader}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
