import { CountryEnum } from "../../../shared/enums/countryEnum";
import { ErrorEnum } from "../../../shared/enums/ErrorEnum";
import {
  IUseResumeContainerState,
  ResumeDataInterface,
} from "./IUseResumeContainerState.interfaces";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { RootState } from "../../../store/store";
import { setOpenModalCreate } from "../../../store/actions/resumeNode.actions";
import { createBranch } from "../../../store/thunks/resumeNode/resumenode.thunks";
import { CreateMerchantNodeRequest } from "../../../../types/create_merchant_node_request";
import { EntityNameEnum } from "../../../shared/enums/EntityNameEnum";
import { ClientTypeEnum } from "../../../shared/enums/ClientTypeEnum";

export const useResumeContainerState = (): IUseResumeContainerState => {
  const {
    openModalCreate,
    createBranchButton,
    openLoaderModal,
    validateBranchName,
    openConfirmationModal,
  } = useAppSelector((state: RootState) => state.resumeNode);
  const dispatch = useAppDispatch();

  const form = useForm<ResumeDataInterface>({
    defaultValues: {},
    mode: "onChange",
  });

  const closeModal = () => {
    dispatch(setOpenModalCreate(false));
    form.reset();
  };

  const openBranchModal = () => {
    dispatch(setOpenModalCreate(true));
  };

  //TODO COMPLETAR CON DATOS DEL NODO
  const createRequestBranch = (): CreateMerchantNodeRequest => {
    return {
      clientType: ClientTypeEnum.B2C,
      constitutionalCountry: CountryEnum.mexico,
      //TODO PONER DATOS REALES MERCHANT ID
      country: CountryEnum.mexico,
      entityName: EntityNameEnum.branch,
      name: form.getValues("nameBranch"),
      publicMerchantId: "2323232323-ALAND",
      //TODO PONER DATOS ROOTID
      rootId: "befdfc3747d3",
      userAlias: localStorage.getItem("fullName")!,
      userName: localStorage.getItem("username")!,
    };
  };

  const validateNameBranch = () => {
    dispatch(createBranch(createRequestBranch()));
  };

  useEffect(() => {
    if (validateBranchName) {
      form.setError("nameBranch", {
        message: ErrorEnum.repeated_name,
        type: "custom",
      });
    }
  }, [validateBranchName]);

  return {
    closeModal,
    createBranchButton,
    form,
    openBranchModal,
    openConfirmationModal,
    openLoaderModal,
    openModalCreate,
    validateNameBranch,
  };
};
