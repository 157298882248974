import { get } from "lodash";

export enum RolesEnum {
  BackofficeAdmin = "BackofficeAdmin",
  RiskManager = "RiskManager",
  RiskAnalyst = "RiskAnalyst",
}

export const validateRole = (role: RolesEnum): boolean => {
  const roles: object = JSON.parse(localStorage.getItem("roles")!);
  return get(roles, role, false);
};

export const idAdminRole = (): boolean => {
  const roles: object = JSON.parse(localStorage.getItem("roles")!);
  const admin_roles = [RolesEnum.BackofficeAdmin, RolesEnum.RiskManager];
  const found_role = admin_roles.find((role) => get(roles, role, false));
  return !!found_role;
};
