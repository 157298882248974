export enum LabelEnum {
  ADD_BUSINESS_RULE = "Agregar regla de negocio",
  ADD_CONDITION = "Agregar condición",
  BACK = "Regresar",
  CONDITIONS_TITLE = "Condiciones",
  DESCRIPTION_ENROLLMENT = "La categoría enrolamiento solo esta disponible para Transfiya",
  CONDITION_TITLE_ACCORDION = "Condición",
  SELECT_CATEGORY = "Seleccionar Categoría",
  SELECT_CONDITIONAL = "Seleccionar condicional",
}
