import { Box, Button, Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { footerStyles } from "./FooterOptions.styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckIcon from "@mui/icons-material/Check";
import { IFooterOptions } from "./FooterOptions.interfaces";
import {
  CONTINUE_TEXT,
  DEFAULT_IS_DISABLE_BUTTON,
  DEFAULT_IS_EDIT_LABEL,
  DEFAULT_IS_HIDDEN_BUTTON,
  DEFAULT_IS_HIDDEN_LABEL,
  EDITION_FINISH,
  SAVE_TEXT,
  SAVED_TEXT,
} from "../../shared/constants/labels/main_footer_labels";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

const FooterOptions: FC<IFooterOptions> = ({
  primaryButton = {
    isDisabled: DEFAULT_IS_DISABLE_BUTTON,
    isHidden: DEFAULT_IS_HIDDEN_BUTTON,
  },
  secondaryButton = {
    isDisabled: DEFAULT_IS_DISABLE_BUTTON,
    isHidden: DEFAULT_IS_HIDDEN_BUTTON,
  },
  isLoading,
  isMassive = false,
  label = {
    editDetail: DEFAULT_IS_EDIT_LABEL,
    isHidden: DEFAULT_IS_HIDDEN_LABEL,
    modify: "",
    text: "",
  },
  isOperation,
}: IFooterOptions) => {
  return (
    <Box sx={footerStyles.footer}>
      <Grid container spacing={0}>
        <Grid item xs={12} md={6}>
          <Box
            sx={
              isOperation
                ? footerStyles.buttonContainerOperationEdit
                : footerStyles.buttonContainer
            }
          >
            <ComponentSecurityWrapper
              componentId={primaryButton.componentIdSecurity}
            >
              <Button
                data-testid="idButtonTest"
                variant="outlined"
                sx={footerStyles.saveButton}
                disabled={secondaryButton.isDisabled || isLoading}
                onClick={secondaryButton.onAction}
                startIcon={isLoading && <CheckIcon />}
              >
                {isLoading ? SAVED_TEXT : SAVE_TEXT}
              </Button>
            </ComponentSecurityWrapper>
            <ComponentSecurityWrapper
              componentId={secondaryButton.componentIdSecurity}
            >
              {!primaryButton.isHidden && (
                <Button
                  variant="contained"
                  color={"primary"}
                  disabled={primaryButton.isDisabled}
                  onClick={primaryButton.onAction}
                  endIcon={<ArrowForwardIcon />}
                >
                  {isMassive ? EDITION_FINISH : CONTINUE_TEXT}
                </Button>
              )}
            </ComponentSecurityWrapper>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          {!label.isHidden && (
            <Box sx={footerStyles.labelContainer}>
              <Box sx={footerStyles.labelBox}>
                <Typography sx={footerStyles.label}>
                  Modificado por: {label.text}
                </Typography>
              </Box>
              <Box sx={footerStyles.labelBox}>
                <Typography sx={footerStyles.label}>{label.modify}</Typography>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default FooterOptions;
