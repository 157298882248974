import { IStyles } from "../../../shared/infrastructure/interfaces/Styles.interfaces";

export const footerStyles: IStyles = {
  footer: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#FAFCFD",
    position: "fixed",
    width: "100%",
    bottom: "0",
    left: "0",
    padding: {
      xs: "32px",
      md: "32px 196px",
    },
    filter:
      "drop-shadow(0 -8px 17px rgba(106, 167, 197, 0.15)) drop-shadow(0 -1.6px 2.7625px rgba(106, 167, 197, 0.075))",
    zIndex: 1,
  },
  buttonContainer: {
    display: "flex",
    flexWrap: "wrap",
    columnGap: "40px",
    rowGap: "20px",
  },
};
