export enum AlertEnum {
  ORIGIN_DEFAULT = "default",
  ORIGIN_GENERAL_RULE = "generalRule",
  PROCESSOR_HIERARCHY = "PROCESSOR_HIERARCHY",
  PROCESSOR_NOT_HIERARCHY = "PROCESSOR_NOT_HIERARCHY",
  ORIGIN_WHITE_LIST = "whiteList",
}

enum AlertMsgEnum {
  ERROR_MSG_DEFAULT = "Hubo un error al enviar la solicitud. Por favor vuelve a intentarlo.",
  SUCCESS_MSG_DEFAULT = "La solicitud fue enviada a revisión con éxito.",
  ERROR_MSG_GENERAL_RULE = "Hubo un error en la creación de la regla. Por favor vuelve a intentarlo.",
  ERROR_MSG_GENERAL_RULE_WITH_HIERARCHY = "Es necesario que el branch o customer tengan activos por lo menos un procesador de card.",
  ERROR_MSG_GENERAL_RULE_WITHOUT_HIERARCHY = "Es necesario que el comercio tenga activo por lo menos un procesador de card.",
  SUCCESS_MSG_GENERAL_RULE = "La regla ha sido creada con éxito",
  SUCCESS_MSG_WHITE_LIST = "La solicitud ha sido enviada correctamente.",
}

export const ERROR_MESSAGE_ALERT: Record<string, string> = {
  [AlertEnum.ORIGIN_DEFAULT]: AlertMsgEnum.ERROR_MSG_DEFAULT,
  [AlertEnum.ORIGIN_GENERAL_RULE]: AlertMsgEnum.ERROR_MSG_GENERAL_RULE,
  [AlertEnum.ORIGIN_WHITE_LIST]: AlertMsgEnum.ERROR_MSG_DEFAULT,
  [AlertEnum.PROCESSOR_HIERARCHY]:
    AlertMsgEnum.ERROR_MSG_GENERAL_RULE_WITH_HIERARCHY,
  [AlertEnum.PROCESSOR_NOT_HIERARCHY]:
    AlertMsgEnum.ERROR_MSG_GENERAL_RULE_WITHOUT_HIERARCHY,
};

export const SUCCESS_MESSAGE_ALERT: Record<string, string> = {
  [AlertEnum.ORIGIN_DEFAULT]: AlertMsgEnum.SUCCESS_MSG_DEFAULT,
  [AlertEnum.ORIGIN_GENERAL_RULE]: AlertMsgEnum.SUCCESS_MSG_GENERAL_RULE,
  [AlertEnum.ORIGIN_WHITE_LIST]: AlertMsgEnum.SUCCESS_MSG_WHITE_LIST,
};
