import { Box, Button, Grid } from "@mui/material";
import React, { FC } from "react";
import { INFORMATION_CUSTOMER_LABELS } from "../../shared/constants/labels/information_customer_container_labels";
import { ComponentIdsEnum } from "../../shared/constants/labels/main_containter_labels";
import { footerStyles } from "./FooterOptions.styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckIcon from "@mui/icons-material/Check";
import { IFooterOptions } from "./FooterOptions.interfaces";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

const FooterOptions: FC<IFooterOptions> = ({
  primaryButton = {
    isDisabled: false,
    isHidden: false,
  },
  secondaryButton = {
    isDisabled: false,
    isHidden: false,
  },
  isLoading,
  textBtn,
  isValidate,
}: IFooterOptions) => {
  return (
    <Box sx={footerStyles.footer}>
      <Grid container spacing={0}>
        <Grid item xs={12} sx={footerStyles.footerGrid}>
          <Box sx={footerStyles.buttonContainer}>
            {!secondaryButton.isHidden && (
              <ComponentSecurityWrapper
                componentId={ComponentIdsEnum.M_CLIENTS_BRANCH_MANUAL_REGRESAR}
              >
                <Button
                  data-testid="idButtonTest"
                  variant="outlined"
                  sx={footerStyles.returnButton}
                  disabled={secondaryButton.isDisabled}
                  onClick={() => secondaryButton.onAction!()}
                  startIcon={isLoading && <CheckIcon />}
                >
                  {INFORMATION_CUSTOMER_LABELS.BACK_BUTTON_LBL}
                </Button>
              </ComponentSecurityWrapper>
            )}
            <ComponentSecurityWrapper
              componentId={ComponentIdsEnum.M_CLIENTS_BRANCH_MANUAL_CONTINUAR}
            >
              <Button
                data-testid="idButtonContinue"
                variant="contained"
                disabled={primaryButton.isDisabled}
                onClick={() => primaryButton.onAction!()}
                endIcon={!isValidate ? <ArrowForwardIcon /> : null}
              >
                {textBtn}
              </Button>
            </ComponentSecurityWrapper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FooterOptions;
