/**
 * PaymentMethodLabel
 */
import { PaymentMethodEnum } from "../../../containers/NotificationsIndex/state/useNotificationsIndexState";

export const PAYMENT_METHOD_LABEL: Record<PaymentMethodEnum, string> = {
  [PaymentMethodEnum.CARD]: "Tarjeta",
  [PaymentMethodEnum.CASH]: "Pagos en efectivo",
  [PaymentMethodEnum.PAYOUTS_CASH]: "Cobros en efectivo",
  [PaymentMethodEnum.PAY_OUTS_TRANSFER]: "Transferencias de cobro",
  [PaymentMethodEnum.TRANSFER]: "Transferencias de pago",
  [PaymentMethodEnum.TRANSFER_SUBSCRIPTIONS]: "Débito recurrente",
  [PaymentMethodEnum.WEBCHECKOUT]: "Smartlink",
  [PaymentMethodEnum.CARD_CHARGEBACK]: "Devoluciones",
  [PaymentMethodEnum.VPOS]: "Virtual POS",
  [PaymentMethodEnum.SUBCRIPTIONS]: "Subscripciones",
};
