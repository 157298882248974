import { makeStyles } from "@material-ui/core/styles";
import { Palette } from "../../themeui";

export const emptyTableStyles = makeStyles({
  container: {
    alignItems: "center",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    height: "70%",
    justifyContent: "center",
    padding: "200px 0px",
    width: "100%",
  },
  title: {
    color: Palette.primary.main,
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "19.2px",
    marginBottom: "8px",
  },
  subTitle: {
    color: Palette.text.grey,
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "19.2px",
    marginBottom: "20px",
  },
});
