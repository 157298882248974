import React from "react";
import { Grid, TextField, MenuItem } from "@mui/material";
import { Controller } from "react-hook-form";
import { defaultTo, get, isEmpty } from "lodash";
import { ErrorsEnum } from "../../../../shared/infrastructure/enums/ErrorEnum";
import { retrieveFormError } from "../../../../utils/utilsFile";
import {
  Continent,
  Country,
  Region,
} from "../../../../../types/geography_list";
import { SelectCheck } from "@kushki/connect-ui";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";
import {
  Labels,
  PlaceHolders,
} from "../../../../shared/infrastructure/constants/BusinessRuleConstants";
import { ICountryConditionSectionProps } from "../../../../shared/infrastructure/interfaces/ICountryConditionSectionProps";
import { useCountryConditionSectionState } from "./state/useCountryConditionSectionState";

export const CountryConditionSection: React.FC<
  ICountryConditionSectionProps
> = (props: ICountryConditionSectionProps) => {
  const {
    control,
    continents,
    regions,
    errors,
    geographyList,
    selectedRegionAccumulator,
    countryToShowEdit,
    referenceCode,
    actions,
  } = useCountryConditionSectionState(props);

  return (
    <React.Fragment>
      <Grid item container direction={"row"} spacing={1}>
        <Grid item xs={4}>
          <Controller
            name={"continents"}
            control={control}
            rules={{
              required: {
                message: ErrorsEnum.REQUIRED_FIELD,
                value: true,
              },
            }}
            render={({ ...selectProps }) => (
              <TextField
                {...selectProps}
                fullWidth
                select
                label={
                  isEmpty(continents)
                    ? PlaceHolders.CONTINENT_VALUE
                    : Labels.CONTINENT
                }
                error={get(errors, "continents")}
                onChange={(event) => {
                  actions.setValue("continents", [event.target.value]);
                }}
                helperText={retrieveFormError(errors, "continents")[1]}
              >
                {defaultTo(get(geographyList, "continents"), []).map(
                  (item: Continent) => {
                    return (
                      <MenuItem
                        key={`item-${get(item, "code", "")}`}
                        value={get(item, "name", "")}
                      >
                        {get(item, "title", "")}
                      </MenuItem>
                    );
                  }
                )}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name={"regions"}
            control={control}
            render={({ ...selectProps }) => (
              <SelectCheck
                {...selectProps}
                onChangeTextField={() => {
                  actions.setSelectedRegionAccumulator(
                    selectedRegionAccumulator + 1
                  );
                }}
                isLoading={false}
                error={!isEmpty(continents) && isEmpty(regions)}
                helperText={
                  !isEmpty(continents) && isEmpty(regions)
                    ? ErrorsEnum.REQUIRED_FIELD
                    : ""
                }
                isActiveSelectAll={false}
                onItemSelect={(items: any[]) => {
                  actions.setValue(
                    "regions",
                    items.map((item) => item.value)
                  );
                }}
                placeholder={PlaceHolders.REGION_VALUE}
                type="withoutCategory"
                items={
                  actions.isRenderEditValues()
                    ? defaultTo(get(geographyList, "regions"), [])
                        .filter((data: Region) => data.code === referenceCode)
                        .map((item: Region) => ({
                          label: get(item, "title", ""),
                          selected: true,
                          value: get(item, "name", ""),
                        }))
                    : defaultTo(get(geographyList, "regions"), []).reduce(
                        (filtered: ICategory[], item: Region) => {
                          const selectedContinentCode = defaultTo(
                            get(geographyList, "continents"),
                            []
                          ).find(
                            (continent) =>
                              continent.name ===
                              get(Array.from(continents), "0", "")
                          )?.code;
                          if (
                            selectedContinentCode ===
                            get(item, "referenceCode", "")
                          ) {
                            const someNewValue = {
                              label: get(item, "title", ""),
                              selected: false,
                              value: get(item, "name", ""),
                            };
                            filtered.push(someNewValue);
                          }
                          return filtered;
                        },
                        []
                      )
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name={`condition.${props.index}.value`}
            control={control}
            render={({ ...selectProps }) => (
              <SelectCheck
                {...selectProps}
                onChangeTextField={() => {}}
                isActiveSelectAll={false}
                isLoading={false}
                onItemSelect={(items) => {
                  actions.setValue(
                    `condition.${props.index}.value`,
                    items.map((item) => {
                      return item.value;
                    })
                  );
                  actions.setCountryEdited(
                    items.map((item) => {
                      return item.value;
                    })
                  );
                }}
                placeholder={PlaceHolders.COUNTRY_VALUE}
                type="withoutCategory"
                items={
                  actions.isRenderEditValues()
                    ? countryToShowEdit
                    : defaultTo(get(geographyList, "countries"), []).reduce(
                        (filtered: ICategory[], item: Country) => {
                          const selectedRegionCodes: string[] = defaultTo(
                            get(geographyList, "regions"),
                            []
                          ).reduce((regionCodes: string[], region) => {
                            if (
                              Array.from(regions).includes(region.name) &&
                              region.code
                            ) {
                              regionCodes.push(region.code);
                            }
                            return regionCodes;
                          }, []);
                          if (
                            selectedRegionCodes.includes(
                              get(item, "referenceCode", "")
                            )
                          ) {
                            const someNewValue = {
                              label: get(item, "title", ""),
                              selected: false,
                              value: get(item, "name", ""),
                            };
                            filtered.push(someNewValue);
                          }
                          return filtered;
                        },
                        []
                      )
                }
              />
            )}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
