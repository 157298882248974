import React from "react";
import { RatesConfigDynamo } from "../../../../../types/rates_config_dynamo";
import { Grid, Typography } from "@mui/material";
import { get, isUndefined } from "lodash";
import {
  CardPaymentTypeEnum,
  CardRegionEnum,
  CardTypeEnum,
  getPaymentMethodForProcessor,
  PaymentMethodEnum,
  RatePaymentTypeEnum,
} from "../../../../shared/enums/PaymentMethodEnum";
import { ratesInfoStyles as style } from "../../RatesInfo.styles";
import { ICON_CARD_NAME } from "../../../../shared/utils/IconCardName";
import { RatesConditionsLabelEnum } from "../../../../shared/enums/RatesConditionsLabelEnum";
export interface RatesConditionsPropsInterface {
  rate: RatesConfigDynamo;
}

export const RatesConditions: React.FC<RatesConditionsPropsInterface> = (
  props: RatesConditionsPropsInterface
) => {
  const { rate } = props;

  const validatePaymentType = (paymentType: string): string => {
    switch (paymentType) {
      case RatePaymentTypeEnum.PAYIN:
        return RatesConditionsLabelEnum.FUNDRAISING;
      case RatePaymentTypeEnum.PAYOUT:
        return RatesConditionsLabelEnum.DISPERSAL_MONEY;
      default:
        return RatesConditionsLabelEnum.ADDITIONAL_SERVICES;
    }
  };
  const validateProcessorName = (
    processorName: string[],
    paymentMethod: string[]
  ): string => {
    let processors = [];

    for (let i = 0; i < processorName.length; i++) {
      processors.push(
        `Red 0${i + 1} ${getPaymentMethodForProcessor(paymentMethod[0])}`
      );
    }

    return processors.join(", ");
  };
  const showProcessors = (
    paymentMethod: string[],
    processorName: string[]
  ): boolean => {
    return (
      (paymentMethod.includes(PaymentMethodEnum.CARD) ||
        paymentMethod.includes(PaymentMethodEnum.CASH) ||
        paymentMethod.includes(PaymentMethodEnum.TRANSFER)) &&
      processorName.length > 0
    );
  };
  const validateCardType = (cardTypes: string[]): string => {
    let cardType = [];

    for (let i = 0; i < cardTypes.length; i++) {
      if (cardTypes[i] === CardTypeEnum.CREDIT) {
        cardType.push(RatesConditionsLabelEnum.CREDIT);
      } else if (cardTypes[i] === CardTypeEnum.DEBIT) {
        cardType.push(RatesConditionsLabelEnum.DEBIT);
      }
    }

    return cardType.join(", ");
  };
  const validateCardPaymentType = (cardPaymentTypes: string[]): string => {
    let cardPaymentType = [];

    for (let i = 0; i < cardPaymentTypes.length; i++) {
      if (cardPaymentTypes[i] === CardPaymentTypeEnum.CURRENT) {
        cardPaymentType.push(RatesConditionsLabelEnum.CURRENT);
      } else if (cardPaymentTypes[i] === CardPaymentTypeEnum.DEFERRED) {
        cardPaymentType.push(RatesConditionsLabelEnum.DEFERRED);
      }
    }

    return cardPaymentType.join(", ");
  };
  const validateRegion = (regions: string[]): string => {
    let region = [];

    for (let i = 0; i < regions.length; i++) {
      if (regions[i] === CardRegionEnum.NATIONAL) {
        region.push(RatesConditionsLabelEnum.NATIONAL);
      } else if (regions[i] === CardRegionEnum.INTERNATIONAL) {
        region.push(RatesConditionsLabelEnum.INTERNATIONAL);
      }
    }

    return region.join(", ");
  };
  const firstLetterUpperCase = (word: string): string => {
    let wordLowerCase = word.toLowerCase();

    return wordLowerCase.charAt(0).toUpperCase() + wordLowerCase.slice(1);
  };

  const processors: string[] = get(rate, "processorName", []);
  const cardType: string[] = get(rate, "cardType", []);
  const cardPaymentType: string[] = get(rate, "cardPaymentType", []);
  const fees: number[] = get(rate, "fees", []);
  const cardBrand: string[] = get(rate, "cardBrand", []);
  const region: string[] = get(rate, "region", []);
  const paymentMethod: string[] = get(rate, "paymentMethod", []);

  return (
    <Grid container spacing={2} data-testid={"rates-conditions"}>
      <Grid item xs={12}>
        <Typography variant={"body2"} sx={style.rangesLabelResp}>
          {RatesConditionsLabelEnum.CONDITIONS}
        </Typography>
      </Grid>

      <Grid xs={12} container sx={style.textTable}>
        <Grid item xs={4} sx={style.conditionsTextLight}>
          {RatesConditionsLabelEnum.RATE_TYPE}
        </Grid>
        <Grid item xs={8} sx={style.conditionsTextInfo}>
          {validatePaymentType(rate.paymentType)}
        </Grid>
      </Grid>
      {showProcessors(paymentMethod, processors) && (
        <Grid xs={12} container sx={style.textTable}>
          <Grid item xs={4} sx={style.conditionsTextLight}>
            {RatesConditionsLabelEnum.PROCESSOR}
          </Grid>
          <Grid item xs={8} sx={style.conditionsTextInfo}>
            {validateProcessorName(processors, paymentMethod)}
          </Grid>
        </Grid>
      )}
      {paymentMethod.includes(PaymentMethodEnum.CARD) && cardType.length > 0 && (
        <>
          <Grid xs={12} container sx={style.textTable}>
            <Grid item xs={4} sx={style.conditionsTextLight}>
              {RatesConditionsLabelEnum.TYPE_OR_PAYMENT_METHOD}
            </Grid>
            <Grid item xs={8} sx={style.conditionsTextInfo}>
              {validateCardType(cardType)}
            </Grid>
          </Grid>

          {cardPaymentType.length > 0 && (
            <Grid xs={12} container sx={style.textTable}>
              <Grid item xs={4} sx={style.conditionsTextLight}>
                {RatesConditionsLabelEnum.PAYMENT_TYPE}
              </Grid>
              <Grid item xs={8} sx={style.conditionsTextInfo}>
                {validateCardPaymentType(cardPaymentType)}
              </Grid>
            </Grid>
          )}

          {cardPaymentType.includes(CardPaymentTypeEnum.DEFERRED) && (
            <>
              <Grid xs={12} container sx={style.textTable}>
                <Grid item xs={4} sx={style.conditionsTextLight}>
                  {RatesConditionsLabelEnum.CONSORTIUM}
                </Grid>
                <Grid item xs={8} sx={style.conditionsCapitalica}>
                  {rate.partnership?.values()}
                </Grid>
              </Grid>

              {cardPaymentType.length > 0 && (
                <Grid xs={12} container sx={style.textTable}>
                  <Grid item xs={4} sx={style.conditionsTextLight}>
                    {RatesConditionsLabelEnum.DUES}
                  </Grid>
                  <Grid item xs={8}>
                    {fees.join(", ")}
                  </Grid>
                </Grid>
              )}
            </>
          )}

          {cardBrand.length > 0 && (
            <Grid xs={12} container sx={style.textTable}>
              <Grid item xs={4} sx={style.conditionsTextLight}>
                {RatesConditionsLabelEnum.CARD_BRAND}
              </Grid>
              <Grid item xs={8} sx={style.baseGrid}>
                {cardBrand.map((brand) => ICON_CARD_NAME[brand])}
              </Grid>
            </Grid>
          )}
          {region.length > 0 && (
            <Grid xs={12} container sx={style.textTable}>
              <Grid item xs={4} sx={style.conditionsTextLight}>
                {RatesConditionsLabelEnum.REGION}
              </Grid>
              <Grid item xs={8} sx={style.conditionsTextInfo}>
                {validateRegion(region)}
              </Grid>
            </Grid>
          )}
        </>
      )}

      {paymentMethod.includes(PaymentMethodEnum.CASH) && (
        <>
          {!isUndefined(rate.model) && (
            <Grid xs={12} container sx={style.textTable}>
              <Grid item xs={4} sx={style.conditionsTextLight}>
                {RatesConditionsLabelEnum.MODEL}
              </Grid>
              <Grid item xs={8} sx={style.conditionsTextInfo}>
                {firstLetterUpperCase(rate.model)}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
