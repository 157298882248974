import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import { environment } from "./environments/environment";
import { ComponentIdEnum } from "./shared/infrastructure/enums/ComponentIdEnum";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
import { AppRoutes } from "./shared/infrastructure/routes";
import { BusinessRulesIndex } from "./containers/BusinessRulesIndex/BusinessRulesIndex";
import { CreateBusinessRuleIndex } from "./containers/CreateBusinessRuleIndex/CreateBusinessRuleIndex";
import { BusinessRulesListIndex } from "./containers/BusinessRulesList/BusinessRulesListIndex";

const Navigation: React.FC = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <ModuleSecurityWrapper
          basePath={environment.kushkiUrl}
          componentId={ComponentIdEnum.M_FRAUD_PREVENTION}
        >
          <Routes>
            <Route
              path={AppRoutes.CREATE_BUSINESS_RULE}
              element={<CreateBusinessRuleIndex />}
            />
            <Route
              path={AppRoutes.LIST_BUSINESS_RULES}
              element={<BusinessRulesListIndex />}
            />
            <Route
              path={AppRoutes.EDIT_BUSINESS_RULE}
              element={<CreateBusinessRuleIndex />}
            />
            <Route
              path={AppRoutes.DETAIL_BUSINESS_RULE}
              element={<BusinessRulesIndex />}
            />
            <Route
              path={AppRoutes.LIST_BUSINESS_RULES_BATCH}
              element={<BusinessRulesListIndex isBatch={true} />}
            />
          </Routes>
        </ModuleSecurityWrapper>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default connect()(Navigation);
