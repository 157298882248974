export enum StatusEnum {
  PENDING = "pending",
  REJECTED = "rejected",
  ENABLED_DISABLED = "enabled|disabled",
}

export enum ConfigTypeEnum {
  WHITE_LIST = "whitelist",
  FIRST_LEVEL = "first_level",
}
