import React, { FC } from "react";
import { ROUTES } from "../../shared/constants/routes";
import FormWrapper from "../../components/FormWrapper/FormWrapper";
import { useMassiveBillingContainer } from "./state/useMassiveBillingContainer";
import BillingForm from "../../components/BillingForm/BillingForm";
import WrapperMassiveContainer from "../../components/WrapperMassiveContanier/WrapperMassiveContainer";

const MassiveBillingContainer: FC = () => {
  const { form } = useMassiveBillingContainer();

  return (
    <WrapperMassiveContainer currentRoute={ROUTES.MASSIVE_BILLING}>
      <FormWrapper title={"Facturación"}>
        <BillingForm control={form.control} errors={form.errors} />
      </FormWrapper>
    </WrapperMassiveContainer>
  );
};

export default MassiveBillingContainer;
