import { ActionTypes } from "./actionTypes";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { TransactionDynamo } from "../../types/remote/transaction_dynamo";
import { environment } from "../environments/environment";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "../shared/axios-util";
import { TransactionTable } from "../../types/transaction_table";
import { IBillingDashboardState, INotification } from "./reducer";
import { Filters } from "../../types/filters";
import {
  InvoiceRecord,
  ResponseSearchBillingByFilters,
} from "../../types/remote/response_search_blling_by_filters";
import { databaseRef } from "../shared/firebase";
import { ITransactionFile } from "../../types/transaction_file";
import { RequestTransaction } from "../../types/transactions_process_request";
import { auth } from "../shared/auth";
import { format } from "date-fns";
import {
  InitialStatusEnum,
  StatusEnum,
} from "../shared/infrastructure/StatusEnum";
import { RequestStatusEnum } from "../shared/infrastructure/RequestStatusEnum";
import { CountryEnum } from "../shared/infrastructure/CountryEnum";
import {
  defaultTo,
  filter,
  get,
  has,
  includes,
  isEmpty,
  isEqual,
  isNil,
  map,
  omit,
  reduce,
  set,
  sortBy,
  split,
  uniq,
  unset,
} from "lodash";
import { SearchBillingRequest } from "../../types/search_billing_request";
import { KindEnum } from "../shared/infrastructure/KindEnum";
import { ITransactionDownloadFile } from "../../types/transaction_download_file";
import { StatusWebsocketEnum } from "../shared/infrastructure/StatusWebsocketEnum";
import { ReprocessCountResponse } from "../../types/reprocess_count_response";

import {
  ProcessTransactionActionsEnum,
  StatusFlowEnum,
  StatusFlowPath,
} from "../shared/infrastructure/StatusFlowEnum";
import { Dispatch } from "react";
import { DataWebsocket } from "../../types/data_websocket";
import { DownloadInvoicePdf } from "../../types/download_invoice_pdf";
import { InvoiceAnnulRequest } from "../../types/invoice_annul_request";
import { CreditNoteRequest } from "../../types/credit_note_request";
import { getKindRoleCountryCatalog } from "../shared/infrastructure/KindCatalog";
import { InvoiceModeEnum } from "../shared/infrastructure/InvoiceModeEnum";
import { InvoiceTransaction } from "../../types/invoice_transaction";
import { RenewInvoiceRequest } from "../../types/renew_invoice_request";
import { UserRolesEnum } from "../shared/infrastructure/UserRolesEnum";
import { MessageNotificationEnum } from "../shared/infrastructure/MessageNotificationEnum";
import { EvidenceData } from "../../types/evidence_data";
import { RentetionData } from "../../types/retention_data";
import { RentetionDataRequest } from "../../types/retention_data_request";
import { RetentionFileRequest } from "../../types/get_retention_file_request";
import { IStepRetentionTimeLine } from "../shared/infrastructure/interfaces/IStepRetentionTimeLine";
import { RetentionDetailFileRequest } from "../../types/retention_detail_file_request";
import { TransactionTypeEnum } from "../shared/infrastructure/TransactionTypeEnum";
import { ListRetentionRequest } from "../../types/get_list_retention_request";
import { StatusTransactionEnum } from "../shared/StatusTransactionEnum";
import { KindRetentionEnum } from "../shared/infrastructure/KindRetentionEnum";
import { TransactionsFileFilters } from "../../types/transactions_file_filters";
import moment from "moment";
import { DownloadDocumentParams } from "../components/DashboardList/Table/DashboardHistoricTable/DashboardHistoricTable";
import { DownloadManualContingency } from "../../types/download_manual_contingency";
import { Catalog } from "../../types/catalog_response";
import { CatalogRequest } from "../../types/catalog_request";

export type ITransactionDownloadFileHistoric = {
  isLoading: boolean;
  isDownloadingCheck: boolean;
};

export interface BillingActions {
  type: string;
  setLoadingBilling?: boolean;
  setLoadingInvoice?: boolean;
  setPathLoadingProcessBilling?: StatusFlowPath;
  setPathLoadingRetention?: StatusFlowPath;
  setLoadingHistoric?: boolean;
  billingData?: TransactionTable[];
  counter?: { contador: number; total: number };
  historicBillingData?: ResponseSearchBillingByFilters;
  historicTrx?: InvoiceRecord;
  invoiceTrx?: InvoiceTransaction;
  pagination?: object[];
  firebaseId?: string;
  transactionsBillingFile?: ITransactionFile;
  country?: string;
  notification?: INotification;
  setLoadingUpdateDisperion?: boolean;
  transactionTableFileDownload?: ITransactionDownloadFile;
  fileDownloadEfact?: string;
  setLoadingDownloadEfact?: boolean;
  setIsLoadingDownloadFile?: ITransactionDownloadFileHistoric;
  fileDownloadDetail?: string;
  fileDownloadManualContingency?: string;
  setLoadingDownloadPreProcess?: boolean;
  setLoadingDownloadManualContingencyPreProcess?: boolean;
  statusFlow?: StatusFlowEnum;
  reprocessCount?: number;
  downloadPdf?: DownloadInvoicePdf;
  downloadPdfLoading?: boolean;
  openModalAnnul?: boolean;
  openModalCreditDebitNote?: boolean;
  redirectCreateInvoice?: boolean;
  downloadFileEvidence?: object;
  transaction?: {
    id: string[];
    data: TransactionTable[];
  };
  transactionHistory?: {
    id: string[];
    data: InvoiceRecord;
  };
  isLoadingDeleteRetention?: boolean;
  retentionData?: {
    isLoading: boolean;
    records: TransactionDynamo[];
  };
  activeTabExecutor?: number;
  stepTimeLineData?: IStepRetentionTimeLine;
  processLoader?: boolean;
  retentionsDataCounter?: { counter: number; total: number };
  openModalDetailTimeLine?: boolean;
  openModalModifyInconsistence?: boolean;
  processData?: boolean;
  dispersionId?: string;
  chargeId?: string;
  annulDeadLineDate?: string;
  annulDeadLineValidate?: object;
  isLoading?: boolean;
  openModalBackRetention?: boolean;
  pdfRetention?: string;
  countryList?: string[];
  catalogsList?: Catalog[];
}

export const setProcessLoader = (payload: boolean): BillingActions => {
  return {
    type: ActionTypes.SET_RETENTIONS_PROCESS_LOADER,
    processLoader: payload,
  };
};

export const setIsLoading = (payload: boolean): BillingActions => {
  return {
    type: ActionTypes.SET_IS_LOADING,
    isLoading: payload,
  };
};

export const setOpenModalBackRetention = (payload: boolean): BillingActions => {
  return {
    type: ActionTypes.SET_OPEN_MODAL_BACK_RETENTION,
    openModalBackRetention: payload,
  };
};

export const setHistoricTrx = (payload: InvoiceRecord): BillingActions => {
  return {
    type: ActionTypes.SET_HISTORIC_TRX,
    historicTrx: payload,
  };
};

export const setInvoiceTrx = (payload: InvoiceTransaction): BillingActions => {
  return {
    type: ActionTypes.SET_INVOICE_TRX,
    invoiceTrx: payload,
  };
};

export const setLoadingBilling = (payload: boolean): BillingActions => {
  return {
    type: ActionTypes.SET_LOADING_BILLING,
    setLoadingBilling: payload,
  };
};
export const setReprocessCount = (payload: number): BillingActions => {
  return {
    type: ActionTypes.SET_REPROCESS_COUNT,
    reprocessCount: payload,
  };
};

export const setActiveTabExecutor = (payload: number): BillingActions => {
  return {
    type: ActionTypes.SET_ACTIVE_TAB_EXECUTOR,
    activeTabExecutor: payload,
  };
};
export const setRetentionData = (payload: RentetionData): BillingActions => {
  return {
    type: ActionTypes.SET_RETENTION_DATA,
    retentionData: payload,
  };
};
export const setRetentionDataPreProcessed = (
  payload: RentetionData
): BillingActions => ({
  type: ActionTypes.SET_RETENTION_DATA,
  retentionData: payload,
});
export const setPathLoadingProcessBilling = (
  payload: StatusFlowPath
): BillingActions => {
  return {
    type: ActionTypes.SET_PATH_LOADING_PROCESS_BILLING,
    setPathLoadingProcessBilling: payload,
  };
};

export const setPathLoadingRetention = (
  payload: StatusFlowPath
): BillingActions => {
  return {
    type: ActionTypes.SET_PATH_LOADING_RETENTION,
    setPathLoadingRetention: payload,
  };
};

export const setTransactionTableDownloadFile = (
  requestData: ITransactionDownloadFile
): BillingActions => {
  return {
    type: ActionTypes.SET_TRANSACTION_TABLE_DOWNLOAD_FILE,
    transactionTableFileDownload: requestData,
  };
};

export const setDownloadFile = (requestData: string): BillingActions => {
  return {
    type: ActionTypes.SET_DOWNLOADFILE,
    fileDownloadEfact: requestData,
  };
};

export const setLoadingDownloadEfact = (
  loadingState: boolean
): BillingActions => {
  return {
    type: ActionTypes.SET_LOADINGDOWNLOADFILE,
    setLoadingDownloadEfact: loadingState,
  };
};

export const setIsDownloadingFile = (
  loadingState: ITransactionDownloadFileHistoric
): BillingActions => {
  return {
    type: ActionTypes.SET_ISLOADINGDOWNLOADFILE,
    setIsLoadingDownloadFile: loadingState,
  };
};

export const setDownloadFileDetail = (requestData: string): BillingActions => {
  return {
    type: ActionTypes.SET_DOWNLOAD_FILE_DETAIL,
    fileDownloadDetail: requestData,
  };
};

export const setDownloadManualContingency = (data: string): BillingActions => {
  return {
    type: ActionTypes.SET_DOWNLOAD_FILE_MANUAL_CONTINGENCY,
    fileDownloadManualContingency: data,
  };
};

export const setLoadingDownloadPreProcess = (
  loadingState: boolean
): BillingActions => {
  return {
    type: ActionTypes.SET_LOADINGDOWNLOAD_PREPROCESS,
    setLoadingDownloadPreProcess: loadingState,
  };
};

export const setLoadingDownloadManualContingencyPreProcess = (
  loadingState: boolean
): BillingActions => {
  return {
    type: ActionTypes.SET_LOADINGDOWNLOADMANUALCONTINGECY_PREPROCESS,
    setLoadingDownloadManualContingencyPreProcess: loadingState,
  };
};

export const setLoadingHistoric = (payload: boolean): BillingActions => {
  return {
    type: ActionTypes.SET_LOADING_HISTORIC,
    setLoadingHistoric: payload,
  };
};

export const setLoadingUpdateDisperion = (payload: boolean): BillingActions => {
  return {
    type: ActionTypes.SET_LOADING_UPDATE_DISPERSION,
    setLoadingUpdateDisperion: payload,
  };
};

export const setLoadingInvoice = (payload: boolean): BillingActions => {
  return {
    type: ActionTypes.SET_LOADING_INVOICE,
    setLoadingInvoice: payload,
  };
};

export const setProcessData = (payload: boolean): BillingActions => {
  return {
    type: ActionTypes.SET_PROCESS_DATA,
    processData: payload,
  };
};

export const setBillingDataByOrder = (payload: TransactionTable[]) => {
  return {
    type: ActionTypes.SET_BILLING_DATA,
    billingData: payload,
  };
};

export const setCountryList = (payload: string[]) => {
  return {
    type: ActionTypes.SET_COUNTRY_LIST,
    countryList: payload,
  };
};

export const setBillingData = (payload: TransactionDynamo[]) => {
  const billingPayload: TransactionTable[] = [
    ...transformTransactionsBilling(payload),
  ];
  return {
    type: ActionTypes.SET_BILLING_DATA,
    billingData: billingPayload,
  };
};

export const setBillingTrxData = (payload: TransactionTable[]) => {
  return {
    type: ActionTypes.SET_BILLING_TRX_DATA,
    billingData: payload,
  };
};

export const setBillingDataCounter = (payload: {
  contador: number;
  total: number;
}) => {
  return {
    type: ActionTypes.SET_BILLING_DATA_COUNTER,
    counter: payload,
  };
};

export const setFirebaseId = (payload: string): BillingActions => {
  return {
    type: ActionTypes.SET_FIREBASE_ID,
    firebaseId: payload,
  };
};

export const setTransactionsBillingFile = (
  payload?: ITransactionFile
): BillingActions => {
  return {
    type: ActionTypes.SET_TRANSACTIONS_FILE,
    transactionsBillingFile: payload,
  };
};

export const setCountry = (payload: string) => {
  return {
    type: ActionTypes.SET_COUNTRY,
    country: payload,
  };
};

export const setStatusFlow = (payload: StatusFlowEnum) => {
  return {
    type: ActionTypes.SET_STATUS_FLOW,
    statusFlow: payload,
  };
};

export const setRetentionDataCounter = (payload: {
  counter: number;
  total: number;
}) => {
  return {
    type: ActionTypes.SET_RETENTIONS_DATA_COUNTER,
    retentionsDataCounter: payload,
  };
};

export const setNotification = (payload: INotification) => {
  return {
    type: ActionTypes.SET_NOTIFICATION,
    notification: payload,
  };
};

export const setOpenModalAnnul = (payload: boolean) => {
  return {
    type: ActionTypes.SET_OPEN_MODAL_ANNUL,
    openModalAnnul: payload,
  };
};

export const setOpenCreditDebitNoteModal = (payload: boolean) => {
  return {
    type: ActionTypes.SET_OPEN_MODAL_CREDIT_DEBIT_NOTE,
    openModalCreditDebitNote: payload,
  };
};

export const setRedirectCreateInvoice = (payload: boolean) => {
  return {
    type: ActionTypes.SET_REDIRECT_CREATE_INVOICE,
    redirectCreateInvoice: payload,
  };
};

export const setIsLoadingDeleteRetention = (payload: boolean) => {
  return {
    type: ActionTypes.SET_IS_LOADING_DELETE_RETENTION,
    isLoadingDeleteRetention: payload,
  };
};

export const setStepTimeLineData = (payload: IStepRetentionTimeLine) => {
  return {
    type: ActionTypes.SET_STEP_TIME_LINE_DATA,
    stepTimeLineData: payload,
  };
};

export const setOpenModalDetailTimeLine = (payload: boolean) => {
  return {
    type: ActionTypes.SET_OPEN_MODAL_DETAIL_TIME_LINE,
    openModalDetailTimeLine: payload,
  };
};

export const setOpenModalModifyInconsistence = (payload: boolean) => {
  return {
    type: ActionTypes.SET_OPEN_MODAL_MODIFY_INCONSISTENCE,
    openModalModifyInconsistence: payload,
  };
};

export const finishLoadingProcessBillingData = (
  error: boolean,
  dispatch: Dispatch<any>,
  statusPath: StatusFlowPath,
  isRetention: boolean,
  message?: string,
  historicRetentionColombia?: boolean,
  retentionDashboardColombia?: boolean
) => {
  if (!isRetention) {
    dispatch(setPathLoadingProcessBilling(statusPath));
  } else {
    dispatch(setProcessLoader(false));
    dispatch(
      setRetentionDataCounter({
        counter: 0,
        total: 0,
      })
    );
    dispatch(setPathLoadingRetention(statusPath));
  }
  if (error) {
    dispatch(
      setNotification({
        open: true,
        message: message!,
        type: "error",
      })
    );
  } else {
    if (historicRetentionColombia)
      dispatch(
        setNotification({
          open: true,
          message: message!,
          type: "gray",
        })
      );
    else
      dispatch(
        setNotification({
          open: true,
          message: message!,
          type: retentionDashboardColombia ? "gray" : "success",
        })
      );
  }
  dispatch(setIsLoading(false));
  dispatch(setOpenModalBackRetention(false));
};

export const getInvoiceData = (params: {
  invoiceId: string;
}): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
  ): void => {
    const url: string = `${environment.kushkiUrl}/invoice/v1/searchByInvoiceId`;
    axios
      .post(url, params)
      .then((axios_response: AxiosResponse<InvoiceTransaction>) => {
        const response = axios_response.data;
        dispatch(setInvoiceTrx(response));
        dispatch(setLoadingInvoice(false));
      })
      .catch(() => {
        dispatch(
          setNotification({
            open: true,
            message: "Error al procesar nueva factura.",
            type: "error",
          })
        );
      });
  };
};

export const getBillingData = (params: {
  date: string;
  filters: Filters;
  userRole?: UserRolesEnum[];
}): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
  ): void => {
    validatFiltersForUserRoleBilling(params.filters, params.userRole);

    const method: string = `/billing-core/v1/listTransaction?date=${params.date}`;
    dispatch(setLoadingBilling(true));
    axios
      .post(`${environment.kushkiUrl}${method}`, params.filters)
      .then((axios_response: AxiosResponse<TransactionDynamo[]>) => {
        const response: TransactionDynamo[] = axios_response.data;
        dispatch(
          setBillingData(
            sortBy(response, [(data: TransactionDynamo) => data.merchantName])
          )
        );
        dispatch(setPathLoadingProcessBilling(StatusFlowPath.DEFAULT));
        dispatch(setLoadingBilling(false));
      })
      .catch(() => {
        dispatch(setPathLoadingProcessBilling(StatusFlowPath.DEFAULT));
        dispatch(
          setBillingData(
            sortBy([], [(data: TransactionDynamo) => data.merchantName])
          )
        );
        dispatch(setLoadingBilling(false));
      });
  };
};

export const orderBillingData = (
  payload: TransactionTable[]
): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
  ): void => {
    dispatch(setBillingDataByOrder(payload));
  };
};

export const getTransactionsReprocessQuantity = (): ThunkAction<
  void,
  IBillingDashboardState,
  undefined,
  BillingActions
> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
  ): void => {
    const url: string = `${environment.kushkiUrl}/billing-charges/v1/charge/transactionsReprocess`;

    dispatch(setLoadingHistoric(true));
    axios
      .get(url)
      .then((axios_response: AxiosResponse<ReprocessCountResponse>) => {
        const response = axios_response.data;
        dispatch(setReprocessCount(response.quantity));
        dispatch(setLoadingHistoric(false));
      })
      .catch(() => {
        dispatch(setLoadingHistoric(false));
      });
  };
};

export const reprocessTransactions = (): ThunkAction<
  void,
  IBillingDashboardState,
  undefined,
  BillingActions
> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
  ): void => {
    const url: string = `${environment.kushkiUrl}/billing-charges/v1/reprocessFile`;

    dispatch(setLoadingHistoric(true));
    axios
      .post(url)
      .then(() => {
        dispatch(setLoadingHistoric(false));
      })
      .catch(() => {
        dispatch(setLoadingHistoric(false));
      });
  };
};

export const updateDispersionReason = (
  transactionId: string,
  reason: string
): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
  ): void => {
    dispatch(setLoadingUpdateDisperion(true));
    const url: string = `${environment.kushkiUrl}/billing-core/v1/reason/${transactionId}`;

    axios
      .patch<{ id: string }>(url, { reason: reason })
      .then(() => {
        dispatch(setLoadingUpdateDisperion(false));
      })
      .catch(() => {
        dispatch(setLoadingUpdateDisperion(false));
      });
  };
};

export const setHistoricBillingData = (
  payload: ResponseSearchBillingByFilters
) => {
  return {
    type: ActionTypes.SET_HISTORIC_DATA,
    historicBillingData: payload,
  };
};

const validatFiltersForUserRoleBilling = (
  filters: Filters,
  userRole?: UserRolesEnum[]
) => {
  const kindFound = filters.type.find(
    (item) => item === "kind" || item === "invoiceMode"
  );
  addMinimalComissionFilters(filters as Filters);
  if (userRole && !kindFound) {
    const catalog = getKindRoleCountryCatalog(
      filters.country as CountryEnum,
      userRole
    );
    if (catalog) {
      const kinds: string[] = catalog.filter(
        (item) =>
          item != InvoiceModeEnum.WITHOUT_IVA &&
          item != InvoiceModeEnum.WITH_IVA
      );
      const invoiceMode: string[] = catalog.filter(
        (item) =>
          item == InvoiceModeEnum.WITHOUT_IVA ||
          item == InvoiceModeEnum.WITH_IVA
      );
      if (invoiceMode.length > 0) {
        kinds.push(KindEnum.INVOICE);
      }
      filters.type.push("kind");
      filters.kind = kinds.join("|");
    }
  }
};
const validatFiltersForUserRoleHistoric = (
  params: SearchBillingRequest,
  userRole?: UserRolesEnum[]
) => {
  const kindFound =
    get(params, "filter.kind") || get(params, "filter.invoice_mode");
  let filter = get(params, "filter", {});
  filter = addMinimalComissionFilters(filter as Filters);
  if (userRole && !kindFound) {
    const catalog = getKindRoleCountryCatalog(
      params.country as CountryEnum,
      userRole
    );
    if (catalog) {
      const kinds: string[] = catalog.filter(
        (item) =>
          item != InvoiceModeEnum.WITHOUT_IVA &&
          item != InvoiceModeEnum.WITH_IVA
      );
      const invoiceMode: string[] = catalog.filter(
        (item) =>
          item == InvoiceModeEnum.WITHOUT_IVA ||
          item == InvoiceModeEnum.WITH_IVA
      );
      if (invoiceMode.length > 0) {
        kinds.push(KindEnum.INVOICE);
      }
      if (kinds.length > 0) {
        filter["kind"] = uniq(kinds).join("|");
      }
      params.filter = filter;
    }
  }
};

const addMinimalComissionFilters = (filter: Filters) => {
  if (
    filter.transactionType &&
    filter.transactionType.includes(TransactionTypeEnum.MINIMAL_COMISSION)
  )
    filter.transactionType.push(
      TransactionTypeEnum.MINIMAL_COMMISSION_FIXED,
      TransactionTypeEnum.MINIMAL_COMMISSION_DEDUCTIBLE
    );
  if (
    filter["transaction_type"] &&
    filter["transaction_type"].includes(TransactionTypeEnum.MINIMAL_COMISSION)
  )
    filter["transaction_type"].push(
      TransactionTypeEnum.MINIMAL_COMMISSION_FIXED,
      TransactionTypeEnum.MINIMAL_COMMISSION_DEDUCTIBLE
    );
  return filter;
};

export const getHistoricBillingData = (
  params: SearchBillingRequest,
  userRole?: UserRolesEnum[]
): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
  ): void => {
    dispatch(setTransactionDefaultHistory());
    validatFiltersForUserRoleHistoric(params, userRole);
    const url: string = `${environment.kushkiUrl}/billing-core/searchBilling`;
    dispatch(setLoadingHistoric(true));
    axios
      .post(url, params)
      .then((axios_response: AxiosResponse<ResponseSearchBillingByFilters>) => {
        const response: ResponseSearchBillingByFilters = axios_response.data;
        dispatch(setHistoricBillingData(response));
        dispatch(setLoadingHistoric(false));
      })
      .catch(() => {
        dispatch(setLoadingHistoric(false));
      });
  };
};

export const setPagination = (payload: object[]): BillingActions => {
  return {
    type: ActionTypes.SET_PAGINATION,
    pagination: payload,
  };
};

export const getFirebaseId = (payload: {
  body: object;
}): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
  ): void => {
    const url: string = `${environment.kushkiUrl}/billing-core/searchBilling`;

    const requestIds = get(payload, "body.requestIds", []);

    if (isEmpty(requestIds)) unset(payload, "body.requestIds");
    axios
      .post<{ id: string }>(url, payload.body)
      .then((response: AxiosResponse<{ id: string }>) => {
        dispatch(setFirebaseId(response.data.id));
      })
      .catch(() => {
        dispatch(
          setIsDownloadingFile({ isDownloadingCheck: false, isLoading: false })
        );
      });
  };
};

export const getTransactionsBillingFile = (
  payload: string
): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
  ): void => {
    databaseRef
      .child(
        `${environment.envName}/usrv-billing-core/file/transactions-billing/${payload}`
      )
      .on("value", (snapshot) => {
        const file: ITransactionFile = snapshot.val();

        if (file.url !== undefined) {
          dispatch(setTransactionsBillingFile(snapshot.val()));
        }
      });
  };
};

const transformTransactionsBilling = (
  trxBilling: TransactionDynamo[]
): TransactionTable[] => {
  const transactions_transformed: TransactionTable[] = [];

  trxBilling.forEach((trx: TransactionDynamo) => {
    const status = get(trx, "stillReprocessing", false)
      ? StatusEnum.PENDING
      : StatusEnum.PROCESS;
    const item = {
      transaction: trx,
      invoice: trx.kind === "invoice",
      charge: trx.kind === "charge",
      invoice_init: trx.kind === "invoice",
      charge_init: trx.kind === "charge",
      amount_init: trx.amount,
      amount: trx.amount,
      total_amount: trx.totalAmount,
      iva_amount: trx.ivaAmount,
      transactionid_invoice: trx.kind === "invoice" ? trx.transactionId : "",
      transactionid_charge: trx.kind === "charge" ? trx.transactionId : "",
    };
    set(item, "status", status);
    transactions_transformed.push(item);
  });

  return transactions_transformed;
};

const transformRetentionData = (
  retentions: TransactionDynamo[]
): TransactionDynamo[] => {
  return Array.from(retentions).map((trx: TransactionDynamo) => {
    return {
      ...trx,
      selectedStatus: StatusEnum.PROCESS,
    };
  });
};

const transformRetentionPreProcessedData = (
  retentions: TransactionDynamo[]
): TransactionDynamo[] =>
  Array.from(retentions).map(
    ({ selectedStatus = StatusEnum.PRE_PROCESSED, ...trx }) => trx
  );

export const setBillingDataRow = (
  billingDataRow: TransactionTable
): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => (
  dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>,
  getState: () => IBillingDashboardState
): void => {
  const records = getState().billingData;
  const index_aux = records.findIndex(
    (data) =>
      data.transaction!.transactionId ===
      billingDataRow.transaction!.transactionId
  );
  dispatch(
    setBillingTrxData(
      Object.assign([], [...getState().billingData], {
        [index_aux]: billingDataRow,
      })
    )
  );
};

export const setBillingHistoricDataRow = (
  billingDataRow: InvoiceRecord
): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => (
  dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>,
  getState: () => IBillingDashboardState
): void => {
  const records = getState().historicBillingData.records;
  const index_aux = records.findIndex(
    (data) => data.transaction_id === billingDataRow.transaction_id
  );

  dispatch(
    setHistoricBillingData({
      records: Object.assign([], [...records], {
        [index_aux]: billingDataRow,
      }),
      total: getState().historicBillingData.records.length,
    })
  );
};

const getJwtAuth = (): string => {
  return localStorage.getItem("jwt") || "";
};

const getStatusFlowPath = (statusFlow: StatusFlowEnum) =>
  statusFlow === StatusFlowEnum.EXECUTOR
    ? StatusFlowPath.EXECUTOR
    : StatusFlowPath.VALIDATOR;

const DISPERSION_COUNTRIES = [
  CountryEnum.peru,
  CountryEnum.chile,
  CountryEnum.colombia,
  CountryEnum.mexico,
  CountryEnum.ecuador,
];

const shouldProcessDispersion = (transactions: TransactionTable[]) =>
  transactions.some(
    (transaction: TransactionTable) =>
      DISPERSION_COUNTRIES.includes(
        defaultTo(get(transaction, "transaction.country"), "")
      ) &&
      defaultTo(get(transaction, "transaction.kind"), "") ===
        KindEnum.DISPERSION &&
      get(transaction, "transaction.statusFlow", "") ===
        StatusFlowEnum.VALIDATOR &&
      defaultTo(get(transaction, "status"), "") === StatusEnum.PROCESS
  );
const getMessageRetentionByStatusFlow = (statusFlow?: string): string =>
  `Retenciones ${
    isEqual(statusFlow, StatusFlowEnum.VALIDATOR) ? "envíadas" : "ejecutadas"
  } con éxito`;
const messageWS = (
  country: string,
  isError: boolean,
  historicRetentionColombia?: boolean,
  retentionDashboardColombia?: boolean,
  statusFlow?: string
): string => {
  const defaultMessage: string = "Procesado exitosamente.";

  if (isError) {
    return country === CountryEnum.colombia
      ? "Ha ocurrido un error inténtalo nuevamente"
      : "No se logró procesar todas las transacciones.";
  } else {
    return country === CountryEnum.colombia
      ? historicRetentionColombia
        ? "Retención regresada con éxito"
        : retentionDashboardColombia
        ? getMessageRetentionByStatusFlow(statusFlow)
        : defaultMessage
      : defaultMessage;
  }
};

const isReprocess = (
  statusRow: string,
  status: string,
  profile: StatusFlowEnum,
  billingDataStatus: string
) => {
  return (
    (status === StatusEnum.PRE_PROCESSED && statusRow) ||
    (status === StatusEnum.PENDING && statusRow === StatusEnum.REPROCESS) ||
    (profile === StatusFlowEnum.VALIDATOR &&
      [StatusEnum.INITIALIZED, StatusEnum.MANUAL].includes(
        <StatusEnum>status
      ) &&
      statusRow === StatusEnum.REPROCESS) ||
    (profile === StatusFlowEnum.VALIDATOR &&
      billingDataStatus === StatusEnum.PRE_PROCESSED &&
      statusRow === StatusEnum.REPROCESS)
  );
};

const updateStatusRow = (
  statusRow: string,
  status: string,
  profile: StatusFlowEnum,
  billingDataStatus: string
): StatusEnum => {
  if (isReprocess(statusRow, status, profile, billingDataStatus)) {
    return StatusEnum.REPROCESS;
  } else {
    return StatusEnum.APPROVED;
  }
};

const updateStatus = (
  statusRow: string,
  status: string,
  profile: StatusFlowEnum,
  billingDataStatus: string
): string => {
  if (
    (status === StatusEnum.PENDING && statusRow === StatusEnum.REPROCESS) ||
    status === StatusEnum.PRE_PROCESSED
  ) {
    return StatusEnum.PRE_PROCESSED;
  } else if (isReprocess(statusRow, status, profile, billingDataStatus)) {
    return StatusEnum.REPROCESS;
  } else {
    return status;
  }
};

export const processBillingData = (): ThunkAction<
  void,
  IBillingDashboardState,
  undefined,
  BillingActions
> => {
  return async (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>,
    getState: () => IBillingDashboardState
  ): Promise<void> => {
    const { billingData, country, statusFlow, chargeId } = getState();
    const dispersionFileId = getState().dispersionId;

    if (!billingData || billingData.length === 0) return;

    let countWSResponse: number = 0;
    let processingError: boolean = false;
    let allResponsesHaveArrived: boolean = false;
    let webSocket: WebSocket | undefined;
    let responseMessage: string | undefined;

    const isDispersion = shouldProcessDispersion(billingData);

    if (statusFlow === StatusFlowEnum.VALIDATOR) {
      const url: string = `${environment.kushkiUrl}/audit/v1/auditBillingData`;
      let transactions: {
        merchantId: string;
        merchantName: string | undefined;
      }[] = [];
      billingData.forEach((transactionItem: TransactionTable) => {
        if (transactionItem.transaction !== undefined) {
          const merchantId: string = transactionItem.transaction.merchantId;
          const merchantName: string | undefined =
            transactionItem.transaction.merchantName;
          transactions.push({ merchantId, merchantName });
        }
      });
      transactions = transactions.slice(0, 3);
      let processingSummary: {
        pending: number;
        process: number;
        reject: number;
      } = {
        pending: billingData.filter(
          (value) => value.status === StatusEnum.PENDING
        ).length,
        process: billingData.filter(
          (value) => value.status === StatusEnum.PROCESS
        ).length,
        reject: billingData.filter(
          (value) => value.status === StatusEnum.REJECT
        ).length,
      };

      axios
        .post(url, { country, transactions, processingSummary })
        .then(() => {})
        .catch(console.error);
    }

    const statusFlowPath: StatusFlowPath = getStatusFlowPath(statusFlow!);
    const date: string = format(new Date(), "yyyy-MM-dd");
    let counter: number = 0;
    dispatch(setPathLoadingProcessBilling(statusFlowPath));
    try {
      webSocket = new WebSocket(
        `${environment.processWSBillingData}?Authorization=${getJwtAuth()}`
      );

      webSocket.onopen = () => {
        billingData
          .map((item: TransactionTable) =>
            transformBillingDataItem(item, country!, statusFlow!)
          )
          .forEach((transaction: RequestTransaction, index: number) => {
            dispatch(
              setBillingDataCounter({
                contador: counter,
                total: billingData.length,
              })
            );
            counter = counter + 1;
            const obj = {
              action: ProcessTransactionActionsEnum.PROCESS_TRANSACTION,
              data: {
                date,
                country,
                profile: statusFlow!,
                transaction,
                ...(transaction.kind === KindEnum.DISPERSION
                  ? { processFileId: dispersionFileId }
                  : {}),
                ...(transaction.kind === KindEnum.CHARGE
                  ? { processFileId: chargeId }
                  : {}),
              },
            };
            const statusRow: string = get(billingData[index], "statusRow", "");
            const billingDataStatus: string = get(
              billingData[index],
              "status",
              ""
            );
            const transactionStatus: string = obj.data.transaction.status;

            obj.data.transaction["statusRow"] = updateStatusRow(
              statusRow,
              transactionStatus,
              obj.data.profile,
              billingDataStatus
            );
            obj.data.transaction["status"] = updateStatus(
              statusRow,
              transactionStatus,
              obj.data.profile,
              billingDataStatus
            );

            webSocket?.send(JSON.stringify(obj));
          });
      };

      webSocket.onerror = (_event) => {
        webSocket?.close();
        finishLoadingProcessBillingData(
          true,
          dispatch,
          StatusFlowPath.RELOAD,
          false,
          messageWS(country!, processingError)
        );
      };

      webSocket.onmessage = (event: MessageEvent) => {
        const newStatusFlow = getState().statusFlow!;
        if (statusFlow !== newStatusFlow) {
          dispatch(setPathLoadingProcessBilling(StatusFlowPath.DEFAULT));
          webSocket?.close();
          return;
        }

        const response: DataWebsocket = JSON.parse(event.data);

        responseMessage = get(response, "message");
        if (responseMessage === "Proceso finalizado") {
          webSocket?.close();
          finishLoadingProcessBillingData(
            false,
            dispatch,
            StatusFlowPath.RELOAD,
            false,
            messageWS(country!, processingError)
          );
        }

        countWSResponse = countWSResponse + 1;

        const error: boolean = get(response, "error", false);
        if (error) {
          processingError = true;
        }

        allResponsesHaveArrived = countWSResponse === billingData.length;
        if (allResponsesHaveArrived) {
          webSocket?.send(
            JSON.stringify({
              action: ProcessTransactionActionsEnum.FINISH_PROCESS_TRANSACTION,
              data: {
                date,
                country,
                profile: newStatusFlow!,
                ...(isDispersion ? { dispersionId: dispersionFileId } : {}),
              },
            })
          );
        }
      };
    } catch (e) {
      webSocket?.close();
      finishLoadingProcessBillingData(
        true,
        dispatch,
        StatusFlowPath.RELOAD,
        false
      );
      dispatch(
        setNotification({
          open: true,
          message: "Error al procesar transacciones.",
          type: "error",
        })
      );
    }
  };
};

export const processRetentionsData = (
  retentionData: RentetionData,
  historicRetentionColombia?: boolean,
  retentionDashboardColombia?: boolean
): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>,
    getState: () => IBillingDashboardState
  ): void => {
    const { country, statusFlow } = getState();
    if (!retentionData || retentionData.length === 0) return;

    let countWSResponse: number = 0;
    let allResponsesHaveArrived: boolean = false;
    let processingError: boolean = false;
    let webSocket: WebSocket | undefined;
    let responseMessage: string | undefined;
    const date: string = format(new Date(), "yyyy-MM-dd");

    dispatch(setProcessData(true));

    try {
      webSocket = new WebSocket(
        `${environment.processWSBillingData}?Authorization=${getJwtAuth()}`
      );

      dispatch(
        setRetentionDataCounter({
          counter: countWSResponse,
          total: retentionData.records.length,
        })
      );
      webSocket.onopen = () => {
        retentionData.records
          .map((item: TransactionDynamo) => {
            const retentionDataM: RequestTransaction = transformRetentionDataItem(
              item,
              country!,
              statusFlow!
            );
            return country === CountryEnum.colombia &&
              statusFlow === StatusFlowEnum.EXECUTOR
              ? {
                  ...(<RequestTransaction>omit(retentionDataM, ["statusFlow"])),
                }
              : retentionDataM;
          })
          .forEach((transaction: RequestTransaction) => {
            const obj = {
              action: ProcessTransactionActionsEnum.PROCESS_TRANSACTION,
              data: {
                date,
                country,
                profile: statusFlow!,
                transaction,
              },
            };
            webSocket?.send(JSON.stringify(obj));
          });
      };

      webSocket.onerror = (_event) => {
        webSocket?.close();
        finishLoadingProcessBillingData(
          true,
          dispatch,
          StatusFlowPath.RELOAD,
          true,
          messageWS(country!, true)
        );
      };

      webSocket.onmessage = (event: MessageEvent) => {
        const newStatusFlow = getState().statusFlow!;
        if (statusFlow !== newStatusFlow) {
          webSocket?.close();
          return;
        }

        const response: DataWebsocket = JSON.parse(event.data);
        responseMessage = get(response, "message");

        if (responseMessage === "Proceso finalizado") {
          webSocket?.close();
          finishLoadingProcessBillingData(
            processingError,
            dispatch,
            StatusFlowPath.RELOAD,
            true,
            messageWS(
              country!,
              processingError,
              historicRetentionColombia,
              retentionDashboardColombia,
              statusFlow
            ),
            historicRetentionColombia,
            retentionDashboardColombia
          );
        }

        countWSResponse = countWSResponse + 1;
        dispatch(
          setRetentionDataCounter({
            counter: countWSResponse,
            total: retentionData.records.length,
          })
        );

        const error: boolean = get(response, "error", false);
        if (error) {
          processingError = true;
        }

        allResponsesHaveArrived =
          countWSResponse === retentionData.records.length;
        if (allResponsesHaveArrived) {
          webSocket?.send(
            JSON.stringify({
              action:
                ProcessTransactionActionsEnum.FINISH_PROCESS_RETENTION_TRANSACTION,
              data: {
                country,
              },
            })
          );
        }
      };
    } catch (error) {
      webSocket?.close();
      finishLoadingProcessBillingData(
        true,
        dispatch,
        StatusFlowPath.RELOAD,
        true
      );
      dispatch(
        setNotification({
          open: true,
          message: messageWS(country!, true)!,
          type: "error",
        })
      );
    }
  };
};

const transformBillingDataItem = (
  billingDataElement: TransactionTable,
  country: string,
  statusFlow: StatusFlowEnum
): RequestTransaction => {
  const modifiedAmounts = () => {
    return (
      billingDataElement.modify?.reason_change_amount && {
        reason_change_amount: billingDataElement.modify?.reason_change_amount,
        modifyAmount: {
          retTotalAmount: billingDataElement.transaction?.amount,
        },
        initialAmount: {
          retTotalAmount: billingDataElement.amount_init,
        },
      }
    );
  };
  const requestTransaction: RequestTransaction = {
    amount: billingDataElement.transaction?.amount!,
    backofficeUser: auth.getUserName(),
    kind: get(billingDataElement, "transaction.kind", ""),
    status: getTransactionStatus(
      <string>billingDataElement.status,
      country,
      get(billingDataElement, "transaction.kind", ""),
      statusFlow
    ),
    invoiceInfo: billingDataElement.invoiceInfo,
    initialStatus: getInitialStatus(
      statusFlow,
      get(billingDataElement, "status")
    ) as "pending" | "approve" | "omit" | "reject",
    advanceSettingsParameters: billingDataElement.advanceSettingsParameters,
    advanceSettingsReference: billingDataElement.advanceSettingsReference,
    transactionId: billingDataElement.transaction?.transactionId!,
  };

  if (!!billingDataElement.modify?.value) {
    set(requestTransaction, "modify", {
      value: billingDataElement.modify?.value,
      reason: billingDataElement.omit?.reason,
      ...modifiedAmounts(),
    });
  }

  if (!!billingDataElement.omit?.reason) {
    set(requestTransaction, "omit", {
      reason: get(
        billingDataElement,
        "omit.reason",
        get(billingDataElement, "transaction.omit.reason")
      ),
      external: billingDataElement.omit?.external,
      external_type: billingDataElement.omit?.external_type,
      external_detail: billingDataElement.omit?.external_detail,
    });
  }

  if (!!billingDataElement.reject?.reason) {
    set(requestTransaction, "reject", {
      reason: defaultTo(
        get(
          billingDataElement,
          "reject.reason",
          get(billingDataElement, "transaction.reject.reason")
        ),
        undefined
      ),
    });
  }

  return requestTransaction;
};

const transformRetentionDataItem = (
  retentionDataElement: TransactionDynamo,
  _country: string,
  statusFlow: StatusFlowEnum
): RequestTransaction => {
  return <RequestTransaction>{
    amount: retentionDataElement.amount,
    amountRetIca: get(retentionDataElement, "amountRetIca"),
    amountRetIva: get(retentionDataElement, "amountRetIva"),
    amountRetSource: get(retentionDataElement, "amountRetSource"),
    baseAmountIca: get(retentionDataElement, "baseAmountIca"),
    baseAmountIva: get(retentionDataElement, "baseAmountIva"),
    baseAmountSource: get(retentionDataElement, "baseAmountSource"),
    backofficeUser: auth.getUserName(),
    kind: get(retentionDataElement, "kind"),
    status: getTransactionStatus(
      get(retentionDataElement, "status"),
      _country,
      get(retentionDataElement, "kind"),
      statusFlow
    ),
    invoiceInfo: retentionDataElement.invoiceInfo,
    initialStatus: getInitialStatus(
      statusFlow,
      get(retentionDataElement, "status"),
      _country
    ),
    statusFlow: getStatusFlow(
      get(retentionDataElement, "status"),
      _country,
      statusFlow
    ),
    advanceSettingsParameters: retentionDataElement.advanceSettingsParameters,
    advanceSettingsReference: retentionDataElement.advanceSettingsReference,
    transactionId: retentionDataElement.transactionId,
    retIva: retentionDataElement.retIva,
    retFue: retentionDataElement.retFue,
    retTotalAmount: retentionDataElement.retTotalAmount,
    modify: {
      initialAmount: retentionDataElement.modify?.initialAmount,
      modifyAmount: retentionDataElement.modify?.modifyAmount,
      reason_change_amount: retentionDataElement.modify?.reason_change_amount,
    },
    omit: {
      reason: retentionDataElement.omit?.reason,
      external: retentionDataElement.omit?.external,
      external_type: retentionDataElement.omit?.external_type,
      external_detail: retentionDataElement.omit?.external_detail,
    },
    reject: {
      reason: retentionDataElement.reject?.reason,
    },
  };
};

const modifyRetentionField = (
  item: TransactionDynamo,
  record: InvoiceRecord
): TransactionDynamo => {
  let totalAmount = 0;
  if (get(record, "transactionId", "") === item.transactionId) {
    const initialAmountValues = {
      amountRetIva: get(item, "amountRetIva", 0),
      amountRetIca: get(item, "amountRetIca", 0),
      amountRetSource: get(item, "amountRetSource", 0),
      retTotalAmount: get(item, "retTotalAmount", 0),
      baseAmountIva: get(item, "baseAmountIva", 0),
      baseAmountIca: get(item, "baseAmountIca", 0),
      baseAmountSource: get(item, "baseAmountSource", 0),
    };

    set(item, "modify.initialAmount", initialAmountValues);

    let modifyAmountValues = {};

    if (has(record, "modify.modifyAmount.amountRetIva")) {
      const amountIva = get(
        record,
        "modify.modifyAmount.amountRetIva",
        initialAmountValues.amountRetIva
      );
      modifyAmountValues = {
        ...modifyAmountValues,
        amountRetIva: amountIva,
      };
      set(item, "amountRetIva", amountIva);
    }

    if (has(record, "modify.modifyAmount.amountRetIca")) {
      const amountIca = get(
        record,
        "modify.modifyAmount.amountRetIca",
        initialAmountValues.amountRetIca
      );
      modifyAmountValues = {
        ...modifyAmountValues,
        amountRetIca: amountIca,
      };
      set(item, "amountRetIca", amountIca);
    }

    if (has(record, "modify.modifyAmount.amountRetSource")) {
      const amountSource = get(
        record,
        "modify.modifyAmount.amountRetSource",
        initialAmountValues.amountRetSource
      );
      modifyAmountValues = {
        ...modifyAmountValues,
        amountRetSource: amountSource,
      };
      set(item, "amountRetSource", amountSource);
    }

    if (has(record, "modify.modifyAmount.baseAmountIca")) {
      const baseAmountIca = get(
        record,
        "modify.modifyAmount.baseAmountIca",
        initialAmountValues.baseAmountIca
      );
      modifyAmountValues = {
        ...modifyAmountValues,
        baseAmountIca: baseAmountIca,
      };
      set(item, "baseAmountIca", baseAmountIca);
    }

    if (has(record, "modify.modifyAmount.baseAmountIva")) {
      const baseAmountIva = get(
        record,
        "modify.modifyAmount.baseAmountIva",
        initialAmountValues.baseAmountIva
      );
      modifyAmountValues = {
        ...modifyAmountValues,
        baseAmountIva: baseAmountIva,
      };
      set(item, "baseAmountIva", baseAmountIva);
    }

    if (has(record, "modify.modifyAmount.baseAmountSource")) {
      const baseAmountSource = get(
        record,
        "modify.modifyAmount.baseAmountSource",
        initialAmountValues.baseAmountSource
      );
      modifyAmountValues = {
        ...modifyAmountValues,
        baseAmountSource: baseAmountSource,
      };
      set(item, "baseAmountSource", baseAmountSource);
    }

    if (has(record, "modify.modifyAmount")) {
      totalAmount =
        Number(
          get(
            record,
            "modify.modifyAmount.amountRetIva",
            initialAmountValues.amountRetIva
          )
        ) +
        Number(
          get(
            record,
            "modify.modifyAmount.amountRetSource",
            initialAmountValues.amountRetSource
          )
        ) +
        Number(
          get(
            record,
            "modify.modifyAmount.amountRetIca",
            initialAmountValues.amountRetIca
          )
        );
      modifyAmountValues = {
        ...modifyAmountValues,
        retTotalAmount: totalAmount,
      };
      set(item, "retTotalAmount", totalAmount);
      modifyAmountValues = reduce(
        modifyAmountValues,
        (result, value, key) => {
          if (
            key === "amountRetIca" &&
            value !== initialAmountValues.amountRetIca
          )
            result[key] = value;

          if (
            key === "amountRetIva" &&
            value !== initialAmountValues.amountRetIva
          )
            result[key] = value;

          if (
            key === "amountRetSource" &&
            value !== initialAmountValues.amountRetSource
          )
            result[key] = value;
          if (
            key === "retTotalAmount" &&
            value !== initialAmountValues.retTotalAmount
          )
            result[key] = value;
          if (
            key === "baseAmountIca" &&
            value !== initialAmountValues.baseAmountIca
          )
            result[key] = value;
          if (
            key === "baseAmountIva" &&
            value !== initialAmountValues.baseAmountIva
          )
            result[key] = value;
          if (
            key === "baseAmountSource" &&
            value !== initialAmountValues.baseAmountSource
          )
            result[key] = value;
          return result;
        },
        {}
      );
      set(item, "modify.modifyAmount", modifyAmountValues);
      set(item, "status", StatusEnum.PENDING);
      set(item, "isEdited", true);
    }
  }
  return item;
};

const getInitialStatus = (
  statusFlow: StatusFlowEnum,
  status?: string,
  country?: string
): InitialStatusEnum | undefined => {
  if (statusFlow !== StatusFlowEnum.VALIDATOR) return;

  let initialStatus: InitialStatusEnum;

  switch (status) {
    case StatusEnum.PROCESS:
      initialStatus =
        country === CountryEnum.colombia
          ? InitialStatusEnum.COMPLETED
          : InitialStatusEnum.APPROVE;
      break;
    case StatusEnum.PENDING:
      initialStatus = InitialStatusEnum.PENDING;
      break;
    case StatusEnum.REJECT:
      initialStatus = InitialStatusEnum.REJECT;
      break;
    case StatusEnum.OMIT:
      initialStatus = InitialStatusEnum.OMIT;
      break;
    default:
      return;
  }
  return initialStatus;
};

const getStatusFlow = (
  status: string,
  country: string,
  statusFlow: StatusFlowEnum
): string => {
  if (status === StatusEnum.REJECTED && country === CountryEnum.colombia)
    return StatusFlowEnum.EXECUTOR;
  else return statusFlow;
};

const getTransactionStatus = (
  status: string,
  country: string,
  kind: string,
  statusFlow: StatusFlowEnum
): string => {
  switch (status) {
    case StatusEnum.PENDING:
      return RequestStatusEnum.PENDING;
    case StatusEnum.OMIT:
      return RequestStatusEnum.OMITTED;
    case StatusEnum.REJECT:
      return RequestStatusEnum.REJECTED;
    case StatusEnum.PROCESS:
      if (statusFlow === StatusFlowEnum.EXECUTOR)
        return StatusEnum.PRE_PROCESSED;
      if (
        statusFlow === StatusFlowEnum.VALIDATOR &&
        kind === KindEnum.RECEIVABLE
      )
        return StatusEnum.IN_RECEIVABLE;
      if (
        statusFlow === StatusFlowEnum.VALIDATOR &&
        kind === KindEnum.ANNUL_INVOICE
      )
        return StatusEnum.PRE_PROCESSED;
      return _getProcessStatus(country, kind);
    default:
      return RequestStatusEnum.MANUAL;
  }
};

const _getProcessStatus = (
  country: string,
  kind: string
): RequestStatusEnum => {
  switch (kind) {
    case KindEnum.RETENTION_EC:
      return _getProcessStatusRetention(country);
    case KindEnum.INVOICE:
      return _getProcessStatusInvoice(country);
    case KindEnum.VOUCHER:
      return _getProcessStatusVoucher(country);
    case KindEnum.CHARGE:
      return _getProcessStatusCharge(country);
    case KindEnum.DISPERSION:
      return _getProcessStatusDispersion(country);
    case KindEnum.CREDIT_NOTE:
    case KindEnum.DEBIT_NOTE:
      return _getProcessStatusCDN(country);
    case KindEnum.RETENTION_CO:
      return _getProcessStatusRetention(country);
    default:
      return RequestStatusEnum.MANUAL;
  }
};

const _getProcessStatusRetention = (country: string): RequestStatusEnum => {
  switch (country) {
    case CountryEnum.ecuador:
      return RequestStatusEnum.INITIALIZED;
    case CountryEnum.colombia:
      return RequestStatusEnum.COMPLETED;
    default:
      return RequestStatusEnum.MANUAL;
  }
};

const _getProcessStatusInvoice = (country: string): RequestStatusEnum => {
  return includes(
    [
      CountryEnum.ecuador,
      CountryEnum.chile,
      CountryEnum.peru,
      CountryEnum.colombia,
      CountryEnum.mexico,
    ],
    country
  )
    ? RequestStatusEnum.INITIALIZED
    : RequestStatusEnum.MANUAL;
};
const _getProcessStatusCDN = (country: string): RequestStatusEnum => {
  return includes(
    [CountryEnum.peru, CountryEnum.mexico, CountryEnum.colombia],
    country
  )
    ? RequestStatusEnum.INITIALIZED
    : RequestStatusEnum.MANUAL;
};
const _getProcessStatusVoucher = (country: string): RequestStatusEnum => {
  return includes([CountryEnum.peru, CountryEnum.chile], country)
    ? RequestStatusEnum.INITIALIZED
    : RequestStatusEnum.MANUAL;
};

const _getProcessStatusDispersion = (country: string): RequestStatusEnum => {
  return includes(
    [
      CountryEnum.peru,
      CountryEnum.mexico,
      CountryEnum.ecuador,
      CountryEnum.chile,
      CountryEnum.colombia,
    ],
    country
  )
    ? RequestStatusEnum.INITIALIZED
    : RequestStatusEnum.MANUAL;
};

const _getProcessStatusCharge = (country: string): RequestStatusEnum => {
  return includes([CountryEnum.ecuador], country)
    ? RequestStatusEnum.INITIALIZED
    : RequestStatusEnum.MANUAL;
};

export const downloadFile = (
  extensionFile: string,
  invoiceId: string
): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
  ): void => {
    const url: string = `${environment.kushkiUrl}/invoice/v1/downloadFileEfact`;
    const data = { extensionFile: extensionFile, invoiceId: invoiceId };
    axios
      .post<{ fileBase64: string }>(url, data)
      .then((response: AxiosResponse<{ fileBase64: string }>) => {
        dispatch(setDownloadFile(response.data.fileBase64));
        dispatch(setLoadingDownloadEfact(false));
      })
      .catch(() => {
        dispatch(setDownloadFile(""));
        dispatch(setLoadingDownloadEfact(false));
      });
  };
};

const defineFilterValueCo = (
  names: string[],
  paramsSearch?: RentetionDataRequest
): object =>
  names
    .filter((value: string) => !isEmpty(get(paramsSearch, `data.${value}`, "")))
    .map((value: string) => {
      let aux_filter: object = {};
      if (value === "from")
        aux_filter["dateRange"] = {
          from: get(paramsSearch, "data.from", ""),
          to: get(paramsSearch, "data.to", ""),
        };
      else aux_filter[value] = get(paramsSearch, `data.${value}`, "");

      return aux_filter;
    })
    .reduce((acc: object, value: object) => Object.assign(acc, value), {});

export const defineAttFilterStateCo = (statusFlow: string): string[] =>
  statusFlow === StatusFlowEnum.EXECUTOR
    ? [StatusEnum.PENDING, StatusEnum.REJECTED]
    : [StatusEnum.PRE_PROCESSED];

export const downloadTransactionUrlRolAll = (
  country: string,
  date: string,
  dateFrom: string,
  dateTo: string,
  statusflow: StatusFlowEnum,
  role: UserRolesEnum[]
) => (dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>) => {
  dispatch(
    setTransactionTableDownloadFile({
      base64: "",
      isDownloading: true,
      isDownloadingCheck: false,
    })
  );
  let kind = getKindRoleCountryCatalog(country as CountryEnum, role);

  if (country === CountryEnum.mexico)
    kind = kind.map((value: string) => {
      if (
        includes([InvoiceModeEnum.WITH_IVA, InvoiceModeEnum.WITHOUT_IVA], value)
      )
        return KindEnum.INVOICE;

      return value;
    });

  const data = {
    filter: {
      roles: role,
      kind: uniq(kind).filter(
        (kindItem) =>
          ![KindEnum.RETENTION_EC, KindEnum.RETENTION_CO].includes(
            kindItem as KindEnum
          )
      ),
    },
    dateFrom,
    dateTo,
    country,
    date,
    statusflow,
  };
  axios
    .post(
      `${environment.kushkiUrl}/billing-core/v1/transactionsFileByFilter`,
      data
    )
    .then((response: AxiosResponse<{ data: string }>) => {
      dispatch(
        setTransactionTableDownloadFile({
          base64: response.data.data,
          isDownloading: false,
          isDownloadingCheck: false,
        })
      );
    })
    .catch(() => {
      dispatch(
        setTransactionTableDownloadFile({
          base64: "",
          isDownloading: false,
          isDownloadingCheck: false,
        })
      );
    });
};

export const downloadTransactionUrlRol = (
  country: string,
  date: string,
  statusflow: StatusFlowEnum,
  role: UserRolesEnum[],
  paramsSearch?: RentetionDataRequest
) => (dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>) => {
  dispatch(
    setTransactionTableDownloadFile({
      base64: "",
      isDownloading: true,
      isDownloadingCheck: false,
    })
  );
  let kind = getKindRoleCountryCatalog(country as CountryEnum, role);
  let filter: object;
  let dataDefault: TransactionsFileFilters = {
    country: country as CountryEnum,
    date,
    statusflow,
  };

  if (country === CountryEnum.mexico)
    kind = kind.map((value: string) => {
      if (
        includes([InvoiceModeEnum.WITH_IVA, InvoiceModeEnum.WITHOUT_IVA], value)
      )
        return KindEnum.INVOICE;

      return value;
    });

  if (country === CountryEnum.colombia) {
    const nameValues: string[] = [
      "cycle",
      "currency",
      "from",
      "cityId",
      "processorType",
      "merchantIdSocialName",
    ];

    filter = {
      roles: role,
      ...defineFilterValueCo(nameValues, paramsSearch),
    };
    dataDefault = {
      ...dataDefault,
      kind: KindEnum.RETENTION_CO,
      state: defineAttFilterStateCo(paramsSearch?.data.statusFlow!),
    };
  } else {
    filter = {
      roles: role,
      kind: uniq(kind),
    };
  }
  axios
    .post(`${environment.kushkiUrl}/billing-core/v1/transactionsFileByFilter`, {
      ...dataDefault,
      filter,
    })
    .then((response: AxiosResponse<{ data: string }>) => {
      dispatch(
        setTransactionTableDownloadFile({
          base64: response.data.data,
          isDownloading: false,
          isDownloadingCheck: false,
        })
      );
    })
    .catch(() => {
      dispatch(
        setTransactionTableDownloadFile({
          base64: "",
          isDownloading: false,
          isDownloadingCheck: false,
        })
      );
    });
};

export const getRetentionFile = (
  request: RetentionFileRequest
): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
  ): void => {
    const url: string = `${environment.kushkiUrl}/billing-core/v1/retention/file`;
    axios
      .post<{ data: string }>(url, request)
      .then((response: AxiosResponse<{ data: string }>) => {
        downloadBase64toCSV(response.data.data, `${Date.now()}.csv`);
        dispatch(
          setIsDownloadingFile({ isDownloadingCheck: false, isLoading: false })
        );
      })
      .catch(() => {
        dispatch(
          setIsDownloadingFile({ isDownloadingCheck: false, isLoading: false })
        );
      });
  };
};

export const downloadTransactionTableFile = (
  country: string,
  date: string,
  statusflow: StatusFlowEnum,
  transactionIds?: string[],
  documentType?: string
): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
  ): void => {
    dispatch(
      setTransactionTableDownloadFile({
        base64: "",
        isDownloadingCheck: true,
        isDownloading: false,
      })
    );
    let data = { country, date, statusflow, transactionIds, documentType };
    axios
      .post<{ data: string }>(
        `${environment.kushkiUrl}/billing-core/v1/transactionsFile`,
        data
      )
      .then((response: AxiosResponse<{ data: string }>) => {
        dispatch(
          setTransactionTableDownloadFile({
            base64: response.data.data,
            isDownloadingCheck: false,
            isDownloading: false,
          })
        );
      })
      .catch(() => {
        dispatch(
          setTransactionTableDownloadFile({
            base64: "",
            isDownloadingCheck: false,
            isDownloading: false,
          })
        );
      });
  };
};

const showOpenErrorNotification = (
  dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
) => (errorMessage: string) => {
  dispatch(
    setNotification({
      message: errorMessage,
      open: true,
      type: "error",
    })
  );
};

const downloadBase64toCSV = (fileBase64: string, filename: string) => {
  const a = document.createElement("a");
  a.href = "data:application/octet-stream;base64," + fileBase64;
  a.download = filename;
  a.click();
};

const ERROR_WHEN_DOWNLOADING_FILE =
  "Ha ocurrido un error al descargar el archivo";
const NO_FILE_FOR_DOWNLOADING = "No existen registros a descargar";
const ERROR_ANNUL_INVOICE = "Error al realizar anulación, intente más tarde";
const ERROR_ANNUL_INVOICE_MX = "Solicitud de anulación fallida";
const ERROR_CREDIT_NOTE =
  "Error al generar la nota de crédito, intente más tarde";
const ERROR_INVOICE = "Error al generar Factura, intente más tarde";

export const downloadPreprocessFileWS = (
  params: DownloadDocumentParams
): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
  ): void => {
    const showError = showOpenErrorNotification(dispatch);
    let webSocket: WebSocket | undefined;
    try {
      dispatch(setLoadingDownloadPreProcess(true));
      webSocket = new WebSocket(
        `${environment.downloadWSPreprocess}?Authorization=${getJwtAuth()}`
      );

      webSocket.onopen = () => {
        dispatch(setLoadingDownloadPreProcess(true));
        webSocket?.send(JSON.stringify(params));
      };

      webSocket.onerror = (_event) => {
        dispatch(setLoadingDownloadPreProcess(false));
        showError(ERROR_WHEN_DOWNLOADING_FILE);
        webSocket?.close();
      };

      webSocket.onmessage = (event: MessageEvent) => {
        const response: DataWebsocket = JSON.parse(event.data);
        const status: string = get(response, "status", "");
        const message: string = get(response, "message", "");
        const error: boolean = get(response, "error", false);

        if (error) {
          dispatch(setLoadingDownloadPreProcess(false));
          showError(ERROR_WHEN_DOWNLOADING_FILE);
          webSocket?.close();
        } else {
          if (status === StatusWebsocketEnum.complete) {
            dispatch(setLoadingDownloadPreProcess(false));
            dispatch(setDownloadFileDetail(get(response, "url", "")));
          } else if (message === "empty") {
            dispatch(setLoadingDownloadPreProcess(false));
            showError(NO_FILE_FOR_DOWNLOADING);
            webSocket?.close();
          }
        }
      };
    } catch (e) {
      dispatch(setLoadingDownloadPreProcess(false));
      showError(ERROR_WHEN_DOWNLOADING_FILE);
      if (webSocket) webSocket.close();
    }
  };
};

export const downloadManualContingencyWS = (
  params: DownloadManualContingency
): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
  ): void => {
    const showError = showOpenErrorNotification(dispatch);

    let webSocket: WebSocket | undefined;
    try {
      dispatch(setLoadingDownloadManualContingencyPreProcess(true));
      webSocket = new WebSocket(
        `${environment.processWSBillingData}?Authorization=${getJwtAuth()}`
      );

      webSocket.onopen = () => {
        dispatch(setLoadingDownloadManualContingencyPreProcess(true));
        webSocket?.send(JSON.stringify(params));
      };

      webSocket.onerror = (_event) => {
        showError(ERROR_WHEN_DOWNLOADING_FILE);
        dispatch(setLoadingDownloadManualContingencyPreProcess(false));
        webSocket?.close();
      };

      webSocket.onmessage = (event: MessageEvent) => {
        const response: DataWebsocket = JSON.parse(event.data);
        const status: string = get(response, "status", "");
        const message: string = get(response, "message", "");
        const error: boolean = get(response, "error", false);

        if (error) {
          showError(ERROR_WHEN_DOWNLOADING_FILE);
          dispatch(setLoadingDownloadManualContingencyPreProcess(false));
          webSocket?.close();
        } else {
          if (status === StatusWebsocketEnum.complete) {
            dispatch(
              setNotification({
                type: "success",
                message: "Procesado exitosamente",
                open: true,
              })
            );

            dispatch(setLoadingDownloadManualContingencyPreProcess(false));
            dispatch(setDownloadManualContingency(message));
          } else if (message === "empty" || message === "Forbidden") {
            showError(NO_FILE_FOR_DOWNLOADING);
            dispatch(setLoadingDownloadManualContingencyPreProcess(false));
            webSocket?.close();
          }
        }
      };
    } catch (e) {
      dispatch(setLoadingDownloadManualContingencyPreProcess(false));
      showError(ERROR_WHEN_DOWNLOADING_FILE);
      if (webSocket) webSocket.close();
    }
  };
};

export const setDownloadFileDPFS3 = (
  requestData: DownloadInvoicePdf
): BillingActions => {
  return {
    type: ActionTypes.SET_DOWNLOAD_PDF,
    downloadPdf: requestData,
  };
};

export const setDownloadFileDPFS3Loading = (
  requestData: boolean
): BillingActions => {
  return {
    type: ActionTypes.SET_DOWNLOAD_PDF_LOADING,
    downloadPdfLoading: requestData,
  };
};

export const downloadFilePDFS3 = (
  invoiceId: string
): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
  ): void => {
    const url: string = `${environment.kushkiUrl}/invoice/v1/DownloadDocument?id=${invoiceId}`;
    dispatch(setDownloadFileDPFS3Loading(true));
    axios
      .get<DownloadInvoicePdf>(url)
      .then((response: AxiosResponse<DownloadInvoicePdf>) => {
        dispatch(setDownloadFileDPFS3(response.data));
        dispatch(setDownloadFileDPFS3Loading(false));
        dispatch(setDownloadFileDPFS3({}));
      })
      .catch(() => {
        dispatch(setDownloadFileDPFS3({}));
        dispatch(setDownloadFileDPFS3Loading(false));
      });
  };
};

const downloadFilePDFXML = (url: string) => {
  const downloadLink: HTMLAnchorElement = document.createElement("a");
  downloadLink.href = url;
  downloadLink.click();
  window.URL.revokeObjectURL(url);
};

export const downloadFilePDFXMLCSV = (
  data: RetentionDetailFileRequest
): ThunkAction<
  void,
  IBillingDashboardState,
  undefined,
  BillingActions
> => async (
  dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
) => {
  const url: string = `${environment.kushkiUrl}/billing-core/v1/retentionDetailFile`;
  try {
    const resp = await axios.post(url, data);
    if (includes(["pdf", "xml"], data.extension)) {
      downloadFilePDFXML(resp.data.data);
      dispatch(
        setNotification({
          open: true,
          message: "Descarga Completa",
          type: "success",
        })
      );
    } else {
      downloadBase64toCSV(resp.data.data, `${Date.now()}.csv`);
      dispatch(
        setNotification({
          open: true,
          message: "Descarga Completa",
          type: "success",
        })
      );
    }
  } catch {
    dispatch(
      setNotification({
        open: true,
        message: "Error al descargar",
        type: "error",
      })
    );
  }
};

export const deleteRetention = (
  retentionId: string,
  reason: string
): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
  ): void => {
    const url: string = `${environment.kushkiUrl}/retentions/v1/deleteRetention/${retentionId}`;
    dispatch(setIsLoadingDeleteRetention(true));
    axios
      .post<DownloadInvoicePdf>(url, { reason })
      .then((response: AxiosResponse) => {
        if (!isEmpty(response)) {
          dispatch(setIsLoadingDeleteRetention(false));
          dispatch(
            setNotification({
              open: true,
              message: "Se ha anulada la retención seleccionada.",
              type: "success",
            })
          );
        }
      })
      .catch(() => {
        dispatch(setIsLoadingDeleteRetention(false));
      });
  };
};

export const getRetentionTrx = (
  payload: RentetionDataRequest | ListRetentionRequest
): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
  ): void => {
    dispatch(
      setRetentionData({
        isLoading: true,
        records: [],
      })
    );
    let data: TransactionDynamo[] = [];
    const webSocket: WebSocket | undefined = new WebSocket(
      `${environment.processWSBillingData}?Authorization=${getJwtAuth()}`
    );
    try {
      webSocket.onopen = () => {
        webSocket?.send(JSON.stringify(payload));
      };
      webSocket.onerror = (_event) => {
        dispatch(
          setRetentionData({
            isLoading: false,
            records: [],
          })
        );
        webSocket?.close();
      };
      webSocket.onmessage = (event: MessageEvent) => {
        const response: DataWebsocket = JSON.parse(event.data);
        data.push(
          ...transformRetentionData(defaultTo(get(response, "data"), []))
        );
        dispatch(
          setRetentionData({
            isLoading: response.status === "pending",
            records: data,
          })
        );
        if (response.status === StatusTransactionEnum.COMPLETE) {
          webSocket?.close();
        }
      };
    } catch (e) {
      dispatch(
        setRetentionData({
          isLoading: false,
          records: [],
        })
      );
      webSocket?.close();
    }
  };
};

export const getPDFRetentionsCo = (
  transactionId: string,
  kindRetention: KindRetentionEnum
): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
  ): void => {
    const url: string = `${environment.kushkiUrl}/billing-core/v1/retention/receipt/${transactionId}`;
    const config: AxiosRequestConfig = {
      params: {
        kindRetention,
      },
    };
    axios
      .get(url, config)
      .then((response: AxiosResponse<string>) => {
        dispatch(setPDFRetention(get(response.data, "data", "")));
      })
      .catch(() => {
        dispatch(setPDFRetention(""));
        dispatch(
          setNotification({
            open: true,
            message: MessageNotificationEnum.errorGetPDF,
            type: "error",
          })
        );
      });
  };
};

export const setPDFRetention = (payload: string) => {
  return {
    type: ActionTypes.SET_PDF_RETENTION,
    pdfRetention: payload,
  };
};

export const getRetentionTrxPreProccessed = (
  payload: RentetionDataRequest | ListRetentionRequest
): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
  ): void => {
    dispatch(
      setRetentionDataPreProcessed({
        isLoading: true,
        records: [],
      })
    );
    const webSocket: WebSocket | undefined = new WebSocket(
      `${environment.processWSBillingData}?Authorization=${getJwtAuth()}`
    );
    try {
      webSocket.onopen = () => {
        webSocket?.send(JSON.stringify(payload));
      };
      webSocket.onerror = (_event) => {
        dispatch(
          setRetentionDataPreProcessed({
            isLoading: false,
            records: [],
          })
        );
        webSocket?.close();
      };
      webSocket.onmessage = (event: MessageEvent) => {
        const response: DataWebsocket = JSON.parse(event.data);
        if (Array.isArray(get(response, "data"))) {
          dispatch(
            setRetentionDataPreProcessed({
              isLoading: response.status === StatusEnum.PRE_PROCESSED,
              records: transformRetentionPreProcessedData(
                defaultTo(get(response, "data"), [])
              ),
            })
          );
        }
        if (response.status === StatusTransactionEnum.COMPLETE) {
          webSocket?.close();
        }
      };
    } catch (e) {
      dispatch(
        setRetentionDataPreProcessed({
          isLoading: false,
          records: [],
        })
      );
      webSocket?.close();
    }
  };
};

export const getAnnulledFile = (
  evidence: EvidenceData
): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
  ): void => {
    const url: string = `${environment.kushkiUrl}/billing-core/v1/getFileS3Evidence`;
    dispatch(setDownloadFileEvidence({}));
    axios
      .post(url, {
        ...evidence,
        reason: defaultTo(get(evidence, "reason"), ""),
      })
      .then((response: AxiosResponse) => {
        dispatch(setDownloadFileEvidence(response.data));
      })
      .catch(console.error);
  };
};

export const setDownloadFileEvidence = (
  requestData: DownloadInvoicePdf
): BillingActions => {
  return {
    type: ActionTypes.SET_DOWNLOAD_FILE_EVIDENCE,
    downloadFileEvidence: requestData,
  };
};

export const invoiceAnnul = (
  data: InvoiceAnnulRequest,
  newInvoice: boolean
): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>,
    getState: () => IBillingDashboardState
  ): void => {
    const showError = showOpenErrorNotification(dispatch);
    const url: string = `${environment.kushkiUrl}/billing-core/v1/annulInvoice`;
    const state: IBillingDashboardState = getState();

    axios
      .post(url, data)
      .then(() => {
        if (newInvoice && state.country !== CountryEnum.mexico) {
          dispatch(setRedirectCreateInvoice(newInvoice));
        } else {
          location.href = "/billing-dashboard/executor";
          dispatch(
            setNotification({
              open: true,
              message: MessageNotificationEnum.annulInvoice,
              type: "success",
            })
          );
        }
      })
      .catch(() => {
        if (state.country === CountryEnum.mexico)
          showError(ERROR_ANNUL_INVOICE_MX);
        else showError(ERROR_ANNUL_INVOICE);
      });
  };
};

export const generateCreditNote = (
  data: CreditNoteRequest,
  newInvoice: boolean
): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
  ): void => {
    const showError = showOpenErrorNotification(dispatch);

    const url: string = `${environment.kushkiUrl}/billing-core/v1/createCreditDebitNote`;
    axios
      .post(url, data)
      .then(() => {
        dispatch(setOpenCreditDebitNoteModal(false));
        if (!newInvoice) location.href = "/billing-dashboard/executor";
        dispatch(setRedirectCreateInvoice(newInvoice));
      })
      .catch(() => {
        showError(ERROR_CREDIT_NOTE);
      });
  };
};

export const createInvoice = (
  invoiceRequest: RenewInvoiceRequest
): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
  ): void => {
    const showError = showOpenErrorNotification(dispatch);
    dispatch(setLoadingInvoice(true));
    const url: string = `${environment.kushkiUrl}/billing-core/v1/renewInvoice`;
    axios
      .post(url, invoiceRequest)
      .then(() => {
        dispatch(setLoadingInvoice(false));
        dispatch(
          setNotification({
            open: true,
            message: MessageNotificationEnum.createInvoice,
            type: "success",
          })
        );
      })
      .catch(() => {
        dispatch(setLoadingInvoice(false));
        showError(ERROR_INVOICE);
      });
  };
};

export const setTransactionIdArray = (payload: {
  id: string[];
  data: TransactionTable[];
}) => {
  return {
    type: ActionTypes.SET_TRANSACTIONID_ADD,
    transaction: payload,
  };
};

export const saveInconsistenceModify = (
  trxModify: InvoiceRecord
): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>,
    getState: () => IBillingDashboardState
  ): void => {
    const { retentionData } = getState();
    if (retentionData.records && retentionData.records.length > 0) {
      retentionData.records = retentionData.records.map(
        (item: TransactionDynamo) => {
          return modifyRetentionField(item, trxModify);
        }
      );

      dispatch(setRetentionData(retentionData));
    }
    dispatch(setOpenModalModifyInconsistence(false));
    dispatch(
      setNotification({
        open: true,
        message: "Se modificaron los montos de la retención con éxito",
        type: "dark",
      })
    );
  };
};

export const transactionIdFilter = () => (
  dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>,
  getState: () => IBillingDashboardState
) => {
  const state = getState();
  let arrayId: string[] = [];

  map(state.billingData, (value: TransactionTable) => {
    if (value.statusAll === true) {
      arrayId.push(value.transaction?.transactionId!);
    }
  });

  const arrayIdTras: TransactionTable[] = filter(
    state.billingData,
    (value: TransactionTable) => value.statusAll! === true
  );

  dispatch(setTransactionIdArray({ id: arrayId, data: arrayIdTras }));
};

export const setTransactionIdArrayHistory = (payload: {
  id: string[];
  data: InvoiceRecord;
}) => {
  return {
    type: ActionTypes.SET_TRANSACTIONIDHISTORY_ADD,
    transactionHistory: payload,
  };
};

export const setTransactionDefaultHistory = () => {
  return {
    type: ActionTypes.SET_TRANSACTIONIDHISTORY_DEFAULT,
  };
};

export const transactionIdFilterHistory = () => (
  dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>,
  getState: () => IBillingDashboardState
) => {
  const state = getState();
  let arrayId: string[] = [];
  map(state.historicBillingData.records, (value: InvoiceRecord) => {
    if (value.statusAll === true) {
      arrayId.push(value.transaction_id);
    }
  });

  const arrayIdTras: InvoiceRecord = filter(
    state.historicBillingData.records,
    (value: InvoiceRecord) => value.statusAll! === true
  );

  dispatch(setTransactionIdArrayHistory({ id: arrayId, data: arrayIdTras }));
};

export const annulDeadLineValidate = () => async (
  dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>,
  getState: () => IBillingDashboardState
) => {
  const trx = getState().historicTrx;

  if (
    trx.country !== CountryEnum.mexico ||
    isEmpty(trx) ||
    get(trx, "kind", "") !== KindEnum.INVOICE ||
    get(trx, "status", "") !== StatusEnum.COMPLETED
  ) {
    dispatch({
      type: ActionTypes.SET_ANNUL_DEAD_LINE_VALIDATE,
      annulDeadLineValidate: {
        value: true,
        text: "",
      },
    });
    return;
  }

  const url = `${environment.kushkiUrl}/billing-core/v1/merchant/annul-deadline`;
  const res = await axios.get(url);
  const annulDeadline = res.data;

  if (isNil(annulDeadline)) return;

  const annulDeadlineYear: string = split(defaultTo(annulDeadline, ""), "/")[2];
  const createdYear: string = moment(get(trx, "created", "")).format("YYYY");
  const annulDeadlineDateTransformed: string = `${
    split(defaultTo(annulDeadline, ""), "/")[1]
  }-${split(defaultTo(annulDeadline, ""), "/")[0]}-${
    split(defaultTo(annulDeadline, ""), "/")[2]
  }`;
  const annulDeadlineDate = new Date(annulDeadlineDateTransformed);
  const annulationDate = new Date();
  const annulAvailable: boolean =
    createdYear === annulDeadlineYear ||
    (+createdYear + 1 === +annulDeadlineYear &&
      annulationDate.getTime() < annulDeadlineDate.getTime());
  const disableButton: boolean = !annulAvailable;
  const textToDisplay: string = annulAvailable
    ? ""
    : "El documento ha excedido el plazo máximo para anular";

  dispatch({
    type: ActionTypes.SET_ANNUL_DEAD_LINE_VALIDATE,
    annulDeadLineValidate: {
      value: disableButton,
      text: textToDisplay,
    },
  });
};

export const setDispersionId = (dispersionId: string) => {
  return {
    type: ActionTypes.SET_DISPERSION_ID,
    dispersionId: dispersionId,
  };
};

export const setChargeId = (chargeId: string) => {
  return {
    type: ActionTypes.SET_CHARGE_ID,
    chargeId: chargeId,
  };
};

export const getProcessFileDispersionId = (
  country: CountryEnum
): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
  ): void => {
    getProcessFileIdByKind(
      KindEnum.DISPERSION,
      setDispersionId,
      country,
      dispatch
    );
  };
};

export const getProcessFileChargeId = (
  country: CountryEnum
): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
  ): void => {
    getProcessFileIdByKind(KindEnum.CHARGE, setChargeId, country, dispatch);
  };
};

const getProcessFileIdByKind = (
  kind: KindEnum,
  setKindId: (kindId: string) => BillingActions,
  country: CountryEnum,
  dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
) => {
  const url: string = `${environment.kushkiUrl}/dispersions/v1/dispersion/configuration/process-file`;
  axios
    .post(url, {
      country,
      cycle: format(new Date(), "yyyy-MM-dd"),
      generates: true,
      kind,
    })
    .then((kind_response: AxiosResponse) => {
      dispatch(setKindId(kind_response.data.id));
    })
    .catch(() => {
      dispatch(
        setNotification({
          open: true,
          message: "Error al obtener process file id.",
          type: "error",
        })
      );
    });
};

export const setCatalogList = (catalog: Catalog[]) => {
  return {
    type: ActionTypes.SET_CATALOG_LIST,
    catalogsList: catalog,
  };
};
export const getCatalogsList = (
  params: CatalogRequest
): ThunkAction<void, IBillingDashboardState, undefined, BillingActions> => {
  return (
    dispatch: ThunkDispatch<IBillingDashboardState, any, BillingActions>
  ): void => {
    const url: string = `${environment.kushkiUrl}/catalog/v1/list-catalog`;
    axios
      .post(url, params)
      .then((axios_response: AxiosResponse<Catalog[]>) => {
        const response = axios_response.data;
        dispatch(setCatalogList(response));
      })
      .catch(() => {
        dispatch(
          setNotification({
            open: true,
            message: "Error al obtener catalogo.",
            type: "error",
          })
        );
      });
  };
};
