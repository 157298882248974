import React from "react";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import { isEmpty } from "lodash";
import { translateRuleKeys } from "../../shared/utils";
import { IOptionSelection } from "../../shared/infrastructure/constants/RulesConstants";
import { RulesTableState } from "./state/RulesTableState";
import { CardContainer } from "../../components/CardContainer/CardContainer";
import { SkeletonTableContainer } from "../../components/Skeletons/SkeletonTableContainer/SkeletonTableContainer";

const useStyles = makeStyles(() => ({
  tableContainer: {
    background: "transparent",
    paddingBottom: "25px",
    boxShadow: "none",
    width: "50%",
  },
  tableCell: { paddingTop: "10px", paddingBottom: "10px" },
  width200: {
    width: "200px",
  },
}));

export const StyledTableCelll = withStyles(
  createStyles({
    head: {
      backgroundColor: "#F7FAFC",
      color: "#293036",
      fontWeight: "bold",
    },
    body: {
      fontSize: 14,
      "font-weight": 500,
    },
  })
)(TableCell);

export const RULES_ACTIONS: IOptionSelection[] = [
  { name: "Enviar a", value: "route" },
  /*{ name: "Alertar", value: "warning" },*/
];

export type IRulesTable = {
  merchantId: string;
};

export const RulesTable: React.FC<IRulesTable> = (props: IRulesTable) => {
  const classes = useStyles();
  const { actions, rules, isRuleLoaded } = RulesTableState(props);

  return (
    <>
      {!isRuleLoaded ? (
        <SkeletonTableContainer rows={2} cells={4} />
      ) : (
        <CardContainer
          key={"1"}
          headerCardTitle={"Reglas de Negocio"}
          headerButtonTitle={"Agregar regla"}
          headerButtonOnClick={actions.handleRedirectActionRule}
          footerCardHrefLocation={`/rules/business/${props.merchantId}`}
          footerTitleHrefLocation={"Edita y revisa todas las reglas de negocio"}
          buttonCreate={true}
          childrenArrayCardComponent={[
            <TableContainer className={classes.tableContainer} key={"1"}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {["Alias", "Acción"].map((header: string) => (
                      <StyledTableCelll
                        key={header}
                        align="left"
                        className={
                          header !== "Failover"
                            ? classes.tableCell
                            : `${classes.tableCell} ${classes.width200}`
                        }
                      >
                        <strong>{header}</strong>
                      </StyledTableCelll>
                    ))}
                  </TableRow>
                </TableHead>
                {!isEmpty(rules) &&
                  rules!
                    .slice(0, 5)
                    .map(({ alias = "", action = "" }, index) => (
                      <TableRow key={index}>
                        <TableCell
                          align="left"
                          component="th"
                          scope="row"
                          className={classes.tableCell}
                        >
                          {alias}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {translateRuleKeys(action, RULES_ACTIONS)}
                        </TableCell>
                      </TableRow>
                    ))}
              </Table>
            </TableContainer>,
          ]}
        />
      )}
    </>
  );
};
