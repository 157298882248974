import {
  HeaderCellProps,
  ITableCellProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import {
  ChipProcessorStatusEnum,
  DeferredStatus,
  ProcessorStatus,
} from "../status-enum";
import { ICellRow, ICellText } from "@kushki/connect-ui";
import { TableBodyCellEnum } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/constants";
import { ReactElement } from "react";
import { DeferredCountriesEnum } from "../countries-enum";

enum CELL_TYPES {
  TEXT = "text",
  TEXT_ACTION = "TEXT_ACTION",
  TAG = "tag",
  CHIPS = "chips",
  BASE = "base",
}

export type CustomHeaderCellProps = HeaderCellProps & {
  cellType: CELL_TYPES;
  key: string;
};

enum tableProperties {
  PROCESSOR = "processor",
  PARTNERSHIP = "partnership",
  DEFERRED_TYPE = "deferred_type",
  MONTHS = "months",
  GRACE_MONTS = "grace_months",
  STATUS = "status",
  BRAND = "brand",
  SCHEDULED = "scheduled",
}

export const ALL_TABLE_COLUMNS: Record<
  DeferredCountriesEnum,
  CustomHeaderCellProps[]
> = {
  Brazil: [
    {
      align: "left",
      cellType: CELL_TYPES.TEXT_ACTION,
      key: tableProperties.PROCESSOR,
      spacing: 0,
      text: "Procesador",
    },
    {
      align: "left",
      cellType: CELL_TYPES.BASE,
      key: tableProperties.BRAND,
      spacing: 0,
      text: "Tarjetas",
    },
    {
      align: "left",
      cellType: CELL_TYPES.CHIPS,
      key: tableProperties.MONTHS,
      spacing: 0,
      text: "Cuotas",
    },
    {
      align: "left",
      cellType: CELL_TYPES.TEXT,
      key: tableProperties.SCHEDULED,
      spacing: 0,
      text: "Fecha Programada",
    },
    {
      align: "left",
      cellType: CELL_TYPES.TAG,
      key: tableProperties.STATUS,
      spacing: 0,
      text: "Estado",
    },
  ],
  CentroAmerica: [
    {
      align: "left",
      cellType: CELL_TYPES.TEXT_ACTION,
      key: tableProperties.PROCESSOR,
      spacing: 0,
      text: "Procesador",
    },
    {
      align: "left",
      cellType: CELL_TYPES.TEXT,
      key: tableProperties.DEFERRED_TYPE,
      spacing: 0,
      text: "Tipo de diferido",
    },
    {
      align: "left",
      cellType: CELL_TYPES.CHIPS,
      key: tableProperties.MONTHS,
      spacing: 0,
      text: "Cuotas",
    },
    {
      align: "left",
      cellType: CELL_TYPES.TAG,
      key: tableProperties.STATUS,
      spacing: 0,
      text: "Estado",
    },
  ],
  Ecuador: [
    {
      align: "left",
      cellType: CELL_TYPES.TEXT_ACTION,
      key: tableProperties.PROCESSOR,
      spacing: 0,
      text: "Procesador",
    },
    {
      align: "left",
      cellType: CELL_TYPES.TEXT,
      key: tableProperties.DEFERRED_TYPE,
      spacing: 0,
      text: "Tipo de diferido",
    },
    {
      align: "left",
      cellType: CELL_TYPES.CHIPS,
      key: tableProperties.MONTHS,
      spacing: 0,
      text: "Cuotas",
    },
    {
      align: "left",
      cellType: CELL_TYPES.CHIPS,
      key: tableProperties.GRACE_MONTS,
      spacing: 0,
      text: "Meses de gracia",
    },
    {
      align: "left",
      cellType: CELL_TYPES.TAG,
      key: tableProperties.STATUS,
      spacing: 0,
      text: "Estado",
    },
  ],
  Mexico: [
    {
      align: "left",
      cellType: CELL_TYPES.TEXT_ACTION,
      key: tableProperties.PROCESSOR,
      spacing: 0,
      text: "Procesador",
    },
    {
      align: "left",
      cellType: CELL_TYPES.TEXT,
      key: tableProperties.PARTNERSHIP,
      spacing: 0,
      text: "Consorcio",
    },
    {
      align: "left",
      cellType: CELL_TYPES.TEXT,
      key: tableProperties.DEFERRED_TYPE,
      spacing: 0,
      text: "Tipo de diferido",
    },
    {
      align: "left",
      cellType: CELL_TYPES.CHIPS,
      key: tableProperties.MONTHS,
      spacing: 0,
      text: "Cuotas",
    },
    {
      align: "left",
      cellType: CELL_TYPES.CHIPS,
      key: tableProperties.GRACE_MONTS,
      spacing: 0,
      text: "Meses de gracia",
    },
    {
      align: "left",
      cellType: CELL_TYPES.TAG,
      key: tableProperties.STATUS,
      spacing: 0,
      text: "Estado",
    },
  ],
};

export const colorDeferredSelector = (
  status: string
): { color: string; label: string } => {
  switch (status) {
    case ProcessorStatus.ENABLED:
      return { color: "success", label: ChipProcessorStatusEnum.ENABLED };
    case ProcessorStatus.DISABLED:
      return { color: "warning", label: ChipProcessorStatusEnum.DISABLED };
    case DeferredStatus.ACTIVE:
      return { color: "success", label: "ACTIVO" };
    case DeferredStatus.INACTIVE:
      return { color: "error", label: "INACTIVO" };
    case DeferredStatus.DISABLED:
      return { color: "error", label: "INACTIVO" };
    case DeferredStatus.ENABLED:
      return { color: "success", label: "ACTIVO" };
    default:
      return { color: "primary", label: "" };
  }
};

export const TAG_CELL = (status: string) =>
  ({
    props: {
      cellProps: {
        align: "left",
        spacing: 1,
      } as ICellRow,
      color: colorDeferredSelector(status).color,
      text: colorDeferredSelector(status).label,
    },
    type: "TAG" as TableBodyCellEnum,
  } as ITableCellProps);

export const TEXT_CELL = (line1: string = "", line2?: string) =>
  ({
    props: {
      cellProps: {
        align: "left",
        spacing: 1,
      },
      line1,
      line2,
      type: line2 ? "twoLines" : "oneLine",
    } as ICellText,
    type: "TEXT" as TableBodyCellEnum,
  } as ITableCellProps);

export const CHIPS_CELL = (
  items: string[],
  onExpandable: (items: string[]) => void
) =>
  ({
    props: {
      cellProps: {
        align: "left",
        spacing: 1,
      } as ICellRow,
      color: "success",
      items: items,
      onExpandable,
    },
    type: "CHIPS" as TableBodyCellEnum,
  } as ITableCellProps);

export const TEXT_ACTION_CELL = (text: string, icon: ReactElement) =>
  ({
    props: {
      cellProps: {
        align: "left",
        spacing: 1,
      } as ICellRow,
      icon: icon,
      line1: text,
      onSelected: (item) => {
        navigator.clipboard.writeText(item.line1);
      },
    },
    type: "TEXT_ACTION" as TableBodyCellEnum,
  } as ITableCellProps);

export const TEXT_TOOLTIP_CELL = (
  text1: string,
  text2: string,
  tooltipText: string
) =>
  ({
    props: {
      align: "left",
      cellProps: {
        align: "left",
        spacing: 1,
      } as ICellRow,
      icon: "right",
      line1: text1,
      line2: text2,
      tooltipText: tooltipText,
      type: "twoLines",
    },
    type: "TEXT_TOOLTIP" as TableBodyCellEnum,
  } as ITableCellProps);

export const BASE_CELL = (icon: ReactElement) =>
  ({
    props: {
      cellProps: {
        align: "left",
        spacing: 1,
      } as ICellRow,
      child: icon,
    },
    type: "BASE" as TableBodyCellEnum,
  } as ITableCellProps);

export enum DeferredRedirectTitleEnum {
  LABEL_CONFIG_DEFERRED = "Configurar diferidos",
  LABEL_EDIT_DEFERRED = "Edita y revisa todos los diferidos",
  LABEL_REVIEW_DEFERRED = "Revisa todos los diferidos",
  LABEL_DEFAULT = "",
}
