import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

const CellTextActionStyles = createNamedStyles({
  textActionActive: {
    color: "#293036",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19.6px",
    cursor: "pointer",
    textDecoration: "underline",
  },
  textActionInactive: {
    color: "rgb(180,184,188)",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19.6px",
    textDecoration: "none",
  },
});

export { CellTextActionStyles };
