/* istanbul ignore file */

import { IOptionSelection } from "../interfaces/IOptionSelection";

export const BANKS_BRAZIL: IOptionSelection[] = [
  { value: "00000000", label: "BANCO DO BRASIL S.A." },
  { value: "00000208", label: "BRB - BANCO DE BRASILIA S.A." },
  { value: "00250699", label: "AGK CORRETORA DE CAMBIO S.A." },
  { value: "00360305", label: "CAIXA ECONOMICA FEDERAL" },
  { value: "00416968", label: "BANCO INTER S.A." },
  {
    value: "00460065",
    label: "COLUNA S/A DISTRIBUIDORA DE TITULOS E VALORES MOBILIÁRIOS",
  },
  { value: "00558456", label: "BANCO CETELEM S.A." },
  { value: "00795423", label: "BANCO SEMEAR S.A." },
  { value: "00806535", label: "PLANNER CORRETORA DE VALORES S.A." },
  { value: "00997185", label: "BANCO B3 S.A." },
  { value: "01023570", label: "BANCO RABOBANK INTERNATIONAL BRASIL S.A." },
  { value: "01181521", label: "BANCO COOPERATIVO SICREDI S.A." },
  { value: "01522368", label: "BANCO BNP PARIBAS BRASIL S.A." },
  { value: "01701201", label: "KIRTON BANK S.A. - BANCO MÚLTIPLO" },
  { value: "02038232", label: "BANCO COOPERATIVO SICOOB S.A. - BANCO SICOOB" },
  { value: "02318507", label: "BANCO KEB HANA DO BRASIL S.A." },
  {
    value: "02332886",
    label:
      "XP INVESTIMENTOS CORRETORA DE CAMBIO,TÍTULOS E VALORES MOBILIÁRIOS S/A",
  },
  { value: "02801938", label: "BANCO MORGAN STANLEY S.A." },
  { value: "02992317", label: "TREVISO CORRETORA DE CAMBIO S.A." },
  { value: "03012230", label: "HIPERCARD BANCO MÚLTIPLO S.A." },
  { value: "03017677", label: "BANCO J. SAFRA S.A." },
  { value: "03443143", label: "AVIPAM CORRETORA DE CAMBIO LTDA" },
  { value: "03532415", label: "BANCO ABN AMRO S.A." },
  { value: "03609817", label: "BANCO CARGILL S.A." },
  { value: "04062902", label: "OZ CORRETORA DE CAMBIO S.A." },
  { value: "04332281", label: "GOLDMAN SACHS DO BRASIL BANCO MULTIPLO S.A." },
  {
    value: "04684647",
    label: "ARC CORRETORA DE CAMBIO, ASSOCIADOS GOUVEIA, CAMPEDELLI S.A.",
  },
  { value: "04866275", label: "BANCO INBURSA S.A." },
  { value: "04902979", label: "BANCO DA AMAZONIA S.A." },
  { value: "04913129", label: "CONFIDENCE CORRETORA DE CAMBIO S.A." },
  { value: "04913711", label: "BANCO DO ESTADO DO PARÁ S.A." },
  {
    value: "05816451",
    label:
      "GENIAL INSTITUCIONAL CORRETORA DE CAMBIO, TÍTULOS E VALORES MOBILIÁRIO",
  },
  { value: "06132348", label: "LABOR SOCIEDADE CORRETORA DE CAMBIO LTDA." },
  {
    value: "06373777",
    label: "BOA VIAGEM SOCIEDADE CORRETORA DE CAMBIO LTDA.",
  },
  { value: "07237373", label: "BANCO DO NORDESTE DO BRASIL S.A." },
  { value: "07333726", label: "ONNIX CORRETORA DE CAMBIO LTDA." },
  {
    value: "07450604",
    label: "CHINA CONSTRUCTION BANK (BRASIL) BANCO MÚLTIPLO S/A",
  },
  { value: "07656500", label: "BANCO KDB DO BRASIL S.A." },
  { value: "07679404", label: "BANCO TOPÁZIO S.A." },
  { value: "08520517", label: "SOL CORRETORA DE CAMBIO LTDA." },
  { value: "08609934", label: "MONEYCORP BANCO DE CAMBIO S.A." },
  { value: "09274232", label: "STATE STREET BRASIL S.A. - BANCO COMERCIAL" },
  { value: "09512542", label: "CODEPE CORRETORA DE VALORES E CAMBIO S.A." },
  { value: "09516419", label: "PICPAY BANK - BANCO MÚLTIPLO S.A" },
  { value: "10690848", label: "BANCO DA CHINA BRASIL S.A." },
  { value: "10853017", label: "GET MONEY CORRETORA DE CAMBIO S.A." },
  {
    value: "11495073",
    label: "OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
  },
  { value: "11703662", label: "TRAVELEX BANCO DE CAMBIO S.A." },
  {
    value: "12392983",
    label:
      "MIRAE ASSET WEALTH MANAGEMENT (BRAZIL) CORRETORA DE CAMBIO, TÍTULOS E",
  },
  { value: "13059145", label: "BEXS BANCO DE CAMBIO S/A" },
  { value: "13220493", label: "BR PARTNERS BANCO DE INVESTIMENTO S.A." },
  {
    value: "13673855",
    label: "FRAM CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
  },
  { value: "13720915", label: "BANCO WESTERN UNION DO BRASIL S.A." },
  { value: "13728156", label: "WESTERN UNION CORRETORA DE CAMBIO S.A." },
  { value: "14190547", label: "CAMBIONET CORRETORA DE CAMBIO LTDA." },
  { value: "14388334", label: "PARANÁ BANCO S.A." },
  { value: "15077393", label: "MEGA CORRETORA DE CAMBIO LTDA." },
  { value: "15114366", label: "BANCO BOCOM BBM S.A." },
  { value: "15122605", label: "LÚMINA CORRETORA DE CAMBIO LTDA." },
  { value: "15168152", label: "CONSEGTUR CORRETORA DE CAMBIO LTDA." },
  { value: "15357060", label: "BANCO WOORI BANK DO BRASIL S.A." },
  { value: "15482499", label: "TURCAMBIO - CORRETORA DE CAMBIO LTDA." },
  { value: "15761217", label: "CORRETORA DE CAMBIO AÇORIANA LIMITADA." },
  { value: "16854999", label: "SINGRATUR CORRETORA DE CAMBIO LTDA" },
  { value: "16927221", label: "AMAZÔNIA CORRETORA DE CAMBIO LTDA." },
  { value: "16944141", label: "BROKER BRASIL CORRETORA DE CAMBIO LTDA." },
  { value: "17184037", label: "BANCO MERCANTIL DO BRASIL S.A." },
  { value: "17298092", label: "BANCO ITAÚ BBA S.A." },
  {
    value: "17312083",
    label: "H H PICCHIONI S/A CORRETORA DE CAMBIO E VALORES MOBILIARIOS",
  },
  {
    value: "17312661",
    label: "AMARIL FRANKLIN CORRETORA DE TÍTULOS E VALORES LTDA",
  },
  {
    value: "17354911",
    label: "COTACAO DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS S.A",
  },
  { value: "17453575", label: "ICBC DO BRASIL BANCO MÚLTIPLO S.A." },
  { value: "17508380", label: "INVEST CORRETORA DE CAMBIO LTDA." },
  { value: "17635177", label: "CONEXION CORRETORA DE CAMBIO LTDA." },
  { value: "17772370", label: "VIP'S CORRETORA DE CAMBIO LTDA." },
  { value: "17904906", label: "BRX CORRETORA DE CAMBIO LTDA." },
  { value: "18145784", label: "NUMATUR CORRETORA DE CAMBIO LTDA." },
  { value: "18287740", label: "CONECTA CORRETORA DE CAMBIO LTDA." },
  { value: "18520834", label: "UBS BRASIL BANCO DE INVESTIMENTO S.A." },
  { value: "19086249", label: "EXECUTIVE CORRETORA DE CAMBIO LTDA." },
  { value: "19307785", label: "MS BANK S.A. BANCO DE CAMBIO" },
  {
    value: "20155248",
    label: "PARMETAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
  },
  {
    value: "21040668",
    label: "GLOBAL EXCHANGE DO BRASIL SOCIEDADE CORRETORA DE CAMBIO LTDA.",
  },
  { value: "23010182", label: "GOOD CORRETORA DE CAMBIO LTDA" },
  { value: "23522214", label: "COMMERZBANK BRASIL S.A. - BANCO MÚLTIPLO" },
  { value: "24074692", label: "GUITTA CORRETORA DE CAMBIO LTDA." },
  { value: "25280945", label: "AVS CORRETORA DE CAMBIO LTDA." },
  {
    value: "27842177",
    label: "IB CORRETORA DE CAMBIO, TÍTULOS E VALORES MOBILIÁRIOS S.A.",
  },
  {
    value: "28048783",
    label: "ELITE CORRETORA DE CAMBIO E VALORES MOBILIÁRIOS LTDA",
  },
  {
    value: "28127603",
    label: "BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO",
  },
  { value: "28195667", label: "BANCO ABC BRASIL S.A." },
  {
    value: "28650236",
    label: "BS2 DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
  },
  { value: "28762249", label: "SADOC SOCIEDADE CORRETORA DE CAMBIO LTDA." },
  { value: "28811341", label: "STONEX BANCO DE CAMBIO S.A." },
  { value: "29030467", label: "SCOTIABANK BRASIL S.A. BANCO MÚLTIPLO" },
  { value: "30183111", label: "TURMA CORRETORA DE CAMBIO LTDA" },
  { value: "30306294", label: "BANCO BTG PACTUAL S.A." },
  { value: "30723886", label: "BANCO MODAL S.A." },
  { value: "31872495", label: "BANCO C6 S.A." },
  { value: "31895683", label: "BANCO INDUSTRIAL DO BRASIL S.A." },
  { value: "32648370", label: "FAIR CORRETORA DE CAMBIO S.A." },
  { value: "33042151", label: "BANCO DE LA NACION ARGENTINA" },
  { value: "33042953", label: "CITIBANK N.A." },
  { value: "33172537", label: "BANCO J.P. MORGAN S.A." },
  { value: "33264668", label: "BANCO XP S.A." },
  { value: "33466988", label: "BANCO CAIXA GERAL - BRASIL S.A." },
  { value: "33479023", label: "BANCO CITIBANK S.A." },
  {
    value: "33657248",
    label: "BANCO NACIONAL DE DESENVOLVIMENTO ECONOMICO E SOCIAL",
  },
  {
    value: "33851064",
    label: "DILLON S/A DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS",
  },
  { value: "33885724", label: "BANCO ITAÚ CONSIGNADO S.A." },
  {
    value: "33886862",
    label: "MASTER S/A CORRETORA DE CAMBIO, TÍTULOS E VALORES MOBILIÁRIOS",
  },
  {
    value: "33923111",
    label: "BRKB DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
  },
  { value: "33923798", label: "BANCO MASTER S/A" },
  {
    value: "33987793",
    label: "BANCO DE INVESTIMENTOS CREDIT SUISSE (BRASIL) S.A.",
  },
  { value: "34111187", label: "HAITONG BANCO DE INVESTIMENTO DO BRASIL S.A." },
  { value: "34265629", label: "INTERCAM CORRETORA DE CAMBIO LTDA." },
  { value: "34666362", label: "MONOPÓLIO CORRETORA DE CAMBIO LTDA." },
  { value: "36588217", label: "WISE BRASIL CORRETORA DE CAMBIO LTDA" },
  { value: "36658769", label: "BANCO XCMG BRASIL S.A." },
  {
    value: "38486817",
    label: "BANCO DE DESENVOLVIMENTO DE MINAS GERAIS S.A.-BDMG",
  },
  { value: "40333582", label: "PROSEFTUR CORRETORA DE CAMBIO S.A" },
  { value: "40353377", label: "FOURTRADE CORRETORA DE CAMBIO LTDA." },
  { value: "44189447", label: "BANCO DE LA PROVINCIA DE BUENOS AIRES" },
  { value: "45056494", label: "MARMARIS CORRETORA DE CAMBIO LTDA." },
  { value: "45246410", label: "BANCO GENIAL S.A." },
  { value: "46518205", label: "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION" },
  { value: "48795256", label: "BANCO ANDBANK (BRASIL) S.A." },
  { value: "50579044", label: "LEVYCAM - CORRETORA DE CAMBIO E VALORES LTDA." },
  { value: "50585090", label: "BCV - BANCO DE CRÉDITO E VAREJO S.A." },
  { value: "52937216", label: "BEXS CORRETORA DE CAMBIO S/A" },
  { value: "53518684", label: "BANCO HSBC S.A." },
  { value: "54403563", label: "BANCO ARBI S.A." },
  { value: "55230916", label: "INTESA SANPAOLO BRASIL S.A. - BANCO MÚLTIPLO" },
  { value: "58160789", label: "BANCO SAFRA S.A." },
  { value: "58497702", label: "BANCO LETSBANK S.A." },
  { value: "58616418", label: "BANCO FIBRA S.A." },
  { value: "59118133", label: "BANCO LUSO BRASILEIRO S.A." },
  { value: "59285411", label: "BANCO PAN S.A." },
  { value: "59588111", label: "BANCO VOTORANTIM S.A." },
  {
    value: "59615005",
    label: "PATACÃO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
  },
  { value: "60394079", label: "BANCO ITAUBANK S.A." },
  { value: "60498557", label: "BANCO MUFG BRASIL S.A." },
  { value: "60518222", label: "BANCO SUMITOMO MITSUI BRASILEIRO S.A." },
  { value: "60701190", label: "ITAÚ UNIBANCO S.A." },
  { value: "60746948", label: "BANCO BRADESCO S.A." },
  { value: "60770336", label: "BANCO ALFA DE INVESTIMENTO S.A." },
  { value: "60889128", label: "BANCO SOFISA S.A." },
  { value: "61024352", label: "BANCO VOITER S.A." },
  { value: "61033106", label: "BANCO CREFISA S.A." },
  { value: "61088183", label: "BANCO MIZUHO DO BRASIL S.A." },
  { value: "61186680", label: "BANCO BMG S.A." },
  { value: "61444949", label: "SAGITUR CORRETORA DE CAMBIO S.A." },
  { value: "61533584", label: "BANCO SOCIETE GENERALE BRASIL S.A." },
  {
    value: "61723847",
    label: "NEON CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
  },
  { value: "61820817", label: "BANCO PAULISTA S.A." },
  {
    value: "62073200",
    label: "BANK OF AMERICA MERRILL LYNCH BANCO MÚLTIPLO S.A.",
  },
  {
    value: "62090873",
    label: "STONEX DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
  },
  { value: "62144175", label: "BANCO PINE S.A." },
  { value: "62232889", label: "BANCO DAYCOVAL S.A." },
  {
    value: "62280490",
    label: "DIBRAN DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
  },
  {
    value: "62285390",
    label: "SINGULARE CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
  },
  { value: "62331228", label: "DEUTSCHE BANK S.A. - BANCO ALEMAO" },
  { value: "65913436", label: "GUIDE INVESTIMENTOS S.A. CORRETORA DE VALORES" },
  { value: "68900810", label: "BANCO RENDIMENTO S.A." },
  { value: "71027866", label: "BANCO BS2 S.A." },
  {
    value: "71590442",
    label: "LASTRO RDV DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
  },
  { value: "71677850", label: "FRENTE CORRETORA DE CAMBIO LTDA." },
  {
    value: "73279093",
    label: "PACIFIC INVEST DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA",
  },
  { value: "73302408", label: "EXIM CORRETORA DE CAMBIO LTDA" },
  { value: "73622748", label: "B&T CORRETORA DE CAMBIO LTDA." },
  { value: "74828799", label: "NOVO BANCO CONTINENTAL S.A. - BANCO MÚLTIPLO" },
  { value: "75647891", label: "BANCO CRÉDIT AGRICOLE BRASIL S.A." },
  { value: "76641497", label: "DOURADA CORRETORA DE CAMBIO LTDA." },
  {
    value: "77162881",
    label: "DEBONI DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA",
  },
  { value: "78632767", label: "BANCO OURINVEST S.A." },
  { value: "80202872", label: "CORREPARTI CORRETORA DE CAMBIO LTDA" },
  {
    value: "89784367",
    label:
      "EBADIVAL - E. BAGGIO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LT",
  },
  { value: "90400888", label: "BANCO SANTANDER (BRASIL) S.A." },
  { value: "92702067", label: "BANCO DO ESTADO DO RIO GRANDE DO SUL S.A." },
  { value: "92856905", label: "ADVANCED CORRETORA DE CAMBIO LTDA" },
  { value: "92894922", label: "BANCO ORIGINAL S.A." },
  { value: "94968518", label: "EFX CORRETORA DE CAMBIO LTDA." },
];
