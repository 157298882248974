import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
export interface DeferredDialogListProps {
  listBanksOrMonths: string[];
}

const useStyles = makeStyles(() => ({
  elementList: {
    display: "inline-block",
    marginRight: 5,
    position: "relative",
  },
  merchantMonthsStyle: {
    backgroundColor: "#0DC298",
    borderRadius: "4px",
    color: "white",
    fontSize: "14px",
    height: "20px",
    marginBottom: "6px",
    marginRight: "8px",
  },
  root: {
    paddingLeft: 0,
  },
}));

export const DeferredDialogList: React.FC<DeferredDialogListProps> = (
  props: DeferredDialogListProps
) => {
  const classes = useStyles();

  return (
    <ul className={classes.root} style={{ paddingLeft: 0 }} id="deferred">
      {props.listBanksOrMonths.map((rowElement: string, index: number) => (
        <li className={classes.elementList} key={`${index}_.${rowElement}`}>
          <Chip
            key={`${index}_.${rowElement}`}
            className={classes.merchantMonthsStyle}
            label={rowElement}
          />
        </li>
      ))}
    </ul>
  );
};
