import { FormControl, MenuItem, TextField } from "@material-ui/core";
import { Control, Controller } from "react-hook-form";
import React from "react";
import { isEmpty } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import {
  CONFIGURATION_LABELS,
  LabelEnum,
} from "../../shared/infrastructure/constants/ProcessorsInformationConstant";
import { ProcessorFetch } from "../../shared/infrastructure/interfaces/ProcessorFetch";

export interface IProcessorSelectProps {
  control: Control;
  name: string;
  options: ProcessorFetch[];
}

export const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(4),
    minWidth: "150px",
    display: "block",
  },
  selectorField: {
    width: "100%",
    "& .MuiFormHelperText-root.Mui-disabled": {
      color: theme.palette.text.secondary,
    },
  },
}));

export const ProcessorSelect = ({
  control,
  name,
  options,
}: IProcessorSelectProps) => {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ onChange, value }) => (
          <TextField
            value={value}
            onChange={onChange}
            disabled={isEmpty(options)}
            variant="outlined"
            className={classes.selectorField}
            select
            label={CONFIGURATION_LABELS[name]}
            helperText={
              isEmpty(options) && LabelEnum.EMPTY_DEFAULT_PROCESSOR_LIST_MSG
            }
          >
            <MenuItem value="">{LabelEnum.SELECT_PROCESSOR}</MenuItem>
            {options.map((option, index) => (
              <MenuItem key={index} value={option.alias}>
                {option.alias}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
    </FormControl>
  );
};

export default ProcessorSelect;
