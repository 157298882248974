import { themeOptions } from "../../themeui";
import { InitialStatusEnum, StatusEnum } from "./StatusEnum";
import { KindEnum } from "./KindEnum";

//Todo refactorar este switch

export const setColor = (status: string, kind?: string) => {
  switch (status) {
    case StatusEnum.FINISHED:
    case StatusEnum.COMPLETED:
      return `${themeOptions.palette.success.dark}`;
    case StatusEnum.REJECTED:
      return kind === KindEnum.RETENTION_EC
        ? `${themeOptions.palette.secondary.dark}`
        : `${themeOptions.palette.primary.main}`;
    case StatusEnum.OMIT:
    case StatusEnum.OMITTED:
    case StatusEnum.EMITTED:
    case StatusEnum.PENDING_VERIFY:
      return `${themeOptions.palette.primary.main}`;
    case StatusEnum.PENDING:
    case StatusEnum.PRE_PROCESSED:
      return `${themeOptions.palette.alternate.dark2}`;
    case StatusEnum.MANUAL:
      return `${themeOptions.palette.neutral.grey8}`;
    case StatusEnum.FAILED:
    case StatusEnum.EXPIRED:
    case StatusEnum.ANNUL:
    case StatusEnum.ANNULLED:
    case StatusEnum.REJECT:
    case StatusEnum.REJECTED:
    case StatusEnum.DELETED:
      return `${themeOptions.palette.error.dark2}`;
    default:
      return "inherit";
  }
};
export const setInitialStatusColor = (initialStatus: string) => {
  switch (initialStatus) {
    case InitialStatusEnum.APPROVE:
      return `${themeOptions.palette.success.dark}`;
    case InitialStatusEnum.OMIT:
    case InitialStatusEnum.OMITTED:
    case InitialStatusEnum.EMITTED:
      return `${themeOptions.palette.primary.main}`;
    case InitialStatusEnum.PENDING:
      return `${themeOptions.palette.alternate.dark2}`;
    case InitialStatusEnum.PENDING_VERIFY:
      return `${themeOptions.palette.primary.main}`;
    case InitialStatusEnum.REJECT:
    case InitialStatusEnum.EXPIRED:
      return `${themeOptions.palette.error.dark2}`;
    default:
      return "inherit";
  }
};
export const setBackgroundColor = (status: string) => {
  switch (status) {
    case StatusEnum.COMPLETED:
      return `${themeOptions.palette.secondary.light3}`;
    case StatusEnum.FINISHED:
      return `${themeOptions.palette.secondary.light3}`;
    case StatusEnum.SUCCESSFUL:
    case StatusEnum.PROCESSED:
      return `${themeOptions.palette.success.light}`;
    case StatusEnum.OMIT:
    case StatusEnum.OMITTED:
    case StatusEnum.EMITTED:
    case StatusEnum.PENDING_VERIFY:
      return `${themeOptions.palette.primary.light3}`;
    case StatusEnum.PENDING:
      return `${themeOptions.palette.alternate.light3}`;
    case StatusEnum.MANUAL:
      return `${themeOptions.palette.warning.light3}`;
    case StatusEnum.FAILED:
    case StatusEnum.EXPIRED:
    case StatusEnum.ANNUL:
    case StatusEnum.ANNULLED:
    case StatusEnum.REJECT:
    case StatusEnum.REJECTED:
    case StatusEnum.DELETED:
      return `${themeOptions.palette.error.light3}`;
    default:
      return "inherit";
  }
};
export const setInitialStatusBackgroundColor = (initialStatus: string) => {
  switch (initialStatus) {
    case InitialStatusEnum.APPROVE:
      return `${themeOptions.palette.secondary.light3}`;
    case InitialStatusEnum.OMIT:
    case InitialStatusEnum.OMITTED:
    case InitialStatusEnum.EMITTED:
      return `${themeOptions.palette.primary.light3}`;
    case InitialStatusEnum.PENDING_VERIFY:
      return `${themeOptions.palette.primary.light3}`;
    case InitialStatusEnum.PENDING:
      return `${themeOptions.palette.alternate.light3}`;
    case InitialStatusEnum.REJECT:
    case InitialStatusEnum.EXPIRED:
      return `${themeOptions.palette.error.light3}`;
    default:
      return "inherit";
  }
};
export enum BackgroundIconColor {
  initializedIcon = "#f8fafc",
  modifiedIcon = "#fef9ec",
  rejectIcon = "#feeff1",
  approveIcon = "#f1fffc",
}
export enum ColorRetentionStatus {
  inconsistenceColor = "#FBC748",
  modifyColor = "#6D7781",
  rejectedColor = "#AD0C2A",
}

export enum BackgroundColorRetentionStatus {
  inconsistenceColor = "#FEF9EC",
  modifyColor = "#F0F4F9",
  defaultColor = "white",
}
