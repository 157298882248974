import React, { FC } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import { usePaymentMethodCheckList } from "./state/usePaymentMethodCheckList";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckList from "../CheckList/CheckList";
import { PAYMENT_METHOD_LABELS } from "../../shared/constants/enums/PAYMENT_METHOD_LABEL";
import { get } from "lodash";
import { WrapperLabelEnum } from "../../shared/enums/WrapperLabelEnum";

export const PaymentMethodCheckList: FC = () => {
  const {
    payoutOptions,
    payinOptions,
    productOptions,
    otherOptions,
    errors,
    selectedOptions,
    handler: { handleSelectAll, handleChange },
    areAllOptionsSelected,
    isEmptyProcessor,
  } = usePaymentMethodCheckList();

  return (
    <Grid
      container
      spacing={2}
      sx={{ marginBottom: "23px", marginTop: " 8px" }}
    >
      <Grid item xs={12}>
        <Typography variant="body1" color="primary">
          Medios de pago
        </Typography>
        {isEmptyProcessor && (
          <Typography variant={"caption"} display={"block"} mt={1}>
            {WrapperLabelEnum.WEBHOOK_DISCLAIMER_PROCESSOR}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleSelectAll}
              checked={areAllOptionsSelected}
              checkedIcon={<CheckBoxOutlinedIcon />}
            />
          }
          label="Seleccionar todo"
        />
      </Grid>
      {payinOptions.length > 0 && (
        <Grid item xs={4} lg={3}>
          <CheckList
            selectedItems={selectedOptions}
            title={PAYMENT_METHOD_LABELS.PAYIN}
            onChange={handleChange}
            items={payinOptions}
          />
        </Grid>
      )}

      {payoutOptions.length > 0 && (
        <Grid item xs={4} lg={3}>
          <CheckList
            selectedItems={selectedOptions}
            title={PAYMENT_METHOD_LABELS.PAYOUTS}
            onChange={handleChange}
            items={payoutOptions}
          />
        </Grid>
      )}
      {productOptions.length > 0 && (
        <Grid item xs={4} lg={3}>
          <CheckList
            selectedItems={selectedOptions}
            title={PAYMENT_METHOD_LABELS.PRODUCT}
            onChange={handleChange}
            items={productOptions}
          />
        </Grid>
      )}
      {otherOptions.length > 0 && (
        <Grid item xs={4} lg={3}>
          <CheckList
            selectedItems={selectedOptions}
            title={PAYMENT_METHOD_LABELS.OTHER}
            onChange={handleChange}
            items={otherOptions}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        {errors["options"] && (
          <FormHelperText error>
            {get(errors, "options.message")}
          </FormHelperText>
        )}
      </Grid>
    </Grid>
  );
};
