import {
  Autocomplete,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import {
  Codes,
  ConfigurationResponse,
  Messages,
  Providers,
} from "../../../types/configuration_response";
import {
  GeneralActionEnum,
  Labels,
} from "../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { Controller, useFormContext, UseFormMethods } from "react-hook-form";
import { ISecurityRuleForm } from "../../shared/infrastructure/interfaces/ISecurityRuleForm";
import { retrieveFormError } from "../../utils/utilsFile";
import { createStyles, makeStyles } from "@mui/styles";
import { ErrorsEnum } from "../../shared/infrastructure/enums/ErrorEnum";
import { defaultTo, get, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../store/reducer";
import { setIsEditedRule, setActionType } from "../../store/actionCreators";

const useStyles = makeStyles(() =>
  createStyles({
    errorColor: {
      color: "rgba(226,71,99,1)",
    },
  })
);

export type GeneralActionSectionProps = {
  configuration?: ConfigurationResponse;
  form?: UseFormMethods<ISecurityRuleForm>;
};

export const GeneralActionSection = (props: GeneralActionSectionProps) => {
  const dispatch = useDispatch();
  const { setValue, errors, control, watch, clearErrors } = useFormContext();
  const classes = useStyles();
  const action: string = defaultTo(watch("generalAction") as string, "");
  const message: string = defaultTo(watch("message") as string, "");
  const providerGeneralRule = defaultTo(watch("partnerValidator"), "");
  const isUpdate = watch("isUpdatedRule");
  const isEditedRule: boolean | undefined = useSelector(
    (state: IAppState) => state.isEditedRule
  );
  const showProviderField = (action: string) => {
    return action !== "";
  };

  const getProvidersByAction = (actionRequired: string): Providers[] => {
    const newInfo: Providers[] | undefined = get(
      props,
      "configuration.providers",
      []
    ).filter((item: Providers) => item.action.includes(actionRequired));

    return defaultTo(newInfo, []);
  };

  const validateGeneralAction = (): boolean => {
    return action !== GeneralActionEnum.AUTHENTICATE;
  };

  const getCodesByAction = (actionRequired: string) => {
    const newInfo: Codes[] | undefined = get(
      props,
      "configuration.codes",
      []
    ).filter((item: Codes) => item.action.includes(actionRequired));

    return defaultTo(newInfo, []);
  };

  const getMessagesByAction = (actionRequired: string) => {
    const newInfo: string[] | undefined = props.configuration?.messages
      ?.filter((item: Messages) => item.action.includes(actionRequired))
      .map((item: Messages) => {
        return item.title;
      });

    return defaultTo(newInfo, []);
  };

  useEffect(() => {
    if (isEmpty(action)) return;

    if (!isEmpty(action) && !isUpdate && !isEditedRule) {
      setValue("provider", "");
      setValue("code", "");
      setValue("message", "");
      clearErrors(["provider", "code", "message"]);
      setValue("isUpdatedRule", false);

      dispatch(setActionType(action.toString()));
    }
    if (providerGeneralRule != "") setValue("provider", providerGeneralRule);
    dispatch(setActionType(action.toString()));
    dispatch(setIsEditedRule(false));
  }, [action]);

  return (
    <Grid container spacing={1}>
      <Grid container item spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="generalAction"
            control={control}
            rules={{
              required: {
                message: ErrorsEnum.REQUIRED_FIELD,
                value: true,
              },
            }}
            value={action}
            defaultValue={""}
            render={({ ...selectProps }) => (
              <TextField
                {...selectProps}
                id={Labels.ACTION}
                fullWidth
                label={Labels.ACTION}
                select
                error={retrieveFormError(errors, "generalAction")[0]}
              >
                {props.configuration?.generalActions!.map(
                  (
                    actionValue: { name?: string; title?: string },
                    index: number
                  ) => (
                    <MenuItem key={index} value={actionValue.name!}>
                      {actionValue.title}
                    </MenuItem>
                  )
                )}
              </TextField>
            )}
          />
          <FormHelperText
            className={classes.errorColor}
            error={retrieveFormError(errors, "generalAction")[0]}
          >
            {retrieveFormError(errors, "generalAction")[1]}
          </FormHelperText>
        </Grid>
        {showProviderField(action) && (
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="provider"
              control={control}
              rules={{
                required: {
                  message: ErrorsEnum.REQUIRED_FIELD,
                  value: !!showProviderField(action),
                },
              }}
              defaultValue={""}
              render={({ ...selectProps }) => (
                <TextField
                  {...selectProps}
                  fullWidth
                  label={Labels.PROVIDER}
                  select
                  error={retrieveFormError(errors, "provider")[0]}
                >
                  {getProvidersByAction(action).map(
                    (
                      item: {
                        name: string;
                        title: string;
                        action: object;
                      },
                      index: number
                    ) => (
                      <MenuItem key={index} value={item.name}>
                        {item.title}
                      </MenuItem>
                    )
                  )}
                </TextField>
              )}
            />
            <FormHelperText
              className={classes.errorColor}
              error={retrieveFormError(errors, "provider")[0]}
            >
              {retrieveFormError(errors, "provider")[1]}
            </FormHelperText>
          </Grid>
        )}
      </Grid>
      <Grid container item spacing={2}>
        {getCodesByAction(action).length > 0 && validateGeneralAction() && (
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="code"
              control={control}
              rules={{
                required: {
                  message: ErrorsEnum.REQUIRED_FIELD,
                  value: true,
                },
              }}
              defaultValue={""}
              render={({ ...selectProps }) => (
                <TextField
                  {...selectProps}
                  fullWidth
                  id={Labels.CODE}
                  label={Labels.CODE}
                  select
                  error={retrieveFormError(errors, "code")[0]}
                >
                  {getCodesByAction(action).map(
                    (
                      item: {
                        code: string;
                        title: string;
                        action: object;
                      },
                      index: number
                    ) => (
                      <MenuItem key={index} value={item.code}>
                        {item.title}
                      </MenuItem>
                    )
                  )}
                </TextField>
              )}
            />
            <FormHelperText
              className={classes.errorColor}
              error={retrieveFormError(errors, "code")[0]}
            >
              {retrieveFormError(errors, "code")[1]}
            </FormHelperText>
          </Grid>
        )}
        {getMessagesByAction(action).length > 0 && validateGeneralAction() && (
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="message"
              control={control}
              rules={{
                required: {
                  message: ErrorsEnum.REQUIRED_FIELD,
                  value: true,
                },
              }}
              defaultValue={""}
              render={({ ...selectProps }) => (
                <Autocomplete
                  {...selectProps}
                  disablePortal
                  value={message}
                  onChange={(_item, newValue: string | null) => {
                    setValue("message", newValue);
                  }}
                  options={getMessagesByAction(action)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={Labels.MESSAGE}
                      label={Labels.MESSAGE}
                      error={retrieveFormError(errors, "message")[0]}
                    />
                  )}
                />
              )}
            />
            <FormHelperText
              className={classes.errorColor}
              error={retrieveFormError(errors, "message")[0]}
            >
              {retrieveFormError(errors, "message")[1]}
            </FormHelperText>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default GeneralActionSection;
