import { Controller, UseFormReturn } from "react-hook-form";
import { useStyles as styles } from "./BillingDataFormCountries.styles";
import React from "react";
import {
  Checkbox,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

import { get, isEmpty, without } from "lodash";

import { LabelsEnum } from "../../shared/enum/LabelsEnum";
import { BillingDataFormLabelsEnum } from "../../shared/enums/BillingDataFormLabelsEnum";
import { ErrorBillingData } from "../../shared/enum/BasicStateMX/ErrorMessageMX";
import { FieldsEnum } from "../../shared/enums/FieldsEnum";
import { TAX_REGIME } from "../../shared/enums/TaxRegimeEnum";
import PhoneInputField from "../commonInputs/PhoneInputField/PhoneInputField";
import { getPhoneInputRules } from "../../shared/constants/billing_form_constants";
import AddressInputField from "../commonInputs/AddressInputField/AddressInputField";
import { CountryEnum } from "../../shared/countryEnum";
import SelectInputField from "../commonInputs/SelectInputField/SelectInputField";
import { MerchantCompanyRequest } from "../../../types/merchant_company_request";

export type formProps = {
  form: UseFormReturn<MerchantCompanyRequest>;
  handleGetCatalogZipcode: (value: boolean, zipCode: string) => void;
  handleUseSameInfoSat: (value: boolean) => void;
  infoBilling: {
    city: string;
    constitutionalCountry: string;
    country: string;
    municipality: string;
    populationCode: string;
    province: string;
    satCity: string;
    satMunicipality: string;
    satPopulationCode: string;
    satProvince: string;
    satStateCode: string;
    stateCode: string;
    useSameInfoSat: boolean;
  };
  isCentralizedNode: boolean;
  zipCodeCatalogs: string[];
  zipCodeSatCatalogs: string[];
};

const BillingDataFormCountriesMX: React.FC<formProps> = (props: formProps) => {
  const classes = styles();

  const handleMaxLength = (value: string) => {
    const phoneNumber: string = props.form.watch(value);

    if (!isEmpty(phoneNumber)) {
      return phoneNumber.split(" ")[0].length + 13;
    }

    return 11;
  };

  const handleMinLength = (value: string) => {
    const phoneNumber: string = props.form.watch(value);

    if (!isEmpty(phoneNumber)) {
      let split_phone = phoneNumber.split("-");

      if (without(split_phone, "").length === 2) {
        const cod_lada: string = split_phone[0];

        return cod_lada.length + 2;
      } else {
        split_phone = phoneNumber.split(" ");

        return get(split_phone, "[0]", []).length + 1;
      }
    }

    return 1;
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Controller
            name={FieldsEnum.taxRegime}
            control={props.form.control}
            rules={{
              required: {
                message: ErrorBillingData.TAX_REGIME,
                value: true,
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                error={
                  !!get(props.form.formState.errors, FieldsEnum.taxRegime, null)
                }
                helperText={
                  get(props.form.formState.errors, FieldsEnum.taxRegime)
                    ?.message ?? ""
                }
                defaultValue={props.form.watch(FieldsEnum.taxRegime)}
                label={BillingDataFormLabelsEnum.tax_regime}
                fullWidth
                select
                onBlur={() => {
                  field.onBlur();
                  props.form.trigger(FieldsEnum.taxRegime);
                }}
                onChange={field.onChange}
                disabled={props.isCentralizedNode}
              >
                {TAX_REGIME.map((item: { key: string; value: string }) => (
                  <MenuItem key={item.key} value={item.value}>
                    {item.value}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name={FieldsEnum.zipCode}
            control={props.form.control}
            rules={{
              maxLength: {
                message: ErrorBillingData.ZIPCODE,
                value: 6,
              },
              minLength: {
                message: ErrorBillingData.ZIPCODE,
                value: 5,
              },
              pattern: {
                message: ErrorBillingData.ZIPCODE,
                value: /^[0-9]+$/,
              },
              required: {
                message: ErrorBillingData.ZIPCODE_REQUIRED,
                value: true,
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                id="outlined-basic"
                fullWidth
                error={
                  !!get(props.form.formState.errors, FieldsEnum.zipCode, null)
                }
                defaultValue={props.form.watch(FieldsEnum.zipCode)}
                helperText={
                  get(props.form.formState.errors, FieldsEnum.zipCode)
                    ?.message ?? ""
                }
                className={classes.errorMessage}
                label={BillingDataFormLabelsEnum.postal_code}
                variant="outlined"
                onChange={(e) => {
                  field.onChange(e.target.value);
                  props.handleGetCatalogZipcode(false, e.target.value);
                }}
                disabled={props.isCentralizedNode}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInputField
            rules={{
              required: {
                message: ErrorBillingData.COLOGNE,
                value: true,
              },
            }}
            name={FieldsEnum.cologne}
            control={props.form.control}
            errors={props.form.formState.errors}
            defaultValue={props.form.watch(FieldsEnum.cologne)}
            label={BillingDataFormLabelsEnum.cologne}
            disabled={
              props.zipCodeCatalogs.length < 2 || props.isCentralizedNode
            }
            triggerOnBlur={() => {
              props.form.trigger(FieldsEnum.cologne);
            }}
            itemsSelect={props.zipCodeCatalogs.map((item: string) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            fullWidth
            label={BillingDataFormLabelsEnum.municipality}
            value={props.infoBilling.municipality}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            fullWidth
            label={BillingDataFormLabelsEnum.state}
            value={props.infoBilling.province}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            fullWidth
            label={BillingDataFormLabelsEnum.city}
            value={props.infoBilling.city}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            fullWidth
            label={BillingDataFormLabelsEnum.state_key}
            value={props.infoBilling.stateCode}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            fullWidth
            label={BillingDataFormLabelsEnum.population_key}
            value={props.infoBilling.populationCode}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <AddressInputField
            control={props.form.control}
            errors={props.form.formState.errors}
            disabled={props.isCentralizedNode}
          />
        </Grid>
        <Grid item xs={6}>
          <PhoneInputField
            fieldName={FieldsEnum.phoneNumber}
            labelName={BillingDataFormLabelsEnum.merchant_phone}
            control={props.form.control}
            errors={props.form.formState.errors}
            watch={props.form.watch}
            rules={
              getPhoneInputRules({
                validateMaxLenght: handleMaxLength,
                validateMinLength: handleMinLength,
              })[props.infoBilling.constitutionalCountry as CountryEnum]
            }
            disabled={props.isCentralizedNode}
            country={props.infoBilling.constitutionalCountry as CountryEnum}
            countryCustomer={props.infoBilling.country as CountryEnum}
          />
        </Grid>
      </Grid>
      <Divider className={classes.satDivider} />
      <Typography className={classes.satSubtitle}>
        {LabelsEnum.SAT_INFORMATION}
      </Typography>
      <Grid container spacing={0}>
        <Grid item>
          <Controller
            name={FieldsEnum.useSameInfoSat}
            control={props.form.control}
            render={({ field }) => (
              <Checkbox
                {...field}
                checked={props.infoBilling.useSameInfoSat}
                onChange={(e) => {
                  field.onChange(e);
                  props.handleUseSameInfoSat(e.target.checked);
                }}
                color={"primary"}
              />
            )}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.satMessage}>
            {"Utilizar la misma información de datos de facturación"}
          </Typography>
        </Grid>
        {!props.infoBilling.useSameInfoSat && (
          <Grid container spacing={2} className={classes.satForm}>
            <Grid item xs={12}>
              <Typography className={classes.satInfoMessage}>
                {
                  "Ingresa la información fiscal básica del customer para su facturación ante el Servicio Administrativo Tributario."
                }
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Controller
                name={FieldsEnum.satZipCode}
                rules={{
                  maxLength: {
                    message: ErrorBillingData.ZIPCODE,
                    value: 6,
                  },
                  minLength: {
                    message: ErrorBillingData.ZIPCODE,
                    value: 5,
                  },
                  required: {
                    message: ErrorBillingData.ZIPCODE,
                    value: true,
                  },
                }}
                control={props.form.control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="outlined-basic"
                    fullWidth
                    error={
                      !!get(
                        props.form.formState.errors,
                        FieldsEnum.satZipCode,
                        null
                      )
                    }
                    defaultValue={props.form.watch(FieldsEnum.satZipCode)}
                    helperText={
                      get(props.form.formState.errors, FieldsEnum.satZipCode)
                        ?.message ?? ""
                    }
                    className={classes.errorMessage}
                    disabled={props.isCentralizedNode}
                    label={BillingDataFormLabelsEnum.fiscal_postal_code}
                    variant="outlined"
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      props.handleGetCatalogZipcode(true, e.target.value);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name={FieldsEnum.satCologne}
                control={props.form.control}
                defaultValue={props.form.watch(FieldsEnum.satCologne)}
                rules={{
                  required: {
                    message: ErrorBillingData.COLOGNE,
                    value: true,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    id="simple-select"
                    disabled={
                      props.zipCodeSatCatalogs.length < 2 ||
                      props.isCentralizedNode
                    }
                    fullWidth
                    select
                    error={
                      !!get(
                        props.form.formState.errors,
                        FieldsEnum.satCologne,
                        null
                      )
                    }
                    helperText={
                      get(props.form.formState.errors, FieldsEnum.satCologne)
                        ?.message ?? ""
                    }
                    onBlur={() => {
                      field.onBlur();
                      props.form.trigger(FieldsEnum.satCologne);
                    }}
                    value={props.form.watch(FieldsEnum.satCologne)}
                    label={BillingDataFormLabelsEnum.trade_tax_colony}
                    onChange={field.onChange}
                  >
                    {props.zipCodeSatCatalogs.map((item: string) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled
                fullWidth
                id="outlined-basic"
                label={BillingDataFormLabelsEnum.tax_status_key}
                value={props.infoBilling.satStateCode}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled
                fullWidth
                id="outlined-basic"
                label={BillingDataFormLabelsEnum.fiscal_population_key}
                value={props.infoBilling.satPopulationCode}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name={FieldsEnum.satAddress}
                rules={{
                  required: {
                    message: ErrorBillingData.ADDRESS,
                    value: true,
                  },
                }}
                control={props.form.control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    id="outlined-basic"
                    error={
                      !!get(
                        props.form.formState.errors,
                        FieldsEnum.satAddress,
                        null
                      )
                    }
                    disabled={props.isCentralizedNode}
                    defaultValue={props.form.watch(FieldsEnum.satAddress)}
                    className={classes.errorMessage}
                    helperText={
                      get(props.form.formState.errors, FieldsEnum.satAddress)
                        ?.message ?? ""
                    }
                    label={BillingDataFormLabelsEnum.fiscal_direction}
                    variant="outlined"
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <PhoneInputField
                fieldName={FieldsEnum.satPhoneNumber}
                labelName={BillingDataFormLabelsEnum.fiscal_phone}
                control={props.form.control}
                errors={props.form.formState.errors}
                watch={props.form.watch}
                rules={
                  getPhoneInputRules({
                    validateMaxLenght: handleMaxLength,
                    validateMinLength: handleMinLength,
                  })[props.infoBilling.constitutionalCountry as CountryEnum]
                }
                disabled={props.isCentralizedNode}
                country={props.infoBilling.constitutionalCountry as CountryEnum}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default BillingDataFormCountriesMX;
