import React from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { MoreHorizontal } from "react-feather";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ProcessorFetch } from "../../../shared/infrastructure/interfaces/ProcessorFetch";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { IAppState } from "../../../store/reducer";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  deleteProcessor,
  IAppAction,
  setCloseDialog,
} from "../../../store/actionCreators";
import { connect } from "react-redux";
import { defaultTo, isEqual } from "lodash";
import { buildBoldText } from "../../../utils/utilsFile";
import { useTableMenuState } from "./state/useTableMenuState";
import { IUpdateProcessor } from "../../../shared/infrastructure/interfaces/IUpdateProcessor";
import { StyleNamesEnum } from "../../../shared/infrastructure/constants/StyleNamesEnum";
import { LoadingDialog } from "../LoadingDialog/LoadingDialog";
import { ModalLoadingEnum } from "../../../shared/infrastructure/constants/ModalLoadingEnum";
import { SPACE_REGEX } from "../../../shared/infrastructure/constants/RegexValidationEnum";
import { ModalInfoTitleEnum } from "../../../shared/infrastructure/constants/ModalInfoEnum";
import { LocalStoragePropertiesEnum } from "../../../shared/infrastructure/constants/LocalStoragePropertiesEnum";
import { routes } from "../../../shared/infrastructure/constants/routes";
import { PaymentMethodEnum } from "../../../shared/infrastructure/constants/PaymentMethodEnum";

export interface ITableMenu {
  processor: ProcessorFetch;
  processors: ProcessorFetch[] | undefined;
  onFailOver(processor: ProcessorFetch): void;
  handleUpdateProcessor(processor: IUpdateProcessor): void;
  isDefaultProcessor?: boolean;
}

export interface IMenuItem {
  label: string;
  to?: string;
  handleClick?(): void;
  activeMenu: boolean;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  buttonWidth: {
    minWidth: "unset",
    border: "1px solid transparent",
    padding: "3px 9px",
    borderRadius: 4,
    backgroundColor: theme.palette.background.default,
    "&:hover": {
      border: "1px solid transparent",
    },
  },
  [StyleNamesEnum.DELETE_PROCESSOR_BUTTON]: {
    color: theme.palette.error.main,
  },
  [StyleNamesEnum.BOLD_TEXT_STYLE]: {
    fontWeight: 500,
  },
}));

export interface ITableMenuFunctionsProps {
  deleteProcessor(
    processorId: string,
    merchantId: string,
    paymentMethod: string,
    processors: ProcessorFetch[] | undefined
  ): void;
  setCloseDialog(close: boolean): void;
}

export interface ITableMenuStateProps {
  loading?: boolean;
  loadingDialog?: boolean;
  dialogClosed?: boolean;
}

export type TTableMenuProps = ITableMenu &
  ITableMenuFunctionsProps &
  ITableMenuStateProps;
export const TableMenu: React.FC<TTableMenuProps> = (
  props: TTableMenuProps
) => {
  const classes = useStyles();
  const {
    anchorEl,
    modalInfo,
    handleClick,
    handleCloseEl,
    menus,
  } = useTableMenuState(props);

  const redirectItemTo = (url: string | undefined) => {
    window.location.href = defaultTo(url, "");
  };

  const setInternationalRedirectUrl = () => {
    localStorage.setItem(
      LocalStoragePropertiesEnum.INTERNATIONAL_REDIRECT_URL,
      `${routes.PROCESSOR_LIST}${props.processor.merchantId}`
    );
  };

  return (
    <React.Fragment>
      <Button
        className={classes.buttonWidth}
        size="small"
        variant="outlined"
        color="primary"
        onClick={handleClick}
      >
        <MoreHorizontal size={20} />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        elevation={1}
        getContentAnchorEl={null}
      >
        {defaultTo(menus, [])
          .filter((menu: IMenuItem) => menu.activeMenu)
          .map((menu: IMenuItem) =>
            menu.to ? (
              <MenuItem
                key={menu.label.replace(SPACE_REGEX, "_")}
                className={classes[defaultTo(menu.className, "")]}
                onClick={() => {
                  if (
                    props.processor.paymentMethod ===
                    PaymentMethodEnum.INTERNATIONAL_TRANSFER
                  ) {
                    setInternationalRedirectUrl();
                  }
                  redirectItemTo(menu.to);
                }}
              >
                {menu.label}
              </MenuItem>
            ) : (
              <MenuItem
                key={menu.label.replace(SPACE_REGEX, "_")}
                onClick={menu.handleClick}
                className={classes[defaultTo(menu.className, "")]}
              >
                {menu.label}
              </MenuItem>
            )
          )}
      </Menu>
      <LoadingDialog
        open={modalInfo.openLoadingDialog!}
        subtitle={ModalLoadingEnum.PROCESSOR_SUBTITLE}
        title={
          isEqual(modalInfo.titleText, ModalInfoTitleEnum.ENABLE_PROCESSOR)
            ? ModalLoadingEnum.ENABLE_PROCESSOR_TITLE
            : ModalLoadingEnum.DISABLE_PROCESSOR_TITLE
        }
      />
      <ConfirmModal
        {...modalInfo}
        contentText={buildBoldText(
          modalInfo.contentText! as string,
          classes,
          StyleNamesEnum.BOLD_TEXT_STYLE
        )}
      />
    </React.Fragment>
  );
};

export const mapStateToProps: (state: IAppState) => ITableMenuStateProps = (
  state: IAppState
): ITableMenuStateProps => ({
  loading: state.loading,
  dialogClosed: state.dialogClosed,
  loadingDialog: state.loadingDialog,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => ITableMenuFunctionsProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): ITableMenuFunctionsProps => ({
  deleteProcessor: (
    processorId: string,
    merchantId: string,
    paymentMethod: string,
    processors: ProcessorFetch[]
  ): void =>
    dispatch(
      deleteProcessor(processorId, merchantId, paymentMethod, processors)
    ),
  setCloseDialog: (close: boolean): IAppAction =>
    dispatch(setCloseDialog(close)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableMenu);
