import { CreateMerchantNodeRequest } from "../../../types/create_merchant_node_request";
import { defaultTo, get, isEmpty } from "lodash";
import { FieldsCustomerEnum } from "../enums/FieldsCustomerEnum";
import { Entities } from "../enums/entities";
import { UseFormReturn } from "react-hook-form";
import { set } from "lodash";

export const getNodeInfoInitialValue = (
  initData: Partial<CreateMerchantNodeRequest>
): Partial<CreateMerchantNodeRequest> => {
  return {
    clientType: get(initData.generalInfo, FieldsCustomerEnum.clientType, ""),
    commercialName: get(initData.generalInfo, "name", ""),
    constitutionCountry: get(initData.generalInfo, "constitutionalCountry", ""),
    operationCountry: get(initData.generalInfo, "country", ""),
  };
};

const getFormValue = (
  form: UseFormReturn<CreateMerchantNodeRequest>,
  value: string,
  defaultValue: string | boolean | number
) => defaultTo(form.getValues(value), defaultValue);

export const buildRequest = (
  form: UseFormReturn<CreateMerchantNodeRequest>,
  nodeId: string,
  publicMerchantId: string,
  countryConstitutionCheck: boolean,
  hasParent: boolean,
  isBranch: boolean
): CreateMerchantNodeRequest => {
  let request: CreateMerchantNodeRequest = {
    clientType: getFormValue(form, FieldsCustomerEnum.clientType, ""),
    constitutionalCountry: countryConstitutionCheck
      ? getFormValue(form, FieldsCustomerEnum.operationCountry, "")
      : getFormValue(form, FieldsCustomerEnum.constitutionCountry, ""),
    country: getFormValue(form, FieldsCustomerEnum.operationCountry, ""),
    entityName: isBranch ? Entities.BRANCH : Entities.CUSTOMER,
    name: getFormValue(form, FieldsCustomerEnum.commercialName, ""),

    userAlias: defaultTo(localStorage.getItem("fullName"), ""),
    userName: defaultTo(localStorage.getItem("username"), ""),
  };

  if (!isEmpty(publicMerchantId))
    set(request, "publicMerchantId", publicMerchantId);

  if (!isEmpty(nodeId)) {
    if (hasParent) set(request, "rootId", nodeId);
    set(request, "nodeId", nodeId);
  }

  return request;
};
