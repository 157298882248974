export interface ICountry {
  value: string;
  name: string;
  flag: string;
}

export const COUNTRIES: ICountry[] = [
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/brasilFlag%402x.png",
    name: "Brasil",
    value: "Brazil",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/chileFlag%402x.png",
    name: "Chile",
    value: "Chile",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/colombiaFlag%402x.png",
    name: "Colombia",
    value: "Colombia",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/costaRicaFlag%402x.png",
    name: "Costa Rica",
    value: "CostaRica",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/ecuadorFlag%402x.png",
    name: "Ecuador",
    value: "Ecuador",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/salvadorFlag%402x.png",
    name: "El Salvador",
    value: "ElSalvador",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/guatemalaFlag%402x.png",
    name: "Guatemala",
    value: "Guatemala",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/hondurasFlag%402x.png",
    name: "Honduras",
    value: "Honduras",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/mexico%402x.png",
    name: "México",
    value: "Mexico",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/nicaraguaFlag%402x.png",
    name: "Nicaragua",
    value: "Nicaragua",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/panamaFlag%402x.png",
    name: "Panamá",
    value: "Panama",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/peruFlag%402x.png",
    name: "Perú",
    value: "Peru",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/usaFlag%402x.png",
    name: "Estados Unidos",
    value: "EEUU",
  },
];
