import { Category } from "../constants/MerchantInformationConstants";

export const CatalogGeneralActivity: Category[] = [
  { name: "Actividades financieras.", value: "ACO01" },
  { name: "Demás actividades comerciales.", value: "ACO02" },
  { name: "Demás actividades de servicios.", value: "ACO03" },
  { name: "Demás actividades industriales.", value: "ACO04" },
  { name: "Edición de libros.", value: "ACO05" },
  {
    name: "Fabricación de productos primarios de hierro y acero; fabricación de material de transporte.",
    value: "ACO06",
  },
  {
    name: "Producción de alimentos, excepto bebidas; producción de calzado y prendas de vestir.",
    value: "ACO07",
  },
  {
    name: "Servicio de restaurante, cafetería, grill, discoteca y similares; servicios de hotel, motel, hospedaje, amoblado y similares; servicio de casas de empeño y servicios de vigilancia.",
    value: "ACO08",
  },
  {
    name: "Transporte, publicación de revistas, libros y periódicos, radiodifusión y programación de televisión.",
    value: "ACO09",
  },
  {
    name: "Venta de alimentos y productos agrícolas en bruto; venta de textos escolares y libros (incluye cuadernos escolares); venta de drogas y medicamentos.",
    value: "ACO10",
  },
  {
    name: "Venta de cigarrillos y licores; venta de combustibles derivados del petróleo y venta de joyas.",
    value: "ACO11",
  },
  {
    name: "Venta de maderas y materiales para construcción; venta de automotores (incluidas motocicletas).",
    value: "ACO12",
  },
];
