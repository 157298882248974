import { makeStyles } from "@material-ui/core/styles";

export const useStyleKshColorPicker = makeStyles({
  title: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "140%",
    display: "flex",
    alignItems: "center",
    color: "#6D7781",
  },
  buttonColorPicker: {
    width: "100%",
    boxSizing: "border-box",
    height: "48px",
    cursor: "pointer",
    background: "transparent",
    borderRadius: "4px",
    border: "1px solid #CBD5E0",
  },
  buttonColorPickerLabel: {
    marginLeft: "8px",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "16px",
    color: "#293036",
  },
  popover: {
    position: "absolute",
    zIndex: 1,
  },
  cover: {
    zIndex: 2,
    position: "fixed",
    top: "0px",
    width: "100%",
    height: "100%",
    left: "0px",
  },
  boxColor: {
    width: "21px",
    height: "21px",
    borderRadius: "1px",
    marginLeft: "8px",
    position: "relative",
  },
});
