import React from "react";
import { ModalFooter as ModalFooterMolecules } from "@kushki/frontend-molecules/modal-footer";
import { Edit, Trash } from "react-feather";
import { SecurityRule } from "../../../types/security_rule";
import { get } from "lodash";
import { Button, Grid } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  ButtonText,
  INotification,
  RulesNotificationResultEnum,
  RuleState,
} from "../../shared/infrastructure/constants/RulesConstants";
import { DialogDisableRule } from "../DialogDisableRule/DialogDisableRule";
import { RulesAction } from "../../store/actionCreator";
import { SnackbarAlert } from "../SnackBarAlert/SnackBarAlert";
import { useDialogDisabledRuleState } from "../DialogDisableRule/state/useDialogDisableRuleState";
import { GeneralActionsEnum } from "../../shared/infrastructure/enums/GeneralActionsEnum";
import {
  ROLE_EDIT_BY_RULE_TYPE,
  SecurityRulesRoles,
} from "../../shared/infrastructure/constants/SecurityRulesConstants";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { Box } from "@material-ui/core";

export interface ModalFooterProps {
  handlerEditRule: (ruleId: string) => void;
  handlerDeleteRule: (ruleId: string) => void;
  handlerUpdateStatusRule: (rule: SecurityRule, ruleId: string) => void;
  mainTextButton: string;
  secondaryTextButton: string;
  isRequest: boolean;
  data: SecurityRule;
  notification?: INotification;
  setNotification?: (payload: INotification) => RulesAction;
}

const useStyles = makeStyles((theme: Theme) => ({
  editButton: {
    backgroundColor: "#023365 !important",
    color: `${theme.palette.common.white} !important`,
  },

  updateStatusButton: {
    backgroundColor: "#98A8B8",
    border: `1px solid ${theme.palette.primary.dark}`,
    color: `${theme.palette.grey["600"]}!important`,
  },

  buttonContainer: {
    backgroundColor: "#F7FAFC",
    paddingBottom: "20px !important",
    paddingTop: "20px !important",
  },
}));

const ModalFooter: React.FC<ModalFooterProps> = (props: ModalFooterProps) => {
  const { actions, openDialogDisableRule } = useDialogDisabledRuleState(props);
  const classes = useStyles();
  const ruleId: string = get(props, "data.id", "");
  const status: string = get(props, "data.status", RuleState.ENABLED);
  return (
    <React.Fragment>
      {props.data.rule?.action === GeneralActionsEnum.THREE_DS ||
      props.data.rule?.action === GeneralActionsEnum.OTP ? (
        status === RuleState.ENABLED || status === RuleState.PENDING ? (
          <Grid
            container
            direction={"row"}
            spacing={0}
            className={classes.buttonContainer}
          >
            <ComponentSecurityWrapper
              componentId={`${SecurityRulesRoles.M_FRAUD_PREVENTION}.${
                ROLE_EDIT_BY_RULE_TYPE[props.data.type]
              }`}
            >
              <Grid item xs={6} style={{ paddingLeft: "20px" }}>
                <Button
                  id={"edit-button"}
                  variant="contained"
                  className={
                    status !== RuleState.PENDING ? classes.editButton : ""
                  }
                  size="large"
                  startIcon={<Edit xlinkTitle={"editar"} size={22} />}
                  disabled={status === RuleState.PENDING}
                  onClick={() => {
                    props.handlerEditRule(ruleId);
                  }}
                  disableElevation
                >
                  {ButtonText.EDIT}
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ textAlign: "right", paddingRight: "20px" }}
              >
                <Button
                  id={"disable-button"}
                  variant="outlined"
                  className={classes.updateStatusButton}
                  size="large"
                  disabled={status === RuleState.PENDING}
                  onClick={actions.handleDialogDisableRule}
                >
                  {ButtonText.DISABLE}
                </Button>
              </Grid>
            </ComponentSecurityWrapper>
            <DialogDisableRule
              data={props.data}
              handleFinish={actions.handleFinish}
              handlerUpdateStatusRule={props.handlerUpdateStatusRule}
              open={openDialogDisableRule}
              notification={props.notification}
              setNotification={props.setNotification}
            />
          </Grid>
        ) : (
          <Grid
            container
            direction={"row"}
            spacing={0}
            className={classes.buttonContainer}
          >
            <ComponentSecurityWrapper
              componentId={`${SecurityRulesRoles.M_FRAUD_PREVENTION}.${
                ROLE_EDIT_BY_RULE_TYPE[props.data.type]
              }`}
            >
              <Grid item xs={6} style={{ paddingLeft: "20px" }}>
                <Button
                  id={"edit-button"}
                  variant="contained"
                  startIcon={<Edit xlinkTitle={"editar"} size={22} />}
                  size="large"
                  disabled
                >
                  {ButtonText.EDIT}
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ textAlign: "right", paddingRight: "20px" }}
              >
                <Button
                  id={"enable-button"}
                  variant="outlined"
                  className={classes.updateStatusButton}
                  size="large"
                  disabled={status === RuleState.PENDING}
                  onClick={() => {
                    props.handlerUpdateStatusRule(
                      actions.enableRule(actions.setUserName(), props.data),
                      get(props, "data.id", "")
                    );
                  }}
                >
                  {ButtonText.ENABLE}
                </Button>
                {get(props, "notification.action") !==
                  RulesNotificationResultEnum.NO_ACTION && (
                  <SnackbarAlert
                    type={get(props, "notification.type")}
                    msg={get(props, "notification.message")}
                    open={get(props, "notification.open")}
                    handlerClose={actions.handleCloseNotification}
                    data={props.data}
                  />
                )}
              </Grid>
            </ComponentSecurityWrapper>
          </Grid>
        )
      ) : (
        <ComponentSecurityWrapper
          componentId={`${SecurityRulesRoles.M_FRAUD_PREVENTION}.${
            ROLE_EDIT_BY_RULE_TYPE[props.data.type]
          }`}
        >
          <Box>
            <ModalFooterMolecules
              loading={props.isRequest}
              primaryActionButton={() => {
                props.handlerEditRule(ruleId);
              }}
              primaryLabelButton={props.mainTextButton}
              secondaryActionButton={() => {
                props.handlerDeleteRule(ruleId);
              }}
              secondaryLabelButton={props.secondaryTextButton}
              primaryActionIcon={<Edit size={14} />}
              secondaryActionIcon={<Trash size={14} />}
              secondaryFontColor={"error"}
            />
          </Box>
        </ComponentSecurityWrapper>
      )}
    </React.Fragment>
  );
};

export default ModalFooter;
