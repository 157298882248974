import React from "react";
import { Grid, Typography } from "@mui/material";
import { styles } from "./ConciliationCustomerInfo.styles";
import { ConciliationCustomerInfoProps } from "./ConciliationCustomerInfo.interfaces";

const ConciliationCustomerInfo = ({
  bankName,
  customerName,
  approvalCode,
  paymentMethod,
  PaymentMethodIcon,
}: ConciliationCustomerInfoProps) => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography sx={styles.text}>Cliente: </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={styles.value}>{customerName}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={styles.text}>Medio de pago:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={styles.value}>
          {PaymentMethodIcon}
          {paymentMethod}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={styles.text}>Banco:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={styles.value}>{bankName}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={styles.text}>No. de aprobación:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={styles.value}>{approvalCode}</Typography>
      </Grid>
    </Grid>
  );
};

export default ConciliationCustomerInfo;
