import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import { detailCardBodyStyles as styles } from "@components/DetailCardBody/DetailCardBody.styles";
import { INodesContainer } from "@components/DetailCardBody/DetailCardBody.interfaces";

const NodesContainer: FC<INodesContainer> = ({
  names,
  label,
}: INodesContainer) => {
  return (
    <Box>
      <Typography variant={"caption"} sx={styles.textBoldGray}>
        {label}
      </Typography>
      <Typography variant={"caption"} sx={styles.nodesName}>
        {" "}
        {names}
      </Typography>
    </Box>
  );
};

export default NodesContainer;
