import React from "react";
import { ModalOverlay } from "@kushki/frontend-molecules/modal";
import { TransactionBody } from "./TransactionBody/TransactionBody";
import { TransactionHeader } from "./TransactionHeader/TransactionHeader";
import { Data } from "../../../types/filter_wallet_dashboard_response";
import { get, isEmpty } from "lodash";
import { TransactionFooter } from "./TransactionFooter/TransactionFooter";

export interface ITransactionTableProps {
  transaction: Data;
  openModal: boolean;
  handleCloseModal(): void;
  country: string;
}

export const TransactionDetail: React.FC<ITransactionTableProps> = (
  props: ITransactionTableProps
) => {
  const { transaction, country } = props;

  return (
    <ModalOverlay
      header={
        <TransactionHeader
          ticketNumber={
            isEmpty(get(transaction, "origin_ticket_number", ""))
              ? get(transaction, "transaction_id", "")
              : get(transaction, "origin_ticket_number", "")
          }
          status={get(transaction, "transaction_status", "")}
          created={get(transaction, "created", "")}
        />
      }
      body={<TransactionBody transaction={transaction} country={country} />}
      footer={<TransactionFooter country={country} transaction={transaction} />}
      openModal={props.openModal}
      onCloseModal={props.handleCloseModal}
      overlay={false}
      backdrop={false}
      transition={"left"}
    />
  );
};
