/**
 * Billing Transaction Status Enum
 */
export enum StatusEnum {
  PENDING = "pending",
  OMIT = "omit",
  REJECT = "rejected",
  INITIALIZED = "initialized",
  COMPLETED = "completed",
  SENT = "sent",
  IN_PROCESS = "in_process",
  APPROVE = "approval",
}

export enum StatusNameEnum {
  PENDING = "Pendiente",
  REJECT = "Rechazada",
  SENT = "Enviada",
  IN_PROCESS = "En proceso",
}
export type StatusList =
  | StatusEnum.IN_PROCESS
  | StatusEnum.PENDING
  | StatusEnum.REJECT
  | StatusEnum.COMPLETED
  | StatusEnum.SENT
  | StatusEnum.APPROVE;

export enum RequestTypeEnum {
  CREATED = "CREATE",
  EDIT = "EDIT",
}
export enum RequestDescriptionStatusEnum {
  APPROVED = "Solicitud aprobada",
  REJECT = "Solicitud rechazada",
  CREATE = "Solicitud de nueva regla",
  EDIT = "Solicitud de edición",
}

export const RULE_STATUS: Record<string, string | Record<string, string>> = {
  [StatusEnum.COMPLETED]: RequestDescriptionStatusEnum.APPROVED,
  [StatusEnum.REJECT]: RequestDescriptionStatusEnum.REJECT,
  [StatusEnum.SENT]: {
    [""]: RequestDescriptionStatusEnum.CREATE,
    [RequestTypeEnum.CREATED]: RequestDescriptionStatusEnum.CREATE,
    [RequestTypeEnum.EDIT]: RequestDescriptionStatusEnum.EDIT,
  },
};

export const RULE_STATUS_CHIP: Record<string, string | Record<string, string>> =
  {
    [StatusEnum.SENT]: {
      [""]: RequestDescriptionStatusEnum.CREATE,
      [RequestTypeEnum.CREATED]: RequestDescriptionStatusEnum.CREATE,
      [RequestTypeEnum.EDIT]: RequestDescriptionStatusEnum.EDIT,
    },
  };
