import React, { FC } from "react";
import { Divider, Grid, Typography } from "@mui/material";
import { metricsContainerStyles as styles } from "./MetricsContainerStyles";
import { useMetricsContainer } from "./state/useMetricsContainer";
import { MetricsContainersLabels } from "../../shared/labels/MetricsContainers.labels";
import { MetricsFilterBar } from "../../components/MetricsFilterBar/MetricsFilterBar";
import { EmptyState } from "../../components/EmptyState/EmptyState";
import { EmptyStateLabels } from "../../shared/labels/EmptyState.labels";
import { ResumeBar } from "../../components/ResumeBar/ResumeBar";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import { ResumeChartBar } from "../../components/ResumeChartBar/ResumeChartBar";

export const MetricsContainer: FC = () => {
  const {
    resumeBarItems,
    isMobile,
    isEmptyResults,
    isLoading,
    emptyResultMessage,
    showChartBar,
  } = useMetricsContainer();

  return (
    <Grid container spacing={2} sx={isMobile ? styles.mainMobile : styles.main}>
      <Grid item md={12} sm={12}>
        <Typography variant={"h2"} color={"primary"}>
          {MetricsContainersLabels.MAIN_TITLE}
        </Typography>
      </Grid>
      <Grid item md={12} sm={12}>
        <MetricsFilterBar />
      </Grid>
      <ResumeBar items={resumeBarItems} />
      <Grid item md={12} sm={12}>
        <Divider variant="fullWidth" sx={styles.divider} />
      </Grid>
      {isEmptyResults && (
        <Grid data-testid={"empty-state"} item md={12} sm={12}>
          <EmptyState
            bodyText={emptyResultMessage}
            titleText={EmptyStateLabels.TITLE}
          />
        </Grid>
      )}
      {isLoading && (
        <Grid data-testid={"loading-spinner"} item md={12} sm={12}>
          <LoadingSpinner height={80} width={80} />
        </Grid>
      )}

      {showChartBar && <ResumeChartBar />}
    </Grid>
  );
};
