import React from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Menu,
} from "@material-ui/core";
import { DeferredMonths } from "../../shared/infrastructure/interfaces/IDeferredMonths";

import { usePopUpDeferredQuotasState } from "./state/usePopUpDeferredQuotasState";

import { makeStyles } from "@material-ui/core/styles";
import { get } from "lodash";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { DeferredSectionPlaceholder } from "../../shared/infrastructure/constants/DeferredConstants";

export interface IMonthsSelectorProps {
  disable: boolean;
  handleSelectedMonth: (
    value: number,
    index: number,
    selected: boolean
  ) => void;
  months: DeferredMonths[];
}

const useStyles = makeStyles(() => ({
  checkbox: {
    display: "flex",
    flexDirection: "row",
    fontSize: "14px",
    fontWeight: "bold",
  },
}));

export const PopUpDeferredQuotas: React.FC<IMonthsSelectorProps> = (
  props: IMonthsSelectorProps
) => {
  const {
    anchorEl,
    actions: { handleClick, handleCloseEl },
  } = usePopUpDeferredQuotasState();
  const styles = useStyles();

  return (
    <Grid container xs={4}>
      <Button
        disabled={props.disable}
        fullWidth
        variant={"outlined"}
        aria-label={"banks"}
        size={"large"}
        style={{ height: "45px", padding: "7px 14px" }}
        endIcon={<ArrowDropDownIcon color={"action"} />}
        onClick={handleClick}
      >
        {DeferredSectionPlaceholder.SELECT_QUOTAS}
      </Button>
      <Menu
        id="quotas"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseEl}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        elevation={1}
        getContentAnchorEl={null}
        PaperProps={{
          style: {
            padding: "12px",
            width: "290px",
          },
        }}
      >
        <Grid xs={12}>
          {props.months.map((month: DeferredMonths, index: number) => {
            return (
              <FormControlLabel
                className={styles.checkbox}
                value={month.value}
                key={index}
                control={
                  <Checkbox
                    size={"small"}
                    onChange={() =>
                      props.handleSelectedMonth(
                        month.value,
                        index,
                        !month.selected
                      )
                    }
                    name={month.value.toString()}
                    color="primary"
                    checked={month.selected}
                    disabled={!get(month, "enable", true)}
                  />
                }
                label={month.value}
                labelPlacement="end"
              />
            );
          })}
        </Grid>
      </Menu>
    </Grid>
  );
};
