import React, { useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Controller, ControllerRenderProps } from "react-hook-form";
import { PatternEnum } from "../../shared/infrastructure/enum/form/pattern-enum";
import { FormErrorsEnum } from "../../shared/infrastructure/enum/form/form-errors-enum";
import TextFieldComponent from "../Common/TextFieldComponent/TextFieldComponent";
import { defaultTo, get } from "lodash";
import { IModifyRetentionAmountForm } from "../../shared/infrastructure/interfaces/IModifyRetentionAmountForm";
import ConnectForm from "../Common/ConnectForm/ConnectForm";

export interface RetentionAmountsFormProps {}

const useStyles = makeStyles(() =>
  createStyles({
    bold: {
      fontWeight: 600,
    },
    marginDetail: {
      marginLeft: "0.5rem",
    },
    fullHeight: {
      height: "100%",
      marginLeft: "0.5rem",
      "& .MuiInputBase-root": {
        height: "100%",
        display: "flex",
        alignItems: "start",
      },
    },
  })
);

export const RetentionAmountsForm: React.FC<RetentionAmountsFormProps> = (
  _props: React.PropsWithChildren<RetentionAmountsFormProps>
) => {
  const classes = useStyles();

  return (
    <ConnectForm<IModifyRetentionAmountForm>>
      {({ errors, getErrorMessage, getValues, setValue, control, watch }) => {
        const retentionFueValue: string = watch("retentionFue");
        const retentionIvaValue: string = watch("retentionIva");
        const retentionTotalValue: string = (
          defaultTo(Number(retentionFueValue), 0) +
          defaultTo(Number(retentionIvaValue), 0)
        ).toFixed(2);

        useEffect(() => {
          setValue("retentionTotal", retentionTotalValue);
        }, [retentionFueValue, retentionIvaValue]);

        return (
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.bold} align="left">
                Modificación de montos:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="left">
                Ingresa los montos nuevos que deseas modificar.
              </Typography>
            </Grid>
            <Grid item xs={8} className={classes.marginDetail}>
              <Controller
                control={control}
                name={"retentionIva"}
                rules={{
                  required: true,
                  pattern: {
                    value: new RegExp(PatternEnum.decimalNumeric),
                    message: FormErrorsEnum.numeric,
                  },
                }}
                defaultValue={getValues().retentionIva}
                render={(field: ControllerRenderProps) => (
                  <TextFieldComponent
                    properties={field}
                    label="Monto retención IVA"
                    error={!!get(errors, "retentionIva")}
                    required
                    id={"retentionIva"}
                    helperText={
                      !!get(errors, "retentionIva") &&
                      getErrorMessage(get(errors, "retentionIva")!)
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={8} className={classes.marginDetail}>
              <Controller
                control={control}
                name={"retentionFue"}
                rules={{
                  required: true,
                  pattern: {
                    value: new RegExp(PatternEnum.decimalNumeric),
                    message: FormErrorsEnum.numeric,
                  },
                }}
                defaultValue={getValues().retentionFue}
                render={(field: ControllerRenderProps) => (
                  <TextFieldComponent
                    properties={field}
                    label="Monto retención Renta"
                    error={!!get(errors, "retentionFue")}
                    required
                    id={"retentionFue"}
                    helperText={
                      !!get(errors, "retentionFue") &&
                      getErrorMessage(get(errors, "retentionFue")!)
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={8} className={classes.marginDetail}>
              <Controller
                control={control}
                name={"retentionTotal"}
                defaultValue={getValues().retentionTotal}
                render={(field: ControllerRenderProps) => {
                  field.value = retentionTotalValue;

                  return (
                    <TextFieldComponent
                      disabled
                      properties={field}
                      label="Monto Total"
                      error={!!get(errors, "retentionTotal")}
                      required={false}
                      id={"retentionTotal"}
                      helperText={
                        "El monto total se calculará automáticamente."
                      }
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="left">
                *Indique el motivo del cambio de montos, para guardar la
                modificación.
              </Typography>
            </Grid>
            <Grid item xs={11} className={classes.fullHeight}>
              <Controller
                control={control}
                name={"modifyReason"}
                rules={{
                  required: true,
                  pattern: {
                    value: new RegExp(PatternEnum.maxLength200),
                    message: FormErrorsEnum.maxLength200,
                  },
                }}
                defaultValue={getValues().modifyReason}
                render={(field: ControllerRenderProps) => (
                  <TextFieldComponent
                    rowsMax={4}
                    multiline={true}
                    properties={field}
                    label="Motivo del cambio"
                    error={!!get(errors, "modifyReason")}
                    required
                    id={"modifyReason"}
                    inputProps={{
                      maxlength: 200,
                    }}
                    helperText={`${defaultTo(
                      get(field, "value.length"),
                      0
                    )}/200`}
                  />
                )}
              />
            </Grid>
          </Grid>
        );
      }}
    </ConnectForm>
  );
};
