export enum ProcessorsEnum {
  ALL = "Todos",
  DATAFAST = "Datafast",
  CREDIMATIC = "Credimatic",
  PROSA = "Prosa",
  BILLPOKET = "Billpoket",
  CREDOMATIC = "Credomatic",
  FIS = "Fis Processor",
}
export enum ProcessorNameEnum {
  FIS = "WorldPay (by FIS)",
}
export const translateProcessor: Record<string, string> = {
  [ProcessorsEnum.FIS]: ProcessorNameEnum.FIS,
};

export type ProcessorToShow = { value: string; label: string };
export const PROCESSOR_VALUES: { [k: string]: ProcessorToShow } = {
  [ProcessorsEnum.FIS]: {
    label: ProcessorNameEnum.FIS,
    value: ProcessorsEnum.FIS,
  },
};

export const BRAZIL_PROCESSORS: ProcessorToShow[] = [
  {
    label: ProcessorNameEnum.FIS,
    value: ProcessorsEnum.FIS,
  },
];
