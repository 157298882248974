import { makeStyles } from "@material-ui/core/styles";

export const customChipStyles = makeStyles({
  successBox: {
    marginTop: "20px",
    marginLeft: "12px",
    height: "34px",
    width: "280px",
    backgroundColor: "#E0FFF8",
    borderRadius: "5px",
  },
  errorBox: {
    marginTop: "20px",
    marginLeft: "12px",
    height: "34px",
    width: "280px",
    backgroundColor: "#FFEAEE",
    borderRadius: "5px",
  },
  successIcon: {
    marginRight: "4px",
    marginTop: "5px",
    color: "#0DC298",
  },
  errorIcon: {
    marginRight: "4px",
    marginTop: "5px",
    color: "#AD0C2A",
  },
  fileText: {
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "15px",
    color: "#023365",
    marginLeft: "4px",
    marginTop: "5px",
  },
  secondGrid: {
    textAlign: "end",
  },
  thirdGrid: {
    marginLeft: "12px",
    marginTop: "6px",
  },
  infoIcon: {
    color: "#AD0C2A",
  },
  errorFileSizeText: {
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "13px",
    color: "#AD0C2A",
  },
});
