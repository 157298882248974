import { Transaction } from "../../../types/transactions_data";
import { utcToZonedTime } from "date-fns-tz";
import { get } from "lodash";
import { differenceInMonths } from "date-fns";
import { TransactionType } from "../infraestructure/transaction-type";
import { isApproval } from "./general_utils";
import { validateChild } from "./transaction_list_utils";
import { isPosTransaction } from "./transaction_table_utils";

export function _validateMonth(trx: Transaction, timeZone: string): number {
  const date_now = new Date();
  const date_created = utcToZonedTime(get(trx, "_source.created"), timeZone);

  return differenceInMonths(date_now, date_created);
}

export function validateHiddenTrx(trx: Transaction): boolean {
  if (isPosTransaction(trx)) return true;

  const transaction_status: string = get(trx._source, "transaction_status", "");
  const is_approval: boolean = isApproval(transaction_status);
  const transaction_type: string = get(trx._source, "transaction_type", "");
  const arrayHidden: string[] = [
    TransactionType.VOID,
    TransactionType.VOID_PARTIAL,
    TransactionType.REFUND,
    TransactionType.REFUND_TOTAL,
    TransactionType.REFUND_PARTIAL,
  ];

  if (is_approval) return arrayHidden.includes(transaction_type);

  return true;
}

export function validateTooltipVoid(
  trx: Transaction,
  timeZone: string
): {
  child: {
    hasChild: boolean;
    typeChild: string;
    amount: number;
  };
  disable: boolean;
  message: string;
} {
  let obj: { disable: boolean; message: string } = {
    disable: false,
    message: "Solicitar devolución.",
  };
  const array: string[] = [
    TransactionType.CHARGEBACK,
    TransactionType.PREAUTHORIZATION,
  ];
  let amount_trx: number = get(trx, "_source.approved_transaction_amount", 0);
  const time_life: number = _validateMonth(trx, timeZone);
  const child: {
    hasChild: boolean;
    typeChild: string;
    amount: number;
  } = validateChild(trx);

  if (
    get(trx, "_source.payment_method", "") !== "card" ||
    array.includes(get(trx, "_source.transaction_type", ""))
  ) {
    obj = {
      disable: true,
      message: "Devolución no disponible para este medio de pago.",
    };
  } else {
    if (time_life >= 6) {
      if (!child.hasChild) {
        obj = {
          disable: true,
          message: "Después de 6 meses no se pueden realizar devoluciones.",
        };
      } else {
        if (child.typeChild === "refund") {
          obj = {
            disable: true,
            message: "Después de 6 meses no se pueden realizar reembolsos.",
          };
        } else {
          obj = {
            disable: true,
            message: "Después de 6 meses no se pueden realizar devoluciones.",
          };
        }
      }
    } else {
      if (child.hasChild) {
        if (child.amount < amount_trx) {
          obj = {
            disable: false,
            message: "Solicitar reembolso",
          };
        } else {
          obj = {
            disable: true,
            message: "Se solicitó un reembolso de esta transacción.",
          };
        }
      }
    }
  }

  return { ...obj, child };
}
