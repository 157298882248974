import { IconButton } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import React from "react";
import { styles } from "./CopyClipBoardButton.styles";
import { ICopyClipBoardButtonProps } from "./CopyClipBoardButton.interfaces";

const CopyClipBoardButton = ({ text }: ICopyClipBoardButtonProps) => {
  const handleCopy = async (text: string): Promise<void> => {
    await window.navigator.clipboard.writeText(text);
  };

  return (
    <IconButton
      aria-label="kushki-copy-button"
      onClick={() => {
        handleCopy(text);
      }}
      size="small"
      sx={styles.button}
    >
      <ContentCopy fontSize="small" sx={styles.icon} />
    </IconButton>
  );
};

export default CopyClipBoardButton;
