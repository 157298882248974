import {
  Box,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import React from "react";
import { FormSkeleton } from "../FormSkeleton/FormSkeleton";
import { dispersionContainerSkeletonStyles as styles } from "./DispersionContainerSkeleton.styles";

export const DispersionContainerSkeleton: React.FC = () => {
  return (
    <Card sx={styles.cardContent} data-testid="dispersion-skeleton">
      <CardContent>
        <Box sx={styles.boxContent2}>
          <Typography>
            <Skeleton />
          </Typography>
          <Grid
            container
            spacing={1}
            alignItems="center"
            sx={{ padding: "15px 5px", width: "100%" }}
          >
            <Grid md={12} xs={12} item>
              <Grid
                container
                spacing={1}
                alignItems="center"
                sx={{ marginTop: "1em", width: "60%" }}
              >
                <Grid item sx={{ pt: "16px" }} md={12} xs={12}>
                  <Typography>
                    <Skeleton />
                  </Typography>
                </Grid>
                <Grid
                  md={12}
                  xs={12}
                  item
                  sx={{ marginBottom: "1em", marginTop: "1em" }}
                >
                  <FormSkeleton rows={1} />
                </Grid>
                <Grid item sx={{ pt: "16px" }} md={6} xs={12}>
                  <Typography>
                    <Skeleton />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              md={12}
              xs={12}
              item
              sx={{ marginBottom: "1em", marginTop: "1em" }}
            >
              <Grid item sx={{ pt: "16px" }} md={1} xs={12}>
                <Typography>
                  <Skeleton />
                </Typography>
              </Grid>
              <Grid item sx={{ pt: "16px" }} md={3} xs={12}>
                <Typography>
                  <Skeleton />
                </Typography>
              </Grid>
              <Grid item sx={{ pt: "16px" }} md={4} xs={12}>
                <Typography>
                  <Skeleton />
                </Typography>
              </Grid>
              <Grid item sx={{ pt: "16px" }} md={1} xs={12}>
                <Typography>
                  <Skeleton />
                </Typography>
              </Grid>
              <Grid item sx={{ pt: "16px" }} md={2} xs={12}>
                <Typography>
                  <Skeleton />
                </Typography>
              </Grid>
              <Grid item sx={{ pt: "16px" }} md={6} xs={12}>
                <Typography>
                  <Skeleton />
                </Typography>
              </Grid>
            </Grid>
            <Grid
              md={12}
              xs={12}
              item
              sx={{ marginBottom: "1em", marginTop: "1em" }}
            >
              <FormSkeleton rows={2} />
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

export default DispersionContainerSkeleton;
