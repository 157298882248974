import { environment } from "../../environments/environment";

export const API_ROUTES = {
  BASIC_DETAILS: "/basic-details",
  BRANCH_SELECTION: "/branch/selection",
  CLIENTS: (ownerId: string, customerId: string) =>
    `/clients/${ownerId}/customers/${customerId}?showUser=true`,
  CREATE_MERCHANT: `${environment.kushkiUrl}/billing-core-node/v1/merchant-node/create`,
  CUSTOMER: "/customer?nodeId=",
  EDIT_BRANCHES: "/branch/edit",
  HISTORICAL_REFUNDS: `${environment.kushkiUrl}/b2c-analytics/v1/refund-transaction-list`,
  MERCHANT_BASIC_DETAILS: "/merchant/basic-details?publicMerchantId=",
  MERCHANT_NODES: `${environment.kushkiUrl}/billing-core-node/v1/merchant-node/search`,
  MERCHANT_RESPONSE: `${environment.kushkiUrl}/billing-core-node/v1/merchant-node/search-merchants`,
  NODE_INFO: `${environment.kushkiUrl}/billing-core-node/v1/node/info`,
  OWNER: "/owner",
  SEARCH_MERCHANT_NODE_BY_NODE_ID: `${environment.kushkiUrl}/hierarchy/v1/node/`,
  WS_MASSIVE: `wss://${environment.kushkiUrlWS}/billingcorenodews`,
};
