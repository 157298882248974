/* istanbul ignore file */

import { ComponentIdEnum } from "./ComponentIdEnum";
import { LabelEnum } from "../enums/LabelEnum";
import { StatusEnum } from "./StatusEnum";
import { IDENTIFIER } from "../constants/RuleRequestManagerConstants";
import { SubTypeEnum } from "./CreateRuleConstans";

export enum TableFieldsEnum {
  MERCHANT_NAME = "merchant_name",
  ACTIONS = "actions",
  MERCHANT_ID = "merchant_id",
  CREATED = "created",
  ENTITY_NAME = "entity_name",
  CONFIG_TYPE = "config_type",
  SUB_TYPE = "sub_type",
  TYPE = "type",
  COUNTRY = "country",
  RULE_ACTION = "rule.general_action",
  STATUS = "status",
  RULE_NAME = "rule.name",
  RULE = "rule",
  MASKED_CARD = "transaction_masked_credit_card",
  CARD_ID = "transaction_card_id",
  CARDHOLDER_NAME = "transaction_card_holder_name",
  EMAIL = "transaction_email",
  DOCUMENT_NUMBER = "transaction_document_number",
  PHONE_NUMBER = "transaction_phone_number",
  IP = "transaction_ip",
  SCHEDULE = "transaction_schedule",
  DATE = "transaction_date",
}

export enum TableValueEnum {
  FIRST_LEVEL = "first_level",
  OWNER = "owner",
  BRANCH = "branch",
  CUSTOMER = "customer",
  COMMERCE = "commerce",
  SECURITY_COMMERCE = "securityCommerce",
  SECURITY_COUNTRY = "securityCountry",
  COUNTRY = "country",
  ENABLE = "enable",
  DISABLE = "disable",
  DISABLED = "disabled",
  VALIDATE = "validate",
  AUTHENTICATE = "authenticate",
  WARNING = "warning",
  DEFAULT = "",
  WHITE_LIST = "whitelist",
}

export enum TableStatusEnum {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export const PENDING_REVIEW_COLUMNS: string[] = [
  TableFieldsEnum.CREATED,
  TableFieldsEnum.ENTITY_NAME,
  TableFieldsEnum.CONFIG_TYPE,
  TableFieldsEnum.SUB_TYPE,
  TableFieldsEnum.MERCHANT_NAME,
  TableFieldsEnum.COUNTRY,
];

export const FIRST_LEVEL_RULES_COLUMNS: string[] = [
  TableFieldsEnum.CREATED,
  TableFieldsEnum.TYPE,
  TableFieldsEnum.MERCHANT_NAME,
  TableFieldsEnum.RULE_NAME,
  TableFieldsEnum.RULE_ACTION,
  TableFieldsEnum.COUNTRY,
  TableFieldsEnum.STATUS,
];

export const WHITE_LIST_COLUMNS: string[] = [
  TableFieldsEnum.CREATED,
  TableFieldsEnum.TYPE,
  TableFieldsEnum.MERCHANT_NAME,
  TableFieldsEnum.COUNTRY,
  TableFieldsEnum.RULE,
  TableFieldsEnum.STATUS,
];

export const TEXT_TRANSFORM_COLUMNS: Record<string, string> = {
  [TableValueEnum.FIRST_LEVEL]: "Reglas de primer nivel",
  [TableValueEnum.OWNER]: "Owner",
  [TableValueEnum.BRANCH]: "Branch",
  [TableValueEnum.CUSTOMER]: "Customer",
  [TableValueEnum.COMMERCE]: "Comercio",
  [TableValueEnum.SECURITY_COMMERCE]: "Comercio",
  [TableValueEnum.SECURITY_COUNTRY]: "País",
  [TableValueEnum.COUNTRY]: "País",
  [TableValueEnum.ENABLE]: "Habilitada",
  [TableValueEnum.DISABLE]: "Deshabilitada",
  [TableValueEnum.DISABLED]: "Deshabilitada",
  [TableStatusEnum.ENABLED]: "Habilitada",
  [TableStatusEnum.DISABLED]: "Deshabilitada",
  [TableValueEnum.VALIDATE]: "Validar",
  [TableValueEnum.AUTHENTICATE]: "Autenticar",
  [TableValueEnum.WARNING]: "Alertar",
  [TableValueEnum.DEFAULT]: LabelEnum.NO_APPLY,
  [TableValueEnum.WHITE_LIST]: "Lista blanca",
  [SubTypeEnum.RULE_NAME_WHITELIST_COUNTRY]: "País",
  [SubTypeEnum.RULE_NAME_WHITELIST_WITH_HIERARCHY]: "Comercio",
  [SubTypeEnum.RULE_NAME_WHITELIST_WITHOUT_HIERARCHY]: "Comercio",
};

export const TEXT_TRANSFORM_SUBTYPE_COLUMNS: Record<string, string> = {
  [SubTypeEnum.RULE_NAME_WHITELIST_COUNTRY]: SubTypeEnum.COUNTRY,
  [SubTypeEnum.RULE_NAME_WHITELIST_WITH_HIERARCHY]: SubTypeEnum.COMMERCE,
  [SubTypeEnum.RULE_NAME_WHITELIST_WITHOUT_HIERARCHY]: SubTypeEnum.COMMERCE,
};

export const COLUMNS_BY_TABS: Record<string, string[]> = {
  [LabelEnum.PENDING_REVIEW]: PENDING_REVIEW_COLUMNS,
  [LabelEnum.FIRST_LEVEL_RULES]: FIRST_LEVEL_RULES_COLUMNS,
  [LabelEnum.REJECTED_APPLICATIONS]: PENDING_REVIEW_COLUMNS,
  [LabelEnum.WHITE_LISTS]: WHITE_LIST_COLUMNS,
};

export const COMPONENT_ID_BY_TABS: Record<string, string> = {
  [LabelEnum.PENDING_REVIEW]:
    ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_PENDING_ADD_OR_REJECT,
  [LabelEnum.FIRST_LEVEL_RULES]:
    ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_FIRST_LEVEL_EDIT,
  [LabelEnum.REJECTED_APPLICATIONS]:
    ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_REJECTED_EDIT,
  [LabelEnum.WHITE_LISTS]:
    ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_WHITE_LIST_EDIT,
};

export const COMPONENT_ID_BY_MODAL: Record<string, string> = {
  [`${LabelEnum.REJECTED_APPLICATIONS}`]:
    ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_MODAL_REJECTED_HEADER,
  [`${LabelEnum.FIRST_LEVEL_RULES}_${StatusEnum.DISABLE}`]:
    ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_MODAL_DISABLE_GENERAL_INFO,
  [`${LabelEnum.REJECTED_APPLICATIONS}_${StatusEnum.REJECTED}`]:
    ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_MODAL_REJECTED_GENERAL_INFO,
  [`${LabelEnum.PENDING_REVIEW}_${StatusEnum.APPROVED}_${LabelEnum.RIGHT_BUTTON}`]:
    ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_PENDING_BUTTON_RIGHT,
  [`${LabelEnum.PENDING_REVIEW}_${StatusEnum.APPROVED}_${LabelEnum.LEFT_BUTTON}`]:
    ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_PENDING_BUTTON_LEFT,
  [`${LabelEnum.PENDING_REVIEW}_${StatusEnum.PENDING}_${LabelEnum.RIGHT_BUTTON}`]:
    ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_PENDING_BUTTON_RIGHT,
  [`${LabelEnum.PENDING_REVIEW}_${StatusEnum.PENDING}_${LabelEnum.LEFT_BUTTON}`]:
    ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_PENDING_BUTTON_LEFT,
  [`${LabelEnum.FIRST_LEVEL_RULES}_${StatusEnum.DISABLE}_${LabelEnum.RIGHT_BUTTON}`]:
    ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_DISABLE_BUTTON_RIGHT,
  [`${LabelEnum.FIRST_LEVEL_RULES}_${StatusEnum.DISABLE}_${LabelEnum.LEFT_BUTTON}`]:
    ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_DELETE_BUTTON_LEFT,
  [`${LabelEnum.FIRST_LEVEL_RULES}_${StatusEnum.REJECTED}_${LabelEnum.LEFT_BUTTON}`]:
    ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_DELETE_BUTTON_LEFT,
  [`${LabelEnum.FIRST_LEVEL_RULES}_${StatusEnum.ENABLE}_${LabelEnum.RIGHT_BUTTON}`]:
    ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_ENABLE_BUTTON_RIGHT,
  [`${LabelEnum.FIRST_LEVEL_RULES}_${StatusEnum.ENABLE}_${LabelEnum.LEFT_BUTTON}`]:
    ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_ENABLE_BUTTON_LEFT,
  [`${LabelEnum.REJECTED_APPLICATIONS}_${StatusEnum.REJECTED}_${LabelEnum.LEFT_BUTTON}`]:
    ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_REJECTED_BUTTON_LEFT,
  [`${LabelEnum.FIRST_LEVEL_RULES}`]:
    ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_MODAL_REJECTED_HEADER,
  [`${LabelEnum.WHITE_LISTS}_${StatusEnum.ENABLE}_${LabelEnum.RIGHT_BUTTON}`]:
    ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_WHITE_LIST_ENABLE_BUTTON_RIGHT,
  [`${LabelEnum.WHITE_LISTS}_${StatusEnum.ENABLE}_${LabelEnum.LEFT_BUTTON}`]:
    ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_WHITE_LIST_ENABLE_BUTTON_LEFT,
  [`${LabelEnum.WHITE_LISTS}_${StatusEnum.DISABLE}_${LabelEnum.RIGHT_BUTTON}`]:
    ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_WHITE_LIST_DISABLE_BUTTON_RIGHT,
};

export const ItemsPerPage = [5, 10, 15, 20];

export enum ConditionPropertiesEnum {
  CARDHOLDER_NAME = "transaction_card_holder_name",
  MASKED_CARD = "transaction_masked_credit_card",
  CARD_ID = "transaction_card_id",
  EMAIL = "transaction_email",
  IP = "transaction_ip",
  DOCUMENT_NUMBER = "transaction_document_number",
  PHONE_NUMBER = "transaction_phone_number",
  DATE = "transaction_date",
  SCHEDULE = "transaction_schedule",
}

export const CONDITION_FIELDS: Record<string, TableFieldsEnum[]> = {
  [IDENTIFIER.TABLE]: [
    TableFieldsEnum.CARDHOLDER_NAME,
    TableFieldsEnum.MASKED_CARD,
    TableFieldsEnum.CARD_ID,
    TableFieldsEnum.EMAIL,
    TableFieldsEnum.IP,
    TableFieldsEnum.DOCUMENT_NUMBER,
    TableFieldsEnum.PHONE_NUMBER,
    TableFieldsEnum.DATE,
    TableFieldsEnum.SCHEDULE,
  ],
};

export const CONDITION_LABEL: Record<string, string> = {
  [ConditionPropertiesEnum.CARDHOLDER_NAME]: "Cardholder name",
  [ConditionPropertiesEnum.MASKED_CARD]: "Tarjeta Enmascarada",
  [ConditionPropertiesEnum.EMAIL]: "Email",
  [ConditionPropertiesEnum.IP]: "IP",
  [ConditionPropertiesEnum.DOCUMENT_NUMBER]: "Número de documento",
  [ConditionPropertiesEnum.PHONE_NUMBER]: "Número de teléfono",
  [ConditionPropertiesEnum.DATE]: "Fecha",
  [ConditionPropertiesEnum.SCHEDULE]: "Hora del comercio",
  [ConditionPropertiesEnum.CARD_ID]: "Kushki ID",
};
