import { defaultTo, get } from "lodash";
import { MerchantResponse } from "../../types/merchant_response";
import { ProcessorFetch } from "./infrastructure/interfaces/ProcessorFetch";
import { ProcessorEnum } from "./infrastructure/interfaces/ProcessorEnum";

export class auth {
  public static getAuthMerchant(): MerchantResponse {
    return JSON.parse(
      defaultTo(localStorage.getItem("merchantBasicInformation"), "{}")
    );
  }

  public static isAdmin(): boolean {
    return get(auth._getPayload(), "[cognito:groups]", []).includes(
      "BackofficeAdmin"
    );
  }

  public static hasProcessor(
    processorName: ProcessorEnum,
    processors: ProcessorFetch[]
  ): boolean {
    return Boolean(
      processors.find(
        (item: ProcessorFetch) => item.processorName === processorName
      )
    );
  }

  private static _getPayload(): object {
    return JSON.parse(defaultTo(localStorage.getItem("payload"), "{}"));
  }
}
