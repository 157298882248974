import { MerchantResponse } from "../../../../types/merchant_response";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { RateResponse } from "../../../../types/rate_response";
import { get } from "lodash";

export interface IuseInvoiceChargeDispersionData {
  merchant: MerchantResponse;
  rate: boolean;
}

export const useInvoiceChargeDispersionData = (): IuseInvoiceChargeDispersionData => {
  const merchant: MerchantResponse = useSelector(
    (state: IAppState) => state.merchant!
  );
  const rateStore: RateResponse = useSelector(
    (state: IAppState) => state.rate!
  );

  const rate = get(rateStore, "keepFraud", false);

  return {
    merchant,
    rate,
  };
};
