import { ICheckboxObject } from "../../components/TransactionForm/state/useTransactionSection.interfaces";
import { CountriesEnum } from "../enums/countriesEnum";
import { TransactionType } from "../enums/transactionTypesEnum";

export enum SemaphoreTransactionType {
  SUBTRACT = "subtract",
  DECLINED = "declined",
  BILL = "bill",
}

const DEFAULT_VALUES: ICheckboxObject[] = [
  {
    checked: false,
    disabled: false,
    label: "Contracargos",
    tooltip: false,
    value: TransactionType.CHARGEBACK,
  },
  {
    checked: false,
    disabled: true,
    label: "Reembolsos",
    tooltip: false,
    value: TransactionType.REFUND,
  },
  {
    checked: false,
    disabled: true,
    label: "Devoluciones",
    tooltip: false,
    value: TransactionType.VOID,
  },
  {
    checked: true,
    disabled: false,
    label: "Ventas",
    tooltip: false,
    value: TransactionType.SALE,
  },
  {
    checked: true,
    disabled: false,
    label: "Diferidos",
    tooltip: false,
    value: TransactionType.DEFERRED,
  },
  {
    checked: false,
    disabled: false,
    label: "Servicios Adicionales",
    tooltip: true,
    value: TransactionType.ADDITIONAL,
  },
];

const VALUES_WITH_PAYOUTS: ICheckboxObject[] = [
  ...DEFAULT_VALUES,
  {
    checked: true,
    disabled: false,
    label: "Pay out",
    tooltip: false,
    value: TransactionType.PAYOUT,
  },
];

const MEXICO_VALUES: ICheckboxObject[] = [
  ...DEFAULT_VALUES,
  {
    checked: false,
    disabled: false,
    label: "Captura",
    value: TransactionType.CAPTURE,
  },
  {
    checked: true,
    disabled: false,
    label: "Pay out",
    tooltip: false,
    value: TransactionType.PAYOUT,
  },
  {
    checked: false,
    disabled: false,
    label: "Pre autorización",
    value: TransactionType.PREAUTHORIZATION,
  },
];

const TRANSACTIONS_TO_SUBTRACT: ICheckboxObject[] = [
  {
    checked: true,
    disabled: true,
    label: "Devoluciones",
    value: TransactionType.VOID,
  },
  {
    checked: false,
    disabled: true,
    label: "Contracargos",
    value: TransactionType.CHARGEBACK,
  },
  {
    checked: true,
    disabled: true,
    label: "Reembolsos",
    value: TransactionType.REFUND,
  },
];

const TRANSACTION_TO_DECLINED: ICheckboxObject[] = [
  {
    checked: true,
    disabled: true,
    label: "Servicios adicionales",
    tooltip: true,
    value: TransactionType.ADDITIONAL_SERVICE,
  },
];

export const getValuesByCountry = (
  country: CountriesEnum
): ICheckboxObject[] => {
  switch (country) {
    case CountriesEnum.PERU:
    case CountriesEnum.CHILE:
    case CountriesEnum.COSTA_RICA:
    case CountriesEnum.PANAMA:
    case CountriesEnum.BRAZIL:
    case CountriesEnum.HONDURAS:
    case CountriesEnum.ESTADOS_UNIDOS:
    case CountriesEnum.NICARAGUA:
    case CountriesEnum.EL_SALVADOR:
    case CountriesEnum.COLOMBIA: {
      return VALUES_WITH_PAYOUTS;
    }
    case CountriesEnum.MEXICO: {
      return MEXICO_VALUES;
    }
    default: {
      return DEFAULT_VALUES;
    }
  }
};

export const CATALOG_VALUES = {
  [SemaphoreTransactionType.SUBTRACT]: TRANSACTIONS_TO_SUBTRACT,
  [SemaphoreTransactionType.DECLINED]: TRANSACTION_TO_DECLINED,
  [CountriesEnum.COLOMBIA]: getValuesByCountry(CountriesEnum.COLOMBIA),
  [CountriesEnum.ECUADOR]: getValuesByCountry(CountriesEnum.ECUADOR),
  [CountriesEnum.PERU]: getValuesByCountry(CountriesEnum.PERU),
  [CountriesEnum.CHILE]: getValuesByCountry(CountriesEnum.CHILE),
  [CountriesEnum.MEXICO]: getValuesByCountry(CountriesEnum.MEXICO),
  [CountriesEnum.EL_SALVADOR]: getValuesByCountry(CountriesEnum.EL_SALVADOR),
  [CountriesEnum.PANAMA]: getValuesByCountry(CountriesEnum.PANAMA),
  [CountriesEnum.COSTA_RICA]: getValuesByCountry(CountriesEnum.COSTA_RICA),
  [CountriesEnum.GUATEMALA]: getValuesByCountry(CountriesEnum.GUATEMALA),
  [CountriesEnum.HONDURAS]: getValuesByCountry(CountriesEnum.HONDURAS),
  [CountriesEnum.NICARAGUA]: getValuesByCountry(CountriesEnum.NICARAGUA),
  [CountriesEnum.ESTADOS_UNIDOS]: getValuesByCountry(
    CountriesEnum.ESTADOS_UNIDOS
  ),
  [CountriesEnum.BRAZIL]: getValuesByCountry(CountriesEnum.BRAZIL),
};

export const isAvailableCountry = (country: string) =>
  Object.keys(CATALOG_VALUES).some(
    (catalogCountry) => catalogCountry === country
  );
