export enum ExecutiveTextEnum {
  BREADCRUMB_TITLE = "Carga de ejecutivos",
  HEADER_TITLE = "Carga de ejecutivos",
  CARD_DESCRIPTION = "Aquí podrás revisar la última información guardada sobre los ejecutivos y de igual forma podrás actualizar esta lista reemplazando el archivo actual por una nueva versión.",
  STEP_1 = "Paso 1",
  STEP_2 = "Paso 2",
  CONSULT_HERE_LABEL = "¿Dudas sobre la carga de ejecutivos? ",
  CONSULT_HERE_BUTTON = "Consulta aquí",
  LAST_UPDATE = "Última actualización: ",
}

export enum ModalInfoLabel {
  TITLE = "¿Cómo realizar la carga de ejecutivos?",
  BUTTON_TEXT = "Entendido",
  INSTRUCTIONS = "Instrucciones:",
  STEP_1 = "El archivo {nombreDeArchivo} mostrará la última información vigente.",
  STEP_2 = "El archivo que adjuntes sustituirá completamente la información que se encuentra actualmente en la lista de ejecutivos, por lo que si eliminas o editas cualquier dato se perderá o modificará.",
  STEP_3 = "El proceso de carga de archivo validará la información cargada, por lo que el correo electrónico ingresado deberá contar con la siguiente estructura: ",
  STEP_4 = "El archivo no deberá tener registros duplicados por nombre.",
  EMAIL_EXAMPLE = "nombreusuario@mail.com.",
}

export enum ModalValidFileLabelEnum {
  TITLE = "Archivo válido ¿Deseas continuar con la carga de la nueva lista?",
  DESCRIPTION = "Este archivo reemplazará el que se haya subido previamente. Este proceso no se puede revertir.",
  CANCEL = "Cancelar",
  CONTINUE = "Continuar",
}
