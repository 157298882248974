import { PaymentMethodEnum, ServicesEnum } from "../enums/PaymentMethodEnum";
import React, { ReactNode } from "react";
import IconCashLocal from "../../assets/icons/iconsCash";
import IconCreditCardLocal from "../../assets/icons/iconsCreditCard";
import IconTransferLocal from "../../assets/icons/iconsTransfer";
import IconCash from "@kushki/connect-ui/dist/Components/Atoms/Icons/IconCash";
import IconTransfer from "@kushki/connect-ui/dist/Components/Atoms/Icons/IconTransferCurve";
import { IconCreditCard } from "@kushki/connect-ui";

export const PAYMENT_METHOD_ICON: Record<string, ReactNode> = {
  [PaymentMethodEnum.CARD]: <IconCreditCardLocal />,
  [PaymentMethodEnum.TRANSFER]: <IconTransferLocal />,
  [PaymentMethodEnum.CASH]: <IconCashLocal />,
  [PaymentMethodEnum.PAYOUTS_CASH]: <IconCashLocal />,
  [PaymentMethodEnum.PAYOUTS_TRANSFER]: <IconTransferLocal />,
  [PaymentMethodEnum.PAYOUTS_CARD]: <IconCreditCardLocal />,
};

export const PAYMENT_METHOD_TRANSLATE: Record<string, string> = {
  [PaymentMethodEnum.CARD]: "Tarjeta",
  [PaymentMethodEnum.TRANSFER]: "Transferencia",
  [PaymentMethodEnum.CASH]: "Efectivo",
};

export const SERVICES_TRANSLATE: Record<string, string> = {
  [ServicesEnum.OTP]: "OTP",
  [ServicesEnum.SIFTSCIENCE]: "SiftScience",
  [ServicesEnum._3DS]: "3DS",
  [ServicesEnum.TRANSUNION]: "TransUnion",
};

export const PAYMENT_METHOD_ICON_TABLE: Record<string, ReactNode> = {
  [PaymentMethodEnum.CARD]: (
    <IconCreditCard fontSize={"medium"} color={"info"} />
  ),
  [PaymentMethodEnum.CASH]: <IconCash fontSize={"medium"} color={"info"} />,
  [PaymentMethodEnum.TRANSFER]: (
    <IconTransfer fontSize={"medium"} color={"info"} />
  ),
};
