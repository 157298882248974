export const CausalReason = [
  {
    value: "01",
    description: "01-Comprobante emitido con errores con relación",
  },
  {
    value: "02",
    description: "02-Comprobante emitido con errores sin relación",
  },
  { value: "03", description: "03-No se llevo a cabo la operación" },
  {
    value: "04",
    description: "04-Operación nominativa relacionada en una factura global",
  },
];
