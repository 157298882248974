import React from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Actions,
  Periodicities,
  Transactions,
} from "../../../../shared/infrastructure/constants/SpeedConditionsRules";
import ConnectForm from "../../../common/ConnectForm/ConnectForm";
import { IRuleConditionForm } from "../../../../shared/infrastructure/interfaces/IRuleConditionForm";
import { ComponentText } from "../../../ComponentText/ComponentText";
import { get } from "lodash";
import { Controller } from "react-hook-form";

const useStyles = makeStyles((theme: Theme) => ({
  labelText: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  textField: {
    padding: "10px 20px",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
    textAlign: "left",
  },
  subTitle: {
    display: "flex",
    padding: "0 15px",
    marginBottom: "25px",
  },
  containerAddRule: {
    display: "flex",
    justifyContent: "flex-end",
  },
  btnAddRule: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "10px",
  },
  rulesConditionSelectsContainer: {
    display: "flex",
  },
  rulesConditionSelectItem: {
    padding: "0px 10px",
  },
}));

export interface ISecondaryActionsStateProps {
  indexParent: number;
  item: any;
}

export const SecondaryActions: React.FC<ISecondaryActionsStateProps> = (
  props: ISecondaryActionsStateProps
) => {
  const classes = useStyles();
  const { indexParent, item } = props;
  return (
    <ConnectForm<IRuleConditionForm>>
      {({ formState: { errors }, getErrorMessage, control }) => {
        return (
          <Grid container>
            <Grid item xs={12} className={classes.subTitle}>
              <ComponentText
                label={"Agrega acciones secundarias a tu regla."}
                type={"lighter"}
                params={{ variant: "body2" }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.rulesConditionSelectsContainer}
            >
              <Grid item xs={3} className={classes.rulesConditionSelectItem}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel
                    id={`label_strictCondition[${indexParent}].actionSpeed`}
                  >
                    Acción
                  </InputLabel>
                  <Controller
                    control={control}
                    name={`strictCondition[${indexParent}].actionSpeed`}
                    defaultValue={get(item, "actionSpeed", "")}
                    rules={{ required: true }}
                    error={
                      !!get(
                        errors,
                        `strictCondition[${indexParent}].actionSpeed`
                      )
                    }
                    render={(field) => (
                      <Select
                        inputProps={{
                          "data-testid": `strictCondition[${indexParent}].actionSpeed`,
                        }}
                        id={`strictCondition[${indexParent}].actionSpeed`}
                        {...field}
                        onChange={field.onChange}
                        label="Acción"
                      >
                        {Actions.map((item) => (
                          <MenuItem
                            key={item.value}
                            value={item.value}
                            disabled={false}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <FormHelperText
                    error={
                      !!get(
                        errors,
                        `strictCondition[${indexParent}].actionSpeed`
                      )
                    }
                  >
                    {!!get(
                      errors,
                      `strictCondition[${indexParent}].actionSpeed`
                    ) &&
                      getErrorMessage(
                        get(
                          errors,
                          `strictCondition[${indexParent}].actionSpeed`
                        )
                      )}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={3} className={classes.rulesConditionSelectItem}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel
                    id={`label_strictCondition[${indexParent}].periodicity`}
                  >
                    Periodicidad
                  </InputLabel>
                  <Controller
                    control={control}
                    name={`strictCondition[${indexParent}].periodicity`}
                    defaultValue={get(item, "periodicity", "")}
                    rules={{ required: true }}
                    error={
                      !!get(
                        errors,
                        `strictCondition[${indexParent}].periodicity`
                      )
                    }
                    render={(field) => (
                      <Select
                        inputProps={{
                          "data-testid": `strictCondition[${indexParent}].periodicity`,
                        }}
                        id={`strictCondition[${indexParent}].periodicity`}
                        {...field}
                        onChange={field.onChange}
                        label="Periodicidad"
                      >
                        {Periodicities.map((item) => (
                          <MenuItem
                            key={item.value}
                            value={item.value}
                            disabled={false}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <FormHelperText
                    error={
                      !!get(
                        errors,
                        `strictCondition[${indexParent}].periodicity`
                      )
                    }
                  >
                    {!!get(
                      errors,
                      `strictCondition[${indexParent}].periodicity`
                    ) &&
                      getErrorMessage(
                        get(
                          errors,
                          `strictCondition[${indexParent}].periodicity`
                        )
                      )}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={3} className={classes.rulesConditionSelectItem}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel
                    id={`label_strictCondition[${indexParent}].transactionStatus`}
                  >
                    Transacciones
                  </InputLabel>
                  <Controller
                    control={control}
                    name={`strictCondition[${indexParent}].transactionStatus`}
                    defaultValue={get(item, "transactionStatus", "")}
                    rules={{ required: true }}
                    error={
                      !!get(
                        errors,
                        `strictCondition[${indexParent}].transactionStatus`
                      )
                    }
                    render={(field) => (
                      <Select
                        inputProps={{
                          "data-testid": `strictCondition[${indexParent}].transactionStatus`,
                        }}
                        id={`strictCondition[${indexParent}].transactionStatus`}
                        {...field}
                        onChange={field.onChange}
                        label="Transacciones"
                      >
                        {Transactions.map((item) => (
                          <MenuItem
                            key={item.value}
                            value={item.value}
                            disabled={false}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <FormHelperText
                    error={
                      !!get(
                        errors,
                        `strictCondition[${indexParent}].transactionStatus`
                      )
                    }
                  >
                    {!!get(
                      errors,
                      `strictCondition[${indexParent}].transactionStatus`
                    ) &&
                      getErrorMessage(
                        get(
                          errors,
                          `strictCondition[${indexParent}].transactionStatus`
                        )
                      )}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={3} className={classes.rulesConditionSelectItem}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <Controller
                    control={control}
                    name={`strictCondition[${indexParent}].maxAmount`}
                    defaultValue={get(item, "maxAmount", "")}
                    rules={{ required: true }}
                    error={
                      !!get(errors, `strictCondition[${indexParent}].maxAmount`)
                    }
                    render={(field) => (
                      <TextField
                        {...field}
                        label="Valor"
                        id={`strictCondition[${indexParent}].maxAmount`}
                        variant="outlined"
                        error={
                          !!get(
                            errors,
                            `strictCondition[${indexParent}].maxAmount`
                          )
                        }
                        inputProps={{
                          "data-testid": `strictCondition[${indexParent}].maxAmount`,
                        }}
                        required
                        fullWidth
                        helperText={
                          !!get(
                            errors,
                            `strictCondition[${indexParent}].maxAmount`
                          ) &&
                          getErrorMessage(
                            get(
                              errors,
                              `strictCondition[${indexParent}].maxAmount`
                            )!
                          )
                        }
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </ConnectForm>
  );
};
