import { ISelectAutocomplete } from "../Interfaces/SelectAutocomplete.interfaces";

export const mccCatalog: ISelectAutocomplete[] = [
  { text: "000 - No aplica", value: "000" },
  { text: "5933 - Casas de Empeño", value: "5933" },
  {
    text: "5122 - (Drogas, propiedad de medicamentos, artículos diversos de boticario)",
    value: "5122",
  },
  {
    text: "7998 - Acuarios, acuarios marinos, delfinarios y zoológicos",
    value: "7998",
  },
  {
    text: "4511 - Aerolíneas y transportistas aéreos (no clasificados en otra parte)",
    value: "4511",
  },
  {
    text: "7361 - Agencias de empleo y servicios de ayuda temporal",
    value: "7361",
  },
  { text: "4722 - Agencias de viajes y operadores turísticos", value: "4722" },
  { text: "6513 - Agentes y administradores inmobiliarios", value: "6513" },
  { text: "5200 - Almacén de suministros para el hogar", value: "5200" },
  {
    text: "4225 - Almacenamiento y depósito públicos: productos agrícolas",
    value: "4225",
  },
  {
    text: "7011 - Alojamiento: hoteles, moteles, centros turísticos, sistemas centrales de reserva (no clasificados en otra parte)",
    value: "7011",
  },
  {
    text: "7394 - Alquiler y arrendamiento de equipos, herramientas, muebles y electrodomésticos",
    value: "7394",
  },
  {
    text: "7995 - Apuestas, incluidos billetes de lotería, fichas de juegos de casino, apuestas fuera de pista, apuestas en hipódromos y juegos de azar para ganar premios de valor monetario",
    value: "7995",
  },
  {
    text: "8911 - Architectural, Engineering, and Surveying Services",
    value: "8911",
  },
  { text: "7991 - Atracciones turísticas y exhibiciones", value: "7991" },
  {
    text: "4214 - Autotransportistas y camiones: locales y de larga distancia, empresas de mudanzas y almacenamiento, y servicios de entrega local",
    value: "4214",
  },
  { text: "5816 - Bienes digitales - Juegos", value: "5816" },
  {
    text: "5199 - Bienes no duraderos (no clasificados en otra parte)",
    value: "5199",
  },
  { text: "5192 - Books, Periodicals and Newspapers", value: "5192" },
  { text: "4411 - Buques de vapor y cruceros", value: "4411" },
  {
    text: "4899 - Cable, satélite y otros servicios de televisión / radio / transmisión de pago",
    value: "4899",
  },
  { text: "7032 - Campamentos deportivos y recreativos", value: "7032" },
  {
    text: "7802 - Carreras de caballos / perros con licencia del gobierno (solo en la región de EE. UU.)",
    value: "7802",
  },
  {
    text: "7801 - Casinos en línea con licencia del gobierno (juegos de apuestas en línea)",
    value: "7801",
  },
  { text: "5811 - Catering", value: "5811" },
  { text: "8050 - Centros de enfermería y cuidado personal", value: "8050" },
  {
    text: "5945 - Clubes de membresía (deportes, Recreación, Atlético), Clubes de campo, y campos de golf privados",
    value: "5945",
  },
  {
    text: "7997 - Clubes de membresía (deportes, recreación, atletismo), clubes de campo y campos de golf privados",
    value: "7997",
  },
  {
    text: "8220 - Colegios, universidades, escuelas profesionales y colegios universitarios",
    value: "8220",
  },
  {
    text: "5511 - Concesionarios de automóviles y camiones (nuevos y usados) Ventas, servicio, reparaciones, repuestos y arrendamiento",
    value: "5511",
  },
  {
    text: "1799 - Contratistas comerciales especiales (no clasificados bajo otros epígrafes)",
    value: "1799",
  },
  { text: "1750 - Contratistas de carpintería", value: "1750" },
  { text: "6211 - Corredores / distribuidores de seguridad (", value: "6211" },
  { text: "8021 - Dentistas y ortodoncistas", value: "8021" },
  { text: "5994 - Distribuidores de noticias y quioscos", value: "5994" },
  {
    text: "5599 - Distribuidores diversos de maquinaria automotriz, aeronáutica y agrícola (no clasificados en otra parte)",
    value: "5599",
  },
  { text: "5912 - Droguerías y farmacias", value: "5912" },
  {
    text: "5044 - Equipo y suministros fotográficos, de fotocopias y microfilmes",
    value: "5044",
  },
  {
    text: "5047 - Equipos y suministros médicos, dentales, oftálmicos y hospitalarios Medical, Dental, Ophthalmic and Hospital Equipment and Supplies",
    value: "5047",
  },
  { text: "8244 - Escuelas de negocios y secretariado", value: "8244" },
  {
    text: "8299 - Escuelas y servicios educativos (no clasificados en otra parte)",
    value: "8299",
  },
  {
    text: "5541 - Estaciones de servicio (con o sin servicios auxiliares",
    value: "5541",
  },
  { text: "7221 - Estudios Fotográficos", value: "7221" },
  { text: "5251 - Ferreterías", value: "5251" },
  { text: "4011 - Ferrocarriles", value: "4011" },
  { text: "7333 - Fotografía comercial, arte y gráficos", value: "7333" },
  { text: "5971 - Galerías y marchantes de arte", value: "5971" },
  { text: "5311 - Grandes almacenes", value: "5311" },
  { text: "8062 - Hospitales", value: "8062" },
  {
    text: "6011 - Instituciones financieras: desembolsos de efectivo automatizados",
    value: "6011",
  },
  {
    text: "6010 - Instituciones financieras: desembolsos de efectivo manuales",
    value: "6010",
  },
  {
    text: "6012 - Instituciones financieras: mercadería, servicios y pago de deudas",
    value: "6012",
  },
  {
    text: "6051 - Instituciones no financieras - Moneda extranjera, no fiat   Moneda (por ejemplo: criptomoneda), giros postales (no   Transferencia de dinero), Financiamiento de la cuenta (no carga de valor almacenado), Cheques de viajero y Pago de deudas",
    value: "6051",
  },
  { text: "5944 - joyerías, relojería, relojería y platería ", value: "5944" },
  {
    text: "7800 - Juegos de apuestas: loterías propiedad del gobierno",
    value: "7800",
  },
  { text: "5942 - Librerias", value: "5942" },
  {
    text: "9406 - Loterías propiedad del gobierno (región fuera de EE. UU.)",
    value: "9406",
  },
  {
    text: "5813 - Lugares para beber (bebidas alcohólicas): bares, tabernas, clubes nocturnos, salones de cócteles y discotecas",
    value: "5813",
  },
  { text: "5812 - Lugares para comer y restaurantes", value: "5812" },
  {
    text: "5966 - Marketing directo - Merchan de telemarketing saliente",
    value: "5966",
  },
  {
    text: "5967 - Marketing directo: comerciante de teleservicios entrantes",
    value: "5967",
  },
  {
    text: "5039 - Materiales de construcción (no clasificados en otra parte) ",
    value: "5039",
  },
  {
    text: "8011 - Médicos y médicos (no clasificados bajo otros epígrafes)",
    value: "8011",
  },
  { text: "5931 - Mercancía usada y tiendas de segunda mano", value: "5931" },
  { text: "8651 - Orgamizaciones Politicas", value: "8651" },
  {
    text: "8398 - Organizaciones benéficas de servicios sociales",
    value: "8398",
  },
  { text: "8661 - Organizaciones Religiosas", value: "8661" },
  { text: "8699 - Otras organizaciones", value: "8699" },
  { text: "5462 - Panaderías", value: "5462" },
  { text: "5943 - Papelerías", value: "5943" },
  {
    text: "7996 - Parques de atracciones, circos, carnavales y adivinos",
    value: "7996",
  },
  { text: "5681 - Peleteros y peleterías", value: "5681" },
  { text: "5172 - Petróleo y productos derivados del petróleo", value: "5172" },
  { text: "5065 - Piezas y equipos eléctricos", value: "5065" },
  { text: "2842 - Preparaciones especiales de limpieza", value: "2842" },
  {
    text: "7829 - Producción y distribución de películas y cintas de video",
    value: "7829",
  },
  { text: "2741 - Publicaciones e impresiones diversas", value: "2741" },
  { text: "7311 - Publicidad", value: "7311" },
  {
    text: "4468 - Puertos deportivos, servicio marítimo y suministros",
    value: "4468",
  },
  {
    text: "5169 - Químicos y productos afines (no clasificados en otra parte) Chemicals and Allied Products (Not Elsewhere Classified)",
    value: "5169",
  },
  {
    text: "7631 - Reparación de relojería, relojería y joyería",
    value: "7631",
  },
  { text: "5814 - Restaurantes de comida rápida", value: "5814" },
  { text: "7994 - Salones / Establecimientos de videojuegos", value: "7994" },
  {
    text: "7399 - Servicios comerciales (no clasificados en otra parte)",
    value: "7399",
  },
  {
    text: "7277 - Servicios de asesoramiento: deudas, matrimoniales y personales",
    value: "7277",
  },
  { text: "7273 - Servicios de citas", value: "7273" },
  { text: "7342 - Servicios de exterminio y desinfección", value: "7342" },
  {
    text: "7392 - Servicios de gestión, consultoría y relaciones públicas",
    value: "7392",
  },
  {
    text: "4816 - Servicios de información de redes informáticas",
    value: "4816",
  },
  {
    text: "1711 - Servicios de información de redes informáticas",
    value: "1711",
  },
  {
    text: "7210 - Servicios de lavandería, limpieza y confección",
    value: "7210",
  },
  {
    text: "7349 - Servicios de limpieza, mantenimiento y limpieza",
    value: "7349",
  },
  {
    text: "4215 - Servicios de mensajería - Aéreos, terrestres y transitarios.",
    value: "4215",
  },
  {
    text: "5962 - Servicios de organización relacionados con viajes",
    value: "5962",
  },
  {
    text: "7372 - Servicios de programación informática, procesamiento de datos y diseño de sistemas integrados",
    value: "7372",
  },
  {
    text: "7999 - Servicios de recreación (no clasificados en otra parte)",
    value: "7999",
  },
  { text: "7375 - Servicios de recuperación de información", value: "7375" },
  {
    text: "4814 - Servicios de telecomunicaciones, incluidas llamadas locales y de larga distancia, llamadas con tarjeta de crédito, llamadas mediante el uso de teléfonos con lectura de banda magnética y servicio de fax",
    value: "4814",
  },
  { text: "7261 - Servicios Funerarios", value: "7261" },
  {
    text: "9399 - Servicios gubernamentales (no clasificados en otra parte)",
    value: "9399",
  },
  { text: "8111 - Servicios legales y abogados", value: "8111" },
  {
    text: "8999 - Servicios profesionales (no clasificados en otra parte)",
    value: "8999",
  },
  {
    text: "4900 - Servicios públicos: electricidad, gas, agua y sanitarios",
    value: "4900",
  },
  {
    text: "7379 - Servicios, reparación y mantenimiento de computadoras (no clasificados en otra parte)",
    value: "7379",
  },
  {
    text: "7299 - Servicios: filtración de agua, purificación, ablandamiento",
    value: "7299",
  },
  { text: "7298 - Spas de salud y belleza", value: "7298" },
  {
    text: "5422 - Suministradores de carne para congeladores y casilleros",
    value: "5422",
  },
  {
    text: "5193 - Suministros de floristería, material de vivero y flores",
    value: "5193",
  },
  {
    text: "5013 - Suministros de vehículos de motor y piezas nuevas",
    value: "5013",
  },
  {
    text: "7993 - Suministros de videojuegos de entretenimiento",
    value: "7993",
  },
  { text: "5085 - Suministros industriales", value: "5085" },
  {
    text: "7623 - Talleres de reparación de aire acondicionado y refrigeración",
    value: "7623",
  },
  {
    text: "7629 - Talleres de reparación de electrodomésticos pequeños y eléctricos",
    value: "7629",
  },
  { text: "7622 - Talleres de reparación de electrónica", value: "7622" },
  {
    text: "7699 - Talleres de reparación diversos y servicios relacionados",
    value: "7699",
  },
  {
    text: "7534 - Talleres de reparación y recauchutado de neumáticos - Tire Retreading and Repair Shops",
    value: "7534",
  },
  {
    text: "7538 - Talleres de servicio automotriz (no distribuidor)",
    value: "7538",
  },
  { text: "7832 - Teatros cinematográficos", value: "7832" },
  { text: "7012 - Tiempos compartidos", value: "7012" },
  { text: "5411 - Tiendas de abarrotes y supermercados", value: "5411" },
  {
    text: "5499 - Tiendas de alimentos misceláneas: tiendas de conveniencia y mercados especializados",
    value: "5499",
  },
  {
    text: "4111 - Tiendas de alimentos misceláneas: tiendas de conveniencia y mercados especializados",
    value: "4111",
  },
  {
    text: "7841 - Tiendas de alquiler de cintas de vídeo / DVD",
    value: "7841",
  },
  { text: "5941 - Tiendas de artículos deportivos", value: "5941" },
  { text: "5993 - Tiendas de cigarros y puestos", value: "5993" },
  { text: "5735 - Tiendas de discos", value: "5735" },
  { text: "5722 - Tiendas de electrodomésticos", value: "5722" },
  { text: "5948 - Tiendas de equipaje y marroquinería", value: "5948" },
  { text: "5732 - Tiendas de equipos electricos", value: "5732" },
  {
    text: "5211 - Tiendas de madera y materiales de construcción",
    value: "5211",
  },
  { text: "5995 - Tiendas de mascotas", value: "5995" },
  {
    text: "5712 - Tiendas de muebles, muebles para el hogar y equipos, excepto electrodomésticos",
    value: "5712",
  },
  {
    text: "5733 - Tiendas de música: instrumentos musicales, pianos y partituras Music Stores ",
    value: "5733",
  },
  { text: "5921 - Tiendas de paquetes: cerveza", value: "5921" },
  { text: "5451 - Tiendas de productos lácteos", value: "5451" },
  { text: "5947 - Tiendas de regalos", value: "5947" },
  { text: "5713 - Tiendas de revestimientos de suelos", value: "5713" },
  { text: "5651 - Tiendas de ropa familiar", value: "5651" },
  { text: "5099 - Tiendas de ropa para deportes y equitación", value: "5099" },
  { text: "5691 - Tiendas de ropa para hombres y mujeres", value: "5691" },
  { text: "5972 - Tiendas de sellos y monedas", value: "5972" },
  { text: "5231 - Tiendas de vidrio", value: "5231" },
  { text: "5699 - Tiendas diversas de ropa y accesorios", value: "5699" },
  {
    text: "5631 - Tiendas especializadas y de accesorios para mujeres",
    value: "5631",
  },
  {
    text: "5999 - Tiendas minoristas diversas y especializadas",
    value: "5999",
  },
  { text: "4829 - Transferencia de dinero", value: "4829" },
  { text: "5719 - Varios muebles para el hogar", value: "5719" },
  {
    text: "4812 - Venta de equipos de telecomunicaciones y telefonía",
    value: "4812",
  },
  { text: "6300 - Ventas, suscripción y primas de seguros", value: "6300" },
  { text: "0742 - Veterinarias", value: "0742" },
  {
    text: "5261 - Viveros y tiendas de suministros para césped y jardín",
    value: "5261",
  },
  { text: "5661 - Zapatería", value: "5661" },
  { text: "0780 - Servicios de jardinería y horticultura", value: "0780" },
  {
    text: "1520 - Contratistas Generales - Residenciales y Comerciales",
    value: "1520",
  },
  {
    text: "1740 - Contratistas de albañilería, mampostería, colocación de baldosas, enlucido y aislamiento",
    value: "1740",
  },
  {
    text: "1761 - Contratistas de techos, revestimientos y trabajos de chapa",
    value: "1761",
  },
  { text: "3000-3350 - MCC de aerolíneas individuales", value: "3000-3350" },
  { text: "4112 - Ferrocarriles de pasajeros", value: "4112" },
  { text: "4119 - Servicios de ambulancia", value: "4119" },
  { text: "4121 - Taxis y limusinas", value: "4121" },
  { text: "4131 - Líneas de bus", value: "4131" },
  { text: "4457 - Alquiler y arrendamiento de barcos", value: "4457" },
  {
    text: "4582 - Aeropuertos, campos de vuelo y terminales aeroportuarias",
    value: "4582",
  },
  {
    text: "4723 - Operadores turísticos de paquetes: solo en Alemania",
    value: "4723",
  },
  { text: "4784 - Peajes y tarifas de puentes", value: "4784" },
  {
    text: "4789 - Servicios de transporte (no clasificados en otra parte)",
    value: "4789",
  },
  { text: "4821 - Servicios de telégrafo", value: "4821" },
  { text: "5021 - Mobiliario de oficina y comercial ...", value: "5021" },
  {
    text: "5045 - Computadoras y equipos y software periféricos de computadora",
    value: "5045",
  },
  {
    text: "5046 - Equipo comercial (no clasificado en otra parte)",
    value: "5046",
  },
  { text: "5051 - Centros y oficinas de servicios metálicos", value: "5051" },
  { text: "5072 - Hardware, equipos y suministros", value: "5072" },
  {
    text: "5074 - Equipos y suministros de fontanería y calefacción",
    value: "5074",
  },
  {
    text: "5094 - Piedras preciosas y metales, relojería y joyería",
    value: "5094",
  },
  {
    text: "5111 - Papelería, Material de oficina, Papel de impresión y escritura",
    value: "5111",
  },
  {
    text: "5131 - Productos por pieza, nociones y otros productos secos",
    value: "5131",
  },
  {
    text: "5137 - Uniformes y ropa comercial para hombres, mujeres y niños",
    value: "5137",
  },
  { text: "5139 - Calzado comercial ........", value: "5139" },
  {
    text: "5189 - Productos químicos y productos afines (no clasificados en otra parte)",
    value: "5189",
  },
  { text: "5198 - Pinturas, barnices y suministros", value: "5198" },
  { text: "5262 - Mercados", value: "5262" },
  { text: "5271 - Distribuidores de casas móviles", value: "5271" },
  { text: "5300 - Clubes al por mayor", value: "5300" },
  { text: "5309 - Tiendas libres de impuestos", value: "5309" },
  { text: "5310 - Tiendas de descuento", value: "5310" },
  { text: "5331 - Tiendas de variedades", value: "5331" },
  { text: "5399 - Mercancía general miscelánea", value: "5399" },
  { text: "5441 - Tiendas de dulces, nueces y confitería", value: "5441" },
  {
    text: "5521 - Concesionarios de automóviles y camiones (solo usados) Ventas, servicio, reparaciones, repuestos y arrendamiento",
    value: "5521",
  },
  { text: "5532 - Tiendas de neumáticos para automóviles", value: "5532" },
  {
    text: "5533 - Tiendas de repuestos y accesorios automotrices",
    value: "5533",
  },
  { text: "5542 - Dispensadores de combustible automatizados", value: "5542" },
  { text: "5551 - Concesionarios de barcos", value: "5551" },
  {
    text: "5561 - Concesionarios de caravanas, remolques recreativos y utilitarios",
    value: "5561",
  },
  { text: "5571 - Tiendas y concesionarios de motocicletas", value: "5571" },
  { text: "5592 - Concesionarios de casas rodantes", value: "5592" },
  { text: "5598 - Concesionarios de motos de nieve", value: "5598" },
  {
    text: "5611 - Tiendas de ropa y accesorios para hombres y niños",
    value: "5611",
  },
  { text: "5621 - Tiendas de prêt-à-porter para mujeres", value: "5621" },
  { text: "5641 - Tiendas de ropa para niños y bebés", value: "5641" },
  { text: "5655 - Tiendas de ropa para deportes y equitación", value: "5655" },
  { text: "5697 - Sastres, costureras, arreglos y reformas", value: "5697" },
  { text: "5698 - Tiendas de pelucas y peluquines", value: "5698" },
  { text: "5714 - Tiendas de cortinas, cortinas y tapicerías", value: "5714" },
  {
    text: "5718 - Tiendas de Chimeneas, Pantallas para Chimeneas y Accesorios",
    value: "5718",
  },
  { text: "5734 - Tiendas de software informático", value: "5734" },
  {
    text: "5815 - Medios de productos digitales: libros, películas, música",
    value: "5815",
  },
  {
    text: "5817 - Productos digitales: aplicaciones (no incluye juegos)",
    value: "5817",
  },
  {
    text: "5818 - Bienes digitales: gran comerciante de bienes digitales",
    value: "5818",
  },
  {
    text: "5932 - Tiendas de antigüedades: servicios de venta, reparación y restauración",
    value: "5932",
  },
  { text: "5935 - Astilleros de demolición y salvamento", value: "5935" },
  { text: "5937 - Reproducciones antiguas", value: "5937" },
  { text: "5940 - Tiendas de bicicletas: venta y servicio", value: "5940" },
  {
    text: "5946 - Tiendas de suministros fotográficos y de cámaras",
    value: "5946",
  },
  {
    text: "5949 - Tiendas de costura, costura, telas y piezas.",
    value: "5949",
  },
  { text: "5950 - Cristalería / Tiendas de cristal ...", value: "5950" },
  { text: "5960 - Marketing directo - Servicios de seguros", value: "5960" },
  { text: "5963 - Ventas puerta a puerta", value: "5963" },
  { text: "5964 - Marketing directo: comerciante de catálogos", value: "5964" },
  {
    text: "5965 - Marketing directo: catálogo combinado y comerciante minorista",
    value: "5965",
  },
  {
    text: "5968 - Marketing directo: comerciante de continuidad / suscripción",
    value: "5968",
  },
  {
    text: "5969 - Marketing directo: otros comercializadores directos (no clasificados en otras categorías)",
    value: "5969",
  },
  {
    text: "5970 - Tiendas de artesanía y suministros para artistas",
    value: "5970",
  },
  { text: "5973 - Tiendas de artículos religiosos", value: "5973" },
  { text: "5975 - Audífonos: ventas, servicio y suministro", value: "5975" },
  {
    text: "5976 - Artículos ortopédicos: dispositivos protésicos",
    value: "5976",
  },
  { text: "5977 - Tiendas de cosméticos", value: "5977" },
  {
    text: "5978 - Tiendas de máquinas de escribir: ventas, alquileres y servicio",
    value: "5978",
  },
  {
    text: "5983 - Distribuidores de combustible: fueloil, madera, carbón y petróleo licuado",
    value: "5983",
  },
  { text: "5992 - Floristas", value: "5992" },
  { text: "5996 - Piscinas: venta y servicio", value: "5996" },
  {
    text: "5997 - Tiendas de maquinillas de afeitar eléctricas: ventas y servicio",
    value: "5997",
  },
  { text: "5998 - Tiendas de carpas y toldos", value: "5998" },
  {
    text: "6540 - Instituciones no financieras: compra / carga con tarjeta de valor almacenado",
    value: "6540",
  },
  { text: "7033 - Parques de remolques y campamentos", value: "7033" },
  { text: "7211 - Lavanderías - Familiares y Comerciales", value: "7211" },
  { text: "7216 - Tintorerías", value: "7216" },
  { text: "7217 - Limpieza de alfombras y tapizados", value: "7217" },
  { text: "7230 - Peluquerías y peluquerías", value: "7230" },
  {
    text: "7251 - Talleres de reparación de calzado, lustrabotas y tiendas de limpieza de sombreros",
    value: "7251",
  },
  { text: "7276 - Servicios de preparación de impuestos", value: "7276" },
  { text: "7278 - Compras y servicios y clubes de compras", value: "7278" },
  {
    text: "7296 - Alquiler de ropa: disfraces, uniformes, ropa formal",
    value: "7296",
  },
  { text: "7297 - Salones de masaje", value: "7297" },
  {
    text: "7321 - Agencias de informes crediticios del consumidor",
    value: "7321",
  },
  {
    text: "7338 - Servicios de copia rápida, reproducción y planos",
    value: "7338",
  },
  { text: "7339 - Apoyo taquigráfico y de secretaría", value: "7339" },
  {
    text: "7393 - Agencias de detectives, servicios de protección y servicios de seguridad, incluidos vehículos blindados y perros guardianes",
    value: "7393",
  },
  {
    text: "7395 - Laboratorios de fotoacabado y revelado fotográfico",
    value: "7395",
  },
  { text: "7512 - Agencia de alquiler de automóviles", value: "7512" },
  {
    text: "7513 - Alquiler de camiones y remolques utilitarios",
    value: "7513",
  },
  {
    text: "7519 - Alquiler de casas rodantes y vehículos recreativos",
    value: "7519",
  },
  { text: "7523 - Estacionamientos, parquímetros y garajes", value: "7523" },
  {
    text: "7531 - Talleres de reparación de carrocerías de automóviles",
    value: "7531",
  },
  { text: "7535 - Talleres de pintura automotriz .....", value: "7535" },
  { text: "7542 - Lavados de autos", value: "7542" },
  { text: "7549 - Servicios de remolque", value: "7549" },
  { text: "7641 - Mobiliario: tapicería, reparación y acabado", value: "7641" },
  { text: "7692 - Servicios de soldadura", value: "7692" },
  { text: "7911 - Salones de baile, estudios y escuelas", value: "7911" },
  {
    text: "7922 - Productores teatrales (excepto películas cinematográficas) y agencias de venta de entradas",
    value: "7922",
  },
  {
    text: "7929 - Bandas, orquestas y animadores diversos (no clasificados bajo otros epígrafes)",
    value: "7929",
  },
  { text: "7932 - Establecimientos de billar y piscina", value: "7932" },
  { text: "7933 - Bolos", value: "7933" },
  {
    text: "7941 - Deportes comerciales, clubes deportivos profesionales, campos deportivos y promotores deportivos",
    value: "7941",
  },
  { text: "7992 - Campos de golf públicos", value: "7992" },
  { text: "8031 - Osteópatas", value: "8031" },
  { text: "8041 - Quiroprácticos", value: "8041" },
  { text: "8042 - Optometristas y oftalmólogos", value: "8042" },
  { text: "8043 - Ópticas, artículos ópticos y anteojos", value: "8043" },
  { text: "8049 - Podólogos y podólogos", value: "8049" },
  { text: "8071 - Laboratorios médicos y dentales", value: "8071" },
  {
    text: "8099 - Servicios médicos y profesionales de la salud (no clasificados en otra parte)",
    value: "8099",
  },
  { text: "8211 - Escuelas primarias y secundarias", value: "8211" },
  { text: "8241 - Escuelas por correspondencia", value: "8241" },
  { text: "8249 - Escuelas vocacionales y comerciales", value: "8249" },
  { text: "8351 - Servicios de cuidado infantil", value: "8351" },
  {
    text: "8641 - Asociaciones cívicas, sociales y fraternales",
    value: "8641",
  },
  { text: "8675 - Asociaciones automovilísticas", value: "8675" },
  {
    text: "8734 - Laboratorios de pruebas (pruebas no médicas)",
    value: "8734",
  },
  {
    text: "8931 - Servicios de contabilidad, auditoría y teneduría de libros",
    value: "8931",
  },
  {
    text: "9211 - Costos judiciales, incluida la pensión alimenticia y la manutención infantil",
    value: "9211",
  },
  { text: "9222 - Propósitos", value: "9222" },
  { text: "9223 - Pagos de fianzas y fianzas", value: "9223" },
  { text: "9311 - Pago de impuestos", value: "9311" },
  { text: "9402 - Servicios postales: solo para el gobierno", value: "9402" },
  {
    text: "9405 - Agencias o departamentos del gobierno federal de EE. UU.",
    value: "9405",
  },
  {
    text: "9702 - Servicios de emergencia (GCAS) (solo para uso de Visa)",
    value: "9702",
  },
  { text: "9950 - Compras dentro de la empresa", value: "9950" },
];
