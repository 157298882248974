import { useEffect, useState } from "react";
import { Control, FieldValues, useFieldArray } from "react-hook-form";
import { useSelector } from "react-redux";
import { PersonType } from "../../../shared/constants/PersonTypeEnum";
import {
  DEFAULT_LEGAL_SHAREHOLDER_STATE,
  DEFAULT_NATURAL_SHAREHOLDER_STATE,
  SHAREHOLDER_KEY,
} from "../../../shared/constants/ShareholdersConstants";
import { IAppState } from "../../../store/reducer";
import { IUseShareholderFormState } from "./useShareholderFormState.interfaces";
import { fill, get } from "lodash";

export const useShareholderFormState = (
  control: Control<FieldValues, Object>,
  clearErrors: (name?: string | string[]) => void
): IUseShareholderFormState => {
  const { fields, append, update, remove, replace } = useFieldArray({
    control,
    keyName: "key",
    name: SHAREHOLDER_KEY,
  });

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [indexToDelete, setIndexToDelete] = useState<number | null>(null);
  const [formSummaryState, setFormSummaryState] = useState<boolean[]>([false]);

  const compliancePeople = useSelector(
    (store: IAppState) => store.compliancePeople
  );

  useEffect(() => {
    append(DEFAULT_NATURAL_SHAREHOLDER_STATE);
  }, []);

  useEffect(() => {
    const shareholders = get(compliancePeople, SHAREHOLDER_KEY, []);

    if (shareholders.length > 0) {
      setFormSummaryState(fill(Array(shareholders.length), true));
      replace(shareholders);
    }
  }, [compliancePeople]);

  const handleChangeForm = (index: number, personType: PersonType) => {
    clearErrors(`${SHAREHOLDER_KEY}.${index}`);
    update(
      index,
      personType === PersonType.NATURAL
        ? { ...DEFAULT_NATURAL_SHAREHOLDER_STATE }
        : { ...DEFAULT_LEGAL_SHAREHOLDER_STATE }
    );
  };

  const handleOpenDeleteModal = (indexToDelete: number) => {
    setIndexToDelete(indexToDelete);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setIndexToDelete(null);
    setOpenDeleteModal(false);
  };

  const handleAddForm = () => {
    const auxFormSummaryState = [...formSummaryState];

    auxFormSummaryState[fields.length - 1] = true;
    auxFormSummaryState.push(false);
    setFormSummaryState(auxFormSummaryState);

    append({ ...DEFAULT_NATURAL_SHAREHOLDER_STATE });
  };

  const handleDeleteShareholder = () => {
    const auxFormSummaryState = formSummaryState.filter(
      (_, i) => i !== indexToDelete
    );

    setFormSummaryState([...auxFormSummaryState]);
    indexToDelete !== null && remove(indexToDelete);
    setIndexToDelete(null);
    setOpenDeleteModal(false);
  };

  const handleClickEditButton = (index: number) => {
    const auxFormSummaryState = [...formSummaryState];

    auxFormSummaryState[index] = false;
    setFormSummaryState(auxFormSummaryState);
  };

  const handleSaveForm = (index: number) => {
    const auxFormSummaryState = [...formSummaryState];

    auxFormSummaryState[index] = true;
    setFormSummaryState(auxFormSummaryState);
  };

  return {
    fields,
    formSummaryState,
    handleAddForm,
    handleChangeForm,
    handleClickEditButton,
    handleCloseDeleteModal,
    handleDeleteShareholder,
    handleOpenDeleteModal,
    handleSaveForm,
    openDeleteModal,
  };
};
