import { Grid } from "@mui/material";
import React from "react";
import { useCreateRuleSectionState } from "./state/useCreateRuleSectionState";
import { PaymentTypeComponent } from "./PaymentTypeComponent/PaymentTypeComponent";
import { SubtypeComponent } from "./SubtypeComponent/SubtypeComponent";
import { CommerceTypeCountry } from "../CommerceTypeCountry/CommerceTypeCountry";

export const CreateRuleSection = () => {
  const {
    showCommerceType,
    setNodes: { setNodesChange },
    showControllerField,
    commerceType,
    setIsItemCountrySelected,
    setIsItemSelectedWithHierarchy,
    setIsItemSelectedWithoutHierarchy,
    subType,
  } = useCreateRuleSectionState();

  return (
    <React.Fragment>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <SubtypeComponent setNodesChange={setNodesChange} />
        </Grid>
        <CommerceTypeCountry
          showControllerField={showControllerField}
          subType={subType}
          setIsItemCountrySelected={setIsItemCountrySelected}
          commerceType={commerceType}
          showCommerceType={showCommerceType}
          setNodesChange={setNodesChange}
          setIsItemSelectedWithHierarchy={setIsItemSelectedWithHierarchy}
          setIsItemSelectedWithoutHierarchy={setIsItemSelectedWithoutHierarchy}
        />
        <Grid item xs={12}>
          <PaymentTypeComponent />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
