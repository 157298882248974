import React, { FC } from "react";
import { FieldsEnum } from "../../../shared/enums/FieldsEnum";
import { ErrorBillingData } from "../../../shared/enum/BasicStateMX/ErrorMessageMX";
import { TextField } from "@mui/material";
import { BillingDataFormLabelsEnum } from "../../../shared/enums/BillingDataFormLabelsEnum";
import { get } from "lodash";
import { Controller } from "react-hook-form";
import { IFormFieldProps } from "../FormFieldProps.interfaces";
import { textInputStyles as styles } from "../../FormControl/InputsForm.styles";
import { AddressInputRule } from "../../../shared/constants/billing_form_constants";

const AddressInputField: FC<IFormFieldProps> = ({
  control,
  errors,
  disabled,
}: IFormFieldProps) => {
  return (
    <Controller
      name={FieldsEnum.address}
      rules={{
        maxLength: AddressInputRule(),
        required: ErrorBillingData.ADDRESS,
      }}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          fullWidth
          id="input-address"
          data-testid="input-address"
          label={BillingDataFormLabelsEnum.address}
          error={!!get(errors, FieldsEnum.address)}
          sx={styles.error1}
          helperText={get(errors, FieldsEnum.address)?.message ?? ""}
          variant="outlined"
          disabled={disabled}
        />
      )}
    />
  );
};

export default AddressInputField;
