import { isEmpty, isEqual } from "lodash";
import React, { FC, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Conciliation } from "../../../types/get_conciliation_transactions_response";
import FilterComponent from "../FilterComponent/FilterComponent";
import { ColumnConfiguration } from "../ColumnConfiguration/ColumnConfiguration";
import { TableHeaderFilter } from "../TableHeaderFilter/TableHeaderFilter";

import {
  Columns,
  DefaultHistoricColumns,
  FixedHistoricColumns,
  HistoricColumns,
} from "../../shared/infrastructure/constants/ColumnConstants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { ARRAY_OPTIONS_DOWNLOAD } from "../../shared/constants/options_download";
import DownloadButton from "../common/DownloadButton/DownloadButton";
import useDownloadTransactions from "../../hooks/useDownloadTransactions/useDownloadTransactions";
import { SnackBarAlert } from "../common/Snackbar/SnackBarAlert";
import { EmptyList } from "../TransactionTable/EmptyList/EmptyList";
import { TransactionTableFooter } from "../TransactionTable/TransactionTableFooter/TransactionTableFooter";
import TransactionConciliationList from "../../containers/TransactionConciliationList/TransactionConciliationList";
import { usePendingConciliationTransactionsState } from "../PendingConciliationTransactions/state/usePendingConciliationTransactionsState";
import TransactionDetailModal from "../modals/SelectedTransactionModal/TransactionDetailModal";
import { ComponentIdsEnum } from "../../shared/constants/security_labels";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { conciliationTabsStyles } from "../ConciliationTabs/ConciliationTabs.styles";
import { COUNTRY_TO_CURRENCY } from "../../shared/infrastructure/enums/Currency";
import { formatNumber } from "../../shared/utils/amount";
import { useInformativeModal } from "../../hooks/useInformativeModal/useInformativeModal";
import { InformativeModalDownloads } from "../modals/InformativeModalDownloads/InformativeModalDownloads";
import { TabSectionEnum } from "../../shared/infrastructure/enums/tab_section_enum";
import { ModalTitleEnum } from "../../shared/infrastructure/enums/ModalTitleEnum";
import { FilterTitlesEnum } from "../../shared/infrastructure/enums/FilterTitlesEnum";
import { NoFiltersMessageEnum } from "../../shared/infrastructure/enums/NoFiltersMessageEnum";
import { NoRecordsMessageEnum } from "../../shared/infrastructure/enums/NoRecordsMessageEnum";
import { useAppSelector } from "../../store/hooks/hooks";
import {
  setAccumulatedAmount,
  setRowsSelected,
} from "../../store/actions/app/app";
import { TabsEnum } from "../../shared/enums/tabs_enum";

const HistoricalConciliationTransactions: FC = () => {
  const maxColumns = 7;
  const columns = [...Columns, ...HistoricColumns];
  const {
    actions: { handleDownloadTransactions },
    handleCloseSnackBar,
    message,
    openSnackBar,
    position,
    type,
  } = useDownloadTransactions("historic");
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    DefaultHistoricColumns
  );

  const {
    handlersInformation,
    openModalInformation,
    information,
    downloadType,
    setConciliationSelectModal,
    setConciliationListAux,
  } = useInformativeModal({ handleDownloadTransactions });

  const dispatch = useDispatch();

  let activeColumns = useSelector(
    (state: RootState) => state.app.activeColumns
  );
  let country = useAppSelector(
    (state: RootState) => state.filters.headerFilter.country
  );
  let paymentMethod = useAppSelector(
    (state: RootState) => state.filters.headerFilter.paymentMethod
  );
  let processorName = useAppSelector(
    (state: RootState) => state.filters.headerFilter.processorName
  );
  let accumulatedAmount = useSelector(
    (state: RootState) => state.app.accumulatedAmount
  );
  let rowsSelected = useSelector((state: RootState) => state.app.rowsSelected);
  let conciliationList: Conciliation[] = useSelector(
    (state: RootState) => state.app.conciliationList
  );

  const isFiltersSelected = () => {
    return (
      !isEqual(country, FilterTitlesEnum.country) &&
      !isEqual(paymentMethod, FilterTitlesEnum.paymentMethod) &&
      !isEqual(processorName, FilterTitlesEnum.processor)
    );
  };

  useEffect(() => {
    dispatch(setRowsSelected([]));
    dispatch(setAccumulatedAmount(0));
    setConciliationListAux([]);
  }, [country]);

  useEffect(() => {
    setSelectedColumns(activeColumns);
  }, [activeColumns]);

  const {
    dialogActions,
    handlers: { handleOpenCloseSelectedTransaction },
    transaction,
    openSelectedTransactionModal,
    setTransaction,
  } = usePendingConciliationTransactionsState();

  return (
    <>
      {!isFiltersSelected() ? (
        <Box>
          <EmptyList
            title={NoFiltersMessageEnum.TITLE}
            firstSubtitle={NoFiltersMessageEnum.FIRST_SUBTITLE}
            secondSubtitle={NoFiltersMessageEnum.SECOND_SUBTITLE}
          />
        </Box>
      ) : (
        <Box>
          <Grid container mb={4} spacing={2}>
            <Grid item xs={6}>
              <TableHeaderFilter />
            </Grid>
            <Grid item xs={2}>
              <FilterComponent currentTab={TabsEnum.HISTORIC} />
            </Grid>
            <Grid item xs={2}>
              <ColumnConfiguration
                fixedColumns={FixedHistoricColumns}
                columns={columns}
                maxColumns={maxColumns}
                starterColumns={DefaultHistoricColumns}
              />
            </Grid>
            <Grid item xs={2}>
              <ComponentSecurityWrapper
                componentId={
                  ComponentIdsEnum.M_BANK_CONCILIATION_TRANSACTIONAL_HISTORIC_DOWNLOAD
                }
              >
                <DownloadButton
                  options={ARRAY_OPTIONS_DOWNLOAD}
                  handleClick={handlersInformation.handleOpenCloseModal}
                />
              </ComponentSecurityWrapper>
            </Grid>
          </Grid>
          <Grid item xs={3} sx={conciliationTabsStyles.main}>
            {rowsSelected.length > 0 ? (
              <Typography sx={conciliationTabsStyles.multipleRefundText}>
                <span style={{ fontWeight: "bolder" }}>
                  {rowsSelected.length} Elemento(s) seleccionado(s)
                </span>{" "}
                <br />
                Monto total: {formatNumber(accumulatedAmount)}{" "}
                {COUNTRY_TO_CURRENCY[country]}
              </Typography>
            ) : (
              <Typography sx={conciliationTabsStyles.multipleRefundText}>
                <span style={{ fontWeight: "bolder" }}>
                  <br />
                </span>
                <br />
              </Typography>
            )}
          </Grid>
          {isEmpty(conciliationList) ? (
            <Box>
              <EmptyList
                title={NoRecordsMessageEnum.TITLE}
                firstSubtitle={NoRecordsMessageEnum.FIRST_SUBTITLE}
              />
            </Box>
          ) : (
            <Grid>
              <TransactionConciliationList
                country={country}
                dialogActions={dialogActions}
                setConciliationListAux={setConciliationListAux}
                setConciliationSelectModal={setConciliationSelectModal}
                selectedColumns={selectedColumns}
                setIsRefundButtonDisabled={() => {}}
                setIsTooltipDisabled={() => {}}
                setOpenSelectedTransactionModal={
                  handleOpenCloseSelectedTransaction
                }
                setSelectedRows={() => {}}
                setSelectedTransactionModal={setTransaction}
                setSlopesCounter={() => {}}
                currentTab={TabsEnum.HISTORIC}
              />
            </Grid>
          )}

          <TransactionTableFooter />
        </Box>
      )}
      <Grid hidden={true}>
        <FilterComponent currentTab={TabsEnum.HISTORIC} />
      </Grid>
      <TransactionDetailModal
        conciliationTransaction={transaction}
        isOpen={openSelectedTransactionModal}
        onCloseModal={handleOpenCloseSelectedTransaction}
      />
      <SnackBarAlert
        type={type}
        msg={message}
        position={position}
        open={openSnackBar}
        handlerClose={handleCloseSnackBar}
      />

      <InformativeModalDownloads
        openModal={openModalInformation}
        title={ModalTitleEnum.DOWNLOAD_MULTIPLE}
        handlers={handlersInformation}
        handleDownloadTransactions={handleDownloadTransactions}
        information={information}
        downloadType={downloadType}
        currentTab={TabSectionEnum.HISTORICAL_TAB}
        rowSelected={rowsSelected.length}
      />
    </>
  );
};

export default HistoricalConciliationTransactions;
