import { useEffect, useState } from "react";
import { get, isEqual, isNil } from "lodash";
import {
  setIsPubliclyExposed,
  setPersonType,
} from "../../../store/reducers/legalDetails/legalDetails";
import { IUseLegalDetailBody } from "./useLegalDetailBody.interfaces";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { PubliclyExposedEnum } from "../../../shared/enums/RadioButtonEnum";
import { EntityNameEnum } from "../../../shared/enum/EntityNameEnum";

export const useLegalDetailBodyState = (): IUseLegalDetailBody => {
  const {
    personType,
    legalRepresentative,
    merchantNodeInfo,
    openModal,
    disabledInputs,
    nodeInfo,
    boardMembers,
  } = useAppSelector((state) => ({
    ...state.legalDetails,
    ...state.initialData,
  }));
  const dispatch = useAppDispatch();
  const [radioButtonValue, setRadioButtonValue] = useState("");

  const [isCustomerMerchant, setIsCustomerMerchant] = useState(false);
  const handleChangeRadioButtonValue = (value: string) => {
    setRadioButtonValue(value);
    value === PubliclyExposedEnum.YES
      ? dispatch(setIsPubliclyExposed(true))
      : dispatch(setIsPubliclyExposed(false));
  };

  useEffect(() => {
    dispatch(setPersonType(get(merchantNodeInfo, "merchantType", "01")));
  }, [merchantNodeInfo]);
  useEffect(() => {
    const localData = get(legalRepresentative[0], "isPubliclyExposed", null);

    if (legalRepresentative) {
      if (localData === null) {
        setRadioButtonValue("");
      } else {
        const getTIn = get(legalRepresentative[0], "isPubliclyExposed", false)
          ? PubliclyExposedEnum.YES
          : PubliclyExposedEnum.NO;

        setRadioButtonValue(getTIn);
      }

      dispatch(
        setIsPubliclyExposed(isNil(localData) ? null : localData ? true : false)
      );
    }
  }, [legalRepresentative]);

  useEffect(() => {
    setIsCustomerMerchant(
      isEqual(get(nodeInfo, "entityName", ""), EntityNameEnum.CUSTOMER)
    );
  }, [nodeInfo]);

  return {
    boardMembers,
    disabledInputs,
    disableMoral: legalRepresentative.length >= 4,
    disablePhysic: legalRepresentative.length >= 1,
    handleChangeRadioButtonValue,
    isCustomerMerchant,
    isDisabledAddBoardMember: boardMembers.length >= 15,
    legalRepresentative,
    merchantNodeInfo,
    openModal,
    personType,
    radioButtonValue,
  };
};
