import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useAccountPreferences } from "./state/useAccountPreferencesSection";
import Edit from "../../assets/images/Edit";
import Button from "@material-ui/core/Button";
import { BasicInfoSkeleton } from "../../components/Skeletons/BasicInfoSkeleton/BasicInfoSkeleton";
import { routes } from "../../shared/infrastructure/constants/routes";

const useStyles = makeStyles(() => ({
  typography: {
    fontWeight: 500,
    paddingLeft: "0px",
    fontSize: "16px",
    fontStyle: "bold",
  },
  typographyItems: {
    fontWeight: 500,
    paddingLeft: "0px",
    fontSize: "16px",
    fontStyle: "bold",
    color: "#6D7781",
  },
  typographyMobile: {
    fontWeight: 500,
    padding: "8px",
    paddingLeft: "0px",
    fontSize: "16px",
    fontStyle: "bold",
  },
  descriptionLabel: {
    fontWeight: 200,
    fontSize: "14px",
    color: "#6D7781",
  },
  informationLabel: {
    fontWeight: 200,
    fontSize: "14px",
    color: "#293036",
  },
  copyIcon: {
    marginLeft: 10,
    padding: "0px",
    color: "#CBD5E0",
  },
  padding: {
    paddingBottom: "1px",
  },
  statusBadge: {
    padding: "4px 8px",
    borderRadius: "4px",
    width: 75,
    textAlign: "center",
    marginBottom: 5,
  },
  paper: {
    backgroundColor: "#F7FAFC",
    width: "100%",
  },
  statusLabel: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "100%",
  },
  editInfo: {
    padding: "0px",
    minWidth: "40px",
    backgroundColor: "#F0F4F9",
  },
  gridContainer: {
    paddingBottom: "0px",
  },
}));

//export interface IConfigHeaderProps extends IAppState {
export interface AccountPreferencesProps {
  title: string;
}

export const AccountPreferencesSection: React.FC<AccountPreferencesProps> = ({
  title,
}: AccountPreferencesProps) => {
  const classes = useStyles();

  const {
    publicMerchantId,
    loading,
    isMobile,
    email,
    colorPrimary,
    colorSecondary,
    phoneNumber,
  } = useAccountPreferences();

  return (
    <React.Fragment>
      <Grid container spacing={1} className={classes.padding}>
        {loading ? (
          <BasicInfoSkeleton />
        ) : (
          <Grid
            item
            xs={12}
            md={12}
            alignItems="center"
            justify="flex-start"
            direction="row"
          >
            <Box
              display={"flex"}
              alignContent={"center"}
              flexDirection={"row"}
              m={2}
            >
              <Typography
                className={
                  isMobile ? classes.typographyMobile : classes.typography
                }
                color={"primary"}
                variant={isMobile ? "h3" : "h1"}
              >
                {title}
              </Typography>
              <Button
                href={`${routes.PERSONALIZATION}/${publicMerchantId}`}
                className={classes.editInfo}
              >
                <Edit />
              </Button>
            </Box>
            <Box display={"flex"} m={2}>
              <Grid
                container
                spacing={0}
                xs={12}
                className={classes.typographyItems}
              >
                <Grid item xs={3}>
                  <Typography>Colores de Marca:</Typography>
                </Grid>
                <Grid item sm={1} xs={12}>
                  <Typography className={classes.typography}>
                    Principal:
                  </Typography>
                </Grid>
                <Grid item sm={1}>
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      background: colorPrimary,
                      position: "relative",
                    }}
                  />
                </Grid>
                <Grid item sm={2}>
                  <Typography className={classes.typography}>
                    {colorPrimary}
                  </Typography>
                </Grid>
                <Grid item sm={1} xs={12}>
                  <Typography className={classes.typography}>
                    Secundario:
                  </Typography>
                </Grid>
                <Grid item sm={1}>
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      background: colorSecondary,
                      position: "relative",
                    }}
                  />
                </Grid>
                <Grid item sm={1} xs={6}>
                  <Typography className={classes.typography}>
                    {colorSecondary}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>Email:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>{email}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>Teléfono</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>{phoneNumber}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default AccountPreferencesSection;
