import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { reducer } from "./reducer";

export const store = configureStore({
  devTools: process.env.NEXT_PUBLIC_ENV_NAME !== "primary",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunk),
  reducer: {
    layout: reducer,
  },
});
