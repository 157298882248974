import React from "react";
import { DeferredSectionsConstants } from "../../shared/infrastructure/constants/DeferredConstants";
import { CardSection } from "../CardSection/CardSection";
import { BasicInformation } from "../BasicInformation/BasicInformation";
import { Grid } from "@material-ui/core";
import { DeferredSettings } from "../DeferredSettings/DeferredSettings";
import { useBrazilSection } from "./state/useBrazilSection";
import { DeferredScheduled } from "../DeferredScheduled/DeferredScheduled";
import { ButtonsSaveDeferred } from "../ButtonsSaveDeferred/ButtonsSaveDeferred";

export const BrazilSection = () => {
  const {
    handleSelectedMonth,
    handleDeleteSelectedMonth,
    handleChangeSwitch,
    handleRangeDate,
    handleCancel,
    handleSave,
    selectedMonths,
    processorName,
    rangeSchedule,
    stateSwitch,
    disableSaveButton,
  } = useBrazilSection();

  return (
    <React.Fragment>
      <Grid container spacing={4}>
        <CardSection
          sectionTitle={DeferredSectionsConstants.BASIC_INFORMATION_TITTLE}
          sectionDescription={
            DeferredSectionsConstants.BASIC_INFORMATION_DESCRIPTION
          }
          sectionComponent={<BasicInformation processorValue={processorName} />}
        />
        <CardSection
          sectionTitle={DeferredSectionsConstants.SETTINGS_TITTLE}
          sectionDescription={
            DeferredSectionsConstants.BASIC_INFORMATION_DESCRIPTION
          }
          sectionComponent={
            <DeferredSettings
              months={selectedMonths}
              handleSelectedMonth={handleSelectedMonth}
              handleDeleteSelectedMonth={handleDeleteSelectedMonth}
            />
          }
        />
        <CardSection
          sectionTitle={DeferredSectionsConstants.ADVANCED_SETTINGS_TITTLE}
          sectionDescription={
            DeferredSectionsConstants.ADVANCED_SETTINGS_DESCRIPTION
          }
          sectionComponent={
            <DeferredScheduled
              stateSwitch={stateSwitch}
              rangeSchedule={rangeSchedule}
              handleChangeSwitch={handleChangeSwitch}
              handleRangeDate={handleRangeDate}
            />
          }
        />
      </Grid>
      <ButtonsSaveDeferred
        handleSave={handleSave}
        handleCancel={handleCancel}
        disableSaveButton={disableSaveButton}
      />
    </React.Fragment>
  );
};
