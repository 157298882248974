import { CountryEnum } from "../CountryEnum";
import { CatalogCo } from "../catalog/catalog-co";
import { CatalogPe } from "../catalog/catalog-pe";
import { CatalogMe } from "../catalog/catalog-me";
import { CatalogEc } from "../catalog/catalog-ec";
import { CatalogCl } from "../catalog/catalog-cl";

export const COUNTRIES: { value: string; name: string; flag: string }[] = [
  {
    value: "Ecuador",
    name: "Ecuador",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/ecuadorFlag%402x.png",
  },
  {
    value: "Colombia",
    name: "Colombia",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/colombiaFlag%402x.png",
  },
  {
    value: "Peru",
    name: "Perú",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/peruFlag%402x.png",
  },
  {
    value: "Chile",
    name: "Chile",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/chileFlag%402x.png",
  },
  {
    value: "Mexico",
    name: "México",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/mexico%402x.png",
  },
  {
    value: "Brazil",
    name: "Brazil",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/brasilFlag%402x.png",
  },
  {
    value: "ElSalvador",
    name: "El Salvador",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/salvadorFlag%402x.png",
  },
  {
    value: "Panama",
    name: "Panamá",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/panamaFlag%402x.png",
  },
  {
    value: "CostaRica",
    name: "Costa Rica",
    flag:
      "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/costaRicaFlag%402x.png",
  },
];

export interface Category {
  name: string;
  value: string | number;

  [k: string]: any;
}

export const getCatalog = (country: string, catalog: string) => {
  switch (country) {
    case CountryEnum.colombia:
      return CatalogCo[catalog];
    case CountryEnum.peru:
      return CatalogPe[catalog];
    case CountryEnum.mexico:
      return CatalogMe[catalog];
    case CountryEnum.ecuador:
      return CatalogEc[catalog];
    case CountryEnum.chile:
      return CatalogCl[catalog];
    default:
      return [];
  }
};

export const openFileUri = (uri: string) => {
  if (!uri) return;
  let link = document.createElement("a");
  link.href = uri;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const HISTORIC_FORMAT_DATE: string = "dd MMM'.' yyyy";
