import { ItemCategoryItem, ItemCategoryProps } from "@kushki/connect-ui";
import { Filter } from "../../../types/search_rule_alarm_request";
import { FilterPlaceHolder, FilterTitle } from "@shared/enum/FilterLabelEnum";
import { get, isEmpty, omitBy } from "lodash";
import { format, parseISO } from "date-fns";
import {
  DateFormatEnum,
  defaultDate,
  parseMonthsToEN,
  parseMonthsToES,
} from "@shared/constants/parseMonths";
import { FilterKey } from "@shared/enum/FilterKeyEnum";
import { IFilterAlarm } from "../../../types/search_alarm_by_filter_request";
import { CountryEnum, CountryTitleEnum } from "@shared/enum/CountryEnum";

const translateFilterTitle = {
  [FilterTitle.Frequency]: "frequency",
  [FilterTitle.Status]: "status",
  [FilterTitle.Constitutional_Country]: "constitutionalCountry",
  [FilterPlaceHolder.Constitutional_Country]: "constitutionalCountry",
  [FilterTitle.Country]: "country",
  [FilterTitle.Category]: "category",
  [FilterTitle.Category_History]: "category",
  [FilterTitle.Created]: "createdAtBranch",
  [FilterTitle.Variables]: "variables",
  [FilterTitle.CountryAlarm]: "country",
};

const filterTitleMultiple = {
  [FilterTitle.Constitutional_Country]: true,
};

const getPropByFilter = (item: ItemCategoryProps): string | string[] => {
  const isMultipleFilter: boolean | undefined =
    filterTitleMultiple[get(item, "title", "")];

  if (isMultipleFilter) {
    return get(item, "items", []).map((itemCat) => itemCat.value);
  }

  return get(item, "items[0].value", "");
};

export const parseDate = (date: string): string => {
  if (isEmpty(date)) return "";

  const dateSplited: string[] = date.split("/");

  dateSplited[1] = parseMonthsToEN[dateSplited[1].toLowerCase()];

  return dateSplited.join("/");
};

export const buildFilterRequest = (items: ItemCategoryProps[]): Filter => {
  let filter: Filter = {};

  items.forEach((item: ItemCategoryProps) => {
    if (
      [FilterTitle.Created].includes(item.placeHolder as FilterTitle) &&
      !isEmpty(item.items)
    ) {
      const dateParsed: string = parseDate(get(item, "items[0].value", ""));

      item.items[0].value = format(new Date(dateParsed), "dd-LL-yyy");
    }

    filter = {
      ...filter,
      [translateFilterTitle[item.placeHolder]]: getPropByFilter(item),
    };
  });

  return filter;
};

export const deleteEmptyFilters = (filter: Filter): Filter => {
  return omitBy(filter, (value) => !value);
};

const convertToStartOfDayUtc = (date: Date): number => {
  const utcDate = new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      0,
      0,
      0,
      0
    )
  );

  return utcDate.getTime();
};

const convertToEndOfDayUtc = (date: Date): number => {
  date.setUTCHours(23, 59, 59, 59);

  return date.getTime();
};

export const formatDateToTimestamp = (
  date: string,
  isEndOfTheDay: boolean
): number => {
  const parsedDate = new Date(parseDate(date));

  if (isEndOfTheDay) return convertToEndOfDayUtc(parsedDate);

  return convertToStartOfDayUtc(parsedDate);
};

export const parseDateFromDataBase = (date: string) => {
  const dateSplited = format(parseISO(date), DateFormatEnum.dd_MMM_yyyy).split(
    "/"
  );

  dateSplited[1] = parseMonthsToES[dateSplited[1]];

  return dateSplited.join("/");
};

export const validateDate = (dateString: string) => {
  const dateFormatted = dateString.includes("-")
    ? parseDateFromDataBase(dateString)
    : dateString;

  const datesSplited = dateFormatted.split("/");

  return datesSplited.length >= 3 ? datesSplited.join("/") : defaultDate;
};

export const parseDateToSave = (date: string) => {
  const dateConverted: string = parseDate(date);

  return format(new Date(dateConverted), DateFormatEnum.yyyy_MM_dd);
};

export const deleteEmptyProperties = (filter: IFilterAlarm): IFilterAlarm => {
  for (let filterKey in filter) {
    if ([FilterKey.TO, FilterKey.FROM].includes(filterKey as FilterKey))
      continue;

    if (isEmpty(filter[filterKey])) delete filter[filterKey];
  }

  if (!filter.from || !filter.to) {
    delete filter.from;
    delete filter.to;
  }

  return filter;
};

export const containsOnlyNumbers = (input: string): boolean => {
  const numbersRegex = /^[0-9]+$/;

  return numbersRegex.test(input);
};

export const getValuesAlarmFilters = (
  filtersSelected: ItemCategoryProps[]
): Record<string, string[]> => {
  const values: Record<string, string[]> = {
    category: [],
    constitutionalCountry: [],
    country: [],
    customerId: [],
    merchantId: [],
    ownerId: [],
    status: [],
    variables: [],
  };

  filtersSelected.forEach((filter) => {
    filter.items.forEach((item) => {
      values[translateFilterTitle[filter.placeHolder]].push(item.value);
    });
  });

  return values;
};

export const buildCountriesFullName = (): ItemCategoryItem[] => {
  let countries: ItemCategoryItem[] = [];

  for (const key in CountryTitleEnum) {
    countries.push({
      label: CountryTitleEnum[key],
      selected: false,
      value: CountryEnum[key],
    });
  }

  return countries;
};
