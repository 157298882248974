/**
 * Catalog Peru
 * Catalog Peru
 */
import { Category } from "../../interfaces/category";
import { CatalogsEnum } from "../../enums/CatalogsEnum";
import { CurrencyEnum } from "../../enums/currencyEnum";

export const CatalogPe: Record<string, Category[]> = {
  [CatalogsEnum.Banks]: [
    {
      name: "Banco de Crédito del Perú",
      value: "0002",
    },
    {
      name: "Interbank",
      value: "0003",
    },
    {
      name: "Citibank del Perú S.A.",
      value: "0007",
    },
    {
      name: "Scotiabank Perú S.A.A.",
      value: "0009",
    },
    {
      name: "Banco BBVA",
      value: "0011",
    },
    {
      name: "Banco de la Nación",
      value: "0018",
    },
    {
      name: "Banco de Comercio",
      value: "0023",
    },
    {
      name: "Banco Financiero del Perú",
      value: "0035",
    },
    {
      name: "Banco Interamericano de Finanzas (BIF)",
      value: "0038",
    },
    {
      name: "Banco Falabella",
      value: "0053",
    },
    {
      name: "Banco Ripley",
      value: "0055",
    },
    {
      name: "Banco Santander Perú",
      value: "0056",
    },
    {
      name: "Banco Azteca",
      value: "0058",
    },
    {
      name: "Banco GNB Perú S.A.",
      value: "0054",
    },
    {
      name: "Caja Municipal de Ahorro y Crédito Trujillo",
      value: "0802",
    },
    {
      name: "Caja Municipal de Ahorro y Crédito Arequipa",
      value: "0803",
    },
    {
      name: "Caja Municipal de Ahorro y Crédito Sullana",
      value: "0805",
    },
    {
      name: "Caja Municipal de Ahorro y Crédito Huancayo",
      value: "0808",
    },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { name: "Corriente", value: "0" },
    { name: "Ahorros", value: "1" },
  ],
  [CatalogsEnum.Currency]: [
    {
      name: `${CurrencyEnum.PEN} - Soles`,
      value: CurrencyEnum.PEN,
    },
    {
      name: `${CurrencyEnum.USD} - Dólares`,
      value: CurrencyEnum.USD,
    },
  ],
};
