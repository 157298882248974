import { createAsyncThunk } from "@reduxjs/toolkit";
import { thunkPrefix } from "@shared/constants/thunkPrefixes";
import { AxiosResponse } from "axios";
import axios from "@shared/utils/axios-util";
import axiosUtil from "@shared/utils/axios-util";
import { API_ROUTES } from "@shared/constants/api_routes";
import { DownloadCommercialConfigResponse } from "../../../../types/download_commercial_config_response";
import { downloadBase64File } from "@shared/utils/fileUtils";
import { setNotification } from "@store/reducers/general/general.slice";
import { buildSnackbar } from "@shared/utils/snackBarUtils";
import { StatusSnackbarEnum } from "@shared/enum/StatusSnackbarEnum";
import { SnackBarMessages } from "@shared/enum/snackBarMessages";
import { DownloadExecutiveTemplateLabels } from "@shared/constants/labels/DownloadExecutiveTemplateLabels";
import { IDownloadTemplateExecutives } from "@store/thunks/executives/executives.thunk.interfaces";
import { UploadCommercialRequest } from "../../../../types/upload_commercial_request";
import { IValidateCommercialExecutiveRequest } from "../../../../types/validate_commercial_executives_request";

export const getExecutiveTemplate = createAsyncThunk(
  thunkPrefix.getFileExecutives,
  async () => {
    const response: AxiosResponse<DownloadCommercialConfigResponse> =
      await axios.get(API_ROUTES.DOWNLOAD_EXECUTIVES);

    return response.data;
  }
);

export const downloadExecutiveTemplate = createAsyncThunk<
  boolean,
  IDownloadTemplateExecutives
>(
  thunkPrefix.downloadFileExecutives,
  async (
    request: IDownloadTemplateExecutives,
    { rejectWithValue, dispatch }
  ) => {
    try {
      downloadBase64File(
        request.file,
        DownloadExecutiveTemplateLabels.FILE_NAME
      );

      return true;
    } catch (error) {
      dispatch(
        setNotification(
          buildSnackbar(
            StatusSnackbarEnum.DANGER,
            SnackBarMessages.ERROR_DOWNLOAD_FILES
          )
        )
      );

      return rejectWithValue(false);
    }
  }
);

export const validateCommercialExecutives = createAsyncThunk(
  thunkPrefix.validateCommercialExecutives,
  async (payload: IValidateCommercialExecutiveRequest) => {
    const response: AxiosResponse<boolean> = await axios.post(
      API_ROUTES.VALIDATE_EXECUTIVES,
      payload
    );

    return response.data;
  }
);

export const uploadCommercialExecutive = createAsyncThunk(
  thunkPrefix.uploadCommercialExecutive,
  async (payload: UploadCommercialRequest, { rejectWithValue, dispatch }) => {
    try {
      await axiosUtil.post(API_ROUTES.UPLOAD_COMMERCIAL_EXECUTIVE, payload);

      dispatch(
        setNotification(
          buildSnackbar(
            StatusSnackbarEnum.SUCCESS,
            SnackBarMessages.SUCCESS_UPLOAD_COMMERCIAL_EXECUTIVES
          )
        )
      );

      return true;
    } catch (error) {
      dispatch(
        setNotification(
          buildSnackbar(
            StatusSnackbarEnum.DANGER,
            SnackBarMessages.ERROR_UPLOAD_COMMERCIAL_EXECUTIVES
          )
        )
      );

      return rejectWithValue(false);
    }
  }
);
