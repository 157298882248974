import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import RepresentativeContainer from "./RepresentativeContainer/RepresentativeContainer";
import { MissingInfoAlert } from "../MissingInfoAlert/MissingInfoAlert";
import { ILegalRepresentative } from "../../store/interfaces/LegalDetailsState.interfaces";
import { get } from "lodash";
import {
  LegalRepresentativeEnum,
  TypeLegalRepresentative,
} from "../../shared/enums/LegalRepresentativeTypeEnum";

export interface LegalRepresentativesProps {
  items: ILegalRepresentative;
  generalCountry: string;
}

const LegalRepresentatives = (props: LegalRepresentativesProps) => {
  const { items, generalCountry } = props;
  const getTypePerson = (isMain: boolean): string => {
    return isMain
      ? LegalRepresentativeEnum.MAIN
      : LegalRepresentativeEnum.ALTERNATE;
  };

  return (
    <>
      <Grid item xs={12}>
        {!items && <MissingInfoAlert />}
        {items && (
          <Box key={items.id} mb={2}>
            <RepresentativeContainer
              type={getTypePerson(get(items, "isMain", false))}
              role={TypeLegalRepresentative.LEGAL}
              country={generalCountry}
              email={get(items, "email", "")}
              phone={get(items, "cellphoneNumber", "")}
              items={items}
            />
          </Box>
        )}
      </Grid>
    </>
  );
};

export default LegalRepresentatives;
