import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const inputNumberStyles = createNamedStyles({
  input: {
    flex: "1 1 100%",
    "& input[type=number]": {
      MozAppearance: "textfield",
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },
});
