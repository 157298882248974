export interface ICountry {
  value: string;
  name: string;
  flag: string;
}

export const COUNTRIES: ICountry[] = [
  { flag: "empty", name: "Afganistán", value: "Afganistan" },
  { flag: "empty", name: "Albania", value: "Albania" },
  { flag: "empty", name: "Alemania", value: "Alemania" },
  { flag: "empty", name: "Andorra", value: "Andorra" },
  { flag: "empty", name: "Angola", value: "Angola" },
  { flag: "empty", name: "Anguila", value: "Anguila" },
  { flag: "empty", name: "Antártida", value: "Antartida" },
  { flag: "empty", name: "Antigua y Barbuda", value: "AntiguayBarbuda" },
  { flag: "empty", name: "Arabia Saudita", value: "ArabiaSaudita" },
  { flag: "empty", name: "Argelia", value: "Argelia" },
  { flag: "empty", name: "Argentina", value: "Argentina" },
  { flag: "empty", name: "Armenia", value: "Armenia" },
  { flag: "empty", name: "Aruba", value: "Aruba" },
  { flag: "empty", name: "Australia", value: "Australia" },
  { flag: "empty", name: "Austria", value: "Austria" },
  { flag: "empty", name: "Azerbaiyán", value: "Azerbaiyan" },
  { flag: "empty", name: "Bélgica", value: "Belgica" },
  { flag: "empty", name: "Bahamas", value: "Bahamas" },
  { flag: "empty", name: "Bahrein", value: "Bahrein" },
  { flag: "empty", name: "Bangladesh", value: "Bangladesh" },
  { flag: "empty", name: "Barbados", value: "Barbados" },
  { flag: "empty", name: "Belice", value: "Belice" },
  { flag: "empty", name: "Benín", value: "Benín" },
  { flag: "empty", name: "Bhután", value: "Bhutan" },
  { flag: "empty", name: "Bielorrusia", value: "Bielorrusia" },
  { flag: "empty", name: "Birmania", value: "Birmania" },
  { flag: "empty", name: "Bolivia", value: "Bolivia" },
  { flag: "empty", name: "Bosnia y Herzegovina", value: "BosniayHerzegovina" },
  { flag: "empty", name: "Botsuana", value: "Botsuana" },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/brasilFlag%402x.png",
    name: "Brasil",
    value: "Brazil",
  },
  { flag: "empty", name: "Brunéi", value: "Brunei" },
  { flag: "empty", name: "Bulgaria", value: "Bulgaria" },
  { flag: "empty", name: "Burkina Faso", value: "BurkinaFaso" },
  { flag: "empty", name: "Burundi", value: "Burundi" },
  { flag: "empty", name: "Cabo Verde", value: "CaboVerde" },
  { flag: "empty", name: "Camboya", value: "Camboya" },
  { flag: "empty", name: "Camerún", value: "Camerún" },
  { flag: "empty", name: "Canadá", value: "Canada" },
  { flag: "empty", name: "Chad", value: "Chad" },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/chileFlag%402x.png",
    name: "Chile",
    value: "Chile",
  },
  { flag: "empty", name: "China", value: "China" },
  { flag: "empty", name: "Chipre", value: "Chipre" },
  { flag: "empty", name: "Ciudad del Vaticano", value: "CiudaddelVaticano" },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/colombiaFlag%402x.png",
    name: "Colombia",
    value: "Colombia",
  },
  { flag: "empty", name: "Comoras", value: "Comoras" },
  { flag: "empty", name: "República del Congo", value: "RepublicadelCongo" },
  {
    flag: "empty",
    name: "República Democrática del Congo",
    value: "RepublicaDemocraticadelCongo",
  },
  { flag: "empty", name: "Corea del Norte", value: "CoreadelNorte" },
  { flag: "empty", name: "Corea del Sur", value: "CoreadelSur" },
  { flag: "empty", name: "Costa de Marfil", value: "CostadeMarfil" },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/costaRicaFlag%402x.png",
    name: "Costa Rica",
    value: "CostaRica",
  },
  { flag: "empty", name: "Croacia", value: "Croacia" },
  { flag: "empty", name: "Cuba", value: "Cuba" },
  { flag: "empty", name: "Curazao", value: "Curazao" },
  { flag: "empty", name: "Dinamarca", value: "Dinamarca" },
  { flag: "empty", name: "Dominica", value: "Dominica" },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/ecuadorFlag%402x.png",
    name: "Ecuador",
    value: "Ecuador",
  },
  { flag: "empty", name: "Egipto", value: "Egipto" },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/salvadorFlag%402x.png",
    name: "El Salvador",
    value: "ElSalvador",
  },
  {
    flag: "empty",
    name: "Emiratos Árabes Unidos",
    value: "EmiratosArabesUnidos",
  },
  { flag: "empty", name: "Eritrea", value: "Eritrea" },
  { flag: "empty", name: "Eslovaquia", value: "Eslovaquia" },
  { flag: "empty", name: "Eslovenia", value: "Eslovenia" },
  { flag: "empty", name: "España", value: "Espania" },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/usaFlag%402x.png",
    name: "Estados Unidos de América",
    value: "EEUU",
  },
  { flag: "empty", name: "Estonia", value: "Estonia" },
  { flag: "empty", name: "Etiopía", value: "Etiopia" },
  { flag: "empty", name: "Filipinas", value: "Filipinas" },
  { flag: "empty", name: "Finlandia", value: "Finlandia" },
  { flag: "empty", name: "Fiyi", value: "Fiyi" },
  { flag: "empty", name: "Francia", value: "Francia" },
  { flag: "empty", name: "Gabón", value: "Gabon" },
  { flag: "empty", name: "Gambia", value: "Gambia" },
  { flag: "empty", name: "Georgia", value: "Georgia" },
  { flag: "empty", name: "Ghana", value: "Ghana" },
  { flag: "empty", name: "Gibraltar", value: "Gibraltar" },
  { flag: "empty", name: "Granada", value: "Granada" },
  { flag: "empty", name: "Grecia", value: "Grecia" },
  { flag: "empty", name: "Groenlandia", value: "Groenlandia" },
  { flag: "empty", name: "Guadalupe", value: "Guadalupe" },
  { flag: "empty", name: "Guam", value: "Guam" },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/guatemalaFlag%402x.png",
    name: "Guatemala",
    value: "Guatemala",
  },
  { flag: "empty", name: "Guayana Francesa", value: "GuayanaFrancesa" },
  { flag: "empty", name: "Guernsey", value: "Guernsey" },
  { flag: "empty", name: "Guinea", value: "Guinea" },
  { flag: "empty", name: "Guinea Ecuatorial", value: "GuineaEcuatorial" },
  { flag: "empty", name: "Guinea-Bissau", value: "GuineaBissau" },
  { flag: "empty", name: "Guyana", value: "Guyana" },
  { flag: "empty", name: "Haití", value: "Haiti" },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/hondurasFlag%402x.png",
    name: "Honduras",
    value: "Honduras",
  },
  { flag: "empty", name: "Hong kong", value: "Hongkong" },
  { flag: "empty", name: "Hungría", value: "Hungria" },
  { flag: "empty", name: "India", value: "India" },
  { flag: "empty", name: "Indonesia", value: "Indonesia" },
  { flag: "empty", name: "Irán", value: "Iran" },
  { flag: "empty", name: "Irak", value: "Irak" },
  { flag: "empty", name: "Irlanda", value: "Irlanda" },
  { flag: "empty", name: "Isla Bouvet", value: "IslaBouvet" },
  { flag: "empty", name: "Isla de Man", value: "IsladeMan" },
  { flag: "empty", name: "Isla de Navidad", value: "IsladeNavidad" },
  { flag: "empty", name: "Isla Norfolk", value: "IslaNorfolk" },
  { flag: "empty", name: "Islandia", value: "Islandia" },
  { flag: "empty", name: "Islas Bermudas", value: "IslasBermudas" },
  { flag: "empty", name: "Islas Caimán", value: "IslasCaiman" },
  { flag: "empty", name: "Islas Cocos (Keeling)", value: "IslasCocos" },
  { flag: "empty", name: "Islas Cook", value: "IslasCook" },
  { flag: "empty", name: "Islas de Åland", value: "IslasdeAland" },
  { flag: "empty", name: "Islas Feroe", value: "IslasFeroe" },
  {
    flag: "empty",
    name: "Islas Georgias del Sur y Sandwich del Sur",
    value: "IslasGeorgiasdelSurySandwichdelSur",
  },
  {
    flag: "empty",
    name: "Islas Heard y McDonald",
    value: "IslasHeardyMcDonald",
  },
  { flag: "empty", name: "Islas Maldivas", value: "IslasMaldivas" },
  { flag: "empty", name: "Islas Malvinas", value: "IslasMalvinas" },
  {
    flag: "empty",
    name: "Islas Marianas del Norte",
    value: "IslasMarianasdelNorte",
  },
  { flag: "empty", name: "Islas Marshall", value: "IslasMarshall" },
  { flag: "empty", name: "Islas Pitcairn", value: "IslasPitcairn" },
  { flag: "empty", name: "Islas Salomón", value: "IslasSalomon" },
  { flag: "empty", name: "Islas Turcas y Caicos", value: "IslasTurcasyCaicos" },
  {
    flag: "empty",
    name: "Islas Ultramarinas Menores de Estados Unidos",
    value: "IslasUltramarinasMenoresdeEstadosUnidos",
  },
  {
    flag: "empty",
    name: "Islas Vírgenes Británicas",
    value: "IslasVirgenesBritanicas",
  },
  {
    flag: "empty",
    name: "Islas Vírgenes de los Estados Unidos",
    value: "IslasVirgenesdelosEstadosUnidos",
  },
  { flag: "empty", name: "Israel", value: "Israel" },
  { flag: "empty", name: "Italia", value: "Italia" },
  { flag: "empty", name: "Jamaica", value: "Jamaica" },
  { flag: "empty", name: "Japón", value: "Japon" },
  { flag: "empty", name: "Jersey", value: "Jersey" },
  { flag: "empty", name: "Jordania", value: "Jordania" },
  { flag: "empty", name: "Kazajistán", value: "Kazajistan" },
  { flag: "empty", name: "Kenia", value: "Kenia" },
  { flag: "empty", name: "Kirguistán", value: "Kirguistan" },
  { flag: "empty", name: "Kiribati", value: "Kiribati" },
  { flag: "empty", name: "Kuwait", value: "Kuwait" },
  { flag: "empty", name: "Líbano", value: "Libano" },
  { flag: "empty", name: "Laos", value: "Laos" },
  { flag: "empty", name: "Lesoto", value: "Lesoto" },
  { flag: "empty", name: "Letonia", value: "Letonia" },
  { flag: "empty", name: "Liberia", value: "Liberia" },
  { flag: "empty", name: "Libia", value: "Libia" },
  { flag: "empty", name: "Liechtenstein", value: "Liechtenstein" },
  { flag: "empty", name: "Lituania", value: "Lituania" },
  { flag: "empty", name: "Luxemburgo", value: "Luxemburgo" },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/mexico%402x.png",
    name: "México",
    value: "Mexico",
  },
  { flag: "empty", name: "Mónaco", value: "Monaco" },
  { flag: "empty", name: "Macao", value: "Macao" },
  { flag: "empty", name: "Macedônia", value: "Macedonia" },
  { flag: "empty", name: "Madagascar", value: "Madagascar" },
  { flag: "empty", name: "Malasia", value: "Malasia" },
  { flag: "empty", name: "Malawi", value: "Malawi" },
  { flag: "empty", name: "Mali", value: "Mali" },
  { flag: "empty", name: "Malta", value: "Malta" },
  { flag: "empty", name: "Marruecos", value: "Marruecos" },
  { flag: "empty", name: "Martinica", value: "Martinica" },
  { flag: "empty", name: "Mauricio", value: "Mauricio" },
  { flag: "empty", name: "Mauritania", value: "Mauritania" },
  { flag: "empty", name: "Mayotte", value: "Mayotte" },
  { flag: "empty", name: "Micronesia", value: "Micronesia" },
  { flag: "empty", name: "Moldavia", value: "Moldavia" },
  { flag: "empty", name: "Mongolia", value: "Mongolia" },
  { flag: "empty", name: "Montenegro", value: "Montenegro" },
  { flag: "empty", name: "Montserrat", value: "Montserrat" },
  { flag: "empty", name: "Mozambique", value: "Mozambique" },
  { flag: "empty", name: "Namibia", value: "Namibia" },
  { flag: "empty", name: "Nauru", value: "Nauru" },
  { flag: "empty", name: "Nepal", value: "Nepal" },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/nicaraguaFlag%402x.png",
    name: "Nicaragua",
    value: "Nicaragua",
  },
  { flag: "empty", name: "Niger", value: "Niger" },
  { flag: "empty", name: "Nigeria", value: "Nigeria" },
  { flag: "empty", name: "Niue", value: "Niue" },
  { flag: "empty", name: "Noruega", value: "Noruega" },
  { flag: "empty", name: "Nueva Caledonia", value: "NuevaCaledonia" },
  { flag: "empty", name: "Nueva Zelanda", value: "NuevaZelanda" },
  { flag: "empty", name: "Omán", value: "Oman" },
  { flag: "empty", name: "Países Bajos", value: "PaisesBajos" },
  { flag: "empty", name: "Pakistán", value: "Pakistan" },
  { flag: "empty", name: "Palau", value: "Palau" },
  { flag: "empty", name: "Palestina", value: "Palestina" },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/panamaFlag%402x.png",
    name: "Panamá",
    value: "Panama",
  },
  { flag: "empty", name: "Papúa Nueva Guinea", value: "PapuaNuevaGuinea" },
  { flag: "empty", name: "Paraguay", value: "Paraguay" },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/peruFlag%402x.png",
    name: "Perú",
    value: "Peru",
  },
  { flag: "empty", name: "Polinesia Francesa", value: "PolinesiaFrancesa" },
  { flag: "empty", name: "Polonia", value: "Polonia" },
  { flag: "empty", name: "Portugal", value: "Portugal" },
  { flag: "empty", name: "Puerto Rico", value: "PuertoRico" },
  { flag: "empty", name: "Qatar", value: "Qatar" },
  { flag: "empty", name: "Reino Unido", value: "ReinoUnido" },
  {
    flag: "empty",
    name: "República Centroafricana",
    value: "RepublicaCentroafricana",
  },
  { flag: "empty", name: "República Checa", value: "RepublicaCheca" },
  { flag: "empty", name: "República Dominicana", value: "RepublicaDominicana" },
  {
    flag: "empty",
    name: "República de Sudán del Sur",
    value: "RepublicadeSudandelSur",
  },
  { flag: "empty", name: "Reunión", value: "Reunion" },
  { flag: "empty", name: "Ruanda", value: "Ruanda" },
  { flag: "empty", name: "Rumanía", value: "Rumania" },
  { flag: "empty", name: "Rusia", value: "Rusia" },
  { flag: "empty", name: "Sahara Occidental", value: "SaharaOccidental" },
  { flag: "empty", name: "Samoa", value: "Samoa" },
  { flag: "empty", name: "Samoa Americana", value: "SamoaAmericana" },
  { flag: "empty", name: "San Bartolomé", value: "SanBartolome" },
  {
    flag: "empty",
    name: "San Cristóbal y Nieves",
    value: "SanCristobalyNieves",
  },
  { flag: "empty", name: "San Marino", value: "SanMarino" },
  { flag: "empty", name: "San Martín (Francia)", value: "SanMartinFrancia" },
  { flag: "empty", name: "San Pedro y Miquelón", value: "SanPedroyMiquelon" },
  {
    flag: "empty",
    name: "San Vicente y las Granadinas",
    value: "SanVicenteylasGranadinas",
  },
  { flag: "empty", name: "Santa Elena", value: "SantaElena" },
  { flag: "empty", name: "Santa Lucía", value: "SantaLucia" },
  { flag: "empty", name: "Santo Tomé y Príncipe", value: "SantoTomeyPrincipe" },
  { flag: "empty", name: "Senegal", value: "Senegal" },
  { flag: "empty", name: "Serbia", value: "Serbia" },
  { flag: "empty", name: "Seychelles", value: "Seychelles" },
  { flag: "empty", name: "Sierra Leona", value: "SierraLeona" },
  { flag: "empty", name: "Singapur", value: "Singapur" },
  { flag: "empty", name: "Sint Maarten", value: "SintMaarten" },
  { flag: "empty", name: "Siria", value: "Siria" },
  { flag: "empty", name: "Somalia", value: "Somalia" },
  { flag: "empty", name: "Sri lanka", value: "Srilanka" },
  { flag: "empty", name: "Sudáfrica", value: "Sudafrica" },
  { flag: "empty", name: "Sudán", value: "Sudan" },
  { flag: "empty", name: "Suecia", value: "Suecia" },
  { flag: "empty", name: "Suiza", value: "Suiza" },
  { flag: "empty", name: "Surinám", value: "Surinam" },
  { flag: "empty", name: "Svalbard y Jan Mayen", value: "SvalbardyJan Mayen" },
  { flag: "empty", name: "Swazilandia", value: "Swazilandia" },
  { flag: "empty", name: "Tayikistán", value: "Tayikistan" },
  { flag: "empty", name: "Tailandia", value: "Tailandia" },
  { flag: "empty", name: "Taiwán", value: "Taiwan" },
  { flag: "empty", name: "Tanzania", value: "Tanzania" },
  {
    flag: "empty",
    name: "Territorio Británico del Océano Índico",
    value: "TerritorioBritanicodelOceanoIndico",
  },
  {
    flag: "empty",
    name: "Territorios Australes y Antárticas Franceses",
    value: "TerritoriosAustralesyAntarticasFranceses",
  },
  { flag: "empty", name: "Timor Oriental", value: "TimorOriental" },
  { flag: "empty", name: "Togo", value: "Togo" },
  { flag: "empty", name: "Tokelau", value: "Tokelau" },
  { flag: "empty", name: "Tonga", value: "Tonga" },
  { flag: "empty", name: "Trinidad y Tobago", value: "TrinidadyTobago" },
  { flag: "empty", name: "Tunez", value: "Tunez" },
  { flag: "empty", name: "Turkmenistán", value: "Turkmenistan" },
  { flag: "empty", name: "Turquía", value: "Turquia" },
  { flag: "empty", name: "Tuvalu", value: "Tuvalu" },
  { flag: "empty", name: "Ucrania", value: "Ucrania" },
  { flag: "empty", name: "Uganda", value: "Uganda" },
  { flag: "empty", name: "Uruguay", value: "Uruguay" },
  { flag: "empty", name: "Uzbekistán", value: "Uzbekistan" },
  { flag: "empty", name: "Vanuatu", value: "Vanuatu" },
  { flag: "empty", name: "Venezuela", value: "Venezuela" },
  { flag: "empty", name: "Vietnam", value: "Vietnam" },
  { flag: "empty", name: "Wallis y Futuna", value: "WallisyFutuna" },
  { flag: "empty", name: "Yemen", value: "Yemen" },
  { flag: "empty", name: "Yibuti", value: "Yibuti" },
  { flag: "empty", name: "Zambia", value: "Zambia" },
  { flag: "empty", name: "Zimbabue", value: "Zimbabue" },
];

export const getCountryName = (value: string): string => {
  return COUNTRIES.filter((country) => country.value === value).map(
    (country) => country.name
  )[0];
};
