export const MAX_NUMBER_SHAREHOLDER = 15;
export const MAX_NUMBER_LEVELS = 14;
export const DEFAULT_PERCENTAGE = 100;
export const LENGTH_FOR_INSERT_ROOT = 2;
export const ZERO_PERCENTAGE = 0;
export const SHAREHOLDER = "SHAREHOLDER";
export const SAVE_BUTTON_SH = "Guardar";
export const ADD_BUTTON_SH = "Agregar";
export const CLOSE_BUTTON_SH = "Cerrar";
export const EDIT_BUTTON_SH = "Editar";
export const PERSON_TYPE_LABEL = "Tipo de persona";
export const LEGAL_INFORMATION_LABEL = "Información personal";
export const LEGAL_TITLE = "Representantes legales";
export const SHAREHOLDER_TITLE = "Accionistas";
export const SHAREHOLDER_DESCRIPTION =
  "Agrega al menos un accionista para el customer.";
export const SHAREHOLDER_BUTTON_TEXT = "Agregar accionista";

export enum ShareholderFields {
  BIRTHDATE = "birthdate",
  COUNTRY = "country",
  DOCUMENT_NUMBER = "documentNumber",
  DOCUMENT_TYPE = "documentType",
  ID = "id",
  LAST_NAME = "lastName",
  NAME = "name",
  PARTICIPATION_PERCENTAGE = "participationPercentage",
  CONSTITUTION_DATE = "constitutionDate",
  MERCHANT_COUNTRY = "merchantCountry",
  RESIDENCY_COUNTRY = "residencyCountry",
  ORIGIN_COUNTRY = "originCountry",
  ADDRESS_COUNTRY = "addressCountry",
  SOCIAL_REASON = "socialReason",
  COMPANY_COUNTRY = "companyCountry",
  TAX_ID = "taxId",
  PERSON_CATEGORY = "personCategory",
  PARENT = "parent",
  CHILDREN = "children",
  LEVEL = "level",
  PERSON_TYPE = "personType",
  MERCHANT_ADDRESS = "merchantAdress",
  UID = "uid",
}
