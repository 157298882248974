import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppState } from "../../interfaces/AppState.interfaces";
import {
  getMerchantInfo,
  getMerchantsRequest,
  getNodeInfo,
  getPaymentReceipt,
  getRetrieveChildren,
  getRetrieveChildrenMongo,
  notifySelectCheckChipsChange,
  searchMerchant,
  searchOriginalTrx,
} from "../../thunks/app/app.thunks";
import {
  buildNotification,
  defaultErrorSnackbar,
} from "../../../shared/constants/snackbar";
import { NotificationTypeEnum } from "../../../shared/enums/SnackbarEnum";
import { get, has, isEmpty } from "lodash";
import { TransactionInfo } from "../../../../types/transactions_data";
import { MerchantForm } from "../../../../types/merchant_form";

export const initialState: IAppState = {
  clickCount: 1,
  isLoadingDownload: false,
  isLoadingMerchantInfo: false,
  isLoadingNodeInfo: false,
  isLoadingOriginalTrx: false,
  isLoadingTransaction: false,
  isMongo: false,
  isTransactionSearch: false,
  loading: false,
  loadingMerchants: false,
  loadingText: "",
  merchantInfo: {
    accountNumber: "",
    accountType: "0",
    address: "",
    bankId: "",
    chargeFrequency: "none",
    chargeMin: "",
    chargeMinAmount: 0,
    city: "",
    contactPerson: "",
    country: "",
    documentType: "0",
    email: "",
    invoiceFrequency: "none",
    name: "",
    phoneNumber: "",
    province: "",
    publicMerchantId: "",
    socialReason: "",
    taxId: "",
    webSite: "",
    zipCode: "",
  },
  merchants: {
    data: [],
    total: 0,
  },
  nodeInfo: {
    configCoreId: "",
  },
  originalTrx: {
    data: [],
  },
  receipt: undefined,
  retrieveChildren: [],
  selectCheckChipsChange: false,
  selectedBranchColumns: [],
  showAlert: false,
  transactionData: {
    data: [],
  },
  transactionFatherData: {
    data: [],
  },
  typeAlert: "success",
  valueTab: "1",
  watchFieldName: "created",
};

export const appSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getNodeInfo.pending, (state) => {
        state.isLoadingNodeInfo = true;
      })
      .addCase(getNodeInfo.fulfilled, (state, action) => {
        state.nodeInfo = action.payload;
        state.isLoadingNodeInfo = false;
      })
      .addCase(getNodeInfo.rejected, (state) => {
        state.isLoadingNodeInfo = false;
      });
    builder
      .addCase(searchMerchant.pending, (state) => {
        state.isLoadingTransaction = true;
      })
      .addCase(searchMerchant.fulfilled, (state, action) => {
        const data = get(action.payload, "data.data", []);

        if (data !== undefined && data.length > 0) {
          state.isMongo = has(data[0], "_source._id");
        }

        if (action.payload.isSecondSearch)
          state.transactionFatherData = action.payload.data;
        else state.transactionData = action.payload.data;
        state.isLoadingTransaction = false;
      })
      .addCase(searchMerchant.rejected, (state) => {
        state.isLoadingTransaction = false;
      });
    builder
      .addCase(getMerchantInfo.pending, (state) => {
        state.isLoadingMerchantInfo = true;
      })
      .addCase(getMerchantInfo.fulfilled, (state, action) => {
        state.merchantInfo = action.payload;
        state.isLoadingMerchantInfo = false;
      })
      .addCase(getMerchantInfo.rejected, (state) => {
        state.isLoadingMerchantInfo = false;
      });
    builder
      .addCase(getPaymentReceipt.fulfilled, (state, action) => {
        state.receipt = get(action, "payload", "").toString();
      })
      .addCase(getPaymentReceipt.rejected, (state) => {
        state.notification = buildNotification(
          NotificationTypeEnum.FAILED,
          get(state, "notification", defaultErrorSnackbar)
        );
      });
    builder
      .addCase(getMerchantsRequest.pending, (state) => {
        state.loadingMerchants = true;
      })
      .addCase(getMerchantsRequest.fulfilled, (state, action) => {
        const merchantsWithName: MerchantForm[] = action.payload.data.filter(
          (res) => !isEmpty(res.name)
        );

        state.merchants.data = merchantsWithName;
        state.merchants.total = merchantsWithName.length;
        state.loadingMerchants = false;
      })
      .addCase(getMerchantsRequest.rejected, (state) => {
        state.loadingMerchants = false;
      });
    builder
      .addCase(getRetrieveChildren.pending, (state) => {
        state.isLoadingTransaction = true;
      })
      .addCase(getRetrieveChildren.fulfilled, (state, action) => {
        if (action.payload.isTransactionSearch)
          state.isLoadingTransaction = false;

        state.isTransactionSearch = action.payload.isTransactionSearch;
        state.retrieveChildren = action.payload.data;
      })
      .addCase(getRetrieveChildren.rejected, (state) => {
        state.isLoadingTransaction = false;
      });
    builder
      .addCase(getRetrieveChildrenMongo.pending, (state) => {
        state.isLoadingTransaction = true;
      })
      .addCase(getRetrieveChildrenMongo.fulfilled, (state, action) => {
        if (action.payload.isTransactionSearch)
          state.isLoadingTransaction = false;

        state.isTransactionSearch = action.payload.isTransactionSearch;
        state.retrieveChildren = action.payload.data;
      })
      .addCase(getRetrieveChildrenMongo.rejected, (state) => {
        state.isLoadingTransaction = false;
      });
    builder
      .addCase(searchOriginalTrx.pending, (state) => {
        state.isLoadingOriginalTrx = true;
      })
      .addCase(searchOriginalTrx.fulfilled, (state, action) => {
        state.isLoadingOriginalTrx = false;
        state.originalTrx = action.payload;
      })
      .addCase(searchOriginalTrx.rejected, (state) => {
        state.isLoadingOriginalTrx = false;
      });
    builder.addCase(notifySelectCheckChipsChange.fulfilled, (state, action) => {
      state.selectCheckChipsChange = action.payload;
    });
  },
  initialState,
  name: "app",
  reducers: {
    deleteBranchColumn: (state, { payload }: PayloadAction<number>) => {
      state.selectedBranchColumns.splice(payload, 1);
    },
    resetTransactionList: (state) => {
      state.transactionData = {
        data: [],
        total: 0,
      };
    },
    setAllBranchColumn: (
      state,
      { payload }: PayloadAction<TransactionInfo[]>
    ) => {
      if (isEmpty(payload) || isEmpty(state.selectedBranchColumns))
        state.selectedBranchColumns = payload;
      else
        state.selectedBranchColumns = [
          ...payload,
          ...state.selectedBranchColumns,
        ];
    },
    setBranchColumn: (state, { payload }: PayloadAction<TransactionInfo>) => {
      state.selectedBranchColumns.push(payload);
    },
    setClickCount: (state, { payload }: PayloadAction<number>) => {
      state.clickCount = payload;
    },
    setIsLoadingDownload: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingDownload = payload;
    },
    setWatchFieldName: (state, { payload }: PayloadAction<string>) => {
      state.watchFieldName = payload;
    },
  },
});

export default appSlice.reducer;
