import {
  Accordion,
  AccordionSummary,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import {
  Deferred,
  EcuadorPeruMerchantDeferredResponse,
} from "../../../types/merchant_deferred_response";
import { find, get } from "lodash";
import { CARD_BRAND_INFO } from "../../shared/constants/CardBrandInfo";
import { DEFERRED_TYPE } from "../../shared/constants/DeferredType";
import { ecuadorDeferredInfoStyles as styles } from "./EcuadorDeferredInfo.styles";
import BankListChip from "../BankListChip/BankListChip";
import { validateIcon } from "../../shared/utils/utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ICountryDeferredInfo } from "./EcuadorDeferredInfo.interfaces";
import { DEFERRED_LABELS } from "../../shared/constants/labels/deferred_info_labels";

const EcuadorDeferredInfo = (props: ICountryDeferredInfo) => {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body2" data-testid={"ec-def-info"}>
            {DEFERRED_LABELS.EC_INFO}
          </Typography>
        </Grid>
        {props.deferredInfo.map(
          (data: EcuadorPeruMerchantDeferredResponse, index: number) => (
            <Grid item xs={12} key={index}>
              <Accordion style={{ border: 12 }}>
                <AccordionSummary
                  aria-controls="panel2bh-content"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Grid container sx={styles.mainGrid}>
                    {validateIcon(data.brand)}
                    <Grid item display={"contents"}>
                      <Typography variant="h5" color={"primary"}>
                        {get(
                          CARD_BRAND_INFO,
                          `${data?.brand?.toLowerCase()}.name`,
                          ""
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionSummary>
                  <Grid container spacing={2}>
                    {data.deferred.map(
                      (deferredInfo: Deferred, index: number) => (
                        <Grid item xs={12} key={index}>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Grid
                                container
                                alignItems={"center"}
                                spacing={1}
                                direction={"row"}
                              >
                                {deferredInfo.months.map(
                                  (value: string, index: number) => (
                                    <Grid item key={index}>
                                      <Paper
                                        variant="outlined"
                                        sx={styles.paperMonth}
                                      >
                                        <Typography
                                          variant="body2"
                                          align={"center"}
                                        >
                                          {value}
                                        </Typography>
                                      </Paper>
                                    </Grid>
                                  )
                                )}
                                <Grid item key={index}>
                                  <Typography variant="body2">
                                    {DEFERRED_LABELS.MONTHS}
                                    {get(
                                      find(DEFERRED_TYPE, [
                                        "value",
                                        deferredInfo.type,
                                      ]),
                                      "name",
                                      ""
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              {deferredInfo.monthsOfGrace.length === 0 ? (
                                <Grid
                                  container
                                  alignItems={"center"}
                                  spacing={1}
                                  direction={"row"}
                                >
                                  <Grid item>
                                    <Typography variant="body2">
                                      {DEFERRED_LABELS.NO_MONTHS_OF_GRACE}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              ) : (
                                <Grid
                                  container
                                  alignItems={"center"}
                                  spacing={1}
                                  direction={"row"}
                                >
                                  <Grid item>
                                    <Paper
                                      variant="outlined"
                                      sx={styles.paperMonth}
                                    >
                                      <Typography
                                        variant="body2"
                                        align={"center"}
                                      >
                                        {deferredInfo.monthsOfGrace}
                                      </Typography>
                                    </Paper>
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="body2">
                                      {DEFERRED_LABELS.MONTHS_OF_GRACE}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="body2" sx={styles.subtitle}>
                                {DEFERRED_LABELS.ISSUING_BANKS}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <BankListChip bankList={deferredInfo.banks} />
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    )}
                  </Grid>
                </AccordionSummary>
              </Accordion>
            </Grid>
          )
        )}
      </Grid>
    </>
  );
};

export default EcuadorDeferredInfo;
