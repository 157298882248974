import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "@kushki/connect-ui";
import { SvgIcon } from "@mui/material";

const FileTextIcon: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <SvgIcon
        width="36"
        height="37"
        viewBox="0 0 36 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        sx={{ fontSize: "36px !important" }}
      >
        <path
          d="M23.25 34.5449H12.75C11.1587 34.5449 9.63258 33.9128 8.50736 32.7876C7.38214 31.6623 6.75 30.1362 6.75 28.5449V9.04492C6.75 7.45362 7.38214 5.9275 8.50736 4.80228C9.63258 3.67706 11.1587 3.04492 12.75 3.04492H21.615C22.0128 3.04492 22.3944 3.20296 22.6757 3.48426C22.957 3.76557 23.115 4.1471 23.115 4.54492C23.115 4.94275 22.957 5.32428 22.6757 5.60558C22.3944 5.88689 22.0128 6.04492 21.615 6.04492H12.75C11.9544 6.04492 11.1913 6.36099 10.6287 6.9236C10.0661 7.48621 9.75 8.24927 9.75 9.04492V28.5449C9.75 29.3406 10.0661 30.1036 10.6287 30.6662C11.1913 31.2288 11.9544 31.5449 12.75 31.5449H23.25C24.0457 31.5449 24.8087 31.2288 25.3713 30.6662C25.9339 30.1036 26.25 29.3406 26.25 28.5449V11.9099C26.25 11.5121 26.408 11.1306 26.6893 10.8493C26.9706 10.568 27.3522 10.4099 27.75 10.4099C28.1478 10.4099 28.5294 10.568 28.8107 10.8493C29.092 11.1306 29.25 11.5121 29.25 11.9099V28.5449C29.25 30.1362 28.6179 31.6623 27.4926 32.7876C26.3674 33.9128 24.8413 34.5449 23.25 34.5449Z"
          fill="#B4B9BE"
        />
        <path
          d="M26.3852 15.0449H21.6152C21.2211 15.0276 20.8343 14.9327 20.477 14.7657C20.1196 14.5987 19.7986 14.3628 19.5325 14.0716C19.2664 13.7804 19.0603 13.4396 18.9261 13.0687C18.7919 12.6978 18.7321 12.304 18.7502 11.9099V6.17992C18.7321 5.78586 18.7919 5.3921 18.9261 5.02116C19.0603 4.65023 19.2664 4.30941 19.5325 4.01821C19.7986 3.72702 20.1196 3.49117 20.477 3.32416C20.8343 3.15715 21.2211 3.06226 21.6152 3.04492C21.8356 3.04457 22.0533 3.09277 22.253 3.1861C22.4526 3.27943 22.6292 3.4156 22.7702 3.58492L28.9052 10.9499C29.1289 11.2198 29.2509 11.5594 29.2502 11.9099C29.2684 12.304 29.2086 12.6978 29.0744 13.0687C28.9401 13.4396 28.7341 13.7804 28.4679 14.0716C28.2018 14.3628 27.8809 14.5987 27.5235 14.7657C27.1661 14.9327 26.7793 15.0276 26.3852 15.0449ZM21.7652 12.0449H25.9052L21.7502 7.04992V11.9099C21.7502 11.9099 21.7652 12.0149 21.7652 12.0449Z"
          fill="#B4B9BE"
        />
        <path
          d="M22.499 21.7949H13.499C13.1012 21.7949 12.7197 21.6369 12.4384 21.3556C12.1571 21.0743 11.999 20.6927 11.999 20.2949C11.999 19.8971 12.1571 19.5156 12.4384 19.2343C12.7197 18.953 13.1012 18.7949 13.499 18.7949H22.499C22.8968 18.7949 23.2784 18.953 23.5597 19.2343C23.841 19.5156 23.999 19.8971 23.999 20.2949C23.999 20.6927 23.841 21.0743 23.5597 21.3556C23.2784 21.6369 22.8968 21.7949 22.499 21.7949Z"
          fill="#B4B9BE"
        />
        <path
          d="M22.499 27.7949H13.499C13.1012 27.7949 12.7197 27.6369 12.4384 27.3556C12.1571 27.0743 11.999 26.6927 11.999 26.2949C11.999 25.8971 12.1571 25.5156 12.4384 25.2343C12.7197 24.953 13.1012 24.7949 13.499 24.7949H22.499C22.8968 24.7949 23.2784 24.953 23.5597 25.2343C23.841 25.5156 23.999 25.8971 23.999 26.2949C23.999 26.6927 23.841 27.0743 23.5597 27.3556C23.2784 27.6369 22.8968 27.7949 22.499 27.7949Z"
          fill="#B4B9BE"
        />
      </SvgIcon>
    </ThemeProvider>
  );
};

export default FileTextIcon;
