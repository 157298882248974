import React from "react";
import { defaultTo, isEmpty } from "lodash";
import { RegexValidationEnum } from "../shared/infrastructure/constants/RegexValidationEnum";
import { ProcessorFetch } from "../shared/infrastructure/interfaces/ProcessorFetch";
import { IDefaultProcessorForm } from "../shared/infrastructure/interfaces/IDefaultProcessorForm";

export const buildBoldText = (
  text: string,
  classes: Record<string, string>,
  className: string
): (string | React.ReactElement)[] =>
  defaultTo(text, "")
    .split(new RegExp(RegexValidationEnum.BOLD_TEXT_REGEX, "g"))
    .filter((text: string) => !isEmpty(text))
    .map((subText: string) =>
      subText.includes("|") ? (
        <b className={classes[className]}>{subText.replace("|", "")}</b>
      ) : (
        subText
      )
    );

const isProcessorValueInDefaultList = (
  value?: string,
  defaultProcessors?: IDefaultProcessorForm
): boolean => {
  return (defaultProcessors ? Object.values(defaultProcessors) : []).includes(
    value
  );
};

export const verifyIsDefaultProcessor = (
  processor: ProcessorFetch,
  defaultProcessors?: IDefaultProcessorForm,
  defaultPayoutsTransferProcessor?: IDefaultProcessorForm
): boolean => {
  return (
    isProcessorValueInDefaultList(processor.alias, defaultProcessors) ||
    isProcessorValueInDefaultList(
      processor.publicProcessorId,
      defaultPayoutsTransferProcessor
    )
  );
};
