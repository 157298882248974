export enum ActionTypes {
  SET_NOTIFICATION = "[RULE_REQUEST] SET_NOTIFICATION",
  SET_RULES_DATA_COUNTER = "[RULE_REQUEST] SET_RULES_DATA_COUNTER",
  SET_LOADING_AUTHENTICATION_RULES = "[RULE_REQUEST] SET_LOADING_AUTHENTICATION_RULES",
  SET_AUTHENTICATION_RULES_DATA = "[RULE_REQUEST] SET_AUTHENTICATION_RULES_DATA",
  SET_LOADING_UPDATE_AUTHENTICATION_RULES = "[RULE_REQUEST] SET_LOADING_UPDATE_AUTHENTICATION_RULES",
  SET_LOADING_AUTHENTICATION_RULES_APPROVAL = "[RULE_REQUEST] SET_LOADING_AUTHENTICATION_RULES_APPROVAL",
  SET_AUTHENTICATION_RULES_APPROVAL_DATA = "[RULE_REQUEST] SET_AUTHENTICATION_RULES_APPROVAL_DATA",
  SET_LOADING_AUTHENTICATION_RULES_REQUEST = "[RULE_REQUEST] SET_LOADING_AUTHENTICATION_RULES_REQUEST",
  SET_AUTHENTICATION_RULES_REQUEST_DATA = "[RULE_REQUEST] SET_AUTHENTICATION_RULES_REQUEST_DATA",
  SET_AUTHENTICATION_DATA = "[RULE_REQUEST] SET_AUTHENTICATION_DATA",
  SET_LOADING_ANALYST = "[RULE_REQUEST] SET_LOADING_ANALYST",
  SET_ANALYST_DATA = "[RULE_REQUEST] SET_ANALYST_DATA",
  SET_LOADING_PROCESSOR = "[RULE_REQUEST] SET_LOADING_PROCESSOR",
  SET_PROCESSOR_DATA = "[RULE_REQUEST] SET_PROCESSOR_DATA",
  SET_LOADING_CREDENTIAL = "[RULE_REQUEST] SET_LOADING_CREDENTIAL",
  SET_CREDENTIAL_DATA = "[RULE_REQUEST] SET_CREDENTIAL_DATA",
  SET_PATH_LOADING_PROCESS_RATES = "[RULE_REQUEST] SET_PATH_LOADING_PROCESS_RATES",
  SET_LOADING_AUTHENTICATION_RULES_HISTORIC = "[RULE_REQUEST] SET_LOADING_AUTHENTICATION_RULES_HISTORIC",
  SET_AUTHENTICATION_RULES_DATA_HISTORIC = "[RULE_REQUEST] SET_AUTHENTICATION_RULES_DATA_HISTORIC",
  SET_LOADING_DOWNLOAD_FILE = "[RULE_REQUEST] SET_LOADING_DOWNLOAD_FILE",
  SET_LOADING_DOWNLOAD_LIST_FILE = "[RULE_REQUEST] SET_LOADING_DOWNLOAD_LIST_FILE",
  SET_LOADING_RULES_REQUEST = "[RULE_REQUEST] SET_LOADING_RULES_REQUEST",
  SET_SNACKBAR_RULES_REQUEST = "[RULE_REQUEST] SET_SNACKBAR_RULES_REQUEST",
  SET_ANALYST_USER = "[RULE_REQUEST] SET_ANALYST_USER",
  SET_RELOAD_DASHBOARD_RULE_REQUEST = "[RULE_REQUEST] SET_RELOAD_DASHBOARD_RULE_REQUEST",
  SET_IS_LOADING_GET_PROCESSORS = "[RULES] SET_IS_LOADING_GET_PROCESSORS",
  SET_PROCESSORS = "[RULES] SET_PROCESSORS",
  SET_IS_REQUESTING = "[RULES] SET IS REQUESTING",
  SET_REQUEST_ERROR = "[RULES] SET REQUEST ERROR",
  SET_MERCHANT_CREDENTIALS = "[RULES] SET MERCHANT CREDENTIALS",
  SET_MERCHANTS = "[RULES] SET_MERCHANTS",
  SET_CURRENT_AUTHENTICATION_RULE = "[RULES] SET_CURRENT_authentication_RULE",
  SET_IS_LOADING_GET_RULE = "[RULES] SET_IS_LOADING_GET_RULE",
  SET_IS_LOADING_GET_MERCHANTS = "[RULES] SET_IS_LOADING_GET_MERCHANTS",
  SET_IS_LOADING = "[RULES] SET_IS_LOADING",
}
