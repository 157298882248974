import { CatalogData } from "../../../../types/catalog_data";

export const channelCatalog: CatalogData[] = [
  { name: "API", value: "api" },
  { name: "KUSHKIJS", value: "kushkijs" },
  { name: "ANDROID", value: "android" },
  { name: "IOS", value: "ios" },
  { name: "SMARTLINKS", value: "smartlinks" },
  { name: "KAJITA", value: "kajita" },
  { name: "CAJITA", value: "cajita" },
  { name: "VTEX", value: "vtex" },
  { name: "MAGENTO", value: "magento" },
  { name: "PRESTASHOP", value: "prestashop" },
  { name: "WOOCOMERCE", value: "woocomerce" },
  { name: "SHOPIFY", value: "shopify" },
  { name: "WEBCHECKOUT", value: "webcheckout" },
  { name: "EXPRESSWEBCHECKOUT", value: "expresswebcheckout" },
  // { name: "POS", value: "pos" },
  { name: "KUSHKI_JS_SDK", value: "kushki_js_sdk" },
];
