import React from "react";
import {
  Grid,
  Divider,
  Select,
  FormControl,
  TextField,
  FormHelperText,
  Box,
  IconButton,
  CircularProgress,
  Typography,
  Tooltip,
} from "@material-ui/core";
import {
  BANKS_COUNTRIES_MAP,
  BUSINESS_RULES_ACTIONS,
  BUSINESS_RULES_ROUTING,
  BUSINESS_RULES_WARNING_RULES,
  CREDIT_CARD_OPTIONS,
  CURRENCIES,
  PARTNER_VALIDATORS,
  PAYMENT_BRANDS_OPTIONS,
  SEARCH_PROCESSOR_PLACEHOLDER,
  STRICT_CONDITIONS,
  STRICT_CONDITIONS_BASIC,
  STRICT_CONDITIONS_CONTAINS,
  STRICT_CONDITIONS_CREDENTIALS,
  STRICT_CONDITIONS_EXTENDED,
  TRANSACTION_TYPES,
} from "../../shared/infrastructure/constants/BusinessRulesEnum";
import { Category } from "../../../types/category";
import { useTypedController } from "@hookform/strictly-typed";
import { IBusinessRulesForm } from "../../shared/infrastructure/interfaces/IBusinessRulesForm";
import ConnectForm from "../commons/ConnectForm/ConnectForm";
import { useWatch } from "react-hook-form";
import { get, defaultTo, isEmpty } from "lodash";
import { PlusCircle } from "react-feather";
import { MinusCircle } from "react-feather";
import { ErrorsEnum } from "../../shared/infrastructure/enums/ErrorEnum";
import {
  IOptionSelection,
  LabelField,
  TitleSection,
} from "../../shared/infrastructure/constants/RulesConstants";
import { LabelEnum } from "../../shared/infrastructure/enums/LabelEnum";
import {
  getNames,
  YES_NO_VALUES,
} from "../../shared/infrastructure/constants/SecurityRulesConstants";
import {
  getCountries,
  USA_COUNTRY_NAME,
} from "../../shared/infrastructure/constants/CountryConstant";
import AutocompleteFieldComponent from "./Inputs/AutocompleteFieldComponent";
import AutocompleteMultipleFieldComponent from "./Inputs/AutocompleteMultipleFieldComponent";
import SelectFieldComponent from "./Inputs/SelectFieldComponent";
import TextFieldComponent from "./Inputs/TextFieldComponent";
import { getValues } from "../../shared/utils";
import { AutocompleteTextFieldComponent } from "../AutocompleteTextField/AutocompleteTextFieldComponent";
import { ProcessorsInformation } from "../../../types/processors_list";

export interface BusinessRulesConfigurationProps {
  processors: ProcessorsInformation[];
  credentials: Category[];
  isRequest: boolean;
  handleAddRule: (
    rule: string,
    condition: string,
    value: string,
    rules: object
  ) => void;
  handleRemoveRule: (rule: string, rules: object) => void;
  country: string;
  handleEnterSearchTextProcessors?: (key: string) => void;
  handleSetSearchTextProcessors?: (text: string) => void;
}

const getRulesSelectValues = (action: string): Category[] => {
  switch (action) {
    case "warning":
      return BUSINESS_RULES_WARNING_RULES;
    case "route":
    default:
      return BUSINESS_RULES_ROUTING;
  }
};

const getRulesConditionsSelectValues = (rule: string): Category[] => {
  switch (rule) {
    case "transactionCredential":
      return STRICT_CONDITIONS_CREDENTIALS;
    case "transactionTotalAmount":
      return STRICT_CONDITIONS_EXTENDED;
    case "transactionCountry":
    case "transactionBIN":
    case "transactionIP":
      return STRICT_CONDITIONS_CONTAINS;
    case "transactionType":
    case "transactionIsCreditCard":
    case "transactionProcessor":
      return STRICT_CONDITIONS_BASIC;
    default:
      return STRICT_CONDITIONS;
  }
};

const convertMerchantProcessorsToCategory = (
  processors: ProcessorsInformation[]
): IOptionSelection[] => {
  const categoryValues: IOptionSelection[] = [];
  processors.forEach((item: ProcessorsInformation) => {
    categoryValues.push({
      name: get(
        item,
        "alias",
        get(item, "StoreInformation.CreditInfo.processorAlias", "")
      ),
      value: get(item, "processorName", ""),
    });
  });
  return categoryValues;
};

const filteredCountries: IOptionSelection[] = getCountries().filter(
  (country: IOptionSelection) => country.value !== USA_COUNTRY_NAME.US
);

const renderValuesField = (
  label: string,
  operator: string,
  disabled: boolean,
  processors: IOptionSelection[],
  country: string,
  credentials: IOptionSelection[]
): JSX.Element => {
  if (isEmpty(operator)) return <TextFieldComponent disabled={true} />;
  switch (label) {
    case LabelEnum.PROCESSOR:
      return (
        <AutocompleteFieldComponent
          disabled={disabled}
          items={getValues(processors)}
          options={processors}
          compare={"value"}
          path={"name"}
        />
      );
    case LabelEnum.CREDENTIAL:
      return (
        <AutocompleteFieldComponent
          disabled={disabled}
          items={getValues(credentials)}
          options={credentials}
          compare={"value"}
          path={"name"}
        />
      );
    case LabelEnum.DEFERRED:
      return <SelectFieldComponent disabled={disabled} items={YES_NO_VALUES} />;
    case LabelEnum.TRANSACTION_TYPE:
      return (
        <SelectFieldComponent disabled={disabled} items={TRANSACTION_TYPES} />
      );
    case LabelEnum.CARD_TYPE:
      return (
        <SelectFieldComponent disabled={disabled} items={CREDIT_CARD_OPTIONS} />
      );
    case LabelEnum.MASKED_CARD:
      return (
        <TextFieldComponent disabled={disabled} rules={{ maxLength: 16 }} />
      );
    case LabelEnum.IP:
    case LabelEnum.BIN:
      return <TextFieldComponent disabled={disabled} />;
    case LabelEnum.AMOUNT:
      return <TextFieldComponent disabled={disabled} type={"number"} />;
    case LabelEnum.COUNTRY:
      return (
        <AutocompleteMultipleFieldComponent
          disabled={disabled}
          items={getNames(filteredCountries)}
          options={filteredCountries}
          compare={"name"}
          path={"name"}
        />
      );
    case LabelEnum.BRAND:
      if (defaultTo(operator, "=").includes("="))
        return (
          <AutocompleteFieldComponent
            disabled={disabled}
            items={getValues(PAYMENT_BRANDS_OPTIONS)}
            options={PAYMENT_BRANDS_OPTIONS}
            compare={"value"}
            path={"name"}
          />
        );
      return (
        <AutocompleteMultipleFieldComponent
          disabled={disabled}
          items={getValues(PAYMENT_BRANDS_OPTIONS)}
          options={PAYMENT_BRANDS_OPTIONS}
          compare={"value"}
          path={"name"}
        />
      );
    case LabelEnum.BANK:
      if (defaultTo(operator, "=").includes("="))
        return (
          <AutocompleteFieldComponent
            disabled={disabled}
            items={getNames(BANKS_COUNTRIES_MAP[country])}
            options={BANKS_COUNTRIES_MAP[country]}
            compare={"name"}
            path={"name"}
          />
        );
      return (
        <AutocompleteMultipleFieldComponent
          disabled={disabled}
          items={getNames(BANKS_COUNTRIES_MAP[country])}
          options={BANKS_COUNTRIES_MAP[country]}
          compare={"name"}
          path={"name"}
        />
      );
    case LabelEnum.CURRENCY:
      if (defaultTo(operator, "=").includes("="))
        return (
          <AutocompleteFieldComponent
            disabled={disabled}
            items={getValues(CURRENCIES)}
            options={CURRENCIES}
            compare={"value"}
            path={"name"}
          />
        );
      return (
        <AutocompleteMultipleFieldComponent
          disabled={disabled}
          items={getNames(CURRENCIES)}
          options={CURRENCIES}
          compare={"name"}
          path={"name"}
        />
      );

    default:
      return <TextFieldComponent disabled={true} />;
  }
};

const splitString = (
  separator: string,
  value: string,
  position: number
): string => {
  const splitResult: string[] = value.split(separator);

  return splitResult[position];
};

export const BusinessRulesConfiguration: React.FC<BusinessRulesConfigurationProps> =
  (props: BusinessRulesConfigurationProps) => {
    return (
      <ConnectForm<IBusinessRulesForm>>
        {({ control, errors }) => {
          const TypedController = useTypedController<IBusinessRulesForm>({
            control: control,
          });
          const action: string = useWatch({
            name: "action",
          }) as string;
          const ruleSelectValue: string = useWatch({
            name: "ruleSelectValue",
          }) as string;
          const ruleSelectConditionValue: string = useWatch({
            name: "ruleSelectConditionValue",
          }) as string;
          const rulesInputValue: string = useWatch({
            name: "rulesInputValue",
          }) as string;
          const rules: object = useWatch({
            name: "rules",
          }) as object;

          const showActionComponents: boolean = action === "warning";

          return (
            <Grid key={"businessRulesConfiguration"} container spacing={2}>
              <Grid item xs={12}>
                <Typography variant={"body1"}>
                  {TitleSection.BUSINESS_RULES_CONFIGURATION_ACTION}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TypedController
                  name="action"
                  rules={{ required: true }}
                  render={(typedProps) => (
                    <FormControl
                      data-testid="actionWrapper"
                      variant="outlined"
                      fullWidth
                      error={!!errors.action}
                    >
                      <Select
                        {...typedProps}
                        native
                        defaultValue={"-1"}
                        error={!!errors.action}
                        value={typedProps.value}
                        required
                      >
                        <option key={"Action"} value={"*Action"}>
                          Acción
                        </option>
                        {BUSINESS_RULES_ACTIONS.map((item, index) => (
                          <option
                            key={`businessRulesAction${index}`}
                            value={item.value}
                          >
                            {item.name}
                          </option>
                        ))}
                      </Select>
                      <FormHelperText error={!!errors.action}>
                        {!!errors.action && ErrorsEnum.REQUIRED_FIELD}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {showActionComponents ? (
                  <TypedController
                    name="partnerValidator"
                    rules={{ required: true }}
                    render={(props) => (
                      <FormControl
                        data-testid="partnerValidatorWrapper"
                        variant="outlined"
                        fullWidth
                        error={!!errors.partnerValidator}
                      >
                        <Select
                          {...props}
                          native
                          defaultValue={"-1"}
                          error={!!errors.partnerValidator}
                          required
                        >
                          <option
                            key={"businessPartnerChoose"}
                            value={"*Elegir"}
                          >
                            Elegir...
                          </option>
                          {PARTNER_VALIDATORS.map((item: Category, index) => (
                            <option
                              key={`businessRulesPartnerValidator${index}`}
                              value={item.value}
                            >
                              {item.name}
                            </option>
                          ))}
                        </Select>
                        <FormHelperText error={!!errors.partnerValidator}>
                          {!!errors.partnerValidator &&
                            ErrorsEnum.REQUIRED_FIELD}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                ) : null}
                {action === "route" ? (
                  <TypedController
                    name="processorName"
                    rules={{ required: true }}
                    render={(typedProps) => (
                      <FormControl
                        variant="outlined"
                        fullWidth
                        style={{ height: "100%" }}
                        error={!!errors.processorName}
                      >
                        {props.isRequest ? (
                          <Grid container>
                            <Grid item xs={10}>
                              <Select
                                {...typedProps}
                                native
                                defaultValue={"-1"}
                                disabled
                                style={{ width: "85%" }}
                              >
                                <option key={"Choose"} value={"*Elegir"}>
                                  Elegir...
                                </option>
                              </Select>
                            </Grid>
                            <Grid item xs={2} style={{ margin: "auto" }}>
                              <CircularProgress color="inherit" size={20} />
                            </Grid>
                          </Grid>
                        ) : (
                          <AutocompleteTextFieldComponent
                            property={"processorName"}
                            label={SEARCH_PROCESSOR_PLACEHOLDER}
                            value={typedProps.value!}
                            options={get(props, "processors", [])}
                            pathValue={"alias"}
                            pathCountry={""}
                            pathName={"alias"}
                            isLoading={false}
                            handleEnterSearchText={
                              props.handleEnterSearchTextProcessors!
                            }
                            handleSetSearchText={
                              props.handleSetSearchTextProcessors!
                            }
                            setValue={typedProps.onChange}
                            disabled={false}
                            error={!!errors.processorName}
                            showCountry={false}
                            errorMessage={ErrorsEnum.REQUIRED_FIELD}
                          />
                        )}
                      </FormControl>
                    )}
                  />
                ) : null}
              </Grid>
              {showActionComponents ? (
                <Grid item xs={12} sm={6}>
                  <TypedController
                    name={["code"]}
                    rules={{ required: true }}
                    render={(props) => (
                      <TextField
                        {...props}
                        id="code"
                        label={LabelField.CODE}
                        variant="outlined"
                        error={!!errors.code}
                        fullWidth
                        helperText={!!errors.code && ErrorsEnum.REQUIRED_FIELD}
                      />
                    )}
                  />
                </Grid>
              ) : null}
              {showActionComponents ? (
                <Grid item xs={12} sm={6}>
                  <TypedController
                    name={["message"]}
                    rules={{ required: true }}
                    render={(props) => (
                      <TextField
                        {...props}
                        id="message"
                        label={LabelField.MESSAGE}
                        variant="outlined"
                        error={!!errors.message}
                        fullWidth
                        helperText={
                          !!errors.message && ErrorsEnum.REQUIRED_FIELD
                        }
                      />
                    )}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant={"body1"}>
                  {TitleSection.BUSINESS_RULES_CONFIGURATION_CONDITIONS}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <TypedController
                      name="ruleSelectValue"
                      render={(typedProps) => (
                        <FormControl
                          data-testid="ruleSelectValueWrapper"
                          variant="outlined"
                          fullWidth
                          error={!!errors.ruleSelectValue}
                        >
                          <Select
                            {...typedProps}
                            native
                            defaultValue={"-1"}
                            error={!!errors.ruleSelectValue}
                            required
                          >
                            <option
                              key={"ruleSelectValueChoose"}
                              value={"*Elegir"}
                            >
                              Elegir...
                            </option>
                            {getRulesSelectValues(action).map(
                              (item: Category, index) => (
                                <option
                                  key={`ruleSelectValue${index}`}
                                  value={item.value}
                                >
                                  {item.name}
                                </option>
                              )
                            )}
                          </Select>
                          <FormHelperText error={!!errors.ruleSelectValue}>
                            {!!errors.ruleSelectValue &&
                              ErrorsEnum.REQUIRED_FIELD}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TypedController
                      name="ruleSelectConditionValue"
                      render={(typedProps) => (
                        <FormControl
                          data-testid="ruleSelectConditionValueWrapper"
                          variant="outlined"
                          fullWidth
                          error={!!errors.ruleSelectConditionValue}
                        >
                          <Select
                            {...typedProps}
                            native
                            defaultValue={"-1"}
                            error={!!errors.ruleSelectConditionValue}
                            required
                          >
                            <option
                              key={"businessRulesActionChoose"}
                              value={"*Elegir"}
                            >
                              Elegir...
                            </option>
                            {getRulesConditionsSelectValues(
                              ruleSelectValue
                            ).map((item: Category, index) => (
                              <option
                                key={`businessRulesAction${index}`}
                                value={item.value}
                              >
                                {item.name}
                              </option>
                            ))}
                          </Select>
                          <FormHelperText
                            error={!!errors.ruleSelectConditionValue}
                          >
                            {!!errors.ruleSelectConditionValue &&
                              ErrorsEnum.REQUIRED_FIELD}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={10} sm={4}>
                    {renderValuesField(
                      ruleSelectValue,
                      ruleSelectConditionValue,
                      props.isRequest,
                      convertMerchantProcessorsToCategory(props.processors),
                      props.country,
                      props.credentials as IOptionSelection[]
                    )}
                  </Grid>
                  <Grid item xs={2} sm={1}>
                    <Box pt={2} pb={3}>
                      <IconButton
                        data-testid="addRuleWrapper"
                        style={{ padding: 0 }}
                        onClick={() => {
                          props.handleAddRule(
                            ruleSelectValue,
                            ruleSelectConditionValue,
                            rulesInputValue,
                            rules
                          );
                        }}
                      >
                        <PlusCircle fontSize="large" />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TypedController
                  name="rules"
                  rules={{ required: true }}
                  render={() => (
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={!!errors.rules}
                    >
                      {Object.keys(defaultTo(rules, {})).map(
                        (key: string, index: number) => (
                          <Grid key={`container${index}`} container spacing={2}>
                            <Grid item xs={12} sm={4}>
                              <Tooltip title={key}>
                                <TextField
                                  id={`rule${index}`}
                                  variant="outlined"
                                  fullWidth
                                  value={key}
                                  disabled
                                />
                              </Tooltip>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <TextField
                                id={`condition${index}`}
                                variant="outlined"
                                fullWidth
                                value={splitString("|", rules[key], 0)}
                                disabled
                              />
                            </Grid>
                            <Grid item xs={10} sm={4}>
                              <Tooltip title={splitString("|", rules[key], 1)}>
                                <TextField
                                  id={`value${index}`}
                                  variant="outlined"
                                  fullWidth
                                  value={splitString("|", rules[key], 1)}
                                  disabled
                                />
                              </Tooltip>
                            </Grid>
                            <Grid item xs={2} sm={1}>
                              <Box pt={2} pb={3}>
                                <IconButton
                                  data-testid={`removeRuleWrapper${index}`}
                                  style={{ padding: 0 }}
                                  onClick={() => {
                                    props.handleRemoveRule(key, rules);
                                  }}
                                >
                                  <MinusCircle fontSize="large" />
                                </IconButton>
                              </Box>
                            </Grid>
                          </Grid>
                        )
                      )}
                      <FormHelperText error={!!errors.rules}>
                        {!!errors.rules && ErrorsEnum.REQUIRED_MIN_RULE}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          );
        }}
      </ConnectForm>
    );
  };
