import { useForm, UseFormMethods, useWatch } from "react-hook-form";
import { cloneDeep, defaultTo, filter, get, isEmpty, keys, set } from "lodash";
import { useParams } from "react-router-dom";
import { TRuleAuthenticationIndexProps } from "../RuleAuthenticationIndex";
import {
  IOptionSelection,
  RuleSchemaEnum,
  RulesNotificationResultEnum,
} from "../../../shared/infrastructure/constants/RuleRequestConstants";
import React, { useEffect, useState } from "react";
import {
  AuthenticationRulesDashboard,
  getLabelValues,
  getValues,
} from "../../../shared/infrastructure/constants/AuthenticationRulesConstants";
// @ts-ignore
import * as cleaner from "deep-cleaner";
import { Category } from "../../../../types/category";
import { BreadcrumProps } from "../../../components/common/Breadcrumb/Breadcrumb";
import { routes } from "../../../shared/infrastructure/routes";
import {
  IRuleAuthenticationForm,
  IRuleSchema,
  IStrictCondition,
} from "../../../shared/infrastructure/interfaces/IRuleAuthenticationForm";
import { StatusList } from "../../../shared/infrastructure/enums/StatusEnum";
import {
  AuthenticationRequestDynamo,
  RuleSchema,
} from "../../../../types/remote/authentication_request_dynamo";
import {
  ProviderEnum,
  ProviderOptionsEnum,
} from "../../../shared/infrastructure/constants/ProviderOptionsEnum";
import { ActionRuleParse } from "../../../shared/infrastructure/enums/ActionRuleEnum";
import { SpeedEnum } from "../../../shared/infrastructure/enums/SpeedEnum";
import { KindRuleEnum } from "../../../shared/infrastructure/enums/kindRuleEnum";

export const initialCondition: IStrictCondition = {
  label: "",
  value: "",
  operator: "",
  actionSpeed: "",
  periodicity: "",
  transactionStatus: "",
  maxAmount: "",
  from: "00:00",
  to: "00:00",
  authRules: [],
  kind: SpeedEnum.strict,
  backupOtherValues: {},
};
export interface IRuleAuthenticationIndexState {
  isEdit: boolean;
  processors: IOptionSelection[];
  credentials: IOptionSelection[];
  actions: {
    handleSubmitForm: (
      formData: IRuleAuthenticationForm,
      status: StatusList
    ) => void;
    handleCancelAction: () => void;
    handleCloseNotification: () => void;
    setFormValue: (name: string, value: string) => void;
  };
  state: IState;
  handlers: {};
  breadCrumbs: BreadcrumProps;
  form: UseFormMethods<IRuleAuthenticationForm>;
  setIgnore3DS: (status: boolean) => void;
  ignore3DS: boolean;
  isLoadingAuthenticationRules: boolean | undefined;
  authorizationKind: string;
  setKind: (newKind: string) => void;
}

export interface IState {}

export const useRuleAuthenticationIndexState = (
  props: TRuleAuthenticationIndexProps
): IRuleAuthenticationIndexState => {
  const { ruleId } = useParams();
  const isEdit: boolean = !isEmpty(ruleId);
  /*---UseState----*/
  const [state] = useState<IState>({});
  const [ignore3DS, setIgnore3DS] = useState<boolean>(false);
  const [processors, setProcessors] = React.useState<IOptionSelection[]>([]);
  const [credentials, setCredentials] = React.useState<IOptionSelection[]>([]);
  const [authorizationKind, setAuthorizationKind] = useState<string>("");

  /*---Initial functions ----*/
  const buildInitialConditions = (
    authRules: RuleSchema[],
    authenticationData: AuthenticationRequestDynamo
  ): IStrictCondition[] => {
    const authenticationDataAux: AuthenticationRequestDynamo =
      cloneDeep(authenticationData);
    const strictCondition: IStrictCondition[] = [];
    const labelValues: string[] = getValues(
      getLabelValues(
        get(authenticationDataAux, "service", ""),
        get(authenticationDataAux, "type", "")
      )
    );
    if (authRules.length === 0)
      return [{ ...initialCondition }] as IStrictCondition[];
    authRules.forEach((itemAuthRule: RuleSchema) => {
      let formObject: object = {
        ...initialCondition,
        kind: get(itemAuthRule, "kind", SpeedEnum.strict),
      };
      let backupOtherValues: object = {};
      let authRulesConditions: IRuleSchema[] = [];
      //STRICT
      keys(itemAuthRule).forEach((key: string) => {
        if (labelValues.includes(key)) {
          const operatorAux: string = get(
            get(itemAuthRule, `${key}`, "").split("|"),
            "0",
            ""
          );
          const valueAux: string = get(
            get(itemAuthRule, `${key}`, "").split("|"),
            "1",
            ""
          );

          if (key === RuleSchemaEnum.transactionCreated) {
            const from: string = get(valueAux.split(";"), "[0]", "00:00");
            const to: string = get(valueAux.split(";"), "[1]", "00:00");
            authRulesConditions.push({
              label: key,
              operator: operatorAux,
              from,
              to,
            });
          } else {
            if (
              operatorAux.includes("=") ||
              operatorAux.includes(">") ||
              operatorAux.includes("<")
            ) {
              authRulesConditions.push({
                label: key,
                operator: operatorAux,
                value: valueAux,
              });
            } else {
              authRulesConditions.push({
                label: key,
                operator: operatorAux,
                value: valueAux.split(","),
              });
            }
          }
        } else {
          if (
            ![
              RuleSchemaEnum.periodicitySum,
              RuleSchemaEnum.periodicityCount,
              RuleSchemaEnum.transactionStatusCount,
              RuleSchemaEnum.transactionStatusSum,
              RuleSchemaEnum.maxAmount,
              RuleSchemaEnum.kind,
            ].includes(key as RuleSchemaEnum)
          ) {
            set(backupOtherValues, key, get(itemAuthRule, `${key}`, ""));
          }
        }
      });
      //SPEED
      if (get(itemAuthRule, "kind", SpeedEnum.strict) === SpeedEnum.speed) {
        if (!isEmpty(itemAuthRule.periodicitySum)) {
          set(formObject, "actionSpeed", "sumar");
          set(formObject, "periodicity", itemAuthRule.periodicitySum);
        }
        if (!isEmpty(itemAuthRule.periodicityCount)) {
          set(formObject, "actionSpeed", "contar");
          set(formObject, "periodicity", itemAuthRule.periodicityCount);
        }
        if (!isEmpty(itemAuthRule.transactionStatusCount)) {
          set(formObject, "actionSpeed", "contar");
          set(
            formObject,
            "transactionStatus",
            itemAuthRule.transactionStatusCount
          );
        }
        if (!isEmpty(itemAuthRule.transactionStatusSum)) {
          set(formObject, "actionSpeed", "sumar");
          set(
            formObject,
            "transactionStatus",
            itemAuthRule.transactionStatusSum
          );
        }
        set(formObject, "maxAmount", get(itemAuthRule, "maxAmount", ""));
      }

      formObject = {
        ...formObject,
        authRules: authRulesConditions,
        backupOtherValues,
      };

      strictCondition.push(formObject as IStrictCondition);
    });
    return strictCondition;
  };
  const getDemoEditFormValues = (): IRuleAuthenticationForm => {
    const authenticationForm = props.state.authenticationData;
    const buildedStrictCondition: IStrictCondition[] = buildInitialConditions(
      get(props.state.authenticationData, "authRules", []),
      props.state.authenticationData
    );
    return {
      ...props.state.authenticationData,
      alias: get(authenticationForm, "alias", ""),
      userAlias: get(authenticationForm, "userAlias", ""),
      provider: get(authenticationForm, "provider", "") as ProviderEnum,
      kind: get(authenticationForm, "kind", "") as ProviderOptionsEnum,
      publicMerchantId: get(authenticationForm, "publicMerchantId", ""),
      merchantName: get(authenticationForm, "merchantName", ""),
      ignore3DS: get(authenticationForm, "ignore3DS", false),
      type: get(authenticationForm, "type", "authenticationCommerce"),
      service: get(authenticationForm, "service", ""),
      country: get(authenticationForm, "country"),
      strictCondition: buildedStrictCondition,
    };
  };

  /*---Hook-form---*/
  const form: UseFormMethods<IRuleAuthenticationForm> =
    useForm<IRuleAuthenticationForm>({
      // Valores con los que se inicializará el formulario
      defaultValues: {
        ...getDemoEditFormValues(),
      },
      // Permitir una validación al perder el focus del componente
      mode: "onBlur",
      reValidateMode: "onBlur",
    });
  const merchantId: string = useWatch({
    control: form.control,
    name: "publicMerchantId",
    defaultValue: "",
  });
  const service: string = useWatch({
    control: form.control,
    name: "service",
    defaultValue: "",
  });

  const setFormValue = (name: any, value: string | string[]) => {
    form.setValue(name, value);
  };

  /*---Functions---*/
  const handleCancelAction = (): void => {
    window.location.href = "/rules";
  };
  const handleCloseNotification = (): void => {
    props.setNotification({
      type: props.notification!.type!,
      open: false,
      message: "",
      action: RulesNotificationResultEnum.NO_ACTION,
    });
  };
  const handleSubmitForm = async (
    formData: IRuleAuthenticationForm,
    status: StatusList
  ) => {
    const validForm: boolean = await form.trigger();
    if (validForm) {
      let dataRequest: AuthenticationRequestDynamo = convertRequestDynamo(
        formData,
        status
      );
      if (isEmpty(dataRequest.authRules)) return;

      dataRequest.authRules?.map((_rule: any, index: number) =>
        set(dataRequest.authRules!, `${index}.ruleNumber`, index + 1)
      );
      props.updateAuthenticationRequest(dataRequest);
    }
  };
  const convertRequestDynamo = (
    form: IRuleAuthenticationForm,
    status: StatusList
  ): AuthenticationRequestDynamo => {
    const authenticationRequestDynamo: AuthenticationRequestDynamo = {
      ...props.state.authenticationData,
      alias: form.alias,
      merchantName: form.merchantName,
      userAlias: form.userAlias,
      provider: form.provider,
      kind: form.kind,
      ignore3DS: ignore3DS,
      publicMerchantId: form.publicMerchantId,
      status: status,
      authRules: buildConditionsAuthRules(),
    };

    return authenticationRequestDynamo;
  };
  const buildConditionsAuthRules = () => {
    let conditionsDynamo: RuleSchema[] = [];
    const conditionsRules: IStrictCondition[] = cloneDeep(
      form.getValues().strictCondition
    );
    if (isEmpty(conditionsRules)) return [];
    conditionsRules.forEach((itemStrictCondition: IStrictCondition) => {
      let aux_authRules: RuleSchema[] = convertAuthRules(
        itemStrictCondition.authRules!,
        itemStrictCondition
      );
      conditionsDynamo = [...conditionsDynamo, ...aux_authRules];
    });
    return conditionsDynamo;
  };
  const convertAuthRules = (
    authRules: IRuleSchema[],
    itemStrictCondition: IStrictCondition
  ) => {
    if (isEmpty(authRules)) return [];
    const aux_rules_dynamo: RuleSchema[] = [];
    let aux_auth: RuleSchema = {
      ...itemStrictCondition.backupOtherValues,
      name: form.getValues().alias,
      kind: get(itemStrictCondition, "kind", SpeedEnum.strict),
      ignore3DS: ignore3DS,
      action: defaultTo(ActionRuleParse[form.getValues().kind], "otp"),
    };
    authRules.forEach((itemAuth: IRuleSchema) => {
      aux_auth = {
        ...aux_auth,
      };
      if (itemAuth.label === RuleSchemaEnum.transactionCreated) {
        aux_auth = {
          ...aux_auth,
          transactionCreated: `betweenDate|${itemAuth.from};${itemAuth.to}`,
        };
      } else {
        aux_auth = {
          ...aux_auth,
          [RuleSchemaEnum[itemAuth.label]]: `${get(
            itemAuth,
            "operator",
            "="
          )}|${itemAuth.value}`,
        };
      }
      if (
        get(itemStrictCondition, "kind", SpeedEnum.strict) === SpeedEnum.speed
      ) {
        const actionValue = get(itemStrictCondition, "actionSpeed", "");
        const actionTxt: "Count" | "Sum" =
          actionValue === "sumar" ? "Sum" : "Count";
        aux_auth = {
          ...aux_auth,
          [`transactionStatus${actionTxt}`]: get(
            itemStrictCondition,
            "transactionStatus",
            ""
          ),
          [`periodicity${actionTxt}`]: get(
            itemStrictCondition,
            "periodicity",
            ""
          ),
          maxAmount: get(itemStrictCondition, "maxAmount", ""),
        };
      }
    });
    aux_rules_dynamo.push(aux_auth);
    return aux_rules_dynamo;
  };

  /*---UseEffect---*/
  useEffect(() => {
    props.getMerchantInfo(ruleId);
  }, []);

  useEffect(() => {
    setIgnore3DS(get(props.state.authenticationData, "ignore3DS", false));
    setAuthorizationKind(
      get(props.state.authenticationData, "kind", KindRuleEnum["OTP"])
    );
    form.reset({ ...getDemoEditFormValues() });
  }, [props.state.authenticationData]);

  useEffect(() => {
    const processorsCard: IOptionSelection[] = [];
    const withCard: object[] = filter(props.processors, [
      "paymentMethod",
      service,
    ]);
    withCard.forEach((processor) =>
      processorsCard.push({
        name: `${get(processor, "processorName", "")} - ${get(
          processor,
          "alias",
          ""
        )}`,
        value: get(processor, "publicProcessorId", ""),
      })
    );

    setProcessors(processorsCard);
  }, [props.processors]);

  useEffect(() => {
    if (!isEmpty(props.authenticationCredentials)) {
      const merchantCredentials: IOptionSelection[] = [];

      defaultTo(props.authenticationCredentials, []).map(
        (credential: Category) => {
          merchantCredentials.push({
            name: get(credential, "name", ""),
            value: get(credential, "value", "").toString(),
          });
        }
      );

      setCredentials(merchantCredentials);
    }
  }, [props.authenticationCredentials]);

  useEffect(() => {
    if (!isEmpty(merchantId)) {
      props.getCredentials(merchantId);
      props.getProcessors({
        merchantId: merchantId,
      });
    }
  }, [merchantId]);

  const setNewAuthorizationKind = (newKind: string) => {
    setAuthorizationKind(newKind);
  };
  return {
    breadCrumbs: {
      items: [
        {
          label: "Inicio",
          url: routes.DASHBOARD,
        },
        {
          label: "Prevención de Fraude",
          url: routes.MONITORING,
        },
        {
          label: AuthenticationRulesDashboard.TITLE,
          url: routes.PATH_RULE_REQUEST_DASHBOARD,
        },
      ],
      lastItem: "Configuración de regla de autenticación",
    },
    form,
    setIgnore3DS,
    ignore3DS,
    isEdit,
    processors,
    credentials,
    state,
    authorizationKind,
    setKind: setNewAuthorizationKind,
    handlers: {},
    actions: {
      handleCancelAction,
      handleCloseNotification,
      handleSubmitForm,
      setFormValue,
    },
    isLoadingAuthenticationRules: props.isLoadingAuthenticationRules,
  };
};
