import { IComponents } from "../../interfaces/components/components.interfaces";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createUpdateComponent,
  GetComponents,
  UpdateStatus,
} from "../../thunks/components/components.thunks";
import { GetComponentsResponse } from "../../../../types/get_components_response";
import { SUCCESS_COMPONENT_MESSAGE } from "../../../shared/constants/labels/components_container_labels";
import {
  SUCCESS_ADD_COMPONENT_MESSAGE,
  SUCCESS_EDIT_COMPONENT_MESSAGE,
} from "../../../shared/constants/labels/modules_container_labels";

export const initialState: IComponents = {
  components: [],
  currentModuleRole: "",
  dataForm: { description: "", id: "", roles: [] },
  isLoadingGetComponents: true,
  isLoadingUpdateComponent: true,
};

export const componentsSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(GetComponents.pending, (state) => {
        state.isLoadingGetComponents = true;
      })
      .addCase(GetComponents.fulfilled, (state, { payload }) => {
        state.components = payload.components;
        state.currentModuleRole = payload.currentModuleRole;
        state.isLoadingGetComponents = false;
      })
      .addCase(GetComponents.rejected, (state) => {
        state.isLoadingGetComponents = false;
      })
      .addCase(UpdateStatus.pending, (state) => {
        state.isLoadingUpdateComponent = true;
      })
      .addCase(UpdateStatus.fulfilled, (state) => {
        state.isLoadingUpdateComponent = false;
        state.alert = {
          message: SUCCESS_COMPONENT_MESSAGE,
          type: "success",
        };
      })
      .addCase(createUpdateComponent.fulfilled, (state, { payload }) => {
        state.components = payload.components;
        state.alert = {
          message: payload.isNew
            ? SUCCESS_ADD_COMPONENT_MESSAGE
            : SUCCESS_EDIT_COMPONENT_MESSAGE,
          type: "success",
        };
      })
      .addCase(createUpdateComponent.rejected, (state, {}) => {
        state.alert = {
          message: SUCCESS_ADD_COMPONENT_MESSAGE,
          type: "error",
        };
      })
      .addCase(UpdateStatus.rejected, (state) => {
        state.isLoadingUpdateComponent = false;
      });
  },
  initialState,
  name: "components",
  reducers: {
    clearModule: (state) => {
      state.componentSelected = undefined;
    },
    setModule: (state, { payload }) => {
      state.componentSelected = payload;
    },
    updateComponent: (
      state,
      { payload }: PayloadAction<GetComponentsResponse[]>
    ) => {
      state.components = payload;
    },
  },
});

export default componentsSlice.reducer;
