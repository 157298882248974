export enum ActionTypes {
  SET_BRANCHES_DATA = "[CREDENTIALS] SET BRANCHES DATA",
  SET_CREDENTIALS_FOR_BOX = "[CREDENTIALS] SET CREDENTIALS_FOR_BOX",
  SET_CREDENTIALS_LIST = "[CREDENTIALS] SET CREDENTIALS LIST",
  SET_CREDENTIALS_LIST_MODAL = "[CREDENTIALS] SET CREDENTIALS LIST MODAL",
  SET_INSERT_CREDENTIALS_RESPONSE = "[CREDENTIALS] SET_INSERT_CREDENTIALS_RESPONSE",
  SET_IS_LOADING = "[CREDENTIALS] SET IS LOADING",
  SET_IS_LOADING_CREDENTIALS = "[CREDENTIALS] SET IS LOADING CREDENTIALS",
  SET_IS_LOADING_GET_SUGGESTED_CREDENTIALS = "[CREDENTIALS] SET IS LOADING GET SUGGESTED CREDENTIALS",
  SET_IS_OPEN_CREDENTIAL_MODAL = "[CREDENTIALS] SET IS OPEN CREDENTIAL MODAL",
  SET_IS_OPEN_CUSTOM_MODAL = "[CREDENTIALS] SET IS OPEN CUSTOM MODAL",
  SET_NODE_INFO = "[CREDENTIALS] SET NODE INFO",
  SET_NOTIFICATION = "[CREDENTIALS] SET NOTIFICATION",
  SET_OPEN_ALERT = "[CREDENTIALS] SET OPEN ALERT",
  SET_PARENT_MERCHANT_ID = "[CREDENTIALS] SET PARENT MERCHANT ID",
  SET_REQUEST_ERROR = "[CREDENTIALS] SET REQUEST ERROR",
  SET_SELECTED_BRANCHES = "[CREDENTIALS] SET_SELECTED_BRANCHES",
  SET_BRANCHES_DETAIL_BAR = "[CREDENTIALS] SET_BRANCHES_DETAIL_BAR",
  SET_BRANCHES = "[CREDENTIALS] SET_BRANCHES",
}
