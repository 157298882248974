import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { SnackbarAlertProps } from "./SnackBarAlert.interfaces";
import { useSnackBarAlertState } from "./state/useSnackBarAlertState";

export const SnackBarAlert: React.FC<SnackbarAlertProps> = (
  props: SnackbarAlertProps
) => {
  const { open } = useSnackBarAlertState(props);

  return (
    <Snackbar
      anchorOrigin={{
        horizontal: "center",
        vertical: "top",
      }}
      open={open}
      autoHideDuration={4000}
      onClose={props.handlerClose}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={props.handlerClose}
        severity={props.type}
      >
        {props.msg}
      </MuiAlert>
    </Snackbar>
  );
};
