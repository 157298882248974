import React, { FC } from "react";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { ContactCard } from "./ContactCard";
import { KushkiCard } from "../KushkiCard/KushkiCard";
import { MissingInfoAlert } from "../MissingInfoAlert/MissingInfoAlert";
import { useSelector } from "react-redux";
import {
  selectContactsData,
  selectMerchantCompliance,
} from "../../store/selectors/selectors";
import useContactsIndex from "./state/useContactsIndex";

export interface IContactInfo {
  email: string;
  name: string;
}

const ContactsIndex: FC = () => {
  const { showContactCardIndex } = useContactsIndex();
  const contactsData = useSelector(selectContactsData);
  const merchantCompliance = useSelector(selectMerchantCompliance);

  const ComercialContacts: IContactInfo[] = contactsData?.contactCommercialArea
    ? contactsData?.contactCommercialArea
    : [{ email: "", name: "" }];
  const DispersionContacts: IContactInfo[] = contactsData?.contactDispersionArea
    ? contactsData?.contactDispersionArea
    : [{ email: "", name: "" }];
  const BillingContacts: IContactInfo[] = contactsData?.contactBillingArea
    ? contactsData?.contactBillingArea
    : [{ email: "", name: "" }];
  const RiskContacts: IContactInfo[] = contactsData?.contactRiskArea
    ? contactsData?.contactRiskArea
    : [{ email: "", name: "" }];
  const TechnicalContacts: IContactInfo[] = contactsData?.contactTechnicalArea
    ? contactsData?.contactTechnicalArea
    : [{ email: "", name: "" }];
  const accountExecutiveName: string = merchantCompliance?.commercialExecutive
    ? merchantCompliance?.commercialExecutive
    : "";
  const accountExecutiveRole: string = merchantCompliance?.categoryMerchant
    ? merchantCompliance?.categoryMerchant
    : "";

  return (
    <Box sx={{ flexGrow: 1, paddingTop: 4 }}>
      {!showContactCardIndex ? (
        <MissingInfoAlert />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} sx={{ paddingTop: "0 !important" }}>
            <ContactCard title="Comercial" contacts={ComercialContacts} />
          </Grid>
          <Grid item xs={12} md={6} mt={0} sx={{ paddingTop: "0 !important" }}>
            <ContactCard title="Facturación" contacts={BillingContacts} />
          </Grid>
          <Grid item xs={12} md={6}>
            <ContactCard title="Dispersión" contacts={DispersionContacts} />
          </Grid>
          <Grid item xs={12} md={6}>
            <ContactCard title="Técnica" contacts={TechnicalContacts} />
          </Grid>
          <Grid item xs={12} md={6}>
            <ContactCard title="Riesgos" contacts={RiskContacts} />
          </Grid>
          <Grid item xs={12} md={6}>
            <KushkiCard
              name={accountExecutiveName}
              role={accountExecutiveRole}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ContactsIndex;
