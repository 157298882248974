import { configureStore } from "@reduxjs/toolkit";
import { createEpicMiddleware } from "redux-observable";
import appReducer from "./reducers/app/app.slice";
import appEpic from "./epics/epics";
import { environment } from "../environments/environment";
import ticketOrderReducer from "./reducers/ticketOrder/ticketOrder.slice";
import rechargeWalletReducer from "./reducers/rechargeWallet/rechargeWallet.slice";

const epicMiddleware = createEpicMiddleware();

const store = configureStore({
  devTools: environment.devTools,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(epicMiddleware),
  reducer: {
    app: appReducer,
    rechargeWallet: rechargeWalletReducer,
    ticketOrder: ticketOrderReducer,
  },
});

epicMiddleware.run(
  (action$: any, state$: any) =>
    appEpic({ action$, dispatch: store.dispatch, state$ }) as any
);

export { epicMiddleware, store };
