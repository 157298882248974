import { TypeEnum } from "@shared/constants/AlarmConfigConstants";
import { NodeSelectionLabels } from "@shared/constants/labels/nodeSelectionLabels";
import { RuleAlarmsLabelsEnum } from "@shared/enum/RuleAlarmsOptionsEnum";

export enum AlarmLabels {
  TITLE = "Configuración de alarmas",
  CREATE_ALARM = "Crear alarma",
  LABEL_SEARCH_TEXT = "Buscar por owner, customer o branch",
  MERCHANT_HIERARCHY_TEXT = "BRANCH/MERCHANT",
  GENERAL_SEARCH_ALIAS_TEXT = "Buscar por alias",
  ACTIVE_ALARMS = "Alarmas activas",
  HISTORIC_ALARMS = "Histórico de alarmas",
  FILTER_SEARCH_TEXT = "Buscar por nombre/Id de branch/merchant",
}

const getSelectedCount = (selected: number): string =>
  selected > 0 ? ` (${selected})` : "";

export const MassiveAlarmLabels = (selected: number): object => ({
  CLOSE_ALARM: `Cerrar alarma${getSelectedCount(selected)}`,
  INIT_INVESTIGATION: `Iniciar investigación${getSelectedCount(selected)}`,
  INIT_INVESTIGATION_DESCRIPTION:
    "Puedes seleccionar varias alarmas y reportar o cerrarlas de forma simultanea",
  INIT_INVESTIGATION_HELPER:
    "Para llevar a cabo Iniciar investigación de manera masiva, el estado se debe encontrar en estado Pendiente",
  REACTIVATE_ALARM_DESCRIPTION:
    "Puedes seleccionar varias alarmas y reactivarlas de forma simultanea.",
  REACTIVATE_ALARM_HELPER:
    "Solo se podrán reactivar las alarmas con estatus Cerrada normal y Cerrada ROS",
  REACTIVATE_ALARMS: `Reactivar alarmas${getSelectedCount(selected)}`,
  SELECTED_ALARMS: "Alarmas seleccionadas:",
});

export const labelByAlarmType: object = {
  [TypeEnum.GENERAL]: {
    ["false"]: {
      header: NodeSelectionLabels.GENERAL_ALARM_CONFIG,
      title: RuleAlarmsLabelsEnum.ALARM_GENERAL,
    },
    ["true"]: {
      header: NodeSelectionLabels.EDIT_GENERAL_ALARM_CONFIG,
      title: RuleAlarmsLabelsEnum.ALARM_GENERAL_EDIT,
    },
  },
  [TypeEnum.INDIVIDUAL]: {
    ["false"]: {
      header: NodeSelectionLabels.ALARM_CONFIG,
      title: RuleAlarmsLabelsEnum.ALARM_USER,
    },
    ["true"]: {
      header: NodeSelectionLabels.EDIT_ALARM_CONFIG,
      title: RuleAlarmsLabelsEnum.ALARM_USER_EDIT,
    },
  },
};
