import { IUseTableRetentionInterface } from "./IUseTableRetention.interfaces";
import { IRetentionInfoInterface } from "../../../../containers/MyMerchantContainer/state/useMyMerchant.interfaces";
import { useEffect, useState } from "react";
import { ITableRowProps } from "../../TableNodes/interfaces";
import { clone, get, isEmpty } from "lodash";
import { sortObjectArray } from "../../../../shared/utils/sortUtils";
import { FIELD_SORT_CATALOGUE } from "../../../../shared/catalogs/CatalogConfigTable";
import { SortEnum } from "../../../../shared/enums/SortEnum";

export const useTableRetention = ({
  isLoadingRetention,
  rows,
  totalRows,
  downloadFile,
}: IRetentionInfoInterface): IUseTableRetentionInterface => {
  const [isMinimizeTable, setIsMinimizeTable] = useState(true);
  const [retentionData, setRetentionData] = useState<ITableRowProps[]>(
    rows.slice(0, 3)
  );
  const [sort, setSort] = useState({ field: "", numClick: 0 });

  const handleMinimizeTable = () => {
    setIsMinimizeTable(!isMinimizeTable);
  };

  const handleRequestSort = (field: string) => {
    const sortField: string = get(sort, "field", "");
    const numClick: number = get(sort, "numClick", 0);
    const fieldPath: string = FIELD_SORT_CATALOGUE[field];

    if (sortField !== field && sortField !== "") {
      setSort({ field, numClick: 1 });
      setRetentionData(
        sortObjectArray(clone(retentionData), fieldPath, SortEnum.ASC)
      );

      return;
    }

    if (numClick >= 2) {
      setSort({ field, numClick: 1 });
      setRetentionData(
        sortObjectArray(clone(retentionData), fieldPath, SortEnum.ASC)
      );
    } else {
      setSort({ field, numClick: numClick + 1 });
      setRetentionData(
        sortObjectArray(
          clone(retentionData),
          fieldPath,
          numClick ? SortEnum.DESC : SortEnum.ASC
        )
      );
    }
  };

  useEffect(() => {
    isMinimizeTable
      ? setRetentionData(retentionData.slice(0, 3))
      : setRetentionData(
          sortObjectArray(
            clone(rows),
            FIELD_SORT_CATALOGUE[get(sort, "field", "")],
            get(sort, "numClick", 0) > 1 ? SortEnum.DESC : SortEnum.ASC
          )
        );
  }, [isMinimizeTable]);

  useEffect(() => {
    if (isEmpty(retentionData)) {
      setRetentionData(rows.slice(0, 3));
    }
  }, [rows]);

  return {
    downloadFile,
    handleMinimizeTable,
    handleRequestSort,
    isLoadingRetention,
    isMinimizeTable,
    retentionData,
    totalRetentionData: totalRows,
  };
};
