import React, { FC } from "react";
import { messageBoxStyles } from "./MessageBox.styles";
import { Info } from "react-feather";
import { Grid, Typography } from "@mui/material";

export interface MessageBoxProps {
  title: string;
  description: string;
}

export const MessageBox: FC<MessageBoxProps> = (props: MessageBoxProps) => {
  return (
    <Grid container sx={messageBoxStyles.container}>
      <Grid sx={messageBoxStyles.titleWrapper}>
        <Info />
        <Typography sx={messageBoxStyles.title}>{props.title}</Typography>
      </Grid>
      <Grid>
        <Typography sx={messageBoxStyles.description}>
          {props.description}
        </Typography>
      </Grid>
    </Grid>
  );
};
