export enum AnalysisStateEnum {
  PNDRFN = "PNDRFN",
  RTRY = "RTRY",
  NTHRZD = "NTHRZD",
  AUTHORIZED = "ATHRZD",
  IVLDTN = "IVLDTN",
  NA = "N/A",
}

export const AnalisisStateText = {
  [AnalysisStateEnum.PNDRFN]: "PENDIENTE",
  [AnalysisStateEnum.RTRY]: "REINGRESO",
  [AnalysisStateEnum.NTHRZD]: "NO AUTORIZADO",
};
