import { createSlice } from "@reduxjs/toolkit";
import { BrazilMerchantDeferredResponse } from "../../../../types/brazil_merchant_deferred_response";
import { getBrazilMerchantDeferred } from "../../thunks/deferred/deferred.thunk";

export interface IDeferredAppState {
  isLoadingMerchantDeferred: boolean;
  merchantDeferred: BrazilMerchantDeferredResponse[];
}

export const initialState: IDeferredAppState = {
  isLoadingMerchantDeferred: false,
  merchantDeferred: [],
};

export const brazilDeferredAppSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getBrazilMerchantDeferred.pending, (state) => {
        state.isLoadingMerchantDeferred = true;
      })
      .addCase(getBrazilMerchantDeferred.fulfilled, (state, action) => {
        state.merchantDeferred = action.payload;
        state.isLoadingMerchantDeferred = false;
      })
      .addCase(getBrazilMerchantDeferred.rejected, (state) => {
        state.isLoadingMerchantDeferred = false;
      });
  },
  initialState,
  name: "brazilDeferredApp",
  reducers: {},
});

export default brazilDeferredAppSlice.reducer;
