import { IStyles } from "./../../../../../shared/interfaces/Styles.interfaces";

export const styles: IStyles = {
  container: {
    backgroundColor: "background.default",
    paddingLeft: "32px",
  },
  itemContentRow: {
    alignItems: "center",
    height: "100%",
  },
  timeLineIcon: {
    transform: "scale(1.5)",
  },
};
