import React, { FC } from "react";
import Box from "@mui/material/Box";
import { IBranches } from "./BranchesResumeContainer.interface";
import { Chip, Grid, Typography } from "@mui/material";
import { styles } from "./BranchesResumeContainer.styles";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import _ from "lodash";
import {
  merchantStatusLabel,
  merchantStatusValue,
} from "../../../shared/enums/merchantStatusEnum";

const BranchesResumeContainer: FC<IBranches> = (props: IBranches) => {
  const { branches } = props;

  const fromatDateMMM = (date: string): string => {
    return _.replace(
      _.startCase(format(new Date(date), "dd/MMM/yyyy", { locale: es })),
      / /g,
      "/"
    );
  };

  const translateStatusMerchant = (merchantStatus: string): string => {
    switch (merchantStatus) {
      case merchantStatusValue.ACTIVE:
        return merchantStatusLabel.ACTIVE;
      case merchantStatusValue.PENDING:
        return merchantStatusLabel.PENDING;
      default:
        return merchantStatus;
    }
  };

  return (
    <Box
      alignItems={"center"}
      sx={{
        display: "inline-flex",
      }}
    >
      <Grid container alignItems="center">
        <Grid item direction="column" xs="auto" rowSpacing={1}>
          {branches.map((branch, index) => {
            return (
              <Typography key={`branch-${index}`} sx={styles.branchName}>
                {branch.name}
              </Typography>
            );
          })}
        </Grid>
        <Grid item direction="column" xs="auto" rowSpacing={1}>
          {branches.map((branch, index) => {
            return (
              <Chip
                key={`branch-${index}`}
                label={translateStatusMerchant(branch.merchant_status!)}
                sx={
                  branch.merchant_status === merchantStatusValue.ACTIVE
                    ? styles.chip
                    : styles.chipPending
                }
              />
            );
          })}
        </Grid>
        <Grid item direction="column" xs="auto" rowSpacing={1}>
          {branches.map((branch, index) => {
            return (
              <Typography
                key={`branch-${index}`}
                sx={styles.created}
              >{`Creado el ${fromatDateMMM(branch.created)}`}</Typography>
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
};

export default BranchesResumeContainer;
