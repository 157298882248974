import {
  IForm,
  TErrorMessage,
} from "../../../shared/infrastructure/interfaces/IForm";
import { FieldError, useFormContext } from "react-hook-form";
import { ErrorsEnum } from "../../../shared/infrastructure/enums/ErrorEnum";

const ConnectForm = <T extends {}>({
  children,
}: {
  children: (form: IForm<T>) => JSX.Element;
}) => {
  const formMethods = useFormContext<T>();

  const getErrorMessage = (
    fieldError: FieldError,
    typeErrorMessage?: TErrorMessage
  ): string | undefined => {
    if (fieldError.message) return fieldError.message;

    switch (fieldError.type) {
      case "required":
        return ErrorsEnum.REQUIRED_FIELD;
      case "minLength":
        switch (typeErrorMessage) {
          case "4":
            return ErrorsEnum.MIN_4_CHARACTER;
          case "12":
            return ErrorsEnum.MIN_12_CHARACTER;
        }
      case "maxLength":
        switch (typeErrorMessage) {
          case "10":
            return ErrorsEnum.MAX_10_CHARACTER;
          case "12":
            return ErrorsEnum.MAX_12_CHARACTER;
          case "22":
            return ErrorsEnum.MAX_22_CHARACTER;
          case "32":
            return ErrorsEnum.MAX_32_CHARACTER;
        }
      default:
        return "";
    }
  };

  return children({
    ...formMethods,
    getErrorMessage,
  });
};

export default ConnectForm;
