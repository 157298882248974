import { Box } from "@mui/material";
import React, { FC, memo } from "react";
import RefundsActionBar from "../RefundsActionBar/RefundsActionBar";
import RefundsLoadMoreButton from "../RefundsLoadMoreButton/RefundsLoadMoreButton";
import RefundsTable from "../RefundsTable/RefundsTable";

const ApprovalRecordContent: FC = () => {
  return (
    <Box>
      <RefundsActionBar data-testid="action-bar" />

      <RefundsTable />

      <RefundsLoadMoreButton />
    </Box>
  );
};

export default memo(ApprovalRecordContent);
