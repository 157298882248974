import { IStyles } from "../../shared/Interfaces/Styles.interfaces";

export const breadcrumbStyles: IStyles = {
  text: {
    color: "#677784",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "170%",
  },
};
