export const M_ROLES_CONTROL = "M_ROLES_CONTROL";

export enum CompomentIdsEnum {
  M_ROLES_ADD_MODULE = "M_ROLES_CONTROL.AgregarModulo",
  M_ROLES_ADD_COMPONENT = "M_ROLES_CONTROL.AgregarComponente",
  M_ROLES_EDIT_MODULE = "M_ROLES_CONTROL.EditarModulo",
  M_ROLES_EDIT_COMPONENT = "M_ROLES_CONTROL.EditarComponente",
  M_ROLES_SWITCH_STATUS_MODULE = "M_ROLES_CONTROL.SwitchEstatusModulo",
  M_ROLES_SWITCH_STATUS_COMPONENT = "M_ROLES_CONTROL.SwitchEstatusComponente",
}
