import {
  BasicPropertiesLabelEnum,
  BasicPropertiesValueEnum,
} from "../../enums/BasicPropertiesEnum";
import { CountriesEnum } from "../countries-enum";

export const columns = {
  [BasicPropertiesValueEnum.MERCHANT_TYPE]: {
    key: BasicPropertiesValueEnum.MERCHANT_TYPE,
    label: BasicPropertiesLabelEnum.MERCHANT_TYPE,
  },
  [BasicPropertiesValueEnum.TAX_ID]: {
    key: BasicPropertiesValueEnum.TAX_ID,
    label: BasicPropertiesLabelEnum.TAX_ID,
  },
  [BasicPropertiesValueEnum.TAX_ID_NUMBER]: {
    key: BasicPropertiesValueEnum.TAX_ID_NUMBER,
    label: BasicPropertiesLabelEnum.TAX_ID_NUMBER,
  },
  [BasicPropertiesValueEnum.TAX_ID_WITH_ID_NUMBER]: {
    key: BasicPropertiesValueEnum.TAX_ID_WITH_ID_NUMBER,
    label: "",
  },
  [BasicPropertiesValueEnum.CONSTITUTION_DATE]: {
    key: BasicPropertiesValueEnum.CONSTITUTION_DATE,
    label: BasicPropertiesLabelEnum.CONSTITUTION_DATE,
  },
  [BasicPropertiesValueEnum.INDUSTRY_TYPE]: {
    key: BasicPropertiesValueEnum.INDUSTRY_TYPE,
    label: BasicPropertiesLabelEnum.INDUSTRY_TYPE,
  },
  [BasicPropertiesValueEnum.WEB_SITE]: {
    key: BasicPropertiesValueEnum.WEB_SITE,
    label: BasicPropertiesLabelEnum.WEB_SITE,
  },
  [BasicPropertiesValueEnum.COMPANY_REGISTRATION_NUMBER]: {
    key: BasicPropertiesValueEnum.COMPANY_REGISTRATION_NUMBER,
    label: BasicPropertiesLabelEnum.COMPANY_REGISTRATION_NUMBER,
  },
  [BasicPropertiesValueEnum.IDENTIFICATION_EIN]: {
    key: BasicPropertiesValueEnum.IDENTIFICATION_EIN,
    label: BasicPropertiesLabelEnum.IDENTIFICATION_EIN,
  },
};

export const COUNTRIES = {
  [CountriesEnum.ECUADOR]: [
    columns[BasicPropertiesValueEnum.MERCHANT_TYPE],
    columns[BasicPropertiesValueEnum.TAX_ID],
    columns[BasicPropertiesValueEnum.TAX_ID_NUMBER],
    columns[BasicPropertiesValueEnum.CONSTITUTION_DATE],
    columns[BasicPropertiesValueEnum.INDUSTRY_TYPE],
    columns[BasicPropertiesValueEnum.WEB_SITE],
  ],
  [CountriesEnum.COLOMBIA]: [
    columns[BasicPropertiesValueEnum.MERCHANT_TYPE],
    columns[BasicPropertiesValueEnum.TAX_ID_WITH_ID_NUMBER],
    columns[BasicPropertiesValueEnum.CONSTITUTION_DATE],
    columns[BasicPropertiesValueEnum.INDUSTRY_TYPE],
    columns[BasicPropertiesValueEnum.WEB_SITE],
  ],
  [CountriesEnum.PERU]: [
    columns[BasicPropertiesValueEnum.MERCHANT_TYPE],
    columns[BasicPropertiesValueEnum.TAX_ID_WITH_ID_NUMBER],
    columns[BasicPropertiesValueEnum.CONSTITUTION_DATE],
    columns[BasicPropertiesValueEnum.INDUSTRY_TYPE],
    columns[BasicPropertiesValueEnum.WEB_SITE],
  ],
  [CountriesEnum.CHILE]: [
    columns[BasicPropertiesValueEnum.MERCHANT_TYPE],
    columns[BasicPropertiesValueEnum.TAX_ID_WITH_ID_NUMBER],
    columns[BasicPropertiesValueEnum.CONSTITUTION_DATE],
    columns[BasicPropertiesValueEnum.INDUSTRY_TYPE],
    columns[BasicPropertiesValueEnum.WEB_SITE],
  ],
  [CountriesEnum.MEXICO]: [
    columns[BasicPropertiesValueEnum.MERCHANT_TYPE],
    columns[BasicPropertiesValueEnum.TAX_ID_WITH_ID_NUMBER],
    columns[BasicPropertiesValueEnum.CONSTITUTION_DATE],
    columns[BasicPropertiesValueEnum.INDUSTRY_TYPE],
    columns[BasicPropertiesValueEnum.WEB_SITE],
  ],
  [CountriesEnum.BRAZIL]: [
    columns[BasicPropertiesValueEnum.MERCHANT_TYPE],
    columns[BasicPropertiesValueEnum.TAX_ID_WITH_ID_NUMBER],
    columns[BasicPropertiesValueEnum.CONSTITUTION_DATE],
    columns[BasicPropertiesValueEnum.INDUSTRY_TYPE],
    columns[BasicPropertiesValueEnum.WEB_SITE],
  ],
  [CountriesEnum.EL_SALVADOR]: [
    columns[BasicPropertiesValueEnum.MERCHANT_TYPE],
    columns[BasicPropertiesValueEnum.TAX_ID_WITH_ID_NUMBER],
    columns[BasicPropertiesValueEnum.CONSTITUTION_DATE],
    columns[BasicPropertiesValueEnum.INDUSTRY_TYPE],
    columns[BasicPropertiesValueEnum.WEB_SITE],
  ],
  [CountriesEnum.PANAMA]: [
    columns[BasicPropertiesValueEnum.MERCHANT_TYPE],
    columns[BasicPropertiesValueEnum.TAX_ID_WITH_ID_NUMBER],
    columns[BasicPropertiesValueEnum.CONSTITUTION_DATE],
    columns[BasicPropertiesValueEnum.INDUSTRY_TYPE],
    columns[BasicPropertiesValueEnum.WEB_SITE],
  ],
  [CountriesEnum.COSTA_RICA]: [
    columns[BasicPropertiesValueEnum.MERCHANT_TYPE],
    columns[BasicPropertiesValueEnum.TAX_ID_WITH_ID_NUMBER],
    columns[BasicPropertiesValueEnum.CONSTITUTION_DATE],
    columns[BasicPropertiesValueEnum.INDUSTRY_TYPE],
    columns[BasicPropertiesValueEnum.WEB_SITE],
  ],
  [CountriesEnum.GUATEMALA]: [
    columns[BasicPropertiesValueEnum.MERCHANT_TYPE],
    columns[BasicPropertiesValueEnum.TAX_ID],
    columns[BasicPropertiesValueEnum.TAX_ID_NUMBER],
    columns[BasicPropertiesValueEnum.CONSTITUTION_DATE],
    columns[BasicPropertiesValueEnum.INDUSTRY_TYPE],
    columns[BasicPropertiesValueEnum.WEB_SITE],
  ],
  [CountriesEnum.HONDURAS]: [
    columns[BasicPropertiesValueEnum.MERCHANT_TYPE],
    columns[BasicPropertiesValueEnum.TAX_ID],
    columns[BasicPropertiesValueEnum.TAX_ID_NUMBER],
    columns[BasicPropertiesValueEnum.CONSTITUTION_DATE],
    columns[BasicPropertiesValueEnum.INDUSTRY_TYPE],
    columns[BasicPropertiesValueEnum.WEB_SITE],
  ],
  [CountriesEnum.NICARAGUA]: [
    columns[BasicPropertiesValueEnum.MERCHANT_TYPE],
    columns[BasicPropertiesValueEnum.TAX_ID_WITH_ID_NUMBER],
    columns[BasicPropertiesValueEnum.CONSTITUTION_DATE],
    columns[BasicPropertiesValueEnum.INDUSTRY_TYPE],
    columns[BasicPropertiesValueEnum.WEB_SITE],
  ],
  [CountriesEnum.ESTADOS_UNIDOS]: [
    columns[BasicPropertiesValueEnum.MERCHANT_TYPE],
    columns[BasicPropertiesValueEnum.TAX_ID],
    columns[BasicPropertiesValueEnum.TAX_ID_NUMBER],
    columns[BasicPropertiesValueEnum.CONSTITUTION_DATE],
    columns[BasicPropertiesValueEnum.INDUSTRY_TYPE],
    columns[BasicPropertiesValueEnum.WEB_SITE],
  ],
  [CountriesEnum.CENTRO_AMERICA]: [
    columns[BasicPropertiesValueEnum.MERCHANT_TYPE],
    columns[BasicPropertiesValueEnum.TAX_ID_WITH_ID_NUMBER],
    columns[BasicPropertiesValueEnum.CONSTITUTION_DATE],
    columns[BasicPropertiesValueEnum.INDUSTRY_TYPE],
    columns[BasicPropertiesValueEnum.WEB_SITE],
  ],
};

export const DEFAULT_COUNTRY = [
  columns[BasicPropertiesValueEnum.MERCHANT_TYPE],
  columns[BasicPropertiesValueEnum.TAX_ID_NUMBER],
  columns[BasicPropertiesValueEnum.CONSTITUTION_DATE],
  columns[BasicPropertiesValueEnum.INDUSTRY_TYPE],
  columns[BasicPropertiesValueEnum.WEB_SITE],
];

export const EEUU_PSP = [
  columns[BasicPropertiesValueEnum.MERCHANT_TYPE],
  columns[BasicPropertiesValueEnum.IDENTIFICATION_EIN],
  columns[BasicPropertiesValueEnum.CONSTITUTION_DATE],
  columns[BasicPropertiesValueEnum.COMPANY_REGISTRATION_NUMBER],
  columns[BasicPropertiesValueEnum.INDUSTRY_TYPE],
  columns[BasicPropertiesValueEnum.WEB_SITE],
];
