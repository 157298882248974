export enum KeyStatusEnum {
  APPROVAL_KUSHKI_STATUS = "approval",
  DECLINED_KUSHKI_STATUS = "declinedKushkiStatus",
  NOT_EXIST_KUSHKI_STATUS = "notExistKushkiStatus",
  APPROVED_PROCESSOR_STATUS = "approved",
  DECLINED_PROCESSOR_STATUS = "declinedProcessorStatus",
  NOT_EXIST_PROCESSOR_STATUS = "notExistProcessorStatus",
  SALE_TRX_TYPE = "sale",
  REFUND_TRX_TYPE = "refund",
  VOID_TRX_TYPE = "void",
  CONCILIATED_STATUS = "conciliated",
  OMITTED_STATUS = "omitted",
  APPROVED_REFUND_STATUS = "approved_refund",
  REFUSED_REFUND_STATUS = "refused_refund",
  PENDING_REFUND_STATUS = "pending_refund",
  MXN_CURRENCY = "USD",
}

export const DeclinedStatus: string[] = [
  KeyStatusEnum.DECLINED_KUSHKI_STATUS,
  KeyStatusEnum.DECLINED_PROCESSOR_STATUS,
];

export const NotExistStatus: string[] = [
  KeyStatusEnum.NOT_EXIST_KUSHKI_STATUS,
  KeyStatusEnum.NOT_EXIST_PROCESSOR_STATUS,
];
