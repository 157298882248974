import React, { useEffect, useState } from "react";
import { WebhookIndexFormProps } from "../WebhookFormIndex";
import {
  SubmitHandler,
  UnpackNestedValue,
  useForm,
  UseFormMethods,
} from "react-hook-form";
import {
  IWebhookForm,
  PaymentUrlWithStatus,
  WebhookEvent,
} from "../../../shared/infrastructure/interfaces/IWebhookForm";
import {
  Status,
  WebhookCreateRequest,
} from "../../../../types/webhook_create_request";
import { get } from "lodash";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { BreadcrumProps } from "../../../components/Breadcrumb/Breadcrumb";
import { auth } from "../../../shared/auth";
import { ModalTestURLProps } from "../../../components/ModalTestConnection/ModalTestURL/ModalTestURL";
import { INotification } from "../../../shared/infrastructure/interfaces/INotification";
import { StatusEnum } from "../../../shared/infrastructure/constants/StatusEnum";
import { ServiceEnum } from "../../../shared/infrastructure/constants/ServiceEnum";
import { IValidUrl } from "../../../shared/infrastructure/interfaces/IValidUrl";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import {
  PreauthSaveRequest,
  saveUrlPreauth,
  setPreauthEnable,
  setShowError,
} from "../../../store/actionCreators";
import {
  CARD_CHARGEBACK,
  PAYMENT_METHODS_EVENTS,
  ServiceOptionEnum,
  WebhookEventsEnum,
} from "../../../shared/infrastructure/constants/OptionsEnum";
export type TWebhookForm = IWebhookForm;
export interface IWebhookFormIndexState {
  form: UseFormMethods<TWebhookForm>;
  actions: {
    handleSubmitForm: SubmitHandler<TWebhookForm>;
    handleGoBack: () => void;
    handlerClose: (_event?: React.SyntheticEvent, reason?: string) => void;
  };
  breadcrumbs: BreadcrumProps;
  formInfo: {
    title: string;
  };
  modalTestUrl: ModalTestURLProps;
  snackBar: INotification;
}

export const useWebhookForm = (
  props: WebhookIndexFormProps
): IWebhookFormIndexState => {
  const merchantId: string = get(useParams(), "merchantId");
  const merchantInfo = JSON.parse(
    localStorage.getItem("merchantInformation") || "{}"
  );
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const locationPathname = location.pathname;
  const isPreauthEnable = useSelector(
    (state: IAppState) => state.isPreauthEnable!
  );

  //modalURL
  const [openModal, setModalOpened] = useState<boolean>(false);
  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [webhookRequest, setWebhookRequest] = useState<WebhookCreateRequest>();
  const merchantStatus: string | undefined = useSelector(
    (state: IAppState) => state.merchantStatus
  );
  const [urlPreauthSaveRequest, setUrlPreAuthSaveRequest] =
    useState<PreauthSaveRequest>({
      preAuthorizationUrl: "",
      sandboxEnable: false,
      webhookSignature: "",
    });

  const [verifiedUrls, setVerifiedUrls] = useState<IValidUrl>({
    general: [{ url: "", status: "" }],
  });

  useEffect(() => {
    if (props.verifiedUrls === undefined) return;
    setLoading(false);
  }, [props.verifiedUrls]);

  useEffect(() => {
    if (props.loading === undefined || props.loading) return;
    history.goBack();
  }, [props.loading]);

  const handleCloseDialog = () => {
    setModalOpened(false);
    setLoading(true);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const handleSave = () => {
    setLoadingModal(true);
    if (locationPathname.includes("create")) {
      props.createWebhook(webhookRequest!);
    } else {
      props.updateWebhook(webhookRequest!, props.webhookSelected.id);
    }

    if (isPreauthEnable) {
      dispatch(saveUrlPreauth(urlPreauthSaveRequest, merchantId));
      dispatch(setPreauthEnable(false));
    }
  };

  const getSelectedOption = (urls: PaymentUrlWithStatus[]) => {
    if (urls.length === 0) return false;
    return urls.map((url) => url.service).indexOf("general") > -1;
  };

  const getDemoEditFormValues = (): TWebhookForm => {
    const locationPathname = location.pathname;

    if (locationPathname.includes("edit")) {
      const demoEditDynamo = { ...props.webhookSelected };

      return {
        alias: get(demoEditDynamo, "alias", ""),
        urls: [],
        urlWebhook: "",
        textValue: [],
        events: get(demoEditDynamo, "events", []),
        headers: get(demoEditDynamo, "headers", []),
        options: getOptions(get(demoEditDynamo, "events", [])),
        paymentUrls: getUrls(
          get(demoEditDynamo, "urls", []),
          get(demoEditDynamo, "status", [])
        ),
        headerItem: { label: "", value: "" },
        selectedOption: getSelectedOption(
          getUrls(
            get(demoEditDynamo, "urls", []),
            get(demoEditDynamo, "status", [])
          )
        ),
      };
    }

    return {
      urls: [],
      alias: "",
      urlWebhook: "",
      events: [],
      headers: [],
      options: [],
      paymentUrls: [],
      headerItem: { label: "", value: "" },
      selectedOption: true,
      textValue: [],
    };
  };

  const getUrls = (
    urlValues: string[],
    status: Status[]
  ): PaymentUrlWithStatus[] => {
    const response: PaymentUrlWithStatus[] = [];

    if (status.length > 0) {
      response.push({
        service: ServiceEnum.GENERAL,
        urls: status.map((item: Status) => ({
          url: item.url,
          status: urlValues.find((urlValue: string) => urlValue === item.url)
            ? item.status
            : StatusEnum.FAILED,
        })),
      });
    } else {
      response.push({
        service: ServiceEnum.GENERAL,
        urls: urlValues.map((url: string) => ({
          url,
          status: StatusEnum.FAILED,
        })),
      });
    }

    return response;
  };

  const getOptions = (events: WebhookEvent) => {
    const options: string[] = [];
    events.map((event) => {
      options.push(event.service!);
    });
    return [
      ...options.filter(
        (option: string, index: number) => options.indexOf(option) === index
      ),
    ];
  };

  const form = useForm<TWebhookForm>({
    defaultValues: {
      ...getDemoEditFormValues(),
    },
    mode: "onBlur",
  });

  const { register } = form;

  useEffect(() => {
    register("urls", { required: true });
    register("events", { required: true });
    register("headers", { required: true });
    register("options", { required: true });
    register("paymentUrls", { required: true });
    register("selectedOption");
  }, [register]);

  const handleSubmitForm: SubmitHandler<TWebhookForm> = async (formData) => {
    if (formData.events.length === 0) {
      dispatch(setShowError(true));
    } else {
      dispatch(setShowError(false));
      setModalOpened(true);
      setLoadingModal(false);
      setLoading(false);

      const reqForCreateWebHooks = transformFormDataBeforeSubmit(formData);
      setVerifiedUrls({
        general: reqForCreateWebHooks.status,
      });
      setWebhookRequest(reqForCreateWebHooks);

      if (isPreauthEnable) {
        const preauthSaveRequest: PreauthSaveRequest = {
          preAuthorizationUrl: formData.paymentUrls[0].urls[0].url,
          sandboxEnable: false,
          webhookSignature: "",
        };
        setUrlPreAuthSaveRequest(preauthSaveRequest);
      }
      setWebhookRequest(reqForCreateWebHooks);
    }
  };

  const hasAllChargebackEvents = (countCurrentEvents: number): boolean => {
    const allChargebackEvents =
      PAYMENT_METHODS_EVENTS[ServiceOptionEnum.CHARGEBACK];

    return Object.values(allChargebackEvents).length === countCurrentEvents;
  };

  const getCountCurrentChargebackEvents = (
    currentEvents: WebhookEvent
  ): number => {
    return currentEvents.filter(
      (event: { service?: string; status?: string }) => {
        if (event.status) {
          return (
            event.service === ServiceOptionEnum.CHARGEBACK &&
            CARD_CHARGEBACK[event.status] === event.status
          );
        }
        return;
      }
    ).length;
  };

  const hasSomeChargebackEvent = (countCurrentEvents: number): boolean => {
    return countCurrentEvents > 0;
  };

  const buildEventsChargeVoid = (currentEvents: WebhookEvent) =>
    currentEvents.map((event: { service?: string; status?: string }) => {
      if (event.service === ServiceOptionEnum.CHARGEBACK_VOID)
        return {
          service: ServiceOptionEnum.CHARGEBACK,
          status: event.status,
        };
      return event;
    });

  const transformEventChargeBack = (
    currentEvents: WebhookEvent = []
  ): WebhookEvent => {
    const countCurrentChargebackEvents: number =
      getCountCurrentChargebackEvents(currentEvents);

    const hasChargebackVoid: boolean =
      currentEvents.findIndex(
        (event: { service?: string }) =>
          event.service === ServiceOptionEnum.CHARGEBACK_VOID
      ) > -1;

    if (hasAllChargebackEvents(countCurrentChargebackEvents)) {
      currentEvents = currentEvents.filter(
        (event: { service?: string; status?: string }) =>
          event.service !== ServiceOptionEnum.CHARGEBACK
      );

      currentEvents = [
        ...currentEvents,
        {
          service: ServiceOptionEnum.CHARGEBACK,
          status: WebhookEventsEnum.chargeback,
        },
      ];
    } else if (hasSomeChargebackEvent(countCurrentChargebackEvents)) {
      currentEvents = currentEvents.filter(
        (event: { service?: string; status?: string }) =>
          WebhookEventsEnum.chargeback !== event.status
      );
    }

    if (hasChargebackVoid) {
      currentEvents = buildEventsChargeVoid(currentEvents);
    }
    return currentEvents;
  };

  const transformFormDataBeforeSubmit = (
    formData: UnpackNestedValue<TWebhookForm>
  ): WebhookCreateRequest => {
    return {
      alias: formData.alias,
      events: transformEventChargeBack(formData.events),
      urls: transformUrls(formData.paymentUrls),
      headers: formData.headers,
      status: formData.paymentUrls[0].urls,
      merchantId,
    };
  };

  const transformUrls = (paymentUrls: PaymentUrlWithStatus[]): string[] => {
    const urls: string[] = [];
    paymentUrls.forEach((payment: PaymentUrlWithStatus) => {
      payment.urls.forEach((item: Status) => urls.push(item.url));
    });
    return urls;
  };

  //breadcrumbs
  const [breadcrumbs, setBreadcrumbs] = useState([
    { label: "Comercios", url: "" },
  ]);
  const [lastItem, setLastItem] = useState<string>("Agregar Webhooks");

  useEffect(() => {
    const locationPathname = location.pathname;
    if (locationPathname.includes("edit")) {
      setLastItem("Editar Webhooks");
    } else {
      setLastItem("Agregar webhook");
    }
  }, []);

  useEffect(() => {
    let breadcrumbsTmp: { label: string; url: string }[];
    if (auth.isAdmin()) {
      const origin = get(auth.getAuthMerchant(), "origin", "");
      breadcrumbsTmp = [{ label: "Comercios", url: "/merchant-list" }];
      if (merchantStatus === "pending" || origin === "createMerchant") {
        breadcrumbsTmp.push({
          label: "Crear comercio",
          url: `/create-merchant/details?publicMerchantId=${merchantId}&hideSideBar=true`,
        });
      } else {
        breadcrumbsTmp.push(
          {
            label: `${get(merchantInfo, "name", " ")}`,
            url: `/config-merchant/merchant/${merchantId}`,
          },
          {
            label: "Desarrolladores",
            url: `/config-merchant/merchant/${merchantId}`,
          },
          { label: "Webhooks", url: `/webhook/${merchantId}` }
        );
      }
    } else {
      breadcrumbsTmp = [
        { label: "Desarrolladores", url: "/config-merchant/developers" },
        { label: "Webhooks", url: `/webhook/${merchantId}` },
      ];
    }
    setBreadcrumbs(breadcrumbsTmp);
  }, [merchantStatus]);

  //snackBar
  const [alertNotification, setAlertNotification] = useState<INotification>(
    props.notification
  );

  useEffect(() => {
    setAlertNotification(props.notification);
  }, [props.notification]);

  const handlerClose = (_event?: React.SyntheticEvent, reason?: string) => {
    props.setNotification({
      message: reason!,
      open: false,
      type: "error",
    });
  };

  return {
    form,
    actions: {
      handleSubmitForm,
      handleGoBack,
      handlerClose,
    },
    breadcrumbs: {
      lastItem,
      items: breadcrumbs,
    },
    formInfo: {
      title: lastItem,
    },
    modalTestUrl: {
      open: openModal,
      loading,
      verifiedUrls: verifiedUrls,
      handleCloseDialog,
      handleSave,
      loadingModal,
    },
    snackBar: alertNotification,
  };
};
