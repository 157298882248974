import React, { FC } from "react";
import BuildableForm, { FormItem } from "../../Forms/Generics/BuildableForm";
import { Grid, Typography } from "@mui/material";
import BoardMemberForm from "../../Forms/BoardMemberForm/BoardMemberForm";
import { Control, FieldValues } from "react-hook-form";

interface BoardMemberPhysicalFormProps {
  personalInformationPhysical: FormItem[];
  identityInformationPhysical: FormItem[];
}

interface BoardMemberPhysicalProps {
  handleSubmit: Function;
  personalInformationPhysical: FormItem[];
  identityInformationPhysical: FormItem[];
  reset: Function;
  closeModal: () => void;
  control?: Control<FieldValues, Object>;
}

export const BoardMemberPhysicalForm: FC<BoardMemberPhysicalFormProps> = ({
  personalInformationPhysical,
  identityInformationPhysical,
}: BoardMemberPhysicalFormProps) => (
  <>
    <BuildableForm formItems={personalInformationPhysical} />
    <Grid item xs={12} mt={4}>
      <Typography variant={"body2"} color={"text.dark"}>
        Información de identificación
      </Typography>
    </Grid>
    <BuildableForm formItems={identityInformationPhysical} />
  </>
);

export const BoardMemberPhysical: FC<BoardMemberPhysicalProps> = ({
  handleSubmit,
  personalInformationPhysical,
  identityInformationPhysical,
  reset,
  control,
  closeModal,
}: BoardMemberPhysicalProps) => (
  <BoardMemberForm
    data-testid="PhysicalBoardMemberForm"
    handleSubmit={handleSubmit}
    reset={reset}
    closeModal={closeModal}
    control={control}
    formNode={
      <BoardMemberPhysicalForm
        personalInformationPhysical={personalInformationPhysical}
        identityInformationPhysical={identityInformationPhysical}
      />
    }
  />
);
