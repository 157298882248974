import {
  IAccountPreferencesForm,
  IAccountPreferencesStore,
} from "../../interfaces/accountPreferences.interfaces";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getMerchantPreferences } from "../../thunks/app/app.thunks";

export const initialState: IAccountPreferencesStore = {
  accountPreferenceForm: {
    email: "",
    image: "",
    phoneNumber: "",
    primaryColor: "",
    secondaryColor: "",
    terms: "",
  },
  merchantPreferences: {
    colors: {
      primary: "#00E6B2",
      secondary: "#112B45",
    },
    contact: {
      areaCode: "",
      email: "",
      phoneNumber: "",
    },
    logos: [{ fileName: "", url: "" }],
    termsAndConditions: "",
  },
};

export const accountPreferencesSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getMerchantPreferences.fulfilled, (state, { payload }) => {
      state.merchantPreferences = payload;
    });
  },
  initialState,
  name: "accountPreference",
  reducers: {
    addAccountPreference: (
      state,
      { payload }: PayloadAction<IAccountPreferencesForm>
    ) => {
      state.accountPreferenceForm = payload;
    },
  },
});

export default accountPreferencesSlice.reducer;
