import React, { RefObject } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { KeyValue } from "../../../../types/key_value";
import { get } from "lodash";
import { Percent } from "react-feather";
import { FieldError, NestDataObject } from "react-hook-form";
import { RetentionMerchantConfigDynamo } from "../../../../types/retention_merchant_configuration";
import { useSelector } from "react-redux";
import { Catalog, Data } from "../../../../types/catalog";
import {
  catalogsDataToKeyValues,
  getDataFromCatalogByName,
} from "../../../utils/functions/Catalog";
import { IAppState } from "../../../store/reducer";
import { CatalogsNameEnum } from "../../../shared/infrastructure/Enums/CatalogsNameEnum";

const useStyles = makeStyles((theme: Theme) => ({
  labelDescription: {
    marginTop: theme.spacing(1),
    fontSize: "18px",
  },
  labelTitle: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  labelDescriptionButton: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  labelTitleStep: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  contentWidth: {
    maxWidth: "fit-content",
  },
  cardStyle: { backgroundColor: "#F7FAFC", border: "none" },
  subtitle2: {
    ...theme.typography.body1,
    fontSize: "16px",
    fontWeight: 400,
    color: "#6D7781",
  },
  content: {
    marginTop: 10,
    padding: 10,
  },
}));

export interface IFormUploadCardComponentProps {
  data: RetentionMerchantConfigDynamo | unknown;
  methods: {
    handleOnChangeSelection: (
      taxpayer: KeyValue | null,
      identifier: string
    ) => void;
    handleValueRetentionChange: (value: string, identifier: string) => void;
  };
  values: {
    taxpayerValue?: KeyValue | null;
    activityValue?: KeyValue | null;
    incomeSourceConceptValue?: KeyValue | null;
    ivaRetentionValue?: string;
    rentRetentionValue?: string;
  };
  register(
    obj: object
  ): ((instance: any) => void) | RefObject<any> | null | undefined;
  errors: NestDataObject<Record<string, any>, FieldError>;
}

export const FormUploadCardComponent: React.FC<IFormUploadCardComponentProps> = (
  props: IFormUploadCardComponentProps
) => {
  const classes = useStyles();
  const catalogsList: Catalog[] | undefined = useSelector(
    (state: IAppState) => state.catalogsList
  );
  const incomeSourceConceptTypes: Data[] = getDataFromCatalogByName(
    catalogsList,
    CatalogsNameEnum.INCOME_SOURCE_CONCEPT
  );
  const incomeSourceConceptTypesKeyValues: KeyValue[] = catalogsDataToKeyValues(
    incomeSourceConceptTypes
  );
  return (
    <Card>
      <CardContent>
        <Container>
          <Grid container spacing={3} direction="row">
            <Grid item xs={8}>
              <Box
                display="flex"
                flexGrow={1}
                alignItems={"center"}
                height={"100%"}
              >
                <Typography
                  className={classes.labelDescription}
                  variant="h5"
                  align="left"
                  color="inherit"
                  style={{ marginBottom: "10px" }}
                >
                  Completa los campos para editar las configuraciones
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={3} direction="row">
            <Grid item xs={10}>
              <Box pr={2}>
                <Autocomplete
                  disabled={true}
                  id="size-small-outlined"
                  size="small"
                  options={get(props, "taxpayerTypes", [])}
                  getOptionLabel={(option: any) => option.value}
                  value={props.values.taxpayerValue}
                  onChange={(_: any, newValue: KeyValue | null) => {
                    props.methods.handleOnChangeSelection(newValue, "0");
                  }}
                  defaultValue={get(props, "values.taxpayerValue", {})}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tipo de contribuyente"
                      name="taxpayerType"
                      variant="outlined"
                      inputRef={props.register({
                        required: "Debe ingresar el tipo de contribuyente.",
                      })}
                      error={!!props.errors.taxpayerType}
                      helperText={
                        props.errors.taxpayerType
                          ? props.errors.taxpayerType.message
                          : ""
                      }
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid container spacing={2} className={classes.content} xs={10}>
              <Grid item xs={6}>
                <Autocomplete
                  disabled={true}
                  id="size-small-outlined"
                  size="small"
                  options={get(props, "activity", [])}
                  getOptionLabel={(option: any) => option.value}
                  value={props.values.activityValue}
                  onChange={(_: any, newValue: KeyValue | null) => {
                    props.methods.handleOnChangeSelection(newValue, "1");
                  }}
                  defaultValue={get(props, "values.activityValue", {})}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Actividad"
                      name="activityType"
                      variant="outlined"
                      inputRef={props.register({
                        required: "Debe ingresar la actividad.",
                      })}
                      error={!!props.errors.activityType}
                      helperText={
                        props.errors.activityType
                          ? props.errors.activityType.message
                          : ""
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  disabled={true}
                  id="size-small-outlined"
                  size="small"
                  options={incomeSourceConceptTypesKeyValues}
                  getOptionLabel={(option: any) => option.value}
                  value={props.values.incomeSourceConceptValue}
                  onChange={(_: any, newValue: KeyValue | null) => {
                    props.methods.handleOnChangeSelection(newValue, "2");
                  }}
                  defaultValue={get(props, "values.incomeSourceConcept", {})}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Concepto retención en la renta"
                      name="incomeSourceConceptType"
                      variant="outlined"
                      inputRef={props.register({
                        required:
                          "Debe ingresar el concepto de retencion de la renta.",
                      })}
                      error={!!props.errors.incomeSourceConceptType}
                      helperText={
                        props.errors.incomeSourceConceptType
                          ? props.errors.incomeSourceConceptType.message
                          : ""
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.content} xs={10}>
              <Grid item xs={6}>
                <TextField
                  autoComplete="off"
                  id="input-edit"
                  fullWidth
                  name="ivaRetention"
                  label={"Retención IVA (%)"}
                  variant="outlined"
                  value={props.values.ivaRetentionValue}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    props.methods.handleValueRetentionChange(
                      event.target.value,
                      "0"
                    )
                  }
                  inputRef={props.register({
                    required:
                      "Debe ingresar el porcentaje de retención de iva.",
                  })}
                  error={!!props.errors.ivaRetention}
                  helperText={
                    props.errors.ivaRetention
                      ? props.errors.ivaRetention.message
                      : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" disabled={true}>
                          <Percent />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  autoComplete="off"
                  id="input-edit"
                  fullWidth
                  name="rentRetention"
                  label={"Retención Renta (%)"}
                  variant="outlined"
                  value={props.values.rentRetentionValue}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    props.methods.handleValueRetentionChange(
                      event.target.value,
                      "1"
                    )
                  }
                  inputRef={props.register({
                    required:
                      "Debe ingresar el porcentaje de retención de la renta.",
                  })}
                  error={!!props.errors.rentRetention}
                  helperText={
                    props.errors.rentRetention
                      ? props.errors.rentRetention.message
                      : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" disabled={true}>
                          <Percent />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </CardContent>
    </Card>
  );
};
