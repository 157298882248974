import { TransactionStatusEnum } from "../enums/TransactionStatusEnum";
import { TransactionTypeEnum } from "../enums/TransactionTypeEnum";
import { PaymentMethodsEnum } from "../infraestructure/payment-method";
import { ContentCard } from "../../components/CardInformation/CardInformation.interfaces";
import { CardSectionValueEnum } from "../enums/CardInformationEnum";

const getContentCardsByFilter = (
  sectionList: ContentCard[],
  filters: string[]
): ContentCard[] => {
  return sectionList.filter((section) =>
    filters.find((filter) => section.type === filter)
  );
};

export const catalogSectionListByTransaction = (
  sectionList: ContentCard[]
): Record<string, object> => {
  const sectionsWithoutAuthenticationData: ContentCard[] =
    getContentCardsByFilter(sectionList, [
      CardSectionValueEnum.TRANSACTION_DATA,
      CardSectionValueEnum.CARD_DATA,
      CardSectionValueEnum.AUTHORIZATION_DATA,
      CardSectionValueEnum.MERCHANT_DATA,
      CardSectionValueEnum.BILLING_DATA,
      CardSectionValueEnum.PAYMENT_CREDENTIAL_DATA,
    ]);
  const sectionsWithoutAuthenticationAndCardData: ContentCard[] =
    getContentCardsByFilter(sectionList, [
      CardSectionValueEnum.TRANSACTION_DATA,
      CardSectionValueEnum.AUTHORIZATION_DATA,
      CardSectionValueEnum.MERCHANT_DATA,
      CardSectionValueEnum.BILLING_DATA,
      CardSectionValueEnum.PAYMENT_CREDENTIAL_DATA,
    ]);
  const sectionsWithTransactionDataOnly: ContentCard[] =
    getContentCardsByFilter(sectionList, [
      CardSectionValueEnum.TRANSACTION_DATA,
    ]);

  const approval_state = {
    [TransactionTypeEnum.SALE]: {
      [PaymentMethodsEnum.CARD]: sectionsWithoutAuthenticationData,
      [PaymentMethodsEnum.CASH]: sectionsWithoutAuthenticationAndCardData,
      [PaymentMethodsEnum.TRANSFER]: sectionsWithoutAuthenticationAndCardData,
    },
    [TransactionTypeEnum.CAPTURE]: {
      [PaymentMethodsEnum.CARD]: sectionsWithoutAuthenticationData,
      [PaymentMethodsEnum.CASH]: [] as ContentCard[],
      [PaymentMethodsEnum.TRANSFER]: [] as ContentCard[],
    },
    [TransactionTypeEnum.DEFFERRED]: {
      [PaymentMethodsEnum.CARD]: sectionsWithoutAuthenticationData,
      [PaymentMethodsEnum.CASH]: [] as ContentCard[],
      [PaymentMethodsEnum.TRANSFER]: [] as ContentCard[],
    },
    [TransactionTypeEnum.PREAUTHORIZATION]: {
      [PaymentMethodsEnum.CARD]: sectionsWithoutAuthenticationData,
      [PaymentMethodsEnum.CASH]: [] as ContentCard[],
      [PaymentMethodsEnum.TRANSFER]: [] as ContentCard[],
    },
    [TransactionTypeEnum.VOID]: {
      [PaymentMethodsEnum.CARD]: sectionsWithTransactionDataOnly,
      [PaymentMethodsEnum.CASH]: sectionsWithTransactionDataOnly,
    },
    [TransactionTypeEnum.VOID_PARTIAL]: {
      [PaymentMethodsEnum.CARD]: sectionsWithTransactionDataOnly,
      [PaymentMethodsEnum.CASH]: sectionsWithTransactionDataOnly,
    },
    [TransactionTypeEnum.REFUND]: {
      [PaymentMethodsEnum.CARD]: sectionsWithTransactionDataOnly,
    },
    [TransactionTypeEnum.REFUND_PARTIAL]: {
      [PaymentMethodsEnum.CARD]: sectionsWithTransactionDataOnly,
    },
    [TransactionTypeEnum.REFUND_TOTAL]: {
      [PaymentMethodsEnum.CARD]: sectionsWithTransactionDataOnly,
    },
    [TransactionTypeEnum.CHARGEBACK]: {
      [PaymentMethodsEnum.CARD]: sectionsWithoutAuthenticationData,
    },
    [TransactionTypeEnum.REVERSE]: {
      [PaymentMethodsEnum.CARD]: sectionsWithTransactionDataOnly,
    },
  };

  const sectionByType: Record<string, object> = {
    [TransactionStatusEnum.APPROVAL]: approval_state,
    [TransactionStatusEnum.APPROVED]: approval_state,
    [TransactionStatusEnum.INITIALIZED]: {
      [TransactionTypeEnum.SALE]: {
        [PaymentMethodsEnum.CARD]: sectionsWithoutAuthenticationData,
        [PaymentMethodsEnum.CASH]: sectionsWithoutAuthenticationAndCardData,
        [PaymentMethodsEnum.TRANSFER]: sectionsWithoutAuthenticationAndCardData,
      },
      [TransactionTypeEnum.VOID]: {
        [PaymentMethodsEnum.CARD]: sectionsWithTransactionDataOnly,
        [PaymentMethodsEnum.CASH]: sectionsWithTransactionDataOnly,
      },
      [TransactionTypeEnum.VOID_PARTIAL]: {
        [PaymentMethodsEnum.CARD]: sectionsWithTransactionDataOnly,
        [PaymentMethodsEnum.CASH]: sectionsWithTransactionDataOnly,
      },
      [TransactionTypeEnum.REVERSE]: {
        [PaymentMethodsEnum.CARD]: sectionsWithTransactionDataOnly,
      },
    },
    [TransactionStatusEnum.DECLINED]: {
      [TransactionTypeEnum.SALE]: {
        [PaymentMethodsEnum.CARD]: sectionsWithoutAuthenticationData,
        [PaymentMethodsEnum.CASH]: sectionsWithoutAuthenticationAndCardData,
        [PaymentMethodsEnum.TRANSFER]: sectionsWithoutAuthenticationAndCardData,
      },
      [TransactionTypeEnum.VOID]: {
        [PaymentMethodsEnum.CARD]: sectionsWithTransactionDataOnly,
        [PaymentMethodsEnum.CASH]: sectionsWithTransactionDataOnly,
      },
      [TransactionTypeEnum.REFUND]: {
        [PaymentMethodsEnum.CARD]: sectionsWithTransactionDataOnly,
      },
      [TransactionTypeEnum.REFUND_PARTIAL]: {
        [PaymentMethodsEnum.CARD]: sectionsWithTransactionDataOnly,
      },
      [TransactionTypeEnum.REFUND_TOTAL]: {
        [PaymentMethodsEnum.CARD]: sectionsWithTransactionDataOnly,
      },
      [TransactionTypeEnum.DEFFERRED]: {
        [PaymentMethodsEnum.CARD]: sectionsWithoutAuthenticationData,
      },
      [TransactionTypeEnum.PREAUTHORIZATION]: {
        [PaymentMethodsEnum.CARD]: sectionsWithoutAuthenticationData,
      },
      [TransactionTypeEnum.REVERSE]: {
        [PaymentMethodsEnum.CARD]: sectionsWithTransactionDataOnly,
      },
    },
  };

  return sectionByType;
};
