import React from "react";
import { LabelEnum } from "../../../shared/infrastructure/enums/LabelEnum";
import { isEmpty } from "lodash";
import {
  CARD_ISSUER_VALUES,
  CARD_TYPE_VALUES,
  getBanksByCountry,
  getCurrencyByCountry,
  getNames,
  getValues,
  PAYMENT_BRANDS_OPTIONS,
  TRANSACTION_TYPES_TO_CONTAINS,
  YES_NO_VALUES,
} from "../../../shared/infrastructure/constants/AuthenticationRulesConstants";
import { IOptionSelection } from "../../../shared/infrastructure/constants/RuleRequestConstants";
import SelectFieldComponent from "./SelectFieldComponent";
import TextFieldComponent from "./TextFieldComponent";
import { getCountries } from "../../../shared/infrastructure/constants/CountryConstant";
import { AutocompleteFieldComponent } from "./AutoCompleteField";
import { ControllerRenderProps } from "react-hook-form";
import { TextFieldCardId } from "./TextFieldCardId";
import { OperatorsEnum } from "../../../shared/infrastructure/constants/OperatorsEnum";
import { Typography } from "@material-ui/core";

export interface BuildFieldProps {
  label: LabelEnum;
  properties: ControllerRenderProps;
  value: string;
  defaultValue: string;
  index: number;
  name: string;
  helperText: string | false | undefined;
  error: boolean;
  operator: string;
  countriesGeneral: string[];
  credentials: IOptionSelection[];
}
const getItems = (
  label: LabelEnum,
  type: "option" | "value",
  countriesGeneral?: string[],
  credentials?: IOptionSelection[]
): string[] | IOptionSelection[] => {
  switch (label) {
    case LabelEnum.TRANSACTION_TYPE:
      return getItemsByType(type, TRANSACTION_TYPES_TO_CONTAINS);
    case LabelEnum.BRAND:
      return getItemsByType(type, PAYMENT_BRANDS_OPTIONS);
    case LabelEnum.DEFERRED:
      return getItemsByType(type, YES_NO_VALUES);
    case LabelEnum.CARD_TYPE:
      return getItemsByType(type, CARD_TYPE_VALUES);
    case LabelEnum.CARD_ISSUER:
      return getItemsByType(type, CARD_ISSUER_VALUES);
    case LabelEnum.CREDENTIAL:
      return getItemsByType(type, credentials!);
    case LabelEnum.COUNTRY:
      if (type === "value") {
        return getNames(getCountries());
      }
      return getCountries();
    case LabelEnum.BANK:
      if (type === "value") {
        return getNames(getBanksByCountry(countriesGeneral!));
      }
      return getBanksByCountry(countriesGeneral!);
    case LabelEnum.CURRENCY:
      if (type === "value") {
        return getValues(getCurrencyByCountry(countriesGeneral!));
      }
      return getCurrencyByCountry(countriesGeneral!);
    default:
      return [];
  }
};
const getItemsByType = (
  type: "option" | "value",
  data: any[]
): string[] | IOptionSelection[] => {
  if (type === "option") return data;
  return getValues(data);
};
const isNotMultiple = (label: LabelEnum, operator: string) => {
  const operatorSlc: string = !isEmpty(operator) ? operator : "=";
  if (
    [LabelEnum.COUNTRY, LabelEnum.CURRENCY, LabelEnum.CREDENTIAL].includes(
      label
    )
  )
    return true;
  return !["=", "!="].includes(operatorSlc);
};
const getCompare = (label: LabelEnum) => {
  if ([LabelEnum.BANK, LabelEnum.COUNTRY].includes(label)) return "name";
  return "value";
};
export const BuildField: React.FC<BuildFieldProps> = (
  props: BuildFieldProps
) => {
  const {
    label,
    properties,
    value,
    defaultValue,
    index,
    name,
    helperText,
    error,
    operator,
    countriesGeneral,
    credentials,
  } = props;
  return (
    <React.Fragment>
      {[
        LabelEnum.TRANSACTION_TYPE,
        LabelEnum.PROCESSOR,
        LabelEnum.BRAND,
        LabelEnum.COUNTRY,
        LabelEnum.BANK,
        LabelEnum.CURRENCY,
        LabelEnum.CREDENTIAL,
      ].includes(label) && (
        <AutocompleteFieldComponent
          properties={properties}
          isMultiple={isNotMultiple(label, operator)}
          value={value}
          defaultValue={defaultValue}
          disabled={false}
          index={index}
          items={
            getItems(label, "value", countriesGeneral, credentials) as string[]
          }
          compare={getCompare(label)}
          path={"name"}
          nameValue={name}
          options={
            getItems(
              label,
              "option",
              countriesGeneral,
              credentials
            ) as IOptionSelection[]
          }
          helperText={helperText}
          error={error}
        />
      )}
      {[
        LabelEnum.DEFERRED,
        LabelEnum.CARD_TYPE,
        LabelEnum.CARD_ISSUER,
      ].includes(label) && (
        <SelectFieldComponent
          disabled={false}
          items={getItems(label, "option") as IOptionSelection[]}
          label={label}
          error={error}
          properties={properties}
          helperText={helperText}
        />
      )}
      {([
        LabelEnum.IP,
        LabelEnum.MASKED_CARD,
        LabelEnum.BIN,
        LabelEnum.AMOUNT,
      ].includes(label) ||
        !label) && (
        <TextFieldComponent
          disabled={false}
          error={error}
          properties={properties}
          helperText={helperText}
          label={label}
        />
      )}
      {[LabelEnum.KUSHKI_ID].includes(label) && (
        <>
          <TextFieldCardId
            disabled={false}
            error={error}
            properties={properties}
            helperText={helperText}
            operator={operator}
            label={label}
            index={index}
          />
          {(operator === OperatorsEnum.CONTAIN ||
            operator === OperatorsEnum.NOT_CONTAIN) && (
            <Typography variant={"body2"} style={{ color: "#6D7781" }}>
              Puedes ingresar más de un número seguido de una &quot;,&quot;
            </Typography>
          )}
        </>
      )}
    </React.Fragment>
  );
};
