import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      color: theme.palette.primary.dark,
      "&.MuiIconButton-root": {
        padding: 3,
        marginBottom: 12,
        marginTop: 0,
        marginRight: 0,
        marginLeft: 24,
      },
    },
    dialogTitle: {
      paddingTop: 16,
      paddingRight: 24,
      paddingBottom: 5,
      paddingLeft: 24,
    },
    dialogContentText: {
      marginBottom: 10,
    },
    dialogActions: {
      paddingRight: 24,
      paddingBottom: 12,
    },
    primaryColor: {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    errorColor: {
      color: theme.palette.error.main,
    },
    cancelAction: {
      color: theme.palette.grey["800"],
    },
  })
);

export default useStyles;
