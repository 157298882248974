import {
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Controller } from "react-hook-form";
import {
  SubTypeEnum,
  ServiceLabelEnum,
} from "../../../shared/infrastructure/enums/CreateRuleConstans";
import { isFormEditMode, retrieveFormError } from "../../../utils/utilsFile";
import React from "react";
import { ErrorsEnum } from "../../../shared/infrastructure/enums/ErrorEnum";
import { defaultTo } from "lodash";
import { useSubtypeState } from "./state/useSubtypeState";
import { NodesState } from "../../../shared/infrastructure/interfaces/NodesState";

export const SubtypeComponent: React.FC<NodesState> = (props: NodesState) => {
  const {
    control,
    errors,
    actions: { selectSubType },
    subtype,
    isUpdatedRule,
    id,
  } = useSubtypeState(props);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Controller
          name="subType"
          control={control}
          rules={{
            required: { message: ErrorsEnum.REQUIRED_FIELD, value: true },
          }}
          defaultValue={SubTypeEnum.COMMERCE}
          as={
            <RadioGroup row value={defaultTo(subtype, "")}>
              <FormControlLabel
                disabled={isUpdatedRule}
                key={SubTypeEnum.COMMERCE}
                value={SubTypeEnum.COMMERCE}
                control={
                  <Radio color={"primary"} disabled={isFormEditMode(id)} />
                }
                label={ServiceLabelEnum.COMMERCE}
                onChange={selectSubType}
              />
              <FormControlLabel
                disabled={isUpdatedRule}
                key={SubTypeEnum.COUNTRY}
                value={SubTypeEnum.COUNTRY}
                control={
                  <Radio color={"primary"} disabled={isFormEditMode(id)} />
                }
                label={ServiceLabelEnum.COUNTRY}
                onChange={selectSubType}
              />
            </RadioGroup>
          }
        />
        <FormHelperText error={retrieveFormError(errors, "subType")[0]}>
          {retrieveFormError(errors, "subType")[1]}
        </FormHelperText>
      </Grid>
    </Grid>
  );
};
