import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  createStyles,
  Divider,
  Grid,
  Link,
  Menu,
  Paper,
  Popover,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { KshSelectInput } from "../../common/KshSelectInput/KshSelectInput";
import { defaultTo, get } from "lodash";
import {
  IDefaultProcessors,
  IProcessorsType,
} from "../../../store/actionCreators";
import { MerchantProcessors } from "../../../../types/merchant_processors";
import { UpdateMerchantRulesRequest } from "../../../../types/update_merchant_rules_request";
import { ProcessorsTable } from "./ProcessorsTable";
import { ArrowDropDown, Close } from "@material-ui/icons";
import { AccordionPopupProcessorMenuPayIn } from "./PopupProcessorMenu/AccordionPopupProcessorMenuPayIn/AccordionPopupProcessorMenuPayIn";
import { AccordionPopupProcessorMenuPayOut } from "./PopupProcessorMenu/AccordionPopupProcessorMenuPayOut/AccordionPopupProcessorMenuPayOut";
import Notification from "../../common/Notification/Notification";
import { navigateWithMerchantInfoStorage } from "../../../shared/infrastructure/constants/CreateMerchantConstants";
import {
  CONFIG_DEFAULT_PROCESSOR,
  CONFIGURATION_LABELS,
  DISABLED_OPTION,
  PROCESSOR_TYPE_ID,
} from "../../../shared/infrastructure/constants/ProcessorsInformationConstant";
import { CountryEnum } from "../../../shared/infrastructure/catalogs/CountryEnum";
import { IDefaultProcessorOption } from "../../../shared/infrastructure/interfaces/IDefaultProcessorOption";
import { KeyValue } from "../../../../types/key_value";
import { ProcessorStatusEnum } from "../../../shared/infrastructure/catalogs/ProcessorStatusEnum";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(2, 2),
      marginBottom: 15,
    },
    font: {
      fontWeight: "initial",
    },
    link: {
      textDecoration: "underline",
    },
    omitButton: {
      color: "rgba(109, 119, 129, 1)",
    },
    stepIcon: {
      cursor: "pointer",
      backgroundColor: "#F0F4F9",
      borderRadius: "4px",
    },
    bgWhite: {
      background: "white",
    },
    marginTopBottom: {
      marginTop: 5,
      marginBottom: 15,
    },
    disabledBtn: {
      "&.mui-ripple-circle": { display: "none" },
      "&:hover": {
        background: theme.palette.primary.main,
      },
    },
    selectButton: {
      borderLeft: "1px solid #bdbdbd",
    },
    defaultProcessorContainer: {
      borderRadius: "5px",
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 4, 3),
    },
    bold: {
      fontWeight: "bold",
    },
    close: {
      cursor: "pointer",
      marginLeft: theme.spacing(6),
    },
    save: {
      fontFamily: "IBM Plex Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      border: `solid 1px ${theme.palette.primary.main}`,
      color: theme.palette.background.paper,
      padding: theme.spacing(1, 3),
      marginLeft: theme.spacing(3),
    },
  })
);

export type IProcessorsSummarySection = {
  to: string;
  loadingDefaultProcessors: boolean;
  publicMerchantId: string;
  defaultProcessors?: IDefaultProcessors;
  defaultPayoutProcessors?: IDefaultProcessors;
  processors?: IProcessorsType;
  loadingFailover: boolean;
  saveDefaultProcessors(payloadProcessors: UpdateMerchantRulesRequest): void;
  saveDefaultPayoutProcessors(
    payloadPayoutProcessors: UpdateMerchantRulesRequest
  ): void;
  saveFailoverProcessor(
    merchantId: string,
    publicProcessorId: string,
    failover: {
      failoverAlias: string;
    }
  ): void;
  country?: string;
};

export const ProcessorsSummarySection: React.FC<IProcessorsSummarySection> = (
  props: IProcessorsSummarySection
) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [
    anchorElProcessors,
    setAnchorElProcessors,
  ] = useState<null | HTMLElement>(null);
  const [typeAccordion, setTypeAccordion] = useState<string>("");
  const openPopover = Boolean(anchorEl);
  const idPopover = openPopover ? "simple-popover" : undefined;
  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClosePopover = () => {
    setDefaultProcessors(props.defaultProcessors);
    setDefaultPayoutProcessors(props.defaultPayoutProcessors);
    setAnchorEl(null);
  };
  const handleCloseElProcessors = (): void => {
    setAnchorElProcessors(null);
    setTypeAccordion("");
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorElProcessors(event.currentTarget);
  };
  const handleChangeActiveAccordion = (type: string): void => {
    setTypeAccordion(type);
  };
  const [defaultProcessors, setDefaultProcessors] = useState<
    IDefaultProcessors | undefined
  >(props.defaultProcessors);
  const [defaultPayoutProcessors, setDefaultPayoutProcessors] = useState<
    IDefaultProcessors | undefined
  >(props.defaultPayoutProcessors);
  useEffect(() => {
    if (!props.defaultProcessors || !props.defaultPayoutProcessors) return;

    setDefaultProcessors(props.defaultProcessors);
    setDefaultPayoutProcessors(props.defaultPayoutProcessors);
  }, [props.defaultProcessors, props.defaultPayoutProcessors]);
  const handleChangeProcessors = (value: string, prop: string) => {
    const processor: MerchantProcessors | undefined = get(
      props,
      "processors.transferPayout",
      []
    ).find((processor: MerchantProcessors) => processor.alias === value);
    if (prop === PROCESSOR_TYPE_ID.TRANSFER_OUT) {
      setDefaultPayoutProcessors({
        ...defaultProcessors!,
        payoutsTransfer: processor?.publicProcessorId,
      });
    }

    setDefaultProcessors({
      ...defaultProcessors!,
      [prop]: value,
    });
  };
  const saveProcessors = () => {
    const processor: MerchantProcessors | undefined = get(
      props,
      "processors.transferPayout",
      []
    ).find(
      (processor: MerchantProcessors) =>
        processor.alias === defaultProcessors?.transferPayout
    );

    const requestDefaultPayoutsTransferProcessor: UpdateMerchantRulesRequest = {
      defaultProcessor: {
        payoutsTransfer: get(
          processor,
          "publicProcessorId",
          defaultPayoutProcessors?.payoutsTransfer
        ),
      },
      publicMerchantId: props.publicMerchantId,
    };

    const requestProcessor: UpdateMerchantRulesRequest = {
      defaultProcessor: {
        card: defaultProcessors?.card,
        transfer: defaultProcessors?.transfer,
        achTransfer: defaultProcessors?.ach,
        payoutsTransfer: defaultProcessors?.transferPayout,
      },
      publicMerchantId: props.publicMerchantId,
    };

    props.saveDefaultProcessors(requestProcessor);
    props.saveDefaultPayoutProcessors(requestDefaultPayoutsTransferProcessor);
    setAnchorEl(null);
  };

  const showNotification = () => {
    const _sendMsg =
      "Recuerda configurar el procesador por defecto y asignar Failover a los procesadores agregados.";
    if (
      showAlertCard() ||
      showAlertTransferAndAch(
        props.defaultProcessors?.transfer,
        props.processors?.transfer
      ) ||
      showAlertTransferAndAch(
        props.defaultProcessors?.ach,
        props.processors?.ach
      )
    ) {
      return <Notification msg={_sendMsg} />;
    } else {
      return null;
    }
  };

  const showAlertTransferAndAch = (
    defaultProcessor: string | undefined,
    processorType: MerchantProcessors[] | undefined
  ): boolean => {
    if (!!processorType?.length) {
      return !verifyDefaultProcessor(defaultProcessor, processorType);
    }
    return false;
  };

  const showAlertCard = (): boolean => {
    if (props.processors?.card.length && props.processors?.card.length >= 2) {
      if (
        (verifyDefaultProcessor(
          props.defaultProcessors?.card,
          props.processors?.card
        ) &&
          !verifyFailOver()) ||
        (verifyFailOver() &&
          !verifyDefaultProcessor(
            props.defaultProcessors?.card,
            props.processors?.card
          )) ||
        (!verifyFailOver() &&
          !verifyDefaultProcessor(
            props.defaultProcessors?.card,
            props.processors?.card
          ))
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const verifyDefaultProcessor = (
    defaultProcessor: string | undefined,
    processorType: MerchantProcessors[] | undefined
  ) => {
    if (defaultProcessor?.length && processorType) {
      return processorType.some(
        (processor) => processor.alias === defaultProcessor
      );
    } else {
      return false;
    }
  };

  const verifyFailOver = (): boolean | undefined => {
    if (!props.processors?.card || props.processors?.card.length <= 0)
      return true;
    if (
      props.processors?.card.filter(
        (row: MerchantProcessors) =>
          row.processorName !== props.processors?.card[0].processorName
      ).length > 0
    ) {
      return props.processors?.card.every(
        (processor) => processor.failOverProcessor?.length
      );
    } else {
      return true;
    }
  };

  const getDefaultProcessorOptions = (
    country: string
  ): IDefaultProcessorOption[] => {
    if (country === CountryEnum.COLOMBIA) {
      return CONFIGURATION_LABELS;
    }
    return CONFIGURATION_LABELS.filter(
      (value: IDefaultProcessorOption) => value.id !== PROCESSOR_TYPE_ID.ACH
    );
  };

  const getDefaultProcessor = (processorOption: string): string => {
    const processor: MerchantProcessors | undefined = get(
      props,
      "processors.transferPayout",
      []
    ).find(
      (processor: MerchantProcessors) =>
        processor.publicProcessorId ===
        get(defaultPayoutProcessors, "payoutsTransfer")
    );

    if (processorOption === PROCESSOR_TYPE_ID.TRANSFER_OUT)
      return defaultTo(
        get(processor, "alias", ""),
        get(defaultProcessors, `[${processorOption}]`, "")
      );
    return get(defaultProcessors, `[${processorOption}]`, "");
  };

  return (
    <Grid container component={Paper} elevation={0} className={classes.paper}>
      <Grid item xs={12}>
        <Box display="flex">
          <Box flexGrow={1} p={1}>
            <Typography
              variant="body1"
              color="textPrimary"
              className={classes.font}
            >
              Procesadores agregados
            </Typography>
          </Box>
          <Box pr={1}>
            <Button
              id="button_popover"
              aria-describedby={idPopover}
              className={classes.bgWhite}
              variant="outlined"
              onClick={handleOpenPopover}
              disabled={props.loadingDefaultProcessors}
            >
              Configurar proc. defecto{" "}
              {props.loadingDefaultProcessors && (
                <CircularProgress
                  style={{ marginLeft: 5 }}
                  color="inherit"
                  size={20}
                />
              )}
            </Button>
            <Popover
              id={idPopover}
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Box className={classes.defaultProcessorContainer}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography
                    variant="h6"
                    color="primary"
                    className={classes.bold}
                  >
                    {CONFIG_DEFAULT_PROCESSOR.HEADER}
                  </Typography>
                  <Close
                    className={classes.close}
                    onClick={handleClosePopover}
                  />
                </Box>
                <Box mt={5}>
                  {getDefaultProcessorOptions(defaultTo(props.country, "")).map(
                    (processorOption: IDefaultProcessorOption) => (
                      <KshSelectInput
                        key={processorOption.id}
                        id={processorOption.id}
                        label={processorOption.label}
                        disableValue={DISABLED_OPTION}
                        value={getDefaultProcessor(processorOption.id)}
                        options={get(
                          props,
                          `processors.${processorOption.id}`,
                          []
                        ).reduce(
                          (
                            processors: KeyValue[],
                            processor: MerchantProcessors
                          ) => {
                            if (
                              processor.status !== ProcessorStatusEnum.DISABLED
                            ) {
                              processors.push({
                                key: processor.alias,
                                value: processor.alias,
                              });
                            }
                            return processors;
                          },
                          []
                        )}
                        onChange={(e) =>
                          handleChangeProcessors(
                            e.target.value as string,
                            processorOption.id
                          )
                        }
                        isDefaultProcessor={true}
                      />
                    )
                  )}
                </Box>

                <Box display={"flex"} justifyContent={"flex-end"} mt={6}>
                  <Button
                    id="button_cancel"
                    onClick={handleClosePopover}
                    className={classes.bold}
                  >
                    {CONFIG_DEFAULT_PROCESSOR.CANCEL}
                  </Button>
                  <Button
                    id="button_save"
                    color="primary"
                    variant="contained"
                    onClick={saveProcessors}
                    className={classes.save}
                  >
                    {CONFIG_DEFAULT_PROCESSOR.CONFIGURE}
                  </Button>
                </Box>
              </Box>
            </Popover>
          </Box>
          <Box>
            <ButtonGroup variant="contained" color="primary">
              <Button
                disableElevation
                className={classes.disabledBtn}
                disableTouchRipple
                disableRipple
                disableFocusRipple
              >
                {"Agregar procesador"}
              </Button>
              <Button
                id={"openMenu"}
                color="primary"
                size="small"
                onClick={handleClick}
                className={classes.selectButton}
              >
                <ArrowDropDown />
              </Button>
            </ButtonGroup>
            <Menu
              id="processors-menu"
              anchorEl={anchorElProcessors}
              open={Boolean(anchorElProcessors)}
              onClose={handleCloseElProcessors}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              elevation={1}
              getContentAnchorEl={null}
            >
              <AccordionPopupProcessorMenuPayIn
                id={get(props, "publicMerchantId", "")}
                country={get(props, "country", "")}
                setActivePayIn={(type: string) =>
                  handleChangeActiveAccordion(type)
                }
                expanded={typeAccordion === "payin"}
              />
              <AccordionPopupProcessorMenuPayOut
                id={get(props, "publicMerchantId", "")}
                country={get(props, "country", "")}
                setActivePayOut={(type: string) =>
                  handleChangeActiveAccordion(type)
                }
                expanded={typeAccordion === "payout"}
              />
            </Menu>
          </Box>
        </Box>
        <Divider className={classes.marginTopBottom} />

        <ProcessorsTable
          defaultProcessors={props.defaultProcessors}
          defaultPayoutProcessor={props.defaultPayoutProcessors}
          processors={props.processors}
          saveFailoverProcessor={props.saveFailoverProcessor}
          loadingFailover={props.loadingFailover}
          merchantId={props.publicMerchantId}
          country={props.country}
        />
        <Link
          style={{ cursor: "pointer" }}
          onClick={() => navigateWithMerchantInfoStorage(props.to!)}
          color="inherit"
          className={classes.link}
        >
          Edita y revisa todos los procesadores
        </Link>
        {showNotification()}
      </Grid>
    </Grid>
  );
};
