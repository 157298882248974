import { createAsyncThunk } from "@reduxjs/toolkit";
import { THUNK_PREFIXES } from "../../../shared/constants/thunk_prefixes";
import {
  GetMerchantsRequest,
  IActivateUser,
  ICreateUserRequest,
  IEditUserRequest,
  IMerchantsResponse,
} from "../../interfaces/userForm.interfaces";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import {
  cloneDeep,
  defaultTo,
  get,
  isEmpty,
  isEqual,
  omit,
  unset,
} from "lodash";
import { SearchMerchantNodeRequest } from "../../../../types/search_merchant_node_request";
import { SearchMerchantNodeResponse } from "../../../../types/search_merchant_node_response";
import { UserForm } from "../../../../types/user_form";
import { buildNotification } from "../../../shared/constants/snackbar";
import { NotificationTypeEnum } from "../../../shared/enum/SnackbarEnum";
import { SemaphoreData } from "../../../../types/remote/semaphore_data";
import { default as axiosLib, AxiosResponse, CancelTokenSource } from "axios";
import { HttpStatusEnum } from "../../../shared/enum/statusConfigEnum";
import { StatusTypeEnum } from "../../../shared/enum/InputTypeEnum";
import {
  INITIAL_USER_STORE,
  setStateLoading,
} from "../../reducers/userForm/userForm.slice";
import {
  setNeedRedirect,
  setNotification,
} from "../../actions/generalData.actions";
import { QueryParamEnum } from "../../../shared/enum/QueryParamEnum";
import {
  IUserListQueryString,
  IUserListResponse,
} from "../../interfaces/users.interfaces";
import { getJwtAuth } from "../../../shared/utils/getJwtAuth_utils";
import { parseUserList } from "../../../shared/utils/BuildUsersData";
import { IUserList } from "../../../shared/interfaces/IUseUsersContainerState";
import { ErrorMessageEnum } from "../../../shared/enum/error_messages_form";
import { ROUTES } from "../../../shared/constants/routes";

function verifySupportSession(): boolean {
  const payload: object = JSON.parse(
    defaultTo(localStorage.getItem("payload"), "{}")
  );

  return get(payload, "isVerify", false);
}
export const validateUsername = createAsyncThunk<boolean, string>(
  THUNK_PREFIXES.VALIDATE_AVAILABLE_USERNAME,
  async (payload: string) => {
    const response = await axios.post<{ available: boolean }>(
      API_ROUTES.AVAILABLE_USERNAME,
      {
        username: payload,
      }
    );

    return response.data.available;
  }
);
let cancelToken: CancelTokenSource | null = null;

export const getMerchants = createAsyncThunk<
  IMerchantsResponse,
  GetMerchantsRequest
>(THUNK_PREFIXES.GET_MERCHANTS, async (payload: GetMerchantsRequest) => {
  if (cancelToken) {
    cancelToken.cancel("Nueva petición, cancelando la antigua");
  }
  cancelToken = axiosLib.CancelToken.source();
  if (isEmpty(payload.text)) unset(payload, "test");
  const body: { limit: number; offset: number; text?: string } = {
    ...payload,
    limit: 15,
  };

  const response = await axios.post(API_ROUTES.GET_MERCHANTS_ANALYTICS, body, {
    cancelToken: cancelToken.token,
  });

  return response.data;
});

export const getSearchMerchantsInfo = createAsyncThunk<
  SearchMerchantNodeResponse,
  SearchMerchantNodeRequest
>(
  THUNK_PREFIXES.GET_SEARCH_MERCHANT_INFO,
  async (payload: SearchMerchantNodeRequest) => {
    const response = await axios.post<SearchMerchantNodeResponse>(
      API_ROUTES.SEARCH_MERCHANT,
      payload
    );

    return response.data;
  }
);

export const findUser = createAsyncThunk<UserForm, IUserListQueryString>(
  THUNK_PREFIXES.FIND_USER,
  async (payload: IUserListQueryString) => {
    const response = await axios.get<IUserListResponse>(API_ROUTES.GET_USERS, {
      headers: {
        Authorization: getJwtAuth(),
        "Content-Type": "application/json",
      },
      params: payload,
    });

    const usersList: IUserList[] = parseUserList(response.data.Users);

    const user: IUserList | undefined = usersList.find((user) =>
      isEqual(user.userName, payload.username)
    );

    const finalUser: UserForm = {
      ...defaultTo(user, INITIAL_USER_STORE),
      group: get(user, "roles", []),
    };

    return omit(finalUser, "roles") as UserForm;
  }
);

export const updateStepUsersStatus = createAsyncThunk<
  void,
  { publicMerchantId: string; userForm: UserForm }
>(
  THUNK_PREFIXES.UPDATE_STEP_USER_STATUS,
  async (
    payload: { publicMerchantId: string; userForm: UserForm },
    { dispatch }
  ) => {
    const semaphore: SemaphoreData = {
      isNew: false,
      publicMerchantId: payload.publicMerchantId,
      stepUsers: {
        status: "complete",
      },
    };

    axios
      .post(API_ROUTES.SEMAPHORE_CREATE_UPDATE, {
        ...semaphore,
      })
      .then(() => {
        dispatch(
          setStateLoading({ isLoading: false, status: StatusTypeEnum.OK })
        );
        dispatch(
          setNotification(
            buildNotification(NotificationTypeEnum.SUCCESS, {
              color: "success",
              message: "El usuario ha sido creado con éxito",
              variant: "simple",
              withIcon: false,
            })
          )
        );
      })
      .catch(() => {
        let message_error: string = ErrorMessageEnum.ERROR_SAVE_SEMAPHORE;

        if (verifySupportSession())
          message_error = ErrorMessageEnum.ERROR_WITH_SUPPORT_SESSION;

        dispatch(
          setStateLoading({ isLoading: false, status: StatusTypeEnum.FAIL })
        );
        dispatch(
          setNotification(
            buildNotification(NotificationTypeEnum.FAILED, {
              color: "danger",
              message: message_error,
              variant: "simple",
              withIcon: false,
            })
          )
        );
      });
  }
);
export const activatedMerchantNode = createAsyncThunk<void, IActivateUser>(
  THUNK_PREFIXES.ACTIVATE_MERCHANT_NODE,
  async (payload: IActivateUser, { dispatch }) => {
    try {
      if (payload.activatedNode) {
        let redirect_path: string = payload.redirectPath.path as string;
        let statusResponse: AxiosResponse<any>;

        if (!payload.isCustomer) {
          statusResponse = await axios.post(
            API_ROUTES.STATUS,
            payload.activateMerchantRequest.status
          );
        }

        const was_activated: boolean =
          defaultTo(statusResponse!.status, "") === HttpStatusEnum.OK;

        const isCallConfig: boolean = payload.isCustomer ? true : was_activated;

        if (isCallConfig) {
          await axios.put(
            API_ROUTES.CONFIG,
            payload.activateMerchantRequest.config
          );
        }

        if (!payload.isCustomer && was_activated) {
          redirect_path = `${payload.redirectPath.path}&${QueryParamEnum.ACTIVE_CLIENT}=true`;
        }

        dispatch(
          setNotification(
            buildNotification(NotificationTypeEnum.SUCCESS, {
              color: "success",
              message: "El usuario ha sido creado con éxito",
              variant: "simple",
              withIcon: false,
            })
          )
        );
        dispatch(
          setNeedRedirect({
            ...payload.redirectPath,
            path: redirect_path,
          })
        );
        dispatch(setStateLoading({ isLoading: false, status: "OK" }));
      } else {
        dispatch(setNeedRedirect(payload.redirectPath));
      }
    } catch (e) {
      dispatch(
        setStateLoading({ isLoading: false, status: StatusTypeEnum.OK })
      );
      dispatch(
        setNotification(
          buildNotification(NotificationTypeEnum.FAILED, {
            color: "danger",
            message: "No se pudo cambiar el estado de la Branch",
            variant: "simple",
            withIcon: false,
          })
        )
      );
    }
  }
);
export const createUser = createAsyncThunk<void, ICreateUserRequest>(
  THUNK_PREFIXES.CREATE_USER,
  async (payload: ICreateUserRequest, { dispatch }) => {
    axios
      .post<object>(
        API_ROUTES.CREATE_EDIT_USER,
        omit(payload.userForm, "isUpdateSemaphore")
      )
      .then((response: AxiosResponse<object>) => {
        if (response.status === HttpStatusEnum.OK) {
          if (
            get(payload.userForm, "isUpdateSemaphore", false) &&
            payload.userForm.publicMerchantId &&
            !payload.activateUser.isConsole3
          ) {
            dispatch(
              updateStepUsersStatus({
                publicMerchantId: payload.userForm.publicMerchantId,
                userForm: cloneDeep(payload.userForm),
              })
            );
          } else {
            if (!payload.activateUser.activatedNode) {
              dispatch(
                setStateLoading({ isLoading: false, status: StatusTypeEnum.OK })
              );
              dispatch(
                setNotification(
                  buildNotification(NotificationTypeEnum.SUCCESS, {
                    color: "success",
                    message: "El usuario ha sido creado con éxito",
                    variant: "simple",
                    withIcon: false,
                  })
                )
              );
            }
          }
          dispatch(activatedMerchantNode(payload.activateUser));
        }
      })
      .catch(() => {
        let message_error: string = ErrorMessageEnum.ERROR_SAVE_USER;

        if (verifySupportSession())
          message_error = ErrorMessageEnum.ERROR_WITH_SUPPORT_SESSION;

        dispatch(
          setStateLoading({ isLoading: false, status: StatusTypeEnum.FAIL })
        );
        dispatch(
          setNotification(
            buildNotification(NotificationTypeEnum.FAILED, {
              color: "danger",
              message: message_error,
              variant: "simple",
              withIcon: false,
            })
          )
        );
      });
  }
);

export const editUser = createAsyncThunk<void, IEditUserRequest>(
  THUNK_PREFIXES.EDIT_USER,
  (payload, { dispatch }) => {
    axios
      .patch<object>(API_ROUTES.CREATE_EDIT_USER, payload.userForm)
      .then((response: AxiosResponse<object>) => {
        if (response.status === 200) {
          dispatch(
            setStateLoading({ isLoading: false, status: StatusTypeEnum.OK })
          );
          dispatch(
            setNotification(
              buildNotification(NotificationTypeEnum.SUCCESS, {
                color: "success",
                message: "El usuario ha sido editado con éxito",
                variant: "simple",
                withIcon: false,
              })
            )
          );
          dispatch(setNeedRedirect({ path: ROUTES.USERS, withQuery: true }));
        }
      })
      .catch(() => {
        let message_error: string = ErrorMessageEnum.ERROR_SAVE_USER;

        if (verifySupportSession())
          message_error = ErrorMessageEnum.ERROR_WITH_SUPPORT_SESSION;

        dispatch(
          setStateLoading({ isLoading: false, status: StatusTypeEnum.FAIL })
        );
        dispatch(
          setNotification(
            buildNotification(NotificationTypeEnum.FAILED, {
              color: "danger",
              message: message_error,
              variant: "simple",
              withIcon: false,
            })
          )
        );
      });
  }
);
