import React, { FC } from "react";
import {
  CANCEL_TEXT,
  MODAL_ACTIVE_FILTER,
  YES_CONTINUE_TEXT,
} from "../../shared/constants/labels/buttons_labels";
import { ModalConfirm } from "../ModalConfirm/ModalConfirm";
import { IModalActiveFilter } from "./ModalActiveFilter.interfaces";

const ModalActiveFilter: FC<IModalActiveFilter> = ({
  openModal,
  setOpenModal,
  handleNextAction,
}: IModalActiveFilter) => {
  return (
    <>
      <ModalConfirm
        onClickPrimary={() => {
          setOpenModal(false);
          handleNextAction();
        }}
        onClickSecondary={() => setOpenModal(false)}
        buttonPrimary={YES_CONTINUE_TEXT}
        buttonSecondary={CANCEL_TEXT}
        descriptionText={MODAL_ACTIVE_FILTER.DESCRIPTION}
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        titleText={MODAL_ACTIVE_FILTER.TITLE}
      />
    </>
  );
};

export default ModalActiveFilter;
