import { Alert } from "@kushki/connect-ui";
import { Box } from "@mui/material";
import { CustomStyles } from "../../shared/infrastructure/interfaces/CustomStyles";

const styles: CustomStyles = {
  alertText: {
    "& .MuiBox-root p": {
      fontWeight: 500,
    },
  },
};

export const enum MissingInfoAlertEnum {
  TITLE = "Falta completar información obligatoria.",
  MISSING_USER = "Deberás crear tu primer branch a través del botón “Confirmar y continuar” para configurar usuarios.",
}

export const MissingInfoAlert = () => {
  return (
    <Box pb={2} sx={styles.alertText}>
      <Alert
        buttonText=""
        description=""
        title={MissingInfoAlertEnum.TITLE}
        type="error"
      />
    </Box>
  );
};
