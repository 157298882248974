import { useSnackBarAlertState } from "./state/useSnackBarAlertState";
import { SnackbarAlertProps } from "./SnackBarAlert.interfaces";
import { snackbarStyles } from "./Snackbar.style";
import { IconButton, Snackbar, Theme } from "@mui/material";
import { Alert } from "@mui/lab";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { useDispatch, useSelector } from "react-redux";
import {
  openErrorSnackbar,
  openSuccessSnackbar,
  selectConciliationAction,
} from "../../../store/selectors/app/app";
import CheckmarkIcon from "../../../assets/icons/CheckmarkIcon";
import SlashIcon from "../../../assets/icons/SlashIcon";
import { SxProps } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { hideSnackbar } from "../../../store/actions/app/app";
import { getMessageConciliationAction } from "../../../shared/utils/get_message";

export const SnackBarAlert: (props: SnackbarAlertProps) => JSX.Element = (
  props: SnackbarAlertProps
) => {
  const dispatch = useDispatch();
  const { open } = useSnackBarAlertState(props);
  let openSuccess = useSelector(openSuccessSnackbar);
  let openError = useSelector(openErrorSnackbar);
  let conciliationAction = useSelector(selectConciliationAction);

  const customSnackbarStyle =
    props.type === "error"
      ? snackbarStyles.downloadErrorSnackbar
      : snackbarStyles.downloadSuccessSnackbar;

  const message = getMessageConciliationAction(conciliationAction, openSuccess);

  const icon: JSX.Element = openSuccess ? <CheckmarkIcon /> : <SlashIcon />;
  const style: SxProps<Theme> = openSuccess
    ? snackbarStyles.successSnackbar
    : snackbarStyles.errorSnackbar;

  const handleClose = () => {
    dispatch(hideSnackbar());
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        color="inherit"
        aria-label="close"
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    </React.Fragment>
  );

  return props.open ? (
    <Snackbar
      anchorOrigin={{
        horizontal: "right",
        vertical: props.position,
      }}
      open={open}
      autoHideDuration={4000}
      onClose={props.handlerClose}
    >
      <Alert
        elevation={6}
        icon={
          props.type === "success" ? (
            false
          ) : (
            <DoNotDisturbIcon sx={{ color: "white" }} />
          )
        }
        onClose={props.handlerClose}
        sx={customSnackbarStyle}
      >
        {props.msg}
      </Alert>
    </Snackbar>
  ) : openError || openSuccess ? (
    <Snackbar
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
      open={openSuccess || openError}
      transitionDuration={1e3}
      sx={style}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        elevation={6}
        variant="filled"
        sx={style}
        icon={icon}
        action={openSuccess ? false : action}
      >
        {message}
      </Alert>
    </Snackbar>
  ) : (
    <></>
  );
};
