import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export interface RetentionAmountsInfoCardProps {
  initialAmountRetentionIva: number;
  initialAmountRetentionFue: number;
  initialTotalAmount: number;
  currency?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    bold: {
      fontWeight: 600,
    },
    marginDetail: {
      marginLeft: "0.5rem",
    },
    bottom: {
      marginBottom: "1rem",
      fontWeight: 600,
    },
  })
);

export const RetentionAmountsInfoCard: React.FC<RetentionAmountsInfoCardProps> = (
  _props: React.PropsWithChildren<RetentionAmountsInfoCardProps>
) => {
  const style = useStyles();
  return (
    <Grid container spacing={3} direction={"row"}>
      <Grid item xs={12}>
        <Typography variant={"h6"} className={style.bold}>
          Montos iniciales:
        </Typography>
      </Grid>
      <Grid item xs={6} className={style.marginDetail}>
        <Typography>Monto ret. IVA</Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography>
          {_props.initialAmountRetentionIva} {_props.currency}
        </Typography>
      </Grid>
      <Grid item xs={6} className={style.marginDetail}>
        <Typography>Monto ret. Renta</Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography>
          {_props.initialAmountRetentionFue} {_props.currency}
        </Typography>
      </Grid>
      <Grid item xs={6} className={style.marginDetail}>
        <Typography className={style.bottom}>Monto Total</Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography className={style.bottom}>
          {_props.initialTotalAmount} {_props.currency}
        </Typography>
      </Grid>
    </Grid>
  );
};
