import React, { FC } from "react";
import {
  CardInformationProps,
  ContentCard,
  ContentTaxes,
  ICardTabsProps,
  ICardTabsTaxProps,
} from "./CardInformation.interfaces";
import { Box, IconButton, Typography } from "@mui/material";
import { CardInformationStyles as styles } from "./CardInformation.styles";
import { Copy } from "react-feather";
import { clipboard } from "../../shared/clipboard";
import { get } from "lodash";
import { renderSectionFieldListByTransaction } from "../../shared/utils/general_utils";

export const CardInformation: FC<CardInformationProps> = (
  props: CardInformationProps
) => {
  const { data, taxSection, columnDirection } = props;

  return (
    <>
      {data.map((item: ContentCard, index: number) => {
        const newContentCard = renderSectionFieldListByTransaction(
          get(props, "transactionInfo", {}),
          item
        );

        return (
          <CardTabs
            key={`k-${index}`}
            items={newContentCard.body}
            orientation="horizontal"
            title={newContentCard.title}
            columnDirection={columnDirection}
          />
        );
      })}
      {taxSection && <CardTax key={"taxes"} data={taxSection} />}
    </>
  );
};

const CardTabs: FC<ICardTabsProps> = (props: ICardTabsProps) => {
  return (
    <Box sx={styles.mainBox}>
      {props.title && (
        <Typography variant={"h6"} color={"text.dark"} sx={styles.title}>
          {props.title}
        </Typography>
      )}

      <Box>
        {props.items.map((item, index) => {
          return (
            <Box sx={styles.descriptionVertical} key={index}>
              {item.label && (
                <Typography
                  color={"text.grey"}
                  sx={styles.descriptionLabelVertical}
                >
                  {item.label}
                  {":"}
                </Typography>
              )}

              {!item.isArray ? (
                <>
                  {item.text && (
                    <Typography
                      color={"text.dark"}
                      variant={"caption"}
                      sx={styles.descriptionTextVertical}
                    >
                      {item.text}
                    </Typography>
                  )}
                </>
              ) : (
                <Box sx={styles.itemsText}>
                  {item.itemText!.map((payment: string) => {
                    return (
                      <Typography
                        key={payment}
                        color={"text.dark"}
                        variant={"caption"}
                        sx={styles.descriptionTextVertical}
                      >
                        {payment}
                      </Typography>
                    );
                  })}
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export const CardTax: FC<ICardTabsTaxProps> = (props: ICardTabsTaxProps) => {
  return (
    <>
      {props.data.map((item: ContentTaxes, index: number) => (
        <Box sx={styles.mainBox} key={`box-${index}`}>
          <Box sx={styles.boxCopy}>
            <IconButton
              data-testid={"IconCopy"}
              sx={styles.iconColor}
              onClick={(e) => {
                clipboard(get(item, "body", ""));
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Copy />
            </IconButton>
          </Box>

          {item.title && (
            <Typography
              variant={"h6"}
              color={"text.dark"}
              sx={styles.titleTaxes}
            >
              {item.title}
            </Typography>
          )}

          {item.body && <Typography sx={styles.tax}>{item.body}</Typography>}
        </Box>
      ))}
    </>
  );
};
