export enum ActionTypes {
  SET_SHOW_SIFT_SCIENCE_EXIT = "[SIFT SCIENCE] SET_SHOW_SIFT_SCIENCE_EXIT",
  SET_IS_LOADING = "[SIFT SCIENCE] SET_IS_LOADING",
  SET_IS_VISIBLE = "[SIFT SCIENCE] SET_IS_VISIBLE",
  SET_SHOW_BUTTON_MIGRATED = "[SIFT SCIENCE] SET_SHOW_BUTTON_MIGRATED",
  SET_IS_MIGRATED = "[SIFT SCIENCE] SET_IS_MIGRATED",
  SET_SHOW_MODAL_CREATE = "[SIFT SCIENCE] SET_SHOW_MODAL_CREATE",
  SET_DISABLED = "[SIFT SCIENCE] SET_DISABLED",
  SET_MIGRATED = "[SIFT SCIENCE] SET_MIGRATED",
  SET_VISIBLE_CONFIGURATION = "[SIFT SCIENCE] SET_VISIBLE_CONFIGURATION",
  SET_MERCHANTS = "[SIFT SCIENCE] GET_MERCHANTS",
  SET_QUERY_MERCHANTS = "[SIFT SCIENCE] GET_QUERY_MERCHANTS",
  SET_SIFTSCIENCE_CREDENTIALS = "[SIFT SCIENCE] SET_SIFTSCIENCE_CREDENTIALS",
  SET_SHOW_FINAL_MODAL = "[SIFT SCIENCE] SET_SHOW_FINAL_MODAL",
  SET_NOTIFICATION = "[SIFT SCIENCE] SET_NOTIFICATION",
  SET_MIGRATE_SIFTSCIENCE_RESPONSE = "[SIFT SCIENCE] SET_MIGRATE_SIFTSCIENCE_RESPONSE",
}
