import { UseModalStartChargebackInterfaces } from "./useModalStartChargeback.interfaces";
import { ModalStartChargebackInterfaces } from "../ModalStartChargeback.interfaces";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm, UseFormReturn } from "react-hook-form";
import {
  chargebackTrxWithEmail,
  getContactInformation,
} from "../../../store/thunks/app/app.thunks";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { defaultTo, get, isEmpty, isEqual, set, uniq } from "lodash";
import {
  ContactInformation,
  EmailsInformation,
} from "../../../../types/contact_information";
import { ContactDetails } from "../../../../types/merchant_node_info_response";
import { isUndefined } from "webpack-merge/dist/utils";
import { TransactionInfo } from "../../../../types/transactions_data";
import { VoidBody } from "../../../store/interfaces/AppState.interfaces";
import {
  HierarchyContactInformationEnum,
  MAX_EMAIL_NUMBER,
  NotHierarchyContactInformationEnum,
} from "../../../shared/enums/ContactInformationEnum";
import { GeneralContactInformation } from "../../../../types/general_contact_information";
import { ModalStartChargebackLabelsEnum } from "../../../shared/enums/ModalStartChargebackLabelsEnum";
import { getPaymentMethod } from "../../../shared/utils/chargeback_utils";
import { PaymentMethodsEnum } from "../../../shared/infraestructure/payment-method";

export const useModalStartChargeback = (
  props: ModalStartChargebackInterfaces
): UseModalStartChargebackInterfaces => {
  const dispatchApp = useAppDispatch();
  const { dataContactInfo } = useAppSelector((state) => ({
    ...state.app,
  }));
  const form: UseFormReturn<ContactInformation> = useForm<ContactInformation>({
    mode: "all",
    shouldUnregister: false,
  });
  const { fields, append, update } = useFieldArray<ContactInformation>({
    control: form.control,
    name: "contacts",
  });
  const { control } = useForm();
  const [emailLimit, setEmailLimit] = useState<number>(2);
  const [totalSelectedEmails, setTotalSelectedEmails] = useState<string>(
    0 + " " + ModalStartChargebackLabelsEnum.SELECTED_EMAILS
  );
  const [isButtonDisable, setIsButtonDisable] = useState<boolean>(true);

  function getSelectedEmails(): string[] {
    const emailList: string[] = [];
    const contacts: ContactInformation[] = get(
      form.getValues(),
      "contacts",
      []
    );

    contacts.forEach((contactInformation: ContactInformation) => {
      const checkboxSelectedEmails: string[] = contactInformation
        .emails!.filter((inf: EmailsInformation) => inf.status === true)
        .map((inf: EmailsInformation) => inf.email!);

      emailList.push(...checkboxSelectedEmails);
    });

    const customizedEmails: string[] = defaultTo(
      get(form.getValues(), "customizedEmails"),
      []
    );

    emailList.push(...customizedEmails);

    return emailList;
  }

  function disableCheckboxesWhenLimitIsReached(): void {
    const contactInformationArray: ContactInformation[] = get(
      form.getValues(),
      "contacts",
      ""
    );

    contactInformationArray.forEach(
      (contactInfo: ContactInformation, index: number) => {
        let categoryDisabled: boolean = false;
        const emailsUpdated: EmailsInformation[] = [];

        contactInfo.emails?.map((emailInformation: EmailsInformation) => {
          if (emailInformation.status == false) {
            emailsUpdated.push({
              ...emailInformation,
              disabled: true,
            });
          } else {
            emailsUpdated.push(emailInformation);
          }
        });

        if (!contactInfo.status) {
          categoryDisabled = true;
        }

        update(index, {
          ...contactInfo,
          disabled: categoryDisabled,
          emails: emailsUpdated,
        });
      }
    );
  }

  function enableCheckboxes(): void {
    const contactInformationArray: ContactInformation[] = get(
      form.getValues(),
      "contacts",
      ""
    );

    contactInformationArray.forEach(
      (contactInfo: ContactInformation, index: number) => {
        const emailsUpdated: EmailsInformation[] = [];

        contactInfo.emails?.map((emailInformation: EmailsInformation) => {
          if (emailInformation.disabled) {
            emailsUpdated.push({
              ...emailInformation,
              disabled: false,
            });
          } else {
            emailsUpdated.push(emailInformation);
          }
        });

        update(index, {
          ...contactInfo,
          disabled: false,
          emails: emailsUpdated,
        });
      }
    );
  }

  function disableOrEnableCheckboxes() {
    const totalSelectedEmails: number = getSelectedEmails().length;

    if (totalSelectedEmails == MAX_EMAIL_NUMBER) {
      disableCheckboxesWhenLimitIsReached();
    } else {
      enableCheckboxes();
    }

    const customizedEmails: string[] = defaultTo(
      get(form.getValues(), "customizedEmails"),
      []
    );

    if (MAX_EMAIL_NUMBER > getSelectedEmails().length) {
      if (customizedEmails.length + 1 === 1) {
        setEmailLimit(2);
      } else {
        setEmailLimit(customizedEmails.length + 1);
      }
    } else {
      setEmailLimit(customizedEmails.length);
    }

    setTotalSelectedEmails(
      totalSelectedEmails.toString() +
        " " +
        ModalStartChargebackLabelsEnum.SELECTED_EMAILS
    );
    setIsButtonDisable(!(totalSelectedEmails > 0));
  }

  function handleSelectedCategory(event: React.ChangeEvent<HTMLInputElement>) {
    const categoryEmail: string = get(
      form.getValues(),
      `contacts[${event.target.id}].category`,
      ""
    );
    const emails: EmailsInformation[] = get(
      form.getValues(),
      `contacts[${event.target.id}].emails`
    );
    const emailEdited: EmailsInformation[] = [];

    emails.forEach((emailInformation: EmailsInformation, index: number) => {
      if (index + getSelectedEmails().length < MAX_EMAIL_NUMBER) {
        emailEdited.push({
          ...emailInformation,
          status: event.target.checked,
        });
      } else {
        emailEdited.push({
          ...emailInformation,
          disabled: true,
          status: false,
        });
      }
    });

    update(Number(event.target.id), {
      category: categoryEmail,
      emails: emailEdited,
      status: event.target.checked,
    });

    disableOrEnableCheckboxes();
  }

  function handleSelectedEmail(event: React.ChangeEvent<HTMLInputElement>) {
    const categoryIndex: number = Number(
      event.target.id.split("|")[0].split("-")[1]
    );
    const emailIndex: number = Number(
      event.target.id.split("|")[1].split("-")[1]
    );
    const categoryName: string = get(
      form.getValues(),
      `contacts[${categoryIndex}].category`,
      ""
    );
    const emails: EmailsInformation[] = get(
      form.getValues(),
      `contacts[${categoryIndex}].emails`,
      ""
    );

    set(emails, `[${emailIndex}].status`, event.target.checked);
    const isAnyEmailSelected: boolean = emails.some(
      (email: EmailsInformation) => email.status == true
    );

    update(categoryIndex, {
      category: categoryName,
      disabled: false,
      emails,
      status: isAnyEmailSelected,
    });

    disableOrEnableCheckboxes();
  }

  const handleCloseModal = (): void => {
    props.modalStartChargeback.setIsOpenModalChargeback(false);
  };

  const handleAddEmail = (emails: string[]) => {
    if (
      emails.length !==
      defaultTo(get(form.getValues(), "customizedEmails"), []).length
    ) {
      form.setValue("customizedEmails", emails);
      disableOrEnableCheckboxes();
    }
  };

  function addContactInformation(
    contactInformation: GeneralContactInformation[]
  ) {
    contactInformation.forEach(
      (hierarchyContactInformation: GeneralContactInformation) => {
        append({
          category: get(hierarchyContactInformation, "category"),
          disabled: false,
          emails: get(hierarchyContactInformation, "emails"),
          status: true,
        });
      }
    );
  }

  function sendChargeback() {
    const contacts: ContactInformation[] = get(
      form.getValues(),
      "contacts",
      []
    );

    if (contacts.length > 0) {
      let emailList: string[] = getSelectedEmails();

      emailList = uniq(emailList);
      const transaction: TransactionInfo = props.selectedChargebackData;
      const paymentMethod: string = getPaymentMethod(transaction);
      const isPos: boolean = isEqual(
        PaymentMethodsEnum.CARD_PRESENT,
        paymentMethod
      );

      let trxChargeback: VoidBody = {
        emailList,
        id: isPos
          ? get(transaction, "transaction_reference", "")
          : get(transaction, "ticket_number", ""),
        kind: isEmpty(get(transaction, "subscription_id", ""))
          ? "card"
          : "subscription",
        paymentMethod: paymentMethod,
        ticketNumber: get(transaction, "ticket_number", ""),
      };

      dispatchApp(chargebackTrxWithEmail(trxChargeback));
    }
    handleCloseModal();
  }

  function addContactEmailBySections(merchantNodeInfo: any) {
    if (!isEmpty(merchantNodeInfo)) {
      const commercialEmailsInformation: EmailsInformation[] = [];
      const billingEmailsInformation: EmailsInformation[] = [];
      const dispersionEmailsInformation: EmailsInformation[] = [];
      const technicalEmailsInformation: EmailsInformation[] = [];
      const riskEmailsInformation: EmailsInformation[] = [];

      if (!isUndefined(merchantNodeInfo.contactCommercialArea)) {
        merchantNodeInfo.contactCommercialArea!.forEach(
          (contact: ContactDetails) =>
            commercialEmailsInformation.push({
              disabled: false,
              email: contact.email,
              status: true,
            })
        );
      }
      if (!isUndefined(merchantNodeInfo.contactBillingArea)) {
        merchantNodeInfo.contactBillingArea!.forEach(
          (contact: ContactDetails) =>
            billingEmailsInformation.push({
              disabled: false,
              email: contact.email,
              status: true,
            })
        );
      }
      if (!isUndefined(merchantNodeInfo.contactDispersionArea)) {
        merchantNodeInfo.contactDispersionArea!.forEach(
          (contact: ContactDetails) =>
            dispersionEmailsInformation.push({
              disabled: false,
              email: contact.email,
              status: true,
            })
        );
      }
      if (!isUndefined(merchantNodeInfo.contactTechnicalArea)) {
        merchantNodeInfo.contactTechnicalArea!.forEach(
          (contact: ContactDetails) =>
            technicalEmailsInformation.push({
              disabled: false,
              email: contact.email,
              status: true,
            })
        );
      }
      if (!isUndefined(merchantNodeInfo.contactRiskArea)) {
        merchantNodeInfo.contactRiskArea!.forEach((contact: ContactDetails) =>
          riskEmailsInformation.push({
            disabled: false,
            email: contact.email,
            status: true,
          })
        );
      }

      const hierarchyContactInformationArray: GeneralContactInformation[] = [
        {
          category: HierarchyContactInformationEnum.COMMERCIAL_AREA,
          emails: commercialEmailsInformation,
        },
        {
          category: HierarchyContactInformationEnum.BILLING_AREA,
          emails: billingEmailsInformation,
        },
        {
          category: HierarchyContactInformationEnum.DISPERSION_AREA,
          emails: dispersionEmailsInformation,
        },
        {
          category: HierarchyContactInformationEnum.TECHNICAL_AREA,
          emails: technicalEmailsInformation,
        },
        {
          category: HierarchyContactInformationEnum.RISK_AREA,
          emails: riskEmailsInformation,
        },
      ];

      addContactInformation(hierarchyContactInformationArray);
    }
  }

  useEffect(() => {
    if (
      !isEmpty(props.rowSelectChargeback) &&
      props.modalStartChargeback.isOpenModalChargeback
    ) {
      dispatchApp(
        getContactInformation({
          publicMerchantId: props.rowSelectChargeback,
        })
      );
      form.reset();
    }
  }, [
    props.rowSelectChargeback,
    props.modalStartChargeback.isOpenModalChargeback,
  ]);

  useEffect(() => {
    if (isEmpty(dataContactInfo) || isEmpty(get(dataContactInfo, "data")))
      return;
    const contactInfo = get(dataContactInfo, "data[0]");

    append({
      category: NotHierarchyContactInformationEnum.ACCOUNTING_AREA,
      emails: [
        {
          email: get(contactInfo, "email", ""),
          status: true,
        },
      ],
      status: true,
    });
    if (contactInfo.entityName === "N/A") {
      append({
        category: NotHierarchyContactInformationEnum.COMMERCIAL_AREA,
        emails: [
          {
            email: get(contactInfo, "contactCommercial.email", ""),
            status: true,
          },
        ],
        status: true,
      });
      append({
        category: NotHierarchyContactInformationEnum.TECHNICAL_AREA,
        emails: [
          {
            email: get(contactInfo, "contactTechnical.email", ""),
            status: true,
          },
        ],
        status: true,
      });
    } else {
      addContactEmailBySections(contactInfo);
    }
    disableOrEnableCheckboxes();
  }, [dataContactInfo]);

  useEffect(() => {
    if (isEmpty(fields)) {
      setTotalSelectedEmails(
        0 + " " + ModalStartChargebackLabelsEnum.SELECTED_EMAILS
      );
      setIsButtonDisable(true);
    }
  }, [fields]);

  return {
    control,
    email: defaultTo(form.getValues("customizedEmails"), []),
    emailLimit,
    fields,
    handleAddEmail,
    handleCloseModal,
    handleSelectedCategory,
    handleSelectedEmail,
    isButtonDisable,
    sendChargeback,
    totalSelectedEmails,
  };
};
