import { createNamedStyles } from "../../../shared/utils";

export const transactionTableItemStyles = createNamedStyles({
  actionButton: {
    backgroundColor: "neutral.grey2",
    borderRadius: 0.5,
    color: "text.primary",
    p: 1,
    "&.Mui-disabled": {
      color: "text.disabled",
    },
  },
  actionTooltip: {
    "& .MuiTooltip-arrow": {
      color: "neutral.grey6",
    },
    backgroundColor: "neutral.grey6",
    borderRadius: "4px",
    maxWidth: "425px",
    typography: "text.secondary",
  },
  container: {
    "&.MuiTableRow-hover:hover": {
      backgroundColor: "primary.light3",
      cursor: "pointer",
    },
  },
  label: {
    color: "neutral.grey8",
    fontSize: (theme) => theme.spacing(1.625),
    fontWeight: 400,
  },
  subLabel: {
    color: "text.grey",
    fontSize: (theme) => theme.spacing(1.5),
    fontWeight: 400,
  },
});
