import {
  ICellOptionsProps,
  ICellTagProps,
  ICellText,
  ICellTextActionProps,
  ICellTextComponent,
} from "@kushki/connect-ui";
import { TableBodyCellEnum } from "@kushki/connect-ui/dist/Components/Organism/Table/TableInfo/constants";
import { ITableCellProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableInfo/interfaces";
import { ICellBaseProps } from "@kushki/connect-ui/dist/Components/Atoms/Table/CellBase";

export enum TableInfoCell {
  TEXT = "text",
  TITLE = "title",
  OPTIONS = "options",
  TAG = "tag",
  BASE = "base",
  ACTION = "action",
}

export interface ITableInfoCellValue {
  line1?: string;
  line2?: string;
  options?: Partial<ICellOptionsProps>;
  tag?: Partial<ICellTagProps>;
  base?: Partial<ICellBaseProps>;
  action?: Partial<ICellTextActionProps>;
}

export const CELL_TITLE = ({ line1 }: ITableInfoCellValue) =>
  ({
    props: {
      cellProps: {
        align: "left",
        spacing: 1,
      },
      line1,
      type: "oneLine",
    } as ICellTextComponent,
    type: "TITLE" as TableBodyCellEnum,
  } as ITableCellProps);

export const CELL_TEXT = ({ line1, line2 }: ITableInfoCellValue) =>
  ({
    props: {
      cellProps: {
        align: "left",
        spacing: 1,
      },
      line1,
      line2,
      type: line2 ? "twoLines" : "oneLine",
    } as ICellText,
    type: "TEXT" as TableBodyCellEnum,
  } as ITableCellProps);

export const CELL_OPTIONS = ({ options }: ITableInfoCellValue) =>
  ({
    props: {
      cellProps: {
        spacing: 1,
      },
      ...options,
    } as ICellOptionsProps,
    type: "OPTIONS" as TableBodyCellEnum,
  } as ITableCellProps);

export const CELL_BASE = ({ base }: ITableInfoCellValue) =>
  ({
    props: {
      cellProps: {
        spacing: 1,
      },
      ...base,
    } as ICellBaseProps,
    type: "BASE" as TableBodyCellEnum,
  } as ITableCellProps);

export const CELL_TAG = ({ tag }: ITableInfoCellValue) =>
  ({
    props: {
      cellProps: {
        spacing: 1,
      },
      ...tag,
    } as ICellTagProps,
    type: "TAG" as TableBodyCellEnum,
  } as ITableCellProps);

export const CELL_TEXT_ACTION = ({ action }: ITableInfoCellValue) =>
  ({
    props: {
      cellProps: {
        align: "center",
        spacing: 1,
      },
      ...action,
    } as ICellTextActionProps,
    type: "TEXT_ACTION" as TableBodyCellEnum,
  } as ITableCellProps);

export const TABLE_ROW: {
  [Key in TableInfoCell]: (props: ITableInfoCellValue) => ITableCellProps;
} = {
  [TableInfoCell.TITLE]: CELL_TITLE,
  [TableInfoCell.TEXT]: CELL_TEXT,
  [TableInfoCell.OPTIONS]: CELL_OPTIONS,
  [TableInfoCell.TAG]: CELL_TAG,
  [TableInfoCell.BASE]: CELL_BASE,
  [TableInfoCell.ACTION]: CELL_TEXT_ACTION,
};
