import { createNamedStyles } from "../../shared/infrastructure/interfaces/create_named_styles";

export const headerFiltersStyles = createNamedStyles({
  gridFilter: {
    display: "flex",
    justifyContent: "right",
  },
  gridFilterCard: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    "& .css-79elbk": {
      flexWrap: "wrap",
      width: "100%",
    },
    margin: "20px 0px",
  },
  title: {
    color: "#023365",
    margin: "3rem 0px 0.5rem 0px",
  },
  subTitle: {
    color: "#023365",
    fontSize: "14px",
  },
  buttonFilter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "end",
  },
});
