import {
  CatalogBr,
  CatalogCl,
  CatalogCo,
  CatalogCr,
  CatalogEc,
  CatalogEl,
  CatalogGt,
  CatalogHn,
  CatalogMx,
  CatalogPa,
  CatalogPe,
} from "../../services";
import { CountriesEnum } from "./countries-enum";
import { CurrencyEnum } from "./currency-enum";
import { TransactionRatesEnum } from "./TransactionRatesEnum";

export const INVOICE_FRECUENCY: { value: string; name: string }[] = [
  { value: "none", name: "Ninguno" },
  { value: "daily", name: "Diario" },
  { value: "threeWeekly", name: "Lun-Mie-Vie" },
  { value: "weekly", name: "Semanal" },
  { value: "biWeekly", name: "Quincenal" },
  { value: "monthly", name: "Mensual" },
  { value: "twoWeekly", name: "Mar-Vie" },
];

export const PARAMETERIZATION_CATALOG: { value: string; name: string }[] = [
  { value: "SALES", name: "Ventas" },
  { value: "DEFERRED", name: "Diferidos" },
  { value: "CHARGEBACK", name: "Contracargos" },
  { value: "REFUND", name: "Reembolsos" },
  { value: "VOID", name: "Devoluciones" },
];

export interface Category {
  name: string;
  value: string | number;

  [k: string]: any;
}

export const CURRENCY_COUNTRY: Map<string, string> = new Map()
  .set(`${CountriesEnum.COLOMBIA}`, CurrencyEnum.COP)
  .set(`${CountriesEnum.ECUADOR}`, CurrencyEnum.USD)
  .set(`${CountriesEnum.PERU}`, CurrencyEnum.PEN)
  .set(`${CountriesEnum.CHILE}`, CurrencyEnum.CLP)
  .set(`${CountriesEnum.MEXICO}`, CurrencyEnum.MXN)
  .set(`${CountriesEnum.BRAZIL}`, CurrencyEnum.BRL)
  .set(`${CountriesEnum.EL_SALVADOR}`, CurrencyEnum.USD)
  .set(`${CountriesEnum.PANAMA}`, CurrencyEnum.USD)
  .set(`${CountriesEnum.COSTA_RICA}`, CurrencyEnum.CRC);

export const PAYMENT_TYPE: Map<string, string> = new Map()
  .set(TransactionRatesEnum.PAYIN, "Pay - In")
  .set(TransactionRatesEnum.PAYOUT, "Pay - Out")
  .set(TransactionRatesEnum.SERVICES, "Servicio adicional");

export const PAYMENT_METHOD: Map<string, string> = new Map()
  .set(`${TransactionRatesEnum.CARD}`, "Tarjeta")
  .set(`${TransactionRatesEnum.TRANSFER}`, "Transferencia")
  .set(`${TransactionRatesEnum.CASH}`, "Efectivo")
  .set(`${TransactionRatesEnum.SIFTSCIENCE}`, "SiftScience")
  .set(`${TransactionRatesEnum.THREEDS}`, "3DS")
  .set(`${TransactionRatesEnum.OTP}`, "OTP")
  .set(`${TransactionRatesEnum.TRANSUNION}`, "TransUnion");

export const getCatalog = (country: string, catalog: string) => {
  switch (country) {
    case CountriesEnum.ECUADOR:
      return CatalogEc[catalog];
    case CountriesEnum.BRAZIL:
      return CatalogBr[catalog];
    case CountriesEnum.CHILE:
      return CatalogCl[catalog];
    case CountriesEnum.COLOMBIA:
      return CatalogCo[catalog];
    case CountriesEnum.COSTA_RICA:
      return CatalogCr[catalog];
    case CountriesEnum.EL_SALVADOR:
      return CatalogEl[catalog];
    case CountriesEnum.MEXICO:
      return CatalogMx[catalog];
    case CountriesEnum.PANAMA:
      return CatalogPa[catalog];
    case CountriesEnum.PERU:
      return CatalogPe[catalog];
    case CountriesEnum.GUATEMALA:
      return CatalogGt[catalog];
    case CountriesEnum.HONDURAS:
      return CatalogHn[catalog];
    default:
      return [];
  }
};
