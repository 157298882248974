export const COMMENTS_TAB_LABELS = {
  ADD_COMMENT: "Añadir Comentario",
  CANCEL: "Cancelar",
  COMMENT: "Comentario:",
  CREATION_DATE: "Fecha de creación:",
  ERROR: "Hubo un error al guardar el comentario.",
  LIMIT: "Puedes escribir hasta un máximo de 280 carácteres.",
  SAVE: "Guardar",
  SUCCESS: "Comentario agregado correctamente.",
  USER: "Usuario:",
  WRITE_COMMENT: "Escriba un comentario",
};
