import React from "react";
import {
  createStyles,
  Grid,
  Typography,
  MenuItem,
  Select,
  Box,
} from "@material-ui/core";
import { Pagination as PaginationMui } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { LabelsDispersion } from "../../shared/infrastructure/constants/RequestDispersionConstants";
import { useFilterComponentState } from "../FilterComponent/state/useFilterComponentState";

export interface PaginationProps {
  total: number;
}
const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: "12px",
      backgroundColor: "white",
    },
    paginationText: {
      fontSize: "14px",
      color: "#6D7781",
      fontWeight: "normal",
      lineHeight: "170%",
      fontStyle: "normal",
    },
  })
);

const itemsPerPage: number[] = [10, 15, 20];

const Pagination: React.FC<PaginationProps> = (props: PaginationProps) => {
  const classes = useStyles();
  const { handlePagination, pageSize, pageNumber } = useFilterComponentState();
  const numberRecordPerPage: number = pageNumber * pageSize + pageSize;

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} md={7}>
        <Box display="flex" alignItems="center">
          <Box pr={1.5}>
            <Typography className={classes.paginationText}>
              {LabelsDispersion.PAGINATION_TITTLE}
            </Typography>
          </Box>
          <Box pr={3}>
            <Select
              id={"select"}
              disableUnderline
              value={pageSize}
              onChange={(event) =>
                handlePagination(event.target.value as number, pageNumber - 1)
              }
            >
              {itemsPerPage.map((item, i) => (
                <MenuItem value={item} key={i + item + ""}>
                  <Typography className={classes.paginationText}>
                    {item}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <Typography className={classes.paginationText}>
              {`${pageNumber * pageSize + 1}-${
                numberRecordPerPage > props.total
                  ? props.total
                  : numberRecordPerPage
              } de ${props.total}`}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        container
        justify="flex-end"
        alignItems="flex-start"
        direction="row"
      >
        <PaginationMui
          id={"pagination"}
          count={Math.ceil(Number(props.total / pageSize))}
          color={"primary"}
          onChange={(_event, value) =>
            handlePagination(pageSize, (value as number) - 1)
          }
          page={pageNumber + 1}
          size={"small"}
        />
      </Grid>
    </Grid>
  );
};

export default Pagination;
