import React from "react";

export const EditIcon = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33301 4.97331C3.11199 4.97331 2.90003 5.0611 2.74375 5.21738C2.58747 5.37367 2.49967 5.58563 2.49967 5.80664V17.4733C2.49967 17.6943 2.58747 17.9063 2.74375 18.0626C2.90003 18.2188 3.11199 18.3066 3.33301 18.3066H14.9997C15.2207 18.3066 15.4326 18.2188 15.5889 18.0626C15.7452 17.9063 15.833 17.6943 15.833 17.4733V13.0233C15.833 12.5631 16.2061 12.19 16.6663 12.19C17.1266 12.19 17.4997 12.5631 17.4997 13.0233V17.4733C17.4997 18.1363 17.2363 18.7722 16.7674 19.2411C16.2986 19.7099 15.6627 19.9733 14.9997 19.9733H3.33301C2.66997 19.9733 2.03408 19.7099 1.56524 19.2411C1.0964 18.7722 0.833008 18.1363 0.833008 17.4733V5.80664C0.833008 5.1436 1.0964 4.50771 1.56524 4.03887C2.03408 3.57003 2.66997 3.30664 3.33301 3.30664H7.78301C8.24325 3.30664 8.61634 3.67974 8.61634 4.13997C8.61634 4.60021 8.24325 4.97331 7.78301 4.97331H3.33301Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4104 1.88421C14.7359 1.55878 15.2635 1.55878 15.5889 1.88421L18.9223 5.21755C19.2477 5.54298 19.2477 6.07062 18.9223 6.39606L10.5889 14.7294C10.4326 14.8857 10.2207 14.9735 9.99967 14.9735H6.66634C6.2061 14.9735 5.83301 14.6004 5.83301 14.1401V10.8068C5.83301 10.5858 5.92081 10.3738 6.07709 10.2175L14.4104 1.88421ZM7.49967 11.152V13.3068H9.6545L17.1545 5.8068L14.9997 3.65198L7.49967 11.152Z"
      fill="white"
    />
  </svg>
);
