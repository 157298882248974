import React from "react";
import { Grid, Skeleton } from "@mui/material";
import {
  mainSubHeaderStyles,
  mainSubHeaderStyles as styles,
} from "../../SubHeaderBranchMassive/SubHeader.styles";

export const SubHeaderMerchantMassiveSkeleton: React.FC = () => {
  return (
    <Grid
      container
      alignItems="center"
      data-testid="sub-header-merchant-massive-skeleton"
    >
      <Grid item container xs={12} md={"auto"} alignItems="center">
        <Skeleton variant="rectangular" width={120} height={30} />
      </Grid>
    </Grid>
  );
};

export const SubHeaderMerchantMassiveTitleSkeleton: React.FC = () => {
  return (
    <Grid
      container
      alignItems="center"
      sx={styles.gridTitle}
      spacing={1}
      data-testid="sub-header-merchant-massive-title-skeleton"
    >
      <Grid item sx={mainSubHeaderStyles.branchesTitle}>
        <Skeleton variant="rectangular" width={150} height={30} />
      </Grid>
      <Grid item sx={mainSubHeaderStyles.branchesButton}>
        <Skeleton variant="rectangular" width={25} height={25} />
      </Grid>
    </Grid>
  );
};
