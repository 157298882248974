import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { useForm } from "react-hook-form";
import { IChargeForm } from "../../../store/interfaces/charge.interfaces";
import { IUseChargeContainerState } from "./useChargeContainerState.interfaces";
import { useEffect, useState } from "react";
import {
  disableContinueButton,
  disableSaveButton,
  updateConstitutionalCountry,
} from "../../../store/actions/dispersion/dispersion.actions";
import { useSearchParams } from "react-router-dom";
import { EntityName } from "../../../shared/enums/entityName";
import { defaultTo, get, isEmpty } from "lodash";
import { ChargeConfigId } from "../../../shared/enums/chargeEnum";
import { IDispersionForm } from "../../../store/interfaces/dispersion.interfaces";
import {
  getBeneficiaryName,
  getConstitutionalCountry,
  getHierarchyNodeConfigPayload,
  getRatesPayload,
  getStatesResume,
} from "../../../shared/utils/dispersionUtils";
import {
  getAccountInfoToSave,
  getBankAccounts,
  getConfigsCharge,
  isBasicDataResponse,
  isChargeDataResponse,
} from "../../../shared/utils/chargeUtils";
import {
  getDispersionRatesConfig,
  getMerchantNodeInfo,
  getNodeInfo,
  patchMerchantNodeInfo,
  postHierarchyNodeConfig,
  postRates,
} from "../../../store/thunks/app/app.thunks";
import {
  Configs,
  NodeInfoResponse,
} from "../../../../types/node_info_response";
import { validateIfHasMainAccount } from "../../../shared/utils/bankAccountUtils";
import { MerchantResponse } from "../../../../types/merchant_response";
import {
  addBankAccount,
  editBankAccount,
  editBeneficiaryName,
  setIsLoadingChargeInformation,
} from "../../../store/actions/charge/charge.actions";
import {
  AllConfigs,
  ConfigIdEnum,
} from "../../../shared/constants/node_config_enums";

export const useChargeContainerState = (): IUseChargeContainerState => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const publicMerchantId = searchParams.get("publicMerchantId");
  const {
    constitutionalCountry,
    frequencyAndFraudForm,
    merchantNodeInfo,
    banksAccounts: bankAccounts,
    banksAccounts,
    nodeInfo,
    footerButtonsClicked,
    isLoadingChargeInformation,
    loading: { loadingForm, loadingRatesConfig },
    appState: { patchMerchantNodeInfo: statePatchMerchantNodeInfo },
    stateCharge: { postChargeRates },
    configsResponse,
  } = useAppSelector((state) => ({
    ...state.app,
    ...state.charge,
  }));

  const [isBankDataUpdated, setBankDataUpdated] = useState<boolean>(false);

  const {
    control,
    resetField,
    reset,
    formState: { errors, isValid },
    watch,
  } = useForm<IChargeForm>({
    defaultValues: { ...frequencyAndFraudForm },
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const [isBeneficiaryNameUpdated, setIsBeneficiaryNameUpdated] =
    useState(false);

  const [isCentralizedBranch, setIsCentralizedBranch] =
    useState<boolean>(false);

  const handleBasicDataResponse = async (response: MerchantResponse) => {
    await dispatch(editBeneficiaryName(getBeneficiaryName(response)));
    setIsBeneficiaryNameUpdated(true);
  };

  const handleValidatedCentralizedBranch = (
    entityName: string,
    config: Configs
  ): boolean => {
    return (
      entityName === EntityName.BRANCH && !isEmpty(config.centralizedNodesId)
    );
  };

  const requestMerchantNodeInfo = (configId: string, merchantId: string) => {
    dispatch(
      getMerchantNodeInfo({
        configId,
        publicMerchantId: merchantId,
      })
    );
  };

  const requestChargeData = (configId: string, merchantId: string) => {
    requestMerchantNodeInfo(configId, merchantId);
  };

  const requestBasicData = (configId: string, merchantId: string) => {
    requestMerchantNodeInfo(configId, merchantId);
  };

  const requestChargeRatesConfig = (value: string) => {
    dispatch(getDispersionRatesConfig({ merchantId: value }));
  };

  const requestDataFromNodeInfo = (nodeInfo: NodeInfoResponse) => {
    const { configCn001, configCn017 } = getConfigsCharge(nodeInfo);

    if (configCn001)
      requestBasicData(configCn001.configuration, configCn001.value);

    if (!configCn017) {
      setBankDataUpdated(true);

      return;
    }

    requestChargeData(configCn017.configuration, configCn017.value);
    requestChargeRatesConfig(configCn017.value);
    setIsCentralizedBranch(
      handleValidatedCentralizedBranch(
        get(nodeInfo, "entityName", ""),
        configCn017
      )
    );
  };

  const handleChargeDataResponse = async (response: MerchantResponse) => {
    const accountInfo = get(response, "charge.accountInfo");

    if (!isEmpty(accountInfo))
      dispatch(
        addBankAccount({
          ...accountInfo,
          accountOrder: "principal",
          country: "Ecuador",
        })
      );

    await dispatch(editBankAccount(getBankAccounts(response)));
    setBankDataUpdated(true);
  };

  const saveChargeData = () => {
    const country: string = get(nodeInfo, "generalInfo.country", "Ecuador");

    dispatch(
      postRates(
        getRatesPayload({
          bankAccounts: banksAccounts,
          clientType: get(nodeInfo, "generalInfo.clientType", ""),
          constitutionalCountry,
          country,
          dispersionForm: frequencyAndFraudForm as IDispersionForm,
          publicMerchantId: publicMerchantId!,
        })
      )
    );

    dispatch(
      patchMerchantNodeInfo({
        charge: {
          accountInfo: getAccountInfoToSave(
            defaultTo(banksAccounts[0], undefined)
          ),
        },
        chargeFrequency: frequencyAndFraudForm.frequency,
        configId: ChargeConfigId.CN017.toString(),
        country,
        publicMerchantId: publicMerchantId!,
      })
    );
  };
  const isEmptyConfig = (arrayConfig: Configs[]) => {
    const getConfig: Configs[] = arrayConfig.filter(
      (item) => item.configuration === ConfigIdEnum.CN017
    );

    if (isEmpty(get(getConfig, "[0].value"))) {
      dispatch(setIsLoadingChargeInformation(false));
    }
  };

  useEffect(() => {
    if (!isEmpty(get(nodeInfo, "configs", [])))
      isEmptyConfig(get(nodeInfo, "configs", []));
  }, [nodeInfo]);

  useEffect(() => {
    dispatch(
      getNodeInfo({
        configIds: AllConfigs,
        publicMerchantId: publicMerchantId!,
      })
    );
  }, []);

  useEffect(() => {
    const isAllDataLoaded =
      !(loadingForm && loadingRatesConfig) && isBankDataUpdated;

    dispatch(
      disableContinueButton(
        !(isAllDataLoaded && isValid && validateIfHasMainAccount(bankAccounts))
      )
    );
    dispatch(disableSaveButton(!(isAllDataLoaded && isValid)));
  }, [
    isValid,
    bankAccounts,
    loadingForm,
    loadingRatesConfig,
    isBankDataUpdated,
  ]);

  useEffect(() => {
    if (!merchantNodeInfo) return;

    if (isBasicDataResponse(merchantNodeInfo)) {
      handleBasicDataResponse(merchantNodeInfo).then();
    }

    if (isChargeDataResponse(merchantNodeInfo))
      handleChargeDataResponse(merchantNodeInfo).then();
  }, [merchantNodeInfo]);

  useEffect(() => {
    const isDispersionFormDataLoaded = !(loadingForm && loadingRatesConfig);

    if (isDispersionFormDataLoaded) {
      const values: object = {
        ...frequencyAndFraudForm,
        keepFraud: frequencyAndFraudForm.keepFraud.toString(),
      };

      reset(values);
    }
  }, [loadingForm, loadingRatesConfig]);

  useEffect(() => {
    const { isSuccess: isDispersionDataSaved } = getStatesResume([
      statePatchMerchantNodeInfo,
      postChargeRates,
    ]);
    const configResponse = defaultTo(configsResponse, []).find(
      (config) => get(config, "configuration") === ChargeConfigId.CN017
    );

    if (isDispersionDataSaved)
      dispatch(
        postHierarchyNodeConfig(
          getHierarchyNodeConfigPayload(
            nodeInfo!,
            configResponse,
            publicMerchantId!,
            bankAccounts
          )
        )
      );
  }, [statePatchMerchantNodeInfo, postChargeRates]);

  useEffect(() => {
    dispatch(disableContinueButton(!isValid || isEmpty(banksAccounts)));
    dispatch(disableSaveButton(!isValid));
  }, [isValid, banksAccounts]);

  useEffect(() => {
    if (isEmpty(nodeInfo)) return;

    dispatch(updateConstitutionalCountry(getConstitutionalCountry(nodeInfo!)));
    requestDataFromNodeInfo(nodeInfo!);
  }, [nodeInfo]);

  useEffect(() => {
    if (footerButtonsClicked && nodeInfo && publicMerchantId) saveChargeData();
  }, [footerButtonsClicked]);

  return {
    form: {
      control,
      errors,
      reset,
      resetField,
      watch,
    },
    isCentralizedBranch,
    isLoadingChargeInformation,
    loading: { loadingForm, loadingRatesConfig },
    showBankData: isBankDataUpdated && isBeneficiaryNameUpdated,
  };
};
