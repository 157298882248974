import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import { monthsListStyles } from "./MonthsList.styles";
import { IMonthsList } from "./MonthsList.interfaces";
import { CountriesEnum } from "../../shared/enums/CountriesEnum";

const MonthsList: FC<IMonthsList> = (props: IMonthsList) => {
  const { title, months, country } = props;

  return (
    <Box display={"flex"} flexDirection={"column"} rowGap={"4px"}>
      <Typography sx={monthsListStyles.title}>{title}</Typography>
      <Box display={"flex"} columnGap={"8px"}>
        {months.map((value: string, index: number) => (
          <Box
            key={index + "months" + value}
            sx={
              country == CountriesEnum.BRAZIL
                ? monthsListStyles.brazilMonthContainer
                : monthsListStyles.monthContainer
            }
          >
            <Typography
              sx={
                country == CountriesEnum.BRAZIL
                  ? monthsListStyles.brazilMonthLabel
                  : monthsListStyles.monthLabel
              }
            >
              {value}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default MonthsList;
