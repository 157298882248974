/* istanbul ignore file */
/* eslint-disable */
export const environment: {
kushkiUrl: string;
KushkiUrlWS: string;
rollbarToken: string;
envName: string;
devTools: boolean;
} = {
kushkiUrl: "https://api.kushkipagos.com",
KushkiUrlWS: "wss://api-ws.kushkipagos.com",
rollbarToken: "811475d581884f91bd90e7fc26115e8e",
envName: "primary",
devTools: false,
};
