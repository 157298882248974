type Patterns = {
  alfa1To40WithoutSpace: string;
};

export const PATTERNS: Patterns = {
  alfa1To40WithoutSpace: "^[a-zA-Z0-9]{1,40}$",
};

export enum Keys {
  ENTER = "Enter",
}
