import { ItemCategoryProps } from "@kushki/connect-ui/dist/Components/Molecules/Filters/ItemCategory/interfaces";
import { IDropdownItemProps } from "@kushki/connect-ui/dist/Components/Atoms/List/DropdownItem";
import { FileExtensionEnum } from "../enums/file_extension";
import {
  AmountFilterFieldEnum,
  FilterEnum,
  FilterLabelEnum,
  OperationTypeLabelEnum,
  StatusFilterEnum,
} from "../enums/filter";
import { map } from "lodash";
import { AmountFilterTypeEnum } from "@kushki/connect-ui/dist/Components/Molecules/Filters/ItemCategory/constants";

export const WALLET_FILTERS_ITEMS: ItemCategoryProps[] = [
  {
    isMinimize: true,
    items: map(
      Object.values(OperationTypeLabelEnum),
      (operationType: string) => {
        return {
          label: operationType,
          selected: false,
          value: operationType,
        };
      }
    ),
    onItemsSelect: function noRefCheck() {},
    placeHolder: FilterLabelEnum.OPERATION_TYPE,
    selectType: "multiple",
    type: "byChips",
  },
  {
    isMinimize: true,
    items: map(Object.values(StatusFilterEnum), (operationType: string) => {
      return {
        label: operationType,
        selected: false,
        value: operationType,
      };
    }),
    onItemsSelect: function noRefCheck() {},
    placeHolder: FilterLabelEnum.STATUS,
    selectType: "multiple",
    type: "byChips",
  },
  {
    isMinimize: true,
    items: [
      {
        label: "operator",
        selected: false,
        value: "",
      },
      {
        label: "firstAmount",
        selected: false,
        value: "",
      },
      {
        label: "secondAmount",
        selected: false,
        value: "",
      },
    ],
    onItemsSelect: function noRefCheck() {},
    placeHolder: FilterLabelEnum.AMOUNT,
    selectType: "single",
    type: "byAmount",
  },
];

export enum OperationTypeEnum {
  KUSHKI_RECHARGE = "Recarga Kushki",
  LIQUIDATION_RECHARGE = "Recarga Liquidación",
  COMMERCE_RECHARGE = "Recarga Comercio",
  CASH_OUT_RECHARGE = "Recarga Cash Out",
  TRANSFER_OUT_RECHARGE = "Recarga Transfer Out",
  CASH_OUT_DEBIT = "Débito Cash Out",
  TRANSFER_OUT_DEBIT = "Débito Transfer Out",
  KUSHKI_DEBIT = "Débito Kushki",
}

export const OPERATION_TYPE_BY_LABEL: Record<string, string> = {
  [OperationTypeLabelEnum.KUSHKI_RECHARGE]: OperationTypeEnum.KUSHKI_RECHARGE,
  [OperationTypeLabelEnum.LIQUIDATION_RECHARGE]:
    OperationTypeEnum.LIQUIDATION_RECHARGE,
  [OperationTypeLabelEnum.COMMERCE_RECHARGE]:
    OperationTypeEnum.COMMERCE_RECHARGE,
  [OperationTypeLabelEnum.CASH_OUT_DEBIT]: OperationTypeEnum.CASH_OUT_DEBIT,
  [OperationTypeLabelEnum.TRANSFER_OUT_DEBIT]:
    OperationTypeEnum.TRANSFER_OUT_DEBIT,
  [OperationTypeLabelEnum.KUSHKI_DEBIT]: OperationTypeEnum.KUSHKI_DEBIT,
  [OperationTypeLabelEnum.CASH_OUT_RECHARGE]:
    OperationTypeEnum.CASH_OUT_RECHARGE,
  [OperationTypeLabelEnum.TRANSFER_OUT_RECHARGE]:
    OperationTypeEnum.TRANSFER_OUT_RECHARGE,
};

export const STATUS_BY_LABEL: Record<string, string> = {
  [StatusFilterEnum.APPROVAL]: FilterEnum.APPROVAL,
  [StatusFilterEnum.DECLINED]: FilterEnum.DECLINED,
};

export const FILTER_BY_AMOUNT_OPERATOR: Record<string, string> = {
  [AmountFilterTypeEnum.EQUAL]: AmountFilterFieldEnum.EQUAL,
  [AmountFilterTypeEnum.GREATER]: AmountFilterFieldEnum.MIN,
  [AmountFilterTypeEnum.LESS]: AmountFilterFieldEnum.MAX,
};

export const DEFAULT_DOWNLOAD_BUTTON_ITEMS: IDropdownItemProps[] = [
  {
    id: FileExtensionEnum.CSV,
    text: ".csv",
    variant: "text",
  },
  {
    id: FileExtensionEnum.JSON,
    text: ".json",
    variant: "text",
  },
];
