import { useAppSelector } from "../../../store/hooks/hooks";
import { IUseTicketOrderState } from "./useTicketOrderState.interfaces";

export const useTicketOrderState = (): IUseTicketOrderState => {
  const ticketOrderStore = useAppSelector((state) => state.ticketOrder);

  return {
    ticketOrder: ticketOrderStore,
  };
};
