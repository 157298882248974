import React, { Fragment } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../../../assets/icons/CloseIcon";
import { InformativeModalStyles } from "./InformativeModalDownloads.styles";
import { InformativeModalProps } from "./InformativeModalDownloads.interfaces";
import { TabSectionEnum } from "../../../shared/infrastructure/enums/tab_section_enum";
import { SingleInformativeModalDownloads } from "../SingleInformativeModalDownloads/SingleInformativeModalDownloads";

export const InformativeModalDownloads = (props: InformativeModalProps) => {
  const modalSubTitleDetail: string = "Detalle de transacciones a descargar:";
  const modalSubTitleAmount: string = "Monto total de las transacciones:";
  const textButtonCancel: string = "Cancelar";
  const textButtonDownload: string = "Descargar";

  return (
    <Fragment>
      <Dialog
        open={props.openModal}
        onClose={props.handlers.handleOpenCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"md"}
        sx={InformativeModalStyles.dialog}
      >
        <DialogTitle sx={InformativeModalStyles.dialogTitle}>
          <Typography sx={InformativeModalStyles.dialogTitleText}>
            {props.information.total.quantity > 1
              ? props.title
              : "Descargar transacción"}
          </Typography>

          <IconButton
            onClick={props.handlers.handleOpenCloseModal}
            aria-label="close"
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {props.information.total.quantity === 1 && props.rowSelected === 1 ? (
          <SingleInformativeModalDownloads
            merchantName={props.information.merchanInfo.merchantName}
            merchantId={props.information.merchanInfo.merchantId}
            approvedTransactionAmount={
              props.information.merchanInfo.approvedTransactionAmount
            }
            currency={props.information.merchanInfo.currency}
          />
        ) : props.currentTab === TabSectionEnum.HISTORICAL_TAB ? (
          <DialogContent>
            <Grid>
              <Typography sx={InformativeModalStyles.subTitleText}>
                {`Se descargarán ${props.information.total.quantity} transacciones.`}
              </Typography>
              <Typography sx={InformativeModalStyles.titleText}>
                {modalSubTitleDetail}
              </Typography>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography sx={InformativeModalStyles.titleText}>
                  {`Conciliados (Automáticos): ${props.information.conciliated.quantity}`}
                </Typography>
                <Typography sx={InformativeModalStyles.titleText}>
                  {props.information.conciliated.amount}
                </Typography>
              </Grid>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography sx={InformativeModalStyles.titleText}>
                  {`No conciliados (Reembolso pendiente): ${props.information.pendingRefund.quantity}`}
                </Typography>
                <Typography sx={InformativeModalStyles.titleText}>
                  {props.information.pendingRefund.amount}
                </Typography>
              </Grid>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography sx={InformativeModalStyles.titleText}>
                  {`No conciliados (Reembolso aprobado): ${props.information.approvedRefund.quantity}`}
                </Typography>
                <Typography sx={InformativeModalStyles.titleText}>
                  {props.information.approvedRefund.amount}
                </Typography>
              </Grid>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography sx={InformativeModalStyles.titleText}>
                  {`No conciliados (Reembolso rechazado): ${props.information.declinedRefund.quantity}`}
                </Typography>
                <Typography sx={InformativeModalStyles.titleText}>
                  {props.information.declinedRefund.amount}
                </Typography>
              </Grid>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography sx={InformativeModalStyles.titleText}>
                  {`No conciliados (Omitidos): ${props.information.omitted.quantity}`}
                </Typography>
                <Typography sx={InformativeModalStyles.titleText}>
                  {props.information.omitted.amount}
                </Typography>
              </Grid>
              <Typography sx={InformativeModalStyles.subTitleTextBtn}>
                Monto total de las transacciones:
              </Typography>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography sx={InformativeModalStyles.titleText}>
                  {`${props.information.total.quantity} transacciones`}
                </Typography>
                <Typography sx={InformativeModalStyles.titleText}>
                  {props.information.total.amount}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
        ) : (
          <DialogContent>
            <Grid>
              <Typography sx={InformativeModalStyles.subTitleText}>
                {`Se descargarán ${props.information.total.quantity} transacciones.`}
              </Typography>
              <Typography sx={InformativeModalStyles.titleText}>
                {modalSubTitleDetail}
              </Typography>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography sx={InformativeModalStyles.titleText}>
                  {`Pendientes: ${props.information.slope.quantity}`}
                </Typography>
                <Typography sx={InformativeModalStyles.titleText}>
                  {props.information.slope.amount}
                </Typography>
              </Grid>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography sx={InformativeModalStyles.titleText}>
                  {`En revisión adquirente: ${props.information.acquirer.quantity}`}
                </Typography>
                <Typography sx={InformativeModalStyles.titleText}>
                  {props.information.acquirer.amount}
                </Typography>
              </Grid>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography sx={InformativeModalStyles.titleText}>
                  {`En revisión interna: ${props.information.underInternalReview.quantity}`}
                </Typography>
                <Typography sx={InformativeModalStyles.titleText}>
                  {props.information.underInternalReview.amount}
                </Typography>
              </Grid>

              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
                marginTop={2}
                marginBottom={2}
              >
                <Typography sx={InformativeModalStyles.titleText}>
                  {modalSubTitleAmount}
                </Typography>
                <Typography sx={InformativeModalStyles.titleText}>
                  {props.information.total.amount}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
        )}

        <DialogActions>
          <Button
            sx={InformativeModalStyles.cancelBtn}
            onClick={props.handlers.handleOpenCloseModal}
            id={"cancelBtn"}
          >
            {textButtonCancel}
          </Button>
          <Button
            id={"sendBtn"}
            sx={InformativeModalStyles.sendBtn}
            onClick={() => {
              props.handleDownloadTransactions(props.downloadType);
              props.handlers.handleOpenCloseModal();
            }}
            autoFocus
          >
            {textButtonDownload}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
