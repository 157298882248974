import { makeStyles } from "@material-ui/core/styles";
import { createStyles, Theme } from "@material-ui/core";

export const ModalStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltipButton: {
      fontSize: "12px",
      maxWidth: "none",
    },
    modalRetentionFooterHistoric: {
      marginTop: "30px",
      minHeight: "70px",
      "& .MuiGrid-align-items-xs-center": {
        justifyContent: "space-between",
      },
      "& .MuiButton-textSizeSmall": {
        padding: "5px 20px",
        border: "1px solid",
        color: "red",
      },
    },
    modalDownloadFooter: {
      "& button": {
        padding: "4px 16px",
        minWidth: "12em",
        marginLeft: "390px",
      },
      "&& button": {
        marginRight: "56%",
      },
      "& .MuiButton-text": {
        margin: "16px",
        position: "absolute",
        right: 0,
        bottom: 0,
      },
      marginTop: "4px",
    },
    modalMultipleButtonDownloadFooter: {
      "& button": {
        borderColor: "#023365",
        padding: "4px 16px",
        minWidth: "12em",
        fontSize: "0.9rem",
        color: "#023365",
        border: "1px solid #677784",
        backgroundColor: "#F7FAFC",
      },
      "& .MuiButton-text": {
        margin: "16px",
        position: "absolute",
        right: 0,
        bottom: 0,
      },
      marginTop: "4px",
    },
    modalHeader: {
      "& .modal-header-tittle": {
        fontSize: "14px",
      },
    },
    header: {
      color: "#6D7781",
    },
    modalRetentionFooter: {
      "& button": {
        borderColor: "#D62C4B",
        padding: "4px 16px",
        minWidth: "12em",
        color: "#D62C4B",
      },
      "& label": {
        marginTop: "4px",
      },
      marginTop: "4px",
    },
    input: {
      marginTop: "30px",
      margin: theme.spacing(1),
      "&.MuiFormControl-root": { marginLeft: 0 },
      "& > label+div": {
        height: "auto",
      },
    },
    inputRed: {
      marginTop: "30px",
      margin: theme.spacing(1),
      "&.MuiFormControl-root": { marginLeft: 0 },
      "& > label+div": {
        height: "auto",
      },
      "& fieldset": {
        borderColor: "#D62C4B",
      },
      "& label.Mui-focused": {
        color: "#D62C4B",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#D62C4B",
      },
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#D62C4B",
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#D62C4B",
        },
      },
    },
    primaryColor: {
      color: theme.palette.primary.main,
    },
    errorColor: {
      color: "#D62C4B",
    },
  })
);
