import { MenuPathKeysEnum } from "./MenuPathKeysEnum";

export enum MenuBasePathsEnum {
  MERCHANT_DATA = "/merchant",
  BILLING_CONFIGURATION = "/financial-configuration",
  RATES_AND_DISCOUNTS = "/console-rates",
  PROCESSING = "/processing",
  SERVICES = "/service-configuration",
  DEVELOPERS = "/developers",
  RESUME = "/merchant-resume",
  MERCHANT_WEBHOOK = "/merchant-webhook",
}

export const TranslatePathStep: Record<string, MenuPathKeysEnum> = {
  [MenuBasePathsEnum.MERCHANT_DATA]: MenuPathKeysEnum.MERCHANT_DATA,
  [MenuBasePathsEnum.BILLING_CONFIGURATION]:
    MenuPathKeysEnum.BILLING_CONFIGURATION,
  [MenuBasePathsEnum.RATES_AND_DISCOUNTS]: MenuPathKeysEnum.RATES_AND_DISCOUNTS,
  [MenuBasePathsEnum.DEVELOPERS]: MenuPathKeysEnum.DEVELOPERS,
  [MenuBasePathsEnum.RESUME]: MenuPathKeysEnum.RESUME,
  [MenuBasePathsEnum.PROCESSING]: MenuPathKeysEnum.PROCESSING,
  [MenuBasePathsEnum.SERVICES]: MenuPathKeysEnum.SERVICES,
};
