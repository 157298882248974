import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { tableComponentStyles as styles } from "./TableComponent.styles";
import { ITableComponent } from "./TableComponent.interfaces";

const TableComponent = <T,>(props: ITableComponent<T>): JSX.Element => {
  const { data, endOptions, headers, handleClickRow } = props;
  const classes = styles();

  const getRows = (row: T, indexRow: number) => {
    return (
      <TableRow key={`table-row-${indexRow}`}>
        {headers.map((header, index) => (
          <TableCell
            key={`table-cell-${index}`}
            className={classes.bodyCell}
            onClick={() => {
              handleClickRow && handleClickRow(row);
            }}
          >
            {header.customRender ? header.customRender(row) : row[header.value]}
          </TableCell>
        ))}
        {endOptions && <TableCell>{endOptions(row)}</TableCell>}
      </TableRow>
    );
  };

  return (
    <TableContainer>
      <Table className={classes.container}>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell
                className={classes.headerCell}
                key={`${header}${index}`}
              >
                {header.label}
              </TableCell>
            ))}
            {endOptions && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>{data.map(getRows)}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableComponent;
