import React from "react";
import { InputFormProps } from "@components/InputsForm/InputForm.interfaces";
import { InputTypeEnum } from "@shared/enum/InputTypeEnum";
import InputSelect from "@components/InputsForm/Inputs/InputSelect/InputSelect";
import InputText from "@components/InputsForm/Inputs/InputText/InputText";
import InputNumber from "@components/InputsForm/Inputs/InputNumber/InputNumber";
import InputAutocomplete from "@components/InputsForm/Inputs/InputAutocomplete/InputAutocomplete";
import InputDate from "@components/InputsForm/Inputs/InputDate/InputDate";

const InputsForm: React.FC<InputFormProps> = ({
  inputType,
  field,
  label,
  options,
  error,
  disabled,
  fieldName,
}: InputFormProps) => {
  switch (inputType) {
    case InputTypeEnum.SELECT:
      return (
        <InputSelect
          options={options!}
          field={field}
          label={label}
          error={error}
        />
      );
    case InputTypeEnum.TEXT:
      return (
        <InputText
          disabled={disabled}
          field={field}
          label={label}
          error={error}
        />
      );
    case InputTypeEnum.NUMBER:
      return (
        <InputNumber
          field={field}
          fieldName={fieldName}
          label={label}
          error={error}
        />
      );
    case InputTypeEnum.AUTOCOMPLETE:
      return (
        <InputAutocomplete
          options={options!}
          field={field}
          label={label}
          error={error}
        />
      );

    case InputTypeEnum.DATE:
      return <InputDate field={field} label={label} error={error} />;
    default:
      return <></>;
  }
};

export default InputsForm;
