import { format, utcToZonedTime } from "date-fns-tz";
import { get, has } from "lodash";
import { Transaction } from "../../../types/transactions_data";
import {
  PaymentMethodEnum,
  PaymentMethodUpperEnum,
} from "../enums/PaymentMethodEnum";
import { PaymentSubMethodsEnum } from "../infraestructure/payment-method";
import { TransactionType } from "../infraestructure/transaction-type";
import { isApproval } from "./general_utils";

export const validateDate = (timeZoneRegion: string, date: string): string => {
  return format(utcToZonedTime(date, timeZoneRegion), "yyyy-MM-dd'T'HH:mm:ss");
};

export const validateExistTrxs = (
  trxSelected: Transaction[],
  row: Transaction
): boolean => {
  let arrayTrx: Transaction[] = [...trxSelected];
  let check: boolean = false;

  if (arrayTrx.length != 0)
    arrayTrx.forEach((a: Transaction) => {
      if (row._id != a._id) return check;

      return (check = true);
    });

  return check;
};

const isMongoCardPresentTransaction = (row: Transaction): boolean => {
  const payment_method_type: string = get(
    row,
    "_source.payment_method_type",
    ""
  );
  const payment_sub_method: string = get(
    row,
    "_source.payment_submethod_type",
    ""
  );
  const is_card: boolean = payment_method_type === PaymentMethodUpperEnum.CARD;
  const is_card_present: boolean = [
    <string>PaymentSubMethodsEnum.CARD_PRESENT,
    PaymentSubMethodsEnum.POS,
  ].includes(payment_sub_method);

  return is_card && is_card_present;
};

const isElasticPosTransaction = (row: Transaction): boolean => {
  const payment_method_type: string = get(row, "_source.payment_method", "");
  const channel: string = get(row, "_source.channel", "");
  const is_card: boolean = payment_method_type === PaymentMethodEnum.CARD;
  const is_pos: boolean = channel === PaymentSubMethodsEnum.POS;

  return is_card && is_pos;
};

export const isPosTransaction = (row: Transaction): boolean => {
  const is_mongo: boolean = has(row, "_source.create_timestamp");

  if (is_mongo) return isMongoCardPresentTransaction(row);

  return isElasticPosTransaction(row);
};

export const isValidStatusToHide = (
  status: string,
  isMongo: boolean
): boolean => {
  if (isMongo) return true;

  return isApproval(status);
};

export const hiddenOptionTrx = (admin: boolean, row: Transaction): boolean => {
  const is_mongo: boolean = has(row, "_source.create_timestamp");
  let is_charge_backed: boolean = false;

  if (!admin) return true;

  const array: string[] = [
    TransactionType.SALE,
    TransactionType.DEFERRED,
    TransactionType.CAPTURE,
  ];
  const is_approval: boolean = isApproval(row._source!.transaction_status!);

  if (!is_approval) return true;
  if (row._source!.payment_method !== "card") return true;
  if (
    get(row, "_source.processor_type") === "gateway" &&
    get(row, "_source.country") !== "Ecuador"
  )
    return true;

  if (!array.includes(row._source!.transaction_type!)) return true;

  if (row._source!.child !== undefined && row._source!.child.length !== 0)
    for (const child of row._source!.child) {
      is_charge_backed =
        (child._source.transaction_type === TransactionType.CHARGEBACK ||
          child._source.transaction_type === TransactionType.VOID ||
          child._source.transaction_type === TransactionType.REFUND) &&
        isValidStatusToHide(child._source.transaction_status, is_mongo);

      if (is_charge_backed) break;
    }

  return is_charge_backed;
};
