export enum MassiveStatusModalConstants {
  TITLE = "Carga Masiva",
  DETAIL_MESSAGE = "Agrega los registros de contracargos de forma masiva subiendo un archivo .csv con los estados que se requieren modificar y da clic en continuar para agregar los correos de los usuarios que deseas notificar.",
  UPLOAD_FILE_INSTRUCTION = "Haz clic en “Elegir archivo” para buscar el documento en tu dispositivo.",
  UPLOAD_FILE_QUESTION = "¿Tienes dudas sobre la carga del archivo? ",
  INSTRUCTIONS_MASSIVE_STATUS_TITTLE = "Instrucciones para subir archivo",
  ADD_EMAILS_TITTLE = "Agregar correos",
  ADD_EMAILS_DETAIL = "Agrega los correos de los usuarios a los que deseas que se les notifique el estado de la actualización. Puedes agregar un máximo de 5 correos.",
  ADD_EMAILS_INPUT = "Ingresar uno o varios correos",
  LOADING_TITLE = "Estamos creando tu solicitud",
  LOADING_DESCRIPTION = "Este proceso puede tardar unos minutos...",
  DOMAIN_ERR_MSG = "Solo se permiten correos con los siguientes dominios: kushkipagos.com, billpocket.com",
  EMAIL_ERROR_MSG = "Hay un correo ingresado incorrectamente",
}

export enum MassiveStatusModalButtons {
  MASSIVE_UPLOAD_BUTTON_TITLE = "Carga Masiva",
  UPLOAD_FILE_BUTTON_TITLE = "Elige archivo",
  CHANGE_FILE_BUTTON_TITLE = "Cambiar archivo",
  CANCEL_BUTTON = "Cancelar",
  CONTINUE_BUTTON = "Continuar",
  UNDERSTOOD_BUTTON = "Entendido",
  RETURN_BUTTON = "Regresar",
  UPLOAD_BUTTON = "Subir",
  CHECK_HERE_BUTTON = "Consulta aquí",
}
