import { createAsyncThunk } from "@reduxjs/toolkit";
import { thunkPrefix } from "@shared/constants/thunkPrefixes";
import { default as axiosLib, AxiosResponse, CancelTokenSource } from "axios";
import { API_ROUTES } from "@shared/constants/api_routes";
import axios from "@shared/utils/axios-util";
import { get, isEqual } from "lodash";
import { EntityName } from "@shared/enum/entityNameEnum";
import { SearchMerchantsRequest } from "../../../../types/search_merchants_request";
import {
  Merchant,
  SearchMerchantsResponse,
} from "../../../../types/search_merchants_response";
import { StatusEnum } from "@shared/constants/AlarmTableConstants";
import { setIsSearchLoading } from "@store/reducers/nodeSelection/nodeSelection.slice";

export const testNodeSelection = createAsyncThunk(
  thunkPrefix.testNodeSelection,
  async () => {
    return "test";
  }
);

export interface ISearchMerchantThunk {
  body: SearchMerchantsRequest;
  type?: "searchFilter" | "create";
}

export interface ISearchMerchantResponseThunk {
  data: SearchMerchantsResponse;
  type: "searchFilter" | "create";
}

export enum TypeSearchFilterEnum {
  CREATE = "create",
  SEARCH_FILTER = "searchFilter",
}

let cancelToken: CancelTokenSource | null = null;

const executeCallMerchants = (
  request: ISearchMerchantThunk,
  entityName: EntityName,
  cancel: CancelTokenSource
) => {
  return axios.post(
    API_ROUTES.GET_MERCHANTS,
    {
      ...request.body,
      filter: {
        ...get(request, "body.filter", {}),
        ...(!isEqual(entityName, EntityName.OWNER) && {
          status: [StatusEnum.ACTIVE.toLowerCase()],
        }),
        entityName: [entityName],
      },
    },
    {
      cancelToken: cancel.token,
    }
  );
};

export const searchMerchants = createAsyncThunk<
  ISearchMerchantResponseThunk,
  ISearchMerchantThunk
>(
  thunkPrefix.searchMerchants,
  async (request: ISearchMerchantThunk, { dispatch }) => {
    if (cancelToken) {
      cancelToken.cancel("Nueva petición, cancelando la antigua");
    }
    cancelToken = axiosLib.CancelToken.source();
    if (request.type === TypeSearchFilterEnum.SEARCH_FILTER) {
      dispatch(setIsSearchLoading(true));
      const res = await Promise.all([
        executeCallMerchants(request, EntityName.OWNER, cancelToken),
        executeCallMerchants(request, EntityName.CUSTOMER, cancelToken),
        executeCallMerchants(request, EntityName.BRANCH, cancelToken),
        executeCallMerchants(request, EntityName.NA, cancelToken),
      ]);

      let total = 0;
      const concatData: Merchant[] = res.reduce((acc: Merchant[], cur) => {
        if (cur.data.data) {
          total += cur.data.total;

          return [...acc, ...cur.data.data];
        }

        return acc;
      }, []);

      return {
        data: { data: concatData, total },
        type: get(request, "type", TypeSearchFilterEnum.CREATE),
      };
    }
    const response: AxiosResponse<SearchMerchantsResponse> = await axios.post(
      API_ROUTES.GET_MERCHANTS,
      request.body,
      {
        cancelToken: cancelToken.token,
      }
    );

    return {
      data: response.data,
      type: get(request, "type", TypeSearchFilterEnum.CREATE),
    };
  }
);

export const queryMerchants = createAsyncThunk<
  SearchMerchantsResponse,
  SearchMerchantsRequest
>(thunkPrefix.queryMerchants, async (request: SearchMerchantsRequest) => {
  const response: AxiosResponse<SearchMerchantsResponse> = await axios.post(
    API_ROUTES.GET_MERCHANTS,
    request
  );

  return response.data;
});
