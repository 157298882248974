import React, { useEffect, useState } from "react";
import { TransactionsConciliationRequest } from "../../../../types/transactions_conciliation_request";
import updateTransactionsStatus from "../../../store/thunks/transactions/updateTransactionsStatus/update_transactions_status";
import { useDispatch } from "react-redux";
import { ConciliationActionEnum } from "../../../shared/enums/concilation_action_enum";
import { setConciliationAction } from "../../../store/actions/app/app";
import { Conciliation } from "../../../../types/get_conciliation_transactions_response";
import { IDialog } from "../../Dialogs/state/useDialogsState.interfaces";
import { ConciliationAction } from "../../../shared/infrastructure/constants/ConciliationAction";

export const usePendingConciliationTransactionsState = () => {
  const defaultTransaction: Conciliation = {
    approvalCode: "001",
    approvedTransactionAmount: 29900.0,
    cardHolderName: "fernando gutierrez",
    conciliationAction: "conciliationAction",
    conciliationCreation: 10000000123,
    conciliationId: "123123",
    country: "Mexico",
    created: "2021-10-07T22:05:37.871Z",
    currencyCode: "COP",
    document_number: "123123123",
    goldenRecordId: "123123",
    iceValue: "0",
    inconsistencyCode: "inconsistencyCode",
    issuingBank: "banco-de-bogota",
    ivaValue: 12,
    maskedCreditCard: "",
    merchantDescription: "Descripcion",
    merchantId: "929292929",
    merchantName: "Azucarerita",
    observationInfo: [
      {
        conciliationAction: ConciliationAction.REVIEW_PROCESSOR,
        modifiedDate: "1609459200000",
        reason: "review_processor",
        user: "userName1",
        userRole: "userRole1",
      },
      {
        conciliationAction: ConciliationAction.REVIEW_KUSHKI,
        modifiedDate: "1613174400000",
        reason: "review_kushki",
        user: "userName1",
        userRole: "userRole1",
      },
      {
        conciliationAction: ConciliationAction.APPROVED,
        modifiedDate: "1621209600000",
        reason: "approved",
        user: "userName2",
        userRole: "userRole2",
      },
      {
        conciliationAction: ConciliationAction.OMITTED,
        modifiedDate: "1632182400000",
        reason: "omitted",
        user: "userName3",
        userRole: "userRole3",
      },
      {
        conciliationAction: ConciliationAction.PENDING_REFUND,
        modifiedDate: "1640995200000",
        reason: "pending_refund",
        user: "userName4",
        userRole: "userRole4",
      },
    ],
    paymentMethod: "transfer",
    processorCode: "002",
    processorMessage: "",
    processorName: "PSE",
    purchaseNumber: "",
    responseCode: "responseCode",
    responseText: "responseText",
    source: "pending",
    status: "APPROVED",
    statusFlow: "string",
    subtotalIVA: 12100.0,
    subtotalIva0: 13300.0,
    transactionId: "transactionId",
    transactionReference: "51922485cead412795c7cc67ca5d2221",
    transactionStatusKushki: "",
    transactionStatusProcessor: "",
    transactionType: "transactionType",
    updatedAt: "",
  };

  const dispatch = useDispatch();
  const defaultRequest: TransactionsConciliationRequest = {
    action: "processConciliationTransactions",
    data: {
      conciliationAction: "pending_refund",
      data: [],
      source: "pending",
    },
  };
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openSelectedTransactionModal, setOpenSelectedTransactionModal] =
    useState<boolean>(false);
  const [isTooltipDisabled, setIsTooltipDisabled] = useState<boolean>(true);
  const [isRefundButtonDisabled, setIsRefundButtonDisabled] =
    useState<boolean>(false);
  const [selectedRowsCounter, setSelectedRowsCounter] = useState<number>(0);
  const [selectedRows, setSelectedRows] = React.useState<string[]>([]);
  const [slopesCounter, setSlopesCounter] = React.useState<number>(0);
  const [request, setRequest] =
    React.useState<TransactionsConciliationRequest>(defaultRequest);
  const [transaction, setTransaction] =
    useState<Conciliation>(defaultTransaction);
  const [open, setOpen] = useState<boolean>(false);
  const handleClose = () => {
    setOpen(false);
  };
  const [dialog, setDialog] = useState<IDialog>({
    buttonText: "",
    description: "",
    title: "",
    type: "",
  });
  const handleOpen = (currentTrx: Conciliation, dialogTemplate: IDialog) => {
    setTransaction(currentTrx);
    setDialog({
      ...dialogTemplate,
      merchantId: `Merchant ID: ${currentTrx.merchantId}`,
      merchantName: currentTrx.merchantName,
    });
    setOpen(true);
  };

  const handleOpenCloseModal = () => {
    setOpenModal(!openModal);
  };

  const handleOpenCloseSelectedTransaction = () => {
    setOpenSelectedTransactionModal(!openSelectedTransactionModal);
  };

  const handleSetIsTooltipDisabled = () => {
    setIsTooltipDisabled(false);
  };

  const handleUpdateConciliationStatus = () => {
    dispatch(setConciliationAction(ConciliationActionEnum.PENDING_REFUND));
    dispatch(updateTransactionsStatus(request));
  };

  useEffect(() => {
    setSelectedRowsCounter(selectedRows.length);
    const newDataForRequest: { conciliationId: string }[] = [];

    selectedRows.map((element) => {
      newDataForRequest.push({ conciliationId: element });
    });
    setRequest({
      ...request,
      data: { ...request.data, data: newDataForRequest },
    });
  }, [selectedRows]);

  return {
    dialogActions: {
      dialog,
      handleClose,
      handleOpen,
      open,
      transaction,
    },
    handlers: {
      handleOpenCloseModal,
      handleOpenCloseSelectedTransaction,
      handleSetIsTooltipDisabled,
      handleUpdateConciliationStatus,
    },
    isRefundButtonDisabled,
    isTooltipDisabled,
    openModal,
    openSelectedTransactionModal,
    selectedRowsCounter,
    setIsRefundButtonDisabled,
    setIsTooltipDisabled,
    setSelectedRows,
    setSlopesCounter,
    setTransaction,
    slopesCounter,
    transaction,
  };
};
