export enum TransactionFieldsEnum {
  status = "status",
  modify = "modify",
  amountRetIva = "amountRetIva",
  amountRetIvaDavi = "amountRetIvaDavi",
  baseAmountIva = "baseAmountIva",
  baseAmountIvaDavi = "baseAmountIvaDavi",

  amountRetIca = "amountRetIca",
  amountRetIcaDavi = "amountRetIcaDavi",
  baseAmountIca = "baseAmountIca",
  baseAmountIcaDavi = "baseAmountIcaDavi",

  amountRetSource = "amountRetSource",
  amountRetSourceDavi = "amountRetSourceDavi",
  baseAmountSource = "baseAmountSource",
  baseAmountSourceDavi = "baseAmountSourceDavi",
}
