import React, { useState } from "react";
import ModalPartnerBanks from "./ModalPartnerBanks/ModalPartnerBanks";
import { TableDeferred } from "./Table/TableDeferred";
import { DeferredOption } from "../../../../shared/infrastructure/interfaces/DeferredOptions";
import { CountryEnum } from "../../../../shared/infrastructure/catalogs/CountryEnum";

export interface IDeferredSection {
  deferred: DeferredOption[];
  maxElements: number;
  country: string;
}

export const DeferredSection: React.FC<IDeferredSection> = (
  props: IDeferredSection
) => {
  const [banks, setBanks] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleOpenModal = () => setOpenModal(!openModal);
  const handleSetBanks = (data: string[]) => {
    setBanks(data);
    setOpenModal(true);
  };
  const getHeaders = (country: string) => {
    if (country === CountryEnum.BRAZIL)
      return ["Procesador", "Fecha Programada", "Cuotas"];
    return ["Procesador", "Tipo", "Cuotas", ""];
  };

  return (
    <React.Fragment>
      <TableDeferred
        dataRows={props.deferred}
        dataHeaders={getHeaders(props.country)}
        maxElements={props.maxElements}
        handleSetBank={handleSetBanks}
        country={props.country}
      />
      <ModalPartnerBanks
        open={openModal}
        handleClose={handleOpenModal}
        banks={banks}
      />
    </React.Fragment>
  );
};
