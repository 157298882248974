import { useEffect, useState } from "react";

export interface OnChangeTimePickerTagState {
  onChange(value: string[]): void;
}

export interface TimeTagState {
  value: string;
  type: string;
}

export interface ITimeTagState {
  concatenateTimeStamp(value: TimeTagState): void;
}

let startTime = "";
let endTime = "";

export const useTimeTagSate = (
  props: OnChangeTimePickerTagState
): ITimeTagState => {
  const [transactionSchedule, setTransactionScheduleFinal] = useState<string[]>(
    []
  );

  useEffect(() => {
    props.onChange(transactionSchedule);
  }, [transactionSchedule]);

  const concatenateTimeStamp = (props: TimeTagState): void => {
    switch (props.type) {
      case "start":
        startTime = props.value;
        break;
      default:
        endTime = props.value;
        break;
    }

    setTransactionScheduleFinal([startTime, endTime]);
  };

  return {
    concatenateTimeStamp,
  };
};
