import { makeStyles } from "@material-ui/core/styles";

export const filterComponentStyle = makeStyles({
  filterButton: {
    backgroundColor: "#FFFFFF",
    height: "36px",
  },
  buttonText: {
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: "14px !important",
    lineHeight: "140% !important",
    color: "#293036 !important",
  },
  mainGrid: {
    minWidth: "400px",
    maxWidth: "500px",
    padding: "12px",
  },
  filterByText: {
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#293036",
  },
  expandedCollapsed: {
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "13px",
    lineHeight: "16px",
    color: "#023365",
  },
  gridRight: {
    textAlign: "right",
  },
  firstDivider: {
    color: "#F0F4F9",
  },
  secondDivider: {
    color: "#CBD5E0",
  },
  chip: {
    borderRadius: "4px",
    backgroundColor: "#F7FAFC",
    padding: "8px",
    width: "auto",
    height: "30px",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "12px",
    lineHeight: "17px",
    color: "#677684",
  },
  chipSelected: {
    borderRadius: "4px",
    backgroundColor: "#0DC298",
    padding: "8px",
    width: "auto",
    height: "30px",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "12px",
    lineHeight: "17px",
    color: "#FFFFFF",
  },
  nonSpace: {
    margin: "0px",
    padding: "0px",
  },
  applyButton: {
    marginTop: "8px",
    width: "150px",
    fontWeight: 500,
    fontSize: "14px",
  },
  deleteButton: {
    marginTop: "8px",
    width: "150px",
    fontWeight: 500,
    fontSize: "14px",
    color: "#293036",
  },
});
