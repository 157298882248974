import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const informationTabStyles = createNamedStyles({
  container: {
    height: "80%",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    overflowY: "auto",
  },
});
