import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Popper,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import DownloadIcon from "../../../assets/DownloadIcon";
import { DownloadButtonProps } from "./DownloadButton.interfaces";
import { downloadButtonStyles } from "./DownloadButton.styles";
import useDownloadButton from "./useDownloadButton/useDownloadButton";
import { callbackListDownload } from "./callbackListDownload/callbackListDownload";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store.interfaces";

const DownloadButton: FC<DownloadButtonProps> = ({
  options,
  title = "Descargar",
  handleClick,
  disabled,
}: DownloadButtonProps) => {
  const {
    actions: { handleClose, handleMainButton, handleMenuItemClick },
    anchorRef,
    open,
    selectedIndex,
  } = useDownloadButton({ handleClick, options });
  const app = useSelector((state: RootState) => state.app);

  return (
    <>
      <ButtonGroup
        ref={anchorRef}
        variant="text"
        aria-label="text button group"
        disabled={disabled || app.loadingDownload}
      >
        <Button
          id={"buttonDownload"}
          sx={downloadButtonStyles.containerButton}
          size={"medium"}
          startIcon={<DownloadIcon />}
          variant="outlined"
          onClick={handleMainButton}
        >
          <Typography variant="body1">{title}</Typography>
          {app.loadingDownload && (
            <Box sx={downloadButtonStyles.boxCircularColor}>
              <CircularProgress
                sx={downloadButtonStyles.circularColor}
                thickness={4}
                size={24}
              />
            </Box>
          )}
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {callbackListDownload({
          handleClose,
          handleMenuItemClick,
          id: "split-button-menu",
          options,
          selectedIndex,
        })}
      </Popper>
    </>
  );
};

export default DownloadButton;
