import { BreadcrumProps } from "../../../components/common/Breadcrumb/Breadcrumb";
import { BreadCrumsItems } from "../../../shared/infrastructure/constants/BreadCrums";
import { useHistory, useLocation } from "react-router-dom";
import { CountryValueEnum } from "../../../shared/infrastructure/Enums/CountryEnum";
import { getCountry } from "../../../shared/infrastructure/constants/MerchantContactConstants";
import { routes } from "../../../shared/infrastructure/routes";
import { KeyValue } from "../../../../types/key_value";
import React, { RefObject, useEffect, useState } from "react";
import { FieldError, NestDataObject, useForm } from "react-hook-form";
import { get, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { RetentionMerchantConfigDynamo } from "../../../../types/retention_merchant_configuration";
import {
  createUpdateRetailsConfig,
  getCatalogsList,
  setNotificationConfig,
  setOpenModalConfirmAddConfig,
} from "../../../store/actionCreators";
import { CatalogsRequestEnum } from "../../../shared/infrastructure/Enums/CatalogsRequestEnum";
export interface IUploadFormEditIndexState {
  breadCrumbs: BreadcrumProps;
  country: CountryValueEnum;
  retentionConfig: RetentionMerchantConfigDynamo | unknown;
  methods: {
    onSubmit: () => void;
    returnTo: (route: string) => void;
    handleOnChangeSelection: (
      taxpayer: KeyValue | null,
      identifier: string
    ) => void;
    handleValueRetentionChange: (value: string, identifier: string) => void;
  };
  values: {
    taxpayerValue?: KeyValue | null;
    activityValue?: KeyValue | null;
    incomeSourceConceptValue?: KeyValue | null;
    ivaRetentionValue?: string;
    rentRetentionValue?: string;
    isDataSaved?: boolean;
  };
  form: {
    errors: NestDataObject<Record<string, any>, FieldError>;
    register: (
      obj: object
    ) => ((instance: any) => void) | RefObject<any> | null | undefined;
  };
  modalDialogConfigAdd: {
    openModalConfigAdd: boolean;
    handleCloseModalConfigAdd: () => void;
  };
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const useFormEditIndexState = (): IUploadFormEditIndexState => {
  const stateStore = useSelector<IAppState, IAppState>(
    (state: IAppState) => state
  );
  const retentionConfig = useSelector<IAppState>(
    (state) => state.retentionConfig
  );
  const { notificationConfig } = useSelector((state: IAppState) => state);

  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const [taxpayerValue, setTaxpayerValue] = React.useState<KeyValue | null>({
    key: get(retentionConfig, "taxpayerTypeId", "0"),
    value: "",
  });
  const [activityValue, setActivityValue] = React.useState<KeyValue | null>({
    key: get(retentionConfig, "activityId", "0"),
    value: "",
  });
  const [
    incomeSourceConceptValue,
    setIncomeSourceConceptValue,
  ] = React.useState<KeyValue | null>({
    key: get(retentionConfig, "incomeSourceConceptId", "0"),
    value: "",
  });
  const { register, errors, setError, triggerValidation } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const [ivaRetentionValue, setIvaRetentionValue] = useState("");
  const [rentRetentionValue, setRentRetentionValue] = useState("");
  const [isDataSaved, setIsDataSaved] = useState(false);
  const country: CountryValueEnum = query.get("country") as CountryValueEnum;

  const handleOnChangeSelection = (
    taxpayer: KeyValue | null,
    identifier: string
  ) => {
    switch (identifier) {
      case "0":
        setTaxpayerValue(taxpayer);
        break;
      case "1":
        setActivityValue(taxpayer);
        break;
      case "2":
        setIncomeSourceConceptValue(taxpayer);
        break;
    }
  };

  const handleValueRetentionChange = (value: string, identifier: string) => {
    const vawr: string[] = (value + "").split(".");
    if (
      !Number(value) ||
      Number(value) > 100 ||
      get(vawr, "[1]", 0).length > 2
    ) {
      switch (identifier) {
        case "0":
          setError("ivaRetention", "required", "Ingrese caracteres válidos.");
          break;
        case "1":
          setError("rentRetention", "required", "Ingrese caracteres válidos.");
          break;
      }
      value = value.substring(0, value.length - 1);
    }
    switch (identifier) {
      case "0":
        setIvaRetentionValue(value);
        break;
      case "1":
        setRentRetentionValue(value);
        break;
    }
  };

  const handleCloseModalConfigAdd = () => {
    dispatch(setOpenModalConfirmAddConfig(false));
    returnTo(
      `${routes.BASE_PATH_RETENTIONS}${routes.DASHBOARD}?country=${country}`
    );
  };

  const onSubmit = () => {
    triggerValidation().then(() => {
      if (!isEmpty(errors)) return;
      const id = `${get(taxpayerValue, "key", "")}${get(
        activityValue,
        "key",
        ""
      )}${get(incomeSourceConceptValue, "key", "")}`.toLowerCase();

      const createUpdateRequest = {
        country: country.toLowerCase() as CountryValueEnum,
        username: localStorage.getItem("username"),
        configs: [
          {
            activityId: get(activityValue, "key", "0"),
            incomeSourceConceptId: get(incomeSourceConceptValue, "key", "0"),
            retIva: ivaRetentionValue,
            retFue: rentRetentionValue,
            taxpayerTypeId: get(taxpayerValue, "key", "0"),
          },
        ],
      };
      const fetchDataRetailsConfig = async () => {
        setIsDataSaved(true);
        await dispatch(createUpdateRetailsConfig(id, createUpdateRequest));
      };
      fetchDataRetailsConfig().finally(() => {
        setIsDataSaved(false);
      });
    });
  };
  const returnTo = (route: string) => {
    dispatch(
      setNotificationConfig({
        message: "No hubo cambios en la configuración",
        open: true,
        type: "dark",
      })
    );
    history.push(route);
  };

  useEffect(() => {
    if (get(notificationConfig, "open", false))
      history.push(
        `${routes.BASE_PATH_RETENTIONS}${routes.DASHBOARD}?country=${country}`
      );
  }, [notificationConfig]);

  useEffect(() => {
    dispatch(
      getCatalogsList({
        productLine: CatalogsRequestEnum.PLA,
        configCode: CatalogsRequestEnum.CN003,
        countryCode: CatalogsRequestEnum.ECUADOR,
      })
    );
    setTaxpayerValue({
      key: get(retentionConfig, "taxpayerTypeId", "0"),
      value: "",
    });
    setActivityValue({
      key: get(retentionConfig, "activityId", "0"),
      value: "",
    });
    setIncomeSourceConceptValue({
      key: get(retentionConfig, "incomeSourceConceptId", "0"),
      value: "",
    });
    setIvaRetentionValue(get(retentionConfig, "retIva", "0"));
    setRentRetentionValue(get(retentionConfig, "retFue", "0"));
  }, []);
  return {
    country,
    breadCrumbs: {
      items: [
        BreadCrumsItems.GlobalConfig,
        { ...BreadCrumsItems.Country, label: getCountry(country).name },
        {
          ...BreadCrumsItems.RetentionsMerchant.label[
            getCountry(country).value
          ],
          url: `${routes.BASE_PATH_RETENTIONS}${routes.DASHBOARD}?country=${country}`,
        },
      ],
      lastItem: BreadCrumsItems.EditWithForm.label,
    },
    retentionConfig,
    methods: {
      onSubmit,
      returnTo,
      handleOnChangeSelection,
      handleValueRetentionChange,
    },
    values: {
      taxpayerValue,
      activityValue,
      incomeSourceConceptValue,
      ivaRetentionValue,
      rentRetentionValue,
      isDataSaved,
    },
    form: {
      errors,
      register,
    },
    modalDialogConfigAdd: {
      openModalConfigAdd: get(stateStore, "isOpenModalConfirmAddConfig", false),
      handleCloseModalConfigAdd,
    },
  };
};
