import { StatusEnum } from "../../../shared/enums/StatusEnum";
import React from "react";
import { Chip } from "@mui/material";
import { defaultTo } from "lodash";
import { CatalogTranslateStatus } from "../../../shared/catalogs/CatalogTranslateStatus";

const getColorByStatus: Record<StatusEnum, string> = {
  [StatusEnum.ACTIVE]: "success",
  [StatusEnum.INACTIVE]: "error",
  [StatusEnum.PENDING]: "warning",
};

export interface IStatusEntity {
  status?: string;
}

export const StatusEntity: React.FC<IStatusEntity> = ({
  status,
}: IStatusEntity) => {
  const color_status = defaultTo(getColorByStatus[defaultTo(status, "")], "");
  const label_text: string = defaultTo(
    CatalogTranslateStatus[defaultTo(status, "")],
    ""
  );

  return <Chip label={label_text} size="small" color={color_status} />;
};
