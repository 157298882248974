export enum WebhookModalDelete {
  TITLE = "¿Deseas eliminar este webhook?",
  DESCRIPTION = "Si eliminas este webhook, estarás eliminando la notificación por eventos que fueron configurados. ",
  BTN_PRIMARY = "Eliminar",
  BTN_SECONDARY = "Cancelar",
}

export enum WebhookModalLoader {
  TITLE = "Estamos eliminando este webhook",
  DESCRIPTION = "Este proceso puede tardar unos minutos...",
}

export enum WebhookSnackbarStatus {
  SUCCESS = "Webhook eliminado",
  ERROR = "Ha ocurrido un error",
}
