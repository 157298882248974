import React, { FC, PropsWithChildren } from "react";
import { Grid, Typography } from "@mui/material";
import { accordionBodyValues } from "../../shared/constants/ProcessorConstants";
import { accordionBodyStyles } from "./AccordionBody.styles";
import { IAccordionBodyProps } from "./AccordionBody.interface";

export const AccordionBody: FC<IAccordionBodyProps> = (
  props: PropsWithChildren<IAccordionBodyProps>
) => {
  const classes = accordionBodyStyles();

  return (
    <Grid container direction={"row"} spacing={1} alignItems={"start"}>
      {accordionBodyValues(props.processor).map(
        (data: { title: string; value: string }, index: number) => (
          <React.Fragment key={`${data.title}-${index}`}>
            <Grid item xs={3}>
              <Typography
                className={classes.title}
              >{`${data.title}:`}</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography className={classes.description}>
                {data.value}
              </Typography>
            </Grid>
          </React.Fragment>
        )
      )}
    </Grid>
  );
};
