import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "@kushki/connect-ui";
import { SvgIcon } from "@mui/material";

const SquareCrossIcon: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <SvgIcon
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        sx={{ fontSize: "24px !important" }}
      >
        <path
          d="M15.3333 18H8.66667C7.95942 18 7.28115 17.719 6.78105 17.219C6.28095 16.7189 6 16.0406 6 15.3333V8.66667C6 7.95942 6.28095 7.28115 6.78105 6.78105C7.28115 6.28095 7.95942 6 8.66667 6H14C14.1768 6 14.3464 6.07024 14.4714 6.19526C14.5964 6.32029 14.6667 6.48986 14.6667 6.66667C14.6667 6.84348 14.5964 7.01305 14.4714 7.13807C14.3464 7.2631 14.1768 7.33333 14 7.33333H8.66667C8.31304 7.33333 7.97391 7.47381 7.72386 7.72386C7.47381 7.97391 7.33333 8.31304 7.33333 8.66667V15.3333C7.33333 15.687 7.47381 16.0261 7.72386 16.2761C7.97391 16.5262 8.31304 16.6667 8.66667 16.6667H15.3333C15.687 16.6667 16.0261 16.5262 16.2761 16.2761C16.5262 16.0261 16.6667 15.687 16.6667 15.3333V14C16.6667 13.8232 16.7369 13.6536 16.8619 13.5286C16.987 13.4036 17.1565 13.3333 17.3333 13.3333C17.5101 13.3333 17.6797 13.4036 17.8047 13.5286C17.9298 13.6536 18 13.8232 18 14V15.3333C18 16.0406 17.719 16.7189 17.219 17.219C16.7189 17.719 16.0406 18 15.3333 18Z"
          fill="#E24763"
        />
        <path
          d="M14.826 15.4932C14.6511 15.4925 14.4835 15.423 14.3594 15.2999L8.69937 9.63986C8.59015 9.51232 8.53308 9.34827 8.53956 9.18049C8.54604 9.0127 8.6156 8.85354 8.73432 8.73481C8.85305 8.61608 9.01221 8.54653 9.18 8.54005C9.34778 8.53357 9.51183 8.59064 9.63937 8.69986L15.3327 14.3599C15.3993 14.4222 15.4524 14.4976 15.4887 14.5813C15.525 14.665 15.5437 14.7553 15.5437 14.8465C15.5437 14.9378 15.525 15.028 15.4887 15.1117C15.4524 15.1955 15.3993 15.2708 15.3327 15.3332C15.2637 15.3932 15.1832 15.4383 15.096 15.4658C15.0088 15.4934 14.9169 15.5027 14.826 15.4932Z"
          fill="#E24763"
        />
        <path
          d="M9.17305 15.4936C9.0005 15.5036 8.83079 15.4463 8.69972 15.3336C8.57555 15.2087 8.50586 15.0397 8.50586 14.8636C8.50586 14.6875 8.57555 14.5185 8.69972 14.3936L14.3597 8.7336C14.483 8.6182 14.6456 8.5539 14.8145 8.55371C14.9834 8.55352 15.1461 8.61744 15.2696 8.73256C15.3932 8.84769 15.4685 9.00543 15.4803 9.17391C15.4921 9.3424 15.4394 9.50908 15.3331 9.64027L9.63972 15.3336C9.51035 15.4446 9.34335 15.5019 9.17305 15.4936Z"
          fill="#E24763"
        />
      </SvgIcon>
    </ThemeProvider>
  );
};

export default SquareCrossIcon;
