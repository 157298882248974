import React, { FC } from "react";
import { Box } from "@mui/material";
import { useTitle } from "./useState/useTitle";
import { Title } from "@kushki/connect-ui";
import { titleComponentStyles } from "./TitleComponent.styles";
import { TitleComponentProps } from "./TitleComponent.interfaces";
import { MaskSkeleton } from "../MaskSkeleton/MaskSkeleton";

const TitleComponent: FC<TitleComponentProps> = (
  props: TitleComponentProps
) => {
  const { onButtonClick } = useTitle();

  return (
    <React.Fragment>
      <Box sx={titleComponentStyles.titleInfo}>
        <MaskSkeleton
          sx={titleComponentStyles.titleSkeleton}
          isLoading={props.isLoading}
        >
          <Title
            items={[
              { id: "1", isDisabled: true, text: "Comercio", url: "" },
              { id: "1", isDisabled: true, text: "Crear Comercio", url: "" },
              {
                id: "1",
                isDisabled: true,
                text: props.headerTitle,
                url: "",
              },
            ]}
            onButtonClick={onButtonClick}
            onButtonClickSecondary={onButtonClick}
            title={props.headerTitle}
            type="titleBase"
          />
        </MaskSkeleton>
      </Box>
    </React.Fragment>
  );
};

export default TitleComponent;
