import React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { SubHeaderSkeleton } from "../SubHeaderSkeleton/SubHeaderSkeleton";
import { FormSkeleton } from "../FormSkeleton/FormSkeleton";
import { mainContainerStyles as styles } from "../../../containers/MainContainer/MainContainer.styles";
import { basicContainerStyles } from "../../../containers/BasicContainer/BasicContainer.styles";

export const BasicContainerSkeleton: React.FC = () => {
  return (
    <Card sx={styles.cardContent}>
      <CardContent>
        <Box sx={styles.boxContent2}>
          <Typography sx={basicContainerStyles.title}>
            <Skeleton />
          </Typography>
          <Grid
            container
            spacing={1}
            alignItems="center"
            sx={{ padding: "15px 5px", width: "100%" }}
          >
            <Grid md={12} xs={12} item>
              <SubHeaderSkeleton />
              <Grid
                container
                spacing={1}
                alignItems="center"
                sx={{ marginTop: "1em", width: "60%" }}
              >
                <Grid item sx={{ pt: "32px" }} md={12} xs={12}>
                  <Typography>
                    <Skeleton />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              md={12}
              xs={12}
              item
              sx={{ marginBottom: "3em", marginTop: "2em" }}
            >
              <FormSkeleton rows={8} />
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};
