import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/hooks/storeHook";
import { RootState } from "@store/store";
import {
  IRange,
  IUseNodeSelection,
} from "@containers/NodeSelection/state/useNodeSelection.interfaces";
import { ModeEnum } from "@shared/enum/modeEnum";
import {
  setActualView,
  setIsLoadingTable,
  setMode,
  setNodeType,
  setSearchNode,
  setSelectedNodes,
  setViewData,
} from "@store/reducers/nodeSelection/nodeSelection.slice";
import { EntityName } from "@shared/enum/entityNameEnum";
import {
  cloneDeep,
  concat,
  defaultTo,
  get,
  isEmpty,
  set,
  uniq,
  uniqBy,
  unset,
} from "lodash";
import { searchMerchants } from "@store/thunks/nodeSelection/nodeSelection.thunk";
import { NodeSelectionLabels } from "@shared/constants/labels/nodeSelectionLabels";
import { ITableRowProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { buildRows } from "@shared/utils/buildTableUtils";
import { INodeRuleSessionStorage } from "@shared/interfaces/INodeRuleSessionStorage";
import { BASE_ROUTES } from "@shared/constants/routes";
import { useNavigate, useParams } from "react-router-dom";
import { MerchantStatusEnum } from "@shared/enum/MerchantStatusEnum";
import { clearSelectedNodes } from "@shared/utils/clearSelectedNodes";
import { TABLE_ITEMS_PER_PAGE } from "@shared/constants/tableHeaderConstants";
import {
  Merchant,
  SearchMerchantsResponse,
} from "../../../../types/search_merchants_response";
import { SearchMerchantsRequest } from "../../../../types/search_merchants_request";
import { getItemSessionStorage } from "@shared/utils/sessionStorageUtil";
import { SessionStorageKey } from "@shared/enum/sessionStorageKeyEnum";
import {
  buildBranchData,
  buildSelectedNodesByEntityName,
  INodeSelectedParsed,
} from "@shared/utils/selectedNodesUtil";

export const useNodeSelection = (): IUseNodeSelection => {
  const {
    viewData,
    searchNode,
    actualView,
    mode,
    searchResultTitle,
    nodeType,
    selectedNodes,
    isLoadingTable,
  } = useAppSelector((state: RootState) => state.nodeSelection);
  let { type } = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [actualSelectedRows, setActualSelectedRows] = useState<string[]>([]);

  const [rows, setRows] = useState<ITableRowProps[]>(
    buildRows(get(viewData, "data", []), "nodeId", actualView, selectedNodes)
  );
  const [deselectedRows, setDeselectedRows] = useState<string[]>([]);

  const [searchValue, setSearchValue] = useState<string>("");
  const [localSearchValue, setLocalSearchValue] = useState<string>("");

  const [tempRows, setTempRows] = useState<ITableRowProps[]>(
    buildRows(get(viewData, "data", []), "nodeId", actualView, selectedNodes)
  );

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [range, setRange] = useState<IRange>({
    limit: 10,
    offset: 1,
  });

  useEffect(() => {
    if (viewData!.data!.length > 0) {
      dispatch(setIsLoadingTable(true));
    }
    setRows(
      buildRows(get(viewData, "data", []), "nodeId", actualView, selectedNodes)
    );
    setTempRows(
      buildRows(get(viewData, "data", []), "nodeId", actualView, selectedNodes)
    );

    if (viewData!.data!.length > 0) {
      dispatch(setIsLoadingTable(false));
    }
  }, [viewData]);

  useEffect(() => {
    dispatch(
      setSelectedNodes(
        getItemSessionStorage(SessionStorageKey.SELECTED_NODES, "[]")
      )
    );
  }, []);

  const filterSelectedNodes = (): SearchMerchantsResponse => {
    const dataParsed: INodeSelectedParsed =
      buildSelectedNodesByEntityName(selectedNodes);

    const dataFiltered: INodeRuleSessionStorage[] =
      dataParsed[actualView.toLowerCase()];

    return {
      data: buildBranchData(dataFiltered) as Merchant[],
      total: dataFiltered.length,
    };
  };

  useEffect(() => {
    if (mode === ModeEnum.SELECTED) {
      dispatch(setViewData(filterSelectedNodes()));
    } else if (mode === ModeEnum.SEARCH) {
      const request: SearchMerchantsRequest = {
        checkActiveBranch: [EntityName.OWNER, EntityName.CUSTOMER].includes(
          <EntityName>nodeType
        ),
        filter: {
          entityName:
            nodeType === EntityName.BRANCH
              ? [EntityName.BRANCH, EntityName.NA]
              : [nodeType],
          name: searchNode,
          ...(nodeType != EntityName.OWNER
            ? {
                status: [MerchantStatusEnum.active],
              }
            : {}),
        },
        mappedNodeId: nodeType === EntityName.BRANCH,
        ...range,
      };

      if (isEmpty(searchNode)) unset(request, "filter.name");

      dispatch(searchMerchants({ body: request }));
    } else {
      dispatch(setIsLoadingTable(false));
      setRows([]);
    }

    return () => {
      setLocalSearchValue("");
    };
  }, [searchNode, actualView, range, mode]);

  useEffect(() => {}, [mode]);

  useEffect(() => {
    if (!isEmpty(deselectedRows))
      setRows((prevState: ITableRowProps[]) => {
        prevState.forEach((row) => {
          if (deselectedRows.includes(row.id))
            set(row, "rowProps.isChecked", false);
        });

        return cloneDeep(prevState);
      });
  }, [deselectedRows]);

  const handleReturn = () => {
    setSearchValue("");
    dispatch(setSearchNode(""));
  };
  const resetPagination = () => {
    setRange({ limit: 10, offset: 1 });
    setSelectedPage(1);
  };

  const handleChangeNodeInput = (value: string) => {
    resetPagination();
    dispatch(setActualView(value));
    dispatch(setNodeType(value));
    dispatch(setIsLoadingTable(true));
    dispatch(setViewData({ data: [] }));
    dispatch(setMode(ModeEnum.SEARCH));
  };

  const handleChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleSetSearchNodeStore = (value: string) => {
    resetPagination();
    dispatch(setIsLoadingTable(true));
    dispatch(setViewData({ data: [] }));
    dispatch(setSearchNode(value));
    dispatch(setMode(ModeEnum.SEARCH));
  };

  const handleResetStore = () => {
    dispatch(setViewData({ data: [] }));
    dispatch(setSearchNode(""));
    dispatch(setMode(ModeEnum.SEARCH));
    dispatch(setActualView(EntityName.OWNER));
    dispatch(setNodeType(EntityName.OWNER));
  };

  const handleSubmitSearchInput = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (searchNode !== searchValue) handleSetSearchNodeStore(searchValue);
  };

  const searchRef = useRef(true);

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current = false;

      return;
    }

    if (isEmpty(searchValue)) {
      handleSetSearchNodeStore(searchValue);
    }
  }, [searchValue]);

  const onSelectedRows = (selectedRows: string[]) => {
    if (
      isEmpty(selectedRows) &&
      rows.some((row) => actualSelectedRows.includes(row.id))
    )
      setDeselectedRows(actualSelectedRows);
    setActualSelectedRows(selectedRows);
  };

  const onSelectedRow = (selectedRow: string) => {
    if (
      selectedNodes.some((nodeRow) => nodeRow.nodeId.includes(selectedRow)) ||
      (rows.some((row) => row.id.includes(selectedRow)) &&
        !actualSelectedRows.find((actSelRow) =>
          actSelRow.includes(selectedRow)
        ))
    ) {
      setDeselectedRows(
        selectedNodes
          .filter((rowSel) => selectedRow.includes(rowSel.nodeId))
          .map(({ nodeId }) => nodeId)
      );
    }
  };

  const handleSelectedNodes = (selectedRows: string[]) => {
    let builtSelected: INodeRuleSessionStorage[] = [];

    uniq(selectedRows).map((rowId) => {
      const matchViewData: Merchant | undefined = get(
        viewData,
        "data",
        []
      ).find((item) => item.nodeId === rowId);

      const entityName: EntityName = <EntityName>(
        get(matchViewData, "entityName", "")
      );

      if (!isEmpty(matchViewData)) {
        const preparedSelected: INodeRuleSessionStorage = {
          category: get(matchViewData, "categoryMerchant", ""),
          constitutionalCountry: get(
            matchViewData,
            "constitutionalCountry",
            ""
          ),
          country: get(matchViewData, "country", ""),
          entityName,
          merchantId: get(matchViewData, "publicMerchantId", ""),
          name: get(matchViewData, "name", ""),
          nodeId: get(matchViewData, "nodeId", ""),
          path:
            entityName === EntityName.NA
              ? EntityName.NA
              : get(matchViewData, "path", ""),
        };

        builtSelected.push(preparedSelected);
      }
    });

    setDeselectedRows([]);

    return uniqBy(
      concat(
        builtSelected.filter((item) => !selectedNodes.includes(item)),
        selectedNodes.filter((item) => !deselectedRows.includes(item.nodeId))
      ),
      "nodeId"
    );
  };

  useEffect(() => {
    if (
      !isEmpty(actualSelectedRows) ||
      rows.some((row) => deselectedRows.includes(row.id))
    ) {
      dispatch(setSelectedNodes(handleSelectedNodes(actualSelectedRows)));
    }
  }, [actualSelectedRows]);

  const onChangeLocalInput = (event: object) => {
    const inputValue: string = get(event, "target.value", "");

    setLocalSearchValue(inputValue);

    if (isEmpty(inputValue)) {
      setRows(tempRows);

      return;
    }

    const filteredRows: ITableRowProps[] = tempRows.filter((row) =>
      get(row, "cells[0].props.line1", "")
        .toLowerCase()
        .includes(inputValue.toLowerCase())
    );

    setRows(filteredRows);
  };

  const handleNextButton = () => {
    handleResetStore();
    sessionStorage.setItem("selectedNodes", JSON.stringify(selectedNodes));
    navigate(
      `${BASE_ROUTES.CONFIG}/${defaultTo(
        type,
        ""
      )}?hideTopBar=true&hideSideBar=true`
    );
  };

  const handleCancelButton = () => {
    clearSelectedNodes(dispatch);
    navigate(`/${defaultTo(type, "")}`);
  };

  const handleSelectedPage = (value: number) => {
    setSelectedPage(value);
    setRange({
      ...range,
      offset: value,
    });
  };

  const handleItemsPerPage = (value: number) => {
    setSelectedPage(1);
    setRange({
      limit: value,
      offset: 1,
    });
  };

  const handleSelectNodeView = (mode: string, actualView: string) => {
    dispatch(setMode(mode));
    dispatch(setActualView(actualView));
  };

  const handleClearSelectedNodes = () => {
    dispatch(setSelectedNodes([]));
    setActualSelectedRows([]);
    setDeselectedRows([]);
    sessionStorage.removeItem(SessionStorageKey.SELECTED_NODES);
    setRows((prevState: ITableRowProps[]) => {
      prevState.forEach((row) => {
        set(row, "rowProps.isChecked", false);
      });

      return cloneDeep(prevState);
    });
    if (mode === ModeEnum.SELECTED) {
      handleResetStore();
      setSearchValue("");
    }
  };

  return {
    actualView,
    footer: {
      handleCancelButton,
      handleNextButton,
    },
    handleChangeNodeInput,
    handleClearSelectedNodes,
    handleReturn,
    handleSelectNodeView,
    isLoadingTable,
    localSearchValue,
    nodeType,
    onChangeLocalInput,
    onSelectedRow,
    onSelectedRows,
    rows,
    searchInputProps: {
      handleChangeSearchValue,
      handleSubmitSearchInput,
      label: NodeSelectionLabels.SEARCH_LABEL,
      searchValue,
    },
    searchResultTitle,
    selectedNodes,
    showSearchResult:
      !isEmpty(searchNode) &&
      mode === ModeEnum.SEARCH &&
      searchResultTitle.isSuccess,
    showSelectedNodesSection: selectedNodes.length > 0,
    tableFooter: {
      handleItemsPerPage,
      handleSelectedPage,
      itemsPerPage: defaultTo(range.limit, 10),
      selectedPage,
      textFieldSelector: TABLE_ITEMS_PER_PAGE,
      total: searchResultTitle.total,
    },
  };
};
