import { CountriesEnum } from "../infrastructure/countries-enum";
import { defaultTo, get, isEmpty } from "lodash";
import { IGetMerchantSettingsDataResponse } from "../../../types/get_merchant_settings_data_response";

export const formatCurrencyWallet = (
  amount: number,
  country: string
): string => {
  const formattedAmount: string =
    country === CountriesEnum.CHILE
      ? Intl.NumberFormat("es", {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
          style: "decimal",
          useGrouping: true,
        }).format(amount)
      : Intl.NumberFormat("en", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(amount);

  return Number(formattedAmount) !== 0 ? formattedAmount : "0";
};
export const getFromLodash = (
  object: object,
  path: string,
  defaultValue: string | number | Array<never> | object
): string | number | Array<never> | object => {
  return defaultTo(get(object, path), defaultValue);
};

export const hasCashOrTransferPayoutsProcessors = (
  merchantSettingsData: IGetMerchantSettingsDataResponse
): boolean => {
  return (
    !isEmpty(
      getFromLodash(merchantSettingsData, "processors.payouts-cash", "[]")
    ) ||
    !isEmpty(
      getFromLodash(merchantSettingsData, "processors.payouts-transfer", "[]")
    )
  );
};
