import React from "react";
import { Box, Chip, Tab, Tabs, Typography } from "@mui/material";
import {
  DetailHeaderStyles,
  useChipDetailsStyles,
} from "./DetailHeader.styles";
import { transactionTitleMap } from "../../../../shared/constants/transactionTitleMap";
import { DetailHeaderProps } from "./DetailHeader.interfaces";
import {
  CONCILIATION_ACTION_TRANSLATE,
  ConciliationAction,
} from "../../../../shared/infrastructure/constants/ConciliationAction";

const DetailHeader = ({
  currentTab,
  handleChangeTab,
  headerLineText,
  subheaderLineText,
  title,
  conciliationAction,
}: DetailHeaderProps) => {
  const status: string = CONCILIATION_ACTION_TRANSLATE[conciliationAction];
  const classes = useChipDetailsStyles({ status: conciliationAction });

  return (
    <Box sx={DetailHeaderStyles.container}>
      <Box sx={DetailHeaderStyles.titleContainer}>
        <Typography sx={DetailHeaderStyles.title}>{title}</Typography>
        <Typography sx={DetailHeaderStyles.headerLine}>
          {headerLineText}
        </Typography>
        <Typography sx={DetailHeaderStyles.subheaderLine}>
          {subheaderLineText}
        </Typography>
        <div className="modal-header-status-value">
          <Chip className={classes.chip} label={status} />
        </div>
      </Box>
      <Box sx={DetailHeaderStyles.tabsContainer}>
        <Tabs variant="fullWidth" value={currentTab} onChange={handleChangeTab}>
          <Tab label="Información" value={0} sx={DetailHeaderStyles.tab} />
          {status !==
            transactionTitleMap[ConciliationAction.PENDING_REFUND] && (
            <Tab
              label="Línea de tiempo"
              value={1}
              sx={DetailHeaderStyles.tab}
            />
          )}
        </Tabs>
      </Box>
    </Box>
  );
};

export default DetailHeader;
