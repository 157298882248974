import { IUseTransactionSnackBar } from "./useTransactionSnackBar.interfaces";
import { useEffect, useState } from "react";

export const useTransactionSnackBar = (
  show: boolean
): IUseTransactionSnackBar => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => setIsOpen(show), [show]);
  const handleClose = () => {
    setIsOpen(false);
  };

  return {
    handleClose,
    isOpen,
  };
};
