import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const brazilDeferredInfoStyles = createNamedStyles({
  imgCardBrand: {
    width: 80,
    height: 40,
  },
  monthBox: {
    pb: 2,
    display: "flex",
    flexDirection: "column",
    rowGap: "15px",
  },
  mainGrid: {
    direction: "row",
    spacing: 3,
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
  },
  boxDetails: {
    marginLeft: "20px",
    px: 2,
    pt: 3,
    pb: 2,
    bgcolor: "#F7FAFC",
  },
  subtitle: {
    color: "#6D7781",
  },
});
