import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { validateIcon } from "../../shared/utils/utils";
import { DEFERRED_LABELS } from "../../shared/constants/labels/deferred_info_labels";
import MonthsList from "../MonthsList/MonthsList";
import { brazilDeferredInfoStyles } from "./BrazilDeferredInfo.styles";
import { get, isEmpty, uniq } from "lodash";
import { useAppSelector } from "../../store/hooks/storeHook";
import { brazilCards } from "../../shared/enums/ConfigEnum";
import { CountriesEnum } from "../../shared/enums/CountriesEnum";

const BrazilDeferredInfo = () => {
  const brazilDeferredInfo = useAppSelector(
    (state) => state.brazilDeferredApp.merchantDeferred
  );

  let months: string[] = [];

  if (!isEmpty(brazilDeferredInfo)) {
    brazilDeferredInfo.map((merchantDeferred) => {
      months = months.concat(get(merchantDeferred, "merchantMonths"));
    });
    months = uniq(([] as string[]).concat.apply([], months)).sort(
      (firstItem: string, secondItem: string) => {
        const firstItemtoNumber = parseInt(firstItem);

        const secondItemtoNumber = parseInt(secondItem);

        return firstItemtoNumber - secondItemtoNumber;
      }
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} data-testid={"brazil-def-info"}>
          <Typography variant="body2" sx={brazilDeferredInfoStyles.subtitle}>
            {DEFERRED_LABELS.BRAZIL_INFO}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={brazilDeferredInfoStyles.boxDetails}>
          <Grid
            container
            spacing={2}
            direction={"row"}
            alignItems={"center"}
            aria-controls="panel2bh-content"
          >
            <Grid item xs={12}>
              <Typography
                variant="body2"
                sx={brazilDeferredInfoStyles.subtitle}
              >
                {DEFERRED_LABELS.BRAZIL_CARDS}
              </Typography>
            </Grid>
            {brazilCards.map((value, index: number) => (
              <Grid item key={index}>
                {validateIcon(value)}
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} sx={brazilDeferredInfoStyles.boxDetails}>
          <Typography variant="body2" sx={brazilDeferredInfoStyles.subtitle}>
            {DEFERRED_LABELS.BRAZIL_ACTIVE_MONTHS}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={brazilDeferredInfoStyles.boxDetails}>
          <Box sx={brazilDeferredInfoStyles.monthBox}>
            <MonthsList months={months} country={CountriesEnum.BRAZIL} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default BrazilDeferredInfo;
