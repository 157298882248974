import { Typography } from "@mui/material";
import { X } from "react-feather";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
} from "@mui/material";
import { refundModalStyles } from "../RefundModal/RefundModal.styles";
import React from "react";
import { IGenericModal } from "./GenericModal.interface";
import { GenericModalStyles } from "./GenericModal.styles";

export const GenericModal: React.FC<IGenericModal> = (
  props: React.PropsWithChildren<IGenericModal>
) => {
  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <Box sx={GenericModalStyles.BoxContent}>
        <Box sx={GenericModalStyles.textTitle}>{props.title}</Box>
        <Box sx={GenericModalStyles.CloseButton}>
          <IconButton onClick={props.handleClose} aria-label="close">
            <X size={28} />
          </IconButton>
        </Box>
      </Box>
      <DialogContent sx={GenericModalStyles.dialogPadding}>
        <Grid
          container
          direction="row"
          alignItems="center"
          sx={GenericModalStyles.paddingB3}
        >
          <Typography sx={GenericModalStyles.dialogMessage}>
            {props.message}
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions sx={GenericModalStyles.dialogPadding}>
        <Button
          id={"cancelBtn"}
          onClick={props.handleClose}
          sx={refundModalStyles.cancelBtn}
        >
          <Typography sx={refundModalStyles.buttonsText}>
            {"Cancelar"}
          </Typography>
        </Button>
        <Button
          id={"sendBtn"}
          autoFocus
          color="primary"
          variant="contained"
          onClick={props.handleAction}
          sx={refundModalStyles.sendBtn}
        >
          <Typography sx={refundModalStyles.buttonsText}>
            {"Confirmar"}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
