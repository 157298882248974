import React, { FC } from "react";
import { Card, CardContent, Chip, Grid, Typography } from "@mui/material";
import {
  IPaymentInfoProps,
  ProcessorTypeChipInfo,
} from "./PaymentProcessorComponent.interfaces";
import { paymentProcessorComponentStyles } from "./PaymentProcessorComponent.styles";
import { get } from "lodash";
import { MaskSkeleton } from "../MaskSkeleton/MaskSkeleton";
import RadioGroupComponent from "../RadioGroupComponent/RadioGroupComponent";
import { InputNameEnum } from "../../shared/constants/processorConstants";

const PaymentProcessorComponent: FC<IPaymentInfoProps> = (
  props: IPaymentInfoProps
) => {
  return (
    <React.Fragment>
      <Card>
        <CardContent sx={paymentProcessorComponentStyles.cardContent}>
          <Grid container>
            <Grid
              item
              sx={paymentProcessorComponentStyles.titleChip}
              key={`gridTitle_${Date.now().toString()}`}
            >
              <Typography sx={paymentProcessorComponentStyles.title}>
                {props.chipTitle}
              </Typography>
            </Grid>
            <Grid
              key={`gridChip_${Date.now().toString()}`}
              item
              sx={paymentProcessorComponentStyles.chipContainer}
            >
              {get(props, "processorsTypes", []).map(
                (type: ProcessorTypeChipInfo, index: number) => (
                  <>
                    <Chip
                      key={`chip_${index}`}
                      sx={paymentProcessorComponentStyles.chip}
                      label={type.name}
                    />
                  </>
                )
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MaskSkeleton
              sx={paymentProcessorComponentStyles.radioSkeleton}
              isLoading={props.isLoading}
            >
              <RadioGroupComponent
                updateList={props.processorsOptions.info.updateList}
                name={props.processorsOptions.info.name}
                items={props.processorsOptions.info.items}
                control={props.control}
                groupName={InputNameEnum.PROCESSOR_NAME}
              />
            </MaskSkeleton>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default PaymentProcessorComponent;
