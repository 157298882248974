import React, { PropsWithChildren } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { Check, Info, X } from "react-feather";
import { customChipStyles } from "./CustomChip.styles";
import { CustomChipProps } from "../../shared/infrastructure/interfaces/CustomChip.interface";
import { LabelsDispersion } from "../../shared/infrastructure/constants/RequestDispersionConstants";

export const CustomChip = (props: PropsWithChildren<CustomChipProps>) => {
  const classes = customChipStyles();

  return (
    <>
      <Box
        className={props.isSuccessful ? classes.successBox : classes.errorBox}
      >
        <Grid container direction={"row"}>
          <Grid item xs={6}>
            <Typography className={classes.fileText}>
              {props.fileName}
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.secondGrid}>
            {props.isSuccessful ? (
              <Check size={"22px"} className={classes.successIcon} />
            ) : (
              <X size={"22px"} className={classes.errorIcon} />
            )}
          </Grid>
        </Grid>
      </Box>
      {!props.isSuccessful && (
        <Grid
          container
          item
          direction={"row"}
          className={classes.thirdGrid}
          spacing={1}
        >
          <Grid item xs={"auto"}>
            <Info size={"20px"} className={classes.infoIcon} />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.errorFileSizeText}>
              {!props.isSuccessFileSize
                ? LabelsDispersion.ERROR_SIZE_FILE
                : !props.isSuccessFormatFile
                ? LabelsDispersion.ERROR_FORMAT_FILE
                : ""}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};
