export enum ErrorMessageEnum {
  MAX_CHARACTERS = "El máximo permitido de caracteres es ",
  CAMPO_REQUIRED = "El campo es requerido",
  ALFA_NUMERIC = "El campo solo acepta valores alfanuméricos",
  ABA_REQUIRED = "Debe ingresar el código ABA",
  ROUTING_NUMBER_REQUIRED = "Debe ingresar el número de enrutamiento",
  IBAN_REQUIRED = "Debe ingresar el código IBAN.",
  SWIFT_REQUIRED = "Debe ingresar el código SWIFT",
  FINANCIAL_INSTITUTION_ADDRESS_REQ = "Debe ingresar la dirección de la institución financiera.",
  BENEFICIARY_NAME_REQ = "Debe ingresar el nombre del beneficiario",
  FINANCIAL_INSTITUTION_ADDRESS_VALID = "Debe ingresar una dirección válida.",
  FINANCIAL_INSTITUTION_NAME_REQ = "Debe ingresar la insitución financiera.",
  FINANCIAL_INSTITUTION_NAME_VALID = "Debe ingresar un nombre válido.",
  BENEFICIARY_NAME_VALID = "Debe ingresar una nombre de beneficiario válido.",
  BANK_CITY_OF_ORIGIN_VALID = "Debe ingresar una ciudad válida",
  BANK_CITY_OF_ORIGIN_REQ = "Debe ingresar la ciudad de origen de la cuenta bancaria",
  BANK_POSTAL_CODE_ORIGIN_REQ = "Debe ingresar el código postal de origen de la cuenta bancaria",
  BANK_POSTAL_CODE_ORIGIN_VALID = "Debe ingresar un código postal válido",
}
