import React, { FC } from "react";
import { MenuItem, TextField } from "@mui/material";
import { ISelectItemCompleteProps } from "./SelectItemComplete.interfaces";
import { ICountry } from "../../shared/infrastructure/Countries";
import { ISelectNewItemList } from "../SelectNewItem/SelectNewItem.interfaces";
import { get, isEmpty } from "lodash";
import { FlagDefault } from "@kushki/connect-ui";

const SelectItemComplete: FC<ISelectItemCompleteProps> = ({
  items,
  label,
  handleOnChange,
  value,
  helperText,
  hasError,
  disable,
  isCountry,
  id,
  required,
}: ISelectItemCompleteProps) => {
  const renderFlag = (country: ICountry | ISelectNewItemList) =>
    isEmpty(get(country, "flag", "")) ? (
      <FlagDefault sx={{ marginRight: "5px" }} />
    ) : (
      <img src={country.flag} width="24px" height="24px" alt={country.flag} />
    );

  const renderName = (nameCountry: string, label: string): string => {
    let getValue: string = "";

    if (nameCountry.length > 0) getValue = nameCountry;
    if (label.length > 0) getValue = label;

    return getValue;
  };

  return (
    <TextField
      label={label}
      onChange={handleOnChange}
      select
      id={id}
      fullWidth
      value={value}
      required={required}
      helperText={helperText}
      error={hasError}
      disabled={disable}
    >
      {items.map((country: ICountry | ISelectNewItemList) => (
        <MenuItem key={country.value} value={country.value}>
          {isCountry && renderFlag(country)}
          {renderName(get(country, "name", ""), get(country, "label", ""))}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SelectItemComplete;
