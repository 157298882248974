export enum ProsaEnum {
  NO_PROMOTION = "00",
  NO_INTEREST = "03",
  WITH_INTEREST = "05",
  SKIP_PAYMENT = "07",
}

export enum ProsaDescriptionEnum {
  NO_PROMOTION = "Sin promoción",
  NO_INTEREST = "Sin intereses al tarjetahabiente",
  WITH_INTEREST = "Con Intereses para el tarjetahabiente",
  SKIP_PAYMENT = "Compre hoy y Pague después (Skip Payment Puro)",
}
