import React from "react";
import {
  FlagBrasil,
  FlagChile,
  FlagColombia,
  FlagCostaRica,
  FlagEcuador,
  FlagElSalvador,
  FlagGuatemala,
  FlagHonduras,
  FlagMexico,
  FlagNicaragua,
  FlagPanama,
  FlagPeru,
} from "@kushki/connect-ui";

export enum CountryIso3Enum {
  MEXICO = "MEX",
  ECUADOR = "EC",
  PERU = "PER",
  CHILE = "CHL",
  COLOMBIA = "COL",
  EL_SALVADOR = "SLV",
  NICARAGUA = "NIC",
  COSTA_RICA = "CRI",
  BRAZIL = "BRA",
  GUATEMALA = "GTM",
  HONDURAS = "HND",
  PANAMA = "PAN",
  USA = "USA",
}

export const getCountryFlag: Record<string, JSX.Element> = {
  [CountryIso3Enum.BRAZIL]: <FlagBrasil fontSize="inherit" />,
  [CountryIso3Enum.CHILE]: <FlagChile fontSize="inherit" />,
  [CountryIso3Enum.COLOMBIA]: <FlagColombia fontSize="inherit" />,
  [CountryIso3Enum.COSTA_RICA]: <FlagCostaRica fontSize="inherit" />,
  [CountryIso3Enum.ECUADOR]: <FlagEcuador fontSize="inherit" />,
  [CountryIso3Enum.EL_SALVADOR]: <FlagElSalvador fontSize="inherit" />,
  [CountryIso3Enum.GUATEMALA]: <FlagGuatemala fontSize="inherit" />,
  [CountryIso3Enum.HONDURAS]: <FlagHonduras fontSize="inherit" />,
  [CountryIso3Enum.MEXICO]: <FlagMexico fontSize="inherit" />,
  [CountryIso3Enum.NICARAGUA]: <FlagNicaragua fontSize="inherit" />,
  [CountryIso3Enum.PANAMA]: <FlagPanama fontSize="inherit" />,
  [CountryIso3Enum.PERU]: <FlagPeru fontSize="inherit" />,
};
