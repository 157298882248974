import { FieldError, useFormContext } from "react-hook-form";
import { IForm, TErrorMessage } from "../../shared/interfaces/IForm";

const ConnectForm = <T extends {}>({
  children,
}: {
  children: (form: IForm<T>) => JSX.Element;
}) => {
  const formMethods = useFormContext<T>();

  const getErrorMessage = (
    fieldError: FieldError,
    typeErrorMessage?: TErrorMessage
  ): string | undefined => {
    if (fieldError.message) return fieldError.message;

    switch (fieldError.type) {
      case "required":
        return "Campo requerido";
      case "pattern":
        switch (typeErrorMessage) {
          case "email":
            return "E-mail inválido";
        }
    }

    return undefined;
  };

  return children({
    ...formMethods,
    getErrorMessage,
  });
};

export default ConnectForm;
