import { IStyles } from "../../shared/utils";

export const footerStyles: IStyles = {
  footer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "30px 64px 30px 64px",
    backgroundColor: "#FAFCFD",
  },
  footerContainer: {
    position: "absolute",
    bottom: "0",
  },
  labelBox: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    justifyContent: "flex-end",
    alignItems: "center",
    height: 1,
  },
  label: {
    color: "#64798D",
    fontWeight: 400,
    fontSize: { xs: "13px", md: "13px" },
    fontFamily: "IBM Plex Sans",
    paddingLeft: "24px",
  },
  saveButton: { marginRight: "50px" },
  continueButton: { variant: "contained" },
};
