import { makeStyles } from "@material-ui/core/styles";

export const dispersionsTableStyle = makeStyles({
  title: {
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: "13px",
    color: "#46525C",
  },
  date: {
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "12px",
    color: "#46525C",
    lineHeight: "136%",
  },
  textItem: {
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "12px",
    color: "#293036",
    lineHeight: "136%",
  },
  downloadButton: {
    width: "140px",
    height: "38px",
    backgroundColor: "white",
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: "14px",
    color: "#293036",
    lineHeight: "140%",
  },
  greyChip: {
    width: "83px",
    height: "25px",
    backgroundColor: "#F0F4F9",
    borderRadius: "4px",
  },
  greyChipText: {
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: "9px",
    textAlign: "center",
    paddingTop: "5px",
    color: "#6D7781",
  },
  blueChip: {
    width: "83px",
    height: "25px",
    backgroundColor: "#EEF6FF",
    borderRadius: "4px",
  },
  blueChipText: {
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: "9px",
    textAlign: "center",
    paddingTop: "5px",
    color: "#023365",
  },
  redChipInvalid: {
    width: "83px",
    height: "25px",
    backgroundColor: "#FFEAEE",
    borderRadius: "4px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  redChipInvalidText: {
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: "9px",
    textAlign: "center",
    paddingTop: "5px",
    color: "#AD0C2A",
  },
  yellowChipPartial: {
    background: "#FFF1D0",
    borderRadius: 4,
    padding: "3px 11px",
    width: 92,
    height: 36,
    "&:hover": {
      cursor: "pointer",
    },
  },
  yellowChipInvalidText: {
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: "9px",
    textAlign: "center",
    paddingTop: "5px",
    color: "#CF711A",
  },
});
