import React from "react";
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Webhook, WebhookResponse } from "../../../types/webhook_response";
import { Edit, Trash2 } from "react-feather";
import { ChipLabel } from "../ChipLabel/ChipLabel";
import {
  PaginationTable,
  PaginationTableProps,
} from "../PaginationTable/PaginationTable";
import { format, isValid } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import {
  dataTableHeader,
  LabelConstantsEnum,
  LabelWebhookStatusEnum,
  WebhookStatusEnum,
} from "../../shared/infrastructure/constants/WebhookListConstants";
import SimpleChip from "../SimpleChip/SimpleChip";
import { get } from "lodash";
import { useWebhookTable } from "./state/useWebhookTable";
import { SecurityWrapperEnum } from "../../shared/infrastructure/constants/SecurityWrapperEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

const useStyles = makeStyles(() => ({
  dataTableHeader: {
    fontWeight: 550,
    fontStyle: "normal",
    fontSize: "14px",
    color: "#6D7781",
  },
}));

export interface WebhookTableProps {
  webhookList: WebhookResponse;
  handleOnDelete: (webhook: Webhook) => void;
  handleOpenTestConnection: (webhook: Webhook) => void;
  pagination: PaginationTableProps;
  handleEdit: (webhookSelected: Webhook) => void;
  isAdmin: boolean;
  handleOpenDetail: (webhookSelected: Webhook) => void;
  enableEdit: boolean;
}

export const WebhookTable: React.FC<WebhookTableProps> = (
  props: WebhookTableProps
) => {
  const style = useStyles();
  const { tableCellPercentage } = useWebhookTable();

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table aria-label="simple-table">
          <TableHead style={{ background: "#F7FAFC" }}>
            <TableRow>
              {dataTableHeader.map((data, index) => (
                <TableCell
                  key={`${index}-${data.label}`}
                  className={style.dataTableHeader}
                  align={index === 3 ? "center" : "left"}
                  style={{
                    width: tableCellPercentage(index),
                  }}
                >
                  {data.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.webhookList.items.map((webhook: Webhook, index: number) => (
              <TableRow
                key={`row_${index}`}
                selected={false}
                hover={true}
                onClick={() => props.handleOpenDetail(webhook)}
              >
                <TableCell key={`created_${index}`} style={{ width: "10%" }}>
                  {isValid(new Date(webhook.created))
                    ? format(new Date(webhook.created), "dd-MM-yyyy")
                    : ""}
                </TableCell>
                <TableCell key={`alias_${index}`} style={{ width: "18%" }}>
                  {webhook.alias}
                </TableCell>
                <TableCell key={`product_${index}`} style={{ width: "35%" }}>
                  <ChipLabel events={webhook.events} />
                </TableCell>
                <TableCell
                  key={`status_${index}`}
                  style={{ width: "15%" }}
                  align={"center"}
                >
                  {get(webhook, "status", [{ url: "", status: "" }]).filter(
                    (data) => data.status === WebhookStatusEnum.FAILED
                  ).length > 0 ? (
                    <SimpleChip
                      key={`${index}-${webhook.status}-failed`}
                      type={"error"}
                      label={LabelWebhookStatusEnum.FAILED}
                      justifyContent={"center"}
                    />
                  ) : (
                    <SimpleChip
                      key={`${index}-${webhook.status}-success`}
                      type={"success"}
                      label={LabelWebhookStatusEnum.SUCCESS}
                      justifyContent={"center"}
                    />
                  )}
                </TableCell>
                <TableCell
                  key={`delete_${index}`}
                  align={"right"}
                  style={{ width: "30%" }}
                >
                  {" "}
                  <ComponentSecurityWrapper
                    componentId={SecurityWrapperEnum.TEST_CONNECTION}
                  >
                    <Button
                      variant={"outlined"}
                      size={"small"}
                      color="default"
                      onClick={(e) => {
                        props.handleOpenTestConnection(webhook);
                        e.stopPropagation();
                      }}
                    >
                      {props.isAdmin
                        ? LabelConstantsEnum.TEST_CONNECTION
                        : LabelConstantsEnum.SIMULATE_CONNECTION}
                    </Button>
                  </ComponentSecurityWrapper>
                  {props.enableEdit && (
                    <IconButton
                      onClick={(e) => {
                        props.handleEdit(webhook);
                        e.stopPropagation();
                      }}
                    >
                      <Edit size={16} />
                    </IconButton>
                  )}
                  {props.isAdmin && props.enableEdit && (
                    <IconButton
                      onClick={(e) => {
                        props.handleOnDelete(webhook);
                        e.stopPropagation();
                      }}
                    >
                      <Trash2 size={16} />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <PaginationTable {...props.pagination} />
      </TableContainer>
    </React.Fragment>
  );
};
