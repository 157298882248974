import React, { FC } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { Control, FieldErrors, FieldValues } from "react-hook-form";
import TextInput from "../InputForms/TextInput/TextInput";
import { LegalRepresentativesFormComponents } from "./LegalRepresentativesForm.interfaces";
import { legalRepresentativesFormStyles } from "./LegalRepresentativesForm.styles";
import { IFormValues } from "../../containers/ContainerIndex/state/useContainerIndexState.interfaces";
import { useLegalRepresentativesFormState } from "./state/useLegalRepresentativesFormState";
import OnlyLettersInput from "../InputForms/OnlyLettersInput/OnlyLettersInput";
import EmailInput from "../InputForms/EmailInput/EmailInput";
import SelectItemsInput from "../InputForms/SelectItemsInput/SelectItemsInput";
import OnlyNumbersInput from "../InputForms/OnlyNumbersInput/OnlyNumbersInput";
import SelectAutocompleteItemsInput from "../InputForms/SelectAutocompleteItemsInput/SelectAutocompleteItemsInput";
import DateInput from "../InputForms/DateInput/DateInput";
import QuestionInput from "../InputForms/QuestionInput/QuestionInput";
import {
  LEGAL_REPRESENTATIVE_KEY,
  LegalRepresentativesComponentType,
  TITLE_ENUM,
} from "../../shared/constants/LegalRepresentativesConstants";
import {
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form/dist/types/form";

export interface ILegalRepresentativesFormProps {
  form: Partial<IFormValues>;
}

const checkTextError = (
  errors: any,
  component: LegalRepresentativesFormComponents,
  getValues: UseFormGetValues<FieldValues> | undefined,
  index: number
) => {
  return component.checkError
    ? component.checkError(
        errors,
        component.name,
        index,
        component.onDisable ? component.onDisable(getValues, index) : false
      )
    : errors;
};

const getFormComponentes = (
  control: Control<FieldValues, Object>,
  errors: FieldErrors,
  index: number,
  component: LegalRepresentativesFormComponents,
  setValue: UseFormSetValue<FieldValues>,
  getValues: UseFormGetValues<FieldValues> | undefined
) => {
  const name = `${LEGAL_REPRESENTATIVE_KEY}.${index}.${component.name}`;
  const controlName = `${LEGAL_REPRESENTATIVE_KEY}[${index}]${component.name}`;
  const key = `${component.name}${index}`;

  switch (component.type) {
    case LegalRepresentativesComponentType.ONLY_LETTERS:
      return (
        <Grid item xs={component.xs} key={key}>
          <OnlyLettersInput
            control={control}
            errors={errors}
            isRequired={component.isRequired}
            name={name}
            controlName={controlName}
            label={component.label}
          />
        </Grid>
      );
    case LegalRepresentativesComponentType.TEXT:
      return (
        <Grid item xs={component.xs} key={key}>
          <TextInput
            control={control}
            errors={checkTextError(errors, component, getValues, index)}
            isRequired={component.isRequired}
            name={name}
            controlName={controlName}
            defaultValue={component.defaultValue}
            label={component.label}
            disabled={
              component.onDisable
                ? component.onDisable(getValues, index)
                : undefined
            }
            onValidate={
              component.onValidate
                ? component.onValidate(getValues, index)
                : undefined
            }
          />
        </Grid>
      );
    case LegalRepresentativesComponentType.ONLY_NUMBERS:
      return (
        <Grid item xs={component.xs} key={key}>
          <OnlyNumbersInput
            control={control}
            errors={errors}
            isRequired={component.isRequired}
            name={name}
            controlName={controlName}
            label={component.label}
          />
        </Grid>
      );
    case LegalRepresentativesComponentType.SELECT:
      return (
        <Grid item xs={component.xs} key={key}>
          <SelectItemsInput
            name={name}
            controlName={controlName}
            isRequired={component.isRequired}
            control={control}
            errors={errors}
            label={component.label}
            items={component.items!}
            onChange={() => {
              component.onChange && component.onChange(setValue, index);
            }}
          />
        </Grid>
      );
    case LegalRepresentativesComponentType.EMAIL:
      return (
        <Grid item xs={component.xs} key={key}>
          <EmailInput
            control={control}
            errors={errors}
            isRequired={component.isRequired}
            name={name}
            controlName={controlName}
            label={component.label}
          />
        </Grid>
      );
    case LegalRepresentativesComponentType.AUTOCOMPLETE:
      return (
        <Grid item xs={component.xs} key={key}>
          <SelectAutocompleteItemsInput
            control={control}
            errors={errors}
            isRequired={component.isRequired}
            name={name}
            controlName={controlName}
            label={component.label}
            items={component.items!}
          />
        </Grid>
      );
    case LegalRepresentativesComponentType.QUESTION:
      return (
        <Grid item xs={component.xs} key={key}>
          <QuestionInput
            control={control}
            errors={errors}
            name={name}
            label={component.label}
          />
        </Grid>
      );
    case LegalRepresentativesComponentType.DATE:
      return (
        <Grid item xs={component.xs} key={key}>
          <DateInput
            control={control}
            errors={errors}
            name={name}
            controlName={controlName}
            label={component.label}
            isRequired={component.isRequired}
          />
        </Grid>
      );
    case LegalRepresentativesComponentType.BLANK:
      return <Grid item xs={component.xs} key={key} />;
    default:
      return null;
  }
};

const LegalRepresentativesForm: FC<ILegalRepresentativesFormProps> = ({
  form: { control, errors, setValue, getValues },
}: ILegalRepresentativesFormProps) => {
  const { fields, formConfig, handleChangeCheckboxForm } =
    useLegalRepresentativesFormState({
      form: {
        control,
        errors,
      },
    });

  return (
    <Box sx={legalRepresentativesFormStyles.boxContainer}>
      {fields.map((field, i) => (
        <Box sx={legalRepresentativesFormStyles.boxForm} key={field.id}>
          <Grid container columnSpacing={2} rowSpacing={3}>
            <Grid item xs={12}>
              <Typography sx={legalRepresentativesFormStyles.title}>
                {i === 0 ? TITLE_ENUM.PRINCIPAL : TITLE_ENUM.ALTERNATIVE}
              </Typography>
              <Typography sx={legalRepresentativesFormStyles.subtitle}>
                * Estos datos son obligatorios
              </Typography>
            </Grid>
            {formConfig.map((formField) =>
              getFormComponentes(
                control!,
                errors!,
                i,
                formField,
                setValue!,
                getValues
              )
            )}
            <Grid item xs={12}>
              {i === 0 && (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fields.length > 1}
                        onChange={(event) => handleChangeCheckboxForm(event, i)}
                      />
                    }
                    label="¿Deseas agregar un representante legal alterno?"
                  />
                </FormGroup>
              )}
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default LegalRepresentativesForm;
