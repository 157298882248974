import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { getMerchantPreferences } from "../../../store/thunks/app/app.thunks";
import { ChangeEvent, useEffect, useState } from "react";
import {
  ILastModify,
  IUseAccountPreferences,
} from "./useAccountPreferences.interfaces";
import { ItemEmail } from "@kushki/connect-ui/dist/Components/Molecules/Form/TextFieldEmail/TextFieldEmail.interfaces";
import { ISelectedImage } from "@kushki/connect-ui/dist/Components/Molecules/Form/UploadImage/UploadImage.interfaces";
import { defaultTo, get, isEmpty } from "lodash";
import { GetSemaphoreResponse } from "../../../../types/get_semaphore_response";
import { buildLastModifyProps } from "../../../shared/constants/main_container_constants";
import {
  patchMerchantPreferences,
  patchMerchantSemaphore,
  putMerchantHierarchyConfig,
} from "../../../store/thunks/merchant-node/merchant-node.thunks";
import { EntityName } from "../../../shared/enums/entityName";
import { ConfigIdEnum } from "../../../shared/constants/enums/ConfigIdEnum";
import { GetMerchantPreferencesResponse } from "../../../../types/get_merchant_preferences_response";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FetchStateEnum } from "../../../shared/constants/enums/fetchStateEnum";
import { SHOW_SNACKBAR } from "../../../store/reducers/merchantNode/merchantNode.slice";
import { RoutePathsEnum } from "../../../shared/constants/enums/RoutePathsEnum";
import { decode } from "url-safe-base64";

export interface IColorValidation {
  primary: boolean;
  secondary: boolean;
}

export const useAccountPreferences = (): IUseAccountPreferences => {
  const {
    merchantPreferences,
    nodeInfo,
    semaphoreData,
    error,
    patchMerchantPreferencesState,
    putMerchantHierarchyConfigState,
  } = useAppSelector((state) => ({
    ...state.accountPreference,
    ...state.app,
    ...state.merchantNode,
  }));

  interface IImageDefault {
    fileName: string;
    url: string;
  }
  const emptyNewImage: ISelectedImage = {
    file64: "",
    name: "",
    type: "",
  };
  const emptyImage: IImageDefault = {
    fileName: "",
    url: "",
  };

  const [image, setImage] = useState<IImageDefault>(emptyImage);
  const [newImage, setNewImage] = useState<ISelectedImage>(emptyNewImage);
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<ItemEmail[]>([]);
  const [terms, setTerms] = useState<string>("");
  const [colorPrimary, setColorPrimary] = useState<string>("");
  const [colorSecondary, setColorSecondary] = useState<string>("");
  const [isValid, setValid] = useState<boolean>(false);

  const [phoneError, setPhoneError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [imageError, setImageError] = useState<boolean>(false);
  const [termsError, setTermsError] = useState<boolean>(false);
  const [colorErrors, setColorErrors] = useState<IColorValidation>({
    primary: false,
    secondary: false,
  });
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [footerLabels, setFooterLabels] = useState<ILastModify | null>(null);
  const [disabled, setDisabled] = useState<boolean>(false);
  const isBranch: boolean = get(nodeInfo, "entityName") === EntityName.BRANCH;
  const isCustomer: boolean =
    get(nodeInfo, "entityName") === EntityName.CUSTOMER;
  const publicMerchantId = searchParams.get("publicMerchantId");
  const userName = localStorage.getItem("username");
  const [isCentralized, setIsCentralized] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const requestMerchantPreferences = () => {
    if (nodeInfo) {
      const configId = nodeInfo.configs.find(
        (config) => config.configuration === ConfigIdEnum.PREFERENCES
      );

      if (configId && !isEmpty(configId.value))
        dispatch(getMerchantPreferences({ publicMerchantId: configId.value }));

      if (configId && !isEmpty(configId.centralizedNodesId))
        setIsCentralized(true);

      if (isBranch && configId && !isEmpty(configId.centralizedNodesId))
        setDisabled(true);
    }
  };

  const setMerchantData = (
    merchantPreference: GetMerchantPreferencesResponse
  ) => {
    setPhone(
      `${defaultTo(merchantPreference.contact.areaCode, "")} ${defaultTo(
        merchantPreference.contact.phoneNumber,
        ""
      )}`
    );
    setColorPrimary(merchantPreference.colors.primary);
    setColorSecondary(merchantPreference.colors.secondary);
    setTerms(merchantPreferences.termsAndConditions);
    setEmail(
      !isEmpty(merchantPreferences.contact.email)
        ? [{ mail: merchantPreferences.contact.email }]
        : []
    );
  };

  const updateLastModify = (semaphoreResponse?: GetSemaphoreResponse) => {
    const data: { userName: string; updatedAt: number } = get(
      semaphoreResponse,
      "infoAccountPreference"
    );

    if (data)
      setFooterLabels(
        buildLastModifyProps(
          get(data, "updatedAt", 0),
          get(data, "userName", "")
        )
      );
  };

  const setEmailChange = (values: ItemEmail[]) => {
    if (values.length === 0) {
      setEmailError(true);
    } else {
      setEmailError(false);
      setEmail(values);
    }
  };

  const setImageChange = (selectedImage: ISelectedImage) => {
    setNewImage(selectedImage);
    setImageError(false);
  };

  const onDeleteImage = () => {
    setNewImage(emptyNewImage);
    setImage(emptyImage);
    setImageError(true);
  };

  const setPhoneChange = (value: string) => {
    value.trim().length > 0 && value.trim().length < 5
      ? setPhoneError(true)
      : setPhoneError(false);
    setPhone(value.trim());
  };
  const setTermsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    setTermsError(isEmpty(newValue));
    setTerms(newValue);
  };

  const setColorPrimaryChange = (selected: string) => {
    if (selected === "") {
      setColorErrors({
        primary: true,
        secondary: false,
      });
      setColorPrimary(selected);
    } else {
      setColorPrimary(selected);
      setColorErrors({
        primary: false,
        secondary: false,
      });
    }
  };

  const setColorSecondaryChange = (selected: string) => {
    if (selected === "") {
      setColorErrors({
        primary: false,
        secondary: true,
      });
      setColorSecondary(selected);
    } else {
      setColorSecondary(selected);
      setColorErrors({
        primary: false,
        secondary: false,
      });
    }
  };

  const saveMerchantPreference = async () => {
    const phoneNumber = phone.split(" ");

    if (!isCentralized) {
      isEmpty(newImage.name) && setImageError(true);
      if (
        nodeInfo &&
        !phoneError &&
        !emailError &&
        !termsError &&
        !imageError &&
        (isCustomer || isBranch)
      ) {
        dispatch(
          patchMerchantPreferences({
            colors: {
              primary: colorPrimary,
              secondary: colorSecondary,
            },
            contact: {
              areaCode: defaultTo(phoneNumber[0], phone),
              email: email[0].mail,
              phoneNumber: defaultTo(phoneNumber[1], phone),
            },
            logo: {
              ext: newImage.type,
              height: "120",
              image: newImage.file64,
              name: newImage.name,
              width: "120",
            },
            merchantId: defaultTo(publicMerchantId, ""),
            termsAndConditions: terms,
          })
        );
      }
    }
  };

  const onCloseSnackBar = () => {
    dispatch(SHOW_SNACKBAR(false));
  };

  useEffect(() => {
    if (patchMerchantPreferencesState === FetchStateEnum.SUCCESS) {
      dispatch(
        putMerchantHierarchyConfig({
          configs: [{ configuration: "cn012", value: publicMerchantId! }],
          nodeId: get(nodeInfo, "nodeId"),
        })
      );
    }
  }, [patchMerchantPreferencesState]);

  useEffect(() => {
    if (putMerchantHierarchyConfigState === FetchStateEnum.SUCCESS) {
      dispatch(
        patchMerchantSemaphore({
          infoAccountPreference: {
            updatedAt: Date.now(),
            userName: userName!,
          },
          publicMerchantId: publicMerchantId!,
          stepAccountPreference: {
            status: "complete",
          },
        })
      );
      navigate(
        `/..${RoutePathsEnum.MERCHANT_RESUME.replace(
          "{merchantId}",
          publicMerchantId as string
        )}`
      );
    }
  }, [putMerchantHierarchyConfigState]);

  useEffect(() => {
    requestMerchantPreferences();
  }, [nodeInfo]);

  useEffect(() => {
    updateLastModify(semaphoreData);
  }, [semaphoreData]);

  useEffect(() => {
    if (merchantPreferences) {
      if (merchantPreferences.logos.length > 0) {
        setNewImage({
          ...newImage,
          file64: decode(merchantPreferences.logos[0].url!),
          name: merchantPreferences.logos[0].fileName!,
          type: merchantPreferences.logos[0].fileName!.split(".").pop()!,
        });
        setImage({
          ...image,
          fileName: merchantPreferences.logos[0].fileName!,
          url: merchantPreferences.logos[0].url!,
        });
      }
      setMerchantData(merchantPreferences);
    }
  }, [merchantPreferences]);

  useEffect(() => {
    if (
      nodeInfo &&
      !phoneError &&
      !emailError &&
      !termsError &&
      !imageError &&
      (isCustomer || isBranch) &&
      colorPrimary !== "" &&
      colorSecondary !== "" &&
      terms !== ""
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [
    nodeInfo,
    phoneError,
    emailError,
    termsError,
    imageError,
    colorPrimary,
    colorSecondary,
    terms,
  ]);

  return {
    disabled,
    error,
    footerLabels,
    isValid,
    merchantData: {
      colorPrimary,
      colorSecondary,
      colorValidation: colorErrors,
      email,
      emailError,
      image: image.url,
      imageError,
      phone,
      phoneError,
      terms,
      termsError,
    },
    nodeInfo,
    onCloseSnackBar,
    onDeleteImage,
    patchMerchantPreferencesState,
    saveMerchantPreference,
    setColorPrimaryChange,
    setColorSecondaryChange,
    setEmailChange,
    setImageChange,
    setPhoneChange,
    setTermsChange,
  };
};
