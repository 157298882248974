/* istanbul ignore file */

import { AuthRuleFormFields } from "./AuthRuleFormFields";

export enum GeneralActionsEnum {
  OTP = "otp",
  THREE_DS = "3dsecure",
}

export const GENERAL_ACTIONS = new Map<string, string>([
  [GeneralActionsEnum.OTP, AuthRuleFormFields.OTP],
  [GeneralActionsEnum.THREE_DS, AuthRuleFormFields.THREE_DS],
]);
