import { FC } from "react";
import * as React from "react";
import { IClientsHeaderWrapper } from "./ClientsHeaderWrapper.interfaces";
import { Box, TextField } from "@mui/material";
import {
  ModalContent,
  ModalContentButtonTypeEnum,
  ModalContentTypeEnum,
  ModalLoader,
} from "@kushki/connect-ui";
import { Controller } from "react-hook-form";
import {
  ErrorMessageEnum,
  ownerFormName,
  RegularExp,
} from "../../shared/enums/FormEnum";
import { mainContainerStyles as styles } from "../../containers/MainContainer/MainContainer.styles";
import { OwnerEnum } from "../../shared/enums/OwnerEnum";
import { useClientsHeaderWrapper } from "./state/useClientsHeaderWrapper";

export const ClientsHeaderWrapper: FC<IClientsHeaderWrapper> = ({
  children,
  isLoading,
  isOpenModal,
  onCloseModal,
  handleCreateOwner,
}: IClientsHeaderWrapper) => {
  const { control, isDisabled, handleModalAction } = useClientsHeaderWrapper({
    handleCreateOwner,
  });

  return (
    <Box>
      {children}
      <ModalContent
        buttonAction={handleModalAction}
        buttonText={OwnerEnum.MODAL_BTN_PRIMARY}
        buttonType={ModalContentButtonTypeEnum.PRIMARY}
        descriptionText={OwnerEnum.MODAL_DESCRIPTION}
        isOpen={isOpenModal}
        onClose={onCloseModal}
        titleText={OwnerEnum.MODAL_TITLE}
        type={ModalContentTypeEnum.DEFAULT}
        buttonSecondaryText={OwnerEnum.MODAL_BTN_SECONDARY}
        buttonPrimaryDisabled={isDisabled}
      >
        <Controller
          name={ownerFormName}
          control={control}
          rules={{
            maxLength: {
              message: `${ErrorMessageEnum.MAX_CHARACTERS} 50`,
              value: 50,
            },
            pattern: {
              message: ErrorMessageEnum.CHARACTERS,
              value: new RegExp(RegularExp.DEFAULT),
            },
            required: {
              message: ErrorMessageEnum.OWNER_NAME,
              value: true,
            },
          }}
          defaultValue=""
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              name={ownerFormName}
              sx={styles.error1}
              value={field.value}
              label={"Nombre del owner"}
              variant="outlined"
              disabled={false}
              fullWidth
              helperText={error && error.message}
              error={!!error}
              onChange={(e) => field.onChange(e.target.value)}
            />
          )}
        />
      </ModalContent>
      <ModalLoader
        descriptionText={OwnerEnum.LOADING_DESCRIPTION}
        isOpen={isLoading}
        titleText={OwnerEnum.LOADING_TITLE}
      />
    </Box>
  );
};

export default ClientsHeaderWrapper;
