import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const contactDataCardStyles = createNamedStyles({
  boxContent2: {
    minHeight: "600px",
    bgcolor: "#FFFFFF",
    width: "100%",
    padding: "48px 64px 48px 64px",
  },
  cardContent: {
    borderRadius: 6,
    boxShadow: "0px 0px 10px 5px #6aa7c529",
  },
  labelTitle: {
    color: "#023365",
    fontSize: "26px",
    fontWeight: 500,
    paddingBottom: 3,
    fontStyle: "normal",
  },
  subTitleText: {
    fontSize: "18px",
    color: "#6D7781",
    lineHeight: "150%",
  },
  subTitleText2: {
    fontSize: "15px",
    color: "#6D7781",
    lineHeight: "144%",
  },
  subTitleText3: {
    fontSize: "16px",
    color: "#6D7781",
    lineHeight: "24px",
    paddingBottom: 4,
  },
  addContact: {
    paddingBottom: 2,
    width: "100%",
  },
  addContactSubtitle: {
    paddingBottom: 2,
    width: "100%",
  },
  addContactSave: {
    float: "right",
  },
  divider: {
    border: "1px solid #E2E8F0",
  },
  buttonStyle: {
    border: "1px solid #4498EE",
    color: "#4498EE",
    "&:hover": {
      color: "#023365",
    },
  },
});
