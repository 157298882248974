import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

export const modelStartChargebackSkeletonStyles = createNamedStyles({
  boxSubHeader: {
    width: "100%",
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "20px",
    marginBottom: "20px",
  },
  text: {
    width: "100%",
    display: "flex",
    flexWrap: "nowrap",
  },
  checkBoxTitle: {
    width: "5%",
    display: "flex",
    flexWrap: "nowrap",
    marginRight: "5%",
  },
  checkBoxEmail: {
    width: "5%",
    display: "flex",
    flexWrap: "nowrap",
    marginRight: "5%",
    marginLeft: "10%",
  },
  skeleton: {
    width: 300,
    height: 25,
  },
  skeletonBar: {
    width: "100%",
    height: 2,
  },
});
