import { makeStyles, Theme } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/core";
import { themeOptions } from "../../themeui";

export const useCustomStepIconStyles = makeStyles({
  root: {
    zIndex: 1,
    color: "#fff",
    width: 32,
    height: 32,
    display: "flex",
    borderRadius: "4px",
    justifyContent: "center",
    alignItems: "center",
  },
});

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      "&..MuiContainer-root": {
        padding: "0 !important",
      },
      display: "block",
      position: "relative",
      backgroundColor: "#F7FAFC !important",
    },
    title: {
      color: themeOptions.palette.text.dark,
      lineHeight: "170%",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
    },
    subtitle: {
      color: themeOptions.palette.text.secondary,
      lineHeight: "136%",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
    },
    description: {
      color: "#023365",
      lineHeight: "136%",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "15px",
    },
    link: {
      color: themeOptions.palette.text.primary,
      lineHeight: "170%",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      cursor: "pointer",
    },
    linearProgress: {
      marginTop: 3,
    },
    paper: {
      padding: theme.spacing(2),
      boxShadow: "none !important",
      borderRadius: "0px !important",
      marginBottom: "20px",
    },
    backgroundIcon: {
      color: "#023365",
    },
    container: {
      paddingTop: 20,
      paddingBottom: 15,
      marginLeft: "-50px",
    },
    grid: {
      margin: 0,
      marginLeft: "30px",
    },
    stepper: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: "40px",
      marginLeft: "30px",
    },
    gridPadding: {
      paddingTop: 30,
    },
    gridHeight: {
      minHeight: 75,
    },
    icon: {
      width: "23px",
      height: "23px",
    },
    gridIcon: {
      marginTop: "-20px",
      marginLeft: "20px",
    },
  })
);
