import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { IProcessorFetch } from "../../../../types/processor_fetch_scheme";
import axiosUtil from "../../../shared/axios-util";

export const getServices = createAsyncThunk<IProcessorFetch[], string>(
  "processors/getServices",
  async (id: string) => {
    const response = await axiosUtil.get<IProcessorFetch[]>(
      API_ROUTES.GET_PROCESSORS(id)
    );

    return response.data;
  }
);
