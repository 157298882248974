import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { MerchantNodeData } from "../../../../types/search_merchant_response";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import {
  IUserData,
  IUseTransactionList,
} from "./IUseTransactionList.interfaces";
import {
  selectApp,
  selectChargeback,
  selectMerchantNode,
  selectRetrieveChild,
} from "../../../store/selectors/selectors";
import {
  searchChargeback,
  searchRetrieveChild,
  updateChargebackStatus,
} from "../../../store/thunks/chargeback/chargeback.thunks";
import { getFirebaseID } from "../../../store/thunks/app/app.thunks";
import {
  resetChargebackRequest,
  setChargebackTransaction,
  setSearchChargebackRequest,
} from "../../../store/actions/chargeback.actions";
import {
  cloneDeep,
  concat,
  defaultTo,
  get,
  has,
  includes,
  isEmpty,
  isNil,
  isUndefined,
  set,
  toString,
} from "lodash";
import {
  column_selected,
  column_selected_hierarchy,
  column_static_end,
  column_static_start,
  column_static_user_start,
} from "../../../shared/utils/column_selected";
import { ColumnsItem, IconCopy } from "@kushki/connect-ui";
import { CellHeaderProps } from "@kushki/connect-ui/dist/Components/Atoms/Table/CellHeader/interfaces";
import {
  ITableCellProps,
  ITableRowProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { HeaderColumnIdEnum } from "../../../shared/enums/HeaderColumnIdEnum";
import { Chargeback } from "../../../../types/chargeback";
import { copyToClipboard } from "../../../shared/utils/misc_utils";
import {
  convertDateCorrectFormat,
  getDateWithCustomFormat,
} from "../../../shared/utils/date_utils";
import { DateformatEnum } from "../../../shared/enums/DateformatEnum";
import { ChargebackStatusEnum } from "../../../shared/enums/ChargebackStatusEnum";
import { RangeAmount } from "../../AmountFilter/state/useAmountFilter.interfaces";
import { FILTER_GROUPS } from "../../../shared/constants/labels/header_filters_labels";
import { TFilterDetailBar } from "@kushki/connect-ui/dist/Components/Molecules/Filters/FilterDetailBar/FilterDetailBar.interfaces";
import {
  buildStringFilter,
  getColumnsUser,
} from "../../../shared/utils/lists_utils";
import { downloadTransactions } from "../../../shared/utils/chargeback_transaction_utils";
import { REDIRECT_CHARGEBACK_STATUS } from "../../../shared/constants/ChargebackConstants";
import UpdateStatusButton from "../../UpdateStatusButton/UpdateStatusButton";
import {
  filterPublicMerchantId,
  getCurrency,
} from "../../../shared/utils/transaction_list_utils";
import { OcbEnum } from "../../../shared/enums/ColumnSelectEnum";
import { auth } from "../../../shared/auth";
import { useNavigate } from "react-router-dom";
import { isCustomerRol } from "../../../shared/utils/roles";
import { resetFirebaseId } from "../../../store/actions/app.actions";
import { resetRetrieveChild } from "../../../store/actions/retrieveChild.actions";
import { FileFormatIDsEnum } from "../../../shared/enums/FileFormatEnum";
import { setIsOpenMassiveStatusModal } from "../../../store/actions/app.actions";
import {
  CATALOG_PAYMENT_SUB_METHOD_TYPE,
  ChargebackPaymentMethodLabelEnum,
  PaymentSubMethodTypeEnum,
} from "../../../shared/enums/PaymentMethodEnum";
import { SearchChargebackRequest } from "../../../../types/search_chargeback_request";

enum TableBodyCellEnum {
  TEXT = "TEXT",
  TAG = "TAG",
  TEXT_ACTION = "TEXT_ACTION",
  TITLE = "TITLE",
}

export const useTransactionList = (): IUseTransactionList => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user_profile: IUserData = getColumnsUser();
  const { selectItems, customerList, searchingMerchants } =
    useAppSelector(selectMerchantNode);
  const { firebaseId, isLoadingTransaction } = useAppSelector((state) => ({
    ...state.app,
  }));
  const {
    searchChargebackRequest,
    searchChargebackResponse,
    searchingChargeback,
    isLoadingStatus,
    isMongo,
  } = useAppSelector(selectChargeback);

  const { retrieveChildResponse, retrieveChildRequest } =
    useAppSelector(selectRetrieveChild);
  const {
    nodeInfo,
    isLoadingNodeInfo,
    isLoadingMerchantInfo,
    loadingDownload,
  } = useAppSelector(selectApp);
  const isRol: boolean = useMemo(() => isCustomerRol(), []);

  const [selectedColumns, setSelectedColumns] = useState<ColumnsItem[]>(
    auth.isAdmin() || auth.isMasterCustomer()
      ? cloneDeep(concat(column_selected_hierarchy, column_selected))
      : cloneDeep(column_selected)
  );
  const [isOpenModalDetailInfo, setIsOpenModalDetailInfo] =
    useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [isApplyFilter, setIsApplyFilter] = useState<boolean>(false);
  const [rowsPerPage] = useState(10);
  const [rangeAmountInfo, setRangeAmountInfo] = useState<RangeAmount>();
  const [transactionInfo, setTransactionInfo] = useState<Chargeback>({
    approval_code: "",
    approved_transaction_amount: 0,
    created: "01 Jan 1970 00:00:00 GMT",
    credential_metadata: {},
    currency_code: "",
    deadLine: 0,
    doc_files: [],
    ice_value: 0,
    iva_value: 0,
    mail_info: [],
    masked_credit_card: "",
    merchant_id: "",
    merchant_name: "",
    metadata: {},
    payment_brand: "",
    payment_method: "",
    previous_status: [],
    sale_ticket_number: "",
    subtotal_iva: 0,
    subtotal_iva0: 0,
    ticket_number: "",
    transaction_id: "",
    transaction_reference: "",
    transaction_status: "",
  });

  const handleChangeChargebackStatus = (data: {
    status: string;
    id: string;
  }) => {
    dispatch(
      updateChargebackStatus({
        id: data.id,
        searchChargebackRequest: {
          ...searchChargebackRequest,
          limit: 10,
          offset: 0,
        },
        status: data.status,
      })
    );
  };
  const cellAdminUpdateStatusButton = (source: object): ITableCellProps => {
    return {
      props: {
        cellProps: {
          align: "left",
          spacing: 0,
        },
        icon: (
          <UpdateStatusButton
            data={source}
            handleChangeChargebackStatus={handleChangeChargebackStatus}
          />
        ),
        id: "",
        line1: "",
        line2: "",
        onSelected: () => {},
      },
      type: TableBodyCellEnum.TEXT_ACTION,
    };
  };

  const cellAdminUpdateStatusButtonDefault = (): ITableCellProps => {
    return {
      props: {
        cellProps: {
          align: "left",
          spacing: 0,
        },

        line1: "",
        line2: "",
      },
      type: TableBodyCellEnum.TEXT,
    };
  };

  const statusValue = (
    status: string
  ): {
    color: "primary" | "secondary" | "error" | "info" | "success" | "warning";
    label: string;
  } => {
    switch (status) {
      case ChargebackStatusEnum.INITIALIZED:
        return {
          color: "primary",
          label: "INICIADO",
        };
      case ChargebackStatusEnum.EXPIRED:
        return {
          color: "error",
          label: "VENCIDO",
        };
      case ChargebackStatusEnum.WON:
        return {
          color: "success",
          label: "GANADO",
        };
      case ChargebackStatusEnum.REVIEW:
        return {
          color: "warning",
          label: "EN REVISIÓN",
        };
      case ChargebackStatusEnum.LOST:
        return {
          color: "error",
          label: "PERDIDO",
        };
      case ChargebackStatusEnum.FAILED:
        return {
          color: "error",
          label: "FALLIDO",
        };
      case ChargebackStatusEnum.DOCSENT:
        return {
          color: "primary",
          label: "DOCUMENTACIÓN ENVIADA",
        };
      case ChargebackStatusEnum.PENDING:
        return {
          color: "primary",
          label: "PENDIENTE",
        };
      case ChargebackStatusEnum.DECLINED:
        return {
          color: "error",
          label: "PERDIDO",
        };
      case ChargebackStatusEnum.APPROVED:
      case ChargebackStatusEnum.APPROVAL:
        return {
          color: "success",
          label: "GANADO",
        };
      default:
        return {
          color: "error",
          label: "",
        };
    }
  };

  const buildItem = (items: object[]) => {
    return items.map((item) => {
      return {
        ...item,
        customerId: get(item, "customer_code", ""),
        customerName: get(item, "customer_name", ""),
        entityName: get(item, "entity_name", ""),
        ownerName: get(item, "owner_name", ""),
        publicMerchantId: get(item, "merchant_code", ""),
      };
    });
  };

  const rowCells: {
    [key in HeaderColumnIdEnum]: (data: object | Chargeback) => ITableCellProps;
  } = {
    [HeaderColumnIdEnum.START_DATE]: (data: object | Chargeback) => {
      return {
        props: {
          cellProps: {
            align: "left",
            spacing: 0,
          },
          id: "start_date",
          line1: getDateWithCustomFormat(
            get(data, "created", 0),
            DateformatEnum.DD_MMM_YYYY
          ),
          line2: getDateWithCustomFormat(
            get(data, "created", 0),
            DateformatEnum.HH_mm_ss
          ),
          type: "twoLines",
        },
        type: TableBodyCellEnum.TITLE,
      };
    },
    [HeaderColumnIdEnum.DATE_MAX]: (data: object | Chargeback) => {
      const deadLine = has(data, "_id")
        ? get(data, "deadline_timestamp")
        : get(data, "dead_line");

      return {
        props: {
          cellProps: {
            align: "left",
            spacing: 0,
          },
          id: "",
          line1: !isUndefined(deadLine)
            ? getDateWithCustomFormat(deadLine, DateformatEnum.DD_MMM_YYYY)
            : "",
          line2: !isUndefined(deadLine)
            ? getDateWithCustomFormat(deadLine, DateformatEnum.HH_mm_ss)
            : "",
          type: "twoLines",
        },
        type: TableBodyCellEnum.TITLE,
      };
    },
    [HeaderColumnIdEnum.BRANCH]: (data: object | Chargeback) => {
      return {
        props: {
          cellProps: {
            align: "left",
            spacing: 0,
          },
          icon: <IconCopy />,
          id: "",
          line1: toString(get(data, "merchant_name", "")),
          line2: toString(get(data, "merchant_id", "")),
          onSelected: () => {
            copyToClipboard(get(data, "merchant_id", ""));
          },
        },
        type: TableBodyCellEnum.TEXT_ACTION,
      };
    },
    [HeaderColumnIdEnum.CHARGEBACK_NUMBER]: (data: object | Chargeback) => {
      return {
        props: {
          cellProps: {
            align: "left",
            spacing: 0,
          },
          icon: <IconCopy />,
          id: "",
          line1: toString(get(data, "transaction_id", "")),
          line2: "",
          onSelected: () => {
            copyToClipboard(get(data, "transaction_id", ""));
          },
        },
        type: TableBodyCellEnum.TEXT_ACTION,
      };
    },
    [HeaderColumnIdEnum.TICKET_NUMBER]: (data: object | Chargeback) => {
      return {
        props: {
          cellProps: {
            align: "left",
            spacing: 0,
          },
          icon: <IconCopy />,
          id: "",
          line1: toString(get(data, "sale_ticket_number", "")),
          line2: "",
          onSelected: () => {
            copyToClipboard(get(data, "sale_ticket_number", ""));
          },
        },
        type: TableBodyCellEnum.TEXT_ACTION,
      };
    },
    [HeaderColumnIdEnum.CARD_NUMBER]: (data: object | Chargeback) => {
      return {
        props: {
          cellProps: {
            align: "left",
            spacing: 0,
          },
          id: "",
          line1: get(data, "masked_credit_card", ""),
          line2: "",
        },
        type: TableBodyCellEnum.TEXT,
      };
    },
    [HeaderColumnIdEnum.STATUS]: (data: object | Chargeback) => {
      return {
        props: {
          cellProps: {
            align: "left",
            spacing: 0,
          },
          color: statusValue(get(data, "transaction_status", "")).color,
          text: statusValue(get(data, "transaction_status", "")).label,
        },
        type: TableBodyCellEnum.TAG,
      };
    },
    [HeaderColumnIdEnum.PAYMENT_SUB_METHOD_TYPE]: (
      data: object | Chargeback
    ) => {
      return {
        props: {
          cellProps: {
            align: "left",
            spacing: 0,
          },
          icon: <></>,
          id: "",
          line1: defaultTo(
            CATALOG_PAYMENT_SUB_METHOD_TYPE[
              get(
                data,
                "payment_submethod_type",
                PaymentSubMethodTypeEnum.CARD_NO_PRESENT
              )
            ],
            ChargebackPaymentMethodLabelEnum.CARD
          ),
          line2: "",
          onSelected: () => {},
        },
        type: TableBodyCellEnum.TEXT_ACTION,
      };
    },
    [HeaderColumnIdEnum.AMOUNT]: (data: object | Chargeback) => {
      const currency: string = getCurrency(data);
      const amount: string = `${get(
        data,
        "approved_transaction_amount",
        0
      ).toFixed(2)} ${currency}`;

      return {
        props: {
          cellProps: {
            align: "left",
            spacing: 0,
          },
          id: "",
          line1: amount,
          line2: "",
        },
        type: TableBodyCellEnum.TEXT,
      };
    },
    [HeaderColumnIdEnum.CARD_BRAND]: (data: object | Chargeback) => {
      return {
        props: {
          cellProps: {
            align: "left",
            spacing: 0,
          },
          id: "",
          line1: get(data, "payment_brand", ""),
          line2: "",
        },
        type: TableBodyCellEnum.TEXT,
      };
    },
    [HeaderColumnIdEnum.ISSUING_BANK]: (data: object | Chargeback) => {
      return {
        props: {
          cellProps: {
            align: "left",
            spacing: 0,
          },
          id: "",
          line1: get(data, "processor_bank_name", ""),
          line2: "",
        },
        type: TableBodyCellEnum.TEXT,
      };
    },
    [HeaderColumnIdEnum.SUBSCRIPTION_ID]: (data: object | Chargeback) => {
      return {
        props: {
          cellProps: {
            align: "left",
            spacing: 0,
          },
          id: "",
          line1: get(data, "subscription_id", ""),
          line2: "",
        },
        type: TableBodyCellEnum.TEXT,
      };
    },
    [HeaderColumnIdEnum.NAME_ON_CARD]: (data: object | Chargeback) => {
      return {
        props: {
          cellProps: {
            align: "left",
            spacing: 0,
          },
          id: "",
          line1: get(data, "card_holder_name", ""),
          line2: "",
        },
        type: TableBodyCellEnum.TEXT,
      };
    },
    [HeaderColumnIdEnum.CREDENTIAL_ID]: (data: object | Chargeback) => {
      return {
        props: {
          cellProps: {
            align: "left",
            spacing: 0,
          },
          id: "",
          line1: get(data, "credential_id", ""),
          line2: "",
        },
        type: TableBodyCellEnum.TEXT,
      };
    },
    [HeaderColumnIdEnum.CREDENTIAL_ALIASES]: (data: object | Chargeback) => {
      return {
        props: {
          cellProps: {
            align: "left",
            spacing: 0,
          },
          id: "",
          line1: get(data, "credential_alias", ""),
          line2: "",
        },
        type: TableBodyCellEnum.TEXT,
      };
    },
    [HeaderColumnIdEnum.PUBLIC_KEY]: (data: object | Chargeback) => {
      return {
        props: {
          cellProps: {
            align: "left",
            spacing: 0,
          },
          id: "",
          line1: get(data, "public_credential_id", ""),
          line2: "",
        },
        type: TableBodyCellEnum.TEXT,
      };
    },
    [HeaderColumnIdEnum.OWNER]: (data: object | Chargeback) => {
      const hierarchy_info = isMongo
        ? buildItem(get(data, "family_names", []))
        : get(retrieveChildResponse, "items", []);
      const item: object | undefined = filterPublicMerchantId(
        hierarchy_info,
        get(data, "merchant_id", "")
      );

      return {
        props: {
          cellProps: {
            align: "left",
            spacing: 0,
          },
          icon: <></>,
          id: "",
          line1: isEmpty(get(item, "ownerName"))
            ? OcbEnum.OCB_2_0
            : get(item, "ownerName", "-"),
          line2: "",
          onSelected: () => {},
        },
        type: TableBodyCellEnum.TEXT_ACTION,
      };
    },
    [HeaderColumnIdEnum.CUSTOMER]: (data: object | Chargeback) => {
      const hierarchy_info = isMongo
        ? buildItem(get(data, "family_names", []))
        : get(retrieveChildResponse, "items", []);

      const item = filterPublicMerchantId(
        hierarchy_info,
        get(data, "merchant_id", "")
      );

      return {
        props: {
          cellProps: {
            align: "left",
            spacing: 0,
          },
          icon: <></>,
          id: "",
          line1: isEmpty(get(item, "customerName"))
            ? OcbEnum.OCB_2_0
            : get(item, "customerName", "-"),
          line2: isEmpty(get(item, "customerId"))
            ? ""
            : get(item, "customerId", ""),

          onSelected: () => {},
        },
        type: TableBodyCellEnum.TEXT_ACTION,
      };
    },
  };

  const handleRowClick = (source: Chargeback) => {
    setTransactionInfo({ ...source });
    if (
      (auth.isUserMaster() || auth.isMasterCustomer()) &&
      REDIRECT_CHARGEBACK_STATUS.includes(source.transaction_status!)
    ) {
      dispatch(setChargebackTransaction(source));
      navigate(`files/${source.transaction_reference}`);
    } else if (auth.isAdmin()) setIsOpenModalDetailInfo(true);

    copyToClipboard(get(source, "transaction_id", ""));
  };
  const buildHeaders = (): CellHeaderProps[] => {
    const respHeader: CellHeaderProps[] = selectedColumns
      .filter((item) => item.isSelected)
      .map((item) => {
        const col: CellHeaderProps = {
          align: "left",
          spacing: 0,
          text: item.label,
          type: "default",
        };

        return col;
      });

    if (user_profile.admin)
      respHeader.push({
        align: "left",
        spacing: 0,
        text: "",
        type: "default",
      });

    return respHeader;
  };

  const selectedColumnsRender = (source: object): ITableCellProps[] => {
    const selectedColumnsAux: ITableCellProps[] = selectedColumns
      .filter((item: ColumnsItem) => item.isSelected)
      .map((col: ColumnsItem) => {
        const cellProps: ITableCellProps = rowCells[col.field](source);

        return cellProps;
      });

    if (user_profile.admin) {
      if (
        includes(
          [
            ChargebackStatusEnum.REVIEW,
            //ChargebackStatusEnum.DOCSENT,
            // ChargebackStatusEnum.FAILED,
          ],
          get(source, "transaction_status", "")
        )
      ) {
        selectedColumnsAux.push(cellAdminUpdateStatusButton(source));
      }
    }

    if (user_profile.admin) {
      if (
        !includes(
          [
            ChargebackStatusEnum.REVIEW,
            //ChargebackStatusEnum.DOCSENT,
            // ChargebackStatusEnum.FAILED,
          ],
          get(source, "transaction_status", "")
        )
      ) {
        selectedColumnsAux.push(cellAdminUpdateStatusButtonDefault());
      }
    }

    return selectedColumnsAux;
  };

  const buildRows = (skeleton: boolean): ITableRowProps[] => {
    const data = !skeleton
      ? get(searchChargebackResponse, "data", [])
      : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
    const rows: ITableRowProps[] = [];

    data.map((item) => {
      const source = get(item, "_source", {});

      rows.push({
        cells: selectedColumnsRender(source),
        id: get(source, "transaction_id", ""),
        rowProps: {
          color: "default",
          onClick: () => handleRowClick(source),
        },
      });
    });

    return rows;
  };

  const orderColumns = (
    columns: ColumnsItem[],
    fieldToMove: HeaderColumnIdEnum,
    newPosition: number
  ): ColumnsItem[] => {
    const index = columns.findIndex((item) => item.field === fieldToMove);

    if (index !== -1) {
      const item = columns.splice(index, 1)[0];

      columns.splice(newPosition, 0, item);
    }

    return columns;
  };

  const orderExtraColumns = (columns: ColumnsItem[]): ColumnsItem[] => {
    const columnsOrdered = orderColumns(columns, HeaderColumnIdEnum.OWNER, 2);
    const columnsOrdered2 = orderColumns(
      columnsOrdered,
      HeaderColumnIdEnum.CUSTOMER,
      3
    );

    return columnsOrdered2;
  };

  const buildHeadersWithStaticColumns = (columns: ColumnsItem[]) => {
    const headerWithStaticColumns =
      auth.isAdmin() || isRol
        ? orderExtraColumns(column_static_start.concat(columns))
        : column_static_user_start.concat(columns);

    headerWithStaticColumns.push(column_static_end);
    setSelectedColumns(headerWithStaticColumns);
  };

  const handleSelectedColumns = (columns: ColumnsItem[]) => {
    buildHeadersWithStaticColumns(columns);
  };
  const handleSelect = (event: any): void => {
    if (Boolean(event.id))
      switch (event.id) {
        case FileFormatIDsEnum.CSV_DOWNLOAD:
        case FileFormatIDsEnum.EXCEL_DOWNLOAD:
        case FileFormatIDsEnum.JSON_DOWNLOAD:
          dispatch(
            getFirebaseID({
              body: {
                ...searchChargebackRequest,
              },
              format: event.id,
            })
          );
          break;
        case FileFormatIDsEnum.CSV_UPLOAD:
          dispatch(setIsOpenMassiveStatusModal(true));
          break;
      }
  };

  const handlePageChange = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);

    dispatch(
      setSearchChargebackRequest({
        ...searchChargebackRequest,
        offset: rowsPerPage * newPage,
      })
    );
  };

  const onDateSelected = (startDate: string, finishDate: string) => {
    setPage(0);

    dispatch(
      setSearchChargebackRequest({
        ...searchChargebackRequest,
        from: convertDateCorrectFormat(startDate, true),
        limit: 10,
        offset: 0,
        to: convertDateCorrectFormat(finishDate),
      })
    );
  };

  useEffect(() => {
    if (!isEmpty(firebaseId)) downloadTransactions(firebaseId, dispatch);
  }, [firebaseId]);

  useEffect(() => {
    if (
      !isUndefined(selectItems) &&
      !isEmpty(customerList.data) &&
      !isUndefined(customerList.data)
    ) {
      if (!isEmpty(selectItems)) {
        const merchantIds: string[] = selectItems.map(
          (obj) => obj.description || ""
        );
        const branchesId = merchantIds.join("|");

        dispatch(
          setSearchChargebackRequest({
            ...searchChargebackRequest,
            headers: {
              ...searchChargebackRequest.headers,
              Branchesid: branchesId,
            },
          })
        );
      } else {
        const merchantIds: string[] = customerList.data.map(
          (merchantNodeData: MerchantNodeData) =>
            merchantNodeData.publicMerchantId || ""
        );

        const branchesId = merchantIds.join("|");

        dispatch(
          setSearchChargebackRequest({
            ...searchChargebackRequest,
            headers: { Branchesid: branchesId, CustomerPath: nodeInfo.path },
          })
        );
      }
    }
  }, [selectItems, customerList, nodeInfo]);

  useEffect(() => {
    if (isRol && isNil(searchChargebackRequest.headers)) return;

    if (searchChargebackRequest.offset == 0) setPage(0);

    dispatch(searchChargeback(searchChargebackRequest));
  }, [searchChargebackRequest]);

  useEffect(() => {
    buildHeadersWithStaticColumns(selectedColumns);

    return () => {
      dispatch(resetRetrieveChild());
      dispatch(resetFirebaseId());
      dispatch(resetChargebackRequest());
    };
  }, []);

  useLayoutEffect(() => {
    if (user_profile.admin) {
      if (
        get(searchChargebackResponse, "data", []).length !== 0 &&
        get(retrieveChildResponse, "item", []).length === 0
      ) {
        dispatch(
          searchRetrieveChild({
            isWating: false,
            request: retrieveChildRequest,
            type: "",
          })
        );
      }
    }
  }, [retrieveChildRequest]);

  const handleChangeFilterDetails = (
    selectedFilters: TFilterDetailBar[]
  ): void => {
    let filterRequest: object = {};

    set(filterRequest, "transactionStatus", "");
    selectedFilters.map((filter: TFilterDetailBar) => {
      switch (filter.title) {
        case FILTER_GROUPS.PAYMENT_METHODS:
          set(
            filterRequest,
            "paymentSubmethodType",
            buildStringFilter(filter.items)
          );
          break;
        case FILTER_GROUPS.STATE:
          set(
            filterRequest,
            "transactionStatus",
            buildStringFilter(filter.items)
          );
          break;
      }
    });

    const chargebackRequest: SearchChargebackRequest = {
      ...searchChargebackRequest,
      filter: filterRequest,
      offset: 0,
      rangeAmount: rangeAmountInfo,
    };

    if (
      isEmpty(rangeAmountInfo) ||
      isUndefined(rangeAmountInfo) ||
      Number.isNaN(rangeAmountInfo[Object.keys(rangeAmountInfo)[0]])
    )
      delete chargebackRequest.rangeAmount;

    dispatch(setSearchChargebackRequest(chargebackRequest));
  };

  return {
    downloadOptions: {
      handleSelect,
      loadingDownload,
    },
    emptyList: isEmpty(get(searchChargebackResponse, "data", [])),
    handleChangeFilterDetails,
    handlePageChange,
    handleRowClick,
    handleSelectedColumns,
    headers: buildHeaders(),
    isAdmin: user_profile.admin,
    isApplyFilter,
    isLoading:
      searchingChargeback ||
      searchingMerchants ||
      isLoadingNodeInfo ||
      isLoadingMerchantInfo,
    isLoadingStatus,
    isLoadingTransaction,
    isRol,
    modalDetailInfo: {
      isOpenModalDetailInfo,
      setIsOpenModalDetailInfo,
    },
    onDateSelected,
    page,
    rangeAmount: isApplyFilter ? rangeAmountInfo : undefined,
    rows: buildRows(false),
    rowsPerPage,
    rowsSkeleton: buildRows(true),
    selectedBranches: selectItems || [],
    setIsApplyFilter,
    setPage,
    setRangeAmount: setRangeAmountInfo,
    totalItems: get(searchChargebackResponse, "total.value", 0),
    transactionInfo,
  };
};
