import * as React from "react";
import { SvgIcon as MuiSvgIcon, styled, SvgIconProps } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "@kushki/connect-ui";

const SvgIcon = styled(MuiSvgIcon, {
  name: "SheetIcon",
  shouldForwardProp: (prop) => prop !== "fill",
})<SvgIconProps>(({ color, fontSize }) => ({
  fill: color + "!important",
  fontSize: fontSize,
}));

SvgIcon.defaultProps = {
  viewBox: "0 0 24 24",
  focusable: "false",
  "aria-hidden": "true",
};

const IconSheet: React.FunctionComponent<SvgIconProps> = (props) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <SvgIcon
          {...props}
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          fontSize={"large"}
        >
          <path
            d="M23.25 33.75H12.75C11.1587 33.75 9.63258 33.1179 8.50736 31.9926C7.38214 30.8674 6.75 29.3413 6.75 27.75V8.25C6.75 6.6587 7.38214 5.13258 8.50736 4.00736C9.63258 2.88214 11.1587 2.25 12.75 2.25H21.615C22.0128 2.25 22.3944 2.40804 22.6757 2.68934C22.957 2.97064 23.115 3.35218 23.115 3.75C23.115 4.14782 22.957 4.52936 22.6757 4.81066C22.3944 5.09196 22.0128 5.25 21.615 5.25H12.75C11.9544 5.25 11.1913 5.56607 10.6287 6.12868C10.0661 6.69129 9.75 7.45435 9.75 8.25V27.75C9.75 28.5456 10.0661 29.3087 10.6287 29.8713C11.1913 30.4339 11.9544 30.75 12.75 30.75H23.25C24.0457 30.75 24.8087 30.4339 25.3713 29.8713C25.9339 29.3087 26.25 28.5456 26.25 27.75V11.115C26.25 10.7172 26.408 10.3356 26.6893 10.0543C26.9706 9.77303 27.3522 9.615 27.75 9.615C28.1478 9.615 28.5294 9.77303 28.8107 10.0543C29.092 10.3356 29.25 10.7172 29.25 11.115V27.75C29.25 29.3413 28.6179 30.8674 27.4926 31.9926C26.3674 33.1179 24.8413 33.75 23.25 33.75Z"
            fill="#023365"
          />
          <path
            d="M26.3855 14.25H21.6155C21.2214 14.2327 20.8346 14.1378 20.4772 13.9708C20.1198 13.8038 19.7989 13.5679 19.5328 13.2767C19.2667 12.9855 19.0606 12.6447 18.9264 12.2738C18.7921 11.9028 18.7324 11.5091 18.7505 11.115V5.385C18.7324 4.99094 18.7921 4.59718 18.9264 4.22624C19.0606 3.85531 19.2667 3.51449 19.5328 3.22329C19.7989 2.9321 20.1198 2.69624 20.4772 2.52923C20.8346 2.36222 21.2214 2.26734 21.6155 2.25C21.8359 2.24964 22.0536 2.29784 22.2532 2.39118C22.4528 2.48451 22.6295 2.62068 22.7705 2.79L28.9055 10.155C29.1291 10.4249 29.2512 10.7645 29.2505 11.115C29.2686 11.5091 29.2089 11.9028 29.0746 12.2738C28.9404 12.6447 28.7343 12.9855 28.4682 13.2767C28.2021 13.5679 27.8811 13.8038 27.5238 13.9708C27.1664 14.1378 26.7796 14.2327 26.3855 14.25ZM21.7655 11.25H25.9055L21.7505 6.255V11.115C21.7505 11.115 21.7655 11.22 21.7655 11.25Z"
            fill="#023365"
          />
          <path
            d="M22.4993 21H13.4993C13.1014 21 12.7199 20.842 12.4386 20.5607C12.1573 20.2794 11.9993 19.8978 11.9993 19.5C11.9993 19.1022 12.1573 18.7206 12.4386 18.4393C12.7199 18.158 13.1014 18 13.4993 18H22.4993C22.8971 18 23.2786 18.158 23.5599 18.4393C23.8412 18.7206 23.9993 19.1022 23.9993 19.5C23.9993 19.8978 23.8412 20.2794 23.5599 20.5607C23.2786 20.842 22.8971 21 22.4993 21Z"
            fill="#023365"
          />
          <path
            d="M22.4993 27H13.4993C13.1014 27 12.7199 26.842 12.4386 26.5607C12.1573 26.2794 11.9993 25.8978 11.9993 25.5C11.9993 25.1022 12.1573 24.7206 12.4386 24.4393C12.7199 24.158 13.1014 24 13.4993 24H22.4993C22.8971 24 23.2786 24.158 23.5599 24.4393C23.8412 24.7206 23.9993 25.1022 23.9993 25.5C23.9993 25.8978 23.8412 26.2794 23.5599 26.5607C23.2786 26.842 22.8971 27 22.4993 27Z"
            fill="#023365"
          />
        </SvgIcon>
      </ThemeProvider>
    </>
  );
};

export default IconSheet;
