import React from "react";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  InvoiceRecord,
  ResponseSearchBillingByFilters,
} from "../../../../types/remote/response_search_blling_by_filters";
import DashboardHistoricTableRow from "../../DashboardList/Table/DashboardHistoricTable/DashboardHistoricTableRow";
import { defaultTo, get } from "lodash";
import { TransactionTable } from "../../../../types/transaction_table";
import { IColumnsTable } from "../../../shared/infrastructure/table/IColumnsTable";
import { DownloadDocumentParams } from "../../DashboardList/Table/DashboardHistoricTable/DashboardHistoricTable";

export interface TableContainerHistoricDownloadProps {
  historicBillingData: ResponseSearchBillingByFilters;
  children?: React.ReactNode;
  update: (
    event: React.ChangeEvent<HTMLInputElement>,
    data: TransactionTable[]
  ) => void;
  setBillingHistoricDataRow: (
    billingDataRow: InvoiceRecord,
    index: number
  ) => void;
  checkValue: boolean;
  transactionTableCollectionsHistory: InvoiceRecord | undefined;
  transactionIdFilterHistory: () => void;
  tableColumns: {
    renderColumns: IColumnsTable[];
  };
  fixedHistoricHeaders: IColumnsTable[];
  modalOver: {
    openModal: boolean;
    handlerCloseModal: () => void;
    handlerSelectHistoricTrx: (row: InvoiceRecord | undefined) => void;
    historicTrx: InvoiceRecord;
    downloadFilePDF: (
      invoiceId: string,
      merchantName: string,
      extension: string
    ) => void;
    isLoadingDownloadEfact: boolean;
    isHistoricMinAmountTrx: boolean;
    downloadPreprocessFileWS: (params: DownloadDocumentParams) => void;
    isLoadingFileDetail: boolean;
    isLoadingDownloadPreProcess: boolean;
    downloadFilePDFS3: (invoiceId: string) => void;
    downloadPdfProps: string;
    downloadPdfLoading: boolean;
  };
  modalBackRetention: {
    openModalBackRetention: boolean;
    handlerOpenModalBackRetention: (row: InvoiceRecord | undefined) => void;
    handlerCloseModalBackRetention: () => void;
    handlerAcceptTransaction: () => void;
    isLoading: boolean;
  };
  country: string;
}

export const TableContainerHistoricDownload = (
  props: TableContainerHistoricDownloadProps
) => {
  const {
    update,
    checkValue,
    tableColumns,
    fixedHistoricHeaders,
    country,
    modalOver,
  } = props;
  return (
    <>
      {props.historicBillingData.records.length > 0 && (
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  id={"checkStatusAllHistoric"}
                  color="primary"
                  onChange={(e) => update(e, props.historicBillingData.records)}
                  checked={checkValue}
                />
              </TableCell>
              {tableColumns.renderColumns.map((column: IColumnsTable) => (
                <TableCell
                  key={column.id}
                  // @ts-ignore
                  align={defaultTo(column.align, "inherit")}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.transactionTableCollectionsHistory?.map(
              (item: InvoiceRecord, index: number) => {
                return (
                  <DashboardHistoricTableRow
                    renderColumns={fixedHistoricHeaders}
                    key={`TransactionHistoricBilling_${get(
                      item,
                      "tax_id",
                      0
                    )}_${index}`}
                    billingInitialStatus={get(item, "initial_status", "")}
                    billingState={get(item, "status", "")}
                    historicBillingData={item}
                    handlerOpenRow={modalOver.handlerSelectHistoricTrx}
                    country={country}
                    index={index}
                    modalBackRetention={props.modalBackRetention}
                  />
                );
              }
            )}
          </TableBody>
        </Table>
      )}
      {props.children}
    </>
  );
};
