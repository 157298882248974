import React from "react";
import { Box, Grid } from "@mui/material";

import { useModalCatalogGeneralRulesState } from "./state/useModalCatalogGeneralRulesState";

import { ModalDetail } from "@kushki/connect-ui";
import { ListCatalogGeneralRulesComponent } from "../../components/ListCatalogGeneralRulesComponent/ListCatalogGeneralRulesComponent";
import { DetailCatalogGeneralRulesComponent } from "../../components/DetailCatalogGeneralRulesComponent/DetailCatalogGeneralRulesComponent";

export const ModalCatalogGeneralRules: React.FC = () => {
  const { header, actions, isOpenModal, showButton, selectedRule } =
    useModalCatalogGeneralRulesState();

  return (
    <Box sx={{ zIndex: 10 }}>
      <ModalDetail
        variant={"content"}
        header={header}
        isOpen={isOpenModal}
        onClose={actions.onClose}
      >
        <Grid container spacing={2}>
          <Grid item md={6}>
            <ListCatalogGeneralRulesComponent
              setSelectedRule={actions.setSelectedRule}
            />
          </Grid>
          <Grid item md={6}>
            <DetailCatalogGeneralRulesComponent
              showButton={showButton}
              selectedRule={selectedRule}
            />
          </Grid>
        </Grid>
      </ModalDetail>
    </Box>
  );
};
