import React from "react";
import { Button, Container, Grid, Typography } from "@material-ui/core";
import { IAppState } from "../../store/reducer";
import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  activateMerchant,
  getMerchantAndSemaphore,
  IAppAction,
  notifyMerchant,
  setIsEdit,
  setNotification,
  setStepStatus,
  updateMultipleChildrenStatus,
  updateStepStatus,
} from "../../store/actionCreators";
import { connect } from "react-redux";
import { useMerchantDetailsIndexState } from "./state/useMerchantDetailsIndex";
import { CreateMerchantHeader } from "../../components/CreateMerchantComponents/CreateMerchantHeader/CreateMerchantHeader";
import { MerchantDetailsAdditionalSteps } from "../../components/CreateMerchantComponents/MerchantDetailsAdditionalSteps/MerchantDetailsAdditionalSteps";
import { MerchantDetailsAllSteps } from "../../components/CreateMerchantComponents/MerchantDetailsAllSteps/MerchantDetailsAllSteps";
import SaveIcon from "@material-ui/icons/Save";
import { StepName } from "../../shared/infrastructure/constants/CreateMerchantConstants";
import { StatusSteps } from "../../../types/remote/semaphore_data";
import ImageModal from "../../components/common/ImageModal/ImageModal";
import imgCreateMerchant from "../../assets/image/img-create-merchant.svg";
import { Loader } from "@kushki/frontend-molecules/loader";
import { INotification } from "../../shared/infrastructure/interfaces/INotification";
import CustomRedirectionModal from "../../components/common/CustomRedirectionModal/CustomRedirectionModal";
import { MerchantActiveNotificationRequest } from "../../../types/merchant_active_notification_request";

export interface IMerchantDetailsStateProps {
  state: IAppState;
}

export interface IMerchantDetailsFunctionsProps {
  setIsEdit(isEdit: boolean): void;
  getMerchantAndSemaphore(publicMerchantId: string): void;
  changeStepStatus(step: StepName, status: StatusSteps): void;
  activateMerchant(publicMerchantId: string): void;
  notifyMerchant(merchantData: MerchantActiveNotificationRequest): void;
  setNotification(payload: INotification): void;
  updateMultipleChildrenStatus(
    publicMerchantId: string,
    stepName: StepName,
    childSteps: { childStepName: string; newStatus: StatusSteps }[]
  ): void;
  updateStepStatus(
    publicMerchantId: string,
    stepName: StepName,
    status: StatusSteps
  ): void;
}

export type TMerchantDetailsIndexProps = IMerchantDetailsStateProps &
  IMerchantDetailsFunctionsProps;

export const MerchantDetailsIndex: React.FC<TMerchantDetailsIndexProps> = (
  props: TMerchantDetailsIndexProps
): JSX.Element | null => {
  const {
    breadCrumbs,
    state: {
      isNewMerchant,
      isDisableUserStep,
      expandedSection,
      steps,
      merchantAffiliation,
    },
    handlers: { handleSectionExpansion, changeStepStatus },
    open,
    handleOpen,
    handleBackPage,
    handleNotify,
    handleOmit,
    isCompleted,
    isLoading,
    form,
    isOmitted,
  } = useMerchantDetailsIndexState(props);

  if (!steps) return null;
  const onCopyMerchantIdHandler = (text: string, result: boolean) =>
    console.log(`Text '${text}' was ${result ? "" : "NOT"} copied`);
  return (
    <Container fixed>
      <Breadcrumb
        items={breadCrumbs.items}
        lastItem={breadCrumbs.lastItem}
        styles={{ marginBottom: 0 }}
      />
      <CreateMerchantHeader
        setIsEdit={props.setIsEdit}
        merchant={merchantAffiliation!}
        onCopy={onCopyMerchantIdHandler}
      />
      <MerchantDetailsAllSteps
        steps={steps}
        isNewMerchant={isNewMerchant}
        isDisableUserStep={isDisableUserStep}
        expandedSection={expandedSection}
        handleSectionExpansion={handleSectionExpansion}
        changeStepStatus={changeStepStatus}
      />
      <MerchantDetailsAdditionalSteps
        steps={steps}
        isNewMerchant={isNewMerchant}
        isDisableUserStep={isDisableUserStep}
        expandedSection={expandedSection}
        handleSectionExpansion={handleSectionExpansion}
        changeStepStatus={changeStepStatus}
      />
      <Grid
        container
        direction="row"
        justify="flex-end"
        style={{
          marginTop: 34,
        }}
      >
        <Button onClick={handleOpen}>
          <Typography>Salir</Typography>
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          startIcon={<SaveIcon />}
          style={{ marginLeft: 39 }}
          onClick={form.submit}
        >
          Guardar datos
        </Button>
        <ImageModal
          open={isCompleted}
          headerText={
            isOmitted
              ? "No se enviará la notificación"
              : "Comercio creado con éxito"
          }
          bodyText={
            isOmitted
              ? "El comercio no recibirá ninguna notificación de activación. Esta acción no se podrá deshacer."
              : "Se ha completado la información necesaria para que el comercio empiece a transaccionar."
          }
          firstButtonText={isOmitted ? "Continuar" : "Notificar al comercio"}
          onClickFirstButton={isOmitted ? handleBackPage : handleNotify}
          secondButtonText={isOmitted ? "Volver" : "Omitir"}
          onClickSecondButton={handleOmit}
          imageSrc={imgCreateMerchant}
        />
        <CustomRedirectionModal
          open={open}
          handleClose={handleOpen}
          headerText={"¿Deseas salir del formulario de creación de comercio?"}
          bodyText={
            "Te recordamos que el comercio no podrá transaccionar hasta que todos los pasos sean completados. La información ingresada se guardará y podrás seguir editándola más adelante."
          }
          firstButtonText={"Cancelar"}
          onClickFirstButton={handleOpen}
          secondButtonText={"Salir del formulario"}
          onClickSecondButton={handleBackPage}
        />
        <Loader
          open={isLoading}
          headerText={"Estamos activando el comercio"}
          bodyText={"Por favor, espera..."}
        />
      </Grid>
    </Container>
  );
};

export const mapStateToProps: (
  state: IAppState
) => IMerchantDetailsStateProps = (
  state: IAppState
): IMerchantDetailsStateProps => ({ state });

export const mapDispatchToProps: (
  dispatch: Dispatch
) => IMerchantDetailsFunctionsProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): IMerchantDetailsFunctionsProps => ({
  setIsEdit: (isEdit: boolean) => dispatch(setIsEdit(isEdit)),
  changeStepStatus: (step: StepName, status: StatusSteps) =>
    dispatch(setStepStatus(step, status)),
  getMerchantAndSemaphore: (publicMerchantId: string) =>
    dispatch(getMerchantAndSemaphore(publicMerchantId)),
  activateMerchant: (publicMerchantId: string) =>
    dispatch(activateMerchant(publicMerchantId)),
  notifyMerchant: (merchantData: MerchantActiveNotificationRequest) =>
    dispatch(notifyMerchant(merchantData)),
  setNotification: (payload: INotification) =>
    dispatch(setNotification(payload)),
  updateMultipleChildrenStatus: (
    publicMerchantId: string,
    stepName: StepName,
    childSteps: { childStepName: string; newStatus: StatusSteps }[]
  ) =>
    dispatch(
      updateMultipleChildrenStatus(publicMerchantId, stepName, childSteps)
    ),
  updateStepStatus: (
    publicMerchantId: string,
    stepName: StepName,
    status: StatusSteps
  ) =>
    dispatch(updateStepStatus(stepName, status, publicMerchantId, undefined)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MerchantDetailsIndex);
