import React, { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TitleHeader from "../TitleHeader/TitleHeader";
import { IRepresentativeData } from "./RepresentativeContainer.interface";
import theme from "../../../theme";
import { setPersonType } from "../../../shared/utils/typePerson";
import {
  numberTypePerson,
  RepresentativeContainerEnum,
} from "../../../shared/enums/RepresentativeContainerEnum";
import { get } from "lodash";

interface IItem {
  label: string;
  text: string;
  isFirstOne?: boolean;
  isLastOne?: boolean;
  isSecondTitle?: boolean;
}

const Item = (props: IItem) => {
  let style;

  if (props.isFirstOne) {
    style = {
      borderRight: "1px solid #CBD5E0",
      margin: 1,
      marginLeft: 0,
      padding: 1,
      paddingLeft: 0,
    };
  } else if (props.isLastOne) {
    style = {
      margin: 1,
      padding: 1,
    };
  } else {
    style = {
      borderRight: "1px solid #CBD5E0",
      justifyContent: "center",
      margin: 1,
      padding: 1,
    };
  }

  return (
    <Box sx={style}>
      <Typography variant="caption" color="text.grey">
        {`${props.label}`}
      </Typography>
      <br />
      <Typography
        color={props.isSecondTitle ? "text.grey" : "neutral.grey6"}
        variant="caption"
        sx={
          !props.isSecondTitle
            ? {
                color: theme.palette.text["grey"],
                fontSize: "13px",
              }
            : {}
        }
      >
        {props.text}
      </Typography>
    </Box>
  );
};

const RepresentativeContainer: FC<IRepresentativeData> = (
  props: IRepresentativeData
) => {
  const { country, email, phone } = props;

  const isNatural: boolean =
    get(props, "items.personType") === numberTypePerson.natural;

  const title: string = isNatural
    ? get(props, "items.name", "") + " " + get(props, "items.lastName", "")
    : get(props, "items.socialReason", "");

  const nameTypePerson: string = setPersonType(country, props.items.personType);

  return (
    <Box
      sx={{
        backgroundColor: "neutral.grey3",
        borderColor: "neutral.grey5",
        borderRadius: "8px",
        width: "100%",
      }}
      p={3}
    >
      <Box
        sx={{
          display: "flex",
          mb: 1,
          pb: 1,
        }}
      >
        <TitleHeader name={title} role={props.role} type={props.type} />
      </Box>

      {isNatural ? (
        <Box
          sx={{
            alignItems: "center",
            display: "grid",
            gridTemplateColumns: "repeat(5, 1fr)",
          }}
        >
          <Item
            label={RepresentativeContainerEnum.typePerson}
            text={nameTypePerson}
            isFirstOne={true}
          />
          <Item
            label={RepresentativeContainerEnum.identificationNumber}
            text={props.items.documentNumber ?? ""}
          />
          <Item label={email} text={phone} />
          <Item
            label={
              RepresentativeContainerEnum.nationality +
              " " +
              props.items.nationality
            }
            text={
              RepresentativeContainerEnum.birthDate +
              " " +
              props.items.birthdate
            }
            isSecondTitle={true}
          />

          <Item
            label={RepresentativeContainerEnum.residenceCountry}
            text={props.items.residencyCountry ?? ""}
            isLastOne={true}
          />
        </Box>
      ) : (
        <Box
          sx={{
            alignItems: "center",
            display: "grid",
            gridTemplateColumns: "repeat(5, 1fr)",
          }}
        >
          <Item
            label={RepresentativeContainerEnum.typePerson}
            text={nameTypePerson}
            isFirstOne={true}
          />
          <Item
            label={RepresentativeContainerEnum.taxIdNumber}
            text={props.items.documentNumber ?? ""}
          />
          <Item label={email} text={phone} />
          <Item
            label={RepresentativeContainerEnum.merchantAddress}
            text={props.items.merchantAdress ?? ""}
          />
          <Item
            label={RepresentativeContainerEnum.constitutionDate}
            text={props.items.constitutionDate ?? ""}
            isLastOne={true}
          />
        </Box>
      )}
    </Box>
  );
};

export default RepresentativeContainer;
