import { IDataSet } from "../../components/InputForms/InputsFormProps.interfaces";
import { ColombiaDocumentTypeEnum } from "./ColombiaDocumentTypeEnum";
import { PersonCategory } from "./PersonCategoryEnum";
import { PersonType } from "./PersonTypeEnum";

export const SHAREHOLDER_KEY = "shareholders";

export const SHAREHOLDER_FORM_OPTIONAL_KEYS = ["secondName", "lastName"];

export const MAX_SHAREHOLDERS = 12;

export const DOCUMENT_TYPE_LABEL = {
  [ColombiaDocumentTypeEnum.CEDULA]: "Cédula",
  [ColombiaDocumentTypeEnum.CEDULA_EXTRANJERIA]: "Cédula de extranjería",
  [ColombiaDocumentTypeEnum.PASAPORTE]: "Pasaporte",
  [ColombiaDocumentTypeEnum.NUIP]: "NUIP",
};

export const DOCUMENT_TYPE_VALUES: IDataSet[] = [
  {
    text: DOCUMENT_TYPE_LABEL[ColombiaDocumentTypeEnum.CEDULA],
    value: ColombiaDocumentTypeEnum.CEDULA,
  },
  {
    text: DOCUMENT_TYPE_LABEL[ColombiaDocumentTypeEnum.CEDULA_EXTRANJERIA],
    value: ColombiaDocumentTypeEnum.CEDULA_EXTRANJERIA,
  },
  {
    text: DOCUMENT_TYPE_LABEL[ColombiaDocumentTypeEnum.PASAPORTE],
    value: ColombiaDocumentTypeEnum.PASAPORTE,
  },
  {
    text: DOCUMENT_TYPE_LABEL[ColombiaDocumentTypeEnum.NUIP],
    value: ColombiaDocumentTypeEnum.NUIP,
  },
];

export const PERSON_TYPE_LABEL = {
  [PersonType.NATURAL]: "Natural",
  [PersonType.LEGAL]: "Jurídica",
};

export const PERSON_TYPE_ITEMS = [
  {
    text: PERSON_TYPE_LABEL[PersonType.NATURAL],
    value: PersonType.NATURAL,
  },
  {
    text: PERSON_TYPE_LABEL[PersonType.LEGAL],
    value: PersonType.LEGAL,
  },
];

export const DEFAULT_NATURAL_SHAREHOLDER_STATE = {
  documentNumber: "",
  documentType: "",
  firstName: "",
  lastName: "",
  personCategory: PersonCategory.SHAREHOLDER,
  personType: PersonType.NATURAL,
  secondName: "",
  surName: "",
};

export const DEFAULT_LEGAL_SHAREHOLDER_STATE = {
  documentNumber: "",
  personCategory: PersonCategory.SHAREHOLDER,
  personType: PersonType.LEGAL,
  socialReason: "",
};
