export const SIDEBAR = "sidebar";
export const SUB_MERCHANT = "Subcomercio";
export const OPEN_MODAL = "abrir modalExit";
export const M_CLIENTS = "M_CLIENTS";
export const CHARGE = "charge";

export enum ComponentIdsEnum {
  M_CLIENTS_ADD_BANK_ACCOUNT_BUTTON = "M_CLIENTS.ConfiguraciónFinanciera.AgregarCuentabancaria",
  M_CHARGE_FOOTER_PRIMARY = "M_CLIENTS.Cobro.Guardar",
  M_CHARGE_FOOTER_SECONDARY = "M_CLIENTS.Cobro.Continuar",
}
