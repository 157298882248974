import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { BillingDashboardProps } from "../BillingDashboard";
import { TransactionTable } from "../../../../types/transaction_table";
import {
  cloneDeep,
  defaultTo,
  delay,
  get,
  has,
  isEmpty,
  isNil,
  omit,
  set,
  uniq,
  without,
} from "lodash";
import { endOfDay, format, startOfDay, subDays } from "date-fns";
import {
  InvoiceRecord,
  ResponseSearchBillingByFilters,
} from "../../../../types/remote/response_search_blling_by_filters";
import { FilterDashboardStateProps } from "../../../components/DashboardList/FilterDashboard";
import {
  IChipSelect,
  IOptionFilter,
} from "../../../components/DashboardList/BoxFilter/BoxFilter";
import { CurrencyCountry } from "../../../shared/infrastructure/CurrencyCatalog";
import { Filters } from "../../../../types/filters";
import { FilterTypeEnum } from "../../../shared/infrastructure/FilterTypeEnum";
import {
  StatusFlowEnum,
  StatusFlowPath,
} from "../../../shared/infrastructure/StatusFlowEnum";
import { HistoricFiltersProps } from "../../../components/Filters/HistoricFilters/HistoricFilters";
import { DateRange } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/src/typings/date";
import { KindEnum } from "../../../shared/infrastructure/KindEnum";
import { CountryList } from "../../../components/DashboardList/Table/DashboardBillingTable/DashboardTableRow/DashboardTableRow";
import { CountryEnum } from "../../../shared/infrastructure/CountryEnum";
import {
  getKindRoleCountryCatalog,
  KindCatalog,
} from "../../../shared/infrastructure/KindCatalog";
import { CurrencyEnum } from "../../../shared/infrastructure/CurrencyEnum";
import { BillingTransactionTypeEnum } from "../../../shared/infrastructure/BillingTransactionTypeEnum";
import { TransactionValidators } from "../../../shared/infrastructure/TransactionValidators";
import { CountryType } from "../../../shared/infrastructure/CountryType";
import { DefaultFilter } from "../../../shared/infrastructure/filter/DefaultFilter";
import {
  DefaultHistoricFilter,
  ValidateCompletedFilter,
} from "../../../shared/infrastructure/filter/DefaultHistoricFilter";
import { RangeAmount } from "../../../../types/range_amount";
import {
  HistoricCycleValues,
  HistoricInitialStatusValues,
  HistoricInvoiceModeValues,
  HistoricKindValues,
  HistoricStatusValues,
  HistoricTransactionTypeValues,
  HistoricValuesModelCo,
} from "../../../shared/infrastructure/filter/HistoricFilterValues";
import { CycleEnum } from "../../../shared/infrastructure/CycleEnum";
import {
  CompletedStatus,
  InitialStatusEnum,
  StatusEnum,
} from "../../../shared/infrastructure/StatusEnum";
import { InvoiceModeEnum } from "../../../shared/infrastructure/InvoiceModeEnum";
import {
  IRetentionDashboardState,
  useRetentionDashboardState,
} from "../../../components/RetentionDashboard/state/useRetentionDashboardState";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadFilePDFXMLCSV,
  downloadTransactionTableFile as downloadTransactionFilter,
  downloadTransactionUrlRolAll,
  getPDFRetentionsCo,
  getProcessFileChargeId,
  getProcessFileDispersionId,
  getRetentionFile,
  processRetentionsData,
  setIsLoading,
  setLoadingHistoric,
  setOpenModalBackRetention,
  setOpenModalDetailTimeLine,
  setPDFRetention,
  setStepTimeLineData,
  setTransactionIdArray,
  setTransactionIdArrayHistory,
  setTransactionsBillingFile,
  setTransactionTableDownloadFile,
  updateDispersionReason,
} from "../../../store/actionCreators";
import { FormatEnum } from "../../../shared/infrastructure/FormatEnum";
import { IStepRetentionTimeLine } from "../../../shared/infrastructure/interfaces/IStepRetentionTimeLine";
import { UserRolesEnum } from "../../../shared/infrastructure/UserRolesEnum";
import { getUserRole } from "../../../shared/infrastructure/constants/UserRoleConstants";
import { BillingTransactionTypeCatalog } from "../../../shared/infrastructure/BillingTransactionTypeCatalog";
import {
  columnsTable,
  defaultColumnsSelectable,
  IColumnsTable,
} from "../../../shared/infrastructure/table/IColumnsTable";
import { TransactionTypeEnum } from "../../../shared/infrastructure/TransactionTypeEnum";
import { ModelsEnum } from "../../../shared/infrastructure/ModelEnum";
import { DownloadDocumentParams } from "../../../components/DashboardList/Table/DashboardHistoricTable/DashboardHistoricTable";
import { StatusPdfEnum } from "../../../shared/infrastructure/StatusPdfEnum";
import { KindRetentionEnum } from "../../../shared/infrastructure/KindRetentionEnum";
import { IBillingDashboardState } from "../../../store/reducer";
import { RetentionEnum } from "../../../shared/infrastructure/RetentionEnum";
import {
  b64toBlob,
  downloadBlob,
} from "../../../shared/infrastructure/constants/PdfViewerDownload";
import { KindModelEnum } from "../../../shared/infrastructure/KindModelEnum";
import { CitiesCoEnum } from "../../../shared/infrastructure/CitiesCoEnum";

export interface ITextArea {
  handleChange: (value: string) => void;
  handleEraseText?: () => void;
  handleDisableButton?: (value: boolean) => void;
  value: string | number | undefined;
  disableButton?: boolean;
  errorCondition: boolean;
  modal?: IModalComment;
  loader?: ILoader;
}
export interface IModalComment {
  openClose: boolean;
  handleOpenClose: () => void;
  handleAccept: (value: string) => void;
}
export interface ILoader {
  openClose: boolean;
}
export interface BillingDashboardState {
  pagination: {
    page: number;
    pageSize: number;
    hideNext: boolean;
    hidePrevious: boolean;
    handleChangePage: (newPage: number) => void;
    handleChangePageSize: (newPageSize: number) => void;
  };
  data: {
    historicBillingData: ResponseSearchBillingByFilters;
  };
  selectProps: {
    country: CountryList;
    isLoadingBilling: boolean;
    isLoadingHistoric: boolean;
    checked: boolean;
    isAdmin: boolean;
    isExecutor: boolean;
    isValidator: boolean;
    query: string;
    statusFlow: StatusFlowEnum;
  };
  actions: {
    handleSelectChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
    handleCheckboxChange: (
      rowValue: TransactionTable
    ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleInputChange: (value: string) => void;
  };
  filterProps: FilterDashboardStateProps;
  pathLoadingProcessBilling: string;
  historicFiltersProps: HistoricFiltersProps;
  modalOver: {
    openModal: boolean;
    openModalRetention: boolean;
    handlerOpenModalRetention: () => void;
    handlerCloseModalRetention: () => void;
    handlerDeleteRetention: () => void;
    handleInputReasonDeleteRetention: (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    handlerCloseModal: () => void;
    handlerSelectHistoricTrx: (row: InvoiceRecord | undefined) => void;
    handleOpenDialogDetail?: (data: IStepRetentionTimeLine) => void;

    pdfViewer?: {
      fileName: string;
      openModal: boolean;
      base64File: string;
      handleCloseModal: () => void;
      handleOpenModal: () => void;
      handlePdfViewer: (
        transactionId: string,
        kindRentenion: KindRetentionEnum
      ) => void;
    };
    handleCloseDialogDetail?: () => void;
    downloadFileRetention: (
      transactionId: string,
      extension: "pdf" | "xml" | "csv"
    ) => void;

    historicTrx: InvoiceRecord;
    downloadFilePDF: (invoiceId: string, extension: string) => void;
    isLoadingDownloadEfact: boolean;
    fileDownloadEfact: string;
    isHistoricMinAmountTrx: boolean;
    downloadPreprocessFileWS: (params: DownloadDocumentParams) => void;
    isLoadingFileDetail: boolean;
    isLoadingDownloadPreProcess: boolean;
    isLoadingDeleteRetention?: boolean;
    availableDeleteRetentionButton: boolean;
    downloadFilePDFS3: (invoiceId: string) => void;
    downloadPdfProps: string;
    reasonDeleteRetention: string | undefined;
    countCharacterReasonDeleteRetention: number;
    downloadPdfLoading: boolean;
    isLoadingFileRetention: boolean;
  };
  modalOverBilling: {
    openModalBilling: boolean;
    handlerCloseModalTrxBilling: () => void;
    handlerSelectTrxBilling: (
      row: TransactionTable | undefined,
      index: number
    ) => void;
    trxBilling: { trx: TransactionTable | undefined; index: number };
    isMinAmountTrx: boolean;
  };
  modalBackRetention: {
    openModalBackRetention: boolean;
    handlerOpenModalBackRetention: (row: InvoiceRecord | undefined) => void;
    handlerCloseModalBackRetention: () => void;
    handlerAcceptTransaction: () => void;
    isLoading: boolean;
  };
  tableColumns: {
    renderColumns: IColumnsTable[];
  };
  textArea: ITextArea;
  sortProps: ISort;
  stateRentetionDashboard: IRetentionDashboardState;
  downloadFileState: {
    isLoading: boolean;
    downloadFile: (format: string) => void;
  };
}

export interface chipState {
  retention_dispersion: boolean;
  invoice: boolean;
  voucher: boolean;
  charge: boolean;
  dispersion: boolean;
  credit_note: boolean;
  debit_note: boolean;
  completed: boolean;
  finished: boolean;
  omitted: boolean;
  emitted: boolean;
  pending: boolean;
  manual: boolean;
  USD: boolean;
  PEN: boolean;
  CLP: boolean;
  UF: boolean;
}

declare type Order = "asc" | "desc";
export const dateFormat: string = "yyyy-MM-dd";
export const initialFromDate: string = format(
  startOfDay(subDays(new Date(), 1)),
  dateFormat
);
export const initialToDate: string = format(endOfDay(new Date()), dateFormat);

export interface ISort {
  handleRequestSort: (
    _event: React.MouseEvent<unknown>,
    property: string
  ) => void;
  order: Order;
  orderBy: string;
}

export interface Sort {
  field: string;
  order: Order;
}

export interface IState {
  sortByKey?: string;
  sortDirection?: "asc" | "desc";
}

export const useBillingDashboardState = (
  props: BillingDashboardProps,
  statusFlowValue: StatusFlowEnum = StatusFlowEnum.EXECUTOR
): BillingDashboardState => {
  const isBackofficeAdmin: boolean = getUserRole(UserRolesEnum.BackofficeAdmin);
  const isBackofficeCoreAccountingExecutor: boolean = getUserRole(
    UserRolesEnum.BackofficeCoreAccountingExecutor
  );
  const isBackofficeCoreAccountingValidator: boolean = getUserRole(
    UserRolesEnum.BackofficeCoreAccountingValidator
  );
  const isBackofficeFinExecutor: boolean = getUserRole(
    UserRolesEnum.BackofficeFinExecutor
  );
  const isBackofficeFinValidator: boolean = getUserRole(
    UserRolesEnum.BackofficeFinValidator
  );
  const { pdfRetention } = useSelector(
    (state: IBillingDashboardState) => state
  );
  const [openModalPdfViewer, setOpenModalPdfViewer] = useState<boolean>(false);

  const [fileNamePdfViewer, setFileNamePdfViewer] = useState<string>("");

  const stateRentetionDashboard: IRetentionDashboardState = useRetentionDashboardState();
  const dispatch = useDispatch();
  const { isLoading, openModalBackRetention } = useSelector(
    (state: IBillingDashboardState) => state
  );
  const [country, setCountry] = useState<CountryList>(CountryEnum.ecuador);
  const [checkedValues, setCheckedValues] = useState<TransactionTable[]>([]);
  const [checked, setChecked] = useState<boolean>(false);
  const [isAdmin, setAdmin] = useState<boolean>(false);
  const [isExecutor, setExecutor] = useState<boolean>(false);
  const [isValidator, setValidator] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [hideNext, setHideNext] = useState<boolean>(true);
  const [hidePrevious, setHidePrevious] = useState<boolean>(true);
  const [filters, setFilters] = useState<object>({});
  const [query, setQuery] = useState<string>("");
  const [statusFlow] = useState<StatusFlowEnum>(statusFlowValue);
  const [value, setValue] = useState<string | number | undefined>("");
  const [errorCondition, setErrorCondition] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const handleChange = (value: string) => {
    setValue(value);
    setErrorCondition(false);
  };
  const handleDisableButton = (value: boolean) => {
    setDisableButton(value);
  };
  const handleEraseText = () => {
    setValue("");
  };
  const [openModalComment, setOpenModalComment] = useState<boolean>(false);
  const handleModalComment = () => {
    setOpenModalComment(!openModalComment);
  };
  const handlerCloseModal = () => SetOpenModal(false);
  const handleAcceptModalCommment = (transactionId: string) => {
    const reason: string = String(value);

    dispatch(updateDispersionReason(transactionId, reason));
    handleModalComment();
    handlerCloseModal();
    setHistoricBillingData(query, fromDate, toDate, filters, page);
  };
  const [renderColumns, setRenderColumns] = useState<IColumnsTable[]>(
    columnsTable
  );
  const [selectedColumns, setSelectedColumns] = React.useState<string[]>(
    defaultColumnsSelectable
  );
  const [filterChips, setFilterChips] = useState<{ [key: string]: boolean }>({
    retention_dispersion: false,
    invoice: false,
    voucher: false,
    charge: false,
    dispersion: false,
    credit_note: false,
    debit_note: false,
    emitted: false,
    omitted: false,
    omitted_initial: false,
    finished: false,
    pending: false,
    completed: false,
    manual: false,
    annulled: false,
    USD: false,
    PEN: false,
    CLP: false,
    UF: false,
  });
  const [state, setState] = useState<IState>({
    sortByKey: "",
    sortDirection: "asc",
  });
  const [dateRangeBilling, setDateRangeBilling] = useState<DateRange>([
    startOfDay(subDays(new Date(), 1)),
    endOfDay(new Date()),
  ]);
  const [range, setDateRange] = useState<DateRange>([
    startOfDay(subDays(new Date(), 1)),
    endOfDay(new Date()),
  ]);
  const [fromDateBilling, setStartDateBilling] = useState<string>(
    initialFromDate
  );
  const [toDateBilling, setEndDateBilling] = useState<string>(initialToDate);
  const [fromDate, setStartDate] = useState<string>(initialFromDate);
  const [toDate, setEndDate] = useState<string>(initialToDate);
  const [filtersDashboard, setFilterDashboard] = useState(DefaultFilter);
  const [rangeAmount, setRangeAmount] = useState<RangeAmount>({
    type: "min",
    min: undefined,
    max: undefined,
    value: undefined,
    eq: undefined,
  });
  const [filtersHistoric, setFilterHistoric] = useState(DefaultHistoricFilter);
  const [counterFilter, setCounterFilter] = useState<number>(0);
  const [counter, setCounter] = useState<number>(0);
  const [merchantSearch, setMerchantSearch] = useState<string>("");
  const [openModal, SetOpenModal] = useState<boolean>(false);
  const [type, setType] = useState<string[]>([]);
  const [retentionRow, setRetentionRow] = useState<InvoiceRecord | undefined>(
    []
  );
  const [openModalRetention, setOpenModalRetention] = useState<boolean>(false);
  const [trxBilling, SetTrxBilling] = useState<{
    trx: TransactionTable | undefined;
    index: number;
  }>({ trx: undefined, index: 0 });
  const [openModalBilling, SetOpenModalBilling] = useState<boolean>(false);
  const [historicTrx, SetHistoricTrx] = useState<InvoiceRecord>({});
  const [isMinAmountTrx, SetIsMinAmountTrx] = useState<boolean>(false);
  const [reasonDeleteRetention, setReasonDeleteRetention] = useState<
    string | undefined
  >(undefined);
  const [
    availableDeleteRetentionButton,
    setAvailableDeleteRetentionButton,
  ] = useState<boolean>(true);
  const [
    countCharacterReasonDeleteRetention,
    setCountCharacterReasonDeleteRetention,
  ] = useState<number>(0);
  const [isLoadingFileRetention, setIsLoadingFileRetention] = useState<boolean>(
    false
  );

  const handlePdfViewer = (
    transactionId: string,
    kindRentenion: KindRetentionEnum
  ) => {
    setFileNamePdfViewer(`${transactionId}-${kindRentenion}.pdf`);
  };

  const handleCloseModalPdfViewer = () => {
    setOpenModalPdfViewer(false);
  };

  const handleOpenModalPdfViewer = () => {
    setOpenModalPdfViewer(true);
  };

  const handleAppliedColumnsFilters = (restore?: boolean) => {
    const validCountry: boolean = [
      CountryEnum.peru,
      CountryEnum.mexico,
      CountryEnum.chile,
      CountryEnum.ecuador,
      CountryEnum.colombia,
    ].includes(country);
    let selectedColumns_aux: string[] = selectedColumns;
    if (restore) {
      setSelectedColumns(defaultColumnsSelectable);
      selectedColumns_aux = defaultColumnsSelectable;
    }
    if (selectedColumns_aux.length === 0 && validCountry) {
      setRenderColumns(columnsTable);
      return;
    }
    let aux_columns: IColumnsTable[] = columnsTable.filter(
      (column: IColumnsTable) => {
        return !column.country || column.country.includes(country);
      }
    );
    if (validCountry) {
      aux_columns = aux_columns.filter((column: IColumnsTable) => {
        return !column.filter || selectedColumns_aux.includes(column.id);
      });
    }
    setRenderColumns(aux_columns);
  };
  const getUserRoleName = (): UserRolesEnum[] => {
    let roles: UserRolesEnum[] = [];

    if (isBackofficeCoreAccountingExecutor)
      roles.push(UserRolesEnum.BackofficeCoreAccountingExecutor);
    if (isBackofficeCoreAccountingValidator)
      roles.push(UserRolesEnum.BackofficeCoreAccountingValidator);
    if (isBackofficeFinExecutor)
      roles.push(UserRolesEnum.BackofficeFinExecutor);
    if (isBackofficeFinValidator)
      roles.push(UserRolesEnum.BackofficeFinValidator);
    if (isBackofficeAdmin) roles.push(UserRolesEnum.BackofficeAdmin);
    return roles;
  };

  const handlerSelectHistoricTrx = (row: InvoiceRecord | undefined) => {
    if (row !== undefined) {
      const isMinAmount = TransactionValidators.isMinAmountTrx(
        get(row, FilterTypeEnum.kind, KindEnum.DEBIT_NOTE),
        get(
          row,
          FilterTypeEnum.transactionTypeHistoric,
          BillingTransactionTypeEnum.AUTOMATIC_COMMISSION
        )
      );
      !openModalBackRetention && SetOpenModal(true);
      if (
        get(row, "status") === StatusEnum.ANNUL &&
        country === CountryEnum.mexico
      )
        row.pdf = StatusPdfEnum.GENERATED;

      SetHistoricTrx(row);
      props.setHistoricTrx(row);
      SetIsMinAmountTrx(isMinAmount);
    }
  };

  const handleOpenDialogDetail = (data: IStepRetentionTimeLine) => {
    dispatch(setStepTimeLineData(data));
    dispatch(setOpenModalDetailTimeLine(true));
  };

  const handleCloseDialogDetail = () => {
    dispatch(setOpenModalDetailTimeLine(false));
  };
  const downloadFileRetention = (
    transactionId: string,
    extension: "pdf" | "xml" | "csv"
  ) => {
    const fetchDownloadFileRetention = async () => {
      setIsLoadingFileRetention(true);
      await dispatch(
        downloadFilePDFXMLCSV({
          extension: extension,
          transactionId: transactionId,
        })
      );
    };
    fetchDownloadFileRetention().finally(() => {
      setIsLoadingFileRetention(false);
    });
  };

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const orderBy: string | undefined = state.sortByKey;
    const order: string | undefined = state.sortDirection;
    const isAsc: boolean = orderBy === property && order === "asc";
    setState({
      ...state,
      sortDirection: isAsc ? "desc" : "asc",
      sortByKey: property,
    });
  };

  const handlerSelectTrxBilling = (
    row: TransactionTable | undefined,
    index: number
  ) => {
    const isMinAmount = TransactionValidators.isMinAmountTrx(
      get(row, "transaction.kind", KindEnum.DEBIT_NOTE),
      get(
        row,
        "transaction.transactionType",
        BillingTransactionTypeEnum.AUTOMATIC_COMMISSION
      )
    );

    if (TransactionValidators.isBillingModalDisable(row)) return;

    SetOpenModalBilling(true);
    SetTrxBilling({ trx: row, index });
    SetIsMinAmountTrx(isMinAmount);
  };

  const handlerCloseModalRetention = () => {
    setOpenModalRetention(false);
    setReasonDeleteRetention("");
  };

  const handlerOpenModalRetention = () => setOpenModalRetention(true);

  const handlerOpenModalBackRetention = (row: InvoiceRecord | undefined) => {
    dispatch(setOpenModalBackRetention(true));
    setRetentionRow(row);
  };
  const handlerCloseModalBackRetention = () => {
    dispatch(setOpenModalBackRetention(false));
  };

  const handlerDeleteRetention = () => {
    props.deleteRetention(
      get(historicTrx, "transaction_id"),
      reasonDeleteRetention!
    );
    setOpenModalRetention(false);
    SetOpenModal(false);
    setReasonDeleteRetention("");
  };
  const handleInputReasonDeleteRetention = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setReasonDeleteRetention(get(e, "target.value", "").slice(0, 200));
    if (defaultTo(e.target.value.length, 0) == 0)
      setAvailableDeleteRetentionButton(true);
    else setAvailableDeleteRetentionButton(false);

    if (defaultTo(e.target.value.length, 0) <= 200)
      setCountCharacterReasonDeleteRetention(
        defaultTo(e.target.value.length, 0)
      );
  };

  const handlerCloseModalTrxBilling = () => {
    SetOpenModalBilling(false);
    SetTrxBilling({ trx: undefined, index: 0 });
  };

  const handleSetDate = (dateRange: DateRange) => {
    setDateRange(dateRange);
    const [firstDate, secondDate]: [
      MaterialUiPickersDate,
      MaterialUiPickersDate
    ] = dateRange;
    const startDate: Date = new Date(firstDate?.toISOString()!);
    const endDate: Date = new Date(secondDate?.toISOString()!);
    setStartDate(format(startOfDay(startDate), dateFormat));
    setEndDate(format(endOfDay(endDate), dateFormat));
    setPage(0);
  };

  const handleSetDateBilling = (dateRange: DateRange) => {
    setDateRangeBilling(dateRange);
    const [firstDate, secondDate]: [
      MaterialUiPickersDate,
      MaterialUiPickersDate
    ] = dateRange;
    const startDate: Date = new Date(firstDate?.toISOString()!);
    const endDate: Date = new Date(secondDate?.toISOString()!);

    const startDateValue: string = format(startOfDay(startDate), dateFormat);
    const endDateValue: string = format(endOfDay(endDate), dateFormat);

    setStartDateBilling(startDateValue);
    setEndDateBilling(endDateValue);

    filtersAplied(
      filtersDashboard,
      merchantSearch,
      get(rangeAmount, "value") === undefined ? undefined : rangeAmount,
      startDateValue,
      endDateValue
    );
  };

  const verifyStatusFilters = (
    statusTemp: string[],
    selectedFilters: string[]
  ) =>
    !isEmpty(statusTemp.filter((status) => selectedFilters.includes(status)));

  const prepareCompleteStatusFilters = (
    selectedFilters: string[],
    hasStatusRetentions: boolean,
    statusTemp: string[],
    statusFinal: StatusEnum,
    kindFinal: string[]
  ): string[] => {
    if (
      verifyStatusFilters(statusTemp, selectedFilters) &&
      !hasStatusRetentions
    ) {
      selectedFilters = selectedFilters.filter(
        (status: string) => !statusTemp.includes(status)
      );
      selectedFilters.push(statusFinal);
      selectedFilters = selectedFilters.concat(
        KindCatalog[country].filter((kind: string) => kindFinal.includes(kind))
      );
    }
    return selectedFilters;
  };

  const handleSetFilters = (filters: { [key: string]: boolean }) => {
    setFilterChips(filters);
    let selectedFilters = Object.keys(filters).filter(
      (key: string) => filters[key]
    );
    setCounter(selectedFilters.length);
    const statusRetentions: string[] = [
      StatusEnum.REJECTED,
      StatusEnum.DELETED,
      StatusEnum.COMPLETED,
    ];
    const hasStatusRetentions: boolean = selectedFilters.some(
      (status: string) => statusRetentions.includes(status)
    );
    const hasKindTrx: boolean = selectedFilters.some((status: string) =>
      HistoricKindValues.includes(status as KindEnum)
    );
    let transaction_type: string[] = [];
    let processor_type: string[] = [];
    let kinds: string[] = [];
    let invoiceMode: string[] = [];
    let status: string[] = [];
    let cycle: string[] = [];
    let currency: string[] = [];
    let initial_status: string[] = [];
    let initial_transaction_elements: string[] = [];
    let kindModel: string[] = [];
    let municipality: string[] = [];
    if (!hasKindTrx) {
      if (
        selectedFilters.includes(StatusEnum.COMPLETED) &&
        !hasStatusRetentions
      ) {
        selectedFilters = selectedFilters.concat(
          KindCatalog[country].filter(
            (kind: string) => kind !== KindEnum.RETENTION_EC
          )
        );
      }
      let kinds: KindEnum[] = [];
      let kind_status: StatusEnum[] = [];

      ValidateCompletedFilter[country].map(
        (value: { status: StatusEnum; kind: KindEnum }) => {
          if (selectedFilters.includes(value.status)) {
            kinds.push(value.kind);
            kind_status.push(value.status);
          }
        }
      );
      selectedFilters = prepareCompleteStatusFilters(
        selectedFilters,
        hasStatusRetentions,
        kind_status,
        StatusEnum.COMPLETED,
        kinds
      );
    } else if (hasCompletedStatus(selectedFilters)) {
      CompletedStatus.map((status: StatusEnum) => {
        selectedFilters = selectedFilters
          .filter((key: string) => key !== status)
          .filter((key: string) => key !== StatusEnum.COMPLETED)
          .concat([StatusEnum.COMPLETED]);
      });
    }

    const filtered = selectedFilters
      .map((key: string) => {
        if (key === StatusEnum.APPROVED) {
          return StatusEnum.COMPLETED;
        }
        return key;
      })
      .reduce((obj, key) => {
        if (HistoricValuesModelCo.includes(key as KindModelEnum))
          processor_type.push(key);
        if (HistoricTransactionTypeValues.includes(key as TransactionTypeEnum))
          transaction_type.push(key);
        if (HistoricKindValues.includes(key as KindEnum)) kinds.push(key);
        if (HistoricInvoiceModeValues.includes(key as InvoiceModeEnum))
          invoiceMode.push(key);
        if (HistoricStatusValues.includes(key)) status.push(key);
        if (HistoricCycleValues.includes(key as CycleEnum)) cycle.push(key);
        if (HistoricInitialStatusValues.includes(key as InitialStatusEnum))
          initial_status.push(
            key === InitialStatusEnum.OMITTED_HIS
              ? InitialStatusEnum.OMITTED
              : key
          );
        if (
          [
            TransactionTypeEnum.PAYIN_COMISSION,
            TransactionTypeEnum.PAYOUT_COMISSION,
            TransactionTypeEnum.MINIMAL_COMISSION,
            ModelsEnum.GATEWAY,
            ModelsEnum.AGGREGATOR,
          ].includes(key as TransactionTypeEnum | ModelsEnum)
        )
          initial_transaction_elements.push(key);

        if (Object.values(CurrencyEnum).includes(key as CurrencyEnum))
          currency.push(key);
        if (Object.keys(KindModelEnum).includes(key as KindModelEnum))
          kindModel.push(KindModelEnum[key]);
        if (Object.values(CitiesCoEnum).includes(key as CitiesCoEnum))
          municipality.push(key);

        transaction_type.length > 0
          ? (obj = { ...obj, transaction_type: transaction_type })
          : obj;
        kinds.length > 0 ? (obj = { ...obj, kind: kinds.join("|") }) : obj;
        invoiceMode.length > 0
          ? (obj = { ...obj, invoice_mode: invoiceMode.join("|") })
          : obj;
        status.length > 0 ? (obj = { ...obj, status: status.join("|") }) : obj;
        cycle.length > 0 ? (obj = { ...obj, cycle: cycle.join("|") }) : obj;
        processor_type.length > 0
          ? (obj = { ...obj, processor_type: processor_type.join("|") })
          : obj;
        initial_status = initial_status.map((statusKey) => {
          if (statusKey === InitialStatusEnum.OMITTED) return "omitted";
          return statusKey;
        });
        initial_status.length > 0
          ? (obj = { ...obj, initial_status: initial_status.join("|") })
          : obj;
        currency.length > 0
          ? (obj = { ...obj, currency_code: currency.join("|") })
          : obj;
        kindModel.length > 0
          ? (obj = { ...obj, processor_type: kindModel.join("|") })
          : obj;
        municipality.length > 0
          ? (obj = { ...obj, city_id: municipality.join("|") })
          : obj;
        return obj;
      }, {});

    if (get(filters, TransactionTypeEnum.PAYIN_COMISSION, false)) {
      if (
        get(filters, ModelsEnum.GATEWAY, false) &&
        get(filters, ModelsEnum.AGGREGATOR, false)
      ) {
        transaction_type.filter(
          (item) =>
            item ===
            (TransactionTypeEnum.PAYIN_COMISSION ||
              ModelsEnum.GATEWAY ||
              ModelsEnum.AGGREGATOR)
        );
        set(
          filtered,
          "transaction_type",
          transaction_type.concat([TransactionTypeEnum.PAYIN_COMISSION])
        );
      } else {
        const valueSelected = get(filters, ModelsEnum.GATEWAY, false)
          ? ModelsEnum.GATEWAY
          : get(filters, ModelsEnum.AGGREGATOR, false)
          ? ModelsEnum.AGGREGATOR
          : "";

        if (!isEmpty(valueSelected)) {
          set(
            filtered,
            "transaction_type",
            without(
              transaction_type.concat([valueSelected]),
              TransactionTypeEnum.PAYIN_COMISSION
            )
          );
        }
      }
      transaction_type = uniq(get(filtered, "transaction_type", []));
      set(filtered, "transaction_type", transaction_type);
    }

    setFilters(filtered);
    setPage(0);
  };

  const hasCompletedStatus = (selectedFilters: string[]) => {
    const filters: string[] = CompletedStatus.filter((status) =>
      selectedFilters.includes(status)
    );

    return !isEmpty(filters);
  };

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const country = event.target.value as CountryList;
    setCountry(country);
    props.setCountry(country);

    isFirstRunDate.current = true;
    isFirstRunFilters.current = true;
    isFirstRunQuery.current = true;
    setDateRange([startOfDay(subDays(new Date(), 1)), endOfDay(new Date())]);
    setFilterChips({
      retention_dispersion: false,
      invoice: false,
      voucher: false,
      charge: false,
      dispersion: false,
      credit_note: false,
      debit_note: false,
      emitted: false,
      omitted_initial: false,
      omitted: false,
      finished: false,
      pending: false,
      completed: false,
      manual: false,
      annulled: false,
      USD: false,
      PEN: false,
      CLP: false,
      UF: false,
    });
    setPage(0);
    setFilters({});
    setQuery("");
    setStartDate(initialFromDate);
    setEndDate(initialToDate);
  };

  const handleCheckboxChange = (rowValue: TransactionTable) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let rowValues: TransactionTable[] = [...checkedValues];
    if (event.target.checked) {
      rowValues = [...rowValues, rowValue];

      setCheckedValues(rowValues);
      setChecked(event.target.checked);
    } else {
      rowValues = rowValues.filter(
        (item: TransactionTable) => item.transaction !== rowValue.transaction
      );
      setCheckedValues(rowValues);
      setChecked(event.target.checked);
    }
  };
  const downloadFile = (
    format: string,
    dataSelectedTransactions?: InvoiceRecord | undefined
  ) => {
    const requestIds: string[] = [];
    if (!!props) {
      if (dataSelectedTransactions) {
        props.setIsLoadingDownloadFile({
          isDownloadingCheck: true,
          isLoading: false,
        });
        dataSelectedTransactions?.forEach(
          (transaction: { transaction_id: string }) => {
            if (transaction.transaction_id !== undefined)
              requestIds.push(transaction.transaction_id);
          }
        );
      } else
        props.setIsLoadingDownloadFile({
          isDownloadingCheck: false,
          isLoading: true,
        });
    }
    if (format === FormatEnum.CSVRETENTIONS)
      dispatch(
        getRetentionFile({
          country: CountryEnum.ecuador,
          from: fromDate,
          to: toDate,
          format: "csv",
          kind: KindEnum.RETENTION_EC,
        })
      );
    else {
      props.getFirebaseId({
        body: {
          from: fromDate,
          to: toDate,
          limit: 500,
          offset: 0,
          format: format,
          country: country,
          filter: filters,
          generalField: query === "" ? undefined : query,
          requestIds,
        },
      });
    }
  };

  const downloadFileHistoricCol = (format: string) => {
    const type = format.split(",");
    dispatch(setPDFRetention(""));
    dispatch(getPDFRetentionsCo(type[1], type[2] as KindRetentionEnum));
    handlePdfViewer(type[1], type[2] as KindRetentionEnum);
    setType(type);
  };

  useEffect(() => {
    if (!isEmpty(pdfRetention))
      if (type[0] === RetentionEnum.VIEW) {
        handleOpenModalPdfViewer();
      } else {
        if (pdfRetention && !isEmpty(pdfRetention)) {
          const file = b64toBlob(pdfRetention);
          handleDownloadFile(file);
        }
      }
  }, [pdfRetention]);

  const handleDownloadFile = (file: Blob) => {
    const fileNameLength = fileNamePdfViewer.length;
    const isValidExtension =
      fileNamePdfViewer.slice(fileNameLength - 3, fileNameLength) === "pdf";
    if (isValidExtension) downloadBlob(file, fileNamePdfViewer);
  };

  const handlerAcceptTransaction = () => {
    if (retentionRow) {
      const invoiceRecord: InvoiceRecord = retentionRow;
      dispatch(setIsLoading(true));
      dispatch(
        processRetentionsData(
          {
            records: [
              {
                ...invoiceRecord,
                amount: get(invoiceRecord, "amount", 0),
                calculateCycle: get(invoiceRecord, "calculate_cycle", ""),
                created: get(invoiceRecord, "created", ""),
                cycle: get(invoiceRecord, "cycle", "0"),
                description: get(invoiceRecord, "description", ""),
                endDate: get(invoiceRecord, "end_date", ""),
                ivaKushkiCommission: get(
                  invoiceRecord,
                  "iva_kushki_commission",
                  0
                ),
                kind: get(invoiceRecord, "kind", ""),
                kushkiCommission: get(invoiceRecord, "kushki_commission", 0),
                merchantId: get(invoiceRecord, "merchant_id", ""),
                startDate: get(invoiceRecord, "startDate", ""),
                status: StatusEnum.PENDING,
                taxId: get(invoiceRecord, "tax_id", ""),
                totalAmountTrx: get(invoiceRecord, "total_amount_trx", 0),
                transactionId: get(invoiceRecord, "transaction_id", ""),
              },
            ],
            isLoading: true,
          },
          true
        )
      );
      SetOpenModal(openModalBackRetention);
    }
  };

  const downloadTransactionTableFile = async (
    _type: string,
    isAll?: boolean,
    dataSelectedTransactions?: InvoiceRecord | undefined
  ) => {
    const rolesName: UserRolesEnum[] = getUserRoleName();
    if (isAll) {
      dispatch(
        downloadTransactionUrlRolAll(
          country,
          format(new Date(), "yyyy-MM-dd"),
          fromDateBilling,
          toDateBilling,
          statusFlow,
          rolesName
        )
      );
    } else {
      const transactionIds: string[] = [];

      if (get(props, "transactionTableCollections", []).length <= 0) {
        if (dataSelectedTransactions) {
          dataSelectedTransactions?.forEach(
            (transaction: { transaction_id: string }) => {
              if (
                get(
                  transaction,
                  "transaction_id",
                  get(transaction, "transaction.transactionId", "")
                ) !== undefined
              )
                transactionIds.push(
                  get(
                    transaction,
                    "transaction_id",
                    get(transaction, "transaction.transactionId", "")
                  )
                );
            }
          );
        }
      } else {
        if (!!props.transactionTableCollections) {
          props.transactionTableCollections.forEach((transaction) => {
            if (transaction.transaction?.transactionId !== undefined)
              transactionIds.push(transaction.transaction?.transactionId);
          });
        }
      }

      dispatch(
        downloadTransactionFilter(
          country,
          format(new Date(), dateFormat),
          statusFlow,
          transactionIds.length > 0 ? transactionIds : undefined
        )
      );
    }
  };

  const downLoadFile = (
    name: string,
    extension: string,
    base64Dada: string
  ) => {
    let a = document.createElement("a");
    a.href = `data:aplication/${extension};base64,` + base64Dada;
    a.download = name;
    a.click();
    a.remove();
  };

  const downloadFilePDF = (invoiceId: string, extension: string) => {
    if (!invoiceId || !extension) return;
    props.setLoadingDownloadEfact(true);
    props.downloadFileEfact(extension, invoiceId);
    if (props.fileDownloadEfact === "") return;
    downLoadFile(invoiceId, extension, props.fileDownloadEfact);
  };

  useEffect(() => {
    dispatch(setTransactionIdArray({ id: [], data: [] }));
  }, [country]);

  useEffect(() => {
    dispatch(setTransactionIdArrayHistory({ id: [], data: [] }));
  }, [country]);

  useEffect(() => {
    dispatch(getProcessFileDispersionId(country));
    dispatch(getProcessFileChargeId(country));
  }, [country]);

  useEffect(() => {
    handleAppliedColumnsFilters();
  }, []);
  useEffect(() => {
    if (props.fileDownloadDetail === "") return;

    let a = document.createElement("a");
    a.href = props.fileDownloadDetail;
    a.download = "";
    a.click();
    a.remove();

    delay(() => {
      props.setFileDownloadDetail("");
    }, 300);
  }, [props.fileDownloadDetail]);
  useEffect(() => {
    if (value === "") setDisableButton(true);
    else setDisableButton(false);
  }, [value]);
  const handleChangePage = (newPage: number): void => {
    setHistoricBillingData(query, fromDate, toDate, filters, newPage);
    setPage(newPage);
  };
  const handleChangePageSize = (newPageSize: number): void => {
    setPage(0);
    setPageSize(newPageSize);
  };

  const handleInputChange = (event: string) => {
    setQuery(event);
  };

  useEffect(() => {
    props.setStatusFlow(statusFlow);
    props.setCountry(country);
  }, []);

  const isFirstRunDate = useRef(true);
  const isFirstRunQuery = useRef(true);
  const isFirstRunFilters = useRef(true);
  const isFirstChangePage = useRef(true);

  const setHistoricBillingData = (
    query: string,
    fromDate: string,
    toDate: string,
    filters: object,
    page: number,
    sort?: Sort
  ) => {
    if (sort && sort.field != "") {
      setPage(0);
      props.getHistoricBillingData(
        {
          from: fromDate,
          to: toDate,
          limit: pageSize,
          offset: 0,
          format: "screen",
          country,
          sort: sort,
          filter: filters,
        },
        getUserRoleName()
      );
      return;
    }
    if (query === "") {
      props.getHistoricBillingData(
        {
          from: fromDate,
          to: toDate,
          limit: pageSize,
          offset: page,
          format: "screen",
          country,
          filter: filters,
        },
        getUserRoleName()
      );
      return;
    }
    props.getHistoricBillingData(
      {
        from: fromDate,
        to: toDate,
        limit: pageSize,
        offset: page,
        format: "screen",
        country,
        filter: filters,
        generalField: query,
      },
      getUserRoleName()
    );
  };

  const setFiltersValues = (filter: IOptionFilter) => {
    if (filter.id === FilterTypeEnum.currency) {
      filter.options = CurrencyCountry[country];
    }

    if (filter.id === FilterTypeEnum.transactionType) {
      const catalog = BillingTransactionTypeCatalog || [];

      filter.options = filter.options.filter((option) =>
        catalog.includes(option.key as TransactionTypeEnum)
      );
    }

    if (filter.id === FilterTypeEnum.kind) {
      const rolesName: UserRolesEnum[] = getUserRoleName();
      const catalog = getKindRoleCountryCatalog(country, rolesName);

      filter.options = filter.options.filter((option) =>
        catalog.includes(option.key as KindEnum)
      );

      if (
        country === CountryEnum.mexico &&
        statusFlow === StatusFlowEnum.EXECUTOR
      ) {
        filter.options = filter.options.filter(
          (option) => option.key !== KindEnum.ANNUL_INVOICE
        );
      }
    }

    if (
      filter.id === FilterTypeEnum.initialState &&
      country === CountryEnum.colombia
    ) {
      filter.options = [
        ...filter.options,
        {
          key: InitialStatusEnum.OMITTED,
          label: "Omitida",
          selected: false,
        },
      ];
    }

    filter.options.forEach((option) => (option.selected = false));
  };

  useEffect(() => {
    if (!openModalBackRetention) {
      SetOpenModal(false);
      setHistoricBillingData(query, fromDate, toDate, filters, page);
    }
  }, [openModalBackRetention]);

  useEffect(() => {
    if (props.pathLoadingProcessBilling === StatusFlowPath.RELOAD) {
      const changedFilters: IOptionFilter[] = cloneDeep(
        DefaultFilter.filter(
          (v) => !v.countries || v.countries.includes(country)
        )
      );
      changedFilters.forEach((filter) => {
        setFiltersValues(filter);
      });
      setCounterFilter(0);
      setFilterDashboard(changedFilters);
      setMerchantSearch("");
      setDateRangeBilling([
        startOfDay(subDays(new Date(), 1)),
        endOfDay(new Date()),
      ]);
      setStartDateBilling(initialFromDate);
      setEndDateBilling(initialToDate);
      props.getBillingData({
        date: format(new Date(), dateFormat),
        filters: {
          type: [],
          country,
          statusFlow,
          dateFrom: fromDateBilling,
          dateTo: toDateBilling,
        },
        userRole: getUserRoleName(),
      });
    }
  }, [props.pathLoadingProcessBilling]);
  // historic dates
  useEffect(() => {
    if (isFirstRunDate.current) {
      isFirstRunDate.current = false;
      return;
    }
    setHistoricBillingData(query, fromDate, toDate, filters, page);
  }, [range]);

  // //historic search
  useEffect(() => {
    if (isFirstRunQuery.current) {
      isFirstRunQuery.current = false;
      return;
    }

    if (query === "")
      setHistoricBillingData(query, fromDate, toDate, filters, page);
  }, [query]);

  //historic filters
  useEffect(() => {
    if (isFirstRunFilters.current) {
      isFirstRunFilters.current = false;
      return;
    }
    setHistoricBillingData(query, fromDate, toDate, filters, page);
  }, [filters]);

  useEffect(() => {
    const sort = { order: state.sortDirection, field: state.sortByKey } as Sort;
    setHistoricBillingData(query, fromDate, toDate, filters, page, sort);
  }, [state.sortDirection, state.sortByKey]);

  useEffect(() => {
    const nextHidden: boolean =
      get(props, "historicBillingData.total", 0) <= page + pageSize;
    const previousHidden: boolean = page < pageSize;
    setHideNext(nextHidden);
    setHidePrevious(previousHidden);
  }, [props.historicBillingData]);

  useEffect(() => {
    if (isFirstChangePage.current) {
      isFirstChangePage.current = false;
      return;
    }
    setHistoricBillingData(query, fromDate, toDate, filters, page);
  }, [pageSize]);

  useEffect(() => {
    const changedFilters: IOptionFilter[] = cloneDeep(
      DefaultFilter.filter((v) => !v.countries || v.countries.includes(country))
    );
    const changedHistoricFilters: IOptionFilter[] = cloneDeep(
      DefaultHistoricFilter.filter(
        (v) => !v.countries || v.countries.includes(country)
      )
    );
    changedFilters.forEach((filter) => {
      setFiltersValues(filter);
    });
    changedHistoricFilters.forEach((historicFilter) => {
      setFiltersValues(historicFilter);
    });
    setCounterFilter(0);
    setFilterDashboard(changedFilters);
    setCounter(0);
    setFilterHistoric(changedHistoricFilters);
    setMerchantSearch("");
    setDateRangeBilling([
      startOfDay(subDays(new Date(), 1)),
      endOfDay(new Date()),
    ]);
    setStartDateBilling(initialFromDate);
    setEndDateBilling(initialToDate);
    props.getBillingData({
      date: format(new Date(), dateFormat),
      filters: {
        type: [],
        country,
        statusFlow,
        dateFrom: fromDateBilling,
        dateTo: toDateBilling,
      },
      userRole: getUserRoleName(),
    });
    handleAppliedColumnsFilters();
  }, [country]);

  useEffect(() => {
    if (country === CountryEnum.ecuador) props.setReprocessQuantity();
  }, [country]);
  useEffect(() => {
    (statusFlow === StatusFlowEnum.EXECUTOR || StatusFlowEnum.VALIDATOR) &&
      props.getHistoricBillingData(
        {
          country,
          format: "screen",
          from: initialFromDate,
          to: initialToDate,
          limit: pageSize,
          offset: 0,
        },
        getUserRoleName()
      );
  }, [country]);

  useEffect(() => {
    const roles: object = JSON.parse(localStorage.getItem("roles")!);
    const adminRole: boolean = get(roles, "BackofficeAdmin", false);

    setAdmin(adminRole);
    setExecutor(
      get(roles, UserRolesEnum.BackofficeFinExecutor, false) ||
        get(roles, UserRolesEnum.BackofficeCoreAccountingExecutor, false)
    );
    setValidator(
      get(roles, UserRolesEnum.BackofficeFinValidator, false) ||
        get(roles, UserRolesEnum.BackofficeCoreAccountingValidator, false)
    );
  }, []);

  useEffect(() => {
    if (props.firebaseId !== null) {
      props.getTransactionsBillingFile(props.firebaseId);
    }
  }, [props.firebaseId]);

  useEffect(() => {
    if (!isNil(props.transactionsBillingFile)) {
      const a: HTMLElementTagNameMap["a"] = document.createElement("a");
      a.setAttribute("style", "display: none");
      a.href = `${props.transactionsBillingFile.url}`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
    props.setIsLoadingDownloadFile({
      isLoading: false,
      isDownloadingCheck: false,
    });
    dispatch(setTransactionsBillingFile(undefined));
  }, [props.transactionsBillingFile]);

  useEffect(() => {
    if (props.transactionTableFileDownload.base64.length > 0) {
      downLoadFile(
        "transactions.csv",
        "csv",
        props.transactionTableFileDownload.base64
      );
      dispatch(
        setTransactionTableDownloadFile({
          base64: "",
          isDownloadingCheck: false,
          isDownloading: false,
        })
      );
    }
  }, [props.transactionTableFileDownload.base64]);

  // Methods for filter dashboard
  const getCounterFilter = (filters: IOptionFilter[]): number => {
    let options: { key: string; label: string; selected: boolean }[] = [];
    filters.forEach((filter: IOptionFilter) => {
      options = options.concat(filter.options);
    });
    return options.filter(
      (option: { key: string; label: string; selected: boolean }) =>
        option.selected
    ).length;
  };

  const handleClosePopover = () => {
    setCounterFilter(getCounterFilter(filtersDashboard));
  };

  const handleGetFilters = (
    optionsChip: IChipSelect[],
    rangeAmount?: RangeAmount
  ) => {
    const chips_selected: IChipSelect[] = optionsChip.filter(
      (chip: IChipSelect) => chip.selected
    );
    let export_filter_selected: {
      filter: object;
      rangeAmount?: { min?: number; max?: number; eq?: number };
    } = { filter: {} };
    filtersDashboard.forEach((filter: IOptionFilter) => {
      const filter_parent: IChipSelect[] = chips_selected.filter(
        (f: IChipSelect) => f.filter === filter.id
      );
      const filter_keys: string[] = filter_parent.map(
        (f: IChipSelect) => f.key
      );
      filter.options.forEach(
        (option: { key: string; label: string; selected: boolean }) =>
          (option.selected = filter_keys.includes(option.key))
      );
      export_filter_selected.filter[filter.id] = filter_keys.join("|");
    });

    handleClosePopover();
    handleSearchFilter(export_filter_selected, filtersDashboard, rangeAmount);
  };

  const handleSearchFilter = (
    _filters: { filter: object },
    filtersChips: IOptionFilter[],
    rangeAmount?: RangeAmount
  ) => {
    filtersAplied(
      filtersChips,
      undefined,
      rangeAmount,
      fromDateBilling,
      toDateBilling
    );
    setFilterDashboard(filtersChips);
  };

  const filtersAplied = (
    filtersSelected: IOptionFilter[],
    valueSearch?: string,
    rangeAmount?: RangeAmount,
    fromDate?: string,
    toDate?: string
  ) => {
    const filters: Filters = {
      country,
      type: [],
      statusFlow,
    };
    if (valueSearch) {
      filters.type.push("merchantTax");
      filters["merchantTax"] = valueSearch ? valueSearch : undefined;
    }

    const selectTransactionType: string[] = [];
    filtersSelected.forEach((filter) => {
      filter.options.forEach((option) => {
        if (option.selected) {
          const type =
            option.key === InvoiceModeEnum.WITHOUT_IVA ||
            option.key === InvoiceModeEnum.WITH_IVA
              ? "invoiceMode"
              : filter.id;
          if (type != FilterTypeEnum.transactionType) {
            filters[type] = option.key;
            filters.type.push(FilterTypeEnum[type]);
          } else {
            selectTransactionType.push(option.key);
            filters[type] = selectTransactionType;
            filters.type.push(FilterTypeEnum[type]);
          }
        }
      });
    });

    const models: IOptionFilter | undefined = filtersSelected.find(
      (item) => item.id === "models"
    );

    if (has(filters, FilterTypeEnum.transactionType)) {
      filters.transactionType = without(
        filters.transactionType,
        TransactionTypeEnum.PAYIN_COMISSION
      );
      const validateOptions: {
        key: string;
        label: string;
        selected: boolean;
      }[] = defaultTo(models!.options, []).filter((item) => item.selected);
      const valuesModels: string =
        validateOptions.length > 1
          ? TransactionTypeEnum.PAYIN_COMISSION
          : get(validateOptions, "[0].key", "");

      if (!isEmpty(valuesModels)) {
        set(
          filters,
          FilterTypeEnum.transactionType,
          filters.transactionType.concat([valuesModels])
        );
      }
    }
    if (rangeAmount) {
      filters.rangeAmount = rangeAmount;
      setRangeAmount(rangeAmount);
    } else {
      setRangeAmount(
        (rangeAmount = {
          type: "min",
          min: undefined,
          max: undefined,
          value: undefined,
          eq: undefined,
        })
      );
    }
    if (fromDate && toDate) {
      set(filters, "dateFrom", fromDate);
      set(filters, "dateTo", toDate);
    }
    set(
      filters,
      "type",
      filters.type.filter((type) => (type as string) !== "models")
    );
    set(filters, "type", uniq(filters.type));

    props.getBillingData({
      date: format(new Date(), dateFormat),
      filters: omit(filters, "models"),
      userRole: getUserRoleName(),
    });
  };

  const handleChangeSearch = (value: string) => {
    setMerchantSearch(value);
    filtersAplied(
      filtersDashboard,
      value,
      undefined,
      fromDateBilling,
      toDateBilling
    );
  };

  const handleSearchBar = (key: string, valueSearch: string) => {
    if (key !== "Enter") return;
    setMerchantSearch(valueSearch);
    filtersAplied(
      filtersDashboard,
      valueSearch,
      undefined,
      fromDateBilling,
      toDateBilling
    );
  };

  const handleHistoricSearchBar = (value: string) => {
    if (value !== "Enter") return;
    setPage(0);
    setHistoricBillingData(query, fromDate, toDate, filters, page);
  };

  useEffect(() => {
    if (
      props.isLoadingDeleteRetention === false && // DON´T SIMPLIFY
      country === CountryEnum.ecuador
    ) {
      dispatch(setLoadingHistoric(true));
      setTimeout(() => {
        setHistoricBillingData(query, fromDate, toDate, filters, page);
      }, 5000);
    }
  }, [props.isLoadingDeleteRetention]);

  return {
    pagination: {
      page,
      pageSize,
      hideNext,
      hidePrevious,
      handleChangePage,
      handleChangePageSize,
    },
    data: {
      historicBillingData: props.historicBillingData,
    },
    selectProps: {
      country,
      checked,
      isAdmin,
      isExecutor,
      isValidator,
      query,
      statusFlow,
      isLoadingBilling: props.isLoadingBilling,
      isLoadingHistoric: props.isLoadingHistoric,
    },
    actions: {
      handleSelectChange: handleSelectChange,
      handleCheckboxChange: handleCheckboxChange,
      handleInputChange: handleInputChange,
    },
    filterProps: {
      statusFlow,
      filters: filtersDashboard,
      rangeAmount,
      range: dateRangeBilling,
      handleClose: handleClosePopover,
      handleGetFilters: handleGetFilters,
      handleSetDate: handleSetDateBilling,
      handleChangeSearch: handleChangeSearch,
      handleSearchBar: handleSearchBar,
      merchantSearch: merchantSearch,
      counterFilter: counterFilter,
      downloadFileState: {
        isLoading: props.transactionTableFileDownload.isDownloading,
        isDownloadingCheck:
          props.transactionTableFileDownload.isDownloadingCheck,
        downloadFile: downloadTransactionTableFile,
      },
      settingsColumns: {
        handleAppliedColumnsFilters,
        selectedColumns,
        setSelectedColumns,
      },
    },
    historicFiltersProps: {
      filters: filtersHistoric,
      query,
      filterChips,
      range,
      counter,
      country: country as CountryType,
      handleInputChange: handleInputChange,
      handleSetFilters: handleSetFilters,
      handleSetDate: handleSetDate,
      handleSearchBar: handleHistoricSearchBar,
      downloadFileState: {
        isLoading: props.isLoadingDownloadFile.isLoading,
        isDownloadingCheck: props.isLoadingDownloadFile.isDownloadingCheck,
        downloadFile: downloadFile,
      },
      handleAppliedColumnsFilters,
      selectedColumns,
      setSelectedColumns,
      reprocess: {
        reprocessNumber: props.reprocessCount!,
        reprocessTransactions: props.reprocessTransactions,
      },
    },
    modalOver: {
      openModalRetention,
      handlerOpenModalRetention,
      handlerCloseModalRetention,
      handlerDeleteRetention,
      openModal,
      availableDeleteRetentionButton,
      countCharacterReasonDeleteRetention,
      reasonDeleteRetention: reasonDeleteRetention,
      handleInputReasonDeleteRetention,
      handlerCloseModal: handlerCloseModal,
      handlerSelectHistoricTrx: handlerSelectHistoricTrx,
      handleOpenDialogDetail,
      handleCloseDialogDetail,
      downloadFileRetention,
      historicTrx: historicTrx,
      pdfViewer: {
        fileName: fileNamePdfViewer,
        openModal: openModalPdfViewer,
        base64File: pdfRetention!,
        handleCloseModal: handleCloseModalPdfViewer,
        handleOpenModal: handleOpenModalPdfViewer,
        handlePdfViewer: handlePdfViewer,
      },
      downloadFilePDF,
      isLoadingDownloadEfact: props.isLoadingDownloadEfact,
      fileDownloadEfact: props.fileDownloadEfact,
      isHistoricMinAmountTrx: isMinAmountTrx,
      downloadPreprocessFileWS: props.downloadPreprocessFileWS,
      isLoadingFileDetail: props.isLoadingFileDetail,
      isLoadingDownloadPreProcess: props.isLoadingDownloadPreProcess,
      isLoadingDeleteRetention: props.isLoadingDeleteRetention,
      downloadFilePDFS3: props.downloadFilePDFS3,
      downloadPdfProps: props.downloadPdfProps,
      downloadPdfLoading: props.downloadPdfLoading,
      isLoadingFileRetention,
    },
    modalOverBilling: {
      openModalBilling,
      handlerCloseModalTrxBilling,
      handlerSelectTrxBilling,
      trxBilling,
      isMinAmountTrx,
    },
    modalBackRetention: {
      openModalBackRetention,
      handlerOpenModalBackRetention,
      handlerCloseModalBackRetention,
      handlerAcceptTransaction,
      isLoading,
    },
    textArea: {
      errorCondition: errorCondition,
      handleChange: handleChange,
      handleEraseText: handleEraseText,
      handleDisableButton: handleDisableButton,
      disableButton: disableButton,
      value: value,
      modal: {
        openClose: openModalComment,
        handleOpenClose: handleModalComment,
        handleAccept: handleAcceptModalCommment,
      },
      loader: {
        openClose: props.isLoadingUpdateDispersion,
      },
    },
    pathLoadingProcessBilling: props.pathLoadingProcessBilling,
    tableColumns: {
      renderColumns,
    },
    sortProps: {
      handleRequestSort,
      order: state.sortDirection!,
      orderBy: state.sortByKey!,
    },
    stateRentetionDashboard,
    downloadFileState: {
      isLoading: false,
      downloadFile: downloadFileHistoricCol,
    },
  };
};
