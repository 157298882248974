export enum RegularExp {
  DEFAULT = "^[a-zA-Z0-9ñÑÁÉÍÓÚáéíóúÜü ]*$",
}

export enum ErrorMessageEnum {
  CHARACTERS = "No se permiten caracteres especiales",
  MAX_CHARACTERS = "El máximo permitido de caracteres es ",
  OWNER_NAME = "Por favor, ingresa el nombre del owner",
}

export const ownerFormName: string = "ownerName";
