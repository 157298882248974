import { includes, isEmpty } from "lodash";
import { MassiveServiceConfiguration } from "../../../types/massive_service_configuration";
import { defaultTo, filter, get, map } from "lodash";
import {
  Branch,
  MassiveServiceRequest,
} from "../../../types/massive_service_request";
import {
  LocalStoragePathEnum,
  SessionStoragePaths,
} from "../enums/LocalStoragePathEnum";
import { BranchEditItem } from "../../../types/branch_edit_item";

export const defaultMassiveConfig: MassiveServiceConfiguration = {
  advancedConfigState: {
    sandboxCardNotPresent: false,
    sandboxCardPresent: false,
  },
  alreadySent: false,
  commissionState: {
    customerId: {
      error: false,
      name: "",
      value: "",
    },
    deferred: false,
    fixAmount: {
      amount: 0,
      error: false,
    },
    isEditing: false,
    iva: {
      error: false,
      percentage: 0,
    },
    merchantId: {
      country: "",
      error: false,
      name: "",
      value: "",
    },
    rangeCommissionLimit: {
      amount: 0,
      error: false,
    },
    rangeCommissionValue: {
      amount: 0,
      error: false,
    },
    ranges: [],
    rangesError: false,
    recipientType: "branch",
    status: false,
    typeCommission: false,
    variableAmount: {
      amount: 0,
      error: false,
    },
  },
  credentialState: {
    apiKeyProd: "",
    apiKeyTest: "",
    baconProdApiKey: "",
    baconSandboxApiKey: "",
    prodAccountId: "",
    sandboxAccountId: "",
  },
  isDefault: true,
  lastRetryUrls: [],
  merchantId: "",
  merchantState: {
    country: "",
    currency: "USD",
    isEditing: false,
    name: "",
    publicMerchantId: "",
  },
  selectedCards: [
    {
      card: "alia",
      image:
        "https://qa-console.chimborazo.kushki.dev/merchant-properties/99129702151681bfb3dcfe0cf347eb96.png",
      selected: false,
    },
    {
      card: "amex",
      image:
        "https://qa-console.chimborazo.kushki.dev/merchant-properties/c5ee132aef0cd62330a27b0ae3103cf7.png",
      selected: false,
    },
    {
      card: "masterCard",
      image:
        "https://qa-console.chimborazo.kushki.dev/merchant-properties/ae8337d2c9dd135d757e1a415c6716a0.png",
      selected: true,
    },
    {
      card: "visa",
      image:
        "https://qa-console.chimborazo.kushki.dev/merchant-properties/755d60b4c8301728be2841cba162e1a8.png",
      selected: true,
    },
  ],
  serviceState: {
    preAuthCashUrl: {
      enable: false,
      error: false,
      url: "",
    },
    smartlinks: true,
    smartlinkSubdomainUrl: {
      error: false,
      url: "qa",
    },
    vpos: false,
    webcheckoutStatus: false,
  },
  validationChargeState: {
    validationAmount: {
      amount: 0,
      currency: "USD",
      error: false,
      type: "default",
    },
    validationCharge: false,
  },
};

export const isMassivePath = (): boolean => {
  const urlPath: string = window.location.pathname;

  return includes(
    ["/service-configuration/batch/", "/service-configuration/batch"],
    urlPath
  );
};

export const getMassiveConfig = (): MassiveServiceConfiguration => {
  const massiveConfig: MassiveServiceConfiguration = JSON.parse(
    defaultTo(
      localStorage.getItem(LocalStoragePathEnum.MASSIVE_SERVICE_CONFIGURATIONS),
      "{}"
    )
  );

  return massiveConfig.isDefault
    ? {
        ...defaultMassiveConfig,
        alreadySent: massiveConfig.alreadySent,
      }
    : massiveConfig;
};

export const isInvalidMassiveConfig = (
  massiveConfig: MassiveServiceConfiguration
): boolean => {
  return (
    isEmpty(massiveConfig) ||
    (Object.keys(massiveConfig).length === 1 &&
      massiveConfig.isDefault === false)
  );
};

export const getSelectedBranches = (): Branch[] => {
  const branchesList: object = JSON.parse(
    sessionStorage.getItem("branchEditList") || "{}"
  );
  const branchesToEdit: object[] = filter(
    get(branchesList, "data", []),
    (branch: object) => get(branch, "edit", false)
  );

  return map(branchesToEdit, (branch: object) => ({
    branchId: get(branch, "public_merchant_id"),
    name: get(branch, "name"),
    nodeId: get(branch, "node_id"),
  }));
};

export const getCustomerRequestInfo = (): Partial<MassiveServiceRequest> => {
  const customerInfo: object = JSON.parse(
    localStorage.getItem(LocalStoragePathEnum.CUSTOMER_BATCH_INFORMATION) ||
      "{}"
  );
  const username: string = defaultTo(
    localStorage.getItem(LocalStoragePathEnum.USERNAME),
    ""
  );
  const email: string = defaultTo(
    localStorage.getItem(LocalStoragePathEnum.EMAIL),
    ""
  );

  return {
    customerId: get(customerInfo, "publicMerchantId"),
    customerName: get(customerInfo, "name"),
    email,
    username,
  };
};

export const getBranchEditListFromSessionStorage = (item: string) => {
  return get(JSON.parse(item), "data", []);
};

const checkIsModified = (values: string[], valueToValidate: string) =>
  values.some((value: string) => valueToValidate === value);

export const setIsModifyByBranch = () => {
  const branchesForUpdate: string[] = getSelectedBranches().map(
    (branch) => branch.branchId
  );

  const branchEditList: BranchEditItem[] = getBranchEditListFromSessionStorage(
    sessionStorage.getItem(SessionStoragePaths.BRANCH_EDIT_LIST) || "{}"
  );

  const branchEditListWithModify: BranchEditItem[] = branchEditList.map(
    (branchItem: BranchEditItem) => ({
      ...branchItem,
      isModify:
        branchItem.isModify ||
        checkIsModified(branchesForUpdate, branchItem.merchant_id!),
    })
  );

  sessionStorage.setItem(
    SessionStoragePaths.BRANCH_EDIT_LIST,
    JSON.stringify({
      data: branchEditListWithModify,
      total: branchEditListWithModify.length,
    })
  );
};
