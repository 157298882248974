import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const processorDialogComponentStyles = createNamedStyles({
  actionButton: {
    color: "#293036",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "140%",
    marginRight: "7%",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
  buttonPrincipal: {
    color: "#FFFFFF",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "140%",
    backgroundColor: "#023365",
    padding: "1% 5% 1% 5%",
    marginLeft: "7%",
    "&:hover": {
      backgroundColor: "#023365",
    },
  },
  title: {
    color: "#023365",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "19px",
    lineHeight: "140%",
  },
  content: {
    margin: "3% 3% 0% 3%",
  },
  actions: {
    margin: "0% 3% 3% 3%",
  },
});
