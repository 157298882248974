import React from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Select,
} from "@material-ui/core";
import { useTypedController } from "@hookform/strictly-typed";
import ConnectForm from "../commons/ConnectForm/ConnectForm";
import { ISecurityRuleForm } from "../../shared/infrastructure/interfaces/ISecurityRuleForm";
import {
  ACTION_VALUES,
  CODE_MESSAGE_VALIDATION,
  PARTNER_VALIDATOR_VALIDATION,
  PARTNER_VALIDATOR_VALUES,
  SecurityRulesLabelField,
} from "../../shared/infrastructure/constants/SecurityRulesConstants";
import { useWatch } from "react-hook-form";

export interface GeneralActionSectionProps {
  handleSetGeneralAction: (generalAction: string) => void;
  generalAction: string;
}

export const GeneralActionSection: React.FC<GeneralActionSectionProps> = (
  sectionProps: GeneralActionSectionProps
) => {
  return (
    <ConnectForm<ISecurityRuleForm>>
      {({ control, errors, getErrorMessage }) => {
        const TypedController = useTypedController<ISecurityRuleForm>({
          control: control,
        });
        const action = useWatch({
          name: "action",
        }) as string;

        return (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl variant="outlined" error={!!errors.action} fullWidth>
                <InputLabel>{SecurityRulesLabelField.ACTION}</InputLabel>
                <TypedController
                  name={["action"]}
                  rules={{ required: true }}
                  defaultValue={sectionProps.generalAction}
                  render={({ onChange, ...typedProps }) => (
                    <Select
                      {...typedProps}
                      label={SecurityRulesLabelField.ACTION}
                      onChange={(event) => {
                        sectionProps.handleSetGeneralAction(
                          event.target.value as string
                        );
                        onChange(event.target.value);
                      }}
                    >
                      {ACTION_VALUES.map(
                        (
                          actionValue: { name: string; value: string },
                          index: number
                        ) => (
                          <MenuItem key={index} value={actionValue.value}>
                            {actionValue.name}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  )}
                />
                <FormHelperText error={!!errors.action}>
                  {!!errors.action && getErrorMessage(errors.action)}
                </FormHelperText>
              </FormControl>
            </Grid>
            {PARTNER_VALIDATOR_VALIDATION.includes(action) ? (
              <Grid item xs={12} md={6} lg={6}>
                <FormControl
                  variant="outlined"
                  error={!!errors.partnerValidator}
                  fullWidth
                >
                  <InputLabel>{SecurityRulesLabelField.VALIDATOR}</InputLabel>
                  <TypedController
                    name={["partnerValidator"]}
                    render={(props) => (
                      <Select
                        {...props}
                        label={SecurityRulesLabelField.VALIDATOR}
                      >
                        {PARTNER_VALIDATOR_VALUES.map(
                          (
                            item: { name: string; value: string },
                            index: number
                          ) => (
                            <MenuItem key={index} value={item.value}>
                              {item.name}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    )}
                  />
                  <FormHelperText error={!!errors.partnerValidator}>
                    {!!errors.partnerValidator &&
                      getErrorMessage(errors.partnerValidator)}
                  </FormHelperText>
                </FormControl>
              </Grid>
            ) : (
              <Grid item xs={12} md={6} lg={6} />
            )}
            {CODE_MESSAGE_VALIDATION.includes(action) ? (
              <Grid item xs={12} md={6} lg={6}>
                <TypedController
                  name={["code"]}
                  rules={{ required: true }}
                  render={(props) => (
                    <TextField
                      {...props}
                      id="code"
                      label={SecurityRulesLabelField.CODE}
                      variant="outlined"
                      error={!!errors.code}
                      fullWidth
                      helperText={!!errors.code && getErrorMessage(errors.code)}
                    />
                  )}
                />
              </Grid>
            ) : null}
            {CODE_MESSAGE_VALIDATION.includes(action) ? (
              <Grid item xs={12} md={6} lg={6}>
                <TypedController
                  name={["message"]}
                  rules={{ required: true }}
                  render={(props) => (
                    <TextField
                      {...props}
                      id="message"
                      label={SecurityRulesLabelField.MESSAGE}
                      variant="outlined"
                      error={!!errors.message}
                      fullWidth
                      helperText={
                        !!errors.message && getErrorMessage(errors.message)
                      }
                    />
                  )}
                />
              </Grid>
            ) : null}
          </Grid>
        );
      }}
    </ConnectForm>
  );
};

export default GeneralActionSection;
