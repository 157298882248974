import React from "react";
import LegalDetailsBody from "../../components/LegalDetailsBody/LegalDetailBody";
import { ModalLoader } from "@kushki/connect-ui";
import { Box } from "@mui/material";
import { LayoutStepContainerBranch } from "../LayoutStepContainerBranch/LayoutStepContainerBranch";
import { useLegalStateMassive } from "./state/useLegalStateMassive";
import {
  LOADER_DESCRIPTION,
  LOADER_TITLE,
} from "../../shared/constants/labels/ModalConstants";
import { LegalContainerSkeleton } from "../../components/Skeletons/LegalContainerSkeleton/LegalContainerSkeleton";
import { basicStateContainerStyles as styles } from "../BasicFormStateContainer/BasicStateContainer.styles";

const LegalDatailsContainerMassive: React.FC = () => {
  const {
    countriesInfo: { country },
    footerLabel,
    headerProps,
    isEdit,
    primaryButton,
    secondaryButton,
    openModalLoader,
    title,
    numberBranches,
    isModalFinalization,
    setModalFinalization,
    saveValues,
    isLoadingCompliancePeople,
    form,
  } = useLegalStateMassive();

  return (
    <>
      <LayoutStepContainerBranch
        headerProps={{
          country: country,
          isEdit,
          numberBranches: numberBranches,
          tabsInfo: headerProps.tabsNodeInfo,
          title: title,
        }}
        renderComponent={
          <Box sx={styles.boxContent}>
            {isLoadingCompliancePeople ? (
              <LegalContainerSkeleton />
            ) : (
              <LegalDetailsBody form={form} />
            )}
          </Box>
        }
        footerProps={{
          isEdit,
          isLoading: false,
          label: footerLabel,
          primaryButton,
          secondaryButton,
        }}
        modalProps={{
          isModalFinalization: isModalFinalization,
          saveValues: saveValues,
          setFinalization: setModalFinalization,
        }}
      />
      <Box>
        <ModalLoader
          descriptionText={LOADER_DESCRIPTION}
          titleText={LOADER_TITLE}
          isOpen={openModalLoader}
        />
      </Box>
    </>
  );
};

export default LegalDatailsContainerMassive;
