import React, { FC } from "react";
import DispersionForm from "../../components/DispersionForm/DispersionForm";
import FormWrapper from "../../components/FormWrapper/FormWrapper";
import WrapperContainer from "../../components/WrapperContainer/WrapperContainer";
import { ROUTES } from "../../shared/constants/routes";
import { useDispersionContainerState } from "./state/useDispersionContainerState";
import { SnackBarProvider } from "@kushki/connect-ui";
import BankAccountSection from "../../components/BankAccountSection/BankAccountSection";
import DispersionContainerSkeleton from "../../components/Skeletons/DispersionContainerSkeleton/DispersionContainerSkeleton";

const DispersionContainer: FC = () => {
  const {
    form,
    showBankData,
    isCentralizedBranch,
    switchDispersion,
    setSwitchDispersion,
    isLoadingDispersionInformation,
    loading,
  } = useDispersionContainerState();

  return (
    <WrapperContainer currentRoute={ROUTES.DISPERSION}>
      {isLoadingDispersionInformation ? (
        <DispersionContainerSkeleton />
      ) : (
        <FormWrapper title={"Dispersión"}>
          <SnackBarProvider>
            <DispersionForm
              form={form}
              isBranchCentralized={isCentralizedBranch}
              loading={loading}
              massive={false}
              switchState={switchDispersion}
              setSwitchValue={setSwitchDispersion}
            />
            {showBankData && (
              <BankAccountSection
                isCentralizedBranch={isCentralizedBranch}
                massive={false}
              />
            )}
          </SnackBarProvider>
        </FormWrapper>
      )}
    </WrapperContainer>
  );
};

export default DispersionContainer;
