import { IconCircleInformation } from "@kushki/connect-ui";
import { Box, Typography } from "@mui/material";
import { MerchantTypeEnum } from "../../shared/constants/initial_state_legal_details";
import { ShareholderAccordion } from "./ShareholderAccordion/ShareholderAccordion";
import { ShareholderInfo } from "./ShareholderInfo/ShareholderInfo";
import { shareHoldersStyles } from "./Shareholders.styles";
import { useShareholders } from "./state/useShareholders";
import { IShareHolder } from "../../store/interfaces/LegalDetailsState.interfaces";
import React from "react";

interface Props {
  shareholders: IShareHolder[];
}

export const Shareholders: React.FC<Props> = ({ shareholders }) => {
  const {
    flattenedShareholders,
    merchantType,
    registeredShareholders,
    shareholdersParticipation,
  } = useShareholders(shareholders);

  return (
    <>
      {merchantType === MerchantTypeEnum.NATURAL ? (
        <Box sx={shareHoldersStyles.boxContent}>
          <IconCircleInformation />
          <Typography
            sx={shareHoldersStyles.txtBox}
            color="neutral.grey6"
            variant="caption"
          >
            Al ser persona natural no cuenta con composición accionaria
          </Typography>
        </Box>
      ) : (
        <>
          <ShareholderInfo
            registeredShareholders={registeredShareholders}
            shareholdersParticipation={shareholdersParticipation}
          />
          {flattenedShareholders.map((s, i) => (
            <ShareholderAccordion key={`${i}-${s.id}`} shareholder={s} />
          ))}
        </>
      )}
    </>
  );
};
