import { useEffect, useState } from "react";
import { ModalTestURLProps } from "../../ModalTestConnection/ModalTestURL/ModalTestURL";
import { useDispatch, useSelector } from "react-redux";
import { validateUrl } from "../../../store/actionCreators";
import { IAppState } from "../../../store/reducer";
import { IValidUrl } from "../../../shared/infrastructure/interfaces/IValidUrl";
import { ServiceEnum } from "../../../shared/infrastructure/constants/ServiceEnum";

export interface ITextFormState {
  modalTestUrl: ModalTestURLProps;
  handleOpenModal: (webHookUrl: string) => void;
  handleEditionInput: (state: boolean) => void;
  getEditionInput: () => boolean;
  handleActiveState: (state: boolean) => void;
  getActiveState: () => boolean;
  handleEditedWebhookURL: (webhookURL: string, index: number) => void;
  getEditedWebhookURL: () => { url: string; index: number };
  isPreauthEnable?: boolean;
}

export interface UseTextFormStateProps {
  addUrl: (verifiedUrls: IValidUrl | undefined) => void;
}

export const useTextFormState = (
  props: UseTextFormStateProps
): ITextFormState => {
  const dispatch = useDispatch();
  const verifiedUrls = useSelector((state: IAppState) => state.verifiedUrls);
  const isPreauthEnable = useSelector(
    (state: IAppState) => state.isPreauthEnable
  );
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [inEdition, setInEdition] = useState(false);
  const [active, setActive] = useState(false);
  const [editedWebhookURL, setEditedWebhookURL] = useState({
    url: "",
    index: 0,
  });

  useEffect(() => {
    if (verifiedUrls) {
      setLoading(false);
    }
  }, [verifiedUrls]);

  const handleCloseDialog = () => {
    setOpenModal(false);
    setLoading(true);
  };

  const handleSave = () => {
    handleCloseDialog();
    props.addUrl(verifiedUrls);
  };
  const handleOpenModal = (webHookUrl: string) => {
    setOpenModal(true);
    setLoading(true);
    dispatch(
      validateUrl([{ service: ServiceEnum.GENERAL, urls: [webHookUrl] }])
    );
  };

  const handleEditionInput = (state: boolean) => {
    setInEdition(state);
  };

  const getEditionInput = (): boolean => {
    return inEdition;
  };

  const getActiveState = (): boolean => {
    return active;
  };

  const handleActiveState = (state: boolean) => {
    setActive(state);
  };

  const handleEditedWebhookURL = (webhookURL: string, index: number) => {
    setEditedWebhookURL({ ...editedWebhookURL, url: webhookURL, index: index });
  };

  const getEditedWebhookURL = (): { url: string; index: number } => {
    return editedWebhookURL;
  };

  return {
    modalTestUrl: {
      verifiedUrls: verifiedUrls as IValidUrl,
      open: openModal,
      loading,
      handleSave,
      handleCloseDialog,
      loadingModal: false,
    },
    handleOpenModal,
    handleEditionInput,
    getEditionInput,
    handleActiveState,
    getActiveState,
    handleEditedWebhookURL,
    getEditedWebhookURL,
    isPreauthEnable,
  };
};
