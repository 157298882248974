import * as React from "react";
import { SvgIcon as MuiSvgIcon, styled, SvgIconProps } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../Themes/themekushki";

const SvgIcon = styled(MuiSvgIcon, {
  name: "MopeimIcon",
  shouldForwardProp: (prop) => prop !== "fill",
})<SvgIconProps>(({ color, fontSize }) => ({
  fill: color + "!important",
  fontSize: fontSize,
}));

SvgIcon.defaultProps = {
  "aria-hidden": "true",
  focusable: "false",
  viewBox: "0 0 24 24",
};

const IconEdit: React.FunctionComponent<SvgIconProps> = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <SvgIcon
        {...props}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10.2269 15.1067H3.56022C2.85298 15.1067 2.1747 14.8257 1.6746 14.3256C1.17451 13.8255 0.893555 13.1473 0.893555 12.44V5.77336C0.893555 5.06611 1.17451 4.38784 1.6746 3.88774C2.1747 3.38764 2.85298 3.10669 3.56022 3.10669H5.56022C5.73703 3.10669 5.9066 3.17693 6.03163 3.30195C6.15665 3.42698 6.22689 3.59655 6.22689 3.77336C6.22689 3.95017 6.15665 4.11974 6.03163 4.24476C5.9066 4.36979 5.73703 4.44002 5.56022 4.44002H3.56022C3.2066 4.44002 2.86746 4.5805 2.61741 4.83055C2.36736 5.0806 2.22689 5.41973 2.22689 5.77336V12.44C2.22689 12.7936 2.36736 13.1328 2.61741 13.3828C2.86746 13.6329 3.2066 13.7734 3.56022 13.7734H10.2269C10.5805 13.7734 10.9196 13.6329 11.1697 13.3828C11.4197 13.1328 11.5602 12.7936 11.5602 12.44V11.1067C11.5602 10.9299 11.6305 10.7603 11.7555 10.6353C11.8805 10.5103 12.0501 10.44 12.2269 10.44C12.4037 10.44 12.5733 10.5103 12.6983 10.6353C12.8233 10.7603 12.8936 10.9299 12.8936 11.1067V12.44C12.8936 13.1473 12.6126 13.8255 12.1125 14.3256C11.6124 14.8257 10.9341 15.1067 10.2269 15.1067Z" />
        <path d="M12.2196 6.33334C12.0428 6.33334 11.8733 6.2631 11.7482 6.13807C11.6232 6.01305 11.553 5.84348 11.553 5.66667C11.5325 5.34274 11.3946 5.03739 11.1651 4.80788C10.9356 4.57837 10.6302 4.44046 10.3063 4.42C10.1295 4.42 9.95994 4.34977 9.83491 4.22474C9.70989 4.09972 9.63965 3.93015 9.63965 3.75334C9.63965 3.57653 9.70989 3.40696 9.83491 3.28193C9.95994 3.15691 10.1295 3.08667 10.3063 3.08667C10.7796 3.10361 11.2398 3.24628 11.6396 3.5C11.9629 3.70331 12.2363 3.97673 12.4396 4.3C12.6934 4.69986 12.836 5.16008 12.853 5.63334C12.853 5.81015 12.7827 5.97972 12.6577 6.10474C12.5327 6.22977 12.3631 6.3 12.1863 6.3L12.2196 6.33334Z" />
        <path d="M8.7799 9.7734H6.89323C6.71642 9.7734 6.54685 9.70316 6.42182 9.57814C6.2968 9.45312 6.22656 9.28355 6.22656 9.10674V7.22007C6.22693 7.13186 6.24479 7.04461 6.27913 6.96336C6.31346 6.88211 6.36357 6.80848 6.42656 6.74674L10.1999 2.9734C10.3248 2.84924 10.4938 2.77954 10.6699 2.77954C10.846 2.77954 11.015 2.84924 11.1399 2.9734L11.8066 3.64007C11.9071 3.73687 11.9747 3.86279 11.9999 4.00007C12.1514 4.01688 12.2926 4.08512 12.3999 4.1934L13.0666 4.86007C13.1907 4.98498 13.2604 5.15395 13.2604 5.33007C13.2604 5.50619 13.1907 5.67516 13.0666 5.80007L9.29323 9.5734C9.22686 9.64139 9.14672 9.69441 9.05819 9.72891C8.96965 9.7634 8.87477 9.77857 8.7799 9.7734V9.7734ZM7.5599 8.44007H8.50656L11.6132 5.3334L11.4399 5.1534C11.3316 5.0461 11.2634 4.90492 11.2466 4.7534C11.095 4.73659 10.9539 4.66835 10.8466 4.56007L10.6666 4.38674L7.5599 7.4934V8.44007Z" />
        <path d="M12.5533 6.00003C12.3774 5.99752 12.2097 5.92563 12.0866 5.80003L11.42 5.13336C11.3117 5.02605 11.2434 4.88487 11.2266 4.73336C11.0751 4.71654 10.9339 4.64831 10.8266 4.54003L10.16 3.87336C10.097 3.81162 10.0469 3.73799 10.0125 3.65674C9.97819 3.57549 9.96033 3.48823 9.95996 3.40003C9.96247 3.22418 10.0344 3.05645 10.16 2.93336L12.0466 1.05336C12.1715 0.929192 12.3405 0.859497 12.5166 0.859497C12.6928 0.859497 12.8617 0.929192 12.9866 1.05336L14.8666 2.93336C14.9908 3.05827 15.0605 3.22723 15.0605 3.40336C15.0605 3.57948 14.9908 3.74845 14.8666 3.87336L13.0266 5.80003C12.9035 5.92563 12.7358 5.99752 12.56 6.00003H12.5533ZM12 4.00003C12.1515 4.01684 12.2927 4.08508 12.4 4.19336L12.5733 4.36669L13.5133 3.42669L12.5533 2.50669L11.6133 3.44669L11.7866 3.62003C11.8983 3.71922 11.9734 3.85304 12 4.00003V4.00003Z" />
      </SvgIcon>
    </ThemeProvider>
  );
};

export default IconEdit;
