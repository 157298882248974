import { defaultTo, get } from "lodash";
import { StorageKeyEnum } from "../enum/StorageKeyEnum";
import { SessionSupport } from "../../../types/session_support";
import { IUserListQueryString } from "../../store/interfaces/users.interfaces";

export const loadLocalStorage = (
  queryParams: IUserListQueryString
): IUserListQueryString => {
  const cache: object = JSON.parse(
    defaultTo(localStorage.getItem(StorageKeyEnum.CACHE), "{}")
  );
  const sessionSupport: SessionSupport = get(
    cache,
    "sessionSupport",
    undefined
  );

  if (localStorage.getItem(StorageKeyEnum.AUTO_PAYLOAD)) {
    const now = new Date();
    const expirationDate = new Date(
      Number(defaultTo(sessionSupport.expired, now))
    );

    const merchantPayload: object = JSON.parse(
      get(sessionSupport, "merchantPayload", "{}")
    );

    if (now < expirationDate)
      queryParams = {
        ...queryParams,
        merchantId: get(merchantPayload, "preferred_username", ""),
        searchSupport: true,
      };
  }

  return queryParams;
};
