import { Box, Grid } from "@mui/material";
import React, { FC } from "react";
import { footerButtonsWrapperStyles as styles } from "./FooterButtonsWrapper.styles";

export interface IFooterButtonsWrapper {
  children: JSX.Element;
}

const FooterButtonsWrapper: FC<IFooterButtonsWrapper> = ({
  children,
}: IFooterButtonsWrapper) => {
  return (
    <Grid container sx={styles.footerContainer}>
      <Grid item md={10} xs={12} className={"footer-item"}>
        <Box className={"footer-box"}>{children}</Box>
      </Grid>
    </Grid>
  );
};

export default FooterButtonsWrapper;
