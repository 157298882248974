import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const mainSubHeaderStyles = createNamedStyles({
  divider: {
    border: "1px solid  #98A8B8",
    marginTop: "2px",
    marginBottom: "4px",
  },
  flagPadding: {
    paddingTop: "2px",
  },
  boxPadding: {
    paddingLeft: "2px",
  },
  gridTitle: {
    paddingBottom: 1.5,
  },
  gridText: {
    paddingRight: 2,
  },
  gridCountry: {
    paddingLeft: 1.7,
    paddingRight: 3,
  },
  link: {
    color: "#4498EE",
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  iconColor: {
    color: "#023365",
    "&:hover": {
      backgroundColor: "#ECF0F1",
    },
    "&:active": {
      backgroundColor: "#F7F9F9",
    },
  },
  linkText: {
    marginLeft: "7px",
  },
  gridId: {
    paddingLeft: {
      md: 3,
      xs: 1,
    },
    paddingRight: 1.6,
  },
  arrowBtn: {
    paddingLeft: 1,
    width: "auto",
  },
});
