import { IUseProcessedDispersion } from "../../../shared/infrastructure/interfaces/useProcessedDispersion.interface";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { useEffect } from "react";
import {
  getProcessFileDispersion,
  setShowAlertOnGetDispersionFile,
} from "../../../store/actionCreators";
import { FilterDispersionRequest } from "../../../../types/filter_dispersion_request";
import moment from "moment";
import { FilterSortEnum } from "../../../shared/infrastructure/constants/RequestDispersionConstants";

export const useProcessedDispersionState = (): IUseProcessedDispersion => {
  const dispatch = useDispatch();
  const showAlertOnGetDispersionFileError: boolean | undefined = useSelector(
    (state: IAppState) => state.showAlertOnGetDispersionFile
  );

  useEffect(() => {
    const yesterday: string = moment().subtract(1, "day").format("YYYY-MM-DD");
    const today: string = moment(new Date()).format("YYYY-MM-DD");
    const initialFilterDispersionRequest: FilterDispersionRequest = {
      from: `${yesterday}T00:00:00`,
      to: `${today}T23:59:59`,
      limit: 10,
      offset: 0,
      sort: {
        field: FilterSortEnum.CREATED,
        order: FilterSortEnum.ASC,
      },
      filter: {},
    };

    dispatch(getProcessFileDispersion(initialFilterDispersionRequest));
  }, []);

  useEffect(() => {
    if (showAlertOnGetDispersionFileError) {
      setTimeout(() => {
        dispatch(setShowAlertOnGetDispersionFile(false));
      }, 1500);
    }
  }, [showAlertOnGetDispersionFileError]);

  return {
    showAlertOnGetDispersionFileError,
  };
};
