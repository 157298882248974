import { ITabInfo } from "../../components/GenericTabs/GenericTabs.interfaces";
import { TransactionTabValueEnum } from "../enums/transactionLabelsEnum";
import { TransactionStatusEnum } from "../enums/TransactionStatusEnum";
import { TransactionTypeEnum } from "../enums/TransactionTypeEnum";
import { PaymentMethodsEnum } from "../infraestructure/payment-method";

export const catalogTabListByTransaction = (
  tabList: ITabInfo[]
): Record<string, object> => {
  const tabListWithoutTimeLineContent: ITabInfo[] = tabList.filter(
    (tab) => tab.type !== TransactionTabValueEnum.TIME_LINE
  );
  const tabListWithoutMetadataAndTimeLineContent: ITabInfo[] = tabList.filter(
    (tab) => tab.type === TransactionTabValueEnum.INFORMATION
  );

  const approval_state = {
    [TransactionTypeEnum.SALE]: {
      [PaymentMethodsEnum.CARD]: tabList,
      [PaymentMethodsEnum.CASH]: tabList,
      [PaymentMethodsEnum.TRANSFER]: tabList,
    },
    [TransactionTypeEnum.CAPTURE]: {
      [PaymentMethodsEnum.CARD]: tabListWithoutTimeLineContent,
      [PaymentMethodsEnum.CASH]: tabListWithoutTimeLineContent,
      [PaymentMethodsEnum.TRANSFER]: tabListWithoutTimeLineContent,
    },
    [TransactionTypeEnum.DEFFERRED]: {
      [PaymentMethodsEnum.CARD]: tabListWithoutTimeLineContent,
    },
    [TransactionTypeEnum.PREAUTHORIZATION]: {
      [PaymentMethodsEnum.CARD]: tabListWithoutTimeLineContent,
      [PaymentMethodsEnum.CASH]: tabListWithoutTimeLineContent,
      [PaymentMethodsEnum.TRANSFER]: tabListWithoutTimeLineContent,
    },
    [TransactionTypeEnum.VOID]: {
      [PaymentMethodsEnum.CARD]: tabList,
      [PaymentMethodsEnum.CASH]: tabListWithoutMetadataAndTimeLineContent,
      [PaymentMethodsEnum.TRANSFER]: tabListWithoutMetadataAndTimeLineContent,
    },
    [TransactionTypeEnum.VOID_PARTIAL]: {
      [PaymentMethodsEnum.CARD]: tabList,
      [PaymentMethodsEnum.CASH]: tabListWithoutMetadataAndTimeLineContent,
      [PaymentMethodsEnum.TRANSFER]: tabListWithoutMetadataAndTimeLineContent,
    },
    [TransactionTypeEnum.REFUND]: {
      [PaymentMethodsEnum.CARD]: tabList,
      [PaymentMethodsEnum.CASH]: tabListWithoutMetadataAndTimeLineContent,
      [PaymentMethodsEnum.TRANSFER]: tabListWithoutMetadataAndTimeLineContent,
    },
    [TransactionTypeEnum.REFUND_TOTAL]: {
      [PaymentMethodsEnum.CARD]: tabList,
      [PaymentMethodsEnum.CASH]: tabListWithoutMetadataAndTimeLineContent,
      [PaymentMethodsEnum.TRANSFER]: tabListWithoutMetadataAndTimeLineContent,
    },
    [TransactionTypeEnum.REFUND_PARTIAL]: {
      [PaymentMethodsEnum.CARD]: tabList,
      [PaymentMethodsEnum.CASH]: tabListWithoutMetadataAndTimeLineContent,
      [PaymentMethodsEnum.TRANSFER]: tabListWithoutMetadataAndTimeLineContent,
    },
    [TransactionTypeEnum.CHARGEBACK]: {
      [PaymentMethodsEnum.CARD]: tabListWithoutTimeLineContent,
    },
    [TransactionTypeEnum.REVERSE]: {
      [PaymentMethodsEnum.CARD]: tabListWithoutTimeLineContent,
    },
  };

  const tabsByType: Record<string, object> = {
    [TransactionStatusEnum.APPROVAL]: approval_state,
    [TransactionStatusEnum.APPROVED]: approval_state,
    [TransactionStatusEnum.INITIALIZED]: {
      [TransactionTypeEnum.SALE]: {
        [PaymentMethodsEnum.CARD]: tabListWithoutTimeLineContent,
        [PaymentMethodsEnum.CASH]: tabList,
        [PaymentMethodsEnum.TRANSFER]: tabList,
      },
      [TransactionTypeEnum.VOID]: {
        [PaymentMethodsEnum.CARD]: tabList,
        [PaymentMethodsEnum.CASH]: tabListWithoutTimeLineContent,
        [PaymentMethodsEnum.TRANSFER]: tabListWithoutMetadataAndTimeLineContent,
      },
      [TransactionTypeEnum.VOID_PARTIAL]: {
        [PaymentMethodsEnum.CARD]: tabList,
        [PaymentMethodsEnum.CASH]: tabListWithoutTimeLineContent,
        [PaymentMethodsEnum.TRANSFER]: tabListWithoutMetadataAndTimeLineContent,
      },
      [TransactionTypeEnum.REFUND]: {
        [PaymentMethodsEnum.CARD]: tabListWithoutMetadataAndTimeLineContent,
        [PaymentMethodsEnum.CASH]: tabListWithoutMetadataAndTimeLineContent,
        [PaymentMethodsEnum.TRANSFER]: tabListWithoutMetadataAndTimeLineContent,
      },
    },
    [TransactionStatusEnum.DECLINED]: {
      [TransactionTypeEnum.SALE]: {
        [PaymentMethodsEnum.CARD]: tabListWithoutTimeLineContent,
        [PaymentMethodsEnum.CASH]: tabList,
        [PaymentMethodsEnum.TRANSFER]: tabList,
      },
      [TransactionTypeEnum.VOID]: {
        [PaymentMethodsEnum.CARD]: tabList,
        [PaymentMethodsEnum.CASH]: tabListWithoutTimeLineContent,
        [PaymentMethodsEnum.TRANSFER]: tabListWithoutMetadataAndTimeLineContent,
      },
      [TransactionTypeEnum.VOID_PARTIAL]: {
        [PaymentMethodsEnum.CARD]: tabList,
        [PaymentMethodsEnum.CASH]: tabListWithoutTimeLineContent,
        [PaymentMethodsEnum.TRANSFER]: tabListWithoutMetadataAndTimeLineContent,
      },
      [TransactionTypeEnum.REFUND]: {
        [PaymentMethodsEnum.CARD]: tabList,
        [PaymentMethodsEnum.CASH]: tabList,
        [PaymentMethodsEnum.TRANSFER]: tabList,
      },
      [TransactionTypeEnum.REFUND_TOTAL]: {
        [PaymentMethodsEnum.CARD]: tabList,
        [PaymentMethodsEnum.CASH]: tabList,
        [PaymentMethodsEnum.TRANSFER]: tabList,
      },
      [TransactionTypeEnum.REFUND_PARTIAL]: {
        [PaymentMethodsEnum.CARD]: tabList,
        [PaymentMethodsEnum.CASH]: tabList,
        [PaymentMethodsEnum.TRANSFER]: tabList,
      },
      [TransactionTypeEnum.DEFFERRED]: {
        [PaymentMethodsEnum.CARD]: tabListWithoutTimeLineContent,
      },
      [TransactionTypeEnum.PREAUTHORIZATION]: {
        [PaymentMethodsEnum.CARD]: tabListWithoutTimeLineContent,
      },
      [TransactionTypeEnum.REVERSE]: {
        [PaymentMethodsEnum.CARD]: tabListWithoutTimeLineContent,
      },
    },
  };

  return tabsByType;
};
