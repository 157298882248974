import { theme } from "@kushki/connect-ui";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { ROUTES, SPA_BASENAME } from "./shared/constants/routes";
import MainContainer from "./containers/MainContainer/MainContainer";
import { Provider } from "react-redux";
import { store } from "./store/store";
import NotificationsContainer from "./containers/NotificationsContainer/NotificationsContainer";
import { SnackBarProvider } from "@kushki/connect-ui";
import { NotificationDetail } from "./containers/NotificationDetail/NotificationDetail";
import WebhooksContainer from "./containers/WebhooksContainer/WebhooksContainer";

const Root = () => {
  return (
    <React.StrictMode>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&display=swap');"
        }
      </style>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500&display=swap');"
        }
      </style>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <SnackBarProvider>
            <BrowserRouter basename={SPA_BASENAME}>
              <Routes>
                <Route path={ROUTES.INDEX} element={<MainContainer />} />
                <Route
                  path={ROUTES.NOTIFICATIONS}
                  element={<NotificationsContainer />}
                />
                <Route
                  path={ROUTES.NOTIFICATION_DETAIL}
                  element={<NotificationDetail />}
                />
                <Route path={ROUTES.WEBHOOKS} element={<WebhooksContainer />} />
              </Routes>
            </BrowserRouter>
          </SnackBarProvider>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
};

export default Root;
