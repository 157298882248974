import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Theme,
  Typography,
} from "@material-ui/core";
import kushki_logo_success from "../../assets/image/img_exito.svg";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  primaryText: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    fontWeight: "lighter",
  },
}));

export interface FileUploadSuccessComponentProps {
  handleSuccessRedirection: () => void;
}

export const FileUploadSuccessComponent: React.FC<FileUploadSuccessComponentProps> = (
  props: FileUploadSuccessComponentProps
) => {
  const classes = useStyles();
  return (
    <Card>
      <CardContent>
        <Container>
          <Grid container spacing={3} direction="row" justifyContent={"center"}>
            <Grid item xs={8}>
              <Box
                display="flex"
                flexDirection="column"
                flexGrow={1}
                alignItems="center"
                height="100%"
                justifyContent="center"
                width="100%"
              >
                <img src={kushki_logo_success} alt="kushki logo success " />
                <Typography
                  variant="h5"
                  className={classes.primaryText}
                  align="left"
                >
                  Se ha procesado tu archivo correctamente
                </Typography>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  onClick={props.handleSuccessRedirection}
                  id={"goto_retentions"}
                >
                  Ver retenciones
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </CardContent>
    </Card>
  );
};
