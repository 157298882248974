import React from "react";
import {
  BackofficeTable,
  TableHeaders,
} from "@kushki/frontend-molecules/backoffice-table";
import { Box, Grid, Paper, TableContainer } from "@material-ui/core";
import { TableEnum } from "../../../../shared/infrastructure/TableEnum";
import { MoreHorizontal } from "react-feather";
import { TransactionSummaryData } from "../../../../../types/transaction_summary_data";
import { TransactionData } from "../../../../../types/transaction_data";
import { get } from "lodash";
import NumberFormat from "react-number-format";
import { TransactionTable } from "../../../../../types/transaction_table";
import { StatusFlowEnum } from "../../../../shared/infrastructure/StatusFlowEnum";
import {
  CountryEnum,
  CountryNameEnum,
} from "../../../../shared/infrastructure/CountryEnum";
import {
  ModalOverUtils,
  trxReconcileSummaryProps,
} from "../../../../containers/DepositDashboard/state/useDepositDashboardState";
import {
  ProcessorNameEnum,
  ProcessorTypeEnum,
} from "../../../../shared/infrastructure/ProcessorNameEnum";
import {
  PaymentMethodEnum,
  PaymentTypeEnum,
} from "../../../../shared/infrastructure/PaymentMethodEnum";
import {
  FiltersProps,
  TrxReconcileProps,
} from "../../../../containers/ConciliationDashboard/state/useConciliationDashboardState";
import { CheckboxDashboard } from "../../../CheckboxDashboard/CheckboxDashboard";
import { TypeCheckBox } from "../../../../shared/infrastructure/TypeCheckBox";
import { BankReconciliation } from "../../../common/BankReconciliation/BankReconciliation";
import { enumValue } from "../../../../shared/infrastructure/staticText";
import { TransactionTableSummary } from "../../../../../types/transaction_table_summary";
import { PendingFiltersProps } from "../../../Filters/PendingFilters/PendingFilters";
import { TransactionsSummaryFilters } from "../../../Filters/TransactionsSummaryFilters/TransactionsSummaryFilters";
import { OmmitedTransactionRegisterModel } from "../../../OmittedTransactionModal/OmittedTransactionModal";
import { OriginEnum } from "../../../../shared/infrastructure/OriginEnum";
import { OrderSortEnum } from "../../../../shared/infrastructure/constants/StatusFlowDashboardConstants";
import { DateRange } from "@material-ui/pickers";
import { CurrencyEnum } from "../../../../shared/infrastructure/CurrencyEnum";
import { formatDate } from "../../../../shared/infrastructure/utils/date";
import { FormatDate } from "../../../../shared/infrastructure/constants/FormatDate";

export interface IPagination {
  page: number;
  limit: number;
  trxPerPage: number;
  siblingCount: number;
  isMobile: boolean;
  size: TableEnum;
  handleChangePage: (
    _event: React.ChangeEvent<unknown> | undefined,
    value: number,
    trxData: (TransactionData | TransactionSummaryData)[]
  ) => void;
  handleChangeLimit: (newLimit: number) => void;
  text: string;
}

export interface DashboardTableProps {
  trxReconcile?: TrxReconcileProps;
  tab?: number;
  country: CountryEnum;
  statusFlow: StatusFlowEnum;
  isLoading: boolean;
  pagination: IPagination;
  check?: {
    selectedCheck: boolean;
    noSelectedCheck: boolean;
  };
  filterProps: FiltersProps;
  orderSort: {
    order: OrderSortEnum;
    orderBy: string;
    handleRequestSort: (
      _event: React.MouseEvent<unknown>,
      property: string
    ) => void;
  };
  handleCheckboxChange?: (rowValue: TransactionData, selected: boolean) => void;
  handleCheckboxChangeAll?: (value: TransactionData[]) => void;
  modalOver: ModalOverUtils;
  pendingFiltersProps: PendingFiltersProps;
  handleDateRangeChange?: (dateRange: DateRange<Date>) => void;
  trxReconcileSummary?: trxReconcileSummaryProps;
  handleDownloadFile?: () => void;
  loadingFileDownload?: boolean;
  titleNoData?: string;
  sbtNoData?: string;
}

export interface TableStateProps {
  conciliationData: TransactionTable | TransactionTableSummary;
}

export type TableComponentProps = DashboardTableProps & TableStateProps;
export const formatNumber = (
  value: number,
  currency: string,
  statusFlow: string
): JSX.Element => {
  return (
    <NumberFormat
      value={value}
      thousandSeparator
      displayType={"text"}
      decimalScale={2}
      suffix={
        statusFlow === StatusFlowEnum.TRANSACTIONS
          ? ` ${currency} ${CurrencyEnum.MXN}`
          : ` ${currency}`
      }
    />
  );
};

export const DashboardTable = (props: TableComponentProps): JSX.Element => {
  const {
    check,
    statusFlow,
    pagination,
    handleCheckboxChange,
    handleCheckboxChangeAll,
    orderSort,
    trxReconcile,
    tab,
    handleDownloadFile,
    loadingFileDownload,
  } = props;

  const handleLimit = (newLimit: string): void => {
    pagination.handleChangeLimit(Number(newLimit));
  };
  const handlePage = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ): void => {
    pagination.handleChangePage(_event, value, props.conciliationData.data!);
  };

  const netAmountTotal = (trx: TransactionSummaryData): JSX.Element => {
    return formatNumber(
      Number(get(trx, "net_amount_total", 0)),
      get(trx, "currency_code", ""),
      StatusFlowEnum.SUMMARY
    );
  };

  const netAmount = (trx: TransactionData): JSX.Element => {
    return formatNumber(
      Number(get(trx, "net_amount", 0)),
      get(trx, "currencyCode", ""),
      StatusFlowEnum.TRANSACTIONS
    );
  };

  const currencyCode = (trx: TransactionSummaryData): JSX.Element => {
    return get(trx, "currency_code", "");
  };

  const defaultCode = (trx: TransactionData, type: string): JSX.Element => {
    return get(trx, type, "");
  };
  const defaultCodeDate = (trx: TransactionData, type: string): string => {
    return formatDate(get(trx, type, "1"), FormatDate.DMY_slash);
  };

  const mapDataConciliation = (
    trx: TransactionSummaryData | TransactionData,
    key: string
  ): JSX.Element => {
    let value: string = "";

    switch (key) {
      case "conciliation_created":
      case "bankConciliationDate":
      case "transaction_created":
        value = formatDate(get(trx, key, "1"), FormatDate.DMY_slash);
        break;
      case "processorName":
        value =
          ProcessorNameEnum[get(trx, key, ProcessorTypeEnum.KUSHKI_ACQUIRER)];
        break;
      case "paymentMethod":
        value = PaymentMethodEnum[get(trx, key, PaymentTypeEnum.CARD)];
        break;
      case "country":
        value = CountryNameEnum[get(trx, key, CountryEnum.mexico)];
        break;
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          {value}
        </Grid>
      </Grid>
    );
  };

  const processCheckAll = (trxData: TransactionData[]): JSX.Element => {
    return (
      <CheckboxDashboard
        registerAllData={trxData}
        handleCheckboxChangeAll={handleCheckboxChangeAll}
        typeCheckBox={TypeCheckBox.CHECKBOX_ALL_APPROVED}
        check={check}
      />
    );
  };

  const bankConciliationStatus = (trx: TransactionData): JSX.Element => {
    return (
      <CheckboxDashboard
        registerData={trx}
        handleCheckboxChange={handleCheckboxChange}
        typeCheckBox={TypeCheckBox.CHECK_APPROVED}
      />
    );
  };

  return (
    <Box>
      {trxReconcile?.origin === enumValue.TRANSACTIONS && tab === 0 && (
        <BankReconciliation
          actionSkip={trxReconcile.openMasiveModal}
          numberItems={
            !trxReconcile.isFinOpsBackOffice
              ? 0
              : trxReconcile.trxSelectedCheck.length
          }
          actionReconcile={trxReconcile.actionReconcile}
        />
      )}

      <TransactionsSummaryFilters
        origin={statusFlow}
        pendingFiltersProps={props.pendingFiltersProps}
        loadingFileDownload={loadingFileDownload}
        handleDownloadFile={handleDownloadFile}
      />
      <TableContainer
        component={Paper}
        style={{ marginTop: "10px", borderRadius: "8px" }}
      >
        <>
          {statusFlow === StatusFlowEnum.TRANSACTIONS && (
            <BackofficeTable
              optionsHeader={"Acciones"}
              items={props.conciliationData.data! as TransactionData[]}
              headers={
                props.filterProps
                  .filterHeaders!() as TableHeaders<TransactionData>[]
              }
              handleOnRowSelection={props.modalOver.handlerSelectTrxItem}
              skeleton={props.isLoading}
              sort={{
                order: orderSort.order,
                orderBy: orderSort.orderBy,
                handleRequestSort: orderSort.handleRequestSort,
              }}
              customHeaders={{
                bankConciliationStatus: () =>
                  processCheckAll(
                    props.conciliationData.data! as TransactionData[]
                  ),
              }}
              customRenderers={{
                bankConciliationStatus,
                netAmount,
                transactionCreated: (trx: TransactionData) =>
                  defaultCodeDate(trx, "transaction_created"),
                transactionId: (trx: TransactionData) =>
                  defaultCode(trx, "transaction_id"),
                merchantName: (trx: TransactionData) =>
                  defaultCode(trx, "merchant_name"),
                merchantId: (trx: TransactionData) =>
                  defaultCode(trx, "merchant_id"),
                approvedTransactionAmount: (trx: TransactionData) =>
                  defaultCode(trx, "approved_transaction_amount"),
              }}
              options={[
                {
                  title: "Opciones",
                  icon: <MoreHorizontal />,
                  handleOnMoreOptions: [
                    {
                      name: "Conciliar",
                      method: !props.trxReconcile?.isFinOpsBackOffice
                        ? () => {}
                        : (item) =>
                            props.trxReconcile?.actionReconcileIndividual([
                              item,
                            ]),
                      fontColor: "default",
                      disabled: !props.trxReconcile?.isFinOpsBackOffice,
                    },
                    {
                      name: "Omitir",
                      method: !props.trxReconcile?.isFinOpsBackOffice
                        ? () => {}
                        : (item) => trxReconcile?.openIndividualModal([item]),
                      fontColor: "default",
                      disabled: !props.trxReconcile?.isFinOpsBackOffice,
                    },
                  ],
                },
              ]}
              emptyState={{
                title: get(props, "titleNoData", ""),
                subtitle: get(props, "sbtNoData", ""),
              }}
              pagination={{
                ...props.pagination,
                total: props.conciliationData.total!,
                handleChangeLimit: handleLimit,
                handleChangePage: handlePage,
              }}
            />
          )}
          {statusFlow === StatusFlowEnum.SUMMARY && (
            <BackofficeTable
              optionsHeader={"Acciones"}
              items={props.conciliationData.data! as TransactionSummaryData[]}
              headers={
                props.filterProps
                  .filterHeaders!() as TableHeaders<TransactionSummaryData>[]
              }
              handleOnRowSelection={props.modalOver.handlerSelectTrxItem}
              skeleton={props.isLoading}
              sort={{
                order: orderSort.order,
                orderBy: orderSort.orderBy,
                handleRequestSort: orderSort.handleRequestSort,
              }}
              customRenderers={{
                country: (trx: TransactionSummaryData) =>
                  mapDataConciliation(trx, "country"),
                paymentMethod: (trx: TransactionSummaryData) =>
                  mapDataConciliation(trx, "paymentMethod"),
                processorName: (trx: TransactionSummaryData) =>
                  mapDataConciliation(trx, "processorName"),
                netAmountTotal,
                conciliationCreated: (trx: TransactionSummaryData) =>
                  mapDataConciliation(trx, "conciliation_created"),
                currencyCode,
              }}
              options={[
                {
                  title: "Opciones",
                  icon: <MoreHorizontal />,
                  handleOnMoreOptions: [
                    {
                      name: "Conciliar",
                      method: !props.trxReconcileSummary?.isTreasuryBackOffice
                        ? () => {}
                        : (item) =>
                            props.trxReconcileSummary?.actionReconcileIndividual(
                              [item]
                            )!,
                      fontColor: "default",
                      disabled:
                        !props.trxReconcileSummary?.isTreasuryBackOffice,
                    },
                    {
                      name: "Omitir",
                      method: !props.trxReconcileSummary?.isTreasuryBackOffice
                        ? () => {}
                        : (item) =>
                            props.trxReconcileSummary?.openIndividualModal([
                              item,
                            ])!,
                      fontColor: "default",
                      disabled:
                        !props.trxReconcileSummary?.isTreasuryBackOffice,
                    },
                  ],
                },
              ]}
              emptyState={{
                title: get(props, "titleNoData", ""),
                subtitle: get(props, "sbtNoData", ""),
              }}
              pagination={{
                ...props.pagination,
                total: props.conciliationData.total!,
                handleChangeLimit: handleLimit,
                handleChangePage: handlePage,
              }}
            />
          )}
        </>
      </TableContainer>

      {statusFlow === StatusFlowEnum.TRANSACTIONS ? (
        <OmmitedTransactionRegisterModel
          open={props.trxReconcile?.openOmittedModal!}
          handleClose={props.trxReconcile?.closeModal!}
          trxNoSelectedCheck={props.trxReconcile?.trxNoSelectedCheck!}
          trxSelectedCheck={props.trxReconcile?.trxSelectedCheck!}
          conciliationMasive={props.trxReconcile?.openOmittedMasiveModal!}
          conciliation={OriginEnum.TRANSACTIONS}
        />
      ) : (
        <OmmitedTransactionRegisterModel
          open={props.trxReconcileSummary?.openOmittedModal!}
          handleClose={props.trxReconcileSummary?.closeModal!}
          trxNoSelectedCheck={[]}
          trxSelectedCheck={[]}
          conciliationMasive={
            props.trxReconcileSummary?.openOmittedMasiveModal!
          }
          conciliation={OriginEnum.SUMMARY}
        />
      )}
    </Box>
  );
};
