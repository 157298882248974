import { FieldValues, useFormContext, useWatch } from "react-hook-form";
import {
  FieldOptionValue,
  inputFieldName,
  InputNameForm,
  inputValueName,
  OperatorOptionValue,
} from "@shared/constants/AlarmConfigConstants";
import { ISelectOption } from "@components/InputsForm/Inputs/InputSelect/InputSelect.interfaces";
import { OPTION } from "@shared/constants/selectOptions";
import {
  IDynamicInputProps,
  IUseDynamicInput,
} from "@components/DynamicInput/state/DynamicInput.interfaces";
import { useEffect, useState } from "react";
import { get, isEqual } from "lodash";
import { useAppDispatch, useAppSelector } from "@store/hooks/storeHook";
import { AppDispatch, RootState } from "@store/interfaces/store.interfaces";
import { getListCatalogs } from "@store/thunks/catalogs/catalogs.thunk";
import { ConfigCodeEnum } from "@shared/enum/ConfigCodeEnum";
import { CountryCode } from "@shared/enum/CountryCodeEnum";
import { ProductLineEnum } from "@shared/enum/ProductLineEnum";
import { TypeCatalog } from "@shared/enum/TypeCatalog";
import { getCatalogAsSelectOptions } from "@shared/utils/listCatalogUtils";

export const useDynamicInput = ({
  handleBehaviorSections,
  disableFieldOptions,
  index,
}: IDynamicInputProps): IUseDynamicInput => {
  const fieldInput = useWatch({ name: inputFieldName(index) });
  const dispatch: AppDispatch = useAppDispatch();
  const { catalogs }: RootState = useAppSelector((state: RootState) => state);

  const renderOptions = (value: FieldOptionValue): ISelectOption[] => {
    if (
      [
        FieldOptionValue.PEP,
        FieldOptionValue.MODEL,
        FieldOptionValue.MCC,
        FieldOptionValue.CONSTITUTIONAL_COUNTRY,
        FieldOptionValue.CATEGORY,
        FieldOptionValue.CONSTITUTION_DATE,
      ].includes(value)
    ) {
      return OPTION.operator.filter((opt) =>
        [OperatorOptionValue.EQUAL, OperatorOptionValue.DIFFERENT].includes(
          opt.value as OperatorOptionValue
        )
      );
    } else if ([FieldOptionValue.STANDARD_DEVIATION].includes(value)) {
      return OPTION.operator.filter((opt) =>
        [OperatorOptionValue.EQUAL].includes(opt.value as OperatorOptionValue)
      );
    } else {
      return OPTION.operator;
    }
  };

  const variables = useWatch({ name: `${InputNameForm.VARIABLES}.${index}` });
  const { setValue } = useFormContext<FieldValues>();
  const [lastFieldVal, setLastFieldVal] = useState<string>(
    get(variables, "field", "")
  );

  useEffect(() => {
    setLastFieldVal(get(variables, "field", ""));
    disableFieldOptions();
    handleBehaviorSections(variables, index);
  }, [variables]);

  useEffect(() => {
    if (isEqual(lastFieldVal, get(variables, "field", ""))) return;

    setValue(inputValueName(index), "");
  }, [fieldInput]);

  useEffect(() => {
    if (catalogs.listMccCatalog.length === 0 && !catalogs.isLoading) {
      dispatch(
        getListCatalogs({
          configCode: ConfigCodeEnum.BASIC_DATA,
          countryCode: CountryCode.GLB,
          productLine: ProductLineEnum.PLA,
          typeCatalog: TypeCatalog.MCC,
        })
      );
    }
  });

  const mccCatalog = () => {
    return getCatalogAsSelectOptions(catalogs.listMccCatalog);
  };

  return {
    fieldInput,
    mccCatalog,
    renderOptions,
  };
};
