import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { get, isEmpty } from "lodash";
import {
  renderRetentionInfoPaper,
  validateItem,
} from "../ModalBodyInfo/ModalBodyRetentionColombia";
import { InvoiceRecord } from "../../../types/remote/response_search_blling_by_filters";
import { useDispatch } from "react-redux";
import { saveInconsistenceModify } from "../../store/actionCreators";
import { useStyles } from "../../shared/infrastructure/ModalInconsistenceStyles";
import {
  ChipStatusEnum,
  StatusEnum,
} from "../../shared/infrastructure/StatusEnum";
import { TransactionFieldsEnum } from "../../shared/infrastructure/TransactionFieldsEnum";
import { LabelEnum } from "../../shared/infrastructure/LabelEnum";
import { StatusFlowEnum } from "../../shared/infrastructure/StatusFlowEnum";

export interface InfoTrxPropsModify {
  trxInfo: InvoiceRecord | undefined;
  open?: boolean;
  handleModalDialogDetail?: () => void;
  contentFooter?: string;
  isdetalleModify?: boolean;
}

export interface trxAmmounts {
  amountRetIca: number;
  amountRetIva: number;
  amountRetSource: number;
  baseAmountIca: number;
  baseAmountIva: number;
  baseAmountSource: number;
}

export const ModalDialogInconsistences: React.FC<InfoTrxPropsModify> = (
  props: InfoTrxPropsModify
) => {
  const styles = useStyles();
  const isModify = !isEmpty(get(props.trxInfo, "modify", {}));
  const isRejected = get(props.trxInfo, "status", "") === StatusEnum.REJECTED;
  const isExecutor =
    get(props.trxInfo, "statusFlow", "") === StatusFlowEnum.EXECUTOR;
  const dispatch = useDispatch();
  const [trxNewData, setTrxNewData] = useState({
    amountRetIca: get(props, "trxInfo.amountRetIcaDavi"),
    amountRetIva: get(props, "trxInfo.amountRetIvaDavi"),
    amountRetSource: get(props, "trxInfo.amountRetSourceDavi"),
    baseAmountIca: get(props, "trxInfo.baseAmountIcaDavi"),
    baseAmountIva: get(props, "trxInfo.baseAmountIvaDavi"),
    baseAmountSource: get(props, "trxInfo.baseAmountSourceDavi"),
  });
  const trxSelectedInfo = get(props, "trxInfo", {});

  const handleInputChange = (value: string, field: string) => {
    let objTrxAux: trxAmmounts = {
      ...trxNewData,
      [field]: Number(value),
    };
    if (field === TransactionFieldsEnum.baseAmountIvaDavi)
      objTrxAux = {
        ...objTrxAux,
        baseAmountIva: Number(value),
      };
    if (field === TransactionFieldsEnum.baseAmountIcaDavi)
      objTrxAux = {
        ...objTrxAux,
        baseAmountIca: Number(value),
        baseAmountSource: Number(value),
      };
    setTrxNewData(objTrxAux);
  };

  const handleModifyTransactions = () => {
    const trxModifiedData: InvoiceRecord = {
      ...props.trxInfo,
      modify: {
        modifyAmount: trxNewData,
      },
    };
    dispatch(saveInconsistenceModify(trxModifiedData));
  };

  const classes = useStyles();

  const renderContentDialog = (props: InfoTrxPropsModify) => {
    const renderTextFieldAmount = (
      trxInfo: InvoiceRecord,
      labelText: string,
      field: string,
      isDefaultDisabled: boolean
    ) => {
      return (
        <TextField
          required
          fullWidth
          label={labelText}
          disabled={
            !isDefaultDisabled
              ? !validateItem(trxInfo, ChipStatusEnum.INCONSISTENCY, field)
              : true
          }
          className={classes.input}
          variant="outlined"
          defaultValue={get(trxInfo, field, 0)}
          onChange={(event: any) =>
            handleInputChange(event.target.value, field)
          }
        />
      );
    };

    return (
      <Paper className={classes.paper}>
        <Grid container className={classes.grid}>
          <Grid item xs={10}>
            {isModify ? (
              <Typography variant="h5" color="primary">
                Monto modificado por {get(props, "trxInfo.backofficeUser", "")}
              </Typography>
            ) : (
              <Typography
                variant="body2"
                color="textPrimary"
                className={styles.titleDialog}
              >
                ¿Deseas modificar los montos de retenciones?
              </Typography>
            )}
          </Grid>
          <Grid item xs={2}>
            <IconButton
              onClick={props.handleModalDialogDetail}
              className={classes.iconButtonClose}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          {!isModify || (isModify && isRejected && isExecutor) ? (
            <Typography
              variant="body2"
              color="textPrimary"
              className={styles.textDialog}
            >
              Estas a punto de modificar los montos iniciales de esta operación.
              Al modificarlos automáticamente se alterará el monto total de
              retención del comercio {get(props, "trxInfo.merchantName", "")}
            </Typography>
          ) : (
            <div>
              <Typography
                variant="h4"
                color="inherit"
                className={styles.textDialog}
              >
                Fecha de modificación:
              </Typography>
              <Typography
                variant="h4"
                color="inherit"
                className={styles.textDialog}
              >
                {get(props, "trxInfo.calculateCycle", "")}
              </Typography>
              <Typography variant="h6" color="inherit">
                Montos:
              </Typography>
            </div>
          )}
          {(!isModify || (isModify && isRejected && isExecutor)) && (
            <Typography
              variant="body2"
              color="textPrimary"
              className={styles.titleDialog}
            >
              Montos iniciales:
            </Typography>
          )}
          <Grid container>
            {renderRetentionInfoPaper(props, true, props.isdetalleModify)}
          </Grid>
          {(!isModify || (isModify && isRejected && isExecutor)) && (
            <div>
              <Typography
                variant="body2"
                color="textPrimary"
                className={styles.titleDialog}
              >
                Modificación de montos:
              </Typography>
              <Typography
                variant="body2"
                color="textPrimary"
                className={styles.textDialog}
              >
                Los montos correctos se muestran automáticamente con base al
                cálculo de Davivienda
              </Typography>
            </div>
          )}
          {(!isModify || (isModify && isRejected && isExecutor)) && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    {renderTextFieldAmount(
                      trxSelectedInfo,
                      LabelEnum.AMOUNT_RETENTION_IVA,
                      TransactionFieldsEnum.amountRetIvaDavi,
                      false
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    {renderTextFieldAmount(
                      trxSelectedInfo,
                      LabelEnum.GRAVABLE_BASE_IVA,
                      TransactionFieldsEnum.baseAmountIvaDavi,
                      false
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    {renderTextFieldAmount(
                      trxSelectedInfo,
                      LabelEnum.AMOUNT_RETENTION_ICA,
                      TransactionFieldsEnum.amountRetIcaDavi,
                      false
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    {renderTextFieldAmount(
                      trxSelectedInfo,
                      LabelEnum.AMOUNT_SOURCE_BASE,
                      TransactionFieldsEnum.baseAmountIcaDavi,
                      false
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={6}>
                  {renderTextFieldAmount(
                    trxSelectedInfo,
                    LabelEnum.AMOUNT_RETENTION_SOURCE,
                    TransactionFieldsEnum.amountRetSourceDavi,
                    false
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
          {!isModify || (isModify && isRejected && isExecutor) ? (
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                className={classes.subtitletextDialog}
              >
                {props.contentFooter}
              </Typography>
            </Grid>
          ) : (
            <DialogActions style={{ padding: "18px 15px" }}>
              <Button
                onClick={props.handleModalDialogDetail}
                className={styles.buttonEntendido}
              >
                {"Entendido"}
              </Button>
            </DialogActions>
          )}
        </Grid>
      </Paper>
    );
  };

  return (
    <React.Fragment>
      <Dialog
        open={props.open!}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"md"}
      >
        <div className={styles.dialog}>
          <DialogContent className={classes.dialogContent}>
            {renderContentDialog(props)}
          </DialogContent>
          {(!isModify || (isModify && isRejected && isExecutor)) && (
            <DialogActions className={classes.dialogContent}>
              <Button
                onClick={props.handleModalDialogDetail}
                className={styles.buttonClose}
              >
                {"Cancelar"}
              </Button>
              <Button
                onClick={handleModifyTransactions}
                className={styles.buttonAccept}
              >
                {"Modificar monto"}
              </Button>
            </DialogActions>
          )}
        </div>
      </Dialog>
    </React.Fragment>
  );
};
