import { format, utcToZonedTime } from "date-fns-tz";
import { ICatalog, TimeZoneCatalog } from "../infrastructure/time-zone-catalog";
import { get } from "lodash";

export const DEFAULT_DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
export const DEFAULT_DATE_FORMAT = "dd-MM-yyyy";
export const DEFAULT_TIME_FORMAT = "kk:mm:ss";

export const timeZoneHour = (
  text: string | undefined,
  value: string
): string => {
  const _timeZoneDefault: string = "America/Guayaquil";
  let index: number;

  if (text === undefined) return value === "utc" ? "-05:00" : _timeZoneDefault;

  index = TimeZoneCatalog.findIndex(
    (data: ICatalog) => data.description === text
  );

  if (index >= 0) {
    const lang: string | undefined = get(
      TimeZoneCatalog[index],
      value,
      undefined
    );

    return lang !== undefined
      ? lang
      : value === "utc"
      ? "-05:00"
      : _timeZoneDefault;
  }

  return value === "utc" ? "-05:00" : _timeZoneDefault;
};

export const formatDate = (
  date: string | number,
  formatDate?: string
): string => {
  if (date) {
    const timeZoneRegion = timeZoneHour(
      localStorage.getItem("timeZone")!,
      "region"
    );
    const finalDate =
      typeof date === "number" && date.toString().length === 10
        ? date * 1000
        : date;

    return format(
      utcToZonedTime(finalDate, timeZoneRegion),
      formatDate || DEFAULT_DATE_TIME_FORMAT
    );
  } else return DEFAULT_DATE_TIME_FORMAT;
};
