import { IconSquareCheck } from "@kushki/connect-ui";
import { Grid } from "@mui/material";
import * as React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { FC } from "react";
import { get } from "lodash";
import { IModalConfirmProps } from "./ModalConfirm.interfaces";
import { ModalConfirmStyles } from "./ModalConfirm.styles";

const ModalConfirmItem: FC<IModalConfirmProps> = ({
  onClickPrimary,
  onClickSecondary,
  buttonPrimary,
  buttonSecondary,
  buttonPrimaryDisabled,
  buttonSecondaryDisabled,
  descriptionText,
  isOpen,
  onClose,
  titleText,
  contentModal,
}: IModalConfirmProps) => {
  return (
    <>
      <div>
        <Modal
          open={isOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid sx={ModalConfirmStyles.modalContainer}>
            <Box className="modalHeader">
              <Grid container className="itemsTop">
                <Grid item xs={11} md={11}>
                  <Box className="headerIconsContainer">
                    <Typography
                      id="modal-modal-title"
                      variant="h3"
                      color="primary"
                      className="titleText"
                    >
                      {titleText}{" "}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={1} md={1}>
                  <IconButton
                    color="secondary"
                    onClick={onClose}
                    size="small"
                    className="closeModalButton"
                    data-testid={"iconButton"}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid className="itemsBottom">
                <Typography
                  id="modal-modal-description"
                  variant="body2"
                  color="text.dark"
                >
                  {descriptionText}
                </Typography>
              </Grid>
              {get(contentModal, "hasContent") ? (
                <>
                  <Grid className="itemsBottom">
                    <Typography
                      id="modal-modal-description"
                      variant="body2"
                      color="text.dark"
                    >
                      {contentModal!.subtitle}
                    </Typography>
                  </Grid>
                  {contentModal!.options.map(
                    (option: { name: string; value: number }) => (
                      <Grid
                        key={option.name}
                        container
                        item
                        xs={12}
                        md={12}
                        className="itemsBottom"
                      >
                        <Grid item xs={1}>
                          <IconSquareCheck />
                        </Grid>
                        <Grid item xs={3}>
                          <Typography color="text.primary" variant="h6">
                            {option.name}: {option.value}
                          </Typography>
                        </Grid>
                      </Grid>
                    )
                  )}
                </>
              ) : (
                <></>
              )}
            </Box>
            <Box className="modalFooter">
              <Grid container className="itemsButtons">
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  disabled={buttonSecondaryDisabled}
                  onClick={onClickSecondary}
                  sx={{ mr: 2 }}
                >
                  {buttonSecondary}
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={buttonPrimaryDisabled}
                  onClick={onClickPrimary}
                >
                  {buttonPrimary}
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Modal>
      </div>
    </>
  );
};

const ModalConfirm: FC<IModalConfirmProps> = (props: IModalConfirmProps) => {
  return <ModalConfirmItem {...props} />;
};

export { ModalConfirm };
