import { environment } from "../../environments/environment";
import { get } from "lodash";

export * from "../interfaces/create_named_styles";

export const emptyFunction = () => {};

export const isCustomerRol = (): boolean => {
  const getRole: string = localStorage.getItem("roles")!;
  const environmentRoles: string[] = environment.customerRoles.split(",");

  for (let step = 0; step <= environmentRoles.length; step++) {
    const foundRole: boolean = get(
      JSON.parse(getRole),
      environmentRoles[step],
      false
    );

    if (foundRole) return true;
  }

  return false;
};
