import React from "react";
import { Box, Container, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import basicInfo from "../../../assets/images/basicInfo.svg";
import { AccordionContainer } from "../../AccordionContainer/AccordionContainer";

export interface IMainSkeleton {
  count: number;
}

export const MainSkeleton: React.FC<IMainSkeleton> = (props: IMainSkeleton) => {
  var rows = [];
  for (var i = 0; i <= props.count; i++) {
    rows.push(
      <AccordionContainer
        childrenArrayComponent={[
          <Skeleton
            animation="wave"
            variant="rect"
            width={"100%"}
            height={48}
            key={0}
          />,
        ]}
        icon={basicInfo}
        title={""}
      />
    );
  }
  return (
    <React.Fragment>
      <Container fixed>
        <Box style={{ padding: "20px", paddingTop: "0px" }}>
          <Grid container xs={12} md={6} spacing={3}>
            <Grid item xs={2}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={"100%"}
                height={48}
              />
            </Grid>
            <Grid item xs={10}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={"50%"}
                height={48}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={3}>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <Box display={"flex"} alignItems={"center"}>
                  <Box flexGrow={1}>
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      width={"25%"}
                      height={28}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={"25%"}
                  height={28}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {rows}
      </Container>
    </React.Fragment>
  );
};
