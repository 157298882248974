export enum RequiredRolEnum {
  HOME = "Console.Home.Read",
  FRAUD_PREVENTION = "Console.FraudPrevention.Read",
  MERCHANTS = "Console.Merchants.Read",
  COMPLIANCE_MERCHANTS = "Console.ComplianceMerchants.Read",
  TRANSACTIONS = "Console.Transaction.Read",
  TRANSACTIONS_TRANSACTIONS = "Console.Transaction.Read",
  TRANSACTIONS_PAYOUTS_TRANSACTION = "Console.Transaction.Read",
  TRANSACTIONS_CHARGEBACK = "Console.Transaction.Read",
  MY_MERCHANTS = "Console.MyMerchants.Read",
  MONITORING = "Console.Monitoring.Read",
  USERS = "Console.Users.Read",
  ALLIES = "Console.Allies.Read",
  SUBSCRIPTIONS = "Console.Subscriptions.Read",
  SMARTLINKS = "Console.Smartlinks.Read",
  BILLING_DASHBOARD_EXECUTOR = "Console.Billing.Read",
  BILLING_DASHBOARD_EXECUTOR_EXECUTOR = "Console.Billing.Read",
  BILLING_DASHBOARD_VALIDATOR = "Console.Billing.Read",
  BILLING_DASHBOARD_VALIDATOR_VALIDATOR = "Console.Billing.Read",
  CONCILIATION_VALIDATOR = "Console.Conciliation.Read",
  CONCILIATION_VALIDATOR_CONCILIATIONVALIDATOR = "Console.Conciliation.Read",
  CONCILIATION_EXECUTOR = "Console.Conciliation.Read",
  CONCILIATION_EXECUTOR_CONCILIATIONEXECUTOR = "Console.Conciliation.Read",
  SNR = "Console.SNR.Read",
  VEHISCORE = "Console.Vehiscore.Read",
  REFUNDS_CONTROL = "Console.RefundsControl.Read",
  MGUAYAQUIL = "Console.MGuayaquil.Read",
  CONCILIATION_DASHBOARD = "Console.Reports.Read",
  RETRY_RULES = "Console.RetryRules.Read",
  GLOBAL_CONFIG = "Console.GlobalConfig.Read",
  MERCHANT_CONFIG = "Console.MerchantConfig.Read",
  DEVELOPER = "Console.Developer.Read",
  COMPLIANCE = "Console.Compliance.Read",
  SUPPORT = "Console.Support.Read",
  CONCILIATION_TRANSACTIONAL = "Console.ConciliationTransactional.Read",
  CONCILIATION_TRANSACTIONAL_TRANSACTIONAL_CONCILIATION = "Console.ConciliationTransactional.Read",
  BANK_CONCILIATION = "Console.BackConciliation.Read",
  BANK_CONCILIATION_BANK_CONCILIATION_SUMMARY = "Console.BackConciliation.Read",
  BANK_CONCILIATION_BANK_CONCILIATION_SUMMARY_BANK_CONCILIATION_SUMMARY = "Console.BackConciliation.Read",
  BANK_CONCILIATION_BANK_CONCILIATION_SUMMARY_BANK_CONCILIATION_TRANSACTIONS = "Console.BackConciliation.Read",
  ROLES_CONTROL = "Console.RolesControl.Read",
  CLIENTS = "Console.Clients.Read",
  REFUNDS = "Console.Refunds.Read",
  EXPRESS_CHECKOUT = "Console.ExpressCheckout.Read",
  M_MERCHANT_MIGRATION = "Console.MerchantMigration.Read",
  DASHBOARD_METRICS = "Console.Metrics.Read",
  DASHBOARD_METRICS_PRODUCTS = "Console.MetricsProducts.Read",
}
