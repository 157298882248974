import React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  labelTextTitleCard: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    fontWeight: "lighter",
  },
  labelTextDescriptionCard: {
    color: "#6D7781",
  },
  labelTextTitleStep: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  contentWidth: {
    maxWidth: "fit-content",
  },
  cardStyle: {
    backgroundColor: "#F7FAFC",
    border: "none",
    marginBottom: theme.spacing(2),
  },
  labelTitleInitial: {
    color: "#023365",
    Weight: 500,
    Size: "16px",
    lineHeight: "24px",
    marginBottom: theme.spacing(2),
  },
}));

export interface FileUploadSubCardComponentProps {
  imgSource: string;
  titleCard: string;
  subtitleCard: string | React.ReactNode;
  reactBtn?: React.ReactNode;
  labelTitleInitial?: boolean;
}

export const FileUploadSubCardComponent: React.FC<FileUploadSubCardComponentProps> = (
  props: FileUploadSubCardComponentProps
) => {
  const classes = useStyles();
  return (
    <Card variant="outlined" className={classes.cardStyle}>
      <CardContent>
        <Grid container spacing={3} direction="row">
          <Grid item xs={2}>
            <Box
              display="flex"
              flexGrow={1}
              flexDirection="row"
              justifyContent={"center"}
            >
              <img src={props.imgSource} alt="csvImage" />
            </Box>
          </Grid>
          <Grid item xs={10}>
            <Box
              display="inline-flex"
              flexGrow={1}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              <Typography
                className={classes.labelTextTitleCard}
                variant="h5"
                align="left"
                color="primary"
              >
                {props.titleCard}
              </Typography>
              {props.labelTitleInitial && (
                <Typography
                  className={classes.labelTitleInitial}
                  variant="h6"
                  align="left"
                  color="inherit"
                >
                  Plantilla inicial
                </Typography>
              )}

              <Typography
                className={classes.labelTextDescriptionCard}
                variant="h6"
                align="left"
                color="inherit"
              >
                {props.subtitleCard}
              </Typography>
              {props.reactBtn}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
