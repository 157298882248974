import React, { FC } from "react";
import { mainSubHeaderStyles as styles } from "./Subheader.styles";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { getCountryFlag } from "../../shared/constants/flags/countryFlag";
import { ISubheaderProps } from "./Subheader.interfaces";
import { IconChevronLeft, IconEdit, IconEye } from "@kushki/connect-ui";
import { redirectUtil } from "../../shared/utils/redirectUtil";
import { SecurityLabelsEnum } from "../../shared/enums/SecurityLabelsEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { clipboard } from "../../shared/clipboard";
import { Copy } from "react-feather";
import { LabelEnum } from "../../shared/enums/LabelEnum";
import { isMassivePath } from "../../shared/utils/massiveConfigUtils";

export const Subheader: FC<ISubheaderProps> = ({
  title,
  country,
  redirectPath,
  id,
  subTitle,
  totalBranches,
  branchesTitle,
}: ISubheaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const routeLocalStorage = { layout: { lastRoute: location.pathname } };

  const handleRedirectButton = () => {
    localStorage.setItem("basicInformation", JSON.stringify(routeLocalStorage));
    redirectUtil(redirectPath);
  };

  return (
    <Box>
      {isMassivePath() ? (
        <Box>
          <Grid container alignItems="center" sx={styles.gridTitle}>
            <Grid item>
              <Button
                onClick={() => {
                  navigate(-1);
                }}
                startIcon={<IconChevronLeft />}
                className={"buttonLink"}
                color="primary"
              >
                {LabelEnum.BACK}
              </Button>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={styles.gridTitle}>
            <Grid item>
              <Typography color="text.primary" variant="h5">
                {totalBranches} {LabelEnum.HEADER_MASSIVE_TITLE}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} sx={styles.containerForm}>
            <Grid item xs={6}>
              <Typography color="text.primary" variant="h3">
                {branchesTitle}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <Button
                onClick={() => {}}
                startIcon={<IconEye />}
                className={"buttonLink"}
                color="primary"
              >
                {LabelEnum.SEE_MORE}
              </Button>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box>
          <Grid container alignItems="center" sx={styles.gridTitle}>
            <Grid item>
              <Typography color="text.primary" variant="h3">
                {title}
              </Typography>
            </Grid>
            <Grid item>
              <ComponentSecurityWrapper
                componentId={SecurityLabelsEnum.SERVICE_EDIT_CUSTOMER}
              >
                <IconButton onClick={handleRedirectButton}>
                  <IconEdit color="primary" fontSize="medium" />
                </IconButton>
              </ComponentSecurityWrapper>
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item container xs={12} md={"auto"} alignItems="center">
              <Grid item sx={styles.gridText}>
                <Typography variant="caption" color="text.primary">
                  {LabelEnum.OPERATION_COUNTRY}
                </Typography>
              </Grid>
              <Grid item>
                <Box>{getCountryFlag[country]}</Box>
              </Grid>
              <Grid item sx={styles.gridCountry}>
                <Typography variant="body1" color="text.primary">
                  {subTitle}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={"auto"} alignItems="center">
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={styles.divider}
              />
              <Grid item sx={styles.gridId}>
                <Typography variant="caption" color="text.primary">
                  {LabelEnum.ID}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" color="text.primary">
                  {id}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  sx={styles.iconEffect}
                  data-testid={"IconCopy"}
                  onClick={(e) => {
                    clipboard(id);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <Copy />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default Subheader;
