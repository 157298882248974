import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  MerchantSettingsResponse,
  ProcessorSettings,
} from "../../../shared/infrastructure/interfaces/IMerchantSettingsResponse";
import { IAppState } from "../../../store/reducer";
import { useFormContext, useWatch } from "react-hook-form";
import {
  getMerchantSettings,
  getSmartlinkMerchant,
  getSubscriptionMerchant,
  getVposMerchant,
} from "../../../store/actionCreators";
import {
  OTHERS,
  PAYMENT_METHODS,
  PRODUCTS,
  ServiceOptionEnum,
} from "../../../shared/infrastructure/constants/OptionsEnum";
import { defaultTo, get } from "lodash";
import { TWebhookForm } from "../../../containers/WebhookFormIndex/state/useWebhookForm";
import { WebhookEvent } from "../../../shared/infrastructure/interfaces/IWebhookForm";

type ICheckboxItem = {
  label: string;
  value: string;
};

interface IUsePaymentMethodCheckList {
  payinOptions: ICheckboxItem[];
  payoutOptions: ICheckboxItem[];
  productOptions: ICheckboxItem[];
  otherOptions: ICheckboxItem[];
  selectedOptions: string[];
  areAllOptionsSelected: boolean;
  handler: {
    handleChange: (value: string) => void;
    handleSelectAll: () => void;
  };
}

const getCheckBoxItem = (
  value: ServiceOptionEnum,
  labelList: Record<string, string>
): ICheckboxItem => {
  return {
    label: labelList[value],
    value: value,
  };
};

const getServiceOptions = (
  processors: ProcessorSettings,
  services: ServiceOptionEnum[],
  labelList: Record<string, string>
): ICheckboxItem[] => {
  const availableServices = services.filter(
    (service: string) => get(processors, service, []).length > 0
  );
  return availableServices.map((service: ServiceOptionEnum) =>
    getCheckBoxItem(service, labelList)
  );
};

const usePaymentMethodCheckList = (): IUsePaymentMethodCheckList => {
  const params = useParams<{ merchantId: string }>();
  const dispatch = useDispatch();
  const { getValues, setValue } = useFormContext<TWebhookForm>();
  const merchantId = params.merchantId;

  const options = useWatch({ name: "options" }) as string[];

  const merchantSettings: MerchantSettingsResponse = useSelector(
    (state: IAppState) => state.merchantSettings!
  );
  const isVposActive: boolean = useSelector(
    (state: IAppState) => state.isVposActive!
  );

  const isSmartlinkActive: boolean = useSelector(
    (state: IAppState) => state.isSmartlinkActive!
  );

  const isSubscriptionActive: boolean = useSelector(
    (state: IAppState) => state.isSubscriptionActive!
  );

  const [productOptions, setProductOptions] = useState<ICheckboxItem[]>([]);
  const [payinOptions, setPayinOptions] = useState<ICheckboxItem[]>([]);
  const [payoutOptions, setPayoutOptions] = useState<ICheckboxItem[]>([]);
  const [additionalServiceOptions, setAdditionalServiceOptions] = useState<
    ICheckboxItem[]
  >([]);
  const [merchantOptions, setMerchantOptions] = useState<string[]>([]);
  const [areAllOptionsSelected, setAreAllOptionsSelected] =
    useState<boolean>(false);

  const addOrRemovePaymentOption = (value: string): void => {
    const { options: currentOptions, events: currentEvents } = getValues();
    const isExistingValue: boolean = currentOptions.indexOf(value) > -1;

    if (isExistingValue) {
      const updatedEvents: WebhookEvent = currentEvents.filter(
        (e: { service?: string; status?: string }) => e.service !== value
      );
      setValue("events", updatedEvents);
      setValue(
        "options",
        currentOptions.filter((opt) => opt !== value)
      );
    } else {
      setValue("options", [...currentOptions, value]);
    }
  };

  useEffect(() => {
    dispatch(getMerchantSettings(merchantId));
    dispatch(getVposMerchant(merchantId));
    dispatch(getSmartlinkMerchant(merchantId));
    dispatch(getSubscriptionMerchant(merchantId));
  }, []);

  useEffect(() => {
    setAvaibleProducts(
      isSubscriptionActive,
      isVposActive,
      isSubscriptionActive
    );
  }, [isSmartlinkActive, isVposActive, isSubscriptionActive]);

  useEffect(() => {
    setAvailableServices(merchantSettings);
  }, [merchantSettings]);

  useEffect(() => {
    const optionsCount: number =
      payinOptions.length +
      payoutOptions.length +
      productOptions.length +
      additionalServiceOptions.length;
    setAreAllOptionsSelected(
      optionsCount === options.length && optionsCount > 0
    );
  }, [options]);

  const setAvaibleProducts = (
    smartlinksActive: boolean,
    vposActive: boolean,
    subscritionsActive: boolean
  ) => {
    const product: ICheckboxItem[] = [];
    if (smartlinksActive)
      product.push(getCheckBoxItem(ServiceOptionEnum.WEBCHECKOUT, PRODUCTS));
    if (vposActive)
      product.push(getCheckBoxItem(ServiceOptionEnum.VPOS, PRODUCTS));
    if (subscritionsActive)
      product.push(getCheckBoxItem(ServiceOptionEnum.SUBSCRIPTIONS, PRODUCTS));
    setProductOptions(product);
  };

  const setAvailableServices = (settings: MerchantSettingsResponse) => {
    const processors = defaultTo(settings.processors, {});
    const payinOpt: ICheckboxItem[] = getServiceOptions(
      processors,
      [
        ServiceOptionEnum.CARD,
        ServiceOptionEnum.CASH,
        ServiceOptionEnum.TRANSFER,
        ServiceOptionEnum.TRANSFER_SUBSCRIPTIONS,
      ],
      PAYMENT_METHODS
    );
    const payoutOpt: ICheckboxItem[] = getServiceOptions(
      processors,
      [ServiceOptionEnum.PAYOUTS_CASH, ServiceOptionEnum.PAYOUTS_TRANSFER],
      PAYMENT_METHODS
    );
    const additionalOpt: ICheckboxItem[] = [];

    if (
      payinOpt.find(
        ({ value }) => value === "card" || value === "subscriptions"
      )
    ) {
      additionalOpt.push(getCheckBoxItem(ServiceOptionEnum.CHARGEBACK, OTHERS));
      additionalOpt.push(
        getCheckBoxItem(ServiceOptionEnum.CHARGEBACK_VOID, OTHERS)
      );
    }

    const merchantOpt: string[] = [
      ...payinOpt,
      ...payoutOpt,
      ...additionalOpt,
    ].map(({ value }) => value as string);

    setPayinOptions(payinOpt);
    setPayoutOptions(payoutOpt);
    setAdditionalServiceOptions(additionalOpt);
    setMerchantOptions(merchantOpt);
  };

  const handleChange = (value: string): void => {
    if (value === ServiceOptionEnum.CHARGEBACK)
      addOrRemovePaymentOption(ServiceOptionEnum.CHARGEBACK_VOID);
    addOrRemovePaymentOption(value);
  };

  const handleSelectAll = (): void => {
    const { options: currentOptions } = getValues();
    const optionsCount: number =
      payinOptions.length +
      payoutOptions.length +
      productOptions.length +
      additionalServiceOptions.length;
    const areAllSelected: boolean = optionsCount === currentOptions.length;
    if (areAllSelected) {
      setValue("options", []);
      setValue("events", []);
    } else {
      const productValues: string[] = productOptions.map(
        ({ value }) => value as string
      );
      setValue("options", [...merchantOptions, ...productValues]);
    }
  };

  return {
    payinOptions: payinOptions,
    payoutOptions: payoutOptions,
    productOptions,
    otherOptions: additionalServiceOptions,
    areAllOptionsSelected,
    selectedOptions: options,
    handler: {
      handleChange,
      handleSelectAll,
    },
  };
};

export { usePaymentMethodCheckList };
export type { ICheckboxItem };
