import { environment } from "../environments/environment";

export const API_ROUTES = {
  GET_MERCHANT_PREFERENCES: (publicMerchantId: string) =>
    `${environment.kushkiUrl}/merchant/v1/merchant-node/preferences/${publicMerchantId}`,
  GET_NODE_INFO: `${environment.kushkiUrl}/billing-core-node/v1/node/info`,
  GET_SEMAPHORE: (publicMerchantId: string) =>
    `${environment.kushkiUrl}/billing-core/v1/getSemaphore/${publicMerchantId}`,
  MERCHANT_HIERARCHY_CONFIG: `${environment.kushkiUrl}/hierarchy/v1/merchant/v1/node/config`,
  MERCHANT_SEMAPHORE: `${environment.kushkiUrl}/billing-core-node/v1/merchant-node/semaphore`,
  PATCH_PREFERENCES: (merchantId: string) =>
    `${environment.kushkiUrl}/merchant/v1/merchant-node/preferences/${merchantId}`,
};
