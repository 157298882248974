import { CountryEnum } from "./CountryEnum";
import { KindEnum } from "./KindEnum";

export const DocumentTypeEnum: Record<string, string[]> = {
  [CountryEnum.ecuador]: [KindEnum.INVOICE],
  [CountryEnum.chile]: [KindEnum.INVOICE],
  [CountryEnum.colombia]: [
    KindEnum.INVOICE,
    KindEnum.CREDIT_NOTE,
    KindEnum.DEBIT_NOTE,
  ],
  [CountryEnum.peru]: [
    KindEnum.INVOICE,
    KindEnum.CREDIT_NOTE,
    KindEnum.DEBIT_NOTE,
    KindEnum.VOUCHER,
  ],
  [CountryEnum.mexico]: [
    KindEnum.INVOICE,
    KindEnum.CREDIT_NOTE,
    KindEnum.DEBIT_NOTE,
  ],
};
