import { createNamedStyles } from "../../../shared/infrastructure/interfaces/create_named_styles";

export const useLastModifyStyles = createNamedStyles({
  containerLastModify: {
    display: "flex",
    paddingTop: "15px",
    marginRight: "50px",
  },
  modifyBy: {
    diplay: "flex",
    marginRight: "30px",
  },
  gridText: {
    display: "flex",
  },
  text: {
    fontSize: "14px !important",
    color: "#6D7781",
  },
});
