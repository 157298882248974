import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

const CELL_SIZE_HEADER = {
  0: { width: "310px !important" },
  1: { width: "268px !important" },
  2: { width: "283px !important" },
};

const SKELETON_WIDTH_BODY = {
  0: "50%",
  1: "100%",
  2: "90px",
};
export const tableSkeletonStyles = (columns: number, position: number) =>
  createNamedStyles({
    cellRadius:
      position === columns - 1
        ? {
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
            ...CELL_SIZE_HEADER[position],
            height: "76px",
          }
        : {
            ...CELL_SIZE_HEADER[position],
            height: "76px",
          },
    skeletonSize: {
      width: SKELETON_WIDTH_BODY[position],
      margin: position === 0 ? "0px" : "auto",
    },
  });
