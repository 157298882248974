import React from "react";
import { FileUploadContainerStyles as styles } from "../FileUploadContainer.styles";
import { Box, CircularProgress, Typography } from "@mui/material";

export interface LoadingFileProps {
  infoText: string;
}
const LoadingFile: React.FC<LoadingFileProps> = ({
  infoText,
}: LoadingFileProps) => {
  return (
    <Box sx={styles.fileUploadContainer}>
      <Box sx={styles.loadingContainer}>
        <CircularProgress sx={styles.circularProgress} />
        <Typography variant="body2" fontSize="16px">
          {infoText}...
        </Typography>
      </Box>
    </Box>
  );
};

export default LoadingFile;
