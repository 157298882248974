import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { get, has } from "lodash";
import { useSelector } from "react-redux";
import { IBillingDashboardState } from "../../store/reducer";
import { formatNumber } from "../DashboardList/Table/DashboardHistoricTable/DashboardHistoricTableRow";
import {
  DynamoReferenceEnum,
  getDynamoElasticRef,
} from "../../shared/infrastructure/constants/DynamoElasticMap";
import {
  FinalStatusEnum,
  StatusEnum,
} from "../../shared/infrastructure/StatusEnum";
import { IStepRetentionTimeLine } from "../../shared/infrastructure/interfaces/IStepRetentionTimeLine";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
  formatDate,
} from "../../shared/utils/date-time-util";
import { FooterEnum } from "../../shared/infrastructure/FooterEnum";
import { StepTitleEnum } from "../../shared/infrastructure/StepTitleEnum";

export interface IModalDialogDetail {
  open?: boolean;
  handleCloseDialogDetail?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.error.dark,
    fontSize: "16px",
  },
  buttonClose: {
    fontSize: "18px !important",
    color: "#023365 !important",
    marginTop: "50px !important",
    maxHeight: "50px !important",
  },
  dialog: {
    padding: "16px 19px",
    width: "600px",
    minHeight: "326px",
  },
  titleText: {
    fontFamily: "IBM Plex Sans",
    fontSize: "20px !important",
    fontStyle: "normal",
    fontWeight: "bolder",
  },
  alert: {
    marginTop: "15px",
  },
  labelContainer: {
    display: "flex",
    flexWrap: "nowrap",
    width: "370px",
  },
  labelContent: {
    display: "flex",
    flexWrap: "nowrap",
  },
  downloadButton: {
    marginLeft: "280px",
  },
  titleDialog: {
    fontSize: "18px !important",
    marginBottom: "10px !important",
    marginTop: "10px !important",
  },
  rejectDialog: {
    fontSize: "18px !important",
    marginBottom: "8px !important",
  },
  footerText: {
    fontSize: "13px !important",
    color: theme.palette.grey["500"],
  },
  gridSpacingReject: {
    paddingBottom: 24,
  },
  textDialog: {
    fontSize: "16px !important",
  },
  paper: {
    padding: theme.spacing(2),
    boxShadow: "none !important",
    borderRadius: "0px !important",
    backgroundColor: "#F7FAFC !important",
    marginBottom: "20px",
  },
  modifiedDateText: {
    fontSize: "16px !important",
  },
  withoutScroll: {
    "& .MuiDialog-paper, & .MuiDialogContent-root": {
      overflowY: "initial",
    },
  },
  alignTitle: {
    marginLeft: "23px",
  },
  grid: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    backgroundColor: "#F7FAFC !important",
    marginTop: "5px !important",
  },
  divider: {
    width: "100%",
    marginTop: 5,
    marginBottom: 3,
  },
}));

const isRejected = (stepTimeLineData: IStepRetentionTimeLine) =>
  [StatusEnum.REJECTED, StatusEnum.PRE_PROCESSED, StatusEnum.OMITTED].includes(
    get(stepTimeLineData, "value.status", "")
  );

export const ModalDialogDetailRetentionColombia: React.FC<IModalDialogDetail> = (
  props: IModalDialogDetail
) => {
  const styles = useStyles();
  const { stepTimeLineData } = useSelector(
    (state: IBillingDashboardState) => state
  );

  const InitialAmount = "value.modify.initialAmount";
  const ModifyAmount = "value.modify.modifyAmount";
  const amountRetIvaInitial = has(
    stepTimeLineData,
    `${InitialAmount}.amountRetIva`
  )
    ? get(stepTimeLineData, `${InitialAmount}.amountRetIva`, 0)
    : get(stepTimeLineData, `${InitialAmount}.retIva`, 0);

  const amountRetIvaModify = has(
    stepTimeLineData,
    `${ModifyAmount}.amountRetIva`
  )
    ? get(stepTimeLineData, `${ModifyAmount}.amountRetIva`)
    : get(stepTimeLineData, `${ModifyAmount}.retIva`);

  const hasRetIvaModify = has(stepTimeLineData, `${ModifyAmount}.amountRetIva`);
  const hasRetIcaModify = has(stepTimeLineData, `${ModifyAmount}.amountRetIca`);
  const hasBaseIcaModify = has(
    stepTimeLineData,
    `${ModifyAmount}.baseAmountIca`
  );
  const hasBaseIvaModify = has(
    stepTimeLineData,
    `${ModifyAmount}.baseAmountIva`
  );
  const hasRetSourceModify = has(
    stepTimeLineData,
    `${ModifyAmount}.amountRetSource`
  );

  const hasRetTotalModify = has(
    stepTimeLineData,
    `${ModifyAmount}.retTotalAmount`
  );

  let retTotalAmountInitial = get(
    stepTimeLineData,
    `${InitialAmount}.retTotalAmount`
  );
  if (!retTotalAmountInitial)
    retTotalAmountInitial =
      Number(get(stepTimeLineData, `${InitialAmount}.amountRetIva`)) +
      Number(get(stepTimeLineData, `${InitialAmount}.amountRetIca`)) +
      Number(get(stepTimeLineData, `${InitialAmount}.amountRetSource`));
  const retTotalAmountModify = get(
    stepTimeLineData,
    `${ModifyAmount}.retTotalAmount`,
    0
  );
  const baseAmountSourceInitial =
    get(stepTimeLineData, `${InitialAmount}.baseAmountSource`) ||
    get(stepTimeLineData, `${InitialAmount}.baseAmountIca`);
  const baseAmountSourceModify =
    get(stepTimeLineData, `${ModifyAmount}.baseAmountSource`) ||
    get(stepTimeLineData, `${ModifyAmount}.baseAmountIca`);

  const hasAmountSource: boolean =
    baseAmountSourceInitial > 0 || baseAmountSourceModify > 0;

  const baseAmountIvaInitial = get(
    stepTimeLineData,
    `${InitialAmount}.baseAmountIva`
  );
  const baseAmountIcaInitial = get(
    stepTimeLineData,
    `${InitialAmount}.baseAmountIca`
  );
  const baseAmountIvaModify = get(
    stepTimeLineData,
    `${ModifyAmount}.baseAmountIva`
  );
  const baseAmountIcaModify = get(
    stepTimeLineData,
    `${ModifyAmount}.baseAmountIca`
  );

  const amountRetIcaInitial = get(
    stepTimeLineData,
    `${InitialAmount}.amountRetIca`
  );
  const amountRetIcaModify = get(
    stepTimeLineData,
    `${ModifyAmount}.amountRetIca`
  );

  const amountRetSourceInitial = get(
    stepTimeLineData,
    `${InitialAmount}.amountRetSource`
  );
  const amountRetSourceModify = get(
    stepTimeLineData,
    `${ModifyAmount}.amountRetSource`
  );
  const show = "block";
  const hide = "none";
  const showRetIva = hasRetIvaModify ? show : hide;
  const showBaseIva = hasBaseIvaModify ? show : hide;
  const showBaseIca = hasBaseIcaModify ? show : hide;
  const showRetIca = hasRetIcaModify ? show : hide;
  const showRetSource = hasRetSourceModify ? show : hide;
  const showRetTotalAmount = hasRetTotalModify ? show : hide;

  function renderContentDialog(): JSX.Element | undefined {
    return StepTitleEnum.modifiedAmount ===
      get(stepTimeLineData, "title", "") ? (
      <Grid container xs={12}>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="textPrimary"
            className={styles.titleDialog}
          >
            Fecha de modificación:
          </Typography>
          <Typography variant="body2" color={"textSecondary"}>
            {formatDate(
              get(
                stepTimeLineData.value,
                getDynamoElasticRef(DynamoReferenceEnum.UPDATED_AT, false)
              ),
              DEFAULT_DATE_FORMAT
            )
              .concat(" ")
              .concat(
                formatDate(
                  get(
                    stepTimeLineData.value,
                    getDynamoElasticRef(DynamoReferenceEnum.UPDATED_AT, false)
                  ),
                  DEFAULT_TIME_FORMAT
                )
              )}
            {" hrs"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="textPrimary"
            style={{ fontWeight: "bold" }}
          >
            Montos modificados en el cálculo de Kushki:
          </Typography>
          <Grid container spacing={2} color="secondary" className={styles.grid}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Typography variant="body2"> </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">Montos Iniciales</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">Montos Modificados</Typography>
                </Grid>

                <Divider className={styles.divider} />
                <Grid item xs={5}>
                  <Typography variant="body2" style={{ display: showRetIva }}>
                    Monto ret. IVA
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ marginBottom: "8px", display: showBaseIva }}
                  >
                    Base gravable IVA
                  </Typography>
                  <Typography variant="body2" style={{ display: showRetIca }}>
                    Monto ret. ICA
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ display: showRetSource }}
                  >
                    Monto ret. Fuente
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ marginBottom: "8px", display: showBaseIca }}
                  >
                    *Base gravable ICA
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ display: showRetTotalAmount }}
                  >
                    Monto ret.Total
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ display: showRetIva }}
                  >
                    {formatNumber(
                      amountRetIvaInitial,
                      get(stepTimeLineData, "value.currency", "COP")
                    )}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ marginBottom: "8px", display: showBaseIva }}
                  >
                    {formatNumber(
                      baseAmountIvaInitial,
                      get(stepTimeLineData, "value.currency", "COP")
                    )}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ display: showRetIca }}
                  >
                    {formatNumber(
                      amountRetIcaInitial,
                      get(stepTimeLineData, "value.currency", "COP")
                    )}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ display: showRetSource }}
                  >
                    {formatNumber(
                      amountRetSourceInitial,
                      get(stepTimeLineData, "value.currency", "COP")
                    )}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ marginBottom: "8px", display: showBaseIca }}
                  >
                    {formatNumber(
                      baseAmountIcaInitial,
                      get(stepTimeLineData, "value.currency", "COP")
                    )}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ display: showRetTotalAmount }}
                  >
                    {formatNumber(
                      retTotalAmountInitial,
                      get(stepTimeLineData, "value.currency", "COP")
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2" style={{ display: showRetIva }}>
                    {formatNumber(
                      amountRetIvaModify,
                      get(stepTimeLineData, "value.currency", "COP")
                    )}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ marginBottom: "8px", display: showBaseIva }}
                  >
                    {formatNumber(
                      baseAmountIvaModify,
                      get(stepTimeLineData, "value.currency", "COP")
                    )}
                  </Typography>
                  <Typography variant="body2" style={{ display: showRetIca }}>
                    {formatNumber(
                      amountRetIcaModify,
                      get(stepTimeLineData, "value.currency", "COP")
                    )}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ display: showRetSource }}
                  >
                    {formatNumber(
                      amountRetSourceModify,
                      get(stepTimeLineData, "value.currency", "COP")
                    )}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ marginBottom: "8px", display: showBaseIca }}
                  >
                    {formatNumber(
                      baseAmountIcaModify,
                      get(stepTimeLineData, "value.currency", "COP")
                    )}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ display: showRetTotalAmount }}
                  >
                    {formatNumber(
                      retTotalAmountModify,
                      get(stepTimeLineData, "value.currency", "COP")
                    )}
                  </Typography>
                </Grid>
                {(hasAmountSource ||
                  hasBaseIvaModify ||
                  hasBaseIcaModify ||
                  hasRetTotalModify ||
                  hasRetIcaModify ||
                  hasRetSourceModify ||
                  hasRetIvaModify) && (
                  <Grid item xs={12}>
                    <Typography className={styles.footerText}>
                      {FooterEnum.retentionPendingContentColombia}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    ) : StepTitleEnum.rejectRetention === get(stepTimeLineData, "title", "") ? (
      <Grid container xs={12}>
        <Grid item xs={12} className={styles.gridSpacingReject}>
          <Typography
            variant="body2"
            color="textPrimary"
            className={styles.rejectDialog}
          >
            Fecha de modificación:
          </Typography>
          <Box display={"flex"} gridColumnGap={3}>
            <Typography variant="body2" className={styles.textDialog}>
              {formatDate(
                get(
                  stepTimeLineData.value,
                  getDynamoElasticRef(
                    DynamoReferenceEnum.UPDATED_AT,
                    get(stepTimeLineData, "isHistoric", false)
                  )
                ),
                DEFAULT_DATE_FORMAT
              )}
            </Typography>
            <Typography
              variant="body2"
              color={"textSecondary"}
              className={styles.modifiedDateText}
            >
              {formatDate(
                get(
                  stepTimeLineData.value,
                  getDynamoElasticRef(
                    DynamoReferenceEnum.UPDATED_AT,
                    get(stepTimeLineData, "isHistoric", false)
                  )
                ),
                DEFAULT_TIME_FORMAT
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="textPrimary"
            className={styles.rejectDialog}
          >
            Motivo:
          </Typography>
          <Typography variant="body2" className={styles.textDialog}>
            {get(stepTimeLineData, "value.initial_status", "") ===
              StatusEnum.REJECT || isRejected(stepTimeLineData)
              ? get(stepTimeLineData, "value.reject.reason", "")
              : get(
                  stepTimeLineData,
                  "value.response_text.mensajes[0].mensaje",
                  ""
                )}
          </Typography>
        </Grid>
      </Grid>
    ) : StepTitleEnum.canceledRetention ===
      get(stepTimeLineData, "title", "") ? (
      <Grid container xs={12}>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="textPrimary"
            className={styles.titleDialog}
          >
            Fecha de anulación:
          </Typography>
          <Typography variant="body2" className={styles.textDialog}>
            {formatDate(
              get(
                stepTimeLineData.value,
                getDynamoElasticRef(
                  DynamoReferenceEnum.UPDATED_AT,
                  get(stepTimeLineData, "isHistoric", false)
                )
              ),
              DEFAULT_DATE_FORMAT
            )}
          </Typography>
          <Typography
            variant="body2"
            color={"textSecondary"}
            className={styles.modifiedDateText}
          >
            {formatDate(
              get(
                stepTimeLineData.value,
                getDynamoElasticRef(
                  DynamoReferenceEnum.UPDATED_AT,
                  get(stepTimeLineData, "isHistoric", false)
                )
              ),
              DEFAULT_TIME_FORMAT
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="textPrimary"
            className={styles.titleDialog}
          >
            Motivo:
          </Typography>
          <Typography variant="body2" className={styles.textDialog}>
            {get(stepTimeLineData, "value.amount_change_info.reason", "")}
          </Typography>
        </Grid>
      </Grid>
    ) : StepTitleEnum.omittedRetention ===
      get(stepTimeLineData, "title", "") ? (
      <Grid container xs={12}>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="textPrimary"
            className={styles.titleDialog}
          >
            Fecha de modificación:
          </Typography>
          <Typography
            variant="body2"
            className={styles.textDialog}
            display={"inline"}
            style={{ marginRight: "20px" }}
          >
            {formatDate(
              get(
                stepTimeLineData.value,
                getDynamoElasticRef(
                  DynamoReferenceEnum.UPDATED_AT,
                  get(stepTimeLineData, "isHistoric", false)
                )
              ),
              DEFAULT_DATE_FORMAT
            )}
          </Typography>
          <Typography
            variant="body2"
            color={"textSecondary"}
            className={styles.modifiedDateText}
            display={"inline"}
          >
            {formatDate(
              get(
                stepTimeLineData.value,
                getDynamoElasticRef(
                  DynamoReferenceEnum.UPDATED_AT,
                  get(stepTimeLineData, "isHistoric", false)
                )
              ),
              DEFAULT_TIME_FORMAT
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="textPrimary"
            className={styles.titleDialog}
          >
            Motivo
          </Typography>
          <Typography variant="body2" className={styles.textDialog}>
            {get(stepTimeLineData, "value.omit.reason", "")}
          </Typography>
        </Grid>
      </Grid>
    ) : undefined;
  }
  return (
    <Dialog
      open={props.open!}
      maxWidth={"md"}
      className={styles.withoutScroll}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={styles.dialog}>
        <Grid container xs={12} direction={"row"} spacing={2}>
          <Grid item xs={12}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              className={styles.alignTitle}
            >
              <Typography
                variant={"h5"}
                color="textPrimary"
                className={styles.titleText}
              >
                {[StatusEnum.REJECT, FinalStatusEnum.OMITTED].includes(
                  get(stepTimeLineData, "value.initial_status", "")
                ) || isRejected(stepTimeLineData)
                  ? get(stepTimeLineData, "title", "")
                      .concat(" ")
                      .concat(get(stepTimeLineData, "subtitle", ""))
                      .concat(" por ")
                      .concat(
                        get(
                          stepTimeLineData.value,
                          getDynamoElasticRef(
                            DynamoReferenceEnum.BACKOFFICE_USER,
                            get(stepTimeLineData, "isHistoric", false)
                          )
                        )
                      )
                  : get(stepTimeLineData, "title", "")
                      .concat(" ")
                      .concat(get(stepTimeLineData, "subtitle", ""))}
              </Typography>
              <IconButton onClick={props.handleCloseDialogDetail}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <DialogContent>{renderContentDialog()}</DialogContent>
        <DialogActions>
          <Button
            onClick={props.handleCloseDialogDetail}
            className={styles.buttonClose}
          >
            {"Entendido"}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
