import React from "react";
import { Box, Button, Grid, IconButton, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { X } from "react-feather";
import {
  ButtonText,
  LabelField,
} from "../../../../shared/infrastructure/constants/RulesConstants";
import { SelectProcessor } from "./SelectProcessor/SelectProcessor";
import { ProcessorsInformation } from "../../../../../types/processors_list";

export interface DefaultProcessorsMerchantProps {
  isRequest: boolean;
  processors: {
    card: ProcessorsInformation[];
    transfer: ProcessorsInformation[];
    ach: ProcessorsInformation[];
  };
  closePopover: () => void;
  onSave: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    color: theme.palette.primary.dark,
  },
  saveButton: {
    color: theme.palette.primary.dark,
    border: `1px solid ${theme.palette.primary.dark}`,
  },
}));

export const DefaultProcessorsMerchant: React.FC<DefaultProcessorsMerchantProps> =
  (props: DefaultProcessorsMerchantProps) => {
    const classes = useStyles();

    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" style={{ width: "100%" }}>
              <Box flexGrow={1} pt={1}>
                <Typography className={classes.title}>
                  {LabelField.DEFAULT_PROCESSOR}
                </Typography>
              </Box>
              <Box>
                <IconButton onClick={props.closePopover}>
                  <X size={18} />
                </IconButton>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <SelectProcessor
              title={LabelField.PROCESSOR_CARD}
              isRequest={props.isRequest}
              controllerName={"cardProcessor"}
              processors={props.processors.card}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectProcessor
              title={LabelField.PROCESSOR_TRANSFER}
              isRequest={props.isRequest}
              controllerName={"transferProcessor"}
              processors={props.processors.transfer}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectProcessor
              title={LabelField.PROCESSOR_ACH}
              isRequest={props.isRequest}
              controllerName={"achProcessor"}
              processors={props.processors.ach}
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button onClick={props.closePopover}>Cancelar</Button>
              <Button
                variant="outlined"
                className={classes.saveButton}
                onClick={props.onSave}
              >
                {ButtonText.SAVE}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
