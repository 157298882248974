import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import React, { FC } from "react";
import { IInputsFormProps } from "../InputsFormProps.interfaces";
import { get } from "lodash";

const AlfaNumericInput: FC<IInputsFormProps> = ({
  name,
  control,
  controlName = name,
  errors,
  label,
  defaultValue,
  onBlur,
  sx = {},
  disabled,
  rules = {},
}: IInputsFormProps): JSX.Element => {
  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue as string}
      render={({ field }) => (
        <TextField
          {...field}
          label={label}
          variant="outlined"
          fullWidth
          disabled={disabled}
          error={get(errors, controlName) !== undefined}
          helperText={get(errors, controlName)?.message ?? ""}
          onBlurCapture={onBlur}
          sx={sx}
        />
      )}
    />
  );
};

export default AlfaNumericInput;
