import React from "react";
import { UseModalRejectRuleComponentState } from "./state/useModalRejectRuleComponentState";
import { ModalLoader, TextArea } from "@kushki/connect-ui";
export declare enum DividerEnum {
  paper = "paper",
  body = "body",
}
import { ModalContent } from "@kushki/connect-ui";
import { Box, Grid } from "@mui/material";
import { IModalUpdateStatusProps } from "../../shared/infrastructure/interfaces/IModalRejectComponent";
import { ConfirmModalEnum } from "../../shared/infrastructure/enums/ConfirmModalEnum";
import { get } from "lodash";
import {
  ModalContentButtonTypeEnum,
  ModalContentTypeEnum,
  ModalUpdateStatusPath,
} from "../../shared/infrastructure/enums/ModalEnum";
import { GeneralActionEnum } from "../../shared/infrastructure/constants/RuleRequestManagerConstants";

export const ModalRejectRuleComponent = (props: IModalUpdateStatusProps) => {
  const {
    open,
    handleAction,
    isLoading,
    modalComponents,
    onCloseModal,
    isDisableState,
    handleInputChange,
  } = UseModalRejectRuleComponentState(props);

  return (
    <React.Fragment>
      <ModalContent
        buttonText={get(
          modalComponents,
          ModalUpdateStatusPath.ACTION_BUTTON,
          ""
        )}
        buttonType={ModalContentButtonTypeEnum.PRIMARY}
        descriptionText={get(
          modalComponents,
          ModalUpdateStatusPath.DESCRIPTION,
          ""
        )}
        onClose={onCloseModal}
        titleText={get(modalComponents, ModalUpdateStatusPath.TITLE, "")}
        type={ModalContentTypeEnum.DEFAULT}
        buttonAction={handleAction}
        buttonPrimaryDisabled={isDisableState}
        isOpen={open!}
        buttonSecondaryText={get(
          modalComponents,
          ModalUpdateStatusPath.BUTTON_SECONDARY_TEXT,
          GeneralActionEnum.RETURN
        )}
      >
        <Grid>
          <Grid container>
            <Grid
              columnSpacing={{
                md: 3,
                sm: 2,
                xs: 1,
              }}
              container
              paddingTop={1}
            >
              <Grid item xs={12}>
                <Box
                  sx={{
                    ".MuiOutlinedInput-root": {
                      "@media (max-width:959.95px)": {
                        width: "100%",
                      },
                      width: "700px",
                    },
                  }}
                >
                  <TextArea
                    height={100}
                    maxCounter={500}
                    placeholder={get(
                      modalComponents,
                      ModalUpdateStatusPath.PLACEHOLDER,
                      ""
                    )}
                    name={"reason"}
                    ref={React.createRef()}
                    onChange={handleInputChange}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ModalContent>
      {isLoading && (
        <ModalLoader
          descriptionText={ConfirmModalEnum.PROCESSING_TITLE}
          titleText={ConfirmModalEnum.PROCESSING_DESCRIPTION}
          isOpen={isLoading}
        />
      )}
    </React.Fragment>
  );
};
