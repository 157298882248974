import { PlatformValueEnum } from "../enums/PlatformValueEnum";
import { IBarChartProp } from "@kushki/connect-ui/dist/Components/Molecules/DataVisualization/BarChart";
import { ChannelNames } from "./ChannelNames";

export const ChannelsDataDefault: IBarChartProp[] = Object.keys(
  PlatformValueEnum
).map((i: string) => {
  return {
    label: ChannelNames[i],
    quantity: 0,
  };
});
