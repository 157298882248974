import React, { useEffect, useState } from "react";
import { TransactionTable } from "../../../../types/transaction_table";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { KshInput } from "../../Common/KshInput/KshInput";
import { get, keys } from "lodash";
import { KshTextArea } from "../../Common/KshTextArea/KshTextArea";
import { BoxProps } from "@material-ui/core/Box/Box";

const boxPros = (): BoxProps => ({
  justifyContent: "center",
  display: "flex",
  flexWrap: "no",
  style: { paddingTop: 20 },
});

export interface IInvoiceInfoDialog {
  open: boolean;
  trxBilling: TransactionTable;
  onOpenInvoiceInfo(open: boolean): void;
  onOk(trx: TransactionTable): void;
}

export const InvoiceInfoDialog = (props: IInvoiceInfoDialog) => {
  const [invoiceInfo, setInvoiceInfo] = useState<{
    associatedInvoice?: string;
    comments?: string;
  }>();

  const handleOk = () => {
    if (
      invoiceInfo &&
      keys(invoiceInfo).filter((key: string) => invoiceInfo[key]).length
    )
      props.trxBilling.invoiceInfo = invoiceInfo;
    else delete props.trxBilling.invoiceInfo;

    props.onOk(props.trxBilling);
    props.onOpenInvoiceInfo(false);
  };

  const handleChange = (value: { prop: string; value?: string | number }) =>
    setInvoiceInfo({ ...invoiceInfo, [value.prop]: value.value });

  const handleClose = () => {
    setInvoiceInfo(props.trxBilling.invoiceInfo);
    props.onOpenInvoiceInfo(false);
  };
  useEffect(
    () =>
      setInvoiceInfo({
        associatedInvoice: get(
          props,
          "trxBilling.invoiceInfo.associatedInvoice"
        ),
        comments: get(props, "trxBilling.invoiceInfo.comments"),
      }),
    [props.trxBilling]
  );

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h5">Información de factura</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          Ingresa información de la factura relacionada:
        </Typography>

        <Box {...boxPros()}>
          <KshInput
            label="Número"
            id="associatedInvoice"
            value={invoiceInfo?.associatedInvoice}
            labelWith={10}
            onBlur={handleChange}
            maxLength={20}
          />
        </Box>
        <Box {...boxPros()}>
          <KshTextArea
            id="comments"
            value={invoiceInfo?.comments}
            onBlur={handleChange}
            label="Comentarios"
            maxLength={200}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button id="button_ok" onClick={handleOk} color="primary" autoFocus>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
