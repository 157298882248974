import { defaultRefundsHistoryColumns } from "./DefaultHistoryColumns";
import { IHeaderTable } from "../../../../types/header_table";
import { StatusFlowEnum } from "../enums/StatusFlowEnum";

export const defaultHeaderOptions: IHeaderTable = {
  disabled: true,
  filterLabel: "",
  id: "options",
  label: "",
  orderBy: "options",
};

export const checkBoxHeader: IHeaderTable = {
  disabled: false,
  filterLabel: "",
  id: "refundDashboardStatus",
  isCustom: true,
  label: "status",
  orderBy: "refundDashboardStatus",
};

export const defaultBaseColumns: IHeaderTable[] = [
  {
    disabled: false,
    filterLabel: "Fecha de solicitud",
    id: "updateAt",
    label: "F. de solici. Reembolso",
    orderBy: "updateAt",
  },
  {
    disabled: false,
    filterLabel: "Nombre / ID del comercio",
    id: "merchantName",
    label: "Nombre y ID del Comercio",
    orderBy: "merchantName",
  },
  {
    disabled: false,
    filterLabel: "Monto",
    id: "requestAmount",
    label: "Monto",
    orderBy: "requestAmount",
  },
  {
    disabled: true,
    filterLabel: "Tipo y Estado de la transacción original",
    id: "transactionType",
    label: "Tipo y Estado de transacción original",
    orderBy: "transactionType",
  },
];

export const defaultExecutorColumns: IHeaderTable[] = [
  checkBoxHeader,
  ...defaultBaseColumns,
  {
    disabled: true,
    filterLabel: "ID Transacción original",
    id: "transactionId",
    label: "ID Transacción original",
    orderBy: "transactionId",
  },
  {
    disabled: true,
    filterLabel: "Estado del análisis",
    id: "stateRefund",
    label: "Edo. del analisis",
    orderBy: "stateRefund",
  },
  defaultHeaderOptions,
];

export const defaultExecutorHistoryColumns: IHeaderTable[] = [
  checkBoxHeader,
  ...defaultBaseColumns,
  {
    disabled: true,
    filterLabel: "Estado del análisis",
    id: "stateRefund",
    label: "Edo. del analisis",
    orderBy: "analysisState",
  },
  {
    disabled: true,
    filterLabel: "Acción y respuesta",
    id: "action",
    label: "Acción y respuesta",
    orderBy: "action",
  },
];

export const defaultValidatorColumns: IHeaderTable[] = [
  checkBoxHeader,
  ...defaultBaseColumns,
  {
    disabled: true,
    filterLabel: "ID Transacción original",
    id: "transactionId",
    label: "ID Transacción original",
    orderBy: "transactionId",
  },
  {
    disabled: true,
    filterLabel: "Acción Ejecutor",
    id: "executor",
    label: "Acción Ejecutor",
    orderBy: "executor",
  },
  defaultHeaderOptions,
];

export const defaultValidatorHistoryColumns: IHeaderTable[] = [
  ...defaultRefundsHistoryColumns,
];

export const defaultViewerColumns: IHeaderTable[] = [
  ...defaultBaseColumns,
  {
    disabled: true,
    filterLabel: "Estado del análisis",
    id: "stateRefund",
    label: "Edo. del analisis",
    orderBy: "stateRefund",
  },
  {
    disabled: true,
    filterLabel: "Acción y respuesta",
    id: "action",
    label: "Acción y respuesta",
    orderBy: "action",
  },
];

export const defaultBaseHeader: IHeaderTable[] = [
  {
    disabled: false,
    filterLabel: "Fecha de solicitud",
    id: "updateAt",
    label: "F. de solici. Reembolso",
    orderBy: "updateAt",
  },
  {
    disabled: false,
    filterLabel: "Nombre / ID del comercio",
    id: "merchantName",
    label: "Nombre y ID del Comercio",
    orderBy: "merchantName",
  },
  {
    disabled: false,
    filterLabel: "Monto",
    id: "requestAmount",
    label: "Monto",
    orderBy: "requestAmount",
  },
  {
    disabled: true,
    filterLabel: "ID Transacción original",
    id: "transactionId",
    label: "ID Transacción original",
    orderBy: "transactionId",
  },
  {
    disabled: true,
    filterLabel: "Tipo y Estado de la transacción original",
    id: "transactionType",
    label: "Tipo y Estado de transacción original",
    orderBy: "transactionType",
  },
  {
    disabled: true,
    filterLabel: "Procesador",
    id: "processorName",
    label: "Procesador",
    orderBy: "processorName",
  },
  {
    disabled: true,
    filterLabel: "ID reembolso declinado",
    id: "transactionRefundId",
    label: "ID reembolso declinado",
    orderBy: "transactionRefundId",
  },
  {
    disabled: true,
    filterLabel: "Monto trans. original",
    id: "approvedTransactionAmount",
    label: "Monto trans. original",
    orderBy: "approvedTransactionAmount",
  },
  {
    disabled: true,
    filterLabel: "Fecha de la transacción original",
    id: "createAt",
    label: "Fecha de la transacción original",
    orderBy: "createAt",
  },
  {
    disabled: true,
    filterLabel: "Número de ticket",
    id: "ticketNumber",
    label: "Número de ticket",
    orderBy: "ticketNumber",
  },
];

export const defaultHeadersExecutor: IHeaderTable[] = [
  checkBoxHeader,
  ...defaultBaseHeader,
  {
    disabled: true,
    filterLabel: "Estado del análisis",
    id: "stateRefund",
    label: "Edo. del analisis",
    orderBy: "stateRefund",
  },
  defaultHeaderOptions,
];

export const defaultHeadersExecutorHistory: IHeaderTable[] = [
  checkBoxHeader,
  ...defaultBaseHeader,
  {
    disabled: true,
    filterLabel: "Estado del análisis",
    id: "stateRefund",
    label: "Edo. del analisis",
    orderBy: "analysisState",
  },
  {
    disabled: true,
    filterLabel: "Acción y respuesta",
    id: "action",
    label: "Acción y respuesta",
    orderBy: "action",
  },
  defaultHeaderOptions,
];

export const defaultHeadersValidator: IHeaderTable[] = [
  checkBoxHeader,
  ...defaultBaseHeader,
  {
    disabled: true,
    filterLabel: "Acción ejecutor",
    id: "executor",
    label: "Acción ejecutor",
    orderBy: "executor",
  },
  defaultHeaderOptions,
];

export const defaultHeadersValidatorHistory: IHeaderTable[] = [
  ...defaultRefundsHistoryColumns,
  {
    disabled: true,
    filterLabel: "ID Transacción original",
    id: "transactionId",
    label: "ID Transacción original",
    orderBy: "transactionId",
  },
  defaultHeaderOptions,
];

export const defaultHeadersViewer: IHeaderTable[] = [
  ...defaultBaseHeader,
  {
    disabled: true,
    filterLabel: "Estado del análisis",
    id: "stateRefund",
    label: "Edo. del analisis",
    orderBy: "stateRefund",
  },
  {
    disabled: true,
    filterLabel: "Acción Ejecutor",
    id: "executor",
    label: "Acción Ejecutor",
    orderBy: "executor",
  },
  {
    disabled: true,
    filterLabel: "Acción y respuesta",
    id: "actionResponse",
    label: "Acción y Respuesta",
    orderBy: "actionResponse",
  },
];

export const defaultHeadersViewerHistory: IHeaderTable[] = [
  ...defaultBaseHeader,
  {
    disabled: true,
    filterLabel: "Estado del análisis",
    id: "analysisState",
    label: "Edo. del analisis",
    orderBy: "analysisState",
  },
  {
    disabled: true,
    filterLabel: "Acción y respuesta",
    id: "action",
    label: "Acción y Respuesta",
    orderBy: "action",
  },
];

export const defaultColumns = {
  [StatusFlowEnum.EXECUTOR]: defaultExecutorColumns,
  [StatusFlowEnum.EXECUTOR_HISTORY]: defaultExecutorHistoryColumns,
  [StatusFlowEnum.VALIDATOR]: defaultValidatorColumns,
  [StatusFlowEnum.VALIDATOR_HISTORY]: defaultValidatorHistoryColumns,
  [StatusFlowEnum.VIEWER]: defaultViewerColumns,
};

export const defaultHeaders = {
  [StatusFlowEnum.EXECUTOR]: defaultHeadersExecutor,
  [StatusFlowEnum.EXECUTOR_HISTORY]: defaultHeadersExecutorHistory,
  [StatusFlowEnum.VALIDATOR]: defaultHeadersValidator,
  [StatusFlowEnum.VALIDATOR_HISTORY]: defaultHeadersValidatorHistory,
  [StatusFlowEnum.VIEWER]: defaultHeadersViewer,
};
