import { Controller, UseFormReturn } from "react-hook-form";
import { useStyles as styles } from "./BillingDataFormCountries.styles";
import React from "react";
import { Divider, Grid, MenuItem, TextField } from "@mui/material";

import { get } from "lodash";
import { BillingDataFormLabelsEnum } from "../../shared/enums/BillingDataFormLabelsEnum";
import { ErrorBillingData } from "../../shared/enum/BasicStateMX/ErrorMessageMX";
import { FieldsEnum } from "../../shared/enums/FieldsEnum";
import PhoneInputField from "../commonInputs/PhoneInputField/PhoneInputField";
import { CountryEnum } from "../../shared/countryEnum";
import { MerchantCompanyRequest } from "../../../types/merchant_company_request";
import { Category } from "../../shared/constants/information_constants";
import { CatalogPan } from "../../shared/services/catalog-pan";
import { CatalogsEnum } from "../../shared/interfaces/catalogs-enum";
import { ZipCodeComponent } from "../ZipCodeComponent/ZipCodeComponent";

export type formProps = {
  cityPanamaCatalog: Category[];
  form: UseFormReturn<MerchantCompanyRequest>;
  infoBilling: {
    city: string;
    constitutionalCountry: string;
    country: string;
    municipality: string;
    populationCode: string;
    province: string;
    satCity: string;
    satMunicipality: string;
    satPopulationCode: string;
    satProvince: string;
    satStateCode: string;
    stateCode: string;
    useSameInfoSat: boolean;
  };
  isCentralizedNode: boolean;
};

export const BillingDataFormCountriesPA = (props: formProps) => {
  const classes = styles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Controller
            name={FieldsEnum.province}
            control={props.form.control}
            rules={{
              required: {
                message: ErrorBillingData.PROVINCE,
                value: true,
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                id="simple-range"
                fullWidth
                label={BillingDataFormLabelsEnum.province}
                select
                value={props.form.watch(FieldsEnum.province)}
                error={
                  !!get(props.form.formState.errors, FieldsEnum.province, null)
                }
                onChange={field.onChange}
                disabled={props.isCentralizedNode}
              >
                {get(CatalogPan, `${CatalogsEnum.Provinces}`, []).map(
                  (item: Category) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.name}
                    </MenuItem>
                  )
                )}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name={FieldsEnum.city}
            control={props.form.control}
            rules={{
              required: {
                message: ErrorBillingData.DISTRIC,
                value: true,
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                id="simple-range"
                fullWidth
                label={BillingDataFormLabelsEnum.distric}
                select
                value={props.form.watch(FieldsEnum.city)}
                error={
                  !!get(props.form.formState.errors, FieldsEnum.city, null)
                }
                onChange={field.onChange}
                disabled={props.isCentralizedNode}
              >
                {props.cityPanamaCatalog.map((item: Category) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>{" "}
        <Grid item xs={6}>
          <ZipCodeComponent
            {...props.form}
            control={props.form.control}
            country={CountryEnum.panama}
            errors={props.form.formState.errors}
            watch={props.form.watch}
            disabled={props.isCentralizedNode}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name={FieldsEnum.address}
            rules={{
              required: {
                message: ErrorBillingData.ADDRESS,
                value: true,
              },
            }}
            control={props.form.control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id="outlined-basic"
                label={BillingDataFormLabelsEnum.address}
                error={
                  !!get(props.form.formState.errors, FieldsEnum.address, null)
                }
                className={classes.errorMessage}
                helperText={
                  get(props.form.formState.errors, FieldsEnum.address)
                    ?.message ?? ""
                }
                variant="outlined"
                onChange={(e) => {
                  field.onChange(e.target.value);
                }}
                disabled={props.isCentralizedNode}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <PhoneInputField
            fieldName={FieldsEnum.phoneNumber}
            labelName={BillingDataFormLabelsEnum.merchant_phone}
            control={props.form.control}
            errors={props.form.formState.errors}
            watch={props.form.watch}
            rules={{
              required: { message: ErrorBillingData.PHONE_NUMBER_EMPTY },
            }}
            country={props.infoBilling.constitutionalCountry as CountryEnum}
            countryCustomer={props.infoBilling.country as CountryEnum}
            disabled={props.isCentralizedNode}
          />
        </Grid>
      </Grid>
      <Divider className={classes.satDivider} />
    </>
  );
};

export default BillingDataFormCountriesPA;
