export enum RuleDetailLabels {
  Category = "Categoría",
  Conditional = "Condicional",
  Contains = "Contiene",
  Different = "Diferente",
  DoestNotContain = "No Contiene",
  Equals = "Igual",
  GreaterThan = "Mayor que",
  LessThan = "Menor que",
  No = "No",
  SendTo = "Enviar a",
  Yes = "Sí",
  Credit = "Crédito",
  Debit = "Débito",
  ValidateEnroll = "Con validación",
  WithOutEnroll = "Sin validación",
}

enum RuleOperators {
  Contains = "contain",
  Different = "!=",
  DoesNotContain = "!contain",
  Equal = "=",
  GreaterThan = ">",
  LessThan = "<",
  ValidateEnroll = "validateEnroll",
  WithOutEnroll = "withOutEnroll",
}

enum RuleValues {
  False = "false",
  True = "true",
  Credit = "credit",
  Debit = "debit",
}

export const RuleConditions: Record<string, string> = {
  [RuleOperators.Contains]: RuleDetailLabels.Contains,
  [RuleOperators.Different]: RuleDetailLabels.Different,
  [RuleOperators.DoesNotContain]: RuleDetailLabels.DoestNotContain,
  [RuleOperators.Equal]: RuleDetailLabels.Equals,
  [RuleOperators.GreaterThan]: RuleDetailLabels.GreaterThan,
  [RuleOperators.LessThan]: RuleDetailLabels.LessThan,
  [RuleOperators.ValidateEnroll]: RuleDetailLabels.ValidateEnroll,
  [RuleOperators.WithOutEnroll]: RuleDetailLabels.WithOutEnroll,
};

export const RuleConditionsValues: Record<string, string> = {
  [RuleValues.False]: RuleDetailLabels.No,
  [RuleValues.True]: RuleDetailLabels.Yes,
};

export const RuleConditionsValuesByCardType: Record<string, string> = {
  [RuleValues.False]: RuleDetailLabels.Debit,
  [RuleValues.True]: RuleDetailLabels.Credit,
  [RuleValues.Credit]: RuleDetailLabels.Credit,
  [RuleValues.Debit]: RuleDetailLabels.Debit,
};
