import { createNamedStyles } from "../../../../shared/utils/create_named_styles";

const TableSkeletonStyles = createNamedStyles({
  checkboxSkeleton: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
});

export default TableSkeletonStyles;
