import { ICellFlag } from "./CellFlag.interfaces";
import { Box, Typography } from "@mui/material";
import React, { FC } from "react";
import { cellFlagStyles } from "./CellFlag.styles";
import { getFlagValues } from "../../../shared/utils/cellUtils";

export interface IFlagValues {
  countryCode: string;
  countryText: string;
  IconFlag: FC;
}

export const CellFlagItem: FC<ICellFlag> = ({
  type,
  country,
  align,
  isCodeCountry = false,
}: ICellFlag) => {
  const { IconFlag, countryCode }: IFlagValues = getFlagValues(
    isCodeCountry,
    country
  );
  const twoLines: boolean = type === "twolines";

  return (
    <Box sx={cellFlagStyles.container} alignItems={align || "center"}>
      <>
        <IconFlag />
        {twoLines && (
          <Typography
            color="text.grey"
            variant="overline"
            component="div"
            sx={cellFlagStyles.textFlag}
          >
            {countryCode}
          </Typography>
        )}
      </>
    </Box>
  );
};
