import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";
import { get } from "lodash";
import { ITEM_SIMPLE_CONDITION } from "../../../../../shared/infrastructure/enums/ItemByTagEnum";
import { useParams } from "react-router-dom";
import { isFormEditMode } from "../../../../../utils/utilsFile";
import { IValuesConditionsProps } from "../../../../../shared/infrastructure/interfaces/IValuesConditionsProps";
import { IUseConditionSimpleLabelState } from "../../../../../shared/infrastructure/interfaces/IUseConditionSimpleLabelState";

export const useConditionSimpleLabelState = (
  props: IValuesConditionsProps
): IUseConditionSimpleLabelState => {
  const { control } = useFormContext();
  const { ruleId } = useParams();
  const defaultValueRadio: string[] = props.defaultValueList;
  const [simpleCondition, setSimpleCondition] = useState<string | undefined>(
    undefined
  );

  const selectSimpleCondition = (
    _event: React.ChangeEvent<{}>,
    value: string
  ): void => {
    setSimpleCondition(value);
  };

  useEffect(() => {
    if (simpleCondition) {
      const valueToRetrieve: ICategory[] = [
        {
          label: get(ITEM_SIMPLE_CONDITION[props.tag], "label", ""),
          value: simpleCondition,
          selected: true,
        },
      ];

      props.setSelectedItems(valueToRetrieve);
    }
  }, [simpleCondition, ruleId]);

  useEffect(() => {
    if (defaultValueRadio.length > 0 && isFormEditMode(ruleId)) {
      setSimpleCondition(defaultValueRadio[0].toString());
    }
  }, [defaultValueRadio, ruleId]);

  return {
    selectSimpleCondition,
    control,
    simpleCondition,
  };
};
