import React from "react";

import { Data } from "../../../../types/filter_wallet_dashboard_response";
import { Box, Grid, Typography } from "@material-ui/core";
import { get } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { TransactionDetailEnum } from "../../../shared/infrastructure/enums/TransactionDetailEnum";
import {
  countriesPayOut,
  formatNumber,
  renderSymbol,
} from "../../../shared/utils/utils";
import { ORIGIN_MICROSERVICE_WALLET_ORIGIN_TICKER_NUMBER } from "../../../shared/infrastructure/constants/OriginMicroserviceWalletConstants";
import { useWalletTableState } from "../../WalletTable/state/useWalletTableState";
import { CountryNameEnum } from "../../../shared/infrastructure/enums/CountryEnum";

const useStyles = makeStyles(() => ({
  masterTitle: {
    color: "#6D7781",
  },
  wrapper: {
    padding: "16px",
    background: "#F7FAFC",
    borderRadius: "8px",
  },
  subtitle: {
    color: "#677784",
  },
  title: {
    color: "#293036",
  },
}));

export interface ITransactionBodyInfo {
  transaction: Data;
  country: string;
}

export const TransactionBody: React.FC<ITransactionBodyInfo> = (
  props: ITransactionBodyInfo
) => {
  const { transaction, country } = props;
  const { renderTransactionTypeText } = useWalletTableState();
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box className={classes.wrapper}>
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <Typography variant="body2" className={classes.subtitle}>
                {TransactionDetailEnum.OPERATION_TYPE}:
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="body2" className={classes.title}>
                {renderTransactionTypeText(transaction.transaction_type)}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2" className={classes.subtitle}>
                {TransactionDetailEnum.ORIGIN}:
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="body2" className={classes.title}>
                {get(transaction, "merchant_name", "")}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              {ORIGIN_MICROSERVICE_WALLET_ORIGIN_TICKER_NUMBER.includes(
                get(transaction, "origin", "")
              ) && (
                <>
                  <Typography variant="body2" className={classes.subtitle}>
                    {TransactionDetailEnum.ORIGIN_TICKET_NUMBER}:
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item xs={7}>
              {ORIGIN_MICROSERVICE_WALLET_ORIGIN_TICKER_NUMBER.includes(
                get(transaction, "origin", "")
              ) && (
                <>
                  <Typography variant="body2" className={classes.title}>
                    {transaction.origin_ticket_number}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2" className={classes.subtitle}>
                {TransactionDetailEnum.ECONOMIC_ACTIVITY}:
              </Typography>
            </Grid>
            <Grid item xs={7} zeroMinWidth>
              <Typography variant="body2" className={classes.title}>
                {transaction.description}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2" className={classes.subtitle}>
                {TransactionDetailEnum.OPERATION_AMOUNT}:
              </Typography>
            </Grid>
            <Grid item xs={7} zeroMinWidth>
              <Typography variant="body2" className={classes.title}>
                {`${renderSymbol(transaction.transaction_type)} `}
                {formatNumber(
                  transaction.total_amount,
                  transaction.currency,
                  country
                )}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {countriesPayOut[country] && (
        <Grid item xs={12}>
          <Box className={classes.wrapper}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.masterTitle}>
                  {TransactionDetailEnum.DATA_BENEFIT}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="body2" className={classes.subtitle}>
                  {TransactionDetailEnum.NAME_BENEFIT}:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="body2" className={classes.title}>
                  {get(transaction, "kushki_mx_bank_info.recipientName", "")}
                </Typography>
              </Grid>
              {country === CountryNameEnum.mexico && (
                <>
                  <Grid item xs={5}>
                    <Typography variant="body2" className={classes.subtitle}>
                      {TransactionDetailEnum.ACCOUNT}:
                    </Typography>
                  </Grid>
                  <Grid item xs={7} zeroMinWidth>
                    <Typography variant="body2" className={classes.title}>
                      {get(transaction, "reference_processor", "")}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
