import React from "react";
import { Grid } from "@mui/material";
import { useAmountConditionState } from "./state/useAmountConditionState";
import { IAmountConditionProps } from "../../../../shared/interfaces/amount_condition_props";
import { TextFieldComponent } from "./components/TextFieldComponent";
import { get } from "lodash";

export const AmountConditionComponent: React.FC<IAmountConditionProps> = (
  props: IAmountConditionProps
) => {
  const { operator, index } = props;
  const name: string = `conditions.${index}.value.0`;

  const { control, placeHolder, actions, errors, value } =
    useAmountConditionState({
      defaultValueList: props.defaultValueList,
      name,
      operator,
      setSelectedItems: props.setSelectedItems,
    });

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextFieldComponent
            value={{
              actions,
              control,
              errors,
              id: name,
              name,
              placeholder: placeHolder,
              register: actions.register,
              setValue: actions.setValue,
              value: value,
            }}
            defaultValueList={[get(props, "defaultValueList[0]")]}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
