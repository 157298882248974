import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const myMerchantContainerStyles = createNamedStyles({
  boxContainer: {
    width: 1,
    height: "100%",
    position: "relative",
    display: "flex",
    paddingLeft: "10px",
    paddingRight: "20px",
  },
  box: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  boxAccordion: {
    marginBottom: 3,
    width: "100%",
    "& .MuiAccordion-root.Mui-expanded": {
      backgroundColor: "#fff !important",
    },
  },
  boxHeader: {
    alignItems: "center",
    direction: "row",
  },
  route: {
    fontSize: "16px !important",
    fontWeight: 200,
    color: "#677784",
  },
  title: {
    fontSize: "38px !important",
    fontWeight: 200,
  },
  text: {
    fontSize: "14px !important",
  },
  webText: {
    fontSize: "14px !important",
    marginTop: "10px",
    color: "#677784",
  },
  iconCopy: {
    color: "#CBD5E0",
  },
  boxSubHeader: {
    width: "100%",
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "24px",
    marginBottom: "30px",
  },
  flagPadding: {
    paddingLeft: "13px",
  },
  boxSubHeaderMerchant: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  boxContent: {
    height: "90%",
  },
  skeleton: {
    width: 300,
    height: 50,
  },
});
