import { ProviderEnum, ProviderOptionsEnum } from "./ProviderOptionsEnum";
import { defaultTo } from "lodash";

export interface ITest {
  value: string;
}

export enum RulesNotificationResultEnum {
  RULES_REQUEST_SUCCESS = "RULES_REQUEST_SUCCESS",
  RULES_REQUEST_ERROR = "RULES_REQUEST_ERROR",
  VALIDATION_ERROR = "VALIDATION_ERROR",
  NO_ACTION = "NO_ACTION",
  DEFAULT_MESSAGE_ERROR_GET_RULES = "Error en la obtención de las reglas.",
  DEFAULT_MESSAGE_MIGRATE_RULES = "Error en la migración de las reglas",
  SUCCESS = "SUCCESS",
  RULE_DELETE = "Regla eliminada.",
  RULE_REQUEST_MIGRATE_SUCCESS = "Reglas migradas con éxito",
}

export interface IOptionSelection {
  name: string;
  value: string;
}

export interface IOptionImageSelection {
  name: string;
  value: string;
  image: string;
  valid?: boolean;
}
export enum Countries {
  ECUADOR = "Ecuador",
  COLOMBIA = "Colombia",
  PERU = "Peru",
  CHILE = "Chile",
  MEXICO = "Mexico",
  BRAZIL = "Brazil",
}

export enum CurrencyEnum {
  USD = "USD",
  COP = "COP",
  CLP = "CLP",
  UF = "UF",
  PEN = "PEN",
  MXN = "MXN",
  BRL = "BRL",
}

export interface ISupplier {
  value: string;
  name: string;
}

export const Suppliers: ISupplier[] = [
  {
    value: "cybersource",
    name: "Cybersource",
  },
  {
    value: "kushki",
    name: "Kushki",
  },
];

export interface IAuthenticationType {
  value: string;
  name: string;
}

export const AuthenticationType: IAuthenticationType[] = [
  {
    value: ProviderOptionsEnum.DS,
    name: ProviderOptionsEnum.DS,
  },
  {
    value: ProviderOptionsEnum.OTP,
    name: ProviderOptionsEnum.OTP,
  },
  {
    value: ProviderOptionsEnum.OTP_DINNERS,
    name: ProviderOptionsEnum.OTP_DINNERS,
  },
];

export const cybersouceOptions: string[] = [ProviderOptionsEnum.DS];
export const kuskiOptions: string[] = [ProviderOptionsEnum.OTP];

export const providerValidation: object = {
  [ProviderEnum.CYBERSOURCE]: cybersouceOptions,
  [ProviderEnum.KUSHKI]: kuskiOptions,
};

export const validateProviderOption = (
  provider: string,
  kind: string
): boolean => {
  return !defaultTo(providerValidation[provider], cybersouceOptions).includes(
    kind
  );
};

export interface IOptionSelection {
  name: string;
  value: string;
}

export enum PeriodicityTypeEnum {
  MINUTE = "per_minute",
  HOUR = "per_hour",
  DAY = "daily",
  WEEK = "weekly",
  BIWEEKLY = "biweekly",
  MONTH = "monthly",
  QUARTERLY = "quarterly",
  BIANNUAL = "biannual",
}

export enum RuleSchemaEnum {
  transactionBank = "transactionBank",
  kind = "kind",
  transactionBrand = "transactionBrand",
  transactionIsDeferred = "transactionIsDeferred",
  transactionProcessor = "transactionProcessor",
  transactionCountry = "transactionCountry",
  transactionTotalAmount = "transactionTotalAmount",
  transactionIP = "transactionIP",
  transactionType = "transactionType",
  transactionCredential = "transactionCredential",
  transactionBIN = "transactionBIN",
  transactionIsCreditCard = "transactionIsCreditCard",
  transactionMaskedCreditCard = "transactionMaskedCreditCard",
  transactionCardId = "transactionCardId",
  transactionCardIssuer = "transactionCardIssuer",
  transactionCurrency = "transactionCurrency",
  transactionCreated = "transactionCreated",
  transactionStatusCount = "transactionStatusCount",
  transactionStatusSum = "transactionStatusSum",
  periodicityCount = "periodicityCount",
  periodicitySum = "periodicitySum",
  limit = "limit",
  maxAmount = "maxAmount",
}

export enum RuleRequestRoles {
  M_FRAUD_PREVENTION = "M_FRAUD_PREVENTION",
  RULE_REQUEST_AUTH_RULES_PROCESS = "ReglasDeAutenticación.Procesar",
  RULE_REQUEST_AUTH_RULES_EDIT = "ReglasDeAutenticación.Editar",
  RULE_REQUEST_AUTH_RULES_REASSIGN_ANALYST = "ReglasDeAutenticación.Editar.ReasignarAnalista",
  RULE_REQUEST_AUTH_RULES_EDIT_REASSIGN_ANALYST = "ReglasDeAutenticación.Editar.ReasignarAnalista",
  RULE_REQUEST_AUTH_RULES_DOWNLOAD = "ReglasDeAutenticación.Descargar",
}
