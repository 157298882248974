import React from "react";
import { InfoTrxProps } from "../ModalBodyInfo/ModalBodyRetentionHistoric";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import {
  AdvanceConfigModalGeneric,
  fieldEnum,
  fieldInfoEnumCamel,
  fielInfoEnum,
  InfoModalGeneric,
  subSectionEnum,
  tabEnum,
} from "../../shared/infrastructure/AdvanceConfigModal";
import { Grid, Typography } from "@material-ui/core";
import { defaultTo, floor, get, isEmpty, isNil, lowerCase } from "lodash";
import {
  getPaymentMethodCatalog,
  PAYMENT_METHOD_DESCRIPTION,
  PAYMENT_METHOD_ENUM,
} from "../../shared/infrastructure/PaymentMethodEnum";
import { InvoiceRecord } from "../../../types/remote/response_search_blling_by_filters";
import { format, utcToZonedTime } from "date-fns-tz";
import { StatusFlowEnum } from "../../shared/infrastructure/StatusFlowEnum";
import { TransactionTable } from "../../../types/transaction_table";
import moment from "moment";
import { getFromCatalog } from "../ModalBodyBilling/ModalBodyTrxBilling";
import { CatalogsEnum } from "../../shared/infrastructure/catalogs-enum";
import { MIN_AMOUNT_TYPE } from "../../shared/infrastructure/MinAmountTypeEnum";
import { translate } from "../../shared/infrastructure/LanguageCatatog";
import { FORMAT_DATE } from "../../shared/infrastructure/constants/ModalInfo";
import { KindEnum } from "../../shared/infrastructure/KindEnum";
import { LiquidationTypeEnum } from "../../shared/infrastructure/BillingTransactionTypeEnum";
import { StatusEnum } from "../../shared/infrastructure/StatusEnum";
import { ProcessorTypeEnum } from "../../shared/infrastructure/ProcessorTypeEnum";
import { ModalBodyTrxEnum } from "../../shared/infrastructure/ModalBodyTrxEnum";
import {
  getAdvanceSettings,
  getIvaValue,
} from "../ModalBodyInfo/utilsModalBody";
import { setChipLabelKind } from "../DashboardList/Table/DashboardBillingTable/DashboardTableRow/DashboardTableRow";
import { InvoiceModeEnum } from "../../shared/infrastructure/InvoiceModeEnum";
import { DetailBankColombia } from "./DetailBankColombia";
import {
  getValueWithDefault,
  isOcbValidate,
  isOcbValidateOwnerCustomer,
} from "../../shared/utils/utils";
import { ValueStatusFinalEnum } from "../../shared/infrastructure/CatalogFinalStatus";
import { OcbEnum } from "../../shared/infrastructure/OcbEnum";

export interface ModalBodyContentDetailProps {
  trxProps: InfoTrxProps;
  isMinAmount: boolean;
  isCharge?: boolean;
  country: CountryEnum;
  subSection: subSectionEnum;
  tabName: tabEnum;
  isHistoric: boolean;
}

export const formatDate = (
  trxDate: string | number,
  formatDate: string
): string => {
  return format(utcToZonedTime(trxDate, "Etc/UTC"), formatDate);
};

const getDate = (trx: TransactionTable | undefined, field: string) => {
  return format(moment(get(trx, field, new Date())).valueOf(), FORMAT_DATE);
};

const getNumber = (trx: TransactionTable, item: string) => {
  return (
    defaultTo(get(trx, item, 0), 0).toFixed(2) +
    " " +
    get(trx, "currency_code", "")
  );
};

const getNumberByCountry = (trx: TransactionTable, item: string) => {
  const country: string = get(trx, "country", "");
  const value: number =
    country === CountryEnum.colombia
      ? floor(defaultTo(get(trx, item), 0), 2)
      : defaultTo(get(trx, item), 0).toFixed(2);
  return value + " " + get(trx, "currency_code", "");
};

const getLiquidationAmount = (trx: TransactionTable) => {
  const amount: Number =
    Number(defaultTo(get(trx, "amount"), 0).toFixed(2)) +
    Number(defaultTo(get(trx, "wallet_config.rechargeAmount"), 0).toFixed(2));

  return amount.toFixed(2) + " " + get(trx, "currency_code", "");
};

const getNumberCamel = (trx: TransactionTable, item: string) => {
  return (
    defaultTo(get(trx, item, 0), 0).toFixed(2) + " " + get(trx, "currency", "")
  );
};

const getNumberValue = (trx: TransactionTable, value: number) => {
  return value.toFixed(2) + " " + get(trx, "currency_code", "");
};
const getCategoryLabel = (trxProps: InvoiceRecord) => {
  return (
    get(trxProps, "processor_type", "").includes(
      ProcessorTypeEnum.AGGREGATOR
    ) && (
      <Grid item container xs={12}>
        <Grid item xs={4}>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontSize: 15 }}
          >
            Categoría:
          </Typography>
        </Grid>
        <Grid item container xs={8}>
          {get(trxProps, "total_by_model", []).map(
            (category: {
              procesorModel: string;
              totalTransactions: number;
              totalAmount: number;
            }) => {
              return (
                get(category, "totalTransactions", 0) > 0 &&
                get(category, "totalAmount", 0) > 0 && (
                  <>
                    <Grid item xs={6}>
                      <Typography variant="body2">
                        {`${translate(get(category, "procesorModel", ""))}:`}
                      </Typography>
                    </Grid>
                    <Grid item container xs={6} style={{ marginBottom: 10 }}>
                      <Grid item xs={12}>
                        <Typography variant="body2">
                          {`${get(category, "totalTransactions", 0).toFixed(
                            2
                          )} transacciones`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2" color="textSecondary">
                          {getNumberValue(trxProps, category.totalAmount)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )
              );
            }
          )}
        </Grid>
      </Grid>
    )
  );
};

const getMinAmountType = (txInfo: InvoiceRecord) => {
  const type = get(txInfo, "charge_min", "");

  if (!Object.keys(MIN_AMOUNT_TYPE).includes(type)) return "";

  return MIN_AMOUNT_TYPE[type];
};
const getMinAmountTypeCamel = (txInfo: InvoiceRecord) => {
  const type = get(txInfo, "chargeMin", "");

  if (!Object.keys(MIN_AMOUNT_TYPE).includes(type)) return "";

  return MIN_AMOUNT_TYPE[type];
};

const renderGrid = (
  title: string,
  value: string | number,
  sizeGrid: 3 | 4 | 5 | 6 | 8 | 12,
  sizeTitle: 3 | 4 | 6 | 8 | 12,
  sizeValue: 3 | 4 | 6 | 8 | 12,
  textSize: { title: number; value: number } = { title: 15, value: 15 },
  obs?: string
) => {
  return (
    <Grid item container xs={sizeGrid}>
      <Grid item xs={sizeTitle}>
        <Typography
          variant="body2"
          style={{ fontSize: textSize.title }}
          color="textSecondary"
          id={"kindData"}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={sizeValue}>
        {value === StatusEnum.PENDING_VERIFY ||
        value === StatusEnum.INITIAL ||
        value === StatusEnum.VALIDATED ||
        value === StatusEnum.COMPLETED ||
        value === StatusEnum.FAILED_VALIDATE ? (
          <DetailBankColombia value={value} obs={obs} />
        ) : (
          <Typography
            variant="body2"
            style={{ fontSize: textSize.value, wordWrap: "break-word" }}
          >
            {value}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

const renderConfigField = (
  trxProps: InvoiceRecord,
  country: CountryEnum,
  field: fieldEnum,
  isHistoric: boolean
) => {
  const advance_settings = getAdvanceSettings(trxProps);

  switch (field) {
    case fieldEnum.commentLabel:
      const comments: string = get(
        advance_settings,
        "advanceSettingsReference.comments",
        ""
      );
      return (
        !isEmpty(comments) && renderGrid("Comentario:", comments, 12, 4, 8)
      );
    case fieldEnum.hesOcLabel:
      const code_reference: string = isHistoric
        ? get(advance_settings, "advanceSettingsReference.code_reference", "")
        : get(advance_settings, "advanceSettingsReference.codeReference", "");
      const option_reference: string = isHistoric
        ? get(advance_settings, "advanceSettingsReference.option_reference", "")
        : get(advance_settings, "advanceSettingsReference.optionReference", "");
      return (
        !isEmpty(code_reference) &&
        renderGrid(`${option_reference}:`, code_reference, 12, 4, 8)
      );
    case fieldEnum.expirationDateLabel:
      const expiration_date: string = isHistoric
        ? get(advance_settings, "advanceSettingsParameters.expiration_date", "")
        : get(advance_settings, "advanceSettingsParameters.expirationDate", "");
      return (
        !isNil(expiration_date) &&
        renderGrid(
          "Fecha de expiración:",
          formatDate(expiration_date, FORMAT_DATE),
          4,
          12,
          12
        )
      );
    case fieldEnum.paymentDateLabel:
      const payment_date: string = isHistoric
        ? get(advance_settings, "advanceSettingsParameters.payment_date", "")
        : get(advance_settings, "advanceSettingsParameters.paymentDate", "");
      return (
        !isNil(payment_date) &&
        renderGrid(
          "Fecha de pago:",
          formatDate(payment_date, FORMAT_DATE),
          4,
          12,
          12
        )
      );
    case fieldEnum.paymentMethodLabel:
      const payment_method_code: string = isHistoric
        ? get(advance_settings, "advanceSettingsParameters.payment_method", "")
        : get(advance_settings, "advanceSettingsParameters.paymentMethod", "");
      const pay_method = getPaymentMethodCatalog(country).find(
        (el) => el.key === payment_method_code
      );
      const pay_method_desc: string =
        country === CountryEnum.mexico
          ? get(pay_method, "value", "")
          : PAYMENT_METHOD_ENUM[payment_method_code];
      return (
        !isEmpty(payment_method_code) &&
        renderGrid(
          `${PAYMENT_METHOD_DESCRIPTION[country]}:`,
          pay_method_desc,
          4,
          12,
          12
        )
      );
    default:
      return <></>;
  }
};

const renderBillingInfoField = (
  trxProps: InvoiceRecord,
  field: fielInfoEnum | fieldInfoEnumCamel,
  isMinAmount: boolean
) => {
  switch (field) {
    case fieldInfoEnumCamel.amountLabel:
      return renderGrid(
        "Monto neto:",
        getNumberCamel(trxProps, "amount"),
        4,
        12,
        12
      );
    case fieldInfoEnumCamel.merchantNameLabel:
      return renderGrid(
        "Nombre del comercio:",
        get(trxProps, "merchantName", ""),
        4,
        12,
        12
      );
    case fieldInfoEnumCamel.emailLabel:
      return renderGrid("Email:", get(trxProps, "email", ""), 5, 12, 12);
    case fieldInfoEnumCamel.addressLabel:
      return renderGrid("Dirección:", get(trxProps, "address", ""), 12, 12, 12);
    case fieldInfoEnumCamel.descriptionLabel:
      return renderGrid(
        "Descripción:",
        get(trxProps, "description", ""),
        12,
        12,
        12
      );
    case fieldInfoEnumCamel.taxIdLabel:
      return renderGrid("Tax ID:", get(trxProps, "taxId", ""), 3, 12, 12);
    case fieldInfoEnumCamel.createdLabel:
      return renderGrid(
        "Fecha de creación:",
        getDate(trxProps, "created"),
        4,
        12,
        12
      );
    case fieldInfoEnumCamel.invoiceAmountTotalLabel:
      return renderGrid(
        "Monto total:",
        getNumberCamel(trxProps, "amount"),
        4,
        12,
        12
      );
    case fieldInfoEnumCamel.typeLabel:
      if (isMinAmount)
        return renderGrid("Tipo:", getMinAmountTypeCamel(trxProps), 4, 12, 12);
      return;
    case fieldInfoEnumCamel.bankLabel:
      return renderGrid(
        "Banco:",
        getFromCatalog(
          get(trxProps, "country") as CountryEnum,
          CatalogsEnum.Banks,
          get(trxProps, "bankId"),
          ValueStatusFinalEnum.Guion
        ),
        12,
        4,
        8,
        { title: 15, value: 15 }
      );
    case fieldInfoEnumCamel.accountTypeLabel:
      return renderGrid(
        "Tipo de cuenta:",
        getFromCatalog(
          get(trxProps, "country") as CountryEnum,
          CatalogsEnum.BankAccountTypes,
          get(trxProps, "accountType"),
          ValueStatusFinalEnum.Guion
        ),
        12,
        4,
        8,
        { title: 15, value: 15 }
      );
    case fieldInfoEnumCamel.itemLabel:
      return renderGrid(
        "Item:",
        get(trxProps, "item", "Comisión de transacción"),
        12,
        4,
        8,
        { title: 15, value: 15 }
      );
    case fieldInfoEnumCamel.accountNumberLabel:
      return renderGrid(
        "Nº de cuenta:",
        getValueWithDefault(
          trxProps,
          "accountBank",
          ValueStatusFinalEnum.Guion
        ),
        12,
        4,
        8,
        { title: 15, value: 15 }
      );
    case fieldInfoEnumCamel.accountNumberCIILabel:
      if ([CountryEnum.peru].includes(get(trxProps, "country", ""))) {
        return renderGrid(
          "Nº de CCI:",
          getValueWithDefault(
            trxProps,
            "interbankAccountCode",
            ValueStatusFinalEnum.Guion
          ),
          12,
          4,
          8,
          { title: 15, value: 15 }
        );
      }
      return;
    case fieldInfoEnumCamel.accountNumberCCILabel:
      if (get(trxProps, "country", "") === CountryEnum.mexico) {
        return renderGrid(
          "Nº de cuenta CCI:",
          getValueWithDefault(
            trxProps,
            "interbankAccountCode",
            ValueStatusFinalEnum.Guion
          ),
          12,
          4,
          8,
          { title: 15, value: 15 }
        );
      }
      return;
    case fieldInfoEnumCamel.ownerId:
      return renderGrid(
        "Owner ID:",
        isOcbValidateOwnerCustomer(
          get(trxProps, "isOcb", false),
          trxProps,
          "ownerId",
          OcbEnum.KUSHKI_ID
        ),
        5,
        12,
        12
      );
    case fieldInfoEnumCamel.ownerName:
      return renderGrid(
        "Owner name:",
        isOcbValidateOwnerCustomer(
          get(trxProps, "isOcb", false),
          trxProps,
          "ownerName",
          OcbEnum.KUSHKI
        ),
        4,
        12,
        12
      );
    case fieldInfoEnumCamel.isOcb && get(trxProps, "isOcb", false)
      ? fieldInfoEnumCamel.isOcb
      : false:
      return renderGrid(
        "Jerarquía:",
        lowerCase(
          isOcbValidate(
            get(trxProps, "isOcb", false),
            get(trxProps, "customerId", ""),
            get(trxProps, "merchantId", "")
          )
        ),
        3,
        12,
        12
      );
    case fieldInfoEnumCamel.customerIdHierarchy:
      return renderGrid(
        "Customer ID:",

        isOcbValidateOwnerCustomer(
          get(trxProps, "isOcb", false),
          trxProps,
          "customerIdHierarchy",
          get(trxProps, "merchantId", "")
        ),
        5,
        12,
        12
      );
    case fieldInfoEnumCamel.customerName:
      return renderGrid(
        "Customer name:",
        isOcbValidateOwnerCustomer(
          get(trxProps, "isOcb", false),
          trxProps,
          "customerName",
          get(trxProps, "merchantName", "")
        ),
        4,
        12,
        12
      );
    case fielInfoEnum.modelLabel:
      return renderGrid(
        "Modelo:",
        get(trxProps, "processor_type", "").includes(
          ProcessorTypeEnum.AGGREGATOR
        )
          ? ModalBodyTrxEnum.AGGREGADOR
          : ModalBodyTrxEnum.GATEWAY,
        12,
        4,
        8
      );
    case fielInfoEnum.categoryLabel:
      return getCategoryLabel(trxProps);
    default:
      return;
  }
};

const getIva = (amount: number, country: string, currencyCode: string) => {
  const amountIva: number = getIvaValue(amount, country);

  return country === CountryEnum.colombia
    ? floor(defaultTo(amountIva, 0), 2) + " " + currencyCode
    : defaultTo(amountIva, 0).toFixed(2) + " " + currencyCode;
};

const getTotal = (amount: number, country: string, currencyCode: string) => {
  const amountIva: number = getIvaValue(amount, country);
  const totalAmount: number = amount + amountIva;

  return country === CountryEnum.colombia
    ? floor(totalAmount, 2) + " " + currencyCode
    : totalAmount.toFixed(2) + " " + currencyCode;
};

const validateInvoiceMode = (invoiceMode: string) => {
  return isEmpty(invoiceMode) || invoiceMode !== InvoiceModeEnum.WITHOUT_IVA;
};

const getAmountLabel = (isCharge: boolean | undefined, invoiceMode: string) => {
  if (isCharge) return "Monto:";

  return invoiceMode === InvoiceModeEnum.WITHOUT_IVA
    ? "Monto total:"
    : "Monto bruto:";
};

const isNewFolio = (trxProps: InvoiceRecord): boolean =>
  (get(trxProps, "status", "") === StatusEnum.PENDING ||
    get(trxProps, "status", "") === StatusEnum.ANNUL ||
    get(trxProps, "status", "") === StatusEnum.PRE_PROCESSED) &&
  get(trxProps, "kind", "") === KindEnum.ANNUL_INVOICE;

const renderConfigInfoField = (
  trxProps: InvoiceRecord,
  field: fielInfoEnum,
  isMinAmount: boolean,
  isCharge: boolean | undefined,
  kind: KindEnum
) => {
  const isDispersion = trxProps.kind === KindEnum.DISPERSION;
  switch (field) {
    case fielInfoEnum.nuevoFolioFiscal:
      if (isNewFolio(trxProps)) {
        return renderGrid(
          "Nuevo Folio Fiscal:",
          get(trxProps, "uuid"),
          4,
          12,
          12
        );
      }
      return;
    case fielInfoEnum.createdLabel:
      return renderGrid(
        "Fecha de creación:",
        getDate(trxProps, "created"),
        4,
        12,
        12
      );
    case fielInfoEnum.processingDateLabel:
      return renderGrid(
        "Fecha procesado:",
        getDate(trxProps, "processing_date"),
        4,
        12,
        12
      );
    case fielInfoEnum.amountLabel:
      return renderGrid(
        getAmountLabel(isCharge, get(trxProps, "invoice_mode", "")),
        getNumber(trxProps, "amount"),
        4,
        12,
        12
      );
    case fielInfoEnum.invoiceAmountIvaLabel:
      if (validateInvoiceMode(get(trxProps, "invoice_mode", "")))
        return renderGrid(
          "Monto IVA TRX:",
          get(trxProps, "kind") !== KindEnum.INVOICE
            ? getIva(
                get(trxProps, "amount", 0),
                get(trxProps, "country", ""),
                get(trxProps, "currency_code", "")
              )
            : getNumberByCountry(trxProps, "invoice_amount_iva"),
          4,
          12,
          12
        );
      return;
    case fielInfoEnum.ivaKushkiCommissionLabel:
      return renderGrid(
        "IVA Comisión:",
        getNumber(trxProps, "iva_kushki_commission"),
        4,
        12,
        12
      );
    case fielInfoEnum.invoiceAmountTotalLabel:
      if (validateInvoiceMode(get(trxProps, "invoice_mode", "")))
        return renderGrid(
          "Monto total:",
          get(trxProps, "kind") !== KindEnum.INVOICE
            ? getTotal(
                get(trxProps, "amount", 0),
                get(trxProps, "country", ""),
                get(trxProps, "currency_code", "")
              )
            : getNumberByCountry(trxProps, "invoice_amount_total"),
          4,
          12,
          12
        );
      return;
    case fielInfoEnum.motivo:
      return renderGrid(
        "Motivo",
        get(trxProps.evidence, "reason", ""),
        4,
        12,
        12
      );
    case fielInfoEnum.totalAmountTotal:
      return renderGrid(
        isDispersion ? "Neto a liquidar:" : "Monto total:",
        getNumber(trxProps, "total_amount_trx"),
        4,
        12,
        12
      );
    case fielInfoEnum.chargeMethodLabel:
      return renderGrid(
        "Forma de cobro:",
        get(trxProps, "status", "") === StatusEnum.COMPLETED
          ? "Automático"
          : "Manual",
        4,
        12,
        12
      );
    case fielInfoEnum.referenceLabel:
      if (get(trxProps, "country", "") === CountryEnum.mexico) {
        return renderGrid(
          "Folio Fiscal:",
          get(trxProps, "invoice_number", ""),
          4,
          12,
          12
        );
      }
      if (kind === KindEnum.DEBIT_NOTE) {
        return renderGrid(
          "Referencia:",
          get(trxProps, "invoice_number", ""),
          4,
          12,
          12
        );
      }
      return;
    case fielInfoEnum.totalTransactionsLabel:
      if (get(trxProps, "total_number_transactions", null) === null) {
        return renderGrid(
          "Total de transacciones:",
          get(trxProps, "total_transactions", 0),
          4,
          12,
          12
        );
      } else {
        return renderGrid(
          "Total de transacciones:",
          get(trxProps, "total_number_transactions", 0),
          4,
          12,
          12
        );
      }
    case fielInfoEnum.totalTransactions:
      if (get(trxProps, "total_number_transactions", null) === null) {
        return renderGrid(
          "Cantidad de transacciones:",
          get(trxProps, "total_transactions", 0),
          4,
          12,
          12
        );
      } else {
        return renderGrid(
          "Cantidad de transacciones:",
          get(trxProps, "total_number_transactions", 0),
          4,
          12,
          12
        );
      }

    case fielInfoEnum.typeLabel:
      if (isMinAmount)
        return renderGrid("Tipo:", getMinAmountType(trxProps), 4, 12, 12);
      return;
    case fielInfoEnum.typeLabel2:
      return renderGrid("Tipo:", getMinAmountType(trxProps), 4, 12, 12);
    case fielInfoEnum.transactionType:
      return renderGrid(
        "Tipo de transacción:",
        setChipLabelKind(get(trxProps, "transaction_type", "").toLowerCase()),
        4,
        12,
        12
      );
    case fielInfoEnum.descriptionLabel:
      return renderGrid(
        "Descripción:",
        get(trxProps, "description", ""),
        12,
        12,
        12
      );
    case fielInfoEnum.bankLabel:
      return renderGrid(
        "Banco:",
        getFromCatalog(
          get(trxProps, "country") as CountryEnum,
          CatalogsEnum.Banks,
          get(trxProps, "bank_id"),
          ValueStatusFinalEnum.Guion
        ),
        12,
        4,
        8,
        { title: 15, value: 15 }
      );
    case fielInfoEnum.modelLabel:
      return renderGrid(
        "Modelo:",
        get(trxProps, "processor_type", "").includes(
          ProcessorTypeEnum.AGGREGATOR
        )
          ? ModalBodyTrxEnum.AGGREGADOR
          : ModalBodyTrxEnum.GATEWAY,
        12,
        4,
        8
      );
    case fielInfoEnum.categoryLabel:
      return getCategoryLabel(trxProps);
    case fielInfoEnum.accountTypeLabel:
      return renderGrid(
        "Tipo de cuenta:",
        getFromCatalog(
          get(trxProps, "country") as CountryEnum,
          CatalogsEnum.BankAccountTypes,
          get(trxProps, "account_type"),
          ValueStatusFinalEnum.Guion
        ),
        12,
        4,
        8,
        { title: 15, value: 15 }
      );
    case fielInfoEnum.accountNumberLabel:
      return renderGrid(
        "Nº de cuenta:",
        getValueWithDefault(
          trxProps,
          "account_bank",
          ValueStatusFinalEnum.Guion
        ),
        12,
        4,
        8,
        { title: 15, value: 15 }
      );
    case fielInfoEnum.initialStatusLabel:
      if (
        [
          StatusEnum.COMPLETED,
          StatusEnum.VALIDATED,
          StatusEnum.FAILED_VALIDATE,
          StatusEnum.PENDING_VERIFY,
          StatusEnum.INITIAL,
          StatusEnum.PENDING,
        ].includes(get(trxProps, "dispersion_status", "")) &&
        !isEmpty(get(trxProps, "dispersion_observation", ""))
      ) {
        return renderGrid(
          "Estado de integración con Banco de Bogotá:",
          get(trxProps, "dispersion_status", ""),
          12,
          4,
          8,
          { title: 15, value: 15 },
          get(trxProps, "dispersion_observation", "")
        );
      }
      return null;
    case fielInfoEnum.counterpartLabel:
      return renderGrid(
        "Contrapartida:",
        isCharge
          ? get(trxProps, "transaction_id", "")
          : get(trxProps, "counterpart", ""),
        12,
        4,
        8
      );
    case fielInfoEnum.currencyLabel:
      return renderGrid(
        "Moneda:",
        get(trxProps, "currency_code", ""),
        4,
        12,
        12
      );
    case fielInfoEnum.accountNumberCIILabel:
      if (get(trxProps, "country", "") === CountryEnum.peru) {
        return renderGrid(
          "Nº de CCI:",
          getValueWithDefault(
            trxProps,
            "interbank_account_code",
            ValueStatusFinalEnum.Guion
          ),
          12,
          4,
          8,
          { title: 15, value: 15 }
        );
      }
      return;
    case fielInfoEnum.accountNumberCCILabel:
      if (get(trxProps, "country", "") === CountryEnum.mexico) {
        return renderGrid(
          "Nº de cuenta CCI:",
          getValueWithDefault(
            trxProps,
            "interbank_account_code",
            ValueStatusFinalEnum.Guion
          ),
          12,
          4,
          8,
          { title: 15, value: 15 }
        );
      }
      return;
    case fielInfoEnum.merchantNameLabel:
      return renderGrid(
        "Nombre del comercio:",
        get(trxProps, "merchant_name", ""),
        4,
        12,
        12
      );
    case fielInfoEnum.taxIdLabel:
      return renderGrid("Tax ID:", get(trxProps, "tax_id", ""), 3, 12, 12);
    case fielInfoEnum.emailLabel:
      return renderGrid("Email:", get(trxProps, "email", ""), 5, 12, 12);
    case fielInfoEnum.addressLabel:
      return renderGrid("Dirección:", get(trxProps, "address", ""), 12, 12, 12);
    case fielInfoEnum.liquidationType:
      return renderGrid(
        "Tipo de liquidación:",
        get(trxProps, "is_exclusive", false)
          ? LiquidationTypeEnum.EXCLUSIVA
          : LiquidationTypeEnum.NORMAL,
        4,
        12,
        12
      );
    case fielInfoEnum.totalAmountTransactions:
      return renderGrid(
        "Monto de transacciones:",
        getNumber(trxProps, "transacted"),
        4,
        12,
        12
      );
    case fielInfoEnum.amountLabelDispersion:
      return renderGrid(
        "Monto Bruto:",
        getNumber(trxProps, "amount"),
        4,
        12,
        12
      );
    case fielInfoEnum.ivaKushkiCommissionLabel:
      return renderGrid(
        "Monto Neto:",
        getNumber(trxProps, "amount"),
        4,
        12,
        12
      );
    case fielInfoEnum.itemLabel:
      return renderGrid(
        "Item:",
        get(trxProps, "item", "Comisión de transacción"),
        4,
        12,
        12,
        { title: 15, value: 15 }
      );
    case fielInfoEnum.transactionId:
      if (get(trxProps, "country", "") === CountryEnum.peru) {
        return renderGrid(
          "Contrapartida:",
          get(trxProps, "transaction_id"),
          12,
          12,
          12
        );
      }
      return;
    case fielInfoEnum.transactionIdWalletConfig:
      if (get(trxProps, "wallet_config.transactionId", "") !== "")
        return renderGrid(
          "ID operación bancaria:",
          get(trxProps, "wallet_config.transactionId"),
          12,
          12,
          12
        );
      return;
    case fielInfoEnum.rechargePercentage:
      if (get(trxProps, "wallet_config.rechargePercentage", 0) !== 0)
        return renderGrid(
          "Valor a liquidar:",
          getLiquidationAmount(trxProps),
          4,
          12,
          12
        );
      return;
    case fielInfoEnum.rechargeAmount:
      if (get(trxProps, "wallet_config.rechargeAmount", 0) !== 0)
        return renderGrid(
          "Recarga a wallet:",
          getNumber(trxProps, "wallet_config.rechargeAmount"),
          4,
          12,
          12
        );
      return;
    case fielInfoEnum.rechargeFinal:
      if (get(trxProps, "wallet_config.rechargePercentage", 0) !== 0) {
        return renderGrid(
          "Liquidación final:",
          getNumber(trxProps, "amount"),
          4,
          12,
          12
        );
      }

      return;
    case fielInfoEnum.kushkiCommission:
      return renderGrid(
        "Kushki Comision:",
        getNumber(trxProps, fielInfoEnum.kushkiCommission),
        4,
        12,
        12
      );
    case fielInfoEnum.retencionesRiesgos:
      return renderGrid(
        "Retenciones por riesgos:",
        getNumber(trxProps, fielInfoEnum.retencionesRiesgos),
        4,
        12,
        12
      );
    case fielInfoEnum.retencionesLiberadas:
      return renderGrid(
        "Retenciones liberadas:",
        getNumber(trxProps, fielInfoEnum.retencionesLiberadas),
        4,
        12,
        12
      );
    case fielInfoEnum.ownerId:
      return renderGrid(
        "Owner ID:",
        isOcbValidateOwnerCustomer(
          get(trxProps, "is_ocb", false),
          trxProps,
          "owner_id",
          OcbEnum.KUSHKI_ID
        ),
        5,
        12,
        12
      );
    case fielInfoEnum.ownerName:
      return renderGrid(
        "Owner name:",
        isOcbValidateOwnerCustomer(
          get(trxProps, "is_ocb", false),
          trxProps,
          "owner_name",
          OcbEnum.KUSHKI
        ),
        4,
        12,
        12
      );
    case fielInfoEnum.isOcb:
      return renderGrid(
        "Jerarquía:",
        lowerCase(
          isOcbValidate(
            get(trxProps, "is_ocb", false),
            get(trxProps, "customer_id", ""),
            get(trxProps, "merchant_id", "")
          )
        ),
        3,
        12,
        12
      );
    case fielInfoEnum.customerIdHierarchy:
      return renderGrid(
        "Customer ID:",
        isOcbValidateOwnerCustomer(
          get(trxProps, "is_ocb", false),
          trxProps,
          "customer_id_hierarchy",
          get(trxProps, "merchant_id", "")
        ),
        5,
        12,
        12
      );
    case fielInfoEnum.customerName:
      return renderGrid(
        "Customer name:",
        isOcbValidateOwnerCustomer(
          get(trxProps, "is_ocb", false),
          trxProps,
          "customer_name",
          get(trxProps, "merchant_name", "")
        ),
        4,
        12,
        12
      );

    default:
      return;
  }
};

export const ModalBodyContentDetail: React.FC<ModalBodyContentDetailProps> = (
  props: ModalBodyContentDetailProps
) => {
  const {
    country,
    subSection,
    trxProps,
    tabName,
    isMinAmount,
    isCharge,
  } = props;
  const trx = trxProps.trxInfo;
  const isHistoric: boolean = defaultTo(trxProps.isHistoric, false);
  const transactionKind: KindEnum = isHistoric
    ? get(trx, "kind", KindEnum.INVOICE)
    : get(trx, "transaction.kind", KindEnum.INVOICE);
  const statusFlow = isHistoric
    ? get(trx, "status_flow", StatusFlowEnum.EXECUTOR)
    : get(trx, "transaction.statusFlow", StatusFlowEnum.EXECUTOR);

  if (tabName === tabEnum.advanceConfigTab) {
    return (
      <>
        {AdvanceConfigModalGeneric[transactionKind][subSection][statusFlow][
          country
        ].map((field: fieldEnum) =>
          renderConfigField(
            get(trxProps, "trxInfo", {}),
            country,
            field,
            isHistoric
          )
        )}
      </>
    );
  }

  if (tabName === tabEnum.infoTab) {
    return (
      <>
        {InfoModalGeneric[subSection][statusFlow][
          country
        ].map((field: fielInfoEnum) =>
          isHistoric
            ? renderConfigInfoField(
                trxProps.trxInfo!,
                field,
                isMinAmount,
                isCharge,
                transactionKind
              )
            : renderBillingInfoField(trxProps.trxInfo!, field, isMinAmount)
        )}
      </>
    );
  }
  return <></>;
};
