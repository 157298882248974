import React, { FC } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { IEditSectionProps } from "./EditSection.interfaces";
import { editSectionStyles as styles } from "./EditSection.styles";
import { ICheckboxObject } from "../TransactionForm/state/useTransactionSection.interfaces";
import { CircleInformation } from "../../assets/CircleInformation";

const EditSection: FC<IEditSectionProps> = ({
  title,
  description,
  checkBoxLabels,
  handleCheckOnChange,
  isCentralized,
  switchState,
  setSwitchValue,
  setTooltip,
}: IEditSectionProps) => {
  return (
    <Box width={1}>
      <Box sx={styles.container} display={"flex"} alignItems={"center"}>
        <Typography sx={styles.title} variant={"h6"}>
          {title}
        </Typography>
        <Switch
          disabled={isCentralized}
          size={"small"}
          checked={switchState}
          onChange={(event) => setSwitchValue(event.target.checked)}
        />
      </Box>
      <Typography variant={"body2"} color="text.dark">
        {description}
      </Typography>
      <FormGroup>
        <Grid container direction={"row"} sx={styles.checkBoxArea}>
          {checkBoxLabels.map(
            ({ label, checked, disabled, tooltip }: ICheckboxObject, index) => (
              <Grid item md={4} key={index}>
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      defaultChecked={checked}
                      disabled={isCentralized || disabled}
                      onChange={(item) =>
                        handleCheckOnChange(
                          item.target.checked,
                          item.target.value,
                          title
                        )
                      }
                    />
                  }
                  value={label}
                  label={
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      alignItems={"center"}
                    >
                      <Typography variant={"overline"}>{label}</Typography>
                      {tooltip && (
                        <Tooltip arrow title={setTooltip} placement={"bottom"}>
                          <Box
                            pl={1}
                            display={"flex"}
                            justifyContent={"center"}
                          >
                            <CircleInformation disabled={!switchState} />
                          </Box>
                        </Tooltip>
                      )}
                    </Box>
                  }
                />
              </Grid>
            )
          )}
        </Grid>
      </FormGroup>
    </Box>
  );
};

export default EditSection;
