import { ConfigEnum } from "../enums/ConfigEnum";
import { MerchantResponse } from "../../../types/get_merchant_response";
import { MerchantData } from "../../../types/merchant-data";

export function mapConfiguration(
  config: ConfigEnum,
  data: MerchantResponse | MerchantData
): MerchantResponse | MerchantData {
  const data_updated: MerchantResponse | MerchantData = { ...data };

  delete data_updated.clientType;
  delete data_updated.constitutionalCountry;
  delete data_updated.country;
  delete data_updated.created;
  delete data_updated.name;
  delete data_updated.publicMerchantId;
  delete data_updated.status;
  delete data_updated.updatedAt;
  delete data_updated.userAlias;
  delete data_updated.userName;

  switch (config) {
    case ConfigEnum.MIN_AMOUNT:
      delete data_updated.taxId;
      break;
  }

  return data_updated;
}
