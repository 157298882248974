import { CountryEnum } from "./CountryEnum";

export interface PaymentMethodCatalog {
  catalog?: PaymentMethod[];
}

export interface PaymentMethod {
  code: string;
  shortDesc: string;
  longDesc: string;
}

export enum PaymentMethodEnum {
  CASH = "cash",
  DEBIT = "debit",
  CREDIT = "credit",
  TRANSFER = "transfer",
  CHEQUE = "cheque",
}

export enum transactionTypeEnum {
  payout = "PAYOUT",
}

export enum PaymentMethodNameEnum {
  cash = "Efectivo",
  debit = "Tarjeta de débito",
  credit = "Tarjeta de crédito",
  transfer = "Transferencia",
  cheque = "Cheque",
}

export enum PaymentMethodDescriptionEnum {
  mxDescription = "Forma de pago",
  globalDescription = "Método de pago",
}

export const PAYMENT_METHOD_DESCRIPTION: Record<
  CountryEnum,
  PaymentMethodDescriptionEnum
> = {
  [CountryEnum.ecuador]: PaymentMethodDescriptionEnum.globalDescription,
  [CountryEnum.peru]: PaymentMethodDescriptionEnum.globalDescription,
  [CountryEnum.colombia]: PaymentMethodDescriptionEnum.globalDescription,
  [CountryEnum.chile]: PaymentMethodDescriptionEnum.globalDescription,
  [CountryEnum.mexico]: PaymentMethodDescriptionEnum.mxDescription,
};

export enum PaymentMethodCodeEnum {
  cash = "01",
  debit = "06",
  credit = "02",
  transfer = "08",
  cheque = "05",
}

type CountryPaymentMethod = Partial<Record<CountryEnum, PaymentMethodCatalog>>;

export const CountryPaymentMethodCatalog: CountryPaymentMethod = {
  [CountryEnum.mexico]: {
    catalog: [
      {
        code: "01",
        shortDesc: "cash",
        longDesc: "Efectivo",
      },
      {
        code: "02",
        shortDesc: "nominalCheck",
        longDesc: "Cheque nominativo",
      },
      {
        code: "03",
        shortDesc: "electronicTransfer",
        longDesc: "Transferencia electrónica de fondos",
      },
      {
        code: "04",
        shortDesc: "credit",
        longDesc: "Tarjeta de crédito",
      },
      {
        code: "05",
        shortDesc: "electronicWallet",
        longDesc: "Monedero electrónico",
      },
      {
        code: "06",
        shortDesc: "electronicMoney",
        longDesc: "Dinero electrónico",
      },
      {
        code: "08",
        shortDesc: "pantryVouchers",
        longDesc: "Vales de despensa",
      },
      {
        code: "12",
        shortDesc: "settlement",
        longDesc: "Dación en pago",
      },
      {
        code: "13",
        shortDesc: "surrogacyPayment",
        longDesc: "Pago por subrogación",
      },
      {
        code: "14",
        shortDesc: "consignmentPayment",
        longDesc: "Pago por consignación",
      },
      {
        code: "15",
        shortDesc: "condonation",
        longDesc: "Condonación",
      },
      {
        code: "17",
        shortDesc: "Compensation",
        longDesc: "Compensación",
      },
      {
        code: "23",
        shortDesc: "novation",
        longDesc: "Novación",
      },
      {
        code: "24",
        shortDesc: "confusion",
        longDesc: "Confusión",
      },
      {
        code: "25",
        shortDesc: "debtRemittance",
        longDesc: "Remisión de deuda",
      },
      {
        code: "26",
        shortDesc: "expiration",
        longDesc: "Prescripción o caducidad",
      },
      {
        code: "27",
        shortDesc: "creditorSatisfaction",
        longDesc: "A satisfacción del acreedor",
      },
      {
        code: "28",
        shortDesc: "debit",
        longDesc: "Tarjeta de débito",
      },
      {
        code: "29",
        shortDesc: "services",
        longDesc: "Tarjeta de servicios",
      },
      {
        code: "30",
        shortDesc: "advancesApplication",
        longDesc: "Aplicación de anticipos",
      },
      {
        code: "31",
        shortDesc: "paymentIntermediary",
        longDesc: "Intermediario pagos",
      },
      {
        code: "99",
        shortDesc: "toDefine",
        longDesc: "Por definir",
      },
    ],
  },
  [CountryEnum.peru]: {
    catalog: [
      {
        code: "01",
        shortDesc: "cash",
        longDesc: "Efectivo",
      },
      {
        code: "06",
        shortDesc: "debit",
        longDesc: "Tarjeta de débito",
      },
      {
        code: "02",
        shortDesc: "credit",
        longDesc: "Tarjeta de crédito",
      },
      {
        code: "08",
        shortDesc: "transfer",
        longDesc: "Transferencia",
      },
      {
        code: "05",
        shortDesc: "cheque",
        longDesc: "Cheque",
      },
    ],
  },
  [CountryEnum.colombia]: {
    catalog: [
      {
        code: "01",
        shortDesc: "cash",
        longDesc: "Efectivo",
      },
      {
        code: "06",
        shortDesc: "debit",
        longDesc: "Tarjeta de débito",
      },
      {
        code: "02",
        shortDesc: "credit",
        longDesc: "Tarjeta de crédito",
      },
      {
        code: "08",
        shortDesc: "transfer",
        longDesc: "Transferencia",
      },
      {
        code: "05",
        shortDesc: "cheque",
        longDesc: "Cheque",
      },
    ],
  },
  [CountryEnum.chile]: {
    catalog: [
      {
        code: "01",
        shortDesc: "cash",
        longDesc: "Efectivo",
      },
      {
        code: "06",
        shortDesc: "debit",
        longDesc: "Tarjeta de débito",
      },
      {
        code: "02",
        shortDesc: "credit",
        longDesc: "Tarjeta de crédito",
      },
      {
        code: "08",
        shortDesc: "transfer",
        longDesc: "Transferencia",
      },
      {
        code: "05",
        shortDesc: "cheque",
        longDesc: "Cheque",
      },
    ],
  },
  [CountryEnum.ecuador]: {
    catalog: [
      {
        code: "01",
        shortDesc: "cash",
        longDesc: "Efectivo",
      },
      {
        code: "06",
        shortDesc: "debit",
        longDesc: "Tarjeta de débito",
      },
      {
        code: "02",
        shortDesc: "credit",
        longDesc: "Tarjeta de crédito",
      },
      {
        code: "08",
        shortDesc: "transfer",
        longDesc: "Transferencia",
      },
      {
        code: "05",
        shortDesc: "cheque",
        longDesc: "Cheque",
      },
    ],
  },
};

export const getPaymentMethodCatalog = (
  country: string
): { key: string; value: string }[] => {
  return CountryPaymentMethodCatalog[country].catalog.map((obj: any) => {
    return { key: obj.code, value: obj.longDesc };
  });
};

export const PAYMENT_METHOD_ENUM: Record<
  PaymentMethodCodeEnum,
  PaymentMethodNameEnum
> = {
  [PaymentMethodCodeEnum.cash]: PaymentMethodNameEnum.cash,
  [PaymentMethodCodeEnum.debit]: PaymentMethodNameEnum.debit,
  [PaymentMethodCodeEnum.credit]: PaymentMethodNameEnum.credit,
  [PaymentMethodCodeEnum.transfer]: PaymentMethodNameEnum.transfer,
  [PaymentMethodCodeEnum.cheque]: PaymentMethodNameEnum.cheque,
};
