export enum PaymentMethodEnum {
  cash = "Efectivo",
  card = "Tarjeta",
  transfer = "Transferencia",
  "payouts-transfer" = "Transferencia",
  "payouts-cash" = "Efectivo",
}
export enum PaymentMethodTypeEnum {
  cash = "cash",
  card = "card",
  transfer = "transfer",
}
