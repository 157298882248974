import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import SimpleChip from "../SimpleChip/SimpleChip";
import theme from "../../../theme";
import { get } from "lodash";
import { Add } from "@material-ui/icons";
import {
  FormatFileAcceptedCatalog,
  FormatUpload,
} from "../../../shared/infrastructure/Enums/FormatUpload";

export interface IFileUpload {
  prop: string;
  label?: string;
  files?: KshFile[];

  onChange(value: { prop: string; files?: KshFile }): void;

  onDelete(
    resultFiles:
      | { prop: string; files?: KshFile[] }
      | { prop: string; files?: KshFile }
  ): void;

  handlerError: (payload: boolean) => void;

  maxSize?: number;
  multiple?: boolean;
  formatUpload?: FormatUpload;
}

export interface KshFile {
  id: string;
  name: string;
  extension?: string;
  base64: string;
  file?: string;
}

const generateNameFiles = (files: KshFile[]) => {
  if (files && files[0] != undefined)
    return files.map((f: KshFile) => ({
      id: f.name.replace(/ /g, "_"),
      name: f.name,
      base64: f.base64,
      extension: f.extension,
    }));
  return;
};
/* istanbul ignore next */
const getFileType = (nameFile: string) => {
  const parts: string[] = nameFile.split(".");
  return parts[parts.length - 1];
};

export const FileUpload: React.FC<IFileUpload> = (props: IFileUpload) => {
  const [nameFiles, setNameFiles] = useState<KshFile[] | undefined>(
    generateNameFiles(get(props, "files", []))
  );
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setNameFiles(generateNameFiles(get(props, "files", [])));
  }, [props.files]);

  const handleDelete = (e: React.MouseEvent<HTMLElement>, id: string) => {
    e.preventDefault();
    if (!props.multiple) {
      props.onDelete({ files: undefined, prop: props.prop });
      setNameFiles([]);
      return;
    }
    const copy_names = nameFiles!.filter((f: KshFile) => f.id !== id);
    props.onDelete({ prop: props.prop, files: copy_names });
    setNameFiles([...copy_names]);
  };

  const handleCaptureFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();
    setError(false);

    if (!event.target.files || event.target.files.length === 0) return;

    if (!isValidFile(event.target.files[0], props.maxSize)) {
      setError(true);
      props.handlerError(true);
      return;
    }

    const all_files = event.target.files;
    reader.readAsDataURL(event.target.files[0]);
    /* istanbul ignore next */
    reader.onload = (): void => {
      const base64_file:
        | string
        | undefined
        | null = reader.result!.toString().split(",")[1];
      const ksh_file: KshFile = {
        id: all_files[0].name.replace(/ /g, "_"),
        base64: base64_file!,
        name: all_files[0].name,
        extension: `.${getFileType(all_files[0].name)}`,
      };
      props.onChange({ prop: props.prop, files: ksh_file });
      props.handlerError(false);
      if (!props.multiple) {
        setNameFiles([ksh_file]);

        return;
      }
      if (nameFiles) {
        const copy_name_files: KshFile[] = [...nameFiles];
        copy_name_files.push(ksh_file);
        setNameFiles(copy_name_files);
      }
    };
  };

  const isValidFile = (file: File, maxSize?: number) => {
    const is_valid = 0 < file.size;
    const file_in_mb = file.size / 1000048;
    if (maxSize) return file_in_mb < maxSize && is_valid;
    return is_valid;
  };

  return (
    <Grid container>
      {
        <Grid item xs={12}>
          <label htmlFor={props.prop}>
            <input
              id={props.prop}
              color="primary"
              accept={[
                FormatFileAcceptedCatalog[
                  get(props, "formatUpload", FormatUpload.csv)
                ],
              ].join(",")}
              type="file"
              onChange={handleCaptureFile}
              style={{ display: "none" }}
            />
            <Grid container>
              <Grid item xs={12} style={{ paddingRight: 10 }}>
                <Box display="flex">
                  <Box>
                    <Button
                      variant="outlined"
                      component="span"
                      style={{
                        color: theme.palette.primary.dark,
                        border: `1px solid ${theme.palette.primary.dark}`,
                      }}
                      size={"medium"}
                      startIcon={<Add />}
                    >
                      Agregar Archivo
                    </Button>
                  </Box>
                  {nameFiles && nameFiles.length > 0 ? (
                    nameFiles.map((kshFile: KshFile) => (
                      <SimpleChip
                        key={kshFile.id}
                        type={"success"}
                        onDelete={(e) => handleDelete(e, kshFile.id)}
                        label={kshFile.name}
                        style={{
                          marginLeft: 7,
                          width: "60%",
                          color: "rgba(41,48,54,1)",
                        }}
                        size="medium"
                      />
                    ))
                  ) : (
                    <Box pl={1}>
                      <Typography
                        variant="body1"
                        style={{ paddingTop: 7 }}
                        gutterBottom
                      >
                        No has seleccionado un documento.
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </label>
          {error ? (
            <Typography
              id="typography_error"
              variant="caption"
              color="error"
              display="block"
              gutterBottom
              style={{ marginTop: 3 }}
            >
              El tamaño del archivo excede el límite permitido
            </Typography>
          ) : null}
        </Grid>
      }
    </Grid>
  );
};
