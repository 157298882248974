import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const IconApproved: FC<SvgIconProps> = (p) => (
  <SvgIcon
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...p}
  >
    <path
      d="M16.5 8.47201V9.16201C16.4991 10.7793 15.9754 12.353 15.007 13.6484C14.0386 14.9437 12.6775 15.8914 11.1265
        16.3499C9.57557 16.8085 7.91794 16.7534 6.40085 16.1929C4.88376 15.6325 3.58849 14.5966 2.70822 13.2398C1.82795
        11.883 1.40984 10.2781 1.51626 8.66424C1.62267 7.05043 2.24791 5.51425 3.29871 4.28481C4.34951 3.05537 5.76959
        2.19854 7.34714 1.84212C8.92469 1.48569 10.5752 1.64876 12.0525 2.30701"
      stroke="#0DC298"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 3.16211L9 10.6696L6.75 8.41961"
      stroke="#0DC298"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
