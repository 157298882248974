import React from "react";
import { Badge, Button, createStyles, Typography } from "@material-ui/core";
import { Filter as IconFilter } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";
import { Filter } from "@kushki/frontend-molecules/filter";
import { defaultFilters } from "../../containers/HistoryIndex/state/useHistoryIndexState";
import { isEmpty } from "lodash";
import { IFilterResponse } from "@kushki/frontend-molecules/filter-container";

export interface IFilterComponent {
  text: string;
  filters: IOptionFilter[];
  onApplyFilter: (
    search: { filter: object; rangeAmount?: object },
    filters: IOptionFilter[],
    text: string
  ) => void;
  isMobile: boolean;
}

interface IOptionFilter {
  id: string;
  title: string;
  options: { key: string; label: string; selected: boolean }[];
}

const useStyles = makeStyles(() =>
  createStyles({
    kshBtnFilter: {
      marginTop: "4px",
      height: "39px",
      padding: "7px 15px",
      backgroundColor: "#ffffff",
    },
    badgedFilter: {
      "& .MuiBadge-anchorOriginTopRightRectangle": {
        transform: "scale(1) translate(30%, -20%)",
      },
      "& .MuiBadge-colorSecondary": {
        color: "rgba(240, 244, 248, 1)",
        backgroundColor: "rgba(13,194,152,1)",
      },
    },
  })
);

const getCounterFilter = (filters: IOptionFilter[], text: string): number => {
  let options: { key: string; label: string; selected: boolean }[] = [];
  filters.forEach((filter: IOptionFilter) => {
    options = options.concat(filter.options);
  });
  let search_text: number = isEmpty(text) ? 0 : 1;

  return (
    options.filter(
      (option: { key: string; label: string; selected: boolean }) =>
        option.selected
    ).length + search_text
  );
};

const FilterComponent = (props: IFilterComponent): JSX.Element => {
  const classes = useStyles();
  const [openModal, setOpenModal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [localState, setLocalState] = React.useState<{
    counterFilter: number;
  }>({
    counterFilter: getCounterFilter(props.filters, props.text),
  });
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenModal(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenModal(false);
  };

  const handleApplyFilters = (response: IFilterResponse) => {
    let export_filter_selected: {
      filter: object;
    } = { filter: {} };
    const text_search: string =
      response.searchFilter !== undefined ? response.searchFilter!.text! : "";

    response.listFilter.forEach((filter: IOptionFilter) => {
      const filter_parent: string[] = filter.options
        .filter((option) => option.selected)
        .map((option) => option.key);

      export_filter_selected.filter[filter.id] = filter_parent.join("|");
    });
    setLocalState({
      ...localState,
      counterFilter: getCounterFilter(response.listFilter, text_search),
    });
    handleClose();
    props.onApplyFilter(
      export_filter_selected,
      response.listFilter,
      text_search
    );
  };

  const handleClearFilters = () => {
    const auxFilters: IOptionFilter[] = defaultFilters;

    auxFilters.forEach((filter) => {
      filter.options.forEach((option) => {
        option.selected = false;
      });
    });
    handleApplyFilters({
      listFilter: auxFilters,
      searchFilter: {
        text: "",
      },
    });
    setLocalState({
      ...localState,
      counterFilter: 0,
    });
  };

  return (
    <React.Fragment>
      <Badge
        color="secondary"
        badgeContent={localState.counterFilter}
        className={classes.badgedFilter}
      >
        <Button
          className={classes.kshBtnFilter}
          id="buttonApplyFilter"
          onClick={handleClick}
          variant="outlined"
          startIcon={<IconFilter xlinkTitle={"Filtrar"} size={16} />}
        >
          <Typography variant="body1">Filtrar</Typography>
        </Button>
      </Badge>
      <Filter
        isMobile={props.isMobile}
        openModal={openModal}
        openPopover={open}
        anchorPopover={anchorEl}
        idPopover={id}
        handleClose={handleClose}
        handleApplyFilters={handleApplyFilters}
        handleClearFilters={handleClearFilters}
        filters={{
          listFilters: {
            multiple: true,
            filters: [...props.filters],
          },
          searchInput: {
            label: "Buscar usuario...",
            text: props.text,
          },
        }}
      />
    </React.Fragment>
  );
};

export default FilterComponent;
