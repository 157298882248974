import { BodyTabField } from "../ModalBodyTab/ModalBodyTab.interfaces";
import { TransactionData } from "../../../../types/transaction_data";

export interface TabContentBlockProps {
  blockType: TabContentBlockType;
  tabIndex: number;
  selectedTab: number;
  transaction?: TransactionData;
  lines?: BodyTabField[];
  timeLine?: TimelineOptions;
}

export interface TimelineOptions {
  icon: TimelineIconEnum;
  date: string;
  time: string;
  user: string;
  title: string;
  reason: string;
}

export enum TimelineActionEnum {
  RPY = "RPY",
  GBCK = "GBCK",
  CNCL = "CNCL",
  OMT = "OMT",
  APRRFN = "APRRFN",
  DCLRFN = "DCLRFN",
  APRGBCK = "APRGBCK",
  DCLGBCK = "DCLGBCK",
  APROMT = "APROMT",
  DCLOMT = "DCLOMT",
  APRCNL = "APRCNL",
  DCLCNL = "DCLCNL",
  RTRY = "RTRY",
  UNEXPECTED = "UNEXPECTED",
  NONE = "",
}

export enum TimelineResponseEnum {
  FIN_OPS_APPROVED = "Aprobado por FinOps",
  FIN_OPS_DECLINED = "Declinado por FinOps",
  FIN_OPS_SEND = "Enviado por FinOps",
  PROCESSOR_APPROVED = "Aprobado por el procesador",
  PROCESSOR_DECLINED = "Declinado por el procesador",
  PROCESSOR_SEND = "Enviado al procesador",
  RETURN_DASHBOARD = "Regresa al dashboad del ejecutor",
  NOT_DEFINED = "N/A",
  NONE = "",
}

export enum TimelineIconEnum {
  RPY = "RPY",
  GBCK = "GBCK",
  CNCL = "CNCL",
  OMT = "OMT",
  APRRFN_SEND = "APRRFN_SEND",
  APRRFN_APPROVED = "APRRFN_APPROVED",
  APRRFN_DECLINED = "APRRFN_DECLINED",
  APRGBCK_SEND = "APRGBCK_SEND",
  APRGBCK_APPROVED = "APRGBCK_APPROVED",
  APRGBCK_DECLINED = "APRGBCK_DECLINED",
  APRCNL_SEND = "APRCNL_SEND",
  APRCNL_APPROVED = "APRCNL_APPROVED",
  APRCNL_DECLINED = "APRCNL_DECLINED",
  APROMT = "APROMT",
  DCLRFN = "DCLRFN",
  DCLGBCK = "DCLGBCK",
  DCLCNL = "DCLCNL",
  DCLOMT = "DCLOMT",
  RTRY = "RTRY",
  UNEXPECTED = "UNEXPECTED",
  NONE = "",
}

export const CONCILIATION_ACTION_OPTIONS = {
  [TimelineActionEnum.RPY]: {
    [TimelineResponseEnum.NONE]: TimelineIconEnum.RPY,
  },
  [TimelineActionEnum.GBCK]: {
    [TimelineResponseEnum.NONE]: TimelineIconEnum.GBCK,
  },
  [TimelineActionEnum.CNCL]: {
    [TimelineResponseEnum.NONE]: TimelineIconEnum.CNCL,
  },
  [TimelineActionEnum.OMT]: {
    [TimelineResponseEnum.NONE]: TimelineIconEnum.OMT,
  },
  [TimelineActionEnum.APRRFN]: {
    [TimelineResponseEnum.PROCESSOR_SEND]: TimelineIconEnum.APRRFN_SEND,
    [TimelineResponseEnum.PROCESSOR_APPROVED]: TimelineIconEnum.APRRFN_APPROVED,
    [TimelineResponseEnum.PROCESSOR_DECLINED]: TimelineIconEnum.APRRFN_DECLINED,
  },
  [TimelineActionEnum.APRGBCK]: {
    [TimelineResponseEnum.FIN_OPS_SEND]: TimelineIconEnum.APRGBCK_SEND,
    [TimelineResponseEnum.FIN_OPS_APPROVED]: TimelineIconEnum.APRGBCK_APPROVED,
    [TimelineResponseEnum.FIN_OPS_DECLINED]: TimelineIconEnum.APRGBCK_DECLINED,
  },
  [TimelineActionEnum.APRCNL]: {
    [TimelineResponseEnum.FIN_OPS_SEND]: TimelineIconEnum.APRCNL_SEND,
    [TimelineResponseEnum.FIN_OPS_APPROVED]: TimelineIconEnum.APRCNL_APPROVED,
    [TimelineResponseEnum.FIN_OPS_DECLINED]: TimelineIconEnum.APRCNL_DECLINED,
  },
  [TimelineActionEnum.APROMT]: {
    [TimelineResponseEnum.NOT_DEFINED]: TimelineIconEnum.APROMT,
    [TimelineResponseEnum.NONE]: TimelineIconEnum.APROMT,
  },
  [TimelineActionEnum.DCLRFN]: {
    [TimelineResponseEnum.NOT_DEFINED]: TimelineIconEnum.DCLRFN,
    [TimelineResponseEnum.NONE]: TimelineIconEnum.DCLRFN,
  },
  [TimelineActionEnum.DCLGBCK]: {
    [TimelineResponseEnum.NOT_DEFINED]: TimelineIconEnum.DCLGBCK,
    [TimelineResponseEnum.NONE]: TimelineIconEnum.DCLGBCK,
  },
  [TimelineActionEnum.DCLCNL]: {
    [TimelineResponseEnum.NOT_DEFINED]: TimelineIconEnum.DCLCNL,
    [TimelineResponseEnum.NONE]: TimelineIconEnum.DCLCNL,
  },
  [TimelineActionEnum.DCLOMT]: {
    [TimelineResponseEnum.RETURN_DASHBOARD]: TimelineIconEnum.DCLOMT,
    [TimelineResponseEnum.NOT_DEFINED]: TimelineIconEnum.DCLOMT,
    [TimelineResponseEnum.NONE]: TimelineIconEnum.DCLOMT,
  },
  [TimelineActionEnum.RTRY]: {
    [TimelineResponseEnum.RETURN_DASHBOARD]: TimelineIconEnum.RTRY,
    [TimelineResponseEnum.NONE]: TimelineIconEnum.RTRY,
  },
  [TimelineActionEnum.UNEXPECTED]: {
    [TimelineResponseEnum.NONE]: TimelineIconEnum.UNEXPECTED,
  },
  [TimelineActionEnum.NONE]: {
    [TimelineResponseEnum.NONE]: TimelineIconEnum.NONE,
  },
};

export const TimelineIconText = {
  [TimelineIconEnum.RPY]: "Acción Reembolsar",
  [TimelineIconEnum.GBCK]: "Acción Devolver",
  [TimelineIconEnum.CNCL]: "Acción Cancelar",
  [TimelineIconEnum.OMT]: "Acción Omitir",
  [TimelineIconEnum.APRRFN_SEND]: "Reembolso enviado al procesador",
  [TimelineIconEnum.APRRFN_APPROVED]: "Reembolso Aprobado",
  [TimelineIconEnum.APRRFN_DECLINED]: "Reembolso Declinado",
  [TimelineIconEnum.APRGBCK_SEND]: "Devolución enviada a FinOps",
  [TimelineIconEnum.APRGBCK_APPROVED]: "Devolución Aprobada",
  [TimelineIconEnum.APRGBCK_DECLINED]: "Devolución Declinada",
  [TimelineIconEnum.APRCNL_SEND]: "Cancelación enviada a FinOps",
  [TimelineIconEnum.APRCNL_APPROVED]: "Cancelación Aprobada",
  [TimelineIconEnum.APRCNL_DECLINED]: "Cancelación Declinada",
  [TimelineIconEnum.APROMT]: "Omitir Autorizado",
  [TimelineIconEnum.DCLRFN]: "Reembolso no autorizado",
  [TimelineIconEnum.DCLGBCK]: "Devolución no autorizado",
  [TimelineIconEnum.DCLCNL]: "Cancelación no autorizado",
  [TimelineIconEnum.DCLOMT]: "Omitir no autorizado",
  [TimelineIconEnum.RTRY]: "Reembolso Declinado",
  [TimelineIconEnum.NONE]: "Solicitud de reembolso",
  [TimelineIconEnum.UNEXPECTED]: "",
};

export enum TabContentBlockType {
  SIMPLE_INFO = "SIMPLE_INFO",
  TIME_LINE_ROW = "TIME_LINE_ROW",
  NONE = "NONE",
}
