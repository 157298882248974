import { InfoTrxProps } from "./ModalBodyTabs";
import { defaultTo, get } from "lodash";
import { KindEnum } from "../../shared/infrastructure/KindEnum";
import { createStyles, Grid, Paper, Typography } from "@material-ui/core";
import { ModalBodyContentDetail } from "../ModalTabs/ModalBodyContentDetail";
import {
  subSectionEnum,
  tabEnum,
} from "../../shared/infrastructure/AdvanceConfigModal";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ModalBodyNoteCreditInfo from "./ModalBodyNoteCredit";
import { formatDate } from "../../shared/utils/date-time-util";

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      boxShadow: "none !important",
      borderRadius: "5px !important",
      backgroundColor: "#F7FAFC !important",
      marginBottom: "30px !important",
      marginTop: "30px !important",
    },
    grid: {
      paddingTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
    },
  })
);

const formDate: string = "dd MMM'.' yyyy";

const PaperContentDetail = (
  props: InfoTrxProps,
  subSection: subSectionEnum,
  isCharge: boolean = false
) => {
  const classes = useStyles();
  const trx = props.trxInfo;

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2} color="secondary" className={classes.grid}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <ModalBodyContentDetail
              tabName={tabEnum.infoTab}
              trxProps={props}
              country={get(trx, "country", CountryEnum.chile)}
              subSection={subSection}
              isMinAmount={props.isMinAmount}
              isCharge={isCharge}
              isHistoric={props.isHistoric}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const ModalBodyChile = (props: InfoTrxProps) => {
  const trx = props.trxInfo;
  const classes = useStyles();

  const ModalKind = () => {
    switch (get(trx, "kind", "")) {
      case KindEnum.INVOICE:
        return (
          <>
            {PaperContentDetail(props, subSectionEnum.infoCard1)}
            {PaperContentDetail(props, subSectionEnum.infoCard2)}
            {get(props, "trxInfo.is_ocb", false)
              ? PaperContentDetail(props, subSectionEnum.infoCard4)
              : PaperContentDetail(props, subSectionEnum.infoCard2_0)}
            {PaperContentDetail(props, subSectionEnum.infoCard3)}
          </>
        );
      case KindEnum.CHARGE:
        return (
          <>
            {PaperContentDetail(props, subSectionEnum.infoCardCharge1, true)}
            {PaperContentDetail(props, subSectionEnum.infoCardCharge2, true)}
            {get(props, "trxInfo.is_ocb", false)
              ? PaperContentDetail(props, subSectionEnum.infoCard4)
              : PaperContentDetail(props, subSectionEnum.infoCard2_0)}
            {PaperContentDetail(props, subSectionEnum.infoCardCharge3, true)}
          </>
        );
      case KindEnum.RECEIVABLE:
        return (
          <>
            {PaperContentDetail(props, subSectionEnum.infoCardReceivable1)}
            {PaperContentDetail(props, subSectionEnum.infoCardReceivable2)}
            {PaperContentDetail(props, subSectionEnum.infoCard3)}
          </>
        );
      case KindEnum.CREDIT_NOTE:
        return <ModalBodyNoteCreditInfo trxInfo={props.trxInfo} />;
      case KindEnum.DISPERSION:
        return (
          <>
            {PaperContentDetail(props, subSectionEnum.infoCardDispersion1)}
            {PaperContentDetail(props, subSectionEnum.infoCardDispersion2)}
            {get(props, "trxInfo.is_ocb", false)
              ? PaperContentDetail(props, subSectionEnum.infoCard4)
              : PaperContentDetail(props, subSectionEnum.infoCard2_0)}
            {PaperContentDetail(props, subSectionEnum.infoCard3)}
          </>
        );
      default:
        return (
          <div>
            <Paper className={classes.paper}>
              <Grid
                container
                spacing={2}
                color="secondary"
                className={classes.grid}
              >
                <Grid item xs={12} spacing={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={4} spacing={2}>
                      <Typography variant="body2" color="textSecondary">
                        Fecha de creación
                      </Typography>
                      <Typography variant="body2">
                        {formatDate(props.trxInfo?.created, formDate)}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} spacing={2}>
                      <Typography variant="body2" color="textSecondary">
                        Fecha procesado
                      </Typography>
                      <Typography variant="body2">
                        {formatDate(
                          defaultTo(
                            props.trxInfo?.processing_date,
                            props.trxInfo?.calculate_cycle
                          ),
                          formDate
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.paper}>
              <Grid
                container
                spacing={2}
                color="secondary"
                className={classes.grid}
              >
                <Grid item xs={4} spacing={2}>
                  <Typography variant="body2" color="textSecondary">
                    Razón social
                  </Typography>
                  <Typography variant="body2">
                    {props.trxInfo?.social_reason}
                  </Typography>
                </Grid>
                <Grid item xs={4} spacing={2}>
                  <Typography variant="body2" color="textSecondary">
                    Tax ID
                  </Typography>
                  <Typography variant="body2">
                    {props.trxInfo?.tax_id}
                  </Typography>
                </Grid>
                <Grid item xs={4} spacing={2}>
                  <Typography variant="body2" color="textSecondary">
                    Email
                  </Typography>
                  <Typography variant="body2">
                    {props.trxInfo?.email}
                  </Typography>
                </Grid>
                <Grid item xs={12} spacing={2}>
                  <Typography variant="body2" color="textSecondary">
                    Dirección:
                  </Typography>
                  <Typography variant="body2">
                    {props.trxInfo?.address}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </div>
        );
    }
  };

  return <ModalKind />;
};

export default ModalBodyChile;
