import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  CatalogsArray,
  CatalogsRecord,
} from "../../shared/infrastructure/Enums/CatalogEnum";
import { CatalogsNameEnum } from "../../shared/infrastructure/Enums/CatalogsNameEnum";
import useStyles from "../CatalogFormDialog/CatalogFormDialog.styles";
import { CatalogFormDialogProps } from "./CatalogFormDialog.interfaces";
import { useCatalogFormDialogState } from "./state/useCatalogFormDialogState";
import { get } from "lodash";
import { ErrorsEnum } from "../../shared/infrastructure/Enums/ErrorsEnum";
import {
  ActionEnum,
  ButtonNameCatalogConfirm,
} from "../../shared/infrastructure/Enums/ActionEnum";
import { CatalogsTextEnum } from "../../shared/infrastructure/Enums/CatalogsTextEnum";

export const CatalogFormDialog: React.FC<CatalogFormDialogProps> = (
  props: CatalogFormDialogProps
) => {
  const classes = useStyles();
  const {
    action,
    code,
    description,
    optionSelected,
    disableButton,
    errorInputCode,
    errorInputDescription,
    messageCodeError,
    handleCatalogCode,
    handleCatalogDescription,
    handleOptionSelected,
    handleCloseDialog,
    handleCreateCatalogItem,
    handleErrorDescription,
    handleErrorCode,
    handleFormCompleted,
  } = useCatalogFormDialogState();
  const { isOpenPopup, isLoading } = props;
  const isDisableButton = (): boolean => disableButton || isLoading;
  const isDisableCode = (): boolean => action === ActionEnum.UPDATE;

  return (
    <Dialog
      open={isOpenPopup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"md"}
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" color="primary" className={classes.title}>
              {action === ActionEnum.UPDATE
                ? "Editar opción de catálogo"
                : "Carga de catálogos con formulario"}
            </Typography>
          </Grid>
          {action !== ActionEnum.UPDATE && (
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.subtitle}>
                Completa los campos de la opción de catálogo que deseas asignar.
              </Typography>
            </Grid>
          )}
          {action !== ActionEnum.UPDATE && (
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="page-size" className={classes.selectLabel}>
                  Catálogo
                </InputLabel>
                <Select
                  variant={"outlined"}
                  labelId={"page-size"}
                  label={"Catálogo"}
                  value={optionSelected}
                  onChange={(event: React.ChangeEvent<{}>) => {
                    handleOptionSelected(get(event, "target.value", ""));
                    handleFormCompleted();
                  }}
                  onBlur={handleErrorCode}
                >
                  {CatalogsArray.map((catalogCode: CatalogsNameEnum) => (
                    <MenuItem key={catalogCode} value={catalogCode}>
                      {CatalogsRecord[catalogCode]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={6}>
            <TextField
              label="Código"
              name="taxpayerType"
              variant="outlined"
              value={code}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleCatalogCode(event.target.value)
              }
              onBlur={() => handleErrorCode()}
              onKeyUp={() => handleErrorCode()}
              error={errorInputCode}
              helperText={messageCodeError}
              disabled={isDisableCode()}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={CatalogsTextEnum.DESCRIPTION}
              name="taxpayerType"
              variant="outlined"
              value={description}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleCatalogDescription(event.target.value)
              }
              onBlur={() => handleErrorDescription()}
              onKeyUp={() => handleErrorDescription()}
              error={errorInputDescription}
              helperText={
                errorInputDescription ? ErrorsEnum.FIELD_REQUIRED : ""
              }
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          color={"primary"}
          onClick={() => handleCloseDialog()}
          className={classes.btnChangeOption}
          disabled={isLoading}
        >
          Cancelar
        </Button>
        <Button
          color={"primary"}
          onClick={() => handleCreateCatalogItem()}
          className={classes.btnAdd}
          disabled={isDisableButton()}
        >
          {action
            ? ButtonNameCatalogConfirm[action]
            : ButtonNameCatalogConfirm[ActionEnum.NEW]}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
