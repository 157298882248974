import { CardBrandsEnum } from "../enums/CardBrandsEnum";

export const CreditCardDict: Record<CardBrandsEnum, string> = {
  alia: "Alia",
  amex: "AMEX",
  bbva: "BBVA",
  carnet: "Carnet",
  default: "",
  diners: "Diners",
  discover: "Discover",
  jcb: "JCB",
  masterCard: "MasterCard",
  visa: "Visa",
  [CardBrandsEnum.UNIONPAY]: "Union Pay",
  [CardBrandsEnum.MAGNA]: "Magna",
};
