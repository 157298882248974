import { get } from "lodash";
import { DEFAULT_PATHS } from "../constants/labels/branch_manual_creation_labels";
import { EditStatusEnum } from "../enums/EditStatusEnum";
import { ITableRowProps } from "../../components/Table/TableSimple/interfaces";

export const verifyAllBranchesNotModified = (rows: ITableRowProps[]): boolean =>
  rows.every(
    (rowItm) =>
      get(rowItm, DEFAULT_PATHS.CELL_ROW_EDIT_STATUS, "") ===
      EditStatusEnum.not_modified
  );
