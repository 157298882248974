import { routes } from "../routes";
import { CountryValueEnum } from "../Enums/CountryEnum";
export const BreadCrumsItems = {
  GlobalConfig: {
    label: "Configuración global",
    url: routes.GLOBAL_CONFIG,
  },
  Country: {
    label: "Country",
    url: routes.GLOBAL_CONFIG,
  },
  RetentionsMerchant: {
    label: {
      [CountryValueEnum.Ecuador]: "Impuestos y retenciones para comercios",
      [CountryValueEnum.Colombia]: "Impuestos y retención para comercios",
    },
    url: routes.BASE_PATH_RETENTIONS,
  },
  UploadFileForm: {
    label: "Carga con archivo",
    url: routes.WITH_CSV,
  },
  UploadFileTextByCountry: {
    [CountryValueEnum.Ecuador]: "Carga con archivo",
    [CountryValueEnum.Colombia]: "Retenciones ICA",
  },
  UploadFileTextEcuador: {
    [CountryValueEnum.Ecuador]: "Carga de catálogos con archivo",
  },
  EditWithForm: {
    label: "Editar configuración",
    url: routes.EDIT_FORM,
  },
  UploadWithForm: {
    label: "Carga con formulario",
    url: routes.WITH_FORM,
  },
};
