import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { defaultTo, find, get, last, sortBy } from "lodash";
import { formatDateAndHour, translateRuleKeys } from "../../../shared/utils";
import {
  getServiceValues,
  MERCHANT_INFORMATION_VALUES,
  RULE_INFORMATION_VALUES,
  SecurityRulesSubtitleSection,
} from "../../../shared/infrastructure/constants/SecurityRulesConstants";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ModalBodyProps } from "../ModalBody";
import { GENERAL_ACTIONS } from "../../../shared/infrastructure/enums/GeneralActionsEnum";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingTop: 24,
      paddingLeft: 24,
      paddingRight: 24,
    },
    chip: {
      color: "#293036 !important",
      backgroundColor: "#F7FAFC !important",
      borderRadius: "4px !important",
      marginRight: "8px !important",
      marginBottom: "8px !important",
    },
    paper: {
      paddingBottom: 15,
      backgroundColor: "#F7FAFC !important",
      borderRadius: 3,
      width: "100%",
    },
  })
);

const InformationComponent = (props: ModalBodyProps): JSX.Element => {
  const classes = useStyles();
  const username = get(
    last(sortBy(props.data.historical, ["created"])),
    "userName"
  );

  const securityRulesValues: object = {
    service: getServiceValues(get(props, "data.service", "")),
    action: defaultTo(
      GENERAL_ACTIONS.get(get(props, "data.rule.action")),
      get(props, "data.rule.action", "")
    ),
    createdAt: formatDateAndHour(get(props, "data.createdAt", 0)),
    username: defaultTo(username, "Sin solicitante"),
  };

  const merchantInformation: object = get(
    find(get(props, "merchant.data", []), {
      _source: { merchantId: props.data.merchantId },
    }),
    "_source",
    []
  );

  const merchantInformationValues: object = {
    name: get(merchantInformation, "name", props.data.merchantName),
    socialReason: get(merchantInformation, "socialReason", ""),
    merchantId: get(merchantInformation, "merchantId", props.data.merchantId),
    merchantSize: get(merchantInformation, "merchantSize", ""),
    country: get(merchantInformation, "country", props.data.country),
  };

  return (
    <div className={classes.container}>
      <Grid container spacing={1}>
        <Paper
          elevation={0}
          className={classes.paper}
          style={{ paddingRight: 0 }}
        >
          <Grid
            container
            xs={12}
            spacing={2}
            className={classes.container}
            style={{ paddingRight: 0 }}
          >
            <Grid item xs={12}>
              <Typography variant={"subtitle1"} color={"textPrimary"}>
                {SecurityRulesSubtitleSection.MERCHANT_INFORMATION}
              </Typography>
            </Grid>
            {Object.keys(defaultTo(merchantInformationValues, {})).map(
              (key: string, index: number) => (
                <Grid item xs={12} key={`container${index}`}>
                  <Grid container key={`container${key}`} wrap="nowrap">
                    <Grid item xs={4} key={`label${key}`}>
                      <Typography variant={"body1"} color={"textSecondary"}>
                        {translateRuleKeys(key, MERCHANT_INFORMATION_VALUES)}
                      </Typography>
                    </Grid>
                    <Grid item xs={8} key={`value${key}`} zeroMinWidth>
                      <Typography
                        variant={"body1"}
                        color={"textPrimary"}
                        style={{ overflowWrap: "break-word" }}
                      >
                        {merchantInformationValues[`${key}`]}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )
            )}
          </Grid>
        </Paper>
      </Grid>
      <Grid container spacing={1} style={{ paddingTop: 30, paddingBottom: 50 }}>
        <Paper
          elevation={0}
          className={classes.paper}
          style={{ paddingRight: 0 }}
        >
          <Grid
            container
            xs={12}
            spacing={2}
            className={classes.container}
            style={{ paddingRight: 0 }}
          >
            <Grid item xs={12}>
              <Typography variant={"subtitle1"} color={"textPrimary"}>
                {SecurityRulesSubtitleSection.RULE_INFORMATION}
              </Typography>
            </Grid>
            {Object.keys(defaultTo(securityRulesValues, {})).map(
              (key: string, index: number) => (
                <Grid item xs={12} key={`container${index}`}>
                  <Grid container key={`container${key}`} wrap="nowrap">
                    <Grid item xs={4} key={`label${key}`}>
                      <Typography variant={"body1"} color={"textSecondary"}>
                        {translateRuleKeys(key, RULE_INFORMATION_VALUES)}
                      </Typography>
                    </Grid>
                    <Grid item xs={8} key={`value${key}`} zeroMinWidth>
                      <Typography
                        variant={"body1"}
                        color={"textPrimary"}
                        style={{ overflowWrap: "break-word" }}
                      >
                        {securityRulesValues[`${key}`]}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )
            )}
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

export default InformationComponent;
