import React from "react";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Popover,
  Typography,
} from "@material-ui/core";
import { get } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { IColumnsTable } from "../../../shared/infrastructure/table/IColumnsTable";

export interface ColumnFiltersProps {
  openColumns: boolean;
  idButtonColumns: string | undefined;
  anchorColumns: HTMLButtonElement | null;
  selectedColumns: string[];
  handleCloseColumns: (_event: unknown) => void;
  handleChangeColumnCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRestoreColumns: () => void;
  handleDisplayedColumnUser: () => void;
  selectableColumns: IColumnsTable[];
  title: string;
  admittedColumns: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    bodyTextSize: {
      fontSize: "14px",
    },
    buttonText: {
      fontSize: "14px",
      fontWeight: 500,
    },
  })
);
export const ColumnFilters = (props: ColumnFiltersProps) => {
  const { selectableColumns, title, admittedColumns } = props;
  const styles = useStyles();
  return (
    <React.Fragment>
      <Popover
        id={props.idButtonColumns}
        open={props.openColumns}
        anchorEl={props.anchorColumns}
        onClose={props.handleCloseColumns}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Card>
          <CardContent style={{ padding: "20px" }}>
            <Grid container spacing={1} direction="column">
              <Grid item>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <Typography variant="h6" color="primary">
                      {title}
                    </Typography>
                  </FormLabel>
                  <FormGroup aria-label="position">
                    {selectableColumns.map((column) => (
                      <FormControlLabel
                        value={column.id}
                        key={column.id}
                        control={
                          <Checkbox
                            onChange={props.handleChangeColumnCheck}
                            name={column.id}
                            color="primary"
                            size="small"
                            checked={props.selectedColumns.includes(column.id)}
                          />
                        }
                        label={
                          <Typography className={styles.bodyTextSize}>
                            {get(column, "filterLabel", column.label)}
                          </Typography>
                        }
                        labelPlacement="end"
                        disabled={
                          column.disabledFilter
                            ? true
                            : props.selectedColumns.length >= admittedColumns &&
                              !props.selectedColumns.includes(
                                column.id as string
                              )
                        }
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      id={"restoreBtn"}
                      variant="outlined"
                      fullWidth
                      onClick={props.handleRestoreColumns}
                    >
                      <Typography
                        variant={"body1"}
                        className={styles.buttonText}
                      >
                        Restablecer
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      id={"applyBtn"}
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={
                        admittedColumns === 1
                          ? props.selectedColumns.length > 2
                          : props.selectedColumns.length > admittedColumns ||
                            props.selectedColumns.length < admittedColumns
                      }
                      onClick={props.handleDisplayedColumnUser}
                    >
                      <Typography
                        variant={"body1"}
                        className={styles.buttonText}
                      >
                        Aplicar
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Popover>
    </React.Fragment>
  );
};
