import {
  OriginTransactionsEnum,
  SourceTransactionsEnum,
} from "../constants/StatusFlowDashboardConstants";

export const defaultHeaderOptions: IHeaderTable = {
  id: "options",
  label: "",
  disabled: true,
  orderBy: "options",
};

export const checkBoxHeader: IHeaderTable = {
  label: "status",
  id: "bankConciliationStatus",
  disabled: false,
  orderBy: "",
  isCustom: true,
};

export const stateTrxHeader: IHeaderTable = {
  label: "Estado",
  id: "bankConciliationStatus",
  disabled: true,
  orderBy: "",
};

export const defaultHeadersTransactions: IHeaderTable[] = [
  checkBoxHeader,
  {
    label: "Fecha de Liquidación",
    id: "transactionCreated",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Transacción ID",
    id: "transactionId",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Nombre del comercio",
    id: "merchantName",
    disabled: true,
    orderBy: "",
  },
  {
    label: "ID del comercio",
    id: "merchantId",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Valor total de la transacción",
    id: "approvedTransactionAmount",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Neto a recibir",
    id: "netAmount",
    disabled: true,
    orderBy: "",
  },
  defaultHeaderOptions,
];
export const defaultHeadersTransactionsHistory: IHeaderTable[] = [
  {
    label: "Fecha de liquidación",
    id: "transactionCreated",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Transacción ID",
    id: "transactionId",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Nombre del comercio",
    id: "merchantName",
    disabled: true,
    orderBy: "",
  },
  {
    label: "ID del comercio",
    id: "merchantId",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Valor total de la transacción",
    id: "approvedTransactionAmount",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Neto a recibir",
    id: "netAmount",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Estado",
    id: "bankConciliationStatus",
    disabled: true,
    orderBy: "",
  },
];

export const defaultHeadersSummary: IHeaderTable[] = [
  {
    label: "Fecha de Liquidación",
    id: "conciliationCreated",
    disabled: true,
    orderBy: "",
  },
  {
    label: "País",
    id: "country",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Método de pago",
    id: "paymentMethod",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Procesador",
    id: "processorName",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Moneda",
    id: "currencyCode",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Neto a recibir",
    id: "netAmountTotal",
    disabled: true,
    orderBy: "",
  },
  defaultHeaderOptions,
];
export const defaultHeadersHistorySummary: IHeaderTable[] = [
  {
    label: "Fecha de Liquidación",
    id: "conciliationCreated",
    disabled: true,
    orderBy: "",
  },
  {
    label: "País",
    id: "country",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Método de pago",
    id: "paymentMethod",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Procesador",
    id: "processorName",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Moneda",
    id: "currencyCode",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Neto a recibir",
    id: "netAmountTotal",
    disabled: true,
    orderBy: "",
  },
  {
    label: "Estado",
    id: "bankConciliationStatus",
    disabled: true,
    orderBy: "",
  },
];

export const defaultHeadersTransactionsPendingStatic: IHeaderTable[] = [
  checkBoxHeader,
  {
    label: "Fecha de liquidación",
    id: "transactionCreated",
    disabled: false,
    orderBy: "",
  },
  {
    label: "Transacción ID",
    id: "transactionId",
    disabled: false,
    orderBy: "",
  },
];

export const defaultHeadersTransactionsHistoricStatic: IHeaderTable[] = [
  {
    label: "Fecha de liquidación",
    id: "transactionCreated",
    disabled: false,
    orderBy: "",
  },
  {
    label: "Transacción ID",
    id: "transactionId",
    disabled: false,
    orderBy: "",
  },
];

export const defaultHeaderSummaryHistoricStatic: IHeaderTable[] = [
  {
    label: "Fecha de Liquidación",
    id: "conciliationCreated",
    disabled: false,
    orderBy: "conciliationCreated",
  },
];

export const staticColumns = {
  [`${OriginTransactionsEnum.TRANSACTIONS}`]: {
    [`${SourceTransactionsEnum.HISTORIC}`]:
      defaultHeadersTransactionsHistoricStatic,
    [`${SourceTransactionsEnum.PENDING}`]:
      defaultHeadersTransactionsPendingStatic,
  },
  [`${OriginTransactionsEnum.SUMMARY}`]: {
    [`${SourceTransactionsEnum.HISTORIC}`]: defaultHeaderSummaryHistoricStatic,
    [`${SourceTransactionsEnum.PENDING}`]: [],
  },
};

export const lastStaticColumn = {
  [`${OriginTransactionsEnum.TRANSACTIONS}`]: {
    [`${SourceTransactionsEnum.HISTORIC}`]: stateTrxHeader,
    [`${SourceTransactionsEnum.PENDING}`]: defaultHeaderOptions,
  },
  [`${OriginTransactionsEnum.SUMMARY}`]: {
    [`${SourceTransactionsEnum.HISTORIC}`]: stateTrxHeader,
    [`${SourceTransactionsEnum.PENDING}`]: defaultHeaderOptions,
  },
};

export interface IHeaderTable {
  id: any;
  label: string;
  disabled: boolean;
  orderBy: string;
  isCustom?: boolean;
}
