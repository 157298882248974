import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Box,
  Card,
  Typography,
  TableContainer,
  Table,
} from "@material-ui/core";
import { Inbox } from "react-feather";
import theme from "../../../theme";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";

export interface IEmptyCard {
  title?: string;
  subtitle?: string;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  headerCell: {
    minHeight: 48,
    color: "#6D7781",
    fontSize: 15,
    padding: "10px 16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "140%",
  },
  emptyCard: {
    minWidth: 650,
    paddingRight: 30,
    paddingLeft: 30,
    background: "#FFF",
    height: 400,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export type EmptyCardProps = IEmptyCard;

export const EmptyCard: React.FC<EmptyCardProps> = (props: EmptyCardProps) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow key="header">
              <TableCell key={"head_date"} className={classes.headerCell}>
                Fecha de creación
              </TableCell>
              <TableCell key={"processors"} className={classes.headerCell}>
                Procesadores activos
              </TableCell>
              <TableCell key={"alias"} className={classes.headerCell}>
                Alias
              </TableCell>
              <TableCell key={"model"} className={classes.headerCell}>
                Modelo
              </TableCell>
              <TableCell key={"payment_method"} className={classes.headerCell}>
                Método de pago
              </TableCell>
              <TableCell key={"options"} className={classes.headerCell} />
            </TableRow>
          </TableHead>
        </Table>
        <Box>
          <Card className={classes.emptyCard}>
            <Inbox size={72} color="#6D7781" />
            <div>
              <Typography
                variant="h4"
                style={{ color: theme.palette.grey.A200, paddingLeft: 13 }}
              >
                {props.title}
              </Typography>
              <Typography variant="h6">{props.subtitle}</Typography>
            </div>
          </Card>
        </Box>
      </TableContainer>
    </React.Fragment>
  );
};
