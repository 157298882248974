import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const MerchantInfoSkeleton: FC = () => (
  <Grid container spacing={1}>
    <Grid item xs={10}>
      <Skeleton animation="wave" variant="rect" height={14} />
    </Grid>
    <Grid item xs={10}>
      <Skeleton animation="wave" variant="rect" height={14} />
    </Grid>
    <Grid item xs={10}>
      <Skeleton animation="wave" variant="rect" height={14} />
    </Grid>
    <Grid item xs={10}>
      <Skeleton animation="wave" variant="rect" height={14} />
    </Grid>
    <Grid item xs={10}>
      <Skeleton animation="wave" variant="rect" height={14} />
    </Grid>
  </Grid>
);

export default MerchantInfoSkeleton;
