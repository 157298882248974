export enum OwnerEnum {
  OWNER = "Owner",
  ADD_OWNER = "Agregar Owner",
  NO_DATA = "Aún no tienes información registrada",
  ADD_OWNERS = "Agrega Owners",
  CREATE_OWNER = "Crear Owner",
  CLIENTS = "Clientes",
  MODAL_DESCRIPTION = "El Owner es el propietario de los customers group, customers, stores y branches que debajo de él se conformen",
  MODAL_TITLE = "Crear nuevo Owner",
  MODAL_BTN_PRIMARY = "Crear",
  MODAL_BTN_SECONDARY = "Cancelar",
  LOADING_TITLE = "Creando Owner",
  LOADING_DESCRIPTION = "Este proceso puede tardar unos minutos...",
  KUSHKI_OWNER = "Kushki Ghost",
  MAIN_KUSHKI_OWNER = "Kushki",
}
