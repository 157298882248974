import { SnackBarProvider, theme } from "@kushki/connect-ui";
import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import MainContainer from "./containers/MainContainer/MainContainer";
import { store } from "./store/store";
import { environment } from "./environments/environment";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
import { M_CLIENTS } from "./shared/constants/labels/main_container_labels";
import { SPA_BASENAME } from "./shared/constants/routes";
import BranchContainer from "./containers/BranchContainer/BranchContainer";
import { SessionStoragePath } from "./shared/constants/sessionStoragePath";
import { Provider as RollbarProvider } from "@rollbar/react";
import { getRollbarConfig } from "./shared/infraestructure/constants/GetRollbarConfig";

const Root = () => {
  useEffect(() => {
    sessionStorage.removeItem(SessionStoragePath.RATES_CONFIGS);
  }, []);

  return (
    <React.StrictMode>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&display=swap');"
        }
      </style>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500&display=swap');"
        }
      </style>
      <RollbarProvider config={getRollbarConfig}>
        <Provider store={store}>
          <ThemeProvider
            theme={{
              ...theme,
              components: {
                ...theme.components,
                MuiPickerStaticWrapper: {
                  styleOverrides: {
                    root: {
                      "& .MuiTypography-caption": {
                        color: "#677684 !important",
                      },
                      "& .MuiTypography-subtitle1": {
                        color: "#023365 !important",
                      },
                    },
                  },
                },
              },
            }}
          >
            <SnackBarProvider>
              <BrowserRouter basename={SPA_BASENAME}>
                <ModuleSecurityWrapper
                  basePath={environment.kushkiUrl}
                  componentId={M_CLIENTS}
                >
                  <Routes>
                    <Route path={"/:nodeId"} element={<MainContainer />} />
                  </Routes>
                  <Routes>
                    <Route path={"/"} element={<MainContainer />} />
                  </Routes>
                  <Routes>
                    <Route
                      path={"/:nodeId/customers/:nodeIdBranch"}
                      element={<BranchContainer />}
                    />
                  </Routes>
                </ModuleSecurityWrapper>
              </BrowserRouter>
            </SnackBarProvider>
          </ThemeProvider>
        </Provider>
      </RollbarProvider>
    </React.StrictMode>
  );
};

export default Root;
