import { Category } from "../constants/information_constants";
import { CatalogsEnum } from "../interfaces/catalogs-enum";

export const CatalogEeuu: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { defaultShareholderDocType: "0", name: "Seguro Social SSN", value: "0" },
    {
      defaultShareholderDocType: "0",
      name: "Identificación del Empleador EIN",
      value: "1",
    },
    {
      defaultShareholderDocType: "0",
      name: "Identificación Personal del Contribuyente ITIN",
      value: "2",
    },
  ],
  [CatalogsEnum.Provinces]: [
    { name: "Alabama", value: "AL" },
    { name: "Alaska", value: "AK" },
    { name: "Arizona", value: "AZ" },
    { name: "Arkansas", value: "AR" },
    { name: "California", value: "CA" },
    { name: "Carolina del Norte", value: "NC" },
    { name: "Carolina del Sur", value: "SC" },
    { name: "Colorado", value: "CO" },
    { name: "Connecticut", value: "CT" },
    { name: "Dakota del Norte", value: "ND" },
    { name: "Dakota del Sur", value: "SD" },
    { name: "Delaware", value: "DE" },
    { name: "Distrito de Columbia", value: "DC" },
    { name: "Florida", value: "FL" },
    { name: "Georgia", value: "GA" },
    { name: "Hawái", value: "HI" },
    { name: "Idaho", value: "ID" },
    { name: "Illinois", value: "IL" },
    { name: "Indiana", value: "IN" },
    { name: "Iowa", value: "IA" },
    { name: "Kansas", value: "KS" },
    { name: "Kentucky", value: "KY" },
    { name: "Luisiana", value: "LA" },
    { name: "Maryland", value: "MD" },
    { name: "Massachusetts", value: "MA" },
    { name: "Minnesota", value: "MN" },
    { name: "Misisipi", value: "MS" },
    { name: "Misuri", value: "MO" },
    { name: "Montana", value: "MT" },
    { name: "Míchigan", value: "MI" },
    { name: "Nebraska", value: "NE" },
    { name: "Nevada", value: "NV" },
    { name: "Nueva Jersey", value: "NJ" },
    { name: "Nueva York", value: "NY" },
    { name: "Nuevo Hampshire", value: "NH" },
    { name: "Nuevo México", value: "NM" },
    { name: "Ohio", value: "OH" },
    { name: "Oklahoma", value: "OK" },
    { name: "Oregón", value: "OR" },
    { name: "Otras Ciudades", value: "OC" },
    { name: "Pensilvania", value: "PA" },
    { name: "Puerto Rico", value: "PR" },
    { name: "Rhode Island", value: "RI" },
    { name: "Tennessee", value: "TN" },
    { name: "Texas", value: "TX" },
    { name: "Utah", value: "UT" },
    { name: "Virginia", value: "VA" },
    { name: "Washington", value: "WA" },
    { name: "Wisconsin", value: "WI" },
  ],
  [CatalogsEnum.Cities]: [
    {
      name: "Abilene",
      parent: "TX",
      value: "Abilene",
    },
    {
      name: "Akron",
      parent: "OH",
      value: "Akron",
    },
    {
      name: "Albuquerque",
      parent: "NM",
      value: "Albuquerque",
    },
    {
      name: "Alexandría",
      parent: "VA",
      value: "Alexandría",
    },
    {
      name: "Allentown",
      parent: "PA",
      value: "Allentown",
    },
    {
      name: "Amarillo",
      parent: "TX",
      value: "Amarillo",
    },
    {
      name: "Anaheim",
      parent: "CA",
      value: "Anaheim",
    },
    {
      name: "Anchorage",
      parent: "AK",
      value: "Anchorage",
    },
    {
      name: "Ann Arbor",
      parent: "MI",
      value: "Ann Arbor",
    },
    {
      name: "Antioch",
      parent: "CA",
      value: "Antioch",
    },
    {
      name: "Arecibo",
      parent: "PR",
      value: "Arecibo",
    },
    {
      name: "Arlington",
      parent: "TX",
      value: "Arlington",
    },
    {
      name: "Arlington",
      parent: "VA",
      value: "Arlington",
    },
    {
      name: "Arvada",
      parent: "CO",
      value: "Arvada",
    },
    {
      name: "Athens",
      parent: "GA",
      value: "Athens",
    },
    {
      name: "Atlanta",
      parent: "GA",
      value: "Atlanta",
    },
    {
      name: "Augusta",
      parent: "GA",
      value: "Augusta",
    },
    {
      name: "Aurora",
      parent: "CO",
      value: "Aurora",
    },
    {
      name: "Aurora",
      parent: "IL",
      value: "Aurora",
    },
    {
      name: "Austin",
      parent: "TX",
      value: "Austin",
    },
    {
      name: "Bakersfield",
      parent: "CA",
      value: "Bakersfield",
    },
    {
      name: "Baltimore",
      parent: "MD",
      value: "Baltimore",
    },
    {
      name: "Baton Rouge",
      parent: "LA",
      value: "Baton Rouge",
    },
    {
      name: "Bayamón",
      parent: "PR",
      value: "Bayamón",
    },
    {
      value: "Bear",
      name: "Bear",
      parent: "DE",
    },
    {
      name: "Beaumont",
      parent: "TX",
      value: "Beaumont",
    },
    {
      name: "Bellevu",
      parent: "WA",
      value: "Bellevu",
    },
    {
      name: "Berkeley",
      parent: "CA",
      value: "Berkeley",
    },
    {
      name: "Billings",
      parent: "MT",
      value: "Billings",
    },
    {
      name: "Birmingham",
      parent: "AL",
      value: "Birmingham",
    },
    {
      name: "Bogota",
      parent: "OC",
      value: "Bogota",
    },
    {
      name: "Boise",
      parent: "ID",
      value: "Boise",
    },
    {
      name: "Boston",
      parent: "MA",
      value: "Boston",
    },
    {
      name: "Bridgeport",
      parent: "CT",
      value: "Bridgeport",
    },
    {
      value: "Brookside",
      name: "Brookside",
      parent: "DE",
    },
    {
      name: "Brownsville",
      parent: "TX",
      value: "Brownsville",
    },
    {
      name: "Burbank",
      parent: "CA",
      value: "Burbank",
    },
    {
      name: "Búfalo",
      parent: "NY",
      value: "Búfalo",
    },
    {
      name: "Cabo Coral",
      parent: "FL",
      value: "Cabo Coral",
    },
    {
      name: "Caguas",
      parent: "PR",
      value: "Caguas",
    },
    {
      name: "Cambridge",
      parent: "MA",
      value: "Cambridge",
    },
    {
      name: "Carlsbad",
      parent: "CA",
      value: "Carlsbad",
    },
    {
      name: "Carolina",
      parent: "PR",
      value: "Carolina",
    },
    {
      name: "Carrollton",
      parent: "TX",
      value: "Carrollton",
    },
    {
      name: "Cary",
      parent: "NC",
      value: "Cary",
    },
    {
      name: "Cedar Rapids",
      parent: "IA",
      value: "Cedar Rapids",
    },
    {
      name: "Centennial",
      parent: "CO",
      value: "Centennial",
    },
    {
      name: "Chandler",
      parent: "AZ",
      value: "Chandler",
    },
    {
      name: "Charleston",
      parent: "SC",
      value: "Charleston",
    },
    {
      name: "Charlotte",
      parent: "NC",
      value: "Charlotte",
    },
    {
      name: "Chattanooga",
      parent: "TN",
      value: "Chattanooga",
    },
    {
      name: "Chesapeake",
      parent: "VA",
      value: "Chesapeake",
    },
    {
      name: "Chicago",
      parent: "IL",
      value: "Chicago",
    },
    {
      name: "Chula Vista",
      parent: "CA",
      value: "Chula Vista",
    },
    {
      name: "Cincinnati",
      parent: "OH",
      value: "Cincinnati",
    },
    {
      name: "Ciudad de Mexico",
      parent: "OC",
      value: "Ciudad de Mexico",
    },
    {
      name: "Clarksville",
      parent: "TN",
      value: "Clarksville",
    },
    {
      value: "Claymont",
      name: "Claymont",
      parent: "DE",
    },
    {
      name: "Clearwater",
      parent: "FL",
      value: "Clearwater",
    },
    {
      name: "Cleveland",
      parent: "OH",
      value: "Cleveland",
    },
    {
      name: "Colorado Springs",
      parent: "CO",
      value: "Colorado Springs",
    },
    {
      name: "Columbia",
      parent: "SC",
      value: "Columbia",
    },
    {
      name: "Columbia",
      parent: "MO",
      value: "Columbia",
    },
    {
      name: "Columbus",
      parent: "OH",
      value: "Columbus",
    },
    {
      name: "Columbus",
      parent: "GA",
      value: "Columbus",
    },
    {
      name: "Concord",
      parent: "CA",
      value: "Concord",
    },
    {
      name: "Coral Springs",
      parent: "FL",
      value: "Coral Springs",
    },
    {
      name: "Corona",
      parent: "CA",
      value: "Corona",
    },
    {
      name: "Corpus Christi",
      parent: "TX",
      value: "Corpus Christi",
    },
    {
      name: "Costa Mesa",
      parent: "CA",
      value: "Costa Mesa",
    },
    {
      name: "Dallas",
      parent: "TX",
      value: "Dallas",
    },
    {
      name: "Daly City",
      parent: "CA",
      value: "Daly City",
    },
    {
      name: "Dayton",
      parent: "OH",
      value: "Dayton",
    },
    {
      value: "Delaware City",
      name: "Delaware City",
      parent: "DE",
    },
    {
      name: "Denton",
      parent: "TX",
      value: "Denton",
    },
    {
      name: "Des Moines",
      parent: "IA",
      value: "Des Moines",
    },
    {
      name: "Detroit",
      parent: "MI",
      value: "Detroit",
    },
    {
      value: "Dover",
      name: "Dover",
      parent: "DE",
    },
    {
      name: "Downey",
      parent: "CA",
      value: "Downey",
    },
    {
      name: "Durham",
      parent: "NC",
      value: "Durham",
    },
    {
      name: "Dénver",
      parent: "CO",
      value: "Dénver",
    },
    {
      name: "El Monte",
      parent: "CA",
      value: "El Monte",
    },
    {
      name: "El Paso",
      parent: "TX",
      value: "El Paso",
    },
    {
      name: "Elgin",
      parent: "IL",
      value: "Elgin",
    },
    {
      name: "Elizabeth",
      parent: "NJ",
      value: "Elizabeth",
    },
    {
      name: "Elk Grove",
      parent: "CA",
      value: "Elk Grove",
    },
    {
      value: "Elsmere",
      name: "Elsmere",
      parent: "DE",
    },
    {
      name: "Erie",
      parent: "PA",
      value: "Erie",
    },
    {
      name: "Escondido",
      parent: "CA",
      value: "Escondido",
    },
    {
      name: "Eugene",
      parent: "OR",
      value: "Eugene",
    },
    {
      name: "Evansville",
      parent: "IN",
      value: "Evansville",
    },
    {
      name: "Everett",
      parent: "WA",
      value: "Everett",
    },
    {
      name: "Fairfield",
      parent: "CA",
      value: "Fairfield",
    },
    {
      name: "Fargo",
      parent: "ND",
      value: "Fargo",
    },
    {
      name: "Fayetteville",
      parent: "NC",
      value: "Fayetteville",
    },
    {
      name: "Filadelfia",
      parent: "PA",
      value: "Filadelfia",
    },
    {
      name: "Flint",
      parent: "MI",
      value: "Flint",
    },
    {
      name: "Fontana",
      parent: "CA",
      value: "Fontana",
    },
    {
      name: "Fort Collins",
      parent: "CO",
      value: "Fort Collins",
    },
    {
      name: "Fort Lauderdale",
      parent: "FL",
      value: "Fort Lauderdale",
    },
    {
      name: "Fort Wayne",
      parent: "IN",
      value: "Fort Wayne",
    },
    {
      name: "Fort Worth",
      parent: "TX",
      value: "Fort Worth",
    },
    {
      name: "Fremont",
      parent: "CA",
      value: "Fremont",
    },
    {
      name: "Fresno",
      parent: "CA",
      value: "Fresno",
    },
    {
      name: "Frisco",
      parent: "TX",
      value: "Frisco",
    },
    {
      name: "Fullerton",
      parent: "CA",
      value: "Fullerton",
    },
    {
      name: "Gainesville",
      parent: "FL",
      value: "Gainesville",
    },
    {
      name: "Garden Grove",
      parent: "CA",
      value: "Garden Grove",
    },
    {
      name: "Garland",
      parent: "TX",
      value: "Garland",
    },
    {
      value: "Georgetown",
      name: "Georgetown",
      parent: "DE",
    },
    {
      name: "Gilbert",
      parent: "AZ",
      value: "Gilbert",
    },
    {
      value: "Glasgow",
      name: "Glasgow",
      parent: "DE",
    },
    {
      name: "Glendale",
      parent: "AZ",
      value: "Glendale",
    },
    {
      name: "Glendale",
      parent: "CA",
      value: "Glendale",
    },
    {
      name: "Grand Prairie",
      parent: "TX",
      value: "Grand Prairie",
    },
    {
      name: "Grand Rapids",
      parent: "MI",
      value: "Grand Rapids",
    },
    {
      name: "Green Bay",
      parent: "WI",
      value: "Green Bay",
    },
    {
      name: "Greensboro",
      parent: "NC",
      value: "Greensboro",
    },
    {
      name: "Gresham",
      parent: "OR",
      value: "Gresham",
    },
    {
      name: "Guaynabo",
      parent: "PR",
      value: "Guaynabo",
    },
    {
      name: "Hampton",
      parent: "VA",
      value: "Hampton",
    },
    {
      name: "Hartfor",
      parent: "CT",
      value: "Hartfor",
    },
    {
      name: "Hayward",
      parent: "CA",
      value: "Hayward",
    },
    {
      name: "Henderson",
      parent: "NV",
      value: "Henderson",
    },
    {
      name: "Hialeah",
      parent: "FL",
      value: "Hialeah",
    },
    {
      name: "High Point",
      parent: "NC",
      value: "High Point",
    },
    {
      value: "Hockessin",
      name: "Hockessin",
      parent: "DE",
    },
    {
      name: "Hollywoo",
      parent: "FL",
      value: "Hollywoo",
    },
    {
      name: "Honolulú",
      parent: "HI",
      value: "Honolulú",
    },
    {
      name: "Houston",
      parent: "TX",
      value: "Houston",
    },
    {
      name: "Huntington Beach",
      parent: "CA",
      value: "Huntington Beach",
    },
    {
      name: "Huntsville",
      parent: "AL",
      value: "Huntsville",
    },
    {
      name: "Independence",
      parent: "MO",
      value: "Independence",
    },
    {
      name: "Indianápolis",
      parent: "IN",
      value: "Indianápolis",
    },
    {
      name: "Inglewood",
      parent: "CA",
      value: "Inglewood",
    },
    {
      name: "Irvine",
      parent: "CA",
      value: "Irvine",
    },
    {
      name: "Irving",
      parent: "TX",
      value: "Irving",
    },
    {
      name: "Jackson",
      parent: "MS",
      value: "Jackson",
    },
    {
      name: "Jacksonville",
      parent: "FL",
      value: "Jacksonville",
    },
    {
      name: "Jersey City",
      parent: "NJ",
      value: "Jersey City",
    },
    {
      name: "Joliet",
      parent: "IL",
      value: "Joliet",
    },
    {
      name: "Kansas City",
      parent: "KS",
      value: "Kansas City",
    },
    {
      name: "Kansas City (Misuri)",
      parent: "MO",
      value: "Kansas City (Misuri)",
    },
    {
      name: "Killeen",
      parent: "TX",
      value: "Killeen",
    },
    {
      name: "Knoxville",
      parent: "TN",
      value: "Knoxville",
    },
    {
      name: "Lafayett",
      parent: "LA",
      value: "Lafayett",
    },
    {
      name: "Lakewood",
      parent: "CO",
      value: "Lakewood",
    },
    {
      name: "Lancaster",
      parent: "CA",
      value: "Lancaster",
    },
    {
      name: "Lansing",
      parent: "MI",
      value: "Lansing",
    },
    {
      name: "Laredo",
      parent: "TX",
      value: "Laredo",
    },
    {
      name: "Las Vegas",
      parent: "NV",
      value: "Las Vegas",
    },
    {
      value: "Lewes",
      name: "Lewes",
      parent: "DE",
    },
    {
      name: "Lexington",
      parent: "KY",
      value: "Lexington",
    },
    {
      name: "Lima",
      parent: "OC",
      value: "Lima",
    },
    {
      name: "Lincoln",
      parent: "NE",
      value: "Lincoln",
    },
    {
      name: "Little Rock",
      parent: "AR",
      value: "Little Rock",
    },
    {
      name: "Long Beach",
      parent: "CA",
      value: "Long Beach",
    },
    {
      name: "Los Angeles",
      parent: "CA",
      value: "Los Angeles",
    },
    {
      name: "Louisville",
      parent: "KY",
      value: "Louisville",
    },
    {
      name: "Lowell",
      parent: "MA",
      value: "Lowell",
    },
    {
      name: "Lubbock",
      parent: "TX",
      value: "Lubbock",
    },
    {
      name: "Madison",
      parent: "WI",
      value: "Madison",
    },
    {
      name: "McAllen",
      parent: "TX",
      value: "McAllen",
    },
    {
      name: "McKinney",
      parent: "TX",
      value: "McKinney",
    },
    {
      name: "Memphis",
      parent: "TN",
      value: "Memphis",
    },
    {
      name: "Mesa",
      parent: "AZ",
      value: "Mesa",
    },
    {
      name: "Mesquite",
      parent: "TX",
      value: "Mesquite",
    },
    {
      name: "Metairie",
      parent: "LA",
      value: "Metairie",
    },
    {
      name: "Miami",
      parent: "FL",
      value: "Miami",
    },
    {
      name: "Miami Gardens",
      parent: "FL",
      value: "Miami Gardens",
    },
    {
      value: "Middletown",
      name: "Middletown",
      parent: "DE",
    },
    {
      name: "Midland",
      parent: "TX",
      value: "Midland",
    },
    {
      value: "Milford",
      name: "Milford",
      parent: "DE",
    },
    {
      name: "Milwaukee",
      parent: "WI",
      value: "Milwaukee",
    },
    {
      name: "Mineápolis",
      parent: "MN",
      value: "Mineápolis",
    },
    {
      name: "Miramar",
      parent: "FL",
      value: "Miramar",
    },
    {
      name: "Mobile",
      parent: "AL",
      value: "Mobile",
    },
    {
      name: "Modesto",
      parent: "CA",
      value: "Modesto",
    },
    {
      name: "Montgomery",
      parent: "AL",
      value: "Montgomery",
    },
    {
      name: "Moreno Valley",
      parent: "CA",
      value: "Moreno Valley",
    },
    {
      name: "Murfreesboro",
      parent: "TN",
      value: "Murfreesboro",
    },
    {
      name: "Murrieta",
      parent: "CA",
      value: "Murrieta",
    },
    {
      name: "Mánchester",
      parent: "NH",
      value: "Mánchester",
    },
    {
      name: "Naperville",
      parent: "IL",
      value: "Naperville",
    },
    {
      name: "Nashville",
      parent: "TN",
      value: "Nashville",
    },
    {
      value: "New Castle",
      name: "New Castle",
      parent: "DE",
    },
    {
      name: "New Haven",
      parent: "CT",
      value: "New Haven",
    },
    {
      name: "Newark",
      parent: "NJ",
      value: "Newark",
    },
    {
      value: "Newark",
      name: "Newark",
      parent: "DE",
    },
    {
      name: "Newport News",
      parent: "VA",
      value: "Newport News",
    },
    {
      name: "Norfolk",
      parent: "VA",
      value: "Norfolk",
    },
    {
      name: "Norman",
      parent: "OK",
      value: "Norman",
    },
    {
      name: "North Las Vegas",
      parent: "NV",
      value: "North Las Vegas",
    },
    {
      name: "Norwalk",
      parent: "CA",
      value: "Norwalk",
    },
    {
      name: "Nueva Orleans",
      parent: "LA",
      value: "Nueva Orleans",
    },
    {
      name: "Nueva York",
      parent: "NY",
      value: "Nueva York",
    },
    {
      name: "Oakland",
      parent: "CA",
      value: "Oakland",
    },
    {
      name: "Oceanside",
      parent: "CA",
      value: "Oceanside",
    },
    {
      name: "Oklahoma City",
      parent: "OK",
      value: "Oklahoma City",
    },
    {
      name: "Olathe",
      parent: "KS",
      value: "Olathe",
    },
    {
      name: "Omaha",
      parent: "NE",
      value: "Omaha",
    },
    {
      name: "Ontario",
      parent: "CA",
      value: "Ontario",
    },
    {
      name: "Orange",
      parent: "CA",
      value: "Orange",
    },
    {
      name: "Orlando",
      parent: "FL",
      value: "Orlando",
    },
    {
      name: "Overland Park ",
      parent: "KS",
      value: "Overland Park ",
    },
    {
      name: "Oxnard",
      parent: "CA",
      value: "Oxnard",
    },
    {
      name: "Palm Bay",
      parent: "FL",
      value: "Palm Bay",
    },
    {
      name: "Palmdale",
      parent: "CA",
      value: "Palmdale",
    },
    {
      name: "Paradise",
      parent: "NV",
      value: "Paradise",
    },
    {
      name: "Pasadena",
      parent: "TX",
      value: "Pasadena",
    },
    {
      name: "Pasadena",
      parent: "CA",
      value: "Pasadena",
    },
    {
      name: "Paterson",
      parent: "NJ",
      value: "Paterson",
    },
    {
      name: "Pembroke Pines",
      parent: "FL",
      value: "Pembroke Pines",
    },
    {
      name: "Peoria",
      parent: "AZ",
      value: "Peoria",
    },
    {
      name: "Peoria",
      parent: "IL",
      value: "Peoria",
    },
    {
      name: "Phoenix",
      parent: "AZ",
      value: "Phoenix",
    },
    {
      name: "Pittsburgh",
      parent: "PA",
      value: "Pittsburgh",
    },
    {
      name: "Plano",
      parent: "TX",
      value: "Plano",
    },
    {
      name: "Pomona",
      parent: "CA",
      value: "Pomona",
    },
    {
      name: "Ponce",
      parent: "PR",
      value: "Ponce",
    },
    {
      name: "Portland",
      parent: "OR",
      value: "Portland",
    },
    {
      name: "Providence",
      parent: "RI",
      value: "Providence",
    },
    {
      name: "Provo",
      parent: "UT",
      value: "Provo",
    },
    {
      name: "Pueblo",
      parent: "CO",
      value: "Pueblo",
    },
    {
      name: "Quito",
      parent: "OC",
      value: "Quito",
    },
    {
      name: "Raleigh",
      parent: "NC",
      value: "Raleigh",
    },
    {
      name: "Rancho Cucamonga",
      parent: "CA",
      value: "Rancho Cucamonga",
    },
    {
      value: "Rehoboth Beach",
      name: "Rehoboth Beach",
      parent: "DE",
    },
    {
      name: "Reno",
      parent: "NV",
      value: "Reno",
    },
    {
      name: "Richmond",
      parent: "VA",
      value: "Richmond",
    },
    {
      name: "Richmond",
      parent: "CA",
      value: "Richmond",
    },
    {
      name: "Riverside",
      parent: "CA",
      value: "Riverside",
    },
    {
      name: "Rochester",
      parent: "NY",
      value: "Rochester",
    },
    {
      name: "Rochester",
      parent: "MN",
      value: "Rochester",
    },
    {
      name: "Rockford",
      parent: "IL",
      value: "Rockford",
    },
    {
      name: "Roseville",
      parent: "CA",
      value: "Roseville",
    },
    {
      name: "Sacramento",
      parent: "CA",
      value: "Sacramento",
    },
    {
      name: "Saint Lucie",
      parent: "FL",
      value: "Saint Lucie",
    },
    {
      name: "Saint Paul",
      parent: "MN",
      value: "Saint Paul",
    },
    {
      name: "Salem",
      parent: "OR",
      value: "Salem",
    },
    {
      name: "Salinas",
      parent: "CA",
      value: "Salinas",
    },
    {
      name: "Salt Lake City",
      parent: "UT",
      value: "Salt Lake City",
    },
    {
      name: "San Antonio",
      parent: "TX",
      value: "San Antonio",
    },
    {
      name: "San Bernardino",
      parent: "CA",
      value: "San Bernardino",
    },
    {
      name: "San Diego",
      parent: "CA",
      value: "San Diego",
    },
    {
      name: "San Francisco",
      parent: "CA",
      value: "San Francisco",
    },
    {
      name: "San José",
      parent: "CA",
      value: "San José",
    },
    {
      name: "San Juan",
      parent: "PR",
      value: "San Juan",
    },
    {
      name: "San Luis",
      parent: "MO",
      value: "San Luis",
    },
    {
      name: "San Petersburgo",
      parent: "FL",
      value: "San Petersburgo",
    },
    {
      name: "Santa Ana",
      parent: "CA",
      value: "Santa Ana",
    },
    {
      name: "Santa Clara",
      parent: "CA",
      value: "Santa Clara",
    },
    {
      name: "Santa Clarita",
      parent: "CA",
      value: "Santa Clarita",
    },
    {
      name: "Santa Monica",
      parent: "CA",
      value: "Santa Monica",
    },
    {
      name: "Santa Rosa",
      parent: "CA",
      value: "Santa Rosa",
    },
    {
      name: "Santiago",
      parent: "OC",
      value: "Santiago",
    },
    {
      name: "Savannah",
      parent: "GA",
      value: "Savannah",
    },
    {
      name: "Scottsdale",
      parent: "AZ",
      value: "Scottsdale",
    },
    {
      value: "Seaford",
      name: "Seaford",
      parent: "DE",
    },
    {
      name: "Seattle",
      parent: "WA",
      value: "Seattle",
    },
    {
      name: "Shreveport",
      parent: "LA",
      value: "Shreveport",
    },
    {
      name: "Simi Valley",
      parent: "CA",
      value: "Simi Valley",
    },
    {
      name: "Sioux Falls",
      parent: "SD",
      value: "Sioux Falls",
    },
    {
      name: "Siracusa",
      parent: "NY",
      value: "Siracusa",
    },
    {
      value: "Smyrna",
      name: "Smyrna",
      parent: "DE",
    },
    {
      name: "South Bend",
      parent: "IN",
      value: "South Bend",
    },
    {
      name: "Spokane",
      parent: "WA",
      value: "Spokane",
    },
    {
      name: "Springfield",
      parent: "MO",
      value: "Springfield",
    },
    {
      name: "Springfield",
      parent: "MA",
      value: "Springfield",
    },
    {
      name: "Springfield",
      parent: "IL",
      value: "Springfield",
    },
    {
      name: "Stamford",
      parent: "CT",
      value: "Stamford",
    },
    {
      name: "Sterling Heights",
      parent: "MI",
      value: "Sterling Heights",
    },
    {
      name: "Stockton",
      parent: "CA",
      value: "Stockton",
    },
    {
      name: "Sunnyvale",
      parent: "CA",
      value: "Sunnyvale",
    },
    {
      name: "Surprise",
      parent: "AZ",
      value: "Surprise",
    },
    {
      name: "Tacoma",
      parent: "WA",
      value: "Tacoma",
    },
    {
      name: "Tallahassee",
      parent: "FL",
      value: "Tallahassee",
    },
    {
      name: "Tampa",
      parent: "FL",
      value: "Tampa",
    },
    {
      name: "Temecula",
      parent: "CA",
      value: "Temecula",
    },
    {
      name: "Tempe",
      parent: "AZ",
      value: "Tempe",
    },
    {
      name: "Thornton",
      parent: "CO",
      value: "Thornton",
    },
    {
      name: "Thousand Oaks",
      parent: "CA",
      value: "Thousand Oaks",
    },
    {
      name: "Toledo",
      parent: "OH",
      value: "Toledo",
    },
    {
      name: "Topeka",
      parent: "KS",
      value: "Topeka",
    },
    {
      name: "Torrance",
      parent: "CA",
      value: "Torrance",
    },
    {
      name: "Tucson",
      parent: "AZ",
      value: "Tucson",
    },
    {
      name: "Tulsa",
      parent: "OK",
      value: "Tulsa",
    },
    {
      name: "Vallejo",
      parent: "CA",
      value: "Vallejo",
    },
    {
      name: "Vancouver",
      parent: "WA",
      value: "Vancouver",
    },
    {
      name: "Ventura",
      parent: "CA",
      value: "Ventura",
    },
    {
      name: "Victorville",
      parent: "CA",
      value: "Victorville",
    },
    {
      name: "Virginia Beach",
      parent: "VA",
      value: "Virginia Beach",
    },
    {
      name: "Visalia",
      parent: "CA",
      value: "Visalia",
    },
    {
      name: "Waco",
      parent: "TX",
      value: "Waco",
    },
    {
      name: "Warren",
      parent: "MI",
      value: "Warren",
    },
    {
      name: "Washington D. C.",
      parent: "DC",
      value: "Washington D. C.",
    },
    {
      name: "Waterbury",
      parent: "CT",
      value: "Waterbury",
    },
    {
      name: "West Covina",
      parent: "CA",
      value: "West Covina",
    },
    {
      name: "West Jordan",
      parent: "UT",
      value: "West Jordan",
    },
    {
      name: "West Valley City",
      parent: "UT",
      value: "West Valley City",
    },
    {
      name: "Westminster",
      parent: "CO",
      value: "Westminster",
    },
    {
      name: "Wichita",
      parent: "KS",
      value: "Wichita",
    },
    {
      name: "Wichita Falls",
      parent: "TX",
      value: "Wichita Falls",
    },
    {
      name: "Wilmington",
      parent: "NC",
      value: "Wilmington",
    },
    {
      value: "Wilmington",
      name: "Wilmington",
      parent: "DE",
    },
    {
      name: "Winston-Salem",
      parent: "NC",
      value: "Winston-Salem",
    },
    {
      name: "Worcester",
      parent: "MA",
      value: "Worcester",
    },
    {
      name: "Yonkers",
      parent: "NY",
      value: "Yonkers",
    },
  ],
};
