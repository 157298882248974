import React from "react";
import { Box, Typography } from "@mui/material";
import { boardMembersStyles as styles } from "./BoardMembers.styles";
import { IBoardMembersProps } from "./BoardMembers.interfaces";
import BoardMemberAccordion from "./BoardMembersAccordion/BoardMemberAccordion";
import { isEmpty, slice } from "lodash";
import ModalBoardMembers from "./ModalBoardMembers/ModalBoardMembers";
import { useBoardMembers } from "./state/useBoardMembers";
import { IBoardMember } from "../../store/interfaces/LegalDetailsState.interfaces";
import {
  BoardMemberContentEnum,
  BoardMemberModalEnum,
} from "../../shared/enum/BoardMemberContentEnum";

const BoardMembers: React.FC<IBoardMembersProps> = ({
  boardMembers,
  openDeleteModal,
  setSelectedBoardMember,
}: IBoardMembersProps) => {
  const {
    openModal,
    handlers: { handleOpenModal, handleCloseModal },
  } = useBoardMembers();

  return (
    <Box>
      {!isEmpty(boardMembers) && (
        <Box>
          <Box>
            {slice(boardMembers, 0, 3).map((boardMember: IBoardMember) => (
              <BoardMemberAccordion
                key={`${boardMember.id}`}
                boardMember={boardMember}
                showMenuActions={true}
                variant={BoardMemberContentEnum.SECONDARY}
                openDeleteModal={openDeleteModal}
                setSelectedBoardMember={setSelectedBoardMember}
              />
            ))}
          </Box>
          {boardMembers.length > 3 && (
            <Box sx={styles.showAllWrapper}>
              <Typography sx={styles.showAll} onClick={handleOpenModal}>
                {BoardMemberModalEnum.SEE_ALL}
              </Typography>
            </Box>
          )}
          <ModalBoardMembers
            boardMembers={boardMembers}
            handleCloseModal={handleCloseModal}
            openModal={openModal}
            openDeleteModal={openDeleteModal}
            setSelectedBoardMember={setSelectedBoardMember}
          />
        </Box>
      )}
    </Box>
  );
};

export default BoardMembers;
