import React, { useEffect, useState } from "react";
import { IModalDetailInfo } from "../ModalDetailInfo.interfaces";
import { defaultTo, get, isEmpty } from "lodash";
import { TransactionInfo } from "../../../../types/transactions_data";
import { useAppSelector } from "../../../store/hooks/storeHook";
import { TransactionTypeEnum } from "../../../shared/enums/TransactionTypeEnum";
import { Chargeback } from "../../../../types/chargeback";

export const useModalDetailInfo = ({
  transactionInfo,
  modal,
}: IModalDetailInfo): {
  availableSectionSummary: boolean;
  handleClose: () => void;
  changeTab: (currentTab: number) => void;
  tabIndex: number;
  transactionInfo: Chargeback;
} => {
  const { isLoadingTransaction, transactionFatherData } = useAppSelector(
    (state) => ({
      ...state.app,
    })
  );

  const [currentTransaction, setCurrentTransaction] = useState<Chargeback>({
    approval_code: "",
    approved_transaction_amount: 0,
    created: "",
    credential_metadata: {},
    currency_code: "",
    deadLine: 0,
    doc_files: [],
    ice_value: 0,
    iva_value: 0,
    mail_info: [],
    masked_credit_card: "",
    merchant_id: "",
    merchant_name: "",
    metadata: {},
    payment_brand: "",
    payment_method: "",
    previous_status: [],
    sale_ticket_number: "",
    subtotal_iva: 0,
    subtotal_iva0: 0,
    ticket_number: "",
    transaction_id: "",
    transaction_reference: "",
    transaction_status: "",
  });
  const [tabIndex, setTabIndex] = React.useState<number>(0);
  const [availableSectionSummary, setAvailableSectionSummary] =
    React.useState<boolean>(false);
  const handleClose = (): void => {
    modal.setIsOpenModalDetailInfo(false);
  };
  const changeTab = (currentTab: number) => {
    if (!isLoadingTransaction) setTabIndex(currentTab);
  };

  useEffect(() => {
    changeTab(tabIndex);
  }, [tabIndex]);
  useEffect(() => {
    if (!modal.isOpenModalDetailInfo) {
      setTabIndex(0);
      setAvailableSectionSummary(false);
    }
  }, [modal.isOpenModalDetailInfo]);

  useEffect(() => {
    if (!isEmpty(defaultTo(transactionInfo, undefined))) {
      setCurrentTransaction(transactionInfo);
    }
  }, [transactionInfo]);
  useEffect(() => {
    if (transactionFatherData) {
      const getTransactionFatherData: TransactionInfo = get(
        transactionFatherData,
        "data[0]._source",
        {}
      );
      const transaction_type: string = get(
        getTransactionFatherData,
        "transaction_type",
        ""
      );

      setAvailableSectionSummary(
        transaction_type === TransactionTypeEnum.VOID ||
          transaction_type === TransactionTypeEnum.VOID_PARTIAL ||
          transaction_type === TransactionTypeEnum.REFUND_TOTAL ||
          transaction_type === TransactionTypeEnum.REFUND_PARTIAL
      );
      if (!isLoadingTransaction) {
        changeTab(0);
      }
    }
  }, [transactionFatherData]);

  return {
    availableSectionSummary,
    changeTab,
    handleClose,
    tabIndex,
    transactionInfo: currentTransaction,
  };
};
