/**
 * Empty State Enum
 */

export enum EmptyStateLabels {
  TITLE = "No hay datos para mostrar.",
  INITIAL_DESCRIPTION = "Ingresa el país y la fecha para generar tu consulta.",
  EMPTY_DESCRIPTION = "Los datos no son suficientes para mostrar un resultado.",
  ERROR_DESCRIPTION = "Hubo un problema con la consulta, inténtalo de nuevo.",
}
