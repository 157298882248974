import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { useEffect } from "react";
import {
  getSmartLinks,
  getVpos,
} from "../../../store/thunks/services/services.thunk";
import { IServicesInfoProps } from "./useServicesInfo.interfaces";

export const useServicesInfo = (
  publicMerchantId?: string
): IServicesInfoProps => {
  const { smartlinks, vpos } = useAppSelector((state) => ({
    ...state.servicesApp,
  }));

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (publicMerchantId) {
      dispatch(
        getSmartLinks({
          publicMerchantId,
        })
      );
      dispatch(
        getVpos({
          publicMerchantId,
        })
      );
    }
  }, [publicMerchantId]);

  return { smartlinks, vpos };
};
