/* istanbul ignore file */
export enum CountryEnum {
  BRAZIL = "Brazil",
  CHILE = "Chile",
  COLOMBIA = "Colombia",
  ECUADOR = "Ecuador",
  MEXICO = "Mexico",
  PERU = "Peru",
  GUATEMALA = "Guatemala",
  PANAMA = "Panama",
  NICARAGUA = "Nicaragua",
  HONDURAS = "Honduras",
  SALVADOR = "El Salvador",
  COSTA_RICA = "Costa Rica",
}
