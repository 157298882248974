import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { IconPlus } from "@kushki/connect-ui";
import { emptyBoardMembersStyles as styles } from "./EmptyBoardMembers.styles";
import { useLocation } from "react-router";
import { QueryParamsEnum } from "../../../shared/enums/queryParamsEnum";
const EmptyBoardMembers: React.FC = () => {
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  const publicMerchantId = searchParams.get(QueryParamsEnum.PUBLIC_MERCHANT_ID);

  const handleRedirect = () => {
    window.location.href = `/merchant/legal-details?publicMerchantId=${publicMerchantId}&menuItem=true`;
  };

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.text}>No hay Board members registrados</Typography>
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<IconPlus />}
        onClick={handleRedirect}
      >
        Agregar Board member
      </Button>
    </Box>
  );
};

export default EmptyBoardMembers;
