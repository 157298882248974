import { environment } from "../../environments/environment";

export const API_ROUTES = {
  CREATE_RULE: `${environment.kushkiUrl}/payouts-rules/v1/admin/rule`,
  GET_ATOMIC_ID: `${environment.kushkiUrl}/payouts-rules/v1/counter/identifier`,
  GET_CONFIGURATION: `${environment.kushkiUrl}/request-manager/v1/configuration`,
  GET_PAYOUTS_RULES: `${environment.kushkiUrl}/payouts-rules/v1/admin/rule/list`,
  GET_PROCESSORS: `${environment.kushkiUrl}/merchant/v1/admin/merchant/$merchantId/processors`,
  GET_RULE_BY_ID: `${environment.kushkiUrl}/payouts-rules/v1/admin/business/rule/$id`,
  UPDATE_RULE: `${environment.kushkiUrl}/payouts-rules/v1/admin/rule/$id`,
};
