import * as React from "react";
import { SvgIconProps } from "@mui/material";

const IconSmartlinks: React.FunctionComponent<SvgIconProps> = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_334_144532)">
        <path
          d="M9.29907 9.89999C9.4234 10.062 9.57962 10.1967 9.75807 10.296C9.94103 10.3961 10.1439 10.4545 10.3521 10.467C10.5566 10.4856 10.7627 10.4579 10.9551 10.386C11.1485 10.314 11.3236 10.2003 11.4681 10.053L12.3681 9.15299C12.5007 9.01954 12.6049 8.86065 12.6745 8.68588C12.7441 8.5111 12.7776 8.32406 12.7731 8.13599C12.7544 7.76437 12.5918 7.41462 12.3196 7.16092C12.0474 6.90721 11.6871 6.7695 11.3151 6.77699C11.1257 6.77478 10.9379 6.81115 10.7631 6.88387C10.5882 6.95659 10.43 7.06415 10.2981 7.19999L9.80307 7.68599"
          stroke="#112B45"
          stroke-width="0.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.4509 9.35098C10.3289 9.18312 10.1711 9.04439 9.98904 8.94479C9.80696 8.84519 9.60507 8.7872 9.39789 8.77498C9.19254 8.7548 8.98539 8.78571 8.79489 8.86498C8.60206 8.93377 8.42686 9.04442 8.28189 9.18898L7.38189 10.089C7.24971 10.2227 7.14576 10.3817 7.07619 10.5564C7.00662 10.7311 6.97285 10.918 6.97689 11.106C6.97741 11.2936 7.01506 11.4793 7.08765 11.6523C7.16025 11.8253 7.26637 11.9822 7.39989 12.114C7.66793 12.38 8.03025 12.5293 8.40789 12.5293C8.78552 12.5293 9.14784 12.38 9.41589 12.114L9.91089 11.619"
          stroke="#112B45"
          stroke-width="0.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.4271 1.35901H3.31412C2.24048 1.35901 1.37012 2.22937 1.37012 3.30301V15.291C1.37012 16.3646 2.24048 17.235 3.31412 17.235H16.4271C17.5008 17.235 18.3711 16.3646 18.3711 15.291V3.30301C18.3711 2.22937 17.5008 1.35901 16.4271 1.35901Z"
          stroke="#112B45"
          stroke-width="1.2"
          stroke-miterlimit="10"
        />
        <path
          d="M13.3131 15.741H6.4371C6.2571 15.741 6.1311 15.543 6.1311 15.264C6.1311 14.985 6.2571 14.778 6.4371 14.778H13.3131C13.4931 14.778 13.6191 14.976 13.6191 15.264C13.6191 15.552 13.4751 15.741 13.3131 15.741Z"
          fill="#112B45"
        />
        <path
          d="M17.6871 4.50001H2.06308C1.64008 4.50001 1.36108 4.26601 1.36108 3.94201C1.36108 3.61801 1.64008 3.39301 2.06308 3.39301H17.6871C18.1101 3.39301 18.3891 3.60901 18.3891 3.94201C18.3891 4.27501 18.1101 4.50001 17.6871 4.50001Z"
          fill="#112B45"
        />
      </g>
      <defs>
        <clipPath id="clip0_334_144532">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.875)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconSmartlinks;
