import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import * as React from "react";
import { FC } from "react";
import { TableSkeletonStyles } from "./TableSkeleton.styles";
import { TableNodesStyle } from "../Table/TableNodes/TableNodes.styles";
import { TableBodyStyles } from "../Table/TableBody/TableBody.style";
import { HeadCell } from "../Table/TableHead/TableHead.interfaces";
import { defaultTo, times } from "lodash";
import { EnhancedTableProps } from "../Table/TableNodes/interfaces";
import { TableHeadStyles } from "../Table/TableHead/TableHead.style";

interface ITableSkeletonProps {
  headTable: HeadCell[];
  rowsPerPage: number;
}

function SkeletonTableHead(props: Partial<EnhancedTableProps>) {
  const { headCells } = props;

  return (
    <TableHead>
      <TableRow sx={TableHeadStyles.row}>
        <TableCell
          padding="checkbox"
          sx={{ paddingLeft: "3%", paddingRight: "3%" }}
        >
          —
        </TableCell>
        {defaultTo(headCells, []).map((headCell) => (
          <TableCell
            sx={TableHeadStyles.label}
            key={headCell.id}
            align={"center"}
            data-testid="TableCellHeader"
          >
            <Skeleton
              variant={"rectangular"}
              animation="wave"
              sx={TableSkeletonStyles.titleSkeletonMobile}
            />
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const TableSkeleton: FC<ITableSkeletonProps> = ({
  headTable,
  rowsPerPage,
}: ITableSkeletonProps) => {
  return (
    <Box data-testid="TableSkeleton">
      <TableContainer>
        <Box>
          <Table sx={TableNodesStyle.table} aria-labelledby="tableTitle">
            <SkeletonTableHead headCells={headTable} />

            <TableBody>
              {times(rowsPerPage, (num) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  sx={TableBodyStyles.row}
                  key={`row-${num}`}
                >
                  <TableCell
                    scope="row"
                    padding="none"
                    align={"center"}
                    sx={{ paddingLeft: "2.5%", width: "6%" }}
                  >
                    <Skeleton variant={"rectangular"} animation="wave" />
                  </TableCell>
                  {headTable.map((item) => (
                    <TableCell
                      key={item.id}
                      scope="row"
                      padding="none"
                      align={"center"}
                      data-testid={`TableCell-${item.id}`}
                    >
                      <Skeleton
                        variant={"rectangular"}
                        animation="wave"
                        sx={TableSkeletonStyles.titleSkeletonMobile}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
    </Box>
  );
};

export { TableSkeleton };
