export enum DynamoReferenceEnum {
  ACCOUNT_BANK = "accountBank",
  ACCOUNT_TYPE = "accountType",
  ADDRESS = "address",
  AMOUNT = "amount",
  AMOUNT_RETENTION = "amountRetention",
  BACKOFFICE_USER = "backofficeUser",
  BANK_ID = "bankId",
  CALCULATE_CYCLE = "calculateCycle",
  COUNTERPART = "counterpart",
  COUNTRY = "country",
  CREATED = "created",
  CREATED_RETENTION = "createdRetention",
  CURRENCY_CODE = "currency",
  CUSTOMER_ID = "customerId",
  CUSTOMER_ID_HIERARCHY = "customerIdHierarchy",
  CUSTOMER_NAME = "customerName",
  CYCLE = "cycle",
  DESCRIPTION = "description",
  DISPERSION_AMOUNT = "dispersionAmount",
  DOCUMENT_NUMBER_BANK = "documentNumberBank",
  EMAIL = "email",
  END_DATE = "endDate",
  HAS_FRAUD_RETENTION = "has_fraud_retention",
  HAS_FRAUD_RETENTION_PENDING = "hasFraudRetentionPending",
  HAS_MIN_AMOUNT_CHARGE = "hasMinAmountCharge",
  INTERBANK_ACCOUNT_CODE = "interbankAccountCode",
  INVOICE_DOCUMENT_NUMBER = "invoiceDocumentNumber",
  INVOICE_ID = "invoiceId",
  INVOICE_MODE = "invoiceMode",
  INVOICE_NUMBER = "invoiceNumber",
  KIND = "kind",
  KUSHKI_COMMISSION = "kushkiCommission",
  MERCHANT_ID = "merchantId",
  MERCHANT_NAME = "merchantName",
  MIN_AMOUNT = "minAmount",
  NAME_MERCHANT_BANK = "nameMerchantBank",
  IS_OCB = "isOcb",
  IVA_KUSHKI_COMMISSION = "ivaKushkiCommission",
  OLD_TRANSACTIONS = "oldTransactions",
  OMIT = "omit",
  OWNER_ID = "ownerId",
  OWNER_NAME = "ownerName",
  REJECT = "reject",
  RESPONSE_BANK = "responseBank",
  SOCIAL_REASON = "socialReason",
  START_DATE = "startDate",
  STATUS = "status",
  INITIAL_STATUS = "initialStatus",
  STATUS_FLOW = "statusFlow",
  TAX_ID = "taxId",
  TOTAL_AMOUNT_TRX = "totalAmountTrx",
  TOTAL_NUMBER_TRANSACTIONS = "totalNumberTransactions",
  TRANSACTED = "transacted",
  REASON = "reason",
  RESPONSE_INVOICE = "responseInvoice",
  TRANSACTION_ID = "transactionId",
  TRANSACTION_TYPE = "transactionType",
  UPDATED_AT = "updatedAt",
  ADVANCE_SETTINGS_REFERENCE = "modify.advanceSettingsReference",
  ADVANCE_SETTINGS_PARAMETERS = "modify.advanceSettingsParameters",
  INVOICE_INFO = "modify.invoiceInfo",
  AMOUNT_CHANGE_INFO_COMMENTS = "modify.reason_change_amount",
  AMOUNT_CHANGE_INFO_VALUE = "modify.value",
  INITIAL_AMOUNTS = "modify.initialAmount",
  MODIFIED_AMOUNTS = "modify.modifyAmount",
  TAXPAYER_TYPE_ID = "taxpayerTypeId",
  ACTIVITY_ID = "activityId",
  INCOME_SOURCE_CONCEPT_ID = "incomeSourceConceptId",
  RET_TOTAL_AMOUNT = "retTotalAmount",
  REFERENCE_ANNUL = "referenceAnnul",
  amountRetIva = "amountRetIva",
  RET_TOTAL_AMOUNT_DAVI = "retTotalAmountDavi",
  STATUS_ROW = "statusRow",
}

export enum ElasticReferenceEnum {
  ACCOUNT_BANK = "account_bank",
  ACCOUNT_TYPE = "account_type",
  ADDRESS = "address",
  AMOUNT = "amount",
  AMOUNT_RETENTION = "amount_retention",
  BACKOFFICE_USER = "backoffice_user",
  BANK_ID = "bank_id",
  CALCULATE_CYCLE = "calculate_cycle",
  COUNTERPART = "counterpart",
  COUNTRY = "country",
  CREATED = "created",
  CREATED_RETENTION = "created_retention",
  CURRENCY_CODE = "currency_code",
  CYCLE = "cycle",
  DESCRIPTION = "description",
  DISPERSION_AMOUNT = "dispersion_amount",
  DOCUMENT_NUMBER_BANK = "document_number_bank",
  EMAIL = "email",
  END_DATE = "end_date",
  HAS_FRAUD_RETENTION = "has_fraud_retention",
  HAS_FRAUD_RETENTION_PENDING = "has_fraud_retention_pending",
  HAS_MIN_AMOUNT_CHARGE = "has_min_amount_charge",
  INTERBANK_ACCOUNT_CODE = "interbank_account_code",
  INVOICE_DOCUMENT_NUMBER = "invoice_document_number",
  INVOICE_ID = "invoice_id",
  INVOICE_MODE = "invoice_mode",
  INVOICE_NUMBER = "invoice_number",
  KIND = "kind",
  KUSHKI_COMMISSION = "kushki_commission",
  MERCHANT_ID = "merchant_id",
  MERCHANT_NAME = "merchant_name",
  MODIFY = "modify",
  MODIFY_AMOUNT = "modify_Amount",
  MIN_AMOUNT = "min_amount",
  NAME_MERCHANT_BANK = "name_merchant_bank",
  IS_OCB = "is_ocb",
  IVA_KUSHKI_COMMISSION = "iva_kushki_commission",
  OLD_TRANSACTIONS = "oldTransactions",
  REJECT = "reject",
  OMIT = "omit",
  REASON = "reason",
  RESPONSE_BANK = "response_bank",
  SOCIAL_REASON = "social_reason",
  START_DATE = "start_date",
  STATUS = "status",
  INITIAL_STATUS = "initial_status",
  STATUS_FLOW = "status_flow",
  TAX_ID = "tax_id",
  TOTAL_AMOUNT_TRX = "total_amount_trx",
  TOTAL_NUMBER_TRANSACTIONS = "total_number_transactions",
  TRANSACTED = "transacted",
  RESPONSE_INVOICE = "response_invoice",
  TRANSACTION_ID = "transaction_id",
  TRANSACTION_TYPE = "transaction_type",
  UPDATED_AT = "updated_at",
  INITIAL_AMOUNTS = "modify.initial_amounts",
  MODIFIED_AMOUNTS = "modify.modified_amounts",
  ADVANCE_SETTINGS_REFERENCE = "advance_settings_reference",
  ADVANCE_SETTINGS_PARAMETERS = "advance_settings_parameters",
  INVOICE_INFO = "invoice_info",
  AMOUNT_MODIFIED = "modified_amounts",
  AMOUNT_CHANGE_INFO = "amount_change_info",
  AMOUNT_CHANGE_INFO_COMMENTS = "amount_change_info.comments",
  AMOUNT_CHANGE_INFO_VALUE = "amount_change_info.value",
  AMOUNT_CHANGE_INFO_MODIFIED_AMOUNT = "amount_change_info.modified_amounts",
  TAXPAYER_TYPE_ID = "taxpayer_type_id",
  ACTIVITY_ID = "activity_id",
  INCOME_SOURCE_CONCEPT_ID = "income_source_concept_id",
  RET_TOTAL_AMOUNT = "ret_total_amount",
  REFERENCE_ANNUL = "reference_annul",
  amountRetIva = "amountRetIva,",
  RET_TOTAL_AMOUNT_DAVI = "ret_total_amount_davi",
  STATUS_ROW = "status_row",
  OWNER_ID = "owner_id",
  ONWER_NAME = "owner_name",
  CUSTOMER_ID = "customer_id",
  CUSTOMER_ID_HIERARCHY = "customer_id_hierarchy",
  CUSTOMER_NAME = "customer_name",
}

export const DYNAMO_ELASTIC_REFERENCE: Record<
  DynamoReferenceEnum,
  ElasticReferenceEnum
> = {
  [DynamoReferenceEnum.ACCOUNT_BANK]: ElasticReferenceEnum.ACCOUNT_BANK,
  [DynamoReferenceEnum.ACCOUNT_TYPE]: ElasticReferenceEnum.ACCOUNT_TYPE,
  [DynamoReferenceEnum.ADDRESS]: ElasticReferenceEnum.ADDRESS,
  [DynamoReferenceEnum.AMOUNT]: ElasticReferenceEnum.AMOUNT,
  [DynamoReferenceEnum.AMOUNT_RETENTION]: ElasticReferenceEnum.AMOUNT_RETENTION,
  [DynamoReferenceEnum.BACKOFFICE_USER]: ElasticReferenceEnum.BACKOFFICE_USER,
  [DynamoReferenceEnum.BANK_ID]: ElasticReferenceEnum.BANK_ID,
  [DynamoReferenceEnum.CALCULATE_CYCLE]: ElasticReferenceEnum.CALCULATE_CYCLE,
  [DynamoReferenceEnum.COUNTERPART]: ElasticReferenceEnum.COUNTERPART,
  [DynamoReferenceEnum.COUNTRY]: ElasticReferenceEnum.COUNTRY,
  [DynamoReferenceEnum.CREATED]: ElasticReferenceEnum.CREATED,
  [DynamoReferenceEnum.CREATED_RETENTION]:
    ElasticReferenceEnum.CREATED_RETENTION,
  [DynamoReferenceEnum.CURRENCY_CODE]: ElasticReferenceEnum.CURRENCY_CODE,
  [DynamoReferenceEnum.CYCLE]: ElasticReferenceEnum.CYCLE,
  [DynamoReferenceEnum.DESCRIPTION]: ElasticReferenceEnum.DESCRIPTION,
  [DynamoReferenceEnum.DISPERSION_AMOUNT]:
    ElasticReferenceEnum.DISPERSION_AMOUNT,
  [DynamoReferenceEnum.DOCUMENT_NUMBER_BANK]:
    ElasticReferenceEnum.DOCUMENT_NUMBER_BANK,
  [DynamoReferenceEnum.EMAIL]: ElasticReferenceEnum.EMAIL,
  [DynamoReferenceEnum.END_DATE]: ElasticReferenceEnum.END_DATE,
  [DynamoReferenceEnum.HAS_FRAUD_RETENTION]:
    ElasticReferenceEnum.HAS_FRAUD_RETENTION,
  [DynamoReferenceEnum.HAS_FRAUD_RETENTION_PENDING]:
    ElasticReferenceEnum.HAS_FRAUD_RETENTION_PENDING,
  [DynamoReferenceEnum.HAS_MIN_AMOUNT_CHARGE]:
    ElasticReferenceEnum.HAS_MIN_AMOUNT_CHARGE,
  [DynamoReferenceEnum.INTERBANK_ACCOUNT_CODE]:
    ElasticReferenceEnum.INTERBANK_ACCOUNT_CODE,
  [DynamoReferenceEnum.INVOICE_DOCUMENT_NUMBER]:
    ElasticReferenceEnum.INVOICE_DOCUMENT_NUMBER,
  [DynamoReferenceEnum.INVOICE_ID]: ElasticReferenceEnum.INVOICE_ID,
  [DynamoReferenceEnum.INVOICE_MODE]: ElasticReferenceEnum.INVOICE_MODE,
  [DynamoReferenceEnum.INVOICE_NUMBER]: ElasticReferenceEnum.INVOICE_NUMBER,
  [DynamoReferenceEnum.KIND]: ElasticReferenceEnum.KIND,
  [DynamoReferenceEnum.KUSHKI_COMMISSION]:
    ElasticReferenceEnum.KUSHKI_COMMISSION,
  [DynamoReferenceEnum.MERCHANT_ID]: ElasticReferenceEnum.MERCHANT_ID,
  [DynamoReferenceEnum.MERCHANT_NAME]: ElasticReferenceEnum.MERCHANT_NAME,
  [DynamoReferenceEnum.MIN_AMOUNT]: ElasticReferenceEnum.MIN_AMOUNT,
  [DynamoReferenceEnum.NAME_MERCHANT_BANK]:
    ElasticReferenceEnum.NAME_MERCHANT_BANK,
  [DynamoReferenceEnum.IVA_KUSHKI_COMMISSION]:
    ElasticReferenceEnum.IVA_KUSHKI_COMMISSION,
  [DynamoReferenceEnum.OLD_TRANSACTIONS]: ElasticReferenceEnum.OLD_TRANSACTIONS,
  [DynamoReferenceEnum.OMIT]: ElasticReferenceEnum.OMIT,
  [DynamoReferenceEnum.REJECT]: ElasticReferenceEnum.REJECT,
  [DynamoReferenceEnum.RESPONSE_BANK]: ElasticReferenceEnum.RESPONSE_BANK,
  [DynamoReferenceEnum.SOCIAL_REASON]: ElasticReferenceEnum.SOCIAL_REASON,
  [DynamoReferenceEnum.START_DATE]: ElasticReferenceEnum.START_DATE,
  [DynamoReferenceEnum.STATUS]: ElasticReferenceEnum.STATUS,
  [DynamoReferenceEnum.INITIAL_STATUS]: ElasticReferenceEnum.INITIAL_STATUS,
  [DynamoReferenceEnum.STATUS_FLOW]: ElasticReferenceEnum.STATUS_FLOW,
  [DynamoReferenceEnum.TAX_ID]: ElasticReferenceEnum.TAX_ID,
  [DynamoReferenceEnum.TOTAL_AMOUNT_TRX]: ElasticReferenceEnum.TOTAL_AMOUNT_TRX,
  [DynamoReferenceEnum.TOTAL_NUMBER_TRANSACTIONS]:
    ElasticReferenceEnum.TOTAL_NUMBER_TRANSACTIONS,
  [DynamoReferenceEnum.TRANSACTED]: ElasticReferenceEnum.TRANSACTED,
  [DynamoReferenceEnum.REASON]: ElasticReferenceEnum.REASON,
  [DynamoReferenceEnum.RESPONSE_INVOICE]: ElasticReferenceEnum.RESPONSE_INVOICE,
  [DynamoReferenceEnum.TRANSACTION_ID]: ElasticReferenceEnum.TRANSACTION_ID,
  [DynamoReferenceEnum.TRANSACTION_TYPE]: ElasticReferenceEnum.TRANSACTION_TYPE,
  [DynamoReferenceEnum.UPDATED_AT]: ElasticReferenceEnum.UPDATED_AT,
  [DynamoReferenceEnum.ADVANCE_SETTINGS_REFERENCE]:
    ElasticReferenceEnum.ADVANCE_SETTINGS_REFERENCE,
  [DynamoReferenceEnum.ADVANCE_SETTINGS_PARAMETERS]:
    ElasticReferenceEnum.ADVANCE_SETTINGS_PARAMETERS,
  [DynamoReferenceEnum.INVOICE_INFO]: ElasticReferenceEnum.INVOICE_INFO,
  [DynamoReferenceEnum.AMOUNT_CHANGE_INFO_COMMENTS]:
    ElasticReferenceEnum.AMOUNT_CHANGE_INFO_COMMENTS,
  [DynamoReferenceEnum.AMOUNT_CHANGE_INFO_VALUE]:
    ElasticReferenceEnum.AMOUNT_CHANGE_INFO_VALUE,
  [DynamoReferenceEnum.INITIAL_AMOUNTS]: ElasticReferenceEnum.INITIAL_AMOUNTS,
  [DynamoReferenceEnum.MODIFIED_AMOUNTS]: ElasticReferenceEnum.MODIFIED_AMOUNTS,
  [DynamoReferenceEnum.TAXPAYER_TYPE_ID]: ElasticReferenceEnum.TAXPAYER_TYPE_ID,
  [DynamoReferenceEnum.RET_TOTAL_AMOUNT]: ElasticReferenceEnum.RET_TOTAL_AMOUNT,
  [DynamoReferenceEnum.RET_TOTAL_AMOUNT_DAVI]:
    ElasticReferenceEnum.RET_TOTAL_AMOUNT_DAVI,
  [DynamoReferenceEnum.ACTIVITY_ID]: ElasticReferenceEnum.ACTIVITY_ID,
  [DynamoReferenceEnum.INCOME_SOURCE_CONCEPT_ID]:
    ElasticReferenceEnum.INCOME_SOURCE_CONCEPT_ID,
  [DynamoReferenceEnum.REFERENCE_ANNUL]: ElasticReferenceEnum.REFERENCE_ANNUL,
  [DynamoReferenceEnum.amountRetIva]: ElasticReferenceEnum.amountRetIva,
  [DynamoReferenceEnum.STATUS_ROW]: ElasticReferenceEnum.STATUS_ROW,
  [DynamoReferenceEnum.OWNER_ID]: ElasticReferenceEnum.OWNER_ID,
  [DynamoReferenceEnum.OWNER_NAME]: ElasticReferenceEnum.ONWER_NAME,
  [DynamoReferenceEnum.CUSTOMER_ID]: ElasticReferenceEnum.CUSTOMER_ID,
  [DynamoReferenceEnum.CUSTOMER_NAME]: ElasticReferenceEnum.CUSTOMER_NAME,
  [DynamoReferenceEnum.IS_OCB]: ElasticReferenceEnum.IS_OCB,
  [DynamoReferenceEnum.CUSTOMER_ID_HIERARCHY]:
    ElasticReferenceEnum.CUSTOMER_ID_HIERARCHY,
};

export const getDynamoElasticRef = (
  dynamoReference: DynamoReferenceEnum,
  isHistoric: boolean
) => {
  return isHistoric
    ? DYNAMO_ELASTIC_REFERENCE[dynamoReference]
    : dynamoReference;
};
