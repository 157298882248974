import { makeStyles } from "@material-ui/core/styles";

export const chipComponentStyle = makeStyles({
  chip: {
    borderRadius: "4px",
    backgroundColor: "#F0F4F9",
    padding: "8px",
    width: "auto",
    height: "30px",
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: "12px",
    lineHeight: "17px",
    color: "#293036",
  },
  chipSelected: {
    borderRadius: "4px",
    backgroundColor: "#0DC298",
    padding: "8px",
    width: "auto",
    height: "30px",
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: "12px",
    lineHeight: "17px",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#0DC298",
    },
  },
});
