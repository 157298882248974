import React from "react";
import { Box, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export const SkeletonInformation: React.FC = () => {
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Box display={"flex"} alignItems={"center"}>
              <Box flexGrow={1}>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={"100%"}
                  height={48}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Skeleton
              animation="wave"
              variant="rect"
              width={"100%"}
              height={48}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid
            container
            spacing={2}
            justify={"flex-start"}
            alignItems={"baseline"}
          >
            <Grid item xs={12} md={2}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={"100%"}
                height={48}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={"100%"}
                height={48}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={"100%"}
                height={48}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={"100%"}
                height={48}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={"100%"}
                height={48}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={"100%"}
                height={48}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={"100%"}
                height={48}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={"100%"}
                height={48}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={"100%"}
                height={48}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={"100%"}
                height={48}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
