import {
  IErrorEditMode,
  IUseAddWebhookFormContainerState as IUseState,
} from "./useAddWebhookFormContainerState.interfaces";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { IWebhookForm } from "../../../store/interfaces/webhook.interfaces";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { QueryParamsEnum } from "../../../shared/enums/queryParamsEnum";
import { ILastModify } from "../../../shared/interfaces/LastModify.interfaces";
import { useEffect, useMemo, useState } from "react";
import { buildLastModifyProps } from "../../../shared/constants/wrapper_container_constants";
import { get, isEmpty, uniq } from "lodash";
import {
  getNodeInfo,
  getWebhook,
} from "../../../store/thunks/webhook/webhook.thunks";
import { GetNodeInfoRequest } from "../../../../types/get_node_info_request";
import { ISubHeaderProps } from "../../../components/SubHeader/SubHeader.interfaces";
import {
  ERRORS_SECTION,
  INPUT_ERROR_MESSAGES,
} from "../../../shared/constants/labels/input_form_errors";
import { namesFormEnum } from "../../../shared/constants/enums/NamesFormEnum";
import {
  openSubmitModal,
  resetWebhook,
} from "../../../store/actions/webhook/webhook.actions";
import { WebhookEvent } from "../../../../types/webhook_create_request";
import { initialState } from "../../../store/reducers/webhook/webhook.slice";
import {
  Configs,
  GetNodeInfoResponse,
} from "../../../../types/get_node_info_response";
import { ConfigurationIdEnum } from "../../../shared/enums/ConfigurationIdEnum";

export const useAddWebhookFormContainerState = (): IUseState => {
  const [searchParams] = useSearchParams();
  const merchantId = searchParams.get(QueryParamsEnum.PUBLIC_MERCHANT_ID);
  const isEdition =
    searchParams.get(QueryParamsEnum.MODE) === QueryParamsEnum.EDITION;
  const errorEditFormList: IErrorEditMode[] = [];
  const webhookId = searchParams.get(QueryParamsEnum.WEBHOOK_ID);

  const dispatch = useAppDispatch();
  const [footerLabels, setFooterLabels] = useState<ILastModify | null>(null);
  const { addWebhookForm, nodeInfo } = useAppSelector((state) => ({
    ...state.webhook,
    ...state.app,
  }));
  const form = useForm<IWebhookForm>({
    defaultValues: { ...addWebhookForm },
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const [subHeaderData, setSubHeaderData] = useState<ISubHeaderProps>({
    country: "",
    customerId: "",
    merchantId: "",
    name: "",
  });
  const {
    control,
    resetField,
    reset,
    formState: { errors, isValid },
    setError,
    setValue,
  } = form;

  const updateLastModify = (nodeInfo: GetNodeInfoResponse) => {
    if (nodeInfo.configs) {
      nodeInfo.configs
        .filter(
          (config: Configs) =>
            config.configuration === ConfigurationIdEnum.CN014
        )
        .map((config: Configs) => {
          setFooterLabels(
            buildLastModifyProps(
              get(config, "updatedAt", 0),
              get(config, "updatedBy", "")
            )
          );
        });
    }
  };

  const handleSave = () => {
    isEmpty(addWebhookForm.alias) &&
      errorEditFormList.push({ nameSection: namesFormEnum.ALIAS });
    addWebhookForm.options.length === 0 &&
      errorEditFormList.push({ nameSection: namesFormEnum.OPTIONS });
    addWebhookForm.events.length === 0 &&
      errorEditFormList.push({ nameSection: namesFormEnum.EVENT });
    addWebhookForm.urls.length === 0 &&
      errorEditFormList.push({ nameSection: namesFormEnum.URL });

    if (errorEditFormList.length > 0) {
      errorEditFormList.map((error) => {
        setError(ERRORS_SECTION[error.nameSection].name, {
          message: ERRORS_SECTION[error.nameSection].errorMessage,
          type: INPUT_ERROR_MESSAGES.type,
        });
      });
    } else {
      dispatch(openSubmitModal(true));
    }
  };

  const getNodeInformation = async () => {
    if (merchantId) {
      const nodeInfoRequest: GetNodeInfoRequest = {
        publicMerchantId: merchantId,
      };

      dispatch(getNodeInfo(nodeInfoRequest));
      if (isEdition) {
        const { payload } = await dispatch(
          getWebhook({
            merchantId: `${merchantId}`,
            webhookId: `${webhookId}`,
          })
        );

        setValue("alias", get(payload, "alias", ""));

        const options: string[] = [];

        get(payload, "events", []).map((event: WebhookEvent) =>
          options.push(event.service!)
        );

        setValue("events", [...get(payload, "events", [])]);
        setValue("options", [...uniq(options)]);
      }
    }
  };

  const resetOnCreate = () => {
    form.reset({ ...initialState.addWebhookForm });
    dispatch(resetWebhook());
  };

  useMemo(() => {
    resetOnCreate();
  }, [isEdition]);

  useEffect(() => {
    getNodeInformation();
  }, [isEdition]);

  useEffect(() => {
    setSubHeaderData({
      country: get(nodeInfo, "generalInfo.country", ""),
      customerId: get(nodeInfo, "nodeId", ""),
      merchantId: get(nodeInfo, "merchantId", ""),
      name: get(nodeInfo, "generalInfo.name", ""),
    });
    updateLastModify(nodeInfo);
  }, [nodeInfo]);

  return {
    footerLabels,
    form: {
      control,
      errors,
      isValid,
      reset,
      resetField,
      setError,
    },
    handleSave,
    hookForm: form,
    isEdition,
    subHeaderData,
  };
};
