import React from "react";
import { Transaction, TransactionInfo } from "../../../types/transactions_data";
import TableComponent from "../TableComponent/TableComponent";
import { IColumns } from "../../shared/infrastructure/interfaces/IColumns";
import { PaginationProps } from "../Pagination/Pagination";

export interface IPayoutsTransactionTableProps {
  payoutsTransactions: Transaction[];
  selectedColumns: IColumns[];
  handleSelectPayoutsTransaction(checked: boolean, row: Transaction): void;
  handleCheckSelectedPayoutsTransaction(
    payoutsTransactionToCheck: Transaction
  ): boolean;
  sortBy: {
    sortByKey?: string;
    direction?: "asc" | "desc";
    handleSortByKey(key: string): void;
  };
  handleSetPayuotTransactionSelected(
    payoutTransactionSelected: TransactionInfo
  ): void;
  pagination: PaginationProps;
}

const PayoutsTransactionTable: React.FC<IPayoutsTransactionTableProps> = (
  props: IPayoutsTransactionTableProps
) => {
  return (
    <React.Fragment>
      <TableComponent
        payoutsTransactions={props.payoutsTransactions}
        selectedColumns={props.selectedColumns}
        handleSelectPayoutsTransaction={props.handleSelectPayoutsTransaction}
        handleCheckSelectedPayoutsTransaction={
          props.handleCheckSelectedPayoutsTransaction
        }
        sortBy={props.sortBy}
        handleSetPayuotTransactionSelected={
          props.handleSetPayuotTransactionSelected
        }
        pagination={props.pagination}
      />
    </React.Fragment>
  );
};

export default PayoutsTransactionTable;
