import { BreadcrumProps } from "../../../components/common/Breadcrumb/Breadcrumb";
import { routes } from "../../../shared/infrastructure/routes";
import { IPagination } from "../../../components/DashboardList/Table/DashboardConciliationTable/DashboardTable";
import { IStatusFlowData } from "../../../shared/infrastructure/interfaces/IStatusFlow";
import { TransactionTableSummary } from "../../../../types/transaction_table_summary";
import { CountryEnum } from "../../../shared/infrastructure/CountryEnum";
import { IOrderSort } from "../../../shared/infrastructure/interfaces/IOrderSort";
import {
  OrderSortEnum,
  OriginTransactionsEnum,
  RequestDownloadEnum,
  STATUSFLOW,
  TabsEnum,
} from "../../../shared/infrastructure/constants/StatusFlowDashboardConstants";
import React, { useEffect, useState } from "react";
import { TransactionSummaryData } from "../../../../types/transaction_summary_data";
import {
  defaultHeadersHistorySummary,
  defaultHeadersSummary,
  IHeaderTable,
} from "../../../shared/infrastructure/headers/defaultHeaders";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadProcessFileWS,
  getConciliationDataSummary,
  setActualHeaders,
  setModalConciliation,
  setModalOmit,
  setOpenModalDetail,
} from "../../../store/actionCreators";
import { IAppState } from "../../../store/reducer";
import { IModalConciliation } from "@kushki/frontend-molecules/modal-conciliation";
import {
  getModalDetailValues,
  MODAL_TRANSITION,
} from "../../../shared/infrastructure/constants/ModalDetailConstants";
import { getInfoDetailDepositComp } from "../../../shared/infrastructure/constants/InformationDetailConstants";
import { TransactionData } from "../../../../types/transaction_table";
import { getDownloadTransactionRequest } from "../../../shared/infrastructure/constants/DownloadTransactionsConstants";
import { DateRange } from "@material-ui/pickers";
import {
  getDayBefore,
  getMinDate,
  isValidDates,
  nowDate,
} from "../../../shared/infrastructure/utils/date";
import { SnackbarAlertProps } from "../../../components/common/SnackBar/SnackBarAlert";
import { endOfDay, format, startOfDay, subDays } from "date-fns";
import { OriginEnum } from "../../../shared/infrastructure/OriginEnum";
import { PendingFiltersProps } from "../../../components/Filters/PendingFilters/PendingFilters";
import { DateEnum } from "../../../shared/infrastructure/DateEnum";
import { getTimelineConciliationComp } from "../../../shared/infrastructure/constants/TimelineDetailConstants";
import {
  buildFiltersObject,
  getDataRequestBody,
} from "../../../shared/infrastructure/utils/buildFiltersObject";
import { cloneDeep } from "lodash";
import { DefaultSummaryHistoricFilter } from "../../../shared/infrastructure/filter/DefaultSummaryHistoricFilter";
import { IOptionFilter } from "../../../shared/infrastructure/interfaces/IOptionFilter";
import { getUserRole } from "../../../shared/infrastructure/constants/UserRoleConstants";
import { UserRolesEnum } from "../../../shared/infrastructure/UserRolesEnum";
import {
  DashboardTableEmptyTypeEnum,
  DashboardTableMessage,
  DashboardTableTypeMessageEnum,
} from "../../../shared/DashboardTableEnum";
import { DocumentRequestData } from "../../../../types/get_download_file_request";

export interface IDepositDashboardState {
  actions: {
    handleDownloadFile: () => void;
  };
  date: {
    dateRange: DateRange<Date>;
    minDate: Date;
  };
  notification: SnackbarAlertProps;
  loadingFileDownload?: boolean;
  state: IState;
  breadCrumbs: BreadcrumProps;
  pagination: IPagination;
  data: {
    conciliationData: TransactionTableSummary;
    titleNoData?: string;
    sbtNoData?: string;
  };
  modalOver: ModalOverUtils;
  orderSort: IOrderSort;
  filterProps: FiltersProps;
  pendingFiltersProps: PendingFiltersProps;
  modalDetail: {
    open: boolean;
    handleOpenDialogDetail: () => void;
    transactionTimelineSelected: TransactionSummaryData;
    handleCloseDialogDetail: () => void;
  };
  dateRange: DateRange<Date>;
  minDate: Date;
  trxReconcileSummaryProps: trxReconcileSummaryProps;
}

export interface trxReconcileSummaryProps {
  actionReconcileIndividual: (item: TransactionSummaryData[]) => void;
  closeModal: () => void;
  openIndividualModal: (item?: TransactionSummaryData[]) => void;
  openOmittedModal: boolean;
  openOmittedMasiveModal: boolean;
  isTreasuryBackOffice: boolean;
}

export interface FiltersProps {
  filterHeaders?: () => IHeaderTable[];
}

export type ModalOverUtils = {
  handlerSelectTrxItem: (
    row: TransactionSummaryData | TransactionData | undefined
  ) => void;
  selectedRow: TransactionSummaryData | TransactionData;
  modalDetail: IModalConciliation;
  handleOpenModal: () => void;
  handleOpenTimelineDetailModal: (trx: TransactionSummaryData) => void;
};

export interface IState {
  country: CountryEnum;
  statusFlow: IStatusFlowData;
  isLoadingConciliation?: boolean;
  isLoadingHistoric?: boolean;
}

export const useDepositDashboardState = (): IDepositDashboardState => {
  const dispatch = useDispatch();
  const [state] = useState<IState>({
    country: CountryEnum.mexico,
    statusFlow: STATUSFLOW.summary,
  });

  const loadingFileDownload = useSelector(
    (state: IAppState) => state.loadingFileDownload
  );

  const notification = useSelector((state: IAppState) => state.notification);

  const conciliationData: TransactionTableSummary = useSelector(
    (state: IAppState) => state.conciliationData as TransactionTableSummary
  );
  const openModalDetail: boolean = useSelector(
    (state: IAppState) => state.openModalDetail!
  );
  const actualHeaders: IHeaderTable[] = useSelector(
    (state: IAppState) => state.actualHeaders
  );
  const actualTab: number = useSelector(
    (state: IAppState) => state.indexTransactionSummaryTab!
  );

  state.isLoadingConciliation = useSelector(
    (state: IAppState) => state.isLoadingSummaryData!
  );

  const [selectedDateRange, setSelectedDateRange] = useState<DateRange<Date>>([
    getDayBefore(),
    nowDate,
  ]);
  const [minDate] = useState<Date>(getMinDate());
  const [order, setOrder] = useState<OrderSortEnum>(OrderSortEnum.ASC);
  const [orderBy, setOrderBy] = useState<string>("created");
  const [limit, setLimit] = useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [conciliationTrx, setConciliationtrxTrx] = useState<
    TransactionSummaryData | TransactionData
  >();
  const [openModal, setOpenModal] = useState(false);
  const [modalDetail, setModalDetail] = useState(getModalDetailValues({}));
  const [openOmittedModal, setOpenOmittedModal] =
    React.useState<boolean>(false);
  const [openOmittedMasiveModal, setOpenOmittedMassiveModal] =
    React.useState<boolean>(false);
  const [transactionTimelineSelected, setTransactionTimelineSelected] =
    useState<TransactionSummaryData>({});
  const isTreasuryBackOffice: boolean = getUserRole(
    UserRolesEnum.TreasuryBackOffice
  );
  const [filtersDashboard, setFiltersDashboard] = useState<IOptionFilter[]>(
    cloneDeep(DefaultSummaryHistoricFilter)
  );
  const [counterFilter, setCounterFilter] = useState<number>(0);
  const [filters, setFilters] = useState<object>({});
  const [fromDate, setFromDate] = useState(
    format(startOfDay(subDays(getDayBefore(), 0)), DateEnum.YMD)
  );
  const [toDate, setToDate] = useState(
    format(endOfDay(new Date()), DateEnum.YMD)
  );

  const [titleNoData, setTitleNoData] = useState<string>(
    DashboardTableMessage[DashboardTableEmptyTypeEnum.EMPTY_FILTER][
      DashboardTableTypeMessageEnum.TITLE
    ]
  );
  const [sbtNoData, setSbtNoData] = useState<string>("");

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleChangePage = (
    _event: React.ChangeEvent<unknown> | undefined,
    value: number
  ): void => {
    setPage(value);
    setBody({
      ...body,
      from: fromDate,
      to: toDate,
      limit: limit,
      offset: limit * (value - 1),
    });
  };

  const handleChangeLimit = (newLimit: number): void => {
    setPage(1);
    setLimit(newLimit);
  };
  const handleOpenDialogDetail = () => {
    dispatch(setOpenModalDetail(true));
  };

  const handleCloseDialogDetail = () => {
    setTransactionTimelineSelected({});
    dispatch(setOpenModalDetail(false));
  };

  const handleOpenTimelineDetailModal = (trx: TransactionData) => {
    setTransactionTimelineSelected(trx);
    handleOpenDialogDetail();
  };

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: string
  ): void => {
    setOrder(
      order === OrderSortEnum.ASC ? OrderSortEnum.DESC : OrderSortEnum.ASC
    );
    setOrderBy(property);
  };

  const handlerSelectTrxItem = (
    row: TransactionSummaryData | TransactionData | undefined
  ): void => {
    if (row) {
      setModalDetailInformation(row);
      setConciliationtrxTrx(row);
      handleOpenModal();
    }
  };

  const setModalDetailInformation = (
    trx: TransactionSummaryData | TransactionData
  ) => {
    setModalDetail(
      getModalDetailValues({
        trx,
        informationComponent: getInfoDetailDepositComp(trx, actualTab),
        ...(actualTab === TabsEnum.HISTORIC && {
          timeLineComponent: getTimelineConciliationComp(
            trx,
            handleOpenTimelineDetailModal
          ),
        }),
      })
    );
  };

  const handleConciliationData = (request: object): void => {
    dispatch(getConciliationDataSummary(request));
  };

  const filterHeaders = (): IHeaderTable[] => {
    const defaultValues: IHeaderTable[] =
      actualTab === TabsEnum.PENDING
        ? defaultHeadersSummary
        : defaultHeadersHistorySummary;
    return actualHeaders.length !== 0 ? actualHeaders : defaultValues;
  };

  const handleDownloadFile = () => {
    dispatch(
      downloadProcessFileWS(
        getDownloadTransactionRequest({
          body: body as DocumentRequestData,
          origin: OriginTransactionsEnum.SUMMARY,
          request: RequestDownloadEnum.DEPOSIT,
        })
      )
    );
  };
  const [body, setBody] = useState(
    getDataRequestBody(actualTab, { from: fromDate, to: toDate })
  );

  const handleDateChange = (dateRange: DateRange<Date>) => {
    if (!isValidDates(dateRange, getDayBefore(), nowDate)) {
      setSelectedDateRange(dateRange);
      setFromDate(format(startOfDay(dateRange[0]!), DateEnum.YMD));
      setToDate(format(endOfDay(dateRange[1]!), DateEnum.YMD));
      handleCustomTitles(
        DashboardTableMessage[DashboardTableEmptyTypeEnum.EMPTY_CALENDAR][
          DashboardTableTypeMessageEnum.TITLE
        ],
        DashboardTableMessage[DashboardTableEmptyTypeEnum.EMPTY_CALENDAR][
          DashboardTableTypeMessageEnum.SUBTITLE
        ]
      );

      setBody({
        ...body,
        from: format(startOfDay(dateRange[0]!), DateEnum.YMD),
        to: format(endOfDay(dateRange[1]!), DateEnum.YMD),
      });
      setPage(1);
    }
  };

  const actionReconcileIndividual = (item: TransactionSummaryData[]) => {
    dispatch(
      setModalConciliation({
        conciliation: OriginEnum.SUMMARY,
        conciliationMasive: false,
        transactions: item,
        transactionsNoSelected: [],
        open: true,
        total: 0,
      })
    );
  };
  const openIndividualModal = (item?: TransactionSummaryData[]) => {
    dispatch(
      setModalOmit({
        omitTransaction: OriginEnum.TRANSACTIONS,
        omitMasive: false,
        transactions: item ? item : [],
        transactionsNoSelected: [],
      })
    );
    handleOpenOmittedModal(false);
  };
  const closeModal = () => {
    handleCloseOmittedModal(true);
  };
  const handleCloseOmittedModal = (value: boolean) => {
    setOpenOmittedModal(false);
    setOpenOmittedMassiveModal(value);
  };
  const handleOpenOmittedModal = (value: boolean) => {
    setOpenOmittedModal(true);
    setOpenOmittedMassiveModal(value);
  };

  const handleSetFilters = (filters: { [key: string]: boolean }) => {
    const export_filter_selected = buildFiltersObject(filters);
    const selectedFilters = Object.keys(filters).filter(
      (key: string) => filters[key]
    );
    setCounterFilter(selectedFilters.length);
    setFilters(export_filter_selected);
    setBody({ ...body, filter: export_filter_selected, offset: 0 });
    setPage(1);
    handleCustomTitles(
      DashboardTableMessage[DashboardTableEmptyTypeEnum.EMPTY_FILTER][
        DashboardTableTypeMessageEnum.TITLE
      ],
      DashboardTableMessage[DashboardTableEmptyTypeEnum.EMPTY_FILTER][
        DashboardTableTypeMessageEnum.SUBTITLE
      ]
    );
  };

  const handleCustomTitles = (title: string, subTitle: string): void => {
    setTitleNoData(title);
    setSbtNoData(subTitle);
  };

  useEffect(() => {
    setBody(
      getDataRequestBody(actualTab, {
        limit: limit,
        offset: 0,
        filter: filters,
        from: format(startOfDay(subDays(getDayBefore(), 0)), DateEnum.YMD),
        to: format(endOfDay(new Date()), DateEnum.YMD),
      })
    );
    dispatch(setActualHeaders([]));
  }, []);

  useEffect(() => {
    setPage(1);

    setBody({
      ...body,
      limit: limit,
      offset: limit * (page - 1),
    });
  }, [limit]);

  useEffect(() => {
    setPage(1);
    setSelectedDateRange([getDayBefore(), nowDate]);
    setFiltersDashboard(cloneDeep(DefaultSummaryHistoricFilter));
    setFilters({});
    setCounterFilter(0);
    handleCustomTitles(
      DashboardTableMessage[DashboardTableEmptyTypeEnum.EMPTY_FILTER][
        DashboardTableTypeMessageEnum.TITLE
      ],
      DashboardTableMessage[DashboardTableEmptyTypeEnum.EMPTY_FILTER][
        DashboardTableTypeMessageEnum.SUBTITLE
      ]
    );
    setBody(
      getDataRequestBody(actualTab, {
        limit: limit,
        offset: 0,
        from: format(startOfDay(subDays(getDayBefore(), 0)), DateEnum.YMD),
        to: format(endOfDay(new Date()), DateEnum.YMD),
      })
    );
    dispatch(setActualHeaders([]));
  }, [actualTab]);

  useEffect(() => {
    handleConciliationData(body);
  }, [body]);

  return {
    state,
    pagination: {
      page,
      limit: limit,
      text: "Transacciones por página",
      trxPerPage: limit,
      handleChangePage,
      handleChangeLimit,
      size: "small",
      isMobile: false,
      siblingCount: 0,
    },
    orderSort: {
      order,
      orderBy,
      handleRequestSort,
    },
    modalOver: {
      handlerSelectTrxItem: handlerSelectTrxItem,
      selectedRow: conciliationTrx!,
      modalDetail: {
        openModal,
        onCloseModal: handleCloseModal,
        transition: MODAL_TRANSITION,
        ...modalDetail,
        footer:
          actualTab === 0
            ? {
                rol: isTreasuryBackOffice,
                handleOnLeftClick: () => {
                  openIndividualModal([conciliationTrx!]);
                },
                handleOnRightClick: () => {
                  actionReconcileIndividual([conciliationTrx!]);
                },
              }
            : undefined,
      },
      handleOpenModal,
      handleOpenTimelineDetailModal,
    },
    filterProps: {
      filterHeaders,
    },
    pendingFiltersProps: {
      filters: filtersDashboard,
      handleDateRangeChange: handleDateChange,
      handleSetFilters: handleSetFilters,
      counterFilter: counterFilter,
      dateRange: selectedDateRange,
      minDate,
    },
    data: {
      conciliationData,
      sbtNoData,
      titleNoData,
    },
    breadCrumbs: {
      items: [
        {
          label: "Inicio",
          url: routes.BASE_PATH_BANK_CONCILIATION,
          disabled: true,
        },
      ],
      lastItem: STATUSFLOW.summary.name,
    },
    modalDetail: {
      open: openModalDetail,
      handleOpenDialogDetail,
      handleCloseDialogDetail,
      transactionTimelineSelected,
    },
    dateRange: selectedDateRange,
    minDate: minDate,
    trxReconcileSummaryProps: {
      actionReconcileIndividual,
      openIndividualModal,
      openOmittedModal,
      openOmittedMasiveModal,
      closeModal,
      isTreasuryBackOffice,
    },
    actions: {
      handleDownloadFile,
    },
    date: {
      dateRange: selectedDateRange,
      minDate,
    },
    notification: notification as SnackbarAlertProps,
    loadingFileDownload,
  };
};
