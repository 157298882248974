import React from "react";
import { ProcessorFetch } from "../../../shared/infrastructure/interfaces/ProcessorFetch";
import { ModalHeader } from "@kushki/frontend-molecules/modal-header";
import { get, isEqual } from "lodash";
import {
  ChipProcessorStatusEnum,
  getTranslatedProcessor,
  ProcessorStatusEnum,
} from "../../../shared/infrastructure/constants/ProcessorsEnum";
import { LabelEnum } from "../../../shared/infrastructure/constants/ProcessorsInformationConstant";

export interface IProcessorInfo {
  processorInfo?: ProcessorFetch;
}

interface StatusRef {
  statusLabel: string;
  chipColor: string;
  chipBackGroundColor: string;
}

const getStatusConfig = (status: string): StatusRef => {
  return isEqual(status, ProcessorStatusEnum.DISABLED)
    ? {
        statusLabel: ChipProcessorStatusEnum.DISABLED,
        chipColor: "#DC9350",
        chipBackGroundColor: "#FEF1D1",
      }
    : {
        statusLabel: ChipProcessorStatusEnum.ENABLED,
        chipColor: "#0DC298",
        chipBackGroundColor: "#E0FFF8",
      };
};

export const ModalHeaderInfo = (props: IProcessorInfo) => {
  const highlightValue: string = getTranslatedProcessor(
    get(props.processorInfo, "processorName", "N/A")
  );
  const status: StatusRef = getStatusConfig(
    get(props.processorInfo, "status", ProcessorStatusEnum.ENABLED)
  );

  return (
    <ModalHeader
      highlightValue={highlightValue}
      tittle={LabelEnum.PROCESSOR_DETAIL}
      secondaryValue={`Public MID ${props.processorInfo?.publicProcessorId}`}
      statusValue={status.statusLabel}
      chipColor={status.chipColor}
      chipBackGround={status.chipBackGroundColor}
    />
  );
};
