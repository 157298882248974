import { useEffect, useState } from "react";
import { SnackbarAlertProps } from "./SnackBarAlert";

export interface SnackBarAlertState {
  open: boolean;
  handleClose?: (event?: React.SyntheticEvent, reason?: string) => void;
}

export const useSnackBarAlertState = (
  props: SnackbarAlertProps
): SnackBarAlertState => {
  const [open, setOpen] = useState(props.open);

  useEffect(() => setOpen(props.open), [props.open]);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    props.handlerClose(event, reason);
    setOpen(false);
  };

  return { open, handleClose };
};
