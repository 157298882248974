/**
 * Catalog CostRica
 */
import { Category } from "../constants/MerchantInformationConstants";
import { CatalogsEnum } from "../enums/CatalogsEnum";

export const CatalogCr: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { name: "CI", value: "0" },
    { name: "Pasaporte", value: "1" },
  ],
  [CatalogsEnum.Provinces]: [
    {
      name: "Alajuela",
      value: "A",
    },
    {
      name: "Cartago",
      value: "C",
    },
    {
      name: "Guanacaste",
      value: "G",
    },
    {
      name: "Heredia",
      value: "H",
    },
    {
      name: "Limón",
      value: "L",
    },
    {
      name: "Puntarenas",
      value: "P",
    },
    {
      name: "San José",
      value: "SJ",
    },
  ],
  [CatalogsEnum.Banks]: [
    {
      name: "BANCO DE COSTA RICA",
      value: "11100006",
    },
    {
      name: "BANCO BAC SAN JOSE",
      value: "11100001",
    },
    {
      name: "BANCO BCT S.A.",
      value: "11100002",
    },
    {
      name: "BANCO CATHAY DE COSTA RICA S.A.",
      value: "11100003",
    },
    {
      name: "BANCO CENTRAL DE COSTA RICA",
      value: "11100004",
    },
    {
      name: "BANCO CITIBANK DE COSTA RICA S.A.",
      value: "11100005",
    },
    {
      name: "BANCO GENERAL DE COSTA RICA S.A.",
      value: "11100007",
    },
    {
      name: "BANCO HSBC DE COSTA RICA S.A.",
      value: "11100008",
    },
    {
      name: "BANCO IMPROSA S.A.",
      value: "11100009",
    },
    {
      name: "BANCO LAFISE S.A.",
      value: "11100010",
    },
    {
      name: "BANCO NACIONAL DE COSTA RICA",
      value: "11100011",
    },
    {
      name: "BANCO PROMERICA",
      value: "11100012",
    },
    {
      name: "SCOTIABANK DE COSTA RICA",
      value: "11100013",
    },
    {
      name: "COSTA RICA",
      value: "11100014",
    },
    {
      name: "BANCO INTERFIN DE COSTA RICA",
      value: "11100015",
    },
    {
      name: "BANCO INTERNACIONAL DE COSTA RICA",
      value: "11100016",
    },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { name: "Corriente", value: "0" },
    { name: "Ahorros", value: "1" },
  ],
};
