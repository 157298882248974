import { defaultTo, get, isEmpty, isNil, set, toString } from "lodash";
import { MerchantData } from "../../../types/merchant-data";
import { validCountries } from "../constants/validCountries";
import { CountryEnum } from "../countryEnum";
import {
  BasicDataByCountry,
  BasicDataByCountryElements,
  europeCountriesPSPCatalog,
} from "../enum/BasicDataByCountry";
import { BasicDataInterface } from "../interfaces/BasicData.interface";
import {
  transformDateToTimestamp,
  transformUnixTimestampToFormatDate,
} from "./date_utils";
import { DateFormatEnum } from "../enum/DateFormatEnum";
import { FieldsNamesRender } from "../enum/FieldsEnumRenderEnum";
import { PersonTypeObjectInterface } from "../enum/BasicStateGeneral/FieldsBasicData";
import {
  findDocTypeCode,
  findDocTypeCountry,
  findDocTypeValue,
} from "./findDocTypeCountry_utils";
import { docTypeEC } from "../enum/BasicStateEC/FieldsBasicDataEC";
import {
  Configs,
  GetNodeInfoResponse,
} from "../../../types/get_node_info_response";
import {
  ConfigIdEnum,
  MerchantTypeEnum,
  PersonTypeEnum,
} from "../constants/initial_state_legal_details";
import { EntityTypes } from "../enum/EntityTypeEnum";
import { UseFormReturn } from "react-hook-form";
import { IShowGenericInputs } from "../enum/BasicStateGeneric/CatalogDataGeneric";
import {
  PersonType_CR_Enum,
  PersonType_EC_PE_Enum,
  PersonType_Generic_Enum,
  PersonType_NI_Enum,
  PersonType_SV_Enum,
  PersonTypeHNEnum,
} from "../enum/BasicStateGeneral/PersonType";
import { docTypePeru } from "../enum/BasicStatePE/FieldsBasicDataPE";
import { docTypeCR } from "../enum/BasicStateCR/FieldsBasicDataCR";
import { PersonTypeBRAEnum } from "../enum/BasicStateBRA/PersonTypeBRAEnum";
import { docTypeBRA } from "../enum/BasicStateBRA/FieldsBasicDataBRA";
import { PersonTypeCHIEnum } from "../enum/BasicStateCHI/PersonTypeCHIEnum";
import { docTypeChile } from "../enum/BasicStateCHI/FieldsBasicDataCHI";
import {
  documentTypeCO,
  documentTypeValuesEnumCO,
} from "../enum/BasicStateCo/CatalogBasicDataCo";
import { documentTypeCOValuesEnum } from "../enum/BasicStateCo/PersonTypeCoEnum";
import { PersonTypePANEnum } from "../enum/BasicStatePAN/PersonTypePANEnum";
import { docTypePanama } from "../enum/BasicStatePAN/FieldsBasicDataPAN";
import { docTypeNI } from "../enum/BasicStateNI/FieldsBasicDataNI";
import { docTypeSV } from "../enum/BasicStateSV/FieldsBasicDataSV";
import { TypeOfTaxIdEnumHN } from "../enum/BasicStateHN/CatalogBasicDataHN";
import { ErrorMessageHN } from "../enum/BasicStateHN/ErrorMessageHN";
import { setButtonSaveFooter } from "../../store/actions/basicDataForm.action";
import { Dispatch } from "@reduxjs/toolkit";
import { docTypeHon } from "../enum/BasicStateHN/FieldsBasicDataHN";
import { docTypeUSA } from "../enum/BasicStateUSA/FieldsBasicDataUSA";
import { docTypeGUA } from "../enum/BasicStateGUA/CatalogBasicDataGUA";
import {
  IBoardMember,
  IBoardMemberRequest,
  ILegalRepresentative,
  ILegalRepresentativeRequest,
  IMerchantPeopleNodeRequest,
  IShareHolder,
  IShareHolderRequest,
  ISocialReasonRequest,
  PersonCategoryEnum,
} from "../../store/interfaces/LegalDetailsState.interfaces";
import { MerchantResponse } from "../../../types/get_merchant_response";
import { ErrorMessageEC } from "../enum/BasicStateEC/ErrorMessageEC";
import { RegularExp } from "../enum/BasicStateMX/ErrorMessageMX";
import {
  createBoardMemberRequest,
  createILegalRequest,
  createShRequest,
} from "../../containers/LegalDetailsContainer/state/LegalContainerState.utils";
import { CompliancePeopleNodeResponse } from "../../../types/get_compliance_people_response";
import { ClientType } from "../enums/ClientTypeEnum";
import { BasicDataRegularExp } from "../enum/BasicDataRegularExpEnum";
import {
  ErrorMessageUSA,
  USAITNREGX,
} from "../enum/BasicStateUSA/ErrorMessageUSA";
import { TypeOfTaxIdUSA } from "../enum/BasicStateUSA/CatalogDataUSA";

const buildSocialReasonNames = (
  name: string | undefined,
  surname: string | undefined
): string => {
  const socialReason: string = `${defaultTo(name, "")} ${defaultTo(
    surname,
    ""
  )}`;

  return socialReason.toUpperCase();
};

export const BuildObjectToSave = (
  objectToSave: MerchantData,
  form: UseFormReturn<BasicDataInterface>,
  showGenericInput: IShowGenericInputs,
  merchantNodeInfo?: string,
  isEuropePSP?: boolean
): object => ({
  [CountryEnum.mexico]: {
    [PersonTypeEnum.MORAL]: {
      ...objectToSave,
      companyType: form.getValues("typeCompany"),
      documentType: "3",
      socialReason: form.getValues("socialReason"),
    },
    [PersonTypeEnum.FISICA]: {
      ...objectToSave,
      documentType: "3",
      naturalPerson: {
        lastname: form.getValues("surname"),
        name: form.getValues("names"),
      },
      socialReason: buildSocialReasonNames(
        form.getValues("names"),
        form.getValues("surname")
      ),
    },
  },
  [CountryEnum.peru]: {
    [PersonType_EC_PE_Enum.JURIDICA]: {
      ...objectToSave,
      companyType: form.getValues("typeCompany"),
      documentType: get(findDocTypeCountry(docTypePeru), "value", "1"),
      socialReason: form.getValues("socialReason"),
    },
    [PersonType_EC_PE_Enum.NATURAL]: {
      ...objectToSave,
      documentType: get(findDocTypeCountry(docTypePeru), "value", "1"),
      naturalPerson: {
        lastname: form.getValues("surname"),
        name: form.getValues("names"),
      },
      socialReason: buildSocialReasonNames(
        form.getValues("names"),
        form.getValues("surname")
      ),
    },
  },
  [CountryEnum.costa_rica]: {
    [PersonType_CR_Enum.JURIDICA]: {
      ...objectToSave,
      companyType: form.getValues("typeCompany"),
      documentType: get(findDocTypeCountry(docTypeCR), "value", "1"),
      socialReason: form.getValues("socialReason"),
    },
    [PersonType_CR_Enum.FISICA]: {
      ...objectToSave,
      documentType: get(findDocTypeCountry(docTypeCR), "value", "1"),
      naturalPerson: {
        lastname: form.getValues("surname"),
        name: form.getValues("names"),
      },
      socialReason: buildSocialReasonNames(
        form.getValues("names"),
        form.getValues("surname")
      ),
    },
  },
  [CountryEnum.ecuador]: {
    [PersonType_EC_PE_Enum.JURIDICA]: {
      ...objectToSave,
      companyType: form.getValues("typeCompany"),
      documentType: get(
        findDocTypeCode(docTypeEC, form.getValues("typeOfTaxId")),
        "value",
        "1"
      ),
      socialReason: form.getValues("socialReason"),
    },
    [PersonType_EC_PE_Enum.NATURAL]: {
      ...objectToSave,
      companyType: form.getValues("typeCompany"),
      documentType: get(
        findDocTypeCode(docTypeEC, form.getValues("typeOfTaxId")),
        "value",
        "1"
      ),
      naturalPerson: {
        lastname: form.getValues("surname"),
        name: form.getValues("names"),
      },
      socialReason: buildSocialReasonNames(
        form.getValues("names"),
        form.getValues("surname")
      ),
    },
  },
  [CountryEnum.brasil]: {
    [PersonTypeBRAEnum.JURIDICA]: {
      ...objectToSave,
      companyType: form.getValues("typeCompany"),
      documentType: get(findDocTypeCountry(docTypeBRA), "value", "2"),
      socialReason: form.getValues("socialReason"),
    },
    [PersonTypeBRAEnum.FISICA]: {
      ...objectToSave,
      companyType: form.getValues("typeCompany"),
      documentType: get(findDocTypeCode(docTypeBRA, "CPF"), "value", "0"),
      naturalPerson: {
        lastname: form.getValues("surname"),
        name: form.getValues("names"),
      },
      socialReason: buildSocialReasonNames(
        form.getValues("names"),
        form.getValues("surname")
      ),
    },
  },
  [CountryEnum.chile]: {
    [PersonTypeCHIEnum.JURIDICA]: {
      ...objectToSave,
      companyType: form.getValues(FieldsNamesRender.type_company),
      documentType: get(findDocTypeCountry(docTypeChile), "value", "2"),
      socialReason: form.getValues(FieldsNamesRender.social_reason),
    },
    [PersonTypeCHIEnum.NATURAL]: {
      ...objectToSave,
      documentType: get(findDocTypeCountry(docTypeChile), "value", "2"),
      naturalPerson: {
        lastname: form.getValues(FieldsNamesRender.surname),
        name: form.getValues(FieldsNamesRender.names),
      },
      socialReason: buildSocialReasonNames(
        form.getValues("names"),
        form.getValues("surname")
      ),
    },
  },
  [CountryEnum.colombia]: {
    [PersonTypeBRAEnum.JURIDICA]: {
      ...objectToSave,
      companyType: form.getValues(FieldsNamesRender.type_company),
      documentType: get(
        findDocTypeCountry(documentTypeCO),
        "value",
        documentTypeCOValuesEnum.THREE
      ),
      socialReason: form.getValues(FieldsNamesRender.social_reason),
    },
    [PersonTypeBRAEnum.FISICA]: {
      ...objectToSave,
      companyType: form.getValues(FieldsNamesRender.type_company),
      documentType: get(
        findDocTypeCode(documentTypeCO, documentTypeValuesEnumCO.NIT),
        "value",
        documentTypeCOValuesEnum.THREE
      ),
      naturalPerson: {
        lastname: form.getValues(FieldsNamesRender.surname),
        name: form.getValues(FieldsNamesRender.names),
      },
      socialReason: buildSocialReasonNames(
        form.getValues("names"),
        form.getValues("surname")
      ),
    },
  },
  [CountryEnum.panama]: {
    [PersonTypePANEnum.JURIDICA]: {
      ...objectToSave,
      companyType: form.getValues(FieldsNamesRender.type_company),
      documentType: get(findDocTypeCountry(docTypePanama), "value", "1"),
      socialReason: form.getValues(FieldsNamesRender.social_reason),
    },
    [PersonTypePANEnum.NATURAL]: {
      ...objectToSave,
      documentType: get(findDocTypeCountry(docTypePanama), "value", "1"),
      socialReason: form.getValues(FieldsNamesRender.social_reason),
    },
  },
  [CountryEnum.nicaragua]: {
    [PersonType_NI_Enum.JURIDICA]: {
      ...objectToSave,
      companyType: form.getValues("typeCompany"),
      documentType: get(findDocTypeCountry(docTypeNI), "value", "1"),
      socialReason: form.getValues("socialReason"),
    },
    [PersonType_NI_Enum.FISICA]: {
      ...objectToSave,
      documentType: get(findDocTypeCountry(docTypeNI), "value", "1"),
      naturalPerson: {
        lastname: form.getValues("surname"),
        name: form.getValues("names"),
      },
      socialReason: buildSocialReasonNames(
        form.getValues("names"),
        form.getValues("surname")
      ),
    },
  },
  [CountryEnum.el_salvador]: {
    [PersonType_SV_Enum.MORAL]: {
      ...objectToSave,
      companyType: form.getValues("typeCompany"),
      documentType: get(findDocTypeCountry(docTypeSV), "value", "1"),
      socialReason: form.getValues("socialReason"),
    },
    [PersonType_SV_Enum.FISICA]: {
      ...objectToSave,
      documentType: get(findDocTypeCountry(docTypeSV), "value", "1"),
      naturalPerson: {
        lastname: form.getValues("surname"),
        name: form.getValues("names"),
      },
      socialReason: buildSocialReasonNames(
        form.getValues("names"),
        form.getValues("surname")
      ),
    },
  },
  [CountryEnum.honduras]: {
    [PersonTypeHNEnum.MORAL]: {
      ...objectToSave,
      companyType: form.getValues("typeCompany"),
      documentType: form.getValues("typeOfTaxId"),
      socialReason: form.getValues("socialReason"),
    },
    [PersonTypeHNEnum.FISICA]: {
      ...objectToSave,
      documentType: form.getValues("typeOfTaxId"),
      naturalPerson: {
        lastname: form.getValues("surname"),
        name: form.getValues("names"),
      },
      socialReason: buildSocialReasonNames(
        form.getValues("names"),
        form.getValues("surname")
      ),
    },
  },
  [CountryEnum.generic]: {
    [PersonType_Generic_Enum.JURIDICA]: {
      ...objectToSave,
      ...(merchantNodeInfo === ClientType.PSP &&
        isEuropePSP && {
          companyRegistrationNumber: form.getValues(
            "companyRegistrationNumber"
          ),
        }),
      companyType: showGenericInput.companyType
        ? form.getValues("typeCompanyGeneric")
        : form.getValues("typeCompany"),
      documentType: PersonType_Generic_Enum.JURIDICA,
      economicActivityId: showGenericInput.economicActivityGeneric
        ? form.getValues("economicActivityGeneric")
        : form.getValues("economyActivity"),
      socialReason: form.getValues("socialReason"),
    },
    [PersonType_Generic_Enum.NATURAL]: {
      ...objectToSave,
      ...(merchantNodeInfo === ClientType.PSP &&
        isEuropePSP && {
          companyRegistrationNumber: form.getValues(
            "companyRegistrationNumber"
          ),
        }),
      documentType: PersonType_Generic_Enum.NATURAL,
      economicActivityId: showGenericInput.economicActivityGeneric
        ? form.getValues("economicActivityGeneric")
        : form.getValues("economyActivity"),
      naturalPerson: {
        lastname: form.getValues("surname"),
        name: form.getValues("names"),
      },
      socialReason: buildSocialReasonNames(
        form.getValues("names"),
        form.getValues("surname")
      ),
    },
  },
  [CountryEnum.guatemala]: {
    [PersonTypeHNEnum.MORAL]: {
      ...objectToSave,
      companyType: form.getValues("typeCompany"),
      documentType: form.getValues("typeOfTaxId"),
      socialReason: form.getValues("socialReason"),
    },
    [PersonTypeHNEnum.FISICA]: {
      ...objectToSave,
      companyType: form.getValues("typeCompany"),
      documentType: form.getValues("typeOfTaxId"),
      naturalPerson: {
        lastname: form.getValues("surname"),
        name: form.getValues("names"),
      },
      socialReason: buildSocialReasonNames(
        form.getValues("names"),
        form.getValues("surname")
      ),
    },
  },
  [CountryEnum.usa]: {
    [PersonType_EC_PE_Enum.NATURAL]: {
      ...objectToSave,
      ...(merchantNodeInfo === ClientType.PSP && {
        companyRegistrationNumber: form.getValues("companyRegistrationNumber"),
      }),
      companyType: form.getValues("typeCompany"),
      documentType: form.getValues("typeOfTaxId"),
      naturalPerson: {
        lastname: form.getValues("surname"),
        name: form.getValues("names"),
      },
      socialReason: buildSocialReasonNames(
        form.getValues("names"),
        form.getValues("surname")
      ),
    },
    [PersonType_EC_PE_Enum.JURIDICA]: {
      ...objectToSave,
      ...(merchantNodeInfo === ClientType.PSP && {
        companyRegistrationNumber: form.getValues("companyRegistrationNumber"),
      }),
      companyType: form.getValues("typeCompany"),
      documentType: "1",
      socialReason: form.getValues("socialReason"),
    },
  },
});

const setValuesRequest = (
  merchantInfo: MerchantData | MerchantResponse,
  path: string
): string => {
  return toString(get(merchantInfo, path, ""));
};

export const becomeSameLevel = (
  shareHolders: IShareHolder[]
): IShareHolder[] => {
  return shareHolders.flatMap((sh) =>
    sh.children ? [sh, ...becomeSameLevel(sh.children)] : sh
  );
};

export const createSrRequest = (
  merchantInfo: MerchantData | MerchantResponse,
  compliancePeopleResponse?: CompliancePeopleNodeResponse
): ISocialReasonRequest => {
  if (merchantInfo.merchantType === MerchantTypeEnum.JURIDICO) {
    return {
      documentNumber: setValuesRequest(merchantInfo, "taxId"),
      documentType: setValuesRequest(merchantInfo, "documentType"),
      id: get(compliancePeopleResponse, "socialReason.id"),
      personCategory: PersonCategoryEnum.SOCIAL_REASON,
      personType: PersonTypeEnum.MORAL,
      socialReason: setValuesRequest(merchantInfo, "socialReason"),
    };
  } else if (
    merchantInfo.merchantType === MerchantTypeEnum.NATURAL &&
    get(merchantInfo, "constitutionalCountry", "") === CountryEnum.panama
  ) {
    return {
      documentNumber: setValuesRequest(merchantInfo, "taxId"),
      documentType: setValuesRequest(merchantInfo, "documentType"),
      id: get(compliancePeopleResponse, "socialReason.id"),
      personCategory: PersonCategoryEnum.SOCIAL_REASON,
      personType: PersonTypeEnum.FISICA,
      socialReason: setValuesRequest(merchantInfo, "socialReason"),
    };
  } else {
    return {
      documentNumber: setValuesRequest(merchantInfo, "taxId"),
      documentType: setValuesRequest(merchantInfo, "documentType"),
      id: get(compliancePeopleResponse, "socialReason.id"),
      lastNames: setValuesRequest(merchantInfo, "naturalPerson.lastname"),
      names: setValuesRequest(merchantInfo, "naturalPerson.name"),
      personCategory: PersonCategoryEnum.SOCIAL_REASON,
      personType: PersonTypeEnum.FISICA,
    };
  }
};

export const constructRequestCompliance = (
  legalRepresentatives: ILegalRepresentative[],
  shareHolders: IShareHolder[],
  boardMembers: IBoardMember[],
  merchantNodeInfo: MerchantResponse,
  nodeInfo: GetNodeInfoResponse,
  publicMerchantId: string,
  isPubliclyExposed: boolean,
  compliancePeopleResponse?: CompliancePeopleNodeResponse
): IMerchantPeopleNodeRequest => {
  const lgRepresentatives: ILegalRepresentativeRequest[] = [];
  const sHolders: IShareHolderRequest[] = [];
  const socialReason: ISocialReasonRequest = createSrRequest(
    merchantNodeInfo,
    compliancePeopleResponse
  );
  const bMembers: IBoardMemberRequest[] = [];

  legalRepresentatives.map((legalRepre: ILegalRepresentative) => {
    set(legalRepre, "personCategory", PersonCategoryEnum.REPRESENTATIVE);
    const legalRepRequest: ILegalRepresentativeRequest = createILegalRequest(
      legalRepre,
      isPubliclyExposed
    );

    lgRepresentatives.push(legalRepRequest);
  });
  shareHolders.map((sHolder: IShareHolder) => {
    const sHolderRequest: IShareHolderRequest = createShRequest(sHolder);

    sHolders.push(sHolderRequest);
  });

  boardMembers.map((boardMember: IBoardMember) => {
    const bMemberRequest: IBoardMemberRequest =
      createBoardMemberRequest(boardMember);

    bMembers.push(bMemberRequest);
  });

  return {
    boardmembers: bMembers,
    constitutionalCountry: get(
      nodeInfo,
      "generalInfo.constitutionalCountry",
      ""
    ),
    country: get(nodeInfo, "generalInfo.country", ""),
    legalRepresentatives: lgRepresentatives,
    merchantId: publicMerchantId,
    merchantName: get(nodeInfo, "generalInfo.name", ""),
    merchantStatus: get(nodeInfo, "generalInfo.status", ""),
    shareholders: sHolders,
    socialReason: socialReason,
  };
};

export const setSaveByFormValid = (
  form: UseFormReturn<any>,
  dispatch: Dispatch
) => {
  dispatch(setButtonSaveFooter(!form.formState.isValid));
};

export const getOperationCountry = (merchantNodeInfo: MerchantData): string =>
  get(merchantNodeInfo, "country");

export const getConstitutionalCountry = (
  merchantNodeInfo: MerchantData
): string =>
  isEmpty(get(merchantNodeInfo, "constitutionalCountry")) ||
  isNil(get(merchantNodeInfo, "constitutionalCountry")) ||
  !validCountries.includes(get(merchantNodeInfo, "constitutionalCountry"))
    ? CountryEnum.generic
    : get(merchantNodeInfo, "constitutionalCountry");

export const getCountryFields = (
  constitutionalCountry: string
): BasicDataByCountryElements => BasicDataByCountry[constitutionalCountry];

export const countriesTaxId: string[] = [
  CountryEnum.ecuador,
  CountryEnum.honduras,
  CountryEnum.guatemala,
  CountryEnum.usa,
];

export const getFormValues = (
  merchantNodeInfo: MerchantData,
  constitutionalCountry: string
): BasicDataInterface => {
  const typeOfTaxIdEC: PersonTypeObjectInterface = findDocTypeValue(
    docTypeEC,
    get(merchantNodeInfo, "documentType", "2")
  );

  return {
    date: transformUnixTimestampToFormatDate(
      get(merchantNodeInfo, "constitutionDate", new Date().getTime()),
      DateFormatEnum.DD_MMM_YYYY
    ),
    economyActivity: get(merchantNodeInfo, "economicActivityId", ""),
    mcc: get(merchantNodeInfo, "mcc", ""),
    names: get(merchantNodeInfo, "naturalPerson.name", ""),
    rfc: get(merchantNodeInfo, "taxId", ""),
    socialReason: get(merchantNodeInfo, "socialReason", ""),
    surname: get(merchantNodeInfo, "naturalPerson.lastname", ""),
    typeCompany: get(merchantNodeInfo, "companyType", ""),
    typeIndustry: get(merchantNodeInfo, "industryType", ""),
    typeOfTaxId:
      constitutionalCountry == CountryEnum.ecuador
        ? typeOfTaxIdEC.name
        : countriesTaxId.includes(constitutionalCountry as CountryEnum)
        ? get(merchantNodeInfo, "documentType", "")
        : "",
    typePerson: get(merchantNodeInfo, "merchantType", ""),
    web: get(merchantNodeInfo, "webSite", ""),
  };
};

export const isNewBasicData = (nodeInfo: GetNodeInfoResponse): boolean => {
  const arrayConfig = get(nodeInfo, "configs", []);

  const configId = arrayConfig.filter(
    (item: Configs) =>
      item.configuration === ConfigIdEnum.CN001 && item.value !== ""
  );

  if (configId.length > 0) {
    return false;
  }

  return true;
};

export const validateBranchEdit = (nodeInfo: GetNodeInfoResponse): boolean => {
  const arrayConfig: Configs[] = get(nodeInfo, "configs", []);

  const configId = arrayConfig.filter(
    (item: Configs) => item.configuration === ConfigIdEnum.CN001
  );

  return (
    !isEmpty(get(configId[0], "centralizedNodesId", "")) &&
    get(nodeInfo, "entityName", "") === EntityTypes.BRANCH
  );
};

export interface CreateMerchantInterface {
  form: UseFormReturn<BasicDataInterface>;
  publicMerchantId?: string;
  merchantNodeInfo: MerchantData;
  operationCountry: string;
  constitutionalCountry: string;
  showGenericInput: IShowGenericInputs;
}

export const createMerchantData = ({
  form,
  publicMerchantId,
  merchantNodeInfo,
  operationCountry,
  constitutionalCountry,
  showGenericInput,
}: CreateMerchantInterface): MerchantData => {
  const objectToSave: MerchantData = {
    configId: ConfigIdEnum.CN001,
    constitutionalCountry: get(merchantNodeInfo, "constitutionalCountry"),
    constitutionDate: transformDateToTimestamp(
      form.getValues("date"),
      DateFormatEnum.DD_MMM_YYYY
    ),
    country: validCountries.includes(get(merchantNodeInfo, "country"))
      ? operationCountry
      : get(merchantNodeInfo, "country"),
    economicActivityId: form.getValues("economyActivity"),
    industryType: form.getValues("typeIndustry"),
    mcc: form.getValues("mcc"),
    merchantType: form.getValues("typePerson")!,
    publicMerchantId: defaultTo(publicMerchantId, ""),
    taxId: form.getValues("rfc"),
    webSite: form.getValues("web"),
  };
  const typePerson = form.getValues("typePerson");

  const isPSPEuropeCountry: boolean = europeCountriesPSPCatalog.includes(
    get(merchantNodeInfo, "constitutionalCountry", "") as CountryEnum
  );

  return get(
    BuildObjectToSave(
      objectToSave,
      form,
      showGenericInput,
      get(merchantNodeInfo, "clientType"),
      isPSPEuropeCountry
    ),
    `[${constitutionalCountry}][${typePerson}]`,
    {}
  );
};

export const TaxIdRulesCountry = (): object => ({
  [CountryEnum.honduras]: {
    [TypeOfTaxIdEnumHN.RTN]: {
      maxLength: {
        message: ErrorMessageHN.MAX_1_50_CHAR,
        value: 50,
      },
      minLength: {
        message: ErrorMessageHN.MAX_1_50_CHAR,
        value: 1,
      },
      required: {
        message: ErrorMessageHN.PF_NUM_TX_CR,
        value: true,
      },
    },
    [TypeOfTaxIdEnumHN.RNP]: {
      maxLength: {
        message: ErrorMessageHN.MAX_1_50_CHAR,
        value: 50,
      },
      minLength: {
        message: ErrorMessageHN.MAX_1_50_CHAR,
        value: 1,
      },
      required: {
        message: ErrorMessageHN.PF_NUM_TX_CR,
        value: true,
      },
    },
    ["Default"]: {
      maxLength: {
        message: ErrorMessageHN.MAX_1_50_CHAR,
        value: 50,
      },
      minLength: {
        message: ErrorMessageHN.MAX_1_50_CHAR,
        value: 1,
      },
      required: {
        message: ErrorMessageHN.PF_NUM_TX_CR,
        value: true,
      },
    },
  },
  [CountryEnum.ecuador]: {
    //Cedula
    [docTypeEC[0].name]: {
      maxLength: {
        message: ErrorMessageEC.LIMIT_CED_EC,
        value: 10,
      },
      minLength: {
        message: ErrorMessageEC.LIMIT_CED_EC,
        value: 10,
      },
      pattern: {
        message: "Entered value does not match email format",
        value: RegularExp.ONLY_NUMBERS,
      },
      required: {
        message: ErrorMessageHN.PF_NUM_TX_CR,
        value: true,
      },
    },
    //Pasaporte
    [docTypeEC[1].name]: {
      maxLength: {
        message: ErrorMessageEC.LIMIT_PASSPORT_EC,
        value: 50,
      },
      minLength: {
        message: ErrorMessageEC.MIN_PASSPORT_EC,
        value: 7,
      },
      pattern: {
        message: "Entered value does not match email format",
        value: BasicDataRegularExp.ALFA_NUMERIC_NO_SPACES,
      },
      required: {
        message: ErrorMessageHN.PF_NUM_TX_CR,
        value: true,
      },
    },
    //RUC
    [docTypeEC[2].name]: {
      maxLength: {
        message: ErrorMessageEC.LIMIT_RUC_EC,
        value: 13,
      },
      minLength: {
        message: ErrorMessageEC.LIMIT_RUC_EC,
        value: 10,
      },
      pattern: {
        message: "Entered value does not match email format",
        value: RegularExp.ONLY_NUMBERS,
      },
      required: {
        message: ErrorMessageHN.PF_NUM_TX_CR,
        value: true,
      },
    },
  },
  [CountryEnum.usa]: {
    [TypeOfTaxIdUSA.SSN]: {
      maxLength: {
        message: ErrorMessageUSA.MAX_50_CHAR,
        value: 50,
      },
      pattern: {
        message: ErrorMessageUSA.ITIN,
        value: RegularExp.ONLY_NUMBERS,
      },
      required: {
        message: ErrorMessageUSA.NUM_TAX_ID,
        value: true,
      },
    },
    [TypeOfTaxIdUSA.ITIN]: {
      maxLength: {
        message: ErrorMessageUSA.MAX_50_CHAR,
        value: 50,
      },
      pattern: {
        message: ErrorMessageUSA.ITIN,
        value: USAITNREGX,
      },
      required: {
        message: ErrorMessageUSA.NUM_TAX_ID,
        value: true,
      },
    },
  },
});

export const validateDataFields = (
  form: UseFormReturn<BasicDataInterface>,
  dispatch: any
) => {
  const fields = Object.keys(form.formState.errors);

  if (fields.length === 0) dispatch(setButtonSaveFooter(false));

  for (let i = 0; i < fields.length; i++) {
    if (
      !isNil(form.formState.errors[fields[i]]?.type) &&
      form.formState.errors[fields[i]]?.type !== "required"
    ) {
      dispatch(setButtonSaveFooter(true));

      break;
    } else dispatch(setButtonSaveFooter(false));
  }
};

export const docTypeTaxIdCountry = (): object => ({
  [CountryEnum.honduras]: {
    docType: docTypeHon,
  },
  [CountryEnum.guatemala]: {
    docType: docTypeGUA,
  },
  [CountryEnum.usa]: {
    docType: docTypeUSA,
  },
});
