import React from "react";

export const DisableServicesIcon: React.FC = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 30C7.10218 30 6.72064 29.842 6.43934 29.5607C6.15804 29.2794 6 28.8978 6 28.5V20.625C6 20.2272 6.15804 19.8456 6.43934 19.5643C6.72064 19.283 7.10218 19.125 7.5 19.125C7.89782 19.125 8.27936 19.283 8.56066 19.5643C8.84196 19.8456 9 20.2272 9 20.625V28.5C9 28.8978 8.84196 29.2794 8.56066 29.5607C8.27936 29.842 7.89782 30 7.5 30Z"
        fill="#E2E8F0"
      />
      <path
        d="M7.5 14.25C7.10218 14.25 6.72064 14.092 6.43934 13.8107C6.15804 13.5294 6 13.1478 6 12.75V7.5C6 7.10217 6.15804 6.72064 6.43934 6.43934C6.72064 6.15804 7.10218 6 7.5 6C7.89782 6 8.27936 6.15804 8.56066 6.43934C8.84196 6.72064 9 7.10217 9 7.5V12.75C9 13.1478 8.84196 13.5294 8.56066 13.8107C8.27936 14.092 7.89782 14.25 7.5 14.25Z"
        fill="#E2E8F0"
      />
      <path
        d="M18 30C17.6022 30 17.2206 29.842 16.9393 29.5607C16.658 29.2794 16.5 28.8978 16.5 28.5V25.875C16.5 25.4772 16.658 25.0956 16.9393 24.8143C17.2206 24.533 17.6022 24.375 18 24.375C18.3978 24.375 18.7794 24.533 19.0607 24.8143C19.342 25.0956 19.5 25.4772 19.5 25.875V28.5C19.5 28.8978 19.342 29.2794 19.0607 29.5607C18.7794 29.842 18.3978 30 18 30Z"
        fill="#E2E8F0"
      />
      <path
        d="M18 19.5C17.6022 19.5 17.2206 19.342 16.9393 19.0607C16.658 18.7794 16.5 18.3978 16.5 18V7.5C16.5 7.10218 16.658 6.72064 16.9393 6.43934C17.2206 6.15804 17.6022 6 18 6C18.3978 6 18.7794 6.15804 19.0607 6.43934C19.342 6.72064 19.5 7.10218 19.5 7.5V18C19.5 18.3978 19.342 18.7794 19.0607 19.0607C18.7794 19.342 18.3978 19.5 18 19.5Z"
        fill="#E2E8F0"
      />
      <path
        d="M28.5 30C28.1022 30 27.7206 29.842 27.4393 29.5607C27.158 29.2794 27 28.8978 27 28.5V18C27 17.6022 27.158 17.2206 27.4393 16.9393C27.7206 16.658 28.1022 16.5 28.5 16.5C28.8978 16.5 29.2794 16.658 29.5607 16.9393C29.842 17.2206 30 17.6022 30 18V28.5C30 28.8978 29.842 29.2794 29.5607 29.5607C29.2794 29.842 28.8978 30 28.5 30Z"
        fill="#E2E8F0"
      />
      <path
        d="M28.5 11.625C28.1022 11.625 27.7206 11.467 27.4393 11.1857C27.158 10.9044 27 10.5228 27 10.125V7.5C27 7.10218 27.158 6.72064 27.4393 6.43934C27.7206 6.15804 28.1022 6 28.5 6C28.8978 6 29.2794 6.15804 29.5607 6.43934C29.842 6.72064 30 7.10218 30 7.5V10.125C30 10.5228 29.842 10.9044 29.5607 11.1857C29.2794 11.467 28.8978 11.625 28.5 11.625Z"
        fill="#E2E8F0"
      />
      <path
        d="M7.49967 22.1249C6.42211 22.1249 5.36878 21.8051 4.47304 21.2062C3.57729 20.6072 2.8794 19.7559 2.46773 18.7601C2.05605 17.7643 1.9491 16.6687 2.16042 15.6121C2.37173 14.5554 2.89181 13.5853 3.65481 12.8244C4.41781 12.0635 5.38941 11.5461 6.44663 11.3377C7.50384 11.1293 8.59912 11.2392 9.59379 11.6537C10.5885 12.0681 11.4378 12.7683 12.0343 13.6657C12.6308 14.5631 12.9476 15.6173 12.9447 16.6949C12.9407 18.1364 12.3653 19.5175 11.3446 20.5354C10.3239 21.5533 8.94118 22.1249 7.49967 22.1249ZM7.49967 14.2499C7.01609 14.2499 6.54338 14.3933 6.1413 14.6619C5.73922 14.9306 5.42584 15.3124 5.24078 15.7592C5.05573 16.206 5.00731 16.6976 5.10165 17.1719C5.19599 17.6461 5.42885 18.0818 5.77079 18.4237C6.11273 18.7657 6.54839 18.9985 7.02267 19.0929C7.49696 19.1872 7.98856 19.1388 8.43533 18.9538C8.8821 18.7687 9.26395 18.4553 9.53261 18.0532C9.80127 17.6512 9.94467 17.1784 9.94467 16.6949C9.94666 16.3732 9.88478 16.0544 9.76261 15.7569C9.64044 15.4593 9.46042 15.189 9.23298 14.9615C9.00555 14.7341 8.73522 14.5541 8.43768 14.4319C8.14014 14.3098 7.82131 14.2479 7.49967 14.2499Z"
        fill="#E2E8F0"
      />
      <path
        d="M17.9997 27.3749C16.9221 27.3749 15.8688 27.0551 14.973 26.4562C14.0773 25.8572 13.3794 25.0059 12.9677 24.0101C12.5561 23.0143 12.4491 21.9187 12.6604 20.8621C12.8717 19.8054 13.3918 18.8353 14.1548 18.0744C14.9178 17.3135 15.8894 16.7961 16.9466 16.5877C18.0038 16.3793 19.0991 16.4892 20.0938 16.9037C21.0885 17.3181 21.9378 18.0183 22.5343 18.9157C23.1308 19.8131 23.4476 20.8673 23.4447 21.9449C23.4407 23.3864 22.8653 24.7675 21.8446 25.7854C20.8239 26.8033 19.4412 27.3749 17.9997 27.3749ZM17.9997 19.4999C17.5161 19.4999 17.0434 19.6433 16.6413 19.9119C16.2392 20.1806 15.9258 20.5624 15.7408 21.0092C15.5557 21.456 15.5073 21.9476 15.6016 22.4219C15.696 22.8961 15.9289 23.3318 16.2708 23.6737C16.6127 24.0157 17.0484 24.2485 17.5227 24.3429C17.997 24.4372 18.4886 24.3888 18.9353 24.2038C19.3821 24.0187 19.7639 23.7053 20.0326 23.3032C20.3013 22.9012 20.4447 22.4284 20.4447 21.9449C20.4467 21.6232 20.3848 21.3044 20.2626 21.0069C20.1404 20.7093 19.9604 20.439 19.733 20.2115C19.5055 19.9841 19.2352 19.8041 18.9377 19.6819C18.6401 19.5598 18.3213 19.4979 17.9997 19.4999Z"
        fill="#E2E8F0"
      />
      <path
        d="M28.4997 19.4999C27.4221 19.4999 26.3688 19.1801 25.473 18.5812C24.5773 17.9822 23.8794 17.1309 23.4677 16.1351C23.0561 15.1393 22.9491 14.0437 23.1604 12.9871C23.3717 11.9304 23.8918 10.9603 24.6548 10.1994C25.4178 9.43847 26.3894 8.92107 27.4466 8.71267C28.5038 8.50427 29.5991 8.61424 30.5938 9.02866C31.5885 9.44308 32.4378 10.1433 33.0343 11.0407C33.6308 11.9381 33.9476 12.9923 33.9447 14.0699C33.9407 15.5114 33.3653 16.8925 32.3446 17.9104C31.3239 18.9283 29.9412 19.4999 28.4997 19.4999ZM28.4997 11.6249C28.0161 11.6249 27.5434 11.7683 27.1413 12.0369C26.7392 12.3056 26.4258 12.6874 26.2408 13.1342C26.0557 13.581 26.0073 14.0726 26.1016 14.5469C26.196 15.0211 26.4289 15.4568 26.7708 15.7987C27.1127 16.1407 27.5484 16.3735 28.0227 16.4679C28.497 16.5622 28.9886 16.5138 29.4353 16.3287C29.8821 16.1437 30.264 15.8303 30.5326 15.4282C30.8013 15.0262 30.9447 14.5534 30.9447 14.0699C30.9467 13.7482 30.8848 13.4294 30.7626 13.1319C30.6404 12.8343 30.4604 12.564 30.233 12.3365C30.0055 12.1091 29.7352 11.9291 29.4377 11.8069C29.1401 11.6848 28.8213 11.6229 28.4997 11.6249Z"
        fill="#E2E8F0"
      />
    </svg>
  );
};
