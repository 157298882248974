import React, { FC, memo } from "react";
import { Box, Typography } from "@material-ui/core";
import { emptyTableStyles as styles } from "./EmptyTable.styles";
import EmptyTableIcon from "../../assets/icons/EmptyTableIcon";
import { IEmptyTable } from "./EmptyTable.interfaces";

const EmptyTable: FC<IEmptyTable> = ({ title, subtitle }: IEmptyTable) => {
  const classes = styles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.subTitle}>{subtitle}</Typography>
      <EmptyTableIcon />
    </Box>
  );
};

export default memo(EmptyTable);
