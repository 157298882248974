import { VariantDeferredOption } from "../../../types/update_deferred_merchant_request";

interface IValidateOptions {
  month: boolean;
  bank: boolean;
  monthOfGrace: boolean;
}

export const hasValidVariants = (
  variants: VariantDeferredOption[],
  { month, bank, monthOfGrace }: IValidateOptions
) => {
  const failedVariants = variants.filter(
    (variant) =>
      (month && variant.months.length === 0) ||
      (bank && variant?.bank?.length === 0) ||
      (monthOfGrace && variant.monthsOfGrace.length === 0)
  );

  return failedVariants.length === 0;
};
