import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { ISnackBar } from "@kushki/connect-ui/dist/Components/Atoms/DataDisplay/SnackBar/SnackBar.interface";

export interface ISharedReducer {
  notification: ISnackBar | undefined;
}

export const initialState = {
  notification: undefined,
};

export const sharedSlice = createSlice({
  initialState,
  name: "shared",
  reducers: {
    setNotification: (
      state: Draft<ISharedReducer>,
      { payload }: PayloadAction<ISnackBar | undefined>
    ) => {
      state.notification = payload;
    },
  },
});
