import { Grid, TextField } from "@mui/material";
import { RuleEnum } from "../../../../shared/infrastructure/enums/RuleEnum";
import {
  Labels,
  PlaceHolders,
} from "../../../../shared/infrastructure/constants/BusinessRuleConstants";
import { ErrorsEnum } from "../../../../shared/infrastructure/enums/ErrorEnum";
import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { useBinTextFieldState } from "./state/useBinTextFieldState";
import { ITransactionBinProps } from "../../../../shared/infrastructure/interfaces/ITransactionBinProps";

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      paddingLeft: 16,
    },
  })
);

export const BinTextField = (props: ITransactionBinProps) => {
  const classes = useStyles();

  const { handleOnChangeTextField, isValidFiledTextField, value } =
    useBinTextFieldState(props);

  return (
    <Grid container spacing={2} className={classes.textField}>
      <TextField
        inputProps={{ maxLength: 8 }}
        name={RuleEnum.BIN}
        placeholder={PlaceHolders.BIN}
        label={Labels.BIN}
        margin="normal"
        variant="outlined"
        fullWidth
        helperText={!isValidFiledTextField && ErrorsEnum.LENGTH_BIN}
        onChange={handleOnChangeTextField}
        value={value}
      />
    </Grid>
  );
};
