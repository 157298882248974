import { createAsyncThunk } from "@reduxjs/toolkit";
import { IChangeStatusRuleRequest } from "../../../../types/change_status_rule_request";
import axiosUtil from "@shared/utils/axios-util";
import { API_ROUTES } from "@shared/constants/api_routes";
import { IChangeStatusRuleResponse } from "../../../../types/change_status_rule_response";
import { thunkPrefix } from "@shared/constants/thunkPrefixes";
import { setModalActionProps } from "@store/actions/rulesAlarm.actions";
import { RootState } from "@store/store";
import { initialModalActionProps } from "@shared/constants/initialModalActionProps";
import { SearchRuleAlarmRequest } from "../../../../types/search_rule_alarm_request";
import { getRuleAlarms } from "@store/thunks/alarmConfig/alarmConfig.thunk";

interface IChangeStatusRequest {
  changeStatusRequest: IChangeStatusRuleRequest;
  searchRequest: SearchRuleAlarmRequest;
}

export const changeStatusRule = createAsyncThunk(
  thunkPrefix.updateRuleStatus,
  async (payload: IChangeStatusRequest, { getState, dispatch }) => {
    try {
      const { rulesAlarm } = getState() as RootState;

      dispatch(
        setModalActionProps({
          ...rulesAlarm.modalActionProps,
          disablePrimaryButton: true,
        })
      );

      const response = await axiosUtil.patch<IChangeStatusRuleResponse>(
        API_ROUTES.UPDATE_STATUS_RULE,
        payload.changeStatusRequest
      );

      if (response.status === 201) {
        dispatch(
          getRuleAlarms({
            cancelToken: undefined,
            request: payload.searchRequest,
          })
        );
      }
    } finally {
      dispatch(setModalActionProps(initialModalActionProps));
    }
  }
);
