import { IOptionSelection } from "../interfaces/option_selection";

export const ACCOUNT_TYPES: IOptionSelection[] = [
  { label: "CC", value: "CC" },
  { label: "CA", value: "CA" },
  { label: "DE", value: "DE" },
  { label: "NC", value: "NC" },
];

export const BANKS_COLOMBIA: IOptionSelection[] = [
  { label: "DING Tecnipagos S.A.", value: "000802" },
  { label: "Banco Agrario", value: "000040" },
  { label: "Banco Caja Social", value: "000032" },
  { label: "BANCO COMPARTIR", value: "000067" },
  { label: "Banco de AV VILLAS", value: "000052" },
  { label: "Banco de Bogota", value: "000001" },
  { label: "Banco de Occidente", value: "000023" },
  { label: "Banco Falabella S.A.", value: "000062" },
  { label: "Banco Finandina S.A.", value: "000063" },
  { label: "Banco GNB Sudameris", value: "000012" },
  { label: "BANCO MULTIBANK", value: "000064" },
  { label: "Banco Popular", value: "000002" },
  { label: "Banco PROCREDIT", value: "000058" },
  { label: "Banco Bancamia", value: "000059" },
  { label: "Banco Santander de Neg Col SA", value: "000065" },
  { label: "BANCOLDEX", value: "000031" },
  { label: "Bancolombia", value: "000007" },
  { label: "BBVA", value: "000013" },
  { label: "BNP PARIBAS", value: "000042" },
  { label: "Citibank", value: "000009" },
  { label: "Colpatria", value: "000019" },
  { label: "BANCO W S.A", value: "000053" },
  { label: "COLTEFINANCIERA", value: "000370" },
  { label: "FINANCIERA JURIDISCOOP", value: "000121" },
  { label: "CONFIAR", value: "000292" },
  { label: "Coop. Financiera de Antioquia", value: "000283" },
  { label: "COOPCENTRAL", value: "000066" },
  { label: "Corficolombiana", value: "000090" },
  { label: "Itaú antes Corpbanca", value: "000006" },
  { label: "COTRAFA COOPERATIVA FINANCIERA", value: "000289" },
  { label: "DAVIVIENDA", value: "000051" },
  { label: "DAVIPLATA", value: "002567" },
  { label: "Dale", value: "000097" },
  { label: "BANCO PICHINCHA S.A.", value: "000060" },
  { label: "Banco JP Morgan", value: "000071" },
  { label: "Credifinanciera", value: "000558" },
  { label: "NEQUI", value: "000507" },
  { label: "Movii", value: "000801" },
  { label: "Banco Itau", value: "000014" },
  { label: "Lulo Bank", value: "000070" },
  { label: "UALÁ", value: "000804" },
  { label: "Giros y Finanzas", value: "000303" },
  { label: "Banco BTG Pactual", value: "000805" },
  { label: "Iris", value: "000637" },
  { label: "BANCOOMEVA", value: "000061" },
  { label: "BANCO SERFINANZA S.A", value: "000069" },
  { label: "Banco Cooperativo Coopcentral Digital", value: "000566" },
  { label: "Superdigital", value: "000565" },
  { label: "Banco Mundo Mujer", value: "000047" },
  { label: "Rappipay DAVIplata", value: "000151" },
  { label: "Cofinep Cooperativa Financiera", value: "000291" },
  { label: "JFK COOPERATIVA FINANCIERA", value: "000286" },
  { label: "PIBANK", value: "000560" },
  { label: "POWWI", value: "000803" },
  { label: "Rappipay", value: "000811" },
  { label: "COINK S.A", value: "000812" },
  { label: "SANTANDER CONSUMER", value: "000813" },
  { label: "GLOBAL66", value: "000814" },
  { label: "BOLD CF", value: "000808" },
  { label: "NU", value: "000809" },
];

export const DOCUMENT_TYPES: IOptionSelection[] = [
  { label: "CC", value: "CC" },
  { label: "NIT", value: "NIT" },
  { label: "CE", value: "CE" },
  { label: "TI", value: "TI" },
  { label: "PP", value: "PP" },
];
