import { CountriesEnum } from "./CountriesEnum";

export const DEFERRED_TITLE = new Map([
  [CountriesEnum.ECUADOR, "Red de bancos"],
  [CountriesEnum.MEXICO, "Consorcio"],
]);

export interface IHeaders {
  align: "left" | "right" | "inherit" | "center" | "justify" | undefined;
  label: string;
  colspan?: number;
}

export const HEADERS: IHeaders[] = [
  {
    align: "left",
    label: "Tipo de diferidos",
  },
  {
    align: "left",
    label: "Número de cuotas (meses)",
  },
  {
    align: "left",
    label: "Meses de gracia",
  },
  {
    align: "center",
    label: "Red de bancos asociados",
  },
  {
    align: "center",
    label: "Activar/Desactivar",
  },
  {
    align: "center",
    label: "",
  },
];

export const HEADERS_2: IHeaders[] = [
  {
    align: "left",
    label: "Tipo de diferidos",
  },
  {
    align: "left",
    label: "Número de cuotas (meses)",
  },
  {
    align: "left",
    label: "Red de bancos asociados",
  },
  {
    align: "left",
    label: "",
  },
  {
    align: "center",
    label: "Activar/Desactivar",
  },
  {
    align: "center",
    label: "",
  },
];

export const HEADERS_3: IHeaders[] = [
  {
    align: "left",
    label: "Tipo de diferidos",
  },
  {
    align: "left",
    label: "Número de cuotas (meses)",
  },
  {
    align: "left",
    label: "",
  },
  {
    align: "left",
    label: "",
  },
  {
    align: "center",
    label: "Activar/Desactivar",
  },
  {
    align: "center",
    label: "",
  },
];

export const CentralCountries = [
  CountriesEnum.COSTA_RICA,
  CountriesEnum.PANAMA,
  CountriesEnum.GUATEMALA,
  CountriesEnum.EL_SALVADOR,
];

export const getHeaders = (
  hideMonthsOfGrace: boolean,
  country: CountriesEnum
) => {
  const hideHeadersCentralCountries = CentralCountries.includes(country);

  return hideHeadersCentralCountries
    ? HEADERS_3
    : hideMonthsOfGrace
    ? HEADERS_2
    : HEADERS;
};
