import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-okaidia.css";
import React from "react";

interface Props {
  value: string;
  handleChange: (value: string) => void;
}

export const CodeEditor: React.FC<Props> = ({ value, handleChange }) => (
  <Editor
    value={value}
    onValueChange={handleChange}
    highlight={(code) => highlight(code, languages.js, "javascript")}
    padding={{
      bottom: "8px",
      left: "16px",
      right: "16px",
      top: "8px",
    }}
    style={{
      backgroundColor: "#293036",
      borderRadius: "3px",
      color: "white",
      //eslint-disable-next-line
      fontFamily: '"IBM Plex Mono", "Fira code", "Fira Mono", monospace',
      fontSize: "1rem",
    }}
  />
);
