export enum CycleEnum {
  DAILY = "daily",
  WEEKLY = "weekly",
  BIWEEKLY = "biWeekly",
  MONTHLY = "monthly",
  QUARTERLY = "quarterly",
  ANNUAL = "annual",
  TWOWEEKLY = "twoWeekly",
  THREEWEEKLY = "threeWeekly",
}
