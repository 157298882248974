export enum QueryParamEnum {
  MODE = "mode",
  EDITION = "edition",
  CONSOLE = "console",
  PUBLIC_MERCHANT_ID = "publicMerchantId",
  MERCHANT_NAME = "merchantName",
  USER_NAME = "userName",
  HIDE_SIDE_BAR = "hideSideBar",
  ACTIVE_CLIENT = "activeClient",
}

export const NEW_CONSOLE = "3";
