import React from "react";
import { SplitButton } from "@kushki/connect-ui";
import { Box } from "@mui/material";
import { Plus } from "react-feather";
import { TitleSection } from "../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { ComponentIdEnum } from "../../../shared/infrastructure/enums/ComponentIdEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { ISplitButtonComponent } from "../../../shared/infrastructure/interfaces/ISplitButtonComponent";

export const SplitButtonComponent = (props: ISplitButtonComponent) => {
  return (
    <ComponentSecurityWrapper
      componentId={ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_ADD}
    >
      <Box>
        <SplitButton
          type={"default"}
          variant={"primary"}
          size={"small"}
          isLoading={false}
          onItemSelected={() => {}}
          items={props.dropdownSplitButtonObjectValues}
          text={TitleSection.ADD}
          icon={<Plus />}
        />
      </Box>
    </ComponentSecurityWrapper>
  );
};
