import { IStyles } from "../../../shared/utils";

export const PaginationSimpleStyle: IStyles = {
  button: {
    borderRadius: "4px",
    marginRight: "8px",
    width: "32px",
    height: "32px",
    boxShadow:
      "0 8px 17px rgba(106, 167, 197, 0.15), 0 1.6px 2.7625px rgba(106, 167, 197, 0.075)",
  },
  tablePagination: {
    width: "100%",
    paddingRight: "10px",
    ".MuiToolbar-root": {
      padding: "0",
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
    },
    ".MuiTablePagination-spacer": { display: "none" },
    ".MuiInputBase-root": {
      display: "none",
    },
    ".MuiTablePagination-displayedRows": {
      color: "primary.main",
      typography: "overline",
    },
    ".MuiTablePagination-select": {
      display: "none",
    },
    ".MuiTablePagination-selectIcon": {
      display: "none",
    },
    ".MuiTablePagination-selectLabel": {
      display: "none",
      color: "green",
    },
    ".MuiTablePagination-actions": {
      display: "flex",
      flexShrink: "inherit",
    },
  },
  paginationBox: {
    width: "100%",
  },
};
