import { KindEnum } from "./KindEnum";
import { StatusFlowEnum } from "./StatusFlowEnum";
import { CountryEnum } from "./CountryEnum";

export interface ITabInfo {
  tab: tabEnum;
  emptyTitle?: string;
  emptyMessage?: string;
}

export enum tabEnum {
  information = "Información",
  advancedConfig = "Config. avanzada",
  observation = "Observaciones",
  timeLine = "Línea de tiempo",
}

export const CatalogRenderTab = {
  [KindEnum.INVOICE]: {
    [StatusFlowEnum.EXECUTOR]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: [
        {
          tab: tabEnum.information,
        },
        {
          tab: tabEnum.advancedConfig,
          emptyTitle: "Esta factura no tiene Configuración Avanzada",
          emptyMessage:
            "'Para agregarlas, haz click en \"Configuración Avanzada\"'",
        },
        {
          tab: tabEnum.observation,
        },
        {
          tab: tabEnum.timeLine,
        },
      ],
    },
    [StatusFlowEnum.VALIDATOR]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: [
        {
          tab: tabEnum.information,
        },
        {
          tab: tabEnum.advancedConfig,
          emptyTitle: "Esta factura no tiene Configuración Avanzada",
          emptyMessage:
            "'Para agregarlas, haz click en \"Configuración Avanzada\"'",
        },
        {
          tab: tabEnum.observation,
        },
        {
          tab: tabEnum.timeLine,
        },
      ],
    },
  },
  [KindEnum.VOUCHER]: {
    [StatusFlowEnum.EXECUTOR]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: [
        {
          tab: tabEnum.information,
        },
        {
          tab: tabEnum.advancedConfig,
          emptyTitle: "Esta factura no tiene Configuración Avanzada",
          emptyMessage:
            "'Para agregarlas, haz click en \"Configuración Avanzada\"'",
        },
        {
          tab: tabEnum.observation,
        },
        {
          tab: tabEnum.timeLine,
        },
      ],
    },
    [StatusFlowEnum.VALIDATOR]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: [
        {
          tab: tabEnum.information,
        },
        {
          tab: tabEnum.advancedConfig,
          emptyTitle: "Esta factura no tiene Configuración Avanzada",
          emptyMessage:
            "'Para agregarlas, haz click en \"Configuración Avanzada\"'",
        },
        {
          tab: tabEnum.observation,
        },
        {
          tab: tabEnum.timeLine,
        },
      ],
    },
  },
  [KindEnum.CHARGE]: {
    [StatusFlowEnum.EXECUTOR]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: [
        {
          tab: tabEnum.information,
        },
        {
          tab: tabEnum.observation,
        },
        {
          tab: tabEnum.timeLine,
        },
      ],
    },
    [StatusFlowEnum.VALIDATOR]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: [
        {
          tab: tabEnum.information,
        },
        {
          tab: tabEnum.observation,
        },
        {
          tab: tabEnum.timeLine,
        },
      ],
    },
  },
  [KindEnum.DISPERSION]: {
    [StatusFlowEnum.EXECUTOR]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: [
        {
          tab: tabEnum.information,
        },
        {
          tab: tabEnum.observation,
        },
        {
          tab: tabEnum.timeLine,
        },
      ],
    },
    [StatusFlowEnum.VALIDATOR]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: [
        {
          tab: tabEnum.information,
        },
        {
          tab: tabEnum.observation,
        },
        {
          tab: tabEnum.timeLine,
        },
      ],
    },
  },
  [KindEnum.RECEIVABLE]: {
    [StatusFlowEnum.EXECUTOR]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: [
        {
          tab: tabEnum.information,
        },
        {
          tab: tabEnum.observation,
        },
        {
          tab: tabEnum.timeLine,
        },
      ],
    },
    [StatusFlowEnum.VALIDATOR]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: [
        {
          tab: tabEnum.information,
        },
        {
          tab: tabEnum.observation,
        },
        {
          tab: tabEnum.timeLine,
        },
      ],
    },
  },
  [KindEnum.CREDIT_NOTE]: {
    [StatusFlowEnum.EXECUTOR]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}`]: [
        {
          tab: tabEnum.information,
        },
        {
          tab: tabEnum.timeLine,
        },
      ],
    },
    [StatusFlowEnum.VALIDATOR]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}`]: [
        {
          tab: tabEnum.information,
        },
        {
          tab: tabEnum.timeLine,
        },
      ],
    },
  },
  [KindEnum.DEBIT_NOTE]: {
    [StatusFlowEnum.EXECUTOR]: {
      [`${CountryEnum.colombia}|${CountryEnum.peru}`]: [
        {
          tab: tabEnum.information,
        },
        {
          tab: tabEnum.timeLine,
        },
      ],
    },
    [StatusFlowEnum.VALIDATOR]: {
      [`${CountryEnum.colombia}|${CountryEnum.peru}`]: [
        {
          tab: tabEnum.information,
        },
        {
          tab: tabEnum.timeLine,
        },
      ],
    },
  },
};
