import React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import { useTypedController } from "@hookform/strictly-typed";
import { IBusinessRulesForm } from "../../shared/infrastructure/interfaces/IBusinessRulesForm";
import ConnectForm from "../commons/ConnectForm/ConnectForm";
import { ErrorsEnum } from "../../shared/infrastructure/enums/ErrorEnum";
import {
  DescriptionSection,
  LabelField,
} from "../../shared/infrastructure/constants/RulesConstants";

export const BusinessRulesGeneralInformation: React.FC = () => {
  return (
    <ConnectForm<IBusinessRulesForm>>
      {({ control, errors }) => {
        const TypedController = useTypedController<IBusinessRulesForm>({
          control: control,
        });

        return (
          <Grid container key={"businessRulesInformation"} spacing={2}>
            <Grid item xs={12}>
              <Typography variant={"body1"}>
                {DescriptionSection.BUSINESS_RULES_GENERAL_INFORMATION}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TypedController
                name={["name"]}
                rules={{ required: true }}
                render={(props) => (
                  <TextField
                    {...props}
                    id="businessRuleName"
                    label={LabelField.ALIAS}
                    variant="outlined"
                    error={!!errors.name}
                    fullWidth
                    helperText={!!errors.name && ErrorsEnum.REQUIRED_FIELD}
                  />
                )}
              />
            </Grid>
          </Grid>
        );
      }}
    </ConnectForm>
  );
};
