import { IDeferredType } from "../interfaces/IDeferredType";
import { ProcessorsEnum } from "./ProcessorsEnum";

export enum DeferredCodesMexico {
  WITHOUT_PROMOTION = "00",
  WITH_INTERESTS = "03",
  WITHOUT_INTERESTS = "05",
  SKIP_PAYMENT = "07",
  MONTHS_WITHOUT_INTEREST = "all",
}

export const DEFERRED_TYPE_MEXICO: IDeferredType[] = [
  {
    code: DeferredCodesMexico.WITHOUT_PROMOTION,
    hasMonthsOfGrace: false,
    processor: ProcessorsEnum.PROSA,
    text: "Sin promoción",
  },
  {
    code: DeferredCodesMexico.WITH_INTERESTS,
    hasMonthsOfGrace: true,
    processor: ProcessorsEnum.PROSA,
    text: "Diferido sin intereses",
  },
  {
    code: DeferredCodesMexico.WITHOUT_INTERESTS,
    hasMonthsOfGrace: true,
    processor: ProcessorsEnum.PROSA,
    text: "Diferido con intereses",
  },
  {
    code: DeferredCodesMexico.SKIP_PAYMENT,
    hasMonthsOfGrace: true,
    processor: ProcessorsEnum.PROSA,
    text: "Compre hoy y Pague después (Skip Payment Puro)",
  },
  {
    code: DeferredCodesMexico.MONTHS_WITHOUT_INTEREST,
    hasMonthsOfGrace: false,
    processor: ProcessorsEnum.BILLPOKET,
    text: "Meses sin intereses",
  },
];

export const DEFAULT_DEFERRED_TYPE = "Meses sin intereses";
