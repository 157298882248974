import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const mainContainerStyles = createNamedStyles({
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: 1,
    rowGap: (theme) => theme.spacing(5),
    pt: {
      xs: 2,
      md: 4,
    },
    px: {
      xs: 5,
      md: 15,
      lg: 26.25,
    },
    bottom: 0,
    backgroundColor: "background.paper",
  },
  returnButton: {
    color: "primary.light2",
    pl: 1,
    width: "fit-content",
  },
});
