import React, { FC } from "react";
import { Box } from "@mui/material";
import {
  FilterSideBar,
  ItemCategoryProps,
  RangeDatePicker,
  SelectSearchBy,
} from "@kushki/connect-ui";
import { IFiltersProps } from "@components/Filters/AlarmFilters/AlarmFilters.interfaces";
import { alarmFiltersStyles as styles } from "@components/Filters/AlarmFilters/AlarmFilters.styles";
import { SecurityWrapperRoles } from "@shared/enum/SecurityWrapperRoles";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { AlarmLabels } from "@shared/constants/labels/alarmLabels";
import { FilterTitle, RangeDatePickerEnum } from "@shared/enum/FilterLabelEnum";
import { useAlarmFilters } from "@components/Filters/AlarmFilters/state/useAlarmFilters";
import { DateFormatEnum } from "@shared/constants/parseMonths";
import SearchInput from "@components/SearchInput/SearchInput";
import { TypeEnum } from "@shared/constants/AlarmConfigConstants";

export const AlarmFilters: FC<IFiltersProps> = ({
  handleAutocompleteInput,
  handleSelectedChange,
  itemsSearch,
  inputValueProp,
  historicSearchItems,
  handleApplyFilters,
  handleSearchDate,
  tabType,
  searchInputProps,
  isLoading,
}: IFiltersProps) => {
  const { categoryItemsIndividual, categoryItemsGeneral } = useAlarmFilters();

  return (
    <Box sx={styles.root}>
      <Box sx={styles.autocomplete}>
        {tabType === TypeEnum.INDIVIDUAL ? (
          <ComponentSecurityWrapper
            componentId={SecurityWrapperRoles.M_COMPLIANCE_SEARCH_NODE}
          >
            <Box>
              <SelectSearchBy
                isLoading={isLoading}
                freeSolo={true}
                placeholder={AlarmLabels.LABEL_SEARCH_TEXT}
                items={itemsSearch}
                onInputChange={handleAutocompleteInput}
                onItemSelected={handleSelectedChange}
                isMultiple={true}
                type={"check"}
                inputValue={inputValueProp}
                values={historicSearchItems}
                optionsMultiple={{
                  displaySelectedChipsBelow: true,
                }}
              />
            </Box>
          </ComponentSecurityWrapper>
        ) : (
          <ComponentSecurityWrapper
            componentId={SecurityWrapperRoles.M_COMPLIANCE_SEARCH_GENERAL_NODE}
          >
            <Box>
              <SearchInput
                label={searchInputProps.label}
                handleSubmitSearchInput={
                  searchInputProps.handleSubmitSearchInput
                }
                handleChangeSearchValue={
                  searchInputProps.handleChangeSearchValue
                }
                searchValue={searchInputProps.searchValue}
              />
            </Box>
          </ComponentSecurityWrapper>
        )}
      </Box>
      <ComponentSecurityWrapper
        componentId={SecurityWrapperRoles.M_COMPLIANCE_FILTER_BY_DATE}
      >
        <Box id="rangeDate" sx={styles.rangeDatePicker}>
          <RangeDatePicker
            key={tabType}
            placeholder={RangeDatePickerEnum.RangeDateLabel}
            isOpen={false}
            isFilled={false}
            dateFormat={DateFormatEnum.dd_MMM_yyyy}
            onDateSelect={(startDate: string, finishDate: string) => {
              handleSearchDate(startDate, finishDate);
            }}
            disabledFutureDate={false}
            disabledOldDate={false}
            showClearDatesButton={true}
          />
        </Box>
      </ComponentSecurityWrapper>
      {tabType === TypeEnum.GENERAL ? (
        <ComponentSecurityWrapper
          componentId={SecurityWrapperRoles.M_COMPLIANCE_ALARM_RULES_FILTER}
        >
          <Box>
            <FilterSideBar
              categoryItems={categoryItemsGeneral}
              onClick={(items: ItemCategoryProps[]) => {
                handleApplyFilters(items);
              }}
              isOpen={false}
              title={FilterTitle.Title}
              filtersCount={1}
              isMinimized={false}
            />
          </Box>
        </ComponentSecurityWrapper>
      ) : (
        <ComponentSecurityWrapper
          componentId={SecurityWrapperRoles.M_COMPLIANCE_ALARM_RULES_FILTER}
        >
          <Box>
            <FilterSideBar
              categoryItems={categoryItemsIndividual}
              onClick={(items: ItemCategoryProps[]) => {
                handleApplyFilters(items);
              }}
              isOpen={false}
              title={FilterTitle.Title}
              filtersCount={1}
              isMinimized={false}
            />
          </Box>
        </ComponentSecurityWrapper>
      )}
    </Box>
  );
};
