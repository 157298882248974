import { OptionLabel } from "../../../components/ModalBodyBilling/AdvanceSettingsDialog/References/References";

export const commonReferences: OptionLabel[] = [
  { label: "No aplica", value: "NA" },
  { label: "Comentarios", value: "Comment" },
];

export const chileReferences: OptionLabel[] = [
  { label: "No aplica", value: "NA" },
  { label: "HES", value: "HES" },
  { label: "OC", value: "OC" },
  { label: "Comentarios", value: "Comment" },
];
