export enum LOCALSTORAGE {
  CACHE = "cache",
  MERCHANT = "merchant",
  MERCHANT_BASIC_INFORMATION = "merchantBasicInformation",
  BASIC_MERCHANT_INFORMATION = "basicMerchantInformation",
  MERCHANT_RESUME = "merchantResume",
  MERCHANT_ID = "merchantId",
  PAYLOAD = "payload",
  ROLES = "roles",
  USERNAME = "username",
  EMAIL = "email",
  FULL_NAME = "fullName",
  TIMEZONE = "timeZone",
  TIME_ZONE = "time-zone",
  ENV = "env",
  DISABLE_SIDE_BAR = "disableSideBar",
  LANGUAGE_CODE = "ljs-lang",
}
