import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    marginBottom: "30px",
  },
  flag: {
    width: "20px",
    height: "20px",
    marginLeft: "10px",
  },
});

export default useStyles;
