import React from "react";
import { TableCell, TableRow, Typography } from "@mui/material";
import { ratesRangeTableStyles as style } from "../../RatesInfo.styles";
import { Configuration } from "../../../../../types/rates_config_dynamo";
import { defaultTo, get } from "lodash";
import { RankIndicatorsIndexEnum } from "../../../../shared/infrastructure/RankIndicatorIndex-enum";
import { SET_CURRENCY } from "../../../../shared/utils/setCurrency";
import { CurrencyEnum } from "../../../../shared/enums/CurrencyEnum";
import { CountriesEnum } from "../../../../shared/enums/CountriesEnum";

export interface RatesRangesTableItemProps {
  row: Configuration;
  id: number;
  country?: string;
  ratesType?: string;
  currency?: string;
}

export const RatesRangesTableItem: React.FC<RatesRangesTableItemProps> = (
  props: RatesRangesTableItemProps
) => {
  const country: string = get(props, "country", "");
  const maximumAmount = get(props.row, "maximumAmount");
  const isChile: boolean = CountriesEnum.CHILE === country;

  const currencyCountry: string =
    isChile && props.currency
      ? props.currency
      : defaultTo(SET_CURRENCY[country], CurrencyEnum.USD);

  const currency =
    props.ratesType != RankIndicatorsIndexEnum.UNO ? currencyCountry : "";

  return (
    <TableRow key={props.id} sx={style.tableRow}>
      <TableCell align="right" sx={style.cellRank}>
        <Typography sx={style.labelTableCell}>
          {props.row.greaterThan} {currency}
        </Typography>
      </TableCell>
      <TableCell align="right" sx={style.cellRank}>
        <Typography sx={style.labelTableCell}>
          {props.row.fixedAmount} {currency}
        </Typography>
      </TableCell>
      <TableCell align="right" sx={style.cellRank}>
        <Typography sx={style.labelTableCell}>
          {props.row.variableAmount + "%"}
        </Typography>
      </TableCell>
      <TableCell
        align="right"
        sx={style.cellRank}
        style={{ paddingRight: "20px" }}
      >
        <Typography sx={style.labelTableCell}>
          {maximumAmount && `${maximumAmount} ${currency}`}
        </Typography>
      </TableCell>
    </TableRow>
  );
};
