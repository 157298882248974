import { SyntheticEvent, useState } from "react";
import { REFUND_MODAL_TABS } from "../../../../shared/constants/refund_modal_tabs";
import { DetailModalState } from "./useRefundDetailModal.interfaces";

export const useSelectedTransactionModalState = (): DetailModalState => {
  const [currentTab, setCurrentTab] = useState(0);

  const getTabContent = (tab: number) => {
    return REFUND_MODAL_TABS[tab];
  };

  const handleChangeTab = (
    _event: SyntheticEvent | undefined,
    newValue: number
  ) => {
    setCurrentTab(newValue);
  };

  return {
    currentTab: currentTab,
    handlers: {
      getTabContent,
      handleChangeTab,
    },
  };
};
