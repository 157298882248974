import { CustomStyles } from "@shared/interfaces/create_named_styles";

export const developerSectionsStyles: CustomStyles = {
  description: {
    color: "#6D7781",
    mt: "8px",
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
  },
  tooltip: {
    marginLeft: "8px",
    display: "grid",
    placeContent: "center",
  },
  sections: {
    display: "grid",
    gridTemplateColumns: `repeat(2,minmax(min(100%, 300px),1fr))`,
    ["@media screen and (max-width:1100px)"]: {
      gridTemplateColumns: `repeat(auto-fill,minmax(min(100%, 300px),1fr))`,
    },
    columnGap: "40px",
    paddingLeft: "64px",
    marginTop: "16px",
  },
  link: {
    marginBottom: "24px",
    cursor: "pointer",
  },
};
