import { useAppDispatch } from "../../../store/hooks/storeHook";
import { setActionsModal } from "../../../store/reducers/users/users.slice";
import {
  ActionsUserTypeEnum,
  ModalActionLabels,
} from "../../../shared/enum/UsersTableEnum";
import { IUseMassiveActionsUsers } from "../MassiveActionsUsers.interfaces";

export const useMassiveActionsUsers = (): IUseMassiveActionsUsers => {
  const dispatch = useAppDispatch();
  const handleOpenModalMassive = (total: number, type: ActionsUserTypeEnum) => {
    const totalStr: string = total.toString();

    if (type === ActionsUserTypeEnum.MASSIVE_DELETE) {
      dispatch(
        setActionsModal({
          cancelButton: ModalActionLabels.CANCEL_BUTTON,
          confirmButton: ModalActionLabels.BUTTON_DELETE_MASSIVE.replace(
            "__TOTAL__",
            totalStr
          ),
          description: ModalActionLabels.DESCRIPTION_DELETE_MASSIVE,
          openModal: true,
          title: ModalActionLabels.TITLE_DELETE_MASSIVE.replace(
            "__TOTAL__",
            totalStr
          ),
          type,
        })
      );
    } else {
      dispatch(
        setActionsModal({
          cancelButton: ModalActionLabels.CANCEL_BUTTON,
          confirmButton: ModalActionLabels.BUTTON_PASSWORD,
          description: ModalActionLabels.DESCRIPTION_PASSWORD_MASSIVE,
          openModal: true,
          title: ModalActionLabels.TITLE_PASSWORD_MASSIVE.replace(
            "__TOTAL__",
            totalStr
          ),
          type,
        })
      );
    }
  };

  return {
    handleOpenModalMassive,
  };
};
