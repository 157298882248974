import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { legalDetailsBodyStyles as styles } from "./LegalDetailBody.styles";
import LegalRepresentativeSection from "./AddPerson/LegalRepresentativeSection";
import { PersonTypeEnum } from "../../shared/constants/initial_state_legal_details";
import PhysicalPersonForm from "./Forms/PhysicalPersonForm/PhysicalPersonForm";
import MoralPersonForm from "./Forms/MoralPersonForm/MoralPersonForm";
import { LEGAL_TITLE } from "../../shared/constants/shareholder_constants";
import ShareholderSection from "../ShareholderSection/ShareholderSection";
import { useLegalDetailBodyState } from "./state/useLegalDetailBodyState";
import { ILegalDetailsBody } from "./LegalDetailBody.interfaces";
import { BoardMemberConstants } from "../../shared/constants/boardmember_constants";
import BoardMembersSection from "./BoardMembersSection/BoardMembersSection";
import FormControl from "../FormControl/FormControl";
import { CountryEnum } from "../../shared/countryEnum";
import { InputTypeEnum } from "../../shared/enum/inputTypeEnum";

const LegalDetailsBody: React.FC<ILegalDetailsBody> = ({
  isEdit,
  isCentralize,
  form,
}: ILegalDetailsBody) => {
  const {
    disabledInputs,
    disableMoral,
    disablePhysic,
    handleChangeRadioButtonValue,
    openModal,
    personType,
    legalRepresentative,
    isCustomerMerchant,
    isDisabledAddBoardMember,
    boardMembers,
  } = useLegalDetailBodyState();

  return (
    <Grid container xs={12} padding={2} sx={styles.container}>
      <Grid xs={12} md={12} lg={12}>
        <Card elevation={2} sx={styles.bodyContainer}>
          <CardContent sx={styles.bodyContent}>
            <Typography variant="h3" mb={"10px"}>
              Datos Legales
            </Typography>

            {personType === PersonTypeEnum.FISICA ? (
              <LegalRepresentativeSection
                title={LEGAL_TITLE}
                description="Agrega al menos un representante legal."
                buttonText="Agregar representante legal"
                modalSize={"md"}
                isDisable={disablePhysic || (isEdit && isCentralize)}
                isOpenModal={openModal}
                formComponent={<PhysicalPersonForm />}
                personType={personType}
              />
            ) : (
              <>
                <LegalRepresentativeSection
                  title={LEGAL_TITLE}
                  description="Agrega al menos un representante legal."
                  buttonText="Agregar representante legal"
                  modalSize={"md"}
                  isDisable={disableMoral || (isEdit && isCentralize)}
                  isOpenModal={openModal}
                  formComponent={<MoralPersonForm />}
                  personType={personType}
                />
                <ShareholderSection
                  isEdit={isEdit}
                  isCentralize={isCentralize}
                />
              </>
            )}
            {isCustomerMerchant && (
              <>
                <BoardMembersSection
                  title={BoardMemberConstants.TITLE}
                  description={BoardMemberConstants.DESCRIPTION}
                  buttonText={BoardMemberConstants.BUTTON_TEXT}
                  modalSize={"md"}
                  isDisable={isDisabledAddBoardMember}
                  boardMembers={boardMembers}
                />
              </>
            )}
            {legalRepresentative.length >= 1 && (
              <>
                <Typography>
                  ¿Alguna de las personas vinculadas al customer es una persona
                  expuesta públicamente?
                </Typography>
                <FormControl
                  onChange={handleChangeRadioButtonValue}
                  isDisable={disabledInputs}
                  control={form!.control!}
                  inputComponent={InputTypeEnum.RADIO_PUBLICLY_EXPOSED}
                  label=""
                  name="exposed"
                  country={CountryEnum.mexico}
                  rules={{
                    required: {
                      message: "Ingresar los datos",
                      value: true,
                    },
                  }}
                />
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default LegalDetailsBody;
