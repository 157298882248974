import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { Catalog, CatalogResponse } from "../../../../types/catalog_response";
import { AxiosResponse } from "axios";
import { defaultTo, get, unset } from "lodash";
import { CatalogRequest } from "../../../../types/catalog_request";

export const getListCatalogs = createAsyncThunk<
  CatalogResponse,
  CatalogRequest
>("catalogs/listCatalogs", async (payload: CatalogRequest) => {
  const typeCatalog: string = defaultTo(payload.typeCatalog, "");

  unset(payload, "typeCatalog");

  const response: AxiosResponse<Catalog[]> | void = await axios.post<Catalog[]>(
    API_ROUTES.CATALOGS,
    payload
  );

  if (get(response, "status") !== 200)
    return {
      data: [],
      typeCatalog,
    };

  return {
    data: defaultTo(get(response, "data"), []),
    typeCatalog,
  };
});
