import { createSlice } from "@reduxjs/toolkit";
import { MerchantDeferredResponse } from "../../../../types/merchant_deferred_response";
import { getMerchantDeferred } from "../../thunks/deferred/deferred.thunk";

export interface IDeferredAppState {
  isLoadingMerchantDeferred: boolean;
  merchantDeferred: MerchantDeferredResponse[];
}

export const initialState: IDeferredAppState = {
  isLoadingMerchantDeferred: false,
  merchantDeferred: [
    {
      brand: "",
      deferred: [
        {
          banks: [],
          months: [],
          monthsOfGrace: [],
        },
      ],
      entity: "",
    },
  ],
};

export const deferredAppSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getMerchantDeferred.pending, (state) => {
        state.isLoadingMerchantDeferred = true;
      })
      .addCase(getMerchantDeferred.fulfilled, (state, action) => {
        state.merchantDeferred = action.payload;
        state.isLoadingMerchantDeferred = false;
      })
      .addCase(getMerchantDeferred.rejected, (state) => {
        state.isLoadingMerchantDeferred = false;
      });
  },
  initialState,
  name: "deferredApp",
  reducers: {},
});

export default deferredAppSlice.reducer;
