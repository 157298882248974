import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { SearchMerchantRequest } from "../../../../types/search_merchant_request";
import { SearchMerchantResponse } from "../../../../types/search_merchant_response";
import { dispatch } from "../../store";
import {
  setCustomerList,
  setSearchingMerchants,
} from "../../actions/merchantNode.actions";

export const searchMerchants = createAsyncThunk<void, SearchMerchantRequest>(
  "merchantNode/searchMerchant",
  async (request) => {
    dispatch(setSearchingMerchants(true));
    try {
      const response = await axios.post<SearchMerchantResponse>(
        API_ROUTES.CHILDREN_MERCHANTS_CORE,
        request
      );

      dispatch(setCustomerList(response.data));
    } catch (e) {}
    dispatch(setSearchingMerchants(false));
  }
);
