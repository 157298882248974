import { defaultTo, isEqual } from "lodash";
import { useLocation } from "react-router-dom";
import { QueryParamEnum } from "../enum/QueryParamEnum";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const handleSetUrl = (
  merchantInfoId: string | null,
  merchantName?: string | null
) => {
  let baseUrl: string = "/users/user/";
  let queryParam: string = "";
  const urlSearchParams: URLSearchParams = new URLSearchParams(
    window.location.search
  );

  const hasConsoleQueryParam: boolean =
    urlSearchParams.get(QueryParamEnum.CONSOLE) === "3";

  if (hasConsoleQueryParam) {
    queryParam = `${merchantInfoId || merchantName ? "&" : "?"}console=3`;
  }

  return { baseUrl, hasConsoleQueryParam, queryParam };
};

export const verifyIsConsole3 = (): boolean => {
  const query = useQuery();

  return isEqual(defaultTo(query.get("console"), ""), "3");
};
