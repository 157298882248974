import { makeStyles } from "@material-ui/core/styles";

export const downloadTemplateDispersionStyles = makeStyles({
  title: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#293036",
    flexGrow: 1,
  },
  inputLinkGrid: {
    textAlign: "center",
    marginTop: "60px",
  },
  uafLinkGrid: {
    textAlign: "center",
    marginTop: "22px",
    marginRight: "16px",
  },
  descriptionGrid: {
    marginTop: "70px",
  },
  link: {
    marginLeft: "7px",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "150%",
    color: "#4498EE",
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
  },
  descriptionText: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "110%",
    color: "#293036",
  },
  box: {
    marginTop: "8px",
  },
});
