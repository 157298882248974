import { PageHeader } from "../../components/PageHeader/PageHeader";
import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  getDefaultPayoutsTransferProcessor,
  getDefaultProcessor,
  getProcessors,
  IAppAction,
  updateProcessor,
} from "../../store/actionCreators";
import { IAppState } from "../../store/reducer";
import { ThunkDispatch } from "redux-thunk";
import { useProcessorIndexState } from "./state/useProcessorIndexState";
import ModalDialog from "../../components/commons/ModalDialog/ModalDialog";
import ProcessorTable from "../../components/ProcessorsTable/ProcessorTable";
import { ModalOverlay } from "@kushki/frontend-molecules/modal";
import { ModalHeaderInfo } from "../../components/ModalInfo/ModalHeaderInfo/ModalHeaderInfo";
import { ModalBodyInfo } from "../../components/ModalInfo/ModalBodyInfo/ModalBodyInfo";
import { ModalFooterSection } from "../../components/ModalInfo/ModalFooterSection/ModalFooterSection";
import { get, isEqual } from "lodash";
import FilterComponent from "../../components/FilterComponent/FilterComponent";
import { Filter } from "../../shared/infrastructure/interfaces/IFilter";
import { TextWithLabel } from "../../components/commons/TextWithLabel/TextWithLabel";
import { Grid } from "@material-ui/core";
import { IUpdateProcessor } from "../../shared/infrastructure/interfaces/IUpdateProcessor";
import {
  ProcessorsEnum,
  ProcessorStatusEnum,
} from "../../shared/infrastructure/constants/ProcessorsEnum";

export interface IProcessorsStateProps {
  state: IAppState;
}

export interface IProcessorsFunctionsProps {
  getProcessors(merchantId?: string, filterToSend?: Filter): void;
  updateProcessor(processor: IUpdateProcessor): void;
  getDefaultProcessor(merchantId: string): void;
  getDefaultPayoutsTransferProcessor(merchantId: string): void;
}

export type ProcessorsIndexProps = IProcessorsStateProps &
  IProcessorsFunctionsProps;

export const ProcessorIndex: React.FC<ProcessorsIndexProps> = (
  props: ProcessorsIndexProps
) => {
  const {
    filters,
    state,
    merchantId,
    handlers,
    country,
    modalConfig,
    origin,
    merchantInfo,
    defaultProcessors,
    defaultPayoutsTransferProcessor,
    pagination,
    isCentralized,
    isCountryIncluded,
    renderPayoutsCard,
  } = useProcessorIndexState(props);

  return (
    <React.Fragment>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={11} xl={10}>
          {merchantId && merchantInfo && (
            <PageHeader
              title="Procesadores"
              merchantId={merchantId}
              country={country}
              modalConfig={modalConfig}
              origin={origin}
              merchantInfo={merchantInfo}
              renderPayoutsCard={renderPayoutsCard}
            />
          )}
          <FilterComponent {...filters.buttonFilter} />
          <TextWithLabel
            label="Procesador por defecto"
            style={{ marginBottom: "30px" }}
          />
          <ProcessorTable
            processors={state.processors}
            defaultProcessors={defaultProcessors}
            defaultPayoutsTransferProcessor={defaultPayoutsTransferProcessor}
            handleOpenDialogFailOver={handlers.handleOpenDialogFailOver}
            handleOpenModalDetail={handlers.handleOpenModalDetail}
            handleRefreshProcessors={handlers.handleRefreshProcessors}
            handleUpdateProcessor={props.updateProcessor}
            limit={5}
            pagination={pagination}
          />
          <ModalDialog
            title="Asignar procesador Fail Over: "
            contentText={`Selecciona un procesador para asignar como Fail Over de: ${get(
              state,
              "processorName",
              ""
            )} - ${get(state, "processorInfo.alias", "")} `}
            open={state.openDialogCancel}
            buttonOkLabel="Asignar"
            buttonCancelLabel="Cancelar"
            handleCloseFailOver={handlers.handleCloseDialogFailOver}
            handleOnChangeFailOver={handlers.handleOnChangeFailOver}
            processors={state.allProcessors}
            processorSelected={state.processorSelected}
            handleAssignDialogFailOver={handlers.handleAssignDialogFailOver}
            processor={state.processorInfo!}
          />
          {state.processorInfo && (
            <ModalOverlay
              header={<ModalHeaderInfo processorInfo={state.processorInfo} />}
              body={
                <ModalBodyInfo
                  processorInfo={state.processorInfo}
                  isCountryIncluded={isCountryIncluded}
                />
              }
              footer={
                isCentralized ||
                isEqual(
                  get(state.processorInfo, "status"),
                  ProcessorStatusEnum.DISABLED
                ) ||
                isEqual(
                  get(state.processorInfo, "processorName", ""),
                  ProcessorsEnum.BANCO_DE_BOGOTA
                ) ? (
                  <></>
                ) : (
                  <ModalFooterSection
                    handleClick={handlers.handleOpenDialogFailOver}
                    processorInfo={state.processorInfo}
                    isCountryIncluded={isCountryIncluded}
                  />
                )
              }
              openModal={state.openModalDetail}
              onCloseModal={handlers.handleCloseModal}
              overlay={false}
              backdrop={false}
              transition={"left"}
            />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export const mapStateToProps: (state: IAppState) => IProcessorsStateProps = (
  state: IAppState
): IProcessorsStateProps => ({ state });

export const mapDispatchToProps: (
  dispatch: Dispatch
) => IProcessorsFunctionsProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): IProcessorsFunctionsProps => ({
  getProcessors: (merchantId: string, filterToSend: Filter): void =>
    dispatch(getProcessors(merchantId, filterToSend)),
  updateProcessor: (body: IUpdateProcessor): void =>
    dispatch(updateProcessor(body)),
  getDefaultProcessor: (merchantId: string): void =>
    dispatch(getDefaultProcessor(merchantId)),
  getDefaultPayoutsTransferProcessor: (merchantId: string): void =>
    dispatch(getDefaultPayoutsTransferProcessor(merchantId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProcessorIndex);
