import React, { FC } from "react";
import { Box, Grid, MenuItem, Typography } from "@mui/material";
import SelectItemsField from "../FormInputFields/SelectItemsField/SelectItemsField";
import { DISPERSION_FREQUENCY_LIST } from "../../shared/constants/dispersions/dispersions";
import { ChargeFormStyles as styles } from "./ChargeForm.styles";
import { FrequencyEnum } from "../../shared/enums/frequencyEnum";
import { IChargeFormProps } from "./ChargeForm.interfaces";
import { useChargeFormState } from "./state/useChargeFormState";
import { ChargeFields } from "../../shared/enums/chargeEnum";
import {
  CHARGE_FIRST_LABEL,
  CHARGE_FREQUENCY,
} from "../../shared/constants/labels/charge_container_labels";

const ChargeForm: FC<IChargeFormProps> = ({
  form: { control, errors, resetField, watch },
  isBranchCentralized,
  loading,
}: IChargeFormProps) => {
  useChargeFormState({
    control,
    loading,
    resetField: resetField!,
    watch,
  });

  return (
    <Box sx={styles.container}>
      <form>
        <Grid container>
          <Grid item xs={12}>
            <Typography color="text.dark" variant="subtitle2">
              {CHARGE_FIRST_LABEL}
            </Typography>
          </Grid>
          <Grid item xs={6} md={5} sx={styles.gridSelect}>
            <SelectItemsField
              name={ChargeFields.FREQUENCY}
              control={control}
              errors={errors}
              label={CHARGE_FREQUENCY}
              disabled={isBranchCentralized}
              defaultValue={FrequencyEnum.NONE}
              items={DISPERSION_FREQUENCY_LIST.map((item, index) => (
                <MenuItem key={`df-${index}`} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default ChargeForm;
