import React, { FC, useState } from "react";
import { TableNodes } from "../../components/Table/TableNodes/TableNodes";
import { HeaderFilters } from "../../components/HeaderFilters/HeaderFilters";
import { EntityNameEnum } from "../../shared/enums/EntityNameEnum";
import { ItemCategoryItem } from "../../components/Filters/ItemCategory/interfaces";
import { BRANCH_PER_PAGE } from "../../shared/constants/labels/label_rows_per_page";
import { useDashboardBranch } from "./state/useDashboardBranch";
import EmptyResults from "../EmptyResults/EmptyResults";
import { IDashboardProps } from "./DashboardBranch.interfaces";
import {
  CATALOG_HEADS,
  TableTypeEnum,
} from "../../shared/catalogs/CatalogConfigTable";
import { BranchEnum } from "../../shared/enums/BranchEnum";

const DashboardBranch: FC<IDashboardProps> = ({
  showEmptyScreen,
  emptyAddText,
  basicNodeInfo: { entityName, path },
  nodeData,
  isBranchTab,
}: IDashboardProps) => {
  const {
    countData,
    handleChangeFilterDetails,
    handleDownloadFile,
    isLoadingDownloadFile,
    paginationProps: {
      handleChangePage,
      handleChangeRowsPerPage,
      page,
      rowsPerPage,
      totalData,
    },
    rows,
    resetFilters,
    showEmptyScreen: showEmptyScreenHook,
  } = useDashboardBranch({
    entityName,
    nodeData,
    path,
    showEmptyScreen,
  });

  const [additionalCountries] = useState<ItemCategoryItem[]>([]);

  return (
    <React.Fragment>
      {showEmptyScreenHook && countData < 1 ? (
        <EmptyResults addText={emptyAddText} title={BranchEnum.NO_DATA} />
      ) : (
        <>
          <HeaderFilters
            entityNameType={entityName as EntityNameEnum}
            additionalCountries={additionalCountries}
            getItemsSelected={handleChangeFilterDetails}
            handleDownload={handleDownloadFile}
            resetFilters={resetFilters}
            isLoadingDownloadFile={isLoadingDownloadFile}
            isBranchListTab={true}
          />
          <TableNodes
            headTable={CATALOG_HEADS[TableTypeEnum.BRANCH]}
            rows={rows}
            labelRowsPerPage={BRANCH_PER_PAGE}
            paginationProps={{
              handleChangePage,
              handleChangeRowsPerPage,
              page,
              rowsPerPage,
              totalData,
            }}
            isBranchTab={isBranchTab}
          />
        </>
      )}
    </React.Fragment>
  );
};

export default DashboardBranch;
