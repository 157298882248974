export enum PermissionsEnum {
  VIEWUSERS = "viewUsers",
  VIEWTRANSACTIONS = "viewTransactions",
  VIEWSMARTLINKS = "viewSmartLinks",
  LISTSMARTLINKS = "listSmartLinks",
  VIEWBANK = "viewBank",
  CREATEALLUSERS = "createAllUsers",
  VOIDANDREFUND = "voidAndRefund",
  PROCESSORSCRUD = "processorsCrud",
  ACCESSUSERMASTER = "accessUserMaster",
  ACCESSUSERMAKER = "accessUserMaker",
  ACCESSUSERREADER = "accessUserReader",
  ACCESSADMIN = "accessAdmin",
  AGENTSNR = "agentSnr",
  COORDINATORSNR = "coordinatorSnr",
  REVIEWERMGUAYAQUIL = "reviewerMguayaquil",
  ADMINCONCILIATION = "adminConciliation",
  AGENTB2C = "agentB2c",
  COORDINATORB2C = "coordinatorB2c",
  EXPRESSCHECKOUTMASTER = "expressCheckoutMaster",
}

export const PERMISSIONS_BACKOFFICE_ADMIN: string[] = [
  PermissionsEnum.VIEWUSERS,
  PermissionsEnum.CREATEALLUSERS,
  PermissionsEnum.PROCESSORSCRUD,
  PermissionsEnum.ACCESSADMIN,
  PermissionsEnum.ADMINCONCILIATION,
];

export const PERMISSIONS_BACKOFFICE_BANK: string[] = [PermissionsEnum.VIEWBANK];

export const PERMISSIONS_GLOBAL_REPORTS: string[] = [
  PermissionsEnum.ADMINCONCILIATION,
];

export const PERMISSIONS_BACKOFFICE_USER_MASTER: string[] = [
  PermissionsEnum.VIEWUSERS,
  PermissionsEnum.VIEWTRANSACTIONS,
  PermissionsEnum.VOIDANDREFUND,
  PermissionsEnum.ACCESSUSERMASTER,
  PermissionsEnum.ADMINCONCILIATION,
];

export const PERMISSIONS_BACKOFFICE_MASTER_CUSTOMER: string[] = [
  PermissionsEnum.VIEWUSERS,
  PermissionsEnum.VIEWTRANSACTIONS,
  PermissionsEnum.ACCESSUSERMASTER,
];

export const PERMISSIONS_BACKOFFICE_MASTER_CREDENTIALS: string[] = [];

export const PERMISSIONS_BACKOFFICE_MONITORING: string[] = [
  PermissionsEnum.ACCESSUSERMASTER,
];

export const PERMISSIONS_BACKOFFICE_USERMAKER: string[] = [
  PermissionsEnum.VIEWTRANSACTIONS,
  PermissionsEnum.VOIDANDREFUND,
];

export const PERMISSIONS_BACKOFFICE_USER_READER: string[] = [
  PermissionsEnum.VIEWTRANSACTIONS,
];

export const PERMISSIONS_WEBCHECKOUT_BASIC: string[] = [
  PermissionsEnum.VIEWSMARTLINKS,
];

export const PERMISSIONS_WEBCHECKOUT_MASTER: string[] = [
  PermissionsEnum.VIEWSMARTLINKS,
  PermissionsEnum.LISTSMARTLINKS,
];

export const PERMISSIONS_SNR_AGENT: string[] = [
  PermissionsEnum.AGENTSNR,
  PermissionsEnum.AGENTB2C,
];

export const PERMISSIONS_SNR_COORDINATOR: string[] = [
  PermissionsEnum.COORDINATORSNR,
  PermissionsEnum.AGENTSNR,
  PermissionsEnum.COORDINATORB2C,
  PermissionsEnum.AGENTB2C,
];

export const PERMISSIONS_MGUAYAQUIL_REVIEWER: string[] = [
  PermissionsEnum.REVIEWERMGUAYAQUIL,
];

export const PERMISSIONS_RESELLER: string[] = [];

export const PERMISSIONS_BACKOFFICE_BILLING_VALIDATOR: string[] = [];
export const PERMISSIONS_BACKOFFICE_BILLING_EXECUTOR: string[] = [];
export const PERMISSIONS_BACKOFFICE_CORE_ACCOUNTING_EXECUTOR: string[] = [];
export const PERMISSIONS_BACKOFFICE_CORE_ACCOUNTING_VALIDATOR: string[] = [];
export const PERMISSIONS_BACKOFFICE_CORE_ACCOUNTING_BOSS: string[] = [];
export const PERMISSIONS_BACKOFFICE_CORE_ACCOUNTING_ANALYST: string[] = [];

export const PERMISSIONS_BACKOFFICE_RISK_MANAGER: string[] = [];
export const PERMISSIONS_BACKOFFICE_RISK_ANALYST: string[] = [];

export const PERMISSIONS_CONCILIATION_EXECUTOR: string[] = [];
export const PERMISSIONS_CONCILIATION_VALIDATOR: string[] = [];

export const PERMISSIONS_BANK_CONCILIATION: string[] = [];

export const PERMISSIONS_BACKOFFICE_COMPLIANCE: string[] = [];

export const PERMISSIONS_BACKOFFICE_REFUND_VALIDATOR: string[] = [];
export const PERMISSIONS_BACKOFFICE_REFUND_EXECUTOR: string[] = [];

export const PERMISSIONS_BACKOFFICE_USER_PARENT: string[] = [
  PermissionsEnum.VIEWUSERS,
  PermissionsEnum.VIEWTRANSACTIONS,
];

export const PERMISSIONS_BACKOFFICE_EXPRESS_CHECKOUT: string[] = [
  PermissionsEnum.EXPRESSCHECKOUTMASTER,
];

export const PERMISSIONS_CHARGEBACK_MANAGER: string[] = [];
