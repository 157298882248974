import { makeStyles } from "@mui/styles";
import { defaultTo } from "lodash";
import { IChipStatusProps } from "./ChipStatus.interfaces";
import { Theme } from "@mui/material/styles";
import {
  getBackgroundChipColorByStatus,
  getColorChipByStatus,
} from "../../shared/utils/general_utils";

export const chipStatusStyles = makeStyles<Theme, IChipStatusProps>(() => ({
  chip: {
    backgroundColor: ({ text }) =>
      getBackgroundChipColorByStatus(defaultTo(text, "")),
    color: ({ text }) => getColorChipByStatus(defaultTo(text, "")),
  },
}));
