export enum ConciliationAction {
  PENDING_REFUND = "pending_refund",
  OMITTED = "omitted",
  CONCILIATED = "conciliated",
  PENDING = "pending",
  APPROVED = "approved",
  REVIEW_PROCESSOR = "review_processor",
  REVIEW_KUSHKI = "review_kushki",
  STATUS_PENDING_RFN = "statusPendingRefund",
  STATUS_DECLINED_RFN = "statusDeclinedRefund",
  STATUS_APPROVED_RFN = "statusApprovedRefund",
}

export enum ConciliationActionCodes {
  APRRFN_PRCS = "APRRFN_PRCS",
  DCLRFN_PRCS = "DCLRFN_PRCS",
  APRCNL_FNPS = "APRCNL_FNPS",
  DCLCNL_FNPS = "DCLCNL_FNPS",
  APRGBCK_FNPS = "APRGBCK_FNPS",
  DCLGBCK_FNPS = "DCLGBCK_FNPS",
  APROMT = "APROMT",
}

export const CONCILIATION_ACTION_TRANSLATE: Partial<
  Record<ConciliationAction | ConciliationActionCodes, string>
> = {
  [ConciliationAction.PENDING_REFUND]: "Reembolso Pendiente",
  [ConciliationAction.OMITTED]: "OMITIDA",
  [ConciliationAction.CONCILIATED]: "CONCILIADA",
  [ConciliationAction.APPROVED]: "CONCILIADA",
  [ConciliationAction.PENDING]: "NO CONCILIADA",
  [ConciliationAction.REVIEW_PROCESSOR]: "Revisión Procesador",
  [ConciliationAction.REVIEW_KUSHKI]: "Revisión Kushki",
  [ConciliationAction.STATUS_PENDING_RFN]: "NO CONCILIADA",
  [ConciliationAction.STATUS_DECLINED_RFN]: "NO CONCILIADA",
  [ConciliationAction.STATUS_APPROVED_RFN]: "NO CONCILIADA",
  [ConciliationActionCodes.APRRFN_PRCS]:
    "Reembolso - Aprobado por el procesador",
  [ConciliationActionCodes.DCLRFN_PRCS]:
    "Reembolso - Declinado por el procesador",
  [ConciliationActionCodes.APRCNL_FNPS]: "Cancelar - Aprobado por FinOps",
  [ConciliationActionCodes.DCLCNL_FNPS]: "Cancelar - Declinado por FinOps",
  [ConciliationActionCodes.APRGBCK_FNPS]: "Devolver - Aprobado por FinOps",
  [ConciliationActionCodes.DCLGBCK_FNPS]: "Devolver - Declinado por FinOps",
  [ConciliationActionCodes.APROMT]: "Omitir - N/A",
};

export enum ConciliationActionTimeLine {
  PENDING_REFUND = "pending_refund",
  OMITTED = "omitted",
  CONCILIATED = "conciliated",
  REVIEW_KUSHKI = "review_kushki",
  REVIEW_PROCESSOR = "review_processor",
  TRX_CREATED = "trx_created",
}

export const CONCILIATION_ACTION_TIME_LINE_TRANSLATE: Record<
  ConciliationActionTimeLine | ConciliationActionCodes,
  string
> = {
  [ConciliationActionTimeLine.PENDING_REFUND]: "Reembolso pendiente",
  [ConciliationActionTimeLine.OMITTED]: "Omitido",
  [ConciliationActionTimeLine.CONCILIATED]: "Conciliado",
  [ConciliationActionTimeLine.REVIEW_KUSHKI]: "Revisión interna",
  [ConciliationActionTimeLine.REVIEW_PROCESSOR]: "Revisión procesador",
  [ConciliationActionTimeLine.TRX_CREATED]: "Transacción creada",
  [ConciliationActionCodes.APRRFN_PRCS]:
    "Reembolso - Aprobado por el procesador",
  [ConciliationActionCodes.DCLRFN_PRCS]:
    "Reembolso - Declinado por el procesador",
  [ConciliationActionCodes.APRCNL_FNPS]: "Cancelar - Aprobado por FinOps",
  [ConciliationActionCodes.DCLCNL_FNPS]: "Cancelar - Declinado por FinOps",
  [ConciliationActionCodes.APRGBCK_FNPS]: "Devolver - Aprobado por FinOps",
  [ConciliationActionCodes.DCLGBCK_FNPS]: "Devolver - Declinado por FinOps",
  [ConciliationActionCodes.APROMT]: "Omitir - N/A",
};
