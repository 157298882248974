import { ICellTextCopyComponent } from "./CellTextCopy.interfaces";
import { FC } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { TableBodyStyles } from "../../Table/TableBody/TableBody.style";
import { IconCopy } from "@kushki/connect-ui";
import { clipboard } from "../../../shared/utils/tableUtils";
import { defaultTo } from "lodash";

export const CellTextCopy: FC<ICellTextCopyComponent> = ({
  align,
  textToCopy,
  title,
  subtitle,
}: ICellTextCopyComponent) => {
  let styles = TableBodyStyles;

  return (
    <Box
      sx={{ alignItems: "center", display: "flex" }}
      justifyContent={defaultTo(align, "center")}
    >
      <Box alignItems={align}>
        <Tooltip title={`${textToCopy}`} placement="top">
          <Typography>{title}</Typography>
        </Tooltip>

        <Typography sx={styles.subtitle}>{subtitle!}</Typography>
      </Box>
      <Box alignItems={align}>
        <IconButton
          onClick={(e) => {
            clipboard(textToCopy);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <IconCopy />
        </IconButton>
      </Box>
    </Box>
  );
};
