import { get, defaultTo } from "lodash";
import { utcToZonedTime, format } from "date-fns-tz";
import {
  ICatalog,
  TimeZoneCatalog,
} from "../infrastructure/constants/TimeZoneCatalog";

const _timeZoneDefault: string = "America/Guayaquil";

export const timeZoneHour = (
  text: string | undefined,
  value: string
): string => {
  if (text === undefined) return value === "utc" ? "-05:00" : _timeZoneDefault;
  const index = TimeZoneCatalog.findIndex(
    (data: ICatalog) => data.description === text
  );
  if (index >= 0) {
    const lang: string | undefined = get(
      TimeZoneCatalog[index],
      value,
      undefined
    );
    return lang !== undefined
      ? lang
      : value === "utc"
      ? "-05:00"
      : _timeZoneDefault;
  }
  return value === "utc" ? "-05:00" : _timeZoneDefault;
};

export const formatDateWithTimezone = (
  date: string,
  formatDate?: string
): string => {
  return format(
    utcToZonedTime(
      `${date}Z`,
      timeZoneHour(localStorage.getItem("timeZone")!, "region")
    ),
    defaultTo(formatDate, "yyyy-MM-dd'T'HH:mm:ss")
  );
};
