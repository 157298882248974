import {
  OptionsEnum,
  WebhookServiceEnum,
} from "../../../shared/infrastructure/constants/OptionsEnum";

export interface IUseModalTestConnection {
  translateCatalogPayments: (webhookServiceName: string) => string;
  translateCatalogProducts: (webhookServiceName: string) => string;
  translateCatalogOthers: (webhookServiceName: string) => string;
}

export const useModalTestConnection = (): IUseModalTestConnection => {
  const translateCatalogPayments = (webhookServiceName: string): string => {
    switch (webhookServiceName) {
      case WebhookServiceEnum.card:
        return OptionsEnum.CARD;
      case WebhookServiceEnum.cash:
        return OptionsEnum.CASH;
      case WebhookServiceEnum["payouts-cash"]:
        return OptionsEnum.PAYOUTS_CASH;
      case WebhookServiceEnum["payouts-transfer"]:
        return OptionsEnum.PAYOUTS_TRANSFER;
      case WebhookServiceEnum.transfer:
        return OptionsEnum.TRANSFER;
      case WebhookServiceEnum["transfer-subscriptions"]:
        return OptionsEnum.TRANSFER_SUBSCRIPTIONS;
      default:
        return "";
    }
  };

  const translateCatalogProducts = (webhookServiceName: string): string => {
    switch (webhookServiceName) {
      case WebhookServiceEnum.subscriptions:
        return OptionsEnum.SUBSCRIPTIONS;
      case WebhookServiceEnum.webcheckout:
        return OptionsEnum.WEBCHECKOUT;
      case WebhookServiceEnum.vpos:
        return OptionsEnum.VPOS;
      default:
        return "";
    }
  };

  const translateCatalogOthers = (webhookServiceName: string): string => {
    switch (webhookServiceName) {
      case WebhookServiceEnum["card-chargeback"]:
        return OptionsEnum.CARD_CHARGEBACK_VOID;
      default:
        return "";
    }
  };

  return {
    translateCatalogPayments,
    translateCatalogProducts,
    translateCatalogOthers,
  };
};
