// istanbul ignore file
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "rgba(2,51,101,1)",
      dark: "rgba(17,43,69,1)",
      light: "rgba(30,101,174,1)",
    },
    secondary: {
      main: "rgba(0,230,178,1)",
      dark: "rgba(13,194,152,1)",
      light: "rgba(222,255,247,1)",
    },
    info: {
      main: "rgba(68,152,238,1)",
      dark: "rgba(2,51,101,1)",
      light: "rgba(212,234,255,0.4)",
    },
    error: {
      main: "rgba(226,71,99,1)",
      dark: "rgba(173,12,42,1)",
      light: "rgba(255,223,229,0.3)",
    },
    warning: {
      main: "rgba(255,215,64,1)",
      dark: "rgba(180,120,0,1)",
      light: "rgba(252,235,193,0.3)",
    },
    success: {
      main: "rgba(0,230,178,1)",
      dark: "rgba(13,194,152,1)",
      light: "rgba(210,255,244,0.3)",
    },
    background: {
      paper: "#fff",
      default: "rgba(240,244,249,1)",
    },
    text: {
      primary: "rgba(41,48,54,1)",
      secondary: "rgba(46, 61, 74, 0.70)",
      disabled: "rgba(46, 61, 74, 0.36)",
    },
    grey: {
      A400: "rgba(41,48,54,1)",
      A700: "rgba(70,82,92,1)",
    },
  },
  typography: {
    fontFamily: "IBM Plex Sans,sans-serif",
  },
  overrides: {
    MuiListItemIcon: {
      root: {
        backgroundColor: "#FFFFFF",
        minWidth: "35px",
      },
    },
    MuiListItem: {
      root: {
        paddingBottom: "4px",
        paddingTop: "4px",
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
      },
      containedPrimary: {
        textTransform: "none",
        "&:hover": {
          color: "#FFF",
        },
      },
      outlinedPrimary: {
        borderRadius: "4px",
        backgroundColor: "#FFFFFF",
        textTransform: "none",
        color: "#023365",
      },
      outlined: {
        textTransform: "none",
      },
    },
  },
});

export default theme;
