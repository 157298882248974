import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const paymentMethodCardStyles = createNamedStyles({
  gridPaymentMethodCard: {
    backgroundColor: "#F7FAFC",
    width: "100%",
    display: "grid",
    padding: "16px 16px 16px 16px",
    marginRight: "-40px",
  },
  principalBox: {
    width: "100%",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
  secondBox: {
    justifyContent: "space-between",
    display: "inline-flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  title: {
    color: "#293036",
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    paddingLeft: "9px",
    width: "100%",
  },
  secondaryTitle: {
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px",
  },
  titleAgregator: {
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "150%",
    color: "#293036",
  },
  box2: {
    width: "100%",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
});
