import React, { FC } from "react";
import { Box, Container, Typography } from "@mui/material";
import { GenericBreadcrumb } from "@components/GenericBreadcrumb/GenericBreadcrumb";
import { useAlarms } from "@containers/Alarms/state/useAlarms";
import { alarmsStyles as styles } from "@containers/Alarms/Alarms.styles";
import HeaderAlarmsTable from "@components/HeaderAlarmsTable/HeaderAlarmsTable";
import {
  IconCircleWarn,
  IconDownload,
  ModalSimple,
  TableSimple,
} from "@kushki/connect-ui";
import {
  FOOTER_TEXT,
  ITEMS_PER_PAGE,
} from "@shared/constants/AlarmTableConstants";
import ModalCloseAlarm from "@components/ModalCloseAlarm/ModalCloseAlarm";
import ModalInvestigation from "@components/ModalInvestigation/ModalInvestigation";
import { WrapperContainer } from "@containers/WrapperContainer/WrapperContainer";
import { ModalDetailCard } from "@kushki/connect-ui/dist/Components/Organism/ModalDetailCard";
import {
  AlarmDetailCardEnum,
  TitleDescription,
} from "@shared/enum/AlarmDetailCardEnum";
import DetailCardBodyAlarms from "@components/organism/DetailCardBodyAlarms/DetailCardBodyAlarms";
import LoadingButton from "@mui/lab/LoadingButton";
import { ModalReactivateLabels } from "@shared/constants/labels/modalReactivateLabels";

const Alarms: FC = () => {
  const {
    closeAlarmModal,
    countSelectedAlarms,
    footer,
    handleBreadcrumbItem,
    handleFilterChange,
    handleSaveCloseAlarm,
    handleSelectedRow,
    rows,
    isLoadingTable,
    isOpenInvestigation,
    emailsArray,
    handleEmails,
    handleCloseModalInvestigation,
    handleInvestigation,
    selectedColumns,
    handleChangeColumns,
    headerTableCells,
    merchantData,
    handleAlarmInfoChange,
    handleAdditionalInfoChange,
    disabledButtonInvestigation,
    detailCard,
    isDisabledDownload,
    title: moduleTitle,
    isLoadingFilters,
    pathParamType,
    selectionActions,
    searchAlarmsRequest,
    downloadAlarmProps,
    reactivateAlarmModal,
  } = useAlarms();

  const {
    control,
    isDisabledCloseAlarm,
    handleCommentsChange,
    handleHideCloseAlarmModal,
    isOpenCloseAlarmModal,
  } = closeAlarmModal;

  const {
    closeReactivateAlarmModal,
    description: reactivateDescription,
    isDisabledReactivateAlarm,
    isOpenReactivateAlarmModal,
    reactivateAlarm,
    title: reactivateTitle,
  } = reactivateAlarmModal;

  return (
    <WrapperContainer>
      <Container>
        <GenericBreadcrumb {...handleBreadcrumbItem()} />
        <Box sx={styles.titleContainer}>
          <Typography variant={"h1"} sx={styles.title}>
            {moduleTitle}
          </Typography>
          <Box>
            <LoadingButton
              sx={styles.btnDownload}
              color={"primary"}
              variant={"contained"}
              size={"medium"}
              fullWidth={true}
              startIcon={<IconDownload fontSize="medium" />}
              onClick={downloadAlarmProps.handleDownloadFileAlarms}
              loading={downloadAlarmProps.isLoadingDownload}
              loadingPosition={"start"}
              disabled={isDisabledDownload}
            >
              Descargar{countSelectedAlarms}
            </LoadingButton>
          </Box>
        </Box>
        {!isLoadingFilters && (
          <HeaderAlarmsTable
            onChangeFilter={handleFilterChange}
            onChangeColumns={handleChangeColumns}
            selectedColumns={selectedColumns}
            pathParamType={pathParamType}
          />
        )}
        <Box>
          <TableSimple
            allowSelection
            isLoading={isLoadingTable}
            header={{
              cells: headerTableCells,
              headerProps: { isDisable: false },
            }}
            onSelectedRows={(selectedRows: string[]) => {
              handleSelectedRow(selectedRows);
            }}
            selectionActions={selectionActions(<IconCircleWarn />)}
            rows={rows}
            showPagination={true}
            footerPagination={{
              isDisable: false,
              itemsPerPage: footer.itemsPerPage,
              onItemsPerPageChange: footer.handleItemsPerPage,
              onPageClick: footer.handleSelectedPage,
              paginationText: FOOTER_TEXT,
              selectedPage: footer.selectedPage,
              textFieldSelector: ITEMS_PER_PAGE,
              totalItems: footer.total,
            }}
          />
        </Box>
        <ModalInvestigation
          open={isOpenInvestigation}
          onClose={handleCloseModalInvestigation}
          emailsArray={emailsArray}
          handleEmails={handleEmails}
          handleInvestigation={handleInvestigation}
          merchantData={merchantData!}
          handleAdditionalInfoChange={handleAdditionalInfoChange}
          handleAlarmInfoChange={handleAlarmInfoChange}
          disabledButtonInvestigation={disabledButtonInvestigation}
        />
        <ModalCloseAlarm
          control={control}
          handleSaveCloseAlarm={handleSaveCloseAlarm}
          isOpenCloseAlarmModal={isOpenCloseAlarmModal}
          handleHideCloseAlarmModal={handleHideCloseAlarmModal}
          isDisabledCloseAlarm={isDisabledCloseAlarm}
          handleCommentsChange={handleCommentsChange}
        />
        <ModalSimple
          descriptionText={reactivateDescription}
          titleText={reactivateTitle}
          typeModal={1}
          buttonPrimary={ModalReactivateLabels.REACTIVATE}
          buttonSecondary={ModalReactivateLabels.CANCEL}
          isOpen={isOpenReactivateAlarmModal}
          onClose={closeReactivateAlarmModal}
          onClickPrimary={reactivateAlarm}
          onClickSecondary={closeReactivateAlarmModal}
          buttonPrimaryDisabled={isDisabledReactivateAlarm}
          buttonSecondaryDisabled={isDisabledReactivateAlarm}
        />
        {/*// @ts-ignore*/}
        <ModalDetailCard
          header={{
            buttonRow: detailCard.buttonsRow,
            justifyContent: TitleDescription.JUSTIFY,
            secondaryText: detailCard.updatedBy,
            title: AlarmDetailCardEnum.TITLE,
            titleDescription: detailCard.createdBy,
            titleDescriptionColor: TitleDescription.COLOR,
            titleDescriptionVariant: TitleDescription.VARIANT,
          }}
          onClose={detailCard.handleClose}
          widthCard={"576px"}
          isOpen={detailCard.isOpen}
        >
          <DetailCardBodyAlarms searchAlarmsRequest={searchAlarmsRequest} />
        </ModalDetailCard>
      </Container>
    </WrapperContainer>
  );
};

export default Alarms;
