export const SIDEBAR = "sidebar";
export const SUB_MERCHANT = "Subcomercio";
export const M_CLIENTS = "M_CLIENTS";

export enum ComponentIdsEnum {
  M_CLIENTS_SEARCH_OWNER = "M_CLIENTS.Seleccióndeowner",
  M_CLIENTS_CREATE_CUSTOMER = "M_CLIENTS.CrearCustomer",
  M_CLIENTS_BRANCH_MANUAL_NUEVO = "M_CLIENTS.CrearBranches.AgregarBranchManual",
  M_CLIENTS_BRANCH_MANUAL_REGRESAR = "M_CLIENTS.CrearBranches.RegresarBranchManual",
  M_CLIENTS_BRANCH_MANUAL_CONTINUAR = "M_CLIENTS.CrearBranches.ContinuarBranchManual",
  M_CLIENTS_BRANCH_LIST_VALIDAR = "M_CLIENTS.CrearBranches.ValidarListBranch",
  M_CLIENTS_BRANCH_MANUAL = "M_CLIENTS.BRANCH_MANUAL",
}
export const OPEN_MODAL = "abrir modal simple";
export const OPEN_MODAL_CONFIRM = "abrir modal confirmación";
export const OPEN_MODAL_VALIDATE = "abrir modal de validación";
export const OPEN_MODAL_VALIDATE_ERRORS =
  "abrir modal de validación con errores";

export const OPEN_MODAL_LOADER_CHECKING_FILE = "abrir loader validando archivo";

export const OPEN_MODAL_LOADER_CREATE_BRANCHES =
  "abrir loader creando branches";
