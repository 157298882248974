import React, { FC } from "react";
import {
  Alia,
  Amex,
  Card,
  Diners,
  Discover,
  Mastercard,
  Visa,
} from "../../../assets/icons/cardLogos";
import { CardIconStyles } from "./CardIcon.styles";

interface CardIconProps {
  cardType: string;
}

const CardIcon: FC<CardIconProps> = (props: CardIconProps) => {
  const { cardType } = props;
  const cardBrands = (cardType: string) => {
    switch (cardType) {
      case "visa":
        return <Visa style={CardIconStyles} />;
      case "mastercard":
      case "master card":
        return <Mastercard style={CardIconStyles} />;
      case "amex":
      case "american express":
        return <Amex style={CardIconStyles} />;
      case "diners":
        return <Diners style={CardIconStyles} />;
      case "discover":
        return <Discover style={CardIconStyles} />;
      case "alia":
        return <Alia style={CardIconStyles} />;
    }

    return <Card />;
  };

  return cardBrands(cardType);
};

export default CardIcon;
