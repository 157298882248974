import React, { RefObject, useEffect, useRef, useState } from "react";
import { IDownloadButton } from "./DownloadButton";

export interface IUseDownloadButton {
  anchorRef: RefObject<HTMLButtonElement>;
  open: boolean;
  handler: {
    handleToggle: () => void;
    handleClose: (event: React.MouseEvent<EventTarget>) => void;
    handleDownload: (format: string) => void;
  };
}

export const useDownloadButton = (
  props: IDownloadButton
): IUseDownloadButton => {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    )
      return;
    setOpen(false);
  };

  const handleDownload = (format: string) => {
    props.handleDownload(format);
    setOpen(false);
  };

  return {
    anchorRef,
    open,
    handler: {
      handleToggle,
      handleClose,
      handleDownload,
    },
  };
};
