/* istanbul ignore file */
import React, { Suspense, useEffect } from "react";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { routes } from "./shared/infrastructure/constants/routes";
import { IAppState } from "./store/reducer";
import { getAuthInfo, IAppAction } from "./store/actionCreators";
import { auth } from "./shared/auth";
import ConnectIndex from "./containers/ConnectIndex/ConnectIndex";
import UserMasterIndex from "./containers/UserMasterIndex/UserMasterIndex";
import AdminIndex from "./containers/AdminIndex/AdminIndex";
import AssistanceIndex from "./containers/AssistanceIndex/AssistanceIndex";
import HistoryIndex from "./containers/HistoryIndex/HistoryIndex";

interface INavigationFunctionsProps {
  getAuthInfo: () => void;
}

const Navigation: React.FC<INavigationFunctionsProps> = (
  props: INavigationFunctionsProps
) => {
  useEffect(() => {
    props.getAuthInfo();
  }, []);

  const getIndex = (): React.ReactNode =>
    auth.isAdmin() ? (
      <AdminIndex />
    ) : auth.isUserMaster() ? (
      <UserMasterIndex />
    ) : (
      <ConnectIndex />
    );

  return (
    <BrowserRouter>
      <Suspense fallback={<div />}>
        <Switch>
          <Route path={routes.SUPPORT_CONNECT} exact component={ConnectIndex} />
          <Route
            path={routes.SUPPORT_ASSISTANCE}
            exact
            component={AssistanceIndex}
          />
          <Route path={routes.SUPPORT_INDEX} exact render={getIndex} />
          <Route path={routes.SUPPORT_HISTORY} exact component={HistoryIndex} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

const mapDispatchToProps: (dispatch: Dispatch) => INavigationFunctionsProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): INavigationFunctionsProps => ({
  getAuthInfo: (): void => dispatch(getAuthInfo()),
});

export default connect(null, mapDispatchToProps)(Navigation);
