import { appSlice } from "../reducer/app";

export const {
  setContactsDataResponse,
  setBillingConfigResponse,
  setConfigurationRatesInfoResponse,
  setMainAccordionDiscount,
  setMainAccordionCompany,
  setMainAccordionFinancial,
  setMainAccordionProcess,
  setMainAccordionDeveloper,
  setMainAccordionService,
  setMainAccordionUser,
  setMainAccordionWallet,
  setBalanceResponse,
  setMerchantBillingInfoResponse,
  setMerchantCommission,
  setMerchantCompliance,
  setMerchantDispersionInfoResponse,
  setMerchantSettingsResponse,
  setServiceSubscriptionData,
  setNodeInfoResponse,
  setSmartlinkMerchantData,
  setMerchantCardInformation,
  setMerchantCardInfoSandbox,
  setMerchantProcessors,
  setMerchantDefaultProcessors,
  setServiceConfiguration,
  setMerchantInfoResponse,
  setNotifications,
  setPayoutRulesResponse,
  setRulesInfoResponse,
  setDeferredList,
  setDeferredConfig,
  setWebhookList,
  setVposMerchantData,
  setCredentials,
  setSearchNodes,
  setCompliancePeople,
  setStatusMerchant,
} = appSlice.actions;
