import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
// @ts-ignore
import Normalize from "react-normalize";
import thunk from "redux-thunk";
import { applyMiddleware, compose, createStore, Store } from "redux";
import { siftScienceReducer } from "./store/reducer";
import { Provider } from "react-redux";
import { MonitoringIndex } from "./containers/MonitoringIndex/MonitoringIndex";
import SiftScience from "./containers/SiftScience/SiftScience";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
import { environment } from "./environments/environment";
import { MonitoringRoles } from "./shared/infrastructure/constants/MonitoringConstantIndex";

const composeEnhancers = process.env.REACT_APP_DEV_TOOLS
  ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose;

const store: Store = createStore(
  siftScienceReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const Root = () => {
  return (
    <React.StrictMode>
      <Normalize />
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <ModuleSecurityWrapper
              basePath={`${environment.kushkiUrl}`}
              componentId={`${MonitoringRoles.M_FRAUD_PREVENTION}`}
            >
              <Switch>
                <Route path="/monitoring" exact component={MonitoringIndex} />
                <Route
                  path="/monitoring/siftscience"
                  exact
                  component={SiftScience}
                />
              </Switch>
            </ModuleSecurityWrapper>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
};

export default Root;
