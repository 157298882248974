import { hideAlert } from "../../../store/actions/app_actions";
import { closeSelectedModal } from "../../../store/actions/transactions/transactions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/hooks";
import { appSelector } from "../../../store/selectors/appState/app_state_selector";
import {
  selectIsSelectedModalOpen,
  selectSelectedTransactions,
} from "../../../store/selectors/transactions/transactions";
import { currentTransactionSelector } from "../../../store/selectors/transactions/transactions_selector";
import { IUseVehiscoreState } from "./useVehiscoreState.interfaces";

export const useVehiscoreState = (): IUseVehiscoreState => {
  const dispatch = useAppDispatch();
  const currentTransaction = useAppSelector(currentTransactionSelector);
  const currentApp = useAppSelector(appSelector);
  const selectedTransactions = useAppSelector(selectSelectedTransactions);
  const areThereSelectedTransactions = selectedTransactions.length > 0;
  const isSelectedModalOpen = useAppSelector(selectIsSelectedModalOpen);

  const onCloseAlert = () => {
    dispatch(hideAlert());
  };

  const onCloseSelectedModal = () => {
    dispatch(closeSelectedModal());
  };

  return {
    actions: {
      onCloseAlert,
      onCloseSelectedModal,
    },
    areThereSelectedTransactions,
    currentTransaction,
    isSelectedModalOpen,
    messageAlert: currentApp.messageAlert,
    openRefundModal: currentApp.openRefundModal,
    showAlert: currentApp.showAlert,
    typeAlert: currentApp.typeAlert,
  };
};
