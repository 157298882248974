import React from "react";
import { Box } from "@mui/material";
import { useLoginState } from "./useLoginState/useLoginState";

export const Login = () => {
  useLoginState();

  return (
    <Box
      sx={{ display: "flex", gap: "20px", padding: "20px" }}
      data-testid={"login-component"}
    ></Box>
  );
};
