import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const useModalInvestigationStyles = createNamedStyles({
  title: {
    display: "flex",
    justifyContent: "space-between",
  },
  closeButton: {
    backgroundColor: "transparent",
  },
  textColor: {
    color: "#023365 !important",
  },
  content: {
    flexGrow: 1,
    p: 4,
    display: "flex",
    justifyContent: "flex-start",
    gap: "32px",
    flexDirection: "column",
    overflowY: "scroll",
    maxHeight: "inherit",
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 1,
    height: "80px",
    width: "100%",
    backgroundColor: "#FAFCFD",
    borderBottomLeftRadius: "0.5rem",
    borderBottomRightRadius: "0.5rem",
    px: 4,
  },
  textArea: {
    width: {
      md: ["-webkit-fill-available", "-moz-available"],
      sm: "500px",
    },
    display: "inline-grid",
    height: "320px",
    "& p": {
      marginRight: "36px",
    },
  },
  textAreaInfo: {
    width: {
      md: ["-webkit-fill-available", "-moz-available"],
      sm: "500px",
    },
    display: "inline-grid",
    height: "220px",
  },
  textMargin: {
    color: "#023365",
    marginBottom: "16px",
  },
  primaryButton: {
    border: "1px solid #4498EE",
    color: "#ffffff",
    "&:hover": {
      color: "#ffffff",
    },
    fontSize: "14px !important",
    borderRadius: "12px",
    padding: "8px 30px",
  },
  secondaryButton: {
    color: "#6D7781",
    "&:hover": {
      color: "#6D7781",
    },
    fontSize: "14px !important",
    borderRadius: "12px",
    padding: "8px 30px",
  },
  textSpacing: {
    marginBottom: "32px",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    flex: 1,
  },
  emailContainer: {
    display: "flex",
    gap: "10px",
    alignItems: "start",
  },
});
