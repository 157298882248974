import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import React, { FC } from "react";
import { loadMoreStyles as styles } from "./RefundsLoadMoreButton.styles";
import useRefundsLoadMoreButtonState from "./state/useRefundsLoadMoreButtonState";

const RefundsLoadMoreButton: FC = () => {
  const { handleLoadMoreClick, loading, showButton } =
    useRefundsLoadMoreButtonState();

  return (
    <Box sx={styles.container}>
      {showButton && (
        <LoadingButton
          variant="contained"
          sx={styles.button}
          onClick={handleLoadMoreClick}
          loading={loading}
          disabled={loading}
        >
          Ver más
        </LoadingButton>
      )}
    </Box>
  );
};

export default RefundsLoadMoreButton;
