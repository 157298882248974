import React from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";

export interface ProcessorsRadioButtonsProps {
  processor: { id: string; value: string }[];
  processorNameSelected: string | undefined;
  change?: (_event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ProcessorsRadioButtons = (props: ProcessorsRadioButtonsProps) => {
  return (
    <React.Fragment>
      {props.processor.length > 0 && (
        <FormControl component="fieldset">
          <RadioGroup
            row
            name="timeSupport"
            value={props.processorNameSelected}
            onChange={props.change}
          >
            {props.processor.map((option) => (
              <FormControlLabel
                key={option.id}
                value={option.value}
                control={<Radio color="primary" />}
                label={
                  <Typography variant="body2" color="textPrimary">
                    {option.value}
                  </Typography>
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    </React.Fragment>
  );
};

export default ProcessorsRadioButtons;
