export const THUNK_PREFIXES = {
  ACTIVATE_MERCHANT_NODE: "userForm/activatedMerchantNode",
  CREATE_USER: "userForm/createUser",
  DELETE_USER: "users/deleteUsers",
  DELETE_USER_MASSIVE: "users/deleteUsersMassive",
  DOWNLOAD_MASSIVE_USER: "massiveUserForm/downloadMassiveUser",
  EDIT_USER: "userForm/editUser",
  FIND_USER: "userForm/findUser",
  FIRST_PARENT: "users/firstParent",
  GET_MERCHANTS: "userForm/getMerchantsRequest",
  GET_NODE_INFO: "general/getNodeInfo",
  GET_NODE_INFO_BY_MERCHANTID: "users/getNodeInfoByMerchantId",
  GET_SEARCH_MERCHANT_INFO: "userForm/getSearchMerchantInfo",
  GET_USERS: "users/getUsers",
  GET_USERS_CUSTOMER: "users/getUsersCustomer",
  HIERARCHY_GET_NODE_INFO: "general/hierarchyGetNodeInfo",
  RESTORE_PASSWORD: "users/restorePassword",
  RESTORE_PASSWORD_MASSIVE: "users/restorePasswordMassive",
  SEARCH: "general/searchMerchant",
  SEARCH_MERCHANT_NODE: "users/searchMerchantNode",
  SEARCH_MERCHANT_NODE_INFO: "general/searchMerchantNodeInfo",
  UPDATE_STEP_USER_STATUS: "userForm/updateStepUsersStatus",
  VALIDATE_AVAILABLE_USERNAME: "userForm/validateUsername",
  VALIDATE_CONFIGS: "general/validateConfigs",
  VALIDATE_FILE: "massiveUserForm/validateFile",
};
