import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { WebhookRequest } from "../../../../types/webhook_request_schema";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { WebhookResponse } from "../../../../types/webhook_response_schema";
import { SmartlinkResponse } from "../../../../types/getSmartlinkMerchantResponse";
import { VposMerchantResponse } from "../../../../types/getVposMerchantResponse";
import { GetNodeInfoRequest } from "../../../../types/get_node_info_request";
import { GetNodeInfoResponse } from "../../../../types/get_node_info_response";
import { WebhookCreateRequest } from "../../../../types/webhook_create_request";
import { IHierarchyNodeRequest } from "../../../shared/interfaces/IHierarchyNodeRequest";
import { GetWebhookResponse } from "../../../../types/get_webhook_response";
import { ProcessorPaymentMethod } from "../../../../types/processor_payment_method";

export const createWebhookConnection = createAsyncThunk<
  WebhookResponse,
  WebhookRequest
>("webhook/connection", async (payload) => {
  const response = await axios.post(API_ROUTES.webhook, payload);

  return response.data;
});

export const getProcessors = createAsyncThunk<ProcessorPaymentMethod[], string>(
  "webhook/processors",
  async (payload) => {
    const response = await axios.get(API_ROUTES.GET_PROCESSORS(payload));

    return response.data;
  }
);

export const getAdminMerchant = createAsyncThunk<VposMerchantResponse, string>(
  "webhook/vpos",
  async (payload) => {
    const response = await axios.get(API_ROUTES.GET_ADMIN_MERCHANT(payload));

    return response.data;
  }
);

export const getSmartlinks = createAsyncThunk<SmartlinkResponse, string>(
  "webhook/smartlinks",
  async (payload) => {
    const response = await axios.get(API_ROUTES.GET_SMARTLINKS(payload));

    return response.data;
  }
);

export const getNodeInfo = createAsyncThunk<
  GetNodeInfoResponse,
  GetNodeInfoRequest
>("webhook/getNodeInfo", async (payload) => {
  const response = await axios.post<GetNodeInfoResponse>(
    API_ROUTES.NODE_INFO,
    payload
  );

  return response.data;
});

interface ICreateWebhook {
  payload: WebhookCreateRequest;
  hierarchyPayload: IHierarchyNodeRequest;
}

export const createWebhook = createAsyncThunk<boolean, ICreateWebhook>(
  "webhook/createWebhook",
  async ({ payload, hierarchyPayload }): Promise<boolean> => {
    await axios.put(API_ROUTES.HIERARCHY_NODE_INFO, hierarchyPayload);

    const response = await axios.post(API_ROUTES.CREATE_WEBHOOK, payload);

    return !!response;
  }
);

export interface IPutHierarchyConfig {
  nodeId: string;
  configs: IConfigs[];
}

export interface IConfigs {
  configuration: string;
  value: string;
}

interface IEditWebhook {
  payload: WebhookCreateRequest;
  hierarchyPayload: IHierarchyNodeRequest;
  webhookId: string | null;
}

export const editWebhook = createAsyncThunk<boolean, IEditWebhook>(
  "webhook/editWebhook",
  async ({ payload, webhookId, hierarchyPayload }) => {
    await axios.put(API_ROUTES.HIERARCHY_NODE_INFO, hierarchyPayload);

    const response = await axios.patch(
      API_ROUTES.PATCH_WEBHOOK(webhookId!),
      payload
    );

    return !!response;
  }
);

export interface MerchantIdRequest {
  merchantId: string;
  webhookId: string;
}

export const getWebhook = createAsyncThunk<
  GetWebhookResponse,
  MerchantIdRequest
>(
  "webhook/getWebhook",
  async ({ merchantId, webhookId }: MerchantIdRequest) => {
    const response = await axios.post(API_ROUTES.GET_WEBHOOK(webhookId), {
      merchantId,
    });

    return response.data;
  }
);

export interface IWebhookSignatureRequest {
  publicMerchantId: string;
}
export interface IWebhookSignatureResponse {
  webhookSignature: string;
}

export const getWebhookSignature = createAsyncThunk<
  string,
  IWebhookSignatureRequest
>("webhook/getWebhookSignature", async (payload) => {
  const response_signature = await axios.get<IWebhookSignatureResponse>(
    API_ROUTES.WEBHOOK_SIGNATURE(payload.publicMerchantId)
  );

  return response_signature.data.webhookSignature;
});
