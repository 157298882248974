import { ProcessorsEnum } from "./ProcessorsEnum";
import { IDeferredType } from "../interfaces/IDeferredType";

export const DEFERRED_TYPE: IDeferredType[] = [
  {
    code: "001",
    hasMonthsOfGrace: false,
    processor: ProcessorsEnum.DATAFAST,
    text: "Diferido corriente",
  },
  {
    code: "002",
    hasMonthsOfGrace: true,
    processor: ProcessorsEnum.DATAFAST,
    text: "Diferido con intereses",
  },
  {
    code: "003",
    hasMonthsOfGrace: true,
    processor: ProcessorsEnum.DATAFAST,
    text: "Diferido sin intereses",
  },
  {
    code: "004",
    hasMonthsOfGrace: false,
    processor: ProcessorsEnum.DATAFAST,
    text: "Tipo de Comercio Gasolinera",
  },
  {
    code: "007",
    hasMonthsOfGrace: true,
    processor: ProcessorsEnum.DATAFAST,
    text: "Diferido especial con interés",
  },
  {
    code: "008",
    hasMonthsOfGrace: false,
    processor: ProcessorsEnum.DATAFAST,
    text: "Tarjeta descuento",
  },
  {
    code: "009",
    hasMonthsOfGrace: true,
    processor: ProcessorsEnum.DATAFAST,
    text: "Diferido especial sin interés",
  },
  {
    code: "021",
    hasMonthsOfGrace: true,
    processor: ProcessorsEnum.DATAFAST,
    text: "Diferido Plus Cuotas",
  },
  {
    code: "022",
    hasMonthsOfGrace: true,
    processor: ProcessorsEnum.DATAFAST,
    text: "Diferido Plus",
  },
  {
    code: "01",
    hasMonthsOfGrace: false,
    processor: ProcessorsEnum.CREDIMATIC,
    text: "Diferido Cuota Fija con intereses",
  },
  {
    code: "02",
    hasMonthsOfGrace: false,
    processor: ProcessorsEnum.CREDIMATIC,
    text: "Diferido con meses de gracia con intereses",
  },
  {
    code: "03",
    hasMonthsOfGrace: false,
    processor: ProcessorsEnum.CREDIMATIC,
    text: "Diferido Pago Mes a Mes con intereses",
  },
  {
    code: "04",
    hasMonthsOfGrace: false,
    processor: ProcessorsEnum.CREDIMATIC,
    text: "Diferido Cuota Fija sin intereses",
  },
  {
    code: "05",
    hasMonthsOfGrace: true,
    processor: ProcessorsEnum.CREDIMATIC,
    text: "Diferido con meses de gracia sin intereses",
  },
  {
    code: "06",
    hasMonthsOfGrace: false,
    processor: ProcessorsEnum.CREDIMATIC,
    text: "Diferido Pago Mes a Mes sin intereses",
  },
  {
    code: "07",
    hasMonthsOfGrace: false,
    processor: ProcessorsEnum.CREDIMATIC,
    text: "Diferido especial sin intereses",
  },
  {
    code: "50",
    hasMonthsOfGrace: false,
    processor: ProcessorsEnum.CREDIMATIC,
    text: "Diferido promociones Supermaxi",
  },
];
