/* eslint-disable no-trailing-spaces */
import React from "react";

const Card = () => (
  <svg
    role="img"
    aria-label="generic card icon"
    width="19"
    height="17"
    viewBox="0 0 19 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.228 1H2.5228C1.68178 1 1 1.80555 1 2.79925V13.5948C1 14.5884 1.68178 15.394 2.5228 15.394H16.228C17.069 15.394 17.7508 14.5884 17.7508 13.5948V2.79925C17.7508 1.80555 17.069 
      1 16.228 1Z"
      stroke="#6D7781"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
    />
    <path
      d="M1 6.39771H17.7508"
      stroke="#6D7781"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Card;
