export enum BRANCH_MANUAL_LABELS {
  STATUS = "Estado",
  GROUP = "Grupo",
  COUNTRY = "País de constitución",
  CLIENT_TYPE = "Tipo de cliente",
}

export enum DEFAULT_PATHS {
  HIDDEN = "rowProps.hidden",
  ROW_INDEX = "props.rowIndex",
  CELL_ROW_INDEX = "cells[0].props.rowIndex",
  CELL_ROW_CHECKED = "cells[0].props.isChecked",
  CELL_ROW_EDIT_STATUS = "cells[3].props.value",
  CELL_ROW_NAME = "cells[0].props.line1",
}
export enum TEXTS_BRANCH_MANUAL {
  TRANSACTION_PER_PAGE = "Transacciones por página",
}
export enum LABELS_GROUP_HEADER {
  CENTRALIZE = "Centralizado",
  DESCENTRALIZE = "Descentralizado",
}

export enum LABELS_STATUS_HEADER {
  INCOMPLETE = "Incompleto",
  NO_MODIFIED = "Sin modificar",
  MODIFIED = "Modificado",
}
