import React, { ReactNode } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  createStyles,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AuthenticationRules } from "../../../../types/response_search_rules_by_filters";
import { defaultTo, get } from "lodash";
import { format, utcToZonedTime } from "date-fns-tz";
import { Add } from "@material-ui/icons";
import {
  ColumnsEnum,
  IColumnsTable,
} from "../../../shared/infrastructure/Table/ColumnsTable";
import { StatusEnum } from "../../../shared/infrastructure/enums/StatusEnum";
import { translate } from "../../../shared/infrastructure/Utils";
import { Edit } from "react-feather";
import { AuthenticationElastic } from "../../../../types/remote/authentication_elastic";
import { tzRegion } from "../../../shared/infrastructure/constants/RuleRequestAuthenticationConstants";
import { RuleRequestRoles } from "../../../shared/infrastructure/constants/RuleRequestConstants";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

export interface DashboardHistoricTableRowProps {
  index: number;
  mainTable?: boolean;
  rulesInitialStatus: string;
  rulesState: string;
  historicRulesRequestData: AuthenticationRules;
  renderColumns: IColumnsTable[];
  handleCheckboxChange?: (
    type: StatusEnum,
    value: boolean,
    index: number
  ) => void;
  handlerOpenRow: (
    row: AuthenticationRules | AuthenticationElastic | undefined,
    type: string
  ) => void;
  checkboxActions?: {
    handleCheckboxChange: (index: number) => void;
  };
  handleOpenAnalystAssignmentModal?: (
    historicRulesRequestData?: AuthenticationRules[]
  ) => void;
  checkBoxSelects?: {
    validateExistTrxs: (row: AuthenticationRules | undefined) => boolean;
    handleSelectTrx: (
      _event: unknown,
      row: AuthenticationRules | undefined
    ) => void;
  };
  handleAddRule?: (row: AuthenticationRules) => void;
}

const styles = makeStyles((theme: Theme) =>
  createStyles({
    chipStyleSize: {
      backgroundColor: "#F7FAFC",
      borderRadius: "5px",
      textAlign: "center",
      padding: "3px 15px",
    },
    chipStyleSizeRequest: {
      backgroundColor: "#F7FAFC",
      borderRadius: "5px",
      textAlign: "center",
      padding: "3px 15px",
      width: "fit-content",
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "center",
      alignItems: "center",
    },
    buttonStyleIcon: {
      padding: "4px 20px",
    },
    chipStyleAuthenticator: {
      backgroundColor: "#EEF6FF",
      borderRadius: "5px",
      textAlign: "center",
      width: "fit-content",
      padding: "3px 15px",
    },
    block: {
      width: "100px",
      float: "left",
    },
    chipInitialStateStyle: {
      backgroundColor: (props: DashboardHistoricTableRowProps) => {
        switch (props.rulesInitialStatus) {
          case StatusEnum.APPROVE:
          case StatusEnum.SENT:
            return `${theme.palette.success.light}`;
          case StatusEnum.OMIT:
          case StatusEnum.IN_PROCESS:
            return `${theme.palette.info.light}`;
          case StatusEnum.PENDING:
            return `${theme.palette.warning.light}`;
          case StatusEnum.REJECT:
            return `${theme.palette.error.light}`;
          default:
            return "inherit";
        }
      },
      color: (props: DashboardHistoricTableRowProps) => {
        switch (props.rulesInitialStatus) {
          case StatusEnum.APPROVE:
          case StatusEnum.SENT:
            return `${theme.palette.secondary.dark}`;
          case StatusEnum.OMIT:
          case StatusEnum.IN_PROCESS:
            return `${theme.palette.info.dark}`;
          case StatusEnum.PENDING:
            return `${theme.palette.warning.dark}`;
          case StatusEnum.REJECT:
            return `${theme.palette.error.dark}`;
          default:
            return "inherit";
        }
      },
    },
    chipStateStyle: {
      borderRadius: "6px !important",
      backgroundColor: (props: DashboardHistoricTableRowProps) => {
        switch (props.rulesState) {
          case StatusEnum.APPROVE:
          case StatusEnum.SENT:
          case StatusEnum.COMPLETED:
            return `${theme.palette.success.light}`;
          case StatusEnum.OMIT && StatusEnum.IN_PROCESS:
            return `${theme.palette.info.light}`;
          case StatusEnum.PENDING:
            return `${"rgb(240, 244, 249)"}`;
          case StatusEnum.REJECT:
            return `${theme.palette.error.light}`;
          default:
            return "inherit";
        }
      },
      color: (props: DashboardHistoricTableRowProps) => {
        switch (props.rulesState) {
          case StatusEnum.APPROVE:
          case StatusEnum.COMPLETED:
          case StatusEnum.SENT:
            return `${theme.palette.secondary.dark}`;
          case StatusEnum.OMIT:
          case StatusEnum.IN_PROCESS:
            return `${theme.palette.info.dark}`;
          case StatusEnum.PENDING:
            return `${theme.palette.primary}`;
          case StatusEnum.REJECT:
            return `${theme.palette.error.dark}`;
          default:
            return "inherit";
        }
      },
    },
    tooltipWidth: {
      maxWidth: 500,
    },
    chipAuthenticateColor: {
      backgroundColor: theme.palette.info.light,
      color: theme.palette.info.dark,
    },
  })
);

const FORMAT_DATE: string = "dd-MM-yyyy";
const HOURS_DATE: string = "HH:mm:ss";
export const buildDate = (trx: AuthenticationRules, field: string) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        {format(utcToZonedTime(get(trx, field, 1), tzRegion), FORMAT_DATE)}
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"body2"} style={{ color: "#46525C" }}>
          {format(utcToZonedTime(get(trx, field, 1), tzRegion), HOURS_DATE)}
        </Typography>
      </Grid>
    </Grid>
  );
};
export const buildText = (label: string | ReactNode) => {
  return (
    <Typography variant={"body2"} style={{ fontWeight: "lighter" }}>
      {label}
    </Typography>
  );
};
export const DashboardTableRow: React.FC<DashboardHistoricTableRowProps> = (
  props: DashboardHistoricTableRowProps
) => {
  const classes = styles(props);

  const propsTableCell = () => ({
    onClick: (e: any) => {
      if (props.handlerOpenRow)
        props.handlerOpenRow(
          props.historicRulesRequestData!,
          get(e.target as HTMLInputElement, "type", "")
        );
      e.preventDefault();
      e.stopPropagation();
    },
    style: { cursor: "pointer" },
  });

  return (
    <TableRow>
      {props.renderColumns
        .filter((column) => column.view)
        .map((column: IColumnsTable) => {
          switch (column.id) {
            case "checkField":
              return (
                <TableCell key={column.id}>
                  <Checkbox
                    id={column.id}
                    checked={get(
                      props.historicRulesRequestData,
                      "isDownload",
                      false
                    )}
                    onClick={() => {
                      props.checkboxActions!.handleCheckboxChange!(props.index);
                    }}
                    inputProps={{ "aria-label": "Aprobar" }}
                    color={"primary"}
                  />
                </TableCell>
              );
            case ColumnsEnum.created:
              return (
                <TableCell key={column.id}>
                  {buildText(
                    buildDate(
                      props.historicRulesRequestData!,
                      ColumnsEnum.created
                    )
                  )}
                </TableCell>
              );
            case ColumnsEnum.public_merchant_id:
              return (
                <TableCell key={column.id} {...propsTableCell()}>
                  {buildText(
                    get(
                      props.historicRulesRequestData,
                      "public_merchant_id",
                      ""
                    )
                  )}
                </TableCell>
              );
            case ColumnsEnum.status:
              return (
                <TableCell key={column.id} {...propsTableCell()}>
                  <Chip
                    className={classes.chipStateStyle}
                    label={translate(
                      get(props.historicRulesRequestData, "status", "")
                    )}
                    size="small"
                  />
                </TableCell>
              );
            case StatusEnum.APPROVE:
              return (
                <TableCell key={column.id}>
                  <Checkbox
                    id={column.id}
                    checked={
                      get(props.historicRulesRequestData, "status", "") ===
                      StatusEnum.APPROVE
                    }
                    onChange={(e) =>
                      props.handleCheckboxChange!(
                        StatusEnum.APPROVE,
                        e.target.checked,
                        props.index
                      )
                    }
                    inputProps={{ "aria-label": "Aprobar" }}
                    color={"primary"}
                  />
                </TableCell>
              );
            case "selectable_column":
              return (
                <TableCell key={column.id}>
                  <Checkbox
                    id={column.id}
                    checked={
                      props.mainTable
                        ? props.checkBoxSelects!.validateExistTrxs(
                            props.historicRulesRequestData
                          )
                        : true
                    }
                    onChange={(_event: any) => {
                      props.checkBoxSelects!.handleSelectTrx(
                        _event,
                        props.historicRulesRequestData
                      );
                    }}
                    inputProps={{ "aria-label": "selectable_column" }}
                    color={"primary"}
                    onClick={(e) => e.stopPropagation()}
                  />
                </TableCell>
              );
            case ColumnsEnum.rule_alias:
              return (
                <TableCell key={column.id}>
                  {buildText(
                    get(
                      props.historicRulesRequestData,
                      "auth_rules[0].name",
                      ""
                    )
                  )}
                </TableCell>
              );
            case StatusEnum.REJECT:
              return (
                <TableCell key={column.id}>
                  <Checkbox
                    id={column.id}
                    checked={
                      get(props.historicRulesRequestData, "status", "") ===
                      StatusEnum.REJECT
                    }
                    onChange={(e) =>
                      props.handleCheckboxChange!(
                        StatusEnum.REJECT,
                        e.target.checked,
                        props.index
                      )
                    }
                    inputProps={{ "aria-label": "Rechazar" }}
                    color={"primary"}
                  />
                </TableCell>
              );
            case ColumnsEnum.merchant_size:
              return (
                <TableCell key={column.id} {...propsTableCell()}>
                  <Box className={classes.chipStyleSize}>
                    {buildText(
                      translate(
                        get(props.historicRulesRequestData, "merchant_size", "")
                      )
                    )}
                  </Box>
                </TableCell>
              );
            case ColumnsEnum.risk_analyst:
              return (
                <TableCell key={column.id}>
                  {buildText(
                    get(props.historicRulesRequestData, "user_alias", "")
                  )}
                </TableCell>
              );
            case ColumnsEnum.applicant:
            case ColumnsEnum.user_alias:
              return (
                <TableCell key={column.id}>
                  {buildText(
                    get(props.historicRulesRequestData, "user_name_request", "")
                  )}
                </TableCell>
              );
            case ColumnsEnum.user_nameRequest:
              return (
                <TableCell key={column.id}>
                  <Box className={classes.chipStyleSizeRequest}>
                    {buildText(
                      get(props.historicRulesRequestData, "user_name", "")
                    )}
                    {props.mainTable ? (
                      <ComponentSecurityWrapper
                        componentId={`${RuleRequestRoles.M_FRAUD_PREVENTION}.${RuleRequestRoles.RULE_REQUEST_AUTH_RULES_EDIT}`}
                      >
                        <IconButton
                          id={"iconRow"}
                          className={classes.buttonStyleIcon}
                          color="primary"
                          onClick={() =>
                            props.handleOpenAnalystAssignmentModal!([
                              props.historicRulesRequestData,
                            ])
                          }
                        >
                          <Edit />
                        </IconButton>
                      </ComponentSecurityWrapper>
                    ) : (
                      ""
                    )}
                  </Box>
                </TableCell>
              );
            case ColumnsEnum.kind:
              return (
                <TableCell key={column.id} {...propsTableCell()}>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Box className={classes.chipStyleAuthenticator}>
                      <Typography color={"primary"} variant={"body2"}>
                        {buildText(
                          defaultTo(
                            get(props.historicRulesRequestData, column.id, ""),
                            ""
                          ).toUpperCase()
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
              );
            case ColumnsEnum.button:
              return get(props.historicRulesRequestData, "status", "") !==
                "rejected" &&
                get(props.historicRulesRequestData, "status", "") !==
                  "in_process" ? (
                <TableCell key={column.id}>
                  <Tooltip
                    title={
                      <p style={{ fontSize: 14 }}>
                        No se puede editar la configuración cuando se ha enviado
                        a revisión
                      </p>
                    }
                    placement="top"
                    disableHoverListener={
                      get(props.historicRulesRequestData, "status", "") !==
                      "sent"
                    }
                    classes={{ tooltip: classes.tooltipWidth }}
                  >
                    <span>
                      <Button
                        id={"applyBtn"}
                        variant="outlined"
                        fullWidth
                        disabled={
                          get(props.historicRulesRequestData, "status", "") ===
                          "sent"
                        }
                        startIcon={<Add />}
                        onClick={() =>
                          props.handleAddRule!(props.historicRulesRequestData)
                        }
                      >
                        <Typography variant={"body1"}>Agregar</Typography>
                      </Button>
                    </span>
                  </Tooltip>
                </TableCell>
              ) : (
                <TableCell key={column.id}>
                  <Button
                    id={"applyBtn"}
                    variant="outlined"
                    fullWidth
                    startIcon={<Edit />}
                    onClick={() =>
                      props.handleAddRule!(props.historicRulesRequestData)
                    }
                  >
                    <Typography variant={"body1"}>Editar</Typography>
                  </Button>
                </TableCell>
              );
            case ColumnsEnum.updated:
              return (
                <TableCell key={column.id} {...propsTableCell()}>
                  {buildText(
                    buildDate(props.historicRulesRequestData!, "updated")
                  )}
                </TableCell>
              );
            default:
              return (
                <TableCell
                  key={column.id}
                  align={"center"}
                  {...propsTableCell()}
                >
                  {buildText(
                    get(props.historicRulesRequestData, column.id, "")
                  )}
                </TableCell>
              );
          }
        })}
    </TableRow>
  );
};
