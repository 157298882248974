import React from "react";
import { Box, createStyles, Paper, Theme, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IFilterResponse } from "@kushki/frontend-molecules/filter-container";
import FilterComponent from "../Filters/FilterComponent/FilterComponent";
import { IRangeAmount } from "@kushki/frontend-molecules/filter-range-amount";
import ColumnsFilterButton from "@kushki/frontend-molecules/columns-filter-button";
import { IColumns } from "@kushki/frontend-molecules/columns-filter-button/components/SelectableColumns/SelectableColumns";
import { IOptionFilter } from "../../containers/PayoutsTransaction/state/usePayoutsTransactionState";
import DateRangePicker from "../Filters/DateRangePicker/DateRangePicker";
import AutocompleteTextField from "../Filters/AutocompleteTextField/AutocompleteTextField";
import { MerchantInfo } from "../../../types/merchant-info";
import { MerchantsData } from "../../store/actionCreators";
import { payoutsTransactionColumns } from "../../shared/infrastructure/constants/PayoutsTransactionsConstants";
import { DownloadButton } from "../commons/DownloadButton/DownloadButton";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/reducer";
import { SecurityWrapperEnum } from "../../shared/infrastructure/SecurityWrapperEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperTable: {
      backgroundColor: "#F0F4F9",
    },
    paperCard: {
      backgroundColor: "#F7FAFC",
    },
    paper: {
      padding: "0px 0px 0px 0px",
      backgroundColor: "#F7FAFC",
      borderRadius: 0,
      boxShadow: "0 0px 0px rgba(0,0,0,0), 0 0px 0px rgba(0,0,0,0)",
    },
    padding: {
      padding: "12px 12px 12px 12px",
      [theme.breakpoints.down("xs")]: {
        padding: "12px 0px",
      },
    },
    column: {
      paddingLeft: "8px",
    },
    date: {
      paddingLeft: "4px",
    },
    root: {
      "& .MuiFormControl-root": {
        height: "auto",
      },
      "& > * + *": {
        marginTop: theme.spacing(0),
      },
      "& .MuiInputBase-root": {
        height: "auto",
        width: "auto",
        backgroundColor: "#FFFFFF",
      },
    },
    searchComponent: {
      "& .MuiInputBase-input": {
        textAlign: "left !important",
      },
    },
  })
);

export interface PayoutsListBoxProps {
  isAdmin?: boolean;
  filtersKind: IOptionFilter[];
  merchantList: MerchantsData;
  filters: {
    rangeFilter: IRangeAmount;
    countFilter: number;
    multipleMerchant: { name: string; id?: string }[];
    selectedDateRange: any;
    actions: {
      handleApplyFilterAmount: (response: IFilterResponse) => void;
      handleClearFilters: () => void;
      handleChangeDateRange: (date: any) => void;
      handleChangeMerchants: (
        _event: unknown,
        value: string,
        reason: string
      ) => void;
      handleChangeMerchant: (
        _event: object,
        value: { _source: MerchantInfo }[],
        _reason: string
      ) => void;
    };
  };
  selectedColumns: IColumns[];
  handleSelectColumns(columns: IColumns[]): void;
  handleDownloadTransactions(format: string): void;
}

export const PayoutsListBox: React.FC<PayoutsListBoxProps> = (
  props: PayoutsListBoxProps
) => {
  const classes = useStyles();
  const loadingDownload: boolean | undefined = useSelector(
    (state: IAppState) => state.loadingDownload
  );
  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Toolbar disableGutters className={classes.padding}>
          <Box display="flex" flexWrap="wrap" flexGrow={1} alignItems="center">
            <Box p={1} pb={1.5}>
              {props.isAdmin && (
                <AutocompleteTextField
                  merchantsList={props.merchantList}
                  handleChangeMerchant={
                    props.filters.actions.handleChangeMerchant
                  }
                  handleChangeMerchants={
                    props.filters.actions.handleChangeMerchants
                  }
                />
              )}
            </Box>
            <Box p={1}>
              <FilterComponent
                rangeFilter={props.filters.rangeFilter}
                onApplyFilter={props.filters.actions.handleApplyFilterAmount}
                onClearFilter={props.filters.actions.handleClearFilters}
                filterCount={props.filters.countFilter}
                filtersKind={props.filtersKind}
              />
            </Box>
            <Box p={1}>
              <DateRangePicker
                value={props.filters.selectedDateRange}
                disableFuture={true}
                handleDate={props.filters.actions.handleChangeDateRange}
              />
            </Box>
            <Box p={1} flexGrow={1}>
              {props.isAdmin && (
                <ColumnsFilterButton
                  columns={payoutsTransactionColumns}
                  selected={props.selectedColumns}
                  columnsSelectedCallback={props.handleSelectColumns}
                  columnsNumber={4}
                />
              )}
            </Box>
            <Box p={1} pb={1.5}>
              <ComponentSecurityWrapper
                componentId={SecurityWrapperEnum.PAYMENT_DOWNLOAD}
              >
                <Box>
                  <DownloadButton
                    handleDownload={props.handleDownloadTransactions}
                    loadingDownload={loadingDownload}
                  />
                </Box>
              </ComponentSecurityWrapper>
            </Box>
          </Box>
        </Toolbar>
      </Paper>
    </React.Fragment>
  );
};
