import { FieldsCustomerEnum } from "./FieldsCustomerEnum";

export enum ErrorMessageCustomerForm {
  COMMERCIAL_NAME = "Por favor, ingresa el nombre",
  COMMERCIAL_NAME_LENGTH = "El máximo permitido de caracteres es 50",
  CLIENT_TYPE = "Por favor, selecciona el Tipo de Cliente",
  OPERATION_COUNTRY = "Por favor, selecciona el País de Operación",
  CONSTITUTION_COUNTRY = "Por favor, selecciona el País de Constitución",
  ERROR_NAME = "Este nombre ya está asociado. Por favor, ingresa otro",
}

export const ERROR_MESSAGE_REQUIRED = {
  [FieldsCustomerEnum.commercialName]: [
    ErrorMessageCustomerForm.COMMERCIAL_NAME,
  ],
  [FieldsCustomerEnum.clientType]: [ErrorMessageCustomerForm.CLIENT_TYPE],
  [FieldsCustomerEnum.operationCountry]: [
    ErrorMessageCustomerForm.OPERATION_COUNTRY,
  ],
  [FieldsCustomerEnum.constitutionCountry]: [
    ErrorMessageCustomerForm.CONSTITUTION_COUNTRY,
  ],
};
