import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const ModalStartChargebackStyles = createNamedStyles({
  checkbokContainer: {
    display: "flex",
    flexDirection: "column",
    ml: 3,
  },
  gridDivider: {
    paddingTop: 1,
    paddingBottom: 1.5,
  },
  parentText: {
    "& .MuiTypography-root": {
      fontSize: "20px !important",
      fontWeight: 500,
      lineHeight: "28px !important",
    },
  },
  divider: {
    borderBottom: "2px dashed !important",
    backgroundColor: "white",
    border: "0px",
    color: "#E6EAEF",
  },
  modalHeader: {
    paddingBottom: "10px",
  },
});
