import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { IExportMerchants } from "../../../containers/MerchantMigrationIndex/MerchantMigrationIndex.interfaces";
import { prefixes } from "../../../shared/constants/prefixes";

const exportMerchants = createAsyncThunk<object, IExportMerchants>(
  prefixes.MERCHANT_MIGRATION,
  async (data: IExportMerchants) => {
    const response = await axios.post<object>(
      `${API_ROUTES.EXPORT_MERCHANTS_MIGRATION}?operation=${data.operation}`,
      { userMail: data.userMail, userName: data.userName }
    );

    return response.data;
  }
);

export { exportMerchants };
