/* istanbul ignore file */

import { IOptionSelection } from "./RulesConstants";
import { find, get } from "lodash";

export enum WhitelistRulesTitleSection {
  CREATE_WHITELIST_RULES = "Agregar tarjeta en Lista Blanca",
}

export enum WhitelistRulesSubtitleSection {
  WHITELIST_HEADER = "Lista blanca",
  WHITELIST_SUBTITLE = "Agrega en cada línea los números de tarjetas enmascaradas y/o kushki ID a las cuales no aplicarán las reglas de seguridad establecidas para el comercio.",
  MERCHANT_HEADER = "Comercio",
  MASKEDCARDS_LABEL = "Tarjeta enmascarada",
  KUSHKI_ID_LABEL = "Kushki ID",
  WHITELIST_CONDITION_HEADER = "Condiciones",
  WHITELIST_MULTIPLE_VALUES_LABEL = "Puedes ingresar más de un número seguido de una “,”",
}

export enum WhitelistRulesTitleButton {
  RETURN = "Regresar",
  SAVE = "Guardar",
  ADD = "Agregar",
}

export const WHITELIST_CONDITION_ELEMENTS: IOptionSelection[] = [
  {
    name: "Tarjeta enmascarada",
    value: "maskedCards",
  },
  {
    name: "Kushki ID",
    value: "cardIDs",
  },
];

export const getNames = (list: IOptionSelection[]): string[] => {
  let values: string[] = [];

  list.forEach((value) => values.push(value.name));

  return values;
};

export const getValues = (list: IOptionSelection[]): string[] => {
  let values: string[] = [];

  list.forEach((value) => values.push(value.value));

  return values;
};

export const findName = (
  list: IOptionSelection[],
  value: string,
  compare: "name" | "value",
  path: "name" | "value"
): string => {
  return get(
    find(list, (item) => {
      return value === get(item, compare);
    }),
    path,
    ""
  );
};
