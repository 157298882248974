export interface IMultipleFormValues {
  name: string;
  value: string;
  default?: boolean;
  defaultShareholderDocType?: string;
}

export enum PersonTypeEnum {
  FISICA = "01",
  MORAL = "02",
}
export enum MerchantTypeEnum {
  NATURAL = "01",
  JURIDICO = "02",
}

export const documentType: IMultipleFormValues[] = [
  {
    defaultShareholderDocType: "0",
    name: "Documento de Identidad",
    value: "0",
  },
  { defaultShareholderDocType: "1", name: "Pasaporte", value: "1" },
];

export enum DocumentTypeEnum {
  DOCUMENTO_IDENTIDAD = "0",
  PASAPORTE = "1",
}
