import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Upload, X } from "react-feather";
import { FileChooserConstants } from "../../../shared/infrastructure/constants/FileChooserConstants";
import { KshFile } from "../../../containers/ConfigByFileIndex/state/useConfigByFileIndexState.interfaces";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useState } from "react";

export interface IFileChooser {
  fileName: string | undefined;
  onChange(file: KshFile): void;
  onDelete(): void;
  disableUpload(disable: boolean): void;
}

export const fileChooserStyles = makeStyles({
  spaceGrid: {
    marginBottom: "18px",
    marginTop: "3px",
  },
});

export const FileChooser: React.FC<IFileChooser> = (props: IFileChooser) => {
  const classes = fileChooserStyles();
  const inputEL = React.createRef<HTMLInputElement>();
  const [isUploading, setUploading] = useState<Boolean>(false);

  const handleChooserFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();

    if (!event.target.files || event.target.files.length === 0) return;
    setUploading(true);
    const file: File = event.target.files[0];

    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (): void => {
      const base64_file: string | undefined | null = reader.result
        ?.toString()
        .split(",")[1];

      const ksh_file: KshFile = {
        base64: base64_file!,
        name: file.name,
      };

      props.onChange(ksh_file);
      setTimeout(() => {
        setUploading(false);
        props.disableUpload(false);
      }, 500);
    };
  };

  const handleDeleteFile = () => {
    if (inputEL && inputEL.current) inputEL.current.value = "";
    props.onDelete();
  };

  return (
    <Grid container xs={12}>
      <Grid item xs={5}>
        <label>
          <input
            id="fileChooser"
            color="primary"
            accept={".csv"}
            type="file"
            onChange={handleChooserFile}
            style={{ display: "none" }}
            ref={inputEL}
          />
          <Button
            variant="outlined"
            component="span"
            size={"medium"}
            className={classes.spaceGrid}
            startIcon={<Upload size={20} />}
          >
            {FileChooserConstants.SELECT_FILE_BUTTON}
          </Button>
        </label>
      </Grid>

      <Grid item xs={5}>
        {!isUploading && props.fileName && (
          <Grid container xs={12}>
            <Grid item>
              <Typography noWrap variant={"body1"} color="primary">
                {props.fileName}
                <IconButton onClick={handleDeleteFile} color="primary">
                  <X size={20} />
                </IconButton>
              </Typography>
            </Grid>
          </Grid>
        )}
        {isUploading && (
          <Grid container>
            <Grid item xs={3}>
              <CircularProgress
                color="primary"
                size={30}
                thickness={4}
                style={{ fontWeight: 800 }}
              />
            </Grid>

            <Typography
              noWrap
              variant={"body1"}
              color="primary"
              style={{ marginTop: 3 }}
            >
              {FileChooserConstants.CIRCULAR_PROGRESS_TEXT}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
