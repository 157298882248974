export enum ActionTypes {
  GET_ALL_PROCESSORS = "[CONFIGURATION] GET_ALL_PROCESSORS",
  SET_NOTIFICATION = "[CONFIGURATION] SET_NOTIFICATION",
  SET_PROCESSORS = "[CONFIGURATION] SET_PROCESSORS",
  SET_LOADING = "[CONFIGURATION] SET_LOADING",
  SET_LOADING_DIALOG = "[CONFIGURATION] SET_LOADING_DIALOG",
  SET_REFRESH_PROCESSOR = "[CONFIGURATION] SET_REFRESH_PROCESSOR",
  SET_CLOSE_DIALOG = "[CONFIGURATION] SET_CLOSE_DIALOG",
  GET_DEFAULT_PROCESSOR = "[CONFIGURATION] GET_DEFAULT_PROCESSOR",
  GET_DEFAULT_PAYOUTS_TRANSFER_PROCESSOR = "[CONFIGURATION] GET_DEFAULT_PAYOUTS_TRANSFER_PROCESSOR",
  SET_MERCHANT = "[CONFIGURATION] SET_MERCHANT",
  SET_TOTAL_PROCESSORS = "[CONFIGURATION] SET_TOTAL_PROCESSORS",
  SET_APPCONFIG_PAYMENT_METHODS = "[CONFIGURATION] SET_APPCONFIG_PAYMENT_METHODS",
}
