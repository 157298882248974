import { DateRange } from "@material-ui/pickers/DateRangePicker/RangeTypes";
import { FilterWalletDashboardRequest } from "../../../../types/filter_wallet_dashboard_request";
import { IAppState } from "../../../store/reducer";
import {
  getTransactions,
  setFilterWalletDashboardRequest,
} from "../../../store/actionCreators";
import { format, set, startOfMonth } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { WalletDashboardConstants } from "../../../shared/infrastructure/constants/WalletDashboardConstants";

export interface IUseDateRangePickerState {
  handleDate: (dateRange: DateRange) => void;
  value: DateRange;
}

export const useDateRangePickerState = (): IUseDateRangePickerState => {
  const dispatch = useDispatch();
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>([
    set(startOfMonth(new Date()), {
      hours: 0,
      minutes: 0,
      seconds: 0,
    }),
    set(new Date(), { hours: 23, minutes: 59, seconds: 59 }),
  ]);
  const filterWalletDashboardRequest: FilterWalletDashboardRequest | undefined =
    useSelector((state: IAppState) => state.filterWalletDashboardRequest);

  const handleChangeDateRange = (date: any): void => {
    setSelectedDateRange(date);

    const [fromDate, toDate] = date;
    const from: string = format(fromDate, WalletDashboardConstants.DATE_FORMAT);
    const to: string = format(toDate, WalletDashboardConstants.DATE_FORMAT);
    const filterRequest: FilterWalletDashboardRequest = {
      ...filterWalletDashboardRequest!,
      from,
      to,
    };
    const publicMerchantId: string =
      new URLSearchParams(location.search).get("publicMerchantId") || "";

    dispatch(setFilterWalletDashboardRequest(filterRequest));
    dispatch(getTransactions(filterRequest, publicMerchantId));
  };

  return {
    value: selectedDateRange,
    handleDate: handleChangeDateRange,
  };
};
