export enum LabelsEnum {
  CHARGEBACKS = "Contracargos",
  EMPTY_DATA_TITLE = "No se encontró ninguna coincidencia",
  EMPTY_DATA_BODY = "Modifica la búsqueda para encontrar nuevos resultados",
  TITLE_ALERT = "Las Transacciones mostradas actualmente en la tabla corresponden a los siguientes Branches. Es necesario remover el filtro de branch para poder visualizar todos los branches.",
}

export enum SubtitleSection {
  CHARGEBACK_DETAILS = "Detalle de contracargo",
  BACKUP_CHARGEBACK = "Documentación de respaldo",
  DATA_TRANSACTION = "Datos de transacción",
  REFERENCE_TRANSACTION = "Referencia de la transacción original:",
}

export enum StepEnum {
  BUSINESS_LETTER = " Paso 1: Carta del establecimiento",
  ADDITIONAL_FILES = "Paso 2: Documentos adicionales",
}

export enum LabelField {
  UPLOAD_FILE = "Elegir archivo",
  CHARGEBACK_NUMBER = "No. de contracargo:",
  CHARGEBACK_REASON = "Motivo del contracargo:",
  START_DATE = "Fecha de inicio:",
  PAY_METHOD = "Medio de pago:",
  CARD = "Tarjeta:",
  APROVAL_NUMBER = "No. de Aprobación:",
  SUBTOTAL = "Subtotal:",
  TAXES = "Impuestos:",
  TOTAL = "Total:",
  SNACKBAR_INFORMATION = "Recuerda que la documentación soporte que se carga para realizar la defensa del contracargo no debe contener datos sensibles, tales como tarjeta completa (16 dígitos), cvv, vigencia, etc. Por norma PCI no podemos abrir, ni gestionar la documentación que contenga información sensible, por lo cual en esos casos no se podrá continuar el flujo de defensa de la controversia.",
  RESPONSE_DATE = "Fecha de respuesta",
  MAX_RESPONSE_DATE = "Enviar documentación antes del:",
  BACK_TO = "Regresar",
  SEND_FILES = "Enviar archivos",
  UPLOAD_DATE = "Fecha de carga:",
  UPLOAD_USER = "Usuario de carga:",
}

export enum ChargebackMessages {
  EMPTY_FILES = "No se ha seleccionado ningún documento",
}

export enum ChargebackAdvices {
  BACKUP_CHARGEBACK = "La documentación de respaldo es necesaria para la verificación de la transacción. Este paso es indispensable para emitir un resolución. Te indicamos a continuación, la documentación necesaria que debes adjuntar para enviar una respuesta del contracargo.",
  BUSINESS_LETTER = "Carta con detalles de los datos del comprador (nombre, identificación, correo electrónico, teléfonos, etc) y explicación detallada del proceso de venta del producto o servicio.",
  CHOOSE_FILE = "Haz clic en “Elegir archivo” para buscar el documento en tu dispositivo",
}

export const ADDITIONAL_FILES_ADVICE_LIST: string[] = [
  "Los comprobantes de venta / factura y documentos soporten de las operaciones relacionadas o registros detallados de la(s) transacción(es).",
  "Copia de la Identificación de la persona que recibió el producto / servicio debiendo ser el titular del Instrumento de Pago.",
  "Track de envío que detalle la dirección de entrega del producto / servicio.",
  "Orden de entrega del producto o servicio firmada por el titular del Instrumento de Pago con firma conforme.",
  "Si su establecimiento presta servicios de hospedaje, viajes o entretenimiento, debes remitir copia del registro de los productos y/o servicios vendidos, la orden de trabajo, el folio de alojamiento, el registro hotelero, el tiquete o documento de transporte, según corresponda.",
];

export enum InformativeText {
  SNACKBAR_INFORMATION = "Recuerda que la documentación soporte que se carga para realizar la defensa del contracargo no debe contener datos sensibles, tales como tarjeta completa (16 dígitos), cvv, vigencia, etc. Por norma PCI no podemos abrir, ni gestionar la documentación que contenga información sensible, por lo cual en esos casos no se podrá continuar el flujo de defensa de la controversia.",
  WITHOUT_DOCS = "No existen documentos",
  WITHOUT_LETTERS = "No existe carta de establecimiento",
  EXPIRED_DOCS = "La documentación no fue enviada dentro del plazo de tiempo establecido. Este contracargo se ha resuelto a favor del tarjetahabiente de forma automática.",
}

export enum ModalLoaderText {
  DESCRIPTION_TEXT = "Esto puede tardar un poco. Espera un momento por favor",
  TITLE_TEXT = "Estamos cambiando el estado",
}
