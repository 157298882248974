import axios from "../../../shared/axios-util";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { MerchantBillingInfo } from "../../../../types/merchant_billing_info";
import { GetNodeInfoResponse } from "../../../../types/get_node_info_response";
import { isEmpty } from "lodash";
import { databaseRef } from "../../../shared/firebase";
import { ITransactionFile } from "../../../../types/transaction_file";
import { SearchChargebackRequest } from "../../../../types/search_chargeback_request";
import { EntityNameEnum } from "../../../shared/enums/EntityNameEnum";
import { IMerchantsResponse } from "../../interfaces/AppState.interfaces";

export const getNodeInfo = createAsyncThunk<
  GetNodeInfoResponse,
  { publicMerchantId: string }
>("app/getNodeInfo", async ({ publicMerchantId }) => {
  const response = await axios.post<GetNodeInfoResponse>(API_ROUTES.NODE_INFO, {
    configIds: "cn001",
    publicMerchantId,
  });

  return response.data;
});

export const getMerchantsRequest = createAsyncThunk<
  IMerchantsResponse,
  {
    name?: string;
    merchantId?: string;
    entityName?: string;
  }
>("app/getMerchantsRequest", async ({ name, entityName }) => {
  let entity_aux: string = entityName!;

  const merchantId =
    name && name.length == 20 && !isNaN(parseInt(name)) ? [name] : [];

  name = merchantId.length == 0 ? name : "";

  if (entityName === EntityNameEnum.MERCHANT) entity_aux = EntityNameEnum.NA;

  const response = await axios.post<IMerchantsResponse>(
    API_ROUTES.MERCHANTS_CORE,
    {
      filter: {
        entityName: isEmpty(entity_aux)
          ? [EntityNameEnum.NA, EntityNameEnum.CUSTOMER, EntityNameEnum.BRANCH]
          : entity_aux.split("|"),
        merchantId,
        name,
      },
      limit: 15,
      offset: 1,
    }
  );

  return response.data;
});

export const getMerchantInfo = createAsyncThunk<
  MerchantBillingInfo,
  { publicMerchantId: string }
>("app/getMerchantInfo", async ({ publicMerchantId }) => {
  const response = await axios.post<MerchantBillingInfo>(
    API_ROUTES.MERCHANT_INFO,
    { publicMerchantId }
  );

  return response.data;
});

const transformToNewAuthorizerCrud = (
  body: SearchChargebackRequest,
  format?: string
) => {
  let headers = {
    Authorization: localStorage.getItem("jwt"),
    "Content-type": "application/json",
  };

  if (body.headers) {
    headers["CustomerPath"] = body.headers?.CustomerPath;
    headers["BranchesId"] = body.headers?.Branchesid;
  }

  delete body.headers;

  return {
    body: {
      ...body,
      format,
      limit: 10,
      text: body.text,
      userType: undefined,
    },
    headers,
  };
};

export const getFirebaseID = createAsyncThunk<
  { id: string },
  {
    body: SearchChargebackRequest;
    format: string;
  }
>("app/getFirebaseID", async ({ body, format }) => {
  let route: string = API_ROUTES.DOWNLOAD_ANALYTICS;

  if (isEmpty(body.publicMerchantId)) delete body.publicMerchantId;

  delete body.userType;

  const new_request = transformToNewAuthorizerCrud(body, format);

  delete new_request.body.isAdmin;

  const response = await axios.post<{ id: string }>(
    route,
    { ...new_request.body },
    { headers: new_request.headers }
  );

  return response.data;
});

export const downloadTransactions = createAsyncThunk<
  ITransactionFile,
  { payload: string }
>("app/downloadTransactions", async ({ payload }) => {
  let response: ITransactionFile = { totalProcessed: 0, totalRecords: 0 };
  const callbackDownload = (snapshot: firebase.database.DataSnapshot) => {
    const file: ITransactionFile = snapshot.val();

    if (file.url !== undefined) response = file;
  };

  databaseRef
    .child(`${API_ROUTES.DOWNLOAD_TRANSACTIONS}${payload}`)
    .on("value", callbackDownload);

  return response;
});
