// istanbul ignore file
import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { Datepicker } from "@kushki/connect-ui";
import { IDatePicker } from "./DatePicker.interfaces";
import { basicContainerStyles } from "../../containers/BasicContainer/BasicContainer.styles";
import { Box } from "@mui/material";
import { get } from "lodash";

const DatePickers: FC<IDatePicker> = ({
  name,
  control,
  formateDate,
  label,
  rules,
  errors,
  isDisable,
}: IDatePicker): JSX.Element => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={""}
      rules={rules}
      render={({ field }) => {
        return (
          <Box
            sx={
              isDisable
                ? basicContainerStyles.disablePicker
                : basicContainerStyles.emptyPicker
            }
          >
            <Datepicker
              disableFuture={true}
              placeholder={label || ""}
              isOpen={false}
              dateFormat={formateDate}
              onDateChange={(formattedDate) => {
                field.onChange(formattedDate);
              }}
              defaultValue={field.value}
              error={get(errors, name) !== undefined}
              helperText={get(errors, name)?.message ?? ""}
              onlySelect={true}
            />
          </Box>
        );
      }}
    />
  );
};

export default DatePickers;
