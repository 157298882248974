import { UseFormReturn } from "react-hook-form";
import { useStyles as styles } from "./BillingDataFormCountries.styles";
import React from "react";
import { Divider, Grid, MenuItem, Typography } from "@mui/material";
import { get } from "lodash";

import { LabelsEnum } from "../../shared/enum/LabelsEnum";
import { BillingDataFormLabelsEnum } from "../../shared/enums/BillingDataFormLabelsEnum";
import { ErrorBillingData } from "../../shared/enum/BasicStateMX/ErrorMessageMX";
import { FieldsEnum } from "../../shared/enums/FieldsEnum";
import PhoneInputField from "../commonInputs/PhoneInputField/PhoneInputField";
import AddressInputField from "../commonInputs/AddressInputField/AddressInputField";
import { CountryEnum } from "../../shared/countryEnum";
import SelectInputField from "../commonInputs/SelectInputField/SelectInputField";
import { MerchantCompanyRequest } from "../../../types/merchant_company_request";
import { Category } from "../../shared/constants/information_constants";
import { CatalogCo } from "../../shared/services/catalog-co";
import { CatalogsEnum } from "../../shared/interfaces/catalogs-enum";
import { ZipCodeComponent } from "../ZipCodeComponent/ZipCodeComponent";
import { BasicDataLabelsCO } from "../../shared/enum/BasicStateCo/ErrorMessagesCO";
import { ICACatalogCo } from "../../shared/enum/BasicStateCo/CatalogBasicDataCo";
import { FieldsNamesRender } from "../../shared/enum/FieldsEnumRenderEnum";

export type formProps = {
  cityColombiaCatalog: Category[];
  form: UseFormReturn<MerchantCompanyRequest>;
  infoBilling: {
    city: string;
    constitutionalCountry: string;
    country: string;
    municipality: string;
    populationCode: string;
    province: string;
    satCity: string;
    satMunicipality: string;
    satPopulationCode: string;
    satProvince: string;
    satStateCode: string;
    stateCode: string;
    useSameInfoSat: boolean;
  };
  isCentralizedNode: boolean;
};

const BillingDataFormCountriesCO: React.FC<formProps> = (props: formProps) => {
  const classes = styles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SelectInputField
            name={FieldsEnum.province}
            control={props.form.control}
            errors={props.form.formState.errors}
            defaultValue={props.form.watch(FieldsEnum.province)}
            label={BillingDataFormLabelsEnum.department}
            itemsSelect={get(CatalogCo, `${CatalogsEnum.Provinces}`, []).map(
              (item: Category) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.name}
                </MenuItem>
              )
            )}
            disabled={props.isCentralizedNode}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInputField
            name={FieldsEnum.city}
            control={props.form.control}
            errors={props.form.formState.errors}
            defaultValue={props.form.watch(FieldsEnum.city)}
            label={BillingDataFormLabelsEnum.municipality}
            itemsSelect={props.cityColombiaCatalog.map((item: Category) => (
              <MenuItem key={item.value} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
            disabled={props.isCentralizedNode}
          />
        </Grid>
        <Grid item xs={6}>
          <ZipCodeComponent
            {...props.form}
            watch={props.form.watch}
            country={CountryEnum.colombia}
            control={props.form.control}
            errors={props.form.formState.errors}
            disabled={props.isCentralizedNode}
          />
        </Grid>
        <Grid item xs={6}>
          <AddressInputField
            control={props.form.control}
            errors={props.form.formState.errors}
            disabled={props.isCentralizedNode}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.phone}>
        <Grid item xs={12}>
          <PhoneInputField
            fieldName={FieldsEnum.phoneNumber}
            labelName={BillingDataFormLabelsEnum.merchant_phone}
            control={props.form.control}
            errors={props.form.formState.errors}
            watch={props.form.watch}
            rules={{
              required: { message: ErrorBillingData.PHONE_NUMBER_EMPTY },
            }}
            country={props.infoBilling.constitutionalCountry as CountryEnum}
            countryCustomer={props.infoBilling.country as CountryEnum}
            disabled={props.isCentralizedNode}
          />
        </Grid>
      </Grid>
      <Divider className={classes.satDivider} />
      <Typography className={classes.satSubtitle}>
        {LabelsEnum.DIAN_INFORMATION}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SelectInputField
            name={FieldsEnum.taxRegime}
            control={props.form.control}
            errors={props.form.formState.errors}
            defaultValue={get(CatalogCo, `${CatalogsEnum.Regime}`, []).map(
              (item) => item.value === props.form.watch(FieldsEnum.taxRegimeId)
            )}
            label={BillingDataFormLabelsEnum.regime}
            itemsSelect={get(CatalogCo, `${CatalogsEnum.Regime}`, []).map(
              (item: Category) => (
                <MenuItem key={item.value} value={item.name}>
                  {item.name}
                </MenuItem>
              )
            )}
            disabled={props.isCentralizedNode}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInputField
            name={FieldsEnum.obligation}
            control={props.form.control}
            errors={props.form.formState.errors}
            defaultValue={props.form.watch(FieldsEnum.obligation)}
            label={BillingDataFormLabelsEnum.obligations}
            itemsSelect={get(CatalogCo, `${CatalogsEnum.Obligations}`, []).map(
              (item: Category) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.name}
                </MenuItem>
              )
            )}
            disabled={props.isCentralizedNode}
          />
        </Grid>
      </Grid>
      <Divider className={classes.satDivider} />
      <Typography className={classes.satSubtitle}>
        {LabelsEnum.RETENTION_DATA}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SelectInputField
            name={FieldsNamesRender.retention_ICA}
            defaultValue={props.form.watch(FieldsNamesRender.retention_ICA)}
            control={props.form.control}
            errors={props.form.formState.errors}
            label={BasicDataLabelsCO.ICA_RETENTIONS}
            itemsSelect={ICACatalogCo.map(
              (item: { id: string; label: string }) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.label}
                </MenuItem>
              )
            )}
            disabled={props.isCentralizedNode}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default BillingDataFormCountriesCO;
