import { RootState } from "../store.interfaces";

export const selectWebhookSuccessResponse = (state: RootState) =>
  state.app.webHooksList.successfulResponse;

export const selectNodeInfo = (state: RootState) => state.app.nodeInfo;

export const selectWebhookList = (state: RootState) => state.app.webhookList;

export const selectGetWebhookList = (state: RootState) =>
  state.app.getWebhookList;
