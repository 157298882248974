import React from "react";
import { ModalHeader } from "@kushki/frontend-molecules/modal-header";
import { Webhook } from "../../../types/webhook_response";
import { format } from "date-fns";

export interface HeaderDetailProps {
  webhook: Webhook;
}

export const HeaderDetail: React.FC<HeaderDetailProps> = (
  props: HeaderDetailProps
) => {
  return (
    <React.Fragment>
      <ModalHeader
        tittle={"Webhooks"}
        secondaryValue={
          props.webhook.created
            ? `Creado el ${format(
                new Date(props.webhook.created),
                "dd MMM. yyyy HH:mm"
              )}`
            : undefined
        }
        highlightValue={"Pág web principal"}
      />
    </React.Fragment>
  );
};
