import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Chip, ChipProps } from "@material-ui/core";

export interface SimpleChipProps extends ChipProps {
  label: string;
}

const useStyles = makeStyles(() => ({
  chip: {
    backgroundColor: "#F7FAFC",
    border: 0,
    borderRadius: "4px",
    color: "#293036",
    marginLeft: "6px",
    marginRight: "6px",
    marginTop: "6px",
    width: "inherit",
  },
}));

const SimpleChip: React.FC<SimpleChipProps> = (props: SimpleChipProps) => {
  const classes = useStyles(props);

  return <Chip className={classes.chip} {...props} />;
};

export default SimpleChip;
