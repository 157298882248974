import { IUseProcessorState } from "./IUseProcessorState.interfaces";
import React, { useEffect, useState } from "react";
import {
  deleteProcessorByPaymentMethod,
  getDefaultPayoutProcessor,
  getDefaultProcessor,
  getMerchantInformation,
  getProcessors,
  updateFailoverProcessor,
} from "../../../store/thunks/layout/layout.thunks";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { GetProcessorResponse } from "../../../../types/get_processor_response";
import {
  getCustomerBatchInfo,
  getMerchantId,
  isAllCardProcessorsWithFailover,
  isAvailableCardProcessor,
  isBatchPath,
  notifyErrorAlert,
  notifySuccessAlert,
} from "../../../utils/utilsFile";
import { defaultTo, get, isEmpty, isEqual, omit } from "lodash";
import { ProcessorConstants } from "../../../shared/constants/ProcessorConstants";
import { PaymentMethodEnum } from "../../../shared/enums/PaymentMethodEnum";
import { AppRoutes } from "../../../shared/infrastructure/routes";
import {
  useSnackbar,
  IconCheck,
  IconCircleWarn,
  IconCircleInformation,
} from "@kushki/connect-ui";
import { SnackBarColors } from "../../../shared/infrastructure/enums/SnackBarColorsEnum";
import { SnackBarVariantEnum } from "../../../shared/infrastructure/enums/SnackBarVariantEnum";
import { SelectChangeEvent } from "@mui/material";
import { ConfigurationCodeEnum } from "../../../shared/enums/ProcessingStepEnum";
import { EntityNameEnum } from "../../../shared/enums/EntityNameEnum";
import { successMessages } from "../../../shared/infrastructure/enums/SnackBarTextEnum";
import { EXPECTED_RESULT } from "../../../shared/constants/modal-info";
import { LoadingModal } from "../../../store/interfaces/layout/loading-modal.interface";
import { LoadingModalEnum } from "../../../shared/enums/LoadingModalEnum";
import {
  changeLoadingValue,
  dismissSnackBar,
  setProcessors,
  showLoadingModal,
} from "../../../store/reducers/layout/layout.slice";
import { STATUS_CODE } from "../../../shared/constants/status_code";
import { CountriesEnum } from "../../../shared/infrastructure/enums/CountriesEnum";
import {
  Configs,
  GetNodeInfoResponse,
} from "../../../../types/get_node_info_response";
import { NodeInfoPath } from "../../../shared/enums/NodeInfoPath";
import { findConfigById } from "../../../containers/ProcessingConfigIndex/state/useOnInitProcessing";

export const useProcessorState = (): IUseProcessorState => {
  const dispatch = useAppDispatch();
  const merchantId: string = getMerchantId();
  const { showSnackbar } = useSnackbar();
  const isMassivePath = isBatchPath();
  const [country, setCountry] = useState("");
  const [disableProcessorsTab, setDisableProcessorsTab] = useState<boolean>(
    false
  );
  const [isOpenDeleteProcessorAlert, setIsOpenDeleteProcessorAlert] = useState<
    boolean
  >(false);
  const [isDefaultProcessor, setIsDefaultProcessor] = useState<boolean>(false);
  const [processorInfo, setProcessorInfo] = useState<
    GetProcessorResponse | undefined
  >(undefined);
  const [
    currentFailoverProcessorSelected,
    setCurrentFailoverProcessorSelected,
  ] = useState<GetProcessorResponse | undefined>(undefined);
  const [failoverProcessorList, setFailoverProcessorList] = useState<
    { name: string; value: string }[]
  >([]);
  const [selectedFailoverProcessor, setSelectedFailoverProcessor] = useState<
    string
  >("");

  const [isRenderFailoverOption, setIsRenderFailoverOption] = useState<boolean>(
    false
  );
  const [isShowFailoverMessage, setIsShowFailoverMessage] = useState<boolean>(
    false
  );
  const [
    isOpenFailoverModalProcessor,
    setIsOpenFailoverModalProcessor,
  ] = useState<boolean>(false);
  const [isCentralized, setIsCentralized] = useState<boolean>(false);
  const [isProcessorsCentralized, setIsProcessorsCentralized] = useState<
    boolean
  >(false);

  const {
    processor,
    merchantInformation,
    defaultProcessor,
    defaultPayoutProcessor,
    nodeInfo,
    loadingProcessor,
    loadingNodeInfo,
    loadingDeferred,
    isMassiveBranches,
    isNotRendererTabs,
    massiveInformation,
    snackBarInfo,
    openAlertCentralizeBranch,
    appConfigPaymentMethods,
  } = useAppSelector((store) => store.layout);

  const handleOnCloseAlert = (): void => {
    setIsOpenDeleteProcessorAlert(false);
  };

  const handleOnClickDeleteProcessor = (
    processor: GetProcessorResponse
  ): void => {
    setIsOpenDeleteProcessorAlert(true);
    setProcessorInfo(processor);

    if (defaultProcessor) {
      const isDefaultProcessor: (string | undefined)[] = Object.values(
        defaultProcessor.defaultProcessor
      ).filter((value) => value === processor.alias);
      const isDefaultPayoutProcessor: boolean =
        get(defaultPayoutProcessor, "defaultProcessor.payoutsCard", "") ===
        processor.publicProcessorId;
      const isDefault: boolean =
        get(isDefaultProcessor, "length", 0) > 0 || isDefaultPayoutProcessor;

      setIsDefaultProcessor(isDefault);
    }
  };

  const handleOnClickEditProcessor = (
    processor: GetProcessorResponse
  ): void => {
    window.location.href = retrieveEditOrDeleteUrlByPaymentMethod(
      get(processor, "paymentMethod"),
      processor,
      true
    );
  };

  const handleOnAcceptModalDeleteProcessor = async () => {
    const merchantId: string = getMerchantId();
    const deleteProcessor: LoadingModal = {
      message: LoadingModalEnum.DELETE_PROCESSOR,
      isOpen: true,
    };

    setIsOpenDeleteProcessorAlert(false);

    if (processorInfo) {
      await dispatch(showLoadingModal(deleteProcessor));
      const urlToDelete: string = retrieveEditOrDeleteUrlByPaymentMethod(
        get(processorInfo, "paymentMethod"),
        processorInfo,
        false
      );

      const result: object = await dispatch(
        deleteProcessorByPaymentMethod({
          url: urlToDelete,
          merchantId,
        })
      );

      if (get(result, "meta.requestStatus") === STATUS_CODE.FULLFILLED) {
        dispatch(
          setProcessors(
            defaultTo(processor, []).filter(
              (p: GetProcessorResponse) =>
                p.publicProcessorId !== get(processorInfo, "publicProcessorId")
            )
          )
        );
      }
    }
  };

  const handleOnClickFailover = (processor: GetProcessorResponse): void => {
    setIsOpenFailoverModalProcessor(true);
    setSelectedFailoverProcessor(get(processor, "failOverProcessor", ""));
    setCurrentFailoverProcessorSelected(processor);
  };

  const handleOnCloseFailoverProcessorModal = (): void => {
    setIsOpenFailoverModalProcessor(false);
  };

  const handleOnChangeFailoverProcessorModalSelect = (
    event: SelectChangeEvent<unknown>
  ): void => {
    setSelectedFailoverProcessor(get(event, "target.value", ""));
  };

  const handleConfigureFailover = async () => {
    if (!isEmpty(selectedFailoverProcessor)) {
      const configFailover: LoadingModal = {
        message: LoadingModalEnum.CONFIG_FAILOVER,
        isOpen: true,
      };
      setIsOpenFailoverModalProcessor(false);
      dispatch(showLoadingModal(configFailover));

      const result: object = await dispatch(
        updateFailoverProcessor({
          failoverAlias: selectedFailoverProcessor,
          merchantId: getMerchantId(),
          publicProcessorId: get(
            currentFailoverProcessorSelected,
            "publicProcessorId"
          )!!,
        })
      );

      if (get(result, "payload.status") === STATUS_CODE.OK) {
        const newProcessors: GetProcessorResponse[] = defaultTo(
          processor,
          []
        ).map((p: GetProcessorResponse) => {
          if (
            p.publicProcessorId ===
            get(currentFailoverProcessorSelected, "publicProcessorId")
          ) {
            p = { ...p, failOverProcessor: selectedFailoverProcessor };
          }
          return p;
        });
        dispatch(setProcessors(newProcessors));
      }
    }
  };

  const retrieveEditOrDeleteUrlByPaymentMethod = (
    paymentMethod: string,
    processor: GetProcessorResponse,
    isEdit: boolean
  ): string => {
    const publicProcessorId: string = get(processor, "publicProcessorId");

    switch (paymentMethod) {
      case PaymentMethodEnum.TRANSFER:
        return isEdit
          ? AppRoutes.EDIT_TRANSFER_PROCESSOR(merchantId, publicProcessorId)
          : AppRoutes.DELETE_TRANSFER_PROCESSOR(publicProcessorId);
      case PaymentMethodEnum.TRANSFER_INTERNATIONAL:
        return isEdit
          ? AppRoutes.EDIT_TRANSFER_INTERNATIONAL_PROCESSOR(
              merchantId,
              publicProcessorId
            )
          : AppRoutes.DELETE_TRANSFER_INTERNATIONAL_PROCESSOR(
              publicProcessorId
            );

      case PaymentMethodEnum.TRANSFER_SUBSCRIPTIONS:
        return isEdit
          ? AppRoutes.EDIT_TRANSFER_SUBS_PROCESSOR(
              merchantId,
              publicProcessorId
            )
          : AppRoutes.DELETE_TRANSFER_SUBS_PROCESSOR(publicProcessorId);
      case PaymentMethodEnum.CASH:
        return isEdit
          ? AppRoutes.EDIT_CASH_PROCESSOR(merchantId, publicProcessorId)
          : AppRoutes.DELETE_CASH_PROCESSOR(publicProcessorId);
      case PaymentMethodEnum.PAYOUTS_TRANSFER:
        return isEdit
          ? AppRoutes.EDIT_PAYOUTS_TRANSFER_PROCESSOR(
              merchantId,
              publicProcessorId
            )
          : AppRoutes.DELETE_PAYOUTS_TRANSFER_PROCESSOR(publicProcessorId);
      case PaymentMethodEnum.PAYOUTS_CASH:
        return isEdit
          ? AppRoutes.EDIT_PAYOUTS_CASH_PROCESSOR(merchantId, publicProcessorId)
          : AppRoutes.DELETE_PAYOUTS_CASH_PROCESSOR(publicProcessorId);
      case PaymentMethodEnum.CARD:
        return isEdit
          ? AppRoutes.EDIT_CARD_PROCESSOR(merchantId, publicProcessorId)
          : AppRoutes.DELETE_CARD_PROCESSOR(merchantId, publicProcessorId);
      case PaymentMethodEnum.PAYOUTS_CARD:
        return isEdit
          ? AppRoutes.EDIT_PAYOUT_CARD_PROCESSOR(merchantId, publicProcessorId)
          : AppRoutes.DELETE_PAYOUT_CARD_PROCESSOR(publicProcessorId);
      default:
        return ProcessorConstants.EMPTY;
    }
  };

  useEffect(() => {
    if (!isEmpty(merchantId)) {
      dispatch(
        getProcessors({
          entity: get(nodeInfo, "entityName", ""),
          merchantId,
        })
      );
      dispatch(
        getMerchantInformation({
          offset: 0,
          text: merchantId,
        })
      );
      dispatch(
        getDefaultProcessor({
          merchantId,
        })
      );
      dispatch(
        getDefaultPayoutProcessor({
          merchantId,
        })
      );
    }
  }, [merchantId]);

  useEffect(() => {
    if (get(merchantInformation, "data.length", 0) > 0) {
      const basePath: string = "data[0]._source";
      const processorsConfig: Configs = findConfigById(
        ConfigurationCodeEnum.PROCESSOR,
        get(nodeInfo, NodeInfoPath.Configs, [])
      );
      const centralizedMerchantId: string = get(processorsConfig, "value", "");

      let merchantBasicInformation: object = {
        country: get(merchantInformation, `${basePath}.country`),
        name: get(merchantInformation, `${basePath}.name`),
        origin: ProcessorConstants.ORIGIN,
        publicMerchantId: isCentralized
          ? centralizedMerchantId
          : get(merchantInformation, `${basePath}.publicMerchantId`),
        webSite: get(merchantInformation, `${basePath}.webSite`),
        entityName: get(nodeInfo, "entityName", ""),
        isCentralized: defaultTo(isCentralized, false),
      };

      if (isEmpty(get(merchantBasicInformation, "entityName"))) {
        merchantBasicInformation = omit(merchantBasicInformation, "entityName");
      }

      if (isBatchPath()) {
        const customerBatchInfo: object = JSON.parse(getCustomerBatchInfo());
        merchantBasicInformation = {
          ...merchantBasicInformation,
          ...customerBatchInfo,
        };
      }

      localStorage.setItem(
        "merchantBasicInformation",
        JSON.stringify(merchantBasicInformation)
      );
    }
  }, [merchantInformation, nodeInfo, isCentralized]);

  useEffect(() => {
    const successAlert: string = notifySuccessAlert();

    if (!isEmpty(successAlert)) {
      showSnackbar({
        message: successMessages.get(successAlert)!!,
        variant: SnackBarVariantEnum.SIMPLE,
        color: SnackBarColors.SUCCESS,
        withIcon: true,
        icon: <IconCheck />,
      });
      localStorage.removeItem("notifySuccessAlert");
    }
  }, [notifySuccessAlert]);

  useEffect(() => {
    const errorAlert: string = notifyErrorAlert();

    if (!isEmpty(errorAlert)) {
      showSnackbar({
        message: ProcessorConstants.ERROR_ON_PROCESSOR,
        variant: SnackBarVariantEnum.SIMPLE,
        color: SnackBarColors.DANGER,
        withIcon: true,
        icon: <IconCircleWarn />,
      });
      localStorage.removeItem("notifyErrorAlert");
    }
  }, [notifyErrorAlert]);

  useEffect(() => {
    setIsRenderFailoverOption(
      isAvailableCardProcessor(processor, 1, country as CountriesEnum)
    );
  }, [processor, country]);

  useEffect(() => {
    if (isRenderFailoverOption) {
      setIsShowFailoverMessage(!isAllCardProcessorsWithFailover(processor));
    } else {
      setIsShowFailoverMessage(false);
    }
  }, [isRenderFailoverOption, processor]);

  useEffect(() => {
    if (isAvailableCardProcessor(processor)) {
      const processorsToRetrieve: { name: string; value: string }[] = processor
        .filter(
          (data: GetProcessorResponse) =>
            data.alias !== get(currentFailoverProcessorSelected, "alias") &&
            data.paymentMethod === PaymentMethodEnum.CARD &&
            data.processorName !==
              get(currentFailoverProcessorSelected, "processorName") &&
            get(data, "status", "") !== ProcessorConstants.STATUS_DISABLED
        )
        .map((data: GetProcessorResponse) => ({
          name: data.alias,
          value: data.alias,
        }));
      setFailoverProcessorList(processorsToRetrieve);
    }
  }, [processor, currentFailoverProcessorSelected]);

  useEffect(() => {
    if (isMassivePath)
      setDisableProcessorsTab(massiveInformation.disableProcessorsTab);
  }, [massiveInformation]);

  const verifyIsCentralized = (
    nodeInfo: GetNodeInfoResponse,
    configurationCode?: string
  ): boolean => {
    return (
      !isEmpty(get(nodeInfo, "configs", [])) &&
      get(nodeInfo, "configs", []).some(
        (data) =>
          !isEmpty(get(data, "centralizedNodesId", "")) &&
          (!configurationCode ||
            get(data, "configuration", "") === configurationCode)
      ) &&
      get(nodeInfo, "entityName", "") === EntityNameEnum.BRANCH
    );
  };

  useEffect(() => {
    if (nodeInfo) {
      const isCentralizedNode: boolean = verifyIsCentralized(nodeInfo);
      const isProcessorsCentralizedNode: boolean = verifyIsCentralized(
        nodeInfo,
        ConfigurationCodeEnum.PROCESSOR
      );

      setIsCentralized(isCentralizedNode);
      setCountry(get(nodeInfo, "generalInfo.country"));
      setIsProcessorsCentralized(isProcessorsCentralizedNode);
    }
    if (
      !isEmpty(nodeInfo) &&
      !isEmpty(nodeInfo.merchantId) &&
      !isMassiveBranches
    ) {
      const processorsConfig: Configs = findConfigById(
        ConfigurationCodeEnum.PROCESSOR,
        get(nodeInfo, NodeInfoPath.Configs, [])
      );
      const processorValue: string = get(processorsConfig, "value", "");
      const merchantId: string = get(nodeInfo, NodeInfoPath.MerchantID, "");

      dispatch(
        changeLoadingValue({ property: "loadingProcessor", value: true })
      );
      dispatch(
        getProcessors({
          merchantId: processorValue || merchantId,
          entity: get(nodeInfo, "entityName", ""),
        })
      );
    }
  }, [nodeInfo]);

  useEffect(() => {
    if (snackBarInfo.isOpen && openAlertCentralizeBranch.openAlert) {
      showSnackbar({
        message: defaultTo(snackBarInfo.message, ""),
        variant: SnackBarVariantEnum.SIMPLE,
        color: defaultTo(snackBarInfo.color, "info"),
        withIcon: true,
        icon: isEqual(openAlertCentralizeBranch.result, EXPECTED_RESULT.OK) ? (
          <IconCheck />
        ) : (
          <IconCircleInformation />
        ),
      });
      dispatch(dismissSnackBar());
    }
  }, [snackBarInfo]);

  return {
    country: country as CountriesEnum,
    appConfigPaymentMethods: get(appConfigPaymentMethods, "[0]", {}),
    isMassivePath,
    disableProcessorsTab,
    isOpenDeleteProcessorAlert,
    processor,
    isDefaultProcessor,
    isRenderFailoverOption,
    isShowFailoverMessage,
    isOpenFailoverModalProcessor,
    failoverProcessorList,
    handleOnCloseAlert,
    handleOnClickEditProcessor,
    handleOnClickDeleteProcessor,
    handleOnAcceptModalDeleteProcessor,
    handleOnClickFailover,
    handleOnCloseFailoverProcessorModal,
    handleOnChangeFailoverProcessorModalSelect,
    handleConfigureFailover,
    isProcessorsCentralized,
    failoverPerDefault: selectedFailoverProcessor,
    loadingProcessor:
      loadingNodeInfo ||
      loadingProcessor ||
      loadingDeferred ||
      (isNotRendererTabs && isEmpty(appConfigPaymentMethods)),
  };
};
