/* istanbul ignore file */

import { IOptionSelection } from "./RulesConstants";
import { CurrencyEnum } from "../enums/CurrencyEnum";

export const BANKS_BRAZIL: IOptionSelection[] = [
  { value: "00000000", name: "BANCO DO BRASIL S.A." },
  { value: "00000208", name: "BRB - BANCO DE BRASILIA S.A." },
  { value: "00250699", name: "AGK CORRETORA DE CAMBIO S.A." },
  { value: "00360305", name: "CAIXA ECONOMICA FEDERAL" },
  { value: "00416968", name: "BANCO INTER S.A." },
  {
    value: "00460065",
    name: "COLUNA S/A DISTRIBUIDORA DE TITULOS E VALORES MOBILIÁRIOS",
  },
  { value: "00558456", name: "BANCO CETELEM S.A." },
  { value: "00795423", name: "BANCO SEMEAR S.A." },
  { value: "00806535", name: "PLANNER CORRETORA DE VALORES S.A." },
  { value: "00997185", name: "BANCO B3 S.A." },
  { value: "01023570", name: "BANCO RABOBANK INTERNATIONAL BRASIL S.A." },
  { value: "01181521", name: "BANCO COOPERATIVO SICREDI S.A." },
  { value: "01522368", name: "BANCO BNP PARIBAS BRASIL S.A." },
  { value: "01701201", name: "KIRTON BANK S.A. - BANCO MÚLTIPLO" },
  { value: "02038232", name: "BANCO COOPERATIVO SICOOB S.A. - BANCO SICOOB" },
  { value: "02318507", name: "BANCO KEB HANA DO BRASIL S.A." },
  {
    value: "02332886",
    name: "XP INVESTIMENTOS CORRETORA DE CAMBIO,TÍTULOS E VALORES MOBILIÁRIOS S/A",
  },
  { value: "02801938", name: "BANCO MORGAN STANLEY S.A." },
  { value: "02992317", name: "TREVISO CORRETORA DE CAMBIO S.A." },
  { value: "03012230", name: "HIPERCARD BANCO MÚLTIPLO S.A." },
  { value: "03017677", name: "BANCO J. SAFRA S.A." },
  { value: "03443143", name: "AVIPAM CORRETORA DE CAMBIO LTDA" },
  { value: "03532415", name: "BANCO ABN AMRO S.A." },
  { value: "03609817", name: "BANCO CARGILL S.A." },
  { value: "04062902", name: "OZ CORRETORA DE CAMBIO S.A." },
  { value: "04332281", name: "GOLDMAN SACHS DO BRASIL BANCO MULTIPLO S.A." },
  {
    value: "04684647",
    name: "ARC CORRETORA DE CAMBIO, ASSOCIADOS GOUVEIA, CAMPEDELLI S.A.",
  },
  { value: "04866275", name: "BANCO INBURSA S.A." },
  { value: "04902979", name: "BANCO DA AMAZONIA S.A." },
  { value: "04913129", name: "CONFIDENCE CORRETORA DE CAMBIO S.A." },
  { value: "04913711", name: "BANCO DO ESTADO DO PARÁ S.A." },
  {
    value: "05816451",
    name: "GENIAL INSTITUCIONAL CORRETORA DE CAMBIO, TÍTULOS E VALORES MOBILIÁRIO",
  },
  { value: "06132348", name: "LABOR SOCIEDADE CORRETORA DE CAMBIO LTDA." },
  { value: "06373777", name: "BOA VIAGEM SOCIEDADE CORRETORA DE CAMBIO LTDA." },
  { value: "07237373", name: "BANCO DO NORDESTE DO BRASIL S.A." },
  { value: "07333726", name: "ONNIX CORRETORA DE CAMBIO LTDA." },
  {
    value: "07450604",
    name: "CHINA CONSTRUCTION BANK (BRASIL) BANCO MÚLTIPLO S/A",
  },
  { value: "07656500", name: "BANCO KDB DO BRASIL S.A." },
  { value: "07679404", name: "BANCO TOPÁZIO S.A." },
  { value: "08520517", name: "SOL CORRETORA DE CAMBIO LTDA." },
  { value: "08609934", name: "MONEYCORP BANCO DE CAMBIO S.A." },
  { value: "09274232", name: "STATE STREET BRASIL S.A. - BANCO COMERCIAL" },
  { value: "09512542", name: "CODEPE CORRETORA DE VALORES E CAMBIO S.A." },
  { value: "09516419", name: "PICPAY BANK - BANCO MÚLTIPLO S.A" },
  { value: "10690848", name: "BANCO DA CHINA BRASIL S.A." },
  { value: "10853017", name: "GET MONEY CORRETORA DE CAMBIO S.A." },
  {
    value: "11495073",
    name: "OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
  },
  { value: "11703662", name: "TRAVELEX BANCO DE CAMBIO S.A." },
  {
    value: "12392983",
    name: "MIRAE ASSET WEALTH MANAGEMENT (BRAZIL) CORRETORA DE CAMBIO, TÍTULOS E",
  },
  { value: "13059145", name: "BEXS BANCO DE CAMBIO S/A" },
  { value: "13220493", name: "BR PARTNERS BANCO DE INVESTIMENTO S.A." },
  {
    value: "13673855",
    name: "FRAM CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
  },
  { value: "13720915", name: "BANCO WESTERN UNION DO BRASIL S.A." },
  { value: "13728156", name: "WESTERN UNION CORRETORA DE CAMBIO S.A." },
  { value: "14190547", name: "CAMBIONET CORRETORA DE CAMBIO LTDA." },
  { value: "14388334", name: "PARANÁ BANCO S.A." },
  { value: "15077393", name: "MEGA CORRETORA DE CAMBIO LTDA." },
  { value: "15114366", name: "BANCO BOCOM BBM S.A." },
  { value: "15122605", name: "LÚMINA CORRETORA DE CAMBIO LTDA." },
  { value: "15168152", name: "CONSEGTUR CORRETORA DE CAMBIO LTDA." },
  { value: "15357060", name: "BANCO WOORI BANK DO BRASIL S.A." },
  { value: "15482499", name: "TURCAMBIO - CORRETORA DE CAMBIO LTDA." },
  { value: "15761217", name: "CORRETORA DE CAMBIO AÇORIANA LIMITADA." },
  { value: "16854999", name: "SINGRATUR CORRETORA DE CAMBIO LTDA" },
  { value: "16927221", name: "AMAZÔNIA CORRETORA DE CAMBIO LTDA." },
  { value: "16944141", name: "BROKER BRASIL CORRETORA DE CAMBIO LTDA." },
  { value: "17184037", name: "BANCO MERCANTIL DO BRASIL S.A." },
  { value: "17298092", name: "BANCO ITAÚ BBA S.A." },
  {
    value: "17312083",
    name: "H H PICCHIONI S/A CORRETORA DE CAMBIO E VALORES MOBILIARIOS",
  },
  {
    value: "17312661",
    name: "AMARIL FRANKLIN CORRETORA DE TÍTULOS E VALORES LTDA",
  },
  {
    value: "17354911",
    name: "COTACAO DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS S.A",
  },
  { value: "17453575", name: "ICBC DO BRASIL BANCO MÚLTIPLO S.A." },
  { value: "17508380", name: "INVEST CORRETORA DE CAMBIO LTDA." },
  { value: "17635177", name: "CONEXION CORRETORA DE CAMBIO LTDA." },
  { value: "17772370", name: "VIP'S CORRETORA DE CAMBIO LTDA." },
  { value: "17904906", name: "BRX CORRETORA DE CAMBIO LTDA." },
  { value: "18145784", name: "NUMATUR CORRETORA DE CAMBIO LTDA." },
  { value: "18287740", name: "CONECTA CORRETORA DE CAMBIO LTDA." },
  { value: "18520834", name: "UBS BRASIL BANCO DE INVESTIMENTO S.A." },
  { value: "19086249", name: "EXECUTIVE CORRETORA DE CAMBIO LTDA." },
  { value: "19307785", name: "MS BANK S.A. BANCO DE CAMBIO" },
  {
    value: "20155248",
    name: "PARMETAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
  },
  {
    value: "21040668",
    name: "GLOBAL EXCHANGE DO BRASIL SOCIEDADE CORRETORA DE CAMBIO LTDA.",
  },
  { value: "23010182", name: "GOOD CORRETORA DE CAMBIO LTDA" },
  { value: "23522214", name: "COMMERZBANK BRASIL S.A. - BANCO MÚLTIPLO" },
  { value: "24074692", name: "GUITTA CORRETORA DE CAMBIO LTDA." },
  { value: "25280945", name: "AVS CORRETORA DE CAMBIO LTDA." },
  {
    value: "27842177",
    name: "IB CORRETORA DE CAMBIO, TÍTULOS E VALORES MOBILIÁRIOS S.A.",
  },
  {
    value: "28048783",
    name: "ELITE CORRETORA DE CAMBIO E VALORES MOBILIÁRIOS LTDA",
  },
  {
    value: "28127603",
    name: "BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO",
  },
  { value: "28195667", name: "BANCO ABC BRASIL S.A." },
  {
    value: "28650236",
    name: "BS2 DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
  },
  { value: "28762249", name: "SADOC SOCIEDADE CORRETORA DE CAMBIO LTDA." },
  { value: "28811341", name: "STONEX BANCO DE CAMBIO S.A." },
  { value: "29030467", name: "SCOTIABANK BRASIL S.A. BANCO MÚLTIPLO" },
  { value: "30183111", name: "TURMA CORRETORA DE CAMBIO LTDA" },
  { value: "30306294", name: "BANCO BTG PACTUAL S.A." },
  { value: "30723886", name: "BANCO MODAL S.A." },
  { value: "31872495", name: "BANCO C6 S.A." },
  { value: "31895683", name: "BANCO INDUSTRIAL DO BRASIL S.A." },
  { value: "32648370", name: "FAIR CORRETORA DE CAMBIO S.A." },
  { value: "33042151", name: "BANCO DE LA NACION ARGENTINA" },
  { value: "33042953", name: "CITIBANK N.A." },
  { value: "33172537", name: "BANCO J.P. MORGAN S.A." },
  { value: "33264668", name: "BANCO XP S.A." },
  { value: "33466988", name: "BANCO CAIXA GERAL - BRASIL S.A." },
  { value: "33479023", name: "BANCO CITIBANK S.A." },
  {
    value: "33657248",
    name: "BANCO NACIONAL DE DESENVOLVIMENTO ECONOMICO E SOCIAL",
  },
  {
    value: "33851064",
    name: "DILLON S/A DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS",
  },
  { value: "33885724", name: "BANCO ITAÚ CONSIGNADO S.A." },
  {
    value: "33886862",
    name: "MASTER S/A CORRETORA DE CAMBIO, TÍTULOS E VALORES MOBILIÁRIOS",
  },
  {
    value: "33923111",
    name: "BRKB DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
  },
  { value: "33923798", name: "BANCO MASTER S/A" },
  {
    value: "33987793",
    name: "BANCO DE INVESTIMENTOS CREDIT SUISSE (BRASIL) S.A.",
  },
  { value: "34111187", name: "HAITONG BANCO DE INVESTIMENTO DO BRASIL S.A." },
  { value: "34265629", name: "INTERCAM CORRETORA DE CAMBIO LTDA." },
  { value: "34666362", name: "MONOPÓLIO CORRETORA DE CAMBIO LTDA." },
  { value: "36588217", name: "WISE BRASIL CORRETORA DE CAMBIO LTDA" },
  { value: "36658769", name: "BANCO XCMG BRASIL S.A." },
  {
    value: "38486817",
    name: "BANCO DE DESENVOLVIMENTO DE MINAS GERAIS S.A.-BDMG",
  },
  { value: "40333582", name: "PROSEFTUR CORRETORA DE CAMBIO S.A" },
  { value: "40353377", name: "FOURTRADE CORRETORA DE CAMBIO LTDA." },
  { value: "44189447", name: "BANCO DE LA PROVINCIA DE BUENOS AIRES" },
  { value: "45056494", name: "MARMARIS CORRETORA DE CAMBIO LTDA." },
  { value: "45246410", name: "BANCO GENIAL S.A." },
  { value: "46518205", name: "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION" },
  { value: "48795256", name: "BANCO ANDBANK (BRASIL) S.A." },
  { value: "50579044", name: "LEVYCAM - CORRETORA DE CAMBIO E VALORES LTDA." },
  { value: "50585090", name: "BCV - BANCO DE CRÉDITO E VAREJO S.A." },
  { value: "52937216", name: "BEXS CORRETORA DE CAMBIO S/A" },
  { value: "53518684", name: "BANCO HSBC S.A." },
  { value: "54403563", name: "BANCO ARBI S.A." },
  { value: "55230916", name: "INTESA SANPAOLO BRASIL S.A. - BANCO MÚLTIPLO" },
  { value: "58160789", name: "BANCO SAFRA S.A." },
  { value: "58497702", name: "BANCO LETSBANK S.A." },
  { value: "58616418", name: "BANCO FIBRA S.A." },
  { value: "59118133", name: "BANCO LUSO BRASILEIRO S.A." },
  { value: "59285411", name: "BANCO PAN S.A." },
  { value: "59588111", name: "BANCO VOTORANTIM S.A." },
  {
    value: "59615005",
    name: "PATACÃO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
  },
  { value: "60394079", name: "BANCO ITAUBANK S.A." },
  { value: "60498557", name: "BANCO MUFG BRASIL S.A." },
  { value: "60518222", name: "BANCO SUMITOMO MITSUI BRASILEIRO S.A." },
  { value: "60701190", name: "ITAÚ UNIBANCO S.A." },
  { value: "60746948", name: "BANCO BRADESCO S.A." },
  { value: "60770336", name: "BANCO ALFA DE INVESTIMENTO S.A." },
  { value: "60889128", name: "BANCO SOFISA S.A." },
  { value: "61024352", name: "BANCO VOITER S.A." },
  { value: "61033106", name: "BANCO CREFISA S.A." },
  { value: "61088183", name: "BANCO MIZUHO DO BRASIL S.A." },
  { value: "61186680", name: "BANCO BMG S.A." },
  { value: "61444949", name: "SAGITUR CORRETORA DE CAMBIO S.A." },
  { value: "61533584", name: "BANCO SOCIETE GENERALE BRASIL S.A." },
  {
    value: "61723847",
    name: "NEON CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
  },
  { value: "61820817", name: "BANCO PAULISTA S.A." },
  {
    value: "62073200",
    name: "BANK OF AMERICA MERRILL LYNCH BANCO MÚLTIPLO S.A.",
  },
  {
    value: "62090873",
    name: "STONEX DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
  },
  { value: "62144175", name: "BANCO PINE S.A." },
  { value: "62232889", name: "BANCO DAYCOVAL S.A." },
  {
    value: "62280490",
    name: "DIBRAN DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
  },
  {
    value: "62285390",
    name: "SINGULARE CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
  },
  { value: "62331228", name: "DEUTSCHE BANK S.A. - BANCO ALEMAO" },
  { value: "65913436", name: "GUIDE INVESTIMENTOS S.A. CORRETORA DE VALORES" },
  { value: "68900810", name: "BANCO RENDIMENTO S.A." },
  { value: "71027866", name: "BANCO BS2 S.A." },
  {
    value: "71590442",
    name: "LASTRO RDV DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
  },
  { value: "71677850", name: "FRENTE CORRETORA DE CAMBIO LTDA." },
  {
    value: "73279093",
    name: "PACIFIC INVEST DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA",
  },
  { value: "73302408", name: "EXIM CORRETORA DE CAMBIO LTDA" },
  { value: "73622748", name: "B&T CORRETORA DE CAMBIO LTDA." },
  { value: "74828799", name: "NOVO BANCO CONTINENTAL S.A. - BANCO MÚLTIPLO" },
  { value: "75647891", name: "BANCO CRÉDIT AGRICOLE BRASIL S.A." },
  { value: "76641497", name: "DOURADA CORRETORA DE CAMBIO LTDA." },
  {
    value: "77162881",
    name: "DEBONI DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA",
  },
  { value: "78632767", name: "BANCO OURINVEST S.A." },
  { value: "80202872", name: "CORREPARTI CORRETORA DE CAMBIO LTDA" },
  {
    value: "89784367",
    name: "EBADIVAL - E. BAGGIO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LT",
  },
  { value: "90400888", name: "BANCO SANTANDER (BRASIL) S.A." },
  { value: "92702067", name: "BANCO DO ESTADO DO RIO GRANDE DO SUL S.A." },
  { value: "92856905", name: "ADVANCED CORRETORA DE CAMBIO LTDA" },
  { value: "92894922", name: "BANCO ORIGINAL S.A." },
  { value: "94968518", name: "EFX CORRETORA DE CAMBIO LTDA." },
];

export const CURRENCY_BRAZIL: IOptionSelection[] = [
  { value: CurrencyEnum.BRL, name: CurrencyEnum.BRL },
];
