import { isEqual, isNil } from "lodash";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { ConfigIdEnum } from "../../../shared/constants/initial_state_legal_details";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { getNodeInfoInitialData } from "../../../store/thunks/general/general.thunk";
import { RootState } from "../../../store/store";
import { initialState } from "../../../store/reducers/initialData/initialData.slice";
import { Routes } from "../../../shared/constants/routes";
import { searchParams } from "../../../shared/utils/useQuery_utils";

export const useLayoutStepContainer = () => {
  const query = searchParams();
  const publicMerchantId: string = query.publicMerchantId;
  const dispatch = useAppDispatch();
  const initialData = useAppSelector((state: RootState) => state.initialData);
  const isLegalDetails: boolean = useLocation().pathname.includes(
    Routes.LEGAL_DETAILS
  );

  useEffect(() => {
    if (
      !isNil(publicMerchantId) &&
      isEqual(initialData, initialState) &&
      !isLegalDetails
    ) {
      dispatch(
        getNodeInfoInitialData({
          configIds: `${ConfigIdEnum.CN001},${ConfigIdEnum.CN002},${ConfigIdEnum.CN016},${ConfigIdEnum.CN015}`,
          publicMerchantId,
        })
      );
    }
  }, []);

  return {};
};
