import appReducer from "./reducers/app/app";
import { configureStore } from "@reduxjs/toolkit";
import webhookReducer from "./reducers/webhook/webhook.slice";
import { PRIMARY_ENV } from "../shared/constants/environment_names";

export const store = configureStore({
  devTools: process.env.NEXT_PUBLIC_ENV_NAME !== PRIMARY_ENV,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    app: appReducer,
    webhook: webhookReducer,
  },
});
