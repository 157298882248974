/**
 *
 */
export enum CountriesEnum {
  ECUADOR = "Ecuador",
  COLOMBIA = "Colombia",
  PERU = "Peru",
  CHILE = "Chile",
  MEXICO = "Mexico",
  BRAZIL = "Brazil",
  EL_SALVADOR = "ElSalvador",
  PANAMA = "Panama",
  COSTA_RICA = "CostaRica",
  GUATEMALA = "Guatemala",
  HONDURAS = "Honduras",
  NICARAGUA = "Nicaragua",
  ESTADOS_UNIDOS = "EEUU",
  CENTRO_AMERICA = "Centro America",
}

export enum CentroAmericaCountriesEnum {
  ELSALVADOR = "ElSalvador",
  PANAMA = "Panama",
  COSTARICA = "CostaRica",
  NICARAGUA = "Nicaragua",
  HONDURAS = "Honduras",
  GUATEMALA = "Guatemala",
}

export enum DeferredCountriesEnum {
  BRAZIL = "Brazil",
  ECUADOR = "Ecuador",
  MEXICO = "Mexico",
  CENTROAMERICA = "CentroAmerica",
}

export const DeferredCountries: string[] = [
  DeferredCountriesEnum.CENTROAMERICA,
  DeferredCountriesEnum.ECUADOR,
  DeferredCountriesEnum.MEXICO,
  DeferredCountriesEnum.BRAZIL,
];
