import { makeStyles } from "@material-ui/core/styles";
import { Palette } from "../../themeui";

export const tableEndOptionsStyles = makeStyles({
  boxContainer: {
    display: "flex",
    columnGap: 15,
  },
  button: {
    backgroundColor: Palette.primary.light3,
  },
});
