import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTypedController } from "@hookform/strictly-typed";
import ConnectForm from "../commons/ConnectForm/ConnectForm";
import { ISecurityRuleForm } from "../../shared/infrastructure/interfaces/ISecurityRuleForm";
import {
  COUNTRY_VALUES,
  SecurityRulesSubtitleSection,
  SERVICE_VALUES,
} from "../../shared/infrastructure/constants/SecurityRulesConstants";
import { OptionImageSelection } from "../OptionImageSelection/OptionImageSelection";
import { MultipleSelectionComponent } from "../MultipleSelect/MultipleSelectComponent";
import { AutocompleteTextFieldComponent } from "../AutocompleteTextField/AutocompleteTextFieldComponent";
import { useWatch } from "react-hook-form";
import { get } from "lodash";
import { MerchantsData } from "../../store/actionCreator";
import { ErrorsEnum } from "../../shared/infrastructure/enums/ErrorEnum";

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: "14px",
  },
  fieldCountry: {
    marginTop: 10,
    minWidth: 250,
    paddingLeft: 1,
  },
  autocompleteTextField: {
    marginTop: 10,
  },
}));

export interface GeneralInformationSectionProps {
  checkedAllCountries: boolean;
  isLoadingMerchantList: boolean;
  isValidCard: boolean;
  isValidTransfer: boolean;
  isEdit: boolean;
  merchantList: MerchantsData;
  handleCheckedAllCountries: () => void;
  handleSetSearchTextMerchantList: (searchText: string) => void;
  handleEnterSearchTextMerchantList: (key: string) => void;
  setFormValue: (name: string, value: string) => void;
  setMerchantInformation: (value: object) => void;
}

export const GeneralInformationSection: React.FC<GeneralInformationSectionProps> =
  (props: GeneralInformationSectionProps) => {
    const classes = useStyles();

    return (
      <ConnectForm<ISecurityRuleForm>>
        {({ control, errors, getErrorMessage }) => {
          const TypedController = useTypedController<ISecurityRuleForm>({
            control: control,
          });
          const ruleType = useWatch({
            name: "type",
          }) as string;

          const countries = useWatch({
            name: "country",
          }) as string[];

          return (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className={classes.title}>
                      {SecurityRulesSubtitleSection.ALIAS_HEADER}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TypedController
                      name={["name"]}
                      rules={{ required: true }}
                      defaultValue={""}
                      render={(properties) => (
                        <TextField
                          {...properties}
                          id="name"
                          label={"Alias"}
                          variant="outlined"
                          error={!!errors.name}
                          fullWidth
                          helperText={
                            !!errors.name && getErrorMessage(errors.name)
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography className={classes.title}>
                      {SecurityRulesSubtitleSection.TYPE_HEADER}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <FormControl component="fieldset" fullWidth>
                      <TypedController
                        name="type"
                        defaultValue={"securityCommerce"}
                        render={(properties) => (
                          <RadioGroup row aria-label="type" {...properties}>
                            <FormControlLabel
                              disabled={props.isEdit}
                              value={"securityCommerce"}
                              control={<Radio color="primary" />}
                              label={
                                <Typography className={classes.title}>
                                  Comercio
                                </Typography>
                              }
                              labelPlacement="end"
                            />
                            <FormControlLabel
                              disabled={props.isEdit}
                              value={"securityCountry"}
                              control={<Radio color="primary" />}
                              label={
                                <Typography className={classes.title}>
                                  País
                                </Typography>
                              }
                              labelPlacement="end"
                            />
                          </RadioGroup>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  {ruleType === "securityCountry" ? (
                    <Grid item xs={12}>
                      <FormControl
                        variant={"outlined"}
                        className={classes.fieldCountry}
                        error={!!errors.country}
                      >
                        <InputLabel>
                          {SecurityRulesSubtitleSection.COUNTRY_HEADER}
                        </InputLabel>
                        <TypedController
                          name={["country"]}
                          rules={{ required: true }}
                          defaultValue={[]}
                          render={({ onChange, value }) => (
                            <MultipleSelectionComponent
                              key={"multipleSelectionCountry"}
                              property={"country"}
                              label={
                                SecurityRulesSubtitleSection.COUNTRY_HEADER
                              }
                              value={value}
                              currencyValues={countries}
                              checkAll={props.checkedAllCountries}
                              items={COUNTRY_VALUES}
                              onChange={onChange}
                              handleCheckedAll={props.handleCheckedAllCountries}
                            />
                          )}
                        />
                        <FormHelperText error={!!errors.country}>
                          {!!errors.country && ErrorsEnum.REQUIRED_FIELD}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={6}>
                      <TypedController
                        name={["merchantId"]}
                        rules={{ required: true }}
                        render={({ value }) => (
                          <AutocompleteTextFieldComponent
                            property={"merchantId"}
                            label={SecurityRulesSubtitleSection.MERCHANT_HEADER}
                            value={value}
                            options={get(props, "merchantList.data", [])}
                            pathValue={"_source.publicMerchantId"}
                            pathCountry={"_source.country"}
                            pathName={"_source.name"}
                            isLoading={props.isLoadingMerchantList}
                            handleEnterSearchText={
                              props.handleEnterSearchTextMerchantList
                            }
                            handleSetSearchText={
                              props.handleSetSearchTextMerchantList
                            }
                            setValue={props.setMerchantInformation}
                            disabled={props.isEdit}
                            error={!!errors.merchantId}
                            errorMessage={ErrorsEnum.SELECT_MERCHANT}
                            className={classes.autocompleteTextField}
                            showCountry={true}
                          />
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className={classes.title}>
                      {SecurityRulesSubtitleSection.SERVICE_HEADER}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl component="fieldset" fullWidth>
                      <TypedController
                        name={["service"]}
                        rules={{ required: true }}
                        // defaultValue={"card"}
                        render={({ onChange, value }) => (
                          <OptionImageSelection
                            property={"service"}
                            onChange={onChange}
                            value={
                              props.isValidCard || props.isValidTransfer
                                ? value
                                : undefined
                            }
                            disabled={props.isEdit}
                            items={[
                              {
                                ...SERVICE_VALUES[0],
                                valid: props.isValidCard,
                              },
                              {
                                ...SERVICE_VALUES[1],
                                valid: props.isValidTransfer,
                              },
                            ]}
                          />
                        )}
                      />
                      <FormHelperText error={!!errors.service}>
                        {!!errors.service && ErrorsEnum.SELECT_SERVICE}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        }}
      </ConnectForm>
    );
  };

export default GeneralInformationSection;
