import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IComment,
  ITransaction,
  ITransactionsState,
} from "../../interfaces/TransactionsState.interfaces";
import { SAMPLE_TRANSACTION } from "../../../shared/constants/sample_data";
import { getTransactionsIDs } from "../../../shared/utils";
import getTransactions from "../../thunks/transactions/getTransactions/getTransactions";
import esLocale from "date-fns/locale/es";
import { parse } from "date-fns";

// TODO: DELETE THIS WHEN GET THE IMPLEMENTATION OF TRANSACTIONS ENDPOINT QUERY IS FINISHED
const sampleTransactions = [...SAMPLE_TRANSACTION];

// TODO: REPLACE SAMPLE TRANSACTION AND TRANSACTION ARRAY WITH DEFAULT VALUES (null, [])
export const initialState: ITransactionsState = {
  areTransactionsLoading: false,
  currentActionTransaction: sampleTransactions[0],
  currentTransaction: sampleTransactions[0],
  isSelectedModalOpen: false,
  selectedTransactions: [],
  transactions: sampleTransactions,
};

export const transactionsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getTransactions.pending, (state) => {
      window.scroll({ behavior: "auto", top: 0 });
      state.areTransactionsLoading = true;
    });
    builder.addCase(
      getTransactions.fulfilled,
      (state, { payload }: PayloadAction<ITransaction[]>) => {
        state.transactions = payload;
        state.areTransactionsLoading = false;
      }
    );
    builder.addCase(getTransactions.rejected, (state) => {
      state.areTransactionsLoading = false;
      state.transactions = [];
    });
  },
  initialState,
  name: "transactions",
  reducers: {
    addComment: (state, { payload }: PayloadAction<IComment>) => {
      state.currentTransaction.comments = [
        ...state.currentTransaction.comments!,
        payload,
      ];
      let transaction = state.transactions.find(
        (transaction) => transaction.id === state.currentTransaction.id
      );

      transaction?.comments!.push(payload);
    },
    clearSelectedTrasactionTable: (state) => {
      state.selectedTransactions = [];
    },
    closeSelectedModal: (state) => {
      state.isSelectedModalOpen = false;
    },
    deselectAllCurrentTransactions: (state) => {
      const transactionsIDs = getTransactionsIDs(state.transactions);

      state.selectedTransactions = state.selectedTransactions.filter(
        (transaction) => !transactionsIDs.includes(transaction.id)
      );
    },
    orderSelectedTransactionTable: (state) => {
      state.selectedTransactions.sort(
        (currentTransaction, nextTransaction) =>
          parse(
            `${currentTransaction.date} ${currentTransaction.hour}`,
            "dd/MM/yyyy HH:mm",
            new Date(),
            { locale: esLocale }
          ).getTime() -
          parse(
            `${nextTransaction.date} ${nextTransaction.hour}`,
            "dd/MM/yyyy HH:mm",
            new Date(),
            { locale: esLocale }
          ).getTime()
      );
    },
    selectAllCurrentTransactions: (state) => {
      const selectedTransactionsIDs = getTransactionsIDs(
        state.selectedTransactions
      );

      state.selectedTransactions = [
        ...state.selectedTransactions,
        ...state.transactions.filter(
          (transaction) => !selectedTransactionsIDs.includes(transaction.id)
        ),
      ];
    },
    setCurrentActionTransaction: (state, { payload }) => {
      state.currentActionTransaction = payload;
    },
    setTransactionData: (state, { payload }) => {
      const foundTransaction = state.transactions.findIndex(
        (transaction) => transaction.id == payload.id
      );

      state.transactions[foundTransaction] = {
        ...state.transactions[foundTransaction],
        ...payload.data,
      };
    },
    toggleClickTransaction: (
      state,
      { payload }: PayloadAction<ITransaction>
    ) => {
      state.currentTransaction = payload;
      state.isSelectedModalOpen = true;
    },
    toggleTransactionSelection: (
      state,
      { payload }: PayloadAction<ITransaction>
    ) => {
      const selectedTransactionsIDs = getTransactionsIDs(
        state.selectedTransactions
      );

      if (selectedTransactionsIDs.includes(payload.id)) {
        state.selectedTransactions = state.selectedTransactions.filter(
          (transaction) => transaction.id !== payload.id
        );

        return;
      }

      state.selectedTransactions.push(payload);
    },
  },
});

export default transactionsSlice.reducer;
