import React, { useEffect, useState } from "react";
import {
  Box,
  createStyles,
  Grid,
  MuiThemeProvider,
  Paper,
  Typography,
} from "@material-ui/core";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import { InfoConfigurationProps } from "./ModalBodyTabs";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { RuleSchema } from "../../../types/remote/authentication_elastic";
import * as BUSINESS_RULES from "../../shared/infrastructure/constants/BusinessRulesConfigurationEnum";
import {
  IOptionSelection,
  RuleSchemaEnum,
} from "../../shared/infrastructure/constants/RuleRequestConstants";
import { get } from "lodash";
import { kindEnum } from "../../shared/infrastructure/enums/KindEnum";

const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 500,
      fontSize: 16,
    },
  },
});

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      boxShadow: "none !important",
      borderRadius: "0px !important",
      backgroundColor: "#F7FAFC !important",
      marginBottom: "30px !important",
    },
  })
);

const ModalBodyConfiguration = (props: InfoConfigurationProps) => {
  const [processorNames, setProcessorNames] = useState(
    Array(get(props, "trxInfo.auth_rules", "").length).fill("")
  );
  const [credentialNames, setCredentialNames] = useState(
    Array(get(props, "trxInfo.auth_rules", "").length).fill("")
  );

  const BUSINESS_RULES_GENERAL = Object.keys(BUSINESS_RULES).reduce(
    (objGlobal, variable) => {
      let objTemp: object = { ...BUSINESS_RULES[variable] };
      if (Array.isArray(BUSINESS_RULES[variable])) {
        objTemp = BUSINESS_RULES[variable].reduce(
          (obj: object, p: IOptionSelection) => ({
            ...obj,
            [p.name]: p.value,
          }),
          {}
        );
      }

      return { ...objGlobal, ...objTemp };
    },
    {}
  );

  const classes = useStyles();

  useEffect(() => {
    const processors = props.trxInfo?.auth_rules.map(
      (auth_rule: RuleSchema) => {
        if (!auth_rule.transactionProcessor) return "";

        const idx = props.processorData.findIndex(
          (v) =>
            v.publicProcessorId ===
            auth_rule.transactionProcessor!.split("|")[1]
        );
        const processor = props.processorData[idx];

        if (processor) return `${processor.processorName} - ${processor.alias}`;

        return `${auth_rule
          .transactionProcessor!.split("|")[1]
          .substring(0, 10)}...`;
      }
    );
    setProcessorNames((_: string[]) => [...processors]);
  }, [props.processorData]);

  useEffect(() => {
    const credentials = props.trxInfo?.auth_rules.map(
      (auth_rule: RuleSchema) => {
        if (!auth_rule.transactionCredential) return "";

        const idx = props.credentialData.findIndex(
          (v) =>
            v.credentialId === auth_rule.transactionCredential!.split("|")[1]
        );
        const credential = props.credentialData[idx];

        if (credential)
          return `${
            credential.alias || "Alias"
          }_${credential.publicCredentialId.substring(0, 6)}XXXXXX`;

        return `${auth_rule
          .transactionCredential!.split("|")[1]
          .substring(0, 10)}...`;
      }
    );
    setCredentialNames((_: string[]) => [...credentials]);
  }, [props.credentialData]);

  return (
    <React.Fragment>
      <Box pb={1}>
        <Paper className={classes.paper}>
          <Grid
            container
            spacing={1}
            color="secondary"
            style={{
              marginTop: 20,
              padding: "10px 20px",
            }}
          >
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <MuiThemeProvider theme={theme}>
                    <Typography variant="h6" color="textPrimary">
                      Datos generales
                    </Typography>
                  </MuiThemeProvider>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="body2" color="textSecondary">
                    Alias:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2">
                    {props.trxInfo?.auth_rules[0]?.name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="body2" color="textSecondary">
                    Nombre de proveedor:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2">
                    {props.trxInfo!.provider![0].toUpperCase() +
                      props.trxInfo!.provider!.substring(1)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="body2" color="textSecondary">
                    Tipo de autenticación:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2">{props.trxInfo!.kind}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {props.trxInfo?.auth_rules!.map((rule: RuleSchema, index: number) => (
          <Paper key={index} className={classes.paper}>
            <Grid
              container
              spacing={1}
              color="secondary"
              style={{
                marginTop: 20,
                padding: "10px 20px",
              }}
            >
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <MuiThemeProvider theme={theme}>
                      <Typography variant="h6" color="textPrimary">
                        Reglas de autenticación N°{index + 1}
                      </Typography>
                    </MuiThemeProvider>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary">
                      Condiciones generales
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {Object.keys(rule).map(
                (property, idx) =>
                  BUSINESS_RULES_GENERAL[property] &&
                  rule[property] && (
                    <Grid key={idx} item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Typography variant="body2">
                            {BUSINESS_RULES_GENERAL[property]}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body2">
                            {
                              BUSINESS_RULES_GENERAL[
                                rule[property].split("|")[0]
                              ]
                            }
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body2">
                            {property === RuleSchemaEnum.transactionProcessor
                              ? processorNames[index]
                              : property ===
                                RuleSchemaEnum.transactionCredential
                              ? credentialNames[index]
                              : property ===
                                RuleSchemaEnum.transactionIsCreditCard
                              ? BUSINESS_RULES_GENERAL[rule[property]!] ===
                                "true"
                                ? BUSINESS_RULES_GENERAL["credit"]
                                : BUSINESS_RULES_GENERAL["debit"]
                              : property ===
                                RuleSchemaEnum.transactionIsDeferred
                              ? BUSINESS_RULES_GENERAL[
                                  BUSINESS_RULES_GENERAL[rule[property]!]
                                ]
                              : rule[property].split("|")[1]}

                            {property ===
                              RuleSchemaEnum.transactionTotalAmount &&
                            rule[RuleSchemaEnum.transactionCurrency]
                              ? ` ${
                                  rule[
                                    RuleSchemaEnum.transactionCurrency
                                  ]!.split("|")[1]
                                }`
                              : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
              )}

              {rule["transactionCreated"] && (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Typography variant="body2">Horario</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <Typography variant="body2" color="textSecondary">
                            Desde
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="body2">
                            {
                              rule[RuleSchemaEnum.transactionCreated]!.split(
                                "|"
                              )[1].split(";")[0]
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <Typography variant="body2" color="textSecondary">
                            Hasta
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="body2">
                            {
                              rule[RuleSchemaEnum.transactionCreated]!.split(
                                "|"
                              )[1].split(";")[1]
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {rule[RuleSchemaEnum.transactionStatusSum] && (
                <Grid item xs={12}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="body2" color="textSecondary">
                          Condiciones de velocidad
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid key={index} item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Typography variant="body2">Sumar</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body2">
                          {
                            BUSINESS_RULES_GENERAL[
                              rule[RuleSchemaEnum.periodicitySum]!
                            ]
                          }
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body2">
                          {
                            BUSINESS_RULES_GENERAL[
                              rule[RuleSchemaEnum.transactionStatusSum]!
                            ]
                          }
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body2">
                          {rule[RuleSchemaEnum.maxAmount]!}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {rule[RuleSchemaEnum.transactionStatusCount] && (
                <Grid item xs={12}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="body2" color="textSecondary">
                          Condiciones de velocidad
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid key={index} item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Typography variant="body2">{"Contar"}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body2">
                          {
                            BUSINESS_RULES_GENERAL[
                              rule[RuleSchemaEnum.periodicityCount]!
                            ]
                          }
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body2">
                          {
                            BUSINESS_RULES_GENERAL[
                              rule[RuleSchemaEnum.transactionStatusCount]!
                            ]
                          }
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body2">
                          {rule[RuleSchemaEnum.maxAmount]!}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Paper>
        ))}

        {get(props.trxInfo, "kind", "") === kindEnum["3DS"] ? (
          <Paper className={classes.paper}>
            <Grid
              container
              spacing={1}
              color="secondary"
              style={{
                marginTop: 20,
                padding: "10px 20px",
              }}
            >
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <MuiThemeProvider theme={theme}>
                      <Typography variant="h6" color="textPrimary">
                        Configuración adicional
                      </Typography>
                    </MuiThemeProvider>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={1}>
                    {props.trxInfo?.ignore_3DS ? (
                      <CheckIcon
                        style={{ marginTop: "-4px", color: "#0DC298" }}
                      />
                    ) : (
                      <CloseIcon
                        style={{ marginTop: "-4px", color: "#AD0C2A" }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={11}>
                    <Typography variant="body2">
                      Asumir riesgo transaccional
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <Grid
            container
            style={{
              marginTop: 20,
            }}
          />
        )}
      </Box>
    </React.Fragment>
  );
};

export default ModalBodyConfiguration;
