import { useSnackbar } from "@kushki/connect-ui";
import { format } from "date-fns";
import { defaultTo, get, isEmpty, isNil, map, set } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { FieldError, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { Configs } from "../../../../types/get_node_info_response";
import { MerchantData } from "../../../../types/merchant-data";
import { ILabel } from "../../../components/FooterOptions/FooterOptions.interfaces";
import { ConfigIdEnum } from "../../constants/initial_state_legal_details";
import { CountryEnum } from "../../countryEnum";
import {
  BasicDataByCountry,
  europeCountriesPSPCatalog,
} from "../../enum/BasicDataByCountry";
import { docTypeEC } from "../../enum/BasicStateEC/FieldsBasicDataEC";
import { PersonTypeObjectInterface } from "../../enum/BasicStateGeneral/FieldsBasicData";
import {
  IShowGenericInputs,
  valueFormGenericEnum,
} from "../../enum/BasicStateGeneric/CatalogDataGeneric";
import { DateFormatEnum } from "../../enum/DateFormatEnum";
import { TabItemStatusEnum } from "../../enum/tabItemStatusEnum";
import { ActionFooterEnum } from "../../enums/ActionFooterEnum";
import { useMerchantCompanyInfo } from "../../hooks/merchantCompanyInfo/useMerchantCompanyInfo";
import { BasicDataInterface } from "../../interfaces/BasicData.interface";
import {
  transformDateToTimestamp,
  transformUnixTimestampToFormatDate,
} from "../date_utils";
import { findDocTypeValue } from "../findDocTypeCountry_utils";
import {
  setButtonContinueFooter,
  setButtonSaveFooter,
  setHourComputer,
  setIsErrorSave,
  setIsSaveButton,
  setNotification,
  setTextSave,
  setTypeButton,
} from "../../../store/actions/basicDataForm.action";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { RootState } from "../../../store/store";
import {
  hierarchyNode,
  saveBasicDataMerchant,
  saveMerchantInterface,
} from "../../../store/thunks/basicDataForm/basicDataForm.thunks";
import { getMerchantInfo } from "../../../store/thunks/general/general.thunk";

import { validCountries } from "../../constants/validCountries";
import { EntityTypes } from "../../enum/EntityTypeEnum";
import { setCompanyTypeGeneric } from "../setCompanyTypeGeneric";
import {
  BuildObjectToSave,
  createSrRequest,
  docTypeTaxIdCountry,
  setSaveByFormValid,
  TaxIdRulesCountry,
  validateDataFields,
} from "../basic_data_utils";
import {
  ILegalRepresentative,
  IMerchantPeopleNodeRequest,
  IShareHolder,
  ISocialReasonRequest,
} from "../../../store/interfaces/LegalDetailsState.interfaces";
import {
  getCompliancePeople,
  saveLegalDetails,
} from "../../../store/thunks/legalDetails/legalDetails.thunk";
import {
  setLegalRepresentativesArray,
  setNaturalPersonLastName,
  setNaturalPersonName,
  setSaveLegalDetailsError,
  setShareHolder,
} from "../../../store/reducers/legalDetails/legalDetails";
import { IUseBasicFormStateContainer } from "../../interfaces/useBasicFormStateContainer.interfaces";
import {
  PersonType_Generic_Enum,
  PersonTypeCatalog,
} from "../../enum/BasicStateGeneral/PersonType";
import { isCentralizedConfig } from "../isCentralizedConfig";
import { EntityNames } from "../../enum/ContactDataStateMX/ContactDataLabelMX";
import { searchParams } from "../useQuery_utils";
import { fieldsRenderPSPUSA } from "../../enum/BasicStateUSA/FieldsBasicDataPSPUSA";
import { ClientType } from "../../enums/ClientTypeEnum";
import { fieldsRenderGenericPSP } from "../../enum/BasicStateGeneric/FieldBasicDataGenericPSP";
import { getListCatalogs } from "../../../store/thunks/catalogs/catalogs.thunk";
import { CountryCodeEnum } from "../../enums/CountryCodeEnum";
import { ConfigCodeEnum } from "../../enums/ConfigCodeEnum";
import { ProductLineEnum } from "../../enums/ProductLineEnum";
import { Category } from "../../enum/BasicStateGeneral/CatalogBasicData";
import { getCatalogByName } from "../listCatalogUtils";
import { Data } from "../../../../types/catalog_response";
import { TypeCatalog } from "../../enum/BasicStateGeneral/ErrorMessage";

export const useBasicFormStateContainerGeneral = (
  isMassive: boolean
): IUseBasicFormStateContainer => {
  const { showSnackbar } = useSnackbar();
  const query = searchParams();
  const dispatch = useAppDispatch();
  const {
    merchantNodeInfo,
    nodeInfo: nodeSingle,
    continueButton,
    saveButton,
    modalOpen,
    textSave,
    isSaveButton,
    hourComputer,
    notification,
    typeButton,
    configsInfo,
    nodeInfoMassiveBasicData,
    isLoadingBasicInformation,
    isErrorSave,
  } = useAppSelector((state: RootState) => ({
    ...state.basicDataForm,
    ...state.initialData,
  }));

  const { compliancePeopleNode } = useAppSelector((state: RootState) => ({
    ...state.legalDetails,
  }));

  const { listMccCatalog, isLoading } = useAppSelector(
    (state: RootState) => state.catalogs
  );

  const nodeInfo = isMassive ? nodeInfoMassiveBasicData : nodeSingle;

  const isFirstMCC = useRef(true);
  const isFirstRunDate = useRef(true);
  const isFirstTaxID = useRef<string>("");
  const typeOfPerson = useRef<string>("");

  const { countriesInfo, headerProps, handleChangeStatusNodeInfo } =
    useMerchantCompanyInfo(isMassive);

  const history = useHistory();
  const footerLabel: ILabel = {
    date: format(
      get(configsInfo, "cn001.updatedAt", 0),
      "dd/MM/yyyy '-' HH:mm"
    ),
    editDetail: false,
    isHidden: true,
    text: "Modificado por " + get(configsInfo, "cn001.updatedBy", ""),
  };

  const operationCountry = get(merchantNodeInfo, "country");

  const constitutionalCountry =
    isEmpty(get(merchantNodeInfo, "constitutionalCountry")) ||
    isNil(get(merchantNodeInfo, "constitutionalCountry")) ||
    !validCountries.includes(get(merchantNodeInfo, "constitutionalCountry"))
      ? CountryEnum.generic
      : get(merchantNodeInfo, "constitutionalCountry");

  const getDataCountry = () => {
    const countryElements = BasicDataByCountry[constitutionalCountry];
    let mccList: Category[] = [];

    if (listMccCatalog.length != 0) {
      const mccCatalog: Data[] = getCatalogByName(
        listMccCatalog,
        TypeCatalog.MCC
      );

      mccList = mccCatalog.map((info: Data) => ({
        id: info.code,
        label: info.value,
      }));

      countryElements.mcc = mccList;
    }

    const isPSP: boolean = merchantNodeInfo.clientType === ClientType.PSP;
    const isPSPEuropeCountry: boolean = europeCountriesPSPCatalog.includes(
      get(merchantNodeInfo, "constitutionalCountry", "") as CountryEnum
    );
    const isUSA: boolean = constitutionalCountry === CountryEnum.usa;

    if (isPSP && (isUSA || isPSPEuropeCountry)) {
      return {
        ...countryElements,
        fieldsRender: isUSA ? fieldsRenderPSPUSA : fieldsRenderGenericPSP,
      };
    }

    return countryElements;
  };

  const countryElements = useMemo(
    () => getDataCountry(),
    [get(merchantNodeInfo, "constitutionalCountry"), listMccCatalog]
  );

  const basicConfig: Configs = get(configsInfo, "cn001");

  const [isNewUser, setNewuser] = useState<boolean>(true);

  const [isDisable, setIsDisable] = useState<boolean>(false);

  const [showGenericInput, setShowGenericInput] = useState<IShowGenericInputs>({
    companyType: false,
    economicActivityGeneric: false,
  });

  const def = "Default";

  const [auxRules, setRules] = useState<object>({});

  const countryTaxId = [
    CountryEnum.ecuador,
    CountryEnum.honduras,
    CountryEnum.guatemala,
    CountryEnum.usa,
  ];

  const publicMerchantId: string = query.publicMerchantId;
  const isEdit: boolean = query.isEdit;
  const getTaxIdSpecialCountry = (): string => {
    const docsTypesCountriesList: object[] = get(
      docTypeTaxIdCountry(),
      `[${constitutionalCountry}].docType`,
      []
    );

    const docTypeIdList: string[] = map(docsTypesCountriesList, "id");

    return (
      docTypeIdList.find(
        (id: string) => id === get(merchantNodeInfo, "documentType", "")
      ) || ""
    );
  };

  const getEditFormValues = (): BasicDataInterface => {
    const typeOfTaxIdEC: PersonTypeObjectInterface = findDocTypeValue(
      docTypeEC,
      get(merchantNodeInfo, "documentType", "2")
    );

    return {
      companyRegistrationNumber: get(
        merchantNodeInfo,
        "companyRegistrationNumber",
        ""
      ),
      date: transformUnixTimestampToFormatDate(
        get(merchantNodeInfo, "constitutionDate", new Date().getTime()),
        DateFormatEnum.DD_MMM_YYYY
      ),
      economyActivity: get(merchantNodeInfo, "economicActivityId", ""),
      mcc: get(merchantNodeInfo, "mcc", ""),
      names: get(merchantNodeInfo, "naturalPerson.name", ""),
      rfc: get(merchantNodeInfo, "taxId", ""),
      socialReason: get(merchantNodeInfo, "socialReason", ""),
      surname: get(merchantNodeInfo, "naturalPerson.lastname", ""),
      typeCompany: get(merchantNodeInfo, "companyType", ""),
      typeIndustry: get(merchantNodeInfo, "industryType", ""),
      typeOfTaxId:
        constitutionalCountry == CountryEnum.ecuador
          ? typeOfTaxIdEC.name
          : countryTaxId.includes(constitutionalCountry as CountryEnum)
          ? getTaxIdSpecialCountry()
          : "",
      typePerson: get(merchantNodeInfo, "merchantType", ""),
      web: get(merchantNodeInfo, "webSite", ""),
    };
  };

  const form = useForm<BasicDataInterface>({
    defaultValues: {
      typePerson: get(merchantNodeInfo, "merchantType", "2"),
    },
    mode: "all",
    reValidateMode: "onChange",
  });

  const isComplete: boolean = useMemo(
    () => basicConfig.status === TabItemStatusEnum.COMPLETE,
    [basicConfig.status]
  );

  const newBasicData = () => {
    let userValue: boolean = isEmpty(basicConfig.value);

    setNewuser(userValue);

    return userValue;
  };

  const validateEditBranch = () => {
    if (
      !isEmpty(get(basicConfig, "centralizedNodesId", "")) &&
      get(nodeInfo, "entityName", "") === EntityTypes.BRANCH
    ) {
      setIsDisable(true);
    }
  };

  const isTaxIdChange = (): boolean => {
    return merchantNodeInfo.taxId !== form.getValues("rfc");
  };

  const createMerchantSave = (): MerchantData => {
    const objectToSave: MerchantData = {
      configId: ConfigIdEnum.CN001,
      constitutionalCountry: get(merchantNodeInfo, "constitutionalCountry"),
      constitutionDate: transformDateToTimestamp(
        form.getValues("date"),
        DateFormatEnum.DD_MMM_YYYY
      ),
      country: validCountries.includes(get(merchantNodeInfo, "country"))
        ? operationCountry
        : get(merchantNodeInfo, "country"),
      economicActivityId: form.getValues("economyActivity"),
      industryType: form.getValues("typeIndustry"),
      mcc: form.getValues("mcc"),
      merchantType: form.getValues("typePerson")!,
      publicMerchantId,
      taxId: form.getValues("rfc"),
      webSite: form.getValues("web"),
    };
    const typePerson = form.getValues("typePerson");

    const isEuropePSP: boolean = europeCountriesPSPCatalog.includes(
      get(merchantNodeInfo, "constitutionalCountry")
    );

    return get(
      BuildObjectToSave(
        objectToSave,
        form,
        showGenericInput,
        merchantNodeInfo.clientType,
        isEuropePSP
      ),
      `[${constitutionalCountry}][${typePerson}]`,
      {}
    );
  };

  const constructRequestCompliance = (
    merchantData: MerchantData
  ): IMerchantPeopleNodeRequest => {
    const socialReason: ISocialReasonRequest = createSrRequest(merchantData);

    return {
      boardmembers: [],
      constitutionalCountry: get(
        nodeInfo,
        "generalInfo.constitutionalCountry",
        ""
      ),
      country: get(nodeInfo, "generalInfo.country", ""),
      legalRepresentatives: [],
      merchantId: isMassive
        ? get(nodeInfo, "merchantId", "")
        : publicMerchantId,
      merchantName: get(nodeInfo, "generalInfo.name", ""),
      merchantStatus: get(nodeInfo, "generalInfo.status", ""),
      shareholders: [],
      socialReason: socialReason,
    };
  };
  const getStatus = (typeButton: string) => {
    if (typeButton === ActionFooterEnum.CONTINUE || form.formState.isValid)
      return TabItemStatusEnum.COMPLETE;
    else return TabItemStatusEnum.PENDING;
  };

  const saveCn0015 = (status: boolean) => {
    status &&
      handleChangeStatusNodeInfo(TabItemStatusEnum.PENDING, ConfigIdEnum.CN015);
  };

  function isDifferentPersonType(): boolean {
    const merchantTypeNodeInfo: string = get(
      merchantNodeInfo,
      "merchantType",
      ""
    );

    const formPersonType: string = defaultTo(
      form.getValues("typePerson"),
      PersonType_Generic_Enum.JURIDICA
    );

    return (
      PersonTypeCatalog.includes(merchantTypeNodeInfo) &&
      merchantTypeNodeInfo !== formPersonType
    );
  }

  const saveLegalState = async (
    repArra: ILegalRepresentative[],
    share: IShareHolder[]
  ) => {
    await dispatch(setLegalRepresentativesArray(repArra));
    await dispatch(setShareHolder(share));
  };

  const saveLegalDataDetails = async () => {
    const objectToSave: MerchantData = createMerchantSave();
    const legalDetails: IMerchantPeopleNodeRequest =
      constructRequestCompliance(objectToSave);

    if (isDifferentPersonType()) {
      legalDetails.legalRepresentatives = [];
      legalDetails.shareholders = [];

      await saveLegalState([], []);
      dispatch(
        hierarchyNode({
          configs: [
            {
              configuration: ConfigIdEnum.CN015,
              status: TabItemStatusEnum.PENDING,
              value: publicMerchantId,
            },
          ],
          nodeId: nodeInfo!.nodeId!,
        })
      );
      await dispatch(saveLegalDetails(legalDetails));
    } else {
      set(
        legalDetails,
        "legalRepresentatives",
        compliancePeopleNode.legalRepresentatives
      );
      set(legalDetails, "shareholders", compliancePeopleNode.shareholders);
      await dispatch(saveLegalDetails(legalDetails));
    }

    await dispatch(setSaveLegalDetailsError(undefined));
  };

  const resetFields = async (typeButton: string) => {
    await saveLegalDataDetails();
    await handleChangeStatusNodeInfo(
      typeButton === ActionFooterEnum.CONTINUE
        ? TabItemStatusEnum.COMPLETE
        : form.formState.isValid
        ? TabItemStatusEnum.COMPLETE
        : TabItemStatusEnum.IN_PROCESS,
      ConfigIdEnum.CN001,
      publicMerchantId
    );

    await dispatch(setTypeButton("NONE"));

    await dispatch(setIsErrorSave(undefined));

    if (typeButton === ActionFooterEnum.CONTINUE) {
      await saveCn0015(isDifferentPersonType());

      await history.push(headerProps.tabsNodeInfo.tabs[1].redirectPath);
    }

    if (typeButton === ActionFooterEnum.SAVE) {
      await saveCn0015(isDifferentPersonType());
    }
  };

  const saveValues = async (typeButton: ActionFooterEnum) => {
    await dispatch(setTypeButton(typeButton));
    const names: string = form.getValues("names") ?? "";
    const lastnames: string = form.getValues("surname") ?? "";

    if (
      !isEmpty(form.getValues("names")) &&
      !isEmpty(form.getValues("surname"))
    ) {
      dispatch(setNaturalPersonName(names));
      dispatch(setNaturalPersonLastName(lastnames));
    }

    if (typeButton === ActionFooterEnum.SAVE) {
      dispatch(setIsSaveButton(true));
    }

    const objectToSave: MerchantData = createMerchantSave();
    const basicData: saveMerchantInterface = {
      configuration: ConfigIdEnum.CN001,
      country: operationCountry,
      isNewUser: isNewUser,
      isTaxIdChange: isTaxIdChange(),
      nodeId: nodeInfo!.nodeId!,
      objectToSave,
      publicMerchantId: publicMerchantId,
      status: getStatus(typeButton),
      taxId: form.getValues("rfc"),
      updatedAt: Date.now(),
      updatedBy: localStorage.getItem("username")!,
    };

    dispatch(saveBasicDataMerchant(basicData));
  };

  const changePersonUSA = () => {
    const isConstitutionalCountryUSA: boolean =
      constitutionalCountry === CountryEnum.usa;
    const isAbleChange: boolean =
      isConstitutionalCountryUSA &&
      ((typeOfPerson.current != "" && !isNewUser) ||
        form.getValues("typePerson") === PersonType_Generic_Enum.JURIDICA ||
        (isNewUser && !isNil(get(merchantNodeInfo, "merchantType"))));

    const isChangePerson: boolean =
      form.getValues("typePerson") !== typeOfPerson.current;

    if (isAbleChange && isChangePerson) {
      form.setValue("rfc", "");
      form.clearErrors("rfc");
    }
  };

  useEffect(() => {
    if (isMassive) return;

    if (!defaultTo(isErrorSave, true)) {
      resetFields(typeButton);

      return;
    }
    if (isErrorSave) {
      dispatch(setTypeButton("NONE"));
      dispatch(setIsErrorSave(undefined));

      return;
    }
  }, [isErrorSave]);

  useEffect(() => {
    if (isFirstMCC.current) {
      isFirstMCC.current = false;
      form.setValue("economyActivity", "");
      form.clearErrors("economyActivity");

      return;
    }
  }, [form.watch("economyActivity")]);

  useEffect(() => {
    dispatch(setButtonContinueFooter(!form.formState.isValid));
    hierarchyNode({
      configs: [
        {
          configuration: ConfigIdEnum.CN001,
          value: publicMerchantId,
        },
      ],
      nodeId: isMassive
        ? get(nodeInfoMassiveBasicData, "nodeId", "")
        : nodeInfo!.nodeId!,
    });
  }, [form.watch("typePerson")]);

  useEffect(() => {
    if (isMassive) return;

    const merchantId: string = !isEmpty(
      get(basicConfig, "centralizedNodesId", "")
    )
      ? get(basicConfig, "value", "")
      : publicMerchantId;

    if (nodeInfo.nodeId) {
      dispatch(
        getMerchantInfo({
          configId: ConfigIdEnum.CN001,
          publicMerchantId: merchantId,
        })
      );
    }

    newBasicData();
    validateEditBranch();
    if (get(nodeSingle, "entityName") === EntityNames.BRANCH) {
      dispatch(
        setButtonContinueFooter(
          isCentralizedConfig(nodeSingle, ConfigIdEnum.CN001)
        )
      );
    }
  }, [nodeInfo]);

  useEffect(() => {
    const legalConfig = get(configsInfo, `${ConfigIdEnum.CN015}`);

    if (
      !isEmpty(legalConfig) &&
      !isEmpty(legalConfig.value) &&
      legalConfig.value != "1"
    ) {
      const merchantId = legalConfig.value!;

      dispatch(getCompliancePeople(merchantId));
    }
  }, [configsInfo.cn015]);

  useEffect(() => {
    if (!newBasicData()) {
      form.reset({ ...getEditFormValues() });
    }
  }, [merchantNodeInfo]);

  useEffect(() => {
    if (!form.formState.isDirty && isNewUser)
      dispatch(setButtonSaveFooter(true));
    else if (isComplete) setSaveByFormValid(form, dispatch);
    else dispatch(setButtonSaveFooter(false));
  }, [isNewUser, form.formState.isDirty]);

  useEffect(() => {
    if (!isSaveButton) {
      setTimeout(() => {
        dispatch(setTextSave(false));
      }, 3000);
      dispatch(setHourComputer(format(new Date(), "dd/MM/yyyy '-' HH:mm")));
    }
  }, [isSaveButton]);

  useEffect(() => {
    if (notification) {
      showSnackbar(notification);
      dispatch(setNotification(undefined));
    }
  }, [notification]);

  useEffect(() => {
    if (
      isFirstTaxID.current &&
      [CountryEnum.ecuador, CountryEnum.usa].includes(constitutionalCountry)
    ) {
      if (form.getValues("typeOfTaxId") !== isFirstTaxID.current) {
        form.setValue("rfc", "");
        form.clearErrors("rfc");
      }
    }

    isFirstTaxID.current = form.getValues("typeOfTaxId") || "";

    if (form.getValues("typeOfTaxId") !== "") {
      const taxId = form.getValues("typeOfTaxId");

      if (countryTaxId.includes(constitutionalCountry as CountryEnum)) {
        setRules(
          get(TaxIdRulesCountry(), `[${constitutionalCountry}][${taxId}]`)
        );
      }
    } else {
      setRules(
        get(TaxIdRulesCountry(), `[${constitutionalCountry}][${def}]`, {})
      );
    }
  }, [form.watch("typeOfTaxId"), constitutionalCountry]);

  useEffect(() => {
    if (isFirstRunDate.current) {
      isFirstRunDate.current = false;

      return;
    }

    if (form && form.watch("rfc") != "") {
      form.clearErrors("rfc");
      form.trigger("rfc");
    }
  }, [auxRules]);

  useEffect(() => {
    if (
      form.getValues("typeCompany") !== "" &&
      !validCountries.includes(constitutionalCountry)
    ) {
      if (!isNil(get(merchantNodeInfo, "constitutionalCountry")))
        setCompanyTypeGeneric(form, merchantNodeInfo.companyType!);
    }
  }, [form.watch("typeCompany"), merchantNodeInfo]);
  useEffect(() => {
    if ([CountryEnum.brasil].includes(constitutionalCountry as CountryEnum)) {
      const reduxMerchantNode = get(merchantNodeInfo, "merchantType", "");
      const currentMerchantNode = form.getValues("typePerson");

      if (
        reduxMerchantNode !== currentMerchantNode &&
        !isEmpty(reduxMerchantNode)
      ) {
        form.setValue("rfc", "");
      } else {
        form.setValue("rfc", get(merchantNodeInfo, "taxId", ""));
      }
      form.clearErrors("rfc");
    }
  }, [form.watch("typePerson"), merchantNodeInfo.merchantType]);
  useEffect(() => {
    if (form.getValues("typeCompany") === valueFormGenericEnum.OTRA) {
      setShowGenericInput((prevState) => ({ ...prevState, companyType: true }));
    } else {
      setShowGenericInput((prevState) => ({
        ...prevState,
        companyType: false,
      }));
    }
  }, [form.watch("typeCompany"), form.watch("typeCompanyGeneric")]);

  useEffect(() => {
    if (form.getValues("economyActivity") === valueFormGenericEnum.OTRA) {
      setShowGenericInput((prevState) => ({
        ...prevState,
        economicActivityGeneric: true,
      }));
    } else {
      setShowGenericInput((prevState) => ({
        ...prevState,
        economicActivityGeneric: false,
      }));
    }
  }, [form.watch("economyActivity")]);

  const rfcError: FieldError | undefined = useMemo(
    () => form.formState.errors.rfc,
    [form.formState.errors.rfc]
  );

  useEffect(() => {
    if (isEmpty(form.getValues("rfc")) || rfcError)
      dispatch(setButtonSaveFooter(true));
    else if (isComplete) setSaveByFormValid(form, dispatch);
    else dispatch(setButtonSaveFooter(false));
  }, [form.getValues("rfc"), rfcError]);

  useEffect(() => {
    const isConstitutionalCountryUSA: boolean =
      constitutionalCountry === CountryEnum.usa;

    changePersonUSA();

    if (
      form.getValues("typePerson") === PersonType_Generic_Enum.NATURAL &&
      form.getValues("typeOfTaxId") === "1" &&
      isConstitutionalCountryUSA
    ) {
      form.setValue("typeOfTaxId", "");
    }
    if (isFirstTaxID.current)
      typeOfPerson.current = form.getValues("typePerson") || "";
  }, [form.watch("typePerson")]);

  useEffect(() => {
    if (!isCentralizedConfig(nodeSingle, ConfigIdEnum.CN001)) {
      dispatch(setButtonContinueFooter(!form.formState.isValid));
    }

    if (isComplete) setSaveByFormValid(form, dispatch);
    else validateDataFields(form, dispatch);
  }, [form.formState.isValid]);

  useEffect(() => {
    if (listMccCatalog.length === 0 && !isLoading) {
      dispatch(
        getListCatalogs({
          configCode: ConfigCodeEnum.BASIC_DATA,
          countryCode: CountryCodeEnum.GLB,
          productLine: ProductLineEnum.PLA,
          typeCatalog: TypeCatalog.MCC,
        })
      );
    }
  });

  return {
    auxRules,
    compliancePeopleNode,
    constructRequestCompliance,
    continueButton,
    countriesInfo: countriesInfo,
    countryElements,
    footerLabel: !isEmpty(get(configsInfo, `${ConfigIdEnum.CN001}.updatedBy`))
      ? footerLabel
      : {
          date: "",
          editDetail: false,
          isHidden: true,
          text: "",
        },
    form,
    handleChangeStatusNodeInfo,
    headerProps: headerProps,
    hourComputer,
    isDisable,
    isEdit,
    isLoadingBasicInformation: isLoadingBasicInformation || isLoading,
    merchantNodeInfo,
    modalOpen,
    newBasicData,
    nodeInfo,
    saveButton,
    saveValues,
    showGenericInput,
    textSave,
    typeButton,
    validateEditBranch,
  };
};
