import { CountriesEnum } from "../../shared/enums/countriesEnum";
import { ComponentIdsEnum } from "../../shared/constants/labels/main_containter_labels";

export interface SecurityObject {
  charge: {
    footer: {
      primary: string;
      secondary: string;
    };
  };
  [k: string]: any;
}

export const SecurityWrapperByCountry: Record<string, SecurityObject> = {
  [CountriesEnum.ECUADOR]: {
    charge: {
      footer: {
        primary: ComponentIdsEnum.M_CHARGE_FOOTER_PRIMARY,
        secondary: ComponentIdsEnum.M_CHARGE_FOOTER_SECONDARY,
      },
    },
  },
};
