import { useEffect, useState } from "react";
import {
  IButtonOptions,
  ILabel,
} from "../../../components/FooterOptions/FooterOptions.interfaces";
import {
  IUseMainContainer,
  ServicesAdvancedList,
} from "./useMainContainer.interfaces";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import {
  getMerchantCardData,
  getMerchantCommissionData,
  getMerchantSmartlinkData,
  getMerchantSubscriptionData,
  getMerchantSubscriptionDataSandbox,
  getMerchantVPOSData,
  getNodeInfo,
  getPaymentCredentials,
  getProcessors,
  setDefaultServiceConfig,
  setMassiveServiceConfig,
  updateHierarchyNodeConfig,
} from "../../../store/thunks/general/general.thunk";
import { RootState } from "../../../store/store";
import {
  EntityNameEnum,
  NodeConfigEnum,
} from "../../../shared/enums/nodeConfigsEnum";
import { convertCountryName } from "../../../shared/utils/convertCountryName";
import {
  CardContent,
  RowContent,
  RowValues,
} from "../../../components/ConfigDataCard/ConfigDataCard.interfaces";
import {
  CONFIGURE_REDIRECT,
  CONFIGURE_REDIRECT_BATCH,
  VIEW_BRANCHES_REDIRECT,
} from "../../../shared/constants/routes";
import { useSearchParams } from "react-router-dom";
import { concat, defaultTo, get, has, isEmpty, omit, reduce } from "lodash";
import { PaymentCredential } from "../../../../types/payment_credential";
import { ProcessorSettings } from "../../../../types/processor_settings";
import { ServiceConfigEnum } from "../../../shared/enums/ServiceConfigEnum";
import { cardBrands } from "../../../shared/constants/cardBrands";
import {
  listBranchesSelected,
  merchantIdLocalStorage,
  merchantInfoLocalStorage,
  merchantLocalStorage,
  setLocalStorageObject,
} from "../../../shared/utils/localStorageUtils";
import { ServiceDefaultResponse } from "../../../../types/service_default_response";
import { CardData } from "../../../../types/card_response";
import { useSnackbar } from "@kushki/connect-ui";
import {
  setIsMassiveDefault,
  setMassiveInformation,
  setNotification,
  setRedirectToBranchList,
} from "../../../store/actions/configData.actions";
import { StatusStepEnum } from "../../../shared/enums/StatusStepEnum";
import { Configs } from "../../../../types/node_info_response";
import { redirectUtil } from "../../../shared/utils/redirectUtil";
import { OriginEnum } from "../../../shared/enums/originEnum";
import { CountriesEnum } from "../../../shared/enums/countriesEnum";
import {
  defaultMassiveConfig,
  getCustomerRequestInfo,
  getMassiveConfig,
  getSelectedBranches,
  isInvalidMassiveConfig,
  isMassivePath,
} from "../../../shared/utils/massiveConfigUtils";
import { isUndefined } from "webpack-merge/dist/utils";
import { MassiveServiceRequest } from "../../../../types/massive_service_request";
import {
  MassiveServiceConfiguration,
  SelectedCard,
} from "../../../../types/massive_service_configuration";
import { IModalDialog } from "../../../components/ModalDialog/ModalDialog.interfaces";
import { MASSIVE_SERVICES_MODAL } from "../../../shared/constants/modalInfo";
import { MassiveConfigPathEnum } from "../../../shared/enums/MassiveConfigPathEnum";
import { LabelEnum } from "../../../shared/enums/LabelEnum";
import { LocalStoragePathEnum } from "../../../shared/enums/LocalStoragePathEnum";

export const useMainContainer = (): IUseMainContainer => {
  const dispatch = useAppDispatch();
  const { showSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const isMassiveConfig: boolean = isMassivePath();
  const massiveConfig: MassiveServiceConfiguration = getMassiveConfig();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalInfo, setModalInfo] = useState<Partial<IModalDialog>>({});
  const [privateCredentialId, setPrivateCredentialId] = useState("");
  const [cardContent, setCardContent] = useState<CardContent>({ content: [] });
  const [redirectPathHeader, setRedirectHeader] = useState<string>("");
  const [isServiceConfigured, setIsServiceConfigured] =
    useState<boolean>(false);
  const [isServiceOmitted, setIsServiceOmitted] = useState<boolean>(false);
  const [isCentralizedBranch, setIsCentralizedBranch] =
    useState<boolean>(false);
  const [rowValuesAdvanced, setRowValuesAdvanced] = useState<RowValues[]>([]);
  const [rowValuesBasic, setRowValuesBasic] = useState<RowValues[]>([]);
  const [primaryButton, setPrimaryButton] = useState<IButtonOptions>({
    isDisabled: false,
    isHidden: false,
    onAction: undefined,
  });
  const [secondaryButton, setSecondaryButton] = useState<IButtonOptions>({
    isDisabled: false,
    isHidden: true,
    onAction: () => {
      setOpenModal(true);
    },
  });

  const [hideEditBtn, setHideEditBtn] = useState(false);
  const [publicMerchantId] = useState(
    defaultTo(searchParams.get("publicMerchantId"), "")
  );
  const [servicesAdvancedList, setServicesAdvancedList] =
    useState<ServicesAdvancedList>({});

  const {
    cardData,
    commissionData,
    defaultServiceConfig,
    merchantSettings,
    paymentCredentials,
    smartlinkData,
    vposData,
    subscriptionData,
    subscriptionDataSandbox,
    nodeInfo,
    notification,
    isMassiveDefault,
    isLoading,
    massiveInformation: { listBranches, title },
  } = useAppSelector((state: RootState) => state.configData);

  const [configureButton, setConfigureButton] = useState<IButtonOptions>({
    isDisabled: false,
    isHidden: false,
    onAction: undefined,
  });

  const footerLabel: ILabel = {
    editDetail: false,
    isHidden: true,
    text: "",
  };

  const handleConfigButton = () => {
    redirectUtil(
      isMassiveConfig ? CONFIGURE_REDIRECT_BATCH : CONFIGURE_REDIRECT
    );
  };

  const redirectPathSubHeader = (publicMerchantId: string) => {
    return `/create-node/customer?publicMerchantId=${publicMerchantId}`;
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const getOverviewBasicSettings = (
    massiveConfig: MassiveServiceConfiguration
  ): string[] => {
    const basicSettings: string[] = [];

    if (get(massiveConfig, MassiveConfigPathEnum.SMARTLINKS, false))
      basicSettings.push(ServiceConfigEnum.SMARTLINK);

    if (get(massiveConfig, MassiveConfigPathEnum.VPOS, false))
      basicSettings.push(ServiceConfigEnum.VPOS);

    if (get(massiveConfig, MassiveConfigPathEnum.WEBCHECKOUT, false))
      basicSettings.push(ServiceConfigEnum.WEBCHECKOUT);

    return basicSettings;
  };

  const getOverviewAdvanceSettings = (
    massiveConfig: MassiveServiceConfiguration
  ): RowValues[] => {
    const advanceSettings: RowValues[] = [];

    if (get(massiveConfig, MassiveConfigPathEnum.COMMISSION_STATUS, false))
      advanceSettings.push({
        label: LabelEnum.COMMISSION,
        value: LabelEnum.YES,
      });

    const selectedCardNames: string[] = reduce(
      get(massiveConfig, MassiveConfigPathEnum.CARDS, []),
      (result: string[], value: SelectedCard) => {
        const cardIndex: string = get(value, "card", "").toLowerCase();
        const isSelected: boolean = get(value, "selected", false);

        if (!isUndefined(value) && has(cardBrands, cardIndex) && isSelected)
          return concat(result, cardBrands[cardIndex]);

        return result;
      },
      []
    );

    if (!isEmpty(selectedCardNames))
      advanceSettings.push({
        chips: selectedCardNames,
        label: LabelEnum.CAJITA,
      });

    if (get(massiveConfig, MassiveConfigPathEnum.VALIDATION_CHARGE, false))
      advanceSettings.push({
        label: LabelEnum.SUBSCRIPTION,
        value: LabelEnum.YES,
      });

    if (
      get(massiveConfig, MassiveConfigPathEnum.SANDBOX_CARD_PRESENT, false) ||
      get(massiveConfig, MassiveConfigPathEnum.SANDBOX_CARD_NOT_PRESENT, false)
    )
      advanceSettings.push({
        label: LabelEnum.SANDBOX,
        value: LabelEnum.ACTIVE,
      });

    return advanceSettings;
  };

  const showMassiveServiceOverview = (
    massiveConfig: MassiveServiceConfiguration
  ): void => {
    const basicSettings: string[] = getOverviewBasicSettings(massiveConfig);

    if (!isEmpty(basicSettings))
      setRowValuesBasic([
        {
          value: basicSettings.join(", "),
        },
      ]);

    const advanceSettings: RowValues[] =
      getOverviewAdvanceSettings(massiveConfig);

    if (!isEmpty(advanceSettings)) setRowValuesAdvanced(advanceSettings);
  };

  const handleDefaultServiceConfig = () => {
    dispatch(
      setDefaultServiceConfig({
        merchantId: publicMerchantId,
        privateMerchantId: privateCredentialId,
      })
    );
    setOpenModal(false);
  };

  useEffect(() => {
    if (!isEmpty(publicMerchantId)) {
      dispatch(
        getNodeInfo({
          configIds: NodeConfigEnum.SERVICE,
          publicMerchantId,
        })
      );
      setRedirectHeader(redirectPathSubHeader(publicMerchantId));
    }
    if (isMassiveConfig) {
      showMassiveServiceOverview(massiveConfig);
    }

    setConfigureButton({
      ...configureButton,
      onAction: () => handleConfigButton(),
    });
    if (isMassiveConfig)
      dispatch(setMassiveInformation(listBranchesSelected()));
  }, []);

  useEffect(() => {
    const filteredPaymentCredentials: PaymentCredential[] =
      paymentCredentials.filter((value) => value.enable === true);

    const merchantId: string = get(
      filteredPaymentCredentials,
      "[0].merchantId",
      ""
    );

    const _privateCredentialId: string = get(
      filteredPaymentCredentials,
      "[0].privateCredentialId",
      ""
    );

    if (!isEmpty(_privateCredentialId))
      setPrivateCredentialId(_privateCredentialId);

    if (!isEmpty(merchantId)) {
      dispatch(
        getProcessors({
          merchantId,
        })
      );
    }

    setCardContent({
      content: [],
    });
  }, [paymentCredentials]);

  useEffect(() => {
    const commissionSettings: RowValues = {
      label: LabelEnum.COMMISSION,
      value: LabelEnum.YES,
    };

    if (get(commissionData, "enable", false)) {
      setServicesAdvancedList({
        ...servicesAdvancedList,
        commissionSettings,
      });
      setIsServiceConfigured(true);
    }
  }, [commissionData]);

  const handleCajitaCards = (
    cardObject: ServiceDefaultResponse | CardData,
    path: string
  ) => {
    const cardSettings: RowValues = {
      chips: [],
      label: LabelEnum.CAJITA,
    };

    const creditCards: string[] = get(cardObject, path, []);

    if (!isEmpty(creditCards)) {
      creditCards.map((val) => {
        cardSettings.chips!.push(cardBrands[val.toLowerCase()]);
      });
      setServicesAdvancedList({ ...servicesAdvancedList, cardSettings });
      setIsServiceConfigured(true);
    }
  };

  useEffect(() => {
    const hasSmartlinks: string | undefined = get(
      defaultServiceConfig,
      "activeServices",
      []
    ).find((val) => val === ServiceConfigEnum.SMARTLINKS);

    if (hasSmartlinks) {
      const basicSettings = {
        value: ServiceConfigEnum.SMARTLINK,
      };

      setRowValuesBasic([basicSettings]);
      setIsServiceConfigured(true);
    }

    handleCajitaCards(defaultServiceConfig, "cards");

    setSecondaryButton({
      ...secondaryButton,
      isHidden: true,
    });

    setPrimaryButton({
      ...primaryButton,
      isHidden: false,
    });
  }, [defaultServiceConfig]);

  useEffect(() => {
    let subscriptionsValues: Partial<ServicesAdvancedList> = {};

    const subscriptionSettings: RowValues = {
      label: LabelEnum.SUBSCRIPTION,
      value: LabelEnum.YES,
    };

    if (get(subscriptionData, "subscriptionValidation", false)) {
      subscriptionsValues = {
        ...subscriptionsValues,
        subscriptionSettings,
      };
    }

    const sandboxSettings: RowValues = {
      label: LabelEnum.SANDBOX,
      value: LabelEnum.ACTIVE,
    };

    if (get(subscriptionDataSandbox, "sandboxEnable", false)) {
      subscriptionsValues = {
        ...subscriptionsValues,
        sandboxSettings,
      };
    }

    if (get(subscriptionData, "sandboxEnable", false) && !isCentralizedBranch) {
      subscriptionsValues = {
        ...subscriptionsValues,
        sandboxSettings,
      };
    }

    if (
      get(subscriptionDataSandbox, "sandboxEnable", false) ||
      get(subscriptionData, "sandboxEnable", false) ||
      get(subscriptionData, "subscriptionValidation", false)
    ) {
      setServicesAdvancedList({
        ...servicesAdvancedList,
        ...subscriptionsValues,
      });
      setIsServiceConfigured(true);
    }
  }, [subscriptionData, subscriptionDataSandbox]);

  useEffect(() => {
    setLocalStorageObject(merchantLocalStorage, {
      country: get(nodeInfo, "generalInfo.country", ""),
      name: get(nodeInfo, "generalInfo.name", ""),
      nodeId: get(nodeInfo, "nodeId", ""),
      origin: OriginEnum.CONSOLE_MERCHANT,
      publicMerchantId,
    });
    setLocalStorageObject(merchantInfoLocalStorage, {
      country: get(nodeInfo, "generalInfo.country", ""),
      isEditing: true,
      merchantName: get(nodeInfo, "generalInfo.name", ""),
      publicMerchantId,
      redirectPath: `${location.pathname}${location.search}`,
    });
    setLocalStorageObject(merchantIdLocalStorage, publicMerchantId);

    const handleServicesQueries = (
      merchantIdParam: string,
      sandBoxMerchantId: string,
      isCentralize?: boolean
    ) => {
      if (isMassiveConfig) return;

      dispatch(
        getPaymentCredentials({
          publicMerchantId: merchantIdParam,
        })
      );

      dispatch(getMerchantSmartlinkData({ publicMerchantId: merchantIdParam }));
      dispatch(getMerchantVPOSData({ publicMerchantId: merchantIdParam }));
      dispatch(getMerchantCardData({ publicMerchantId: merchantIdParam }));
      dispatch(
        getMerchantSubscriptionData({ publicMerchantId: merchantIdParam })
      );

      if (isCentralize) {
        dispatch(
          getMerchantSubscriptionDataSandbox({
            publicMerchantId: sandBoxMerchantId,
          })
        );
      }
      dispatch(
        getMerchantCommissionData({ publicMerchantId: merchantIdParam })
      );
    };

    const nodeServiceConfig: Configs = defaultTo(
      get(nodeInfo, "configs", []).find(
        (config) => get(config, "configuration", "") === NodeConfigEnum.SERVICE
      ),
      {}
    );

    const centralizeNodeId = get(nodeServiceConfig, "centralizedNodesId", "");
    const nodeValueId: string = get(nodeServiceConfig, "value", "");

    const entityName: string = get(nodeInfo, "entityName", "").toUpperCase();

    if (
      centralizeNodeId &&
      typeof centralizeNodeId === "string" &&
      entityName === EntityNameEnum.BRANCH
    ) {
      setIsCentralizedBranch(true);
      handleServicesQueries(nodeValueId, publicMerchantId, true);
    } else if (!isEmpty(get(nodeInfo, "merchantId", ""))) {
      handleServicesQueries(publicMerchantId, publicMerchantId);
    }
  }, [nodeInfo]);

  useEffect(() => {
    handleCajitaCards(cardData, "acceptCreditCards");
  }, [cardData]);

  useEffect(() => {
    let serviceConfiguredSettings: boolean = isServiceConfigured;

    const authSettings: RowValues = {
      label: "Servicio de autenticación",
      value: "3DS",
    };

    if (get(merchantSettings, "active_3dsecure", false)) {
      setServicesAdvancedList((previousValues) => ({
        ...previousValues,
        authSettings,
      }));
    }

    const cardProcessors: ProcessorSettings = get(
      merchantSettings,
      "processors.card",
      []
    );

    const cashProcessors: ProcessorSettings = get(
      merchantSettings,
      "processors.cash",
      []
    );

    const transferProcessors: ProcessorSettings = get(
      merchantSettings,
      "processors.transfer",
      []
    );

    const transferSubsProcessors: ProcessorSettings = get(
      merchantSettings,
      "processors['transfer-subscriptions']",
      []
    );

    const payoutsCashProcessors: ProcessorSettings = get(
      merchantSettings,
      "processors['payouts-cash']",
      []
    );

    const payoutsTransferProcessors: ProcessorSettings = get(
      merchantSettings,
      "processors['payouts-transfer']",
      []
    );

    const onlyPayoutProcessorData: boolean =
      isEmpty(cardProcessors) &&
      isEmpty(cashProcessors) &&
      isEmpty(transferProcessors) &&
      isEmpty(transferSubsProcessors) &&
      (!isEmpty(payoutsCashProcessors) || !isEmpty(payoutsTransferProcessors));

    const onlyPayinProcessorData: boolean =
      (!isEmpty(cashProcessors) ||
        !isEmpty(transferProcessors) ||
        !isEmpty(transferSubsProcessors)) &&
      isEmpty(cardProcessors);

    setHideEditBtn(onlyPayoutProcessorData);

    setIsServiceOmitted(onlyPayoutProcessorData);

    const isDisabledContinue: boolean =
      !isEmpty(cardProcessors) && !serviceConfiguredSettings;

    setSecondaryButton({
      ...secondaryButton,
      isDisabled: isCentralizedBranch,
      isHidden: !onlyPayinProcessorData || serviceConfiguredSettings,
    });

    setPrimaryButton({
      ...primaryButton,
      isDisabled: isDisabledContinue || isCentralizedBranch,
      isHidden: onlyPayinProcessorData && !serviceConfiguredSettings,
    });
  }, [merchantSettings]);

  const handleServiceConfigured = (func: Function) => {
    func();
    setIsServiceConfigured(true);
  };

  useEffect(() => {
    const basicValues: string[] = [];

    get(vposData, "active", false) &&
      handleServiceConfigured(() => basicValues.push(ServiceConfigEnum.VPOS));

    get(smartlinkData, "status", false) &&
      handleServiceConfigured(() =>
        basicValues.push(ServiceConfigEnum.SMARTLINK)
      );

    get(smartlinkData, "webcheckoutStatus", false) &&
      handleServiceConfigured(() =>
        basicValues.push(ServiceConfigEnum.WEBCHECKOUT)
      );

    if (!isEmpty(basicValues)) {
      const basicSettings = {
        value: basicValues.join(", "),
      };

      setRowValuesBasic([basicSettings]);
    }
  }, [smartlinkData, vposData]);

  useEffect(() => {
    const {
      sandboxSettings,
      cardSettings,
      subscriptionSettings,
      commissionSettings,
      authSettings,
    } = servicesAdvancedList;

    if (!isEmpty(servicesAdvancedList))
      setRowValuesAdvanced([
        defaultTo(sandboxSettings, {}),
        defaultTo(cardSettings, {}),
        defaultTo(subscriptionSettings, {}),
        defaultTo(commissionSettings, {}),
        defaultTo(authSettings, {}),
      ]);
  }, [servicesAdvancedList]);

  useEffect(() => {
    const content: RowContent[] = [];

    if (rowValuesBasic.length > 0) {
      content.push({
        rowValues: rowValuesBasic,
        subtitle: "Básicos",
      });
    }

    let hasAdvancedValues: boolean = false;

    rowValuesAdvanced.map((val) => {
      if (!isEmpty(val)) hasAdvancedValues = true;
    });

    if (hasAdvancedValues) {
      content.push({
        rowValues: rowValuesAdvanced,
        subtitle: "Avanzados",
      });
    }
    setCardContent({
      content,
    });
  }, [rowValuesAdvanced, rowValuesBasic]);

  useEffect(() => {
    if (notification) {
      showSnackbar(notification);
      dispatch(setNotification(undefined));
    }
  }, [notification]);

  const handleContinue = (serviceConfigured: boolean) => {
    if (isMassiveConfig) {
      setOpenModal(true);
      if (isInvalidMassiveConfig(massiveConfig))
        setModalInfo(MASSIVE_SERVICES_MODAL.CONFIRM_DEFAULT);
      else setModalInfo(MASSIVE_SERVICES_MODAL.CONFIRM_EDITION);
    } else {
      let statusStep: string = StatusStepEnum.PENDING;

      isServiceOmitted && (statusStep = StatusStepEnum.OMITTED);

      serviceConfigured && (statusStep = StatusStepEnum.COMPLETE);

      dispatch(
        updateHierarchyNodeConfig({
          configs: [
            {
              configuration: NodeConfigEnum.SERVICE,
              status: statusStep,
              value: publicMerchantId,
            },
          ],
          nodeId: get(nodeInfo, "nodeId", ""),
        })
      );
    }
  };

  const handleMassiveServiceConfig = (): void => {
    setOpenModal(false);
    if (!massiveConfig.alreadySent) {
      let request: MassiveServiceRequest = {
        branches: getSelectedBranches(),
        configuration: omit(massiveConfig, ["isDefault", "alreadySent"]),
        isDefault:
          massiveConfig.isDefault || isInvalidMassiveConfig(massiveConfig),
        ...getCustomerRequestInfo(),
      };

      dispatch(setRedirectToBranchList(false));
      dispatch(setMassiveServiceConfig(request));
      setLocalStorageObject(
        LocalStoragePathEnum.MASSIVE_SERVICE_CONFIGURATIONS,
        { ...massiveConfig, alreadySent: true, isDefault: request.isDefault }
      );
    } else {
      redirectUtil(VIEW_BRANCHES_REDIRECT);
    }
  };

  const branchesTitle = (): string =>
    defaultTo(listBranches.length, 0) > 3 ? title.concat("...") : title;

  const handleMassiveServices = () => {
    let request: MassiveServiceRequest = {
      branches: getSelectedBranches(),
      isDefault: false,
      ...getCustomerRequestInfo(),
      configuration: omit(massiveConfig, ["isDefault", "alreadySent"]),
    };

    if (isEmpty(massiveConfig) || massiveConfig.isDefault) {
      request = {
        branches: getSelectedBranches(),
        isDefault: true,
        ...getCustomerRequestInfo(),
        configuration: omit(massiveConfig, ["isDefault", "alreadySent"]),
      };
      setLocalStorageObject(
        LocalStoragePathEnum.MASSIVE_SERVICE_CONFIGURATIONS,
        {
          ...defaultMassiveConfig,
        }
      );
    }

    dispatch(setMassiveServiceConfig(request));
    showMassiveServiceOverview(getMassiveConfig());
    setOpenModal(false);
    dispatch(setIsMassiveDefault(false));
  };

  const handleMassiveServicesCreation = () => {
    if (massiveConfig.alreadySent) {
      return;
    }
    if (isEmpty(massiveConfig) || massiveConfig.isDefault) {
      dispatch(setIsMassiveDefault(true));
      setOpenModal(true);

      return;
    }
    setModalInfo(MASSIVE_SERVICES_MODAL.SAVE_SERVICES);
    setOpenModal(true);
  };

  return {
    cardContent,
    configureButton,
    footerLabel,
    handleAcceptModal: isMassiveConfig
      ? isMassiveDefault
        ? handleMassiveServices
        : handleMassiveServiceConfig
      : handleDefaultServiceConfig,
    handleMassiveServicesCreation,
    hideEditBtn,
    isCentralizedBranch,
    isLoading,
    modalInfo,
    onCloseModal,
    openModal,
    primaryButton: {
      ...primaryButton,
      onAction: () => handleContinue(isServiceConfigured),
    },
    secondaryButton,
    subHeader: {
      branchesTitle: branchesTitle(),
      country: get(nodeInfo, "generalInfo.country", CountriesEnum.GENERIC),
      id: get(nodeInfo, "generalInfo.publicMerchantId", ""),
      redirectPath: redirectPathHeader,
      subTitle: convertCountryName(get(nodeInfo, "generalInfo.country", "")),
      title: get(nodeInfo, "generalInfo.name", ""),
      totalBranches: listBranches.length,
    },
  };
};
