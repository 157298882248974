import { cloneDeep, defaultTo, filter, get, maxBy, unionBy } from "lodash";
import { Configs, NodeInfoResponse } from "../../../types/node_info_response";
import {
  ITabItem,
  StatusType,
} from "../../components/WrappedTabs/TabItem/TabItem.interfaces";
import {
  HANDLE_MODAL_LOADER_WS,
  updateLocalConfigResponse,
} from "../../store/reducers/app/app.slice";
import { ConfigIdEnum, nodeConfigId } from "../constants/node_config_enums";
import { ROUTES } from "../constants/routes";
import { SessionStoragePaths } from "../constants/Session_storage_paths";
import {
  mapConfigurationByTabRoute,
  mapConfigurationByTabRouteMassive,
} from "../constants/wrapper_container_constants";
import { CountriesEnum } from "../enums/countriesEnum";
import { status } from "../enums/statusEnum";
import { TabItemStatusEnum } from "../enums/tabItemStatusEnum";
import {
  IBillingValuesElements,
  IBranchBatchItem,
  IValuesBilling,
  IValuesDeclinedBilling,
  IValuesSubstractBilling,
} from "../interfaces/massiveBranch/massiveBranch.interfaces";
import getTime from "date-fns/getTime";
import { BranchEditItem } from "../../../types/branch_edit_item";

export const getBranchEditListFromSessionStorage = (item: string) => {
  return get(JSON.parse(sessionStorage.getItem(item) || "[]"), "data", []);
};

export const getTabsValue2 = (tabs: ITabItem[], configsResponse: Configs[]) => {
  return tabs.map((tab) => {
    return {
      ...tab,
      status: get(
        configsResponse.find(
          (config) =>
            get(config, "configuration") ===
            `${mapConfigurationByTabRoute.get(tab.redirectPath as ROUTES)}`
        ),
        "status",
        TabItemStatusEnum.PENDING
      ) as StatusType,
    };
  });
};

export const mapTabStatus = new Map([
  [TabItemStatusEnum.IN_PROGRESS, TabItemStatusEnum.PENDING],
  [TabItemStatusEnum.PENDING, TabItemStatusEnum.PENDING],
  [TabItemStatusEnum.COMPLETE, TabItemStatusEnum.COMPLETE],
]);

export const getTabsValueMassive = (
  tabs: ITabItem[],
  configsResponse: Configs[]
) => {
  return tabs.map((tab) => {
    const configFound = configsResponse.find(
      (config) =>
        config.configuration ===
        `${mapConfigurationByTabRouteMassive.get(tab.redirectPath as ROUTES)}`
    );

    if (configFound) {
      const status = mapTabStatus.get(
        get(configFound, "status", TabItemStatusEnum.PENDING) as StatusType
      )!;

      return {
        ...tab,
        status,
      };
    }

    return tab;
  });
};

export const getAllConfigsUpdated = (
  oldConfigs: Configs[],
  nodeInfo: NodeInfoResponse
) => {
  const newConfigs = get(nodeInfo, "configs", []);

  return unionBy(defaultTo(oldConfigs, []), newConfigs, "configuration");
};

export const getMostUpdatedMerchantBySection = (
  sectionId: nodeConfigId,
  branchBatches: IBranchBatchItem[]
): string => {
  const branchBatch = branchBatches.find(
    (branchItem) => branchItem.section === sectionId
  );

  return get(branchBatch, "publicMerchantId", "");
};

export const setLocalConfigResponse = (
  dispatch: any,
  configId: string,
  configResponse: Configs[],
  statusTab: TabItemStatusEnum,
  someBranchHasError: boolean
) => {
  const newConfigs = defaultTo(cloneDeep(configResponse), []).map((item) => {
    if (item.configuration === configId) {
      item.status = someBranchHasError ? TabItemStatusEnum.PENDING : statusTab;
      item.updatedAt = getTime(Date.now());
      item.updatedBy = localStorage.getItem("username") ?? "backoffice";
    }

    return item;
  });

  dispatch(updateLocalConfigResponse(newConfigs));
};

export const mapStatusConfigIdByRoute = new Map([
  [ROUTES.MASSIVE_BILLING, nodeConfigId.BILLING],
  [ROUTES.MASSIVE_DISPERSION, nodeConfigId.DISPERSION],
  [ROUTES.MASSIVE_CHARGE, nodeConfigId.CHARGE],
]);

export const handleCloseModalAndSave = (
  updateMassiveBranchStatus: boolean | undefined,
  dispatch: any
) => {
  if (updateMassiveBranchStatus) {
    dispatch(HANDLE_MODAL_LOADER_WS(false));
  }
};

export const buildBranchesBatchMostCurrent = (): IBranchBatchItem[] => {
  const branchEditList = getBranchEditListFromSessionStorage(
    SessionStoragePaths.BRANCH_EDIT_LIST
  ).filter((branchEdit: any) => get(branchEdit, "edit", false));

  const country = get(branchEditList, "[0].constitutional_country");

  const configs: object[] = branchEditList.map((filter: any) => {
    return {
      billing: {
        ...filter.configs.filter(
          (config: Configs) => config.configuration === ConfigIdEnum.CN003
        )[0],
      },
      charge: {
        ...filter.configs.filter(
          (config: Configs) => config.configuration === ConfigIdEnum.CN017
        )[0],
      },
      dispersion: {
        ...filter.configs.filter(
          (config: Configs) => config.configuration === ConfigIdEnum.CN004
        )[0],
      },
      publicMerchantId: filter.public_merchant_id,
    };
  });

  const billingDetail = maxBy(configs, "billing.updatedAt");
  const dispersionDetail = maxBy(configs, "dispersion.updatedAt");

  const branchBatch: IBranchBatchItem[] = [
    {
      publicMerchantId: get(billingDetail, "publicMerchantId"),
      section: ConfigIdEnum.CN003,
      status: get(billingDetail, "billing.status"),
      updatedAt: get(billingDetail, "billing.updatedAt"),
    },
    {
      publicMerchantId: get(dispersionDetail, "publicMerchantId"),
      section: [ConfigIdEnum.CN001, ConfigIdEnum.CN004].toString(),
      status: get(dispersionDetail, "dispersion.status"),
      updatedAt: get(dispersionDetail, "dispersion.updatedAt"),
    },
  ];

  if (country === CountriesEnum.ECUADOR) {
    const chargeDetail = maxBy(configs, "charge.updatedAt");
    const chargeBranchBach = {
      publicMerchantId: get(chargeDetail, "publicMerchantId"),
      section: [ConfigIdEnum.CN001, ConfigIdEnum.CN017].toString(),
      status: get(chargeDetail, "charge.status"),
      updatedAt: get(chargeDetail, "charge.updatedAt"),
    };

    branchBatch.push(chargeBranchBach);
  }

  return branchBatch;
};

const checkIsModified = (values: string[], valueToValidate: string) =>
  values.some((value: string) => valueToValidate === value);

export const setIsModifyByBranch = (branchesForUpdate: string[]) => {
  const branchEditList = getBranchEditListFromSessionStorage(
    SessionStoragePaths.BRANCH_EDIT_LIST
  );

  const branchEditListWithModify = branchEditList.map(
    (branchItem: BranchEditItem) => ({
      ...branchItem,
      isModify:
        branchItem.isModify ||
        checkIsModified(branchesForUpdate, branchItem.merchant_id!),
    })
  );

  sessionStorage.setItem(
    SessionStoragePaths.BRANCH_EDIT_LIST,
    JSON.stringify({
      data: branchEditListWithModify,
      total: branchEditListWithModify.length,
    })
  );
};

export const buildBillingValuesAndRates = (
  valuesBillingState: IValuesBilling,
  valuesSubtractBillingState: IValuesSubstractBilling,
  valuesDeclinedBillingState: IValuesDeclinedBilling
): IBillingValuesElements => {
  const billing_elements_approved: string[] = filter(
    valuesBillingState!.checkBillItems,
    ["checked", true]
  ).map((item) => item.value);

  const billing_substract_elements_approved: string[] = filter(
    valuesSubtractBillingState!.checkSubtractItems,
    ["checked", true]
  ).map((item) => item.value);

  const billing_substract_elements_declined: string[] = filter(
    valuesSubtractBillingState!.checkSubtractItems,
    ["checked", false]
  ).map((item) => item.value);
  const billing_declined_elements: string[] = filter(
    valuesDeclinedBillingState!.checkDeclinedItems,
    ["checked", true]
  ).map((item) => item.value);
  const billing_declined_status: boolean =
    valuesDeclinedBillingState!.statusDeclined;

  return {
    valuesBilling: {
      elementsApproved: [...billing_elements_approved],
      elementsDeclined: billing_declined_status
        ? [...billing_declined_elements]
        : [],
      statusApproved: valuesBillingState!.statusApproved,
      statusDeclined: billing_declined_status,
    },
    valuesSubtractBilling: {
      elementsApproved: [...billing_substract_elements_approved],
      elementsDeclined: [...billing_substract_elements_declined],
      statusApproved: valuesSubtractBillingState!.statusApproved,
      statusDeclined: status.declined,
    },
  };
};

export const getBranchesToUpdate = () =>
  getBranchEditListFromSessionStorage(SessionStoragePaths.BRANCH_EDIT_LIST)
    .filter((merchantEdit: any) => merchantEdit.edit)
    .map((merchantEdit: any) => ({
      merchantId: merchantEdit.public_merchant_id,
      nodeId: merchantEdit.node_id,
    }));
