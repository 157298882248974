import { IStyles } from "../../../shared/utils";

export const SelectedBranchStyles: IStyles = {
  // @ts-ignore
  boxItems: {
    overflowY: "scroll !important",
    height: "50vh !important",
    "&::-webkit-scrollbar": {
      display: {
        md: "initial",
        xs: "none",
      },
      width: 5,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "grey.500",
      border: "4px solid transparent",
      borderRadius: 3,
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "grey.800",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "neutral.grey1",
      borderRadius: 3,
    },
    scrollbarColor: "#595F76 #F4F6FA",
    scrollbarWidth: {
      md: "auto",
      xs: "none",
    },
  },
  dialogContainerParent: {
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        maxWidth: "610px",
        width: "100%",
      },
    },
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    borderBottom: "1px dashed #E2E8F0",
    paddingLeft: "15px",
    paddingRight: "15px",
    "&. css-1onuufn-MuiPaper-root-MuiDialog-paper": {
      width: "700px",
    },
  },
  mainHeader: {
    display: "block",
    alignItems: "center",
    width: "600px",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "10px",
  },
  titleSpan: {
    position: "relative",
    float: "left",
    fontWeight: 600,
  },
  closeIcon: {
    position: "relative",
    float: "right",
    boxShadow:
      "0px 10px 80px rgba(106, 167, 197, 0.1), 0px 5.0625px 34.875px rgba(106, 167, 197, 0.05), 0px 2px 13px rgba(106, 167, 197, 0.03), 0px 0.4375px 4.625px rgba(106, 167, 197, 0.02);",
    color: "#023365",
  },
  subHeader: {
    alignSelf: "flex-start",
    paddingLeft: "8px",
  },
  inputField: {
    paddingTop: "25px",
    paddingBottom: "25px",
    width: "100%",
  },
  dialogContent: {
    overflow: "hidden !important",
    height: "100% !important",
  },
  branchItems: {
    borderBottom: "1px solid #E2E8F0",
    maxHeight: "100px",
    overflow: "auto",
    fontFamily: "IBM Plex Sans",
    color: "#293036",
    margin: "15px 0px",
  },
  clientTypeText: {
    color: "text.grey",
  },
};
