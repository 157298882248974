import { IDropdownItemProps } from "@kushki/connect-ui/dist/Components/Atoms/List/DropdownItem";
import {
  DropDownID,
  DropDownText,
} from "../../../../shared/enums/DropDownValues";
import { AppRoutes } from "../../../../shared/infrastructure/routes";
import { showDeleteModal } from "../../../../store/reducers/layout/layout.slice";
import { DeleteRuleModalProperties } from "../../../../shared/constants/modal-info";
import { useEffect } from "react";
import { getBusinessListConfiguration } from "../../../../store/thunks/layout/layout.thunks";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DeleteModalInfo } from "../../BusinessRules.interfaces";
import { Condition } from "../../../../../types/get_payouts_business_rules_response";
import { Category } from "../../../RuleDetail/RuleDetail.interfaces";
import { mapFieldToBusinessRuleName } from "../../../RuleDetail/RuleDetail";

export interface IUseRuleList {
  getDropDownList: (ruleID: string, payout?: boolean) => IDropdownItemProps[];
  transformConditionToCategory: (conditions: Condition[]) => Category[];
}

export const useRuleList = (deleteModalInfo: DeleteModalInfo): IUseRuleList => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getDropDownList = (
    ruleID: string,
    payout?: boolean
  ): IDropdownItemProps[] => {
    return [
      {
        id: DropDownID.EDIT,
        text: DropDownText.EDIT,
        onSelect: () => {
          payout
            ? navigate(AppRoutes.EDIT_PAYOUT_RULE(ruleID))
            : navigate(AppRoutes.EDIT_RULE(ruleID));
        },
      },
      {
        id: DropDownID.DELETE,
        text: DropDownText.DELETE,
        onSelect: () => {
          deleteModalInfo.setRuleIDForDelete(ruleID);
          dispatch(
            showDeleteModal(DeleteRuleModalProperties.DELETE_RULE_MODAL)
          );
        },
      },
    ];
  };

  const transformConditionToCategory = (
    conditions: Condition[]
  ): Category[] => {
    return conditions.map((condition: Condition) => ({
      name: mapFieldToBusinessRuleName(condition.field),
      conditional: condition.operator,
      values: condition.value,
    }));
  };

  useEffect(() => {
    dispatch(getBusinessListConfiguration());
  }, []);
  return { getDropDownList, transformConditionToCategory };
};
