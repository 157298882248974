export enum BillingInfoLabelsEnum {
  BILLING = "Facturación",
  BILLING_LEGEND = "Datos que utilizamos para emitir las facturas a tu comercio",
  CHARGES = "Cobros",
  CHARGES_LEGEND = "Configuración de cómo te cobramos nuestras comisiones.",
  CONFIGURED_CURRENCY = "Moneda configurada",
  CURRENCY_LEGEND = " Moneda en la que se realizarán todas las transacciones correspondientes.",
  DISPERSION = "Dispersion",
  DISPERSION_LEGEND = "Datos de la liquidación que Kushki hace a tu comercio.",
  DISPERSION_LEGEND_NO_HAS = "No tiene configurado la dispersión",
  RETENTION = "Retenciones",
  RETENTION_LEGEND = "Documentos de las retenciones mensuales que te realizamos en el año fiscal actual. Presiona alguno de los links para poder visualizar o descargar y si requieres consultar documentos anteriores puedes solicitarlos en soporte.",
  RETENTION_EMPTY_LEGEND = "No hay documentación registrada",
  SHOW_MORE = "Ver listado completo",
  SHOW_LESS = "Ver menos",
  WITHOUT_CYCLE = "No aplica en este ciclo",
}
