import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const headerSkeletonStyles = createNamedStyles({
  wrapperSelect: {
    width: "100%",
  },
  wrapperTabs: {
    width: "100%",
    marginBottom: "50px",
    marginTop: "50px",
  },
  selectInput: {
    height: "30px !important",
    mb: "1rem",
    width: "295px",
  },
  tabs: {
    height: "30px !important",
    mb: "1rem",
    width: "100%",
  },
  ownerValue: {
    height: "18px !important",
    width: "80px",
  },
  ownerIdText: {
    height: "18px !important",
    width: "46px",
  },
  customerIdValue: {
    height: "18px !important",
    width: "168px",
  },
  customerIdText: {
    height: "18px !important",
    width: "83.5px",
  },
  countryIcon: {
    height: "24px !important",
    width: "24px",
  },
  countryText: {
    height: "18px !important",
    width: "116px",
  },
  statusChip: {
    height: "30px !important",
    width: "66px",
  },
  dateText: {
    height: "18px !important",
    width: "133px",
  },
  returnBtn: {
    height: "30px !important",
    width: "100px",
  },
  branchName: {
    height: "30px !important",
    width: "250px",
  },
  wrapperCreate: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    mt: "3rem",
    width: "100%",
  },
  text: {
    width: "220px",
  },
  button: {
    height: "44px",
    width: "195px",
    borderRadius: "1rem !important",
  },
  divider: {
    border: "none",
  },
  wrapperFilters: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    m: "1rem 0",
  },
  circleButton: {
    height: "44px",
    width: "44px",
  },
  wrapperSearch: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    width: "80%",
  },
  input: {
    height: "44px !important",
    mb: "1rem",
    width: "47%",
    borderRadius: "1rem !important",
  },
});
