import React from "react";
import { RuleComponentStyles } from "../RuleComponent/RuleComponent.styles";
import {
  Autocomplete,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { LabelEnum } from "../../shared/enums/LabelEnum";
import { IconCircleWarn } from "@kushki/connect-ui";
import { MerchantProcessors } from "../../../types/merchant_processors";
import { Controller, ControllerRenderProps } from "react-hook-form";
import { get } from "lodash";
import { FormFieldEnum } from "../../shared/enums/FormFieldEnum";
import { IRuleConfigProps } from "./RuleConfigurationComponent.interfaces";
import { useRuleConfigurationState } from "./state/useRuleConfigurationComponente";
import { getProcessorById } from "../../shared/utils/utilsFile";

export const RuleConfigurationComponent: React.FC<IRuleConfigProps> = ({
  handleEnterSearchTextProcessors,
  handleSetSearchTextProcessors,
}) => {
  const { control, processorList, selectedProcessor } =
    useRuleConfigurationState();
  const classes = RuleComponentStyles();
  const descriptionRuleConfiguration: string =
    LabelEnum.DESCRIPTION_RULE_CONFIGURATION;

  return (
    <Grid item xs={12} className={classes.paddingTop32}>
      <Card elevation={2} className={classes.rootCard}>
        <CardContent className={classes.cardContent}>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h3" color="text.primary">
                {LabelEnum.RULE_CONFIGURATION}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.paddingTop16}>
              <Typography variant="body2" color="text.dark">
                {descriptionRuleConfiguration}
              </Typography>
            </Grid>
            <Grid item xs={5} className={classes.paddingTop20}>
              <Controller
                name={FormFieldEnum.RULE_PROCESSOR_ID}
                control={control}
                defaultValue=""
                render={(
                  parentProps: ControllerRenderProps<Record<string, any>>
                ) => (
                  <Autocomplete
                    disablePortal
                    disableClearable
                    value={{
                      label: getProcessorById(parentProps.value, processorList!)
                        .alias,
                      value: parentProps.value,
                    }}
                    onChange={(_event: any, value: any) =>
                      parentProps.onChange(get(value, "value"))
                    }
                    options={processorList!.map(
                      (processor: MerchantProcessors) => {
                        return {
                          label: processor.alias,
                          value: processor.publicProcessorId,
                        };
                      }
                    )}
                    noOptionsText={"No hay opciones"}
                    onKeyDown={(event) =>
                      handleEnterSearchTextProcessors(event.key)
                    }
                    onInputChange={(_event: any, newInputValue: string) =>
                      handleSetSearchTextProcessors(newInputValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={LabelEnum.SELECT_PROCESSOR}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5} alignSelf={"end"}>
              <Typography variant="body2" color="text.grey">
                <IconCircleWarn fontSize="small" />
                {` ${LabelEnum.PROCESSOR_PUBLIC_ID} ${get(
                  selectedProcessor,
                  "publicProcessorId",
                  ""
                )}`}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
