import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import React, { FC } from "react";
import { ISecurityWrapperProps } from "@components/atom/SecurityWrapper/SecurityWrapper.interfaces";

const SecurityWrapper: FC<ISecurityWrapperProps> = ({
  tagByModule,
  children,
  source,
  component,
}: ISecurityWrapperProps) => {
  return (
    <ComponentSecurityWrapper componentId={tagByModule[source][component]}>
      {children}
    </ComponentSecurityWrapper>
  );
};

export { SecurityWrapper };
