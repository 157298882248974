import { Box } from "@mui/material";
import { CardList } from "@kushki/connect-ui";

import { CustomStyles } from "../../shared/infrastructure/interfaces/CustomStyles";

interface Items {
  label: string;
  text: string;
}

interface Props {
  items: Items[];
  title: string;
}

export const cardListStyles: CustomStyles = {
  cardList: {
    div: {
      minWidth: "0",
    },
    paddingLeft: "24px",
    paddingRight: "24px",
    span: {
      paddingLeft: "0 !important",
    },
  },
};

export const CardListContainer: React.FC<Props> = ({ items, title }) => {
  return (
    <Box
      bgcolor="neutral.grey3"
      borderRadius="8px"
      marginBottom="16px"
      paddingBottom="24px"
      sx={cardListStyles.cardList}
    >
      <CardList items={items} orientation="horizontal" title={title} />
    </Box>
  );
};
