import { IRootState } from "../../interfaces/RootState.interfaces";
import {
  getAreAllCurrentTransactionsSelected,
  getTransactionsIDs,
} from "../../../shared/utils";

export const selectSelectedTransactions = (state: IRootState) =>
  state.transactions.selectedTransactions;

export const selectTransactions = (state: IRootState) =>
  state.transactions.transactions;

export const selectTransactionsLoading = (state: IRootState) =>
  state.transactions.areTransactionsLoading;

export const selectIsSelectedModalOpen = (state: IRootState) =>
  state.transactions.isSelectedModalOpen;

export const selectSelectedTransactionsIDs = (state: IRootState) =>
  getTransactionsIDs(state.transactions.selectedTransactions);

export const selectAreAllCurrentTransactionsSelected = (state: IRootState) =>
  getAreAllCurrentTransactionsSelected(state.transactions);

export const selectCurrentActionTransaction = (state: IRootState) =>
  state.transactions.currentActionTransaction;
