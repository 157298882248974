export enum ActionTypes {
  SET_NOTIFICATION = "[WALLET DASHBOARD] SET_NOTIFICATION",
  SET_IS_LOADING_GET_TRANSACTIONS = "[WALLET DASHBOARD] SET_IS_LOADING_GET_TRANSACTIONS",
  SET_WALLET_DASHBOARD_RESPONSE = "[WALLET DASHBOARD] SET_WALLET_DASHBOARD_RESPONSE",
  SET_SHOW_ALERT_ON_GET_TRANSACTIONS = "[WALLET DASHBOARD] SET_SHOW_ALERT_ON_GET_TRANSACTIONS",
  SET_FILTER_WALLET_DASHBOARD_REQUEST = "[WALLET DASHBOARD] SET_FILTER_WALLET_DASHBOARD_REQUEST",
  SET_BALANCE = "[WALLET DASHBOARD] SET_BALANCE",
  SET_IS_DOWNLOADING_FILE = "[WALLET DASHBOARD] SET_IS_DOWNLOADING_FILE",
  SET_SIGNED_URL_RESPONSE = "[WALLET DASHBOARD] SET_SIGNED_URL_RESPONSE",
  SET_DOWNLOAD_ERROR = "[WALLET DASHBOARD] SET_DOWNLOAD_ERROR",
  SET_SHOW_ALERT_ON_DOWNLOAD_SUCCESS = "[WALLET DASHBOARD] SET_SHOW_ALERT_ON_DOWNLOAD_SUCCESS",
  SET_SHOW_ALERT_ON_DOWNLOAD_ERROR = "[WALLET DASHBOARD] SET_SHOW_ALERT_ON_DOWNLOAD_ERROR",
  SET_TABLE_SIZE = "[WALLET DASHBOARD] SET_TABLE_SIZE",
  SET_CURRENT_PAGE = "[WALLET DASHBOARD] SET_CURRENT_PAGE",
  SET_TOTAL_RECORDS = "[WALLET DASHBOARD] SET_TOTAL_RECORDS",
  SET_SEARCH_TYPE = "[WALLET DASHBOARD] SET_SEARCH_TYPE",
  SET_IS_REPORT_GENERATED = "[WALLET DASHBOARD] SET_IS_REPORT_GENERATED",
  SET_IS_DATE_RANGE_ERROR = "[WALLET DASHBOARD] SET_IS_DATE_RANGE_ERROR",
  SET_PAYOUT_MERCHANT = "[WALLET DASHBOARD] SET_IS_PAYOUT_MERCHANT",
}
