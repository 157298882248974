import { useFieldArray } from "react-hook-form";
import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  FORM_BY_COUNTRY,
  LEGAL_REPRESENTATIVE_KEY,
  LegalRepresentativesComponentType,
} from "../../../shared/constants/LegalRepresentativesConstants";
import { PersonCategory } from "../../../shared/constants/PersonCategoryEnum";
import { PersonType } from "../../../shared/constants/PersonTypeEnum";
import { defaultTo, get, isNil, sortBy } from "lodash";
import { IAppState } from "../../../store/reducer";
import { LegalRepresentativesFormComponents } from "../LegalRepresentativesForm.interfaces";
import {
  IFormData,
  ILegalRepresentativesFormState,
  ILegalRepresentativesFormStateProps,
} from "./useLegalRepresentativesFormState.interfaces";
import moment from "moment";

export const useLegalRepresentativesFormState = ({
  form: { control },
}: ILegalRepresentativesFormStateProps): ILegalRepresentativesFormState => {
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: LEGAL_REPRESENTATIVE_KEY,
  });
  const [formFields, setFormFields] = useState<object>({});
  const [formConfig, setFormConfig] = useState<
    LegalRepresentativesFormComponents[]
  >([]);

  const merchantInformation = useSelector(
    (store: IAppState) => store.merchantInformation
  );
  const compliancePeople = useSelector(
    (store: IAppState) => store.compliancePeople
  );

  const getFormData = (): IFormData => {
    const formConfiguration = get(
      FORM_BY_COUNTRY,
      get(merchantInformation, "country", ""),
      []
    );
    const fieldsToSet = formConfiguration
      .filter(
        (formField: LegalRepresentativesFormComponents) =>
          formField.type != LegalRepresentativesComponentType.BLANK
      )
      .reduce(
        (acc: object, curr: LegalRepresentativesFormComponents) => ({
          ...acc,
          [curr.name]: get(curr, "defaultValue", ""),
        }),
        {}
      );

    return { fieldsToSet, formConfiguration };
  };

  useEffect(() => {
    if (merchantInformation) {
      const { fieldsToSet, formConfiguration } = getFormData();

      setFormConfig(formConfiguration);
      setFormFields(fieldsToSet);
    }
  }, [merchantInformation]);

  useEffect(() => {
    if (compliancePeople) {
      const { fieldsToSet } = getFormData();

      let legalRepresentatives = get(
        compliancePeople,
        LEGAL_REPRESENTATIVE_KEY,
        []
      );

      legalRepresentatives = legalRepresentatives.map((lr) => {
        const bd = get(lr, "birthdate");
        const birth_date = !isNil(bd)
          ? defaultTo(moment(bd).toISOString(), "").split("T")[0]
          : "";
        const expDate = get(lr, "birthdate");
        const expeditionDate = !isNil(expDate)
          ? defaultTo(moment(expDate).toISOString(), "").split("T")[0]
          : "";

        return {
          ...lr,
          ...(!isNil(bd) && { birthdate: birth_date }),
          ...(!isNil(expDate) && { expeditionDate }),
        };
      });

      if (legalRepresentatives.length > 0) {
        const firstMainLegalRepresentative = sortBy(legalRepresentatives, [
          (lr) => {
            return !lr.isMain;
          },
        ]);

        replace(
          firstMainLegalRepresentative.map((legalRepresentative, index) => ({
            ...fieldsToSet,
            personCategory: PersonCategory.REPRESENTATIVE,
            personType: PersonType.NATURAL,
            ...legalRepresentative,
            isMain: index === 0,
          }))
        );
      } else {
        append({
          ...fieldsToSet,
          isMain: true,
          personCategory: PersonCategory.REPRESENTATIVE,
          personType: PersonType.NATURAL,
        });
      }
    }
  }, [compliancePeople]);

  const handleChangeCheckboxForm = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { checked } = event.target;
    const legalRepresentatives = get(
      compliancePeople,
      LEGAL_REPRESENTATIVE_KEY,
      []
    );

    const altLegalRepresentative = defaultTo(
      legalRepresentatives.find(
        (legalRepresentative) => !get(legalRepresentative, "isMain")
      ),
      {}
    );

    if (checked) {
      append({
        ...formFields,
        ...altLegalRepresentative,
        isMain: false,
        personCategory: PersonCategory.REPRESENTATIVE,
        personType: PersonType.NATURAL,
      });
    } else {
      remove(index + 1);
    }
  };

  return {
    fields,
    formConfig,
    handleChangeCheckboxForm,
  };
};
