import React from "react";
import { Box } from "@mui/material";

import { defaultTo } from "lodash";
import { IContentModalDetail } from "../../shared/infrastructure/interfaces/IModalContainer";

export const ContentModalDetail: React.FC<IContentModalDetail> = (
  props: IContentModalDetail
) => {
  return (
    <Box display="flex" flexDirection="column" marginRight="8px" width="100%">
      {React.Children.map(props.children, (child: React.ReactNode) => {
        return defaultTo(child, <></>);
      })}
    </Box>
  );
};
