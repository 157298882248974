import { configureStore } from "@reduxjs/toolkit";
import OwnerDataReducer from "./reducers/ownerData/ownerData.slice";
import CustomerReducer from "./reducers/customerData/customerData.slice";
import BranchReducer from "./reducers/branchData/branchData.slice";
import mainContainerReducer from "./reducers/mainContainer/mainContainer";

export const store = configureStore({
  devTools: process.env.NEXT_PUBLIC_ENV_NAME !== "primary",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    branchReducer: BranchReducer,
    customerReducer: CustomerReducer,
    mainContainer: mainContainerReducer,
    ownerReducer: OwnerDataReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
