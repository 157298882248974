enum PrefixReducer {
  INFO_BRANCHES = "infoBranches",
}

export const ThunkPrefix = {
  ASYNC_MASSIVE_ACTION: `${PrefixReducer.INFO_BRANCHES}/asyncMassiveAction`,
  CENTRALIZE_BRANCH: `${PrefixReducer.INFO_BRANCHES}/centralizeBranch`,
  DECENTRALIZE_BRANCH: `${PrefixReducer.INFO_BRANCHES}/decentralizeBranch`,
  NODE_INFO: `${PrefixReducer.INFO_BRANCHES}/nodeInfo`,
  REMOVE_MERCHANT_PEOPLE: `${PrefixReducer.INFO_BRANCHES}/removeMerchantPeople`,
  SEARCH_MERCHANT_NODE: `${PrefixReducer.INFO_BRANCHES}/searchMerchantNode`,
};
