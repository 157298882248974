import { createNamedStyles } from "../../../../shared/utils";

export const TableHeadStyles = createNamedStyles({
  cell: {
    color: "neutral.grey7",
    fontSize: "13px",
    fontWeight: "500",
  },
  checkbox: {
    color: "primary.main",
    padding: 0,
    "&.Mui-checked": {
      color: "primary.main",
    },
  },
  checkboxCell: {
    color: "neutral.grey7",
    textAlign: "center",
    padding: "0px 80px",
  },
  sortLabel: {
    color: "neutral.grey7",
    "&:hover": {
      color: "neutral.grey7",
    },
    "&:focus": {
      color: "neutral.grey7",
    },
    "& .MuiTableSortLabel-icon": {
      color: "neutral.grey7",
      fill: "transparent",
      opacity: 1,
    },
  },
});
