import {
  FieldValues,
  useFieldArray,
  UseFieldArrayReturn,
  useFormContext,
} from "react-hook-form";
import {
  FieldOptionValue,
  InputNameForm,
  TypeEnum,
} from "@shared/constants/AlarmConfigConstants";
import { IUseAlarmConfigForm } from "@components/AlarmConfigForm/AlarmConfigForm.interfaces";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ISelectOption } from "@components/InputsForm/Inputs/InputSelect/InputSelect.interfaces";
import { fieldOptionsByType } from "@shared/constants/selectOptions";
import { IVariableRule } from "@containers/AlarmConfig/AlarmConfig.interfaces";
import {
  findDuplicatedOperator,
  getFieldByOperatorEqual,
  operationIsEqual,
  removeSectionsStandardDeviation,
  removeVariableSection,
  verifyEmptyProperties,
} from "@shared/utils/alarmConfigUtil";
import { clone, defaultTo } from "lodash";

export const useAlarmConfigForm = (): IUseAlarmConfigForm => {
  let { type } = useParams();
  const [options, setOptions] = useState<ISelectOption[]>(
    fieldOptionsByType(defaultTo(type, ""))
  );
  const [disabledButton, setDisabledButton] = useState<boolean>(false);

  const variablesFields: UseFieldArrayReturn<
    FieldValues,
    InputNameForm.VARIABLES,
    string
  > = useFieldArray({
    name: InputNameForm.VARIABLES,
  });

  const { watch, getValues } = useFormContext<FieldValues>();
  const addVariablesSection = () => {
    variablesFields.append({
      field: "",
    });
  };

  const disableFieldOptions = () => {
    const variables: IVariableRule[] = getValues(InputNameForm.VARIABLES);
    const fieldValues: string[] = variables
      .map((item: IVariableRule) => item.field)
      .filter((field: string) =>
        [
          FieldOptionValue.PEP,
          FieldOptionValue.MODEL,
          FieldOptionValue.MCC,
          FieldOptionValue.CATEGORY,
          FieldOptionValue.CONSTITUTIONAL_COUNTRY,
          FieldOptionValue.CONSTITUTION_DATE,
        ].includes(field as FieldOptionValue)
      );
    const operatorValues: string[] = getFieldByOperatorEqual(variables);
    const newOptions: ISelectOption[] = clone(options).map(
      (opt: ISelectOption) => {
        if ([...fieldValues, ...operatorValues].includes(opt.value)) {
          return { ...opt, isDisabled: true };
        }

        return { ...opt, isDisabled: false };
      }
    );

    setOptions(newOptions);
  };

  const handleBehaviorSections = (
    sectionModified: IVariableRule,
    index: number
  ) => {
    const variables: IVariableRule[] = getValues(InputNameForm.VARIABLES);

    const emptyFields: boolean = verifyEmptyProperties(variables);
    const filterSection: IVariableRule[] = variables.filter(
      (item) => item.field === sectionModified.field
    );

    if (filterSection.length >= 2) {
      if (findDuplicatedOperator(filterSection)) {
        variablesFields.update(index, { ...sectionModified, operator: "" });
      } else if (operationIsEqual(filterSection)) {
        removeVariableSection(filterSection, variables, variablesFields.remove);
      }
    }

    if (
      sectionModified.field === FieldOptionValue.STANDARD_DEVIATION &&
      variables.length > 1
    ) {
      removeSectionsStandardDeviation(variablesFields);
    }
    setDisabledButton(emptyFields);
  };

  useEffect(() => {
    if (!verifyEmptyProperties(getValues(InputNameForm.VARIABLES))) {
      setDisabledButton(false);
    }
  }, [watch(InputNameForm.VARIABLES)]);

  return {
    addVariablesSection,
    disabledButton,
    disableFieldOptions,
    handleBehaviorSections,
    options,
    showTooltip: type === TypeEnum.GENERAL,
    variablesFields,
    watch,
  };
};
