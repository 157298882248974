import { useEffect, useState } from "react";
import { IMaskedCardConditionProps } from "../MaskedCardCondition";
import { get, isUndefined } from "lodash";
import { isAmex, isFormEditMode } from "../../../../../utils/utilsFile";
import { useParams } from "react-router-dom";
import { IUseParams } from "../../../../../shared/infrastructure/interfaces/IUseParams";
import { setTextFieldComponentActions } from "../../../../../store/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../../../store/reducer";
import { LabelEnum } from "../../../../../shared/infrastructure/enums/LabelEnum";

export interface IUseMaskedCardConditionState {
  cardList: string[];
  handleAddCard: () => void;
  handleEditCard: (value: string, index: number) => void;
  handleDeleteCard: (value: string) => void;
  handleOnChangeCard: (evt: string) => void;
  setCardList: (value: ((prevState: string[]) => string[]) | string[]) => void;
  setValue: (value: ((prevState: string) => string) | string) => void;
  validCard: boolean;
  value: string;
}

export const useMaskedCardConditionState = (
  props: IMaskedCardConditionProps
): IUseMaskedCardConditionState => {
  const textFieldComponentActions = useSelector(
    (state: IAppState) => state.textFieldComponentActions
  );
  const dispatch = useDispatch();
  const { ruleId } = useParams<IUseParams>();
  const [value, setValue] = useState<string>("");
  const [cardList, setCardList] = useState<string[]>(
    isFormEditMode(ruleId) ? get(props, "defaultListCard", []) : []
  );
  const [validCard, setValidCard] = useState<boolean>(false);

  useEffect(() => {
    props.setSelectedItems(cardList);
  }, [cardList]);

  useEffect(() => {
    setCardList(props.defaultListCard.length > 0 ? props.defaultListCard : []);
  }, [props.operator]);

  const handleAddCard = (): void => {
    setValue("");
    setCardList((array) => [...array, value]);
    setValidCard(false);
  };

  const handleOnChangeCard = (value: string): void => {
    setValidCard((isAmex(value) && value.length >= 17) || value.length >= 19);
  };

  const handleEditCard = (value: string, index: number): void => {
    const newCardList = [...cardList];

    newCardList[index] = value;
    setCardList(newCardList);
  };

  const handleDeleteCard = (value: string): void => {
    setCardList(cardList.filter((item) => item != value));
  };

  useEffect(() => {
    if (!isUndefined(textFieldComponentActions)) {
      if (textFieldComponentActions.label === LabelEnum.MASKED_CARD) {
        const newValues: string[] = textFieldComponentActions.value.split(",");

        setCardList(newValues);

        dispatch(setTextFieldComponentActions(undefined));
      }
    }
  }, [textFieldComponentActions]);

  return {
    cardList,
    handleAddCard,
    handleEditCard,
    handleDeleteCard,
    handleOnChangeCard,
    setCardList,
    setValue,
    validCard,
    value,
  };
};
