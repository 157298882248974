import React from "react";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { routes } from "../../../shared/infrastructure/constants/routes";

interface BreadcrumItem {
  label: string;
  url: string;
}

export interface BreadcrumProps {
  items: BreadcrumItem[];
  lastItem: string;
}

const useStyles = makeStyles(() => ({
  typography: {
    color: "#808080",
    fontWeight: 100,
    textDecoration: "none",
  },
}));

const Breadcrumb = (props: BreadcrumProps) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Breadcrumbs
        className={classes.typography}
        separator="/"
        aria-label="breadcrumb"
      >
        {props.items.map((item: BreadcrumItem) => (
          <>
            {item.url === routes.MERCHANT_LIST ? (
              <a className={classes.typography} href={"/merchant-list"}>
                {item.label}
              </a>
            ) : (
              <Link
                className={classes.typography}
                key={item.label}
                component={RouterLink}
                to={item.url}
              >
                {item.label}
              </Link>
            )}
          </>
        ))}
        <Typography color="textPrimary" className={classes.typography}>
          {props.lastItem}
        </Typography>
      </Breadcrumbs>
    </React.Fragment>
  );
};

export default Breadcrumb;
