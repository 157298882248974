import React, { useEffect } from "react";
import { Box, Button, ButtonBase, Typography } from "@mui/material";
import { IUploadFileInput } from "./UploadFileInput.interfaces";
import { defaultTo, get, isEmpty } from "lodash";
import { ItemCard } from "../../ItemCard/ItemCard";
import { Alert, IconTrash } from "@kushki/connect-ui";
import { InputStyles, UploadFileInputStyles } from "./UploadFileInput.styles";
import {
  ACCEPTED_FILE_FORMATS,
  UPLOAD_FILE_INPUT_TEXTS,
} from "../../../shared/constants/labels/form_labels";

export const UploadFileInput = ({
  register,
  resetFile,
  name,
  rules,
  value,
  errors,
  trigger,
  transactionType,
  closeAlert,
}: IUploadFileInput) => {
  const fileName: string = defaultTo(get(value, "0", { name: "" }).name, "");

  useEffect(() => {
    !isEmpty(fileName) && trigger(name);
  }, [value]);

  return (
    <Box>
      <Typography
        sx={UploadFileInputStyles.label}
        variant={"subtitle1"}
        color={"text.grey"}
      >
        {UPLOAD_FILE_INPUT_TEXTS.title}
      </Typography>
      <Typography sx={UploadFileInputStyles.subLabel} color={"text.lightGrey"}>
        {UPLOAD_FILE_INPUT_TEXTS.subTitle}
      </Typography>

      {!!errors[name] && (
        <Box sx={UploadFileInputStyles.errorCardContainer}>
          <Alert
            type={"error"}
            closeButton
            title={UPLOAD_FILE_INPUT_TEXTS.alertTitle(transactionType)}
            subTitle={defaultTo(
              errors[name].message,
              UPLOAD_FILE_INPUT_TEXTS.alertSubtitle(transactionType)
            )}
            onClose={closeAlert}
          />
        </Box>
      )}
      {
        <Button
          sx={UploadFileInputStyles.uploadButton}
          variant="contained"
          component="label"
          disabled={!isEmpty(fileName)}
        >
          {UPLOAD_FILE_INPUT_TEXTS.uploadFile}
          <input
            {...register(name as never, rules)}
            type="file"
            hidden
            style={InputStyles}
            accept={ACCEPTED_FILE_FORMATS}
          />
        </Button>
      }
      {!isEmpty(fileName) && (
        <Box sx={UploadFileInputStyles.itemContainer}>
          <ItemCard itemName={fileName} />
          <ButtonBase
            sx={UploadFileInputStyles.buttonBase}
            onClick={() => {
              resetFile(name as any);
            }}
          >
            <IconTrash color={"error"} />
          </ButtonBase>
        </Box>
      )}
    </Box>
  );
};
