import { ICardIcon } from "./CardIcon.interfaces";
import { Box } from "@mui/material";
import React from "react";
import { cardIconStyles } from "./CardIcon.styles";

export const CardIcon: React.FC<ICardIcon> = ({
  background = "#fff",
  border = "0.5px solid #CBD5E0",
  icon,
  image,
}: ICardIcon) => {
  return (
    <>
      <Box data-testid={"CardIconBox"} sx={cardIconStyles(background, border)}>
        {image ? <img src={image} /> : icon}
      </Box>
    </>
  );
};
