import React from "react";
import { useSummaryMassiveBranch } from "./state/useSummaryMassiveBranch";
import { Box, Button, Typography } from "@mui/material";
import { IconEye } from "@kushki/connect-ui";
import { summaryMassiveBranchStyles as styles } from "./SummaryMassiveBranch.styles";
import { SUMMARY_BTN_TEXT } from "../../shared/constants/labels/massive_modal_labels";
import { ISummaryMassiveBranchInterfaces } from "./SummaryMassiveBranch.interfaces";

const SummaryMassiveBranch = ({
  handleOpenSelectedBranch,
}: ISummaryMassiveBranchInterfaces) => {
  const { names, totalBranches } = useSummaryMassiveBranch();

  return (
    <Box sx={styles.wrappedSummary}>
      <Box>
        <Typography
          sx={styles.textTotalBranches}
        >{`${totalBranches} branches a editar:`}</Typography>
        <Box sx={styles.branchNames}>
          <Typography sx={styles.textNameBranches} noWrap>
            {names}
          </Typography>
        </Box>
      </Box>
      <Box sx={styles.boxRight}>
        <Button
          sx={styles.summaryBtn}
          variant="outlined"
          color="secondary"
          size="small"
          startIcon={<IconEye fontSize="small" sx={{ marginRight: "10px" }} />}
          onClick={() => {
            handleOpenSelectedBranch(true);
          }}
        >
          {SUMMARY_BTN_TEXT}
        </Button>
      </Box>
    </Box>
  );
};

export default SummaryMassiveBranch;
