export enum KeyStatusEnum {
  PAYMENT_MOVEMENT_TYPE = "paymentMovementType",
  CHARGE_MOVEMENT_TYPE = "chargeMovementType",
  MASTERCARD_CARD_BRAND = "mastercardCardBrand",
  VISA_CARD_BRAND = "visaCardBrand",
  AMEX_CARD_BRAND = "amexCardBrand",
  CARNET_CARD_BRAND = "carnetCardBrand",
  DEBIT_CARD_TYPE = "debitCardType",
  CREDIT_CARD_TYPE = "creditCardType",
  CONCILIATION_BANK_CONCILIATION_STATUS = "conciliationBankConciliationStatus",
  OMIT_BANK_CONCILIATION_STATUS = "omitBankConciliationStatus",
  NO_CONCILIATION_BANK_CONCILIATION_STATUS = "noConciliationBankConciliationStatus",
}
