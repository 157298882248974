import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./reducers/app/app";
import merchantNodesReducer from "./reducers/nodes/node";
import massiveBranch from "./reducers/massiveMerchant/massiveMerchant";
import processBranchFileReducer from "./reducers/processBranchFile/processBranchFile";
import tableDataReducer from "./reducers/tableData/tableData";
import createMerchantNodeReducer, {
  getNodeInfoSlice,
  searchMerchantByNodeSlice,
} from "./reducers/createNode/createNode";
import resumeNode from "./reducers/resumeNode/resumeNode.slice";
import merchantResponseSlice from "./reducers/merchantResponse/merchantResponse";

export const store = configureStore({
  devTools: process.env.NEXT_PUBLIC_ENV_NAME !== "primary",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    app: appReducer,
    createMerchant: createMerchantNodeReducer,
    getNodeInfo: getNodeInfoSlice.reducer,
    massiveBranch: massiveBranch,
    merchantNodes: merchantNodesReducer,
    merchantResponse: merchantResponseSlice,
    nodeInfoByNodeId: searchMerchantByNodeSlice.reducer,
    processBranchFile: processBranchFileReducer,
    resumeNode: resumeNode,
    tableData: tableDataReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
