import {
  BoardMember,
  CompliancePeopleNodeResponse,
} from "../../../types/get_compliance_people_response";
import {
  IBoardMember,
  PersonCategoryEnum,
} from "../../store/interfaces/LegalDetailsState.interfaces";
import { v4 } from "uuid";
import { PersonTypeEnum } from "../constants/initial_state_legal_details";
import { transformUnixTimestampToFormatDate } from "./date_utils";
import { get } from "lodash";
import { DateFormatEnum } from "../enum/DateFormatEnum";

export const buildBoardMember = (boardMember: BoardMember): IBoardMember => {
  const auxId: string = v4();

  const builtBoardMember: object = {
    [PersonTypeEnum.FISICA]: {
      birthdate: transformUnixTimestampToFormatDate(
        get(boardMember, "birthdate", new Date().getTime()) as number,
        DateFormatEnum.DD_MMM_YYYY
      ),
      documentNumber: get(boardMember, "documentNumber", ""),
      documentType: get(boardMember, "documentType", ""),
      id: get(boardMember, "id", auxId),
      lastNames: get(boardMember, "lastNames", ""),
      names: get(boardMember, "names", ""),
      personCategory: get(boardMember, "personCategory") as PersonCategoryEnum,
      personType: get(boardMember, "personType"),
      residencyCountry: get(boardMember, "residencyCountry", ""),
      uid: get(boardMember, "uid", auxId),
    },
    [PersonTypeEnum.MORAL]: {
      addressCountry: get(boardMember, "addressCountry", ""),
      constitutionDate: transformUnixTimestampToFormatDate(
        get(boardMember, "constitutionDate", new Date().getTime()),
        DateFormatEnum.DD_MMM_YYYY
      ),
      documentNumber: get(boardMember, "documentNumber", ""),
      id: get(boardMember, "id", auxId),
      personCategory: get(boardMember, "personCategory") as PersonCategoryEnum,
      personType: get(boardMember, "personType"),
      socialReason: get(boardMember, "socialReason", ""),
      uid: get(boardMember, "uid", auxId),
    },
  };

  return builtBoardMember[
    get(boardMember, "personType", PersonTypeEnum.FISICA)
  ];
};

export const handleCompliancePeopleBMemberUtil = (
  compliancePeople: CompliancePeopleNodeResponse
) => {
  return get(compliancePeople, "boardmembers", []).map(
    (boardMember: BoardMember) => {
      return buildBoardMember(boardMember);
    }
  );
};
