import { ICatalogInfoPopupState } from "../CatalogInfoPopup.interfaces";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import {
  getRetentionCatalogsByCountry,
  setBase64CatalogsMatchWithMerchantRetentionConfig,
  setCatalogRequest,
  setIsOpenCatalogInfoPopup,
} from "../../../store/actionCreators";
import { CountryRetentionEnum } from "../../../shared/infrastructure/Enums/CountryEnum";
import { getRetentionType } from "../../../utils/functions/Catalog";

export const useCatalogInfoPopupState = (): ICatalogInfoPopupState => {
  const dispatch: Dispatch<any> = useDispatch();

  const handleClosePopup = (): void => {
    dispatch(setCatalogRequest({ action: "", code: "", id: "", value: "" }));
    dispatch(setIsOpenCatalogInfoPopup(false));
    dispatch(setBase64CatalogsMatchWithMerchantRetentionConfig(""));
  };

  const handleRetentionListByType = (catalogName: string): void => {
    dispatch(
      getRetentionCatalogsByCountry(
        CountryRetentionEnum.ecuador,
        getRetentionType(catalogName)
      )
    );
  };

  return {
    handleClosePopup,
    handleRetentionListByType,
  };
};
