import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";

export const walletTableStyle = makeStyles({
  date: {
    color: "#46525C",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "136%",
  },
  downloadButton: {
    backgroundColor: "white",
    color: "#293036",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    height: "38px",
    lineHeight: "140%",
    width: "140px",
  },
  emptyResult: {
    alignItems: "center",
    backgroundColor: "#F0F4F9",
    display: "flex",
    height: theme.spacing(61.375),
    justifyContent: "center",
    width: "100%",
  },
  greenChip: {
    backgroundColor: "#E0FFF8",
    borderRadius: "4px",
    height: "22px",
    width: "112px",
    paddingLeft: "17px",
    paddingRight: "17px",
  },
  greenChipText: {
    color: "#3B9D86",
    fontSize: "14px",
    fontStyle: "normal",
    textAlign: "center",
  },
  redChip: {
    backgroundColor: "#FFEAEE",
    borderRadius: "4px",
    height: "22px",
    width: "112px",
    paddingLeft: "17px",
    paddingRight: "17px",
  },
  yellowChip: {
    backgroundColor: "#FFDC87",
    borderRadius: "4px",
    height: "22px",
    width: "112px",
    paddingLeft: "17px",
    paddingRight: "17px",
  },
  redChipText: {
    color: "#AD0C2A",
    fontSize: "14px",
    fontStyle: "normal",
    textAlign: "center",
  },
  yellowChipText: {
    color: "#CF711A",
    fontSize: "14px",
    fontStyle: "normal",
    textAlign: "center",
  },
  textItem: {
    color: "#293036",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "136%",
  },
  title: {
    color: "#46525C",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "bold",
  },
});
