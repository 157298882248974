/* istanbul ignore file */
import { IOptionSelection } from "./infrastructure/constants/RulesConstants";
import { get, defaultTo } from "lodash";
import { TRANSLATE_PROCESSOR } from "./infrastructure/catalogs/DeferredTypeEcuadorEnum";
import { CountryEnum } from "./infrastructure/catalogs/CountryEnum";

export const translateRuleKeys = (
  text: string,
  targets: IOptionSelection[]
): string => {
  if (text === undefined) return "";
  const index: number = targets.findIndex(
    (detail: IOptionSelection) => detail.value === text
  );

  if (index >= 0) {
    const translation: string | undefined = get(targets, `[${index}].name`);

    return translation !== undefined ? translation : text;
  }

  return text;
};

export const translateProcessor = (processor: string): string => {
  return defaultTo(TRANSLATE_PROCESSOR[processor], processor);
};

export const centralcounties: string[] = [
  CountryEnum.PANAMA,
  CountryEnum.EL_SALVADOR,
  CountryEnum.PANAMA,
  CountryEnum.COSTA_RICA,
  CountryEnum.NICARAGUA,
  CountryEnum.HONDURAS,
  CountryEnum.GUATEMALA,
];
