import { CountriesEnum } from "../infrastructure/countries-enum";
import { CatalogMx } from "../services/catalog-mx";
import { CatalogEc } from "../services/catalog-ec";
import { CatalogBr } from "../services/catalog-br";
import { CatalogCl } from "../services/catalog-cl";
import { CatalogCo } from "../services/catalog-co";
import { CatalogCr } from "../services/catalog-cr";
import { CatalogEl } from "../services/catalog-el";
import { CatalogPa } from "../services/catalog-pa";
import { CatalogPe } from "../services/catalog-pe";
import { CatalogGt } from "../services/catalog-gt";
import { CatalogHn } from "../services/catalog-hn";
import { CatalogNi } from "../services/catalog-ni";
import { CatalogEeuu } from "../services/catalog-eeuu";
import { CatalogDefault } from "../services/catalog-default";

export const INVOICE_FRECUENCY: { name: string; value: string }[] = [
  { name: "Ninguna", value: "none" },
  { name: "Diaria", value: "daily" },
  { name: "Semanal", value: "weekly" },
  { name: "Mar-Vie", value: "twoWeekly" },
  { name: "Quincenal", value: "biWeekly" },
  { name: "Lun-Mie-Vie", value: "threeWeekly" },
  { name: "Mensual", value: "monthly" },
];

export enum InvoiceModeEnum {
  WITH_IVA = "with_iva",
  WITHOUT_IVA = "without_iva",
}

export const InvoiceModeCatalog = [
  {
    name: "Con IVA",
    value: InvoiceModeEnum.WITH_IVA,
  },
  {
    name: "Sin IVA",
    value: InvoiceModeEnum.WITHOUT_IVA,
  },
];

export interface Category {
  name: string;
  value: string | number;

  [k: string]: any;
}

export const getCatalog = (country: string, catalog: string) => {
  switch (country) {
    case CountriesEnum.ECUADOR:
      return CatalogEc[catalog];
    case CountriesEnum.BRAZIL:
      return CatalogBr[catalog];
    case CountriesEnum.CHILE:
      return CatalogCl[catalog];
    case CountriesEnum.COLOMBIA:
      return CatalogCo[catalog];
    case CountriesEnum.COSTA_RICA:
      return CatalogCr[catalog];
    case CountriesEnum.EL_SALVADOR:
      return CatalogEl[catalog];
    case CountriesEnum.MEXICO:
      return CatalogMx[catalog];
    case CountriesEnum.PANAMA:
      return CatalogPa[catalog];
    case CountriesEnum.PERU:
      return CatalogPe[catalog];
    case CountriesEnum.GUATEMALA:
      return CatalogGt[catalog];
    case CountriesEnum.NICARAGUA:
      return CatalogNi[catalog];
    case CountriesEnum.HONDURAS:
      return CatalogHn[catalog];
    case CountriesEnum.ESTADOS_UNIDOS:
      return CatalogEeuu[catalog];
    default:
      return CatalogDefault[catalog];
  }
};
