import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export interface HeaderHistoryProps {
  isAdmin: boolean;
}

const useStyles = makeStyles({
  container: {
    marginBottom: "30px",
  },
  typography: {
    fontWeight: () => 200,
  },
});

export const HeaderHistory: React.FC<HeaderHistoryProps> = (
  props: HeaderHistoryProps
) => {
  const classes = useStyles(props);

  return (
    <React.Fragment>
      <Grid container spacing={1} className={classes.container}>
        <Grid
          item
          xs={6}
          md={6}
          container
          alignItems="center"
          justify="flex-start"
          direction="row"
        >
          <Typography
            className={classes.typography}
            color={"primary"}
            variant={"h1"}
          >
            Soporte
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
