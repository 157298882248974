import React from "react";
import { Box, Typography } from "@mui/material";
import ModalForm from "../ModalForm/ModalForm";
import { BoardMembersSectionProps } from "./BoardMembersSection.interfaces";
import { boardMembersSectionStyle as styles } from "./BoardMembersSection.styles";
import SectionTitle from "../../SectionTitle/SectionTitle";
import { ComponentIdsEnum } from "../../../shared/constants/labels/main_containter_labels";
import { useBoardMembersState } from "./state/useBoardMembersState";
import BoardMembers from "../../BoardMembers/BoardMembers";
import { ModalSimple } from "@kushki/connect-ui";
import { BoardMemberModalEnum } from "../../../shared/enum/BoardMemberContentEnum";

const BoardMembersSection: React.FC<BoardMembersSectionProps> = ({
  title,
  description,
  buttonText,
  modalSize,
  isDisable = false,
  boardMembers,
}: BoardMembersSectionProps) => {
  const {
    formComponent,
    isOpenModal,
    openModal,
    openDeleteModal,
    closeDeleteModal,
    isOpenDeleteModal,
    setSelectedBoardMember,
    onClickDeleteConfirm,
  } = useBoardMembersState();

  return (
    <Box sx={styles.container}>
      <SectionTitle
        buttonText={buttonText}
        componentId={ComponentIdsEnum.M_CLIENTS_ADD_LEGAL_REPRESENTATIVE_BUTTON}
        handleOnClick={openModal}
        isDisabled={isDisable}
        title={title}
      />
      <Box>
        <Typography variant="caption" color="text.lightGrey">
          {description}
        </Typography>
      </Box>
      <ModalForm modalSize={modalSize} openModal={isOpenModal}>
        {formComponent}
      </ModalForm>
      <ModalSimple
        onClickPrimary={onClickDeleteConfirm!}
        onClickSecondary={closeDeleteModal!}
        buttonPrimary={BoardMemberModalEnum.CONFIRM}
        buttonSecondary={BoardMemberModalEnum.CANCEL}
        descriptionText={BoardMemberModalEnum.DESCRIPTION_SIMPLE}
        isOpen={isOpenDeleteModal}
        onClose={closeDeleteModal!}
        titleText={BoardMemberModalEnum.TITLE_SIMPLE}
        typeModal={1}
        buttonPrimaryDisabled={false}
      />
      <BoardMembers
        openDeleteModal={openDeleteModal!}
        boardMembers={boardMembers}
        setSelectedBoardMember={setSelectedBoardMember}
      />
    </Box>
  );
};

export default BoardMembersSection;
