import React from "react";
import { Box, Typography } from "@mui/material";
import { useTabItemStyles } from "./Tabitem.styles";
import { ITabItem } from "./TabItem.interfaces";
import { useTabItem } from "./state/useTabItem";
import { IconCircleCheck } from "@kushki/connect-ui";
import { TabItemStatusEnum } from "../../../shared/enum/tabItemStatusEnum";

const TabItem: React.FC<ITabItem> = ({
  title,
  status,
  redirectPath,
  isDisabled,
}: ITabItem) => {
  const { selected, redirectTo, statusText, statusColor } = useTabItem(
    status,
    isDisabled,
    redirectPath
  );
  const styles = useTabItemStyles(isDisabled, selected, statusColor);

  return (
    <Box
      component={"button"}
      sx={styles.tabContainer}
      disabled={isDisabled}
      onClick={redirectTo}
    >
      <Typography variant="body2" sx={styles.titleText}>
        {title}
      </Typography>
      <Box sx={styles.statusContainer}>
        {status === TabItemStatusEnum.COMPLETE && !isDisabled && (
          <Box sx={styles.icon}>
            <IconCircleCheck color={"success"} fontSize="small" />
          </Box>
        )}
        <Typography variant="overline" sx={styles.statusText}>
          {statusText()}
        </Typography>
      </Box>
    </Box>
  );
};

export default TabItem;
