import React, { FC } from "react";
import { Alert, Box, IconButton, Snackbar, Typography } from "@mui/material";
import { myMerchantContainerStyles as styles } from "./MyMerchantContainer.styles";
import { clipboard } from "../../shared/clipboard";
import { Copy } from "react-feather";
import { defaultTo, get, isEmpty } from "lodash";
import {
  ConfiguracionGlobal,
  IdComercio,
  MiComercio,
} from "../../shared/constants/labels/main_containter_labels";
import { useMyMerchant } from "./state/useMyMerchant";
import { Flag } from "../../components/Flag/Flag";
import { CountryEnum } from "../../shared/enums/CountryEnum";
import { TitleAccordionEnum } from "../../shared/enums/TitleAccordionEnum";
import Accordion from "../../components/Accordion/Accordion";
import BranchList from "../../components/BranchList/BranchList";
import { RatesInfo } from "../RatesInfo/RatesInfo";
import { CountriesEnum } from "../../shared/enums/CountriesEnum";
import IconBilling from "../../assets/icons/IconBilling";
import { ServicesInfo } from "../ServicesInfo/ServicesInfo";
import IconServiceTitle from "../../assets/icons/IconServiceTitle";
import { SubHeaderSkeleton } from "../../components/Skeleton/SubHeaderSkeleton/SubHeaderSkeleton";
import IconPaymentMethod from "../../assets/icons/iconsPaymentMethod";
import PaymentMethod from "../../components/PaymentMethod/PaymentMethod";
import { AccordionSkeleton } from "../../components/Skeleton/Accordion/AccordionSkeleton";
import IconBranches from "../../assets/icons/IconBranches";
import { selectDeferredCountry } from "../../shared/utils/utils";
import IconDeferred from "../../assets/icons/IconDeferred";
import IconRates from "../../assets/icons/IconRates";
import { BillingInfo } from "../BillingInfo/BillingInfo";
import { environment } from "../../environments/environment";

const MyMerchantContainer: FC = () => {
  const {
    country,
    isMasterCustomer,
    isUserMaster,
    merchantInfo,
    merchantDeferred,
    ratesInfo,
    retentionInfo,
    processors,
    isCustomerNode,
    isLoadingServices,
    snackBar,
  } = useMyMerchant();

  return (
    <Box sx={styles.boxContainer}>
      <Box sx={styles.box}>
        <Box sx={styles.boxHeader}>
          <Typography sx={styles.route}>{ConfiguracionGlobal}</Typography>
          <Typography color="primary" sx={styles.title}>
            {MiComercio}
          </Typography>
        </Box>
        {isEmpty(merchantInfo.publicMerchantId) ? (
          <SubHeaderSkeleton />
        ) : (
          <Box sx={styles.boxSubHeader}>
            <Box alignSelf={"flex-start"}>
              <Box sx={styles.boxSubHeaderMerchant}>
                <Typography variant={"h4"} color={"primary"}>
                  {get(merchantInfo, "name", "")}
                </Typography>
                {isMasterCustomer && (
                  <Box sx={styles.flagPadding}>
                    <Flag country={merchantInfo.country as CountryEnum} />
                  </Box>
                )}
              </Box>
              <Typography sx={styles.webText}>
                {get(merchantInfo, "webSite", "")}
              </Typography>
            </Box>
            <Box>
              <Typography sx={styles.text}>{IdComercio}</Typography>
              <Box sx={styles.boxSubHeaderMerchant}>
                <Typography sx={styles.text}>
                  {get(merchantInfo, "publicMerchantId", "")}
                </Typography>
                <IconButton
                  data-testid={"IconCopy"}
                  sx={styles.iconCopy}
                  onClick={(e) => {
                    clipboard(get(merchantInfo, "publicMerchantId", ""));
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <Copy />
                </IconButton>
              </Box>
            </Box>
          </Box>
        )}
        <Box sx={styles.boxContent}>
          {isMasterCustomer && isCustomerNode && (
            <Accordion
              iconLeft={<IconBranches fontSize={"large"} />}
              isOpen={true}
              text={TitleAccordionEnum.SECTION_BRANCHES}
              sectionComponent={<BranchList />}
              iconSummaryDown={false}
            />
          )}
          {isLoadingServices ? (
            <AccordionSkeleton />
          ) : (
            <Accordion
              iconLeft={<IconPaymentMethod data-testid={"iconPaymentMethod"} />}
              isOpen={false}
              text={TitleAccordionEnum.SECTION_PAYMENT_METHODS}
              sectionComponent={<PaymentMethod paymentMethod={processors} />}
              iconSummaryDown={false}
            />
          )}
          {isLoadingServices ? (
            <AccordionSkeleton />
          ) : (
            <Accordion
              iconLeft={<IconServiceTitle fontSize={"large"} />}
              isOpen={false}
              text={TitleAccordionEnum.SECTION_SERVICES}
              sectionComponent={
                <ServicesInfo
                  publicMerchantId={merchantInfo.publicMerchantId}
                />
              }
              iconSummaryDown={false}
            />
          )}
          {isLoadingServices ? (
            <AccordionSkeleton />
          ) : (
            <Accordion
              iconLeft={<IconBilling fontSize={"large"} />}
              isOpen={false}
              text={
                country === CountriesEnum.COLOMBIA &&
                defaultTo(environment.retentionSwitch, false) &&
                isUserMaster
                  ? TitleAccordionEnum.SECTION_BILLING_COLOMBIA
                  : TitleAccordionEnum.SECTION_BILLING
              }
              sectionComponent={
                <BillingInfo
                  country={country}
                  merchantInfo={merchantInfo}
                  paymentMethod={processors}
                  retentionInfo={retentionInfo}
                  isUserMaster={isUserMaster}
                />
              }
              iconSummaryDown={false}
            />
          )}
          {isLoadingServices ? (
            <AccordionSkeleton />
          ) : (
            <Accordion
              iconLeft={<IconRates fontSize={"large"} />}
              isOpen={false}
              text={TitleAccordionEnum.SECTION_RATES}
              sectionComponent={
                <RatesInfo
                  merchantInfo={merchantInfo}
                  ratesProps={ratesInfo}
                  country={merchantInfo.country as CountriesEnum}
                />
              }
              iconSummaryDown={false}
            />
          )}
          {[
            CountriesEnum.ECUADOR,
            CountriesEnum.PERU,
            CountriesEnum.MEXICO,
            CountriesEnum.BRAZIL,
          ].includes(defaultTo(merchantInfo.country, "") as CountriesEnum) && (
            <Accordion
              iconLeft={<IconDeferred fontSize={"large"} />}
              isOpen={false}
              text={TitleAccordionEnum.SECTION_DEFERRED}
              sectionComponent={selectDeferredCountry(
                merchantDeferred,
                merchantInfo?.country
              )}
              iconSummaryDown={false}
            />
          )}
        </Box>
      </Box>
      <Snackbar open={snackBar.showSnackBar} autoHideDuration={2000}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {snackBar.snackBarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MyMerchantContainer;
