export const CountriesName = ["ecuador", "chile", "peru", "mexico", "colombia"];
export const isValidCountry = (country: string) => {
  const filteredCountry = country.split("Console.Country.")[1];
  return CountriesDefault[filteredCountry] ? filteredCountry : "";
};
export const CountriesDefault = {
  ["ecuador"]: {
    name: "Ecuador",
    value: "Ecuador",
  },
  ["chile"]: {
    name: "Chile",
    value: "Chile",
  },
  ["peru"]: {
    name: "Perú",
    value: "Peru",
  },
  ["mexico"]: {
    name: "México",
    value: "Mexico",
  },
  ["colombia"]: {
    name: "Colombia",
    value: "Colombia",
  },
};
