import React, { useEffect, useRef, useState } from "react";
import {
  IFiltersDashboard,
  IUseFilterDashboard,
  IUseFilterDashboardProps,
} from "./useDashboardBranch.interfaces";
import { TFilterDetailBar } from "@kushki/connect-ui/dist/Components/Molecules/Filters/FilterDetailBar/FilterDetailBar.interfaces";
import { SearchMerchantRequest } from "../../../../types/search_merchant_request";
import { get, has, isEmpty, isNil, omitBy, set } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { builderRows } from "../../Table/TableNodes/constants";
import { ITableRowProps } from "../../Table/TableNodes/interfaces";
import { MerchantNodeData } from "../../../../types/search_merchant_response";
import { IFilterItem } from "@kushki/connect-ui/dist/Components/Atoms/Filters/FilterDetailItem/FilterDetailItem.interfaces";
import {
  CATALOG_ROWS,
  TableTypeEnum,
} from "../../../shared/catalogs/CatalogConfigTable";
import { FILTER_LABELS } from "../../../shared/constants/labels/filter_labels";
import { searchBranchMerchants } from "../../../store/thunks/branch/searchMerchants.thunks";
import { downloadFile as downloadFileThunk } from "../../../store/thunks/downloadFile/downloadFile.thunk";
import { RootState } from "../../../store/store";
import { setNotification } from "../../../store/actions/downloadFile.actions";
import { useSnackbar } from "@kushki/connect-ui";
import { DownloadFile } from "../../../shared/utils/dashboard_branch_utils";
import { isCustomerRol } from "../../../shared/utils";
import { StatusEnum } from "../../../shared/enums/StatusEnum";

export const useDashboardBranch = ({
  entityName,
  path,
  nodeData,
  showEmptyScreen,
}: IUseFilterDashboardProps): IUseFilterDashboard => {
  const dispatchApp = useAppDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const totalData: number = get(nodeData, "total", 0);
  const { showSnackbar } = useSnackbar();

  const [initialFilters] = useState<IFiltersDashboard>({
    entityName,
    paginationFilters: {
      offset: rowsPerPage * page,
      rowsPerPage: rowsPerPage,
    },
    path,
  });
  const [filterDashboard, setFilterDashboard] =
    useState<IFiltersDashboard>(initialFilters);
  const [resetFilters, setResetFilters] = useState<boolean>();
  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const [resetItemChecked, setResetItemChecked] = useState<boolean>();
  const [hasFilters, setHasFilters] = useState<boolean>(false);
  const [countData, setCountData] = useState<number>(0);
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
    setFilterDashboard({
      ...filterDashboard,
      paginationFilters: {
        ...filterDashboard.paginationFilters,
        offset: rowsPerPage * newPage,
      },
    });
  };

  const { downloadFile, notification, isLoadingDownloadFile } = useAppSelector(
    (state: RootState) => ({
      ...state.downloadFileApp,
    })
  );

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilterDashboard({
      ...filterDashboard,
      paginationFilters: {
        ...filterDashboard.paginationFilters,
        offset: 0,
        rowsPerPage: parseInt(event.target.value, 10),
      },
    });
  };

  const buildStringFilter = (filterItem: IFilterItem[]): string => {
    let value: string = "";

    filterItem.map((item: IFilterItem, index: number) => {
      value = value.concat(item.value);
      if (index + 1 < filterItem.length) {
        value = value.concat("|");
      }
    });

    return value;
  };

  const handleDownloadFile = (): void => {
    dispatchApp(downloadFileThunk({ path: path, status: StatusEnum.ACTIVE }));
  };

  const handleChangeFilterDetails = (
    selectedFilters: TFilterDetailBar[]
  ): void => {
    const filterRequest = {};

    selectedFilters.map((filter: TFilterDetailBar) => {
      switch (filter.title) {
        case FILTER_LABELS.CONSTITUTIONAL_COUNTRY:
          set(
            filterRequest,
            "constitutionalCountry",
            buildStringFilter(filter.items)
          );
          break;
        case FILTER_LABELS.STATE:
          set(filterRequest, "merchantStatus", buildStringFilter(filter.items));
          break;
        case FILTER_LABELS.NAME:
          const isNum = /^\d+$/.test(buildStringFilter(filter.items));

          isNum
            ? set(filterRequest, "merchantId", buildStringFilter(filter.items))
            : set(filterDashboard, "name", buildStringFilter(filter.items));
          break;
        case FILTER_LABELS.RANGE_DATE_FROM:
          set(filterDashboard, "from", buildStringFilter(filter.items));
          break;
        case FILTER_LABELS.RANGE_DATE_TO:
          set(filterDashboard, "to", buildStringFilter(filter.items));
          break;
      }
    });

    setPage(0);
    setFilterDashboard({
      ...filterDashboard,
      filterDetailBar: filterRequest,
      paginationFilters: {
        ...filterDashboard.paginationFilters,
        offset: 0,
      },
    });
  };

  const builderSearchMerchantRequest = (
    filters: IFiltersDashboard
  ): SearchMerchantRequest => {
    if (isCustomerRol())
      set(filters, "filterDetailBar.merchantStatus", "active");

    return {
      entityName: get(filters, "entityName"),
      filter: get(filters, "filterDetailBar"),
      from: get(filters, "from"),
      limit: get(filters, "paginationFilters.rowsPerPage"),
      name: has(get(filters, "filterDetailBar"), "merchantId")
        ? undefined
        : get(filters, "name"),
      offset: get(filters, "paginationFilters.offset"),
      path: get(filters, "path"),
      strictSearch: !has(get(filters, "filterDetailBar"), "merchantId")
        ? true
        : false,
      to: get(filters, "to"),
    };
  };

  const rows: ITableRowProps[] = builderRows<MerchantNodeData>(
    get(nodeData, "data", []),
    CATALOG_ROWS[TableTypeEnum.BRANCH],
    entityName,
    [],
    page.toString()
  );

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (!isEmpty(downloadFile.url)) DownloadFile(downloadFile.url);
  }, [downloadFile.url]);

  useEffect(() => {
    if (notification) {
      showSnackbar(notification);
      dispatchApp(setNotification(undefined));
    }
  }, [notification]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;

      return;
    }
    const filters: SearchMerchantRequest =
      builderSearchMerchantRequest(filterDashboard);
    const clean_filters: object = omitBy(
      filters,
      (item) => isNil(item) || isEmpty(item)
    );

    setIsFiltering(
      has(clean_filters, "filter") ||
        has(clean_filters, "name") ||
        has(clean_filters, "from") ||
        has(clean_filters, "to")
    );
    dispatchApp(searchBranchMerchants(filters));
  }, [filterDashboard]);

  useEffect(() => {
    if (!isEmpty(path)) {
      setHasFilters(false);
      setCountData(0);
      setFilterDashboard({
        ...initialFilters,
        entityName,
        path,
      });
    }
    setResetFilters(!resetFilters);
  }, [path, entityName]);

  useEffect(() => {
    setResetItemChecked(!resetItemChecked);
  }, [path, entityName, page]);

  useEffect(() => {
    if (!hasFilters) {
      setCountData(get(nodeData, "total", 0));
      setHasFilters(true);
    }
  }, [nodeData]);

  return {
    countData,
    handleChangeFilterDetails,
    handleDownloadFile,
    isLoadingDownloadFile,
    paginationProps: {
      handleChangePage,
      handleChangeRowsPerPage,
      page,
      rowsPerPage,
      totalData,
    },
    resetFilters,
    rows,
    showEmptyScreen: isFiltering ? false : showEmptyScreen,
  };
};
