export const LanguageCatalog: ICategory[] = [
  {
    lang: "daily",
    es: "Diaria",
  },
  {
    lang: "threeweekly",
    es: "Lun-Mie-Vie",
  },
  {
    lang: "biweekly",
    es: "Quincenal",
  },
  {
    lang: "weekly",
    es: "Semanal",
  },
  {
    lang: "monthly",
    es: "Mensual",
  },
  {
    lang: "quarterly",
    es: "Trimestral",
  },
  {
    lang: "annual",
    es: "Anual",
  },
  {
    lang: "invoice",
    es: "Factura",
  },
  {
    lang: "charge",
    es: "Cobro",
  },
  {
    lang: "big",
    es: "Grande",
  },
  {
    lang: "medium",
    es: "Mediana",
  },
  {
    lang: "small",
    es: "Pequeña",
  },
  {
    lang: "dispersion",
    es: "Dispersión",
  },
  {
    lang: "retention_dispersion",
    es: "Retención",
  },
  {
    lang: "credit_note",
    es: "N. de crédito",
  },
  {
    lang: "debit_note",
    es: "N. de débito",
  },
  {
    lang: "voucher",
    es: "Boleta",
  },
  {
    lang: "pending",
    es: "Pendiente",
  },
  {
    lang: "manual",
    es: "Manual",
  },
  {
    lang: "omit",
    es: "Omitida",
  },
  {
    lang: "omitted",
    es: "Omitida",
  },
  {
    lang: "emitted",
    es: "Emitida",
  },
  {
    lang: "completed",
    es: "Aprobada",
  },
  {
    lang: "failed",
    es: "Declinada",
  },
  {
    lang: "finished",
    es: "Liquidada",
  },
  {
    lang: "successful",
    es: "Exitosa",
  },
  {
    lang: "card",
    es: "Tarjeta",
  },
  {
    lang: "to_charge",
    es: "Por cobrar",
  },
  {
    lang: "approval",
    es: "Aprobada",
  },
  {
    lang: "rejected",
    es: "Rechazada",
  },
  {
    lang: "processed",
    es: "Procesado",
  },
  {
    lang: "with_iva",
    es: " con IVA",
  },
  {
    lang: "without_iva",
    es: " sin IVA",
  },
  {
    lang: "payincommission",
    es: "Pay-In",
  },
  {
    lang: "payoutcommission",
    es: "Pay-Out",
  },
  {
    lang: "in_process",
    es: "En proceso",
  },
  {
    lang: "sent",
    es: "Enviada",
  },
  {
    lang: "sent_edit",
    es: "Solicitud de edición",
  },
  {
    lang: "sent_create",
    es: "Solicitud de nueva regla",
  },
];

export interface ICategory {
  lang: string;
  es?: string;
}
