import {
  Configs,
  GetNodeInfoResponse,
} from "../../../types/get_node_info_response";
import { ConfigurationEnum } from "../infrastructure/constants/ConfigurationEnum";

export const isCentralizedConfig = (
  node: GetNodeInfoResponse,
  config: ConfigurationEnum,
  merchantId: string
): boolean => {
  if (node.configs && node.configs.length > 0)
    return isSectionCentralized(node.configs, config, merchantId);

  return false;
};

const isSectionCentralized = (
  configs: Configs[],
  section: ConfigurationEnum,
  merchantId: string
): boolean => {
  console.log(merchantId);
  const config = getConfigNode(configs, section);

  if (config && config.centralizedNodesId && config.value != merchantId)
    return true;

  return false;
};

const getConfigNode = (
  configs: Configs[],
  section: ConfigurationEnum
): Configs | undefined => {
  return configs
    .filter((config: Configs) => config.configuration === section)
    .shift();
};
