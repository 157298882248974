import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const transactionContainerStyles = createNamedStyles({
  main: {
    background: "#fff",
    borderRadius: "8px",
    display: "flex",
    flexWrap: "wrap",
    padding: "52px 40px",
    width: "100%",
  },
  checkChips: {
    width: "100%",
  },
});
