import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { breadcrumbStyles } from "./Breadcrumb.styles";
import { IBreadcrumbItem, IBreadcrumbProps } from "./Breadcrumb.interfaces";
import { FC } from "react";

const Breadcrumb: FC<IBreadcrumbProps> = ({ items, lastItem }) => (
  <Box mb={2}>
    <Breadcrumbs separator={">"} aria-label="breadcrumb">
      {items.map((item: IBreadcrumbItem) => (
        <Link
          key={item.label}
          sx={breadcrumbStyles.text}
          component={RouterLink}
          to={item.url}
        >
          {item.label}
        </Link>
      ))}
      <Typography sx={breadcrumbStyles.text}>{lastItem}</Typography>
    </Breadcrumbs>
  </Box>
);

export default Breadcrumb;
