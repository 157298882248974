import { filtersSlice } from "../../reducers/filters/filters";

export const {
  setDateFilter,
  setGeneralFilter,
  setPaginationFilter,
  setSearchFilter,
  setSortOrder,
  setTransactionsLimit,
  setTransactionsPage,
  setTransactionsTotal,
} = filtersSlice.actions;
