import { get, isNil } from "lodash";
import {
  IRefundIndexState,
  IState,
  KshFile,
} from "./useConfigByFileIndexState.interfaces";
import { useHistory, useLocation } from "react-router-dom";
import { auth } from "../../../shared/utils/auth";
import { DispersionResponse } from "../../../../types/dispersion_response";
import { IAppState } from "../../../store/reducer";
import { ResponseStatusEnum } from "../../../shared/infrastructure/Enums/ResponseStatusEnum";
import { routes } from "../../../shared/infrastructure/routes";
import { setIsLoadingDispersion } from "../../../store/actionCreators";
import { uploadDispersionCycles } from "../../../store/actionCreators";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useState } from "react";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const useConfigByFileIndexState = (): IRefundIndexState => {
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const country: string = query.get("country") as string;
  const type: string = query.get("type") as string;
  const [state] = useState<IState>({});
  const [openAlertConfirmModal, setOpenAlertConfirmModal] =
    useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [errors, setErrors] = useState<DispersionResponse>({
    errors: [],
    records: 0,
    status: "",
  });

  const { dispersionResponse } = useSelector((state: IAppState) => state);

  state.isLoadingDispersion = useSelector(
    (state: IAppState) => state.isLoadingDispersion!
  );
  const handelActionModal = () => {
    setOpenAlertConfirmModal(!openAlertConfirmModal);
  };

  useEffect(() => {
    if (isNil(dispersionResponse)) return;

    if (dispersionResponse.status == ResponseStatusEnum.FAILED) {
      setErrors(dispersionResponse);
      setOpenModal(true);
    }
    if (dispersionResponse.status == ResponseStatusEnum.OK) {
      handelActionModal();
    }
  }, [dispersionResponse]);

  const actionHandlerClose = () => {
    setOpenModal(false);
  };
  const { register } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const [disableUploadButton, setDisableUploadButton] = useState<boolean>(true);
  const [file, setFile] = useState<KshFile>();

  // eslint-disable-next-line no-console
  console.log("for use form-hook", register);
  /*
   * You can use useSelector to access data from redux store
   * */
  /*
   * You can use useDispatch to execute functions of actionCreators
   * */
  const handleClickReturnGlobalConfig = (): void => {
    history.push("/global-config");
  };

  const handleCaptureFile = (ksh_file: KshFile) => {
    setFile(ksh_file);
  };
  const handleDisableUpload = (disable: boolean) => {
    setDisableUploadButton(disable);
  };
  const handleDelete = () => {
    handleDisableUpload(true);
    setFile(undefined);
  };
  const handleUploadFile = () => {
    if (!isNil(file)) {
      dispatch(
        uploadDispersionCycles({
          country: country,
          file: file.base64,
          userName: auth.getUserName(),
        })
      );
    }
  };

  const handleCloseAlertModal = () => {
    dispatch(setIsLoadingDispersion(false));
  };

  return {
    breadCrumbs: {
      items: [
        {
          label: "Configuración global",
          url: routes.GLOBAL_CONFIG,
        },
      ],
      lastItem: "Dispersión",
    },
    errorModal: {
      actionHandlerClose,
      errors,
      openModal,
    },
    fileUploader: {
      disableUploadButton,
      file,
      handleCaptureFile,
      handleDelete,
      handleDisableUpload,
      handleUploadFile,
    },
    handleCloseAlertModal,
    handlers: { handleClickReturnGlobalConfig: handleClickReturnGlobalConfig },
    modal: {
      dispersionRecords: get(dispersionResponse, "records", 0),
      handelActionModal: handleClickReturnGlobalConfig,
      openAlertConfirmModal,
    },
    params: { countryParam: country, typeParam: type },
    state,
  };
};
