import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { RootState } from "../../../store/store";
import { useEffect, useState } from "react";
import {
  ArraySemaphoreSteps,
  SemaphoreItemsEnum,
} from "../../enums/SemaphoreItemsEnum";
import { cloneDeep, get, isEmpty, isNil, set } from "lodash";
import {
  CatalogConfigNode,
  CatalogPositionTab,
  CatalogRenderTabsNode,
  CatalogRenderTabsNodeMassive,
  IBuilderTab,
} from "../../catalogs/CatalogRenderTabsNode";
import {
  setSemaphore as setSemaphoreStore,
  setTabsInfo,
} from "../../../store/actions/semaphore.action";
import { IUseMerchantCompanyInfo } from "./useMerchantCompanyInfo.interfaces";
import { TabItemStatusEnum } from "../../enum/tabItemStatusEnum";
import { IWrappedTabs } from "../../../components/WrappedTabs/WrappedTabs.interfaces";
import { ITabItem } from "../../../components/WrappedTabs/TabItem/TabItem.interfaces";
import { FieldsNodeEnum } from "../../enums/FieldsNodeEnum";
import { CountryEnum } from "../../countryEnum";
import { ConfigIdEnum } from "../../constants/initial_state_legal_details";
import { setConfigInfo } from "../../../store/actions/initialData.action";
import {
  createSemaphoreBranches,
  semaphoreBranches,
} from "../../utils/massiveBranchEdit";
import { setBranchBatch } from "../../../store/actions/massiveBranch.actions";
import { searchParams } from "../../utils/useQuery_utils";

export const useMerchantCompanyInfo = (
  masssive?: boolean
): IUseMerchantCompanyInfo => {
  const query = searchParams();
  const dispatch = useAppDispatch();
  const {
    semaphoreData,
    initialData: { configsInfo, nodeInfo },
  } = useAppSelector((state: RootState) => state);
  const publicMerchantId: string = query.publicMerchantId;
  const { branchBatch } = useAppSelector(
    (state: RootState) => state.branchBatch
  );
  const [builderTabs] = useState<IBuilderTab[]>(
    masssive === true ? CatalogRenderTabsNodeMassive : CatalogRenderTabsNode
  );

  const validateConditionActiveTab = (
    key: string,
    statusBefore: TabItemStatusEnum
  ): boolean => {
    if (masssive) return false;

    if (
      key === SemaphoreItemsEnum.basicDetail ||
      statusBefore === TabItemStatusEnum.COMPLETE
    )
      return false;

    return true;
  };
  const validateStatusEmpty = (status: TabItemStatusEnum) => {
    return isEmpty(status) ? TabItemStatusEnum.PENDING : status;
  };
  const handleChangeTabs = (): IWrappedTabs => {
    const tabs: ITabItem[] = [];
    let positionTab: number;
    let config: string;

    ArraySemaphoreSteps.forEach((key: string) => {
      positionTab = CatalogPositionTab[key];
      config = CatalogConfigNode[key];
      tabs[positionTab] = {
        isDisabled: validateConditionActiveTab(
          key,
          validateStatusEmpty(
            get(
              configsInfo,
              `${CatalogConfigNode[builderTabs[positionTab].beforeKey]}.status`,
              TabItemStatusEnum.PENDING
            )
          )
        ),
        redirectPath: builderTabs[positionTab].getRoute(publicMerchantId),
        status: validateStatusEmpty(
          get(configsInfo, `${config}.status`, TabItemStatusEnum.PENDING)
        ),
        title: builderTabs[positionTab].title,
      };
    });

    return { tabs };
  };

  const handleChangeStatusNodeInfo = (
    status: string,
    configId: ConfigIdEnum,
    publicMerchantId?: string
  ) => {
    const oldConfigs = cloneDeep(configsInfo);

    set(oldConfigs, `${configId}.status`, status);
    set(
      oldConfigs,
      `${configId}.value`,
      isNil(publicMerchantId) ? "1" : publicMerchantId
    );
    set(oldConfigs, `${configId}.updatedBy`, localStorage.getItem("username")!);
    set(oldConfigs, `${configId}.updatedAt`, Date.now());
    dispatch(setConfigInfo(oldConfigs));
  };

  useEffect(() => {
    dispatch(setTabsInfo(handleChangeTabs()));
  }, [configsInfo]);
  useEffect(() => {
    if (masssive == true) {
      dispatch(setBranchBatch(createSemaphoreBranches()));

      return;
    }
  }, []);

  useEffect(() => {
    if (masssive == true) dispatch(setSemaphoreStore(semaphoreBranches()));
  }, [branchBatch]);

  return {
    countriesInfo: {
      constitutionalCountry: get(
        nodeInfo,
        `${FieldsNodeEnum.generalInfo}.${FieldsNodeEnum.constitutionalCountry}`,
        ""
      ) as CountryEnum,
      country: get(
        nodeInfo,
        `${FieldsNodeEnum.generalInfo}.${FieldsNodeEnum.country}`,
        ""
      ) as CountryEnum,
    },
    handleChangeStatusNodeInfo,
    headerProps: {
      nodeInfo,
      tabsNodeInfo: semaphoreData.tabsNodeInfo,
    },
  };
};
