import {
  HeaderCellProps,
  ITableHeaderProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { ISort } from "@kushki/connect-ui/dist/Shared/Utils/IconSortArrow/IconSortArrow.interfaces";
import { Sort } from "../../../types/search_merchants_request";

export enum FieldNameEnum {
  NAME = "name",
  OWNER = "owner",
  CUSTOMER = "customer",
}

export const RuleDetailTableCells = (
  onClickSort: Function,
  actualSort: ISort,
  numClick: number
): HeaderCellProps[] => [
  {
    actualSort,
    align: "left",
    field: FieldNameEnum.NAME,
    isSort: true,
    numClick,
    onClickSort,
    spacing: 0,
    text: "Nombre",
    type: "default",
    width: 250,
  },
  {
    align: "left",
    spacing: 0,
    text: "Branch/Merchant Id",
    type: "default",
    width: 250,
  },
  {
    actualSort,
    align: "left",
    field: FieldNameEnum.OWNER,
    isSort: true,
    numClick,
    onClickSort,
    spacing: 0,
    text: "Owner",
    type: "default",
    width: 250,
  },
  {
    actualSort,
    align: "left",
    field: FieldNameEnum.CUSTOMER,

    isSort: true,
    numClick,
    onClickSort,
    spacing: 0,
    text: "Customer",
    type: "default",
    width: 250,
  },
  {
    align: "left",
    spacing: 0,
    text: "País de constitución",
    type: "default",
    width: 250,
  },
  {
    align: "left",
    spacing: 0,
    text: "País de operación",
    type: "default",
    width: 250,
  },
  {
    align: "left",
    spacing: 0,
    text: "Categoría",
    type: "default",
    width: 250,
  },
];

export const FIELD_SORT_CATALOGUE = {
  [FieldNameEnum.NAME]: "cells[0].props.line1",
  [FieldNameEnum.OWNER]: "cells[1].props.line1",
  [FieldNameEnum.CUSTOMER]: "cells[2].props.line1",
};

const RULE_DETAIL_TABLE_CELLS = (
  onClickSort: Function,
  actualSort: ISort,
  numClick: number
): ITableHeaderProps => ({
  cells: RuleDetailTableCells(onClickSort, actualSort, numClick),
  headerProps: {
    isDisable: false,
  },
});

const RULE_DETAIL_FOOTER_TEXT: string = "Branches por página";

const orderNumToStr = (valueNumber: number): Sort["order"] => {
  switch (valueNumber) {
    case 1:
      return "asc";
    case 2:
      return "desc";
    default:
      return "asc";
  }
};

enum LowerStatusEnum {
  ACTIVE = "active",
  INACTIVE = "inactive",
  DELETED = "deleted",
  NONE = "none",
}

export {
  RULE_DETAIL_TABLE_CELLS,
  RULE_DETAIL_FOOTER_TEXT,
  LowerStatusEnum,
  orderNumToStr,
};
