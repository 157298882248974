import React, { FC } from "react";
import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { get, isUndefined } from "lodash";
import { IInputFieldsFormProps } from "../../../shared/interfaces/InputFieldsForm.interfaces";
import { getRequiredErrorMessages } from "../../../shared/utils/errorMessages/error_messages";

const SelectCurrency: FC<IInputFieldsFormProps> = ({
  name,
  control,
  errors,
  label,
  isRequired = true,
  defaultValue,
  items = [],
  onClick,
  customErrorMessages,
  disabled,
  onChange,
}: IInputFieldsFormProps) => {
  return (
    <Controller
      name={name}
      rules={
        isRequired
          ? { required: getRequiredErrorMessages(customErrorMessages) }
          : undefined
      }
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField
          {...field}
          select
          variant="outlined"
          fullWidth
          label={label}
          disabled={disabled}
          error={!isUndefined(errors[name])}
          helperText={get(errors, `${name}.message`, "")}
          onClick={onClick}
          onChange={onChange}
        >
          {items}
        </TextField>
      )}
    />
  );
};

export default SelectCurrency;
