/* istanbul ignore file */
/* eslint-disable */
export const environment: {
kushkiUrl: string;
envName: string;
devTools: boolean;
customerRoles: string;
rollbarToken: string;
kushkiWsUrl: string;
disableDownloadMIDs: string;
} = {
kushkiUrl: "https://api.kushkipagos.com",
envName: "primary",
devTools: false,
customerRoles: "BackofficeMasterCustomer",
rollbarToken: 'c497100aec0b464ca3f0f03bab1c260e',
kushkiWsUrl: "wss://ws.kushkipagos.com/analytics-async",
disableDownloadMIDs: "",
};
