import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { billingInfoStyles as style } from "./BillingInfo.styles";
import { Info } from "../../components/InfoContent/InfoContentData/InfoContentData";
import { defaultTo, get, isEqual } from "lodash";
import { useBillingInfoState } from "./state/useBillingInfoState";
import { CatalogsEnum } from "../../shared/enums/CatalogsEnum";
import { InfoContentData } from "../../components/InfoContent/InfoContentData/InfoContentData";
import { CURRENCY_COUNTRY } from "../../shared/constants/MerchantInformationConstants";
import { getCurrencyName } from "../../shared/constants/CurrencyConstants";
import { BillingInfoEnums } from "../../shared/enums/BillingInfoEnums";
import { CountriesEnum } from "../../shared/enums/CountriesEnum";
import { BillingInfoLabelsEnum } from "../../shared/enums/BillingInfoLabelsEnum";
import { BillingInfoProps } from "./BillingInfo.interface";
import { InfoContentCard } from "../../components/InfoContent/InfoContentCard/InfoContentCard";
import { TableRetention } from "../../components/Table/TableRetention/TableRetention";
import { environment } from "../../environments/environment";
import { NOTCHARGE } from "../../shared/constants/PaymentMethodConstants";

export const BillingInfo: React.FC<BillingInfoProps> = (
  props: BillingInfoProps
) => {
  const billingData: Info[] = [];
  const chargesData: Info[] = [];
  const dispersionData: Info[] = [];
  const { handlers } = useBillingInfoState();
  const country: string = get(props, "merchantInfo.country", "");
  const province: string = get(props, "merchantInfo.province", "");
  const city: string = get(props, "merchantInfo.city", "");

  const setBankName = (
    bankId: string,
    bankName: string,
    countryOrigin: string
  ): string => {
    if (bankId.length <= 0 && bankName.length > 0) {
      return bankName;
    }

    return handlers.getNameOnCatalog(CatalogsEnum.Banks, countryOrigin, bankId);
  };

  billingData.push({
    tag: BillingInfoEnums.FREQUENCY,
    value: handlers.mapFrequency(
      get(props, "merchantInfo.invoiceFrequency", "")
    ),
  });
  billingData.push({
    tag: BillingInfoEnums.SOCIAL_REASON,
    value: get(props, "merchantInfo.socialReason", ""),
  });
  billingData.push({
    tag: BillingInfoEnums.ADDRESS,
    value: get(props, "merchantInfo.address", ""),
  });
  billingData.push({
    tag: BillingInfoEnums.TAX_ID,
    value: get(props, "merchantInfo.taxId", ""),
  });
  billingData.push({
    tag:
      country === CountriesEnum.COLOMBIA
        ? BillingInfoEnums.DEPARTMENT
        : BillingInfoEnums.PROVINCE,
    value: handlers.getNameOnCatalog(
      CatalogsEnum.Provinces,
      country,
      province,
      province
    ),
  });
  billingData.push({
    tag: BillingInfoEnums.CITY,
    value: handlers.getNameOnCatalog(CatalogsEnum.Cities, country, city, city),
  });
  billingData.push({
    tag: BillingInfoEnums.MAIL,
    value: get(props, "merchantInfo.email", ""),
  });
  billingData.push({
    tag: BillingInfoEnums.PHONE,
    value: get(props, "merchantInfo.phoneNumber", ""),
  });

  chargesData.push({
    tag: BillingInfoEnums.FREQUENCY,
    value: handlers.mapFrequency(
      get(props, "merchantInfo.chargeFrequency", "")
    ),
  });
  chargesData.push({
    tag: BillingInfoEnums.MINIMUM_CHARGE,
    value: isEqual(get(props, "merchantInfo.minAmount.type", ""), NOTCHARGE)
      ? "N/A"
      : `${get(
          props,
          "merchantInfo.minAmount.amount",
          ""
        )} ${CURRENCY_COUNTRY.get(country)}`,
  });
  chargesData.push({
    tag: BillingInfoEnums.ACCOUNT_BANK,
    value: handlers.formatAccountNumber(
      get(props, "merchantInfo.charge.accountInfo.accountNumber", "")
    ),
  });
  chargesData.push({
    tag: BillingInfoEnums.ACCOUNT_TYPE,
    value: handlers.getNameOnCatalog(
      CatalogsEnum.BankAccountTypes,
      country,
      get(props, "merchantInfo.charge.accountInfo.accountType", "")
    ),
  });
  chargesData.push({
    tag: BillingInfoEnums.BANK,
    value: setBankName(
      get(props, "merchantInfo.charge.accountInfo.bankId", ""),
      get(props, "merchantInfo.charge.accountInfo.bankName", ""),
      get(props, "merchantInfo.charge.accountInfo.bankOfOrigin", "")
    ),
  });

  dispersionData.push({
    tag: BillingInfoEnums.FREQUENCY,
    value: handlers.mapFrequency(
      get(props, "merchantInfo.dispersionFrequency", "")
    ),
  });
  dispersionData.push({
    tag: BillingInfoEnums.ACCOUNT_BANK,
    value: handlers.formatAccountNumber(
      get(props, "merchantInfo.dispersion.accountInfo[0].accountNumber", "")
    ),
  });
  dispersionData.push({
    tag: BillingInfoEnums.ACCOUNT_TYPE,
    value: handlers.getNameOnCatalog(
      CatalogsEnum.BankAccountTypes,
      country,
      get(props, "merchantInfo.dispersion.accountInfo[0].accountType", "")
    ),
  });
  dispersionData.push({
    tag: BillingInfoEnums.CURRENCY,
    value: get(props, "merchantInfo.dispersion.accountInfo[0].currency", ""),
  });
  dispersionData.push({
    tag: BillingInfoEnums.BANK,
    value: setBankName(
      get(props, "merchantInfo.dispersion.accountInfo[0].bankId", ""),
      get(props, "merchantInfo.dispersion.accountInfo[0].bankName", ""),
      get(props, "merchantInfo.dispersion.accountInfo[0].bankOfOrigin", "")
    ),
  });

  return (
    <>
      <Grid container spacing={3} sx={style.contentGrid}>
        <Grid item xs={12} md={6} sx={style.gridItem}>
          <InfoContentCard
            title={BillingInfoLabelsEnum.BILLING}
            legend={BillingInfoLabelsEnum.BILLING_LEGEND}
          >
            <InfoContentData data={billingData} />
          </InfoContentCard>
        </Grid>
        <Grid item xs={12} md={6} sx={style.gridTwoItem}>
          <InfoContentCard
            title={BillingInfoLabelsEnum.CHARGES}
            legend={BillingInfoLabelsEnum.CHARGES_LEGEND}
          >
            <InfoContentData data={chargesData} />
          </InfoContentCard>

          {handlers.hasDispersionProcessor(props.paymentMethod) ? (
            <InfoContentCard
              title={BillingInfoLabelsEnum.DISPERSION}
              legend={BillingInfoLabelsEnum.DISPERSION_LEGEND}
            >
              <InfoContentData data={dispersionData} />
            </InfoContentCard>
          ) : (
            <InfoContentCard
              title={BillingInfoLabelsEnum.DISPERSION}
              legend={BillingInfoLabelsEnum.DISPERSION_LEGEND_NO_HAS}
            >
              <InfoContentData data={[]} />
            </InfoContentCard>
          )}
        </Grid>
        {props.country === CountriesEnum.COLOMBIA &&
          defaultTo(environment.retentionSwitch, false) &&
          props.isUserMaster && (
            <Grid item xs={12} md={12}>
              <InfoContentCard
                title={BillingInfoLabelsEnum.RETENTION}
                legend={BillingInfoLabelsEnum.RETENTION_LEGEND}
              >
                <TableRetention
                  isLoadingRetention={get(
                    props,
                    "retentionInfo.isLoadingRetention",
                    false
                  )}
                  rows={get(props, "retentionInfo.rows", [])}
                  totalRows={get(props, "retentionInfo.totalRows", 0)}
                  downloadFile={{
                    fileData: get(
                      props,
                      "retentionInfo.downloadFile.fileData",
                      ""
                    ),
                    fileName: get(
                      props,
                      "retentionInfo.downloadFile.fileName",
                      ""
                    ),
                    handleCloseModal: get(
                      props,
                      "retentionInfo.downloadFile.handleCloseModal",
                      () => {}
                    ),
                    openModal: get(
                      props,
                      "retentionInfo.downloadFile.openModal",
                      false
                    ),
                  }}
                />
              </InfoContentCard>
            </Grid>
          )}
        <Grid item xs={12} md={12} sx={style.gridInfo}>
          <Divider sx={style.divider} />
          <Box pb={1} pt={2}>
            <Box sx={style.boxCurrency}>
              <Typography>
                {BillingInfoLabelsEnum.CONFIGURED_CURRENCY}
              </Typography>
              <Typography sx={style.labelTitle3}>
                {getCurrencyName(country)} ({CURRENCY_COUNTRY.get(country)})
              </Typography>
            </Box>
            <Box sx={style.boxCurrency} mt={"10px"}>
              <Typography sx={style.labelTitle4}>
                {BillingInfoLabelsEnum.CURRENCY_LEGEND}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
