import React from "react";
import { Button, Menu, MenuItem, Typography } from "@material-ui/core";
import { MoreHorizontal } from "react-feather";
import { useRowMenuTableState } from "./state/useRowMenuTableState";
import { ButtonTextEnum } from "../../shared/infrastructure/constants/DialogEnum";
import { deferredTableStyle } from "../CustomDeferredTable/DeferredTableBrazil/DeferredTable.style";
import { StatusEnum } from "../../shared/infrastructure/constants/statusEnum";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { DeferredOption } from "../../../types/deferred_options";
import { routes } from "../../shared/infrastructure/constants/routes";
import { useHistory } from "react-router";

export interface IUseRowMenuTableState {
  id: string | undefined;
  status: string | undefined;
  row: DeferredOption;
}
export const RowMenuTable: React.FC<IUseRowMenuTableState> = (
  props: IUseRowMenuTableState
) => {
  const classes = deferredTableStyle(props);
  const {
    anchorEl,
    deferredId,
    handleEvents,
    modalProps,
    isLoadingAcceptAction,
    statusModalOpen,
    isCentralizeNodeBrasil,
  } = useRowMenuTableState(props);
  const history = useHistory();

  return (
    <>
      <Menu
        id={deferredId}
        aria-haspopup="true"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleEvents.handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        className={classes.menuStyle}
      >
        <MenuItem
          className={classes.subMenu}
          onClick={() => {
            history.push(routes.CONFIG_DEFERRED, {
              ...props.row,
            });
          }}
          disabled={isCentralizeNodeBrasil}
        >
          {ButtonTextEnum.EDIT}
        </MenuItem>
        <MenuItem
          className={classes.subMenu}
          onClick={handleEvents.handleOpenStatusModal}
          disabled={isCentralizeNodeBrasil}
        >
          {props.status === StatusEnum.ENABLED
            ? ButtonTextEnum.DEACTIVATE
            : ButtonTextEnum.ACTIVATE}
        </MenuItem>
      </Menu>
      <Button
        className={classes.menuButton}
        color="primary"
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleEvents.handleMenuClick}
      >
        <MoreHorizontal size={22} />
      </Button>
      <ConfirmModal
        {...modalProps}
        open={statusModalOpen}
        isLoadingAcceptAction={isLoadingAcceptAction}
        customCancelBtnStyle={classes.modalCancelBtn}
        contentText={
          <Typography color={"textSecondary"}>
            {modalProps.contentText}
          </Typography>
        }
      />
    </>
  );
};
