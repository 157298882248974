import React from "react";
import { InvoiceRecord } from "../../../types/remote/response_search_blling_by_filters";
import { createStyles, Grid, Paper, Typography } from "@material-ui/core";
import { get } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns-tz";
import NumberFormat from "react-number-format";
import { StatusFlowEnum } from "../../shared/infrastructure/StatusFlowEnum";
import { HierarchyType } from "../../shared/infrastructure/constants/RetentionConstants";
import { translate } from "../../shared/infrastructure/LanguageCatatog";
import {
  DynamoReferenceEnum,
  getDynamoElasticRef,
} from "../../shared/infrastructure/constants/DynamoElasticMap";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import { UtcEnum } from "../../shared/infrastructure/utc-enum";
import { useSelector } from "react-redux";
import { IBillingDashboardState } from "../../store/reducer";
import { Catalog, Data } from "../../../types/catalog_response";
import { CatalogsNameEnum } from "../../shared/infrastructure/enum/CatalogsNameEnum";

export interface InfoTrxProps {
  trxInfo: InvoiceRecord | undefined;
  isHistoric?: boolean;
  statusFlow?: StatusFlowEnum;
  country?: string;
}
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: "#ccc",
      zIndex: 1,
      color: "#fff",
      width: 32,
      height: 32,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
    },
    paper: {
      boxShadow: "none !important",
      borderRadius: "5px !important",
      backgroundColor: "#F7FAFC !important",
      marginBottom: "20px !important",
      marginTop: "30px",
    },
    active: {
      backgroundColor: "#0DC298",
    },
    completed: {
      backgroundColor: "#0DC298",
    },
    linearProgress: {
      marginTop: 3,
    },
    grid: {
      paddingTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
    },
    margin: {
      marginTop: 10,
    },
  })
);
const formatDate = (
  trxDate: any,
  formatDate: string,
  country?: string
): string => {
  const utc: string =
    country === CountryEnum.colombia ? UtcEnum.utc_05 : "Etc/UTC";

  let finalDate: string = "";
  try {
    let date: string;
    date = new Date(
      trxDate.toString().length === 10 ? trxDate * 1000 : trxDate
    ).toISOString();

    finalDate = format(new Date(date.replace("Z", "")), formatDate, {
      timeZone: utc,
    });
  } catch (e) {
    finalDate = "";
  }
  return finalDate;
};

const formatNumber = (value: number, currency: string) => {
  return (
    <NumberFormat
      value={value}
      thousandSeparator
      displayType={"text"}
      decimalScale={2}
      suffix={` ${currency}`}
    />
  );
};
export const renderTrxInfoPaper = (props: InfoTrxProps) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2} color="secondary" className={classes.grid}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2" color="textSecondary">
                Tipo de Documento:
              </Typography>
              <Typography variant="body2">
                {translate(get(props.trxInfo, "kind", ""))}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="textSecondary">
                Ciclo:
              </Typography>
              <Typography variant="body2" id={"CycleDate"}>
                {`${translate(get(props.trxInfo, "cycle"))} ${formatDate(
                  get(props.trxInfo, "startDate"),
                  "dd-MM-yyyy",
                  props.country
                )} - ${formatDate(
                  get(props.trxInfo, "endDate"),
                  "dd-MM-yyyy",
                  props.country
                )}`}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="textSecondary">
                Monto de Retención IVA:
              </Typography>
              <Typography variant="body2">
                {formatNumber(
                  get(props.trxInfo, "retIva", 0),
                  get(props.trxInfo, "currency")
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="textSecondary">
                Monto de Retención Fuente:
              </Typography>
              <Typography variant="body2">
                {formatNumber(
                  get(props.trxInfo, "retFue", 0),
                  get(props.trxInfo, "currency")
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Monto Total:
              </Typography>
              <Typography variant="body2">
                {formatNumber(
                  get(props.trxInfo, "retTotalAmount", 0),
                  get(props.trxInfo, "currency")
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Fecha de emisión del comprobante:
              </Typography>
              <Typography variant="body2">
                {formatDate(get(props.trxInfo, "startDate"), "dd-MM-yyyy")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Número de Documento:
              </Typography>
              <Typography variant="body2">
                {get(props.trxInfo, "voucherNumber")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
export const renderMerchantInfoPaper = (props: InfoTrxProps) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2} color="secondary" className={classes.grid}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2">Razón social:</Typography>
              <Typography variant="body2">
                {get(
                  props.trxInfo,
                  getDynamoElasticRef(
                    DynamoReferenceEnum.SOCIAL_REASON,
                    !!props.isHistoric
                  ),
                  0
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Email:</Typography>
              <Typography variant="body2">
                {get(props.trxInfo, "email")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">Dirección:</Typography>
              <Typography variant="body2">
                {get(props.trxInfo, "address")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
export const renderRetentionInfoPaper = (props: InfoTrxProps) => {
  const { catalogsList } = useSelector(
    (state: IBillingDashboardState) => state
  );
  const getCatalogByCode = (name: string, code: string): string => {
    const list: Catalog | undefined = catalogsList!.find(
      (catalog: Catalog) => catalog.name === name
    );
    const result_find: Data | undefined = get(list, "data", []).find(
      (item: Data) => item.code === code
    );
    return get(result_find, "value", "");
  };
  const classes = useStyles();
  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Grid container spacing={2} color="secondary" className={classes.grid}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="body2">Tipo de contribuyente:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">
                  <Typography variant="body2">
                    {getCatalogByCode(
                      CatalogsNameEnum.TAX_PAYER_TYPE,
                      get(
                        props.trxInfo,
                        getDynamoElasticRef(
                          DynamoReferenceEnum.TAXPAYER_TYPE_ID,
                          !!props.isHistoric
                        ),
                        "-"
                      )
                    )}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">Actividad:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">
                  {getCatalogByCode(
                    CatalogsNameEnum.ACTIVITY,
                    get(
                      props.trxInfo,
                      getDynamoElasticRef(
                        DynamoReferenceEnum.ACTIVITY_ID,
                        !!props.isHistoric
                      ),
                      "-"
                    )
                  )}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">Concepto de retención:</Typography>{" "}
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">
                  {getCatalogByCode(
                    CatalogsNameEnum.INCOME_SOURCE_CONCEPT,
                    get(
                      props.trxInfo,
                      getDynamoElasticRef(
                        DynamoReferenceEnum.INCOME_SOURCE_CONCEPT_ID,
                        !!props.isHistoric
                      ),
                      "-"
                    )
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.paper}>
        <Grid container spacing={2} color="secondary" className={classes.grid}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Typography variant="body2" color="textSecondary">
                  Owner ID:
                </Typography>
                <Typography variant="body2">
                  {get(
                    props.trxInfo,
                    getDynamoElasticRef(
                      DynamoReferenceEnum.OWNER_ID,
                      !!props.isHistoric
                    ),
                    "-"
                  )}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" color="textSecondary">
                  Owner Name:
                </Typography>
                <Typography variant="body2">
                  {get(
                    props.trxInfo,
                    getDynamoElasticRef(
                      DynamoReferenceEnum.OWNER_NAME,
                      !!props.isHistoric
                    ),
                    "-"
                  )}
                </Typography>
              </Grid>
              {get(
                props.trxInfo,
                getDynamoElasticRef(
                  DynamoReferenceEnum.IS_OCB,
                  !!props.isHistoric
                ),
                false
              ) && (
                <Grid item xs={3}>
                  <Typography variant="body2" color="textSecondary">
                    Jerarquia:
                  </Typography>{" "}
                  <Typography variant="body2">
                    {get(
                      props.trxInfo,
                      getDynamoElasticRef(
                        DynamoReferenceEnum.CUSTOMER_ID,
                        !!props.isHistoric
                      ),
                      ""
                    ) !==
                    get(
                      props.trxInfo,
                      getDynamoElasticRef(
                        DynamoReferenceEnum.MERCHANT_ID,
                        !!props.isHistoric
                      ),
                      ""
                    )
                      ? HierarchyType.centralized
                      : HierarchyType.decentralized}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={5}>
                <Typography variant="body2" color="textSecondary">
                  Customer ID:
                </Typography>
                <Typography variant="body2">
                  <Typography variant="body2">
                    {get(
                      props.trxInfo,
                      getDynamoElasticRef(
                        DynamoReferenceEnum.CUSTOMER_ID_HIERARCHY,
                        !!props.isHistoric
                      ),
                      "-"
                    )}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="body2" color="textSecondary">
                  Customer Name:
                </Typography>
                <Typography variant="body2">
                  {get(
                    props.trxInfo,
                    getDynamoElasticRef(
                      DynamoReferenceEnum.CUSTOMER_NAME,
                      !!props.isHistoric
                    ),
                    "-"
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

const ModalBodyRetention = (props: InfoTrxProps) => {
  return (
    <React.Fragment>
      {renderTrxInfoPaper(props)}
      {renderRetentionInfoPaper(props)}
      {renderMerchantInfoPaper(props)}
    </React.Fragment>
  );
};

export default ModalBodyRetention;
