import { FC } from "react";
import { Box, Typography } from "@mui/material";
import * as React from "react";
import { CellDateRangeStyles } from "./CellDateRange.styles";
import { ICellDateRangeComponent } from "./CellDateRange.interfaces";

export const CellDateRange: FC<ICellDateRangeComponent> = ({
  fromDate,
  toDate,
  align,
}: ICellDateRangeComponent) => {
  return (
    <Box alignItems={align}>
      <Typography sx={CellDateRangeStyles.dateText}>
        {fromDate} - {toDate}
      </Typography>
    </Box>
  );
};
