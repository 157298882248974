import { makeStyles } from "@mui/styles";

export const telephoneTagStyle = makeStyles({
  mainGrid: {
    paddingBottom: "10px",
    paddingTop: "0px",
  },
  colorFont: {
    color: "#4498EE",
    fontSize: "16px !important",
    fontWeight: "bold !important",
  },
  titleTextPhone: {
    fontSize: "16px",
    paddingBottom: "14px",
  },
  buttonPhone: {
    paddingTop: "0px",
    fontSize: "15px",
    "&:hover": {
      boxShadow: "3px 3px transparent !important",
      backgroundColor: "#FFFF !important",
    },
  },
});
