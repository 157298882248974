export const enum routes {
  BASIC_DETAILS = "/merchant/basic-details",
  BILLING = "merchant/billing-details",
  CONSOLE_RATES = "console-rates",
  CONTACT_DETAILS = "/merchant/contact-details",
  CREATE_NODE = "/create-node/customer",
  CREATE_NODE_BRANCH = "/create-node/branch/options",
  CREATE_RULE = "/business-rules/create/",
  CREATE_PAYOUT_RULE = "/payout-business-rules/create/",
  CREDENTIALS = "/v3/credentials/",
  FINANCIAL_CONFIGURATION_DISPERSION = "/financial-configuration/dispersion",
  FINANCIAL_CONFIGURATION_BILLING = "financial-configuration/billing",
  FINANCIAL_CONFIGURATION_CHARGES = "financial-configuration/charge",
  LEGAL_DETAILS = "/merchant/legal-details",
  LIST_RULE = "/business-rules/list/",
  LIST_PAYOUTS_RULE = "/payout-business-rules/list/",
  MERCHANT_RATES = "/merchant-rates",
  MERCHANT_RESUME = "/merchant-resume",
  MERCHANT_PROPERTIES = "/merchant-properties",
  BRANCHES_CUSTOMER = "/clients/",
  CONTROL_CENTRALIZATION = "/centralization/merchant",
  WEBHOOK_NOTIFICATIONS = "/merchant-webhook/notifications",
  MERCHANT_WEBHOOKS = "/merchant-webhook/webhook",
  CONSOLE_USER = "/console-user",
  PROCESSOR_LIST = "/merchant-resume?publicMerchantId={merchantId}&menuItem=true",
  WALLET_DASHBOARD = "/console-wallet-dashboard",
}
