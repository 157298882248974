import React, { FC } from "react";
import { Pagination, PaginationItem } from "@mui/material";
import { transactionsTablePaginationStyles } from "./TransactionsTablePagination.styles";
import ChevronLeftIcon from "../../../../assets/icons/ChevronLeftIcon/ChevronLeftIcon";
import ChevronRightIcon from "../../../../assets/icons/ChevronRightIcon/ChevronRightIcon";
import { useTransactionsTablePaginationState } from "./state/useTransactionsTablePaginationState";

const TransactionsTablePagination: FC = () => {
  const {
    areTransactionsLoading,
    currentPage,
    handleSetTransactionsPage,
    pageCount,
    resetPagination,
  } = useTransactionsTablePaginationState();

  return (
    <Pagination
      color="primary"
      count={areTransactionsLoading && resetPagination ? 0 : pageCount}
      disabled={areTransactionsLoading}
      onChange={(_, page) => handleSetTransactionsPage(page)}
      page={resetPagination ? 1 : currentPage}
      renderItem={(item) => (
        <PaginationItem
          components={{
            next: ChevronRightIcon,
            previous: ChevronLeftIcon,
          }}
          sx={transactionsTablePaginationStyles.paginationItem}
          {...item}
        />
      )}
    />
  );
};

export default TransactionsTablePagination;
