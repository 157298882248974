import {
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import {
  ServiceLabelEnum,
  SubTypeEnum,
} from "../../../shared/infrastructure/enums/CreateRuleConstans";
import { isFormEditMode, retrieveFormError } from "../../../utils/utilsFile";
import React from "react";
import { ErrorsEnum } from "../../../shared/infrastructure/enums/ErrorEnum";
import { useSubtypeState } from "../../CreateRuleSection/SubtypeComponent/state/useSubtypeState";
import { NodesState } from "../../../shared/infrastructure/interfaces/NodesState";
import { defaultTo } from "lodash";
import { SubTitleSection } from "../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { generalStyles } from "../../../shared/styles/generalStyles.styles";

export const CommerceTypeComponent: React.FC<NodesState> = (
  props: NodesState
) => {
  const {
    control,
    errors,
    actions: { selectCommerceType },
    commerceType,
    isUpdatedRule,
    id,
    commerceTypeDefaultValue,
  } = useSubtypeState(props);
  const classes = generalStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.subtitleTypography}>
          {SubTitleSection.RULES_CREATE_COMMERCE_SUBTITLE}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="commerceType"
          control={control}
          rules={{
            required: { message: ErrorsEnum.REQUIRED_FIELD, value: true },
          }}
          defaultValue={defaultTo(
            commerceTypeDefaultValue,
            SubTypeEnum.COMMERCE_WITH_HIERARCHY
          )}
          as={
            <RadioGroup
              row
              value={defaultTo(
                commerceType,
                SubTypeEnum.COMMERCE_WITH_HIERARCHY
              )}
            >
              <FormControlLabel
                disabled={isUpdatedRule}
                key={SubTypeEnum.COMMERCE_WITH_HIERARCHY}
                value={SubTypeEnum.COMMERCE_WITH_HIERARCHY}
                control={
                  <Radio color={"primary"} disabled={isFormEditMode(id)} />
                }
                label={ServiceLabelEnum.COMMERCE_WITH_HIERARCHY}
                onChange={selectCommerceType}
              />
              <FormControlLabel
                disabled={isUpdatedRule}
                key={SubTypeEnum.COMMERCE_WITHOUT_HIERARCHY}
                value={SubTypeEnum.COMMERCE_WITHOUT_HIERARCHY}
                control={
                  <Radio color={"primary"} disabled={isFormEditMode(id)} />
                }
                label={ServiceLabelEnum.COMMERCE_WITHOUT_HIERARCHY}
                onChange={selectCommerceType}
              />
            </RadioGroup>
          }
        />

        <FormHelperText error={retrieveFormError(errors, "commerceType")[0]}>
          {retrieveFormError(errors, "commerceType")[1]}
        </FormHelperText>
      </Grid>
    </Grid>
  );
};
