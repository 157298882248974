export enum CurrencyEnum {
  COP = "COP",
  USD = "USD",
  PEN = "PEN",
  CLP = "CLP",
  MXN = "MXN",
  BRL = "BRL",
  PAB = "PAB",
  CRC = "CRC",
}

export enum CountryNameEnum {
  ecuador = "Ecuador",
  peru = "Peru",
  colombia = "Colombia",
  chile = "Chile",
  mexico = "Mexico",
}

export const CURRENCY_BY_COUNTRY: Record<CountryNameEnum, CurrencyEnum> = {
  [CountryNameEnum.chile]: CurrencyEnum.CLP,
  [CountryNameEnum.ecuador]: CurrencyEnum.USD,
  [CountryNameEnum.peru]: CurrencyEnum.PEN,
  [CountryNameEnum.colombia]: CurrencyEnum.COP,
  [CountryNameEnum.mexico]: CurrencyEnum.MXN,
};
