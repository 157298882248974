import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { styles } from "../BusinessRules.styles";
import { Accordion, Alert, TableInfo } from "@kushki/connect-ui";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import Button from "@mui/material/Button";
import {
  HeaderCellProps,
  ITableRowProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { AccordionRulesLabelsEnum } from "../../../shared/enums/AccordionLabelsEnum";

interface IAccordionRulesProps {
  id: string;
  title: string;
  collapsed?: boolean;
  buttonText?: string;
  isLoading?: boolean;
  isDecentralizeOrIsCostumer?: boolean;
  table: {
    columns: HeaderCellProps[];
    rows: ITableRowProps[];
  };
  editRules: () => void;
  configRule: () => void;
}
export const AccordionRules: React.FC<IAccordionRulesProps> = ({
  id,
  title,
  buttonText,
  collapsed = false,
  isLoading = false,
  table,
  editRules,
  configRule,
  isDecentralizeOrIsCostumer,
}: IAccordionRulesProps) => {
  const ConfigRules: React.FC = () => {
    return (
      <Box borderRadius="8px" marginBottom="16px" sx={styles.accordionTitle}>
        <Alert
          buttonText={AccordionRulesLabelsEnum.ALERT_BTN_TEXT}
          description=""
          onButtonClick={configRule}
          title={AccordionRulesLabelsEnum.ALERT_TITLE}
          type={AccordionRulesLabelsEnum.ALERT_TYPE}
        />
      </Box>
    );
  };

  return (
    <Grid sx={styles.accordionTitle} id={id}>
      <Box>
        <Accordion
          divider
          nestingLabel=""
          nesting={1}
          isOpen={collapsed}
          text={title}
          button={
            <ComponentSecurityWrapper>
              <Button
                variant={"text"}
                color={"primary"}
                disabled={!isDecentralizeOrIsCostumer}
                onClick={(event) => {
                  editRules();
                  event.stopPropagation();
                }}
              >
                {buttonText}
              </Button>
            </ComponentSecurityWrapper>
          }
        >
          {table.rows.length === 0 ? (
            <ConfigRules />
          ) : (
            <Box
              bgcolor="neutral.grey3"
              borderRadius="8px"
              marginBottom="16px"
              p={3}
            >
              <Typography color="neutral.grey8" sx={{ fontWeight: 600 }}>
                Reglas creadas
              </Typography>

              <TableInfo
                isLoading={isLoading}
                header={{
                  cells: table.columns,
                  headerProps: {
                    isDisable: false,
                  },
                }}
                rows={table.rows}
              />
            </Box>
          )}
        </Accordion>
      </Box>
    </Grid>
  );
};
