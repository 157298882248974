import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  iconButton: {
    "& .MuiIconButton-root": {
      borderRadius: 4,
      padding: "6px",
      backgroundColor: "white",
    },
  },
  icon: {
    transform: "scaleX(-1)",
  },
}));
