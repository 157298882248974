import React from "react";
import { IUseUpdateStatusButtonInterfaces } from "./IUseUpdateStatusButton.interfaces";

export const useUpdateStatusButton = (): IUseUpdateStatusButtonInterfaces => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open: boolean = Boolean(anchorEl);
  const id: string | undefined = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  return {
    anchorEl,
    handleClick,
    handleClose,
    id,
    open,
  };
};
