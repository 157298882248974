import {
  Configs,
  GetNodeInfoResponse,
} from "../../../types/get_node_info_response";
import { get } from "lodash";

export const filterNodeInfoByConfiguration = (
  nodeInfo: GetNodeInfoResponse,
  configurationCode: string | undefined
): Configs => {
  return get(nodeInfo, "configs", []).filter(
    (config: Configs) => config.configuration === configurationCode
  )[0];
};

export const filterNodeInfoByConfigAndStatus = (
  nodeInfo: GetNodeInfoResponse,
  configurationCode: string,
  status: string
): Configs => {
  return get(nodeInfo, "configs", []).filter(
    (config: Configs) =>
      config.configuration === configurationCode && config.status === status
  )[0];
};
