import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import {
  MerchantNodeData,
  SearchMerchantResponse,
} from "../../../../types/search_merchant_response";
import { cloneDeep, get, has, set } from "lodash";
import { AxiosResponse } from "axios";
import { GetBalanceResponse } from "../../../../types/get_balance_response";
import { CATALOG_CURRENCY_CODE } from "../../../shared/catalogs/CatalogCurrencyCode";
import { IBalanceDataRequest } from "../../interfaces/branch.interfaces";
import { StatusEnum } from "../../../shared/enums/StatusEnum";
import { formatCurrencyWallet } from "../../../shared/utils/currency_utils";

const getBalanceByMerchantId = async (
  merchantId: string
): Promise<GetBalanceResponse> => {
  let response: GetBalanceResponse = {};

  try {
    const getBalanceResponse: AxiosResponse<GetBalanceResponse> =
      await axios.get<GetBalanceResponse>(API_ROUTES.GET_BALANCE(merchantId));

    response = getBalanceResponse.data;
  } finally {
  }

  return response;
};

const getAllBalanceByMerchantId = async (
  merchantsNodeData: MerchantNodeData[]
): Promise<MerchantNodeData[]> => {
  await Promise.all(
    merchantsNodeData.map(async (merchantData: MerchantNodeData) => {
      const getBalanceResponse = await getBalanceByMerchantId(
        get(merchantData, "merchant_id", "")
      );
      const currentCurrencyCode: string =
        CATALOG_CURRENCY_CODE[get(merchantData, "country_code", "")];
      const balanceValue: string = formatCurrencyWallet(
        get(getBalanceResponse, "balance", 0),
        get(merchantData, "country", "")
      );
      const balanceAmountWithCurrency = has(getBalanceResponse, "balance")
        ? `BLC ${balanceValue} ${currentCurrencyCode}`
        : "";

      set(
        merchantData,
        "balance_amount_with_currency",
        balanceAmountWithCurrency
      );
    })
  );

  return merchantsNodeData;
};

export const getFilteredBalanceFromBranchList = createAsyncThunk<
  SearchMerchantResponse,
  IBalanceDataRequest
>("balance/getFilteredBalanceFromBranchList", async (data) => {
  const filters: StatusEnum[] = data.filtersStatusEnum;
  const filteredMerchantsNodeData: MerchantNodeData[] = cloneDeep(
    get(data.dataResponse, "data", []).filter(
      (merchantNodeData: MerchantNodeData) =>
        filters.includes(merchantNodeData.merchant_status as StatusEnum)
    )
  );

  const merchantsNodeData: MerchantNodeData[] = await getAllBalanceByMerchantId(
    filteredMerchantsNodeData
  );

  return {
    ...data.dataResponse,
    data: merchantsNodeData,
  };
});

export const getFilteredBalanceFromCustomerFamily = createAsyncThunk<
  SearchMerchantResponse,
  IBalanceDataRequest
>("balance/getFilteredBalanceFromCustomerFamily", async (data) => {
  const filters: StatusEnum[] = data.filtersStatusEnum;
  const filteredMerchantsNodeData: MerchantNodeData[] = cloneDeep(
    get(data.dataResponse, "data", []).filter(
      (merchantNodeData: MerchantNodeData) =>
        filters.includes(merchantNodeData.merchant_status as StatusEnum)
    )
  );
  const merchantsNodeData: MerchantNodeData[] = await getAllBalanceByMerchantId(
    filteredMerchantsNodeData
  );

  return {
    ...data.dataResponse,
    data: merchantsNodeData,
  };
});
