import React, { FC } from "react";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { IWebhookConfig } from "./WebhookConfig.interfaces";
import { webhookConfigStyles } from "./styles";
import { WrapperLabelEnum } from "../../shared/enums/WrapperLabelEnum";

export const WebhookConfig: FC<IWebhookConfig> = ({
  title,
  buttonTitle,
  onRedirectWebhook,
  isCentralized,
}: IWebhookConfig) => {
  return (
    <Paper elevation={0} sx={webhookConfigStyles.paper}>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <Typography
            color="primary"
            variant="h6"
            sx={webhookConfigStyles.title}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"caption"} sx={webhookConfigStyles.subtitle}>
            {WrapperLabelEnum.WEBHOOK_CONFIG_DISCLAIMER}
          </Typography>
        </Grid>
        {!isCentralized && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={onRedirectWebhook}
            >
              {buttonTitle}
            </Button>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
