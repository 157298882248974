import { environment } from "../../environments/environment";
import { projectPaths } from "../enums/projects_path_enum";

export const API_ROUTES = {
  CATALOGS: `${environment.kushkiUrl}/catalog/v1/list-catalog`,
  GET_MERCHANT_NODE_INFO: `${environment.kushkiUrl}/billing-core-node/v1/merchant-node/info`,
  GET_NODE_INFO: `${environment.kushkiUrl}/billing-core-node/v1/node/info`,
  GET_RATES_BILLING: (publicMerchantId: string) =>
    `${environment.kushkiUrl}/rates/billingConfig/${publicMerchantId}`,
  HISTORICAL_REFUNDS: `${environment.kushkiUrl}/b2c-analytics/v1/refund-transaction-list`,
  NODE_BILLING_INFO: `${environment.kushkiUrl}/hierarchy/v1/merchant/v1/node/config`,
  PATCH_MERCHANT_NODE_INFO: `${environment.kushkiUrl}/billing-core-node/v1/merchant-node/details`,
  POST_DISPERSION_CONFIG: `${environment.kushkiUrl}/dispersions/v1/dispersion/configuration`,
  POST_RATES: `${environment.kushkiUrl}/costs/costs/createRates`,
  POST_SEMAPHORE: `${environment.kushkiUrl}/billing-core-node/v1/merchant-node/semaphore`,
  PROCESSING: (publicMerchantId: string) =>
    projectPaths.PROCESSING.concat(
      `?publicMerchantId=${publicMerchantId}&menuItem=true`
    ),
  RATES_BILLING: `${environment.kushkiUrl}/rates/billingConfig`,
  RATES_DISPERSION: `${environment.kushkiUrl}/costs/costs/configRates`,
  WS_MASSIVE: `wss://${environment.kushkiUrlWS}/billingcorenodews`,
};
