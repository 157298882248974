import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SmartlinkDatePicker } from "../DatePicker/SmartlinkDatePicker";
import { PopoverFilter } from "../PopoverFilters/PopoverFilter";
import { Search } from "react-feather";
import { DateRange } from "@material-ui/pickers";
import { get, sortBy } from "lodash";
import { ButtonDownloadFile } from "../ButtonDownloadFile/ButtonDownloadFile";
import { IOptionFilter } from "../common/BoxFilter/BoxFilter";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { LoaderModal } from "../LoaderModal/LoaderModal";
import {
  AuthenticationRules,
  ResponseSearchRulesByFilters,
} from "../../../types/response_search_rules_by_filters";
import { StatusEnum } from "../../shared/infrastructure/enums/StatusEnum";

import { ColumnFilters } from "../DashboardListHistoric/ColumnFilters/ColumnFilters";
import {
  historicColumnsTable,
  IColumnsTable,
} from "../../shared/infrastructure/Table/ColumnsTable";
import imgSettings from "../../assets/images/⚙️settings.svg";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { RuleRequestRoles } from "../../shared/infrastructure/constants/RuleRequestConstants";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "0px",
      backgroundColor: "#F7FAFC",
      width: "100%",
      margin: "0 !important",
    },
    cardRoot: {
      maxWidth: 400,
    },
    kshBtnProcess: {
      marginTop: "0px",
      padding: "7px 30px",
    },
    heading: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightMedium,
    },
    table: {
      borderCollapse: "collapse",
      width: "70%",
    },
    td: {
      paddingTop: 5,
      paddingBottom: 5,
      width: 225,
    },
    td_large: {
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    divider: {
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
      borderBottomColor: "#EEE",
    },
    textField: {
      padding: 0,
      height: "36px",
      marginTop: "0px",
    },
    iconButton: {
      "& .MuiIconButton-root": {
        height: "39px",
        width: "39px",
        marginTop: "0px",
        borderStyle: "solid",
        borderColor: "#C4C4C4",
        borderWidth: 1,
        borderRadius: 4,
        backgroundColor: "white",
      },
    },
  })
);

export interface FilterDashboardFunctionProps {
  handleSetFilters?: (filters: { [key: string]: boolean }) => void;
  handleInputChange: (value: string) => void;
  handleSearchBar: (value: string) => void;
  filterChips: { [key: string]: boolean };
  handleSetDate: (dateRange: DateRange<Date>) => void;
  downloadFileState?: {
    isLoading: boolean;
    downloadFile?: (format: string) => void;
    downloadFileList?: (format: string) => void;
  };
  setSelectedColumns?: (data: string[]) => void;
  handleAppliedColumnsFilters?: (restore?: boolean) => void;
  handleCloseProcessLoader?: () => void;
  handleProcessModalOpen?: () => void;
  handleProcessModalClose?: () => void;
  handleProcessTransaction?: () => void;
}

export interface FilterDashboardStateProps {
  country?: string;
  filters?: IOptionFilter[];
  query?: string;
  range?: DateRange<Date>;
  counter: number;
  selectedColumns?: string[];
  optionTabValue: number;
  isLoadingProcessRules?: boolean;
  rulesDataCounter?: { contador: number; total: number };
  rulesData?: ResponseSearchRulesByFilters;
  processLoader?: boolean;
  processModal?: boolean;
}

export type DataTableFiltersProps = FilterDashboardStateProps &
  FilterDashboardFunctionProps;

export const DataTableFilters: React.FC<DataTableFiltersProps> = (
  props: DataTableFiltersProps
) => {
  const [anchorColumns, setAnchorColumns] =
    React.useState<HTMLButtonElement | null>(null);

  const [selectableColumns] = useState<IColumnsTable[]>(
    sortBy(
      historicColumnsTable.filter((column: IColumnsTable) => column.filter),
      ({ filterOrder }) => filterOrder
    )
  );
  const openColumns = Boolean(anchorColumns);
  const idButtonColumns = openColumns ? "filter-popover" : undefined;

  const handleClickColumns = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorColumns(event.currentTarget);
  };

  const handleCloseColumns = () => {
    setAnchorColumns(null);
  };
  const handleChangeColumnCheck = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const column: string = event.target.name;
    let aunxColumnsSelected: string[] = [...props.selectedColumns!];

    if (!event.target.checked) {
      const index: number = aunxColumnsSelected.findIndex(
        (col: string) => col === column
      );
      if (index !== -1) aunxColumnsSelected.splice(index, 1);
    } else {
      if (props.selectedColumns!.length > 7) return;
      aunxColumnsSelected = [column, ...aunxColumnsSelected];
    }
    props.setSelectedColumns!(aunxColumnsSelected);
  };

  const handleRestoreColumns = () => {
    props.handleAppliedColumnsFilters!(true);
    handleCloseColumns();
  };
  const handleDisplayedColumnUser = () => {
    props.handleAppliedColumnsFilters!();
    handleCloseColumns();
  };

  const checkCeroValue = (requestValue: number): string => {
    if (requestValue < 10) return `0${requestValue}`;

    return `${requestValue}`;
  };

  const classes = useStyles();
  const [buttonLabel, setButtonLabel] = React.useState<string>("Procesar");
  const handleDate = (date: DateRange<object> | MouseEvent): void => {
    if (get(date, "movementX") === 0) {
      props.handleSetDate([null, null]);
      return;
    }
    props.handleSetDate(date as DateRange<Date>);
  };

  useEffect(() => {
    if (props.isLoadingProcessRules) {
      setButtonLabel("Procesando");
      return;
    }
    setButtonLabel("Procesar");
  }, [props.isLoadingProcessRules]);

  const loadProcessResume = () => {
    const rulesDataRecords = get(props.rulesData, "records", []);
    const ommited = rulesDataRecords.filter(
      (value: AuthenticationRules) => value.status === StatusEnum.OMIT
    ).length;
    const approved = rulesDataRecords.filter(
      (value: AuthenticationRules) => value.status === StatusEnum.APPROVE
    ).length;
    const reject = rulesDataRecords.filter(
      (value: AuthenticationRules) => value.status === StatusEnum.REJECT
    ).length;
    const total = rulesDataRecords.length;

    return (
      <table className={classes.table}>
        <tr className={classes.divider}>
          <td className={classes.td_large}>
            <Typography variant="h6">Total a procesar:</Typography>
          </td>
          <td>
            <Typography variant="h6">{checkCeroValue(total)}</Typography>
          </td>
        </tr>
        <tr>
          <td className={classes.td}>
            <Typography variant="h6">Total aprobadas:</Typography>
          </td>
          <td>
            <Typography variant="h6">{checkCeroValue(approved)}</Typography>
          </td>
        </tr>
        <tr>
          <td className={classes.td}>
            <Typography variant="h6">Total rechazadas:</Typography>
          </td>
          <td>
            <Typography variant="h6">{checkCeroValue(reject)}</Typography>
          </td>
        </tr>
        <tr>
          <td className={classes.td}>
            <Typography variant="h6">Total pendientes:</Typography>
          </td>
          <td>
            <Typography variant="h6">{checkCeroValue(ommited)}</Typography>
          </td>
        </tr>
      </table>
    );
  };

  return (
    <React.Fragment>
      <Grid
        container
        spacing={3}
        direction={"row"}
        alignItems={"center"}
        className={classes.root}
      >
        <Grid item xs={9}>
          <Grid container spacing={3}>
            <Grid item>
              <PopoverFilter
                handleSetFilters={props.handleSetFilters!}
                filterChips={props.filterChips}
                counterFilter={props.counter}
                filters={props.filters!}
              />
            </Grid>
            <Grid item xs={4}>
              <SmartlinkDatePicker
                value={props.range}
                disableFuture={false}
                handleDate={handleDate}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={props.query}
                id="outlined-search"
                key={"outlinedSearch"}
                autoFocus
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search size={16} />
                    </InputAdornment>
                  ),
                }}
                className={classes.textField}
                variant={"outlined"}
                type="search"
                size={"small"}
                margin={"none"}
                placeholder={"Buscar comercio o analista"}
                fullWidth
                onKeyDown={(event: any) => {
                  if (event.key !== "Enter") return;
                  props.handleSearchBar(event.key);
                }}
                onChange={(event: any) => {
                  props.handleInputChange(event.target.value);
                }}
              />
            </Grid>
            {props.optionTabValue === 2 && (
              <>
                <Grid item xs={1}>
                  <Box className={classes.iconButton}>
                    <IconButton
                      aria-label="settings-columns"
                      color="primary"
                      onClick={handleClickColumns}
                    >
                      <img src={imgSettings} />
                    </IconButton>
                    <ColumnFilters
                      openColumns={openColumns}
                      idButtonColumns={idButtonColumns}
                      anchorColumns={anchorColumns}
                      handleCloseColumns={handleCloseColumns}
                      selectedColumns={props.selectedColumns!}
                      handleChangeColumnCheck={handleChangeColumnCheck}
                      handleRestoreColumns={handleRestoreColumns}
                      handleDisplayedColumnUser={handleDisplayedColumnUser}
                      selectableColumns={selectableColumns}
                      title={"Elige hasta 7F columna para mostrar"}
                      admittedColumns={7}
                    />
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>

        {props.optionTabValue !== 1 && (
          <Grid xs={3}>
            <Grid
              container
              spacing={3}
              style={{ width: "100%", margin: "0px !important" }}
            >
              {props.optionTabValue === 0 && (
                <Grid xs={12} item>
                  <ComponentSecurityWrapper
                    componentId={`${RuleRequestRoles.M_FRAUD_PREVENTION}.${RuleRequestRoles.RULE_REQUEST_AUTH_RULES_PROCESS}`}
                  >
                    <Box display={"flex"} justifyContent={"flex-end"}>
                      <Button
                        id="button_process_billing_data"
                        variant="contained"
                        color="primary"
                        disabled={props.isLoadingProcessRules}
                        className={classes.kshBtnProcess}
                        style={{
                          marginLeft: "0",
                        }}
                        onClick={props.handleProcessModalOpen!}
                      >
                        <Typography>{buttonLabel}</Typography>
                        {props.isLoadingProcessRules && (
                          <CircularProgress
                            color={"secondary"}
                            size={25}
                            style={{ marginLeft: "5px" }}
                          />
                        )}
                      </Button>
                    </Box>
                  </ComponentSecurityWrapper>
                </Grid>
              )}
              {props.optionTabValue === 2 && (
                <Grid xs={12} item>
                  <Box
                    width={"100%"}
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                  >
                    <ButtonDownloadFile
                      isLoading={get(
                        props,
                        "downloadFileState.isLoading",
                        false
                      )}
                      downloadFile={get(
                        props,
                        "downloadFileState.downloadFile"
                      )}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        {props.rulesData && props.rulesDataCounter && (
          <>
            <LoaderModal
              open={props?.processLoader!}
              message={"Estamos procesando tus solicitudes"}
              message1={"No cierres tu ventana"}
              rulesDataCounter={props.rulesDataCounter!}
              handleClose={props.handleCloseProcessLoader!}
            />
            <ConfirmModal
              open={props.processModal!}
              titleText={"¿Deseas procesar todas las solicitudes?"}
              contentText={
                "Estás a punto de procesar las siguientes solicitudes de reglas de autenticación. Esta acción no se puede deshacer."
              }
              acceptText={"Sí, procesar"}
              handleClose={props.handleProcessModalClose!}
              handleAccept={props.handleProcessTransaction!}
              acceptColor="primary"
            >
              {loadProcessResume()}
            </ConfirmModal>
          </>
        )}
      </Grid>
    </React.Fragment>
  );
};
