import { BreadcrumProps } from "../../../components/common/Breadcrumb/Breadcrumb.interfaces";

export enum LabelsDispersion {
  BEGIN_TEMPLATE_DESC_TITLE = "Utiliza estas",
  BASE_TEMPLATE = "plantillas base",
  END_TEMPLATE_DESC_TITLE = "para\ndetallar las dispersiones\nque deseas solicitar.",
  DOWNLOAD_INPUT_TEMPLATE = "Descargar plantilla INPUT",
  DOWNLOAD_UAF_TEMPLATE = "Descargar plantilla UAF",
  FORMAT_DESCRIPTION = "*Formato permitido: XLS y XLSX.",
  SIZE_DESCRIPTION = "*Peso máximo de archivo: 2MB.",
  TITLE_FILE = "Adjunta los archivos* con los datos completos",
  FILE_INPUT = "1. Archivo INPUT:",
  FILE_UAF = "2. Archivo UAF:",
  CHOOSE_FILE = "Elegir archivo",
  FILE_NOT_SELECTED = "No se ha seleccionado ningún documento",
  BUTTON_DESCRIPTION = "Haz clic en 'Elegir Archivo' para buscar el documento en tu dispositivo.",
  PROCESS = "Procesar",
  ERROR_SIZE_FILE = "El archivo que seleccionaste es demasiado grande. El tamaño máximo es de 2MB.",
  ERROR_FORMAT_FILE = "El formato o extensión de este archivo no es válido. Compruebe que la extensión sea .xls o .xlsx",
  DISPERSION = "Dispersiones",
  LOADING_TITLE = "CARGANDO...",
  LOADING_SUBTITLE = "Estamos procesando tus archivos",
  LOADING_MESSAGE = "Por favor espera",
  VALIDATION_DETAILS_ERROR_TITTLE = "Error en un archivo",
  VALIDATION_DETAILS_PARTIALY_PROCESSED_TITTLE = "Solicitud procesada parcialmente",
  VALIDATION_DETAILS_PARTIALY_PROCESSED_DESCRIPTION = "La solicitud de dispersión se ha validado con las siguientes novedades:",
  VALIDATION_DETAILS_PARTIALY_PROCESSED_SUBTITLE_ONE = "Las filas correctas se han enviado a procesar.",
  VALIDATION_DETAILS_PARTIALY_PROCESSED_SUBTITLE_TWO = "Las filas con error puedes corregirlas y enviarlas en una nueva solicitud.",
  VALIDATION_ERROR_TITLE_INPUT = "Error en el archivo INPUT",
  VALIDATION_ERROR_TITLE_UAF = "Error en el archivo UAF",
  VALIDATION_ERROR_TITLE_BOTH_FILES = "Error en ambos archivos",
  VALIDATION_SUCCESS_TITLE = "Tu solicitud de dispersiones se encuentra en validación",
  VALID_ROWS_LABEL = "Filas correctas:",
  INVALID_ROWS_LABEL = "Filas con error:",
  VALIDATION_ERROR_DETAILS_LABEL = "Ver filas con error",
  RETURN_TRANSACTIONS = "Ir a transacciones",
  REQUEST_OPTION = "Ver solicitudes",
  RELOAD_OPTION = "Cargar de nuevo",
  VALIDATION_ERROR_ALERT = "Hubo un error al cargar los archivos, por favor intenta nuevamente",
  VALIDATION_TEXT_TOTAL_FILES = "Total de filas archivo",
  VALIDATION_DOWNLOAD_FILES = "Descargar archivo",
  REQUEST_IN_PROCESS = "Tu solicitud de disperciones está en proceso",
  DISPERSION_REQUEST = "Solicitud de dispersión",
  DESCRIPTION = "Una vez realizada podrás visualizar y descargar el Balance GeneralSPLIT y la respuesta del Banco dentro del detalle de la transacción.",
  RESUME_DISPERSION = "Resumen de la dispersion",
  N_REQUEST = "No de solicitud:",
  AMOUNT = "Monto total a dispersar:",
  ITEMS = "Cantidad de registros:",
  GO_BACK_TO_TRANSACTIONS = "Ir a transacciones",
  INSUFFICIENT_FUNDS = "No cuentas con saldo suficiente SPLITpara procesar tu solicitud.",
  SUPPORT_COMUNICATION = "Puedes comunicarte con soporte desde el chat de consola.",
  GO_BACK_TO_PAYMENTS = "Ir a pagos",
  FILTER = "Filtrar",
  FILTER_BY = "Filtrar por",
  COLLAPSED = "Contraer todo",
  EXPANDED = "Expandir todo",
  STATUS = "Estado",
  AMOUNTS = "Montos",
  CLP = "CLP",
  APPLY_FILTER = "Aplicar Filtros",
  CLEAN_FILTER = "Borrar filtros",
  ERROR_PROCESSING_FILES = "Hubo un error al consultar los registros. Por favor intenta nuevamente",
  DOWNLOAD = "Descargar",
  ON_PROCESS = "EN PROCESO",
  PROCESSED = "PROCESADO",
  PARTIALLY_PROCESSED = "PROCESADO PARCIAL",
  INVALID = "INVÁLIDO",
  INITIALIZED = "INICIALIZADO",
  PAGINATION_TITTLE = "Transacciones por página.",
  LABEL_SEARCH_FIELD = "Nro. de solicitud",
  DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss",
  SUCCESS_DESCRIPTION = "Una vez validado se te notificará por correo el estado de la solicitud. En caso de estar correcto se mantendrá en proceso hasta que el banco emita una respuesta. Puedes revisar el estado de solicitudes en la sección de 'Solicitudes procesadas'",
  REFERENCE_NUMBER = "No de solicitud:",
  TITLE_DISPERSION_VALIDATION = "Tu solicitud de dispersiones se encuentra en validación",
  BODY_DISPERSION_VALIDATION = "Una vez validado se te noficará por correo el estado de la solicitud. En caso de estar correcto se mantendra en proceso hasta que el banco emita una respuesta. Puedes revisar el estado de solicitudes en la sección de 'Solicitudes procesadas'",
  NOTIFICATION_DISPERSION_VALIDATION = "No de solicitud:",
  ERROR_REQUEST_DISPERSION_VALIDATION = "Ha ocurrido un error al consultar el estado de la solicitud",
}

export enum TypeFileEnum {
  INPUT = "INPUT",
  UAF = "UAF",
}

export enum FilterSortEnum {
  CREATED = "created",
  ASC = "asc",
}

export const breadcrumbValues: BreadcrumProps = {
  items: [
    { label: "Inicio", url: "/dashboard" },
    {
      label: "Transacciones",
      url: "/transactions",
    },
  ],
  lastItem: "Dispersiones",
};

export enum StatusEnum {
  COMPLETE = "complete",
  INCOMPLETE = "incomplete",
  ERROR = "error",
}
export type FilterProperties = {
  status: undefined | "PROCESSING" | "PROCESSED";
  type: "min" | "max" | "eq" | "range";
};

export enum DownloadType {
  BANK = "bank",
  BALANCE = "balance",
}

export const chipStatus: string[] = ["Procesado", "En proceso"];

export interface MenuItemAmount {
  label: string;
  value: string;
}

export const menuItem: MenuItemAmount[] = [
  {
    label: "Mayor que",
    value: "max",
  },
  {
    label: "Menor que",
    value: "min",
  },
  {
    label: "Igual a",
    value: "eq",
  },
  {
    label: "Entre",
    value: "range",
  },
];

export const headerTableItem: string[] = [
  "Fecha de creación",
  "Nro. de solicitud",
  "Monto",
  "Respuesta Banco de Chile",
  "Estado",
];

export enum StatusCodeEnum {
  INSUFFICIENT_FUNDS = "insufficient_funds",
}
