import React from "react";
import {
  createStyles,
  createTheme,
  Grid,
  MuiThemeProvider,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { InfoTrxProps } from "./ModalBodyTabs";
import { get } from "lodash";
import { tzRegion } from "../../shared/infrastructure/constants/RuleRequestAuthenticationConstants";
import { UTC_FORMAT_TIME } from "../../shared/infrastructure/UtcTimeEnum";
import { format, utcToZonedTime } from "date-fns-tz";

const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 500,
      fontSize: 16,
    },
  },
});

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      boxShadow: "none !important",
      borderRadius: "0px !important",
      backgroundColor: "#F7FAFC !important",
      marginBottom: "40px !important",
    },
    typography: {
      color: "#293036",
      fontSize: "14px",
    },
  })
);

const ModalBodyInfo = (props: InfoTrxProps) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div>
        <Paper
          className={classes.paper}
          style={{
            marginTop: 20,
            padding: "10px 20px",
          }}
        >
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <MuiThemeProvider theme={theme}>
                  <Typography variant="h6" color="textPrimary">
                    Datos del comercio
                  </Typography>
                </MuiThemeProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2} direction={"row"}>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                Nombre:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="textPrimary">
                {get(props.trxInfo, "merchant_name", "")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2} direction={"row"}>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                Razón Social:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="textPrimary">
                {get(props.trxInfo, "social_reason", "")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2} direction={"row"}>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                ID del comercio:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="textPrimary">
                {get(props.trxInfo, "public_merchant_id", "")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2} direction={"row"}>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                Tamaño del comercio:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="textPrimary">
                {get(props.trxInfo, "merchant_size", "")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2} direction={"row"}>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                País:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="textPrimary">
                {get(props.trxInfo, "country", "")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2} direction={"row"}>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                GMT / UTC:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="textPrimary">
                {UTC_FORMAT_TIME[get(props.trxInfo, "country", "")]}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          className={classes.paper}
          style={{
            marginTop: 20,
            padding: "10px 20px",
          }}
        >
          <Grid container item xs={12} spacing={2} direction={"row"}>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                Medio de pago:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="textPrimary">
                Tarjeta
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2} direction={"row"}>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                Autenticador:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="textPrimary">
                {get(props.trxInfo, "kind", "")}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          className={classes.paper}
          style={{
            marginTop: 20,
            padding: "10px 20px",
          }}
        >
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                Fecha de creación:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="textPrimary">
                {format(
                  utcToZonedTime(get(props.trxInfo, "created", 1), tzRegion),
                  "dd MMM'.' yyyy HH:mm"
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2} direction={"row"}>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                Solicitado por:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="textPrimary">
                {get(props.trxInfo, "user_name_request", "")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2} direction={"row"}>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                Analista asignado:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="textPrimary">
                {get(props.trxInfo, "user_alias", "")}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Grid container item xs={12} spacing={2} direction={"row"}></Grid>
      </div>
    </React.Fragment>
  );
};

export default ModalBodyInfo;
