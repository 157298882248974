import React, { FC } from "react";
import { MessageDialogProps } from "./MessageDialog.interfaces";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./MessageDialog.styles";

const MessageDialog: FC<MessageDialogProps> = (props: MessageDialogProps) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.dialogDomain }}
      open={props.open}
      onClose={props.onClose}
    >
      <DialogTitle>
        <Typography className={classes.dialogTitle}>{props.title}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogContainer}>
          {props.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={props.onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MessageDialog;
