import React, { FC } from "react";
import { Box, RadioGroup, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { IInputFieldsFormProps } from "../../../shared/interfaces/InputFieldsForm.interfaces";
import { getRequiredErrorMessages } from "../../../shared/utils/errorMessages/error_messages";
import { get, isUndefined } from "lodash";

const RadioButtonField: FC<IInputFieldsFormProps> = ({
  name,
  control,
  items,
  errors,
  defaultValue,
  customErrorMessages,
}: IInputFieldsFormProps) => {
  return (
    <Controller
      name={name}
      rules={{
        required: getRequiredErrorMessages(customErrorMessages),
      }}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <>
          <RadioGroup
            sx={{ marginBottom: "0px !important" }}
            {...field}
            defaultValue={defaultValue}
            row
            name={name}
          >
            {items}
          </RadioGroup>
          {!isUndefined(errors[name]) && (
            <Box>
              <Typography variant={"overline"} sx={{ color: "error.dark2" }}>
                {get(errors, `${name}.message`, "")}
              </Typography>
            </Box>
          )}
        </>
      )}
    />
  );
};

export default RadioButtonField;
