import { IStyles } from "../../../shared/interfaces/Styles.interfaces";

const cellTitleStyles: IStyles = {
  cellTitles: {
    alignItems: "center",
    display: "flex",
  },
  description: {
    color: "text.grey",
  },
  marginRText: {
    mr: "5px",
  },
  subtitle2: {
    color: "text.dark",
  },
  toolTip: {
    background: "primary.dark",
    borderRadius: "8px",
    color: "text.white",
    fontSize: "15px",
    fontWeight: "400",
    lineHeight: "144%%",
    width: "100%",
  },
};

export { cellTitleStyles };
