import {
  PersonTypeInterface,
  PersonTypeObjectInterface,
} from "../BasicStateGeneral/FieldsBasicData";
import { PersonType_EC_PE_Enum } from "../BasicStateGeneral/PersonType";
import {
  BasicDataLabels,
  ErrorMessageMX,
  RegularExp,
  TypeElement,
} from "../BasicStateMX/ErrorMessageMX";
import { Category } from "../BasicStateGeneral/CatalogBasicData";
import { ErrorMessageGeneric } from "../BasicStateGeneral/ErrorMessage";
import { WEB_SITE_REGEX } from "../../constants/billing_form_constants";
import { ErrorMessageUSA } from "./ErrorMessageUSA";

export const PersonTypeUSAlist: Array<PersonTypeObjectInterface> = [
  { name: "Jurídica", value: "02" },
  { name: "Natural", value: "01" },
];

export const docTypeUSANatural: PersonTypeObjectInterface[] = [
  { merchantType: "01", name: "Seguro Social SSN", value: "0" },
  {
    merchantType: "01",
    name: "Identificación Personal del Contribuyente ITIN",
    value: "2",
  },
];
export const docTypeUSA: Array<Category> = [
  { id: "0", label: "Seguro Social SSN" },
  {
    id: "2",
    label: "Identificación Personal del Contribuyente ITIN",
  },
];
export const docTypeUSAJuridica: PersonTypeObjectInterface[] = [
  { merchantType: "02", name: "Identificación del Empleador EIN", value: "1" },
];

export const fieldsRenderUSA: Array<PersonTypeInterface> = [
  {
    filter: PersonType_EC_PE_Enum.JURIDICA,
    label: BasicDataLabels.RAZON_SOCIAL,
    name: "socialReason",
    regExp: RegularExp.CAPITAL_LETTER_NUMBERS,
    rules: {
      maxLength: {
        message: ErrorMessageGeneric.MAX150,
        value: 150,
      },
      minLength: {
        message: ErrorMessageMX.MAX_1_50_CHAR,
        value: 1,
      },

      required: {
        message: ErrorMessageMX.PF_RAZON_SOCIAL,
        value: true,
      },
    },
    type: TypeElement.TEXT_CAPITAL,
    value: 6,
  },
  {
    filter: PersonType_EC_PE_Enum.JURIDICA,
    label: BasicDataLabels.EIN,
    name: "rfc",
    regExp: RegularExp.LETTER_NUMBERS,
    rules: {
      maxLength: {
        message: ErrorMessageMX.MAX_50_CHAR,
        value: 50,
      },
      minLength: {
        message: ErrorMessageMX.MAX_1_50_CHAR,
        value: 1,
      },
      pattern: {
        message: ErrorMessageUSA.ITIN,
        value: RegularExp.LETTER_NUMBERS,
      },
      required: {
        message: ErrorMessageMX.PF_NUM_ID,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 6,
  },
  {
    filter: PersonType_EC_PE_Enum.JURIDICA,
    label: BasicDataLabels.TIPO_EMPRESA_USA,
    name: "typeCompany",
    rules: {
      required: {
        message: ErrorMessageMX.PF_COMPANY,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    value: 6,
  },
  {
    filter: PersonType_EC_PE_Enum.JURIDICA,
    label: BasicDataLabels.TIPO_INDUSTRIA,
    name: "typeIndustry",
    rules: {
      required: {
        message: ErrorMessageMX.PF_INDUSTRIA,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    value: 6,
  },
  {
    filter: PersonType_EC_PE_Enum.JURIDICA,
    label: BasicDataLabels.MCC,
    name: "mcc",
    rules: {
      required: {
        message: ErrorMessageMX.PF_MCC,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    typeArray: TypeElement.COMBO_ARRAY,
    value: 6,
  },
  {
    filter: PersonType_EC_PE_Enum.JURIDICA,
    label: BasicDataLabels.FECHA_CONST,
    name: "date",
    type: TypeElement.DATE_PICKER,
    value: 6,
  },
  {
    filter: PersonType_EC_PE_Enum.JURIDICA,
    label: BasicDataLabels.WEB,
    name: "web",
    regExp: RegularExp.WEB,
    rules: {
      pattern: {
        message: ErrorMessageMX.REG_EXP_WEB,
        value: WEB_SITE_REGEX,
      },
      required: {
        message: ErrorMessageMX.PF_WEB,
        value: true,
      },
    },
    type: TypeElement.WEB,
    value: 6,
  },

  {
    filter: PersonType_EC_PE_Enum.NATURAL,
    label: BasicDataLabels.NOMBRE,
    name: "names",
    regExp: RegularExp.ONLY_LETTERS,
    rules: {
      maxLength: {
        message: ErrorMessageMX.MAX_1_50_CHAR,
        value: 50,
      },
      minLength: {
        message: ErrorMessageMX.MAX_1_50_CHAR,
        value: 1,
      },
      pattern: {
        message: ErrorMessageMX.LETRAS,
        value: new RegExp(RegularExp.ONLY_LETTERS),
      },
      required: {
        message: ErrorMessageMX.PF_NOMBRE,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 6,
  },
  {
    filter: PersonType_EC_PE_Enum.NATURAL,
    label: BasicDataLabels.APELLIDO,
    name: "surname",
    regExp: RegularExp.ONLY_LETTERS,
    rules: {
      maxLength: {
        message: ErrorMessageMX.MAX_1_50_CHAR,
        value: 50,
      },
      minLength: {
        message: ErrorMessageMX.MAX_1_50_CHAR,
        value: 1,
      },
      pattern: {
        message: ErrorMessageMX.LETRAS,
        value: new RegExp(RegularExp.ONLY_LETTERS),
      },
      required: {
        message: ErrorMessageMX.PF_APELLIDO,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 6,
  },
  {
    filter: PersonType_EC_PE_Enum.NATURAL,
    label: BasicDataLabels.TIPO_TAXID,
    name: "typeOfTaxId",
    regExp: RegularExp.CAPITAL_LETTER_NUMBERS,
    rules: {
      required: {
        message: ErrorMessageMX.PF_TIPO_TAX_ID,
        value: true,
      },
    },
    type: TypeElement.TEXT_COMBO,
    typeArray: TypeElement.COMBO_ARRAY,
    value: 6,
  },
  {
    filter: PersonType_EC_PE_Enum.NATURAL,
    label: BasicDataLabels.NUMBER_TAXID,
    name: "rfc",
    needCombo: true,
    regExp: RegularExp.LETTER_NUMBERS,
    rules: {
      required: {
        message: ErrorMessageMX.PF_NUM_ID,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 6,
  },

  {
    filter: PersonType_EC_PE_Enum.NATURAL,
    label: BasicDataLabels.TIPO_EMPRESA_USA,
    name: "typeCompany",
    rules: {
      required: {
        message: ErrorMessageMX.PF_COMPANY,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    value: 6,
  },
  {
    filter: PersonType_EC_PE_Enum.NATURAL,
    label: BasicDataLabels.TIPO_INDUSTRIA,
    name: "typeIndustry",
    rules: {
      required: {
        message: ErrorMessageMX.PF_INDUSTRIA,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    value: 6,
  },
  {
    filter: PersonType_EC_PE_Enum.NATURAL,
    label: BasicDataLabels.MCC,
    name: "mcc",
    rules: {
      required: {
        message: ErrorMessageMX.PF_MCC,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    typeArray: TypeElement.COMBO_ARRAY,
    value: 6,
  },
  {
    filter: PersonType_EC_PE_Enum.NATURAL,
    label: BasicDataLabels.FECHA_CONST,
    name: "date",
    type: TypeElement.DATE_PICKER,
    value: 6,
  },
  {
    filter: PersonType_EC_PE_Enum.NATURAL,
    label: BasicDataLabels.WEB,
    name: "web",
    regExp: RegularExp.WEB,
    rules: {
      pattern: {
        message: ErrorMessageMX.REG_EXP_WEB,
        value: WEB_SITE_REGEX,
      },
      required: {
        message: ErrorMessageMX.PF_WEB,
        value: true,
      },
    },
    type: TypeElement.WEB,
    value: 6,
  },
];
