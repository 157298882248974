import React from "react";
import { Button, Grid, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { staticText } from "../../../shared/infrastructure/staticText";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { CloudSecurityEnum } from "../../../shared/infrastructure/CloudSecurityEnum";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: "#E2E8F0",
    borderRadius: "5px",
    marginBottom: "10px",
  },
  containerTitle: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  containerAction: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  title: {
    fontSize: "16px",
    color: "#023365",
  },
  titleText: {
    fontWeight: 500,
    marginTop: theme.spacing(2),
    fontSize: "16px",
    color: "#023365",
  },
  subTitle: {
    fontWeight: 500,
    fontSize: "15px",
    color: "#6D7781",
  },
  buttonSkip: {
    Width: " 41px",
    Height: " 20px",
    backgroundColor: theme.palette.background.default,
    marginRight: theme.spacing(3),
    "&:disabled": {
      marginBottom: theme.spacing(3),
    },
  },
  buttonReconcile: {
    Width: " 117px",
    Height: "36px",
    color: theme.palette.background.default,
    backgroundColor: theme.palette.primary.main,

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default,
    },
    "&:disabled": {
      color: theme.palette.background.default,
      backgroundColor: theme.palette.primary.main,
      marginBottom: theme.spacing(3),
      opacity: 0.5,
    },
  },
}));

export interface BankReconciliationProps {
  numberItems: number;
  actionSkip?: () => void;
  actionReconcile?: () => void;
}

export const BankReconciliation = (props: BankReconciliationProps) => {
  const classes = useStyles();
  const disabledValue = props.numberItems !== 0;

  return (
    <>
      <Grid className={classes.container} container justify="space-between">
        <Grid className={classes.containerTitle}>
          {disabledValue ? (
            <>
              <Typography className={classes.title}>
                {staticText.TITLE}
              </Typography>
              <Typography className={classes.subTitle}>
                {`${props.numberItems} ${staticText.SUB_TITLE}`}
              </Typography>
            </>
          ) : (
            <Typography className={classes.titleText}>
              {staticText.TITLE}
            </Typography>
          )}
        </Grid>
        <Grid className={classes.containerAction}>
          <ComponentSecurityWrapper componentId={CloudSecurityEnum.OMIT}>
            <Button
              id={"ButtonSkip"}
              variant="contained"
              disabled={!disabledValue}
              className={classes.buttonSkip}
              onClick={props.actionSkip}
            >
              Omitir
            </Button>
          </ComponentSecurityWrapper>
          <ComponentSecurityWrapper componentId={CloudSecurityEnum.CONCILIATE}>
            <Button
              id={"ButtonReconcile"}
              variant="contained"
              disabled={!disabledValue}
              className={classes.buttonReconcile}
              onClick={props.actionReconcile}
            >
              Conciliar
            </Button>
          </ComponentSecurityWrapper>
        </Grid>
      </Grid>
    </>
  );
};
