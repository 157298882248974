import { CustomStyles } from "../../../../shared/interfaces/CustomStyles";

export const boardMembersContentStyles: CustomStyles = {
  container: {
    display: "flex",
    gap: "1rem",
    justifyContent: "flex-start",
    paddingLeft: "3.5rem",
  },
  info: {
    borderRight: "1px solid #CBD5E0",
    paddingRight: "1rem",
  },
  text: {
    color: "#6D7781",
    fontSize: { sm: "15px" },
  },
  textBlack: {
    color: "#293036",
    fontSize: { sm: "15px" },
  },
  country: {
    verticalAlign: "bottom",
    marginRight: "5px",
    display: "inline-flex",
  },
  flex: {
    display: "flex",
  },
};
