import { OptionsWithTZ, utcToZonedTime, format } from "date-fns-tz";

const utcTimeZone =
  localStorage.getItem("timeZone") || localStorage.getItem("time-zone") || "";

export const formatZoned = (
  date: Date,
  format_: string,
  options?: OptionsWithTZ
) => {
  const zonedTime = utcToZonedTime(date, utcTimeZone);

  return format(zonedTime, format_, options);
};
