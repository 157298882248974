import React, { FC } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { sectionInfoComponentStyles } from "./SectionInfoComponent.styles";
import { ISectionComponent } from "./SectionComponent.interfaces";
import { defaultTo } from "lodash";

const SectionInfoComponent: FC<ISectionComponent> = (
  props: ISectionComponent
) => {
  return (
    <React.Fragment>
      {!props.hidden && (
        <Grid container sx={sectionInfoComponentStyles.principal}>
          <Grid direction={"row"} container>
            <Grid item xs={4}>
              <Box sx={sectionInfoComponentStyles.sectionInfoComponent}>
                <Grid>
                  <Typography
                    key={`titleChip_${Date.now().toString()}`}
                    variant={"h4"}
                    color={"primary"}
                    sx={sectionInfoComponentStyles.sectionTitleComponent}
                  >
                    {props.title}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    sx={sectionInfoComponentStyles.sectionDesc}
                    variant={"h6"}
                  >
                    {props.description}
                  </Typography>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={8}>
              {defaultTo(props.children!, <></>)}
            </Grid>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default SectionInfoComponent;
