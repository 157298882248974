import { cloneDeep, get, isNil, isUndefined, set } from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getCatalog } from "../../../../shared/constants/dispersions/dispersions";
import { CatalogsEnum } from "../../../../shared/enums/CatalogsEnum";
import { CountriesEnum } from "../../../../shared/enums/countriesEnum";
import { BankAccountFields } from "../../../../shared/enums/dispersionsEnum";
import { Category } from "../../../../shared/interfaces/category";
import {
  addBankAccount,
  editBankAccount,
  updateBankAccountIndexOnFocus,
} from "../../../../store/actions/dispersion/dispersion.actions";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../store/hooks/storeHook";
import { IBankAccount } from "../../../../store/interfaces/dispersion.interfaces";
import { IUseBankAccountFormsModal } from "./useBankAccountFormsModalState.interfaces";
import {
  cleanBankAccountFields,
  getBankAccountOrder,
  getInitialBankAccountValues,
  validateIfHasMainAccount,
} from "../../../../shared/utils/bankAccountUtils";
import { TypeAccountEnum } from "../../../../shared/enums/TypeAccountEnum";
import { getListCatalogs } from "../../../../store/thunks/catalogs/catalogs.thunk";
import { ConfigIdEnum } from "../../../../shared/constants/node_config_enums";
import { CountryCodeEnum } from "../../../../shared/enums/CountryCodeEnum";
import { ProductLineEnum } from "../../../../shared/enums/ProductLineEnum";
import { CatalogsNameEnum } from "../../../../shared/constants/catalogs/CatalogsNameEnum";
import { ICatalogsData } from "../../../../store/interfaces/catalogs/catalogs.interfaces";
import {
  catalogsDataToCategory,
  getDataFromCatalogByName,
} from "../../../../shared/utils/catalog";
import { Data } from "../../../../../types/catalog_response";

export const useBankAccountFormsModalState = (): IUseBankAccountFormsModal => {
  const dispatch = useAppDispatch();
  const {
    banksAccounts: bankAccountsStore,
    constitutionalCountry,
    beneficiaryNameFromBasicDetails,
    bankAccountIndexOnFocus,
  } = useAppSelector((state) => state.dispersion);
  const { listCatalogs }: ICatalogsData = useAppSelector(
    (state) => state.catalogs
  );
  const {
    control,
    formState: { errors },
    getValues,
    resetField,
    setValue,
    reset,
    handleSubmit,
    clearErrors,
  } = useForm<IBankAccount>({
    defaultValues: getInitialBankAccountValues({
      bankAccounts: bankAccountsStore,
      beneficiaryNameFromBasicDetails,
      constitutionalCountry,
    }),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const [isDiferentConstitutionCountry, setisDiferentConstitutionCountry] =
    useState<boolean>(false);
  const [isEditingForm, setIsEditingForm] = useState(false);
  const [hasMainAccount, setHasMainAccount] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bankAccountIndex, setBankAccountIndex] = useState<number>(
    bankAccountsStore.length
  );
  const [openBankAccountModal, setOpenBankAccountModal] =
    useState<boolean>(false);
  const [country, setCountry] = useState<string>(constitutionalCountry);

  const countryIs = (countryExpected: CountriesEnum): boolean =>
    country.toUpperCase() === countryExpected.toUpperCase();

  const getCatalogToRender = (
    country: string,
    Catalog: CatalogsEnum
  ): Category[] => {
    if (countryIs(CountriesEnum.CHILE) && Catalog === CatalogsEnum.Banks) {
      const banksListDispersionData: Data[] = getDataFromCatalogByName(
        listCatalogs,
        CatalogsNameEnum.BANK_LIST_DISPERSION
      );

      return catalogsDataToCategory(banksListDispersionData);
    }

    return getCatalog(country as CountriesEnum, Catalog);
  };

  const [currenciesList, setCurrenciesList] = useState<Category[]>(
    getCatalogToRender(country, CatalogsEnum.Currency)
  );

  const [banksList, setBanksList] = useState<Category[]>(
    getCatalogToRender(country, CatalogsEnum.Banks)
  );

  const [isInternationalTransfer, setIsInternationalTransfer] = useState(false);

  const changeIsInternationalTransfer = (value: boolean): void => {
    if (!value) {
      setValue(`${BankAccountFields.BANK_CITY_OF_ORIGIN}`, "");
      setValue(`${BankAccountFields.BANK_POSTAL_CODE_ORIGIN}`, "");
      setValue(`${BankAccountFields.TYPE_ACCOUNT}`, undefined);
      setValue(`${BankAccountFields.FINANCIAL_INSTITUTION_ADDRESS}`, "");
      setValue(`${BankAccountFields.IBAN_CODE}`, "");
      setValue(`${BankAccountFields.SWIFT_CODE}`, "");
    } else {
      setValue(
        `${BankAccountFields.TYPE_ACCOUNT}`,
        TypeAccountEnum.INTERNATIONAL
      );
      setValue(`${BankAccountFields.ACCOUNT_TYPE}`, "");
    }

    setIsInternationalTransfer(value);
  };

  const changeCountry = (country: string) => {
    setCountry(country);
    setIsInternationalTransfer(false);
    setValue(BankAccountFields.KEY_ACCOUNT_NUMBER, null as never);
    setValue(BankAccountFields.SWIFT_CODE, null as never);
    setValue(BankAccountFields.IBAN_CODE, null as never);
    setValue(BankAccountFields.ROUTING_NUMBER, null as never);
    setValue(BankAccountFields.ABA_CODE, null as never);
    setValue(BankAccountFields.BANK_ID, null as never);
    setValue(BankAccountFields.FINANCIAL_INSTITUTION_ADDRESS, null as never);
    changeIsInternationalTransfer(false);
  };

  const [orderAccountState, setOrderAccountState] = useState(
    getBankAccountOrder(bankAccountsStore)
  );

  const handleEditBankAccount = (index: number) => {
    setIsEditingForm(true);
    setBankAccountIndex(index);
    setCountry(bankAccountsStore[`${index}`].country);
    reset(bankAccountsStore[index]);
    setOrderAccountState(bankAccountsStore[`${index}`].accountOrder);
    setOpenBankAccountModal(true);
  };

  const saveBankAccount = (data: IBankAccount) => {
    const cleanData: IBankAccount = cleanBankAccountFields(data);

    setIsLoading(true);

    if (isEditingForm) {
      setIsEditingForm(false);
      const newBanks = set(
        cloneDeep(bankAccountsStore),
        `[${bankAccountIndex}]`,
        cleanData
      );

      dispatch(editBankAccount(newBanks));
      dispatch(updateBankAccountIndexOnFocus(undefined));
    } else {
      dispatch(
        addBankAccount({
          ...cleanData,
          accountOrder: getBankAccountOrder(bankAccountsStore),
        })
      );
    }

    setOpenBankAccountModal(false);
    reset(
      getInitialBankAccountValues({
        bankAccounts: bankAccountsStore,
        beneficiaryNameFromBasicDetails,
        constitutionalCountry,
      })
    );
    setIsLoading(false);
  };

  const closeBankAccountModal = () => {
    setOpenBankAccountModal(false);
    reset();
    setIsEditingForm(false);
    dispatch(updateBankAccountIndexOnFocus(undefined));
  };

  const handleOpenBankAccountModal = () => {
    setOrderAccountState(getBankAccountOrder(bankAccountsStore));
    setCountry(constitutionalCountry);
    if (!isEditingForm) {
      setBankAccountIndex(-1);
      reset(
        getInitialBankAccountValues({
          accountType: "0",
          bankAccounts: bankAccountsStore,
          beneficiaryNameFromBasicDetails,
          constitutionalCountry,
        })
      );
    }
    setOpenBankAccountModal(true);
  };

  useEffect(() => {
    if (listCatalogs.length === 0)
      dispatch(
        getListCatalogs({
          configCode: ConfigIdEnum.CN004,
          countryCode: CountryCodeEnum.CL,
          name: CatalogsNameEnum.BANK_LIST_DISPERSION,
          productLine: ProductLineEnum.PLA,
        })
      );
  }, []);

  useEffect(() => {
    const currenciesNewList = getCatalogToRender(
      country,
      CatalogsEnum.Currency
    );
    const banksNewList: Category[] = getCatalogToRender(
      country,
      CatalogsEnum.Banks
    );
    const currencyValue =
      isEditingForm &&
      country === get(bankAccountsStore[bankAccountIndex], "country")
        ? get(bankAccountsStore[bankAccountIndex], "currency")
        : currenciesNewList[0].value;

    setCurrenciesList(currenciesNewList);
    setBanksList(banksNewList);
    setValue(BankAccountFields.CURRENCY, currencyValue as never);
    setValue(BankAccountFields.COUNTRY, country as never);

    if (
      country.toUpperCase().trim() !==
      constitutionalCountry.toUpperCase().trim()
    ) {
      setisDiferentConstitutionCountry(true);
      if (!isEditingForm) {
        setValue!(BankAccountFields.BENEFICIARY_NAME, "" as never);
      }

      return;
    }

    setisDiferentConstitutionCountry(false);
  }, [country]);

  useEffect(() => {
    if (isNil(bankAccountIndexOnFocus)) return;

    handleEditBankAccount(bankAccountIndexOnFocus);
  }, [bankAccountIndexOnFocus]);

  useEffect(() => {
    setHasMainAccount(validateIfHasMainAccount(bankAccountsStore));
  }, [bankAccountsStore]);

  useEffect(() => {
    setIsInternationalTransfer(!isUndefined(control._formValues.typeAccount));
  }, [control._formValues]);

  return {
    addBankAccountSameCountryProps: {
      banksForm: {
        clearErrors,
        control,
        errors,
        getValues,
        resetField,
        setValue,
      },
      banksList,
      changeCountry,
      changeIsInternationalTransfer,
      country,
      countryIs,
      currenciesList,
      getCatalogToRender,
      hasMainAccount,
      isDispersionTab: true,
      isEditingForm,
      isInternationalTransfer,
      orderAccountState,
    },
    closeBankAccountModal,
    handleOpenBankAccountModal,
    isDiferentConstitutionCountry,
    isEditingForm,
    isLoading,
    openBankAccountModal,
    saveBankAccount: handleSubmit(saveBankAccount),
  };
};
