import { ColumnsItem } from "@kushki/connect-ui";
import { ColumnSelectEnum } from "../enums/ColumnSelectEnum";
import {
  HeaderColumnIdEnum,
  headTooltip,
  TOOLTIP_LABELS,
} from "../enums/HeaderColumnIdEnum";
import { HeadCell } from "../../components/Table/TableHead/TableHead.interfaces";
import {
  ActionRow,
  CustomRowTable,
  StatusRow,
} from "../catalogs/CatalogConfigTableTransaction";
import { get } from "lodash";
import { isCustomerRol } from "./index";
import { user_profile } from "../constants/PayoutsListConstants";
import { auth } from "../auth";

export const column_selected: ColumnsItem[] = [
  {
    field: HeaderColumnIdEnum.COMPLETED,
    isDisabled: false,
    isSelected: false,
    label: ColumnSelectEnum.COMPLETED,
  },
  {
    field: HeaderColumnIdEnum.OWNER,
    isDisabled: false,
    isSelected: true,
    label: ColumnSelectEnum.OWNER,
  },
  {
    field: HeaderColumnIdEnum.CUSTOMER,
    isDisabled: false,
    isSelected: true,
    label: ColumnSelectEnum.CUSTOMER,
  },
  {
    field: HeaderColumnIdEnum.AMOUNT,
    isDisabled: false,
    isSelected: true,
    label: ColumnSelectEnum.AMOUNT,
  },
  {
    field: HeaderColumnIdEnum.TYPE,
    isDisabled: false,
    isSelected: true,
    label: ColumnSelectEnum.TYPE,
  },
  {
    field: HeaderColumnIdEnum.METHOD,
    isDisabled: false,
    isSelected: true,
    label: ColumnSelectEnum.METHOD,
  },
  {
    field: HeaderColumnIdEnum.TICKET_NUMBER,
    isDisabled: false,
    isSelected: true,
    label: ColumnSelectEnum.TICKET_NUMBER,
  },
  {
    field: HeaderColumnIdEnum.EMAIL,
    isDisabled: false,
    isSelected: false,
    label: ColumnSelectEnum.EMAIL,
  },
];

const cellMerchant: HeadCell[] = [
  {
    align: "left",
    disablePadding: false,
    id: HeaderColumnIdEnum.BRANCH,
    isActive: true,
    label: ColumnSelectEnum.BRANCH,
  },
];

const validateMerchantCell = (
  field: string,
  hasCustomer?: ColumnsItem,
  hasOwner?: ColumnsItem
) =>
  (hasCustomer && !hasOwner && field === HeaderColumnIdEnum.CUSTOMER) ||
  (hasOwner && !hasCustomer && field === HeaderColumnIdEnum.OWNER) ||
  (hasCustomer && hasOwner && field === HeaderColumnIdEnum.CUSTOMER) ||
  (!hasCustomer && !hasOwner && field === HeaderColumnIdEnum.COMPLETED);

export const buildSelectedHead = (data: ColumnsItem[]): HeadCell[] => {
  const hasCustomer: ColumnsItem | undefined = data.find(
    (item) => item.field === HeaderColumnIdEnum.CUSTOMER
  );
  const hasOwner: ColumnsItem | undefined = data.find(
    (item) => item.field === HeaderColumnIdEnum.OWNER
  );

  const cells: HeadCell[] = data.reduce(
    (acc: HeadCell[], currentValue: ColumnsItem) => {
      const cell: HeadCell = {
        align: "left",
        disablePadding: false,
        hasTooltip: headTooltip.includes(currentValue.field),
        id: currentValue.field,
        isActive: currentValue.isSelected,
        label: currentValue.label,
        tooltipLabel: TOOLTIP_LABELS[currentValue.field],
      };

      acc.push(cell);

      if (
        validateMerchantCell(currentValue.field, hasCustomer, hasOwner) &&
        (isCustomerRol() || user_profile.admin)
      )
        acc.push({
          ...cell,
          hasTooltip: false,
          id: HeaderColumnIdEnum.BRANCH,
          label: ColumnSelectEnum.BRANCH,
        });

      return acc;
    },
    []
  );

  return cells.find((item) => item.label === ColumnSelectEnum.BRANCH) ||
    (!isCustomerRol() && !user_profile.admin)
    ? cells
    : cellMerchant.concat(cells);
};

export const getCustomRow = (selectedItems: ColumnsItem[]): any[] => {
  const hasCustomer: ColumnsItem | undefined = selectedItems.find(
    (item) => item.field === HeaderColumnIdEnum.CUSTOMER
  );
  const hasOwner: ColumnsItem | undefined = selectedItems.find(
    (item) => item.field === HeaderColumnIdEnum.OWNER
  );

  const rows: any[] = selectedItems.reduce((acc: any[], curr: ColumnsItem) => {
    acc.push(CustomRowTable[curr.label]);

    if (
      validateMerchantCell(curr.field, hasCustomer, hasOwner) &&
      (isCustomerRol() || user_profile.admin)
    )
      acc.push(CustomRowTable[ColumnSelectEnum.BRANCH]);

    return acc;
  }, []);

  return rows.find(
    (item) => get(item, "configMain.defaultValue", "") === "merchantName"
  ) ||
    (!isCustomerRol() && !user_profile.admin)
    ? rows.concat(StatusRow, ActionRow)
    : [CustomRowTable[ColumnSelectEnum.BRANCH]].concat(
        rows.concat(StatusRow, ActionRow)
      );
};

export const getMaxColumns = (): 1 | 2 | 3 | 4 | 5 | 6 | 7 => {
  if (auth.isAdmin()) return 6;

  return 4;
};
