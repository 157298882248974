import React from "react";
import { Box, Divider, Modal, Typography } from "@mui/material";
import { modalBoardMembersStyles as styles } from "./ModalBoardMembers.styles";
import IconButton from "@mui/material/IconButton";
import { IconCircleCross } from "@kushki/connect-ui";
import BoardMemberAccordion from "../BoardMembersAccordion/BoardMemberAccordion";
import { IBoardMember } from "../../../store/interfaces/LegalDetailsState.interfaces";
import {
  BoardMemberContentEnum,
  BoardMemberModalEnum,
} from "../../../shared/enum/BoardMemberContentEnum";

interface IModalBoardMembersProps {
  boardMembers: IBoardMember[];
  openModal: boolean;
  handleCloseModal: () => void;
  openDeleteModal: () => void;
  setSelectedBoardMember: (bm: IBoardMember) => void;
}

const ModalBoardMembers: React.FC<IModalBoardMembersProps> = ({
  boardMembers,
  openModal,
  handleCloseModal,
  setSelectedBoardMember,
  openDeleteModal,
}: IModalBoardMembersProps) => {
  return (
    <Modal open={openModal}>
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <Typography variant="h3" color="primary">
            {BoardMemberModalEnum.ADDED_BM}
          </Typography>
          <IconButton sx={styles.closeModal} onClick={handleCloseModal}>
            <IconCircleCross color="primary" />
          </IconButton>
        </Box>
        <Divider flexItem />
        <Box sx={styles.accordionContainer}>
          {boardMembers.map((boardMember, idx) => (
            <BoardMemberAccordion
              key={`bmAcc-${idx}-${boardMember.documentNumber}`}
              boardMember={boardMember}
              showMenuActions={true}
              variant={BoardMemberContentEnum.MAIN}
              openDeleteModal={openDeleteModal}
              setSelectedBoardMember={setSelectedBoardMember}
            />
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalBoardMembers;
