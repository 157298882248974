import React from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  Button,
  Popper,
  Paper,
  Grow,
} from "@material-ui/core";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { useBusinessRulesState } from "./state/useBusinessRulesIndexState";
import {
  INotification,
  LabelField,
  RulesNotificationResultEnum,
} from "../../shared/infrastructure/constants/RulesConstants";
import { BusinessRulesTable } from "../../components/BusinessRulesSection/BusinessRulesTable/BusinessRulesTable";
import {
  deleteBusinessRule,
  getBusinessRules,
  getProcessors,
  IRulesState,
  RulesAction,
  saveDefaultProcessor,
  setIsLoading,
  setIsRequest,
  setNotification,
} from "../../store/actionCreator";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { defaultTo, get } from "lodash";
import { FormProvider } from "react-hook-form";
import { SnackbarAlert } from "../../components/SnackBarAlert/SnackBarAlert";
import { DefaultProcessorsMerchant } from "../../components/BusinessRulesSection/BusinessRulesTable/DefaultProcessorSelect/DefaultProcessorsMerchant";
import { UpdateMerchantRulesRequest } from "../../../types/update_merchant_rules_request";
import {
  getMerchantInfo,
  getProcessorListInfo,
} from "../../shared/infrastructure/constants/BusinessRulesEnum";
import { ProcessorsInformation } from "../../../types/processors_list";
import { GetBusinessRulesResponse } from "../../../types/get_business_rules_response";

export interface BusinessRulesListStateProps {
  isLoading: boolean | undefined;
  isRequest: boolean | undefined;
  processors: ProcessorsInformation[] | undefined;
  businessRuleList: GetBusinessRulesResponse | undefined;
  defaultProcessor: object | undefined;
  notification: INotification | undefined;
}

export interface BusinessRulesListFunctionsProps {
  setIsLoading: (payload: boolean) => RulesAction;
  setIsRequest: (payload: boolean) => RulesAction;
  getProcessors: (merchantId: string, filterProcessor: boolean) => void;
  deleteBusinessRule: (
    ruleId: string,
    merchantId: string,
    limit: number
  ) => void;
  saveDefaultProcessor: (payload: UpdateMerchantRulesRequest) => void;
  getBusinessRuleList: (payload: {
    merchantId: string;
    limit: number;
    lastEvaluatedKey?: any;
  }) => void;
  setNotification: (payload: INotification) => RulesAction;
}

export type BusinessRulesIndexProps = BusinessRulesListStateProps &
  BusinessRulesListFunctionsProps;

export const BusinessRulesIndex: React.FC<BusinessRulesIndexProps> = (
  props: BusinessRulesIndexProps
) => {
  const {
    merchantId,
    merchantInfo,
    pagination,
    handler,
    openPopover,
    form,
    dialog,
    processors,
  } = useBusinessRulesState(props);
  const [anchorEl] = React.useState<HTMLButtonElement | null>(null);

  // @ts-ignore
  return (
    <React.Fragment>
      <Container fixed>
        <Breadcrumb
          items={defaultTo(
            get(
              getMerchantInfo(merchantId!, merchantInfo.name)[
                `${merchantInfo.origin}`
              ],
              "breadcrumb"
            ),
            getProcessorListInfo(merchantId!).breadcrumb
          )}
          lastItem={"Reglas de negocio"}
        />
        <Grid container>
          <Box display="flex" style={{ width: "100%" }}>
            <Box flexGrow={1} pt={2} pb={3}>
              <Typography variant={"h1"}>Reglas de negocio</Typography>
            </Box>
            <Box flexGrow={4} pt={3} pb={3} pr={3}>
              <Button
                variant="outlined"
                id={"returnCommerce"}
                size="medium"
                onClick={handler.handleClickReturnCreateCommerce}
                color="primary"
                hidden={defaultTo(
                  get(
                    getMerchantInfo(merchantId!, merchantInfo.name)[
                      `${merchantInfo.origin}`
                    ],
                    "hidden_button"
                  ),
                  false
                )}
              >
                {LabelField.RETURN_CREATE_COMMERCE}
              </Button>
            </Box>
            <Box pt={3} pb={3} pl={1}>
              <FormProvider {...form}>
                <Popper
                  open={openPopover}
                  anchorEl={anchorEl}
                  placement="bottom-end"
                  transition
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper style={{ maxWidth: "500px", padding: "15px" }}>
                        <DefaultProcessorsMerchant
                          closePopover={() => handler.changeOpenPopover(false)}
                          processors={processors}
                          isRequest={props.isRequest!}
                          onSave={form.handleSubmit(
                            handler.handleSubmitDefaultProcessor
                          )}
                        />
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </FormProvider>
            </Box>
            <Box pt={3} pb={3} pl={1}>
              <Button
                id={"addRule"}
                size="medium"
                onClick={() => {
                  window.location.href = `/rules/business/rule/${merchantId}`;
                }}
                variant="contained"
                color="primary"
              >
                {LabelField.ADD_RULE}
              </Button>
            </Box>
          </Box>
          <Grid item xs={12}>
            <BusinessRulesTable
              isLoading={props.isLoading!}
              businessRules={get(props.businessRuleList, "items", [])}
              editRule={handler.editRule}
              pagination={pagination}
              dialog={dialog}
            />
          </Grid>
        </Grid>
        {get(props, "notification.action") !==
          RulesNotificationResultEnum.NO_ACTION && (
          <SnackbarAlert
            type={get(props, "notification.type")}
            msg={get(props, "notification.message")}
            open={get(props, "notification.open")}
            handlerClose={handler.handleCloseNotification}
          />
        )}
      </Container>
    </React.Fragment>
  );
};

export const mapStateToProps: (
  state: IRulesState
) => BusinessRulesListStateProps = (
  state: IRulesState
): BusinessRulesListStateProps => ({
  isLoading: state.isLoading,
  processors: state.processorsMerchant,
  businessRuleList: state.businessRulesList,
  isRequest: state.isRequest,
  defaultProcessor: state.defaultProcessor,
  notification: state.notification,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => BusinessRulesListFunctionsProps = (
  dispatch: ThunkDispatch<IRulesState, undefined, RulesAction>
): BusinessRulesListFunctionsProps => ({
  setIsLoading: (payload: boolean): RulesAction =>
    dispatch(setIsLoading(payload)),
  getProcessors: (merchantId: string, filterProcessor: boolean): void =>
    dispatch(getProcessors(merchantId, undefined, filterProcessor)),
  saveDefaultProcessor: (payload: UpdateMerchantRulesRequest): void =>
    dispatch(saveDefaultProcessor(payload)),
  setIsRequest: (payload: boolean): RulesAction =>
    dispatch(setIsRequest(payload)),
  getBusinessRuleList: (payload: {
    merchantId: string;
    limit: number;
    lastEvaluatedKey?: any;
  }): void => dispatch(getBusinessRules(payload)),
  setNotification: (payload: INotification): RulesAction =>
    dispatch(setNotification(payload)),
  deleteBusinessRule: (
    ruleId: string,
    merchantId: string,
    limit: number
  ): void => dispatch(deleteBusinessRule(ruleId, merchantId, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessRulesIndex);
