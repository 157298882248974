import { createNamedStyles } from "../../shared/interfaces/create_named_styles";
import { green } from "@mui/material/colors";

export const userCreateEditStyles = createNamedStyles({
  radioRoles: {
    paddingBottom: "20px",
  },
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 40,
    color: "#023365",
    fontFamily: "IBM Plex Sans,sans-serif",
    lineHeight: "12px",
    fontWeight: 500,
    fontStyle: "normal",
    marginBottom: "3rem",
    marginTop: "1rem",
  },
  pos: {
    marginBottom: 12,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  buttons: {
    textTransform: "none",
  },
  ButtonCancel: {
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
    },
  },
  ButtonSave: {
    textTransform: "capitalize",
  },
  backBtnNewConsole: {
    color: "#4498EE",
    textTransform: "capitalize",
    margin: "20px 0 15px 0",
    fontSize: "16px !important",
    "&:hover": {
      backgroundColor: "#f0f4f8",
      borderColor: "transparent",
    },
  },

  mainContainer: {
    marginBottom: 30,
    "@media (max-width: 1440px) and (min-width: 1025px)": {
      overflowY: "scroll",
      paddingBottom: "5em",
    },
    "@media (max-width: 1024px) and (min-width: 675px)": {
      overflowY: "scroll",
      paddingBottom: "10em",
    },
  },
  buttonOldConsole: {
    display: "flex",
    justifyContent: "end",
    marginTop: "40px",
  },
});
