import { IStyles } from "../../../shared/interfaces/Styles.interfaces";
import { withStyles } from "@mui/styles";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

export const ExpansionPanel = withStyles({
  root: {
    "&$expanded": {
      borderBottom: "1px solid rgba(0, 0, 0, .125)",
      margin: "auto",
    },
    "&:before": {
      display: "none",
    },
    "&:not(:last-child)": {
      borderTop: 0,
      margin: "0 !important",
    },
    borderBottom: "1px solid rgba(0, 0, 0, .08)",
    boxShadow: "none",
  },
})(Accordion);

export const ExpansionPanelSummary = withStyles({
  content: {
    "&$expanded": {
      margin: "10px 0",
    },
  },
  root: {
    "&$expanded": {
      borderBottom: 0,
      minHeight: 56,
    },
    backgroundColor: "rgba(0, 0, 0, 0)",
    margin: "0px !important",
    minHeight: 56,
    padding: "8px 0px",
  },
})(AccordionSummary);

export const ExpansionPanelDetails = AccordionDetails;

export const filterBoxStyles: IStyles = {
  applyFilterBtn: {
    "&.Mui-disabled": {
      backgroundColor: "#023365",
      opacity: "0.5",
    },
    backgroundColor: "#023365",
    borderRadius: "4px",
    color: "white",
    marginTop: "15px",
    padding: "8px 30px",
    textTransform: "none",
  },
  buttonsBox: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: 1,
  },
  buttonsText: {
    fontSize: "14px !important",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "140%",
  },
  filterChip: {
    "&.MuiChip-root": {
      borderRadius: "4px !important",
      fontSize: "12px",
    },
    backgroundColor: "#F7FAFC !important",
    color: "#677684 !important",
    marginBottom: "8px",
    marginRight: "8px",
    padding: "3px 8px",
  },
  filterSelect: {
    "&.MuiOutlinedInput-root": {
      height: "44px",
    },
    color: "#6D7781",
    fontSize: "16px",
  },
  filterTitle: {
    color: "#293036",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "150%",
    marginTop: "3px",
  },
  kshExpand: {
    "&.MuiPaper-root .MuiAccordionSummary-content": {
      margin: "0px !important",
    },
    backgroundColor: "white",
    border: "none",
    borderBottom: "1px solid rgba(0, 0, 0, .08)",
    borderColor: "#CBD5E0",
  },
  kshExpandBtn: {
    "&:hover": {
      color: "#023365 !important",
      boxShadow: "none !important",
      border: "1px solid #FFFFFF",
    },
    border: "1px solid #FFFFFF",
    color: "#023365",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    letterSpacing: "0.01em",
    lineHeight: "15.6px",
    padding: 0,
    textTransform: "none",
  },
  kshExpandBtnText: {
    color: "#023365",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    letterSpacing: "0.01em",
    lineHeight: "120%",
  },
  kshTittle: {
    color: "#293036",
    fontSize: "15px !important",
    fontWeight: 400,
    lineHeight: "120%",
  },
  menuItem: {
    backgroundColor: "white",
    color: "#6D7781",
    fontSize: "16px",
    fontWeight: "22.4px",
    height: "45px",
    width: "240px",
  },
  panelAmountDetails: {
    "&.MuiAccordionDetails-root": {
      padding: "0px 0px 16px 0px !important",
    },
  },
  restoreBtn: {
    "&.Mui-disabled": {
      border: "1px solid #B4B9BE",
      opacity: "0.5",
    },
    border: "1px solid #CBD5E0",
    borderRadius: "4px",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "140%",
    marginTop: "15px",
    padding: "8px 30px",
    textTransform: "none",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "left",
  },
  adornmentText: {
    "&.MuiInputAdornment-root .MuiTypography-root": {
      color: "text.dark",
      fontSize: "16px",
    },
  },
};
