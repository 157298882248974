/**
 * RetentionTextEnum
 */
export enum RetentionTextEnum {
  IVA = "retención IVA",
  RENTA = "retención Renta",
}

export enum RetentionTitleEnum {
  IVA = "Retención IVA",
  RENTA = "Retención Renta",
}
