import { MerchantResponse } from "../../types/merchant_response";
import { ActionTypes } from "./actionTypes";
import { IAppAction } from "./actionCreators";
import { INotification } from "../shared/infrastructure/interfaces/INotification";
import { MerchantPeople } from "../../types/merchant_people";

export interface IAppState {
  notification: INotification | undefined;
  loading: boolean;
  merchantInformation: MerchantResponse | undefined;
  compliancePeople: MerchantPeople | undefined;
  completeSave: boolean;
  completeGetCompliance: boolean;
}

export const INITIAL_STATE: IAppState = {
  completeGetCompliance: false,
  completeSave: false,
  compliancePeople: undefined,
  loading: false,
  merchantInformation: undefined,
  notification: {
    message: "",
    open: false,
    type: "success",
  },
};

export const reducer = (
  state: IAppState = INITIAL_STATE,
  action: IAppAction
): IAppState => {
  switch (action.type) {
    case ActionTypes.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.notification,
      };
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.HIDE_LOADING:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.SET_MERCHANT_INFORMATION:
      return {
        ...state,
        merchantInformation: action.merchantInformation,
      };
    case ActionTypes.SET_COMPLIANCE_PEOPLE:
      return {
        ...state,
        compliancePeople: action.compliancePeople,
      };
    case ActionTypes.SET_COMPLETE_SAVE:
      return {
        ...state,
        completeSave: action.completeSave!,
      };
    case ActionTypes.SET_COMPLETE_GET_COMPLIANCE:
      return {
        ...state,
        completeGetCompliance: action.completeGetCompliance!,
      };
    default:
      return state;
  }
};
