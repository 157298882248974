import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.error.dark,
    fontSize: "16px",
  },
  buttonClose: {
    fontSize: "18px !important",
    color: "#AD0C2A !important",
    marginTop: "20px !important",
    maxHeight: "50px !important",
  },
  buttonEntendido: {
    fontSize: "16px !important",
    position: "relative",
    marginLeft: "430px !important",
    marginTop: "-30px !important",
    color: "secondary !important",
  },
  buttonAccept: {
    fontSize: "18px !important",
    color: "#023365 !important",
    marginTop: "20px !important",
    maxHeight: "50px !important",
  },
  iconButtonClose: {
    position: "absolute",
    alignContent: "end !important",
    marginLeft: "50px !important",
  },
  dialog: {
    padding: "10",
    width: "592px",
    minHeight: "326px",
  },
  titleText: {
    fontFamily: "IBM Plex Sans",
    fontSize: "17px !important",
    fontStyle: "normal",
    fontWeight: "bolder",
    margin: "10px",
  },
  alert: {
    marginTop: "15px",
  },
  labelContainer: {
    display: "flex",
    flexWrap: "nowrap",
    width: "370px",
  },
  labelContent: {
    display: "flex",
    flexWrap: "nowrap",
  },
  downloadButton: {
    marginLeft: "280px",
  },
  titleDialog: {
    fontSize: "18px !important",
    marginBottom: "10px !important",
    marginTop: "10px !important",
  },
  textDialog: {
    fontSize: "16px !important",
    marginBottom: "10px !important",
    marginTop: "10px !important",
    marginLeft: "10px !important",
  },
  subtitletextDialog: {
    marginTop: "20px !important",
  },
  paper: {
    boxShadow: "none !important",
    borderRadius: "0px !important",
  },
  modifiedDateText: {
    fontSize: "16px !important",
    marginTop: "-10px !important",
    marginLeft: "10px !important",
  },
  grid: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },
  input: {
    margin: theme.spacing(1),
    "&.MuiFormControl-root": { marginLeft: 0 },
  },
  dialogContent: {
    padding: "8px 15px",
  },
}));
