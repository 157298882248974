import React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { mainContainerStyles as styles } from "../../../containers/MainContainer/MainContainer.styles";
import { SubHeaderLegalSkeleton } from "../SubHeaderLegalSkeleton/SubHeaderLegalSkeleton";
import { FormSkeleton } from "../FormSkeleton/FormSkeleton";
import { ContactContainerSkeletonStyles } from "./ContactContainerSkeleton.styles";

export const ContactContainerSkeleton: React.FC = () => {
  return (
    <Card sx={styles.cardContent} data-testid="contact-skeleton">
      <CardContent>
        <Box sx={styles.boxContent2}>
          <SubHeaderLegalSkeleton />
          <Box sx={ContactContainerSkeletonStyles.containerForm}>
            <FormSkeleton rows={10} />
          </Box>
          <Grid item md={12} xs={12} sx={{ marginTop: "2.5em" }}>
            <Typography
              variant={"subtitle2"}
              color={"textPrimary"}
              sx={ContactContainerSkeletonStyles.skeletonTxt}
            >
              <Skeleton />
            </Typography>
            <Grid
              container
              spacing={2}
              sx={ContactContainerSkeletonStyles.containerSelects}
            >
              <Grid
                item
                md={5}
                xs={3}
                sx={ContactContainerSkeletonStyles.select}
              >
                <Skeleton />
              </Grid>
              <Grid
                item
                md={5}
                xs={3}
                sx={ContactContainerSkeletonStyles.select}
              >
                <Skeleton />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};
