import { useEffect, useState } from "react";

export const useSkipTransactions = (reason: string) => {
  const [isDisabledSkipButton, setIsDisabledSkipButton] = useState(true);

  useEffect(() => {
    if (reason.length > 0) {
      setIsDisabledSkipButton(false);
    } else {
      setIsDisabledSkipButton(true);
    }
  }, [reason]);

  return { isDisabledSkipButton };
};
