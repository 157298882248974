export enum NotHierarchyContactInformationEnum {
  ACCOUNTING_AREA = "Área contable",
  COMMERCIAL_AREA = "Área comercial",
  TECHNICAL_AREA = "Área técnica",
}

export enum HierarchyContactInformationEnum {
  COMMERCIAL_AREA = "Área Comercial",
  BILLING_AREA = "Área de Facturación",
  DISPERSION_AREA = "Área Dispersión",
  TECHNICAL_AREA = "Área Técnica",
  RISK_AREA = "Área de Riesgos",
}

export let MAX_EMAIL_NUMBER: number = 20;
