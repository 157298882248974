import { TransactionStatusEnum } from "../enums/TransactionStatusEnum";
import { TransactionTypeEnum } from "../enums/TransactionTypeEnum";
import { PaymentMethodsEnum } from "../infraestructure/payment-method";
import { ContentInformation } from "../../components/CardInformation/CardInformation.interfaces";
import {
  CardSectionValueEnum,
  TransactionDataDataFieldValueEnum,
} from "../enums/CardInformationEnum";

const getContentInformationByFilter = (
  sectionList: ContentInformation[],
  filters: string[]
): ContentInformation[] =>
  sectionList.filter((section) =>
    filters.find((filter) => section.type === filter)
  );

export const catalogSectionFieldsByTransaction = (
  sectionFieldList: ContentInformation[]
): Record<string, object> => {
  const sectionFieldListWithoutTotalTransactionAndRefund =
    getContentInformationByFilter(sectionFieldList, [
      TransactionDataDataFieldValueEnum.TOTAL,
      TransactionDataDataFieldValueEnum.TYPE,
      TransactionDataDataFieldValueEnum.CLIENT_DOC_NUMBER,
      TransactionDataDataFieldValueEnum.KUSHKI_ID,
      TransactionDataDataFieldValueEnum.TAXES,
      TransactionDataDataFieldValueEnum.SUBTOTAL,
      TransactionDataDataFieldValueEnum.PAYMENT_METHOD,
      TransactionDataDataFieldValueEnum.APPROVAL_NO,
    ]);

  const sectionFieldListWithTotalTransactionAndRefund =
    getContentInformationByFilter(sectionFieldList, [
      TransactionDataDataFieldValueEnum.CLIENT_DOC_NUMBER,
      TransactionDataDataFieldValueEnum.TYPE,
      TransactionDataDataFieldValueEnum.TOTAL_TRANSACTION,
      TransactionDataDataFieldValueEnum.TOTAL_REFUND,
    ]);

  const approval_state = {
    [TransactionTypeEnum.SALE]: {
      [PaymentMethodsEnum.CARD]: {
        [CardSectionValueEnum.TRANSACTION_DATA]:
          sectionFieldListWithoutTotalTransactionAndRefund,
        [CardSectionValueEnum.PAYMENT_CREDENTIAL_DATA]: sectionFieldList,
        [CardSectionValueEnum.BILLING_DATA]: sectionFieldList,
        [CardSectionValueEnum.CARD_DATA]: sectionFieldList,
        [CardSectionValueEnum.AUTHORIZATION_DATA]: sectionFieldList,
        [CardSectionValueEnum.MERCHANT_DATA]: sectionFieldList,
      },
      [PaymentMethodsEnum.CASH]: {
        [CardSectionValueEnum.TRANSACTION_DATA]:
          sectionFieldListWithoutTotalTransactionAndRefund,
        [CardSectionValueEnum.PAYMENT_CREDENTIAL_DATA]: sectionFieldList,
        [CardSectionValueEnum.BILLING_DATA]: sectionFieldList,
        [CardSectionValueEnum.AUTHORIZATION_DATA]: sectionFieldList,
        [CardSectionValueEnum.MERCHANT_DATA]: sectionFieldList,
      },
      [PaymentMethodsEnum.TRANSFER]: {
        [CardSectionValueEnum.TRANSACTION_DATA]:
          sectionFieldListWithoutTotalTransactionAndRefund,
        [CardSectionValueEnum.PAYMENT_CREDENTIAL_DATA]: sectionFieldList,
        [CardSectionValueEnum.BILLING_DATA]: sectionFieldList,
        [CardSectionValueEnum.AUTHORIZATION_DATA]: sectionFieldList,
        [CardSectionValueEnum.MERCHANT_DATA]: sectionFieldList,
      },
    },
    [TransactionTypeEnum.CAPTURE]: {
      [PaymentMethodsEnum.CARD]: {
        [CardSectionValueEnum.TRANSACTION_DATA]:
          sectionFieldListWithoutTotalTransactionAndRefund,
        [CardSectionValueEnum.PAYMENT_CREDENTIAL_DATA]: sectionFieldList,
        [CardSectionValueEnum.BILLING_DATA]: sectionFieldList,
        [CardSectionValueEnum.CARD_DATA]: sectionFieldList,
        [CardSectionValueEnum.AUTHORIZATION_DATA]: sectionFieldList,
        [CardSectionValueEnum.MERCHANT_DATA]: sectionFieldList,
      },
    },
    [TransactionTypeEnum.DEFFERRED]: {
      [PaymentMethodsEnum.CARD]: {
        [CardSectionValueEnum.TRANSACTION_DATA]:
          sectionFieldListWithoutTotalTransactionAndRefund,
        [CardSectionValueEnum.PAYMENT_CREDENTIAL_DATA]: sectionFieldList,
        [CardSectionValueEnum.BILLING_DATA]: sectionFieldList,
        [CardSectionValueEnum.CARD_DATA]: sectionFieldList,
        [CardSectionValueEnum.AUTHORIZATION_DATA]: sectionFieldList,
        [CardSectionValueEnum.MERCHANT_DATA]: sectionFieldList,
      },
    },
    [TransactionTypeEnum.PREAUTHORIZATION]: {
      [PaymentMethodsEnum.CARD]: {
        [CardSectionValueEnum.TRANSACTION_DATA]:
          sectionFieldListWithoutTotalTransactionAndRefund,
        [CardSectionValueEnum.PAYMENT_CREDENTIAL_DATA]: sectionFieldList,
        [CardSectionValueEnum.BILLING_DATA]: sectionFieldList,
        [CardSectionValueEnum.CARD_DATA]: sectionFieldList,
        [CardSectionValueEnum.AUTHORIZATION_DATA]: sectionFieldList,
        [CardSectionValueEnum.MERCHANT_DATA]: sectionFieldList,
      },
    },
    [TransactionTypeEnum.VOID]: {
      [PaymentMethodsEnum.CARD]: {
        [CardSectionValueEnum.TRANSACTION_DATA]:
          sectionFieldListWithTotalTransactionAndRefund,
      },
      [PaymentMethodsEnum.CASH]: {
        [CardSectionValueEnum.TRANSACTION_DATA]:
          sectionFieldListWithTotalTransactionAndRefund,
      },
    },
    [TransactionTypeEnum.VOID_PARTIAL]: {
      [PaymentMethodsEnum.CARD]: {
        [CardSectionValueEnum.TRANSACTION_DATA]:
          sectionFieldListWithTotalTransactionAndRefund,
      },
      [PaymentMethodsEnum.CASH]: {
        [CardSectionValueEnum.TRANSACTION_DATA]:
          sectionFieldListWithTotalTransactionAndRefund,
      },
    },
    [TransactionTypeEnum.REFUND]: {
      [PaymentMethodsEnum.CARD]: {
        [CardSectionValueEnum.TRANSACTION_DATA]:
          sectionFieldListWithTotalTransactionAndRefund,
      },
    },
    [TransactionTypeEnum.REFUND_PARTIAL]: {
      [PaymentMethodsEnum.CARD]: {
        [CardSectionValueEnum.TRANSACTION_DATA]:
          sectionFieldListWithTotalTransactionAndRefund,
      },
    },
    [TransactionTypeEnum.REFUND_TOTAL]: {
      [PaymentMethodsEnum.CARD]: {
        [CardSectionValueEnum.TRANSACTION_DATA]:
          sectionFieldListWithTotalTransactionAndRefund,
      },
    },
    [TransactionTypeEnum.CHARGEBACK]: {
      [PaymentMethodsEnum.CARD]: {
        [CardSectionValueEnum.TRANSACTION_DATA]: sectionFieldList,
        [CardSectionValueEnum.PAYMENT_CREDENTIAL_DATA]: sectionFieldList,
        [CardSectionValueEnum.BILLING_DATA]: sectionFieldList,
        [CardSectionValueEnum.CARD_DATA]: sectionFieldList,
        [CardSectionValueEnum.AUTHORIZATION_DATA]: sectionFieldList,
        [CardSectionValueEnum.MERCHANT_DATA]: sectionFieldList,
      },
    },
    [TransactionTypeEnum.REVERSE]: {
      [PaymentMethodsEnum.CARD]: {
        [CardSectionValueEnum.TRANSACTION_DATA]:
          sectionFieldListWithoutTotalTransactionAndRefund,
      },
    },
  };

  const sectionFieldsByType: Record<string, object> = {
    [TransactionStatusEnum.APPROVAL]: approval_state,
    [TransactionStatusEnum.APPROVED]: approval_state,
    [TransactionStatusEnum.INITIALIZED]: {
      [TransactionTypeEnum.SALE]: {
        [PaymentMethodsEnum.CARD]: {
          [CardSectionValueEnum.TRANSACTION_DATA]:
            sectionFieldListWithoutTotalTransactionAndRefund,
          [CardSectionValueEnum.PAYMENT_CREDENTIAL_DATA]: sectionFieldList,
          [CardSectionValueEnum.BILLING_DATA]: sectionFieldList,
          [CardSectionValueEnum.AUTHORIZATION_DATA]: sectionFieldList,
          [CardSectionValueEnum.MERCHANT_DATA]: sectionFieldList,
          [CardSectionValueEnum.CARD_DATA]: sectionFieldList,
        },
        [PaymentMethodsEnum.CASH]: {
          [CardSectionValueEnum.TRANSACTION_DATA]:
            sectionFieldListWithoutTotalTransactionAndRefund,
          [CardSectionValueEnum.PAYMENT_CREDENTIAL_DATA]: sectionFieldList,
          [CardSectionValueEnum.BILLING_DATA]: sectionFieldList,
          [CardSectionValueEnum.AUTHORIZATION_DATA]: sectionFieldList,
          [CardSectionValueEnum.MERCHANT_DATA]: sectionFieldList,
        },
        [PaymentMethodsEnum.TRANSFER]: {
          [CardSectionValueEnum.TRANSACTION_DATA]:
            sectionFieldListWithoutTotalTransactionAndRefund,
          [CardSectionValueEnum.PAYMENT_CREDENTIAL_DATA]: sectionFieldList,
          [CardSectionValueEnum.BILLING_DATA]: sectionFieldList,
          [CardSectionValueEnum.AUTHORIZATION_DATA]: sectionFieldList,
          [CardSectionValueEnum.MERCHANT_DATA]: sectionFieldList,
        },
      },
      [TransactionTypeEnum.VOID]: {
        [PaymentMethodsEnum.CARD]: {
          [CardSectionValueEnum.TRANSACTION_DATA]:
            sectionFieldListWithTotalTransactionAndRefund,
        },
        [PaymentMethodsEnum.CASH]: {
          [CardSectionValueEnum.TRANSACTION_DATA]:
            sectionFieldListWithTotalTransactionAndRefund,
        },
      },
      [TransactionTypeEnum.VOID_PARTIAL]: {
        [PaymentMethodsEnum.CARD]: {
          [CardSectionValueEnum.TRANSACTION_DATA]:
            sectionFieldListWithTotalTransactionAndRefund,
        },
        [PaymentMethodsEnum.CASH]: {
          [CardSectionValueEnum.TRANSACTION_DATA]:
            sectionFieldListWithTotalTransactionAndRefund,
        },
      },
    },
    [TransactionStatusEnum.DECLINED]: {
      [TransactionTypeEnum.SALE]: {
        [PaymentMethodsEnum.CARD]: {
          [CardSectionValueEnum.TRANSACTION_DATA]:
            sectionFieldListWithoutTotalTransactionAndRefund,
          [CardSectionValueEnum.PAYMENT_CREDENTIAL_DATA]: sectionFieldList,
          [CardSectionValueEnum.BILLING_DATA]: sectionFieldList,
          [CardSectionValueEnum.CARD_DATA]: sectionFieldList,
          [CardSectionValueEnum.AUTHORIZATION_DATA]: sectionFieldList,
          [CardSectionValueEnum.MERCHANT_DATA]: sectionFieldList,
        },
        [PaymentMethodsEnum.CASH]: {
          [CardSectionValueEnum.TRANSACTION_DATA]:
            sectionFieldListWithoutTotalTransactionAndRefund,
          [CardSectionValueEnum.PAYMENT_CREDENTIAL_DATA]: sectionFieldList,
          [CardSectionValueEnum.BILLING_DATA]: sectionFieldList,
          [CardSectionValueEnum.AUTHORIZATION_DATA]: sectionFieldList,
          [CardSectionValueEnum.MERCHANT_DATA]: sectionFieldList,
        },
        [PaymentMethodsEnum.TRANSFER]: {
          [CardSectionValueEnum.TRANSACTION_DATA]:
            sectionFieldListWithoutTotalTransactionAndRefund,
          [CardSectionValueEnum.PAYMENT_CREDENTIAL_DATA]: sectionFieldList,
          [CardSectionValueEnum.BILLING_DATA]: sectionFieldList,
          [CardSectionValueEnum.AUTHORIZATION_DATA]: sectionFieldList,
          [CardSectionValueEnum.MERCHANT_DATA]: sectionFieldList,
        },
      },
      [TransactionTypeEnum.VOID]: {
        [PaymentMethodsEnum.CARD]: {
          [CardSectionValueEnum.TRANSACTION_DATA]:
            sectionFieldListWithTotalTransactionAndRefund,
        },
        [PaymentMethodsEnum.CASH]: {
          [CardSectionValueEnum.TRANSACTION_DATA]:
            sectionFieldListWithTotalTransactionAndRefund,
        },
      },
      [TransactionTypeEnum.REFUND]: {
        [PaymentMethodsEnum.CARD]: {
          [CardSectionValueEnum.TRANSACTION_DATA]:
            sectionFieldListWithTotalTransactionAndRefund,
        },
      },
      [TransactionTypeEnum.REFUND_TOTAL]: {
        [PaymentMethodsEnum.CARD]: {
          [CardSectionValueEnum.TRANSACTION_DATA]:
            sectionFieldListWithTotalTransactionAndRefund,
        },
      },
      [TransactionTypeEnum.REFUND_PARTIAL]: {
        [PaymentMethodsEnum.CARD]: {
          [CardSectionValueEnum.TRANSACTION_DATA]:
            sectionFieldListWithTotalTransactionAndRefund,
        },
      },
      [TransactionTypeEnum.PREAUTHORIZATION]: {
        [PaymentMethodsEnum.CARD]: {
          [CardSectionValueEnum.TRANSACTION_DATA]:
            sectionFieldListWithoutTotalTransactionAndRefund,
          [CardSectionValueEnum.PAYMENT_CREDENTIAL_DATA]: sectionFieldList,
          [CardSectionValueEnum.BILLING_DATA]: sectionFieldList,
          [CardSectionValueEnum.CARD_DATA]: sectionFieldList,
          [CardSectionValueEnum.AUTHORIZATION_DATA]: sectionFieldList,
          [CardSectionValueEnum.MERCHANT_DATA]: sectionFieldList,
        },
      },
      [TransactionTypeEnum.DEFFERRED]: {
        [PaymentMethodsEnum.CARD]: {
          [CardSectionValueEnum.TRANSACTION_DATA]:
            sectionFieldListWithoutTotalTransactionAndRefund,
          [CardSectionValueEnum.PAYMENT_CREDENTIAL_DATA]: sectionFieldList,
          [CardSectionValueEnum.BILLING_DATA]: sectionFieldList,
          [CardSectionValueEnum.CARD_DATA]: sectionFieldList,
          [CardSectionValueEnum.AUTHORIZATION_DATA]: sectionFieldList,
          [CardSectionValueEnum.MERCHANT_DATA]: sectionFieldList,
        },
      },
      [TransactionTypeEnum.REVERSE]: {
        [PaymentMethodsEnum.CARD]: {
          [CardSectionValueEnum.TRANSACTION_DATA]:
            sectionFieldListWithoutTotalTransactionAndRefund,
        },
      },
    },
  };

  return sectionFieldsByType;
};
