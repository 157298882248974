import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    box: {
      border: "1px solid rgb(232, 235, 242)",
      borderRadius: 4,
      padding: "1px 15px",
      marginRight: 20,
    },
    buttonDownload: {
      fontSize: "15px",
    },
  })
);
