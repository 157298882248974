import { ConfigurationIdEnum, Section } from "../enums";
import { CountryCodeEnum } from "../enums/CountryCodeEnum";

export enum SectionItem {
  DEFAULT = "default",
  CONFIG_PROCESSORS = "processor",
  CONFIG_DEFERRED = "deferred",
  CONFIG_BUSINESS_RULE = "businessRule",
}

export const CENTRALIZATION_CONFIGURATIONS: {
  [K in string]: { [k: string]: ConfigurationIdEnum[] };
} = {
  [Section.DEVELOPERS]: {
    [SectionItem.DEFAULT]: [ConfigurationIdEnum.CN014],
  },
  [Section.COMPANY]: {
    [SectionItem.DEFAULT]: [
      ConfigurationIdEnum.CN001,
      ConfigurationIdEnum.CN016,
      ConfigurationIdEnum.CN015,
      ConfigurationIdEnum.CN002,
    ],
  },
  [Section.USERS]: {
    [SectionItem.DEFAULT]: [ConfigurationIdEnum.CN019],
  },
  [Section.FINANCIAL]: {
    [SectionItem.DEFAULT]: [
      ConfigurationIdEnum.CN003,
      ConfigurationIdEnum.CN004,
    ],
    [CountryCodeEnum.ECUADOR]: [
      ConfigurationIdEnum.CN003,
      ConfigurationIdEnum.CN017,
      ConfigurationIdEnum.CN004,
    ],
  },
  [Section.SERVICES]: {
    [SectionItem.DEFAULT]: [ConfigurationIdEnum.CN011],
  },
  [Section.PROCESSING]: {
    [SectionItem.DEFAULT]: [
      ConfigurationIdEnum.CN007,
      ConfigurationIdEnum.CN010,
    ],
    [SectionItem.CONFIG_PROCESSORS]: [ConfigurationIdEnum.CN007],
    [SectionItem.CONFIG_BUSINESS_RULE]: [ConfigurationIdEnum.CN008],
    [SectionItem.CONFIG_DEFERRED]: [ConfigurationIdEnum.CN010],
  },
  [Section.RATES_DISCOUNTS]: {
    [SectionItem.DEFAULT]: [
      ConfigurationIdEnum.CN005,
      ConfigurationIdEnum.CN006,
      ConfigurationIdEnum.CN018,
    ],
  },
  [Section.WALLET]: {
    [SectionItem.DEFAULT]: [],
  },
};

export enum ProcessingItems {
  PROCESSOR = "Procesadores",
  DEFERRED = "Diferidos",
  BUSINESS_RULE = "Reglas de negocio",
}
