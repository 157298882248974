import { Grid, IconButton, Paper, Typography } from "@mui/material";
import React, { FC } from "react";
import { configDataWrapperStyles as styles } from "./ConfigDataWrapper.styles";
import { IconEdit } from "@kushki/connect-ui";
import { CardContent } from "../ConfigDataCard/ConfigDataCard.interfaces";
import { get, isEmpty } from "lodash";
import { IButtonOptions } from "../FooterOptions/FooterOptions.interfaces";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { SecurityLabelsEnum } from "../../shared/enums/SecurityLabelsEnum";

export interface IConfigDataWrapper {
  title: string;
  children: JSX.Element;
  cardContent: CardContent;
  configureButton: IButtonOptions;
  hideEditBtn: boolean;
}

const ConfigDataWrapper: FC<IConfigDataWrapper> = ({
  title,
  children,
  cardContent,
  configureButton,
  hideEditBtn,
}: IConfigDataWrapper) => {
  const isNotEmptyContent: boolean = !isEmpty(get(cardContent, "content", []));

  const isPayoutsTrue: boolean = hideEditBtn && isNotEmptyContent;

  const hideEditButton: boolean = isPayoutsTrue ? true : isNotEmptyContent;

  return (
    <Paper elevation={2} sx={styles.configDataWrapper}>
      <Grid container alignItems="center" sx={styles.gridTitle}>
        <Grid item>
          <Typography color="text.primary" variant="h3">
            {title}
          </Typography>
        </Grid>
        {hideEditButton && (
          <Grid item sx={styles.marginEditBtn}>
            <ComponentSecurityWrapper
              componentId={SecurityLabelsEnum.SERVICE_EDIT_SERVICE}
            >
              <IconButton onClick={configureButton.onAction!}>
                <IconEdit color="primary" fontSize="medium" />
              </IconButton>
            </ComponentSecurityWrapper>
          </Grid>
        )}
      </Grid>
      <br />
      {children}
    </Paper>
  );
};

export default ConfigDataWrapper;
