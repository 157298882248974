import React from "react";
export const SaveIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 4.80664C4.73478 4.80664 4.48043 4.912 4.29289 5.09953C4.10536 5.28707 4 5.54142 4 5.80664V19.8066C4 20.0719 4.10536 20.3262 4.29289 20.5137C4.48043 20.7013 4.73478 20.8066 5 20.8066H19C19.2652 20.8066 19.5196 20.7013 19.7071 20.5137C19.8946 20.3262 20 20.0719 20 19.8066V9.22085L15.5858 4.80664H5ZM2.87868 3.68532C3.44129 3.12271 4.20435 2.80664 5 2.80664H16C16.2652 2.80664 16.5196 2.912 16.7071 3.09953L21.7071 8.09953C21.8946 8.28707 22 8.54142 22 8.80664V19.8066C22 20.6023 21.6839 21.3654 21.1213 21.928C20.5587 22.4906 19.7957 22.8066 19 22.8066H5C4.20435 22.8066 3.44129 22.4906 2.87868 21.928C2.31607 21.3654 2 20.6023 2 19.8066V5.80664C2 5.01099 2.31607 4.24793 2.87868 3.68532Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 13.8066C6 13.2544 6.44772 12.8066 7 12.8066H17C17.5523 12.8066 18 13.2544 18 13.8066V21.8066C18 22.3589 17.5523 22.8066 17 22.8066C16.4477 22.8066 16 22.3589 16 21.8066V14.8066H8V21.8066C8 22.3589 7.55228 22.8066 7 22.8066C6.44772 22.8066 6 22.3589 6 21.8066V13.8066Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 2.80664C7.55228 2.80664 8 3.25436 8 3.80664V7.80664H15C15.5523 7.80664 16 8.25436 16 8.80664C16 9.35893 15.5523 9.80664 15 9.80664H7C6.44772 9.80664 6 9.35893 6 8.80664V3.80664C6 3.25436 6.44772 2.80664 7 2.80664Z"
      fill="white"
    />
  </svg>
);
