import { Divider, Paper, Typography } from "@mui/material";
import React, { FC } from "react";
import { formWrapperStyles as styles } from "./FormWrapper.interfaces";

export interface IFormWrapper {
  children: JSX.Element;
  title: string;
}

const FormWrapper: FC<IFormWrapper> = ({ children, title }: IFormWrapper) => {
  return (
    <Paper elevation={2} sx={styles.formWrapper}>
      <Typography color="text.primary" variant="h3">
        {title}
      </Typography>
      <Divider sx={styles.divider} />
      {children}
    </Paper>
  );
};

export default FormWrapper;
