export enum WalletDetailTitleEnum {
  BALANCE = "Saldo disponible",
  COUNTABLE_BALANCE = "Histórico de recargas",
  UNPROCESSED_BALANCE = "Monto en proceso",
}

export enum WalletDetailTooltipTextEnum {
  BALANCE = "Dinero disponible para dispersión hasta hoy",
  COUNTABLE_BALANCE = "Monto total acumulado de recargas realizadas a tu billetera",
  UNPROCESSED_BALANCE = "Pagos en proceso de dispersión",
}

export enum WalletAlertDownloadModal {
  SUCCESS_MODAL_TITLE = "Estamos generando tu reporte de recargas.",
  SUCCESS_MODAL_DESCRIPTION = "En breve recibirás el link de descarga en tu correo electrónico: ",
  ERROR_DATE_RANGE_DESCRIPTION = "El rango de fechas que deseas descargar excede los seis meses con base a la fecha actual. Por favor intenta con un rango de fechas más actual.",
  ERROR_DATE_RANGE_TITLE = "Solicitud de reporte fuera de rango.",
  MODAL_BUTTON = "Aceptar",
}

export enum WalletSnackBarMessageEnum {
  ERROR_DOWNLOAD = "No se pudo realizar la descarga. Inténtalo nuevamente.",
  ERROR_SEARCH_WALLET_TRX = "Se presentó un error al filtrar. Inténtalo nuevamente.",
  SUCCESS_DOWNLOAD_FILE = "La descarga del archivo se ha completado con éxito",
}
