import { IMerchantsState, MerchantsFetch } from "./reducer";
import { ActionTypes } from "./actionTypes";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import axios from "../shared/axios-util";
import { environment } from "../environments/environment";
import { AxiosResponse } from "axios";
import { Merchant } from "../../types/merchant";

export interface MerchantActions {
  type: string;
  merchants?: MerchantsFetch;
  isLoading?: boolean;
}
export const countryCurrency = {
  Ecuador: "USD",
  Peru: "PEN",
  Colombia: "COP",
  Chile: "CLP",
  Mexico: "MXN",
  EEUU: "USD",
  CostaRica: "USD",
  ElSalvador: "USD",
  Guatemala: "USD",
  Honduras: "USD",
  Nicaragua: "USD",
  Panama: "USD",
  Brazil: "BRL",
};

export const setMerchants = (payload: MerchantsFetch): MerchantActions => {
  return {
    type: ActionTypes.SET_MERCHANTS,
    merchants: payload,
  };
};

export const setLoading = (payload: boolean): MerchantActions => {
  return {
    type: ActionTypes.SET_LOADING,
    isLoading: payload,
  };
};

export const getMerchantsRequest = (payload: {
  limit: number;
  offset: number;
  text: string;
  merchant_id?: string;
}): ThunkAction<void, IMerchantsState, undefined, MerchantActions> => {
  return (
    dispatch: ThunkDispatch<IMerchantsState, any, MerchantActions>
  ): void => {
    if (payload.text === "") delete payload.text;

    const method: string = "analytics/v1/admin/merchant/list";
    const balanceMethod: string = "wallet/v1/balance";
    const body: {
      limit: number;
      offset: number;
      text?: string;
      merchant_id?: string;
    } = {
      ...payload,
    };
    axios
      .post<MerchantsFetch>(`${environment.kushkiUrl}/${method}`, body)
      .then(async (axios_response: AxiosResponse<MerchantsFetch>) => {
        const response: MerchantsFetch = axios_response.data;
        if (response.total.value === 0)
          dispatch(setMerchants({ data: [], total: { value: 0 } }));
        const merchants: (object | null)[] = await Promise.all(
          response.data.map(async (val: { _source: Merchant }, idx) => {
            const validPublicMerchantId = !!val._source.publicMerchantId.match(
              /\d+/
            );

            if (validPublicMerchantId) {
              const bl_axios_response = await axios.get<{ balance: number }>(
                `${environment.kushkiUrl}/${balanceMethod}/${val._source.publicMerchantId}`
              );

              const balResp: { balance: number } = bl_axios_response.data;

              return {
                ...val,
                _source: {
                  ...val._source,
                  balance: `${balResp.balance} ${
                    countryCurrency[val._source.country]
                  }`,
                },
              };
            }
          })
        );

        const merchantsFiltered = merchants.filter((el) => !!el);

        dispatch(
          setMerchants({
            ...response,
            data: merchantsFiltered as { _source: Merchant }[],
          })
        );
      });
  };
};

export const updateMerchantState = (payload: {
  merchants: MerchantsFetch;
  merchantId: string;
  state: "inactive" | "active";
}): ThunkAction<void, IMerchantsState, undefined, MerchantActions> => {
  return (
    dispatch: ThunkDispatch<IMerchantsState, any, MerchantActions>
  ): void => {
    dispatch(setLoading(true));
    axios
      .patch(
        `${environment.kushkiUrl}/merchant/v1/admin/merchant/${payload.merchantId}/status`,
        {
          state: payload.state,
        }
      )
      .then(() => {
        const merchantList = [...payload.merchants.data];
        merchantList.forEach((item) => {
          if (item._source.publicMerchantId === payload.merchantId) {
            item._source.isActive = !item._source.isActive;
          }
        });
        dispatch(
          setMerchants({
            ...payload.merchants,
            data: merchantList,
          })
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };
};
