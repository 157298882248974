import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import { transactionsEmptyStateStyles } from "./TransactionsEmptyState.styles";
import InboxIcon from "../../assets/icons/InboxIcon/InboxIcon";
import { useTransactionsEmptyState } from "./state/useTransactionsEmptyState";

const TransactionsEmptyState: FC = () => {
  const { subtitle, title } = useTransactionsEmptyState();

  return (
    <Box sx={transactionsEmptyStateStyles.container}>
      <Box>
        <Typography sx={transactionsEmptyStateStyles.title}>{title}</Typography>
        <Typography sx={transactionsEmptyStateStyles.subtitle}>
          {subtitle}
        </Typography>
      </Box>
      <Box>
        <InboxIcon sx={transactionsEmptyStateStyles.inboxIcon} />
      </Box>
    </Box>
  );
};

export default TransactionsEmptyState;
