import developersImage from "../../../assets/images/developersImage.svg";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { AssistanceIndexProps } from "../AssistanceIndex";
import { IAssistance } from "../../../components/CardAssistance/CardAssistance";
import React, { useEffect, useState } from "react";
import { ConnectionRequest } from "../../../../types/connection_request";
import { Payload } from "../../../../types/payload";
import { defaultTo } from "lodash";
import { auth } from "../../../shared/auth";

export interface IAssistanceState {
  properties: {
    assistanceProps: IAssistance;
  };
  handler: {
    close: (_event?: React.SyntheticEvent, reason?: string) => void;
    verifyAccessCode: (accessCode: string) => void;
    connectSession: (body: ConnectionRequest) => void;
  };
}

export const useAssistanceIndexState = (
  props: AssistanceIndexProps
): IAssistanceState => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingConnection, setLoadingConnection] = useState<boolean>(false);
  const close = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    props.setNotification({ show: false, message: "", type: "success" });
  };
  const verifyAccessCode = (accessCode: string) => {
    setLoading(true);
    props.verifyAccessCode(accessCode);
  };
  const connectSession = (body: ConnectionRequest) => {
    setLoadingConnection(true);
    props.connectSession(body);
  };
  const assistanceProps: IAssistance = {
    loading,
    title: "Asistencia Remota",
    image: developersImage,
    isMedium: isMedium,
    merchantAssistance: {
      isVerify: false,
      merchantName: "",
      username: "",
      timeConnect: "",
      status: "",
      created: 0,
      accessCode: "",
      merchantId: "",
      message: "",
      rol: [],
      email: "",
    },
    loadingConnection,
  };

  useEffect(() => {
    setLoading(false);
  }, [props.state.merchantAccessCode]);
  useEffect(() => {
    setLoading(false);
  }, [props.state.notification]);
  useEffect(() => {
    setLoadingConnection(false);
    if (!props.state.isConnectionSuccessful) return;
    const authPayload: Payload = JSON.parse(
      defaultTo(localStorage.getItem("payload"), "")
    );
    const payload: Payload = {
      "cognito:groups": props.state.merchantAccessCode!.rol,
      preferred_username: props.state.merchantAccessCode!.merchantId,
      "cognito:username": props.state.merchantAccessCode!.username,
      timeConnect: props.state.merchantAccessCode!.timeConnect!,
      status: "ACTIVE",
      accessCode: props.state.merchantAccessCode!.accessCode,
      isVerify: props.state.merchantAccessCode!.isVerify,
      merchantName: props.state.merchantAccessCode!.merchantName,
      created: props.state.merchantAccessCode!.created,
      message: "conexión exitosa",
      exp: authPayload.exp,
      email: props.state.merchantAccessCode!.email,
    };
    auth.setSessionSupport({
      accessCode: props.state.merchantAccessCode!.accessCode,
      userSupport: defaultTo(localStorage.getItem("username"), ""),
      expired: `${auth
        .getExpiredDateSession(props.state.merchantAccessCode!.timeConnect!)
        .getTime()}`,
      supportSessionTimeConnectStart: props.state
        .merchantAccessCode!.created.toString()
        .substring(0, 10),
      merchantPayload: JSON.stringify(payload),
    });
    localStorage.removeItem("merchantId");
    localStorage.removeItem("roles");
    localStorage.setItem("authPayload", JSON.stringify(authPayload));
    localStorage.setItem("payload", JSON.stringify(payload));
    localStorage.setItem(
      "supportSessionTimeConnectStart",
      props.state.merchantAccessCode!.created.toString().substring(0, 10)
    );
    window.location.href = "/";
  }, [props.state.isConnectionSuccessful]);
  return {
    properties: {
      assistanceProps: assistanceProps,
    },
    handler: {
      close,
      verifyAccessCode,
      connectSession,
    },
  };
};
