import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const searchInputStyles = createNamedStyles({
  input: {
    width: "100%",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "16px !important",
    },
  },
  form: {
    width: "400px",
  },
});
