import React from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { DEFAULT_ITEM_BY_TAG } from "../../../../shared/infrastructure/enums/ItemByTagEnum";
import {
  isOperatorEqualOrDifferent,
  retrieveFormError,
} from "../../../../utils/utilsFile";
import { GenericAddButton } from "../GenericAddButton/GenericAddButton";
import {
  DEFAULT_BUTTON_TITTLE,
  Labels,
} from "../../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { TextFieldCardIdSectionList } from "./components/TextFieldCardIdSectionList";
import { useTextFieldCardIdState } from "./state/useTextFieldCardIdState";
import { ITextFieldCardIdProps } from "../../../../shared/infrastructure/interfaces/ITextFieldCardId";

export const TextFieldCardId = (props: ITextFieldCardIdProps) => {
  const {
    register,
    errors,
    handleOnBlurCardId,
    textCardIdList,
    setTextCardIdList,
    handleAddTextCardId,
    handleEditTextCardId,
    isValidFiledTextCardId,
    handleOnChangeTextCardId,
  } = useTextFieldCardIdState(props);

  const errorMsg: string =
    props.isFinalCond || props.isEditCondition
      ? DEFAULT_ITEM_BY_TAG[props.tag].errorMessage
      : Labels.NOT_APPLICABLE;

  return (
    <>
      {textCardIdList &&
        textCardIdList.map((fieldValue: string, index: number) => (
          <React.Fragment
            key={`textFieldCardIdSectionList-${fieldValue}-${index}`}
          >
            <TextFieldCardIdSectionList
              tag={props.tag}
              value={fieldValue.toString()}
              handleDelete={() =>
                setTextCardIdList(
                  textCardIdList.filter((item) => item != fieldValue.toString())
                )
              }
              handleEdit={(value: string) => handleEditTextCardId(value, index)}
              isDisable={props.isDisable}
              keyIndex={index}
              errors={errors}
              register={register}
              errMsg={errorMsg}
            />
          </React.Fragment>
        ))}
      {!props.isDisable &&
        !(
          textCardIdList.length >= 1 &&
          isOperatorEqualOrDifferent(props.operator)
        ) && (
          <Grid item xs={4}>
            <Typography variant={"h6"} sx={{ my: 1 }}>
              {DEFAULT_ITEM_BY_TAG[props.tag].label}
            </Typography>
            <TextField
              name={DEFAULT_ITEM_BY_TAG[props.tag].name}
              inputRef={register({
                pattern: {
                  message: errorMsg,
                  value: DEFAULT_ITEM_BY_TAG[props.tag].pattern,
                },
              })}
              onChange={handleOnChangeTextCardId}
              label={"Kushki ID"}
              placeholder={DEFAULT_ITEM_BY_TAG[props.tag].placeholder}
              helperText={
                retrieveFormError(
                  errors,
                  DEFAULT_ITEM_BY_TAG[props.tag].name
                )[1]
              }
              variant="outlined"
              fullWidth
              onBlur={handleOnBlurCardId}
              disabled={props.isDisable}
              error={
                retrieveFormError(
                  errors,
                  DEFAULT_ITEM_BY_TAG[props.tag].name
                )[0]
              }
            />
          </Grid>
        )}
      {isValidFiledTextCardId && !props.isDisable && (
        <GenericAddButton
          handleOnClick={handleAddTextCardId}
          buttonTitle={DEFAULT_BUTTON_TITTLE[props.tag]}
        />
      )}
    </>
  );
};
