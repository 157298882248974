import { format } from "date-fns";
import { parseDateTime } from "../../../shared/utils/date_utils";
import { IDateTime } from "../../../store/interfaces/RefundsState.interfaces";
import { GetRefundTransactionListRequest } from "../../../../types/get-refund-transaction-list-request";
import { RefundStatusEnum } from "../../../shared/enums/RefundStatusEnum";

export const mapDateTimeToRequest = (dateTimeStr: string) => {
  const date = parseDateTime(dateTimeStr);

  return format(date, "yyyy-MM-dd'T'HH:mm");
};

export const buildBodyRequest = (values: {
  dateTimeFilters: IDateTime;
  sort: "asc" | "desc";
  transactionReference?: string;
}): GetRefundTransactionListRequest => {
  const { dateTimeFilters, sort, transactionReference } = values;

  return {
    from: mapDateTimeToRequest(dateTimeFilters.from),
    limit: 10,
    sort,
    status: [RefundStatusEnum.PENDING],
    timeZone: dateTimeFilters.timezone,
    to: mapDateTimeToRequest(dateTimeFilters.to),
    transactionReference,
  };
};
