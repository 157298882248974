import { CustomStyles } from "../../shared/infrastructure/interfaces/CustomStyles";

export const walletSectionStyles: CustomStyles = {
  container: {
    display: "flex",
    gap: "1rem",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: 1.5,
    maxWidth: {
      md: "100%",
      lg: "70%",
      xl: "50%",
    },
  },
  containerGrid: {
    justifyContent: "flex-start",
    width: "100%",
    alignItems: "center",
  },
  itemContainer: {
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
  },
};
