import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { Color } from "@material-ui/lab/Alert";
import { useSnackBarAlertState } from "./useSnackBarAlertState";

export interface SnackbarAlertProps {
  msg: string;
  open: boolean;
  type: Color | "dark" | "gray";
  handlerClose: (_event?: React.SyntheticEvent, reason?: string) => void;
}

export const SnackbarAlert: React.FC<SnackbarAlertProps> = (
  props: SnackbarAlertProps
) => {
  const { type } = props;
  const { open } = useSnackBarAlertState(props);

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={4000}
        onClose={props.handlerClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={props.handlerClose}
          severity={type as Color}
          style={{
            backgroundColor: `${
              type == "dark" ? "#293036" : type == "gray" ? "#677684" : ""
            }`,
            color: "#FFF",
          }}
        >
          {props.msg}
        </MuiAlert>
      </Snackbar>
    </React.Fragment>
  );
};
