import { makeStyles } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/core";

export const useModalObservationsStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: "#ccc",
      zIndex: 1,
      color: "#fff",
      width: 32,
      height: 32,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
    },
    paper: {
      boxShadow: "none !important",
      borderRadius: "5px !important",
      backgroundColor: "#F7FAFC !important",
      marginBottom: "20px !important",
      marginTop: "30px !important",
    },
    active: {
      backgroundColor: "#0DC298",
    },
    completed: {
      backgroundColor: "#0DC298",
    },
    linearProgress: {
      marginTop: 3,
    },
    grid: {
      paddingTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
    },
    margin: {
      marginTop: 10,
    },
  })
);
