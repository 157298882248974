export enum TransacationStatus {
  ENABLE = "enabled",
  DISABLE = "disable",
  DRAFT = "draft",
}

export enum DeferredStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
  DISABLED = "disabled",
  ENABLED = "enabled",
}

export enum NotificationStatus {
  FAILED = "FAILED",
  SUCCESSFUL = "200",
}

export enum StatusMerchantEnum {
  ACTIVE = "active",
  INACTIVE = "inactive",
  PENDING = "pending",
}

export enum ProcessorStatus {
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
}

export enum ChipProcessorStatusEnum {
  ENABLED = "HABILITADO",
  DISABLED = "DESHABILITADO",
}
