import React, { ChangeEvent, useEffect, useState } from "react";
import { IUseColumnConfiguration } from "./useColumnConfiguration.interfaces";
import { useAppDispatch } from "../../../store/hooks/hooks";
import { setActiveColumns } from "../../../store/actions/app/app";
import { TransactionsTableConstants } from "../../../shared/infrastructure/constants/TransactionsTableConstants";

export interface IColumnConfigurationState {
  starterColumns: string[];
}

const useColumnConfiguration = (
  props: IColumnConfigurationState
): IUseColumnConfiguration => {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [columnConfiguration, setColumnConfiguration] = useState<string[]>(
    props.starterColumns
  );

  const [columns, setColumns] = useState<string[]>(props.starterColumns);

  const open = Boolean(anchorEl);

  const handleOpen = (currentTarget: HTMLButtonElement | null) => {
    setAnchorEl(currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setColumnConfiguration(columns);
  };
  const handleRestoreColumns = () =>
    setColumnConfiguration(props.starterColumns);

  const handleApplyColumns = () => {
    setAnchorEl(null);
    setColumns(columnConfiguration);
  };

  const handleChangeColumnCheck = (event: ChangeEvent<HTMLInputElement>) => {
    const column: string = event.target.name;
    let auxColumnsSelected: string[] = [...columnConfiguration];

    if (event.target.checked) auxColumnsSelected.push(column);
    if (!event.target.checked)
      auxColumnsSelected = auxColumnsSelected.filter(
        (col: string) => col !== column
      );
    setColumnConfiguration(auxColumnsSelected);
  };

  useEffect(() => {
    dispatch(
      setActiveColumns(
        TransactionsTableConstants.sortKeysSelectedColumns(columns)
      )
    );
  }, [columns]);

  return {
    anchorEl,
    columnConfiguration,
    columns,
    handler: {
      handleApplyColumns,
      handleChangeColumnCheck,
      handleClose,
      handleOpen,
      handleRestoreColumns,
    },
    open,
  };
};

export { useColumnConfiguration };
