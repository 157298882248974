import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import kushkiLogo from "../assets/logo.svg";
import Chip from "@material-ui/core/Chip";
import { Theme, useTheme } from "@material-ui/core/styles";
import { Avatar, Box, Divider, Typography } from "@material-ui/core";
import { navigateToUrl } from "single-spa";
import ButtonBase from "@material-ui/core/ButtonBase";
import BrasilFlag from "../assets/flags/brazil.png";
import ChileFlag from "../assets/flags/chile.png";
import ColombiaFlag from "../assets/flags/colombia.png";
import EcuadorFlag from "../assets/flags/ecuador.png";
import MexicoFlag from "../assets/flags/mexico.png";
import PeruFlag from "../assets/flags/peru.png";
import { get, isNil } from "lodash";
import { IMerchantForm } from "../infrastructure/interfaces/IMerchantForm";
import SupportSessionTimer from "./SupportSessionTimer";
import { IRoleInfo } from "../infrastructure/interfaces/IRoleInfo";
import kushkiLogoDark from "../assets/kushki_logo_dark.svg";

const styles = (theme: Theme, props: TopBarMobileProps) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: props.roleInfo?.supportSessionActive
      ? theme.palette.primary.main
      : "white",
  },
  topBar: {
    background: "linear-gradient(268.59deg, #0093A2 -1.58%, #00E6B2 100.09%)",
    width: "100%",
    height: 5,
  },
  imgKushki: {
    width: 80,
    height: "auto",
  },
  chipModeTest: {
    marginLeft: 10,
    backgroundColor: props.roleInfo?.supportSessionActive
      ? "#F7FAFC"
      : theme.palette.primary.main,
    color: props.roleInfo?.supportSessionActive
      ? theme.palette.info.dark
      : "white",
    borderRadius: 4,
    fontSize: 10,
  },
  divider: {
    backgroundColor: props.roleInfo?.supportSessionActive
      ? "#F7FAFC"
      : theme.palette.primary.main,
    marginLeft: 10,
    marginRight: 10,
  },
  avatarCountry: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: 10,
  },
  titleCountry: {
    width: props.isProduction ? "calc(100vw - 150px)" : "calc(100vw - 250px)",
    whiteSpace: "nowrap" as "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    color: props.roleInfo?.supportSessionActive
      ? "white"
      : theme.palette.primary.main,
  },
});

export interface TopBarMobileProps {
  setActiveRoute: (path: string) => void;
  merchantInfo: IMerchantForm | null | undefined;
  isProduction: boolean;
  roleInfo?: IRoleInfo;
}

const TopBarMobile = (props: TopBarMobileProps) => {
  const theme = useTheme();
  const inlineStyles = styles(theme, props);
  const countryFlags: { [key: string]: string } = {
    Brasil: BrasilFlag,
    Chile: ChileFlag,
    Colombia: ColombiaFlag,
    Ecuador: EcuadorFlag,
    Mexico: MexicoFlag,
    Peru: PeruFlag,
  };

  const handleClick = (url: string) => {
    handleSelectedMenu(url);
    return navigateToUrl(url);
  };

  const handleSelectedMenu = (basePath: string) => {
    props.setActiveRoute(basePath);
  };

  return (
    <div>
      <AppBar
        position="fixed"
        style={inlineStyles.appBar}
        color="inherit"
        elevation={0}
      >
        <div style={inlineStyles.topBar} />
        <Toolbar>
          <Box display="flex" flexGrow={1} alignItems="center">
            <ButtonBase>
              <img
                style={inlineStyles.imgKushki}
                src={
                  props.roleInfo?.supportSessionActive
                    ? kushkiLogoDark
                    : kushkiLogo
                }
                alt="kushkiLogo"
              />
            </ButtonBase>
            {!props.isProduction && (
              <Chip
                style={inlineStyles.chipModeTest}
                size="small"
                label="Modo de Prueba"
              />
            )}
            <Divider
              orientation="vertical"
              flexItem
              style={inlineStyles.divider}
              hidden={isNil(props.merchantInfo)}
            />
            {props.roleInfo?.supportSessionActive ? (
              <SupportSessionTimer />
            ) : (
              <>
                <div hidden={isNil(props.merchantInfo)}>
                  <Avatar
                    src={
                      countryFlags[`${get(props, "merchantInfo.country", "")}`]
                    }
                    style={inlineStyles.avatarCountry}
                  />
                </div>
                <Typography
                  variant="subtitle1"
                  color={"primary"}
                  hidden={isNil(props.merchantInfo)}
                  style={inlineStyles.titleCountry}
                >
                  {get(props, "merchantInfo.name", "")}
                </Typography>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default TopBarMobile;
