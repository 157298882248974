import React from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { FileUploadContainerStyles as styles } from "../FileUploadContainer.styles";
import { FileUploadProps } from "../FileUploadContainer.interfaces";
import FileTextIcon from "../../../assets/icons/FileTextIcon";

const FileUpload: React.FC<FileUploadProps> = ({
  handleDrag,
  handleDrop,
  handleChange,
  dragActive,
}: FileUploadProps) => {
  return (
    <Box onDragEnter={handleDrag} sx={styles.fileUploadContainer}>
      <Box sx={styles.uploadContainer}>
        <Box sx={styles.dragUpload}>
          <FileTextIcon />
          <Typography sx={styles.textUpload}>
            Arrastra el documento hasta aquí
          </Typography>
        </Box>
        <Divider sx={styles.divider}>
          <Typography sx={styles.textUpload}>o</Typography>
        </Divider>
        <Button variant="outlined" component="label" color={"secondary"}>
          Selecciona un archivo
          <input hidden accept=".xlsx" type="file" onChange={handleChange} />
        </Button>
        <Typography sx={styles.validFormat}>Formatos válidos: .xlsx</Typography>
      </Box>
      {dragActive && (
        <Box
          component="div"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          sx={styles.dragContainer}
        ></Box>
      )}
    </Box>
  );
};

export default FileUpload;
