/* istanbul ignore file */

import {
  Countries,
  CurrencyEnum,
  IOptionSelection,
} from "./RuleRequestConstants";
import { BANKS_ECUADOR } from "./CatalogEC";
import { BANKS_COLOMBIA } from "./CatalogCO";
import { BANKS_PERU } from "./CatalogPE";
import { BANKS_CHILE } from "./CatalogCL";
import { BANKS_MEXICO } from "./CatalogMX";
import { BANKS_BRAZIL } from "./CatalogBR";

export const BUSINESS_RULES_ACTIONS: IOptionSelection[] = [
  { name: "Enviar a", value: "route" },
  /*{ name: "Alertar", value: "warning" },*/
];

export const BUSINESS_RULES_RULES: IOptionSelection[] = [
  { name: "Banco", value: "transactionBank" },
  { name: "Marca", value: "transactionBrand" },
  { name: "Diferido", value: "transactionIsDeferred" },
  { name: "Procesador", value: "transactionProcessor" },
  { name: "País", value: "transactionCountry" },
  { name: "Monto", value: "transactionTotalAmount" },
  { name: "Ip", value: "transactionIP" },
  { name: "Tipo de transacción", value: "transactionType" },
  { name: "Credencial", value: "transactionCredential" },
  { name: "Bin", value: "transactionBIN" },
  { name: "Tipo de tarjeta", value: "transactionIsCreditCard" },
  { name: "Tarjeta enmascarada", value: "transactionMaskedCreditCard" },
];

export const BUSINESS_RULES_WARNING_RULES: IOptionSelection[] = [
  ...BUSINESS_RULES_RULES,
];

export const BUSINESS_RULES_ROUTING: IOptionSelection[] = [
  ...BUSINESS_RULES_RULES,
  { name: "Moneda", value: "transactionCurrency" },
];

export const STRICT_CONDITIONS_BASIC: IOptionSelection[] = [
  { value: "=", name: "Igual" },
  { value: "!=", name: "Diferente" },
];
export const STRICT_CONDITIONS_CREDENTIALS: IOptionSelection[] = [
  { value: "=", name: "Igual" },
];

export const STRICT_CONDITIONS_CONTAINS: IOptionSelection[] = [
  { value: "contain", name: "Contiene" },
  { value: "!contain", name: "No Contiene" },
];

export const STRICT_CONDITIONS: IOptionSelection[] = [
  ...STRICT_CONDITIONS_BASIC,
  ...STRICT_CONDITIONS_CONTAINS,
];

export const STRICT_CONDITIONS_EXTENDED: IOptionSelection[] = [
  ...STRICT_CONDITIONS,
  { value: ">", name: "Mayor que" },
  { value: "<", name: "Menor que" },
];

export const PARTNER_VALIDATORS: IOptionSelection[] = [
  { value: "transunion", name: "transunion" },
  { value: "experian", name: "experian" },
];

export const TRANSACTION_TYPES: IOptionSelection[] = [
  { value: "token", name: "Token" },
  { value: "charge", name: "Cobro" },
  { value: "deferred", name: "Diferido" },
  { value: "preauthorization", name: "Preautorización" },
  { value: "capture", name: "Captura" },
  { value: "subscriptionToken", name: "Token de subscripción" },
  { value: "subscription", name: "Crear subscripción" },
  { value: "subscriptionCharge", name: "Cobro de subscripción" },
  {
    value: "subscriptionChargeValidation",
    name: "Cobro de validación de subscripción",
  },
  {
    value: "subscriptionPreauthorization",
    name: "Preautorización de subscripción",
  },
  { value: "subscriptionCapture", name: "Captura de subscripción" },
];

export const TRANSACTION_DEFERRED_CONDITION_VALUES: IOptionSelection[] = [
  { value: "true", name: "Si" },
  { value: "false", name: "No" },
];

export const BUSINESS_RULES_MAP_TARGETS: string[] = [
  "transactionBank",
  "transactionBrand",
  "transactionIsDeferred",
  "transactionProcessor",
  "transactionCountry",
  "transactionTotalAmount",
  "transactionCity",
  "transactionIP",
  "transactionType",
  "transactionCredential",
  "transactionBIN",
  "transactionIsCreditCard",
  "transactionMaskedCreditCard",
  "transactionCurrency",
];

export const BANKS_COUNTRIES_MAP: Record<string, IOptionSelection[]> = {
  [Countries.ECUADOR]: BANKS_ECUADOR,
  [Countries.COLOMBIA]: BANKS_COLOMBIA,
  [Countries.PERU]: BANKS_PERU,
  [Countries.CHILE]: BANKS_CHILE,
  [Countries.MEXICO]: BANKS_MEXICO,
  [Countries.BRAZIL]: BANKS_BRAZIL,
};

export const COUNTRIES: IOptionSelection[] = [
  { name: Countries.ECUADOR, value: Countries.ECUADOR },
  { name: Countries.COLOMBIA, value: Countries.COLOMBIA },
  { name: Countries.PERU, value: Countries.PERU },
  { name: Countries.CHILE, value: Countries.CHILE },
  { name: Countries.MEXICO, value: Countries.MEXICO },
  { name: Countries.BRAZIL, value: Countries.BRAZIL },
];

export const CURRENCIES: IOptionSelection[] = [
  { name: CurrencyEnum.USD, value: CurrencyEnum.USD },
  { name: CurrencyEnum.COP, value: CurrencyEnum.COP },
  { name: CurrencyEnum.PEN, value: CurrencyEnum.PEN },
  { name: CurrencyEnum.CLP, value: CurrencyEnum.CLP },
  { name: CurrencyEnum.UF, value: CurrencyEnum.UF },
  { name: CurrencyEnum.MXN, value: CurrencyEnum.MXN },
  { name: CurrencyEnum.BRL, value: CurrencyEnum.BRL },
];

export const CREDIT_CARD_OPTIONS: IOptionSelection[] = [
  { name: "Crédito", value: "credit" },
  { name: "Débito", value: "debit" },
];

export const PAYMENT_BRANDS_OPTIONS: IOptionSelection[] = [
  { name: "Visa", value: "Visa" },
  { name: "Mastercard", value: "Mastercard" },
  { name: "Amex", value: "Amex" },
  { name: "American Express", value: "american express" },
  { name: "Diners", value: "Diners" },
  { name: "Diners Club", value: "diners club" },
  { name: "Discover", value: "Discover" },
  { name: "Redcompra", value: "Redcompra" },
  { name: "Alia", value: "Alia" },
  { name: "Credencial", value: "Credencial" },
  { name: "Maestro", value: "maestro" },
  { name: "Propia", value: "propia" },
  { name: "Cmrfalabella", value: "Cmrfalabella" },
  { name: "JCB", value: "JCB" },
  { name: "Serfinanza", value: "Serfinanza" },
  { name: "Unionpay", value: "unionpay" },
];

export const MAP_TYPE_ISCREDIT: Record<string, string> = {
  ["debit"]: "false",
  ["credit"]: "true",
  ["true"]: "true",
  ["false"]: "false",
  ["Crédito"]: "true",
  ["Débito"]: "false",
};

export const MAP_TYPE_CARD: Record<string, string> = {
  ["=|debit"]: "=|false",
  ["=|credit"]: "=|true",
  ["=|true"]: "=|true",
  ["=|false"]: "=|false",
  ["=|Crédito"]: "=|true",
  ["=|Débito"]: "=|false",
};
