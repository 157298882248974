import React from "react";
import { TextField } from "@material-ui/core";
import { ControllerRenderProps } from "react-hook-form";
import { InputProps as StandardInputProps } from "@material-ui/core/Input/Input";
import { get } from "lodash";

export interface TextFieldComponentProps {
  disabled?: boolean;
  type?: string;
  multiline?: boolean;
  rowsMax?: number;
  inputProps?: StandardInputProps["inputProps"];
  properties: ControllerRenderProps;
  helperText: string | false | undefined;
  error: boolean;
  required: boolean;
  label: string;
  id: string;
}

export const TextFieldComponent: React.FC<TextFieldComponentProps> = (
  props: TextFieldComponentProps
) => {
  const { properties, helperText, error, label, id, required } = props;
  return (
    <TextField
      {...properties}
      id={id}
      label={label}
      type={props.type}
      margin="normal"
      multiline={props.multiline}
      rowsMax={get(props, "rowsMax", 1)}
      variant="outlined"
      disabled={props.disabled}
      error={error}
      required={required}
      fullWidth
      inputProps={props.inputProps}
      helperText={helperText}
    />
  );
};

export default TextFieldComponent;
