import { FormHelperText, Grid, Typography } from "@mui/material";
import { SubTitleSection } from "../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { Controller, useFormContext } from "react-hook-form";
import { ChoiceButtonGroup } from "@kushki/connect-ui";
import { retrieveFormError } from "../../../utils/utilsFile";
import React from "react";
import { ErrorsEnum } from "../../../shared/infrastructure/enums/ErrorEnum";
import { generalStyles } from "../../../shared/styles/generalStyles.styles";
import { usePaymentTypeComponent } from "./state/usePaymentTypeComponent";

export const PaymentTypeComponent = () => {
  const { items, getButtonSelect, isUpdatedRule, isLoadingProcessor } =
    usePaymentTypeComponent();
  const { watch, errors, control } = useFormContext();
  const classes = generalStyles();

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Typography className={classes.subtitleTypography}>
            {SubTitleSection.RULES_CREATE_PAY_SUBTITLE}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"service"}
            control={control}
            rules={{
              required: { message: ErrorsEnum.REQUIRED_FIELD, value: true },
            }}
            defaultValue={watch("service")}
            render={() => (
              <ChoiceButtonGroup
                items={items}
                onItemsSelect={getButtonSelect}
                isDisabled={isUpdatedRule}
                isLoading={isLoadingProcessor}
              />
            )}
          />
          <FormHelperText error={retrieveFormError(errors, "service")[0]}>
            {retrieveFormError(errors, "service")[1]}
          </FormHelperText>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
