import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { routes } from "./shared/infrastructure/constants/routes";
import { ContainerIndex } from "./containers/ContainerIndex";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./auth-config";

const msalInstance = new PublicClientApplication({ ...msalConfig });

const Root = () => {
  return (
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <ThemeProvider theme={theme}>
          <BrowserRouter basename={routes.BASENAME}>
            <ContainerIndex />
          </BrowserRouter>
        </ThemeProvider>
      </MsalProvider>
    </React.StrictMode>
  );
};

export default Root;
