import { BoxProps, Button, Grid } from "@mui/material";
import React, { FC } from "react";
import FilterIcon from "../../assets/icons/FilterIcon";
import DateTimeButton from "../common/DateTimeButton/DateTimeButton";
import DateTimeRangePicker from "../common/DateTimeRangePicker/DateTimeRangePicker";
import FilterBox from "../common/FilterBox/FilterBox";
import LayoutActionBar from "../common/LayoutActionBar/LayoutActionBar";
import { refundsActionBarStyles as styles } from "../RefundsActionBar/RefundsActionBar.styles";
import useHistoricalRefundsActionBarState from "./state/useHistoricalRefundsActionBarState";
import DownloadButton from "../common/DownloadButton/DownloadButton";
import { ARRAY_OPTIONS_DOWNLOAD } from "../../shared/constants/options_download";
import useDownloadHistoricRefunds from "../../hooks/useDownloadTransactions/useDownloadHistoricRefunds";
import { useHistoricalTable } from "../HistoricalList/hooks/useHistoricalTable";

export const onClickButtonFilterBox =
  (setAnchor: (element: Element) => void) =>
  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    setAnchor(e.currentTarget as Element);

const HistoricalRefundsActionBar: FC<BoxProps> = (props: BoxProps) => {
  const {
    currentFilters,
    dateTimeFrom,
    dateTimeTo,
    handleSetGeneralFilter,
    setFromTo,
  } = useHistoricalRefundsActionBarState();

  const {
    actions: { handleDownloadHistoricRefunds },
  } = useDownloadHistoricRefunds();
  const { data } = useHistoricalTable();

  const timeStyle = { ...styles.baseBtn, ...styles.btnTime };
  const filterStyle = { ...styles.baseBtn, ...styles.btnFilter };
  const gridTimeStyle = { ...styles.gridTime };

  return (
    <LayoutActionBar sx={styles.componentContainer} {...props}>
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <FilterBox
            filters={currentFilters}
            renderComponent={({ setAnchor }) => (
              <Button
                onClick={onClickButtonFilterBox(setAnchor)}
                startIcon={<FilterIcon />}
                sx={filterStyle}
              >
                Filtrar
              </Button>
            )}
            onChange={handleSetGeneralFilter}
          />
        </Grid>
        <Grid item sx={gridTimeStyle}>
          <DateTimeRangePicker
            disableFuture
            from={dateTimeFrom}
            to={dateTimeTo}
            onChange={setFromTo}
            renderComponent={(props) => (
              <DateTimeButton {...props} sx={timeStyle} />
            )}
          />
        </Grid>
      </Grid>
      <DownloadButton
        options={ARRAY_OPTIONS_DOWNLOAD}
        handleClick={handleDownloadHistoricRefunds}
        disabled={data.length === 0}
      />
    </LayoutActionBar>
  );
};

export default HistoricalRefundsActionBar;
