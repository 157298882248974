import React, { FC } from "react";
import { TransactionLabelsEnum } from "../../shared/enums/transactionLabelsEnum";
import { useModalDetailInfo } from "./state/useModalDetailInfo";
import { IModalDetailInfo } from "./ModalDetailInfo.interfaces";
import { get, has, isEmpty } from "lodash";
import { getColorByStatus } from "../../shared/utils/general_utils";
import { CATALOG_TRANSACTION_STATUS } from "../../shared/enums/TransactionStatusEnum";
import { TransactionInfo } from "../../../types/transactions_data";
import GenericTabs from "../GenericTabs/GenericTabs";
import { ModalDetailCard } from "@kushki/connect-ui/dist/Components/Organism/ModalDetailCard";
import { format } from "date-fns-tz";
import {
  buildDate,
  dateZonedTime,
} from "../../shared/utils/timeZoneRegion_utils";

const ModalDetailInfo: FC<IModalDetailInfo> = (props: IModalDetailInfo) => {
  const {
    buttonsRow,
    handleClose,
    tabsInfo,
    transactionInfo,
    transactionTimeLine,
  } = useModalDetailInfo(props);
  const { modal } = props;
  const valueTransaction = (): TransactionInfo => {
    return !isEmpty(transactionTimeLine)
      ? transactionTimeLine
      : transactionInfo;
  };
  const isMongo: boolean = has(transactionInfo, "_id");

  return !isEmpty(get(valueTransaction(), "merchant_id", "")) ? (
    <ModalDetailCard
      bottom={"0"}
      slideDirection="left"
      isOpen={modal.isOpenModalDetailInfo}
      header={{
        buttonRow: buttonsRow,
        secondaryText: TransactionLabelsEnum.MODAL_DETAIL_DATE.concat(
          format(
            dateZonedTime(
              isMongo ? buildDate(transactionInfo) : transactionInfo.created!
            ),
            TransactionLabelsEnum.MODAL_DETAIL_DATE_FORMAT
          )
        ),
        tags: [
          {
            color: getColorByStatus(
              get(valueTransaction(), "transaction_status")!
            ),
            text: CATALOG_TRANSACTION_STATUS[
              get(valueTransaction(), "transaction_status")!
            ],
          },
        ],
        title: TransactionLabelsEnum.MODAL_DETAIL_TITLE,
        titleDescription: get(valueTransaction(), "ticket_number")!,
      }}
      onClose={handleClose}
      right={"0"}
      top={"150px"}
      variant={"full"}
      widthCard={"576px"}
    >
      <GenericTabs
        orientation={"horizontal"}
        tabList={tabsInfo}
        variant={"fullWidth"}
      />
    </ModalDetailCard>
  ) : (
    <></>
  );
};

export default ModalDetailInfo;
