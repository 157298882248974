import React from "react";
import {
  Box,
  Button,
  createStyles,
  Grid,
  IconButton,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { StatusSteps } from "../../../../types/remote/semaphore_data";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { useHistory } from "react-router-dom";
import { get, isEmpty } from "lodash";
import { Skeleton } from "@material-ui/lab";
import { AccountPreferencesResponse } from "../../../../types/account_preferences_response";
import Step from "@material-ui/core/Step";
import EditIcon from "../../../assets/image/edit.svg";
import { setMerchantInfoStorage } from "../../../shared/infrastructure/constants/CreateMerchantConstants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(2, 0),
      margin: theme.spacing(1, 1),
      paddingTop: 35,
      paddingBottom: 35,
    },
    fontWeight: {
      fontWeight: "lighter",
    },
    omitButton: {
      color: "rgba(109, 119, 129, 1)",
    },
    copyIcon: {
      marginLeft: "4px",
      cursor: "pointer",
    },
    stepIcon: {
      cursor: "pointer",
      backgroundColor: "#F0F4F9",
      borderRadius: "4px",
    },
    colorBox: {
      marginLeft: "5px",
      marginRight: "5px",
      width: "20px",
      height: "20px",
      borderRadius: "1px",
      position: "relative",
    },
    fixGrid: {
      flexBasis: "inherit",
    },
  })
);

export type IAccountPreferencesAdditionalSection = {
  publicMerchantId: string;
  accountPreferences: AccountPreferencesResponse | undefined;
  status: StatusSteps;
  changeStepStatus: (step: string, status: StatusSteps) => void;
  isLoading: boolean;
};

export const AccountPreferencesAdditionalSection: React.FC<
  Partial<IAccountPreferencesAdditionalSection>
> = (_: Partial<IAccountPreferencesAdditionalSection>) => {
  const classes = useStyles();
  const history = useHistory();
  const stateProps: Partial<IAccountPreferencesAdditionalSection> = useSelector(
    (state: IAppState) => ({
      publicMerchantId: state.merchantAffiliation?.publicMerchantId,
      accountPreferences: get(state, "accountPreferences", undefined),
      isLoading: state.loading,
    })
  );
  const basicDataURL: string = `/config-merchant/personalization/${stateProps.publicMerchantId}?hideSideBar=true`;

  const redirectBasicDataPage = () => {
    setMerchantInfoStorage();

    if (!stateProps.publicMerchantId) return;

    history.push(basicDataURL);
  };

  if (stateProps.isLoading)
    return <Skeleton variant="rect" width="100%" height={150} />;

  return isEmpty(stateProps.accountPreferences) ? (
    <Grid
      container
      direction="column"
      component={Paper}
      elevation={0}
      className={classes.paper}
      justify="center"
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={12} className={classes.fixGrid}>
        <Typography
          variant="h5"
          align="center"
          color="textPrimary"
          className={classes.fontWeight}
        >
          Aún no has configurado la <strong>personalización de marca</strong>.
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.fixGrid}>
        <Button
          id="btn-redirect"
          variant="contained"
          color="primary"
          onClick={redirectBasicDataPage}
        >
          Ingresar datos
        </Button>
      </Grid>
    </Grid>
  ) : (
    <Grid
      container
      direction="column"
      component={Paper}
      elevation={0}
      className={classes.paper}
      justify="center"
      alignItems="center"
      spacing={1}
    >
      <Grid item container xs={12} spacing={1} direction={"column"}>
        <Grid container item spacing={1}>
          <Grid item xs={2}>
            <Typography color="initial" style={{ fontWeight: "bold" }}>
              Personalización de marca
            </Typography>
          </Grid>
          <Grid item xs={"auto"}>
            <Step className={classes.stepIcon}>
              <IconButton
                onClick={redirectBasicDataPage}
                id="copy_btn"
                size="small"
                aria-label="copy"
              >
                <img width={26} src={EditIcon} alt={EditIcon.toString()} />
              </IconButton>
            </Step>
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={2}>
            <Typography variant={"subtitle2"} color={"textSecondary"}>
              Colores de la marca:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box
              display={"flex"}
              style={{
                flexWrap: "wrap",
              }}
            >
              <Box width={{ xs: "100%", sm: "35%" }}>
                <Box display={"flex"} style={{ width: "80%", height: "100%" }}>
                  <Typography align="left" style={{ fontWeight: "bold" }}>
                    Principal:{" "}
                  </Typography>
                  <div
                    style={{
                      background: get(
                        stateProps.accountPreferences,
                        "colors.primary",
                        "transparent"
                      ),
                    }}
                    className={classes.colorBox}
                  />
                  <Typography align="left">
                    {get(
                      stateProps.accountPreferences,
                      "colors.primary",
                      ""
                    ).toUpperCase()}
                  </Typography>
                </Box>
              </Box>
              <Box width={{ xs: "100%", sm: "45%" }}>
                <Box display={"flex"} style={{ width: "80%", height: "100%" }}>
                  <Typography align="left" style={{ fontWeight: "bold" }}>
                    Secundario:{" "}
                  </Typography>
                  <div
                    style={{
                      background: get(
                        stateProps.accountPreferences,
                        "colors.secondary",
                        "transparent"
                      ),
                    }}
                    className={classes.colorBox}
                  />
                  <Typography align="left">
                    {get(
                      stateProps.accountPreferences,
                      "colors.secondary",
                      ""
                    ).toUpperCase()}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={2}>
            <Typography variant={"subtitle2"} color={"textSecondary"}>
              Email:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"body2"} color={"textPrimary"}>
              {get(stateProps.accountPreferences, "contact.email", "")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={2}>
            <Typography variant={"subtitle2"} color={"textSecondary"}>
              Teléfono:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"body2"} color={"textPrimary"}>
              {get(stateProps.accountPreferences, "contact.phoneNumber", "")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
