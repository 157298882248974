import { createSlice } from "@reduxjs/toolkit";
import { addBalance } from "../../thunks/balance/balance.thunk";
import { buildNotificationAddBalance } from "../../../shared/utils/snackbar";
import { NotificationTypeEnum } from "../../../shared/enums/snackbar";
import { get } from "lodash";
import { ErrorWallet } from "../../../shared/enums/error_wallet";
import { BalanceData } from "../../interfaces/balance.interfaces";

export const initialState: BalanceData = {
  isLoadingAddBalance: false,
  notificationAddBalanceStatus: undefined,
  transactionType: "",
};

export const balanceDataSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(addBalance.pending, (state) => {
        state.isLoadingAddBalance = true;
      })
      .addCase(addBalance.fulfilled, (state) => {
        state.notificationAddBalanceStatus = buildNotificationAddBalance(
          NotificationTypeEnum.SUCCESS,
          state.transactionType
        );
        state.isLoadingAddBalance = false;
      })
      .addCase(addBalance.rejected, (state, action) => {
        const message_error =
          get(action.error, "code") === ErrorWallet.WLT009
            ? get(action.error, "message", "")
            : undefined;

        state.notificationAddBalanceStatus = buildNotificationAddBalance(
          NotificationTypeEnum.FAILED,
          state.transactionType,
          message_error
        );
        state.isLoadingAddBalance = false;
      });
  },
  initialState,
  name: "BalanceData",
  reducers: {
    setNotifyAddBalanceStatus: (state, action) => {
      state.notificationAddBalanceStatus = action.payload;
    },
    setTransactionType: (state, action) => {
      state.transactionType = action.payload;
    },
  },
});

export default balanceDataSlice.reducer;
