import { makeStyles } from "@material-ui/core/styles";

export const requestDispersionOnProcessStyles = makeStyles({
  successImage: {
    width: "300px",
    marginTop: 25,
    marginBottom: 20,
  },
  mainTitle: {
    fontWeight: 500,
    fontSize: "20px",
    color: "#023365",
  },
  description: {
    fontWeight: 400,
    fontSize: "16px",
    color: "#6D7781",
    lineHeight: "24px",
    justifyContent: "center",
  },
  resume: {
    fontWeight: 500,
    fontSize: "16px",
    color: "#023365",
  },
  rowTitle: {
    fontWeight: 400,
    fontSize: "15px",
    color: "#023365",
    lineHeight: "18px",
    marginLeft: "100px",
  },
  rowContent: {
    fontWeight: 400,
    fontSize: "15px",
    color: "#6D7781",
    lineHeight: "18px",
    marginLeft: "60px",
  },
  button: {
    marginTop: "30px",
    marginBottom: "30px",
  },
});
