import React from "react";
import { Box, Typography } from "@mui/material";
import { ISlideItem } from "./slideItem.interfaces";
import { slideItemStyles } from "./slideItem.styles";

const SlideItem: React.FC<ISlideItem> = ({
  image,
  title,
  secondaryText,
}: ISlideItem) => {
  const styles = slideItemStyles();

  return (
    <Box sx={styles.container}>
      <Box sx={styles.image}>{image}</Box>
      <Box sx={styles.content}>
        <Box pb={2}>
          <Typography variant={"h2"} sx={styles.title}>
            {title}
          </Typography>
        </Box>
        <Box>
          <Typography variant={"body1"} sx={styles.secondaryText}>
            {secondaryText}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SlideItem;
