import React from "react";
import { TableCell, Typography } from "@mui/material";
import { TableBodyStyles } from "../../../common/Table/TableBody/TableBody.styles";

interface Props {
  value: string;
}

const TableRowDate = ({ value }: Props) => {
  const date = value.split("T");

  return (
    <TableCell sx={TableBodyStyles.cell}>
      <Typography sx={TableBodyStyles.label}>{date[0]}</Typography>

      <Typography sx={TableBodyStyles.subLabel}>{date[1]}</Typography>
    </TableCell>
  );
};

export default TableRowDate;
