/**
 * Catalog ElSalvador
 */
import { Category } from "../../interfaces/category";
import { CatalogsEnum } from "../../enums/CatalogsEnum";
import { CurrencyEnum } from "../../enums/currencyEnum";

export const CatalogEl: Record<string, Category[]> = {
  [CatalogsEnum.Banks]: [
    {
      name: "BANCO CITIBANK DE EL SALVADOR S.A.",
      value: "10100003",
    },
    {
      name: "BANCO AGRICOLA S.A.",
      value: "10100001",
    },
    {
      name: "BANCO CENTRAL DE RESERVA DE EL SALVADOR",
      value: "10100002",
    },
    {
      name: "BANCO DE AMERICA CENTRAL S.A.",
      value: "10100004",
    },
    {
      name: "BANCO HIPOTECARIO DE EL SALVADOR S.A.",
      value: "10100005",
    },
    {
      name: "BANCO HSBC SALVADORENO S.A.",
      value: "10100006",
    },
    {
      name: "BANCO MULTISECTORIAL DE INVERSIONES",
      value: "10100007",
    },
    {
      name: "BANCO PROCREDIT S.A.",
      value: "10100008",
    },
    {
      name: "SCOTIABANK EL SALVADOR S.A.",
      value: "10100009",
    },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { name: "Corriente", value: "0" },
    { name: "Ahorros", value: "1" },
  ],
  [CatalogsEnum.Currency]: [
    {
      name: `${CurrencyEnum.USD} - Dólares`,
      value: CurrencyEnum.USD,
    },
  ],
};
