/**
 * Catalog Mexico
 */
import { Category } from "../constants/information_constants";
import { CatalogsEnum } from "../interfaces/catalogs-enum";
import { CurrencyEnum } from "../interfaces/currency-enum";

export const CatalogMx: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { defaultShareholderDocType: "0", value: "0", name: "CURP" },
    { defaultShareholderDocType: "1", value: "1", name: "Pasaporte" },
    { defaultShareholderDocType: "0", value: "3", name: "RFC" },
  ],
  [CatalogsEnum.Provinces]: [
    { value: "AGU", name: "AGUASCALIENTES" },
    { value: "BCN", name: "BAJA CALIFORNIA" },
    { value: "BCS", name: "BAJA CALIFORNIA SUR" },
    { value: "CAM", name: "CAMPECHE" },
    { value: "CHP", name: "CHIAPAS" },
    { value: "CHH", name: "CHIHUAHUA" },
    { value: "COA", name: "COAHUILA" },
    { value: "COL", name: "COLIMA" },
    { value: "CDM", name: "CIUDAD DE MÉXICO" },
    { value: "DUR", name: "DURANGO" },
    { value: "GUA", name: "GUANAJUATO" },
    { value: "GRO", name: "GUERRERO" },
    { value: "HID", name: "HIDALGO" },
    { value: "JAL", name: "JALISCO" },
    { value: "EDO", name: "ESTADO DE MÉXICO" },
    { value: "MIC", name: "MICHOACÁN" },
    { value: "MOR", name: "MORELOS" },
    { value: "NAY", name: "NAYARIT" },
    { value: "NLE", name: "NUEVO LEÓN" },
    { value: "OAX", name: "OAXACA" },
    { value: "PUE", name: "PUEBLA" },
    { value: "QUE", name: "QUERÉTARO" },
    { value: "ROO", name: "QUINTANA ROO" },
    { value: "SLP", name: "SAN LUIS POTOSÍ" },
    { value: "SIN", name: "SINALOA" },
    { value: "SON", name: "SONORA" },
    { value: "TAB", name: "TABASCO" },
    { value: "TAM", name: "TAMAULIPAS" },
    { value: "TLA", name: "TLAXCALA" },
    { value: "VER", name: "VERACRUZ" },
    { value: "YUC", name: "YUCATÁN" },
    { value: "ZAC", name: "ZACATECAS" },
  ],
  [CatalogsEnum.Cities]: [
    {
      value: "AGUASCALIENTES",
      name: "AGUASCALIENTES",
      parent: "AGUASCALIENTES",
    },
    {
      value: "ASIENTOS",
      name: "ASIENTOS",
      parent: "AGUASCALIENTES",
    },
    {
      value: "CALVILLO",
      name: "CALVILLO",
      parent: "AGUASCALIENTES",
    },
    {
      value: "COSÍO",
      name: "COSÍO",
      parent: "AGUASCALIENTES",
    },
    {
      value: "JESÚS MARÍA",
      name: "JESÚS MARÍA",
      parent: "AGUASCALIENTES",
    },
    {
      value: "PABELLÓN DE ARTEAGA",
      name: "PABELLÓN DE ARTEAGA",
      parent: "AGUASCALIENTES",
    },
    {
      value: "RINCÓN DE ROMOS",
      name: "RINCÓN DE ROMOS",
      parent: "AGUASCALIENTES",
    },
    {
      value: "SAN JOSÉ DE GRACIA",
      name: "SAN JOSÉ DE GRACIA",
      parent: "AGUASCALIENTES",
    },
    {
      value: "TEPEZALÁ",
      name: "TEPEZALÁ",
      parent: "AGUASCALIENTES",
    },
    {
      value: "EL LLANO",
      name: "EL LLANO",
      parent: "AGUASCALIENTES",
    },
    {
      value: "SAN FRANCISCO DE LOS ROMO",
      name: "SAN FRANCISCO DE LOS ROMO",
      parent: "AGUASCALIENTES",
    },
    {
      value: "ENSENADA",
      name: "ENSENADA",
      parent: "BAJA CALIFORNIA",
    },
    {
      value: "MEXICALI",
      name: "MEXICALI",
      parent: "BAJA CALIFORNIA",
    },
    {
      value: "TECATE",
      name: "TECATE",
      parent: "BAJA CALIFORNIA",
    },
    {
      value: "TIJUANA",
      name: "TIJUANA",
      parent: "BAJA CALIFORNIA",
    },
    {
      value: "PLAYAS DE ROSARITO",
      name: "PLAYAS DE ROSARITO",
      parent: "BAJA CALIFORNIA",
    },
    {
      value: "COMONDÚ",
      name: "COMONDÚ",
      parent: "BAJA CALIFORNIA SUR",
    },
    {
      value: "MULEGÉ",
      name: "MULEGÉ",
      parent: "BAJA CALIFORNIA SUR",
    },
    {
      value: "LA PAZ",
      name: "LA PAZ",
      parent: "BAJA CALIFORNIA SUR",
    },
    {
      value: "LOS CABOS",
      name: "LOS CABOS",
      parent: "BAJA CALIFORNIA SUR",
    },
    {
      value: "LORETO",
      name: "LORETO",
      parent: "BAJA CALIFORNIA SUR",
    },
    {
      value: "CALKINÍ",
      name: "CALKINÍ",
      parent: "CAMPECHE",
    },
    {
      value: "CAMPECHE",
      name: "CAMPECHE",
      parent: "CAMPECHE",
    },
    {
      value: "CARMEN",
      name: "CARMEN",
      parent: "CAMPECHE",
    },
    {
      value: "CHAMPOTÓN",
      name: "CHAMPOTÓN",
      parent: "CAMPECHE",
    },
    {
      value: "HECELCHAKÁN",
      name: "HECELCHAKÁN",
      parent: "CAMPECHE",
    },
    {
      value: "HOPELCHÉN",
      name: "HOPELCHÉN",
      parent: "CAMPECHE",
    },
    {
      value: "PALIZADA",
      name: "PALIZADA",
      parent: "CAMPECHE",
    },
    {
      value: "TENABO",
      name: "TENABO",
      parent: "CAMPECHE",
    },
    {
      value: "ESCÁRCEGA",
      name: "ESCÁRCEGA",
      parent: "CAMPECHE",
    },
    {
      value: "CALAKMUL",
      name: "CALAKMUL",
      parent: "CAMPECHE",
    },
    {
      value: "CANDELARIA",
      name: "CANDELARIA",
      parent: "CAMPECHE",
    },
    {
      value: "ACACOYAGUA",
      name: "ACACOYAGUA",
      parent: "CHIAPAS",
    },
    {
      value: "ACALA",
      name: "ACALA",
      parent: "CHIAPAS",
    },
    {
      value: "ACAPETAHUA",
      name: "ACAPETAHUA",
      parent: "CHIAPAS",
    },
    {
      value: "ALTAMIRANO",
      name: "ALTAMIRANO",
      parent: "CHIAPAS",
    },
    {
      value: "AMATÁN",
      name: "AMATÁN",
      parent: "CHIAPAS",
    },
    {
      value: "AMATENANGO DE LA FRONTERA",
      name: "AMATENANGO DE LA FRONTERA",
      parent: "CHIAPAS",
    },
    {
      value: "AMATENANGO DEL VALLE",
      name: "AMATENANGO DEL VALLE",
      parent: "CHIAPAS",
    },
    {
      value: "ANGEL ALBINO CORZO",
      name: "ANGEL ALBINO CORZO",
      parent: "CHIAPAS",
    },
    {
      value: "ARRIAGA",
      name: "ARRIAGA",
      parent: "CHIAPAS",
    },
    {
      value: "BEJUCAL DE OCAMPO",
      name: "BEJUCAL DE OCAMPO",
      parent: "CHIAPAS",
    },
    {
      value: "BELLA VISTA",
      name: "BELLA VISTA",
      parent: "CHIAPAS",
    },
    {
      value: "BERRIOZÁBAL",
      name: "BERRIOZÁBAL",
      parent: "CHIAPAS",
    },
    {
      value: "BOCHIL",
      name: "BOCHIL",
      parent: "CHIAPAS",
    },
    {
      value: "EL BOSQUE",
      name: "EL BOSQUE",
      parent: "CHIAPAS",
    },
    {
      value: "CACAHOATÁN",
      name: "CACAHOATÁN",
      parent: "CHIAPAS",
    },
    {
      value: "CATAZAJÁ",
      name: "CATAZAJÁ",
      parent: "CHIAPAS",
    },
    {
      value: "CINTALAPA",
      name: "CINTALAPA",
      parent: "CHIAPAS",
    },
    {
      value: "COAPILLA",
      name: "COAPILLA",
      parent: "CHIAPAS",
    },
    {
      value: "COMITÁN DE DOMÍNGUEZ",
      name: "COMITÁN DE DOMÍNGUEZ",
      parent: "CHIAPAS",
    },
    {
      value: "LA CONCORDIA",
      name: "LA CONCORDIA",
      parent: "CHIAPAS",
    },
    {
      value: "COPAINALÁ",
      name: "COPAINALÁ",
      parent: "CHIAPAS",
    },
    {
      value: "CHALCHIHUITÁN",
      name: "CHALCHIHUITÁN",
      parent: "CHIAPAS",
    },
    {
      value: "CHAMULA",
      name: "CHAMULA",
      parent: "CHIAPAS",
    },
    {
      value: "CHANAL",
      name: "CHANAL",
      parent: "CHIAPAS",
    },
    {
      value: "CHAPULTENANGO",
      name: "CHAPULTENANGO",
      parent: "CHIAPAS",
    },
    {
      value: "CHENALHÓ",
      name: "CHENALHÓ",
      parent: "CHIAPAS",
    },
    {
      value: "CHIAPA DE CORZO",
      name: "CHIAPA DE CORZO",
      parent: "CHIAPAS",
    },
    {
      value: "CHIAPILLA",
      name: "CHIAPILLA",
      parent: "CHIAPAS",
    },
    {
      value: "CHICOASÉN",
      name: "CHICOASÉN",
      parent: "CHIAPAS",
    },
    {
      value: "CHICOMUSELO",
      name: "CHICOMUSELO",
      parent: "CHIAPAS",
    },
    {
      value: "CHILÓN",
      name: "CHILÓN",
      parent: "CHIAPAS",
    },
    {
      value: "ESCUINTLA",
      name: "ESCUINTLA",
      parent: "CHIAPAS",
    },
    {
      value: "FRANCISCO LEÓN",
      name: "FRANCISCO LEÓN",
      parent: "CHIAPAS",
    },
    {
      value: "FRONTERA COMALAPA",
      name: "FRONTERA COMALAPA",
      parent: "CHIAPAS",
    },
    {
      value: "FRONTERA HIDALGO",
      name: "FRONTERA HIDALGO",
      parent: "CHIAPAS",
    },
    {
      value: "LA GRANDEZA",
      name: "LA GRANDEZA",
      parent: "CHIAPAS",
    },
    {
      value: "HUEHUETÁN",
      name: "HUEHUETÁN",
      parent: "CHIAPAS",
    },
    {
      value: "HUIXTÁN",
      name: "HUIXTÁN",
      parent: "CHIAPAS",
    },
    {
      value: "HUITIUPÁN",
      name: "HUITIUPÁN",
      parent: "CHIAPAS",
    },
    {
      value: "HUIXTLA",
      name: "HUIXTLA",
      parent: "CHIAPAS",
    },
    {
      value: "LA INDEPENDENCIA",
      name: "LA INDEPENDENCIA",
      parent: "CHIAPAS",
    },
    {
      value: "IXHUATÁN",
      name: "IXHUATÁN",
      parent: "CHIAPAS",
    },
    {
      value: "IXTACOMITÁN",
      name: "IXTACOMITÁN",
      parent: "CHIAPAS",
    },
    {
      value: "IXTAPA",
      name: "IXTAPA",
      parent: "CHIAPAS",
    },
    {
      value: "IXTAPANGAJOYA",
      name: "IXTAPANGAJOYA",
      parent: "CHIAPAS",
    },
    {
      value: "JIQUIPILAS",
      name: "JIQUIPILAS",
      parent: "CHIAPAS",
    },
    {
      value: "JITOTOL",
      name: "JITOTOL",
      parent: "CHIAPAS",
    },
    {
      value: "JUÁREZ",
      name: "JUÁREZ",
      parent: "CHIAPAS",
    },
    {
      value: "LARRÁINZAR",
      name: "LARRÁINZAR",
      parent: "CHIAPAS",
    },
    {
      value: "LA LIBERTAD",
      name: "LA LIBERTAD",
      parent: "CHIAPAS",
    },
    {
      value: "MAPASTEPEC",
      name: "MAPASTEPEC",
      parent: "CHIAPAS",
    },
    {
      value: "LAS MARGARITAS",
      name: "LAS MARGARITAS",
      parent: "CHIAPAS",
    },
    {
      value: "MAZAPA DE MADERO",
      name: "MAZAPA DE MADERO",
      parent: "CHIAPAS",
    },
    {
      value: "MAZATÁN",
      name: "MAZATÁN",
      parent: "CHIAPAS",
    },
    {
      value: "METAPA",
      name: "METAPA",
      parent: "CHIAPAS",
    },
    {
      value: "MITONTIC",
      name: "MITONTIC",
      parent: "CHIAPAS",
    },
    {
      value: "MOTOZINTLA",
      name: "MOTOZINTLA",
      parent: "CHIAPAS",
    },
    {
      value: "NICOLÁS RUÍZ",
      name: "NICOLÁS RUÍZ",
      parent: "CHIAPAS",
    },
    {
      value: "OCOSINGO",
      name: "OCOSINGO",
      parent: "CHIAPAS",
    },
    {
      value: "OCOTEPEC",
      name: "OCOTEPEC",
      parent: "CHIAPAS",
    },
    {
      value: "OCOZOCOAUTLA DE ESPINOSA",
      name: "OCOZOCOAUTLA DE ESPINOSA",
      parent: "CHIAPAS",
    },
    {
      value: "OSTUACÁN",
      name: "OSTUACÁN",
      parent: "CHIAPAS",
    },
    {
      value: "OSUMACINTA",
      name: "OSUMACINTA",
      parent: "CHIAPAS",
    },
    {
      value: "OXCHUC",
      name: "OXCHUC",
      parent: "CHIAPAS",
    },
    {
      value: "PALENQUE",
      name: "PALENQUE",
      parent: "CHIAPAS",
    },
    {
      value: "PANTELHÓ",
      name: "PANTELHÓ",
      parent: "CHIAPAS",
    },
    {
      value: "PANTEPEC",
      name: "PANTEPEC",
      parent: "CHIAPAS",
    },
    {
      value: "PICHUCALCO",
      name: "PICHUCALCO",
      parent: "CHIAPAS",
    },
    {
      value: "PIJIJIAPAN",
      name: "PIJIJIAPAN",
      parent: "CHIAPAS",
    },
    {
      value: "EL PORVENIR",
      name: "EL PORVENIR",
      parent: "CHIAPAS",
    },
    {
      value: "VILLA COMALTITLÁN",
      name: "VILLA COMALTITLÁN",
      parent: "CHIAPAS",
    },
    {
      value: "PUEBLO NUEVO SOLISTAHUACÁN",
      name: "PUEBLO NUEVO SOLISTAHUACÁN",
      parent: "CHIAPAS",
    },
    {
      value: "RAYÓN",
      name: "RAYÓN",
      parent: "CHIAPAS",
    },
    {
      value: "REFORMA",
      name: "REFORMA",
      parent: "CHIAPAS",
    },
    {
      value: "LAS ROSAS",
      name: "LAS ROSAS",
      parent: "CHIAPAS",
    },
    {
      value: "SABANILLA",
      name: "SABANILLA",
      parent: "CHIAPAS",
    },
    {
      value: "SALTO DE AGUA",
      name: "SALTO DE AGUA",
      parent: "CHIAPAS",
    },
    {
      value: "SAN CRISTÓBAL DE LAS CASAS",
      name: "SAN CRISTÓBAL DE LAS CASAS",
      parent: "CHIAPAS",
    },
    {
      value: "SAN FERNANDO",
      name: "SAN FERNANDO",
      parent: "CHIAPAS",
    },
    {
      value: "SILTEPEC",
      name: "SILTEPEC",
      parent: "CHIAPAS",
    },
    {
      value: "SIMOJOVEL",
      name: "SIMOJOVEL",
      parent: "CHIAPAS",
    },
    {
      value: "SITALÁ",
      name: "SITALÁ",
      parent: "CHIAPAS",
    },
    {
      value: "SOCOLTENANGO",
      name: "SOCOLTENANGO",
      parent: "CHIAPAS",
    },
    {
      value: "SOLOSUCHIAPA",
      name: "SOLOSUCHIAPA",
      parent: "CHIAPAS",
    },
    {
      value: "SOYALÓ",
      name: "SOYALÓ",
      parent: "CHIAPAS",
    },
    {
      value: "SUCHIAPA",
      name: "SUCHIAPA",
      parent: "CHIAPAS",
    },
    {
      value: "SUCHIATE",
      name: "SUCHIATE",
      parent: "CHIAPAS",
    },
    {
      value: "SUNUAPA",
      name: "SUNUAPA",
      parent: "CHIAPAS",
    },
    {
      value: "TAPACHULA",
      name: "TAPACHULA",
      parent: "CHIAPAS",
    },
    {
      value: "TAPALAPA",
      name: "TAPALAPA",
      parent: "CHIAPAS",
    },
    {
      value: "TAPILULA",
      name: "TAPILULA",
      parent: "CHIAPAS",
    },
    {
      value: "TECPATÁN",
      name: "TECPATÁN",
      parent: "CHIAPAS",
    },
    {
      value: "TENEJAPA",
      name: "TENEJAPA",
      parent: "CHIAPAS",
    },
    {
      value: "TEOPISCA",
      name: "TEOPISCA",
      parent: "CHIAPAS",
    },
    {
      value: "TILA",
      name: "TILA",
      parent: "CHIAPAS",
    },
    {
      value: "TONALÁ",
      name: "TONALÁ",
      parent: "CHIAPAS",
    },
    {
      value: "TOTOLAPA",
      name: "TOTOLAPA",
      parent: "CHIAPAS",
    },
    {
      value: "LA TRINITARIA",
      name: "LA TRINITARIA",
      parent: "CHIAPAS",
    },
    {
      value: "TUMBALÁ",
      name: "TUMBALÁ",
      parent: "CHIAPAS",
    },
    {
      value: "TUXTLA GUTIÉRREZ",
      name: "TUXTLA GUTIÉRREZ",
      parent: "CHIAPAS",
    },
    {
      value: "TUXTLA CHICO",
      name: "TUXTLA CHICO",
      parent: "CHIAPAS",
    },
    {
      value: "TUZANTÁN",
      name: "TUZANTÁN",
      parent: "CHIAPAS",
    },
    {
      value: "TZIMOL",
      name: "TZIMOL",
      parent: "CHIAPAS",
    },
    {
      value: "UNIÓN JUÁREZ",
      name: "UNIÓN JUÁREZ",
      parent: "CHIAPAS",
    },
    {
      value: "VENUSTIANO CARRANZA",
      name: "VENUSTIANO CARRANZA",
      parent: "CHIAPAS",
    },
    {
      value: "VILLA CORZO",
      name: "VILLA CORZO",
      parent: "CHIAPAS",
    },
    {
      value: "VILLAFLORES",
      name: "VILLAFLORES",
      parent: "CHIAPAS",
    },
    {
      value: "YAJALÓN",
      name: "YAJALÓN",
      parent: "CHIAPAS",
    },
    {
      value: "SAN LUCAS",
      name: "SAN LUCAS",
      parent: "CHIAPAS",
    },
    {
      value: "ZINACANTÁN",
      name: "ZINACANTÁN",
      parent: "CHIAPAS",
    },
    {
      value: "SAN JUAN CANCUC",
      name: "SAN JUAN CANCUC",
      parent: "CHIAPAS",
    },
    {
      value: "ALDAMA",
      name: "ALDAMA",
      parent: "CHIAPAS",
    },
    {
      value: "BENEMÉRITO DE LAS AMÉRICAS",
      name: "BENEMÉRITO DE LAS AMÉRICAS",
      parent: "CHIAPAS",
    },
    {
      value: "MARAVILLA TENEJAPA",
      name: "MARAVILLA TENEJAPA",
      parent: "CHIAPAS",
    },
    {
      value: "MARQUÉS DE COMILLAS",
      name: "MARQUÉS DE COMILLAS",
      parent: "CHIAPAS",
    },
    {
      value: "MONTECRISTO DE GUERRERO",
      name: "MONTECRISTO DE GUERRERO",
      parent: "CHIAPAS",
    },
    {
      value: "SAN ANDRÉS DURAZNAL",
      name: "SAN ANDRÉS DURAZNAL",
      parent: "CHIAPAS",
    },
    {
      value: "SANTIAGO EL PINAR",
      name: "SANTIAGO EL PINAR",
      parent: "CHIAPAS",
    },
    {
      value: "AHUMADA",
      name: "AHUMADA",
      parent: "CHIHUAHUA",
    },
    {
      value: "ALDAMA",
      name: "ALDAMA",
      parent: "CHIHUAHUA",
    },
    {
      value: "ALLENDE",
      name: "ALLENDE",
      parent: "CHIHUAHUA",
    },
    {
      value: "AQUILES SERDÁN",
      name: "AQUILES SERDÁN",
      parent: "CHIHUAHUA",
    },
    {
      value: "ASCENSIÓN",
      name: "ASCENSIÓN",
      parent: "CHIHUAHUA",
    },
    {
      value: "BACHÍNIVA",
      name: "BACHÍNIVA",
      parent: "CHIHUAHUA",
    },
    {
      value: "BALLEZA",
      name: "BALLEZA",
      parent: "CHIHUAHUA",
    },
    {
      value: "BATOPILAS",
      name: "BATOPILAS",
      parent: "CHIHUAHUA",
    },
    {
      value: "BOCOYNA",
      name: "BOCOYNA",
      parent: "CHIHUAHUA",
    },
    {
      value: "BUENAVENTURA",
      name: "BUENAVENTURA",
      parent: "CHIHUAHUA",
    },
    {
      value: "CAMARGO",
      name: "CAMARGO",
      parent: "CHIHUAHUA",
    },
    {
      value: "CARICHÍ",
      name: "CARICHÍ",
      parent: "CHIHUAHUA",
    },
    {
      value: "CASAS GRANDES",
      name: "CASAS GRANDES",
      parent: "CHIHUAHUA",
    },
    {
      value: "CORONADO",
      name: "CORONADO",
      parent: "CHIHUAHUA",
    },
    {
      value: "COYAME DEL SOTOL",
      name: "COYAME DEL SOTOL",
      parent: "CHIHUAHUA",
    },
    {
      value: "LA CRUZ",
      name: "LA CRUZ",
      parent: "CHIHUAHUA",
    },
    {
      value: "CUAUHTÉMOC",
      name: "CUAUHTÉMOC",
      parent: "CHIHUAHUA",
    },
    {
      value: "CUSIHUIRIACHI",
      name: "CUSIHUIRIACHI",
      parent: "CHIHUAHUA",
    },
    {
      value: "CHIHUAHUA",
      name: "CHIHUAHUA",
      parent: "CHIHUAHUA",
    },
    {
      value: "CHÍNIPAS",
      name: "CHÍNIPAS",
      parent: "CHIHUAHUA",
    },
    {
      value: "DELICIAS",
      name: "DELICIAS",
      parent: "CHIHUAHUA",
    },
    {
      value: "DR. BELISARIO DOMÍNGUEZ",
      name: "DR. BELISARIO DOMÍNGUEZ",
      parent: "CHIHUAHUA",
    },
    {
      value: "GALEANA",
      name: "GALEANA",
      parent: "CHIHUAHUA",
    },
    {
      value: "SANTA ISABEL",
      name: "SANTA ISABEL",
      parent: "CHIHUAHUA",
    },
    {
      value: "GÓMEZ FARÍAS",
      name: "GÓMEZ FARÍAS",
      parent: "CHIHUAHUA",
    },
    {
      value: "GRAN MORELOS",
      name: "GRAN MORELOS",
      parent: "CHIHUAHUA",
    },
    {
      value: "GUACHOCHI",
      name: "GUACHOCHI",
      parent: "CHIHUAHUA",
    },
    {
      value: "GUADALUPE",
      name: "GUADALUPE",
      parent: "CHIHUAHUA",
    },
    {
      value: "GUADALUPE Y CALVO",
      name: "GUADALUPE Y CALVO",
      parent: "CHIHUAHUA",
    },
    {
      value: "GUAZAPARES",
      name: "GUAZAPARES",
      parent: "CHIHUAHUA",
    },
    {
      value: "GUERRERO",
      name: "GUERRERO",
      parent: "CHIHUAHUA",
    },
    {
      value: "HIDALGO DEL PARRAL",
      name: "HIDALGO DEL PARRAL",
      parent: "CHIHUAHUA",
    },
    {
      value: "HUEJOTITÁN",
      name: "HUEJOTITÁN",
      parent: "CHIHUAHUA",
    },
    {
      value: "IGNACIO ZARAGOZA",
      name: "IGNACIO ZARAGOZA",
      parent: "CHIHUAHUA",
    },
    {
      value: "JANOS",
      name: "JANOS",
      parent: "CHIHUAHUA",
    },
    {
      value: "JIMÉNEZ",
      name: "JIMÉNEZ",
      parent: "CHIHUAHUA",
    },
    {
      value: "JUÁREZ",
      name: "JUÁREZ",
      parent: "CHIHUAHUA",
    },
    {
      value: "JULIMES",
      name: "JULIMES",
      parent: "CHIHUAHUA",
    },
    {
      value: "LÓPEZ",
      name: "LÓPEZ",
      parent: "CHIHUAHUA",
    },
    {
      value: "MADERA",
      name: "MADERA",
      parent: "CHIHUAHUA",
    },
    {
      value: "MAGUARICHI",
      name: "MAGUARICHI",
      parent: "CHIHUAHUA",
    },
    {
      value: "MANUEL BENAVIDES",
      name: "MANUEL BENAVIDES",
      parent: "CHIHUAHUA",
    },
    {
      value: "MATACHÍ",
      name: "MATACHÍ",
      parent: "CHIHUAHUA",
    },
    {
      value: "MATAMOROS",
      name: "MATAMOROS",
      parent: "CHIHUAHUA",
    },
    {
      value: "MEOQUI",
      name: "MEOQUI",
      parent: "CHIHUAHUA",
    },
    {
      value: "MORELOS",
      name: "MORELOS",
      parent: "CHIHUAHUA",
    },
    {
      value: "MORIS",
      name: "MORIS",
      parent: "CHIHUAHUA",
    },
    {
      value: "NAMIQUIPA",
      name: "NAMIQUIPA",
      parent: "CHIHUAHUA",
    },
    {
      value: "NONOAVA",
      name: "NONOAVA",
      parent: "CHIHUAHUA",
    },
    {
      value: "NUEVO CASAS GRANDES",
      name: "NUEVO CASAS GRANDES",
      parent: "CHIHUAHUA",
    },
    {
      value: "OCAMPO",
      name: "OCAMPO",
      parent: "CHIHUAHUA",
    },
    {
      value: "OJINAGA",
      name: "OJINAGA",
      parent: "CHIHUAHUA",
    },
    {
      value: "PRAXEDIS G. GUERRERO",
      name: "PRAXEDIS G. GUERRERO",
      parent: "CHIHUAHUA",
    },
    {
      value: "RIVA PALACIO",
      name: "RIVA PALACIO",
      parent: "CHIHUAHUA",
    },
    {
      value: "ROSALES",
      name: "ROSALES",
      parent: "CHIHUAHUA",
    },
    {
      value: "ROSARIO",
      name: "ROSARIO",
      parent: "CHIHUAHUA",
    },
    {
      value: "SAN FRANCISCO DE BORJA",
      name: "SAN FRANCISCO DE BORJA",
      parent: "CHIHUAHUA",
    },
    {
      value: "SAN FRANCISCO DE CONCHOS",
      name: "SAN FRANCISCO DE CONCHOS",
      parent: "CHIHUAHUA",
    },
    {
      value: "SAN FRANCISCO DEL ORO",
      name: "SAN FRANCISCO DEL ORO",
      parent: "CHIHUAHUA",
    },
    {
      value: "SANTA BÁRBARA",
      name: "SANTA BÁRBARA",
      parent: "CHIHUAHUA",
    },
    {
      value: "SATEVÓ",
      name: "SATEVÓ",
      parent: "CHIHUAHUA",
    },
    {
      value: "SAUCILLO",
      name: "SAUCILLO",
      parent: "CHIHUAHUA",
    },
    {
      value: "TEMÓSACHIC",
      name: "TEMÓSACHIC",
      parent: "CHIHUAHUA",
    },
    {
      value: "EL TULE",
      name: "EL TULE",
      parent: "CHIHUAHUA",
    },
    {
      value: "URIQUE",
      name: "URIQUE",
      parent: "CHIHUAHUA",
    },
    {
      value: "URUACHI",
      name: "URUACHI",
      parent: "CHIHUAHUA",
    },
    {
      value: "VALLE DE ZARAGOZA",
      name: "VALLE DE ZARAGOZA",
      parent: "CHIHUAHUA",
    },
    {
      value: "AZCAPOTZALCO",
      name: "AZCAPOTZALCO",
      parent: "CIUDAD DE MÉXICO",
    },
    {
      value: "COYOACÁN",
      name: "COYOACÁN",
      parent: "CIUDAD DE MÉXICO",
    },
    {
      value: "CUAJIMALPA DE MORELOS",
      name: "CUAJIMALPA DE MORELOS",
      parent: "CIUDAD DE MÉXICO",
    },
    {
      value: "GUSTAVO A. MADERO",
      name: "GUSTAVO A. MADERO",
      parent: "CIUDAD DE MÉXICO",
    },
    {
      value: "IZTACALCO",
      name: "IZTACALCO",
      parent: "CIUDAD DE MÉXICO",
    },
    {
      value: "IZTAPALAPA",
      name: "IZTAPALAPA",
      parent: "CIUDAD DE MÉXICO",
    },
    {
      value: "LA MAGDALENA CONTRERAS",
      name: "LA MAGDALENA CONTRERAS",
      parent: "CIUDAD DE MÉXICO",
    },
    {
      value: "MILPA ALTA",
      name: "MILPA ALTA",
      parent: "CIUDAD DE MÉXICO",
    },
    {
      value: "ÁLVARO OBREGÓN",
      name: "ÁLVARO OBREGÓN",
      parent: "CIUDAD DE MÉXICO",
    },
    {
      value: "TLÁHUAC",
      name: "TLÁHUAC",
      parent: "CIUDAD DE MÉXICO",
    },
    {
      value: "TLALPAN",
      name: "TLALPAN",
      parent: "CIUDAD DE MÉXICO",
    },
    {
      value: "XOCHIMILCO",
      name: "XOCHIMILCO",
      parent: "CIUDAD DE MÉXICO",
    },
    {
      value: "BENITO JUÁREZ",
      name: "BENITO JUÁREZ",
      parent: "CIUDAD DE MÉXICO",
    },
    {
      value: "CUAUHTÉMOC",
      name: "CUAUHTÉMOC",
      parent: "CIUDAD DE MÉXICO",
    },
    {
      value: "MIGUEL HIDALGO",
      name: "MIGUEL HIDALGO",
      parent: "CIUDAD DE MÉXICO",
    },
    {
      value: "VENUSTIANO CARRANZA",
      name: "VENUSTIANO CARRANZA",
      parent: "CIUDAD DE MÉXICO",
    },
    {
      value: "ABASOLO",
      name: "ABASOLO",
      parent: "COAHUILA",
    },
    {
      value: "ACUÑA",
      name: "ACUÑA",
      parent: "COAHUILA",
    },
    {
      value: "ALLENDE",
      name: "ALLENDE",
      parent: "COAHUILA",
    },
    {
      value: "ARTEAGA",
      name: "ARTEAGA",
      parent: "COAHUILA",
    },
    {
      value: "CANDELA",
      name: "CANDELA",
      parent: "COAHUILA",
    },
    {
      value: "CASTAÑOS",
      name: "CASTAÑOS",
      parent: "COAHUILA",
    },
    {
      value: "CUATRO CIÉNEGAS",
      name: "CUATRO CIÉNEGAS",
      parent: "COAHUILA",
    },
    {
      value: "ESCOBEDO",
      name: "ESCOBEDO",
      parent: "COAHUILA",
    },
    {
      value: "FRANCISCO I. MADERO",
      name: "FRANCISCO I. MADERO",
      parent: "COAHUILA",
    },
    {
      value: "FRONTERA",
      name: "FRONTERA",
      parent: "COAHUILA",
    },
    {
      value: "GENERAL CEPEDA",
      name: "GENERAL CEPEDA",
      parent: "COAHUILA",
    },
    {
      value: "GUERRERO",
      name: "GUERRERO",
      parent: "COAHUILA",
    },
    {
      value: "HIDALGO",
      name: "HIDALGO",
      parent: "COAHUILA",
    },
    {
      value: "JIMÉNEZ",
      name: "JIMÉNEZ",
      parent: "COAHUILA",
    },
    {
      value: "JUÁREZ",
      name: "JUÁREZ",
      parent: "COAHUILA",
    },
    {
      value: "LAMADRID",
      name: "LAMADRID",
      parent: "COAHUILA",
    },
    {
      value: "MATAMOROS",
      name: "MATAMOROS",
      parent: "COAHUILA",
    },
    {
      value: "MONCLOVA",
      name: "MONCLOVA",
      parent: "COAHUILA",
    },
    {
      value: "MORELOS",
      name: "MORELOS",
      parent: "COAHUILA",
    },
    {
      value: "MÚZQUIZ",
      name: "MÚZQUIZ",
      parent: "COAHUILA",
    },
    {
      value: "NADADORES",
      name: "NADADORES",
      parent: "COAHUILA",
    },
    {
      value: "NAVA",
      name: "NAVA",
      parent: "COAHUILA",
    },
    {
      value: "OCAMPO",
      name: "OCAMPO",
      parent: "COAHUILA",
    },
    {
      value: "PARRAS",
      name: "PARRAS",
      parent: "COAHUILA",
    },
    {
      value: "PIEDRAS NEGRAS",
      name: "PIEDRAS NEGRAS",
      parent: "COAHUILA",
    },
    {
      value: "PROGRESO",
      name: "PROGRESO",
      parent: "COAHUILA",
    },
    {
      value: "RAMOS ARIZPE",
      name: "RAMOS ARIZPE",
      parent: "COAHUILA",
    },
    {
      value: "SABINAS",
      name: "SABINAS",
      parent: "COAHUILA",
    },
    {
      value: "SACRAMENTO",
      name: "SACRAMENTO",
      parent: "COAHUILA",
    },
    {
      value: "SALTILLO",
      name: "SALTILLO",
      parent: "COAHUILA",
    },
    {
      value: "SAN BUENAVENTURA",
      name: "SAN BUENAVENTURA",
      parent: "COAHUILA",
    },
    {
      value: "SAN JUAN DE SABINAS",
      name: "SAN JUAN DE SABINAS",
      parent: "COAHUILA",
    },
    {
      value: "SAN PEDRO",
      name: "SAN PEDRO",
      parent: "COAHUILA",
    },
    {
      value: "SIERRA MOJADA",
      name: "SIERRA MOJADA",
      parent: "COAHUILA",
    },
    {
      value: "TORREÓN",
      name: "TORREÓN",
      parent: "COAHUILA",
    },
    {
      value: "VIESCA",
      name: "VIESCA",
      parent: "COAHUILA",
    },
    {
      value: "VILLA UNIÓN",
      name: "VILLA UNIÓN",
      parent: "COAHUILA",
    },
    {
      value: "ZARAGOZA",
      name: "ZARAGOZA",
      parent: "COAHUILA",
    },
    {
      value: "ARMERÍA",
      name: "ARMERÍA",
      parent: "COLIMA",
    },
    {
      value: "COLIMA",
      name: "COLIMA",
      parent: "COLIMA",
    },
    {
      value: "COMALA",
      name: "COMALA",
      parent: "COLIMA",
    },
    {
      value: "COQUIMATLÁN",
      name: "COQUIMATLÁN",
      parent: "COLIMA",
    },
    {
      value: "CUAUHTÉMOC",
      name: "CUAUHTÉMOC",
      parent: "COLIMA",
    },
    {
      value: "IXTLAHUACÁN",
      name: "IXTLAHUACÁN",
      parent: "COLIMA",
    },
    {
      value: "MANZANILLO",
      name: "MANZANILLO",
      parent: "COLIMA",
    },
    {
      value: "MINATITLÁN",
      name: "MINATITLÁN",
      parent: "COLIMA",
    },
    {
      value: "TECOMÁN",
      name: "TECOMÁN",
      parent: "COLIMA",
    },
    {
      value: "VILLA DE ÁLVAREZ",
      name: "VILLA DE ÁLVAREZ",
      parent: "COLIMA",
    },
    {
      value: "CANATLÁN",
      name: "CANATLÁN",
      parent: "DURANGO",
    },
    {
      value: "CANELAS",
      name: "CANELAS",
      parent: "DURANGO",
    },
    {
      value: "CONETO DE COMONFORT",
      name: "CONETO DE COMONFORT",
      parent: "DURANGO",
    },
    {
      value: "CUENCAMÉ",
      name: "CUENCAMÉ",
      parent: "DURANGO",
    },
    {
      value: "DURANGO",
      name: "DURANGO",
      parent: "DURANGO",
    },
    {
      value: "GENERAL SIMÓN BOLÍVAR",
      name: "GENERAL SIMÓN BOLÍVAR",
      parent: "DURANGO",
    },
    {
      value: "GÓMEZ PALACIO",
      name: "GÓMEZ PALACIO",
      parent: "DURANGO",
    },
    {
      value: "GUADALUPE VICTORIA",
      name: "GUADALUPE VICTORIA",
      parent: "DURANGO",
    },
    {
      value: "GUANACEVÍ",
      name: "GUANACEVÍ",
      parent: "DURANGO",
    },
    {
      value: "HIDALGO",
      name: "HIDALGO",
      parent: "DURANGO",
    },
    {
      value: "INDÉ",
      name: "INDÉ",
      parent: "DURANGO",
    },
    {
      value: "LERDO",
      name: "LERDO",
      parent: "DURANGO",
    },
    {
      value: "MAPIMÍ",
      name: "MAPIMÍ",
      parent: "DURANGO",
    },
    {
      value: "MEZQUITAL",
      name: "MEZQUITAL",
      parent: "DURANGO",
    },
    {
      value: "NAZAS",
      name: "NAZAS",
      parent: "DURANGO",
    },
    {
      value: "NOMBRE DE DIOS",
      name: "NOMBRE DE DIOS",
      parent: "DURANGO",
    },
    {
      value: "OCAMPO",
      name: "OCAMPO",
      parent: "DURANGO",
    },
    {
      value: "EL ORO",
      name: "EL ORO",
      parent: "DURANGO",
    },
    {
      value: "OTÁEZ",
      name: "OTÁEZ",
      parent: "DURANGO",
    },
    {
      value: "PÁNUCO DE CORONADO",
      name: "PÁNUCO DE CORONADO",
      parent: "DURANGO",
    },
    {
      value: "PEÑÓN BLANCO",
      name: "PEÑÓN BLANCO",
      parent: "DURANGO",
    },
    {
      value: "POANAS",
      name: "POANAS",
      parent: "DURANGO",
    },
    {
      value: "PUEBLO NUEVO",
      name: "PUEBLO NUEVO",
      parent: "DURANGO",
    },
    {
      value: "RODEO",
      name: "RODEO",
      parent: "DURANGO",
    },
    {
      value: "SAN BERNARDO",
      name: "SAN BERNARDO",
      parent: "DURANGO",
    },
    {
      value: "SAN DIMAS",
      name: "SAN DIMAS",
      parent: "DURANGO",
    },
    {
      value: "SAN JUAN DE GUADALUPE",
      name: "SAN JUAN DE GUADALUPE",
      parent: "DURANGO",
    },
    {
      value: "SAN JUAN DEL RÍO",
      name: "SAN JUAN DEL RÍO",
      parent: "DURANGO",
    },
    {
      value: "SAN LUIS DEL CORDERO",
      name: "SAN LUIS DEL CORDERO",
      parent: "DURANGO",
    },
    {
      value: "SAN PEDRO DEL GALLO",
      name: "SAN PEDRO DEL GALLO",
      parent: "DURANGO",
    },
    {
      value: "SANTA CLARA",
      name: "SANTA CLARA",
      parent: "DURANGO",
    },
    {
      value: "SANTIAGO PAPASQUIARO",
      name: "SANTIAGO PAPASQUIARO",
      parent: "DURANGO",
    },
    {
      value: "SÚCHIL",
      name: "SÚCHIL",
      parent: "DURANGO",
    },
    {
      value: "TAMAZULA",
      name: "TAMAZULA",
      parent: "DURANGO",
    },
    {
      value: "TEPEHUANES",
      name: "TEPEHUANES",
      parent: "DURANGO",
    },
    {
      value: "TLAHUALILO",
      name: "TLAHUALILO",
      parent: "DURANGO",
    },
    {
      value: "TOPIA",
      name: "TOPIA",
      parent: "DURANGO",
    },
    {
      value: "VICENTE GUERRERO",
      name: "VICENTE GUERRERO",
      parent: "DURANGO",
    },
    {
      value: "NUEVO IDEAL",
      name: "NUEVO IDEAL",
      parent: "DURANGO",
    },
    {
      value: "ABASOLO",
      name: "ABASOLO",
      parent: "GUANAJUATO",
    },
    {
      value: "ACÁMBARO",
      name: "ACÁMBARO",
      parent: "GUANAJUATO",
    },
    {
      value: "SAN MIGUEL DE ALLENDE",
      name: "SAN MIGUEL DE ALLENDE",
      parent: "GUANAJUATO",
    },
    {
      value: "APASEO EL ALTO",
      name: "APASEO EL ALTO",
      parent: "GUANAJUATO",
    },
    {
      value: "APASEO EL GRANDE",
      name: "APASEO EL GRANDE",
      parent: "GUANAJUATO",
    },
    {
      value: "ATARJEA",
      name: "ATARJEA",
      parent: "GUANAJUATO",
    },
    {
      value: "CELAYA",
      name: "CELAYA",
      parent: "GUANAJUATO",
    },
    {
      value: "MANUEL DOBLADO",
      name: "MANUEL DOBLADO",
      parent: "GUANAJUATO",
    },
    {
      value: "COMONFORT",
      name: "COMONFORT",
      parent: "GUANAJUATO",
    },
    {
      value: "CORONEO",
      name: "CORONEO",
      parent: "GUANAJUATO",
    },
    {
      value: "CORTAZAR",
      name: "CORTAZAR",
      parent: "GUANAJUATO",
    },
    {
      value: "CUERÁMARO",
      name: "CUERÁMARO",
      parent: "GUANAJUATO",
    },
    {
      value: "DOCTOR MORA",
      name: "DOCTOR MORA",
      parent: "GUANAJUATO",
    },
    {
      value: "DOLORES HIDALGO CUNA DE LA INDEPENDENCIA NACIONAL",
      name: "DOLORES HIDALGO CUNA DE LA INDEPENDENCIA NACIONAL",
      parent: "GUANAJUATO",
    },
    {
      value: "GUANAJUATO",
      name: "GUANAJUATO",
      parent: "GUANAJUATO",
    },
    {
      value: "HUANÍMARO",
      name: "HUANÍMARO",
      parent: "GUANAJUATO",
    },
    {
      value: "IRAPUATO",
      name: "IRAPUATO",
      parent: "GUANAJUATO",
    },
    {
      value: "JARAL DEL PROGRESO",
      name: "JARAL DEL PROGRESO",
      parent: "GUANAJUATO",
    },
    {
      value: "JERÉCUARO",
      name: "JERÉCUARO",
      parent: "GUANAJUATO",
    },
    {
      value: "LEÓN",
      name: "LEÓN",
      parent: "GUANAJUATO",
    },
    {
      value: "MOROLEÓN",
      name: "MOROLEÓN",
      parent: "GUANAJUATO",
    },
    {
      value: "OCAMPO",
      name: "OCAMPO",
      parent: "GUANAJUATO",
    },
    {
      value: "PÉNJAMO",
      name: "PÉNJAMO",
      parent: "GUANAJUATO",
    },
    {
      value: "PUEBLO NUEVO",
      name: "PUEBLO NUEVO",
      parent: "GUANAJUATO",
    },
    {
      value: "PURÍSIMA DEL RINCÓN",
      name: "PURÍSIMA DEL RINCÓN",
      parent: "GUANAJUATO",
    },
    {
      value: "ROMITA",
      name: "ROMITA",
      parent: "GUANAJUATO",
    },
    {
      value: "SALAMANCA",
      name: "SALAMANCA",
      parent: "GUANAJUATO",
    },
    {
      value: "SALVATIERRA",
      name: "SALVATIERRA",
      parent: "GUANAJUATO",
    },
    {
      value: "SAN DIEGO DE LA UNIÓN",
      name: "SAN DIEGO DE LA UNIÓN",
      parent: "GUANAJUATO",
    },
    {
      value: "SAN FELIPE",
      name: "SAN FELIPE",
      parent: "GUANAJUATO",
    },
    {
      value: "SAN FRANCISCO DEL RINCÓN",
      name: "SAN FRANCISCO DEL RINCÓN",
      parent: "GUANAJUATO",
    },
    {
      value: "SAN JOSÉ ITURBIDE",
      name: "SAN JOSÉ ITURBIDE",
      parent: "GUANAJUATO",
    },
    {
      value: "SAN LUIS DE LA PAZ",
      name: "SAN LUIS DE LA PAZ",
      parent: "GUANAJUATO",
    },
    {
      value: "SANTA CATARINA",
      name: "SANTA CATARINA",
      parent: "GUANAJUATO",
    },
    {
      value: "SANTA CRUZ DE JUVENTINO ROSAS",
      name: "SANTA CRUZ DE JUVENTINO ROSAS",
      parent: "GUANAJUATO",
    },
    {
      value: "SANTIAGO MARAVATÍO",
      name: "SANTIAGO MARAVATÍO",
      parent: "GUANAJUATO",
    },
    {
      value: "SILAO DE LA VICTORIA",
      name: "SILAO DE LA VICTORIA",
      parent: "GUANAJUATO",
    },
    {
      value: "TARANDACUAO",
      name: "TARANDACUAO",
      parent: "GUANAJUATO",
    },
    {
      value: "TARIMORO",
      name: "TARIMORO",
      parent: "GUANAJUATO",
    },
    {
      value: "TIERRA BLANCA",
      name: "TIERRA BLANCA",
      parent: "GUANAJUATO",
    },
    {
      value: "URIANGATO",
      name: "URIANGATO",
      parent: "GUANAJUATO",
    },
    {
      value: "VALLE DE SANTIAGO",
      name: "VALLE DE SANTIAGO",
      parent: "GUANAJUATO",
    },
    {
      value: "VICTORIA",
      name: "VICTORIA",
      parent: "GUANAJUATO",
    },
    {
      value: "VILLAGRÁN",
      name: "VILLAGRÁN",
      parent: "GUANAJUATO",
    },
    {
      value: "XICHÚ",
      name: "XICHÚ",
      parent: "GUANAJUATO",
    },
    {
      value: "YURIRIA",
      name: "YURIRIA",
      parent: "GUANAJUATO",
    },
    {
      value: "ACAPULCO DE JUÁREZ",
      name: "ACAPULCO DE JUÁREZ",
      parent: "GUERRERO",
    },
    {
      value: "AHUACUOTZINGO",
      name: "AHUACUOTZINGO",
      parent: "GUERRERO",
    },
    {
      value: "AJUCHITLÁN DEL PROGRESO",
      name: "AJUCHITLÁN DEL PROGRESO",
      parent: "GUERRERO",
    },
    {
      value: "ALCOZAUCA DE GUERRERO",
      name: "ALCOZAUCA DE GUERRERO",
      parent: "GUERRERO",
    },
    {
      value: "ALPOYECA",
      name: "ALPOYECA",
      parent: "GUERRERO",
    },
    {
      value: "APAXTLA",
      name: "APAXTLA",
      parent: "GUERRERO",
    },
    {
      value: "ARCELIA",
      name: "ARCELIA",
      parent: "GUERRERO",
    },
    {
      value: "ATENANGO DEL RÍO",
      name: "ATENANGO DEL RÍO",
      parent: "GUERRERO",
    },
    {
      value: "ATLAMAJALCINGO DEL MONTE",
      name: "ATLAMAJALCINGO DEL MONTE",
      parent: "GUERRERO",
    },
    {
      value: "ATLIXTAC",
      name: "ATLIXTAC",
      parent: "GUERRERO",
    },
    {
      value: "ATOYAC DE ÁLVAREZ",
      name: "ATOYAC DE ÁLVAREZ",
      parent: "GUERRERO",
    },
    {
      value: "AYUTLA DE LOS LIBRES",
      name: "AYUTLA DE LOS LIBRES",
      parent: "GUERRERO",
    },
    {
      value: "AZOYÚ",
      name: "AZOYÚ",
      parent: "GUERRERO",
    },
    {
      value: "BENITO JUÁREZ",
      name: "BENITO JUÁREZ",
      parent: "GUERRERO",
    },
    {
      value: "BUENAVISTA DE CUÉLLAR",
      name: "BUENAVISTA DE CUÉLLAR",
      parent: "GUERRERO",
    },
    {
      value: "COAHUAYUTLA DE JOSÉ MARÍA IZAZAGA",
      name: "COAHUAYUTLA DE JOSÉ MARÍA IZAZAGA",
      parent: "GUERRERO",
    },
    {
      value: "COCULA",
      name: "COCULA",
      parent: "GUERRERO",
    },
    {
      value: "COPALA",
      name: "COPALA",
      parent: "GUERRERO",
    },
    {
      value: "COPALILLO",
      name: "COPALILLO",
      parent: "GUERRERO",
    },
    {
      value: "COPANATOYAC",
      name: "COPANATOYAC",
      parent: "GUERRERO",
    },
    {
      value: "COYUCA DE BENÍTEZ",
      name: "COYUCA DE BENÍTEZ",
      parent: "GUERRERO",
    },
    {
      value: "COYUCA DE CATALÁN",
      name: "COYUCA DE CATALÁN",
      parent: "GUERRERO",
    },
    {
      value: "CUAJINICUILAPA",
      name: "CUAJINICUILAPA",
      parent: "GUERRERO",
    },
    {
      value: "CUALÁC",
      name: "CUALÁC",
      parent: "GUERRERO",
    },
    {
      value: "CUAUTEPEC",
      name: "CUAUTEPEC",
      parent: "GUERRERO",
    },
    {
      value: "CUETZALA DEL PROGRESO",
      name: "CUETZALA DEL PROGRESO",
      parent: "GUERRERO",
    },
    {
      value: "CUTZAMALA DE PINZÓN",
      name: "CUTZAMALA DE PINZÓN",
      parent: "GUERRERO",
    },
    {
      value: "CHILAPA DE ÁLVAREZ",
      name: "CHILAPA DE ÁLVAREZ",
      parent: "GUERRERO",
    },
    {
      value: "CHILPANCINGO DE LOS BRAVO",
      name: "CHILPANCINGO DE LOS BRAVO",
      parent: "GUERRERO",
    },
    {
      value: "FLORENCIO VILLARREAL",
      name: "FLORENCIO VILLARREAL",
      parent: "GUERRERO",
    },
    {
      value: "GENERAL CANUTO A. NERI",
      name: "GENERAL CANUTO A. NERI",
      parent: "GUERRERO",
    },
    {
      value: "GENERAL HELIODORO CASTILLO",
      name: "GENERAL HELIODORO CASTILLO",
      parent: "GUERRERO",
    },
    {
      value: "HUAMUXTITLÁN",
      name: "HUAMUXTITLÁN",
      parent: "GUERRERO",
    },
    {
      value: "HUITZUCO DE LOS FIGUEROA",
      name: "HUITZUCO DE LOS FIGUEROA",
      parent: "GUERRERO",
    },
    {
      value: "IGUALA DE LA INDEPENDENCIA",
      name: "IGUALA DE LA INDEPENDENCIA",
      parent: "GUERRERO",
    },
    {
      value: "IGUALAPA",
      name: "IGUALAPA",
      parent: "GUERRERO",
    },
    {
      value: "IXCATEOPAN DE CUAUHTÉMOC",
      name: "IXCATEOPAN DE CUAUHTÉMOC",
      parent: "GUERRERO",
    },
    {
      value: "ZIHUATANEJO DE AZUETA",
      name: "ZIHUATANEJO DE AZUETA",
      parent: "GUERRERO",
    },
    {
      value: "JUAN R. ESCUDERO",
      name: "JUAN R. ESCUDERO",
      parent: "GUERRERO",
    },
    {
      value: "LEONARDO BRAVO",
      name: "LEONARDO BRAVO",
      parent: "GUERRERO",
    },
    {
      value: "MALINALTEPEC",
      name: "MALINALTEPEC",
      parent: "GUERRERO",
    },
    {
      value: "MÁRTIR DE CUILAPAN",
      name: "MÁRTIR DE CUILAPAN",
      parent: "GUERRERO",
    },
    {
      value: "METLATÓNOC",
      name: "METLATÓNOC",
      parent: "GUERRERO",
    },
    {
      value: "MOCHITLÁN",
      name: "MOCHITLÁN",
      parent: "GUERRERO",
    },
    {
      value: "OLINALÁ",
      name: "OLINALÁ",
      parent: "GUERRERO",
    },
    {
      value: "OMETEPEC",
      name: "OMETEPEC",
      parent: "GUERRERO",
    },
    {
      value: "PEDRO ASCENCIO ALQUISIRAS",
      name: "PEDRO ASCENCIO ALQUISIRAS",
      parent: "GUERRERO",
    },
    {
      value: "PETATLÁN",
      name: "PETATLÁN",
      parent: "GUERRERO",
    },
    {
      value: "PILCAYA",
      name: "PILCAYA",
      parent: "GUERRERO",
    },
    {
      value: "PUNGARABATO",
      name: "PUNGARABATO",
      parent: "GUERRERO",
    },
    {
      value: "QUECHULTENANGO",
      name: "QUECHULTENANGO",
      parent: "GUERRERO",
    },
    {
      value: "SAN LUIS ACATLÁN",
      name: "SAN LUIS ACATLÁN",
      parent: "GUERRERO",
    },
    {
      value: "SAN MARCOS",
      name: "SAN MARCOS",
      parent: "GUERRERO",
    },
    {
      value: "SAN MIGUEL TOTOLAPAN",
      name: "SAN MIGUEL TOTOLAPAN",
      parent: "GUERRERO",
    },
    {
      value: "TAXCO DE ALARCÓN",
      name: "TAXCO DE ALARCÓN",
      parent: "GUERRERO",
    },
    {
      value: "TECOANAPA",
      name: "TECOANAPA",
      parent: "GUERRERO",
    },
    {
      value: "TÉCPAN DE GALEANA",
      name: "TÉCPAN DE GALEANA",
      parent: "GUERRERO",
    },
    {
      value: "TELOLOAPAN",
      name: "TELOLOAPAN",
      parent: "GUERRERO",
    },
    {
      value: "TEPECOACUILCO DE TRUJANO",
      name: "TEPECOACUILCO DE TRUJANO",
      parent: "GUERRERO",
    },
    {
      value: "TETIPAC",
      name: "TETIPAC",
      parent: "GUERRERO",
    },
    {
      value: "TIXTLA DE GUERRERO",
      name: "TIXTLA DE GUERRERO",
      parent: "GUERRERO",
    },
    {
      value: "TLACOACHISTLAHUACA",
      name: "TLACOACHISTLAHUACA",
      parent: "GUERRERO",
    },
    {
      value: "TLACOAPA",
      name: "TLACOAPA",
      parent: "GUERRERO",
    },
    {
      value: "TLALCHAPA",
      name: "TLALCHAPA",
      parent: "GUERRERO",
    },
    {
      value: "TLALIXTAQUILLA DE MALDONADO",
      name: "TLALIXTAQUILLA DE MALDONADO",
      parent: "GUERRERO",
    },
    {
      value: "TLAPA DE COMONFORT",
      name: "TLAPA DE COMONFORT",
      parent: "GUERRERO",
    },
    {
      value: "TLAPEHUALA",
      name: "TLAPEHUALA",
      parent: "GUERRERO",
    },
    {
      value: "LA UNIÓN DE ISIDORO MONTES DE OCA",
      name: "LA UNIÓN DE ISIDORO MONTES DE OCA",
      parent: "GUERRERO",
    },
    {
      value: "XALPATLÁHUAC",
      name: "XALPATLÁHUAC",
      parent: "GUERRERO",
    },
    {
      value: "XOCHIHUEHUETLÁN",
      name: "XOCHIHUEHUETLÁN",
      parent: "GUERRERO",
    },
    {
      value: "XOCHISTLAHUACA",
      name: "XOCHISTLAHUACA",
      parent: "GUERRERO",
    },
    {
      value: "ZAPOTITLÁN TABLAS",
      name: "ZAPOTITLÁN TABLAS",
      parent: "GUERRERO",
    },
    {
      value: "ZIRÁNDARO",
      name: "ZIRÁNDARO",
      parent: "GUERRERO",
    },
    {
      value: "ZITLALA",
      name: "ZITLALA",
      parent: "GUERRERO",
    },
    {
      value: "EDUARDO NERI",
      name: "EDUARDO NERI",
      parent: "GUERRERO",
    },
    {
      value: "ACATEPEC",
      name: "ACATEPEC",
      parent: "GUERRERO",
    },
    {
      value: "MARQUELIA",
      name: "MARQUELIA",
      parent: "GUERRERO",
    },
    {
      value: "COCHOAPA EL GRANDE",
      name: "COCHOAPA EL GRANDE",
      parent: "GUERRERO",
    },
    {
      value: "JOSÉ JOAQUÍN DE HERRERA",
      name: "JOSÉ JOAQUÍN DE HERRERA",
      parent: "GUERRERO",
    },
    {
      value: "JUCHITÁN",
      name: "JUCHITÁN",
      parent: "GUERRERO",
    },
    {
      value: "ILIATENCO",
      name: "ILIATENCO",
      parent: "GUERRERO",
    },
    {
      value: "ACATLÁN",
      name: "ACATLÁN",
      parent: "HIDALGO",
    },
    {
      value: "ACAXOCHITLÁN",
      name: "ACAXOCHITLÁN",
      parent: "HIDALGO",
    },
    {
      value: "ACTOPAN",
      name: "ACTOPAN",
      parent: "HIDALGO",
    },
    {
      value: "AGUA BLANCA DE ITURBIDE",
      name: "AGUA BLANCA DE ITURBIDE",
      parent: "HIDALGO",
    },
    {
      value: "AJACUBA",
      name: "AJACUBA",
      parent: "HIDALGO",
    },
    {
      value: "ALFAJAYUCAN",
      name: "ALFAJAYUCAN",
      parent: "HIDALGO",
    },
    {
      value: "ALMOLOYA",
      name: "ALMOLOYA",
      parent: "HIDALGO",
    },
    {
      value: "APAN",
      name: "APAN",
      parent: "HIDALGO",
    },
    {
      value: "EL ARENAL",
      name: "EL ARENAL",
      parent: "HIDALGO",
    },
    {
      value: "ATITALAQUIA",
      name: "ATITALAQUIA",
      parent: "HIDALGO",
    },
    {
      value: "ATLAPEXCO",
      name: "ATLAPEXCO",
      parent: "HIDALGO",
    },
    {
      value: "ATOTONILCO EL GRANDE",
      name: "ATOTONILCO EL GRANDE",
      parent: "HIDALGO",
    },
    {
      value: "ATOTONILCO DE TULA",
      name: "ATOTONILCO DE TULA",
      parent: "HIDALGO",
    },
    {
      value: "CALNALI",
      name: "CALNALI",
      parent: "HIDALGO",
    },
    {
      value: "CARDONAL",
      name: "CARDONAL",
      parent: "HIDALGO",
    },
    {
      value: "CUAUTEPEC DE HINOJOSA",
      name: "CUAUTEPEC DE HINOJOSA",
      parent: "HIDALGO",
    },
    {
      value: "CHAPANTONGO",
      name: "CHAPANTONGO",
      parent: "HIDALGO",
    },
    {
      value: "CHAPULHUACÁN",
      name: "CHAPULHUACÁN",
      parent: "HIDALGO",
    },
    {
      value: "CHILCUAUTLA",
      name: "CHILCUAUTLA",
      parent: "HIDALGO",
    },
    {
      value: "ELOXOCHITLÁN",
      name: "ELOXOCHITLÁN",
      parent: "HIDALGO",
    },
    {
      value: "EMILIANO ZAPATA",
      name: "EMILIANO ZAPATA",
      parent: "HIDALGO",
    },
    {
      value: "EPAZOYUCAN",
      name: "EPAZOYUCAN",
      parent: "HIDALGO",
    },
    {
      value: "FRANCISCO I. MADERO",
      name: "FRANCISCO I. MADERO",
      parent: "HIDALGO",
    },
    {
      value: "HUASCA DE OCAMPO",
      name: "HUASCA DE OCAMPO",
      parent: "HIDALGO",
    },
    {
      value: "HUAUTLA",
      name: "HUAUTLA",
      parent: "HIDALGO",
    },
    {
      value: "HUAZALINGO",
      name: "HUAZALINGO",
      parent: "HIDALGO",
    },
    {
      value: "HUEHUETLA",
      name: "HUEHUETLA",
      parent: "HIDALGO",
    },
    {
      value: "HUEJUTLA DE REYES",
      name: "HUEJUTLA DE REYES",
      parent: "HIDALGO",
    },
    {
      value: "HUICHAPAN",
      name: "HUICHAPAN",
      parent: "HIDALGO",
    },
    {
      value: "IXMIQUILPAN",
      name: "IXMIQUILPAN",
      parent: "HIDALGO",
    },
    {
      value: "JACALA DE LEDEZMA",
      name: "JACALA DE LEDEZMA",
      parent: "HIDALGO",
    },
    {
      value: "JALTOCÁN",
      name: "JALTOCÁN",
      parent: "HIDALGO",
    },
    {
      value: "JUÁREZ HIDALGO",
      name: "JUÁREZ HIDALGO",
      parent: "HIDALGO",
    },
    {
      value: "LOLOTLA",
      name: "LOLOTLA",
      parent: "HIDALGO",
    },
    {
      value: "METEPEC",
      name: "METEPEC",
      parent: "HIDALGO",
    },
    {
      value: "SAN AGUSTÍN METZQUITITLÁN",
      name: "SAN AGUSTÍN METZQUITITLÁN",
      parent: "HIDALGO",
    },
    {
      value: "METZTITLÁN",
      name: "METZTITLÁN",
      parent: "HIDALGO",
    },
    {
      value: "MINERAL DEL CHICO",
      name: "MINERAL DEL CHICO",
      parent: "HIDALGO",
    },
    {
      value: "MINERAL DEL MONTE",
      name: "MINERAL DEL MONTE",
      parent: "HIDALGO",
    },
    {
      value: "LA MISIÓN",
      name: "LA MISIÓN",
      parent: "HIDALGO",
    },
    {
      value: "MIXQUIAHUALA DE JUÁREZ",
      name: "MIXQUIAHUALA DE JUÁREZ",
      parent: "HIDALGO",
    },
    {
      value: "MOLANGO DE ESCAMILLA",
      name: "MOLANGO DE ESCAMILLA",
      parent: "HIDALGO",
    },
    {
      value: "NICOLÁS FLORES",
      name: "NICOLÁS FLORES",
      parent: "HIDALGO",
    },
    {
      value: "NOPALA DE VILLAGRÁN",
      name: "NOPALA DE VILLAGRÁN",
      parent: "HIDALGO",
    },
    {
      value: "OMITLÁN DE JUÁREZ",
      name: "OMITLÁN DE JUÁREZ",
      parent: "HIDALGO",
    },
    {
      value: "SAN FELIPE ORIZATLÁN",
      name: "SAN FELIPE ORIZATLÁN",
      parent: "HIDALGO",
    },
    {
      value: "PACULA",
      name: "PACULA",
      parent: "HIDALGO",
    },
    {
      value: "PACHUCA DE SOTO",
      name: "PACHUCA DE SOTO",
      parent: "HIDALGO",
    },
    {
      value: "PISAFLORES",
      name: "PISAFLORES",
      parent: "HIDALGO",
    },
    {
      value: "PROGRESO DE OBREGÓN",
      name: "PROGRESO DE OBREGÓN",
      parent: "HIDALGO",
    },
    {
      value: "MINERAL DE LA REFORMA",
      name: "MINERAL DE LA REFORMA",
      parent: "HIDALGO",
    },
    {
      value: "SAN AGUSTÍN TLAXIACA",
      name: "SAN AGUSTÍN TLAXIACA",
      parent: "HIDALGO",
    },
    {
      value: "SAN BARTOLO TUTOTEPEC",
      name: "SAN BARTOLO TUTOTEPEC",
      parent: "HIDALGO",
    },
    {
      value: "SAN SALVADOR",
      name: "SAN SALVADOR",
      parent: "HIDALGO",
    },
    {
      value: "SANTIAGO DE ANAYA",
      name: "SANTIAGO DE ANAYA",
      parent: "HIDALGO",
    },
    {
      value: "SANTIAGO TULANTEPEC DE LUGO GUERRERO",
      name: "SANTIAGO TULANTEPEC DE LUGO GUERRERO",
      parent: "HIDALGO",
    },
    {
      value: "SINGUILUCAN",
      name: "SINGUILUCAN",
      parent: "HIDALGO",
    },
    {
      value: "TASQUILLO",
      name: "TASQUILLO",
      parent: "HIDALGO",
    },
    {
      value: "TECOZAUTLA",
      name: "TECOZAUTLA",
      parent: "HIDALGO",
    },
    {
      value: "TENANGO DE DORIA",
      name: "TENANGO DE DORIA",
      parent: "HIDALGO",
    },
    {
      value: "TEPEAPULCO",
      name: "TEPEAPULCO",
      parent: "HIDALGO",
    },
    {
      value: "TEPEHUACÁN DE GUERRERO",
      name: "TEPEHUACÁN DE GUERRERO",
      parent: "HIDALGO",
    },
    {
      value: "TEPEJI DEL RÍO DE OCAMPO",
      name: "TEPEJI DEL RÍO DE OCAMPO",
      parent: "HIDALGO",
    },
    {
      value: "TEPETITLÁN",
      name: "TEPETITLÁN",
      parent: "HIDALGO",
    },
    {
      value: "TETEPANGO",
      name: "TETEPANGO",
      parent: "HIDALGO",
    },
    {
      value: "VILLA DE TEZONTEPEC",
      name: "VILLA DE TEZONTEPEC",
      parent: "HIDALGO",
    },
    {
      value: "TEZONTEPEC DE ALDAMA",
      name: "TEZONTEPEC DE ALDAMA",
      parent: "HIDALGO",
    },
    {
      value: "TIANGUISTENGO",
      name: "TIANGUISTENGO",
      parent: "HIDALGO",
    },
    {
      value: "TIZAYUCA",
      name: "TIZAYUCA",
      parent: "HIDALGO",
    },
    {
      value: "TLAHUELILPAN",
      name: "TLAHUELILPAN",
      parent: "HIDALGO",
    },
    {
      value: "TLAHUILTEPA",
      name: "TLAHUILTEPA",
      parent: "HIDALGO",
    },
    {
      value: "TLANALAPA",
      name: "TLANALAPA",
      parent: "HIDALGO",
    },
    {
      value: "TLANCHINOL",
      name: "TLANCHINOL",
      parent: "HIDALGO",
    },
    {
      value: "TLAXCOAPAN",
      name: "TLAXCOAPAN",
      parent: "HIDALGO",
    },
    {
      value: "TOLCAYUCA",
      name: "TOLCAYUCA",
      parent: "HIDALGO",
    },
    {
      value: "TULA DE ALLENDE",
      name: "TULA DE ALLENDE",
      parent: "HIDALGO",
    },
    {
      value: "TULANCINGO DE BRAVO",
      name: "TULANCINGO DE BRAVO",
      parent: "HIDALGO",
    },
    {
      value: "XOCHIATIPAN",
      name: "XOCHIATIPAN",
      parent: "HIDALGO",
    },
    {
      value: "XOCHICOATLÁN",
      name: "XOCHICOATLÁN",
      parent: "HIDALGO",
    },
    {
      value: "YAHUALICA",
      name: "YAHUALICA",
      parent: "HIDALGO",
    },
    {
      value: "ZACUALTIPÁN DE ÁNGELES",
      name: "ZACUALTIPÁN DE ÁNGELES",
      parent: "HIDALGO",
    },
    {
      value: "ZAPOTLÁN DE JUÁREZ",
      name: "ZAPOTLÁN DE JUÁREZ",
      parent: "HIDALGO",
    },
    {
      value: "ZEMPOALA",
      name: "ZEMPOALA",
      parent: "HIDALGO",
    },
    {
      value: "ZIMAPÁN",
      name: "ZIMAPÁN",
      parent: "HIDALGO",
    },
    {
      value: "ACATIC",
      name: "ACATIC",
      parent: "JALISCO",
    },
    {
      value: "ACATLÁN DE JUÁREZ",
      name: "ACATLÁN DE JUÁREZ",
      parent: "JALISCO",
    },
    {
      value: "AHUALULCO DE MERCADO",
      name: "AHUALULCO DE MERCADO",
      parent: "JALISCO",
    },
    {
      value: "AMACUECA",
      name: "AMACUECA",
      parent: "JALISCO",
    },
    {
      value: "AMATITÁN",
      name: "AMATITÁN",
      parent: "JALISCO",
    },
    {
      value: "AMECA",
      name: "AMECA",
      parent: "JALISCO",
    },
    {
      value: "SAN JUANITO DE ESCOBEDO",
      name: "SAN JUANITO DE ESCOBEDO",
      parent: "JALISCO",
    },
    {
      value: "ARANDAS",
      name: "ARANDAS",
      parent: "JALISCO",
    },
    {
      value: "EL ARENAL",
      name: "EL ARENAL",
      parent: "JALISCO",
    },
    {
      value: "ATEMAJAC DE BRIZUELA",
      name: "ATEMAJAC DE BRIZUELA",
      parent: "JALISCO",
    },
    {
      value: "ATENGO",
      name: "ATENGO",
      parent: "JALISCO",
    },
    {
      value: "ATENGUILLO",
      name: "ATENGUILLO",
      parent: "JALISCO",
    },
    {
      value: "ATOTONILCO EL ALTO",
      name: "ATOTONILCO EL ALTO",
      parent: "JALISCO",
    },
    {
      value: "ATOYAC",
      name: "ATOYAC",
      parent: "JALISCO",
    },
    {
      value: "AUTLÁN DE NAVARRO",
      name: "AUTLÁN DE NAVARRO",
      parent: "JALISCO",
    },
    {
      value: "AYOTLÁN",
      name: "AYOTLÁN",
      parent: "JALISCO",
    },
    {
      value: "AYUTLA",
      name: "AYUTLA",
      parent: "JALISCO",
    },
    {
      value: "LA BARCA",
      name: "LA BARCA",
      parent: "JALISCO",
    },
    {
      value: "BOLAÑOS",
      name: "BOLAÑOS",
      parent: "JALISCO",
    },
    {
      value: "CABO CORRIENTES",
      name: "CABO CORRIENTES",
      parent: "JALISCO",
    },
    {
      value: "CASIMIRO CASTILLO",
      name: "CASIMIRO CASTILLO",
      parent: "JALISCO",
    },
    {
      value: "CIHUATLÁN",
      name: "CIHUATLÁN",
      parent: "JALISCO",
    },
    {
      value: "ZAPOTLÁN EL GRANDE",
      name: "ZAPOTLÁN EL GRANDE",
      parent: "JALISCO",
    },
    {
      value: "COCULA",
      name: "COCULA",
      parent: "JALISCO",
    },
    {
      value: "COLOTLÁN",
      name: "COLOTLÁN",
      parent: "JALISCO",
    },
    {
      value: "CONCEPCIÓN DE BUENOS AIRES",
      name: "CONCEPCIÓN DE BUENOS AIRES",
      parent: "JALISCO",
    },
    {
      value: "CUAUTITLÁN DE GARCÍA BARRAGÁN",
      name: "CUAUTITLÁN DE GARCÍA BARRAGÁN",
      parent: "JALISCO",
    },
    {
      value: "CUAUTLA",
      name: "CUAUTLA",
      parent: "JALISCO",
    },
    {
      value: "CUQUÍO",
      name: "CUQUÍO",
      parent: "JALISCO",
    },
    {
      value: "CHAPALA",
      name: "CHAPALA",
      parent: "JALISCO",
    },
    {
      value: "CHIMALTITÁN",
      name: "CHIMALTITÁN",
      parent: "JALISCO",
    },
    {
      value: "CHIQUILISTLÁN",
      name: "CHIQUILISTLÁN",
      parent: "JALISCO",
    },
    {
      value: "DEGOLLADO",
      name: "DEGOLLADO",
      parent: "JALISCO",
    },
    {
      value: "EJUTLA",
      name: "EJUTLA",
      parent: "JALISCO",
    },
    {
      value: "ENCARNACIÓN DE DÍAZ",
      name: "ENCARNACIÓN DE DÍAZ",
      parent: "JALISCO",
    },
    {
      value: "ETZATLÁN",
      name: "ETZATLÁN",
      parent: "JALISCO",
    },
    {
      value: "EL GRULLO",
      name: "EL GRULLO",
      parent: "JALISCO",
    },
    {
      value: "GUACHINANGO",
      name: "GUACHINANGO",
      parent: "JALISCO",
    },
    {
      value: "GUADALAJARA",
      name: "GUADALAJARA",
      parent: "JALISCO",
    },
    {
      value: "HOSTOTIPAQUILLO",
      name: "HOSTOTIPAQUILLO",
      parent: "JALISCO",
    },
    {
      value: "HUEJÚCAR",
      name: "HUEJÚCAR",
      parent: "JALISCO",
    },
    {
      value: "HUEJUQUILLA EL ALTO",
      name: "HUEJUQUILLA EL ALTO",
      parent: "JALISCO",
    },
    {
      value: "LA HUERTA",
      name: "LA HUERTA",
      parent: "JALISCO",
    },
    {
      value: "IXTLAHUACÁN DE LOS MEMBRILLOS",
      name: "IXTLAHUACÁN DE LOS MEMBRILLOS",
      parent: "JALISCO",
    },
    {
      value: "IXTLAHUACÁN DEL RÍO",
      name: "IXTLAHUACÁN DEL RÍO",
      parent: "JALISCO",
    },
    {
      value: "JALOSTOTITLÁN",
      name: "JALOSTOTITLÁN",
      parent: "JALISCO",
    },
    {
      value: "JAMAY",
      name: "JAMAY",
      parent: "JALISCO",
    },
    {
      value: "JESÚS MARÍA",
      name: "JESÚS MARÍA",
      parent: "JALISCO",
    },
    {
      value: "JILOTLÁN DE LOS DOLORES",
      name: "JILOTLÁN DE LOS DOLORES",
      parent: "JALISCO",
    },
    {
      value: "JOCOTEPEC",
      name: "JOCOTEPEC",
      parent: "JALISCO",
    },
    {
      value: "JUANACATLÁN",
      name: "JUANACATLÁN",
      parent: "JALISCO",
    },
    {
      value: "JUCHITLÁN",
      name: "JUCHITLÁN",
      parent: "JALISCO",
    },
    {
      value: "LAGOS DE MORENO",
      name: "LAGOS DE MORENO",
      parent: "JALISCO",
    },
    {
      value: "EL LIMÓN",
      name: "EL LIMÓN",
      parent: "JALISCO",
    },
    {
      value: "MAGDALENA",
      name: "MAGDALENA",
      parent: "JALISCO",
    },
    {
      value: "SANTA MARÍA DEL ORO",
      name: "SANTA MARÍA DEL ORO",
      parent: "JALISCO",
    },
    {
      value: "LA MANZANILLA DE LA PAZ",
      name: "LA MANZANILLA DE LA PAZ",
      parent: "JALISCO",
    },
    {
      value: "MASCOTA",
      name: "MASCOTA",
      parent: "JALISCO",
    },
    {
      value: "MAZAMITLA",
      name: "MAZAMITLA",
      parent: "JALISCO",
    },
    {
      value: "MEXTICACÁN",
      name: "MEXTICACÁN",
      parent: "JALISCO",
    },
    {
      value: "MEZQUITIC",
      name: "MEZQUITIC",
      parent: "JALISCO",
    },
    {
      value: "MIXTLÁN",
      name: "MIXTLÁN",
      parent: "JALISCO",
    },
    {
      value: "OCOTLÁN",
      name: "OCOTLÁN",
      parent: "JALISCO",
    },
    {
      value: "OJUELOS DE JALISCO",
      name: "OJUELOS DE JALISCO",
      parent: "JALISCO",
    },
    {
      value: "PIHUAMO",
      name: "PIHUAMO",
      parent: "JALISCO",
    },
    {
      value: "PONCITLÁN",
      name: "PONCITLÁN",
      parent: "JALISCO",
    },
    {
      value: "PUERTO VALLARTA",
      name: "PUERTO VALLARTA",
      parent: "JALISCO",
    },
    {
      value: "VILLA PURIFICACIÓN",
      name: "VILLA PURIFICACIÓN",
      parent: "JALISCO",
    },
    {
      value: "QUITUPAN",
      name: "QUITUPAN",
      parent: "JALISCO",
    },
    {
      value: "EL SALTO",
      name: "EL SALTO",
      parent: "JALISCO",
    },
    {
      value: "SAN CRISTÓBAL DE LA BARRANCA",
      name: "SAN CRISTÓBAL DE LA BARRANCA",
      parent: "JALISCO",
    },
    {
      value: "SAN DIEGO DE ALEJANDRÍA",
      name: "SAN DIEGO DE ALEJANDRÍA",
      parent: "JALISCO",
    },
    {
      value: "SAN JUAN DE LOS LAGOS",
      name: "SAN JUAN DE LOS LAGOS",
      parent: "JALISCO",
    },
    {
      value: "SAN JULIÁN",
      name: "SAN JULIÁN",
      parent: "JALISCO",
    },
    {
      value: "SAN MARCOS",
      name: "SAN MARCOS",
      parent: "JALISCO",
    },
    {
      value: "SAN MARTÍN DE BOLAÑOS",
      name: "SAN MARTÍN DE BOLAÑOS",
      parent: "JALISCO",
    },
    {
      value: "SAN MARTÍN HIDALGO",
      name: "SAN MARTÍN HIDALGO",
      parent: "JALISCO",
    },
    {
      value: "SAN MIGUEL EL ALTO",
      name: "SAN MIGUEL EL ALTO",
      parent: "JALISCO",
    },
    {
      value: "GÓMEZ FARÍAS",
      name: "GÓMEZ FARÍAS",
      parent: "JALISCO",
    },
    {
      value: "SAN SEBASTIÁN DEL OESTE",
      name: "SAN SEBASTIÁN DEL OESTE",
      parent: "JALISCO",
    },
    {
      value: "SANTA MARÍA DE LOS ÁNGELES",
      name: "SANTA MARÍA DE LOS ÁNGELES",
      parent: "JALISCO",
    },
    {
      value: "SAYULA",
      name: "SAYULA",
      parent: "JALISCO",
    },
    {
      value: "TALA",
      name: "TALA",
      parent: "JALISCO",
    },
    {
      value: "TALPA DE ALLENDE",
      name: "TALPA DE ALLENDE",
      parent: "JALISCO",
    },
    {
      value: "TAMAZULA DE GORDIANO",
      name: "TAMAZULA DE GORDIANO",
      parent: "JALISCO",
    },
    {
      value: "TAPALPA",
      name: "TAPALPA",
      parent: "JALISCO",
    },
    {
      value: "TECALITLÁN",
      name: "TECALITLÁN",
      parent: "JALISCO",
    },
    {
      value: "TECOLOTLÁN",
      name: "TECOLOTLÁN",
      parent: "JALISCO",
    },
    {
      value: "TECHALUTA DE MONTENEGRO",
      name: "TECHALUTA DE MONTENEGRO",
      parent: "JALISCO",
    },
    {
      value: "TENAMAXTLÁN",
      name: "TENAMAXTLÁN",
      parent: "JALISCO",
    },
    {
      value: "TEOCALTICHE",
      name: "TEOCALTICHE",
      parent: "JALISCO",
    },
    {
      value: "TEOCUITATLÁN DE CORONA",
      name: "TEOCUITATLÁN DE CORONA",
      parent: "JALISCO",
    },
    {
      value: "TEPATITLÁN DE MORELOS",
      name: "TEPATITLÁN DE MORELOS",
      parent: "JALISCO",
    },
    {
      value: "TEQUILA",
      name: "TEQUILA",
      parent: "JALISCO",
    },
    {
      value: "TEUCHITLÁN",
      name: "TEUCHITLÁN",
      parent: "JALISCO",
    },
    {
      value: "TIZAPÁN EL ALTO",
      name: "TIZAPÁN EL ALTO",
      parent: "JALISCO",
    },
    {
      value: "TLAJOMULCO DE ZÚÑIGA",
      name: "TLAJOMULCO DE ZÚÑIGA",
      parent: "JALISCO",
    },
    {
      value: "SAN PEDRO TLAQUEPAQUE",
      name: "SAN PEDRO TLAQUEPAQUE",
      parent: "JALISCO",
    },
    {
      value: "TOLIMÁN",
      name: "TOLIMÁN",
      parent: "JALISCO",
    },
    {
      value: "TOMATLÁN",
      name: "TOMATLÁN",
      parent: "JALISCO",
    },
    {
      value: "TONALÁ",
      name: "TONALÁ",
      parent: "JALISCO",
    },
    {
      value: "TONAYA",
      name: "TONAYA",
      parent: "JALISCO",
    },
    {
      value: "TONILA",
      name: "TONILA",
      parent: "JALISCO",
    },
    {
      value: "TOTATICHE",
      name: "TOTATICHE",
      parent: "JALISCO",
    },
    {
      value: "TOTOTLÁN",
      name: "TOTOTLÁN",
      parent: "JALISCO",
    },
    {
      value: "TUXCACUESCO",
      name: "TUXCACUESCO",
      parent: "JALISCO",
    },
    {
      value: "TUXCUECA",
      name: "TUXCUECA",
      parent: "JALISCO",
    },
    {
      value: "TUXPAN",
      name: "TUXPAN",
      parent: "JALISCO",
    },
    {
      value: "UNIÓN DE SAN ANTONIO",
      name: "UNIÓN DE SAN ANTONIO",
      parent: "JALISCO",
    },
    {
      value: "UNIÓN DE TULA",
      name: "UNIÓN DE TULA",
      parent: "JALISCO",
    },
    {
      value: "VALLE DE GUADALUPE",
      name: "VALLE DE GUADALUPE",
      parent: "JALISCO",
    },
    {
      value: "VALLE DE JUÁREZ",
      name: "VALLE DE JUÁREZ",
      parent: "JALISCO",
    },
    {
      value: "SAN GABRIEL",
      name: "SAN GABRIEL",
      parent: "JALISCO",
    },
    {
      value: "VILLA CORONA",
      name: "VILLA CORONA",
      parent: "JALISCO",
    },
    {
      value: "VILLA GUERRERO",
      name: "VILLA GUERRERO",
      parent: "JALISCO",
    },
    {
      value: "VILLA HIDALGO",
      name: "VILLA HIDALGO",
      parent: "JALISCO",
    },
    {
      value: "CAÑADAS DE OBREGÓN",
      name: "CAÑADAS DE OBREGÓN",
      parent: "JALISCO",
    },
    {
      value: "YAHUALICA DE GONZÁLEZ GALLO",
      name: "YAHUALICA DE GONZÁLEZ GALLO",
      parent: "JALISCO",
    },
    {
      value: "ZACOALCO DE TORRES",
      name: "ZACOALCO DE TORRES",
      parent: "JALISCO",
    },
    {
      value: "ZAPOPAN",
      name: "ZAPOPAN",
      parent: "JALISCO",
    },
    {
      value: "ZAPOTILTIC",
      name: "ZAPOTILTIC",
      parent: "JALISCO",
    },
    {
      value: "ZAPOTITLÁN DE VADILLO",
      name: "ZAPOTITLÁN DE VADILLO",
      parent: "JALISCO",
    },
    {
      value: "ZAPOTLÁN DEL REY",
      name: "ZAPOTLÁN DEL REY",
      parent: "JALISCO",
    },
    {
      value: "ZAPOTLANEJO",
      name: "ZAPOTLANEJO",
      parent: "JALISCO",
    },
    {
      value: "SAN IGNACIO CERRO GORDO",
      name: "SAN IGNACIO CERRO GORDO",
      parent: "JALISCO",
    },
    {
      value: "ACAMBAY DE RUÍZ CASTAÑEDA",
      name: "ACAMBAY DE RUÍZ CASTAÑEDA",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "ACOLMAN",
      name: "ACOLMAN",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "ACULCO",
      name: "ACULCO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "ALMOLOYA DE ALQUISIRAS",
      name: "ALMOLOYA DE ALQUISIRAS",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "ALMOLOYA DE JUÁREZ",
      name: "ALMOLOYA DE JUÁREZ",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "ALMOLOYA DEL RÍO",
      name: "ALMOLOYA DEL RÍO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "AMANALCO",
      name: "AMANALCO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "AMATEPEC",
      name: "AMATEPEC",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "AMECAMECA",
      name: "AMECAMECA",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "APAXCO",
      name: "APAXCO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "ATENCO",
      name: "ATENCO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "ATIZAPÁN",
      name: "ATIZAPÁN",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "ATIZAPÁN DE ZARAGOZA",
      name: "ATIZAPÁN DE ZARAGOZA",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "ATLACOMULCO",
      name: "ATLACOMULCO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "ATLAUTLA",
      name: "ATLAUTLA",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "AXAPUSCO",
      name: "AXAPUSCO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "AYAPANGO",
      name: "AYAPANGO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "CALIMAYA",
      name: "CALIMAYA",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "CAPULHUAC",
      name: "CAPULHUAC",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "COACALCO DE BERRIOZÁBAL",
      name: "COACALCO DE BERRIOZÁBAL",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "COATEPEC HARINAS",
      name: "COATEPEC HARINAS",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "COCOTITLÁN",
      name: "COCOTITLÁN",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "COYOTEPEC",
      name: "COYOTEPEC",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "CUAUTITLÁN",
      name: "CUAUTITLÁN",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "CHALCO",
      name: "CHALCO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "CHAPA DE MOTA",
      name: "CHAPA DE MOTA",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "CHAPULTEPEC",
      name: "CHAPULTEPEC",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "CHIAUTLA",
      name: "CHIAUTLA",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "CHICOLOAPAN",
      name: "CHICOLOAPAN",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "CHICONCUAC",
      name: "CHICONCUAC",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "CHIMALHUACÁN",
      name: "CHIMALHUACÁN",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "DONATO GUERRA",
      name: "DONATO GUERRA",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "ECATEPEC DE MORELOS",
      name: "ECATEPEC DE MORELOS",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "ECATZINGO",
      name: "ECATZINGO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "HUEHUETOCA",
      name: "HUEHUETOCA",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "HUEYPOXTLA",
      name: "HUEYPOXTLA",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "HUIXQUILUCAN",
      name: "HUIXQUILUCAN",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "ISIDRO FABELA",
      name: "ISIDRO FABELA",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "IXTAPALUCA",
      name: "IXTAPALUCA",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "IXTAPAN DE LA SAL",
      name: "IXTAPAN DE LA SAL",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "IXTAPAN DEL ORO",
      name: "IXTAPAN DEL ORO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "IXTLAHUACA",
      name: "IXTLAHUACA",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "XALATLACO",
      name: "XALATLACO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "JALTENCO",
      name: "JALTENCO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "JILOTEPEC",
      name: "JILOTEPEC",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "JILOTZINGO",
      name: "JILOTZINGO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "JIQUIPILCO",
      name: "JIQUIPILCO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "JOCOTITLÁN",
      name: "JOCOTITLÁN",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "JOQUICINGO",
      name: "JOQUICINGO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "JUCHITEPEC",
      name: "JUCHITEPEC",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "LERMA",
      name: "LERMA",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "MALINALCO",
      name: "MALINALCO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "MELCHOR OCAMPO",
      name: "MELCHOR OCAMPO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "METEPEC",
      name: "METEPEC",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "MEXICALTZINGO",
      name: "MEXICALTZINGO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "MORELOS",
      name: "MORELOS",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "NAUCALPAN DE JUÁREZ",
      name: "NAUCALPAN DE JUÁREZ",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "NEZAHUALCÓYOTL",
      name: "NEZAHUALCÓYOTL",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "NEXTLALPAN",
      name: "NEXTLALPAN",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "NICOLÁS ROMERO",
      name: "NICOLÁS ROMERO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "NOPALTEPEC",
      name: "NOPALTEPEC",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "OCOYOACAC",
      name: "OCOYOACAC",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "OCUILAN",
      name: "OCUILAN",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "EL ORO",
      name: "EL ORO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "OTUMBA",
      name: "OTUMBA",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "OTZOLOAPAN",
      name: "OTZOLOAPAN",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "OTZOLOTEPEC",
      name: "OTZOLOTEPEC",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "OZUMBA",
      name: "OZUMBA",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "PAPALOTLA",
      name: "PAPALOTLA",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "LA PAZ",
      name: "LA PAZ",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "POLOTITLÁN",
      name: "POLOTITLÁN",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "RAYÓN",
      name: "RAYÓN",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "SAN ANTONIO LA ISLA",
      name: "SAN ANTONIO LA ISLA",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "SAN FELIPE DEL PROGRESO",
      name: "SAN FELIPE DEL PROGRESO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "SAN MARTÍN DE LAS PIRÁMIDES",
      name: "SAN MARTÍN DE LAS PIRÁMIDES",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "SAN MATEO ATENCO",
      name: "SAN MATEO ATENCO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "SAN SIMÓN DE GUERRERO",
      name: "SAN SIMÓN DE GUERRERO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "SANTO TOMÁS",
      name: "SANTO TOMÁS",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "SOYANIQUILPAN DE JUÁREZ",
      name: "SOYANIQUILPAN DE JUÁREZ",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "SULTEPEC",
      name: "SULTEPEC",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TECÁMAC",
      name: "TECÁMAC",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TEJUPILCO",
      name: "TEJUPILCO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TEMAMATLA",
      name: "TEMAMATLA",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TEMASCALAPA",
      name: "TEMASCALAPA",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TEMASCALCINGO",
      name: "TEMASCALCINGO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TEMASCALTEPEC",
      name: "TEMASCALTEPEC",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TEMOAYA",
      name: "TEMOAYA",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TENANCINGO",
      name: "TENANCINGO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TENANGO DEL AIRE",
      name: "TENANGO DEL AIRE",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TENANGO DEL VALLE",
      name: "TENANGO DEL VALLE",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TEOLOYUCAN",
      name: "TEOLOYUCAN",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TEOTIHUACÁN",
      name: "TEOTIHUACÁN",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TEPETLAOXTOC",
      name: "TEPETLAOXTOC",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TEPETLIXPA",
      name: "TEPETLIXPA",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TEPOTZOTLÁN",
      name: "TEPOTZOTLÁN",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TEQUIXQUIAC",
      name: "TEQUIXQUIAC",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TEXCALTITLÁN",
      name: "TEXCALTITLÁN",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TEXCALYACAC",
      name: "TEXCALYACAC",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TEXCOCO",
      name: "TEXCOCO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TEZOYUCA",
      name: "TEZOYUCA",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TIANGUISTENCO",
      name: "TIANGUISTENCO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TIMILPAN",
      name: "TIMILPAN",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TLALMANALCO",
      name: "TLALMANALCO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TLALNEPANTLA DE BAZ",
      name: "TLALNEPANTLA DE BAZ",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TLATLAYA",
      name: "TLATLAYA",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TOLUCA",
      name: "TOLUCA",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TONATICO",
      name: "TONATICO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TULTEPEC",
      name: "TULTEPEC",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TULTITLÁN",
      name: "TULTITLÁN",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "VALLE DE BRAVO",
      name: "VALLE DE BRAVO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "VILLA DE ALLENDE",
      name: "VILLA DE ALLENDE",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "VILLA DEL CARBÓN",
      name: "VILLA DEL CARBÓN",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "VILLA GUERRERO",
      name: "VILLA GUERRERO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "VILLA VICTORIA",
      name: "VILLA VICTORIA",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "XONACATLÁN",
      name: "XONACATLÁN",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "ZACAZONAPAN",
      name: "ZACAZONAPAN",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "ZACUALPAN",
      name: "ZACUALPAN",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "ZINACANTEPEC",
      name: "ZINACANTEPEC",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "ZUMPAHUACÁN",
      name: "ZUMPAHUACÁN",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "ZUMPANGO",
      name: "ZUMPANGO",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "CUAUTITLÁN IZCALLI",
      name: "CUAUTITLÁN IZCALLI",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "VALLE DE CHALCO SOLIDARIDAD",
      name: "VALLE DE CHALCO SOLIDARIDAD",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "LUVIANOS",
      name: "LUVIANOS",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "SAN JOSÉ DEL RINCÓN",
      name: "SAN JOSÉ DEL RINCÓN",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "TONANITLA",
      name: "TONANITLA",
      parent: "ESTADO DE MÉXICO",
    },
    {
      value: "ACUITZIO",
      name: "ACUITZIO",
      parent: "MICHOACÁN",
    },
    {
      value: "AGUILILLA",
      name: "AGUILILLA",
      parent: "MICHOACÁN",
    },
    {
      value: "ÁLVARO OBREGÓN",
      name: "ÁLVARO OBREGÓN",
      parent: "MICHOACÁN",
    },
    {
      value: "ANGAMACUTIRO",
      name: "ANGAMACUTIRO",
      parent: "MICHOACÁN",
    },
    {
      value: "ANGANGUEO",
      name: "ANGANGUEO",
      parent: "MICHOACÁN",
    },
    {
      value: "APATZINGÁN",
      name: "APATZINGÁN",
      parent: "MICHOACÁN",
    },
    {
      value: "APORO",
      name: "APORO",
      parent: "MICHOACÁN",
    },
    {
      value: "AQUILA",
      name: "AQUILA",
      parent: "MICHOACÁN",
    },
    {
      value: "ARIO",
      name: "ARIO",
      parent: "MICHOACÁN",
    },
    {
      value: "ARTEAGA",
      name: "ARTEAGA",
      parent: "MICHOACÁN",
    },
    {
      value: "BRISEÑAS",
      name: "BRISEÑAS",
      parent: "MICHOACÁN",
    },
    {
      value: "BUENAVISTA",
      name: "BUENAVISTA",
      parent: "MICHOACÁN",
    },
    {
      value: "CARÁCUARO",
      name: "CARÁCUARO",
      parent: "MICHOACÁN",
    },
    {
      value: "COAHUAYANA",
      name: "COAHUAYANA",
      parent: "MICHOACÁN",
    },
    {
      value: "COALCOMÁN DE VÁZQUEZ PALLARES",
      name: "COALCOMÁN DE VÁZQUEZ PALLARES",
      parent: "MICHOACÁN",
    },
    {
      value: "COENEO",
      name: "COENEO",
      parent: "MICHOACÁN",
    },
    {
      value: "CONTEPEC",
      name: "CONTEPEC",
      parent: "MICHOACÁN",
    },
    {
      value: "COPÁNDARO",
      name: "COPÁNDARO",
      parent: "MICHOACÁN",
    },
    {
      value: "COTIJA",
      name: "COTIJA",
      parent: "MICHOACÁN",
    },
    {
      value: "CUITZEO",
      name: "CUITZEO",
      parent: "MICHOACÁN",
    },
    {
      value: "CHARAPAN",
      name: "CHARAPAN",
      parent: "MICHOACÁN",
    },
    {
      value: "CHARO",
      name: "CHARO",
      parent: "MICHOACÁN",
    },
    {
      value: "CHAVINDA",
      name: "CHAVINDA",
      parent: "MICHOACÁN",
    },
    {
      value: "CHERÁN",
      name: "CHERÁN",
      parent: "MICHOACÁN",
    },
    {
      value: "CHILCHOTA",
      name: "CHILCHOTA",
      parent: "MICHOACÁN",
    },
    {
      value: "CHINICUILA",
      name: "CHINICUILA",
      parent: "MICHOACÁN",
    },
    {
      value: "CHUCÁNDIRO",
      name: "CHUCÁNDIRO",
      parent: "MICHOACÁN",
    },
    {
      value: "CHURINTZIO",
      name: "CHURINTZIO",
      parent: "MICHOACÁN",
    },
    {
      value: "CHURUMUCO",
      name: "CHURUMUCO",
      parent: "MICHOACÁN",
    },
    {
      value: "ECUANDUREO",
      name: "ECUANDUREO",
      parent: "MICHOACÁN",
    },
    {
      value: "EPITACIO HUERTA",
      name: "EPITACIO HUERTA",
      parent: "MICHOACÁN",
    },
    {
      value: "ERONGARÍCUARO",
      name: "ERONGARÍCUARO",
      parent: "MICHOACÁN",
    },
    {
      value: "GABRIEL ZAMORA",
      name: "GABRIEL ZAMORA",
      parent: "MICHOACÁN",
    },
    {
      value: "HIDALGO",
      name: "HIDALGO",
      parent: "MICHOACÁN",
    },
    {
      value: "LA HUACANA",
      name: "LA HUACANA",
      parent: "MICHOACÁN",
    },
    {
      value: "HUANDACAREO",
      name: "HUANDACAREO",
      parent: "MICHOACÁN",
    },
    {
      value: "HUANIQUEO",
      name: "HUANIQUEO",
      parent: "MICHOACÁN",
    },
    {
      value: "HUETAMO",
      name: "HUETAMO",
      parent: "MICHOACÁN",
    },
    {
      value: "HUIRAMBA",
      name: "HUIRAMBA",
      parent: "MICHOACÁN",
    },
    {
      value: "INDAPARAPEO",
      name: "INDAPARAPEO",
      parent: "MICHOACÁN",
    },
    {
      value: "IRIMBO",
      name: "IRIMBO",
      parent: "MICHOACÁN",
    },
    {
      value: "IXTLÁN",
      name: "IXTLÁN",
      parent: "MICHOACÁN",
    },
    {
      value: "JACONA",
      name: "JACONA",
      parent: "MICHOACÁN",
    },
    {
      value: "JIMÉNEZ",
      name: "JIMÉNEZ",
      parent: "MICHOACÁN",
    },
    {
      value: "JIQUILPAN",
      name: "JIQUILPAN",
      parent: "MICHOACÁN",
    },
    {
      value: "JUÁREZ",
      name: "JUÁREZ",
      parent: "MICHOACÁN",
    },
    {
      value: "JUNGAPEO",
      name: "JUNGAPEO",
      parent: "MICHOACÁN",
    },
    {
      value: "LAGUNILLAS",
      name: "LAGUNILLAS",
      parent: "MICHOACÁN",
    },
    {
      value: "MADERO",
      name: "MADERO",
      parent: "MICHOACÁN",
    },
    {
      value: "MARAVATÍO",
      name: "MARAVATÍO",
      parent: "MICHOACÁN",
    },
    {
      value: "MARCOS CASTELLANOS",
      name: "MARCOS CASTELLANOS",
      parent: "MICHOACÁN",
    },
    {
      value: "LÁZARO CÁRDENAS",
      name: "LÁZARO CÁRDENAS",
      parent: "MICHOACÁN",
    },
    {
      value: "MORELIA",
      name: "MORELIA",
      parent: "MICHOACÁN",
    },
    {
      value: "MORELOS",
      name: "MORELOS",
      parent: "MICHOACÁN",
    },
    {
      value: "MÚGICA",
      name: "MÚGICA",
      parent: "MICHOACÁN",
    },
    {
      value: "NAHUATZEN",
      name: "NAHUATZEN",
      parent: "MICHOACÁN",
    },
    {
      value: "NOCUPÉTARO",
      name: "NOCUPÉTARO",
      parent: "MICHOACÁN",
    },
    {
      value: "NUEVO PARANGARICUTIRO",
      name: "NUEVO PARANGARICUTIRO",
      parent: "MICHOACÁN",
    },
    {
      value: "NUEVO URECHO",
      name: "NUEVO URECHO",
      parent: "MICHOACÁN",
    },
    {
      value: "NUMARÁN",
      name: "NUMARÁN",
      parent: "MICHOACÁN",
    },
    {
      value: "OCAMPO",
      name: "OCAMPO",
      parent: "MICHOACÁN",
    },
    {
      value: "PAJACUARÁN",
      name: "PAJACUARÁN",
      parent: "MICHOACÁN",
    },
    {
      value: "PANINDÍCUARO",
      name: "PANINDÍCUARO",
      parent: "MICHOACÁN",
    },
    {
      value: "PARÁCUARO",
      name: "PARÁCUARO",
      parent: "MICHOACÁN",
    },
    {
      value: "PARACHO",
      name: "PARACHO",
      parent: "MICHOACÁN",
    },
    {
      value: "PÁTZCUARO",
      name: "PÁTZCUARO",
      parent: "MICHOACÁN",
    },
    {
      value: "PENJAMILLO",
      name: "PENJAMILLO",
      parent: "MICHOACÁN",
    },
    {
      value: "PERIBÁN",
      name: "PERIBÁN",
      parent: "MICHOACÁN",
    },
    {
      value: "LA PIEDAD",
      name: "LA PIEDAD",
      parent: "MICHOACÁN",
    },
    {
      value: "PURÉPERO",
      name: "PURÉPERO",
      parent: "MICHOACÁN",
    },
    {
      value: "PURUÁNDIRO",
      name: "PURUÁNDIRO",
      parent: "MICHOACÁN",
    },
    {
      value: "QUERÉNDARO",
      name: "QUERÉNDARO",
      parent: "MICHOACÁN",
    },
    {
      value: "QUIROGA",
      name: "QUIROGA",
      parent: "MICHOACÁN",
    },
    {
      value: "COJUMATLÁN DE RÉGULES",
      name: "COJUMATLÁN DE RÉGULES",
      parent: "MICHOACÁN",
    },
    {
      value: "LOS REYES",
      name: "LOS REYES",
      parent: "MICHOACÁN",
    },
    {
      value: "SAHUAYO",
      name: "SAHUAYO",
      parent: "MICHOACÁN",
    },
    {
      value: "SAN LUCAS",
      name: "SAN LUCAS",
      parent: "MICHOACÁN",
    },
    {
      value: "SANTA ANA MAYA",
      name: "SANTA ANA MAYA",
      parent: "MICHOACÁN",
    },
    {
      value: "SALVADOR ESCALANTE",
      name: "SALVADOR ESCALANTE",
      parent: "MICHOACÁN",
    },
    {
      value: "SENGUIO",
      name: "SENGUIO",
      parent: "MICHOACÁN",
    },
    {
      value: "SUSUPUATO",
      name: "SUSUPUATO",
      parent: "MICHOACÁN",
    },
    {
      value: "TACÁMBARO",
      name: "TACÁMBARO",
      parent: "MICHOACÁN",
    },
    {
      value: "TANCÍTARO",
      name: "TANCÍTARO",
      parent: "MICHOACÁN",
    },
    {
      value: "TANGAMANDAPIO",
      name: "TANGAMANDAPIO",
      parent: "MICHOACÁN",
    },
    {
      value: "TANGANCÍCUARO",
      name: "TANGANCÍCUARO",
      parent: "MICHOACÁN",
    },
    {
      value: "TANHUATO",
      name: "TANHUATO",
      parent: "MICHOACÁN",
    },
    {
      value: "TARETAN",
      name: "TARETAN",
      parent: "MICHOACÁN",
    },
    {
      value: "TARÍMBARO",
      name: "TARÍMBARO",
      parent: "MICHOACÁN",
    },
    {
      value: "TEPALCATEPEC",
      name: "TEPALCATEPEC",
      parent: "MICHOACÁN",
    },
    {
      value: "TINGAMBATO",
      name: "TINGAMBATO",
      parent: "MICHOACÁN",
    },
    {
      value: "TINGÜINDÍN",
      name: "TINGÜINDÍN",
      parent: "MICHOACÁN",
    },
    {
      value: "TIQUICHEO DE NICOLÁS ROMERO",
      name: "TIQUICHEO DE NICOLÁS ROMERO",
      parent: "MICHOACÁN",
    },
    {
      value: "TLALPUJAHUA",
      name: "TLALPUJAHUA",
      parent: "MICHOACÁN",
    },
    {
      value: "TLAZAZALCA",
      name: "TLAZAZALCA",
      parent: "MICHOACÁN",
    },
    {
      value: "TOCUMBO",
      name: "TOCUMBO",
      parent: "MICHOACÁN",
    },
    {
      value: "TUMBISCATÍO",
      name: "TUMBISCATÍO",
      parent: "MICHOACÁN",
    },
    {
      value: "TURICATO",
      name: "TURICATO",
      parent: "MICHOACÁN",
    },
    {
      value: "TUXPAN",
      name: "TUXPAN",
      parent: "MICHOACÁN",
    },
    {
      value: "TUZANTLA",
      name: "TUZANTLA",
      parent: "MICHOACÁN",
    },
    {
      value: "TZINTZUNTZAN",
      name: "TZINTZUNTZAN",
      parent: "MICHOACÁN",
    },
    {
      value: "TZITZIO",
      name: "TZITZIO",
      parent: "MICHOACÁN",
    },
    {
      value: "URUAPAN",
      name: "URUAPAN",
      parent: "MICHOACÁN",
    },
    {
      value: "VENUSTIANO CARRANZA",
      name: "VENUSTIANO CARRANZA",
      parent: "MICHOACÁN",
    },
    {
      value: "VILLAMAR",
      name: "VILLAMAR",
      parent: "MICHOACÁN",
    },
    {
      value: "VISTA HERMOSA",
      name: "VISTA HERMOSA",
      parent: "MICHOACÁN",
    },
    {
      value: "YURÉCUARO",
      name: "YURÉCUARO",
      parent: "MICHOACÁN",
    },
    {
      value: "ZACAPU",
      name: "ZACAPU",
      parent: "MICHOACÁN",
    },
    {
      value: "ZAMORA",
      name: "ZAMORA",
      parent: "MICHOACÁN",
    },
    {
      value: "ZINÁPARO",
      name: "ZINÁPARO",
      parent: "MICHOACÁN",
    },
    {
      value: "ZINAPÉCUARO",
      name: "ZINAPÉCUARO",
      parent: "MICHOACÁN",
    },
    {
      value: "ZIRACUARETIRO",
      name: "ZIRACUARETIRO",
      parent: "MICHOACÁN",
    },
    {
      value: "ZITÁCUARO",
      name: "ZITÁCUARO",
      parent: "MICHOACÁN",
    },
    {
      value: "JOSÉ SIXTO VERDUZCO",
      name: "JOSÉ SIXTO VERDUZCO",
      parent: "MICHOACÁN",
    },
    {
      value: "AMACUZAC",
      name: "AMACUZAC",
      parent: "MORELOS",
    },
    {
      value: "ATLATLAHUCAN",
      name: "ATLATLAHUCAN",
      parent: "MORELOS",
    },
    {
      value: "AXOCHIAPAN",
      name: "AXOCHIAPAN",
      parent: "MORELOS",
    },
    {
      value: "AYALA",
      name: "AYALA",
      parent: "MORELOS",
    },
    {
      value: "COATLÁN DEL RÍO",
      name: "COATLÁN DEL RÍO",
      parent: "MORELOS",
    },
    {
      value: "CUAUTLA",
      name: "CUAUTLA",
      parent: "MORELOS",
    },
    {
      value: "CUERNAVACA",
      name: "CUERNAVACA",
      parent: "MORELOS",
    },
    {
      value: "EMILIANO ZAPATA",
      name: "EMILIANO ZAPATA",
      parent: "MORELOS",
    },
    {
      value: "HUITZILAC",
      name: "HUITZILAC",
      parent: "MORELOS",
    },
    {
      value: "JANTETELCO",
      name: "JANTETELCO",
      parent: "MORELOS",
    },
    {
      value: "JIUTEPEC",
      name: "JIUTEPEC",
      parent: "MORELOS",
    },
    {
      value: "JOJUTLA",
      name: "JOJUTLA",
      parent: "MORELOS",
    },
    {
      value: "JONACATEPEC DE LEANDRO VALLE",
      name: "JONACATEPEC DE LEANDRO VALLE",
      parent: "MORELOS",
    },
    {
      value: "MAZATEPEC",
      name: "MAZATEPEC",
      parent: "MORELOS",
    },
    {
      value: "MIACATLÁN",
      name: "MIACATLÁN",
      parent: "MORELOS",
    },
    {
      value: "OCUITUCO",
      name: "OCUITUCO",
      parent: "MORELOS",
    },
    {
      value: "PUENTE DE IXTLA",
      name: "PUENTE DE IXTLA",
      parent: "MORELOS",
    },
    {
      value: "TEMIXCO",
      name: "TEMIXCO",
      parent: "MORELOS",
    },
    {
      value: "TEPALCINGO",
      name: "TEPALCINGO",
      parent: "MORELOS",
    },
    {
      value: "TEPOZTLÁN",
      name: "TEPOZTLÁN",
      parent: "MORELOS",
    },
    {
      value: "TETECALA",
      name: "TETECALA",
      parent: "MORELOS",
    },
    {
      value: "TETELA DEL VOLCÁN",
      name: "TETELA DEL VOLCÁN",
      parent: "MORELOS",
    },
    {
      value: "TLALNEPANTLA",
      name: "TLALNEPANTLA",
      parent: "MORELOS",
    },
    {
      value: "TLALTIZAPÁN DE ZAPATA",
      name: "TLALTIZAPÁN DE ZAPATA",
      parent: "MORELOS",
    },
    {
      value: "TLAQUILTENANGO",
      name: "TLAQUILTENANGO",
      parent: "MORELOS",
    },
    {
      value: "TLAYACAPAN",
      name: "TLAYACAPAN",
      parent: "MORELOS",
    },
    {
      value: "TOTOLAPAN",
      name: "TOTOLAPAN",
      parent: "MORELOS",
    },
    {
      value: "XOCHITEPEC",
      name: "XOCHITEPEC",
      parent: "MORELOS",
    },
    {
      value: "YAUTEPEC",
      name: "YAUTEPEC",
      parent: "MORELOS",
    },
    {
      value: "YECAPIXTLA",
      name: "YECAPIXTLA",
      parent: "MORELOS",
    },
    {
      value: "ZACATEPEC",
      name: "ZACATEPEC",
      parent: "MORELOS",
    },
    {
      value: "ZACUALPAN DE AMILPAS",
      name: "ZACUALPAN DE AMILPAS",
      parent: "MORELOS",
    },
    {
      value: "TEMOAC",
      name: "TEMOAC",
      parent: "MORELOS",
    },
    {
      value: "ACAPONETA",
      name: "ACAPONETA",
      parent: "NAYARIT",
    },
    {
      value: "AHUACATLÁN",
      name: "AHUACATLÁN",
      parent: "NAYARIT",
    },
    {
      value: "AMATLÁN DE CAÑAS",
      name: "AMATLÁN DE CAÑAS",
      parent: "NAYARIT",
    },
    {
      value: "COMPOSTELA",
      name: "COMPOSTELA",
      parent: "NAYARIT",
    },
    {
      value: "HUAJICORI",
      name: "HUAJICORI",
      parent: "NAYARIT",
    },
    {
      value: "IXTLÁN DEL RÍO",
      name: "IXTLÁN DEL RÍO",
      parent: "NAYARIT",
    },
    {
      value: "JALA",
      name: "JALA",
      parent: "NAYARIT",
    },
    {
      value: "XALISCO",
      name: "XALISCO",
      parent: "NAYARIT",
    },
    {
      value: "DEL NAYAR",
      name: "DEL NAYAR",
      parent: "NAYARIT",
    },
    {
      value: "ROSAMORADA",
      name: "ROSAMORADA",
      parent: "NAYARIT",
    },
    {
      value: "RUÍZ",
      name: "RUÍZ",
      parent: "NAYARIT",
    },
    {
      value: "SAN BLAS",
      name: "SAN BLAS",
      parent: "NAYARIT",
    },
    {
      value: "SAN PEDRO LAGUNILLAS",
      name: "SAN PEDRO LAGUNILLAS",
      parent: "NAYARIT",
    },
    {
      value: "SANTA MARÍA DEL ORO",
      name: "SANTA MARÍA DEL ORO",
      parent: "NAYARIT",
    },
    {
      value: "SANTIAGO IXCUINTLA",
      name: "SANTIAGO IXCUINTLA",
      parent: "NAYARIT",
    },
    {
      value: "TECUALA",
      name: "TECUALA",
      parent: "NAYARIT",
    },
    {
      value: "TEPIC",
      name: "TEPIC",
      parent: "NAYARIT",
    },
    {
      value: "TUXPAN",
      name: "TUXPAN",
      parent: "NAYARIT",
    },
    {
      value: "LA YESCA",
      name: "LA YESCA",
      parent: "NAYARIT",
    },
    {
      value: "BAHÍA DE BANDERAS",
      name: "BAHÍA DE BANDERAS",
      parent: "NAYARIT",
    },
    {
      value: "ABASOLO",
      name: "ABASOLO",
      parent: "NUEVO LEÓN",
    },
    {
      value: "AGUALEGUAS",
      name: "AGUALEGUAS",
      parent: "NUEVO LEÓN",
    },
    {
      value: "LOS ALDAMAS",
      name: "LOS ALDAMAS",
      parent: "NUEVO LEÓN",
    },
    {
      value: "ALLENDE",
      name: "ALLENDE",
      parent: "NUEVO LEÓN",
    },
    {
      value: "ANÁHUAC",
      name: "ANÁHUAC",
      parent: "NUEVO LEÓN",
    },
    {
      value: "APODACA",
      name: "APODACA",
      parent: "NUEVO LEÓN",
    },
    {
      value: "ARAMBERRI",
      name: "ARAMBERRI",
      parent: "NUEVO LEÓN",
    },
    {
      value: "BUSTAMANTE",
      name: "BUSTAMANTE",
      parent: "NUEVO LEÓN",
    },
    {
      value: "CADEREYTA JIMÉNEZ",
      name: "CADEREYTA JIMÉNEZ",
      parent: "NUEVO LEÓN",
    },
    {
      value: "EL CARMEN",
      name: "EL CARMEN",
      parent: "NUEVO LEÓN",
    },
    {
      value: "CERRALVO",
      name: "CERRALVO",
      parent: "NUEVO LEÓN",
    },
    {
      value: "CIÉNEGA DE FLORES",
      name: "CIÉNEGA DE FLORES",
      parent: "NUEVO LEÓN",
    },
    {
      value: "CHINA",
      name: "CHINA",
      parent: "NUEVO LEÓN",
    },
    {
      value: "DOCTOR ARROYO",
      name: "DOCTOR ARROYO",
      parent: "NUEVO LEÓN",
    },
    {
      value: "DOCTOR COSS",
      name: "DOCTOR COSS",
      parent: "NUEVO LEÓN",
    },
    {
      value: "DOCTOR GONZÁLEZ",
      name: "DOCTOR GONZÁLEZ",
      parent: "NUEVO LEÓN",
    },
    {
      value: "GALEANA",
      name: "GALEANA",
      parent: "NUEVO LEÓN",
    },
    {
      value: "GARCÍA",
      name: "GARCÍA",
      parent: "NUEVO LEÓN",
    },
    {
      value: "SAN PEDRO GARZA GARCÍA",
      name: "SAN PEDRO GARZA GARCÍA",
      parent: "NUEVO LEÓN",
    },
    {
      value: "GENERAL BRAVO",
      name: "GENERAL BRAVO",
      parent: "NUEVO LEÓN",
    },
    {
      value: "GENERAL ESCOBEDO",
      name: "GENERAL ESCOBEDO",
      parent: "NUEVO LEÓN",
    },
    {
      value: "GENERAL TERÁN",
      name: "GENERAL TERÁN",
      parent: "NUEVO LEÓN",
    },
    {
      value: "GENERAL TREVIÑO",
      name: "GENERAL TREVIÑO",
      parent: "NUEVO LEÓN",
    },
    {
      value: "GENERAL ZARAGOZA",
      name: "GENERAL ZARAGOZA",
      parent: "NUEVO LEÓN",
    },
    {
      value: "GENERAL ZUAZUA",
      name: "GENERAL ZUAZUA",
      parent: "NUEVO LEÓN",
    },
    {
      value: "GUADALUPE",
      name: "GUADALUPE",
      parent: "NUEVO LEÓN",
    },
    {
      value: "LOS HERRERAS",
      name: "LOS HERRERAS",
      parent: "NUEVO LEÓN",
    },
    {
      value: "HIGUERAS",
      name: "HIGUERAS",
      parent: "NUEVO LEÓN",
    },
    {
      value: "HUALAHUISES",
      name: "HUALAHUISES",
      parent: "NUEVO LEÓN",
    },
    {
      value: "ITURBIDE",
      name: "ITURBIDE",
      parent: "NUEVO LEÓN",
    },
    {
      value: "JUÁREZ",
      name: "JUÁREZ",
      parent: "NUEVO LEÓN",
    },
    {
      value: "LAMPAZOS DE NARANJO",
      name: "LAMPAZOS DE NARANJO",
      parent: "NUEVO LEÓN",
    },
    {
      value: "LINARES",
      name: "LINARES",
      parent: "NUEVO LEÓN",
    },
    {
      value: "MARÍN",
      name: "MARÍN",
      parent: "NUEVO LEÓN",
    },
    {
      value: "MELCHOR OCAMPO",
      name: "MELCHOR OCAMPO",
      parent: "NUEVO LEÓN",
    },
    {
      value: "MIER Y NORIEGA",
      name: "MIER Y NORIEGA",
      parent: "NUEVO LEÓN",
    },
    {
      value: "MINA",
      name: "MINA",
      parent: "NUEVO LEÓN",
    },
    {
      value: "MONTEMORELOS",
      name: "MONTEMORELOS",
      parent: "NUEVO LEÓN",
    },
    {
      value: "MONTERREY",
      name: "MONTERREY",
      parent: "NUEVO LEÓN",
    },
    {
      value: "PARÁS",
      name: "PARÁS",
      parent: "NUEVO LEÓN",
    },
    {
      value: "PESQUERÍA",
      name: "PESQUERÍA",
      parent: "NUEVO LEÓN",
    },
    {
      value: "LOS RAMONES",
      name: "LOS RAMONES",
      parent: "NUEVO LEÓN",
    },
    {
      value: "RAYONES",
      name: "RAYONES",
      parent: "NUEVO LEÓN",
    },
    {
      value: "SABINAS HIDALGO",
      name: "SABINAS HIDALGO",
      parent: "NUEVO LEÓN",
    },
    {
      value: "SALINAS VICTORIA",
      name: "SALINAS VICTORIA",
      parent: "NUEVO LEÓN",
    },
    {
      value: "SAN NICOLÁS DE LOS GARZA",
      name: "SAN NICOLÁS DE LOS GARZA",
      parent: "NUEVO LEÓN",
    },
    {
      value: "HIDALGO",
      name: "HIDALGO",
      parent: "NUEVO LEÓN",
    },
    {
      value: "SANTA CATARINA",
      name: "SANTA CATARINA",
      parent: "NUEVO LEÓN",
    },
    {
      value: "SANTIAGO",
      name: "SANTIAGO",
      parent: "NUEVO LEÓN",
    },
    {
      value: "VALLECILLO",
      name: "VALLECILLO",
      parent: "NUEVO LEÓN",
    },
    {
      value: "VILLALDAMA",
      name: "VILLALDAMA",
      parent: "NUEVO LEÓN",
    },
    {
      value: "ABEJONES",
      name: "ABEJONES",
      parent: "OAXACA",
    },
    {
      value: "ACATLÁN DE PÉREZ FIGUEROA",
      name: "ACATLÁN DE PÉREZ FIGUEROA",
      parent: "OAXACA",
    },
    {
      value: "ASUNCIÓN CACALOTEPEC",
      name: "ASUNCIÓN CACALOTEPEC",
      parent: "OAXACA",
    },
    {
      value: "ASUNCIÓN CUYOTEPEJI",
      name: "ASUNCIÓN CUYOTEPEJI",
      parent: "OAXACA",
    },
    {
      value: "ASUNCIÓN IXTALTEPEC",
      name: "ASUNCIÓN IXTALTEPEC",
      parent: "OAXACA",
    },
    {
      value: "ASUNCIÓN NOCHIXTLÁN",
      name: "ASUNCIÓN NOCHIXTLÁN",
      parent: "OAXACA",
    },
    {
      value: "ASUNCIÓN OCOTLÁN",
      name: "ASUNCIÓN OCOTLÁN",
      parent: "OAXACA",
    },
    {
      value: "ASUNCIÓN TLACOLULITA",
      name: "ASUNCIÓN TLACOLULITA",
      parent: "OAXACA",
    },
    {
      value: "AYOTZINTEPEC",
      name: "AYOTZINTEPEC",
      parent: "OAXACA",
    },
    {
      value: "EL BARRIO DE LA SOLEDAD",
      name: "EL BARRIO DE LA SOLEDAD",
      parent: "OAXACA",
    },
    {
      value: "CALIHUALÁ",
      name: "CALIHUALÁ",
      parent: "OAXACA",
    },
    {
      value: "CANDELARIA LOXICHA",
      name: "CANDELARIA LOXICHA",
      parent: "OAXACA",
    },
    {
      value: "CIÉNEGA DE ZIMATLÁN",
      name: "CIÉNEGA DE ZIMATLÁN",
      parent: "OAXACA",
    },
    {
      value: "CIUDAD IXTEPEC",
      name: "CIUDAD IXTEPEC",
      parent: "OAXACA",
    },
    {
      value: "COATECAS ALTAS",
      name: "COATECAS ALTAS",
      parent: "OAXACA",
    },
    {
      value: "COICOYÁN DE LAS FLORES",
      name: "COICOYÁN DE LAS FLORES",
      parent: "OAXACA",
    },
    {
      value: "LA COMPAÑÍA",
      name: "LA COMPAÑÍA",
      parent: "OAXACA",
    },
    {
      value: "CONCEPCIÓN BUENAVISTA",
      name: "CONCEPCIÓN BUENAVISTA",
      parent: "OAXACA",
    },
    {
      value: "CONCEPCIÓN PÁPALO",
      name: "CONCEPCIÓN PÁPALO",
      parent: "OAXACA",
    },
    {
      value: "CONSTANCIA DEL ROSARIO",
      name: "CONSTANCIA DEL ROSARIO",
      parent: "OAXACA",
    },
    {
      value: "COSOLAPA",
      name: "COSOLAPA",
      parent: "OAXACA",
    },
    {
      value: "COSOLTEPEC",
      name: "COSOLTEPEC",
      parent: "OAXACA",
    },
    {
      value: "CUILÁPAM DE GUERRERO",
      name: "CUILÁPAM DE GUERRERO",
      parent: "OAXACA",
    },
    {
      value: "CUYAMECALCO VILLA DE ZARAGOZA",
      name: "CUYAMECALCO VILLA DE ZARAGOZA",
      parent: "OAXACA",
    },
    {
      value: "CHAHUITES",
      name: "CHAHUITES",
      parent: "OAXACA",
    },
    {
      value: "CHALCATONGO DE HIDALGO",
      name: "CHALCATONGO DE HIDALGO",
      parent: "OAXACA",
    },
    {
      value: "CHIQUIHUITLÁN DE BENITO JUÁREZ",
      name: "CHIQUIHUITLÁN DE BENITO JUÁREZ",
      parent: "OAXACA",
    },
    {
      value: "HEROICA CIUDAD DE EJUTLA DE CRESPO",
      name: "HEROICA CIUDAD DE EJUTLA DE CRESPO",
      parent: "OAXACA",
    },
    {
      value: "ELOXOCHITLÁN DE FLORES MAGÓN",
      name: "ELOXOCHITLÁN DE FLORES MAGÓN",
      parent: "OAXACA",
    },
    {
      value: "EL ESPINAL",
      name: "EL ESPINAL",
      parent: "OAXACA",
    },
    {
      value: "TAMAZULÁPAM DEL ESPÍRITU SANTO",
      name: "TAMAZULÁPAM DEL ESPÍRITU SANTO",
      parent: "OAXACA",
    },
    {
      value: "FRESNILLO DE TRUJANO",
      name: "FRESNILLO DE TRUJANO",
      parent: "OAXACA",
    },
    {
      value: "GUADALUPE ETLA",
      name: "GUADALUPE ETLA",
      parent: "OAXACA",
    },
    {
      value: "GUADALUPE DE RAMÍREZ",
      name: "GUADALUPE DE RAMÍREZ",
      parent: "OAXACA",
    },
    {
      value: "GUELATAO DE JUÁREZ",
      name: "GUELATAO DE JUÁREZ",
      parent: "OAXACA",
    },
    {
      value: "GUEVEA DE HUMBOLDT",
      name: "GUEVEA DE HUMBOLDT",
      parent: "OAXACA",
    },
    {
      value: "MESONES HIDALGO",
      name: "MESONES HIDALGO",
      parent: "OAXACA",
    },
    {
      value: "VILLA HIDALGO",
      name: "VILLA HIDALGO",
      parent: "OAXACA",
    },
    {
      value: "HEROICA CIUDAD DE HUAJUAPAN DE LEÓN",
      name: "HEROICA CIUDAD DE HUAJUAPAN DE LEÓN",
      parent: "OAXACA",
    },
    {
      value: "HUAUTEPEC",
      name: "HUAUTEPEC",
      parent: "OAXACA",
    },
    {
      value: "HUAUTLA DE JIMÉNEZ",
      name: "HUAUTLA DE JIMÉNEZ",
      parent: "OAXACA",
    },
    {
      value: "IXTLÁN DE JUÁREZ",
      name: "IXTLÁN DE JUÁREZ",
      parent: "OAXACA",
    },
    {
      value: "HEROICA CIUDAD DE JUCHITÁN DE ZARAGOZA",
      name: "HEROICA CIUDAD DE JUCHITÁN DE ZARAGOZA",
      parent: "OAXACA",
    },
    {
      value: "LOMA BONITA",
      name: "LOMA BONITA",
      parent: "OAXACA",
    },
    {
      value: "MAGDALENA APASCO",
      name: "MAGDALENA APASCO",
      parent: "OAXACA",
    },
    {
      value: "MAGDALENA JALTEPEC",
      name: "MAGDALENA JALTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTA MAGDALENA JICOTLÁN",
      name: "SANTA MAGDALENA JICOTLÁN",
      parent: "OAXACA",
    },
    {
      value: "MAGDALENA MIXTEPEC",
      name: "MAGDALENA MIXTEPEC",
      parent: "OAXACA",
    },
    {
      value: "MAGDALENA OCOTLÁN",
      name: "MAGDALENA OCOTLÁN",
      parent: "OAXACA",
    },
    {
      value: "MAGDALENA PEÑASCO",
      name: "MAGDALENA PEÑASCO",
      parent: "OAXACA",
    },
    {
      value: "MAGDALENA TEITIPAC",
      name: "MAGDALENA TEITIPAC",
      parent: "OAXACA",
    },
    {
      value: "MAGDALENA TEQUISISTLÁN",
      name: "MAGDALENA TEQUISISTLÁN",
      parent: "OAXACA",
    },
    {
      value: "MAGDALENA TLACOTEPEC",
      name: "MAGDALENA TLACOTEPEC",
      parent: "OAXACA",
    },
    {
      value: "MAGDALENA ZAHUATLÁN",
      name: "MAGDALENA ZAHUATLÁN",
      parent: "OAXACA",
    },
    {
      value: "MARISCALA DE JUÁREZ",
      name: "MARISCALA DE JUÁREZ",
      parent: "OAXACA",
    },
    {
      value: "MÁRTIRES DE TACUBAYA",
      name: "MÁRTIRES DE TACUBAYA",
      parent: "OAXACA",
    },
    {
      value: "MATÍAS ROMERO AVENDAÑO",
      name: "MATÍAS ROMERO AVENDAÑO",
      parent: "OAXACA",
    },
    {
      value: "MAZATLÁN VILLA DE FLORES",
      name: "MAZATLÁN VILLA DE FLORES",
      parent: "OAXACA",
    },
    {
      value: "MIAHUATLÁN DE PORFIRIO DÍAZ",
      name: "MIAHUATLÁN DE PORFIRIO DÍAZ",
      parent: "OAXACA",
    },
    {
      value: "MIXISTLÁN DE LA REFORMA",
      name: "MIXISTLÁN DE LA REFORMA",
      parent: "OAXACA",
    },
    {
      value: "MONJAS",
      name: "MONJAS",
      parent: "OAXACA",
    },
    {
      value: "NATIVIDAD",
      name: "NATIVIDAD",
      parent: "OAXACA",
    },
    {
      value: "NAZARENO ETLA",
      name: "NAZARENO ETLA",
      parent: "OAXACA",
    },
    {
      value: "NEJAPA DE MADERO",
      name: "NEJAPA DE MADERO",
      parent: "OAXACA",
    },
    {
      value: "IXPANTEPEC NIEVES",
      name: "IXPANTEPEC NIEVES",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO NILTEPEC",
      name: "SANTIAGO NILTEPEC",
      parent: "OAXACA",
    },
    {
      value: "OAXACA DE JUÁREZ",
      name: "OAXACA DE JUÁREZ",
      parent: "OAXACA",
    },
    {
      value: "OCOTLÁN DE MORELOS",
      name: "OCOTLÁN DE MORELOS",
      parent: "OAXACA",
    },
    {
      value: "LA PE",
      name: "LA PE",
      parent: "OAXACA",
    },
    {
      value: "PINOTEPA DE DON LUIS",
      name: "PINOTEPA DE DON LUIS",
      parent: "OAXACA",
    },
    {
      value: "PLUMA HIDALGO",
      name: "PLUMA HIDALGO",
      parent: "OAXACA",
    },
    {
      value: "SAN JOSÉ DEL PROGRESO",
      name: "SAN JOSÉ DEL PROGRESO",
      parent: "OAXACA",
    },
    {
      value: "PUTLA VILLA DE GUERRERO",
      name: "PUTLA VILLA DE GUERRERO",
      parent: "OAXACA",
    },
    {
      value: "SANTA CATARINA QUIOQUITANI",
      name: "SANTA CATARINA QUIOQUITANI",
      parent: "OAXACA",
    },
    {
      value: "REFORMA DE PINEDA",
      name: "REFORMA DE PINEDA",
      parent: "OAXACA",
    },
    {
      value: "LA REFORMA",
      name: "LA REFORMA",
      parent: "OAXACA",
    },
    {
      value: "REYES ETLA",
      name: "REYES ETLA",
      parent: "OAXACA",
    },
    {
      value: "ROJAS DE CUAUHTÉMOC",
      name: "ROJAS DE CUAUHTÉMOC",
      parent: "OAXACA",
    },
    {
      value: "SALINA CRUZ",
      name: "SALINA CRUZ",
      parent: "OAXACA",
    },
    {
      value: "SAN AGUSTÍN AMATENGO",
      name: "SAN AGUSTÍN AMATENGO",
      parent: "OAXACA",
    },
    {
      value: "SAN AGUSTÍN ATENANGO",
      name: "SAN AGUSTÍN ATENANGO",
      parent: "OAXACA",
    },
    {
      value: "SAN AGUSTÍN CHAYUCO",
      name: "SAN AGUSTÍN CHAYUCO",
      parent: "OAXACA",
    },
    {
      value: "SAN AGUSTÍN DE LAS JUNTAS",
      name: "SAN AGUSTÍN DE LAS JUNTAS",
      parent: "OAXACA",
    },
    {
      value: "SAN AGUSTÍN ETLA",
      name: "SAN AGUSTÍN ETLA",
      parent: "OAXACA",
    },
    {
      value: "SAN AGUSTÍN LOXICHA",
      name: "SAN AGUSTÍN LOXICHA",
      parent: "OAXACA",
    },
    {
      value: "SAN AGUSTÍN TLACOTEPEC",
      name: "SAN AGUSTÍN TLACOTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN AGUSTÍN YATARENI",
      name: "SAN AGUSTÍN YATARENI",
      parent: "OAXACA",
    },
    {
      value: "SAN ANDRÉS CABECERA NUEVA",
      name: "SAN ANDRÉS CABECERA NUEVA",
      parent: "OAXACA",
    },
    {
      value: "SAN ANDRÉS DINICUITI",
      name: "SAN ANDRÉS DINICUITI",
      parent: "OAXACA",
    },
    {
      value: "SAN ANDRÉS HUAXPALTEPEC",
      name: "SAN ANDRÉS HUAXPALTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN ANDRÉS HUAYÁPAM",
      name: "SAN ANDRÉS HUAYÁPAM",
      parent: "OAXACA",
    },
    {
      value: "SAN ANDRÉS IXTLAHUACA",
      name: "SAN ANDRÉS IXTLAHUACA",
      parent: "OAXACA",
    },
    {
      value: "SAN ANDRÉS LAGUNAS",
      name: "SAN ANDRÉS LAGUNAS",
      parent: "OAXACA",
    },
    {
      value: "SAN ANDRÉS NUXIÑO",
      name: "SAN ANDRÉS NUXIÑO",
      parent: "OAXACA",
    },
    {
      value: "SAN ANDRÉS PAXTLÁN",
      name: "SAN ANDRÉS PAXTLÁN",
      parent: "OAXACA",
    },
    {
      value: "SAN ANDRÉS SINAXTLA",
      name: "SAN ANDRÉS SINAXTLA",
      parent: "OAXACA",
    },
    {
      value: "SAN ANDRÉS SOLAGA",
      name: "SAN ANDRÉS SOLAGA",
      parent: "OAXACA",
    },
    {
      value: "SAN ANDRÉS TEOTILÁLPAM",
      name: "SAN ANDRÉS TEOTILÁLPAM",
      parent: "OAXACA",
    },
    {
      value: "SAN ANDRÉS TEPETLAPA",
      name: "SAN ANDRÉS TEPETLAPA",
      parent: "OAXACA",
    },
    {
      value: "SAN ANDRÉS YAÁ",
      name: "SAN ANDRÉS YAÁ",
      parent: "OAXACA",
    },
    {
      value: "SAN ANDRÉS ZABACHE",
      name: "SAN ANDRÉS ZABACHE",
      parent: "OAXACA",
    },
    {
      value: "SAN ANDRÉS ZAUTLA",
      name: "SAN ANDRÉS ZAUTLA",
      parent: "OAXACA",
    },
    {
      value: "SAN ANTONINO CASTILLO VELASCO",
      name: "SAN ANTONINO CASTILLO VELASCO",
      parent: "OAXACA",
    },
    {
      value: "SAN ANTONINO EL ALTO",
      name: "SAN ANTONINO EL ALTO",
      parent: "OAXACA",
    },
    {
      value: "SAN ANTONINO MONTE VERDE",
      name: "SAN ANTONINO MONTE VERDE",
      parent: "OAXACA",
    },
    {
      value: "SAN ANTONIO ACUTLA",
      name: "SAN ANTONIO ACUTLA",
      parent: "OAXACA",
    },
    {
      value: "SAN ANTONIO DE LA CAL",
      name: "SAN ANTONIO DE LA CAL",
      parent: "OAXACA",
    },
    {
      value: "SAN ANTONIO HUITEPEC",
      name: "SAN ANTONIO HUITEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN ANTONIO NANAHUATÍPAM",
      name: "SAN ANTONIO NANAHUATÍPAM",
      parent: "OAXACA",
    },
    {
      value: "SAN ANTONIO SINICAHUA",
      name: "SAN ANTONIO SINICAHUA",
      parent: "OAXACA",
    },
    {
      value: "SAN ANTONIO TEPETLAPA",
      name: "SAN ANTONIO TEPETLAPA",
      parent: "OAXACA",
    },
    {
      value: "SAN BALTAZAR CHICHICÁPAM",
      name: "SAN BALTAZAR CHICHICÁPAM",
      parent: "OAXACA",
    },
    {
      value: "SAN BALTAZAR LOXICHA",
      name: "SAN BALTAZAR LOXICHA",
      parent: "OAXACA",
    },
    {
      value: "SAN BALTAZAR YATZACHI EL BAJO",
      name: "SAN BALTAZAR YATZACHI EL BAJO",
      parent: "OAXACA",
    },
    {
      value: "SAN BARTOLO COYOTEPEC",
      name: "SAN BARTOLO COYOTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN BARTOLOMÉ AYAUTLA",
      name: "SAN BARTOLOMÉ AYAUTLA",
      parent: "OAXACA",
    },
    {
      value: "SAN BARTOLOMÉ LOXICHA",
      name: "SAN BARTOLOMÉ LOXICHA",
      parent: "OAXACA",
    },
    {
      value: "SAN BARTOLOMÉ QUIALANA",
      name: "SAN BARTOLOMÉ QUIALANA",
      parent: "OAXACA",
    },
    {
      value: "SAN BARTOLOMÉ YUCUAÑE",
      name: "SAN BARTOLOMÉ YUCUAÑE",
      parent: "OAXACA",
    },
    {
      value: "SAN BARTOLOMÉ ZOOGOCHO",
      name: "SAN BARTOLOMÉ ZOOGOCHO",
      parent: "OAXACA",
    },
    {
      value: "SAN BARTOLO SOYALTEPEC",
      name: "SAN BARTOLO SOYALTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN BARTOLO YAUTEPEC",
      name: "SAN BARTOLO YAUTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN BERNARDO MIXTEPEC",
      name: "SAN BERNARDO MIXTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN BLAS ATEMPA",
      name: "SAN BLAS ATEMPA",
      parent: "OAXACA",
    },
    {
      value: "SAN CARLOS YAUTEPEC",
      name: "SAN CARLOS YAUTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN CRISTÓBAL AMATLÁN",
      name: "SAN CRISTÓBAL AMATLÁN",
      parent: "OAXACA",
    },
    {
      value: "SAN CRISTÓBAL AMOLTEPEC",
      name: "SAN CRISTÓBAL AMOLTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN CRISTÓBAL LACHIRIOAG",
      name: "SAN CRISTÓBAL LACHIRIOAG",
      parent: "OAXACA",
    },
    {
      value: "SAN CRISTÓBAL SUCHIXTLAHUACA",
      name: "SAN CRISTÓBAL SUCHIXTLAHUACA",
      parent: "OAXACA",
    },
    {
      value: "SAN DIONISIO DEL MAR",
      name: "SAN DIONISIO DEL MAR",
      parent: "OAXACA",
    },
    {
      value: "SAN DIONISIO OCOTEPEC",
      name: "SAN DIONISIO OCOTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN DIONISIO OCOTLÁN",
      name: "SAN DIONISIO OCOTLÁN",
      parent: "OAXACA",
    },
    {
      value: "SAN ESTEBAN ATATLAHUCA",
      name: "SAN ESTEBAN ATATLAHUCA",
      parent: "OAXACA",
    },
    {
      value: "SAN FELIPE JALAPA DE DÍAZ",
      name: "SAN FELIPE JALAPA DE DÍAZ",
      parent: "OAXACA",
    },
    {
      value: "SAN FELIPE TEJALÁPAM",
      name: "SAN FELIPE TEJALÁPAM",
      parent: "OAXACA",
    },
    {
      value: "SAN FELIPE USILA",
      name: "SAN FELIPE USILA",
      parent: "OAXACA",
    },
    {
      value: "SAN FRANCISCO CAHUACUÁ",
      name: "SAN FRANCISCO CAHUACUÁ",
      parent: "OAXACA",
    },
    {
      value: "SAN FRANCISCO CAJONOS",
      name: "SAN FRANCISCO CAJONOS",
      parent: "OAXACA",
    },
    {
      value: "SAN FRANCISCO CHAPULAPA",
      name: "SAN FRANCISCO CHAPULAPA",
      parent: "OAXACA",
    },
    {
      value: "SAN FRANCISCO CHINDÚA",
      name: "SAN FRANCISCO CHINDÚA",
      parent: "OAXACA",
    },
    {
      value: "SAN FRANCISCO DEL MAR",
      name: "SAN FRANCISCO DEL MAR",
      parent: "OAXACA",
    },
    {
      value: "SAN FRANCISCO HUEHUETLÁN",
      name: "SAN FRANCISCO HUEHUETLÁN",
      parent: "OAXACA",
    },
    {
      value: "SAN FRANCISCO IXHUATÁN",
      name: "SAN FRANCISCO IXHUATÁN",
      parent: "OAXACA",
    },
    {
      value: "SAN FRANCISCO JALTEPETONGO",
      name: "SAN FRANCISCO JALTEPETONGO",
      parent: "OAXACA",
    },
    {
      value: "SAN FRANCISCO LACHIGOLÓ",
      name: "SAN FRANCISCO LACHIGOLÓ",
      parent: "OAXACA",
    },
    {
      value: "SAN FRANCISCO LOGUECHE",
      name: "SAN FRANCISCO LOGUECHE",
      parent: "OAXACA",
    },
    {
      value: "SAN FRANCISCO NUXAÑO",
      name: "SAN FRANCISCO NUXAÑO",
      parent: "OAXACA",
    },
    {
      value: "SAN FRANCISCO OZOLOTEPEC",
      name: "SAN FRANCISCO OZOLOTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN FRANCISCO SOLA",
      name: "SAN FRANCISCO SOLA",
      parent: "OAXACA",
    },
    {
      value: "SAN FRANCISCO TELIXTLAHUACA",
      name: "SAN FRANCISCO TELIXTLAHUACA",
      parent: "OAXACA",
    },
    {
      value: "SAN FRANCISCO TEOPAN",
      name: "SAN FRANCISCO TEOPAN",
      parent: "OAXACA",
    },
    {
      value: "SAN FRANCISCO TLAPANCINGO",
      name: "SAN FRANCISCO TLAPANCINGO",
      parent: "OAXACA",
    },
    {
      value: "SAN GABRIEL MIXTEPEC",
      name: "SAN GABRIEL MIXTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN ILDEFONSO AMATLÁN",
      name: "SAN ILDEFONSO AMATLÁN",
      parent: "OAXACA",
    },
    {
      value: "SAN ILDEFONSO SOLA",
      name: "SAN ILDEFONSO SOLA",
      parent: "OAXACA",
    },
    {
      value: "SAN ILDEFONSO VILLA ALTA",
      name: "SAN ILDEFONSO VILLA ALTA",
      parent: "OAXACA",
    },
    {
      value: "SAN JACINTO AMILPAS",
      name: "SAN JACINTO AMILPAS",
      parent: "OAXACA",
    },
    {
      value: "SAN JACINTO TLACOTEPEC",
      name: "SAN JACINTO TLACOTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN JERÓNIMO COATLÁN",
      name: "SAN JERÓNIMO COATLÁN",
      parent: "OAXACA",
    },
    {
      value: "SAN JERÓNIMO SILACAYOAPILLA",
      name: "SAN JERÓNIMO SILACAYOAPILLA",
      parent: "OAXACA",
    },
    {
      value: "SAN JERÓNIMO SOSOLA",
      name: "SAN JERÓNIMO SOSOLA",
      parent: "OAXACA",
    },
    {
      value: "SAN JERÓNIMO TAVICHE",
      name: "SAN JERÓNIMO TAVICHE",
      parent: "OAXACA",
    },
    {
      value: "SAN JERÓNIMO TECÓATL",
      name: "SAN JERÓNIMO TECÓATL",
      parent: "OAXACA",
    },
    {
      value: "SAN JORGE NUCHITA",
      name: "SAN JORGE NUCHITA",
      parent: "OAXACA",
    },
    {
      value: "SAN JOSÉ AYUQUILA",
      name: "SAN JOSÉ AYUQUILA",
      parent: "OAXACA",
    },
    {
      value: "SAN JOSÉ CHILTEPEC",
      name: "SAN JOSÉ CHILTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN JOSÉ DEL PEÑASCO",
      name: "SAN JOSÉ DEL PEÑASCO",
      parent: "OAXACA",
    },
    {
      value: "SAN JOSÉ ESTANCIA GRANDE",
      name: "SAN JOSÉ ESTANCIA GRANDE",
      parent: "OAXACA",
    },
    {
      value: "SAN JOSÉ INDEPENDENCIA",
      name: "SAN JOSÉ INDEPENDENCIA",
      parent: "OAXACA",
    },
    {
      value: "SAN JOSÉ LACHIGUIRI",
      name: "SAN JOSÉ LACHIGUIRI",
      parent: "OAXACA",
    },
    {
      value: "SAN JOSÉ TENANGO",
      name: "SAN JOSÉ TENANGO",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN ACHIUTLA",
      name: "SAN JUAN ACHIUTLA",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN ATEPEC",
      name: "SAN JUAN ATEPEC",
      parent: "OAXACA",
    },
    {
      value: "ÁNIMAS TRUJANO",
      name: "ÁNIMAS TRUJANO",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN BAUTISTA ATATLAHUCA",
      name: "SAN JUAN BAUTISTA ATATLAHUCA",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN BAUTISTA COIXTLAHUACA",
      name: "SAN JUAN BAUTISTA COIXTLAHUACA",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN BAUTISTA CUICATLÁN",
      name: "SAN JUAN BAUTISTA CUICATLÁN",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN BAUTISTA GUELACHE",
      name: "SAN JUAN BAUTISTA GUELACHE",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN BAUTISTA JAYACATLÁN",
      name: "SAN JUAN BAUTISTA JAYACATLÁN",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN BAUTISTA LO DE SOTO",
      name: "SAN JUAN BAUTISTA LO DE SOTO",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN BAUTISTA SUCHITEPEC",
      name: "SAN JUAN BAUTISTA SUCHITEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN BAUTISTA TLACOATZINTEPEC",
      name: "SAN JUAN BAUTISTA TLACOATZINTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN BAUTISTA TLACHICHILCO",
      name: "SAN JUAN BAUTISTA TLACHICHILCO",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN BAUTISTA TUXTEPEC",
      name: "SAN JUAN BAUTISTA TUXTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN CACAHUATEPEC",
      name: "SAN JUAN CACAHUATEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN CIENEGUILLA",
      name: "SAN JUAN CIENEGUILLA",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN COATZÓSPAM",
      name: "SAN JUAN COATZÓSPAM",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN COLORADO",
      name: "SAN JUAN COLORADO",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN COMALTEPEC",
      name: "SAN JUAN COMALTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN COTZOCÓN",
      name: "SAN JUAN COTZOCÓN",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN CHICOMEZÚCHIL",
      name: "SAN JUAN CHICOMEZÚCHIL",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN CHILATECA",
      name: "SAN JUAN CHILATECA",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN DEL ESTADO",
      name: "SAN JUAN DEL ESTADO",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN DEL RÍO",
      name: "SAN JUAN DEL RÍO",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN DIUXI",
      name: "SAN JUAN DIUXI",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN EVANGELISTA ANALCO",
      name: "SAN JUAN EVANGELISTA ANALCO",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN GUELAVÍA",
      name: "SAN JUAN GUELAVÍA",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN GUICHICOVI",
      name: "SAN JUAN GUICHICOVI",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN IHUALTEPEC",
      name: "SAN JUAN IHUALTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN JUQUILA MIXES",
      name: "SAN JUAN JUQUILA MIXES",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN JUQUILA VIJANOS",
      name: "SAN JUAN JUQUILA VIJANOS",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN LACHAO",
      name: "SAN JUAN LACHAO",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN LACHIGALLA",
      name: "SAN JUAN LACHIGALLA",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN LAJARCIA",
      name: "SAN JUAN LAJARCIA",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN LALANA",
      name: "SAN JUAN LALANA",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN DE LOS CUÉS",
      name: "SAN JUAN DE LOS CUÉS",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN MAZATLÁN",
      name: "SAN JUAN MAZATLÁN",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN MIXTEPEC",
      name: "SAN JUAN MIXTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN MIXTEPEC",
      name: "SAN JUAN MIXTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN ÑUMÍ",
      name: "SAN JUAN ÑUMÍ",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN OZOLOTEPEC",
      name: "SAN JUAN OZOLOTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN PETLAPA",
      name: "SAN JUAN PETLAPA",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN QUIAHIJE",
      name: "SAN JUAN QUIAHIJE",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN QUIOTEPEC",
      name: "SAN JUAN QUIOTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN SAYULTEPEC",
      name: "SAN JUAN SAYULTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN TABAÁ",
      name: "SAN JUAN TABAÁ",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN TAMAZOLA",
      name: "SAN JUAN TAMAZOLA",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN TEITA",
      name: "SAN JUAN TEITA",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN TEITIPAC",
      name: "SAN JUAN TEITIPAC",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN TEPEUXILA",
      name: "SAN JUAN TEPEUXILA",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN TEPOSCOLULA",
      name: "SAN JUAN TEPOSCOLULA",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN YAEÉ",
      name: "SAN JUAN YAEÉ",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN YATZONA",
      name: "SAN JUAN YATZONA",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN YUCUITA",
      name: "SAN JUAN YUCUITA",
      parent: "OAXACA",
    },
    {
      value: "SAN LORENZO",
      name: "SAN LORENZO",
      parent: "OAXACA",
    },
    {
      value: "SAN LORENZO ALBARRADAS",
      name: "SAN LORENZO ALBARRADAS",
      parent: "OAXACA",
    },
    {
      value: "SAN LORENZO CACAOTEPEC",
      name: "SAN LORENZO CACAOTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN LORENZO CUAUNECUILTITLA",
      name: "SAN LORENZO CUAUNECUILTITLA",
      parent: "OAXACA",
    },
    {
      value: "SAN LORENZO TEXMELÚCAN",
      name: "SAN LORENZO TEXMELÚCAN",
      parent: "OAXACA",
    },
    {
      value: "SAN LORENZO VICTORIA",
      name: "SAN LORENZO VICTORIA",
      parent: "OAXACA",
    },
    {
      value: "SAN LUCAS CAMOTLÁN",
      name: "SAN LUCAS CAMOTLÁN",
      parent: "OAXACA",
    },
    {
      value: "SAN LUCAS OJITLÁN",
      name: "SAN LUCAS OJITLÁN",
      parent: "OAXACA",
    },
    {
      value: "SAN LUCAS QUIAVINÍ",
      name: "SAN LUCAS QUIAVINÍ",
      parent: "OAXACA",
    },
    {
      value: "SAN LUCAS ZOQUIÁPAM",
      name: "SAN LUCAS ZOQUIÁPAM",
      parent: "OAXACA",
    },
    {
      value: "SAN LUIS AMATLÁN",
      name: "SAN LUIS AMATLÁN",
      parent: "OAXACA",
    },
    {
      value: "SAN MARCIAL OZOLOTEPEC",
      name: "SAN MARCIAL OZOLOTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN MARCOS ARTEAGA",
      name: "SAN MARCOS ARTEAGA",
      parent: "OAXACA",
    },
    {
      value: "SAN MARTÍN DE LOS CANSECOS",
      name: "SAN MARTÍN DE LOS CANSECOS",
      parent: "OAXACA",
    },
    {
      value: "SAN MARTÍN HUAMELÚLPAM",
      name: "SAN MARTÍN HUAMELÚLPAM",
      parent: "OAXACA",
    },
    {
      value: "SAN MARTÍN ITUNYOSO",
      name: "SAN MARTÍN ITUNYOSO",
      parent: "OAXACA",
    },
    {
      value: "SAN MARTÍN LACHILÁ",
      name: "SAN MARTÍN LACHILÁ",
      parent: "OAXACA",
    },
    {
      value: "SAN MARTÍN PERAS",
      name: "SAN MARTÍN PERAS",
      parent: "OAXACA",
    },
    {
      value: "SAN MARTÍN TILCAJETE",
      name: "SAN MARTÍN TILCAJETE",
      parent: "OAXACA",
    },
    {
      value: "SAN MARTÍN TOXPALAN",
      name: "SAN MARTÍN TOXPALAN",
      parent: "OAXACA",
    },
    {
      value: "SAN MARTÍN ZACATEPEC",
      name: "SAN MARTÍN ZACATEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN MATEO CAJONOS",
      name: "SAN MATEO CAJONOS",
      parent: "OAXACA",
    },
    {
      value: "CAPULÁLPAM DE MÉNDEZ",
      name: "CAPULÁLPAM DE MÉNDEZ",
      parent: "OAXACA",
    },
    {
      value: "SAN MATEO DEL MAR",
      name: "SAN MATEO DEL MAR",
      parent: "OAXACA",
    },
    {
      value: "SAN MATEO YOLOXOCHITLÁN",
      name: "SAN MATEO YOLOXOCHITLÁN",
      parent: "OAXACA",
    },
    {
      value: "SAN MATEO ETLATONGO",
      name: "SAN MATEO ETLATONGO",
      parent: "OAXACA",
    },
    {
      value: "SAN MATEO NEJÁPAM",
      name: "SAN MATEO NEJÁPAM",
      parent: "OAXACA",
    },
    {
      value: "SAN MATEO PEÑASCO",
      name: "SAN MATEO PEÑASCO",
      parent: "OAXACA",
    },
    {
      value: "SAN MATEO PIÑAS",
      name: "SAN MATEO PIÑAS",
      parent: "OAXACA",
    },
    {
      value: "SAN MATEO RÍO HONDO",
      name: "SAN MATEO RÍO HONDO",
      parent: "OAXACA",
    },
    {
      value: "SAN MATEO SINDIHUI",
      name: "SAN MATEO SINDIHUI",
      parent: "OAXACA",
    },
    {
      value: "SAN MATEO TLAPILTEPEC",
      name: "SAN MATEO TLAPILTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN MELCHOR BETAZA",
      name: "SAN MELCHOR BETAZA",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL ACHIUTLA",
      name: "SAN MIGUEL ACHIUTLA",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL AHUEHUETITLÁN",
      name: "SAN MIGUEL AHUEHUETITLÁN",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL ALOÁPAM",
      name: "SAN MIGUEL ALOÁPAM",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL AMATITLÁN",
      name: "SAN MIGUEL AMATITLÁN",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL AMATLÁN",
      name: "SAN MIGUEL AMATLÁN",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL COATLÁN",
      name: "SAN MIGUEL COATLÁN",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL CHICAHUA",
      name: "SAN MIGUEL CHICAHUA",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL CHIMALAPA",
      name: "SAN MIGUEL CHIMALAPA",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL DEL PUERTO",
      name: "SAN MIGUEL DEL PUERTO",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL DEL RÍO",
      name: "SAN MIGUEL DEL RÍO",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL EJUTLA",
      name: "SAN MIGUEL EJUTLA",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL EL GRANDE",
      name: "SAN MIGUEL EL GRANDE",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL HUAUTLA",
      name: "SAN MIGUEL HUAUTLA",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL MIXTEPEC",
      name: "SAN MIGUEL MIXTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL PANIXTLAHUACA",
      name: "SAN MIGUEL PANIXTLAHUACA",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL PERAS",
      name: "SAN MIGUEL PERAS",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL PIEDRAS",
      name: "SAN MIGUEL PIEDRAS",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL QUETZALTEPEC",
      name: "SAN MIGUEL QUETZALTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL SANTA FLOR",
      name: "SAN MIGUEL SANTA FLOR",
      parent: "OAXACA",
    },
    {
      value: "VILLA SOLA DE VEGA",
      name: "VILLA SOLA DE VEGA",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL SOYALTEPEC",
      name: "SAN MIGUEL SOYALTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL SUCHIXTEPEC",
      name: "SAN MIGUEL SUCHIXTEPEC",
      parent: "OAXACA",
    },
    {
      value: "VILLA TALEA DE CASTRO",
      name: "VILLA TALEA DE CASTRO",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL TECOMATLÁN",
      name: "SAN MIGUEL TECOMATLÁN",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL TENANGO",
      name: "SAN MIGUEL TENANGO",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL TEQUIXTEPEC",
      name: "SAN MIGUEL TEQUIXTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL TILQUIÁPAM",
      name: "SAN MIGUEL TILQUIÁPAM",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL TLACAMAMA",
      name: "SAN MIGUEL TLACAMAMA",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL TLACOTEPEC",
      name: "SAN MIGUEL TLACOTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL TULANCINGO",
      name: "SAN MIGUEL TULANCINGO",
      parent: "OAXACA",
    },
    {
      value: "SAN MIGUEL YOTAO",
      name: "SAN MIGUEL YOTAO",
      parent: "OAXACA",
    },
    {
      value: "SAN NICOLÁS",
      name: "SAN NICOLÁS",
      parent: "OAXACA",
    },
    {
      value: "SAN NICOLÁS HIDALGO",
      name: "SAN NICOLÁS HIDALGO",
      parent: "OAXACA",
    },
    {
      value: "SAN PABLO COATLÁN",
      name: "SAN PABLO COATLÁN",
      parent: "OAXACA",
    },
    {
      value: "SAN PABLO CUATRO VENADOS",
      name: "SAN PABLO CUATRO VENADOS",
      parent: "OAXACA",
    },
    {
      value: "SAN PABLO ETLA",
      name: "SAN PABLO ETLA",
      parent: "OAXACA",
    },
    {
      value: "SAN PABLO HUITZO",
      name: "SAN PABLO HUITZO",
      parent: "OAXACA",
    },
    {
      value: "SAN PABLO HUIXTEPEC",
      name: "SAN PABLO HUIXTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN PABLO MACUILTIANGUIS",
      name: "SAN PABLO MACUILTIANGUIS",
      parent: "OAXACA",
    },
    {
      value: "SAN PABLO TIJALTEPEC",
      name: "SAN PABLO TIJALTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN PABLO VILLA DE MITLA",
      name: "SAN PABLO VILLA DE MITLA",
      parent: "OAXACA",
    },
    {
      value: "SAN PABLO YAGANIZA",
      name: "SAN PABLO YAGANIZA",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO AMUZGOS",
      name: "SAN PEDRO AMUZGOS",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO APÓSTOL",
      name: "SAN PEDRO APÓSTOL",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO ATOYAC",
      name: "SAN PEDRO ATOYAC",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO CAJONOS",
      name: "SAN PEDRO CAJONOS",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO COXCALTEPEC CÁNTAROS",
      name: "SAN PEDRO COXCALTEPEC CÁNTAROS",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO COMITANCILLO",
      name: "SAN PEDRO COMITANCILLO",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO EL ALTO",
      name: "SAN PEDRO EL ALTO",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO HUAMELULA",
      name: "SAN PEDRO HUAMELULA",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO HUILOTEPEC",
      name: "SAN PEDRO HUILOTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO IXCATLÁN",
      name: "SAN PEDRO IXCATLÁN",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO IXTLAHUACA",
      name: "SAN PEDRO IXTLAHUACA",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO JALTEPETONGO",
      name: "SAN PEDRO JALTEPETONGO",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO JICAYÁN",
      name: "SAN PEDRO JICAYÁN",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO JOCOTIPAC",
      name: "SAN PEDRO JOCOTIPAC",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO JUCHATENGO",
      name: "SAN PEDRO JUCHATENGO",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO MÁRTIR",
      name: "SAN PEDRO MÁRTIR",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO MÁRTIR QUIECHAPA",
      name: "SAN PEDRO MÁRTIR QUIECHAPA",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO MÁRTIR YUCUXACO",
      name: "SAN PEDRO MÁRTIR YUCUXACO",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO MIXTEPEC",
      name: "SAN PEDRO MIXTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO MIXTEPEC",
      name: "SAN PEDRO MIXTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO MOLINOS",
      name: "SAN PEDRO MOLINOS",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO NOPALA",
      name: "SAN PEDRO NOPALA",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO OCOPETATILLO",
      name: "SAN PEDRO OCOPETATILLO",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO OCOTEPEC",
      name: "SAN PEDRO OCOTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO POCHUTLA",
      name: "SAN PEDRO POCHUTLA",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO QUIATONI",
      name: "SAN PEDRO QUIATONI",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO SOCHIÁPAM",
      name: "SAN PEDRO SOCHIÁPAM",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO TAPANATEPEC",
      name: "SAN PEDRO TAPANATEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO TAVICHE",
      name: "SAN PEDRO TAVICHE",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO TEOZACOALCO",
      name: "SAN PEDRO TEOZACOALCO",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO TEUTILA",
      name: "SAN PEDRO TEUTILA",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO TIDAÁ",
      name: "SAN PEDRO TIDAÁ",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO TOPILTEPEC",
      name: "SAN PEDRO TOPILTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO TOTOLÁPAM",
      name: "SAN PEDRO TOTOLÁPAM",
      parent: "OAXACA",
    },
    {
      value: "VILLA DE TUTUTEPEC",
      name: "VILLA DE TUTUTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO YANERI",
      name: "SAN PEDRO YANERI",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO YÓLOX",
      name: "SAN PEDRO YÓLOX",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO Y SAN PABLO AYUTLA",
      name: "SAN PEDRO Y SAN PABLO AYUTLA",
      parent: "OAXACA",
    },
    {
      value: "VILLA DE ETLA",
      name: "VILLA DE ETLA",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO Y SAN PABLO TEPOSCOLULA",
      name: "SAN PEDRO Y SAN PABLO TEPOSCOLULA",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO Y SAN PABLO TEQUIXTEPEC",
      name: "SAN PEDRO Y SAN PABLO TEQUIXTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SAN PEDRO YUCUNAMA",
      name: "SAN PEDRO YUCUNAMA",
      parent: "OAXACA",
    },
    {
      value: "SAN RAYMUNDO JALPAN",
      name: "SAN RAYMUNDO JALPAN",
      parent: "OAXACA",
    },
    {
      value: "SAN SEBASTIÁN ABASOLO",
      name: "SAN SEBASTIÁN ABASOLO",
      parent: "OAXACA",
    },
    {
      value: "SAN SEBASTIÁN COATLÁN",
      name: "SAN SEBASTIÁN COATLÁN",
      parent: "OAXACA",
    },
    {
      value: "SAN SEBASTIÁN IXCAPA",
      name: "SAN SEBASTIÁN IXCAPA",
      parent: "OAXACA",
    },
    {
      value: "SAN SEBASTIÁN NICANANDUTA",
      name: "SAN SEBASTIÁN NICANANDUTA",
      parent: "OAXACA",
    },
    {
      value: "SAN SEBASTIÁN RÍO HONDO",
      name: "SAN SEBASTIÁN RÍO HONDO",
      parent: "OAXACA",
    },
    {
      value: "SAN SEBASTIÁN TECOMAXTLAHUACA",
      name: "SAN SEBASTIÁN TECOMAXTLAHUACA",
      parent: "OAXACA",
    },
    {
      value: "SAN SEBASTIÁN TEITIPAC",
      name: "SAN SEBASTIÁN TEITIPAC",
      parent: "OAXACA",
    },
    {
      value: "SAN SEBASTIÁN TUTLA",
      name: "SAN SEBASTIÁN TUTLA",
      parent: "OAXACA",
    },
    {
      value: "SAN SIMÓN ALMOLONGAS",
      name: "SAN SIMÓN ALMOLONGAS",
      parent: "OAXACA",
    },
    {
      value: "SAN SIMÓN ZAHUATLÁN",
      name: "SAN SIMÓN ZAHUATLÁN",
      parent: "OAXACA",
    },
    {
      value: "SANTA ANA",
      name: "SANTA ANA",
      parent: "OAXACA",
    },
    {
      value: "SANTA ANA ATEIXTLAHUACA",
      name: "SANTA ANA ATEIXTLAHUACA",
      parent: "OAXACA",
    },
    {
      value: "SANTA ANA CUAUHTÉMOC",
      name: "SANTA ANA CUAUHTÉMOC",
      parent: "OAXACA",
    },
    {
      value: "SANTA ANA DEL VALLE",
      name: "SANTA ANA DEL VALLE",
      parent: "OAXACA",
    },
    {
      value: "SANTA ANA TAVELA",
      name: "SANTA ANA TAVELA",
      parent: "OAXACA",
    },
    {
      value: "SANTA ANA TLAPACOYAN",
      name: "SANTA ANA TLAPACOYAN",
      parent: "OAXACA",
    },
    {
      value: "SANTA ANA YARENI",
      name: "SANTA ANA YARENI",
      parent: "OAXACA",
    },
    {
      value: "SANTA ANA ZEGACHE",
      name: "SANTA ANA ZEGACHE",
      parent: "OAXACA",
    },
    {
      value: "SANTA CATALINA QUIERÍ",
      name: "SANTA CATALINA QUIERÍ",
      parent: "OAXACA",
    },
    {
      value: "SANTA CATARINA CUIXTLA",
      name: "SANTA CATARINA CUIXTLA",
      parent: "OAXACA",
    },
    {
      value: "SANTA CATARINA IXTEPEJI",
      name: "SANTA CATARINA IXTEPEJI",
      parent: "OAXACA",
    },
    {
      value: "SANTA CATARINA JUQUILA",
      name: "SANTA CATARINA JUQUILA",
      parent: "OAXACA",
    },
    {
      value: "SANTA CATARINA LACHATAO",
      name: "SANTA CATARINA LACHATAO",
      parent: "OAXACA",
    },
    {
      value: "SANTA CATARINA LOXICHA",
      name: "SANTA CATARINA LOXICHA",
      parent: "OAXACA",
    },
    {
      value: "SANTA CATARINA MECHOACÁN",
      name: "SANTA CATARINA MECHOACÁN",
      parent: "OAXACA",
    },
    {
      value: "SANTA CATARINA MINAS",
      name: "SANTA CATARINA MINAS",
      parent: "OAXACA",
    },
    {
      value: "SANTA CATARINA QUIANÉ",
      name: "SANTA CATARINA QUIANÉ",
      parent: "OAXACA",
    },
    {
      value: "SANTA CATARINA TAYATA",
      name: "SANTA CATARINA TAYATA",
      parent: "OAXACA",
    },
    {
      value: "SANTA CATARINA TICUÁ",
      name: "SANTA CATARINA TICUÁ",
      parent: "OAXACA",
    },
    {
      value: "SANTA CATARINA YOSONOTÚ",
      name: "SANTA CATARINA YOSONOTÚ",
      parent: "OAXACA",
    },
    {
      value: "SANTA CATARINA ZAPOQUILA",
      name: "SANTA CATARINA ZAPOQUILA",
      parent: "OAXACA",
    },
    {
      value: "SANTA CRUZ ACATEPEC",
      name: "SANTA CRUZ ACATEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTA CRUZ AMILPAS",
      name: "SANTA CRUZ AMILPAS",
      parent: "OAXACA",
    },
    {
      value: "SANTA CRUZ DE BRAVO",
      name: "SANTA CRUZ DE BRAVO",
      parent: "OAXACA",
    },
    {
      value: "SANTA CRUZ ITUNDUJIA",
      name: "SANTA CRUZ ITUNDUJIA",
      parent: "OAXACA",
    },
    {
      value: "SANTA CRUZ MIXTEPEC",
      name: "SANTA CRUZ MIXTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTA CRUZ NUNDACO",
      name: "SANTA CRUZ NUNDACO",
      parent: "OAXACA",
    },
    {
      value: "SANTA CRUZ PAPALUTLA",
      name: "SANTA CRUZ PAPALUTLA",
      parent: "OAXACA",
    },
    {
      value: "SANTA CRUZ TACACHE DE MINA",
      name: "SANTA CRUZ TACACHE DE MINA",
      parent: "OAXACA",
    },
    {
      value: "SANTA CRUZ TACAHUA",
      name: "SANTA CRUZ TACAHUA",
      parent: "OAXACA",
    },
    {
      value: "SANTA CRUZ TAYATA",
      name: "SANTA CRUZ TAYATA",
      parent: "OAXACA",
    },
    {
      value: "SANTA CRUZ XITLA",
      name: "SANTA CRUZ XITLA",
      parent: "OAXACA",
    },
    {
      value: "SANTA CRUZ XOXOCOTLÁN",
      name: "SANTA CRUZ XOXOCOTLÁN",
      parent: "OAXACA",
    },
    {
      value: "SANTA CRUZ ZENZONTEPEC",
      name: "SANTA CRUZ ZENZONTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTA GERTRUDIS",
      name: "SANTA GERTRUDIS",
      parent: "OAXACA",
    },
    {
      value: "SANTA INÉS DEL MONTE",
      name: "SANTA INÉS DEL MONTE",
      parent: "OAXACA",
    },
    {
      value: "SANTA INÉS YATZECHE",
      name: "SANTA INÉS YATZECHE",
      parent: "OAXACA",
    },
    {
      value: "SANTA LUCÍA DEL CAMINO",
      name: "SANTA LUCÍA DEL CAMINO",
      parent: "OAXACA",
    },
    {
      value: "SANTA LUCÍA MIAHUATLÁN",
      name: "SANTA LUCÍA MIAHUATLÁN",
      parent: "OAXACA",
    },
    {
      value: "SANTA LUCÍA MONTEVERDE",
      name: "SANTA LUCÍA MONTEVERDE",
      parent: "OAXACA",
    },
    {
      value: "SANTA LUCÍA OCOTLÁN",
      name: "SANTA LUCÍA OCOTLÁN",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA ALOTEPEC",
      name: "SANTA MARÍA ALOTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA APAZCO",
      name: "SANTA MARÍA APAZCO",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA LA ASUNCIÓN",
      name: "SANTA MARÍA LA ASUNCIÓN",
      parent: "OAXACA",
    },
    {
      value: "HEROICA CIUDAD DE TLAXIACO",
      name: "HEROICA CIUDAD DE TLAXIACO",
      parent: "OAXACA",
    },
    {
      value: "AYOQUEZCO DE ALDAMA",
      name: "AYOQUEZCO DE ALDAMA",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA ATZOMPA",
      name: "SANTA MARÍA ATZOMPA",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA CAMOTLÁN",
      name: "SANTA MARÍA CAMOTLÁN",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA COLOTEPEC",
      name: "SANTA MARÍA COLOTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA CORTIJO",
      name: "SANTA MARÍA CORTIJO",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA COYOTEPEC",
      name: "SANTA MARÍA COYOTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA CHACHOÁPAM",
      name: "SANTA MARÍA CHACHOÁPAM",
      parent: "OAXACA",
    },
    {
      value: "VILLA DE CHILAPA DE DÍAZ",
      name: "VILLA DE CHILAPA DE DÍAZ",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA CHILCHOTLA",
      name: "SANTA MARÍA CHILCHOTLA",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA CHIMALAPA",
      name: "SANTA MARÍA CHIMALAPA",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA DEL ROSARIO",
      name: "SANTA MARÍA DEL ROSARIO",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA DEL TULE",
      name: "SANTA MARÍA DEL TULE",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA ECATEPEC",
      name: "SANTA MARÍA ECATEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA GUELACÉ",
      name: "SANTA MARÍA GUELACÉ",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA GUIENAGATI",
      name: "SANTA MARÍA GUIENAGATI",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA HUATULCO",
      name: "SANTA MARÍA HUATULCO",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA HUAZOLOTITLÁN",
      name: "SANTA MARÍA HUAZOLOTITLÁN",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA IPALAPA",
      name: "SANTA MARÍA IPALAPA",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA IXCATLÁN",
      name: "SANTA MARÍA IXCATLÁN",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA JACATEPEC",
      name: "SANTA MARÍA JACATEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA JALAPA DEL MARQUÉS",
      name: "SANTA MARÍA JALAPA DEL MARQUÉS",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA JALTIANGUIS",
      name: "SANTA MARÍA JALTIANGUIS",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA LACHIXÍO",
      name: "SANTA MARÍA LACHIXÍO",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA MIXTEQUILLA",
      name: "SANTA MARÍA MIXTEQUILLA",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA NATIVITAS",
      name: "SANTA MARÍA NATIVITAS",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA NDUAYACO",
      name: "SANTA MARÍA NDUAYACO",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA OZOLOTEPEC",
      name: "SANTA MARÍA OZOLOTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA PÁPALO",
      name: "SANTA MARÍA PÁPALO",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA PEÑOLES",
      name: "SANTA MARÍA PEÑOLES",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA PETAPA",
      name: "SANTA MARÍA PETAPA",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA QUIEGOLANI",
      name: "SANTA MARÍA QUIEGOLANI",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA SOLA",
      name: "SANTA MARÍA SOLA",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA TATALTEPEC",
      name: "SANTA MARÍA TATALTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA TECOMAVACA",
      name: "SANTA MARÍA TECOMAVACA",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA TEMAXCALAPA",
      name: "SANTA MARÍA TEMAXCALAPA",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA TEMAXCALTEPEC",
      name: "SANTA MARÍA TEMAXCALTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA TEOPOXCO",
      name: "SANTA MARÍA TEOPOXCO",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA TEPANTLALI",
      name: "SANTA MARÍA TEPANTLALI",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA TEXCATITLÁN",
      name: "SANTA MARÍA TEXCATITLÁN",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA TLAHUITOLTEPEC",
      name: "SANTA MARÍA TLAHUITOLTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA TLALIXTAC",
      name: "SANTA MARÍA TLALIXTAC",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA TONAMECA",
      name: "SANTA MARÍA TONAMECA",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA TOTOLAPILLA",
      name: "SANTA MARÍA TOTOLAPILLA",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA XADANI",
      name: "SANTA MARÍA XADANI",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA YALINA",
      name: "SANTA MARÍA YALINA",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA YAVESÍA",
      name: "SANTA MARÍA YAVESÍA",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA YOLOTEPEC",
      name: "SANTA MARÍA YOLOTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA YOSOYÚA",
      name: "SANTA MARÍA YOSOYÚA",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA YUCUHITI",
      name: "SANTA MARÍA YUCUHITI",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA ZACATEPEC",
      name: "SANTA MARÍA ZACATEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA ZANIZA",
      name: "SANTA MARÍA ZANIZA",
      parent: "OAXACA",
    },
    {
      value: "SANTA MARÍA ZOQUITLÁN",
      name: "SANTA MARÍA ZOQUITLÁN",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO AMOLTEPEC",
      name: "SANTIAGO AMOLTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO APOALA",
      name: "SANTIAGO APOALA",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO APÓSTOL",
      name: "SANTIAGO APÓSTOL",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO ASTATA",
      name: "SANTIAGO ASTATA",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO ATITLÁN",
      name: "SANTIAGO ATITLÁN",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO AYUQUILILLA",
      name: "SANTIAGO AYUQUILILLA",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO CACALOXTEPEC",
      name: "SANTIAGO CACALOXTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO CAMOTLÁN",
      name: "SANTIAGO CAMOTLÁN",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO COMALTEPEC",
      name: "SANTIAGO COMALTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO CHAZUMBA",
      name: "SANTIAGO CHAZUMBA",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO CHOÁPAM",
      name: "SANTIAGO CHOÁPAM",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO DEL RÍO",
      name: "SANTIAGO DEL RÍO",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO HUAJOLOTITLÁN",
      name: "SANTIAGO HUAJOLOTITLÁN",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO HUAUCLILLA",
      name: "SANTIAGO HUAUCLILLA",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO IHUITLÁN PLUMAS",
      name: "SANTIAGO IHUITLÁN PLUMAS",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO IXCUINTEPEC",
      name: "SANTIAGO IXCUINTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO IXTAYUTLA",
      name: "SANTIAGO IXTAYUTLA",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO JAMILTEPEC",
      name: "SANTIAGO JAMILTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO JOCOTEPEC",
      name: "SANTIAGO JOCOTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO JUXTLAHUACA",
      name: "SANTIAGO JUXTLAHUACA",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO LACHIGUIRI",
      name: "SANTIAGO LACHIGUIRI",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO LALOPA",
      name: "SANTIAGO LALOPA",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO LAOLLAGA",
      name: "SANTIAGO LAOLLAGA",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO LAXOPA",
      name: "SANTIAGO LAXOPA",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO LLANO GRANDE",
      name: "SANTIAGO LLANO GRANDE",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO MATATLÁN",
      name: "SANTIAGO MATATLÁN",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO MILTEPEC",
      name: "SANTIAGO MILTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO MINAS",
      name: "SANTIAGO MINAS",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO NACALTEPEC",
      name: "SANTIAGO NACALTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO NEJAPILLA",
      name: "SANTIAGO NEJAPILLA",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO NUNDICHE",
      name: "SANTIAGO NUNDICHE",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO NUYOÓ",
      name: "SANTIAGO NUYOÓ",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO PINOTEPA NACIONAL",
      name: "SANTIAGO PINOTEPA NACIONAL",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO SUCHILQUITONGO",
      name: "SANTIAGO SUCHILQUITONGO",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO TAMAZOLA",
      name: "SANTIAGO TAMAZOLA",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO TAPEXTLA",
      name: "SANTIAGO TAPEXTLA",
      parent: "OAXACA",
    },
    {
      value: "VILLA TEJÚPAM DE LA UNIÓN",
      name: "VILLA TEJÚPAM DE LA UNIÓN",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO TENANGO",
      name: "SANTIAGO TENANGO",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO TEPETLAPA",
      name: "SANTIAGO TEPETLAPA",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO TETEPEC",
      name: "SANTIAGO TETEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO TEXCALCINGO",
      name: "SANTIAGO TEXCALCINGO",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO TEXTITLÁN",
      name: "SANTIAGO TEXTITLÁN",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO TILANTONGO",
      name: "SANTIAGO TILANTONGO",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO TILLO",
      name: "SANTIAGO TILLO",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO TLAZOYALTEPEC",
      name: "SANTIAGO TLAZOYALTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO XANICA",
      name: "SANTIAGO XANICA",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO XIACUÍ",
      name: "SANTIAGO XIACUÍ",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO YAITEPEC",
      name: "SANTIAGO YAITEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO YAVEO",
      name: "SANTIAGO YAVEO",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO YOLOMÉCATL",
      name: "SANTIAGO YOLOMÉCATL",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO YOSONDÚA",
      name: "SANTIAGO YOSONDÚA",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO YUCUYACHI",
      name: "SANTIAGO YUCUYACHI",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO ZACATEPEC",
      name: "SANTIAGO ZACATEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTIAGO ZOOCHILA",
      name: "SANTIAGO ZOOCHILA",
      parent: "OAXACA",
    },
    {
      value: "NUEVO ZOQUIÁPAM",
      name: "NUEVO ZOQUIÁPAM",
      parent: "OAXACA",
    },
    {
      value: "SANTO DOMINGO INGENIO",
      name: "SANTO DOMINGO INGENIO",
      parent: "OAXACA",
    },
    {
      value: "SANTO DOMINGO ALBARRADAS",
      name: "SANTO DOMINGO ALBARRADAS",
      parent: "OAXACA",
    },
    {
      value: "SANTO DOMINGO ARMENTA",
      name: "SANTO DOMINGO ARMENTA",
      parent: "OAXACA",
    },
    {
      value: "SANTO DOMINGO CHIHUITÁN",
      name: "SANTO DOMINGO CHIHUITÁN",
      parent: "OAXACA",
    },
    {
      value: "SANTO DOMINGO DE MORELOS",
      name: "SANTO DOMINGO DE MORELOS",
      parent: "OAXACA",
    },
    {
      value: "SANTO DOMINGO IXCATLÁN",
      name: "SANTO DOMINGO IXCATLÁN",
      parent: "OAXACA",
    },
    {
      value: "SANTO DOMINGO NUXAÁ",
      name: "SANTO DOMINGO NUXAÁ",
      parent: "OAXACA",
    },
    {
      value: "SANTO DOMINGO OZOLOTEPEC",
      name: "SANTO DOMINGO OZOLOTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTO DOMINGO PETAPA",
      name: "SANTO DOMINGO PETAPA",
      parent: "OAXACA",
    },
    {
      value: "SANTO DOMINGO ROAYAGA",
      name: "SANTO DOMINGO ROAYAGA",
      parent: "OAXACA",
    },
    {
      value: "SANTO DOMINGO TEHUANTEPEC",
      name: "SANTO DOMINGO TEHUANTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTO DOMINGO TEOJOMULCO",
      name: "SANTO DOMINGO TEOJOMULCO",
      parent: "OAXACA",
    },
    {
      value: "SANTO DOMINGO TEPUXTEPEC",
      name: "SANTO DOMINGO TEPUXTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTO DOMINGO TLATAYÁPAM",
      name: "SANTO DOMINGO TLATAYÁPAM",
      parent: "OAXACA",
    },
    {
      value: "SANTO DOMINGO TOMALTEPEC",
      name: "SANTO DOMINGO TOMALTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTO DOMINGO TONALÁ",
      name: "SANTO DOMINGO TONALÁ",
      parent: "OAXACA",
    },
    {
      value: "SANTO DOMINGO TONALTEPEC",
      name: "SANTO DOMINGO TONALTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTO DOMINGO XAGACÍA",
      name: "SANTO DOMINGO XAGACÍA",
      parent: "OAXACA",
    },
    {
      value: "SANTO DOMINGO YANHUITLÁN",
      name: "SANTO DOMINGO YANHUITLÁN",
      parent: "OAXACA",
    },
    {
      value: "SANTO DOMINGO YODOHINO",
      name: "SANTO DOMINGO YODOHINO",
      parent: "OAXACA",
    },
    {
      value: "SANTO DOMINGO ZANATEPEC",
      name: "SANTO DOMINGO ZANATEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTOS REYES NOPALA",
      name: "SANTOS REYES NOPALA",
      parent: "OAXACA",
    },
    {
      value: "SANTOS REYES PÁPALO",
      name: "SANTOS REYES PÁPALO",
      parent: "OAXACA",
    },
    {
      value: "SANTOS REYES TEPEJILLO",
      name: "SANTOS REYES TEPEJILLO",
      parent: "OAXACA",
    },
    {
      value: "SANTOS REYES YUCUNÁ",
      name: "SANTOS REYES YUCUNÁ",
      parent: "OAXACA",
    },
    {
      value: "SANTO TOMÁS JALIEZA",
      name: "SANTO TOMÁS JALIEZA",
      parent: "OAXACA",
    },
    {
      value: "SANTO TOMÁS MAZALTEPEC",
      name: "SANTO TOMÁS MAZALTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTO TOMÁS OCOTEPEC",
      name: "SANTO TOMÁS OCOTEPEC",
      parent: "OAXACA",
    },
    {
      value: "SANTO TOMÁS TAMAZULAPAN",
      name: "SANTO TOMÁS TAMAZULAPAN",
      parent: "OAXACA",
    },
    {
      value: "SAN VICENTE COATLÁN",
      name: "SAN VICENTE COATLÁN",
      parent: "OAXACA",
    },
    {
      value: "SAN VICENTE LACHIXÍO",
      name: "SAN VICENTE LACHIXÍO",
      parent: "OAXACA",
    },
    {
      value: "SAN VICENTE NUÑÚ",
      name: "SAN VICENTE NUÑÚ",
      parent: "OAXACA",
    },
    {
      value: "SILACAYOÁPAM",
      name: "SILACAYOÁPAM",
      parent: "OAXACA",
    },
    {
      value: "SITIO DE XITLAPEHUA",
      name: "SITIO DE XITLAPEHUA",
      parent: "OAXACA",
    },
    {
      value: "SOLEDAD ETLA",
      name: "SOLEDAD ETLA",
      parent: "OAXACA",
    },
    {
      value: "VILLA DE TAMAZULÁPAM DEL PROGRESO",
      name: "VILLA DE TAMAZULÁPAM DEL PROGRESO",
      parent: "OAXACA",
    },
    {
      value: "TANETZE DE ZARAGOZA",
      name: "TANETZE DE ZARAGOZA",
      parent: "OAXACA",
    },
    {
      value: "TANICHE",
      name: "TANICHE",
      parent: "OAXACA",
    },
    {
      value: "TATALTEPEC DE VALDÉS",
      name: "TATALTEPEC DE VALDÉS",
      parent: "OAXACA",
    },
    {
      value: "TEOCOCUILCO DE MARCOS PÉREZ",
      name: "TEOCOCUILCO DE MARCOS PÉREZ",
      parent: "OAXACA",
    },
    {
      value: "TEOTITLÁN DE FLORES MAGÓN",
      name: "TEOTITLÁN DE FLORES MAGÓN",
      parent: "OAXACA",
    },
    {
      value: "TEOTITLÁN DEL VALLE",
      name: "TEOTITLÁN DEL VALLE",
      parent: "OAXACA",
    },
    {
      value: "TEOTONGO",
      name: "TEOTONGO",
      parent: "OAXACA",
    },
    {
      value: "TEPELMEME VILLA DE MORELOS",
      name: "TEPELMEME VILLA DE MORELOS",
      parent: "OAXACA",
    },
    {
      value:
        "HEROICA VILLA TEZOATLÁN DE SEGURA Y LUNA, CUNA DE LA INDEPENDENCIA DE OAXACA",
      name: "HEROICA VILLA TEZOATLÁN DE SEGURA Y LUNA, CUNA DE LA INDEPENDENCIA DE OAXACA",
      parent: "OAXACA",
    },
    {
      value: "SAN JERÓNIMO TLACOCHAHUAYA",
      name: "SAN JERÓNIMO TLACOCHAHUAYA",
      parent: "OAXACA",
    },
    {
      value: "TLACOLULA DE MATAMOROS",
      name: "TLACOLULA DE MATAMOROS",
      parent: "OAXACA",
    },
    {
      value: "TLACOTEPEC PLUMAS",
      name: "TLACOTEPEC PLUMAS",
      parent: "OAXACA",
    },
    {
      value: "TLALIXTAC DE CABRERA",
      name: "TLALIXTAC DE CABRERA",
      parent: "OAXACA",
    },
    {
      value: "TOTONTEPEC VILLA DE MORELOS",
      name: "TOTONTEPEC VILLA DE MORELOS",
      parent: "OAXACA",
    },
    {
      value: "TRINIDAD ZAACHILA",
      name: "TRINIDAD ZAACHILA",
      parent: "OAXACA",
    },
    {
      value: "LA TRINIDAD VISTA HERMOSA",
      name: "LA TRINIDAD VISTA HERMOSA",
      parent: "OAXACA",
    },
    {
      value: "UNIÓN HIDALGO",
      name: "UNIÓN HIDALGO",
      parent: "OAXACA",
    },
    {
      value: "VALERIO TRUJANO",
      name: "VALERIO TRUJANO",
      parent: "OAXACA",
    },
    {
      value: "SAN JUAN BAUTISTA VALLE NACIONAL",
      name: "SAN JUAN BAUTISTA VALLE NACIONAL",
      parent: "OAXACA",
    },
    {
      value: "VILLA DÍAZ ORDAZ",
      name: "VILLA DÍAZ ORDAZ",
      parent: "OAXACA",
    },
    {
      value: "YAXE",
      name: "YAXE",
      parent: "OAXACA",
    },
    {
      value: "MAGDALENA YODOCONO DE PORFIRIO DÍAZ",
      name: "MAGDALENA YODOCONO DE PORFIRIO DÍAZ",
      parent: "OAXACA",
    },
    {
      value: "YOGANA",
      name: "YOGANA",
      parent: "OAXACA",
    },
    {
      value: "YUTANDUCHI DE GUERRERO",
      name: "YUTANDUCHI DE GUERRERO",
      parent: "OAXACA",
    },
    {
      value: "VILLA DE ZAACHILA",
      name: "VILLA DE ZAACHILA",
      parent: "OAXACA",
    },
    {
      value: "SAN MATEO YUCUTINDOO",
      name: "SAN MATEO YUCUTINDOO",
      parent: "OAXACA",
    },
    {
      value: "ZAPOTITLÁN LAGUNAS",
      name: "ZAPOTITLÁN LAGUNAS",
      parent: "OAXACA",
    },
    {
      value: "ZAPOTITLÁN PALMAS",
      name: "ZAPOTITLÁN PALMAS",
      parent: "OAXACA",
    },
    {
      value: "SANTA INÉS DE ZARAGOZA",
      name: "SANTA INÉS DE ZARAGOZA",
      parent: "OAXACA",
    },
    {
      value: "ZIMATLÁN DE ÁLVAREZ",
      name: "ZIMATLÁN DE ÁLVAREZ",
      parent: "OAXACA",
    },
    {
      value: "ACAJETE",
      name: "ACAJETE",
      parent: "PUEBLA",
    },
    {
      value: "ACATENO",
      name: "ACATENO",
      parent: "PUEBLA",
    },
    {
      value: "ACATLÁN",
      name: "ACATLÁN",
      parent: "PUEBLA",
    },
    {
      value: "ACATZINGO",
      name: "ACATZINGO",
      parent: "PUEBLA",
    },
    {
      value: "ACTEOPAN",
      name: "ACTEOPAN",
      parent: "PUEBLA",
    },
    {
      value: "AHUACATLÁN",
      name: "AHUACATLÁN",
      parent: "PUEBLA",
    },
    {
      value: "AHUATLÁN",
      name: "AHUATLÁN",
      parent: "PUEBLA",
    },
    {
      value: "AHUAZOTEPEC",
      name: "AHUAZOTEPEC",
      parent: "PUEBLA",
    },
    {
      value: "AHUEHUETITLA",
      name: "AHUEHUETITLA",
      parent: "PUEBLA",
    },
    {
      value: "AJALPAN",
      name: "AJALPAN",
      parent: "PUEBLA",
    },
    {
      value: "ALBINO ZERTUCHE",
      name: "ALBINO ZERTUCHE",
      parent: "PUEBLA",
    },
    {
      value: "ALJOJUCA",
      name: "ALJOJUCA",
      parent: "PUEBLA",
    },
    {
      value: "ALTEPEXI",
      name: "ALTEPEXI",
      parent: "PUEBLA",
    },
    {
      value: "AMIXTLÁN",
      name: "AMIXTLÁN",
      parent: "PUEBLA",
    },
    {
      value: "AMOZOC",
      name: "AMOZOC",
      parent: "PUEBLA",
    },
    {
      value: "AQUIXTLA",
      name: "AQUIXTLA",
      parent: "PUEBLA",
    },
    {
      value: "ATEMPAN",
      name: "ATEMPAN",
      parent: "PUEBLA",
    },
    {
      value: "ATEXCAL",
      name: "ATEXCAL",
      parent: "PUEBLA",
    },
    {
      value: "ATLIXCO",
      name: "ATLIXCO",
      parent: "PUEBLA",
    },
    {
      value: "ATOYATEMPAN",
      name: "ATOYATEMPAN",
      parent: "PUEBLA",
    },
    {
      value: "ATZALA",
      name: "ATZALA",
      parent: "PUEBLA",
    },
    {
      value: "ATZITZIHUACÁN",
      name: "ATZITZIHUACÁN",
      parent: "PUEBLA",
    },
    {
      value: "ATZITZINTLA",
      name: "ATZITZINTLA",
      parent: "PUEBLA",
    },
    {
      value: "AXUTLA",
      name: "AXUTLA",
      parent: "PUEBLA",
    },
    {
      value: "AYOTOXCO DE GUERRERO",
      name: "AYOTOXCO DE GUERRERO",
      parent: "PUEBLA",
    },
    {
      value: "CALPAN",
      name: "CALPAN",
      parent: "PUEBLA",
    },
    {
      value: "CALTEPEC",
      name: "CALTEPEC",
      parent: "PUEBLA",
    },
    {
      value: "CAMOCUAUTLA",
      name: "CAMOCUAUTLA",
      parent: "PUEBLA",
    },
    {
      value: "CAXHUACAN",
      name: "CAXHUACAN",
      parent: "PUEBLA",
    },
    {
      value: "COATEPEC",
      name: "COATEPEC",
      parent: "PUEBLA",
    },
    {
      value: "COATZINGO",
      name: "COATZINGO",
      parent: "PUEBLA",
    },
    {
      value: "COHETZALA",
      name: "COHETZALA",
      parent: "PUEBLA",
    },
    {
      value: "COHUECAN",
      name: "COHUECAN",
      parent: "PUEBLA",
    },
    {
      value: "CORONANGO",
      name: "CORONANGO",
      parent: "PUEBLA",
    },
    {
      value: "COXCATLÁN",
      name: "COXCATLÁN",
      parent: "PUEBLA",
    },
    {
      value: "COYOMEAPAN",
      name: "COYOMEAPAN",
      parent: "PUEBLA",
    },
    {
      value: "COYOTEPEC",
      name: "COYOTEPEC",
      parent: "PUEBLA",
    },
    {
      value: "CUAPIAXTLA DE MADERO",
      name: "CUAPIAXTLA DE MADERO",
      parent: "PUEBLA",
    },
    {
      value: "CUAUTEMPAN",
      name: "CUAUTEMPAN",
      parent: "PUEBLA",
    },
    {
      value: "CUAUTINCHÁN",
      name: "CUAUTINCHÁN",
      parent: "PUEBLA",
    },
    {
      value: "CUAUTLANCINGO",
      name: "CUAUTLANCINGO",
      parent: "PUEBLA",
    },
    {
      value: "CUAYUCA DE ANDRADE",
      name: "CUAYUCA DE ANDRADE",
      parent: "PUEBLA",
    },
    {
      value: "CUETZALAN DEL PROGRESO",
      name: "CUETZALAN DEL PROGRESO",
      parent: "PUEBLA",
    },
    {
      value: "CUYOACO",
      name: "CUYOACO",
      parent: "PUEBLA",
    },
    {
      value: "CHALCHICOMULA DE SESMA",
      name: "CHALCHICOMULA DE SESMA",
      parent: "PUEBLA",
    },
    {
      value: "CHAPULCO",
      name: "CHAPULCO",
      parent: "PUEBLA",
    },
    {
      value: "CHIAUTLA",
      name: "CHIAUTLA",
      parent: "PUEBLA",
    },
    {
      value: "CHIAUTZINGO",
      name: "CHIAUTZINGO",
      parent: "PUEBLA",
    },
    {
      value: "CHICONCUAUTLA",
      name: "CHICONCUAUTLA",
      parent: "PUEBLA",
    },
    {
      value: "CHICHIQUILA",
      name: "CHICHIQUILA",
      parent: "PUEBLA",
    },
    {
      value: "CHIETLA",
      name: "CHIETLA",
      parent: "PUEBLA",
    },
    {
      value: "CHIGMECATITLÁN",
      name: "CHIGMECATITLÁN",
      parent: "PUEBLA",
    },
    {
      value: "CHIGNAHUAPAN",
      name: "CHIGNAHUAPAN",
      parent: "PUEBLA",
    },
    {
      value: "CHIGNAUTLA",
      name: "CHIGNAUTLA",
      parent: "PUEBLA",
    },
    {
      value: "CHILA",
      name: "CHILA",
      parent: "PUEBLA",
    },
    {
      value: "CHILA DE LA SAL",
      name: "CHILA DE LA SAL",
      parent: "PUEBLA",
    },
    {
      value: "HONEY",
      name: "HONEY",
      parent: "PUEBLA",
    },
    {
      value: "CHILCHOTLA",
      name: "CHILCHOTLA",
      parent: "PUEBLA",
    },
    {
      value: "CHINANTLA",
      name: "CHINANTLA",
      parent: "PUEBLA",
    },
    {
      value: "DOMINGO ARENAS",
      name: "DOMINGO ARENAS",
      parent: "PUEBLA",
    },
    {
      value: "ELOXOCHITLÁN",
      name: "ELOXOCHITLÁN",
      parent: "PUEBLA",
    },
    {
      value: "EPATLÁN",
      name: "EPATLÁN",
      parent: "PUEBLA",
    },
    {
      value: "ESPERANZA",
      name: "ESPERANZA",
      parent: "PUEBLA",
    },
    {
      value: "FRANCISCO Z. MENA",
      name: "FRANCISCO Z. MENA",
      parent: "PUEBLA",
    },
    {
      value: "GENERAL FELIPE ÁNGELES",
      name: "GENERAL FELIPE ÁNGELES",
      parent: "PUEBLA",
    },
    {
      value: "GUADALUPE",
      name: "GUADALUPE",
      parent: "PUEBLA",
    },
    {
      value: "GUADALUPE VICTORIA",
      name: "GUADALUPE VICTORIA",
      parent: "PUEBLA",
    },
    {
      value: "HERMENEGILDO GALEANA",
      name: "HERMENEGILDO GALEANA",
      parent: "PUEBLA",
    },
    {
      value: "HUAQUECHULA",
      name: "HUAQUECHULA",
      parent: "PUEBLA",
    },
    {
      value: "HUATLATLAUCA",
      name: "HUATLATLAUCA",
      parent: "PUEBLA",
    },
    {
      value: "HUAUCHINANGO",
      name: "HUAUCHINANGO",
      parent: "PUEBLA",
    },
    {
      value: "HUEHUETLA",
      name: "HUEHUETLA",
      parent: "PUEBLA",
    },
    {
      value: "HUEHUETLÁN EL CHICO",
      name: "HUEHUETLÁN EL CHICO",
      parent: "PUEBLA",
    },
    {
      value: "HUEJOTZINGO",
      name: "HUEJOTZINGO",
      parent: "PUEBLA",
    },
    {
      value: "HUEYAPAN",
      name: "HUEYAPAN",
      parent: "PUEBLA",
    },
    {
      value: "HUEYTAMALCO",
      name: "HUEYTAMALCO",
      parent: "PUEBLA",
    },
    {
      value: "HUEYTLALPAN",
      name: "HUEYTLALPAN",
      parent: "PUEBLA",
    },
    {
      value: "HUITZILAN DE SERDÁN",
      name: "HUITZILAN DE SERDÁN",
      parent: "PUEBLA",
    },
    {
      value: "HUITZILTEPEC",
      name: "HUITZILTEPEC",
      parent: "PUEBLA",
    },
    {
      value: "ATLEQUIZAYAN",
      name: "ATLEQUIZAYAN",
      parent: "PUEBLA",
    },
    {
      value: "IXCAMILPA DE GUERRERO",
      name: "IXCAMILPA DE GUERRERO",
      parent: "PUEBLA",
    },
    {
      value: "IXCAQUIXTLA",
      name: "IXCAQUIXTLA",
      parent: "PUEBLA",
    },
    {
      value: "IXTACAMAXTITLÁN",
      name: "IXTACAMAXTITLÁN",
      parent: "PUEBLA",
    },
    {
      value: "IXTEPEC",
      name: "IXTEPEC",
      parent: "PUEBLA",
    },
    {
      value: "IZÚCAR DE MATAMOROS",
      name: "IZÚCAR DE MATAMOROS",
      parent: "PUEBLA",
    },
    {
      value: "JALPAN",
      name: "JALPAN",
      parent: "PUEBLA",
    },
    {
      value: "JOLALPAN",
      name: "JOLALPAN",
      parent: "PUEBLA",
    },
    {
      value: "JONOTLA",
      name: "JONOTLA",
      parent: "PUEBLA",
    },
    {
      value: "JOPALA",
      name: "JOPALA",
      parent: "PUEBLA",
    },
    {
      value: "JUAN C. BONILLA",
      name: "JUAN C. BONILLA",
      parent: "PUEBLA",
    },
    {
      value: "JUAN GALINDO",
      name: "JUAN GALINDO",
      parent: "PUEBLA",
    },
    {
      value: "JUAN N. MÉNDEZ",
      name: "JUAN N. MÉNDEZ",
      parent: "PUEBLA",
    },
    {
      value: "LAFRAGUA",
      name: "LAFRAGUA",
      parent: "PUEBLA",
    },
    {
      value: "LIBRES",
      name: "LIBRES",
      parent: "PUEBLA",
    },
    {
      value: "LA MAGDALENA TLATLAUQUITEPEC",
      name: "LA MAGDALENA TLATLAUQUITEPEC",
      parent: "PUEBLA",
    },
    {
      value: "MAZAPILTEPEC DE JUÁREZ",
      name: "MAZAPILTEPEC DE JUÁREZ",
      parent: "PUEBLA",
    },
    {
      value: "MIXTLA",
      name: "MIXTLA",
      parent: "PUEBLA",
    },
    {
      value: "MOLCAXAC",
      name: "MOLCAXAC",
      parent: "PUEBLA",
    },
    {
      value: "CAÑADA MORELOS",
      name: "CAÑADA MORELOS",
      parent: "PUEBLA",
    },
    {
      value: "NAUPAN",
      name: "NAUPAN",
      parent: "PUEBLA",
    },
    {
      value: "NAUZONTLA",
      name: "NAUZONTLA",
      parent: "PUEBLA",
    },
    {
      value: "NEALTICAN",
      name: "NEALTICAN",
      parent: "PUEBLA",
    },
    {
      value: "NICOLÁS BRAVO",
      name: "NICOLÁS BRAVO",
      parent: "PUEBLA",
    },
    {
      value: "NOPALUCAN",
      name: "NOPALUCAN",
      parent: "PUEBLA",
    },
    {
      value: "OCOTEPEC",
      name: "OCOTEPEC",
      parent: "PUEBLA",
    },
    {
      value: "OCOYUCAN",
      name: "OCOYUCAN",
      parent: "PUEBLA",
    },
    {
      value: "OLINTLA",
      name: "OLINTLA",
      parent: "PUEBLA",
    },
    {
      value: "ORIENTAL",
      name: "ORIENTAL",
      parent: "PUEBLA",
    },
    {
      value: "PAHUATLÁN",
      name: "PAHUATLÁN",
      parent: "PUEBLA",
    },
    {
      value: "PALMAR DE BRAVO",
      name: "PALMAR DE BRAVO",
      parent: "PUEBLA",
    },
    {
      value: "PANTEPEC",
      name: "PANTEPEC",
      parent: "PUEBLA",
    },
    {
      value: "PETLALCINGO",
      name: "PETLALCINGO",
      parent: "PUEBLA",
    },
    {
      value: "PIAXTLA",
      name: "PIAXTLA",
      parent: "PUEBLA",
    },
    {
      value: "PUEBLA",
      name: "PUEBLA",
      parent: "PUEBLA",
    },
    {
      value: "QUECHOLAC",
      name: "QUECHOLAC",
      parent: "PUEBLA",
    },
    {
      value: "QUIMIXTLÁN",
      name: "QUIMIXTLÁN",
      parent: "PUEBLA",
    },
    {
      value: "RAFAEL LARA GRAJALES",
      name: "RAFAEL LARA GRAJALES",
      parent: "PUEBLA",
    },
    {
      value: "LOS REYES DE JUÁREZ",
      name: "LOS REYES DE JUÁREZ",
      parent: "PUEBLA",
    },
    {
      value: "SAN ANDRÉS CHOLULA",
      name: "SAN ANDRÉS CHOLULA",
      parent: "PUEBLA",
    },
    {
      value: "SAN ANTONIO CAÑADA",
      name: "SAN ANTONIO CAÑADA",
      parent: "PUEBLA",
    },
    {
      value: "SAN DIEGO LA MESA TOCHIMILTZINGO",
      name: "SAN DIEGO LA MESA TOCHIMILTZINGO",
      parent: "PUEBLA",
    },
    {
      value: "SAN FELIPE TEOTLALCINGO",
      name: "SAN FELIPE TEOTLALCINGO",
      parent: "PUEBLA",
    },
    {
      value: "SAN FELIPE TEPATLÁN",
      name: "SAN FELIPE TEPATLÁN",
      parent: "PUEBLA",
    },
    {
      value: "SAN GABRIEL CHILAC",
      name: "SAN GABRIEL CHILAC",
      parent: "PUEBLA",
    },
    {
      value: "SAN GREGORIO ATZOMPA",
      name: "SAN GREGORIO ATZOMPA",
      parent: "PUEBLA",
    },
    {
      value: "SAN JERÓNIMO TECUANIPAN",
      name: "SAN JERÓNIMO TECUANIPAN",
      parent: "PUEBLA",
    },
    {
      value: "SAN JERÓNIMO XAYACATLÁN",
      name: "SAN JERÓNIMO XAYACATLÁN",
      parent: "PUEBLA",
    },
    {
      value: "SAN JOSÉ CHIAPA",
      name: "SAN JOSÉ CHIAPA",
      parent: "PUEBLA",
    },
    {
      value: "SAN JOSÉ MIAHUATLÁN",
      name: "SAN JOSÉ MIAHUATLÁN",
      parent: "PUEBLA",
    },
    {
      value: "SAN JUAN ATENCO",
      name: "SAN JUAN ATENCO",
      parent: "PUEBLA",
    },
    {
      value: "SAN JUAN ATZOMPA",
      name: "SAN JUAN ATZOMPA",
      parent: "PUEBLA",
    },
    {
      value: "SAN MARTÍN TEXMELUCAN",
      name: "SAN MARTÍN TEXMELUCAN",
      parent: "PUEBLA",
    },
    {
      value: "SAN MARTÍN TOTOLTEPEC",
      name: "SAN MARTÍN TOTOLTEPEC",
      parent: "PUEBLA",
    },
    {
      value: "SAN MATÍAS TLALANCALECA",
      name: "SAN MATÍAS TLALANCALECA",
      parent: "PUEBLA",
    },
    {
      value: "SAN MIGUEL IXITLÁN",
      name: "SAN MIGUEL IXITLÁN",
      parent: "PUEBLA",
    },
    {
      value: "SAN MIGUEL XOXTLA",
      name: "SAN MIGUEL XOXTLA",
      parent: "PUEBLA",
    },
    {
      value: "SAN NICOLÁS BUENOS AIRES",
      name: "SAN NICOLÁS BUENOS AIRES",
      parent: "PUEBLA",
    },
    {
      value: "SAN NICOLÁS DE LOS RANCHOS",
      name: "SAN NICOLÁS DE LOS RANCHOS",
      parent: "PUEBLA",
    },
    {
      value: "SAN PABLO ANICANO",
      name: "SAN PABLO ANICANO",
      parent: "PUEBLA",
    },
    {
      value: "SAN PEDRO CHOLULA",
      name: "SAN PEDRO CHOLULA",
      parent: "PUEBLA",
    },
    {
      value: "SAN PEDRO YELOIXTLAHUACA",
      name: "SAN PEDRO YELOIXTLAHUACA",
      parent: "PUEBLA",
    },
    {
      value: "SAN SALVADOR EL SECO",
      name: "SAN SALVADOR EL SECO",
      parent: "PUEBLA",
    },
    {
      value: "SAN SALVADOR EL VERDE",
      name: "SAN SALVADOR EL VERDE",
      parent: "PUEBLA",
    },
    {
      value: "SAN SALVADOR HUIXCOLOTLA",
      name: "SAN SALVADOR HUIXCOLOTLA",
      parent: "PUEBLA",
    },
    {
      value: "SAN SEBASTIÁN TLACOTEPEC",
      name: "SAN SEBASTIÁN TLACOTEPEC",
      parent: "PUEBLA",
    },
    {
      value: "SANTA CATARINA TLALTEMPAN",
      name: "SANTA CATARINA TLALTEMPAN",
      parent: "PUEBLA",
    },
    {
      value: "SANTA INÉS AHUATEMPAN",
      name: "SANTA INÉS AHUATEMPAN",
      parent: "PUEBLA",
    },
    {
      value: "SANTA ISABEL CHOLULA",
      name: "SANTA ISABEL CHOLULA",
      parent: "PUEBLA",
    },
    {
      value: "SANTIAGO MIAHUATLÁN",
      name: "SANTIAGO MIAHUATLÁN",
      parent: "PUEBLA",
    },
    {
      value: "HUEHUETLÁN EL GRANDE",
      name: "HUEHUETLÁN EL GRANDE",
      parent: "PUEBLA",
    },
    {
      value: "SANTO TOMÁS HUEYOTLIPAN",
      name: "SANTO TOMÁS HUEYOTLIPAN",
      parent: "PUEBLA",
    },
    {
      value: "SOLTEPEC",
      name: "SOLTEPEC",
      parent: "PUEBLA",
    },
    {
      value: "TECALI DE HERRERA",
      name: "TECALI DE HERRERA",
      parent: "PUEBLA",
    },
    {
      value: "TECAMACHALCO",
      name: "TECAMACHALCO",
      parent: "PUEBLA",
    },
    {
      value: "TECOMATLÁN",
      name: "TECOMATLÁN",
      parent: "PUEBLA",
    },
    {
      value: "TEHUACÁN",
      name: "TEHUACÁN",
      parent: "PUEBLA",
    },
    {
      value: "TEHUITZINGO",
      name: "TEHUITZINGO",
      parent: "PUEBLA",
    },
    {
      value: "TENAMPULCO",
      name: "TENAMPULCO",
      parent: "PUEBLA",
    },
    {
      value: "TEOPANTLÁN",
      name: "TEOPANTLÁN",
      parent: "PUEBLA",
    },
    {
      value: "TEOTLALCO",
      name: "TEOTLALCO",
      parent: "PUEBLA",
    },
    {
      value: "TEPANCO DE LÓPEZ",
      name: "TEPANCO DE LÓPEZ",
      parent: "PUEBLA",
    },
    {
      value: "TEPANGO DE RODRÍGUEZ",
      name: "TEPANGO DE RODRÍGUEZ",
      parent: "PUEBLA",
    },
    {
      value: "TEPATLAXCO DE HIDALGO",
      name: "TEPATLAXCO DE HIDALGO",
      parent: "PUEBLA",
    },
    {
      value: "TEPEACA",
      name: "TEPEACA",
      parent: "PUEBLA",
    },
    {
      value: "TEPEMAXALCO",
      name: "TEPEMAXALCO",
      parent: "PUEBLA",
    },
    {
      value: "TEPEOJUMA",
      name: "TEPEOJUMA",
      parent: "PUEBLA",
    },
    {
      value: "TEPETZINTLA",
      name: "TEPETZINTLA",
      parent: "PUEBLA",
    },
    {
      value: "TEPEXCO",
      name: "TEPEXCO",
      parent: "PUEBLA",
    },
    {
      value: "TEPEXI DE RODRÍGUEZ",
      name: "TEPEXI DE RODRÍGUEZ",
      parent: "PUEBLA",
    },
    {
      value: "TEPEYAHUALCO",
      name: "TEPEYAHUALCO",
      parent: "PUEBLA",
    },
    {
      value: "TEPEYAHUALCO DE CUAUHTÉMOC",
      name: "TEPEYAHUALCO DE CUAUHTÉMOC",
      parent: "PUEBLA",
    },
    {
      value: "TETELA DE OCAMPO",
      name: "TETELA DE OCAMPO",
      parent: "PUEBLA",
    },
    {
      value: "TETELES DE AVILA CASTILLO",
      name: "TETELES DE AVILA CASTILLO",
      parent: "PUEBLA",
    },
    {
      value: "TEZIUTLÁN",
      name: "TEZIUTLÁN",
      parent: "PUEBLA",
    },
    {
      value: "TIANGUISMANALCO",
      name: "TIANGUISMANALCO",
      parent: "PUEBLA",
    },
    {
      value: "TILAPA",
      name: "TILAPA",
      parent: "PUEBLA",
    },
    {
      value: "TLACOTEPEC DE BENITO JUÁREZ",
      name: "TLACOTEPEC DE BENITO JUÁREZ",
      parent: "PUEBLA",
    },
    {
      value: "TLACUILOTEPEC",
      name: "TLACUILOTEPEC",
      parent: "PUEBLA",
    },
    {
      value: "TLACHICHUCA",
      name: "TLACHICHUCA",
      parent: "PUEBLA",
    },
    {
      value: "TLAHUAPAN",
      name: "TLAHUAPAN",
      parent: "PUEBLA",
    },
    {
      value: "TLALTENANGO",
      name: "TLALTENANGO",
      parent: "PUEBLA",
    },
    {
      value: "TLANEPANTLA",
      name: "TLANEPANTLA",
      parent: "PUEBLA",
    },
    {
      value: "TLAOLA",
      name: "TLAOLA",
      parent: "PUEBLA",
    },
    {
      value: "TLAPACOYA",
      name: "TLAPACOYA",
      parent: "PUEBLA",
    },
    {
      value: "TLAPANALÁ",
      name: "TLAPANALÁ",
      parent: "PUEBLA",
    },
    {
      value: "TLATLAUQUITEPEC",
      name: "TLATLAUQUITEPEC",
      parent: "PUEBLA",
    },
    {
      value: "TLAXCO",
      name: "TLAXCO",
      parent: "PUEBLA",
    },
    {
      value: "TOCHIMILCO",
      name: "TOCHIMILCO",
      parent: "PUEBLA",
    },
    {
      value: "TOCHTEPEC",
      name: "TOCHTEPEC",
      parent: "PUEBLA",
    },
    {
      value: "TOTOLTEPEC DE GUERRERO",
      name: "TOTOLTEPEC DE GUERRERO",
      parent: "PUEBLA",
    },
    {
      value: "TULCINGO",
      name: "TULCINGO",
      parent: "PUEBLA",
    },
    {
      value: "TUZAMAPAN DE GALEANA",
      name: "TUZAMAPAN DE GALEANA",
      parent: "PUEBLA",
    },
    {
      value: "TZICATLACOYAN",
      name: "TZICATLACOYAN",
      parent: "PUEBLA",
    },
    {
      value: "VENUSTIANO CARRANZA",
      name: "VENUSTIANO CARRANZA",
      parent: "PUEBLA",
    },
    {
      value: "VICENTE GUERRERO",
      name: "VICENTE GUERRERO",
      parent: "PUEBLA",
    },
    {
      value: "XAYACATLÁN DE BRAVO",
      name: "XAYACATLÁN DE BRAVO",
      parent: "PUEBLA",
    },
    {
      value: "XICOTEPEC",
      name: "XICOTEPEC",
      parent: "PUEBLA",
    },
    {
      value: "XICOTLÁN",
      name: "XICOTLÁN",
      parent: "PUEBLA",
    },
    {
      value: "XIUTETELCO",
      name: "XIUTETELCO",
      parent: "PUEBLA",
    },
    {
      value: "XOCHIAPULCO",
      name: "XOCHIAPULCO",
      parent: "PUEBLA",
    },
    {
      value: "XOCHILTEPEC",
      name: "XOCHILTEPEC",
      parent: "PUEBLA",
    },
    {
      value: "XOCHITLÁN DE VICENTE SUÁREZ",
      name: "XOCHITLÁN DE VICENTE SUÁREZ",
      parent: "PUEBLA",
    },
    {
      value: "XOCHITLÁN TODOS SANTOS",
      name: "XOCHITLÁN TODOS SANTOS",
      parent: "PUEBLA",
    },
    {
      value: "YAONÁHUAC",
      name: "YAONÁHUAC",
      parent: "PUEBLA",
    },
    {
      value: "YEHUALTEPEC",
      name: "YEHUALTEPEC",
      parent: "PUEBLA",
    },
    {
      value: "ZACAPALA",
      name: "ZACAPALA",
      parent: "PUEBLA",
    },
    {
      value: "ZACAPOAXTLA",
      name: "ZACAPOAXTLA",
      parent: "PUEBLA",
    },
    {
      value: "ZACATLÁN",
      name: "ZACATLÁN",
      parent: "PUEBLA",
    },
    {
      value: "ZAPOTITLÁN",
      name: "ZAPOTITLÁN",
      parent: "PUEBLA",
    },
    {
      value: "ZAPOTITLÁN DE MÉNDEZ",
      name: "ZAPOTITLÁN DE MÉNDEZ",
      parent: "PUEBLA",
    },
    {
      value: "ZARAGOZA",
      name: "ZARAGOZA",
      parent: "PUEBLA",
    },
    {
      value: "ZAUTLA",
      name: "ZAUTLA",
      parent: "PUEBLA",
    },
    {
      value: "ZIHUATEUTLA",
      name: "ZIHUATEUTLA",
      parent: "PUEBLA",
    },
    {
      value: "ZINACATEPEC",
      name: "ZINACATEPEC",
      parent: "PUEBLA",
    },
    {
      value: "ZONGOZOTLA",
      name: "ZONGOZOTLA",
      parent: "PUEBLA",
    },
    {
      value: "ZOQUIAPAN",
      name: "ZOQUIAPAN",
      parent: "PUEBLA",
    },
    {
      value: "ZOQUITLÁN",
      name: "ZOQUITLÁN",
      parent: "PUEBLA",
    },
    {
      value: "AMEALCO DE BONFIL",
      name: "AMEALCO DE BONFIL",
      parent: "QUERÉTARO",
    },
    {
      value: "PINAL DE AMOLES",
      name: "PINAL DE AMOLES",
      parent: "QUERÉTARO",
    },
    {
      value: "ARROYO SECO",
      name: "ARROYO SECO",
      parent: "QUERÉTARO",
    },
    {
      value: "CADEREYTA DE MONTES",
      name: "CADEREYTA DE MONTES",
      parent: "QUERÉTARO",
    },
    {
      value: "COLÓN",
      name: "COLÓN",
      parent: "QUERÉTARO",
    },
    {
      value: "CORREGIDORA",
      name: "CORREGIDORA",
      parent: "QUERÉTARO",
    },
    {
      value: "EZEQUIEL MONTES",
      name: "EZEQUIEL MONTES",
      parent: "QUERÉTARO",
    },
    {
      value: "HUIMILPAN",
      name: "HUIMILPAN",
      parent: "QUERÉTARO",
    },
    {
      value: "JALPAN DE SERRA",
      name: "JALPAN DE SERRA",
      parent: "QUERÉTARO",
    },
    {
      value: "LANDA DE MATAMOROS",
      name: "LANDA DE MATAMOROS",
      parent: "QUERÉTARO",
    },
    {
      value: "EL MARQUÉS",
      name: "EL MARQUÉS",
      parent: "QUERÉTARO",
    },
    {
      value: "PEDRO ESCOBEDO",
      name: "PEDRO ESCOBEDO",
      parent: "QUERÉTARO",
    },
    {
      value: "PEÑAMILLER",
      name: "PEÑAMILLER",
      parent: "QUERÉTARO",
    },
    {
      value: "QUERÉTARO",
      name: "QUERÉTARO",
      parent: "QUERÉTARO",
    },
    {
      value: "SAN JOAQUÍN",
      name: "SAN JOAQUÍN",
      parent: "QUERÉTARO",
    },
    {
      value: "SAN JUAN DEL RÍO",
      name: "SAN JUAN DEL RÍO",
      parent: "QUERÉTARO",
    },
    {
      value: "TEQUISQUIAPAN",
      name: "TEQUISQUIAPAN",
      parent: "QUERÉTARO",
    },
    {
      value: "TOLIMÁN",
      name: "TOLIMÁN",
      parent: "QUERÉTARO",
    },
    {
      value: "COZUMEL",
      name: "COZUMEL",
      parent: "QUINTANA ROO",
    },
    {
      value: "FELIPE CARRILLO PUERTO",
      name: "FELIPE CARRILLO PUERTO",
      parent: "QUINTANA ROO",
    },
    {
      value: "ISLA MUJERES",
      name: "ISLA MUJERES",
      parent: "QUINTANA ROO",
    },
    {
      value: "OTHÓN P. BLANCO",
      name: "OTHÓN P. BLANCO",
      parent: "QUINTANA ROO",
    },
    {
      value: "BENITO JUÁREZ",
      name: "BENITO JUÁREZ",
      parent: "QUINTANA ROO",
    },
    {
      value: "JOSÉ MARÍA MORELOS",
      name: "JOSÉ MARÍA MORELOS",
      parent: "QUINTANA ROO",
    },
    {
      value: "LÁZARO CÁRDENAS",
      name: "LÁZARO CÁRDENAS",
      parent: "QUINTANA ROO",
    },
    {
      value: "SOLIDARIDAD",
      name: "SOLIDARIDAD",
      parent: "QUINTANA ROO",
    },
    {
      value: "TULUM",
      name: "TULUM",
      parent: "QUINTANA ROO",
    },
    {
      value: "BACALAR",
      name: "BACALAR",
      parent: "QUINTANA ROO",
    },
    {
      value: "PUERTO MORELOS",
      name: "PUERTO MORELOS",
      parent: "QUINTANA ROO",
    },
    {
      value: "AHUALULCO",
      name: "AHUALULCO",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "ALAQUINES",
      name: "ALAQUINES",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "AQUISMÓN",
      name: "AQUISMÓN",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "ARMADILLO DE LOS INFANTE",
      name: "ARMADILLO DE LOS INFANTE",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "CÁRDENAS",
      name: "CÁRDENAS",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "CATORCE",
      name: "CATORCE",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "CEDRAL",
      name: "CEDRAL",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "CERRITOS",
      name: "CERRITOS",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "CERRO DE SAN PEDRO",
      name: "CERRO DE SAN PEDRO",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "CIUDAD DEL MAÍZ",
      name: "CIUDAD DEL MAÍZ",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "CIUDAD FERNÁNDEZ",
      name: "CIUDAD FERNÁNDEZ",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "TANCANHUITZ",
      name: "TANCANHUITZ",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "CIUDAD VALLES",
      name: "CIUDAD VALLES",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "COXCATLÁN",
      name: "COXCATLÁN",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "CHARCAS",
      name: "CHARCAS",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "EBANO",
      name: "EBANO",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "GUADALCÁZAR",
      name: "GUADALCÁZAR",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "HUEHUETLÁN",
      name: "HUEHUETLÁN",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "LAGUNILLAS",
      name: "LAGUNILLAS",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "MATEHUALA",
      name: "MATEHUALA",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "MEXQUITIC DE CARMONA",
      name: "MEXQUITIC DE CARMONA",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "MOCTEZUMA",
      name: "MOCTEZUMA",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "RAYÓN",
      name: "RAYÓN",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "RIOVERDE",
      name: "RIOVERDE",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "SALINAS",
      name: "SALINAS",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "SAN ANTONIO",
      name: "SAN ANTONIO",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "SAN CIRO DE ACOSTA",
      name: "SAN CIRO DE ACOSTA",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "SAN LUIS POTOSÍ",
      name: "SAN LUIS POTOSÍ",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "SAN MARTÍN CHALCHICUAUTLA",
      name: "SAN MARTÍN CHALCHICUAUTLA",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "SAN NICOLÁS TOLENTINO",
      name: "SAN NICOLÁS TOLENTINO",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "SANTA CATARINA",
      name: "SANTA CATARINA",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "SANTA MARÍA DEL RÍO",
      name: "SANTA MARÍA DEL RÍO",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "SANTO DOMINGO",
      name: "SANTO DOMINGO",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "SAN VICENTE TANCUAYALAB",
      name: "SAN VICENTE TANCUAYALAB",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "SOLEDAD DE GRACIANO SÁNCHEZ",
      name: "SOLEDAD DE GRACIANO SÁNCHEZ",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "TAMASOPO",
      name: "TAMASOPO",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "TAMAZUNCHALE",
      name: "TAMAZUNCHALE",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "TAMPACÁN",
      name: "TAMPACÁN",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "TAMPAMOLÓN CORONA",
      name: "TAMPAMOLÓN CORONA",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "TAMUÍN",
      name: "TAMUÍN",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "TANLAJÁS",
      name: "TANLAJÁS",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "TANQUIÁN DE ESCOBEDO",
      name: "TANQUIÁN DE ESCOBEDO",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "TIERRA NUEVA",
      name: "TIERRA NUEVA",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "VANEGAS",
      name: "VANEGAS",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "VENADO",
      name: "VENADO",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "VILLA DE ARRIAGA",
      name: "VILLA DE ARRIAGA",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "VILLA DE GUADALUPE",
      name: "VILLA DE GUADALUPE",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "VILLA DE LA PAZ",
      name: "VILLA DE LA PAZ",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "VILLA DE RAMOS",
      name: "VILLA DE RAMOS",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "VILLA DE REYES",
      name: "VILLA DE REYES",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "VILLA HIDALGO",
      name: "VILLA HIDALGO",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "VILLA JUÁREZ",
      name: "VILLA JUÁREZ",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "AXTLA DE TERRAZAS",
      name: "AXTLA DE TERRAZAS",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "XILITLA",
      name: "XILITLA",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "ZARAGOZA",
      name: "ZARAGOZA",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "VILLA DE ARISTA",
      name: "VILLA DE ARISTA",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "MATLAPA",
      name: "MATLAPA",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "EL NARANJO",
      name: "EL NARANJO",
      parent: "SAN LUIS POTOSÍ",
    },
    {
      value: "AHOME",
      name: "AHOME",
      parent: "SINALOA",
    },
    {
      value: "ANGOSTURA",
      name: "ANGOSTURA",
      parent: "SINALOA",
    },
    {
      value: "BADIRAGUATO",
      name: "BADIRAGUATO",
      parent: "SINALOA",
    },
    {
      value: "CONCORDIA",
      name: "CONCORDIA",
      parent: "SINALOA",
    },
    {
      value: "COSALÁ",
      name: "COSALÁ",
      parent: "SINALOA",
    },
    {
      value: "CULIACÁN",
      name: "CULIACÁN",
      parent: "SINALOA",
    },
    {
      value: "CHOIX",
      name: "CHOIX",
      parent: "SINALOA",
    },
    {
      value: "ELOTA",
      name: "ELOTA",
      parent: "SINALOA",
    },
    {
      value: "ESCUINAPA",
      name: "ESCUINAPA",
      parent: "SINALOA",
    },
    {
      value: "EL FUERTE",
      name: "EL FUERTE",
      parent: "SINALOA",
    },
    {
      value: "GUASAVE",
      name: "GUASAVE",
      parent: "SINALOA",
    },
    {
      value: "MAZATLÁN",
      name: "MAZATLÁN",
      parent: "SINALOA",
    },
    {
      value: "MOCORITO",
      name: "MOCORITO",
      parent: "SINALOA",
    },
    {
      value: "ROSARIO",
      name: "ROSARIO",
      parent: "SINALOA",
    },
    {
      value: "SALVADOR ALVARADO",
      name: "SALVADOR ALVARADO",
      parent: "SINALOA",
    },
    {
      value: "SAN IGNACIO",
      name: "SAN IGNACIO",
      parent: "SINALOA",
    },
    {
      value: "SINALOA",
      name: "SINALOA",
      parent: "SINALOA",
    },
    {
      value: "NAVOLATO",
      name: "NAVOLATO",
      parent: "SINALOA",
    },
    {
      value: "ACONCHI",
      name: "ACONCHI",
      parent: "SONORA",
    },
    {
      value: "AGUA PRIETA",
      name: "AGUA PRIETA",
      parent: "SONORA",
    },
    {
      value: "ALAMOS",
      name: "ALAMOS",
      parent: "SONORA",
    },
    {
      value: "ALTAR",
      name: "ALTAR",
      parent: "SONORA",
    },
    {
      value: "ARIVECHI",
      name: "ARIVECHI",
      parent: "SONORA",
    },
    {
      value: "ARIZPE",
      name: "ARIZPE",
      parent: "SONORA",
    },
    {
      value: "ATIL",
      name: "ATIL",
      parent: "SONORA",
    },
    {
      value: "BACADÉHUACHI",
      name: "BACADÉHUACHI",
      parent: "SONORA",
    },
    {
      value: "BACANORA",
      name: "BACANORA",
      parent: "SONORA",
    },
    {
      value: "BACERAC",
      name: "BACERAC",
      parent: "SONORA",
    },
    {
      value: "BACOACHI",
      name: "BACOACHI",
      parent: "SONORA",
    },
    {
      value: "BÁCUM",
      name: "BÁCUM",
      parent: "SONORA",
    },
    {
      value: "BANÁMICHI",
      name: "BANÁMICHI",
      parent: "SONORA",
    },
    {
      value: "BAVIÁCORA",
      name: "BAVIÁCORA",
      parent: "SONORA",
    },
    {
      value: "BAVISPE",
      name: "BAVISPE",
      parent: "SONORA",
    },
    {
      value: "BENJAMÍN HILL",
      name: "BENJAMÍN HILL",
      parent: "SONORA",
    },
    {
      value: "CABORCA",
      name: "CABORCA",
      parent: "SONORA",
    },
    {
      value: "CAJEME",
      name: "CAJEME",
      parent: "SONORA",
    },
    {
      value: "CANANEA",
      name: "CANANEA",
      parent: "SONORA",
    },
    {
      value: "CARBÓ",
      name: "CARBÓ",
      parent: "SONORA",
    },
    {
      value: "LA COLORADA",
      name: "LA COLORADA",
      parent: "SONORA",
    },
    {
      value: "CUCURPE",
      name: "CUCURPE",
      parent: "SONORA",
    },
    {
      value: "CUMPAS",
      name: "CUMPAS",
      parent: "SONORA",
    },
    {
      value: "DIVISADEROS",
      name: "DIVISADEROS",
      parent: "SONORA",
    },
    {
      value: "EMPALME",
      name: "EMPALME",
      parent: "SONORA",
    },
    {
      value: "ETCHOJOA",
      name: "ETCHOJOA",
      parent: "SONORA",
    },
    {
      value: "FRONTERAS",
      name: "FRONTERAS",
      parent: "SONORA",
    },
    {
      value: "GRANADOS",
      name: "GRANADOS",
      parent: "SONORA",
    },
    {
      value: "GUAYMAS",
      name: "GUAYMAS",
      parent: "SONORA",
    },
    {
      value: "HERMOSILLO",
      name: "HERMOSILLO",
      parent: "SONORA",
    },
    {
      value: "HUACHINERA",
      name: "HUACHINERA",
      parent: "SONORA",
    },
    {
      value: "HUÁSABAS",
      name: "HUÁSABAS",
      parent: "SONORA",
    },
    {
      value: "HUATABAMPO",
      name: "HUATABAMPO",
      parent: "SONORA",
    },
    {
      value: "HUÉPAC",
      name: "HUÉPAC",
      parent: "SONORA",
    },
    {
      value: "IMURIS",
      name: "IMURIS",
      parent: "SONORA",
    },
    {
      value: "MAGDALENA",
      name: "MAGDALENA",
      parent: "SONORA",
    },
    {
      value: "MAZATÁN",
      name: "MAZATÁN",
      parent: "SONORA",
    },
    {
      value: "MOCTEZUMA",
      name: "MOCTEZUMA",
      parent: "SONORA",
    },
    {
      value: "NACO",
      name: "NACO",
      parent: "SONORA",
    },
    {
      value: "NÁCORI CHICO",
      name: "NÁCORI CHICO",
      parent: "SONORA",
    },
    {
      value: "NACOZARI DE GARCÍA",
      name: "NACOZARI DE GARCÍA",
      parent: "SONORA",
    },
    {
      value: "NAVOJOA",
      name: "NAVOJOA",
      parent: "SONORA",
    },
    {
      value: "NOGALES",
      name: "NOGALES",
      parent: "SONORA",
    },
    {
      value: "ONAVAS",
      name: "ONAVAS",
      parent: "SONORA",
    },
    {
      value: "OPODEPE",
      name: "OPODEPE",
      parent: "SONORA",
    },
    {
      value: "OQUITOA",
      name: "OQUITOA",
      parent: "SONORA",
    },
    {
      value: "PITIQUITO",
      name: "PITIQUITO",
      parent: "SONORA",
    },
    {
      value: "PUERTO PEÑASCO",
      name: "PUERTO PEÑASCO",
      parent: "SONORA",
    },
    {
      value: "QUIRIEGO",
      name: "QUIRIEGO",
      parent: "SONORA",
    },
    {
      value: "RAYÓN",
      name: "RAYÓN",
      parent: "SONORA",
    },
    {
      value: "ROSARIO",
      name: "ROSARIO",
      parent: "SONORA",
    },
    {
      value: "SAHUARIPA",
      name: "SAHUARIPA",
      parent: "SONORA",
    },
    {
      value: "SAN FELIPE DE JESÚS",
      name: "SAN FELIPE DE JESÚS",
      parent: "SONORA",
    },
    {
      value: "SAN JAVIER",
      name: "SAN JAVIER",
      parent: "SONORA",
    },
    {
      value: "SAN LUIS RÍO COLORADO",
      name: "SAN LUIS RÍO COLORADO",
      parent: "SONORA",
    },
    {
      value: "SAN MIGUEL DE HORCASITAS",
      name: "SAN MIGUEL DE HORCASITAS",
      parent: "SONORA",
    },
    {
      value: "SAN PEDRO DE LA CUEVA",
      name: "SAN PEDRO DE LA CUEVA",
      parent: "SONORA",
    },
    {
      value: "SANTA ANA",
      name: "SANTA ANA",
      parent: "SONORA",
    },
    {
      value: "SANTA CRUZ",
      name: "SANTA CRUZ",
      parent: "SONORA",
    },
    {
      value: "SÁRIC",
      name: "SÁRIC",
      parent: "SONORA",
    },
    {
      value: "SOYOPA",
      name: "SOYOPA",
      parent: "SONORA",
    },
    {
      value: "SUAQUI GRANDE",
      name: "SUAQUI GRANDE",
      parent: "SONORA",
    },
    {
      value: "TEPACHE",
      name: "TEPACHE",
      parent: "SONORA",
    },
    {
      value: "TRINCHERAS",
      name: "TRINCHERAS",
      parent: "SONORA",
    },
    {
      value: "TUBUTAMA",
      name: "TUBUTAMA",
      parent: "SONORA",
    },
    {
      value: "URES",
      name: "URES",
      parent: "SONORA",
    },
    {
      value: "VILLA HIDALGO",
      name: "VILLA HIDALGO",
      parent: "SONORA",
    },
    {
      value: "VILLA PESQUEIRA",
      name: "VILLA PESQUEIRA",
      parent: "SONORA",
    },
    {
      value: "YÉCORA",
      name: "YÉCORA",
      parent: "SONORA",
    },
    {
      value: "GENERAL PLUTARCO ELÍAS CALLES",
      name: "GENERAL PLUTARCO ELÍAS CALLES",
      parent: "SONORA",
    },
    {
      value: "BENITO JUÁREZ",
      name: "BENITO JUÁREZ",
      parent: "SONORA",
    },
    {
      value: "SAN IGNACIO RÍO MUERTO",
      name: "SAN IGNACIO RÍO MUERTO",
      parent: "SONORA",
    },
    {
      value: "BALANCÁN",
      name: "BALANCÁN",
      parent: "TABASCO",
    },
    {
      value: "CÁRDENAS",
      name: "CÁRDENAS",
      parent: "TABASCO",
    },
    {
      value: "CENTLA",
      name: "CENTLA",
      parent: "TABASCO",
    },
    {
      value: "CENTRO",
      name: "CENTRO",
      parent: "TABASCO",
    },
    {
      value: "COMALCALCO",
      name: "COMALCALCO",
      parent: "TABASCO",
    },
    {
      value: "CUNDUACÁN",
      name: "CUNDUACÁN",
      parent: "TABASCO",
    },
    {
      value: "EMILIANO ZAPATA",
      name: "EMILIANO ZAPATA",
      parent: "TABASCO",
    },
    {
      value: "HUIMANGUILLO",
      name: "HUIMANGUILLO",
      parent: "TABASCO",
    },
    {
      value: "JALAPA",
      name: "JALAPA",
      parent: "TABASCO",
    },
    {
      value: "JALPA DE MÉNDEZ",
      name: "JALPA DE MÉNDEZ",
      parent: "TABASCO",
    },
    {
      value: "JONUTA",
      name: "JONUTA",
      parent: "TABASCO",
    },
    {
      value: "MACUSPANA",
      name: "MACUSPANA",
      parent: "TABASCO",
    },
    {
      value: "NACAJUCA",
      name: "NACAJUCA",
      parent: "TABASCO",
    },
    {
      value: "PARAÍSO",
      name: "PARAÍSO",
      parent: "TABASCO",
    },
    {
      value: "TACOTALPA",
      name: "TACOTALPA",
      parent: "TABASCO",
    },
    {
      value: "TEAPA",
      name: "TEAPA",
      parent: "TABASCO",
    },
    {
      value: "TENOSIQUE",
      name: "TENOSIQUE",
      parent: "TABASCO",
    },
    {
      value: "ABASOLO",
      name: "ABASOLO",
      parent: "TAMAULIPAS",
    },
    {
      value: "ALDAMA",
      name: "ALDAMA",
      parent: "TAMAULIPAS",
    },
    {
      value: "ALTAMIRA",
      name: "ALTAMIRA",
      parent: "TAMAULIPAS",
    },
    {
      value: "ANTIGUO MORELOS",
      name: "ANTIGUO MORELOS",
      parent: "TAMAULIPAS",
    },
    {
      value: "BURGOS",
      name: "BURGOS",
      parent: "TAMAULIPAS",
    },
    {
      value: "BUSTAMANTE",
      name: "BUSTAMANTE",
      parent: "TAMAULIPAS",
    },
    {
      value: "CAMARGO",
      name: "CAMARGO",
      parent: "TAMAULIPAS",
    },
    {
      value: "CASAS",
      name: "CASAS",
      parent: "TAMAULIPAS",
    },
    {
      value: "CIUDAD MADERO",
      name: "CIUDAD MADERO",
      parent: "TAMAULIPAS",
    },
    {
      value: "CRUILLAS",
      name: "CRUILLAS",
      parent: "TAMAULIPAS",
    },
    {
      value: "GÓMEZ FARÍAS",
      name: "GÓMEZ FARÍAS",
      parent: "TAMAULIPAS",
    },
    {
      value: "GONZÁLEZ",
      name: "GONZÁLEZ",
      parent: "TAMAULIPAS",
    },
    {
      value: "GÜÉMEZ",
      name: "GÜÉMEZ",
      parent: "TAMAULIPAS",
    },
    {
      value: "GUERRERO",
      name: "GUERRERO",
      parent: "TAMAULIPAS",
    },
    {
      value: "GUSTAVO DÍAZ ORDAZ",
      name: "GUSTAVO DÍAZ ORDAZ",
      parent: "TAMAULIPAS",
    },
    {
      value: "HIDALGO",
      name: "HIDALGO",
      parent: "TAMAULIPAS",
    },
    {
      value: "JAUMAVE",
      name: "JAUMAVE",
      parent: "TAMAULIPAS",
    },
    {
      value: "JIMÉNEZ",
      name: "JIMÉNEZ",
      parent: "TAMAULIPAS",
    },
    {
      value: "LLERA",
      name: "LLERA",
      parent: "TAMAULIPAS",
    },
    {
      value: "MAINERO",
      name: "MAINERO",
      parent: "TAMAULIPAS",
    },
    {
      value: "EL MANTE",
      name: "EL MANTE",
      parent: "TAMAULIPAS",
    },
    {
      value: "MATAMOROS",
      name: "MATAMOROS",
      parent: "TAMAULIPAS",
    },
    {
      value: "MÉNDEZ",
      name: "MÉNDEZ",
      parent: "TAMAULIPAS",
    },
    {
      value: "MIER",
      name: "MIER",
      parent: "TAMAULIPAS",
    },
    {
      value: "MIGUEL ALEMÁN",
      name: "MIGUEL ALEMÁN",
      parent: "TAMAULIPAS",
    },
    {
      value: "MIQUIHUANA",
      name: "MIQUIHUANA",
      parent: "TAMAULIPAS",
    },
    {
      value: "NUEVO LAREDO",
      name: "NUEVO LAREDO",
      parent: "TAMAULIPAS",
    },
    {
      value: "NUEVO MORELOS",
      name: "NUEVO MORELOS",
      parent: "TAMAULIPAS",
    },
    {
      value: "OCAMPO",
      name: "OCAMPO",
      parent: "TAMAULIPAS",
    },
    {
      value: "PADILLA",
      name: "PADILLA",
      parent: "TAMAULIPAS",
    },
    {
      value: "PALMILLAS",
      name: "PALMILLAS",
      parent: "TAMAULIPAS",
    },
    {
      value: "REYNOSA",
      name: "REYNOSA",
      parent: "TAMAULIPAS",
    },
    {
      value: "RÍO BRAVO",
      name: "RÍO BRAVO",
      parent: "TAMAULIPAS",
    },
    {
      value: "SAN CARLOS",
      name: "SAN CARLOS",
      parent: "TAMAULIPAS",
    },
    {
      value: "SAN FERNANDO",
      name: "SAN FERNANDO",
      parent: "TAMAULIPAS",
    },
    {
      value: "SAN NICOLÁS",
      name: "SAN NICOLÁS",
      parent: "TAMAULIPAS",
    },
    {
      value: "SOTO LA MARINA",
      name: "SOTO LA MARINA",
      parent: "TAMAULIPAS",
    },
    {
      value: "TAMPICO",
      name: "TAMPICO",
      parent: "TAMAULIPAS",
    },
    {
      value: "TULA",
      name: "TULA",
      parent: "TAMAULIPAS",
    },
    {
      value: "VALLE HERMOSO",
      name: "VALLE HERMOSO",
      parent: "TAMAULIPAS",
    },
    {
      value: "VICTORIA",
      name: "VICTORIA",
      parent: "TAMAULIPAS",
    },
    {
      value: "VILLAGRÁN",
      name: "VILLAGRÁN",
      parent: "TAMAULIPAS",
    },
    {
      value: "XICOTÉNCATL",
      name: "XICOTÉNCATL",
      parent: "TAMAULIPAS",
    },
    {
      value: "AMAXAC DE GUERRERO",
      name: "AMAXAC DE GUERRERO",
      parent: "TLAXCALA",
    },
    {
      value: "APETATITLÁN DE ANTONIO CARVAJAL",
      name: "APETATITLÁN DE ANTONIO CARVAJAL",
      parent: "TLAXCALA",
    },
    {
      value: "ATLANGATEPEC",
      name: "ATLANGATEPEC",
      parent: "TLAXCALA",
    },
    {
      value: "ATLTZAYANCA",
      name: "ATLTZAYANCA",
      parent: "TLAXCALA",
    },
    {
      value: "APIZACO",
      name: "APIZACO",
      parent: "TLAXCALA",
    },
    {
      value: "CALPULALPAN",
      name: "CALPULALPAN",
      parent: "TLAXCALA",
    },
    {
      value: "EL CARMEN TEQUEXQUITLA",
      name: "EL CARMEN TEQUEXQUITLA",
      parent: "TLAXCALA",
    },
    {
      value: "CUAPIAXTLA",
      name: "CUAPIAXTLA",
      parent: "TLAXCALA",
    },
    {
      value: "CUAXOMULCO",
      name: "CUAXOMULCO",
      parent: "TLAXCALA",
    },
    {
      value: "CHIAUTEMPAN",
      name: "CHIAUTEMPAN",
      parent: "TLAXCALA",
    },
    {
      value: "MUÑOZ DE DOMINGO ARENAS",
      name: "MUÑOZ DE DOMINGO ARENAS",
      parent: "TLAXCALA",
    },
    {
      value: "ESPAÑITA",
      name: "ESPAÑITA",
      parent: "TLAXCALA",
    },
    {
      value: "HUAMANTLA",
      name: "HUAMANTLA",
      parent: "TLAXCALA",
    },
    {
      value: "HUEYOTLIPAN",
      name: "HUEYOTLIPAN",
      parent: "TLAXCALA",
    },
    {
      value: "IXTACUIXTLA DE MARIANO MATAMOROS",
      name: "IXTACUIXTLA DE MARIANO MATAMOROS",
      parent: "TLAXCALA",
    },
    {
      value: "IXTENCO",
      name: "IXTENCO",
      parent: "TLAXCALA",
    },
    {
      value: "MAZATECOCHCO DE JOSÉ MARÍA MORELOS",
      name: "MAZATECOCHCO DE JOSÉ MARÍA MORELOS",
      parent: "TLAXCALA",
    },
    {
      value: "CONTLA DE JUAN CUAMATZI",
      name: "CONTLA DE JUAN CUAMATZI",
      parent: "TLAXCALA",
    },
    {
      value: "TEPETITLA DE LARDIZÁBAL",
      name: "TEPETITLA DE LARDIZÁBAL",
      parent: "TLAXCALA",
    },
    {
      value: "SANCTÓRUM DE LÁZARO CÁRDENAS",
      name: "SANCTÓRUM DE LÁZARO CÁRDENAS",
      parent: "TLAXCALA",
    },
    {
      value: "NANACAMILPA DE MARIANO ARISTA",
      name: "NANACAMILPA DE MARIANO ARISTA",
      parent: "TLAXCALA",
    },
    {
      value: "ACUAMANALA DE MIGUEL HIDALGO",
      name: "ACUAMANALA DE MIGUEL HIDALGO",
      parent: "TLAXCALA",
    },
    {
      value: "NATÍVITAS",
      name: "NATÍVITAS",
      parent: "TLAXCALA",
    },
    {
      value: "PANOTLA",
      name: "PANOTLA",
      parent: "TLAXCALA",
    },
    {
      value: "SAN PABLO DEL MONTE",
      name: "SAN PABLO DEL MONTE",
      parent: "TLAXCALA",
    },
    {
      value: "SANTA CRUZ TLAXCALA",
      name: "SANTA CRUZ TLAXCALA",
      parent: "TLAXCALA",
    },
    {
      value: "TENANCINGO",
      name: "TENANCINGO",
      parent: "TLAXCALA",
    },
    {
      value: "TEOLOCHOLCO",
      name: "TEOLOCHOLCO",
      parent: "TLAXCALA",
    },
    {
      value: "TEPEYANCO",
      name: "TEPEYANCO",
      parent: "TLAXCALA",
    },
    {
      value: "TERRENATE",
      name: "TERRENATE",
      parent: "TLAXCALA",
    },
    {
      value: "TETLA DE LA SOLIDARIDAD",
      name: "TETLA DE LA SOLIDARIDAD",
      parent: "TLAXCALA",
    },
    {
      value: "TETLATLAHUCA",
      name: "TETLATLAHUCA",
      parent: "TLAXCALA",
    },
    {
      value: "TLAXCALA",
      name: "TLAXCALA",
      parent: "TLAXCALA",
    },
    {
      value: "TLAXCO",
      name: "TLAXCO",
      parent: "TLAXCALA",
    },
    {
      value: "TOCATLÁN",
      name: "TOCATLÁN",
      parent: "TLAXCALA",
    },
    {
      value: "TOTOLAC",
      name: "TOTOLAC",
      parent: "TLAXCALA",
    },
    {
      value: "ZILTLALTÉPEC DE TRINIDAD SÁNCHEZ SANTOS",
      name: "ZILTLALTÉPEC DE TRINIDAD SÁNCHEZ SANTOS",
      parent: "TLAXCALA",
    },
    {
      value: "TZOMPANTEPEC",
      name: "TZOMPANTEPEC",
      parent: "TLAXCALA",
    },
    {
      value: "XALOZTOC",
      name: "XALOZTOC",
      parent: "TLAXCALA",
    },
    {
      value: "XALTOCAN",
      name: "XALTOCAN",
      parent: "TLAXCALA",
    },
    {
      value: "PAPALOTLA DE XICOHTÉNCATL",
      name: "PAPALOTLA DE XICOHTÉNCATL",
      parent: "TLAXCALA",
    },
    {
      value: "XICOHTZINCO",
      name: "XICOHTZINCO",
      parent: "TLAXCALA",
    },
    {
      value: "YAUHQUEMEHCAN",
      name: "YAUHQUEMEHCAN",
      parent: "TLAXCALA",
    },
    {
      value: "ZACATELCO",
      name: "ZACATELCO",
      parent: "TLAXCALA",
    },
    {
      value: "BENITO JUÁREZ",
      name: "BENITO JUÁREZ",
      parent: "TLAXCALA",
    },
    {
      value: "EMILIANO ZAPATA",
      name: "EMILIANO ZAPATA",
      parent: "TLAXCALA",
    },
    {
      value: "LÁZARO CÁRDENAS",
      name: "LÁZARO CÁRDENAS",
      parent: "TLAXCALA",
    },
    {
      value: "LA MAGDALENA TLALTELULCO",
      name: "LA MAGDALENA TLALTELULCO",
      parent: "TLAXCALA",
    },
    {
      value: "SAN DAMIÁN TEXÓLOC",
      name: "SAN DAMIÁN TEXÓLOC",
      parent: "TLAXCALA",
    },
    {
      value: "SAN FRANCISCO TETLANOHCAN",
      name: "SAN FRANCISCO TETLANOHCAN",
      parent: "TLAXCALA",
    },
    {
      value: "SAN JERÓNIMO ZACUALPAN",
      name: "SAN JERÓNIMO ZACUALPAN",
      parent: "TLAXCALA",
    },
    {
      value: "SAN JOSÉ TEACALCO",
      name: "SAN JOSÉ TEACALCO",
      parent: "TLAXCALA",
    },
    {
      value: "SAN JUAN HUACTZINCO",
      name: "SAN JUAN HUACTZINCO",
      parent: "TLAXCALA",
    },
    {
      value: "SAN LORENZO AXOCOMANITLA",
      name: "SAN LORENZO AXOCOMANITLA",
      parent: "TLAXCALA",
    },
    {
      value: "SAN LUCAS TECOPILCO",
      name: "SAN LUCAS TECOPILCO",
      parent: "TLAXCALA",
    },
    {
      value: "SANTA ANA NOPALUCAN",
      name: "SANTA ANA NOPALUCAN",
      parent: "TLAXCALA",
    },
    {
      value: "SANTA APOLONIA TEACALCO",
      name: "SANTA APOLONIA TEACALCO",
      parent: "TLAXCALA",
    },
    {
      value: "SANTA CATARINA AYOMETLA",
      name: "SANTA CATARINA AYOMETLA",
      parent: "TLAXCALA",
    },
    {
      value: "SANTA CRUZ QUILEHTLA",
      name: "SANTA CRUZ QUILEHTLA",
      parent: "TLAXCALA",
    },
    {
      value: "SANTA ISABEL XILOXOXTLA",
      name: "SANTA ISABEL XILOXOXTLA",
      parent: "TLAXCALA",
    },
    {
      value: "ACAJETE",
      name: "ACAJETE",
      parent: "VERACRUZ",
    },
    {
      value: "ACATLÁN",
      name: "ACATLÁN",
      parent: "VERACRUZ",
    },
    {
      value: "ACAYUCAN",
      name: "ACAYUCAN",
      parent: "VERACRUZ",
    },
    {
      value: "ACTOPAN",
      name: "ACTOPAN",
      parent: "VERACRUZ",
    },
    {
      value: "ACULA",
      name: "ACULA",
      parent: "VERACRUZ",
    },
    {
      value: "ACULTZINGO",
      name: "ACULTZINGO",
      parent: "VERACRUZ",
    },
    {
      value: "CAMARÓN DE TEJEDA",
      name: "CAMARÓN DE TEJEDA",
      parent: "VERACRUZ",
    },
    {
      value: "ALPATLÁHUAC",
      name: "ALPATLÁHUAC",
      parent: "VERACRUZ",
    },
    {
      value: "ALTO LUCERO DE GUTIÉRREZ BARRIOS",
      name: "ALTO LUCERO DE GUTIÉRREZ BARRIOS",
      parent: "VERACRUZ",
    },
    {
      value: "ALTOTONGA",
      name: "ALTOTONGA",
      parent: "VERACRUZ",
    },
    {
      value: "ALVARADO",
      name: "ALVARADO",
      parent: "VERACRUZ",
    },
    {
      value: "AMATITLÁN",
      name: "AMATITLÁN",
      parent: "VERACRUZ",
    },
    {
      value: "NARANJOS AMATLÁN",
      name: "NARANJOS AMATLÁN",
      parent: "VERACRUZ",
    },
    {
      value: "AMATLÁN DE LOS REYES",
      name: "AMATLÁN DE LOS REYES",
      parent: "VERACRUZ",
    },
    {
      value: "ANGEL R. CABADA",
      name: "ANGEL R. CABADA",
      parent: "VERACRUZ",
    },
    {
      value: "LA ANTIGUA",
      name: "LA ANTIGUA",
      parent: "VERACRUZ",
    },
    {
      value: "APAZAPAN",
      name: "APAZAPAN",
      parent: "VERACRUZ",
    },
    {
      value: "AQUILA",
      name: "AQUILA",
      parent: "VERACRUZ",
    },
    {
      value: "ASTACINGA",
      name: "ASTACINGA",
      parent: "VERACRUZ",
    },
    {
      value: "ATLAHUILCO",
      name: "ATLAHUILCO",
      parent: "VERACRUZ",
    },
    {
      value: "ATOYAC",
      name: "ATOYAC",
      parent: "VERACRUZ",
    },
    {
      value: "ATZACAN",
      name: "ATZACAN",
      parent: "VERACRUZ",
    },
    {
      value: "ATZALAN",
      name: "ATZALAN",
      parent: "VERACRUZ",
    },
    {
      value: "TLALTETELA",
      name: "TLALTETELA",
      parent: "VERACRUZ",
    },
    {
      value: "AYAHUALULCO",
      name: "AYAHUALULCO",
      parent: "VERACRUZ",
    },
    {
      value: "BANDERILLA",
      name: "BANDERILLA",
      parent: "VERACRUZ",
    },
    {
      value: "BENITO JUÁREZ",
      name: "BENITO JUÁREZ",
      parent: "VERACRUZ",
    },
    {
      value: "BOCA DEL RÍO",
      name: "BOCA DEL RÍO",
      parent: "VERACRUZ",
    },
    {
      value: "CALCAHUALCO",
      name: "CALCAHUALCO",
      parent: "VERACRUZ",
    },
    {
      value: "CAMERINO Z. MENDOZA",
      name: "CAMERINO Z. MENDOZA",
      parent: "VERACRUZ",
    },
    {
      value: "CARRILLO PUERTO",
      name: "CARRILLO PUERTO",
      parent: "VERACRUZ",
    },
    {
      value: "CATEMACO",
      name: "CATEMACO",
      parent: "VERACRUZ",
    },
    {
      value: "CAZONES DE HERRERA",
      name: "CAZONES DE HERRERA",
      parent: "VERACRUZ",
    },
    {
      value: "CERRO AZUL",
      name: "CERRO AZUL",
      parent: "VERACRUZ",
    },
    {
      value: "CITLALTÉPETL",
      name: "CITLALTÉPETL",
      parent: "VERACRUZ",
    },
    {
      value: "COACOATZINTLA",
      name: "COACOATZINTLA",
      parent: "VERACRUZ",
    },
    {
      value: "COAHUITLÁN",
      name: "COAHUITLÁN",
      parent: "VERACRUZ",
    },
    {
      value: "COATEPEC",
      name: "COATEPEC",
      parent: "VERACRUZ",
    },
    {
      value: "COATZACOALCOS",
      name: "COATZACOALCOS",
      parent: "VERACRUZ",
    },
    {
      value: "COATZINTLA",
      name: "COATZINTLA",
      parent: "VERACRUZ",
    },
    {
      value: "COETZALA",
      name: "COETZALA",
      parent: "VERACRUZ",
    },
    {
      value: "COLIPA",
      name: "COLIPA",
      parent: "VERACRUZ",
    },
    {
      value: "COMAPA",
      name: "COMAPA",
      parent: "VERACRUZ",
    },
    {
      value: "CÓRDOBA",
      name: "CÓRDOBA",
      parent: "VERACRUZ",
    },
    {
      value: "COSAMALOAPAN DE CARPIO",
      name: "COSAMALOAPAN DE CARPIO",
      parent: "VERACRUZ",
    },
    {
      value: "COSAUTLÁN DE CARVAJAL",
      name: "COSAUTLÁN DE CARVAJAL",
      parent: "VERACRUZ",
    },
    {
      value: "COSCOMATEPEC",
      name: "COSCOMATEPEC",
      parent: "VERACRUZ",
    },
    {
      value: "COSOLEACAQUE",
      name: "COSOLEACAQUE",
      parent: "VERACRUZ",
    },
    {
      value: "COTAXTLA",
      name: "COTAXTLA",
      parent: "VERACRUZ",
    },
    {
      value: "COXQUIHUI",
      name: "COXQUIHUI",
      parent: "VERACRUZ",
    },
    {
      value: "COYUTLA",
      name: "COYUTLA",
      parent: "VERACRUZ",
    },
    {
      value: "CUICHAPA",
      name: "CUICHAPA",
      parent: "VERACRUZ",
    },
    {
      value: "CUITLÁHUAC",
      name: "CUITLÁHUAC",
      parent: "VERACRUZ",
    },
    {
      value: "CHACALTIANGUIS",
      name: "CHACALTIANGUIS",
      parent: "VERACRUZ",
    },
    {
      value: "CHALMA",
      name: "CHALMA",
      parent: "VERACRUZ",
    },
    {
      value: "CHICONAMEL",
      name: "CHICONAMEL",
      parent: "VERACRUZ",
    },
    {
      value: "CHICONQUIACO",
      name: "CHICONQUIACO",
      parent: "VERACRUZ",
    },
    {
      value: "CHICONTEPEC",
      name: "CHICONTEPEC",
      parent: "VERACRUZ",
    },
    {
      value: "CHINAMECA",
      name: "CHINAMECA",
      parent: "VERACRUZ",
    },
    {
      value: "CHINAMPA DE GOROSTIZA",
      name: "CHINAMPA DE GOROSTIZA",
      parent: "VERACRUZ",
    },
    {
      value: "LAS CHOAPAS",
      name: "LAS CHOAPAS",
      parent: "VERACRUZ",
    },
    {
      value: "CHOCAMÁN",
      name: "CHOCAMÁN",
      parent: "VERACRUZ",
    },
    {
      value: "CHONTLA",
      name: "CHONTLA",
      parent: "VERACRUZ",
    },
    {
      value: "CHUMATLÁN",
      name: "CHUMATLÁN",
      parent: "VERACRUZ",
    },
    {
      value: "EMILIANO ZAPATA",
      name: "EMILIANO ZAPATA",
      parent: "VERACRUZ",
    },
    {
      value: "ESPINAL",
      name: "ESPINAL",
      parent: "VERACRUZ",
    },
    {
      value: "FILOMENO MATA",
      name: "FILOMENO MATA",
      parent: "VERACRUZ",
    },
    {
      value: "FORTÍN",
      name: "FORTÍN",
      parent: "VERACRUZ",
    },
    {
      value: "GUTIÉRREZ ZAMORA",
      name: "GUTIÉRREZ ZAMORA",
      parent: "VERACRUZ",
    },
    {
      value: "HIDALGOTITLÁN",
      name: "HIDALGOTITLÁN",
      parent: "VERACRUZ",
    },
    {
      value: "HUATUSCO",
      name: "HUATUSCO",
      parent: "VERACRUZ",
    },
    {
      value: "HUAYACOCOTLA",
      name: "HUAYACOCOTLA",
      parent: "VERACRUZ",
    },
    {
      value: "HUEYAPAN DE OCAMPO",
      name: "HUEYAPAN DE OCAMPO",
      parent: "VERACRUZ",
    },
    {
      value: "HUILOAPAN DE CUAUHTÉMOC",
      name: "HUILOAPAN DE CUAUHTÉMOC",
      parent: "VERACRUZ",
    },
    {
      value: "IGNACIO DE LA LLAVE",
      name: "IGNACIO DE LA LLAVE",
      parent: "VERACRUZ",
    },
    {
      value: "ILAMATLÁN",
      name: "ILAMATLÁN",
      parent: "VERACRUZ",
    },
    {
      value: "ISLA",
      name: "ISLA",
      parent: "VERACRUZ",
    },
    {
      value: "IXCATEPEC",
      name: "IXCATEPEC",
      parent: "VERACRUZ",
    },
    {
      value: "IXHUACÁN DE LOS REYES",
      name: "IXHUACÁN DE LOS REYES",
      parent: "VERACRUZ",
    },
    {
      value: "IXHUATLÁN DEL CAFÉ",
      name: "IXHUATLÁN DEL CAFÉ",
      parent: "VERACRUZ",
    },
    {
      value: "IXHUATLANCILLO",
      name: "IXHUATLANCILLO",
      parent: "VERACRUZ",
    },
    {
      value: "IXHUATLÁN DEL SURESTE",
      name: "IXHUATLÁN DEL SURESTE",
      parent: "VERACRUZ",
    },
    {
      value: "IXHUATLÁN DE MADERO",
      name: "IXHUATLÁN DE MADERO",
      parent: "VERACRUZ",
    },
    {
      value: "IXMATLAHUACAN",
      name: "IXMATLAHUACAN",
      parent: "VERACRUZ",
    },
    {
      value: "IXTACZOQUITLÁN",
      name: "IXTACZOQUITLÁN",
      parent: "VERACRUZ",
    },
    {
      value: "JALACINGO",
      name: "JALACINGO",
      parent: "VERACRUZ",
    },
    {
      value: "XALAPA",
      name: "XALAPA",
      parent: "VERACRUZ",
    },
    {
      value: "JALCOMULCO",
      name: "JALCOMULCO",
      parent: "VERACRUZ",
    },
    {
      value: "JÁLTIPAN",
      name: "JÁLTIPAN",
      parent: "VERACRUZ",
    },
    {
      value: "JAMAPA",
      name: "JAMAPA",
      parent: "VERACRUZ",
    },
    {
      value: "JESÚS CARRANZA",
      name: "JESÚS CARRANZA",
      parent: "VERACRUZ",
    },
    {
      value: "XICO",
      name: "XICO",
      parent: "VERACRUZ",
    },
    {
      value: "JILOTEPEC",
      name: "JILOTEPEC",
      parent: "VERACRUZ",
    },
    {
      value: "JUAN RODRÍGUEZ CLARA",
      name: "JUAN RODRÍGUEZ CLARA",
      parent: "VERACRUZ",
    },
    {
      value: "JUCHIQUE DE FERRER",
      name: "JUCHIQUE DE FERRER",
      parent: "VERACRUZ",
    },
    {
      value: "LANDERO Y COSS",
      name: "LANDERO Y COSS",
      parent: "VERACRUZ",
    },
    {
      value: "LERDO DE TEJADA",
      name: "LERDO DE TEJADA",
      parent: "VERACRUZ",
    },
    {
      value: "MAGDALENA",
      name: "MAGDALENA",
      parent: "VERACRUZ",
    },
    {
      value: "MALTRATA",
      name: "MALTRATA",
      parent: "VERACRUZ",
    },
    {
      value: "MANLIO FABIO ALTAMIRANO",
      name: "MANLIO FABIO ALTAMIRANO",
      parent: "VERACRUZ",
    },
    {
      value: "MARIANO ESCOBEDO",
      name: "MARIANO ESCOBEDO",
      parent: "VERACRUZ",
    },
    {
      value: "MARTÍNEZ DE LA TORRE",
      name: "MARTÍNEZ DE LA TORRE",
      parent: "VERACRUZ",
    },
    {
      value: "MECATLÁN",
      name: "MECATLÁN",
      parent: "VERACRUZ",
    },
    {
      value: "MECAYAPAN",
      name: "MECAYAPAN",
      parent: "VERACRUZ",
    },
    {
      value: "MEDELLÍN DE BRAVO",
      name: "MEDELLÍN DE BRAVO",
      parent: "VERACRUZ",
    },
    {
      value: "MIAHUATLÁN",
      name: "MIAHUATLÁN",
      parent: "VERACRUZ",
    },
    {
      value: "LAS MINAS",
      name: "LAS MINAS",
      parent: "VERACRUZ",
    },
    {
      value: "MINATITLÁN",
      name: "MINATITLÁN",
      parent: "VERACRUZ",
    },
    {
      value: "MISANTLA",
      name: "MISANTLA",
      parent: "VERACRUZ",
    },
    {
      value: "MIXTLA DE ALTAMIRANO",
      name: "MIXTLA DE ALTAMIRANO",
      parent: "VERACRUZ",
    },
    {
      value: "MOLOACÁN",
      name: "MOLOACÁN",
      parent: "VERACRUZ",
    },
    {
      value: "NAOLINCO",
      name: "NAOLINCO",
      parent: "VERACRUZ",
    },
    {
      value: "NARANJAL",
      name: "NARANJAL",
      parent: "VERACRUZ",
    },
    {
      value: "NAUTLA",
      name: "NAUTLA",
      parent: "VERACRUZ",
    },
    {
      value: "NOGALES",
      name: "NOGALES",
      parent: "VERACRUZ",
    },
    {
      value: "OLUTA",
      name: "OLUTA",
      parent: "VERACRUZ",
    },
    {
      value: "OMEALCA",
      name: "OMEALCA",
      parent: "VERACRUZ",
    },
    {
      value: "ORIZABA",
      name: "ORIZABA",
      parent: "VERACRUZ",
    },
    {
      value: "OTATITLÁN",
      name: "OTATITLÁN",
      parent: "VERACRUZ",
    },
    {
      value: "OTEAPAN",
      name: "OTEAPAN",
      parent: "VERACRUZ",
    },
    {
      value: "OZULUAMA DE MASCAREÑAS",
      name: "OZULUAMA DE MASCAREÑAS",
      parent: "VERACRUZ",
    },
    {
      value: "PAJAPAN",
      name: "PAJAPAN",
      parent: "VERACRUZ",
    },
    {
      value: "PÁNUCO",
      name: "PÁNUCO",
      parent: "VERACRUZ",
    },
    {
      value: "PAPANTLA",
      name: "PAPANTLA",
      parent: "VERACRUZ",
    },
    {
      value: "PASO DEL MACHO",
      name: "PASO DEL MACHO",
      parent: "VERACRUZ",
    },
    {
      value: "PASO DE OVEJAS",
      name: "PASO DE OVEJAS",
      parent: "VERACRUZ",
    },
    {
      value: "LA PERLA",
      name: "LA PERLA",
      parent: "VERACRUZ",
    },
    {
      value: "PEROTE",
      name: "PEROTE",
      parent: "VERACRUZ",
    },
    {
      value: "PLATÓN SÁNCHEZ",
      name: "PLATÓN SÁNCHEZ",
      parent: "VERACRUZ",
    },
    {
      value: "PLAYA VICENTE",
      name: "PLAYA VICENTE",
      parent: "VERACRUZ",
    },
    {
      value: "POZA RICA DE HIDALGO",
      name: "POZA RICA DE HIDALGO",
      parent: "VERACRUZ",
    },
    {
      value: "LAS VIGAS DE RAMÍREZ",
      name: "LAS VIGAS DE RAMÍREZ",
      parent: "VERACRUZ",
    },
    {
      value: "PUEBLO VIEJO",
      name: "PUEBLO VIEJO",
      parent: "VERACRUZ",
    },
    {
      value: "PUENTE NACIONAL",
      name: "PUENTE NACIONAL",
      parent: "VERACRUZ",
    },
    {
      value: "RAFAEL DELGADO",
      name: "RAFAEL DELGADO",
      parent: "VERACRUZ",
    },
    {
      value: "RAFAEL LUCIO",
      name: "RAFAEL LUCIO",
      parent: "VERACRUZ",
    },
    {
      value: "LOS REYES",
      name: "LOS REYES",
      parent: "VERACRUZ",
    },
    {
      value: "RÍO BLANCO",
      name: "RÍO BLANCO",
      parent: "VERACRUZ",
    },
    {
      value: "SALTABARRANCA",
      name: "SALTABARRANCA",
      parent: "VERACRUZ",
    },
    {
      value: "SAN ANDRÉS TENEJAPAN",
      name: "SAN ANDRÉS TENEJAPAN",
      parent: "VERACRUZ",
    },
    {
      value: "SAN ANDRÉS TUXTLA",
      name: "SAN ANDRÉS TUXTLA",
      parent: "VERACRUZ",
    },
    {
      value: "SAN JUAN EVANGELISTA",
      name: "SAN JUAN EVANGELISTA",
      parent: "VERACRUZ",
    },
    {
      value: "SANTIAGO TUXTLA",
      name: "SANTIAGO TUXTLA",
      parent: "VERACRUZ",
    },
    {
      value: "SAYULA DE ALEMÁN",
      name: "SAYULA DE ALEMÁN",
      parent: "VERACRUZ",
    },
    {
      value: "SOCONUSCO",
      name: "SOCONUSCO",
      parent: "VERACRUZ",
    },
    {
      value: "SOCHIAPA",
      name: "SOCHIAPA",
      parent: "VERACRUZ",
    },
    {
      value: "SOLEDAD ATZOMPA",
      name: "SOLEDAD ATZOMPA",
      parent: "VERACRUZ",
    },
    {
      value: "SOLEDAD DE DOBLADO",
      name: "SOLEDAD DE DOBLADO",
      parent: "VERACRUZ",
    },
    {
      value: "SOTEAPAN",
      name: "SOTEAPAN",
      parent: "VERACRUZ",
    },
    {
      value: "TAMALÍN",
      name: "TAMALÍN",
      parent: "VERACRUZ",
    },
    {
      value: "TAMIAHUA",
      name: "TAMIAHUA",
      parent: "VERACRUZ",
    },
    {
      value: "TAMPICO ALTO",
      name: "TAMPICO ALTO",
      parent: "VERACRUZ",
    },
    {
      value: "TANCOCO",
      name: "TANCOCO",
      parent: "VERACRUZ",
    },
    {
      value: "TANTIMA",
      name: "TANTIMA",
      parent: "VERACRUZ",
    },
    {
      value: "TANTOYUCA",
      name: "TANTOYUCA",
      parent: "VERACRUZ",
    },
    {
      value: "TATATILA",
      name: "TATATILA",
      parent: "VERACRUZ",
    },
    {
      value: "CASTILLO DE TEAYO",
      name: "CASTILLO DE TEAYO",
      parent: "VERACRUZ",
    },
    {
      value: "TECOLUTLA",
      name: "TECOLUTLA",
      parent: "VERACRUZ",
    },
    {
      value: "TEHUIPANGO",
      name: "TEHUIPANGO",
      parent: "VERACRUZ",
    },
    {
      value: "ÁLAMO TEMAPACHE",
      name: "ÁLAMO TEMAPACHE",
      parent: "VERACRUZ",
    },
    {
      value: "TEMPOAL",
      name: "TEMPOAL",
      parent: "VERACRUZ",
    },
    {
      value: "TENAMPA",
      name: "TENAMPA",
      parent: "VERACRUZ",
    },
    {
      value: "TENOCHTITLÁN",
      name: "TENOCHTITLÁN",
      parent: "VERACRUZ",
    },
    {
      value: "TEOCELO",
      name: "TEOCELO",
      parent: "VERACRUZ",
    },
    {
      value: "TEPATLAXCO",
      name: "TEPATLAXCO",
      parent: "VERACRUZ",
    },
    {
      value: "TEPETLÁN",
      name: "TEPETLÁN",
      parent: "VERACRUZ",
    },
    {
      value: "TEPETZINTLA",
      name: "TEPETZINTLA",
      parent: "VERACRUZ",
    },
    {
      value: "TEQUILA",
      name: "TEQUILA",
      parent: "VERACRUZ",
    },
    {
      value: "JOSÉ AZUETA",
      name: "JOSÉ AZUETA",
      parent: "VERACRUZ",
    },
    {
      value: "TEXCATEPEC",
      name: "TEXCATEPEC",
      parent: "VERACRUZ",
    },
    {
      value: "TEXHUACÁN",
      name: "TEXHUACÁN",
      parent: "VERACRUZ",
    },
    {
      value: "TEXISTEPEC",
      name: "TEXISTEPEC",
      parent: "VERACRUZ",
    },
    {
      value: "TEZONAPA",
      name: "TEZONAPA",
      parent: "VERACRUZ",
    },
    {
      value: "TIERRA BLANCA",
      name: "TIERRA BLANCA",
      parent: "VERACRUZ",
    },
    {
      value: "TIHUATLÁN",
      name: "TIHUATLÁN",
      parent: "VERACRUZ",
    },
    {
      value: "TLACOJALPAN",
      name: "TLACOJALPAN",
      parent: "VERACRUZ",
    },
    {
      value: "TLACOLULAN",
      name: "TLACOLULAN",
      parent: "VERACRUZ",
    },
    {
      value: "TLACOTALPAN",
      name: "TLACOTALPAN",
      parent: "VERACRUZ",
    },
    {
      value: "TLACOTEPEC DE MEJÍA",
      name: "TLACOTEPEC DE MEJÍA",
      parent: "VERACRUZ",
    },
    {
      value: "TLACHICHILCO",
      name: "TLACHICHILCO",
      parent: "VERACRUZ",
    },
    {
      value: "TLALIXCOYAN",
      name: "TLALIXCOYAN",
      parent: "VERACRUZ",
    },
    {
      value: "TLALNELHUAYOCAN",
      name: "TLALNELHUAYOCAN",
      parent: "VERACRUZ",
    },
    {
      value: "TLAPACOYAN",
      name: "TLAPACOYAN",
      parent: "VERACRUZ",
    },
    {
      value: "TLAQUILPA",
      name: "TLAQUILPA",
      parent: "VERACRUZ",
    },
    {
      value: "TLILAPAN",
      name: "TLILAPAN",
      parent: "VERACRUZ",
    },
    {
      value: "TOMATLÁN",
      name: "TOMATLÁN",
      parent: "VERACRUZ",
    },
    {
      value: "TONAYÁN",
      name: "TONAYÁN",
      parent: "VERACRUZ",
    },
    {
      value: "TOTUTLA",
      name: "TOTUTLA",
      parent: "VERACRUZ",
    },
    {
      value: "TUXPAN",
      name: "TUXPAN",
      parent: "VERACRUZ",
    },
    {
      value: "TUXTILLA",
      name: "TUXTILLA",
      parent: "VERACRUZ",
    },
    {
      value: "URSULO GALVÁN",
      name: "URSULO GALVÁN",
      parent: "VERACRUZ",
    },
    {
      value: "VEGA DE ALATORRE",
      name: "VEGA DE ALATORRE",
      parent: "VERACRUZ",
    },
    {
      value: "VERACRUZ",
      name: "VERACRUZ",
      parent: "VERACRUZ",
    },
    {
      value: "VILLA ALDAMA",
      name: "VILLA ALDAMA",
      parent: "VERACRUZ",
    },
    {
      value: "XOXOCOTLA",
      name: "XOXOCOTLA",
      parent: "VERACRUZ",
    },
    {
      value: "YANGA",
      name: "YANGA",
      parent: "VERACRUZ",
    },
    {
      value: "YECUATLA",
      name: "YECUATLA",
      parent: "VERACRUZ",
    },
    {
      value: "ZACUALPAN",
      name: "ZACUALPAN",
      parent: "VERACRUZ",
    },
    {
      value: "ZARAGOZA",
      name: "ZARAGOZA",
      parent: "VERACRUZ",
    },
    {
      value: "ZENTLA",
      name: "ZENTLA",
      parent: "VERACRUZ",
    },
    {
      value: "ZONGOLICA",
      name: "ZONGOLICA",
      parent: "VERACRUZ",
    },
    {
      value: "ZONTECOMATLÁN DE LÓPEZ Y FUENTES",
      name: "ZONTECOMATLÁN DE LÓPEZ Y FUENTES",
      parent: "VERACRUZ",
    },
    {
      value: "ZOZOCOLCO DE HIDALGO",
      name: "ZOZOCOLCO DE HIDALGO",
      parent: "VERACRUZ",
    },
    {
      value: "AGUA DULCE",
      name: "AGUA DULCE",
      parent: "VERACRUZ",
    },
    {
      value: "EL HIGO",
      name: "EL HIGO",
      parent: "VERACRUZ",
    },
    {
      value: "NANCHITAL DE LÁZARO CÁRDENAS DEL RÍO",
      name: "NANCHITAL DE LÁZARO CÁRDENAS DEL RÍO",
      parent: "VERACRUZ",
    },
    {
      value: "TRES VALLES",
      name: "TRES VALLES",
      parent: "VERACRUZ",
    },
    {
      value: "CARLOS A. CARRILLO",
      name: "CARLOS A. CARRILLO",
      parent: "VERACRUZ",
    },
    {
      value: "TATAHUICAPAN DE JUÁREZ",
      name: "TATAHUICAPAN DE JUÁREZ",
      parent: "VERACRUZ",
    },
    {
      value: "UXPANAPA",
      name: "UXPANAPA",
      parent: "VERACRUZ",
    },
    {
      value: "SAN RAFAEL",
      name: "SAN RAFAEL",
      parent: "VERACRUZ",
    },
    {
      value: "SANTIAGO SOCHIAPAN",
      name: "SANTIAGO SOCHIAPAN",
      parent: "VERACRUZ",
    },
    {
      value: "ABALÁ",
      name: "ABALÁ",
      parent: "YUCATÁN",
    },
    {
      value: "ACANCEH",
      name: "ACANCEH",
      parent: "YUCATÁN",
    },
    {
      value: "AKIL",
      name: "AKIL",
      parent: "YUCATÁN",
    },
    {
      value: "BACA",
      name: "BACA",
      parent: "YUCATÁN",
    },
    {
      value: "BOKOBÁ",
      name: "BOKOBÁ",
      parent: "YUCATÁN",
    },
    {
      value: "BUCTZOTZ",
      name: "BUCTZOTZ",
      parent: "YUCATÁN",
    },
    {
      value: "CACALCHÉN",
      name: "CACALCHÉN",
      parent: "YUCATÁN",
    },
    {
      value: "CALOTMUL",
      name: "CALOTMUL",
      parent: "YUCATÁN",
    },
    {
      value: "CANSAHCAB",
      name: "CANSAHCAB",
      parent: "YUCATÁN",
    },
    {
      value: "CANTAMAYEC",
      name: "CANTAMAYEC",
      parent: "YUCATÁN",
    },
    {
      value: "CELESTÚN",
      name: "CELESTÚN",
      parent: "YUCATÁN",
    },
    {
      value: "CENOTILLO",
      name: "CENOTILLO",
      parent: "YUCATÁN",
    },
    {
      value: "CONKAL",
      name: "CONKAL",
      parent: "YUCATÁN",
    },
    {
      value: "CUNCUNUL",
      name: "CUNCUNUL",
      parent: "YUCATÁN",
    },
    {
      value: "CUZAMÁ",
      name: "CUZAMÁ",
      parent: "YUCATÁN",
    },
    {
      value: "CHACSINKÍN",
      name: "CHACSINKÍN",
      parent: "YUCATÁN",
    },
    {
      value: "CHANKOM",
      name: "CHANKOM",
      parent: "YUCATÁN",
    },
    {
      value: "CHAPAB",
      name: "CHAPAB",
      parent: "YUCATÁN",
    },
    {
      value: "CHEMAX",
      name: "CHEMAX",
      parent: "YUCATÁN",
    },
    {
      value: "CHICXULUB PUEBLO",
      name: "CHICXULUB PUEBLO",
      parent: "YUCATÁN",
    },
    {
      value: "CHICHIMILÁ",
      name: "CHICHIMILÁ",
      parent: "YUCATÁN",
    },
    {
      value: "CHIKINDZONOT",
      name: "CHIKINDZONOT",
      parent: "YUCATÁN",
    },
    {
      value: "CHOCHOLÁ",
      name: "CHOCHOLÁ",
      parent: "YUCATÁN",
    },
    {
      value: "CHUMAYEL",
      name: "CHUMAYEL",
      parent: "YUCATÁN",
    },
    {
      value: "DZÁN",
      name: "DZÁN",
      parent: "YUCATÁN",
    },
    {
      value: "DZEMUL",
      name: "DZEMUL",
      parent: "YUCATÁN",
    },
    {
      value: "DZIDZANTÚN",
      name: "DZIDZANTÚN",
      parent: "YUCATÁN",
    },
    {
      value: "DZILAM DE BRAVO",
      name: "DZILAM DE BRAVO",
      parent: "YUCATÁN",
    },
    {
      value: "DZILAM GONZÁLEZ",
      name: "DZILAM GONZÁLEZ",
      parent: "YUCATÁN",
    },
    {
      value: "DZITÁS",
      name: "DZITÁS",
      parent: "YUCATÁN",
    },
    {
      value: "DZONCAUICH",
      name: "DZONCAUICH",
      parent: "YUCATÁN",
    },
    {
      value: "ESPITA",
      name: "ESPITA",
      parent: "YUCATÁN",
    },
    {
      value: "HALACHÓ",
      name: "HALACHÓ",
      parent: "YUCATÁN",
    },
    {
      value: "HOCABÁ",
      name: "HOCABÁ",
      parent: "YUCATÁN",
    },
    {
      value: "HOCTÚN",
      name: "HOCTÚN",
      parent: "YUCATÁN",
    },
    {
      value: "HOMÚN",
      name: "HOMÚN",
      parent: "YUCATÁN",
    },
    {
      value: "HUHÍ",
      name: "HUHÍ",
      parent: "YUCATÁN",
    },
    {
      value: "HUNUCMÁ",
      name: "HUNUCMÁ",
      parent: "YUCATÁN",
    },
    {
      value: "IXIL",
      name: "IXIL",
      parent: "YUCATÁN",
    },
    {
      value: "IZAMAL",
      name: "IZAMAL",
      parent: "YUCATÁN",
    },
    {
      value: "KANASÍN",
      name: "KANASÍN",
      parent: "YUCATÁN",
    },
    {
      value: "KANTUNIL",
      name: "KANTUNIL",
      parent: "YUCATÁN",
    },
    {
      value: "KAUA",
      name: "KAUA",
      parent: "YUCATÁN",
    },
    {
      value: "KINCHIL",
      name: "KINCHIL",
      parent: "YUCATÁN",
    },
    {
      value: "KOPOMÁ",
      name: "KOPOMÁ",
      parent: "YUCATÁN",
    },
    {
      value: "MAMA",
      name: "MAMA",
      parent: "YUCATÁN",
    },
    {
      value: "MANÍ",
      name: "MANÍ",
      parent: "YUCATÁN",
    },
    {
      value: "MAXCANÚ",
      name: "MAXCANÚ",
      parent: "YUCATÁN",
    },
    {
      value: "MAYAPÁN",
      name: "MAYAPÁN",
      parent: "YUCATÁN",
    },
    {
      value: "MÉRIDA",
      name: "MÉRIDA",
      parent: "YUCATÁN",
    },
    {
      value: "MOCOCHÁ",
      name: "MOCOCHÁ",
      parent: "YUCATÁN",
    },
    {
      value: "MOTUL",
      name: "MOTUL",
      parent: "YUCATÁN",
    },
    {
      value: "MUNA",
      name: "MUNA",
      parent: "YUCATÁN",
    },
    {
      value: "MUXUPIP",
      name: "MUXUPIP",
      parent: "YUCATÁN",
    },
    {
      value: "OPICHÉN",
      name: "OPICHÉN",
      parent: "YUCATÁN",
    },
    {
      value: "OXKUTZCAB",
      name: "OXKUTZCAB",
      parent: "YUCATÁN",
    },
    {
      value: "PANABÁ",
      name: "PANABÁ",
      parent: "YUCATÁN",
    },
    {
      value: "PETO",
      name: "PETO",
      parent: "YUCATÁN",
    },
    {
      value: "PROGRESO",
      name: "PROGRESO",
      parent: "YUCATÁN",
    },
    {
      value: "QUINTANA ROO",
      name: "QUINTANA ROO",
      parent: "YUCATÁN",
    },
    {
      value: "RÍO LAGARTOS",
      name: "RÍO LAGARTOS",
      parent: "YUCATÁN",
    },
    {
      value: "SACALUM",
      name: "SACALUM",
      parent: "YUCATÁN",
    },
    {
      value: "SAMAHIL",
      name: "SAMAHIL",
      parent: "YUCATÁN",
    },
    {
      value: "SANAHCAT",
      name: "SANAHCAT",
      parent: "YUCATÁN",
    },
    {
      value: "SAN FELIPE",
      name: "SAN FELIPE",
      parent: "YUCATÁN",
    },
    {
      value: "SANTA ELENA",
      name: "SANTA ELENA",
      parent: "YUCATÁN",
    },
    {
      value: "SEYÉ",
      name: "SEYÉ",
      parent: "YUCATÁN",
    },
    {
      value: "SINANCHÉ",
      name: "SINANCHÉ",
      parent: "YUCATÁN",
    },
    {
      value: "SOTUTA",
      name: "SOTUTA",
      parent: "YUCATÁN",
    },
    {
      value: "SUCILÁ",
      name: "SUCILÁ",
      parent: "YUCATÁN",
    },
    {
      value: "SUDZAL",
      name: "SUDZAL",
      parent: "YUCATÁN",
    },
    {
      value: "SUMA",
      name: "SUMA",
      parent: "YUCATÁN",
    },
    {
      value: "TAHDZIÚ",
      name: "TAHDZIÚ",
      parent: "YUCATÁN",
    },
    {
      value: "TAHMEK",
      name: "TAHMEK",
      parent: "YUCATÁN",
    },
    {
      value: "TEABO",
      name: "TEABO",
      parent: "YUCATÁN",
    },
    {
      value: "TECOH",
      name: "TECOH",
      parent: "YUCATÁN",
    },
    {
      value: "TEKAL DE VENEGAS",
      name: "TEKAL DE VENEGAS",
      parent: "YUCATÁN",
    },
    {
      value: "TEKANTÓ",
      name: "TEKANTÓ",
      parent: "YUCATÁN",
    },
    {
      value: "TEKAX",
      name: "TEKAX",
      parent: "YUCATÁN",
    },
    {
      value: "TEKIT",
      name: "TEKIT",
      parent: "YUCATÁN",
    },
    {
      value: "TEKOM",
      name: "TEKOM",
      parent: "YUCATÁN",
    },
    {
      value: "TELCHAC PUEBLO",
      name: "TELCHAC PUEBLO",
      parent: "YUCATÁN",
    },
    {
      value: "TELCHAC PUERTO",
      name: "TELCHAC PUERTO",
      parent: "YUCATÁN",
    },
    {
      value: "TEMAX",
      name: "TEMAX",
      parent: "YUCATÁN",
    },
    {
      value: "TEMOZÓN",
      name: "TEMOZÓN",
      parent: "YUCATÁN",
    },
    {
      value: "TEPAKÁN",
      name: "TEPAKÁN",
      parent: "YUCATÁN",
    },
    {
      value: "TETIZ",
      name: "TETIZ",
      parent: "YUCATÁN",
    },
    {
      value: "TEYA",
      name: "TEYA",
      parent: "YUCATÁN",
    },
    {
      value: "TICUL",
      name: "TICUL",
      parent: "YUCATÁN",
    },
    {
      value: "TIMUCUY",
      name: "TIMUCUY",
      parent: "YUCATÁN",
    },
    {
      value: "TINUM",
      name: "TINUM",
      parent: "YUCATÁN",
    },
    {
      value: "TIXCACALCUPUL",
      name: "TIXCACALCUPUL",
      parent: "YUCATÁN",
    },
    {
      value: "TIXKOKOB",
      name: "TIXKOKOB",
      parent: "YUCATÁN",
    },
    {
      value: "TIXMEHUAC",
      name: "TIXMEHUAC",
      parent: "YUCATÁN",
    },
    {
      value: "TIXPÉHUAL",
      name: "TIXPÉHUAL",
      parent: "YUCATÁN",
    },
    {
      value: "TIZIMÍN",
      name: "TIZIMÍN",
      parent: "YUCATÁN",
    },
    {
      value: "TUNKÁS",
      name: "TUNKÁS",
      parent: "YUCATÁN",
    },
    {
      value: "TZUCACAB",
      name: "TZUCACAB",
      parent: "YUCATÁN",
    },
    {
      value: "UAYMA",
      name: "UAYMA",
      parent: "YUCATÁN",
    },
    {
      value: "UCÚ",
      name: "UCÚ",
      parent: "YUCATÁN",
    },
    {
      value: "UMÁN",
      name: "UMÁN",
      parent: "YUCATÁN",
    },
    {
      value: "VALLADOLID",
      name: "VALLADOLID",
      parent: "YUCATÁN",
    },
    {
      value: "XOCCHEL",
      name: "XOCCHEL",
      parent: "YUCATÁN",
    },
    {
      value: "YAXCABÁ",
      name: "YAXCABÁ",
      parent: "YUCATÁN",
    },
    {
      value: "YAXKUKUL",
      name: "YAXKUKUL",
      parent: "YUCATÁN",
    },
    {
      value: "YOBAÍN",
      name: "YOBAÍN",
      parent: "YUCATÁN",
    },
    {
      value: "APOZOL",
      name: "APOZOL",
      parent: "ZACATECAS",
    },
    {
      value: "APULCO",
      name: "APULCO",
      parent: "ZACATECAS",
    },
    {
      value: "ATOLINGA",
      name: "ATOLINGA",
      parent: "ZACATECAS",
    },
    {
      value: "BENITO JUÁREZ",
      name: "BENITO JUÁREZ",
      parent: "ZACATECAS",
    },
    {
      value: "CALERA",
      name: "CALERA",
      parent: "ZACATECAS",
    },
    {
      value: "CAÑITAS DE FELIPE PESCADOR",
      name: "CAÑITAS DE FELIPE PESCADOR",
      parent: "ZACATECAS",
    },
    {
      value: "CONCEPCIÓN DEL ORO",
      name: "CONCEPCIÓN DEL ORO",
      parent: "ZACATECAS",
    },
    {
      value: "CUAUHTÉMOC",
      name: "CUAUHTÉMOC",
      parent: "ZACATECAS",
    },
    {
      value: "CHALCHIHUITES",
      name: "CHALCHIHUITES",
      parent: "ZACATECAS",
    },
    {
      value: "FRESNILLO",
      name: "FRESNILLO",
      parent: "ZACATECAS",
    },
    {
      value: "TRINIDAD GARCÍA DE LA CADENA",
      name: "TRINIDAD GARCÍA DE LA CADENA",
      parent: "ZACATECAS",
    },
    {
      value: "GENARO CODINA",
      name: "GENARO CODINA",
      parent: "ZACATECAS",
    },
    {
      value: "GENERAL ENRIQUE ESTRADA",
      name: "GENERAL ENRIQUE ESTRADA",
      parent: "ZACATECAS",
    },
    {
      value: "GENERAL FRANCISCO R. MURGUÍA",
      name: "GENERAL FRANCISCO R. MURGUÍA",
      parent: "ZACATECAS",
    },
    {
      value: "EL PLATEADO DE JOAQUÍN AMARO",
      name: "EL PLATEADO DE JOAQUÍN AMARO",
      parent: "ZACATECAS",
    },
    {
      value: "GENERAL PÁNFILO NATERA",
      name: "GENERAL PÁNFILO NATERA",
      parent: "ZACATECAS",
    },
    {
      value: "GUADALUPE",
      name: "GUADALUPE",
      parent: "ZACATECAS",
    },
    {
      value: "HUANUSCO",
      name: "HUANUSCO",
      parent: "ZACATECAS",
    },
    {
      value: "JALPA",
      name: "JALPA",
      parent: "ZACATECAS",
    },
    {
      value: "JEREZ",
      name: "JEREZ",
      parent: "ZACATECAS",
    },
    {
      value: "JIMÉNEZ DEL TEUL",
      name: "JIMÉNEZ DEL TEUL",
      parent: "ZACATECAS",
    },
    {
      value: "JUAN ALDAMA",
      name: "JUAN ALDAMA",
      parent: "ZACATECAS",
    },
    {
      value: "JUCHIPILA",
      name: "JUCHIPILA",
      parent: "ZACATECAS",
    },
    {
      value: "LORETO",
      name: "LORETO",
      parent: "ZACATECAS",
    },
    {
      value: "LUIS MOYA",
      name: "LUIS MOYA",
      parent: "ZACATECAS",
    },
    {
      value: "MAZAPIL",
      name: "MAZAPIL",
      parent: "ZACATECAS",
    },
    {
      value: "MELCHOR OCAMPO",
      name: "MELCHOR OCAMPO",
      parent: "ZACATECAS",
    },
    {
      value: "MEZQUITAL DEL ORO",
      name: "MEZQUITAL DEL ORO",
      parent: "ZACATECAS",
    },
    {
      value: "MIGUEL AUZA",
      name: "MIGUEL AUZA",
      parent: "ZACATECAS",
    },
    {
      value: "MOMAX",
      name: "MOMAX",
      parent: "ZACATECAS",
    },
    {
      value: "MONTE ESCOBEDO",
      name: "MONTE ESCOBEDO",
      parent: "ZACATECAS",
    },
    {
      value: "MORELOS",
      name: "MORELOS",
      parent: "ZACATECAS",
    },
    {
      value: "MOYAHUA DE ESTRADA",
      name: "MOYAHUA DE ESTRADA",
      parent: "ZACATECAS",
    },
    {
      value: "NOCHISTLÁN DE MEJÍA",
      name: "NOCHISTLÁN DE MEJÍA",
      parent: "ZACATECAS",
    },
    {
      value: "NORIA DE ÁNGELES",
      name: "NORIA DE ÁNGELES",
      parent: "ZACATECAS",
    },
    {
      value: "OJOCALIENTE",
      name: "OJOCALIENTE",
      parent: "ZACATECAS",
    },
    {
      value: "PÁNUCO",
      name: "PÁNUCO",
      parent: "ZACATECAS",
    },
    {
      value: "PINOS",
      name: "PINOS",
      parent: "ZACATECAS",
    },
    {
      value: "RÍO GRANDE",
      name: "RÍO GRANDE",
      parent: "ZACATECAS",
    },
    {
      value: "SAIN ALTO",
      name: "SAIN ALTO",
      parent: "ZACATECAS",
    },
    {
      value: "EL SALVADOR",
      name: "EL SALVADOR",
      parent: "ZACATECAS",
    },
    {
      value: "SOMBRERETE",
      name: "SOMBRERETE",
      parent: "ZACATECAS",
    },
    {
      value: "SUSTICACÁN",
      name: "SUSTICACÁN",
      parent: "ZACATECAS",
    },
    {
      value: "TABASCO",
      name: "TABASCO",
      parent: "ZACATECAS",
    },
    {
      value: "TEPECHITLÁN",
      name: "TEPECHITLÁN",
      parent: "ZACATECAS",
    },
    {
      value: "TEPETONGO",
      name: "TEPETONGO",
      parent: "ZACATECAS",
    },
    {
      value: "TEÚL DE GONZÁLEZ ORTEGA",
      name: "TEÚL DE GONZÁLEZ ORTEGA",
      parent: "ZACATECAS",
    },
    {
      value: "TLALTENANGO DE SÁNCHEZ ROMÁN",
      name: "TLALTENANGO DE SÁNCHEZ ROMÁN",
      parent: "ZACATECAS",
    },
    {
      value: "VALPARAÍSO",
      name: "VALPARAÍSO",
      parent: "ZACATECAS",
    },
    {
      value: "VETAGRANDE",
      name: "VETAGRANDE",
      parent: "ZACATECAS",
    },
    {
      value: "VILLA DE COS",
      name: "VILLA DE COS",
      parent: "ZACATECAS",
    },
    {
      value: "VILLA GARCÍA",
      name: "VILLA GARCÍA",
      parent: "ZACATECAS",
    },
    {
      value: "VILLA GONZÁLEZ ORTEGA",
      name: "VILLA GONZÁLEZ ORTEGA",
      parent: "ZACATECAS",
    },
    {
      value: "VILLA HIDALGO",
      name: "VILLA HIDALGO",
      parent: "ZACATECAS",
    },
    {
      value: "VILLANUEVA",
      name: "VILLANUEVA",
      parent: "ZACATECAS",
    },
    {
      value: "ZACATECAS",
      name: "ZACATECAS",
      parent: "ZACATECAS",
    },
    {
      value: "TRANCOSO",
      name: "TRANCOSO",
      parent: "ZACATECAS",
    },
    {
      value: "SANTA MARÍA DE LA PAZ",
      name: "SANTA MARÍA DE LA PAZ",
      parent: "ZACATECAS",
    },
  ],
  [CatalogsEnum.Banks]: [
    { value: "02001", name: "BANXICO" },
    { value: "37006", name: "BANCOMEXT" },
    { value: "37009", name: "BANOBRAS" },
    { value: "37019", name: "BANJERCITO" },
    { value: "37135", name: "NACIONAL FINANCIERA" },
    { value: "37166", name: "BANSEFI" },
    { value: "37168", name: "HIPOTECARIA FEDERAL" },
    { value: "40002", name: "BANAMEX" },
    { value: "40012", name: "BBVA BANCOMER" },
    { value: "40014", name: "BANCO SANTANDER" },
    { value: "40021", name: "HSBC" },
    { value: "40030", name: "BAJIO" },
    { value: "40032", name: "IXE" },
    { value: "40036", name: "INBURSA" },
    { value: "40037", name: "INTERACCIONES" },
    { value: "40042", name: "MIFEL" },
    { value: "40044", name: "SCOTIA BANK INVERLAT" },
    { value: "40058", name: "BANREGIO" },
    { value: "40059", name: "INVEX" },
    { value: "40060", name: "BANSI" },
    { value: "40062", name: "AFIRME" },
    { value: "40072", name: "BANORTE" },
    { value: "40102", name: "ROYAL BANK OF SCOTLAND" },
    { value: "40103", name: "AMERICAN EXPRESS" },
    { value: "40106", name: "BANK OF AMERICA" },
    { value: "40108", name: "TOKYO" },
    { value: "40110", name: "JP MORGAN" },
    { value: "40112", name: "BANCO MONEX" },
    { value: "40113", name: "VE POR MAS" },
    { value: "40116", name: "ING" },
    { value: "40124", name: "DEUTCHE" },
    { value: "40126", name: "CREDIT SUISSE" },
    { value: "40127", name: "AZTECA" },
    { value: "40128", name: "BANCO AUTOFIN" },
    { value: "40129", name: "BARCLAYS BANK" },
    { value: "40130", name: "BANCO COMPARTAMOS" },
    { value: "40131", name: "BANCO DE AHORRO FAMSA" },
    { value: "40132", name: "BANCO MULTIVA" },
    { value: "40133", name: "PRUDENTIAL BANK" },
    { value: "40134", name: "BANCO WAL MART" },
    { value: "40136", name: "BANCO REGIONAL SA" },
    { value: "40137", name: "BANCOPPEL" },
    { value: "40138", name: "BANCO AMIGO" },
    { value: "40139", name: "UBS BANK MEXICO" },
    { value: "40140", name: "BANCO FACIL" },
    { value: "40141", name: "VOLKSWAGEN BANK" },
    { value: "40143", name: "BANCO CONSULTORIA" },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { value: "0", name: "Corriente" },
    { value: "1", name: "Ahorros" },
  ],
  [CatalogsEnum.Currency]: [{ value: CurrencyEnum.MXN, name: "Pesos" }],
  [CatalogsEnum.TaxRegime]: [
    { value: "601", name: "General de Ley Personas Morales" },
    { value: "603", name: "Personas Morales con Fines no Lucrativos" },
    {
      value: "605",
      name: "Sueldos y Salarios e Ingresos Asimilados a Salarios",
    },
    { value: "606", name: "Arrendamiento" },
    { value: "607", name: "Régimen de Enajenación o Adquisición de Bienes" },
    { value: "608", name: "Demás ingresos" },
    { value: "609", name: "Consolidación" },
    {
      value: "610",
      name: "Residentes en el Extranjero sin Establecimiento Permanente en México",
    },
    { value: "611", name: "Ingresos por Dividendos (socios y accionistas)" },
    {
      value: "612",
      name: "Personas Físicas con Actividades Empresariales y Profesionales",
    },
    { value: "614", name: "Ingresos por intereses" },
    { value: "615", name: "Régimen de los ingresos por obtención de premios" },
    { value: "616", name: "Sin obligaciones fiscales" },
    {
      value: "620",
      name: "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
    },
    { value: "621", name: "Incorporación Fiscal" },
    {
      value: "622",
      name: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
    },
    { value: "623", name: "Opcional para Grupos de Sociedades" },
    { value: "624", name: "Coordinados" },
    {
      value: "625",
      name: "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
    },
    { value: "626", name: "Régimen Simplificado de Confianza" },
    { value: "628", name: "Hidrocarburos" },
    {
      value: "629",
      name: "De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales",
    },
    { value: "630", name: "Enajenación de acciones en bolsa de valores" },
  ],
};
