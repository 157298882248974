import React, { FC } from "react";
import { TextfieldPhone } from "@kushki/connect-ui";
import { get } from "lodash";
import { Controller } from "react-hook-form";
import { IPhoneInputFieldProps } from "./PhoneInputField.interfaces";
import {
  getCountryCodeMessage,
  getCountryPhoneCode,
} from "../../../shared/services/CatalogCountryPhoneCode";

const PhoneInputField: FC<IPhoneInputFieldProps> = ({
  control,
  errors,
  watch,
  rules = {},
  country,
  disabled,
  labelName,
  fieldName,
  countryCustomer,
}: IPhoneInputFieldProps) => {
  return (
    <Controller
      name={fieldName}
      rules={rules}
      control={control}
      render={({ field }) => (
        <TextfieldPhone
          {...field}
          error={!!get(errors, fieldName)}
          helperText={getCountryCodeMessage(
            get(errors, fieldName)?.message,
            watch!(fieldName),
            country
          )}
          defaultValue={watch!(fieldName)}
          isDisabled={!!disabled}
          placeholder={labelName}
          removeLada={true}
          selectedCountry={
            countryCustomer === country
              ? getCountryPhoneCode(country)
              : undefined
          }
        />
      )}
    />
  );
};

export default PhoneInputField;
