import { createStyles } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    buttonStyled: {
      marginBottom: "8px",
      marginTop: "20px",
      paddingLeft: "32px",
      paddingRight: "32px",
    },
  })
);
