import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

export const TextfieldAmountInputStyles = createNamedStyles({
  container: {
    marginTop: 2,
  },
  textField: {
    maxWidth: "40%",
  },
});
