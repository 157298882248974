export const contactItemStyles = {
  container: {
    alignItems: "start",
    display: "flex",
    gap: "8px",
  },
  iconUser: {
    color: "primary.main",
    width: "100%",
  },
};
