import { IStyles } from "../../shared/infrastructure/interfaces/Styles.interfaces";

export const merchantResumeStyles: IStyles = {
  branchesContainer: {
    border: 1,
    background: "#FBFCFE",
    boxShadow:
      "0px 8px 17px rgba(106, 167, 197, 0.15), 0px 1.6px 2.7625px rgba(106, 167, 197, 0.075)",
    display: "flex",
    flexDirection: "column",
    padding: {
      md: "7rem 8.5rem",
      xs: "2rem",
    },
  },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: "100px",
    padding: {
      md: "40px 8.5rem",
      xs: "2rem",
    },
    background: "#FAFCFD",
  },
  contentContainer: {
    padding: {
      md: "4rem 3rem",
      xs: "2rem 1.5rem",
    },
  },
  divider: {
    marginTop: "16px",
    borderWidth: ".1px",
    borderStyle: "solid",
    borderColor: "neutral.grey4",
    background: "neutral.grey4",
    marginBottom: "16px",
  },
  regresarBtn: {
    alignSelf: "flex-start",
    border: "unset !important",
    fontWeight: "500",
    padding: "12px 0",
    marginBottom: "40px",
    ":hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    ":focus": {
      backgroundColor: "transparent",
      boxShadow: "none",
      color: "#4498EE",
    },
  },
  boxContainer: {
    boxShadow:
      "0px 8px 17px rgb(106 167 197 / 15%), 0px 1.6px 2.7625px rgb(106 167 197 / 8%)",
    borderRadius: "8px",
    padding: "16px",
    background: "#FBFCFE",
  },
};
