import { SxProps } from "@mui/material";

export const attachFileStyles: Record<string, SxProps> = {
  addIcon: { color: "#023365" },
  attachIcon: {
    border: "3px solid #023365",
    "& svg": { fontSize: "27px !important" },
  },
  captionInfo: { alignItems: "center", display: "flex", gap: "5px" },
  fileInformation: {
    display: "flex",
    flexDirection: "row",
    gap: "12px",
    placeItems: "center",
    width: "100%",
    "& .MuiBox-root": {
      flexBasis: "content",
    },
  },
  modalContainer: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
};
