import { LocalStoragePathEnum } from "../../infrastructure/enums/LocalStoragePathEnum";
import {
  IBasicMerchantInformation,
  IUserInformation,
} from "../../infrastructure/interfaces/IBasicMerchantInformation";
import { defaultTo, isEmpty } from "lodash";
import { CustomerBatchInformation } from "../../../../types/customer_batch_information";

const defaultBasicMerchantInformation: IBasicMerchantInformation = {
  name: "",
  origin: "",
  nodeId: "",
  publicMerchantId: "",
  country: "",
  redirectPath: "",
  constitutionalCountry: "",
  redirectTab: "",
};

export const getBasicMerchantInformation = (): IBasicMerchantInformation => {
  const storageMerchant = window.localStorage.getItem(
    LocalStoragePathEnum.BASIC_MERCHANT_INFORMATION
  );
  if (isEmpty(storageMerchant)) return defaultBasicMerchantInformation;
  const actualValues = JSON.parse(
    defaultTo(storageMerchant, JSON.stringify(defaultBasicMerchantInformation))
  );

  return defaultTo(actualValues, defaultBasicMerchantInformation);
};

export const getCustomerBatchInformation = (): CustomerBatchInformation => {
  const storageMerchant = window.localStorage.getItem(
    LocalStoragePathEnum.CUSTOMER_BATCH_INFORMATION
  );
  if (isEmpty(storageMerchant)) return defaultBasicMerchantInformation;
  const actualValues = JSON.parse(
    defaultTo(storageMerchant, JSON.stringify(defaultBasicMerchantInformation))
  );

  return defaultTo(actualValues, defaultBasicMerchantInformation);
};

export const setBasicMerchantInformation = (
  newValue: Partial<IBasicMerchantInformation>
) => {
  const oldValue = getBasicMerchantInformation();
  const newItem: IBasicMerchantInformation = { ...oldValue, ...newValue };
  const merchantBasicInformation: object = JSON.parse(
    defaultTo(
      localStorage.getItem(LocalStoragePathEnum.MERCHANT_BASIC_INFORMATION),
      "{}"
    )
  );

  window.localStorage.setItem(
    LocalStoragePathEnum.BASIC_MERCHANT_INFORMATION,
    JSON.stringify(newItem)
  );
  window.localStorage.setItem(
    LocalStoragePathEnum.MERCHANT_BASIC_INFORMATION,
    JSON.stringify({
      ...newItem,
      ...merchantBasicInformation,
    })
  );
};

export const getUserInformation = (): IUserInformation => {
  const username = window.localStorage.getItem(LocalStoragePathEnum.USERNAME);
  const email = window.localStorage.getItem(LocalStoragePathEnum.EMAIL);

  const userInformation: IUserInformation = {
    username: defaultTo(username, ""),
    email: defaultTo(email, ""),
  };

  return userInformation;
};
