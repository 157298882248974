import { ICellTextComponent } from "./CellTitle.interfaces";
import { FC } from "react";
import { Box, Link, Typography } from "@mui/material";
import * as React from "react";
import { TableBodyStyles } from "../../Table/TableBody/TableBody.style";

export const CellTitleItem: FC<ICellTextComponent> = ({
  line1,
  line2,
  align,
}: ICellTextComponent) => {
  let styles = TableBodyStyles;

  return (
    <Box alignItems={align}>
      <Typography sx={styles.title}>
        <Link underline={"none"}>{line1!}</Link>
      </Typography>
      <Typography sx={styles.subtitle}>{line2!}</Typography>
    </Box>
  );
};
