import { IUseDefaultConditionComponent } from "../../../../../shared/interfaces/IUseDefaultConditionComponent";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { IUseDefaultComponentResponse } from "../../../../../shared/interfaces/IUseDefaultComponentResponse";

export const useDefaultConditionState = (
  props: IUseDefaultConditionComponent
): IUseDefaultComponentResponse => {
  const { control, setValue, watch } = useFormContext();
  const defaultValue: string = `conditions.${props.index}.value.0`;
  const defaultValueWatch: string = watch(defaultValue);

  useEffect(() => {
    if (props.defaultValueList.length != 0) {
      setValue(defaultValue, props.defaultValueList[0]);
      props.setSelectedItems([
        {
          label: props.ruleType,
          selected: true,
          value: props.defaultValueList[0],
        },
      ]);
    }
  }, [props.defaultValueList]);

  useEffect(() => {
    props.setSelectedItems([
      {
        label: props.ruleType,
        selected: true,
        value: [defaultValueWatch].toString(),
      },
    ]);
  }, [defaultValueWatch]);

  return {
    control,
    defaultValue,
    defaultValueWatch,
    setValue,
  };
};
