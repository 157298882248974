export enum RolesExecutorEnum {
  M_BILLING_DOWNLOAD = "M_BILLING.EjecutadorFacturación.Descargar",
  M_BILLING_EXECUTE = "M_BILLING.EjecutadorFacturación.Ejecutar",
  M_BILLING_MODAL_FOOTER = "M_BILLING.EjecutadorFacturación.Detalle.DescargaryConfiguraciónAvanzada",
  M_BILLING_EDIT_AMOUNT = "M_BILLING.EjecutadorFacturación.Detalle.EditarMonto",
}

export enum RolesValidatorEnum {
  M_BILLING_DOWNLOAD = "M_BILLING.AprobadorFacturación.Descargar",
  M_BILLING_EXECUTE = "M_BILLING.AprobadorFacturación.Procesar",
  M_BILLING_MODAL_FOOTER = "M_BILLING.AprobadorFacturación.Detalle.DescargaryConfiguraciónAvanzada",
  M_BILLING_EDIT_AMOUNT = "M_BILLING.AprobadorFacturación.Detalle.EditarMonto",
}

export enum RolesHistoricEnum {
  M_BILLING_HISTORIC_DOWNLOAD = "M_BILLING.Histórico.Descargar",
  M_BILLING_HISTORIC_MODAL_FOOTER = "M_BILLING.Histórico.Detalle.DescargaryGeneraryAnular",
  M_BILLING_HISTORIC_REPROCESSED_CHARGES = "M_BILLING.Histórico.ReprocesarCobrosPendientes",
}

export enum RolesRetentionEnum {
  M_BILLING_RETENTION_DOWNLOAD = "M_BILLING.Retención.Descargar",
  M_BILLING_RETENTION_EXECUTE = "M_BILLING.EjecutadorRetención.Ejecutar",
}
