export enum TransactionLabelsEnum {
  LABEL_ADMIN = "Buscar Customer, Branch o Merchant",
  LABEL_BRANCHES = "Selecciona uno o varios branches",
  TITLE_ALERT = "Las Transacciones mostradas actualmente en la tabla corresponden a los siguientes Branches. Es necesario remover el filtro de branch para poder visualizar todos los branches.",
  ADMIN = "admin",
  MERCHANT = "merchant",
  MODAL_DETAIL_TITLE = "Detalle de la transacción",
  MODAL_DETAIL_BUTTON_SECONDARY = "Descargar recibo",
  MODAL_DETAIL_BUTTON_PRIMARY = "Solicitar Devolución",
  MODAL_DETAIL_TAB_1 = "Información",
  MODAL_DETAIL_TAB_2 = "Línea de tiempo",
  MODAL_DETAIL_TAB_3 = "Metadata",
  MODAL_DETAIL_DATE = "Fecha y hora de la creación: ",
  MODAL_DETAIL_DATE_FORMAT = "dd/MM/yyyy - HH:mm",
}

export enum TransactionTabValueEnum {
  TIME_LINE = "timeLine",
  INFORMATION = "information",
  METADATA = "metadata",
}
