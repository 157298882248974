import { useEffect, useState } from "react";
import React from "react";
import {
  HeaderCellProps,
  ITableCellProps,
  ITableRowProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import {
  buildOneCell,
  isCentralizedBranch,
} from "../../../shared/utils/utilsFile";
import { LabelEnum, TABLE_COLUMNS } from "../../../shared/enums/LabelEnum";
import { IOptionListItemProps, useSnackbar } from "@kushki/connect-ui";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import CheckIcon from "@mui/icons-material/Check";
import {
  BUSINESS_RULES_COLUMNS,
  TableBodyCellEnum,
  TableFieldsEnum,
} from "../../../shared/enums/DataTableEnum";
import { format } from "date-fns-tz";
import { defaultTo, get, isEmpty, remove, set } from "lodash";
import { PathsEnum } from "../../../shared/enums/PathsEnum";
import { STATUS_SPANISH, StatusEnum } from "../../../shared/enums/StatusEnum";
import {
  CATEGORY_BY_CONDITIONS,
  DateFormatEnum,
} from "../../../shared/infrastructure/constants/BusinessRuleConstants";
import {
  AlertEnum,
  ERROR_MESSAGE_ALERT,
  SUCCESS_MESSAGE_ALERT,
} from "../../../shared/enums/AlertEnum";
import { StylesEnum } from "../../../shared/infrastructure/constants/StyleEnum";
import { useParams } from "react-router-dom";
import { IUseFilterTableState } from "../../../shared/interfaces/IFilterTableProps";
import { ISearchByList } from "../../../shared/interfaces/ISearchByList";
import { RootState } from "../../../store/store";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import {
  setIsFilterSearch,
  setPayoutRules,
  showAlert,
  showConfirmModal,
  showModalRuleDetail,
} from "../../../store/reducers/app/app";
import {
  Condition,
  GetPayoutsRulesResponse,
  Rule,
} from "../../../../types/get_payouts_rules_response";
import {
  deleteRuleById,
  disableRuleById,
  getConfiguration,
  getPayoutsRules,
  getRuleById,
} from "../../../store/thunks/app/app.thunks";
import {
  Filter,
  GetPayoutsRulesRequest,
} from "../../../../types/get_payouts_rules_request";
import {
  OptionsTypeEnum,
  TableOptionsEnum,
} from "../../../shared/enums/TableBodyCellEnum";
import { TIME_OUT_VALUE } from "../../../shared/constants/TimeOutValue";
import { DeleteRuleRequest } from "../../../../types/delete_rule_request";
import { ROUTES } from "../../../shared/constants/routes";
import { getBusinessRulesStorage } from "../../../shared/utils/localStorage";
import { LocalStoragePathEnum } from "../../../shared/enums/LocalStoragePathEnum";

export const useFilterTableState = (
  isBatch?: boolean
): IUseFilterTableState => {
  const dispatch = useAppDispatch();
  const { showSnackbar } = useSnackbar();
  const { merchantId } = useParams();
  const cells: HeaderCellProps[] = isCentralizedBranch()
    ? TABLE_COLUMNS[LabelEnum.BUSINESS_RULES_CENTRALIZED]
    : TABLE_COLUMNS[LabelEnum.BUSINESS_RULES];
  const [rows, setRows] = useState<ITableRowProps[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [ruleId, setRuleId] = useState<string>("");
  const [offset, setOffset] = useState<number>(0);
  const [ruleTimeOut, setRuleTimeOut] = useState<any>(0);
  const [searchRuleRequest, setSearchRuleRequest] = useState<
    GetPayoutsRulesRequest | undefined
  >();
  const [filterSelectList, setFilterSelectList] = useState<ISearchByList[]>([]);
  const {
    openConfirmModal,
    loadingModal,
    openAlert,
    payoutsRules,
    isFilterSearch,
  } = useAppSelector((state: RootState) => ({ ...state.app }));
  const defaultFilters: GetPayoutsRulesRequest = {
    limit: itemsPerPage,
    merchantId: defaultTo(merchantId, ""),
    offset,
  };

  const handleAddRule = (): void => {
    window.location.href = `${ROUTES.CREATE_BUSINESS_RULE}`;
  };

  const handleEditRule = (ruleId: string): void => {
    window.location.href = ROUTES.EDIT_BUSINESS_RULE.replace(":ruleId", ruleId);
  };

  const handleUpdateStatusRule = (): void => {
    dispatch(showConfirmModal(false));
    dispatch(disableRuleById({ id: ruleId, status: StatusEnum.DISABLE }));
    setTimeout(() => {
      dispatch(getPayoutsRules(defaultTo(searchRuleRequest, defaultFilters)));
    }, 3000);
  };

  const handleCancelConfirmModal = (): void => {
    dispatch(showConfirmModal(false));
  };

  const handleAcceptConfirmModal = (): void => {
    dispatch(showConfirmModal(true));
  };

  const handleDeleteRule = (id: string): void => {
    if (isBatch) {
      const businessRulesStorage: GetPayoutsRulesResponse =
        getBusinessRulesStorage();

      remove(businessRulesStorage.data, { id });

      localStorage.setItem(
        LocalStoragePathEnum.BUSINESS_RULES_BATCH,
        JSON.stringify({
          data: businessRulesStorage.data,
          total: businessRulesStorage.data.length,
        })
      );

      dispatch(setPayoutRules(businessRulesStorage));
      dispatch(
        showAlert({
          isError: false,
          open: true,
          originAlert: AlertEnum.ORIGIN_DELETE,
        })
      );

      return;
    }

    const request: DeleteRuleRequest = {
      id,
      status: StatusEnum.DELETE,
    };

    dispatch(deleteRuleById(request));
    setTimeout(() => {
      dispatch(getPayoutsRules(defaultTo(searchRuleRequest, defaultFilters)));
    }, 3000);

    return;
  };

  const handleSetOffset = (value: number) => {
    setSelectedPage(1);
    setOffset(value);
  };

  const handleSearchRuleRequest = (
    value: GetPayoutsRulesRequest | undefined
  ) => {
    setSearchRuleRequest(value);
  };

  const calculateOffset = (page: number, itemsPerPage: number): void => {
    setOffset((page - 1) * itemsPerPage);
    setItemsPerPage(itemsPerPage);
  };

  const handleItemsPerPage = (value: number) => {
    setSelectedPage(1);
    calculateOffset(1, value);
  };

  const handleSelectedPage = (value: number) => {
    setSelectedPage(value);
    calculateOffset(value, itemsPerPage);
  };

  const getActionsTable = (
    ruleId: string,
    status: string,
    sent: boolean
  ): ITableCellProps => {
    const deleteDp: IOptionListItemProps = {
      disable: sent,
      handleOptionClick: () => {},
      onClick: () => {
        handleDeleteRule(ruleId);
      },
      optionText: TableOptionsEnum.DELETE,
      type: OptionsTypeEnum.TYPE1,
    };
    const enableDp: IOptionListItemProps = {
      disable: isBatch,
      handleOptionClick: () => {},
      onClick: () => {
        handleEditRule(ruleId);
      },
      optionText: TableOptionsEnum.ENABLE,
      type: OptionsTypeEnum.TYPE1,
    };
    const disableDp: IOptionListItemProps = {
      disable: isBatch,
      handleOptionClick: () => {},
      onClick: () => {
        setRuleId(ruleId);
        handleAcceptConfirmModal();
      },
      optionText: TableOptionsEnum.DISABLE,
      type: OptionsTypeEnum.TYPE1,
    };
    const editOp: IOptionListItemProps = {
      disable: sent,
      handleOptionClick: () => {},
      onClick: () => {
        handleEditRule(ruleId);
      },
      optionText: TableOptionsEnum.EDIT,
      type: OptionsTypeEnum.TYPE1,
    };

    switch (status.toUpperCase()) {
      case StatusEnum.ENABLE:
        return {
          props: {
            cellProps: {
              spacing: 0,
            },
            isDisabled: false,
            optionListItems: [editOp, deleteDp, disableDp],
          },
          type: TableBodyCellEnum.OPTIONS,
        };
      case StatusEnum.DISABLE:
        return {
          props: {
            cellProps: {
              spacing: 0,
            },
            isDisabled: false,
            optionListItems: [enableDp, deleteDp],
          },
          type: TableBodyCellEnum.OPTIONS,
        };
      case StatusEnum.DRAFT:
        return {
          props: {
            cellProps: {
              spacing: 0,
            },
            isDisabled: false,
            optionListItems: [enableDp, deleteDp],
          },
          type: TableBodyCellEnum.OPTIONS,
        };
      default:
        return {
          props: {
            cellProps: {
              spacing: 0,
            },
            isDisabled: false,
            optionListItems: [enableDp, deleteDp],
          },
          type: TableBodyCellEnum.OPTIONS,
        };
    }
  };

  const buildCategoryByConditions = (conditions: Condition[]): string => {
    const category: string[] = [];

    conditions.map((condition: Condition) => {
      const value: string = CATEGORY_BY_CONDITIONS[condition.field];

      if (!isEmpty(value)) {
        category.push(value);
      }
    });

    return category.join(", ");
  };

  const buildCells = (item: Rule): ITableCellProps[] => {
    const cells: ITableCellProps[] = [];
    const id: string = get(item, PathsEnum.ID, "");
    const status: string = get(item, "status", "");
    const category: string = buildCategoryByConditions(item.conditions);

    BUSINESS_RULES_COLUMNS.forEach((key: string) => {
      const itemText: string = get(item, key, "");

      switch (key) {
        case TableFieldsEnum.CREATED:
          if (Number(itemText) === 0 || isEmpty(itemText.toString())) {
            cells.push(
              buildOneCell(TableBodyCellEnum.TEXT, LabelEnum.NO_APPLY)
            );
          } else {
            const created: string = format(
              Number(itemText) * 1000,
              DateFormatEnum.yyyy_mm_dd_hh_mm
            );

            cells.push(buildOneCell(TableBodyCellEnum.TEXT, created));
          }
          break;
        case TableFieldsEnum.ALIAS:
          if (isEmpty(itemText)) {
            cells.push(
              buildOneCell(TableBodyCellEnum.TITLE, LabelEnum.NO_APPLY)
            );
          } else {
            cells.push(buildOneCell(TableBodyCellEnum.TITLE, itemText));
          }
          break;
        case TableFieldsEnum.CATEGORY:
          if (isEmpty(category)) {
            cells.push(
              buildOneCell(TableBodyCellEnum.TEXT, LabelEnum.NO_APPLY)
            );
          } else {
            cells.push(buildOneCell(TableBodyCellEnum.TEXT, category));
          }
          break;
        case TableFieldsEnum.PROCESSOR:
          if (isEmpty(itemText)) {
            cells.push(
              buildOneCell(TableBodyCellEnum.TEXT, LabelEnum.NO_APPLY)
            );
          } else {
            cells.push(buildOneCell(TableBodyCellEnum.TEXT, itemText));
          }
          break;
        case TableFieldsEnum.STATUS:
          const status: string = defaultTo(
            STATUS_SPANISH[itemText.toUpperCase()],
            ""
          ).toUpperCase();

          cells.push(
            buildOneCell(
              TableBodyCellEnum.TAG,
              status,
              undefined,
              {
                align: "center",
                spacing: 0,
              },
              itemText
            )
          );
          break;
      }
    });
    if (!isCentralizedBranch()) {
      cells.push(getActionsTable(id, status, get(item, "isSent", false)));
    }

    return cells;
  };

  const handleRequestRuleByIdBranch = (id: string) => {
    dispatch(getRuleById({ id }));
  };

  const transformDataToSchemaRowTable = (data: Rule[]): void => {
    let tableRowsProps: ITableRowProps[] = [];

    data.forEach((item: Rule, index: number) => {
      tableRowsProps = [
        ...tableRowsProps,
        {
          cells: buildCells(item),
          id: index.toString(),
          rowProps: {
            color: "default",
            onClick: () => {
              if (!isBatch) {
                dispatch(showModalRuleDetail(true));
                handleRequestRuleByIdBranch(get(item, PathsEnum.ID, ""));

                return;
              }
            },
          },
        },
      ];
    });

    setRows(tableRowsProps);
  };

  const mapFilterList = (): void => {
    const filterLastList: ISearchByList[] = [];
    const items: Rule[] = get(payoutsRules, "data", []);

    items.map((item: Rule) =>
      filterLastList.push({
        id: get(item, "id", ""),
        secondaryText: get(item, "processorName", ""),
        text: get(item, "name", ""),
      })
    );

    setFilterSelectList(filterLastList);
  };

  const handleSelectItem = (selectedItem: ISearchByList): void => {
    dispatch(setIsFilterSearch(false));
    const search: GetPayoutsRulesRequest = {
      ...defaultFilters,
    };

    set(search, "filter.id", get(selectedItem, "id", ""));
    setSearchRuleRequest(search);
  };

  const handleDataChange = (value: string): void => {
    if (ruleTimeOut) {
      clearTimeout(ruleTimeOut);
    }
    if (value.trim().length > 0) {
      setRuleTimeOut(
        setTimeout(() => {
          dispatch(setIsFilterSearch(true));
          const search: GetPayoutsRulesRequest = {
            ...defaultTo(searchRuleRequest, defaultFilters),
          };

          set(search, "filter.name", value);
          setSearchRuleRequest(search);
        }, TIME_OUT_VALUE.MILLISECONDS)
      );
    }
  };

  useEffect(() => {
    if (openAlert.open) {
      showSnackbar({
        color: openAlert.isError ? "danger" : "info",
        icon: openAlert.isError ? (
          <ErrorOutlinedIcon
            fontSize={StylesEnum.INHERIT}
            color={StylesEnum.INHERIT}
          />
        ) : (
          <CheckIcon fontSize={StylesEnum.INHERIT} color={StylesEnum.PRIMARY} />
        ),
        message: openAlert.isError
          ? ERROR_MESSAGE_ALERT[get(openAlert, "originAlert", "")]
          : SUCCESS_MESSAGE_ALERT[get(openAlert, "originAlert", "")],
        variant: "simple",
        withIcon: true,
      });
      dispatch(showAlert({ isError: false, open: false }));
    }
  }, [openAlert.open]);

  useEffect(() => {
    if (!isFilterSearch) {
      transformDataToSchemaRowTable(get(payoutsRules, "data", []));
    } else {
      mapFilterList();
    }
  }, [payoutsRules]);

  const handleStorageBusinessRules = (search: GetPayoutsRulesRequest): void => {
    const filterUsers = (rules: Rule[], query: Filter) => {
      return rules.filter((rule: Rule) => {
        for (const key in query) {
          if (
            query[key] &&
            rule[key] &&
            !rule[key]
              .toString()
              .toLowerCase()
              .includes(query[key].toString().toLowerCase())
          ) {
            return false;
          }
        }

        return true;
      });
    };

    const storageRuleResponse: GetPayoutsRulesResponse =
      getBusinessRulesStorage();

    const filteredData: Rule[] = filterUsers(
      storageRuleResponse.data,
      defaultTo(search.filter, {})
    );

    dispatch(
      setPayoutRules({ data: filteredData, total: filteredData.length })
    );

    return;
  };

  useEffect(() => {
    const search: GetPayoutsRulesRequest = {
      filter: {
        category: get(searchRuleRequest, "filter.category", ""),
        id: get(searchRuleRequest, "filter.id", ""),
        name: get(searchRuleRequest, "filter.name", ""),
        status: get(searchRuleRequest, "filter.status", ""),
        type: LabelEnum.BUSINESS,
      },
      limit: itemsPerPage,
      merchantId: !isEmpty(get(searchRuleRequest, "merchantId"))
        ? get(searchRuleRequest, "merchantId", "")
        : defaultTo(merchantId, ""),
      offset,
    };

    Object.keys(search.filter!).forEach((key: string) => {
      if (isEmpty(search.filter![key])) {
        delete search.filter![key];
      }
    });

    if (isBatch) {
      setItemsPerPage(100);
      handleStorageBusinessRules(search);

      return;
    }

    dispatch(getPayoutsRules(search));
  }, [itemsPerPage, offset, searchRuleRequest]);

  useEffect(() => {
    dispatch(getConfiguration());
  }, []);

  return {
    actions: {
      handleAddRule,
      handleCancelConfirmModal,
      handleDataChange,
      handleItemsPerPage,
      handleSearchRuleRequest,
      handleSelectedPage,
      handleSelectItem,
      handleSetOffset,
      handleUpdateStatusRule,
    },
    cells,
    filterSelectList,
    isLoading: loadingModal.isLoading,
    itemsPerPage,
    openConfirmModal,
    rows,
    searchRuleRequest,
    searchRuleResponse: payoutsRules,
    selectedPage,
  };
};
