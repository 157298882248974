import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { Box, Button, Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import {
  IconPlus,
  ModalContent,
  ModalContentButtonTypeEnum,
  ModalContentTypeEnum,
  ModalLoader,
} from "@kushki/connect-ui";
import { ADD_BANK_ACCOUNT_MODAL_LABELS } from "../../../shared/constants/labels/add_bank_account_modal_labels";
import {
  BANK_BUTTON_LABEL,
  BANK_BUTTON_LABEL_ALTERNATE,
  BANK_SECTION_LABEL,
  MODAL_LOADER_DESCRIPTION,
  MODAL_LOADER_TITLE,
} from "../../../shared/constants/labels/dispersion_container_labels";
import { ComponentIdsEnum } from "../../../shared/constants/labels/main_containter_labels";
import { getLabelsBankModal } from "../../../shared/utils/bankAccountUtils";
import { dispersionFormStyles as styles } from "../../DispersionForm/DispersionForm.styles";
import { useBankAccountFormsModalState } from "./state/useBankAccountFormsModalState";
import AddBankAccountSameCountryFlow from "../../AddBankAccountSameCountryFlow/AddBankAccountSameCountryFlow";
import AddBankAccountDifferentCountryFlow from "../../AddBankAccountDifferentCountryFlow/AddBankAccountDifferentCountryFlow";
import { IBankAccountFormsModalProps } from "./BankAccountFormsModal.interfaces";

const BankAccountFormsModal: FC<IBankAccountFormsModalProps> = ({
  isCentralizedBranch,
  canAddAccounts,
}: IBankAccountFormsModalProps) => {
  const {
    addBankAccountSameCountryProps,
    isDiferentConstitutionCountry,
    saveBankAccount,
    openBankAccountModal,
    isEditingForm,
    isLoading,
    closeBankAccountModal,
    handleOpenBankAccountModal,
  } = useBankAccountFormsModalState();

  return (
    <>
      <Box sx={styles.boxFlex}>
        <Grid item xs={7}>
          <Typography color="text.grey" variant="body2" sx={styles.label}>
            {BANK_SECTION_LABEL}
          </Typography>
        </Grid>
        <Grid item xs={5} sx={styles.boxFlexEnd}>
          <ComponentSecurityWrapper
            componentId={ComponentIdsEnum.M_CLIENTS_ADD_BANK_ACCOUNT_BUTTON}
          >
            <Button
              color="secondary"
              variant={"outlined"}
              startIcon={<IconPlus />}
              disabled={isCentralizedBranch || !canAddAccounts}
              onClick={handleOpenBankAccountModal}
            >
              <Typography
                variant="body2"
                sx={styles.button}
                data-testid="title-modal-id"
              >
                {!addBankAccountSameCountryProps.hasMainAccount
                  ? BANK_BUTTON_LABEL
                  : BANK_BUTTON_LABEL_ALTERNATE}
              </Typography>
            </Button>
          </ComponentSecurityWrapper>
          <ModalLoader
            isOpen={isLoading}
            descriptionText={MODAL_LOADER_DESCRIPTION}
            titleText={MODAL_LOADER_TITLE}
          />
        </Grid>
      </Box>
      <ModalContent
        buttonAction={saveBankAccount}
        buttonText={
          isEditingForm
            ? ADD_BANK_ACCOUNT_MODAL_LABELS.btnText.saveText
            : ADD_BANK_ACCOUNT_MODAL_LABELS.btnText.addText
        }
        buttonType={ModalContentButtonTypeEnum.PRIMARY}
        descriptionText=""
        isOpen={openBankAccountModal}
        onClose={closeBankAccountModal}
        titleText={getLabelsBankModal(
          addBankAccountSameCountryProps.orderAccountState,
          isEditingForm
        )}
        type={ModalContentTypeEnum.ACTIVE}
      >
        {isDiferentConstitutionCountry ? (
          <AddBankAccountDifferentCountryFlow
            {...addBankAccountSameCountryProps}
          />
        ) : (
          <AddBankAccountSameCountryFlow {...addBankAccountSameCountryProps} />
        )}
      </ModalContent>
    </>
  );
};

export default BankAccountFormsModal;
