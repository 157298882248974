import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((_theme) => ({
  dialog: {
    borderRadius: "13px",
    gap: "50px",
    width: "568px",
    display: "block",
    alignItems: "center",
  },
  dialogTitle: {
    color: "#023365",
    fontSize: "26px",
    fontWeight: 600,
    lineHeight: "120%",
    textAlign: "center",
  },
  dialogActions: {
    flexDirection: "column",
    paddingBottom: "40px",
    "& :not(:first-child)": {
      marginTop: "8px",
      marginLeft: "0px",
    },
  },
  textButton: {
    padding: "14px 30px",
    fontWeight: 500,
    fontSize: "18px !important",
    lineHeight: "24px !important",
  },
  closeButton: {
    padding: "8px 30px",
    fontWeight: 500,
    fontSize: "18px !important",
    lineHeight: "24px !important",
  },
  dialogContent: {
    paddingBottom: "10px",
    paddingTop: "0px",
    textAlign: "center",
    margin: "0px 50px",
  },
  dialogContentDescription: {
    paddingBottom: "24px",
    paddingTop: "0px",
    textAlign: "center",
    margin: "0px 40px",
  },
  dialogContentText: {
    fontSize: "0.85rem",
  },
}));
