import { IOptionSelection } from "../interfaces/option_selection";
import { CountryEnum } from "./CountryEnum";
import {
  ACCOUNT_TYPES,
  BANKS_COLOMBIA,
  DOCUMENT_TYPES,
} from "../constants/CatalogCO";

export enum PlaceHolders {
  AMOUNT_SINGLE_VALUE = "Valor",
  INPUT_VALUE = "Ingresa un valor",
}

export enum HelperText {
  AMOUNT_HELPER = "Solo se permiten números",
}

export const getBanksByCountry = (countries: string[]): IOptionSelection[] => {
  let banks: IOptionSelection[] = [];

  if (countries.includes(CountryEnum.COLOMBIA))
    banks = [...banks, ...BANKS_COLOMBIA];

  return banks;
};

export const getAccountTypesByCountry = (
  countries: string[]
): IOptionSelection[] => {
  let banks: IOptionSelection[] = [];

  if (countries.includes(CountryEnum.COLOMBIA))
    banks = [...banks, ...ACCOUNT_TYPES];

  return banks;
};

export const getDocumentTypesByCountry = (
  countries: string[]
): IOptionSelection[] => {
  let banks: IOptionSelection[] = [];

  if (countries.includes(CountryEnum.COLOMBIA))
    banks = [...banks, ...DOCUMENT_TYPES];

  return banks;
};

export enum Labels {
  ACCOUNT_TYPES = "Seleccionar tipo de cuenta",
  BANKS = "Seleccionar institución",
  DOCUMENT_TYPES = "Seleccionar tipo de documento",
}
