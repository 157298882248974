import { ItemCategoryProps } from "@kushki/connect-ui/dist/Components/Molecules/Filters/ItemCategory/interfaces";
import { TFilterDetailBar } from "@kushki/connect-ui/dist/Components/Molecules/Filters/FilterDetailBar/FilterDetailBar.interfaces";

export const changeItemCategoryToFilterDetailBar = (
  itemCategory: ItemCategoryProps[]
) => {
  const filterDetailBar: TFilterDetailBar[] = [];

  itemCategory.map((category) => {
    if (category.items.filter((item) => item.selected).length > 0)
      filterDetailBar.push({
        items: category.items.filter((item) => item.selected),
        title: category.title || category.placeHolder,
      });
  });

  return filterDetailBar;
};
