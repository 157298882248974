import React from "react";
import { TableSimple } from "@kushki/connect-ui";
import { ComponentTextTitleEnum } from "../../shared/infrastructure/enums/ComponentTextTitleEnum";
import { ItemsPerPage } from "../../shared/infrastructure/enums/DataTableEnum";
import { useDataTableComponentState } from "./state/useDataTableComponentState";
import { IDataTableComponent } from "../../shared/infrastructure/interfaces/IDataTableComponent";
import { get } from "lodash";
import { Box } from "@mui/material";
import { dataTableComponentStyles as styles } from "./DataTableComponent.styles";

export enum TableBodyCellEnum {
  TITLE = "TITLE",
  TEXT = "TEXT",
  TAG = "TAG",
  OPTIONS = "OPTIONS",
  SWITCH = "SWITCH",
  CHECK = "CHECK",
  FLAG = "FLAG",
  TEXT_TOOLTIP = "TEXT_TOOLTIP",
  TEXT_ACTION = "TEXT_ACTION",
  CONTENT = "CONTENT",
}

export const DataTableComponent: React.FC<IDataTableComponent> = (
  props: IDataTableComponent
) => {
  const { rows, isLoading, isLoadingCredentials, header } =
    useDataTableComponentState(props);

  return (
    <Box sx={styles.tableContainer}>
      <TableSimple
        footerPagination={{
          onItemsPerPageChange: (value: number) =>
            props.actions.handleItemsPerPage(value),
          isDisable: false,
          itemsPerPage: props.itemsPerPage,
          onPageClick: (value: number) =>
            props.actions.handleSelectedPage(value),
          selectedPage: props.selectedPage,
          paginationText: ComponentTextTitleEnum.FOOTER_PAGINATION_TITLE,
          textFieldSelector: ItemsPerPage,
          totalItems: get(props, "credentialsResponse.total", 0),
        }}
        header={{
          cells: header,
          headerProps: {
            isDisable: false,
          },
        }}
        rows={rows}
        isLoading={isLoading || isLoadingCredentials}
        showPagination
      />
    </Box>
  );
};
