import { environment } from "../../environments/environment";

export const API_ROUTES = {
  CREATE_PROCESSOR: `${environment.kushkiUrl}/international-transfer/v1/admin/processor`,
  DEFAULT_PROCESSOR: (merchantId: string) =>
    `${environment.kushkiUrl}/rules/v1/admin/merchant/${merchantId}`,
  GET_ALL_PROCESSOR: (merchantId: string) =>
    `${environment.kushkiUrl}/merchant/v1/admin/merchant/${merchantId}/processors`,
  GET_MOVIES: "https://kform.getsandbox.com:443/movies",
  GET_PROCESSOR: (processorId: string) =>
    `${environment.kushkiUrl}/international-transfer/v1/admin/processor/${processorId}`,
  HIERARCHY_GET_FIRST_PARENT: `${environment.kushkiUrl}/hierarchy/v1/node/first-parent`,
  HISTORICAL_REFUNDS: `${environment.kushkiUrl}/b2c-analytics/v1/refund-transaction-list`,
  NODE_INFO: `${environment.kushkiUrl}/billing-core-node/v1/node/info`,
  REDIRECT_URL: (merchantId: string) =>
    `/processing/?menuItem=true&publicMerchantId=${merchantId}`,
  UPDATE_PROCESSOR: (processorId: string) =>
    `${environment.kushkiUrl}/international-transfer/v1/admin/processor/${processorId}`,
};
