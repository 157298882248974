/**
 * CatalogsEnum
 */
export enum CatalogsEnum {
  BankAccountTypes = "sBankAccountTypes",
  IdTypes = "sIdTypes",
  Banks = "sBanks",
  CategoryCode = "sCategoryCodes",
  Provinces = "sProvinces",
  Departments = "sDepartments",
  Cities = "sCities",
  Currency = "sCurrency",
  Regime = "sRegime",
  Obligations = "sObligations",
  BillingCountry = "sBillingCountry",
  TaxRegime = "sTaxRegime",
  ConceptRetentionRent = "sConceptRetentionRent",
  TaxPayer = "sTaxPayer",
  Activity = "sActivity",
  DefaultIdTypesShareholder = "sDefaultIdTypesShareholder",
}
