import React from "react";
import { TableInfo } from "@kushki/connect-ui";
import { Box, Grid, Typography } from "@mui/material";
import { styles } from "./ChargesBlock.styles";
import { useChargesBlock } from "./state/useChargesBlock";
import { TextBlock } from "../commons/TextBlock/TextBlock";
import { MissingInfoAlert } from "../MissingInfoAlert/MissingInfoAlert";

const ChargesBlock = () => {
  const { table, isComplete, isLoading, chargesBlock } = useChargesBlock();

  return (
    <>
      {!isComplete ? (
        <MissingInfoAlert />
      ) : (
        <Box sx={styles.container}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <TextBlock
                title={chargesBlock.title}
                subtitle={chargesBlock.subtitle}
                isLoading={isLoading}
              />
            </Grid>
          </Grid>

          <Box>
            <Typography color="text.dark" variant="subtitle2">
              Cuentas bancarias
            </Typography>
            <Box
              sx={{
                overflowX: "auto",
              }}
            >
              <TableInfo
                isLoading={isLoading}
                header={{
                  cells: table.columns,
                  headerProps: {
                    isDisable: false,
                  },
                }}
                rows={table.rows}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ChargesBlock;
