/**
 * Country Enumeration
 */
export enum CountryEnum {
  mexico = "Mexico",
}

export const CountryNameEnum = {
  [CountryEnum.mexico]: "México",
};
