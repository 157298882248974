import { createNamedStyles } from "../../shared/interfaces/create_named_styles";
import { SxProps, Theme } from "@mui/material";
import { MenuItemNamesEnum } from "../../shared/infrastructure/MenuItemNamesEnum";

export const buttonCardStyles = createNamedStyles({
  tooltip: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
  },
  txtCard: {
    lineHeight: "144% !important",
    fontSize: "14px !important",
    fontFamily: " IBM Plex Sans !important",
    fontWeight: 400,
    wordWrap: "break-word",
    color: "#023365",
  },
});

export const buttonContainer = (isTextButton: boolean): SxProps<Theme> => {
  return {
    display: "flex",
    justifyContent: "center",
    padding: 0,
    height: (theme) =>
      isTextButton ? theme.spacing(7.25) : theme.spacing(15.25),
    ml: 1,
    width: (theme) => theme.spacing(15.5),
    p: 0,
  };
};

export const choiceRadio = (
  isTextButton: boolean,
  title: string = ""
): SxProps<Theme> => {
  return {
    display: () =>
      isTextButton || title === MenuItemNamesEnum.RESUME
        ? "none !important"
        : "flex",
    justifyContent: "end !important",
    width: "100%",
    padding: 0,
    transition: "all .2s ease-out",
    "&:hover": {
      background: "none",
    },
  };
};

export const choiceBox = (isTextButton: boolean): SxProps<Theme> => {
  return {
    height: () => (isTextButton ? "0%" : "100%"),
    justifyContent: "space-around",
  };
};

export const buttonCard = (
  isSelected?: boolean,
  isTextButton?: boolean
): SxProps<Theme> => {
  return {
    backgroundColor: "background.default",
    width: 1,
    height: 1,
    textAlign: "center",
    borderRadius: "8px",
    border: "1px solid",
    borderColor: () => (isSelected ? "primary.light2" : "white"),
    display: "flex !important",
    transition: "all .2s ease-out",
    p: (theme) =>
      isTextButton
        ? theme.spacing(2.5, 1.75, 2.5, 1.75)
        : theme.spacing(1, 1.75, 1.5, 1.75),
    m: "auto !important",
    filter:
      "drop-shadow(0px 8px 17px rgba(106, 167, 197, 0.15)) drop-shadow(0px 1.6px 2.7625px rgba(106, 167, 197, 0.075))",
    "&:hover:not(.Mui-disabled)": {
      boxShadow:
        "0px 8px 17px rgba(106, 167, 197, 0.15), 0px 1.6px 2.7625px rgba(106, 167, 197, 0.075)",
      border: "1px solid" + "primary.light2",
    },
    ".MuiTypography-body1.MuiFormControlLabel-label": {
      height: "100%",
    },
    "&.Mui-disabled": {
      filter:
        "drop-shadow(0px 8px 17px rgba(222, 239, 247, 0.15)) drop-shadow(0px 1.6px 2.7625px rgba(222, 239, 247, 0.075))",
      ".MuiTypography-root": {
        color: "#E2E8F0",
      },
    },
  };
};
