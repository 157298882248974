import { ModalBody } from "@kushki/frontend-molecules/modal-body";
import React, { Fragment } from "react";
import { TransactionTable } from "../../../types/transaction_table";
import PaperInfo, { IModalContainerInfo } from "../Common/PaperInfo/PaperInfo";
import {
  defaultTo,
  floor,
  get,
  hasIn,
  isEmpty,
  lowerCase,
  sum,
  toNumber,
} from "lodash";
import { format } from "date-fns";
import { KindEnum } from "../../shared/infrastructure/KindEnum";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import {
  Category,
  getCatalog,
} from "../../shared/infrastructure/constants/MerchantInformationConstants";
import { CatalogsEnum } from "../../shared/infrastructure/catalogs-enum";
import { MIN_AMOUNT_TYPE } from "../../shared/infrastructure/MinAmountTypeEnum";
import { TransactionTypeEnum } from "../../shared/infrastructure/TransactionTypeEnum";
import { ProcessorTypeEnum } from "../../shared/infrastructure/ProcessorTypeEnum";
import { Box, Grid, Typography } from "@material-ui/core";
import {
  LiquidationTypeEnum,
  TransactionTypeChipLabelEnum,
  TransactionTypeLowerCaseEnum,
} from "../../shared/infrastructure/BillingTransactionTypeEnum";
import { ModalBodyTrxEnum } from "../../shared/infrastructure/ModalBodyTrxEnum";
import ModalBodyTrxBillingCharge from "./ModalBodyTrxBilling/ModalBodyTrxCharge";
import { translate } from "../../shared/infrastructure/LanguageCatatog";
import {
  RowStatusEnum,
  StatusEnum,
  StatusFlowEnum,
} from "../../shared/infrastructure/StatusEnum";
import ModalBodyTrxBillingPeru from "./ModalBodyTrxBilling/ModalBodyTrxBillingPeru";
import { formatNumber } from "../../shared/utils/format-number";
import { InvoiceModeEnum } from "../../shared/infrastructure/InvoiceModeEnum";
import { validate3DS } from "../ModalHeaderBiling/ModalHeaderBilling";
import { DefaultMessage } from "../DefaultMessage/DefaultMessage";
import {
  getValueWithDefault,
  isOcbValidate,
  isOcbValidateOwnerCustomer,
} from "../../shared/utils/utils";
import { ValueStatusFinalEnum } from "../../shared/infrastructure/CatalogFinalStatus";
import { OcbEnum } from "../../shared/infrastructure/OcbEnum";
import { useSelector } from "react-redux";
import { IBillingDashboardState } from "../../store/reducer";
import { Data } from "../../../types/catalog_response";
import { getCatalogDataByCode } from "../../shared/utils/catalog";
import { CatalogsNameEnum } from "../../shared/infrastructure/enum/CatalogsNameEnum";
import { getIvaValue } from "../ModalBodyInfo/utilsModalBody";

export interface ITrxBilling {
  trx: TransactionTable | undefined;
  isMinAmountTrx: boolean;
  statusFlow?: string;
}

const formatDate: string = "dd MMM. yyyy";
const getValueTrx = (
  trx: TransactionTable | undefined,
  valueKey: string,
  hasCurrency?: boolean,
  round?: number
) => {
  const value: string | number = get(
    trx,
    `transaction.${valueKey}`,
    hasCurrency ? 0 : ""
  );
  return `${round ? toNumber(value).toFixed(2) : value} ${
    hasCurrency ? get(trx, "transaction.currency", "") : ""
  }`;
};
const getValueGenericTrx = (
  trx: TransactionTable | undefined,
  valueKey: string,
  hasCurrency?: boolean,
  round?: number
) => {
  const value: string | number = get(trx, `${valueKey}`, hasCurrency ? 0 : "");
  return `${round ? toNumber(value).toFixed(2) : value} ${
    hasCurrency ? get(trx, "transaction.currency", "") : ""
  }`;
};
const getTypeKind = (trx: TransactionTable | undefined, valueKey: string) => {
  const kind = get(trx, `transaction.${valueKey}`, "");

  switch (kind) {
    case KindEnum.CREDIT_NOTE:
      return "N. de Crédito";
    default:
      return "";
  }
};
const getGrossAmount = (trx: TransactionTable) =>
  getValueTrx(trx, "amount", true, 2);
const getTotalAmount = (trx: TransactionTable) => {
  const amount: number = get(trx, "amount", 0);
  const country: string = get(trx, "transaction.country", "");
  const ivaValue: number = getIvaValue(amount, country);
  const totalAmount: number =
    country === CountryEnum.colombia
      ? floor(Number((amount + ivaValue).toFixed(2)), 2)
      : Number((amount + ivaValue).toFixed(2));

  return `${totalAmount.toFixed(2)} ${get(trx, "transaction.currency", "")}`;
};

const getLiquidAmount = (trx: TransactionTable) => {
  const sumLiquid: number = sum([
    get(trx, "transaction.amount", 0),
    get(trx, "transaction.walletConfig.rechargeAmount", 0),
  ]);
  return `${toNumber(sumLiquid).toFixed(2)} ${get(
    trx,
    "transaction.currency",
    ""
  )}`;
};

export const getIva = (trx: TransactionTable): string => {
  const country: string = get(trx, "transaction.country", "");

  const ivaTotalAmount: number = getIvaValue(get(trx, "amount", 0), country);

  return `${ivaTotalAmount.toFixed(2)} ${get(trx, "transaction.currency", "")}`;
};
const getBillingTransactionType = (
  trx: TransactionTable | undefined,
  valueKey: string
) => {
  const TransactionType = get(trx, `transaction.${valueKey}`, "").toLowerCase();
  if (TransactionType.includes(TransactionTypeLowerCaseEnum.payout)) {
    return TransactionTypeChipLabelEnum.payout;
  } else if (TransactionType.includes(TransactionTypeLowerCaseEnum.payin)) {
    return TransactionTypeChipLabelEnum.payin;
  } else if (
    TransactionType.includes(TransactionTypeLowerCaseEnum.minimalCommission)
  ) {
    return TransactionTypeChipLabelEnum.minimalCommission;
  } else if (
    TransactionType.includes(TransactionTypeLowerCaseEnum.automaticCommision)
  ) {
    return TransactionTypeChipLabelEnum.automaticCommision;
  } else {
    return "";
  }
};

const isVoucher = (trx: TransactionTable | undefined) =>
  [KindEnum.VOUCHER].includes(get(trx, "transaction.kind", ""));
const isReceivable = (trx: TransactionTable | undefined) =>
  get(trx, "transaction.kind", "") === KindEnum.RECEIVABLE;

const isCharge = (trx: TransactionTable | undefined) =>
  get(trx, "transaction.kind", "") === KindEnum.CHARGE;

const isInvoice = (trx: TransactionTable | undefined) =>
  get(trx, "transaction.kind", "") === KindEnum.INVOICE;

const isDispersion = (trx: TransactionTable | undefined) =>
  get(trx, "transaction.kind", "") === KindEnum.DISPERSION;

const isWalletConfig = (trx: TransactionTable | undefined) =>
  !isEmpty(get(trx, "transaction.walletConfig", {}));

const isCreditNote = (trx: TransactionTable | undefined) =>
  get(trx, "transaction.kind", "") === KindEnum.CREDIT_NOTE;

const isDebitNote = (trx: TransactionTable | undefined) =>
  trx?.transaction?.kind === KindEnum.DEBIT_NOTE;

const getMinAmountType = (trx: TransactionTable | undefined) => {
  const chargeMin = get(trx, "transaction.chargeMin", "");

  if (!chargeMin) return "";

  return MIN_AMOUNT_TYPE[chargeMin];
};

const getValueNumberFormat = (
  props: Record<string, any>,
  tag: string
): string => {
  return `${formatNumber(
    defaultTo(get(props, `transaction.${tag}`), 0),
    get(props, "transaction.currency")
  )}`;
};

const getTotalTransactions = (
  props: Record<string, any> | undefined
): string => {
  if (get(props, "transaction.totalNumberTransactions", null) === null) {
    return get(props, "transaction.totalTransactions", "0");
  } else {
    return get(props, "transaction.totalNumberTransactions", "0");
  }
};
const getProcessorType = (trx: TransactionTable | undefined) =>
  get(trx, "transaction.processorType") === ProcessorTypeEnum.AGGREGATOR
    ? ModalBodyTrxEnum.AGGREGADOR
    : ModalBodyTrxEnum.GATEWAY;

const threeDSPaper = (props: Record<string, any> | undefined) => {
  return (
    <PaperInfo
      values={[
        {
          label: "Fecha creación:",
          value: format(
            get(props, "transaction.created", new Date()),
            formatDate
          ),
          xs: 4,
        },
        {
          label: "Monto bruto:",
          xs: 4,
          value: getValueTrx(props, "kushkiCommission", true, 2),
        },
        {
          label: "Monto IVA:",
          xs: 4,
          value: getIva(props!),
        },
        {
          label: "Monto total:",
          xs: 4,
          value: getTotalAmount(props!),
        },
        {
          label: "Total de transacciones:",
          xs: 4,
          value: getTotalTransactions(props),
        },
        {
          label: "Descripción:",
          value: getValueTrx(props, "description"),
          xs: 12,
        },
      ]}
    />
  );
};

const getCategory = (trx: TransactionTable | undefined) => {
  return get(trx, "transaction.processorType") ===
    ProcessorTypeEnum.AGGREGATOR ? (
    <Box display={"flex"} flexDirection={"column"} gridRowGap={24}>
      {get(trx, "transaction.totalByModel", []).map(
        (category: {
          procesorModel: string;
          totalTransactions: number;
          totalAmount: number;
        }) =>
          get(category, "totalTransactions", 0) > 0 &&
          get(category, "totalAmount", 0) > 0 && (
            <Grid item xs={12}>
              <Box display="flex">
                <Box width={"50%"}>
                  <Typography variant="body2">
                    {`${translate(get(category, "processorModel", ""))}:`}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection={"column"}>
                  <Typography variant="body2">
                    {`${get(category, "totalTransactions", 0)} transacciones`}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {toNumber(get(category, "totalAmount", 0)).toFixed(2)}{" "}
                    {get(trx, "transaction.currency")}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          )
      )}
    </Box>
  ) : (
    ModalBodyTrxEnum.GATEWAY
  );
};

export const getFromCatalog = (
  country: CountryEnum,
  catalog: CatalogsEnum,
  valueSearch: string,
  defaultValue?: string
): string => {
  if (country === CountryEnum.chile && catalog === CatalogsEnum.Banks) {
    return getChileBankValue(valueSearch);
  }

  return get(
    getCatalog(country.trim(), catalog).find((option: Category) => {
      return (
        option.value.toString().trim() === defaultTo(valueSearch, "").trim()
      );
    }),
    "name",
    defaultTo(defaultValue, "")
  );
};

const getHierarchyPanelInfo = (trx: TransactionTable | undefined) => {
  let info: IModalContainerInfo[] = [
    {
      label: "Owner ID:",
      value: isOcbValidateOwnerCustomer(
        get(trx, "transaction.isOcb", false),
        defaultTo(trx, {}),
        "transaction.ownerId",
        OcbEnum.KUSHKI_ID
      ),
      xs: 5,
    },
    {
      label: "Owner name:",
      xs: 4,
      value: isOcbValidateOwnerCustomer(
        get(trx, "transaction.isOcb", false),
        defaultTo(trx, {}),
        "transaction.ownerName",
        OcbEnum.KUSHKI
      ),
    },
    {
      label: "Customer ID:",
      xs: 5,
      value: isOcbValidateOwnerCustomer(
        get(trx, "transaction.isOcb", false),
        defaultTo(trx, {}),
        "transaction.customerId",
        get(trx, "transaction.merchantId", "")
      ),
    },
    {
      label: "Customer name:",
      xs: 4,
      value: isOcbValidateOwnerCustomer(
        get(trx, "transaction.isOcb", false),
        defaultTo(trx, {}),
        "transaction.customerName",
        get(trx, "transaction.merchantName", "")
      ),
    },
  ];

  if (get(trx, "transaction.isOcb", false))
    info.splice(2, 0, {
      label: "Jerarquía:",
      xs: 3,
      value: lowerCase(
        isOcbValidate(
          get(trx, "transaction.isOcb", ""),
          get(trx, "transaction.customerId", ""),
          get(trx, "transaction.merchantId", "")
        )
      ),
    });

  return info as IModalContainerInfo[];
};

function getChileBankValue(valueSearch: string): string {
  const { catalogsList }: IBillingDashboardState = useSelector(
    (state: IBillingDashboardState) => state
  );
  const chileBankData: Data = getCatalogDataByCode(
    catalogsList,
    CatalogsNameEnum.BANK_LIST_DISPERSION,
    valueSearch
  );
  return chileBankData.value;
}

export const ModalBodyTrxBilling = ({
  trx,
  isMinAmountTrx,
  statusFlow,
}: ITrxBilling) => {
  const GeneralInfo = (props: Record<string, any>) => {
    const minAmountType = {
      label: "Tipo",
      value: getMinAmountType(trx),
      xs: 12,
    };
    const valuesWithwalletConfig: IModalContainerInfo[] = [
      {
        label: "Valor a liquidar:",
        value: getLiquidAmount(props),
        xs: 4,
      },
      {
        label: "Valor de recarga:",
        value: getValueGenericTrx(
          props,
          "transaction.walletConfig.rechargeAmount",
          true,
          2
        ),
        xs: 4,
      },
      {
        label: "Liquidación final:",
        value: getGrossAmount(props),
        xs: 4,
      },
    ];
    const valuesInvoice: IModalContainerInfo[] = [
      {
        label: "Fecha creación:",
        value: format(
          get(props, "transaction.created", new Date()),
          formatDate
        ),
        xs: 4,
      },
      {
        label: "Monto bruto:",
        xs: 4,
        value: getGrossAmount(props),
      },
      {
        label: "Monto IVA:",
        xs: 4,
        value: getIva(props),
      },
      {
        label: "Monto total:",
        xs: 4,
        value: getTotalAmount(props),
      },
      {
        label: "Total transacciones:",
        xs: 4,
        value: getValueTrx(props, "totalNumberTransactions", false),
      },
      {
        label: "Descripción:",
        value: getValueTrx(props, "description"),
        xs: 12,
      },
    ];
    const transactionPanelInfo = [
      {
        label: "Fecha creación:",
        value: format(
          get(props, "transaction.created", new Date()),
          formatDate
        ),
        xs: 4,
      },
      {
        label: "Monto bruto:",
        xs: 4,
        value: getGrossAmount(props),
      },
      {
        label: "Monto IVA:",
        xs: 4,
        value: getIva(props),
      },
      {
        label: "Monto total:",
        xs: 4,
        value: getTotalAmount(props),
      },
      {
        label: "Total transacciones:",
        xs: 4,
        value: getValueTrx(props, "totalNumberTransactions", false),
      },
      {
        label: "Descripción:",
        value: getValueTrx(props, "description"),
        xs: 12,
      },
    ];
    const merchantPanelInfo: IModalContainerInfo[] = [
      {
        label: "Nombre del comercio:",
        value: getValueTrx(props, "merchantName"),
        xs: 4,
      },
      {
        label: "Tax ID:",
        value: getValueTrx(props, "taxId"),
        xs: 3,
      },
      {
        label: "Email:",
        value: getValueTrx(trx, "email"),
        xs: 5,
      },
      {
        label: "Dirección:",
        value: getValueTrx(trx, "address"),
        xs: 12,
      },
    ];
    const bankPanelInformation: IModalContainerInfo[] = [
      {
        label: "Banco:",
        value: getFromCatalog(
          get(props, "transaction.country", "") as CountryEnum,
          CatalogsEnum.Banks,
          get(props, "transaction.bankId"),
          ValueStatusFinalEnum.Guion
        ),
        xs: 4,
        xs2: 8,
      },
      {
        label: "Modelo: ",
        value: getProcessorType(props),
        xs: 4,
        xs2: 8,
      },
      {
        label: "Categoría: ",
        value: getCategory(props),
        xs: 4,
        xs2: 8,
      },
      {
        label: "Tipo de cuenta: ",
        value: getFromCatalog(
          getValueTrx(props, "country") as CountryEnum,
          CatalogsEnum.BankAccountTypes,
          get(props, "transaction.accountType"),
          ValueStatusFinalEnum.Guion
        ),
        xs: 4,
        xs2: 8,
      },
      {
        label: "N° de cuenta: ",
        value: getValueWithDefault(
          trx,
          "transaction.accountBank",
          ValueStatusFinalEnum.Guion
        ),
        xs: 4,
        xs2: 8,
      },
    ];
    const bankPanelInformationNoCategory: IModalContainerInfo[] = [
      {
        label: "Banco:",
        value: getFromCatalog(
          get(props, "transaction.country", "") as CountryEnum,
          CatalogsEnum.Banks,
          get(props, "transaction.bankId"),
          ValueStatusFinalEnum.Guion
        ),
        xs: 4,
        xs2: 8,
      },
      {
        label: "Modelo: ",
        value: getProcessorType(props),
        xs: 4,
        xs2: 8,
      },
      {
        label: "Tipo de cuenta: ",
        value: getFromCatalog(
          getValueTrx(props, "country") as CountryEnum,
          CatalogsEnum.BankAccountTypes,
          get(props, "transaction.accountType"),
          ValueStatusFinalEnum.Guion
        ),
        xs: 4,
        xs2: 8,
      },
      {
        label: "N° de cuenta: ",
        value: getValueWithDefault(
          trx,
          "transaction.accountBank",
          ValueStatusFinalEnum.Guion
        ),
        xs: 4,
        xs2: 8,
      },
    ];
    const merchantPanelInvoiceDispersion: IModalContainerInfo[] = [
      {
        label: "Nombre del comercios",
        value: getValueTrx(props, "merchantName"),
        xs: 4,
      },
      {
        label: "Tax ID:",
        value: getValueTrx(props, "taxId"),
        xs: 4,
      },
      {
        label: "Email",
        value: getValueTrx(trx, "email"),
        xs: 4,
      },
      {
        label: "Dirección:",
        value: getValueTrx(trx, "address"),
        xs: 12,
      },
    ];
    const bankPanelInformationNoModel: IModalContainerInfo[] = [
      {
        label: "Banco:",
        value: getFromCatalog(
          get(props, "transaction.country", "") as CountryEnum,
          CatalogsEnum.Banks,
          get(props, "transaction.bankId"),
          ValueStatusFinalEnum.Guion
        ),
        xs: 4,
        xs2: 8,
      },
      {
        label: "Tipo de cuenta: ",
        value: getFromCatalog(
          getValueTrx(props, "country") as CountryEnum,
          CatalogsEnum.BankAccountTypes,
          get(props, "transaction.accountType"),
          ValueStatusFinalEnum.Guion
        ),
        xs: 4,
        xs2: 8,
      },
      {
        label: "N° de cuenta: ",
        value: getValueWithDefault(
          trx,
          "transaction.accountBank",
          ValueStatusFinalEnum.Guion
        ),
        xs: 4,
        xs2: 8,
      },
    ];
    const invoicePanelInfo: IModalContainerInfo[] = [
      {
        label: "Fecha creación:",
        value: format(
          get(props, "transaction.created", new Date()),
          formatDate
        ),
        xs: 4,
      },
      {
        label: "Monto bruto:",
        xs: 4,
        value: getGrossAmount(props),
      },
      {
        label: "Monto IVA:",
        xs: 4,
        value: getIva(props),
      },
      {
        label: "Monto total:",
        xs: 4,
        value: getTotalAmount(props),
      },
      {
        label: "Total transacciones:",
        xs: 4,
        value: getValueTrx(props, "totalNumberTransactions", false),
      },
      {
        label: "Tipo:",
        xs: 4,
        value: getMinAmountType(props),
      },
      {
        label: "Descripción:",
        value: getValueTrx(props, "description"),
        xs: 12,
      },
    ];
    const dispersionPanelInfo: IModalContainerInfo[] = [
      {
        label: "Fecha de creación:",
        value: format(
          get(props, "transaction.created", new Date()),
          formatDate
        ),
        xs: 4,
      },
      {
        label: "Monto total:",
        value: getValueNumberFormat(props, "totalAmountTrx"),
        xs: 4,
      },
      {
        label: "IVA Comisión:",
        value: getValueNumberFormat(props, "ivaKushkiCommission"),
        xs: 4,
      },
      {
        label: "Fecha procesado:",
        value: format(
          new Date(get(props, "transaction.processingDate", new Date())),
          formatDate
        ),
        xs: 4,
      },
      {
        label: "Monto de transacciones:",
        value: getValueNumberFormat(props, "transacted"),
        xs: 4,
      },
      {
        label: "Retenciones por riesgo:",
        value: getValueNumberFormat(props, "amountRetention"),
        xs: 4,
      },
      {
        label: "Cantidad de transacciones:",
        value: defaultTo(get(props, "transaction.totalTransactions"), 0),
        xs: 4,
      },
      {
        label: "Retenciones liberadas:",
        value: getValueNumberFormat(props, "amountSumChargePending"),
        xs: 4,
      },
      {
        label: "Monto bruto:",
        value: getValueNumberFormat(props, "amount"),
        xs: 4,
      },
      {
        label: "Kushki comisión:",
        value: getValueNumberFormat(props, "kushkiCommission"),
        xs: 4,
      },
      {
        label: "Monto IVA TRX:",
        value: getIva(props),
        xs: 4,
      },
    ];
    const hierarchyPanelInfo: IModalContainerInfo[] = getHierarchyPanelInfo(
      trx
    );

    if (isMinAmountTrx) transactionPanelInfo.splice(3, 0, minAmountType);

    switch (get(trx, "transaction.country", "")) {
      case CountryEnum.colombia:
        return (
          <React.Fragment>
            {isCreditNote(trx) ? (
              <>
                <PaperInfo
                  values={[
                    {
                      label: "Monto total:",
                      xs: 4,
                      value: getTotalAmount(props),
                    },
                    {
                      label: "Fecha de creación:",
                      xs: 4,
                      value: format(
                        get(props, "transaction.created", new Date()),
                        formatDate
                      ),
                    },
                    {
                      label: "Tipo:",
                      xs: 4,
                      value: getTypeKind(props, "kind"),
                    },
                    {
                      label: "Cantidad de transacciones:",
                      xs: 4,
                      value: getValueTrx(props, "totalNumberTransactions"),
                    },
                    {
                      label: "Tipo de transacción:",
                      xs: 4,
                      value: getBillingTransactionType(
                        props,
                        "transactionType"
                      ),
                    },
                    {
                      label: "Motivo:",
                      value: getValueTrx(props, "evidence.reason"),
                      xs: 12,
                    },
                    {
                      label: "Descripción:",
                      value: getValueTrx(props, "description"),
                      xs: 12,
                    },
                  ]}
                />
                <PaperInfo
                  direction="row"
                  values={[...bankPanelInformationNoModel]}
                />
                <PaperInfo values={merchantPanelInfo} />
              </>
            ) : (
              ""
            )}
            {trx?.transaction?.kind === KindEnum.INVOICE &&
              ([
                TransactionTypeEnum.MINIMAL_COMMISSION_DEDUCTIBLE,
                TransactionTypeEnum.MINIMAL_COMMISSION_FIXED,
              ].includes(get(trx, "transaction.transactionType", "")) ? (
                <PaperInfo values={[...invoicePanelInfo]} />
              ) : validate3DS(
                  get(trx, "transaction.additionalServices", []),
                  get(trx, "transaction.hasAdditionalServices", false)
                ) ? (
                <>{threeDSPaper(props)}</>
              ) : (
                <PaperInfo values={valuesInvoice} />
              ))}
            {(trx?.transaction?.kind === KindEnum.INVOICE &&
            validate3DS(
              get(trx, "transaction.additionalServices", []),
              get(trx, "transaction.hasAdditionalServices", false)
            ) ? (
              <PaperInfo values={[...bankPanelInformation]} direction={"row"} />
            ) : (
              trx?.transaction?.kind === KindEnum.INVOICE ||
              trx?.transaction?.kind === KindEnum.DISPERSION ||
              trx?.transaction?.kind === KindEnum.RECEIVABLE
            )) &&
              (getProcessorType(props) === ModalBodyTrxEnum.AGGREGADOR &&
              validate3DS(
                get(trx, "transaction.additionalServices", []),
                get(trx, "transaction.hasAdditionalServices", false)
              ) ? (
                <PaperInfo
                  values={[...bankPanelInformation]}
                  direction={"row"}
                />
              ) : (
                <PaperInfo
                  values={
                    !isDispersion(trx) && !isReceivable(trx)
                      ? [...bankPanelInformationNoCategory]
                      : [...bankPanelInformationNoModel]
                  }
                  direction={"row"}
                />
              ))}
            {trx?.transaction?.kind === KindEnum.DISPERSION && (
              <PaperInfo
                values={
                  !isWalletConfig(trx)
                    ? [...dispersionPanelInfo]
                    : [...dispersionPanelInfo, ...valuesWithwalletConfig]
                }
                direction={"col"}
              />
            )}
            <PaperInfo values={hierarchyPanelInfo} />
            {trx?.transaction?.kind === KindEnum.RECEIVABLE && (
              <PaperInfo values={[...dispersionPanelInfo]} direction={"col"} />
            )}
            {(trx?.transaction?.kind === KindEnum.INVOICE ||
              trx?.transaction?.kind === KindEnum.DISPERSION ||
              trx?.transaction?.kind === KindEnum.RECEIVABLE ||
              (trx?.transaction?.kind === KindEnum.INVOICE &&
                validate3DS(
                  get(trx, "transaction.additionalServices", []),
                  get(trx, "transaction.hasAdditionalServices", false)
                ))) && <PaperInfo values={merchantPanelInvoiceDispersion} />}
          </React.Fragment>
        );
      case CountryEnum.ecuador:
        return (
          <React.Fragment>
            {isDispersion(trx) || isReceivable(trx) ? (
              <>
                <PaperInfo
                  values={[...bankPanelInformationNoModel]}
                  direction={"row"}
                />
                <PaperInfo values={dispersionPanelInfo} direction={"col"} />
              </>
            ) : isInvoice(trx) ? (
              [
                TransactionTypeEnum.MINIMAL_COMMISSION_DEDUCTIBLE,
                TransactionTypeEnum.MINIMAL_COMMISSION_FIXED,
              ].includes(get(trx, "transaction.transactionType", "")) ? (
                <>
                  <PaperInfo values={[...invoicePanelInfo]} />
                  {getProcessorType(props) === ModalBodyTrxEnum.AGGREGADOR ? (
                    <PaperInfo
                      values={[...bankPanelInformation]}
                      direction={"row"}
                    />
                  ) : (
                    <PaperInfo
                      values={[...bankPanelInformationNoCategory]}
                      direction={"row"}
                    />
                  )}
                </>
              ) : validate3DS(
                  get(trx, "transaction.additionalServices", []),
                  get(trx, "transaction.hasAdditionalServices", false)
                ) ? (
                <>
                  {threeDSPaper(props)}
                  {getProcessorType(props) === ModalBodyTrxEnum.AGGREGADOR ? (
                    <PaperInfo
                      values={[...bankPanelInformation]}
                      direction={"row"}
                    />
                  ) : (
                    <PaperInfo
                      values={[...bankPanelInformationNoCategory]}
                      direction={"row"}
                    />
                  )}
                </>
              ) : (
                <>
                  <PaperInfo values={[...invoicePanelInfo]} />
                  {getProcessorType(props) === ModalBodyTrxEnum.AGGREGADOR ? (
                    <PaperInfo
                      values={[...bankPanelInformation]}
                      direction={"row"}
                    />
                  ) : (
                    <PaperInfo
                      values={[...bankPanelInformationNoCategory]}
                      direction={"row"}
                    />
                  )}
                </>
              )
            ) : (
              <>
                <PaperInfo values={merchantPanelInfo} />
              </>
            )}
            <PaperInfo values={hierarchyPanelInfo} />
            <PaperInfo values={merchantPanelInfo} />
          </React.Fragment>
        );
      case CountryEnum.mexico:
        return (
          <React.Fragment>
            {isDispersion(trx) || isReceivable(trx) ? (
              <>
                <PaperInfo
                  values={[
                    ...bankPanelInformationNoModel,
                    {
                      label: "Nº de CCI: ",
                      value: getValueWithDefault(
                        trx,
                        "transaction.interbankAccountCode",
                        ValueStatusFinalEnum.Guion
                      ),
                      xs: 4,
                      xs2: 8,
                    },
                  ]}
                  direction={"row"}
                />
                <PaperInfo values={[...dispersionPanelInfo]} />
              </>
            ) : isInvoice(trx) ? (
              <>
                {[
                  TransactionTypeEnum.MINIMAL_COMMISSION_DEDUCTIBLE,
                  TransactionTypeEnum.MINIMAL_COMMISSION_FIXED,
                ].includes(get(trx, "transaction.transactionType", "")) ? (
                  <PaperInfo values={[...invoicePanelInfo]} />
                ) : validate3DS(
                    get(trx, "transaction.additionalServices", []),
                    get(trx, "transaction.hasAdditionalServices", false)
                  ) ? (
                  <>{threeDSPaper(props)}</>
                ) : get(trx, "transaction.invoiceMode", "") ===
                  InvoiceModeEnum.WITH_IVA ? (
                  <PaperInfo
                    values={[
                      {
                        label: "Fecha creación:",
                        value: format(
                          get(props, "transaction.created", new Date()),
                          formatDate
                        ),
                        xs: 4,
                      },
                      {
                        label: "Monto bruto:",
                        xs: 4,
                        value: getGrossAmount(props),
                      },
                      {
                        label: "Monto IVA:",
                        xs: 4,
                        value: getIva(props),
                      },
                      {
                        label: "Monto total:",
                        xs: 4,
                        value: getTotalAmount(props),
                      },
                      {
                        label: "Total transacciones:",
                        xs: 4,
                        value: getValueTrx(
                          props,
                          "totalNumberTransactions",
                          false
                        ),
                      },
                      {
                        label: "Descripción:",
                        value: getValueTrx(props, "description"),
                        xs: 12,
                      },
                    ]}
                  />
                ) : (
                  <PaperInfo
                    values={[
                      {
                        label: "Fecha creación:",
                        value: format(
                          get(props, "transaction.created", new Date()),
                          formatDate
                        ),
                        xs: 4,
                      },
                      {
                        label: "Monto total:",
                        xs: 4,
                        value: getGrossAmount(props),
                      },
                      {
                        label: "Total transacciones:",
                        xs: 4,
                        value: getValueTrx(
                          props,
                          "totalNumberTransactions",
                          false
                        ),
                      },
                      {
                        label: "Descripción:",
                        value: getValueTrx(props, "description"),
                        xs: 12,
                      },
                    ]}
                  />
                )}
                {getProcessorType(props) === ModalBodyTrxEnum.AGGREGADOR ? (
                  <PaperInfo
                    values={[
                      ...bankPanelInformation,
                      {
                        label: "N° de cuenta CCI: ",
                        value: getValueWithDefault(
                          trx,
                          "transaction.interbankAccountCode",
                          ValueStatusFinalEnum.Guion
                        ),
                        xs: 4,
                        xs2: 8,
                      },
                    ]}
                    direction={"row"}
                  />
                ) : (
                  <PaperInfo
                    values={[
                      ...bankPanelInformationNoCategory,
                      {
                        label: "N° de cuenta CCI: ",
                        value: getValueWithDefault(
                          trx,
                          "transaction.interbankAccountCode",
                          ValueStatusFinalEnum.Guion
                        ),
                        xs: 4,
                        xs2: 8,
                      },
                    ]}
                    direction={"row"}
                  />
                )}
              </>
            ) : (
              <>
                <PaperInfo values={merchantPanelInfo} />
              </>
            )}
            <PaperInfo values={hierarchyPanelInfo} />
            <PaperInfo values={merchantPanelInfo} />
          </React.Fragment>
        );
      case CountryEnum.peru:
        const wallet_panel = !isWalletConfig(trx)
          ? [...dispersionPanelInfo]
          : [...dispersionPanelInfo, ...valuesWithwalletConfig];

        return (
          <React.Fragment>
            {isDispersion(trx) ? (
              <>
                <PaperInfo
                  values={[
                    ...bankPanelInformationNoModel,
                    {
                      label: "Nº de CCI: ",
                      value: getValueWithDefault(
                        trx,
                        "transaction.interbankAccountCode",
                        ValueStatusFinalEnum.Guion
                      ),
                      xs: 4,
                      xs2: 8,
                    },
                  ]}
                  direction={"row"}
                />
                <PaperInfo values={[...wallet_panel]} />
              </>
            ) : isInvoice(trx) ? (
              <>
                {[
                  TransactionTypeEnum.MINIMAL_COMMISSION_DEDUCTIBLE,
                  TransactionTypeEnum.MINIMAL_COMMISSION_FIXED,
                ].includes(get(trx, "transaction.transactionType", "")) ? (
                  <PaperInfo values={[...invoicePanelInfo]} />
                ) : validate3DS(
                    get(trx, "transaction.additionalServices", []),
                    get(trx, "transaction.hasAdditionalServices", false)
                  ) ? (
                  <>{threeDSPaper(props)}</>
                ) : (
                  <PaperInfo
                    values={[
                      {
                        label: "Fecha creación:",
                        value: format(
                          get(props, "transaction.created", new Date()),
                          formatDate
                        ),
                        xs: 4,
                      },
                      {
                        label: "Monto bruto:",
                        xs: 4,
                        value: getGrossAmount(props),
                      },
                      {
                        label: "Monto IVA:",
                        xs: 4,
                        value: getIva(props),
                      },
                      {
                        label: "Monto total:",
                        xs: 4,
                        value: getTotalAmount(props),
                      },
                      {
                        label: "Total transacciones:",
                        xs: 4,
                        value: getValueTrx(
                          props,
                          "totalNumberTransactions",
                          false
                        ),
                      },
                      {
                        label: "Descripción:",
                        value: getValueTrx(props, "description"),
                        xs: 12,
                      },
                    ]}
                  />
                )}
                {getProcessorType(props) === ModalBodyTrxEnum.AGGREGADOR ? (
                  <PaperInfo
                    values={[
                      ...bankPanelInformation,
                      {
                        label: "Nº de CCI: ",
                        value: getValueWithDefault(
                          trx,
                          "transaction.interbankAccountCode",
                          ValueStatusFinalEnum.Guion
                        ),
                        xs: 4,
                        xs2: 8,
                      },
                    ]}
                    direction={"row"}
                  />
                ) : (
                  <PaperInfo
                    values={[
                      ...bankPanelInformationNoCategory,
                      {
                        label: "Nº de CCI: ",
                        value: getValueWithDefault(
                          trx,
                          "transaction.interbankAccountCode",
                          ValueStatusFinalEnum.Guion
                        ),
                        xs: 4,
                        xs2: 8,
                      },
                    ]}
                    direction={"row"}
                  />
                )}
              </>
            ) : isReceivable(trx) ? (
              <>
                <PaperInfo
                  values={[
                    ...bankPanelInformationNoModel,
                    {
                      label: "Nº de CCI: ",
                      value: getValueWithDefault(
                        trx,
                        "transaction.interbankAccountCode",
                        ValueStatusFinalEnum.Guion
                      ),
                      xs: 4,
                      xs2: 8,
                    },
                  ]}
                  direction={"row"}
                />
                <PaperInfo values={[...dispersionPanelInfo]} />
              </>
            ) : isCreditNote(trx) ? (
              <>
                <PaperInfo
                  values={[
                    {
                      label: "Monto total:",
                      xs: 4,
                      value: getTotalAmount(props),
                    },
                    {
                      label: "Fecha de creación:",
                      xs: 4,
                      value: format(
                        get(props, "transaction.created", new Date()),
                        formatDate
                      ),
                    },
                    {
                      label: "Tipo:",
                      xs: 4,
                      value: getTypeKind(props, "kind"),
                    },
                    {
                      label: "Cantidad de transacciones:",
                      xs: 4,
                      value: getValueTrx(props, "totalNumberTransactions"),
                    },
                    {
                      label: "Tipo de transacción:",
                      xs: 4,
                      value: getBillingTransactionType(
                        props,
                        "transactionType"
                      ),
                    },
                    {
                      label: "Motivo:",
                      value: getValueTrx(props, "evidence.reason"),
                      xs: 12,
                    },
                    {
                      label: "Descripción:",
                      value: getValueTrx(props, "description"),
                      xs: 12,
                    },
                  ]}
                />
                <PaperInfo
                  direction="row"
                  values={[
                    ...bankPanelInformationNoModel,
                    {
                      label: "Nº de CCI: ",
                      value: getValueWithDefault(
                        trx,
                        "transaction.interbankAccountCode",
                        ValueStatusFinalEnum.Guion
                      ),
                      xs: 4,
                      xs2: 8,
                    },
                  ]}
                />
              </>
            ) : (
              <>
                <PaperInfo values={merchantPanelInfo} />
              </>
            )}
            <PaperInfo values={hierarchyPanelInfo} />
            <PaperInfo values={merchantPanelInfo} />
          </React.Fragment>
        );
      case CountryEnum.chile:
        dispersionPanelInfo.push({
          label: "Tipo de liquidación:",
          value: get(trx, "transaction.isExclusive")
            ? LiquidationTypeEnum.EXCLUSIVA
            : LiquidationTypeEnum.NORMAL,
          xs: 4,
        });
        return (
          <React.Fragment>
            {isDispersion(trx) || isReceivable(trx) ? (
              <>
                <PaperInfo
                  values={[...bankPanelInformationNoModel]}
                  direction={"row"}
                />
                <PaperInfo values={[...dispersionPanelInfo]} />
              </>
            ) : isInvoice(trx) ? (
              <>
                {[
                  TransactionTypeEnum.MINIMAL_COMMISSION_DEDUCTIBLE,
                  TransactionTypeEnum.MINIMAL_COMMISSION_FIXED,
                ].includes(get(trx, "transaction.transactionType", "")) ? (
                  <PaperInfo values={[...invoicePanelInfo]} />
                ) : validate3DS(
                    get(trx, "transaction.additionalServices", []),
                    get(trx, "transaction.hasAdditionalServices", false)
                  ) ? (
                  <>{threeDSPaper(props)}</>
                ) : (
                  <PaperInfo
                    values={[
                      {
                        label: "Fecha creación:",
                        value: format(
                          get(props, "transaction.created", new Date()),
                          formatDate
                        ),
                        xs: 4,
                      },
                      {
                        label: "Monto bruto:",
                        xs: 4,
                        value: getGrossAmount(props),
                      },
                      {
                        label: "Monto IVA:",
                        xs: 4,
                        value: getIva(props),
                      },
                      {
                        label: "Monto total:",
                        xs: 4,
                        value: getTotalAmount(props),
                      },
                      {
                        label: "Total transacciones:",
                        xs: 4,
                        value: getValueTrx(
                          props,
                          "totalNumberTransactions",
                          false
                        ),
                      },
                      {
                        label: "Descripción:",
                        value: getValueTrx(props, "description"),
                        xs: 12,
                      },
                    ]}
                  />
                )}

                {getProcessorType(props) === ModalBodyTrxEnum.AGGREGADOR ? (
                  <PaperInfo
                    values={[...bankPanelInformation]}
                    direction={"row"}
                  />
                ) : (
                  <PaperInfo
                    values={[...bankPanelInformationNoCategory]}
                    direction={"row"}
                  />
                )}
              </>
            ) : isCharge(trx) ? (
              <>
                <PaperInfo
                  values={[
                    {
                      label: "Fecha creación:",
                      value: format(
                        get(props, "transaction.created", new Date()),
                        formatDate
                      ),
                      xs: 4,
                    },
                    {
                      label: "Monto total:",
                      xs: 4,
                      value: getTotalAmount(props),
                    },
                    {
                      label: "Descripción:",
                      value: getValueTrx(props, "description"),
                      xs: 12,
                    },
                  ]}
                />
                <PaperInfo
                  direction="row"
                  values={[
                    {
                      label: "Item:",
                      value: "Comisión de transacción",
                      xs: 6,
                      xs2: 6,
                    },
                    {
                      label: "Banco:",
                      value: getFromCatalog(
                        get(trx, "transaction.country", "") as CountryEnum,
                        CatalogsEnum.Banks,
                        get(trx, "transaction.bankId"),
                        ValueStatusFinalEnum.Guion
                      ),
                      xs: 6,
                      xs2: 6,
                    },
                    {
                      label: "Tipo de cuenta:",
                      value: getFromCatalog(
                        getValueTrx(trx, "country") as CountryEnum,
                        CatalogsEnum.BankAccountTypes,
                        get(trx, "transaction.accountType"),
                        ValueStatusFinalEnum.Guion
                      ),
                      xs: 6,
                      xs2: 6,
                    },
                    {
                      label: "N° de cuenta:",
                      value: getValueWithDefault(
                        trx,
                        "transaction.accountBank",
                        ValueStatusFinalEnum.Guion
                      ),
                      xs: 6,
                      xs2: 6,
                    },
                  ]}
                />
              </>
            ) : isCreditNote(trx) ? (
              <>
                <PaperInfo
                  values={[
                    {
                      label: "Monto total:",
                      xs: 4,
                      value: getTotalAmount(props),
                    },
                    {
                      label: "Tipo:",
                      xs: 4,
                      value: getTypeKind(props, "kind"),
                    },
                    {
                      label: "Cantidad de transacciones:",
                      xs: 4,
                      value: getValueTrx(props, "totalNumberTransactions"),
                    },
                    {
                      label: "Tipo de transacción:",
                      xs: 4,
                      value: getBillingTransactionType(
                        props,
                        "transactionType"
                      ),
                    },
                    {
                      label: "Descripción:",
                      value: getValueTrx(props, "description"),
                      xs: 12,
                    },
                  ]}
                />
                <PaperInfo
                  direction="row"
                  values={[
                    {
                      label: "Banco:",
                      value: getFromCatalog(
                        get(trx, "transaction.country", "") as CountryEnum,
                        CatalogsEnum.Banks,
                        get(trx, "bankId"),
                        ValueStatusFinalEnum.Guion
                      ),
                      xs: 6,
                      xs2: 6,
                    },
                    {
                      label: "Tipo de cuenta:",
                      value: getFromCatalog(
                        getValueTrx(trx, "country") as CountryEnum,
                        CatalogsEnum.BankAccountTypes,
                        get(trx, "transaction.accountType"),
                        ValueStatusFinalEnum.Guion
                      ),
                      xs: 6,
                      xs2: 6,
                    },
                  ]}
                />
              </>
            ) : (
              <>
                <PaperInfo values={merchantPanelInfo} />
              </>
            )}
            <PaperInfo values={hierarchyPanelInfo} />
            <PaperInfo values={merchantPanelInfo} />
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            {trx?.transaction?.transactionType ===
            TransactionTypeEnum.MINIMAL_COMISSION ? (
              <PaperInfo values={[...invoicePanelInfo]} />
            ) : (
              <PaperInfo values={dispersionPanelInfo} />
            )}
            {getProcessorType(props) === ModalBodyTrxEnum.AGGREGADOR ? (
              <PaperInfo
                values={[
                  ...bankPanelInformation,
                  {
                    label: "Nº de CCI: ",
                    value: getValueWithDefault(
                      trx,
                      "transaction.interbankAccountCode",
                      ValueStatusFinalEnum.Guion
                    ),
                    xs: 4,
                    xs2: 8,
                  },
                ]}
                direction={"row"}
              />
            ) : (
              <PaperInfo
                values={[
                  ...bankPanelInformationNoCategory,
                  {
                    label: "Nº de CCI: ",
                    value: getValueWithDefault(
                      trx,
                      "transaction.interbankAccountCode",
                      ValueStatusFinalEnum.Guion
                    ),
                    xs: 4,
                    xs2: 8,
                  },
                ]}
                direction={"row"}
              />
            )}
            <PaperInfo values={merchantPanelInfo} />
          </React.Fragment>
        );
    }
  };

  const country: CountryEnum = get(
    trx,
    "transaction.country",
    ""
  ) as CountryEnum;

  return (
    <ModalBody>
      {[CountryEnum.peru, CountryEnum.colombia].includes(country) &&
        get(trx, "transaction.kind", "") === KindEnum.CREDIT_NOTE && (
          <GeneralInfo {...trx} />
        )}
      {isDebitNote(trx) &&
        [CountryEnum.colombia, CountryEnum.peru].includes(country) && (
          <React.Fragment>
            <PaperInfo
              values={[
                {
                  label: "Referencia:",
                  value: getValueTrx(trx, "invoiceNumber"),
                  xs: 4,
                },
                {
                  label: "Fecha creación:",
                  value: format(get(trx, "created", new Date()), formatDate),
                  xs: 4,
                },
                {
                  label: "Monto total:",
                  value: getValueTrx(trx, "totalAmountTrx", true, 2),
                  xs: 4,
                },
                {
                  label: "Forma de cobro:",
                  value:
                    getValueTrx(trx, "status") === StatusEnum.COMPLETED
                      ? "Automático"
                      : "Manual",
                  xs: 4,
                },
                {
                  label: "Item:",
                  value: "Comisión de transacción",
                  xs: 4,
                },
                {
                  label: "Descripción:",
                  value: getValueTrx(trx, "description"),
                  xs: 12,
                },
              ]}
            />
            <PaperInfo
              direction="row"
              values={[
                {
                  label: "Banco:",
                  value: getFromCatalog(
                    country,
                    CatalogsEnum.Banks,
                    get(trx, "transaction.bankId"),
                    ValueStatusFinalEnum.Guion
                  ),
                  xs: 6,
                  xs2: 6,
                },
                {
                  label: "Tipo de cuenta:",
                  value: getFromCatalog(
                    getValueTrx(trx, "country") as CountryEnum,
                    CatalogsEnum.BankAccountTypes,
                    get(trx, "transaction.accountType"),
                    ValueStatusFinalEnum.Guion
                  ),
                  xs: 6,
                  xs2: 6,
                },
                {
                  label: "N° de cuenta:",
                  value: getValueWithDefault(
                    trx,
                    "transaction.accountBank",
                    ValueStatusFinalEnum.Guion
                  ),
                  xs: 6,
                  xs2: 6,
                },
                ...(country === CountryEnum.peru
                  ? [
                      {
                        label: "Nº de CCI:",
                        value: getValueWithDefault(
                          trx,
                          "transaction.interbankAccountCode",
                          ValueStatusFinalEnum.Guion
                        ),
                        xs: 6,
                        xs2: 6,
                      },
                    ]
                  : []),
              ]}
            />
            <PaperInfo
              values={[
                {
                  label: "Nombre del comercio",
                  value: getValueTrx(trx, "merchantName"),
                  xs: 4,
                },
                {
                  label: "Tax ID:",
                  value: getValueTrx(trx, "taxId"),
                  xs: 3,
                },
                {
                  label: "Email",
                  value: getValueTrx(trx, "email"),
                  xs: 5,
                },
                {
                  label: "Dirección:",
                  value: getValueTrx(trx, "address"),
                  xs: 12,
                },
              ]}
            />
          </React.Fragment>
        )}
      {trx?.transaction?.kind === KindEnum.RECEIVABLE && (
        <GeneralInfo {...trx} />
      )}
      {get(trx, "transaction.kind", "") === KindEnum.DISPERSION && (
        <Fragment>
          {hasIn(trx, "transaction.modify.reprocess.comments") &&
            statusFlow === StatusFlowEnum.EXECUTOR &&
            RowStatusEnum.Reprocess ==
              get(trx, "transaction.statusRow", "") && (
              <DefaultMessage
                message={get(trx, "transaction.modify.reprocess.comments", "")}
              />
            )}
          <GeneralInfo {...trx} />
        </Fragment>
      )}
      {isInvoice(trx) ? (
        <Fragment>
          {hasIn(trx, "transaction.modify.reprocess.comments") &&
            statusFlow === StatusFlowEnum.EXECUTOR &&
            RowStatusEnum.Reprocess ==
              get(trx, "transaction.statusRow", "") && (
              <DefaultMessage
                message={get(trx, "transaction.modify.reprocess.comments", "")}
              />
            )}
          <GeneralInfo {...trx} />
        </Fragment>
      ) : isVoucher(trx) ? (
        <ModalBodyTrxBillingPeru
          isHistoric={false}
          trxInfo={trx}
          isMinAmount={false}
          country={country}
        />
      ) : (
        isCharge(trx) && (
          <Fragment>
            {hasIn(trx, "transaction.modify.reprocess.comments") &&
              statusFlow === StatusFlowEnum.EXECUTOR &&
              RowStatusEnum.Reprocess ==
                get(trx, "transaction.statusRow", "") && (
                <DefaultMessage
                  message={get(
                    trx,
                    "transaction.modify.reprocess.comments",
                    ""
                  )}
                />
              )}
            <ModalBodyTrxBillingCharge
              trxInfo={trx}
              country=""
              isMinAmount={isMinAmountTrx}
              isHistoric={false}
            />
          </Fragment>
        )
      )}
    </ModalBody>
  );
};
