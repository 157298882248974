import React from "react";
import { Chip } from "@material-ui/core";
import { get } from "lodash";
import { IChipProps } from "../../shared/infrastructure/CatalogFinalStatus";
import { useStatusFinalStyles } from "./ChipStatusStyles";

export interface ChipStatusProps {
  statusInfo: IChipProps;
}

export const ChipStatus: React.FC<ChipStatusProps> = (
  props: React.PropsWithChildren<ChipStatusProps>
) => {
  const classes = useStatusFinalStyles(props);

  return (
    <Chip
      id={"chip-status"}
      className={classes.chipStateStyle}
      label={get(props, "statusInfo.value", "")}
      size="small"
    />
  );
};
