import React from "react";
import { TextEnum } from "../../shared/constants/textEnum";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import { RedirectObject } from "../../shared/constants/processorConstants";
import { processorDialogComponentStyles } from "./ProcessorDialogComponent.styles";
import { get } from "lodash";

export interface ProcessorDialogProps {
  open: boolean;
  onClose: (url: string) => void;
  redirectObject: RedirectObject;
}

export const ProcessorDialogComponent: React.FC<ProcessorDialogProps> = (
  props: ProcessorDialogProps
) => {
  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <DialogContent sx={processorDialogComponentStyles.content}>
        <Box mb={2}>
          <Grid container direction="row">
            <Grid>
              <Box mb={2}>
                <Typography sx={processorDialogComponentStyles.title}>
                  {TextEnum.processorSuccessfullySaved}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={processorDialogComponentStyles.actions}>
        <Button
          onClick={() =>
            props.onClose(get(props, "redirectObject.redirectPath", ""))
          }
          sx={processorDialogComponentStyles.actionButton}
        >
          {props.redirectObject.redirectButtonLabel}
        </Button>
        <Button
          sx={processorDialogComponentStyles.buttonPrincipal}
          onClick={() =>
            props.onClose(
              get(props, "redirectObject.redirectPathAddProcessor", "")
            )
          }
          variant="contained"
        >
          {props.redirectObject.redirectAddProcessorLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
