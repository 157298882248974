import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const customerHeaderStyles = createNamedStyles({
  divider: {
    border: "none",
  },
  select: {
    width: "310px",
    mb: "1rem",
  },
  selectWrapper: {
    width: "100%",
  },
  createCustomerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    mt: "3rem",
  },
  tooltipWrapper: {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
  },
  mainBox: {
    height: 25,
    paddingBottom: 10,
    paddingTop: 5,
    width: "100%",
  },
  tabContainer: {
    button: {
      flexBasis: 0,
      flexGrow: 1,
      flexShrink: 1,
      maxWidth: "none",
    },
    "button:hover": {
      color: "#6D7781 !important",
      borderColor: "#B4B9BD !important",
    },
  },
});
