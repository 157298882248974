import { useHistory } from "react-router-dom";
import { BalanceResponse } from "../../../../types/balance_response";
import { PayoutMerchantResponse } from "../../../../types/payout_merchant_response";
import { CountryNameEnum } from "../../../shared/infrastructure/enums/CountryEnum";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { defaultTo, get } from "lodash";
import { useEffect } from "react";
import {
  getBalanceValues,
  getMerchantSettings,
} from "../../../store/actionCreators";

export interface IBalanceDescription {
  balanceResponse: BalanceResponse;
  country: CountryNameEnum;
  handleCheckoutV2: () => void;
  merchantPayout: PayoutMerchantResponse;
}
export const useBalanceDescription = (): IBalanceDescription => {
  const merchantData = defaultTo(
    JSON.parse(`${window.localStorage.getItem("merchantBasicInformation")}`),
    {}
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const { balanceResponse, merchantPayout } = useSelector(
    (state: IAppState) => state
  );
  const merchantId = get(
    merchantData,
    "publicMerchantId",
    localStorage.getItem("merchantId") as string
  );
  const country = get(merchantData, "country", "") as CountryNameEnum;

  const handleCheckoutV2 = () => {
    history.push("/../checkout-v2");
  };

  useEffect(() => {
    dispatch(getBalanceValues(merchantId));
    dispatch(getMerchantSettings(merchantId));
  }, []);

  return {
    balanceResponse,
    country,
    handleCheckoutV2,
    merchantPayout,
  };
};
