import { makeStyles } from "@material-ui/core/styles";

export const processedDispersionsHeaderStyle = makeStyles({
  mainGrid: {
    backgroundColor: "#F7FAFC",
    height: "65px",
    paddingLeft: "12px",
    paddingRight: "12px",
    borderRadius: "5px 5px 0px 0px",
  },
  picker: {
    display: "flex",
    marginRight: "140px",
  },
  searchBox: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
});
