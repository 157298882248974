import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import {
  editLegalRepresentative,
  setEdited,
  setLegalRepresentative,
  setOpenModal,
} from "../../../../../store/reducers/legalDetails/legalDetails";
import {
  ILegalRepresentative,
  PersonCategoryEnum,
} from "../../../../../store/interfaces/LegalDetailsState.interfaces";
import { physicalPersonRules } from "../../../../../shared/constants/form_labels_legal_details";
import { get } from "lodash";
import { CountryEnum } from "../../../../../shared/countryEnum";
import { useEffect, useState } from "react";
import {
  DOCUMENT_TYPE_BY_COUNTRY_VALUES,
  isAvailableCountry,
} from "../../../../../shared/services/CatalogDocumentTypeByCountry";
import { IMultipleFormValues } from "../../../../../shared/constants/initial_state_legal_details";
import { v4 } from "uuid";

export const usePhysicalPersonForm = () => {
  const { isEdited, legalRepresentative, merchantNodeInfo, nodeInfo } =
    useSelector((state: RootState) => ({
      ...state.legalDetails,
      ...state.initialData,
    }));

  const [listDocTypesByCountry, setListDocTypesByCountry] = useState<
    IMultipleFormValues[]
  >([]);
  const [isFirstEdit, setIsFirstEdit] = useState(true);
  const defaultValuesForm = () => {
    if (isEdited.value) {
      return legalRepresentative.find((item) => item.id === isEdited.id);
    }

    return {
      lastName: get(merchantNodeInfo, "naturalPerson.lastname"),
      name: get(merchantNodeInfo, "naturalPerson.name"),
    };
  };

  const dispatch = useDispatch();

  const form = useForm<FieldValues>({
    defaultValues: defaultValuesForm(),
  });

  const closeModal = () => {
    dispatch(setOpenModal(false));
    dispatch(setEdited({ id: "", value: false }));
  };

  const onSubmit: SubmitHandler<FieldValues> = (data: FieldValues) => {
    let cellphoneSlpitted = data.cellphoneNumber.split(" ");
    const currentRepresentative: ILegalRepresentative | undefined =
      legalRepresentative.find(
        (legalRepresentative) => legalRepresentative.id === data.id
      );

    data.countryCode = cellphoneSlpitted[0];
    data.isShareHolder = false;
    data.personType = "01";
    data.personCategory = PersonCategoryEnum.REPRESENTATIVE;
    data.isMain =
      currentRepresentative?.isMain ?? legalRepresentative.length === 0;
    if (data.cellphoneNumber.startsWith("52")) {
      data.areaCode = cellphoneSlpitted[1];
    } else {
      data.areaCode = "";
    }

    if (isEdited.value) {
      dispatch(setEdited({ id: "", value: false }));
      dispatch(setOpenModal(false));

      dispatch(editLegalRepresentative(data as ILegalRepresentative));
    } else {
      data.id = v4();
      dispatch(setLegalRepresentative(data as ILegalRepresentative));
      dispatch(setOpenModal(false));
    }
  };

  const documentTypeWatch = form.watch("documentType");
  const personNationalityCountryWatch = form.watch("nationality");
  const countryWatch = form.watch("country");
  const rulesDocumentNumber =
    documentTypeWatch === "0"
      ? physicalPersonRules.documentNumber
      : physicalPersonRules.documentNumberNumeric;
  const country: CountryEnum = get(
    nodeInfo,
    "generalInfo.country",
    CountryEnum.generic
  );
  const constitutionalCountry: CountryEnum = get(
    nodeInfo,
    "generalInfo.constitutionalCountry",
    CountryEnum.generic
  );

  useEffect(() => {
    isEdited.value && isFirstEdit
      ? form.setValue("documentType", documentTypeWatch)
      : form.setValue("documentType", "");
    isAvailableCountry(personNationalityCountryWatch)
      ? setListDocTypesByCountry(
          DOCUMENT_TYPE_BY_COUNTRY_VALUES[personNationalityCountryWatch]
        )
      : setListDocTypesByCountry(
          DOCUMENT_TYPE_BY_COUNTRY_VALUES[CountryEnum.generic]
        );
    setIsFirstEdit(false);
  }, [personNationalityCountryWatch]);

  return {
    closeModal,
    form,
    isEdited,
    legalRepresentative,
    onSubmit,
    values: {
      constitutionalCountry,
      country,
      countryWatch,
      listDocTypesByCountry,
      rulesDocumentNumber,
    },
  };
};
