import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import { BREADCRUMBS_PAGES } from "../../shared/infrastructure/constants/breadcrumbs_pages";
import { useKshRedux } from "../../store/useKshRedux/useKshRedux";
import CustomBreadcrumbs from "../commons/CustomBreadcrumbs/CustomBreadcrumbs";
import { headerPageStyles } from "./HeaderPage.styles";
import InfoHelper from "./InfoHelper/InfoHelper";

const HeaderPage: React.FC = () => {
  const {
    actions: { setValueTab },
    store: {
      app: { valueTab },
    },
  } = useKshRedux();

  return (
    <Grid data-testid="headerPage" container sx={headerPageStyles.main}>
      <Grid item md={8}>
        <Grid item md={12} xs={12}>
          <CustomBreadcrumbs routes={BREADCRUMBS_PAGES} />
        </Grid>
        <Grid item md={8}>
          <Typography data-testid="title" sx={headerPageStyles.title}>
            Control de Reembolsos
          </Typography>
        </Grid>
      </Grid>
      <InfoHelper />
      <Grid item md={12}>
        <Box sx={headerPageStyles.box}>
          <Tabs
            data-testid="tabs"
            value={valueTab}
            variant="fullWidth"
            onChange={(_, newValue) => setValueTab(newValue)}
            sx={headerPageStyles.tabs}
          >
            <Tab
              label="Registro de aprobación"
              value="1"
              sx={headerPageStyles.tab}
            />
            <Tab
              data-testid="tabHistorical"
              label="Histórico"
              value="2"
              sx={headerPageStyles.tab}
            />
          </Tabs>
        </Box>
      </Grid>
    </Grid>
  );
};

export default HeaderPage;
