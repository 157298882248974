import { isEmpty } from "lodash";
import { get } from "lodash";
import { Rule } from "../../../types/get_payouts_rules_response";

const isValidAlias = (
  value: string,
  getRuleById: Rule | undefined,
  rules: Rule[] | undefined
): boolean => {
  if (!isEmpty(rules)) {
    if (getRuleById && value === get(getRuleById, "name", "")) {
      return true;
    }
    const existAlias = rules!.some((rule) => rule.name === value);

    return !existAlias;
  }

  return true;
};

export const validateAlias = (
  value: string,
  getRuleById: Rule | undefined,
  rules: Rule[] | undefined
): boolean => isValidAlias(value, getRuleById, rules);
