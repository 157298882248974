import React, { useEffect, useState } from "react";
import { Box, MenuItem } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { get, isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store/reducer";
import { CountryEnum } from "../../../../../shared/infrastructure/catalogs/CountryEnum";
import { centralcounties } from "../../../../../shared/utils";

export interface ICustomMenuItemProps {
  keyName: string;
  navigateTo: string;
  menuTitle: string;
  menuIcon: string;
  customNavigateFn?: () => void;
  country?: string | undefined;
}

export const CustomMenuItem: React.FC<ICustomMenuItemProps> = (
  props: React.PropsWithChildren<ICustomMenuItemProps>
) => {
  const [enabledList, setEnabledList] = useState([]);
  const countryConfigProfile = useSelector((state: IAppState) =>
    get(state, "appConfigPaymentMethods", [])
  );

  useEffect(() => {
    if (!isEmpty(countryConfigProfile)) {
      setEnabledList(countryConfigProfile[0]);
    }
  }, [countryConfigProfile]);

  const showItem: boolean = ((country: string, payName: string) => {
    if (centralcounties.includes(country)) {
      country = CountryEnum.CENTRO_AMERICA;
    }
    return !(enabledList[country] || []).includes(payName);
  })(get(props, "country", ""), props.keyName);

  return (
    <>
      {props.customNavigateFn
        ? !showItem && (
            <MenuItem key={props.keyName} onClick={props.customNavigateFn}>
              <Box display="flex" flexDirection="row">
                <Box style={{ marginRight: 10 }}>
                  <img alt={props.keyName} src={props.menuIcon} />
                </Box>
                <Box>{props.menuTitle}</Box>
              </Box>
            </MenuItem>
          )
        : !showItem && (
            <MenuItem
              key={props.keyName}
              component={RouterLink}
              to={props.navigateTo}
            >
              <Box display="flex" flexDirection="row">
                <Box style={{ marginRight: 10 }}>
                  <img alt={props.keyName} src={props.menuIcon} />
                </Box>
                <Box>{props.menuTitle}</Box>
              </Box>
            </MenuItem>
          )}
    </>
  );
};
