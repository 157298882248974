import { IUsePayoutsDispersionIndexState } from "../PayoutsDispersionIndex";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { setSelectedIndexDispersionTab } from "../../../store/actionCreators";
import { defaultTo } from "lodash";
import { DispersionTabEnum } from "../../../shared/infrastructure/constants/DispersionTabEnum";

export const usePayoutsDispersionIndexState =
  (): IUsePayoutsDispersionIndexState => {
    const value: number = useSelector((state: IAppState) =>
      defaultTo(
        state.selectedIndexDispersionTab,
        DispersionTabEnum.REQUEST_DISPERSION_INDEX
      )
    );

    const dispatch = useDispatch();

    const handleSetValue = (value: number) => {
      dispatch(setSelectedIndexDispersionTab(value));
    };

    return {
      value,
      handleSetValue,
    };
  };
