import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { IconCircleWarn, IconPlus } from "@kushki/connect-ui";
import { IConditionsComponentProps } from "../../shared/interfaces/conditions_component_props";
import { ConditionsComponentStyles } from "./ConditionsComponent.styles";
import { ClassNameMap } from "@mui/styles";
import { LabelEnum } from "../../shared/constants/labels/rule_labels";
import { ConditionItemComponent } from "./components/ConditionItemComponent/ConditionItemComponent";

export const ConditionsComponent = (props: IConditionsComponentProps) => {
  const classes: ClassNameMap = ConditionsComponentStyles();

  return (
    <Grid item xs={12} className={classes.paddingTop32}>
      <Card elevation={2} className={classes.rootCard}>
        <CardContent className={classes.cardContent}>
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Typography variant="h3" color="text.primary">
                {LabelEnum.CONDITIONS_TITLE}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.paddingTop20}>
              <Box sx={{ paddingLeft: "34px" }}>
                <Button
                  startIcon={
                    <IconPlus className={"buttonLink"} fontSize="medium" />
                  }
                  className={"buttonLink"}
                  color="primary"
                  onClick={() => props.actions.handleAddCondition()}
                >
                  {LabelEnum.ADD_CONDITION}
                </Button>
              </Box>
            </Grid>
            {props.fields.map(
              (
                item: Partial<Record<string, never> & Record<"id", string>>,
                index: number
              ) => (
                <Grid
                  key={item.id}
                  item
                  xs={12}
                  className={classes.paddingTop20}
                >
                  <ConditionItemComponent
                    handleRemoveCondition={props.actions.handleRemoveCondition}
                    index={index}
                    key={item.id}
                    item={item}
                    fieldsLength={props.fields.length}
                  />
                </Grid>
              )
            )}
            <Grid item xs={12} className={classes.paddingTop16}>
              <Typography variant="body2" color="text.grey">
                <IconCircleWarn fontSize="medium" />
                {` ${LabelEnum.DESCRIPTION_ENROLLMENT}`}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
