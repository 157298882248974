import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import { IFieldValueDetailCard } from "@components/atom/FieldValueDetailCard/FieldValueDetailCard.interfaces";
import { fieldValueDetailCardStyles as styles } from "@components/atom/FieldValueDetailCard/FieldValueDetailCard.styles";
const FieldValueDetailCard: FC<IFieldValueDetailCard> = ({
  data,
}: IFieldValueDetailCard) => {
  return (
    <Box sx={styles.informationContainer}>
      <Box sx={styles.information}>
        <Typography variant={"caption"} sx={styles.textLight}>
          {data.label}
        </Typography>
        <Typography variant={"caption"} sx={styles.textValues}>
          {data.value}
        </Typography>
      </Box>
    </Box>
  );
};

export default FieldValueDetailCard;
