import React from "react";
import { Grid, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    marginBottom: 25,
  },
  typography: {
    fontWeight: 300,
  },
  typographyMobile: {
    fontWeight: 200,
  },
  countryFlag: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 5,
  },
  mediumTitle: {
    fontSize: 14,
    color: theme.palette.primary.dark,
    fontWeight: 500,
  },
  normalTitle: {
    fontSize: 14,
    color: theme.palette.primary.dark,
  },
  copyIcon: {
    marginLeft: 10,
    color: "#CBD5E0",
  },
}));

export interface IConfigHeaderProps {
  title: string;
  isMobile: boolean;
}

export const Header: React.FC<IConfigHeaderProps> = (
  props: IConfigHeaderProps
) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid className={classes.grid} container spacing={1}>
        <Grid
          item
          xs={6}
          md={6}
          container
          alignItems="center"
          justify="flex-start"
          direction="row"
        >
          <Typography
            className={
              props.isMobile ? classes.typographyMobile : classes.typography
            }
            color={"primary"}
            variant={props.isMobile ? "h3" : "h1"}
          >
            {props.title}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
