import { Grid, Typography } from "@mui/material";
import React from "react";
import { styles } from "./InformationRow.styles";
import { InformationRowProps } from "./InformationRow.interfaces";

const InformationRow = ({ label, value }: InformationRowProps) => {
  return (
    <Grid container sx={styles.container}>
      <Grid item xs={6}>
        <Typography sx={styles.text}>{label}: </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={styles.value}>{value}</Typography>
      </Grid>
    </Grid>
  );
};

export default InformationRow;
