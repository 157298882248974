import { ICellCheckProps } from "./CellCheck.interfaces";
import { useTableContext } from "../../Table/TableContext";
import { pushOrReplaceObjectInValueCells } from "../../Table/TableContext/tableUtils";
import { useEffect, useMemo, useState } from "react";
import { IValueCell } from "../CellHeader/interfaces";
import { get } from "lodash";

interface IUseCellRow {
  handler: {
    handleChange: () => void;
  };
  isLoading: boolean;
  value: boolean;
}

const useCellCheck = ({
  onChecked,
  id,
  isChecked,
  rowIndex,
  defaultValue,
}: ICellCheckProps): IUseCellRow => {
  const {
    state: { isLoading, valueCells },
    handler: { handleSetCellValues },
  } = useTableContext();

  const [value, setValue] = useState(isChecked);

  const updateStore = (value: boolean) => {
    handleSetCellValues(
      pushOrReplaceObjectInValueCells(valueCells, {
        error: false,
        id: `${rowIndex}-table-check`,
        rowIndex,
        value,
      })
    );
  };

  const handleChange = () => {
    updateStore(!isChecked);
    setValue(!isChecked);
    onChecked(id, isChecked);
  };

  const valueStore: IValueCell | undefined = useMemo(
    () =>
      valueCells.find(
        (cell: IValueCell) => cell.id === `${rowIndex}-table-check`
      ),

    []
  );

  useEffect(() => {
    if (valueStore) {
      setValue(!!get(valueStore, "value", isChecked));
      isChecked = !!get(valueStore, "value", isChecked);
    } else {
      updateStore(!!defaultValue);
      setValue(!!defaultValue);
    }
  }, [valueStore, defaultValue]);

  return {
    handler: {
      handleChange,
    },
    isLoading,
    value,
  };
};

export default useCellCheck;
