import woocommerce from "../../../assets/images/plugins/woocommerce.svg";
import prestashop from "../../../assets/images/plugins/prestashop.svg";
import magento from "../../../assets/images/plugins/magento.png";
import shopify from "../../../assets/images/plugins/shopify.svg";
import magentov2 from "../../../assets/images/plugins/magentov2.svg";
import vtex from "../../../assets/images/plugins/vtex.svg";

export const pluginsImg: { [key: string]: string } = {
  magento,
  prestashop,
  shopify,
  woocommerce,
  magentov2,
  vtex,
};
