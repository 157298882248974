import React from "react";
import { MenuItem, TextField } from "@mui/material";
import { ISelectOption } from "@components/InputsForm/Inputs/InputSelect/InputSelect.interfaces";
import { ISelectNodeInputProps } from "@components/SelectNodeInput/SelectNodeInput.interfaces";
import { selectNodeInputStyles as styles } from "@components/SelectNodeInput/SelectNodeInput.styles";
import { SecurityWrapperRoles } from "@shared/enum/SecurityWrapperRoles";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
const SelectNodeInput: React.FC<ISelectNodeInputProps> = ({
  handleChangeNode,
  value,
  options,
}: ISelectNodeInputProps) => {
  return (
    <ComponentSecurityWrapper
      componentId={SecurityWrapperRoles.M_COMPLIANCE_SELECT_NODE}
    >
      <TextField
        sx={styles.select}
        value={value}
        select
        onChange={(e) => handleChangeNode(e.target.value)}
      >
        {options.map((opt: ISelectOption) => {
          return (
            <MenuItem key={opt.label} value={opt.value}>
              {opt.label}
            </MenuItem>
          );
        })}
      </TextField>
    </ComponentSecurityWrapper>
  );
};

export default SelectNodeInput;
