import React from "react";
import { FileUploadExecutivesStyles as styles } from "@components/organism/FileUploadExecutives/FileUploadExecutives.styles";
import { Box, IconButton, Typography } from "@mui/material";
import { IconCircleCross, IconFileText } from "@kushki/connect-ui";
import { IFileNameExecutivesProps } from "@components/organism/FileUploadExecutives/FileUploadExecutives.interfaces";

const FileName: React.FC<IFileNameExecutivesProps> = ({
  name,
  size,
  handleCloseFile,
}: IFileNameExecutivesProps) => {
  const numberParsed = size <= 1 ? size.toFixed(2) : size.toFixed(1);

  return (
    <Box sx={styles.fileUploadContainer}>
      <Box sx={styles.nameContainer}>
        <IconFileText color={"disabled"} />
        <Box sx={styles.name}>
          <Typography
            variant="h6"
            sx={styles.nameText}
          >{`${name} (${numberParsed}MB)`}</Typography>
          <IconButton sx={styles.crossIcon} onClick={handleCloseFile}>
            <IconCircleCross color={"primary"} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default FileName;
