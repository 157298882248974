import { IStyles } from "../../shared/Interfaces/Styles.interfaces";

export const legalRepresentativesFormStyles: IStyles = {
  boxForm: {
    bgcolor: "white",
    borderRadius: 0.5,
    boxShadow:
      "0px 1px 4px rgba(2, 51, 101, 0.08), 0px 0px 0px rgba(14, 30, 46, 0.1), 0px 1px 2px rgba(14, 30, 46, 0.2)",
    p: 3,
  },
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: 4,
  },
  title: {
    color: "primary.main",
    fontWeight: 500,
    fontSize: 20,
  },
  subtitle: {
    color: "#6D7781",
    fontWeight: 400,
    fontSize: 15,
  },
};
