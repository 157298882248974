import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { MerchantResponse } from "../../../../types/merchant_response";
import { useMediaQuery } from "@material-ui/core";
import { get } from "lodash";

export interface IUseAccountPreferencesSection {
  loading?: boolean;
  publicMerchantId: string;
  isMobile?: boolean;
  email: string;
  colorPrimary: string;
  colorSecondary: string;
  phoneNumber: string;
}

export const useAccountPreferences = (): IUseAccountPreferencesSection => {
  const loading: boolean = useSelector((state: IAppState) => !!state.isLoading);
  const customization: MerchantResponse = useSelector(
    (state: IAppState) => state.customization!
  );
  const publicMerchantId: string = useSelector(
    (state: IAppState) => state.merchant?.publicMerchantId!
  );
  const isMobile: boolean = useMediaQuery("(max-width:767px)");

  const email = get(customization, "contact.email", "-");
  const colorPrimary = get(customization, "colors.primary", "-");
  const colorSecondary = get(customization, "colors.secondary", "-");
  const phoneNumber = get(customization, "contact.phoneNumber", "-");
  return {
    publicMerchantId,
    email,
    colorPrimary,
    colorSecondary,
    loading,
    isMobile,
    phoneNumber,
  };
};
