export enum RetentionTypeCO {
  city = "sCity",
  activityId = "sActivityId",
  model = "sModel",
}

export enum MunicipalityCodeTypeEnum {
  M01 = "11001",
  M02 = "76001",
  M03 = "08001",
  M04 = "68001",
  M05 = "66001",
  M06 = "05001",
}
export const MUNICIPALITY = [
  MunicipalityCodeTypeEnum.M01,
  MunicipalityCodeTypeEnum.M02,
  MunicipalityCodeTypeEnum.M03,
  MunicipalityCodeTypeEnum.M04,
  MunicipalityCodeTypeEnum.M05,
  MunicipalityCodeTypeEnum.M06,
];

export enum ActivityEconomicCodeTypeEnum {
  ACO01 = "ACO01",
  ACO02 = "ACO02",
  ACO03 = "ACO03",
  ACO04 = "ACO04",
  ACO05 = "ACO05",
  ACO06 = "ACO06",
  ACO07 = "ACO07",
  ACO08 = "ACO08",
  ACO09 = "ACO09",
  ACO10 = "ACO10",
  ACO11 = "ACO11",
  ACO12 = "ACO12",
}

export const ACTIVITYECONOMIC = [
  ActivityEconomicCodeTypeEnum.ACO01,
  ActivityEconomicCodeTypeEnum.ACO02,
  ActivityEconomicCodeTypeEnum.ACO03,
  ActivityEconomicCodeTypeEnum.ACO04,
  ActivityEconomicCodeTypeEnum.ACO05,
  ActivityEconomicCodeTypeEnum.ACO06,
  ActivityEconomicCodeTypeEnum.ACO07,
  ActivityEconomicCodeTypeEnum.ACO08,
  ActivityEconomicCodeTypeEnum.ACO09,
  ActivityEconomicCodeTypeEnum.ACO10,
  ActivityEconomicCodeTypeEnum.ACO11,
  ActivityEconomicCodeTypeEnum.ACO12,
];

export enum ModelCodeEnum {
  MO01 = "1",
  MO05 = "5",
}

export const MODEL = [ModelCodeEnum.MO01, ModelCodeEnum.MO05];

export const RetentionCatalogCO: Record<string, object> = {
  [RetentionTypeCO.city]: {
    [MunicipalityCodeTypeEnum.M01]: "Bogotá",
    [MunicipalityCodeTypeEnum.M02]: "Cali",
    [MunicipalityCodeTypeEnum.M03]: "Barranquilla",
    [MunicipalityCodeTypeEnum.M04]: "Bucaramanga",
    [MunicipalityCodeTypeEnum.M05]: "Pereira",
    [MunicipalityCodeTypeEnum.M06]: "Medellin",
  },
  [RetentionTypeCO.activityId]: {
    [ActivityEconomicCodeTypeEnum.ACO01]: "Actividades financieras.",
    [ActivityEconomicCodeTypeEnum.ACO02]: "Demás actividades comerciales.",
    [ActivityEconomicCodeTypeEnum.ACO03]: "Demás actividades de servicios.",
    [ActivityEconomicCodeTypeEnum.ACO04]: "Demás actividades industriales.",
    [ActivityEconomicCodeTypeEnum.ACO05]: "Edición de libros.",
    [ActivityEconomicCodeTypeEnum.ACO06]:
      "Fabricación de productos primarios de hierro y acero; fabricación de material de transporte.",
    [ActivityEconomicCodeTypeEnum.ACO07]:
      "Producción de alimentos, excepto bebidas; producción de calzado y prendas de vestir.",
    [ActivityEconomicCodeTypeEnum.ACO08]:
      "Servicio de restaurante, cafetería, grill, discoteca y similares; servicios de hotel, motel, hospedaje, amoblado y similares; servicio de casas de empeño y servicios de vigilancia.",
    [ActivityEconomicCodeTypeEnum.ACO09]:
      "Transporte, publicación de revistas, libros y periódicos, radiodifusión y programación de televisión.",
    [ActivityEconomicCodeTypeEnum.ACO10]:
      "Venta de alimentos y productos agrícolas en bruto; venta de textos escolares y libros (incluye cuadernos escolares); venta de drogas y medicamentos.",
    [ActivityEconomicCodeTypeEnum.ACO11]:
      "Venta de cigarrillos y licores; venta de combustibles derivados del petróleo y venta de joyas.",
    [ActivityEconomicCodeTypeEnum.ACO12]:
      "Venta de maderas y materiales para construcción; venta de automotores (incluidas motocicletas).",
  },
  [RetentionTypeCO.model]: {
    [ModelCodeEnum.MO01]: "Agregador - Formal",
    [ModelCodeEnum.MO05]: "Agregador - Pasarela",
  },
};

export const ActivityEconomicCol = [
  { value: "ACO01", name: "Actividades financieras." },
  { value: "ACO02", name: "Demás actividades comerciales." },
  { value: "ACO03", name: "Demás actividades de servicios." },
  { value: "ACO04", name: "Demás actividades industriales." },
  { value: "ACO05", name: "Edición de libros." },
  {
    value: "ACO06",
    name:
      "Fabricación de productos primarios de hierro y acero; fabricación de material de transporte.",
  },
  {
    value: "ACO07",
    name:
      "Producción de alimentos, excepto bebidas; producción de calzado y prendas de vestir.",
  },
  {
    value: "ACO08",
    name:
      "Servicio de restaurante, cafetería, grill, discoteca y similares; servicios de hotel, motel, hospedaje, amoblado y similares; servicio de casas de empeño y servicios de vigilancia.",
  },
  {
    value: "ACO09",
    name:
      "Transporte, publicación de revistas, libros y periódicos, radiodifusión y programación de televisión.",
  },
  {
    value: "ACO10",
    name:
      "Venta de alimentos y productos agrícolas en bruto; venta de textos escolares y libros (incluye cuadernos escolares); venta de drogas y medicamentos.",
  },
  {
    value: "ACO11",
    name:
      "Venta de cigarrillos y licores; venta de combustibles derivados del petróleo y venta de joyas.",
  },
  {
    value: "ACO12",
    name:
      "Venta de maderas y materiales para construcción; venta de automotores (incluidas motocicletas).",
  },
];
