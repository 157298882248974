import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

export const sectionInfoComponentStyles = createNamedStyles({
  principal:{
    marginBottom:"32px"
  },
  sectionTitleComponent:{
    marginRight:"40px"
  },
  sectionDesc:{
    marginRight:"40px",
    color:"#677784"
  },
});
