import React, { FC } from "react";
import { Box, Container } from "@material-ui/core";
import Title from "../../components/Title/Title";
import TableHeader from "../../components/TableHeader/TableHeader";
import {
  BUTTON_COMPONENTS_TEXT,
  EMPTY_COMPONENT_TABLE_SUBTITLE,
  EMPTY_COMPONENTS_TABLE_TITLE,
  TABLE_COMPONENTS_HEADER_TITLE,
} from "../../shared/constants/labels/modules_container_labels";
import SkeletonTable from "../../components/SkeletonTable/SkeletonTable";
import EmptyTable from "../../components/EmptyTable/EmptyTable";
import TableComponent from "../../components/TableComponent/TableComponent";
import { StatusEnum } from "../../shared/enums/statusEnum";
import TableEndOptions from "../../components/TableEndOptions/TableEndOptions";
import { useComponentsContainer } from "./state/useComponentsContainer";
import { GetComponentsResponse } from "../../../types/get_components_response";
import PaginationComponent from "../../components/PaginationComponent/PaginationComponent";
import { HEADERS_COMPONENTS } from "../../shared/constants/components_container_constants";
import SnackBarAlertComponent from "../../components/SnackBarAlertComponent/SnackBarAlertComponent";
import { FormProvider } from "react-hook-form";
import { ModalDialog } from "../../components/ModalDialog/ModalDialog";
import { TypeEnum } from "../../shared/enums/typeEnum";

const ComponentsContainer: FC = () => {
  const {
    form,
    handleChangePage,
    handleChangePageSize,
    handleChangeSwitch,
    handleClickEditButton,
    handleClickAddButton,
    modalDialogProps,
    isLoadingGetComponents,
    components,
    limit,
    page,
    total,
  } = useComponentsContainer();

  const endOptions = (row: GetComponentsResponse) => (
    <TableEndOptions<GetComponentsResponse>
      checkedCondition={(value) => value.status === StatusEnum.ENABLED}
      handleChangeSwitch={handleChangeSwitch}
      handleClickEditButton={handleClickEditButton}
      row={row}
      isModule={false}
    />
  );

  return (
    <>
      <Container maxWidth={false}>
        <Title />
        <TableHeader
          title={TABLE_COMPONENTS_HEADER_TITLE}
          buttonText={BUTTON_COMPONENTS_TEXT}
          handleButtonClick={handleClickAddButton}
          isModule={false}
        />
        <Box pt={3}>
          {isLoadingGetComponents ? (
            <SkeletonTable columns={5} rows={6} />
          ) : components.length === 0 ? (
            <EmptyTable
              title={EMPTY_COMPONENTS_TABLE_TITLE}
              subtitle={EMPTY_COMPONENT_TABLE_SUBTITLE}
            />
          ) : (
            <>
              <TableComponent<GetComponentsResponse>
                data={components}
                headers={HEADERS_COMPONENTS}
                endOptions={endOptions}
              />
              <PaginationComponent
                page={page}
                limit={limit}
                total={total}
                handleChangePage={handleChangePage}
                handleChangePageSize={handleChangePageSize}
              />
            </>
          )}
        </Box>
        <FormProvider {...form}>
          <ModalDialog
            handleClose={modalDialogProps.handlerCloseModalDialog}
            handleCreateOrEdit={modalDialogProps.handlerCreateOrEdit}
            isNew={modalDialogProps.isNew}
            open={modalDialogProps.openModalDialog}
            handlerDeleteRoles={modalDialogProps.handlerDeleteRoles}
            type={TypeEnum.COMPONENT}
          />
        </FormProvider>
      </Container>
      <SnackBarAlertComponent rootStoreKey={"components"} />
    </>
  );
};

export default ComponentsContainer;
