import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { auth } from "../../../shared/auth";
import { mapPaymentMethods } from "../../../shared/infrastructure/constants/payment-method-enum";
import { formatNumber } from "../../../shared/format-number";

export interface IContactDetail {
  document_type: string;
  document_number: string;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
}

export interface IPayoutTransactionInfo {
  currencyCode: string;
  merchantId: string;
  merchantName: string;
  paymentMethod: string;
  amount: number;
  description: string;
  contactDetails?: IContactDetail;
  pin: number;
}

const useStyles = makeStyles(() => ({
  scroll: {
    marginBottom: "20px",
  },
  container: {
    paddingBottom: "20px !important",
    marginTop: "20px !important",
    background: "#F7FAFC",
    borderRadius: "8px",
  },
  item: {
    paddingTop: "20px",
  },
  subtitle: {
    color: "#6D7781",
    fontSize: "14px !important",
  },
  title: {
    paddingTop: "4px",
    fontSize: "14px !important",
    color: "#293036",
  },
}));

export const CashDetail: React.FC<IPayoutTransactionInfo> = (
  props: IPayoutTransactionInfo
) => {
  const isBackOfficeAdmin: boolean = auth.isAdmin();
  const classes = useStyles();
  const {
    contactDetails,
    currencyCode,
    description,
    merchantId,
    merchantName,
    paymentMethod,
    pin,
    amount,
  } = props;
  const { height, width, icon, paddingBottom, label } =
    mapPaymentMethods(paymentMethod);

  return (
    <div className={classes.scroll}>
      <Container className={classes.container}>
        <Grid container>
          <Grid
            item
            xs={8}
            className={classes.item}
            hidden={!isBackOfficeAdmin}
          >
            <Typography variant="body2" className={classes.subtitle}>
              Comercio:
            </Typography>
            <Typography variant="h6" className={classes.title}>
              {merchantName}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.item}
            hidden={!isBackOfficeAdmin}
          >
            <Typography variant="body2" className={classes.subtitle}>
              Merchant ID:
            </Typography>
            <Typography variant="h6" className={classes.title}>
              {merchantId}
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.item}>
            <Typography variant="body2" className={classes.subtitle}>
              Medio de pago:
            </Typography>
            <Typography variant="h6" className={classes.title}>
              <img
                height={height}
                width={width}
                style={{
                  paddingBottom: paddingBottom + "px !important",
                }}
                src={icon}
              />{" "}
              {label}
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.item}>
            <Typography variant="body2" className={classes.subtitle}>
              Total:
            </Typography>
            <Typography variant="h6" className={classes.title}>
              {formatNumber(amount.toString())} {currencyCode}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.item}
            hidden={!isBackOfficeAdmin}
          >
            <Typography variant="body2" className={classes.subtitle}>
              Pin de pago:
            </Typography>
            <Typography variant="h6" className={classes.title}>
              {pin}
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.item}>
            <Typography variant="body2" className={classes.subtitle}>
              Descripción:
            </Typography>
            <Typography variant="h6" className={classes.title}>
              {description}
            </Typography>
          </Grid>
        </Grid>
      </Container>
      {contactDetails && (
        <Container className={classes.container}>
          <Grid container>
            <Grid item xs={4} className={classes.item}>
              <Typography variant="body2" className={classes.subtitle}>
                Cliente:
              </Typography>
              <Typography variant="h6" className={classes.title}>
                {contactDetails.first_name} {contactDetails.last_name}
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.item}>
              <Typography variant="body2" className={classes.subtitle}>
                Tipo identificación:
              </Typography>
              <Typography variant="h6" className={classes.title}>
                {contactDetails.document_type}
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.item}>
              <Typography variant="body2" className={classes.subtitle}>
                Nro identificación:
              </Typography>
              <Typography variant="h6" className={classes.title}>
                {contactDetails.document_number}
              </Typography>
            </Grid>
            <Grid item xs={8} className={classes.item}>
              <Typography variant="body2" className={classes.subtitle}>
                Email:
              </Typography>
              <Typography variant="h6" className={classes.title}>
                {contactDetails.email}
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.item}>
              <Typography variant="body2" className={classes.subtitle}>
                Nro Teléfono:
              </Typography>
              <Typography variant="h6" className={classes.title}>
                {contactDetails.phone_number}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      )}
    </div>
  );
};
