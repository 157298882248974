import { SubTypeEnum } from "../../../shared/infrastructure/enums/CreateRuleConstans";
import { useFormContext } from "react-hook-form";
import { RuleDetail } from "../../../../types/rule_detail";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountries, setRuleDetails } from "../../../store/actionCreators";
import { defaultTo, get, isEmpty, isUndefined, size, isEqual } from "lodash";
import { Nodes } from "../../../../types/merchant_hierarchy_response";
import { Country } from "../../../../types/country_response";
import { IAppState } from "../../../store/reducer";
import { IUseCreateRuleSectionState } from "../../../shared/infrastructure/interfaces/IUseCreateRuleSectionState";
import { ConfigurationResponse } from "../../../../types/configuration_response";
import { GetSecurityRulesByTypeResponse } from "../../../../types/get_security_rules_by_type_response";
import {
  CONDITIONS_CONFIG_BY_COMMERCE,
  Values,
} from "../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { GetWhiteListByCountryResponse } from "../../../../types/get_whitle_list_by_country_response";
import {
  GetMerchantResponse,
  MerchantProperties,
} from "../../../../types/get_merchant_response";
import { TableValueEnum } from "../../../shared/infrastructure/enums/DataTableEnum";
import { ConfigurationTypeEnum } from "../../../shared/infrastructure/enums/ConfigurationTypeEnum";
import { RequestTypeEnum } from "../../../shared/infrastructure/enums/RequestTypeEnum";
import { StatusEnum } from "../../../shared/infrastructure/enums/StatusEnum";
import { isFormEditMode } from "../../../utils/utilsFile";
import { useParams } from "react-router-dom";
import { IUseParams } from "../../../shared/infrastructure/interfaces/IUseParams";
import { FieldModelEnum } from "../../../shared/infrastructure/enums/ModalEnum";

export const useCreateRuleSectionState = (): IUseCreateRuleSectionState => {
  const dispatch = useDispatch();
  const { ruleId, ruleName } = useParams<IUseParams>();
  const { watch, setValue } = useFormContext();
  const whitelistConfiguration: ConfigurationResponse | undefined = useSelector(
    (state: IAppState) => state.configuration
  );

  const countries: string[] | undefined = watch("countries");
  const isUpdatedRule: boolean | undefined = watch("isUpdatedRule");
  const service: string | undefined = watch("service");
  const subType: string | undefined = watch("subType");
  const commerceType: string | undefined = watch("commerceType");
  const withoutHierarchy = watch("withoutHierarchy");
  const hierarchy = watch("hierarchy");
  const [nodes, setNodes] = useState<Nodes | undefined>(undefined);
  const [entityName, setEntityName] = useState<string>("");
  const [nodeId, setNodeId] = useState<string>("");
  const [rootId, setRootId] = useState<string>("");
  const [merchantId, setMerchantId] = useState<string>("");
  const [merchantName, setMerchantName] = useState<string>("");
  const [isItemSelectedWithHierarchy, setIsItemSelectedWithHierarchy] =
    useState<boolean>(false);
  const [isItemSelectedWithoutHierarchy, setIsItemSelectedWithoutHierarchy] =
    useState<boolean>(false);
  const [isItemCountrySelected, setIsItemCountrySelected] =
    useState<boolean>(false);
  const [commerceConditions, setCommerceConditions] = useState<string[]>([]);
  const [countryConditions, setCountryConditions] =
    useState<GetWhiteListByCountryResponse>([]);
  const [
    isRenderConfiguredConditionsComponent,
    setIsRenderConfiguredConditionsComponent,
  ] = useState<boolean>(false);

  const countries_array: Country[] | undefined = useSelector(
    (state: IAppState) => state.countries
  );
  const merchant_info: GetMerchantResponse | undefined = useSelector(
    (state: IAppState) => state.merchantInformation
  );
  const ruleDetails: RuleDetail | undefined = useSelector(
    (state: IAppState) => state.ruleDetails
  );
  const securityRuleByType: GetSecurityRulesByTypeResponse | undefined =
    useSelector((state: IAppState) => state.securityRuleByType);

  const whiteListByCountry: GetWhiteListByCountryResponse | undefined =
    useSelector((state: IAppState) => state.whiteListByCountry);

  const showControllerField = (action: string): boolean => {
    return action === SubTypeEnum.COUNTRY;
  };

  const showCommerceType = (action: string): boolean => {
    return action === SubTypeEnum.COMMERCE_WITH_HIERARCHY;
  };

  const setNodesChange = (node: Nodes | undefined): void => {
    setNodes(node);
  };

  const setDetails = (node: Nodes | undefined): void => {
    setEntityName(get(node, "entityName", ""));
    setNodeId(get(node, "nodeId", ""));
    setRootId(get(node, "rootId", ""));
    setMerchantId(get(node, "merchantId", ""));
    setMerchantName(get(node, "name", ""));
  };

  const setDataRuleDetail = (node: Nodes | undefined): void => {
    setDetails(node);
    if (subType === SubTypeEnum.COMMERCE) {
      const country: Country | undefined = defaultTo(countries_array, []).find(
        (country: Country) => country.code === get(node, "countryCode", "")
      );

      if (!isUndefined(country)) {
        setValue("countries", [country.name]);
      } else {
        setValue("countries", []);
      }
    }
  };

  const validateMerchantId = (): string => {
    if (subType === SubTypeEnum.COUNTRY && size(countries) > 0) return "all";

    if (isFormEditMode(ruleId || ruleName))
      return get(ruleDetails, "merchantId", "");

    const merchantInfoId: string = get(
      merchant_info,
      "data[0]._source.merchantId"
    );

    if (
      (isEmpty(merchantId) && !isEmpty(merchantInfoId)) ||
      (isEmpty(rootId) && !isEmpty(get(ruleDetails, "merchantId")))
    )
      setMerchantId(merchantInfoId);

    return merchantInfoId;
  };

  useEffect(() => {
    if (!isUpdatedRule) {
      dispatch(getCountries());
    }
  }, [isUpdatedRule]);

  useEffect(() => {
    if (nodes) setDataRuleDetail(nodes);
  }, [nodes]);

  useEffect(() => {
    const merchantData: MerchantProperties | undefined = get(
      merchant_info,
      "data",
      []
    ).find(
      (dataItem: MerchantProperties) =>
        get(dataItem, "_source.merchantId", "") === merchantId
    );
    const isCommerceWithoutHierarchy: boolean = isEqual(
      commerceType,
      SubTypeEnum.COMMERCE_WITHOUT_HIERARCHY
    );

    if (!isCommerceWithoutHierarchy) {
      const isEditedRule: boolean = isFormEditMode(ruleId || ruleName);
      const isCommerceWithHierarchy: boolean = isEqual(
        commerceType,
        SubTypeEnum.COMMERCE_WITH_HIERARCHY
      );
      const isCommerceSubType: boolean = isEqual(
        subType,
        TableValueEnum.COMMERCE
      );
      const merchantNameValid: string = isCommerceWithHierarchy
        ? merchantName
        : isEditedRule
        ? get(ruleDetails, "merchantName", "")
        : get(merchant_info, "data[0]._source.name");
      const merchantIdValid: string = validateMerchantId();
      const payload: RuleDetail = {
        ...ruleDetails,
        country: isCommerceWithHierarchy
          ? [get(merchantData, "_source.country", "")]
          : get(ruleDetails, "country", []),
        service: defaultTo(service, ""),
        subType: subType,
        entityName: isCommerceWithHierarchy ? defaultTo(entityName, "") : "",
        nodeId: nodeId ? nodeId : get(ruleDetails, "nodeId", ""),
        rootId: rootId ? rootId : get(ruleDetails, "rootId", ""),
        merchantName: isCommerceSubType ? defaultTo(merchantNameValid, "") : "",
        merchantId: isCommerceSubType ? defaultTo(merchantIdValid, "") : "",
        configType: ConfigurationTypeEnum.FIRST_LEVEL,
        requestType: RequestTypeEnum.CREATION,
        status: StatusEnum.PENDING,
        kind: Values.RULES_KIND_STRICT,
      };

      if (!isEqual(ruleDetails, payload)) dispatch(setRuleDetails(payload));
    }
  }, [
    service,
    subType,
    entityName,
    nodeId,
    rootId,
    merchantName,
    merchantId,
    merchant_info,
  ]);

  useEffect(() => {
    if (
      !isEmpty(countries) &&
      !isUndefined(ruleDetails) &&
      !isEqual(get(ruleDetails, "country", []), countries)
    )
      dispatch(
        setRuleDetails({
          ...ruleDetails,
          country: countries,
          subType: watch(FieldModelEnum.SUBTYPE),
        })
      );
  }, [countries]);

  useEffect(() => {
    setIsItemSelectedWithoutHierarchy(false);
    setIsItemSelectedWithHierarchy(false);
    setIsItemCountrySelected(false);
  }, [commerceType, subType]);

  useEffect(() => {
    if (
      !isEmpty(commerceConditions) &&
      commerceType === SubTypeEnum.COMMERCE_WITHOUT_HIERARCHY &&
      isUndefined(get(withoutHierarchy, "id"))
    ) {
      setCommerceConditions([]);
    }
  }, [withoutHierarchy, commerceConditions]);

  useEffect(() => {
    if (
      isUndefined(get(hierarchy, "id")) &&
      commerceType === SubTypeEnum.COMMERCE_WITH_HIERARCHY &&
      !isEmpty(commerceConditions)
    ) {
      setCommerceConditions([]);
    }
  }, [hierarchy, commerceConditions]);

  useEffect(() => {
    if (isUndefined(countries)) {
      setCountryConditions([]);
    }
  }, [countries]);

  useEffect(() => {
    const commerceConditionsToRetrieve: string[] = [];
    if (securityRuleByType && get(securityRuleByType, "items") !== null) {
      securityRuleByType.items.forEach((data) => {
        Object.keys(data.rule).forEach((key: string) => {
          const result: string = CONDITIONS_CONFIG_BY_COMMERCE[key];

          if (result) {
            commerceConditionsToRetrieve.push(result);
          }
        });
      });
    }

    setCommerceConditions(commerceConditionsToRetrieve);
  }, [securityRuleByType]);

  useEffect(() => {
    if (whiteListByCountry) {
      const countryConditionsToRetrieve: GetWhiteListByCountryResponse = [];

      whiteListByCountry.forEach(
        (data: { country: string; conditions: string[] }) => {
          const keyValue: string = data.country;
          const conditionsValue: string[] = data.conditions;
          let whitelistObject:
            | { country: string; conditions: string[] }
            | undefined = undefined;
          const temporalConditions: string[] = [];

          conditionsValue.forEach((value: string) => {
            const transformResult: string =
              CONDITIONS_CONFIG_BY_COMMERCE[value];

            if (value && !isUndefined(transformResult)) {
              temporalConditions.push(transformResult);
            }
            whitelistObject = {
              country: keyValue,
              conditions: temporalConditions,
            };
          });

          if (whitelistObject) {
            countryConditionsToRetrieve.push(whitelistObject);
          }
        }
      );

      setCountryConditions(countryConditionsToRetrieve);
    }
  }, [whiteListByCountry]);

  useEffect(() => {
    const isRenderComponent: boolean =
      ((isItemSelectedWithHierarchy || isItemSelectedWithoutHierarchy) &&
        commerceConditions.length > 0) ||
      (isItemCountrySelected && countryConditions.length > 0);

    setIsRenderConfiguredConditionsComponent(isRenderComponent);
  }, [
    isItemSelectedWithHierarchy,
    isItemSelectedWithoutHierarchy,
    isItemCountrySelected,
    commerceConditions,
    countryConditions,
  ]);

  const findConditionsByCountry = (
    new_country_conditions: GetWhiteListByCountryResponse,
    country: string
  ): number => {
    return new_country_conditions.findIndex(
      (condition: { country: string; conditions: string[] }) =>
        isEqual(condition.country, country)
    );
  };

  const filterConditionsCountry = (): GetWhiteListByCountryResponse => {
    const new_country_conditions: GetWhiteListByCountryResponse = [];

    countryConditions.map(
      (countryCondition: { country: string; conditions: string[] }) => {
        const find_index: number = findConditionsByCountry(
          new_country_conditions,
          countryCondition.country
        );

        if (find_index !== -1) {
          countryCondition.conditions.map((cond: string) => {
            const conditions: string[] =
              new_country_conditions[find_index].conditions;
            if (conditions.indexOf(cond) < 0) conditions.push(cond);
          });
        } else {
          new_country_conditions.push({
            country: countryCondition.country,
            conditions: countryCondition.conditions,
          });
        }
      }
    );
    return new_country_conditions;
  };

  return {
    setNodes: {
      setNodesChange,
    },
    commerceConditions,
    commerceType,
    countryConditions: filterConditionsCountry(),
    isRenderConfiguredConditionsComponent,
    setCommerceConditions,
    setIsItemCountrySelected,
    setIsItemSelectedWithHierarchy,
    setIsItemSelectedWithoutHierarchy,
    showCommerceType,
    showControllerField,
    subType,
    whitelistConfiguration,
  };
};
