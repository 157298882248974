import { IAppAction } from "./actionCreators";
import { INotification } from "../shared/infrastructure/interfaces/INotification";
import { ActionTypes } from "./actionTypes";
import { RetentionMerchantConfigDynamo } from "../../types/retention_merchant_configuration";
import { ICsvTemplateData } from "../shared/infrastructure/interfaces/ICsvTemplate";
import {
  columnsRetentionIVATable,
  IColumnsTable,
} from "../shared/infrastructure/Table/ColumnsTable";
import { RetailsConfigurationsFile } from "../../types/retails_configurations_file";
import { Catalog } from "../../types/catalog";
import { CatalogRequest } from "../../types/catalog_request";

export interface IAppState {
  base64Csv?: string;
  notification?: INotification;
  notificationConfig?: INotification;
  isLoading?: boolean;
  retention?: RetentionMerchantConfigDynamo[];
  retentionConfig?: RetentionMerchantConfigDynamo;
  isOpenModalConfirmAddConfig?: boolean;
  catalogRequest?: CatalogRequest;
  isOpenCatalogFormPopup?: boolean;
  isOpenCatalogConfirmPopup?: boolean;
  isOpenCatalogInfoPopup?: boolean;
  isLoadingRetentionDashboard?: boolean;
  isLoadingDeleteRetailConfig?: boolean;
  isCompletedDelete?: boolean;
  editRetention?: RetentionMerchantConfigDynamo;
  errorCsv?: boolean;
  errorCsvMessage?: string;
  fileSent?: boolean;
  templateXslxData?: ICsvTemplateData;
  loadByFormError?: boolean;
  existRetention?: boolean;
  isLoadingVerifyExistRetention?: boolean;
  isAddRetention?: boolean;
  isEditRetention?: boolean;
  columnsConfigRetentionEc?: IColumnsTable[];
  indexEditRetentionTab?: number;
  editRetentionDashboard?: boolean;
  validateRetentionEdit?: boolean;
  addRetentionDirectly?: boolean;
  codeDatil?: string;
  retentionsConfigFile?: RetailsConfigurationsFile;
  catalogsList?: Catalog[];
  fileSentCatalog?: boolean;
}

export const INITIAL_STATE: IAppState = {
  base64Csv: "",
  notification: {
    type: "success",
    open: false,
    message: "",
  },
  notificationConfig: {
    type: "success",
    open: false,
    message: "",
  },
  isLoading: false,
  isLoadingRetentionDashboard: true,
  retention: [],
  templateXslxData: {
    isLoadingFileData: false,
    isLoading: false,
    s3Url: "",
  },
  retentionConfig: {} as RetentionMerchantConfigDynamo,
  isOpenModalConfirmAddConfig: false,
  isOpenCatalogFormPopup: false,
  isOpenCatalogConfirmPopup: false,
  isLoadingDeleteRetailConfig: false,
  isOpenCatalogInfoPopup: false,
  isCompletedDelete: false,
  editRetention: undefined,
  errorCsv: false,
  fileSent: false,
  fileSentCatalog: false,
  loadByFormError: false,
  existRetention: false,
  isLoadingVerifyExistRetention: false,
  isAddRetention: false,
  isEditRetention: false,
  columnsConfigRetentionEc: columnsRetentionIVATable,
  indexEditRetentionTab: 0,
  editRetentionDashboard: false,
  validateRetentionEdit: false,
  addRetentionDirectly: false,
  codeDatil: "",
  catalogsList: [],
};

export const reducer = (
  state: IAppState = INITIAL_STATE,
  action: IAppAction
): IAppState => {
  switch (action.type) {
    case ActionTypes.SET_BASE64_CSV:
      return {
        ...state,
        base64Csv: action.base64Csv,
      };
    case ActionTypes.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.notification,
      };
    case ActionTypes.SET_NOTIFICATION_CONFIG:
      return {
        ...state,
        notificationConfig: action.notificationConfig,
      };
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case ActionTypes.SET_LOADING_RETENTION_DASHBOARD:
      return {
        ...state,
        isLoadingRetentionDashboard: action.isLoadingRetentionDashboard,
      };
    case ActionTypes.SET_RETENTION:
      return {
        ...state,
        retention: action.retention,
      };
    case ActionTypes.SET_RETENTION_CONFIG:
      return {
        ...state,
        retentionConfig: action.retentionConfig,
      };
    case ActionTypes.SET_OPEN_MODAL_CONFIRM_ADD_CONFIG:
      return {
        ...state,
        isOpenModalConfirmAddConfig: action.isOpenModalConfirmAddConfig,
      };
    case ActionTypes.SET_LOADING_DELETE_RETAIL_CONFIG:
      return {
        ...state,
        isLoadingDeleteRetailConfig: action.isLoadingDeleteRetailConfig,
      };
    case ActionTypes.SET_COMPLETED_DELETE_RETAIL_CONFIG:
      return {
        ...state,
        isCompletedDelete: action.isCompletedDelete,
      };
    case ActionTypes.SET_LOAD_BY_FORM_ERROR:
      return {
        ...state,
        loadByFormError: action.loadByFormError,
      };
    case ActionTypes.SET_EDIT_RETENTION:
      return {
        ...state,
        editRetention: action.editRetention,
      };
    case ActionTypes.SET_ERROR_CSV:
      return {
        ...state,
        errorCsv: action.errorCsv,
        errorCsvMessage: action.errorCsvMessage,
      };
    case ActionTypes.SET_FILE_SENT:
      return {
        ...state,
        fileSent: action.fileSent,
      };
    case ActionTypes.SET_CSV_TEMPLATE:
    case ActionTypes.SET_EXCEL_TEMPLATE:
      return {
        ...state,
        templateXslxData: action.templateXslxData,
      };
    case ActionTypes.EXIST_RETENTION:
      return {
        ...state,
        existRetention: action.existRetention,
      };
    case ActionTypes.SET_LOADING_VERIFY_EXIST_RETENTION:
      return {
        ...state,
        isLoadingVerifyExistRetention: action.isLoadingVerifyExistRetention,
      };
    case ActionTypes.SET_ADD_RETENTION:
      return {
        ...state,
        isAddRetention: action.isAddRetention,
      };
    case ActionTypes.SET_IS_EDIT_RETENTION:
      return {
        ...state,
        isEditRetention: action.isEditRetention,
      };
    case ActionTypes.SET_COLUMNS_CONFIG_RETENTION_EC:
      return {
        ...state,
        columnsConfigRetentionEc: action.columnsConfigRetentionEc,
      };
    case ActionTypes.SET_INDEX_TAB_EDIT_RETENTION:
      return {
        ...state,
        indexEditRetentionTab: action.indexEditRetentionTab,
      };
    case ActionTypes.SET_EDIT_RETENTION_DASHBOARD:
      return {
        ...state,
        editRetentionDashboard: action.editRetentionDashboard,
      };
    case ActionTypes.SET_EDIT_RETENTION_VALIDATE:
      return {
        ...state,
        validateRetentionEdit: action.validateRetentionEdit,
      };
    case ActionTypes.SET_ADD_RETENTION_DIRECTLY:
      return {
        ...state,
        addRetentionDirectly: action.addRetentionDirectly,
      };
    case ActionTypes.SET_CODE_DATIL:
      return {
        ...state,
        codeDatil: action.codeDatil,
      };
    case ActionTypes.SET_RETENTIONS_CONFIG_FILE:
      return {
        ...state,
        retentionsConfigFile: action.retentionsConfigFile,
      };
    case ActionTypes.SET_CATALOGS_LIST:
      return {
        ...state,
        catalogsList: action.catalogsList,
      };
    case ActionTypes.SET_CATALOG_REQUEST:
      return {
        ...state,
        catalogRequest: action.catalogRequest,
      };
    case ActionTypes.SET_IS_OPEN_CATALOG_FORM_POPUP:
      return {
        ...state,
        isOpenCatalogFormPopup: action.isOpenCatalogFormPopup,
      };
    case ActionTypes.SET_IS_OPEN_CATALOG_CONFIRM_POPUP:
      return {
        ...state,
        isOpenCatalogConfirmPopup: action.isOpenCatalogConfirmPopup,
      };
    case ActionTypes.SET_IS_OPEN_CATALOG_INFO_POPUP:
      return {
        ...state,
        isOpenCatalogInfoPopup: action.isOpenCatalogInfoPopup,
      };
    case ActionTypes.SET_FILE_SENT_CATALOG:
      return {
        ...state,
        fileSentCatalog: action.fileSentCatalog,
      };
    default:
      return state;
  }
};
