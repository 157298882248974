import React, { FC, memo } from "react";
import { Box, Typography } from "@material-ui/core";
import { titleStyles as styles } from "./Title.styles";
import { TITLE } from "../../shared/constants/labels/title_labels";

const Title: FC = () => {
  const classes = styles();

  return (
    <Box className={classes.titleContainer}>
      <Typography className={classes.title}>{TITLE}</Typography>
    </Box>
  );
};

export default memo(Title);
