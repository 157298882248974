import React from "react";
import { TextField } from "@material-ui/core";
import { AuthenticationRulesLabelField } from "../../../shared/infrastructure/constants/AuthenticationRulesConstants";
import { ControllerRenderProps } from "react-hook-form";

export interface TextFieldComponentProps {
  disabled: boolean;
  type?: string;
  mask?: string;
  properties: ControllerRenderProps;
  helperText: string | false | undefined;
  error: boolean;
  label?: string;
}

export const TextFieldComponent: React.FC<TextFieldComponentProps> = (
  props: TextFieldComponentProps
) => {
  const { properties, error, helperText } = props;

  return (
    <TextField
      {...properties}
      id="value"
      label={AuthenticationRulesLabelField.VALUE}
      type={props.type}
      variant="outlined"
      error={error}
      fullWidth
      InputProps={{
        inputComponent: props.mask as any,
      }}
      helperText={helperText}
    />
  );
};

export default TextFieldComponent;
