import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  hideLoading as hideLoadingRedux,
  hideSnackbar as hideSnackbarRedux,
  setActiveColumns as setActiveColumnsRedux,
  setConciliationList as setConciliationListRedux,
  setLoadingText as setLoadingTextRedux,
  setNotification as setNotificationRedux,
  setTotalConciliations as setTotalConciliationsRedux,
  showErrorSnackbar as showErrorSnackbarRedux,
  showLoading as showLoadingRedux,
  showSuccessSnackbar as showSuccessSnackbarRedux,
} from "../actions/app/app";
import { AppDispatch, RootState } from "../store.interfaces";

import { getConciliationList as getConciliationListRedux } from "../epics/";
import { GetConciliationTransactionsRequest } from "../../../types/get_conciliation_transactions_request";
import { setValueTab as setValueTabRedux } from "../actions/app_actions";
import { INotification } from "../../shared/infrastructure/interfaces/INotification";
import { Conciliation } from "../../../types/get_conciliation_transactions_response";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useKshRedux = () => {
  const dispatch = useAppDispatch();
  const store = useAppSelector((state: RootState) => state.app);

  const hideSnackbar = () => {
    dispatch(hideSnackbarRedux());
  };

  const hideLoading = () => {
    dispatch(hideLoadingRedux());
  };

  const setValueTab = (valueTab: string) => {
    dispatch(setValueTabRedux(valueTab));
  };

  const setLoadingText = (loadingText: string) => {
    dispatch(setLoadingTextRedux(loadingText));
  };

  const showErrorSnackbar = () => {
    dispatch(showErrorSnackbarRedux());
  };

  const showLoading = () => {
    dispatch(showLoadingRedux());
  };

  const showSuccessSnackbar = () => {
    dispatch(showSuccessSnackbarRedux());
  };

  const setConciliationList = (data: Conciliation[]) => {
    dispatch(setConciliationListRedux(data));
  };

  const setTotalConciliations = (data: number) => {
    dispatch(setTotalConciliationsRedux(data));
  };

  const setActiveColumns = (columns: string[]) => {
    dispatch(setActiveColumnsRedux(columns));
  };

  const getConciliationList = async (
    data: GetConciliationTransactionsRequest
  ) => {
    return await getConciliationListRedux(data, dispatch);
  };

  const setNotification = (notification: INotification) => {
    dispatch(setNotificationRedux(notification));
  };

  return {
    actions: {
      getConciliationList,
      hideLoading,
      hideSnackbar,
      setActiveColumns,
      setConciliationList,
      setLoadingText,
      setNotification,
      setTotalConciliations,
      setValueTab,
      showErrorSnackbar,
      showLoading,
      showSuccessSnackbar,
    },
    store,
  };
};
