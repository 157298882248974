import React, { PropsWithChildren } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { get } from "lodash";
import {
  ITEM_SIMPLE_CONDITION,
  TRANSACTION_TYPE_MAPPING_CREATION,
} from "../../../../../../shared/infrastructure/enums/ItemByTagEnum";
import { IRadioGroupContainerProps } from "../../../../../../shared/infrastructure/interfaces/IRadioGroupContainerProps";
import { RuleEnum } from "../../../../../../shared/infrastructure/enums/RuleEnum";

const getCorrectValueByTag = (props: IRadioGroupContainerProps): string => {
  if (props.tag === RuleEnum.CARD_TYPE)
    return TRANSACTION_TYPE_MAPPING_CREATION[props.simpleCondition!];

  return props.simpleCondition!;
};

export const RadioGroupContainer: React.FC<IRadioGroupContainerProps> = (
  props: PropsWithChildren<IRadioGroupContainerProps>
) => {
  const simpleCondition: string = getCorrectValueByTag(props);

  return (
    <RadioGroup
      key={simpleCondition}
      row
      defaultValue={simpleCondition}
      value={simpleCondition}
      onChange={props.selectSimpleCondition}
    >
      <FormControlLabel
        {...props.selectProps}
        value={get(ITEM_SIMPLE_CONDITION[props.tag], "firstValue", "")}
        control={<Radio color={"primary"} />}
        label={get(ITEM_SIMPLE_CONDITION[props.tag], "firstValueLabel", "")}
      />
      <FormControlLabel
        {...props.selectProps}
        value={get(ITEM_SIMPLE_CONDITION[props.tag], "secondValue", "")}
        control={<Radio color={"primary"} />}
        label={get(ITEM_SIMPLE_CONDITION[props.tag], "secondValueLabel", "")}
      />
    </RadioGroup>
  );
};
