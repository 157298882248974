/**
 * ActionContain Enumeration
 */
export enum ActionModalContentEnum {
  conciliationTittle = "¿Desea realizar la conciliación?",
  conciliationMassiveTitle = "¿Desea realizar las conciliaciones?",
  omitTittle = "¿Desea omitir la transacción?",
  omitMassiveTittle = "¿Desea omitir las transacciones?",
  depositOmitTitle = "¿Desea omitir el registro?",
  confirmButton = "Confirmar",
  omitButton = "Omitir",
  message = "Tenga en cuenta que esta acción es irreversible.",
}
