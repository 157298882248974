/* istanbul ignore file */
import { LocalizationProvider, StaticDateRangePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Box, Button, Input } from "@mui/material";
import esLocale from "date-fns/locale/es";
import React, { FC, FormEvent, useState } from "react";
import { pickerStyles as styles } from "./Picker.styles";
import { PickerProps } from "./Picker.interfaces";
import { DateRange } from "@mui/lab/DateRangePicker/RangeTypes";
import useRawDateTime from "../useRawDateTime/useRawDateTime";
import {
  formatDateTime,
  parseDate,
  today,
  yesterday,
} from "../utils/functions";

/**
 * @param 'from, to' string format "dd/mm/yyyy hh:mm"
 */
const Picker: FC<PickerProps> = ({
  disableFuture = false,
  disablePast = false,
  from = "",
  onChange = () => undefined,
  to = "",
}: PickerProps) => {
  const [rawFromDate, rawFromTime] = useRawDateTime(from);
  const [rawToDate, rawToTime] = useRawDateTime(to);

  const [fromToDate, setFromToDate] = useState<DateRange<Date>>(() => {
    const fromDate = parseDate(rawFromDate || yesterday(esLocale), esLocale);
    const toDate = parseDate(rawToDate || today(esLocale), esLocale);

    return [fromDate, toDate];
  });

  const [fromTime, setFromTime] = useState<string>(rawFromTime || "00:00");
  const [toTime, setToTime] = useState<string>(rawToTime || "23:59");

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const [fromDate, toDate] = fromToDate;

    if (fromDate && toDate) {
      const formattedFrom = formatDateTime(fromDate, fromTime, esLocale);
      const formattedTo = formatDateTime(toDate, toTime, esLocale);

      onChange({
        from: formattedFrom,
        to: formattedTo,
      });
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
        <StaticDateRangePicker
          displayStaticWrapperAs="desktop"
          value={fromToDate}
          onChange={setFromToDate}
          disableFuture={disableFuture}
          disablePast={disablePast}
          renderInput={() => <div />}
          reduceAnimations
        />
      </LocalizationProvider>

      <Box sx={styles.footer}>
        <Input
          id="from"
          type="time"
          value={fromTime}
          onChange={(e) => setFromTime(e.target.value)}
          sx={styles.inputContainer}
          inputProps={{ "aria-label": "from", step: 300, sx: styles.input }}
        />
        <Input
          id="to"
          type="time"
          value={toTime}
          onChange={(e) => setToTime(e.target.value)}
          sx={styles.inputContainer}
          inputProps={{ "aria-label": "to", step: 300, sx: styles.input }}
        />

        <Button sx={styles.button} variant="contained" type="submit">
          Aplicar Fechas
        </Button>
      </Box>
    </Box>
  );
};

export default Picker;
