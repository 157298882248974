/* istanbul ignore file */
import axiosRetry from "axios-retry";
import axios from "axios";
import { defaultRetryConfig } from "./constants/axios_retry_constants";

const retryConfiguration = {
  retries: 15,
  retryCondition: () => true,
  retryDelay: () => 10000,
};

const retryAxiosInstance = axios.create({
  headers: {
    Authorization: localStorage.getItem("jwt"),
    "Content-type": "application/json",
  },
});

axiosRetry(retryAxiosInstance, {
  ...defaultRetryConfig,
  ...retryConfiguration,
});

export default retryAxiosInstance;
