import React, { FC } from "react";
import { TextField } from "@mui/material";
import { IRenderInputProps } from "../../SelectNewItem.interfaces";
import { selectNewItemStyles } from "../../SelectNewItem.styles";

const RenderInput: FC<IRenderInputProps> = ({
  params,
  placeholder,
  error,
  inputValueText,
  specialCharacters,
  setInputValueText,
  isDisabled,
  isLoading,
  helperText,
  handleOnChange,
}: IRenderInputProps) => {
  return (
    <>
      <TextField
        {...params}
        data-testid={"renderInput"}
        label={placeholder}
        id={"selectTextField"}
        error={error}
        type={"text"}
        value={inputValueText}
        onChange={(e) => {
          if (!specialCharacters) {
            const newValue = e.target.value;

            if (newValue.match(/^[a-zA-Z0-9\s]+$/)) {
              setInputValueText(newValue);
            } else {
              setInputValueText(newValue.slice(0, -1));
            }
          }
          handleOnChange(e.target.value);
        }}
        disabled={isDisabled || isLoading}
        helperText={error ? helperText : ""}
        InputProps={{ ...params.InputProps, sx: selectNewItemStyles.textField }}
      />
    </>
  );
};

export default RenderInput;
