import React from "react";
import { Box, Button, createStyles, Typography } from "@material-ui/core";
import {
  navigateRoute,
  StatusSemaphore,
} from "../../../shared/infrastructure/constants/CreateMerchantConstants";
import { makeStyles } from "@material-ui/core/styles";
import { StatusSteps } from "../../../../types/remote/semaphore_data";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { get } from "lodash";

const useStyles = makeStyles(() =>
  createStyles({
    containedButton: {
      fontSize: "16px",
    },
  })
);

export interface InvoiceConfigPendingProps {
  status: StatusSteps;
  publicMerchantId: string;
}

export const InvoiceConfigPending: React.FC<
  Partial<InvoiceConfigPendingProps>
> = () => {
  const classes = useStyles();
  const { status, publicMerchantId }: InvoiceConfigPendingProps = useSelector(
    (state: IAppState) => ({
      status: get(
        state,
        "semaphore.stepConfigRatesAndInvoice.statusInvoice",
        StatusSemaphore.pending
      ),
      publicMerchantId: get(state, "merchantAffiliation.publicMerchantId", ""),
    })
  );

  const redirectConfigPage = () => {
    if (publicMerchantId)
      navigateRoute(
        `/billing-merchant/charge/${publicMerchantId}?hideSideBar=true`
      );
  };

  return status === StatusSemaphore.pending ? (
    <Box
      mt={2}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      bgcolor="background.default"
      borderRadius={4}
      py={3}
    >
      <Typography variant="h5" gutterBottom>
        <Box fontWeight={300} display="inline">
          Aún no has ingresado datos de{" "}
        </Box>
        <Box fontWeight={400} display="inline">
          facturación, cobro y dispersión
        </Box>
      </Typography>
      <Button
        variant="contained"
        className={classes.containedButton}
        color="primary"
        size="medium"
        onClick={redirectConfigPage}
      >
        Ingresar datos
      </Button>
    </Box>
  ) : null;
};
