import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { LoaderModal } from "../LoaderModal/LoaderModal";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/reducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cancelButton: {
      color: "#293036",
      backgroundColor: "white",
      "&.MuiButton-root": {
        border: "1px solid",
        borderColor: "#CCD4DE",
      },
    },
    dialogContainer: {
      height: theme.spacing(22.25),
      width: theme.spacing(68.75),
    },
  })
);

export interface IDialogMessageProps {
  title: string;
  message: string;
  buttonLbl: string;
  openDialog: boolean;
  onCancel: () => void;
  submitAction: () => void;
}

export const ActionModal: React.FC<IDialogMessageProps> = ({
  title,
  message,
  buttonLbl,
  openDialog,
  onCancel,
  submitAction,
}: IDialogMessageProps) => {
  const classes = useStyles();
  const store = useSelector((state: IAppState) => state);

  return (
    <React.Fragment>
      <Dialog
        open={openDialog}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box className={classes.dialogContainer}>
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onCancel}
              color="primary"
              variant={"outlined"}
              className={classes.cancelButton}
            >
              Cancelar
            </Button>
            <Button
              id="ok_modal"
              onClick={submitAction}
              variant={"contained"}
              color="primary"
              autoFocus
            >
              {buttonLbl}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <LoaderModal open={store.loadingMassive!} handleClose={onCancel} />
    </React.Fragment>
  );
};
