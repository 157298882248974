import React, { SyntheticEvent } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { DetailHeaderStyles } from "./DetailHeader.styles";

interface DetailHeaderProps {
  currentTab: number;
  handleChangeTab: (
    _event: SyntheticEvent | undefined,
    newValue: number
  ) => void;
  title: string;
  headerLineText: string;
  subheaderLineText?: string;
}

const DetailHeader = ({
  currentTab,
  handleChangeTab,
  headerLineText,
  subheaderLineText,
  title,
}: DetailHeaderProps) => {
  return (
    <Box sx={DetailHeaderStyles.container}>
      <Box sx={DetailHeaderStyles.titleContainer}>
        <Typography sx={DetailHeaderStyles.title}>{title}</Typography>
        <Typography sx={DetailHeaderStyles.headerLine}>
          {headerLineText}
        </Typography>
        <Typography sx={DetailHeaderStyles.subheaderLine}>
          {subheaderLineText}
        </Typography>
      </Box>
      <Box sx={DetailHeaderStyles.tabsContainer}>
        <Tabs variant="fullWidth" value={currentTab} onChange={handleChangeTab}>
          <Tab label="Información" value={0} sx={DetailHeaderStyles.tab} />
          <Tab label="Metadata" value={1} sx={DetailHeaderStyles.tab} />
        </Tabs>
      </Box>
    </Box>
  );
};

export default DetailHeader;
