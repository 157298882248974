export const status = {
  approved: true,
  declined: false,
};

export enum SemaphoreStatusEnum {
  PENDING = "pending",
  COMPLETE = "complete",
  IN_PROCESS = "inProcess",
  OMITTED = "omitted",
}
