import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from "@material-ui/core";
import { X } from "react-feather";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cancelAction: {
      color: theme.palette.error.dark,
      marginRight: 20,
    },
    circularProgress: {
      color: "#fff",
    },
    closeButton: {
      "&.MuiIconButton-root": {
        marginBottom: 12,
        marginLeft: 24,
        marginRight: 0,
        marginTop: 0,
        padding: 3,
      },
      color: theme.palette.primary.dark,
    },
    dialogActions: {
      paddingBottom: 12,
      paddingRight: 24,
    },
    dialogContentText: {
      marginBottom: 24,
    },
    dialogTitle: {
      paddingBottom: 16,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 16,
    },
    errorColor: {
      color: theme.palette.error.main,
    },
    primaryColor: {
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
        opacity: "0.8",
      },
      backgroundColor: theme.palette.primary.dark,
      border: "0.5px solid rgb(190,200,212)",
      color: "#fff",
      height: "42px",
      padding: "6px 30px",
    },
    titleText: {
      color: theme.palette.primary.dark,
    },
  })
);

export interface ConfirmModalProps {
  open: boolean;
  titleText: string;
  contentText?: string | React.ReactElement;
  isDetail?: boolean;
  acceptText?: string;
  cancelText?: string;
  acceptColor?: "primary" | "error";
  fullWidth?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  active?: boolean;
  isLoadingAcceptAction?: boolean;
  customCancelBtnStyle?: any;
  handleClose: () => void;
  handleAccept: () => void;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = (
  props: React.PropsWithChildren<ConfirmModalProps>
) => {
  const classes = useStyles(props);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth={props.fullWidth}
      maxWidth={props.maxWidth}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box className={classes.dialogTitle} display="flex" flexGrow={1}>
        <Box display="flex" alignItems="center" flexGrow={1}>
          <Typography variant="h5" className={classes.titleText}>
            {props.titleText}
          </Typography>
        </Box>
        <Box display="flex" alignItems="flex-start">
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={props.handleClose}
          >
            <X size={18} />
          </IconButton>
        </Box>
      </Box>
      <DialogContent>
        {props.contentText && (
          <DialogContentText
            className={classes.dialogContentText}
            id="alert-dialog-description"
          >
            <Typography component="span" variant="h6" color="textPrimary">
              {props.contentText}
            </Typography>
          </DialogContentText>
        )}
        {props.children}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {!props.isDetail ? (
          <Button
            onClick={props.handleClose}
            className={
              props.customCancelBtnStyle
                ? props.customCancelBtnStyle
                : classes.cancelAction
            }
          >
            {props.cancelText}
          </Button>
        ) : undefined}
        <Button
          onClick={props.handleAccept}
          className={
            props.acceptColor === "error"
              ? classes.errorColor
              : classes.primaryColor
          }
          disabled={props.isLoadingAcceptAction}
        >
          {props.isLoadingAcceptAction ? (
            <CircularProgress
              size={24}
              className={
                props.acceptColor === "error"
                  ? classes.errorColor
                  : classes.circularProgress
              }
            />
          ) : (
            props.acceptText
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmModal.defaultProps = {
  acceptColor: "error",
  acceptText: "Aceptar",
  cancelText: "Cancelar",
  fullWidth: true,
  maxWidth: "sm",
};
