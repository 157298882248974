import { IDefaultProcessorForm } from "../interfaces/IDefaultProcessorForm";

export const CONFIGURATION_LABELS: IDefaultProcessorForm = {
  payoutsCard: "Tarjeta Pay Out",
  card: "Tarjeta Pay In",
  payoutsTransfer: "Transferencia Pay Out",
  transfer: "Transferencia Pay In",
  achTransfer: "Débito recurrente",
};

export enum LabelEnum {
  SELECT_PROCESSOR = "Seleccionar procesador",
  EMPTY_DEFAULT_PROCESSOR_LIST_MSG = "Debes tener al menos un procesador creado y activo para configurar procesador por defecto",
  PROCESSOR_DETAIL = "Detalle del procesador ",
  PROCESSOR_CONFIG = "Configurar procesador",
  DEFAULT_PROCESSOR_CONFIG = "Configuración de procesador por defecto",
}
