import { DeferredOption } from "../../../../types/get_merchant_deferred_response";

export const DEFERRED_DEFAULT_VALUE: DeferredOption = {
  id: "",
  merchantStatus: "",
  status: "",
};

export enum StatusDialogTypeEnum {
  ACTIVE = "ACTIVE",
  DEACTIVATE = "DEACTIVATE",
}

export interface StatusDialogTexts {
  title: string;
  subtitle: string;
  buttonText: string;
  type: StatusDialogTypeEnum;
}

export const ACTIVE_TEXTS: StatusDialogTexts = {
  buttonText: "Activar",
  subtitle:
    "Tu comercio podrá ofrecer pagos en cuotas si activas los diferidos.",
  title: "¿Deseas activar los diferidos?",
  type: StatusDialogTypeEnum.ACTIVE,
};

export const DEACTIVATE_TEXTS: StatusDialogTexts = {
  buttonText: "Desactivar",
  subtitle:
    "Tu comercio no podrá ofrecer pagos en cuotas si desactivas los diferidos.",
  title: "¿Deseas desactivar los diferidos?",
  type: StatusDialogTypeEnum.DEACTIVATE,
};

export const STATUS_ENABLED_CHANGE_SUCCESS_MESSAGE =
  "¡Se activaron los diferidos!";

export const STATUS_DISABLED_CHANGE_SUCCESS_MESSAGE =
  "¡Se desactivaron los diferidos!";
export const UPDATE_DEFERRED_SUCCESS_MESSAGE =
  "¡La Configuración se realizó correctamente!";
export const UPDATE_DEFERRED_FAIL_MESSAGE =
  "¡Hubo un error al guardar la configuració!";
export const UPDATE_DEFERRED_CENTRAL_AMERICA = "¡Diferido guardado con éxito!";
export const ERROR_UPDATE_DEFERRED_MESSAGE =
  "Hubo un error al cambiar el estado";
export const UPDATE_DEFERRED_SUCCESS_BRAZIL =
  "El diferido se ha actualizado con éxito";
