import {
  Button,
  ButtonGroup,
  MenuList,
  MenuItem,
  Popover,
  createStyles,
  Theme,
} from "@material-ui/core";
import { ActionRefundEnum } from "../../shared/infrastructure/enums/ActionEnum";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

export interface ButtonDownloadFileProps {
  options: { text: string; value: ActionRefundEnum }[];
  actionHandler: (actionEnum: ActionRefundEnum) => void;
  disabledButtonsAction: boolean;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonIconStyled: {
      "&:disabled": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.default,
        opacity: 0.5,
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        border: "0px",
        color: theme.palette.background.default,
      },
      backgroundColor: theme.palette.primary.main,
      border: "0px",
      borderLeft: "1px solid gray",
      color: theme.palette.background.default,
      padding: "0px",
    },
    buttonStyled: {
      "&:disabled": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.default,
        opacity: 0.5,
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        border: "0px",
        color: theme.palette.background.default,
      },
      backgroundColor: theme.palette.primary.main,
      border: "0px",
      color: theme.palette.background.default,
    },
  })
);

export const ButtonDropDownAction: React.FC<ButtonDownloadFileProps> = (
  props: ButtonDownloadFileProps
) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <div>
        <ButtonGroup
          variant="outlined"
          size={"medium"}
          style={{ display: "flex" }}
        >
          <Button
            id={"btnDownload"}
            variant="contained"
            className={classes.buttonStyled}
            onClick={handleClick}
            disabled={props.disabledButtonsAction}
          >
            Acción
          </Button>
          <Button
            id="button_arrow"
            variant="contained"
            onClick={handleClick}
            disabled={props.disabledButtonsAction}
            className={classes.buttonIconStyled}
          >
            {<ArrowDropDownIcon />}
          </Button>
        </ButtonGroup>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
      >
        <MenuList id="split-button-menu">
          {props.options.map((option) => (
            <MenuItem
              key={option.value}
              onClick={() => {
                setAnchorEl(null);
                props.actionHandler(option.value);
              }}
            >
              {option.text}
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </React.Fragment>
  );
};
