import React, { FC } from "react";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import { ModalBranchCreateInterfaces } from "./ModalBranchCreate.interfaces";
import { ModalBranchCreateStyles } from "./ModalBranchCreate.styles";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import TextInputCapitalLetters from "../TextInputCapitalLetters/TextInputCapitalLetters";
import { ResumeDataInterface } from "../../containers/ResumeContainer/useResumeContainerState/IUseResumeContainerState.interfaces";
import { RegExpEnum } from "../../shared/enums/RegExpEnum";
import { INPUT_BRANCH } from "../../shared/constants/labels/resume_container_label";
import { ArrowRight } from "../Tooltip/ToolTip";
import { get } from "lodash";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

export const ModalBranchCreate: FC<ModalBranchCreateInterfaces> = ({
  titleText,
  onClose,
  descriptionText,
  buttonPrimary,
  buttonSecondary,
  isOpen,
  descriptionText2,
  form,
}: ModalBranchCreateInterfaces) => {
  const nameBranch: string = form.watch("nameBranch");

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid sx={ModalBranchCreateStyles.modalContainer}>
        <Box className="modalHeader">
          <Grid container className="itemsTop">
            <Box className="headerIconsContainer">
              <Typography
                id="modal-modal-title"
                variant="h3"
                color="primary"
                sx={{
                  flex: "1",
                }}
              >
                {titleText}{" "}
              </Typography>
            </Box>
            <Grid item height={36} width={36}>
              <IconButton color="secondary" onClick={onClose} size="small">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid className="itemsBottom">
            <Typography
              id="modal-modal-description"
              variant="body1"
              color="primary"
            >
              {descriptionText}
            </Typography>{" "}
          </Grid>

          <Grid className="itemsBottomRow">
            <Typography
              id="modal-modal-description"
              variant="body1"
              color="primary"
            >
              {descriptionText2}
            </Typography>
            <ArrowRight />
          </Grid>
          <Grid className="itemsBottom">
            <TextInputCapitalLetters
              control={form.control}
              name={"nameBranch" as keyof ResumeDataInterface}
              label={INPUT_BRANCH}
              errors={form.formState.errors}
              rules={{
                required: {
                  message: "Ingresa algo",
                  value: true,
                },
              }}
              regExp={RegExpEnum.ONLY_LETTER_NUMBERS_SPACES}
            />
          </Grid>
        </Box>
        <Box className="modalFooter">
          <Grid container className="itemsButtons">
            <ComponentSecurityWrapper
              componentId={get(buttonSecondary, "securityRol", "")}
            >
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={buttonSecondary?.action}
                sx={{ mr: 2 }}
                data-testid={"secondary-btn-create"}
              >
                {get(buttonSecondary, "text")}
              </Button>
            </ComponentSecurityWrapper>
            <ComponentSecurityWrapper
              componentId={get(buttonPrimary, "securityRol", "")}
            >
              <Button
                disabled={!form.formState.isValid || nameBranch === ""}
                size="small"
                variant="contained"
                color="primary"
                onClick={buttonPrimary.action}
                data-testid={"primary-btn-create"}
              >
                {get(buttonPrimary, "text")}
              </Button>
            </ComponentSecurityWrapper>
          </Grid>
        </Box>
      </Grid>
    </Modal>
  );
};
