import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { CategoryData, OptionsSelectSearchBy } from "@kushki/connect-ui";
import {
  MerchantHierarchyResponse,
  Nodes,
} from "../../../../../types/merchant_hierarchy_response";
import { IAppState } from "../../../../store/reducer";
import { useEffect, useState, useRef } from "react";
import { defaultTo, get, isEmpty, isEqual, isUndefined } from "lodash";
import {
  getHierarchyMerchantCredential,
  getMerchantHierarchy,
  getMerchantInformation,
  getProcessors,
  getSecurityRulesByType,
  setRuleDetails,
} from "../../../../store/actionCreators";
import { IUseMerchantHierarchyState } from "../../../../shared/infrastructure/interfaces/IUseMerchantHierarchyState";
import { NodesState } from "../../../../shared/infrastructure/interfaces/NodesState";
import {
  groupHierarchies,
  groupHierarchiesArray,
} from "../../../../shared/infrastructure/constants/HierarchiesGroup";
import { SearchRequestRuleByIdResponse } from "../../../../../types/search_request_rule_by_id_response";
import { checkIsWhiteList, isFormEditMode } from "../../../../utils/utilsFile";
import { useLocation, useParams } from "react-router-dom";
import { IUseParams } from "../../../../shared/infrastructure/interfaces/IUseParams";
import { FieldsRule } from "../../../../shared/infrastructure/constants/RuleRequestConstants";
import { RuleDetail } from "../../../../../types/rule_detail";
// @ts-ignore
import { ItemsCategoryData } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectSearchBy/SelectSearchBy.interfaces";
import { GetRuleByIdResponse } from "../../../../../types/get_rule_by_id";
import { GroupEnum } from "../../../../shared/infrastructure/enums/CreateRuleConstans";
import {
  GetMerchantResponse,
  MerchantProperties,
} from "../../../../../types/get_merchant_response";
import { SubTypeEnum } from "../../../../shared/infrastructure/enums/CreateRuleConstans";
import { TIMEOUT_VALUE } from "../../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { StatusEnum } from "../../../../shared/infrastructure/enums/StatusEnum";

export const useMerchantHierarchyState = (
  props: NodesState
): IUseMerchantHierarchyState => {
  const { control, errors, watch, setValue } = useFormContext();
  const dispatch = useDispatch();
  const { id, ruleId, status } = useParams<IUseParams>();
  const idValue: string = id || ruleId;

  const isWhiteList = checkIsWhiteList(useLocation().pathname);

  const hierarchy: OptionsSelectSearchBy = watch("hierarchy");
  const isUpdatedRule: boolean | undefined = watch("isUpdatedRule");
  const merchant: MerchantHierarchyResponse | undefined = useSelector(
    (state: IAppState) => state.merchantHierarchy
  );
  const ruleDetails: RuleDetail | undefined = useSelector(
    (state: IAppState) => state.ruleDetails
  );
  const searchRequestRuleById: SearchRequestRuleByIdResponse | undefined =
    useSelector((state: IAppState) => state.searchRequestRuleById);
  const searchRuleById: GetRuleByIdResponse | undefined = useSelector(
    (state: IAppState) => state.searchRuleById
  );

  const [load, setLoad] = useState<boolean>(false);
  const [find, setFind] = useState<string>(
    defaultTo(get(hierarchy, "text"), "")
  );
  const [listMerchantsItems, setListMerchantsItems] = useState<CategoryData[]>(
    []
  );
  const [isCreationRule, setIsCreationRule] = useState<boolean>(false);
  const merchant_info: GetMerchantResponse | undefined = useSelector(
    (state: IAppState) => state.merchantInformation
  );
  const [merchantId, setMerchantId] = useState<string>("");

  const [defaultValueSelect, setDefaultValueSelect] = useState<
    OptionsSelectSearchBy | undefined
  >(undefined);
  const debounceTimerReference = useRef<NodeJS.Timeout | undefined>(undefined);
  const [showSearchingTextMessage, setShowSearchingTextMessage] =
    useState(false);
  const isEmptyObject = (obj: any): boolean => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  const isItemDataLoaded = (item: OptionsSelectSearchBy): boolean => {
    return item && !isEmpty(item) && item.categoryId !== null;
  };
  const isEntityNameLoaded = (entityName: string): boolean => {
    return !isEqual(GroupEnum.OWNER, entityName) && !isEmpty(entityName);
  };

  const onItemSelectHierarchy = (item: OptionsSelectSearchBy): void => {
    const merchantIdValue: string = get(item, "secondaryText", "");
    const entityName: string = defaultTo(
      get(item, "categoryId", ""),
      ""
    ).toUpperCase();
    setValue("hierarchy", item);
    if (isItemDataLoaded(item)) {
      props.setNodesChange(
        filterSelectMerchantHierarchyByCountry(
          item,
          groupHierarchies[entityName]
        )
      );

      if (props.setIsItemSelectedWithHierarchy) {
        props.setIsItemSelectedWithHierarchy(true);
      }

      setMerchantId(merchantIdValue);
    }

    if (isItemDataLoaded(item) && isEntityNameLoaded(entityName)) {
      dispatch(getProcessors(merchantIdValue));
    }

    if (
      isItemDataLoaded(item) &&
      isEntityNameLoaded(entityName) &&
      !isFormEditMode(idValue)
    ) {
      dispatch(
        getHierarchyMerchantCredential(
          merchantIdValue,
          entityName.toLowerCase()
        )
      );
    }

    if (isItemDataLoaded(item) && isWhiteList && !isEmpty(merchantIdValue)) {
      dispatch(
        getSecurityRulesByType(FieldsRule.WHITE_LIST, {
          merchantId: merchantIdValue,
          limit: 20,
          lastEvaluatedKey: null,
        })
      );
    }

    if (isItemDataLoaded(item))
      dispatch(
        getMerchantInformation(
          {
            merchant_id: merchantIdValue,
            limit: 20,
            offset: 0,
          },
          "get"
        )
      );
  };

  useEffect(() => {
    setShowSearchingTextMessage(false);
    const merchantData = get(merchant_info, "data", []).find(
      (dataItem: MerchantProperties) =>
        get(dataItem, "_source.merchantId", "") === merchantId
    );

    if (!isUndefined(ruleDetails)) {
      dispatch(setRuleDetails(ruleDetails));
    } else {
      dispatch(
        setRuleDetails({
          service: "",
          merchantId: merchantId,
          subType: SubTypeEnum.COMMERCE,
          merchantName: "",
          country: [get(merchantData, "_source.country", "")],
        })
      );
    }
  }, [merchant_info]);

  const doMerchantSearch = (value: string) => {
    if (value.length > 1) {
      setFind(value);
      setShowSearchingTextMessage(true);
    } else setValue("hierarchy", undefined);

    if (isEmpty(value) && !isFormEditMode(idValue)) {
      setListMerchantsItems([]);
      if (props.setIsItemSelectedWithHierarchy) {
        props.setIsItemSelectedWithHierarchy(false);
      }
    }
  };
  const searchHierarchy = (value: string): void => {
    clearTimeout(debounceTimerReference.current);

    debounceTimerReference.current = setTimeout(() => {
      doMerchantSearch(value);
    }, TIMEOUT_VALUE);
  };

  const filterSelectMerchantHierarchyByCountry = (
    value: OptionsSelectSearchBy,
    path: string
  ): Nodes | undefined => {
    const dataOwnerNodes: Nodes[] = get(merchant, path);

    if (isUndefined(dataOwnerNodes)) return undefined;
    if (dataOwnerNodes.length < 2) return dataOwnerNodes[0];

    return dataOwnerNodes.find(
      (node: Nodes) => node.merchantId === value.secondaryText
    );
  };

  const namesHierarchies = (): Map<string, string> => {
    const nameHierarchies: Map<string, string> = new Map<string, string>();

    groupHierarchiesArray.map((group: string) => {
      nameHierarchies.set(group, groupHierarchies[group]);
    });

    return nameHierarchies;
  };

  useEffect(() => {
    if (!isFormEditMode(idValue)) setFind("");
  }, [load]);

  useEffect(() => {
    if (!isFormEditMode(idValue) && find && find.length > 1) {
      dispatch(getMerchantHierarchy(find));
    } else {
      setLoad(false);
    }
  }, [find, isUpdatedRule]);

  useEffect(() => {
    if (!isUndefined(merchant) && !isEmptyObject(merchant) && hierarchy) {
      const categoryData: CategoryData[] = [];

      namesHierarchies().forEach((value: string, key: string) => {
        const merchantHierarchy: Nodes[] = get(merchant, value);
        if (merchantHierarchy) {
          const categoryDataItems: ItemsCategoryData[] = [];
          merchantHierarchy.forEach((merchant: Nodes) => {
            categoryDataItems.push({
              id: key,
              secondaryText: get(merchant, "merchantId", ""),
              text: merchant.name,
            });
          });
          categoryData.push({
            categoryId: key,
            categoryName: key,
            items: categoryDataItems,
          });
        }
      });

      setListMerchantsItems(categoryData);
      if (isUpdatedRule) {
        onItemSelectHierarchy(hierarchy!);
      }
      setLoad(true);
    } else {
      setLoad(false);
    }
  }, [merchant, isUpdatedRule]);

  useEffect(() => {
    if (!isUndefined(searchRequestRuleById) && isFormEditMode(idValue)) {
      const categoryData: CategoryData[] = [];
      const categoryDataItems: ItemsCategoryData[] = [];

      categoryDataItems.push({
        id: searchRequestRuleById.entityName,
        text: searchRequestRuleById.merchantName,
        secondaryText: searchRequestRuleById.merchantId,
      });

      categoryData.push({
        categoryName: searchRequestRuleById.entityName,
        categoryId: searchRequestRuleById.entityName,
        items: categoryDataItems,
      });

      const item: OptionsSelectSearchBy = {
        ...categoryDataItems[0],
        categoryName: searchRequestRuleById.entityName,
        categoryId: searchRequestRuleById.entityName,
      };

      setDefaultValueSelect(item);
      setValue("hierarchy", item);
      onItemSelectHierarchy(item);
      setListMerchantsItems(categoryData);

      const merchantId: string = get(searchRequestRuleById, "merchantId");

      dispatch(getMerchantHierarchy(merchantId));
    }
  }, [searchRequestRuleById, idValue]);

  useEffect(() => {
    if (searchRuleById && isFormEditMode(idValue)) {
      const merchantId: string = get(searchRuleById, "rule.merchantId");

      dispatch(getMerchantHierarchy(merchantId));
    }
  }, [searchRuleById, idValue]);

  useEffect(() => {
    if (searchRuleById && !isUndefined(merchant) && isFormEditMode(idValue)) {
      namesHierarchies().forEach((value: string) => {
        const merchantHierarchy: Nodes[] = get(merchant, value, []);
        const foundMerchantNode: Nodes | undefined = merchantHierarchy.find(
          (item: Nodes) =>
            get(item, "merchantId", "") ===
            get(searchRuleById, "rule.merchantId")
        );
        if (
          merchantHierarchy &&
          merchantHierarchy.length > 0 &&
          !isUndefined(foundMerchantNode)
        ) {
          const categoryData: CategoryData[] = [];
          const categoryDataItems: ItemsCategoryData[] = [];

          categoryDataItems.push({
            id: foundMerchantNode.entityName,
            text: foundMerchantNode.name,
            secondaryText: foundMerchantNode.merchantId,
          });

          categoryData.push({
            categoryName: foundMerchantNode.entityName,
            categoryId: foundMerchantNode.entityName,
            items: categoryDataItems,
          });

          const item: OptionsSelectSearchBy = {
            ...categoryDataItems[0],
            categoryName: foundMerchantNode.entityName,
            categoryId: foundMerchantNode.entityName,
          };

          setDefaultValueSelect(item);
          setValue("hierarchy", item);
          onItemSelectHierarchy(item);
          setIsCreationRule(false);
        }
      });
    }
    if (isEmpty(searchRequestRuleById)) setShowSearchingTextMessage(false);
  }, [merchant, searchRuleById, idValue]);

  useEffect(() => {
    if (
      !isEmpty(merchant) &&
      !isUndefined(ruleDetails) &&
      status != StatusEnum.REJECTED
    ) {
      setIsCreationRule(true);
      const categoryDataList: CategoryData[] = [];
      namesHierarchies().forEach((value: string, key: string) => {
        const merchantHierarchy: Nodes[] = get(merchant, value);
        if (merchantHierarchy) {
          const categoryDataItems: ItemsCategoryData[] = [];
          merchantHierarchy.forEach((merchant: Nodes) => {
            categoryDataItems.push({
              id: key,
              secondaryText: get(merchant, "merchantId", ""),
              text: merchant.name,
            });
          });
          categoryDataList.push({
            categoryId: key,
            categoryName: key,
            items: categoryDataItems,
          });
        }
      });

      const categoryData: CategoryData[] = [];
      const categoryDataItems: ItemsCategoryData[] = [];

      categoryDataItems.push({
        id: get(ruleDetails, "entityName", ""),
        text: get(ruleDetails, "merchantName", ""),
        secondaryText: get(ruleDetails, "merchantId", ""),
      });

      categoryData.push({
        categoryName: get(ruleDetails, "entityName", ""),
        categoryId: get(ruleDetails, "entityName", ""),
        items: categoryDataItems,
      });

      const item: OptionsSelectSearchBy = {
        ...categoryDataItems[0],
        categoryName: get(ruleDetails, "entityName", ""),
        categoryId: get(ruleDetails, "entityName", ""),
      };

      setDefaultValueSelect(item);
      setValue("hierarchy", item);
      onItemSelectHierarchy(item);
      setListMerchantsItems(categoryDataList);
    }
  }, []);

  return {
    control,
    errors,
    hierarchy,
    isUpdatedRule,
    listMerchantsItems,
    isWhiteList,
    actions: {
      onItemSelectHierarchy,
      searchHierarchy,
    },
    defaultValueSelect,
    id: idValue,
    isCreationRule,
    showSearchingTextMessage,
  };
};
