import React from "react";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import {
  ButtonTextEnum,
  SecurityRulesRoles,
} from "../../shared/constants/processorConstants";
import { actionButtonStyles } from "./ActionButtons.styles";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

export interface ActionButtonsProps {
  disabled: boolean;
  saveButtonText: string;
  handleSave: () => void;
  onReturn: () => void;
}

export const ActionButtons: React.FC<ActionButtonsProps> = (
  props: ActionButtonsProps
) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-end"
          direction={"row"}
        >
          <Box mr={3}>
            <Button
              size="large"
              disabled={props.disabled}
              onClick={props.onReturn}
            >
              <Typography sx={actionButtonStyles.returnButtonText}>
                {ButtonTextEnum.RETURN_BUTTON}
              </Typography>
            </Button>
          </Box>
          <Box>
            <ComponentSecurityWrapper
              componentId={SecurityRulesRoles.PROCESSOR_ADD_PROCESSOR}
            >
              <Button
                disabled={props.disabled}
                size="large"
                fullWidth
                variant="contained"
                onClick={props.handleSave}
              >
                <Typography sx={actionButtonStyles.saveButtonText}>
                  {props.saveButtonText}
                </Typography>
                {props.disabled && (
                  <Box ml={1} display="flex" alignItems="center">
                    <CircularProgress color="inherit" size={20} />
                  </Box>
                )}
              </Button>
            </ComponentSecurityWrapper>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
