export const SECTION_SELECT_INITIAL_MESSAGE =
  "Selecciona las secciones que deseas editar";

export enum ConditionsEditionEnum {
  SUBTITLE = "Para poder llevar a cabo la edición de forma masiva, será necesario considerar las siguientes condiciones en los branches:",
  PLEASE_REVIEW = "Favor de validar tu selección",
}

export const conditionsEditList: string[] = [
  "Deberán tener la misma sección descentralizada",
  "Deberán corresponder al mismo país de constitución",
];
