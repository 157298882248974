import React, { FC } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useAttachFile } from "./hook/useAttachFile";
import { ModalContentBase } from "@kushki/connect-ui/dist/Components/Molecules/Modal/ModalContentBase/ModalContentBase";
import { AttachFileProps } from "./AttachFile.interfaces";
import { ConditionLabels } from "../../shared/infrastructure/enums/ConditionEnum";
import { IconFileText } from "@kushki/connect-ui";
import { DeleteOutlined, InfoOutlined } from "@mui/icons-material";
import { ItemFile } from "@kushki/connect-ui/dist/Components/Atoms/Form/ItemFile";
import { attachFileStyles } from "./AttachFile.styles";

export const AttachFile: FC<AttachFileProps> = (props: AttachFileProps) => {
  const {
    deleteFile,
    errorMessage,
    getFileExtension,
    getFileNameWithoutExtension,
    handleFileChange,
    isLoading,
    openModal,
    renderComponent,
    selectedFile,
    selectFile,
    toggleModal,
    uploadInputRef,
    validate,
  } = useAttachFile(props);

  return (
    <React.Fragment>
      {renderComponent && (
        <IconButton
          disabled={props.disabled}
          onClick={() => {
            toggleModal(true);
          }}
          sx={attachFileStyles.attachIcon}
          size={"small"}
        >
          <AddIcon sx={attachFileStyles.addIcon} />
        </IconButton>
      )}
      {openModal && (
        <ModalContentBase
          onClose={() => {
            toggleModal(false);
          }}
          open={openModal}
          buttonPrimaryText={"Validar"}
          buttonPrimaryDisabled={!selectedFile || isLoading}
          onButtonPrimaryClick={validate}
        >
          <Box sx={attachFileStyles.modalContainer}>
            <Typography variant={"h5"} id={"labelTitle"}>
              Listado de {ConditionLabels[props.label]}
            </Typography>
            <Box sx={attachFileStyles.captionInfo}>
              <Typography color="text.grey" variant="caption">
                <InfoOutlined />
              </Typography>
              <Typography color="text.grey" variant="caption">
                Formato permitido .csv
              </Typography>
            </Box>
            <Button
              variant={"outlined"}
              color={"secondary"}
              onClick={selectFile}
              disabled={Boolean(selectedFile)}
            >
              Seleccionar archivo
              <input
                type="file"
                onChange={handleFileChange}
                hidden
                ref={uploadInputRef}
              />
            </Button>
            {errorMessage === "" && (
              <Typography color="text.grey" variant="caption">
                Haz clic en “Seleccionar” para buscar el documento en tu
                dispositivo.
              </Typography>
            )}
            {errorMessage !== "" && (
              <Typography color="error" variant="caption" id={"errorMessage"}>
                {errorMessage}
              </Typography>
            )}
            {selectedFile && (
              <Box sx={attachFileStyles.fileInformation}>
                <ItemFile
                  format={getFileExtension(selectedFile)}
                  icon={<IconFileText />}
                  text={getFileNameWithoutExtension(selectedFile)}
                />
                <IconButton onClick={deleteFile} id={"deleteFileBtn"}>
                  <DeleteOutlined color={"error"} />
                </IconButton>
              </Box>
            )}
          </Box>
        </ModalContentBase>
      )}
    </React.Fragment>
  );
};
