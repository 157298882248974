import * as React from "react";
import { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { UnfoldMore } from "@material-ui/icons";
import { cloneDeep, get, isEmpty, isEqual } from "lodash";
import { TableCellCustom } from "../TableCell/TableCellCustom";
import { IColumnDefinition } from "../../../shared/infrastructure/interfaces/IColumnDefinition";
import { IDefault } from "../../../shared/infrastructure/interfaces/IDefault";
import { SkeletonTable } from "../../Skeleton/SkeletonTable";
import { OrderEnum } from "../../../shared/infrastructure/enum/OrderEnum";
import {
  ChipStatusEnum,
  StatusEnum,
} from "../../../shared/infrastructure/StatusEnum";
import { CellTypeEnum } from "../../../shared/infrastructure/enum/CellTypeEnum";
import { RetentionFilters } from "../../Filters/RetentionFilters/RetentionFilters";
import { EmptyList } from "../../EmptyList/EmptyList";
import { StatusFlowEnum } from "../../../shared/infrastructure/StatusFlowEnum";
import { IRetentionFilter } from "../../RetentionDashboard/state/useRetentionDashboardState";
import { ModalOverlay } from "@kushki/frontend-molecules/modal";
import { ModalHeader } from "@kushki/frontend-molecules/modal-header";
import { TransactionTable } from "../../../../types/transaction_table";
import { ModalStyles } from "../ModalCard/ModalStyles";
import ModalBodyRetention from "../../ModalBodyInfo/ModalBodyRetention";
import { translate } from "../../../shared/infrastructure/LanguageCatatog";
import {
  fixedColumnsRetention,
  fixedColumnsRetentionColombia,
} from "../../../shared/infrastructure/table/IColumnsTable";
import { CountryList } from "../../DashboardList/Table/DashboardBillingTable/DashboardTableRow/DashboardTableRow";
import { CountryEnum } from "../../../shared/infrastructure/CountryEnum";
import { DateRange } from "@material-ui/pickers";
import ModalBodyRetentionColombia from "../../ModalBodyInfo/ModalBodyRetentionColombia";
import { InvoiceRecord } from "../../../../types/remote/response_search_blling_by_filters";
import { FooterEnum } from "../../../shared/infrastructure/FooterEnum";
import {
  BackgroundColorRetentionStatus,
  ColorRetentionStatus,
} from "../../../shared/infrastructure/Colors";
import { TransactionFieldsEnum } from "../../../shared/infrastructure/TransactionFieldsEnum";
import { IStepRetentionTimeLine } from "../../../shared/infrastructure/interfaces/IStepRetentionTimeLine";

export interface DataTableCustomProps {
  country: CountryList;
  columns: IColumnDefinition[];
  records: IDefault[];
  handleChangeCheckbox: (
    type: CellTypeEnum,
    status: string,
    row?: object
  ) => void;
  headerBg?: string;
  skeleton?: boolean;
  queryInput: string;
  handleInputChange: (value: string) => void;
  handleSearchBar: (value: string) => void;
  statusFlow?: StatusFlowEnum;
  columnsSettings: {
    selectedColumns: string[];
    setSelectedColumns: (data: string[]) => void;
    restoreRetention: () => void;
  };
  filter: IRetentionFilter;
  modalOverRetention: {
    openModalRetention: boolean;
    handlerCloseModalTrxRetention: () => void;
    handlerSelectTrxRetention: (
      row: TransactionTable | undefined,
      index: number
    ) => void;
    trxRetention: { trx: TransactionTable | undefined; index: number };
  };
  modalOverProcessRetention: {
    openProcessRetentionModal: boolean;
    handleCloseProcessRetentionsModal: () => void;
    handleAcceptProcessTransactions: () => void;
    handleProcessRetentionData: () => void;
    handleCloseProcessLoader: () => void;
    retentionsDataCounter: { counter: number; total: number };
    processLoader: boolean;
  };
  isProcessData: boolean;
  downloadFileRetention: {
    isLoading: boolean;
    downloadFile: () => void;
  };
  handleSetDate?: (dateRange: DateRange) => void;
  rangeDate?: DateRange;
  openModalModifyInconsistence?: boolean;
  handleModalModifyInconsistence?: (value: boolean) => void;
  modalOverDetail: {
    handleOpenDialogDetail?: (data: IStepRetentionTimeLine) => void;
    handleCloseDialogDetail?: () => void;
  };
}

const getValue = (trx: DataTableCustomProps, value: string) =>
  get(trx, `modalOverRetention.trxRetention.trx.${value}`);

export const getRowBackColor = (status: string) => {
  switch (status) {
    case StatusEnum.PENDING:
      return "rgba(255, 223, 229, 0.3)";
    default:
      return "";
  }
};

export const getRowBackColorStatus = (fila: IDefault) => {
  const status: string = get(fila, TransactionFieldsEnum.status, "");
  const modify: object = get(fila, TransactionFieldsEnum.modify, []);
  if (status === StatusEnum.REJECTED) return "#F6AEBB";
  if (status === StatusEnum.PRE_PROCESSED || StatusEnum.PENDING) {
    if (validateDifferentAmountValues(fila))
      return BackgroundColorRetentionStatus.inconsistenceColor;
    if (!isEmpty(modify)) return BackgroundColorRetentionStatus.modifyColor;
  }
  return BackgroundColorRetentionStatus.defaultColor;
};

export const textColorStatus = (fila: IDefault) => {
  const status: string = get(fila, TransactionFieldsEnum.status, "");
  const modify: object = get(fila, TransactionFieldsEnum.modify, []);
  if (status === StatusEnum.PENDING) {
    if (validateDifferentAmountValues(fila))
      return ColorRetentionStatus.inconsistenceColor;
    if (!isEmpty(modify)) return ColorRetentionStatus.modifyColor;
  }
  if (status === StatusEnum.REJECTED) {
    return ColorRetentionStatus.rejectedColor;
  }
  return BackgroundColorRetentionStatus.defaultColor;
};
export const getRowBackTextStatus = (fila: IDefault) => {
  const status: string = get(fila, TransactionFieldsEnum.status, "");
  const modify = get(fila, TransactionFieldsEnum.modify, []);
  if (
    [
      StatusEnum.PENDING,
      StatusEnum.REJECTED,
      StatusEnum.PRE_PROCESSED,
    ].includes(status as StatusEnum)
  ) {
    if (validateDifferentAmountValues(fila))
      return ChipStatusEnum.INCONSISTENCY;
    if (!isEmpty(modify) && status !== StatusEnum.REJECTED)
      return ChipStatusEnum.MODIFY;
    if (status == StatusEnum.REJECTED) return ChipStatusEnum.REJECTED;
  }
  return ChipStatusEnum.NONE;
};

const validateDifferentAmountValues = (fila: IDefault) => {
  const amountRetIva: string = get(
    fila,
    TransactionFieldsEnum.amountRetIva,
    ""
  );
  const amountRetIvaDavi: string = get(
    fila,
    TransactionFieldsEnum.amountRetIvaDavi,
    ""
  );
  const amountRetIcaDavi: string = get(
    fila,
    TransactionFieldsEnum.amountRetIcaDavi,
    ""
  );
  const amountRetIca: string = get(
    fila,
    TransactionFieldsEnum.amountRetIca,
    ""
  );
  const amountRetSourceDavi: string = get(
    fila,
    TransactionFieldsEnum.amountRetSourceDavi,
    ""
  );
  const amountRetSource: string = get(
    fila,
    TransactionFieldsEnum.amountRetSource,
    ""
  );
  const baseAmountIvaDavi: string = get(
    fila,
    TransactionFieldsEnum.baseAmountIvaDavi,
    ""
  );
  const baseAmountIva: string = get(
    fila,
    TransactionFieldsEnum.baseAmountIva,
    ""
  );
  const baseAmountIcaDavi: string = get(
    fila,
    TransactionFieldsEnum.baseAmountIcaDavi,
    ""
  );
  const baseAmountIca: string = get(
    fila,
    TransactionFieldsEnum.baseAmountIca,
    ""
  );

  if (
    !isEqual(amountRetIva, amountRetIvaDavi) ||
    !isEqual(amountRetIcaDavi, amountRetIca) ||
    !isEqual(amountRetSourceDavi, amountRetSource) ||
    !isEqual(baseAmountIvaDavi, baseAmountIva) ||
    !isEqual(baseAmountIcaDavi, baseAmountIca)
  )
    return true;
  return false;
};

const getChipText = (trx: InvoiceRecord) => {
  const status = get(trx, "status", "");
  return [StatusEnum.REJECTED].includes(status)
    ? translate(status)
    : getRowBackTextStatus(trx);
};

export const footerText = (trxInfo: InvoiceRecord): string => {
  const statusText: string = getRowBackTextStatus(trxInfo);
  switch (statusText) {
    case ChipStatusEnum.MODIFY:
    case ChipStatusEnum.INCONSISTENCY:
    case ChipStatusEnum.REJECTED:
      return FooterEnum.retentionPendingContent;
    default:
      return FooterEnum.retentionDefaultContent;
  }
};

export const DataTableCustom: React.FC<DataTableCustomProps> = (
  props: DataTableCustomProps
) => {
  const classes = ModalStyles();
  const {
    columns,
    records,
    headerBg,
    handleChangeCheckbox,
    skeleton,
    handleInputChange,
    queryInput,
    handleSearchBar,
    statusFlow,
    modalOverProcessRetention,
    columnsSettings,
    downloadFileRetention,
    openModalModifyInconsistence,
    handleModalModifyInconsistence,
  } = props;

  const [columnsNew, setColumnsNew] = useState(columns);

  useEffect(() => {
    setColumnsNew(columns);
  }, [columns]);

  const applyColumnFilters = (restore?: boolean) => {
    if (restore) {
      setColumnsNew(columns);
      props.columnsSettings.restoreRetention();
    } else {
      let aux_columns: IColumnDefinition[] = cloneDeep(columnsNew);
      aux_columns = aux_columns.map((itemCo) => {
        return {
          ...itemCo,
          view:
            props.columnsSettings.selectedColumns.includes(itemCo.field) ||
            props.country === CountryEnum.colombia
              ? fixedColumnsRetentionColombia.includes(itemCo.field)
              : fixedColumnsRetention.includes(itemCo.field),
        };
      });
      setColumnsNew(aux_columns);
    }
  };

  const hideChipList = (statusTrx: StatusEnum) =>
    [CountryEnum.colombia].includes(props.country as CountryEnum) &&
    statusFlow === StatusFlowEnum.VALIDATOR &&
    statusTrx === StatusEnum.PRE_PROCESSED;

  const isValidatorProps = (statusTran: string): boolean =>
    statusFlow === StatusFlowEnum.VALIDATOR &&
    [StatusEnum.PRE_PROCESSED, StatusEnum.COMPLETED].includes(
      statusTran as StatusEnum
    );

  const isExecutorProps = (statusTran: string): boolean =>
    statusFlow === StatusFlowEnum.EXECUTOR &&
    [StatusEnum.PENDING, StatusEnum.COMPLETED, StatusEnum.REJECTED].includes(
      statusTran as StatusEnum
    );

  const showModalBody = (statusTran: string) => {
    return (
      [CountryEnum.colombia].includes(props.country as CountryEnum) &&
      (isValidatorProps(statusTran) || isExecutorProps(statusTran))
    );
  };

  const getChipListColombiaModifyInconsistency = () => {
    const trx = get(props, "modalOverRetention.trxRetention.trx");
    if (!trx) return;
    const chipStatus = getChipText(trx);
    if (chipStatus === ChipStatusEnum.NONE) return;
    const background =
      chipStatus === ChipStatusEnum.MODIFY
        ? BackgroundColorRetentionStatus.modifyColor
        : BackgroundColorRetentionStatus.inconsistenceColor;
    const color = ChipStatusEnum.MODIFY
      ? ColorRetentionStatus.modifyColor
      : ColorRetentionStatus.inconsistenceColor;
    return [
      {
        background,
        color,
        value: chipStatus,
      },
    ];
  };

  return (
    <TableContainer>
      {skeleton && <SkeletonTable />}
      {!skeleton && (
        <>
          <RetentionFilters
            queryInput={queryInput}
            handleInputChange={handleInputChange}
            handleSearchBar={handleSearchBar}
            statusFlow={statusFlow}
            filter={props.filter}
            retentionData={records}
            modalOverProcessRetention={modalOverProcessRetention}
            columnsSettings={{
              ...columnsSettings,
              handleAppliedColumnsFilters: applyColumnFilters,
            }}
            columns={columnsNew}
            downloadFileRetention={downloadFileRetention}
            hasDateRange={props.country === CountryEnum.colombia}
            range={props.rangeDate}
            handleSetDate={props.handleSetDate}
            country={props.country}
          />
          {records.length > 0 ? (
            <Table aria-label="simple table">
              <TableHead>
                <TableRow key="header">
                  {columnsNew
                    .filter((column) => column.view)
                    .map((itemHeader, index: number) => (
                      <TableCellCustom
                        key={`header_${index}`}
                        itemHeader={itemHeader}
                        headerBg={headerBg}
                        node={
                          itemHeader.checkeable ? (
                            <Box
                              width="100%"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              flexDirection="column"
                            >
                              <>{itemHeader.headerName}</>
                              <Checkbox
                                size={"small"}
                                color={"primary"}
                                onChange={(_e, value: boolean) =>
                                  handleChangeCheckbox(
                                    CellTypeEnum.header,
                                    !!value
                                      ? itemHeader.checkeable!.compare
                                      : ""
                                  )
                                }
                                checked={
                                  records.findIndex(
                                    (rowTrx) =>
                                      get(
                                        rowTrx,
                                        itemHeader.checkeable!.field,
                                        ""
                                      ) !== itemHeader.checkeable?.compare
                                  ) === -1 && records.length > 0
                                }
                              />
                            </Box>
                          ) : itemHeader.sortable ? (
                            <TableSortLabel
                              IconComponent={UnfoldMore}
                              onClick={() =>
                                itemHeader.sortable?.handleSort(
                                  itemHeader.sortable?.order === OrderEnum.asc
                                    ? OrderEnum.desc
                                    : OrderEnum.asc,
                                  itemHeader.field
                                )
                              }
                            >
                              {itemHeader.headerName}
                            </TableSortLabel>
                          ) : (
                            <>{itemHeader.headerName}</>
                          )
                        }
                      />
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {records.map((itemCell, indexCell: number) => (
                  <TableRow
                    style={{
                      background:
                        statusFlow === StatusFlowEnum.EXECUTOR
                          ? props.country === CountryEnum.colombia
                            ? getRowBackColorStatus(itemCell)
                            : getRowBackColor(
                                get(itemCell, "selectedStatus", "")
                              )
                          : getRowBackColorStatus(itemCell),
                    }}
                    key={`cell_${indexCell}`}
                    onClick={(e) => {
                      props.modalOverRetention.handlerSelectTrxRetention(
                        itemCell,
                        indexCell
                      );
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    {columnsNew
                      .filter((column) => column.view)
                      .map((itemColumn, indexColumn: number) => {
                        const selectedStatus: string = get(
                          itemCell,
                          "selectedStatus",
                          ""
                        );
                        const isEdited: boolean = get(
                          itemCell,
                          "isEdited",
                          false
                        );
                        const isEcuador: boolean =
                          get(itemCell, "country") === CountryEnum.ecuador;
                        return (
                          <TableCellCustom
                            key={`row_${indexColumn}`}
                            itemHeader={itemColumn}
                            headerBg={
                              isEdited && isEcuador
                                ? "#FFF1D0"
                                : StatusEnum.REJECT.toString() ===
                                  selectedStatus
                                ? "rgba(255, 223, 229, 0.3)"
                                : "initial"
                            }
                            node={
                              !itemColumn.renderCell &&
                              !itemColumn.checkeable ? (
                                <>{get(itemCell, itemColumn.field, "")}</>
                              ) : itemColumn.checkeable ? (
                                <Box
                                  width="100%"
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  flexDirection="column"
                                >
                                  <Checkbox
                                    size={"small"}
                                    color={"primary"}
                                    onClick={(_e) => {
                                      handleChangeCheckbox(
                                        CellTypeEnum.cell,
                                        !(
                                          get(
                                            itemCell,
                                            itemColumn.checkeable!.field,
                                            ""
                                          ) === itemColumn.checkeable!.compare
                                        )
                                          ? itemColumn.checkeable!.compare
                                          : "",
                                        itemCell
                                      );
                                      _e.preventDefault();
                                      _e.stopPropagation();
                                    }}
                                    checked={
                                      get(
                                        itemCell,
                                        itemColumn.checkeable.field,
                                        ""
                                      ) === itemColumn.checkeable.compare
                                    }
                                  />
                                </Box>
                              ) : (
                                <>
                                  {itemColumn.renderCell!(
                                    itemCell,
                                    get(itemCell, itemColumn.field, "")
                                  )}
                                </>
                              )
                            }
                          />
                        );
                      })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <EmptyList
              hiddenInbox={true}
              searchImg={true}
              isProcessData={props.isProcessData}
            />
          )}
        </>
      )}
      <ModalOverlay
        header={
          <ModalHeader
            className={classes.modalHeader}
            highlightValue={getValue(props, "socialReason")}
            tittle={"Detalle de Retención"}
            secondaryValue={`${translate(getValue(props, "merchantId"))}`}
            subtitle={"Merchant ID"}
            chipList={
              !hideChipList(
                get(props, "modalOverRetention.trxRetention.trx.status", "")
              )
                ? [
                    {
                      background: getRowBackColorStatus(
                        get(props, "modalOverRetention.trxRetention.trx")
                      ),
                      value: getChipText(
                        get(props, "modalOverRetention.trxRetention.trx")
                      ),
                      color: textColorStatus(
                        get(props, "modalOverRetention.trxRetention.trx")
                      ),
                    },
                  ]
                : getChipListColombiaModifyInconsistency()
            }
          />
        }
        body={
          showModalBody(
            get(props, "modalOverRetention.trxRetention.trx.status", "")
          ) ? (
            <ModalBodyRetentionColombia
              trxInfo={props.modalOverRetention.trxRetention.trx}
              statusFlow={statusFlow}
              country={props.country}
              hasFooter={true}
              contentFooter={footerText(
                get(props, "modalOverRetention.trxRetention.trx")
              )}
              handleModalModifyInconsistence={
                isExecutorProps(
                  get(props, "modalOverRetention.trxRetention.trx.status", "")
                )
                  ? handleModalModifyInconsistence
                  : undefined
              }
              openModifyInconsistence={
                isExecutorProps(
                  get(props, "modalOverRetention.trxRetention.trx.status", "")
                )
                  ? openModalModifyInconsistence
                  : undefined
              }
              handleOpenDialogDetail={
                props.modalOverDetail.handleOpenDialogDetail
              }
              handleCloseDialogDetail={
                props.modalOverDetail.handleCloseDialogDetail
              }
              isHistoric={false}
            />
          ) : (
            <ModalBodyRetention
              trxInfo={props.modalOverRetention.trxRetention.trx}
              statusFlow={statusFlow}
              country={props.country}
            />
          )
        }
        footer={<Box height={"5%"} bgcolor={"#F7FAFC"} />}
        openModal={props.modalOverRetention.openModalRetention}
        onCloseModal={props.modalOverRetention.handlerCloseModalTrxRetention}
        transition="left"
      />
    </TableContainer>
  );
};
