import React, { FC } from "react";
import { Box, Skeleton, TableCell, TableRow } from "@mui/material";
import { ITableSkeletonProps } from "../../../../shared/interfaces/Table.interfaces";
import TableSkeletonStyles from "./TableSkeleton.styles";

const TableSkeleton: FC<ITableSkeletonProps> = (props: ITableSkeletonProps) => {
  const { columns, times } = props;

  return (
    <>
      {[...Array(times)].map((_, index) => (
        <TableRow aria-label={"skeletonRow" + index} key={index}>
          {columns.map((column) => (
            <TableCell key={column.id} padding={column.padding}>
              {column.type === "checkbox" ? (
                <Box sx={TableSkeletonStyles.checkboxSkeleton}>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={18}
                    width={18}
                  />
                </Box>
              ) : (
                <Skeleton animation="wave" />
              )}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default TableSkeleton;
