import { Box } from "@mui/material";
import React from "react";

export type IVerticalSpace = {
  selected: boolean;
  width: number;
};

export const VerticalSpace: React.FC<IVerticalSpace> = ({
  selected,
  width,
}: IVerticalSpace) => {
  const color = selected ? "#4498EE" : "#E2E8F0";

  return (
    <Box
      sx={{
        background: "transparent",
        borderRight: `1px solid ${color}`,
        height: 32,
        width: width,
      }}
    />
  );
};
