/* istanbul ignore file */
import React, { useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { VerificationResponse } from "../../../types/verification_code_response";
import { ConnectionRequest } from "../../../types/connection_request";
import { defaultTo, get } from "lodash";

export interface IAssistance {
  title: string;
  image: string;
  isMedium: boolean;
  loading: boolean;
  loadingConnection: boolean;
  handlerVerifyAccessCode?: (accessCode: string) => void;
  handlerConnectSupport?(body: ConnectionRequest): void;
  merchantAssistance: VerificationResponse;
}

const useStyles = makeStyles((theme: Theme) => ({
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  textError: {
    color: theme.palette.error.main,
  },
  inputAccess: {
    "& .MuiInputBase-formControl": {
      width: "300px",
      height: "auto",
    },
  },
}));

export const CardAssistance = (props: IAssistance) => {
  const classes = useStyles();
  const [accessCode, setAccessCode] = useState<string>("");
  const [errorInputAccessCode, setErrorInputAccessCode] = useState<boolean>(
    false
  );
  const onVerifyAccessCode = () => {
    if (accessCode.length === 0) {
      setErrorInputAccessCode(true);

      return;
    }

    setErrorInputAccessCode(false);
    if (props.handlerVerifyAccessCode)
      props.handlerVerifyAccessCode(accessCode);
  };
  const onConnect = () => {
    props.handlerConnectSupport?.({
      accessCode: props.merchantAssistance.accessCode,
      userAgent: defaultTo(localStorage.getItem("username"), ""),
      userAgentFullName: defaultTo(localStorage.getItem("fullName"), ""),
    });
  };
  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccessCode(event.target.value);
  };

  return (
    <React.Fragment>
      {props.loadingConnection ? (
        <Paper
          style={{
            padding: "100px",
            textAlign: "center",
          }}
        >
          <CircularProgress size={40} />
        </Paper>
      ) : (
        <Paper>
          <Box display="flex" alignItems="center" p={1}>
            <Box p={1}>
              <Avatar className={classes.large} src={props.image} />
            </Box>
            <Box p={1} flexGrow={1}>
              <Typography variant="h5" color={"primary"}>
                {props.title}
              </Typography>
            </Box>
          </Box>
          <Grid
            container
            spacing={4}
            style={{ paddingLeft: props.isMedium ? 80 : 10 }}
          >
            <Grid item xs={12}>
              <Typography variant="body2" style={{ marginBottom: 20 }}>
                Brinda asistencia al comercio ingresando a la vista de su
                Consola. Esta opción está disponible en modo solo lectura.
              </Typography>
              <Typography variant="body2" style={{ marginBottom: 20 }}>
                Ingresa el código de acceso que te ha brindado el comercio.
              </Typography>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="center"
                  m={1}
                  p={1}
                  bgcolor="background.paper"
                >
                  <TextField
                    id="accesoCode"
                    label="Código de acceso"
                    variant="outlined"
                    size="small"
                    value={accessCode}
                    className={classes.inputAccess}
                    error={errorInputAccessCode}
                    onChange={onChangeInput}
                  />
                </Box>
              </Grid>
              {!props.merchantAssistance.isVerify &&
              props.merchantAssistance.merchantName &&
              props.merchantAssistance.username ? (
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography
                    variant="body2"
                    style={{ marginBottom: 20 }}
                    className={classes.textError}
                  >
                    {get(
                      props.merchantAssistance,
                      "message",
                      "El código ingresado es incorrecto. Verifica e intenta nuevamente."
                    )}
                  </Typography>
                </Grid>
              ) : null}

              {props.merchantAssistance.isVerify &&
              props.merchantAssistance.merchantName &&
              props.merchantAssistance.username ? (
                <React.Fragment>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={5}>
                      <Typography variant={"h6"} color={"primary"}>
                        Comercio:{" "}
                        <span style={{ color: "#6D7781" }}>
                          {props.merchantAssistance.merchantName}
                        </span>
                      </Typography>
                      <br />
                      <Typography variant={"h6"} color={"primary"}>
                        Usuario:{" "}
                        <span style={{ color: "#6D7781" }}>
                          {props.merchantAssistance.username}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      m={1}
                      p={1}
                      bgcolor="background.paper"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={props.loading}
                        onClick={onConnect}
                      >
                        Conectar{" "}
                        {props.loading && <CircularProgress size={16} />}
                      </Button>
                    </Box>
                  </Grid>
                </React.Fragment>
              ) : (
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    m={1}
                    p={1}
                    bgcolor="background.paper"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={props.loading}
                      onClick={onVerifyAccessCode}
                    >
                      Continuar {"  "}
                      {props.loading ? <CircularProgress size={16} /> : null}
                    </Button>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Paper>
      )}
    </React.Fragment>
  );
};
