import {
  createStyles,
  makeStyles,
  Tooltip,
  withStyles,
} from "@material-ui/core";

const useStyles = makeStyles({
  editIcon: {
    color: "#113361",
    width: "16px",
    height: "16px",
  },
  trashIcon: {
    color: "#c53d4f",
    width: "16px",
    height: "16px",
  },
  TableCell: {
    padding: "9px 16px",
    maxWidth: "200px",
  },
  TableCellTaxpayer: {
    padding: "9px 16px",
    maxWidth: "250px",
  },
  DateTooltip: {
    display: "block",
    fontWeight: "bold",
  },
});

const StyledTooltip = withStyles(
  createStyles({
    tooltip: {
      backgroundColor: "#6a7682",
      padding: "10px 15px",
      fontSize: "14px",
      maxWidth: "375px",
    },
  })
)(Tooltip);

const DateTooltip = withStyles(
  createStyles({
    tooltip: {
      backgroundColor: "#6a7682",
      padding: "4px 15px",
      fontSize: "14px",
      maxWidth: "200px",
    },
  })
)(Tooltip);

export { useStyles, StyledTooltip, DateTooltip };
