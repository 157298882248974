import { format, parse } from "date-fns";

export const getDate = (timeStamp: number): string => {
  return format(timeStamp, "yyyy-MM-dd");
};
export const getHour = (timeStamp: number): string => {
  return format(timeStamp, "HH:mm:ss");
};

export const parseToEnUSDate = (date: string): string => {
  const parsedDateTime = parse(date, "dd/MM/yyyy HH:mm", new Date());

  return format(parsedDateTime, "yyyy-MM-dd'T'HH:mm");
};

export const parseToEsDate = (date: string) => {
  const parsedDateTime = parse(date, "yyyy-MM-dd'T'HH:mm:ss", new Date());

  return {
    date: format(parsedDateTime, "dd/MM/yyyy"),
    hour: format(parsedDateTime, "HH:mm"),
  };
};
