/* istanbul ignore file */
import { format, set, sub } from "date-fns";

import { IUserData } from "../../components/DashboardTransaction/state/useDashboardTransaction.interfaces";
import { TransactionsRequest } from "../../../types/transactions_request";
import { getColumnsUser } from "../utils/transaction_list_utils";

export const user_profile: IUserData = getColumnsUser();

export const PAY_OUTS_LIST_FILTERS_INITIAL_STATE: TransactionsRequest = {
  filter: {
    paymentMethod: "",
    transactionStatus: "",
    transactionType: "",
  },
  from: format(
    set(sub(new Date(), { days: 1 }), { hours: 0, minutes: 0, seconds: 0 }),
    "yyyy-MM-dd'T'HH:mm:ss"
  ),
  limit: 10,
  offset: 0,
  publicMerchantId: user_profile.publicMerchantId,
  text: "",
  timeZone: user_profile.timeZone,
  to: format(
    set(new Date(), { hours: 23, minutes: 59, seconds: 59 }),
    "yyyy-MM-dd'T'HH:mm:ss"
  ),
  userType: user_profile.admin ? "admin" : "merchant",
};
