import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { IBranchItem, ISelectedBranch } from "./SelectedBranch.interfaces";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import List from "@mui/material/List";
import { SelectedBranchStyles } from "./SelectedBranch.styles";
import { useSelectedBranch } from "./state/useSelectedBranch";
import { ButtonVariantEnum } from "../../shared/enums/ButtonStylesEnum";
import { SelectedBranchData } from "./SelectedBranch.data";

const SelectedBranch: React.FC<ISelectedBranch> = ({
  isOpen,
  closeBranchModal,
}: ISelectedBranch) => {
  const { totalBranches, filtered, handleFilter } = useSelectedBranch();

  return (
    <>
      <Dialog sx={SelectedBranchStyles.dialogContainerParent} open={isOpen!}>
        <DialogTitle sx={SelectedBranchStyles.dialogTitle}>
          <Grid sx={SelectedBranchStyles.mainHeader}>
            <Typography sx={SelectedBranchStyles.titleSpan}>
              {SelectedBranchData.SELECTED_BRANCHES}
            </Typography>
            <IconButton
              sx={SelectedBranchStyles.closeIcon}
              onClick={closeBranchModal}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid sx={SelectedBranchStyles.subHeader}>
            <Typography variant="caption" color="text.grey">
              {`${SelectedBranchData.TOTAL_BRANCHES} ${totalBranches}`}
            </Typography>
          </Grid>
        </DialogTitle>
        <DialogContent sx={SelectedBranchStyles.dialogContent}>
          <FormControl sx={SelectedBranchStyles.inputField}>
            <TextField
              size="small"
              placeholder={"Buscar por nombre..."}
              variant={ButtonVariantEnum.Outlined}
              onChange={handleFilter}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <Box sx={SelectedBranchStyles.boxItems}>
            {filtered.map((item: IBranchItem, index: number) => (
              <List sx={SelectedBranchStyles.branchItems} key={index}>
                {item.name}
                <Box sx={SelectedBranchStyles.clientTypeText} component="span">
                  {` -  ${item.clientType}`}
                </Box>
              </List>
            ))}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SelectedBranch;
