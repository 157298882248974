import { useState } from "react";
import { DeferredOption } from "../../../../../types/deferred_options";
import { SortDirectionEnum } from "../../../../shared/infrastructure/constants/DeferredConstants";
import { isEqual } from "lodash";

export interface useDeferredTableBrazilResult {
  paginationData: DeferredOption[];
  openMonthsModal: boolean;
  sortDirection: SortDirectionEnum | undefined;
  handlePaginationData: (data: DeferredOption[]) => void;
  handleChangeSortDirection: () => void;
  monthsList: string[] | undefined;
  handleEvents: {
    handleOpenMonthsModal: (months: string[]) => void;
  };
  closeMonthsModal: () => void;
}

export const useDeferredTableBrazil = (): useDeferredTableBrazilResult => {
  const [paginationData, setPaginationData] = useState<DeferredOption[]>([]);
  const [sortDirection, setSortDirection] = useState<
    SortDirectionEnum | undefined
  >(undefined);

  const [monthsList, setMonthsList] = useState<string[] | undefined>(undefined);
  const [openMonthsModal, setOpenMonthsModal] = useState<boolean>(false);

  const handlePaginationData = (data: DeferredOption[]) => {
    setPaginationData(data);
  };
  const handleChangeSortDirection = (): void => {
    setSortDirection(
      isEqual(sortDirection, SortDirectionEnum.ASC)
        ? SortDirectionEnum.DESC
        : SortDirectionEnum.ASC
    );
  };

  const handleOpenMonthsModal = (months: string[]): void => {
    setMonthsList(months);
    setOpenMonthsModal(true);
  };

  const handleCloseMonthsModal = () => {
    setOpenMonthsModal(false);
    setMonthsList(undefined);
  };

  return {
    closeMonthsModal: handleCloseMonthsModal,
    handleChangeSortDirection,
    handleEvents: {
      handleOpenMonthsModal,
    },
    handlePaginationData,
    monthsList,
    openMonthsModal,
    paginationData,
    sortDirection,
  };
};
