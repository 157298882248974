import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../src/shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { prefixes } from "../../../shared/constants/prefixes";

export const getCommercialExecutives = createAsyncThunk(
  prefixes.COMMERCIAL_EXECUTIVES,
  async () => {
    const requestObject: object = {
      isActive: true,
      limit: 100,
      offset: 0,
      strictSearch: false,
    };

    const responseMerchantNodes = await axios.get(
      API_ROUTES.COMMERCIAL_EXECUTIVES,
      requestObject
    );

    return responseMerchantNodes.data;
  }
);

export default getCommercialExecutives;
