import { Box, Button, Chip, Typography } from "@mui/material";
import React, { FC } from "react";
import Expandable from "../Expandable/Expandable";
import useChipState from "../useChipState/useChipState";
import useExpandItems from "../useExpandItems/useExpandItems";
import { FilterProps } from "./Filter.interfaces";
import { filterStyles as styles, textButtonStyle } from "./Filter.styles";

const Filter: FC<FilterProps> = ({
  filters = [],
  onChangeFilters = () => undefined,
  sx,
  ...rest
}: FilterProps) => {
  const { collapseAll, expandAll, expandItem, isAllExpanded, isExpanded } =
    useExpandItems(filters.length);

  const { clearAllChips, handleChipClicked, internalFilters } =
    useChipState(filters);

  const containerStyle = { ...styles.container, ...sx };
  const footerStyle = { ...styles.space, ...styles.footer };

  const handleRemoveFilters = () => {
    const clearedFilters = clearAllChips();

    onChangeFilters(clearedFilters);
  };

  return (
    <Box sx={containerStyle} {...rest}>
      <Box sx={styles.space}>
        <Typography variant="subtitle2" sx={styles.title}>
          Filtrar por
        </Typography>

        <Button
          variant="text"
          sx={textButtonStyle("text.primary")}
          onClick={() => (isAllExpanded ? collapseAll() : expandAll())}
        >
          {isAllExpanded ? "Minimizar todos" : "Expandir todos"}
        </Button>
      </Box>

      {internalFilters.map(({ title, options }, filterIndex) => (
        <Expandable
          data-testid="expandable"
          key={filterIndex}
          title={title}
          expanded={isExpanded[filterIndex]}
          onStateChange={() => expandItem(filterIndex)}
          sx={styles.expandable}
        >
          {options.map(({ label, selected }, optionIndex) => (
            <Chip
              data-testid="chip"
              size="small"
              color={selected ? "success" : "default"}
              onClick={() => handleChipClicked(title, label)}
              key={`${filterIndex}-${optionIndex}`}
              label={label}
              sx={styles.chip}
              aria-label={label}
            />
          ))}
        </Expandable>
      ))}

      <Box sx={footerStyle}>
        <Button
          size="small"
          variant="contained"
          onClick={() => onChangeFilters(internalFilters)}
        >
          Aplicar filtros
        </Button>

        <Button
          variant="text"
          sx={textButtonStyle("neutral.grey6")}
          onClick={handleRemoveFilters}
        >
          Borrar filtros
        </Button>
      </Box>
    </Box>
  );
};

export default Filter;
