import { Box, Typography } from "@mui/material";
import React from "react";
import { IContactInfo } from "./ContactsIndex";
import { ContactItem } from "../ContactItem/ContactItem";

interface IContactCard {
  title: string;
  contacts: IContactInfo[];
}

export const ContactCard: React.FC<IContactCard> = (props: IContactCard) => {
  const { title, contacts } = props;

  return (
    <Box
      sx={{
        alignItems: "start",
        backgroundColor: "neutral.grey3",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "flex-start",
        padding: "24px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "30%",
        }}
      >
        <Typography variant="subtitle2" color="text.dark">
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
        ml={3}
      >
        {contacts.map((contact, index) => (
          <ContactItem key={index} name={contact.name} email={contact.email} />
        ))}
      </Box>
    </Box>
  );
};
