import React from "react";
import { TableInfo as ConnectTable } from "@kushki/connect-ui";
import {
  ITableCellProps,
  ITableRowProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableInfo/interfaces";
import { get, toString } from "lodash";
import { TABLE_ROW } from "../../../shared/constants/table_info";
import { ITableInfo } from "./TableInfo.interfaces";

/**
 * @typedef ITableInfo<T>
 * @prop {ITableCellProps[]} columns - Array of columns to be rendered in the table
 * @prop {ITableRowProps[]} rows - Array of rows to be rendered in the table
 * @prop {(column: string, t: T) => ITableInfoCellValue} onRenderValue - Function to be called when a cell is rendered
 */

/**
 * Custom implementation of TableInfo component from @kushki/connect-ui
  @param {ITableInfo} props - Props to be passed to the component
*/
export const TableInfo = <TableFillData,>({
  columns,
  rows,
  onRenderValue,
  isLoading,
}: ITableInfo<TableFillData>) => {
  const getCells = (row: TableFillData): ITableCellProps[] => {
    return columns.map((column) => {
      const value = get(row, column.key, "");

      const cellProps = onRenderValue
        ? onRenderValue(column.key, row)
        : { line1: toString(value) };

      const tableCell = get(TABLE_ROW, column.cellType);

      return tableCell(cellProps) as ITableCellProps;
    });
  };

  const getRows = (): ITableRowProps[] => {
    return rows.map((row, index) => ({
      cells: getCells(row),
      id: `row_${index}`,
      rowProps: {
        color: "default",
        onClick: () => {},
      },
    }));
  };

  const values = isLoading ? [] : getRows();

  return (
    <ConnectTable
      header={{
        cells: columns,
        headerProps: {
          isDisable: false,
        },
      }}
      rows={values}
      isInitialLoading={isLoading}
    />
  );
};
