export enum MainPrefix {
  ALARM_CONFIG = "alarmConfig",
  ALARMS = "alarms",
  EXECUTIVES = "executives",
  GENERAL = "general",
  NODE_SELECTION = "nodeSelection",
  RULES_ALARM = "rulesAlarm",
}

interface IThunkPrefix {
  actionMassiveAlarm: string;
  alarmActionV2: string;
  createRules: string;
  downloadFileAlarms: string;
  downloadFileExecutives: string;
  getFileComment: string;
  getFileExecutives: string;
  getMerchantsCore: string;
  getRuleAlarm: string;
  getRuleAlarms: string;
  queryMerchants: string;
  searchAlarmByFilters: string;
  searchMerchants: string;
  setAlarmAction: string;
  testNodeSelection: string;
  updateAlarm: string;
  updateRuleStatus: string;
  validateCommercialExecutives: string;
  uploadCommercialExecutive: string;
}

export const thunkPrefix: IThunkPrefix = {
  actionMassiveAlarm: `${MainPrefix.ALARMS}/actionMassiveAlarm`,
  alarmActionV2: `${MainPrefix.ALARMS}/alarmActionV2`,
  createRules: `${MainPrefix.ALARM_CONFIG}/createRules`,
  downloadFileAlarms: `${MainPrefix.ALARMS}/downloadFileAlarms`,
  downloadFileExecutives: `${MainPrefix.EXECUTIVES}/downloadFileExecutives`,
  getFileComment: `${MainPrefix.ALARMS}/getFileComment`,
  getFileExecutives: `${MainPrefix.EXECUTIVES}/getFileExecutives`,
  getMerchantsCore: `${MainPrefix.GENERAL}/getMerchantsCore`,
  getRuleAlarm: `${MainPrefix.ALARM_CONFIG}/getRuleAlarm`,
  getRuleAlarms: `${MainPrefix.ALARM_CONFIG}/getRuleAlarms`,
  queryMerchants: `${MainPrefix.NODE_SELECTION}/queryMerchants`,
  searchAlarmByFilters: `${MainPrefix.ALARMS}/searchAlarmByFilters`,
  searchMerchants: `${MainPrefix.NODE_SELECTION}/searchMerchant`,
  setAlarmAction: `${MainPrefix.ALARMS}/setAlarmAction`,
  testNodeSelection: `${MainPrefix.NODE_SELECTION}/test`,
  updateAlarm: `${MainPrefix.ALARMS}/updateAlarm`,
  updateRuleStatus: `${MainPrefix.RULES_ALARM}/updateRuleStatus`,
  uploadCommercialExecutive: `${MainPrefix.EXECUTIVES}/uploadCommercialExecutive`,
  validateCommercialExecutives: `${MainPrefix.EXECUTIVES}/validateCommercialExecutive`,
};
