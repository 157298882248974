import {
  CENTRO_AMERICA_COUNTRIES,
  CountriesEnum,
} from "../infrastructure/enums/CountriesEnum";
import { includes } from "lodash";
import { ProcessorNameDeferred } from "../../components/Deferred/DeferredList/DeferredResume/DeferredResume.data";

export const CountryProcessor = new Map<CountriesEnum, ProcessorNameDeferred[]>(
  [
    [
      CountriesEnum.ECUADOR,
      [ProcessorNameDeferred.CREDIMATIC, ProcessorNameDeferred.DATAFAST],
    ],
    [
      CountriesEnum.MEXICO,
      [
        ProcessorNameDeferred.BILLPOCKET,
        ProcessorNameDeferred.PROSA,
        ProcessorNameDeferred.KUSHKI_ACQ,
      ],
    ],
    [CountriesEnum.CENTRO_AMERICA, [ProcessorNameDeferred.CREDOMATIC]],
    [CountriesEnum.BRAZIL, [ProcessorNameDeferred.FIS]],
  ]
);

export const getProcessors = (
  country: string
): ProcessorNameDeferred[] | undefined => {
  return CountryProcessor.get(isCentroAmerica(country));
};

const isCentroAmerica = (country: string): CountriesEnum => {
  if (includes(CENTRO_AMERICA_COUNTRIES, country)) {
    return CountriesEnum.CENTRO_AMERICA;
  }
  return <CountriesEnum>country;
};
