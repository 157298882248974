import React, { useState } from "react";
import { Box, Grid, TextField, Typography } from "@material-ui/core";
import InputMask from "react-input-mask";
import Check from "../../assets/images/check.svg";
import Error from "../../assets/images/error.svg";
import { environment } from "../../environments/environment";
import { makeStyles } from "@material-ui/core/styles";

export interface SubdomainProps {
  isPrimary: boolean;
  setSubdomain: (subdomain: string) => void;
  validateSubdomain: (subdomain: string) => void;
  styles: { sectionTitle: string };
  currentUrl?: string;
  error: boolean | null;
}

export const Subdomain: React.FC<SubdomainProps> = (props: SubdomainProps) => {
  const useStyles = makeStyles({
    textTypography: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 14,
      lineHeight: "140%",
      color: "#6D7781",
    },
    errorTypography: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 14,
      lineHeight: "140%",
      color: "#AD0C2A",
      marginTop: 4,
    },
  });
  const classes = useStyles();
  const [subdomainInputValue, setSubdomainInputValue] = useState(
    props.currentUrl
  );

  const subdomainMask: string =
    "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa";
  return (
    <Grid container style={{ paddingBottom: 30 }} spacing={1}>
      <Grid
        item
        xs={12}
        className={props.styles.sectionTitle}
        style={{ paddingTop: 30 }}
      >
        Subdominio
      </Grid>

      <Grid item xs={10} style={{ paddingBottom: 30 }}>
        <Typography className={classes.textTypography}>
          Este subdominio se mostrará a tus clientes cuando realicen pagos a tu
          comercio. Aplica para Smartlinks y Webcheckout. Te sugerimos utilizar
          un nombre que identifique a tu empresa y sea fácil de recordar.
        </Typography>
      </Grid>
      {props.isPrimary ? (
        <Grid item xs={12} md={5}>
          <Box display={"flex"} alignItems={"center"}>
            <InputMask
              mask={subdomainMask}
              maskChar={null}
              formatChars={{
                a: "[a-z0-9]",
              }}
              value={subdomainInputValue}
              onChange={(e) => {
                setSubdomainInputValue(e.target.value);
                props.setSubdomain(e.target.value);
              }}
              onBlur={(e) => props.validateSubdomain(e.target.value)}
            >
              {(inputProps: any) => (
                <TextField
                  {...inputProps}
                  fullWidth
                  variant="outlined"
                  label="Subdominio"
                  error={props.error || subdomainInputValue === ""}
                />
              )}
            </InputMask>

            <Box ml={2}>
              <Typography variant={"subtitle1"}>.kushkipagos.com</Typography>
            </Box>
            {props.error === false && (
              <Box ml={2}>
                <img src={Check} alt="" />
              </Box>
            )}
            {props.error && (
              <Box ml={2}>
                <img src={Error} alt="" />
              </Box>
            )}
          </Box>
          {props.error ? (
            <Typography className={classes.errorTypography}>
              Este subdominio no está disponible
            </Typography>
          ) : null}
        </Grid>
      ) : (
        <Grid item xs={10} md={5}>
          <Box display={"flex"} alignItems={"center"}>
            <TextField
              disabled
              fullWidth
              label={"Subdominio"}
              variant="outlined"
              value={environment.envName}
            />
            <Box ml={1}>
              <Typography variant={"subtitle1"}>.kushkipagos.com</Typography>
            </Box>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
