import { Box, Button, Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { footerStyles } from "./FooterOptions.styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { IFooterOptions } from "./FooterOptions.interfaces";
import {
  CONTINUE_TEXT,
  DEFAULT_IS_DISABLE_BUTTON,
  DEFAULT_IS_EDIT_LABEL,
  DEFAULT_IS_HIDDEN_BUTTON,
  DEFAULT_IS_HIDDEN_LABEL,
  FINISH_EDITION,
  OMIT_TEXT,
  SAVE,
} from "../../shared/constants/labels/footerOptionsConstants";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { SecurityLabelsEnum } from "../../shared/enums/SecurityLabelsEnum";
import {
  getMassiveConfig,
  isMassivePath,
} from "../../shared/utils/massiveConfigUtils";
import { MassiveServiceConfiguration } from "../../../types/massive_service_configuration";

const FooterOptions: FC<IFooterOptions> = ({
  primaryButton = {
    isDisabled: DEFAULT_IS_DISABLE_BUTTON,
    isHidden: DEFAULT_IS_HIDDEN_BUTTON,
  },
  secondaryButton = {
    isDisabled: DEFAULT_IS_DISABLE_BUTTON,
    isHidden: DEFAULT_IS_HIDDEN_BUTTON,
  },
  label = {
    date: "Jun/4/2022",
    editDetail: DEFAULT_IS_EDIT_LABEL,
    isHidden: DEFAULT_IS_HIDDEN_LABEL,
    text: "",
  },
  handleMassiveServicesCreation,
}: IFooterOptions) => {
  const massiveConfig: MassiveServiceConfiguration = getMassiveConfig();

  return (
    <Box sx={footerStyles.footer}>
      <Grid container spacing={0}>
        <Grid item xs={12} lg={6}>
          <Box sx={footerStyles.buttonContainer}>
            {!secondaryButton.isHidden && (
              <ComponentSecurityWrapper
                componentId={SecurityLabelsEnum.SERVICE_OMIT}
              >
                <Button
                  data-testid="idButtonTest"
                  variant="contained"
                  sx={footerStyles.saveButton}
                  disabled={secondaryButton.isDisabled}
                  onClick={secondaryButton.onAction!}
                  endIcon={<ArrowForwardIcon />}
                >
                  {OMIT_TEXT}
                </Button>
              </ComponentSecurityWrapper>
            )}

            {isMassivePath() && (
              <ComponentSecurityWrapper
                componentId={SecurityLabelsEnum.SERVICE_CONTINUE}
              >
                <Button
                  data-testid="idButtonSave"
                  variant="outlined"
                  color={"info"}
                  disabled={massiveConfig.alreadySent ?? false}
                  onClick={handleMassiveServicesCreation}
                  sx={footerStyles.saveButton}
                >
                  {SAVE}
                </Button>
              </ComponentSecurityWrapper>
            )}

            {!primaryButton.isHidden && (
              <ComponentSecurityWrapper
                componentId={SecurityLabelsEnum.SERVICE_CONTINUE}
              >
                <Button
                  data-testid="idButtonContinue"
                  variant="contained"
                  disabled={primaryButton.isDisabled}
                  onClick={primaryButton.onAction!}
                  sx={footerStyles.continueButton}
                  endIcon={<ArrowForwardIcon />}
                >
                  {isMassivePath() ? FINISH_EDITION : CONTINUE_TEXT}
                </Button>
              </ComponentSecurityWrapper>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box sx={footerStyles.labelContainer}>
            <Box sx={footerStyles.labelBox}>
              <Typography sx={footerStyles.label}>{label.text}</Typography>
            </Box>
            <Box sx={footerStyles.labelBox}>
              <Typography sx={footerStyles.label}>{label.date}</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FooterOptions;
