import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { KshDateRangePicker } from "@kushki/frontend-molecules/date-range-picker";
import { dateRangerPickerStyle } from "./DateRangerPicker.style";
import { LabelsDeferred } from "../../shared/constants/RequestDispersionConstants";
import { RotateCcw } from "react-feather";
import { DateRange } from "@material-ui/pickers/DateRangePicker/RangeTypes";

export interface DateRangePickerProps {
  dateRange: DateRange<Date>;
  clickDateFilter: (date: DateRange<Date>) => void;
  clickResetDateFilter: () => void;
}

export const DateRangePicker: React.FC<DateRangePickerProps> = (
  props: DateRangePickerProps
) => {
  const classes = dateRangerPickerStyle();
  const { clickDateFilter, clickResetDateFilter } = props;

  return (
    <Grid className={classes.containerDateRange} container direction={"row"}>
      <Typography className={classes.dateRangeText}>
        {LabelsDeferred.DEFERRED_PROGRAMS}
      </Typography>
      <Grid
        container
        item
        direction={"row"}
        className={classes.dateRangePicker}
      >
        <KshDateRangePicker
          handleDate={clickDateFilter}
          value={props.dateRange}
          disableFuture={false}
          enableTimePicker={false}
        />
        <Button
          className={classes.reloadButton}
          variant={"outlined"}
          onClick={clickResetDateFilter}
        >
          {<RotateCcw className={classes.reloadIcon} />}
        </Button>
      </Grid>
    </Grid>
  );
};

export default DateRangePicker;
