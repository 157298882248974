export const TITLE_DELETE_TEXT =
  "¿Estás seguro que deseas eliminar el accionista?";
export const DESCRIPTION_DELETE_TEXT =
  "Se borrará toda la información del accionista, incluyendo a sus subaccionistas";
export const ACCEPT_BUTTON_DELETE_TEXT = "Sí, eliminar";
export const SELECTED_BRANCHES = "Branches seleccionados:";
export const CANCEL_BUTTON_TEXT = "No, cancelar";
export const TITLE_EDIT_TEXT = "¿Estás seguro de cambiar el tipo de persona?";
export const DESCRIPTION_EDIT_TEXT =
  "Se borrará toda la información del accionista, incluyendo a sus subaccionistas";
export const ACCEPT_BUTTON_EDIT_TEXT = "Sí, cambiar";
