import React, { useState } from "react";
import { IInputsProps } from "../../../shared/interfaces/FormController";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { get } from "lodash";
import { TextAreaInputStyles } from "./TextAreaInput.styles";
import { FORM_LABEL } from "../../../shared/constants/labels/form_labels";

export const TextAreaInput = ({
  name,
  rules,
  register,
  errors,
  value,
  maxLength,
}: IInputsProps) => {
  const [label, setLabel] = useState(FORM_LABEL.descriptionMessage);

  return (
    <Box sx={{ marginTop: 2 }}>
      <TextField
        {...register(name, rules)}
        sx={TextAreaInputStyles.textField}
        label={label}
        multiline
        placeholder={FORM_LABEL.descriptionMessage}
        variant="outlined"
        onFocus={() => {
          setLabel(FORM_LABEL.descriptionLabel);
        }}
        rows={5}
        error={!!errors[name]}
        helperText={get(errors, `${name}.message`, "") as string}
        inputProps={{ maxLength }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={TextAreaInputStyles.endAdorment}>
              <Typography sx={TextAreaInputStyles.countLenght}>
                {get(value, "length")} / {maxLength}
              </Typography>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};
