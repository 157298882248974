import React, { FC } from "react";
import { Container, Grid, makeStyles, Typography } from "@material-ui/core";
import { PluginConfiguration } from "../../components/PluginConfiguration/PluginConfiguration";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { routes } from "../../shared/infrastructure/constants/routes";

import { ConfigSectionPlugin } from "../../components/ConfigSectionPlugin/ConfigSectionPlugin";
import edit from "../../assets/images/edit.svg";
import {
  IUsePluginIndexState,
  usePluginIndexState,
} from "./state/usePluginIndexState";

const useStyles = makeStyles({
  subTitle: {
    fontSize: "15px",
  },
});

export const PluginsIndex: FC = () => {
  const classes = useStyles();
  const { handler, confirmation }: IUsePluginIndexState = usePluginIndexState();

  return (
    <>
      <Container fixed>
        <Grid container spacing={2}>
          <Breadcrumb
            items={[{ label: "Configuración", url: routes.INDEX }]}
            lastItem={"Plugins"}
          />

          <Grid item xs={12}>
            <Typography variant={"h1"}>Plugins</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.subTitle}>
              Selecciona el plugin que deseas configurar:
            </Typography>
          </Grid>
          <Grid
            container
            xs={12}
            alignItems="center"
            justifyContent="flex-start"
            spacing={2}
          >
            <Grid item xs={6} md={3}>
              <PluginConfiguration
                plugin="woocommerce"
                handlerOnClick={handler.renderConfirmation}
                getPluginSvg={handler.getPluginSvg}
                currentPlugin={confirmation.plugin}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <PluginConfiguration
                plugin="magento"
                handlerOnClick={handler.renderConfirmation}
                getPluginSvg={handler.getPluginSvg}
                currentPlugin={confirmation.plugin}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <PluginConfiguration
                plugin="prestashop"
                handlerOnClick={handler.renderConfirmation}
                getPluginSvg={handler.getPluginSvg}
                currentPlugin={confirmation.plugin}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <PluginConfiguration
                plugin="shopify"
                handlerOnClick={handler.renderConfirmation}
                getPluginSvg={handler.getPluginSvg}
                currentPlugin={confirmation.plugin}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <PluginConfiguration
                plugin="magentov2"
                handlerOnClick={handler.renderConfirmation}
                getPluginSvg={handler.getPluginSvg}
                currentPlugin={confirmation.plugin}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <PluginConfiguration
                plugin="vtex"
                handlerOnClick={handler.renderConfirmation}
                getPluginSvg={handler.getPluginSvg}
                currentPlugin={confirmation.plugin}
              />
            </Grid>

            <ConfigSectionPlugin
              icon={edit}
              plugin={confirmation.plugin}
              handlerOnClick={handler.goToKform}
              show={confirmation.show}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default PluginsIndex;
