export const DEFERRED_TYPE: object[] = [
  { name: "Diferido Cuota Fija con intereses", value: "01" },
  {
    name: "Diferido con meses de gracia con intereses",
    value: "02",
  },
  {
    name: "Diferido Pago Mes a Mes con intereses",
    value: "03",
  },
  { name: "Diferido Cuota Fija sin intereses", value: "04" },
  {
    name: "Diferido con meses de gracia sin intereses",
    value: "05",
  },
  { name: "Diferido Pago Mes a Mes sin intereses", value: "06" },
  { name: "Diferido especial sin intereses", value: "07" },
  { name: "Diferido corriente", value: "001" },
  { name: "Diferido con intereses", value: "002" },
  { name: "Diferido sin intereses", value: "003" },
  { name: "Tipo de Comercio Gasolinera", value: "004" },
  { name: "Diferido especial con interés", value: "007" },
  { name: "Tarjeta descuento", value: "008" },
  { name: "Diferido especial sin interés", value: "009" },
  { name: "Diferido Plus Cuotas", value: "021" },
  { name: "Diferido Plus", value: "022" },
  { name: "Diferido promociones Supermaxi", value: "50" },
];
