import { defaultTo, get, has, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { MerchantResponse } from "../../../../types/merchant_response";
import {
  Configs,
  NodeInfoResponse,
} from "../../../../types/node_info_response";
import { validateIfHasMainAccount } from "../../../shared/utils/bankAccountUtils";
import {
  getBankAccounts,
  getBeneficiaryName,
  getConfigsDispersion,
  getConstitutionalCountry,
  getDispersionMerchantNodePayload,
  getEnable4x1000,
  getHierarchyNodeConfigPayload,
  getIsExclusiveValue,
  getRatesPayload,
  getStatesResume,
  isBasicDataResponse,
  isDispersionDataResponse,
  requestMerchantNodeInfo,
} from "../../../shared/utils/dispersionUtils";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { useForm } from "react-hook-form";
import {
  getDispersionRatesConfig,
  getNodeInfo,
  patchMerchantNodeInfo,
  postHierarchyNodeConfig,
  postRates,
} from "../../../store/thunks/app/app.thunks";
import { IUseDispersionContainerState } from "./useDispersionContainerState.interfaces";
import {
  disableContinueButton,
  disableSaveButton,
  editBankAccount,
  editBeneficiaryName,
  setIsLoadingDispersionInformation,
  updateConstitutionalCountry,
  updateFrequencyAndFraudForm,
  updateLoadingForm,
} from "../../../store/actions/dispersion/dispersion.actions";
import { ConfigIdDispersion } from "../../../shared/enums/dispersionsEnum";
import { EntityName } from "../../../shared/enums/entityName";
import { FrequencyEnum } from "../../../shared/enums/frequencyEnum";
import {
  AllConfigs,
  ConfigIdEnum,
} from "../../../shared/constants/node_config_enums";
import { RootState } from "../../../store/interfaces/store.interfaces";
import { DispersionForm } from "../../../shared/interfaces/dispersion_form";

export const useDispersionContainerState = (): IUseDispersionContainerState => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const {
    banksAccounts: bankAccounts,
    frequencyAndFraudForm,
    constitutionalCountry,
    merchantNodeInfo,
    nodeInfo,
    loading: { loadingForm, loadingRatesConfig },
    footerButtonsClicked,
    state: { postDispersionRates: statePostDispersionRates },
    appState: { patchMerchantNodeInfo: statePatchMerchantNodeInfo },
    configsResponse,
    isLoadingDispersionInformation,
  } = useAppSelector((state: RootState) => ({
    ...state.app,
    ...state.dispersion,
  }));
  const [isBankDataUpdated, setBankDataUpdated] = useState<boolean>(false);
  const [isBeneficiaryNameUpdated, setIsBeneficiaryNameUpdated] =
    useState(false);
  const [merchantBasicData, setMerchantBasicData] =
    useState<MerchantResponse>();
  const publicMerchantId = searchParams.get("publicMerchantId");
  const [isCentralizedBranch, setIsCentralizedBranch] =
    useState<boolean>(false);
  const [switchDispersion, setSwitchDispersion] = useState(false);

  const {
    control,
    resetField,
    reset,
    formState: { errors, isValid },
  } = useForm<DispersionForm>({
    defaultValues: {
      enable4x1000: frequencyAndFraudForm.enable4x1000.toString(),
      frequency: FrequencyEnum.NONE,
      keepCommission: frequencyAndFraudForm.keepCommission.toString(),
      keepFraud: frequencyAndFraudForm.keepFraud.toString(),
    },
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const requestBasicData = (configId: string, merchantId: string) => {
    requestMerchantNodeInfo(configId, merchantId, dispatch);
  };

  const requestDispersionData = (configId: string, merchantId: string) => {
    requestMerchantNodeInfo(configId, merchantId, dispatch);
  };

  const requestDispersionRatesConfig = (value: string) => {
    dispatch(getDispersionRatesConfig({ merchantId: value }));
  };
  const isEdit: boolean = (searchParams.get("operation") as string) === "edit";

  const handleValidatedCentralizedBranch = (
    entityName: string,
    config: Configs
  ): boolean => {
    return (
      entityName === EntityName.BRANCH && !isEmpty(config.centralizedNodesId)
    );
  };

  const requestDataFromNodeInfo = (nodeInfo: NodeInfoResponse) => {
    const { configCn001, configCn004 } = getConfigsDispersion(nodeInfo);

    if (configCn001)
      requestBasicData(configCn001.configuration, configCn001.value);

    if (!configCn004) {
      setBankDataUpdated(true);
      dispatch(updateLoadingForm(false));

      return;
    }

    requestDispersionData(configCn004.configuration, configCn004.value);
    requestDispersionRatesConfig(configCn004.value);
    setIsCentralizedBranch(
      handleValidatedCentralizedBranch(
        get(nodeInfo, "entityName", ""),
        configCn004
      )
    );
  };

  const handleBasicDataResponse = async (response: MerchantResponse) => {
    await dispatch(editBeneficiaryName(getBeneficiaryName(response)));
    setIsBeneficiaryNameUpdated(true);
  };

  const handleDispersionDataResponse = async (response: MerchantResponse) => {
    const { dispersionFrequency, dispersion } = response;

    dispatch(
      updateFrequencyAndFraudForm({
        ...frequencyAndFraudForm,
        ...(!isEmpty(dispersionFrequency) && {
          frequency: dispersionFrequency,
        }),
        ...(has(dispersion, "keepCommission") && {
          keepCommission: dispersion?.keepCommission,
        }),
        ...(has(dispersion, "enable4x1000") && {
          enable4x1000: getEnable4x1000(
            constitutionalCountry,
            get(dispersion, "enable4x1000", false)
          ),
        }),
        ...(has(dispersion, "isExclusive") && {
          isExclusive: getIsExclusiveValue(
            constitutionalCountry,
            get(dispersion, "isExclusive", false)
          ),
        }),
      })
    );

    await dispatch(editBankAccount(getBankAccounts(response)));
    setBankDataUpdated(true);
  };

  const getCommonPayloadData = () => ({
    bankAccounts,
    constitutionalCountry: get(
      nodeInfo,
      "generalInfo.constitutionalCountry",
      ""
    ),
    country: get(nodeInfo, "generalInfo.country", ""),
    dispersionForm: frequencyAndFraudForm,
    publicMerchantId: publicMerchantId!,
  });

  const saveDispersionData = () => {
    dispatch(
      patchMerchantNodeInfo(
        getDispersionMerchantNodePayload({
          ...getCommonPayloadData(),
          isExclusive: switchDispersion,
        })
      )
    );

    dispatch(
      postRates(
        getRatesPayload({
          ...getCommonPayloadData(),
          clientType: get(merchantBasicData, "clientType", ""),
          constitutionalCountry,
        })
      )
    );
  };

  const isEmptyConfig = (arrayConfig: Configs[]) => {
    const getConfig: Configs[] = arrayConfig.filter(
      (item) => item.configuration === ConfigIdEnum.CN004
    );

    if (isEmpty(get(getConfig, "[0].value")))
      dispatch(setIsLoadingDispersionInformation(false));
  };

  useEffect(() => {
    if (!isEmpty(get(nodeInfo, "configs", [])))
      isEmptyConfig(get(nodeInfo, "configs", []));
  }, [nodeInfo]);

  useEffect(() => {
    if (!merchantNodeInfo) return;

    if (isBasicDataResponse(merchantNodeInfo)) {
      setMerchantBasicData(merchantNodeInfo);
      handleBasicDataResponse(merchantNodeInfo).then();
    }

    if (isDispersionDataResponse(merchantNodeInfo)) {
      handleDispersionDataResponse(merchantNodeInfo).then();
      setSwitchDispersion(
        get(merchantNodeInfo.dispersion, "isExclusive", false)
      );
    }
  }, [merchantNodeInfo]);

  useEffect(() => {
    if (isEmpty(nodeInfo)) return;

    dispatch(updateConstitutionalCountry(getConstitutionalCountry(nodeInfo!)));
    requestDataFromNodeInfo(nodeInfo!);
  }, [nodeInfo]);

  useEffect(() => {
    if (switchDispersion) {
      const values: DispersionForm = {
        enable4x1000: frequencyAndFraudForm.enable4x1000.toString(),
        fraudPercentage: get(
          frequencyAndFraudForm,
          "fraudPercentage",
          ""
        ).toString(),
        frequency: FrequencyEnum.NONE,
        isExclusive: get(frequencyAndFraudForm, "isExclusive", "").toString(),
        keepCommission: frequencyAndFraudForm.keepCommission.toString(),
        keepFraud: frequencyAndFraudForm.keepFraud.toString(),
        retentionPeriod: get(
          frequencyAndFraudForm,
          "retentionPeriod",
          ""
        ).toString(),
      };

      reset(values);
    }
  }, [switchDispersion]);

  useEffect(() => {
    const isAllDataLoaded =
      !(loadingForm && loadingRatesConfig) &&
      isBankDataUpdated &&
      isBeneficiaryNameUpdated;

    dispatch(
      disableContinueButton(
        !(isAllDataLoaded && isValid && validateIfHasMainAccount(bankAccounts))
      )
    );
    dispatch(disableSaveButton(!(isAllDataLoaded && isValid)));
  }, [
    isValid,
    bankAccounts,
    loadingForm,
    loadingRatesConfig,
    isBankDataUpdated,
    isBeneficiaryNameUpdated,
    frequencyAndFraudForm,
  ]);

  useEffect(() => {
    if (footerButtonsClicked && nodeInfo && publicMerchantId)
      saveDispersionData();
  }, [footerButtonsClicked]);

  useEffect(() => {
    const isDispersionFormDataLoaded = !(loadingForm && loadingRatesConfig);

    const values = {
      enable4x1000: defaultTo(
        get(merchantNodeInfo, "dispersion.enable4x1000"),
        false
      ).toString(),
      fraudPercentage: get(
        frequencyAndFraudForm,
        "fraudPercentage",
        ""
      ).toString(),
      frequency: frequencyAndFraudForm.frequency,
      isExclusive: get(frequencyAndFraudForm, "isExclusive", "").toString(),
      keepCommission: defaultTo(
        frequencyAndFraudForm.keepCommission,
        true
      ).toString(),
      keepFraud: frequencyAndFraudForm.keepFraud.toString(),
      retentionPeriod: get(
        frequencyAndFraudForm,
        "retentionPeriod",
        ""
      ).toString(),
    };

    if (isDispersionFormDataLoaded) {
      // @ts-ignore
      reset(values);
    }
  }, [loadingForm, loadingRatesConfig]);

  useEffect(() => {
    const { isSuccess: isDispersionDataSaved } = getStatesResume([
      statePatchMerchantNodeInfo,
      statePostDispersionRates,
    ]);
    const configResponse = defaultTo(configsResponse, []).find(
      (config) => get(config, "configuration") === ConfigIdDispersion.CN004
    );

    if (isDispersionDataSaved)
      dispatch(
        postHierarchyNodeConfig(
          getHierarchyNodeConfigPayload(
            nodeInfo!,
            configResponse,
            publicMerchantId!,
            bankAccounts
          )
        )
      );
  }, [statePatchMerchantNodeInfo, statePostDispersionRates]);

  useEffect(() => {
    dispatch(
      getNodeInfo({
        configIds: AllConfigs,
        publicMerchantId: publicMerchantId!,
      })
    );
  }, []);

  return {
    form: {
      control,
      errors,
      reset,
      resetField,
    },
    isCentralizedBranch,
    isLoadingDispersionInformation,
    loading: { loadingForm, loadingRatesConfig },
    setSwitchDispersion,
    showBankData: (isBankDataUpdated && isBeneficiaryNameUpdated) || isEdit,
    switchDispersion,
  };
};
