import { makeStyles } from "@material-ui/core/styles";
import { Palette } from "../../themeui";
import { StatusEnum } from "../../shared/enums/statusEnum";
import { IChipStatus } from "./ChipStatus.interfaces";
import { Theme as DefaultTheme } from "@material-ui/core/styles/createTheme";

const stylesByStatus = new Map([
  [
    StatusEnum.DISABLED,
    {
      backgroundColor: Palette.error.light3,
      color: Palette.error.dark2,
    },
  ],
  [
    StatusEnum.ENABLED,
    {
      backgroundColor: Palette.secondary.light3,
      color: Palette.success.dark,
    },
  ],
]);

export const chipStatusStyles = makeStyles<DefaultTheme, IChipStatus>(() => ({
  chip: {
    color: (props) =>
      stylesByStatus.get(props.status)
        ? stylesByStatus.get(props.status)!.color
        : "white",
    backgroundColor: (props) =>
      stylesByStatus.get(props.status)
        ? stylesByStatus.get(props.status)!.backgroundColor
        : "white",
    borderRadius: "4px",
    fontSize: "9px",
    fontWeight: 500,
    textAlign: "center",
    width: 74,
    padding: "3px 11px",
  },
}));
