export enum ErrorEnum {
  GREATER_THAN_ERROR = "El segundo monto debe ser mayor que el primer monto",
  LESS_THAN_ERROR = "El primer monto debe ser menor que el segundo monto",
  NOT_ALLOWED_VALUE = "Valor no permitido",
  ONLY_NUMBERS = "Solo se admiten números",
  REQUIRED_FIELD = "Campo requerido",
  ALIAS_REPEATED = "Alias no disponible, porfavor ingresar uno nuevo",
  LENGTH_BIN = "El número bin consta de 6 u 8 dígitos",
  VALID_IP = "Ingrese una IP v6 o v4 válida",
}
