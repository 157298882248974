import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@material-ui/core";
import { get } from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      color: theme.palette.primary.dark,
      "&.MuiIconButton-root": {
        padding: 3,
        marginBottom: 12,
        marginTop: 0,
        marginRight: 0,
        marginLeft: 24,
      },
    },
    button: {
      "&.MuiButton-root": {
        fontSize: 16,
      },
    },
    dialogTitle: {
      paddingTop: 16,
      paddingRight: 24,
      paddingBottom: 16,
      paddingLeft: 24,
    },
    dialogContentText: {
      marginTop: -10,
      marginBottom: 24,
    },
    dialogActions: {
      paddingRight: 24,
      paddingBottom: 12,
    },
    dialogPaper: {
      maxWidth: "500px",
    },
    primaryColor: {
      "&.MuiButton-root": {
        fontSize: (props: ConfirmModalProps) =>
          props.buttonTextSize && props.buttonTextSize,
      },
      color: theme.palette.primary.main,
    },
    errorColor: {
      "&.MuiButton-root": {
        fontSize: (props: ConfirmModalProps) =>
          props.buttonTextSize && props.buttonTextSize,
      },
      color: theme.palette.error.main,
    },
    cancelAction: {
      color: theme.palette.primary.main,
    },
  })
);

export interface ConfirmModalProps {
  open: boolean;
  titleText: string | React.ReactElement;
  contentText?: string | React.ReactElement;
  acceptText?: string;
  cancelText?: string;
  acceptColor?: "primary" | "error";
  fullWidth?: boolean;
  disableAccept?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  active?: boolean;
  adjustedSize?: boolean;
  buttonTextSize?: string;
  handleClose: () => void;
  handleAccept: () => void;
  isLoading?: boolean;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = (
  props: React.PropsWithChildren<ConfirmModalProps>
) => {
  const classes = useStyles(props);
  const disableButton: boolean = get(props, "disableAccept", false);
  return (
    <Dialog
      classes={props.adjustedSize ? { paper: classes.dialogPaper } : undefined}
      open={props.open}
      onClose={props.handleClose}
      fullWidth={props.fullWidth}
      maxWidth={props.maxWidth}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box className={classes.dialogTitle} display="flex" flexGrow={1}>
        <Box display="flex" alignItems="center" flexGrow={1}>
          <Typography variant="h5" color={"primary"}>
            {props.titleText}
          </Typography>
        </Box>
      </Box>
      <DialogContent>
        {props.contentText && (
          <DialogContentText
            className={classes.dialogContentText}
            id="alert-dialog-description"
          >
            <Typography component="span" variant="h6" color="inherit">
              {props.contentText}
            </Typography>
          </DialogContentText>
        )}
        {props.children}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={props.handleClose}
          className={classes.cancelAction}
          color="primary"
        >
          <Typography variant="caption" color="inherit">
            {props.cancelText}
          </Typography>
        </Button>
        <Button
          disabled={disableButton}
          color="primary"
          onClick={props.handleAccept}
          autoFocus
        >
          <Typography variant="caption">{props.acceptText}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmModal.defaultProps = {
  acceptColor: "error",
  acceptText: "Aceptar",
  cancelText: "Cancelar",
  fullWidth: true,
  maxWidth: "md",
};
