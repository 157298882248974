import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@material-ui/core";
import CashPaymentMethod from "../../../../../assets/image/payment_methods_cash.svg";
import TransferPaymentMethod from "../../../../../assets/image/payment_methods_transfer.svg";
import { CustomMenuItem } from "../CustomMenuItem/CustomMenuItem";
import {
  navigateWithMerchantInfoStorage,
  PayInOutPopupConstants,
} from "../../../../../shared/infrastructure/constants/CreateMerchantConstants";
import { routes } from "../../../../../shared/infrastructure/routes";
import { get, isEmpty } from "lodash";
import { CountryEnum } from "../../../../../shared/infrastructure/catalogs/CountryEnum";

export interface IAccordionPopupProcessorMenuPayOut {
  id: string | null;
  country?: string | undefined;
  expanded: boolean;
  setActivePayOut: (type: string) => void;
}

export const AccordionPopupProcessorMenuPayOut: React.FC<IAccordionPopupProcessorMenuPayOut> = (
  props: React.PropsWithChildren<IAccordionPopupProcessorMenuPayOut>
) => {
  const disabledAccordion = ((country: string) => {
    if (isEmpty(country)) return false;
    return [CountryEnum.BRAZIL].includes(country as CountryEnum);
  })(get(props, "country", ""));

  const handleChange = () => {
    props.expanded || disabledAccordion
      ? props.setActivePayOut("")
      : props.setActivePayOut("payout");
  };

  return (
    <Accordion
      defaultExpanded={false}
      expanded={get(props, "expanded", false)}
      elevation={0}
      onClick={handleChange}
      disabled={disabledAccordion}
    >
      <AccordionSummary>
        <Typography>{PayInOutPopupConstants.PAY_OUT}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction={"column"}>
          <Grid item>
            <CustomMenuItem
              keyName={PayInOutPopupConstants.TRANSFER_PAY_OUT}
              customNavigateFn={() =>
                navigateWithMerchantInfoStorage(
                  `${routes.PROCESSOR_PAYOUT_TRANSFER}/${props.id}`
                )
              }
              navigateTo={`${routes.PROCESSOR_PAYOUT_TRANSFER}/${props.id}`}
              menuTitle={PayInOutPopupConstants.TRANSFER_TITLE}
              menuIcon={TransferPaymentMethod}
              country={props.country}
            />
          </Grid>
          <Grid item>
            <CustomMenuItem
              keyName={PayInOutPopupConstants.CASH_PAY_OUT}
              customNavigateFn={() =>
                navigateWithMerchantInfoStorage(
                  `${routes.PROCESSOR_PAYOUT_CASH}/${props.id}`
                )
              }
              navigateTo={`${routes.PROCESSOR_PAYOUT_CASH}/${props.id}`}
              menuTitle={PayInOutPopupConstants.CASH_TITLE}
              menuIcon={CashPaymentMethod}
              country={props.country}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
