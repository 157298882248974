import React from "react";
import { Button, Divider, Grid, Menu, Typography } from "@material-ui/core";
import { Filter } from "react-feather";
import { filterComponentStyle } from "./FilterComponent.style";
import { LabelsDispersion } from "../../shared/infrastructure/constants/RequestDispersionConstants";
import { useFilterComponentState } from "./state/useFilterComponentState";
import { AccordionPopUp } from "../AccordionPopUp/AccordionPopUp";
import { ChipComponent } from "../AccordionPopUp/components/ChipComponent/ChipComponent";
import { SelectComponent } from "../AccordionPopUp/components/SelectComponent/SelectComponent";

export const FilterComponent = () => {
  const classes = filterComponentStyle();
  const {
    anchor,
    defaultExpanded,
    defaultExpandedStatus,
    defaultExpandedAmount,
    amountValue,
    generalAmountValue,
    eqAmountValueMin,
    eqAmountValueMax,
    handleClickFilter,
    handleCloseMenu,
    handleExpandedOrCollapsed,
    handleExpandedOrCollapsedStatus,
    handleExpandedOrCollapsedAmount,
    isChipSelected,
    handleClick,
    handleOnchangeSelect,
    handleOnchangeGeneralAmount,
    handleEqMinAmount,
    handleEqMaxAmount,
    handleApplyFilters,
    handleDeleteFilters,
  } = useFilterComponentState();

  return (
    <>
      <Button
        variant={"outlined"}
        className={classes.filterButton}
        startIcon={<Filter size={"20"} color={"#293036"} />}
        onClick={handleClickFilter}
        fullWidth
      >
        <Typography className={classes.buttonText}>
          {LabelsDispersion.FILTER}
        </Typography>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchor}
        keepMounted
        open={Boolean(anchor)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        elevation={1}
        getContentAnchorEl={null}
      >
        <Grid container direction={"column"} className={classes.mainGrid}>
          <Grid
            container
            item
            xs={12}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-around"}
            spacing={1}
          >
            <Grid item xs={6}>
              <Typography className={classes.filterByText}>
                {LabelsDispersion.FILTER_BY}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.gridRight}>
              <Button
                className={classes.expandedCollapsed}
                onClick={handleExpandedOrCollapsed}
              >
                {!defaultExpanded
                  ? LabelsDispersion.EXPANDED
                  : LabelsDispersion.COLLAPSED}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.firstDivider} />
            </Grid>
            <Grid item xs={12}>
              <AccordionPopUp
                title={LabelsDispersion.STATUS}
                defaultExpanded={defaultExpandedStatus}
                handleExpandedOrCollapsed={handleExpandedOrCollapsedStatus}
                componentToRender={
                  <ChipComponent
                    isChipSelected={isChipSelected}
                    handleClick={handleClick}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.firstDivider} />
            </Grid>
            <Grid item xs={12}>
              <AccordionPopUp
                title={LabelsDispersion.AMOUNTS}
                defaultExpanded={defaultExpandedAmount}
                handleExpandedOrCollapsed={handleExpandedOrCollapsedAmount}
                componentToRender={
                  <SelectComponent
                    amountValue={amountValue}
                    eqAmountValueMax={eqAmountValueMax}
                    eqAmountValueMin={eqAmountValueMin}
                    generalAmountValue={generalAmountValue}
                    handleEqMaxAmount={handleEqMaxAmount}
                    handleEqMinAmount={handleEqMinAmount}
                    handleOnchangeGeneralAmount={handleOnchangeGeneralAmount}
                    handleOnchangeSelect={handleOnchangeSelect}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.firstDivider} />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant={"contained"}
                color={"primary"}
                className={classes.applyButton}
                onClick={handleApplyFilters}
              >
                {LabelsDispersion.APPLY_FILTER}
              </Button>
            </Grid>
            <Grid item xs={6} className={classes.gridRight}>
              <Button
                className={classes.deleteButton}
                onClick={handleDeleteFilters}
              >
                {LabelsDispersion.CLEAN_FILTER}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Menu>
    </>
  );
};
