import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { IconCircleWarn, useSnackbar } from "@kushki/connect-ui";
import { useCheckoutContext } from "../../../containers/CheckoutContainer/context/useCheckoutContext";
import {
  selectCreateExpressCheckoutStatus,
  selectExpressCheckout,
  selectPaymentMethods,
  selectPaymentMethodsStatus,
} from "../../../store/selectors/selectors";
import { getProcessors } from "../../../store/epics/getProcessorsEpic/getProcessors.epic";
import React, { useEffect } from "react";
import { RequestStatusEnum } from "../../../shared/enums/RequestStatusEnum";
import { SnackBarMessageLabels } from "../../../shared/labels/SnackBarMessage.labels";
import { CreateExpressCheckoutRequest } from "../../../../types/create_express_checkout_request";
import { CurrencyType } from "../../../shared/enums/CurrencyEnum";
import { PaymentMethodType } from "../../../shared/enums/PaymentMethodEnum";
import { StepperEnum } from "../../../shared/infrastructure/StepperEnum";
import { FormSummaryState } from "./useFromSummaryState.interfaces";
import { createExpressCheckout } from "../../../store/epics/createExpressCheckout/createExpressCheckout.epic";
import { KIND, SOURCE } from "../../../shared/constants/definitions";

export const useFromSummaryState = (): FormSummaryState => {
  const dispatch = useAppDispatch();
  const { showSnackbar } = useSnackbar();
  const {
    checkoutData,
    iva,
    currency,
    isMobile,
    setStep,
    setIsLoading,
    setWebcheckoutUrl,
    country,
  } = useCheckoutContext();

  const paymentMethodsStatus = useAppSelector(selectPaymentMethodsStatus);
  const paymentMethods = useAppSelector(selectPaymentMethods);
  const createExpressCheckoutStatus = useAppSelector(
    selectCreateExpressCheckoutStatus
  );
  const expressCheckout = useAppSelector(selectExpressCheckout);

  const handleCreateQR = () => {
    setIsLoading(true);
    dispatch(getProcessors(country));
  };

  const handleBack = () => {
    setStep(StepperEnum.FORM);
  };

  const buildCheckoutExpressRequest = (): CreateExpressCheckoutRequest => ({
    contactDetail: {
      email: checkoutData.email,
      name: checkoutData.name,
    },
    kind: KIND,
    kushkiMetadata: {
      source: SOURCE,
    },
    paymentConfig: {
      amount: {
        currency: currency as CurrencyType,
        iva: checkoutData.ivaAmount,
        subtotalIva: checkoutData.subtotalIva,
        subtotalIva0: checkoutData.subtotalIva0,
      },
      paymentMethod: paymentMethods as PaymentMethodType,
    },
    paymentDescription: checkoutData.description,
    webcheckoutType: "express",
  });

  const showErrorSnackBar = (message: string) =>
    showSnackbar({
      color: "danger",
      icon: <IconCircleWarn />,
      message,
      variant: "simple",
      withIcon: true,
    });

  const onErrorExpressCheckout = () => {
    setIsLoading(false);
    showErrorSnackBar(SnackBarMessageLabels.CREATE_EXPRESS_CHECKOUT_ERROR);
  };

  const redirectOnCreateExpressCheckout = () => {
    if (expressCheckout) {
      setWebcheckoutUrl(expressCheckout.webcheckoutUrl);
      setIsLoading(false);
      setStep(StepperEnum.CODE_QR);
    } else onErrorExpressCheckout();
  };

  useEffect(() => {
    if (paymentMethodsStatus === RequestStatusEnum.SUCCESS) {
      dispatch(createExpressCheckout(buildCheckoutExpressRequest()));
    } else if (paymentMethodsStatus === RequestStatusEnum.ERROR) {
      setIsLoading(false);
      showErrorSnackBar(SnackBarMessageLabels.PROCESSORS_ERROR);
    }
  }, [paymentMethodsStatus]);

  useEffect(() => {
    if (createExpressCheckoutStatus === RequestStatusEnum.SUCCESS) {
      redirectOnCreateExpressCheckout();
    } else if (createExpressCheckoutStatus === RequestStatusEnum.ERROR)
      onErrorExpressCheckout();
  }, [createExpressCheckoutStatus]);

  return {
    checkoutData,
    currency,
    handleBack,
    handleCreateQR,
    isMobile,
    iva,
  };
};
