import { useAppDispatch, useAppSelector } from "@store/hooks/storeHook";
import { RootState } from "@store/store";
import { IUseDetailCardBody } from "@components/DetailCardBody/DetailCardBody.interfaces";
import {
  groupNodesByEntityName,
  translateFrequency,
} from "@shared/utils/detailCardUtils";
import { defaultTo, get } from "lodash";
import { RuleAlarm } from "../../../../types/rule_alarm";
import { FrequencyValue } from "@shared/enum/FrequencyEnum";
import { ROUTES } from "@shared/constants/routes";
import { useEffect, useState } from "react";
import { getListCatalogs } from "@store/thunks/catalogs/catalogs.thunk";
import { ConfigCodeEnum } from "@shared/enum/ConfigCodeEnum";
import { CountryCode } from "@shared/enum/CountryCodeEnum";
import { ProductLineEnum } from "@shared/enum/ProductLineEnum";
import { TypeCatalog } from "@shared/enum/TypeCatalog";
import { AppDispatch } from "@store/interfaces/store.interfaces";
import { ISelectOption } from "@components/InputsForm/Inputs/InputSelect/InputSelect.interfaces";
import { getCatalogAsSelectOptions } from "@shared/utils/listCatalogUtils";

export const useDetailCardBody = (tabType: string): IUseDetailCardBody => {
  const { detailCard } = useAppSelector((state: RootState) => state.rulesAlarm);
  const { listMccCatalog, isLoading } = useAppSelector(
    (state: RootState) => state.catalogs
  );
  const [mccCatalog, setMccCatalog] = useState<ISelectOption[]>([]);
  const dispatch: AppDispatch = useAppDispatch();

  const selectedRule: RuleAlarm = defaultTo(
    detailCard.selectedRule,
    {} as RuleAlarm
  );

  const handleRedirect = () => {
    window.location.href = `${ROUTES.SPA_BASENAME}/rule-detail/${tabType}/${selectedRule._id}?hideSideBar=true&hideTopBar=true`;
  };

  useEffect(() => {
    if (listMccCatalog.length === 0 && !isLoading) {
      dispatch(
        getListCatalogs({
          configCode: ConfigCodeEnum.BASIC_DATA,
          countryCode: CountryCode.GLB,
          productLine: ProductLineEnum.PLA,
          typeCatalog: TypeCatalog.MCC,
        })
      );
    }
  });

  useEffect(() => {
    if (listMccCatalog.length !== 0 && mccCatalog.length === 0) {
      setMccCatalog(getCatalogAsSelectOptions(listMccCatalog));
    }
  }, [listMccCatalog]);

  return {
    frequencyLabel:
      translateFrequency[get(selectedRule, "frequency", FrequencyValue.DAILY)],
    handleRedirect,
    mccCatalog,
    nodes: groupNodesByEntityName(get(selectedRule, "nodes", [])),
    variables: get(selectedRule, "variables", []),
  };
};
