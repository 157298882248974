import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useTypedController } from "@hookform/strictly-typed";
import ConnectForm from "../../commons/ConnectForm/ConnectForm";
import { SecurityRulesLabelField } from "../../../shared/infrastructure/constants/SecurityRulesConstants";
import { get } from "lodash";
import { IOptionSelection } from "../../../shared/infrastructure/constants/RulesConstants";
import { IBusinessRulesForm } from "../../../shared/infrastructure/interfaces/IBusinessRulesForm";
import { ErrorsEnum } from "../../../shared/infrastructure/enums/ErrorEnum";

export interface SelectFieldComponentProps {
  items: IOptionSelection[];
  disabled: boolean;
}

export const SelectFieldComponent: React.FC<SelectFieldComponentProps> = (
  props: SelectFieldComponentProps
) => {
  return (
    <ConnectForm<IBusinessRulesForm>>
      {({ control, errors }) => {
        const TypedController = useTypedController<IBusinessRulesForm>({
          control: control,
        });

        return (
          <React.Fragment>
            <TypedController
              name={"rulesInputValue"}
              defaultValue={props.items[0].value}
              render={(properties) => (
                <FormControl
                  variant="outlined"
                  error={!!get(errors, "rulesInputValue")}
                  fullWidth
                >
                  <InputLabel>{SecurityRulesLabelField.VALUE}</InputLabel>
                  <Select
                    {...properties}
                    value={properties.value}
                    label={SecurityRulesLabelField.VALUE}
                    disabled={props.disabled}
                  >
                    {props.items.map(
                      (
                        actionValue: { name: string; value: string },
                        index: number
                      ) => (
                        <MenuItem key={index} value={actionValue.value}>
                          {actionValue.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                  <FormHelperText error={!!get(errors, "rulesInputValue")}>
                    {!!get(errors, "rulesInputValue") &&
                      ErrorsEnum.REQUIRED_FIELD}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </React.Fragment>
        );
      }}
    </ConnectForm>
  );
};

export default SelectFieldComponent;
