import React, { ChangeEvent, SyntheticEvent } from "react";
import { Grid } from "@mui/material";
import FormControl from "../FormControl/FormControl";
import { get, isEmpty, keys } from "lodash";
import { RolesId } from "../../shared/constants/RoleNames";

export interface RolesChecksProps {
  disabledKey?: string;
  label: string;
  roles: { id: string; value: string; kind?: string }[];
  viewCondition?: string;
  onChange: (
    event: ChangeEvent<HTMLInputElement> | SyntheticEvent,
    name: string
  ) => void;
  rolesForm: object;
  errors: object;
  isChargeBackManagerSelected?: boolean;
}

const RolesChecks = (props: RolesChecksProps) => {
  const validateItem = !!props.disabledKey;

  return (
    <React.Fragment>
      <Grid item xs={12}>
        {props.label}
        {!isEmpty(get(props.errors, "group")) && (
          <p
            id="error-message"
            style={{
              color: "rgba(226,71,99,1)",
              fontSize: "0.75rem",
              fontWeight: "normal",
              letterSpacing: "0.03333em",
              lineHeight: "1.66",
              margin: 0,
              marginTop: "3px",
              textAlign: "left",
            }}
          >
            {get(props.errors, "group.message", "")}
          </p>
        )}
        <Grid container spacing={3} style={{ marginTop: 12 }}>
          {props.roles.map(
            (role: { id: string; value: string; kind?: string }) => (
              <Grid
                data-testid={`test-${role.id}`}
                key={role.id}
                id={role.id}
                item
                md={4}
                sm={6}
                xs={12}
                style={{
                  ...(props.viewCondition
                    ? {
                        display:
                          props.viewCondition === role.kind ? "block" : "none",
                      }
                    : {}),
                  paddingBottom: 0,
                  paddingTop: 0,
                }}
              >
                <FormControl
                  inputComponent={"checkbox"}
                  name={`group.${role.id}`}
                  label={role.value}
                  onChange={props.onChange}
                  checked={
                    !!keys(props.rolesForm).find(
                      (item) => item === role.id && !!props.rolesForm[item]
                    )
                  }
                  isDisable={
                    props.isChargeBackManagerSelected
                      ? role.id !== RolesId.ChargebackManager && true
                      : validateItem && !role[get(props, "disabledKey", "")]
                  }
                />
              </Grid>
            )
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default RolesChecks;
