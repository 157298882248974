import {
  createNamedStyles,
  IStyles,
  NamedStyles,
} from "../../shared/interfaces/create_named_styles";

export const qrInformationStyles = (
  isMobile: boolean
): NamedStyles<IStyles> => {
  return createNamedStyles({
    boxQrInformationContainer: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      justifyContent: "space-between",
      width: "100%",
    },
    buttonLink: {
      "&:hover": {
        color: "black",
        fontWeight: 500,
      },
      color: "black",
      fontWeight: 500,
      marginTop: "15px",
    },
    containerQR: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      height: "auto",
      marginBottom: isMobile ? "15px" : "0px",
      marginRight: {
        lg: "0px",
        md: "0px",
        sm: "0px",
        xl: "70px",
      },
      marginTop: "20px",
      width: {
        lg: "auto",
        md: "100%",
        sm: "100%",
      },
    },
    contentText: {
      margin: {
        lg: "0px",
        md: "0px auto",
        sm: "0px auto",
      },
      width: {
        lg: "auto",
        md: "100%",
        sm: "90%",
      },
    },
    qrBox: {
      alignItems: "center",
      borderRadius: "10px",
      boxShadow:
        "0px 36px 65px rgba(106, 167, 197, 0.16), 0px 18.225px 28.3359px rgba(106, 167, 197, 0.12), 0px 7.2px 10.5625px rgba(106, 167, 197, 0.08), 0px 1.575px 3.75781px rgba(106, 167, 197, 0.03)",
      display: "flex",
      justifyContent: "center",
      margin: isMobile ? "0px auto" : "0px",
      minHeight: isMobile ? "250px" : "230px",
      minWidth: isMobile ? "250px" : "230px",
      padding: "25px",
    },
    subTitle: {
      fontWeight: 600,
      marginBottom: "20px",
      textAlign: { lg: "left", md: "center", sm: "center" },
    },
    title: {
      marginBottom: "20px",
      marginTop: isMobile ? "20px" : "0px",
      textAlign: { lg: "left", md: "center", sm: "center" },
    },
  });
};
