export interface AccountStyleInterface {
  indexTitle: object;
  commerceTitle: object;
  cardTitle: object;
  sectionTitle: object;
  subSectionTitle: object;
  kshMultiline: object;
  returnLabel: object;
  continueBtn: object;
  message: object;
}

const AccountStyle: AccountStyleInterface | {} = {
  indexTitle: {
    color: "#023365",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "30px",
    lineHeight: "52px",
  },
  commerceTitle: {
    color: "#677784",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "140%",
    paddingBottom: 32,
  },
  cardTitle: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "140%",
    display: "flex",
    alignItems: "center",
    color: "#023365",
  },
  sectionTitle: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#6D7781",
  },
  subSectionTitle: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "140%",
    display: "flex",
    alignItems: "center",
  },
  kshMultiline: {
    "& > label+div": {
      height: "auto",
    },
  },
  returnLabel: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "140%",
    textAlign: "center",
    color: "#293036",
    flex: "none",
    alignSelf: "center",
    margin: "10px 0px",
  },
  continueBtn: {
    flex: "none",
    alignSelf: "center",
    margin: "10px 0px",
  },
  message: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "150%",
    color: "#6D7781",
  },
};
export default AccountStyle;
