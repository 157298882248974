import React, { PropsWithChildren } from "react";
import { Typography } from "@mui/material";
import { IGeneralTextFieldProps } from "../../../../shared/infrastructure/interfaces/IGeneralTextFieldProps";
import { SelectCheck } from "../../../SelectCheck/SelectCheck";

export const GeneralTextField: React.FC<IGeneralTextFieldProps> = (
  props: PropsWithChildren<IGeneralTextFieldProps>
) => {
  return (
    <>
      <Typography variant={"subtitle2"} className={props.className}>
        {props.title}
      </Typography>
      <SelectCheck
        isLoading={false}
        isActiveSelectAll={props.isActiveSelectAll || false}
        placeHolder={props.placeHolder}
        items={props.items}
        selected={props.selected}
        operator={props.operator}
        setSelectedItems={props.setSelectedItems}
        maxSelectedItems={props.maxSelectedItems}
        isDisable={props.isDisable}
        isEditCondition={props.isEditCondition}
        isFinalCond={props.isFinalCond}
      />
    </>
  );
};
