import React, { useState } from "react";
import { Box, InputAdornment, TextField } from "@mui/material";
import { IInputsProps } from "../../../shared/interfaces/FormController";
import { get } from "lodash";
import { TextfieldAmountInputStyles } from "./TextfieldAmountInput.styles";
import { handleInputValidation } from "../../../shared/constants/Form";
import { FORM_LABEL } from "../../../shared/constants/labels/form_labels";

export const TextfieldAmountInput = ({
  name,
  register,
  rules,
  errors,
  currency,
}: IInputsProps) => {
  const registerObj = register(name, rules);
  const [label, setLabel] = useState(FORM_LABEL.amountMessage);

  return (
    <Box sx={TextfieldAmountInputStyles.container}>
      <TextField
        {...registerObj}
        sx={TextfieldAmountInputStyles.textField}
        label={label}
        placeholder={FORM_LABEL.amountMessage}
        variant="outlined"
        fullWidth
        onFocus={() => {
          setLabel(FORM_LABEL.amountLabel);
        }}
        error={!!errors[name]}
        type="number"
        helperText={get(errors, `${name}.message`, "") as string}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{currency}</InputAdornment>
          ),
          inputProps: {
            onInput: handleInputValidation,
          },
        }}
      />
    </Box>
  );
};
