import { applyMiddleware, compose, createStore, Store } from "redux";
// @ts-ignore
import Normalize from "react-normalize";
import React from "react";
import { SnackBarProvider, theme } from "@kushki/connect-ui";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { reducer } from "./store/reducer";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import Navigation from "./navigation.component";
import { environment } from "./environments/environment";
import { Provider as RollbarProvider } from "@rollbar/react";
import Rollbar from "rollbar";
import packageJson from "../package.json";

const composeEnhancers = environment.devTools
  ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose;

export const store: Store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunk))
);

const Root = () => {
  const rollbarConfig: Rollbar.Configuration = {
    accessToken: environment.rollbarId,
    addErrorContext: true,
    autoInstrument: true,
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: environment.envName,
    payload: {
      client: {
        javascript: {
          code_version: packageJson.dependencies["@kushki/js"],
          source_map_enabled: true,
        },
      },
      environment: environment.envName,
    },
  };

  return (
    <RollbarProvider config={rollbarConfig}>
      <StyledEngineProvider>
        <React.StrictMode>
          <Normalize />
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <SnackBarProvider>
                <Navigation />
              </SnackBarProvider>
            </ThemeProvider>
          </Provider>
        </React.StrictMode>
      </StyledEngineProvider>
    </RollbarProvider>
  );
};

export default Root;
