import React from "react";
import { get } from "lodash";
import { translate } from "../../../shared/infraestructure/LanguageCatalog";
import { Chip, Grid, Typography } from "@mui/material";
import { NotificationSectionProps } from "./NotificationSectionModal.interfaces";
import { EmailInfo } from "../../../../types/chargeback";
import { getStatusTheme } from "./NotificationSectionModal.styles";

const NotificationSectionModal: React.FC<NotificationSectionProps> = (
  props: NotificationSectionProps
) => {
  const mail_info: EmailInfo[] = props.isMongo
    ? get(props, "data.email_info_object", [])
    : get(props, "data.mail_info", []);

  return (
    <React.Fragment>
      {mail_info.length === 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body2" color="text.grey">
              No existen notificaciones
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          {mail_info.map((item: EmailInfo, index: number) => (
            <Grid item xs={12} key={index}>
              <Grid container spacing={2}>
                <Grid item xs={7}>
                  <Typography variant="body2" color="text.grey">
                    {item.email}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Chip
                    style={{
                      backgroundColor: getStatusTheme(get(item, "status", ""))
                        .backgroundColor,
                      borderRadius: 3,
                      color: getStatusTheme(get(item, "status", "")).color,
                      textAlign: "center",
                      width: 120,
                    }}
                    label={translate(get(item, "status", ""))}
                    size="small"
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    </React.Fragment>
  );
};

export default NotificationSectionModal;
