import { FormHelperText, Grid, Typography } from "@mui/material";
import { SubTitleSection } from "../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { Controller } from "react-hook-form";
import { SelectSearchBy } from "@kushki/connect-ui";
import { isFormEditMode, retrieveFormError } from "../../../utils/utilsFile";
import React, { FC, PropsWithChildren } from "react";
import { ErrorsEnum } from "../../../shared/infrastructure/enums/ErrorEnum";
import { useMerchantWithoutHierarchyState } from "./state/useMerchantWithoutHierarchyState";
import { IMerchantWithoutHierarchyComponentProps } from "../../../shared/infrastructure/interfaces/IMerchantWithoutHierarchyComponentProps";
import { generalStyles } from "../../../shared/styles/generalStyles.styles";

export const MerchantWithoutHierarchyComponent: FC<
  IMerchantWithoutHierarchyComponentProps
> = (props: PropsWithChildren<IMerchantWithoutHierarchyComponentProps>) => {
  const {
    control,
    errors,
    listMerchantsItems,
    onItemSelectWithoutHierarchy,
    handleOnChangeInputWithoutHierarchy,
    defaultValueSelect,
    id,
    isCreationRule,
    isWhiteList,
    showSearchingTextMessage,
  } = useMerchantWithoutHierarchyState(props);
  const classes = generalStyles();

  return (
    <Grid container spacing={1.5}>
      <Grid item xs={12}>
        <Typography className={classes.subtitleTypography}>
          {isWhiteList
            ? SubTitleSection.RULES_CREATE_MERCHANT_WHITELIST_WITHOUT_HIERARCHY_SUBTITLE
            : SubTitleSection.RULES_CREATE_MERCHANT_WITHOUT_HIERARCHY_SUBTITLE}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {(isFormEditMode(id) || isCreationRule) && defaultValueSelect ? (
          <>
            <Controller
              name={"withoutHierarchy"}
              control={control}
              rules={{
                required: { message: ErrorsEnum.REQUIRED_FIELD, value: true },
              }}
              defaultValue={defaultValueSelect}
              as={
                <Grid item xs={12} md={8.5} lg={5}>
                  <SelectSearchBy
                    isDisabled={!isCreationRule}
                    defaultValue={defaultValueSelect}
                    items={listMerchantsItems}
                    placeholder={
                      SubTitleSection.RULES_SEARCH_MERCHANT_PLACEHOLDER
                    }
                    onItemSelected={onItemSelectWithoutHierarchy}
                    onInputChange={handleOnChangeInputWithoutHierarchy}
                    isEmptyListMessage={
                      showSearchingTextMessage
                        ? SubTitleSection.SEARCHING_MERCHANT
                        : undefined
                    }
                  />
                </Grid>
              }
            />
          </>
        ) : (
          <Controller
            name={"withoutHierarchy"}
            control={control}
            rules={{
              required: { message: ErrorsEnum.REQUIRED_FIELD, value: true },
            }}
            as={
              <Grid item xs={12} md={8.5} lg={5}>
                <SelectSearchBy
                  items={listMerchantsItems}
                  placeholder={
                    SubTitleSection.RULES_SEARCH_MERCHANT_PLACEHOLDER
                  }
                  onItemSelected={onItemSelectWithoutHierarchy}
                  onInputChange={handleOnChangeInputWithoutHierarchy}
                  isEmptyListMessage={
                    showSearchingTextMessage
                      ? SubTitleSection.SEARCHING_MERCHANT
                      : undefined
                  }
                />
              </Grid>
            }
          />
        )}
        <FormHelperText
          error={retrieveFormError(errors, "withoutHierarchy")[0]}
        >
          {retrieveFormError(errors, "withoutHierarchy")[1]}
        </FormHelperText>
      </Grid>
    </Grid>
  );
};
