import { makeStyles } from "@mui/styles";

export const securityRuleDetailStyles = makeStyles({
  snackbarError: {
    backgroundColor: "#e85757",
    color: "white",
  },
  snackbarSuccess: {
    backgroundColor: "#bcd6ec",
    color: "#023365",
  },
  divider: {
    height: "0px",
    border: "1px solid #E2E8F0",
    backgroundColor: "#E2E8F0",
  },
});
