import { IStyles } from "../../../shared/Interfaces/Styles.interfaces";

export const toggleInputStyles: IStyles = {
  toggleInputDefaultStyles: {
    pt: 1,
    display: "flex",
    columnGap: (theme) => theme.spacing(1),
  },
  toggleButton: {
    backgroundColor: "#FBFCFE !important",
    py: 3,
    px: 2,
    borderRadius: 0.5,
    borderLeft: "1.5px solid !important",
    borderRight: "1.5px solid !important",
    border: "1.5px solid #EEF6FF !important",
    color: "#023365 !important",
    textTransform: "none",
    margin: "0px !important",
    "&.Mui-selected": {
      border: "1.75px solid #0DC298 !important",
      borderLeft: "1.75px solid #0DC298 !important",
      borderRight: "1.75px solid #0DC298 !important",
    },
  },
};
