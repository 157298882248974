import { CountryNameEnum } from "../enums/CountryNameEnum";
import { FilterTitlesEnum } from "../enums/FilterTitlesEnum";
import { ProcessorsEnum } from "../enums/ProcessorsEnum";
import { PaymentMethodEnum } from "../enums/PaymentMethodEnum";

export interface SelectCountry {
  label: string;
  value: CountryNameEnum | FilterTitlesEnum;
}

export const COUNTRIES: SelectCountry[] = [
  { label: "País", value: FilterTitlesEnum.country },
  { label: "México", value: CountryNameEnum.mexico },
  { label: "Perú", value: CountryNameEnum.peru },
  { label: "Chile", value: CountryNameEnum.chile },
  { label: "Colombia", value: CountryNameEnum.colombia },
];

export interface SelectPaymentMethod {
  label: string;
  value: PaymentMethodEnum | FilterTitlesEnum;
}

export const PAYMENT_METHODS: SelectPaymentMethod[] = [
  { label: "Medio de pago", value: FilterTitlesEnum.paymentMethod },
  { label: "Tarjeta", value: PaymentMethodEnum.card },
];

export const PROCESSORS: string[] = [
  FilterTitlesEnum.processor,
  ProcessorsEnum.kushkiAcquirerProcessor,
];
