import * as React from "react";
import { FC } from "react";
import { ICellChipProps } from "./CellChip.interfaces";
import { Chip } from "@mui/material";
import { StatusEnum } from "../../../shared/enums/StatusEnum";
import { defaultTo } from "lodash";
import { CatalogTranslateStatus } from "../../../shared/catalogs/CatalogTranslateStatus";

const getColorByStatus = (status: string) => {
  switch (status) {
    case StatusEnum.ACTIVE:
      return "success";
    case StatusEnum.INACTIVE:
      return "error";
    case StatusEnum.PENDING:
      return "warning";
    case StatusEnum.CENTRALIZED:
      return "primary";
    case StatusEnum.DECENTRALIZED:
      return "info";
    default:
      return "info";
  }
};

export const CellChipStatus: FC<ICellChipProps> = ({
  text,
}: ICellChipProps) => {
  const color_status = getColorByStatus(defaultTo(text, ""));
  const label_text: string = defaultTo(
    CatalogTranslateStatus[defaultTo(text, "")],
    ""
  );

  return (
    <Chip
      data-testid={"chip-status"}
      label={label_text}
      size="small"
      color={color_status}
    />
  );
};
