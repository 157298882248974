import React from "react";
import { ModalSimple, TableSimple } from "@kushki/connect-ui";

import { useAlarmTableSate } from "@components/AlarmTable/state/useAlarmTableSate";
import {
  FOOTER_TEXT,
  ITEMS_PER_PAGE,
} from "@shared/constants/AlarmTableConstants";
import { SecurityWrapperRoles } from "@shared/enum/SecurityWrapperRoles";
import { Box } from "@mui/material";
import { RulesAlarmStyles as styles } from "@containers/RulesAlarm/RulesAlarm.styles";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { AlarmFilters } from "@components/Filters/AlarmFilters/AlarmFilters";
import { DEFAULT_TABLE_CELLS } from "@shared/constants/TableCells";
import { EditAlarmModalLabels } from "@shared/constants/labels/editAlarmModalLabels";
import { IAlarmTableProps } from "@components/AlarmTable/AlarmTable.interfaces";
import { ModalDetailCard } from "@kushki/connect-ui/dist/Components/Organism/ModalDetailCard";
import { AlarmDetailCardEnum } from "@shared/enum/AlarmDetailCardEnum";
import DetailCardBody from "@components/DetailCardBody/DetailCardBody";

export const AlarmTable: React.FC<IAlarmTableProps> = ({
  tabType,
}: IAlarmTableProps) => {
  const {
    rows,
    footer,
    isLoading,
    total,
    modalAction,
    autocompleteProps,
    handleApplyFilters,
    handleSearchDate,
    isOpenEditModal,
    onCloseEditModal,
    onContinueEditModal,
    searchInputProps,
    detailCard,
    isSearchLoading,
  } = useAlarmTableSate({ tabType });

  const {
    handleAutocompleteInput,
    handleSelectedChange,
    itemsSearch,
    inputValueProp,
    historicSearchItems,
  } = autocompleteProps;

  return (
    <React.Fragment>
      <Box>
        <AlarmFilters
          handleAutocompleteInput={handleAutocompleteInput}
          handleSelectedChange={handleSelectedChange}
          itemsSearch={itemsSearch}
          inputValueProp={inputValueProp}
          historicSearchItems={historicSearchItems}
          handleApplyFilters={handleApplyFilters}
          handleSearchDate={handleSearchDate}
          tabType={tabType}
          searchInputProps={searchInputProps}
          isLoading={isSearchLoading}
        />
      </Box>
      <ComponentSecurityWrapper
        componentId={SecurityWrapperRoles.M_COMPLIANCE_CREATE_ALARM_LIST}
      >
        <Box sx={styles.tableContainer}>
          <TableSimple
            header={DEFAULT_TABLE_CELLS(tabType)}
            rows={rows}
            showPagination
            isLoading={isLoading}
            footerPagination={{
              isDisable: false,
              itemsPerPage: footer.itemsPerPage,
              onItemsPerPageChange: footer.handleItemsPerPage,
              onPageClick: footer.handleSelectedPage,
              paginationText: FOOTER_TEXT,
              selectedPage: footer.selectedPage,
              textFieldSelector: ITEMS_PER_PAGE,
              totalItems: total,
            }}
          />
        </Box>
      </ComponentSecurityWrapper>
      <ModalSimple {...modalAction} />
      <ModalSimple
        onClickPrimary={onContinueEditModal}
        onClickSecondary={onCloseEditModal}
        buttonPrimary={EditAlarmModalLabels.CONTINUE}
        buttonSecondary={EditAlarmModalLabels.RETURN}
        descriptionText={EditAlarmModalLabels.DESCRIPTION}
        isOpen={isOpenEditModal}
        onClose={onCloseEditModal}
        titleText={EditAlarmModalLabels.TITLE}
        typeModal={1}
      />
      {/*// @ts-ignore*/}
      <ModalDetailCard
        header={{
          buttonRow: detailCard.buttonsRow,
          secondaryText: detailCard.updatedBy,
          tags: [
            { color: detailCard.tag.color as never, text: detailCard.tag.text },
          ],
          title: AlarmDetailCardEnum.TITLE,
          titleDescription: detailCard.createdBy,
        }}
        onClose={detailCard.handleClose}
        widthCard={"576px"}
        isOpen={detailCard.isOpen}
      >
        <DetailCardBody tabType={tabType} />
      </ModalDetailCard>
    </React.Fragment>
  );
};
