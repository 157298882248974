import { useEffect, useState } from "react";
import { selectNodeInfo } from "../../../store/selectors/selectors";
import {
  setBillingConfigResponse,
  setMerchantBillingInfoResponse,
} from "../../../store/actions/actions";
import { IPostMerchantBillingInfoResponse } from "../../../../types/merchant_billing_info_response";
import { IGetBillingConfigResponse } from "../../../../types/billing_config_response";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { IBillingResume } from "./useBillingResume.interface";
import { ConfigurationIdEnum } from "../../../shared/enums";
import { EntityNameEnum } from "../../../shared/enums/entityName";
import {
  getSectionInfo,
  statusNodeInfo,
} from "../../../shared/utils/node_info";

const useBillingResume = (): IBillingResume => {
  const [loading, setLoading] = useState(true);
  const [showBillingResume, setShowBillingResume] = useState(false);
  const nodeInfoData = useSelector(selectNodeInfo);
  const dispatch = useDispatch();

  const getData = async (publicMerchantId: string) => {
    setLoading(true);
    try {
      const [merchantBillingInfoResponse, billingConfigResponse] =
        await Promise.all([
          axiosInstance.post<IPostMerchantBillingInfoResponse>(
            API_ROUTES.BILLING_CORE_MERCHANT_NODE_INFO,
            {
              configId: ConfigurationIdEnum.CN003,
              publicMerchantId: `${publicMerchantId}`,
            }
          ),
          axiosInstance.get<IGetBillingConfigResponse>(
            `${API_ROUTES.GET_BILLING_CONFIG}/${publicMerchantId}`
          ),
        ]);

      dispatch(
        setMerchantBillingInfoResponse(merchantBillingInfoResponse.data)
      );
      dispatch(setBillingConfigResponse(billingConfigResponse.data));
      setShowBillingResume(true);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (nodeInfoData) {
      if (
        nodeInfoData.entityName === EntityNameEnum.CUSTOMER ||
        nodeInfoData.entityName === EntityNameEnum.BRANCH
      ) {
        let sectionInfo = getSectionInfo(
          ConfigurationIdEnum.CN003,
          nodeInfoData.configs
        );

        if (sectionInfo) {
          if (sectionInfo.status === statusNodeInfo.COMPLETE) {
            getData(sectionInfo.value);
          }
        }
      }
    }
  }, [nodeInfoData]);

  return { loading, showBillingResume };
};

export default useBillingResume;
