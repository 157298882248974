import React, { Suspense } from "react";
import {
  Button,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";
import { connect } from "react-redux";
import { IBillingDashboardState, INotification } from "../../store/reducer";
import { InvoiceRecord } from "../../../types/remote/response_search_blling_by_filters";
import { useBillingInvoiceState } from "./state/useBillingInvoiceState";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  BillingActions,
  createInvoice,
  getInvoiceData,
  setLoadingInvoice,
  setNotification,
  setOpenModalAnnul,
  setRedirectCreateInvoice,
} from "../../store/actionCreators";
import { SnackbarAlert } from "../../components/Snackbar/SnackbarAlert";
import { BillingInvoiceBody } from "../../components/BillingInvoiceBody/BillingInvoiceBody";
import { get } from "lodash";
import { InvoiceTransaction } from "../../../types/invoice_transaction";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import { Skeleton } from "@material-ui/lab";
import { routes } from "../../shared/infrastructure/routes";
import { useBillingInvoiceStyles } from "./BillingInvoice.style";
import { RenewInvoiceRequest } from "../../../types/renew_invoice_request";

export interface BillingInvoiceStateProps {
  historicTrx: InvoiceRecord;
  invoiceTrx: InvoiceTransaction;
  country: CountryEnum;
  notification: INotification;
  isLoadingInvoice: boolean;
}
export interface BillingInvoiceFunctionsProps {
  getInvoiceData: (params: { invoiceId: string }) => void;
  setNotification: (payload: INotification) => void;
  setLoadingInvoice: (payload: boolean) => BillingActions;
  createInvoice: (payload: RenewInvoiceRequest) => void;
  setRedirectCreateInvoice: (payload: boolean) => void;
  setOpenModalAnnul: (payload: boolean) => void;
}

export type BillingInvoiceProps = BillingInvoiceStateProps &
  BillingInvoiceFunctionsProps;

export const BillingInvoice = (props: BillingInvoiceProps) => {
  const classes = useBillingInvoiceStyles();
  const {
    redirectNextStep,
    invoiceBilling,
    form,
    handleCloseNotification,
  } = useBillingInvoiceState(props);

  return (
    <React.Fragment>
      <Container>
        <form>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography
                variant={"h1"}
                color={"primary"}
                className={classes.mainTitle}
              >
                Generar nueva factura
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent style={{ padding: "32px" }}>
                  <Grid container spacing={3} direction="column">
                    <Grid item xs={12}>
                      <Typography className={classes.titleCardHeader}>
                        Datos de la nueva factura
                      </Typography>
                      <Suspense fallback={<div />}>
                        {props.isLoadingInvoice ? (
                          <React.Fragment>
                            <Grid container spacing={1} direction={"column"}>
                              <Grid item xs={6}>
                                <Skeleton
                                  animation="wave"
                                  variant="rect"
                                  width={"100%"}
                                  height={30}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Skeleton
                                  animation="wave"
                                  variant="rect"
                                  width={"100%"}
                                  height={30}
                                />
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <Typography className={classes.subTitleHeader}>
                              Comercio:{" "}
                              {get(invoiceBilling, "merchant_name", "")}
                            </Typography>
                            <Typography className={classes.subTitleHeader}>
                              Tax ID asociado Nº:
                              {get(invoiceBilling, "tax_id", "")}
                            </Typography>
                          </React.Fragment>
                        )}
                      </Suspense>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography className={classes.titleHeader}>
                        Ingrese el monto a facturar
                      </Typography>
                      <BillingInvoiceBody
                        isLoadingInvoice={props.isLoadingInvoice}
                        invoiceBilling={invoiceBilling}
                        formChange={form.formChange}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item container spacing={1} justify="flex-end">
              <Grid item xs={12} md={2}>
                <Button
                  fullWidth
                  onClick={() =>
                    redirectNextStep(
                      `${routes.BASE_PATH_DASHBOARD}${routes.DASHBOARD_EXECUTOR}`
                    )
                  }
                  style={{
                    fontSize: "16px",
                  }}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={12} md={2}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    form.submit();
                  }}
                  disabled={props.isLoadingInvoice}
                  style={{
                    fontSize: "16px",
                  }}
                >
                  Generar nueva factura
                </Button>
              </Grid>
            </Grid>
            <SnackbarAlert
              type={props.notification.type}
              msg={props.notification.message}
              open={props.notification.open}
              handlerClose={handleCloseNotification}
            />
          </Grid>
        </form>
      </Container>
    </React.Fragment>
  );
};

export const mapDispatchToProps: (
  dispatch: Dispatch
) => BillingInvoiceFunctionsProps = (
  dispatch: ThunkDispatch<IBillingDashboardState, undefined, BillingActions>
): BillingInvoiceFunctionsProps => ({
  getInvoiceData: (params: { invoiceId: string }): void =>
    dispatch(getInvoiceData(params)),
  setNotification: (payload: INotification) =>
    dispatch(setNotification(payload)),
  setLoadingInvoice: (payload: boolean): BillingActions =>
    dispatch(setLoadingInvoice(payload)),
  createInvoice: (payload: RenewInvoiceRequest): void =>
    dispatch(createInvoice(payload)),
  setRedirectCreateInvoice: (payload: boolean): BillingActions =>
    dispatch(setRedirectCreateInvoice(payload)),
  setOpenModalAnnul: (payload: boolean): BillingActions =>
    dispatch(setOpenModalAnnul(payload)),
});

export const mapStateToProps: (
  state: IBillingDashboardState
) => BillingInvoiceStateProps = (
  state: IBillingDashboardState
): BillingInvoiceStateProps => ({
  historicTrx: state.historicTrx,
  invoiceTrx: state.invoiceTrx,
  country: state.country as CountryEnum,
  notification: state.notification,
  isLoadingInvoice: state.isLoadingInvoice,
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingInvoice);
