import { RefObject, useEffect, useState } from "react";
import { Control, FieldError, NestDataObject, useForm } from "react-hook-form";
import {
  SmartlinksCustomization,
  SmartlinksLogos,
} from "../../../../types/smartlink_customization";
import { MerchantResponse } from "../../../../types/merchant_response";
import { get, isEmpty } from "lodash";
import { SmartlinkResponse } from "../../../../types/smartlink_get_response";
import { SmartlinkRequest } from "../../../../types/smartlink_post_request";
import { useParams } from "react-router-dom";

export interface AccountPreference {
  logos: SmartlinksLogos[];
  selectedLogo: SmartlinksLogos | undefined;
  email: string;
  phone: string;
  termsAndConditions: string;
  mainColor: string;
  secondaryColor: string;
  smartLinkValue: string;
  smartlinkError: boolean | null | undefined;
}

export interface AccountPreferencesState {
  isLoading: boolean;
  accountPreference: AccountPreference;
  currentColorPicker: string | undefined;
  handler: {
    handlerSetValue: (value: string, kind: string) => void;
    handlerRemoveLogo: (logo: SmartlinksLogos) => void;
    handleFileChange: (value: {
      prop: string;
      files?: SmartlinksLogos;
    }) => void;
    handleDeleteFile: (result: {
      prop: string;
      files?: SmartlinksLogos[];
    }) => void;
    setCurrentColorPicker: (value: string) => void;
    handlerContinue: (brandPersonalization?: boolean) => void;
    handlerEditLogoName: (id: string, value: string) => void;
  };
  form: {
    errors: NestDataObject<Record<string, any>, FieldError>;
    register: (
      obj: object
    ) => ((instance: any) => void) | RefObject<any> | null | undefined;
    control: Control<Record<string, any>>;
  };
}

export interface useAccountPreferencesStateProps {
  merchant: MerchantResponse | undefined;
  smartLinkInfo: SmartlinkResponse | undefined;
  setMerchantCustomization: (
    merchantId: string,
    payload: SmartlinksCustomization
  ) => void;
  setSmartlink: (body: SmartlinkRequest) => void;
}

export const useAccountPreferencesState = (
  props: useAccountPreferencesStateProps
): AccountPreferencesState => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    const customization = props.merchant;
    const selectedLogo = get(customization, "logos", []).find(
      // @ts-ignore
      (logo) => logo.predetermined === true
    );
    const currentPreference = {
      logos: get(customization, "logos", []),
      selectedLogo,
      mainColor: get(customization, "colors.primary", "#00E6B2"),
      secondaryColor: get(customization, "colors.secondary", "#023365"),
      termsAndConditions: get(customization, "termsAndConditions", ""),
      phone: get(customization, "contact.phoneNumber", ""),
      email: get(customization, "contact.email", ""),
      smartLinkValue: get(props.smartLinkInfo, "subdomain", ""),
      smartlinkError: null,
    };
    setAccountPreference(currentPreference);
  }, [props.merchant, props.smartLinkInfo]);

  const [accountPreference, setAccountPreference] = useState<AccountPreference>(
    {
      logos: [],
      selectedLogo: undefined,
      email: "",
      phone: "",
      termsAndConditions: "",
      mainColor: "#00E6B2",
      secondaryColor: "#023365",
      smartLinkValue: "",
      smartlinkError: false,
    }
  );

  const [currentColorPicker, setCurrentColorPicker] = useState<string>();

  const { register, errors, control } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const handlerSetValue = (value: string, kind: string) => {
    if (kind === "selectedLogo") {
      const logo = accountPreference.logos.find(
        (logo: SmartlinksLogos) => logo.alias === value
      );
      setAccountPreference({
        ...accountPreference,
        [kind]: logo,
      });
    } else {
      setAccountPreference({
        ...accountPreference,
        [kind]: value,
      });
    }
  };

  const handlerRemoveLogo = (logo: SmartlinksLogos) => {
    const logos = accountPreference.logos.filter(
      (item) => item.alias !== logo.alias
    );
    setAccountPreference({
      ...accountPreference,
      logos,
    });
  };

  const handlerEditLogoName = (id: string, value: string) => {
    const logos = accountPreference.logos.map((logo: SmartlinksLogos) => {
      if (logo.alias === id) {
        const alias = value;
        return { ...logo, alias };
      }
      return logo;
    });
    setAccountPreference({
      ...accountPreference,
      logos,
    });
  };

  const handleFileChange = (value: {
    prop: string;
    files?: SmartlinksLogos;
  }) => {
    if (!isEmpty(value.files)) {
      setAccountPreference({
        ...accountPreference,
        logos: [...accountPreference.logos, value.files!],
      });
    }
  };

  const handleDeleteFile = (result: {
    prop: string;
    files?: SmartlinksLogos[];
  }) => {
    setAccountPreference({
      ...accountPreference,
      logos: result.files || [],
    });
  };

  const normalizeLogos = (logos: SmartlinksLogos[]) => {
    const nLogos = logos.map((logo: SmartlinksLogos) => {
      return {
        alias: logo.alias,
        image: logo.image,
        delete: false,
        predetermined: false,
        extension: logo.extension,
      };
    });
    if (accountPreference.selectedLogo) {
      const selected = nLogos.find(
        (logo) => accountPreference.selectedLogo?.alias === logo.alias
      );
      if (selected) selected.predetermined = true;
    }
    return nLogos;
  };

  const normalizePreferences = (
    accountPreference: AccountPreference
  ): SmartlinksCustomization => {
    return {
      logos: normalizeLogos(accountPreference.logos),
      colors: {
        primary: accountPreference.mainColor,
        secondary: accountPreference.secondaryColor,
      },
      contact: {
        email: accountPreference.email,
        phoneNumber: accountPreference.phone,
      },
      termsAndConditions: accountPreference.termsAndConditions,
    };
  };

  const params = useParams<{ merchantId: string }>();
  const handlerContinue = (brandPersonalization?: boolean) => {
    setIsLoading(true);
    const body = normalizePreferences(accountPreference);
    props.setMerchantCustomization(params.merchantId, body);
    if (!!brandPersonalization) return;
    const smartlinkRequest: SmartlinkRequest = {
      publicMerchantId: props.merchant!.publicMerchantId,
      country: get(props, "smartLinkInfo.country", ""),
      merchantName: get(props, "smartLinkInfo.merchantName", ""),
      status: get(props, "smartLinkInfo.status", false),
      webcheckoutStatus: get(props, "smartLinkInfo!.webcheckoutStatus", false),
      subdomain: accountPreference.smartLinkValue,
    };

    props.setSmartlink(smartlinkRequest);
  };

  return {
    isLoading,
    accountPreference,
    currentColorPicker,
    handler: {
      handlerSetValue,
      handlerRemoveLogo,
      handleFileChange,
      handleDeleteFile,
      setCurrentColorPicker,
      handlerContinue,
      handlerEditLogoName,
    },
    form: {
      register,
      errors,
      control,
    },
  };
};
