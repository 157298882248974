import { theme } from "@kushki/connect-ui";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { ROUTES, SPA_BASENAME } from "./shared/constants/routes";

import { store } from "./store/store";
import { TransactionContainer } from "./containers/TransactionContainer/TransactionContainer";
import { Provider as RollbarProvider } from "@rollbar/react";
import { getRollbarConfig } from "./shared/constants/GetRollbarConfig";

const Root = () => {
  return (
    <React.StrictMode>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&display=swap');"
        }
      </style>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500&display=swap');"
        }
      </style>
      <RollbarProvider config={getRollbarConfig}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <BrowserRouter basename={SPA_BASENAME}>
              <Routes>
                <Route path={ROUTES.INDEX} element={<TransactionContainer />} />
              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        </Provider>
      </RollbarProvider>
    </React.StrictMode>
  );
};

export default Root;
