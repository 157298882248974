import esLocale from "date-fns/locale/es";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  today,
  yesterday,
} from "../../../components/common/DateTimeRangePicker/utils/functions";
import {
  IDateFilter,
  IFiltersState,
  IGeneralFilter,
  IPaginationFilter,
} from "../../interfaces/FiltersState.interfaces";

export const initialState: IFiltersState = {
  date: {
    from: `${yesterday(esLocale)} 00:00`,
    timeZone: "",
    to: `${today(esLocale)} 23:59`,
  },
  general: {
    paymentMethod: [],
    transactionStatus: [],
  },
  pagination: {
    limit: 10,
    page: 1,
    resetPagination: true,
    total: 0,
  },
  search: {
    text: "",
  },
  sortOrder: "asc",
};

export const filtersSlice = createSlice({
  initialState,
  name: "filters",
  reducers: {
    setDateFilter: (state, { payload }: PayloadAction<IDateFilter>) => {
      state.pagination.page = 1;
      state.pagination.resetPagination = true;
      state.date = payload;
    },
    setGeneralFilter: (state, { payload }: PayloadAction<IGeneralFilter>) => {
      state.pagination.page = 1;
      state.pagination.resetPagination = true;
      state.general = payload;
    },
    setPaginationFilter: (
      state,
      { payload }: PayloadAction<IPaginationFilter>
    ) => {
      state.pagination = payload;
    },
    setSearchFilter: (state, { payload }: PayloadAction<string>) => {
      state.pagination.page = 1;
      state.pagination.resetPagination = true;
      state.search.text = payload;
    },
    setSortOrder: (state) => {
      state.sortOrder = state.sortOrder === "asc" ? "desc" : "asc";
    },
    setTransactionsLimit: (state, { payload }: PayloadAction<number>) => {
      state.pagination.page = 1;
      state.pagination.resetPagination = true;
      state.pagination.limit = payload;
    },
    setTransactionsPage: (state, { payload }: PayloadAction<number>) => {
      state.pagination.resetPagination = false;
      state.pagination.page = payload;
    },
    setTransactionsTotal: (state, { payload }: PayloadAction<number>) => {
      state.pagination.total = payload;
    },
  },
});

export default filtersSlice.reducer;
