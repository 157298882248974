import React from "react";
import { EcuadorPeruMerchantDeferredResponse } from "../../../types/merchant_deferred_response";
import { Grid, Typography } from "@mui/material";
import { ecuadorDeferredInfoStyles as styles } from "../EcuadorDeferredInfo/EcuadorDeferredInfo.styles";
import { validateIcon } from "../../shared/utils/utils";
import { ICountryDeferredInfo } from "./PeruDeferredInfo.interfaces";
import { DEFERRED_LABELS } from "../../shared/constants/labels/deferred_info_labels";

const PeruDeferredInfo = (props: ICountryDeferredInfo) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} data-testid={"peru-def-info"}>
          <Typography variant="body2">{DEFERRED_LABELS.PERU_INFO}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} direction={"row"} alignItems={"center"}>
            {props.deferredInfo.map(
              (data: EcuadorPeruMerchantDeferredResponse, index: number) => (
                <Grid item key={index}>
                  <Grid item sx={styles.imgTemplate}>
                    {validateIcon(data.brand)}
                  </Grid>
                </Grid>
              )
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            {DEFERRED_LABELS.DEFERRED_FEES_INFO}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default PeruDeferredInfo;
