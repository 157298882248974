import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { headerTableItem } from "../../shared/infrastructure/constants/WalletDashboardConstants";

export const TableSkeleton = () => {
  return (
    <>
      {headerTableItem.map((data: string, index: number) => (
        <TableRow key={`tableRow-${index}-${data}`} component="th" scope="row">
          {headerTableItem.map((data: string, index: number) => (
            <TableCell key={`tableCell-${data}-${index}`}>
              <Skeleton variant="text" animation="wave" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};
