import React, { useEffect } from "react";
import { Box, Divider } from "@mui/material";
import MainTextFieldSelect from "../MainTextFieldSelect/MainTextFieldSelect";
import { OwnerEnum } from "../../shared/enums/OwnerEnum";
import { customerHeaderStyles as styles } from "./customerHeader.styles";
import { useCustomerHeader } from "./state/useCustomerHeader";
import { ICustomerHeader } from "./CustomerHeader.interfaces";
import { Tab, Tabs } from "@kushki/connect-ui";
import { MerchantNodeData } from "../../../types/search_merchant_response";
import { CATALOG_HEADS_NODE } from "../../shared/catalogs/CatalogConfigTable";
import { TableSkeleton } from "../TableSkeleton/TableSkeleton";
import HeaderSkeleton from "../HeaderSkeleton/HeaderSkeleton";
import { EntityNameEnum } from "../../shared/enums/EntityNameEnum";
import { displayUtils } from "../../shared/utils/DisplayUtils";
import {
  BRANCH_TAB_LABEL,
  CUSTOMER_TAB_LABEL,
} from "../../shared/constants/labels/main_tabs_labels";
import { CustomerList } from "../CustomerList/CustomerList";
import BranchList from "../BranchList/BranchList";

const CustomerHeader: React.FC<ICustomerHeader> = ({
  onClickAddBtn,
  emptyAddText,
  showEmptyScreen,
}: ICustomerHeader) => {
  const {
    changeTab,
    closeSlider,
    createCustomer,
    handleSearchNode,
    isOpenSlider,
    listOwners,
    defaultValue,
    queryParam,
    pathCustomer,
    entityName,
    customerList,
    modalStatusDashboard,
    handleChangeStatus,
    isLoadingMerchants,
    isLoadingFlow,
  } = useCustomerHeader();

  const [tabIndex, setTabIndex] = React.useState<number>(0);

  useEffect(() => {
    changeTab(tabIndex);
  }, [tabIndex]);

  return (
    <React.Fragment>
      <Box display={displayUtils(!isLoadingFlow)}>
        <HeaderSkeleton />
        <TableSkeleton
          headTable={CATALOG_HEADS_NODE[EntityNameEnum.CUSTOMER]}
          rowsPerPage={10}
        />
      </Box>
      <Box display={displayUtils(isLoadingFlow)}>
        <Box>
          <Box sx={styles.selectWrapper}>
            <Box sx={styles.select}>
              <MainTextFieldSelect
                addButtonText={OwnerEnum.ADD_OWNER}
                placeholder={OwnerEnum.OWNER}
                items={listOwners}
                onItemSelected={handleSearchNode}
                onAddButtonChange={onClickAddBtn}
                value={defaultValue}
              />
            </Box>
            <Divider sx={styles.divider} />
          </Box>
          <Box sx={styles.mainBox}>
            <Box position="static" sx={styles.tabContainer}>
              <Tabs
                onChange={setTabIndex}
                orientation={"horizontal"}
                variant={"standard"}
              >
                <Tab
                  text={CUSTOMER_TAB_LABEL}
                  variant={"primary"}
                  defaultTab={true}
                />
                <Tab text={BRANCH_TAB_LABEL} variant={"primary"} />
              </Tabs>
            </Box>
          </Box>
        </Box>
        {tabIndex === 0 ? (
          <CustomerList
            actionBtn={createCustomer}
            dashboard={{
              basicNodeInfo: {
                entityName,
                path: pathCustomer,
              },
              customerNode: {} as MerchantNodeData,
              emptyAddText,
              handleChangeStatus,
              modalStatusDashboard,
              nodeData: customerList,
              showEmptyScreen,
            }}
            isLoadingMerchants={isLoadingMerchants}
            slide={{
              closeModal: closeSlider,
              isOpen: isOpenSlider,
              queryParam,
            }}
          />
        ) : (
          <BranchList />
        )}
      </Box>
    </React.Fragment>
  );
};

export default CustomerHeader;
