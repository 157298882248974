import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { merchantNodeInfoDefaultState } from "../../../shared/constants/initial_merchant_node_info";
import { ConfigIdEnum } from "../../../shared/constants/node_config_enums";
import { ROUTES } from "../../../shared/constants/routes";
import { CountriesEnum } from "../../../shared/enums/countriesEnum";
import { FetchStateEnum } from "../../../shared/enums/fetchStateEnum";
import { removeAccents } from "../../../shared/utils/parseData/parse_data";
import { AppState } from "../../interfaces/app.interfaces";
import { defaultTo, get, set } from "lodash";
import {
  getMerchantNodeInfo,
  getNodeInfo,
  invokeSemaphoreData,
  patchMerchantNodeInfo,
  postHierarchyNodeConfig,
  ratesBillingConfig,
} from "../../thunks/app/app.thunks";
import { Configs } from "../../../../types/node_info_response";
import { Configs as ConfigInfoRequest } from "../../../../types/billing_core_info_request_schema";

export const initialState: AppState = {
  appState: {},
  currentRoute: ROUTES.BILLING,
  fetching: {},
  massiveCurrentRoute: ROUTES.MASSIVE_BILLING,
  massiveMostCurrentBranches: {},
  openMassiveModal: false,
  openModalLoader: false,
  openModalLoaderWS: false,
  showPanel: false,
};

export const appSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getNodeInfo.fulfilled, (state, { payload }) => {
      const constitutionalCountry = removeAccents(
        get(payload, "generalInfo.constitutionalCountry")
      );
      const country = removeAccents(
        get(payload, "generalInfo.country", CountriesEnum.MEXICO)
      );

      set(payload, "generalInfo.constitutionalCountry", constitutionalCountry);
      set(payload, "generalInfo.country", country);

      payload.configs.forEach((config) => {
        const configsList = {
          [`${ConfigIdEnum.CN003}`]: () => {
            state.massiveMostCurrentBranches.billing = payload;
          },
          [`${ConfigIdEnum.CN004}`]: () => {
            state.massiveMostCurrentBranches.dispersion = payload;
          },
          [`${ConfigIdEnum.CN017}`]: () => {
            state.massiveMostCurrentBranches.charge = payload;
          },
        };

        defaultTo(configsList[config.configuration], () => ({}))();
      });
      state.nodeInfo = payload;
    });
    builder.addCase(patchMerchantNodeInfo.pending, (state) => {
      state.openModalLoader = true;
      state.appState.patchMerchantNodeInfo = FetchStateEnum.PENDING;
    });
    builder.addCase(patchMerchantNodeInfo.fulfilled, (state) => {
      state.appState.patchMerchantNodeInfo = FetchStateEnum.SUCCESS;
    });
    builder.addCase(patchMerchantNodeInfo.rejected, (state) => {
      state.appState.patchMerchantNodeInfo = FetchStateEnum.FAILED;
    });
    builder.addCase(ratesBillingConfig.pending, (state) => {
      state.appState.postRatesBilling = FetchStateEnum.PENDING;
    });
    builder.addCase(ratesBillingConfig.rejected, (state) => {
      state.appState.postRatesBilling = FetchStateEnum.FAILED;
    });
    builder.addCase(ratesBillingConfig.fulfilled, (state) => {
      state.appState.postRatesBilling = FetchStateEnum.SUCCESS;
    });
    builder.addCase(postHierarchyNodeConfig.pending, (state) => {
      state.appState.postHierarchyNode = FetchStateEnum.PENDING;
    });
    builder.addCase(postHierarchyNodeConfig.fulfilled, (state, { payload }) => {
      const config: ConfigInfoRequest[] | undefined = get(
        payload.payload,
        "configs",
        []
      );

      state.showSnackbarSuccess = true;
      state.openModalLoader = false;
      state.appState.postHierarchyNode = FetchStateEnum.SUCCESS;
      if (state.configsResponse) {
        const configIndex: number = state.configsResponse.findIndex(
          (configTemp) =>
            get(configTemp, "configuration", "") ===
            get(config, "[0].configuration")
        );

        if (configIndex >= 0) {
          const configDataProcess: Configs = {
            ...get(config, "[0]"),
            centralizedNodesId: get(
              state.configsResponse[configIndex],
              "centralizedNodesId"
            ),
          };

          set(state.configsResponse, `[${configIndex}]`, configDataProcess);
        }
      }
    });
    builder.addCase(postHierarchyNodeConfig.rejected, (state) => {
      state.appState.postHierarchyNode = FetchStateEnum.FAILED;
    });
    builder.addCase(invokeSemaphoreData.pending, (state) => {
      state.appState.postSemaphore = FetchStateEnum.PENDING;
    });
    builder.addCase(invokeSemaphoreData.fulfilled, (state, { payload }) => {
      state.showSnackbarSuccess = true;
      state.openModalLoader = false;
      state.appState.postSemaphore = FetchStateEnum.SUCCESS;
      sessionStorage.setItem("semaphoreData", JSON.stringify(payload));
    });
    builder.addCase(invokeSemaphoreData.rejected, (state) => {
      state.appState.postSemaphore = FetchStateEnum.FAILED;
      state.showSnackbarError = true;
      state.openModalLoader = false;
    });
    builder
      .addCase(getMerchantNodeInfo.fulfilled, (state, { payload }) => {
        state.merchantNodeInfo = payload;
      })
      .addCase(getMerchantNodeInfo.rejected, (state) => {
        state.merchantNodeInfo = merchantNodeInfoDefaultState;
      });
  },
  initialState,
  name: "app",
  reducers: {
    CLEAN_NODE_INFO: (state: AppState) => {
      state.nodeInfo = undefined;
    },
    CLEAN_VALUES_AND_SHOW_SNACK_ERROR: (state: AppState) => {
      state.appState.postHierarchyNode = undefined;
      state.appState.postSemaphore = undefined;
      state.appState.patchMerchantNodeInfo = undefined;
      state.typeRequest = "";
      state.footerButtonsClicked = false;
      state.openModalLoader = false;
      state.showSnackbarError = true;
    },
    CLOSE_MODAL_LOADER: (state: AppState) => {
      state.openModalLoader = false;
    },
    ENABLE_SAVE_BUTTON: (state: AppState) => {
      state.appState.postSemaphore = undefined;
    },
    HANDLE_MASSIVE_MODAL_LOADER: (state: AppState, { payload }) => {
      state.openMassiveModal = payload;
    },
    HANDLE_MODAL_LOADER_WS: (state: AppState, { payload }) => {
      state.openModalLoaderWS = payload;
    },
    HIDE_SNACKBAR: (state: AppState) => {
      state.showSnackbarError = false;
      state.showSnackbarSuccess = false;
    },
    RESET_SHARED_VALUES: (state: AppState) => {
      state.appState.patchMerchantNodeInfo = undefined;
      state.appState.postHierarchyNode = undefined;
      state.footerButtonsClicked = false;
      state.openModalLoader = false;
      state.typeRequest = "";
    },
    SET_FOOTER_BUTTON_CLICKED: (state: AppState, { payload }) => {
      state.footerButtonsClicked = payload;
    },
    SET_TYPE_REQUEST: (state: AppState, { payload }: PayloadAction<string>) => {
      state.typeRequest = payload;
    },
    SHOW_ERROR_SNACKBAR: (state: AppState) => {
      state.showSnackbarError = true;
    },
    SHOW_SECTION: (state: AppState, { payload }: PayloadAction<boolean>) => {
      state.showPanel = payload;
    },
    SHOW_SUCCESS_SNACKBAR: (state: AppState) => {
      state.showSnackbarSuccess = true;
    },
    UPDATE_MASSIVE_ROUTE: (
      state: AppState,
      { payload }: PayloadAction<ROUTES>
    ) => {
      state.massiveCurrentRoute = payload;
    },
    UPDATE_ROUTE: (state: AppState, { payload }: PayloadAction<ROUTES>) => {
      state.currentRoute = payload;
    },
    updateLocalConfigResponse: (
      state: AppState,
      { payload }: PayloadAction<Configs[] | undefined>
    ) => {
      state.configsResponse = payload;
    },
  },
});

export const {
  RESET_SHARED_VALUES,
  ENABLE_SAVE_BUTTON,
  SET_TYPE_REQUEST,
  HIDE_SNACKBAR,
  SHOW_SECTION,
  CLEAN_NODE_INFO,
  UPDATE_ROUTE,
  UPDATE_MASSIVE_ROUTE,
  SET_FOOTER_BUTTON_CLICKED,
  SHOW_SUCCESS_SNACKBAR,
  SHOW_ERROR_SNACKBAR,
  CLOSE_MODAL_LOADER,
  HANDLE_MODAL_LOADER_WS,
  HANDLE_MASSIVE_MODAL_LOADER,
  CLEAN_VALUES_AND_SHOW_SNACK_ERROR,
  updateLocalConfigResponse,
} = appSlice.actions;

export default appSlice.reducer;
