export enum BillingDetailTypeEnum {
  B2C_CARD = "B2C_CARD",
  B2C_TRANSFER = "B2C_TRANSFER",
  B2C_CASH = "B2C_CASH",
  B2B_PAYIN_GATEWAY_CARD = "B2B_PAYIN_GATEWAY_CARD",
  B2B_PAYIN_GATEWAY_TRANSFER = "B2B_PAYIN_GATEWAY_TRANSFER",
  B2B_PAYIN_GATEWAY_CASH = "B2B_PAYIN_GATEWAY_CASH",
  B2B_PAYIN_AGGREGATOR_CARD = "B2B_PAYIN_AGGREGATOR_CARD",
  B2B_PAYIN_AGGREGATOR_TRANSFER = "B2B_PAYIN_AGGREGATOR_TRANSFER",
  B2B_PAYIN_AGGREGATOR_CASH = "B2B_PAYIN_AGGREGATOR_CASH",
  B2B_PAYOUT_TRANSFER = "B2B_PAYOUT_TRANSFER",
  B2B_PAYOUT_CASH = "B2B_PAYOUT_CASH",
  B2B_PAYOUT_CARD = "B2B_PAYOUT_CARD",
  MINIMAL_COMMISSION_FIXED = "MINIMAL_COMMISSION_FIXED",
  MINIMAL_COMMISSION_DEDUCTIBLE = "MINIMAL_COMMISSION_DEDUCTIBLE",
}
