import { get, set } from "lodash";
import { NodeInfoResponse } from "../../../types/node_info_response";
import { ILastModify } from "../../components/LastModify/LastModify.interfaces";
import { ITabItem } from "../../components/WrappedTabs/TabItem/TabItem.interfaces";
import { CountriesEnum } from "../enums/countriesEnum";
import { TabItemStatusEnum } from "../enums/tabItemStatusEnum";
import { getDate } from "../utils/date/date_utils";
import { ROUTES } from "./routes";
import { ConfigIdEnum, nodeConfigId } from "./node_config_enums";
import {
  mapStatusConfigIdByRoute,
  mapTabStatus,
} from "../utils/massiveBranchUtils";
import { BranchEditItem } from "../../../types/branch_edit_item";

export enum TabsTitlesEnum {
  BILLING = "Facturación",
  DISPERSION = "Dispersión",
  CHARGE = "Cobro",
  RATES = "Tarifas",
  DISCOUNTS = "Descuentos",
}

export const generateDefaultTabs = (isMassive = false): ITabItem[] => [
  {
    configId: nodeConfigId.BILLING,
    isDisabled: false,
    redirectPath: isMassive ? ROUTES.MASSIVE_BILLING : ROUTES.BILLING,
    status: TabItemStatusEnum.PENDING,
    title: TabsTitlesEnum.BILLING,
  },
  {
    configId: nodeConfigId.DISPERSION,
    isDisabled: false,
    redirectPath: isMassive ? ROUTES.MASSIVE_DISPERSION : ROUTES.DISPERSION,
    status: TabItemStatusEnum.PENDING,
    title: TabsTitlesEnum.DISPERSION,
  },
];

export const getRoutesByBranchBatch = (
  branchesBatch: BranchEditItem[],
  isMassive: boolean,
  currentRoute: string
): ITabItem[] => {
  const originalRoutes = generateDefaultTabs(isMassive);
  const currentConfig = mapStatusConfigIdByRoute.get(currentRoute as ROUTES);

  return originalRoutes.map((route) => {
    const branchBatch = branchesBatch.find(
      (branchBatch: any) => branchBatch.section === route.configId
    );
    let status;

    if (isMassive)
      status = mapTabStatus.get(
        get(branchBatch, "status", TabItemStatusEnum.PENDING)
      )!;
    else
      status =
        route.configId === currentConfig
          ? TabItemStatusEnum.IN_PROGRESS
          : get(branchBatch, "status", TabItemStatusEnum.PENDING);

    if (branchBatch) {
      set(route, "status", status);
    }

    return route;
  });
};

export const generateChargeTabs = (isMassive = false): ITabItem[] => [
  {
    configId: nodeConfigId.BILLING,
    isDisabled: false,
    redirectPath: isMassive ? ROUTES.MASSIVE_BILLING : ROUTES.BILLING,
    status: TabItemStatusEnum.PENDING,
    title: TabsTitlesEnum.BILLING,
  },
  {
    configId: nodeConfigId.DISPERSION,
    isDisabled: false,
    redirectPath: isMassive ? ROUTES.MASSIVE_DISPERSION : ROUTES.DISPERSION,
    status: TabItemStatusEnum.PENDING,
    title: TabsTitlesEnum.DISPERSION,
  },
  {
    configId: nodeConfigId.CHARGE,
    isDisabled: false,
    redirectPath: isMassive ? ROUTES.MASSIVE_CHARGE : ROUTES.CHARGE,
    status: TabItemStatusEnum.PENDING,
    title: TabsTitlesEnum.CHARGE,
  },
];

export const mapConfigurationByTabRoute = new Map([
  [ROUTES.BILLING, "cn003"],
  [ROUTES.DISPERSION, "cn004"],
  [ROUTES.CHARGE, "cn017"],
]);
export const mapConfigurationByTabRouteMassive = new Map([
  [ROUTES.MASSIVE_BILLING, ConfigIdEnum.CN003],
  [ROUTES.MASSIVE_DISPERSION, ConfigIdEnum.CN004],
  [ROUTES.MASSIVE_CHARGE, ConfigIdEnum.CN017],
]);

export const SUB_HEADER_DEFAULT_STATE = {
  country: "" as CountriesEnum,
  id: "",
  redirectPath: "",
  title: "",
};

export const buildSubHeaderTexts = (
  publicMerchantId: string | null,
  nodeInfo: NodeInfoResponse
) => ({
  country: get(nodeInfo, "generalInfo.country", CountriesEnum.MEXICO),
  id: get(nodeInfo, "merchantId", ""),
  redirectPath: `/create-node/customer?publicMerchantId=${publicMerchantId}`,
  title: get(nodeInfo, "generalInfo.name", ""),
});

export const buildLastModifyProps = (
  updatedAt: number,
  userName: string
): ILastModify => ({
  author: userName,
  date: getDate(updatedAt, "dd/MM/yyyy - HH:mm"),
});
