import React, { useEffect, useState } from "react";
import {
  DashboardHistoricTableProps,
  DashboardTable,
} from "../DashboardTable/DashboardTable";
import { CountryEnum } from "../../../shared/infrastructure/CountryEnum";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  AuthenticationRules,
  ResponseSearchRulesByFilters,
} from "../../../../types/response_search_rules_by_filters";
import { get } from "lodash";
import { RuleRequestRoles } from "../../../shared/infrastructure/constants/RuleRequestConstants";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

export interface SelectedRuleRequestTablePros {
  tableProps: DashboardHistoricTableProps;
}
const useStyles = makeStyles({
  boxFooter: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  rootFooter: {
    width: "100%",
    margin: "0 !important",
    padding: "10px 20px !important",
    background: "white",
    borderRadius: "10px",
  },
  selecteds: {
    color: "#6D7781",
  },
});

export const SelectedRuleRequestTable: React.FC<SelectedRuleRequestTablePros> =
  (props: SelectedRuleRequestTablePros) => {
    const classes = useStyles();
    const [trxSelectData, setTrxSelectData] =
      useState<ResponseSearchRulesByFilters>({});

    const orderDataDashboard = (data: AuthenticationRules[]) => {
      setTrxSelectData({
        records: data,
        total: get(props.tableProps.historicRulesData, "data.length", 0),
      });
    };
    useEffect(() => {
      setTrxSelectData({
        records: props.tableProps.historicRulesData.records,
        total: get(props.tableProps.historicRulesData, "records.length", 0),
      });
    }, [props.tableProps.historicRulesData]);
    return (
      <React.Fragment>
        <Box marginBottom={"50px"}>
          <DashboardTable
            selectionMode={true}
            mainTable={props.tableProps.mainTable}
            hideFilters={props.tableProps.hideFilters}
            isAnalyst={props.tableProps.isAnalyst}
            country={CountryEnum.colombia}
            historicRulesData={trxSelectData}
            trxSelected={props.tableProps.trxSelected}
            isLoading={props.tableProps.isLoading}
            page={props.tableProps.page}
            pageSize={props.tableProps.pageSize}
            paginationV2={true}
            handleChangePage={props.tableProps.handleChangePage}
            handleChangePageSize={props.tableProps.handleChangePageSize}
            hideNext={false}
            hidePrevious={false}
            filterProps={props.tableProps.filterProps}
            orderDataDashboard={orderDataDashboard}
            tableColumns={props.tableProps.tableColumns}
            handleSelectTrx={props.tableProps.handleSelectTrx}
            validateExistTrxs={props.tableProps.validateExistTrxs}
          />
          <Grid container spacing={3} className={classes.rootFooter}>
            <Grid xs={6}>
              <Box className={classes.boxFooter}>
                <Typography variant={"body2"} className={classes.selecteds}>
                  {`Solicitudes seleccionadas: ${props.tableProps.historicRulesData.records?.length}`}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={6}>
              <ComponentSecurityWrapper
                componentId={`${RuleRequestRoles.M_FRAUD_PREVENTION}.${RuleRequestRoles.RULE_REQUEST_AUTH_RULES_REASSIGN_ANALYST}`}
              >
                <Box className={classes.boxFooter} justifyContent={"flex-end"}>
                  <Button
                    aria-haspopup="true"
                    variant="outlined"
                    color="default"
                    onClick={() => {
                      props.tableProps.handleOpenAnalystAssignmentModal!(
                        props.tableProps.historicRulesData.records
                      );
                    }}
                  >
                    Reasignar analista
                  </Button>
                </Box>
              </ComponentSecurityWrapper>
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    );
  };
