import { Box, BoxProps } from "@mui/material";
import React, { FC } from "react";
import TableFilters from "./TableFilters/TableFilters";
import { actionBarStyles as styles } from "./ActionBar.styles";
import DownloadButton from "../common/DownloadButton/DownloadButton";
import { ARRAY_OPTIONS_DOWNLOAD } from "../../shared/constants/options_download";
import useDownloadTransactions from "../../hooks/useDownloadTransactions/useDownloadTransactions";
import { useTransactionsTableState } from "../TransactionsTable/state/useTransactionsTableState";

const ActionBar: FC<BoxProps> = ({ sx }: BoxProps) => {
  const containerStyles = { ...styles.container, ...sx };

  const {
    actions: { handleDownloadTransactions },
  } = useDownloadTransactions();
  const { transactions } = useTransactionsTableState();

  return (
    <Box sx={containerStyles}>
      <TableFilters />

      <Box sx={styles.btnDownload}>
        <DownloadButton
          options={ARRAY_OPTIONS_DOWNLOAD}
          handleClick={handleDownloadTransactions}
          disabled={transactions.length === 0}
        />
      </Box>
    </Box>
  );
};

export default ActionBar;
