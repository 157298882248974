import React, { FC } from "react";
import { StatusStepsState } from "../../../store/reducer";
import { ExpandedSection } from "../../../containers/MerchantDetailsIndex/state/useMerchantDetailsIndex";
import {
  StepData,
  StepName,
  StepsEnum,
} from "../../../shared/infrastructure/constants/CreateMerchantConstants";
import { MerchantStepSection } from "../MerchantStepSection/MerchantStepSection";
import { StatusSteps } from "../../../../types/remote/semaphore_data";
import { ServicesSection } from "../ServicesSection/ServicesSection";
import { ConfigRatesInvoiceSection } from "../ConfigRatesAndInvoiceSection/ConfigRatesAndInvoiceSection";
import { BasicDataSection } from "../BasicDataSection/BasicDataSection";
import { UserSection } from "../UsersSection/UserSection";
import { ProcessorsSection } from "../ProcessorsSection/ProcessorsSection";

export const CREATE_MERCHANT_SECTIONS: { [key in StepsEnum]: StepData } = {
  [StepsEnum.stepBasicData]: {
    label: "Datos básicos",
    content: BasicDataSection,
  },
  [StepsEnum.stepProcessor]: {
    label: "Procesamiento",
    content: ProcessorsSection,
  },
  [StepsEnum.stepServices]: {
    label: "Servicios",
    content: ServicesSection,
  },
  [StepsEnum.stepConfigRatesAndInvoice]: {
    label: "Configuración de facturación y tarifas",
    content: ConfigRatesInvoiceSection,
  },
  [StepsEnum.stepUsers]: {
    label: "Usuarios",
    content: UserSection,
  },
};

export type MerchantAllStepsProps = {
  steps: StatusStepsState[];
  isNewMerchant: boolean;
  isDisableUserStep: boolean;
  expandedSection: ExpandedSection;
  handleSectionExpansion: (
    step: StepsEnum
  ) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  changeStepStatus: (step: StepName, status: StatusSteps) => void;
};

export const MerchantDetailsAllSteps: FC<MerchantAllStepsProps> = (props) => {
  const {
    steps,
    isNewMerchant,
    isDisableUserStep,
    expandedSection,
    handleSectionExpansion,
    changeStepStatus,
  } = props;
  return (
    <div>
      {steps.map((step: StatusStepsState, index: number) => {
        const stepData = CREATE_MERCHANT_SECTIONS[step.name];
        if (!stepData) {
          return null;
        }

        return (
          <MerchantStepSection
            disabled={
              (isNewMerchant && step.name !== StepsEnum.stepBasicData) ||
              (!isDisableUserStep && step.name === StepsEnum.stepUsers)
            }
            stepStatus={step}
            sectionData={stepData}
            expanded={step.name === expandedSection}
            handleChange={handleSectionExpansion(step.name as StepsEnum)}
            key={index}
            sectionNumber={index + 1}
          >
            {stepData.content(step.name, changeStepStatus)}
          </MerchantStepSection>
        );
      })}
    </div>
  );
};
