import { NotificationTypeEnum, SnackbarEnum } from "../enums/SnackbarEnum";
import { ISnackBar } from "@kushki/connect-ui/dist/Components/Atoms/DataDisplay/SnackBar/SnackBar.interface";

const successData: Partial<ISnackBar> = {
  color: "success",
  message: SnackbarEnum.REQUEST_SUCCESS,
};

const failedData: Partial<ISnackBar> = {
  color: "danger",
  message: SnackbarEnum.REQUEST_ERROR,
};

export const defaultDataSnackbar: ISnackBar = {
  color: "success",
  message: SnackbarEnum.REQUEST_SUCCESS,
  variant: "simple",
  withIcon: false,
};

export const buildNotification = (
  notificationType: NotificationTypeEnum,
  data: ISnackBar
): ISnackBar => {
  switch (notificationType) {
    case NotificationTypeEnum.SUCCESS:
      return {
        ...data,
        ...successData,
      };
    case NotificationTypeEnum.FAILED:
      return {
        ...data,
        ...failedData,
      };
  }
};
