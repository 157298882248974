import React, { PropsWithChildren } from "react";
import { Box, Button, Grid } from "@material-ui/core";
import { chipComponentStyle } from "./ChipComponent.style";
import { ChipComponentProps } from "../../../shared/infrastructure/interfaces/ChipComponent.interface";
import { FilterItems } from "../../../shared/infrastructure/constants/WalletDashboardConstants";

export const ChipComponent = (props: PropsWithChildren<ChipComponentProps>) => {
  const classes = chipComponentStyle();

  return (
    <Grid container direction={"row"} spacing={2}>
      {props.dataToDraw.map((data: FilterItems, idx: number) => (
        <Grid key={`grid-${data.title}-${idx}`} item>
          <Box
            className={data.isSelected ? classes.chipSelected : classes.chip}
            component={Button}
            onClick={() => {
              props.handleClick(data.title, !data.isSelected);
            }}
          >
            {data.title}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
