export enum RetentionType {
  city = "sCity",
  taxpayerType = "sTaxpayerType",
  activity = "sActivity",
  incomeSourceConcept = "sIncomeSourceConcept",
  processorType = "sProcessorType",
}

export enum HierarchyType {
  centralized = "Centralizado",
  decentralized = "Descentralizado",
}

export enum ProcessorCodeTypeEnum {
  P01 = "1",
  P02 = "5",
}

export enum MunicipalityCodeTypeEnum {
  M01 = "11001",
  M02 = "76001",
  M03 = "08001",
  M04 = "68001",
  M05 = "66001",
  M06 = "05001",
}

export const MUNICIPALITY = [
  MunicipalityCodeTypeEnum.M01,
  MunicipalityCodeTypeEnum.M02,
  MunicipalityCodeTypeEnum.M03,
  MunicipalityCodeTypeEnum.M04,
  MunicipalityCodeTypeEnum.M05,
  MunicipalityCodeTypeEnum.M06,
];
export enum TaxpayerCodeTypeEnum {
  C01 = "C01",
  C02 = "C02",
  C03 = "C03",
  C06 = "C06",
  C07 = "C07",
  C08 = "C08",
  C09 = "C09",
  C10 = "C10",
  C11 = "C11",
  C12 = "C12",
}
export const TAXPAYER = [
  TaxpayerCodeTypeEnum.C01,
  TaxpayerCodeTypeEnum.C02,
  TaxpayerCodeTypeEnum.C03,
  TaxpayerCodeTypeEnum.C06,
  TaxpayerCodeTypeEnum.C07,
  TaxpayerCodeTypeEnum.C08,
  TaxpayerCodeTypeEnum.C09,
  TaxpayerCodeTypeEnum.C10,
  TaxpayerCodeTypeEnum.C11,
  TaxpayerCodeTypeEnum.C12,
];

export enum ActivityCodeTypeEnum {
  A01 = "A01",
  A02 = "A02",
  A03 = "A03",
  A04 = "A04",
  A05 = "A05",
}

export const ACTIVITY = [
  ActivityCodeTypeEnum.A01,
  ActivityCodeTypeEnum.A02,
  ActivityCodeTypeEnum.A03,
  ActivityCodeTypeEnum.A04,
  ActivityCodeTypeEnum.A05,
];

export enum IncomeSourceConceptCodeEnum {
  CR01 = "CR01",
  CR02 = "CR02",
  CR03 = "CR03",
  CR04 = "CR04",
  CR05 = "CR05",
  CR06 = "CR06",
  CR07 = "CR07",
  CR08 = "CR08",
  CR09 = "CR09",
  CR10 = "CR10",
  CR11 = "CR11",
  CR12 = "CR12",
  CR13 = "CR13",
  CR14 = "CR14",
  CR15 = "CR15",
  CR16 = "CR16",
  CR17 = "CR17",
  CR18 = "CR18",
  CR19 = "CR19",
  CR20 = "CR20",
  CR21 = "CR21",
  CR22 = "CR22",
  CR23 = "CR23",
  CR24 = "CR24",
  CR25 = "CR25",
  CR26 = "CR26",
  CR27 = "CR27",
  CR28 = "CR28",
  CR29 = "CR29",
  CR30 = "CR30",
  CR31 = "CR31",
  CR32 = "CR32",
  CR33 = "CR33",
  CR34 = "CR34",
  CR35 = "CR35",
  CR36 = "CR36",
  CR37 = "CR37",
  CR38 = "CR38",
  CR39 = "CR39",
  CR40 = "CR40",
  CR41 = "CR41",
  CR42 = "CR42",
  CR43 = "CR43",
  CR50 = "CR50",
  CR51 = "CR51",
  CR52 = "CR52",
  CR53 = "CR53",
  CR54 = "CR54",
  CR55 = "CR55",
  CR56 = "CR56",
  CR57 = "CR57",
  CR58 = "CR58",
  CR59 = "CR59",
  CR60 = "CR60",
  CR61 = "CR61",
  CR62 = "CR62",
  CR63 = "CR63",
  CR64 = "CR64",
  CR66 = "CR66",
  CR67 = "CR67",
  CR68 = "CR68",
  CR69 = "CR69",
  CR70 = "CR70",
  CR71 = "CR71",
  CR72 = "CR72",
  CR73 = "CR73",
  CR74 = "CR74",
  CR80 = "CR80",
  CR101 = "CR101",
  CR102 = "CR102",
  CR120 = "CR120",
}

export const INCOME_SOURCE_CONCEPT = [
  IncomeSourceConceptCodeEnum.CR01,
  IncomeSourceConceptCodeEnum.CR02,
  IncomeSourceConceptCodeEnum.CR03,
  IncomeSourceConceptCodeEnum.CR04,
  IncomeSourceConceptCodeEnum.CR05,
  IncomeSourceConceptCodeEnum.CR06,
  IncomeSourceConceptCodeEnum.CR07,
  IncomeSourceConceptCodeEnum.CR08,
  IncomeSourceConceptCodeEnum.CR09,
];

export enum RetentionContentEnum {
  FOOTER_CO = "*En retenciones ICA y Fuente la base gravable es la misma.",
  RETURN_RET = "Regresar retención",
}
export const MUNICIPALITIES = {
  [MunicipalityCodeTypeEnum.M01]: "Bogotá",
  [MunicipalityCodeTypeEnum.M02]: "Cali",
  [MunicipalityCodeTypeEnum.M03]: "Barranquilla",
  [MunicipalityCodeTypeEnum.M04]: "Bucaramanga",
  [MunicipalityCodeTypeEnum.M05]: "Pereira",
  [MunicipalityCodeTypeEnum.M06]: "Medellin",
};

export const RetentionCatalog: Record<string, object> = {
  [RetentionType.city]: MUNICIPALITIES,
  [RetentionType.taxpayerType]: {
    [TaxpayerCodeTypeEnum.C01]: "Instituciones del estado y públicas.",
    [TaxpayerCodeTypeEnum.C02]:
      "Compañías de aviación/agencias de viaje/Distribuidores comercializadores derivados del petróleo/ Exportador habitual de bienes obligado a llevar contabilidad / Voceadores de periódicos y revistas.",
    [TaxpayerCodeTypeEnum.C03]: "Contribuyentes Especiales",
    [TaxpayerCodeTypeEnum.C06]: "Importación de servicios gravados.",
    [TaxpayerCodeTypeEnum.C07]:
      "Agentes De Retención Considerados Exportadores Habituales De Bienes Y/O Servicios.",
    [TaxpayerCodeTypeEnum.C08]:
      "RIMPE Emprendedores Contribuyentes calificados y no como agentes de retención.",
    [TaxpayerCodeTypeEnum.C09]: "RIMPE Negocio Popular.",
    [TaxpayerCodeTypeEnum.C10]: "Importación de servicios gravados.",
    [TaxpayerCodeTypeEnum.C11]: "No residentes.",
    [TaxpayerCodeTypeEnum.C12]: "Régimen General.",
  },
  [RetentionType.activity]: {
    [ActivityCodeTypeEnum.A01]: "Bienes",
    [ActivityCodeTypeEnum.A02]: "Servicios",
    [ActivityCodeTypeEnum.A03]: "Honorarios profesionales",
    [ActivityCodeTypeEnum.A04]: "Arriendo",
    [ActivityCodeTypeEnum.A05]: "Exento",
  },
  [RetentionType.incomeSourceConcept]: {
    [IncomeSourceConceptCodeEnum.CR01]:
      "Honorarios profesionales y demás pagos por servicios relacionados con el título profesional",
    [IncomeSourceConceptCodeEnum.CR02]:
      "Servicios predomina el intelecto no relacionados con el título profesional",
    [IncomeSourceConceptCodeEnum.CR03]:
      "Comisiones y demás pagos por servicios predomina intelecto no relacionados con el título profesional",
    [IncomeSourceConceptCodeEnum.CR04]:
      "Pagos a notarios y registradores de la propiedad y mercantil por sus actividades ejercidas como tales",
    [IncomeSourceConceptCodeEnum.CR05]:
      "Pagos a deportistas, entrenadores, árbitros, miembros del cuerpo técnico por sus actividades ejercidas como tales",
    [IncomeSourceConceptCodeEnum.CR06]:
      "Pagos a artistas por sus actividades ejercidas como tales",
    [IncomeSourceConceptCodeEnum.CR07]:
      "Honorarios y demás pagos por servicios de docencia",
    [IncomeSourceConceptCodeEnum.CR08]: "Servicios predomina la mano de obra",
    [IncomeSourceConceptCodeEnum.CR09]:
      "Utilización o aprovechamiento de la imagen o renombre",
    [IncomeSourceConceptCodeEnum.CR10]:
      "Servicios prestados por medios de comunicación y agencias de publicidad",
    [IncomeSourceConceptCodeEnum.CR11]:
      "Servicio de transporte privado de pasajeros o transporte público o privado de carga",
    [IncomeSourceConceptCodeEnum.CR12]:
      "Pagos a través de liquidación de compra (nivel cultural o rusticidad)",
    [IncomeSourceConceptCodeEnum.CR13]:
      "Transferencia de bienes muebles de naturaleza corporal",
    [IncomeSourceConceptCodeEnum.CR14]:
      "Compra de bienes de origen agrícola, avícola, pecuario, apícola, cunícula, bioacuático, forestal y carnes en estado natural",
    [IncomeSourceConceptCodeEnum.CR15]:
      "Impuesto a la Renta único para la actividad de producción y cultivo de palma aceitera",
    [IncomeSourceConceptCodeEnum.CR16]:
      "Regalías por concepto de franquicias de acuerdo a Ley de Propiedad Intelectual - pago a personas naturales",
    [IncomeSourceConceptCodeEnum.CR17]:
      "Cánones, derechos de autor,  marcas, patentes y similares de acuerdo a Ley de Propiedad Intelectual – pago a personas naturales",
    [IncomeSourceConceptCodeEnum.CR18]:
      "Regalías por concepto de franquicias de acuerdo a Ley de Propiedad Intelectual  - pago a sociedades",
    [IncomeSourceConceptCodeEnum.CR19]:
      "Cánones, derechos de autor,  marcas, patentes y similares de acuerdo a Ley de Propiedad Intelectual – pago a sociedades",
    [IncomeSourceConceptCodeEnum.CR20]:
      "Cuotas de arrendamiento mercantil (prestado por sociedades), inclusive la de opción de compra",
    [IncomeSourceConceptCodeEnum.CR21]: "Arrendamiento bienes inmuebles",
    [IncomeSourceConceptCodeEnum.CR22]:
      "Seguros y reaseguros (primas y cesiones)",
    [IncomeSourceConceptCodeEnum.CR23]:
      "Rendimientos financieros pagados a naturales y sociedades  (No a IFIs)",
    [IncomeSourceConceptCodeEnum.CR24]:
      "Rendimientos financieros: depósitos Cta. Corriente",
    [IncomeSourceConceptCodeEnum.CR25]:
      "Rendimientos financieros:  depósitos Cta. Ahorros Sociedades",
    [IncomeSourceConceptCodeEnum.CR26]:
      "Rendimientos financieros: depósito a plazo fijo  gravados",
    [IncomeSourceConceptCodeEnum.CR27]:
      "Rendimientos financieros: depósito a plazo fijo exentos",
    [IncomeSourceConceptCodeEnum.CR28]:
      "Rendimientos financieros: operaciones de reporto - repos",
    [IncomeSourceConceptCodeEnum.CR29]:
      "Inversiones (captaciones) rendimientos distintos de aquellos pagados a IFIs",
    [IncomeSourceConceptCodeEnum.CR30]:
      "Rendimientos financieros: obligaciones",
    [IncomeSourceConceptCodeEnum.CR31]:
      "Rendimientos financieros: bonos convertible en acciones",
    [IncomeSourceConceptCodeEnum.CR32]:
      "Rendimientos financieros: Inversiones en títulos valores en renta fija gravados",
    [IncomeSourceConceptCodeEnum.CR33]:
      "Rendimientos financieros: Inversiones en títulos valores en renta fija exentos",
    [IncomeSourceConceptCodeEnum.CR34]:
      "Intereses y demás rendimientos financieros pagados a bancos y otras entidades sometidas al control de la Superintendencia de Bancos y de la Economía Popular y Solidaria",
    [IncomeSourceConceptCodeEnum.CR35]:
      "Intereses pagados por entidades del sector público a favor de sujetos pasivos",
    [IncomeSourceConceptCodeEnum.CR36]:
      "Otros intereses y rendimientos financieros gravados",
    [IncomeSourceConceptCodeEnum.CR37]:
      "Otros intereses y rendimientos financieros exentos",
    [IncomeSourceConceptCodeEnum.CR38]:
      "Pagos y créditos en cuenta efectuados por el BCE y los depósitos centralizados de valores, en calidad de intermediarios, a instituciones del sistema financiero por cuenta de otras personas naturales y sociedades",
    [IncomeSourceConceptCodeEnum.CR39]:
      "Rendimientos financieros originados en la deuda pública ecuatoriana",
    [IncomeSourceConceptCodeEnum.CR40]:
      "Rendimientos financieros originados en títulos valores de obligaciones de 360 días o más para el financiamiento de proyectos públicos en asociación público-privada",
    [IncomeSourceConceptCodeEnum.CR41]:
      "Intereses y comisiones en operaciones de crédito entre instituciones del sistema financiero y entidades economía popular y solidaria.",
    [IncomeSourceConceptCodeEnum.CR42]:
      "Inversiones entre instituciones del sistema financiero y entidades economía popular y solidaria",
    [IncomeSourceConceptCodeEnum.CR43]:
      "Pagos y créditos en cuenta efectuados por el BCE y los depósitos centralizados de valores, en calidad de intermediarios, a instituciones del sistema financiero por cuenta de otras instituciones del sistema financiero",
    [IncomeSourceConceptCodeEnum.CR50]:
      "Dividendos en acciones (capitalización de utilidades)",
    [IncomeSourceConceptCodeEnum.CR51]:
      "Otras compras de bienes y servicios no sujetas a retención (incluye régimen RIMPE - Negocios Populares para este caso aplica con cualquier forma de pago inclusive los pagos que deban realizar las tarjetas de crédito/débito)",
    [IncomeSourceConceptCodeEnum.CR52]: "Compra de bienes inmuebles",
    [IncomeSourceConceptCodeEnum.CR53]: "Transporte público de pasajeros",
    [IncomeSourceConceptCodeEnum.CR54]:
      "Pagos en el país por transporte de pasajeros o transporte internacional de carga, a compañías nacionales o extranjeras de aviación o marítimas",
    [IncomeSourceConceptCodeEnum.CR55]:
      "Valores entregados por las cooperativas de transporte a sus socios",
    [IncomeSourceConceptCodeEnum.CR56]:
      "Compraventa de divisas distintas al dólar de los Estados Unidos de América",
    [IncomeSourceConceptCodeEnum.CR57]: "Pagos con tarjeta de crédito",
    [IncomeSourceConceptCodeEnum.CR58]:
      "Pago al exterior tarjeta de crédito reportada por la Emisora de tarjeta de crédito, solo RECAP",
    [IncomeSourceConceptCodeEnum.CR59]:
      "Pago a través de convenio de debito (Clientes IFI`s)",
    [IncomeSourceConceptCodeEnum.CR60]:
      "Ganancia en la enajenación de derechos representativos de capital u otros derechos que permitan la exploración, explotación, concesión o similares de sociedades, que se\n" +
      "coticen en bolsa de valores del Ecuador",
    [IncomeSourceConceptCodeEnum.CR61]:
      "Contraprestación producida por la enajenación de derechos representativos de capital u otros derechos que permitan la exploración, explotación, concesión o similares de\n" +
      "sociedades, no cotizados en bolsa de valores del Ecuador",
    [IncomeSourceConceptCodeEnum.CR62]: "Loterías, rifas, apuestas y similares",
    [IncomeSourceConceptCodeEnum.CR63]:
      "Venta de combustibles a comercializadoras",
    [IncomeSourceConceptCodeEnum.CR64]:
      "Venta de combustibles a distribuidores",
    [IncomeSourceConceptCodeEnum.CR66]:
      "Impuesto único a la exportación de banano",
    [IncomeSourceConceptCodeEnum.CR67]:
      "Otras retenciones aplicables el 1% (incluye régimen RIMPE - Emprendedores, para este caso aplica con cualquier forma de pago inclusive los pagos que deban realizar las tarjetas de crédito/débito)",
    [IncomeSourceConceptCodeEnum.CR68]: "Energía eléctrica",
    [IncomeSourceConceptCodeEnum.CR69]:
      "Actividades de construcción de obra material inmueble, urbanización, lotización o actividades similares",
    [IncomeSourceConceptCodeEnum.CR70]:
      "Impuesto Redimible a las botellas plásticas - IRBP",
    [IncomeSourceConceptCodeEnum.CR71]: "Otras retenciones aplicables el 2,75%",
    [IncomeSourceConceptCodeEnum.CR72]:
      "Pago local tarjeta de crédito /débito reportada por la Emisora de tarjeta de crédito / entidades del sistema financiero",
    [IncomeSourceConceptCodeEnum.CR73]:
      "Adquisición de sustancias minerales dentro del territorio nacional",
    [IncomeSourceConceptCodeEnum.CR74]: "Otras retenciones aplicables el 8%",
    [IncomeSourceConceptCodeEnum.CR80]:
      "Impuesto único a ingresos provenientes de actividades agropecuarias en etapa de producción / comercialización local o exportación",
    [IncomeSourceConceptCodeEnum.CR101]:
      "Pago a no residentes - Intereses por financiamiento de proveedores externos",
    [IncomeSourceConceptCodeEnum.CR102]:
      "Pago a no residentes - Intereses de otros créditos externos",
    [IncomeSourceConceptCodeEnum.CR120]:
      "Pago a no residentes - Por las empresas de transporte marítimo o aéreo y por empresas pesqueras de alta mar, por su actividad.",
  },
  [RetentionType.processorType]: {
    [ProcessorCodeTypeEnum.P01]: "Agregador Formal",
    [ProcessorCodeTypeEnum.P02]: "Agregador Gateway",
  },
};
