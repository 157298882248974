import MaskedInput from "react-text-mask";
import React from "react";
import { get } from "lodash";

export interface MaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const n: RegExp = new RegExp(/[0-9]/);

export const NumberMaskCustom = (props: MaskCustomProps): any => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(get(ref, "inputElement", null));
      }}
      mask={[
        n,
        n,
        n,
        n,
        n,
        n,
        n,
        n,
        n,
        n,
        n,
        n,
        n,
        n,
        n,
        n,
        n,
        n,
        n,
        n,
        n,
        n,
        n,
        n,
        n,
        n,
        n,
        n,
        n,
        n,
        n,
      ]}
      guide={false}
      showMask={false}
    />
  );
};
