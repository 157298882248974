import { HeadCell } from "../../components/Table/TableHead/TableHead.interfaces";
import {
  IConfigCells,
  TableBodyCellEnum,
} from "../../components/Table/TableSimple/constants";

export const headCells: HeadCell[] = [
  {
    disablePadding: false,
    id: "line1",
    label: "Nombre",
    numeric: false,
    orderBy: "line1",
  },
  {
    disablePadding: false,
    id: "title",
    label: "Branch ID",
    numeric: true,
    orderBy: "title",
  },
  {
    disablePadding: false,
    id: "country",
    label: "País de Constitución",
    numeric: true,
    orderBy: "country",
  },
  {
    disablePadding: false,
    id: "text1",
    label: "Estado",
    numeric: true,
    orderBy: "text",
  },
  {
    disablePadding: false,
    id: "text",
    label: "Grupo",
    numeric: false,
    orderBy: "text",
  },
];

export const CatalogConfigTableBranch: IConfigCells[] = [
  {
    align: "center",
    isChecked: false,
    type: TableBodyCellEnum.CHECK,
  },
  {
    align: "center",
    configMain: {
      defaultValue: "Name Default",
      path: "name",
      text: "$var",
    },
    configSecondary: {
      defaultValue: "created default",
      path: "created",
      text: "Creación: $var",
    },
    type: TableBodyCellEnum.TITLE,
  },
  {
    align: "center",
    configMain: {
      defaultValue: "MerchantId Default",
      path: "merchant_id", //or node_id if Branch Id
      text: "$var",
    },
    type: TableBodyCellEnum.TEXT_COPY,
  },
  {
    align: "center",
    configMain: {
      defaultValue: "Colombia",
      path: "constitutional_country",
      text: "$var",
    },
    isCodeCountry: false,
    type: TableBodyCellEnum.FLAG,
  },
  {
    align: "center",
    configMain: {
      defaultValue: "inactive",
      path: "merchant_status",
      text: "$var",
    },
    type: TableBodyCellEnum.TAG,
  },
  {
    align: "center",
    configMain: {
      defaultValue: true,
      path: "is_centralized",
      text: "$var",
    },
    type: TableBodyCellEnum.TAG_GROUP,
  },
];
