import React, { FC } from "react";
import { Container, IconButton, Typography } from "@mui/material";
import { RulesAlarmStyles as RAstyles } from "@containers/RulesAlarm/RulesAlarm.styles";
import { RuleDetailStyles as styles } from "@containers/RuleDetail/RuleDetail.styles";
import { ITEMS_PER_PAGE } from "@shared/constants/AlarmTableConstants";
import { TableSimple } from "@kushki/connect-ui";
import { RULE_DETAIL_FOOTER_TEXT } from "@shared/constants/ruleDetailTable";
import { useRuleDetailState } from "@containers/RuleDetail/state/useRuleDetailState";
import GenericHeader from "@components/GenericHeader/GenericHeader";
import { NodeSelectionLabels } from "@shared/constants/labels/nodeSelectionLabels";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  RuleDetailLabels,
  TOTAL_BRANCHES,
} from "@shared/constants/labels/ruleDetailLabels";

const RuleDetail: FC = () => {
  const { rows, footer, isLoading, returnToMain, headers, totalBranches } =
    useRuleDetailState();

  return (
    <>
      <GenericHeader
        hasBoldText={true}
        title={NodeSelectionLabels.ALARM_CONFIG}
      />
      <Container fixed sx={RAstyles.container}>
        <IconButton sx={styles.iconBtn} onClick={returnToMain}>
          <ArrowBackIosIcon sx={styles.icon} />
          {RuleDetailLabels.RETURN}
        </IconButton>
        {!isLoading && (
          <>
            <Typography pt={2} mb={4} variant="h5">
              {TOTAL_BRANCHES(totalBranches)}
            </Typography>
          </>
        )}
        <TableSimple
          header={headers}
          rows={rows}
          showPagination
          isLoading={isLoading}
          footerPagination={{
            isDisable: false,
            itemsPerPage: footer.itemsPerPage,
            onItemsPerPageChange: footer.handleItemsPerPage,
            onPageClick: footer.handleSelectedPage,
            paginationText: RULE_DETAIL_FOOTER_TEXT,
            selectedPage: footer.selectedPage,
            textFieldSelector: ITEMS_PER_PAGE,
            totalItems: footer.total,
          }}
        />
      </Container>
    </>
  );
};

export default RuleDetail;
