import {
  HistoricalItemEventTypeEnum,
  HistoricalItemStatus,
} from "../../store/interfaces/TransactionsState.interfaces";

export const transactionTitleMap = {
  [`${HistoricalItemEventTypeEnum.TRANSACTION}_${HistoricalItemStatus.APPROVED}`]:
    "Transacción Aprobada",
  [`${HistoricalItemEventTypeEnum.TRANSACTION}_${HistoricalItemStatus.APPROVAL}`]:
    "Transacción Aprobada",
  [`${HistoricalItemEventTypeEnum.TRANSACTION}_${HistoricalItemStatus.DECLINED}`]:
    "Transacción Declinada",
  [`${HistoricalItemEventTypeEnum.TRANSACTION}_${HistoricalItemStatus.INITIALIZED}`]:
    "Transacción Inicializada",
  [`${HistoricalItemEventTypeEnum.TRANSACTION}_${HistoricalItemStatus.VOID}`]:
    "Void",
  [`${HistoricalItemEventTypeEnum.TRANSACTION}_${HistoricalItemStatus.REFUND}`]:
    "Reembolso Inicializado",
  [`${HistoricalItemEventTypeEnum.FILE}_${HistoricalItemStatus.GENERATED}`]:
    "Generar Certificado",
  [`${HistoricalItemEventTypeEnum.FILE}_${HistoricalItemStatus.FAILED}`]:
    "Certificado Fallido",
  [`${HistoricalItemEventTypeEnum.FILE}_${HistoricalItemStatus.COMPLETED}`]:
    "Certificado Emitido",
  [`${HistoricalItemEventTypeEnum.FILE}_${HistoricalItemStatus.REQUESTED}`]:
    "Generar Certificado",
  [`${HistoricalItemEventTypeEnum.REFUND}_${HistoricalItemStatus.GENERATED}`]:
    "Reemboloso Inicializado",
  [`${HistoricalItemEventTypeEnum.REFUND}_${HistoricalItemStatus.INITIALIZED}`]:
    "Reemboloso Inicializado",
  [`${HistoricalItemEventTypeEnum.REFUND}_${HistoricalItemStatus.REFUND}`]:
    "Reembolso Inicializado",
  [`${HistoricalItemEventTypeEnum.REFUND}_${HistoricalItemStatus.APPROVED}`]:
    "Reembolso Aprobado",
  [`${HistoricalItemEventTypeEnum.REFUND}_${HistoricalItemStatus.APPROVAL}`]:
    "Reembolso Aprobado",
  [`${HistoricalItemEventTypeEnum.REFUND}_${HistoricalItemStatus.RETRIED}`]:
    "Reembolso Reintentándose",
};
