import { makeStyles } from "@material-ui/core/styles";
import SaveAltOutlinedIcon from "@material-ui/icons/SaveAltOutlined";
import React, { FC } from "react";
import { Box, Button } from "@material-ui/core";
import { Data } from "../../../../types/filter_wallet_dashboard_response";
import { useTransactionFooter } from "./state/useTransactionFooter";
import { countriesPayOut } from "../../../shared/utils/utils";

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: "16px",
    background: "#F7FAFC",
  },
  buttonStyled: {
    padding: "8px 16px !important",
    backgroundColor: "#fff",
    color: "#293036 !important",
    fontWeight: 500,
    fontSize: "16px !important",
    lineHeight: "100%",
    border: "1px solid #B4B9BE !important",
  },
}));

export interface ITransactionFooterProps {
  country: string;
  transaction: Data;
}

export const TransactionFooter: FC<ITransactionFooterProps> = (props) => {
  const classes = useStyles();
  const { country, transaction } = props;
  const { downloadFile, isVisible } = useTransactionFooter(transaction);

  return (
    <>
      {countriesPayOut[country] && isVisible && (
        <Box className={classes.wrapper}>
          <Button
            id={"btn-download"}
            className={classes.buttonStyled}
            onClick={downloadFile}
            startIcon={<SaveAltOutlinedIcon />}
          >
            {"Descargar"}
          </Button>
        </Box>
      )}
    </>
  );
};
