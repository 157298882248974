import React from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  AlertDialog,
  ButtonText,
  ButtonTitles,
  TableTitle,
} from "../../shared/infrastructure/constants/RulesConstants";
import { ChevronLeft, ChevronRight, Edit, Trash2 } from "react-feather";
import { WhitelistRules } from "../../../types/whitelist_rules";
import { isEmpty, get } from "lodash";
import { WhitelistItems } from "../../../types/whitelist_items";
import { MerchantsData } from "../../store/actionCreator";
import { SecurityRulesRoles } from "../../shared/infrastructure/constants/SecurityRulesConstants";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

export interface DataTableWhiteListProps {
  whitelistRules: WhitelistRules | undefined;
  nextWhitelistAction: () => void;
  backWhitelistAction: () => void;
  deleteWhitelistRule: (merchantId: string) => void;
  merchantName: string;
  merchantList: MerchantsData | undefined;
  isLoadingMerchantList: boolean;
  handleSetSearchTextMerchantList: (merchantName: string) => void;
  handleSearchTextMerchantList: (key: string) => void;
  handleSearchWhitelistRule: (merchantIdParam: string) => void;
  handlerSetMerchantNameMigrateRules: (merchantName: string) => void;
}

const useStyles = makeStyles(() => ({
  tableHover: {
    "&:hover": {
      opacity: 0.9,
      cursor: "pointer",
    },
  },
  tableHeaderStyle: {
    fontWeight: "bold",
  },
  dialogTitle: {
    fontWeight: "bold",
    color: "#023365",
    marginTop: "10px",
  },
  dialogBody: {
    color: "#293036",
  },
  dialogActions: {
    paddingBottom: "20px",
    paddingRight: "20px",
  },
  cancelButton: {
    fontSize: "15px",
    color: "black",
    paddingRight: "10px",
  },
  deleteRuleButton: {
    fontSize: "15px",
    color: "red",
  },
}));

export const DataTableWhiteList: React.FC<DataTableWhiteListProps> = (
  props: DataTableWhiteListProps
) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [merchantId, setMerchantId] = React.useState("");

  const handleOpenDialog = (merchantId: string) => {
    setOpen(true);
    setMerchantId(merchantId);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align={"left"} className={classes.tableHeaderStyle}>
                  {TableTitle.NOMBRE_COMERCIO}
                </TableCell>
                <TableCell align={"left"} className={classes.tableHeaderStyle}>
                  {TableTitle.ID_COMERCIO}
                </TableCell>
                <TableCell align={"left"} className={classes.tableHeaderStyle}>
                  {TableTitle.PAIS}
                </TableCell>
                <TableCell
                  align={"center"}
                  className={classes.tableHeaderStyle}
                >
                  {TableTitle.C_TARJETAS}
                </TableCell>
                <TableCell
                  align={"left"}
                  className={classes.tableHeaderStyle}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {(get(props, "whitelistRules.items", []) == null
                ? []
                : get(props, "whitelistRules.items", [])
              ).map((rule: WhitelistItems) => (
                <TableRow key={rule.merchantId}>
                  <TableCell align={"left"} className={classes.tableHover}>
                    <Typography
                      align={"left"}
                      color={"textPrimary"}
                      variant={"body2"}
                    >
                      {rule.merchantName}
                    </Typography>
                  </TableCell>
                  <TableCell align={"left"} className={classes.tableHover}>
                    <Typography
                      align={"left"}
                      color={"textPrimary"}
                      variant={"body2"}
                    >
                      {rule.merchantId}
                    </Typography>
                  </TableCell>
                  <TableCell align={"left"} className={classes.tableHover}>
                    <Typography
                      align={"left"}
                      color={"textPrimary"}
                      variant={"body2"}
                    >
                      {rule.country}
                    </Typography>
                  </TableCell>
                  <TableCell align={"center"} className={classes.tableHover}>
                    <Chip
                      label={
                        get(rule, "validationType") === "maskedCards"
                          ? get(rule, "maskedCards", []) &&
                            rule.maskedCards.length
                          : get(rule, "cardIDs", []) && rule.cardIDs.length
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="edit"
                      onClick={() => {
                        window.location.href = `/rules/whitelist/rule/${rule.merchantId}`;
                      }}
                    >
                      <Edit fontSize="small" />
                    </IconButton>
                    <ComponentSecurityWrapper
                      componentId={`${SecurityRulesRoles.M_FRAUD_PREVENTION}.${SecurityRulesRoles.WHITELIST_DELETE}`}
                    >
                      <IconButton
                        aria-label="trash"
                        onClick={() => {
                          handleOpenDialog(rule.merchantId);
                        }}
                      >
                        <Trash2 fontSize="small" />
                      </IconButton>
                    </ComponentSecurityWrapper>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display="flex" style={{ width: "100%" }}>
          <Box flexGrow={1} />
          <Box p={1}>
            <Button
              id="button_back_wl"
              onClick={props.backWhitelistAction}
              style={{
                textTransform: "capitalize",
              }}
              startIcon={<ChevronLeft xlinkTitle="back" size={22} />}
            >
              {ButtonTitles.BACK}
            </Button>
            <Button
              id="button_next_wl"
              disabled={isEmpty(get(props, "whitelistRules.items"))}
              onClick={props.nextWhitelistAction}
              style={{
                textTransform: "capitalize",
              }}
              endIcon={<ChevronRight xlinkTitle="next" size={22} />}
            >
              {ButtonTitles.NEXT}
            </Button>
          </Box>
        </Box>
      </Paper>
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle className={classes.dialogTitle}>
          {AlertDialog.TITLE}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogBody}>
            {AlertDialog.BODY}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button className={classes.cancelButton} onClick={handleCloseDialog}>
            {ButtonText.CANCEL}
          </Button>
          <Button
            className={classes.deleteRuleButton}
            onClick={() => {
              props.deleteWhitelistRule(merchantId);
              handleCloseDialog();
            }}
          >
            {ButtonText.DELETE_RULE}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
