import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import {
  FilterSearchContext,
  initialFilterSearchContext,
} from "../../context/filter-search/FilterSearchContext";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { defaultTo, get, isEmpty, set } from "lodash";
import { TabIndexEnum } from "../../infrastructure/enums/TabIndexEnum";
import { format } from "date-fns-tz";
import { DateFormatStringEnum } from "../../infrastructure/enums/FilterEnum";
import {
  getConfiguration,
  getRequestRules,
  setSelectedFilter,
} from "../../../store/actionCreators";
import { EndpointsPathsEnum } from "../../infrastructure/enums/PathsEnum";
import { StatusEnum } from "../../infrastructure/enums/StatusEnum";
import { SubTypeEnum } from "../../infrastructure/enums/CreateRuleConstans";
import { ConfigTypeEnum } from "../../infrastructure/enums/TabStatusEnum";
import { timeZoneMapper } from "../../utils/time-zone";

const FilterSearchProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(initialFilterSearchContext.filterSearch);
  const [path, setPath] = useState(EndpointsPathsEnum.RULES_REQUEST);
  const [readyToRender, setReadyToRender] = useState(false);
  const indexTab: number = useSelector((state: IAppState) =>
    defaultTo(state.selectedTabIndex, TabIndexEnum.PENDING_REVIEW_INDEX)
  );
  const defaultStatusByTab: Record<TabIndexEnum, string> = {
    [TabIndexEnum.PENDING_REVIEW_INDEX]: `${StatusEnum.PENDING}`,
    [TabIndexEnum.FIRST_LEVEL_RULES_INDEX]: `${StatusEnum.RULES_ENABLED}|${StatusEnum.RULES_DISABLED}`,
    [TabIndexEnum.WHITE_LISTS_INDEX]: `${StatusEnum.RULES_ENABLED}|${StatusEnum.RULES_DISABLED}`,
    [TabIndexEnum.REJECTED_APPLICATIONS_INDEX]: `${StatusEnum.REJECTED}`,
  };
  const defaultTypeByTab: Record<number, string> = {
    [TabIndexEnum.FIRST_LEVEL_RULES_INDEX]: `${SubTypeEnum.SECURITY_COUNTRY}|${SubTypeEnum.SECURITY_COMMERCE}`,
    [TabIndexEnum.WHITE_LISTS_INDEX]: `${SubTypeEnum.WHITELIST}`,
  };
  const calculateOffset = (page: number, limit: number): number => {
    return (page - 1) * limit;
  };
  const updateFilter = (filter: object) => {
    const status: string = get(filter, "status", "");
    const type: string = get(filter, "type", "");

    if (isEmpty(status)) {
      set(filter, "status", defaultStatusByTab[indexTab]);
    }
    if (isEmpty(type)) {
      set(filter, "type", defaultTypeByTab[indexTab]);
    }

    setFilter((prevState) => {
      return {
        ...prevState,
        page: 1,
        request: {
          ...prevState.request,
          filter: {
            ...filter,
          },
          offset: calculateOffset(1, prevState.request.limit),
        },
      };
    });
  };
  const updatePage = (page: number) => {
    setFilter((prevState) => {
      return {
        ...prevState,
        page,
        request: {
          ...prevState.request,
          offset: calculateOffset(page, prevState.request.limit),
        },
      };
    });
  };
  const updateLimit = (limit: number) => {
    setFilter((prevState) => {
      return {
        ...prevState,
        page: 1,
        request: {
          ...prevState.request,
          limit,
          offset: calculateOffset(1, limit),
        },
      };
    });
  };
  const updateRange = (from: Date, to: Date) => {
    const fromDateString = `${format(
      from,
      DateFormatStringEnum.PATTERN_yyyyMMdd
    )}${DateFormatStringEnum.PATTERN_T000000}`;
    const toDateString = `${format(to, DateFormatStringEnum.PATTERN_yyyyMMdd)}${
      DateFormatStringEnum.PATTERN_T235959
    }`;

    setFilter((prevState) => {
      return {
        ...prevState,
        page: 1,
        request: {
          ...prevState.request,
          from: fromDateString,
          to: toDateString,
          offset: calculateOffset(1, prevState.request.limit),
        },
      };
    });
  };
  const updateMerchant = (merchant: string) => {
    setFilter((prevState) => {
      return {
        ...prevState,
        request: {
          ...prevState.request,
          filter: { ...prevState.request.filter, merchant },
        },
      };
    });
  };

  useEffect(() => {
    setReadyToRender(false);
    dispatch(setSelectedFilter([]));
    const defaultStartDate = `${format(
      new Date(),
      DateFormatStringEnum.PATTERN_yyyyMMdd
    )}${DateFormatStringEnum.PATTERN_T000000}`;
    const todayDate = new Date();
    const fourYearAgoDate = new Date(
      todayDate.getFullYear() - 4,
      todayDate.getMonth(),
      todayDate.getDate()
    );
    const todayFormat = `${format(
      todayDate,
      DateFormatStringEnum.PATTERN_yyyyMMdd
    )}${DateFormatStringEnum.PATTERN_T235959}`;
    const fourYearAgoFormat = `${format(
      fourYearAgoDate,
      DateFormatStringEnum.PATTERN_yyyyMMdd
    )}${DateFormatStringEnum.PATTERN_T000000}`;

    const fourYearsTab: number[] = [
      TabIndexEnum.FIRST_LEVEL_RULES_INDEX,
      TabIndexEnum.WHITE_LISTS_INDEX,
    ];
    const fromDate: string = fourYearsTab.includes(indexTab)
      ? fourYearAgoFormat
      : defaultStartDate;

    setPath(
      fourYearsTab.includes(indexTab)
        ? EndpointsPathsEnum.RULES
        : EndpointsPathsEnum.RULES_REQUEST
    );

    switch (indexTab) {
      case TabIndexEnum.PENDING_REVIEW_INDEX:
        setFilter(() => {
          return {
            page: 1,
            request: {
              filter: {
                status: defaultStatusByTab[TabIndexEnum.PENDING_REVIEW_INDEX],
              },
              from: fromDate,
              to: todayFormat,
              limit: 5,
              offset: 0,
              timeZone: timeZoneMapper.timeZoneHour(
                localStorage.getItem("timeZone")!,
                "utc"
              ),
            },
          };
        });
        break;
      case TabIndexEnum.FIRST_LEVEL_RULES_INDEX:
        setFilter(() => {
          return {
            page: 1,
            request: {
              filter: {
                status:
                  defaultStatusByTab[TabIndexEnum.FIRST_LEVEL_RULES_INDEX],
                type: `${SubTypeEnum.SECURITY_COUNTRY}|${SubTypeEnum.SECURITY_COMMERCE}`,
              },
              from: fromDate,
              to: todayFormat,
              limit: 5,
              offset: 0,
              timeZone: timeZoneMapper.timeZoneHour(
                localStorage.getItem("timeZone")!,
                "utc"
              ),
            },
          };
        });
        break;
      case TabIndexEnum.WHITE_LISTS_INDEX:
        setFilter(() => {
          return {
            page: 1,
            request: {
              filter: {
                status: defaultStatusByTab[TabIndexEnum.WHITE_LISTS_INDEX],
                type: ConfigTypeEnum.WHITE_LIST,
              },
              from: fromDate,
              to: todayFormat,
              limit: 5,
              offset: 0,
              timeZone: timeZoneMapper.timeZoneHour(
                localStorage.getItem("timeZone")!,
                "utc"
              ),
            },
          };
        });
        break;
      case TabIndexEnum.REJECTED_APPLICATIONS_INDEX:
        setFilter(() => {
          return {
            page: 1,
            request: {
              filter: {
                status:
                  defaultStatusByTab[TabIndexEnum.REJECTED_APPLICATIONS_INDEX],
                type: undefined,
              },
              from: fromDate,
              to: todayFormat,
              limit: 5,
              offset: 0,
              timeZone: timeZoneMapper.timeZoneHour(
                localStorage.getItem("timeZone")!,
                "utc"
              ),
            },
          };
        });
        break;
    }
  }, [indexTab]);

  useEffect(() => {
    if (filter.request.from !== "" && filter.request.to !== "") {
      dispatch(getRequestRules(path, filter.request));
      setReadyToRender(true);
    }
  }, [filter]);

  useEffect(() => {
    dispatch(getConfiguration());
  }, []);

  return (
    <FilterSearchContext.Provider
      value={{
        filterSearch: filter,
        readyToRender,
        updateFilter,
        updateLimit,
        updateMerchant,
        updatePage,
        updateRange,
      }}
    >
      {children}
    </FilterSearchContext.Provider>
  );
};

export { FilterSearchProvider };
