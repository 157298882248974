import { IStyles } from "../../../shared/interfaces/create_named_styles";

export const chargeContainerSkeletonStyles: IStyles = {
  title: {
    paddingBottom: "16px",
  },
  boxContent: {
    height: "90%",
    marginBottom: "6em",
    padding: "48px 64px 48px 64px",
    width: "100%",
    borderRadius: "16px",
  },
  formWrapper: {
    backgroundColor: "neutral.white",
    borderRadius: 4,
    marginRight: "150px",
    mb: 15,
    minHeight: 349,
    px: 8,
    py: 6,
  },
  boxContent2: {
    minHeight: "100px",
    bgcolor: "#FFFFFF",
    width: "100%",
    padding: "48px 64px 48px 64px",
  },
  cardContent: {
    borderRadius: 6,
    boxShadow: "0px 0px 10px 5px #6aa7c529",
    marginRight: "150px",
  },
  flagCountry: {
    borderRadius: "100% !important",
    height: "24px",
    transform: "scale(1, 1)",
    width: "24px",
  },
  skeletonText: {
    height: "24px",
  },
};
