import React from "react";
import { Accordion, IconEdit, IconMore, IconTrash } from "@kushki/connect-ui";
import { Box } from "@mui/material";
import {
  DROPDOWN_PRINCIPAL,
  DROPDOWN_PRINCIPAL_COLOR,
  DROPDOWN_SECONDARY,
  DROPDOWN_SECONDARY_COLOR,
  DROPDOWN_TITLE,
} from "../../shared/constants/labels/dropdown_container_labels";
import { IDropDownItem } from "./DropDownItem.interfaces";
import { useDropdownItemState } from "./state/useDropdownItemState";
import { SummaryBankAccountSameCountry } from "../SummaryBankAccountSameCountry/SummaryBankAccountSameCountry";
import { SummaryBankAccountDifferentCountry } from "../SummaryBankAccountDifferentCountry/SummaryBankAccountDifferentCountry";
import { CountriesEnum } from "../../shared/enums/countriesEnum";
import { SummaryChargeBankAccount } from "../SummaryChargeBankAccount/SummaryChargeBankAccount";

const DropDownItem: React.FC<IDropDownItem> = (item: IDropDownItem) => {
  const { summaryItem, isCountryAccountDifferentOfConstitution } =
    useDropdownItemState(item);

  function isCountryEcuadorItem(): boolean {
    return item.country === CountriesEnum.ECUADOR;
  }

  return (
    <Box>
      <Accordion
        divider={false}
        isOpen={item.isMainAccount!}
        iconButton={<IconMore />}
        listDropdown={[
          {
            icon: <IconEdit fontSize="small" />,
            id: "1",
            isDisable: item.disabledActions,
            onSelect: item.handleEditBankAccount,
            text: "Editar",
            variant: "withIcon",
          },
          {
            icon: <IconTrash fontSize="small" />,
            id: "2",
            isDisable: item.disabledActions || item.isMainAccount,
            onSelect: item.handleDeleteBankAccount,
            text: "Eliminar",
            variant: "withIcon",
          },
        ]}
        nesting={1}
        nestingLabel=""
        tagColor={
          item.accountOrder.toUpperCase() === DROPDOWN_PRINCIPAL
            ? DROPDOWN_PRINCIPAL_COLOR
            : DROPDOWN_SECONDARY_COLOR
        }
        tagText={
          item.accountOrder.toUpperCase() === DROPDOWN_PRINCIPAL
            ? DROPDOWN_PRINCIPAL
            : DROPDOWN_SECONDARY
        }
        text={DROPDOWN_TITLE}
      >
        {isCountryEcuadorItem() ? (
          <SummaryChargeBankAccount {...summaryItem} />
        ) : isCountryAccountDifferentOfConstitution ? (
          <SummaryBankAccountDifferentCountry {...summaryItem} />
        ) : (
          <SummaryBankAccountSameCountry {...summaryItem} />
        )}
      </Accordion>
    </Box>
  );
};

export default DropDownItem;
