import React, { FC } from "react";
import { useTransactionList } from "./state/useTransactionList";
import { Box, TablePagination } from "@mui/material";
import { HeaderFilters } from "../HeaderFilters/HeaderFilters";
import EmptyResults from "../EmptyResults/EmptyResults";
import { LabelsEnum, ModalLoaderText } from "../../shared/enums/LabelsEnum";
import { TableSimple } from "@kushki/connect-ui";
import { transactionListStyles } from "./TransactionList.styles";
import ModalDetailInfo from "../ModalDetailInfo/ModalDetailInfo";
import { CustomModalLoader } from "../CustomModalLoader/CustomModalLoader";

const TransactionList: FC = () => {
  const {
    emptyList,
    handleSelectedColumns,
    handlePageChange,
    headers,
    isLoading,
    isLoadingStatus,
    isRol,
    isAdmin,
    onDateSelected,
    page,
    rows,
    rowsPerPage,
    rowsSkeleton,
    selectedBranches,
    totalItems,
    rangeAmount,
    setRangeAmount,
    handleChangeFilterDetails,
    setIsApplyFilter,
    transactionInfo,
    modalDetailInfo,
    isApplyFilter,
    downloadOptions,
    isLoadingTransaction,
    setPage,
  } = useTransactionList();

  return (
    <React.Fragment>
      <Box width={"100%"}>
        <HeaderFilters
          selectedBranches={selectedBranches}
          onDateSelected={onDateSelected}
          onActionsButton={handleSelectedColumns}
          rangeAmountInfo={rangeAmount}
          setRangeAmount={setRangeAmount}
          getItemsSelected={handleChangeFilterDetails}
          setIsApplyFilter={setIsApplyFilter}
          isApplyFilter={isApplyFilter}
          downloadFileOptions={downloadOptions}
          isRol={isRol}
          isAdmin={isAdmin}
          setPage={setPage}
        />
        {emptyList && !isLoading && (
          <EmptyResults
            addText={LabelsEnum.EMPTY_DATA_TITLE}
            title={LabelsEnum.EMPTY_DATA_BODY}
          />
        )}
        {(isLoading || !emptyList) && (
          <React.Fragment>
            <Box sx={transactionListStyles.boxTable}>
              <TableSimple
                isLoading={isLoading}
                header={{
                  cells: headers,
                  headerProps: {
                    isDisable: false,
                  },
                }}
                rows={isLoading ? rowsSkeleton : rows}
                showPagination={false}
              />
            </Box>
            <TablePagination
              component="div"
              count={totalItems}
              page={page}
              onPageChange={handlePageChange}
              backIconButtonProps={{
                sx: transactionListStyles.paginationPageNavigationButton,
              }}
              nextIconButtonProps={{
                sx: transactionListStyles.paginationPageNavigationButton,
              }}
              rowsPerPageOptions={[]}
              rowsPerPage={rowsPerPage}
              labelDisplayedRows={({ from, to, count }) => {
                return "" + from + "-" + to + " de " + count;
              }}
              sx={transactionListStyles.paginationToolbar}
            />
          </React.Fragment>
        )}
        {isAdmin && (
          <ModalDetailInfo
            transactionInfo={transactionInfo}
            isLoadingTransaction={isLoadingTransaction}
            modal={modalDetailInfo}
          />
        )}

        <CustomModalLoader
          descriptionText={ModalLoaderText.DESCRIPTION_TEXT}
          isOpen={isLoadingStatus}
          titleText={ModalLoaderText.TITLE_TEXT}
        />
      </Box>
    </React.Fragment>
  );
};

export default TransactionList;
