import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { IconEdit, IconTrash, ModalSimple } from "@kushki/connect-ui";
import { useDispatch } from "react-redux";
import {
  setEdited,
  setOpenModal,
} from "../../../store/reducers/legalDetails/legalDetails";
import { get, isUndefined, toString } from "lodash";
import { AccordionItemsProps } from "./AccordionItems.interfaces";
import { LegalRepresentativeEnum } from "../../../shared/enum/LegalRepresentativeEnum";
import { ChipColorEnum } from "../../../shared/enum/ChipColorEnum";
import Accordion from "../../Accordion/Accordion";
import { IDropdownItemProps } from "../../DropDownItem/DropdownItem.interfaces";
import { IShareholderInfoItems } from "../../Accordion/Accordion.interfaces";
import { translate_document_type } from "../../../shared/services/CatalogDocumentTypeByCountry";
import { getCountryName } from "../../../shared/constants/Countries";
import {
  getPersonName,
  PersonTypeEnum,
} from "../../../shared/constants/initial_state_legal_details";
import { BasicDataByCountry } from "../../../shared/enum/BasicDataByCountry";
import { CountryEnum } from "../../../shared/countryEnum";
import { PersonTypeObjectInterface } from "../../../shared/enum/BasicStateGeneral/FieldsBasicData";

const AccordionItems: React.FC<AccordionItemsProps> = ({
  constitutionalCountry,
  items,
  personType,
  handleRemoveRepresentative,
  disableMenuAction,
}: AccordionItemsProps) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const tagText = items.isMain
    ? LegalRepresentativeEnum.MAIN
    : LegalRepresentativeEnum.ALTERNATE;
  const tagColor = items.isMain ? ChipColorEnum.SUCCESS : ChipColorEnum.PRIMARY;
  const dropdownOption: IDropdownItemProps[] = [
    {
      icon: <IconEdit />,
      id: "1",
      isDisable: disableMenuAction,
      onSelect: () => {
        dispatch(setOpenModal(true));
        dispatch(
          setEdited({
            id: get(items, "id", ""),
            uid: get(items, "uid", ""),
            value: true,
          })
        );
      },
      text: "Editar",
      variant: "withIcon",
    },
    {
      icon: <IconTrash />,
      id: "2",
      isDisable: disableMenuAction,
      onSelect: () => {
        setOpenDeleteModal(true);
      },
      text: "Eliminar",
      variant: "withIcon",
    },
  ];

  const catalogTypePersonByCountry = (): Array<PersonTypeObjectInterface> =>
    isUndefined(BasicDataByCountry[constitutionalCountry])
      ? BasicDataByCountry[CountryEnum.generic].radioListPersons
      : BasicDataByCountry[constitutionalCountry].radioListPersons;

  const renderData = (title: string, value: string) => {
    return (
      <>
        <Grid item xs={4} color="text.grey">
          <Typography>{title}</Typography>
        </Grid>
        <Grid item xs={6} color="text.dark">
          <Typography>{value}</Typography>
        </Grid>
        <Grid item xs={2} />
      </>
    );
  };

  const renderPersonTypeMoralInfo = () => {
    return (
      <>
        {get(items, "personType", "") === PersonTypeEnum.FISICA ? (
          <Grid container>
            {renderData(
              "Tipo de persona:",
              getPersonName(
                get(items, "personType", ""),
                catalogTypePersonByCountry()
              )
            )}
            {renderData(
              "Nombre(s) y apellido(s):",
              `${get(items, "name", "")} ${get(items, "lastName", "")}`
            )}
            {renderData(
              "Nacionalidad:",
              getCountryName(get(items, "nationality", ""))
            )}
            {renderData("Fecha de nacimiento:", get(items, "birthdate", ""))}
            {renderData(
              "País de residencia:",
              getCountryName(get(items, "country", ""))
            )}
            {renderData(
              "Tipo de identificación:",
              translate_document_type(
                get(items, "nationality", ""),
                get(items, "documentType", "")
              )
            )}
            {renderData(
              "Nro. de identificación:",
              get(items, "documentNumber", "")
            )}
            {renderData("Correo electrónico:", get(items, "email", ""))}
            {renderData("Celular:", get(items, "cellphoneNumber", ""))}
            {renderData(
              "Accionista:",
              get(items, "isShareHolder", false) ? "si" : "no"
            )}
          </Grid>
        ) : (
          <Grid container>
            {renderData(
              "Tipo de persona:",
              getPersonName(
                get(items, "personType", ""),
                catalogTypePersonByCountry()
              )
            )}
            {renderData("Razón social:", get(items, "socialReason", ""))}
            {renderData(
              "País de domicilio de la empresa:",
              getCountryName(get(items, "merchantAdress", ""))
            )}
            {renderData("Número de Tax ID:", get(items, "taxId", ""))}
            {renderData(
              "Fecha de constitución:",
              get(items, "constitutionDate", "")
            )}
            {renderData(
              "País de origen del negocio:",
              getCountryName(get(items, "merchantCountry", ""))
            )}
            {renderData("Correo electrónico:", get(items, "email", ""))}
            {renderData("Celular:", get(items, "cellphoneNumber", ""))}
            {renderData(
              "Accionista:",
              get(items, "isShareHolder", false) ? "si" : "no"
            )}
          </Grid>
        )}
      </>
    );
  };

  const renderPersonTypeInfo = () => {
    return (
      <Grid container>
        {renderData(
          "Nombre(s) y apellido(s):",
          `${get(items, "name", "")} ${get(items, "lastName", "")}`
        )}
        {renderData(
          "Nacionalidad:",
          getCountryName(get(items, "nationality", ""))
        )}
        {renderData("Fecha de nacimiento:", get(items, "birthdate", ""))}
        {renderData(
          "País de residencia:",
          getCountryName(get(items, "country", ""))
        )}
        {renderData(
          "Tipo de identificación:",
          translate_document_type(
            get(items, "nationality", ""),
            get(items, "documentType", "")
          )
        )}
        {renderData(
          "Nro. de identificación:",
          get(items, "documentNumber", "")
        )}
        {renderData("Correo electrónico:", get(items, "email", ""))}
        {renderData("Celular:", get(items, "cellphoneNumber", ""))}
      </Grid>
    );
  };

  return (
    <Box>
      <Accordion
        key={items.id}
        text={"Representante Legal"}
        isOpen={items.isMain}
        divider={false}
        tagText={tagText}
        tagColor={tagColor}
        nesting={toString("")}
        listDropdown={dropdownOption}
        shareholderInfo={{} as IShareholderInfoItems}
        iconSummaryDown={true}
        isDisableDropdownList={disableMenuAction}
      >
        {personType === PersonTypeEnum.FISICA
          ? renderPersonTypeInfo()
          : renderPersonTypeMoralInfo()}
      </Accordion>
      <ModalSimple
        onClickPrimary={() => {
          handleRemoveRepresentative(items);
          setOpenDeleteModal(false);
        }}
        onClickSecondary={() => {
          setOpenDeleteModal(false);
        }}
        buttonPrimary={"Sí, eliminar"}
        buttonSecondary={"Cancelar"}
        descriptionText={
          "Se borrará toda la información del representate legal."
        }
        isOpen={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        titleText={
          "¿Estás seguro de que deseas eliminar el representante legal?"
        }
        typeModal={1}
        buttonPrimaryDisabled={false}
      />
    </Box>
  );
};

export default AccordionItems;
