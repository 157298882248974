import { Box, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import React, { FC } from "react";
import WrappedTabs from "../WrappedTabs/WrappedTabs";
import { useWrapperContainer } from "./state/useWrapperContainer";
import { IWrapperContainer } from "./WrapperContainer.interfaces";
import { wrapperContainerStyles as styles } from "./WrapperContainer.styles";
import Subheader from "../Subheader/Subheader";
import FooterOptions from "../FooterOptions/FooterOptions";
import { get, isEmpty } from "lodash";
import * as BILLING_LABELS from "../../shared/constants/labels/billing_form_labels";
import { ModalLoader, SnackbarAlert } from "@kushki/connect-ui";
import { TypeRequestEnum } from "../../shared/enums/typeRequestEnum";
import Snackbar from "@mui/material/Snackbar";

const WrapperContainer: FC<IWrapperContainer> = ({
  children,
  currentRoute,
  type,
}: IWrapperContainer) => {
  const {
    footerLabels,
    subHeaderTexts,
    tabs,
    activeFooterButton,
    footerButtons,
    showSavedButtonText,
    openModalLoader,
    snackbar,
    operationCountry,
    securityIdPrimary,
    securityIdSecondary,
    isEditOperationParam,
  } = useWrapperContainer({
    currentRoute,
    type,
  });

  return (
    <>
      <ModalLoader
        descriptionText={BILLING_LABELS.DESCRIPTION_LOADER}
        isOpen={openModalLoader}
        titleText={BILLING_LABELS.LOADER_TITLE}
      />
      <Snackbar
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        open={snackbar.showError || snackbar.showSuccess}
        autoHideDuration={3000}
      >
        <SnackbarAlert
          variant={"simple"}
          message={
            snackbar.showError
              ? BILLING_LABELS.SNACKBAR_FETCH_ERROR
              : snackbar.showSuccess
              ? BILLING_LABELS.SNACKBAR_FETCH_SUCCESS
              : ""
          }
          color={
            snackbar.showError
              ? "danger"
              : snackbar.showSuccess
              ? "success"
              : "info"
          }
        />
      </Snackbar>
      <Box
        sx={{
          ...styles.containerBody,
          paddingLeft: isEditOperationParam ? "200px" : undefined,
        }}
      >
        {isEditOperationParam && (
          <IconButton
            style={{
              color: "#4399ED",
              paddingLeft: 1,
              width: "fit-content",
            }}
            onClick={() => window.history.go(-1)}
          >
            <ArrowBackIosIcon />
            {BILLING_LABELS.RETURN_BUTTON_OPERATION_EDIT_LABEL}
          </IconButton>
        )}
        <Subheader {...subHeaderTexts} />
        <Box sx={styles.wrappedTabs}>
          {!isEditOperationParam && <WrappedTabs tabs={tabs} />}
        </Box>
        <Box sx={styles.containerForm}>{children}</Box>
      </Box>
      <Box sx={styles.containerFooter}>
        {!isEmpty(operationCountry) && (
          <FooterOptions
            primaryButton={{
              componentIdSecurity: securityIdPrimary!(type!),
              isDisabled: footerButtons.disableContinue,
              isHidden: footerButtons.isHidden,
              onAction: () => activeFooterButton(TypeRequestEnum.CONTINUE),
            }}
            secondaryButton={{
              componentIdSecurity: securityIdSecondary!(type!),
              isDisabled: footerButtons.disableSave,
              onAction: () => activeFooterButton(TypeRequestEnum.SAVE),
            }}
            isLoading={showSavedButtonText}
            label={{
              editDetail: false,
              isHidden: footerLabels === null,
              modify: get(footerLabels, "date", ""),
              text: get(footerLabels, "author", ""),
            }}
            isOperation={isEditOperationParam}
          />
        )}
      </Box>
    </>
  );
};

export default WrapperContainer;
