import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@store/hooks/storeHook";
import { RootState } from "@store/store";
import {
  setNotification,
  setTimezoneProps,
} from "@store/reducers/general/general.slice";
import { useSnackbar } from "@kushki/connect-ui";
import { timeZoneHour } from "@shared/utils/parseDatesUtils";

export const useWrapperContainer = () => {
  const dispatch = useAppDispatch();
  const { notification } = useAppSelector((state: RootState) => state.general);

  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (notification) {
      showSnackbar(notification);
      dispatch(setNotification(null));
    }
  }, [notification]);

  useEffect(() => {
    dispatch(
      setTimezoneProps({
        regionTz: timeZoneHour(localStorage.getItem("timeZone")!, "region"),
        utcTimeZone: timeZoneHour(localStorage.getItem("timeZone")!, "utc"),
      })
    );
  }, []);

  return {};
};
