import React from "react";
import { Grid, Typography, Divider, Switch, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTypedController } from "@hookform/strictly-typed";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ConnectForm from "../commons/ConnectForm/ConnectForm";
import { ISecurityRuleForm } from "../../shared/infrastructure/interfaces/ISecurityRuleForm";
import {
  AuthRulesAdditionalInfo,
  SecurityRulesSubtitleSection,
  SPEED_CONDITION_VALIDATION,
} from "../../shared/infrastructure/constants/SecurityRulesConstants";
import { useWatch } from "react-hook-form";
import { isEmpty } from "lodash";
import GeneralActionSection from "./GeneralActionSection";
import StrictConditionsSection from "./StrictConditionsSection";
import { PaymentMethodEnum } from "../../shared/infrastructure/enums/PaymentMethodEnum";
import SpeedConditionsSection from "./SpeedConditionsSection";
import { IOptionSelection } from "../../shared/infrastructure/constants/RulesConstants";
import { MerchantCredential } from "../../../types/merchant_credential";
import { GeneralActionsEnum } from "../../shared/infrastructure/enums/GeneralActionsEnum";

const primaryColor = "#023365";

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: "14px",
    color: primaryColor,
  },
  subtitle: {
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: "14px",
  },
}));

export interface ConfigurationInformationSectionProps {
  indexesStrictCondition: string[];
  addStrictCondition: (index: number) => void;
  removeStrictCondition: (item: number) => void;
  indexesSpeedCondition: string[];
  addSpeedCondition: (index: number) => void;
  removeSpeedCondition: (item: number) => void;
  countryGeneral: string;
  terminateFillFields: boolean;
  credentials: IOptionSelection[];
  generalAction: string;
  isLoadingGetSuggestedCredentials: boolean;
  credentialsSuggested: MerchantCredential[];
  handleEnterSearchTextCredentialList: (key: string) => void;
  handleSetSearchTextCredentialList: (searchTextCredential: string) => void;
  setCredentialInformation: (credential: object, index: number) => void;
  handleSetGeneralAction: (generalAction: string) => void;
}

export const ConfigurationInformationSection: React.FC<ConfigurationInformationSectionProps> =
  (props: ConfigurationInformationSectionProps) => {
    const classes = useStyles();

    return (
      <ConnectForm<ISecurityRuleForm>>
        {({ control }) => {
          const TypedController = useTypedController<ISecurityRuleForm>({
            control: control,
          });
          const service = useWatch({
            name: "service",
          }) as string;

          const action = useWatch({
            name: "action",
          }) as string;

          const ruleType = useWatch({
            name: "type",
          }) as string;

          const activeSpeedCondition = useWatch({
            name: "activeSpeedCondition",
          }) as string;

          if (!props.generalAction) {
            props.handleSetGeneralAction(action);
          }

          return (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className={classes.title}>
                      {SecurityRulesSubtitleSection.GENERAL_ACTION_HEADER}
                    </Typography>
                    <Typography className={classes.subtitle}>
                      {SecurityRulesSubtitleSection.GENERAL_ACTION_SUBTITLE}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <GeneralActionSection
                      handleSetGeneralAction={props.handleSetGeneralAction}
                      generalAction={props.generalAction}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <br />
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className={classes.title}>
                      {SecurityRulesSubtitleSection.STRICT_CONDITION_HEADER}
                    </Typography>
                    <Typography className={classes.subtitle}>
                      {SecurityRulesSubtitleSection.STRICT_CONDITION_SUBTITLE}
                    </Typography>
                    <Typography className={classes.subtitle}>
                      {SecurityRulesSubtitleSection.STRICT_CONDITION_SUBTITLE2}
                    </Typography>
                    <br />
                  </Grid>
                  {props.indexesStrictCondition.map(
                    (value: string, index: number) =>
                      value === "value" ? (
                        <Grid item xs={12}>
                          <StrictConditionsSection
                            index={index}
                            total={props.indexesStrictCondition}
                            addStrictCondition={props.addStrictCondition}
                            removeStrictCondition={props.removeStrictCondition}
                            disabled={isEmpty(service)}
                            credentials={props.credentials}
                            credentialsSuggested={props.credentialsSuggested}
                            countryGeneral={props.countryGeneral}
                            terminateFillFields={props.terminateFillFields}
                            handleEnterSearchTextCredentialList={
                              props.handleEnterSearchTextCredentialList
                            }
                            setCredentialInformation={
                              props.setCredentialInformation
                            }
                            handleSetSearchTextCredentialList={
                              props.handleSetSearchTextCredentialList
                            }
                            isLoadingGetSuggestedCredentials={
                              props.isLoadingGetSuggestedCredentials
                            }
                            generalAction={props.generalAction}
                          />
                        </Grid>
                      ) : null
                  )}
                </Grid>
              </Grid>
              {service === PaymentMethodEnum.CARD &&
              SPEED_CONDITION_VALIDATION.includes(action) &&
              ruleType !== "securityCountry" ? (
                <Grid item xs={12}>
                  <br />
                  <Divider />
                </Grid>
              ) : null}
              {service === PaymentMethodEnum.CARD &&
              SPEED_CONDITION_VALIDATION.includes(action) &&
              ruleType !== "securityCountry" ? (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={9}>
                      <Typography className={classes.title}>
                        {SecurityRulesSubtitleSection.SPEED_CONDITION_HEADER}
                      </Typography>
                      <Typography className={classes.subtitle}>
                        {SecurityRulesSubtitleSection.SPEED_CONDITION_SUBTITLE}
                      </Typography>
                      <br />
                    </Grid>
                    <Grid item xs={3}>
                      <Grid container justify={"flex-end"}>
                        <Grid item>
                          <TypedController
                            name={["activeSpeedCondition"]}
                            defaultValue={false}
                            render={({ value, onChange, ...props }) => (
                              <Switch
                                {...props}
                                checked={!!value}
                                onChange={(e) => onChange(e.target.checked)}
                                color="primary"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {activeSpeedCondition
                      ? props.indexesSpeedCondition.map(
                          (value: string, index: number) =>
                            value === "value" ? (
                              <Grid item xs={12}>
                                <SpeedConditionsSection
                                  index={index}
                                  total={props.indexesSpeedCondition}
                                  addSpeedCondition={props.addSpeedCondition}
                                  removeSpeedCondition={
                                    props.removeSpeedCondition
                                  }
                                />
                              </Grid>
                            ) : null
                        )
                      : null}
                  </Grid>
                </Grid>
              ) : null}
              {props.generalAction === GeneralActionsEnum.THREE_DS ? (
                <Grid item xs={12}>
                  <br />
                  <Divider />
                </Grid>
              ) : null}
              {props.generalAction === GeneralActionsEnum.THREE_DS ? (
                <Grid item xs={12}>
                  <Typography className={classes.title}>
                    {AuthRulesAdditionalInfo.ADDITIONAL_INFO}
                  </Typography>
                  <Typography className={classes.subtitle}>
                    {AuthRulesAdditionalInfo.ADDITIONAL_INFO_DESCRIPTION}
                  </Typography>
                  <Grid container>
                    <Box display="flex" mt={3}>
                      <TypedController
                        name={["activeTransactionRisk"]}
                        defaultValue={false}
                        render={({ value, onChange, ...props }) => (
                          <Switch
                            {...props}
                            checked={!!value}
                            onChange={(e) => onChange(e.target.checked)}
                            color="primary"
                          />
                        )}
                      />
                      <Box pl={2}>
                        <Typography className={classes.subtitle}>
                          {AuthRulesAdditionalInfo.ASSUME_TRANSACTIONAL_RISK}
                        </Typography>
                        <Box display="flex" mt={1}>
                          <InfoOutlinedIcon
                            style={{ color: primaryColor, marginRight: "3px" }}
                          />
                          <Typography className={classes.subtitle}>
                            {
                              AuthRulesAdditionalInfo.ASSUME_TRANSACTIONAL_RISK_DESCRIPTION
                            }
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <br />
              </Grid>
            </Grid>
          );
        }}
      </ConnectForm>
    );
  };

export default ConfigurationInformationSection;
