export const styles = {
  container: {
    padding: "16px 24px",
    display: "flex",
    flexDirection: "column" as "column",
    gap: "16px",
    backgroundColor: "neutral.grey3",
    borderRadius: "8px",
  },
};
