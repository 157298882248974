import { ITableRowProps, Order } from "../interfaces";
import {
  IUseTableNodesInterfaces,
  IUseTableNodesRequest,
} from "./IUseTableNodes.interfaces";
import React, { useEffect, useState } from "react";
import { find, findIndex, get } from "lodash";
import { MerchantNodeData } from "../../../../../types/search_merchant_response";
import { useAppSelector } from "../../../../store/hooks/storeHook";
import { RootState } from "../../../../store/store";

export const useTableNodes = ({
  handleSelectedRows,
  rows,
  rowsPerPage,
  totalData,
  setNodeDataSelected,
  resetItemChecked,
}: IUseTableNodesRequest): IUseTableNodesInterfaces => {
  const [order] = React.useState<Order>("asc");
  const [orderBy] = React.useState<string>("line112343242");
  const [selected, setSelected] = React.useState<string[]>([]);
  const totalPages = totalData > 0 ? Math.ceil(totalData / rowsPerPage) : 0;
  const [dataSelected, setDataSelected] = useState<MerchantNodeData[]>([]);

  const { isLoadingMerchants, isLoadingFlow } = useAppSelector(
    (state: RootState) => ({
      ...state.ownerReducer,
      ...state.customerReducer,
    })
  );

  const getSelectedData = (selectedRows: string[]) => {
    const data: MerchantNodeData[] = [];

    selectedRows.map((id: string) => {
      const position: number = findIndex(rows, ["id", id]);

      if (position > -1) {
        data.push(rows[position].info);
      }
    });

    setDataSelected(data);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);

      setSelected(newSelected);
      getSelectedData(newSelected);

      return;
    }
    setSelected([]);
    getSelectedData([]);
  };

  const handleClick = (_event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    getSelectedData(newSelected);
  };

  const isSelected = (id: string): boolean => selected.indexOf(id) !== -1;

  const findDataByRowId = (rowId: string): MerchantNodeData => {
    const value: ITableRowProps | undefined = find(rows, ["id", rowId]);

    return get(value, "info") as MerchantNodeData;
  };

  const handleGetRowByMenuAction = (rowId: string) => {
    setNodeDataSelected(findDataByRowId(rowId));
  };

  useEffect(() => {
    handleSelectedRows(dataSelected);
  }, [dataSelected]);

  useEffect(() => {
    if (resetItemChecked !== undefined) {
      setSelected([]);
      setDataSelected([]);
    }
  }, [resetItemChecked]);

  return {
    handleGetRowByMenuAction,
    headerProps: {
      order,
      orderBy,
    },
    isLoadingFlow,
    isLoadingMerchants,
    rowsProps: {
      check: {
        handleClick,
        handleSelectAllClick,
        isSelected,
        selected,
      },
    },
    totalPages,
  };
};
