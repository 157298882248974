import React from "react";
import { Container, Typography } from "@material-ui/core";
import { useRuleDashboardIndexState } from "./state/useRuleDashboardIndexState";
import { DashboardTabs } from "../../components/DashboardTabs/DashboardTabs";
import { TabsTable } from "../../shared/infrastructure/constants/RuleRequestAuthenticationConstants";
import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
import { makeStyles } from "@material-ui/core/styles";
import { RuleApprovalDashboard } from "../RuleApprovalDashboard/RuleApprovalDashboard";
import { RuleHistoricDashboard } from "../RuleHistoricDashboard/RuleHistoricDashboard";
import { IAppState } from "../../store/reducer";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  getCredentialData,
  getProcessorData,
  IAppAction,
} from "../../store/actionCreators";
import { connect } from "react-redux";
import { ProcessorInfoResponse } from "../../../types/processor_info_response";
import { CredentialInfoResponse } from "../../../types/credential_info_response";
import { RuleRequestDashboard } from "../RuleRequestDashboard/RuleRequestDashboard";
import { AuthenticationRulesDashboard } from "../../shared/infrastructure/constants/AuthenticationRulesConstants";

export interface IRuleDashBoardIndexStateProps {
  processorData: ProcessorInfoResponse[];
  credentialData: CredentialInfoResponse[];
}

export interface IRuleDashBoardIndexFunctionsProps {
  getProcessorData: (merchantId: string) => void;
  getCredentialData: (merchantId: string) => void;
}

export type TRuleDashBoardIndexProps = IRuleDashBoardIndexStateProps &
  IRuleDashBoardIndexFunctionsProps;

const useStyles = makeStyles({
  titleContent: {
    lineHeight: "2.5rem",
    marginTop: "25px",
    marginBottom: "35px",
  },
});

export const RuleDashboardIndex: React.FC<TRuleDashBoardIndexProps> = (
  props: TRuleDashBoardIndexProps
) => {
  const classes = useStyles();
  const {
    breadCrumbs,
    stateUtilTab2,
    stateUtilTabRequest,
    stateUtilTabApproval,
  } = useRuleDashboardIndexState();
  return (
    <React.Fragment>
      <Container fixed>
        <Breadcrumb items={breadCrumbs.items} lastItem={breadCrumbs.lastItem} />
        <Typography
          variant="h1"
          color="primary"
          className={classes.titleContent}
        >
          {AuthenticationRulesDashboard.TITLE}
        </Typography>
        <DashboardTabs
          tabsHeader={[...TabsTable]}
          tabsContent={[
            {
              index: 0,
              children: (
                <RuleApprovalDashboard
                  stateUtilTab={stateUtilTabApproval}
                  {...props}
                />
              ),
            },
            {
              index: 1,
              children: (
                <RuleRequestDashboard
                  stateUtilTab={stateUtilTabRequest}
                  {...props}
                />
              ),
            },
            {
              index: 2,
              children: (
                <RuleHistoricDashboard
                  stateUtilTab={stateUtilTab2}
                  {...props}
                />
              ),
            },
          ]}
        />
      </Container>
    </React.Fragment>
  );
};

export const mapStateToProps: (
  state: IAppState
) => IRuleDashBoardIndexStateProps = (
  state: IAppState
): IRuleDashBoardIndexStateProps => ({
  processorData: state.processorData,
  credentialData: state.credentialData,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => IRuleDashBoardIndexFunctionsProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): IRuleDashBoardIndexFunctionsProps => ({
  getProcessorData: (merchantId: string): void =>
    dispatch(getProcessorData(merchantId)),
  getCredentialData: (merchantId: string): void =>
    dispatch(getCredentialData(merchantId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RuleDashboardIndex);
