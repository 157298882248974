import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export interface ConfirmationSubdomainProps {
  show: boolean;
  closePopUp: () => void;
  cancel: () => void;
  subdomain: string;
}

const useStyles = makeStyles({
  titleStyle: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "120%",
    color: "#293036",
  },
  infoStyle: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 15,
    lineHeight: "120%",
    color: "#293036",
  },
  buttonStyle: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "140%",
  },
});

export const ConfigurationSubdomainPopUp: React.FC<ConfirmationSubdomainProps> = (
  props: ConfirmationSubdomainProps
) => {
  const styles = useStyles();
  return (
    <Dialog
      open={props.show}
      onClose={props.closePopUp}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography className={styles.titleStyle}>
          {`¿Deseas hacer el cambio de subdominio a ${props.subdomain}?`}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography className={styles.infoStyle}>
            Ten en cuenta que si realizas un cambio del subdominio, los
            smartlinks creados hasta el momento serán actualizados y los enlaces
            de pago serán distintos a los que ya creaste.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.cancel} color="primary">
          <Typography className={styles.buttonStyle}>Cancelar</Typography>
        </Button>
        <Button onClick={props.closePopUp} color="primary" autoFocus>
          <Typography className={styles.buttonStyle}>
            Cambiar subdominio
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
