import React from "react";
import { createStyles, Grid, Paper, Typography } from "@material-ui/core";
import { defaultTo, get, isNil } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { CountryEnum } from "../../../shared/infrastructure/CountryEnum";
import { ModalBodyContentDetail } from "../ModalBodyContentDetail";
import { InfoTrxProps } from "../../ModalBodyInfo/ModalBodyRetentionHistoric";
import {
  subSectionEnum,
  tabEnum,
} from "../../../shared/infrastructure/AdvanceConfigModal";
import { getAdvanceSettings } from "../../ModalBodyInfo/utilsModalBody";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: "#ccc",
      zIndex: 1,
      color: "#fff",
      width: 32,
      height: 32,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
    },
    paper: {
      boxShadow: "none !important",
      borderRadius: "5px !important",
      backgroundColor: "#F7FAFC !important",
      marginBottom: "30px !important",
      marginTop: "30px !important",
    },
    active: {
      backgroundColor: "#0DC298",
    },
    completed: {
      backgroundColor: "#0DC298",
    },
    linearProgress: {
      marginTop: 3,
    },
    grid: {
      paddingTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
    },
    margin: {
      marginTop: 10,
    },
  })
);
export interface AdvanceConfigurationTabProps {
  InfoTrxProps: InfoTrxProps;
  country: CountryEnum;
  isHistoric: boolean;
}

const getGrid = (
  title: string,
  props: AdvanceConfigurationTabProps,
  subSection: subSectionEnum
) => {
  const classes = useStyles();
  const { InfoTrxProps, country, isHistoric } = props;

  return (
    <Grid>
      <Typography color={"primary"} variant={"body1"}>
        {title}
      </Typography>
      <Paper className={classes.paper}>
        <Grid container spacing={2} color="secondary" className={classes.grid}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <ModalBodyContentDetail
                tabName={tabEnum.advanceConfigTab}
                trxProps={InfoTrxProps}
                country={defaultTo(country, CountryEnum.ecuador)}
                subSection={subSection}
                isMinAmount={true}
                isHistoric={isHistoric}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export const AdvanceConfigTabDetail: React.FC<AdvanceConfigurationTabProps> = (
  props: AdvanceConfigurationTabProps
) => {
  const { InfoTrxProps } = props;
  const advance_settings = getAdvanceSettings(get(InfoTrxProps, "trxInfo", {}));

  return (
    <>
      {!isNil(advance_settings.advanceSettingsReference) &&
        getGrid("Referencias", props, subSectionEnum.reference)}
      {!isNil(advance_settings.advanceSettingsParameters) &&
        getGrid("Parámetros", props, subSectionEnum.parameters)}
    </>
  );
};
