import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { Boardmembers } from "../../../../../types/get_compliance_people_response";
import { useBoardMemberContent } from "./State/useBoardMemberContent";
import { boardMembersContentStyles as styles } from "./BoardMembers.styles";

interface IBoardMemberContentProps {
  boardMember: Boardmembers;
}
const BoardMemberContent: React.FC<IBoardMemberContentProps> = ({
  boardMember,
}: IBoardMemberContentProps) => {
  const { date, dateLabel, documentLabel, document, countryLabel, country } =
    useBoardMemberContent(boardMember);

  return (
    <Box sx={styles.container}>
      <Box>
        <Typography sx={styles.text}>{countryLabel}</Typography>
        <Typography sx={styles.text}>{country}</Typography>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box>
        <Typography sx={styles.text}>{dateLabel}</Typography>
        <Typography sx={styles.text}>{date}</Typography>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box>
        <Typography sx={styles.text}>{documentLabel}</Typography>
        <Typography sx={styles.text}>{document}</Typography>
      </Box>
      <Divider orientation="vertical" flexItem />
    </Box>
  );
};

export default BoardMemberContent;
