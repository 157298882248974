import { CustomStyles } from "../../shared/infrastructure/interfaces/CustomStyles";

export const balanceDataStyles: CustomStyles = {
  container: {
    display: "flex",
    gap: "1rem",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: 0,
    maxWidth: {
      md: "100%",
      lg: "60%",
      xl: "50%",
      xs: "100%",
    },
  },
  containerGrid: {
    justifyContent: "flex-start",
    width: "100%",
    alignItems: "center",
  },
  itemContainer: {
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
  },
  dividerVertical: {
    borderWidth: "0px thin 0px 0px",
    borderStyle: "solid",
    borderColor: "#E2E8F0",
    color: "text.grey",
  },
};
