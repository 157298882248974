import React from "react";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "react-feather";

export interface PaginationTableProps {
  pages: number[];
  currentPages: number;
  handleChangePages: (value: number) => void;
  handleClickPrevious: () => void;
  handlerClickNext: () => void;
  disableNext: boolean;
  disablePrevious: boolean;
}
export const PaginationTable: React.FC<PaginationTableProps> = (
  props: PaginationTableProps
) => {
  return (
    <React.Fragment>
      <Grid container component={Box} px={2} py={2}>
        <Grid
          item
          container
          xs={6}
          alignItems="center"
          justify="flex-start"
          direction="row"
        >
          <Typography variant={"body2"}>Transacciones por página</Typography>
          <Select
            disableUnderline
            value={props.currentPages}
            onChange={(e) => {
              props.handleChangePages(e.target.value as number);
            }}
            style={{ paddingLeft: "24px" }}
          >
            {props.pages.map((page: number) => (
              <MenuItem key={page} value={page}>
                <Typography variant={"body2"} color={"textSecondary"}>
                  {page}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid
          item
          xs={6}
          container
          alignItems="center"
          justify="flex-end"
          direction="row"
        >
          <Button
            startIcon={<ChevronLeft size={25} />}
            disabled={props.disablePrevious}
            onClick={props.handleClickPrevious}
          >
            Anterior
          </Button>

          <Button
            endIcon={<ChevronRight size={25} />}
            disabled={props.disableNext}
            onClick={props.handlerClickNext}
          >
            Siguiente
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
