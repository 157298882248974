import React from "react";
import { CardEntityName } from "../CardEntity/CardEntityName";
import { Grid, Typography } from "@mui/material";
import { useStyles } from "../EntityStyles/EntityStyles";
import { MerchantNodeData } from "../../../../types/search_merchant_response";
import { VerticalSpace } from "../VerticalSpace/VerticalSpace";
import { getSimplifiedFormatLocal } from "../../../shared/utils/date_utils";

export interface IOwnerEntity {
  selected: boolean;
  owner: MerchantNodeData;
}

export const OwnerEntity: React.FC<IOwnerEntity> = ({
  selected,
  owner,
}: IOwnerEntity) => {
  const styles = useStyles();

  return (
    <React.Fragment>
      <CardEntityName selected={selected}>
        <Typography className={styles.subtitle} variant="inherit">
          Owner
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          columns={2}
        >
          <Typography className={styles.primaryTitle2} variant="inherit">
            {owner.name}
          </Typography>
          <Typography className={styles.text} variant="inherit">
            Creado: {owner.created && getSimplifiedFormatLocal(owner.created)}
          </Typography>
        </Grid>
      </CardEntityName>
      <VerticalSpace selected={selected} width={40} />
    </React.Fragment>
  );
};
