import {
  IHistoricalRefundsFilters,
  IRefundsFilters,
} from "../../../store/interfaces/RefundsState.interfaces";

export const isEmptyFilters = (
  filters: IRefundsFilters | IHistoricalRefundsFilters
): boolean =>
  filters.dateTime.from === "" ||
  filters.dateTime.to === "" ||
  filters.dateTime.timezone === "";
