/* istanbul ignore file */

import { IOptionSelection } from "../interfaces/IOptionSelection";

export const BANKS_ECUADOR: IOptionSelection[] = [
  { value: "BANCO DEL AUSTRO S.A.", label: "BANCO DEL AUSTRO S.A." },
  {
    value: "FILIAL LATINOAMERICANA DE TARJETAS DE CREDITO FILA",
    label: "FILIAL LATINOAMERICANA DE TARJETAS DE CREDITO FILA",
  },
  { value: "BANCO DE MACHALA S.A.", label: "BANCO DE MACHALA S.A." },
  {
    value: "DINERS CLUB DEL ECUADOR S.A. SOCIEDAD FINANCIERA",
    label: "DINERS CLUB DEL ECUADOR S.A. SOCIEDAD FINANCIERA",
  },
  { value: "BANCO PICHINCHA C.A.", label: "BANCO PICHINCHA C.A." },
  {
    value: "COOPERATIVA DE AHORRO Y CREDITO COOPROGRESO LTDA",
    label: "COOPERATIVA DE AHORRO Y CREDITO COOPROGRESO LTDA",
  },
  {
    value: "Cooperativa de Ahorro y Crédito Policía Nacional",
    label: "Cooperativa de Ahorro y Crédito Policía Nacional",
  },
  { value: "BANCO CAPITAL / MANEJA BI", label: "BANCO CAPITAL / MANEJA BI" },
  { value: "Banco Amazonas S.A.", label: "Banco Amazonas S.A." },
  { value: "BANCO DE MACHALA, S.A.", label: "BANCO DE MACHALA, S.A." },
  { value: "BANCO DEL AUSTRO, S.A.", label: "BANCO DEL AUSTRO, S.A." },
  {
    value: "Cooperativa de Ahorro y Credito Juventud Ecuatoriana Progresista",
    label: "Cooperativa de Ahorro y Credito Juventud Ecuatoriana Progresista",
  },
  { value: "BANCO DE GUAYAQUIL", label: "BANCO DE GUAYAQUIL" },
  {
    value: "BANCO DE LA PRODUCCION S.A. (PRODUBANCO)",
    label: "BANCO DE LA PRODUCCION S.A. (PRODUBANCO)",
  },
  {
    value: "ASOCIACION MUTUALISTA DE A/C PARA LA VIVIENDA PICHINCHA",
    label: "ASOCIACION MUTUALISTA DE A/C PARA LA VIVIENDA PICHINCHA",
  },
  { value: "BANCO DEL PACIFICO, S.A.", label: "BANCO DEL PACIFICO, S.A." },
  { value: "FILANBANCO, S.A.", label: "FILANBANCO, S.A." },
  {
    value: "MASTERCARD - MDS FOR EUROPE DE",
    label: "MASTERCARD - MDS FOR EUROPE DE",
  },
  {
    value: "COOPERATIVA DE AHORRO Y CREDITO POLICIA NACIONAL",
    label: "COOPERATIVA DE AHORRO Y CREDITO POLICIA NACIONAL",
  },
  { value: "BCO TERRITORIAL", label: "BCO TERRITORIAL" },
  {
    value: "COOPERATIVA DE AHORRO Y CRÉDITO OSCUS LTDA",
    label: "COOPERATIVA DE AHORRO Y CRÉDITO OSCUS LTDA",
  },
  { value: "BANCO LA PREVISORA", label: "BANCO LA PREVISORA" },
  { value: "BANCO INTERNACIONAL, S.A.", label: "BANCO INTERNACIONAL, S.A." },
  { value: "DINERS CLUB INTERNATIONAL", label: "DINERS CLUB INTERNATIONAL" },
  { value: "PRODUBANCO", label: "PRODUBANCO" },
  { value: "CREDIT AGRICOLE, S.A.", label: "CREDIT AGRICOLE, S.A." },
  { value: "CITIBANK USA, N.A.", label: "CITIBANK USA, N.A." },
  {
    value: "CARD SERVICES FOR CREDIT UNIONS, INC.",
    label: "CARD SERVICES FOR CREDIT UNIONS, INC.",
  },
  { value: "BANCO SOLIDARIO S.A.", label: "BANCO SOLIDARIO S.A." },
  { value: "DISCOVER DINERS CLUB", label: "DISCOVER DINERS CLUB" },
  {
    value: "BANCO FINCA/ COOP AHORRO Y CRÉDITO RIOBAMBA / MANEJA BI",
    label: "BANCO FINCA/ COOP AHORRO Y CRÉDITO RIOBAMBA / MANEJA BI",
  },
  { value: "BANCO DEL AZUAY", label: "BANCO DEL AZUAY" },
  { value: "ASSOCIATED BANK, N.A.", label: "ASSOCIATED BANK, N.A." },
  { value: "BANCO AMAZONAS, S.A.", label: "BANCO AMAZONAS, S.A." },
  { value: "BANCO DE GUAYAQUIL S.A.", label: "BANCO DE GUAYAQUIL S.A." },
  { value: "BANCO DE LOJA S.A.", label: "BANCO DE LOJA S.A." },
  { value: "BANCO DEL PICHINCHA, C.A.", label: "BANCO DEL PICHINCHA, C.A." },
  { value: "SERVICIOS BANKCARD, S.A.", label: "SERVICIOS BANKCARD, S.A." },
  {
    value: "CHINA MINSHENG BANKING CORP., LTD.",
    label: "CHINA MINSHENG BANKING CORP., LTD.",
  },
  { value: "MASTERCARD ECUADOR", label: "MASTERCARD ECUADOR" },
  {
    value: "BANCO PROCREDIT ECUADOR S.A.",
    label: "BANCO PROCREDIT ECUADOR S.A.",
  },
  { value: "BANK OF AMERICA", label: "BANK OF AMERICA" },
  { value: "ICUL SERVICE CORPORATION", label: "ICUL SERVICE CORPORATION" },
  {
    value: "Cooperativa de Ahorro y Credito Juventud Ecuatoriana Progres",
    label: "Cooperativa de Ahorro y Credito Juventud Ecuatoriana Progres",
  },
  {
    value: "ASOCIACION MUTUALISTA DE AHORRO Y CREDITO PARA LA VIVIENDA A",
    label: "ASOCIACION MUTUALISTA DE AHORRO Y CREDITO PARA LA VIVIENDA A",
  },
  {
    value: "CAPITAL BANK OF JORDAN PLC. CO.",
    label: "CAPITAL BANK OF JORDAN PLC. CO.",
  },
  { value: "FISERV SOLUTIONS, INC.", label: "FISERV SOLUTIONS, INC." },
  {
    value: "WESTPAC BANKING CORPORATION",
    label: "WESTPAC BANKING CORPORATION",
  },
  {
    value: "BANCO GENERAL RUMINAHUI, S.A.",
    label: "BANCO GENERAL RUMINAHUI, S.A.",
  },
  { value: "Banco Solidario S.A.", label: "Banco Solidario S.A." },
  {
    value: "BANCO DE LA PRODUCCION,S.A. (PRODUBANCO)",
    label: "BANCO DE LA PRODUCCION,S.A. (PRODUBANCO)",
  },
  { value: "BANCO PROMERICA, S.A.", label: "BANCO PROMERICA, S.A." },
  {
    value: "COOP 29 DE OCTUBRE/ PATROCINIO VAZCORP",
    label: "COOP 29 DE OCTUBRE/ PATROCINIO VAZCORP",
  },
  { value: "BANCO DE GUAYAQUIL, S.A.", label: "BANCO DE GUAYAQUIL, S.A." },
  {
    value: "Banco Comercial de Manabi S.A.",
    label: "Banco Comercial de Manabi S.A.",
  },
  { value: "BANCO BOLIVARIANO C.A.", label: "BANCO BOLIVARIANO C.A." },
  {
    value: "COOPERATIVA DE AHORRO Y CREDIT O OSCUS LTDA",
    label: "COOPERATIVA DE AHORRO Y CREDIT O OSCUS LTDA",
  },
  {
    value: "BANCO GENERAL RUMINAHUI S.A.",
    label: "BANCO GENERAL RUMINAHUI S.A.",
  },
  { value: "COOP ATUNTAQUI / MANEJA BI", label: "COOP ATUNTAQUI / MANEJA BI" },
  { value: "BANCO DE CREDITO, S.A.", label: "BANCO DE CREDITO, S.A." },
  { value: "BANCO INTERNACIONAL S.A.", label: "BANCO INTERNACIONAL S.A." },
  { value: "BCO MACHALA", label: "BCO MACHALA" },
  { value: "COOP ANDALUCÍA / MANEJA BI", label: "COOP ANDALUCÍA / MANEJA BI" },
  {
    value: "BANCO DE LA PRODUCCIONS.A. (PRODUBANCO)",
    label: "BANCO DE LA PRODUCCIONS.A. (PRODUBANCO)",
  },
  { value: "BANCO TERRITORIAL, S.A.", label: "BANCO TERRITORIAL, S.A." },
  {
    value: "BANCO DE CREDITO E HIPOTECARIO, S.A.",
    label: "BANCO DE CREDITO E HIPOTECARIO, S.A.",
  },
  { value: "BANCO BOLIVARIANO, C.A.", label: "BANCO BOLIVARIANO, C.A." },
  {
    value: "ASOCIACION PARA LA VIVIENDA MUTUALISTA PICHINCHA",
    label: "ASOCIACION PARA LA VIVIENDA MUTUALISTA PICHINCHA",
  },
  { value: "MONEY ACCESS SERVICE, INC.", label: "MONEY ACCESS SERVICE, INC." },
  { value: "MUTUALISTA PICHINCHA (MC)", label: "MUTUALISTA PICHINCHA (MC)" },
];
