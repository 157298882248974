import React from "react";
import { Box, Divider, Modal, Typography } from "@mui/material";
import { modalBoardMembersStyles as styles } from "./ModalBoardMembers.styles";
import IconButton from "@mui/material/IconButton";
import { IconCircleCross } from "@kushki/connect-ui";
import { Boardmembers } from "../../../../types/get_compliance_people_response";
import BoardMemberAccordion from "../BoardMembersAccordion/BoardMemberAccordion";

interface IModalBoardMembersProps {
  boardMembers: Boardmembers[];
  openModal: boolean;
  handleCloseModal: () => void;
}

const ModalBoardMembers: React.FC<IModalBoardMembersProps> = ({
  boardMembers,
  openModal,
  handleCloseModal,
}: IModalBoardMembersProps) => {
  return (
    <Modal open={openModal}>
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <Typography variant="h3" color="primary">
            Board members agregados
          </Typography>
          <IconButton sx={styles.closeModal} onClick={handleCloseModal}>
            <IconCircleCross color="primary" />
          </IconButton>
        </Box>
        <Divider flexItem />
        <Box sx={styles.accordionContainer}>
          {boardMembers.map((boardMember) => (
            <BoardMemberAccordion
              key={boardMember.documentNumber}
              boardMember={boardMember}
              showMenuActions={false}
            />
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalBoardMembers;
