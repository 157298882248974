import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  container: {
    marginTop: "8px",
    marginBottom: "24px",
  },
  backButton: {
    border: "1px solid #CBD5E0",
    borderRadius: "5px",
    cursor: "pointer",
    height: "40px",
    lineHeight: "30px",
    marginRight: "4px",
    padding: "3px 25px",
    textDecoration: "none",
    fontWeight: 500,
    fontSize: "14px",
    color: "#293036",
    textAlign: "center",
    "&:hover": {
      textDecoration: "none",
    },
  },
  typography: {
    fontWeight: 200,
    marginRight: "16px",
  },
}));
