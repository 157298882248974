import { basicDataFormSlice } from "../reducers/basicDataForm/basicDataForm.slice";

export const {
  setTextSave,
  setOpenModal,
  setButtonSaveFooter,
  setButtonContinueFooter,
  setLoaderState,
  setIsSaveButton,
  setHourComputer,
  setNotification,
  setTypeButton,
  setIsErrorSave,
} = basicDataFormSlice.actions;
