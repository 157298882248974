import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import { HeaderSection } from "../../shared/infrastructure/constants/CredentialManagerConstants";

export const CredentialHeaderCustomer = () => {
  return (
    <Box
      sx={{
        padding: "0px !important",
      }}
    >
      <Grid container pt={4} px={0}>
        <Grid container>
          <Grid item xs={6}>
            <Box pb={2} mt={1}>
              <Typography variant={"h5"} color="text.primary">
                {HeaderSection.TITLE_CREDENTIALS}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} mt={1}>
            <Box pb={2} mt={1}>
              <Typography variant={"body2"} color="text.primary">
                {HeaderSection.SUB_TITLE_CREDENTIALS_CUSTOMER}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
