import React, { FC } from "react";
import { Box, CircularProgress, Dialog, Typography } from "@mui/material";
import { useKshRedux } from "../../../store/hooks/hooks";
import { genericLoaderStyle } from "./GenericLoader.styles";

export const GenericLoader: FC = () => {
  const {
    store: { loading, loadingText },
  } = useKshRedux();

  return (
    <Dialog data-testid="dialog" maxWidth="md" open={loading}>
      <Box sx={genericLoaderStyle.container}>
        <Box>
          <CircularProgress
            data-testid="circularProgress"
            sx={genericLoaderStyle.circularColor}
            thickness={4}
            size={60}
          />
        </Box>
        <div>
          <Typography
            data-testid="text"
            variant="h6"
            sx={genericLoaderStyle.textContainer}
          >
            {loadingText}
          </Typography>
        </div>
      </Box>
    </Dialog>
  );
};
