import React, { PropsWithChildren } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { sucessValidationResultStyles } from "./SucessValidationResult.styles";
import { SuccessValidationResultProps } from "../../../shared/infrastructure/interfaces/SuccessValidationResult.interface";
import errorValidation from "../../../assets/images/errorValidation.png";
import successValidation from "../../../assets/images/successValid.png";
import { LabelsDispersion } from "../../../shared/infrastructure/constants/RequestDispersionConstants";
import { useFileValidationResultState } from "./state/useFileValidationResultState";
import { RequestDispersionOnProcess } from "../../RequestDispersionOnProcess/RequestDispersionOnProcess";
import { FileLoadingModal } from "../../FileLoadingModal/FileLoadingModal";

export const SuccessValidationResult = (
  props: PropsWithChildren<SuccessValidationResultProps>
) => {
  const classes = sucessValidationResultStyles();
  const {
    validateTitle,
    showError,
    cleanValidationResult,
    showFileResultComponent,
    handleProcessRequest,
    openLoadingModal,
    processFileDispersionsResponse,
    referenceNumber,
  } = useFileValidationResultState(props);

  return (
    <Grid item xs={12}>
      {showFileResultComponent ? (
        <Grid
          container
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={3}
        >
          <Grid item xs={12}>
            <Box display={"flex"} justifyContent={"center"}>
              {showError ? (
                <img
                  src={errorValidation}
                  alt={"error"}
                  className={classes.resultImage}
                />
              ) : (
                <img
                  src={successValidation}
                  alt={"error"}
                  className={classes.resultImage}
                />
              )}
            </Box>
          </Grid>

          <Grid>
            <Box>
              <Typography
                variant="h5"
                color="primary"
                align={"center"}
                className={classes.title}
              >
                {validateTitle(props.validationResults)}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={5}>
            <Typography className={classes.descriptionSuccess}>
              {LabelsDispersion.SUCCESS_DESCRIPTION}
            </Typography>
          </Grid>

          <Box>
            <Grid className={classes.referenceStyle}>
              <Typography color="primary">
                {LabelsDispersion.REFERENCE_NUMBER}
              </Typography>
              <Typography color="textSecondary">{referenceNumber}</Typography>
            </Grid>
          </Box>

          <Grid item xs={12} className={classes.buttonsContainer}>
            <Grid
              container
              justifyContent={"space-around"}
              alignItems={"center"}
            >
              {validateTitle(props.validationResults) !==
                LabelsDispersion.VALIDATION_SUCCESS_TITLE && (
                <Grid item xs={5}>
                  <Button
                    variant={"outlined"}
                    hidden={!showError}
                    fullWidth
                    onClick={cleanValidationResult}
                  >
                    {LabelsDispersion.RELOAD_OPTION}
                  </Button>
                </Grid>
              )}

              <Grid item xs={5}>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  onClick={handleProcessRequest}
                  fullWidth
                >
                  {LabelsDispersion.RETURN_TRANSACTIONS}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <FileLoadingModal openLoadingModal={openLoadingModal} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <RequestDispersionOnProcess
          processFileDispersionsResponse={processFileDispersionsResponse}
        />
      )}
    </Grid>
  );
};
