import authorizedIcon from "../../../../assets/images/THRZD.svg";
import { Icon } from "@material-ui/core";
import React from "react";
import { useStyles } from "../../DetailsCard.styles";

export const THRZDIcon = () => {
  const classes = useStyles();

  return (
    <Icon className={classes.tlIcon}>
      <img alt={"non authorized icon"} src={authorizedIcon} />
    </Icon>
  );
};
