import { IModalTemplateInfo } from "../interfaces/ModalContainer";
import { LabelEnum } from "./LabelEnum";
import { StatusEnum } from "./StatusEnum";

export const MODAL_TEMPLATE_BRANCH: Record<string, IModalTemplateInfo> = {
  [LabelEnum.BUSINESS_RULES]: {
    ruleSetting: {
      title: LabelEnum.RULES_SETTINGS_TITLE,
      columns: {
        processorName: LabelEnum.PROCESSOR_NAME,
        publicID: LabelEnum.PUBLIC_ID,
      },
      status: StatusEnum.ENABLE,
    },
  },
};

export const PATH_RULE_SETTING: Record<string, string> = {
  [LabelEnum.KEY_PROCESSOR_NAME]: "rule.rule.processorName",
  [LabelEnum.KEY_PUBLIC_ID]: "rule.rule.transactionProcessor",
};
