import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const walletStyles = createNamedStyles({
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: 1,
    rowGap: (theme) => theme.spacing(4),
  },
  boxButtons: {
    display: "flex",
    flexDirection: "row-reverse",
    columnGap: (theme) => theme.spacing(2),
    mb: 10,
  },
  boxFormPaper: {
    backgroundColor: "neutral.white",
    borderRadius: 2,
    minHeight: 350,
    px: {
      xs: 4.5,
      md: 6,
      lg: 8,
    },
    py: {
      xs: 3.5,
      md: 5,
      lg: 6,
    },
  },
});
