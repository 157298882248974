import React, { FC } from "react";
import { Box } from "@mui/material";
import Lottie from "react-lottie";
import { ILoadingSpinner } from "./LoadingSpinner.interfaces";
import { loaderModalOptions } from "./Lottie/constants/LottieOptions";
import { loadingSpinnerStyles } from "./LoadingSpinner.styles";

const LoadingSpinner: FC<ILoadingSpinner> = ({
  width,
  height,
}: ILoadingSpinner) => {
  return (
    <Box sx={loadingSpinnerStyles.container}>
      <Lottie options={loaderModalOptions} width={width} height={height} />
    </Box>
  );
};

export { LoadingSpinner };
