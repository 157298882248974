import { useForm, UseFormReturn } from "react-hook-form";
import { IFormValues } from "../../../shared/interfaces/FormController";
import { FieldNames } from "../../../shared/constants/Form";
import {
  IUseWalletBalanceForm,
  IUseWalletBalanceFormProps,
} from "./useWalletBalanceForm.interfaces";
import { useSnackbar } from "@kushki/connect-ui";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { RootState } from "../../../store/store";
import { useEffect, useState } from "react";
import { defaultTo, get } from "lodash";
import {
  setNotifyAddBalanceStatus,
  setTransactionType,
} from "../../../store/actions/balance.action";
import { AddBalanceRequest } from "../../../../types/remote/add_balance_request";
import { LOADER_DESCRIPTION } from "../../../shared/constants/labels/modal_labels";
import {
  getModalConfirmInfo,
  getValueBase64,
} from "../../../store/utils/store-utils";
import { auth } from "../../../shared/auth";
import { CurrencyAddBalanceRequestEnum } from "../../../shared/enums/currencies";
import { TransactionType } from "../../../shared/enums/transaction_types";
import {
  FILE_NAME_PATH,
  FILE_PATH,
  FILE_TYPE_PATH,
  PREVIOUS_PAGE,
  TIMEOUT_REDIRECT,
} from "../../../shared/constants/main_container_constants";
import { CATALOG_MODAL_LOADER_TITLE } from "../../../shared/catalogs/CatalogModalLoaderTitle";
import { addBalance } from "../../../store/thunks/balance/balance.thunk";

export const useWalletBalanceForm = ({
  currency,
  transactionType,
  publicMerchantId,
}: IUseWalletBalanceFormProps): IUseWalletBalanceForm => {
  const { showSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const form: UseFormReturn<IFormValues> = useForm<IFormValues>({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const { isLoadingAddBalance, notificationAddBalanceStatus } = useAppSelector(
    (state: RootState) => ({
      ...state.balanceReducer,
    })
  );
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const [titleLoadingModal, setTitleLoadingModal] = useState("");
  const [confirmModalData, setConfirmModalData] = useState({
    descriptionConfirmModal: "",
    openConfirmModal: false,
    titleConfirmModal: "",
  });

  const handleCloseModal = () => {
    setConfirmModalData({ ...confirmModalData, openConfirmModal: false });
  };

  const handleConfirmModal = async () => {
    const fileBase64: string = await getValueBase64(
      get(form.getValues(), FILE_PATH)
    );
    const addBalanceCreditRequest: AddBalanceRequest = {
      backofficeUser: auth._getUserName(),
      currency: defaultTo(currency, "") as CurrencyAddBalanceRequestEnum,
      description: get(form.getValues(), "description", ""),
      file: {
        fileName: get(form.getValues(), FILE_NAME_PATH, ""),
        fileType: get(form.getValues(), FILE_TYPE_PATH, ""),
        value: get(fileBase64.split(","), "[1]"),
      },
      merchantId: publicMerchantId,
      totalAmount: Number(form.getValues().totalAmount),
      transactionType: transactionType,
    };

    handleCloseModal();
    await dispatch(setTransactionType(transactionType));
    dispatch(addBalance(addBalanceCreditRequest));
  };

  const showConfirmModal = () => {
    const { title, description } = getModalConfirmInfo(
      transactionType as TransactionType
    );

    setConfirmModalData({
      descriptionConfirmModal: description(
        defaultTo(currency, ""),
        get(form.getValues(), FieldNames.TOTAL_AMOUNT, "")
      ),
      openConfirmModal: true,
      titleConfirmModal: title,
    });
  };

  const showLoaderModal = (isLoading: boolean) => {
    let title: string = CATALOG_MODAL_LOADER_TITLE[transactionType];

    setTitleLoadingModal(title);
    setOpenLoadingModal(isLoading);
  };

  const onSubmit = async () => {
    const trigger = await form.trigger();

    if (trigger) return showConfirmModal();
  };

  const closeAlert = () => {
    form.resetField(FieldNames.FILE);
  };

  const redirect = async () => {
    await dispatch(setNotifyAddBalanceStatus(undefined));
    setTimeout(() => {
      window.history.go(PREVIOUS_PAGE);
    }, TIMEOUT_REDIRECT);
  };

  useEffect(() => {
    if (notificationAddBalanceStatus) {
      showSnackbar(notificationAddBalanceStatus);
      redirect();
    }
  }, [notificationAddBalanceStatus]);

  useEffect(() => {
    showLoaderModal(isLoadingAddBalance);
  }, [isLoadingAddBalance]);

  return {
    closeAlert,
    form,
    modalConfirm: {
      description: confirmModalData.descriptionConfirmModal,
      handleCloseModal,
      handleConfirmModal,
      openConfirmModal: confirmModalData.openConfirmModal,
      title: confirmModalData.titleConfirmModal,
    },
    modalLoader: {
      description: LOADER_DESCRIPTION,
      openLoadingModal,
      title: titleLoadingModal,
    },
    onSubmit,
  };
};
