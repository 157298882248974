import React from "react";
import {
  createStyles,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { usePaginationState } from "./state/usePaginationState";
import { PaginationEnum } from "../../../shared/infrastructure/constants/HeaderTableEnum";
import { DeferredTableProps } from "../DeferredTableBrazil/DeferredTableBrazil";
import { DeferredOption } from "../../../../types/deferred_options";
import { SortDirectionEnum } from "../../../shared/infrastructure/constants/DeferredConstants";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: "12px",
    },
  })
);

export interface TablePaginationState {
  handlePaginationData: (data: DeferredOption[]) => void;
  sortDirection: SortDirectionEnum | undefined;
}
export type TablePaginationProps = TablePaginationState & DeferredTableProps;
const TablePagination: React.FC<TablePaginationProps> = (
  props: TablePaginationProps
) => {
  const classes = useStyles();
  const {
    tableSize,
    currentPage,
    totalRecords,
    pagination,
    handleChangePageSize,
    handleChangePage,
  } = usePaginationState(props);

  return (
    <React.Fragment>
      <Grid container spacing={1} className={classes.container}>
        <Grid item xs={12} md={7}>
          <Grid container spacing={2} direction={"row"} alignItems={"center"}>
            <Grid item>
              <Typography color={"textSecondary"} variant={"body2"}>
                {PaginationEnum.PAGE_TRANSACTION}
              </Typography>
            </Grid>
            <Grid item>
              <Select
                id={"select"}
                disableUnderline
                value={tableSize}
                onChange={(event) =>
                  handleChangePageSize(event.target.value as number)
                }
              >
                <MenuItem value={10}>
                  <Typography variant={"body2"} color={"textSecondary"}>
                    10
                  </Typography>
                </MenuItem>
                <MenuItem value={15}>
                  <Typography variant={"body2"} color={"textSecondary"}>
                    15
                  </Typography>
                </MenuItem>
                <MenuItem value={20}>
                  <Typography variant={"body2"} color={"textSecondary"}>
                    20
                  </Typography>
                </MenuItem>
              </Select>
            </Grid>
            <Grid item>
              <Typography color={"textSecondary"} variant={"body2"}>
                {`${(currentPage - 1) * tableSize + 1}-${
                  (currentPage - 1) * tableSize + tableSize
                } de ${totalRecords}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          container
          justify="flex-end"
          alignItems="flex-start"
          direction="row"
        >
          <Pagination
            id={"pagination"}
            count={pagination}
            color={"primary"}
            onChange={(_event, value) => handleChangePage(value as number)}
            page={currentPage}
            size={"small"}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default TablePagination;
