import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const actionButtonStyles = createNamedStyles({
  returnButtonText: {
    color: "#293036",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontWeight: 500,
    textTransform: "none",
    padding: 0.5,
  },
  saveButtonText: {
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontWeight: 500,
    textTransform: "none",
    padding: 0.5,
  },
});
