import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { groupBy } from "lodash";
import { useAppSelector } from "../../../store/hooks/storeHook";
import { ITableRowProps, TableProps } from "../TableSimple/interfaces";
import { ActionTypes } from "./actionTypes";
import { ITableContext } from "./interfaces";
import { TABLE_INITIAL_STATE, tableReducer } from "./reducer";
import { CheckState, IValueCell } from "../../TableCell/CellHeader/interfaces";
import { noop } from "../../../shared/constants/noop";

const TableContext = createContext<ITableContext>(undefined!);

export type ITableProviderProps = PropsWithChildren<
  Pick<
    TableProps,
    | "isLoading"
    | "isInitialLoading"
    | "rows"
    | "allowDeletion"
    | "allowSelection"
    | "onSelectedRows"
    | "getRowValues"
    | "typesCellsToBlock"
    | "valuesCellsToBlock"
  >
>;

export const TableProvider: FC<ITableProviderProps> = ({
  children,
  isInitialLoading = false,
  isLoading = false,
  rows,
  allowSelection = false,
  allowDeletion = false,
  valuesCellsToBlock,
  onSelectedRows,
  getRowValues,
}: ITableProviderProps) => {
  const [state, dispatch] = useReducer(tableReducer, {
    ...TABLE_INITIAL_STATE,
    isInitialLoading,
    isLoading,
  });

  const { selectedRowsStore } = useAppSelector((state) => ({
    ...state.tableData,
  }));

  const handleSetCellValues = (payload: IValueCell[]) => {
    getRowValues ? getRowValues(groupBy(payload, "rowIndex")) : noop();
    dispatch({
      type: ActionTypes.SET_CELL_VALUES,
      valueCells: payload,
    });
  };

  const handleSetSelectedRows = (payload: ITableRowProps[]): void => {
    dispatch({
      selectedRows: payload,
      type: ActionTypes.SET_SELECTED_ROWS,
    });
  };

  const handleAppendSelectedRow = (payload: ITableRowProps): void => {
    if (state.selectedRows.some(({ id }: ITableRowProps) => id === payload.id))
      return handleSetSelectedRows(
        state.selectedRows.filter(({ id }: ITableRowProps) => id !== payload.id)
      );

    return handleSetSelectedRows([...state.selectedRows, payload]);
  };

  const changeAllCheckValues = (value: boolean) => {
    return state.valueCells.map((valueRow: IValueCell) => {
      if (valueRow.id.endsWith("table-check"))
        valueRow = { ...valueRow, value };

      return valueRow;
    });
  };

  const selectAllState: CheckState = useMemo<CheckState>(() => {
    if (state.selectedRows.length > 0) {
      if (state.selectedRows.length === rows.length) return "checked";

      return "indeterminate";
    }
    handleSetCellValues(changeAllCheckValues(false));

    return "unChecked";
  }, [state.selectedRows, rows.length]);

  const handleCheckMassive = () => {
    switch (selectAllState) {
      case "checked":
        handleSetCellValues(changeAllCheckValues(false));

        return handleSetSelectedRows([]);
      default:
        handleSetCellValues(changeAllCheckValues(true));

        return handleSetSelectedRows(rows);
    }
  };

  useEffect(() => {
    handleSetSelectedRows([]);
  }, [allowSelection]);

  useEffect(() => {
    onSelectedRows &&
      onSelectedRows(state.selectedRows.map(({ id }: ITableRowProps) => id));
  }, [state.selectedRows]);

  useEffect(() => {
    if (selectedRowsStore) {
      handleSetSelectedRows(selectedRowsStore);
    }
  }, [selectedRowsStore]);

  const value: ITableContext = useMemo<ITableContext>(
    () => ({
      handler: {
        handleAppendSelectedRow,
        handleCheckMassive,
        handleSetCellValues,
        handleSetSelectedRows,
      },
      state: {
        ...state,
        allowDeletion,
        allowSelection,
        isInitialLoading: isInitialLoading,
        isLoading: isLoading || (rows.length === 0 && isInitialLoading),
        rows,
        selectAllState,
        valuesCellsToBlock,
      },
    }),
    [
      isLoading,
      isInitialLoading,
      rows,
      state,
      dispatch,
      allowSelection,
      allowDeletion,
    ]
  );

  useEffect(() => {
    if (rows.length === 0) {
      dispatch({
        type: ActionTypes.SET_CELL_VALUES,
        valueCells: [],
      });
    }
  }, [rows.length]);

  useEffect(() => {
    handleSetSelectedRows([]);
  }, []);

  return (
    <TableContext.Provider value={value}>{children}</TableContext.Provider>
  );
};

export const useTableContext = () => useContext(TableContext);
