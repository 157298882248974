import React from "react";
import { Button, Grid, MenuItem, MenuList, Popover } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { RolesHistoricEnum } from "../../shared/infrastructure/enum/Roles/RolesEnum";

export interface ButtonReprocessProps {
  isLoading: boolean;
  reprocess: { reprocessNumber: number; reprocessTransactions: () => void };
  options?: { text: string; value: string }[];
}

export const ButtonReprocess: React.FC<ButtonReprocessProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <ComponentSecurityWrapper
        componentId={RolesHistoricEnum.M_BILLING_HISTORIC_REPROCESSED_CHARGES}
      >
        <Button color="primary" onClick={handleClick} id="button_reprocess">
          <MoreHorizIcon color="primary" />
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuList id="split-button-menu">
            <MenuItem
              disabled={!(props.reprocess.reprocessNumber > 0)}
              key={"reprocess-button"}
              onClick={() => {
                setAnchorEl(null);
                props.reprocess.reprocessTransactions();
              }}
            >
              <Grid container direction={"row"} alignItems={"center"}>
                <Grid item xs={12}>
                  {"Reprocesar cobros"}
                </Grid>
                <Grid item xs={12}>
                  {"pendientes"}
                </Grid>
              </Grid>
            </MenuItem>
          </MenuList>
        </Popover>
      </ComponentSecurityWrapper>
    </React.Fragment>
  );
};
