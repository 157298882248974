import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { Color } from "@material-ui/lab/Alert";
import { useSnackBarAlertState } from "./useSnackBarAlertState";
import { makeStyles } from "@material-ui/core";
import { get } from "lodash";

export interface SnackbarAlertProps {
  msg: string;
  open: boolean;
  type: Color;
  customColor?: string;
  icon?: boolean;
  handlerClose: (_event?: React.SyntheticEvent, reason?: string) => void;
}

const useStyles = makeStyles({
  customSnackbar: {
    backgroundColor: (props: SnackbarAlertProps) => props.customColor!,
  },
});

export const SnackBarAlert: React.FC<SnackbarAlertProps> = (
  props: SnackbarAlertProps
) => {
  const { open } = useSnackBarAlertState(props);

  const classes = useStyles(props);

  const getAlert = (props: SnackbarAlertProps) => {
    return get(props, "icon", true) ? (
      <MuiAlert
        className={classes.customSnackbar}
        elevation={6}
        variant="filled"
        onClose={props.handlerClose}
        severity={props.type}
      >
        {props.msg}
      </MuiAlert>
    ) : (
      <MuiAlert
        className={classes.customSnackbar}
        elevation={6}
        variant="filled"
        onClose={props.handlerClose}
        severity={props.type}
        icon={false}
      >
        {props.msg}
      </MuiAlert>
    );
  };

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        open={open}
        autoHideDuration={4000}
        onClose={props.handlerClose}
      >
        {getAlert(props)}
      </Snackbar>
    </React.Fragment>
  );
};
