import { ISnackBar } from "@kushki/connect-ui/dist/Components/Atoms/DataDisplay/SnackBar/SnackBar.interface";
import { defaultTo } from "lodash";
import { NotificationTypeEnum, SnackbarEnum } from "../enums/SnackbarEnum";

const successData: Partial<ISnackBar> = {
  color: "success",
};

const failedData: Partial<ISnackBar> = {
  color: "danger",
  message: SnackbarEnum.REQUEST_ERROR,
};

export const defaultErrorSnackbar: ISnackBar = {
  color: "danger",
  message: SnackbarEnum.REQUEST_ERROR,
  variant: "simple",
  withIcon: false,
};

export const buildNotification = (
  notificationType: NotificationTypeEnum,
  data: ISnackBar,
  message?: string
): ISnackBar => {
  switch (notificationType) {
    case NotificationTypeEnum.SUCCESS:
      return {
        ...data,
        ...successData,
      };
    case NotificationTypeEnum.FAILED:
      return {
        ...data,
        ...failedData,
        message: defaultTo(message, ""),
      };
  }
};
