import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { Catalog } from "../../../../types/catalog_response";
import { AxiosResponse } from "axios";
import { defaultTo, get } from "lodash";
import { CatalogRequest } from "../../../../types/catalog_request";

export const getListCatalogs = createAsyncThunk<Catalog[], CatalogRequest>(
  "catalogs/listCatalogs",
  async (payload: CatalogRequest): Promise<Catalog[]> => {
    const response: AxiosResponse<Catalog[]> | void = await axios.post<
      Catalog[]
    >(API_ROUTES.CATALOGS, payload);

    if (get(response, "status") !== 200) return [];

    return defaultTo(get(response, "data"), []);
  }
);
