import { IBank } from "../../MexicoSection/state/useMexicoSection";
import { useState } from "react";

export interface IUseMexicanSectionState {
  handleFilterBanks: (banks: IBank[]) => IBank[];
  searchText: string;
  handleSearch: (e: any) => void;
}

export const useMexicanVariantSection = (
  index: number
): IUseMexicanSectionState => {
  const [searchText, setSearchText] = useState("");

  const handleFilterBanks = (banks: IBank[]): IBank[] =>
    banks.filter((bank) => bank.index === undefined || bank.index === index);

  const handleSearch = (e: any) => setSearchText(e.target.value);

  return {
    handleFilterBanks,
    handleSearch,
    searchText,
  };
};
