import React, { FC } from "react";
import { Box, FormControlLabel, Radio, Stack, Typography } from "@mui/material";
import { IconCircleCheck, IconCircleOutlined } from "@kushki/connect-ui";
import { choiceButtonStyles } from "./ChoiceButton.styles";
import { IChoiceButtonProps } from "./ChoiceButton.interfaces";

const ChoiceButton: FC<IChoiceButtonProps> = ({
  icon,
  title,
  bodyText,
  value,
  checked,
  onClick,
  isSmall = false,
  isDisabled = false,
}: IChoiceButtonProps) => {
  return (
    <Box
      sx={
        isSmall ? choiceButtonStyles.mainBoxSmall : choiceButtonStyles.mainBox
      }
    >
      <FormControlLabel
        disabled={isDisabled}
        data-testid={`radio-${value}`}
        style={
          isSmall
            ? choiceButtonStyles.formControlLabelSmall
            : choiceButtonStyles.formControlLabel
        }
        className={"choiceButton"}
        value={value}
        checked={checked}
        control={
          <Radio
            className="choiceRadio"
            icon={<IconCircleOutlined color="action" />}
            checkedIcon={<IconCircleCheck />}
            onClick={onClick}
          />
        }
        labelPlacement="bottom"
        label={
          <Stack style={choiceButtonStyles.stack}>
            {icon}
            <Typography variant="subtitle2" color="primary">
              {title}
            </Typography>
            {bodyText && (
              <Typography variant="body2" color="primary">
                {bodyText}
              </Typography>
            )}
          </Stack>
        }
      />
    </Box>
  );
};

export default ChoiceButton;
