import { CurrencyEnum } from "../enums/CurrencyEnum";

export const CurrencyCountry = {
  Chile: [
    { key: CurrencyEnum.CLP, label: "Pesos chilenos", selected: false },
    { key: CurrencyEnum.UF, label: "Unidad de fomento", selected: false },
  ],
  Colombia: [
    { key: CurrencyEnum.COP, label: "Pesos colombianos", selected: false },
  ],
  Ecuador: [{ key: CurrencyEnum.USD, label: "USD", selected: false }],
  Mexico: [{ key: CurrencyEnum.MXN, label: "MXN", selected: false }],
  Peru: [
    { key: CurrencyEnum.PEN, label: "Soles", selected: false },
    { key: CurrencyEnum.USD, label: "Dólares", selected: false },
  ],
};
