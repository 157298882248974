import React, { useEffect } from "react";
import {
  IChipSelect,
  IOptionFilter,
  IRangeAmount,
} from "../../FilterComponent.interfaces";
import { DefaultTransactionalConciliationFilter } from "../../../../shared/infrastructure/filter/DefaultConciliationFilter";
import {
  FilterBoxProps,
  IFilterBoxState,
  IRangeAmoutFilter,
} from "../FilterBox.interfaces";
import { cloneDeep } from "lodash";

const expandText: string = "Maximizar todo";
const minText: string = "Minimizar todo";
const rangeAmountFilter: IRangeAmoutFilter = {
  id: "rangeAmount",
  range: {},
  title: "Montos",
};

const populateOptionChips = (
  options: IOptionFilter[]
): { [key: string]: IChipSelect } => {
  let result: { [key: string]: IChipSelect } = {};
  let chips: IChipSelect[] = [];

  options.forEach((option: IOptionFilter) => {
    chips = chips.concat(
      option.options.map(
        (op: { key: string; label: string; selected: boolean }) => ({
          borderColor: op.selected ? "1px solid #0DC298" : "none",
          filter: option.id,
          key: op.key,
          label: op.label,
          selected: op.selected,
        })
      )
    );
  });

  chips.forEach((chip: IChipSelect) => (result[chip.key] = chip));

  return result;
};

const getAllAccordionId = (
  filters: IOptionFilter[],
  rangeAmountId: string
): { [key: string]: boolean } => {
  let result: { [key: string]: boolean } = {};

  filters.forEach((filter: IOptionFilter) => {
    result[filter.id] = false;
  });
  result[rangeAmountId] = false;

  return result;
};

const getInitialRangeAmount = (type: "min" | "max" | "eq" | "range") => {
  return {
    eq: "",
    max: "",
    min: "",
    type,
  };
};

export const useFilterBoxState = (props: FilterBoxProps): IFilterBoxState => {
  const [allExpanded, setAllExpanded] = React.useState<boolean>(false);
  const [textAllExpanded, setTextAllExpanded] =
    React.useState<string>(expandText);
  const [optionsChip, setOptionsChip] = React.useState<{
    [key: string]: IChipSelect;
  }>(populateOptionChips(props.filters));
  const [expandId, setExpandId] = React.useState<{ [key: string]: boolean }>(
    getAllAccordionId(props.filters, rangeAmountFilter.id)
  );
  const [rangeAmount, setAmountRange] = React.useState<IRangeAmount>(
    props.rangeAmount
  );
  const [rangeAmountError, setRangeAmountError] =
    React.useState<boolean>(false);
  const [amountError, setAmountError] = React.useState<boolean>(false);
  const [isAmountFilterSelected, setIsAmountFilterSelected] =
    React.useState<boolean>(false);
  const [isFiltersSelected, setIsFiltersSelected] =
    React.useState<boolean>(false);

  const setExpandAllId = (expand: boolean) => {
    Object.keys(expandId).forEach((key: string) => {
      expandId[key] = expand;
    });
  };

  const expandAll = () => {
    setExpandAllId(!allExpanded);
    setAllExpanded(!allExpanded);
    setTextAllExpanded(allExpanded ? expandText : minText);
  };

  const handleChangeSelectRange = (value: unknown) => {
    const initial_amount_range: IRangeAmount = getInitialRangeAmount(
      value as "min" | "max" | "eq" | "range"
    );

    setRangeAmountError(false);
    setAmountError(false);
    setAmountRange({ ...initial_amount_range });
  };

  const handleChangeAmount = (value: string, type: string) => {
    let range_amount: IRangeAmount = cloneDeep(rangeAmount);

    if (value.length > 0 && +value >= 0) {
      props.setFilterButtonsDisabled(false);
    } else if (!isFiltersSelected) {
      props.setFilterButtonsDisabled(true);
    }
    setIsAmountFilterSelected(value.length > 0);

    range_amount[type] = value;
    setRangeAmountError(
      range_amount.type === "range" && +range_amount.min >= +range_amount.max
    );
    setAmountError(+value < 0);
    setAmountRange({ ...range_amount });
    props.onRangeAmount(range_amount);
  };

  const handleButtonsDisabled = (isAmountFilter: boolean) => {
    if (isAmountFilter) props.setFilterButtonsDisabled(true);
    for (const opt in optionsChip) {
      if (optionsChip[opt].selected) {
        setIsFiltersSelected(true);

        return false;
      }
    }

    setIsFiltersSelected(false);

    return !isAmountFilterSelected;
  };

  const handleClickChip = (option: string) => {
    optionsChip[option].borderColor = !optionsChip[option].selected
      ? "1px solid #0DC298"
      : "none";
    optionsChip[option].selected = !optionsChip[option].selected;
    props.setFilterButtonsDisabled(handleButtonsDisabled(false));
    setOptionsChip({ ...optionsChip });
  };

  const handleExpansionPanelChange = (panel: string) => {
    expandId[panel] = !expandId[panel];
    setAllExpanded(false);
    setExpandId({ ...expandId });
    setTextAllExpanded(expandText);
  };

  const handleRestoreFilters = () => {
    setAmountRange(getInitialRangeAmount("min"));
    props.onRangeAmount(getInitialRangeAmount("min"));
    props.onRestoreFilters();
    setOptionsChip(populateOptionChips(DefaultTransactionalConciliationFilter));
    props.setFilterButtonsDisabled(true);
  };

  useEffect(() => {
    if (amountError || rangeAmountError) handleButtonsDisabled(true);
  }, [amountError, rangeAmountError]);

  return {
    errors: {
      amountError,
      rangeAmountError,
    },
    expandId,
    handlers: {
      handleChangeAmount,
      handleChangeSelectRange,
      handleClickChip,
      handleExpandAll: expandAll,
      handleExpansionPanelChange,
      handleRestoreFilters,
    },
    optionsChip,
    rangeAmount,
    rangeAmountFilter,
    textAllExpanded,
  };
};
