import { Box, Grid } from "@mui/material";
import React from "react";
import { useSecurityRulesTabsState } from "./state/useSecurityRulesTabsState";
import { LabelEnum } from "../../shared/infrastructure/enums/LabelEnum";
import { Tabs, Tab } from "@kushki/connect-ui";
const SecurityRulesTabs: React.FC = () => {
  const { indexTab, handleTabsChange, componentByTab } =
    useSecurityRulesTabsState();
  const Component = componentByTab[indexTab];

  return (
    <Grid item container>
      <Grid item md={12}>
        <Tabs
          orientation={"horizontal"}
          variant={"fullWidth"}
          onChange={(indexTab: number) => handleTabsChange(indexTab)}
          valueTab={indexTab}
        >
          <Tab text={LabelEnum.PENDING_REVIEW} variant="primary" />
          <Tab text={LabelEnum.FIRST_LEVEL_RULES} variant="primary" />
          <Tab text={LabelEnum.WHITE_LISTS} variant="primary" />
          <Tab text={LabelEnum.REJECTED_APPLICATIONS} variant="primary" />
        </Tabs>
        <Box p={3}>
          <Component />
        </Box>
      </Grid>
    </Grid>
  );
};

export default SecurityRulesTabs;
