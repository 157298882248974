import React from "react";
import { BankConciliationStatusEnum } from "../BankConciliationStatusEnum";
import { Box } from "@material-ui/core";
import { CheckCircle, Clock, XCircle } from "react-feather";
import {
  ITimelineProps,
  TimeLineConciliation,
  TimelineData,
} from "@kushki/frontend-molecules/time-line-conciliation";
import {
  TransactionData,
  TransactionDynamo,
} from "../../../../types/transaction_data";
import { get, map } from "lodash";
import {
  TransactionSummaryData,
  TransactionSummaryDynamo,
} from "../../../../types/transaction_summary_data";

export const SuccessTimelineIcon = () => (
  <Box
    width={32}
    height={32}
    bgcolor={"rgba(183, 255, 238, 0.2)"}
    borderRadius={4}
    display={"flex"}
    justifyContent={"center"}
    alignItems={"center"}
  >
    <CheckCircle size={15} color={"#0DC298"} />
  </Box>
);

export const OmitTimelineIcon = () => (
  <Box
    width={32}
    height={32}
    bgcolor={"#FEF9EC"}
    borderRadius={4}
    display={"flex"}
    justifyContent={"center"}
    alignItems={"center"}
  >
    <Clock size={15} color={"#CF711A"} />
  </Box>
);

export const CancelTimelineIcon = () => (
  <Box
    width={32}
    height={32}
    bgcolor={"rgba(255, 234, 238, 0.5)"}
    borderRadius={4}
    display={"flex"}
    justifyContent={"center"}
    alignItems={"center"}
  >
    <XCircle size={15} color={"#AD0C2A"} />
  </Box>
);

const getConciliationStatusIcon = (status?: string) => {
  switch (status) {
    case BankConciliationStatusEnum.CONCILIATION:
    case BankConciliationStatusEnum.PENDING:
      return SuccessTimelineIcon;
    case BankConciliationStatusEnum.NO_CONCILIATION:
      return CancelTimelineIcon;
    case BankConciliationStatusEnum.OMIT:
      return OmitTimelineIcon;
    default:
      return SuccessTimelineIcon;
  }
};

const getConciliationStatusTitle = {
  [BankConciliationStatusEnum.CONCILIATION]: "Registro Conciliado",
  [BankConciliationStatusEnum.PENDING]: "Registro Creado",
  [BankConciliationStatusEnum.NO_CONCILIATION]: "Registro No Conciliado",
  [BankConciliationStatusEnum.OMIT]: "Registro Omitido",
};

const showLink = (status?: string) =>
  status === BankConciliationStatusEnum.CONCILIATION ||
  status === BankConciliationStatusEnum.OMIT;

const getTimelineConciliationProps = (
  trx: TransactionData | TransactionSummaryData,
  handleOpenTimelineDetailModal: (
    data: TransactionData | TransactionSummaryData
  ) => void
): ITimelineProps => {
  const lastTimelineItem: TimelineData = {
    data: trx,
    date: get(trx, "bank_conciliation_date", "") as string,
    link: showLink(get(trx, "bank_conciliation_status")),
    stepIcon: getConciliationStatusIcon(get(trx, "bank_conciliation_status")),
    subtitle1: get(trx, "user_name"),
    subtitle2: get(trx, "user_role"),
    title: getConciliationStatusTitle[get(trx, "bank_conciliation_status", "")],
  };

  const previousTimeLineItems = map(
    get(trx, "historical", []) as
      | TransactionDynamo[]
      | TransactionSummaryDynamo[],
    (trxItem: TransactionDynamo | TransactionSummaryDynamo) => ({
      data: trxItem,
      date: get(trxItem, "bank_conciliation_date", "") as string,
      link: showLink(get(trxItem, "bank_conciliation_status")),
      stepIcon: getConciliationStatusIcon(
        get(trxItem, "bank_conciliation_status")
      ),
      subtitle1: get(trxItem, "user_name"),
      subtitle2: get(trxItem, "user_role"),
      title:
        getConciliationStatusTitle[
          get(trxItem, "bank_conciliation_status", "")
        ],
    })
  );

  const timelineData: TimelineData[] = [
    ...previousTimeLineItems,
    lastTimelineItem,
  ];

  return {
    handleClick: handleOpenTimelineDetailModal,
    timelineData,
  };
};

export const getTimelineConciliationComp = (
  trx: TransactionData | TransactionSummaryData,
  handleOpenTimelineDetailModal: (
    data: TransactionData | TransactionSummaryData
  ) => void
): JSX.Element => (
  <Box pl={6} pr={11}>
    <TimeLineConciliation
      {...getTimelineConciliationProps(trx, handleOpenTimelineDetailModal)}
    />
  </Box>
);
