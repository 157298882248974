import {
  FlagBrasil,
  FlagChile,
  FlagColombia,
  FlagCostaRica,
  FlagEcuador,
  FlagElSalvador,
  FlagGuatemala,
  FlagHonduras,
  FlagMexico,
  FlagNicaragua,
  FlagPanama,
  FlagPeru,
} from "@kushki/connect-ui";
import FlagDefault from "../../components/Flags/FlagDefault/FlagDefault";
import { Country } from "../../components/SelectCountry/SelectCountry.interfaces";

export const COUNTRIES_KUSHKI: Country[] = [
  {
    FlagIcon: FlagBrasil,
    label: "Brazil",
    value: "Brazil",
  },
  {
    FlagIcon: FlagChile,
    label: "Chile",
    value: "Chile",
  },
  {
    FlagIcon: FlagColombia,
    label: "Colombia",
    value: "Colombia",
  },
  {
    FlagIcon: FlagCostaRica,
    label: "Costa Rica",
    value: "CostaRica",
  },
  {
    FlagIcon: FlagEcuador,
    label: "Ecuador",
    value: "Ecuador",
  },
  {
    FlagIcon: FlagElSalvador,
    label: "El Salvador",
    value: "ElSalvador",
  },
  {
    FlagIcon: FlagGuatemala,
    label: "Guatemala",
    value: "Guatemala",
  },
  {
    FlagIcon: FlagHonduras,
    label: "Honduras",
    value: "Honduras",
  },
  {
    FlagIcon: FlagMexico,
    label: "México",
    value: "Mexico",
  },
  {
    FlagIcon: FlagNicaragua,
    label: "Nicaragua",
    value: "Nicaragua",
  },
  {
    FlagIcon: FlagPanama,
    label: "Panamá",
    value: "Panama",
  },
  {
    FlagIcon: FlagPeru,
    label: "Perú",
    value: "Peru",
  },
];

export const USA: Country = {
  FlagIcon: FlagDefault,
  label: "Estados Unidos",
  value: "EEUU",
};

export const COUNTRIES_LATAM: Country[] = [
  ...COUNTRIES_KUSHKI,
  {
    FlagIcon: FlagDefault,
    label: "Argentina",
    value: "Argentina",
  },
  {
    FlagIcon: FlagDefault,
    label: "Bolivia",
    value: "Bolivia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Cuba",
    value: "Cuba",
  },
  {
    FlagIcon: FlagDefault,
    label: "Paraguay",
    value: "Paraguay",
  },
  {
    FlagIcon: FlagDefault,
    label: "Puerto Rico",
    value: "Puerto Rico",
  },
  {
    FlagIcon: FlagDefault,
    label: "República Dominicana",
    value: "Republica Dominicana",
  },
  {
    FlagIcon: FlagDefault,
    label: "Uruguay",
    value: "Uruguay",
  },
  {
    FlagIcon: FlagDefault,
    label: "Venezuela",
    value: "Venezuela",
  },
  {
    FlagIcon: FlagDefault,
    label: "Guayana Francesa",
    value: "Guayana Francesa",
  },
];

export const COUNTRIES: Country[] = [
  {
    FlagIcon: FlagDefault,
    label: "Afganistán",
    value: "Afganistan",
  },
  {
    FlagIcon: FlagDefault,
    label: "Albania",
    value: "Albania",
  },
  {
    FlagIcon: FlagDefault,
    label: "Alemania",
    value: "Alemania",
  },
  {
    FlagIcon: FlagDefault,
    label: "Andorra",
    value: "Andorra",
  },
  {
    FlagIcon: FlagDefault,
    label: "Angola",
    value: "Angola",
  },
  {
    FlagIcon: FlagDefault,
    label: "Anguila",
    value: "Anguila",
  },
  {
    FlagIcon: FlagDefault,
    label: "Antártida",
    value: "Antartida",
  },
  {
    FlagIcon: FlagDefault,
    label: "Antigua y Barbuda",
    value: "Antigua y Barbuda",
  },
  {
    FlagIcon: FlagDefault,
    label: "Arabia Saudita",
    value: "Arabia Saudita",
  },
  {
    FlagIcon: FlagDefault,
    label: "Argelia",
    value: "Argelia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Armenia",
    value: "Armenia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Aruba",
    value: "Aruba",
  },
  {
    FlagIcon: FlagDefault,
    label: "Australia",
    value: "Australia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Austria",
    value: "Austria",
  },
  {
    FlagIcon: FlagDefault,
    label: "Azerbaiyán",
    value: "Azerbaiyan",
  },
  {
    FlagIcon: FlagDefault,
    label: "Bahamas",
    value: "Bahamas",
  },
  {
    FlagIcon: FlagDefault,
    label: "Bahrein",
    value: "Bahrein",
  },
  {
    FlagIcon: FlagDefault,
    label: "Bangladesh",
    value: "Bangladesh",
  },
  {
    FlagIcon: FlagDefault,
    label: "Barbados",
    value: "Barbados",
  },
  {
    FlagIcon: FlagDefault,
    label: "Bélgica",
    value: "Belgica",
  },
  {
    FlagIcon: FlagDefault,
    label: "Belice",
    value: "Belice",
  },
  {
    FlagIcon: FlagDefault,
    label: "Benín",
    value: "Benin",
  },
  {
    FlagIcon: FlagDefault,
    label: "Bhután",
    value: "Bhutan",
  },
  {
    FlagIcon: FlagDefault,
    label: "Bielorrusia",
    value: "Bielorrusia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Birmania",
    value: "Birmania",
  },
  {
    FlagIcon: FlagDefault,
    label: "Bosnia y Herzegovina",
    value: "Bosnia y Herzegovina",
  },
  {
    FlagIcon: FlagDefault,
    label: "Botsuana",
    value: "Botsuana",
  },
  {
    FlagIcon: FlagDefault,
    label: "Brunéi",
    value: "Brunei",
  },
  {
    FlagIcon: FlagDefault,
    label: "Bulgaria",
    value: "Bulgaria",
  },
  {
    FlagIcon: FlagDefault,
    label: "Burkina Faso",
    value: "Burkina Faso",
  },
  {
    FlagIcon: FlagDefault,
    label: "Burundi",
    value: "Burundi",
  },
  {
    FlagIcon: FlagDefault,
    label: "Cabo Verde",
    value: "Cabo Verde",
  },
  {
    FlagIcon: FlagDefault,
    label: "Camboya",
    value: "Camboya",
  },
  {
    FlagIcon: FlagDefault,
    label: "Camerún",
    value: "Camerun",
  },
  {
    FlagIcon: FlagDefault,
    label: "Canadá",
    value: "Canada",
  },
  {
    FlagIcon: FlagDefault,
    label: "Chad",
    value: "chad",
  },
  {
    FlagIcon: FlagDefault,
    label: "China",
    value: "China",
  },
  {
    FlagIcon: FlagDefault,
    label: "Chipre",
    value: "Chipre",
  },
  {
    FlagIcon: FlagDefault,
    label: "Ciudad del Vaticano",
    value: "Ciudad del Vaticano",
  },
  {
    FlagIcon: FlagDefault,
    label: "Comoras",
    value: "Comoras",
  },
  {
    FlagIcon: FlagDefault,
    label: "Corea del Norte",
    value: "Corea del Norte",
  },
  {
    FlagIcon: FlagDefault,
    label: "Corea del Sur",
    value: "Corea del Sur",
  },
  {
    FlagIcon: FlagDefault,
    label: "Costa de Marfil",
    value: "Costa de Marfil",
  },
  {
    FlagIcon: FlagDefault,
    label: "Croacia",
    value: "Croacia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Curazao",
    value: "Curazao",
  },
  {
    FlagIcon: FlagDefault,
    label: "Dinamarca",
    value: "Dinamarca",
  },
  {
    FlagIcon: FlagDefault,
    label: "Dominica",
    value: "Dominica",
  },
  {
    FlagIcon: FlagDefault,
    label: "Egipto",
    value: "Egipto",
  },
  {
    FlagIcon: FlagDefault,
    label: "Emiratos Árabes Unidos",
    value: "Emiratos Arabes Unidos",
  },
  {
    FlagIcon: FlagDefault,
    label: "Eritrea",
    value: "Eritrea",
  },
  {
    FlagIcon: FlagDefault,
    label: "Eslovaquia",
    value: "Eslovaquia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Eslovenia",
    value: "Eslovenia",
  },
  {
    FlagIcon: FlagDefault,
    label: "España",
    value: "España",
  },
  {
    FlagIcon: FlagDefault,
    label: "Estonia",
    value: "Estonia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Etiopía",
    value: "Etiopia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Filipinas",
    value: "Filipinas",
  },
  {
    FlagIcon: FlagDefault,
    label: "Finlandia",
    value: "Finlandia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Fiyi",
    value: "Fiyi",
  },
  {
    FlagIcon: FlagDefault,
    label: "Francia",
    value: "Francia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Gabón",
    value: "Gabon",
  },
  {
    FlagIcon: FlagDefault,
    label: "Gambia",
    value: "Gambia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Georgia",
    value: "Georgia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Ghana",
    value: "Ghana",
  },
  {
    FlagIcon: FlagDefault,
    label: "Gibraltar",
    value: "Gibraltar",
  },
  {
    FlagIcon: FlagDefault,
    label: "Granada",
    value: "Granada",
  },
  {
    FlagIcon: FlagDefault,
    label: "Grecia",
    value: "Grecia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Groenlandia",
    value: "Groenlandia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Guadalupe",
    value: "Guadalupe",
  },
  {
    FlagIcon: FlagDefault,
    label: "Guam",
    value: "Guam",
  },
  {
    FlagIcon: FlagDefault,
    label: "Guinea Ecuatorial",
    value: "Guinea Ecuatorial",
  },
  {
    FlagIcon: FlagDefault,
    label: "Guernsey",
    value: "Guernsey",
  },
  {
    FlagIcon: FlagDefault,
    label: "Guinea",
    value: "Guinea",
  },
  {
    FlagIcon: FlagDefault,
    label: "Guinea-Bissau",
    value: "Guinea-Bissau",
  },
  {
    FlagIcon: FlagDefault,
    label: "Guyana",
    value: "Guyana",
  },
  {
    FlagIcon: FlagDefault,
    label: "Haití",
    value: "Haiti",
  },
  {
    FlagIcon: FlagDefault,
    label: "Hong kong",
    value: "Hong kong",
  },
  {
    FlagIcon: FlagDefault,
    label: "Hungría",
    value: "Hungria",
  },
  {
    FlagIcon: FlagDefault,
    label: "India",
    value: "India",
  },
  {
    FlagIcon: FlagDefault,
    label: "Indonesia",
    value: "Indonesia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Irak",
    value: "Irak",
  },
  {
    FlagIcon: FlagDefault,
    label: "Irán",
    value: "Iran",
  },
  {
    FlagIcon: FlagDefault,
    label: "Irlanda",
    value: "Irlanda",
  },
  {
    FlagIcon: FlagDefault,
    label: "Isla Bouvet",
    value: "Isla Bouvet",
  },
  {
    FlagIcon: FlagDefault,
    label: "Isla de Man",
    value: "Isla de Man",
  },
  {
    FlagIcon: FlagDefault,
    label: "Isla de Navidad",
    value: "Isla de Navidad",
  },
  {
    FlagIcon: FlagDefault,
    label: "Isla Norfolk",
    value: "Isla Norfolk",
  },
  {
    FlagIcon: FlagDefault,
    label: "Islandia",
    value: "Islandia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Islas Bermudas",
    value: "Islas Bermudas",
  },
  {
    FlagIcon: FlagDefault,
    label: "Islas Caimán",
    value: "Islas Caiman",
  },
  {
    FlagIcon: FlagDefault,
    label: "Islas Cocos (Keeling)",
    value: "Islas Cocos (Keeling)",
  },
  {
    FlagIcon: FlagDefault,
    label: "Islas Cook",
    value: "Islas Cook",
  },
  {
    FlagIcon: FlagDefault,
    label: "Islas de Åland",
    value: "Islas de Aland",
  },
  {
    FlagIcon: FlagDefault,
    label: "Islas Feroe",
    value: "Islas Feroe",
  },
  {
    FlagIcon: FlagDefault,
    label: "Islas Georgias del Sur y Sandwich del Sur",
    value: "Islas Georgias del Sur y Sandwich del Sur",
  },
  {
    FlagIcon: FlagDefault,
    label: "Islas Heard y McDonald",
    value: "Islas Heard y McDonald",
  },
  {
    FlagIcon: FlagDefault,
    label: "Islas Maldivas",
    value: "Islas Maldivas",
  },
  {
    FlagIcon: FlagDefault,
    label: "Islas Marianas del Norte",
    value: "Islas Marianas del Norte",
  },
  {
    FlagIcon: FlagDefault,
    label: "Islas Marshall",
    value: "Islas Marshall",
  },
  {
    FlagIcon: FlagDefault,
    label: "Islas Pitcairn",
    value: "Islas Pitcairn",
  },
  {
    FlagIcon: FlagDefault,
    label: "Islas Salomón",
    value: "Islas Salomon",
  },
  {
    FlagIcon: FlagDefault,
    label: "Islas Turcas y Caicos",
    value: "Islas Turcas y Caicos",
  },
  {
    FlagIcon: FlagDefault,
    label: "Islas Ultramarinas Menores de Estados Unidos",
    value: "Islas Ultramarinas Menores de Estados Unidos",
  },
  {
    FlagIcon: FlagDefault,
    label: "Islas Vírgenes Británicas",
    value: "Islas Virgenes Britanicas",
  },
  {
    FlagIcon: FlagDefault,
    label: "Islas Vírgenes de los Estados Unidos",
    value: "Islas Virgenes de los Estados Unidos",
  },
  {
    FlagIcon: FlagDefault,
    label: "Israel",
    value: "Israel",
  },
  {
    FlagIcon: FlagDefault,
    label: "Italia",
    value: "Italia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Jamaica",
    value: "Jamaica",
  },
  {
    FlagIcon: FlagDefault,
    label: "Japón",
    value: "Japon",
  },
  {
    FlagIcon: FlagDefault,
    label: "Jersey",
    value: "Jersey",
  },
  {
    FlagIcon: FlagDefault,
    label: "Jordania",
    value: "Jordania",
  },
  {
    FlagIcon: FlagDefault,
    label: "Kazajistán",
    value: "Kazajistan",
  },
  {
    FlagIcon: FlagDefault,
    label: "Kenia",
    value: "Kenia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Kirguistán",
    value: "Kirguistan",
  },
  {
    FlagIcon: FlagDefault,
    label: "Kiribati",
    value: "Kiribati",
  },
  {
    FlagIcon: FlagDefault,
    label: "Kuwait",
    value: "Kuwait",
  },
  {
    FlagIcon: FlagDefault,
    label: "Laos",
    value: "Laos",
  },
  {
    FlagIcon: FlagDefault,
    label: "Lesoto",
    value: "Lesoto",
  },
  {
    FlagIcon: FlagDefault,
    label: "Letonia",
    value: "Letonia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Líbano",
    value: "Libano",
  },
  {
    FlagIcon: FlagDefault,
    label: "Liberia",
    value: "Liberia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Libia",
    value: "Libia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Liechtenstein",
    value: "Liechtenstein",
  },
  {
    FlagIcon: FlagDefault,
    label: "Lituania",
    value: "Lituania",
  },
  {
    FlagIcon: FlagDefault,
    label: "Luxemburgo",
    value: "Luxemburgo",
  },
  {
    FlagIcon: FlagDefault,
    label: "Macao",
    value: "Macao",
  },
  {
    FlagIcon: FlagDefault,
    label: "Macedônia",
    value: "Macedonia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Madagascar",
    value: "Madagascar",
  },
  {
    FlagIcon: FlagDefault,
    label: "Malasia",
    value: "Malasia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Malawi",
    value: "Malawi",
  },
  {
    FlagIcon: FlagDefault,
    label: "Mali",
    value: "Mali",
  },
  {
    FlagIcon: FlagDefault,
    label: "Malta",
    value: "Malta",
  },
  {
    FlagIcon: FlagDefault,
    label: "Marruecos",
    value: "Marruecos",
  },
  {
    FlagIcon: FlagDefault,
    label: "Martinica",
    value: "Martinica",
  },
  {
    FlagIcon: FlagDefault,
    label: "Mauricio",
    value: "Mauricio",
  },
  {
    FlagIcon: FlagDefault,
    label: "Mauritania",
    value: "Mauritania",
  },
  {
    FlagIcon: FlagDefault,
    label: "Mayotte",
    value: "Mayotte",
  },
  {
    FlagIcon: FlagDefault,
    label: "Micronesia",
    value: "Micronesia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Moldavia",
    value: "Moldavia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Mónaco",
    value: "Monaco",
  },
  {
    FlagIcon: FlagDefault,
    label: "Mongolia",
    value: "Mongolia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Montenegro",
    value: "Montenegro",
  },
  {
    FlagIcon: FlagDefault,
    label: "Montserrat",
    value: "Montserrat",
  },
  {
    FlagIcon: FlagDefault,
    label: "Mozambique",
    value: "Mozambique",
  },
  {
    FlagIcon: FlagDefault,
    label: "Namibia",
    value: "Namibia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Nauru",
    value: "Nauru",
  },
  {
    FlagIcon: FlagDefault,
    label: "Nepal",
    value: "Nepal",
  },
  {
    FlagIcon: FlagDefault,
    label: "Niger",
    value: "Niger",
  },
  {
    FlagIcon: FlagDefault,
    label: "Nigeria",
    value: "Nigeria",
  },
  {
    FlagIcon: FlagDefault,
    label: "Niue",
    value: "Niue",
  },
  {
    FlagIcon: FlagDefault,
    label: "Noruega",
    value: "Noruega",
  },
  {
    FlagIcon: FlagDefault,
    label: "Nueva Caledonia",
    value: "Nueva Caledonia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Nueva Zelanda",
    value: "Nueva Zelanda",
  },
  {
    FlagIcon: FlagDefault,
    label: "Omán",
    value: "Oman",
  },
  {
    FlagIcon: FlagDefault,
    label: "Países Bajos",
    value: "Paises Bajos",
  },
  {
    FlagIcon: FlagDefault,
    label: "Pakistán",
    value: "Pakistan",
  },
  {
    FlagIcon: FlagDefault,
    label: "Palau",
    value: "Palau",
  },
  {
    FlagIcon: FlagDefault,
    label: "Palestina",
    value: "Palestina",
  },
  {
    FlagIcon: FlagDefault,
    label: "Papúa Nueva Guinea",
    value: "Papua Nueva Guinea",
  },
  {
    FlagIcon: FlagDefault,
    label: "Polinesia Francesa",
    value: "Polinesia Francesa",
  },
  {
    FlagIcon: FlagDefault,
    label: "Polonia",
    value: "Polonia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Portugal",
    value: "Portugal",
  },
  {
    FlagIcon: FlagDefault,
    label: "Qatar",
    value: "Qatar",
  },
  {
    FlagIcon: FlagDefault,
    label: "Reino Unido",
    value: "Reino Unido",
  },
  {
    FlagIcon: FlagDefault,
    label: "República Centroafricana",
    value: "Republica Centroafricana",
  },
  {
    FlagIcon: FlagDefault,
    label: "República Checa",
    value: "Republica Checa",
  },
  {
    FlagIcon: FlagDefault,
    label: "República de Sudán del Sur",
    value: "Republica de Sudan del Sur",
  },
  {
    FlagIcon: FlagDefault,
    label: "República del Congo",
    value: "Republica del Congo",
  },
  {
    FlagIcon: FlagDefault,
    label: "República Democrática del Congo",
    value: "Republica Democratica del Congo",
  },
  {
    FlagIcon: FlagDefault,
    label: "Reunión",
    value: "Reunion",
  },
  {
    FlagIcon: FlagDefault,
    label: "Ruanda",
    value: "Ruanda",
  },
  {
    FlagIcon: FlagDefault,
    label: "Rumanía",
    value: "Rumania",
  },
  {
    FlagIcon: FlagDefault,
    label: "Rusia",
    value: "Rusia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Sahara Occidental",
    value: "Sahara Occidental",
  },
  {
    FlagIcon: FlagDefault,
    label: "Samoa",
    value: "Samoa",
  },
  {
    FlagIcon: FlagDefault,
    label: "Samoa Americana",
    value: "Samoa Americana",
  },
  {
    FlagIcon: FlagDefault,
    label: "San Bartolomé",
    value: "San Bartolome",
  },
  {
    FlagIcon: FlagDefault,
    label: "San Cristóbal y Nieves",
    value: "San Cristobal y Nieves",
  },
  {
    FlagIcon: FlagDefault,
    label: "San Marino",
    value: "San Marino",
  },
  {
    FlagIcon: FlagDefault,
    label: "San Martín (Francia)",
    value: "San Martin (Francia)",
  },
  {
    FlagIcon: FlagDefault,
    label: "San Pedro y Miquelón",
    value: "San Pedro y Miquelon",
  },
  {
    FlagIcon: FlagDefault,
    label: "San Vicente y las Granadinas",
    value: "San Vicente y las Granadinas",
  },
  {
    FlagIcon: FlagDefault,
    label: "Santa Elena",
    value: "Santa Elena",
  },
  {
    FlagIcon: FlagDefault,
    label: "Santa Lucía",
    value: "Santa Lucia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Santo Tomé y Príncipe",
    value: "Santo Tome y Principe",
  },
  {
    FlagIcon: FlagDefault,
    label: "Senegal",
    value: "Senegal",
  },
  {
    FlagIcon: FlagDefault,
    label: "Serbia",
    value: "Serbia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Seychelles",
    value: "Seychelles",
  },
  {
    FlagIcon: FlagDefault,
    label: "Sierra Leona",
    value: "Sierra Leona",
  },
  {
    FlagIcon: FlagDefault,
    label: "Singapur",
    value: "Singapur",
  },
  {
    FlagIcon: FlagDefault,
    label: "Sint Maarten",
    value: "Sint Maarten",
  },
  {
    FlagIcon: FlagDefault,
    label: "Siria",
    value: "Siria",
  },
  {
    FlagIcon: FlagDefault,
    label: "Somalia",
    value: "Somalia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Sri lanka",
    value: "Sri lanka",
  },
  {
    FlagIcon: FlagDefault,
    label: "Sudáfrica",
    value: "Sudafrica",
  },
  {
    FlagIcon: FlagDefault,
    label: "Sudán",
    value: "Sudan",
  },
  {
    FlagIcon: FlagDefault,
    label: "Suecia",
    value: "Suecia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Suiza",
    value: "Suiza",
  },
  {
    FlagIcon: FlagDefault,
    label: "Surinám",
    value: "Surinam",
  },
  {
    FlagIcon: FlagDefault,
    label: "Svalbard y Jan Mayen",
    value: "Svalbard y Jan Mayen",
  },
  {
    FlagIcon: FlagDefault,
    label: "Swazilandia",
    value: "Swazilandia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Tailandia",
    value: "Tailandia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Taiwán",
    value: "Taiwan",
  },
  {
    FlagIcon: FlagDefault,
    label: "Tanzania",
    value: "Tanzania",
  },
  {
    FlagIcon: FlagDefault,
    label: "Tayikistán",
    value: "Tayikistan",
  },
  {
    FlagIcon: FlagDefault,
    label: "Territorio Británico del Océano Índico",
    value: "Territorio Britanico del Oceano Indico",
  },
  {
    FlagIcon: FlagDefault,
    label: "Territorios Australes y Antárticas Franceses",
    value: "Territorios Australes y Antarticas Franceses",
  },
  {
    FlagIcon: FlagDefault,
    label: "Timor Oriental",
    value: "Timor Oriental",
  },
  {
    FlagIcon: FlagDefault,
    label: "Togo",
    value: "Togo",
  },
  {
    FlagIcon: FlagDefault,
    label: "Tokelau",
    value: "Tokelau",
  },
  {
    FlagIcon: FlagDefault,
    label: "Tonga",
    value: "Tonga",
  },
  {
    FlagIcon: FlagDefault,
    label: "Trinidad y Tobago",
    value: "Trinidad y Tobago",
  },
  {
    FlagIcon: FlagDefault,
    label: "Tunez",
    value: "Tunez",
  },
  {
    FlagIcon: FlagDefault,
    label: "Turkmenistán",
    value: "Turkmenistan",
  },
  {
    FlagIcon: FlagDefault,
    label: "Turquía",
    value: "Turquia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Tuvalu",
    value: "Tuvalu",
  },
  {
    FlagIcon: FlagDefault,
    label: "Ucrania",
    value: "Ucrania",
  },
  {
    FlagIcon: FlagDefault,
    label: "Uganda",
    value: "Uganda",
  },
  {
    FlagIcon: FlagDefault,
    label: "Uzbekistán",
    value: "Uzbekistan",
  },
  {
    FlagIcon: FlagDefault,
    label: "Vanuatu",
    value: "Vanuatu",
  },
  {
    FlagIcon: FlagDefault,
    label: "Vietnam",
    value: "Vietnam",
  },
  {
    FlagIcon: FlagDefault,
    label: "Wallis y Futuna",
    value: "Wallis y Futuna",
  },
  {
    FlagIcon: FlagDefault,
    label: "Yemen",
    value: "Yemen",
  },
  {
    FlagIcon: FlagDefault,
    label: "Yibuti",
    value: "Yibuti",
  },
  {
    FlagIcon: FlagDefault,
    label: "Zambia",
    value: "Zambia",
  },
  {
    FlagIcon: FlagDefault,
    label: "Zimbabue",
    value: "Zimbabue",
  },
  {
    FlagIcon: FlagDefault,
    label: "Kosovo",
    value: "Kosovo",
  },
  {
    FlagIcon: FlagDefault,
    label: "Túnez",
    value: "Tunez",
  },
  {
    FlagIcon: FlagDefault,
    label: "Belarús",
    value: "Belarus",
  },
];

export const ALL_COUNTRIES: Country[] = [USA, ...COUNTRIES_LATAM, ...COUNTRIES];
