import {
  IErrorUploadExecutives,
  IFileUploadExecutives,
} from "@components/organism/FileUploadExecutives/FileUploadExecutives.interfaces";

export const fileSelectedInitExecutive: IFileUploadExecutives = {
  name: "",
  size: 0,
  type: "",
};

export const errorUploadingFileInitExecutive: IErrorUploadExecutives = {
  errorFile: null,
  message: "",
};

export const FileValidationExecutive = {
  MAX_SIZE: 5,
  TYPE: "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};
