import React from "react";
import { capitalize } from "lodash";
import { CountryValueEnum } from "../../../shared/infrastructure/Enums/CountryEnum";
import { DashboardTableRowEC } from "./DashboardTableRowEC/DashboardTableRowEC";
import { DashboardTableRowCO } from "./DashboardTableRowCO/DashboardTableRowCO";
import { IDashboardTableRowProps } from "./DashboardTableRowEC/DashboardTableRowEC.interfaces";

export const DashboardTableRow = (
  props: IDashboardTableRowProps
): JSX.Element => {
  switch (capitalize(props.country)) {
    case CountryValueEnum.Colombia:
      return <DashboardTableRowCO {...props} />;
    case CountryValueEnum.Ecuador:
    default:
      return <DashboardTableRowEC {...props} />;
  }
};
