export enum StatusUserEnum {
  FORCE_CHANGE_PASSWORD = "FORCE_CHANGE_PASSWORD",
  UNCONFIRMED = "UNCONFIRMED",
  COMPROMISED = "COMPROMISED",
  CONFIRMED = "CONFIRMED",
}

export const CatalogDisabledStatus: string[] = [
  StatusUserEnum.FORCE_CHANGE_PASSWORD,
  StatusUserEnum.COMPROMISED,
  StatusUserEnum.UNCONFIRMED,
];

export enum LabelStatusEnum {
  ACTIVE = "Activo",
  INACTIVE = "Inactivo",
}

export enum StatusTagColor {
  ERROR = "error",
  SUCCESS = "success",
}

export const CatalogUserState: Record<LabelStatusEnum, boolean> = {
  [LabelStatusEnum.ACTIVE]: true,
  [LabelStatusEnum.INACTIVE]: false,
};
