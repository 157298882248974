import React, { FC } from "react";
import { Box } from "@mui/material";
import FooterOptions from "../../components/FooterOptions/FooterOptions";
import { mainContainerStyles as styles } from "./MainContainer.styles";
import { useMainContainer } from "./state/useMainContainer";
import ConfigDataWrapper from "../../components/ConfigDataWrapper/ConfigDataWrapper";
import ConfigDataCard from "../../components/ConfigDataCard/ConfigDataCard";
import { ServiceConfigEnum } from "../../shared/enums/ServiceConfigEnum";
import Subheader from "../../components/Subheader/Subheader";
import ModalDialog from "../../components/ModalDialog/ModalDialog";
import { ModalLoader } from "@kushki/connect-ui";

const MainContainer: FC = () => {
  const {
    primaryButton,
    secondaryButton,
    footerLabel,
    cardContent,
    configureButton,
    hideEditBtn,
    subHeader,
    openModal,
    modalInfo,
    onCloseModal,
    handleAcceptModal,
    handleMassiveServicesCreation,
    isLoading,
    isCentralizedBranch,
  } = useMainContainer();

  return (
    <div>
      <Box sx={styles.containerBody}>
        <Box>
          <Subheader
            country={subHeader.country}
            id={subHeader.id}
            redirectPath={subHeader.redirectPath}
            title={subHeader.title}
            subTitle={subHeader.subTitle}
            totalBranches={subHeader.totalBranches}
            branchesTitle={subHeader.branchesTitle}
          />
        </Box>
        <Box>
          <ConfigDataWrapper
            cardContent={cardContent}
            title={ServiceConfigEnum.SERVICES}
            configureButton={configureButton}
            hideEditBtn={hideEditBtn}
          >
            <ConfigDataCard
              configureButton={configureButton}
              cardContent={cardContent}
              hideEditBtn={hideEditBtn}
              isCentralized={isCentralizedBranch}
            />
          </ConfigDataWrapper>
        </Box>
      </Box>
      <Box sx={styles.boxFooter}>
        <FooterOptions
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
          label={footerLabel}
          handleMassiveServicesCreation={handleMassiveServicesCreation}
        />
      </Box>
      <ModalDialog
        acceptButtonText={ServiceConfigEnum.CONTINUE}
        cancelButtonText={ServiceConfigEnum.CANCEL}
        descriptionText={ServiceConfigEnum.MODAL_DESCRIPTION}
        onClickAcceptButton={handleAcceptModal}
        onClickCancelButton={onCloseModal}
        onClose={onCloseModal}
        openModalDialog={openModal}
        titleText={ServiceConfigEnum.MODAL_TITLE}
        {...modalInfo}
      />
      <ModalLoader
        data-testid={"ModalLoader"}
        descriptionText={ServiceConfigEnum.MODAL_LOADER_DESCRIPTION}
        titleText={ServiceConfigEnum.MODAL_LOADER_TITLE}
        isOpen={isLoading}
      />
    </div>
  );
};

export default MainContainer;
