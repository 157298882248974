import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { useEffect, useState } from "react";
import { ISubheaderProps } from "../../../components/Subheader/Subheader.interfaces";
import {
  buildSubHeaderTexts,
  SUB_HEADER_DEFAULT_STATE,
} from "../../../shared/constants/main_container_constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getNodeInfo,
  getSemaphoreData,
} from "../../../store/thunks/app/app.thunks";
import { get } from "lodash";
import { EntityName } from "../../../shared/enums/entityName";
import { IUseWrapperContainer } from "./useWrapperContainer.interfaces";
import { ConfigIdEnum } from "../../../shared/constants/enums/ConfigIdEnum";
import { RoutePathsEnum } from "../../../shared/constants/enums/RoutePathsEnum";

export const useWrapperContainer = (): IUseWrapperContainer => {
  const { nodeInfo, semaphoreData } = useAppSelector((state) => state.app);
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const publicMerchantId = searchParams.get("publicMerchantId");
  const [subHeaderTexts, setSubHeaderTexts] = useState<ISubheaderProps>(
    SUB_HEADER_DEFAULT_STATE
  );

  const requestNodeInfo = () => {
    if (!nodeInfo && publicMerchantId)
      dispatch(
        getNodeInfo({
          configIds: ConfigIdEnum.PREFERENCES,
          publicMerchantId,
        })
      );
  };

  const requestSemaphoreData = () => {
    if (!semaphoreData && publicMerchantId)
      dispatch(
        getSemaphoreData({
          publicMerchantId,
        })
      );
  };

  const setSubheaderTexts = () => {
    setSubHeaderTexts(
      buildSubHeaderTexts(searchParams.get("publicMerchantId"), nodeInfo!)
    );
  };

  const handleReturn = () => {
    navigate(
      `/..${RoutePathsEnum.MERCHANT_RESUME.replace(
        "{merchantId}",
        publicMerchantId as string
      )}`
    );
  };

  useEffect(() => {
    requestNodeInfo();
  }, []);

  useEffect(() => {
    const isCustomerBranch: boolean =
      [EntityName.CUSTOMER, EntityName.BRANCH].indexOf(
        get(nodeInfo, "entityName") as EntityName
      ) > -1;

    if (nodeInfo && isCustomerBranch) {
      requestSemaphoreData();
      setSubheaderTexts();
    }
  }, [nodeInfo]);

  return {
    handleReturn,
    subHeaderTexts,
  };
};
