import { createWebsocket } from "../create_websockets";
import { IHandleResponseWS } from "./update_transactions_status_ws.interfaces";
import { UpdateTransactionsStatusWS } from "./update_transactions_status_ws.interfaces";
import { hideLoading, showLoading } from "../../../../store/actions/app/app";

export const callbackMessage =
  (
    handleResponse: (
      response: MessageEvent
    ) => IHandleResponseWS | Promise<IHandleResponseWS>,
    webSocket: WebSocket
  ) =>
  async (event: MessageEvent) => {
    const { closeConnection } = await handleResponse(event);

    if (closeConnection) {
      webSocket.close();
    }
  };

export const updateTransactionsStatusWS: UpdateTransactionsStatusWS = async (
  urlWS,
  { data, handleResponse, dispatch }
) => {
  return new Promise((resolve) => {
    try {
      dispatch(showLoading());
      const webSocket = createWebsocket(urlWS);

      webSocket.addEventListener("open", () => {
        webSocket.send(JSON.stringify(data));
      });

      webSocket.addEventListener("error", () => {
        dispatch(hideLoading());
        webSocket.close();
        resolve(false);
      });

      webSocket.addEventListener(
        "message",
        callbackMessage(
          (message: MessageEvent<string>) => handleResponse(message, dispatch),
          webSocket
        )
      );
    } catch (error) {
      resolve(false);
    }
  });
};
