import {
  MerchantNodeData,
  SearchMerchantResponse,
} from "../../../types/search_merchant_response";
import { StatusConfigEnum, StatusEnum } from "../enums/StatusEnum";
import { SearchMerchantRequest } from "../../../types/search_merchant_request";
import axios from "../axios-util";
import { API_ROUTES } from "../constants/api_routes";
import { getJwtAuth } from "./getJwtAuth_utils";
import { ChangeStatusMerchant } from "../../../types/change_status_merchant";
import { cloneDeep, get, groupBy, isEmpty, remove, set } from "lodash";
import { NodeTypeEnum } from "../catalogs/CatalogConfigTable";
import { Configs, INodeInfoResponse } from "../../../types/node_info_response";
import { ConfigTypeEnum } from "../enums/configEnum";
import {
  labelRequiredConfigs,
  requiredConfigs,
} from "../constants/requiredConfigs";
import { CountryEnum } from "../enums/CountryEnum";
import { CatalogRequiredConfig } from "../catalogs/CatalogRequiredConfig";
import { EntityNameEnum } from "../enums/EntityNameEnum";

export const verifyStatus = (items: MerchantNodeData[], status: StatusEnum) =>
  items.every((item) => item.merchant_status === status);

export const validateMultipleStatusPending = (items: MerchantNodeData[]) => {
  const groupedStatus = groupBy(items, "status");
  const keysList: string[] = Object.keys(groupedStatus);

  return keysList.length === 1 && !keysList.includes(StatusEnum.PENDING);
};

export const validateMultipleStatus = (
  items: MerchantNodeData[],
  entityName: String
) => {
  const groupedStatus = groupBy(items, "status");
  const keysList: string[] = Object.keys(groupedStatus);

  return (
    entityName === NodeTypeEnum.BRANCH &&
    (keysList.length > 1 || items.length > 1)
  );
};

export const verifyIsPending = (items: MerchantNodeData[]) =>
  items.some((item) => item.merchant_status === StatusEnum.PENDING);

export const searchBranches = (request: SearchMerchantRequest) =>
  axios.post<SearchMerchantResponse>(API_ROUTES.SEARCH_MERCHANT_NODE, request, {
    headers: {
      Authorization: getJwtAuth(),
      "Content-Type": "application/json",
    },
  });

export const changeStatusNodesByIds = (
  status: StatusEnum,
  merchantIdsNodes: string[]
) =>
  axios.post<ChangeStatusMerchant>(
    API_ROUTES.CHANGE_STATUS_BY_ID,
    {
      publicMerchantId: merchantIdsNodes,
      status:
        status === StatusEnum.ACTIVE ? StatusEnum.INACTIVE : StatusEnum.ACTIVE,
    },
    {
      headers: {
        Authorization: getJwtAuth(),
        "Content-Type": "application/json",
      },
    }
  );
export const nodeInfo = (publicMerchantId: string) =>
  axios.post<INodeInfoResponse>(
    API_ROUTES.NODE_INFO,
    { publicMerchantId },
    {
      headers: {
        Authorization: getJwtAuth(),
        "Content-Type": "application/json",
      },
    }
  );

export const verifyEditButton = (itemsSelected: MerchantNodeData[]) => {
  const selectedCountries: string[] = itemsSelected.map(
    (item: MerchantNodeData) => get(item, "constitutional_country", "")
  );

  return !selectedCountries.every(
    (country: string) => country === selectedCountries[0]
  );
};

export const validateRequiredConfigs = (
  merchant: INodeInfoResponse
): boolean => {
  const operationalCountry: string = get(merchant, "generalInfo.country", "");
  const tempReqConfigs: ConfigTypeEnum[] = cloneDeep(requiredConfigs);

  if (
    !isEmpty(operationalCountry) &&
    operationalCountry !== CountryEnum.ecuador
  ) {
    remove(
      tempReqConfigs,
      (data: ConfigTypeEnum) => data === ConfigTypeEnum.cn017
    );
  }

  return tempReqConfigs.every((requiredConfig: string) => {
    return get(merchant, "configs", []).some(
      (config: Configs) =>
        config.configuration === requiredConfig &&
        (config.status === StatusConfigEnum.COMPLETE ||
          config.status === StatusConfigEnum.OMITTED)
    );
  });
};

export const generateRequiredConfigsLabel = (
  merchant: INodeInfoResponse
): string => {
  const operationalCountry: string = get(merchant, "generalInfo.country", "");
  const tempLabelReqConfigs: ConfigTypeEnum[] = cloneDeep(labelRequiredConfigs);

  if (
    !isEmpty(operationalCountry) &&
    operationalCountry !== CountryEnum.ecuador
  ) {
    remove(
      tempLabelReqConfigs,
      (data: ConfigTypeEnum) => data === ConfigTypeEnum.cn017
    );
  }

  let builtLabel: string[] = [];

  get(merchant, "configs", []).some((config: Configs) => {
    if (isEmpty(config.status)) set(config, "status", StatusEnum.PENDING);
    if (
      tempLabelReqConfigs.includes(config.configuration as ConfigTypeEnum) &&
      config.status === StatusEnum.PENDING
    ) {
      builtLabel.push(
        CatalogRequiredConfig[config.configuration as ConfigTypeEnum]
      );
    }
  });

  return builtLabel.join(", ");
};

export const validateUserConfig = (merchant: INodeInfoResponse): boolean => {
  if (
    merchant.entityName === EntityNameEnum.CUSTOMER &&
    validateRequiredConfigs(merchant) &&
    get(merchant, "merchant_status") === StatusEnum.PENDING
  ) {
    return false;
  }

  return get(merchant, "configs", []).some(
    (config: Configs) =>
      config.configuration === ConfigTypeEnum.cn019 &&
      (config.status === StatusConfigEnum.COMPLETE ||
        config.status === StatusConfigEnum.OMITTED)
  );
};
