import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const summaryMassiveBranchStyles = createNamedStyles({
  summaryBtn: {
    paddingRight: 0,
    border: "none",
    ":hover": {
      border: "none",
      backgroundColor: "transparent",
    },
    "& .MuiButtonBase-root-MuiButton-root": {
      border: "none",
    },
  },
  branchNames: {
    width: "550px",
    whiteSpace: "nowrap",
    overflow: "hidden !important",
  },
  boxRight: { display: "flex", margin: "15px 0px 15px 15px" },
  wrappedSummary: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: "150px",
  },
  textTotalBranches: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0025em",
    color: "#023365",
  },
  textNameBranches: {
    color: "#023365",
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "140%",
  },
});
