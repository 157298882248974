import React, { useState } from "react";
import {
  Box,
  createStyles,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SmartlinkDatePicker } from "../../DatePicker/SmartlinkDatePicker";
import { PopoverFilter } from "../../DashboardList/PopoverFilters/PopoverFilter";
import { Search } from "react-feather";
import { DateRange } from "@material-ui/pickers";
import { get } from "lodash";
import { CountryType } from "../../../shared/infrastructure/CountryType";
import { IOptionFilter } from "../../DashboardList/BoxFilter/BoxFilter";
import { ColumnFilters } from "../../DashboardList/ColumnFilters/ColumnFilters";
import imgSettings from "../../../assets/images/⚙️settings.svg";
import { InvoiceRecord } from "../../../../types/remote/response_search_blling_by_filters";
import { FilterButtonSection } from "../../FilterButtonSection/FilterButtonSection";
import {
  ColumnsEnum,
  columnsTable,
} from "../../../shared/infrastructure/table/IColumnsTable";
import { IColumnsTable } from "../../../shared/infrastructure/table/IColumnsTable";
import { CountryEnum } from "../../../shared/infrastructure/CountryEnum";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "4px",
      padding: "7px 15px",
      backgroundColor: "#f7fafc",
      color: "#293036",
      width: "100%",
      marginLeft: "0px",
    },
    cardRoot: {
      maxWidth: 400,
    },
    heading: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightMedium,
    },
    divider: {
      height: 28,
      marginLeft: 8,
      border: "0.5px solid",
      boxSizing: "border-box",
    },
    textField: {
      padding: 0,
      height: "36px",
      marginTop: "-3px",
      width: "100%",
      fontSize: "16px !important",
    },
    iconButton: {
      "& .MuiIconButton-root": {
        height: "39px",
        width: "39px",
        marginTop: "0px",
        borderStyle: "solid",
        borderColor: "#C4C4C4",
        borderWidth: 1,
        borderRadius: 4,
        backgroundColor: "#FFFFFF",
      },
    },
    reprocessButton: {
      paddingLeft: 20,
    },
  })
);

export interface HistoricFiltersProps {
  handleSetFilters: (filters: { [key: string]: boolean }) => void;
  handleInputChange: (value: string) => void;
  filters: IOptionFilter[];
  handleSearchBar: (value: string) => void;
  query: string;
  filterChips: { [key: string]: boolean };
  handleSetDate: (dateRange: DateRange) => void;
  range: DateRange;
  reprocess: { reprocessNumber: number; reprocessTransactions: () => void };
  downloadFileState: {
    isLoading: boolean;
    isDownloadingCheck: boolean;
    downloadFile: (
      format: string,
      dataSelectedTransactions?: InvoiceRecord | undefined
    ) => void;
  };
  counter: number;
  country: CountryType;
  selectedColumns: string[];
  setSelectedColumns: (data: string[]) => void;
  handleAppliedColumnsFilters: (restore?: boolean) => void;
}

export const HistoricFilters: React.FC<HistoricFiltersProps> = (
  props: HistoricFiltersProps
) => {
  const classes = useStyles();
  const [
    anchorColumns,
    setAnchorColumns,
  ] = React.useState<HTMLButtonElement | null>(null);
  const getSelectableColumns = (
    columnsTable: IColumnsTable[]
  ): IColumnsTable[] => {
    const isCountryPeru: boolean = props.country === CountryEnum.peru;
    const hasInterbankAccountCode = (column: IColumnsTable): boolean =>
      column.id === ColumnsEnum.INTERBANK_ACCOUNT_CODE;
    const columns: IColumnsTable[] = isCountryPeru
      ? columnsTable.filter(
          (column: IColumnsTable) =>
            column.filter || hasInterbankAccountCode(column)
        )
      : columnsTable.filter(
          (column: IColumnsTable) =>
            column.filter && !hasInterbankAccountCode(column)
        );

    return columns;
  };
  const [selectableColumns] = useState<IColumnsTable[]>(
    getSelectableColumns(columnsTable)
  );
  const openColumns = Boolean(anchorColumns);
  const idButtonColumns = openColumns ? "filter-popover" : undefined;

  const {
    country,
    filterChips,
    handleSetFilters,
    counter,
    filters,
    reprocess,
    downloadFileState,
  } = props;
  const handleDate = (date: DateRange | MouseEvent): void => {
    if (get(date, "movementX") === 0) {
      props.handleSetDate([null, null]);

      return;
    }

    props.handleSetDate(date as DateRange);
  };
  const handleClickColumns = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorColumns(event.currentTarget);
  };
  const handleCloseColumns = () => {
    setAnchorColumns(null);
  };

  const handleChangeColumnCheck = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const column: string = event.target.name;
    let auxColumnsSelected: string[] = [...props.selectedColumns];

    if (!event.target.checked) {
      const index: number = auxColumnsSelected.findIndex(
        (col: string) => col === column
      );

      if (index !== -1) auxColumnsSelected.splice(index, 1);
    } else {
      if (props.selectedColumns.length > 2) return;
      auxColumnsSelected = [...auxColumnsSelected, column];
    }
    props.setSelectedColumns(auxColumnsSelected);
  };
  const handleRestoreColumns = () => {
    props.handleAppliedColumnsFilters(true);
    handleCloseColumns();
  };
  const handleDisplayedColumnUser = () => {
    props.handleAppliedColumnsFilters();
    handleCloseColumns();
  };

  return (
    <React.Fragment>
      <Grid
        container
        spacing={3}
        alignItems="center"
        direction="row"
        className={classes.root}
      >
        <Grid item>
          <PopoverFilter
            country={country}
            handleSetFilters={handleSetFilters}
            filterChips={filterChips}
            counterFilter={counter}
            filters={filters}
          />
        </Grid>
        <Grid item xs={3}>
          <SmartlinkDatePicker
            value={props.range}
            disableFuture={false}
            handleDate={handleDate}
          />
        </Grid>
        <Grid item xs={3} justify={"flex-end"}>
          <TextField
            value={props.query}
            id="outlined-search"
            key={"outlinedSearch"}
            autoFocus
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search size={16} />
                </InputAdornment>
              ),
            }}
            className={classes.textField}
            variant={"outlined"}
            type="search"
            size={"small"}
            placeholder={"TAX ID / Comercio / Razón social"}
            onKeyDown={(event: any) => {
              props.handleSearchBar(event.key);
            }}
            onChange={(event: any) => {
              props.handleInputChange(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={1} justify={"flex-end"}>
          <Box className={classes.iconButton}>
            <IconButton
              aria-label="settings-columns"
              color="primary"
              onClick={handleClickColumns}
            >
              <img src={imgSettings} />
            </IconButton>
            <ColumnFilters
              openColumns={openColumns}
              idButtonColumns={idButtonColumns}
              anchorColumns={anchorColumns}
              handleCloseColumns={handleCloseColumns}
              selectedColumns={props.selectedColumns}
              handleChangeColumnCheck={handleChangeColumnCheck}
              handleRestoreColumns={handleRestoreColumns}
              handleDisplayedColumnUser={handleDisplayedColumnUser}
              selectableColumns={selectableColumns}
              title={"Elige hasta 2 columnas para mostrar "}
              admittedColumns={2}
            />
          </Box>
        </Grid>
        <Grid xs={3} container item justify="space-between">
          <FilterButtonSection
            country={country}
            reprocess={reprocess}
            downloadFileState={downloadFileState}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
