import { CustomStyles } from "../../../shared/infrastructure/interfaces/CustomStyles";

export const boardMembersAccordionStyles: CustomStyles = {
  accordion: {
    backgroundColor: "#f0f4f9",
    boxShadow: "none",
    marginTop: "2rem !important",
    padding: "1rem 1.5rem",
    "&:hover": {
      boxShadow: "none",
    },
  },
  summary: {
    flexDirection: "row-reverse",
    height: "55px",
    "& .MuiAccordionSummary-content": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginLeft: "1rem",
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(-180deg)",
    },
  },
  subtitle: {
    color: "#6D7781",
    fontSize: "13px",
  },
};
