import { createSlice } from "@reduxjs/toolkit";
import { RatesConfigDynamo } from "../../../../types/rates_config_dynamo";
import { getRatesInfo } from "../../thunks/rates/rates.thunks";

export interface IRatesAppState {
  ratesInfo: RatesConfigDynamo[];
  isLoadingRates: boolean;
}

export const initialState: IRatesAppState = {
  isLoadingRates: true,
  ratesInfo: [],
};

export const ratesAppSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getRatesInfo.pending, (state) => {
        state.isLoadingRates = true;
      })
      .addCase(getRatesInfo.fulfilled, (state, action) => {
        state.ratesInfo = action.payload;
        state.isLoadingRates = false;
      })
      .addCase(getRatesInfo.rejected, (state) => {
        state.isLoadingRates = false;
      });
  },
  initialState,
  name: "ratesApp",
  reducers: {},
});

export default ratesAppSlice.reducer;
