import React from "react";
import { Divider, Grid } from "@material-ui/core";
import { DownloadTemplateDispersion } from "../DownloadTemplateDispersion/DownloadTemplateDispersion";
import { UploadFileDispersion } from "../UploadFileDispersion/UploadFileDispersion";
import { requestDispersionCompleteStyle } from "./styles/RequestDispersionComplete.style";
import { isEmpty } from "lodash";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";
import { LabelsDispersion } from "../../shared/infrastructure/constants/RequestDispersionConstants";
import { FileValidationResult } from "../FileValidationResult/FileValidationResult";
import { useRequestDispersionCompleteState } from "./states/useRequestDispersionCompleteState";

export const RequestDispersionComplete = () => {
  const classes = requestDispersionCompleteStyle();
  const {
    validationResults,
    showValidationAlert,
    showAlertOnProcessFileRequest,
  } = useRequestDispersionCompleteState();

  return (
    <>
      {(showValidationAlert || showAlertOnProcessFileRequest) && (
        <Alert
          icon={<CloseIcon fontSize="inherit" />}
          className={classes.errorAlert}
        >
          {LabelsDispersion.VALIDATION_ERROR_ALERT}
        </Alert>
      )}

      {isEmpty(validationResults) ? (
        <Grid
          container
          direction={"row"}
          spacing={2}
          className={classes.mainGrid}
        >
          <Grid item xs={4} sm={3}>
            <DownloadTemplateDispersion />
          </Grid>
          <Grid item xs={"auto"} className={classes.divider}>
            <Divider orientation={"vertical"} />
          </Grid>
          <Grid item xs={7} className={classes.gridUploadDispersion}>
            <UploadFileDispersion />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} className={classes.mainGrid}>
          <FileValidationResult validationResults={validationResults} />
        </Grid>
      )}
    </>
  );
};
