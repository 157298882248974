import * as React from "react";
import { Box, Checkbox, Typography } from "@mui/material";
import { FC, HTMLAttributes } from "react";
import {
  checkedIcon,
  icon,
} from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/icons/icons";

export interface IOptionRenderProps {
  listProps: HTMLAttributes<HTMLLIElement>;
  selected: boolean;
  label: string;
  hasDescription?: boolean;
  description?: string;
  isDisabled?: boolean;
}

const OptionRenderer: FC<IOptionRenderProps> = ({
  listProps,
  selected,
  label,
  isDisabled,
  description,
  hasDescription,
}) => (
  <li {...listProps} key={description}>
    <Checkbox
      icon={icon}
      checkedIcon={checkedIcon}
      checked={selected}
      disabled={isDisabled}
    />

    {hasDescription ? (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingRight: "10px",
          paddingTop: "10px",
        }}
      >
        <Typography fontSize={14} color={"#293036"}>
          {label}
        </Typography>
        <Typography fontSize={13} color={"#B4B9BE"}>
          {description}
        </Typography>
      </Box>
    ) : (
      label
    )}
  </li>
);

export default OptionRenderer;
