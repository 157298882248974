import { Chip, Grid } from "@material-ui/core";

import React from "react";
import { DeferredMonths } from "../../shared/infrastructure/interfaces/IDeferredMonths";
import { X } from "react-feather";
import { get } from "lodash";
import { makeStyles } from "@material-ui/core/styles";

export interface IMonthChipSelector {
  handleDeleteSelectedMonth(value: number): void;
  months: DeferredMonths[];
}

const useStyles = makeStyles({
  chipIcon: {
    color: "#293036",
  },
  chipLabel: {
    backgroundColor: "#F7FAFC",
    borderRadius: "5px",
    color: "#293036",
    fontSize: "14px",
    fontWeight: 400,
    height: "26px",
    width: "77px",
  },
  rootGrid: {
    marginTop: "10px",
  },
});

export const MonthChipSelector: React.FC<IMonthChipSelector> = (
  props: IMonthChipSelector
) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      {props.months.filter((month: DeferredMonths) => month.selected).length >
        0 && (
        <Grid
          container
          direction={"row"}
          spacing={2}
          className={classes.rootGrid}
        >
          {props.months
            .filter((month: DeferredMonths) => month.selected)
            .map((month: DeferredMonths, index: number) => {
              return (
                <Grid item key={`${month.value}-${index}`}>
                  <Chip
                    label={month.value}
                    deleteIcon={<X className={classes.chipIcon} />}
                    className={classes.chipLabel}
                    onDelete={() =>
                      props.handleDeleteSelectedMonth(month.value)
                    }
                    disabled={!get(month, "enable", true)}
                  />
                </Grid>
              );
            })}
        </Grid>
      )}
    </Grid>
  );
};
