import { IStyles } from "../../shared/interfaces/create_named_styles";

export const sliderStyle = (): IStyles => {
  return {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      boxSizing: "border-box",
      height: "100%",
    },
    modal: {
      borderRadius: "1rem",
    },
    dotStepper: {
      backgroundColor: "transparent",
      paddingTop: 0,
      "& .MuiMobileStepper-dot": {
        backgroundColor: "palette.neutral.grey5",
      },
      "& .MuiMobileStepper-dotActive": {
        backgroundColor: `palette.primary.main !important`,
        borderRadius: 5,
        width: 32,
      },
    },
    arrowButton: {
      ":disabled": {
        backgroundColor: "transparent",
        borderColor: "transparent",
        boxShadow: 0,
      },
      ":focus": {
        backgroundColor: "transparent",
        borderColor: "transparent",
        boxShadow: 0,
      },
      ":hover": {
        backgroundColor: "transparent",
        borderColor: "transparent",
        boxShadow: 0,
      },
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: 0,
    },
    redirectButton: {
      backgroundColor: "primary.light",
    },
    sliderWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    stepperWrapper: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      gap: 3,
    },
    stepperButton: { display: "none" },
  };
};
