import { makeStyles } from "@material-ui/core/styles";

export const payoutsDispersionStyle = makeStyles({
  errorAlert: {
    position: "absolute",
    top: "84px",
    left: "calc(50vw - 300px)",
    width: "45%",
    justifyContent: "center",
    backgroundColor: "#AD0C2A !important",
    color: "white !important",
    "& svg": {
      fill: "white",
    },
  },
  mainGrid: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    padding: "9px",
    backgroundColor: "white",
    borderRadius: "5px",
  },
});
