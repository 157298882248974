import React from "react";
import { useMaskedCardConditionState } from "./state/useMaskedCardConditionState";
import { Grid } from "@mui/material";
import MaskedCardField from "./MaskedCardField/MaskedCardField";
import { TextFieldCard } from "@kushki/connect-ui";
import { Labels } from "../../../../shared/infrastructure/constants/BusinessRuleConstants";
import { isOperatorEqualOrDifferent } from "../../../../utils/utilsFile";
import { GenericAddButton } from "../GenericAddButton/GenericAddButton";
import { IMaskedCardConditionProps } from "../../../../shared/infrastructure/interfaces/IMaskedCardConditionProps";

const MaskedCardCondition = (props: IMaskedCardConditionProps) => {
  const {
    cardList,
    handleAddCard,
    handleEditCard,
    handleOnChangeCard,
    setCardList,
    setValue,
    validCard,
    value,
  } = useMaskedCardConditionState(props);

  return (
    <React.Fragment>
      {cardList.map((cardValue: string, index: number) => (
        <React.Fragment key={`maskedCardField-${cardValue}-${index}`}>
          <MaskedCardField
            onEdit={(value) => handleEditCard(value, index)}
            onDelete={() =>
              setCardList(
                cardList.filter((item) => item != cardValue.toString())
              )
            }
            value={cardValue.toString()}
          />
        </React.Fragment>
      ))}

      {((isOperatorEqualOrDifferent(props.operator) && cardList.length < 1) ||
        !isOperatorEqualOrDifferent(props.operator)) && (
        <Grid item xs={4}>
          <TextFieldCard
            isDisabled={false}
            maskedCard="middle"
            onBlur={setValue}
            onChange={handleOnChangeCard}
            placeholder=""
            value={value}
          />
        </Grid>
      )}
      {validCard && (
        <Grid item xs={4}>
          <GenericAddButton
            handleOnClick={handleAddCard}
            buttonTitle={Labels.ADD_CARD}
          />
        </Grid>
      )}
    </React.Fragment>
  );
};

export default MaskedCardCondition;
