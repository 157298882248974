import { createAsyncThunk } from "@reduxjs/toolkit";
import { THUNK_PREFIXES } from "../../../shared/constants/thunk_prefixes";
import axios, { AxiosError, AxiosResponse, CancelTokenSource } from "axios";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import {
  IUserListQueryString,
  IUserListResponse,
} from "../../interfaces/users.interfaces";
import { clone, compact, defaultTo, get, isEmpty, isEqual } from "lodash";
import { StorageKeyEnum } from "../../../shared/enum/StorageKeyEnum";
import { getJwtAuth } from "../../../shared/utils/getJwtAuth_utils";
import {
  buildUsersRowSkeleton,
  usersTableRows,
} from "../../../shared/utils/BuildUsersData";
import { ITableRowProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableInfo/interfaces";
import { IGetFirstParentResponse } from "../../../../types/get_first_parent_response";
import { MessageError } from "../../../shared/enum/FileUploadEnum";
import { buildNotification } from "../../../shared/constants/snackbar";
import { NotificationTypeEnum } from "../../../shared/enum/SnackbarEnum";
import { setNotification } from "../../actions/generalData.actions";
import { RequestStatusEnum } from "../../../shared/enum/requestStatusEnum";
import { INodeInfoByMerchantId } from "../../../../types/get_node_info_by_merchantId_response";
import { SearchMerchantRequest } from "../../../../types/search_merchant_request";
import { SearchMerchantResponse } from "../../../../types/search_merchant_response";
import { EntityEnum } from "../../../shared/enum/EntityEnum";
import {
  setIsDisabledTable,
  setIsLoadingTable,
  setRowstable,
  setRowstableCustomer,
  setUserList,
} from "../../reducers/users/users.slice";
import { loadLocalStorage } from "../../../shared/utils/userListUtils";
import { RootState } from "../../store";

interface IGetUserList {
  response: IUserListResponse;
  rows: ITableRowProps[];
}
interface IGetUserListRequest {
  queryParams: IUserListQueryString;
  role?: string;
}
let cancelToken: CancelTokenSource | null = null;

export const getUserList = createAsyncThunk<IGetUserList, IGetUserListRequest>(
  THUNK_PREFIXES.GET_USERS,
  async (request: IGetUserListRequest) => {
    {
      const queryParams = loadLocalStorage(request.queryParams);

      const response = await axios.get<IUserListResponse>(
        API_ROUTES.GET_USERS,
        {
          headers: {
            Authorization: getJwtAuth(),
            "Content-Type": "application/json",
          },
          params: queryParams,
        }
      );

      const rows = usersTableRows(get(response, "data.Users"));

      return { response: response.data, rows };
    }
  }
);

export const getUserListCustomer = createAsyncThunk<void, IGetUserListRequest>(
  THUNK_PREFIXES.GET_USERS_CUSTOMER,
  async (request: IGetUserListRequest, { dispatch, getState }) => {
    {
      try {
        if (cancelToken) {
          cancelToken.cancel("Nueva petición, cancelando la antigua");
        }
        cancelToken = axios.CancelToken.source();
        dispatch(setIsDisabledTable(true));
        dispatch(setRowstable([]));
        dispatch(setRowstableCustomer([buildUsersRowSkeleton()]));
        const queryParams = loadLocalStorage(request.queryParams);

        const merchantIdList = get(queryParams, "merchantId", "").split(",");

        for (const merchantId of compact(merchantIdList)) {
          let paginationToken: string = "initial";

          while (!isEmpty(paginationToken)) {
            const response = await axios.get<IUserListResponse>(
              API_ROUTES.GET_USERS,
              {
                cancelToken: get(cancelToken, "token"),
                headers: {
                  Authorization: getJwtAuth(),
                  "Content-Type": "application/json",
                },
                params: {
                  ...queryParams,
                  merchantId,
                  ...(!isEqual(paginationToken, "initial") && {
                    paginationToken,
                  }),
                },
              }
            );

            const users = get(response, "data.Users");

            if (!isEmpty(users)) {
              const rows = usersTableRows(users);

              dispatch(setRowstableCustomer(rows));
              dispatch(setUserList(response.data));
              dispatch(setIsLoadingTable(false));
            }
            paginationToken = get(response, "data.PaginationToken", "");
          }
        }

        // @ts-ignore
        const state: RootState = getState();
        const removeSkeleton = clone(state.users.rowsTable);

        removeSkeleton.shift();

        dispatch(setRowstable(removeSkeleton));
        dispatch(setIsDisabledTable(false));
      } catch (e) {
        dispatch(setRowstableCustomer([]));
      }
    }
  }
);

export const getFirstParent = createAsyncThunk<IGetFirstParentResponse, string>(
  THUNK_PREFIXES.FIRST_PARENT,
  async (publicMerchantId: string) => {
    const response = await axios.post<IGetFirstParentResponse>(
      API_ROUTES.HIERARCHY_GET_FIRST_PARENT,
      { publicMerchantId },
      {
        headers: {
          Authorization: getJwtAuth(),
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  }
);
export const restorePassword = createAsyncThunk(
  THUNK_PREFIXES.RESTORE_PASSWORD,
  (username: string, { dispatch }) => {
    axios
      .get(API_ROUTES.RESTORE_PASSWORD, {
        headers: {
          Authorization: getJwtAuth(),
          "Content-Type": "application/json",
        },
        params: { username: username },
      })
      .then((response) => {
        if (response.status === 201) {
          dispatch(
            setNotification(
              buildNotification(NotificationTypeEnum.SUCCESS, {
                color: "success",
                message: MessageError.RESTORE_PASSWORD_SUCCES,
                variant: "simple",
                withIcon: false,
              })
            )
          );
        }
      })
      .catch((_error: AxiosError) => {
        let messageError = MessageError.GENERAL_ERROR;

        if (get(_error, "response.data.code") === "AUT016") {
          messageError = MessageError.RESTORE_PASSWORD_ERROR_AUT016;
        }

        const payload: object = JSON.parse(
          defaultTo(localStorage.getItem(StorageKeyEnum.PAYLOAD), "{}")
        );
        const isSupportSession: boolean = get(payload, "isVerify", false);

        if (isSupportSession) messageError = MessageError.ERROR_SUPPORT;

        dispatch(
          setNotification(
            buildNotification(NotificationTypeEnum.SUCCESS, {
              color: "danger",
              message: messageError,
              variant: "simple",
              withIcon: false,
            })
          )
        );
      });
  }
);

export const deleteUser = createAsyncThunk(
  THUNK_PREFIXES.DELETE_USER,
  (username: string, { dispatch }) => {
    axios
      .delete(`${API_ROUTES.DELETE_USER}/${username}`, {
        headers: {
          Authorization: getJwtAuth(),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            const queryParams: string = get(window, "location.search", "");

            window.location.assign(`/console-user/users${queryParams}`);
          }, 800);

          dispatch(
            setNotification(
              buildNotification(NotificationTypeEnum.SUCCESS, {
                color: "success",
                message: MessageError.DELETE_USER.replace(
                  "__USERNAME__",
                  username
                ),
                variant: "simple",
                withIcon: false,
              })
            )
          );
        }
      })
      .catch(() => {
        let messageError = MessageError.GENERAL_ERROR;

        const payload: object = JSON.parse(
          defaultTo(localStorage.getItem("payload"), "{}")
        );
        const isSupportSession: boolean = get(payload, "isVerify", false);

        if (isSupportSession) messageError = MessageError.ERROR_SUPPORT;

        dispatch(
          setNotification(
            buildNotification(NotificationTypeEnum.SUCCESS, {
              color: "danger",
              message: messageError,
              variant: "simple",
              withIcon: false,
            })
          )
        );
      });
  }
);

export const deleteUserMassive = createAsyncThunk(
  THUNK_PREFIXES.DELETE_USER_MASSIVE,
  (usernameList: string[], { dispatch }) => {
    const allRequest = usernameList.map((username) =>
      axios.delete(`${API_ROUTES.DELETE_USER}/${username}`, {
        headers: {
          Authorization: getJwtAuth(),
          "Content-Type": "application/json",
        },
      })
    );
    const queryParams: string = get(window, "location.search", "");

    Promise.allSettled(allRequest).then((responses) => {
      let allSuccess = true;

      for (const userResponse of responses) {
        if (userResponse.status === RequestStatusEnum.REJECTED) {
          allSuccess = false;

          dispatch(
            setNotification(
              buildNotification(NotificationTypeEnum.FAILED, {
                color: "danger",
                message: MessageError.ERROR_DELETE_USER,
                variant: "simple",
                withIcon: false,
              })
            )
          );
          break;
        }
      }
      if (allSuccess) {
        window.location.assign(`/console-user/users${queryParams}`);
      }
    });
  }
);

export const restorePasswordMassive = createAsyncThunk(
  THUNK_PREFIXES.RESTORE_PASSWORD_MASSIVE,
  (usernameList: string[], { dispatch }) => {
    const allRequest = usernameList.map((username: string) =>
      axios.get(API_ROUTES.RESTORE_PASSWORD, {
        headers: {
          Authorization: getJwtAuth(),
          "Content-Type": "application/json",
        },
        params: { username: username },
      })
    );

    Promise.allSettled(allRequest).then((responses) => {
      let allSuccess = true;

      for (const userResponse of responses) {
        if (userResponse.status === RequestStatusEnum.REJECTED) {
          allSuccess = false;
          dispatch(
            setNotification(
              buildNotification(NotificationTypeEnum.SUCCESS, {
                color: "danger",
                message: MessageError.RESTORE_PASSWORD_ERROR,
                variant: "simple",
                withIcon: false,
              })
            )
          );
          break;
        }
      }

      if (allSuccess) {
        dispatch(
          setNotification(
            buildNotification(NotificationTypeEnum.SUCCESS, {
              color: "success",
              message: MessageError.RESTORE_PASSWORD_SUCCESS_MASSIVE,
              variant: "simple",
              withIcon: false,
            })
          )
        );
      }
    });
  }
);

export const searchMerchants = createAsyncThunk(
  THUNK_PREFIXES.SEARCH_MERCHANT_NODE,
  async (query: SearchMerchantRequest) => {
    const response: AxiosResponse<SearchMerchantResponse> = await axios.post(
      API_ROUTES.SEARCH_MERCHANT_NODE_INFO,
      query,
      {
        headers: {
          Authorization: getJwtAuth(),
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  }
);
export const getNodeInfoByMerchantId = createAsyncThunk<
  INodeInfoByMerchantId,
  string
>(
  THUNK_PREFIXES.GET_NODE_INFO_BY_MERCHANTID,
  async (publicMerchantId: string, { dispatch }) => {
    dispatch(setIsLoadingTable(true));
    const response = await axios.get<INodeInfoByMerchantId>(
      `${API_ROUTES.GET_NODE_INFO_BY_MERCHANT_ID}${publicMerchantId}`,
      {
        headers: {
          Authorization: getJwtAuth(),
          "Content-Type": "application/json",
        },
      }
    );

    const searchMerchantRequest = {
      entityName: EntityEnum.BRANCH,
      filter: {
        merchantStatus: "active",
      },
      limit: 1000,
      offset: 0,
      path: get(response, "data.path"),
      strictSearch: false,
    };

    await dispatch(searchMerchants(searchMerchantRequest));

    return response.data;
  }
);
