import React from "react";
import { Box, Collapse } from "@mui/material";
import { inputFieldName } from "@shared/constants/AlarmConfigConstants";
import { variablesWrapperStyles as styles } from "@components/VariablesWrapper/VariablesWrapper.styles";
import DynamicInput from "@components/DynamicInput/DyanamicInput";
import { IVariablesWrapperProps } from "@components/VariablesWrapper/VariablesWrapper.interfaces";
import FieldWrapper from "@components/FieldWrapper/FieldWrapper";
const VariablesWrapper: React.FC<IVariablesWrapperProps> = ({
  variablesFields: { fields, remove },
  options,
  watch,
  disableFieldOptions,
  handleBehaviorSections,
}: IVariablesWrapperProps) => {
  return (
    <Box sx={styles.container}>
      {fields.map((field, index) => {
        return (
          <Box key={field.id}>
            <FieldWrapper options={options} remove={remove} index={index} />
            {
              <Collapse in={watch(inputFieldName(index), "")}>
                <DynamicInput
                  index={index}
                  disableFieldOptions={disableFieldOptions}
                  handleBehaviorSections={handleBehaviorSections}
                />
              </Collapse>
            }
          </Box>
        );
      })}
    </Box>
  );
};

export default VariablesWrapper;
