import {
  IconCalculator,
  IconCardSwipe,
  IconCash,
  IconSend,
  IconTransferCurve,
} from "@kushki/connect-ui";
import {
  PaymentMethodsEnum,
  PaymentTypesEnum,
} from "../../enums/PaymentMethodsEnum";
import { CreateProcessorRoutes } from "./CreateProcessorRoutes";
import { OptionsEnum } from "../../enums/OptionsEnums";
import React from "react";
import { useHistory } from "react-router";
import { CountriesEnum } from "../../infrastructure/countries-enum";
import { LOCAL_STORAGE_ITEMS } from "../../constants/local_storage_items";
import { routes } from "../../infrastructure/constants/routes";

export const createDropdownProcessorItem = (
  publicMerchantId: string,
  country: string
): any[] => {
  const history = useHistory();

  const setInternationalRedirectUrl = () => {
    localStorage.setItem(
      LOCAL_STORAGE_ITEMS.INTERNATIONAL_REDIRECT_URL,
      `${routes.PROCESSOR_LIST}`.replace("{merchantId}", publicMerchantId)
    );
  };

  const dropDownItems = [
    {
      categoryIcon: <IconCardSwipe />,
      categoryId: PaymentMethodsEnum.card,
      categoryItems: [
        {
          id: `${PaymentTypesEnum.PAY_IN}-${PaymentMethodsEnum.card}`,
          onSelect: () => {
            history.push(
              CreateProcessorRoutes.CREATE_CARD_PROCESSOR(publicMerchantId)
            );
          },
          text: PaymentTypesEnum.PAY_IN,
          variant: "text",
        },
      ],
      categoryName: OptionsEnum.CARD,
    },
    {
      categoryIcon: <IconCash />,
      categoryId: PaymentMethodsEnum.cash,
      categoryItems: [
        {
          id: `${PaymentTypesEnum.PAY_IN}-${PaymentMethodsEnum.cash}`,
          onSelect: () => {
            history.push(
              CreateProcessorRoutes.CREATE_CASH_PROCESSOR(publicMerchantId)
            );
          },
          text: PaymentTypesEnum.PAY_IN,
          variant: "text",
        },
        {
          id: `${PaymentTypesEnum.PAY_OUT}-${PaymentMethodsEnum.cash}`,
          onSelect: () => {
            history.push(
              CreateProcessorRoutes.CREATE_PAYOUTS_CASH_PROCESSOR(
                publicMerchantId
              )
            );
          },
          text: PaymentTypesEnum.PAY_OUT,
          variant: "text",
        },
      ],
      categoryName: OptionsEnum.PAYOUTS_CASH,
    },
    {
      categoryIcon: <IconTransferCurve />,
      categoryId: PaymentMethodsEnum.transfer,
      categoryItems: [
        {
          id: `${PaymentTypesEnum.PAY_IN}-${PaymentMethodsEnum.transfer}`,
          onSelect: () => {
            history.push(
              CreateProcessorRoutes.CREATE_TRANSFER_PROCESSOR(publicMerchantId)
            );
          },
          text: PaymentTypesEnum.PAY_IN,
          variant: "text",
        },
        {
          id: `${PaymentTypesEnum.PAY_OUT}-${PaymentMethodsEnum.transfer}`,
          onSelect: () => {
            history.push(
              CreateProcessorRoutes.CREATE_PAYOUTS_TRANSFER_PROCESSOR(
                publicMerchantId
              )
            );
          },
          text: PaymentTypesEnum.PAY_OUT,
          variant: "text",
        },
      ],
      categoryName: OptionsEnum.TRANSFER,
    },
    {
      categoryIcon: <IconSend />,
      categoryId: PaymentMethodsEnum.internationalTransfer,
      categoryItems: [
        {
          id: `${PaymentTypesEnum.INTERNATIONAL_TRANSFER}`,
          onSelect: () => {
            history.push(
              CreateProcessorRoutes.CREATE_INTERNATIONAL_TRANSFER_PROCESSOR(
                publicMerchantId
              )
            );
            setInternationalRedirectUrl();
          },
          text: PaymentTypesEnum.INTERNATIONAL_TRANSFER,
          variant: "text",
        },
      ],
      categoryName: OptionsEnum.DISPERSION,
    },
  ];

  if (country === CountriesEnum.COLOMBIA)
    dropDownItems.push({
      categoryIcon: <IconCalculator />,
      categoryId: PaymentMethodsEnum.transferSubscriptions,
      categoryItems: [
        {
          id: `${PaymentTypesEnum.PAY_IN}-${PaymentMethodsEnum.transferSubscriptions}`,
          onSelect: () => {
            history.push(
              CreateProcessorRoutes.CREATE_TRANSFER_SUBS_PROCESSOR(
                publicMerchantId
              )
            );
          },
          text: PaymentTypesEnum.PAY_IN,
          variant: "text",
        },
      ],
      categoryName: OptionsEnum.TRANSFER_SUBSCRIPTIONS,
    });

  return dropDownItems;
};
