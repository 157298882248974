import React from "react";
import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  subTitle: {
    font: "IBM Plex Sans",
    color: "#023365",
    fontStyle: "normal",
    lineHeight: "52px",
    fontSize: "20px",
    fontWeight: 500,
  },
  textTitle: {
    font: "IBM Plex Sans",
    color: "#46525C",
    fontStyle: "normal",
    lineHeight: "120%",
    fontSize: "15px",
  },
}));
export type TCardHeaderProps = {
  subTitle: string;
  textTitle: string;
  children?: React.ReactNode;
  marginBottom: number;
};

export const CardHeader: React.FC<TCardHeaderProps> = (_props) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container style={{ marginBottom: `${_props.marginBottom}px` }}>
        <Grid item xs={12} md={3} lg={3}>
          <Typography className={classes.subTitle}>
            {_props.subTitle}
          </Typography>
          <Box pt={1} mr={1}>
            <Typography
              className={classes.textTitle}
              variant={"subtitle2"}
              color={"textPrimary"}
              align="left"
            >
              {_props.textTitle}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={9} lg={9}>
          <Card>
            <CardContent>
              <Grid container spacing={1} direction="column">
                {_props.children}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
