import { IUseFilterSideBarState } from "./useFilterSideBarState.interfaces";
import React, { useEffect } from "react";
import { FilterSideBarProps } from "../FilterSideBar.interfaces";
import {
  ItemCategoryItem,
  ItemCategoryProps,
} from "../../ItemCategory/interfaces";

const useFilterSideBarState = ({
  categoryItems,
  filtersCount,
  isMinimized,
  isOpen,
  onClick,
}: FilterSideBarProps): IUseFilterSideBarState => {
  const removeDuplicatesArrayObjects = (
    array: any[],
    uniqueKey: string
  ): any[] => {
    return array.filter(
      (value, index, self) =>
        index === self.findIndex((item) => item[uniqueKey] === value[uniqueKey])
    );
  };

  const [open, setOpen] = React.useState(isOpen);

  const [minimizeAll, setMinimizeAll] = React.useState(isMinimized);

  const [list, setList] = React.useState<ItemCategoryProps[]>(categoryItems);

  const [reservedList, setReservedList] = React.useState<ItemCategoryProps[]>(
    []
  );

  const toggleDrawer = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const [filterCount, setFilterCount] = React.useState<number>(filtersCount);

  const handleOnOpenSideBar = () => {
    setReservedList(JSON.parse(JSON.stringify(list)));
    toggleDrawer(true);
  };

  const handleOpenSideBar = () => {
    handleOnOpenSideBar();
  };
  const loopAndSet = (
    categoryItemsTemp: ItemCategoryProps[],
    reservedListTemp: ItemCategoryProps[]
  ) => {
    for (let i = 0; i < categoryItemsTemp.length; i++) {
      for (let k = 0; k < categoryItemsTemp[i].items.length; k++) {
        if (reservedListTemp.length > 0) {
          categoryItemsTemp[i].items[k].selected = reservedListTemp[i].items[k]
            ? reservedListTemp[i].items[k].selected
            : false;
        }
      }
    }
    setList(categoryItemsTemp);
  };
  const handleOnCloseSideBar = () => {
    toggleDrawer(false);
    loopAndSet(categoryItems, reservedList);
  };

  const handleApplyClick = () => {
    let count: number = 0;

    const filterList: ItemCategoryProps[] = JSON.parse(JSON.stringify(list));

    filterList.map((item) => {
      item.items = item.items.filter((subItem) => subItem.selected);
    });

    onClick(filterList);
    toggleDrawer(false);
    list.map((item: ItemCategoryProps) => {
      item.items.map((subItem: ItemCategoryItem) => {
        if (subItem.selected) {
          count++;
        }
      });

      return item;
    });
    setList(list);
    setFilterCount(count);
  };

  const handleDeleteFilters = () => {
    const listCopy: ItemCategoryProps[] = JSON.parse(
      JSON.stringify(categoryItems)
    );

    listCopy.map((item: ItemCategoryProps) => {
      item.items.map((subItem: ItemCategoryItem) => {
        subItem.selected = false;

        return subItem;
      });

      return item;
    });
    setList(listCopy);
  };

  const handleMinimizeEverything = () => {
    setMinimizeAll(!minimizeAll);
    setList(
      list.map((item) => {
        item.isMinimize = minimizeAll;

        return item;
      })
    );
  };

  useEffect(() => {
    filtersCount = filterCount;
  }, [filterCount]);
  useEffect(() => {
    categoryItems = categoryItems.map((data: ItemCategoryProps) => {
      data.items = removeDuplicatesArrayObjects(data.items, "value");

      return data;
    });
    setReservedList(JSON.parse(JSON.stringify(list)));
    loopAndSet(categoryItems, JSON.parse(JSON.stringify(list)));
  }, [categoryItems]);

  useEffect(() => {
    let count: number = 0;

    toggleDrawer(false);
    list.map((item: ItemCategoryProps) => {
      item.items.map((subItem: ItemCategoryItem) => {
        if (subItem.selected) {
          count++;
        }
      });

      return item;
    });
    setFilterCount(count);
  }, []);

  useEffect(() => {
    if (open) {
      loopAndSet(categoryItems, reservedList);
    }
  }, [open]);

  return {
    filterCount,
    handleApplyClick,
    handleDeleteFilters,
    handleMinimizeEverything,
    handleOnCloseSideBar,
    handleOnOpenSideBar,
    handleOpenSideBar,
    list,
    minimizeAll,
    open,
    toggleDrawer,
  };
};

export { useFilterSideBarState };
