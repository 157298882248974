import { useMediaQuery, useTheme } from "@material-ui/core";

export interface AppState {
  isMobile: boolean;
}

export const useStateApp = (): AppState => {
  const theme = useTheme();
  return {
    isMobile: useMediaQuery(theme.breakpoints.down("xs")),
  };
};
