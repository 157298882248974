import { Merchants } from "../../types/merchants";
import { SiftScienceCredentials } from "../../types/siftscience_credentials";
import { AxiosResponse } from "axios";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { environment } from "../environments/environment";
import { ActionTypes } from "./actionTypes";
import axios from "../shared/axios-util";
import { defaultTo } from "lodash";
import { ICredentialsRequest } from "../shared/infrastructure/interfaces/ICredentialsRequest";
import { INotification } from "../shared/infrastructure/interfaces/INotification";
import { SiftScienceNotificationResultEnum } from "../shared/infrastructure/constants/SiftScienceConstants";
import { get, isEmpty } from "lodash";
import { MigrateSiftScienceResponse } from "../../types/migrate_siftscience_response";

export interface SiftScienceAction {
  type: string;
  isLoading?: boolean;
  disabled?: boolean;
  merchants?: Merchants;
  queryMerchants?: Merchants;
  isMigrated?: boolean;
  credentials?: SiftScienceCredentials;
  notification?: INotification;
  showModalCreate?: boolean;
  isVisible?: boolean;
  showButtonMigrated?: boolean;
  migrateSiftScienceResponse?: MigrateSiftScienceResponse;
}

export interface ISiftScienceState {
  isLoading?: boolean;
  disabled?: boolean;
  merchants: Merchants;
  queryMerchants: Merchants;
  isMigrated?: boolean;
  credentials: SiftScienceCredentials;
  notification?: INotification;
  showModalCreate?: boolean;
  isVisible?: boolean;
  showButtonMigrated?: boolean;
  migrateSiftScienceResponse?: MigrateSiftScienceResponse;
}

export const setShowButtonMigrated = (payload: boolean): SiftScienceAction => {
  return {
    type: ActionTypes.SET_SHOW_BUTTON_MIGRATED,
    showButtonMigrated: payload,
  };
};

export const setIsVisible = (payload: boolean): SiftScienceAction => {
  return {
    type: ActionTypes.SET_IS_VISIBLE,
    isVisible: payload,
  };
};

export const setIsLoading = (payload: boolean): SiftScienceAction => {
  return {
    type: ActionTypes.SET_IS_LOADING,
    isLoading: payload,
  };
};

export const setIsMigrated = (payload: boolean): SiftScienceAction => {
  return {
    type: ActionTypes.SET_IS_MIGRATED,
    isMigrated: payload,
  };
};

export const setShowModalCreate = (payload: boolean): SiftScienceAction => {
  return {
    type: ActionTypes.SET_SHOW_MODAL_CREATE,
    showModalCreate: payload,
  };
};

export const setDisabled = (payload: boolean): SiftScienceAction => {
  return {
    type: ActionTypes.SET_DISABLED,
    disabled: payload,
  };
};

export const setMerchantData = (payload: Merchants): SiftScienceAction => {
  return {
    type: ActionTypes.SET_MERCHANTS,
    merchants: payload,
  };
};

export const setQueryMerchant = (payload: Merchants): SiftScienceAction => {
  return {
    type: ActionTypes.SET_QUERY_MERCHANTS,
    queryMerchants: payload,
  };
};

export const setNotification = (payload: INotification): SiftScienceAction => {
  return {
    type: ActionTypes.SET_NOTIFICATION,
    notification: payload,
  };
};

export const setCredentials = (
  payload: SiftScienceCredentials
): SiftScienceAction => {
  return {
    type: ActionTypes.SET_SIFTSCIENCE_CREDENTIALS,
    credentials: payload,
  };
};

export const setMigrateSiftScienceResponse = (
  payload: MigrateSiftScienceResponse
): SiftScienceAction => {
  return {
    type: ActionTypes.SET_MIGRATE_SIFTSCIENCE_RESPONSE,
    migrateSiftScienceResponse: payload,
  };
};

export const createCredentials = (
  payload: ICredentialsRequest,
  redirectPath: string
): ThunkAction<void, ISiftScienceState, undefined, SiftScienceAction> => {
  return (
    dispatch: ThunkDispatch<ISiftScienceState, any, SiftScienceAction>
  ): void => {
    dispatch(setIsLoading(true));
    const url: string = `${environment.kushkiUrl}/rules/v1/admin/rules/siftscience`;

    axios
      .post<ICredentialsRequest>(url, {
        ...payload,
      })
      .then(() => {
        dispatch(
          setNotification({
            action:
              SiftScienceNotificationResultEnum.SIFTSCIENCE_REQUEST_SUCCESS,
            message:
              SiftScienceNotificationResultEnum.SIFTSCIENCE_REQUEST_MIGRATE_SUCCESS,
            open: true,
            type: "success",
          })
        );
        dispatch(setIsLoading(false));
        window.location.href = redirectPath;
      })
      .catch((e: string) => {
        dispatch(
          setNotification({
            action: SiftScienceNotificationResultEnum.SIFTSCIENCE_REQUEST_ERROR,
            message: defaultTo(
              get(e, "response.data.message"),
              SiftScienceNotificationResultEnum.DEFAULT_MESSAGE_SAVE_CREDENTIALS
            ),
            open: true,
            type: "error",
          })
        );
        dispatch(setIsLoading(false));
      });
  };
};

export const getCredentials = (
  merchantId: string
): ThunkAction<void, ISiftScienceState, undefined, SiftScienceAction> => {
  return (
    dispatch: ThunkDispatch<ISiftScienceState, any, SiftScienceAction>
  ): void => {
    dispatch(setIsLoading(true));
    axios
      .get<SiftScienceCredentials>(
        `${environment.kushkiUrl}/rules/v1/monitor/siftscience/${merchantId}`
      )
      .then((axios_response: AxiosResponse<SiftScienceCredentials>) => {
        const isMigrated = defaultTo(
          axios_response.data.siftCredentials.migrated,
          false
        );

        dispatch(setCredentials(axios_response.data));
        dispatch(setIsVisible(true));

        if (isMigrated) {
          dispatch(setIsMigrated(true));
          dispatch(setDisabled(false));
        } else {
          dispatch(setIsMigrated(false));
          dispatch(setDisabled(true));
        }

        if (isEmpty(axios_response.data.siftCredentials)) {
          dispatch(setShowModalCreate(true));
        } else {
          dispatch(setShowModalCreate(false));
        }

        dispatch(setIsLoading(false));
      })
      .catch((e: string) => {
        dispatch(
          setNotification({
            action: SiftScienceNotificationResultEnum.SIFTSCIENCE_REQUEST_ERROR,
            message: defaultTo(
              get(e, "response.data.message"),
              SiftScienceNotificationResultEnum.DEFAULT_MESSAGE_ERROR_GET_CREDENTIALS
            ),
            open: true,
            type: "error",
          })
        );
        dispatch(setIsLoading(false));
      });
  };
};

export const getMerchants = (payload: {
  offset: number;
  text: string;
  pageSize: number;
}): ThunkAction<void, ISiftScienceState, undefined, SiftScienceAction> => {
  return (
    dispatch: ThunkDispatch<ISiftScienceState, any, SiftScienceAction>
  ): void => {
    let body: { limit: number; offset: number; text?: string } = {
      offset: payload.offset,
      limit: payload.pageSize,
    };
    if (Boolean(payload.text)) body = { ...body, text: payload.text };
    dispatch(setIsLoading(true));
    axios
      .post<Merchants>(
        `${environment.kushkiUrl}/analytics/v1/admin/merchant/get`,
        body
      )
      .then((axios_response: AxiosResponse<Merchants>) => {
        if (Boolean(payload.text))
          dispatch(setQueryMerchant(axios_response.data));
        else dispatch(setMerchantData(axios_response.data));
        dispatch(setIsLoading(false));
      })
      .catch((e: string) => {
        dispatch(
          setNotification({
            action: SiftScienceNotificationResultEnum.SIFTSCIENCE_REQUEST_ERROR,
            message: defaultTo(
              get(e, "response.data.message"),
              SiftScienceNotificationResultEnum.DEFAULT_MESSAGE_ERROR_GET_MERCHANTS
            ),
            open: true,
            type: "error",
          })
        );
        dispatch(setIsLoading(false));
      });
  };
};

export const migrateSiftScience = (
  merchantId: string
): ThunkAction<void, ISiftScienceState, undefined, SiftScienceAction> => {
  return (
    dispatch: ThunkDispatch<ISiftScienceState, any, SiftScienceAction>
  ): void => {
    const url: string = `${environment.kushkiUrl}/rules/v1/monitor/migrate-sift`;

    axios
      .post<MigrateSiftScienceResponse>(url, {
        merchantId,
      })
      .then((axios_response: AxiosResponse<MigrateSiftScienceResponse>) => {
        const response: MigrateSiftScienceResponse = axios_response.data;

        dispatch(
          setNotification({
            action:
              SiftScienceNotificationResultEnum.SIFTSCIENCE_REQUEST_SUCCESS,
            message:
              SiftScienceNotificationResultEnum.MIGRATE_SIFTSCIENCE_SUCCESS,
            open: true,
            type: "success",
          })
        );
        dispatch(setMigrateSiftScienceResponse(response));
      })
      .catch((_: object) => {
        dispatch(
          setNotification({
            action: SiftScienceNotificationResultEnum.SIFTSCIENCE_REQUEST_ERROR,
            message:
              SiftScienceNotificationResultEnum.MIGRATE_SIFTSCIENCE_MESSAGE,
            open: true,
            type: "error",
          })
        );
      });
  };
};
