import { useFieldArray, useForm, UseFormMethods } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import { IConditionItem } from "../../../shared/interfaces/condition_item";
import {
  createRule,
  getAtomicId,
  getConfiguration,
  getPayoutsRules,
  getProcessors,
  getRuleById,
  updateRule,
} from "../../../store/thunks/app/app.thunks";
import {
  getBasicMerchantInformation,
  getMerchantInfo,
  isMassiveRules,
  upsertBusinessRuleLocalStorage,
} from "../../../shared/utils/localStorage";
import { defaultTo, get, isEmpty } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { FormFieldEnum } from "../../../shared/enums/FormFieldEnum";
import { RULE_INITIAL_STATE } from "../../../shared/utils/initialStates";
import { useNavigate, useParams } from "react-router";
import { Rule } from "../../../../types/get_payouts_rules_response";
import { BusinessRuleStatus } from "../../../shared/constants/BusinessRuleConstants";
import { LabelEnum, ModalSimpleData } from "../../../shared/enums/LabelEnum";
import {
  dismissModalInfo,
  setPrefixAtomicId,
  showModalInfo,
} from "../../../store/reducers/app/app";
import {
  enableEditRule,
  ruleAdded,
  saveEditDraftRule,
  saveRepeatedRule,
} from "../../../shared/constants/SaveRuleModal";
import {
  deleteDuplicatedElements,
  existPathBusinessRuleList,
  formatConditions,
  formatConditionsToEdit,
  getProcessorById,
} from "../../../shared/utils/utilsFile";
import { IRepeatedConditions } from "../../../shared/interfaces/IRepeatedConditions";
import { RootState } from "../../../store/store";
import { IUseCreateBusinessRuleState } from "../../../shared/interfaces/IUseCreateBusinessRuleState";

export const useCreateBusinessRuleState = (): IUseCreateBusinessRuleState => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const merchantInformation = getBasicMerchantInformation();
  const merchantId: string = ((id: string) => {
    return isEmpty(id) ? getMerchantInfo().publicMerchantId : id;
  })(merchantInformation.publicMerchantId);
  const { ruleId } = useParams();
  const { redirectPath } = getBasicMerchantInformation();

  const {
    loadingModal,
    modalInfo,
    editRule,
    payoutsRules,
    processorList,
    prefixAtomicId,
  } = useAppSelector((state: RootState) => ({
    ...state.app,
  }));

  const [, setSearchTextProcessor] = useState<string>("");
  const [newRule, setNewRule] = useState(RULE_INITIAL_STATE);
  const isMassive: boolean = useMemo(isMassiveRules, []);

  useEffect(() => {
    if (!isEmpty(merchantId)) {
      dispatch(
        getProcessors({
          merchantId,
        })
      );

      dispatch(
        getPayoutsRules({
          limit: 1000,
          merchantId,
          offset: 0,
        })
      );

      if (isMassive && isEmpty(ruleId)) dispatch(getAtomicId());

      dispatch(getConfiguration());
    }
  }, [merchantId]);

  useEffect(() => {
    if (!isEmpty(merchantId) && !isEmpty(ruleId))
      dispatch(getRuleById({ id: ruleId!, isMassive }));
  }, [merchantId]);

  const form: UseFormMethods<Rule> = useForm<Rule>({
    defaultValues: {
      ...RULE_INITIAL_STATE,
    },
    mode: "all",
    shouldUnregister: false,
  });

  useEffect(() => {
    if (!isEmpty(ruleId)) {
      const rule: Rule = {
        ...editRule,
        conditions: formatConditionsToEdit(editRule.conditions),
      };

      if (isMassive) {
        const regexp: string = "EM-([0-9]{3})";
        const matches: RegExpMatchArray | null = rule.name.match(regexp);
        const prefix: string = get(matches, "0", "");
        const nameWithoutPrefix: string = rule.name.replace(prefix, "").trim();

        rule.name = nameWithoutPrefix;
        dispatch(setPrefixAtomicId(prefix));
      }

      form.reset({ ...rule });
    }
  }, [editRule]);

  const { fields, prepend, remove } = useFieldArray({
    control: form.control,
    name: FormFieldEnum.CONDITIONS,
  });

  const handleEnterSearchTextProcessors = (key: string): void => {
    if (key !== "Enter") return;
    setSearchTextProcessor("");
  };

  const handleSetSearchTextProcessors = (searchText: string): void => {
    setSearchTextProcessor(searchText);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const createRuleRequest = (
    form: UseFormMethods<Rule>,
    status: BusinessRuleStatus,
    ruleId?: string
  ): Rule => {
    const formData: Rule = form.getValues();

    const conditions: any[] = get(formData, "conditions", []);

    return {
      action: "route",
      conditions: formatConditions(conditions),
      id: ruleId ? ruleId : "",
      merchantId,
      name: formData.name,
      processorId: formData.processorId,
      processorName: defaultTo(
        getProcessorById(formData.processorId, processorList!).processorName,
        ""
      ),
      status,
      type: LabelEnum.BUSINESS,
    };
  };

  const getRepeatedConditions = (rule: Rule): IRepeatedConditions => {
    const repeatedConditions: Array<string> = [];
    const repeatedSource: Array<string> = [];

    rule.conditions.forEach((currentCondition) => {
      payoutsRules.data.forEach((payoutRule) => {
        payoutRule.conditions.forEach((condition) => {
          if (currentCondition.field == condition.field) {
            repeatedConditions.push(condition.field);
            repeatedSource.push(payoutRule.name);
          }
        });
      });
    });

    return {
      conditions: deleteDuplicatedElements(repeatedConditions),
      sources: deleteDuplicatedElements(repeatedSource),
    };
  };

  const verifyRepeatedConditions = (rule: Rule): boolean => {
    setNewRule(rule);
    const repeatedConditions = getRepeatedConditions(rule);
    const isRepeatedConditions = !isEmpty(repeatedConditions.conditions);

    if (isRepeatedConditions && isEmpty(ruleId)) {
      dispatch(
        showModalInfo(
          saveRepeatedRule(
            repeatedConditions.conditions.join(","),
            repeatedConditions.sources.join(",")
          )
        )
      );

      return true;
    }

    return false;
  };

  const handleRedirectPath = () => {
    if (isEmpty(redirectPath) || existPathBusinessRuleList()) {
      navigate(-1);

      return;
    }
    navigate(redirectPath);
  };

  const handleSaveRuleEnable = async () => {
    const status: BusinessRuleStatus = BusinessRuleStatus.ENABLE;
    const ruleRequest: Rule = createRuleRequest(form, status);
    const isRepeated: boolean = verifyRepeatedConditions(ruleRequest);

    if (isMassive) {
      if (!isEmpty(ruleId)) {
        upsertBusinessRuleLocalStorage({
          ...ruleRequest,
          createdAt: editRule.createdAt,
          id: ruleId!,
          name: `${prefixAtomicId} ${ruleRequest.name}`,
        });
        handleRedirectPath();
      } else {
        upsertBusinessRuleLocalStorage({
          ...ruleRequest,
          createdAt: Date.now(),
          id: Date.now().toString(),
          name: `${prefixAtomicId} ${ruleRequest.name}`,
        });
        dispatch(showModalInfo(ruleAdded));
      }

      return;
    }
    if (!isRepeated) {
      if (!isEmpty(ruleId) && !isEmpty(editRule)) {
        const ruleStatus: string = get(
          editRule,
          "status",
          BusinessRuleStatus.ENABLE
        );

        switch (ruleStatus) {
          case BusinessRuleStatus.DRAFT:
            dispatch(showModalInfo(enableEditRule));
            break;

          case BusinessRuleStatus.ENABLE:
            await dispatch(
              updateRule(createRuleRequest(form, ruleStatus, ruleId))
            );
            handleRedirectPath();
            break;
          case BusinessRuleStatus.DISABLE:
            await dispatch(
              updateRule(
                createRuleRequest(form, BusinessRuleStatus.ENABLE, ruleId)
              )
            );
            handleRedirectPath();
            break;
          default:
            dispatch(createRule(ruleRequest));
            handleRedirectPath();
            break;
        }

        return;
      }

      await dispatch(createRule(ruleRequest));
      await dispatch(
        getPayoutsRules({
          limit: 10000,
          merchantId,
          offset: 0,
        })
      );
      dispatch(showModalInfo(ruleAdded));
    }
  };

  const handlePrimaryButtonRuleModal = async () => {
    switch (modalInfo!.action) {
      case ModalSimpleData.CREATE_DRAFT:
        dispatch(dismissModalInfo());
        if (isEmpty(ruleId)) {
          await dispatch(createRule(newRule));
        } else {
          await dispatch(
            updateRule(
              createRuleRequest(form, BusinessRuleStatus.DRAFT, ruleId)
            )
          );
        }
        handleRedirectPath();
        break;
      case ModalSimpleData.CREATE:
        dispatch(dismissModalInfo());
        await dispatch(createRule(newRule));
        handleRedirectPath();
        break;
      case ModalSimpleData.EDIT:
        dispatch(dismissModalInfo());
        await dispatch(
          updateRule(createRuleRequest(form, BusinessRuleStatus.ENABLE, ruleId))
        );
        handleRedirectPath();
        break;
      default:
        form.reset(RULE_INITIAL_STATE);
        setNewRule(RULE_INITIAL_STATE);
        dispatch(dismissModalInfo());
        navigate(0);
        break;
    }
  };

  const handleSecondaryButtonRuleModal = () => {
    switch (modalInfo!.buttonSecondary) {
      case ModalSimpleData.MODAL_BTN_END:
        dispatch(dismissModalInfo());
        handleRedirectPath();
        break;
      default:
        dispatch(dismissModalInfo());
        break;
    }
  };

  const handleSaveRuleDraft = () => {
    const status = BusinessRuleStatus.DRAFT;
    const rule = createRuleRequest(form, status);
    const isRepeated = verifyRepeatedConditions(rule);

    if (!isRepeated) {
      dispatch(showModalInfo(saveEditDraftRule));

      return;
    }
  };

  const handleAddCondition = (): void => {
    const emptyObject: IConditionItem = {
      field: "",
      operator: "",
      value: [],
    };

    prepend({ ...emptyObject });
  };

  const handleRemoveCondition = (index: number): void => {
    remove(index);
  };

  return {
    actions: {
      handleAddCondition,
      handleBack,
      handleEnterSearchTextProcessors,
      handlePrimaryButtonRuleModal,
      handleRedirectPath,
      handleRemoveCondition,
      handleSaveRuleDraft,
      handleSaveRuleEnable,
      handleSecondaryButtonRuleModal,
      handleSetSearchTextProcessors,
    },
    editRule,
    fields,
    form,
    isMassive,
    loadingModal,
    saveRuleModal: modalInfo,
  };
};
