enum FilterTitle {
  Frequency = "Frecuencia",
  Status = "Estado",
  Constitutional_Country = "País de constitución (branch/merchant)",
  Constitutional_Country_History = "País de constitución",
  Country = "País de operación (branch/merchant)",
  CountryAlarm = "País de operación",
  Category = "Categoría (branch/merchant)",
  Category_History = "Categoría del comercio",
  Title = "Filtrar por",
  Created = "Fecha del creación del branch",
  Variables = "Variables",
  Select_Constitutional_Country = "Selecciona uno o varios...",
}

enum FilterPlaceHolder {
  Constitutional_Country = "Selecciona uno o varios...",
}

enum RangeDatePickerEnum {
  RangeDateLabel = "Buscar por fecha de creación",
  RangeDateLabelActiveAlarms = "Buscar por fecha",
}

export { RangeDatePickerEnum, FilterPlaceHolder, FilterTitle };
