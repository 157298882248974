import { Typography } from "@mui/material";
import React from "react";
import { ITransactionHistoryInfoProps } from "./TransactionHistoryInfo.interfaces";
import { styles } from "./TransactionHistoryInfo.styles";

const TransactionHistoryInfo = ({
  entryName,
  entryType,
  entryNotes,
}: ITransactionHistoryInfoProps) => {
  return (
    <>
      <Typography variant="h6" sx={styles.entryTitle}>
        {entryName}
      </Typography>
      {entryType && (
        <Typography variant="caption" sx={styles.entryBody}>
          {entryType}
        </Typography>
      )}
      {entryNotes && (
        <Typography variant="caption" sx={styles.entryBody}>
          {entryNotes}
        </Typography>
      )}
    </>
  );
};

export default TransactionHistoryInfo;
