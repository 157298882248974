import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import { ResendRefundModalStyles } from "./ResendRefundModal.styles";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, useForm } from "react-hook-form";
import { RULES_INPUTS } from "../../../shared/constants/rules_inputs";
import { LoadingButton } from "@mui/lab";
import { useResendRefundModal } from "./useResendRefundModal/useResendRefundModal";
import { useKshRedux } from "../../../store/hooks/hooks";

interface IResendRefundModal {
  isOpen: boolean;
}

const ResendRefundModal: FC<IResendRefundModal> = ({
  isOpen,
}: IResendRefundModal) => {
  const {
    store: { refundModalData },
  } = useKshRedux();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      email: refundModalData.email,
    },
  });

  const { checked, disableInput, handleClose, loading, onSubmit, setChecked } =
    useResendRefundModal(setValue);

  return (
    <Dialog onClose={handleClose} open={isOpen} maxWidth="md">
      <Grid sx={ResendRefundModalStyles.main}>
        <DialogTitle sx={ResendRefundModalStyles.titleContent}>
          <Typography variant={"h6"} sx={ResendRefundModalStyles.title}>
            Reenviar solicitud de reembolsos
          </Typography>
          <IconButton
            onClick={handleClose}
            sx={{ color: "#293036" }}
            data-testid="cancelEmail"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <Controller
                  name="email"
                  control={control}
                  rules={RULES_INPUTS.email}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl
                      disabled={disableInput}
                      error={!!errors.email}
                      fullWidth
                    >
                      <OutlinedInput
                        {...field}
                        fullWidth
                        placeholder="Ingresa tu correo"
                        sx={ResendRefundModalStyles.emailInput}
                        inputProps={{ "data-testid": "inputEmail" }}
                      />
                      {!!errors.email && (
                        <FormHelperText>{errors.email.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <LoadingButton
                  loading={loading}
                  loadingPosition="start"
                  data-testid="buttonEmail"
                  type="submit"
                  sx={ResendRefundModalStyles.buttonResend}
                >
                  Reenviar
                </LoadingButton>
              </Grid>
              <Grid item xs={12} sx={{ mt: 1 }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        data-testid="checkboxEmail"
                        onChangeCapture={() => {
                          setChecked(!checked);
                        }}
                        checked={checked}
                      />
                    }
                    label="Enviar solicitud al correo registrado"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions sx={{ height: "48px" }}>
          <Button
            size="medium"
            variant="text"
            sx={ResendRefundModalStyles.buttonCancel}
            onClick={handleClose}
            data-testid="cancelButtonEmail"
          >
            {"Cancelar"}
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export default ResendRefundModal;
