import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { TransactionStatusEnum } from "../../../shared/infrastructure/constants/transaction-status-enum";
import { PaymentMethodEnum } from "../../../shared/infrastructure/constants/payment-method-enum";
import { SecurityWrapperEnum } from "../../../shared/infrastructure/SecurityWrapperEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "#F7FAFC",
    marginTop: "10px",
    paddingBottom: "10px",
    paddingTop: "10px",
    paddingLeft: "25px",
  },
  button: {
    background: "#F7FAFC",
    color: "#293036",
    paddingTop: "10px",
    paddingBottom: "10px",
    fontSize: "15px",
    fontWeight: 501,
  },
}));

export interface ITransactionOption {
  transactionReference: string;
  paymentMethod: string;
  status: string;
  handlerDownloadReceipt: (payload: string) => void;
}

export const TransactionOption: React.FC<any> = (props: ITransactionOption) => {
  const classes = useStyles();
  const loadingReceipt = useSelector(
    (state: IAppState) => state.loadingReceipt
  );
  return (
    <React.Fragment>
      {props.paymentMethod === PaymentMethodEnum.TRANSFER &&
        props.status === TransactionStatusEnum.APPROVAL && (
          <Grid container direction="row" className={classes.container}>
            <Grid item>
              <ComponentSecurityWrapper
                componentId={SecurityWrapperEnum.PAYMENT_RECEIPE_DOWNLOAD}
              >
                <Button
                  variant={"outlined"}
                  className={classes.button}
                  endIcon={loadingReceipt && <CircularProgress size={20} />}
                  disabled={loadingReceipt}
                  onClick={() =>
                    props.handlerDownloadReceipt(props.transactionReference)
                  }
                >
                  Descargar recibo
                </Button>
              </ComponentSecurityWrapper>
            </Grid>
          </Grid>
        )}
    </React.Fragment>
  );
};
