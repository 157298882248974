import { Input, InputProps } from "@mui/material";
import React, { FC } from "react";
import { searchStyles as styles } from "./SearchInput.styles";

const SearchInput: FC<InputProps> = ({
  sx,
  placeholder = "",
  ...rest
}: InputProps) => {
  const inputStyle = { ...styles.input, ...sx };

  return (
    <Input
      sx={inputStyle}
      disableUnderline
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default SearchInput;
