import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { GetComponentsResponse } from "../../../../types/get_components_response";
import { GetAllModulesResponse } from "../../../../types/get_all_modules_response";

export const GetComponents = createAsyncThunk<
  {
    components: GetComponentsResponse[];
    currentModuleRole: string;
  },
  { moduleId?: string }
>("components/getComponents", async ({ moduleId }) => {
  const response = await axios.get<GetComponentsResponse[]>(
    `${API_ROUTES.GET_COMPONENTS_BY_MODULE}${moduleId}${API_ROUTES.COMPONENTS}`
  );
  const module = response.data.filter((item) => item.type === "MODULE")[0];

  return {
    components: (response.data as GetComponentsResponse[])
      .filter((single) => single.type === "COMPONENT")
      .sort((a, b) => a.id.localeCompare(b.id)),
    currentModuleRole: module.roles[0],
  };
});

export const createUpdateComponent = createAsyncThunk<
  {
    isNew: boolean;
    body: GetAllModulesResponse;
    components: GetComponentsResponse[];
  },
  { isNew: boolean; body: GetAllModulesResponse }
>("components/createUpdateComponent", async (data) => {
  await axios.post<boolean>(API_ROUTES.POST_ALL_MODULES, data.body);
  const response = await axios.get<GetComponentsResponse[]>(
    `${API_ROUTES.GET_COMPONENTS_BY_MODULE}${data.body.parent}${API_ROUTES.COMPONENTS}`
  );

  return {
    ...data,
    components: (response.data as GetComponentsResponse[])
      .filter((single) => single.type === "COMPONENT")
      .sort((a, b) => a.id.localeCompare(b.id)),
  };
});

export const UpdateStatus = createAsyncThunk<
  boolean,
  { componentId: string; componentUpdate: object }
>("components/updateStatus", async ({ componentId, componentUpdate }) => {
  const response = await axios.put<boolean>(
    API_ROUTES.UPDATE_MODULES(componentId),
    componentUpdate
  );

  return response.data;
});
