import React from "react";
import { Box, Grid } from "@mui/material";
import Timeline from "./Timeline";
import TransactionHistoryInfo from "./TransactionHistoryInfo/TransactionHistoryInfo";
import TransactionHistoryDate from "./TransactionHistoryDate/TransactionHistoryDate";
import { styles } from "./TimeLineTab.styles";
import { ITimeLineTabProps } from "./TimeLineTab.interfaces";
import useTimeLineTabState from "./state/useTimeLineTabState";

const TimeLineTab = ({ transaction }: ITimeLineTabProps) => {
  const { items } = useTimeLineTabState({
    historicalItems: transaction.historical,
  });

  return (
    <Box sx={styles.container}>
      <Timeline>
        {items.map((item, index) => {
          const Icon = item.icon;

          return (
            <Timeline.Item key={index}>
              <Timeline.ItemSpot>
                <Timeline.Connector invisible={index === 0} />
                <Timeline.ItemSpotIcon>
                  {Icon && <Icon sx={styles.timeLineIcon} />}
                </Timeline.ItemSpotIcon>
                <Timeline.Connector />
              </Timeline.ItemSpot>
              <Timeline.ItemContent>
                <Grid container spacing={1} sx={styles.itemContentRow}>
                  <Grid item xs={6}>
                    <TransactionHistoryInfo
                      entryName={item.title}
                      entryType={item.type}
                      entryNotes={item.notes}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TransactionHistoryDate
                      entryDate={item.date}
                      entryTime={item.time}
                    />
                  </Grid>
                </Grid>
              </Timeline.ItemContent>
            </Timeline.Item>
          );
        })}
      </Timeline>
    </Box>
  );
};

export default TimeLineTab;
