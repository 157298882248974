import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const selectNodeInputStyles = createNamedStyles({
  select: {
    width: "185px",

    "& .MuiOutlinedInput-root .MuiSelect-select.MuiSelect-outlined": {
      color: "#6D7781",
    },
  },
});
