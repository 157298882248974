import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import React, { FC } from "react";
import { IInputsFormProps } from "../InputsFormProps.interfaces";
import { ERROR_MESSAGES } from "../../../shared/constants/inputFormConstants";
import { get } from "lodash";

const EmailInput: FC<IInputsFormProps> = ({
  name,
  control,
  controlName = name,
  errors,
  label,
  defaultValue = "",
  onBlur,
  style,
  sx = {},
  isRequired,
}: IInputsFormProps): JSX.Element => {
  return (
    <Controller
      name={name}
      rules={{
        pattern: {
          message: ERROR_MESSAGES.invalid_email,
          value: new RegExp("^[\\w-.]+@([\\w-]+\\.)+[\\w-]+$"),
        },
        ...(isRequired && { required: ERROR_MESSAGES.required }),
      }}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField
          {...field}
          label={label}
          variant="outlined"
          size="small"
          fullWidth
          error={get(errors, controlName) !== undefined}
          helperText={get(errors, controlName)?.message ?? ""}
          onBlurCapture={onBlur}
          style={style}
          sx={sx}
        />
      )}
    />
  );
};

export default EmailInput;
