import React from "react";
import { Control, Controller } from "react-hook-form";
import InputsForm from "./InputsForm";
import { IMultipleFormValues } from "../../shared/constants/initial_state_legal_details";
import { CountryEnum } from "../../shared/countryEnum";

type Props = {
  control: Control;
  inputComponent: string;
  name: string;
  label: string;
  rules?: object;
  isDisable?: boolean;
  onChange?: any;
  values?: IMultipleFormValues[];
  country?: CountryEnum;
  constitutionalCountry?: CountryEnum;
};

const FormControl: React.FC<Props> = ({
  control,
  name,
  label,
  rules = {},
  isDisable = false,
  inputComponent,
  onChange,
  values,
  country,
  constitutionalCountry,
}: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue=""
      render={({ field, fieldState: { error } }) => (
        <InputsForm
          inputComponent={inputComponent}
          inputProps={{
            constitutionalCountry,
            control,
            country,
            error,
            field,
            isDisable,
            label,
            options: values,
          }}
          onChange={onChange}
        />
      )}
    />
  );
};

export default FormControl;
