import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const informationFormStyles = createNamedStyles({
  boxContent: {
    display: "flex",
    height: "45%",
    alignItems: "center",
    mt: (theme) => theme.spacing(12),
    maxWidth: (theme) => theme.spacing(60),
    width: "34%",
  },
  title: {
    fontSize: (theme) => theme.spacing(3.25),
    pb: 6,
    fontWeight: 600,
  },
  subtitle: {
    alignSelf: "self-start",
    fontSize: (theme) => theme.spacing(2),
    fontWeight: 500,
    pb: 5,
  },
  detail: {
    alignSelf: "self-start",
    fontSize: (theme) => theme.spacing(2),
    fontWeight: 500,
    pb: 5,
  },
  boxUl: {
    color: "#023365",
    fontSize: (theme) => theme.spacing(2),
    fontWeight: 400,
    m: 0,
    p: (theme) => theme.spacing(0, 0, 7.5, 2.8),
  },
  boxSelect: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    width: 1,
  },
  nextButton: {
    fontSize: (theme) => theme.spacing(1.75),
    h: (theme) => theme.spacing(4),
    mt: (theme) => theme.spacing(6.25),
    p: (theme) => theme.spacing(1, 3.75, 1, 3.75),
  },
});
