import { IconPlus } from "@kushki/connect-ui";
import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { DeferredData } from "./Deferred.data";
import { useDeferredStyles as styles } from "./Deferred.styles";
import { DeferredOptions } from "../../../types/get_deferred_admin_merchant_response";
import { get, isEmpty } from "lodash";
import { DeferredList } from "./DeferredList/DeferredList";
import { SectionContainer } from "../SectionContainer/SectionContainer";
import { DeferredInformation } from "./DeferredList/DeferredInformation/DeferredInformation";
import { DeferredSettingsPE } from "./Setting/PE/DeferredSettingPE";
import { CountriesEnum } from "../../shared/infrastructure/enums/CountriesEnum";
import { isCentralizedBranchDeferred } from "../../utils/branch/branch-util";
import { DeleteModalInfo } from "../BusinessRules/BusinessRules.interfaces";
import { DeleteModal } from "./DeferredList/state/DeleteProcessorModal/DeleteProcessorModal";
import { NoDecentralizedDeferred } from "./NoDecentralizedDeferred/NoDecentralizedDeferred";
import { ButtonVariantEnum } from "../../shared/enums/ButtonStylesEnum";
import { useDeferredState } from "./state/useDeferredState";
import { AlertPanelType } from "../AlertPanel/AlertPanel.data";
import { AlertPanel } from "../AlertPanel/AlertPanel";

export interface DeferredProps {
  deferredList: DeferredOptions;
  deleteModalInfo: DeleteModalInfo;
}

export interface IEmptyList {
  country: string;
  isMassiveBranches: boolean;
}

const EmptyList: React.FC<IEmptyList> = (props: IEmptyList) => {
  return (
    <Typography color="text.grey" variant={"body1"}>
      {!props.isMassiveBranches ? (
        <AlertPanel
          text={DeferredData.EmptyDeferredMessage}
          type={AlertPanelType.info}
          country={props.country}
        />
      ) : (
        DeferredData.AddMassiveDeferred
      )}
    </Typography>
  );
};

export const Deferred: React.FC<DeferredProps> = ({
  deferredList,
  deleteModalInfo,
}) => {
  const {
    areAllDecentralized,
    availableCards,
    country,
    deferredConfig,
    handleNavigate,
    haveCardPaymetMethodProcessors,
    isMassiveBranches,
    nodeInfo,
    processor,
    showMoreDeferred,
  } = useDeferredState({
    deferredList,
  });

  return isMassiveBranches && !areAllDecentralized ? (
    <NoDecentralizedDeferred />
  ) : (
    <SectionContainer
      titleSection={DeferredData.HeaderDeferred}
      alertDeferredProcessor={
        isMassiveBranches && country !== CountriesEnum.PERU
      }
    >
      {country !== CountriesEnum.PERU ? (
        <Grid container justifyContent={"space-between"}>
          <Grid
            xs={6}
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={styles.gridList}
          >
            {isEmpty(deferredList) && (
              <EmptyList
                isMassiveBranches={isMassiveBranches}
                country={country}
              />
            )}
          </Grid>
          {((!isCentralizedBranchDeferred(nodeInfo) &&
            haveCardPaymetMethodProcessors(processor, country)) ||
            isMassiveBranches) && (
            <Grid
              item
              paddingBottom={"30px"}
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              <Button
                onClick={handleNavigate}
                color="secondary"
                variant={ButtonVariantEnum.Outlined}
                startIcon={<IconPlus />}
              >
                {DeferredData.AddNewDeferredMessage}
              </Button>
            </Grid>
          )}
          <DeferredInformation showMoreDeferred={showMoreDeferred} />
          <Grid item xs={12}>
            {get(deferredList, "length", 0) > 0 && (
              <DeferredList
                deferredList={deferredList}
                deferredConfig={deferredConfig}
                nodeInfo={nodeInfo}
                deleteModalInfo={deleteModalInfo}
              />
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <DeferredSettingsPE acceptCreditCards={availableCards} />
        </Grid>
      )}
      <DeleteModal deleteModalInfo={deleteModalInfo} />
    </SectionContainer>
  );
};
