/* istanbul ignore file */
import { useFormContext, useWatch } from "react-hook-form";
import { get } from "lodash";
import { TWebhookForm } from "../../../containers/WebhookFormIndex/state/useWebhookForm";
import {
  EventList,
  PAYMENT_METHODS_EVENTS,
  ServiceOptionEnum,
  WebhookEventsEnum,
  WebhookServiceEnum,
} from "../../../shared/infrastructure/constants/OptionsEnum";
import React, { useState } from "react";
import { ISelectedProduct } from "../../../shared/infrastructure/interfaces/ISelectedProduct";
import {
  PaymentUrlWithStatus,
  WebhookEvent,
} from "../../../shared/infrastructure/interfaces/IWebhookForm";
import { findIndex } from "lodash";
import { useDispatch } from "react-redux";
import { setPreauthEnable } from "../../../store/actionCreators";

interface IUseWebhookEventsCheckList {
  options: string[];
  handler: {
    handleStatus: (
      event: React.ChangeEvent<HTMLInputElement>,
      product: ISelectedProduct
    ) => void;
    handleCheck: (product: ISelectedProduct) => boolean;
    handleSelectAllEvents: () => void;
    handleAreAllEventsSelected: () => boolean;
    hasEvents: (record: object) => boolean;
  };
  modalPreath: {
    openModalPreauth: boolean;
    handleOpenModal: (open: boolean) => void;
    clearEventsExceptPreauth: () => void;
    handleCloseModal: () => void;
  };
}

const useWebhookEventsCheckList = (): IUseWebhookEventsCheckList => {
  const { getValues, setValue } = useFormContext<TWebhookForm>();
  const options: string[] = useWatch({
    name: "options",
  }) as string[];

  const events: string[] = useWatch({
    name: "events",
  }) as string[];

  const dispatch = useDispatch();

  const [openModalPreauth, setOpenModalPreauth] = useState<boolean>(false);

  const checkOptionChargeBack = (options: string[]) => {
    if (
      options.includes(ServiceOptionEnum.CHARGEBACK) &&
      !options.includes(ServiceOptionEnum.CHARGEBACK_VOID)
    ) {
      setValue("options", [...options, ServiceOptionEnum.CHARGEBACK_VOID]);
      options.push(ServiceOptionEnum.CHARGEBACK_VOID);
    }

    if (
      !options.includes(ServiceOptionEnum.CHARGEBACK) &&
      options.includes(ServiceOptionEnum.CHARGEBACK_VOID)
    ) {
      setValue("options", [...options, ServiceOptionEnum.CHARGEBACK]);
      options.push(ServiceOptionEnum.CHARGEBACK);
    }

    return options;
  };

  const handleOpenModal = (open: boolean) => {
    setOpenModalPreauth(open);
  };

  const handleCloseModal = () => {
    const eventsWithOutPreauth = getValues("events");

    const index = findIndex(eventsWithOutPreauth, {
      service: WebhookServiceEnum.cash,
      status: WebhookEventsEnum.cashPreathorization,
    });
    if (index > -1) {
      eventsWithOutPreauth.splice(index, 1);
      setValue("events", eventsWithOutPreauth);
      dispatch(setPreauthEnable(false));
    }

    handleOpenModal(false);
  };

  const clearEventsExceptPreauth = async () => {
    const emplyPaymnetUrl: PaymentUrlWithStatus[] = [];
    await setValue("events", [
      {
        service: WebhookServiceEnum.cash,
        status: WebhookEventsEnum.cashPreathorization,
      },
    ]);
    setValue("options", ["cash"]);
    setValue("urlWebhook", "");
    setValue("paymentUrls", emplyPaymnetUrl);
    dispatch(setPreauthEnable(true));
    handleOpenModal(false);
  };

  const handleStatus = (
    event: React.ChangeEvent<HTMLInputElement>,
    product: ISelectedProduct
  ) => {
    const { events } = getValues();
    if (event.target.checked) {
      setValue("events", [...events, product]);
    } else {
      findPreauthEvent();
      setValue("events", [
        ...events.filter(
          (item) => JSON.stringify(item) !== JSON.stringify(product)
        ),
      ]);
    }
    findPreauthEvent();
  };

  const findPreauthEvent = () => {
    const { events } = getValues();

    const hasPreauthEvent = !!events.find(
      ({ service, status }) =>
        service === WebhookServiceEnum.cash &&
        status === WebhookEventsEnum.cashPreathorization
    );

    if (hasPreauthEvent) {
      handleOpenModal(true);
    } else {
      dispatch(setPreauthEnable(false));
    }
  };

  const handleCheck = (product: ISelectedProduct) => {
    return events === undefined
      ? false
      : events.filter((event) => {
          if (get(event, "status", "") === "cashPreathorization") {
            dispatch(dispatch(setPreauthEnable(true)));
          }
          return JSON.stringify(event) === JSON.stringify(product);
        }).length === 1;
  };

  const hasEvents = (record: object): boolean => {
    const keys = Object.keys(record);
    const totalItems: number = keys.length + options.length;
    return new Set([...keys, ...options]).size < totalItems;
  };

  const handleAreAllEventsSelected = (): boolean => {
    const { events = [], options: currentOptions = [] } = getValues();
    let totalEventCount: number = 0;
    const totalEvents: EventList[] = currentOptions.map(
      (option: string) => PAYMENT_METHODS_EVENTS[option]
    );
    totalEvents.forEach(
      (eList: EventList) => (totalEventCount += Object.values(eList).length)
    );

    return events.length === totalEventCount;
  };

  const handleSelectAllEvents = () => {
    const areAllSelected: boolean = handleAreAllEventsSelected();

    if (areAllSelected) setValue("events", []);
    else {
      const updatedEvents: WebhookEvent = [];

      options.forEach((option: string) => {
        Object.entries(PAYMENT_METHODS_EVENTS[option]).forEach(([status]) => {
          if (
            option === WebhookServiceEnum.cash &&
            status === WebhookEventsEnum.cashPreathorization
          ) {
            return;
          }
          updatedEvents.push({ service: option, status: status });
        });
      });

      setValue("events", updatedEvents);
    }
  };

  return {
    options: checkOptionChargeBack(options),
    handler: {
      handleStatus,
      handleCheck,
      handleSelectAllEvents,
      handleAreAllEventsSelected,
      hasEvents,
    },
    modalPreath: {
      openModalPreauth,
      handleOpenModal,
      clearEventsExceptPreauth,
      handleCloseModal,
    },
  };
};

export { useWebhookEventsCheckList };
