import { IAppState, IRulesRequestState } from "./reducer";
import { ActionTypes } from "./actionTypes";
import { INotification } from "../shared/infrastructure/interfaces/INotification";
import { SearchAuthenticationRulesRequest } from "../../types/search_authentication_rules_request";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AxiosResponse } from "axios";
import axios from "../shared/axios-util";
import { AuthenticationRequestDynamo } from "../../types/remote/authentication_request_dynamo";
import { RulesTable } from "../../types/rules_table";
import {
  AuthenticationRules,
  ResponseSearchRulesByFilters,
} from "../../types/response_search_rules_by_filters";
import { environment } from "../environments/environment";
import { ProcessRequestEnum } from "../shared/infrastructure/enums/ProcessRequestEnum";
import { Dispatch } from "redux";
import { DataWebsocket } from "../../types/data_websocket";
import { SearchRuleAnalystRequest } from "../../types/search_analyst_rules_request";
import {
  StatusEnum,
  StatusList,
} from "../shared/infrastructure/enums/StatusEnum";
import { AuthenticationElastic } from "../../types/remote/authentication_elastic";
import { ProviderEnum } from "../shared/infrastructure/enums/ProviderEnum";
import { kindEnum } from "../shared/infrastructure/enums/KindEnum";
import { UserBalancerRequest } from "../../types/user_balancer_request";
import { UserBalancerResponse } from "../../types/user_balancer_response";
import { ReassignAnalystRequest } from "../../types/reassign_analyst_request";
import { LoadingFileEnum } from "../shared/infrastructure/enums/LoadingFileEnum";
import { ProcessorInfoResponse } from "../../types/processor_info_response";
import { CredentialInfoResponse } from "../../types/credential_info_response";
import { MerchantInformation } from "../../types/merchant_information";
import { ProcessorInformation } from "../../types/processor_information";
import { AuthenticationRule } from "../../types/authentication_rule";
import { BusinessRulesResponse } from "../../types/business_rules_response";
import { Category } from "../../types/category";
import { MerchantProcessors } from "../../types/merchant_processors";
import { WhitelistRules } from "../../types/whitelist_rules";
import { AuthenticationRulesByType } from "../../types/authentication_rules_by_type";
import { RulesNotificationResultEnum } from "../shared/infrastructure/constants/RuleRequestConstants";
import { MerchantCredential } from "../../types/merchant_credential";
import { defaultTo, get, isEmpty, isNil, sortBy } from "lodash";
import { tzUTC } from "../shared/infrastructure/constants/RuleRequestAuthenticationConstants";
const DEFAULT_MESSAGE_ERROR: string = "No fue posible realizar la petición";

export interface RulesAction {
  type: string;
  isLoading?: boolean;
  isLoadingGetMerchants?: boolean;
  isLoadingGetProcessors?: boolean;
  isLoadingGetRule?: boolean;
  isLoadingRulesByType?: boolean;
  isLoadingWhiteList?: boolean;
  notification?: INotification;
  merchants?: MerchantsData;
  processors?: ProcessorInformation[];
  currentAuthenticationRule?: AuthenticationRule;
  isRequest?: boolean;
  requestError?: boolean;
  businessRule?: BusinessRulesResponse;
  processorsMerchant?: MerchantProcessors[];
  credentials?: Category[];
  defaultProcessor?: object;
  rulesByType?: AuthenticationRulesByType;
  commerceRules?: AuthenticationRulesByType;
  whitelistRules?: WhitelistRules;
  currentWhitelistRule?: WhitelistRules;
  showMigrationRule?: boolean;
}

export interface IRulesState {
  isLoading?: boolean;
  isLoadingGetMerchants?: boolean;
  isLoadingGetProcessors?: boolean;
  isLoadingGetRule?: boolean;
  isLoadingGetMerchant?: boolean;
  isLoadingRulesByType?: boolean;
  isLoadingWhiteList?: boolean;
  notification?: INotification;
  merchants?: MerchantsData;
  processors?: ProcessorInformation[];
  currentAuthenticationRule?: AuthenticationRule;
  currentMerchant?: MerchantInformation;
  isRequest?: boolean;
  requestError?: boolean;
  businessRule?: BusinessRulesResponse;
  processorsMerchant?: MerchantProcessors[];
  credentials?: Category[];
  defaultProcessor?: object;
  rulesByType?: AuthenticationRulesByType;
  commerceRules?: AuthenticationRulesByType;
  whitelistRules?: WhitelistRules;
  currentWhitelistRule?: WhitelistRules;
  showMigrationRule?: boolean;
}

export type IAppAction = {
  type: string;
  pathLoadingProcessRates?: boolean;
  notification?: INotification;
  rulesDataCounter?: { contador: number; total: number };
  setLoadingAuthenticationRules?: boolean;
  authenticationRulesData?: ResponseSearchRulesByFilters;
  isLoadingUpdateAuthenticationRules?: boolean;
  setLoadingHistoric?: boolean;
  setLoadingDownloadFile?: boolean;
  setLoadingDownloadListFile?: boolean;
  authenticationRulesDataHistoric?: ResponseSearchRulesByFilters;
  isLoadingDashboardApproval?: boolean;
  authenticationRulesApprovalData?: ResponseSearchRulesByFilters;
  isLoadingDashboardRequest?: boolean;
  isLoadingRuleDashboardRequest?: boolean;
  authenticationRulesRequestData?: ResponseSearchRulesByFilters;
  isSnackbarRuleDashboardRequest?: boolean;
  userAnalystData?: UserBalancerResponse[];
  authenticationData?: AuthenticationRequestDynamo;
  setLoadingAnalyst?: boolean;
  ruleAnalystData?: ResponseSearchRulesByFilters;
  isReloadDashboardRuleRequest?: string;
  setLoadingProcessor?: boolean;
  processorData?: ProcessorInfoResponse[];
  setLoadingCredential?: boolean;
  credentialData?: CredentialInfoResponse[];
};

export interface RulesActions {
  type: string;
  setLoadingAnalyst?: boolean;
  ruleAnalystData?: ResponseSearchRulesByFilters;
}

export const setPathLoadingProcessRates = (payload: boolean) => {
  return {
    type: ActionTypes.SET_PATH_LOADING_PROCESS_RATES,
    pathLoadingProcessRates: payload,
  };
};

export const setRuleDataCounter = (payload: {
  contador: number;
  total: number;
}) => {
  return {
    type: ActionTypes.SET_RULES_DATA_COUNTER,
    rulesDataCounter: payload,
  };
};

export const setLoadingTabs = (
  tab: number,
  loading: boolean,
  data?: ResponseSearchRulesByFilters
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    switch (tab) {
      case 0:
        dispatch(setLoadingAuthenticationRulesApproval(loading));
        if (data) {
          const records: AuthenticationRules[] = get(data, "records", []);
          data.records = records.map((ruleRecord: AuthenticationRules) => ({
            ...ruleRecord,
          }));
          dispatch(setAuthenticationRulesApprovalData(data));
        }
        break;
      case 1:
        dispatch(setLoadingAuthenticationRulesRequest(loading));
        if (data) {
          dispatch(setAuthenticationRulesRequestData(data));
        }
        break;
      case 2:
        dispatch(setLoadingAuthenticationRulesHistoric(loading));
        if (data) {
          dispatch(setAuthenticationRulesDataHistoric(data));
        }
        break;
    }
  };
};

export type MerchantsData = {
  data: { _source: MerchantInformation }[];
};

export const setNotification = (payload: INotification) => ({
  type: ActionTypes.SET_NOTIFICATION,
  notification: payload,
});

export const setIsRequest = (payload: boolean): RulesAction => {
  return {
    type: ActionTypes.SET_IS_REQUESTING,
    isRequest: payload,
  };
};

export const setRequestError = (payload: boolean): RulesAction => {
  return {
    type: ActionTypes.SET_REQUEST_ERROR,
    requestError: payload,
  };
};

export const setLoadingAuthenticationRules = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_LOADING_AUTHENTICATION_RULES,
    setLoadingAuthenticationRules: payload,
  };
};
export const setLoadingDownloadFile = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_LOADING_DOWNLOAD_FILE,
    setLoadingDownloadFile: payload,
  };
};
export const setLoadingDownloadListFile = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_LOADING_DOWNLOAD_LIST_FILE,
    setLoadingDownloadListFile: payload,
  };
};

export const setLoadingAuthenticationRulesRequest = (
  payload: boolean
): IAppAction => {
  return {
    type: ActionTypes.SET_LOADING_AUTHENTICATION_RULES_REQUEST,
    isLoadingDashboardRequest: payload,
  };
};

export const setLoadingRulesDashboardRequest = (
  payload: boolean
): IAppAction => {
  return {
    type: ActionTypes.SET_LOADING_RULES_REQUEST,
    isLoadingRuleDashboardRequest: payload,
  };
};

export const setSnackbarRulesDashboardRequest = (
  payload: boolean
): IAppAction => {
  return {
    type: ActionTypes.SET_SNACKBAR_RULES_REQUEST,
    isSnackbarRuleDashboardRequest: payload,
  };
};

export const setAnalystUser = (payload: UserBalancerResponse[]) => {
  return {
    type: ActionTypes.SET_ANALYST_USER,
    userAnalystData: payload,
  };
};

export const setAuthenticationRulesData = (
  payload: ResponseSearchRulesByFilters
) => {
  return {
    type: ActionTypes.SET_AUTHENTICATION_RULES_DATA,
    authenticationRulesData: payload,
  };
};

export const setLoadingUpdateAuthenticationRules = (
  payload: boolean
): IAppAction => {
  return {
    type: ActionTypes.SET_LOADING_UPDATE_AUTHENTICATION_RULES,
    isLoadingUpdateAuthenticationRules: payload,
  };
};

export const setLoadingAuthenticationRulesHistoric = (
  payload: boolean
): IAppAction => {
  return {
    type: ActionTypes.SET_LOADING_AUTHENTICATION_RULES_HISTORIC,
    setLoadingHistoric: payload,
  };
};

export const setReloadDashboard = (payload: string) => ({
  type: ActionTypes.SET_RELOAD_DASHBOARD_RULE_REQUEST,
  isReloadDashboardRuleRequest: payload,
});
export const setAuthenticationRulesDataHistoric = (
  payload: ResponseSearchRulesByFilters
) => {
  return {
    type: ActionTypes.SET_AUTHENTICATION_RULES_DATA_HISTORIC,
    authenticationRulesDataHistoric: payload,
  };
};

export const setLoadingAuthenticationRulesApproval = (
  payload: boolean
): IAppAction => {
  return {
    type: ActionTypes.SET_LOADING_AUTHENTICATION_RULES_APPROVAL,
    isLoadingDashboardApproval: payload,
  };
};

export const setAuthenticationRulesApprovalData = (
  payload: ResponseSearchRulesByFilters
) => {
  return {
    type: ActionTypes.SET_AUTHENTICATION_RULES_APPROVAL_DATA,
    authenticationRulesApprovalData: payload,
  };
};

export const setAuthenticationData = (payload: AuthenticationRequestDynamo) => {
  return {
    type: ActionTypes.SET_AUTHENTICATION_DATA,
    authenticationData: payload,
  };
};

export const setRulesRequestDataRow =
  (
    _rulesRow: RulesTable,
    index: number
  ): ThunkAction<void, IRulesRequestState, undefined, RulesActions> =>
  (): void => {
    console.log(index);
  };

export const setLoadingAnalyst = (payload: boolean): RulesActions => {
  return {
    type: ActionTypes.SET_LOADING_ANALYST,
    setLoadingAnalyst: payload,
  };
};
export const setRuleAnalystData = (
  payload: ResponseSearchRulesByFilters
): RulesActions => {
  return {
    type: ActionTypes.SET_ANALYST_DATA,
    ruleAnalystData: payload,
  };
};

export const setLoadingProcessor = (payload: boolean) => {
  return {
    type: ActionTypes.SET_LOADING_PROCESSOR,
    setLoadingProcessor: payload,
  };
};
export const setProcessorData = (payload: ProcessorInfoResponse[]) => {
  return {
    type: ActionTypes.SET_PROCESSOR_DATA,
    processorData: payload,
  };
};

export const setLoadingCredential = (payload: boolean) => {
  return {
    type: ActionTypes.SET_LOADING_CREDENTIAL,
    setLoadingCredential: payload,
  };
};
export const setCredentialData = (payload: CredentialInfoResponse[]) => {
  return {
    type: ActionTypes.SET_CREDENTIAL_DATA,
    credentialData: payload,
  };
};

export const setAuthenticationRulesRequestData = (
  payload: ResponseSearchRulesByFilters
) => {
  return {
    type: ActionTypes.SET_AUTHENTICATION_RULES_REQUEST_DATA,
    authenticationRulesRequestData: payload,
  };
};

export const getAuthenticationRulesDataTabs = (
  tab: number,
  params: SearchAuthenticationRulesRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    let url: string = `${environment.kushkiUrl}/request-manager/v1/searchRequest`;
    dispatch(setLoadingTabs(tab, true));
    axios
      .post(url, params)
      .then((axios_response: AxiosResponse<ResponseSearchRulesByFilters>) => {
        const response: ResponseSearchRulesByFilters = axios_response.data;
        dispatch(setLoadingTabs(tab, false, response));
      })
      .catch(() => {
        dispatch(setLoadingTabs(tab, false)); // TODO: DESCOMENTAR
        //dispatch(setLoadingTabs(tab, false, {records: [], total: 0}));
      });
  };
};

export const getAuthenticationRulesData = (
  params: SearchAuthenticationRulesRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    const url: string =
      "https://rule-request.getsandbox.com/rule-request/v1/getRequest";
    //${environment.kushkiUrl}
    dispatch(setLoadingAuthenticationRules(true));
    axios
      .post(url, params)
      .then((axios_response: AxiosResponse<ResponseSearchRulesByFilters>) => {
        const response: ResponseSearchRulesByFilters = axios_response.data;
        dispatch(setAuthenticationRulesData(response));
        dispatch(setLoadingAuthenticationRules(false));
      })
      .catch(() => {
        dispatch(setLoadingAuthenticationRules(false));
      });
  };
};
export const setIsLoadingGetProcessors = (payload: boolean): RulesAction => {
  return {
    type: ActionTypes.SET_IS_LOADING_GET_PROCESSORS,
    isLoadingGetProcessors: payload,
  };
};

export const setProcessors = (payload: ProcessorInformation[]): RulesAction => {
  return {
    type: ActionTypes.SET_PROCESSORS,
    processors: payload,
  };
};

export const getProcessorsauthenticationRules = (payload: {
  merchantId: string;
}): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    dispatch(setIsLoadingGetProcessors(true));
    axios
      .get<ProcessorInformation[]>(
        `${environment.kushkiUrl}/merchant/v1/admin/merchant/${payload.merchantId}/processors`
      )
      .then((axios_response: AxiosResponse<ProcessorInformation[]>) => {
        const response: ProcessorInformation[] = axios_response.data;
        dispatch(setProcessors(response));
        dispatch(setIsLoadingGetProcessors(false));
      })
      .catch(() => {
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_SUCCESS,
            message: "Error",
            open: true,
            type: "error",
          })
        );
        dispatch(setIsLoadingGetProcessors(false));
      });
  };
};

const buildCredentials = (
  merchantCredentials: MerchantCredential[],
  dispatch: ThunkDispatch<IRulesState, any, RulesAction>
): void => {
  const credentials: Category[] = [];

  merchantCredentials.forEach((credential: MerchantCredential) => {
    const alias: string | undefined | null = get(credential, "alias");
    let name: string = "";

    if (isNil(alias))
      name = `${credential.publicCredentialId.substr(0, 6)}XXXXXX`;
    else
      name = `${
        alias === "" ? "Alias" : alias
      }_${credential.publicCredentialId.substr(0, 6)}XXXXXX`;

    credentials.push({ name, value: credential.credentialId });
  });

  dispatch(setMerchantCredentials(credentials));
  dispatch(setIsRequest(false));
  dispatch(setRequestError(false));
};
export const setMerchantCredentials = (payload: Category[]): RulesAction => {
  return {
    type: ActionTypes.SET_MERCHANT_CREDENTIALS,
    credentials: payload,
  };
};

export const setIsLoadingGetMerchants = (payload: boolean): RulesAction => {
  return {
    type: ActionTypes.SET_IS_LOADING_GET_MERCHANTS,
    isLoadingGetMerchants: payload,
  };
};

export const setIsLoading = (payload: boolean): RulesAction => {
  return {
    type: ActionTypes.SET_IS_LOADING,
    isLoading: payload,
  };
};
export const setMerchants = (payload: MerchantsData): RulesAction => {
  return {
    type: ActionTypes.SET_MERCHANTS,
    merchants: payload,
  };
};
export const getMerchantCredential = (
  merchantId: string
): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    dispatch(setIsRequest(true));
    dispatch(setRequestError(false));
    axios
      .get<MerchantCredential[]>(
        `${environment.kushkiUrl}/payment-credentials/v1/admin/credentials/${merchantId}`
      )
      .then((axios_response: AxiosResponse<MerchantCredential[]>) => {
        const response: MerchantCredential[] = get(axios_response, "data");

        buildCredentials(response, dispatch);
      })
      .catch((e) => {
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_ERROR,
            message: defaultTo(
              get(e, "response.data.message"),
              DEFAULT_MESSAGE_ERROR
            ),
            open: true,
            type: "error",
          })
        );
        dispatch(setRequestError(true));
        dispatch(setIsRequest(false));
        dispatch(setMerchantCredentials([]));
      });
  };
};

export const getMerchants = (payload: {
  offset: number;
  limit: number;
  text?: string;
}): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    let body: { limit: number; offset: number; text?: string } = {
      limit: payload.limit,
      offset: payload.offset,
    };
    if (Boolean(payload.text)) body = { ...body, text: payload.text };

    dispatch(setIsLoadingGetMerchants(true));
    axios
      .post<MerchantsData>(
        `${environment.kushkiUrl}/analytics/v1/admin/merchant/get`,
        body
      )
      .then((axios_response: AxiosResponse<MerchantsData>) => {
        const response: MerchantsData = axios_response.data;
        dispatch(setMerchants(response));
        dispatch(setIsLoadingGetMerchants(false));
      })
      .catch(() => {
        dispatch(setIsLoadingGetMerchants(false));
      });
  };
};

export const getAnalystUsers = (
  params: UserBalancerRequest,
  isActiveUser: boolean | undefined
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    const url: string = `${environment.kushkiUrl}/balancer/v1/usersBalancer`;
    axios
      .get(url, { params, headers: { Authorization: getJwtAuth() } })
      .then((axios_response: AxiosResponse<UserBalancerResponse[]>) => {
        const response: UserBalancerResponse[] = axios_response.data;
        if (isActiveUser) {
          const responseFilter: UserBalancerResponse[] = [];
          for (let i = 0; i < response.length; i += 1) {
            if (get(response[i], "balanceRules[0].active", false)) {
              responseFilter.push(response[i]);
            }
          }
          dispatch(setAnalystUser(responseFilter));
        } else {
          dispatch(setAnalystUser(response));
        }
      })
      .catch(() => {
        dispatch(setAnalystUser([]));
      });
  };
};

export const editAnalystUser = (
  params: ReassignAnalystRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setLoadingRulesDashboardRequest(true));
    axios
      .put(
        `${environment.kushkiUrl}/request-manager/v1/authentication/updateAnalyst`,
        { ...params },
        { headers: { Authorization: getJwtAuth() } }
      )
      .then(() => {
        dispatch(setLoadingRulesDashboardRequest(false));
        dispatch(setSnackbarRulesDashboardRequest(true));
        dispatch(setReloadDashboard("reload"));
      })
      .catch(() => {
        dispatch(setLoadingRulesDashboardRequest(false));
      });
  };
};

export const getMerchantInfo = (
  id: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    if (!id) return;
    const url: string = `${environment.kushkiUrl}/request-manager/v1/authentication/getRequest/${id}`;
    dispatch(setLoadingAuthenticationRules(true));

    axios
      .get(url)
      .then((axios_response: AxiosResponse<AuthenticationRequestDynamo>) => {
        const response: AuthenticationRequestDynamo = axios_response.data;

        if (response.authRules && response.authRules.length > 0) {
          response.authRules = sortBy(
            response.authRules,
            ({ ruleNumber }) => ruleNumber
          );
        }
        dispatch(setAuthenticationData(response));
        if (
          !isEmpty(response.publicMerchantId) &&
          get(response, "type", "") === "authenticationCommerce" //TODO: VALIDAR TYPE
        ) {
          dispatch(
            getProcessorsauthenticationRules({
              merchantId: get(response, "publicMerchantId", ""),
            })
          );
          dispatch(
            getMerchantCredential(get(response, "publicMerchantId", ""))
          );
        }
        dispatch(setLoadingAuthenticationRules(false));
      })
      .catch(() => {
        dispatch(setLoadingAuthenticationRules(false));
      });
  };
};

export const getRuleAnalystData = (
  params: SearchRuleAnalystRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    const url: string = `${environment.kushkiUrl}/request-manager/v1/searchRequest`;
    dispatch(setLoadingAnalyst(true));
    axios
      .post(url, params)
      .then((axios_response: AxiosResponse<ResponseSearchRulesByFilters>) => {
        const response: ResponseSearchRulesByFilters = axios_response.data;
        dispatch(setRuleAnalystData(response));
        dispatch(setLoadingAnalyst(false));
      })
      .catch(() => {
        dispatch(setLoadingAnalyst(false));
      });
  };
};

const finishLoadingProcessRulesData = (
  error: boolean,
  dispatch: Dispatch<any>,
  messageError?: string
) => {
  dispatch(setPathLoadingProcessRates(true)); // TODO re render the table data
  if (error) {
    dispatch(
      setNotification({
        open: true,
        message: messageError ? messageError : "Error al procesar la regla",
        type: "error",
      })
    );
  } else {
    dispatch(
      setNotification({
        open: true,
        message: "Procesado exitosamente",
        type: "success",
      })
    );
  }
};

export const getProcessorData = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    const url: string = `${environment.kushkiUrl}/merchant/v1/admin/merchant/${merchantId}/processors`;
    dispatch(setLoadingProcessor(true));
    axios
      .get(url)
      .then((axios_response: AxiosResponse<ProcessorInfoResponse[]>) => {
        const response: ProcessorInfoResponse[] = axios_response.data;

        dispatch(setProcessorData(response));
        dispatch(setLoadingProcessor(false));
      })
      .catch(() => {
        dispatch(setLoadingProcessor(false));
      });
  };
};

export const getCredentialData = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    const url: string = `${environment.kushkiUrl}/payment-credentials/v1/admin/credentials/${merchantId}`;
    dispatch(setLoadingCredential(true));
    axios
      .get(url)
      .then((axios_response: AxiosResponse<CredentialInfoResponse[]>) => {
        const response: CredentialInfoResponse[] = axios_response.data;

        dispatch(setCredentialData(response));
        dispatch(setLoadingCredential(false));
      })
      .catch(() => {
        dispatch(setLoadingCredential(false));
      });
  };
};

const getJwtAuth = (): string => {
  return localStorage.getItem("jwt") || "";
};

export const transformRuleDataItem = (
  ruleData: AuthenticationElastic
): AuthenticationRequestDynamo => {
  return {
    id: get(ruleData, "id", ""),
    userAliasRequest: get(ruleData, "user_alias_request", ""),
    userNameRequest: get(ruleData, "user_name_request", ""),
    country: get(ruleData, "country", ""),
    publicMerchantId: get(ruleData, "public_merchant_id", ""),
    merchantName: get(ruleData, "merchant_name", ""),
    provider: get(ruleData, "provider", "") as ProviderEnum,
    kind: get(ruleData, "kind", "") as kindEnum,
    status: get(ruleData, "status", "pending") as StatusList,
    observations: {
      date: get(ruleData, "created", ""),
      description: get(ruleData, "description", ""),
    },
  };
};

export const processRulesData = (): ThunkAction<any, any, any, any> => {
  return (
    dispatch: ThunkDispatch<any, any, any>,
    getState: () => IAppState
  ): void => {
    const { authenticationRulesApprovalData } = getState();
    const data: AuthenticationElastic[] = <AuthenticationElastic[]>(
      get(authenticationRulesApprovalData, "records", [])
    );
    const rulesData: AuthenticationElastic[] = data.filter(
      (element) =>
        element.status === StatusEnum.APPROVE ||
        element.status === StatusEnum.REJECT
    );

    let webSocket: WebSocket | undefined;
    let processingError: boolean = false;
    let countWSResponse: number = 0;

    if (rulesData.length === 0) dispatch(setPathLoadingProcessRates(true));

    dispatch(
      setRuleDataCounter({
        contador: countWSResponse,
        total: rulesData.length,
      })
    );

    try {
      webSocket = new WebSocket(
        `${environment.processWSRulesData}?Authorization=${getJwtAuth()}`
      );
      webSocket.onopen = () => {
        rulesData
          .map((item: AuthenticationElastic) => transformRuleDataItem(item))
          .forEach((process: any) => {
            const rulesRequest = {
              action: ProcessRequestEnum.PROCESS_REQUEST,
              data: {
                ...process,
              },
            };
            webSocket?.send(JSON.stringify(rulesRequest));
          });
      };

      webSocket.onerror = () => {
        webSocket?.close();
        finishLoadingProcessRulesData(true, dispatch);
      };

      webSocket.onmessage = (event: MessageEvent) => {
        const response: DataWebsocket = JSON.parse(event.data);
        const error = get(response, "error", false);
        const message = get(response, "message", "");
        const status = get(response, "status", "");

        if (error || message === "Internal server error") {
          processingError = true;
        }

        if (
          message === "request not found" ||
          message === "Internal server error" ||
          message === "bad request" ||
          status === "complete"
        ) {
          countWSResponse++;
          dispatch(
            setRuleDataCounter({
              contador: countWSResponse,
              total: rulesData.length,
            })
          );
        }

        if (countWSResponse === rulesData.length) {
          webSocket?.close();
          finishLoadingProcessRulesData(
            processingError,
            dispatch,
            processingError
              ? "No se logró procesar todas las transacciones"
              : undefined
          );
        }
      };
    } catch (e) {
      webSocket?.close();
      dispatch(
        setNotification({
          open: true,
          message: "Error al procesar las reglas",
          type: "error",
        })
      );
    }
  };
};

const setLoading = (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>,
  param: string,
  status: boolean
) => {
  if (param == LoadingFileEnum.DOWNLOAD_FILE_ENUM) {
    dispatch(setLoadingDownloadFile(status));
  } else if (param == LoadingFileEnum.DOWNLOAD_LIST_FILE_ENUM) {
    dispatch(setLoadingDownloadListFile(status));
  }
};

export const DonwloadFile = (
  data: any,
  param: string
): ThunkAction<any, any, any, any> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    let webSocket: WebSocket | undefined;
    setLoading(dispatch, param, true);
    try {
      webSocket = new WebSocket(
        `${environment.processWSRulesData}?Authorization=${getJwtAuth()}`
      );
      webSocket.onopen = () => {
        const rulesRequest = {
          action: ProcessRequestEnum.DOWNLOAD_REQUEST,
          timeZone: tzUTC,
          data: {
            ...data,
          },
        };
        webSocket?.send(JSON.stringify(rulesRequest));
      };

      webSocket.onerror = () => {
        webSocket?.close();
        setLoading(dispatch, param, false);
      };

      webSocket.onmessage = (event: MessageEvent) => {
        const response: DataWebsocket = JSON.parse(event.data);

        const error = get(response, "error", false);
        const message = get(response, "message", "");
        if (
          error ||
          message === "Internal server error" ||
          message === "request not found" ||
          message === "bad request" ||
          message == "empty"
        ) {
          webSocket?.close();
          setLoading(dispatch, param, false);
        }
        if (message.startsWith("https")) {
          let a = document.createElement("a");
          a.href = message;
          a.download = name;
          a.click();
          a.remove();
          setLoading(dispatch, param, false);
          webSocket?.close();
        }
      };
    } catch (e) {
      webSocket?.close();
      setLoading(dispatch, param, false);
    }
  };
};
export const updateAuthenticationRequest =
  (
    body: AuthenticationRequestDynamo
  ): ThunkAction<void, IAppState, undefined, IAppAction> =>
  (dispatch: ThunkDispatch<IAppState, any, IAppAction>) => {
    dispatch(setLoadingUpdateAuthenticationRules(true));
    const url: string = `${environment.kushkiUrl}/request-manager/v1/authentication/update`;
    axios
      .put(url, body)
      .then(() => {
        dispatch(setLoadingUpdateAuthenticationRules(false));
        window.location.href = "/rule-request/rules/dashboard";
      })
      .catch(() => {
        dispatch(setLoadingUpdateAuthenticationRules(false));
        dispatch(
          setNotification({
            open: true,
            message: "Error al procesar solicitud",
            type: "error",
          })
        );
      });
  };
