import React from "react";
import { Grid, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IAppState } from "../../store/reducer";

const useStyles = makeStyles((theme: Theme) => ({
  typography: {
    fontWeight: 300,
  },
  typographyMobile: {
    fontWeight: 200,
  },
  countryFlag: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 5,
  },
  mediumTitle: {
    fontSize: 14,
    color: theme.palette.primary.dark,
    fontWeight: 500,
  },
  normalTitle: {
    fontSize: 14,
    color: theme.palette.primary.dark,
  },
  copyIcon: {
    marginLeft: 10,
    color: "#CBD5E0",
  },
  padding: {
    paddingBottom: "32px",
  },
  statusBadge: {
    padding: "4px 8px",
    borderRadius: "4px",
    width: 75,
    textAlign: "center",
    marginTop: 8,
    marginBottom: 24,
  },
  statusLabel: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "100%",
  },
}));

export interface IConfigHeaderProps extends IAppState {
  title: string;
  isActive?: boolean;
}

export const ConfigHeader: React.FC<IConfigHeaderProps> = ({
  title,
}: IConfigHeaderProps) => {
  const classes = useStyles();
  const isMobile: boolean = useMediaQuery("(max-width:767px)");

  return (
    <React.Fragment>
      <Grid container spacing={1} className={classes.padding}>
        <Grid
          item
          xs={6}
          md={6}
          container
          alignItems="center"
          justify="flex-start"
          direction="row"
        >
          <Typography
            className={isMobile ? classes.typographyMobile : classes.typography}
            color={"primary"}
            variant={isMobile ? "h3" : "h1"}
          >
            {title}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ConfigHeader;
