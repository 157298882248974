import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    executorButton: {
      "&:disabled": {
        backgroundColor: "#64798D",
      },
      "&:hover": {
        backgroundColor: "#023365",
      },
      backgroundColor: "#023365",
      borderRadius: "4px",
      color: "#F4F6F8 !important",
      fontSize: "13px",
      marginLeft: "10px",
      padding: "8px 16px",
    },
  })
);
