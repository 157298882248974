import { useEffect, useState } from "react";
import axiosInstance from "../../../shared/axios-util";
import { IMerchantNodeSearchResponse } from "../../../../types/merchant_node_search_response";
import { selectNodeInfo } from "../../../store/selectors/selectors";
import { useSelector } from "react-redux";

const useBranchesResume = () => {
  const [branchesResponse, setBranchesResponse] =
    useState<IMerchantNodeSearchResponse>();
  const nodeInfoData = useSelector(selectNodeInfo);
  const path = nodeInfoData?.path;
  const getData = async () => {
    try {
      const { data } = await axiosInstance.post<IMerchantNodeSearchResponse>(
        "/billing-core-node/v1/merchant-node/search",
        {
          entityName: "BRANCH",
          isActive: true,
          limit: 3,
          offset: 0,
          path,
          sort: {
            field: "created",
            order: "desc",
          },
          strictSearch: false,
        }
      );

      setBranchesResponse(data);
    } catch (error) {}
  };

  useEffect(() => {
    nodeInfoData && getData();
  }, [nodeInfoData]);

  const onClickRedirect = (route: string) => {
    const idOwner = path?.substring(0, path?.indexOf(":"));
    const idCustomer = path?.substring(path?.indexOf(":") + 1);

    window.location.href = `${route + idOwner}/customers/${idCustomer}`;
  };

  return { branchesResponse, onClickRedirect };
};

export default useBranchesResume;
