export enum TableOptionsEnum {
  EDIT = "Editar",
  ENABLE = "Habilitar",
  DISABLE = "Deshabilitar",
  DELETE = "Eliminar",
}

export enum RuleStatusEnum {
  ENABLE = "ENABLE",
  DISABLE = "DISABLE",
}

export enum OptionsTypeEnum {
  TYPE1 = "type1",
}
