import React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SmartlinksLogos } from "../../../types/smartlink_customization";
import { Gallery } from "@kushki/frontend-molecules/gallery";
import { useLogoUpload } from "./state/useLogoUpload";
import { environment } from "../../environments/environment";

const useStyle = makeStyles({
  title: {
    paddingTop: 5,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#6D7781",
  },
  subTitle: {
    fontWeight: 500,
    fontSize: "14px",
    display: "inline-block",
    verticalAlign: "baseline",
    lineHeight: "1rem",
    marginBottom: "0.25rem",
    color: "#677784",
  },
  buttonMessage: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "120%",
    color: "#293036",
  },
  alertMessage: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "134%",
    color: "#6D7781",
  },
  searchBtn: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "140%",
    color: "#023365",
    backgroundColor: "#FFFFFF",
    border: "1px solid #023365",
    boxSizing: "border-box",
    borderRadius: "4px",
    "&:hover": { backgroundColor: "#FFFFFF", color: "#023365" },
    width: "100%",
  },
});

export interface ILogoUpload {
  prop: string;
  files?: SmartlinksLogos[];
  onChange?(value: { prop: string; files?: SmartlinksLogos }): void;
  maxSize?: number;
  message?: string;
}

export interface KshFile {
  id: string;
  name: string;
  extension: string;
  alias?: string;
  base64?: string;
  file?: string;
}

export const LogoUpload: React.FC<ILogoUpload> = (props: ILogoUpload) => {
  const styles = useStyle();
  const { openGallery, handler } = useLogoUpload(props);

  return (
    <Grid container style={{ marginTop: 15 }}>
      <Grid item xs={12}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Grid container spacing={2} alignItems="center">
            <Grid item md={4} sm={4} xs={6}>
              <Button
                className={styles.searchBtn}
                variant="contained"
                color="primary"
                component="span"
                onClick={() => handler.handleOpenGallery(true)}
              >
                Agregar imagen
              </Button>
              <Gallery
                kushkiUrl={`${environment.kushkiUrl}`}
                open={openGallery}
                onClose={() => handler.handleOpenGallery(false)}
                onInsert={(insertedImage) =>
                  handler.handleInsertedImage(
                    insertedImage.url,
                    insertedImage.name
                  )
                }
                message={props.message}
              />
            </Grid>
            <Grid item md={8} sm={8} xs={6}>
              <Typography className={styles.buttonMessage}>
                No se ha seleccionado ninguna imagen
              </Typography>
            </Grid>
            <Grid item sm xs style={{ paddingTop: 0 }}>
              <Typography className={styles.alertMessage}>
                Tamaño máx. 4mb.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
