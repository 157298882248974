import React from "react";
import { applyMiddleware, createStore, Store } from "redux";
import thunk from "redux-thunk";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { hot } from "react-hot-loader/root";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import { reducer } from "./store/reducer";
import PayoutsTransaction from "./containers/PayoutsTransaction/PayoutsTransaction";
import { Notification } from "./components/commons/Notification/Notification";
import { environment } from "./environments/environment";
import { SecurityWrapperEnum } from "./shared/infrastructure/SecurityWrapperEnum";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";

const store: Store = createStore(reducer, applyMiddleware(thunk));

const Root = () => {
  return (
    <React.StrictMode>
      <ModuleSecurityWrapper
        basePath={environment.kushkiUrl}
        componentId={SecurityWrapperEnum.MODULE_ID}
      >
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Switch>
                <Route
                  path="/payouts-transaction"
                  exact
                  component={PayoutsTransaction}
                />
              </Switch>
            </BrowserRouter>
            <Notification />
          </ThemeProvider>
        </Provider>
      </ModuleSecurityWrapper>
    </React.StrictMode>
  );
};

export default hot(Root);
