import React, { useEffect, useRef, useState } from "react";
import { auth } from "../../../shared/auth";
import { ProcessorsIndexProps } from "../ProcessorIndex";
import { useLocation } from "react-router-dom";
import { ProcessorFetch } from "../../../shared/infrastructure/interfaces/ProcessorFetch";
import {
  defaultTo,
  filter,
  get,
  isEmpty,
  isEqual,
  set,
  some,
  includes,
  omit,
} from "lodash";
import { SubmitHandler, useForm, UseFormMethods } from "react-hook-form";
import { IDefaultProcessorForm } from "../../../shared/infrastructure/interfaces/IDefaultProcessorForm";
import { UpdateMerchantRulesRequest } from "../../../../types/update_merchant_rules_request";
import { PaymentMethodEnum } from "../../../shared/infrastructure/constants/PaymentMethodEnum";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import {
  getMerchantInfo,
  saveDefaultPayoutsTransferProcessor,
  saveDefaultProcessor,
  setRefreshProcessor,
} from "../../../store/actionCreators";
import { MerchantProperties } from "../../../../types/merchants";
import { IFilterResponse } from "@kushki/frontend-molecules/filter-container";
import {
  DEFAULT_FILTERS,
  filterLabels,
  paymentMethodPayOutEnum,
  ProcessorProperties,
  processorTypeLabelEnum,
} from "../../../shared/constants/filter_component";
import { Filter } from "../../../shared/infrastructure/interfaces/IFilter";
import { TablePaginationProps } from "../../../components/TablePagination/TablePagination";
import { LocalStoragePropertiesEnum } from "../../../shared/infrastructure/constants/LocalStoragePropertiesEnum";
import { CountryEnum } from "../../../shared/infrastructure/constants/CountryEnum";
import { NotificationMessagesEnum } from "../../../shared/infrastructure/constants/NotificationEnum";
import { ProcessorStatusEnum } from "../../../shared/infrastructure/constants/ProcessorsEnum";
import { OriginEnum } from "../../../shared/infrastructure/constants/OriginEnum";

export interface IModalConfig {
  form: UseFormMethods<IDefaultProcessorForm>;
  open: boolean;
  setOpen: (open: boolean) => void;
  handleSubmitDefaultProcessor: SubmitHandler<IDefaultProcessorForm>;
  merchantProcessors: IMerchantProcessor;
  showConfigButton: boolean;
}

export interface IMerchantProcessor {
  card: ProcessorFetch[];
  transfer: ProcessorFetch[];
  achTransfer: ProcessorFetch[];
  payoutsTransfer: ProcessorFetch[];
  payoutsCard: ProcessorFetch[];
}

export interface IProcessorsIndexState {
  filters: {
    buttonFilter: {
      filterCount: number;
      filters: IOptionFilter[];
      onApplyFilter: (response: IFilterResponse) => void;
      onClearFilter: () => void;
    };
  };
  isCentralized: boolean;
  isCountryIncluded: boolean;
  state: IState;
  merchantId: string | null;
  hideSideBar: boolean;
  merchantName: string;
  origin: string;
  modalConfig: IModalConfig;
  handlers: {
    handleOpenDialogFailOver(processor: ProcessorFetch): void;
    handleOnChangeFailOver(
      event: React.ChangeEvent<{ name?: string; value: unknown }>
    ): void;
    handleCloseDialogFailOver(): void;
    handleAssignDialogFailOver(): void;
    handleCloseModal(): void;
    handleOpenModalDetail(processor: ProcessorFetch): void;
    handleRefreshProcessors(): void;
  };
  country: string;
  merchantInfo?: MerchantProperties;
  defaultProcessors?: IDefaultProcessorForm;
  defaultPayoutsTransferProcessor?: IDefaultProcessorForm;
  pagination: TablePaginationProps;
  renderPayoutsCard: boolean;
}

export interface IState {
  allProcessors?: ProcessorFetch[];
  processors?: ProcessorFetch[];
  processorInfo?: ProcessorFetch;
  processorSelected: string;
  publicProcessorId: string;
  openDialogCancel: boolean;
  openModalDetail: boolean;
  isAdmin: boolean;
}

export interface IOptionFilter {
  id: string;
  title: string;
  options: { key: string; label: string; selected: boolean }[];
}

export interface IPaginationState {
  limit: number;
  offset: number;
}

export interface IFilterSearch {
  paymentMethod: string;
  processorType: string;
  status: string;
  nonExistingFields?: string[];
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const getRowsPerPage = (offset: number, limit: number): number => {
  return offset / limit + 1;
};

export const useProcessorIndexState = (
  props: ProcessorsIndexProps
): IProcessorsIndexState => {
  const merchantId = useQuery().get("merchantId");
  const hideSideBar = useQuery().get("hideSideBar");
  const basicInformation = useRef(
    JSON.parse(
      defaultTo(
        localStorage.getItem(LocalStoragePropertiesEnum.MERCHANT_BASIC_INFO),
        "{}"
      )
    )
  );

  const [open, setOpen] = useState<boolean>(false);

  const { merchantSource = {} } = useSelector((state: IAppState) => ({
    merchantSource: get(state, "merchant._source"),
  }));

  const country = get(merchantSource, "country", "");

  const isCountryIncluded = [CountryEnum.BRAZIL].includes(
    country as CountryEnum
  );

  const [merchantToRequest, setMerchantToRequest] = useState<string | null>(
    merchantId
  );
  const [isCentralized, setIsCentralized] = useState<boolean>(false);

  const [paginationState, setPaginationState] = useState<IPaginationState>({
    limit: 10,
    offset: 0,
  });

  const [filtered, setFiltered] = useState<IFilterSearch | undefined>(
    undefined
  );

  const [renderPayoutsCard, setRenderPayoutsCard] = useState<boolean>(false);

  const changePage = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPaginationState(({ limit }) => ({
      limit,
      offset: (value - 1) * limit,
    }));
  };

  const changeRowsPerPage = (value: number) => {
    setPaginationState({ limit: value, offset: 0 });
  };

  const page = getRowsPerPage(paginationState.offset, paginationState.limit);

  const origin: string = get(auth.getAuthMerchant(), "origin", "");

  const [state, setState] = useState<IState>({
    allProcessors: [],
    isAdmin: auth.isAdmin(),
    publicProcessorId: "",
    processorSelected: "",
    openDialogCancel: false,
    openModalDetail: false,
  });

  const {
    merchant: merchantInfo,
    defaultProcessor: defaultProcessors,
    defaultPayoutsTransferProcessor: defaultPayoutsProcessor,
    totalProcessors: total,
    allProcessors,
    refreshProcessors,
  } = useSelector((state: IAppState) => state);
  const dispatch = useDispatch();

  const [
    merchantProcessors,
    setMerchantProcessors,
  ] = useState<IMerchantProcessor>({
    card: [],
    transfer: [],
    achTransfer: [],
    payoutsTransfer: [],
    payoutsCard: [],
  });

  const form = useForm<IDefaultProcessorForm>({
    mode: "all",
    shouldUnregister: false,
    defaultValues: {
      card: "",
      transfer: "",
      achTransfer: "",
      payoutsCard: "",
      payoutsTransfer: "",
    },
  });

  const handleSubmitDefaultProcessor: SubmitHandler<IDefaultProcessorForm> = async (
    formData: IDefaultProcessorForm
  ): Promise<void> => {
    const payoutsTransferProcessor:
      | ProcessorFetch
      | undefined = allProcessors?.find(
      (processor: ProcessorFetch) =>
        processor.alias === formData.payoutsTransfer
    );
    const payoutsCardProcessor:
      | ProcessorFetch
      | undefined = allProcessors?.find(
      (processor: ProcessorFetch) => processor.alias === formData.payoutsCard
    );
    const requestPayoutsTransferProcessor: UpdateMerchantRulesRequest = {
      defaultProcessor: {
        payoutsCard: get(payoutsCardProcessor, "publicProcessorId", ""),
        payoutsTransfer: get(payoutsTransferProcessor, "publicProcessorId", ""),
      },
      publicMerchantId: defaultTo(merchantId, ""),
    };

    const requestProcessor: UpdateMerchantRulesRequest = {
      defaultProcessor: {
        ...omit(formData, ["defaultPayoutsTransferProcessor", "payoutsCard"]),
      },
      publicMerchantId: defaultTo(merchantId, ""),
    };

    setOpen(false);
    dispatch(saveDefaultProcessor(requestProcessor));
    dispatch(
      saveDefaultPayoutsTransferProcessor(requestPayoutsTransferProcessor)
    );
  };

  const handleOnChangeFailOver = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const value = event.target.value as keyof typeof state;
    const processorInfo: ProcessorFetch = state.processorInfo!;
    const failOver: string = isEqual(value, "None") ? "" : value;

    set(processorInfo, "failOver", failOver);
    setState({
      ...state,
      processorSelected: value,
      processorInfo,
      openModalDetail: false,
    });
  };

  useEffect(() => {
    setState({
      ...state,
      processors: props.state.processors,
    });
  }, [props.state.processors, paginationState]);

  const getCentralizedMerchantId = (
    centralized: boolean,
    publicMerchantId: string
  ) => {
    return centralized && !isEmpty(publicMerchantId)
      ? publicMerchantId
      : merchantId;
  };

  useEffect(() => {
    if (!merchantId) return;
    const centralized: boolean = get(
      basicInformation,
      "current.isCentralized",
      false
    );
    const publicMerchantId: string = get(
      basicInformation,
      "current.publicMerchantId",
      ""
    );
    const value: string | null = getCentralizedMerchantId(
      centralized,
      publicMerchantId
    );

    setIsCentralized(centralized);
    setMerchantToRequest(value);
    dispatch(getMerchantInfo(merchantId));
    setState({
      ...state,
      processors: undefined,
    });
    props.getDefaultProcessor(value as string);
    props.getDefaultPayoutsTransferProcessor(value as string);
    getProcessors(value as string, {
      limit: paginationState.limit,
      offset: paginationState.offset,
      filter: filtered,
    });
    getProcessors(value as string, {});
  }, [basicInformation, paginationState.offset, filtered]);

  useEffect(() => {
    if (!props.state.dialogClosed) return;
    handleCloseDialogFailOver();
  }, [props.state.dialogClosed]);

  const handleCloseModal = (): void => {
    setState({
      ...state,
      openModalDetail: false,
    });
  };

  const handleOpenDialogFailOver = (processor: ProcessorFetch): void => {
    setState({
      ...state,
      publicProcessorId: "",
      processorInfo: processor,
      processorSelected: get(processor, "failOverProcessor", "None"),
      openDialogCancel: true,
    });
  };

  const handleCloseDialogFailOver = (): void =>
    setState({
      ...state,
      publicProcessorId: "",
      openDialogCancel: false,
    });

  const handleOpenModalDetail = (processor: ProcessorFetch): void => {
    setState({ ...state, openModalDetail: true, processorInfo: processor });
  };

  const getProcessors = (
    merchantIdParam: string | null,
    filterToSend?: Filter
  ) => {
    props.getProcessors(merchantIdParam || "", filterToSend);
  };

  const handleAssignDialogFailOver = (): void => {
    const processorInfo: ProcessorFetch = replaceNull(state.processorInfo!);

    props.updateProcessor({
      notificationMessage: NotificationMessagesEnum.UPDATE_PROCESSOR_SUCCESS,
      notificationMessageError: NotificationMessagesEnum.UPDATE_PROCESSOR_ERROR,
      data: {
        failoverAlias: defaultTo(processorInfo.failOver, ""),
      },
      merchantId: processorInfo.merchantId,
      publicProcessorId: processorInfo.publicProcessorId,
      paymentMethod: PaymentMethodEnum.CARD,
    });
  };

  function replaceNull(someObj: object, replaceValue = "") {
    const replacer = (_key: any, value: any) => defaultTo(value, replaceValue);

    return JSON.parse(JSON.stringify(someObj, replacer));
  }

  const handleRefreshProcessors = (): void => {
    setState({
      ...state,
      processors: undefined,
    });
    getProcessors(merchantToRequest as string, {
      limit: paginationState.limit,
      offset: paginationState.offset,
    });
  };

  const formatProcessors = (processorMerchant: ProcessorFetch[]) => {
    processorMerchant = processorMerchant.filter(
      (processor: ProcessorFetch) =>
        get(processor, "status", "") !== ProcessorStatusEnum.DISABLED
    );

    const card = processorMerchant.filter(
      (processor) =>
        get(processor, "paymentMethod", "") === PaymentMethodEnum.CARD
    );
    const transfer = processorMerchant.filter(
      (processor) =>
        get(processor, "paymentMethod", "") === PaymentMethodEnum.TRANSFER
    );
    const achTransfer = processorMerchant.filter((processor) =>
      [
        PaymentMethodEnum.TRANSFER_SUBSCRIPTION,
        PaymentMethodEnum.ACH_TRANSFER,
      ].some((payment) => payment === get(processor, "paymentMethod", ""))
    );
    const payoutsTransfer = processorMerchant.filter(
      (processor) =>
        get(processor, "paymentMethod", "") ===
        PaymentMethodEnum.PAYOUTS_TRANSFER
    );

    const payoutsCard = processorMerchant.filter(
      (processor) =>
        get(processor, "paymentMethod", "") === PaymentMethodEnum.PAYOUTS_CARD
    );

    return {
      card,
      transfer,
      achTransfer,
      payoutsCard,
      payoutsTransfer,
    };
  };

  useEffect(() => {
    if (allProcessors) {
      const processors = formatProcessors(allProcessors);

      setMerchantProcessors(processors);
      form.setValue("card", get(defaultProcessors, "card", ""));
      form.setValue("transfer", get(defaultProcessors, "transfer", ""));
      form.setValue("achTransfer", get(defaultProcessors, "achTransfer", ""));
      form.setValue(
        "payoutsTransfer",
        get(defaultProcessors, "payoutsTransfer", "")
      );
      setState({
        ...state,
        allProcessors,
      });
    }
  }, [allProcessors]);

  useEffect(() => {
    if (
      allProcessors &&
      defaultPayoutsProcessor &&
      !isEmpty(defaultPayoutsProcessor.payoutsCard)
    ) {
      const defaultProcessorId: string = get(
        defaultPayoutsProcessor,
        processorTypeLabelEnum.PAYOUTS_CARD,
        ""
      );
      const defaultProcessorAlias:
        | ProcessorFetch
        | undefined = allProcessors.find(
        (processor) => processor.publicProcessorId === defaultProcessorId
      );

      form.setValue(
        processorTypeLabelEnum.PAYOUTS_CARD,
        get(defaultProcessorAlias, "alias", "")
      );
    }
  }, [allProcessors, defaultPayoutsProcessor]);

  const showConfigButton =
    merchantProcessors.card.length === 0 &&
    merchantProcessors.transfer.length === 0 &&
    merchantProcessors.achTransfer.length === 0 &&
    merchantProcessors.payoutsCard.length === 0;

  const [filters, setFilters] = useState<IOptionFilter[]>(DEFAULT_FILTERS);

  const [filterCount, setFilterCount] = useState<number>(0);

  const getCountFilters = (response: IFilterResponse) => {
    return filter(response.listFilter, (filter: IOptionFilter) =>
      some(filter.options, { selected: true })
    ).length;
  };

  const handleApplyFilterAmount = (response: IFilterResponse) => {
    setFilters(response.listFilter);

    changeRowsPerPage(10);

    const export_filter_selected: {
      filter: object;
    } = { filter: {} };

    response.listFilter.forEach((filter: IOptionFilter) => {
      const filter_parent: string[] = filter.options
        .filter((option) => option.selected)
        .map((option) => option.key);

      if (filter_parent.length > 0) {
        export_filter_selected.filter[filter.id] = filter_parent.join("|");
      }
    });

    setFilterCount(getCountFilters(response));

    let paymentMethod;
    let processorStatus = export_filter_selected.filter["status"];
    const paymentIn = export_filter_selected.filter["paymentMethodIn"];
    const paymentOut = export_filter_selected.filter["paymentMethodOut"];
    const nonExistingFields: string[] = [];

    if (paymentIn) {
      paymentMethod = paymentIn;
    }
    if (paymentOut) {
      paymentMethod = paymentOut;
    }
    if (paymentIn && paymentOut) {
      paymentMethod = paymentIn + "|" + paymentOut;
    }
    if (includes(processorStatus, ProcessorStatusEnum.ENABLED)) {
      processorStatus += "|";
      nonExistingFields.push(ProcessorProperties.PROCESSOR_STATUS);
    }

    setFiltered({
      nonExistingFields,
      paymentMethod,
      processorType: export_filter_selected.filter["processorType"],
      status: processorStatus,
    });
  };

  function addCardOutFilter(): void {
    if (renderPayoutsCard) {
      const defaultFilters: IOptionFilter[] = DEFAULT_FILTERS;
      const newFilters = [...defaultFilters];
      const optionIndex: number = defaultFilters.findIndex(
        (option) => option.id === ProcessorProperties.PAYMENT_METHOD_IN
      );
      const updatedOptions = [
        {
          key: paymentMethodPayOutEnum.CARD,
          label: filterLabels.CARD,
          selected: false,
        },
        ...defaultFilters[optionIndex].options.map((option) => ({ ...option })),
      ];

      newFilters[optionIndex] = {
        id: defaultFilters[optionIndex].id,
        title: defaultFilters[optionIndex].title,
        options: updatedOptions,
      };

      setFilters(newFilters);
    }
  }

  const handleClearFilters = () => {
    setFilterCount(0);
    addCardOutFilter();
    getProcessors(merchantToRequest, {
      limit: paginationState.limit,
      offset: paginationState.offset,
    });
  };

  useEffect(() => {
    if (refreshProcessors) {
      handleRefreshProcessors();
      dispatch(setRefreshProcessor(false));
    }
  }, [refreshProcessors]);

  useEffect(() => {
    const origin = get(basicInformation, "current.origin", "");
    if (origin !== OriginEnum.CONFIG_MERCHANT) {
      setRenderPayoutsCard(true);
    }
  }, [basicInformation]);

  useEffect(() => {
    addCardOutFilter();
  }, [renderPayoutsCard]);

  return {
    filters: {
      buttonFilter: {
        filterCount,
        filters,
        onApplyFilter: handleApplyFilterAmount,
        onClearFilter: handleClearFilters,
      },
    },
    isCentralized,
    isCountryIncluded,
    state,
    merchantId,
    origin,
    hideSideBar: hideSideBar === "true",
    modalConfig: {
      showConfigButton,
      form,
      open,
      setOpen,
      merchantProcessors,
      handleSubmitDefaultProcessor,
    },
    merchantName: get(basicInformation, "current.name", ""),
    handlers: {
      handleOpenDialogFailOver,
      handleCloseDialogFailOver,
      handleOnChangeFailOver,
      handleCloseModal,
      handleOpenModalDetail,
      handleAssignDialogFailOver,
      handleRefreshProcessors,
    },
    country: get(basicInformation, "current.country", ""),
    merchantInfo,
    defaultProcessors,
    defaultPayoutsTransferProcessor: defaultPayoutsProcessor,
    pagination: {
      changePage,
      changeRowsPerPage,
      page,
      rowsPerPage: paginationState.limit,
      total: total ?? 0,
    },
    renderPayoutsCard,
  };
};
