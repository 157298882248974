import { appSlice } from "../reducers/app/app";

export const {
  showLoading,
  hideLoading,
  hideAlert,
  setLoadingText,
  setMessageAlert,
  setTypeAlert,
  setValueTab,
  showAlert,
} = appSlice.actions;
