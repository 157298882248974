import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { GetSmartLinksResponse } from "../../../../types/smartlinks_response";
import { GetVposResponse } from "../../../../types/vpos_response";

export const getSmartLinks = createAsyncThunk<
  GetSmartLinksResponse,
  { publicMerchantId: string }
>("services/getSmartLinks", async ({ publicMerchantId }) => {
  const response = await axios.get<GetSmartLinksResponse>(
    API_ROUTES.GET_SMARTLINKS(publicMerchantId)
  );

  return response.data;
});

export const getVpos = createAsyncThunk<
  GetVposResponse,
  { publicMerchantId: string }
>("services/getVpos", async ({ publicMerchantId }) => {
  const response = await axios.get<GetVposResponse>(
    API_ROUTES.VPOS(publicMerchantId)
  );

  return response.data;
});
