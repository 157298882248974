/**
 * Button Styles Enum
 */

export enum ButtonVariantEnum {
  Contained = "contained",
  Outlined = "outlined",
}

export enum ButtonColorEnum {
  Primary = "primary",
  Info = "info",
  Error = "error",
}
