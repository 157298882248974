import React, { useState } from "react";
import { ModalOverlay } from "@kushki/frontend-molecules/modal";
import { ModalHeader } from "@kushki/frontend-molecules/modal-header";
import { Box, Typography, useTheme, Tabs, Tab } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { InformationTab } from "./InformationTab/InformationTab";
import { defaultTo, get } from "lodash";
import { COUNTRIES } from "../../infrastructure/constants/Countries";
import { ICountry } from "../../infrastructure/interfaces/ICountry";
import { format, utcToZonedTime } from "date-fns-tz";
import { ProcessorsTab } from "./ProcessorsTab/ProcessorsTab";
import { RatesTab } from "./RatesTab/RatesTab";
import { TIMEZONE_COUNTRY } from "../../infrastructure/constants/TimeZoneCountry";

export interface MerchantResume {
  merchantInfo?: {
    [k: string]: any;
  };
  ratesConfig?: {
    [k: string]: any;
  };
  rates?: {
    [k: string]: any;
  };
  processors?: {
    [k: string]: any;
  };
  defaultProcessor?: {
    [k: string]: any;
  };
}

interface ResumeProps {
  merchantResume: MerchantResume;
}

export const formatDate = (
  trxDate: string,
  formatDate: string,
  country: string
): string => {
  if (!trxDate) return "";
  return format(
    utcToZonedTime(trxDate, TIMEZONE_COUNTRY.get(country)!),
    formatDate
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabStyle: {
      textTransform: "none",
    },
    marginRightS: {
      marginRight: "10px",
    },
  })
);

const styles = (theme: Theme) => ({
  tabButton: {
    background: theme.palette.primary.light,
  },
  tabStyle: {
    textTransform: "none",
  },
  root: {
    padding: "0 !important",
  },
});

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function tabProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const ResumeModal: React.FC<ResumeProps> = (props: ResumeProps) => {
  const { merchantResume } = props;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [value, setValue] = useState<number>(0);
  const handleTabsChange = (
    _event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setValue(newValue);
  };
  const classes = useStyles(0);
  const theme = useTheme();
  const inlineStyles = styles(theme);
  const isValidMerchant = get(merchantResume, "merchantInfo", false);
  let isValidProcessor = false;
  const processorsTemp = get(merchantResume, "processors", []);
  const processorsKeys = Object.keys(processorsTemp);
  processorsKeys.map((key: string) => {
    if (get(processorsTemp, key, []).length > 0) {
      isValidProcessor = true;
    }
  });
  const isValidRates = get(merchantResume, "rates", []).length > 0;
  let countryFlag = null;
  if (isValidMerchant) {
    countryFlag = COUNTRIES.find(
      (country: ICountry) =>
        country.value === get(merchantResume, "merchantInfo.country")
    );
  }

  const tabButton = {
    text: "Resumen del comercio",
    style: inlineStyles.tabButton,
  };

  const tabs = isValidMerchant ? (
    <>
      <Tabs
        value={value}
        aria-label="billing tabs"
        variant="fullWidth"
        onChange={handleTabsChange}
        indicatorColor={"primary"}
        style={{
          padding: 0,
          borderBottom: "1px solid gray",
        }}
      >
        <Tab
          label={
            <Typography variant={"body1"} color={"textPrimary"}>
              Informacion
            </Typography>
          }
          {...tabProps(0)}
          className={classes.tabStyle}
        />
        <Tab
          disabled={!isValidProcessor}
          label={
            <Typography variant={"body1"} color={"textPrimary"}>
              Procesadores
            </Typography>
          }
          {...tabProps(0)}
          className={classes.tabStyle}
        />
        <Tab
          disabled={!isValidRates}
          label={
            <Typography variant={"body1"} color={"textPrimary"}>
              Tarifas
            </Typography>
          }
          {...tabProps(0)}
          className={classes.tabStyle}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <InformationTab
          merchantInfo={merchantResume.merchantInfo}
          ratesConfig={merchantResume.ratesConfig}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ProcessorsTab
          processors={merchantResume.processors}
          defaultProcessor={merchantResume.defaultProcessor}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <RatesTab
          rates={merchantResume.rates}
          ratesConfig={merchantResume.ratesConfig}
        />
      </TabPanel>
    </>
  ) : (
    <></>
  );
  const header = isValidMerchant ? (
    <ModalHeader
      highlightValue={get(merchantResume, "merchantInfo.name")}
      tittle={"Detalle del comercio"}
      secondaryValue={formatDate(
        get(merchantResume, "merchantInfo.created"),
        "dd MMM'.' yyyy HH:mm",
        get(merchantResume, "merchantInfo.country")
      )}
      identifierValue={get(merchantResume, "merchantInfo.publicMerchantId")}
      highlightIcon={
        <img
          src={get(countryFlag, "flag", "")}
          width="24px"
          height="24px"
          alt={get(countryFlag, "flag", "")}
          className={classes.marginRightS}
        />
      }
    ></ModalHeader>
  ) : (
    <></>
  );
  return (
    <ModalOverlay
      header={header}
      body={tabs}
      footer={<></>}
      onCloseModal={() => setIsModalOpen(false)}
      onOpenModal={() => setIsModalOpen(true)}
      openModal={isModalOpen}
      overlay={false}
      backdrop={false}
      transition={"left"}
      tabButton={tabButton}
    ></ModalOverlay>
  );
};
