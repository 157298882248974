/**
 * Catalog MX
 */

import { CatalogsEnum } from "../infrastructure/catalogs-enum";
import { CurrencyEnum } from "../infrastructure/currency-enum";
import { ICategory } from "../interfaces/ICategory";

export const CatalogMx: Record<string, ICategory[]> = {
  [CatalogsEnum.Banks]: [
    { name: "BANXICO", value: "02001" },
    { name: "BANCOMEXT", value: "37006" },
    { name: "BANOBRAS", value: "37009" },
    { name: "BANJERCITO", value: "37019" },
    { name: "NACIONAL FINANCIERA", value: "37135" },
    { name: "BANSEFI", value: "37166" },
    { name: "HIPOTECARIA FEDERAL", value: "37168" },
    { name: "BANAMEX", value: "40002" },
    { name: "BBVA BANCOMER", value: "40012" },
    { name: "BANCO SANTANDER", value: "40014" },
    { name: "HSBC", value: "40021" },
    { name: "BAJIO", value: "40030" },
    { name: "IXE", value: "40032" },
    { name: "INBURSA", value: "40036" },
    { name: "INTERACCIONES", value: "40037" },
    { name: "MIFEL", value: "40042" },
    { name: "SCOTIA BANK INVERLAT", value: "40044" },
    { name: "BANREGIO", value: "40058" },
    { name: "INVEX", value: "40059" },
    { name: "BANSI", value: "40060" },
    { name: "AFIRME", value: "40062" },
    { name: "BANORTE", value: "40072" },
    { name: "ROYAL BANK OF SCOTLAND", value: "40102" },
    { name: "AMERICAN EXPRESS", value: "40103" },
    { name: "BANK OF AMERICA", value: "40106" },
    { name: "TOKYO", value: "40108" },
    { name: "JP MORGAN", value: "40110" },
    { name: "BANCO MONEX", value: "40112" },
    { name: "VE POR MAS", value: "40113" },
    { name: "ING", value: "40116" },
    { name: "DEUTCHE", value: "40124" },
    { name: "CREDIT SUISSE", value: "40126" },
    { name: "AZTECA", value: "40127" },
    { name: "BANCO AUTOFIN", value: "40128" },
    { name: "BARCLAYS BANK", value: "40129" },
    { name: "BANCO COMPARTAMOS", value: "40130" },
    { name: "BANCO DE AHORRO FAMSA", value: "40131" },
    { name: "BANCO MULTIVA", value: "40132" },
    { name: "PRUDENTIAL BANK", value: "40133" },
    { name: "BANCO WAL MART", value: "40134" },
    { name: "BANCO REGIONAL SA", value: "40136" },
    { name: "BANCOPPEL", value: "40137" },
    { name: "BANCO AMIGO", value: "40138" },
    { name: "UBS BANK MEXICO", value: "40139" },
    { name: "BANCO FACIL", value: "40140" },
    { name: "VOLKSWAGEN BANK", value: "40141" },
    { name: "BANCO CONSULTORIA", value: "40143" },
    { name: "ALQUIMIAPAY", value: "659" },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { name: "Corriente", value: "0" },
    { name: "Ahorros", value: "1" },
  ],
  [CatalogsEnum.Currency]: [
    { name: "Pesos", value: CurrencyEnum.MXN },
    { name: "Dólares", value: CurrencyEnum.USD },
  ],
  [CatalogsEnum.IdTypes]: [
    { name: "CURP", value: "0" },
    { name: "Pasaporte", value: "1" },
    { name: "RFC", value: "3" },
  ],
  [CatalogsEnum.PersonTypes]: [
    { name: "Física", value: "01" },
    { name: "Moral", value: "02" },
  ],
};
