import {
  IUseTableNodesRequest,
  IUseTableTransactionsInterfaces,
} from "./IUseTableTransactions.interfaces";
import React, { useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../store/hooks/storeHook";
import { RootState } from "../../../../store/store";
import { verifyRol } from "../../../../shared/utils/transaction_container_utils";
import {
  Transaction,
  TransactionInfo,
} from "../../../../../types/transactions_data";
import { find, get, isNil } from "lodash";
import { ITableRowProps, Order } from "../TableTransactions.interfaces";
import {
  deleteBranchColumn,
  setAllBranchColumn,
  setBranchColumn,
} from "../../../../store/actions/app.actions";
import {
  verifyAllSelectedRows,
  verifySelectedRows,
} from "../../../../shared/utils/table_transactions_utils";

export const useTableTransactions = ({
  rowsPerPage,
  totalData,
  rows,
  handleSelectedRows,
  resetItemChecked,
  setTransactionInfo,
  setIsOpenModalDetailInfo,
}: IUseTableNodesRequest): IUseTableTransactionsInterfaces => {
  const dispatch = useAppDispatch();
  const [order] = React.useState<Order>("asc");
  const [indeterminate, setIndeterminate] = React.useState<boolean | undefined>(
    false
  );
  const [allChecked, setAllChecked] = React.useState<boolean>(false);
  const [orderBy] = React.useState<string>("line112343242");
  const totalPages = totalData > 0 ? Math.ceil(totalData / rowsPerPage) : 0;
  const isUserRole = verifyRol();

  const { isLoadingTransaction, selectedBranchColumns } = useAppSelector(
    (state: RootState) => ({
      ...state.app,
    })
  );

  const isSelected = (data: TransactionInfo): boolean =>
    verifySelectedRows(selectedBranchColumns, data);

  const handleClick = (data: TransactionInfo) => {
    const selectedIndex: number = selectedBranchColumns.indexOf(data);

    if (selectedIndex === -1) dispatch(setBranchColumn(data));
    else dispatch(deleteBranchColumn(selectedIndex));
  };

  const getAllSelected = () => rows.map((n) => (<Transaction>n.info)._source!);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllChecked(event.target.checked);
    if (event.target.checked) {
      dispatch(setAllBranchColumn(getAllSelected()));
      setIndeterminate(undefined);

      return;
    }
    dispatch(setAllBranchColumn([]));
    setIndeterminate(false);
  };

  useEffect(() => {
    handleSelectedRows(selectedBranchColumns);
    if (selectedBranchColumns.length > 0 && !isNil(indeterminate))
      setIndeterminate(
        !verifyAllSelectedRows(selectedBranchColumns, getAllSelected())
      );
  }, [selectedBranchColumns]);

  useEffect(() => {
    if (!isNil(resetItemChecked)) {
      setIndeterminate(false);
      dispatch(setAllBranchColumn([]));
    }
  }, [resetItemChecked]);

  const findDataByRowId = (rowId: string): Transaction => {
    const value: ITableRowProps | undefined = find(rows, ["id", rowId]);

    return get(value, "info") as Transaction;
  };

  const handleGetRow = (rowId: string, openModal: boolean) => {
    setTransactionInfo(findDataByRowId(rowId));
    setIsOpenModalDetailInfo(openModal);
  };

  return {
    handleGetRow,
    headerProps: {
      order,
      orderBy,
    },
    isLoadingTransaction,
    isUserRole,
    rowsProps: {
      check: {
        allChecked,
        handleClick,
        handleSelectAllClick,
        indeterminate,
        isSelected,
        selected: selectedBranchColumns,
      },
    },
    totalPages,
  };
};
