import React from "react";
import { Box, Grid, Stack } from "@mui/material";
import { InformationTabStyles } from "./InformationTab.styles";
// import CardIcon from "../../../../common/CardIcon/CardIcon";
import { TransferMethod } from "../../../../../assets/icons/PaymentMethods";
import RefundCustomerInfo from "./components/RefundCustomerInfo/RefundCustomerInfo";
import InformationRow from "./components/InformationRow/InformationRow";
import { InformationTabProps } from "./InformationTab.interfaces";
import RefundProcessorInfo from "./components/RefundProcessorInfo/RefundProcessorInfo";
import { formatCurrency } from "../../../../../shared/utils/currency_utils";
import { RefundStatusEnum } from "../../../../../shared/enums/RefundStatusEnum";

const InformationTab = ({
  transaction,
  showProcessorInfo = true,
}: InformationTabProps) => {
  const {
    amount,
    bank_name,
    currency,
    customer_name,
    document_number,
    processor_name,
    refund_retries,
    status,
  } = transaction;

  return (
    <Box sx={InformationTabStyles.container}>
      <Grid container sx={InformationTabStyles.informationCard}>
        <RefundCustomerInfo
          customerName={customer_name}
          bankName={bank_name}
          paymentMethod="Transferencia"
          documentNumber={document_number}
          PaymentMethodIcon={<TransferMethod />}
        />
      </Grid>
      <Grid container sx={InformationTabStyles.informationCard}>
        <InformationRow
          label="Monto aprobado"
          value={`${formatCurrency(amount)} ${currency}`}
        />
      </Grid>
      {showProcessorInfo && status !== RefundStatusEnum.PENDING && (
        <Stack sx={InformationTabStyles.informationCard} spacing={1}>
          <RefundProcessorInfo
            name={processor_name || "--"}
            message={
              status === RefundStatusEnum.APPROVED
                ? "Transacción aprobada"
                : "--"
            }
            retries={refund_retries > 0 ? `${refund_retries}` : "No"}
          />
        </Stack>
      )}
    </Box>
  );
};

export default InformationTab;
