import React from "react";
import { Box, Button, Grid, CircularProgress } from "@material-ui/core";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { SecurityRulesRoles } from "../../shared/infrastructure/constants/SecurityRulesConstants";

export interface WhitelistActionButtonsProps {
  onCancel: () => void;
  onAdd: () => void;
  disabled: boolean;
  mainActionText: string;
  secondaryActionText: string;
}

export const WhitelistActionButtons: React.FC<WhitelistActionButtonsProps> = (
  props: WhitelistActionButtonsProps
) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="flex-start"
          justify="flex-end"
          direction={"row"}
        >
          <Box mr={3} pt={4}>
            <Button
              size="large"
              color="default"
              onClick={props.onCancel}
              disabled={props.disabled}
            >
              {props.secondaryActionText}
            </Button>
          </Box>
          <Box pt={4}>
            <ComponentSecurityWrapper
              componentId={`${SecurityRulesRoles.M_FRAUD_PREVENTION}.${SecurityRulesRoles.WHITELIST_ADD}`}
            >
              <Button
                id={"add-button"}
                disabled={props.disabled}
                size="large"
                fullWidth
                variant="contained"
                color="primary"
                onClick={props.onAdd}
              >
                {props.mainActionText}
                {props.disabled && (
                  <Box ml={1} display="flex" alignItems="center">
                    <CircularProgress color="inherit" size={20} />
                  </Box>
                )}
              </Button>
            </ComponentSecurityWrapper>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
