import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const webhookConfigStyles = createNamedStyles({
  paper: {
    bgcolor: "neutral.grey2",
    borderRadius: 1,
    padding: "40px 24px",
  },
  title: {
    textAlign: "center",
    width: "198px",
  },
});
