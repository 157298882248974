import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import { ConfirmModal } from "../ConfirmModal";
import { defaultTo, get } from "lodash";
import { RetentionAmountsInfoCard } from "../../RetentionAmountsInfoCard/RetentionAmountsInfoCard";
import { TransactionDynamo } from "../../../../types/remote/transaction_dynamo";
import { RetentionAmountsForm } from "../../RetentionAmountsForm/RetentionAmountsForm";

export interface EditRetentionConfirmModalProps {
  open: boolean;
  disableAccept: boolean;
  handleCloseEditRetention: () => void;
  handleAcceptEditRetention: () => void;
  retention: Partial<TransactionDynamo>;
}

export const EditRetentionModal: React.FC<EditRetentionConfirmModalProps> = (
  props: React.PropsWithChildren<EditRetentionConfirmModalProps>
) => {
  const [openModalEditRetention, setOpenModalEditRetention] = useState(
    props.open
  );
  useEffect(() => {
    setOpenModalEditRetention(props.open);
  }, [props.open, openModalEditRetention]);
  return (
    <ConfirmModal
      open={openModalEditRetention}
      titleText={"¿Deseas modificar los montos de retenciones?"}
      contentText={
        <>
          <Typography>
            Estas a punto de modificar los montos iniciales de esta operación.
            Al modificarlos automáticamente se alterará el monto total de
            retención del comercio {get(props, "retention.merchantName", "")}
          </Typography>
        </>
      }
      acceptText="Modificar monto"
      acceptColor="primary"
      handleClose={props.handleCloseEditRetention}
      handleAccept={props.handleAcceptEditRetention}
      disableAccept={props.disableAccept}
    >
      <React.Fragment>
        <RetentionAmountsInfoCard
          currency={defaultTo(props.retention.currency, "")}
          initialAmountRetentionIva={defaultTo(props.retention.retIva, 0)}
          initialAmountRetentionFue={defaultTo(props.retention.retFue, 0)}
          initialTotalAmount={defaultTo(props.retention.retTotalAmount, 0)}
        />
        <RetentionAmountsForm />
      </React.Fragment>
    </ConfirmModal>
  );
};
