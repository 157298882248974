import { CustomStyles } from "../../shared/infrastructure/interfaces/CustomStyles";
export const BillingResumeStyles: CustomStyles = {
  papper: {
    backgroundColor: "neutral.grey3",
    width: "100%",
    padding: "24px",
    borderRadius: "8px",
  },
  title: {
    fontWeight: 600,
    fontSize: "16px",
  },
  subtitle: {
    fontSize: "13px",
  },
  fields: {
    margin: "10px 0",
  },
  chip: {
    borderRadius: "8px",
    color: "#000",
    backgroundColor: "rgb(226, 232, 240)",
    fontWeight: 600,
    margin: "5px 5px 0 0",
    fontSize: "14px",
  },
};
