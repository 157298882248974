import React, { FC } from "react";
import { Box } from "@mui/material";
import BranchManualCreationForm from "../../components/BranchManualCreationForm/BranchManualCreationForm";
import { branchManualCreationContainerStyles as styles } from "./BranchManualCreationContainer.styles";

const BranchManualCreationContainer: FC = () => {
  return (
    <>
      <Box sx={styles.boxContainer}>
        <BranchManualCreationForm />
      </Box>
    </>
  );
};

export default BranchManualCreationContainer;
