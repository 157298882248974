import { useEffect, useState } from "react";
import { useAppNavigate } from "../../../shared/hooks/useNavigate";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import axios from "../../../shared/axios-util";
import { useLocation } from "react-router-dom";
import { get } from "lodash";
import { INodeInfoResponse } from "../../../../types/node_info_response";
import { QueryParamsEnum } from "../../../shared/enums/queryParamsEnum";
import { setNodeInfoResponse } from "../../../store/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  selectGetWebhookList,
  selectNodeInfo,
  selectWebhookList,
} from "../../../store/selectors/selectors";
import {
  ConfigPropName,
  ConfigurationIdEnum,
} from "../../../shared/enums/ConfigurationIdEnum";
import { validIsCentralized } from "../../../shared/utils/centralized/validIsCentralized";

export interface IUseMainContainer {
  onRedirectWebhook: () => void;
  isEmpty: boolean;
  isCentralized: boolean;
}

export const useMainContainer = (): IUseMainContainer => {
  const { navigate } = useAppNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const publicMerchantId = searchParams.get(QueryParamsEnum.PUBLIC_MERCHANT_ID);
  const dispatch = useDispatch();
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [isCentralized, setIsCentralized] = useState<boolean>(false);

  const webhookList = useSelector(selectWebhookList);
  const webhookResponse = useSelector(selectGetWebhookList);
  const nodeInfo = useSelector(selectNodeInfo);

  const handleRedirectWebhook = () => {
    navigate("/../merchant-webhook/create");
  };

  useEffect(() => {
    const callNodeInfoApi = async () => {
      let result = await axios.post<INodeInfoResponse>(
        API_ROUTES.BILLING_CORE_NODE,
        {
          publicMerchantId: publicMerchantId,
        }
      );

      dispatch(setNodeInfoResponse(result.data));
    };

    callNodeInfoApi();
  }, []);

  useEffect(() => {
    if (webhookResponse === "success") {
      if (webhookList.items.length === 0) {
        setIsEmpty(true);

        return;
      }
      setIsEmpty(false);
    }
  }, [webhookResponse, webhookList]);

  useEffect(() => {
    if (nodeInfo) {
      const entityName: string = nodeInfo.entityName;
      let publicMerchantIdInArr: string = "";

      nodeInfo?.configs.forEach((obj: any) => {
        if (obj.configuration === ConfigurationIdEnum.CN014) {
          setIsCentralized(
            validIsCentralized(
              entityName,
              get(obj, ConfigPropName.CentralizedNodeId, "")
            )
          );
          publicMerchantIdInArr = obj.value;
        }
      });

      if (publicMerchantIdInArr === "") {
        setIsEmpty(true);
      }
    }
  }, [nodeInfo]);

  return {
    isCentralized,
    isEmpty,
    onRedirectWebhook: handleRedirectWebhook,
  };
};
