import * as React from "react";
import { SvgIcon as MuiSvgIcon, styled, SvgIconProps } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../Themes/themekushki";

const SvgIcon = styled(MuiSvgIcon, {
  name: "MopeimIcon",
  shouldForwardProp: (prop) => prop !== "fill",
})<SvgIconProps>(({ color, fontSize }) => ({
  fill: color + "!important",
  fontSize: fontSize,
}));

SvgIcon.defaultProps = {
  "aria-hidden": "true",
  focusable: "false",
  viewBox: "0 0 24 24",
};

const IconTrash: React.FunctionComponent<SvgIconProps> = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <SvgIcon
        {...props}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11.4893 15.1163H5.48931C5.32242 15.117 5.16135 15.0551 5.03791 14.9427C4.91448 14.8304 4.83766 14.6759 4.82264 14.5097L3.82264 3.843C3.81494 3.75115 3.82637 3.65871 3.8562 3.5715C3.88604 3.48429 3.93363 3.40422 3.99598 3.33633C4.05831 3.26727 4.1344 3.21202 4.21936 3.17413C4.30433 3.13624 4.39628 3.11655 4.48931 3.11633H12.4893C12.5823 3.11655 12.6743 3.13624 12.7593 3.17413C12.8442 3.21202 12.9203 3.26727 12.9826 3.33633C13.045 3.40422 13.0926 3.48429 13.1224 3.5715C13.1523 3.65871 13.1637 3.75115 13.156 3.843L12.156 14.5097C12.141 14.6759 12.0641 14.8304 11.9407 14.9427C11.8173 15.0551 11.6562 15.117 11.4893 15.1163ZM6.09598 13.783H10.8826L11.756 4.44967H5.22264L6.09598 13.783Z" />
        <path d="M13.1543 4.44967H3.82096C3.64415 4.44967 3.47458 4.37943 3.34956 4.2544C3.22453 4.12938 3.1543 3.95981 3.1543 3.783C3.1543 3.60619 3.22453 3.43662 3.34956 3.3116C3.47458 3.18657 3.64415 3.11633 3.82096 3.11633H13.1543C13.3311 3.11633 13.5007 3.18657 13.6257 3.3116C13.7507 3.43662 13.821 3.60619 13.821 3.783C13.821 3.95981 13.7507 4.12938 13.6257 4.2544C13.5007 4.37943 13.3311 4.44967 13.1543 4.44967Z" />
        <path d="M10.1543 2.44964H6.82096C6.64415 2.44964 6.47458 2.3794 6.34956 2.25437C6.22453 2.12935 6.1543 1.95978 6.1543 1.78297C6.1543 1.60616 6.22453 1.43659 6.34956 1.31156C6.47458 1.18654 6.64415 1.1163 6.82096 1.1163H10.1543C10.3311 1.1163 10.5007 1.18654 10.6257 1.31156C10.7507 1.43659 10.821 1.60616 10.821 1.78297C10.821 1.95978 10.7507 2.12935 10.6257 2.25437C10.5007 2.3794 10.3311 2.44964 10.1543 2.44964Z" />
      </SvgIcon>
    </ThemeProvider>
  );
};

export default IconTrash;
