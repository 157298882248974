import { Skeleton } from "@material-ui/lab";
import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";

export interface SkeletonFormProps {
  items: number;
  type: "v1" | "v2";
}

export const SkeletonForm: React.FC<SkeletonFormProps> = (
  props: SkeletonFormProps
) => {
  return (
    <React.Fragment>
      {[...Array(props.items)].map((_: string, index: number) => (
        <Box key={`skl_${index}`}>
          {props.type === "v1" && (
            <Grid
              id={"loadv1"}
              container
              spacing={3}
              key={`skeleton_fm_${index}`}
            >
              <Grid item xs={12}>
                <Typography component="div" variant={"h5"}>
                  <Skeleton />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Skeleton animation="wave" height={"50px"} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton animation="wave" height={"50px"} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton animation="wave" height={"50px"} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton animation="wave" height={"50px"} />
              </Grid>
            </Grid>
          )}
          {props.type === "v2" && (
            <Grid
              id={"loadv2"}
              container
              spacing={3}
              key={`skeleton_fm_${index}`}
              style={{ marginBottom: "25px" }}
            >
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Typography component="div" variant={"h5"}>
                      <Skeleton />
                    </Typography>
                    <Typography component="div" variant={"h6"}>
                      <Skeleton />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Skeleton animation="wave" height={"50px"} />
              </Grid>
              <Grid item xs={3}>
                <Skeleton animation="wave" height={"50px"} />
              </Grid>
              <Grid item xs={3}>
                <Skeleton animation="wave" height={"50px"} />
              </Grid>
              <Grid item xs={2}>
                <Skeleton animation="wave" height={"50px"} />
              </Grid>
            </Grid>
          )}
        </Box>
      ))}
    </React.Fragment>
  );
};
