import { useEffect, useRef, useState } from "react";
import { concat, debounce, defaultTo, get, isEmpty, set } from "lodash";
import {
  FILTER_GROUPS,
  TITLE_SIDEBAR,
} from "../../../shared/enum/FilterLabelUsersEnum";
import { merchantIdBranchesJoined } from "../../../shared/utils/usersTableUtils";
import {
  TFilterDetailBar,
  TOnChangeFilters,
} from "@kushki/connect-ui/dist/Components/Molecules/Filters/FilterDetailBar/FilterDetailBar.interfaces";
import { ItemCategoryProps } from "@kushki/connect-ui/dist/Components/Molecules/Filters/ItemCategory/interfaces";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { RootState } from "../../../store/store";
import { EntityEnum } from "../../../shared/enum/EntityEnum";
import { MerchantStatusEnum } from "../../../shared/enum/MerchantStatusEnum";
import { changeItemCategoryToFilterDetailBar } from "../../../shared/utils/filterDetailBarUtil";
import { searchMerchants } from "../../../store/thunks/users/users.thunk";
import {
  buildRoleItemsCategory,
  buildStatusItemsCategory,
} from "../../../shared/utils/buildItemsCategory";
import { IUHeaderFiltersUser } from "./useHeaderFilterUser.interfaces";
import { IHeaderFiltersUserProps } from "../HeaderFilterUser.interfaces";
import { IFiltersSearchRequest } from "../../../containers/UsersContainer/UsersContainer.interfaces";

export const useHeaderFilterUser = (
  props: IHeaderFiltersUserProps
): IUHeaderFiltersUser => {
  const { searchMerchant, nodeInfoCustomer } = useAppSelector(
    (state: RootState) => state.users
  );
  const dispatch = useAppDispatch();
  const [requestSearch, setRequestSearch] = useState({
    entityName: EntityEnum.BRANCH,
    filter: {
      merchantStatus: MerchantStatusEnum.ACTIVE,
    },
    limit: 10,
    name: "",
    offset: 0,
    path: "",
    strictSearch: false,
  });

  const firstChargeBranches = useRef(true);
  const searchBranches = (value: string) => {
    firstChargeBranches.current = false;

    setRequestSearch((prevState) => ({ ...prevState, name: value }));
  };

  useEffect(() => {
    setRequestSearch((prevState) => ({
      ...prevState,
      path: get(nodeInfoCustomer, "path", ""),
    }));
  }, [nodeInfoCustomer.path]);

  useEffect(() => {
    if (!isEmpty(requestSearch.name)) dispatch(searchMerchants(requestSearch));
  }, [requestSearch.name]);

  const debouncedOnChange = debounce(searchBranches, 500);

  const [categoryItems, setCategoryItems] = useState<ItemCategoryProps[]>([
    {
      isMinimize: true,
      items: buildRoleItemsCategory(),
      onItemsSelect: () => {},
      placeHolder: FILTER_GROUPS.ROLE,
      selectType: "single",
      title: FILTER_GROUPS.ROLE,
      type: "byChips",
    },
    {
      isMinimize: true,
      items: buildStatusItemsCategory(),
      onItemsSelect: () => {},
      placeHolder: FILTER_GROUPS.STATE,
      selectType: "single",
      title: FILTER_GROUPS.STATE,
      type: "byChips",
    },
    {
      displaySelectedChipsBelow: true,
      isLoading: false,
      isMinimize: true,
      items: [],
      mergePreviousSelections: true,
      onChangeTextField: debouncedOnChange,
      onItemsSelect: () => "",
      onlyPlaceholderInput: true,
      persistInputText: true,
      placeHolder: "Selecciona uno o varios",
      selectType: "multiple",
      title: FILTER_GROUPS.BRANCH,
      type: "bySelectCheck",
      useFilteredSelection: true,
    },
  ]);

  const [filterDetailBar, setFilterDetailBar] = useState<TFilterDetailBar[]>(
    changeItemCategoryToFilterDetailBar(categoryItems)
  );
  const firstRender = useRef(true);
  const onApply = (selected: ItemCategoryProps[]) => {
    setFilterDetailBar(changeItemCategoryToFilterDetailBar(selected));
  };
  const onChangeFilterDetailBar = (changeFilter: TOnChangeFilters) => {
    setFilterDetailBar(changeFilter.newArgs);
    categoryItems.map((item) => {
      if (
        defaultTo(item.title, item.placeHolder) === changeFilter.affected.title
      )
        item.items.map((subItem) => {
          if (subItem.label === changeFilter.affected.itemDeleted.label)
            subItem.selected = false;
        });
    });

    setCategoryItems(categoryItems);
  };
  const buildRequestSidebarFilters = (filterSelected: TFilterDetailBar[]) => {
    let concatBranches: string = "";
    const filterRequest: Partial<IFiltersSearchRequest> = {};

    const roleFilter = filterSelected.filter(
      (section) => section.title === FILTER_GROUPS.ROLE
    );
    const statusFilter = filterSelected.filter(
      (section) => section.title === FILTER_GROUPS.STATE
    );

    set(filterRequest, "path", get(nodeInfoCustomer, "path", ""));
    filterSelected.map((filter: TFilterDetailBar) => {
      switch (filter.title) {
        case FILTER_GROUPS.BRANCH:
          concatBranches = merchantIdBranchesJoined(
            get(filter, "items", []),
            "value"
          );
          set(filterRequest, "merchantId", concatBranches);
          set(filterRequest, "branchList", get(searchMerchant, "data"));
          break;
      }
    });

    set(filterRequest, "role", get(roleFilter[0], "items[0].value", ""));
    set(filterRequest, "status", get(statusFilter[0], "items[0].value", ""));

    return filterRequest;
  };

  useEffect(() => {
    const filtered: ItemCategoryProps[] = [];
    const aux_category = categoryItems.map((item) => {
      if (item.title === "Branch") {
        const actual_items = item.items.map((actualItem) => actualItem.value);

        item.items = concat(
          item.items,
          defaultTo(get(searchMerchant, "data"), [])
            .filter((itemBr) => !actual_items.includes(itemBr.merchant_id!))
            .map((itemBranch) => ({
              label: itemBranch.name!,
              selected: firstChargeBranches.current,
              value: itemBranch.merchant_id!,
            }))
        );
      }
      const aux_selected = item.items.filter(
        (itemFilter) => itemFilter.selected
      );

      if (aux_selected.length > 0) {
        filtered.push({ ...item, items: aux_selected });
      }

      return item;
    });

    setCategoryItems(aux_category);
    if (
      firstChargeBranches.current &&
      defaultTo(searchMerchant?.data, []).length > 0
    ) {
      onApply(filtered);
    }
  }, [searchMerchant]);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;

      return;
    }
    props.onChangeFilterSidebar(buildRequestSidebarFilters(filterDetailBar));
  }, [filterDetailBar]);

  return {
    filterDetailBar,
    filterSideBar: {
      categoryItems,
      filtersCount: 0,
      isMinimized: false,
      isOpen: false,
      onClick: onApply,
      title: TITLE_SIDEBAR,
    },
    onChangeFilterDetailBar,
  };
};
