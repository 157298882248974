import CloseIcon from "@material-ui/icons/Close";
import { defaultTo, get } from "lodash";
import React from "react";
import { Alert } from "@material-ui/lab";
import { DateRangePickerComponent } from "../DateRangePickerComponent/DateRangePickerComponent";
import { DownloadButtonComponent } from "../DownloadButtonComponent/DownloadButtonComponent";
import { FilterComponent } from "../FilterComponent.test.tsx/FilterComponent";
import { Grid } from "@material-ui/core";
import { WalletHeader } from "../WalletHeader/WalletHeader";
import { WalletTable } from "../WalletTable/WalletTable";
import { useWalletDashboard } from "./state/useWalletDashboard";
import { TransactionDetail } from "../TransactionDetail/TransactionDetail";
import SearchField from "../SearchField/SearchField";
import { walletDashboardStyle } from "./WalletDashboard.style";
import { LabelsWallet } from "../../shared/infrastructure/enums/LabelWalletEnum";

export const WalletDashboard = () => {
  const classes = walletDashboardStyle();
  const { showAlertOnGetTransactionsError, state, handlers } =
    useWalletDashboard();

  const merchantData = defaultTo(
    JSON.parse(`${window.localStorage.getItem("merchantBasicInformation")}`),
    {}
  );

  const country: string = get(merchantData, "country", "");

  return (
    <>
      {showAlertOnGetTransactionsError && (
        <Alert
          icon={<CloseIcon fontSize="inherit" />}
          className={classes.errorAlert}
        >
          {LabelsWallet.ERROR_GET_TRANSACTIONS}
        </Alert>
      )}
      <Grid container direction={"column"} className={classes.mainGrid}>
        <Grid item xs={12}>
          <WalletHeader
            filterComponent={<FilterComponent />}
            pickerComponent={<DateRangePickerComponent />}
            downloadButtonComponent={<DownloadButtonComponent />}
            searchComponent={<SearchField />}
          />
        </Grid>
        <Grid item xs={12}>
          <WalletTable
            country={country}
            handleTransactionSelected={handlers.handleTransactionSelected}
          />
        </Grid>
        {state.transactionSelected && (
          <TransactionDetail
            transaction={state.transactionSelected}
            openModal={state.openModal}
            handleCloseModal={handlers.handleCloseModal}
            country={country}
          />
        )}
      </Grid>
    </>
  );
};
