/**
 * Modal Info Enum
 */
export enum ModalInfoTitleEnum {
  DELETE_DEFAULT_PROCESSOR = "¿Deseas eliminar este procesador?",
  DELETE_PROCESSOR = "¿Deseas eliminar el procesador {processorName}?",
  DISABLE_PROCESSOR = "¿Deseas deshabilitar este procesador?",
  ENABLE_PROCESSOR = "¿Deseas habilitar este procesador?",
}

export enum ModalInfoDescriptionEnum {
  DISABLE_PROCESSOR = "Si deshabilitas este procesador ya no bold(|podrás transaccionar) con él hasta que se vuelva a habilitar.",
  ENABLE_PROCESSOR = "Puedes editar la configuración del procesador antes de habilitarlo nuevamente. Si no se edita se habilitará con las configuraciones que tenía anteriormente.",
  DISABLE_FAILOVER_PROCESSOR = "Este procesador fue seleccionado como failOver para {processorName}. Si lo deshabilitas, bold(|no habrá Fail Over hasta que se vuelva a habilitar o selecciones otro.)",
  DISABLE_DEFAULT_PROCESSOR = "Este procesador fue seleccionado por defecto. Si deseas deshabiltarlo, bold(|debes configurar otro procesador por defecto.)",
  DELETE_DEFAULT_PROCESSOR = "Este procesador fue seleccionado por defecto. Si deseas eliminarlo, bold(|debes configurar otro procesador por defecto.)",
  DELETE_PROCESSOR = "Si eliminas este procesador, esta acción no podrá deshacerse.",
}

export enum ModalInfoButtonTextEnum {
  CANCEL = "Cancelar",
  AGREE = "Aceptar",
  DISABLE_PROCESSOR = "Deshabilitar procesador",
  ENABLE_PROCESSOR = "Solo habilitar",
  EDIT_PROCESSOR = "Editar",
  DELETE_PROCESSOR = "Eliminar procesador",
}

export enum ModalInfoTypesEnum {
  DISABLE_PROCESSOR = "DISABLE_PROCESSOR",
  ENABLE_PROCESSOR = "ENABLE_PROCESSOR",
  DISABLE_FAILOVER_PROCESSOR = "DISABLE_FAILOVER_PROCESSOR",
  DISABLE_DEFAULT_PROCESSOR = "DISABLE_DEFAULT_PROCESSOR",
  DELETE_PROCESSOR = "DELETE_PROCESSOR",
  DELETE_DEFAULT_PROCESSOR = "DELETE_DEFAULT_PROCESSOR",
}
