import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import {
  Alert,
  IconAlertTriangle,
  IconBadge,
  IconBank,
  IconChartSmall,
  IconChevronLeft,
  IconMixer,
  IconUser,
  IconWallet,
  ModalLoader,
} from "@kushki/connect-ui";
import BaseDataContainer from "../../components/commons/BaseDataContainer/BaseDataContainer";
import BillingData from "../../components/BillingData/BillingData";
import BillingResume from "../../components/BillingResume/BillingResume";
import { BusinessRulesMemo } from "../../components/BusinessRules/BusinessRules";
import ContactsIndex from "../../components/ContactCard/ContactsIndex";
import DispersionBlock from "../../components/DispersionBlock/DispersionBlock";
import ChargesBlock from "../../components/ChargesBlock/ChargesBlock";
import FooterOptions from "../../components/commons/FooterOptions/FooterOptions";
import { BasicData } from "../../components/BasicData/BasicData";
import { CustomSkeleton } from "../../components/commons/Skeleton/Skeleton";
import { MerchantInformation } from "../../components/MerchantInformation/MerchantInformation";
import { ResumeContainer } from "../../components/ResumeContainer/ResumeContainer";
import { Shareholders } from "../../components/Shareholders/Shareholders";
import UserListResume from "../../components/UserListResume/UserListResume";
import { LabelEnums } from "../../shared/enums/LabelEnums";
import { usersTextEnum } from "../../shared/infrastructure/constants/UserResumeConstants";
import { merchantResumeStyles as styles } from "./MerchantResume.styles";
import { useMerchantResume } from "./state/useMerchantResume";
import { routes } from "../../shared/infrastructure/constants/routes";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import BranchesResume from "../../components/BranchesResume/BranchesResume";
import { ModalSimpleSecurity } from "../../components/commons/ModalSimpleSecurity/ModalSimpleSecurity";
import { ModalBranchCreate } from "../../components/ModalBranchCreate/ModalBranchCreate";
import { SecurityWrapperEnum } from "../../shared/enums/SecurityWrapperEnum";
import { DeferredListMemo } from "../../components/DeferredList/DeferredList";
import { CustomerWebhooks } from "../../components/CustomerWebhooks/CustomerWebhooks";
import ServiceConfiguration from "../../components/ServiceConfiguration/ServiceConfiguration";
import { Section } from "../../shared/enums";
import Credentials from "../../components/Credentials/Credentials";
import RatesAndDiscountResume from "../../components/RatesAndDiscountResume/RatesAndDiscountResume";
import Processors from "../../components/Processors/Processors";
import WebhookNotifications from "../../components/WebhookNotifications/WebhookNotifications";
import { isEmpty, orderBy } from "lodash";
import { ILegalRepresentative } from "../../store/interfaces/LegalDetailsState.interfaces";
import LegalRepresentatives from "../../components/LegalRepresentatives/LegalRepresentatives";
import { MODAL_LABEL } from "../../shared/constants/modal_labels";
import { ModalSimple } from "../../components/commons/ModalSimple/ModalSimple";
import UserConfiguration from "../../components/UserConfiguration/UserConfiguration";
import { TAlertType } from "@kushki/connect-ui/dist/Components/Atoms/DataDisplay/Alert/Alert.constants";
import BoardMembers from "../../components/BoardMembers/BoardMembers";
import { EntityNameEnum } from "../../shared/enums/entityName";
import { AccordionLabelsEnum } from "../../shared/enums/AccordionLabelsEnum";
import { walletTextEnum } from "../../shared/infrastructure/constants/WalletResumeConstants";
import WalletSection from "../../components/Wallet/WalletSection";

export const MerchantResume: React.FC = () => {
  const {
    isDataComplete,
    isLoading,
    generalInfo,
    isNodeBranch,
    isNodeCustomer,
    onClick,
    onClickCompanyAccordion,
    onClickDeveloperAccordion,
    onClickDiscountAccordion,
    onClickFinancialAccordion,
    onClickProcessAccordion,
    onClickServiceAccordion,
    onClickUserAccordion,
    onClickWalletAccordion,
    onRedirect,
    onRedirectWallet,
    handlers: {
      handleActionUsersBtn,
      handleCloseModalSimpleSecurity,
      handleCloseModalBranchCreate,
      handleOpenModalBranchCreate,
      handleSecondaryAction,
      onRedirectBranchCreation,
      handleValidateNameBranch,
      handleRedirectModalBranchCreatedSuccessful,
      handleCloseModalBranchCreatedSuccessful,
      handleOnCloseModalBranchCreatedSuccessful,
    },
    isCompanyAccordionOpen,
    isDeveloperAccordionOpen,
    isDisabledCreateBranchBtn,
    isDiscountAccordionOpen,
    isFinancialAccordionOpen,
    isModalBranchCreateOpen,
    isModalSimpleSecurityOpen,
    isModalBranchCreatedSuccessfulOpen,
    isLoaderModalOpen,
    isProcessAccordionOpen,
    isServiceAccordionOpen,
    isUserAccordionOpen,
    isWalletAccordionOpen,
    form,
    entityName,
    queryParamMode,
    shouldRenderChargesBlock,
    shouldRenderDispersionBlock,
    isFooterOptionsOpen,
    legalRepresentatives,
    shareHolders,
    isCentralizedBillingBranch,
    customerName,
    usersList,
    link,
    linkCustomer,
    hasChildrens,
    isDisabledUserBtn,
    alertMessage,
    typeAlert,
    userSectionRef,
    isPendingUsers,
    boardMembers,
    requiredConfigs,
    balanceResponse,
    canShowWallet,
    isWalletLoading,
    isActiveBranch,
  } = useMerchantResume();

  return (
    <>
      <Box
        data-testid="mainContainerResumeTest"
        sx={{
          ...styles.mainContainer,
          marginBottom: isFooterOptionsOpen ? "100px" : "0px",
        }}
      >
        {queryParamMode === "edition" &&
          (isLoading ? (
            <CustomSkeleton
              sx={{
                height: "44px",
                width: "100px",
              }}
              variant="h3"
              data-testid="skeleton-button"
            />
          ) : (
            <ComponentSecurityWrapper>
              <Button
                color="primary"
                variant="text"
                size="medium"
                startIcon={<IconChevronLeft />}
                disableElevation
                onClick={handleSecondaryAction}
                sx={styles.regresarBtn}
              >
                Regresar
              </Button>
            </ComponentSecurityWrapper>
          ))}
        <MerchantInformation
          generalInfo={generalInfo}
          onClick={onClick}
          isLoading={isLoading}
        />
        <Box sx={styles.contentContainer}>
          {queryParamMode !== "edition" && isLoading && (
            <>
              <CustomSkeleton
                sx={{
                  width: {
                    md: "25%",
                    xs: "75%",
                  },
                }}
                variant="h3"
                showContainer
                data-testid="skeleton-title"
              />
              <CustomSkeleton
                sx={{
                  width: {
                    md: "75%",
                    xs: "100%",
                  },
                }}
                variant="h3"
                showContainer
                data-testid="skeleton-subtitle"
              />
            </>
          )}
          {isLoading ? (
            <CustomSkeleton
              sx={{
                width: {
                  md: "75%",
                  xs: "100%",
                },
              }}
              variant="h3"
              showContainer
              data-testid="skeleton-p-resume"
            />
          ) : (
            <>
              {" "}
              <Typography color="text.primary" variant="h3" mb={1}>
                {LabelEnums.RESUME}
              </Typography>
              <Typography color="text.grey" variant="body2">
                {LabelEnums.LEGENDRESUME}
              </Typography>
              <Divider sx={styles.divider} />
            </>
          )}
          <Grid container gap="2rem">
            {!isLoading && !isEmpty(alertMessage) && (
              <Alert title={alertMessage} type={typeAlert as TAlertType} />
            )}
            {queryParamMode === "edition" && entityName === "CUSTOMER" ? (
              <Grid item xs={12}>
                <Box sx={styles.boxContainer} mt={2}>
                  <BaseDataContainer title="Branches" withoutEdit={true}>
                    <BranchesResume />
                  </BaseDataContainer>
                </Box>
              </Grid>
            ) : (
              <></>
            )}
            <ResumeContainer
              title={AccordionLabelsEnum.COMPANY_DATA}
              isLoading={isLoading}
              mode={queryParamMode}
              collapsed={isCompanyAccordionOpen}
              Icon={IconBank}
              iconLeft={<IconBank />}
              onClick={onClickCompanyAccordion}
              section={Section.COMPANY}
            >
              <Grid item md={6} xs={12}>
                <BaseDataContainer
                  title={AccordionLabelsEnum.BASIC_DATA}
                  onClickEdit={() => onRedirect(routes.BASIC_DETAILS)}
                >
                  <BasicData />
                </BaseDataContainer>
              </Grid>
              <Grid item md={6} xs={12}>
                <BaseDataContainer
                  title={AccordionLabelsEnum.BILLING_DATA}
                  onClickEdit={() => onRedirect(routes.BILLING)}
                >
                  <BillingData />
                </BaseDataContainer>
              </Grid>
              <Grid item xs={12}>
                <BaseDataContainer
                  title={AccordionLabelsEnum.LEGAL_DATA}
                  onClickEdit={() => onRedirect(routes.LEGAL_DETAILS)}
                >
                  {orderBy(legalRepresentatives, ["isMain"], ["desc"]).map(
                    (element: ILegalRepresentative) => (
                      <LegalRepresentatives
                        key={`${element.id}`}
                        items={element}
                        generalCountry={
                          generalInfo?.constitutionalCountry ?? ""
                        }
                      />
                    )
                  )}
                  <Shareholders shareholders={shareHolders} />
                  {entityName === EntityNameEnum.CUSTOMER && (
                    <BoardMembers boardMembers={boardMembers} />
                  )}
                </BaseDataContainer>
              </Grid>
              <Grid item xs={12} mt={4}>
                <BaseDataContainer
                  title={AccordionLabelsEnum.CONTACT_DATA}
                  onClickEdit={() => onRedirect(routes.CONTACT_DETAILS)}
                >
                  <ContactsIndex />
                </BaseDataContainer>
              </Grid>
            </ResumeContainer>
            <div ref={userSectionRef} />
            <ResumeContainer
              title={AccordionLabelsEnum.FINANCIAL_SETUP}
              isLoading={isLoading}
              mode={queryParamMode}
              Icon={IconChartSmall}
              iconLeft={<IconChartSmall />}
              collapsed={isFinancialAccordionOpen}
              onClick={onClickFinancialAccordion}
              section={Section.FINANCIAL}
            >
              {isCentralizedBillingBranch && (
                <Grid
                  item
                  xs={12}
                  boxShadow={3}
                  style={{
                    alignItems: "center",
                    borderRadius: "8px",
                    display: "grid",
                    gridTemplateColumns: "50px 1fr",
                    padding: "24px",
                  }}
                >
                  <IconAlertTriangle />
                  <Typography fontSize={"16px"} fontWeight={500}>
                    La facturación, retención, cobro y liquidación se generará
                    con los “Datos de la Empresa” del customer {customerName} ya
                    que el branch se encuentra centralizado.
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <BaseDataContainer
                  title={AccordionLabelsEnum.BILLING}
                  onClickEdit={() =>
                    onRedirect(routes.FINANCIAL_CONFIGURATION_BILLING)
                  }
                >
                  <BillingResume />
                </BaseDataContainer>
              </Grid>
              {shouldRenderChargesBlock ? (
                <Grid item xs={12}>
                  <BaseDataContainer
                    title={AccordionLabelsEnum.CHARGES}
                    onClickEdit={() =>
                      onRedirect(routes.FINANCIAL_CONFIGURATION_CHARGES)
                    }
                  >
                    <ChargesBlock />
                  </BaseDataContainer>
                </Grid>
              ) : null}
              {shouldRenderDispersionBlock ? (
                <Grid item xs={12}>
                  <BaseDataContainer
                    title={AccordionLabelsEnum.DISPERSION}
                    onClickEdit={() =>
                      onRedirect(routes.FINANCIAL_CONFIGURATION_DISPERSION)
                    }
                  >
                    <DispersionBlock />
                  </BaseDataContainer>
                </Grid>
              ) : null}
            </ResumeContainer>
            <ResumeContainer
              title={AccordionLabelsEnum.PROSECUTION}
              isLoading={isLoading}
              mode={queryParamMode}
              Icon={IconChartSmall}
              iconLeft={<IconChartSmall />}
              collapsed={isProcessAccordionOpen}
              onClick={onClickProcessAccordion}
              section={Section.PROCESSING}
            >
              <BusinessRulesMemo />
              <DeferredListMemo />
              <Processors />
            </ResumeContainer>
            <ResumeContainer
              title={AccordionLabelsEnum.SERVICES}
              isLoading={isLoading}
              mode={queryParamMode}
              Icon={IconMixer}
              iconLeft={<IconMixer />}
              collapsed={isServiceAccordionOpen}
              onClick={onClickServiceAccordion}
              section={Section.SERVICES}
            >
              <ServiceConfiguration />
            </ResumeContainer>
            <ResumeContainer
              title={AccordionLabelsEnum.RATES_AND_DISCOUNTS}
              isLoading={isLoading}
              mode={queryParamMode}
              Icon={IconChartSmall}
              iconLeft={<></>}
              collapsed={isDiscountAccordionOpen}
              onClick={onClickDiscountAccordion}
              section={Section.RATES_DISCOUNTS}
            >
              <Grid item xs={12}>
                <BaseDataContainer
                  title={AccordionLabelsEnum.CONFIGURED_INFORMATION}
                  onClickEdit={() => onRedirect(routes.MERCHANT_RATES)}
                >
                  <RatesAndDiscountResume />
                </BaseDataContainer>
              </Grid>
            </ResumeContainer>
            <ResumeContainer
              title={AccordionLabelsEnum.DEVELOPERS}
              isLoading={isLoading}
              mode={queryParamMode}
              Icon={IconBadge}
              iconLeft={<IconBadge />}
              collapsed={isDeveloperAccordionOpen}
              onClick={onClickDeveloperAccordion}
              section={Section.DEVELOPERS}
            >
              <CustomerWebhooks
                mode={queryParamMode}
                section={Section.DEVELOPERS}
              />
              <WebhookNotifications />
              {entityName === "BRANCH" && <Credentials />}
            </ResumeContainer>
            <ResumeContainer
              title={AccordionLabelsEnum.USERS}
              isLoading={isLoading}
              mode={queryParamMode}
              Icon={IconUser}
              iconLeft={<IconUser />}
              isFromUsers={true}
              collapsed={isUserAccordionOpen}
              onClick={onClickUserAccordion}
              section={Section.USERS}
            >
              {isNodeBranch ? (
                <BaseDataContainer
                  title={usersTextEnum.TITLE}
                  withoutEdit={true}
                >
                  <UserListResume
                    isPendingUser={isPendingUsers}
                    users={usersList}
                    link={link}
                    requiredConfigs={requiredConfigs}
                  />
                </BaseDataContainer>
              ) : isNodeCustomer ? (
                <>
                  <BaseDataContainer
                    title={usersTextEnum.CUSTOMER_TITLE}
                    withoutEdit={true}
                    redirectCustomerButton={linkCustomer}
                    entityName={entityName}
                    isUserTab={true}
                  >
                    <UserListResume
                      isPendingUser={isPendingUsers}
                      users={usersList}
                      link={link}
                      isNodeCustomer={isNodeCustomer}
                      requiredConfigs={requiredConfigs}
                    />
                  </BaseDataContainer>
                  <UserConfiguration
                    isDisabled={isDisabledUserBtn}
                    handleActionUsersBtn={handleActionUsersBtn}
                  />
                </>
              ) : (
                <UserConfiguration
                  isDisabled={isDisabledUserBtn}
                  handleActionUsersBtn={handleActionUsersBtn}
                />
              )}
            </ResumeContainer>
            <ResumeContainer
              title={AccordionLabelsEnum.WALLET}
              isLoading={isLoading}
              mode={queryParamMode}
              Icon={IconWallet}
              iconLeft={<IconWallet />}
              isFromUsers={true}
              collapsed={isWalletAccordionOpen}
              onClick={onClickWalletAccordion}
              section={Section.WALLET}
              walletConditions={{
                canShowWallet,
                isActiveBranch,
                isNodeBranch,
                isNodeCustomer,
                isWalletLoading,
              }}
            >
              {isNodeBranch && canShowWallet && (
                <BaseDataContainer
                  title={walletTextEnum.TITLE}
                  withoutEdit={true}
                  isWallet={true}
                  onClickRedirectWallet={onRedirectWallet}
                >
                  <WalletSection
                    balanceData={balanceResponse}
                    country={generalInfo.country}
                  ></WalletSection>
                </BaseDataContainer>
              )}
            </ResumeContainer>
          </Grid>
        </Box>
      </Box>
      <ModalSimpleSecurity
        buttonPrimary={{
          action: handleCloseModalSimpleSecurity,
          securityRol: SecurityWrapperEnum.BRANCH_ACTIVADO,
          text: "Listo",
        }}
        buttonSecondary={{
          action: onRedirectBranchCreation,
          isDisabled: isDisabledCreateBranchBtn,
          securityRol: SecurityWrapperEnum.CREAR_NUEVO_BRANCH,
          text: "Crear nuevo Branch",
        }}
        titleText="El cliente ya está activo"
        descriptionText="La configuración del customer y de su primer branch ha sido guardada con éxito."
        isOpen={isModalSimpleSecurityOpen}
        onClose={handleCloseModalSimpleSecurity}
        typeModal={1}
      />
      <ModalSimple
        buttonPrimary={{
          action: handleRedirectModalBranchCreatedSuccessful,
          text: MODAL_LABEL.PRIMARY_BUTTON,
        }}
        descriptionText={MODAL_LABEL.DESCRIPTION}
        isOpen={isModalBranchCreatedSuccessfulOpen}
        onClose={handleOnCloseModalBranchCreatedSuccessful}
        titleText={MODAL_LABEL.TITLE}
        typeModal={1}
        hideCloseBtn={true}
        buttonSecondary={{
          action: handleCloseModalBranchCreatedSuccessful,
          text: MODAL_LABEL.SECONDARY_BUTTON,
        }}
      />
      <ModalBranchCreate
        buttonPrimary={{
          action: handleValidateNameBranch,
          securityRol: SecurityWrapperEnum.CREAR_PRIMER_BRANCH,
          text: "Crear Branch",
        }}
        buttonSecondary={{
          action: handleCloseModalBranchCreate,
          securityRol: SecurityWrapperEnum.CANCELAR_BRANCH,
          text: "Cancelar",
        }}
        titleText="El customer está casi listo"
        descriptionText="Para que el cliente pueda operar deberás crear su primer branch."
        descriptionText2="*El primer branch replicará automáticamente la configuración del customer."
        isOpen={isModalBranchCreateOpen}
        onClose={handleCloseModalBranchCreate}
        form={form}
      />
      <ModalLoader
        descriptionText="Este proceso puede tardar un poco..."
        titleText="Creando Branch"
        isOpen={isLoaderModalOpen}
      />
      <FooterOptions
        secondaryButton={{
          isDisabled: isLoading,
          onAction: handleSecondaryAction,
          text: "Configurar después",
        }}
        primaryButton={{
          isDisabled: isLoading || !isDataComplete || hasChildrens,
          isHidden: isNodeBranch,
          onAction: handleOpenModalBranchCreate,
          text: "Confirmar y continuar",
        }}
        open={isFooterOptionsOpen}
      />
    </>
  );
};
