import { useEffect, useState } from "react";
import { get, isEmpty } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";

import { useSnackbar } from "@kushki/connect-ui";
import { setNotification } from "../../../store/actions/ownerData.actions";

import { RootState } from "../../../store/store";
import { redirectPageUtils } from "../../../shared/utils/redirectPage.utils";
import { REDIRECT_PATH_BRANCH } from "../../../shared/constants/content_slides";
import { EntityNameEnum } from "../../../shared/enums/EntityNameEnum";
import {
  ModalStatusTextBranch,
  ModalStatusTextBranches,
  ShowUserParamValue,
} from "../../../shared/enums/DashboardEnum";
import { IModalStatusText } from "../../../components/CustomerHeader/CustomerHeader.interfaces";
import { StatusEnum } from "../../../shared/enums/StatusEnum";
import {
  changeStatusNodes,
  searchMerchantBillingCore,
  searchMerchantByNode,
} from "../../../store/thunks/customer/searchMerchants.thunk";
import { MerchantNodeData } from "../../../../types/search_merchant_response";
import {
  verifyIsPending,
  verifyStatus,
} from "../../../shared/utils/verifyStatus.utils";
import { BranchEnum } from "../../../shared/enums/BranchEnum";
import { setOwnerDetail } from "../../../store/actions/owner.actions";
import { CreateNodeResponse } from "../../../../types/create_node_response";
import { IUseBranchContainer } from "./IUseBranchContainer.interfaces";
import { resetCustomerList } from "../../../store/actions/customer.action";
import { INodeInfoResponse } from "../../../../types/node_info_response";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { useQuery } from "../../../shared/utils/location-utils";
import { QueryParamEnum } from "../../../shared/enums/QueryParamEnum";

export const useBranchContainer = (): IUseBranchContainer => {
  const query = useQuery();
  const dispatch = useAppDispatch();
  const { showSnackbar } = useSnackbar();
  const nodeId: string = location.pathname.split("/")[2];
  const nodeIdCustomer: string = location.pathname.split("/")[4];

  const [merchantCustomer, setMerchantCustomer] = useState<MerchantNodeData>(
    {}
  );
  const [merchantOwner, setMerchantOwner] = useState<MerchantNodeData>({});
  const [merchantBillingCore, setmerchantBillingCore] =
    useState<INodeInfoResponse>({} as INodeInfoResponse);
  const [pathCustomer] = useState<string>(`${nodeId}:${nodeIdCustomer}`);
  const [configUserModal, setConfigUserModal] = useState<boolean>(false);
  const showUserQuery: string | null = query.get(QueryParamEnum.SHOW_USER);

  const handleSearchMerchantByNodeId = (nodeId: string) =>
    dispatch(
      searchMerchantByNode({
        nodeId,
      })
    );
  const handleSearchMerchantBillingCore = (nodeId: string) =>
    dispatch(
      searchMerchantBillingCore({
        nodeId,
      })
    );

  const handleReturn = () => {
    window.history.go(-1);
  };

  const [entityName] = useState<EntityNameEnum>(EntityNameEnum.BRANCH);

  const {
    isLoading,
    isLoadingMerchants,
    notification,
    customerList,
    listOwner,
  } = useAppSelector((state: RootState) => ({
    ...state.ownerReducer,
    ...state.customerReducer,
  }));
  const [emptyAddText, setEmptyAddText] = useState<string>(
    BranchEnum.ADD_BRANCHES
  );
  const [isOwnerCreated, setIsOwnerCreated] = useState(false);
  const [modalStatusText, setModalStatusText] = useState<IModalStatusText>({
    description: "",
    title: "",
  });
  const createBranch = () => {
    redirectPageUtils(
      `${REDIRECT_PATH_BRANCH}${get(merchantCustomer, "merchantId")}`
    );
  };

  const checkModalStatusText = (name?: string, total?: number) => {
    if (name) {
      setModalStatusText({
        description: ModalStatusTextBranch.DESCRIPTION(name),
        title: ModalStatusTextBranch.TITLE,
      });
    } else {
      setModalStatusText({
        description: ModalStatusTextBranches.DESCRIPTION,
        title: ModalStatusTextBranches.TITLE(total!),
      });
    }
  };

  const handleChangeStatus = (
    itemsSelected: MerchantNodeData[],
    itemsStatus: StatusEnum
  ) => {
    const verifyValidStatus =
      verifyStatus(itemsSelected, itemsStatus) &&
      !verifyIsPending(itemsSelected);

    if (verifyValidStatus) {
      dispatch(
        changeStatusNodes({
          entityName: EntityNameEnum.BRANCH,
          itemsSelected: itemsSelected,
          status: itemsStatus,
        })
      );
    }
  };

  const closeConfigUserModal = () => {
    setConfigUserModal(false);
  };
  const redirectCreateMassiveUsers = () => {
    window.location.href = API_ROUTES.CREATE_MASSIVE_USERS(
      get(merchantCustomer, "merchantId", "")
    );
  };

  useEffect(() => {
    if (showUserQuery === ShowUserParamValue.TRUE) setConfigUserModal(true);
  }, []);

  useEffect(() => {
    handleSearchMerchantByNodeId(nodeId).then((data) =>
      setMerchantOwner(get(data, "payload", {}) as MerchantNodeData)
    );
    handleSearchMerchantByNodeId(nodeIdCustomer).then((data) => {
      setMerchantCustomer(get(data, "payload", {}) as MerchantNodeData);
    });
  }, []);

  useEffect(() => {
    handleSearchMerchantBillingCore(get(merchantCustomer, "merchantId")).then(
      (data) => {
        setmerchantBillingCore(get(data, "payload", {}) as INodeInfoResponse);
      }
    );
  }, [merchantCustomer]);

  useEffect(() => {
    return () => {
      setMerchantCustomer({});
      setMerchantOwner({});
      dispatch(resetCustomerList());
    };
  }, []);
  useEffect(() => {
    dispatch(setOwnerDetail(merchantOwner as CreateNodeResponse));
  }, [merchantOwner]);

  useEffect(() => {
    if (!isEmpty(get(listOwner, "data", []))) {
      setEmptyAddText(BranchEnum.ADD_BRANCHES);
      setIsOwnerCreated(true);
    }
  }, [listOwner]);

  useEffect(() => {
    if (notification) {
      showSnackbar(notification);
      dispatch(setNotification(undefined));
    }
  }, [notification]);

  return {
    createBranch,
    customerList,
    emptyAddText,
    entityName,
    handleChangeStatus,
    handleReturn,
    isLoading,
    isLoadingMerchants,
    isOwnerCreated,
    merchantBillingCore,
    merchantByNodeId: merchantOwner,
    merchantCustomer,
    modalConfigUser: {
      closeConfigUserModal: closeConfigUserModal,
      isOpen: configUserModal,
      redirectCreateMassiveUsers,
    },
    modalStatusDashboard: {
      checkModalStatusText,
      modalStatusText,
    },
    pathCustomer,
    showEmptyScreen: isEmpty(get(customerList, "data", [])),
  };
};
