import React from "react";
import { DateRange } from "@material-ui/pickers/DateRangePicker/RangeTypes";
import { Grid } from "@material-ui/core";
import { DateRangePicker } from "../../DateRange/DateRangePicker";
import { usePendingFiltersStyles } from "./PendingFilters.style";
import { ButtonDownloadFile } from "../../ButtonDownloadFile/ButtonDownloadFile";
import { IOptionFilter } from "../../../shared/infrastructure/interfaces/IOptionFilter";
import { CloudSecurityEnum } from "../../../shared/infrastructure/CloudSecurityEnum";

export interface PendingFiltersProps {
  handleDateRangeChange: (dateRange: DateRange<Date>) => void;
  dateRange: DateRange<Date>;
  minDate?: Date;
  downloadFile?: () => void;
  loading?: boolean;
  handleSetFilters?: (filters: { [key: string]: boolean }) => void;
  counterFilter?: number;
  filters?: IOptionFilter[];
}

export const PendingFilters: React.FC<PendingFiltersProps> = (
  props: PendingFiltersProps
) => {
  const classes = usePendingFiltersStyles();

  return (
    <>
      <Grid
        container
        direction={"row"}
        alignItems={"center"}
        className={classes.root}
      >
        <Grid xs={6} container item justify={"flex-start"}>
          <DateRangePicker
            handleDate={props.handleDateRangeChange}
            disableFuture={true}
            enableTimePicker={false}
            value={props.dateRange}
            minDate={props.minDate}
          />
        </Grid>
        <Grid xs={6} container item justify={"flex-end"}>
          <ButtonDownloadFile
            componentId={CloudSecurityEnum.PENDING_DOWNLOAD_CSV}
            downloadFile={props.downloadFile!}
            loading={props.loading!}
          />
        </Grid>
      </Grid>
    </>
  );
};
