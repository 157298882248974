import React from "react";
import { useRuleApprovalDashboardState } from "./state/useRuleApprovalDashboardState";
import { ModalApproveReject } from "../../components/ModalApproveReject/ModalApproveReject";
import { DashboardTable } from "../../components/Table/DashboardTable/DashboardTable";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import { columnsTableApproval } from "../../shared/infrastructure/Table/ColumnsTable";
import { IUtilDashboard } from "../Utils/useUtilDashboard";
import { TRuleDashBoardIndexProps } from "../RuleDashboardIndex/RuleDashboardIndex";

export interface IUtilDashboardHookProps {
  stateUtilTab: IUtilDashboard;
}

export type TRuleApprovalDashboardProps = IUtilDashboardHookProps &
  TRuleDashBoardIndexProps;

export const RuleApprovalDashboard: React.FC<TRuleApprovalDashboardProps> = (
  props: TRuleApprovalDashboardProps
) => {
  const {
    dataModal,
    pagination,
    filterProps,
    checkboxActions,
    data,
    modalOver,
  } = useRuleApprovalDashboardState(props);
  return (
    <React.Fragment>
      <DashboardTable
        country={CountryEnum.colombia}
        historicRulesData={data.authenticationRulesApprovalData}
        isLoading={data.isLoading}
        page={pagination.page}
        pageSize={pagination.pageSize}
        paginationV2={true}
        handleChangePage={pagination.handleChangePage}
        handleChangePageSize={pagination.handleChangePageSize}
        hideNext={false}
        hidePrevious={false}
        filterProps={filterProps}
        orderDataDashboard={data.orderDataDashboard}
        tableColumns={{
          renderColumns: [
            ...columnsTableApproval,
            {
              id: "approval",
              label: "Aceptar",
              view: true,
              filter: false,
              checkboxHeader: {
                handleChange: checkboxActions.handleChangeCheckboxHeader,
                checked: checkboxActions.checkedApprove,
                name: "approval",
              },
            },
            {
              id: "rejected",
              label: "Rechazar",
              view: true,
              filter: false,
              checkboxHeader: {
                handleChange: checkboxActions.handleChangeCheckboxHeader,
                checked: checkboxActions.checkedReject,
                name: "rejected",
              },
            },
          ],
        }}
        handleCheckboxChange={checkboxActions.handleCheckboxChange}
        isAnalyst={false}
        modalOver={modalOver}
      />
      <ModalApproveReject
        actionModal={dataModal.actionModal}
        open={dataModal.openModal}
        title={dataModal.title}
        description={dataModal.description}
        handleDescription={dataModal.setDescription}
        handleClose={() => {
          dataModal.handleClose(dataModal.actionModal);
        }}
        handleAccept={() => {
          dataModal.handleAccept(dataModal.actionModal);
        }}
      />
    </React.Fragment>
  );
};
