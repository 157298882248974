import { Box, Chip } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React from "react";
import useTextFieldChip from "./hooks/useTextFieldChip";
import { ITextFieldProps } from "./hooks/useTextFieldChip.interfaces";
import { ItemChip, ITextFieldChipProps } from "./TextFieldChip.interfaces";
import { IconCircleCross } from "@kushki/connect-ui";
import { createStyles, makeStyles } from "@mui/styles";
import { defaultTo } from "lodash";
import { HelperText } from "../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { isOperatorEqualOrDifferent } from "../../utils/utilsFile";

const useStyles = makeStyles(() =>
  createStyles({
    textFieldAutocomplete: {
      "& .MuiInputBase-root": {
        minHeight: "44px",
        height: "auto",
        maxHeight: "170px",
      },
      "& MuiAutocomplete-root": {
        display: "none",
      },
      "& .MuiOutlinedInput-input": {
        textTransform: "lowercase",
        maxHeight: "12px !important",
      },
      width: "400px",
    },
    filterChip: {
      maxWidth: "255px",
      width: "fit-content",
      "&.MuiChip-filledDefault": {
        marginBottom: "6px",
        marginRight: "6px",
        backgroundColor: "#EEF6FF!important",
        border: "solid 1px#1E65AE!important",
        color: "#1E65AE !important",
        padding: "5px 10px !important",
        borderRadius: "16px!important",
        fontSize: "12px!important",
      },
    },
  })
);

export const TextFieldChip: React.FC<ITextFieldChipProps> = (
  props: ITextFieldChipProps
) => {
  const {
    placeholder,
    helperText = isOperatorEqualOrDifferent(props.operator!)
      ? HelperText.EMPTY
      : HelperText.MORE_THAN_ONE_BIN,
    isDisabled,
    limit,
    error,
  } = props;
  const {
    handleKeyPress,
    handleDelete,
    handleOnBlur,
    handleSetInputValue,
    handleOnChipDoubleClick,
    inputValue,
    isOneValue,
    itemState,
  } = useTextFieldChip(props);

  const classes = useStyles();

  const disableInput: boolean = !!limit && props.items.length >= limit;

  let textFieldProps: ITextFieldProps = {};

  if (isOneValue) {
    textFieldProps = {
      value: inputValue,
    };
  }

  return (
    <Box>
      {isOneValue ? (
        <TextField
          {...textFieldProps}
          label={placeholder}
          error={error}
          fullWidth
          helperText={helperText}
          onChange={(event) => handleSetInputValue(event.target.value)}
          onBlur={handleOnBlur}
          disabled={isDisabled}
          className={classes.textFieldAutocomplete}
        />
      ) : (
        <Autocomplete
          disabled={isDisabled}
          multiple
          disableClearable
          clearOnBlur={false}
          id={`tags-outlined-${Math.random() * 100}`}
          options={itemState}
          inputValue={defaultTo(inputValue, "").trim()}
          open={false}
          popupIcon={""}
          value={itemState}
          style={{ width: "400px" }}
          onInputChange={(_event, newInputValue) => {
            !disableInput && handleSetInputValue(newInputValue);
          }}
          renderTags={(value: ItemChip[]) => {
            const chips = value.map((option: ItemChip, index: number) => (
              <Chip
                disabled={isDisabled}
                key={index + "autocomplete-chip"}
                label={option.value}
                size="small"
                onDelete={() => {
                  handleDelete(index);
                }}
                className={classes.filterChip}
                onDoubleClick={() => handleOnChipDoubleClick(index, option)}
                deleteIcon={<IconCircleCross fontSize="small" />}
              />
            ));

            return (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginTop: "24px",
                  maxHeight: "120px",
                  overflow: "overlay",
                  width: "100%",
                }}
              >
                {chips}
              </div>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              multiline
              error={error}
              label={placeholder}
              helperText={helperText}
              onKeyPress={handleKeyPress}
              onBlur={handleOnBlur}
              className={classes.textFieldAutocomplete}
              disabled={isDisabled}
            />
          )}
        />
      )}
    </Box>
  );
};
