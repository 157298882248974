import React from "react";
import {
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  ElementIndex,
  ElementIndexProps,
} from "../../components/Index/ElementIndex";
import developersImage from "../../assets/images/developersImage.svg";
import { routes } from "../../shared/infrastructure/constants/routes";
import { IAppState } from "../../store/reducer";

export const DevelopersIndex: React.FC<IAppState> = ({}: IAppState) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  const roles = localStorage.getItem("roles");
  const rol = roles!.replace(/"/g, "");
  const publicMerchantId = localStorage.getItem("merchantId");

  const element: ElementIndexProps = rol.includes(
    "BackofficeMasterCredentials:true,BackofficeMasterCredentials:true"
  )
    ? {
        title: "Desarrolladores",
        image: developersImage,
        elements: [
          {
            subtitle: "Credenciales",
            description:
              "Verifica los usuarios y los permisos que tiene cada uno.",
            redirect: `${routes.DEVELOPERS_CREDENTIALS}/${publicMerchantId}`,
          },
        ],
        isMedium: isMedium,
      }
    : {
        title: "Desarrolladores",
        image: developersImage,
        elements: [
          {
            subtitle: "Credenciales",
            description:
              "Establece las llaves privadas y público para el comercio.",
            redirect: `${routes.DEVELOPERS_CREDENTIALS}/${publicMerchantId}`,
          },
          {
            subtitle: "Webhooks",
            description:
              "Configura las notificaciones por webhooks que el comercio tendrá disponibles.",
            redirect: `${routes.WEBHOOKS}/${publicMerchantId}`,
          },
          {
            subtitle: "Notificaciones de webhooks",
            description:
              "Haz seguimiento de tus URLs de notificaciones de tus webhooks y los datos de envío y respuesta.",
            redirect: `${routes.NOTIFICATIONS}/${publicMerchantId}`,
          },
        ],
        isMedium: isMedium,
      };

  return (
    <React.Fragment>
      <Container fixed>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3" color={"primary"}>
              Desarrolladores
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ElementIndex
              title={element.title}
              image={element.image}
              elements={element.elements}
              isMedium={isMedium}
            />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default DevelopersIndex;
