import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import {
  changeStatusModule,
  createUpdateModule,
  getAllModules,
  getAllRoles,
} from "../../../store/thunks/modules/modules.thunks";
import { useNavigate } from "react-router-dom";
import { GetAllModulesResponse } from "../../../../types/get_all_modules_response";
import { StatusEnum } from "../../../shared/enums/statusEnum";
import {
  clearAlert,
  clearModule,
  setModule,
} from "../../../store/actions/modules/modules.actions";
import { CreateEditRequest } from "../../../../types/create_edit_request";
import { FieldsEnum } from "../../../shared/enums/fieldsEnun";
import { chunk, defaultTo, get, remove } from "lodash";
import { useForm, UseFormReturn } from "react-hook-form";
import {
  IPaginationState,
  IUseModulesContainer,
} from "./useModulesContainer.interfaces";
import { TypeEnum } from "../../../shared/enums/typeEnum";

export const useModulesContainer = (): IUseModulesContainer => {
  const dispatch = useAppDispatch();
  const { isLoadingGetModules, modules, dataForm } = useAppSelector(
    (state) => state.modules
  );
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<IPaginationState>({
    page: 1,
    size: 10,
  });
  const handleClickRow = (row: GetAllModulesResponse) => {
    navigate(`/modules/components/${row.id}`);
    dispatch(clearAlert());
  };
  const handleChangeSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    row: GetAllModulesResponse
  ) => {
    dispatch(
      changeStatusModule({
        body: {
          roles: row.roles,
          status: event.target.checked
            ? StatusEnum.ENABLED
            : StatusEnum.DISABLED,
        },
        id: row.id,
      })
    );
  };
  const handleChangePage = (value: number) => {
    setPagination({
      ...pagination,
      page: value,
    });
  };
  const handleChangePageSize = (newPageSize: number) => {
    setPagination({
      page: 1,
      size: newPageSize,
    });
  };
  const getInitialValue = (
    initData: Partial<CreateEditRequest>
  ): Partial<CreateEditRequest> => {
    return {
      description: get(initData, FieldsEnum.description, ""),
      id: get(initData, FieldsEnum.id, ""),
      roles: get(initData, FieldsEnum.roles, []),
    };
  };

  const form: UseFormReturn<CreateEditRequest> = useForm<CreateEditRequest>({
    defaultValues: {
      ...getInitialValue(dataForm),
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const validateForm = () => {
    if (defaultTo(form.getValues("id"), "").length === 0) {
      form.register("id", { required: true });
      form.setError("id", { type: "required" });
    }
    if (defaultTo(form.getValues("description"), "").length === 0) {
      form.register("description", { required: true });
      form.setError("description", { type: "required" });
    }
    if (defaultTo(form.getValues("roles"), []).length === 0) {
      form.register("roles", { required: true });
      form.setError("roles", { type: "required" });
    }

    return form.trigger();
  };
  const handlerDeleteRoles = (e: React.MouseEvent, chipToDelete: string) => {
    form.setValue(
      "roles",
      remove(form.getValues("roles") || [], (value) => value !== chipToDelete)
    );
    e.stopPropagation();
  };
  const [openModalDialog, setOpenModalDialog] = useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean>(true);
  const handlerOpenModalDialog = (edit?: boolean) => {
    if (!edit) {
      form.setValue("id", "");
      form.setValue("description", "");
      form.setValue("roles", []);
    }
    setOpenModalDialog(true);
  };
  const handlerCloseModalDialog = () => {
    setOpenModalDialog(false);
    setIsNew(true);
    form.clearErrors();
  };
  const handlerCreateOrEdit = async (data: Partial<GetAllModulesResponse>) => {
    const validForm: boolean = await validateForm();

    if (validForm) {
      setOpenModalDialog(false);
      const id = form.getValues("id") as string;
      const description = form.getValues("description") as string;
      const formRoles = form.getValues("roles");
      const sendRoles = Array.isArray(formRoles) ? formRoles : [formRoles];
      const roles = sendRoles as string[];

      dispatch(
        createUpdateModule({
          body: {
            description,
            id,
            parent: data.parent || id,
            roles,
            status: isNew ? StatusEnum.ENABLED : (data.status as StatusEnum),
            type: data.type!,
          },
          isNew: isNew,
        })
      );
    }
  };

  const handleClickEditButton = (row: GetAllModulesResponse) => {
    form.setValue("id", row.id);
    form.setValue("description", row.description);
    form.setValue("roles", row.roles);
    dispatch(setModule(row));
    setIsNew(false);
    handlerOpenModalDialog(true);
  };
  const handleClickAddButton = () => {
    dispatch(clearModule());
    setIsNew(true);
    handlerOpenModalDialog();
  };

  useEffect(() => {
    dispatch(getAllModules());
    dispatch(getAllRoles({ type: TypeEnum.MODULE }));
  }, []);
  const getModulesToDisplay = () =>
    chunk(modules, pagination.size)[pagination.page - 1];

  return {
    form,
    handleChangeSwitch,
    handleClickAddButton,
    handleClickEditButton,
    handleClickRow,
    handlerOpenModalDialog,
    isLoadingGetModules,
    modalDialogProps: {
      handlerCloseModalDialog,
      handlerCreateOrEdit,
      handlerDeleteRoles,
      isNew,
      openModalDialog,
    },
    modules: getModulesToDisplay(),
    pagination: {
      handleChangePage,
      handleChangePageSize,
      limit: pagination.size,
      page: pagination.page,
      total: modules.length,
    },
  };
};
