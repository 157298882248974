import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const commentsTabStyles = createNamedStyles({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  commentsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    marginBottom: "1rem",
    flex: 2,
    overflowY: "auto",
    scrollBehavior: "smooth",
  },
  textAreaWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  textArea: {
    flexGrow: 1,
    width: ["-webkit-fill-available", "-moz-available"],
    display: "inline-grid",
    margin: "1rem 0px",
    "& .MuiFormControl-root .MuiOutlinedInput-root": {
      height: "100%",
    },
  },
  addCommentBtn: {
    display: "flex",
    justifyContent: "flex-end",
    flex: 1,
    paddingTop: "1rem",
  },
});
