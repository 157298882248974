export enum FormatEnum {
  CSV = "csv",
  JSON = "json",
  TXT = "txt",
}

export const FORMAT_SEPARATED: Record<string, string> = {
  [FormatEnum.CSV]: ",",
  [FormatEnum.TXT]: "|",
};
