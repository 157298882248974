import { Merchant } from "../../../types/search_merchants_response";
import { EntityName } from "@shared/enum/entityNameEnum";
import { get, isEqual } from "lodash";
import { IFilterAlarm } from "../../../types/search_alarm_by_filter_request";

export function getIdByEntity(merchant: Merchant) {
  return [EntityName.BRANCH, EntityName.NA].includes(
    merchant.entityName as EntityName
  )
    ? merchant.publicMerchantId
    : merchant.nodeId;
}

export function filterPropByEntity(merchant: Merchant): IFilterAlarm {
  switch (get(merchant, "entityName")!) {
    case EntityName.OWNER:
      return { ownerId: [merchant.nodeId] };
    case EntityName.CUSTOMER:
      return { customerId: [merchant.nodeId] };
    case EntityName.BRANCH:
    case EntityName.NA:
      return { merchantId: [merchant.publicMerchantId] };
    default:
      return { merchantId: [] };
  }
}

export function builderFilterNode(
  merchants: Merchant[],
  id: string
): IFilterAlarm {
  const findMerchant: Merchant = merchants.find((merchant: Merchant) =>
    isEqual(id, getIdByEntity(merchant))
  )!;

  return {
    customerId: [],
    merchantId: [],
    ownerId: [],
    ...filterPropByEntity(findMerchant),
  };
}
