import React from "react";

export const DisabledBankIcon: React.FC = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33 33H3C2.60218 33 2.22064 32.842 1.93934 32.5607C1.65804 32.2794 1.5 31.8978 1.5 31.5C1.5 31.1022 1.65804 30.7206 1.93934 30.4393C2.22064 30.158 2.60218 30 3 30H33C33.3978 30 33.7794 30.158 34.0607 30.4393C34.342 30.7206 34.5 31.1022 34.5 31.5C34.5 31.8978 34.342 32.2794 34.0607 32.5607C33.7794 32.842 33.3978 33 33 33Z"
        fill="#E2E8F0"
      />
      <path
        d="M30 33C29.6022 33 29.2206 32.842 28.9393 32.5607C28.658 32.2794 28.5 31.8978 28.5 31.5V28.5H7.5V31.5C7.5 31.8978 7.34196 32.2794 7.06066 32.5607C6.77936 32.842 6.39782 33 6 33C5.60218 33 5.22064 32.842 4.93934 32.5607C4.65804 32.2794 4.5 31.8978 4.5 31.5V27C4.5 26.6022 4.65804 26.2206 4.93934 25.9393C5.22064 25.658 5.60218 25.5 6 25.5H30C30.3978 25.5 30.7794 25.658 31.0607 25.9393C31.342 26.2206 31.5 26.6022 31.5 27V31.5C31.5 31.8978 31.342 32.2794 31.0607 32.5607C30.7794 32.842 30.3978 33 30 33Z"
        fill="#E2E8F0"
      />
      <path
        d="M10.5 24C10.1022 24 9.72064 23.842 9.43934 23.5607C9.15804 23.2794 9 22.8978 9 22.5V13.5C9 13.1022 9.15804 12.7206 9.43934 12.4393C9.72064 12.158 10.1022 12 10.5 12C10.8978 12 11.2794 12.158 11.5607 12.4393C11.842 12.7206 12 13.1022 12 13.5V22.5C12 22.8978 11.842 23.2794 11.5607 23.5607C11.2794 23.842 10.8978 24 10.5 24Z"
        fill="#E2E8F0"
      />
      <path
        d="M18 24C17.6022 24 17.2206 23.842 16.9393 23.5607C16.658 23.2794 16.5 22.8978 16.5 22.5V10.5C16.5 10.1022 16.658 9.72064 16.9393 9.43934C17.2206 9.15804 17.6022 9 18 9C18.3978 9 18.7794 9.15804 19.0607 9.43934C19.342 9.72064 19.5 10.1022 19.5 10.5V22.5C19.5 22.8978 19.342 23.2794 19.0607 23.5607C18.7794 23.842 18.3978 24 18 24Z"
        fill="#E2E8F0"
      />
      <path
        d="M25.5 24C25.1022 24 24.7206 23.842 24.4393 23.5607C24.158 23.2794 24 22.8978 24 22.5V13.5C24 13.1022 24.158 12.7206 24.4393 12.4393C24.7206 12.158 25.1022 12 25.5 12C25.8978 12 26.2794 12.158 26.5607 12.4393C26.842 12.7206 27 13.1022 27 13.5V22.5C27 22.8978 26.842 23.2794 26.5607 23.5607C26.2794 23.842 25.8978 24 25.5 24Z"
        fill="#E2E8F0"
      />
      <path
        d="M3.75042 13.5003C3.47342 13.4994 3.20206 13.4219 2.96642 13.2763C2.73078 13.1307 2.54007 12.9226 2.41542 12.6753C2.2375 12.321 2.20705 11.9107 2.33072 11.5341C2.45439 11.1575 2.72213 10.8451 3.07542 10.6653L18.0754 3.16526C18.2924 3.0497 18.5345 2.98926 18.7804 2.98926C19.0263 2.98926 19.2684 3.0497 19.4854 3.16526L32.9854 10.6653C33.1568 10.7638 33.3071 10.895 33.4277 11.0516C33.5484 11.2082 33.637 11.387 33.6885 11.5778C33.7401 11.7686 33.7535 11.9677 33.7281 12.1638C33.7027 12.3598 33.6389 12.5489 33.5404 12.7203C33.4419 12.8916 33.3106 13.0419 33.1541 13.1626C32.9975 13.2832 32.8187 13.3718 32.6279 13.4234C32.437 13.4749 32.2379 13.4883 32.0419 13.4629C31.8459 13.4375 31.6568 13.3738 31.4854 13.2753L18.7204 6.19526L4.50042 13.3353C4.27078 13.4593 4.01093 13.5165 3.75042 13.5003Z"
        fill="#E2E8F0"
      />
    </svg>
  );
};
