import React from "react";
import { TextField } from "@material-ui/core";

import {
  AuthenticationRulesLabelField,
  findName,
} from "../../../shared/infrastructure/constants/AuthenticationRulesConstants";
import { Autocomplete } from "@material-ui/lab";
import { IOptionSelection } from "../../../shared/infrastructure/constants/RuleRequestConstants";
import { defaultTo, isEmpty } from "lodash";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ControllerRenderProps } from "react-hook-form";

export interface AutocompleteFieldComponentProps {
  index: number;
  disabled: boolean;
  items: string[];
  options: IOptionSelection[];
  compare: "name" | "value";
  path: "name" | "value";
  nameValue: string | (string | number)[];
  value: string | string[];
  defaultValue: any;
  properties: ControllerRenderProps;
  helperText: any;
  error: any;
  isMultiple: boolean;
}
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      ["& .MuiInputBase-root"]: {
        height: "auto !important",
      },
    },
  })
);

export const AutocompleteFieldComponent: React.FC<AutocompleteFieldComponentProps> =
  (props: AutocompleteFieldComponentProps) => {
    const { helperText, error, isMultiple } = props;
    const classes = useStyles();

    const valueWithoutSpace: string[] =
      isMultiple && !isEmpty(props.value)
        ? defaultTo(
            typeof props.value === "string"
              ? [props.value]
              : (props.value as string[]),
            []
          ).map((item) => item.trim())
        : [];
    return (
      <Autocomplete
        classes={{ root: isMultiple ? classes.root : undefined }}
        {...props.properties}
        fullWidth
        multiple={isMultiple}
        id={`strictConditionWrapper_${props.nameValue}`}
        defaultValue={props.defaultValue}
        onChange={(_e, data) => props.properties.onChange(data)}
        getOptionLabel={(option) =>
          findName(props.options, option, props.compare, props.path)
        }
        value={isMultiple ? valueWithoutSpace : props.value}
        options={props.items}
        renderInput={(params: any) => (
          <TextField
            fullWidth
            {...params}
            error={error}
            label={AuthenticationRulesLabelField.VALUE}
            variant="outlined"
            helperText={helperText}
          />
        )}
      />
    );
  };

export default AutocompleteFieldComponent;
