import { IUseMassiveStatusInstructionsModal } from "../../../shared/interfaces/IUseModalMassiveStatus";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { selectApp } from "../../../store/selectors/selectors";
import { setIsOpenMassiveStatusInstructionsModal } from "../../../store/actions/app.actions";

export const useMassiveStatusInstructionsModal =
  (): IUseMassiveStatusInstructionsModal => {
    const dispatch = useAppDispatch();
    const { isOpenMassiveStatusInstructionsModal } = useAppSelector(selectApp);
    const onCloseModal = (): void => {
      dispatch(setIsOpenMassiveStatusInstructionsModal(false));
    };

    return {
      isOpen: isOpenMassiveStatusInstructionsModal,
      onCloseModal,
    };
  };
