import React, { FC } from "react";
import { Table, TableBody, TableContainer } from "@mui/material";
import TransactionsTableHead from "../common/TransactionsTableHead/TransactionsTableHead";
import TransactionsSkeleton from "../TransactionsSkeleton/TransactionsSkeleton";
import TransactionTableItem from "../common/TransactionTableItem/TransactionTableItem";
import { useTransactionsTableState } from "./state/useTransactionsTableState";
import { transactionsTableStyles } from "./TransactionsTable.styles";
import TransactionsTableFooter from "./TransactionsTableFooter/TransactionsTableFooter";
import TransactionsEmptyState from "../TransactionsEmptyState/TransactionsEmptyState";

const TransactionsTable: FC = () => {
  const {
    areAllCurrentTransactionsSelected,
    areTransactionsLoading,
    handleOnChangeSelection,
    handleOnClickSortDate,
    selectedTransactionsIDs,
    transactions,
  } = useTransactionsTableState();

  return (
    <TableContainer sx={transactionsTableStyles.container}>
      {!areTransactionsLoading && transactions.length === 0 ? (
        <TransactionsEmptyState />
      ) : (
        <>
          <Table>
            <TransactionsTableHead
              areAllItemsSelected={
                transactions.length > 0 && areAllCurrentTransactionsSelected
              }
              disabled={areTransactionsLoading}
              onChangeSelection={handleOnChangeSelection}
              onClickSortDate={handleOnClickSortDate}
            />
            <TableBody>
              {areTransactionsLoading ? (
                <TransactionsSkeleton />
              ) : (
                transactions.map((transaction) => (
                  <TransactionTableItem
                    key={transaction.id}
                    transaction={transaction}
                    isSelected={selectedTransactionsIDs.includes(
                      transaction.id
                    )}
                  />
                ))
              )}
            </TableBody>
          </Table>
          <TransactionsTableFooter />
        </>
      )}
    </TableContainer>
  );
};

export default TransactionsTable;
