import { createNamedStyles } from "../../shared/utils";

export const selectedTransactionsTableStyles = createNamedStyles({
  container: {
    backgroundColor: "neutral.white",
    mb: 4,
  },
  containerCountTransactions: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: 3,
  },
});
