import { useContext } from "react";
import { IDropdownSplitButtonProps } from "../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { Routes } from "../../../shared/infrastructure/routes";
import { SecurityContext } from "@kushki/security-wrapper";
import { ISecurityContextValue } from "@kushki/security-wrapper/dist/cjs/types/components/ModuleSecurityWrapper/ModuleSecurityWrapper.interfaces";
import { IUseRuleRequestManagerIndexState } from "../../../shared/infrastructure/interfaces/IUseRuleRequestManagerIndexState";

export const useRuleRequestManagerIndexState =
  (): IUseRuleRequestManagerIndexState => {
    const dropdownSplitButtonObjectValues: IDropdownSplitButtonProps = {
      idSecurityRulesItem: "",
      idWhiteList: "",
      idGeneralSecurityRule: "",
      onSelectedSecurityRulesItem: () => {
        window.location.href = Routes.CREATE_RULE_REQUEST;
      },
      onSelectedGeneralSecurityRule: () => {
        window.location.href = Routes.CREATE_GENERAL_RULE;
      },
      onSelectedWhiteList: () => {
        window.location.href = Routes.CREATE_WHITELIST_RULE;
      },
    };

    const securityContextValue: ISecurityContextValue =
      useContext(SecurityContext);

    return {
      securityContextValue,
      dropdownSplitButtonObjectValues,
    };
  };
