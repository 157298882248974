import React from "react";

export const DisableProcessingIcon: React.FC = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5065 32.3552C13.3135 32.3541 12.1697 31.8793 11.3265 31.0352L4.71155 24.4202C3.86885 23.5764 3.39551 22.4327 3.39551 21.2402C3.39551 20.0477 3.86885 18.9039 4.71155 18.0602L18.5865 4.18518C19.4303 3.34248 20.574 2.86914 21.7665 2.86914C22.9591 2.86914 24.1028 3.34248 24.9465 4.18518L31.5016 10.8152C32.3443 11.6589 32.8176 12.8027 32.8176 13.9952C32.8176 15.1877 32.3443 16.3314 31.5016 17.1752L17.6265 31.0352C16.7982 31.8648 15.6788 32.3384 14.5065 32.3552V32.3552ZM21.7515 5.86518C21.5531 5.86601 21.3568 5.9062 21.174 5.98345C20.9911 6.06069 20.8255 6.17345 20.6865 6.31518L6.82655 20.1752C6.54717 20.4562 6.39036 20.8364 6.39036 21.2327C6.39036 21.629 6.54717 22.0091 6.82655 22.2902L13.5015 28.9202C13.641 29.0608 13.8069 29.1724 13.9897 29.2485C14.1725 29.3247 14.3685 29.3639 14.5665 29.3639C14.7646 29.3639 14.9606 29.3247 15.1434 29.2485C15.3262 29.1724 15.4921 29.0608 15.6315 28.9202L29.4316 15.0002C29.5721 14.8607 29.6837 14.6948 29.7599 14.512C29.836 14.3293 29.8752 14.1332 29.8752 13.9352C29.8752 13.7372 29.836 13.5411 29.7599 13.3583C29.6837 13.1755 29.5721 13.0096 29.4316 12.8702L22.8165 6.31518C22.6776 6.17345 22.512 6.06069 22.3291 5.98345C22.1463 5.9062 21.95 5.86601 21.7515 5.86518Z"
        fill="#E2E8F0"
      />
      <path
        d="M21.7494 19.2302C21.4261 19.2312 21.1059 19.168 20.8073 19.0444C20.5086 18.9208 20.2374 18.7393 20.0094 18.5102L17.6244 16.1252C17.3958 15.8967 17.2144 15.6255 17.0907 15.3269C16.967 15.0284 16.9033 14.7084 16.9033 14.3852C16.9033 14.062 16.967 13.742 17.0907 13.4435C17.2144 13.1449 17.3958 12.8736 17.6244 12.6452L20.1444 10.1102C20.6156 9.66173 21.2413 9.41162 21.8919 9.41162C22.5424 9.41162 23.1681 9.66173 23.6394 10.1102L26.0244 12.4952C26.2529 12.7236 26.4343 12.9949 26.558 13.2935C26.6817 13.592 26.7454 13.912 26.7454 14.2352C26.7454 14.5584 26.6817 14.8784 26.558 15.1769C26.4343 15.4755 26.2529 15.7467 26.0244 15.9752L23.4894 18.5102C23.2613 18.7393 22.9901 18.9208 22.6915 19.0444C22.3928 19.168 22.0726 19.2312 21.7494 19.2302ZM20.1144 14.3852L21.7494 16.0052L23.5194 14.2352L21.8994 12.6002L20.1144 14.3852Z"
        fill="#E2E8F0"
      />
      <path
        d="M32.25 33.1353H3.75C3.35218 33.1353 2.97064 32.9772 2.68934 32.6959C2.40804 32.4146 2.25 32.0331 2.25 31.6353C2.25 31.2374 2.40804 30.8559 2.68934 30.5746C2.97064 30.2933 3.35218 30.1353 3.75 30.1353H32.25C32.6478 30.1353 33.0294 30.2933 33.3107 30.5746C33.592 30.8559 33.75 31.2374 33.75 31.6353C33.75 32.0331 33.592 32.4146 33.3107 32.6959C33.0294 32.9772 32.6478 33.1353 32.25 33.1353Z"
        fill="#E2E8F0"
      />
      <path
        d="M11.2499 22.6347C10.952 22.6364 10.6603 22.5494 10.412 22.3847C10.1637 22.22 9.97013 21.9851 9.85588 21.7099C9.74163 21.4347 9.71191 21.1318 9.77052 20.8397C9.82912 20.5475 9.97339 20.2795 10.1849 20.0697L13.1849 17.0697C13.3244 16.9291 13.4903 16.8175 13.6731 16.7413C13.8559 16.6652 14.0519 16.626 14.2499 16.626C14.448 16.626 14.644 16.6652 14.8268 16.7413C15.0096 16.8175 15.1755 16.9291 15.3149 17.0697C15.5943 17.3507 15.7511 17.7309 15.7511 18.1272C15.7511 18.5235 15.5943 18.9036 15.3149 19.1847L12.3149 22.1847C12.176 22.3264 12.0103 22.4392 11.8275 22.5164C11.6447 22.5937 11.4484 22.6339 11.2499 22.6347Z"
        fill="#E2E8F0"
      />
      <path
        d="M14.2509 25.6352C13.953 25.6369 13.6613 25.5499 13.413 25.3852C13.1647 25.2205 12.9711 24.9855 12.8569 24.7104C12.7426 24.4352 12.7129 24.1323 12.7715 23.8401C12.8301 23.548 12.9744 23.28 13.1859 23.0702L16.1859 20.0702C16.3254 19.9296 16.4913 19.818 16.674 19.7418C16.8568 19.6657 17.0529 19.6265 17.2509 19.6265C17.4489 19.6265 17.645 19.6657 17.8278 19.7418C18.0106 19.818 18.1765 19.9296 18.3159 20.0702C18.5953 20.3512 18.7521 20.7314 18.7521 21.1277C18.7521 21.5239 18.5953 21.9041 18.3159 22.1852L15.3159 25.1852C15.177 25.3269 15.0113 25.4396 14.8285 25.5169C14.6457 25.5941 14.4494 25.6343 14.2509 25.6352Z"
        fill="#E2E8F0"
      />
    </svg>
  );
};
