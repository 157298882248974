import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../src/shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { prefixes } from "../../../shared/constants/prefixes";
import { CreateMerchantNodeRequest } from "../../../../types/create_merchant_node_request";
import { CreateMerchantNodeResponse } from "../../../../types/create_merchant_node_response";
import { GetNodeInfoResponse } from "../../../../types/get_node_info_response";
import { GetNodeInfoRequest } from "../../../../types/get_node_info_request";
import { get } from "lodash";
import { SearchMerchantByNodeId } from "../../../../types/search_merchant_by_node_id";
import { AxiosResponse } from "axios";

export const createMerchantNode = createAsyncThunk(
  prefixes.CREATE_MERCHANT,
  async (request: CreateMerchantNodeRequest, { rejectWithValue }) => {
    try {
      const responseCreateMerchantNode =
        await axios.post<CreateMerchantNodeResponse>(
          API_ROUTES.CREATE_MERCHANT,
          request
        );

      return responseCreateMerchantNode.data;
    } catch (error) {
      return rejectWithValue(get(error, "response.data"));
    }
  }
);
export const getNodeInfo = createAsyncThunk(
  prefixes.NODE_INFO,
  async (payload: GetNodeInfoRequest) => {
    const getNodeInformation = await axios.post<GetNodeInfoResponse>(
      API_ROUTES.NODE_INFO,
      payload
    );

    return getNodeInformation.data;
  }
);
export const searchMerchantByNode = createAsyncThunk<
  GetNodeInfoResponse,
  SearchMerchantByNodeId
>(prefixes.CUSTOMER_MERCHANT, async (request: SearchMerchantByNodeId) => {
  const getNodeInformation: AxiosResponse<GetNodeInfoResponse> =
    await axios.get<GetNodeInfoResponse>(
      `${API_ROUTES.SEARCH_MERCHANT_NODE_BY_NODE_ID}${request.nodeId}`
    );

  return getNodeInformation.data;
});

export default createMerchantNode;
