import { CountryEnum } from "../CountryEnum";

export enum ModalTypeEnum {
  REJECT_MODAL_RETENTION = "reject_modal_retention",
  OMIT_MODAL_RETENTION = "omit_modal_retention",
}

export const FORMAT_DATE: string = "dd MMM. yyyy";

export enum ModalInfoElements {
  TITLE = "title",
  BODY = "body",
  CONFIRM = "confirm",
  ACCEPT = "accept",
  LABEL = "label",
}

export enum ModalInfoQuantity {
  SINGLE = "single",
  MULTIPLE = "multiple",
}

enum ModalTextContent {
  TITLE_SINGLE = "¿Deseas rechazar esta retención?",
  CONFIRM_TEXT = "*Indique el motivo del rechazo, para guardar la modificación.",
  ACCEPT = "Aceptar",
  REJECT_LABEL = "Motivo del rechazo",
  BODY_TEXT = "Estas a punto de rechazar esta retención, al dar click en “Aceptar” esta retención regresará a la bandeja del Usuario Ejecutor en su apartado de “Retención”.",
  COLOMBIA_MULTIPLE_TITLE = "¿Deseas rechazar estas retenciones?",
  COLOMBIA_MULTIPLE_LABEL = "Motivo de anulación",
  COLOMBIA_MULTIPLE_BODY = "Estas a punto de rechazar más de una retención, al dar click en “Aceptar” estas retenciones regresarán a la bandeja del Usuario Ejecutor en su apartado de “Retención”.",
  ECUADOR_MULTIPLE_TITLE = "¿Deseas rechazar todas las retenciones?",
  ECUADOR_MULTIPLE_BODY = "Estas a punto de rechazar estas retenciones, al dar click en “Aceptar” estas retenciones regresarán a la bandeja del Usuario Ejecutor en su apartado de “Retención”.",
}

export const ModalInfo: Record<string, object> = {
  [ModalTypeEnum.REJECT_MODAL_RETENTION]: {
    [CountryEnum.colombia]: {
      [ModalInfoQuantity.SINGLE]: {
        [ModalInfoElements.TITLE]: ModalTextContent.TITLE_SINGLE,
        [ModalInfoElements.BODY]: ModalTextContent.BODY_TEXT,
        [ModalInfoElements.CONFIRM]: ModalTextContent.CONFIRM_TEXT,
        [ModalInfoElements.ACCEPT]: ModalTextContent.ACCEPT,
        [ModalInfoElements.LABEL]: ModalTextContent.REJECT_LABEL,
      },
      [ModalInfoQuantity.MULTIPLE]: {
        [ModalInfoElements.TITLE]: ModalTextContent.COLOMBIA_MULTIPLE_TITLE,
        [ModalInfoElements.BODY]: ModalTextContent.COLOMBIA_MULTIPLE_BODY,
        [ModalInfoElements.CONFIRM]: ModalTextContent.CONFIRM_TEXT,
        [ModalInfoElements.ACCEPT]: ModalTextContent.ACCEPT,
        [ModalInfoElements.LABEL]: ModalTextContent.COLOMBIA_MULTIPLE_LABEL,
      },
    },
    [CountryEnum.ecuador]: {
      [ModalInfoQuantity.SINGLE]: {
        [ModalInfoElements.TITLE]: ModalTextContent.TITLE_SINGLE,
        [ModalInfoElements.BODY]: ModalTextContent.BODY_TEXT,
        [ModalInfoElements.CONFIRM]: ModalTextContent.CONFIRM_TEXT,
        [ModalInfoElements.ACCEPT]: ModalTextContent.ACCEPT,
        [ModalInfoElements.LABEL]: ModalTextContent.REJECT_LABEL,
      },
      [ModalInfoQuantity.MULTIPLE]: {
        [ModalInfoElements.TITLE]: ModalTextContent.ECUADOR_MULTIPLE_TITLE,
        [ModalInfoElements.BODY]: ModalTextContent.ECUADOR_MULTIPLE_BODY,
        [ModalInfoElements.CONFIRM]: ModalTextContent.CONFIRM_TEXT,
        [ModalInfoElements.ACCEPT]: ModalTextContent.ACCEPT,
        [ModalInfoElements.LABEL]: ModalTextContent.REJECT_LABEL,
      },
    },
  },
  [ModalTypeEnum.OMIT_MODAL_RETENTION]: {
    [ModalInfoQuantity.SINGLE]: {
      [ModalInfoElements.TITLE]: "¿Deseas omitir esta retención?",
      [ModalInfoElements.BODY]:
        "Estas a punto de omitir esta retención, al dar click en “Aceptar” esta retención ingresará a la pestaña de “Histórico”.",
      [ModalInfoElements.CONFIRM]:
        "*Indique el motivo de la omisión, para guardar la modificación.",
      [ModalInfoElements.ACCEPT]: "Aceptar",
      [ModalInfoElements.LABEL]: "Motivo...",
    },
    [ModalInfoQuantity.MULTIPLE]: {
      [ModalInfoElements.TITLE]: "¿Deseas omitir todas las retenciones?",
      [ModalInfoElements.BODY]:
        "Estas a punto de omitir mas de una retención, al dar click en “Aceptar” estas retenciones ingresaran al apartado “Histórico”.",
      [ModalInfoElements.CONFIRM]:
        "*Indique el motivo de la omisión, para guardar la modificación.",
      [ModalInfoElements.ACCEPT]: "Aceptar",
      [ModalInfoElements.LABEL]: "Motivo...",
    },
  },
};
