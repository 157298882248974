import React from "react";
import { Card, CardContent, Container, Grid } from "@material-ui/core";
import { CatalogListComponentProps } from "./CatalogListComponent.interfaces";
import { Catalog } from "../../../types/catalog";
import { CatalogDetail } from "../CatalogDetail/CatalogDetail";
import useStyles from "./CatalogListComponent.styles";

export const CatalogListComponent: React.FC<CatalogListComponentProps> = (
  props: CatalogListComponentProps
) => {
  const { listCatalog } = props;
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Container className={classes.container}>
          <Grid container direction="row">
            <Grid item xs={12}>
              {listCatalog.map((catalog: Catalog) => (
                <CatalogDetail key={catalog.name} catalog={catalog} />
              ))}
            </Grid>
          </Grid>
        </Container>
      </CardContent>
    </Card>
  );
};
