import { Grid } from "@mui/material";
import { ModalLoader } from "@kushki/connect-ui";
import React from "react";
import { ProcessingTab } from "../../components/ProcessingTab/ProcessingTab";
import { BusinessRules } from "../../components/BusinessRules/BusinessRules";
import { Subheader } from "../../components/Subheader/Subheader";
import { Footer } from "../../components/Footer/Footer";
import { useOnInitProcessing } from "./state/useOnInitProcessing";
import { ContinueModal } from "../../components/Modal/ContinueModal";
import { TabIndex } from "../../shared/enums/TabIndexEnum";
import {
  IProcessingConfigIndexProps,
  ITabContent,
} from "./ProcessingConfigIndex.interface";
import { Deferred } from "../../components/Deferred/Deferred";
import { defaultTo, get } from "lodash";
import { NodeInfoPath } from "../../shared/enums/NodeInfoPath";
import { Processors } from "../../components/Processors/Processors";
import { MessageAlertEnum } from "../../shared/enums/AlertEnum";
import { MassiveHeader } from "../../components/MassiveLayout/MassiveHeader/MassiveHeader";
import { ProcessingConfigIndexStyles } from "./ProcessingConfigIndex.styles";

const TabContent: React.FC<ITabContent> = ({
  tabName,
  rules,
  deferred,
  deleteModalInfo,
  isBatch,
  payoutRules,
}: ITabContent) => {
  switch (tabName) {
    case TabIndex.BUSINESS_RULES:
      return (
        <BusinessRules
          rules={rules}
          deleteModalInfo={deleteModalInfo}
          isBatch={isBatch}
          payoutRules={payoutRules}
        />
      );
    case TabIndex.DEFERRED:
      return (
        <Deferred deferredList={deferred} deleteModalInfo={deleteModalInfo} />
      );
    default:
      return <Processors />;
  }
};

export const ProcessingConfigIndex: React.FC<IProcessingConfigIndexProps> = ({
  isBatch,
}: IProcessingConfigIndexProps) => {
  const {
    isMassivePath,
    businessRules,
    tabSelected,
    deferred,
    filterNodeInfoConfigByTab,
    loadingModal,
    nodeInfo,
    deleteModalInfo,
    isLoading,
    payoutsBusinessRules,
  } = useOnInitProcessing(isBatch);

  return (
    <>
      <Grid container sx={ProcessingConfigIndexStyles.principalContainer}>
        <Grid container item xs={12} direction={"column"}>
          {isMassivePath ? (
            <MassiveHeader />
          ) : (
            <Subheader
              title={get(nodeInfo, NodeInfoPath.InfoName, "")}
              country={get(nodeInfo, NodeInfoPath.InfoCountry, "")}
              isLoading={isLoading}
              id={get(nodeInfo, "merchantId", "")}
              redirectPath={""}
            />
          )}
          <ProcessingTab />
          <TabContent
            tabName={tabSelected}
            rules={businessRules.items}
            payoutRules={payoutsBusinessRules}
            deferred={deferred}
            deleteModalInfo={deleteModalInfo}
            isBatch={isBatch}
          />
        </Grid>
      </Grid>
      <ContinueModal />
      <ModalLoader
        descriptionText={defaultTo(
          loadingModal.description,
          MessageAlertEnum.LOADING_MESSAGE_WAITING
        )}
        titleText={loadingModal.message}
        isOpen={loadingModal.isOpen}
      />
      <Footer
        isBatch={isBatch}
        nodeInfoConfig={filterNodeInfoConfigByTab}
        isMassive={isMassivePath}
        tabSelected={tabSelected}
      />
    </>
  );
};
