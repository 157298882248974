import { routes } from "../../../shared/infrastructure/constants/routes";
import { useState } from "react";
import { pluginsImg } from "../../../shared/infrastructure/constants/plugin-imgs";
import { useHistory } from "react-router-dom";

export interface IUsePluginIndexState {
  confirmation: {
    show: boolean;
    plugin: string;
  };
  handler: {
    getStateMerchantId: () => string | null;
    goToKform: (plugin: string) => void;
    renderConfirmation: (plugin: string) => void;
    getPluginSvg: (plugin: string) => string;
  };
}

export const usePluginIndexState = (): IUsePluginIndexState => {
  const [confirmation, setConfirmation] = useState({
    show: false,
    plugin: "",
  });
  const history = useHistory();
  const getPluginSvg = (plugin: string): string => pluginsImg[plugin];
  const getStateMerchantId = () => {
    const merchantId = localStorage.getItem("merchantId");
    return merchantId;
  };
  const goToKform = (plugin: string) => {
    const url = `${routes.KFORM_EDIT}/${getStateMerchantId()}?plugin=${plugin}`;
    history.push(url);
  };

  const renderConfirmation = (plugin: string) => {
    setConfirmation({
      show: true,
      plugin,
    });
  };

  return {
    confirmation,
    handler: {
      getStateMerchantId,
      goToKform,
      renderConfirmation,
      getPluginSvg,
    },
  };
};
