import React from "react";
const ManualIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_398_239535)">
        <path
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          stroke="#CBD5E0"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.248 7V16.73H15.652V10.066L12.684 16.73H11.578L8.596 10.066V16.73H7V7H8.722L12.138 14.63L15.54 7H17.248Z"
          fill="#CBD5E0"
        />
      </g>
      <defs>
        <clipPath id="clip0_398_239535">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ManualIcon;
