import React, { useState } from "react";
import { AppBar, Box, Tab, Tabs, Typography } from "@material-ui/core";

interface contentTab {
  title: string;
  content: JSX.Element;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
export interface IKshTab {
  tabs: {
    title: string;
    content: JSX.Element;
  }[];
  onTabClick?(tabId: number): void;
}
const tabProps = (index: any) => ({
  id: `simple-tab-${index}`,
  "aria-controls": `simple-tabpanel-${index}`,
});

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export const KshTab = (props: IKshTab) => {
  const [valueTab, setValueTab] = useState<number>(0);

  const changeTab = (tabId: number): void => {
    setValueTab(tabId);
    props.onTabClick?.(tabId);
  };
  return (
    <React.Fragment>
      <AppBar position="static" color="transparent" elevation={0}>
        <Tabs
          value={valueTab}
          aria-label="billing tabs"
          variant="fullWidth"
          indicatorColor="primary"
          onChange={(_e, newValue) => changeTab(newValue)}
        >
          {props.tabs
            .map((tab: contentTab) => tab.title)
            .map((title: string, index: number) => (
              <Tab
                key={title.replace(/ /g, "_")}
                label={<Typography variant="h6">{title}</Typography>}
                {...tabProps(index)}
                style={{ textTransform: "none" }}
              />
            ))}
        </Tabs>
      </AppBar>
      {props.tabs.map((tab: contentTab, index: number) => (
        <TabPanel value={valueTab} index={index} key={`content_${index}`}>
          {tab.content}
        </TabPanel>
      ))}
    </React.Fragment>
  );
};
