import React, { useEffect } from "react";
import {
  createStyles,
  Grid,
  ListItem,
  ListItemText,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  IProcessorsType,
  setStatusProcessors,
} from "../../../store/actionCreators";
import { RatesConfigDynamo } from "../../../../types/rates_config_dynamo";
import { CheckCircle, RemoveCircle } from "@material-ui/icons";
import { get, keys, sortedUniq, values } from "lodash";
import { StatusSemaphoreEnum } from "../../../shared/infrastructure/catalogs/StatusSemaphoreEnum";
import { useDispatch } from "react-redux";
import { MerchantProcessors } from "../../../../types/merchant_processors";
import {
  PaymentMethodEnum,
  PaymentMethodTypeEnum,
} from "../../../shared/infrastructure/catalogs/PaymentMethodEnum";

export interface IIndicatorProps {
  stepConfigRatesAndInvoice?: object;
  processors?: IProcessorsType;
  rates?: RatesConfigDynamo[];
  publicMerchantId?: string;
}

const styles = makeStyles((theme: Theme) =>
  createStyles({
    indicatorStyles: {
      minWidth: `${theme.spacing(2)}px`,
    },
    root: {
      marginTop: theme.spacing(3),
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  })
);

const getArrayProcessor = (props: IIndicatorProps) => {
  const transfer = [
    ...get(props.processors, "transfer", []),
    ...get(props.processors, "transferPayout", []),
  ];
  const cash = [
    ...get(props.processors, "cash", []),
    ...get(props.processors, "cashPayout", []),
  ];
  const dataProcessor: MerchantProcessors[] = [
    ...get(props.processors, "card", []),
    ...transfer,
    ...cash,
  ];

  return sortedUniq(dataProcessor);
};

const getArrayRates = (props: IIndicatorProps) => {
  return sortedUniq(
    get(props, "rates", [] as RatesConfigDynamo[]).reduce(
      (ratesConfig: string[], rate: RatesConfigDynamo) => [
        ...ratesConfig,
        ...rate.paymentMethod,
      ],
      []
    )
  );
};

const getStatusProcessors = (props: IIndicatorProps) => {
  const dataProcessor = getArrayProcessor(props);
  const dataRates = getArrayRates(props);
  let statusSemaphore: object = {
    card: StatusSemaphoreEnum.OMITTED,
    transfer: StatusSemaphoreEnum.OMITTED,
    cash: StatusSemaphoreEnum.OMITTED,
  };
  dataProcessor.forEach((processor) => {
    const payment_method: string = transformPaymentMethod(
      get(processor, "paymentMethod", "")
    );
    if (dataRates.includes(payment_method))
      statusSemaphore[payment_method] = StatusSemaphoreEnum.COMPLETE;
    else statusSemaphore[payment_method] = StatusSemaphoreEnum.PENDING;
  });

  return statusSemaphore;
};
const transformPaymentMethod = (payment: string) => {
  switch (payment) {
    case "payouts-transfer":
      return PaymentMethodTypeEnum.transfer;
    case "payouts-cash":
      return PaymentMethodTypeEnum.cash;
    default:
      return payment;
  }
};

export const Indicators: React.FC<IIndicatorProps> = (
  props: IIndicatorProps
) => {
  const classes = styles();
  const dispatch = useDispatch();
  let statusProcessor: object = getStatusProcessors(props);

  useEffect(() => {
    statusProcessor = getStatusProcessors(props);
    dispatch(setStatusProcessors(statusProcessor));
  }, [props.processors!]);

  const iconStatusRates = (status: string) => {
    switch (status) {
      case StatusSemaphoreEnum.PENDING:
        return (
          <RemoveCircle className={classes.icon} style={{ color: "#fddc88" }} />
        );
      default:
        return (
          <CheckCircle className={classes.icon} style={{ color: "#4bdfab" }} />
        );
    }
  };
  return !values(statusProcessor).every(
    (processor) => processor === StatusSemaphoreEnum.OMITTED
  ) ? (
    <Grid
      container
      direction="row"
      alignItems="center"
      justify="center"
      id={"indicator"}
    >
      <Grid item xs={3}>
        <Typography variant="h6" color="textSecondary" id={"title"}>
          Tarifas por agregar:
        </Typography>
      </Grid>

      <Grid
        item
        container
        direction="row"
        spacing={2}
        justify="flex-start"
        alignItems="center"
        xs={9}
      >
        {keys(statusProcessor).map((processor: string, index: number) => {
          return (
            get(statusProcessor, processor) !== StatusSemaphoreEnum.OMITTED && (
              <Grid item key={index}>
                <ListItem disableGutters>
                  {iconStatusRates(get(statusProcessor, processor))}

                  {get(statusProcessor, processor) ===
                  StatusSemaphoreEnum.PENDING ? (
                    <Tooltip
                      title="Debe crear al menos una tarifa para este metodo de pago"
                      arrow
                      placement={"top"}
                    >
                      <ListItemText
                        id={"itemText"}
                        primary={PaymentMethodEnum[processor]}
                      />
                    </Tooltip>
                  ) : (
                    <ListItemText
                      id={"itemText"}
                      primary={PaymentMethodEnum[processor]}
                    />
                  )}
                </ListItem>
              </Grid>
            )
          );
        })}
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};
