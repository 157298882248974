import axios from "../../../shared/axios-util";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { RatesConfigDynamo } from "../../../../types/rates_config_dynamo";

export const getRatesInfo = createAsyncThunk<
  RatesConfigDynamo[],
  { publicMerchantId: string }
>("ratesApp/getRatesInfo", async ({ publicMerchantId }) => {
  const response = await axios.post<RatesConfigDynamo[]>(
    API_ROUTES.RATES_INFO,
    { publicMerchantId }
  );

  return response.data;
});
