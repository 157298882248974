import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppState } from "../../interfaces/AppState.interfaces";
import {
  chargebackTransaction,
  chargebackTrxWithEmail,
  downloadFileForTrxSelected,
  downloadTransactions,
  getContactInformation,
  getFirebaseID,
  getMerchantInfo,
  getMerchantNodeInfo,
  getMerchantsRequest,
  getNodeInfo,
  getPaymentReceipt,
  getRetrieveChildren,
  getRetrieveChildrenMongo,
  notifySelectCheckChipsChange,
  searchMerchant,
  searchOriginalTrx,
  validateReceivable,
  validateTimeRefund,
  voidTransaction,
} from "../../thunks/app/app.thunks";
import { MerchantForm } from "../../../../types/merchant_form";
import { get, has, isEmpty } from "lodash";
import {
  buildNotification,
  defaultDataSnackbar,
} from "../../../shared/constants/snackbar";
import {
  NotificationTypeEnum,
  SnackbarEnum,
} from "../../../shared/enum/SnackbarEnum";
import { ISnackBar } from "@kushki/connect-ui/dist/Components/Atoms/DataDisplay/SnackBar/SnackBar.interface";
import { TransactionInfo } from "../../../../types/transactions_data";

export const initialState: IAppState = {
  clickCount: 0,
  closeVoidDialog: false,
  countSuccessResults: 0,
  currentTransaction: {
    _source: {
      approval_code: "",
      client_name: "",
      created: "",
      ice_value: undefined,
      iva_value: undefined,
      masked_credit_card: "",
      merchant_name: "",
      metadata: {},
      number_of_months: undefined,
      payment_brand: "",
      payment_method: "",
      ticket_number: "",
      transaction_status: "",
      transaction_type: "",
    },
  },
  dataContactInfo: {},
  error: "",
  file: {
    totalProcessed: 0,
    totalRecords: 0,
    url: "",
  },
  firebaseId: "",
  isLoadingMerchantInfo: false,
  isLoadingNodeInfo: false,
  isLoadingOriginalTrx: false,
  isLoadingTimeRefund: false,
  isLoadingTransaction: false,
  isMongo: false,
  isTransactionSearch: false,
  loadingChargebackAction: false,
  loadingDownload: false,
  loadingMerchants: false,
  loadingSearchContactInfo: false,
  loadingTrx: true,
  merchantBalance: {
    balance: 0,
  },
  merchantInfo: {
    accountNumber: "",
    accountType: "0",
    address: "",
    bankId: "",
    chargeFrequency: "none",
    chargeMin: "",
    chargeMinAmount: 0,
    city: "",
    contactPerson: "",
    country: "",
    documentType: "0",
    email: "",
    invoiceFrequency: "none",
    name: "",
    phoneNumber: "",
    province: "",
    publicMerchantId: "",
    socialReason: "",
    taxId: "",
    webSite: "",
    zipCode: "",
  },
  merchantNodeInfo: {},
  merchants: {
    data: [],
    total: 0,
  },
  nodeInfo: {
    configCoreId: "",
  },
  originalTrx: {
    data: [],
  },
  receipt: undefined,
  receivable: false,
  resultTrx: [],
  retrieveChildren: [],
  selectCheckChipsChange: false,
  selectedBranchColumns: [],
  setPayloadLocalStorage: false,
  suggets: {
    data: [],
  },
  timeRefund: {
    daysToExpire: {
      dateResult: "",
      days: 0,
    },
    expirationConsoleDay: {
      dateResult: "",
      days: 0,
    },
    expirationDate: {
      dateResult: "",
      days: 0,
    },
    processorName: "",
  },
  transactionData: {
    data: [],
  },
  transactionFatherData: {
    data: [],
  },
  voidChargeBackError: false,
  voidDialogError: false,
  watchFieldName: "created",
};

export const appSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getMerchantInfo.pending, (state) => {
        state.isLoadingMerchantInfo = true;
      })
      .addCase(getMerchantInfo.fulfilled, (state, action) => {
        state.merchantInfo = action.payload;
        state.isLoadingMerchantInfo = false;
      })
      .addCase(getMerchantInfo.rejected, (state) => {
        state.isLoadingMerchantInfo = false;
      });
    builder
      .addCase(getNodeInfo.pending, (state) => {
        state.isLoadingNodeInfo = true;
      })
      .addCase(getNodeInfo.fulfilled, (state, action) => {
        state.nodeInfo = action.payload;
        state.isLoadingNodeInfo = false;
      })
      .addCase(getNodeInfo.rejected, (state) => {
        state.isLoadingNodeInfo = false;
      });
    builder
      .addCase(getMerchantNodeInfo.pending, (state) => {
        state.isLoadingNodeInfo = true;
      })
      .addCase(getMerchantNodeInfo.fulfilled, (state, action) => {
        state.merchantNodeInfo = action.payload;
        state.isLoadingNodeInfo = false;
      })
      .addCase(getMerchantNodeInfo.rejected, (state) => {
        state.isLoadingNodeInfo = false;
      });
    builder
      .addCase(getMerchantsRequest.pending, (state) => {
        state.loadingMerchants = true;
      })
      .addCase(getMerchantsRequest.fulfilled, (state, action) => {
        const merchantsWithName: MerchantForm[] = action.payload.data.filter(
          (res) => !isEmpty(res.name)
        );

        state.merchants.data = merchantsWithName;
        state.merchants.total = merchantsWithName.length;
        state.loadingMerchants = false;
      })
      .addCase(getMerchantsRequest.rejected, (state) => {
        state.loadingMerchants = false;
      });
    builder
      .addCase(getFirebaseID.pending, (state) => {
        state.loadingDownload = true;
      })
      .addCase(getFirebaseID.fulfilled, (state, action) => {
        state.firebaseId = action.payload.id;
      })
      .addCase(getFirebaseID.rejected, (state) => {
        state.notification = buildNotification(
          NotificationTypeEnum.FAILED,
          get(state, "notification", defaultDataSnackbar)
        );
        state.error = "Se superó el número máximo de registros en el archivo";
        state.loadingDownload = false;
      });
    builder
      .addCase(downloadTransactions.pending, (state) => {
        state.loadingDownload = true;
      })
      .addCase(downloadTransactions.fulfilled, (state, action) => {
        state.file = action.payload;
        state.loadingDownload = false;
      })
      .addCase(downloadTransactions.rejected, (state) => {
        state.loadingDownload = false;
      });
    builder
      .addCase(searchMerchant.pending, (state) => {
        state.isLoadingTransaction = true;
      })
      .addCase(searchMerchant.fulfilled, (state, action) => {
        const data = get(action.payload, "data.data", []);

        if (data !== undefined && data.length > 0) {
          state.isMongo = has(data[0], "_source._id");
        }

        if (action.payload.isSecondSearch)
          state.transactionFatherData = action.payload.data;
        else state.transactionData = action.payload.data;
        state.isLoadingTransaction = false;
      })
      .addCase(searchMerchant.rejected, (state) => {
        state.isLoadingTransaction = false;
      });
    builder.addCase(chargebackTransaction.fulfilled, (state, action) => {
      const errors: string[] = action.payload.filter((item: string) =>
        item.includes("Número de ticket")
      );

      action.payload.map((item: string) => state.resultTrx.push(item));
      state.voidChargeBackError = errors.length > 0;
      state.voidDialogError = true;
      state.closeVoidDialog = true;
    });
    builder
      .addCase(validateTimeRefund.pending, (state) => {
        state.isLoadingTimeRefund = true;
      })
      .addCase(validateTimeRefund.fulfilled, (state, action) => {
        state.timeRefund = action.payload;
        state.isLoadingTimeRefund = false;
      })
      .addCase(validateTimeRefund.rejected, (state) => {
        state.isLoadingTimeRefund = false;
      });
    builder
      .addCase(validateReceivable.fulfilled, (state, action) => {
        state.receivable = action.payload.isReceivable;
      })
      .addCase(validateReceivable.rejected, (state) => {
        state.notification = buildNotification(
          NotificationTypeEnum.FAILED,
          get(state, "notification", defaultDataSnackbar)
        );
      });
    builder
      .addCase(getPaymentReceipt.fulfilled, (state, action) => {
        state.receipt = action.payload.buffer;
      })
      .addCase(getPaymentReceipt.rejected, (state) => {
        state.notification = buildNotification(
          NotificationTypeEnum.FAILED,
          get(state, "notification", defaultDataSnackbar)
        );
      });
    builder
      .addCase(chargebackTrxWithEmail.pending, (state) => {
        state.loadingChargebackAction = true;
      })
      .addCase(chargebackTrxWithEmail.fulfilled, (state, action) => {
        state.loadingChargebackAction = false;
        state.notification = buildNotification(NotificationTypeEnum.SUCCESS, {
          ...defaultDataSnackbar,
          message:
            SnackbarEnum.CHARGEBACK_REQUEST_SUCCESS +
            action.payload.ticketNumber,
        });
      })
      .addCase(chargebackTrxWithEmail.rejected, (state) => {
        state.loadingChargebackAction = false;
        state.notification = buildNotification(NotificationTypeEnum.FAILED, {
          ...defaultDataSnackbar,
          message: SnackbarEnum.CHARGEBACK_REQUEST_FAILED,
        });
      });
    builder
      .addCase(downloadFileForTrxSelected.pending, (state) => {
        state.loadingDownload = true;
      })
      .addCase(downloadFileForTrxSelected.fulfilled, (state, action) => {
        state.firebaseId = action.payload.id;
      })
      .addCase(downloadFileForTrxSelected.rejected, (state) => {
        state.loadingDownload = false;
      });
    builder
      .addCase(getContactInformation.pending, (state) => {
        state.loadingSearchContactInfo = true;
      })
      .addCase(getContactInformation.fulfilled, (state, action) => {
        state.loadingSearchContactInfo = false;
        state.dataContactInfo = action.payload;
      })
      .addCase(getContactInformation.rejected, (state) => {
        state.loadingSearchContactInfo = false;
      });
    builder.addCase(voidTransaction.fulfilled, (state, action) => {
      state.countSuccessResults = action.payload.successResults;

      if (action.payload.errorResults === 0) {
        state.voidChargeBackError = false;
        if (action.payload.request.length === 1) {
          state.notification = buildNotification(
            NotificationTypeEnum.SUCCESS,
            get(state, "notification", defaultDataSnackbar)
          );
        } else {
          state.notification = buildNotification(
            NotificationTypeEnum.SUCCESS,
            get(state, "notification", defaultDataSnackbar)
          );
        }
      } else {
        state.voidChargeBackError = true;
        if (action.payload.request.length === 1) {
          state.notification = buildNotification(
            NotificationTypeEnum.FAILED,
            get(state, "notification", defaultDataSnackbar),
            `Error Número de ticket ${action.payload.request[0].id}`
          );
        } else {
          action.payload.request.map((data) => {
            state.resultTrx.push(`- ID ${data.id}`);
          });
          state.voidDialogError = true;
        }
      }
    });
    builder
      .addCase(getRetrieveChildren.pending, (state) => {
        state.isLoadingTransaction = true;
      })
      .addCase(getRetrieveChildren.fulfilled, (state, action) => {
        if (action.payload.isTransactionSearch)
          state.isLoadingTransaction = false;

        state.isTransactionSearch = action.payload.isTransactionSearch;
        state.retrieveChildren = action.payload.data;
      })
      .addCase(getRetrieveChildren.rejected, (state) => {
        state.isLoadingTransaction = false;
      });
    builder
      .addCase(getRetrieveChildrenMongo.pending, (state) => {
        state.isLoadingTransaction = true;
      })
      .addCase(getRetrieveChildrenMongo.fulfilled, (state, action) => {
        if (action.payload.isTransactionSearch)
          state.isLoadingTransaction = false;

        state.isTransactionSearch = action.payload.isTransactionSearch;
        state.retrieveChildren = action.payload.data;
      })
      .addCase(getRetrieveChildrenMongo.rejected, (state) => {
        state.isLoadingTransaction = false;
      });
    builder.addCase(notifySelectCheckChipsChange.fulfilled, (state, action) => {
      state.selectCheckChipsChange = action.payload;
    });
    builder
      .addCase(searchOriginalTrx.pending, (state) => {
        state.isLoadingOriginalTrx = true;
      })
      .addCase(searchOriginalTrx.fulfilled, (state, action) => {
        state.isLoadingOriginalTrx = false;
        state.originalTrx = action.payload;
      })
      .addCase(searchOriginalTrx.rejected, (state) => {
        state.isLoadingOriginalTrx = false;
      });
  },
  initialState,
  name: "app",
  reducers: {
    deleteBranchColumn: (state, { payload }: PayloadAction<number>) => {
      state.selectedBranchColumns.splice(payload, 1);
    },
    resetOriginalTrx: (state) => {
      state.originalTrx = { data: [] };
    },
    resetTransactionList: (state) => {
      state.transactionData = {
        data: [],
        total: 0,
      };
    },
    setAllBranchColumn: (
      state,
      { payload }: PayloadAction<TransactionInfo[]>
    ) => {
      if (isEmpty(payload) || isEmpty(state.selectedBranchColumns))
        state.selectedBranchColumns = payload;
      else
        state.selectedBranchColumns = [
          ...payload,
          ...state.selectedBranchColumns,
        ];
    },
    setBranchColumn: (state, { payload }: PayloadAction<TransactionInfo>) => {
      state.selectedBranchColumns.push(payload);
    },
    setClearTrxResult: (state) => {
      state.resultTrx = [];
    },
    setClickCount: (state, { payload }: PayloadAction<number>) => {
      state.clickCount = payload;
    },
    setFirebaseId: (state, { payload }: PayloadAction<string>) => {
      state.firebaseId = payload;
    },
    setLoadingDownload: (state, { payload }: PayloadAction<boolean>) => {
      state.loadingDownload = payload;
    },
    setNotification: (
      state,
      { payload }: PayloadAction<ISnackBar | undefined>
    ) => {
      state.notification = payload;
    },
    setWatchFieldName: (state, { payload }: PayloadAction<string>) => {
      state.watchFieldName = payload;
    },
  },
});

export default appSlice.reducer;
