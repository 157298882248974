export enum ResumeBarLabels {
  COMMISSION = "Comisión",
  TOTAL_TRX = "Total de tx",
  MERCHANTS = "Comercios",
  TOTAL_AMOUNT = "Monto total",
}

export const ResumeBarTooltips: Record<ResumeBarLabels, string> = {
  [ResumeBarLabels.COMMISSION]:
    "Total de las comisiones de los productos seleccionados",
  [ResumeBarLabels.TOTAL_TRX]:
    "Sumatoria de las transacciones realizadas según el filtro",
  [ResumeBarLabels.MERCHANTS]:
    "Número de comercios que utilizan los productos seleccionados",
  [ResumeBarLabels.TOTAL_AMOUNT]:
    "Sumatoria del monto de las transacciones aprobadas",
};
