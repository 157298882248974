import { useEffect, useState } from "react";

import { defaultTo, get, isEmpty, orderBy } from "lodash";
import { DeferredOption } from "../../../../../types/deferred_options";
import { TablePaginationProps } from "../TablePagination";

export interface usePaginationResult {
  tableSize: number;
  currentPage: number;
  totalRecords: number;
  pagination: number;
  handleChangePageSize: (pageSize: number) => void;
  handleChangePage: (page: number) => void;
}

export const usePaginationState = (
  props: TablePaginationProps
): usePaginationResult => {
  const [tableSize, setTableSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(
    defaultTo(props.merchantDeferred, []).length
  );

  const [pagination, setPagination] = useState(0);

  const handleChangePageSize = (pageSize: number): void => {
    setTableSize(pageSize);
    setCurrentPage(1);
  };
  const handleChangePage = (page: number): void => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const records: DeferredOption[] = get(props, "merchantDeferred", []);
    const countPerPage: number = Math.ceil(Number(records.length / tableSize));
    const start: number = (currentPage - 1) * tableSize + 1;
    const end: number = Math.min(start + tableSize - 1, records.length);

    if (start > end && records.length > 0) {
      setCurrentPage(currentPage - 1);
    } else {
      props.handlePaginationData(records.slice(start - 1, end));
      setPagination(countPerPage);
      setTotalRecords(records.length);
    }
  }, [props.merchantDeferred, currentPage, tableSize]);

  useEffect(() => {
    const start: number = (currentPage - 1) * tableSize + 1;
    const end: number = Math.min(start + tableSize - 1, totalRecords);

    if (!isEmpty(props.sortDirection)) {
      props.handlePaginationData(
        orderBy(
          props.merchantDeferred,
          "processorName",
          props.sortDirection
        ).slice(start - 1, end)
      );
    }
  }, [props.sortDirection, props.merchantDeferred]);

  return {
    currentPage,
    handleChangePage,
    handleChangePageSize,
    pagination,
    tableSize,
    totalRecords,
  };
};
