import React from "react";
import { Box, Button, Dialog, Paper, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  StatusDialogTexts,
  StatusDialogTypeEnum,
} from "../../shared/infrastructure/constants/CreateDeferredConstants";

const useStyles = makeStyles((theme: Theme) => ({
  activeDeferredColor: {
    color: theme.palette.primary.main + " !important",
  },
  buttonFont: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "22.4px",
  },
  disableDeferredColor: {
    color: theme.palette.error.main + " !important",
  },
  subtitle: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    margin: 0,
    marginBottom: 24,
    width: 367,
  },
  title: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "19px",
    margin: 0,
    marginBottom: 10,
  },
}));

export interface IDeferredStatusModalProps {
  dialogTexts: StatusDialogTexts;
  openDialog: boolean;
  handleConfirmChangeStatusDeferred: () => void;
  handleCloseDialog: () => void;
}

const DeferredStatusModal: React.FC<IDeferredStatusModalProps> = ({
  dialogTexts,
  openDialog,
  handleCloseDialog,
  handleConfirmChangeStatusDeferred,
}: IDeferredStatusModalProps) => {
  const classes = useStyles();

  const { type, title, subtitle, buttonText } = dialogTexts;

  const getColor = (type: StatusDialogTypeEnum) => {
    return type === StatusDialogTypeEnum.DEACTIVATE
      ? classes.disableDeferredColor
      : classes.activeDeferredColor;
  };

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <Paper>
        <Box key="modal_deferred" p={3} display="flex" flexDirection="column">
          <p className={classes.title}>{title}</p>
          <p className={classes.subtitle}>{subtitle}</p>
          <Box key="modal_buttons" display="flex" justifyContent="flex-end">
            <Button className={classes.buttonFont} onClick={handleCloseDialog}>
              Cancelar
            </Button>
            <Button
              className={`${classes.buttonFont} ${getColor(type)}`}
              onClick={handleConfirmChangeStatusDeferred}
            >
              {buttonText}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Dialog>
  );
};

export default DeferredStatusModal;
