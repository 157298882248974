import { useFormContext } from "react-hook-form";
import { CategoryData } from "@kushki/connect-ui";
import { useEffect, useState } from "react";
import { get, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { setOpenModalCatalogGeneralRules } from "../../../store/actionCreators";
import { SubTypeEnum } from "../../../shared/infrastructure/enums/CreateRuleConstans";
import { GetMerchantResponse } from "../../../../types/get_merchant_response";
import { IAppState } from "../../../store/reducer";

export interface IUseAssignRuleState {
  isDisabledSelectRuleBtn: boolean;
  handle: {
    handleSelectRule: () => void;
  };
}

export const useAssignRuleState = (): IUseAssignRuleState => {
  const dispatch = useDispatch();
  const { watch } = useFormContext();

  const countries: string[] | undefined = watch("countries");
  const subtype: string | undefined = watch("subType");
  const hierarchy: CategoryData | undefined = watch("hierarchy");
  const withoutHierarchy: CategoryData | undefined = watch("withoutHierarchy");
  const commerceType: string | undefined = watch("commerceType");
  const [isDisabledSelectRuleBtn, setIsDisabledSelectRuleBtn] =
    useState<boolean>(true);
  const merchant_info: GetMerchantResponse | undefined = useSelector(
    (state: IAppState) => state.merchantInformation
  );

  const handleSelectRule = () => {
    dispatch(
      setOpenModalCatalogGeneralRules({
        openModal: true,
        showButton: true,
      })
    );
  };

  const isPaymentMethod: boolean | undefined = useSelector(
    (state: IAppState) => state.isPaymentMethod
  );

  useEffect(() => {
    if (
      commerceType === SubTypeEnum.COMMERCE_WITH_HIERARCHY &&
      isPaymentMethod!
    )
      setIsDisabledSelectRuleBtn(
        isEmpty(get(hierarchy, "text", "")) ||
          isEmpty(get(merchant_info, "data[0]._source.merchantId", ""))
      );
    else setIsDisabledSelectRuleBtn(true);
  }, [countries, hierarchy, merchant_info, commerceType, subtype]);

  useEffect(() => {
    if (
      commerceType === SubTypeEnum.COMMERCE_WITHOUT_HIERARCHY &&
      isPaymentMethod!
    )
      setIsDisabledSelectRuleBtn(isEmpty(get(withoutHierarchy, "text", "")));
    else setIsDisabledSelectRuleBtn(true);
  }, [withoutHierarchy, commerceType, subtype]);

  useEffect(() => {
    if (subtype === SubTypeEnum.COUNTRY)
      setIsDisabledSelectRuleBtn(isEmpty(countries));
  }, [countries, subtype]);

  return {
    isDisabledSelectRuleBtn,
    handle: {
      handleSelectRule,
    },
  };
};
