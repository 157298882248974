import { makeStyles } from "@material-ui/core/styles";

export const amountComponentStyle = makeStyles({
  mainContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  textFieldContainer: {
    display: "flex",
    justifyContent: "flex-start",
  },
});
