import { ItemCategoryProps } from "@kushki/connect-ui";
import { VerifyIfComponentEnable } from "@kushki/security-wrapper";
import {
  filterItems,
  filterItemsGeneral,
} from "@shared/constants/categoryItems";
import { FilterPlaceHolder, FilterTitle } from "@shared/enum/FilterLabelEnum";
import { useEffect, useState } from "react";
import { IUseAlarmFilter } from "@components/Filters/AlarmFilters/AlarmFilters.interfaces";
import { SecurityWrapperRoles } from "@shared/enum/SecurityWrapperRoles";

export const useAlarmFilters = (): IUseAlarmFilter => {
  const verifyFrequencySection: boolean = VerifyIfComponentEnable(
    SecurityWrapperRoles.M_COMPLIANCE_FILTER_FREQUENCY
  );
  const verifyStateSection: boolean = VerifyIfComponentEnable(
    SecurityWrapperRoles.M_COMPLIANCE_FILTER_STATE
  );
  const verifyConstitutionalCountrySection: boolean = VerifyIfComponentEnable(
    SecurityWrapperRoles.M_COMPLIANCE_FILTER_CONSTITUTIONAL_COUNTRY
  );

  const verifyCountrySection: boolean = VerifyIfComponentEnable(
    SecurityWrapperRoles.M_COMPLIANCE_FILTER_COUNTRY
  );
  const verifyCategorySection: boolean = VerifyIfComponentEnable(
    SecurityWrapperRoles.M_COMPLIANCE_FILTER_CATEGORY
  );
  const verifyDateCreatedSection: boolean = VerifyIfComponentEnable(
    SecurityWrapperRoles.M_COMPLIANCE_FILTER_DATE_CREATED
  );

  const defaultCategoryItemsGeneral: ItemCategoryProps[] = [
    {
      isDisabled: !verifyFrequencySection,
      isMinimize: true,
      items: filterItemsGeneral.frequency!,
      onItemsSelect: () => {},
      placeHolder: FilterTitle.Frequency,
      selectType: "single",
      type: "byChips",
    },
    {
      isDisabled: !verifyStateSection,
      isMinimize: true,
      items: filterItemsGeneral.status!,
      onItemsSelect: () => {},
      placeHolder: FilterTitle.Status,
      selectType: "single",
      type: "byChips",
    },
  ];

  const defaultCategoryItems: ItemCategoryProps[] = [
    {
      isDisabled: !verifyFrequencySection,
      isMinimize: true,
      items: filterItems.frequency,
      onItemsSelect: () => {},
      placeHolder: FilterTitle.Frequency,
      selectType: "single",
      type: "byChips",
    },
    {
      isDisabled: !verifyStateSection,
      isMinimize: true,
      items: filterItems.status,
      onItemsSelect: () => {},
      placeHolder: FilterTitle.Status,
      selectType: "single",
      type: "byChips",
    },
    {
      isDisabled: !verifyConstitutionalCountrySection,
      isMinimize: true,
      items: filterItems.constitutionalCountry,
      onItemsSelect: () => {},
      placeHolder: FilterPlaceHolder.Constitutional_Country,
      selectType: "multiple",
      title: FilterTitle.Constitutional_Country,
      type: "bySelectCheck",
    },
    {
      isDisabled: !verifyCountrySection,
      isMinimize: true,
      items: filterItems.country,
      onItemsSelect: () => {},
      placeHolder: FilterTitle.Country,
      selectType: "single",
      type: "byChips",
    },
    {
      isDisabled: !verifyCategorySection,
      isMinimize: true,
      items: filterItems.category,
      onItemsSelect: () => {},
      placeHolder: FilterTitle.Category,
      selectType: "single",
      type: "byChips",
    },
    {
      isDisabled: !verifyDateCreatedSection,
      isMinimize: true,
      items: filterItems.created,
      onItemsSelect: () => {},
      placeHolder: FilterTitle.Created,
      selectType: "single",
      type: "byDate",
    },
  ];

  const [categoryItems, setCategoryItems] =
    useState<ItemCategoryProps[]>(defaultCategoryItems);

  useEffect(() => {
    setCategoryItems(defaultCategoryItems);
  }, [
    verifyFrequencySection,
    verifyStateSection,
    verifyConstitutionalCountrySection,
    verifyCountrySection,
    verifyCategorySection,
    verifyDateCreatedSection,
  ]);

  return {
    categoryItemsGeneral: defaultCategoryItemsGeneral,
    categoryItemsIndividual: categoryItems,
  };
};
