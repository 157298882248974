import { makeStyles } from "@material-ui/core/styles";

export const basicInformationStyles = makeStyles({
  paddingContent: {
    padding: "20px 22px",
  },
  titleContent: {
    color: "#023365",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    paddingBottom: "20px",
  },
});
