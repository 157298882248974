import React, { FC } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import DeleteFileIcon from "../../assets/images/deletefile.svg";
import FileIcon from "../../assets/images/file.svg";
import { paperFileUploadStyles } from "./PaperFileUpload.styles";

export interface IPaperFileUploadProps {
  name?: string;
  value?: string;
  size?: number;
  handleRemoveFile: (path: string, index: number) => void;
  index: number;
  type: string;
}

export const PaperFileUpload: FC<IPaperFileUploadProps> = (
  props: IPaperFileUploadProps
) => {
  return (
    <Grid container>
      <Box>
        <Grid container spacing={1} sx={paperFileUploadStyles.container}>
          <Grid item xs={11} sx={paperFileUploadStyles.grid}>
            <Paper sx={paperFileUploadStyles.containerPaper}>
              <Grid sx={paperFileUploadStyles.containerImg}>
                <img src={FileIcon} />
              </Grid>
              <Typography sx={paperFileUploadStyles.nameFile}>
                {props.name?.split(".")[0]}
                <strong>.{props.name?.split(".")[1]}</strong>
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={1} sx={paperFileUploadStyles.gridImg}>
            <img
              src={DeleteFileIcon}
              onClick={() => props.handleRemoveFile(props.type, props.index)}
              style={{ cursor: "pointer", paddingTop: "10px" }}
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
