import { createNamedStyles } from "../../../utils";

export const transactionsTableFooterStyles = createNamedStyles({
  container: {
    alignItems: "center",
    display: "flex",
    height: 53,
    justifyContent: "space-between",
    px: 2,
  },
});
