export const enum StatusEnum {
  REJECTED = "rejected",
  ENABLE = "enabled",
  DISABLE = "disable",
  PENDING = "pending",
  APPROVED = "approved",
  DRAFT = "draft",
}

export const STATUS_SPANISH: Record<string, string> = {
  [StatusEnum.REJECTED]: "rechazado",
  [StatusEnum.ENABLE]: "habilitado",
  [StatusEnum.DISABLE]: "deshabilitado",
  [StatusEnum.PENDING]: "pendiente",
  [StatusEnum.APPROVED]: "aprobado",
  [StatusEnum.DRAFT]: "borrador",
};
