export enum TitleAccordionEnum {
  SECTION_BRANCHES = "Listado de Branches",
  SECTION_PAYMENT_METHODS = "Medios de pago",
  SECTION_SERVICES = "Servicios",
  SECTION_BILLING_COLOMBIA = "Facturación, Cobros, Dispersión y Retenciones",
  SECTION_BILLING = "Facturación, Cobros y Dispersión",
  SECTION_RATES = "Tarifas",
  SECTION_DEFERRED = "Diferidos",
  MESSAGE_TOOLTIP = "Las acciones masivas están deshabilitadas para branches con estado (Pendiente)",
}
