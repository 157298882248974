import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const ratesInfoStyles = createNamedStyles({
  baseGrid: {
    display: "flex",
    gap: "18.8px",
  },
  contentGrid: {
    margin: 0,
    width: "100%",
  },
  collapseButtonText: {
    cursor: "pointer",
  },
  textPayment: {
    margin: 0,
  },
  collapseContainer: {
    maxWidth: "510px",
  },
  conditionsTextInfo: {
    color: "#293036",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "140%",
  },
  conditionsTextLight: {
    color: "#6D7781",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "144%",
  },
  conditionsCapitalica: {
    textTransform: "capitalize",
    color: "#293036",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "140%",
  },
  rangesLabelResp: {
    color: "#293036",
    fontSize: "14px",
    fontWeight: 500,
    fontStyle: "normal",
  },
  infoLegend: {
    color: "#46525C",
    fontSize: "13px",
    fontStyle: "normal",
    paddingTop: "10px",
    paddingRight: "18px",
    lineHeight: "15.6px",
  },
  rangesLabelSubTitle: {
    color: "#6D7781",
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "normal",
  },
  textTable: {
    alignItems: "center",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    gap: "10px",
    padding: "16px 8px 8px 16px",
  },
});

export const ratesRangeTableStyles = createNamedStyles({
  tableContainer: {
    backgroundColor: "#F7FAFC",
  },
  table: {
    borderBottom: "none",
    size: "small",
    maxWidth: "432px",
  },
  cellHeader: {
    borderBottom: "none",
    size: "small",
    height: 36,
    backgroundColor: "#F0F4F9",
  },
  labelSubTitle: {
    color: "#293036",
    fontSize: "14px",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "140%",
  },
  tableRow: {
    margin: 0,
    padding: 0,
  },
  cellRank: {
    backgroundColor: "#F7FAFC !important",
    borderBottom: "none",
    paddingBottom: 0,
    border: "none !important",
  },
  labelTableCell: {
    color: "#293036",
    fontSize: "14px",
    fontWeight: 400,
    paddingTop: "10px",
    lineHeight: "120%",
  },
});
