/* istanbul ignore file */

import { IOptionSelection } from "../interfaces/IOptionSelection";

export const BANKS_COSTA_RICA: IOptionSelection[] = [
  { value: "11100006", label: "BANCO DE COSTA RICA" },
  { value: "11100001", label: "BANCO BAC SAN JOSE" },
  { value: "11100002", label: "BANCO BCT S.A." },
  { value: "11100003", label: "BANCO CATHAY DE COSTA RICA S.A." },
  { value: "11100004", label: "BANCO CENTRAL DE COSTA RICA" },
  { value: "11100005", label: "BANCO CITIBANK DE COSTA RICA S.A." },
  { value: "11100007", label: "BANCO GENERAL DE COSTA RICA S.A." },
  { value: "11100008", label: "BANCO HSBC DE COSTA RICA S.A." },
  { value: "11100009", label: "BANCO IMPROSA S.A." },
  { value: "11100010", label: "BANCO LAFISE S.A." },
  { value: "11100011", label: "BANCO NACIONAL DE COSTA RICA" },
  { value: "11100012", label: "BANCO PROMERICA" },
  { value: "11100013", label: "SCOTIABANK DE COSTA RICA" },
  { value: "11100014", label: "COSTA RICA" },
  { value: "11100015", label: "BANCO INTERFIN DE COSTA RICA" },
  { value: "11100016", label: "BANCO INTERNACIONAL DE COSTA RICA" },
];
