import React from "react";
import {
  BackofficeTable,
  TableHeaders,
} from "@kushki/frontend-molecules/backoffice-table";
import { Grid, Paper, TableContainer } from "@material-ui/core";
import { TableEnum } from "../../../../shared/infrastructure/TableEnum";
import { TransactionSummaryData } from "../../../../../types/transaction_summary_data";
import { TransactionData } from "../../../../../types/transaction_data";
import { get } from "lodash";
import NumberFormat from "react-number-format";
import { TransactionTable } from "../../../../../types/transaction_table";
import { StatusFlowEnum } from "../../../../shared/infrastructure/StatusFlowEnum";
import {
  CountryEnum,
  CountryNameEnum,
} from "../../../../shared/infrastructure/CountryEnum";
import {
  ModalOverUtils,
  trxReconcileSummaryProps,
} from "../../../../containers/DepositDashboard/state/useDepositDashboardState";
import {
  ProcessorNameEnum,
  ProcessorTypeEnum,
} from "../../../../shared/infrastructure/ProcessorNameEnum";
import {
  PaymentMethodEnum,
  PaymentTypeEnum,
} from "../../../../shared/infrastructure/PaymentMethodEnum";
import {
  FiltersProps,
  TrxReconcileProps,
} from "../../../../containers/ConciliationDashboard/state/useConciliationDashboardState";
import { TransactionTableSummary } from "../../../../../types/transaction_table_summary";
import { PendingFiltersProps } from "../../../Filters/PendingFilters/PendingFilters";
import { OrderSortEnum } from "../../../../shared/infrastructure/constants/StatusFlowDashboardConstants";

import { DateRange } from "@material-ui/pickers";
import { CurrencyEnum } from "../../../../shared/infrastructure/CurrencyEnum";
import { PopoverFilter } from "../../../PopoverFilters/PopoverFilter";
import { DateRangePicker } from "../../../DateRange/DateRangePicker";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ButtonDownloadFile } from "../../../ButtonDownloadFile/ButtonDownloadFile";
import { ColumnsConfig } from "../../../ColumnsConfig/ColumnsConfig";
import { formatDate } from "../../../../shared/infrastructure/utils/date";
import { FormatDate } from "../../../../shared/infrastructure/constants/FormatDate";
import { STATUS_LABEL } from "../../../../shared/infrastructure/constants/BankConciliationConstans";
import { CloudSecurityEnum } from "../../../../shared/infrastructure/CloudSecurityEnum";

export interface IPagination {
  page: number;
  limit: number;
  trxPerPage: number;
  siblingCount: number;
  isMobile: boolean;
  size: TableEnum;
  handleChangePage: (
    _event: React.ChangeEvent<unknown> | undefined,
    value: number,
    trxData: (TransactionData | TransactionSummaryData)[]
  ) => void;
  handleChangeLimit: (newLimit: number) => void;
  text: string;
}

export interface DashboardTableProps {
  trxReconcile?: TrxReconcileProps;
  tab?: number;
  country: CountryEnum;
  statusFlow: StatusFlowEnum;
  isLoading: boolean;
  pagination: IPagination;
  check?: {
    selectedCheck: boolean;
    noSelectedCheck: boolean;
  };
  filterProps: FiltersProps;
  orderSort: {
    order: OrderSortEnum;
    orderBy: string;
    handleRequestSort: (
      _event: React.MouseEvent<unknown>,
      property: string
    ) => void;
  };
  handleCheckboxChange?: (rowValue: TransactionData, selected: boolean) => void;
  handleCheckboxChangeAll?: (value: TransactionData[]) => void;
  modalOver: ModalOverUtils;
  pendingFiltersProps?: PendingFiltersProps;
  handleDateRangeChange?: (dateRange: DateRange<Date>) => void;
  trxReconcileSummary?: trxReconcileSummaryProps;
  handleDownloadFile: () => void;
  loadingFileDownload?: boolean;
  titleNoData?: string;
  sbtNoData?: string;
}

export interface TableStateProps {
  conciliationData: TransactionTable | TransactionTableSummary;
}

export type TableComponentProps = DashboardTableProps & TableStateProps;
export const formatNumber = (value: number, currency: string): JSX.Element => {
  return (
    <NumberFormat
      value={value}
      thousandSeparator
      displayType={"text"}
      decimalScale={2}
      suffix={` ${currency} ${CurrencyEnum.MXN}`}
    />
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: "#F7FAFC",
    padding: "0 13px",
  },
  containerBar: {
    display: "flex",
    alignItems: "stretch",
  },
  column: { marginLeft: theme.spacing(2) },
  filter: { marginLeft: theme.spacing(1) },
  date: {
    marginLeft: theme.spacing(2),
  },
}));

export const SummaryHistoricTable = (
  props: TableComponentProps
): JSX.Element => {
  const classes = useStyles();
  const { pagination, orderSort } = props;

  const handleLimit = (newLimit: string): void => {
    pagination.handleChangeLimit(Number(newLimit));
  };
  const handlePage = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ): void => {
    pagination.handleChangePage(_event, value, props.conciliationData.data!);
  };

  const netAmountTotal = (trx: TransactionSummaryData): JSX.Element => {
    return formatNumber(Number(get(trx, "net_amount_total", 0)), "");
  };

  const currencyCode = (trx: TransactionSummaryData): JSX.Element => {
    return get(trx, "currency_code", "");
  };

  const bankConciliationStatus = (trx: TransactionData): string => {
    const status = get(trx, "bank_conciliation_status", "");
    return STATUS_LABEL[status] || status;
  };

  const mapDataConciliation = (
    trx: TransactionSummaryData | TransactionData,
    key: string
  ): JSX.Element => {
    let value: string = "";

    switch (key) {
      case "conciliation_created":
      case "bank_conciliation_date":
      case "transaction_created":
        value = formatDate(get(trx, key, "1"), FormatDate.DMY_slash);
        break;
      case "processor_name":
        value =
          ProcessorNameEnum[get(trx, key, ProcessorTypeEnum.KUSHKI_ACQUIRER)];
        break;
      case "payment_method":
        value = PaymentMethodEnum[get(trx, key, PaymentTypeEnum.CARD)];
        break;
      case "country":
        value = CountryNameEnum[get(trx, key, CountryEnum.mexico)];
        break;
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          {value}
        </Grid>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.containerBar}>
              <Grid item className={classes.filter}>
                <PopoverFilter
                  handleSetFilters={
                    props.pendingFiltersProps!.handleSetFilters!
                  }
                  counterFilter={props.pendingFiltersProps!.counterFilter!}
                  filters={props.pendingFiltersProps!.filters!}
                />
              </Grid>
              <Grid item className={classes.date}>
                <DateRangePicker
                  handleDate={props.pendingFiltersProps!.handleDateRangeChange}
                  disableFuture={true}
                  enableTimePicker={false}
                  value={props.pendingFiltersProps!.dateRange}
                  minDate={props.pendingFiltersProps!.minDate}
                />
              </Grid>
              <Grid item className={classes.column}>
                <ColumnsConfig origin={props.statusFlow} isHistoric />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <ButtonDownloadFile
            componentId={CloudSecurityEnum.HISTORIC_DOWNLOAD_CSV}
            downloadFile={props.handleDownloadFile}
            loading={props.loadingFileDownload!}
          />
        </Grid>
      </Grid>

      <TableContainer component={Paper} style={{ marginTop: "21px" }}>
        <BackofficeTable
          items={props.conciliationData.data! as TransactionSummaryData[]}
          headers={
            props.filterProps
              .filterHeaders!() as TableHeaders<TransactionSummaryData>[]
          }
          handleOnRowSelection={props.modalOver.handlerSelectTrxItem}
          skeleton={props.isLoading}
          sort={{
            order: orderSort.order,
            orderBy: orderSort.orderBy,
            handleRequestSort: orderSort.handleRequestSort,
          }}
          customRenderers={{
            country: (trx: TransactionSummaryData) =>
              mapDataConciliation(trx, "country"),
            paymentMethod: (trx: TransactionSummaryData) =>
              mapDataConciliation(trx, "payment_method"),
            processorName: (trx: TransactionSummaryData) =>
              mapDataConciliation(trx, "processor_name"),
            netAmountTotal,
            conciliationCreated: (trx: TransactionSummaryData) =>
              mapDataConciliation(trx, "conciliation_created"),
            currencyCode,
            bankConciliationStatus,
          }}
          emptyState={{
            title: get(props, "titleNoData", ""),
            subtitle: get(props, "sbtNoData", ""),
          }}
          pagination={{
            ...props.pagination,
            total: props.conciliationData.total!,
            handleChangeLimit: handleLimit,
            handleChangePage: handlePage,
          }}
        />
      </TableContainer>
    </React.Fragment>
  );
};
