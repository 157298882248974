import { Box, Button } from "@mui/material";
import React, { FC } from "react";
import { footerStyles as styles } from "./FooterOptions.styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { IFooterOptions } from "./FooterOptions.interfaces";

const FooterOptions: FC<IFooterOptions> = ({
  primaryButton,
  secondaryButton,
  open = true,
}: IFooterOptions) => {
  if (!open) return null;

  return (
    <Box sx={styles.footer}>
      <Box sx={styles.buttonContainer}>
        <Button
          data-testid="secondary-button"
          variant="outlined"
          color="secondary"
          sx={styles.saveButton}
          disabled={secondaryButton.isDisabled}
          onClick={secondaryButton.onAction}
        >
          {secondaryButton.text}
        </Button>
        {!primaryButton.isHidden && (
          <Button
            data-testid="primary-button"
            variant="contained"
            disabled={primaryButton.isDisabled}
            onClick={primaryButton.onAction}
            sx={styles.continueButton}
            endIcon={<ArrowForwardIcon />}
          >
            {primaryButton.text}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default FooterOptions;
