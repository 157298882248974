import { useEffect, useRef, useState } from "react";
import {
  IUseHeaderAlarmsTable,
  IUseHeaderAlarmsTablePros,
} from "@components/HeaderAlarmsTable/state/useHeaderAlarmsTable.interfaces";
import {
  containsOnlyNumbers,
  deleteEmptyProperties,
  formatDateToTimestamp,
  getValuesAlarmFilters,
} from "@shared/utils/filterUtils";
import { defaultTo, get, isEmpty } from "lodash";
import { useAppDispatch, useAppSelector } from "@store/hooks/storeHook";
import {
  getMerchantsCore,
  ISearchMerchantCoreRequestThunk,
} from "@store/thunks/general/general.thunk";
import { AppDispatch, RootState } from "@store/store";
import { TextFieldSearchByItem } from "@kushki/connect-ui/dist/Components/Molecules/Form/TextFieldSearchBy/TextFieldSearchBy.interfaces";
import { Merchant } from "../../../../types/search_merchants_response";
import { ReduxNameVariablesEnum } from "@shared/enum/ReduxNameVariablesEnum";
import { ItemCategoryProps } from "@kushki/connect-ui";
import { EntityNameBySource } from "@shared/catalogs/EntityNameBySource";
import {
  builderFilterNode,
  getIdByEntity,
} from "@shared/utils/filtersAlarmUtils";
import { IFilterAlarm } from "../../../../types/search_alarm_by_filter_request";
import { setDataSearch } from "@store/reducers/general/general.slice";

export const useHeaderAlarmsTable = ({
  onChangeFilter,
  pathParamType,
}: IUseHeaderAlarmsTablePros): IUseHeaderAlarmsTable => {
  let typingTimeout: NodeJS.Timeout | null = null;
  const dispatch: AppDispatch = useAppDispatch();
  const { dataSearchMerchant, timezoneProps } = useAppSelector(
    (state: RootState) => ({
      ...state.general,
    })
  );
  const [filtersRequestState, setFiltersRequestState] = useState<IFilterAlarm>({
    category: [],
    country: [],
    customerId: [],
    merchantId: [],
    ownerId: [],
    status: [],
    variables: [],
  });
  const [itemsSearchBy, setItemsSearchBy] = useState<TextFieldSearchByItem[]>(
    []
  );

  function buildItemsSearchBy(merchants: Merchant[]) {
    const items: TextFieldSearchByItem[] = merchants.reduce(
      (acc: TextFieldSearchByItem[], merchant: Merchant) => {
        const id: string = getIdByEntity(merchant);

        acc.push({
          id,
          secondaryText: get(merchant, "publicMerchantId", id),
          text: defaultTo(get(merchant, "name"), ""),
        });

        return acc;
      },
      []
    );

    setItemsSearchBy(items);
  }

  const handleInputChangeSearchBy = (value: unknown) => {
    const text_input: string = get(value, "target.value", "");
    const request: ISearchMerchantCoreRequestThunk = {
      body: {
        filter: {
          ...(containsOnlyNumbers(text_input)
            ? { merchantId: [text_input] }
            : { name: text_input }),
          entityName: EntityNameBySource[pathParamType],
        },
        limit: 10,
        offset: 1,
      },
      nameVariable: ReduxNameVariablesEnum.dataSearchMerchant,
    };

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    typingTimeout = setTimeout(() => {
      dispatch(getMerchantsCore(request));
    }, 700);
  };

  const handleSelectedSearchBy = (selected: TextFieldSearchByItem) => {
    const id: string = get(selected, "id", "");
    const filters: IFilterAlarm = {
      ...filtersRequestState,
      ...builderFilterNode(get(dataSearchMerchant, "data", []), id),
    };

    setFiltersRequestState(filters);
  };

  useEffect(() => {
    if (!isEmpty(dataSearchMerchant)) {
      buildItemsSearchBy(get(dataSearchMerchant, "data", []));
    }
  }, [dataSearchMerchant]);

  const handleApplyFilters = (selected: ItemCategoryProps[]) => {
    setFiltersRequestState((prevState) => ({
      ...prevState,
      ...getValuesAlarmFilters(selected),
    }));
  };

  const filterRef = useRef(true);

  useEffect(() => {
    if (filterRef.current) {
      filterRef.current = false;

      return;
    }

    onChangeFilter({
      ...deleteEmptyProperties(filtersRequestState),
      timeZone: get(timezoneProps, "utcTimeZone"),
    } as IFilterAlarm);
  }, [filtersRequestState]);

  const handleSearchDate = (startDate: string, finishDate: string) => {
    setFiltersRequestState((prevState) => ({
      ...prevState,
      from: formatDateToTimestamp(startDate, false),
      to: formatDateToTimestamp(finishDate, true),
    }));
  };

  useEffect(() => {
    dispatch(setDataSearch({ data: [], total: 0 }));
  }, [pathParamType]);

  return {
    handleApplyFilters,
    handleSearchDate,
    propsFilterSearch: {
      handleInputChangeSearchBy,
      handleSelectedSearchBy,
      itemsSearchBy,
    },
  };
};
