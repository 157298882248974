import { walletTransactionSlice } from "../reducers/walletTransaction/walletTransaction.slice";

export const {
  setPage,
  setLimit,
  setOffset,
  setTrxRequest,
  setShowDetailModal,
  setCurrentTransaction,
} = walletTransactionSlice.actions;
