import { IStyles } from "../../../shared/infrastructure/interfaces/Styles.interfaces";

export const SkipTransactionsStyles: IStyles = {
  cancelButton: {
    color: "text.dark",
    borderRadius: "4px",
    backgroundColor: "#ffffff",
    fontWeight: "500",
    "&:hover": {
      backgroundColor: "#ffffff",
      color: "text.dark",
    },
  },
  closeButton: {
    position: "absolute",
    right: 15,
    top: 12,
  },
  dialogActions: {
    padding: "24px",
  },
  dialogContent: {
    paddingY: 0,
  },
  dialogTitle: {
    paddingBottom: 0,
    paddingTop: "24px",
  },
  label: {
    color: "text.dark",
    fontSize: "15px",
    fontWeight: "400",
  },
  paperProps: {
    backgroundColor: "#FFFFFF",
    width: "33%",
  },
  reasonContainer: {
    marginTop: "16px",
  },
  reasonInput: {
    color: "text.dark",
    fontSize: "16px",
    padding: "18px",
  },
  skipButton: {
    backgroundColor: "primary.main",
    borderColor: "primary.main",
    borderRadius: "4px",
    color: "#ffffff",
    fontSize: "14px",
    height: "36px",
    "&:hover": {
      backgroundColor: "primary.main",
      borderColor: "primary.main",
    },
    "&:disabled": {
      backgroundColor: "primary.main",
      borderColor: "primary.main",
      opacity: 0.5,
    },
  },
  subtitle: {
    color: "text.dark",
    fontWeight: 500,
    marginY: "24px",
  },
  title: {
    color: "primary.main",
    fontSize: "20px",
    fontWeight: 500,
  },
};
