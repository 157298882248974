import { RoleNameEnum } from "../enum/roleNameEnum";

export const ROLES_NAMES: KindRole[] = [
  {
    id: RoleNameEnum.MERCHANT_BACKOFFICE,
    value: "Roles de usuario",
  },
  { id: RoleNameEnum.KUSHKI_BACKOFFICE, value: "Roles de usuario (Kushki)" },
  { id: RoleNameEnum.SNR, value: "Usuarios B2C" },
  {
    id: RoleNameEnum.CORE_ACCOUNTING,
    value: "Roles de Usuario (Core Accounting)",
  },
  { id: RoleNameEnum.FIN_BACKOFFICE, value: "Roles de Usuario (Contabilidad)" },
];
export type KindRole = {
  id: string;
  value: string;
  parentMerchantEnable?: boolean;
};

export type Role = {
  id: string;
  value: string;
  parentMerchantEnable?: boolean;
  kind: string;
};

export enum RolesId {
  WebcheckoutBasic = "WebcheckoutBasic",
  VirtualPOS = "VirtualPOS",
  BackofficeUserReader = "BackofficeUserReader",
  WebcheckoutMaster = "WebcheckoutMaster",
  BackofficeUserMaker = "BackofficeUserMaker",
  SnrCoordinator = "SnrCoordinator",
  BackofficeAdmin = "BackofficeAdmin",
  RiskManager = "RiskManager",
  BackofficeFinValidator = "BackofficeFinValidator",
  BackofficeFinExecutor = "BackofficeFinExecutor",
  BackofficeUserMaster = "BackofficeUserMaster",
  ExpressCheckoutMaster = "ExpressCheckoutMaster",
  ChargebackManager = "ChargebackManager",
}

export const ROLES_MERCHANT_BACKOFFICE: Role[] = [
  {
    id: "BackofficeUserMaster",
    kind: RoleNameEnum.MERCHANT_BACKOFFICE,
    value: "Usuario Maestro",
  },
  {
    id: RolesId.VirtualPOS,
    kind: RoleNameEnum.MERCHANT_BACKOFFICE,
    value: "Virtual POS",
  },
  {
    id: RolesId.BackofficeUserMaker,
    kind: RoleNameEnum.MERCHANT_BACKOFFICE,
    parentMerchantEnable: true,
    value: "Creador de usuarios",
  },
  {
    id: "BackofficeMasterCredentials",
    kind: RoleNameEnum.MERCHANT_BACKOFFICE,
    value: "Maestro de Credenciales",
  },
  {
    id: RolesId.WebcheckoutMaster,
    kind: RoleNameEnum.MERCHANT_BACKOFFICE,
    value: "Maestro Smartlink",
  },
  {
    id: "BackofficeMasterCustomer",
    kind: RoleNameEnum.MERCHANT_BACKOFFICE,
    parentMerchantEnable: true,
    value: "Maestro Customer",
  },
  {
    id: RolesId.BackofficeUserReader,
    kind: RoleNameEnum.MERCHANT_BACKOFFICE,
    parentMerchantEnable: true,
    value: "Usuario de lectura",
  },
  {
    id: RolesId.WebcheckoutBasic,
    kind: RoleNameEnum.MERCHANT_BACKOFFICE,
    value: "Smartlink",
  },
  {
    id: RolesId.ExpressCheckoutMaster,
    kind: RoleNameEnum.MERCHANT_BACKOFFICE,
    value: "Express Checkout",
  },
  {
    id: RolesId.ChargebackManager,
    kind: RoleNameEnum.MERCHANT_BACKOFFICE,
    value: "Gestor de Contracargos",
  },
];

export const ROLES_KUSHKI_BACKOFFICE: Role[] = [
  {
    id: "BackofficeAdmin",
    kind: RoleNameEnum.KUSHKI_BACKOFFICE,
    value: "Administrador",
  },
  {
    id: "BackofficeBank",
    kind: RoleNameEnum.KUSHKI_BACKOFFICE,
    value: "Usuario Banco",
  },
  {
    id: "MguayaquilReviewer",
    kind: RoleNameEnum.KUSHKI_BACKOFFICE,
    value: "Revisor Guayaquil",
  },
  {
    id: "BackofficeCompliance",
    kind: RoleNameEnum.KUSHKI_BACKOFFICE,
    value: "Operador de Cumplimiento",
  },
  {
    id: "BackofficeGlobalReports",
    kind: RoleNameEnum.KUSHKI_BACKOFFICE,
    value: "Usuario Global",
  },
  {
    id: "BackofficeMonitoring",
    kind: RoleNameEnum.KUSHKI_BACKOFFICE,
    value: "Monitoreo",
  },
  // { id: "BackofficeFinValidator", value: "Aprobador (Liquidaciones)" },
  // { id: "BackofficeFinExecutor", value: "Ejecutador (Liquidaciones)" },
  {
    id: RolesId.RiskManager,
    kind: RoleNameEnum.KUSHKI_BACKOFFICE,
    value: "Administrador de Riesgos",
  },
  {
    id: "RiskAnalyst",
    kind: RoleNameEnum.KUSHKI_BACKOFFICE,
    value: "Analista de Riesgos",
  },
  {
    id: RolesId.BackofficeFinValidator,
    kind: RoleNameEnum.KUSHKI_BACKOFFICE,
    value: "Aprobador Contabilidad",
  },
  {
    id: RolesId.BackofficeFinExecutor,
    kind: RoleNameEnum.KUSHKI_BACKOFFICE,
    value: "Ejecutor Contabilidad",
  },
  {
    id: "BackofficeConciliationExecutor",
    kind: RoleNameEnum.KUSHKI_BACKOFFICE,
    value: "Ejecutador (Conciliación)",
  },
  {
    id: "BackofficeConciliationValidator",
    kind: RoleNameEnum.KUSHKI_BACKOFFICE,
    value: "Validador  (Conciliación)",
  },
  {
    id: "FinOpsBackOffice",
    kind: RoleNameEnum.KUSHKI_BACKOFFICE,
    value: "FinOps",
  },
  {
    id: "TreasuryBackOffice",
    kind: RoleNameEnum.KUSHKI_BACKOFFICE,
    value: "Tesorería",
  },
  {
    id: "BackOfficeRefundExecutor",
    kind: RoleNameEnum.KUSHKI_BACKOFFICE,
    value: "Ejecutor Reembolsos",
  },
  {
    id: "BackOfficeRefundApprover",
    kind: RoleNameEnum.KUSHKI_BACKOFFICE,
    value: "Aprobador Reembolsos",
  },
];

export const ROLES_SNR: Role[] = [
  { id: "B2CCoordinator", kind: RoleNameEnum.SNR, value: "Coordinador B2C" },
  { id: "B2CAgent", kind: RoleNameEnum.SNR, value: "Agente B2C" },
];

export const ROLES_CORE_ACCOUNTING: Role[] = [
  {
    id: "BackofficeCoreAccountingExecutor",
    kind: RoleNameEnum.CORE_ACCOUNTING,
    value: "Ejecutor Core Accounting",
  },
  {
    id: "BackofficeCoreAccountingValidator",
    kind: RoleNameEnum.CORE_ACCOUNTING,
    value: "Aprobador Core Accounting",
  },
  {
    id: "BackofficeCoreAccountingAnalyst",
    kind: RoleNameEnum.CORE_ACCOUNTING,
    value: "Analista Conciliación Transaccional",
  },
  {
    id: "BackofficeCoreAccountingBoss",
    kind: RoleNameEnum.CORE_ACCOUNTING,
    value: "Jefe Conciliación Transaccional",
  },
];

export const ROLES_FIN: Role[] = [
  {
    id: RolesId.BackofficeFinValidator,
    kind: RoleNameEnum.FIN_BACKOFFICE,
    value: "Aprobador Contabilidad",
  },
  {
    id: RolesId.BackofficeFinExecutor,
    kind: RoleNameEnum.FIN_BACKOFFICE,
    value: "Ejecutador Contabilidad",
  },
];

export const ROLES_NO_ADMIN: string[] = [
  RolesId.WebcheckoutBasic,
  RolesId.VirtualPOS,
  RolesId.BackofficeUserReader,
  RolesId.WebcheckoutMaster,
  RolesId.BackofficeUserMaker,
  RolesId.ExpressCheckoutMaster,
  RolesId.ChargebackManager,
];
export const isRoleMerchant = (role: string): boolean => {
  const rolesMerchant = ROLES_MERCHANT_BACKOFFICE;
  const item = rolesMerchant.find((item) => item.id === role);

  return Boolean(item);
};
export const isRoleKushki = (role: string): boolean => {
  const item = ROLES_KUSHKI_BACKOFFICE.find((item) => item.id === role);

  return Boolean(item);
};

export const isRoleSNR = (role: string): boolean => {
  const item = ROLES_SNR.find((item) => item.id === role);

  return Boolean(item);
};

export const isRoleFIN = (role: string): boolean => {
  const item = ROLES_FIN.find((item) => item.id === role);

  return Boolean(item);
};

export const isRoleCoreAccounting = (role: string): boolean => {
  const item = ROLES_CORE_ACCOUNTING.find((item) => item.id === role);

  return Boolean(item);
};

export const ROLES: Role[] = [
  ...ROLES_MERCHANT_BACKOFFICE,
  ...ROLES_KUSHKI_BACKOFFICE,
  ...ROLES_SNR,
  ...ROLES_CORE_ACCOUNTING,
  ...ROLES_FIN,
];

export const ROLES_WITHOUT_REPEATS: Role[] = ROLES.filter((item: Role) => {
  const findRole: boolean = [
    RolesId.BackofficeFinValidator,
    RolesId.BackofficeFinExecutor,
  ].includes(item.id as RolesId);

  const findKind: boolean = [RoleNameEnum.KUSHKI_BACKOFFICE].includes(
    item.kind as RoleNameEnum
  );

  return !(findRole && findKind);
});
