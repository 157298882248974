import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";

export const useCreditNoteStyles = makeStyles(() => ({
  root: {
    padding: "1.5rem",
    paddingBottom: "1rem",
  },
  cancelButton: {
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  annulButton: {
    color: theme.palette.error.dark,
  },
  fileMessage: {
    color: "#6D7781",
    fontSize: "12px",
  },
  messageText: {
    marginLeft: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: "10px",
    top: "5px",
  },
  divider: {
    margin: "32px 0",
  },
  uploadFileContainer: {
    marginTop: theme.spacing(3),
  },
  sizeMessage: {
    fontStyle: "italic",
    fontWeight: "lighter",
  },
  textFieldMultiLine: {
    marginTop: theme.spacing(3),
    "& .MuiInputBase-root": { height: "inherit", minWidth: "20rem" },
  },
  infoIcon: {
    flexGrow: 0.08,
  },
  fileSelected: {
    padding: 5,
    backgroundColor: theme.palette.success.light,
    borderRadius: "4px;",
    minWidth: "290px",
    "& svg": {
      float: "right",
    },
  },
  bold: {
    fontWeight: "bolder",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
  },
  primaryLight: {
    color: theme.palette.primary.light,
  },
  primaryDark: {
    color: theme.palette.primary.dark,
  },
}));
