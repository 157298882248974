import React from "react";
import { ITemCardProps } from "./ItemCard.interfaces";
import { Card, CardHeader } from "@mui/material";
import { FileIcon } from "../../assets/Icons/FileIcon";
import { ItemCardStyles } from "./ItemCard.styles";

export const ItemCard = ({ itemName }: ITemCardProps) => {
  return (
    <Card sx={ItemCardStyles.cardContainer}>
      <CardHeader
        sx={ItemCardStyles.cardHeader}
        avatar={<FileIcon />}
        title={itemName}
      />
    </Card>
  );
};
