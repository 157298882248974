import { IUseDownloadTemplateDispersionState } from "../../../shared/infrastructure/interfaces/useDownloadTemplateDispersionState.interface";
import { useDispatch } from "react-redux";
import { downloadFiles } from "../../../store/actionCreators";
import { FileTypeEnum } from "../../../shared/infrastructure/constants/FileTypeEnum";

export const useDownloadTemplateDispersionState =
  (): IUseDownloadTemplateDispersionState => {
    const dispatch = useDispatch();

    const handleInputFile = (): void => {
      dispatch(downloadFiles(FileTypeEnum.INPUT));
    };

    const handleUafFile = (): void => {
      dispatch(downloadFiles(FileTypeEnum.UAF));
    };

    return {
      handleInputFile,
      handleUafFile,
    };
  };
