import { CountryEnum } from "../enum/CountryEnum";

export enum FILTER_GROUPS {
  STATE = "Estado",
}
export enum STATE_LABEL_ITEM {
  COMPLETE = "Completado",
  PENDING = "Pendiente",
}

export const STATE_ITEM_VALUES: Record<STATE_LABEL_ITEM, string> = {
  [STATE_LABEL_ITEM.COMPLETE]: "complete",
  [STATE_LABEL_ITEM.PENDING]: "pending",
};

export const OPERATIONAL_COUNTRY_LABELS: Partial<Record<CountryEnum, string>> =
  {
    [CountryEnum.generic]: CountryEnum.generic,
    [CountryEnum.mexico]: "México",
    [CountryEnum.peru]: "Perú",
    [CountryEnum.chile]: "Chile",
    [CountryEnum.colombia]: "Colombia",
    [CountryEnum.el_salvador]: "El Salvador",
    [CountryEnum.nicaragua]: "Nicaragua",
    [CountryEnum.panama]: "Panamá",
    [CountryEnum.ecuador]: "Ecuador",
    [CountryEnum.costa_rica]: "Costa Rica",
    [CountryEnum.guatemala]: "Guatemala",
    [CountryEnum.honduras]: "Honduras",
    [CountryEnum.usa]: "Estados Unidos",
    [CountryEnum.brasil]: "Brasil",
  };
