/* istanbul ignore file */

import { IOptionSelection } from "../interfaces/IOptionSelection";

export const BANKS_MEXICO: IOptionSelection[] = [
  { value: "37006", label: "BANCOMEXT" },
  { value: "37009", label: "BANOBRAS" },
  { value: "37019", label: "BANJERCITO" },
  { value: "37135", label: "NAFIN" },
  { value: "37166", label: "BANSEFI" },
  { value: "37168", label: "HIPOTECARIA FED" },
  { value: "40002", label: "BANAMEX" },
  { value: "40003", label: "CITIBANAMEX" },
  { value: "40012", label: "BBVA BANCOMER" },
  { value: "40013", label: "BBVA" },
  { value: "40014", label: "SANTANDER" },
  { value: "40021", label: "HSBC" },
  { value: "40030", label: "BAJIO" },
  { value: "40036", label: "INBURSA" },
  { value: "40037", label: "INTERACCIONES" },
  { value: "40042", label: "MIFEL" },
  { value: "40044", label: "SCOTIABANK" },
  { value: "40058", label: "BANREGIO" },
  { value: "40059", label: "INVEX" },
  { value: "40060", label: "BANSI" },
  { value: "40062", label: "AFIRME" },
  { value: "40072", label: "BANORTE/IXE" },
  { value: "40102", label: "INVESTA BANK" },
  { value: "40103", label: "AMERICAN EXPRES" },
  { value: "40106", label: "BAMSA" },
  { value: "40108", label: "TOKYO" },
  { value: "40110", label: "JP MORGAN" },
  { value: "40112", label: "BMONEX" },
  { value: "40113", label: "VE POR MAS" },
  { value: "40124", label: "DEUTSCHE" },
  { value: "40126", label: "CREDIT SUISSE" },
  { value: "40127", label: "AZTECA" },
  { value: "40128", label: "AUTOFIN" },
  { value: "40129", label: "BARCLAYS" },
  { value: "40130", label: "COMPARTAMOS" },
  { value: "40131", label: "BANCO FAMSA" },
  { value: "40132", label: "MULTIVA BANCO" },
  { value: "40133", label: "ACTINVER" },
  { value: "40136", label: "INTERCAM BANCO" },
  { value: "40137", label: "BANCOPPEL" },
  { value: "40138", label: "ABC CAPITAL" },
  { value: "40140", label: "CONSUBANCO" },
  { value: "40141", label: "VOLKSWAGEN" },
  { value: "40143", label: "CIBANCO" },
  { value: "40145", label: "BBASE" },
  { value: "40147", label: "BANKAOOL" },
  { value: "40148", label: "PAGATODO" },
  { value: "40150", label: "INMOBILIARIO" },
  { value: "40151", label: "DONDE" },
  { value: "40152", label: "BANCREA" },
  { value: "40154", label: "BANCO FINTERRA" },
  { value: "40155", label: "ICBC" },
  { value: "40156", label: "SABADELL" },
  { value: "40158", label: "MIZUHO BANK" },
  { value: "90600", label: "MONEXCB" },
  { value: "90601", label: "GBM" },
  { value: "90602", label: "MASARI" },
  { value: "90605", label: "VALUE" },
  { value: "90606", label: "ESTRUCTURADORES" },
  { value: "90608", label: "VECTOR" },
  { value: "90613", label: "MULTIVA CBOLSA" },
  { value: "90614", label: "ACCIVAL" },
  { value: "90616", label: "FIlabelX" },
  { value: "90617", label: "VALMEX" },
  { value: "90620", label: "PROFUTURO" },
  { value: "90621", label: "CB ACTINVER" },
  { value: "90623", label: "SKANDIA" },
  { value: "90626", label: "CBDEUTSCHE" },
  { value: "90627", label: "ZURICH" },
  { value: "90628", label: "ZURICHVI" },
  { value: "90630", label: "CB INTERCAM" },
  { value: "90631", label: "CI BOLSA" },
  { value: "90634", label: "FINCOMUN" },
  { value: "90636", label: "HDI SEGUROS" },
  { value: "90637", label: "ORDER" },
  { value: "90638", label: "AKALA" },
  { value: "90640", label: "CB JPMORGAN" },
  { value: "90642", label: "REFORMA" },
  { value: "90646", label: "STP" },
  { value: "90648", label: "EVERCORE" },
  { value: "90649", label: "OSKNDIA" },
  { value: "90652", label: "ASEA" },
  { value: "90653", label: "KUSPIT" },
  { value: "90655", label: "SOFIEXPRESS" },
  { value: "90656", label: "UNAGRA" },
  { value: "90659", label: "ASP INTEGRA OPC" },
  { value: "90670", label: "LIBERTAD" },
  { value: "90671", label: "HUASTECAS" },
  { value: "90674", label: "AXA" },
  { value: "90677", label: "CAJA POP MEXICA" },
  { value: "90678", label: "SURA" },
  { value: "90679", label: "FND" },
  { value: "90680", label: "CRISTOBAL COLON" },
  { value: "90681", label: "PRINCIPAL" },
  { value: "90683", label: "CAJA TELEFONIST" },
  { value: "90684", label: "TRANSFER" },
  { value: "90685", label: "FONDO (FIRA)" },
  { value: "90686", label: "INVERCAP" },
  { value: "90687", label: "INFONAVIT" },
  { value: "90689", label: "FOMPED" },
  { value: "90901", label: "CLS" },
  { value: "90902", label: "INDEVAL" },
];
