export enum CountriesEnum {
  ECUADOR = "Ecuador",
  PERU = "Peru",
  COLOMBIA = "Colombia",
  CHILE = "Chile",
  MEXICO = "Mexico",
  PANAMA = "Panama",
  COSTA_RICA = "CostaRica",
  EL_SALVADOR = "ElSalvador",
  GUATEMALA = "Guatemala",
  BRAZIL = "Brazil",
}

export const TimeZoneByCountry: Record<string, string> = {
  [CountriesEnum.BRAZIL]: "America/Sao_Paulo",
};
