import { IUseCreateBusinessRuleState } from "../../../shared/infrastructure/interfaces/IUseCreateBusinessRuleState";
import { useFieldArray, useForm, UseFormMethods } from "react-hook-form";
import { CreateRuleRequest } from "../../../../types/create_rule_request";
import {
  BusinessRuleStatus,
  getAliasTransactionRule,
} from "../../../shared/infrastructure/constants/BusinessRuleConstants";
import { IConditionItem } from "../../../shared/infrastructure/interfaces/IConditionItem";
import { get, set, defaultTo, isEmpty } from "lodash";
import {
  LabelEnum,
  ModalSimpleData,
} from "../../../shared/infrastructure/enums/LabelEnum";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../store/hooks/store-hook";
import {
  createRule,
  getActiveProcessors,
  getAtomicId,
  getBusinessRules,
  getConfiguration,
  getCountries,
  getCustomerCredentials,
  getGeographyList,
  getGetRuleById,
  getNodeInfo,
  getParentMerchantId,
  getProcessors,
  getRule,
  getSuggestCredentials,
  IAppAction,
  setLoadingModal,
  setPrefixAtomicCounter,
  setSuccessEnableRule,
  updateRule,
} from "../../../store/actionCreators";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  fromRuleKeyToTitleMap,
  RuleEnum,
} from "../../../shared/infrastructure/enums/RuleEnum";
import {
  GetBusinessRulesResponse,
  Rule,
  RuleItem,
} from "../../../../types/get_business_rules_response";
import { setSaveRuleModal } from "../../../store/actionCreators";

import {
  BUSINESS_RULE_INITIAL_STATE,
  DEFAULT_LOADING_MODAL,
  DEFAULT_SIMPLE_MODAL,
} from "../../../store/defaultStates";
import { FormFieldEnum } from "../../../shared/infrastructure/enums/FormFieldEnum";
import {
  enableEditRule,
  ruleAdded,
  saveEditDraftRule,
  saveRepeatedRule,
} from "../../../shared/infrastructure/constants/SaveRuleModal";
import { IRepeatedConditions } from "../../../shared/infrastructure/interfaces/IRepeatedConditions";
import { deleteDuplicatedElements } from "../../../utils/utilsFile";
import { setConditionsFromRule } from "../../../shared/utils/form-utils";
import {
  getBasicMerchantInformation,
  getBusinessRulesStorage,
  getCustomerBatchInformation,
  getIsMassiveRules,
  getMerchantInfo,
} from "../../../shared/utils/localStorage";
import { EntityNameEnum } from "../../../shared/infrastructure/enums/NodeInfoEnum";
import { LocalStoragePathEnum } from "../../../shared/infrastructure/enums/LocalStoragePathEnum";
import { environment } from "../../../environments/environment";
import { existPathBusinessRuleList } from "../../../shared/utils/utilsFile";
import { LoadingModalMessages } from "../../../shared/infrastructure/enums/LoadingModalMessages";
import { BusinessRule } from "../../../../types/get_rule_response";
import { ThunkAction } from "redux-thunk";
import { IAppState } from "../../../shared/infrastructure/interfaces/IAppState";
import { TRANSACTION_TYPE_MAPPING_CREATION } from "../../../shared/infrastructure/enums/ItemByTagEnum";

const genericSearchTerm: string = "ssd";

export const useCreateBusinessRuleIndexState =
  (): IUseCreateBusinessRuleState => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { redirectPath } = getBasicMerchantInformation();
    const {
      getRuleById,
      processors,
      businessRules,
      saveRuleModal,
      configList,
      loadingModal,
      nodeInfoMerchant,
      parentMerchantId,
      atomicId,
      prefixAtomicCounter,
      successEnableRule,
    } = useAppSelector((store) => store.layout);
    const { ruleId } = useParams();
    const { pathname } = useLocation();
    const [newRule, setNewRule] = useState({ ...BUSINESS_RULE_INITIAL_STATE });
    const [isCreate, setIsCreate] = useState<boolean>(false);
    const [searchTextProcessor, setSearchTextProcessor] = useState<string>("");

    const { publicMerchantId } = getBasicMerchantInformation();
    const merchantInfo = getMerchantInfo();

    const merchantStorageId: string = ((
      publicMerchantId: string,
      merchantInfoId: string
    ) => {
      if (!isEmpty(publicMerchantId)) {
        return publicMerchantId;
      }
      if (!isEmpty(merchantInfoId)) {
        return merchantInfoId;
      }
      return "";
    })(publicMerchantId, merchantInfo.publicMerchantId);

    const form: UseFormMethods<CreateRuleRequest> = useForm<CreateRuleRequest>({
      mode: "all",
      defaultValues: {
        ...BUSINESS_RULE_INITIAL_STATE,
      },
      shouldUnregister: false,
    });

    const { fields, remove, prepend, insert } = useFieldArray({
      control: form.control,
      name: FormFieldEnum.CONDITION,
    });

    const customerBatchInformation = getCustomerBatchInformation();

    const storageMerchantId: string = ((
      merchantInfoId: string,
      customerBatchInformationId: string
    ) => {
      if (!isEmpty(merchantInfoId)) {
        return merchantInfoId;
      }
      if (!isEmpty(customerBatchInformationId)) {
        return customerBatchInformationId;
      }
      return "";
    })(
      merchantInfo.publicMerchantId,
      customerBatchInformation.publicMerchantId
    );

    const handleEnterSearchTextProcessors = (key: string): void => {
      if (key !== "Enter") return;
      dispatch(
        getActiveProcessors(
          parentMerchantId || storageMerchantId,
          searchTextProcessor
        )
      );
      setSearchTextProcessor("");
    };

    const handleSetSearchTextProcessors = (searchText: string): void => {
      setSearchTextProcessor(searchText);
    };

    const closeModal = (
      pendingRequests: ThunkAction<void, IAppState, undefined, IAppAction>[]
    ): void => {
      Promise.allSettled(pendingRequests).then(() => {
        dispatch(setLoadingModal(DEFAULT_LOADING_MODAL));
      });
    };

    useEffect(() => {
      const basicMerchantInformation = JSON.parse(
        defaultTo(localStorage.getItem("basicMerchantInformation"), "{}")
      );
      if (
        basicMerchantInformation &&
        basicMerchantInformation.isCentralized &&
        basicMerchantInformation.entityName === EntityNameEnum.BRANCH &&
        parentMerchantId !== ""
      ) {
        dispatch(getProcessors(parentMerchantId || "", true));
      }
    });

    useEffect(() => {
      if (!isEmpty(parentMerchantId)) {
        dispatch(getActiveProcessors(parentMerchantId || ""));
        return;
      }
      dispatch(getActiveProcessors(storageMerchantId));
    }, [parentMerchantId]);

    useEffect(() => {
      if (successEnableRule) {
        if (isEmpty(redirectPath)) {
          navigate(-1);
        }
        navigate(redirectPath);
      }
      setSuccessEnableRule(false);
    }, [successEnableRule]);

    useEffect(() => {
      let strAtomicId = "";
      if (atomicId) {
        strAtomicId = strAtomicId + atomicId;
        const prefixAlias = "EM-" + strAtomicId.padStart(3, "0");
        dispatch(setPrefixAtomicCounter(prefixAlias));
      }
    }, [atomicId]);

    const aliasTransactionRule = getAliasTransactionRule(environment.envName);

    useEffect(() => {
      const pendingRequests: ThunkAction<
        void,
        IAppState,
        undefined,
        IAppAction
      >[] = [];
      const isMassiveRules = getIsMassiveRules();
      if (isMassiveRules) {
        dispatch(getAtomicId(aliasTransactionRule));
        const merchantIdStorage =
          getBasicMerchantInformation().publicMerchantId;
        if (!isEmpty(merchantIdStorage)) {
          dispatch(getParentMerchantId(merchantIdStorage));
          return;
        }
        dispatch(getParentMerchantId(storageMerchantId));
      }

      pendingRequests.push(
        dispatch(
          getBusinessRules(
            {
              merchantId: merchantStorageId,
              limit: 10000,
            },
            false
          )
        ),
        dispatch(
          getNodeInfo(
            {
              publicMerchantId: merchantStorageId,
            },
            false
          )
        )
      );

      if (isEmpty(processors)) {
        dispatch(
          setLoadingModal({
            isLoading: true,
            message: isEmpty(ruleId)
              ? LoadingModalMessages.DEFAULT_MESSAGE
              : LoadingModalMessages.GET_RULE,
          })
        );
        pendingRequests.push(
          dispatch(getConfiguration(false)),
          dispatch(getGeographyList()),
          dispatch(getCountries())
        );

        if (!isMassiveRules)
          pendingRequests.push(
            dispatch(getActiveProcessors(merchantStorageId))
          );
      }
      if (!isEmpty(ruleId)) {
        if (!isMassiveRules) {
          pendingRequests.push(dispatch(getRule(ruleId!)));
          closeModal(pendingRequests);
          return;
        }

        const businessRules: GetBusinessRulesResponse =
          getBusinessRulesStorage();
        const rule = businessRules.items.filter((item) => item.id === ruleId);
        if (isEmpty(rule)) return;

        const ruleName: string = get(rule[0], "rule.name", "");
        const names: string[] = ruleName.split(" ");
        const name: string = ruleName.replace(names[0], "").trim();
        const businessRule: RuleItem = rule[0];
        dispatch(
          getGetRuleById({
            type: get(businessRule, "type", ""),
            updatedAt: 0,
            service: get(businessRule, "service", ""),
            rule: {
              TransactionMerchantCountry: get(
                businessRule,
                "rule.TransactionMerchantCountry",
                ""
              ),
              action: get(businessRule, "rule.action", ""),
              code: get(businessRule, "rule.code", ""),
              ignore3DS: get(businessRule, "rule.ignore3DS", false),
              kind: get(businessRule, "rule.kind", ""),
              limit: get(businessRule, "rule.limit", ""),
              maxAmount: get(businessRule, "rule.maxAmount", ""),
              message: get(businessRule, "rule.message", ""),
              metric: get(businessRule, "rule.metric", ""),
              name: name,
              partnerValidator: get(businessRule, "rule.partnerValidator", ""),
              periodicity: get(businessRule, "rule.periodicity", ""),
              periodicityCount: get(businessRule, "rule.periodicityCount", ""),
              periodicityCountDay: get(
                businessRule,
                "rule.periodicityCountDay",
                ""
              ),
              periodicityCountHour: get(
                businessRule,
                "rule.periodicityCountHour",
                ""
              ),
              periodicitySum: get(businessRule, "rule.periodicitySum", ""),
              processorLimit: get(businessRule, "rule.processorLimit", ""),
              processorName: get(businessRule, "rule.processorName", ""),
              processorPeriodicityCountDay: get(
                businessRule,
                "rule.processorPeriodicityCountDay",
                ""
              ),
              processorPeriodicityCountHour: get(
                businessRule,
                "rule.processorPeriodicityCountHour",
                ""
              ),
              transactionBIN: get(businessRule, "rule.transactionBIN", ""),
              transactionBank: get(businessRule, "rule.transactionBank", ""),
              transactionBrand: get(businessRule, "rule.transactionBrand", ""),
              transactionCardIssuer: get(
                businessRule,
                "rule.transactionCardIssuer",
                ""
              ),
              transactionCountry: get(
                businessRule,
                "rule.transactionCountry",
                ""
              ),
              transactionCreated: get(
                businessRule,
                "rule.transactionCreated",
                ""
              ),
              transactionCredential: get(
                businessRule,
                "rule.transactionCredential",
                ""
              ),
              transactionCurrency: get(
                businessRule,
                "rule.transactionCurrency",
                ""
              ),
              transactionEmail: get(businessRule, "rule.transactionEmail", ""),
              transactionIP: get(businessRule, "rule.transactionIP", ""),
              transactionIsCreditCard: get(
                businessRule,
                "rule.transactionIsCreditCard",
                ""
              ),
              transactionIsDeferred: get(
                businessRule,
                "rule.transactionIsDeferred",
                ""
              ),
              transactionMaskedCreditCard: get(
                businessRule,
                "rule.transactionMaskedCreditCard",
                ""
              ),
              transactionProcessor: get(
                businessRule,
                "rule.transactionProcessor",
                ""
              ),
              transactionProcessorName: get(
                businessRule,
                "rule.transactionProcessorName",
                ""
              ),
              transactionStatus: get(
                businessRule,
                "rule.transactionStatus",
                ""
              ),
              transactionStatusCount: get(
                businessRule,
                "rule.transactionStatusCount",
                ""
              ),
              transactionStatusSum: get(
                businessRule,
                "rule.transactionStatusSum",
                ""
              ),
              transactionTotalAmount: get(
                businessRule,
                "rule.transactionTotalAmount",
                ""
              ),
              transactionType: get(businessRule, "rule.transactionType", ""),
              updatedProcessorRule: get(
                businessRule,
                "rule.updatedProcessorRule",
                false
              ),
            },
            historyVersion: "",
            country: get(businessRule, "country", []),
            createdAt: 0,
            deletedAt: 0,
            id: get(businessRule, "id", ""),
            merchantId: get(businessRule, "merchantId", ""),
            merchantName: get(businessRule, "merchantName", ""),
          })
        );
        setTimeout(() => {
          dispatch(setPrefixAtomicCounter(names[0]));
        }, 2000);
      }
      closeModal(pendingRequests);
    }, []);

    useEffect(() => {
      if (
        get(nodeInfoMerchant, "hasChilds", false) &&
        get(nodeInfoMerchant, "entityName", "") === EntityNameEnum.CUSTOMER
      ) {
        dispatch(
          getCustomerCredentials({
            merchantId: merchantStorageId,
            searchTerm: genericSearchTerm,
            entityName: get(nodeInfoMerchant, "entityName", ""),
            path: get(nodeInfoMerchant, "path", ""),
          })
        );
      }

      if (get(nodeInfoMerchant, "entityName", "") === EntityNameEnum.BRANCH) {
        dispatch(
          getSuggestCredentials({
            merchantId: merchantStorageId,
            searchTerm: genericSearchTerm,
            entityName: get(nodeInfoMerchant, "entityName", ""),
          })
        );
      }
    }, [nodeInfoMerchant]);

    useEffect(() => {
      if (isEmpty(ruleId)) {
        form.reset();
        return;
      }
      const isMassiveRules: boolean = getIsMassiveRules();
      if (!isEmpty(getRuleById) && !isEmpty(configList)) {
        const path: string = isMassiveRules ? "" : "rule.";
        const editRule: CreateRuleRequest = {
          merchantId: get(getRuleById, `${path}merchantId`, ""),
          type: get(getRuleById, `${path}type`, ""),
          status: get(getRuleById, `${path}status`, ""),
          merchantName: get(getRuleById, `${path}merchantName`, ""),
          service: get(getRuleById, `${path}service`, ""),
          rule: get(getRuleById, `${path}rule`, {}),
        };
        form.reset({ ...editRule });
        const conditions = setConditionsFromRule(getRuleById, `${path}rule`);

        insert(0, conditions);
        return;
      }
    }, [getRuleById, configList, ruleId, isCreate]);

    useEffect(() => {
      if (pathname) setIsCreate(pathname.includes("create"));
    }, [pathname]);

    const handleAddCondition = (): void => {
      const emptyObject: IConditionItem = {
        value: [],
        label: "",
        operator: "",
      };
      prepend({ ...emptyObject });
    };

    const handleRemoveCondition = (index: number): void => {
      const deletedField = fields[index];
      form.setValue(`rule.${get(deletedField, "label", "")}`, "");
      remove(index);
    };

    const verifyRepeatedConditions = (rule: CreateRuleRequest): boolean => {
      const repeatedConditions = getRepeatedConditions(rule, businessRules);
      const isRepeatedConditions = !isEmpty(repeatedConditions.conditions);
      setNewRule(rule);
      if (isRepeatedConditions && isEmpty(ruleId)) {
        dispatch(
          setSaveRuleModal(
            saveRepeatedRule(
              repeatedConditions.conditions.join(","),
              repeatedConditions.sources.join(",")
            )
          )
        );
        return isRepeatedConditions;
      }

      return false;
    };

    const handleSaveRuleEnable = () => {
      const status: BusinessRuleStatus = BusinessRuleStatus.ENABLE;
      const rule: CreateRuleRequest = createRuleRequest(form, status);
      const isRepeated: boolean = verifyRepeatedConditions(rule);
      if (!isRepeated) {
        if (!isEmpty(ruleId) && !isEmpty(getRuleById)) {
          const ruleStatus: string = get(
            getRuleById,
            "status",
            BusinessRuleStatus.ENABLE
          );

          switch (ruleStatus) {
            // Edit Draft
            case BusinessRuleStatus.DRAFT:
              dispatch(setSaveRuleModal(enableEditRule));
              break;
            // Edit Rule
            case BusinessRuleStatus.ENABLE:
              dispatch(
                setLoadingModal({
                  isLoading: true,
                  message: LoadingModalMessages.EDIT_RULE,
                })
              );
              if (getIsMassiveRules()) {
                const businessRules: GetBusinessRulesResponse =
                  getBusinessRulesStorage();
                const ruleLocalStorage: RuleItem[] = businessRules.items.filter(
                  (rule: RuleItem) => rule.id === ruleId
                );
                if (!isEmpty(ruleLocalStorage)) {
                  const ruleObject: Rule = ruleLocalStorage[0].rule;
                  const prefixAlias: string = ruleObject.name.substring(0, 6);
                  const ruleEdit: BusinessRule = rule.rule;
                  businessRules.items.forEach((itemRule: RuleItem) => {
                    if (itemRule.id === ruleId) {
                      itemRule.rule = ruleEdit;
                      itemRule.rule.name = `${prefixAlias} ${itemRule.rule.name}`;
                    }
                  });
                  localStorage.setItem(
                    LocalStoragePathEnum.BUSINESS_RULES_BATCH,
                    JSON.stringify(businessRules)
                  );
                }
              } else {
                dispatch(
                  updateRule(ruleId!, createRuleRequest(form, ruleStatus))
                );
              }
              setTimeout(() => {
                dispatch(setLoadingModal(DEFAULT_LOADING_MODAL));
                if (isEmpty(redirectPath) || existPathBusinessRuleList()) {
                  navigate(-1);
                  return;
                }
                navigate(redirectPath);
              }, 2000);
              break;

            default:
              dispatch(createRule(rule));
              dispatch(setSaveRuleModal(DEFAULT_SIMPLE_MODAL));
              navigate(redirectPath);
              break;
          }

          return;
        }

        const isMassiveRule = getIsMassiveRules();
        if (isMassiveRule) {
          rule.rule.name = `${prefixAtomicCounter} ${rule.rule.name}`;
          rule.id = Date.now().toString();
          rule.ruleCreatedAt = new Date().toLocaleString();
          const businessRules: GetBusinessRulesResponse =
            getBusinessRulesStorage();
          businessRules.items.push(rule as RuleItem);
          localStorage.setItem(
            LocalStoragePathEnum.BUSINESS_RULES_BATCH,
            JSON.stringify(businessRules)
          );
        } else {
          dispatch(createRule(rule));
        }
        dispatch(
          getBusinessRules({
            merchantId: merchantStorageId,
            limit: 10000,
          })
        );
        dispatch(setSaveRuleModal(ruleAdded));
      }
    };

    const handleSaveRuleDraft = () => {
      const status = BusinessRuleStatus.DRAFT;
      const rule = createRuleRequest(form, status);
      const isRepeated = verifyRepeatedConditions(rule);
      if (!isRepeated) {
        dispatch(setSaveRuleModal(saveEditDraftRule));
        return;
      }
    };

    const handleRedirectPath = () => {
      if (isEmpty(redirectPath)) {
        navigate(-1);
        return;
      }
      navigate(redirectPath);
    };

    const handlePrimaryButtonRuleModal = () => {
      switch (saveRuleModal!.action) {
        case ModalSimpleData.CREATE_DRAFT:
          dispatch(createRule(newRule));
          setTimeout(() => {
            dispatch(setSaveRuleModal(DEFAULT_SIMPLE_MODAL));
            dispatch(setSuccessEnableRule(true));
          }, 2000);
          break;
        case ModalSimpleData.CREATE:
          dispatch(createRule(newRule));
          dispatch(setSaveRuleModal(DEFAULT_SIMPLE_MODAL));
          if (isEmpty(redirectPath) || existPathBusinessRuleList()) {
            navigate(-1);
            break;
          }
          navigate(redirectPath);
          break;
        default:
          //ModalSimpleData.MODAL_BTN_ADD_NEW_RULE
          form.reset({ ...BUSINESS_RULE_INITIAL_STATE });
          setNewRule({ ...BUSINESS_RULE_INITIAL_STATE });
          dispatch(setSaveRuleModal(DEFAULT_SIMPLE_MODAL));
          navigate(0);
          break;
      }
    };

    const handleSecondaryButtonRuleModal = () => {
      switch (saveRuleModal!.buttonSecondary) {
        case ModalSimpleData.MODAL_BTN_END:
          dispatch(setSaveRuleModal(DEFAULT_SIMPLE_MODAL));
          if (isEmpty(redirectPath) || existPathBusinessRuleList()) {
            navigate(-1);
            break;
          }
          navigate(redirectPath);
          break;
        default:
          //ModalSimpleData.CANCEL_BTN
          dispatch(setSaveRuleModal(DEFAULT_SIMPLE_MODAL));
          break;
      }
    };

    const handleBack = () => {
      navigate(-1);
    };

    return {
      form,
      fields,
      actions: {
        handleBack,
        handleRedirectPath,
        handleRemoveCondition,
        handleAddCondition,
        handleSaveRuleEnable,
        handleSaveRuleDraft,
        insert,
        handlePrimaryButtonRuleModal,
        handleSecondaryButtonRuleModal,
        handleEnterSearchTextProcessors,
        handleSetSearchTextProcessors,
      },
      saveRuleModal: saveRuleModal!,
      loadingModal: loadingModal!,
      editRule: getRuleById,
    };
  };

const createRuleRequest = (
  form: UseFormMethods<CreateRuleRequest>,
  ruleStatus: BusinessRuleStatus
): CreateRuleRequest => {
  const formData: CreateRuleRequest = form.getValues();
  const { publicMerchantId, name: merchantName } =
    getBasicMerchantInformation();
  const merchantIdInfo = getMerchantInfo().publicMerchantId;
  const conditions: IConditionItem[] = defaultTo(formData["condition"], []);

  const merchantStorageId: string = ((
    publicMerchantId: string,
    merchantInfoId: string
  ) => {
    if (!isEmpty(publicMerchantId)) {
      return publicMerchantId;
    }
    if (!isEmpty(merchantInfoId)) {
      return merchantInfoId;
    }
    return "";
  })(publicMerchantId, merchantIdInfo);

  const ruleRequest: CreateRuleRequest = {
    merchantId: merchantStorageId,
    type: LabelEnum.BUSINESS,
    service: formData.service,
    country: formData.country,
    merchantName: defaultTo(merchantName || formData.merchantName, ""),
    metadataReference: formData.metadataReference,
    rule: validateRuleUpdateData(formData.rule, conditions),
    status: ruleStatus,
  };

  conditions.forEach((item: IConditionItem) => {
    const conditionValue: string =
      item.label === RuleEnum.CARD_TYPE
        ? TRANSACTION_TYPE_MAPPING_CREATION[item.value[0]]
        : item.value.join(",");
    set(
      ruleRequest,
      `rule.${item.label}`,
      `${item.operator}|${conditionValue}`
    );
  });

  return ruleRequest;
};

const validateRuleUpdateData = (
  formRule: BusinessRule,
  conditions: IConditionItem[]
): BusinessRule => {
  Object.keys(formRule).forEach((key: string) => {
    if (
      Object.values(RuleEnum).includes(key as RuleEnum) &&
      conditions.every((condition: IConditionItem) => condition.label != key)
    )
      formRule[key] = "";
  });

  return formRule;
};

const getRepeatedConditions = (
  rule: CreateRuleRequest,
  rules: RuleItem[] | undefined
): IRepeatedConditions => {
  const repeatedConditions: Array<string> = [];
  const ruleRepeatedSource: Array<string> = [];
  if (isEmpty(rules)) return { conditions: [], sources: [] };

  rules!.forEach((oldRule) => {
    return Object.values(RuleEnum).forEach((condition) => {
      const conditionOldRule = get(oldRule, `rule.${condition}`, "");
      const conditionNewRule = get(rule, `rule.${condition}`, "");
      if (!isEmpty(conditionOldRule)) {
        const isRepeated = conditionOldRule == conditionNewRule;
        if (isRepeated) {
          ruleRepeatedSource.push(oldRule.rule.name);
          const titleCondition = fromRuleKeyToTitleMap.get(condition);
          repeatedConditions.push(
            defaultTo(titleCondition, "condición no reconocida")
          );
        }
      }
    });
  });

  return {
    conditions: deleteDuplicatedElements(repeatedConditions),
    sources: deleteDuplicatedElements(ruleRepeatedSource),
  };
};
