import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useServicesSection } from "../../containers/ServicesSection/state/useServicesSection";

export interface CardTypeProps {
  name: string;
  image: string;
}

const useStyles = makeStyles({
  cardClass: {
    height: 45,
    width: 60,
    textTransform: "none",
  },
  imageClass: {
    display: "block",
    height: 20,
    paddingRight: "2px",
  },
});

export const CardTypeList = (props: CardTypeProps) => {
  const classes = useStyles();
  const { acceptedCardImage } = useServicesSection();

  return (
    <img
      src={acceptedCardImage(props.image)}
      alt={props.name}
      height={20}
      className={classes.imageClass}
    />
  );
};
