import React from "react";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { get } from "lodash";
import { StatusFlowEnum } from "../../../shared/infrastructure/StatusFlowEnum";
import { TransactionTable } from "../../../../types/transaction_table";
import {
  StyledTableCell,
  StyledTableCellCheck,
} from "../../DashboardList/Table/DashboardBillingTable/DashboardTable";
import { CountryEnum } from "../../../shared/infrastructure/CountryEnum";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { StatusEnum } from "../../../shared/infrastructure/StatusEnum";
import DashboardTableRow from "../../DashboardList/Table/DashboardBillingTable/DashboardTableRow/DashboardTableRow";
import { IColumnsTable } from "../../../shared/infrastructure/table/IColumnsTable";

export interface TableContainerDownloadProps {
  tableRowProps: {
    modalOverBilling: {
      openModalBilling: boolean;
      handlerCloseModalTrxBilling: () => void;
      handlerSelectTrxBilling: (
        row: TransactionTable | undefined,
        index: number
      ) => void;
      trxBilling: { trx: TransactionTable | undefined; index: number };
      isMinAmountTrx: boolean;
    };
    handleCheckboxChange: (
      rowValue: TransactionTable
    ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    checked: boolean;
  };
  selectProps: {
    statusRows: string;
    checkedRows: boolean;
    order: "asc" | "desc";
    orderBy: string;
    openAdvanceSettingsDialog: boolean;
    openInvoiceInfoDialog: boolean;
    openModalOmit: boolean;
    openRejectModal: boolean;
    reasonCheckBox: string;
  };
  fixedHeaders: IColumnsTable[];
  actions: {
    handleChangeCheckbox: (
      event: React.ChangeEvent<HTMLInputElement>,
      type: StatusEnum,
      statusFlow: StatusFlowEnum
    ) => void;
    addAdditionalData: (trxUpdated: TransactionTable) => void;
    validateCheckboxByTaxId: (
      checkedRow: TransactionTable,
      status: StatusEnum
    ) => void;
    handleRequestSort: (
      event: React.MouseEvent<unknown>,
      property: string
    ) => void;
    handleOpenAdvanceSettings: (open: boolean) => void;
    handleOpenInvoiceInfo: (open: boolean) => void;
    openDialogsHandler: () => void;
    handleCloseOmit: () => void;
    handleAcceptOmit: (omitReason: string) => void;
    handleCloseReject: () => void;
    handleAcceptReject: (rejectReason: string) => void;
    handleDefaultValuesRows: () => void;
  };
  settingsColumns: {
    selectedColumns: string[];
    setSelectedColumns: (data: string[]) => void;
    handleAppliedColumnsFilters: (restore?: boolean) => void;
    renderColumns: IColumnsTable[];
  };
  orderBy: string;
  handleRequestSort: (
    _event: React.MouseEvent<unknown>,
    property: string
  ) => void;
  statusFlow: StatusFlowEnum;
  data: TransactionTable[];
  dataOriginal: TransactionTable[];
  order: "asc" | "desc";
  checkValue: boolean;
  children?: React.ReactNode;
  country: string;
  update: (
    event: React.ChangeEvent<HTMLInputElement>,
    data: TransactionTable[]
  ) => void;
  setBillingDataRow(billingDataRow: TransactionTable, index: number): void;
  transactionIdFilter: () => void;
}
const useStyles = makeStyles((theme: Theme) => ({
  chip: { backgroundColor: "#F7FAFC" },
  check: {
    marginLeft: theme.spacing(1),
  },
  checkRow: {
    marginLeft: theme.spacing(2),
  },
}));
export const TableContainerDownload = (props: TableContainerDownloadProps) => {
  const classes = useStyles();
  const {
    statusFlow,
    settingsColumns,
    selectProps,
    actions,
    fixedHeaders,
  } = props;

  const getTableCellWithSortButton = (
    column: IColumnsTable,
    order: "desc" | "asc" | undefined,
    orderBy: string,
    handleRequestSort: (
      event: React.MouseEvent<unknown>,
      property: string
    ) => void
  ) => {
    switch (column.id) {
      case "merchantName":
        return (
          <StyledTableCell>
            <TableSortLabel
              direction={orderBy === "merchantId.keyword" ? order : "asc"}
              onClick={(event) =>
                handleRequestSort(event, "merchantName.keyword")
              }
            >
              Comercios
            </TableSortLabel>
          </StyledTableCell>
        );
      default:
        return (
          <StyledTableCell key={column.id}>{column.label}</StyledTableCell>
        );
    }
  };
  return (
    <>
      <TableContainer component={Paper} elevation={0}>
        {get(props, "data", []).length > 0 && (
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <Checkbox
                    className={classes.check}
                    id={"checkStatusAll"}
                    color={"primary"}
                    checked={props.checkValue}
                    onChange={(e) => props.update(e, props.dataOriginal)}
                  />
                </StyledTableCell>
                {settingsColumns.renderColumns.map((column: IColumnsTable) =>
                  getTableCellWithSortButton(
                    column,
                    selectProps.order,
                    selectProps.orderBy,
                    actions.handleRequestSort
                  )
                )}
                <StyledTableCellCheck>
                  <Checkbox checked={false} disabled={true} />
                  {statusFlow === StatusFlowEnum.VALIDATOR
                    ? "Aprobar"
                    : "Procesar"}
                </StyledTableCellCheck>
                {statusFlow === StatusFlowEnum.EXECUTOR && (
                  <StyledTableCellCheck>
                    <Checkbox checked={false} disabled={true} />
                    Pendiente
                  </StyledTableCellCheck>
                )}

                {statusFlow === StatusFlowEnum.VALIDATOR && (
                  <StyledTableCellCheck>
                    <Checkbox checked={false} disabled={true} />
                    Rechazar
                  </StyledTableCellCheck>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data?.map((row: TransactionTable, index: number) => (
                <DashboardTableRow
                  isComplementaryTable={true}
                  renderColumns={fixedHeaders}
                  billingData={row}
                  country={props.country as CountryEnum}
                  handleCheckboxChange={
                    props.tableRowProps.handleCheckboxChange
                  }
                  checked={props.tableRowProps.checked}
                  index={index}
                  key={index}
                  handlerOpenCardRow={
                    props.tableRowProps.modalOverBilling.handlerSelectTrxBilling
                  }
                  validateCheckboxByTaxId={actions.validateCheckboxByTaxId}
                  statusFlow={statusFlow}
                  statusRows={selectProps.statusRows}
                  reasonCheckBox={selectProps.reasonCheckBox}
                  handleDefaultValuesRows={actions.handleDefaultValuesRows}
                />
              ))}
            </TableBody>
          </Table>
        )}
        {props.children}
      </TableContainer>
    </>
  );
};
