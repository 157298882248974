import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

export const processorInfoComponentStyles = createNamedStyles({
  fieldSkeleton: {
    width: 260,
    height: 80,
  },
  checkSkeleton: {
    marginLeft:"20px",
    width: 400,
    height: 40,
  },
  basicContainer: {
    marginBottom: "5px",
  },
  formContainer: {
    display: "flex",
    marginLeft: "20px",
    marginBottom: "16px",
  },
  field: {
    marginRight: "5px",
  },
  titleBasic: {
    marginRight: "5px",
    marginBottom: "15px",
    marginLeft: "20px",
  },
  checkBox:{
    '&.Mui-checked': {
      color: "#023365",
    },
  }
});
