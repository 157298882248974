import React, { FC } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { userCreateEditStyles as styles } from "./UserCreateEdit.styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { verifyIsConsole3 } from "../../shared/utils/urlPath-utils";
import { GenericBreadcrumb } from "../../components/GenericBreadcrumb/GenericBreadcrumb";
import RolesRadioButtons from "../../components/RolesRadioButtons/RolesRadioButtons";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import {
  FooterNewConsoleEnum,
  UserFormLabel,
} from "../../shared/constants/labels/UserFormLabel";
import { ModalLoader, ModalSimple } from "@kushki/connect-ui";
import FooterNewConsole from "../../components/FooterNewConsole/FooterNewConsole";
import { FormProvider } from "react-hook-form";
import FormControl from "../../components/FormControl/FormControl";
import { FormUserRules } from "../../shared/constants/FormUserRules";
import RolesChecks from "../../components/RolesChecks/RolesCheks";
import { ROLES, ROLES_NAMES } from "../../shared/constants/RoleNames";
import { defaultTo, get } from "lodash";
import { DisableKeyEnum } from "../../shared/enum/DisableKeyEnum";
import { WrapperContainer } from "../WrapperContainer/WrapperContainer";
import { useUserCreateEdit } from "./state/useUserCreateEdit";
import { IOnChangeFunction } from "../../shared/interfaces/Generic";
import { SecurityWrapperRoles } from "../../shared/enum/SecurityWrapperRoles";

const UserCreateEdit: FC = () => {
  const {
    isConsole3,
    handleBreadcrumbItem,
    breadcrumbState,
    kindRoles,
    form,
    handleReturn,
    handleOpenCloseModal,
    footerLabels,
    isOwner,
    openModal,
    handleOk,
    handleChangeRoles,
    valuesRoles,
    viewMerchantSelect,
    merchantName,
    merchants,
    isAdmin,
    handleChangeMerchant,
    handleChangeMerchants,
    rolesNoAdmin,
    isCreateForm,
    viewCheckboxRoles,
    handleValidateUser,
    handleValidateEmail,
    onSubmit,
    isLoadingCreateEditUser,
    isValidUserName,
    isLoadingValidateUsername,
    isCustomerSelected,
    isChargeBackManagerSelected,
  } = useUserCreateEdit();

  return (
    <React.Fragment>
      <WrapperContainer single={true}>
        <Container fixed sx={styles.mainContainer}>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              {!verifyIsConsole3() && (
                <GenericBreadcrumb {...handleBreadcrumbItem()} />
              )}
              {isConsole3 && (
                <Button
                  variant="text"
                  startIcon={<ArrowBackIosIcon />}
                  sx={styles.backBtnNewConsole}
                  onClick={handleReturn}
                >
                  Regresar
                </Button>
              )}

              <Box sx={styles.title}>
                <Typography variant={"h1"} color={"primary"}>
                  {breadcrumbState.lastItemBreadcrumb}
                </Typography>
              </Box>

              <Card>
                <CardContent>
                  <RolesRadioButtons roles={kindRoles} />
                  <Typography
                    variant="h5"
                    component="h2"
                    style={{ marginBottom: 12 }}
                  >
                    Datos del Usuario
                  </Typography>
                  <Grid container spacing={3} mb={3}>
                    {viewMerchantSelect && (
                      <React.Fragment>
                        <Grid item md={!!merchantName ? 12 : 6} sm={12} xs={12}>
                          {!!merchantName ? (
                            <TextField
                              id="nameMerchant"
                              name="merchantName"
                              disabled={true}
                              style={{ width: "100%" }}
                              defaultValue={merchantName}
                              variant={"outlined"}
                              size={"small"}
                            />
                          ) : (
                            <FormControl
                              key={"merchantName"}
                              inputComponent="autocomplete"
                              label="Comercio Asociado"
                              name="publicMerchantId"
                              pathId={"_source.publicMerchantId"}
                              onChange={handleChangeMerchant}
                              onInputChange={handleChangeMerchants}
                              listObject={merchants.data}
                              pathInfo={"_source.name"}
                              rules={FormUserRules.merchant}
                            />
                          )}
                        </Grid>
                      </React.Fragment>
                    )}
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        key={"name"}
                        inputComponent="text"
                        label="Nombre"
                        name="name"
                        rules={FormUserRules.name}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        key={"familyName"}
                        inputComponent="text"
                        label="Apellido"
                        name="familyName"
                        rules={FormUserRules.familyName}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        key={"userName"}
                        inputComponent="text"
                        label="Nombre de usuario"
                        name="userName"
                        isDisable={!isCreateForm}
                        onChange={handleValidateUser}
                        rules={{
                          ...FormUserRules.userName,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        key={"email"}
                        inputComponent="email"
                        label="E-mail"
                        name="email"
                        rules={{
                          ...FormUserRules.email,
                          validate: (value: string) => {
                            return handleValidateEmail(value);
                          },
                        }}
                      />
                    </Grid>
                    {viewCheckboxRoles && (
                      <RolesChecks
                        errors={form.formState.errors}
                        label={get(
                          ROLES_NAMES.find(
                            (item) => item.id === form.getValues("kindRoles")
                          ),
                          "value",
                          ""
                        )}
                        disabledKey={
                          isOwner || (isAdmin && isCustomerSelected)
                            ? DisableKeyEnum.PARENT_MERCHANT_ENABLE
                            : undefined
                        }
                        isChargeBackManagerSelected={
                          isChargeBackManagerSelected
                        }
                        roles={
                          !isAdmin
                            ? ROLES.filter((item) =>
                                rolesNoAdmin.includes(item.id)
                              ).map((itemM) => ({
                                id: itemM.id,
                                kind: itemM.kind,
                                parentMerchantEnable:
                                  isOwner &&
                                  itemM.id !== "BackofficeMasterCustomer"
                                    ? false
                                    : itemM.parentMerchantEnable,
                                value: itemM.value,
                              }))
                            : ROLES.filter((item) =>
                                (!isAdmin && !isCustomerSelected) ||
                                (!isOwner && !isCustomerSelected)
                                  ? item.id !== "BackofficeMasterCustomer"
                                  : true
                              ).map((itemM) => ({
                                id: itemM.id,
                                kind: itemM.kind,
                                parentMerchantEnable:
                                  (isOwner ||
                                    (isAdmin && isCustomerSelected)) &&
                                  itemM.id !== "BackofficeMasterCustomer"
                                    ? itemM.id === "ChargebackManager"
                                    : itemM.parentMerchantEnable,
                                value: itemM.value,
                              }))
                        }
                        rolesForm={valuesRoles}
                        onChange={
                          handleChangeRoles as IOnChangeFunction<string>
                        }
                        viewCondition={form.getValues("kindRoles")}
                      ></RolesChecks>
                    )}
                  </Grid>
                </CardContent>
              </Card>
              {!localStorage.getItem("authPayload") && !isConsole3 && (
                <Box sx={styles.buttonOldConsole}>
                  <Button
                    onClick={handleOpenCloseModal}
                    sx={styles.ButtonCancel}
                  >
                    <Typography>Regresar</Typography>
                  </Button>
                  <ComponentSecurityWrapper
                    componentId={
                      breadcrumbState.lastItemBreadcrumb ===
                      UserFormLabel.EDIT_USER
                        ? SecurityWrapperRoles.M_USERS_USER_EDIT
                        : SecurityWrapperRoles.M_USERS_USER_CREATE
                    }
                  >
                    <Button
                      id={"btn-redirect"}
                      color={"primary"}
                      variant={"contained"}
                      type="submit"
                      disabled={
                        isLoadingCreateEditUser ||
                        !isValidUserName ||
                        isLoadingValidateUsername
                      }
                      style={{
                        marginLeft: 23,
                      }}
                    >
                      {isLoadingCreateEditUser && (
                        <CircularProgress
                          sx={{ marginRight: "10px" }}
                          size={20}
                        />
                      )}
                      {breadcrumbState.lastItemBreadcrumb}
                    </Button>
                  </ComponentSecurityWrapper>
                </Box>
              )}

              {isConsole3 && (
                <FooterNewConsole
                  date={footerLabels.date}
                  buttonLabel={
                    isOwner
                      ? FooterNewConsoleEnum.ADD_USER
                      : FooterNewConsoleEnum.SAVE
                  }
                  modifyName={footerLabels.modifyName}
                  handleClick={form.handleSubmit(onSubmit)}
                />
              )}
            </form>
          </FormProvider>
        </Container>
        <ModalLoader
          isOpen={defaultTo(isLoadingCreateEditUser, false) && isCreateForm}
          descriptionText={UserFormLabel.LOADER_BODY}
          titleText={UserFormLabel.LOADER_TITLE}
        />
        <ModalSimple
          buttonPrimary={UserFormLabel.MODAL_NEXT}
          buttonSecondary={UserFormLabel.MODAL_CANCEL}
          descriptionText={UserFormLabel.MODAL_BODY}
          onClickPrimary={handleOk}
          onClickSecondary={handleOpenCloseModal}
          onClose={handleOpenCloseModal}
          titleText={UserFormLabel.MODAL_TITLE}
          typeModal={1}
          isOpen={openModal}
        />
      </WrapperContainer>
    </React.Fragment>
  );
};

export default UserCreateEdit;
