import { SubmitHandler, useForm, UseFormMethods } from "react-hook-form";
import { defaultTo, isEmpty, get } from "lodash";
import {
  IWhitelistRuleForm,
  IWhitelistRuleRequest,
} from "../../../shared/infrastructure/interfaces/IWhitelistRulesForm";
import { WhitelistRulesEditProps } from "../WhitelistRulesEdit";
import { RulesNotificationResultEnum } from "../../../shared/infrastructure/constants/RulesConstants";
import React, { useEffect } from "react";
import { MerchantsData } from "../../../store/actionCreator";
// @ts-ignore
import { WhitelistRulesTitleButton } from "../../../shared/infrastructure/constants/WhitelistRulesConstants";
import { useParams } from "react-router-dom";

export interface WhitelistRulesState {
  form: UseFormMethods<IWhitelistRuleForm>;
  isEdit: boolean;
  textButton: string;
  terminateFillFields: boolean;
  merchantName: string;
  actions: {
    handleSubmitForm: SubmitHandler<IWhitelistRuleForm>;
    handleCancelAction: () => void;
    handleCloseNotification: () => void;
    setFormValue: (name: string, value: string) => void;
    handleSetSearchTextMerchantList: (searchText: string) => void;
    handleEnterSearchTextMerchantList: (key: string) => void;
    setMerchantInformation: (value: object) => void;
  };
}

const useWhitelistRulesState = (
  props: WhitelistRulesEditProps
): WhitelistRulesState => {
  const form = useForm<IWhitelistRuleForm>({
    mode: "all",
    defaultValues: {
      maskedCards: "",
      cardIDs: "",
    },
  });

  const valueCondition = form.watch("validationType");
  const { merchantId } = useParams();
  const isEdit: boolean = window.location.pathname.startsWith(
    `/rules/whitelist/rule/${merchantId}`
  );

  const textButton: string = isEdit
    ? WhitelistRulesTitleButton.SAVE
    : WhitelistRulesTitleButton.ADD;

  const [role, setRole] = React.useState<string>("");
  console.log(role);
  const [terminateFillFields] = React.useState<boolean>(isEdit ? false : true);

  const [searchTextMerchant, setSearchTextMerchant] =
    React.useState<string>("");

  const [merchantName, setMerchantName] = React.useState<string>("");

  const [countrytName, setCountryName] = React.useState<string>("");

  const [publicMerchantId, setPublicMerchantId] = React.useState<string>("");

  useEffect(() => {
    getRole();
    if (isEdit) {
      setFormValue("merchantId", "20000000108222267000");
      setMerchantName("My merchant");
      setCountryName("Ecuador");
      setFormValue("maskedCards", ["12345", "6789", "098654"].join("\n"));
      setFormValue("cardIDs", ["12345", "6789", "098654"].join(","));
      setFormValue("validationType", "maskedCards");
    } else {
      props.searchMerchantsList({
        offset: 0,
        limit: 6,
      });
    }
  }, []);

  useEffect(() => {
    if (merchantId) {
      props.getWhitelistRuleMerchant(merchantId);
      setPublicMerchantId(merchantId);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(props.currentWhitelistRule)) {
      setFormValue("merchantId", merchantId);
      setMerchantName(
        get(props, "currentWhitelistRule.items.0.merchantName", "")
      );
      setCountryName(get(props, "currentWhitelistRule.items.0.country", ""));
      setFormValue(
        "validationType",
        get(props, "currentWhitelistRule.items.0.validationType", "")
      );
    }
  }, [props.currentWhitelistRule]);

  useEffect(() => {
    setFormValue(
      "maskedCards",
      get(props, "currentWhitelistRule.items.0.maskedCards", []).join("\n")
    );
    setFormValue(
      "cardIDs",
      defaultTo(
        get(props, "currentWhitelistRule.items.0.cardIDs", []),
        []
      ).join(",")
    );
  }, [valueCondition, props.currentWhitelistRule]);

  useEffect(() => {
    getRole();
    if (isEdit && !isEmpty(props.currentMerchant)) {
      props.setMerchants({
        data: [{ _source: props.currentMerchant }],
      } as MerchantsData);
      setMerchantInformation(defaultTo(props.currentMerchant, {}));
    }
  }, [props.currentMerchant]);

  const setFormValue = (name: string, value: string) => {
    // @ts-ignore
    form.control.setValue(name, value);
  };

  const getRole = () => {
    const roles: object = JSON.parse(
      defaultTo(localStorage.getItem("roles"), "{}")
    );

    if (get(roles, "BackofficeMonitoring") === true)
      setRole("BackofficeMonitoring");
  };

  const setMerchantInformation = (value: object) => {
    setFormValue("merchantId", get(value, "publicMerchantId", ""));
    setMerchantName(get(value, "name", ""));
    setCountryName(get(value, "country", ""));
    setPublicMerchantId(get(value, "publicMerchantId", ""));
  };

  const handleSubmitForm: SubmitHandler<IWhitelistRuleForm> = async (
    formData
  ) => {
    const maskedCards = get(formData, "maskedCards", "").split("\n");
    const cardIDsValue = get(formData, "cardIDs", "");
    const cardIDs = !isEmpty(cardIDsValue)
      ? get(formData, "cardIDs").replace(/\s/g, "").split(",")
      : undefined;
    const validationType = get(formData, "validationType", "");
    const whitelist_rule: IWhitelistRuleRequest = {
      cardIDs,
      country: countrytName,
      maskedCards,
      merchantId: publicMerchantId,
      merchantName: merchantName,
      validationType,
    };
    props.createWhitelistRule(whitelist_rule);
  };

  const handleCancelAction = (): void => {
    window.location.href = "/rules";
  };

  const handleSetSearchTextMerchantList = (searchText: string) => {
    setSearchTextMerchant(searchText);
  };
  const handleEnterSearchTextMerchantList = (key: string) => {
    if (key !== "Enter") return;
    props.searchMerchantsList({
      offset: 0,
      limit: 6,
      text: searchTextMerchant,
    });
  };

  const handleCloseNotification = (): void => {
    props.setNotification({
      type: props.notification!.type!,
      open: false,
      message: "",
      action: RulesNotificationResultEnum.NO_ACTION,
    });
  };

  return {
    form,
    isEdit: !isEmpty(merchantId),
    textButton,
    terminateFillFields,
    merchantName,
    actions: {
      handleCancelAction,
      handleCloseNotification,
      handleSubmitForm,
      setFormValue,
      handleSetSearchTextMerchantList,
      handleEnterSearchTextMerchantList,
      setMerchantInformation,
    },
  };
};

export default useWhitelistRulesState;
