import { environment } from "../../environments/environment";

export const API_ROUTES = {
  AVAILABLE_USERNAME: `${environment.kushkiUrl}/authorizer/v1/isUseNameAvailable`,
  CONFIG: `${environment.kushkiUrl}/hierarchy/v1/merchant/v1/node/config`,
  CREATE_EDIT_USER: `${environment.kushkiUrl}/authorizer/v1/user`,
  DELETE_USER: `${environment.kushkiUrl}/authorizer/v1/user`,
  DOWNLOAD_MASSIVE_USER: `${environment.kushkiUrl}/billing-core-node/v1/user/massive`,
  GET_MERCHANTS_ANALYTICS: `${environment.kushkiUrl}/analytics/v1/admin/merchant/get`,
  GET_NODE_INFO: `${environment.kushkiUrl}/billing-core-node/v1/node/info`,
  GET_NODE_INFO_BY_MERCHANT_ID: `${environment.kushkiUrl}/hierarchy/v1/merchant/`,
  GET_USERS: `${environment.kushkiUrl}/authorizer/v1/user`,
  HIERARCHY_GET_FIRST_PARENT: `${environment.kushkiUrl}/hierarchy/v1/node/first-parent`,
  HIERARCHY_GET_NODE_INFO: `${environment.kushkiUrl}/hierarchy/v1/merchant/`,
  RESTORE_PASSWORD: `${environment.kushkiUrl}/authorizer/v1/restorePassword`,
  SEARCH_MERCHANT: `${environment.kushkiUrl}/billing-core-node/v1/merchant-node/search-merchants`,
  SEARCH_MERCHANT_NODE_INFO: `${environment.kushkiUrl}/billing-core-node/v1/merchant-node/search`,
  SEMAPHORE_CREATE_UPDATE: `${environment.kushkiUrl}/billing-core/v1/semaphore/createUpdate`,
  STATUS: `${environment.kushkiUrl}/billing-core-node/v1/merchant-node/status`,
  VALIDATE_USER: `${environment.KushkiUrlWS}/billingcorenodews`,
};
