import { IStyles } from "../../shared/interfaces/Styles.interfaces";

export const filterComponentStyles: IStyles = {
  defaultColor: {
    color: "#023365",
    fill: "none",
  },
  filterButton: {
    "&.MuiButton-root": {
      textTransform: "none",
    },
    border: "1px solid #CBD5E0",
    borderRadius: "4px",
    color: "#293036",
    fontSize: "16px",
    fontWeight: 500,
    height: "44px",
    lineHeight: "150%",
    padding: "7px 30px",
    textTransform: "none",
    width: "135px",
  },
  filterPopover: {
    "&.MuiPopover-root .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "&.MuiPopover-root .MuiInput-underline:before": {
      borderBottom: "none",
    },
  },
  kshBtnFilter: {
    backgroundColor: "#ffffff",
    height: "39px",
    marginTop: "4px",
    padding: "7px 15px",
  },
  paperStyle: {
    backgroundColor: "white",
    maxWidth: "500px",
    minWidth: "500px",
    overflow: "auto",
    padding: "20px",
  },
};
