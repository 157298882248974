import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { Routes } from "./shared/infrastructure/routes";
import { Credentials } from "./containers/Credentials/Credentials";
import { EntityNameEnum } from "./shared/infrastructure/enums/EntityNameEnum";

const Navigation: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route
            path={`${Routes.BASE_PATH_CONSOLE_CREDENTIALS}/:merchantId`}
            exact
            render={(routeProps) => (
              <Credentials type={EntityNameEnum.BRANCH} {...routeProps} />
            )}
          />
          <Route
            path={`${Routes.BASE_PATH_CONSOLE_CREDENTIALS_CUSTOMER}/:merchantId`}
            exact
            render={(routeProps) => (
              <Credentials type={EntityNameEnum.CUSTOMER} {...routeProps} />
            )}
          />
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default connect()(Navigation);
