import React, { FC } from "react";
import { Grid, Typography } from "@mui/material";
import EmptyTableIcon from "../../../assets/images/EmptyTableIcon";
import EmptyTableStyles from "./EmptyTable.styles";

const EmptyTable: FC = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={EmptyTableStyles.container}
    >
      <Grid item pr={20}>
        <Typography typography="h4" sx={EmptyTableStyles.mainText}>
          Aún no cuentas con solicitudes de reembolsos
        </Typography>
        <Typography typography="caption" sx={EmptyTableStyles.subText}>
          Intenta revisarlo de nuevo más tarde
        </Typography>
      </Grid>
      <Grid item>
        <EmptyTableIcon />
      </Grid>
    </Grid>
  );
};

export default EmptyTable;
