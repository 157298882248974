import { ConfigurationIdEnum } from "../../../shared/enums";

export const requiredDataConfigs: string[] = [
  ConfigurationIdEnum.CN001,
  ConfigurationIdEnum.CN002,
  ConfigurationIdEnum.CN003,
  ConfigurationIdEnum.CN004,
  ConfigurationIdEnum.CN005,
  ConfigurationIdEnum.CN007,
  ConfigurationIdEnum.CN015,
  ConfigurationIdEnum.CN016,
  ConfigurationIdEnum.CN017,
  ConfigurationIdEnum.CN018,
];

export enum NODE_INFO_KEYS {
  COUNTRY = "data.data.generalInfo.country",
  ENTITY_NAME = "data.data.entityName",
  GENERAL_INFO = "data.data.generalInfo",
  GENERAL_INFO_STATUS = "data.data.generalInfo.status",
  GENERAL_INFO_NAME = "data.data.generalInfo.name",
  MERCHAN_ID = "data.data.merchantId",
}
