export enum StatusConfigEnum {
  COMPLETE = "complete",
  OMITTED = "omitted",
}

export enum StatusNodeEnum {
  ACTIVE = "active",
  PENDING = "pending",
}

export enum HttpStatusEnum {
  OK = 200,
}
