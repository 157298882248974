import React, { FC, PropsWithChildren } from "react";
import { Box, Grid } from "@mui/material";
import { FilterSideBar, FilterDetailBar } from "@kushki/connect-ui";
import { headerFiltersStyles as styles } from "./HeaderFilters.styles";
import { useHeaderFilterState } from "./state/useHeaderFilterState";
import { HeaderFiltersProps } from "./HeaderFilters.interfaces";

export const HeaderFilters: FC<HeaderFiltersProps> = (
  props: PropsWithChildren<HeaderFiltersProps>
) => {
  const { filterSideBar, onChangeFilterDetailBar, filterDetailBar } =
    useHeaderFilterState(props);

  return (
    <>
      <Grid item xs={6} sx={styles.gridFilter}>
        <Box sx={styles.buttonFilter}>
          <FilterSideBar data-testid={"filterBar"} {...filterSideBar} />
        </Box>
      </Grid>
      <Grid item xs={12} sx={styles.gridFilterCard}>
        <FilterDetailBar
          filters={filterDetailBar}
          onChangeFilters={onChangeFilterDetailBar}
        />
      </Grid>
    </>
  );
};
