/**
 * CatalogsEnum
 */
export enum CatalogsEnum {
  BankAccountTypes = "sBankAccountTypes",
  IdTypes = "sIdTypes",
  Banks = "sBanks",
  CategoryCode = "sCategoryCodes",
  MerchantTypes = "sMerchantTypes",
  Provinces = "sProvinces",
  UserTypes = "sUserTypes",
  DeferredTypes = "sDeferredTypes",
  DeferredBanks = "sDeferredBanks",
  DeferredCredimaticBanks = "sDeferredCredimaticBanks",
  DeferredDatafastBanks = "sDeferredDatafastBanks",
  Months = "sMonths",
  MonthsOfGrace = "monthsOfGrace",
  AcquirerBanks = "sAcquirerBanks",
  Processors = "sProcessors",
  Bins = "sBins",
  MCBins = "sMCBins",
  Currency = "sCurrency",
}
