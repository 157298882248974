import { makeStyles } from "@material-ui/core/styles";

export const uploadSectionStyles = makeStyles({
  title: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "150%",
    color: "#293036",
  },
  buttonText: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "100%",
    color: "#293036",
    marginTop: "17px",
    height: "34px",
    padding: "0px",
  },
  fileStatusNormal: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "120%",
    color: "#293036",
    marginTop: "25px",
    marginLeft: "12px",
  },
  description: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "120%",
    color: "#6D7781",
    marginTop: "10px",
  },
});
