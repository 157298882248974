import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export const fileUploadCardComponentEcuadorStyle = makeStyles((theme: Theme) =>
  createStyles({
    labelDescription: {
      marginLeft: theme.spacing(2),
      fontSize: "18px",
    },
    labelAlert: {
      marginLeft: theme.spacing(2),
    },
    labelTitle: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(2),
      color: theme.palette.primary.main,
      fontSize: "28px",
    },
    labelDescriptionButton: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
    labelTitleStep: {
      marginLeft: theme.spacing(2),
      color: theme.palette.primary.main,
    },
    contentWidth: {
      maxWidth: "fit-content",
    },
    cardStyle: { backgroundColor: "#F7FAFC", border: "none" },

    subLabelTitleDownload: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      color: "#6D7781",
    },
    LabelTitleDownload: {
      color: "#023365",
      Weight: 500,
      Size: "16px",
      lineHeight: "24px",
      marginTop: theme.spacing(2),
    },
  })
);
