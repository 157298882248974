import React, { FC } from "react";
import Modal from "@mui/material/Modal";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import { useAppSelector } from "../../store/hooks/storeHook";
import { RootState } from "../../store/store";
import { IModalErrorBranchesProps } from "./IModalErrorBranches.interfaces";
import { modalErrorBranchesStyles as styles } from "./ModalErrorBranches.styles";
import { IconCircleCross } from "@kushki/connect-ui";

const ModalErrorBranches: FC<IModalErrorBranchesProps> = ({
  isOpen,
  handleCloseModalError,
}: IModalErrorBranchesProps) => {
  const { errorBranches } = useAppSelector(
    (state: RootState) => state.massiveBranch
  );

  return (
    <Modal open={isOpen}>
      <Box sx={styles.modalContainer}>
        <Box sx={styles.header}>
          <Typography variant={"h5"} sx={styles.title}>
            Error al crear las siguientes branches:
          </Typography>
          <IconButton sx={styles.closeBtn} onClick={handleCloseModalError}>
            <IconCircleCross />
          </IconButton>
        </Box>
        <Divider sx={styles.divider} />
        <Box sx={styles.wrapper}>
          {errorBranches.map((branch, index) => (
            <Box key={index}>
              <li>
                <Typography variant={"body2"} sx={styles.label}>
                  {branch.branchName}
                </Typography>
              </li>
              <Divider sx={styles.divider} />
            </Box>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalErrorBranches;
