import React, { FC } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export interface IInformationSection {
  title: string;
  items: {
    label: string;
    value?: string | number;
  }[];
}

export interface IInformationDetail {
  infoDetail: IInformationSection[];
}

const useStyles = makeStyles({
  wordItem: {
    wordWrap: "break-word",
  },
  row: {
    paddingBlock: "4px",
  },
  title: {
    paddingBottom: "16px",
    paddingTop: "8px",
  },
  textSimple: {
    "&.MuiTypography-root": {
      fontSize: "16px",
      fontWeight: 400,
      color: "#6D7781",
    },
  },
  textLabel: {
    "&.MuiTypography-root": {
      fontSize: "16px",
      fontWeight: 500,
      color: "#023365",
    },
  },
  section: {
    padding: "32px",
    paddingBottom: "0px",
    paddingTop: "16px",
  },
  divider: {
    marginTop: "16px",
    marginBottom: "4px",
    height: "1px",
    background: "#c6c6c6",
  },
});

export const InformationDetail: FC<IInformationDetail> = ({
  infoDetail,
}: IInformationDetail) => {
  const classes = useStyles();

  const renderItem = (label: string, value?: string | number): JSX.Element => (
    <Grid item container className={classes.row}>
      <Grid item xs={6} className={classes.wordItem}>
        <Typography className={classes.textLabel}>{label}:</Typography>
      </Grid>
      <Grid item xs={6} className={classes.wordItem}>
        <Typography className={classes.textSimple}>{value || ""}</Typography>
      </Grid>
    </Grid>
  );

  return (
    <>
      {infoDetail.map((section, index) => (
        <Box key={`iDetail-${index}`} className={classes.section}>
          <Box className={classes.title}>
            <Typography id={"iTitle"} className={classes.textLabel}>
              {section.title}
            </Typography>
          </Box>
          {section.items.map((item) => renderItem(item.label, item.value))}
          {index !== infoDetail.length - 1 && (
            <Box className={classes.divider} />
          )}
        </Box>
      ))}
    </>
  );
};
