import { createSlice } from "@reduxjs/toolkit";
import { searchMerchantNodeInfo } from "../../thunks/mainContainer.thunk";
import { searchMerchantNodeInfoAction } from "../../actions/mainContainer.action";
import { IMainContainerState } from "../../interfaces/mainContainerState.interfaces";

export const initialState: IMainContainerState = {
  isLoadingMerchantNodeInfo: false,
  searchData: {},
};

export const mainContainerSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(searchMerchantNodeInfo.pending, (state) => {
        state.isLoadingMerchantNodeInfo = true;
      })
      .addCase(searchMerchantNodeInfo.fulfilled, searchMerchantNodeInfoAction)
      .addCase(searchMerchantNodeInfo.rejected, (state) => {
        state.isLoadingMerchantNodeInfo = false;
      });
  },
  initialState,
  name: "mainContainer",
  reducers: {},
});

export default mainContainerSlice.reducer;
