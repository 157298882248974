import React from "react";
import { Container, Grid } from "@material-ui/core";
import { DashboardHeader } from "../../components/Header/DashboardHeader";
import { DashboardTabs } from "../../components/DashboardTabs/DashboardTabs";
import { useBillingDashboardState } from "./state/useBillingDashboardState";
import {
  BillingActions,
  getBillingData,
  getHistoricBillingData,
  setLoadingBilling,
  setLoadingHistoric,
  setPagination,
  getFirebaseId,
  getTransactionsBillingFile,
  setCountry,
  setNotification,
  setStatusFlow,
  downloadFile,
  downloadTransactionTableFile,
  setLoadingDownloadEfact,
  downloadPreprocessFileWS,
  setDownloadFileDetail,
  getTransactionsReprocessQuantity,
  reprocessTransactions,
  setIsDownloadingFile,
  downloadFilePDFS3,
  deleteRetention,
  setActiveTabExecutor,
  setHistoricTrx,
  ITransactionDownloadFileHistoric,
  updateDispersionReason,
} from "../../store/actionCreators";
import { ThunkDispatch } from "redux-thunk";
import { Dispatch } from "redux";
import { IBillingDashboardState, INotification } from "../../store/reducer";
import { Filters } from "../../../types/filters";
import { connect } from "react-redux";
import { ITransactionFile } from "../../../types/transaction_file";
import { SearchBillingRequest } from "../../../types/search_billing_request";
import {
  InvoiceRecord,
  ResponseSearchBillingByFilters,
} from "../../../types/remote/response_search_blling_by_filters";
import { SnackbarAlert } from "../../components/Snackbar/SnackbarAlert";
import { ITransactionDownloadFile } from "../../../types/transaction_download_file";
import {
  StatusFlowEnum,
  StatusFlowPath,
} from "../../shared/infrastructure/StatusFlowEnum";
import { UserRolesEnum } from "../../shared/infrastructure/UserRolesEnum";
import { TransactionTable } from "../../../types/transaction_table";
import { Loader } from "@kushki/frontend-molecules/loader";
import { DownloadDocumentParams } from "../../components/DashboardList/Table/DashboardHistoricTable/DashboardHistoricTable";

export interface BilligDashboardFunctionsProps {
  getBillingData: (params: {
    date: string;
    filters: Filters;
    userRole?: UserRolesEnum[];
  }) => void;
  getHistoricBillingData: (
    params: SearchBillingRequest,
    userRole?: UserRolesEnum[]
  ) => void;
  setHistoricTrx: (payload: InvoiceRecord) => BillingActions;
  setLoadingBilling: (payload: boolean) => BillingActions;
  setLoadingHistoric: (payload: boolean) => BillingActions;
  setIsLoadingDownloadFile: (
    payload: ITransactionDownloadFileHistoric
  ) => BillingActions;
  setPagination: (payload: object[]) => BillingActions;
  getFirebaseId: (payload: { body: object }) => void;
  getTransactionsBillingFile: (payload: string) => void;
  setCountry: (payload: string) => void;
  setFileDownloadDetail: (payload: string) => void;
  setReprocessQuantity: () => void;
  reprocessTransactions: () => void;
  setNotification: (payload: INotification) => void;
  setStatusFlow: (payload: StatusFlowEnum) => void;
  downloadFileEfact: (extensionFile: string, invoiceId: string) => void;
  downloadTransactionTableFile: (
    country: string,
    date: string,
    statusFlow: StatusFlowEnum,
    transactionIds?: string[]
  ) => void;
  setLoadingDownloadEfact: (loadingState: boolean) => BillingActions;
  downloadPreprocessFileWS: (params: DownloadDocumentParams) => void;
  downloadFilePDFS3: (invoiceId: string) => void;
  deleteRetention: (retentionId: string, reason: string) => void;
  setActiveTabExecutor: (payload: number) => void;
  setUpdateDispersionReason?: (transactionId: string, payload: string) => void;
}
export interface BillingDashboardStateProps {
  historicBillingData: ResponseSearchBillingByFilters;
  isLoadingBilling: boolean;
  pathLoadingProcessBilling: string;
  isLoadingHistoric: boolean;
  isLoadingUpdateDispersion: boolean;
  pagination: object[];
  firebaseId: string | null;
  transactionsBillingFile: ITransactionFile | null;
  transactionTableFileDownload: ITransactionDownloadFile;
  notification: INotification;
  fileDownloadEfact: string;
  isLoadingDownloadEfact: boolean;
  isLoadingDownloadFile: ITransactionDownloadFileHistoric;
  fileDownloadDetail: string;
  isLoadingFileDetail: boolean;
  isLoadingDownloadPreProcess: boolean;
  isLoadingDeleteRetention?: boolean;
  downloadPdfProps: string;
  downloadPdfLoading: boolean;
  transactionTableCollectionsHistory?: InvoiceRecord | undefined;
  transactionTableCollections?: TransactionTable[];
  reprocessCount?: number;
}

export type BillingDashboardProps = BilligDashboardFunctionsProps &
  BillingDashboardStateProps;

export const BillingDashboard: React.FC<BillingDashboardProps> = (
  props: BillingDashboardProps
) => {
  const {
    data,
    selectProps,
    actions,
    pagination,
    filterProps,
    historicFiltersProps,
    modalOver,
    modalOverBilling,
    modalBackRetention,
    pathLoadingProcessBilling,
    tableColumns,
    sortProps,
    stateRentetionDashboard,
    downloadFileState,
    textArea,
  } = useBillingDashboardState(props);

  const handleCloseNotification = () => {
    props.setNotification({
      open: false,
      message: props.notification.message,
      type: props.notification.type,
    });
  };

  return (
    <React.Fragment>
      <Container fixed>
        {(selectProps.isAdmin || selectProps.isExecutor) && (
          <Grid container spacing={5} direction="column">
            <Grid item xs={12}>
              <DashboardHeader
                country={selectProps.country}
                handleSelectChange={actions.handleSelectChange}
                statusFlow={selectProps.statusFlow}
              />
            </Grid>
            <Grid item xs={12}>
              <DashboardTabs
                stateRentetionDashboard={stateRentetionDashboard}
                checked={selectProps.checked}
                country={selectProps.country}
                filterProps={filterProps}
                handleChangePage={pagination.handleChangePage}
                handleChangePageSize={pagination.handleChangePageSize}
                handleCheckboxChange={actions.handleCheckboxChange}
                hideNext={pagination.hideNext}
                hidePrevious={pagination.hidePrevious}
                historicBillingData={data.historicBillingData}
                historicFilterProps={historicFiltersProps}
                isLoading={selectProps.isLoadingBilling}
                isLoadingProcessBilling={
                  pathLoadingProcessBilling === StatusFlowPath.EXECUTOR
                }
                isLoadingHistoric={selectProps.isLoadingHistoric}
                modalOver={modalOver}
                modalOverBilling={modalOverBilling}
                modalBackRetention={modalBackRetention}
                page={pagination.page}
                pageSize={pagination.pageSize}
                statusFlow={selectProps.statusFlow}
                tableColumns={tableColumns}
                textArea={textArea}
                sortProps={sortProps}
                setActiveTabExecutor={props.setActiveTabExecutor}
                reprocess={{
                  reprocessNumber: props.reprocessCount!,
                  reprocessTransactions: props.reprocessTransactions,
                }}
                downloadFileState={downloadFileState}
              />
            </Grid>
            <SnackbarAlert
              type={props.notification.type}
              msg={props.notification.message}
              open={props.notification.open}
              handlerClose={handleCloseNotification}
            />
          </Grid>
        )}
      </Container>
      <Loader
        open={!!modalOver.isLoadingDeleteRetention}
        headerText={"Estamos procesando la anulación"}
        bodyText={"Este proceso puede tardar un poco..."}
      />
    </React.Fragment>
  );
};

export const mapStateToProps: (
  state: IBillingDashboardState
) => BillingDashboardStateProps = (
  state: IBillingDashboardState
): BillingDashboardStateProps => ({
  isLoadingBilling: state.isLoadingBilling,
  pathLoadingProcessBilling: state.pathLoadingProcessBilling,
  isLoadingHistoric: state.isLoadingHistoric,
  historicBillingData: state.historicBillingData,
  pagination: state.pagination,
  firebaseId: state.firebaseId,
  transactionsBillingFile: state.transactionsBillingFile,
  transactionTableFileDownload: state.transactionTableFileDownload,
  notification: state.notification,
  fileDownloadEfact: state.fileDownloadEfact,
  isLoadingDownloadEfact: state.isLoadingFileDownloadEfact,
  isLoadingDownloadFile: state.isLoadingDownloadFile,
  fileDownloadDetail: state.fileDownloadDetail,
  isLoadingFileDetail: state.isLoadingFileDownloadDetail,
  isLoadingDownloadPreProcess: state.isLoadingFileDownloadDetail,
  reprocessCount: state.reprocessCount,
  downloadPdfProps: state.downloadPdf?.id?.url!,
  downloadPdfLoading: state.downloadPdfLoading,
  transactionTableCollectionsHistory: state.transactionTableCollectionsHistory,
  transactionTableCollections: state.transactionTableCollections,
  isLoadingDeleteRetention: state.isLoadingDeleteRetention,
  isLoadingUpdateDispersion: state.isLoadingUpdateDispersion,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => BilligDashboardFunctionsProps = (
  dispatch: ThunkDispatch<IBillingDashboardState, undefined, BillingActions>
): BilligDashboardFunctionsProps => ({
  getBillingData: (params: {
    date: string;
    filters: Filters;
    userRole?: UserRolesEnum[];
  }): void => dispatch(getBillingData(params)),
  getHistoricBillingData: (
    params: SearchBillingRequest,
    userRole?: UserRolesEnum[]
  ): void => dispatch(getHistoricBillingData(params, userRole)),
  setHistoricTrx: (payload: InvoiceRecord): BillingActions =>
    dispatch(setHistoricTrx(payload)),
  setLoadingBilling: (payload: boolean): BillingActions =>
    dispatch(setLoadingBilling(payload)),
  setLoadingHistoric: (payload: boolean): BillingActions =>
    dispatch(setLoadingHistoric(payload)),
  setPagination: (payload: object[]): BillingActions =>
    dispatch(setPagination(payload)),
  getFirebaseId: (payload: { body: object }): void =>
    dispatch(getFirebaseId(payload)),
  getTransactionsBillingFile: (payload: string): void =>
    dispatch(getTransactionsBillingFile(payload)),
  setCountry: (payload: string): BillingActions =>
    dispatch(setCountry(payload)),
  setFileDownloadDetail: (payload: string): BillingActions =>
    dispatch(setDownloadFileDetail(payload)),
  setNotification: (payload: INotification) => {
    dispatch(setNotification(payload));
  },
  setStatusFlow: (payload: StatusFlowEnum): void => {
    dispatch(setStatusFlow(payload));
  },
  downloadFileEfact: (extensionFile: string, invoiceId: string) => {
    dispatch(downloadFile(extensionFile, invoiceId));
  },
  downloadTransactionTableFile: (
    country: string,
    date: string,
    statusFlow: StatusFlowEnum,
    transactionIds?: string[]
  ) => {
    dispatch(
      downloadTransactionTableFile(country, date, statusFlow, transactionIds)
    );
  },
  setLoadingDownloadEfact: (payload: boolean): BillingActions =>
    dispatch(setLoadingDownloadEfact(payload)),
  setIsLoadingDownloadFile: (
    payload: ITransactionDownloadFileHistoric
  ): BillingActions => dispatch(setIsDownloadingFile(payload)),
  downloadPreprocessFileWS: (params: DownloadDocumentParams) => {
    dispatch(downloadPreprocessFileWS(params));
  },
  setReprocessQuantity: () => dispatch(getTransactionsReprocessQuantity()),
  reprocessTransactions: () => dispatch(reprocessTransactions()),
  downloadFilePDFS3: (invoiceId: string) => {
    dispatch(downloadFilePDFS3(invoiceId));
  },
  deleteRetention: (retentionId: string, reason: string) =>
    dispatch(deleteRetention(retentionId, reason)),
  setActiveTabExecutor: (payload: number) => {
    dispatch(setActiveTabExecutor(payload));
  },
  setUpdateDispersionReason: (transactionId: string, payload: string) => {
    dispatch(updateDispersionReason(transactionId, payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingDashboard);
