export const TABLE_HEADER_TITLE = "Módulos";
export const BUTTON_TEXT = "Agregar módulo";
export const EMPTY_TABLE_TITLE = "Aún no tienes módulos registrados";
export const EMPTY_TABLE_SUBTITLE = "Agrega módulos";
export const TABLE_COMPONENTS_HEADER_TITLE = "Componentes";
export const BUTTON_COMPONENTS_TEXT = "Agregar componente";
export const EMPTY_COMPONENTS_TABLE_TITLE =
  "Aún no tienes componentes registrados";
export const EMPTY_COMPONENT_TABLE_SUBTITLE = "Agrega componentes";
export const SUCCESS_MODULE_MESSAGE = "Se actualizó el módulo exitosamente";
export const SUCCESS_ADD_MODULE_MESSAGE = "Se añadió el módulo exitosamente";
export const SUCCESS_ADD_COMPONENT_MESSAGE =
  "Se añadió el componente exitosamente";
export const SUCCESS_EDIT_COMPONENT_MESSAGE =
  "Se actualizó el componente exitosamente";
export const ERROR_ADD_MODULE_MESSAGE = "Ha ocurrido un error";
export const NEW_MODULE = "Nuevo módulo";
export const EDIT_MODULE = "Editar módulo";
export const NEW_COMPONENT = "Nuevo componente";
export const EDIT_COMPONENT = "Editar componente";
export const ADD_BUTTON = "Agregar";
export const EDIT_BUTTON = "Actualizar";
export const MODULE = "módulo";
export const COMPONENT = "componente";
