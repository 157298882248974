export enum FileStatusEnum {
  DEFAULT = "default",
  UPLOADING = "uploading",
  WITH_FILE = "with_file",
}

export const FileValidation = {
  MAX_SIZE: 5,
};

export enum MessageError {
  MAX_SIZE = "Uno o más archivo(s) supera el tamaño máximo permitido (5MB).",
  TOTAL_MAX_SIZE = "El total de uno o más archivo(s) supera el tamaño máximo permitido (20MB).",
}

export enum DragTypeEnum {
  ENTER = "dragenter",
  OVER = "dragover",
  LEAVE = "dragleave",
}

export enum ErrorAlertEnum {
  TITLE = "Se detectaron algunos errores",
}
