import React, { FC, Fragment } from "react";
import { Checkbox, TableCell, TableRow, Typography } from "@mui/material";
import { ITableBodyProps } from "../../../../shared/interfaces/Table.interfaces";
import { TableBodyStyles } from "./TableBody.styles";
import { isAgent } from "../../../../shared/utils/role_utils";
import { useStateTableBody } from "./state/useTableBodyState";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { SecurityWrapperEnum } from "../../../../shared/enums/SecurityWrapperEnum";

const BackofficeTableBody: FC<ITableBodyProps> = (props: ITableBodyProps) => {
  const { columns, data, onRowClick, rowId } = props;
  const { handleChangeSelectionRefund, handleIsChecked } = useStateTableBody();

  return (
    <>
      {data.map((item, key) => (
        <TableRow key={key}>
          {columns.map((column) => (
            <Fragment key={column.id}>
              {column.type === "checkbox" ? (
                <TableCell
                  padding={column.padding}
                  sx={TableBodyStyles.checkboxCell}
                >
                  <ComponentSecurityWrapper
                    componentId={SecurityWrapperEnum.APPROVE}
                  >
                    <Checkbox
                      onChange={() => handleChangeSelectionRefund(item[rowId])}
                      disabled={isAgent()}
                      sx={TableBodyStyles.checkbox}
                      checked={handleIsChecked(item[rowId])}
                    />
                  </ComponentSecurityWrapper>
                </TableCell>
              ) : (
                <TableCell
                  padding={column.padding}
                  onClick={() => onRowClick && onRowClick(item[rowId])}
                  sx={TableBodyStyles.cell}
                >
                  <Typography sx={TableBodyStyles.label}>
                    {item[column.id]}
                  </Typography>
                  {column.subId && (
                    <Typography sx={TableBodyStyles.subLabel}>
                      {item[column.subId]}
                    </Typography>
                  )}
                </TableCell>
              )}
            </Fragment>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default BackofficeTableBody;
