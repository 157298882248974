import { CountryEnum } from "./CountryEnum";

export type CurrencyType =
  | "USD"
  | "COP"
  | "PEN"
  | "CLP"
  | "MXN"
  | "BRL"
  | "CRC"
  | "GTQ"
  | "HNL"
  | "NIO"
  | "UF"
  | "PAB";

export const enum CurrencyEnum {
  USD = "USD",
  COP = "COP",
  PEN = "PEN",
  CLP = "CLP",
  MXN = "MXN",
  BRL = "BRL",
  CRC = "CRC",
  GTQ = "GTQ",
  HNL = "HNL",
  NIO = "NIO",
  UF = "UF",
  PAB = "PAB",
}

export const CurrencyMapByCountry: Record<CountryEnum, CurrencyEnum> = {
  [CountryEnum.ECUADOR]: CurrencyEnum.USD,
  [CountryEnum.COLOMBIA]: CurrencyEnum.COP,
  [CountryEnum.PERU]: CurrencyEnum.PEN,
  [CountryEnum.CHILE]: CurrencyEnum.CLP,
  [CountryEnum.MEXICO]: CurrencyEnum.MXN,
  [CountryEnum.BRAZIL]: CurrencyEnum.BRL,
  [CountryEnum.COSTA_RICA]: CurrencyEnum.CRC,
  [CountryEnum.EL_SALVADOR]: CurrencyEnum.USD,
  [CountryEnum.GUATEMALA]: CurrencyEnum.GTQ,
  [CountryEnum.HONDURAS]: CurrencyEnum.HNL,
  [CountryEnum.NICARAGUA]: CurrencyEnum.NIO,
  [CountryEnum.PANAMA]: CurrencyEnum.USD,
};

export const COUNTRY_ISO_CODE: Record<CountryEnum, string> = {
  [CountryEnum.ECUADOR]: "EC",
  [CountryEnum.COLOMBIA]: "CO",
  [CountryEnum.PERU]: "PE",
  [CountryEnum.CHILE]: "CL",
  [CountryEnum.MEXICO]: "MX",
  [CountryEnum.BRAZIL]: "BR",
  [CountryEnum.EL_SALVADOR]: "SV",
  [CountryEnum.PANAMA]: "PA",
  [CountryEnum.NICARAGUA]: "NI",
  [CountryEnum.HONDURAS]: "HN",
  [CountryEnum.GUATEMALA]: "GT",
  [CountryEnum.COSTA_RICA]: "CRI",
};
