import { format } from "date-fns";

export const calculateDateUtc = (
  dateTimestamp: number,
  isStart: boolean
): number => {
  const date = new Date(dateTimestamp);

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    isStart ? 0 : 23,
    isStart ? 0 : 59,
    isStart ? 0 : 59
  ).getTime();
};

export const formatDate = (
  dateTimestamp: number,
  isStart: boolean = false,
  dateFormat: string = "dd/MM/yyyy"
): string => {
  if (dateTimestamp === 0) return "";

  return format(
    new Date(calculateDateUtc(dateTimestamp * 1000, isStart)),
    dateFormat
  );
};
