/* istanbul ignore file */
import React, { Suspense, useEffect, useState } from "react";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  BrowserRouter,
  Route,
  RouteComponentProps,
  Switch,
} from "react-router-dom";
import { connect } from "react-redux";
import { routes } from "./shared/infrastructure/constants/routes";
import DevelopersIndex from "./containers/DeveloperIndex/DevelopersIndex";
import ConfigurationIndex from "./containers/ConfigurationIndex/ConfigurationIndex";
import { IAppState, INotification } from "./store/reducer";
import {
  getAuthInfo,
  getAuthMerchant,
  getCardOptions,
  getVposOptions,
  getComissionOptions,
  getSubscriptionOptions,
  getWebCheckout,
  getBillingConfig,
  getMerchant,
  getMerchantCustomization,
  getMerchantProcessors,
  getMerchantWebhooks,
  getTrxList,
  getDiscountInfo,
  getUserList,
  getSmartlinkInfo,
  IAppAction,
  setNotification,
  getAppConfigPaymentMethods,
} from "./store/actionCreators";
import { auth } from "./shared/auth";
import BillingIndex from "./containers/BillingIndex/BillingIndex";
import NotificationsIndex from "./containers/NotificationsIndex/NotificationsIndex";
import AccountPreferencesIndex from "./containers/AccountPreferencesIndex/AccountPreferencesIndex";
import { defaultTo } from "lodash";
import { SnackBarAlert } from "./components/Commons/Snackbar/SnackBarAlert";
import PluginsIndex from "./containers/PluginsIndex/PluginsIndex";

interface INavigationFunctionsProps {
  getAuthInfo: () => void;
  getDiscountInfo: (merchantId: string) => void;
  getMerchant: (merchantId: string) => void;
  getCardOptions: (merchantId: string) => void;
  getWebCheckout: (merchantId: string) => void;
  getVposOptions: (merchantId: string) => void;
  getComissionOptions: (merchantId: string) => void;
  getSubscriptionOptions: (merchantId: string) => void;
  getAuthMerchant: () => void;
  getBillingConfig: (merchantId: string) => void;
  getWebhooks: (merchantId: string) => void;
  getMerchantCustomization: (merchantId: string) => void;
  getMerchantProcessors: (merchantId: string) => void;
  getSmartLink: (merchantId: string) => void;
  setNotification(payload: INotification): void;
  getTrxList: (body: { publicMerchantId: string }) => void;
  getUserList: (merchantId: string) => void;
  getAppConfigPaymentMethods: () => void;
}

interface INavigationStateProps {
  notification: INotification;
}

type TNavigationProps = INavigationStateProps & INavigationFunctionsProps;

const Navigation: React.FC<TNavigationProps> = (props: TNavigationProps) => {
  useEffect(() => {
    props.getAuthInfo();
  }, []);

  const withAuthMerchant = (component: any) => () => {
    props.getAuthMerchant();
    const merchantId: string = defaultTo(
      localStorage.getItem("merchantId"),
      "00000"
    );
    props.getMerchantProcessors(merchantId);
    props.getMerchant(merchantId);
    props.getAppConfigPaymentMethods();
    props.getDiscountInfo(merchantId);
    return component;
  };

  const withNotify = (component: any) => (
    routeProps: RouteComponentProps<any>
  ) => {
    const { merchantId } = routeProps.match.params;
    props.getMerchant(merchantId);
    return component;
  };

  const withCustomization = (component: any) => (
    routeProps: RouteComponentProps<any>
  ) => {
    const { merchantId } = routeProps.match.params;
    const { BackofficeUserMaster } = JSON.parse(
      defaultTo(localStorage.getItem("roles"), "{}")
    );
    const isValidUser = auth.isAdmin() || BackofficeUserMaster;
    if (merchantId && isValidUser) {
      props.getMerchantCustomization(merchantId);
      props.getSmartLink(merchantId);
      props.getUserList(merchantId);
      return component;
    }
  };

  const [merchantId, setMerchantId] = useState<string>("");

  useEffect(() => {
    if (merchantId) {
      props.getMerchantProcessors(merchantId);
      if (auth.isAdmin()) {
        props.getTrxList({ publicMerchantId: merchantId });
        props.getMerchant(merchantId);
        props.getBillingConfig(merchantId);
        props.getCardOptions(merchantId);
        props.getVposOptions(merchantId);
        props.getWebCheckout(merchantId);
        props.getComissionOptions(merchantId);
        props.getSubscriptionOptions(merchantId);
        props.getMerchantCustomization(merchantId);
        props.getWebhooks(merchantId);
        props.getUserList(merchantId);
        props.getDiscountInfo(merchantId);
        props.getAppConfigPaymentMethods();
      } else {
        props.getMerchant(merchantId);
      }
    }
  }, [merchantId]);

  const withMerchant = (component: any) => (
    routeProps: RouteComponentProps<any>
  ) => {
    const { merchantId: id } = routeProps.match.params;
    setMerchantId(id);
    return component;
  };
  const handleCloseSnackbar = () => {
    props.setNotification({
      open: false,
      message: props.notification.message,
      type: props.notification.type,
    });
  };

  return (
    <div>
      <BrowserRouter>
        <Suspense fallback={<div />}>
          <Switch>
            <Route
              path={routes.INDEX}
              exact
              render={withAuthMerchant(<ConfigurationIndex />)}
            />
            <Route path={`${routes.INDEX}/plugins`} component={PluginsIndex} />
            <Route
              path={`${routes.INDEX}/merchant/:merchantId`}
              exact
              render={withMerchant(<ConfigurationIndex />)}
            />
            <Route
              path="/config-merchant/developers"
              component={DevelopersIndex}
            />
            <Route
              path={`${routes.BILLING}/:merchantId`}
              render={withMerchant(<BillingIndex />)}
            />
            <Route
              path={`${routes.PERSONALIZATION}/:merchantId`}
              render={withCustomization(<AccountPreferencesIndex />)}
            />
            <Route
              path={`${routes.TRANSACTION_NOTIFICATION}/:merchantId`}
              render={withNotify(<NotificationsIndex />)}
            />
          </Switch>
        </Suspense>
      </BrowserRouter>
      <SnackBarAlert
        type={props.notification.type}
        msg={props.notification.message}
        open={props.notification.open}
        handlerClose={handleCloseSnackbar}
      />
    </div>
  );
};

export const mapStateToProps: (state: IAppState) => INavigationStateProps = (
  state: IAppState
): INavigationStateProps => ({
  notification: state.notification!,
});

const mapDispatchToProps: (dispatch: Dispatch) => INavigationFunctionsProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): INavigationFunctionsProps => ({
  getAuthInfo: (): void => dispatch(getAuthInfo()),
  getDiscountInfo: (merchantId: string): void =>
    dispatch(getDiscountInfo(merchantId)),
  getMerchant: (merchantId: string): void => dispatch(getMerchant(merchantId)),
  getCardOptions: (merchantId: string): void =>
    dispatch(getCardOptions(merchantId)),
  getVposOptions: (merchantId: string): void =>
    dispatch(getVposOptions(merchantId)),
  getComissionOptions: (merchantId: string): void =>
    dispatch(getComissionOptions(merchantId)),
  getSubscriptionOptions: (merchantId: string): void =>
    dispatch(getSubscriptionOptions(merchantId)),
  getWebCheckout: (merchantId: string): void =>
    dispatch(getWebCheckout(merchantId)),
  getAuthMerchant: (): void => dispatch(getAuthMerchant()),
  getBillingConfig: (merchantId: string): void =>
    dispatch(getBillingConfig(merchantId)),
  getWebhooks: (merchantId: string): void =>
    dispatch(getMerchantWebhooks(merchantId)),
  getMerchantCustomization: (merchantId: string): void =>
    dispatch(getMerchantCustomization(merchantId)),
  setNotification: (payload: INotification) => {
    dispatch(setNotification(payload));
  },
  getSmartLink: (merchantId: string): void =>
    dispatch(getSmartlinkInfo(merchantId)),
  getMerchantProcessors: (merchantId: string): void =>
    dispatch(getMerchantProcessors(merchantId)),
  getTrxList: (body: { publicMerchantId: string }): void =>
    dispatch(getTrxList(body)),
  getUserList: (merchantId: string): void => dispatch(getUserList(merchantId)),
  getAppConfigPaymentMethods: (): void =>
    dispatch(getAppConfigPaymentMethods()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
