import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import errorCsv from "../../../assets/image/csv-error.svg";
import circleError from "../../../assets/image/x-circle.svg";
//import errorCsv from "../../../assets/image/error.svg";

const useStyles = makeStyles((theme: Theme) => ({
  center: {
    display: "block",
    "margin-left": "auto",
    "margin-right": "auto",
    width: "50%",
  },
  labelTextInfo: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: "ibm-plex-sans",
    fontSize: "25px",
    color: theme.palette.primary.main,
    fontWeight: 300,
  },
}));

export interface ErrorFileUploadComponentProps {
  handleOpenError: () => void;
  messageErrorCsv: string;
}

export interface ErrorMessageProps {
  handleOpenError: () => void;
  messageErrorCsv: string;
}

export const ErrorFileUploadComponent: React.FC<ErrorFileUploadComponentProps> = (
  props: ErrorFileUploadComponentProps
) => {
  const handleSnackbar = () => {
    props.handleOpenError();
  };
  const classes = useStyles();
  return (
    <Card>
      <CardContent
        style={{
          padding: "50px",
        }}
      >
        <Container>
          <img
            className={classes.center}
            src={errorCsv}
            alt="errorCsv"
            style={{
              width: "auto",
              maxHeight: "300px",
            }}
          />
          <Typography
            className={classes.labelTextInfo}
            variant="h5"
            align="center"
          >
            Se ha producido un error al procesar tu archivo
          </Typography>
          <Typography
            className={classes.labelTextInfo}
            variant="h6"
            align="center"
          >
            <img
              className={classes.labelTextInfo}
              src={circleError}
              alt="circleError"
              style={{
                width: "auto",
                maxHeight: "300px",
                marginRight: "10px",
                marginBottom: "-30px",
              }}
            />
            {props.messageErrorCsv}
          </Typography>
          <Box textAlign="center">
            <Button
              variant={"contained"}
              id="returnbtn"
              size="large"
              color="primary"
              onClick={handleSnackbar}
            >
              Regresar
            </Button>
          </Box>
        </Container>
      </CardContent>
    </Card>
  );
};
