import React from "react";
import { applyMiddleware, compose, createStore, Store } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { environment } from "./environments/environment";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
// @ts-ignore
import { reducer } from "./store/reducer";
import { CssBaseline } from "@material-ui/core";
import { Routes } from "./shared/infrastructure/routes";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { WalletDashboardIndex } from "./containers/WalletDashboardIndex/WalletDashboardIndex";

const composeEnhancers = environment.devTools
  ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose;

const store: Store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunk))
);

const Root = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter basename={Routes.BASE_PATH_WALLET_DASHBOARD}>
            <Switch>
              <Route
                exact
                path={Routes.INDEX}
                component={WalletDashboardIndex}
              />
            </Switch>
          </BrowserRouter>
          <CssBaseline />
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
};

export default Root;
