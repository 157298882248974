import React, { FC } from "react";
import { IWrapperContainerProps } from "./WrapperContainer.interfaces";
import { useWrapperContainer } from "./state/useWrapperContainer";

export const WrapperContainer: FC<IWrapperContainerProps> = ({
  children,
  single,
}: IWrapperContainerProps) => {
  const {} = useWrapperContainer({ single });

  return <>{children}</>;
};
