import {
  ModalContent,
  ModalContentButtonTypeEnum,
  ModalContentTypeEnum,
} from "@kushki/connect-ui";
import {
  Box,
  FormControl,
  FormControlLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { CodeEditor } from "./CodeEditor/CodeEditor";
import { LoadingComponent } from "./LoadingComponent/LoadingComponent";
import { useWebhookSimulationModal } from "./useWebhookSimulationModal";
import { Webhook } from "../../types/post_webhooks_list_response";
import React, { FC } from "react";

interface Props {
  open?: boolean;
  handleClose: () => void;
  webhook?: Webhook | null;
}

export const WebhookSimulationModal: FC<Props> = ({
  open = true,
  handleClose,
  webhook,
}) => {
  if (!webhook) return <></>;

  const {
    disableSimulateConnectionButton,
    editor,
    handleSubmit,
    paymentMethod,
    testConnectionResult,
    transactionType,
  } = useWebhookSimulationModal({ handleClose, webhook });

  return (
    <ModalContent
      buttonAction={handleSubmit}
      buttonText={"Simular conexión"}
      buttonType={ModalContentButtonTypeEnum.PRIMARY}
      buttonSecondaryText={"Cancelar"}
      descriptionText={""}
      buttonPrimaryDisabled={disableSimulateConnectionButton}
      isOpen={open}
      onClose={handleClose}
      titleText={"Simulación de conexión"}
      type={ModalContentTypeEnum.DEFAULT}
    >
      <Typography variant="body2">
        Selecciona el medio de pago o producto y el evento.
      </Typography>
      <Box paddingY="1.5rem">
        <FormControl>
          <Select
            displayEmpty
            input={<OutlinedInput />}
            inputProps={{ "aria-label": "Without label" }}
            placeholder="Medio de pago"
            onChange={paymentMethod.handleChange}
            renderValue={(selected: string | undefined) => {
              if (!selected) {
                return <div>Medio de pago</div>;
              }

              return selected;
            }}
            value={paymentMethod.value}
            sx={{ width: "27rem" }}
          >
            {paymentMethod.fields.map((field) => (
              <MenuItem key={field} value={field}>
                {field}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Typography variant="body2">
        Detalla al código correspondiente según el medio que deseas simular.
      </Typography>
      <RadioGroup row>
        {transactionType.fields.map(({ value, label }) => (
          <FormControlLabel
            key={label}
            value={value}
            control={<Radio />}
            label={label}
            onChange={transactionType.handleChange}
            sx={{
              "& span.MuiFormControlLabel-label": {
                fontSize: "0.875rem !important",
              },
            }}
          />
        ))}
      </RadioGroup>
      <CodeEditor {...editor} />

      {testConnectionResult?.loading && <LoadingComponent />}
    </ModalContent>
  );
};
