import React from "react";
import { AppBar, Container, Tab, Tabs } from "@material-ui/core";
import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
import { TitlePage } from "../../components/common/TitlePage/TitlePage";
import { useRetentionsDashboardState } from "./state/useRetentionDashboardState";
import { DashboardTable } from "../../components/Table/DashboardTable/DashboardTable";
import { SubTitlePage } from "../../components/common/SubTitlePage/SubTitlePage";
import { capitalize, defaultTo, includes, isEmpty, get, maxBy } from "lodash";
import { UploadSelectable } from "../../components/UploadSelectable/UploadSelectable";
import { CountryValueEnum } from "../../shared/infrastructure/Enums/CountryEnum";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/reducer";
import { CatalogListComponent } from "../../components/CatalogListComponent/CatalogListComponent";
import { environment } from "../../environments/environment";
import { TabEnum } from "../../shared/infrastructure/Enums/TabEnum";
import { CatalogPopup } from "../../components/CatalogPopup/CatalogPopup";

export const RetentionDashboard: React.FC = () => {
  const showCatalogTab: boolean = defaultTo(
    get(environment, "showCatalogTab"),
    true
  );
  const {
    catalogsList,
    isOpenCatalogConfirmPopup,
    isOpenCatalogFormPopup,
    isOpenCatalogInfoPopup,
    catalogRequest,
    isLoading,
  } = useSelector((state: IAppState) => state);
  const {
    breadCrumbs,
    country,
    data,
    pagination,
    filterProps,
    modalDeleteProps,
    tabTableDashboard,
    handleSetFiltersInput,
    securityAzure,
  } = useRetentionsDashboardState();

  function a11yProps(index: number) {
    return {
      id: `dashboard-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const dateMax = maxBy(data.retentionsData, (r) => r.created);

  const catalogTabSelected = (value: number) => {
    return value === TabEnum.CATALOG && showCatalogTab;
  };

  return (
    <React.Fragment>
      <Container fixed>
        <Breadcrumb {...breadCrumbs} />
        <TitlePage
          title={"Impuestos y retenciones para comercios"}
          country={country!}
          dateMax={get(dateMax, "created", 0)}
        />

        {includes([CountryValueEnum.Colombia], capitalize(country!)) && (
          <>
            {data.isLoading ||
            !isEmpty(data.retentionsData) ||
            filterProps.counter > 0 ? (
              <>
                <SubTitlePage
                  country={country! as CountryValueEnum}
                  isCatalogTab={
                    tabTableDashboard.valueTabTable === TabEnum.CATALOG
                  }
                />
                <DashboardTable
                  isFilterProps={true}
                  isPaginatorProps={true}
                  isLoading={data.isLoading}
                  tableColumns={data.retentionColumns}
                  retentionData={data.retentionsData}
                  onHandleEditAction={data.onHandleEditAction}
                  filterProps={filterProps}
                  pagination={pagination}
                  modalDeleteProps={modalDeleteProps}
                  country={country!}
                  handleSetFiltersInput={handleSetFiltersInput}
                />
              </>
            ) : (
              <UploadSelectable country={country!} />
            )}
          </>
        )}

        {includes([CountryValueEnum.Ecuador], capitalize(country!)) && (
          <>
            <React.Fragment>
              <SubTitlePage
                country={country!}
                isCatalogTab={
                  tabTableDashboard.valueTabTable === TabEnum.CATALOG
                }
              />
              <AppBar
                position="static"
                color="transparent"
                elevation={0}
                style={{ paddingBottom: "30px" }}
              >
                <Tabs
                  value={tabTableDashboard.valueTabTable}
                  onChange={(_event, value) =>
                    tabTableDashboard.handleChangeTableDashboard(value)
                  }
                  aria-label="dashboard retention tabs example"
                  variant="fullWidth"
                  indicatorColor={"primary"}
                  centered={true}
                >
                  <Tab
                    label="Retención IVA"
                    {...a11yProps(0)}
                    style={{ textTransform: "none" }}
                  />
                  <Tab
                    label="Retención Renta"
                    style={{ textTransform: "none" }}
                    {...a11yProps(1)}
                  />
                  {showCatalogTab && (
                    <Tab
                      label="Catálogos"
                      style={{ textTransform: "none" }}
                      {...a11yProps(2)}
                    />
                  )}
                </Tabs>
              </AppBar>
              {tabTableDashboard.valueTabTable !== TabEnum.CATALOG && (
                <DashboardTable
                  isFilterProps={true}
                  isPaginatorProps={true}
                  isLoading={data.isLoading}
                  tableColumns={data.retentionColumns}
                  retentionData={data.retentionsData}
                  onHandleEditAction={data.onHandleEditAction}
                  filterProps={filterProps}
                  pagination={pagination}
                  modalDeleteProps={modalDeleteProps}
                  securityAzure={securityAzure}
                  retentionType={data.retentionType}
                />
              )}
              {catalogTabSelected(tabTableDashboard.valueTabTable) && (
                <CatalogListComponent listCatalog={catalogsList!} />
              )}
            </React.Fragment>
          </>
        )}
      </Container>
      <CatalogPopup
        isLoading={defaultTo(isLoading, false)}
        isOpenCatalogFormPopup={defaultTo(isOpenCatalogFormPopup, false)}
        isOpenCatalogInfoPopup={defaultTo(isOpenCatalogInfoPopup, false)}
        isOpenCatalogConfirmPopup={defaultTo(isOpenCatalogConfirmPopup, false)}
        catalogsList={catalogsList!}
        catalogRequest={catalogRequest!}
      />
    </React.Fragment>
  );
};
