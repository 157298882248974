import { makeStyles } from "@mui/styles";

export const AlertPanelStyles = makeStyles({
  container: {
    borderRadius: "10px",
    margin: 0,
  },
});
export const AlertPanelStylesBackground = makeStyles({
  container: {
    background: "#EDF1F2",
    color: "#67717A",
    "& .MuiAlert-icon": {
      color: "#67717A",
    },
  },
});
