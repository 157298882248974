/**
 * Catalog Nicaragua
 */
import { Category } from "../../interfaces/category";
import { CatalogsEnum } from "../../enums/CatalogsEnum";
import { CurrencyEnum } from "../../enums/currencyEnum";

export const CatalogNi: Record<string, Category[]> = {
  [CatalogsEnum.Banks]: [
    {
      name: "BANCO DE LA PRODUCCIÓN (BANPRO)",
      value: "2001",
    },
    {
      name: "BANCO DE CRÉDITO CENTROAMERICANO (BANCENTRO)",
      value: "2002",
    },
    {
      name: "BANCO DE AMÉRICA CENTRAL (BAC)",
      value: "2003",
    },
    {
      name: "BANCO DE FINANZAS (BDF)",
      value: "2004",
    },
    {
      name: "BANCO FICOHSA DE NICARAGUA",
      value: "2005",
    },
    {
      name: "BANCO AVANZ",
      value: "2006",
    },
    {
      name: "FINANCIERA FAMA",
      value: "2007",
    },
  ],
  [CatalogsEnum.Currency]: [
    {
      name: `${CurrencyEnum.NIO} - Córdobas`,
      value: CurrencyEnum.NIO,
    },
    {
      name: `${CurrencyEnum.USD} - Dólares`,
      value: CurrencyEnum.USD,
    },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { name: "Corriente", value: "0" },
    { name: "Ahorros", value: "1" },
  ],
};
