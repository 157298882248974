import React from "react";
import { get } from "lodash";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { X } from "react-feather";
import { OriginEnum } from "../../shared/infrastructure/OriginEnum";
import { TransactionData } from "../../../types/transaction_data";
import { ActionModal } from "../ActionModal/ActionModal";
import { ActionModalContentEnum } from "../../shared/infrastructure/ActionModalContentEnum";
import { useOmittedTransactionModal } from "./state/useOmittedTransactionModalState";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { CloudSecurityEnum } from "../../shared/infrastructure/CloudSecurityEnum";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonReconcile: {
      color: theme.palette.background.default,
      backgroundColor: theme.palette.primary.main,
      "&:disabled": {
        color: theme.palette.background.default,
        backgroundColor: theme.palette.primary.main,
        opacity: 0.5,
      },
    },
    closeButton: {
      color: theme.palette.primary.dark,
      "&.MuiIconButton-root": {
        padding: 3,
        marginBottom: 12,
        marginTop: 0,
        marginRight: 0,
        marginLeft: 24,
      },
    },
    dialogTitle: {
      paddingTop: 16,
      paddingRight: 24,
      paddingBottom: 16,
      paddingLeft: 24,
    },
    dialogContentText: {
      marginBottom: 24,
    },
    dialogContentTextStyle: {
      fontSize: "15px",
      fontWeight: "normal",
      color: "#6D7781",
    },
    dialogContentComment: {
      color: "#6D7781",
      fontSize: "16px",
      fontWeight: 500,
    },
    dialogContentCommentIcon: {
      color: "#6D7781",
      fontSize: "16px",
      fontWeight: 500,
      paddingLeft: "5px",
    },
    dialogInstructionTF: {
      color: "#6D7781",
      fontSize: "14px",
      fontWeight: 500,
    },
    dialogActions: {
      paddingRight: 24,
      paddingBottom: 12,
    },
    primaryColor: {
      color: theme.palette.primary.main,
    },
    errorColor: {
      color: theme.palette.error.main,
    },
    cancelAction: {
      color: theme.palette.primary.dark,
      border: "1px solid #CBD5E0",
      padding: "6px 16px",
    },
    titleFont: {
      color: theme.palette.primary.main,
      fontSize: 24,
    },
    textField: {
      "& .MuiInputBase-root": {
        height: "auto",
      },
    },
    dialogInstructionRed: {
      color: "#D62C4B",
      fontSize: "14px",
      fontcolor: "#D62C4B",
      fontWeight: 500,
    },
  })
);

export interface ConfirmModalProps {
  open: boolean;
  cancelText?: string;
  acceptColor?: "primary" | "error";
  fullWidth?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  handleClose: () => void;
  handleAccept?: () => void;
  trxSelectedCheck: TransactionData[];
  trxNoSelectedCheck: TransactionData[];
  conciliationMasive: boolean;
  conciliation: OriginEnum;
}

export const OmmitedTransactionRegisterModel: React.FC<ConfirmModalProps> = (
  props: React.PropsWithChildren<ConfirmModalProps>
) => {
  const {
    count,
    totalCheck,
    message,
    setComment,
    handleAlertModalSubmit,
    handleCloseAlertModal,
    openAlertModal,
    submit,
    actionModalProps,
  } = useOmittedTransactionModal(props);
  const classes = useStyles(props);

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        fullWidth={props.fullWidth}
        maxWidth={props.maxWidth}
        aria-labelledby="alert-dialo g-title"
        aria-describedby="alert-dialog-description"
      >
        <Box className={classes.dialogTitle} display="flex" flexGrow={1}>
          <Box display="flex" alignItems="center" flexGrow={1}>
            <Typography
              id={"my_top"}
              variant="h5"
              color="primary"
              className={classes.titleFont}
            >
              {props.conciliation === OriginEnum.TRANSACTIONS
                ? props.conciliationMasive
                  ? "Omitir transacciones"
                  : "Omitir transacción"
                : "Omitir registro"}
            </Typography>
          </Box>
          <Box display="flex" alignItems="flex-start">
            <IconButton
              id={"close_buttton"}
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                props.handleClose();
                setComment("");
              }}
            >
              <X size={18} />
            </IconButton>
          </Box>
        </Box>
        <DialogContent>
          <Typography
            id={"description"}
            style={{
              fontSize: "15px",
              fontWeight: "normal",
              color: "#6D7781",
            }}
          >
            {props.conciliationMasive ? (
              <div>
                Se omitirán <b>{get(props, "trxSelectedCheck", []).length}</b>{" "}
                transacciones por un monto de <b>{totalCheck.toFixed(2)} MXN</b>
                . Te quedan{" "}
                <b>
                  {get(props, "trxNoSelectedCheck", []).length} transacciones
                  pendientes.
                </b>
              </div>
            ) : (
              ""
            )}
          </Typography>
          <Typography
            className={classes.dialogContentComment}
            style={{ marginTop: "20px" }}
          >
            {props.conciliation === OriginEnum.TRANSACTIONS
              ? "Ingrese el motivo por el cual se omite la transacción:"
              : "Ingrese el motivo por el cual se omite el registro"}
          </Typography>
          <div className={classes.textField}>
            <TextField
              id={"comment"}
              error={count > 100}
              placeholder={"Comentario"}
              fullWidth
              multiline
              InputProps={{
                className: `${count > 100 ? classes.dialogInstructionRed : ""}`,
              }}
              value={message}
              rows="1"
              rowsMax="10"
              margin="normal"
              variant="outlined"
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
          <Typography className={classes.dialogInstructionTF}>
            {count > 100
              ? "Mas de 100 caracteres"
              : `Máximo 100 caracteres (${100 - count} restantes)`}
          </Typography>
          {props.children}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            id={"closedButton"}
            onClick={() => {
              props.handleClose();
              setComment("");
            }}
            className={classes.cancelAction}
          >
            {props.cancelText}
          </Button>
          <ComponentSecurityWrapper componentId={CloudSecurityEnum.OMIT}>
            <Button
              onClick={submit}
              autoFocus
              color="primary"
              variant="contained"
              disabled={count === 0 || count > 100}
              className={classes.buttonReconcile}
            >
              <Typography>{"Omitir"}</Typography>
            </Button>
          </ComponentSecurityWrapper>
        </DialogActions>
      </Dialog>
      <ActionModal
        title={actionModalProps.title}
        message={ActionModalContentEnum.message}
        openDialog={openAlertModal}
        buttonLbl={actionModalProps.buttonLbl}
        onCancel={handleCloseAlertModal}
        submitAction={handleAlertModalSubmit}
      />
    </React.Fragment>
  );
};

OmmitedTransactionRegisterModel.defaultProps = {
  acceptColor: "error",
  cancelText: "Cancelar",
  fullWidth: true,
  maxWidth: "sm",
};
