export enum ErrorMessageEnum {
  EMAIL = "Debe ser un email válido",
  USERNAME_PATTERN = "Debe tener entre 1 y 40 caracteres alfanuméricos y sin espacios",
  PATTERN_ALPHA = "Debe tener entre 1 y 40 caracteres alfanuméricos",
  REQUIRED = "El campo es obligatorio",
  REQUIRED_ROLE = "Debe seleccionar un tipo de usuario",
  MERCHANT_NAME_ASSOCIATED = "Debe seleccionar un tipo de usuario",
  EMAIL_DOMAIN = "El email no pertenece al dominio",
  UNAVAILABLE_USER = "Nombre no disponible",
  ERROR_SAVE_USER = "Algo salió mal! No pudimos cargar los usuarios",
  ERROR_WITH_SUPPORT_SESSION = "No es posible realizar ninguna acción en modo Asistencia Remota",
  ERROR_SAVE_SEMAPHORE = "Algo salió mal! No pudimos actualizar el estado en el semaforo ",
}
