import { RefObject, useEffect, useState } from "react";
import { BillingIndexProps } from "../BillingIndex";
import { MerchantResponse } from "../../../../types/merchant_response";
import { set, isEmpty, indexOf, includes } from "lodash";
import { BillingConfig } from "../../../../types/billing_config";
import { Control, FieldError, NestDataObject, useForm } from "react-hook-form";
import { CountriesEnum } from "../../../shared/infrastructure/constants/countries-enum";
import { routes } from "../../../shared/infrastructure/constants/routes";

export interface BillingState {
  prop: LocalState;
  handler: {
    editBilling: () => void;
    editDispersion: () => void;
    close: (_event?: React.SyntheticEvent, reason?: string) => void;
    onPreview: () => void;
    onAddAccount: () => void;
  };
  merchant: MerchantResponse;
  billingConfig: BillingConfig;
  merchantCountryWithDispersion: () => boolean;
  form: {
    errors: NestDataObject<Record<string, any>, FieldError>;
    register: (
      obj: object
    ) => ((instance: any) => void) | RefObject<any> | null | undefined;
    submitBilling: () => void;
    formChange: (
      state: string,
      name: string,
      value: string | boolean | number
    ) => void;
    getValues: (payload?: { nest: boolean } | undefined) => Record<string, any>;
    control: Control<Record<string, any>>;
    submitDispersion: () => void;
  };
}
export interface LocalState {
  isLoading: boolean;
  isEditBilling: boolean;
  isEditDispersion: boolean;
  isSaving: boolean;
  addAccount: boolean;
}

export const useBillingIndexState = (
  props: BillingIndexProps
): BillingState => {
  const { register, errors, triggerValidation, getValues, control } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const countriesWithDispersion: CountriesEnum[] = [
    CountriesEnum.PERU,
    CountriesEnum.CHILE,
  ];
  const [merchantState, setMerchantState] = useState<MerchantResponse>(
    props.state.merchant!
  );
  const [billingConfigState, setBillingConfigState] = useState<BillingConfig>(
    props.state.billingConfig!
  );
  const [state, setState] = useState<LocalState>({
    isLoading: true,
    isEditDispersion: false,
    isEditBilling: false,
    isSaving: false,
    addAccount: false,
  });

  const onEditBilling = (): void => {
    setState({
      ...state,
      isEditBilling: true,
    });
  };
  const onEditDispersion = (): void => {
    setState({
      ...state,
      isEditDispersion: true,
    });
  };
  const onPreview = (): void => {
    if (state.isEditBilling || state.isEditDispersion) {
      setState({
        ...state,
        isEditDispersion: false,
        isEditBilling: false,
        addAccount: false,
      });
      return;
    }
    window.location.href = `${routes.INDEX}/merchant/${props.state.merchant?.publicMerchantId}`;
  };
  const onAddAccount = (): void => {
    const current_merchant: MerchantResponse = { ...merchantState };
    if (current_merchant.dispersion!.accountInfo!.length < 2) {
      current_merchant.dispersion!.accountInfo!.push({});
    }
    setMerchantState(current_merchant);
    setState({ ...state, addAccount: !state.addAccount });
  };
  const handleFormChange = (
    state: string,
    name: string,
    value: string | boolean | number
  ): void => {
    if (state === "merchant") {
      const current_merchant: MerchantResponse = { ...merchantState };

      if (
        current_merchant.country === CountriesEnum.PERU &&
        name === "dispersion.accountInfo[0].currency"
      ) {
        set(
          current_merchant,
          "dispersion.accountInfo[1].currency",
          value === "PEN" ? "USD" : "PEN"
        );
      }
      if (
        current_merchant.country === CountriesEnum.PERU &&
        name === "dispersion.accountInfo[1].currency"
      ) {
        set(
          current_merchant,
          "dispersion.accountInfo[0].currency",
          value === "PEN" ? "USD" : "PEN"
        );
      }
      set(current_merchant, name, value);
      setMerchantState(current_merchant);
    }
    if (state === "billingConfig") {
      const current_billing_config: BillingConfig = { ...billingConfigState };

      if (name === "valuesBilling.statusApproved")
        set(
          current_billing_config,
          name,
          !current_billing_config.valuesBilling.statusApproved
        );
      else if (name === "valuesBilling.statusDeclined")
        set(
          current_billing_config,
          name,
          !current_billing_config.valuesBilling.statusDeclined
        );
      else if (name === "valuesSubtractBilling.statusApproved")
        set(
          current_billing_config,
          name,
          !current_billing_config.valuesSubtractBilling.statusApproved
        );
      else if (name === "valuesSubtractBilling.statusDeclined")
        set(
          current_billing_config,
          name,
          !current_billing_config.valuesSubtractBilling.statusDeclined
        );
      else if (name === "valuesBilling.elementsApproved") {
        const element_approved: string[] =
          current_billing_config.valuesBilling.elementsApproved;
        set(
          current_billing_config,
          name,
          setElements(element_approved, String(value))
        );
      } else if (name === "valuesSubtractBilling.elementsApproved") {
        const element_approved: string[] =
          current_billing_config.valuesSubtractBilling.elementsApproved;
        set(
          current_billing_config,
          name,
          setElements(element_approved, String(value))
        );
      } else if (name === "valuesBilling.elementsDeclined") {
        const element_approved: string[] =
          current_billing_config.valuesBilling.elementsDeclined;
        set(
          current_billing_config,
          name,
          setElements(element_approved, String(value))
        );
      } else if (name === "valuesSubtractBilling.elementsDeclined") {
        const element_approved: string[] =
          current_billing_config.valuesSubtractBilling.elementsDeclined;
        set(
          current_billing_config,
          name,
          setElements(element_approved, String(value))
        );
      } else set(current_billing_config, name, value);

      setBillingConfigState(current_billing_config);
    }
  };
  const setElements = (elements: string[], value: string): string[] => {
    let element_approved: string[] = [...elements];
    const exist_element: boolean = indexOf(element_approved, value) !== -1;

    if (exist_element)
      element_approved.splice(indexOf(element_approved, String(value)), 1);
    else element_approved = [...element_approved, value];

    return element_approved;
  };

  const onSubmitBilling = (): void => {
    setState({ ...state, isSaving: true });
    triggerValidation().then(() => {
      if (!isEmpty(errors))
        if (errors.chargeMinAmount) {
          if (merchantState.chargeMin) return;
        } else return;
      props.updateMerchantAndBillingConfig(
        getUpdateMerchantState(),
        billingConfigState
      );
    });
  };
  const onSubmitDispersion = (): void => {
    setState({ ...state, isSaving: true, addAccount: false });
    triggerValidation().then(() => {
      if (!isEmpty(errors))
        if (errors.chargeMinAmount) {
          if (merchantState.chargeMin) return;
        } else return;
      props.updateDispersion(getUpdateMerchantState());
    });
  };
  const close = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    props.setNotification({ open: false, message: "", type: "success" });
  };

  const getUpdateMerchantState = (): MerchantResponse => {
    return {
      ...merchantState,
      dispersion: merchantCountryWithDispersion()
        ? merchantState.dispersion
        : undefined,
      dispersionFrequency: merchantCountryWithDispersion()
        ? merchantState.dispersionFrequency
        : undefined,
    };
  };

  const merchantCountryWithDispersion = (): boolean => {
    return includes(countriesWithDispersion, merchantState.country);
  };

  useEffect(() => {
    const current_merchant: MerchantResponse = { ...props.state.merchant! };
    const current_billing_config: BillingConfig = {
      ...props.state.billingConfig!,
    };

    if (!isEmpty(current_merchant)) {
      setState({ ...state, isLoading: false });
      if (current_billing_config.publicMerchantId === "") {
        set(
          current_billing_config,
          "publicMerchantId",
          current_merchant.publicMerchantId
        );
        set(current_billing_config, "country", current_merchant.country);
        setBillingConfigState(current_billing_config);
      }
    }

    if (isEmpty(current_merchant.dispersion)) {
      set(current_merchant, "dispersion", {
        accountInfo: [],
      });
    }

    setMerchantState(current_merchant);
  }, [props.state.merchant]);
  useEffect(() => {
    const current_merchant: MerchantResponse = { ...props.state.merchant! };

    if (!isEmpty(current_merchant)) setState({ ...state, isLoading: false });
    setBillingConfigState(props.state.billingConfig!);
  }, [props.state.billingConfig]);
  useEffect(() => {
    setState({
      ...state,
      isSaving: false,
      isEditDispersion: false,
      isEditBilling: false,
    });
  }, [props.state.notification]);

  return {
    prop: state,
    handler: {
      editBilling: onEditBilling,
      editDispersion: onEditDispersion,
      close,
      onPreview,
      onAddAccount,
    },
    merchant: merchantState,
    billingConfig: billingConfigState,
    merchantCountryWithDispersion,
    form: {
      errors,
      register,
      getValues,
      submitBilling: onSubmitBilling,
      submitDispersion: onSubmitDispersion,
      formChange: handleFormChange,
      control: control,
    },
  };
};
