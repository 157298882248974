import { theme } from "@kushki/connect-ui";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import BillingContainer from "./containers/BillingContainer/BillingContainer";
import DispersionContainer from "./containers/DispersionContainer/DispersionContainer";
import { ROUTES } from "./shared/constants/routes";
import { store } from "./store/store";
import { M_CLIENTS } from "./shared/constants/labels/main_containter_labels";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
import { environment } from "./environments/environment";
import ChargeContainer from "./containers/ChargeContainer/ChargeContainer";
import MassiveBillingContainer from "./containers/MassiveBillingContainer/MassiveBillingContainer";
import MassiveChargeContainer from "./containers/MassiveChargeContainer/MassiveChargeContainer";
import MassiveDispersionContainer from "./containers/MassiveDispersionContainer/MassiveDispersionContainer";
import { Provider as RollbarProvider } from "@rollbar/react";
import { getRollbarConfig } from "./shared/constants/GetRollbarConfig";

const Root = () => {
  return (
    <React.StrictMode>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&display=swap');"
        }
      </style>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500&display=swap');"
        }
      </style>
      <RollbarProvider config={getRollbarConfig}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <BrowserRouter basename={"/financial-configuration"}>
              <ModuleSecurityWrapper
                basePath={environment.kushkiUrl}
                componentId={M_CLIENTS}
              >
                <Routes>
                  <Route path={ROUTES.BILLING} element={<BillingContainer />} />
                  <Route
                    path={ROUTES.MASSIVE_BILLING}
                    element={<MassiveBillingContainer />}
                  />
                  <Route
                    path={ROUTES.DISPERSION}
                    element={<DispersionContainer />}
                  />
                  <Route
                    path={ROUTES.MASSIVE_DISPERSION}
                    element={<MassiveDispersionContainer />}
                  />
                  <Route path={ROUTES.CHARGE} element={<ChargeContainer />} />
                  <Route
                    path={ROUTES.MASSIVE_CHARGE}
                    element={<MassiveChargeContainer />}
                  />
                  <Route path={"*"} element={<BillingContainer />} />*
                </Routes>
              </ModuleSecurityWrapper>
            </BrowserRouter>
          </ThemeProvider>
        </Provider>
      </RollbarProvider>
    </React.StrictMode>
  );
};

export default Root;
