import { Grid, Link, Typography } from "@material-ui/core";
import {
  TabContentBlockProps,
  TimelineIconEnum,
} from "../TabContentBlock.interfaces";
import { ArrowsIcon } from "./ArrowsIcon";
import { get } from "lodash";
import { NTHRZDIcon } from "./NTHRZDIcon";
import { PNDNGIcon } from "./PNDNGIcon";
import React from "react";
import { RTRYIcon } from "./RTRYIcon";
import { SimpleDialog } from "./SimpleDialog";
import { THRZDIcon } from "./THRZDIcon";
import { useStyles } from "../../DetailsCard.styles";
import { useTimelineState } from "./TimeLineRowBlockState";

export const TimeLineRowBlock = (props: TabContentBlockProps) => {
  const classes = useStyles();

  const icon: TimelineIconEnum = get(props, "timeLine.icon", "");
  const title: string = get(props, "timeLine.title", "");
  const user: string = get(props, "timeLine.user", "");
  const date: string = get(props, "timeLine.date", "");
  const time: string = get(props, "timeLine.time", "");
  const allowedToShowDetails: TimelineIconEnum[] = [
    TimelineIconEnum.DCLRFN,
    TimelineIconEnum.APRRFN_DECLINED,
    TimelineIconEnum.OMT,
    TimelineIconEnum.GBCK,
  ];
  const showDetails: boolean = !allowedToShowDetails.includes(icon);

  const { handleOpen, handleClose, openDialog, titleDialog, dateTitle } =
    useTimelineState({ icon, user });

  return (
    <>
      <Grid
        hidden={props.selectedTab !== props.tabIndex}
        container
        xs={12}
        className={classes.tlRow}
      >
        <Grid item md={3} className={classes.tlContainerIcon}>
          {[
            TimelineIconEnum.RPY,
            TimelineIconEnum.GBCK,
            TimelineIconEnum.CNCL,
            TimelineIconEnum.OMT,
            TimelineIconEnum.APRRFN_SEND,
            TimelineIconEnum.APRGBCK_SEND,
            TimelineIconEnum.APRCNL_SEND,
          ].includes(icon) && <PNDNGIcon />}
          {[
            TimelineIconEnum.NONE,
            TimelineIconEnum.APRRFN_APPROVED,
            TimelineIconEnum.APRGBCK_APPROVED,
            TimelineIconEnum.APRCNL_APPROVED,
            TimelineIconEnum.APROMT,
          ].includes(icon) && <THRZDIcon />}
          {[
            TimelineIconEnum.DCLRFN,
            TimelineIconEnum.DCLGBCK,
            TimelineIconEnum.DCLCNL,
            TimelineIconEnum.DCLOMT,
            TimelineIconEnum.APRRFN_DECLINED,
            TimelineIconEnum.APRGBCK_DECLINED,
            TimelineIconEnum.APRCNL_DECLINED,
          ].includes(icon) && <NTHRZDIcon />}
          {icon === TimelineIconEnum.RTRY && <RTRYIcon />}
        </Grid>
        <Grid item md={4} className={classes.tlTitleUser}>
          <Typography>{title}</Typography>
          <Typography className={"second"}>{user}</Typography>
        </Grid>
        <Grid item md={3} className={classes.tlDateTime}>
          <Typography>{date}</Typography>
          <Typography className={"second"}>{time}</Typography>
        </Grid>
        <Grid item md={2} className={classes.tlDetailsLink}>
          <Link hidden={showDetails} onClick={handleOpen}>
            <ArrowsIcon />
            <Typography>Ver detalle</Typography>
          </Link>
        </Grid>
        <SimpleDialog
          openDialog={openDialog}
          title={titleDialog}
          onClose={handleClose}
          time={time}
          date={date}
          dateTitle={dateTitle}
          reason={get(props, "timeLine.reason", "")}
        />
      </Grid>
    </>
  );
};
