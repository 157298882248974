export enum KindEnum {
  INVOICE = "invoice",
  CHARGE = "charge",
  DISPERSION = "dispersion",
  CREDIT_NOTE = "credit_note",
  ANNUL_INVOICE = "annul_invoice",
  VOUCHER = "voucher",
  DEBIT_NOTE = "debit_note",
  RETENTION = "retention_dispersion",
  RETENTION_EC = "retention",
  RETENTION_CO = "retention",
  TO_CHARGE = "to_charge",
  RECEIVABLE = "receivable",
  EMITTED = "emitted",
}
