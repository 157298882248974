import React from "react";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Download } from "react-feather";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "61px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  text: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    fontSize: "12px",
    color: "#293036",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "-0.5rem",
  },
  relative: {
    position: "relative",
  },
}));

export const FooterTable = (props: {
  count: number;
  onClick: () => void;
  isLoading: boolean;
}) => {
  const classes = useStyles();
  return (
    <Grid className={classes.root} direction="row">
      <Grid item>
        <Typography
          className={classes.text}
          variant="subtitle1"
          gutterBottom
          component="div"
        >
          Transacciones seleccionadas: {props.count}
        </Typography>
      </Grid>
      <Grid className={classes.relative}>
        <Button
          onClick={props.onClick}
          variant={"outlined"}
          className={classes.button}
          startIcon={<Download size={"1rem"} />}
          disabled={props.isLoading}
        >
          Descargar
        </Button>
        {props.isLoading ? (
          <CircularProgress
            size={20}
            color={"primary"}
            className={classes.buttonProgress}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};
