import React from "react";

const DownloadIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.99992 9.33337C2.36811 9.33337 2.66659 9.63185 2.66659 10V12.6667C2.66659 12.8435 2.73682 13.0131 2.86185 13.1381C2.98687 13.2631 3.15644 13.3334 3.33325 13.3334H12.6666C12.8434 13.3334 13.013 13.2631 13.138 13.1381C13.263 13.0131 13.3333 12.8435 13.3333 12.6667V10C13.3333 9.63185 13.6317 9.33337 13.9999 9.33337C14.3681 9.33337 14.6666 9.63185 14.6666 10V12.6667C14.6666 13.1971 14.4559 13.7058 14.0808 14.0809C13.7057 14.456 13.197 14.6667 12.6666 14.6667H3.33325C2.80282 14.6667 2.29411 14.456 1.91904 14.0809C1.54397 13.7058 1.33325 13.1971 1.33325 12.6667V10C1.33325 9.63185 1.63173 9.33337 1.99992 9.33337Z"
      fill="#293036"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.19526 6.19526C4.45561 5.93491 4.87772 5.93491 5.13807 6.19526L8 9.05719L10.8619 6.19526C11.1223 5.93491 11.5444 5.93491 11.8047 6.19526C12.0651 6.45561 12.0651 6.87772 11.8047 7.13807L8.4714 10.4714C8.21106 10.7318 7.78894 10.7318 7.5286 10.4714L4.19526 7.13807C3.93491 6.87772 3.93491 6.45561 4.19526 6.19526Z"
      fill="#293036"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99992 1.33337C8.36811 1.33337 8.66659 1.63185 8.66659 2.00004V10C8.66659 10.3682 8.36811 10.6667 7.99992 10.6667C7.63173 10.6667 7.33325 10.3682 7.33325 10V2.00004C7.33325 1.63185 7.63173 1.33337 7.99992 1.33337Z"
      fill="#293036"
    />
  </svg>
);

export default DownloadIcon;
