import { SemaphoreItemsEnum } from "../enums/SemaphoreItemsEnum";
import { Routes } from "../constants/routes";
import { ConfigIdEnum } from "../constants/initial_state_legal_details";

export interface IBuilderTab {
  beforeKey: SemaphoreItemsEnum;
  getRoute: (publicMerchantId: string) => string;
  title: string;
}

export const CatalogRenderTabsNode: IBuilderTab[] = [
  {
    beforeKey: SemaphoreItemsEnum.basicDetail,
    getRoute: (publicMerchantId: string): string =>
      `${Routes.BASIC_DETAILS}?publicMerchantId=${publicMerchantId}&menuItem=true`,
    title: "Datos básicos",
  },
  {
    beforeKey: SemaphoreItemsEnum.basicDetail,
    getRoute: (publicMerchantId: string): string =>
      `${Routes.BILLING_DETAILS}?publicMerchantId=${publicMerchantId}&menuItem=true`,
    title: "Datos de facturación",
  },
  {
    beforeKey: SemaphoreItemsEnum.billingDetail,
    getRoute: (publicMerchantId: string): string =>
      `${Routes.LEGAL_DETAILS}?publicMerchantId=${publicMerchantId}&menuItem=true`,
    title: "Datos legales",
  },
  {
    beforeKey: SemaphoreItemsEnum.legalDetail,
    getRoute: (publicMerchantId: string): string =>
      `${Routes.CONTACT_DETAIL}?publicMerchantId=${publicMerchantId}&menuItem=true`,
    title: "Datos de contacto",
  },
];

export const CatalogRenderTabsNodeMassive: IBuilderTab[] = [
  {
    beforeKey: SemaphoreItemsEnum.basicDetail,
    getRoute: (): string => `${Routes.EDIT_BRANCH_BASIC_DETAILS}?menuItem=true`,
    title: "Datos básicos",
  },
  {
    beforeKey: SemaphoreItemsEnum.billingDetail,
    getRoute: (): string =>
      `${Routes.EDIT_BRANCH_BILLING_DETAILS}?menuItem=true`,
    title: "Datos de facturación",
  },
  {
    beforeKey: SemaphoreItemsEnum.legalDetail,
    getRoute: (): string => `${Routes.EDIT_BRANCH_LEGAL_DETAILS}?menuItem=true`,
    title: "Datos legales",
  },
  {
    beforeKey: SemaphoreItemsEnum.contactDetail,
    getRoute: (): string =>
      `${Routes.EDIT_BRANCH_CONTACT_DETAIL}?menuItem=true`,
    title: "Datos de contacto",
  },
];

export const CatalogPositionTab = {
  [SemaphoreItemsEnum.basicDetail]: 0,
  [SemaphoreItemsEnum.billingDetail]: 1,
  [SemaphoreItemsEnum.legalDetail]: 2,
  [SemaphoreItemsEnum.contactDetail]: 3,
};

export const CatalogConfigNode = {
  [SemaphoreItemsEnum.basicDetail]: ConfigIdEnum.CN001,
  [SemaphoreItemsEnum.billingDetail]: ConfigIdEnum.CN016,
  [SemaphoreItemsEnum.legalDetail]: ConfigIdEnum.CN015,
  [SemaphoreItemsEnum.contactDetail]: ConfigIdEnum.CN002,
};
