import { defaultTo, isEqual, get } from "lodash";
import { StorageKeyEnum } from "../../infrastructure/enums/StorageKeyEnum";

export const getBasicMerchantInfo = (): object =>
  JSON.parse(
    defaultTo(sessionStorage.getItem(StorageKeyEnum.BASIC_MERCHANT_INFO), "{}")
  );

export const isNodeLayout = () =>
  isEqual(
    get(getBasicMerchantInfo(), "layout.origin", ""),
    StorageKeyEnum.CREATE_NODE
  );
