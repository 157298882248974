import React from "react";
import { Grid, Typography } from "@mui/material";
import FormControl from "../../../FormControl/FormControl";
import { physicalPersonRules } from "../../../../shared/constants/form_labels_legal_details";
import { CountryEnum } from "../../../../shared/countryEnum";
import { IMultipleFormValues } from "../../../../shared/constants/initial_state_legal_details";
import { Control } from "react-hook-form";

type Props = {
  control: Control;
  rules?: object;
  isDisable?: boolean;
  country?: CountryEnum;
  constitutionalCountry?: CountryEnum;
  options?: IMultipleFormValues[];
};

const PhysicalForm: React.FC<Props> = ({
  control,
  options,
  rules,
  isDisable = false,
  country,
  constitutionalCountry,
}: Props) => {
  return (
    <Grid container rowSpacing={2} columnSpacing={1}>
      <Grid item xs={12}>
        <Typography variant={"body2"} color={"text.dark"}>
          Información personal
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl
          control={control}
          inputComponent="text"
          label="Nombre(s)"
          name="name"
          isDisable={isDisable}
          rules={physicalPersonRules.nameField}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl
          control={control}
          inputComponent="text"
          label="Apellido(s)"
          name="lastName"
          isDisable={isDisable}
          rules={physicalPersonRules.lastName}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl
          control={control}
          inputComponent="countries"
          label="Nacionalidad"
          name="nationality"
          rules={physicalPersonRules.nacionality}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl
          control={control}
          inputComponent="date"
          label="Fecha de nacimiento"
          name="birthdate"
          rules={physicalPersonRules.birthdate}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl
          control={control}
          inputComponent="countries"
          label="País de residencia"
          name="country"
          rules={physicalPersonRules.countryResidence}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"body2"} color={"text.dark"}>
          Información de identificación
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl
          control={control}
          values={options}
          inputComponent="select"
          label="Tipo de identificación"
          name="documentType"
          rules={physicalPersonRules.documentType}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl
          control={control}
          inputComponent="text"
          label="Nro. de identificación"
          name="documentNumber"
          rules={rules!}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"body2"} color={"text.dark"}>
          Datos de contacto
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl
          control={control}
          inputComponent="email"
          label="Correo electrónico"
          name="email"
          rules={physicalPersonRules.email}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl
          control={control}
          country={country}
          constitutionalCountry={constitutionalCountry}
          inputComponent="phone"
          label="Celular"
          name="cellphoneNumber"
          rules={physicalPersonRules.phone}
        />
      </Grid>
    </Grid>
  );
};

export default PhysicalForm;
