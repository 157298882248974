import React, { FC, PropsWithChildren } from "react";
import { Box, Grid } from "@mui/material";
import { headerFiltersStyles as styles } from "./HeaderFilters.styles";
import SearchText from "../SearchText/SearchText";
import {
  Alert,
  ColumnsPicker,
  FilterDetailBar,
  IconDownload,
  IconUpload,
  ModalLoader,
  SplitButton,
} from "@kushki/connect-ui";
import { FilterSideBar } from "../Filters/FilterSideBar/FilterSideBar";
import { LabelsEnum } from "../../shared/enums/LabelsEnum";
import { IHeaderFiltersProps } from "./HeaderFilters.interfaces";
import { joinItemList } from "../../shared/utils/lists_utils";
import { useHeaderFiltersState } from "./state/useHeaderFiltersState";
import { TransactionEnum } from "../../shared/enums/TransactionEnum";
import {
  FileFormatIDsEnum,
  FileFormatTitleEnum,
} from "../../shared/enums/FileFormatEnum";
import { isAdminUser } from "../../shared/utils/roles";
import { getMaxColumns } from "../../shared/utils/column_selected";
import { MassiveStatusModal } from "../MassiveStatusModal/MassiveStatusModal";
import {
  MassiveStatusModalButtons,
  MassiveStatusModalConstants,
} from "../../shared/infraestructure/MassiveStatusModalConstants";
import { MassiveStatusInstructionsModal } from "../MassiveStatusInstructionsModal/MassiveStatusInstructionsModal";
import { MassiveStatusEmailsModal } from "../MassiveStatusEmailsModal/MassiveStatusEmailsModal";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { ChargebackRoles } from "../../shared/constants/ChargebackConstants";

export const HeaderFilters: FC<IHeaderFiltersProps> = (
  props: PropsWithChildren<IHeaderFiltersProps>
) => {
  const {
    defaultEndDate,
    defaultStartDate,
    itemsDetailBar,
    valueSearch,
    onEnterName,
    onChange,
    onChangeFiltersDetailBar,
    filterSideBarProps,
    onClickFilterSideBar,
    columnsItems,
    isLoadingMassiveStatus,
    massiveStatusFile,
    setMassiveStatusFile,
    minDate,
  } = useHeaderFiltersState({
    getItemsSelected: props.getItemsSelected,
    setPage: props.setPage,
  });

  return (
    <Grid container>
      <Grid item container xs={12}>
        <Grid item xs={8} sx={styles.gridSearch}>
          <SearchText
            onEnter={onEnterName}
            onDateSelected={props.onDateSelected!}
            value={valueSearch}
            onChange={onChange}
            showRangePicker={true}
            defaultEndDate={defaultEndDate}
            defaultStartDate={defaultStartDate}
            minDate={minDate}
          />
        </Grid>
        <Grid item xs={1} sx={styles.gridFilter}>
          <ColumnsPicker
            maxColumns={getMaxColumns()}
            items={columnsItems}
            onActionButtons={props.onActionsButton!}
          />
        </Grid>
        <Grid item xs={1} sx={styles.gridFilter}>
          <Box mt={2} mb={2}>
            <FilterSideBar
              data-testid={"filterBar"}
              {...filterSideBarProps}
              categoryItems={filterSideBarProps.categoryItems}
              onClick={onClickFilterSideBar}
              setRangeAmount={props.setRangeAmount!}
              rangeAmountInfo={props.rangeAmountInfo!}
              isApplyFilter={props.isApplyFilter!}
              setIsApplyFilter={props.setIsApplyFilter!}
            />
          </Box>
        </Grid>

        <ComponentSecurityWrapper
          componentId={`${ChargebackRoles.M_TRANSACTIONS_CHARGEBACK}.${ChargebackRoles.UPLOAD_FILE}`}
        >
          <Grid item xs={2} sx={styles.button}>
            <Box>
              <SplitButton
                variant="primary"
                disabled={props.downloadFileOptions?.loadingDownload}
                isLoading={props.downloadFileOptions?.loadingDownload}
                onItemSelected={props.downloadFileOptions?.handleSelect}
                items={
                  isAdminUser()
                    ? [
                        {
                          categoryIcon: <IconDownload />,
                          categoryId: "download",
                          categoryItems: [
                            {
                              id: FileFormatIDsEnum.CSV_DOWNLOAD,
                              text: FileFormatTitleEnum.CSV_DOWNLOAD,
                              variant: "text",
                            },
                            {
                              id: FileFormatIDsEnum.EXCEL_DOWNLOAD,
                              text: FileFormatTitleEnum.EXCEL_DOWNLOAD,
                              variant: "text",
                            },
                            {
                              id: FileFormatIDsEnum.JSON_DOWNLOAD,
                              text: FileFormatTitleEnum.JSON_DOWNLOAD,
                              variant: "text",
                            },
                          ],
                          categoryName: TransactionEnum.BUTTON_DOWNLOAD_TITLE,
                        },
                        {
                          categoryIcon: <IconUpload />,
                          categoryId: "upload",
                          categoryItems: [
                            {
                              id: FileFormatIDsEnum.CSV_UPLOAD,
                              text: FileFormatTitleEnum.CSV_UPLOAD,
                              variant: "text",
                            },
                          ],
                          categoryName:
                            MassiveStatusModalButtons.MASSIVE_UPLOAD_BUTTON_TITLE,
                        },
                      ]
                    : [
                        {
                          categoryIcon: <IconDownload />,
                          categoryId: "download",
                          categoryItems: [
                            {
                              id: FileFormatIDsEnum.CSV_DOWNLOAD,
                              text: FileFormatTitleEnum.CSV_DOWNLOAD,
                              variant: "text",
                            },
                            {
                              id: FileFormatIDsEnum.EXCEL_DOWNLOAD,
                              text: FileFormatTitleEnum.EXCEL_DOWNLOAD,
                              variant: "text",
                            },
                            {
                              id: FileFormatIDsEnum.JSON_DOWNLOAD,
                              text: FileFormatTitleEnum.JSON_DOWNLOAD,
                              variant: "text",
                            },
                          ],
                          categoryName: TransactionEnum.BUTTON_DOWNLOAD_TITLE,
                        },
                      ]
                }
                size={"small"}
                text={TransactionEnum.ACTIONS_BUTTON_TITTLE}
                type={"accordionMultiple"}
              ></SplitButton>
            </Box>
          </Grid>
        </ComponentSecurityWrapper>

        <Grid item xs={12} sx={styles.gridFilterCard}>
          {props.selectedBranches!.length > 0 && (
            <Box sx={styles.boxAlert}>
              <Alert
                title={LabelsEnum.TITLE_ALERT}
                type="informative"
                subTitle={joinItemList(
                  props.selectedBranches!,
                  ", ",
                  "",
                  false
                )}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sx={styles.gridFilterCard}>
          <FilterDetailBar
            filters={itemsDetailBar}
            onChangeFilters={onChangeFiltersDetailBar}
          />
        </Grid>
      </Grid>
      <MassiveStatusModal
        massiveStatusFile={massiveStatusFile}
        setMassiveStatusFile={setMassiveStatusFile}
      />
      <MassiveStatusInstructionsModal />
      <MassiveStatusEmailsModal massiveStatusFile={massiveStatusFile} />
      {isLoadingMassiveStatus && (
        <ModalLoader
          descriptionText={MassiveStatusModalConstants.LOADING_DESCRIPTION}
          titleText={MassiveStatusModalConstants.LOADING_TITLE}
          isOpen={isLoadingMassiveStatus}
        />
      )}
    </Grid>
  );
};
