import { RadioInfoItems } from "../../components/RadioGroupComponent/RadioGroupComponent.interfaces";
import { ProcessorTypeChipInfo } from "../../components/InternationalTransferComponent/PaymentProcessorComponent/PaymentProcessorComponent.interfaces";
export enum ProcessorEnum {
  INTERNATIONAL = "International Transfer Processor",
}

export enum ModeEnum {
  CREATE = "CREATE",
  EDIT = "EDIT",
}
export const PROCESSOR_OPTIONS_RADIO_ITEMS: RadioInfoItems[] = [
  {
    label: "CitiBank",
    value: "CitiBank Processor",
  },
];

export const PROCESSOR_PAYMENT_METHOD: ProcessorTypeChipInfo[] = [
  { name: "Internacional" },
];

export const PROCESSOR_CATEGORY_RADIO_ITEMS: RadioInfoItems[] = [
  {
    label: "Formal",
    value: "FORMAL",
  },
];

export const PROCESSOR_MODEL_RADIO_ITEMS: RadioInfoItems[] = [
  {
    label: "Gateway",
    value: "GATEWAY",
  },
  {
    label: "Agregador",
    value: "AGGREGATOR",
  },
];

export const CURRENCY_OPTIONS_RADIO_ITEMS: string[] = ["MXN"];
