import { ChipLabelProps } from "../ChipLabel";
import React from "react";

export interface ChipLabelState {
  eventsTransformed: { service: string; status: string[] }[];
}
export const useChipLabelState = (props: ChipLabelProps): ChipLabelState => {
  const [eventsTransformed, setEventsTransformed] = React.useState<
    { service: string; status: string[] }[]
  >([]);
  React.useEffect(() => {
    buildEvent();
  }, [props.events]);

  const buildEvent = () => {
    const arr: { service: string; status: string[] }[] = [];

    props.events.forEach((evt1: { service?: string; status?: string }) => {
      const eventsFiltered: {
        service?: string;
        status?: string;
      }[] = props.events.filter((evt) => evt.service === evt1.service!);
      const newEventsFiltered: {
        service: string;
        status: string[];
      }[] = arr.filter((newEvt) => newEvt.service === evt1.service!);
      if (newEventsFiltered.length === 0)
        arr.push({
          service: eventsFiltered[0].service!,
          status: eventsFiltered.map(({ status }) => status!),
        });
      if (newEventsFiltered.length > 0) {
        const status: string[] = eventsFiltered.map(({ status }) => status!);
        const index = arr.findIndex(({ service }) => service === evt1.service);
        arr[index].status = status;
      }
    });
    setEventsTransformed(arr);
  };

  return {
    eventsTransformed,
  };
};
