import React, { useCallback, useEffect, useState } from "react";
import {
  ITotalEditBranches,
  ITotalEditBranchesNumbers,
  ITotalSelectedBranches,
  ITotalSelectedBranchesNumbers,
  IUseBranchEditContainerState,
} from "./useBranchEditContainerState.interfaces";
import { ITableRowProps } from "../../../components/Table/TableSimple/interfaces";
import { IValueCell } from "../../../components/TableCell/CellHeader/interfaces";
import {
  cloneDeep,
  defaultTo,
  get,
  has,
  isEmpty,
  orderBy,
  set,
  slice,
} from "lodash";
import {
  DEFAULT_PATHS,
  LABELS_STATUS_HEADER,
} from "../../../shared/constants/labels/branch_manual_creation_labels";
import { SessionStoragePaths } from "../../../shared/enums/sessionStoragePaths";
import {
  ItemCategoryItem,
  ItemCategoryProps,
} from "../../../components/Filters/ItemCategory/interfaces";
import { CountryEnum } from "../../../shared/enums/countryEnum";
import {
  applyFiltersForItems2,
  findNameInAllCountries,
} from "../../../shared/utils/filters_utils";
import {
  MerchantNodeData,
  SearchMerchantResponse,
} from "../../../../types/search_merchant_response";
import { IButtonOptions } from "../../../components/FooterOptions/FooterOptions.interfaces";
import { useNavigate } from "react-router-dom";
import { TableBodyCellEnum } from "../../../components/Table/TableSimple/constants";
import {
  EDIT_STATUS_TEXT,
  EditStatusEnum,
} from "../../../shared/enums/EditStatusEnum";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { getSimplifiedFormatedDate } from "../../../shared/utils/date_utils";
import {
  getBranchListFromSessionStorage,
  getItemFromSessionStorage,
} from "../../../shared/utils/session_storage_utils";
import { BranchResponse } from "../../../../types/validate_branch_response";
import searchMerchantNodes from "../../../store/thunks/merchantResponse/merchantResponse.thunks";
import { editStatusPath } from "../../../shared/constants/edit_container_paths";
import { getBranchEditListWithUpdatedStatus } from "../../../shared/utils/massiveUtils/massiveUtils";
import { allConfigs, ConfigIdEnum } from "../../../shared/enums/configIdEnums";
import _debounce from "lodash/debounce";
import { useSnackbar } from "@kushki/connect-ui";
import { EDIT_AND_CREATION_SUCCESS } from "../../../shared/constants/snackbar_messages";
import { getNodeInfo } from "../../../store/thunks/createNode/createNode.thunks";
import { BranchEditItem } from "../../../../types/branch_edit_item";
import { verifyAllBranchesNotModified } from "../../../shared/utils/branchesEditionUtils";
import { EDIT_BRANCH } from "../../../shared/constants/labels/buttons_labels";
import { INFORMATION_CUSTOMER_LABELS } from "../../../shared/constants/labels/information_customer_container_labels";
import { conditionalExecution } from "../../../shared/utils/executionUtils";
import { RedirectRoutesEnum } from "../../../shared/enums/RedirectRoutesEnum";
import { LocalStoragePaths } from "../../../shared/enums/LocalStoragePathsEnum";

export const useBranchEditContainerState = (): IUseBranchEditContainerState => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const primaryColor: string = "primary";
  const customerPublicMerchantId = defaultTo(
    sessionStorage.getItem(SessionStoragePaths.CUSTOMER_MERCHANT_ID),
    ""
  );
  const [openModalActiveFilter, setOpenModalActiveFilter] = useState(false);
  const [value, setValue] = useState<object>({});
  const [isEmptySearch, setIsEmptySearch] = useState<boolean>(false);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [itemCheckCurrent, setItemCheckCurrent] = useState<ITableRowProps | {}>(
    {}
  );
  const [disableEditButton, setDisableEditButton] = useState(true);
  const { getNodeInfoResponse, branchEditList } = useAppSelector((state) => ({
    ...state.merchantResponse,
    ...state.getNodeInfo,
  }));
  const [allBranchesNotModified, setAllBranchesNotModified] = useState(false);
  const [primaryBtnLabel, setPrimaryBtnLabel] = useState("");

  const processRows = (
    merchantResponse: SearchMerchantResponse
  ): ITableRowProps[] => {
    const dataTemp = get(merchantResponse, "data", []);
    const rowsState: ITableRowProps[] = [];
    let indexBranch: number = 0;
    const defaultEditStatusObject: {
      color: string;
      valueText: string;
    } = {
      color: primaryColor,
      valueText: LABELS_STATUS_HEADER.NO_MODIFIED,
    };

    dataTemp.map((branch: MerchantNodeData) => {
      const editStatusObject = get(
        EDIT_STATUS_TEXT,
        `[${get(branch, editStatusPath, "")}]`,
        defaultEditStatusObject
      );
      const statusColor = branch.isModify
        ? editStatusObject.color
        : primaryColor;
      const statusText: string = branch.isModify
        ? editStatusObject.valueText
        : LABELS_STATUS_HEADER.NO_MODIFIED;
      const statusValue: string = get(
        branch,
        editStatusPath,
        EditStatusEnum.not_modified
      );

      let rowData: ITableRowProps = {
        cells: [
          {
            props: {
              cellProps: {
                align: "left",
                spacing: 1,
              },
              id: "name",
              line1: get(branch, "name", ""),
              line2: `Creación: ${
                !isEmpty(get(branch, "created", ""))
                  ? getSimplifiedFormatedDate(get(branch, "created", ""))
                  : ""
              }`,
              nodeId: get(branch, "node_id", ""),
              rowIndex: Number(indexBranch),
              type: "twoLines",
            },
            type: TableBodyCellEnum.TITLE,
          },
          {
            props: {
              cellProps: {
                align: "center",
                spacing: 1,
              },
              country: get(branch, "constitutional_country", ""),
              id: "country",
              rowIndex: Number(indexBranch),
              type: "twolines",
            },
            type: TableBodyCellEnum.FLAG,
          },
          {
            props: {
              cellProps: {
                align: "center",
                spacing: 1,
              },
              id: "client_type",
              line1: get(branch, "client_type", ""),
              rowIndex: Number(indexBranch),
              type: "oneLine",
            },
            type: TableBodyCellEnum.TITLE,
          },
          {
            props: {
              cellProps: { align: "center", spacing: 2 },
              color: statusColor,
              id: editStatusPath,
              rowIndex: Number(indexBranch),
              text: statusText,
              value: statusValue,
            },
            type: TableBodyCellEnum.TAG,
          },
        ],
        id: get(branch, "node_id", ""),
        rowProps: {
          hidden: false,
          isSelected: false,
        },
      };

      rowsState.push(rowData);
      indexBranch++;
    });

    return rowsState;
  };
  const [rows, setRows] = React.useState<ITableRowProps[]>(
    processRows(branchEditList)
  );
  const [rowsFilters, setRowsFilters] = React.useState<ITableRowProps[]>(
    processRows(branchEditList)
  );
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [isOnClickPage, setIsOnClickPage] = useState<boolean>(false);
  const [isOnClickTransitions, setIsOnClickTransitions] =
    useState<boolean>(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [editProcess, setEditProcess] = useState(true);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [countFilters, setCountFilters] = useState<number>(0);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [filterCountries, setFilterCountries] = useState<ItemCategoryItem[]>(
    []
  );
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const showAlertAction = (value: boolean) => {
    setShowAlert(value);
  };
  const [totalEdit, setTotalEdit] = useState<ITotalEditBranches>({
    incomplete: "0",
    noModify: "0",
  });
  const [totalSelected, setTotalSelected] = useState<ITotalSelectedBranches>({
    selected: "0",
    total: "0",
  });
  const [allCheck, setAllCheck] = useState<boolean>(false);

  const changeOpenModalActiveFilter = (value: boolean) =>
    setOpenModalActiveFilter(value);

  const handleConfirmModalActiveFilter = () => setOpenConfirmModal(true);

  const accountTotals = (
    filterRowsHi: ITableRowProps[],
    elements: IValueCell[][]
  ): {
    totalSelected: ITotalSelectedBranchesNumbers;
    totalEdit: ITotalEditBranchesNumbers;
  } => {
    const totalSelectedValues: ITotalSelectedBranchesNumbers = {
      selected: 0,
      total: get(filterRowsHi, "length", 0),
    };
    const totalEditValues: ITotalEditBranchesNumbers = {
      incomplete: 0,
      noModify: 0,
    };

    filterRowsHi.map((row) => {
      const cellsData: IValueCell[] | undefined = elements.find(
        (cells: IValueCell[]) =>
          cells[0].rowIndex === get(row, DEFAULT_PATHS.CELL_ROW_INDEX)
      );

      if (
        get(cellsData, "[0].id", "") ===
        `${get(cellsData, "[0].rowIndex", "")}-table-check`
      ) {
        if (get(cellsData, "[0].value", false)) {
          totalSelectedValues.selected++;
        }
      }
      if (
        get(row, DEFAULT_PATHS.CELL_ROW_EDIT_STATUS, "") ===
        EditStatusEnum.not_modified
      )
        totalEditValues.noModify++;
      if (
        get(row, DEFAULT_PATHS.CELL_ROW_EDIT_STATUS, "") ===
        EditStatusEnum.pending
      )
        totalEditValues.incomplete++;
    });

    return {
      totalEdit: totalEditValues,
      totalSelected: totalSelectedValues,
    };
  };

  const calculateIndex = (
    rowsCalculateIndex: ITableRowProps[]
  ): ITableRowProps[] => {
    rowsCalculateIndex.map((row, index) =>
      row.cells.map((cell) => set(cell, DEFAULT_PATHS.ROW_INDEX, index))
    );

    return rowsCalculateIndex;
  };

  const updateAllRows = (rowsTemp: ITableRowProps[]) => {
    let range = {
      max: itemsPerPage * selectedPage - 1,
      min: itemsPerPage * selectedPage - itemsPerPage,
    };

    if (isEmptySearch) {
      range = {
        max: itemsPerPage * 1 - 1,
        min: 0,
      };
    }
    const rowIndexShowed: number[] = [];
    const getRowShow: ITableRowProps[] = [];

    const filterRows: ITableRowProps[] = rowsTemp.filter(
      (row) => !row.rowProps.hidden
    );
    const rowsPagination = slice(filterRows, range.min, range.max + 1);

    rowsPagination.map((item: ITableRowProps) =>
      rowIndexShowed.push(get(item, DEFAULT_PATHS.CELL_ROW_INDEX))
    );

    rowsTemp.map((item: ITableRowProps) => {
      let rowsItem: ITableRowProps;

      if (
        rowIndexShowed.includes(get(item, DEFAULT_PATHS.CELL_ROW_INDEX)) &&
        !get(item, DEFAULT_PATHS.HIDDEN, true)
      ) {
        rowsItem = {
          ...item,
          rowProps: { ...item.rowProps, hidden: false },
        };
        getRowShow.push(rowsItem);
      } else {
        rowsItem = {
          ...item,
          rowProps: { ...item.rowProps, hidden: true },
        };
        getRowShow.push(rowsItem);
      }
    });
    setRows(getRowShow);
  };
  const getFiltersRowsHidden = (rowsCopy: ITableRowProps[]) => {
    return rowsCopy.filter(
      (row: ITableRowProps) => row.rowProps.hidden !== true
    );
  };

  const onPageClick = (page: number) => {
    setEditProcess(false);
    const rowsCopy: ITableRowProps[] = [];
    const valueElements: IValueCell[][] = Object.keys(value).map(
      (key: string) => value[key]
    );

    for (let i in rows) {
      const rowsItem: ITableRowProps = {
        ...rows[i],
        rowProps: {
          ...rows[i].rowProps,
          hidden: true,
        },
      };

      rowsCopy.push(rowsItem);
    }
    valueElements.map(() =>
      rowsCopy.map((cell) => set(cell, DEFAULT_PATHS.HIDDEN, false))
    );

    const filterRowsHidden: ITableRowProps[] = getFiltersRowsHidden(rowsCopy);

    const { totalSelected, totalEdit } = accountTotals(
      cloneDeep(rowsCopy),
      valueElements
    );

    setTotalSelected({
      selected: totalSelected.selected.toString(),
      total: totalSelected.total.toString(),
    });

    setTotalEdit({
      incomplete: totalEdit.incomplete.toString(),
      noModify: totalEdit.noModify.toString(),
    });
    setTotalItems(filterRowsHidden.length);
    setRows(calculateIndex(rowsCopy));
    setRowsFilters(cloneDeep(calculateIndex(rowsCopy)));
    updateAllRows(cloneDeep(rowsCopy));
    setSelectedPage(page);
    setIsOnClickPage(true);
    setIsOnClickTransitions(false);
    setIsEmptySearch(false);
  };

  const createDataBranch = (elements: IValueCell[][]) => {
    const data = get(branchEditList, "data", []);
    const total = get(branchEditList, "total", 0);
    const rowsCopy: ITableRowProps[] = cloneDeep(rows);
    const branches: MerchantNodeData[] = [];

    data.map((branch: MerchantNodeData) => {
      let editValue: boolean = false;
      const rowData: ITableRowProps | undefined = rowsCopy.find(
        (row: ITableRowProps) => row.id === branch.node_id
      );
      const cellsData: IValueCell[] | undefined = elements.find(
        (cells: IValueCell[]) =>
          get(cells, "[1].nodeId", "") === get(rowData, "id", "")
      );

      if (get(cellsData, "[1].nodeId", "") === get(rowData, "id", "")) {
        if (get(cellsData, "[0].value", false)) {
          editValue = true;
        }
      }
      branches.push({
        ...branch,
        edit: editValue,
      });
    });

    sessionStorage.setItem(
      SessionStoragePaths.BRANCH_EDIT_LIST,
      JSON.stringify({
        data: orderBy(branches, ["name"], ["asc"]),
        total: total,
      })
    );
  };

  const showConfirmModal = (isOpen: boolean) => setOpenConfirmModal(isOpen);

  const handleDebounceSaveBranch = (path: string) => {
    if (!isEmpty(path) && path.split(":").length > 1) {
      const ownerId = path.split(":")[0];
      const customerId = path.split(":")[1];

      setOpenLoader(false);
      window.location.href = API_ROUTES.CLIENTS(ownerId, customerId);
    }
  };

  const debounceFn = useCallback(
    _debounce((path: string) => handleDebounceSaveBranch(path), 2000),
    []
  );

  const saveBranches = () => {
    setOpenLoader(true);
    showSnackbar(EDIT_AND_CREATION_SUCCESS);
    debounceFn(get(getNodeInfoResponse, "path", ""));
  };

  const primaryButtonAction = () =>
    countFilters > 0
      ? changeOpenModalActiveFilter(true)
      : setOpenConfirmModal(true);

  const buildRedirect = () => {
    sessionStorage.setItem(
      SessionStoragePaths.BRANCH_EDIT_LIST,
      JSON.stringify({ data: [], total: 0 })
    );

    const url: string = `${RedirectRoutesEnum.CLIENTS}/${get(
      getNodeInfoResponse,
      "rootId",
      ""
    )}/customers/${get(getNodeInfoResponse, "nodeId", "")}`;

    window.location.href = url;
  };

  const handleNavigateBranchSelection = () =>
    navigate(`${API_ROUTES.BRANCH_SELECTION}`);

  const primaryButton: IButtonOptions = {
    isDisabled: allBranchesNotModified ? disableEditButton : false,
    isHidden: true,
    onAction: () => {
      conditionalExecution(
        allBranchesNotModified,
        handleNavigateBranchSelection,
        primaryButtonAction
      );
    },
  };
  const secondaryButton: IButtonOptions = {
    isDisabled: false,
    isHidden: !allBranchesNotModified,
    onAction: () => buildRedirect(),
  };

  const createItemsFilter = (elements: IValueCell[][]) => {
    const countries: string[] = [];
    const newCountriesFilter: ItemCategoryItem[] = [];
    let uniqueCountries: string[];

    elements.map((item: IValueCell[]) => {
      if (item.length === 5) {
        countries.push(item[2].value as string);
      }
    });
    uniqueCountries = countries.filter(
      (elem: string, index: number, self: string[]) =>
        index === self.indexOf(elem)
    );
    uniqueCountries.map((country: string) => {
      const filterCountryExist: ItemCategoryItem | undefined =
        filterCountries.find((item) => item.value === country);

      if (filterCountryExist) newCountriesFilter.push(filterCountryExist);
      else {
        switch (country) {
          case "Mexico":
            newCountriesFilter.push({
              label: CountryEnum.mexico,
              selected: false,
              value: country,
            });
            break;
          case "Panama":
            newCountriesFilter.push({
              label: CountryEnum.panama,
              selected: false,
              value: country,
            });
            break;
          case "Peru":
            newCountriesFilter.push({
              label: "Perú",
              selected: false,
              value: country,
            });
            break;
          default:
            newCountriesFilter.push({
              label: findNameInAllCountries(country),
              selected: false,
              value: country,
            });
            break;
        }
      }
    });
    setFilterCountries(newCountriesFilter);
  };

  const getRowValues = (valueFromTable: object) => {
    const elements: IValueCell[][] = Object.keys(valueFromTable).map(
      (key: string) => valueFromTable[key]
    );

    const filterRowsHi: ITableRowProps[] = [...rows];

    const { totalSelected, totalEdit } = accountTotals(filterRowsHi, elements);

    setTotalSelected({
      selected: totalSelected.selected.toString(),
      total: filterRowsHi.length.toString(),
    });
    setTotalEdit({
      incomplete: totalEdit.incomplete.toString(),
      noModify: totalEdit.noModify.toString(),
    });
    setValue(valueFromTable);

    if (allCheck) {
      createDataBranch(elements);
    }

    if (editProcess && !isEmpty(valueFromTable)) {
      createDataBranch(elements);
      createItemsFilter(elements);
    }
  };

  const calculateHiddenRow = (
    rowsValues: ITableRowProps[],
    rowsFiltersValues: ITableRowProps[]
  ): ITableRowProps[] => {
    let index: number = 0;

    rowsValues.map((rowCopy) => {
      const rowTemp: ITableRowProps | undefined = rowsFiltersValues.find(
        (rowFilter) => rowFilter.id === rowCopy.id
      );

      if (rowTemp) {
        if (!get(rowTemp, DEFAULT_PATHS.HIDDEN, true)) {
          if (index < itemsPerPage) {
            set(rowCopy, DEFAULT_PATHS.HIDDEN, false);
            index++;
          } else {
            set(rowCopy, DEFAULT_PATHS.HIDDEN, true);
          }
        }
      }
    });

    return rowsValues;
  };
  const processOrder = (order: string, pathValue: string) => {
    let rowsCopy: ITableRowProps[] = [];
    let rowsFiltersCopy: ITableRowProps[] = [];

    rowsCopy = orderBy(rows, pathValue, order as "asc" | "desc");
    rowsFiltersCopy = orderBy(rowsFilters, pathValue, order as "asc" | "desc");
    rowsCopy = calculateHiddenRow(rowsCopy, rowsFilters);
    setRows(calculateIndex(cloneDeep(rowsCopy)));
    setRowsFilters(calculateIndex(cloneDeep(rowsFiltersCopy)));
    onPageClick(1);
    updateAllRows(cloneDeep(rowsFiltersCopy));
  };

  const processOrderByValue = (valueOrder: string, order: string) => {
    switch (valueOrder) {
      case "name":
        processOrder(order, "cells[0].props.line1");
        break;
      case "country":
        processOrder(order, "cells[1].props.country");
        break;
      case "client_type":
        processOrder(order, "cells[2].props.line1");
        break;
      case editStatusPath:
        processOrder(order, "cells[3].props.text");
        break;
    }
  };

  const handleOrderByHeader = (
    valueOrder: string,
    order: string,
    numberOfClick: number
  ) => {
    setEditProcess(false);
    if (!isEmpty(order)) {
      processOrderByValue(valueOrder, order);
    } else {
      if (numberOfClick === 0) {
        processOrderByValue(valueOrder, "asc");
      }
    }
  };

  const handleOnSearch = (name: string) => {
    setEditProcess(false);
    const valueElements: IValueCell[][] = Object.keys(value).map(
      (key: string) => {
        return value[key];
      }
    );

    const rowsCopy: ITableRowProps[] = [];

    for (let i in rows) {
      const rowsItem: ITableRowProps = {
        ...rows[i],
        rowProps: {
          ...rows[i].rowProps,
          hidden: true,
        },
      };

      rowsCopy.push(rowsItem);
    }

    if (name !== "") {
      rowsCopy.map((rowCopy) => {
        const valueToUpperCase = get(
          rowCopy,
          DEFAULT_PATHS.CELL_ROW_NAME,
          ""
        ).toUpperCase();

        if (`${valueToUpperCase}`.includes(name)) {
          set(rowCopy, DEFAULT_PATHS.HIDDEN, false);
        }
      });
    } else {
      valueElements.map(() =>
        rowsCopy.map((cell) => set(cell, DEFAULT_PATHS.HIDDEN, false))
      );
    }
    const filterRowsHi: ITableRowProps[] = rowsCopy.filter(
      (row: ITableRowProps) => row.rowProps.hidden !== true
    );

    const { totalSelected, totalEdit } = accountTotals(
      cloneDeep(rowsCopy),
      valueElements
    );

    setTotalSelected({
      selected: totalSelected.selected.toString(),
      total: totalSelected.total.toString(),
    });

    setTotalEdit({
      incomplete: totalEdit.incomplete.toString(),
      noModify: totalEdit.noModify.toString(),
    });

    setTotalItems(filterRowsHi.length);
    onPageClick(1);
    setRows(calculateIndex(rowsCopy));
    setRowsFilters(cloneDeep(calculateIndex(rowsCopy)));
    setIsEmptySearch(true);
    updateAllRows(cloneDeep(rowsCopy));
  };

  const handleOnFilter = (filters: ItemCategoryProps[]) => {
    setEditProcess(false);
    const valueFilters: IValueCell[][] = Object.keys(value).map(
      (key: string) => value[key]
    );
    let valueElementsFound: ITableRowProps[] = [];
    const valuesIndex: number[] = [];
    let allowFilters: boolean = filters.some((elem) => !isEmpty(elem.items));

    const rowsCopy: ITableRowProps[] = [];

    if (allowFilters) {
      for (let i = 0; i < filters.length; i++) {
        valueElementsFound = applyFiltersForItems2(
          filters[i],
          i === 0 ? rows : valueElementsFound
        );
      }

      valueElementsFound.map((value) =>
        valuesIndex.push(get(value, DEFAULT_PATHS.CELL_ROW_INDEX))
      );

      for (let i in rows) {
        const rowsItem: ITableRowProps = {
          ...rows[i],
          rowProps: {
            hidden: true,
            isSelected: get(rows[i], "rowProps.isSelected"),
          },
        };

        rowsCopy.push(rowsItem);
      }

      rowsCopy.map((cell) => {
        if (valuesIndex.length > 0) {
          if (!valuesIndex.includes(get(cell, DEFAULT_PATHS.CELL_ROW_INDEX))) {
            set(cell, DEFAULT_PATHS.HIDDEN, true);
          } else {
            set(cell, DEFAULT_PATHS.HIDDEN, false);
          }
        } else {
          set(cell, DEFAULT_PATHS.HIDDEN, true);
        }
      });

      const filterRowsHi: ITableRowProps[] = rowsCopy.filter(
        (row: ITableRowProps) => row.rowProps.hidden !== true
      );

      const { totalSelected, totalEdit } = accountTotals(
        cloneDeep(rowsCopy),
        valueFilters
      );

      setTotalSelected({
        selected: totalSelected.selected.toString(),
        total: totalSelected.total.toString(),
      });
      setTotalEdit({
        incomplete: totalEdit.incomplete.toString(),
        noModify: totalEdit.noModify.toString(),
      });
      setTotalItems(filterRowsHi.length);
      onPageClick(1);
      setRows(calculateIndex(cloneDeep(rowsCopy)));
      setRowsFilters(cloneDeep(calculateIndex(cloneDeep(rowsCopy))));
      updateAllRows(cloneDeep(rowsCopy));
    } else {
      setTotalItems(rows.length);
      for (let i in rows) {
        const rowsItem: ITableRowProps = {
          ...rows[i],
          rowProps: {
            hidden: false,
            isSelected: get(rows[i], "rowProps.isSelected"),
          },
        };

        rowsCopy.push(rowsItem);
      }
      onPageClick(selectedPage);
      setRows(calculateIndex(cloneDeep(rowsCopy)));
      setRowsFilters(cloneDeep(calculateIndex(cloneDeep(rowsCopy))));
      updateAllRows(cloneDeep(rowsCopy));

      const { totalSelected, totalEdit } = accountTotals(
        cloneDeep(rowsCopy),
        valueFilters
      );

      setTotalSelected({
        selected: totalSelected.selected.toString(),
        total: totalSelected.total.toString(),
      });
      setTotalEdit({
        incomplete: totalEdit.incomplete.toString(),
        noModify: totalEdit.noModify.toString(),
      });
    }
  };

  const onItemsPerPageChange = (value: number) => {
    setEditProcess(false);
    setSelectedPage(1);
    setItemsPerPage(value);
    setIsOnClickPage(false);
    setIsOnClickTransitions(true);
    setIsEmptySearch(false);
  };

  const processData = async () => {
    setEditProcess(true);
    const branchList = getBranchListFromSessionStorage(
      SessionStoragePaths.BRANCH_LIST
    );

    const publicMerchantIdArray =
      branchList &&
      branchList
        .filter((item: BranchResponse) => {
          const branchHasNoError = !isEmpty(get(item, "publicMerchantId"));

          return branchHasNoError && !item.centralize;
        })
        .map((item: BranchResponse) => item.publicMerchantId);

    try {
      await dispatch(
        searchMerchantNodes({
          configs: allConfigs,
          limit: 500,
          offset: 0,
          publicMerchantId: publicMerchantIdArray,
        })
      ).then((response) => {
        if (!has(response, "error")) {
          setRows(processRows(branchEditList));
          setInitialLoading(false);
        }
      });
    } catch (e) {}
  };

  const isSomeBranchSelectedToEdition = (branchList: any[]) =>
    branchList.some((branchEdit: BranchEditItem) => branchEdit.edit);

  const validateIfSomeBranchIsModify = () => {
    const branchEditList = get(
      getItemFromSessionStorage(SessionStoragePaths.BRANCH_EDIT_LIST),
      "data",
      []
    ).filter((branch: BranchEditItem) => branch.isModify);

    return isEmpty(branchEditList);
  };

  useEffect(() => {
    setTotalItems(rows.length);
    setAllBranchesNotModified(verifyAllBranchesNotModified(rows));
  }, [rows.length]);

  useEffect(() => {
    allBranchesNotModified
      ? setPrimaryBtnLabel(EDIT_BRANCH)
      : setPrimaryBtnLabel(INFORMATION_CUSTOMER_LABELS.FINISH_EDIT);
  }, [allBranchesNotModified]);

  useEffect(() => {
    if (!isEmpty(itemCheckCurrent)) {
      const elements: IValueCell[][] = Object.keys(value).map(
        (key: string) => value[key]
      );

      let countCheckModified: number = 0;

      const filterRowsHi: ITableRowProps[] = rows.filter(
        (row: ITableRowProps) =>
          get(row, DEFAULT_PATHS.CELL_ROW_INDEX) !==
          get(itemCheckCurrent, DEFAULT_PATHS.CELL_ROW_INDEX)
      );

      filterRowsHi.map((row) => {
        const cellsData: IValueCell[] | undefined = elements.find(
          (cells: IValueCell[]) =>
            cells[0].rowIndex === get(row, DEFAULT_PATHS.CELL_ROW_INDEX)
        );

        if (
          get(cellsData, "[0].id", "") ===
          `${get(cellsData, "[0].rowIndex", "")}-table-check`
        ) {
          if (get(cellsData, "[0].value", false)) {
            if (
              get(row, DEFAULT_PATHS.CELL_ROW_EDIT_STATUS, "") ===
              EditStatusEnum.complete
            )
              countCheckModified++;
          }
        }
      });
      if (
        countCheckModified > 0 &&
        get(itemCheckCurrent, DEFAULT_PATHS.CELL_ROW_EDIT_STATUS) ===
          EditStatusEnum.pending
      )
        setShowAlert(true);
      if (editProcess) getRowValues(value);
      setItemCheckCurrent({});
    }
  }, [itemCheckCurrent, editProcess]);

  useEffect(() => {
    if (isOnClickPage) {
      setSelectedPage(selectedPage);
      setIsOnClickPage(false);
      setIsOnClickTransitions(false);
    }
    if (isOnClickTransitions) {
      onPageClick(1);
      setIsOnClickPage(false);
      setIsOnClickTransitions(false);
    }
    updateAllRows(rowsFilters);
  }, [itemsPerPage, selectedPage, rows.length, isEmptySearch]);

  useEffect(() => {
    const notHaveToModify = validateIfSomeBranchIsModify();

    if (notHaveToModify) {
      const initialRows = processRows(branchEditList);

      setRows(initialRows);
      setRowsFilters(initialRows);
    } else {
      const updatedBranchEditList = getBranchEditListWithUpdatedStatus(
        get(branchEditList, "data", [])
      );
      const initialRows = processRows({ data: updatedBranchEditList });

      setRows(initialRows);
      setRowsFilters(initialRows);
    }
  }, [branchEditList]);

  useEffect(() => {
    localStorage.removeItem(LocalStoragePaths.MASSIVE_SERVICE_CONFIGURATIONS);
    processData();
  }, []);

  useEffect(() => {
    getRowValues(value);
  }, [allCheck]);

  useEffect(() => {
    if (isEmpty(getNodeInfoResponse.path)) {
      dispatch(
        getNodeInfo({
          configIds: ConfigIdEnum.CN001,
          publicMerchantId: customerPublicMerchantId,
        })
      );
    }
  }, [getNodeInfoResponse.path]);

  useEffect(() => {
    const branchList = getBranchListFromSessionStorage(
      SessionStoragePaths.BRANCH_EDIT_LIST
    );

    setDisableEditButton(
      !isSomeBranchSelectedToEdition(defaultTo(get(branchList, "data"), []))
    );
  }, [totalSelected]);

  return {
    allBranchesNotModified,
    disableEditButton,
    filterCountries,
    footerActions: {
      primaryButton,
      saveLoader: false,
      secondaryButton,
    },
    getRowValues,
    handleNavigateBranchSelection,
    handleOnFilter,
    handleOnSearch,
    handleOrderByHeader,
    initialLoading,
    itemsPerPage,
    modalActiveFilter: {
      handleNextAction: handleConfirmModalActiveFilter,
      openModal: openModalActiveFilter,
      setOpenModal: changeOpenModalActiveFilter,
    },
    onItemsPerPageChange,
    onPageClick,
    openConfirmModal,
    openLoader,
    primaryBtnLabel,
    rows,
    saveBranches,
    selectedPage,
    setAllCheck,
    setCountFilters,
    setEditProcess,
    setItemCheckCurrent,
    showAlert,
    showAlertAction,
    showConfirmModal,
    totalEdit,
    totalItems,
    totalSelected,
  };
};
