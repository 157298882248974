import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { loadingSpinnerStyles as styles } from "./LoadingSpinnerStyles";
import {
  ILoadingSpinner,
  LoadingSpinnerLabels,
} from "./LoadingSpinner.interfaces";

export const LoadingSpinner = (props: ILoadingSpinner) => {
  return (
    <>
      {props.isLoading && (
        <Box sx={styles.containerBox}>
          <Box sx={styles.containerAnimation}>
            <CircularProgress
              size={70}
              variant={"determinate"}
              value={100}
              color={"secondary"}
              thickness={5}
              sx={styles.backSpinner}
            />
            <CircularProgress
              disableShrink
              size={70}
              thickness={5}
              sx={styles.frontSpinner}
            />
            {!props.hideLabels && (
              <Box sx={styles.messageBox}>
                <Typography variant={"h6"} color={"primary"}>
                  {LoadingSpinnerLabels.FIRST_LINE}
                </Typography>
                <Typography
                  variant={"h6"}
                  color={"primary"}
                  className={"second-line"}
                >
                  {LoadingSpinnerLabels.SECOND_LINE}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};
