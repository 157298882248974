import React from "react";
import { Button, ButtonGroup, Grid, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { routes } from "../../shared/infrastructure/routes";
const useStyles = makeStyles((theme: Theme) => ({
  messageText: {
    ...theme.typography.h5,
    marginBottom: theme.spacing(4),
    textAlign: "center",
  },
  btnContent: {
    display: "flex",
    marginBottom: theme.spacing(4),
    maxWidth: "320px",
  },
  btnChild: {
    padding: "10px 25px",
  },
}));

export const UploadSelectableCO: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <React.Fragment>
      <Grid item xs={8}>
        <Typography color="primary" className={classes.messageText}>
          <b>No hay registro de configuraciones</b>
        </Typography>
        <Typography
          className={classes.messageText}
          style={{ fontWeight: "lighter" }}
        >
          En Colombia solo se permite configurar los porcentajes ICA.
          <br />
          *Las retenciones de IVA (15%) y FUENTE (1.5%) serán preestablecidas
          para los comercios donde aplique.
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <ButtonGroup
          variant="contained"
          color="primary"
          size={"small"}
          className={classes.btnContent}
        >
          <Button
            id={"configRetention"}
            fullWidth
            className={classes.btnChild}
            onClick={() => {
              history.push(
                `${routes.BASE_PATH_RETENTIONS}${routes.WITH_CSV}?country=colombia`
              );
            }}
          >
            <Typography variant="h6">Configurar retenciones ICA</Typography>
          </Button>
        </ButtonGroup>
      </Grid>
    </React.Fragment>
  );
};
