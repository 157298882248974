import { chargeSlice } from "../../reducers/charge/charge.slice";

export const {
  addBankAccount,
  editBankAccount,
  editBeneficiaryName,
  updateBankAccountIndexOnFocus,
  updateFrequencyAndFraudForm,
  setIsLoadingChargeInformation,
} = chargeSlice.actions;
