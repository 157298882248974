import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@material-ui/core";
import CardPaymentMethod from "../../../../../assets/image/payment_methods_card.svg";
import CashPaymentMethod from "../../../../../assets/image/payment_methods_cash.svg";
import TransferPaymentMethod from "../../../../../assets/image/payment_methods_transfer.svg";
import { CustomMenuItem } from "../CustomMenuItem/CustomMenuItem";
import { CountryEnum } from "../../../../../shared/infrastructure/catalogs/CountryEnum";
import { routes } from "../../../../../shared/infrastructure/routes";
import {
  navigateWithMerchantInfoStorage,
  PayInOutPopupConstants,
} from "../../../../../shared/infrastructure/constants/CreateMerchantConstants";
import { get } from "lodash";

export interface IAccordionPopupProcessorMenuPayIn {
  id: string | null;
  country?: string | undefined;
  expanded: boolean;
  setActivePayIn: (type: string) => void;
}

export const AccordionPopupProcessorMenuPayIn: React.FC<IAccordionPopupProcessorMenuPayIn> = (
  props: React.PropsWithChildren<IAccordionPopupProcessorMenuPayIn>
) => {
  const handleChange = () => {
    props.expanded ? props.setActivePayIn("") : props.setActivePayIn("payin");
  };

  return (
    <Accordion
      defaultExpanded={false}
      expanded={get(props, "expanded", false)}
      elevation={0}
      onClick={handleChange}
    >
      <AccordionSummary>
        <Typography>{PayInOutPopupConstants.PAY_IN}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction={"column"}>
          <Grid item>
            <CustomMenuItem
              keyName={PayInOutPopupConstants.CARD}
              customNavigateFn={() =>
                navigateWithMerchantInfoStorage(
                  `${routes.PROCESSOR_CARD}/${props.id}`
                )
              }
              navigateTo={`${routes.PROCESSOR_CARD}/${props.id}`}
              menuTitle={PayInOutPopupConstants.CARD_TITLE}
              menuIcon={CardPaymentMethod}
              country={props.country}
            />
          </Grid>
          <Grid item>
            <CustomMenuItem
              keyName={PayInOutPopupConstants.TRANSFER}
              customNavigateFn={() =>
                navigateWithMerchantInfoStorage(
                  `${routes.PROCESSOR_TRANSFER}/${props.id}`
                )
              }
              navigateTo={`${routes.PROCESSOR_TRANSFER}/${props.id}`}
              menuTitle={PayInOutPopupConstants.TRANSFER_TITLE}
              menuIcon={TransferPaymentMethod}
              country={props.country}
            />
          </Grid>
          <Grid item>
            {(props.country === CountryEnum.COLOMBIA ||
              props.country === CountryEnum.MEXICO) && (
              <CustomMenuItem
                keyName={PayInOutPopupConstants.RECURRENT_DEBIT}
                customNavigateFn={() =>
                  navigateWithMerchantInfoStorage(
                    `${routes.PROCESSOR_TRANSFER_SUBSCRIPTION}/${props.id}`
                  )
                }
                navigateTo={`${routes.PROCESSOR_TRANSFER_SUBSCRIPTION}/${props.id}`}
                menuTitle={PayInOutPopupConstants.RECURRENT_DEBIT_TITLE}
                menuIcon={TransferPaymentMethod}
                country={props.country}
              />
            )}
          </Grid>
          <Grid item>
            <CustomMenuItem
              keyName={PayInOutPopupConstants.CASH}
              customNavigateFn={() =>
                navigateWithMerchantInfoStorage(
                  `${routes.PROCESSOR_CASH}/${props.id}`
                )
              }
              navigateTo={`${routes.PROCESSOR_CASH}/${props.id}`}
              menuTitle={PayInOutPopupConstants.CASH_TITLE}
              menuIcon={CashPaymentMethod}
              country={props.country}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
