import { ActionTypes } from "./actionTypes";
import { IMerchantForm } from "../infrastructure/interfaces/IMerchantForm";
import { IAppAction } from "./actionCreators";
import { IRoleInfo } from "../infrastructure/interfaces/IRoleInfo";
import { Color } from "@material-ui/lab/Alert";
import { IStatusSessionSupport } from "../infrastructure/interfaces/IStatusSessionSupport";

export interface IAppState {
  merchant?: IMerchantForm | null;
  activeRoute?: string;
  roleInfo?: IRoleInfo;
  appLoaded?: boolean;
  notification?: INotification;
  statusSession?: IStatusSessionSupport;
}

export interface INotification {
  message: string;
  show: boolean;
  type: Color;
}

export const INITIAL_STATE: IAppState = {
  activeRoute: "/dashboard",
  appLoaded: false,
  notification: { message: "", show: false, type: "success" },
};

export const reducer = (
  state: IAppState = INITIAL_STATE,
  action: IAppAction
): IAppState => {
  switch (action.type) {
    case ActionTypes.SET_ROLE_INFO:
      return {
        ...state,
        roleInfo: action.roleInfo,
      };
    case ActionTypes.SET_MERCHANT:
      return {
        ...state,
        merchant: action.merchant,
      };
    case ActionTypes.SET_ACTIVE_ROUTE:
      return {
        ...state,
        activeRoute: action.activeRoute,
      };
    case ActionTypes.SET_APP_LOADED:
      return {
        ...state,
        appLoaded: true,
      };
    case ActionTypes.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.notification,
      };
    case ActionTypes.SET_STATUS_SESSION_VALUES:
      return {
        ...state,
        statusSession: action.statusSession,
      };
    default:
      return state;
  }
};
