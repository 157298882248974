import React, { FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  acceptButton: {
    color: "#E24763",
    fontSize: 16,
    fontWeight: 500,
    margin: 0,
    padding: "7px 24px",
  },
  cancelButton: {
    color: "#293036",
    fontSize: 16,
    fontWeight: 500,
    margin: 0,
    padding: "7px 24px",
  },
  dialogPadding: {
    paddingBottom: 2,
    paddingTop: 24,
  },
  subtitle: {
    color: "#293036",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "150%",
  },
  title: {
    color: "#293036",
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "120%",
  },
});

export interface IConfirmationModalProps {
  isOpen: boolean;
  handleCancel: () => void;
  handleAccept: () => void;
}

const ConfirmationModal: FC<IConfirmationModalProps> = ({
  handleAccept,
  handleCancel,
  isOpen,
}: IConfirmationModalProps) => {
  const classes = useStyles();

  return (
    <Dialog open={isOpen} maxWidth={"xs"}>
      <DialogTitle className={classes.dialogPadding}>
        <Typography className={classes.title}>
          ¿Estás seguro que deseas guardar los cambios?
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography className={classes.subtitle}>
          Visualizarás los detalles en la sección diferidos del Comercio.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancel}
          variant={"text"}
          className={classes.cancelButton}
          id={"cancel-button"}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleAccept}
          variant={"text"}
          className={classes.acceptButton}
          id={"accept-button"}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
