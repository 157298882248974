import { get } from "lodash";
import { SessionStoragePaths } from "../../../shared/enums/sessionStoragePaths";
import { IDataWebsockets } from "../../../shared/interfaces/IDataWebSocket";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { ThunkAction } from "redux-thunk";
import { setProcessBranchFile } from "../../actions/processBranchFiles.actions";
import { IAppState } from "../../interfaces/AppState.interfaces";
import { IProcessBranchFile } from "../../interfaces/processBranchFile/processBranchFile.interfaces";
import { FileStatus } from "../../../shared/enums/FileStatus";
import {
  ISnackBar,
  ISnackBarWithAction,
} from "@kushki/connect-ui/dist/Components/Atoms/DataDisplay/SnackBar/SnackBar.interface";
import { MAX_RECORDS_OVER_500 } from "../../../shared/constants/snackbar_messages";
import { BranchResponse } from "../../../../types/validate_branch_response";

export type IAppAction = {
  type: string;
};

export const processBranchFile = (
  base64File: string,
  showSnackbar: (value: ISnackBar | ISnackBarWithAction) => void
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (dispatch): Promise<void> => {
    const jwt: string | null = localStorage.getItem("jwt");
    let webSocket: WebSocket | undefined;
    const params: object = {
      action: "processBranchFile",
      data: base64File,
    };
    let processBranchFileData: IProcessBranchFile = {
      validateBranchFileStatus: undefined,
    };

    dispatch(setProcessBranchFile(processBranchFileData));

    const validateDataBranchFormat = (
      branchList: BranchResponse[]
    ): BranchResponse[] => {
      return branchList.map((branch) => ({
        ...branch,
        constitutionalCountry: branch.country,
      }));
    };

    try {
      webSocket = new WebSocket(
        `${API_ROUTES.WS_MASSIVE}?Authorization=${jwt}`
      );
      webSocket.onopen = () => {
        webSocket?.send(JSON.stringify(params));
      };

      webSocket.onerror = () => {
        webSocket?.close();
      };

      webSocket.onmessage = (event: MessageEvent) => {
        const response: IDataWebsockets = JSON.parse(event.data);
        const status: string | undefined = get(
          response,
          "status",
          "server error"
        );
        const messageValidation: string = get(response, "message", "");

        if (status === "complete") {
          processBranchFileData = {
            branchList: validateDataBranchFormat(
              get(response, "branchList", [])
            ),
            summary: get(response, "summary", processBranchFileData),
            validateBranchFileStatus: FileStatus.success,
          };
          dispatch(setProcessBranchFile(processBranchFileData));
          webSocket?.close();
        }
        if (status === "error" || status === "server error") {
          showSnackbar(MAX_RECORDS_OVER_500);
          const fileStatus =
            status === "error" ? FileStatus.failed : FileStatus.error;

          sessionStorage.setItem(
            SessionStoragePaths.BRANCH_LIST,
            JSON.stringify([])
          );
          processBranchFileData = {
            messageValidation,
            validateBranchFileStatus: fileStatus,
          };
          dispatch(setProcessBranchFile(processBranchFileData));
          webSocket?.close();
        }
      };
    } catch (e) {
      if (webSocket) webSocket.close();
    }
  };
};
