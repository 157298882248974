import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import { IAppState } from "../../store/reducer";
import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  getAuthenticationRulesData,
  getCredentialData,
  getProcessorData,
  getRuleAnalystData,
  IAppAction,
} from "../../store/actionCreators";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Header } from "../../components/common/Header/Header";
import { SearchAuthenticationRulesRequest } from "../../../types/search_authentication_rules_request";
import { useRuleAnalystDashboardState } from "./state/useRuleAnalystDashboardState";
import { DashboardTable } from "../../components/Table/DashboardTable/DashboardTable";
import { get } from "lodash";
import { ResponseSearchRulesByFilters } from "../../../types/response_search_rules_by_filters";
import { SearchRuleAnalystRequest } from "../../../types/search_analyst_rules_request";
import { ProcessorInfoResponse } from "../../../types/processor_info_response";
import { CredentialInfoResponse } from "../../../types/credential_info_response";
import { AuthenticationRulesDashboard } from "../../shared/infrastructure/constants/AuthenticationRulesConstants";

export interface IRuleAnalystDashaboardStateProps {
  state: IAppState;
  isLoading: boolean;
  ruleAnalystProps: ResponseSearchRulesByFilters;
  processorData: ProcessorInfoResponse[];
  credentialData: CredentialInfoResponse[];
}

export interface IRuleAnalystDashaboardFunctionsProps {
  getAuthenticationRulesData: (
    params: SearchAuthenticationRulesRequest
  ) => void;
  getRuleAnalystDataProps: (params: SearchRuleAnalystRequest) => void;
  getProcessorData: (merchantId: string) => void;
  getCredentialData: (merchantId: string) => void;
}

export type TRuleAnalystDashaboardProps = IRuleAnalystDashaboardStateProps &
  IRuleAnalystDashaboardFunctionsProps;

const useStyles = makeStyles({
  titleContent: {
    lineHeight: "40% !important",
    marginTop: "20px",
  },
  topDashboard: {
    marginTop: "50px",
  },
  kshBtnFilter: {
    padding: "7px 30px",
  },
  textField: {
    padding: "0px 25px",
    height: "36px",
  },
});

export const RuleAnalystDashaboard: React.FC<TRuleAnalystDashaboardProps> = (
  props: TRuleAnalystDashaboardProps
) => {
  const classes = useStyles();
  const {
    breadCrumbs,
    handleSetDate,
    range,
    filters,
    handleSetFilters,
    counterFilter,
    filterChips,
    pagination,
    tableColumns,
    filterProps,
    modalOver,
    orderDataDashboard,
    handleAddRule,
    handleInputChange,
    handleSearchBar,
  } = useRuleAnalystDashboardState(props);

  return (
    <React.Fragment>
      <Container fixed>
        <Breadcrumb items={breadCrumbs.items} lastItem={breadCrumbs.lastItem} />
        <Typography
          variant="h1"
          color="primary"
          className={classes.titleContent}
        >
          {AuthenticationRulesDashboard.TITLE}
        </Typography>
        <Grid container direction={"column"} className={classes.topDashboard}>
          <Grid item xs={12}>
            <Header
              range={range}
              handleSetDate={handleSetDate}
              handleSetFilters={handleSetFilters}
              counterFilter={counterFilter}
              filters={filters}
              filterChips={filterChips}
              handleInputChange={handleInputChange}
              handleSearchBar={handleSearchBar}
            />
          </Grid>
        </Grid>
        <DashboardTable
          historicRulesData={props.ruleAnalystProps}
          pageSize={pagination.pageSize}
          page={pagination.page}
          paginationV2={true}
          hideNext={get(props, "hideNext", false)}
          hidePrevious={get(props, "hidePrevious", false)}
          handleChangePageSize={pagination.handleChangePageSize}
          handleChangePage={pagination.handleChangePage}
          tableColumns={tableColumns}
          country={""}
          filterProps={filterProps}
          isAnalyst={true}
          orderDataDashboard={orderDataDashboard}
          modalOver={modalOver}
          handleAddRule={handleAddRule}
          isLoading={props.isLoading}
        />
      </Container>
    </React.Fragment>
  );
};

export const mapStateToProps: (
  state: IAppState
) => IRuleAnalystDashaboardStateProps = (
  state: IAppState
): IRuleAnalystDashaboardStateProps => ({
  state,
  isLoading: state.isLoadingAnalyst,
  ruleAnalystProps: state.ruleAnalystData,
  processorData: state.processorData,
  credentialData: state.credentialData,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => IRuleAnalystDashaboardFunctionsProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): IRuleAnalystDashaboardFunctionsProps => ({
  getAuthenticationRulesData: (
    params: SearchAuthenticationRulesRequest
  ): void => dispatch(getAuthenticationRulesData(params)),
  getRuleAnalystDataProps: (params: SearchRuleAnalystRequest): void =>
    dispatch(getRuleAnalystData(params)),
  getProcessorData: (merchantId: string): void =>
    dispatch(getProcessorData(merchantId)),
  getCredentialData: (merchantId: string): void =>
    dispatch(getCredentialData(merchantId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RuleAnalystDashaboard);
