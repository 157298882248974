import { FilterSideBar } from "@kushki/connect-ui";
import { Box } from "@mui/material";
import { get, isEmpty, set } from "lodash";
import * as React from "react";
import { useEffect } from "react";
import {
  CountriesDefault,
  CountriesName,
  isValidCountry,
} from "../../shared/infrastructure/catalog/countries-catalog";
import { useAppSelector } from "../../store/hooks/storeHook";
import { RootState } from "../../store/interfaces/store.interfaces";
import { IFilterProps } from "./Filter.interfaces";

const Filter: React.FC<IFilterProps> = (props: IFilterProps) => {
  const { countryList } = useAppSelector(
    (state: RootState) => state.countryList
  );

  const getCountryList = () => {
    const countriesArray: string[] = [];

    if (!isEmpty(countryList)) {
      countryList?.forEach((item) => {
        const checkCountry = isValidCountry(item);

        if (checkCountry) countriesArray.push(checkCountry);
      });

      return countriesArray;
    }

    return CountriesName;
  };

  useEffect(() => {
    const localCountryList = getCountryList();
    const newCountries = localCountryList.map((item) => {
      return {
        label: CountriesDefault[item].label,
        selected: false,
        value: CountriesDefault[item].value,
      };
    });

    set(props, "dataItems.categoryItems[1]", {
      isMinimize: true,
      items: newCountries,
      onItemsSelect: () => {},
      placeHolder: "País de Constitución",
      selectType: "multiple",
      type: "byChips",
    });
  }, [countryList]);

  return (
    <Box>
      <FilterSideBar
        categoryItems={get(props, "dataItems.categoryItems", [])}
        isMinimized={false}
        isOpen={false}
        onClick={props.handleOnClick}
        title={"Filtrar por"}
        filtersCount={0}
      />
    </Box>
  );
};

export default Filter;
