import { useState } from "react";
import { SearchAuthenticationRulesRequest } from "../../../types/search_authentication_rules_request";
import { cloneDeep, isEmpty, set } from "lodash";
import { DateRange } from "@material-ui/pickers";
import { endOfDay, format, startOfDay, subDays } from "date-fns";
import { tzUTC } from "../../shared/infrastructure/constants/RuleRequestAuthenticationConstants";

export const FORMAT_SEARCH: string = "yyyy-MM-dd'T'HH:mm:ss";
export interface IUtilDashboard {
  pagination: IPagination;
  stateUtil: Partial<SearchAuthenticationRulesRequest>;
  filterData: {
    queryInput: string;
    handleInputChange: (value: string) => void;
    handleSearchBar: (value: string) => void;
    range: DateRange<Date>;
    fromDate: string;
    toDate: string;
    handleSetDate: (dateRange: DateRange<Date>) => void;
    filterChips: { [key: string]: boolean };
    counter: number;
    handleSetFilters: (filters: { [key: string]: boolean }) => void;
  };
}
export interface IUtilDashboardProps {
  filtersToBuild: IFiltersToBuild[];
}
interface IFiltersToBuild {
  data: any[];
  key: string;
  temArray?: any[];
}

interface IPagination {
  page: number;
  pageSize: number;
  handleChangePage: (newPage: number) => void;
  handleChangePageSize: (newPageSize: number) => void;
}
export const useUtilDashboard = (
  props: IUtilDashboardProps
): IUtilDashboard => {
  const { filtersToBuild } = props;
  const [page, setPage] = useState<number>(1);

  const [pageSize, setPageSize] = useState<number>(10);

  const [queryInput, setQueryInput] = useState<string>("");

  const [range, setDateRange] = useState<DateRange<Date>>([
    startOfDay(subDays(new Date(), 1)),
    endOfDay(new Date()),
  ]);

  const [fromDate, setStartDate] = useState<string>(
    format(startOfDay(subDays(new Date(), 1)), FORMAT_SEARCH)
  );

  const [toDate, setEndDate] = useState<string>(
    format(endOfDay(new Date()), FORMAT_SEARCH)
  );

  const [filters, setFilters] = useState<object>({});
  const [filterChips, setFilterChips] = useState({});
  const [counter, setCounter] = useState(0);

  /*---This State always be stay at end---*/
  const [stateUtil, setStateUtil] = useState<
    Partial<SearchAuthenticationRulesRequest>
  >({
    offset: 0,
    limit: pageSize,
    from: fromDate,
    to: toDate,
    format: "screen",
    filter: filters,
    timeZone: tzUTC,
  });

  /*---FUNCTIONS---*/
  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    handleUpdateState({}, newPage);
  };
  const handleChangePageSize = (newPageSize: number) => {
    setPageSize(newPageSize);
    handleUpdateState(
      {
        limit: newPageSize,
      },
      page,
      newPageSize
    );
  };
  const handleInputChange = (value: string) => {
    setQueryInput(value);
  };
  const handleSearchBar = (value: string) => {
    if (value === "Enter") {
      setPage(0);
      if (isEmpty(queryInput)) {
        handleUpdateState({}, 1);
      } else {
        handleUpdateState(
          {
            generalField: queryInput,
          },
          1
        );
      }
    }
  };
  const handleSetDate = (dateRange: DateRange<Date>) => {
    setDateRange(dateRange);
    const [firstDate, secondDate] = dateRange;
    const startDate: Date = new Date(firstDate?.toISOString()!);
    const endDate: Date = new Date(secondDate?.toISOString()!);

    const formatDateStart = format(startDate, FORMAT_SEARCH);
    const formatDateEnd = format(endDate, FORMAT_SEARCH);
    setStartDate(formatDateStart);
    setEndDate(formatDateEnd);
    handleUpdateState(
      {
        from: formatDateStart,
        to: formatDateEnd,
      },
      1
    );
  };
  const handleSetFilters = (filters: { [key: string]: boolean }): void => {
    setFilterChips(filters);
    const selectedFilters = Object.keys(filters).filter(
      (key: string) => filters[key]
    );
    setCounter(selectedFilters.length);
    let aux_data: IFiltersToBuild[] = cloneDeep(filtersToBuild);
    aux_data.forEach((item) => {
      set(item, "temArray", []);
    });
    const filtered = selectedFilters.reduce((obj, key) => {
      aux_data.forEach((item) => {
        if (item.data.includes(key)) {
          item.temArray!.push(key);
        }
      });
      aux_data.forEach((item) => {
        item.temArray!.length > 0
          ? (obj = { ...obj, [item.key]: item.temArray!.join("|") })
          : (obj = { ...obj });
      });
      return obj;
    }, {});
    setFilters(filtered);
    handleUpdateState(
      {
        filter: filtered,
      },
      1
    );
    return;
  };
  const handleUpdateState = (
    params: Partial<SearchAuthenticationRulesRequest>,
    newPage: number,
    newPageSize?: number
  ) => {
    const page_aux: number = newPage;
    const page_size_aux: number = newPageSize ? newPageSize : pageSize;

    let to_search = {
      ...stateUtil,
      ...params,
    };
    to_search.offset = page_size_aux * (page_aux - 1);
    setStateUtil({ ...to_search });
    setPage(page_aux);
    setPageSize(page_size_aux);
  };
  return {
    pagination: {
      page,
      pageSize,
      handleChangePageSize,
      handleChangePage,
    },
    stateUtil,
    filterData: {
      queryInput,
      handleInputChange,
      handleSearchBar,
      range,
      toDate,
      fromDate,
      handleSetDate,
      filterChips,
      counter,
      handleSetFilters,
    },
  };
};
