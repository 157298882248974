import React from "react";
import { StatusSteps } from "../../../../types/remote/semaphore_data";
import { AccountPreferencesAdditionalSection } from "./AccountPreferencesAdditionalSection";

export const AccountPreferencesAdditional: React.FC<any> = (
  _0: (step: string, status: StatusSteps) => void
) => {
  return (
    <React.Fragment>
      <AccountPreferencesAdditionalSection />
    </React.Fragment>
  );
};
