import { ModalSimple } from "@kushki/connect-ui";
import React from "react";
import { useModalExit } from "./state/useModalExit";
import { getSessionStorageValues } from "../../store/utils/sessionStorage-utils";

const ModalExit = () => {
  const {
    handlerCancelModalState,
    handlerChangeOpenModalState,
    modalButtonCancelText,
    modalButtonText,
    modalSubtitle,
    modalTitle,
    isButtonPrimaryDisabled,
    isOpenExitModal,
  } = useModalExit();

  return (
    <ModalSimple
      onClickPrimary={() => {
        handlerChangeOpenModalState();
      }}
      onClickSecondary={() => {
        handlerCancelModalState();
      }}
      buttonPrimary={modalButtonText}
      buttonSecondary={modalButtonCancelText}
      titleText={modalTitle}
      descriptionText={modalSubtitle}
      isOpen={isOpenExitModal}
      onClose={() => {
        handlerCancelModalState();
      }}
      typeModal={1}
      buttonPrimaryDisabled={isButtonPrimaryDisabled}
    />
  );
};

getSessionStorageValues();

export default ModalExit;
