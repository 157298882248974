import React, { SyntheticEvent, useEffect, useState } from "react";
import {
  ISelectNewItemList,
  ISelectNewItemListExtend,
  ISelectNewItemProps,
} from "../SelectNewItem.interfaces";
import { IUseSelectNewItemState } from "./useSelectNewItem.interfaces";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { FilterOptionsState } from "@mui/material";

const useSelectNewItem = (
  props: ISelectNewItemProps
): IUseSelectNewItemState => {
  let mapped: ISelectNewItemListExtend[] = props.items.map(
    (el: ISelectNewItemList, i: number) => {
      return { index: i, label: el.label, value: el.value };
    }
  );
  const [inputValueText, setInputValueText] = useState("");
  const [listOptions, setListOptions] =
    useState<ISelectNewItemListExtend[]>(mapped);
  const myDefaultOption = {
    label: "DefaultOption",
    value: "DefaultOption",
  };
  const _filterOptions = createFilterOptions();

  const handleClick = () => {
    setListOptions([
      ...listOptions,
      {
        index: listOptions.length,
        label: inputValueText,
        value: inputValueText,
      },
    ]);
    props.onAddButtonChange!(inputValueText);
  };
  const handleSelected = (_e: React.SyntheticEvent, value: any) => {
    props.onItemSelected(value);
  };

  const handleInputChange = (_e: SyntheticEvent, newInputValue: string) => {
    setInputValueText(newInputValue);
  };

  const filterOptions = (
    options: ISelectNewItemList[],
    state: FilterOptionsState<unknown>
  ): ISelectNewItemListExtend[] => {
    const results = _filterOptions(options, state);

    results.unshift(myDefaultOption);

    return results as ISelectNewItemListExtend[];
  };

  useEffect(() => {
    mapped = props.items.map((el, i) => {
      return { index: i, label: el.label, value: el.value };
    });

    setListOptions(mapped);
  }, [props.items]);

  useEffect(() => {
    if (props.orderBy == "alphabetical") {
      mapped.sort((a, b) => {
        return a.label > b.label ? 1 : -1;
      });

      const result = mapped.map(function (el) {
        return listOptions[el.index];
      });

      setListOptions(result);
    } else {
      setListOptions(mapped);
    }
  }, [props.orderBy]);

  return {
    addButtonText: props.addButtonText,
    error: props.error,
    filterOptions,
    handleClick,
    handleInputChange,
    handleOnChange: props.onChange,
    handleSelected,
    helperText: props.helperText,
    inputValueText,
    isDisabled: props.isDisabled,
    isLoading: props.isLoading,
    listOptions,
    placeholder: props.placeholder,
    setInputValueText,
    setListOptions,
    specialCharacters: props.specialCharacters,
  };
};

export { useSelectNewItem };
