import { IUseModalContainerBranch } from "../DataTableContainerBranch.interface";
import { useEffect, useState } from "react";
import { get } from "lodash";
import { IModalHeaderProps } from "@kushki/connect-ui/dist/Components/Molecules/ModalDetail/ModalHeader/ModalHeader.interfaces";
import { DateFormatEnum } from "../../../shared/infrastructure/constants/BusinessRuleConstants";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { RootState } from "../../../store/store";
import { showModalRuleDetail } from "../../../store/reducers/app/app";
import { IModalDetailBranchHeaderProps } from "../../../shared/interfaces/ModalDetailHeaderBranch";
import {
  IElementModalBody,
  IElementModalComponentProps,
  IModalInformation,
  IModalTemplateInfo,
} from "../../../shared/interfaces/ModalContainer";
import { PathsEnum } from "../../../shared/enums/PathsEnum";
import { LabelEnum } from "../../../shared/enums/LabelEnum";
import { Rule } from "../../../../types/get_payouts_rules_response";
import { MODAL_TEMPLATE_BRANCH } from "../../../shared/enums/ModalEnum";
import { format } from "date-fns-tz";

export const useModalContainer = (): IUseModalContainerBranch => {
  const dispatch = useAppDispatch();

  const { openModalRuleDetail, editRule, processorList, loadingModal } =
    useAppSelector((state: RootState) => ({
      ...state.app,
    }));

  const [modalHeader] = useState<Omit<IModalHeaderProps, "isLoading">>();

  const [headerRuleModal, setHeaderRuleModal] =
    useState<IModalDetailBranchHeaderProps>();
  const [ruleSettings, setRuleSetting] = useState<
    IElementModalComponentProps | undefined
  >();

  const buildHeaderRuleModal = (item: Rule | undefined): void => {
    const rule_status: string = get(item, PathsEnum.STATUS, "");
    const createdAt: number = get(item, PathsEnum.CREATED, 0);

    const dateTimeFormat: string = format(
      createdAt * 1000,
      DateFormatEnum.yyyy_mm_dd_hh_mm
    );

    setHeaderRuleModal({
      secondaryText: dateTimeFormat,
      status: rule_status.toLowerCase(),
      title: get(item, PathsEnum.RULE_NAME, ""),
    });
  };

  const buildRuleSetting = (item: Rule | undefined): void => {
    const template: IModalTemplateInfo =
      MODAL_TEMPLATE_BRANCH[LabelEnum.BUSINESS_RULES];
    const ruleSettingsColumns: IModalInformation = get(
      template,
      "ruleSetting.columns"
    );

    const rule: IElementModalBody[] = Object.keys(ruleSettingsColumns).map(
      (property: string) => ({
        label: get(ruleSettingsColumns, property, ""),
        secondaryText: "",
        text: get(item, property, ""),
      })
    );

    setRuleSetting({
      body: [...rule],
      isVisible: true,
      title: get(template, "ruleSetting.title", ""),
    });
  };

  const onChangeModal = (): void => {
    dispatch(showModalRuleDetail(false));
  };

  useEffect(() => {
    setRuleSetting(undefined);
    setHeaderRuleModal(undefined);
    buildHeaderRuleModal(editRule);
    buildRuleSetting(editRule);
  }, [editRule, processorList]);

  return {
    data: {
      ruleModalHeader: headerRuleModal!,
      ruleSetting: ruleSettings!,
    },
    header: modalHeader!,
    isLoadingOneRule: loadingModal.isLoading,
    isOpenModal: openModalRuleDetail,
    onChangeModal,
  };
};
