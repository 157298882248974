export enum TabItemStatusEnum {
  PENDING = "pending",
  COMPLETE = "complete",
  IN_PROCESS = "inProcess",
  OMITTED = "omitted",
  BLOCKED = "blocked",
}

export enum TabItemTitleEnum {
  NO_DISPONIBLE = "No disponible",
  EN_PROCESO = "En proceso",
  COMPLETADO = "Completado",
  PENDIENTE = "Pendiente",
}
