export enum RepresentativeContainerEnum {
  typePerson = "Tipo de Persona:",
  identificationNumber = "Nro. de Identificación:",
  residenceCountry = "País de residencia:",
  taxIdNumber = "Número de taxId:",
  merchantAddress = "País de domicilio de la empresa:",
  constitutionDate = "Fecha de constitución:",
  nationality = "Nacionalidad:",
  birthDate = "Fecha de nacimiento:",
}

export enum numberTypePerson {
  natural = "01",
  physic = "02",
}
