/* istanbul ignore file */

export enum CardBrandEnum {
  Visa = "Visa",
  Mastercard = "Mastercard",
  Jbc = "Jbc",
  Discover = "Discover",
  Diners = "Diners",
  Amex = "Amex",
  Alia = "Alia",
}
