import { IMerchantMigration } from "../../interfaces/merchantMigration.interfaces";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { exportMerchants } from "../../thunks/merchantMigration/merchantMigration.thunks";
import {
  ErrorCodes,
  ErrorMessages,
} from "../../../shared/constants/infrastructure/MerchantMigrationEnum";

export const initialState: IMerchantMigration = {};

export const merchantMigrationSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(exportMerchants.fulfilled, (state, action) => {
        state.responseState = action.payload;
      })
      .addCase(exportMerchants.rejected, (state, action) => {
        if (action.error.message!.includes(ErrorCodes.ON_PROCESS)) {
          state.responseState = {
            code: ErrorCodes.BCN020,
            message: ErrorMessages.ON_PROCESS,
          };
        } else {
          state.responseState = {
            code: ErrorCodes.UNKNOWN,
            message: ErrorMessages.UNKNOWN,
          };
        }
      });
  },
  initialState,
  name: "merchantMigration",
  reducers: {
    setResponseExport: (state, { payload }: PayloadAction<object>) => {
      state.responseState = payload;
    },
  },
});

export default merchantMigrationSlice.reducer;
