import { IDataSet } from "../../components/InputForms/InputsFormProps.interfaces";

export const nationalityCatalog: IDataSet[] = [
  {
    text: "United States",
    value: "United States",
  },
  {
    text: "Afghanistan",
    value: "Afghanistan",
  },
  {
    text: "Albania",
    value: "Albania",
  },
  {
    text: "Algeria",
    value: "Algeria",
  },
  {
    text: "American Samoa",
    value: "American Samoa",
  },
  {
    text: "Andorra",
    value: "Andorra",
  },
  {
    text: "Angola",
    value: "Angola",
  },
  {
    text: "Anguilla",
    value: "Anguilla",
  },
  {
    text: "Antigua and Barbuda",
    value: "Antigua and Barbuda",
  },
  {
    text: "Argentina",
    value: "Argentina",
  },
  {
    text: "Armenia",
    value: "Armenia",
  },
  {
    text: "Aruba",
    value: "Aruba",
  },
  {
    text: "Australia",
    value: "Australia",
  },
  {
    text: "Austria",
    value: "Austria",
  },
  {
    text: "Azerbaijan",
    value: "Azerbaijan",
  },
  {
    text: "The Bahamas",
    value: "The Bahamas",
  },
  {
    text: "Bahrain",
    value: "Bahrain",
  },
  {
    text: "Bangladesh",
    value: "Bangladesh",
  },
  {
    text: "Barbados",
    value: "Barbados",
  },
  {
    text: "Belarus",
    value: "Belarus",
  },
  {
    text: "Belgium",
    value: "Belgium",
  },
  {
    text: "Belize",
    value: "Belize",
  },
  {
    text: "Benin",
    value: "Benin",
  },
  {
    text: "Bermuda",
    value: "Bermuda",
  },
  {
    text: "Bhutan",
    value: "Bhutan",
  },
  {
    text: "Bolivia",
    value: "Bolivia",
  },
  {
    text: "Bosnia and Herzegovina",
    value: "Bosnia and Herzegovina",
  },
  {
    text: "Botswana",
    value: "Botswana",
  },
  {
    text: "Brazil",
    value: "Brazil",
  },
  {
    text: "Brunei",
    value: "Brunei",
  },
  {
    text: "Bulgaria",
    value: "Bulgaria",
  },
  {
    text: "Burkina Faso",
    value: "Burkina Faso",
  },
  {
    text: "Burundi",
    value: "Burundi",
  },
  {
    text: "Cambodia",
    value: "Cambodia",
  },
  {
    text: "Cameroon",
    value: "Cameroon",
  },
  {
    text: "Canada",
    value: "Canada",
  },
  {
    text: "Cape Verde",
    value: "Cape Verde",
  },
  {
    text: "Cayman Islands",
    value: "Cayman Islands",
  },
  {
    text: "Central African Republic",
    value: "Central African Republic",
  },
  {
    text: "Chad",
    value: "Chad",
  },
  {
    text: "Chile",
    value: "Chile",
  },
  {
    text: "China",
    value: "China",
  },
  {
    text: "Christmas Island",
    value: "Christmas Island",
  },
  {
    text: "Cocos (Keeling) Islands",
    value: "Cocos (Keeling) Islands",
  },
  {
    text: "Colombia",
    value: "Colombia",
  },
  {
    text: "Comoros",
    value: "Comoros",
  },
  {
    text: "Congo",
    value: "Congo",
  },
  {
    text: "Cook Islands",
    value: "Cook Islands",
  },
  {
    text: "Costa Rica",
    value: "Costa Rica",
  },
  {
    text: "Cote d'Ivoire",
    value: "Cote d'Ivoire",
  },
  {
    text: "Croatia",
    value: "Croatia",
  },
  {
    text: "Cuba",
    value: "Cuba",
  },
  {
    text: "Curacao",
    value: "Curacao",
  },
  {
    text: "Cyprus",
    value: "Cyprus",
  },
  {
    text: "Czech Republic",
    value: "Czech Republic",
  },
  {
    text: "Democratic Republic of the Congo",
    value: "Democratic Republic of the Congo",
  },
  {
    text: "Denmark",
    value: "Denmark",
  },
  {
    text: "Djibouti",
    value: "Djibouti",
  },
  {
    text: "Dominica",
    value: "Dominica",
  },
  {
    text: "Dominican Republic",
    value: "Dominican Republic",
  },
  {
    text: "Ecuador",
    value: "Ecuador",
  },
  {
    text: "Egypt",
    value: "Egypt",
  },
  {
    text: "El Salvador",
    value: "El Salvador",
  },
  {
    text: "Equatorial Guinea",
    value: "Equatorial Guinea",
  },
  {
    text: "Eritrea",
    value: "Eritrea",
  },
  {
    text: "Estonia",
    value: "Estonia",
  },
  {
    text: "Ethiopia",
    value: "Ethiopia",
  },
  {
    text: "Falkland Islands",
    value: "Falkland Islands",
  },
  {
    text: "Faroe Islands",
    value: "Faroe Islands",
  },
  {
    text: "Fiji",
    value: "Fiji",
  },
  {
    text: "Finland",
    value: "Finland",
  },
  {
    text: "France",
    value: "France",
  },
  {
    text: "French Polynesia",
    value: "French Polynesia",
  },
  {
    text: "Gabon",
    value: "Gabon",
  },
  {
    text: "The Gambia",
    value: "The Gambia",
  },
  {
    text: "Georgia",
    value: "Georgia",
  },
  {
    text: "Germany",
    value: "Germany",
  },
  {
    text: "Ghana",
    value: "Ghana",
  },
  {
    text: "Gibraltar",
    value: "Gibraltar",
  },
  {
    text: "Greece",
    value: "Greece",
  },
  {
    text: "Greenland",
    value: "Greenland",
  },
  {
    text: "Grenada",
    value: "Grenada",
  },
  {
    text: "Guadeloupe",
    value: "Guadeloupe",
  },
  {
    text: "Guam",
    value: "Guam",
  },
  {
    text: "Guatemala",
    value: "Guatemala",
  },
  {
    text: "Guernsey",
    value: "Guernsey",
  },
  {
    text: "Guinea",
    value: "Guinea",
  },
  {
    text: "Guinea-Bissau",
    value: "Guinea-Bissau",
  },
  {
    text: "Guyana",
    value: "Guyana",
  },
  {
    text: "Haiti",
    value: "Haiti",
  },
  {
    text: "Honduras",
    value: "Honduras",
  },
  {
    text: "Hong Kong",
    value: "Hong Kong",
  },
  {
    text: "Hungary",
    value: "Hungary",
  },
  {
    text: "Iceland",
    value: "Iceland",
  },
  {
    text: "India",
    value: "India",
  },
  {
    text: "Indonesia",
    value: "Indonesia",
  },
  {
    text: "Iran",
    value: "Iran",
  },
  {
    text: "Iraq",
    value: "Iraq",
  },
  {
    text: "Ireland",
    value: "Ireland",
  },
  {
    text: "Israel",
    value: "Israel",
  },
  {
    text: "Italy",
    value: "Italy",
  },
  {
    text: "Jamaica",
    value: "Jamaica",
  },
  {
    text: "Japan",
    value: "Japan",
  },
  {
    text: "Jersey",
    value: "Jersey",
  },
  {
    text: "Jordan",
    value: "Jordan",
  },
  {
    text: "Kazakhstan",
    value: "Kazakhstan",
  },
  {
    text: "Kenya",
    value: "Kenya",
  },
  {
    text: "Kiribati",
    value: "Kiribati",
  },
  {
    text: "North Korea",
    value: "North Korea",
  },
  {
    text: "South Korea",
    value: "South Korea",
  },
  {
    text: "Kosovo",
    value: "Kosovo",
  },
  {
    text: "Kuwait",
    value: "Kuwait",
  },
  {
    text: "Kyrgyzstan",
    value: "Kyrgyzstan",
  },
  {
    text: "Laos",
    value: "Laos",
  },
  {
    text: "Latvia",
    value: "Latvia",
  },
  {
    text: "Lebanon",
    value: "Lebanon",
  },
  {
    text: "Lesotho",
    value: "Lesotho",
  },
  {
    text: "Liberia",
    value: "Liberia",
  },
  {
    text: "Libya",
    value: "Libya",
  },
  {
    text: "Liechtenstein",
    value: "Liechtenstein",
  },
  {
    text: "Lithuania",
    value: "Lithuania",
  },
  {
    text: "Luxembourg",
    value: "Luxembourg",
  },
  {
    text: "Macau",
    value: "Macau",
  },
  {
    text: "Macedonia",
    value: "Macedonia",
  },
  {
    text: "Madagascar",
    value: "Madagascar",
  },
  {
    text: "Malawi",
    value: "Malawi",
  },
  {
    text: "Malaysia",
    value: "Malaysia",
  },
  {
    text: "Maldives",
    value: "Maldives",
  },
  {
    text: "Mali",
    value: "Mali",
  },
  {
    text: "Malta",
    value: "Malta",
  },
  {
    text: "Marshall Islands",
    value: "Marshall Islands",
  },
  {
    text: "Martinique",
    value: "Martinique",
  },
  {
    text: "Mauritania",
    value: "Mauritania",
  },
  {
    text: "Mauritius",
    value: "Mauritius",
  },
  {
    text: "Mayotte",
    value: "Mayotte",
  },
  {
    text: "Mexico",
    value: "Mexico",
  },
  {
    text: "Micronesia",
    value: "Micronesia",
  },
  {
    text: "Moldova",
    value: "Moldova",
  },
  {
    text: "Monaco",
    value: "Monaco",
  },
  {
    text: "Mongolia",
    value: "Mongolia",
  },
  {
    text: "Montenegro",
    value: "Montenegro",
  },
  {
    text: "Montserrat",
    value: "Montserrat",
  },
  {
    text: "Morocco",
    value: "Morocco",
  },
  {
    text: "Mozambique",
    value: "Mozambique",
  },
  {
    text: "Myanmar",
    value: "Myanmar",
  },
  {
    text: "Nagorno-Karabakh",
    value: "Nagorno-Karabakh",
  },
  {
    text: "Namibia",
    value: "Namibia",
  },
  {
    text: "Nauru",
    value: "Nauru",
  },
  {
    text: "Nepal",
    value: "Nepal",
  },
  {
    text: "Netherlands",
    value: "Netherlands",
  },
  {
    text: "Netherlands Antilles",
    value: "Netherlands Antilles",
  },
  {
    text: "New Caledonia",
    value: "New Caledonia",
  },
  {
    text: "New Zealand",
    value: "New Zealand",
  },
  {
    text: "Nicaragua",
    value: "Nicaragua",
  },
  {
    text: "Niger",
    value: "Niger",
  },
  {
    text: "Nigeria",
    value: "Nigeria",
  },
  {
    text: "Niue",
    value: "Niue",
  },
  {
    text: "Norfolk Island",
    value: "Norfolk Island",
  },
  {
    text: "Turkish Republic of Northern Cyprus",
    value: "Turkish Republic of Northern Cyprus",
  },
  {
    text: "Northern Mariana",
    value: "Northern Mariana",
  },
  {
    text: "Norway",
    value: "Norway",
  },
  {
    text: "Oman",
    value: "Oman",
  },
  {
    text: "Pakistan",
    value: "Pakistan",
  },
  {
    text: "Palau",
    value: "Palau",
  },
  {
    text: "Palestine",
    value: "Palestine",
  },
  {
    text: "Panama",
    value: "Panama",
  },
  {
    text: "Papua New Guinea",
    value: "Papua New Guinea",
  },
  {
    text: "Paraguay",
    value: "Paraguay",
  },
  {
    text: "Peru",
    value: "Peru",
  },
  {
    text: "Philippines",
    value: "Philippines",
  },
  {
    text: "Pitcairn Islands",
    value: "Pitcairn Islands",
  },
  {
    text: "Poland",
    value: "Poland",
  },
  {
    text: "Portugal",
    value: "Portugal",
  },
  {
    text: "Puerto Rico",
    value: "Puerto Rico",
  },
  {
    text: "Qatar",
    value: "Qatar",
  },
  {
    text: "Republic of the Congo",
    value: "Republic of the Congo",
  },
  {
    text: "Romania",
    value: "Romania",
  },
  {
    text: "Russia",
    value: "Russia",
  },
  {
    text: "Rwanda",
    value: "Rwanda",
  },
  {
    text: "Saint Barthelemy",
    value: "Saint Barthelemy",
  },
  {
    text: "Saint Helena",
    value: "Saint Helena",
  },
  {
    text: "Saint Kitts and Nevis",
    value: "Saint Kitts and Nevis",
  },
  {
    text: "Saint Lucia",
    value: "Saint Lucia",
  },
  {
    text: "Saint Martin",
    value: "Saint Martin",
  },
  {
    text: "Saint Pierre and Miquelon",
    value: "Saint Pierre and Miquelon",
  },
  {
    text: "Saint Vincent and the Grenadines",
    value: "Saint Vincent and the Grenadines",
  },
  {
    text: "Samoa",
    value: "Samoa",
  },
  {
    text: "San Marino",
    value: "San Marino",
  },
  {
    text: "Sao Tome and Principe",
    value: "Sao Tome and Principe",
  },
  {
    text: "Saudi Arabia",
    value: "Saudi Arabia",
  },
  {
    text: "Senegal",
    value: "Senegal",
  },
  {
    text: "Serbia",
    value: "Serbia",
  },
  {
    text: "Seychelles",
    value: "Seychelles",
  },
  {
    text: "Sierra Leone",
    value: "Sierra Leone",
  },
  {
    text: "Singapore",
    value: "Singapore",
  },
  {
    text: "Slovakia",
    value: "Slovakia",
  },
  {
    text: "Slovenia",
    value: "Slovenia",
  },
  {
    text: "Solomon Islands",
    value: "Solomon Islands",
  },
  {
    text: "Somalia",
    value: "Somalia",
  },
  {
    text: "Somaliland",
    value: "Somaliland",
  },
  {
    text: "South Africa",
    value: "South Africa",
  },
  {
    text: "South Ossetia",
    value: "South Ossetia",
  },
  {
    text: "South Sudan",
    value: "South Sudan",
  },
  {
    text: "Spain",
    value: "Spain",
  },
  {
    text: "Sri Lanka",
    value: "Sri Lanka",
  },
  {
    text: "Sudan",
    value: "Sudan",
  },
  {
    text: "Suriname",
    value: "Suriname",
  },
  {
    text: "Svalbard",
    value: "Svalbard",
  },
  {
    text: "eSwatini",
    value: "eSwatini",
  },
  {
    text: "Sweden",
    value: "Sweden",
  },
  {
    text: "Switzerland",
    value: "Switzerland",
  },
  {
    text: "Syria",
    value: "Syria",
  },
  {
    text: "Taiwan",
    value: "Taiwan",
  },
  {
    text: "Tajikistan",
    value: "Tajikistan",
  },
  {
    text: "Tanzania",
    value: "Tanzania",
  },
  {
    text: "Thailand",
    value: "Thailand",
  },
  {
    text: "Timor-Leste",
    value: "Timor-Leste",
  },
  {
    text: "Togo",
    value: "Togo",
  },
  {
    text: "Tokelau",
    value: "Tokelau",
  },
  {
    text: "Tonga",
    value: "Tonga",
  },
  {
    text: "Transnistria Pridnestrovie",
    value: "Transnistria Pridnestrovie",
  },
  {
    text: "Trinidad and Tobago",
    value: "Trinidad and Tobago",
  },
  {
    text: "Tristan da Cunha",
    value: "Tristan da Cunha",
  },
  {
    text: "Tunisia",
    value: "Tunisia",
  },
  {
    text: "Turkey",
    value: "Turkey",
  },
  {
    text: "Turkmenistan",
    value: "Turkmenistan",
  },
  {
    text: "Turks and Caicos Islands",
    value: "Turks and Caicos Islands",
  },
  {
    text: "Tuvalu",
    value: "Tuvalu",
  },
  {
    text: "Uganda",
    value: "Uganda",
  },
  {
    text: "Ukraine",
    value: "Ukraine",
  },
  {
    text: "United Arab Emirates",
    value: "United Arab Emirates",
  },
  {
    text: "United Kingdom",
    value: "United Kingdom",
  },
  {
    text: "Uruguay",
    value: "Uruguay",
  },
  {
    text: "Uzbekistan",
    value: "Uzbekistan",
  },
  {
    text: "Vanuatu",
    value: "Vanuatu",
  },
  {
    text: "Vatican City",
    value: "Vatican City",
  },
  {
    text: "Venezuela",
    value: "Venezuela",
  },
  {
    text: "Vietnam",
    value: "Vietnam",
  },
  {
    text: "British Virgin Islands",
    value: "British Virgin Islands",
  },
  {
    text: "Isle of Man",
    value: "Isle of Man",
  },
  {
    text: "US Virgin Islands",
    value: "US Virgin Islands",
  },
  {
    text: "Wallis and Futuna",
    value: "Wallis and Futuna",
  },
  {
    text: "Western Sahara",
    value: "Western Sahara",
  },
  {
    text: "Yemen",
    value: "Yemen",
  },
  {
    text: "Zambia",
    value: "Zambia",
  },
  {
    text: "Zimbabwe",
    value: "Zimbabwe",
  },
];
