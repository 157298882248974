import { createNamedStyles } from "../../shared/interfaces/create_named_styles";
import theme from "../../theme";

export const addWebhookFormStyles = createNamedStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: (theme) => theme.spacing(5),
  },
  descriptionText: {
    color: "text.grey",
    fontSize: theme.spacing(1.875),
    fontWeight: 400,
  },
  paper: {
    bgcolor: theme.palette.background.default,
    borderRadius: 4,
    minHeight: theme.spacing(94),
    p: (theme) => theme.spacing(6, 8, 6, 8),
    w: 1,
  },
  divider: {
    borderStyle: "solid",
    mb: 4,
    mt: 2,
  },
  paperAdvancedConfig: {
    bgcolor: theme.palette.background.default,
    borderRadius: 4,
    minHeight: theme.spacing(46.375),
    p: (theme) => theme.spacing(6, 8, 6, 8),
    w: 1,
  },
  subtitle: {
    color: "text.disabled",
    fontSize: theme.spacing(1.875),
    mt: 2,
  },
  addBoxButton: {
    mt: 2,
    minWidth: theme.spacing(19),
  },
  subtitleUrl: {
    color: "text.disabled",
    fontSize: theme.spacing(1.625),
  },
  infoUrl: {
    fontSize: theme.spacing(1.625),
    color: "text.dark",
  },
  textField: {
    w: 1,
  },
  configurationContent: {
    display: "flex",
    flexDirection: "column",
    rowGap: (theme) => theme.spacing(2),
  },
  inputConfigurationContent: {
    display: "flex",
    flexDirection: "row",
    columnGap: (theme) => theme.spacing(1),
  },
  verticalContainer: {
    display: "flex",
    flexDirection: "column",
  },
  headerItem: {
    display: "flex",
    flexDirection: "row",
    height: theme.spacing(9.5),
    p: (theme) => theme.spacing(2.0625, 2, 2.0625, 2),
  },
  headerEditableItem: {
    display: "flex",
    flexDirection: "row",
    height: theme.spacing(9.5),
    columnGap: (theme) => theme.spacing(2),
    p: (theme) => theme.spacing(2.0625, 0, 2.0625, 0),
  },
  editButtons: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "row",
    columnGap: (theme) => theme.spacing(1),
    minWidth: theme.spacing(17),
  },
  textFieldHeader: {
    color: "red",
  },
  dividerHeaders: {
    borderStyle: "solid",
  },
  urlBoxContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: (theme) => theme.spacing(3),
  },
  subtitleBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: (theme) => theme.spacing(1),
  },
  inputUrlBox: {
    display: "flex",
    flexDirection: "row",
    columnGap: (theme) => theme.spacing(3),
  },
  infoIconStyle: {
    display: "flex",
    justifySelf: "self-start",
    mt: 0.25,
  },
  errorMessage: {
    fontSize: theme.spacing(1.625),
    color: "error.dark2",
  },
  containerFooter: {
    bottom: 0,
    position: "fixed",
    width: 1,
    left: "19.2%",
    zIndex: 99,
  },
});
