import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
  },
  circle: {
    width: 10,
    height: 10,
    borderRadius: 10 / 2,
    backgroundColor: "#1E65AE",
  },
  overline: {
    lineHeight: "120%",
    fontWeight: 400,
    fontSize: 13,
    fontStyle: "normal",
  },
}));
