export enum CategoryValue {
  SMALL = "Small",
  MEDIUM = "Medium",
  ENTERPRISE = "Enterprise",
}

export enum CategoryLabel {
  SMALL = "Small",
  MEDIUM = "Medium",
  ENTERPRISE = "Enterprise",
}
