import { InputAdornment, TextField } from "@mui/material";
import { Control, Controller, DeepMap, FieldError } from "react-hook-form";
import { ErrorEnum } from "../../../shared/enums/ErrorEnum";
import { LabelEnum } from "../../../shared/enums/LabelEnum";
import { get } from "lodash";
import { validateAlias } from "../../../shared/utils/form-utils";
import React from "react";
import { FormFieldEnum } from "../../../shared/enums/FormFieldEnum";
import { Rule } from "../../../../types/get_payouts_rules_response";

export interface AliasComponentProps {
  control: Control<Record<string, any>>;
  getRuleById: Rule | undefined;
  rules: Rule[] | undefined;
  formErrors: [boolean, string];
  errors: DeepMap<Record<string, any>, FieldError>;
  prefixAtomicCounter?: string;
}

export const AliasComponent = ({
  control,
  getRuleById,
  rules,
  errors,
  formErrors,
  prefixAtomicCounter,
}: AliasComponentProps) => {
  return (
    <Controller
      control={control}
      name={FormFieldEnum.RULE_NAME}
      defaultValue=""
      rules={{
        required: ErrorEnum.REQUIRED_FIELD,
        validate: (value) =>
          validateAlias(value, getRuleById, rules) || ErrorEnum.ALIAS_REPEATED,
      }}
      render={(field) => (
        <TextField
          {...field}
          name={FormFieldEnum.RULE_NAME}
          label={LabelEnum.ALIAS}
          margin="normal"
          variant="outlined"
          fullWidth
          error={get(errors, FormFieldEnum.RULE_NAME)}
          helperText={formErrors[1]}
          InputProps={{
            startAdornment: prefixAtomicCounter && (
              <InputAdornment position="start">
                {prefixAtomicCounter}
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};
