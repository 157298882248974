import React from "react";
import { Button, Container, Grid } from "@mui/material";
import { IconChevronLeft, ModalLoader, ModalSimple } from "@kushki/connect-ui";
import { FormProvider } from "react-hook-form";
import { GlobalInformationComponent } from "../../components/GlobalInformationComponent/GlobalInformationComponent";
import { LabelEnum } from "../../shared/infrastructure/enums/LabelEnum";
import { ActionButtons } from "../../components/ActionButtons/ActionButtons";
import { useCreateBusinessRuleIndexState } from "./state/useCreateBusinessRuleIndexState";

export const CreateBusinessRuleIndex: React.FC = () => {
  const { form, actions, fields, loadingModal, saveRuleModal, editRule } =
    useCreateBusinessRuleIndexState();
  return (
    <React.Fragment>
      <Container fixed>
        <Button
          onClick={actions.handleBack}
          startIcon={
            <IconChevronLeft className={"buttonLink"} fontSize="medium" />
          }
          className={"buttonLink"}
          color="primary"
        >
          {LabelEnum.BACK}
        </Button>
        <FormProvider {...form}>
          <Grid container>
            <GlobalInformationComponent actions={actions} fields={fields} />
            <ActionButtons
              editRule={editRule}
              fields={fields}
              onAddRule={form.handleSubmit(actions.handleSaveRuleEnable)}
              onRedirectPath={actions.handleRedirectPath}
              onSaveDraft={form.handleSubmit(actions.handleSaveRuleDraft)}
            />

            <ModalLoader
              descriptionText=" "
              titleText={loadingModal.message}
              isOpen={loadingModal.isLoading}
            />
            <ModalSimple
              buttonPrimary={saveRuleModal.buttonPrimaryText}
              buttonSecondary={saveRuleModal.buttonSecondary}
              descriptionText={saveRuleModal.descriptionText}
              onClickPrimary={() => actions.handlePrimaryButtonRuleModal()}
              onClickSecondary={() => actions.handleSecondaryButtonRuleModal()}
              onClose={() => actions.handleSecondaryButtonRuleModal()}
              titleText={saveRuleModal.titleText}
              typeModal={1}
              buttonPrimaryDisabled={false}
              isOpen={saveRuleModal.isOpen}
            />
          </Grid>
        </FormProvider>
      </Container>
    </React.Fragment>
  );
};
