import React, { FC } from "react";
import { ModalOverlay } from "@kushki/frontend-molecules/modal";
import DetailHeader from "./DetailHeader/DetailHeader";
import { ITransactionModal } from "../../../shared/interfaces/TransactionModal.interfaces";
import { TRANSATION_MODAL_STEPS } from "../../../shared/constants/transaction_modal_steps";
import { useSelectedTransactionModalState } from "./state/useSelectedTransactionModalState";
import DetailFooter from "./DetailFooter/DetailFooter";

const SelectedTransactionModal: FC<ITransactionModal> = (
  props: ITransactionModal
) => {
  const { isOpen, onCloseModal, transaction } = props;
  const { actualTab, handleChangeTab } = useSelectedTransactionModalState();
  const Content = TRANSATION_MODAL_STEPS[actualTab];

  return (
    <ModalOverlay
      header={
        <DetailHeader
          id={transaction.ticketNumber}
          date={transaction.date}
          hour={transaction.hour}
          status={transaction.status}
          url={transaction.certificateURL}
          actualTab={actualTab}
          handleChangeTab={handleChangeTab}
        />
      }
      body={<Content transaction={transaction} />}
      footer={<DetailFooter transaction={transaction} />}
      openModal={isOpen}
      onCloseModal={onCloseModal}
      transition="left"
    />
  );
};

export default SelectedTransactionModal;
