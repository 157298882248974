import { CountryEnum } from "../countryEnum";

export enum CountryCodeEnum {
  EC = "EC",
  MX = "MX",
  CO = "CO",
  BR = "BR",
  AS = "AS",
  CL = "CL",
  PA = "PA",
  PE = "PE",
  SV = "SV",
  HN = "HN",
  GT = "GT",
  NI = "NI",
  CR = "CR",
  GLB = "GLB",
}

export const CountryCode: Record<string, CountryCodeEnum> = {
  [CountryEnum.ecuador]: CountryCodeEnum.EC,
  [CountryEnum.mexico]: CountryCodeEnum.MX,
  [CountryEnum.brasil]: CountryCodeEnum.BR,
  [CountryEnum.usa]: CountryCodeEnum.AS,
  [CountryEnum.chile]: CountryCodeEnum.CL,
  [CountryEnum.panama]: CountryCodeEnum.PA,
  [CountryEnum.peru]: CountryCodeEnum.PE,
  [CountryEnum.el_salvador]: CountryCodeEnum.SV,
  [CountryEnum.honduras]: CountryCodeEnum.HN,
  [CountryEnum.guatemala]: CountryCodeEnum.GT,
  [CountryEnum.nicaragua]: CountryCodeEnum.NI,
  [CountryEnum.costa_rica]: CountryCodeEnum.CR,
  [CountryEnum.colombia]: CountryCodeEnum.CO,
};
