import { appSlice } from "../reducers/app/app";

export const {
  resetFirebaseId,
  setLoadingDownload,
  setIsOpenMassiveStatusModal,
  setIsOpenMassiveStatusEmailsModal,
  setIsOpenMassiveStatusInstructionsModal,
  setIsLoadingMassiveStatus,
} = appSlice.actions;
