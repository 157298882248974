import {
  Operators,
  PlaceHolders,
  TextFieldsNames,
} from "../../../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { useEffect, useState } from "react";
import { toNumber, isEmpty } from "lodash";
import { FieldName, useFormContext } from "react-hook-form";
import { ErrorsEnum } from "../../../../../shared/infrastructure/enums/ErrorEnum";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";
import { LabelEnum } from "../../../../../shared/infrastructure/enums/LabelEnum";
import { getRawNumber } from "../../../../../utils/utilsFile";

export interface ActionsInterface {
  getFirstPlaceHolder: () => void;
  getSecondPlaceHolder: () => void;
  getOtherPlaceholder: (value: string) => void;
  validateAmounts: (
    firstValue: string,
    secondValue: string,
    type: string
  ) => string | undefined;
  getValues: () => number[];
}

export interface useAmountResponseInterface {
  actions: ActionsInterface;
  firstPlaceHolder: string;
  secondPlaceHolder: string;
}

export interface useAmountRequestInterface {
  operator: string;
  firstValue: string;
  secondValue: string;
  setValue: any;
  clearErrors: (
    name?: FieldName<Record<string, any>> | FieldName<Record<string, any>>[]
  ) => void;
  name: string;
  setSelectedItems: (value: ICategory[]) => void;
  defaultValueList: string[];
  isDisable: boolean;
}

export const useAmountConditionState = (
  props: useAmountRequestInterface
): useAmountResponseInterface => {
  const { operator, firstValue, secondValue, clearErrors, setValue, name } =
    props;
  const { control } = useFormContext();
  const [firstPlaceHolder, setFirstPlaceHolder] = useState<string>("");
  const [secondPlaceHolder, setSecondPlaceHolder] = useState<string>("");

  const getFirstPlaceHolder = (): void => {
    operator != Operators.BETWEEN
      ? setFirstPlaceHolder(PlaceHolders.AMOUNT_VALUE)
      : setFirstPlaceHolder(PlaceHolders.AMOUNT_FIRST_VALUE);
  };

  const getSecondPlaceHolder = (): void => {
    setSecondPlaceHolder(PlaceHolders.AMOUNT_SECOND_VALUE);
  };

  const getOtherPlaceholder = (firstValue: string): void => {
    firstValue.length > 0 && operator != Operators.BETWEEN
      ? setFirstPlaceHolder(PlaceHolders.AMOUNT_SINGLE_VALUE)
      : getFirstPlaceHolder();
  };

  const validateAmounts = (
    firstValue: string,
    secondValue: string,
    type: string
  ): string | undefined => {
    if (
      operator != Operators.BETWEEN ||
      isEmpty(firstValue) ||
      isEmpty(secondValue)
    )
      return undefined;

    if (type === TextFieldsNames.FirstAmount)
      return toNumber(firstValue) >= toNumber(secondValue)
        ? ErrorsEnum.LESS_THAN_ERROR
        : undefined;

    return toNumber(firstValue) >= toNumber(secondValue)
      ? ErrorsEnum.GREATER_THAN_ERROR
      : undefined;
  };

  const getValues = (): number[] => {
    const rawFirstValue = getRawNumber(firstValue);
    const rawSecondValue = getRawNumber(secondValue);

    if (operator != Operators.BETWEEN) {
      control.validFieldsRef.current.firstAmount = true;
      if (rawFirstValue === "") {
        props.setSelectedItems([]);
        return [Number(rawFirstValue)];
      }
      props.setSelectedItems([
        {
          label: LabelEnum.AMOUNT,
          value: [Number(rawFirstValue)].toString(),
          selected: true,
        },
      ]);

      return [Number(rawFirstValue)];
    } else {
      control.validFieldsRef.current.firstAmount = true;
      control.validFieldsRef.current.secondAmount = true;
      props.setSelectedItems([
        {
          label: LabelEnum.AMOUNT,
          value: [Number(rawFirstValue), Number(rawSecondValue)].toString(),
          selected: true,
        },
      ]);

      return [Number(rawFirstValue), Number(rawSecondValue)];
    }
  };

  useEffect(() => {
    if (!operator) {
      return;
    }
    setValue(name, getValues());
    setValue(TextFieldsNames.FirstAmount, firstValue);
    setValue(TextFieldsNames.SecondAmount, secondValue);
  }, [props.firstValue, props.secondValue, operator]);

  const clearData = (): void => {
    if (props.isDisable) {
      return;
    }
    setFirstPlaceHolder("");
    setSecondPlaceHolder("");
    clearErrors(TextFieldsNames.FirstAmount);
    clearErrors(TextFieldsNames.SecondAmount);
    setValue(TextFieldsNames.FirstAmount, "");
    setValue(TextFieldsNames.SecondAmount, "");
    setValue(name, "");
  };

  useEffect(() => {
    clearData();
    getFirstPlaceHolder();
    getSecondPlaceHolder();

    if (firstValue.length > 0) getOtherPlaceholder(firstValue);
  }, [operator]);

  return {
    actions: {
      getFirstPlaceHolder,
      getSecondPlaceHolder,
      getOtherPlaceholder,
      validateAmounts,
      getValues,
    },
    firstPlaceHolder,
    secondPlaceHolder,
  };
};
