import React from "react";
import { Grid, Typography } from "@mui/material";
import { IChipConciliationAction } from "./ChipConciliationAction.interfaces";
import { defaultTo } from "lodash";
import { CONCILIATION_ACTION_TRANSLATE } from "../../../shared/infrastructure/constants/ConciliationAction";
import { chipConciliationActionStyles } from "./ChipConciliationAction.styles";

const ChipConciliationAction = (props: IChipConciliationAction) => {
  const conciliation_action: string[] = defaultTo(
    CONCILIATION_ACTION_TRANSLATE[props.conciliationAction],
    ""
  ).split(" - ");

  return (
    <Grid>
      <Typography sx={chipConciliationActionStyles.title}>
        {conciliation_action[0]}
      </Typography>
      {conciliation_action.length > 1 && (
        <Typography sx={chipConciliationActionStyles.subtitle}>
          {conciliation_action[1]}
        </Typography>
      )}
    </Grid>
  );
};

export default ChipConciliationAction;
