import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const accountPreferencesFormStyles = createNamedStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    padding: 0.5,
  },
  divider: {
    borderWidth: "1px",
    borderStyle: "solid",
    mb: "40px",
    mt: "40px",
  },
  gridImage: {
    paddingTop: 4.1,
  },
  gridColor: {
    display: "flex",
  },
  gridRadios: {
    paddingBottom: 3,
    paddintTop: 2,
  },
  labelSecondary: {
    fontWeight: 300,
    paddingBottom: "42px",
    fontSize: "20px !important",
    color: "text.grey",
  },
  labelColor: {
    color: "text.primary",
    fontWeight: 400,
    paddingTop: "8px",
    paddingRight: 1,
  },
  paddingInputFraud: {
    paddingRight: {
      xs: 0,
      md: 2,
    },
  },
  boxTextArea: {
    ".MuiOutlinedInput-root": {
      "@media (max-width:959.95px)": {
        width: "100%",
      },
      width: "832px",
    },
  },
  containerFooter: {
    bottom: 0,
    position: "fixed",
    width: 1,
    zIndex: 99,
  },
  formWrapper: {
    borderRadius: 4,
    marginLeft: "auto",
    marginRight: "auto",
    mb: 0,
    minHeight: 349,
    px: 8,
    py: 6,
    width: 960,
  },
  boxContainer: {
    display: "flex",
  },
  chip: {
    marginLeft: "15px",
    marginTop: "3px",
    fontWeight: 500,
  },
});
