import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Box, ButtonBase, InputAdornment, TextField } from "@material-ui/core";
import { Edit } from "react-feather";
import acceptIcon from "../../assets/images/accept_icon.svg";
import cancelIcon from "../../assets/images/cancel_icon.svg";
import { useRowElementEditState } from "./state/useRowElementEditState";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { StatusFlowEnum } from "../../shared/infrastructure/StatusFlowEnum";
import {
  RolesExecutorEnum,
  RolesValidatorEnum,
} from "../../shared/infrastructure/enum/Roles/RolesEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "70%",
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    input: {
      margin: theme.spacing(1),
      "&.MuiFormControl-root": { marginLeft: 0 },
    },
    amountLabel: {
      marginRight: 7,
    },
    editIcon: {
      marginBottom: 3,
      color: "#6D7781",
      cursor: "pointer",
    },
    hideEditIcon: {
      marginBottom: 3,
      color: "#969494",
      cursor: "pointer",
    },
    acceptIcon: {
      marginRight: 4,
    },
  })
);

export interface RowElementEditProps {
  stillProsessing: boolean;
  value: string;
  number?: boolean;
  statusFlow?: string;
  handleEditValueChange: (value: string) => void;
}

const NumberFormatElement = (props: any) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      style={{ padding: "8px 0px 10px 6px" }}
      getInputRef={inputRef}
      onValueChange={(values: NumberFormatValues) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      decimalScale={2}
    />
  );
};

export const RowElementEdit: React.FC<RowElementEditProps> = (
  props: React.PropsWithChildren<RowElementEditProps>
) => {
  const classes = useStyles(props);

  const { selectProps, actions } = useRowElementEditState(props);

  return (
    <React.Fragment>
      {selectProps.editState ? (
        <TextField
          style={{ height: "25px" }}
          className={classes.input}
          disabled={props.stillProsessing}
          id="input-edit"
          label="Monto Total"
          variant="outlined"
          value={selectProps.editValue}
          onChange={actions.handleInputEditChange()}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ButtonBase
                  className={classes.acceptIcon}
                  disabled={props.stillProsessing}
                >
                  <img
                    src={acceptIcon}
                    onClick={actions.handleSaveEdit()}
                    alt="accept"
                    height="25"
                    width="25"
                  />
                </ButtonBase>
                <ButtonBase>
                  <img
                    src={cancelIcon}
                    onClick={actions.handleCancelEdit()}
                    alt="accept"
                    height="25"
                    width="25"
                  />
                </ButtonBase>
              </InputAdornment>
            ),
            inputComponent: props.number ? NumberFormatElement : undefined,
          }}
        />
      ) : (
        <>
          <ComponentSecurityWrapper
            componentId={
              props.statusFlow == StatusFlowEnum.EXECUTOR
                ? RolesExecutorEnum.M_BILLING_EDIT_AMOUNT
                : RolesValidatorEnum.M_BILLING_EDIT_AMOUNT
            }
          >
            <Box
              display="flex"
              alignItems="center"
              onClick={actions.handleSetEditState(!props.stillProsessing)}
            >
              <ButtonBase
                className={classes.amountLabel}
                onMouseOver={actions.handleShowEditIcon(true)}
                onMouseLeave={actions.handleShowEditIcon(false)}
              >
                {props.children}
              </ButtonBase>
              <Edit
                className={
                  selectProps.showEditIcon
                    ? classes.editIcon
                    : classes.hideEditIcon
                }
                size={16}
              />
            </Box>
          </ComponentSecurityWrapper>
        </>
      )}
    </React.Fragment>
  );
};
