import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

interface ICheckBoxProps {
  value: boolean;
  label: string;
  onChange: (e: any) => void;
}

const CheckBoxInput: React.FC<ICheckBoxProps> = ({
  label,
  value,
  onChange,
}: ICheckBoxProps) => {
  return (
    <FormControlLabel
      control={<Checkbox onChange={() => onChange(!value)} checked={value} />}
      label={label}
    />
  );
};

export default CheckBoxInput;
