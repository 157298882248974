import React, { FC } from "react";
import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import { ticketOrderStyles as styles } from "./TicketOrderCard.styles";
import {
  AMOUNT_LABEL,
  BENEFICIARY_LABEL,
  BENEFICIARY_NAME,
  DESCRIPTION_LABEL,
  DETAILS_TITLE_LABEL,
  DISCLAIMER_LABEL,
  EXPIRATION_LABEL,
  KEY_ACCOUNT_LABEL,
  ORIGIN_LABEL,
  REFERENCE_NUMBER_LABEL,
  SERVICE_LABEL,
  TITLE_LABEL,
  VERIFY_BY,
} from "../../shared/constants/labels/checkout_labels";
import { Copy } from "react-feather";
import { LogoKushki } from "@kushki/connect-ui/dist/Components/Atoms/Images/LogoKushki";
import { useTicketOrderState } from "./state/useTicketOrderState";

const TicketOrderCard: FC = () => {
  const { ticketOrder } = useTicketOrderState();

  return (
    <Box sx={styles.container}>
      <Grid container alignItems="center" sx={styles.gridDetails}>
        <Grid item xs={12}>
          <Typography variant="body2" sx={styles.labelTitle}>
            {TITLE_LABEL}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2" sx={styles.labelDescriptionL1}>
            {DESCRIPTION_LABEL}
          </Typography>

          <Typography variant="body2" sx={styles.labelAmount}>
            {AMOUNT_LABEL}
          </Typography>

          <Typography variant="body2" sx={styles.labelAmountValue}>
            {ticketOrder.amount}
          </Typography>

          <Grid item container xs={12}>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" sx={styles.labelService}>
                {SERVICE_LABEL}
              </Typography>

              <Typography variant="body2" sx={styles.labelServiceDescription}>
                {ticketOrder.transactionType}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="body2" sx={styles.labelService}>
                {ORIGIN_LABEL}
              </Typography>

              <Typography variant="body2" sx={styles.labelServiceDescription}>
                {ticketOrder.merchantName}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={styles.divider} />
        </Grid>

        <Grid item xs={12}>
          <Typography sx={styles.labelDetailsTitle}>
            {DETAILS_TITLE_LABEL}
          </Typography>

          <Grid item container xs={12}>
            <Grid item xs={12} md={6} pr={1}>
              <Typography sx={styles.labelSubtitle}>
                {BENEFICIARY_LABEL}
              </Typography>
              <Typography sx={styles.labelDetailsValue}>
                {BENEFICIARY_NAME}
                <IconButton
                  sx={styles.copyIcon}
                  onClick={() => {
                    navigator.clipboard.writeText(ticketOrder.beneficiaryName);
                  }}
                >
                  <Copy size={20} />
                </IconButton>
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography sx={styles.labelSubtitle}>
                {REFERENCE_NUMBER_LABEL}
              </Typography>
              <Typography sx={styles.labelDetailsValue}>
                {ticketOrder.referenceNumber}
                <IconButton
                  sx={styles.copyIcon}
                  onClick={() => {
                    navigator.clipboard.writeText(ticketOrder.referenceNumber);
                  }}
                >
                  <Copy size={20} />
                </IconButton>
              </Typography>
            </Grid>
          </Grid>

          <Grid item container xs={12}>
            <Grid item xs={12} md={6} pr={1}>
              <Typography sx={styles.labelSubtitle}>
                {KEY_ACCOUNT_LABEL}
              </Typography>
              <Typography sx={styles.labelDetailsValue}>
                {ticketOrder.keyAccount}
                <IconButton
                  sx={styles.copyIcon}
                  onClick={() => {
                    navigator.clipboard.writeText(ticketOrder.keyAccount);
                  }}
                >
                  <Copy size={20} />
                </IconButton>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={styles.disclaimerContainer}>
        <Grid item xs={12}>
          <Typography variant="body2" sx={styles.labelDisclaimer}>
            {DISCLAIMER_LABEL}
          </Typography>
          <Typography variant="body2" sx={styles.labelExpiration}>
            {EXPIRATION_LABEL}
          </Typography>
          <Box sx={styles.boxDisclaimer}>
            <Typography variant="body2" sx={styles.labelDisclaimerVerify}>
              {VERIFY_BY}
            </Typography>
            <LogoKushki />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TicketOrderCard;
