/* eslint-disable */
export const environment: {
    envName: string;
    rollbarToken: string;
    devTools: boolean;
    kushkiUrl: string;
} = {
    envName: "primary",
    rollbarToken: "",
    kushkiUrl: "https://api.kushkipagos.com",
    devTools: false
};
