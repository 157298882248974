import { auth } from "../../auth";
import { IColumns } from "../interfaces/IColumns";
import { IOptionFilter } from "../../../containers/PayoutsTransaction/state/usePayoutsTransactionState";

export const payoutsTransactionColumns: IColumns[] = [
  {
    key: "merchant_name",
    value: "Comercio",
    default: true,
    hidden: !auth.isAdmin(),
  },
  {
    key: "payment_method",
    value: "Medio de pago",
    default: true,
  },
  {
    key: "ticket_number",
    value: "Número de ticket",
    default: true,
  },
  {
    key: "approved_transaction_amount",
    value: "Monto",
    default: true,
  },
  {
    key: "contact_details.email",
    value: "Correo de recibo",
    default: false,
  },
];

export const defaultFilters: IOptionFilter[] = [
  {
    id: "paymentMethod",
    title: "Medios de Pago",
    options: [
      { key: "transfer", label: "Transferencia", selected: false },
      { key: "cash", label: "Efectivo", selected: false },
    ],
  },
  {
    id: "transactionStatus",
    title: "Estados",
    options: [
      { key: "approval", label: "Aprobada", selected: false },
      { key: "initialized", label: "Inicializada", selected: false },
      { key: "declined", label: "Declinada", selected: false },
    ],
  },
];

export const staticColumns: IColumns[] = [
  {
    key: "created",
    value: "Fecha",
  },
  {
    key: "transaction_status",
    value: "Estado",
  },
];

export const getInitialSelectedColumns = () => {
  const columns = payoutsTransactionColumns.filter((c, index) => {
    if (auth.isAdmin()) {
      return c.default;
    }
    return !c.hidden && index !== payoutsTransactionColumns.length - 1;
  });

  if (auth.isAdmin()) return columns;
  columns.splice(0, 0, payoutsTransactionColumns[4]);
  return columns;
};
