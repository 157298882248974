import { ItemCategoryType } from "@kushki/connect-ui/dist/Components/Molecules/Filters/ItemCategory/enums";
import { FilterPathsEnum } from "./PathsEnum";

export enum FilterEnum {
  BUSINESS_CONDITIONS = "Categoría",
  STATUS = "Estado",
}

export enum CategoryTypeEnum {
  BY_CHIPS = "byChips",
}

export enum SelectorTypeEnum {
  BUSINESS_CONDITIONS = "payoutBusinessConditions",
  RULE_STATUS = "ruleStatus",
}

export const FiltersGroup: string[] = [
  FilterEnum.BUSINESS_CONDITIONS,
  FilterEnum.STATUS,
];

export interface IFiltersInterface {
  filterPath: string;
  selectorType: string;
  categoryItem: ItemCategoryType;
}

export const FiltersRequestName: Record<string, IFiltersInterface> = {
  [FilterEnum.BUSINESS_CONDITIONS]: {
    categoryItem: CategoryTypeEnum.BY_CHIPS,
    filterPath: FilterPathsEnum.BUSINESS_CONDITIONS,
    selectorType: SelectorTypeEnum.BUSINESS_CONDITIONS,
  },
  [FilterEnum.STATUS]: {
    categoryItem: CategoryTypeEnum.BY_CHIPS,
    filterPath: FilterPathsEnum.STATUS,
    selectorType: SelectorTypeEnum.RULE_STATUS,
  },
};
