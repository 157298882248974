import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { get, has, isEmpty } from "lodash";
import { AxiosError, AxiosResponse } from "axios";
import { ErrorResponse } from "../../../../types/error_analytics";
import { ErrorEnum } from "../../../shared/infraestructure/error_enum";
import { TransactionData } from "../../../../types/transactions_data";
import { GetNodeInfoResponse } from "../../../../types/get_node_info_response";
import { SearchTransactionRequest } from "../../../../types/search_transaction_request";
import { MerchantBillingInfo } from "../../../../types/merchant_billing_info";
import { EntityNameEnum } from "../../../shared/enums/EntityNameEnum";
import { TransactionInfo } from "../../../../types/transaction";
import { TransactionLabelsEnum } from "../../../shared/enums/transactionLabelsEnum";
import { IMerchantsResponse } from "../../interfaces/AppState.interfaces";
import { RetrieveChildrenRequest } from "../../../../types/retrive_children_request";
import { RetrieveChildrenResponse } from "../../../../types/retrive_children_response";

const transformToNewAuthorizerCrud = (body: SearchTransactionRequest) => {
  let headers = {
    Authorization: localStorage.getItem("jwt"),
    "Content-type": "application/json",
  };

  if (body.customerFilter) {
    headers["CustomerPath"] = body.customerFilter.path;
    headers["BranchesId"] = body.customerFilter.childrenIds;
  }
  delete body.customerFilter;

  return {
    body: {
      ...body,
      limit: 10,
      text: body.text,
      userType: undefined,
    },
    headers,
  };
};

const newAuthorizerModalReceiptHeaders = (
  body: GetNodeInfoResponse,
  merchantIdBranch: string
) => {
  let headers = {
    Authorization: localStorage.getItem("jwt"),
    "Content-type": "application/json",
  };

  if (body.entityName === EntityNameEnum.CUSTOMER) {
    headers["Customer-Path"] = body.path;
    headers["Branches-Id"] = merchantIdBranch;
  }

  return headers;
};

export const searchMerchant = createAsyncThunk<
  { data: TransactionData; isSecondSearch?: boolean },
  {
    body: SearchTransactionRequest;
    isSecondSearch?: boolean;
  }
>("app/searchMerchant", async ({ body, isSecondSearch }) => {
  let route_error: string = "";

  let paramSearch: string = TransactionLabelsEnum.ADMIN;

  if (body.userType !== TransactionLabelsEnum.ADMIN) {
    paramSearch = TransactionLabelsEnum.MERCHANT;
    delete body.publicMerchantId;
  }

  const new_request = transformToNewAuthorizerCrud(body);

  let response: AxiosResponse<TransactionData> = await axios
    .post<TransactionData>(
      API_ROUTES.SEARCH_MERCHANT_ANALYTYCS.replace("$param", paramSearch),
      { ...new_request.body },
      { headers: new_request.headers }
    )
    .catch((e: AxiosError<ErrorResponse>) => {
      if (e.response?.data.code === ErrorEnum.E015)
        route_error = e.response.config.url!;

      return {} as AxiosResponse<TransactionData>;
    });

  if (!isEmpty(route_error))
    response = await axios.post<TransactionData>(route_error, {
      ...body,
      limit: 10,
      text: body.text,
    });

  return { data: response.data, isSecondSearch };
});

export const getPaymentReceipt = createAsyncThunk<
  { buffer: string },
  { transactionInfo: TransactionInfo; body: GetNodeInfoResponse }
>("app/getPaymentReceipt", async ({ body, transactionInfo }) => {
  const is_mongo: boolean = has(transactionInfo, "create_timestamp");
  const merchant_id_field: string = is_mongo ? "merchant_code" : "merchant_id";
  const transaction_reference_field: string = is_mongo
    ? "reference_transaction_code"
    : "transaction_reference";
  const merchantIdBranch: string = get(transactionInfo, merchant_id_field, "");
  let transactionReference: string = get(
    transactionInfo,
    transaction_reference_field,
    ""
  );

  if (isEmpty(transactionReference))
    transactionReference = get(transactionInfo, "_id", "");

  const create_timestamp: string = get(transactionInfo, "create_timestamp", "");
  const api_url: string = is_mongo
    ? `${API_ROUTES.WEBHOOK_RECEIPT}/${transactionReference}/${create_timestamp}`
    : `${API_ROUTES.WEBHOOK_RECEIPT}/${transactionReference}`;

  const newHeaders = newAuthorizerModalReceiptHeaders(body, merchantIdBranch);

  const response = await axios.get<{ buffer: string }>(api_url, {
    headers: newHeaders,
  });

  return response.data;
});

export const getNodeInfo = createAsyncThunk<
  GetNodeInfoResponse,
  { publicMerchantId: string }
>("app/getNodeInfo", async ({ publicMerchantId }) => {
  const response = await axios.post<GetNodeInfoResponse>(API_ROUTES.NODE_INFO, {
    configIds: "cn001",
    publicMerchantId,
  });

  return response.data;
});

export const notifySelectCheckChipsChange = createAsyncThunk<
  boolean,
  { hasChange: boolean }
>("app/notifySelectCheckChipsChange", async ({ hasChange }) => {
  return hasChange;
});

export const getMerchantInfo = createAsyncThunk<
  MerchantBillingInfo,
  { publicMerchantId: string }
>("app/getMerchantInfo", async ({ publicMerchantId }) => {
  const response = await axios.post<MerchantBillingInfo>(
    API_ROUTES.MERCHANT_INFO,
    { publicMerchantId }
  );

  return response.data;
});

export const getMerchantsRequest = createAsyncThunk<
  IMerchantsResponse,
  {
    name?: string;
    entityName?: string;
  }
>("app/getMerchantsRequest", async ({ name, entityName }) => {
  let entity_aux: string = entityName!;

  const merchantId =
    name && name.length >= 10 && !isNaN(Number(name)) ? [name] : [];

  name = merchantId.length == 0 ? name : "";

  if (entityName === EntityNameEnum.MERCHANT) entity_aux = EntityNameEnum.NA;

  const response = await axios.post<IMerchantsResponse>(
    API_ROUTES.MERCHANTS_ANALYTICS,
    {
      filter: {
        entityName: isEmpty(entity_aux)
          ? [EntityNameEnum.NA, EntityNameEnum.CUSTOMER, EntityNameEnum.BRANCH]
          : entity_aux.split("|"),
        merchantId,
        name,
      },
      limit: 15,
      offset: 1,
    }
  );

  return response.data;
});

export const getRetrieveChildren = createAsyncThunk<
  { data: RetrieveChildrenResponse[]; isTransactionSearch: boolean },
  {
    body: { publicMerchantId: string; entityName?: string }[];
    isTransactionSearch: boolean;
  }
>("app/getRetrieveChildren", async ({ body, isTransactionSearch }) => {
  const response = await axios.post<{ items: RetrieveChildrenResponse[] }>(
    API_ROUTES.RETRIEVE_CHILDREN,
    { Items: [...body] }
  );

  return { data: response.data.items, isTransactionSearch };
});

export const getRetrieveChildrenMongo = createAsyncThunk<
  { data: RetrieveChildrenResponse[]; isTransactionSearch: boolean },
  {
    body: RetrieveChildrenRequest[];
    isTransactionSearch: boolean;
  }
>("app/getRetrieveChildrenMongo", async ({ body, isTransactionSearch }) => {
  const response: RetrieveChildrenResponse[] = body.map((item) => {
    return {
      entityName: item.entityName,
      publicMerchantId: [item.publicMerchantId],
    };
  });

  return { data: [...response], isTransactionSearch };
});

export const searchOriginalTrx = createAsyncThunk<
  TransactionData,
  {
    body: SearchTransactionRequest;
  }
>("app/searchOriginalTrx", async ({ body }) => {
  let paramSearch: string = TransactionLabelsEnum.ADMIN;

  const new_request = transformToNewAuthorizerCrud(body);

  let response: AxiosResponse<TransactionData> =
    await axios.post<TransactionData>(
      API_ROUTES.SEARCH_MERCHANT_ANALYTYCS.replace("$param", paramSearch),
      { ...new_request.body },
      { headers: new_request.headers }
    );

  return response.data;
});
