import React from "react";
import { ConciliationStatusEnum } from "../ConciliationStatusEnum";
import { IHeader } from "@kushki/frontend-molecules/modal-conciliation/header/Header";
import { TransactionSummaryData } from "../../../../types/transaction_summary_data";
import { defaultTo, get } from "lodash";
import { formatDate } from "../utils/date";
import { TransactionData } from "../../../../types/transaction_table";
import { FormatDate } from "./FormatDate";

const MODAL_TRANSITION = "left";

const getModalHeaderValues = (
  title: string,
  dateLiquidation: string,
  status: string
): IHeader => ({
  titleMain: {
    text: title,
  },
  titleSecondary: {
    text: "Detalle de la transacción",
  },
  subtitle: {
    text: `Fecha de liquidación ${dateLiquidation}`,
  },
  chip: {
    kind: status as ConciliationStatusEnum,
  },
});

const getModalDetailValues = ({
  trx,
  informationComponent,
  timeLineComponent,
}: {
  trx?: TransactionSummaryData | TransactionData;
  informationComponent?: JSX.Element;
  timeLineComponent?: JSX.Element;
}) => {
  return {
    header: getModalHeaderValues(
      defaultTo(get(trx, "user_name"), "NA"),
      formatDate(get(trx, "bank_conciliation_date", "1"), FormatDate.DMY_dash),
      defaultTo(
        get(trx, "bank_conciliation_status"),
        ConciliationStatusEnum.PENDING
      )
    ),
    body: {
      showTimeLineTab: !!timeLineComponent,
      informationContent: informationComponent || <></>,
      timeLineContent: timeLineComponent,
    },
    ...(!timeLineComponent && {
      footer: {
        handleOnLeftClick: () => {},
        handleOnRightClick: () => {},
      },
    }),
  };
};

export { MODAL_TRANSITION, getModalDetailValues, getModalHeaderValues };
