import React from "react";
import { format, utcToZonedTime } from "date-fns-tz";
import { useHistory } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Chip,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { WebhookNotification } from "../../../types/webhook_notification";
import PaginationTable, {
  PaginationTableProps,
} from "./NotificationsPaginationTable/NotificationsPaginationTable";
import { LabelEnum } from "../../containers/NotificationsIndex/state/useNotificationsIndexState";
import ResendNotificationButton from "../ResendNotificationButton/ResendNotificationButton";
import { useNotificationsTableStyles as useStyles } from "./NotificationsTable.styles";

export interface NotificationsTableProps {
  timeZoneRegion: string;
  isLoading: Boolean;
  pagination: PaginationTableProps;
  webhooks: WebhookNotification[];
  reprocessWebhookNotification: (payload: WebhookNotification) => void;
}

export const NotificationsTable: React.FC<NotificationsTableProps> = (
  props: NotificationsTableProps
) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <React.Fragment>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow key="header">
              <TableCell size={"small"}>
                <Typography className={classes.tableHeader}>Fecha</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.tableHeader}>Alias</Typography>
              </TableCell>
              <TableCell align={"center"} size={"small"} padding={"none"}>
                <Typography className={classes.tableHeader}>
                  Método de pago
                </Typography>
              </TableCell>
              <TableCell size={"small"} padding={"none"}>
                <Typography className={classes.tableHeader}>
                  Descripción
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.tableHeader}>Estado</Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {props.isLoading ? (
              <React.Fragment key={"isLoadingData"}>
                {[...Array(props.pagination.limit)].map((index: number) => (
                  <TableRow key={index}>
                    <TableCell align="left">
                      <Skeleton variant="text" animation="wave" />
                    </TableCell>
                    <TableCell align="left">
                      <Skeleton variant="text" animation="wave" />
                    </TableCell>
                    <TableCell align="left">
                      <Skeleton variant="text" animation="wave" />
                    </TableCell>
                    <TableCell align="left">
                      <Skeleton variant="text" animation="wave" />
                    </TableCell>
                    <TableCell align="left">
                      <Skeleton variant="text" animation="wave" />
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ) : (
              <React.Fragment key={"isNotLoading"}>
                {props.webhooks.map(
                  (webhook: WebhookNotification, index: number) => {
                    const date = format(
                      utcToZonedTime(
                        webhook.created,
                        "UTC-05 (Bogota, Quito, Lima)"
                      ),
                      "dd/MM/yyyy"
                    );
                    const clockTime = format(
                      utcToZonedTime(
                        webhook.created,
                        "UTC-05 (Bogota, Quito, Lima)"
                      ),
                      "HH:mm:ss"
                    );
                    const isStatusCorrect = webhook.status[0] === "2";
                    return (
                      <TableRow
                        key={index}
                        onClick={() => {
                          history.push(
                            "/webhook/notifications/detail",
                            webhook
                          );
                        }}
                      >
                        <TableCell size={"small"}>
                          <Typography className={classes.dateLabel}>
                            {date}
                          </Typography>
                          <Typography className={classes.timeLabel}>
                            {clockTime}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            className={
                              webhook.alias
                                ? classes.aliasCell
                                : classes.aliasCellCenter
                            }
                          >
                            {webhook.alias || "-"}
                          </Typography>
                        </TableCell>
                        <TableCell
                          padding="none"
                          size={"small"}
                          align={"center"}
                          style={{
                            maxWidth: "250px",
                          }}
                        >
                          {webhook.paymentMethod.map(
                            (method: string, subIndex: number) => (
                              <Chip
                                key={subIndex}
                                label={LabelEnum[method]}
                                className={classes.paymentMethodLabel}
                                style={{
                                  backgroundColor: "#F7FAFC",
                                  margin: 3,
                                  borderRadius: "4px",
                                }}
                              />
                            )
                          )}
                        </TableCell>
                        <TableCell padding="none">
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            <Typography
                              className={classes.aliasCell}
                              style={{
                                marginRight: 5,
                              }}
                            >
                              POST
                            </Typography>
                            <Typography className={classes.urlLabel}>
                              {webhook.url}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={`${webhook.status} ${
                              isStatusCorrect ? "OK" : "ERR"
                            }`}
                            className={classes.paymentMethodLabel}
                            style={{
                              backgroundColor: isStatusCorrect
                                ? "#F7FAFC"
                                : "rgba(255, 223, 229, 0.3)",
                              color: isStatusCorrect ? "#293036" : "#AD0C2A",
                              borderRadius: "4px",
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <ResendNotificationButton
                            webhookNotification={webhook}
                            handleReprocessWebhookNotification={
                              props.reprocessWebhookNotification
                            }
                          />
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </React.Fragment>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationTable {...props.pagination} />
    </React.Fragment>
  );
};
