import React, { useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps, Color } from "@material-ui/lab/Alert";

export interface SnackProps {
  msg: string;
  open: boolean;
  type: Color;
  handlerClose: (_event?: React.SyntheticEvent, reason?: string) => void;
}

export function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const NotificationSnackbar = (props: SnackProps) => {
  const [open, setOpen] = React.useState(props.open);
  useEffect(() => setOpen(props.open), [props.open]);

  return (
    <React.Fragment>
      <Snackbar
        id="snackbar"
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={2000}
        onClose={props.handlerClose}
      >
        <Alert onClose={props.handlerClose} severity={props.type}>
          {props.msg}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};
export default NotificationSnackbar;
