import { SnackBarProvider, theme } from "@kushki/connect-ui";
import React from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import BranchCreationContainer from "./containers/BranchCreationContainer/BranchCreationContainer";
import BranchManualCreationContainer from "./containers/BranchManualCreationContainer/BranchManualCreationContainer";
import ListBranchCreationContainer from "./containers/ListBranchCreationContainer/ListBranchCreationContainer";
import MainContainer from "./containers/MainContainer/MainContainer";
import { ROUTES, SPA_BASENAME } from "./shared/constants/routes";
import OwnerContainer from "./containers/OwnerContainer/OwnerContainer";
import { store } from "./store/store";
import CustomerContainer from "./containers/CustomerContainer/CustomerContainer";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
import { environment } from "./environments/environment";
import { SecurityWrapperEnum } from "./shared/infrastructure/SecurityWrapperEnum";
import ResumeContainer from "./containers/ResumeContainer/ResumeContainer";
import BranchEditContainer from "./containers/BranchEditContainer/BranchEditContainer";
import SectionContainer from "./containers/SectionContainer/SectionContainer";
import { Provider as RollbarProvider } from "@rollbar/react";
import { getRollbarConfig } from "./shared/infrastructure/constants/GetRollbarConfig";

const Root = () => {
  return (
    <React.StrictMode>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&display=swap');"
        }
      </style>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500&display=swap');"
        }
      </style>
      <RollbarProvider config={getRollbarConfig}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <SnackBarProvider>
              <BrowserRouter basename={SPA_BASENAME}>
                <ModuleSecurityWrapper
                  basePath={environment.kushkiUrl}
                  componentId={SecurityWrapperEnum.ROOT_MODULE}
                >
                  <Routes>
                    <Route index element={<MainContainer />} />
                    <Route path={ROUTES.OWNER} element={<OwnerContainer />} />
                    <Route
                      path={ROUTES.CUSTOMER}
                      element={<CustomerContainer />}
                    />
                    <Route path={ROUTES.BRANCH} element={<Outlet />}>
                      <Route
                        path={ROUTES.BRANCH_CREATION_OPTIONS}
                        element={<BranchCreationContainer />}
                      />
                      <Route
                        path={ROUTES.BRANCH_BATCH}
                        element={<BranchManualCreationContainer />}
                      />
                      <Route
                        path={ROUTES.BRANCH_UPLOAD_FILE}
                        element={<ListBranchCreationContainer />}
                      />
                      <Route
                        path={ROUTES.BRANCH_EDIT}
                        element={<BranchEditContainer />}
                      />
                      <Route
                        path={ROUTES.BRANCH_SELECT_EDITION}
                        element={<SectionContainer />}
                      />
                    </Route>
                    <Route path={ROUTES.RESUME} element={<ResumeContainer />} />
                  </Routes>
                </ModuleSecurityWrapper>
              </BrowserRouter>
            </SnackBarProvider>
          </ThemeProvider>
        </Provider>
      </RollbarProvider>
    </React.StrictMode>
  );
};

export default Root;
