import { useEffect, useRef, useState } from "react";
import { BreadcrumProps } from "../../../components/common/Breadcrumb/Breadcrumb";
import { routes } from "../../../shared/infrastructure/routes";
import { DateRange } from "@material-ui/pickers/DateRangePicker/RangeTypes";
import { defaultTo, get, isEmpty, isNil, set } from "lodash";
import { endOfDay, format, startOfDay, subDays } from "date-fns";
import { TRuleAnalystDashaboardProps } from "../RuleAnalystDashboard";
import { DataTableFiltersProps } from "../../../components/Filters/DataTableFilters";
import {
  columnsAnalystTable,
  IColumnsTable,
} from "../../../shared/infrastructure/Table/ColumnsTable";
import { DefaultFilter } from "../../../shared/infrastructure/filter/DefaultFilter";
import { IOptionFilter } from "../../../components/common/BoxFilter/BoxFilter";
import {
  AuthenticationRules,
  ResponseSearchRulesByFilters,
} from "../../../../types/response_search_rules_by_filters";
import { setRuleAnalystData as rule } from "../../../store/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { AuthenticationElastic } from "../../../../types/remote/authentication_elastic";
import { ProcessorInfoResponse } from "../../../../types/processor_info_response";
import { CredentialInfoResponse } from "../../../../types/credential_info_response";
import { StatusEnum } from "../../../shared/infrastructure/enums/StatusEnum";
import { useHistory } from "react-router-dom";
import { COUNTRIES } from "../../../shared/infrastructure/CountryEnum";
import { SIZES_VALUES } from "../../../shared/infrastructure/constants/SizeEnum";
import { AUTHENTICATOR_VALUES } from "../../../shared/infrastructure/constants/AuthenticatorEnum";
import { STATUS_VALUES } from "../../../shared/infrastructure/constants/StatusEnum";
import { idAdminRole } from "../../../shared/infrastructure/enums/RolesEnum";
import { tzUTC } from "../../../shared/infrastructure/constants/RuleRequestAuthenticationConstants";
import { AuthenticationRulesDashboard } from "../../../shared/infrastructure/constants/AuthenticationRulesConstants";

export interface IRuleAnalystDashboardState {
  state: IState;
  handlers: {};
  range: DateRange;
  handleSetDate: (date: DateRange | MouseEvent) => void;
  breadCrumbs: BreadcrumProps;
  filterProps: DataTableFiltersProps;
  pagination: {
    page: number;
    pageSize: number;
    hideNext: boolean;
    hidePrevious: boolean;
    handleChangePage: (newPage: number) => void;
    handleChangePageSize: (newPageSize: number) => void;
  };
  tableColumns: {
    renderColumns: IColumnsTable[];
  };
  ruleAnalystProps: {};
  handleSetFilters: (
    filters: { [key: string]: boolean },
    filtersList: IOptionFilter[]
  ) => void;
  filterChips: { [key: string]: boolean };
  counterFilter: number;
  filters: IOptionFilter[];
  orderDataDashboard: (records: AuthenticationElastic[]) => void;
  modalOver: {
    openModalCard: boolean;
    handlerCloseModal: () => void;
    handlerSelectTrx: (
      row: AuthenticationRules | AuthenticationElastic | undefined
    ) => void;
    trx: AuthenticationRules | AuthenticationElastic;
    processorData: ProcessorInfoResponse[];
    credentialData: CredentialInfoResponse[];
  };
  handleAddRule: (row: AuthenticationRules) => void;
  handleInputChange: (search: string) => void;
  handleSearchBar: (key: string) => void;
}

export interface IState {}

export const useRuleAnalystDashboardState = (
  props: TRuleAnalystDashaboardProps
): IRuleAnalystDashboardState => {
  const history = useHistory();
  const [state] = useState<IState>({});
  const [trx, setTrx] = useState<AuthenticationRules | AuthenticationElastic>(
    {}
  );
  const [searchData, setSearchData] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);
  const [hideNext, setHideNext] = useState<boolean>(true);
  const [hidePrevious, setHidePrevious] = useState<boolean>(true);
  const [tableColumns, setTableColumns] =
    useState<IColumnsTable[]>(columnsAnalystTable);
  const [filtersDashboard, setFiltersDashboard] = useState(DefaultFilter);
  const [filters, setFilters] = useState<object>({});
  const [fromDate, setStartDate] = useState<string>(
    format(startOfDay(subDays(new Date(), 1)), "yyyy-MM-dd'T'HH:mm:ss")
  );
  const [toDate, setEndDate] = useState<string>(
    format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss")
  );
  const [range, setDateRange] = useState<DateRange>([
    startOfDay(subDays(new Date(), 1)),
    endOfDay(new Date()),
  ]);
  const [openModalCard, setOpenModalCard] = useState<boolean>(false);

  const isFirstRunDate = useRef(true);
  const isFirstChangePage = useRef(true);
  const dispatch = useDispatch();
  const stateStore = useSelector<IAppState, IAppState>(
    (state: IAppState) => state
  );
  const [filterChips] = useState<{ [key: string]: boolean }>({
    retention_dispersion: false,
    invoice: false,
    voucher: false,
    charge: false,
    dispersion: false,
    credit_note: false,
    debit_note: false,
    emitted: false,
    finished: false,
    pending: false,
    completed: false,
    manual: false,
    USD: false,
    PEN: false,
    CLP: false,
    UF: false,
  });
  const [counterFilter] = useState<number>(0);
  const initialStatusRequest: string = `${StatusEnum.PENDING}|${StatusEnum.IN_PROCESS}|${StatusEnum.REJECT}|${StatusEnum.SENT}`;
  const isAdmin = idAdminRole();
  const getUserName: string | null = localStorage.getItem("username");

  const setRuleAnalystData = (
    fromDate: string,
    toDate: string,
    filters: object,
    pageInfo?: number
  ) => {
    const page_aux: number = defaultTo(pageInfo, page);

    if (!get(filters, "status")) {
      set(filters, "status", initialStatusRequest);
    }
    if (!isAdmin) set(filters, "user_name", getUserName);
    if (isEmpty(searchData))
      props.getRuleAnalystDataProps({
        from: fromDate,
        to: toDate,
        limit: pageSize,
        offset: pageSize * (page_aux - 1),
        filter: filters,
        timeZone: tzUTC,
      });
    else
      props.getRuleAnalystDataProps({
        from: fromDate,
        to: toDate,
        limit: pageSize,
        offset: pageSize * (page_aux - 1),
        filter: filters,
        timeZone: tzUTC,
        generalField: searchData,
      });
  };
  const handleDate = (date: DateRange | MouseEvent): void => {
    if (get(date, "movementX") === 0) {
      return;
    }
    let firstDate = new Date(date[0]);
    let secondDate = new Date(date[1]);
    const startDate = format(
      startOfDay(new Date(firstDate?.toISOString()!)),
      "yyyy-MM-dd'T'HH:mm:ss"
    );
    const endDate = format(
      endOfDay(new Date(secondDate?.toISOString()!)),
      "yyyy-MM-dd'T'HH:mm:ss"
    );

    setStartDate(startDate);
    setEndDate(endDate);
    setRuleAnalystData(startDate, endDate, {});
  };

  const handleColumns = (): void => {
    setTableColumns(columnsAnalystTable);
  };

  const handleChangePage = (newPage: number): void => {
    setPage(newPage);
    setRuleAnalystData(fromDate, toDate, filters, newPage);
    handleColumns();
  };
  const handleChangePageSize = (newPageSize: number): void => {
    setPage(1);
    setPageSize(newPageSize);
  };

  const handleInputChange = (search: string): void => {
    setSearchData(search);
  };

  const handleSearchBar = (key: string): void => {
    if (key === "Enter") setRuleAnalystData(fromDate, toDate, filters);
  };

  const handleSetFilters = (
    _filters: { [key: string]: boolean },
    filtersList: IOptionFilter[]
  ): void => {
    let aux_filters = {};

    COUNTRIES.map((country) => {
      if (_filters[country]) {
        if (isNil(get(aux_filters, "country")))
          set(aux_filters, "country", country);
        else
          set(
            aux_filters,
            "country",
            `${get(aux_filters, "country")}|${country}`
          );
      }
    });
    SIZES_VALUES.map((size) => {
      if (_filters[size]) {
        if (isNil(get(aux_filters, "merchant_size")))
          set(aux_filters, "merchant_size", size);
        else
          set(
            aux_filters,
            "merchant_size",
            `${get(aux_filters, "merchant_size")}|${size}`
          );
      }
    });
    AUTHENTICATOR_VALUES.map((value) => {
      if (_filters[value]) {
        if (isNil(get(aux_filters, "kind"))) set(aux_filters, "kind", value);
        else set(aux_filters, "kind", `${get(aux_filters, "kind")}|${value}`);
      }
    });
    STATUS_VALUES.map((value) => {
      if (_filters[value]) {
        if (isNil(get(aux_filters, "status")))
          set(aux_filters, "status", value);
        else
          set(aux_filters, "status", `${get(aux_filters, "status")}|${value}`);
      }
    });
    setFiltersDashboard(filtersList);
    setFilters(aux_filters);
    setRuleAnalystData(fromDate, toDate, aux_filters);
  };

  const updateAnalystRulesApprovalData = (
    data: ResponseSearchRulesByFilters
  ) => {
    dispatch(rule(data));
  };

  const orderDataDashboard = (records: AuthenticationElastic[]) => {
    updateAnalystRulesApprovalData({
      records: [...records],
      total: stateStore.ruleAnalystData.total,
    });
  };

  const handlerCloseModal = () => setOpenModalCard(false);
  const handleAddRule = (row: AuthenticationRules) => {
    history.push(`${routes.BASE_PATH_RULE_REQUEST}/${row.id}`);
  };

  const handlerSelectTrx = (
    row: AuthenticationRules | AuthenticationElastic | undefined
  ) => {
    if (row !== undefined) {
      if (row.status !== StatusEnum.PENDING) {
        props.getProcessorData(row.public_merchant_id!);
        props.getCredentialData(row.public_merchant_id!);
      }
      setOpenModalCard(true);
      setTrx(row);
    }
  };

  useEffect(() => {
    setFilters({});
    setDateRange([startOfDay(subDays(new Date(), 1)), endOfDay(new Date())]);
  }, []);

  useEffect(() => {
    if (isFirstRunDate.current) {
      isFirstRunDate.current = false;
      return;
    }

    setRuleAnalystData(fromDate, toDate, filters);
  }, [range]);

  useEffect(() => {
    const nextHidden: boolean =
      get(props, "ruleAnalystProps.total", 0) <= page + pageSize;
    const previousHidden: boolean = page < pageSize;
    setHideNext(nextHidden);
    setHidePrevious(previousHidden);
  }, [props.ruleAnalystProps]);

  useEffect(() => {
    if (isFirstChangePage.current) {
      isFirstChangePage.current = false;
      return;
    }
    setRuleAnalystData(fromDate, toDate, filters);
  }, [pageSize]);

  return {
    state,
    range,
    handleInputChange,
    handleSearchBar,
    handleSetDate: handleDate,
    handlers: {},
    filters: DefaultFilter,
    handleSetFilters,
    filterChips,
    counterFilter,
    breadCrumbs: {
      items: [
        {
          label: "Inicio",
          url: routes.DASHBOARD,
        },
        {
          label: "Prevención de Fraude",
          url: routes.MONITORING,
        },
      ],
      lastItem: AuthenticationRulesDashboard.TITLE,
    },
    pagination: {
      page,
      pageSize,
      hideNext,
      hidePrevious,
      handleChangePage,
      handleChangePageSize,
    },
    ruleAnalystProps: {
      range,
    },
    tableColumns: { renderColumns: tableColumns },
    filterProps: {
      handleInputChange: () => {},
      filters: filtersDashboard,
      handleSearchBar: () => {},
      filterChips: {},
      handleSetDate: () => {},
      counter: 0,
      optionTabValue: 0,
      downloadFileState: {
        isLoading: false,
        downloadFile: () => {},
      },
      selectedColumns: [],
      setSelectedColumns: () => "erty",
      handleAppliedColumnsFilters: () => "erty",
    },
    orderDataDashboard,
    modalOver: {
      openModalCard: openModalCard,
      handlerCloseModal: handlerCloseModal,
      handlerSelectTrx: handlerSelectTrx,
      trx,
      processorData: props.processorData,
      credentialData: props.credentialData,
    },
    handleAddRule,
  };
};
