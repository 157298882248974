import React from "react";
import { Skeleton } from "@mui/material";
import { tableCellSkeletonStyles as styles } from "./TableCellSkeleton.styles";
const TableCellSkeleton: React.FC = () => {
  return (
    <Skeleton
      sx={styles.skeleton}
      variant="rectangular"
      data-testid="tableCell-skeleton"
    />
  );
};

export default TableCellSkeleton;
