import React, { FC } from "react";
import { Container } from "@material-ui/core";
import ConfigHeader from "../../components/ConfigHeader/ConfigHeader";
import preferences from "../../assets/images/preferences.svg";
import notificationIcon from "../../assets/images/notifications.svg";
import { ConfigPanel } from "../../components/ConfigPanel/ConfigPanel";
import { ConfigSubsection } from "../../components/ConfigSubsection/ConfigSubsection";
import { routes } from "../../shared/infrastructure/constants/routes";
import { CountriesEnum } from "../../shared/infrastructure/constants/countries-enum";
import { auth } from "../../shared/auth";
import { ProcessorEnum } from "../../shared/infrastructure/interfaces/ProcessorEnum";
import { useMerchantConfiguration } from "./state/useMerchantConfiguration";
import inte from "../../assets/images/integration.svg";
import { deferredRoutes } from "../../shared/infrastructure/constants/defered-routes";
import processors from "../../assets/images/processors.svg";

export const MerchantConfiguration: FC = () => {
  const {
    merchant,
    haveWebcheckout,
    authInfo,
    merchant_processors,
    showDeferred,
  } = useMerchantConfiguration();

  return (
    <>
      {!merchant ? null : (
        <Container fixed>
          <ConfigHeader title="Configuración" isActive={merchant.isActive} />
          <ConfigPanel icon={preferences} title="Preferencias de cuenta">
            <ConfigSubsection
              link={`${routes.PERSONALIZATION}/${merchant?.publicMerchantId}`}
              title="Personalización de marca"
              description="Selecciona el logotipo y colores que identifican al comercio."
            />

            <ConfigSubsection
              link={routes.SUPPORT_INFO}
              titleNotActive="Información de Soporte"
              description="Indica los datos de contacto para que soporte."
            />

            <ConfigSubsection
              link={routes.LOCALIZATION}
              titleNotActive="Idioma y región"
              description="Configura el idioma y la zona horaria para el comercio."
            />

            {merchant.country === CountriesEnum.CHILE ? (
              auth.hasProcessor(ProcessorEnum.ET_PLUS, merchant_processors!) ? (
                <ConfigSubsection
                  link={routes.ETPAY_CUSTOMIZATION}
                  title="Sistema de transferencias bancarias"
                  description="Personaliza la plataforma de transferencias para que tus clientes puedan completar pagos con este medio."
                />
              ) : (
                <ConfigSubsection
                  link={routes.ETPAY_CUSTOMIZATION}
                  titleNotActive="Sistema de transferencias bancarias"
                  description="Personaliza la plataforma de transferencias para que tus clientes puedan completar pagos con este medio."
                />
              )
            ) : (
              <ConfigSubsection
                link={routes.BANK_TRANSFER}
                titleNotActive="Sistema de transferencias bancarias"
                description="Aquí encontrarás las transferencias bancarias de tu comercio."
              />
            )}
          </ConfigPanel>

          <ConfigPanel
            hidden={!showDeferred}
            icon={processors}
            title="Procesamiento"
          >
            <ConfigSubsection
              link={deferredRoutes[merchant?.country || ""]}
              title="Diferidos"
              description="Gestiona la configuración de diferidos que aplica al comercio."
            />
          </ConfigPanel>

          <ConfigPanel icon={notificationIcon} title="Notificaciones">
            <ConfigSubsection
              link={`${routes.TRANSACTION_NOTIFICATION}/${merchant?.publicMerchantId}`}
              title="Notificaciones para tus clientes"
              description="Configura las notificaciones por transacción que deseas enviar a tus clientes."
            />
          </ConfigPanel>

          <ConfigPanel
            icon={inte}
            title="Integraciones"
            hidden={authInfo?.isAdmin}
          >
            {haveWebcheckout && (
              <ConfigSubsection
                link={"/webcheckout/"}
                title="Botón de Pagos"
                description="Configura y personaliza la página de redirección para el pago de tus productos o servicios."
              />
            )}
            <ConfigSubsection
              link={`${routes.KFORMS}`}
              title="Kajita"
              description="Está función te permitirá administrar tus formularios y crear la respectiva configuración."
            />

            <ConfigSubsection
              link={`${routes.INDEX}/plugins`}
              title="Plugins"
              description="Configura el tipo de transacción y método de pago de acuerdo a tu plugin."
            />
          </ConfigPanel>
        </Container>
      )}
    </>
  );
};

export default MerchantConfiguration;
