/* istanbul ignore file */
import React from "react";
import { TWebhookForm } from "../../containers/WebhookFormIndex/state/useWebhookForm";
import { useTypedController } from "@hookform/strictly-typed";
import { useWatch } from "react-hook-form";
import {
  Box,
  Card,
  CardContent,
  createStyles,
  Grid,
  IconButton,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { Minus, Plus } from "react-feather";
import ConnectForm from "../commons/ConnectForm/ConnectForm";
import { makeStyles } from "@material-ui/core/styles";
import { Header } from "../../shared/infrastructure/interfaces/IWebhookForm";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
      paddingTop: 20,
      color: "#023365",
    },
    iconStyle: { background: "#F0F4F9" },
  })
);
export const WebhookHeaders: React.FC = () => {
  const classes = useStyles();
  return (
    <ConnectForm<TWebhookForm>>
      {({ control, setValue, getValues }) => {
        const TypedController = useTypedController<TWebhookForm>({
          control: control,
        });
        const headers = useWatch({ name: "headers" }) as Header[];
        const addHeaders = () => {
          const { headerItem } = getValues();
          setValue("headers", [...headers, headerItem]);
          setValue("headerItem", { label: "", value: "" });
        };
        const removeHeaders = (index: number) => {
          headers.splice(index, 1);
          setValue("headers", [...headers]);
        };
        const editHeaders = (
          event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
          index: number,
          property: string
        ) => {
          if (property === "label") headers[index].label = event.target.value;
          else headers[index].value = event.target.value;

          setValue("headers", [...headers]);
        };
        return (
          <Box mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3} lg={3}>
                <Typography variant={"h5"} color="primary" gutterBottom>
                  Configuración avanzada
                </Typography>

                <Typography
                  variant={"body2"}
                  color={"textSecondary"}
                  gutterBottom
                >
                  Configura los encabezados.
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} lg={9}>
                <Card>
                  <CardContent>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={12}>
                        <Typography variant="h6" color="primary">
                          Encabezados
                        </Typography>

                        {headers.length ? (
                          <Grid item xs={12}>
                            <Typography className={classes.heading}>
                              Editar encabezados
                            </Typography>
                            <Grid
                              container
                              justify="space-between"
                              spacing={1}
                              alignItems="center"
                            >
                              {headers.map((header: Header, index: number) => (
                                <React.Fragment key={index}>
                                  <Grid item xs={5}>
                                    <TextField
                                      id={`url-general-${index}`}
                                      label="Label"
                                      variant="outlined"
                                      required
                                      fullWidth
                                      margin="normal"
                                      defaultValue={header.label}
                                      onBlur={(
                                        event: React.FocusEvent<
                                          HTMLInputElement | HTMLTextAreaElement
                                        >
                                      ) => editHeaders(event, index, "label")}
                                    />
                                  </Grid>
                                  <Grid item xs={5}>
                                    <TextField
                                      id={`url-general-${index}`}
                                      label="Value"
                                      variant="outlined"
                                      required
                                      fullWidth
                                      margin="normal"
                                      defaultValue={header.value}
                                      onBlur={(event) =>
                                        editHeaders(event, index, "value")
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={1}>
                                    <IconButton
                                      className={classes.iconStyle}
                                      onClick={() => removeHeaders(index)}
                                    >
                                      <Minus />
                                    </IconButton>
                                  </Grid>
                                </React.Fragment>
                              ))}
                            </Grid>
                          </Grid>
                        ) : null}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.heading}>
                          Agregar encabezados
                        </Typography>
                        <Grid
                          container
                          justify="space-between"
                          spacing={1}
                          alignItems="center"
                        >
                          <Grid item xs={12}>
                            <Typography
                              color="textSecondary"
                              style={{
                                paddingTop: 10,
                              }}
                            >
                              Agrega encabezados personalizados. Configura un
                              label y un string para cada encabezado que añadas.
                              Puedes agregar máximo 4.
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <TypedController
                              name={["headerItem", "label"]}
                              render={(props) => (
                                <TextField
                                  {...props}
                                  id="LabelHeader"
                                  label="Label"
                                  variant="outlined"
                                  fullWidth
                                  margin="normal"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={5}>
                            <TypedController
                              name={["headerItem", "value"]}
                              render={(props) => (
                                <TextField
                                  {...props}
                                  id="valueHeader"
                                  label="Value"
                                  variant="outlined"
                                  fullWidth
                                  margin="normal"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton
                              className={classes.iconStyle}
                              onClick={addHeaders}
                              disabled={headers.length === 4}
                            >
                              {" "}
                              <Plus />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        );
      }}
    </ConnectForm>
  );
};
