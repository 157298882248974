import React from "react";
import { NotificationTypeEnum, SnackbarEnum } from "../enums/SnackbarEnum";
import { IconCircleWarn, ISnackBar } from "@kushki/connect-ui";
import { ISnackBarWithAction } from "@kushki/connect-ui";
import { FinalStatusEnum, StatusEnum } from "../enums/StatusEnum";
import { upperFirstLetter } from "./string_utils";

enum SnackbarColor {
  INFO = "info",
  DANGER = "danger",
  SUCCESS = "success",
}

const successData: Partial<ISnackBarWithAction> = {
  actionMessage: SnackbarEnum.BUTTON_TEXT,
  color: SnackbarColor.SUCCESS,
  message: SnackbarEnum.REQUEST_SUCCESS,
  variant: "withAction",
  withIcon: true,
};

const failedData: Partial<ISnackBarWithAction> = {
  actionMessage: SnackbarEnum.BUTTON_TEXT,
  color: SnackbarColor.DANGER,
  icon: <IconCircleWarn />,
  message: SnackbarEnum.REQUEST_ERROR,
  variant: "withAction",
  withIcon: true,
};

export const defaultDataSnackbar: ISnackBar = {
  color: SnackbarColor.SUCCESS,
  message: SnackbarEnum.REQUEST_SUCCESS,
  variant: "simple",
  withIcon: false,
};

export const buildNotification = (
  notificationType: NotificationTypeEnum,
  data: ISnackBar
): ISnackBar => {
  switch (notificationType) {
    case NotificationTypeEnum.SUCCESS:
      return {
        ...data,
        ...successData,
      };
    case NotificationTypeEnum.FAILED:
      return {
        ...data,
        ...failedData,
      };
  }
};

export const buildGenericNotification = (
  notificationType: NotificationTypeEnum,
  data: Partial<ISnackBarWithAction>
): ISnackBar => {
  switch (notificationType) {
    case NotificationTypeEnum.SUCCESS:
      return {
        ...successData,
        ...data,
      } as ISnackBar;
    case NotificationTypeEnum.FAILED:
      return {
        ...failedData,
        ...data,
      } as ISnackBar;
  }
};

export const buildMessage = (
  entityName: string,
  status: string,
  type: string = "success"
): string => {
  if (type === "error") {
    const final_status: string =
      status === StatusEnum.ACTIVE
        ? FinalStatusEnum.ACTIVAR
        : FinalStatusEnum.DESACTIVAR;

    return SnackbarEnum.CHANGE_STATUS_FAILED.replace(
      "$status",
      final_status
    ).replace("$entity", upperFirstLetter(entityName));
  }

  const final_status: string =
    status === StatusEnum.ACTIVE
      ? FinalStatusEnum.ACTIVATED
      : FinalStatusEnum.DEACTIVATED;

  return SnackbarEnum.CHANGE_STATUS_SUCCESS.replace(
    "$status",
    final_status
  ).replace("$entity", upperFirstLetter(entityName));
};
