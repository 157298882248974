import React from "react";
import { Box, Grid, Link, Typography } from "@material-ui/core";
import { LabelsDispersion } from "../../shared/infrastructure/constants/RequestDispersionConstants";
import { downloadTemplateDispersionStyles } from "./DownloadTemplateDispersion.styles";
import { Download } from "react-feather";
import { useDownloadTemplateDispersionState } from "./state/useDownloadTemplateDispersionState";

export const DownloadTemplateDispersion = () => {
  const classes = downloadTemplateDispersionStyles();
  const { handleInputFile, handleUafFile } =
    useDownloadTemplateDispersionState();

  return (
    <Grid container direction={"column"}>
      <Grid item xs={10}>
        <Typography className={classes.title}>
          {LabelsDispersion.BEGIN_TEMPLATE_DESC_TITLE}{" "}
          <strong>{LabelsDispersion.BASE_TEMPLATE}</strong>{" "}
          {LabelsDispersion.END_TEMPLATE_DESC_TITLE}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.inputLinkGrid}>
        <Typography>
          <Download size={"20px"} />
          <Link
            onClick={handleInputFile}
            target={"_blank"}
            className={classes.link}
          >
            {LabelsDispersion.DOWNLOAD_INPUT_TEMPLATE}
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.uafLinkGrid}>
        <Typography>
          <Download size={"20px"} />
          <Link
            onClick={handleUafFile}
            target={"_blank"}
            className={classes.link}
          >
            {LabelsDispersion.DOWNLOAD_UAF_TEMPLATE}
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.descriptionGrid}>
        <Typography className={classes.descriptionText}>
          {LabelsDispersion.FORMAT_DESCRIPTION}
        </Typography>
        <Box className={classes.box} />
        <Typography className={classes.descriptionText}>
          {LabelsDispersion.SIZE_DESCRIPTION}
        </Typography>
      </Grid>
    </Grid>
  );
};
