import React from "react";
import { IPaginationComponent } from "./PaginationComponent.interfaces";
import { Grid, MenuItem, Select, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { PAGE_ITEMS } from "../../shared/enums/pageItemsEnum";
import { paginationComponentStyles as styles } from "../PaginationComponent/PaginationComponent.styles";

const PaginationComponent = (props: IPaginationComponent): JSX.Element => {
  const classes = styles();

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid container>
          <Grid item className={classes.marginButton}>
            <Typography className={classes.typographyLabelPagination}>
              Solicitudes por página
            </Typography>
            <Select
              id={"page-size"}
              labelId="country-label"
              label={"limits"}
              className={classes.select}
              disableUnderline
              value={props.limit}
              onChange={(e) =>
                props.handleChangePageSize(Number(e.target.value))
              }
            >
              {PAGE_ITEMS.map((item) => {
                return (
                  <MenuItem title={"menu-item-size"} key={item} value={item}>
                    <Typography className={classes.typographyLabelPages}>
                      {item}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid className={classes.itemTotalCount}>
            <Typography className={classes.typographyLabelPagination}>
              {`${(props.page - 1) * props.limit + 1}-${
                props.page === Math.ceil(Number(props.total / props.limit))
                  ? props.total
                  : props.limit * props.page
              } of ${props.total}`}
            </Typography>
          </Grid>
          <Grid
            item
            xl={8}
            lg={8}
            md={5}
            sm={5}
            xs={5}
            container
            justifyContent="flex-end"
            alignItems="center"
            direction="row"
          >
            <Pagination
              count={Math.ceil(Number(props.total / props.limit))}
              onChange={(_e, value: number) => props.handleChangePage(value)}
              page={props.page}
              size={"small"}
              siblingCount={1}
              color={"primary"}
            />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default PaginationComponent;
