import React from "react";
import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  typography: {
    fontWeight: 200,
    marginBottom: 30,
  },
  typographyMobile: {
    fontWeight: 200,
    marginBottom: 10,
  },
}));

export interface IPageHeader {
  title: string;
}

export const PageHeader: React.FC<IPageHeader> = ({ title }: IPageHeader) => {
  const classes = useStyles();
  const isMobile: boolean = useMediaQuery("(max-width:767px)");

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            className={isMobile ? classes.typographyMobile : classes.typography}
            color={"primary"}
            variant={isMobile ? "h3" : "h1"}
          >
            {title}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default PageHeader;
