export enum RatesConditionsLabelEnum {
  ADDITIONAL_SERVICES = "Servicios adicionales",
  CARD_BRAND = "Marca de Tarjeta:",
  CREDIT = "Crédito",
  CONDITIONS = "Condiciones",
  CONSORTIUM = "Consorcio:",
  CURRENT = "Corriente",
  DEBIT = "Débito",
  DEFERRED = "Diferido",
  DISPERSAL_MONEY = "Dispersión de dinero",
  DUES = "Cuotas:",
  FUNDRAISING = "Recaudo de fondos",
  INTERNATIONAL = "Internacional",
  MODEL = "Modelo:",
  NATIONAL = "Nacional",
  PAYMENT_TYPE = "Tipo de pago:",
  PROCESSOR = "Procesador:",
  RATE_TYPE = "Tipo de tarifa:",
  REGION = "Region:",
  TYPE_OR_PAYMENT_METHOD = "Tipo o método de pago:",
}
