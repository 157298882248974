import axios from "axios";
import { Configs, INodeInfoResponse } from "../../../types/node_info_response";
import { API_ROUTES } from "../constants/api_routes";
import { getJwtAuth } from "./getJwtAuth_utils";
import { each, find, get, remove } from "lodash";
import { CountryEnum } from "../enum/CountryEnum";
import { requiredConfigs } from "../constants/requiredConfigs";
import { ConfigTypeEnum } from "../enum/configEnum";
import { StatusEnum } from "../enum/StatusEnum";
import { MerchantNodeData } from "../../../types/search_merchant_response";

export const nodeInfo = (publicMerchantId: string) =>
  axios.post<INodeInfoResponse>(
    API_ROUTES.GET_NODE_INFO,
    { publicMerchantId },
    {
      headers: {
        Authorization: getJwtAuth(),
        "Content-Type": "application/json",
      },
    }
  );

export const validateRequiredConfigs = (
  merchant: INodeInfoResponse
): boolean => {
  if (get(merchant, "generalInfo.country", "") !== CountryEnum.ecuador) {
    remove(
      requiredConfigs,
      (data: ConfigTypeEnum) => data === ConfigTypeEnum.cn017
    );
  }

  return requiredConfigs.every((requiredConfig: string) => {
    return get(merchant, "configs", []).some(
      (config: Configs) =>
        config.configuration === requiredConfig &&
        (config.status === StatusEnum.COMPLETE ||
          config.status === StatusEnum.OMITTED)
    );
  });
};

export const verifyCofigsComplete = async (
  request: MerchantNodeData[]
): Promise<MerchantNodeData[]> => {
  const nodes: MerchantNodeData[] = [];

  const nodeInfoResponse = await Promise.all(
    request.map((branch) => nodeInfo(get(branch, "merchant_id", "")))
  );

  each(nodeInfoResponse, (branch) => {
    const matchedBranches = find(
      request,
      (item) => item.merchant_id === branch.data.merchantId
    );

    nodes.push({
      ...matchedBranches,
      configs_complete: validateRequiredConfigs(branch.data),
    });
  });

  return nodes;
};
