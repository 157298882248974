/**
 * DynamoDB's Index enum
 */
export enum SizeNameEnum {
  Entrepreneur = "Entrepreneur",
  Mediana = "Mediana",
  Grande = "Grande",
}
export enum SizeValueEnum {
  Entrepreneur = "entrepreneur",
  Mediana = "median",
  Grande = "big",
}
export const SIZES_VALUES = [
  SizeValueEnum.Entrepreneur,
  SizeValueEnum.Mediana,
  SizeValueEnum.Grande,
];
