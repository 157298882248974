import React from "react";
import {
  ITabInfo,
  tabEnum,
} from "../../../shared/infrastructure/CatalogRenderTab";
import { ModalBody } from "@kushki/frontend-molecules/modal-body";
import { get, isNil } from "lodash";
import { CountryEnum } from "../../../shared/infrastructure/CountryEnum";
import { KshTab } from "../../Common/KshTab/KshTab";
import { InfoTrxProps } from "../../ModalBodyInfo/ModalBodyTabs";
import { AdvanceConfigTabDetail } from "../../ModalTabs/AdvanceConfigTabDetail/AdvanceConfigTabDetail";
import { EmptyStateTab } from "../../Common/EmptyStateTab/EmptyStateTab";
import { KindEnum } from "../../../shared/infrastructure/KindEnum";
import { ModalBodyTrxBilling } from "../ModalBodyTrxBilling";
import {
  getAdvanceSettings,
  getCatalogInfo,
  getEmptyTitleByKind,
} from "../../ModalBodyInfo/utilsModalBody";

const getTabsRender = (
  props: InfoTrxProps,
  catalog: ITabInfo[]
): { title: string; content: JSX.Element }[] => {
  const tabsRender: { title: string; content: JSX.Element }[] = [];

  catalog.map((tabInfo: ITabInfo) => {
    switch (tabInfo.tab) {
      case tabEnum.information:
        tabsRender.push({
          title: tabEnum.information,
          content: (
            <ModalBodyTrxBilling
              trx={props.trxInfo}
              isMinAmountTrx={props.isMinAmount}
              statusFlow={props.statusFlow}
            />
          ),
        });
        break;
      case tabEnum.advancedConfig:
        const transactionType = get(props.trxInfo, "transaction.kind", "");
        const advance_settings = getAdvanceSettings(get(props, "trxInfo", {}));

        tabsRender.push({
          title: tabEnum.advancedConfig,
          content:
            !isNil(advance_settings.advanceSettingsReference) ||
            !isNil(advance_settings.advanceSettingsParameters) ? (
              <AdvanceConfigTabDetail
                InfoTrxProps={props}
                country={props.country as CountryEnum}
                isHistoric={props.isHistoric}
              />
            ) : (
              <EmptyStateTab
                title={getEmptyTitleByKind(transactionType, false)}
                subtitle='Para agregarlas, haz click en "Configuración Avanzada"'
              />
            ),
        });
        break;
    }
  });
  return tabsRender;
};

export const ModalBodyTrxTabs: React.FC<InfoTrxProps> = (
  props: InfoTrxProps
) => {
  const catalogTabs: ITabInfo[] = getCatalogInfo(props);

  return (
    <ModalBody>
      <React.Fragment>
        {[KindEnum.INVOICE, KindEnum.VOUCHER].includes(
          get(props.trxInfo, "transaction.kind", "")
        ) ? (
          <KshTab tabs={getTabsRender(props, catalogTabs)} />
        ) : (
          <ModalBodyTrxBilling
            trx={props.trxInfo}
            isMinAmountTrx={props.isMinAmount}
            statusFlow={props.statusFlow}
          />
        )}
      </React.Fragment>
    </ModalBody>
  );
};
