import { createAsyncThunk } from "@reduxjs/toolkit";
import { RetrieveChildrenRequest } from "../../../../types/retrive_children_request";
import { ConfigEnum } from "../../../shared/enums/ConfigEnum";
import {
  IMerchantsResponse,
  ITimeRefund,
  VoidBody,
} from "../../interfaces/AppState.interfaces";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { get, has, isEmpty } from "lodash";
import { ITransactionFile } from "../../../../types/transaction_file";
import { AxiosError, AxiosResponse } from "axios";
import { ErrorResponse } from "../../../../types/error_analytics";
import { ErrorEnum } from "../../../shared/infraestructure/error_enum";
import {
  TransactionData,
  TransactionInfo,
} from "../../../../types/transactions_data";
import { TransactionsDownload } from "../../../../types/transactions_download";
import {
  Configs,
  GetNodeInfoResponse,
} from "../../../../types/get_node_info_response";
import { MerchantBillingInfo } from "../../../../types/merchant_billing_info";
import { SearchTransactionRequest } from "../../../../types/search_transaction_request";
import {
  ErrorCodeEnum,
  mapErrors,
} from "../../../shared/infraestructure/CatalogError";
import { TransactionLabelsEnum } from "../../../shared/enums/transactionLabelsEnum";
import { EntityNameEnum } from "../../../shared/enums/EntityNameEnum";
import { RetrieveChildrenResponse } from "../../../../types/retrive_children_response";
import { MerchantNodeInfoResponse } from "../../../../types/merchant_node_info_response";

const transformToNewAuthorizerCrud = (
  body: SearchTransactionRequest,
  isDownload: boolean,
  format?: string
) => {
  let headers = {
    Authorization: localStorage.getItem("jwt"),
    "Content-type": "application/json",
  };

  if (body.customerFilter) {
    headers["CustomerPath"] = body.customerFilter.path;
    headers["BranchesId"] = body.customerFilter.childrenIds;
  }
  delete body.customerFilter;

  if (isDownload)
    return {
      body: {
        ...body,
        format,
        limit: 10,
        text: body.text,
        userType: undefined,
      },
      headers,
    };

  return {
    body: {
      ...body,
      limit: 10,
      text: body.text,
      userType: undefined,
    },
    headers,
  };
};

const newAuthorizerModalReceiptHeaders = (
  body: GetNodeInfoResponse,
  merchantIdBranch: string
) => {
  let headers = {
    Authorization: localStorage.getItem("jwt"),
    "Content-type": "application/json",
  };

  if (body.entityName === EntityNameEnum.CUSTOMER) {
    headers["CustomerPath"] = body.path;
    headers["BranchesId"] = merchantIdBranch;
  }

  return headers;
};

export const getMerchantsRequest = createAsyncThunk<
  IMerchantsResponse,
  {
    name?: string;
    merchantId?: string;
    entityName?: string;
  }
>("app/getMerchantsRequest", async ({ name, entityName }) => {
  let entity_aux: string = entityName!;

  const merchantId =
    name && name.length >= 10 && !isNaN(Number(name)) ? [name] : [];

  name = merchantId.length == 0 ? name : "";

  if (entityName === EntityNameEnum.MERCHANT) entity_aux = EntityNameEnum.NA;

  const response = await axios.post<IMerchantsResponse>(
    API_ROUTES.MERCHANTS_ANALYTICS,
    {
      filter: {
        entityName: isEmpty(entity_aux)
          ? [EntityNameEnum.NA, EntityNameEnum.CUSTOMER, EntityNameEnum.BRANCH]
          : entity_aux.split("|"),
        merchantId,
        name,
      },
      limit: 15,
      offset: 1,
    }
  );

  return response.data;
});

export const getFirebaseID = createAsyncThunk<
  { id: string },
  {
    body: SearchTransactionRequest;
    format: string;
    customerFilter?: {
      path: string;
      childrenIds: string;
    };
  }
>("app/getFirebaseID", async ({ body, format, customerFilter }) => {
  let route: string = API_ROUTES.DOWNLOAD_ANALYTICS;

  if (body.userType === TransactionLabelsEnum.MERCHANT) {
    route = API_ROUTES.DOWNLOAD_MERCHANT_ANALYTICS;
    delete body.publicMerchantId;
  }

  body.customerFilter = customerFilter;

  if (isEmpty(body.publicMerchantId)) delete body.publicMerchantId;
  delete body.userType;

  const new_request = transformToNewAuthorizerCrud(body, true, format);

  const response = await axios.post<{ id: string }>(
    route,
    { ...new_request.body },
    { headers: new_request.headers }
  );

  return response.data;
});

export const downloadTransactions = createAsyncThunk<
  ITransactionFile,
  { payload: string }
>("app/downloadTransactions", async () => {
  let response: ITransactionFile = { totalProcessed: 0, totalRecords: 0 };

  return response;
});

export const notifySelectCheckChipsChange = createAsyncThunk<
  boolean,
  { hasChange: boolean }
>("app/notifySelectCheckChipsChange", async ({ hasChange }) => {
  return hasChange;
});

export const searchMerchant = createAsyncThunk<
  { data: TransactionData; isSecondSearch?: boolean },
  {
    body: SearchTransactionRequest;
    isSecondSearch?: boolean;
  }
>("app/searchMerchant", async ({ body, isSecondSearch }) => {
  let route_error: string = "";

  let paramSearch: string = TransactionLabelsEnum.ADMIN;

  if (body.userType !== "admin") {
    paramSearch = TransactionLabelsEnum.MERCHANT;
    delete body.publicMerchantId;
  }

  const new_request = transformToNewAuthorizerCrud(body, false);

  let response: AxiosResponse<TransactionData> = await axios
    .post<TransactionData>(
      API_ROUTES.SEARCH_MERCHANT_ANALYTYCS.replace("$param", paramSearch),
      { ...new_request.body },
      { headers: new_request.headers }
    )
    .catch((e: AxiosError<ErrorResponse>) => {
      if (e.response?.data.code === ErrorEnum.E015)
        route_error = e.response.config.url!;

      return {} as AxiosResponse<TransactionData>;
    });

  if (!isEmpty(route_error))
    response = await axios.post<TransactionData>(route_error, {
      ...body,
      limit: 10,
      text: body.text,
    });

  return { data: response.data, isSecondSearch };
});

export const searchOriginalTrx = createAsyncThunk<
  TransactionData,
  {
    body: SearchTransactionRequest;
  }
>("app/searchOriginalTrx", async ({ body }) => {
  let paramSearch: string =
    body.userType !== TransactionLabelsEnum.ADMIN
      ? TransactionLabelsEnum.MERCHANT
      : TransactionLabelsEnum.ADMIN;

  const new_request = transformToNewAuthorizerCrud(body, false);

  let response: AxiosResponse<TransactionData> =
    await axios.post<TransactionData>(
      API_ROUTES.SEARCH_MERCHANT_ANALYTYCS.replace("$param", paramSearch),
      { ...new_request.body },
      { headers: new_request.headers }
    );

  return response.data;
});

export const chargebackTransaction = createAsyncThunk<string[], VoidBody[]>(
  "app/chargebackTransaction",
  async (payload) => {
    let response: string[] = [];

    for (const item of payload) {
      const requestUrl: {
        method: string;
        message: string;
      } = API_ROUTES.CHARGEBACK(item);

      await axios.delete<object[]>(requestUrl.method).catch((error) => {
        response.push(
          `${error.response.data.message} Número de ticket ${item.ticketNumber}`
        );
      });
      response.push(requestUrl.message);
    }

    return response;
  }
);

export const voidTransaction = createAsyncThunk<
  {
    request: VoidBody[];
    transaction: {
      index: number;
      message: string;
      data?: object[];
      error?: object;
    }[];
    successResults: number;
    errorResults: number;
  },
  VoidBody[]
>("app/voidTransaction", async (payload) => {
  let response: {
    request: VoidBody[];
    transaction: {
      index: number;
      message: string;
      data?: object[];
      error?: object;
    }[];
    successResults: number;
    errorResults: number;
  } = {
    errorResults: 0,
    request: payload,
    successResults: 0,
    transaction: [],
  };
  let successResults: number = 0;
  let errorResults: number = 0;
  let index: number = 0;

  for (const data of payload) {
    let body: object = { data: { ...data.body } };

    await axios
      .delete<object[]>(
        `${API_ROUTES.CARD_CHARGE}${data.id}`,
        !isEmpty(get(body, "data")) ? body : {}
      )
      .then((resp) => {
        successResults += 1;

        response.transaction.push({
          data: resp.data,
          index,
          message: "Has solicitado una devolución con éxito.",
        });
      })
      .catch((error) => {
        let code: string = get(error.response!.data, "code");

        errorResults += 1;

        response.transaction.push({
          error,
          index,
          message:
            code === ErrorCodeEnum.AUT006
              ? mapErrors(code)
              : `Error Número de ticket ${data.id}`,
        });
      });
    index++;
  }

  return { ...response, errorResults, successResults };
});

export const validateTimeRefund = createAsyncThunk<
  ITimeRefund,
  { processorName: string; date: string }
>("app/validateTimeRefund", async ({ processorName, date }) => {
  const response = await axios.get<ITimeRefund>(
    API_ROUTES.REFUND_CONCILIATION_CONFIGURATION,
    {
      params: { dateToVerify: date, processorName },
    }
  );

  return response.data;
});

export const validateReceivable = createAsyncThunk<
  { isReceivable: boolean },
  { merchantId: string }
>("app/validateReceivable", async ({ merchantId }) => {
  const response = await axios.get<{ isReceivable: boolean }>(
    `${API_ROUTES.VALIDATE_RECEIVABLE}${merchantId}`
  );

  return response.data;
});

export const getPaymentReceipt = createAsyncThunk<
  { buffer: string },
  { transactionInfo: TransactionInfo; body: GetNodeInfoResponse }
>("app/getPaymentReceipt", async ({ body, transactionInfo }) => {
  const is_mongo: boolean = has(transactionInfo, "create_timestamp");
  const merchant_id_field: string = is_mongo ? "merchant_code" : "merchant_id";
  const transaction_reference_field: string = is_mongo
    ? "reference_transaction_code"
    : "transaction_reference";
  const merchantIdBranch: string = get(transactionInfo, merchant_id_field, "");
  const transactionReference: string = get(
    transactionInfo,
    transaction_reference_field,
    ""
  );
  const create_timestamp: string = get(transactionInfo, "create_timestamp", "");
  const api_url: string = is_mongo
    ? `${API_ROUTES.WEBHOOK_RECEIPT}/${transactionReference}/${create_timestamp}`
    : `${API_ROUTES.WEBHOOK_RECEIPT}/${transactionReference}`;
  const newHeaders = newAuthorizerModalReceiptHeaders(body, merchantIdBranch);
  const response = await axios.get<{ buffer: string }>(api_url, {
    headers: newHeaders,
  });

  return response.data;
});

export const getContactInformation = createAsyncThunk(
  "app/getContactInformation",
  async ({ publicMerchantId }: { publicMerchantId: string }) => {
    const nodeInfoResponse = await axios.post<GetNodeInfoResponse>(
      API_ROUTES.NODE_INFO,
      { configIds: ConfigEnum.CONTACT_DATA, publicMerchantId }
    );
    const nodeInfo = nodeInfoResponse.data;
    const contactConfiguration = get(nodeInfo, "configs", []).find(
      (configs: Configs) => configs.configuration === ConfigEnum.CONTACT_DATA
    );
    const merchant = get(contactConfiguration, "value", publicMerchantId);
    const response = await axios.post<MerchantBillingInfo>(
      API_ROUTES.MERCHANT_INFO,
      { publicMerchantId: merchant }
    );

    return { data: [response.data], total: 1 };
  }
);

export const chargebackTrxWithEmail = createAsyncThunk<
  { message: string; data: object; id: string; ticketNumber: string },
  VoidBody
>("app/chargebackTrxWithEmail", async (payload: VoidBody) => {
  let requestUrl: {
    method: string;
    message: string;
  } = API_ROUTES.CHARGEBACK(payload);

  const response = await axios.delete<object>(requestUrl.method, {
    data: { emails: payload.emailList, paymentMethod: payload.paymentMethod },
  });

  return {
    data: response.data,
    id: payload.id,
    message: "",
    ticketNumber: get(payload, "ticketNumber", ""),
  };
});

export const downloadFileForTrxSelected = createAsyncThunk<
  { id: string },
  TransactionsDownload
>("app/downloadFileForTrxSelected", async (transactionDownload) => {
  const response = await axios.post<{ id: string }>(
    API_ROUTES.DOWNLOAD_FILE_LIST,
    { ...transactionDownload }
  );

  return response.data;
});

export const getNodeInfo = createAsyncThunk<
  GetNodeInfoResponse,
  { configId?: string; publicMerchantId: string }
>("app/getNodeInfo", async ({ publicMerchantId, configId }) => {
  const response = await axios.post<GetNodeInfoResponse>(API_ROUTES.NODE_INFO, {
    configIds: configId,
    publicMerchantId,
  });

  return response.data;
});

export const getMerchantInfo = createAsyncThunk<
  MerchantBillingInfo,
  { publicMerchantId: string }
>("app/getMerchantInfo", async ({ publicMerchantId }) => {
  const response = await axios.post<MerchantBillingInfo>(
    API_ROUTES.MERCHANT_INFO,
    { publicMerchantId }
  );

  return response.data;
});

export const getRetrieveChildren = createAsyncThunk<
  { data: RetrieveChildrenResponse[]; isTransactionSearch: boolean },
  {
    body: { publicMerchantId: string; entityName?: string }[];
    isTransactionSearch: boolean;
  }
>("app/getRetrieveChildren", async ({ body, isTransactionSearch }) => {
  const response: AxiosResponse<{
    items: RetrieveChildrenResponse[];
  }> = await axios.post<{ items: RetrieveChildrenResponse[] }>(
    API_ROUTES.RETRIEVE_CHILDREN,
    { Items: [...body] }
  );

  return { data: response.data.items, isTransactionSearch };
});

export const getRetrieveChildrenMongo = createAsyncThunk<
  { data: RetrieveChildrenResponse[]; isTransactionSearch: boolean },
  {
    body: RetrieveChildrenRequest[];
    isTransactionSearch: boolean;
  }
>("app/getRetrieveChildrenMongo", async ({ body, isTransactionSearch }) => {
  const response: RetrieveChildrenResponse[] = body.map((item) => {
    return {
      entityName: item.entityName,
      publicMerchantId: [item.publicMerchantId],
    };
  });

  return { data: [...response], isTransactionSearch };
});

export const getMerchantNodeInfo = createAsyncThunk<
  MerchantNodeInfoResponse,
  {
    configId: string;
    publicMerchantId: string;
  }
>("app/getMerchantNodeInfo", async ({ configId, publicMerchantId }) => {
  const response = await axios.post<MerchantNodeInfoResponse>(
    API_ROUTES.MERCHANT_NODE_INFO,
    {
      configId,
      publicMerchantId,
    }
  );

  return response.data;
});
