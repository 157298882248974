import { createContext } from "react";
import { IFilterSearchContextProps } from "./FilterSearchContext.interfaces";
import { timeZoneMapper } from "../../utils/time-zone";

const initialFilterSearchContext: IFilterSearchContextProps = {
  filterSearch: {
    page: 1,
    request: {
      from: "",
      limit: 5,
      offset: 0,
      timeZone: timeZoneMapper.timeZoneHour(
        localStorage.getItem("timeZone")!,
        "utc"
      ),
      to: "",
    },
  },
  readyToRender: false,
  updateFilter: () => {},
  updateLimit: () => {},
  updateMerchant: () => {},
  updatePage: () => {},
  updateRange: () => {},
};
const FilterSearchContext = createContext({ ...initialFilterSearchContext });

export { FilterSearchContext, initialFilterSearchContext };
