import { Category } from "../../interfaces/category";

export const EXTRA_CURRENCIES_CATALOG: Category[] = [
  {
    name: "Afgani afgano",
    value: "AFN",
  },
  {
    name: "Lek albanés",
    value: "ALL",
  },
  {
    name: "Dólar estadounidense",
    value: "USD",
  },
  {
    name: "Euro",
    value: "EUR",
  },
  {
    name: "Kwanza angoleño",
    value: "AOA",
  },
  {
    name: "Dólar del Caribe Oriental",
    value: "XCD",
  },
  {
    name: "Riyal saudí",
    value: "SAR",
  },
  {
    name: "Dinar argelino",
    value: "DZD",
  },
  {
    name: "Dram armenio",
    value: "AMD",
  },
  {
    name: "Florín arubeño",
    value: "AWG",
  },
  {
    name: "Dólar australiano",
    value: "AUD",
  },

  {
    name: "Manat azerbaiyano",
    value: "AZN",
  },
  {
    name: "Peso argentino",
    value: "ARS",
  },
  {
    name: "Dólar bahameño",
    value: "BSD",
  },
  {
    name: "Taka de Bangladés",
    value: "BDT",
  },
  {
    name: "Dólar de Barbados",
    value: "BBD",
  },
  {
    name: "Dinar bahreiní",
    value: "BHD",
  },

  {
    name: "Dólar de Belice",
    value: "BZD",
  },
  {
    name: "Franco de oro (Special settlement currency)",
    value: "XFO",
  },
  {
    name: "Rublo bielorruso",
    value: "BYR",
  },
  {
    name: "Boliviano",
    value: "BOB",
  },
  {
    name: "Marco convertible de Bosnia-Herzegovina",
    value: "BAM",
  },
  {
    name: "Pula de Botsuana",
    value: "BWP",
  },
  {
    name: "Dólar de Brunéi",
    value: "BND",
  },
  {
    name: "Lev búlgaro",
    value: "BGN",
  },
  {
    name: "Franco CFA",
    value: "XOF",
  },
  {
    name: "Franco CFA de África Occidental",
    value: "XOF",
  },
  {
    name: "Franco burundés",
    value: "BIF",
  },
  {
    name: "Ngultrum de Bután",
    value: "BTN",
  },
  {
    name: "Escudo caboverdiano",
    value: "CVE",
  },
  {
    name: "Riel camboyano",
    value: "KHR",
  },
  {
    name: "Franco CFA de África Central",
    value: "XAF",
  },
  {
    name: "Dólar canadiense",
    value: "CAD",
  },
  {
    name: "Rial qatarí",
    value: "QAR",
  },
  {
    name: "Franco CFA de África Central",
    value: "XAF",
  },
  {
    name: "Yuan chino",
    value: "CNY",
  },

  {
    name: "Franco comoriano (de Comoras)",
    value: "KMF",
  },
  {
    name: "Won norcoreano",
    value: "KPW",
  },
  {
    name: "Won surcoreano",
    value: "KRW",
  },
  {
    name: "Franco CFA de África Occidental",
    value: "XOF",
  },
  {
    name: "Kuna croata",
    value: "HRK",
  },
  {
    name: "Peso cubano",
    value: "CUP / CUC",
  },
  {
    name: "Dinar sudanés",
    value: "SDG",
  },
  {
    name: "Corona danesa",
    value: "DKK",
  },
  {
    name: "Dólar del Caribe Oriental",
    value: "XCD",
  },
  {
    name: "Libra egipcia",
    value: "EGP",
  },
  {
    name: "Dirham de los Emiratos Árabes Unidos",
    value: "AED",
  },
  {
    name: "Nakfa eritreo",
    value: "ERN",
  },

  {
    name: "Birr etíope",
    value: "ETB",
  },
  {
    name: "Dólar fiyiano",
    value: "FJD",
  },
  {
    name: "Peso filipino",
    value: "PHP",
  },

  {
    name: "Franco CFA de África Central",
    value: "XAF",
  },
  {
    name: "Dalasi gambiano",
    value: "GMD",
  },
  {
    name: "Lari georgiano",
    value: "GEL",
  },
  {
    name: "Cedi ghanés",
    value: "GHS",
  },
  {
    name: "Dólar del Caribe Oriental",
    value: "XCD",
  },

  {
    name: "Franco guineano",
    value: "GNF",
  },
  {
    name: "Franco CFA de África Occidental",
    value: "XOF",
  },
  {
    name: "Franco CFA de África Central",
    value: "XAF",
  },
  {
    name: "Dólar guyanés",
    value: "GYD",
  },
  {
    name: "Gourde haitiano",
    value: "HTG",
  },
  {
    name: "Forint húngaro",
    value: "HUF",
  },
  {
    name: "Rupia india",
    value: "INR",
  },
  {
    name: "Rupiah indonesia",
    value: "IDR",
  },
  {
    name: "Dinar iraquí",
    value: "IQD",
  },
  {
    name: "Rial iraní",
    value: "IRR",
  },

  {
    name: "Króna islandesa",
    value: "ISK",
  },
  {
    name: "Dólar de las Islas Salomón",
    value: "SBD",
  },
  {
    name: "Nuevo shéquel israelí",
    value: "ILS",
  },

  {
    name: "Dólar jamaicano",
    value: "JMD",
  },
  {
    name: "Yen japonés",
    value: "JPY",
  },
  {
    name: "Dinar jordano",
    value: "JOD",
  },
  {
    name: "Tenge kazajo",
    value: "KZT",
  },
  {
    name: "Chelín keniata",
    value: "KES",
  },
  {
    name: "Som kirguís (de Kirguistán)",
    value: "KGS",
  },
  {
    name: "Dólar australiano",
    value: "AUD",
  },
  {
    name: "Dinar kuwaití",
    value: "KWD",
  },
  {
    name: "Kip lao",
    value: "LAK",
  },
  {
    name: "Loti lesotense",
    value: "LSL",
  },

  {
    name: "Libra libanesa",
    value: "LBP",
  },
  {
    name: "Dólar liberiano",
    value: "LRD",
  },
  {
    name: "Dinar libio",
    value: "LYD",
  },
  {
    name: "Franco suizo",
    value: "CHF",
  },

  {
    name: "Denar macedonio",
    value: "MKD",
  },
  {
    name: "Ariary malgache",
    value: "MGA",
  },
  {
    name: "Ringgit malayo",
    value: "MYR",
  },
  {
    name: "Kwacha malauí",
    value: "MWK",
  },
  {
    name: "Rufiyaa maldiva",
    value: "MVR",
  },
  {
    name: "Franco CFA de África Occidental",
    value: "XOF",
  },

  {
    name: "Dirham marroquí",
    value: "MAD",
  },
  {
    name: "Rupia mauricia",
    value: "MUR",
  },
  {
    name: "Ouguiya mauritana",
    value: "MRO",
  },
  {
    name: "Peso mexicano",
    value: "MXN",
  },
  {
    name: "Leu moldavo",
    value: "MDL",
  },

  {
    name: "Tughrik mongol",
    value: "MNT",
  },

  {
    name: "Metical mozambiqueño",
    value: "MZN",
  },
  {
    name: "Rand sudafricano",
    value: "ZAR",
  },
  {
    name: "Dólar australiano",
    value: "AUD",
  },
  {
    name: "Rupia nepalesa",
    value: "NPR",
  },
  {
    name: "Franco CFA de África Occidental",
    value: "XOF",
  },
  {
    name: "Naira nigeriana",
    value: "NGN",
  },
  {
    name: "Corona noruega",
    value: "NOK",
  },
  {
    name: "Dólar neozelandés",
    value: "NZD",
  },
  {
    name: "Rial omaní",
    value: "OMR",
  },

  {
    name: "Rupia pakistaní",
    value: "PKR",
  },
  {
    name: "Kina de Papúa Nueva Guinea",
    value: "PGK",
  },
  {
    name: "Guaraní paraguayo",
    value: "PYG",
  },
  {
    name: "Nuevo sol peruano",
    value: "PEN",
  },

  {
    name: "Libra egipcia",
    value: "EGP",
  },
  {
    name: "Libra esterlina (libra de Gran Bretaña)",
    value: "GBP",
  },
  {
    name: "Franco CFA",
    value: "CFA",
  },
  {
    name: "Koruna checa",
    value: "CZK",
  },
  {
    name: "Franco congoleño",
    value: "CDF",
  },
  {
    name: "Franco congoleño",
    value: "CDF",
  },
  {
    name: "Peso dominicano",
    value: "DOP",
  },
  {
    name: "Rand sudafricano",
    value: "ZAR",
  },
  {
    name: "Franco ruandés",
    value: "RWF",
  },
  {
    name: "Leu rumano",
    value: "RON",
  },
  {
    name: "Rublo ruso",
    value: "RUB",
  },
  {
    name: "Tala samoana",
    value: "WST",
  },
  {
    name: "Dólar del Caribe Oriental",
    value: "XCD",
  },

  {
    name: "Dólar del Caribe Oriental",
    value: "XCD",
  },
  {
    name: "Dólar del Caribe Oriental",
    value: "XCD",
  },
  {
    name: "Dobra de Santo Tomé y Príncipe",
    value: "STD",
  },
  {
    name: "Franco CFA de África Occidental",
    value: "XOF",
  },
  {
    name: "Dinar serbio",
    value: "RSD",
  },
  {
    name: "Rupia de Seychelles",
    value: "SCR",
  },
  {
    name: "Leone de Sierra Leona",
    value: "SLL",
  },
  {
    name: "Dólar de Singapur",
    value: "SGD",
  },
  {
    name: "Libra siria",
    value: "SYP",
  },
  {
    name: "Chelín somalí",
    value: "SOS",
  },
  {
    name: "Rupia de Sri Lanka",
    value: "LKR",
  },
  {
    name: "Lilangeni suazi",
    value: "SZL",
  },
  {
    name: "Dinar sudanés",
    value: "SDG",
  },
  {
    name: "Libra sursudanesa",
    value: "SSP",
  },
  {
    name: "Corona sueca",
    value: "SEK",
  },
  {
    name: "Franco suizo",
    value: "CHF",
  },
  {
    name: "Dólar surinamés",
    value: "SRD",
  },
  {
    name: "Baht tailandés",
    value: "THB",
  },
  {
    name: "Chelín tanzano",
    value: "TZS",
  },
  {
    name: "Somoni tayik (de Tayikistán)",
    value: "TJS",
  },
  {
    name: "Franco CFA de África Occidental",
    value: "XOF",
  },
  {
    name: "Pa'anga tongano",
    value: "TOP",
  },
  {
    name: "Dólar de Trinidad y Tobago",
    value: "TTD",
  },
  {
    name: "Dinar tunecino",
    value: "TND",
  },
  {
    name: "Manat turcomano",
    value: "TMT",
  },
  {
    name: "Lira turca",
    value: "TRY",
  },
  {
    name: "Dólar australiano",
    value: "AUD",
  },
  {
    name: "Grivna ucraniana",
    value: "UAH",
  },
  {
    name: "Chelín ugandés",
    value: "UGX",
  },
  {
    name: "Peso uruguayo",
    value: "UYU",
  },
  {
    name: "Som uzbeko",
    value: "UZS",
  },
  {
    name: "Vatu vanuatense",
    value: "VUV",
  },
  {
    name: "Bolívar fuerte venezolano",
    value: "VEF",
  },
  {
    name: "Dong vietnamita",
    value: "VND",
  },
  {
    name: "Rial yemení (de Yemen)",
    value: "YER",
  },
  {
    name: "Franco yibutiano",
    value: "DJF",
  },
  {
    name: "Kwacha zambiano",
    value: "ZMW",
  },
  {
    name: "Dólar de Hong Kong",
    value: "HKD",
  },
];
