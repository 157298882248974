export enum TransactionType {
  ADDITIONAL = "additionalServices",
  CHARGEBACK = "CHARGEBACK",
  DEFERRED = "DEFFERED",
  PAYOUT = "PAYOUT",
  REFUND = "REFUND",
  SALE = "SALE",
  VOID = "VOID",
  CAPTURE = "CAPTURE",
  PREAUTHORIZATION = "PREAUTHORIZATION",
  ADDITIONAL_SERVICE = "additionalServices",
}
