import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    list: {
      listStyle: "none",
      paddingLeft: "20px",
    },
  })
);
