export enum PaymentMethodsEnum {
  TRANSFER = "transfer",
  CARD = "card",
  CASH = "cash",
}
export enum PaymentMethodsUpperEnum {
  TRANSFER = "TRANSFER",
  CARD = "CARD",
  CASH = "CASH",
}
export enum PaymentMethodsLabelEnum {
  TRANSFER = "Transferencia",
  CARD = "Tarjeta",
  CASH = "Efectivo",
}

export const PaymentMethodLabel: Record<PaymentMethodsEnum, string> = {
  [PaymentMethodsEnum.TRANSFER]: PaymentMethodsLabelEnum.TRANSFER,
  [PaymentMethodsEnum.CARD]: PaymentMethodsLabelEnum.CARD,
  [PaymentMethodsEnum.CASH]: PaymentMethodsLabelEnum.CASH,
};
