import { Alarm } from "../../../types/alarm";
import { SnackBarMessages } from "@shared/enum/snackBarMessages";

const DEFAULT_ALARM: Alarm = {
  _id: "",
  category: "",
  commercialExecutive: "",
  commercialExecutiveEmail: "",
  country: "",
  createdAt: 0,
  createdBy: "",
  customerId: "",
  customerName: "",
  frequency: "",
  merchantName: "",
  ownerId: "",
  ownerName: "",
  ruleAlarmType: "",
  ruleGrafanaId: "",
  ruleId: "",
  status: "",
  timeline: [],
  tpv: 0,
  variables: [],
};

enum ACTION_TYPE {
  INVESTIGATION = "investigation",
  ROS = "ros",
  NORMAL = "normal",
  PENDING = "pending",
}

const SNACKBAR_MESSAGE: object = {
  [ACTION_TYPE.INVESTIGATION]: {
    error: SnackBarMessages.ERROR_INITIALIZE_INVESTIGATION,
    success: SnackBarMessages.INITIALIZE_INVESTIGATION,
  },
  [ACTION_TYPE.ROS]: {
    error: SnackBarMessages.ERROR_CANCEL_ALARM,
    success: SnackBarMessages.SUCCESS_CANCEL_ALARM,
  },
  [ACTION_TYPE.NORMAL]: {
    error: SnackBarMessages.ERROR_CANCEL_ALARM,
    success: SnackBarMessages.SUCCESS_CANCEL_ALARM,
  },
  [ACTION_TYPE.PENDING]: {
    error: SnackBarMessages.ERROR_REACTIVATE_ALARM,
    success: SnackBarMessages.SUCCESS_REACTIVATE_ALARM,
  },
};

export { DEFAULT_ALARM, SNACKBAR_MESSAGE, ACTION_TYPE };
