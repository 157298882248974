import React, { useEffect } from "react";
import {
  Button,
  createStyles,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { Trash2, CheckCircle, Edit, Plus, XCircle } from "react-feather";
import ConnectForm from "../commons/ConnectForm/ConnectForm";
import { useTypedController } from "@hookform/strictly-typed";
import { makeStyles } from "@material-ui/core/styles";
import { TWebhookForm } from "../../containers/WebhookFormIndex/state/useWebhookForm";
import { useWatch } from "react-hook-form";
import { PaymentUrlWithStatus } from "../../shared/infrastructure/interfaces/IWebhookForm";
import { ModalTestUrl } from "../ModalTestConnection/ModalTestURL/ModalTestURL";
import { useTextFormState } from "./state/useTextFormState";
import { Status } from "../../../types/webhook_create_request";
import { IValidUrl } from "../../shared/infrastructure/interfaces/IValidUrl";
import { get, isEmpty } from "lodash";
import { ServiceEnum } from "../../shared/infrastructure/constants/ServiceEnum";
import { StatusEnum } from "../../shared/infrastructure/constants/StatusEnum";
import theme from "../../theme";
import { TEXTS } from "../../shared/infrastructure/constants/ModalTexts";

const useStyles = makeStyles(
  createStyles({
    iconStyle: { background: "#F0F4F9" },
    addButton: {
      height: "56px",
      fontWeight: 600,
      fontSize: "16px",
      marginLeft: "5px",
      width: "134px",
    },
    empty: {
      fontSize: "12px",
      color: "#AD0C2A",
    },
    text: {
      color: "#293036",
      fontSize: "16px",
    },
    urlSection: {
      marginTop: "14px",
      marginBottom: "10px",
    },
    url: {
      marginTop: "8px",
      marginBottom: "8px",
    },
    saveButton: {
      height: "56px",
      width: "108px",
      fontWeight: "bold",
      fontSize: "16px",
      marginTop: "6px",
      marginLeft: "8px",
      background: "#023365",
      color: "#FFFFFF",
    },
    error: {
      padding: 9,
      background: theme.palette.error.light,
      minWidth: "30px",
      borderRadius: "4px",
    },
    success: {
      padding: 9,
      background: theme.palette.success.light,
      minWidth: "30px",
      borderRadius: "4px",
    },
  })
);
export const TextForm: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <ConnectForm<TWebhookForm>>
        {({ control, setValue, getValues, setError, clearErrors }) => {
          const TypedController = useTypedController<TWebhookForm>({
            control: control,
          });

          const paymentUrls = useWatch({
            name: "paymentUrls",
          }) as PaymentUrlWithStatus[];

          useEffect(() => {
            const isValid = isPaymentUrlValid();
            if (isValid) {
              setError("paymentUrl", {});
            } else {
              clearErrors("paymentUrl");
            }
          }, [paymentUrls]);

          const handleCreateUrl = () => {
            const { urlWebhook } = getValues();
            if (urlWebhook.trim() === "") {
              handleActiveState(true);
            } else {
              handleOpenModal(urlWebhook);
            }
          };

          const hasUrlPreauth = () => {
            const paymentUrls = get(getValues(), "paymentUrls", []);
            const getUrls: object[] = get(paymentUrls[0], "urls");

            return !isEmpty(paymentUrls) && !isEmpty(getUrls);
          };

          const isPaymentUrlValid = (): boolean =>
            !(
              paymentUrls.length > 0 &&
              paymentUrls.filter((paymentUrl) => paymentUrl.urls.length > 0)
                .length > 0
            );

          const handleEditUrl = () => {
            handleOpenModal(getEditedWebhookURL().url);
          };

          const addUrl = (verifiedUrls: IValidUrl | undefined) => {
            handleActiveState(false);
            const index: number = paymentUrls
              .map((e) => e.service)
              .indexOf(ServiceEnum.GENERAL);
            if (index > -1) {
              paymentUrls[index].urls.push({
                url: get(verifiedUrls, ServiceEnum.GENERAL, [])[0].url,
                status: get(verifiedUrls, ServiceEnum.GENERAL, [])[0].status,
              });
              setValue("paymentUrls", [...paymentUrls]);
            } else {
              paymentUrls.push({
                service: "general",
                urls: [
                  {
                    url: get(verifiedUrls, ServiceEnum.GENERAL, [])[0].url,
                    status: get(verifiedUrls, ServiceEnum.GENERAL, [])[0]
                      .status,
                  },
                ],
              });
              setValue("paymentUrls", [...paymentUrls]);
            }
            setValue("urlWebhook", "");
          };

          const {
            modalTestUrl,
            handleOpenModal,
            handleEditionInput,
            getEditionInput,
            handleActiveState,
            handleEditedWebhookURL,
            getEditedWebhookURL,
            isPreauthEnable,
          } = useTextFormState({
            addUrl,
          });

          const removeUrl = (index: number) => {
            const { paymentUrls } = getValues();
            const id: number = paymentUrls
              .map((e) => e.service)
              .indexOf("general");
            paymentUrls[id].urls.splice(index, 1);
            setValue("paymentUrls", [...paymentUrls]);
          };
          return (
            <>
              <Grid container alignItems="center">
                <Grid
                  item
                  container
                  xs={12}
                  alignContent="flex-start"
                  alignItems="flex-start"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  {!isPreauthEnable ? (
                    <Grid item style={{ flex: "1" }}>
                      <TypedController
                        name={"urlWebhook"}
                        render={(props) => (
                          <TextField
                            {...props}
                            id="url-general"
                            label="URL"
                            variant="outlined"
                            required
                            fullWidth
                            helperText={
                              isPaymentUrlValid() && (
                                <FormHelperText className={classes.empty}>
                                  Es necesario agregar al menos un URL para
                                  configurar el webhook.
                                </FormHelperText>
                              )
                            }
                          />
                        )}
                      />
                    </Grid>
                  ) : (
                    <Grid item style={{ flex: "1" }}>
                      <TypedController
                        name={"urlWebhook"}
                        render={(props) => (
                          <TextField
                            {...props}
                            id="url-general"
                            label="URL de Preautorización."
                            variant="outlined"
                            required
                            fullWidth
                            disabled={hasUrlPreauth() || getEditionInput()}
                            helperText={
                              isPaymentUrlValid() && (
                                <FormHelperText className={classes.empty}>
                                  {TEXTS.preauthHelpText}
                                </FormHelperText>
                              )
                            }
                          />
                        )}
                      />
                    </Grid>
                  )}

                  <Grid item>
                    <Button
                      className={classes.addButton}
                      variant="contained"
                      aria-label="addButton"
                      color="primary"
                      onClick={() => {
                        handleCreateUrl();
                      }}
                      startIcon={<Plus size={23} />}
                    >
                      Agregar
                    </Button>
                  </Grid>
                </Grid>
                {getEditionInput() && (
                  <Grid
                    item
                    container
                    xs={12}
                    alignContent="center"
                    alignItems="center"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Grid item style={{ flex: "1" }}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={getEditedWebhookURL().url}
                        onChange={(event) => {
                          handleEditedWebhookURL(
                            event.target.value,
                            getEditedWebhookURL().index
                          );
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        className={classes.saveButton}
                        onClick={() => {
                          handleEditUrl(), handleEditionInput(false);
                        }}
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                )}

                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  alignItems="center"
                  alignContent="space-between"
                  className={classes.urlSection}
                >
                  {paymentUrls.length > 0 &&
                    paymentUrls[0].urls.map((item: Status, index: number) => (
                      <Grid
                        item
                        container
                        key={index}
                        xs={12}
                        sm={12}
                        md={12}
                        alignItems="center"
                        alignContent="space-between"
                        className={classes.url}
                      >
                        <Grid
                          item
                          container
                          xs={8}
                          sm={9}
                          md={10}
                          alignItems="center"
                          justify="center"
                        >
                          <Grid item xs={3} sm={1} md={1}>
                            {item.status === StatusEnum.OK ? (
                              <CheckCircle
                                size={38}
                                color={theme.palette.success.dark}
                                className={
                                  item.status === StatusEnum.OK
                                    ? classes.success
                                    : classes.error
                                }
                                style={{ verticalAlign: "middle" }}
                              />
                            ) : (
                              <XCircle
                                size={38}
                                color={theme.palette.error.dark}
                                className={
                                  item.status === StatusEnum.OK
                                    ? classes.success
                                    : classes.error
                                }
                              />
                            )}
                          </Grid>
                          <Grid item xs={9} sm={11} md={11}>
                            <Typography className={classes.text}>
                              {item.url}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={4}
                          sm={3}
                          md={2}
                          alignItems="center"
                          justify="flex-end"
                        >
                          <Grid
                            item
                            xs={6}
                            sm={5}
                            md={5}
                            style={{ textAlign: "center" }}
                          >
                            <IconButton
                              onClick={() => {
                                if (!getEditionInput()) {
                                  handleEditionInput(true);
                                  handleEditedWebhookURL(item.url, index);
                                  removeUrl(index);
                                }
                              }}
                              style={{ textAlign: "center" }}
                            >
                              <Edit
                                size={25}
                                color="#023365"
                                style={{ textAlign: "center" }}
                              />
                            </IconButton>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={5}
                            md={5}
                            style={{ textAlign: "center" }}
                          >
                            <IconButton onClick={() => removeUrl(index)}>
                              <Trash2 size={25} color="#AD0C2A" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
              <ModalTestUrl
                open={modalTestUrl.open}
                loading={modalTestUrl.loading}
                verifiedUrls={modalTestUrl.verifiedUrls}
                handleCloseDialog={modalTestUrl.handleCloseDialog}
                handleSave={modalTestUrl.handleSave}
                loadingModal={modalTestUrl.loadingModal}
              />
            </>
          );
        }}
      </ConnectForm>
    </>
  );
};
