import { UserList } from "../../../../types/user_list";
import { UserResponse } from "../../../../types/user_response";
import useFetchData from "../../../hooks/useFecthData/useFetchData";
import axios from "../../../shared/axios-util";
import axiosInstance from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import {
  IConfigurationItem,
  INodeInfoResponse,
} from "../../../../types/node_info_response";
import { Configs } from "../../../../types/get_node_info_response";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LabelEnums } from "../../../shared/enums/LabelEnums";
import { ROLES } from "../../../shared/infrastructure/constants/UserResumeConstants";
import { IUserListResponse } from "../../../shared/infrastructure/interfaces/UserListResponse";
import {
  setCompliancePeople,
  setMainAccordionCompany,
  setMainAccordionDeveloper,
  setMainAccordionDiscount,
  setMainAccordionFinancial,
  setMainAccordionProcess,
  setMainAccordionService,
  setMainAccordionUser,
  setMainAccordionWallet,
  setNodeInfoResponse,
  setServiceConfiguration,
} from "../../../store/actions/actions";
import {
  compliancePeople,
  searchNodesList,
  selectAccordionCompanyOpen,
  selectAccordionDiscountOpen,
  selectAccordionFinancialOpen,
  selectAccordionProcessOpen,
  selectBalanceData,
  selectDeveloperProcessOpen,
  selectMerchantSettingsInfo,
  selectNodeInfo,
  selectServiceOpen,
  selectUserOpen,
  selectWalletOpen,
  users,
} from "../../../store/selectors/selectors";
import { useHistory, useLocation } from "react-router";
import { routes } from "../../../shared/infrastructure/constants/routes";
import { LOCAL_STORAGE_ITEMS } from "../../../shared/constants/local_storage_items";
import { ConfigurationIdEnum } from "../../../shared/enums";
import { ResumeDataInterface } from "../../../shared/infrastructure/interfaces/IInputsProps.interfaces";
import { get, useForm } from "react-hook-form";
import { IMerchantNodeResponse } from "../../../../types/post_merchant_node_response";
import { QueryParamsEnum } from "../../../shared/enums/queryParamsEnum";
import { statusNodeInfo } from "../../../shared/utils/node_info";
import { EntityNameEnum } from "../../../shared/enums/entityName";
import { CountriesEnum } from "../../../shared/infrastructure/countries-enum";
import {
  Boardmembers,
  CompliancePeopleNodeResponse,
} from "../../../../types/get_compliance_people_response";
import { ILegalRepresentatives } from "../../../components/LegalRepresentatives/LegalRepresentatives.interface";
import { transformCompliancePeople } from "../../../components/LegalRepresentatives/LegalRepresentativesState.utils";
import {
  ILegalRepresentative,
  IShareHolder,
} from "../../../store/interfaces/LegalDetailsState.interfaces";
import { ActiveClientEnum } from "../../../shared/enums/activeClientEnum";
import {
  RedirectRoutesEnum,
  ROUTE,
} from "../../../shared/constants/redirect_routes";
import { defaultTo, isEmpty, isEqual, isUndefined, remove } from "lodash";
import { CountryEnum } from "../../../shared/countryEnum";
import { getBalance, getUsers } from "../../../store/thunks/app/app.thunks";
import { ISearchMerchantNode } from "../../../components/WebhookNotifications/state/useWebhookNotification.interface";
import { useAppSelector } from "../../../store/hooks/hooks";
import { RootState } from "../../../store/store.interface";
import { StatusMerchantEnum } from "../../../shared/infrastructure/status-enum";
import { MessagesAlertEnum } from "../../../shared/infrastructure/messagesAlert-enum";
import {
  NODE_INFO_KEYS,
  requiredDataConfigs,
} from "./useMerchantResume.interfaces";
import { IBalanceResponse } from "../../../../types/balance_response";
import { IGetMerchantSettingsDataResponse } from "../../../../types/get_merchant_settings_data_response";
import { hasCashOrTransferPayoutsProcessors } from "../../../shared/utils/walletUtils";

const isRequiredDataMissing = (
  nodeInfo: INodeInfoResponse,
  entityName: string
): boolean => {
  const operationalCountry: string = get(nodeInfo, "generalInfo.country", "");

  if (
    !isEmpty(operationalCountry) &&
    operationalCountry !== CountryEnum.ecuador
  ) {
    remove(requiredDataConfigs, (data) => data === ConfigurationIdEnum.CN017);
  }

  return (
    nodeInfo?.entityName === entityName &&
    requiredDataConfigs.every((requiredConfig: string) =>
      nodeInfo.configs.some(
        (config) =>
          config.configuration === requiredConfig &&
          (config.status === statusNodeInfo.COMPLETE ||
            config.status === statusNodeInfo.OMITTED)
      )
    )
  );
};

const saveCurrentLocation = (publicMerchantId: string | null) => {
  const item = localStorage.getItem(
    LOCAL_STORAGE_ITEMS.BASIC_MERCHANT_INFORMATION
  );

  let basicMerchantInformation = { layout: {} };

  if (item) {
    basicMerchantInformation = JSON.parse(item);
  }

  localStorage.setItem(
    LOCAL_STORAGE_ITEMS.BASIC_MERCHANT_INFORMATION,
    JSON.stringify({
      ...basicMerchantInformation,
      layout: {
        ...basicMerchantInformation.layout,
        lastRoute: `/merchant-resume?publicMerchantId=${publicMerchantId}`,
      },
    })
  );
};

const getCentralizedBillingBranch = (nodeInfo?: INodeInfoResponse): string => {
  if (!nodeInfo || nodeInfo.entityName !== EntityNameEnum.BRANCH) return "";

  const [billingConfig]: IConfigurationItem[] = nodeInfo.configs.filter(
    (configObj: IConfigurationItem) =>
      configObj.configuration === ConfigurationIdEnum.CN003
  );

  if (!billingConfig || Array.isArray(billingConfig.centralizedNodesId))
    return "";

  if (
    billingConfig.isCentralized ||
    billingConfig.centralizedNodesId === nodeInfo.path.split(":").slice(-2)[0]
  )
    return billingConfig.value;

  return "";
};

const getNodeInfoResponseData = (
  nodeInfo?: object,
  targetKey: string = "",
  defaultValue?: string | null
) => {
  return get(nodeInfo, targetKey, defaultValue);
};

const checkIsFooterOptionsOpen = (
  nodeInfoResponse: object,
  queryParamMode: string | null
): boolean =>
  !(
    getNodeInfoResponseData(nodeInfoResponse, NODE_INFO_KEYS.ENTITY_NAME) ===
      EntityNameEnum.BRANCH && queryParamMode === "edition"
  );

export const useMerchantResume = () => {
  const isCompanyAccordionOpen = useSelector(selectAccordionCompanyOpen);
  const isFinancialAccordionOpen = useSelector(selectAccordionFinancialOpen);
  const isDiscountAccordionOpen = useSelector(selectAccordionDiscountOpen);
  const isProcessAccordionOpen = useSelector(selectAccordionProcessOpen);
  const isDeveloperAccordionOpen = useSelector(selectDeveloperProcessOpen);
  const isServiceAccordionOpen = useSelector(selectServiceOpen);
  const isUserAccordionOpen = useSelector(selectUserOpen);
  const isWalletAccordionOpen = useSelector(selectWalletOpen);
  const balanceResponse: IBalanceResponse = useSelector(selectBalanceData);
  const merchantSettingsData: IGetMerchantSettingsDataResponse | undefined =
    useSelector(selectMerchantSettingsInfo);

  const nodeInfoData = useSelector(selectNodeInfo);
  const compliancePeopleData: CompliancePeopleNodeResponse | undefined =
    useSelector(compliancePeople);
  const userResponseData: IUserListResponse | undefined = useSelector(users);
  const searchNodes: ISearchMerchantNode[] | undefined =
    useSelector(searchNodesList);
  const { statusMerchant } = useAppSelector((state: RootState) => state.app);

  const [isModalSimpleSecurityOpen, setIsModalSimpleSecurityOpen] =
    useState(false);
  const [isModalBranchCreateOpen, setIsModalBranchCreateOpen] = useState(false);
  const [isLoaderModalOpen, setIsLoaderModalOpen] = useState(false);
  const [shouldRenderChargesBlock, setShouldRenderChargesBlock] =
    useState<boolean>(false);
  const [shouldRenderDispersionBlock, setShouldRenderDispersionBlock] =
    useState<boolean>(false);
  const [usersList, setUsersList] = useState<UserList[]>([]);
  const [link, setLink] = useState<string>("");
  const [linkCustomer, setLinkCustomer] = useState<string>("");
  const [hasChildrens, sethasChildrens] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [typeAlert, setAlertAlert] = useState<string>(
    MessagesAlertEnum.WARNING
  );
  const [isPendingUsers, setIsPendingUsers] = useState<boolean>(true);
  const [canShowWallet, setCanShowWallet] = useState<boolean>(false);

  const userSectionRef = useRef(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  const publicMerchantId = searchParams.get(QueryParamsEnum.PUBLIC_MERCHANT_ID);
  const userScrollParam = searchParams.get(QueryParamsEnum.USERS);

  const [isDataComplete, setIsDataComplete] = useState<boolean | undefined>();
  const [queryParamMode, setQueryParamMode] = useState<string | null>("");
  const [legalRepresentatives, setLegalRepresentatives] = useState<
    ILegalRepresentative[]
  >([]);
  const [shareHolders, setShareHolders] = useState<IShareHolder[]>([]);
  const [boardMembers, setBoardMembers] = useState<Boardmembers[]>([]);
  const [branchCreated, setBranchCreated] = useState<IMerchantNodeResponse>({});
  const [customerName, setCustomerName] = useState<string>("");
  const [
    isModalBranchCreatedSuccessfulOpen,
    setIsModalBranchCreatedSuccessfulOpen,
  ] = useState<boolean>(false);
  const [queryParamActiveClient, setQueryParamActiveClient] = useState<
    string | null
  >("");
  const [isDisabledCreateBranchBtn, setIsDisabledCreateBranchBtn] =
    useState<boolean>(true);

  const [requiredConfigs, setRequiredConfigs] = useState<boolean>(false);

  const nodeInfoResponse = useFetchData(() => {
    const configIdsArr = [
      ConfigurationIdEnum.CN001,
      ConfigurationIdEnum.CN002,
      ConfigurationIdEnum.CN003,
      ConfigurationIdEnum.CN004,
      ConfigurationIdEnum.CN005,
      ConfigurationIdEnum.CN006,
      ConfigurationIdEnum.CN008,
      ConfigurationIdEnum.CN010,
      ConfigurationIdEnum.CN011,
      ConfigurationIdEnum.CN016,
      ConfigurationIdEnum.CN015,
      ConfigurationIdEnum.CN007,
      ConfigurationIdEnum.CN017,
      ConfigurationIdEnum.CN018,
      ConfigurationIdEnum.CN014,
      ConfigurationIdEnum.CN019,
    ];

    return axios.post<INodeInfoResponse>(API_ROUTES.NODE_INFO, {
      configIds: configIdsArr.join(","),
      publicMerchantId,
    });
  }, [publicMerchantId]);

  const centralizedBillingMerchantId: string = getCentralizedBillingBranch(
    nodeInfoResponse.data?.data
  );
  const isCentralizedBillingBranch: boolean = !!centralizedBillingMerchantId;

  const getCompliancePeople = async (
    publicMerchantId: string
  ): Promise<any> => {
    try {
      const response = await axios.get(
        `${API_ROUTES.COMPLIANCE_PEOPLE}${publicMerchantId}`
      );

      dispatch(setCompliancePeople(response.data));
    } catch (err) {
      /* istanbul ignore next */
      console.warn(err);
    }
  };

  const getLegalDetails = (configs: IConfigurationItem[]) => {
    if (configs) {
      configs!.map((conf: Configs) => {
        if (
          [
            ConfigurationIdEnum.CN015.toString(),
            ConfigurationIdEnum.CN015,
          ].includes(get(conf, "configuration", ""))
        ) {
          getCompliancePeople(get(conf, "value", ""));
        }
      });
    }
  };

  const getAttributeUser = (
    attributes: { Name: string; Value: string }[],
    attributeName: string
  ): string => {
    const attr: { Name: string; Value: string } | undefined = attributes.find(
      (attribute: { Name: string; Value: string }) =>
        attribute.Name === attributeName
    );

    return attr ? attr.Value : "";
  };

  const getRolName = (rol: string): string => {
    const rol_found: { id: string; value: string } | undefined = ROLES.find(
      (rolLoop: { id: string; value: string }) =>
        rolLoop.id.toLowerCase() === rol.toLowerCase()
    );

    if (rol_found !== undefined) return rol_found.value;

    return "";
  };

  const filterUser = (userList: UserList[]): UserList[] => {
    return (userList || []).filter(
      (item) => get(item, "Enabled", false) === true
    );
  };

  const mapUserList = (userList: UserResponse[]): UserList[] => {
    return defaultTo(userList, []).map((user: UserResponse) => {
      const getRoles: string[] = get(user, "Groups", []).map(
        (g: { GroupName: string }) => {
          return getRolName(g.GroupName);
        }
      );

      return {
        mail: getAttributeUser(user.Attributes!, "email"),
        name: getAttributeUser(user.Attributes!, "name").concat(
          " ",
          getAttributeUser(user.Attributes!, "family_name")
        ),
        roles: getRoles.join(),
        userName: get(user, "Username", ""),
      };
    });
  };

  const statusNode = (status: string): boolean =>
    get(nodeInfoResponse, "data.data.generalInfo.status", "") === status &&
    get(nodeInfoResponse, "data.data.entityName", "") === EntityNameEnum.BRANCH;

  useEffect(() => {
    if (centralizedBillingMerchantId.trim().length > 0)
      axios
        .post<INodeInfoResponse>(API_ROUTES.NODE_INFO, {
          configIds: ConfigurationIdEnum.CN001,
          publicMerchantId: centralizedBillingMerchantId,
        })
        .then((response) =>
          setCustomerName(get(response, "data.generalInfo.name", ""))
        );
  }, [centralizedBillingMerchantId]);

  useEffect(() => {
    if (nodeInfoResponse.data) {
      dispatch(setNodeInfoResponse(nodeInfoResponse.data.data));

      const result = isRequiredDataMissing(
        nodeInfoResponse.data.data,
        EntityNameEnum.CUSTOMER
      );

      setIsDataComplete(result);

      if (statusNode(StatusMerchantEnum.ACTIVE)) {
        setAlertMessage(LabelEnums.ALERTACTIVEBRANCH);
        setAlertAlert(MessagesAlertEnum.INFORMATIVE);
      } else if (statusNode(StatusMerchantEnum.INACTIVE)) {
        setAlertMessage(LabelEnums.ALERTINACTIVEBRANCH);
      } else if (
        get(nodeInfoResponse, "data.data.entityName", "") ===
          EntityNameEnum.CUSTOMER &&
        result &&
        get(nodeInfoResponse, "data.data.generalInfo.status", "") !==
          StatusMerchantEnum.ACTIVE
      ) {
        setAlertMessage(LabelEnums.ALERTCREATECUSTOMER);
      }
    }
  }, [nodeInfoResponse.data]);

  useEffect(() => {
    if (
      getNodeInfoResponseData(nodeInfoResponse, NODE_INFO_KEYS.COUNTRY) ===
      CountriesEnum.ECUADOR
    ) {
      setShouldRenderChargesBlock(true);
      setShouldRenderDispersionBlock(true);
    } else {
      setShouldRenderDispersionBlock(true);
      setShouldRenderChargesBlock(false);
    }
  }, [getNodeInfoResponseData(nodeInfoResponse, NODE_INFO_KEYS.GENERAL_INFO)]);

  useEffect(() => {
    let serviceDispatched = false;

    if (nodeInfoData?.configs) {
      const userConfig = nodeInfoData.configs.find(
        (config) => config.configuration === ConfigurationIdEnum.CN019
      );

      setIsPendingUsers(
        get(userConfig, "status", statusNodeInfo.COMPLETE) ===
          statusNodeInfo.PENDING
      );

      const serviceConfig = nodeInfoData.configs.find(
        (config) => config.configuration === ConfigurationIdEnum.CN011
      );

      if (
        serviceConfig &&
        serviceConfig.centralizedNodesId &&
        serviceConfig.centralizedNodesId !== [] &&
        serviceConfig?.value
      ) {
        serviceDispatched = true;
        dispatch(
          setServiceConfiguration({
            isCentralized: true,
            publicMerchantId: serviceConfig.value,
          })
        );
      }
    }
    if (!serviceDispatched)
      dispatch(
        setServiceConfiguration({
          isCentralized: false,
          publicMerchantId,
        })
      );
    getLegalDetails(nodeInfoData?.configs!);
  }, [nodeInfoData?.configs]);

  useEffect(() => {
    if (compliancePeopleData) {
      const setInfoLegal: ILegalRepresentatives = transformCompliancePeople(
        compliancePeopleData!
      );

      setLegalRepresentatives(setInfoLegal.legalRepresentatives!);

      setShareHolders(setInfoLegal.shareholders!);
      setBoardMembers(get(compliancePeopleData, "boardmembers", []));
    }
  }, [compliancePeopleData]);
  useEffect(() => {
    if (userResponseData) {
      setUsersList(
        mapUserList(filterUser(userResponseData.Users as UserList[]))
      );
    }
  }, [userResponseData]);
  useEffect(() => {
    if (searchNodes && searchNodes.length > 0) {
      sethasChildrens(true);
    }
  }, [searchNodes]);

  const onClickCompanyAccordion = () => {
    if (isCompanyAccordionOpen) {
      dispatch(setMainAccordionCompany(false));
    } else {
      dispatch(setMainAccordionCompany(true));
      dispatch(setMainAccordionFinancial(false));
      dispatch(setMainAccordionProcess(false));
      dispatch(setMainAccordionDiscount(false));
      dispatch(setMainAccordionDeveloper(false));
      dispatch(setMainAccordionService(false));
      dispatch(setMainAccordionUser(false));
      dispatch(setMainAccordionWallet(false));
    }
  };

  const onClickFinancialAccordion = () => {
    if (isFinancialAccordionOpen) {
      dispatch(setMainAccordionFinancial(false));
    } else {
      dispatch(setMainAccordionFinancial(true));
      dispatch(setMainAccordionCompany(false));
      dispatch(setMainAccordionProcess(false));
      dispatch(setMainAccordionDiscount(false));
      dispatch(setMainAccordionDeveloper(false));
      dispatch(setMainAccordionService(false));
      dispatch(setMainAccordionUser(false));
      dispatch(setMainAccordionWallet(false));
    }
  };

  const onClickProcessAccordion = () => {
    if (isProcessAccordionOpen) {
      dispatch(setMainAccordionProcess(false));
    } else {
      dispatch(setMainAccordionProcess(true));
      dispatch(setMainAccordionCompany(false));
      dispatch(setMainAccordionFinancial(false));
      dispatch(setMainAccordionDeveloper(false));
      dispatch(setMainAccordionDiscount(false));
      dispatch(setMainAccordionService(false));
      dispatch(setMainAccordionUser(false));
      dispatch(setMainAccordionWallet(false));
    }
  };
  const onClickDeveloperAccordion = () => {
    if (isDeveloperAccordionOpen) {
      dispatch(setMainAccordionDeveloper(false));
    } else {
      dispatch(setMainAccordionDeveloper(true));
      dispatch(setMainAccordionProcess(false));
      dispatch(setMainAccordionCompany(false));
      dispatch(setMainAccordionFinancial(false));
      dispatch(setMainAccordionFinancial(false));
      dispatch(setMainAccordionDiscount(false));
      dispatch(setMainAccordionService(false));
      dispatch(setMainAccordionUser(false));
      dispatch(setMainAccordionWallet(false));
    }
  };
  const onClickDiscountAccordion = () => {
    if (isDiscountAccordionOpen) {
      dispatch(setMainAccordionDiscount(false));
    } else {
      dispatch(setMainAccordionProcess(false));
      dispatch(setMainAccordionDiscount(true));
      dispatch(setMainAccordionCompany(false));
      dispatch(setMainAccordionFinancial(false));
      dispatch(setMainAccordionDeveloper(false));
      dispatch(setMainAccordionService(false));
      dispatch(setMainAccordionUser(false));
      dispatch(setMainAccordionWallet(false));
    }
  };

  const onClickServiceAccordion = () => {
    if (isServiceAccordionOpen) {
      dispatch(setMainAccordionService(false));
    } else {
      dispatch(setMainAccordionProcess(false));
      dispatch(setMainAccordionDiscount(false));
      dispatch(setMainAccordionCompany(false));
      dispatch(setMainAccordionFinancial(false));
      dispatch(setMainAccordionDeveloper(false));
      dispatch(setMainAccordionService(true));
      dispatch(setMainAccordionUser(false));
      dispatch(setMainAccordionWallet(false));
    }
  };
  const onClickUserAccordion = () => {
    if (isUserAccordionOpen) {
      dispatch(setMainAccordionUser(false));
    } else {
      dispatch(setMainAccordionProcess(false));
      dispatch(setMainAccordionDiscount(false));
      dispatch(setMainAccordionCompany(false));
      dispatch(setMainAccordionFinancial(false));
      dispatch(setMainAccordionDeveloper(false));
      dispatch(setMainAccordionService(false));
      dispatch(setMainAccordionWallet(false));
      dispatch(setMainAccordionUser(true));
    }
  };
  const onClickWalletAccordion = () => {
    if (isWalletAccordionOpen) {
      dispatch(setMainAccordionWallet(false));
    } else {
      dispatch(setMainAccordionDiscount(false));
      dispatch(setMainAccordionProcess(false));
      dispatch(setMainAccordionCompany(false));
      dispatch(setMainAccordionFinancial(false));
      dispatch(setMainAccordionDeveloper(false));
      dispatch(setMainAccordionService(false));
      dispatch(setMainAccordionUser(false));
      dispatch(setMainAccordionWallet(true));
    }
  };

  const onRedirectWallet = () => {
    saveCurrentLocation(publicMerchantId);
    history.push({
      pathname: routes.WALLET_DASHBOARD,
      search: `?publicMerchantId=${publicMerchantId}&console=3`,
    });
  };

  useEffect(() => {
    const queryParam = new URLSearchParams(search);
    const queryParamGetMode = queryParam.get(QueryParamsEnum.MODE);
    const queryActiveClient = queryParam.get(QueryParamsEnum.ACTIVE_CLIENT);

    setQueryParamActiveClient(queryActiveClient);
    setQueryParamMode(queryParamGetMode);
  }, []);

  useEffect(() => {
    setIsModalSimpleSecurityOpen(
      queryParamActiveClient === ActiveClientEnum.TRUE
    );
  }, [queryParamActiveClient]);

  const onClick = () => {
    saveCurrentLocation(publicMerchantId);
    history.push({
      pathname: routes.CREATE_NODE,
      search: `?publicMerchantId=${publicMerchantId}`,
    });
  };

  const onRedirect = (route: string) => {
    saveCurrentLocation(publicMerchantId);
    if (route === routes.MERCHANT_RATES) {
      window.location.href = `${routes.CONSOLE_RATES}?publicMerchantId=${publicMerchantId}&operation=edit`;
    } else {
      window.location.href = `${route}?publicMerchantId=${publicMerchantId}&operation=edit`;
    }
  };

  const handleSecondaryAction = async () => {
    const nodeInfo: INodeInfoResponse = defaultTo(
      get(nodeInfoResponse.data, "data"),
      {}
    );

    if (
      get(nodeInfo, "generalInfo.status", "") === StatusMerchantEnum.PENDING
    ) {
      switch (nodeInfo.entityName) {
        case EntityNameEnum.CUSTOMER:
          window.location.href = ROUTE.CLIENTS(nodeInfo.rootId);
          break;
        case EntityNameEnum.BRANCH:
          window.location.href = ROUTE.BRANCH_LIST(
            nodeInfo.rootId,
            nodeInfo.path.split(":")[1]
          );
          break;
      }
    } else {
      if (nodeInfo.entityName !== EntityNameEnum.BRANCH) {
        window.location.href = ROUTE.CLIENTS(nodeInfo.rootId);

        return;
      }

      const res = await axiosInstance.post(
        API_ROUTES.HIERARCHY_GET_FIRST_PARENT,
        { publicMerchantId }
      );

      window.location.href = ROUTE.MERCHANT_RESUME(res.data.merchantId);
    }
  };

  const form = useForm<ResumeDataInterface>({
    defaultValues: {},
    mode: "onChange",
  });

  const handleValidateNameBranch = async () => {
    setIsLoaderModalOpen(true);
    setIsModalBranchCreateOpen(false);
    try {
      const res = await axiosInstance.post<IMerchantNodeResponse>(
        API_ROUTES.CREATE_MERCHANT,
        {
          centralize: true,
          clientType: nodeInfoData?.generalInfo?.clientType,
          constitutionalCountry:
            nodeInfoData?.generalInfo?.constitutionalCountry,
          country: nodeInfoData?.generalInfo?.country,
          entityName: "BRANCH",
          name: form.getValues("nameBranch"),
          publicMerchantId,
          rootId: nodeInfoData?.rootId,
          userAlias: localStorage.getItem("fullName")!,
          userName: localStorage.getItem("username")!,
        }
      );

      setBranchCreated(res.data);
      setIsModalBranchCreateOpen(false);
      setIsModalBranchCreatedSuccessfulOpen(true);
    } catch (error) {
      setIsModalBranchCreateOpen(true);
      form.setError("nameBranch", {
        message: "Este nombre ya está asociado. Por favor, ingresa otro",
        type: "custom",
      });
    } finally {
      setIsLoaderModalOpen(false);
    }
  };

  const isNodeBranch: boolean =
    getNodeInfoResponseData(nodeInfoResponse, NODE_INFO_KEYS.ENTITY_NAME) ===
    EntityNameEnum.BRANCH;

  const isNodeCustomer: boolean =
    getNodeInfoResponseData(nodeInfoResponse, NODE_INFO_KEYS.ENTITY_NAME) ===
    EntityNameEnum.CUSTOMER;

  const handleOpenModalBranchCreate = () => {
    setIsModalBranchCreateOpen(true);
  };

  const handleCloseModalBranchCreate = () => {
    setIsModalBranchCreateOpen(false);
  };

  const handleCloseModalSimpleSecurity = () => {
    setIsModalSimpleSecurityOpen(false);
    window.location.href = ROUTE.MERCHANT_RESUME(
      defaultTo(publicMerchantId, "")
    );
  };
  const handleCloseModalBranchCreatedSuccessful = () => {
    setIsModalBranchCreatedSuccessfulOpen(false);
    window.location.href = ROUTE.MERCHANT_RESUME(
      defaultTo(publicMerchantId, "")
    );
  };

  const handleRedirectModalBranchCreatedSuccessful = () => {
    window.location.href = ROUTE.USERS(
      get(branchCreated, "name", ""),
      get(branchCreated, "publicMerchantId", "")
    );
  };

  useEffect(() => {
    if (queryParamActiveClient && nodeInfoData) {
      setIsDisabledCreateBranchBtn(false);
    }
  }, [nodeInfoData]);

  const onRedirectBranchCreation = async () => {
    setIsDisabledCreateBranchBtn(true);
    const res = await axiosInstance.post(
      API_ROUTES.HIERARCHY_GET_FIRST_PARENT,
      { publicMerchantId }
    );

    saveCurrentLocation(publicMerchantId);
    history.push({
      pathname: routes.CREATE_NODE_BRANCH,
      search: `?publicMerchantId=${res.data.merchantId}`,
    });
  };

  useEffect(() => {
    const merchant_id = getNodeInfoResponseData(
      nodeInfoResponse,
      NODE_INFO_KEYS.MERCHAN_ID
    );

    if (merchant_id) {
      const general_info_name = getNodeInfoResponseData(
        nodeInfoResponse,
        NODE_INFO_KEYS.GENERAL_INFO_NAME,
        ""
      );

      isNodeCustomer
        ? dispatch(getUsers({ limitNumber: 15, publicMerchantId: merchant_id }))
        : dispatch(getUsers({ limitNumber: 5, publicMerchantId: merchant_id }));

      setLink(
        `${RedirectRoutesEnum.CONSOLE_USER_USERS}?publicMerchantId=${merchant_id}&merchantName=${general_info_name}&console=3&hideSideBar=true`
      );
      setLinkCustomer(
        `${RedirectRoutesEnum.CONSOLE_USER_USERS}user/?publicMerchantId=${merchant_id}&merchantName=${general_info_name}&console=3&hideSideBar=true`
      );
    }
  }, [isNodeBranch, isNodeCustomer]);

  const isFooterOptionsOpen = checkIsFooterOptionsOpen(
    nodeInfoResponse,
    queryParamMode
  );

  const handleVerifyDisabledUsersButton = () =>
    !(
      isEqual(
        get(nodeInfoResponse, "data.data.entityName"),
        EntityNameEnum.CUSTOMER
      ) &&
      !isEqual(statusMerchant, StatusMerchantEnum.INACTIVE) &&
      hasChildrens &&
      isDataComplete
    );

  const handleActionUsersBtn = () => {
    if (handleVerifyDisabledUsersButton()) return () => {};

    return history.push(
      `${routes.CONSOLE_USER}?publicMerchantId=${publicMerchantId}`
    );
  };

  useEffect(() => {
    if (!nodeInfoResponse.loading && userScrollParam)
      get(userSectionRef as unknown, "current", {})?.scrollIntoView();
  }, [nodeInfoResponse.loading]);

  useEffect(() => {
    if (!isEmpty(get(nodeInfoResponse, "data", [])))
      setRequiredConfigs(
        isRequiredDataMissing(
          get(nodeInfoResponse, "data.data", {}),
          get(nodeInfoResponse, "data.data.entityName")
        )
      );
  }, [nodeInfoResponse]);

  useEffect(() => {
    if (
      merchantSettingsData &&
      !canShowWallet &&
      hasCashOrTransferPayoutsProcessors(merchantSettingsData)
    ) {
      setCanShowWallet(true);
      isNodeBranch &&
        dispatch(getBalance({ merchantId: defaultTo(publicMerchantId, "") }));
    }
  }, [merchantSettingsData]);

  return {
    alertMessage,
    balanceResponse,
    boardMembers,
    canShowWallet,
    customerName,
    entityName: getNodeInfoResponseData(
      nodeInfoResponse,
      NODE_INFO_KEYS.ENTITY_NAME
    ),
    form,
    generalInfo: getNodeInfoResponseData(
      nodeInfoResponse,
      NODE_INFO_KEYS.GENERAL_INFO
    ),
    handlers: {
      handleActionUsersBtn,
      handleCloseModalBranchCreate,
      handleCloseModalBranchCreatedSuccessful,
      handleCloseModalSimpleSecurity,
      handleOnCloseModalBranchCreatedSuccessful: () => {},
      handleOpenModalBranchCreate,
      handleRedirectModalBranchCreatedSuccessful,
      handleSecondaryAction,
      handleValidateNameBranch,
      onRedirectBranchCreation,
    },
    hasChildrens,
    isActiveBranch: statusNode(StatusMerchantEnum.ACTIVE),
    isCentralizedBillingBranch,
    isCompanyAccordionOpen,
    isDataComplete,
    isDeveloperAccordionOpen,
    isDisabledCreateBranchBtn,
    isDisabledUserBtn: handleVerifyDisabledUsersButton(),
    isDiscountAccordionOpen,
    isFinancialAccordionOpen,
    isFooterOptionsOpen,
    isLoaderModalOpen,
    isLoading: nodeInfoResponse.loading,
    isModalBranchCreatedSuccessfulOpen,
    isModalBranchCreateOpen,
    isModalSimpleSecurityOpen,
    isNodeBranch,
    isNodeCustomer,
    isPendingUsers,
    isProcessAccordionOpen,
    isServiceAccordionOpen,
    isUserAccordionOpen,
    isWalletAccordionOpen,
    isWalletLoading: !isUndefined(merchantSettingsData),
    legalRepresentatives,
    link,
    linkCustomer,
    onClick,
    onClickCompanyAccordion,
    onClickDeveloperAccordion,
    onClickDiscountAccordion,
    onClickFinancialAccordion,
    onClickProcessAccordion,
    onClickServiceAccordion,
    onClickUserAccordion,
    onClickWalletAccordion,
    onRedirect,
    onRedirectWallet,
    queryParamMode,
    requiredConfigs,
    shareHolders,
    shouldRenderChargesBlock,
    shouldRenderDispersionBlock,
    typeAlert,
    userSectionRef,
    usersList,
  };
};
