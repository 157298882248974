import React from "react";
import { CardList } from "@kushki/connect-ui";
import { Box } from "@mui/material";
import { IElementModalComponentProps } from "../../../shared/interfaces/ModalContainer";

export const ElementModalComponent: React.FC<IElementModalComponentProps> = (
  props: IElementModalComponentProps
) => {
  return (
    <React.Fragment>
      {(props.isVisible || props.isLoading) && (
        <Box display="flex">
          <Box
            display="flex"
            flexDirection="column"
            marginRight="8px"
            width="100%"
          >
            <Box
              bgcolor="neutral.grey3"
              borderRadius="4px"
              marginBottom="16px"
              paddingBottom="24px"
            >
              <CardList
                items={props.body}
                orientation="horizontal"
                title={props.title}
                isLoading={props.isLoading}
              />
            </Box>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};
