import { ErrorMessageEnum } from "@shared/enum/errorMessagesEnum";

type Patterns = {
  alphanumeric: string;
  numeric: string;
};
export const PATTERNS: Patterns = {
  alphanumeric: "^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ ]+$",
  numeric: "^(\\d)*(\\.)?([0-9]{1,10})?$",
};
export const RULES = {
  alias: {
    pattern: {
      message: ErrorMessageEnum.ALPHANUMERIC,
      value: new RegExp(PATTERNS.alphanumeric),
    },
    required: {
      message: ErrorMessageEnum.REQUIRED,
      value: true,
    },
  },
  field: {
    required: {
      message: ErrorMessageEnum.REQUIRED,
      value: true,
    },
  },
  frequency: {
    required: {
      message: ErrorMessageEnum.REQUIRED,
      value: true,
    },
  },
  operator: {
    required: {
      message: ErrorMessageEnum.REQUIRED,
      value: true,
    },
  },
  value: {
    required: {
      message: ErrorMessageEnum.REQUIRED,
      value: true,
    },
  },
};
