import { IModalContainerBranch } from "./DataTableContainerBranch.interface";
import { useModalContainer } from "./state/useModalContainer";
import { ModalDetail } from "@kushki/connect-ui";
import React from "react";
import { ContentModalDetail } from "../ContentModalDetail/ContentModalDetail";
import { ElementModalComponent } from "./ElementModalComponent/ElementModalComponent";
import { ModalDetailHeader } from "../ModalDetailHeader/ModalDetailHeaderBranch";
import { Conditions } from "../ConditionsModal/Conditions";

export const ModalContainer: React.FC<IModalContainerBranch> = () => {
  const { onChangeModal, header, isOpenModal, data, isLoadingOneRule } =
    useModalContainer();

  return (
    <React.Fragment>
      <ModalDetail
        header={header}
        onClose={onChangeModal}
        variant={"content"}
        isOpen={isOpenModal}
        isLoading={isLoadingOneRule}
      >
        {!isLoadingOneRule && (
          <ContentModalDetail>
            <ModalDetailHeader {...data.ruleModalHeader} />
            <ElementModalComponent {...data.ruleSetting} isVisible={true} />
            <Conditions />
          </ContentModalDetail>
        )}
      </ModalDetail>
    </React.Fragment>
  );
};
