import {
  IUseGenericTableProps,
  IUseGenericTableState,
  NumberMapping,
} from "@components/GenericTable/state/useGenericTable.interfaces";
import { cloneDeep, get, isEmpty } from "lodash";
import {
  HeaderCellProps,
  ITableRowProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { useEffect, useState } from "react";
import { ISortClick } from "@shared/interfaces/ISortClick";

export const useGenericTable = <T = unknown, U = unknown>({
  rows,
  headers,
  isLoading,
  sortCatalog,
  tableColumns,
}: IUseGenericTableProps<T, U>): IUseGenericTableState => {
  const [handledRows, setHandledRows] = useState<ITableRowProps[]>(rows);
  const [handledHeaders, setHandledHeaders] = useState<HeaderCellProps[]>(
    get(headers, "cells", [])
  );
  const [isLoadingTable, setIsLoadingTable] = useState<boolean | undefined>(
    isLoading
  );

  const [sortClick, setSortClick] = useState<ISortClick>({
    field: "",
    numClick: 0,
  });

  const sortOptions: NumberMapping<number> = {
    [0]: 1,
    [1]: 1,
    [2]: -1,
  };

  const dynamicSort = <T>(
    item1: T,
    item2: T,
    property: unknown,
    sort: number
  ) => {
    const sortOrder: number = sortOptions[sort];

    const result: number =
      get(item1, property as string, "") < get(item2, property as string, "")
        ? -1
        : get(item1, property as string, "") >
          get(item2, property as string, "")
        ? 1
        : 0;

    return result * sortOrder;
  };

  const sortObjectArray = <T>(
    array: T[],
    property: unknown,
    click: number
  ): T[] =>
    array.sort((item1, item2) => dynamicSort(item1, item2, property, click));

  const handleCountClick = (field: string): void => {
    const sortField: string = get(sortClick, "field", "");
    const numClick: number = get(sortClick, "numClick", 0);

    if (sortField !== field && sortField !== "") {
      setSortClick({ field, numClick: 1 });

      return;
    }

    setSortClick({ field, numClick: numClick >= 2 ? 0 : numClick + 1 });
  };

  useEffect(() => {
    setHandledHeaders(
      tableColumns(
        handleCountClick,
        { field: get(sortClick, "field", "") },
        sortClick.numClick
      )
    );
  }, [tableColumns]);

  useEffect(() => {
    const sortField: string = get(sortClick, "field", "");

    if (!sortCatalog) return;

    const cellDataField: unknown = sortCatalog[sortField];

    if (!isEmpty(sortField)) {
      setHandledHeaders(
        cloneDeep(
          tableColumns(
            handleCountClick,
            { field: sortField },
            sortClick.numClick
          )
        )
      );
      setHandledRows(
        cloneDeep(sortObjectArray(rows, cellDataField, sortClick.numClick))
      );
    }
  }, [sortClick]);

  useEffect(() => {
    setHandledRows(rows);
  }, [rows]);

  useEffect(() => {
    setIsLoadingTable(isLoading);
  }, [isLoading]);

  return {
    handleCountClick,
    handledHeaders,
    handledRows,
    isLoadingTable,
    sortObjectArray,
  };
};
