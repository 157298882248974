import { format } from "date-fns";
import { es } from "date-fns/locale";

export const getDate = (
  timeStamp: number,
  formatOutput: string = "yyyy-MM-dd",
  locale?: Locale
): string => format(timeStamp, formatOutput, { locale });

export const getNotificationDate = (timeStamp: number): string => {
  const formattedDate: string = getDate(timeStamp, "LLLL yyyy - HH:mm:ss", es);

  return `${getDate(timeStamp, "dd")} de ${
    formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
  }`;
};

export const formatDDMMYYYY = (date: string) =>
  format(new Date(date), "dd/MM/yyyy");

export const formatHourMinutesSeconds = (date: string) =>
  format(new Date(date), "HH:mm:ss");
