import { makeStyles } from "@material-ui/core/styles";

export const useStylesConfirmModal = makeStyles(() => ({
  confirmModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  confirmModalContainer: {
    padding: "24px 32px",
    background: "#FFFFFF",
    boxShadow:
      "0 4px 6px rgba(50, 50, 93, 0.12), 0 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    outline: "none",
    width: "35%",
  },
  textStrong: {
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "28px",
    color: "#023365",
    paddingBottom: "24px",
  },
  confirmModalBody: {
    paddingTop: "10px",
    paddingBottom: "23px",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "18px",
    color: "#6D7781",
    wordWrap: "break-word",
  },
  confirmModalButtonGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  downloadButton: {
    fontSize: "16px",
    marginTop: "16px",
    height: "36px",
  },
}));
