import React, { FC } from "react";
import TicketOrderCard from "../../components/TicketOrderCard/TicketOrderCard";
import { ticketOrderStyles as styles } from "./TicketOrder.styles";
import { Button } from "@mui/material";
import {
  BUTTON_ACCEPT,
  BUTTON_ORDER,
  TITLE_LABEL,
} from "../../shared/constants/labels/main_labels";
import { RouteEnum } from "../../shared/infrastructure/routes";
import { useNavigate } from "react-router";
import { HeaderCard } from "../../components/HeaderCard/HeaderCard";
import { IconPlus } from "@kushki/connect-ui";
import {
  NAVIGATION_CHECKOUT,
  NAVIGATION_INDEX,
} from "../../shared/constants/labels/recharge_wallet_labels";
import FooterButtonsWrapper from "../../components/FooterButtonsWrapper/FooterButtonsWrapper";
import FormWrapper from "../../components/FormWrapper/FormWrapper";
import { CountryEnum } from "../../shared/enums/CountryEnum";
import { useGetMerchantCountry } from "../../store/hooks/hooks";

const TicketOrder: FC = () => {
  const navigate = useNavigate();
  const country = useGetMerchantCountry();

  return (
    <>
      <HeaderCard
        title={TITLE_LABEL}
        navItems={[
          {
            id: "1",
            text: NAVIGATION_INDEX,
            url: RouteEnum.CHECKOUT,
          },
          {
            id: "2",
            text: NAVIGATION_CHECKOUT,
            url: "",
          },
        ]}
      />
      {country === CountryEnum.MEXICO && (
        <FormWrapper>
          <TicketOrderCard />
        </FormWrapper>
      )}
      <FooterButtonsWrapper>
        <>
          <Button
            size="small"
            sx={styles.orderButton}
            startIcon={<IconPlus />}
            onClick={() => navigate(RouteEnum.CHECKOUT, { replace: true })}
          >
            {BUTTON_ORDER}
          </Button>
          <Button
            size="small"
            sx={styles.acceptButton}
            onClick={() => navigate(RouteEnum.DASHBOARD)}
          >
            {BUTTON_ACCEPT}
          </Button>
        </>
      </FooterButtonsWrapper>
    </>
  );
};

export { TicketOrder };
