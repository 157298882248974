import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

export const LegalContainerSkeletonStyles = createNamedStyles({
  container: {
    width: "100%",
  },
  subTitle: {
    fontSize: "40px",
    width: "30%",
  },
  subTitle2: {
    fontSize: "26px",
    width: "40%",
  },
  subTitle3: {
    fontSize: "22px",
    width: "40%",
  },
  subTitle4: {
    fontSize: "18px",
    width: "60%",
  },
  contentBox: {
    height: "13vh",
    marginBottom: "1.5em",
    marginTop: "1.5em",
    padding: "16px",
  },
  contentLegend: {
    margin: "1em, 0",
    width: " 100%",
  },
  persons: {
    height: "100%",
    transform: "scale(1, 1.5)",
  },
  contentBall: {
    alignContent: "center",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginTop: "1.5em",
    width: "12.5%",
  },
  balls: {
    borderRadius: "100% !important",
    height: "24px",
    transform: "scale(1, 1)",
    width: "24px",
  },
});
