export const INIT_TITLE =
  "¿Estás seguro que deseas salir de la creación de customer?";
export const INIT_SUBTITLE =
  "Al ejecutar esta acción la información que has ingresado hasta ahora se perderá y la creación del customer no será posible.";
export const SUBTITLE_SECTION_CREATE_NOTE =
  "Si aún no has guardado los datos registrados hasta ahora ten en cuenta que se perderán de forma inmediata. De lo contrario, podrás ingresar cuando lo desees para continuar con el llenado del formulario. El customer solo estará activo hasta que ingreses toda la información.";
export const CREATE_NOTE = "createNode";
export const CANCEL_BUTTON_TEXT = "No, cancelar";
export const lEAVE_BUTTON_TEXT = "Sí, abandonar";
export const EXIT_BUTTON_TEXT = "Sí, salir";
export const TITLE_BRANCHES =
  "¿Estás seguro de que deseas salir de la creación de los branches?";
export const SUBTITLE_BRANCHES =
  "Al ejecutar esta acción la información que has ingresado hasta ahora se perderá y la creación del (los) branch(es) no será posible";
export const TITLE_MERCHANT =
  "¿Estás seguro de que deseas salir del resumen del merchant?";
export const CANCEL_BUTTON_TEXT_BRANCH = "Cancelar";
export const EXIT_BUTTON_TEXT_BRANCH = "Salir";

export const MODAL_TITLE = (sectionName: string): string =>
  `¿Deseas abandonar el formulario de ${sectionName.toLowerCase()}?`;
