import React from "react";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export interface LoadingDialogProps {
  open: boolean;
}

const useStyles = makeStyles({
  title: {
    color: "#023365",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "140%",
  },
  subtitle: {
    color: "#293036",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "140%",
  },
});

export const LoadingDialog: React.FC<LoadingDialogProps> = (
  props: LoadingDialogProps
) => {
  const classes = useStyles();

  return (
    <Dialog open={props.open} fullWidth>
      <DialogContent>
        <DialogContentText>
          <Grid container>
            <Grid item xs={2}>
              <CircularProgress size={60} thickness={5} />
            </Grid>
            <Grid item xs={10} container alignItems="center" direction="row">
              <Grid>
                <Typography className={classes.title}>
                  Estamos configurando tu regla de negocio
                </Typography>
              </Grid>
              <Grid>
                <Typography className={classes.subtitle}>
                  Por favor, espera...
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
