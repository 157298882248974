import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const RuleDetailStyles = createNamedStyles({
  iconBtn: {
    paddingLeft: "5%",
    color: "#023365",
  },
  icon: {
    marginRight: "15px",
  },
  subtitle: {
    color: "#6D7781",
  },
});
