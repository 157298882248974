import { Box, Chip, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useDropDown } from "./state/useDropDown";
import { IDropDownProps } from "./DropDown.interfaces";
import { IconChevronDown } from "@kushki/connect-ui";
import { dropDownStyles as styles } from "./DropDown.styles";
import { ModalContentButtonTypeEnum } from "@kushki/connect-ui/dist/Components/Molecules/Modal/Content/enums";

const DropDrown: React.FC<IDropDownProps> = ({
  items,
  values,
}: IDropDownProps) => {
  const { open, handleClose, handleClick, anchor, getValueName } = useDropDown({
    values,
  });

  return (
    <Box sx={styles.container}>
      <Chip
        label={getValueName(items[0]).toUpperCase()}
        onClick={handleClick}
        icon={
          <IconChevronDown
            color={ModalContentButtonTypeEnum.PRIMARY}
            fontSize={"small"}
          />
        }
        sx={styles.chip}
      />
      <Menu
        id="values-menu"
        anchorEl={anchor}
        open={open}
        onClose={handleClose}
      >
        {items.map((item: string, index: number) => (
          <MenuItem key={index} onClick={handleClose} sx={styles.menuItems}>
            {getValueName(item).toUpperCase()}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default DropDrown;
