import React from "react";
import { Box, Chip, Skeleton, Tooltip, Typography } from "@mui/material";
import { CellTagStyles } from "./TagCell.styles";
import { TOOL_TIP_TITLE } from "../../../shared/constants/labels/webhook_form_labels";

interface ITagsCell {
  tags: string[];
}

export const TagsCell = (props: ITagsCell) => {
  const { tags } = props;

  const buildMultipleTagChips = () => {
    const firstTag: string = tags[0];
    const toolTipText: string = tags.slice(1).join(", ");

    return (
      <>
        <Chip
          key={`${firstTag}-tag`}
          label={firstTag}
          size="small"
          color="primary"
        />
        <Tooltip
          title={
            <>
              <Typography sx={CellTagStyles.toolTipTitle}>
                {TOOL_TIP_TITLE}
              </Typography>
              <Typography sx={CellTagStyles.toolTipText}>
                {toolTipText}
              </Typography>
            </>
          }
          placement="right"
          arrow
        >
          <Chip
            key={`${toolTipText}-tag`}
            label="..."
            size="small"
            color="primary"
          />
        </Tooltip>
      </>
    );
  };

  return (
    <Box sx={CellTagStyles.box}>
      {tags.length > 1
        ? buildMultipleTagChips()
        : tags.map((tag, index) => (
            <Chip key={index} label={tag} size="small" color="primary" />
          ))}
    </Box>
  );
};

export const TagsCellSkeleton = () => {
  return (
    <Skeleton
      data-testid="tags-cell-skeleton"
      variant="rectangular"
      animation="wave"
      sx={CellTagStyles.skeleton}
    />
  );
};
