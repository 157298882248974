import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { get } from "lodash";
import { StatusSteps } from "../../../../types/remote/semaphore_data";
import {
  StatusSemaphore,
  StepsEnum,
} from "../../../shared/infrastructure/constants/CreateMerchantConstants";
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { updateStepStatus } from "../../../store/actionCreators";
import { Dispatch } from "redux";
import { WalletMessage } from "../../../shared/infrastructure/constants/WalletMessagesCatalog";
import { useWalletPendingStyles } from "./WalletPending.styles";
import { Edit } from "react-feather";
import { GridPaper } from "../../common/GridPaper/GridPaper";
import { WalletConfig } from "../../../../types/wallet_config";

export interface WalletPendingProps {
  status: StatusSteps;
  publicMerchantId: string;
  walletConfig: WalletConfig | [];
}

export const WalletPending: React.FC<Partial<WalletPendingProps>> = () => {
  const classes = useWalletPendingStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const {
    status,
    publicMerchantId,
    walletConfig,
  }: WalletPendingProps = useSelector((state: IAppState) => ({
    status: get(
      state,
      "semaphore.stepConfigRatesAndInvoice.statusWallet",
      StatusSemaphore.pending
    ),
    publicMerchantId: get(state, "merchantAffiliation.publicMerchantId", ""),
    walletConfig: get(state, "merchantAffiliation.walletConfig", []),
  }));

  const redirectConfiguration = (status: StatusSemaphore) => {
    if (publicMerchantId) {
      dispatch(
        updateStepStatus(
          StepsEnum.stepConfigRatesAndInvoice,
          status,
          publicMerchantId,
          "statusWallet"
        )
      );
    }
  };

  const omitWalletStep = () => {
    dispatch(
      updateStepStatus(
        StepsEnum.stepConfigRatesAndInvoice,
        StatusSemaphore.omitted,
        publicMerchantId,
        "statusWallet"
      )
    );
  };
  const validateAutomaticRecharge = (merchantId: string): boolean => {
    return (
      walletConfig.length > 0 &&
      get(walletConfig, "[0].automaticRecharge", false) &&
      merchantId === get(walletConfig, "[0].merchantId", "")
    );
  };

  const subtitle = () => (
    <Typography variant="subtitle2" gutterBottom color={"primary"}>
      <Box className={classes.text}>{WalletMessage.WalletNote}</Box>
      <Box className={classes.textBolder}>{WalletMessage.WalletNoteBolder}</Box>
    </Typography>
  );

  return status === StatusSemaphore.pending ? (
    <Box className={classes.box} bgcolor="background.default" py={3} mb={2}>
      <Typography variant="h5" gutterBottom>
        <Box className={classes.text}>{WalletMessage.FIRST_CONFIG}</Box>
        <Box className={classes.textBolder}>
          {WalletMessage.WALLET_AUTOMATIC_RECHARGE}
        </Box>
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.containedButton}
        size="medium"
        onClick={() => redirectConfiguration(StatusSemaphore.pending)}
      >
        {WalletMessage.Configuration}
      </Button>
      <Button
        onClick={omitWalletStep}
        variant="text"
        className={classes.button}
        size="medium"
      >
        {WalletMessage.Omit}
      </Button>
      {subtitle()}
    </Box>
  ) : status === StatusSemaphore.omitted ? (
    <Box className={classes.box} bgcolor="background.default" py={3} mb={2}>
      <Typography variant="h5" gutterBottom>
        <Box className={classes.text}>Haz </Box>
        <Box className={classes.textBolder}>omitido </Box>
        <Box className={classes.text}>
          {WalletMessage.WALLET_AUTOMATIC_RECHARGE}
        </Box>
      </Typography>
      <Button
        variant="text"
        className={classes.buttonOmitted}
        size="medium"
        id="iconOmmit"
        onClick={() => redirectConfiguration(StatusSemaphore.pending)}
      >
        {WalletMessage.Configuration}
      </Button>
      {subtitle()}
    </Box>
  ) : status === StatusSemaphore.complete &&
    validateAutomaticRecharge(publicMerchantId) ? (
    <GridPaper direction={"row"}>
      <Grid item xs={12}>
        <Typography
          variant={"h6"}
          color={"textPrimary"}
          className={classes.textBolder}
        >
          {WalletMessage.TITLE_RESUME}
        </Typography>
        <IconButton
          onClick={() => redirectConfiguration(StatusSemaphore.complete)}
          size={"small"}
          className={classes.buttonEdit}
          id="iconButtonEdit"
        >
          <Edit />
        </IconButton>
      </Grid>
      <Grid item xs={12} className={classes.subtitleBox}>
        <Typography
          variant={"body1"}
          color={"textPrimary"}
          className={classes.textSubtitle}
        >
          {WalletMessage.WalletNote + WalletMessage.WalletNoteBolder}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant={"body2"} color={"textSecondary"}>
          Tipo de recarga:
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography variant={"body2"} color={"textPrimary"}>
          {get(walletConfig, "[0].type", "") === "total"
            ? WalletMessage.TOTAL
            : WalletMessage.PARTIAL}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant={"body2"} color={"textSecondary"}>
          Porcentaje (%) seleccionado:
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Chip
          label={get(walletConfig, "[0].rechargePercentage", "0") + "%"}
          className={classes.chip}
          size={"small"}
        />
      </Grid>
    </GridPaper>
  ) : status === StatusSemaphore.complete &&
    !validateAutomaticRecharge(publicMerchantId) ? (
    <Box
      className={classes.boxSecondConfig}
      bgcolor="background.default"
      py={3}
      mb={2}
    >
      <Grid item className={classes.grid}>
        <Typography variant="h5" gutterBottom>
          <Box className={classes.text}>Aún no has configurado la </Box>
          <Box className={classes.textBolder}>
            {WalletMessage.WALLET_AUTOMATIC_RECHARGE}
          </Box>
        </Typography>
      </Grid>
      <Grid item className={classes.grid}>
        <Button
          variant="contained"
          color="primary"
          className={classes.containedButton}
          size="medium"
          id="walletButton"
          onClick={() => redirectConfiguration(StatusSemaphore.pending)}
        >
          {WalletMessage.WalletButton}
        </Button>
      </Grid>
      <Grid item className={classes.grid}>
        {subtitle()}
      </Grid>
    </Box>
  ) : null;
};
