import { IStyles } from "../../shared/interfaces/Styles.interfaces";

export const CardInformationStyles: IStyles = {
  contentDescription: {
    margin: 0,
    width: "100%",
  },
  descriptionLabelVertical: {
    color: "#6D7781",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "144%",
    width: "40%",
  },

  descriptionTextVertical: {
    color: "#293036",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "144%",
    width: "60%",
  },

  descriptionVertical: {
    display: "flex",
    flexWrap: "nowrap",
    padding: "0 0 8px 0",
    gap: "12px",
    alignItems: "baseline",
  },

  descriptionVerticalColumn: {
    display: "flex",
    flexWrap: "nowrap",
    padding: "0 0 8px 0",
    gap: "12px",
    alignItems: "baseline",
    flexDirection: "column",
  },
  itemsText: {
    display: "flex",
    flexDirection: "column",
    margin: 0,
    padding: 0,
    width: "100%",
  },

  mainBox: {
    bgcolor: "neutral.grey3",
    mb: "16px",
    padding: "15px 0px 10px 24px",
    marginBottom: "10px",
  },

  title: {
    color: "#293036",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "120%",
    letterSpacing: "0.0015em",
    margin: 0,
  },

  titleTaxes: {
    color: "#293036",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "120%",
    letterSpacing: "0.0015em",
    margin: 0,
    mb: "24px",
  },

  tax: {
    color: "#6D7781",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "144%",
    letterSpacing: "0.0015em",
    margin: 0,
    pb: "16px",
    whiteSpace: "pre",
  },
  iconColor: {
    color: "#023365",
    "&:hover": {
      backgroundColor: "#ECF0F1",
    },
    "&:active": {
      backgroundColor: "#F7F9F9",
    },
    position: "absolute",
    top: 0,
    right: 0,
  },

  boxCopy: {
    backgroundColor: "#FFFAFA",
    position: "relative",
  },
};
