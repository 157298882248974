import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      padding: "0px",
      margin: "0px",
      boxShadow: "rgba(17, 17, 26, 0.1) 0px 0px 0px",
    },
    cardContent: {
      padding: "0px",
      margin: "0px",
      backgroundColor: "#f0f4f9",
    },
    container: {
      padding: "0px",
      margin: "0px",
    },
  })
);
export default useStyles;
