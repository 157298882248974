import * as React from "react";
import { Grid, TextField, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { RangeDatePicker } from "@kushki/connect-ui";
import { searchTextStyles as styles } from "./SearchText.styles";
import { PlaceholderFilterCatalogs } from "../../shared/catalogs/CatalogTextByEntityName";
import { get } from "lodash";
import { TimeEnum } from "../../shared/enums/TimeEnum";

export interface searchTextProps {
  defaultFinishDate: string;
  defaultStartDate: string;
  minDate: string;
  entityName: string;
  onEnter: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onDateSelected: (startDate: string, finishDate: string) => void;
  searchMessage: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  value?: string;
}

export const SearchText: React.FC<searchTextProps> = (
  props: searchTextProps
) => {
  return (
    <Grid container item xs={12} md={12} sx={{ alignItems: "center" }}>
      <Grid item xs={6}>
        <Tooltip
          title={`${get(PlaceholderFilterCatalogs, "tooltipSearchText", "")}`}
        >
          <TextField
            placeholder={`${get(PlaceholderFilterCatalogs, "searchText", "")}`}
            sx={styles.searchText}
            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) =>
              props.onEnter(e)
            }
            value={props.value}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => props.onChange(e)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {" "}
                  <SearchIcon color={"primary"} />{" "}
                </InputAdornment>
              ),
            }}
            size="medium"
          />
        </Tooltip>
      </Grid>
      <Grid item xs={6} sx={styles.rangeDate}>
        <RangeDatePicker
          dateFormat={TimeEnum.timeDateFormat}
          onDateSelect={props.onDateSelected}
          placeholder={`${get(PlaceholderFilterCatalogs, "rangeDateText", "")}`}
          disabledFutureDate={true}
          isOpen={false}
          isFilled={true}
          disabledOldDate={false}
          defaultStartDate={props.defaultStartDate}
          defaultFinishDate={props.defaultFinishDate}
          showHour={true}
          initialHour={TimeEnum.defaultInitialTime}
          finalHour={TimeEnum.defaultFinalTime}
          minDate={new Date(props.minDate)}
        />
      </Grid>
    </Grid>
  );
};

export default SearchText;
