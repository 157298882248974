import { FC } from "react";
import { CellCheckItem } from "../../TableCells/CellCheck/CellCheck";

import { ITableCellProps, ITableRowProps } from "./interfaces";
import { CellTextCopy } from "../../TableCells/CellTextCopy/CellTextCopy";
import { CellFlagItem } from "../../TableCells/CellFlag/CellFlag";
import { CellTitleItem } from "../../TableCells/CellTitle/CellTitle";
import { CellChipStatus } from "../../TableCells/CellChip/CellChipStatus";
import { defaultTo, get, isEmpty } from "lodash";
import { CellActionMenu } from "../../TableCells/CellActionMenu/CellActionMenu";
import { IItemList } from "../../TableCells/CellActionMenu/CellActionMenu.interfaces";
import { MerchantNodeData } from "../../../../types/search_merchant_response";
import { format, parseISO } from "date-fns";
import { generateUrl } from "../../../shared/utils/urlUtils";

enum TableBodyCellEnum {
  CHECK = "CHECK",
  TITLE = "TITLE",
  TEXT_COPY = "TEXT_COPY",
  TAG = "TAG",
  FLAG = "FLAG",
  ACTION_MENU = "ACTION_MENU",
}

interface IConfig {
  //Valor por defecto en caso que no se obtenga un valor del objeto con el path.
  defaultValue?: any;
  //Path para obtener el valor del objeto.
  path: string;
  //Text que se mostrara en la tabla, se agrega $var para mostrar la posición donde estara el valor obtenido del objeto.
  text: string;
  isDate?: boolean;
  hasUrl?: boolean;
}

export interface IConfigCellBasic {
  configMain?: IConfig;
  configSecondary?: IConfig;
  type: TableBodyCellEnum;
  align?: "inherit" | "left" | "center" | "right" | "justify";
}

interface IConfigCellCheck extends IConfigCellBasic {
  isChecked: boolean;
}
interface IConfigCellFlag extends IConfigCellBasic {
  isCodeCountry?: boolean;
}

export type IConfigCells =
  | IConfigCellBasic
  | IConfigCellCheck
  | IConfigCellFlag;

export interface IAdditionalProps {
  handleGetRowData?: () => MerchantNodeData;
}

const CELL_COMPONENTS_BY_TABLE_ENUM: Record<TableBodyCellEnum, FC<any>> = {
  [TableBodyCellEnum.CHECK]: CellCheckItem,
  [TableBodyCellEnum.TITLE]: CellTitleItem,
  [TableBodyCellEnum.TEXT_COPY]: CellTextCopy,
  [TableBodyCellEnum.FLAG]: CellFlagItem,
  [TableBodyCellEnum.TAG]: CellChipStatus,
  [TableBodyCellEnum.ACTION_MENU]: CellActionMenu,
};

const formatDate = (
  dateString: string,
  formatString: string = "dd/MM/yyyy"
): string => {
  let formattedDate: string = "";

  try {
    formattedDate = format(parseISO(dateString), formatString);
  } catch (_) {}

  return formattedDate;
};

function generateText<T = object>(
  obj: T,
  text: string = "",
  path: string,
  defaultValue?: string,
  isDate: boolean = false
): string {
  const required_value = get(obj, path, defaultTo(defaultValue, ""));

  if (isDate) {
    const formattedDate: string = formatDate(required_value);

    return text.replace("$var", formattedDate);
  }

  return text.replace("$var", required_value);
}

export function builderRows<T = object>(
  data: T[],
  configRows: IConfigCells[],
  entityName: string,
  menuAction?: IItemList[],
  page: string = ""
): ITableRowProps<T>[] {
  const rows_props: ITableRowProps<T>[] = [];
  let table_cells: ITableCellProps[];
  let cell: ITableCellProps;

  data.map((obj: T, index: number) => {
    table_cells = [];
    configRows.map((config: IConfigCells) => {
      switch (config.type) {
        case TableBodyCellEnum.CHECK:
          cell = {
            props: {
              align: get(config, "align"),
              id: new Date().getTime().toString(),
              isChecked: false,
            },
            type: TableBodyCellEnum.CHECK,
          };
          break;
        case TableBodyCellEnum.TITLE:
          cell = {
            props: {
              align: get(config, "align"),
              line1: generateText<T>(
                obj,
                get(config, "configMain.text"),
                get(config, "configMain.path"),
                get(config, "configMain.defaultValue"),
                get(config, "configMain.isDate", false)
              ),
              line2: generateText<T>(
                obj,
                get(config, "configSecondary.text"),
                get(config, "configSecondary.path"),
                get(config, "configSecondary.defaultValue"),
                get(config, "configSecondary.isDate", false)
              ),
              type: !isEmpty(get(config, "configSecondary.path"))
                ? "twoLines"
                : "oneLine",
              url: generateUrl(obj, get(config, "configMain.hasUrl")),
            },
            type: TableBodyCellEnum.TITLE,
          };
          break;
        case TableBodyCellEnum.TEXT_COPY:
          const info = generateText<T>(
            obj,
            get(config, "configMain.text"),
            get(config, "configMain.path"),
            get(config, "configMain.defaultValue")
          );
          const subtitleValue: string = generateText<T>(
            obj,
            get(config, "configSecondary.text"),
            get(config, "configSecondary.path"),
            get(config, "configSecondary.defaultValue")
          );
          const type: "oneLine" | "twoLines" = !isEmpty(
            get(config, "configSecondary.path")
          )
            ? "twoLines"
            : "oneLine";

          cell = {
            props: {
              align: get(config, "align"),
              subtitle: subtitleValue,
              textToCopy: info,
              title: info,
              type: type,
            },
            type: TableBodyCellEnum.TEXT_COPY,
          };
          break;
        case TableBodyCellEnum.FLAG:
          cell = {
            props: {
              align: get(config, "align"),
              country: generateText<T>(
                obj,
                get(config, "configMain.text"),
                get(config, "configMain.path"),
                get(config, "configMain.defaultValue")
              ),
              isCodeCountry: get(config, "isCodeCountry", false),
              type: "twolines",
            },
            type: TableBodyCellEnum.FLAG,
          };
          break;
        case TableBodyCellEnum.TAG:
          cell = {
            props: {
              align: get(config, "align"),
              text: generateText<T>(
                obj,
                get(config, "configMain.text"),
                get(config, "configMain.path"),
                get(config, "configMain.defaultValue")
              ),
              textTooltipMain: generateText<T>(
                obj,
                get(config, "configSecondary.text"),
                get(config, "configSecondary.path"),
                get(config, "configSecondary.defaultValue")
              ),
            },
            type: TableBodyCellEnum.TAG,
          };
          break;
        case TableBodyCellEnum.ACTION_MENU:
          cell = {
            props: {
              actions: defaultTo(menuAction, []),
              configsCompleted: get(obj, "configs_completed", false),
              entityName,
              status: generateText<T>(
                obj,
                get(config, "configMain.text"),
                get(config, "configMain.path"),
                get(config, "configMain.defaultValue")
              ),
            },
            type: TableBodyCellEnum.ACTION_MENU,
          };
          break;
      }
      table_cells.push(cell);
    });
    rows_props.push({
      cells: table_cells,
      id: index.toString().concat(`.${page}`),
      info: obj,
    });
  });

  return rows_props;
}

export { TableBodyCellEnum, CELL_COMPONENTS_BY_TABLE_ENUM };
