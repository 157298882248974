import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  cardEntity: {
    background: "#FFFFFF",
    border: "1px solid #E2E8F0",
    borderLeft: "8px solid #E2E8F0",
    borderRadius: "8px",
    boxShadow:
      "0px 8px 17px rgba(106, 167, 197, 0.15), 0px 1.6px 2.7625px rgba(106, 167, 197, 0.075)",
    padding: "16px",
    width: "410px",
  },
  cardEntitySelected: {
    background: "#FFFFFF",
    border: "1px solid #4498EE",
    borderLeft: "8px solid #4498EE",
    borderRadius: "8px",
    boxShadow:
      "0px 8px 17px rgba(106, 167, 197, 0.15), 0px 1.6px 2.7625px rgba(106, 167, 197, 0.075)",
    padding: "16px",
    width: "410px",
  },
  closeButton: {
    alignItems: "center",
    background: "#FFFFFF",
    border: "1px solid #FFFFFF",
    borderRadius: "48px",
    boxShadow:
      "0px 10px 80px rgba(106, 167, 197, 0.1), 0px 5.0625px 34.875px rgba(106, 167, 197, 0.05), 0px 2px 13px rgba(106, 167, 197, 0.03), 0px 0.4375px 4.625px rgba(106, 167, 197, 0.02)",
    cursor: "pointer",
    display: "flex",
    height: "36px",
    justifyContent: "center",
    width: "36px",
  },
  dialogContent: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  link: {
    color: "#4498EE",
    fontSize: "13px",
    fontWeight: 400,
    letterSpacing: "0.01em",
    lineHeight: "120%",
  },
  paper: { minWidth: "500px" },
  primaryTitle: {
    color: "#023365",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "140%",
  },
  primaryTitle2: {
    "&:hover": {
      color: "#4498EE",
      cursor: "pointer",
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "140%",
    },
    color: "#023365",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "140%",
  },
  skeletonBranch: {
    height: 265,
    marginTop: "15px",
    width: 410,
  },
  skeletonCustomer: {
    height: 114,
    marginTop: "15px",
    width: 410,
  },
  skeletonOwner: {
    height: 78,
    width: 410,
  },
  subtitle: {
    color: "#023365",
    fontSize: "13px",
    fontWeight: 400,
    letterSpacing: "0.01em",
    lineHeight: "120%",
    textTransform: "uppercase",
  },
  text: {
    color: "#293036",
    fontSize: "13px",
    fontWeight: 400,
    letterSpacing: "0.01em",
    lineHeight: "120%",
  },
  title: {
    color: "#023365",
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "0.0025em",
    lineHeight: "150%",
  },
  titleBranch: {
    color: "#4498EE",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: 600,
    letterSpacing: "0.0025em",
    lineHeight: "150%",
  },
  titleModal: {
    color: "#023365",
    fontSize: "26px",
    fontWeight: 600,
    lineHeight: "120%",
  },
}));
