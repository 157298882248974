import { CountryEnum } from "../enums/CountryEnum";

export const formatCurrency = (currency: number) => {
  return Intl.NumberFormat(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(currency);
};

export const formatCurrencyWallet = (
  amount: number,
  country: string
): string => {
  const formattedAmount: string =
    country === CountryEnum.chile
      ? Intl.NumberFormat("es", {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
          style: "decimal",
          useGrouping: true,
        }).format(amount)
      : Intl.NumberFormat("en", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(amount);

  return Number(formattedAmount) !== 0 ? formattedAmount : "0";
};
