import { ISort } from "@kushki/connect-ui/dist/Shared/Utils/IconSortArrow/IconSortArrow.interfaces";
import { HeaderCellProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { HierarchyEnum } from "@shared/enum/HierarchyEnum";
import { CellHeaderAlignEnum } from "@kushki/connect-ui/dist/Components/Atoms/Table/CellHeader/enums";

export const enum HeaderTableTextColumn {
  OWNER_NAME = "Owner",
  NAME = "Nombre",
  CUSTOMER_ID = "Customer Id",
  CONSTITUTIONAL_COUNTRY = "País de constitución",
  BRANCH_ID = "Branch/Merchant Id",
  OPERATION_COUNTRY = "País de operación",
  CATEGORY = "Categoría",
}

export const enum SortCellPropertyColumn {
  OWNER_NAME = "ownerName",
  CUSTOMER_NAME = "customerName",
  BRANCH_NAME = "branchName",
  CUSTOMER_ID = "customerId",
  BRANCH_ID = "branchId",
  CONSTITUTIONAL_COUNTRY = "constitutionalCountry",
  OPERATION_COUNTRY = "operationCountry",
  CATEGORY = "category",
}

export const OWNER_HEADER_COLUMNS = (
  onClickSort: Function,
  actualSort: ISort,
  numClick: number
): HeaderCellProps[] => [
  {
    actualSort,
    align: CellHeaderAlignEnum.LEFT,
    field: SortCellPropertyColumn.OWNER_NAME,
    isSort: true,
    numClick,
    onClickSort,
    spacing: 0,
    text: HeaderTableTextColumn.OWNER_NAME,
    type: "default",
    width: 1000,
  },
];

export const CUSTOMER_HEADER_COLUMNS = (
  onClickSort: Function,
  actualSort: ISort,
  numClick: number
): HeaderCellProps[] => [
  {
    actualSort,
    align: CellHeaderAlignEnum.LEFT,
    field: SortCellPropertyColumn.CUSTOMER_NAME,
    isSort: true,
    numClick,
    onClickSort,
    spacing: 0,
    text: HeaderTableTextColumn.NAME,
    type: "default",
    width: 200,
  },
  {
    align: CellHeaderAlignEnum.CENTER,
    field: SortCellPropertyColumn.CUSTOMER_ID,
    spacing: 0,
    text: HeaderTableTextColumn.CUSTOMER_ID,
    type: "default",
    width: 200,
  },
  {
    align: CellHeaderAlignEnum.CENTER,
    field: SortCellPropertyColumn.CONSTITUTIONAL_COUNTRY,
    spacing: 0,
    text: HeaderTableTextColumn.CONSTITUTIONAL_COUNTRY,
    type: "default",
    width: 700,
  },
];

export const BRANCH_HEADER_COLUMNS = (
  onClickSort: Function,
  actualSort: ISort,
  numClick: number
): HeaderCellProps[] => [
  {
    actualSort,
    align: CellHeaderAlignEnum.LEFT,
    field: SortCellPropertyColumn.BRANCH_NAME,
    isSort: true,
    numClick,
    onClickSort,
    spacing: 0,
    text: HeaderTableTextColumn.NAME,
    type: "default",
    width: 200,
  },
  {
    align: CellHeaderAlignEnum.LEFT,
    field: SortCellPropertyColumn.BRANCH_ID,
    spacing: 0,
    text: HeaderTableTextColumn.BRANCH_ID,
    type: "default",
    width: 200,
  },
  {
    align: CellHeaderAlignEnum.CENTER,
    field: SortCellPropertyColumn.CONSTITUTIONAL_COUNTRY,
    spacing: 0,
    text: HeaderTableTextColumn.CONSTITUTIONAL_COUNTRY,
    type: "default",
    width: 200,
  },
  {
    align: CellHeaderAlignEnum.CENTER,
    field: SortCellPropertyColumn.OPERATION_COUNTRY,
    spacing: 0,
    text: HeaderTableTextColumn.OPERATION_COUNTRY,
    type: "default",
    width: 200,
  },
  {
    align: CellHeaderAlignEnum.LEFT,
    field: SortCellPropertyColumn.CATEGORY,
    spacing: 0,
    text: HeaderTableTextColumn.CATEGORY,
    type: "default",
    width: 200,
  },
];

export const enum SortCellProp {
  OWNER_NAME = "cells[0].props.line1",
  CUSTOMER_NAME = "cells[0].props.line1",
  BRANCH_NAME = "cells[0].props.line1",
}

export const NODE_SELECTION_SORT_CATALOG = {
  [SortCellPropertyColumn.OWNER_NAME]: SortCellProp.OWNER_NAME,
  [SortCellPropertyColumn.CUSTOMER_NAME]: SortCellProp.CUSTOMER_NAME,
  [SortCellPropertyColumn.BRANCH_NAME]: SortCellProp.BRANCH_NAME,
};

export const TABLE_COLUMNS = {
  [HierarchyEnum.OWNER]: OWNER_HEADER_COLUMNS,
  [HierarchyEnum.CUSTOMER]: CUSTOMER_HEADER_COLUMNS,
  [HierarchyEnum.BRANCH]: BRANCH_HEADER_COLUMNS,
};

export const TABLE_FOOTER_TEXT: string = "Registros por página";

export const TABLE_ITEMS_PER_PAGE: number[] = [10, 20, 50];
