import { ICellTextStyleComponent } from "./CellText.interfaces";
import { FC } from "react";
import { Box, Typography } from "@mui/material";
import * as React from "react";

export const CellTextItem: FC<ICellTextStyleComponent> = ({
  text,
  styles,
  align,
}: ICellTextStyleComponent) => {
  return (
    <Box alignItems={align}>
      <Typography sx={styles.textStyle}>{text}</Typography>
    </Box>
  );
};
