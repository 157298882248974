export enum TransactionEnum {
  HEADER_TITLE = "Listado de Branches",
  CREATE_BRANCH = "Crear Branch",
  ADD_TRANSACTIONS = "Agrega Transacciones",
  SEARCH_MESSAGE = "Buscar por número de ticket",
  MESSAGE_TOOLTIP = "Las acciones masivas están deshabilitadas para branches con estado (Pendiente)",
  NO_DATA = "Aún no tienes información registrada",
  BUTTON_DOWNLOAD_TITLE = "Descargar",
  ACTIONS_BUTTON_TITTLE = "Acciones",
  SEARCH_DATE_MESSAGE = "Buscar por fecha",
}

export enum StatusEnumMonth {
  Enero = "ene",
  Febrero = "feb",
  Marzo = "mar",
  Abril = "abr",
  Mayo = "may",
  Junio = "jun",
  Julio = "jul",
  Agosto = "ago",
  Septiembre = "sep",
  Octubre = "oct",
  Noviembre = "nov",
  Diciembre = "dic",
}

export const CatalogTranslateMonth: Record<StatusEnumMonth, string> = {
  [StatusEnumMonth.Enero]: "01",
  [StatusEnumMonth.Febrero]: "02",
  [StatusEnumMonth.Marzo]: "03",
  [StatusEnumMonth.Abril]: "04",
  [StatusEnumMonth.Mayo]: "05",
  [StatusEnumMonth.Junio]: "06",
  [StatusEnumMonth.Julio]: "07",
  [StatusEnumMonth.Agosto]: "08",
  [StatusEnumMonth.Septiembre]: "09",
  [StatusEnumMonth.Octubre]: "10",
  [StatusEnumMonth.Noviembre]: "11",
  [StatusEnumMonth.Diciembre]: "12",
};

export enum FormatDateEnum {
  D_MMM_YYY = "D/MMM/YYYY",
}

export enum DateEnum {
  DEFAULT = "1970-01-01T00:00:00.000Z",
  MONTH_DEFAULT = "01",
  DAY_DEFAULT = "01",
}

export enum ConfigUserModalEnum {
  TITLE = "¿Deseas configurar usuarios para tus branches?",
  DESCRIPTION = "Es necesario configurar un Usuario para cada Branch, puedes configurarlos de forma masiva desde el resumen del Customer.",
  PRIMARY_BUTTON = "Configurar usuarios",
  SECONDARY_BUTTON = "En otro momento",
}

export enum VariantButtonEnum {
  CONTAINED = "contained",
}

export enum FilterAmountLabelEnum {
  MIN = "Mayor que",
  MAX = "Menor que",
  EQ = "Igual a",
  BET = "Entre",
}
export enum FilterAmountValueEnum {
  MIN = "max",
  MAX = "min",
  EQ = "eq",
  BET = "bet",
}

export enum LabelTransactionEnum {
  MIN = "Min",
  MAX = "Max",
  MESSAGE_ERROR = "El valor monto mínimo no puede ser mayor que el máximo",
  RANGE = "Rango",
}
