import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const headerFiltersStyles = createNamedStyles({
  gridFilter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  gridHeaders: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  gridSearch: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  button: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
  innerButton: {
    alignItems: "center",
    display: "flex",
    gap: "15px",
    margin: 0,
    padding: 0,
    width: "80%",
  },
  gridFilterCard: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    "& .css-79elbk": {
      flexWrap: "wrap",
      width: "100%",
    },
  },
  boxAlert: {
    padding: "24px 0px 24px 0px",
    width: "100%",
  },
});
