/**
 * Menu Item Enum
 */
export enum MenuItemTitleEnum {
  EDIT_PROCESSOR = "Editar Procesador",
  ENABLED_PROCESSOR = "Habilitar",
  DISABLED_PROCESSOR = "Deshabilitar",
  ASSIGN_FAILOVER = "Asignar Fail Over",
  DELETE_PROCESSOR = "Eliminar",
}
