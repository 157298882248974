import { format } from "date-fns";
import { NodeRule, VariableRule } from "../../../types/rule_alarm";
import { get, isEmpty } from "lodash";
import { FrequencyLabel, FrequencyValue } from "@shared/enum/FrequencyEnum";
import { EntityName } from "@shared/enum/entityNameEnum";
import {
  FieldOptionValue,
  OperatorOptionLabel,
  OperatorOptionValue,
  SiNoEnum,
  YesNoEnum,
} from "@shared/constants/AlarmConfigConstants";
import {
  AlarmDetailCardEnum,
  TitleType,
} from "@shared/enum/AlarmDetailCardEnum";
import { DateFormatEnum } from "@shared/constants/parseMonths";
import { countriesOption } from "@shared/constants/countriesOption";
import { ISelectOption } from "@components/InputsForm/Inputs/InputSelect/InputSelect.interfaces";
import { Alarm, Timeline } from "../../../types/alarm";
import { ActiveAlarmStatus } from "@shared/enum/ActiveAlarmStatusEnum";

export interface IMerchantsGroupedByNodes {
  label: string;
  merchants: string;
}

export const descriptionDetailCard = <T>(
  label: string,
  data: T | null,
  type: TitleType.CREATE | TitleType.UPDATE
) => {
  if (isEmpty(data)) return "";

  if (type === TitleType.UPDATE && get(data, "updatedAt", 0) === 0) return "";

  const date =
    type === TitleType.CREATE
      ? get(data, "createdAt", 0)
      : get(data, "updatedAt", 0);
  const user =
    type === TitleType.CREATE
      ? get(data, "createdBy", "")
      : get(data, "updatedBy", "");

  return `${label}: ${user} ${format(date, DateFormatEnum.dd_MM_yyyy_HH_mm)}`;
};

export const translateFrequency: Record<string, string> = {
  [FrequencyValue.DAILY]: FrequencyLabel.DIARIA,
  [FrequencyValue.WEEKLY]: FrequencyLabel.SEMANAL,
  [FrequencyValue.BIWEEKLY]: FrequencyLabel.QUINCENAL,
  [FrequencyValue.QUARTERLY]: FrequencyLabel.TRIMESTRAL,
  [FrequencyValue.MONTHLY]: FrequencyLabel.MENSUAL,
  [FrequencyValue.BIANNUAL]: FrequencyLabel.SEMESTRAL,
};

export const translateOperationOption: Record<string, string> = {
  [OperatorOptionValue.EQUAL]: OperatorOptionLabel.IGUAL,
  [OperatorOptionValue.DIFFERENT]: OperatorOptionLabel.DIFERENTE,
  [OperatorOptionValue.GREATER_THAN]: OperatorOptionLabel.MAYOR_QUE,
  [OperatorOptionValue.LESS_THAN]: OperatorOptionLabel.MENOR_QUE,
};

export const translateYesNoValue: Record<string, string> = {
  [YesNoEnum.YES]: SiNoEnum.SI,
  [YesNoEnum.NO]: SiNoEnum.No,
};

const parseNodesNames = (names: string[]): string => {
  const namesSliced: string[] = names.slice(0, 6);

  if (names.length <= 6) {
    return namesSliced.join(", ");
  }

  return `${namesSliced.join(", ")}, ...(+${names.length - 6})`;
};

export const groupNodesByEntityName = (
  nodes: NodeRule[]
): IMerchantsGroupedByNodes[] => {
  const nodesByEntityName: Record<string, string[]> = {
    branch: [],
    customer: [],
    owner: [],
  };

  nodes.forEach((node: NodeRule) => {
    if (node.entityName === EntityName.OWNER) {
      nodesByEntityName.owner.push(node.name);
    } else if (node.entityName === EntityName.CUSTOMER) {
      nodesByEntityName.customer.push(node.name);
    } else {
      nodesByEntityName.branch.push(node.name);
    }
  });

  return [
    {
      label: AlarmDetailCardEnum.OWNER,
      merchants: parseNodesNames(nodesByEntityName.owner),
    },
    {
      label: AlarmDetailCardEnum.CUSTOMER,
      merchants: parseNodesNames(nodesByEntityName.customer),
    },
    {
      label: AlarmDetailCardEnum.BRANCH,
      merchants: parseNodesNames(nodesByEntityName.branch),
    },
  ];
};

export const translateCatalogOptions = (
  value: string,
  options: ISelectOption[]
): string => {
  const selectedOption = options.find((item) => item.value === value);

  return get(selectedOption, "label", "");
};

export const renderAmountValue = (
  amount: string | number,
  currency: string = "USD"
): string => {
  if (isEmpty(amount) && typeof amount !== "number") return "";

  return `${Number(Number(amount).toFixed(2)).toLocaleString(
    "en-EN"
  )} ${currency}`;
};

export const parseVariablesValue = (
  variable: VariableRule,
  rowData?: Alarm | null
): string => {
  switch (variable.field) {
    case FieldOptionValue.PEP:
      return translateYesNoValue[variable.value];
    case FieldOptionValue.TPV:
      if (!rowData) return renderAmountValue(variable.value);

      return get(rowData, "isTpvTriggered") === 1
        ? renderAmountValue(get(rowData, "sumConvertedApprovedAmount", 0))
        : "";
    case FieldOptionValue.AVERAGE_TICKET:
      if (!rowData) return renderAmountValue(variable.value);

      return get(rowData, "isAverageTicketTriggered") === 1
        ? renderAmountValue(get(rowData, "averageConvertedApprovedAmount", 0))
        : "";
    case FieldOptionValue.CONSTITUTIONAL_COUNTRY:
      return translateCatalogOptions(
        variable.value.toString(),
        countriesOption
      );
    default:
      return variable.value.toString();
  }
};

export const getCloseDateDescriptionHistory = (alarm: Alarm): string => {
  if (
    ![ActiveAlarmStatus.ROS, ActiveAlarmStatus.NORMAL].includes(
      get(alarm, "status", "") as ActiveAlarmStatus
    )
  ) {
    return "";
  }

  const date: Timeline[] = alarm.timeline
    .filter((timeline: Timeline) =>
      [ActiveAlarmStatus.ROS, ActiveAlarmStatus.NORMAL].includes(
        timeline.status as ActiveAlarmStatus
      )
    )
    .sort(
      (previous: Timeline, current: Timeline) =>
        current.createdAt - previous.createdAt
    );

  return `${AlarmDetailCardEnum.CLOSE_DATE} ${format(
    date[0].createdAt,
    DateFormatEnum.ddMMyyyy
  )}`;
};
