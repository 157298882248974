import React from "react";
import {
  createStyles,
  Grid,
  Icon,
  Link,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
  withStyles,
} from "@material-ui/core";
import clsx from "clsx";
import {
  FinalStatusEnum,
  InitialStatusEnum,
  StatusEnum,
} from "../../../shared/infrastructure/StatusEnum";
import openIcon from "../../../assets/images/open-icon.svg";
import approveIcon from "../../../assets/images/Icon-Aprobada.svg";
import initializedIcon from "../../../assets/images/Icon-Inicializada.svg";
import modifiedIcon from "../../../assets/images/Icon-Modificada.svg";
import rejectIcon from "../../../assets/images/Icon-Declinada.svg";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { InfoTrxProps } from "../../ModalBodyInfo/ModalBodyRetentionHistoric";
import {
  capitalize,
  defaultTo,
  findLastIndex,
  get,
  has,
  isEmpty,
} from "lodash";
import {
  DynamoReferenceEnum,
  getDynamoElasticRef,
} from "../../../shared/infrastructure/constants/DynamoElasticMap";
import { InvoiceRecord } from "../../../../types/remote/response_search_blling_by_filters";
import { StepTitleEnum } from "../../../shared/infrastructure/StepTitleEnum";
import { formatNumber } from "../../DashboardList/Table/DashboardHistoricTable/DashboardHistoricTableRow";
import { IStepRetentionTimeLine } from "../../../shared/infrastructure/interfaces/IStepRetentionTimeLine";
import { useSelector } from "react-redux";
import { IBillingDashboardState } from "../../../store/reducer";
import { ModalDialogDetail } from "../../ModalDialogDetail/ModalDialogDetail";
import { sortTrxList, validateDate } from "../../ModalBodyInfo/utilsModalBody";
import { ModalDialogDetailRetentionColombia } from "../../ModalDialogDetail/ModalDialogDetailRetentionColombia";
import { ModalDialogInconsistences } from "../../ModalDialogInconsistences/ModalDialogInconsistences";
import { CountryEnum } from "../../../shared/infrastructure/CountryEnum";
import { StatusFlowEnum } from "../../../shared/infrastructure/StatusFlowEnum";

export const CustomConnector = withStyles({
  active: {
    "& $lineVertical": {
      borderColor: "#0DC298",
    },
  },
  completed: {
    "& $lineVertical": {
      borderColor: "#0DC298",
    },
  },
  lineVertical: {
    minHeight: "80px !important",
    backgroundColor: "transparent",
    borderRadius: 1,
    borderLeftWidth: "2px !important",
    margin: "0px 20px !important",
    padding: "0px 8px 0px 0px !important",
  },
  vertical: {
    margin: "0px !important",
    padding: "0px 0px 0px 0px !important",
  },
})(StepConnector);

const useCustomStepIconStyles = makeStyles({
  root: {
    zIndex: 1,
    color: "#fff",
    width: 32,
    height: 32,
    display: "flex",
    borderRadius: "4px",
    justifyContent: "center",
    alignItems: "center",
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      "&..MuiContainer-root": {
        padding: "0 !important",
      },
      display: "block",
      position: "relative",
      backgroundColor: "#F7FAFC !important",
    },
    title: {
      color: "#46525C",
      lineHeight: "170%",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
    },
    subtitle: {
      color: "#6D7781",
      lineHeight: "136%",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
    },
    description: {
      color: "#023365",
      lineHeight: "136%",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "15px",
    },
    link: {
      color: "#023365",
      lineHeight: "170%",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      cursor: "pointer",
    },
    linearProgress: {
      marginTop: 3,
    },
    paper: {
      padding: theme.spacing(2),
      boxShadow: "none !important",
      borderRadius: "0px !important",
      marginBottom: "20px",
    },
    backgroundIcon: {
      color: "#023365",
    },
    container: {
      paddingTop: 20,
      paddingBottom: 15,
      marginLeft: "-50px",
    },
  })
);

export function OpenIcon() {
  return (
    <Icon>
      <img src={openIcon} alt={openIcon} />
    </Icon>
  );
}

export function CustomStepIcon(props: StepIconProps) {
  const classes = useCustomStepIconStyles();
  const icon = get(props, "icon", "") as string;
  const backgroundIcon = get(props, "background", "");

  return (
    <div className={clsx(classes.root)}>
      <img
        src={icon}
        alt={icon}
        style={{
          background: backgroundIcon,
          padding: "7px",
          borderRadius: "10%",
        }}
      />
    </div>
  );
}

export const renderStepTimeLine = (
  trxInfo: InvoiceRecord,
  index: number,
  isHistoric: boolean,
  arrayLength: number
): IStepRetentionTimeLine[] => {
  const statusFinal = get(
    trxInfo,
    getDynamoElasticRef(DynamoReferenceEnum.STATUS, index === 0)
  );
  const initialStatus = get(
    trxInfo,
    getDynamoElasticRef(DynamoReferenceEnum.INITIAL_STATUS, index === 0)
  );
  const modify = get(
    trxInfo,
    getDynamoElasticRef(DynamoReferenceEnum.MODIFIED_AMOUNTS, false)
  );

  const amountRetIva = get(trxInfo, "amountRetIva", 0);
  const oldImage = get(trxInfo, "oldTransactions", []);
  const findOldImage = oldImage[oldImage.length - 1];
  const oldAmountRetIva = get(findOldImage, "amountRetIva", amountRetIva);
  const amountRetIca = get(trxInfo, "amountRetIca", 0);
  const oldImageAmountRetIca = get(trxInfo, "oldTransactions", []);
  const isRejectedModify =
    statusFinal === StatusEnum.PENDING &&
    modify &&
    !isEmpty(get(trxInfo, "reject", {})) &&
    trxInfo.statusFlow === StatusFlowEnum.EXECUTOR;
  const findOldImageAmountRetIca =
    oldImageAmountRetIca[oldImageAmountRetIca.length - 1];
  const oldAmountRetIca = get(
    findOldImageAmountRetIca,
    "amountRetIca",
    amountRetIca
  );
  let response: IStepRetentionTimeLine[] = [];

  if (statusFinal === StatusEnum.PENDING && modify === undefined)
    if (arrayLength === index + 1)
      response = [
        {
          icon: initializedIcon,
          backgroundIcon: "#f8fafc",
          title: StepTitleEnum.initialAmountProcessed,
          description: "Software",
          amount: get(
            trxInfo,
            getDynamoElasticRef(
              DynamoReferenceEnum.RET_TOTAL_AMOUNT,
              index === 0
            ),
            0
          ),
          viewDetail: false,
          value: trxInfo,
          isHistoric: index === 0,
        },
      ];
    else
      response = [
        {
          icon: initializedIcon,
          backgroundIcon: "#f8fafc",
          title: StepTitleEnum.returnedRetention,
          amount: get(
            trxInfo,
            getDynamoElasticRef(DynamoReferenceEnum.RET_TOTAL_AMOUNT, false),
            0
          ),
          viewDetail: false,
          value: trxInfo,
          isHistoric: false,
        },
      ];
  if (statusFinal === StatusEnum.PRE_PROCESSED) {
    response = [
      {
        icon: initializedIcon,
        backgroundIcon: "#f8fafc",
        title: StepTitleEnum.processRetention,
        amount: get(
          trxInfo,
          getDynamoElasticRef(DynamoReferenceEnum.RET_TOTAL_AMOUNT, false),
          0
        ),
        viewDetail: false,
        value: trxInfo,
        isHistoric: isHistoric || false,
      },
    ];
    if (modify)
      response.push({
        icon: modifiedIcon,
        backgroundIcon: "#fef9ec",
        title: StepTitleEnum.modifiedAmount,
        amount: get(
          trxInfo,
          getDynamoElasticRef(DynamoReferenceEnum.RET_TOTAL_AMOUNT, false),
          0
        ),
        viewDetail: true,
        value: trxInfo,
        isHistoric: isHistoric && index === 0,
      });
  }

  if (
    amountRetIva != oldAmountRetIva &&
    statusFinal !== StatusEnum.PRE_PROCESSED
  ) {
    response.push({
      icon: modifiedIcon,
      backgroundIcon: "#fef9ec",
      title: "Monto Modificado",
      amount: 1234567,
      viewDetail: true,
      value: trxInfo,
      isHistoric: index === 0,
    });
  }
  if (
    amountRetIca != oldAmountRetIca &&
    statusFinal !== StatusEnum.PRE_PROCESSED
  ) {
    response.push({
      icon: modifiedIcon,
      backgroundIcon: "#fef9ec",
      title: "Monto Modificado",
      amount: 1234567,
      viewDetail: true,
      value: trxInfo,
      isHistoric: index === 0,
    });
  }
  if (statusFinal === StatusEnum.INITIALIZED)
    response = [
      {
        icon: approveIcon,
        backgroundIcon: "#f1fffc",
        title: StepTitleEnum.approveOperation,
        description: get(
          trxInfo,
          getDynamoElasticRef(DynamoReferenceEnum.BACKOFFICE_USER, index === 0),
          ""
        ),
        viewDetail: false,
        value: trxInfo,
        isHistoric: index === 0,
      },
    ];
  if (initialStatus === StatusEnum.REJECT)
    response = [
      {
        icon: rejectIcon,
        backgroundIcon: "#feeff1",
        title: StepTitleEnum.rejectRetention,
        subtitle: "(kushki)",
        amount: get(
          trxInfo,
          getDynamoElasticRef(
            DynamoReferenceEnum.RET_TOTAL_AMOUNT,
            index === 0
          ),
          0
        ),
        viewDetail: true,
        value: trxInfo,
        isHistoric: index === 0,
      },
    ];
  if (
    initialStatus === InitialStatusEnum.APPROVE &&
    statusFinal === StatusEnum.REJECTED
  )
    response = [
      {
        icon: rejectIcon,
        backgroundIcon: "#feeff1",
        title: StepTitleEnum.rejectRetention,
        subtitle: "(Dátil)",
        amount: get(
          trxInfo,
          getDynamoElasticRef(
            DynamoReferenceEnum.RET_TOTAL_AMOUNT,
            index === 0
          ),
          0
        ),
        viewDetail: true,
        value: trxInfo,
        isHistoric: index === 0,
      },
    ];
  if (statusFinal === StatusEnum.REJECTED || isRejectedModify)
    response = [
      {
        icon: rejectIcon,
        backgroundIcon: "#feeff1",
        title: "Retención Rechazada",
        subtitle: "",
        amount: get(
          trxInfo,
          getDynamoElasticRef(
            DynamoReferenceEnum.RET_TOTAL_AMOUNT,
            isHistoric && index === 0
          ),
          0
        ),
        viewDetail: true,
        value: trxInfo,
        isHistoric: isHistoric && index === 0,
      },
    ];
  if (statusFinal === StatusEnum.COMPLETED)
    response = [
      {
        icon: approveIcon,
        backgroundIcon: "#f1fffc",
        title: StepTitleEnum.autorizedRetention,
        amount: get(
          trxInfo,
          getDynamoElasticRef(
            DynamoReferenceEnum.RET_TOTAL_AMOUNT,
            index === 0
          ),
          0
        ),
        viewDetail: false,
        value: trxInfo,
        isHistoric: index === 0,
      },
    ];
  if (statusFinal === StatusEnum.DELETED)
    response = [
      {
        icon: rejectIcon,
        backgroundIcon: "#feeff1",
        title: StepTitleEnum.canceledRetention,
        amount: get(
          trxInfo,
          getDynamoElasticRef(
            DynamoReferenceEnum.RET_TOTAL_AMOUNT,
            index === 0
          ),
          0
        ),
        viewDetail: true,
        value: trxInfo,
        isHistoric: index === 0,
      },
    ];
  if (statusFinal === FinalStatusEnum.OMITTED)
    response = [
      {
        icon: initializedIcon,
        backgroundIcon: "#f8fafc",
        title: "Retención Omitida",
        amount: get(
          trxInfo,
          getDynamoElasticRef(
            DynamoReferenceEnum.RET_TOTAL_AMOUNT_DAVI,
            index === 0
          ),
          0
        ),
        viewDetail: true,
        value: trxInfo,
        isHistoric: index === 0,
      },
    ];
  return response;
};

const TimeLineTabComponent = (props: InfoTrxProps) => {
  const isModify: boolean | undefined = defaultTo(props.isModify, false);
  const country: CountryEnum = get(
    props,
    "trxInfo.country",
    CountryEnum.ecuador
  );

  const classes = useStyles();

  const { openModalDetailTimeLine } = useSelector(
    (state: IBillingDashboardState) => state
  );

  const CustomConnector = withStyles({
    completed: {
      "& $lineVertical": {
        borderColor: "#0DC298",
      },
    },
    lineVertical: {
      minHeight: "40px",
      backgroundColor: "transparent",
      borderRadius: 1,
      borderLeftStyle: "dotted",
      borderLeftWidth: "2px",
      margin: "0px 15px",
    },
    vertical: {
      margin: "0px",
      padding: "0px 0px 0px 0px",
    },
  })(StepConnector);

  let renderSteps: IStepRetentionTimeLine[] = [];
  sortTrxList(
    get(props.trxInfo, DynamoReferenceEnum.OLD_TRANSACTIONS, []),
    props.trxInfo,
    true
  ).map((data: InvoiceRecord, index: number, array: InvoiceRecord[]) => {
    return renderStepTimeLine(
      data,
      index,
      props.isHistoric || false,
      array.length
    ).map((response: IStepRetentionTimeLine) => renderSteps.push(response));
  });
  const isValidatorPreProcessed: boolean =
    [CountryEnum.colombia].includes(
      capitalize(props.trxInfo!.country) as CountryEnum
    ) &&
    props.statusFlow === StatusFlowEnum.VALIDATOR &&
    get(props, "trxInfo.status", "") === StatusEnum.PRE_PROCESSED;

  const isRejected: boolean =
    [CountryEnum.colombia].includes(
      capitalize(props.trxInfo!.country) as CountryEnum
    ) &&
    props.statusFlow === StatusFlowEnum.EXECUTOR &&
    get(props, "trxInfo.status", "") === StatusEnum.REJECTED;

  return (
    <React.Fragment>
      <Grid
        container
        color="secondary"
        style={{
          margin: 0,
          marginLeft: "50px",
        }}
      >
        <Grid item xs={2} className={"statusGrid"}>
          <Stepper
            orientation={"vertical"}
            connector={<CustomConnector />}
            activeStep={findLastIndex(renderSteps, ["completed", true])}
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: "30px",
              marginLeft: "30px",
            }}
          >
            {renderSteps.map(
              (response: IStepRetentionTimeLine, index: number) => (
                <Step key={index}>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <CustomStepIcon
                        {...props}
                        background={response.backgroundIcon}
                      />
                    )}
                    icon={response.icon}
                  />
                </Step>
              )
            )}
          </Stepper>
        </Grid>
        <Grid item xs={4} className={"infoGrid"}>
          <Grid container style={{ paddingTop: 30 }}>
            {renderSteps.map(
              (response: IStepRetentionTimeLine, index: number) => (
                <Grid
                  item
                  xs={12}
                  key={index}
                  style={{
                    minHeight: 70,
                  }}
                >
                  <Grid item xs={12}>
                    <Typography variant="body2" className={classes.title}>
                      {response.title}
                    </Typography>
                    {has(response, "subtitle") && (
                      <Typography
                        variant="subtitle2"
                        className={classes.subtitle}
                      >
                        {response.subtitle}
                      </Typography>
                    )}
                    {has(response, "description") && (
                      <Typography
                        variant="body2"
                        className={classes.description}
                      >
                        {response.description}
                      </Typography>
                    )}
                    {has(response, "amount") && (
                      <Typography
                        variant="subtitle2"
                        className={classes.subtitle}
                      >
                        Monto total:{" "}
                        {formatNumber(
                          get(response, "amount", 0),
                          get(
                            response.value,
                            "currency_code",
                            get(response.value, "currency", "USD")
                          )
                        )}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              )
            )}
          </Grid>
        </Grid>
        <Grid item xs={3} className={"infoGrid"}>
          <Grid container style={{ paddingTop: 30 }}>
            {renderSteps.map(
              (response: IStepRetentionTimeLine, index: number) => (
                <Grid
                  item
                  xs={12}
                  key={index}
                  style={{
                    minHeight: 70,
                  }}
                >
                  <Typography variant="body2" className={classes.title}>
                    {
                      validateDate(
                        get(
                          response.value,
                          getDynamoElasticRef(
                            DynamoReferenceEnum.UPDATED_AT,
                            props.isHistoric!
                          ),
                          get(
                            response.value,
                            DynamoReferenceEnum.UPDATED_AT,
                            ""
                          )
                        )!
                      ).split("T")[0]
                    }{" "}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.subtitle}>
                    {
                      validateDate(
                        get(
                          response.value,
                          getDynamoElasticRef(
                            DynamoReferenceEnum.UPDATED_AT,
                            props.isHistoric!
                          ),
                          get(
                            response.value,
                            DynamoReferenceEnum.UPDATED_AT,
                            ""
                          )
                        )!
                      )
                        .split("T")[1]
                        .split(".")[0]
                    }
                  </Typography>
                </Grid>
              )
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={3}
          className={"infoGrid"}
          style={{ marginLeft: "-50px" }}
        >
          <Grid container style={{ paddingTop: 30 }}>
            {renderSteps.map(
              (response: IStepRetentionTimeLine, index: number) => (
                <Grid
                  xs={12}
                  key={index}
                  style={{
                    minHeight: 70,
                  }}
                >
                  {get(response, "viewDetail", true) &&
                    ((country === CountryEnum.colombia &&
                      isValidatorPreProcessed &&
                      props.isRetColombia) ||
                      (isModify && !isValidatorPreProcessed) ||
                      isRejected ||
                      get(response, "value.status") === StatusEnum.OMITTED) && (
                      <Link
                        underline="always"
                        component="button"
                        variant={"caption"}
                        onClick={() => props.handleOpenDialogDetail!(response)}
                        style={{ marginLeft: "30px" }}
                      >
                        <Grid
                          item
                          xs={12}
                          style={{ marginBottom: -10, marginTop: -11 }}
                        >
                          <OpenIcon />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant={"caption"} align={"center"}>
                            Ver detalle
                          </Typography>
                        </Grid>
                      </Link>
                    )}
                  {get(response, "viewDetail", true) &&
                    !isRejected &&
                    !isModify &&
                    !isValidatorPreProcessed &&
                    get(response, "value.status") !== StatusEnum.OMITTED && (
                      <Link
                        underline="always"
                        component="button"
                        variant={"caption"}
                        onClick={() =>
                          props.handleModalModifyInconsistence!(true)
                        }
                        style={{ marginLeft: "30px" }}
                      >
                        <Grid
                          item
                          xs={12}
                          style={{ marginBottom: -10, marginTop: -11 }}
                        >
                          <OpenIcon />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant={"caption"} align={"center"}>
                            Ver detalle
                          </Typography>
                        </Grid>
                      </Link>
                    )}
                </Grid>
              )
            )}
          </Grid>
          {props.isRetColombia && country === CountryEnum.colombia ? (
            <ModalDialogDetailRetentionColombia
              open={openModalDetailTimeLine}
              handleCloseDialogDetail={props.handleCloseDialogDetail}
            />
          ) : (
            <ModalDialogDetail
              open={openModalDetailTimeLine}
              handleCloseDialogDetail={props.handleCloseDialogDetail}
            />
          )}
        </Grid>
        <ModalDialogInconsistences
          trxInfo={props.trxInfo}
          open={props.openModifyInconsistence}
          handleModalDialogDetail={() =>
            props.handleModalModifyInconsistence!(false)
          }
          contentFooter={props.contentFooter}
          isdetalleModify={true}
        />
      </Grid>
    </React.Fragment>
  );
};

export default TimeLineTabComponent;
