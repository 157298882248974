import { createNamedStyles } from "../../../../shared/interfaces/create_named_styles";

const CellTextActionStyles = createNamedStyles({
  textStyle: {
    color: "#293036",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19.6px",
  },
});

export { CellTextActionStyles };
