import {
  ActionHistoricEnum,
  RequestActionEnum,
} from "../../shared/infrastructure/enums/ActionEnum";
import {
  BodyTab,
  BodyTabContentBlock,
  BodyTabField,
} from "./ModalBodyTab/ModalBodyTab.interfaces";
import {
  CONCILIATION_ACTION_OPTIONS,
  TabContentBlockType,
  TimelineActionEnum,
  TimelineIconEnum,
  TimelineIconText,
  TimelineResponseEnum,
} from "./TabContentBlocks/TabContentBlock.interfaces";
import {
  contentLineApproval,
  contentLineCard,
  sharedContentLineTrxInfo,
} from "../../shared/infrastructure/constants/PendingDetailsCardFields";
import { defaultTo, get, isEmpty } from "lodash";
import { AnalysisStateEnum } from "../../shared/infrastructure/constants/AnalysisStateEnum";
import { formatZoned } from "../../shared/utils/formatZoned";
import { IChip } from "./ModalHeaderInfo/ModalHeaderInfo.interfaces";
import { StatusFlowEnum } from "../../shared/infrastructure/enums/StatusFlowEnum";
import { TransactionData } from "../../../types/transaction_data";
import { TransactionHistory } from "../../shared/infrastructure/interfaces/TransactionHistory";
import { translate } from "../../shared/infrastructure/LanguageCatatog";

export const getPendingExecutorChipColors = (
  trx: TransactionData,
  stateRefund: AnalysisStateEnum
): [string, string, string] => {
  switch (stateRefund) {
    case AnalysisStateEnum.PNDRFN:
      return [
        "#FEECF8",
        "#8F4F8B",
        translate(get(trx, "stateRefund", "PENDIENTE")),
      ];
    case AnalysisStateEnum.RTRY:
      return [
        "#F0F4F9",
        "#6D7781",
        translate(get(trx, "stateRefund", "REINGRESO")),
      ];
    case AnalysisStateEnum.NTHRZD:
      return [
        "#FFEAEE",
        "#AD0C2A",
        translate(get(trx, "stateRefund", "NO AUTORIZADO")),
      ];
    default:
      return ["#535353", "#FEFEFE", "N/A"];
  }
};

export const getPendingValidatorChipColors = (
  action: RequestActionEnum
): [string, string, string] => {
  switch (action) {
    case RequestActionEnum.refund_v:
      return ["#F0F4F9", "#6D7781", "Reembolso"];
    case RequestActionEnum.giveback:
      return ["#F0F4F9", "#6D7781", "Devolver"];
    case RequestActionEnum.cancel:
      return ["#F0F4F9", "#6D7781", "Cancelar"];
    case RequestActionEnum.skip:
      return ["#F0F4F9", "#6D7781", "Omitir"];
    default:
      return ["#535353", "#FEFEFE", "N/A"];
  }
};

export const getHistoricChipColors = (
  trx: TransactionData,
  action: RequestActionEnum
): [string, string, string] => {
  const response: ActionHistoricEnum = get(trx, "response", "");
  let chip_text: string;

  switch (action) {
    case RequestActionEnum.APRRFN:
    case RequestActionEnum.DCLRFN:
      chip_text = "Reembolso";
      break;
    case RequestActionEnum.APRGBCK:
    case RequestActionEnum.DCLGBCK:
      chip_text = "Devolver";
      break;
    case RequestActionEnum.APRCNL:
    case RequestActionEnum.DCLCNL:
      chip_text = "Cancelar";
      break;
    case RequestActionEnum.APROMT:
    case RequestActionEnum.DCLOMT:
      chip_text = "Omitir";
      break;
    default:
      chip_text = "N/A";
  }

  if (!isEmpty(response) && response !== ActionHistoricEnum.notDefined) {
    chip_text = chip_text.concat(" - ").concat(response.toString());
  }

  return ["#F0F4F9", "#6D7781", chip_text];
};

export const getChipColors = (
  statusFlow: StatusFlowEnum,
  trx: TransactionData
): [string, string, string] => {
  const stateRefund: AnalysisStateEnum = get<
    TransactionData,
    string,
    AnalysisStateEnum
  >(trx, "stateRefund", AnalysisStateEnum.NA);
  const action: RequestActionEnum = get(trx, "action", "");
  const historic: boolean = stateRefund === AnalysisStateEnum.AUTHORIZED;
  const pending_validator: boolean =
    stateRefund === AnalysisStateEnum.IVLDTN &&
    statusFlow === StatusFlowEnum.VALIDATOR;
  const pending_executor: boolean =
    [
      AnalysisStateEnum.PNDRFN,
      AnalysisStateEnum.RTRY,
      AnalysisStateEnum.NTHRZD,
    ].includes(stateRefund) && statusFlow === StatusFlowEnum.EXECUTOR;

  if (historic) return getHistoricChipColors(trx, action);
  if (pending_executor) return getPendingExecutorChipColors(trx, stateRefund);
  if (pending_validator) return getPendingValidatorChipColors(action);

  return ["#535353", "#FEFEFE", "N/A"];
};

export const getDetailsCardChips = (
  statusFlow: StatusFlowEnum,
  trx: TransactionData
): IChip[] => {
  const [background, color, value] = getChipColors(statusFlow, trx);

  return [
    {
      background,
      color,
      value,
    },
  ];
};

export const getTimelineContent = (
  statusFlow: StatusFlowEnum,
  trx: TransactionData
): BodyTabContentBlock[] => {
  const history: TransactionHistory[] = defaultTo(trx.history, []);
  let response: BodyTabContentBlock[] = [];

  if (!Array.isArray(history)) return [];

  if (
    statusFlow === StatusFlowEnum.EXECUTOR ||
    statusFlow === StatusFlowEnum.VALIDATOR ||
    statusFlow === StatusFlowEnum.VIEWER
  ) {
    response = history.map((row: TransactionHistory) => {
      const dateTimestamp: string = get(row, "modified_date", "0");
      const dateTime = new Date(dateTimestamp);
      const date = formatZoned(dateTime, "dd-MM-yyyy");
      const time = formatZoned(dateTime, "HH:mm:ss");
      const action: string = get(row, "action", "");
      const response: string = get(row, "response", "");
      const actionFound: TimelineActionEnum | undefined = Object.values(
        TimelineActionEnum
      ).find((actionEnumValue) => actionEnumValue === action);
      const responseFound: TimelineResponseEnum | undefined =
        actionFound === undefined
          ? TimelineResponseEnum.NONE
          : Object.values(TimelineResponseEnum).find(
              (responseEnumValue) => responseEnumValue === response
            );
      const timeLineIcons: TimelineIconEnum =
        CONCILIATION_ACTION_OPTIONS[
          defaultTo(actionFound, TimelineActionEnum.UNEXPECTED)
        ][defaultTo(responseFound, TimelineResponseEnum.NONE)];

      return {
        blockType: TabContentBlockType.TIME_LINE_ROW,
        timeLine: {
          date,
          icon: timeLineIcons,
          reason: get(row, "observation", ""),
          time,
          title: TimelineIconText[timeLineIcons],
          user: get(row, "user", ""),
        },
      };
    });
  }

  return response.reverse();
};

export const getDetailsCardTabs = (
  statusFlow: StatusFlowEnum,
  trx: TransactionData
): BodyTab[] => {
  let executorInfoLines: BodyTabField[] = [];

  if (statusFlow === StatusFlowEnum.EXECUTOR) {
    switch (trx.stateRefund) {
      case AnalysisStateEnum.PNDRFN:
      case AnalysisStateEnum.NTHRZD:
        executorInfoLines = contentLineApproval(trx);
        break;
      case AnalysisStateEnum.RTRY:
      default:
        executorInfoLines = contentLineCard(trx);
        break;
    }

    return [
      {
        content: [
          {
            blockType: TabContentBlockType.SIMPLE_INFO,
            lines: executorInfoLines,
          },
          {
            blockType: TabContentBlockType.SIMPLE_INFO,
            lines: sharedContentLineTrxInfo(trx),
          },
        ],
        title: "Información",
      },
      {
        content: getTimelineContent(statusFlow, trx),
        title: "Línea de tiempo",
      },
    ];
  }

  if (statusFlow === StatusFlowEnum.VALIDATOR) {
    return [
      {
        content: [
          {
            blockType: TabContentBlockType.SIMPLE_INFO,
            lines: contentLineApproval(trx),
          },
          {
            blockType: TabContentBlockType.SIMPLE_INFO,
            lines: sharedContentLineTrxInfo(trx),
          },
        ],
        title: "Información",
      },
      {
        content: getTimelineContent(statusFlow, trx),
        title: "Línea de tiempo",
      },
    ];
  }

  if (statusFlow === StatusFlowEnum.VIEWER) {
    switch (trx.stateRefund) {
      case AnalysisStateEnum.PNDRFN:
      case AnalysisStateEnum.NTHRZD:
        executorInfoLines = contentLineApproval(trx);
        break;
      case AnalysisStateEnum.RTRY:
      default:
        executorInfoLines = contentLineCard(trx);
        break;
    }

    return [
      {
        content: [
          {
            blockType: TabContentBlockType.SIMPLE_INFO,
            lines: executorInfoLines,
          },
          {
            blockType: TabContentBlockType.SIMPLE_INFO,
            lines: sharedContentLineTrxInfo(trx),
          },
        ],
        title: "Información",
      },
      {
        content: getTimelineContent(statusFlow, trx),
        title: "Línea de tiempo",
      },
    ];
  }

  return [];
};
