export enum DeferredData {
  EmptyDeferredMessage = "Aún no tienes diferidos agregados",
  HeaderDeferred = "Diferidos",
  AddNewDeferredMessage = "Agregar diferido",
  AddMassiveDeferred = "Agrega diferidos de forma masiva",
  AlertMassiveDeferred = "Toma en cuenta que los diferidos serán creados de acuerdo a los procesadores configurados.",
}

export enum StatusDeferred {
  STATUS_DISABLED = "disabled",
  STATUS_ENABLED = "enabled",
}

export enum StatusDeferredLabel {
  STATUS_DISABLED = "Inhabilitar",
  STATUS_ENABLED = "Habilitar",
}

export enum StatusMerchantDeferred {
  STATUS_DISABLED = "no",
  STATUS_ENABLED = "yes",
}

export const mapStatusDeferredMerchant: Record<string, string> = {
  [StatusMerchantDeferred.STATUS_DISABLED]: StatusDeferred.STATUS_DISABLED,
  [StatusMerchantDeferred.STATUS_ENABLED]: StatusDeferred.STATUS_ENABLED,
  [StatusDeferred.STATUS_DISABLED]: StatusDeferred.STATUS_DISABLED,
  [StatusDeferred.STATUS_ENABLED]: StatusDeferred.STATUS_ENABLED,
};

export enum StatusMerchantDeferredLabel {
  STATUS_DISABLED = "No",
  STATUS_ENABLED = "Si",
}

export const titleConfigurationInformation = "Información de la configuración";
export const subTitleCardBrands = "Marcas de tarjetas";

export enum BodyDeferredSetting {
  BodyCardBrands = "Estas son las marcas de tarjeta en las que se podrán aplicar diferidos.",
  BodyQuestionStatusDeferredMerchant = "Cada comercio podrá activar o desactivar la configuración.",
  QuestionStatusDeferredMerchant = "¿Deseas activar la configuración para los comercios?",
}

export const mapStatusMerchant: Record<string, string> = {
  [StatusDeferred.STATUS_DISABLED]: StatusMerchantDeferred.STATUS_DISABLED,
  [StatusDeferred.STATUS_ENABLED]: StatusMerchantDeferred.STATUS_ENABLED,
};

export const mapStatusMerchantBase: Record<string, string> = {
  [StatusMerchantDeferred.STATUS_DISABLED]: StatusDeferred.STATUS_DISABLED,
  [StatusMerchantDeferred.STATUS_ENABLED]: StatusDeferred.STATUS_ENABLED,
};

export enum PeruDeferredPath {
  deferredId = "id",
  deferredStatus = "status",
}
