export const tipoEmpresaGeneric: Array<string> = [
  "Otra",
  "Asociaciones Comerciales",
  "Asociaciones o Corporaciones",
  "Comerciante Individual",
  "Compañía de Economía Mixta (pública y privada)",
  "Compañía de Responsabilidad Limitada",
  "Compañía en Comandita por Acciones",
  "Companía en Comandita Simple",
  "Compañía en Nombre Colectivo",
  "Consorcio",
  "Corporation",
  "Empresa Individual de Responsabilidad Limitada (EIRL)",
  "Entidades de Economía Solidaria",
  "Fundaciones",
  "General Partnership",
  "Limited Liability Company",
  "Limited Partnership",
  "Oficina de representativos",
  "Sociedad Anónima",
  "Sociedad Anonima (S.A.)",
  "Sociedad Anónima Abierta (S.A.A.)",
  "Sociedad Anónima Cerrada (S.A.C.)",
  "Sociedad Civil de Responsabilidad Limitada",
  "Sociedad Civil Ordinaria",
  "Sociedad Colectiva",
  "Sociedad Colectiva Comercial",
  "Sociedad Colectiva de Responsabilidad Limitada",
  "Sociedad Comandita Simple",
  "Sociedad Comercial de Responsabilidad Limitada (S.R.L.)",
  "Sociedad Cooperativa",
  "Sociedad de Responsabilidad Limitada (S.R.L)",
  "Sociedad en Comandita por Acciones",
  "Sociedad Limitada (Ltda)",
  "Sociedad por Acciones",
  "Sociedad por Acciones Simplificada (S.A.S.)",
  "Sociedades extranjeras",
  "Sub-Chapter S Corporation",
  "Sucursal",
  "Sucursal de una empresa extranjera",
];

export interface IShowGenericInputs {
  companyType: boolean;
  economicActivityGeneric: boolean;
}

export enum valueFormGenericEnum {
  OTRA = "Otra",
}

export enum regExpFormGenericEnum {
  regExp = "[a-zA-Z0-9.]",
}
