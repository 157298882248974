import React, { FC } from "react";
import { RechargeWalletForm } from "../Form/RechargeWalletForm";
import { useRechargeWalletCardState } from "./state/useRechargeWalletCardState";
import { ModalLoader } from "@kushki/connect-ui";

const RechargeWalletCard: FC = () => {
  const { isLoaderOpen, onRechargeEvent } = useRechargeWalletCardState();

  return (
    <>
      <ModalLoader
        descriptionText="Esto puede tomar un momento"
        titleText="Estamos generando tu orden de recarga"
        isOpen={isLoaderOpen}
      />
      <RechargeWalletForm onRecharge={onRechargeEvent} />
    </>
  );
};

export { RechargeWalletCard };
