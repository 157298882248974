/* istanbul ignore file */
import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

// @ts-ignore
import Normalize from "react-normalize";
import { Routes } from "./shared/infrastructure/routes";
import { RuleRequestManagerIndex } from "./containers/RuleRequestManagerIndex/RuleRequestManagerIndex";
import { SecurityRuleDetail } from "./containers/SecurityRuleDetail/SecurityRuleDetail";
import { CreateRulesIndex } from "./containers/CreateRulesIndex/CreateRulesIndex";
import { environment } from "./environments/environment";
import { ComponentIdEnum } from "./shared/infrastructure/enums/ComponentIdEnum";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
import { WhiteList } from "./containers/WhiteListRule/WhiteList";
import { useRollbar } from "@rollbar/react";
import { FilterSearchProvider } from "./shared/provider/filter-search/FilterSearchProvider";

const Navigation: React.FC = () => {
  const rollbar = useRollbar();

  useEffect(() => {
    rollbar.configure({
      environment: environment.envName,
    });
  }, []);

  return (
    <>
      <Normalize />
      <BrowserRouter>
        <ModuleSecurityWrapper
          basePath={environment.kushkiUrl}
          componentId={ComponentIdEnum.M_FRAUD_PREVENTION}
        >
          <FilterSearchProvider>
            <Switch>
              <Route
                path={Routes.BASE_PATH_RULE_REQUEST_MANAGER}
                exact
                component={RuleRequestManagerIndex}
              />
              <Route
                path={Routes.CREATE_RULE_REQUEST}
                exact
                component={CreateRulesIndex}
              />
              <Route
                path={Routes.CREATE_RULE_DETAIL}
                exact
                component={SecurityRuleDetail}
              />
              <Route
                path={Routes.EDIT_RULE_DETAIL}
                exact
                component={SecurityRuleDetail}
              />
              <Route
                path={Routes.CREATE_GENERAL_RULE}
                exact
                component={SecurityRuleDetail}
              />
              <Route
                path={Routes.RULE_UPDATE}
                exact
                component={SecurityRuleDetail}
              />
              <Route
                path={Routes.GENERAL_RULE_UPDATE}
                exact
                component={SecurityRuleDetail}
              />
              <Route
                path={Routes.CREATE_WHITELIST_RULE}
                exact
                component={WhiteList}
              />
              <Route
                path={Routes.UPDATE_WHITELIST_RULE}
                exact
                component={WhiteList}
              />
            </Switch>
          </FilterSearchProvider>
        </ModuleSecurityWrapper>
      </BrowserRouter>
    </>
  );
};

export default connect()(Navigation);
