import { BranchEditItem } from "../../../types/branch_edit_item";
import { get, isEmpty, isNil } from "lodash";
import { clientTypeEnum } from "../constants/selected_section";
import { Configs } from "../../../types/search_merchant_response";
import { ConfigIdEnum } from "../enums/configIdEnums";

export const validateAllBranchHaveSameCountry = (
  editBranchList: BranchEditItem[]
) => {
  const country = get(editBranchList, "[0].constitutional_country");

  if (isNil(country)) {
    return false;
  }

  return editBranchList.every(
    (element: BranchEditItem) => element.constitutional_country === country
  );
};

export const validateClientTypeIsPSP = (editBranchList: BranchEditItem[]) => {
  return editBranchList.every(
    (element: BranchEditItem) => element.client_type === clientTypeEnum.PSP
  );
};

export const validateClientTypeIsBTB_BTC = (
  editBranchList: BranchEditItem[]
) => {
  return editBranchList.every(
    (element: BranchEditItem) =>
      element.client_type === clientTypeEnum.B2C ||
      element.client_type === clientTypeEnum.B2B
  );
};

export const findHierarchyConfiguration = (
  listConfigs: Configs[],
  configuration: ConfigIdEnum
): Configs => {
  const position: number = listConfigs.findIndex(
    (config: Configs) => config.configuration === configuration
  );

  return get(listConfigs, `[${position}]`, {});
};

export const validateConfigIsDecentralize = (
  editBranchList: BranchEditItem[],
  configuration: ConfigIdEnum
): boolean => {
  return editBranchList.every((element: BranchEditItem) => {
    const get_config: Configs = findHierarchyConfiguration(
      get(element, "configs", []),
      configuration
    );

    return !isEmpty(get_config) && isEmpty(get_config.centralizedNodesId);
  });
};
