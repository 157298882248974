import { ROUTES } from "../constants/routes";

export enum RedirectEnum {
  HOME = "/dashboard",
  MERCHANT_LIST = "/merchant-list",
  CREATE_MERCHANT_DETAILS = "/create-merchant/details",
}

export enum UrlParamsEnum {
  PUBLIC_MERCHANT_ID = "publicMerchantId",
  HIDE_SIDEBAR = "hideSideBar",
}

export const CreateMerchantURL = (merchantId: string, isConsole3: boolean) =>
  isConsole3
    ? `${ROUTES.MERCHANT_RESUME}?${UrlParamsEnum.PUBLIC_MERCHANT_ID}=${merchantId}`
    : `${RedirectEnum.CREATE_MERCHANT_DETAILS}?${UrlParamsEnum.PUBLIC_MERCHANT_ID}=${merchantId}&${UrlParamsEnum.HIDE_SIDEBAR}=true`;
