import { GroupEnum } from "../enums/CreateRuleConstans";

export const groupHierarchies: Record<string, string> = {
  [GroupEnum.OWNER]: "dataOwner.nodes",
  [GroupEnum.CUSTOMER]: "dataCustomer.nodes",
  [GroupEnum.BRANCH]: "dataBranch.nodes",
};

export const groupHierarchiesArray: string[] = [
  GroupEnum.CUSTOMER,
  GroupEnum.BRANCH,
];
