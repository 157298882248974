import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { RootState } from "../../../store/store";
import { Dispatch } from "redux";
import { IUseHeaderFilterDashboard } from "./useHeadFiltersDashboardState.interfaces";
import {
  FILTER_BY_AMOUNT_OPERATOR,
  OPERATION_TYPE_BY_LABEL,
  STATUS_BY_LABEL,
  WALLET_FILTERS_ITEMS,
} from "../../../shared/constants/head_filters";
import { HEAD_FILTERS_LABELS } from "../../../shared/constants/labels/head_filters_labels";
import {
  AmountFilterFieldEnum,
  DateFormatStringEnum,
  FilterLabelEnum,
} from "../../../shared/enums/filter";
import {
  setOffset,
  setPage,
  setTrxRequest,
} from "../../../store/actions/walletTransaction.action";
import {
  ItemCategoryItem,
  ItemCategoryProps,
} from "@kushki/connect-ui/dist/Components/Molecules/Filters/ItemCategory/interfaces";
import {
  cloneDeep,
  forEach,
  get,
  isEmpty,
  isEqual,
  merge,
  pick,
  remove,
  set,
  unset,
} from "lodash";
import { TOnChangeFilters } from "@kushki/connect-ui/dist/Components/Molecules/Filters/FilterDetailBar/FilterDetailBar.interfaces";
import { TrxRequestFilter } from "../../../../types/get_transactions_request";
import format from "date-fns/format";
import { parse, startOfMonth } from "date-fns";
import { useState } from "react";
import { AmountFilterTypeEnum } from "@kushki/connect-ui/dist/Components/Molecules/Filters/ItemCategory/constants";
import { WalletTransactionPath } from "../../../shared/enums/wallet_transaction_path";

export const useHeadFiltersDashboardState = (
  setSelectPage: (page: number) => void
): IUseHeaderFilterDashboard => {
  const dispatch: Dispatch<any> = useAppDispatch();
  const [ticketNumberSearch, setTicketNumber] = useState<string>("");
  const { walletTrxRequest, limit } = useAppSelector((state: RootState) => ({
    ...state.walletTransaction,
  }));
  const defaultStartDate: string = `${format(
    startOfMonth(new Date()),
    DateFormatStringEnum.PATTERN_ddMMyyyy
  )}`;
  const defaultEndDate: string = `${format(
    new Date(),
    DateFormatStringEnum.PATTERN_ddMMyyyy
  )}`;

  const handleChangeTextField = (value: string): void => {
    setTicketNumber(value);
  };

  const handleFilterChange = (filters: ItemCategoryProps[]): void => {
    const newFilter: TrxRequestFilter = pick(
      cloneDeep(get(walletTrxRequest, "filter", {})),
      "origin_ticket_number"
    );

    forEach(filters, (filter: ItemCategoryProps) => {
      if (!isEmpty(filter.items)) {
        switch (filter.placeHolder) {
          case FilterLabelEnum.STATUS:
            const selectedStatus: string[] = [];

            forEach(filter.items, (item: ItemCategoryItem) => {
              selectedStatus.push(STATUS_BY_LABEL[item.label]);
            });
            set(
              newFilter,
              WalletTransactionPath.TRX_STATUS,
              selectedStatus.join("|")
            );
            break;
          case FilterLabelEnum.OPERATION_TYPE:
            const selectedOperationTypes: string[] = [];

            forEach(filter.items, (item: ItemCategoryItem) => {
              selectedOperationTypes.push(OPERATION_TYPE_BY_LABEL[item.label]);
            });
            set(
              newFilter,
              WalletTransactionPath.OPERATION_TYPE,
              selectedOperationTypes.join("|")
            );
            break;
          case FilterLabelEnum.AMOUNT:
            const operator: string = get(filter, "items[0].value", "");
            const firstAmount: string = get(filter, "items[1].value", "");

            if (isEqual(operator, AmountFilterTypeEnum.BETWEEN)) {
              const secondAmount: string = get(filter, "items[2].value", "");

              merge(newFilter, {
                rangeAmount: {
                  max: Number(secondAmount),
                  min: Number(firstAmount),
                  type: AmountFilterFieldEnum.RANGE,
                },
              });
            } else {
              const filterType: string = FILTER_BY_AMOUNT_OPERATOR[operator];

              merge(newFilter, {
                rangeAmount: {
                  [filterType]: Number(firstAmount),
                  type: filterType,
                },
              });
            }
            break;
        }
      }
    });

    setSelectPage(1);
    dispatch(setPage(1));
    dispatch(setOffset(0));
    dispatch(
      setTrxRequest({
        ...walletTrxRequest,
        filter: {
          ...newFilter,
          origin_ticket_number: ticketNumberSearch,
        },
      })
    );
  };

  const handleRemovedFilters = (changes: TOnChangeFilters) => {
    const newFilter: TrxRequestFilter = cloneDeep(
      get(walletTrxRequest, "filter", {})
    );

    if (isEqual(changes.affected.title, FilterLabelEnum.AMOUNT))
      unset(newFilter, "rangeAmount");
    else {
      const removedLabel: string = changes.affected.itemDeleted.label;
      const isOperationType: boolean = isEqual(
        changes.affected.title,
        FilterLabelEnum.OPERATION_TYPE
      );
      const filterPath: string = isOperationType
        ? WalletTransactionPath.OPERATION_TYPE
        : WalletTransactionPath.TRX_STATUS;
      const newItems: string[] = get(newFilter, filterPath, "").split("|");

      remove(newItems, (item: string) =>
        isEqual(
          item,
          isOperationType
            ? OPERATION_TYPE_BY_LABEL[removedLabel]
            : STATUS_BY_LABEL[removedLabel]
        )
      );
      if (!isEmpty(newItems)) {
        set(newFilter, filterPath, newItems.join("|"));
      } else {
        unset(newFilter, filterPath);
      }
    }

    setSelectPage(1);
    dispatch(setPage(1));
    dispatch(setOffset(0));
    dispatch(
      setTrxRequest({
        ...walletTrxRequest,
        filter: newFilter,
      })
    );
  };

  const handleDateChange = (startDate: string, finishDate: string): void => {
    const filters: TrxRequestFilter = cloneDeep(
      get(walletTrxRequest, WalletTransactionPath.FILTER, {})
    );
    const searchStartDate: string = format(
      parse(startDate, DateFormatStringEnum.PATTERN_ddMMyyyy, new Date()),
      DateFormatStringEnum.PATTERN_yyyyMMdd
    );
    const searchEndDate: string = format(
      parse(finishDate, DateFormatStringEnum.PATTERN_ddMMyyyy, new Date()),
      DateFormatStringEnum.PATTERN_yyyyMMdd
    );

    set(filters, WalletTransactionPath.TICKET_NUMBER, ticketNumberSearch);
    const newFilters: object = {
      ...walletTrxRequest,
      filter: filters,
      from: `${searchStartDate}${DateFormatStringEnum.PATTERN_T000000}`,
      limit: limit,
      offset: 0,
      to: `${searchEndDate}${DateFormatStringEnum.PATTERN_T235959}`,
    };

    setSelectPage(1);
    dispatch(setPage(1));
    dispatch(setOffset(0));
    dispatch(setTrxRequest(newFilters));
  };

  const handleOperationIdChange = (value: string): void => {
    const newFilter: TrxRequestFilter = cloneDeep(
      get(walletTrxRequest, WalletTransactionPath.FILTER, {})
    );

    set(newFilter, WalletTransactionPath.TICKET_NUMBER, value);
    setSelectPage(1);
    dispatch(setPage(1));
    dispatch(setOffset(0));
    dispatch(
      setTrxRequest({
        ...walletTrxRequest,
        filter: newFilter,
        limit: limit,
        offset: 0,
      })
    );
  };

  return {
    filterProps: {
      filterDetailBar: {
        filters: [],
        onChangeFilters: handleRemovedFilters,
      },
      filterSideBar: {
        categoryItems: WALLET_FILTERS_ITEMS,
        filtersCount: 0,
        isMinimized: false,
        isOpen: true,
        onClick: handleFilterChange,
        title: HEAD_FILTERS_LABELS.filterBy,
      },
      rangeDatePicker: {
        dateFormat: DateFormatStringEnum.PATTERN_ddMMyyyy,
        defaultFinishDate: defaultEndDate,
        defaultStartDate: defaultStartDate,
        disabledFutureDate: true,
        disabledOldDate: false,
        isFilled: true,
        isOpen: false,
        onDateSelect: handleDateChange,
        placeholder: HEAD_FILTERS_LABELS.searchByDate,
      },
      textFieldSimpleSearch: {
        onChange: handleChangeTextField,
        onEnter: handleOperationIdChange,
        placeholder: HEAD_FILTERS_LABELS.searchByIdOperation,
      },
    },
  };
};
