export enum BillingTransactionTypeEnum {
  MINIMAL_COMMISSION = "minimalCommission",
  AUTOMATIC_COMMISSION = "automaticCommission",
  PAYOUT_COMMISSION = "payOutCommission",
  PAYIN_COMMISSION = "payInCommission",
  MANUAL_FROM_FILE = "manualFromFile",
  FROM_CONSOLE = "fromConsole",
}

export enum TransactionTypeLowerCaseEnum {
  automaticCommision = "automatic",
  minimalCommission = "minimal",
  payout = "payout",
  payin = "payin",
  threeDS = "3ds",
}

export enum TransactionTypeChipLabelEnum {
  automaticCommision = "Comisión automática",
  minimalCommission = "Mínimo comisional",
  payout = "Pay-out",
  payin = "Pay-in",
  threeDS = "3DS",
}

export enum LiquidationTypeEnum {
  NORMAL = "Normal",
  EXCLUSIVA = "Exclusiva",
}

export enum RechargeTypeEnum {
  TOTAL = "total",
  PARTIAL = "partial",
}
