import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./reducers/app/app";
import refundsReducer from "./reducers/refunds/refunds";
import transactionsReducer from "./reducers/transactions/transactions";

export const store = configureStore({
  devTools: process.env.NEXT_PUBLIC_ENV_NAME !== "primary",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    app: appReducer,
    refunds: refundsReducer,
    transactions: transactionsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
