import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const layoutStepContainerStyles = createNamedStyles({
  boxContainer: {
    width: 1,
    height: "100%",
    position: "fixed",
    display: "flex",
  },
  boxSidebar: {
    width: {
      md: "10%",
      sm: "30%",
    },
    height: 1,
    bgcolor: {
      md: "primary.light",
      sm: "secondary.main",
    },
  },
  box: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
  },
  boxHeader: {
    width: 1,
    heigth: {
      md: "10%",
      sm: "30%",
    },
    bgcolor: {
      md: "#B6C5EE",
      sm: "lightskyblue",
    },
    display: "flex",
    flexDirection: {
      sm: "column",
    },
  },
  headerStyle: {
    height: "100%",
    width: "100%",
    padding: "48px 64px 0px 64px",
  },
  boxFooter: {
    zIndex: "1000",
    width: "100%",
    position: "fixed",
    bottom: "0",
  },
});
