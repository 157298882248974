import React from "react";
import LegalDetailsBody from "../../components/LegalDetailsBody/LegalDetailBody";
import { get } from "lodash";
import { LayoutStepContainer } from "../LayoutStepContainer/LayoutStepContainer";
import { Routes } from "../../shared/constants/routes";
import { ModalLoader } from "@kushki/connect-ui";
import { Box } from "@mui/material";
import { useLegalData } from "../../shared/utils/state/useLegalData";
import {
  LOADER_DESCRIPTION,
  LOADER_TITLE,
} from "../../shared/constants/labels/ModalConstants";
import { basicStateContainerStyles as styles } from "../BasicFormStateContainer/BasicStateContainer.styles";
import { LegalContainerSkeleton } from "../../components/Skeletons/LegalContainerSkeleton/LegalContainerSkeleton";

const LegalDatailsContainer: React.FC = () => {
  const {
    countriesInfo: { country },
    footerLabel,
    headerProps,
    isEdit,
    primaryButton,
    secondaryButton,
    openModalLoader,
    isLoadingCompliancePeople,
    isCentralize,
    form,
  } = useLegalData();

  const publicMerchantId = get(headerProps.nodeInfo, "merchantId", "");

  return (
    <>
      <LayoutStepContainer
        headerProps={{
          apiUrl: `${Routes.CREATE_NODE}?publicMerchantId=${publicMerchantId}`,
          country: country,
          id: publicMerchantId,
          isEdit,
          subTitle: "País de operación: ",
          tabsInfo: headerProps.tabsNodeInfo,
          title: get(headerProps.nodeInfo, "generalInfo.name"),
        }}
        renderComponent={
          <Box sx={isEdit ? styles.boxContentEdit : styles.boxContent}>
            {isLoadingCompliancePeople ? (
              <LegalContainerSkeleton />
            ) : (
              <LegalDetailsBody
                isCentralize={isCentralize}
                isEdit={isEdit}
                form={form}
              />
            )}
          </Box>
        }
        footerProps={{
          isEdit,
          isLoading: false,
          label: footerLabel,
          primaryButton,
          secondaryButton,
        }}
      />
      <Box>
        <ModalLoader
          descriptionText={LOADER_DESCRIPTION}
          titleText={LOADER_TITLE}
          isOpen={openModalLoader}
        />
      </Box>
    </>
  );
};

export default LegalDatailsContainer;
