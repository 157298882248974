import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppState } from "../../interfaces/AppState.interfaces";
import { PaymentMethodEnum } from "../../../shared/enums/PaymentMethodEnum";
import { RequestStatusEnum } from "../../../shared/enums/RequestStatusEnum";
import { CreateExpressCheckoutResponse } from "../../../../types/create_express_checkout_response";

export const appInitialState: IAppState = {
  createExpressCheckoutStatus: RequestStatusEnum.PENDING,
  paymentMethods: [],
  paymentMethodsStatus: RequestStatusEnum.PENDING,
};

export const appSlice = createSlice({
  initialState: appInitialState,
  name: "app",
  reducers: {
    setCreateExpressCheckoutStatus: (
      state,
      action: PayloadAction<RequestStatusEnum>
    ) => {
      state.createExpressCheckoutStatus = action.payload;
    },
    setExpressCheckout: (
      state,
      action: PayloadAction<CreateExpressCheckoutResponse | undefined>
    ) => {
      state.expressCheckoutResponse = action.payload;
    },
    setIvaValues: (state, action: PayloadAction<object | undefined>) => {
      state.ivaValues = action.payload;
    },
    setPaymentMethods: (state, action: PayloadAction<PaymentMethodEnum[]>) => {
      state.paymentMethods = action.payload;
    },
    setPaymentMethodsStatus: (
      state,
      action: PayloadAction<RequestStatusEnum>
    ) => {
      state.paymentMethodsStatus = action.payload;
    },
  },
});

export default appSlice.reducer;
