import { KushkiBankInfo } from "../../../../types/filter_wallet_dashboard_response";
import { LabelsWallet } from "../enums/LabelWalletEnum";
import { CountryNameEnum } from "../enums/CountryEnum";
import { StatusEnum } from "../enums/StatusEnum";
import { IEmptyTableProps } from "../interfaces/EmptyTable.interface";
import { SearchTypeEnum } from "../enums/SearchTypeEnum";
import { IRangeAmount } from "../interfaces/IRangeAmount";
import { RangeAmountTypeEnum } from "../enums/RangeAmountTypesEnum";

export const kushkiBankInfoMX: KushkiBankInfo = {
  amount: "255",
  clabeAccount: "1212 1212 1212 1212",
  description: "Testing",
  recipientName: "Kushki IRL",
};

export enum WalletDashboardConstants {
  FILTER = "Filtrar",
  FILTER_BY = "Filtrar por",
  COLLAPSED = "Minimizar todos",
  EXPANDED = "Expandir todos",
  OPERATION_TYPE = "Tipo de operación",
  STATUS = "Estado",
  APPLY = "Aplicar",
  RESET = "Restablecer",
  DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss",
  AMOUNTS = "Montos",
}

export enum OperationType {
  R_KUSHKI = "Recarga - Kushki",
  R_LIQUIDATION = "Recarga - Liquidación",
  R_COMMERCE = "Recarga - Comercio",
  R_CASH_OUT = "Recarga - Cash out",
  R_TRANSFER_OUT = "Recarga - Transfer out",
  D_CASH_OUT = "Débito - Cash out",
  D_TRANSFER_OUT = "Débito - Transfer out",
  D_KUSHKI = "Débito - Kushki",
}

export enum AlertModalConstants {
  titleLbl = "Estamos generando tu reporte de recargas.",
  modalDescriptionLbl = "En breve recibirás el link de descarga en tu correo electrónico: ",
  errorDateRangeLbl = "El rango de fechas que deseas descargar excede los seis meses con base a la fecha actual. Por favor intenta con un rango de fechas más actual",
  errorDateRangeTitle = "Solicitud de reporte fuera de rango.",
  buttonLbl = "Aceptar",
}

export const EmptyFilterResultLabels: IEmptyTableProps = {
  title: "FILTRO SIN RESULTADOS",
  description: "No se encontraron resultados para el filtro seleccionado",
};

export const EmptyDateRangeResultLabels: IEmptyTableProps = {
  title: "BÚSQUEDA SIN RESULTADOS",
  description: "No se encontraron resultados en las fechas seleccionadas",
};

export const EmptyInputResultLabels = {
  title: "BÚSQUEDA SIN RESULTADOS",
  description: "No se encontraron resultados para ",
};

export const resultLabelsBySearchType = {
  [SearchTypeEnum.FILTER]: EmptyFilterResultLabels,
  [SearchTypeEnum.DATE_RANGE]: EmptyDateRangeResultLabels,
  [SearchTypeEnum.INPUT]: EmptyInputResultLabels,
};

export interface FilterItems {
  title: string;
  isSelected: boolean;
  value?: string;
}

export const headerTableItem: string[] = [
  LabelsWallet.DATE,
  "Tipo de operación",
  "ID de operación",
  "Monto",
  "Estado",
];

export const ACCORDION_ITEMS_COUNT = 3;

export const DEFAULT_ACCORDION = () => {
  const accordion = {};

  for (let i = 0; i < ACCORDION_ITEMS_COUNT; i++) {
    accordion[i] = false;
  }

  return accordion;
};

export const operationTypeItemsValues: FilterItems[] = [
  {
    title: OperationType.R_KUSHKI,
    isSelected: false,
  },
  {
    title: OperationType.R_LIQUIDATION,
    isSelected: false,
  },
  {
    title: OperationType.R_COMMERCE,
    isSelected: false,
  },
  {
    title: OperationType.D_CASH_OUT,
    isSelected: false,
  },
  {
    title: OperationType.D_TRANSFER_OUT,
    isSelected: false,
  },
  {
    title: OperationType.D_KUSHKI,
    isSelected: false,
  },
  {
    title: OperationType.R_CASH_OUT,
    isSelected: false,
  },
  {
    title: OperationType.R_TRANSFER_OUT,
    isSelected: false,
  },
];

export enum OperationTypeEnum {
  KUSHKI_RECHARGE = "Recarga Kushki",
  LIQUIDATION_RECHARGE = "Recarga Liquidación",
  COMMERCE_RECHARGE = "Recarga Comercio",
  CASH_OUT_RECHARGE = "Recarga Cash Out",
  TRANSFER_OUT_RECHARGE = "Recarga Transfer Out",
  CASH_OUT_DEBIT = "Débito Cash Out",
  TRANSFER_OUT_DEBIT = "Débito Transfer Out",
  KUSHKI_DEBIT = "Débito Kushki",
}

export const OPERATION_TYPE_BY_TITLE: Record<string, string> = {
  [OperationType.R_KUSHKI]: OperationTypeEnum.KUSHKI_RECHARGE,
  [OperationType.R_LIQUIDATION]: OperationTypeEnum.LIQUIDATION_RECHARGE,
  [OperationType.R_COMMERCE]: OperationTypeEnum.COMMERCE_RECHARGE,
  [OperationType.D_CASH_OUT]: OperationTypeEnum.CASH_OUT_DEBIT,
  [OperationType.D_TRANSFER_OUT]: OperationTypeEnum.TRANSFER_OUT_DEBIT,
  [OperationType.D_KUSHKI]: OperationTypeEnum.KUSHKI_DEBIT,
  [OperationType.R_CASH_OUT]: OperationTypeEnum.CASH_OUT_RECHARGE,
  [OperationType.R_TRANSFER_OUT]: OperationTypeEnum.TRANSFER_OUT_RECHARGE,
};

export const defaultFilterStatus: FilterItems[] = [
  {
    title: "Aprobado",
    value: StatusEnum.APPROVAL,
    isSelected: false,
  },
  {
    title: "Rechazado",
    value: StatusEnum.DECLINED,
    isSelected: false,
  },
];

export const mexicoFilterStatus: FilterItems[] = [
  {
    title: "Aprobado",
    isSelected: false,
    value: StatusEnum.APPROVAL,
  },
  {
    title: "Rechazado",
    isSelected: false,
    value: StatusEnum.DECLINED,
  },
];

export const statusFilterByCountry = {
  [CountryNameEnum.mexico]: mexicoFilterStatus,
  [CountryNameEnum.colombia]: defaultFilterStatus,
  [CountryNameEnum.chile]: defaultFilterStatus,
  [CountryNameEnum.peru]: defaultFilterStatus,
  [CountryNameEnum.ecuador]: defaultFilterStatus,
};

export const DEFAULT_RANGE_AMOUNT = (
  type: RangeAmountTypeEnum = RangeAmountTypeEnum.MIN
): IRangeAmount => ({
  type,
  min: "",
  max: "",
  value: "",
  eq: "",
});
