import { IStyles } from "../../shared/utils";

export const footerStyles: IStyles = {
  buttonContainer: {
    paddingLeft: { xs: "6%", sm: "6%", md: "6%", lg: "7%" },
  },
  buttonContainerOperationEdit: {
    paddingLeft: { xs: "19%", sm: "19%", md: "19%", lg: "20%" },
  },
  footer: {
    display: "flex",
    boxShadow: 3,
    justifyContent: "space-between",
    padding: {
      xs: "15px 25px 15px 25px",
      sm: "30px 50px 30px 50px",
    },
    backgroundColor: "#FAFCFD",
  },
  footerContainer: {
    position: "absolute",
    bottom: "0",
  },
  labelBox: {
    display: "inline-block",
    padding: { xs: "10px 20px 0px 0px", lg: "14px 10px 0px 0px" },
  },
  label: {
    color: "#64798D",
    fontSize: { xs: "15px", md: "13px" },
  },
  labelContainer: {
    paddingLeft: { sm: "3%", md: "15%", lg: "0%" },
  },
  saveButton: { marginRight: "50px" },
};
