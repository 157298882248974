import { createAsyncThunk } from "@reduxjs/toolkit";
import { WEBSOCKETS_ENDPOINTS } from "../../../../shared/constants/websockets_endpoints";
import { updateTransactionsStatusWS } from "../../../../shared/utils/websockets/updateTransactionsStatus/update_transactions_status_ws";
import { handleResponse } from "../updateTransactionsStatus/update_transactions_status";
import { UpdatePendingTransactions } from "../../../../../types/transaction_conciliation_update_request";
import { defaultTo, get } from "lodash";

const updatePendingTransaction = createAsyncThunk(
  "transactions/updatePendingTransactions",
  async (data: UpdatePendingTransactions, thunkAPI) => {
    const payload = JSON.parse(
      defaultTo(localStorage.getItem("payload"), "{}")
    );

    const request: UpdatePendingTransactions = {
      ...data,
      data: {
        ...data.data,
        observationInfo: {
          ...get(data, "data.observationInfo", {}),
          userName: get(payload, "cognito:username", ""),
        },
      },
    };

    return await updateTransactionsStatusWS(
      WEBSOCKETS_ENDPOINTS.updateTransactionsState,
      {
        data: request,
        dispatch: thunkAPI.dispatch,
        handleResponse,
      }
    );
  }
);

export default updatePendingTransaction;
