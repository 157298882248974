import React from "react";
import { TextField } from "@material-ui/core";
import { useTypedController } from "@hookform/strictly-typed";
import ConnectForm from "../../commons/ConnectForm/ConnectForm";
import { SecurityRulesLabelField } from "../../../shared/infrastructure/constants/SecurityRulesConstants";
import { get } from "lodash";
import { IBusinessRulesForm } from "../../../shared/infrastructure/interfaces/IBusinessRulesForm";
import { ErrorsEnum } from "../../../shared/infrastructure/enums/ErrorEnum";

export interface TextFieldComponentProps {
  disabled: boolean;
  type?: string;
  rules?: object;
}

export const TextFieldComponent: React.FC<TextFieldComponentProps> = (
  props: TextFieldComponentProps
) => {
  return (
    <ConnectForm<IBusinessRulesForm>>
      {({ control, errors }) => {
        const TypedController = useTypedController<IBusinessRulesForm>({
          control: control,
        });

        return (
          <React.Fragment>
            <TypedController
              name={"rulesInputValue"}
              render={(properties) => (
                <TextField
                  {...properties}
                  disabled={props.disabled}
                  id="value"
                  label={SecurityRulesLabelField.VALUE}
                  type={props.type}
                  variant="outlined"
                  error={!!get(errors, "rulesInputValue")}
                  fullWidth
                  inputProps={get(props, "rules", {})}
                  helperText={
                    !!get(errors, "rulesInputValue") &&
                    ErrorsEnum.REQUIRED_FIELD
                  }
                />
              )}
            />
          </React.Fragment>
        );
      }}
    </ConnectForm>
  );
};

export default TextFieldComponent;
