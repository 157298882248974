import { useEffect, useState } from "react";
import { INotification } from "../../../../store/reducer";
import { MessageDialogProps } from "../../../MessageDialog/MessageDialog.interfaces";
import axiosInstance from "../../../../shared/axios-util";
import { DomainByMerchant } from "../../../../shared/interfaces/DomainByMerchant";
import { environment } from "../../../../environments/environment";
import { isEmpty } from "lodash";
import { SaveCredential } from "../../../../../types/save_credential_request";
import { AliasCredentialEnum } from "../../../../shared/infrastructure/constants/AliasCredentialEnum";
import { PluginTypeEnum } from "../../../../shared/infrastructure/constants/PluginTypeEnum";
import { ChannelEnum } from "../../../../shared/infrastructure/constants/ChannelEnum";

const REGEX_VALID_DOMAIN = /^(?!https?:)(?:[\w-]+\.)+[a-z]{2,6}$/i;

const useVTexDomainState = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showBlurredText, setShowBlurredText] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [privateCredential, setPrivateCredential] = useState<string>();
  const [publicCredential, setPublicCredential] = useState<string>();
  const [executeSave, setExecuteSave] = useState<boolean>(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
  const [hasCredentials, setHasCredentials] = useState<boolean>(false);
  const [messageDialog, setMessageDialog] = useState<MessageDialogProps>({
    open: false,
  });
  const [vTexDomain, setVTexDomain] = useState("");
  const [isValid, setValid] = useState(false);
  const [blockSaveButton, setBlockSaveButton] = useState(false);
  const [update, setUpdate] = useState(false);
  const merchantId = localStorage.getItem("merchantId")!;
  const [notification, setNotification] = useState<INotification>({
    message: "",
    type: "error",
    open: false,
  });

  const handleOnCloseNotification = () => {
    setNotification({
      message: "",
      type: notification.type,
      open: false,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (isEmpty(publicCredential) || isEmpty(privateCredential)) {
      try {
        const credentialsRequest = {
          alias: AliasCredentialEnum.VTEX,
          merchantId: merchantId,
          hidden: false,
        };

        const credentialsResponse = await axiosInstance.post(
          `${environment.kushkiUrl}/payment-credentials/v1/admin/credentials`,
          credentialsRequest
        );

        setPrivateCredential(credentialsResponse.data.privateCredentialId);
        setPublicCredential(credentialsResponse.data.publicCredentialId);
        setExecuteSave(true);
      } catch (_error) {
        setExecuteSave(false);
        setNotification({
          message:
            "Ha ocurrido un error al guardar las credenciales. Vuelve a intentarlo.",
          open: true,
          type: "error",
        });
      }
    } else {
      setExecuteSave(true);
    }
  };

  useEffect(() => {
    if (executeSave) {
      const save = async () => {
        setBlockSaveButton(true);
        const request: SaveCredential = {
          domain: vTexDomain,
          merchantId: merchantId,
          pluginType: PluginTypeEnum.VTEX,
        };
        await axiosInstance.post(
          `${environment.kushkiUrl}/plugins/v1/saveCredentials`,
          update
            ? request
            : {
                ...request,
                privateKey: privateCredential,
                publicKey: publicCredential,
              }
        );

        update
          ? setNotification({
              message: "Tu dominio se ha actualizado con éxito.",
              open: true,
              type: "success",
            })
          : setShowSuccessDialog(true);
        setIsSaved(true);
        setLoading(false);
        setBlockSaveButton(true);
        setHasCredentials(true);
        setUpdate(true);
      };

      save().catch((error) => {
        setLoading(false);
        const messageError = (error as {
          response?: {
            data?: {
              message: string;
            };
          };
        }).response?.data?.message;
        if (
          messageError &&
          messageError ===
            "Este Dominio se encuentra vinculado a otro comercio."
        ) {
          setMessageDialog({
            description:
              "Este dominio ya es utilizado por otro comercio. Por favor intenta ingresar otro.",
            onClose: () => {
              setMessageDialog({
                open: false,
              });
            },
            open: true,
            title: "Validación de dominio",
          });
          setBlockSaveButton(false);
        } else {
          setNotification({
            message:
              "Ha ocurrido un error al guardar el dominio. Vuelve a intentarlo.",
            open: true,
            type: "error",
          });
          setBlockSaveButton(true);
          setTimeout(() => {
            setBlockSaveButton(false);
          }, 3000);
        }
      });
      setExecuteSave(false);
    }
  }, [executeSave]);

  const handleChangeText: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    setVTexDomain(e.target.value);
  };

  const handleOnClikConfigurateButton = async () => {
    window.open(
      `https://${vTexDomain}/admin/pci-gateway/#/affiliations/Vtex.PaymentGateway.Connectors.PaymentProvider.PaymentProviderConnector${environment.connectorName}`,
      "_blank"
    );
  };

  const handleClickShowBlurredText = () => {
    setShowBlurredText(!showBlurredText);
  };

  const handleCloseSuccessDialog = () => {
    setShowSuccessDialog(false);
  };

  const handleChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    setBlockSaveButton(false);
    setVTexDomain(e.target.value);
    setValid(REGEX_VALID_DOMAIN.test(e.target.value));
    setIsSaved(false);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await axiosInstance.get<DomainByMerchant[]>(
          `${environment.kushkiUrl}/plugins/v1/getDomainsByMerchant/${merchantId}`
        );
        const domainByMerchant = data.find(
          (d) => d.channel === ChannelEnum.VTEX
        );

        if (domainByMerchant) {
          setVTexDomain(domainByMerchant.domain);
          setPublicCredential(domainByMerchant.publicKey);
          setPrivateCredential(domainByMerchant.privateKey);
          setHasCredentials(
            Boolean(domainByMerchant.privateKey) &&
              Boolean(domainByMerchant.publicKey)
          );
          setBlockSaveButton(true);
        }

        setValid(Boolean(domainByMerchant));
        setIsSaved(Boolean(domainByMerchant));
        setUpdate(Boolean(domainByMerchant));
      } catch {
        setValid(false);
        setIsSaved(false);
        setNotification({
          message: "Ha ocurrido un error en el sistema. Vuelve a intentarlo.",
          open: true,
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    })();
    setMessageDialog({ open: false });
  }, []);
  return {
    blockSaveButton,
    handleChange,
    handleChangeText,
    handleClickShowBlurredText,
    handleCloseSuccessDialog,
    handleOnClikConfigurateButton,
    handleOnCloseNotification,
    handleSubmit,
    hasCredentials,
    isSaved,
    isValid,
    loading,
    messageDialog,
    notification,
    privateCredential,
    publicCredential,
    showBlurredText,
    showSuccessDialog,
    update,
    vTexDomain,
  };
};

export default useVTexDomainState;
