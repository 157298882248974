import React from "react";
import { Box, Chip, Skeleton } from "@mui/material";
import { CellTagStyles } from "./TagCell.styles";

interface ITagsCell {
  tags: string[];
}

export const TagsCell = (props: ITagsCell) => {
  const { tags } = props;

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: "8px",
        padding: "4px",
      }}
    >
      {tags.map((tag, index) => (
        <Chip key={index} label={tag} size="small" color="primary" />
      ))}
    </Box>
  );
};

export const TagsCellSkeleton = () => {
  return (
    <Skeleton
      data-testid="tags-cell-skeleton"
      variant="rectangular"
      animation="wave"
      sx={CellTagStyles.skeleton}
    />
  );
};
