import { SourceEnum } from "@shared/utils/activeAlarmsEnum";
import { EntityName } from "@shared/enum/entityNameEnum";

export const EntityNameBySource: Record<SourceEnum, string[]> = {
  [SourceEnum.ALARMS]: [EntityName.BRANCH, EntityName.NA],
  [SourceEnum.HISTORY]: [
    EntityName.OWNER,
    EntityName.CUSTOMER,
    EntityName.BRANCH,
    EntityName.NA,
  ],
};
