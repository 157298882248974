import { ActionTypes } from "./actionTypes";
import { IAppAction } from "./actionCreators";
import { IAppState } from "../shared/infrastructure/interfaces/IAppState";
import { DEFAULT_SIMPLE_MODAL, DEFAULT_LOADING_MODAL } from "./defaultStates";

export const INITIAL_STATE: IAppState = {
  isRequest: false,
  requestError: false,
  loadingModal: DEFAULT_LOADING_MODAL,
  saveRuleModal: DEFAULT_SIMPLE_MODAL,
  processors: [],
  configList: {},
  newRule: {},
  geographyList: {},
  countries: [],
  suggestCredentials: [],
  customerCredentials: [],
  businessRules: [],
  openAlert: { open: false, isError: false },
  nodeInfoMerchant: { configCoreId: "" },
  parentMerchantId: "",
  searchFilterRulesResponse: {
    data: [],
    total: 0,
  },
};

export const reducer = (
  state: IAppState = INITIAL_STATE,
  action: IAppAction
): IAppState => {
  switch (action.type) {
    case ActionTypes.SET_IS_REQUESTING:
      return {
        ...state,
        isRequest: action.isRequest,
      };
    case ActionTypes.SET_REQUEST_ERROR:
      return {
        ...state,
        requestError: action.requestError,
      };
    case ActionTypes.SET_LOADING_MODAL:
      return {
        ...state,
        loadingModal: action.loadingModal,
      };

    case ActionTypes.SET_SAVE_RULE_MODAL:
      return {
        ...state,
        saveRuleModal: action.saveRuleModal,
      };
    case ActionTypes.GET_LIST_PROCESSORS:
      return {
        ...state,
        processors: action.processors,
      };
    case ActionTypes.GET_CONFIG_LIST:
      return {
        ...state,
        configList: action.configList,
      };
    case ActionTypes.SET_NEW_RULES:
      return {
        ...state,
        newRule: action.newRule,
      };
    case ActionTypes.GET_DETAIL_RULES:
      return {
        ...state,
        getRuleById: action.getRuleById,
      };
    case ActionTypes.GET_GEOGRAPHY_LIST:
      return {
        ...state,
        geographyList: action.geographyList,
      };
    case ActionTypes.SET_CURRENCY:
      return {
        ...state,
        currencyByCountry: action.currencyByCountry,
      };
    case ActionTypes.SET_COUNTRY_RULES:
      return {
        ...state,
        countries: action.countries,
      };
    case ActionTypes.GET_SUGGEST_CREDENTIALS:
      return {
        ...state,
        suggestCredentials: action.suggestCredentials,
      };
    case ActionTypes.GET_CUSTOMER_CREDENTIALS:
      return {
        ...state,
        customerCredentials: action.customerCredentials,
      };
    case ActionTypes.SET_SEARCH_REQUEST_RULES_RESPONSE:
      return {
        ...state,
        searchRequestRulesResponse: action.searchRequestRulesResponse,
      };
    case ActionTypes.SET_OPEN_MODAL_INFO:
      return {
        ...state,
        openModalInfo: action.openModalInfo,
      };
    case ActionTypes.SET_IS_LOADING_RULES:
      return {
        ...state,
        isLoadingRules: action.isLoadingRules,
      };
    case ActionTypes.SET_IS_LOADING_ONE_RULE:
      return {
        ...state,
        isLoadingOneRule: action.isLoadingOneRule,
      };
    case ActionTypes.SET_SEARCH_REQUEST_BY_ID:
      return {
        ...state,
        requestRule: action.requestRule,
      };
    case ActionTypes.SET_BUSINESS_RULES:
      return {
        ...state,
        businessRules: action.businessRules,
      };
    case ActionTypes.SET_OPEN_ALERT:
      return {
        ...state,
        openAlert: action.openAlert,
      };
    case ActionTypes.SET_OPEN_CONFIRM_MODAL:
      return {
        ...state,
        openConfirmModal: action.openConfirmModal,
      };
    case ActionTypes.SET_NODE_INFO:
      return {
        ...state,
        nodeInfoMerchant: action.nodeInfoMerchant,
      };
    case ActionTypes.SET_PARENT_MERCHANT_ID:
      return {
        ...state,
        parentMerchantId: action.parentMerchantId,
      };
    case ActionTypes.SET_ATOMIC_ID:
      return {
        ...state,
        atomicId: action.atomicId,
      };
    case ActionTypes.SET_PREFIX_ATOMIC_COUNTER:
      return {
        ...state,
        prefixAtomicCounter: action.prefixAtomicCounter,
      };
    case ActionTypes.SET_SUCCESS_ENABLE_RULE:
      return {
        ...state,
        successEnableRule: action.successEnableRule,
      };
    case ActionTypes.SET_SEARCH_FILTER_RULES_RESPONSE:
      return {
        ...state,
        searchFilterRulesResponse: action.searchFilterRulesResponse,
      };
    default:
      return state;
  }
};
