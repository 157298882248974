import React, { FC } from "react";
import { FormControl, MenuItem, TextField } from "@mui/material";
import { ISelectItemCompleteProps } from "./SelectItemComplete.interfaces";
import { selectItemStyles } from "./SelectItemComplete.styles";
import { ICountry } from "../../shared/constants/Countries";
import FlagDefault from "../../assets/icons/FlagDefault";

const SelectItemComplete: FC<ISelectItemCompleteProps> = ({
  items,
  label,
  value,
  disabled,
  onChange,
  error,
  helperText,
}: ISelectItemCompleteProps) => {
  return (
    <FormControl
      sx={selectItemStyles.selectContainer}
      id="country-form"
      variant="outlined"
      fullWidth
    >
      <TextField
        label={label}
        value={value}
        disabled={disabled}
        select
        fullWidth
        helperText={helperText}
        error={error}
        onChange={(e) => onChange!(e.target.value)}
      >
        {items.map((country: ICountry) => (
          <MenuItem key={country.value} value={country.value}>
            {country.flag === "empty" ? (
              <FlagDefault fontSize="small" />
            ) : (
              <img
                src={country.flag}
                width="24px"
                height="24px"
                alt={country.flag}
              />
            )}
            {country.name}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};

export default SelectItemComplete;
