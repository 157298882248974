/**
 * CatalogsEnum
 */
export enum CatalogsEnum {
  BankAccountTypes = "sBankAccountTypes",
  Banks = "sBanks",
  Currency = "sCurrency",
  IdTypes = "sIdTypes",
  PersonTypes = "sPersonTypes",
  Provinces = "sProvinces",
  Cities = "sCities",
  TaxPayer = "sTaxPayer",
  Activity = "sActivity",
  ConceptRetentionRent = "sConceptRetentionRent",
  Regime = "sRegime",
  Obligations = "sObligations",
  Departments = "sDepartments",
  BillingCountry = "sBillingCountry",
  GeneralActivity = "sGeneralActivity",
}
