import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const modalInfoExecutivesStyles = createNamedStyles({
  textUnderline: {
    textDecoration: "underline",
  },
  listWrapper: {
    "& li": {
      paddingBottom: "5px",
    },
  },
});
