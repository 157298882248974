import { UseFormReturn } from "react-hook-form";
import { useStyles as styles } from "./BillingDataFormCountries.styles";
import React from "react";
import { Grid, MenuItem } from "@mui/material";

import { get } from "lodash";
import { BillingDataFormLabelsEnum } from "../../shared/enums/BillingDataFormLabelsEnum";
import { ErrorBillingData } from "../../shared/enum/BasicStateMX/ErrorMessageMX";
import { FieldsEnum } from "../../shared/enums/FieldsEnum";
import PhoneInputField from "../commonInputs/PhoneInputField/PhoneInputField";
import AddressInputField from "../commonInputs/AddressInputField/AddressInputField";
import { CountryEnum } from "../../shared/countryEnum";
import SelectInputField from "../commonInputs/SelectInputField/SelectInputField";
import { MerchantCompanyRequest } from "../../../types/merchant_company_request";
import { Category } from "../../shared/constants/information_constants";
import { CatalogEl } from "../../shared/services/catalog-el";
import { CatalogsEnum } from "../../shared/interfaces/catalogs-enum";
import { ZipCodeComponent } from "../ZipCodeComponent/ZipCodeComponent";

export type formProps = {
  cityElSalvadorCatalog: Category[];
  form: UseFormReturn<MerchantCompanyRequest>;
  infoBilling: {
    city: string;
    constitutionalCountry: string;
    country: string;
    municipality: string;
    populationCode: string;
    province: string;
    satCity: string;
    satMunicipality: string;
    satPopulationCode: string;
    satProvince: string;
    satStateCode: string;
    stateCode: string;
    useSameInfoSat: boolean;
  };
  isCentralizedNode: boolean;
};

const BillingDataFormCountriesES: React.FC<formProps> = (props: formProps) => {
  const classes = styles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SelectInputField
            name={FieldsEnum.province}
            control={props.form.control}
            errors={props.form.formState.errors}
            defaultValue={props.form.watch(FieldsEnum.province)}
            rules={{ required: ErrorBillingData.DEPARTAMENT }}
            label={BillingDataFormLabelsEnum.department}
            itemsSelect={get(CatalogEl, `${CatalogsEnum.Provinces}`, []).map(
              (item: Category) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.name}
                </MenuItem>
              )
            )}
            disabled={props.isCentralizedNode}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInputField
            name={FieldsEnum.city}
            control={props.form.control}
            errors={props.form.formState.errors}
            defaultValue={props.form.watch(FieldsEnum.city)}
            rules={{ required: ErrorBillingData.CITY_ES }}
            label={BillingDataFormLabelsEnum.municipality}
            itemsSelect={props.cityElSalvadorCatalog.map((item: Category) => (
              <MenuItem key={item.value} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
            disabled={props.isCentralizedNode}
          />
        </Grid>
        <Grid item xs={6}>
          <ZipCodeComponent
            {...props.form}
            watch={props.form.watch}
            country={CountryEnum.el_salvador}
            control={props.form.control}
            errors={props.form.formState.errors}
            disabled={props.isCentralizedNode}
          />
        </Grid>
        <Grid item xs={6}>
          <AddressInputField
            control={props.form.control}
            errors={props.form.formState.errors}
            disabled={props.isCentralizedNode}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.phone}>
        <Grid item xs={12}>
          <PhoneInputField
            fieldName={FieldsEnum.phoneNumber}
            labelName={BillingDataFormLabelsEnum.merchant_phone}
            control={props.form.control}
            errors={props.form.formState.errors}
            watch={props.form.watch}
            rules={{
              required: { message: ErrorBillingData.PHONE_NUMBER_EMPTY },
            }}
            country={props.infoBilling.constitutionalCountry as CountryEnum}
            countryCustomer={props.infoBilling.country as CountryEnum}
            disabled={props.isCentralizedNode}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default BillingDataFormCountriesES;
