import React, { FC } from "react";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import {
  IRadioGroupProps,
  RadioInfoItems,
} from "./RadioGroupComponent.interfaces";
import { useRadioButton } from "./useState/useRadioButton";
import { radioGroupComponentStyles } from "./RadioGroupComponent.styles";
import { Controller } from "react-hook-form";

const RadioGroupComponent: FC<IRadioGroupProps> = (props: IRadioGroupProps) => {
  const { items } = useRadioButton(props);

  return (
    <React.Fragment>
      <Controller
        name={props.groupName!}
        control={props.control}
        render={({ onChange, value }) => (
          <RadioGroup
            row
            value={value}
            key={`rg_${props.name}`}
            name={props.name}
            onChange={onChange}
          >
            {items.map((radio: RadioInfoItems, index: number) => {
              const jsxLabel: JSX.Element = (
                <Typography sx={radioGroupComponentStyles.label}>
                  {radio.label}
                </Typography>
              );

              return (
                <FormControlLabel
                  key={`formControl_${index}`}
                  value={radio.value}
                  label={jsxLabel}
                  control={
                    <Radio
                      key={`radio_${index}`}
                      sx={radioGroupComponentStyles.radio}
                      disabled={radio.disable}
                    />
                  }
                />
              );
            })}
          </RadioGroup>
        )}
      />
    </React.Fragment>
  );
};

export default RadioGroupComponent;
