import { MenuItem, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import React, { FC } from "react";
import { IInputsFormProps } from "../InputsFormProps.interfaces";
import { ERROR_MESSAGES } from "../../../shared/constants/inputFormConstants";
import { defaultTo, get } from "lodash";

const SelectItemsInput: FC<IInputsFormProps> = ({
  name,
  control,
  controlName = name,
  errors,
  label,
  defaultValue,
  items,
  sx = {},
  isRequired,
  onChange,
}: IInputsFormProps): JSX.Element => {
  return (
    <Controller
      name={name}
      rules={{ ...(isRequired && { required: ERROR_MESSAGES.required }) }}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField
          {...field}
          onChange={(event) => {
            onChange && onChange();
            field.onChange(event);
          }}
          select
          variant="outlined"
          size="small"
          fullWidth
          label={label}
          error={get(errors, controlName) !== undefined}
          helperText={get(errors, controlName)?.message ?? ""}
          sx={sx}
        >
          {defaultTo(items, []).map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.text}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};

export default SelectItemsInput;
