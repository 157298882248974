import React from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { snackbarSmartlinkActiveStyles } from "./SnackbarSmartlinkActive.styles";

export const SnackbarSmartlinkActive: React.FC = () => {
  const classes = snackbarSmartlinkActiveStyles();
  return (
    <Grid>
      <Box className={classes.container}>
        <IconButton className={classes.iconButton}>
          <InfoOutlinedIcon></InfoOutlinedIcon>
        </IconButton>
        <Typography className={classes.text}>
          Esta etiqueta no funcionará en transacciones que usen{" "}
          <strong>smartLink</strong>, OTP y 3DS
        </Typography>
      </Box>
    </Grid>
  );
};
