import { get } from "lodash";
import { LayoutSavedState } from "../interfaces/layout/layout.interfaces";

export const DEFAULT_VALUES = {
  customerEntityName: "",
  customerName: "",
  entityName: "",
  nodeName: "",
  origin: "createNode",
  section: "",
};

const getBasicMerchantInformation = () =>
  JSON.parse(sessionStorage.getItem("basicMerchantInformation") ?? "{}");

export const getSessionStorageValues = () =>
  get(getBasicMerchantInformation(), "layout", DEFAULT_VALUES);

export const setSessionStorageValues = (
  newValues: Partial<LayoutSavedState>
) => {
  const currentValues = getSessionStorageValues();

  const layoutUpdateValues = {
    ...currentValues,
    ...newValues,
  };

  sessionStorage.setItem(
    "basicMerchantInformation",
    JSON.stringify({
      ...getBasicMerchantInformation(),
      layout: layoutUpdateValues,
    })
  );
};

export const getSessionStorageValueByPath = (path: string) =>
  JSON.parse(get(sessionStorage, path, "{}"));

export const setLocalStorage = (newValues: object, path: string) => {
  const currentValues = getSessionStorageValueByPath(path);

  const layoutUpdateValues = {
    ...currentValues,
    ...newValues,
  };

  sessionStorage.setItem(path, JSON.stringify(layoutUpdateValues));
};
