import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  makeStyles,
  Popover,
  Typography,
} from "@material-ui/core";
import { IHeaderTable } from "../../shared/infrastructure/headers/defaultHeaders";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: "100%",
    },
    formLabel: {
      marginBottom: "25px",
    },
    resetButton: {
      width: "130px",
      height: "44px",
      marginTop: "18px",
    },
    applyButton: {
      width: "130px",
      height: "44px",
      marginTop: "18px",
      marginLeft: "24px",
    },
  })
);

export interface SelectableColumnsProps {
  openColumnsSelector: boolean;
  idButtonColumnsSelector: string | undefined;
  anchorElement: HTMLButtonElement | null;
  selectedColumns: string[];
  handleCloseColumns: (_event: unknown) => void;
  handleChangeColumnCheck: (event: React.BaseSyntheticEvent) => void;
  handleRestoreColumns: () => void;
  selectableColumns: IHeaderTable[];
  maxSelection: number;
  handleDisplayedColumn: () => void;
  origin: string;
}

const SelectableColumns = (props: SelectableColumnsProps) => {
  const selections: object = {
    ["transactions"]: {
      maxSelection: props.maxSelection,
      selectedColumns: props.selectedColumns?.length,
    },
    ["summary"]: {
      maxSelection: props.maxSelection,
      selectedColumns: props.selectedColumns?.length,
    },
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <Popover
        PaperProps={{
          style: { width: "30%" },
        }}
        id={props.idButtonColumnsSelector}
        open={props.openColumnsSelector}
        anchorEl={props.anchorElement}
        onClose={props.handleCloseColumns}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Card className={classes.root}>
          <CardContent>
            <Box component="div" sx={{ overflow: "auto", height: "330px" }}>
              <Grid container direction="column">
                <Grid item>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      <Typography variant="h4" color="primary">
                        Elige {selections[props.origin]?.maxSelection} columnas
                        para mostrar
                      </Typography>
                    </FormLabel>
                    <FormLabel component="legend" className={classes.formLabel}>
                      <Typography variant="h6" color="textSecondary">
                        Columnas seleccionadas{" "}
                        {selections[props.origin]?.selectedColumns} de{" "}
                        {selections[props.origin]?.maxSelection}
                      </Typography>
                    </FormLabel>
                    <FormGroup aria-label="position">
                      {props.selectableColumns.map(
                        (column) =>
                          !column.isCustom && (
                            <FormControlLabel
                              value={column.id}
                              key={column.id}
                              control={
                                <Checkbox
                                  onChange={props.handleChangeColumnCheck}
                                  name={column.id}
                                  color="primary"
                                  checked={props.selectedColumns.includes(
                                    column.id
                                  )}
                                />
                              }
                              label={column.label}
                              labelPlacement="end"
                              disabled={
                                props.selectedColumns.length >=
                                  props.maxSelection &&
                                !props.selectedColumns.includes(column.id)
                              }
                            />
                          )
                      )}
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ textAlign: "right" }}>
              <Button
                variant="outlined"
                onClick={props.handleRestoreColumns}
                className={classes.resetButton}
              >
                Restablecer
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={props.selectedColumns.length !== props.maxSelection}
                className={classes.applyButton}
                onClick={props.handleDisplayedColumn}
              >
                Aplicar
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Popover>
    </React.Fragment>
  );
};

export default SelectableColumns;
