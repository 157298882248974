import { useEffect, useState } from "react";
import { get, isEmpty, isUndefined } from "lodash";
import { CategoryData, OptionsSelectSearchBy } from "@kushki/connect-ui";
import { ItemsCategoryData } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectSearchBy/SelectSearchBy.interfaces";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store/reducer";
import { Category } from "../../../../../shared/infrastructure/interfaces/Category";
import { GetRuleByIdResponse } from "../../../../../../types/get_rule_by_id";
import { SearchRequestRuleByIdResponse } from "../../../../../../types/search_request_rule_by_id_response";

export interface IUseCredentialComponentSectionState {
  selectValue: string;
  setSelectValue: (value: string) => void;
  handleChange: (event: ItemsCategoryData) => void;
  listHierarchyCredentialItems: CategoryData[];
  defaultValueSelect: OptionsSelectSearchBy | undefined;
}

export interface IUseCredentialComponentSectionProps {
  selectedValue: string;
  setTextValue: (value: string[]) => void;
}

export const useCredentialComponentSection = (
  props: IUseCredentialComponentSectionProps
): IUseCredentialComponentSectionState => {
  const [selectValue, setSelectValue] = useState<string>(props.selectedValue);
  const [defaultValueSelect, setDefaultValueSelect] = useState<
    OptionsSelectSearchBy | undefined
  >(undefined);

  const [listHierarchyCredentialItems, setListHierarchyCredentialItems] =
    useState<CategoryData[]>([]);

  const searchRuleById: GetRuleByIdResponse | undefined = useSelector(
    (state: IAppState) => state.searchRuleById
  );

  const searchRequestRuleById: SearchRequestRuleByIdResponse | undefined =
    useSelector((state: IAppState) => state.searchRequestRuleById);

  const hierarchyCredentials: Category[] = useSelector((state: IAppState) =>
    get(state, "credentials", [])
  );

  const handleChange = (event: ItemsCategoryData) => {
    setSelectValue(get(event, "text", ""));
    props.setTextValue([get(event, "text", "")]);
  };

  const mapHierarchyCredentials = () => {
    const categoryData: CategoryData[] = [];
    const categoryDataItems: ItemsCategoryData[] = [];
    hierarchyCredentials.forEach((credential: Category) => {
      categoryDataItems.push({
        secondaryText: credential.name,
        text: get(credential, "value", "").toString(),
      });
    });
    categoryData.push({
      categoryId: "",
      categoryName: "",
      items: categoryDataItems,
    });
    setListHierarchyCredentialItems(categoryData);
  };

  useEffect(() => {
    if (!isUndefined(searchRuleById) || !isUndefined(searchRequestRuleById)) {
      const categoryData: CategoryData[] = [];
      const credential: string = !isUndefined(searchRuleById)
        ? get(searchRuleById, "rule.rule.transactionCredential", "").split(
            "|"
          )[1]
        : get(searchRequestRuleById, "rule.transactionCredential", "").split(
            "|"
          )[1];
      const item: OptionsSelectSearchBy = {
        categoryName: "",
        id: credential,
        categoryId: "",
        text: credential,
      };

      categoryData.push({
        categoryId: "",
        categoryName: "",
        items: [item],
      });
      setListHierarchyCredentialItems(categoryData);
      setDefaultValueSelect(item);
    } else {
      mapHierarchyCredentials();
    }

    if (!isEmpty(props.selectedValue)) {
      props.setTextValue([props.selectedValue]);
    }
  }, [searchRuleById, searchRequestRuleById]);

  return {
    selectValue,
    setSelectValue,
    handleChange,
    listHierarchyCredentialItems,
    defaultValueSelect,
  };
};
