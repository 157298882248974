import { Chip } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../shared/axios-util";
import {
  setMerchantCardInformation,
  setMerchantCardInfoSandbox,
  setMerchantCommission,
  setMerchantSettingsResponse,
  setServiceSubscriptionData,
  setSmartlinkMerchantData,
  setVposMerchantData,
} from "../../../store/actions/actions";
import {
  selectMerchantCardInfoSandbox,
  selectMerchantCommission,
  selectMerchantSettingsInfo,
  selectNodeInfo,
  selectServiceConfiguration,
  selectServiceSubscription,
  selectSmartlinkMerchant,
  selectVposMerchant,
} from "../../../store/selectors/selectors";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { CreditCardDict } from "../../../shared/constants/service_configuration";
import { ServiceConfigurationItems } from "../../../shared/enums/service_configuration";
import { selectMerchantCardInfo } from "../../../store/selectors/selectors";
import {
  Item,
  IUseServiceConfiguration,
} from "./useServiceConfiguration.interface";
import { IGetServiceSubscriptionResponse } from "../../../../types/get_service_subscription_response";
import { IGetMerchantSettingsDataResponse } from "../../../../types/get_merchant_settings_data_response";
import { IGetSmartlinkMerchantResponse } from "../../../../types/get_smartlink_merchant_response";
import { IGetVposMerchantResponse } from "../../../../types/get_vpos_merchant_response";
import { GetPaymentCredentialResponse } from "../../../../types/get_payment_credentials_response";
import { useHistory, useLocation } from "react-router";
import { routes } from "../../../shared/infrastructure/constants/routes";
import { IGetMerchantCardInfoResponse } from "../../../../types/get_merchant_card_info_response";
import { IGetMerchantCommissionInfoResponse } from "../../../../types/get_merchant_commission_info_response";
import {
  merchantInfoLocalStorage,
  setLocalStorageObject,
} from "../../../shared/utils/localStorageUtils";
import { get, isNil } from "lodash";
import { OriginEnum } from "../../../shared/enums/originEnum";
import { EntityNameEnum } from "../../../shared/enums/entityName";
import { QueryParamsEnum } from "../../../shared/enums/queryParamsEnum";
import { ServiceConfigurationEnums } from "../../../shared/enums/ServiceConfigurationEnums";

export const useServiceConfiguration = (): IUseServiceConfiguration => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { isCentralized, publicMerchantId } = useSelector(
    selectServiceConfiguration
  );
  const merchantSettingsData = useSelector(selectMerchantSettingsInfo);
  const smartlinkMerchant = useSelector(selectSmartlinkMerchant);
  const vposMerchantData = useSelector(selectVposMerchant);
  const merchantCardInfo = useSelector(selectMerchantCardInfo);
  const merchantCardInfoSandbox = useSelector(selectMerchantCardInfoSandbox);
  const serviceSubscriptionInfo = useSelector(selectServiceSubscription);
  const nodeInfo = useSelector(selectNodeInfo);
  const merchantCommission = useSelector(selectMerchantCommission);
  const publicMerchantIdForSandbox = searchParams.get(
    QueryParamsEnum.PUBLIC_MERCHANT_ID
  );
  const [isSettingsAvailable, setIsSettingAvailable] = useState<boolean>(true);
  const [isCustomer, setIsCustomer] = useState<boolean>(false);
  const [paymentPublicCredentialId, setPaymentPublicCredentialId] = useState<
    string | undefined
  >();

  const [activeServices, setActiveServices] = useState<string[]>([]);

  const [items, setItems] = useState<Array<Item>>([]);

  const location = useLocation();
  const getPaymentCredentials = async () => {
    try {
      const { data } = await axiosInstance.get<GetPaymentCredentialResponse>(
        `${API_ROUTES.PAYMENT_CREDENTIALS}/${publicMerchantId}`
      );

      const enabledCredential = data.find((credential) => credential.enable);

      if (enabledCredential) {
        setPaymentPublicCredentialId(enabledCredential.publicCredentialId);
      }
    } catch (error) {}
  };

  const getMerchantSettingsAdmin = async () => {
    try {
      const { data } =
        await axiosInstance.get<IGetMerchantSettingsDataResponse>(
          `${API_ROUTES.MERCHANT_SETTINGS_ADMIN}/${publicMerchantId}`
        );

      dispatch(setMerchantSettingsResponse(data));
    } catch (error) {}
  };

  const getSmartLinkData = async () => {
    try {
      const { data } = await axiosInstance.get<IGetSmartlinkMerchantResponse>(
        `${API_ROUTES.SMARTLINK}/${publicMerchantId}`
      );

      dispatch(setSmartlinkMerchantData(data));
    } catch (error) {}
  };

  const getVposMerchantData = async () => {
    try {
      const { data } = await axiosInstance.get<IGetVposMerchantResponse>(
        `${API_ROUTES.VPOS_MERCHANT}/${publicMerchantId}`
      );

      dispatch(setVposMerchantData(data));
    } catch (error) {}
  };

  const getSubscriptionData = async () => {
    const { data } = await axiosInstance.get<IGetServiceSubscriptionResponse>(
      `${API_ROUTES.SUBSCRIPTIONS}/${publicMerchantId}`
    );

    dispatch(setServiceSubscriptionData(data));
  };

  const getMerchantCardInfo = async (isCentralizedBranch?: boolean) => {
    const { data } = await axiosInstance.get<IGetMerchantCardInfoResponse>(
      `${API_ROUTES.MERCHANT_CARD_INFO}/${
        isCentralizedBranch ? publicMerchantIdForSandbox : publicMerchantId
      }`
    );

    dispatch(
      isCentralizedBranch
        ? setMerchantCardInfoSandbox(data)
        : setMerchantCardInformation(data)
    );
  };

  const getMerchantCommissionInfo = async () => {
    const { data } =
      await axiosInstance.get<IGetMerchantCommissionInfoResponse>(
        `${API_ROUTES.MERCHANT_COMMISSION}/${publicMerchantId}`
      );

    dispatch(setMerchantCommission(data));
  };

  useEffect(() => {
    paymentPublicCredentialId && getMerchantSettingsAdmin();
  }, [paymentPublicCredentialId]);
  useEffect(() => {
    if (!publicMerchantId) return;
    if (isCentralized) {
      getMerchantCardInfo(isCentralized);
    }
    getPaymentCredentials();
    getSmartLinkData();
    getVposMerchantData();
    getSubscriptionData();
    getMerchantCardInfo();
    getMerchantCommissionInfo();
  }, [publicMerchantId]);

  useEffect(() => {
    if (merchantSettingsData?.processors) {
      const processorsList = merchantSettingsData.processors;

      if (
        !processorsList.card?.length &&
        !processorsList.cash?.length &&
        !processorsList.transfer?.length &&
        !processorsList["transfer-subscription"]?.length &&
        ((processorsList["payouts-cash"] &&
          processorsList["payouts-cash"]?.length > 0) ||
          (processorsList["payouts-transfer"] &&
            processorsList["payouts-transfer"]?.length > 0))
      ) {
        setIsSettingAvailable(false);
      }
    }
  }, [merchantSettingsData]);

  useEffect(() => {
    if (
      vposMerchantData &&
      vposMerchantData?.active &&
      !activeServices.includes(ServiceConfigurationEnums.VIRTUAL_POS)
    ) {
      setActiveServices([
        ServiceConfigurationEnums.VIRTUAL_POS,
        ...activeServices,
      ]);
    }
  }, [vposMerchantData]);

  useEffect(() => {
    if (smartlinkMerchant) {
      const customItems = [];

      if (
        smartlinkMerchant.status &&
        !activeServices.includes(ServiceConfigurationEnums.SMARTLINKS)
      ) {
        customItems.push(ServiceConfigurationEnums.SMARTLINKS);
      }

      if (
        smartlinkMerchant.webcheckoutStatus &&
        !activeServices.includes(ServiceConfigurationEnums.WEBCHECKOUT)
      ) {
        customItems.push(ServiceConfigurationEnums.WEBCHECKOUT);
      }
      setActiveServices([...activeServices, ...customItems]);
    }
  }, [smartlinkMerchant]);

  useEffect(() => {
    if (merchantCardInfo === undefined) return;

    const cardToChip = (card: string, index: number) => (
      <Chip
        label={CreditCardDict[card]}
        sx={{ marginRight: "4px" }}
        key={`cardChip-${card}-${index}`}
      />
    );

    const items: Item[] = [];

    if (!isNil(merchantCardInfoSandbox?.sandboxEnable) && !isCustomer) {
      items.push({
        label: ServiceConfigurationItems.SANDBOX,
        text: merchantCardInfoSandbox?.sandboxEnable
          ? ServiceConfigurationEnums.ACTIVATED
          : ServiceConfigurationEnums.DEACTIVATED,
      });
    }
    if (
      !isNil(merchantCardInfo?.sandboxEnable) &&
      !isCentralized &&
      !isCustomer
    ) {
      items.push({
        label: ServiceConfigurationItems.SANDBOX,
        text: merchantCardInfo?.sandboxEnable
          ? ServiceConfigurationEnums.ACTIVATED
          : ServiceConfigurationEnums.DEACTIVATED,
      });
    }
    if (merchantCardInfo?.acceptCreditCards?.length > 0) {
      items.push({
        label: ServiceConfigurationItems.BOX,
        text: merchantCardInfo?.acceptCreditCards.map(cardToChip),
      });
    }
    if (serviceSubscriptionInfo?.subscriptionValidation) {
      items.push({
        label: ServiceConfigurationItems.SUBSCRIPTION_PAYMENTS,
        text: "SI",
      });
    }
    if (merchantCommission?.enable) {
      items.push({
        label: ServiceConfigurationItems.COMMISIONS,
        text: "SI",
      });
    }
    items.push({
      label: ServiceConfigurationItems.AUTH,
      text: merchantSettingsData?.active_3dsecure
        ? "3DS"
        : "Sin servicio de autenticación",
    });

    setItems(items);
  }, [
    serviceSubscriptionInfo,
    merchantSettingsData,
    merchantCardInfo,
    merchantCommission,
    merchantCardInfoSandbox,
  ]);

  const handleConfigure = useCallback(() => {
    const data = {
      country: nodeInfo?.generalInfo.country,
      name: nodeInfo?.generalInfo.name,
      nodeId: nodeInfo?.nodeId,
      origin: OriginEnum.CONSOLE_MERCHANT_V3,
      publicMerchantId: nodeInfo?.generalInfo.publicMerchantId,
      redirectPath: `${location.pathname}${location.search}`,
    };

    localStorage.setItem("merchantBasicInformation", JSON.stringify(data));

    history.push(
      `${routes.MERCHANT_PROPERTIES}?hideSideBar=true&hideTopBar=true`
    );
  }, [nodeInfo]);

  useEffect(() => {
    setIsCustomer(get(nodeInfo, "entityName", "") === EntityNameEnum.CUSTOMER);
    setLocalStorageObject(merchantInfoLocalStorage, {
      country: get(nodeInfo, "generalInfo.country", ""),
      isEditing: true,
      merchantName: get(nodeInfo, "generalInfo.name", ""),
      publicMerchantId,
    });
  }, [nodeInfo]);

  return {
    activeServices,
    handleConfigure,
    isSettingsAvailable,
    items,
  };
};
