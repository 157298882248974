import { CatalogsEnum } from "../catalogs-enum";
import { Category } from "../constants/MerchantInformationConstants";
import { CurrencyEnum } from "../CurrencyEnum";

export const CatalogPe: Record<string, Category[]> = {
  [CatalogsEnum.Banks]: [
    { value: "9999", name: "BBVA" },
    { value: "0002", name: "Banco de Crédito del Perú" },
    { value: "0003", name: "Interbank" },
    { value: "0007", name: "Citibank del Perú S.A." },
    { value: "0009", name: "Scotiabank Perú S.A.A." },
    { value: "0011", name: "Banco Continental" },
    { value: "0018", name: "Banco de la Nación" },
    { value: "0023", name: "Banco de Comercio" },
    { value: "0035", name: "Banco Financiero del Perú" },
    { value: "0038", name: "Banco Interamericano de Finanzas (BIF)" },
    { value: "0053", name: "Banco Falabella" },
    { value: "0055", name: "Banco Ripley" },
    { value: "0056", name: "Banco Santander Perú" },
    { value: "0058", name: "Banco Azteca" },
    { value: "0054", name: "Banco GNB Perú S.A." },
    { value: "0802", name: "Caja Municipal de Ahorro y Crédito Trujillo" },
    { value: "0803", name: "Caja Municipal de Ahorro y Crédito Arequipa" },
    { value: "0805", name: "Caja Municipal de Ahorro y Crédito Sullana" },
    { value: "0808", name: "Caja Municipal de Ahorro y Crédito Huancayo" },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { value: "0", name: "Corriente" },
    { value: "1", name: "Ahorros" },
  ],
  [CatalogsEnum.Currency]: [
    { value: CurrencyEnum.USD, name: "Dólares" },
    { value: CurrencyEnum.PEN, name: "Soles" },
  ],
  [CatalogsEnum.Taxes]: [{ value: 0.18, name: "18%" }],
};
