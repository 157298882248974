import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../../interfaces/developers.interfaces";
import { getWebhookList } from "../../thunks/developers/developers.thunks";
import { FetchStateEnum } from "../../../shared/enums/fetchStateEnum";
import { INodeInfoResponse } from "../../../../types/node_info_response";
import { WebhookListResponse } from "../../../../types/webhook_list_response";

export const initialState: AppState = {
  getWebhookList: FetchStateEnum.PENDING,
  nodeInfo: undefined,
  webhookList: {
    items: [],
  },
  webHooksList: {
    successfulResponse: true,
  },
};

export const appSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getWebhookList.pending, (state: AppState) => {
      state.getWebhookList = FetchStateEnum.PENDING;
    });
    builder.addCase(getWebhookList.fulfilled, (state, { payload }) => {
      state.getWebhookList = FetchStateEnum.SUCCESS;
      state.webhookList = payload;
    });
  },
  initialState,
  name: "app",
  reducers: {
    setNodeInfoResponse(state, action: { payload: INodeInfoResponse }) {
      state.nodeInfo = action.payload;
    },
    setSuccessfulResponse: (state, action: PayloadAction<boolean>) => {
      state.webHooksList.successfulResponse = action.payload;
    },
    setWebhookListResponse(state, action: { payload: WebhookListResponse }) {
      state.webhookList = action.payload;
    },
  },
});

export default appSlice.reducer;
