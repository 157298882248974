import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import { environment } from "./environments/environment";
import { ComponentIdEnum } from "./shared/enums/ComponentIdEnum";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
import { AppRoutes } from "./shared/infrastructure/routes";
import { ProcessingConfigIndex } from "./containers/ProcessingConfigIndex/ProcessingConfigIndex";

const Navigation = () => {
  return (
    <>
      <BrowserRouter>
        <ModuleSecurityWrapper
          basePath={environment.kushkiUrl}
          componentId={ComponentIdEnum.M_TEST}
        >
          <Routes>
            <Route
              path={AppRoutes.INDEX}
              element={<ProcessingConfigIndex isBatch={false} />}
            />
            <Route
              path={AppRoutes.BATCH}
              element={<ProcessingConfigIndex isBatch={true} />}
            />
          </Routes>
        </ModuleSecurityWrapper>
      </BrowserRouter>
    </>
  );
};

export default connect()(Navigation);
