import React, { FC } from "react";
import { connect } from "react-redux";
import { IAppState } from "../../store/reducer";
import { MerchantConfiguration } from "../MerchantConfiguration/MerchantConfiguration";
import { AdminConfiguration } from "../AdminConfiguration/AdminConfiguration";

export const ConfigurationIndex: FC<IAppState> = ({ authInfo }: IAppState) =>
  authInfo?.isAdmin ? <AdminConfiguration /> : <MerchantConfiguration />;

export const mapStateToProps: (state: IAppState) => IAppState = (
  state: IAppState
): IAppState => ({
  ...state,
});

export default connect(mapStateToProps)(ConfigurationIndex);
