import React, { FC } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { RulesAlarmStyles as styles } from "./RulesAlarm.styles";
import { WrapperContainer } from "@containers/WrapperContainer/WrapperContainer";
import { GenericBreadcrumb } from "@components/GenericBreadcrumb/GenericBreadcrumb";
import { useRulesAlarm } from "@containers/RulesAlarm/state/useRulesAlarm";
import { AlarmLabels } from "@shared/constants/labels/alarmLabels";
import { IconPlus, SplitButton, Tab, Tabs } from "@kushki/connect-ui";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { SecurityWrapperRoles } from "@shared/enum/SecurityWrapperRoles";
import { AlarmTable } from "@components/AlarmTable/AlarmTable";
import { AlarmTypeByIndex } from "@shared/constants/AlarmConfigConstants";

const RulesAlarm: FC = () => {
  const { handleBreadcrumbItem, alarmOptions, onAlarmOptionSelect, tabs } =
    useRulesAlarm();

  return (
    <WrapperContainer>
      <Container fixed sx={styles.container}>
        <GenericBreadcrumb {...handleBreadcrumbItem()} />
        <Grid
          container
          sx={styles.headerContainer}
          justifyContent="space-between"
        >
          <Grid item xs={6}>
            <Box pb={2}>
              <Typography variant={"h2"} color="text.primary">
                {AlarmLabels.TITLE}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <ComponentSecurityWrapper
              componentId={SecurityWrapperRoles.M_COMPLIANCE_CREATE_ALARM}
            >
              <SplitButton
                items={alarmOptions}
                onItemSelected={onAlarmOptionSelect}
                size="medium"
                text={AlarmLabels.CREATE_ALARM}
                type="default"
                variant="primary"
                icon={<IconPlus />}
              />
            </ComponentSecurityWrapper>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs md>
            <Tabs
              onChange={tabs.onTabChange}
              orientation="horizontal"
              variant="fullWidth"
              valueTab={tabs.tabIndex}
            >
              <Tab
                colorTextSecondary="success"
                text="Generales"
                textSecondary="Completado"
                variant="primary"
              />
              <Tab
                colorTextSecondary="success"
                text="Por cliente"
                textSecondary="Completado"
                variant="primary"
              />
            </Tabs>
          </Grid>
        </Grid>
        <Grid item xs md>
          <AlarmTable tabType={AlarmTypeByIndex[tabs.tabIndex]} />
        </Grid>
      </Container>
    </WrapperContainer>
  );
};

export default RulesAlarm;
