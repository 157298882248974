import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import {
  RuleAlarm,
  SearchRuleAlarmResponse,
} from "@local-types/search_rule_alarm_response";
import {
  ITableCellProps,
  ITableRowProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { TableBodyCellEnum } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/constants";
import {
  CategoryData,
  ICellRow,
  ItemCategoryProps,
  OptionsSelectSearchBy,
} from "@kushki/connect-ui";
import {
  RuleAlarmStatus,
  StatusEnum,
} from "@shared/constants/AlarmTableConstants";
import {
  concat,
  defaultTo,
  get,
  groupBy,
  isEmpty,
  isEqual,
  unionBy,
  uniq,
} from "lodash";
import { IOptionListItemProps } from "@kushki/connect-ui/dist/Components/Molecules/OptionList/OptionList.interfaces";
import * as Locales from "date-fns/locale";
import { VariableRule } from "@local-types/rule_alarm";
import {
  AlarmTypeParse,
  TypeEnum,
  VARIABLE_RECORD,
  VARIABLES_VALUES,
} from "@shared/constants/AlarmConfigConstants";
import { EllipsisText } from "@components/AlarmTable/EllipsisText";
import { SecurityWrapperRoles } from "@shared/enum/SecurityWrapperRoles";
import { useAppDispatch, useAppSelector } from "@store/hooks/storeHook";
import { translateActionStatus } from "@shared/constants/translateActionStatus";
import { ActionTitleEnum } from "@shared/enum/ActionTitleEnum";
import { VerifyIfComponentEnable } from "@kushki/security-wrapper";
import {
  Filter,
  SearchRuleAlarmRequest,
} from "@local-types/search_rule_alarm_request";
import {
  setDetailCard,
  setModalActionProps,
} from "@store/actions/rulesAlarm.actions";
import { ModalActionLabel } from "@shared/enum/modalActionLabel";
import { statusType } from "@shared/interfaces/statusType";
import { changeStatusRule } from "@store/thunks/rulesAlarm/rulesAlarm.thunk";
import { initialModalActionProps } from "@shared/constants/initialModalActionProps";
import { AlarmType } from "@shared/enum/AlarmTypeEnum";
import { getRuleAlarms } from "@store/thunks/alarmConfig/alarmConfig.thunk";
import {
  searchMerchants,
  TypeSearchFilterEnum,
} from "@store/thunks/nodeSelection/nodeSelection.thunk";
import { ItemsCategoryData } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectSearchBy/SelectSearchBy.interfaces";
import { POSITION_FILTER_BY_ENTITY_NAME } from "@shared/constants/FilterConstans";
import {
  buildFilterRequest,
  deleteEmptyFilters,
  formatDateToTimestamp,
} from "@shared/utils/filterUtils";
import { useNavigate } from "react-router-dom";
import { EntityName, SearchAllMerchants } from "@shared/enum/entityNameEnum";
import {
  IUseAlarmTableState,
  IUseAlarmTableStateProps,
} from "@components/AlarmTable/state/useAlarmTableState.interfaces";
import { AlarmLabels } from "@shared/constants/labels/alarmLabels";
import { alarmRuleUri } from "@shared/constants/alarmRuleUri";
import axios, { CancelTokenSource } from "axios";
import { descriptionDetailCard } from "@shared/utils/detailCardUtils";
import {
  AlarmDetailCardEnum,
  TitleType,
} from "@shared/enum/AlarmDetailCardEnum";
import { IModalHeaderProps } from "@kushki/connect-ui/dist/Components/Molecules";
import { setIsSearchLoading } from "@store/reducers/nodeSelection/nodeSelection.slice";
import { deleteEmptyFiltersAlarmConfig } from "@shared/utils/alarmConfigUtil";
import DropDown from "@components/molecule/DropDown/DropDown";
import {
  formatTimestampWithTimezone,
  timeZoneHour,
} from "@shared/utils/parseDatesUtils";
import { DateFormatEnum } from "@shared/constants/parseMonths";

export const useAlarmTableSate = ({
  tabType,
}: IUseAlarmTableStateProps): IUseAlarmTableState => {
  const navigate = useNavigate();
  const {
    ruleData,
    isLoading,
    modalActionProps,
    filterSearchData,
    detailCard,
    isSearchLoading,
    timezoneProps,
  } = useAppSelector((state) => ({
    ...state.rulesAlarm,
    ...state.nodeSelection,
    ...state.general,
  }));
  const [rows, setRows] = useState<ITableRowProps[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const timeZoneStorage = timeZoneHour(
    localStorage.getItem("timeZone")!,
    "utc"
  );

  const [requestRulesAlarms, setRequestRulesAlarms] =
    useState<SearchRuleAlarmRequest>({
      filter: {
        timeZone: timeZoneStorage,
      } as Filter,
      limit: 10,
      offset: 0,
      type: AlarmTypeParse[defaultTo(tabType, AlarmType.GENERAL)],
    });

  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
  const [editRuleId, setEditRuleId] = useState<string>("");

  const dispatch = useAppDispatch();
  const [itemsSearch, setItemsSearch] = useState<CategoryData[]>([]);
  const [historicSearchItems, setHistoricSearchItems] = useState<
    OptionsSelectSearchBy[]
  >([]);
  const [inputValueProp, setInputValueProp] = useState<string>("");
  const [merchantsHierarchy, setMerchantsHierarchy] = useState<object[]>([]);
  const [actualNodes, setActualNodes] = useState<object[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");

  const getVariablesRule = (variables: VariableRule[]): string[] => {
    return variables.map(
      (variable: VariableRule) => VARIABLE_RECORD[variable.field]
    );
  };

  const verifyActiveComponent: boolean = VerifyIfComponentEnable(
    SecurityWrapperRoles.M_COMPLIANCE_ALARM_RULES_ACTIVATE
  );

  const verifyInactiveComponent: boolean = VerifyIfComponentEnable(
    SecurityWrapperRoles.M_COMPLIANCE_ALARM_RULES_INACTIVATE
  );
  const verifyDeleteComponent: boolean = VerifyIfComponentEnable(
    SecurityWrapperRoles.M_COMPLIANCE_ALARM_RULES_DELETE
  );
  const verifyEditComponent: boolean = VerifyIfComponentEnable(
    SecurityWrapperRoles.M_COMPLIANCE_ALARM_RULES_EDIT
  );

  const verifyDetailComponent: boolean = VerifyIfComponentEnable(
    SecurityWrapperRoles.M_COMPLIANCE_CREATE_ALARM_LIST_VARIABLES
  );

  const verifySecurityComponentActions = (optionText: string): boolean => {
    switch (optionText) {
      case ActionTitleEnum.ACTIVATE:
        return !verifyActiveComponent;
      case ActionTitleEnum.INACTIVATE:
        return !verifyInactiveComponent;
      case ActionTitleEnum.DELETE:
        return !verifyDeleteComponent;
      case ActionTitleEnum.EDIT:
        return !verifyEditComponent;
      default:
        return false;
    }
  };

  const handleChangeStatusRule = (ruleId: string, status: statusType) => {
    dispatch(
      changeStatusRule({
        changeStatusRequest: { ruleId, status },
        searchRequest: requestRulesAlarms,
      })
    );

    dispatch(setDetailCard({ isOpen: false, selectedRule: null }));
  };
  const handleAcceptModalAction = () => {
    handleChangeStatusRule(
      modalActionProps.ruleId,
      modalActionProps.type as statusType
    );
  };
  const handleCloseModalAction = () => {
    dispatch(setModalActionProps(initialModalActionProps));
  };
  const handleOpenActionModal = (title: string, ruleId: string) => {
    switch (title) {
      case ActionTitleEnum.INACTIVATE:
        dispatch(
          setModalActionProps({
            description: ModalActionLabel.DESCRIPTION_INACTIVATE,
            disablePrimaryButton: false,
            isOpen: true,
            primaryButtonLabel: ModalActionLabel.INACTIVATE_BUTTON,
            ruleId,
            secondaryButtonLabel: ModalActionLabel.CANCEL_BUTTON,
            title: ModalActionLabel.TITLE_INACTIVATE,
            type: StatusEnum.INACTIVE,
          })
        );
        break;
      case ActionTitleEnum.DELETE:
        dispatch(
          setModalActionProps({
            description: ModalActionLabel.DESCRIPTION_DELETE,
            disablePrimaryButton: false,
            isOpen: true,
            primaryButtonLabel: ModalActionLabel.DELETE_BUTTON,
            ruleId,
            secondaryButtonLabel: ModalActionLabel.CANCEL_BUTTON,
            title: ModalActionLabel.TITLE_DELETE,
            type: StatusEnum.DELETED,
          })
        );
        break;
      case ActionTitleEnum.EDIT:
        setIsOpenEditModal(true);
        break;
    }
  };
  const handleClickAction = (title: string, ruleAlarm: RuleAlarm) => {
    switch (title) {
      case ActionTitleEnum.ACTIVATE:
        handleChangeStatusRule(ruleAlarm._id, StatusEnum.ACTIVE);
        break;
      case ActionTitleEnum.DELETE:
      case ActionTitleEnum.INACTIVATE:
        handleOpenActionModal(title, ruleAlarm._id);
        break;
      case ActionTitleEnum.EDIT:
        setActualNodes(ruleAlarm.nodes);
        handleOpenActionModal(title, ruleAlarm._id);
        setEditRuleId(ruleAlarm._id);
        break;
    }
  };

  const onContinueEditModal = () => {
    sessionStorage.setItem("selectedNodes", JSON.stringify(actualNodes));
    navigate(alarmRuleUri(requestRulesAlarms.type, editRuleId).editURI);
    dispatch(setDetailCard({ isOpen: false, selectedRule: null }));
  };

  const onCloseEditModal = () => {
    setIsOpenEditModal(false);
    setEditRuleId("");
  };

  const commonCellProps: ICellRow = { align: "left", spacing: 0 };

  const buildTextCell = (text: string): ITableCellProps => {
    return {
      props: {
        cellProps: commonCellProps,
        line1: text,
        type: "oneLine",
      },
      type: TableBodyCellEnum.TEXT,
    };
  };

  const buildOptionCell = (
    optionText: string,
    ruleAlarm: RuleAlarm
  ): IOptionListItemProps => {
    return {
      disable: verifySecurityComponentActions(optionText),
      handleOptionClick: () => {},
      onClick: () => handleClickAction(optionText, ruleAlarm),
      optionText,
      type: "type1",
    };
  };

  const handleClickVariableCell = (ruleAlarm: RuleAlarm) => {
    dispatch(setDetailCard({ isOpen: true, selectedRule: ruleAlarm }));
  };

  const buildCell = (ruleAlarm: RuleAlarm): ITableCellProps[] => {
    const itemsMaxCharacters: number = 40;
    const cells: ITableCellProps[] = [];

    const status_field: { color: string; text: string } =
      RuleAlarmStatus[get(ruleAlarm, "status", StatusEnum.NONE)];

    cells.push({
      ...buildTextCell(
        formatTimestampWithTimezone(
          ruleAlarm.createdAt,
          DateFormatEnum.dd_MMM_yyyy,
          {
            locale: Locales.es,
            timeZone: get(timezoneProps, "regionTz")!,
          }
        ).toUpperCase()
      ),
    });

    cells.push({ ...buildTextCell(ruleAlarm.alias) });
    if (TypeEnum.INDIVIDUAL.includes(requestRulesAlarms.type)) {
      cells.push({
        props: {
          cellProps: commonCellProps,
          line1: ruleAlarm.frequency,
          type: "oneLine",
        },
        type: TableBodyCellEnum.TEXT,
      });

      cells.push({
        props: {
          cellProps: commonCellProps,
          child: (
            <EllipsisText
              itemsList={uniq(
                concat(
                  defaultTo(ruleAlarm.partialMerchants, []),
                  ruleAlarm.nodes
                    .filter((item) =>
                      [EntityName.BRANCH, EntityName.NA].includes(
                        item.entityName as EntityName
                      )
                    )
                    .map((item) => item.name)
                )
              )}
              quantity={ruleAlarm.activeMerchants}
              itemsMaxCharacters={itemsMaxCharacters}
            />
          ),
          childSkeleton: <div></div>,
        },
        type: TableBodyCellEnum.BASE,
      });
    }
    cells.push({
      props: {
        cellProps: commonCellProps,
        child: (
          <DropDown
            values={VARIABLES_VALUES}
            items={getVariablesRule(ruleAlarm.variables)}
          />
        ),
        childSkeleton: <div></div>,
      },
      type: TableBodyCellEnum.BASE,
    });

    cells.push({
      props: {
        cellProps: commonCellProps,
        color: status_field.color as "primary",
        text: status_field.text,
      },
      type: TableBodyCellEnum.TAG,
    });

    cells.push({
      props: {
        cellProps: commonCellProps,
        isDisabled: false,
        optionListItems: [
          {
            ...buildOptionCell(
              defaultTo(translateActionStatus[ruleAlarm.status], ""),
              ruleAlarm
            ),
          },
          { ...buildOptionCell(ActionTitleEnum.EDIT, ruleAlarm) },
          { ...buildOptionCell(ActionTitleEnum.DELETE, ruleAlarm) },
        ],
      },
      type: TableBodyCellEnum.OPTIONS,
    });

    return cells;
  };

  const buildTableRows = (data: SearchRuleAlarmResponse): ITableRowProps[] => {
    if (data.total > 0) {
      return data.records.map(
        (ruleAlarm: RuleAlarm): ITableRowProps => ({
          cells: buildCell(ruleAlarm),
          id: ruleAlarm._id,
          rowProps: {
            color: "default",
            onClick: () => {
              if (verifyDetailComponent) handleClickVariableCell(ruleAlarm);
            },
          },
        })
      );
    }

    return [];
  };

  const handleAutocompleteInput = (inputValue: string) => {
    setInputValueProp(inputValue);
    dispatch(
      searchMerchants({
        body: {
          filter: {
            entityName: SearchAllMerchants,
            ...(!isEmpty(inputValue) && { name: inputValue }),
          },
          limit: 5,
          offset: 1,
        },
        type: TypeSearchFilterEnum.SEARCH_FILTER,
      })
    );
  };
  const verifySelectedItem = (nodeId: string): boolean => {
    return !isEmpty(
      historicSearchItems.find((item: OptionsSelectSearchBy) =>
        isEqual(item.id, nodeId)
      )
    );
  };
  const buildItemsToSearch = (): CategoryData[] => {
    let id: string;
    const categoryItems: CategoryData[] = [];

    if (get(filterSearchData, "total", 0) > 0) {
      const listMerchants: object[] = [];
      let listItems: ItemsCategoryData[];
      let categoryName: string;
      let grouped: object[];
      const groupByEntity = groupBy(
        get(filterSearchData, "data", []),
        "entityName"
      );

      for (const key of POSITION_FILTER_BY_ENTITY_NAME) {
        listItems = [];
        categoryName = key;
        grouped = get(groupByEntity, key, []);

        if (isEqual(key, EntityName.BRANCH)) {
          grouped = concat(grouped, get(groupByEntity, EntityName.NA, []));
          categoryName = AlarmLabels.MERCHANT_HIERARCHY_TEXT;
        }

        for (const item of grouped) {
          id = isEqual(get(item, "entityName"), EntityName.NA)
            ? get(item, "publicMerchantId", "")
            : get(item, "nodeId", "");

          listItems.push({
            id: id,
            selected: verifySelectedItem(id),
            text: defaultTo(get(item, "name"), ""),
          });

          listMerchants.push({
            ...item,
            idMerchantHierarchy: id,
          });
        }

        categoryItems.push({
          categoryId: `id-${categoryName}`,
          categoryName: categoryName,
          items: listItems,
        });
      }
      setMerchantsHierarchy(
        unionBy(merchantsHierarchy, listMerchants, "idMerchantHierarchy")
      );
    }

    return categoryItems;
  };

  const getFilterOCB = (id: string): object => {
    const merchant = merchantsHierarchy.find((item: object) =>
      isEqual(get(item, "idMerchantHierarchy"), id)
    );

    if ((get(merchant, "entityName", "") as EntityName) === EntityName.NA)
      return { merchantId: get(merchant, "publicMerchantId", "") };

    return { path: get(merchant, "path") };
  };

  const getPathsAndMerchantIds = (list: object[]): object => {
    return list.reduce(
      (acc, item) => {
        if (get(item, "path") === undefined)
          return {
            ...acc,
            merchantId: [...get(acc, "merchantId", []), item["merchantId"]],
          };

        return { ...acc, path: [...get(acc, "path", []), item["path"]] };
      },
      {
        merchantId: [],
        path: [],
      }
    );
  };

  const buildFilterSelectedChange = (
    selectedItems: OptionsSelectSearchBy[]
  ) => {
    const filterOCB: object[] = [];

    selectedItems.map((item) => {
      filterOCB.push(getFilterOCB(item.id as string));
    });
    setSelectedPage(1);
    setRequestRulesAlarms({
      ...requestRulesAlarms,
      filter: {
        ...get(requestRulesAlarms, "filter", {}),
        ...getPathsAndMerchantIds(filterOCB),
      },
      offset: 0,
    });
  };

  const handleSelectedChange = (selectedItem: OptionsSelectSearchBy) => {
    let newItems: OptionsSelectSearchBy[] = historicSearchItems;

    if (selectedItem.id!!) {
      if (selectedItem.selected) newItems.push(selectedItem);
      else {
        newItems = historicSearchItems.filter(
          (item) => !isEqual(item.id, selectedItem.id)
        );
      }
    } else {
      newItems = [];
    }
    setHistoricSearchItems(newItems);
    dispatch(setIsSearchLoading(false));
    buildFilterSelectedChange(newItems);
  };

  useEffect(() => {
    setItemsSearch(buildItemsToSearch());
  }, [filterSearchData]);

  useEffect(() => {
    setRows(buildTableRows(ruleData));
  }, [verifyActiveComponent]);

  const calculateOffset = (page: number, itemsPerPage: number) => {
    setRequestRulesAlarms({
      ...requestRulesAlarms!,
      limit: itemsPerPage,
      offset: (page - 1) * itemsPerPage,
    });
  };

  const handleSelectedPage = (value: number) => {
    setSelectedPage(value);
    calculateOffset(value, requestRulesAlarms.limit);
  };

  const handleItemsPerPage = (value: number) => {
    setSelectedPage(1);
    calculateOffset(1, value);
  };

  const handleApplyFilters = (items: ItemCategoryProps[]) => {
    const filtersSidebar: Filter = buildFilterRequest(items);

    setSelectedPage(1);

    setRequestRulesAlarms((prevState: SearchRuleAlarmRequest) => ({
      ...prevState,
      filter: deleteEmptyFilters({ ...prevState.filter, ...filtersSidebar }),
      offset: 0,
    }));
  };

  const handleSearchDate = (startDate: string, finishDate: string) => {
    const startDateParsed: number = formatDateToTimestamp(startDate, false);
    const finishDateParsed: number = formatDateToTimestamp(finishDate, true);

    setSelectedPage(1);
    setRequestRulesAlarms((prevState) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        from: startDateParsed,
        to: finishDateParsed,
      },
      offset: 0,
    }));
  };

  const handleChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleSubmitSearchInput = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSelectedPage(1);
    setRequestRulesAlarms((prevState) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        alias: searchValue,
      },
      offset: 0,
    }));
  };

  useEffect(() => {
    const cancelToken: CancelTokenSource = axios.CancelToken.source();

    deleteEmptyFiltersAlarmConfig(requestRulesAlarms, tabType, searchValue);

    dispatch(getRuleAlarms({ cancelToken, request: requestRulesAlarms }));

    return () => {
      cancelToken.cancel("");
    };
  }, [requestRulesAlarms]);

  useEffect(() => {
    setRows(buildTableRows(ruleData));
    setTotal(ruleData.total);
  }, [ruleData]);

  useEffect(() => {
    if (
      get(requestRulesAlarms, "type", "").toUpperCase() ===
      tabType.toUpperCase()
    )
      return;

    setRequestRulesAlarms({
      filter: {
        timeZone: timeZoneStorage,
      } as Filter,
      limit: 10,
      offset: 0,
      type: AlarmTypeParse[defaultTo(tabType, AlarmType.GENERAL)],
    });
    setSelectedPage(1);
  }, [tabType]);

  const handleCloseDetailCard = () => {
    dispatch(setDetailCard({ isOpen: false, selectedRule: null }));
  };

  const buttonsDetailCard: IModalHeaderProps["buttonRow"] = [
    {
      color: "secondary",
      isDisabled: verifySecurityComponentActions(AlarmDetailCardEnum.EDIT),
      onClick: () =>
        handleClickAction(ActionTitleEnum.EDIT, detailCard.selectedRule!),
      text: AlarmDetailCardEnum.EDIT,
      variant: "outlined",
    },
    {
      color: "primary",
      isDisabled: verifySecurityComponentActions(
        translateActionStatus[get(detailCard, "selectedRule.status", "")]
      ),
      onClick: () =>
        handleClickAction(
          defaultTo(
            translateActionStatus[get(detailCard, "selectedRule.status", "")],
            ""
          ),
          detailCard.selectedRule!
        ),
      text: defaultTo(
        translateActionStatus[get(detailCard, "selectedRule.status", "")],
        ""
      ),
      variant: "contained",
    },
  ];

  return {
    autocompleteProps: {
      handleAutocompleteInput,
      handleSelectedChange,
      historicSearchItems,
      inputValueProp,
      itemsSearch,
    },
    detailCard: {
      buttonsRow: buttonsDetailCard,
      createdBy: descriptionDetailCard<RuleAlarm>(
        AlarmDetailCardEnum.CREATED_BY,
        detailCard.selectedRule,
        TitleType.CREATE
      ),
      handleClose: handleCloseDetailCard,
      isOpen: detailCard.isOpen,
      tag: RuleAlarmStatus[
        get(detailCard, "selectedRule.status", StatusEnum.NONE)
      ],
      updatedBy: descriptionDetailCard<RuleAlarm>(
        AlarmDetailCardEnum.LAST_UPDATED_BY,
        detailCard.selectedRule,
        TitleType.UPDATE
      ),
    },
    footer: {
      handleItemsPerPage,
      handleSelectedPage,
      itemsPerPage: defaultTo(requestRulesAlarms.limit, 10),
      selectedPage,
    },
    handleApplyFilters,
    handleSearchDate,
    isLoading,
    isOpenEditModal,
    isSearchLoading,
    modalAction: {
      buttonPrimary: modalActionProps.primaryButtonLabel,
      buttonPrimaryColor: "error",
      buttonPrimaryDisabled: modalActionProps.disablePrimaryButton,
      buttonSecondary: modalActionProps.secondaryButtonLabel,
      descriptionText: modalActionProps.description,
      isOpen: modalActionProps.isOpen,
      onClickPrimary: handleAcceptModalAction,
      onClickSecondary: handleCloseModalAction,
      onClose: handleCloseModalAction,
      titleText: modalActionProps.title,
      typeModal: 1,
    },
    onCloseEditModal,
    onContinueEditModal,
    rows,
    searchInputProps: {
      handleChangeSearchValue,
      handleSubmitSearchInput,
      label: AlarmLabels.GENERAL_SEARCH_ALIAS_TEXT,
      searchValue,
    },
    total,
  };
};
