import { UpdateMerchantInfoRequest } from "../../../../types/update_merchant_info_request";
import { PersonCategory } from "../../../shared/constants/PersonCategoryEnum";
import {
  MerchantType,
  PersonType,
} from "../../../shared/constants/PersonTypeEnum";
import { CreateMerchantPeopleRequest } from "../../../shared/Interfaces/CreateMerchantPeopleRequest";
import { IUseContainerIndexState } from "./useContainerIndexState.interfaces";
import { defaultTo, get, omit, pickBy, set } from "lodash";
import { ValidateRefinitivRequest } from "../../../../types/validate_refinitiv_request";
import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompliancePeople,
  getMerchantInformation,
  saveMerchantPeople,
  setCompleteSave,
} from "../../../store/actionCreators";
import { IAppState } from "../../../store/reducer";
import { useForm } from "react-hook-form";
import { IPathParams } from "../../../shared/Interfaces/PathParams.interfaces";
import { CountriesEnum } from "../../../shared/infrastructure/constants/countries-enum";
import { SocialReasonRequest } from "../../../../types/social_reason_request";
import { CompanyInformationEnum } from "../../../shared/constants/CompanyInformationEnum";
import moment from "moment";
import {
  AFFILIATION_NO_APPLY,
  SOCIAL_REASON_MERCHANT_INFO_SOCIAL_REASON,
  SOCIAL_REASON_ROOT,
} from "../../../shared/constants/LegalRepresentativesConstants";

export const useContainerIndexState = (): IUseContainerIndexState => {
  const {
    compliancePeople,
    merchantInformation,
    loading,
    completeSave,
    completeGetCompliance,
  } = useSelector((store: IAppState) => store);

  const dispatch = useDispatch();
  const history = useHistory();
  const { merchantId } = useParams<IPathParams>();
  const merchantName = get(merchantInformation, "name", "");
  const country = get(merchantInformation, "country", "");
  const merchantType = get(merchantInformation, "merchantType", "");
  const affiliationId =
    get(merchantInformation, "merchantLead.id", "") === AFFILIATION_NO_APPLY
      ? ""
      : get(merchantInformation, "merchantLead.id");
  const socialReason = get(
    merchantInformation,
    SOCIAL_REASON_ROOT,
    get(merchantInformation, SOCIAL_REASON_MERCHANT_INFO_SOCIAL_REASON, "")
  );

  const {
    clearErrors,
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ValidateRefinitivRequest>({
    mode: "onChange",
  });

  const onSubmit = (data: ValidateRefinitivRequest): void => {
    const social_reason: SocialReasonRequest = pickBy({
      affiliationId,
      completeName: socialReason,
      country: country,
      documentNumber: get(merchantInformation, "taxId", ""),
      id: get(compliancePeople, "socialReason.id"),
      merchantId: merchantId,
      personCategory: PersonCategory.SOCIAL_REASON,
      personType: MerchantType[merchantType],
      socialReason: socialReason,
    });

    const bodyMerchant: UpdateMerchantInfoRequest = {
      ciiu: get(data, CompanyInformationEnum.CIIU, ""),
      constitutionDate: Number(
        moment(get(merchantInformation, "constitutionDate")).format("x")
      ),
      mcc: get(data, CompanyInformationEnum.MCC, ""),
      publicMerchantId: merchantId,
    };
    let legalRepresentatives = get(data, "legalRepresentatives", []).map(
      (lr) => ({
        ...lr,
        affiliationId,
      })
    );
    let shareHolders = get(data, "shareholders", []).map((sh) => ({
      ...sh,
      affiliationId,
    }));

    if (affiliationId === "") {
      // @ts-ignore
      shareHolders = get(data, "shareholders", []).map((sh) =>
        omit(sh, ["affiliationId"])
      );
      // @ts-ignore
      legalRepresentatives = get(data, "legalRepresentatives", []).map((lr) =>
        omit(lr, ["affiliationId"])
      );
    }

    set(data, "legalRepresentatives", legalRepresentatives);
    set(data, "shareholders", shareHolders);

    const bodyPeople: CreateMerchantPeopleRequest = {
      ...data,
      country,
      merchantId: merchantId,
      merchantName,
      socialReason: social_reason,
    };

    delete bodyPeople.ciiu;
    delete bodyPeople.mcc;
    if (defaultTo(social_reason.socialReason, "") === "") {
      delete bodyPeople.socialReason;
    }

    dispatch(saveMerchantPeople(bodyMerchant, bodyPeople));
  };

  const validateCountry = (country: CountriesEnum) => {
    return [
      CountriesEnum.COLOMBIA,
      CountriesEnum.ECUADOR,
      CountriesEnum.MEXICO,
      CountriesEnum.PERU,
      CountriesEnum.CHILE,
    ].includes(country);
  };

  const showCompanyInformation = validateCountry(country as CountriesEnum);

  const showShareholders =
    validateCountry(country as CountriesEnum) &&
    MerchantType[merchantInformation?.merchantType!] !== PersonType.NATURAL;

  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    dispatch(getMerchantInformation(merchantId));
    dispatch(getCompliancePeople(merchantId));
  }, []);

  useEffect(() => {
    if (!loading && completeSave) {
      dispatch(setCompleteSave(false));
      handleGoBack();
    }
  }, [loading]);

  return {
    form: {
      clearErrors,
      control,
      errors,
      getValues,
      handleSubmit: handleSubmit(onSubmit),
      setValue,
      watch,
    },
    handleGoBack,
    loading,
    merchantName,
    showCompanyInformation,
    showForm: completeGetCompliance && merchantInformation !== undefined,
    showShareholders,
  };
};
