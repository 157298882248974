import React from "react";
import { Grid, Typography } from "@mui/material";
import { Chargeback, PreviousStatus } from "../../../../types/chargeback";
import { format } from "date-fns";
import { filter, get, has, orderBy } from "lodash";
import {
  RESOLVED_DATE_CHARGEBACK_STATUS,
  REVIEW_DATE_CHARGEBACK_STATUS,
  SubtitleSection,
} from "../../../shared/infraestructure/ChargeBackConstants";
import { ChargebackStatusEnum } from "../../../shared/enums/ChargebackStatusEnum";
import { subtractDates } from "../../../shared/utils/date_calculation";

export interface ChargebackSectionProps {
  data: Chargeback;
}

export const formatDate = (
  trxDate: number | string,
  formatDate: string
): string => {
  return format(new Date(trxDate), formatDate);
};

const ChargebackSection: React.FC<ChargebackSectionProps> = (
  props: ChargebackSectionProps
) => {
  const createdDate: string | number = get(props, "data.created", 0);
  const deadlineDate: number = has(props, "data.create_timestamp")
    ? get(props, "data.deadline_timestamp", 0)
    : get(props, "data.dead_line", 0);
  const reviewDate: string | number = get(
    orderBy(
      filter(get(props, "data.previous_status", []), (item: PreviousStatus) => {
        return REVIEW_DATE_CHARGEBACK_STATUS.includes(item.status);
      }),
      "updatedAt",
      "desc"
    ),
    "0.updatedAt",
    ""
  );
  const resolvedDate: string | number = get(
    orderBy(
      filter(get(props, "data.previous_status", []), (item: PreviousStatus) => {
        return RESOLVED_DATE_CHARGEBACK_STATUS.includes(item.status);
      }),
      "updatedAt",
      "desc"
    ),
    "0.updatedAt",
    ""
  );
  const creation_date: string = formatDate(createdDate, "dd-MM-yyyy HH:mm:ss");
  const current_date: string = formatDate(
    new Date().toString(),
    "dd-MM-yyyy HH:mm:ss"
  );
  const upload_date: string =
    reviewDate !== "" ? formatDate(reviewDate, "dd-MM-yyyy HH:mm:ss") : "";
  const passed_days: number | string =
    props.data.transaction_status === ChargebackStatusEnum.INITIALIZED ||
    props.data.transaction_status === ChargebackStatusEnum.EXPIRED
      ? subtractDates(current_date, creation_date)
      : upload_date !== ""
      ? subtractDates(upload_date, creation_date)
      : "";

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container direction={"row"} spacing={6}>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.grey">
                {SubtitleSection.INIT_DATE}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.grey">
                {formatDate(createdDate, "dd-MM-yyyy")} |{" "}
                {formatDate(createdDate, "HH:mm:ss")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction={"row"} spacing={6}>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.grey">
                {SubtitleSection.RESPONSE_DATE}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={"body2"} color="text.grey">
                {formatDate(deadlineDate, "dd-MM-yyyy")} |{" "}
                {formatDate(deadlineDate, "HH:mm:ss")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {reviewDate !== "" ? (
          <Grid item xs={12}>
            <Grid container direction={"row"} spacing={6}>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.grey">
                  {SubtitleSection.REVIEW_DATE}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.grey">
                  {formatDate(reviewDate, "dd-MM-yyyy")} |{" "}
                  {formatDate(reviewDate, "HH:mm:ss")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        {resolvedDate !== "" ? (
          <Grid item xs={12}>
            <Grid container direction={"row"} spacing={6}>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.grey">
                  {SubtitleSection.RESOLVED_DATE}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.grey">
                  {formatDate(resolvedDate, "dd-MM-yyyy")} |{" "}
                  {formatDate(resolvedDate, "HH:mm:ss")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        {passed_days !== "" && (
          <Grid item xs={12}>
            <Grid container direction={"row"} spacing={6}>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.grey">
                  {SubtitleSection.DAYS_PASSED}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.grey">
                  {passed_days}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default ChargebackSection;
