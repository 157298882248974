import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { SearchMerchantResponse } from "../../../../types/search_merchant_response";
import { SearchMerchantRequest } from "../../../../types/search_merchant_request";
import { get } from "lodash";

export const searchBranchMerchants = createAsyncThunk<
  SearchMerchantResponse | undefined,
  SearchMerchantRequest
>("branch/searchMerchant", (request, { dispatch }) => {
  return axios
    .post<SearchMerchantResponse>(API_ROUTES.SEARCH_MERCHANT_NODE, request)
    .then((response) => {
      if (
        response.data.total === 0 &&
        request.strictSearch === true &&
        get(request, "name", "") !== ""
      ) {
        dispatch(searchBranchMerchants({ ...request, strictSearch: false }));

        return;
      }

      return response.data;
    });
});
