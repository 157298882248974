import { LayoutState } from "../../interfaces/layout/layout.interfaces";
import { createSlice } from "@reduxjs/toolkit";
import {
  findNodeByNodeId,
  getNodeInfo,
  getParentNodeByMerchantId,
} from "../../thunks/layout/layout.thunks";
import {
  DEFAULT_VALUES,
  getSessionStorageValues,
  setSessionStorageValues,
} from "../../utils/sessionStorage-utils";
import { GetNodeInfoResponse } from "../../../../types/get_node_info_response";
import { EntityName } from "../../../shared/enums/entityName";

export const initialState: LayoutState = {
  isOpenExitModal: false,
  nodeInfo: {} as GetNodeInfoResponse,
  ...getSessionStorageValues(),
};

export const layoutSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getParentNodeByMerchantId.fulfilled, (state, { payload }) => {
        state.customerName = payload.queryNode?.name;
        state.entityName = payload.entityName;
        state.nodeName = payload.name;
        state.parentNodeId = payload.nodeId;

        setSessionStorageValues({
          customerName: payload.queryNode?.name,
          entityName: payload.entityName,
          nodeName: payload.name,
        });
      })
      .addCase(findNodeByNodeId.fulfilled, (state, { payload }) => {
        state.customerName = payload.queryNode?.name;
        state.entityName = payload.entityName;
        payload.entityName !== EntityName.OWNER
          ? (state.nodeName = payload.name)
          : (state.ownerName = payload.name);

        setSessionStorageValues(
          payload.entityName !== EntityName.OWNER
            ? {
                customerName: payload.queryNode?.name,
                entityName: payload.entityName,
                nodeName: payload.name,
              }
            : { ownerName: payload.name }
        );
      })
      .addCase(getNodeInfo.fulfilled, (state, { payload }) => {
        state.customerEntityName = payload.entityName;
        state.nodeInfo = payload;

        setSessionStorageValues({
          customerEntityName: payload.entityName,
        });
      });
  },
  initialState,
  name: "layout",
  reducers: {
    closeModal: (state) => {
      state.isOpenExitModal = false;
    },
    openModal: (state) => {
      state.isOpenExitModal = true;
    },
    refreshHeaderValuesFromLocalStorage: (state) => {
      const newValues = getSessionStorageValues();

      state.customerName = newValues.customerName;
      state.entityName = newValues.entityName;
      state.nodeName = newValues.nodeName;
      state.origin = newValues.origin;
      state.section = newValues.section;
      state.customerEntityName = newValues.customerEntityName;
    },
    resetValuesFromLocalStorage: (state) => {
      state.customerName = DEFAULT_VALUES.customerName;
      state.entityName = DEFAULT_VALUES.entityName;
      state.nodeName = DEFAULT_VALUES.nodeName;
      state.origin = DEFAULT_VALUES.origin;
      state.section = DEFAULT_VALUES.section;
      state.customerEntityName = DEFAULT_VALUES.customerEntityName;
    },
  },
});

export default layoutSlice.reducer;
