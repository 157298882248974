import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const branchListContainerStyles = createNamedStyles({
  boxFooter: {
    zIndex: "1000",
    width: "100%",
    position: "fixed",
    bottom: "0",
  },
  centerContainer: {
    width: "75%",
    margin: "0 auto",
    marginBottom: "10%",
  },
  doubtsContainer: {
    width: "75%",
    marginBottom: "10%",
    marginLeft: "64px",
    marginTop: "24px",
  },
  description: {
    color: "#023365",
    fontSize: "16px",
  },
});
