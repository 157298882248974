import React, { PropsWithChildren } from "react";
import { Box, Grid } from "@material-ui/core";
import { walletHeaderStyle } from "./WalletHeader.style";
import { WalletHeaderProps } from "../../shared/infrastructure/interfaces/WalletHeader.interface";

export const WalletHeader = (props: PropsWithChildren<WalletHeaderProps>) => {
  const classes = walletHeaderStyle();

  return (
    <Grid
      container
      direction={"row"}
      className={classes.mainGrid}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Grid item xs={1}>
        {props.filterComponent}
      </Grid>
      <Grid item xs={4} className={classes.picker}>
        {props.pickerComponent}
      </Grid>
      <Grid item xs={2}>
        <Box className={classes.downloadButton}>
          {props.downloadButtonComponent}
        </Box>
      </Grid>
      <Grid item xs={5}>
        <Box className={classes.searchBox}>{props.searchComponent}</Box>
      </Grid>
    </Grid>
  );
};
