import React from "react";
import {
  SemaphoreData,
  StatusSteps,
} from "../../../../types/remote/semaphore_data";
import { ProcessorsPendingSection } from "./ProcessorsPendingSection";
import { useDispatch, useSelector } from "react-redux";
import { IAppState, StatusStepsState } from "../../../store/reducer";
import {
  setMerchantInfoStorage,
  StatusSemaphore,
  StepName,
  StepsEnum,
} from "../../../shared/infrastructure/constants/CreateMerchantConstants";
import { MerchantCreateUpdateResponse } from "../../../../types/merchant_create_update_response";
import { Skeleton } from "@material-ui/lab";
import { Grid } from "@material-ui/core";
import { defaultTo, get, includes, isEmpty } from "lodash";
import { ProcessorsSummarySection } from "./ProcessorsSummarySection";
import {
  IDefaultProcessors,
  IProcessorsType,
  saveDefaultPayoutsTransferProcessor,
  saveDefaultProcessor,
  saveFailoverProcessor,
  updateStepStatus,
} from "../../../store/actionCreators";
import { Dispatch } from "redux";
import { UpdateMerchantRulesRequest } from "../../../../types/update_merchant_rules_request";
import { MerchantProcessors } from "../../../../types/merchant_processors";
import { CardContainer } from "../../common/CardContainer/CardContainer";
import { DeferredSection } from "./DeferredSection/DeferredSection";
import {
  COUNTRY_DEFAULT_DEFERRED,
  CountryEnum,
} from "../../../shared/infrastructure/catalogs/CountryEnum";
import { useHistory } from "react-router-dom";
import { DeferredOption } from "../../../shared/infrastructure/interfaces/DeferredOptions";
import { RulesSummarySection } from "./RulesSummarySection";
import { MerchantProcessorsRules } from "../../../../types/merchant_processors_rules_response";
import { ProcessorsDeferredSection } from "./ProcessorsDeferredSection";
import SkeletonTableContainer from "../../common/SkeletonTableContainer/SkeletonTableContainer";
import { DEFERRED_TYPES_PROSA_MAP } from "../../../shared/infrastructure/constants/ProsaTypes";
import { statusType } from "../../../shared/infrastructure/types/statusType";
import { MerchantSectionRetries } from "../MerchantSectionRetries/MerchantSectionRetries";
import { SearchResponse } from "../../../../types/search_response";

const getStatusByPath = (
  semaphore: Partial<IProcessorsSection>,
  idProp: statusType
) => get(semaphore, `semaphore.stepProcessor.${idProp}`, "pending");

export type IProcessorsSection = {
  publicMerchantId: string;
  merchantData: MerchantCreateUpdateResponse | undefined;
  status: StatusSteps;
  statusDeferred: StatusSteps;
  statusRetryRules: StatusSteps;
  statusMerchantRules: StatusSteps;
  isLoading: boolean;
  loadingDefaultProcessors: boolean;
  loadingFailover: boolean;
  semaphore: SemaphoreData;
  defaultProcessors: IDefaultProcessors;
  defaultPayoutProcessors: IDefaultProcessors;
  processors: IProcessorsType;
  processorsRules: MerchantProcessorsRules;
  merchantProcessors: MerchantProcessors[];
  country: string;
  deferred?: DeferredOption[];
  retries?: SearchResponse;
};

export const ProcessorsSection: React.FC<any> = (
  _: string,
  _0: (step: string, status: StatusSteps) => void
) => {
  const history = useHistory();
  const stateProps: Partial<IProcessorsSection> = useSelector(
    (state: IAppState) => ({
      publicMerchantId: state.merchantAffiliation?.publicMerchantId,
      country: state.merchantAffiliation?.country,
      status: state.steps?.find(
        (step: StatusStepsState) => step.name === StepsEnum.stepProcessor
      )?.status,
      processorsRules: state.processorRules,
      statusDeferred: get(
        state,
        "semaphore.stepProcessor.statusDeferred",
        StatusSemaphore.pending
      ),
      statusRetryRules: get(
        state,
        "semaphore.stepProcessor.statusRetryRules",
        StatusSemaphore.pending
      ),
      statusMerchantRules: get(
        state,
        "semaphore.stepProcessor.statusMerchantRules",
        StatusSemaphore.pending
      ),
      isLoading: state.loading,
      semaphore: state.semaphore,
      processors: state.processors,
      defaultProcessors: state.defaultProcessor,
      defaultPayoutProcessors: state.defaultPayoutTransferProcessor,
      loadingDefaultProcessors: state.loadingDefaultProcessors,
      loadingFailover: state.loadingFailover,
      deferred: state.deferred,
      retries: state.retries,
      appConfigPaymentMethods: state.appConfigPaymentMethods,
    })
  );
  const processorsURL: string = `/processor-list?merchantId=${stateProps.publicMerchantId}&hideSideBar=true`;
  const deferredURL: string = "/deferred?hideSideBar=true";
  const rulesURL: string = `/rules/business/rule/${stateProps.publicMerchantId}?hideSideBar=true`;
  const retriesURL: string = "/retry-rules/merchant?hideSideBar=true";
  const retriesDashboard: string = "/retry-rules?hideSideBar=true";

  if (stateProps.isLoading)
    return <Skeleton variant="rect" width="100%" height={150} />;

  const dispatch: Dispatch<any> = useDispatch();

  const saveUpdateDefaultProcessors = (
    payloadProcessors: UpdateMerchantRulesRequest
  ) => {
    dispatch(saveDefaultProcessor(payloadProcessors));
  };

  const saveUpdateDefaultPayoutsTransferProcessor = (
    payloadProcessors: UpdateMerchantRulesRequest
  ) => {
    dispatch(saveDefaultPayoutsTransferProcessor(payloadProcessors));
  };

  const updateFailoverProcessor = (
    merchantId: string,
    publicProcessorId: string,
    failover: {
      failoverAlias: string;
    }
  ) => dispatch(saveFailoverProcessor(merchantId, publicProcessorId, failover));

  const eGlobalRegex: RegExp = new RegExp("^e.*global$", "ig");

  const getChosenDeferred = (
    baseDeferred: DeferredOption[],
    chosenDeferred: object[]
  ): DeferredOption[] => {
    const deferredOptions = chosenDeferred
      .map((processor: object) => {
        const processorName: string = get(processor, "id", "")
          .toLowerCase()
          .trim();

        const isEGlobal = eGlobalRegex.test(processorName);
        const isProsa = processorName.length === 36;
        const isBillpocket = processorName === "billpocket";

        if (isEGlobal) return baseDeferred[1];
        if (isBillpocket)
          return {
            bank: get(processor, "bank", []),
            months: billpocketMonths(get(processor, "maxMonths", 0)),
            deferredType: [get(processor, "id", "")],
          };
        if (isProsa)
          return {
            type: defaultTo(
              DEFERRED_TYPES_PROSA_MAP[get(processor, "deferredType")],
              "N/A"
            ),
            bank: get(processor, "bank", []),
            months: get(processor, "months", []),
            monthsOfGrace: get(processor, "monthsOfGrace", []).join(", "),
            deferredType: baseDeferred[0].deferredType,
          };

        return undefined;
      })
      .filter((processor: object | undefined) => processor !== undefined);

    return deferredOptions as DeferredOption[];
  };

  const billpocketMonths = (maxMonth: number): number[] => {
    const array_month: number[] = [];
    for (let _i = 3; _i <= maxMonth; _i = _i + 3) {
      array_month.push(_i);
    }
    return array_month;
  };

  const getDeferredByCountry = (country?: string): DeferredOption[] => {
    if (!country) return [];
    const deferredOptions: DeferredOption[] = get(stateProps, "deferred", []);
    if (includes([CountryEnum.ECUADOR, CountryEnum.BRAZIL], country))
      return deferredOptions;

    const baseDeferred: DeferredOption[] = [
      {
        deferredType: ["Prosa"],
        months: ["3", "6", "9", "12"],
        bank: ["BANCO PROSA"],
        monthsOfGrace: ["1"],
      },
      {
        deferredType: ["E Global"],
        months: ["Hasta 24 meses"],
        bank: ["BANCO E GLOBAL"],
        monthsOfGrace: ["1"],
      },
    ];

    return getChosenDeferred(baseDeferred, deferredOptions);
  };

  const omitStep = (
    parentStep: StepName,
    status: StatusSteps,
    childStepStatus: string
  ) =>
    dispatch(
      updateStepStatus(
        parentStep,
        status,
        stateProps.publicMerchantId!,
        childStepStatus
      )
    );

  const hasCardProcessor = () => {
    const card_processor: MerchantProcessors[] = get(
      stateProps,
      "processors.card",
      []
    );

    return card_processor.length > 0;
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} style={{ padding: 10 }}>
          {getStatusByPath(stateProps!, "statusProcessor") ===
          StatusSemaphore.pending ? (
            <ProcessorsPendingSection
              to={processorsURL}
              buttonText="Agregar procesador"
              title={
                <span>
                  Aún no has agregado <strong>procesadores</strong>.
                </span>
              }
              note={
                "Recuerda que si agregaste un procesador, es posible que tarde un poco en aparecer en la lista. Si no aparece en unos segundos intenta recargar la página."
              }
              country={stateProps.country}
              isPopUp={true}
              id={stateProps.publicMerchantId}
            />
          ) : stateProps.defaultProcessors ? (
            <ProcessorsSummarySection
              loadingDefaultProcessors={!!stateProps.loadingDefaultProcessors}
              processors={stateProps.processors}
              defaultProcessors={stateProps.defaultProcessors}
              defaultPayoutProcessors={stateProps.defaultPayoutProcessors}
              to={processorsURL}
              saveDefaultProcessors={saveUpdateDefaultProcessors}
              saveDefaultPayoutProcessors={
                saveUpdateDefaultPayoutsTransferProcessor
              }
              publicMerchantId={stateProps.publicMerchantId!}
              loadingFailover={get(stateProps, "loadingFailover", false)}
              saveFailoverProcessor={updateFailoverProcessor}
              country={stateProps.country}
            />
          ) : (
            <SkeletonTableContainer rows={5} cells={4} />
          )}
          {hasCardProcessor() ? (
            getStatusByPath(stateProps!, "statusMerchantRules") ===
              StatusSemaphore.pending ||
            getStatusByPath(stateProps!, "statusMerchantRules") ===
              StatusSemaphore.omitted ? (
              <ProcessorsPendingSection
                to={rulesURL}
                helperText={
                  stateProps.statusMerchantRules === StatusSemaphore.omitted
                    ? "Has omitido este paso"
                    : get(stateProps, "processors.card", []).length > 0
                    ? "También podrás agregar reglas de negocio una vez terminado de configurar el comercio en la sección de Configuración"
                    : undefined
                }
                omitButtonText={
                  stateProps.statusMerchantRules !== StatusSemaphore.omitted &&
                  get(stateProps, "processors.card", []).length > 0
                    ? "Omitir este paso"
                    : undefined
                }
                omitButtonAction={() =>
                  omitStep(
                    StepsEnum.stepProcessor,
                    StatusSemaphore.omitted,
                    "statusMerchantRules"
                  )
                }
                buttonText="Configurar regla"
                title={
                  <span>
                    Aún no has configurado ninguna{" "}
                    <strong>regla de negocio</strong>.
                  </span>
                }
              />
            ) : (
              <RulesSummarySection
                publicMerchantId={stateProps.publicMerchantId!}
                to={rulesURL}
                processorRules={stateProps.processorsRules!}
              />
            )
          ) : null}

          {includes([CountryEnum.PERU], stateProps.country) &&
            !isEmpty(stateProps.processors?.card) && (
              <ProcessorsDeferredSection
                publicMerchantId={stateProps.publicMerchantId!}
              />
            )}

          {includes(COUNTRY_DEFAULT_DEFERRED, stateProps.country) &&
            hasCardProcessor() &&
            (getStatusByPath(stateProps!, "statusDeferred") ===
              StatusSemaphore.pending ||
            getStatusByPath(stateProps!, "statusDeferred") ===
              StatusSemaphore.omitted ? (
              <ProcessorsPendingSection
                helperText={
                  stateProps.statusDeferred === StatusSemaphore.omitted
                    ? "Has omitido este paso"
                    : "También podrás agregar diferidos una vez terminado de configurar el comercio en la sección de Configuración"
                }
                omitButtonText={
                  stateProps.statusDeferred !== StatusSemaphore.omitted
                    ? "Omitir este paso"
                    : undefined
                }
                omitButtonAction={() =>
                  omitStep(
                    StepsEnum.stepProcessor,
                    StatusSemaphore.omitted,
                    "statusDeferred"
                  )
                }
                to={deferredURL}
                buttonText="Agregar diferidos"
                title={
                  <span>
                    Aún no has agregado <strong>diferidos</strong>.
                  </span>
                }
              />
            ) : (
              <CardContainer
                headerCardTitle="Diferidos"
                headerButtonTitle="Agregar diferidos"
                footerCardHrefLocation={deferredURL}
                footerTitleHrefLocation="Edita y revisa todos los diferidos"
                childrenArrayCardComponent={[
                  <DeferredSection
                    key="deferredSection"
                    deferred={getDeferredByCountry(stateProps.country)}
                    maxElements={5}
                    country={get(stateProps, "country", "")}
                  />,
                ]}
                buttonCreate={true}
                headerButtonOnClick={() => {
                  setMerchantInfoStorage();
                  history.push(deferredURL);
                }}
                footerCardHrefOnClickHandler={() => setMerchantInfoStorage()}
              />
            ))}

          {get(stateProps, "retries.data", []).length < 1 ? (
            <ProcessorsPendingSection
              to={retriesURL}
              buttonText="Configurar regla de reintentos"
              title={
                <span>
                  Aún no has agregado ninguna{" "}
                  <strong>regla de reintentos</strong>.
                </span>
              }
              country={stateProps.country}
              id={stateProps.publicMerchantId}
              helperText={
                stateProps.statusRetryRules === StatusSemaphore.omitted
                  ? "Has omitido este paso"
                  : "También podrás agregar reglas de reintento una vez terminado de configurar el comercio en la sección reintentos"
              }
              omitButtonText={
                stateProps.statusRetryRules !== StatusSemaphore.omitted
                  ? "Omitir este paso"
                  : undefined
              }
              omitButtonAction={() =>
                omitStep(
                  StepsEnum.stepProcessor,
                  StatusSemaphore.omitted,
                  "statusRetryRules"
                )
              }
            />
          ) : (
            <MerchantSectionRetries
              retries={stateProps.retries!}
              retriesURL={retriesDashboard}
            />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
