import React, { FC } from "react";
import { Box, Button, Typography } from "@mui/material";
import {
  informationFormStyles,
  informationFormStyles as styles,
} from "./InformationForm.styles";
import { INFORMATION_LABELS } from "../../shared/constants/labels/information_container_labels";
import { useInformationForm } from "./state/useInformationForm";

import { ISelectNewItemList } from "../SelectNewItem/SelectNewItem.interfaces";
import SelectNewItem from "../SelectNewItem/SelectNewItem";

const InformationForm: FC = () => {
  const {
    handleItemSelect,
    isSelected,
    nodeList,
    handleOnClick,
    handleOnChange,
  } = useInformationForm();

  return (
    <Box display={"flex"} flexDirection={"column"} sx={styles.boxContent}>
      <Typography sx={informationFormStyles.title} color={"primary"}>
        {INFORMATION_LABELS.OWNER_TITLE}
      </Typography>
      <Typography sx={informationFormStyles.subtitle} color={"primary"}>
        {INFORMATION_LABELS.OWNER_SUBTITLE}
      </Typography>
      <Box component="ul" sx={informationFormStyles.boxUl}>
        <Box component="li">
          {INFORMATION_LABELS.DESCRIPTION_BEGIN}
          <b>{INFORMATION_LABELS.OWNER_LABEL}</b>
          {INFORMATION_LABELS.DESCRIPTION_END}
        </Box>
      </Box>
      <Box sx={informationFormStyles.boxSelect}>
        <SelectNewItem
          placeholder={INFORMATION_LABELS.PLACEHOLDER_OWNER}
          items={nodeList}
          onItemSelected={(item: ISelectNewItemList | null) =>
            handleItemSelect(item)
          }
          specialCharacters={false}
          orderBy={"default"}
          onChange={handleOnChange}
        />
      </Box>
      <Button
        disabled={!isSelected}
        sx={informationFormStyles.nextButton}
        variant={"contained"}
        onClick={handleOnClick}
      >
        {INFORMATION_LABELS.NEXT_BUTTON_LBL}
      </Button>
    </Box>
  );
};

export default InformationForm;
