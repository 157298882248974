import * as React from "react";
import { styled, SvgIcon as MuiSvgIcon, SvgIconProps } from "@mui/material";

const SvgIcon = styled(MuiSvgIcon, {
  name: "IconTransfer",
  shouldForwardProp: (prop) => prop !== "fill",
})<SvgIconProps>(({ color }) => ({
  fill: color + "!important",
  fontSize: "22px !important",
}));

SvgIcon.defaultProps = {
  viewBox: "0 0 24 24",
  focusable: "false",
  "aria-hidden": "true",
};

const IconTransferLocal: React.FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={"transferIcon"}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.9609 2.29289C16.3514 1.90237 16.9846 1.90237 17.3751 2.29289L20.7084 5.62623C20.8959 5.81376 21.0013 6.06812 21.0013 6.33333C21.0013 6.59855 20.8959 6.8529 20.7084 7.04044L17.3751 10.3738C16.9846 10.7643 16.3514 10.7643 15.9609 10.3738C15.5703 9.98325 15.5703 9.35008 15.9609 8.95956L17.587 7.33341H8.33333C7.71449 7.33341 7.121 7.57925 6.68342 8.01683C6.24583 8.45442 6 9.04791 6 9.66675V11.3334C6 11.8857 5.55228 12.3334 5 12.3334C4.44772 12.3334 4 11.8857 4 11.3334V9.66675C4 8.51748 4.45655 7.41528 5.2692 6.60262C6.08186 5.78996 7.18406 5.33341 8.33333 5.33341H17.5872L15.9609 3.70711C15.5703 3.31658 15.5703 2.68342 15.9609 2.29289ZM20 12.0001C20.5523 12.0001 21 12.4478 21 13.0001V14.6667C21 15.816 20.5435 16.9182 19.7308 17.7309C18.9181 18.5435 17.8159 19.0001 16.6667 19.0001L7.41421 19.0001L9.04044 20.6263C9.43096 21.0168 9.43096 21.65 9.04044 22.0405C8.64992 22.431 8.01675 22.431 7.62623 22.0405L4.29289 18.7072C3.90237 18.3167 3.90237 17.6835 4.29289 17.293L7.62623 13.9596C8.01675 13.5691 8.64992 13.5691 9.04044 13.9596C9.43096 14.3502 9.43096 14.9833 9.04044 15.3739L7.41421 17.0001L16.6667 17.0001C17.2855 17.0001 17.879 16.7542 18.3166 16.3167C18.7542 15.8791 19 15.2856 19 14.6667V13.0001C19 12.4478 19.4477 12.0001 20 12.0001Z"
        fill="#023365"
      />
    </SvgIcon>
  );
};

export default IconTransferLocal;
