import { IStyles } from "../../shared/interfaces/create_named_styles";
import { VariantButtonEnum } from "../../shared/enums/BranchEnum";

type VariantType = "text" | "outlined" | "contained" | undefined;
export const useModalSimpleStyles = (variant: VariantType): IStyles => {
  const isContained = variant === VariantButtonEnum.CONTAINED;

  return {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "#ffffff",
      borderRadius: "0.5rem",
      boxShadow: 24,
      boxSizing: "border-box",
      width: {
        md: "725px",
        sm: "500px",
      },
      height: "300px",
    },
    title: {
      display: "flex",
      justifyContent: "space-between",
    },
    closeButton: {
      backgroundColor: "transparent",
    },
    textColor: {
      color: "#023365 !important",
    },
    content: {
      flexGrow: 1,
      p: 4,
      display: "flex",
      justifyContent: "space-around",
      flexDirection: "column",
    },
    buttonsWrapper: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      gap: 1,
      height: "80px",
      width: "100%",
      backgroundColor: "#FAFCFD",
      borderBottomLeftRadius: "0.5rem",
      borderBottomRightRadius: "0.5rem",
      px: 4,
    },
    primaryButton: {
      border: isContained ? "1px solid #4498EE" : "1px solid #E24763",
      color: isContained ? "#ffffff" : "#E24763",
      "&:hover": {
        color: isContained ? "#ffffff" : "#023365",
      },
    },
    secondaryButton: {
      color: "#6D7781",
      "&:hover": {
        color: "#6D7781",
      },
    },
  };
};
