import React from "react";
import {
  Grid,
  Typography,
  IconButton,
  FormControlLabel,
  Radio,
  Box,
  TextField,
} from "@material-ui/core";
import { Trash2 } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";
import { SmartlinksLogos } from "../../../types/smartlink_customization";
import { useForm } from "react-hook-form";

const useStyles = makeStyles({
  sectionTitle: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#6D7781",
    marginTop: 10,
  },
});

export interface LogoItemProps {
  logo: SmartlinksLogos;
  remove: (item: SmartlinksLogos) => void;
  editName: (currentName: string) => void;
}

export interface ILogoItem {
  alias: string;
}

export const LogoItem: React.FC<LogoItemProps> = (props: LogoItemProps) => {
  const styles = useStyles();
  const { register, errors } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  return (
    <React.Fragment>
      <Grid
        container
        spacing={3}
        style={{
          paddingTop: 20,
          paddingBottom: 15,
        }}
      >
        <Grid item sm={1} xs={1}>
          <FormControlLabel
            value={props.logo.alias}
            control={<Radio color="primary" />}
            label=""
          />
        </Grid>
        <Grid item sm xs>
          <TextField
            id="alias"
            name="alias"
            variant="outlined"
            label="Alias"
            fullWidth
            value={props.logo.alias}
            inputRef={register({
              required: "* Campo requerido",
              pattern: {
                value: new RegExp("^[a-zA-Z0-9À-ÿ-_.,:() ]+$"),
                message: "Alias inválido",
              },
            })}
            helperText={errors.alias ? errors.alias.message : ""}
            error={!!errors.alias}
            onChange={(e) => props.editName(e.target.value)}
          />
        </Grid>
        <Grid item sm={2} xs={2}>
          <Typography className={styles.sectionTitle}>
            {props.logo.extension}
          </Typography>
        </Grid>

        <Grid item sm={2} xs={2}>
          <Box display={"flex"} justifyContent={"flex-end"}>
            <IconButton onClick={() => props.remove(props.logo)}>
              <Trash2 />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
