import React, { PropsWithChildren } from "react";
import { FormHelperText } from "@mui/material";
import { retrieveFormError } from "../../../../utils/utilsFile";
import { IFormHelperTextComponentProps } from "../../../../shared/infrastructure/interfaces/IFormHelperTextComponentProps";

export const FormHelperTextComponent: React.FC<
  IFormHelperTextComponentProps
> = (props: PropsWithChildren<IFormHelperTextComponentProps>) => {
  return (
    <>
      <FormHelperText
        className={props.className}
        error={retrieveFormError(props.errors, props.path)[0]}
      >
        {retrieveFormError(props.errors, props.path)[1]}
      </FormHelperText>
    </>
  );
};
