import React, { PropsWithChildren } from "react";
import { FormHelperText } from "@mui/material";
import { retrieveFormError } from "../../../../shared/utils/utilsFile";
import { IFormHelperTextComponentProps } from "../../../../shared/interfaces/form_helper_text_component_props";

export const FormHelperTextComponent: React.FC<
  IFormHelperTextComponentProps
> = (props: PropsWithChildren<IFormHelperTextComponentProps>) => {
  return (
    <React.Fragment>
      <FormHelperText
        className={props.className}
        error={retrieveFormError(props.errors, props.path)[0]}
      >
        {retrieveFormError(props.errors, props.path)[1]}
      </FormHelperText>
    </React.Fragment>
  );
};
