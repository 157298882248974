import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const basicInfoStyles = createNamedStyles({
  radioSkeleton: {
    marginLeft: "20px",
    width: 100,
    height: 60,
  },
  title: {
    fontFamily: "IBM Plex Sans",
    fontWeight: 500,
    fontSize: "16px",
    color: "#023365",
  },
  titleChip: {
    marginRight: "5px",
    marginBottom: "2px",
    marginLeft: "20px",
  },
  chipContainer: {
    marginTop: "1p",
  },
  chip: {
    marginRight: "5px",
    marginLeft: "5px",
  },
  cardContent: {
    paddingTop: "30px",
    paddingBottom: "30px",
  },
  modelContent: {
    marginBottom: "30px",
  },
});
