import { format, utcToZonedTime } from "date-fns-tz";
import React from "react";
import { ModalHeader } from "@kushki/frontend-molecules/modal-header";
import { get } from "lodash";

export interface ModalHeaderInfoProps {
  supportInfo: object | undefined;
  isAdmin: boolean;
  timeZoneRegion: string;
}

function formatDate(date: string, timeZone: string): string {
  return format(utcToZonedTime(date, timeZone), "dd-MM-yyyy HH:mm");
}

const ModalHeaderInfo = (props: ModalHeaderInfoProps) => {
  return (
    <ModalHeader
      highlightValue={
        props.isAdmin
          ? get(props.supportInfo, "_source.user_agent")
          : get(props.supportInfo, "_source.user_full_name")
      }
      tittle={"Detalle de Asistencia Remota"}
      secondaryValue={formatDate(
        props.isAdmin
          ? get(props.supportInfo, "_source.connected_in")
          : get(props.supportInfo, "_source.created"),
        props.timeZoneRegion
      )}
    />
  );
};

export default ModalHeaderInfo;
