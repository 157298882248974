import React, { FC } from "react";
import { Box, Button } from "@mui/material";
import { IconChevronLeft } from "@kushki/connect-ui";
import { RETURN_BUTTON_LABEL } from "../../shared/constants/labels/add_webhook_labels";
import { useNavigate } from "react-router-dom";

const ReturnButton: FC = () => {
  const navigate = useNavigate();

  return (
    <Box>
      <Button
        className={"buttonLink"}
        startIcon={<IconChevronLeft />}
        onClick={() => navigate(-1)}
      >
        {RETURN_BUTTON_LABEL}
      </Button>
    </Box>
  );
};

export default ReturnButton;
