import React from "react";
import { ModalContent } from "@kushki/connect-ui";
import { useMassiveStatusEmailsModal } from "./state/useMassiveStatusEmailsModal";
import { TextFieldEmail } from "@kushki/connect-ui";
import {
  MassiveStatusModalButtons,
  MassiveStatusModalConstants,
} from "../../shared/infraestructure/MassiveStatusModalConstants";
import { Grid, Typography } from "@mui/material";
import { FileMassiveData } from "../../../types/update_chargeback_status_massive_request";

export enum ModalContentButtonTypeEnum {
  PRIMARY = "primary",
}

export enum ModalContentTypeEnum {
  DEFAULT = "Default",
}

export const MassiveStatusEmailsModal = (props: {
  massiveStatusFile: FileMassiveData | undefined;
}) => {
  const {
    isOpen,
    emails,
    isDomainEmailErr,
    isDisabledUploadBtn,
    onItemsChange,
    handleReturn,
    handleUpdateChargebackStatusMassive,
  } = useMassiveStatusEmailsModal(props);

  return (
    <>
      <ModalContent
        buttonText={MassiveStatusModalButtons.UPLOAD_BUTTON}
        buttonType={ModalContentButtonTypeEnum.PRIMARY}
        descriptionText={""}
        onClose={handleReturn}
        titleText={MassiveStatusModalConstants.ADD_EMAILS_TITTLE}
        type={ModalContentTypeEnum.DEFAULT}
        buttonAction={handleUpdateChargebackStatusMassive}
        buttonPrimaryDisabled={isDisabledUploadBtn}
        isOpen={isOpen}
        buttonSecondaryText={MassiveStatusModalButtons.RETURN_BUTTON}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} style={{ paddingTop: "30px" }}>
            <Typography
              variant={"body2"}
              align={"justify"}
              sx={{ color: "#293036" }}
            >
              {MassiveStatusModalConstants.ADD_EMAILS_DETAIL}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} style={{ paddingTop: 20 }}>
            <TextFieldEmail
              mailLimit={5}
              items={emails.map((email: string) => ({ mail: email }))}
              onItemsChange={onItemsChange}
              placeholder={MassiveStatusModalConstants.ADD_EMAILS_INPUT}
              autoWidth={true}
              helperText={""}
              error={isDomainEmailErr}
              errorHelperText={
                isDomainEmailErr
                  ? MassiveStatusModalConstants.DOMAIN_ERR_MSG
                  : MassiveStatusModalConstants.EMAIL_ERROR_MSG
              }
            />
          </Grid>
        </Grid>
      </ModalContent>
    </>
  );
};
