import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

export const infoContentTableStyles = createNamedStyles({
  emptySearchContainer: {
    backgroundColor: "rgba(227,231, 239)",
    paddingTop: 10,
    paddingBottom: 10,
  },
  emptySearchText: {
    color: "#6e8291",
    fontSize: "16px",
    fontStyle: "normal",
  },
  table: {
    tableLayout: "fixed",
    borderColor: "grey",
    "&.MuiTable-root": {
      borderCollapse: "separate",
      borderSpacing: "0px 12px",
      display: "table",
    },
  },
  viewMoreText: {
    color: "rgb(112,118,128)",
    textDecoration: "underline",
    cursor: "pointer",
  },
  sortIcon: {
    paddingLeft: "5px",
    width: "25px",
  },
  sortGroup: {
    cursor: "pointer",
  },
});
