import React from "react";
import { useForm, useWatch } from "react-hook-form";
import {
  IBasicInformationProps,
  IBasicInformationState,
  ICheckoutData,
} from "../BasicInformation.interfaces";
import { useCheckoutContext } from "../../../containers/CheckoutContainer/context/useCheckoutContext";
import { BasicInformationErrors } from "../../../shared/labels/BasicInformation.labels";
import { REGEX_COMMONS } from "../../../shared/constants/regex";
import { IconCircleWarn, useSnackbar } from "@kushki/connect-ui";
import { useEffect } from "react";
import { formatInputAmount } from "../../../shared/utils/utils";
import { isInteger } from "lodash";
import { SnackBarMessageLabels } from "../../../shared/labels/SnackBarMessage.labels";

export const enum inputsBasicInformation {
  NAME = "name",
  EMAIL = "email",
  SUBTOTAL_IVA = "subtotalIva",
  SUBTOTAL_IVA_0 = "subtotalIva0",
  DESCRIPTION = "description",
}

export const validationsForm = {
  [inputsBasicInformation.NAME]: {
    pattern: {
      message: BasicInformationErrors.ALPHANUMERIC_VALIDATION,
      value: REGEX_COMMONS.ALPHANUMERIC,
    },
    required: BasicInformationErrors.FIELD_REQUIRED,
  },
  [inputsBasicInformation.EMAIL]: {
    pattern: {
      message: BasicInformationErrors.EMIAL_VALIDATION,
      value: REGEX_COMMONS.EMAIL,
    },
    required: BasicInformationErrors.FIELD_REQUIRED,
  },
  [inputsBasicInformation.SUBTOTAL_IVA]: {
    min: {
      message: BasicInformationErrors.NEGATIVE_NUMBER_VALIDATION,
      value: 0,
    },
    required: false,
    valueAsNumber: true,
  },
  [inputsBasicInformation.DESCRIPTION]: {
    maxLength: {
      message: BasicInformationErrors.MAX_LENGTH_500_VALIDATION,
      value: 500,
    },
    pattern: {
      message: BasicInformationErrors.ALPHANUMERIC_VALIDATION,
      value: REGEX_COMMONS.ALPHANUMERIC,
    },
    required: false,
  },
};

export const useBasicInformation = ({
  onSubmit,
  defaultValues,
}: IBasicInformationProps): IBasicInformationState => {
  const { showSnackbar } = useSnackbar();
  const basicInformationForm = useForm<ICheckoutData>({
    criteriaMode: "all",
    defaultValues,
    mode: "onChange",
    reValidateMode: "onBlur",
  });
  const { isMobile, currency, configAmount, iva } = useCheckoutContext();
  const {
    calculateTotalAmount,
    calculateIvaAmount,
    getIvaAmountFormat,
    getTotalAmountFormat,
  } = configAmount;
  const subtotalIva0 = useWatch<number>({
    control: basicInformationForm.control,
    defaultValue: 0,
    name: inputsBasicInformation.SUBTOTAL_IVA_0,
  });

  const subtotalIva = useWatch<number>({
    control: basicInformationForm.control,
    defaultValue: 0,
    name: inputsBasicInformation.SUBTOTAL_IVA,
  });

  const description = useWatch<string>({
    control: basicInformationForm.control,
    defaultValue: "",
    name: inputsBasicInformation.DESCRIPTION,
  });

  const validationAfterSubmit = (formData: ICheckoutData) => {
    const transformValues = formData;

    if (!REGEX_COMMONS.NUMBER.test(formData.subtotalIva.toString()))
      transformValues.subtotalIva = 0;

    if (!REGEX_COMMONS.NUMBER.test(formData.subtotalIva0.toString()))
      transformValues.subtotalIva0 = 0;

    if (formData.subtotalIva <= 0 && formData.subtotalIva0 <= 0) {
      showSnackbar({
        color: "danger",
        icon: <IconCircleWarn />,
        message: SnackBarMessageLabels.EMPTY_VALUES,
        variant: "simple",
        withIcon: true,
      });

      return;
    }

    transformValues.ivaAmount = Number(
      calculateIvaAmount(transformValues.subtotalIva)
    );
    transformValues.totalAmount = Number(
      calculateTotalAmount(
        transformValues.subtotalIva,
        transformValues.subtotalIva0
      )
    );

    onSubmit(transformValues);
  };

  const enableBtnSubmit = () => {
    const validationInputSubtotalIva =
      REGEX_COMMONS.NUMBER.test(subtotalIva.toString()) && subtotalIva > 0;
    const validationInputSubtotalIva0 =
      REGEX_COMMONS.NUMBER.test(subtotalIva0.toString()) && subtotalIva0 > 0;

    return validationInputSubtotalIva || validationInputSubtotalIva0;
  };

  const handleContinueSubmit = async (event: {
    preventDefault: () => void;
  }) => {
    event.preventDefault();
    const isValid = await basicInformationForm.trigger();

    if (isValid) validationAfterSubmit(basicInformationForm.getValues());
  };

  const checkFormatInput = (value: number) => value > 0 && !isInteger(value);

  useEffect(() => {
    if (checkFormatInput(subtotalIva))
      basicInformationForm.setValue(
        inputsBasicInformation.SUBTOTAL_IVA,
        formatInputAmount(subtotalIva, currency)
      );
  }, [subtotalIva]);

  useEffect(() => {
    if (checkFormatInput(subtotalIva0))
      basicInformationForm.setValue(
        inputsBasicInformation.SUBTOTAL_IVA_0,
        formatInputAmount(subtotalIva0, currency)
      );
  }, [subtotalIva0]);

  useEffect(() => {
    basicInformationForm.setValue(
      inputsBasicInformation.SUBTOTAL_IVA_0,
      formatInputAmount(defaultValues.subtotalIva0, currency)
    );

    basicInformationForm.setValue(
      inputsBasicInformation.SUBTOTAL_IVA,
      formatInputAmount(defaultValues.subtotalIva, currency)
    );
  }, []);

  return {
    basicInformationForm,
    currency,
    data: {
      description,
      subtotalIva,
      subtotalIva0,
    },
    enableBtnSubmit,
    getIvaAmountFormat,
    getTotalAmountFormat,
    handleContinueSubmit,
    isMobile,
    ivaPercentage: iva,
  };
};
