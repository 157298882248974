import { IStyles } from "../../../shared/infrastructure/interfaces/Styles.interfaces";

export const snackbarStyles: IStyles = {
  downloadErrorSnackbar: {
    backgroundColor: "#E24763",
    border: "none",
    borderRadius: "0",
    width: "480px",
  },
  downloadSuccessSnackbar: {
    backgroundColor: "#677684",
    border: "none",
    borderRadius: "0",
    width: "300px",
  },
  errorSnackbar: {
    backgroundColor: "#E24763",
    color: "#FBFBFB",
  },
  successSnackbar: {
    backgroundColor: "#0DC298",
    color: "#FFFFFF",
  },
};
