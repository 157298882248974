import React from "react";
import {
  Box,
  createStyles,
  Paper,
  TextField,
  Theme,
  Toolbar,
} from "@material-ui/core";
import { DateRange } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import { SupportDatePicker } from "../../DatePicker/SupportDatePicker";
import { Autocomplete } from "@material-ui/lab";
import { MerchantForm } from "../../../../types/merchant_form";
import { IMerchantsResponse } from "../../../store/reducer";
import { get } from "lodash";
import FilterComponent from "../../FilterComponent/FilterComponent";
import { IOptionFilter } from "../../../containers/HistoryIndex/state/useHistoryIndexState";

export interface FiltersHistoryProps {
  isAdmin: boolean;
  autocomplete: {
    handleChargeMerchants: (
      _event: unknown,
      value: string,
      _reason: unknown
    ) => void;
    handleChangeMerchant: (
      _event: object,
      value: { _source: MerchantForm }[],
      _reason: string
    ) => void;
    merchants: IMerchantsResponse | undefined;
  };
  filterComponent: {
    text: string;
    filters: IOptionFilter[];
    isMobile: boolean;
    onApplyFilter: (
      search: { filter: object },
      filters: IOptionFilter[],
      text: string
    ) => void;
  };
  handleDate?: (dateRange: DateRange) => void;
  disableFuture?: boolean;
  value?: DateRange;
  isLoading?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiFormControl-root": {
        height: "auto",
      },
      "& > * + *": {
        marginTop: theme.spacing(0),
      },
      "& .MuiInputBase-root": {
        height: "auto",
        width: "auto",
        backgroundColor: "#FFFFFF",
      },
    },
    searchComponent: {
      "& .MuiInputBase-input": {
        textAlign: "left !important",
      },
    },
  })
);

export const FiltersHistory: React.FC<FiltersHistoryProps> = (
  props: FiltersHistoryProps
) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Paper
        style={{
          paddingLeft: 12,
          paddingRight: 12,
          backgroundColor: "#F7FAFC",
          borderRadius: 4,
          boxShadow: "0 0px 0px rgba(0,0,0,0), 0 0px 0px rgba(0,0,0,0)",
        }}
      >
        <Toolbar disableGutters>
          <Box display="flex" flexWrap="wrap" flexGrow={1} alignItems="center">
            {props.isAdmin ? (
              <Autocomplete
                style={{
                  minWidth: "200px",
                  maxWidth: "200px",
                }}
                size="small"
                className={classes.root}
                multiple
                id="fixed-tags-demo"
                onInputChange={props.autocomplete.handleChargeMerchants}
                onChange={props.autocomplete.handleChangeMerchant}
                options={get(props.autocomplete.merchants!, "data", [])}
                getOptionSelected={(
                  option: { _source: MerchantForm },
                  value: { _source: MerchantForm }
                ) => option._source.name === value._source.name}
                getOptionLabel={(option: { _source: MerchantForm }) =>
                  option._source.name
                }
                renderInput={(params) => (
                  <TextField
                    className={classes.searchComponent}
                    {...params}
                    label="Comercios"
                    variant="outlined"
                    placeholder="Selecciona un comercio"
                    margin="dense"
                  />
                )}
              />
            ) : null}
            <Box p={1}>
              <FilterComponent
                onApplyFilter={props.filterComponent.onApplyFilter}
                filters={props.filterComponent.filters}
                isMobile={props.filterComponent.isMobile}
                text={props.filterComponent.text}
              />
            </Box>
            <Box>
              <SupportDatePicker
                handleDate={props.handleDate}
                disableFuture
                value={props.value}
              />
            </Box>
          </Box>
        </Toolbar>
      </Paper>
    </React.Fragment>
  );
};
