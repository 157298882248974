import { MerchantResponse } from "../../../types/get_merchant_response";
import { CountryEnum } from "../countryEnum";
import { CatalogBr } from "../services/catalog-br";
import { CatalogsEnum } from "../interfaces/catalogs-enum";
import { CatalogCr } from "../services/catalog-cr";
import { CatalogCl } from "../services/catalog-cl";
import { CatalogCo } from "../services/catalog-co";
import { CatalogEc } from "../services/catalog-ec";
import { CatalogEl } from "../services/catalog-el";
import { CatalogGt } from "../services/catalog-gt";
import { CatalogHn } from "../services/catalog-hn";
import { CatalogMx } from "../services/catalog-mx";
import { CatalogNi } from "../services/catalog-ni";
import { CatalogPan } from "../services/catalog-pan";
import { CatalogPe } from "../services/catalog-pe";
import { PersonTypeObjectInterface } from "../enum/BasicStateGeneral/FieldsBasicData";
import { find, get } from "lodash";
import { CompliancePeopleNodeResponse } from "../../../types/get_compliance_people_response";

export const merchantNodeInfoDefaultState: MerchantResponse = {
  country: "",
  name: "",
  naturalPerson: { lastname: "", name: "" },
  publicMerchantId: "",
};

export const compliancePeopleDefaultState: CompliancePeopleNodeResponse = {
  boardmembers: [],
  constitutionalCountry: "",
  country: "",
  legalRepresentatives: [],
  merchantId: "",
  merchantName: "",
  shareholders: [],
  socialReason: {},
};

export enum PersonTypeEnum {
  FISICA = "01",
  MORAL = "02",
}
export enum MerchantTypeEnum {
  NATURAL = "01",
  JURIDICO = "02",
}

export enum LegalPersonTypeEnum {
  SHAREHOLDER = "ACCIONISTA",
  LEGAL_REPRESENTATIVE = "REPRESENTANTE_LEGAL",
}

export enum ConfigIdEnum {
  CN001 = "cn001",
  CN002 = "cn002",
  CN006 = "cn006",
  CN015 = "cn015",
  CN016 = "cn016",
  CN018 = "cn018",
}

export interface IMultipleFormValues {
  name: string;
  value: string;
  default?: boolean;
  defaultShareholderDocType?: string;
}

export const personType: IMultipleFormValues[] = [
  { name: "Físico", value: "01" },
  { name: "Moral", value: "02" },
];

export const personTypeSh: IMultipleFormValues[] = [
  { name: "Moral", value: "02" },
  { name: "Física", value: "01" },
];

export enum documentTypeName {
  IDENTIFICATION_DOCUMENT = "Documento de Identidad",
  PASSPORT = "Pasaporte",
  CARNE = "Carné de extranjería",
  DPI = "DPI",
  NIT = "NIT",
  RNP = "RNP",
  RTN = "RTN",
  DUI = "DUI",
}

export const documentType: IMultipleFormValues[] = [
  {
    defaultShareholderDocType: "0",
    name: "Documento de Identidad",
    value: "0",
  },
  { defaultShareholderDocType: "1", name: "Pasaporte", value: "1" },
];

export const documentTypeByCountry = {
  [CountryEnum.brasil]: {
    sIdTypes: CatalogBr[CatalogsEnum.IdTypes],
  },
  [CountryEnum.chile]: {
    sIdTypes: CatalogCl[CatalogsEnum.IdTypes],
  },
  [CountryEnum.colombia]: {
    sIdTypes: CatalogCo[CatalogsEnum.IdTypes],
  },
  [CountryEnum.costa_rica]: {
    sIdTypes: CatalogCr[CatalogsEnum.IdTypes],
  },
  [CountryEnum.ecuador]: {
    sIdTypes: CatalogEc[CatalogsEnum.IdTypes],
  },
  [CountryEnum.el_salvador]: {
    sIdTypes: CatalogEl[CatalogsEnum.IdTypes],
  },
  [CountryEnum.guatemala]: {
    sIdTypes: CatalogGt[CatalogsEnum.IdTypes],
  },
  [CountryEnum.honduras]: {
    sIdTypes: CatalogHn[CatalogsEnum.IdTypes],
  },
  [CountryEnum.mexico]: {
    sIdTypes: CatalogMx[CatalogsEnum.IdTypes],
  },
  [CountryEnum.nicaragua]: {
    sIdTypes: CatalogNi[CatalogsEnum.IdTypes],
  },
  [CountryEnum.panama]: {
    sIdTypes: CatalogPan[CatalogsEnum.IdTypes],
  },
  [CountryEnum.peru]: {
    sIdTypes: CatalogPe[CatalogsEnum.IdTypes],
  },
};

export const documentTypeSh: IMultipleFormValues[] = [
  { name: documentTypeName.IDENTIFICATION_DOCUMENT, value: "0" },
  { name: documentTypeName.PASSPORT, value: "1" },
  { name: documentTypeName.CARNE, value: "2" },
];

export const documentTypeBM: IMultipleFormValues[] = [
  { name: documentTypeName.IDENTIFICATION_DOCUMENT, value: "0" },
  { name: documentTypeName.PASSPORT, value: "1" },
];

export const getPersonName = (
  value: string,
  TypePersonCountryCatalog: Array<PersonTypeObjectInterface>
): string => {
  return get(find(TypePersonCountryCatalog, { value: value }), "name", "");
};

export const countriesWithDepartment = [
  CountryEnum.chile,
  CountryEnum.costa_rica,
  CountryEnum.peru,
];

export enum DocumentTypeEnum {
  DOCUMENTO_IDENTIDAD = "0",
  PASAPORTE = "1",
}
