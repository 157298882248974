import React, { FC } from "react";
import { Box, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { mainContainerStyles as styles } from "./MainContainer.styles";
import Subheader from "../../components/Subheader/Subheader";
import Buttons from "../../shared/constants/labels/buttons_labels";
import { useMainContainer } from "./state/useMainContainer";
import Wallet from "../../components/Wallet/Wallet";

const MainContainer: FC = () => {
  const { transactionType, subheaderInfo, currency, publicMerchantId } =
    useMainContainer();

  return (
    <Box sx={styles.boxContainer}>
      <IconButton
        sx={styles.returnButton}
        onClick={() => window.history.go(-1)}
      >
        <ArrowBackIosIcon />
        {Buttons.RETURN_BUTTON}
      </IconButton>
      <Subheader {...subheaderInfo} />
      <Wallet
        transactionType={transactionType}
        currency={currency}
        publicMerchantId={publicMerchantId}
      />
    </Box>
  );
};

export default MainContainer;
