import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const resumeBoxStyles = createNamedStyles({
  box: {
    background:
      "linear-gradient(180deg, rgba(255,255,255,1) 50%, rgba(247,250,252,1) 100%)",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-evenly",
  },
  icon: {
    marginLeft: "10pt",
  },
});
