import { FilterSideBarProps } from "../../components/Filters/FilterSideBar/FilterSideBar.interfaces";
import {
  BRANCH_MANUAL_LABELS,
  LABELS_GROUP_HEADER,
  LABELS_STATUS_HEADER,
} from "./labels/branch_manual_creation_labels";
import { ClientTypeEnum } from "../enums/ClientTypeEnum";
import { FilterTextsEnum } from "../enums/FilterTextsEnum";
import { ItemCategoryProps } from "../../components/Filters/ItemCategory/interfaces";
import { EditStatusEnum } from "../enums/EditStatusEnum";

const STATUS_CATEGORY_ITEM: ItemCategoryProps = {
  groupSection: BRANCH_MANUAL_LABELS.STATUS,
  isMinimize: true,
  items: [
    {
      label: LABELS_STATUS_HEADER.INCOMPLETE,
      selected: false,
      value: EditStatusEnum.pending,
    },
    {
      label: LABELS_STATUS_HEADER.NO_MODIFIED,
      selected: false,
      value: EditStatusEnum.not_modified,
    },
    {
      label: LABELS_STATUS_HEADER.MODIFIED,
      selected: false,
      value: EditStatusEnum.complete,
    },
  ],
  selectType: "multiple",
  type: "byChips",
};

const GROUP_CATEGORY_ITEM: ItemCategoryProps = {
  groupSection: BRANCH_MANUAL_LABELS.GROUP,
  isMinimize: true,
  items: [
    {
      label: LABELS_GROUP_HEADER.CENTRALIZE,
      selected: false,
      value: false + "",
    },
    {
      label: LABELS_GROUP_HEADER.DESCENTRALIZE,
      selected: false,
      value: true + "",
    },
  ],
  selectType: "multiple",
  type: "byChips",
};

const COUNTRY_CATEGORY_ITEM: ItemCategoryProps = {
  groupSection: BRANCH_MANUAL_LABELS.COUNTRY,
  isMinimize: true,
  items: [],
  selectType: "multiple",
  type: "byChips",
};

const CLIENT_CATEGORY_ITEM: ItemCategoryProps = {
  groupSection: BRANCH_MANUAL_LABELS.CLIENT_TYPE,
  isMinimize: true,
  items: [
    {
      label: ClientTypeEnum.B2C,
      selected: false,
      value: ClientTypeEnum.B2C,
    },
    {
      label: ClientTypeEnum.B2B,
      selected: false,
      value: ClientTypeEnum.B2B,
    },
    {
      label: ClientTypeEnum.PSP,
      selected: false,
      value: ClientTypeEnum.PSP,
    },
  ],
  selectType: "multiple",
  type: "byChips",
};

export const INITIAL_CREATE_FILTERS: FilterSideBarProps = {
  categoryItems: [
    GROUP_CATEGORY_ITEM,
    COUNTRY_CATEGORY_ITEM,
    CLIENT_CATEGORY_ITEM,
  ],
  filtersCount: 0,
  isMinimized: false,
  isOpen: true,
  onClick: () => {},
  title: FilterTextsEnum.FILTER_BY,
};

export const INITIAL_EDIT_FILTERS: FilterSideBarProps = {
  categoryItems: [
    STATUS_CATEGORY_ITEM,
    COUNTRY_CATEGORY_ITEM,
    CLIENT_CATEGORY_ITEM,
  ],
  filtersCount: 0,
  isMinimized: false,
  isOpen: true,
  onClick: () => {},
  title: FilterTextsEnum.FILTER_BY,
};
