import { useState } from "react";
import { ampm } from "../../../../shared/utils";
import { History } from "../../../../../types/security_rule";
import { DataModal } from "../../../DialogTimeLine/DialogTimeLine";
import { format } from "date-fns";

export interface TimeLineState {
  actions: {
    getFormatDate: (date: number) => string;
    getFormatHour: (date: number) => string;
    handleShowDialogTimeline: (history: History) => void;
    handleFinish: () => void;
  };
  openDialogTimeLine: boolean;
  dataModal: DataModal | undefined;
}

export const useTimeLineState = (): TimeLineState => {
  const [openDialogTimeLine, setOpenDialogTimeLine] = useState<boolean>(false);
  const [dataModal, setDataModal] = useState<DataModal>();

  const getFormatDate = (date: number): string => {
    return format(new Date(date), "yyyy-MM-dd");
  };

  const getFormatHour = (date: number): string => {
    const time: string = format(new Date(date), "hh:mm:ss");
    return `${time} ${ampm(Number(format(new Date(date), "HH")))}`;
  };

  const handleShowDialogTimeline = (history: History): void => {
    setOpenDialogTimeLine(true);
    setDataModal({
      history,
      dateFormat: getFormatDate(history.created),
      hourFormat: getFormatHour(history.created),
    });
  };

  const handleFinish = (): void => {
    setOpenDialogTimeLine(false);
  };

  return {
    actions: {
      getFormatDate,
      getFormatHour,
      handleShowDialogTimeline,
      handleFinish,
    },
    openDialogTimeLine,
    dataModal,
  };
};
