import React from "react";
import { TextField } from "@material-ui/core";
import { useTypedController } from "@hookform/strictly-typed";
import ConnectForm from "../../commons/ConnectForm/ConnectForm";
import { ISecurityRuleForm } from "../../../shared/infrastructure/interfaces/ISecurityRuleForm";
import { SecurityRulesLabelField } from "../../../shared/infrastructure/constants/SecurityRulesConstants";
import { get, isEmpty } from "lodash";
import { Autocomplete } from "@material-ui/lab";
import { useWatch } from "react-hook-form";
import { IOptionSelection } from "../../../shared/infrastructure/constants/RulesConstants";
import { OPTION_ALL } from "../../../shared/infrastructure/constants/BusinessRulesEnum";
import { findName } from "../../../shared/utils";

export interface AutocompleteMultipleFieldComponentProps {
  index: number;
  disabled: boolean;
  items: string[];
  options: IOptionSelection[];
  compare: "name" | "value";
  path: "name" | "value";
}

export const AutocompleteMultipleFieldComponent: React.FC<AutocompleteMultipleFieldComponentProps> =
  (props: AutocompleteMultipleFieldComponentProps) => {
    return (
      <ConnectForm<ISecurityRuleForm>>
        {({ control, errors, getErrorMessage }) => {
          const TypedController = useTypedController<ISecurityRuleForm>({
            control: control,
          });
          const value = useWatch({
            name: `strictCondition[${props.index}].value`,
          }) as string[];
          return (
            <React.Fragment>
              <TypedController
                name={["strictCondition", props.index, "value"]}
                rules={{ required: true }}
                render={({ onChange }) => (
                  <Autocomplete
                    data-testid="strictConditionWrapper"
                    value={getDefaultValue(value)}
                    options={props.items}
                    onChange={(_event: any, value: any) => {
                      value = getAllOptionValues(value, props.options);
                      onChange(value);
                    }}
                    multiple={true}
                    defaultValue={value}
                    getOptionLabel={(option) =>
                      findName(props.options, option, props.compare, props.path)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          !!get(errors, `strictCondition.${props.index}.value`)
                        }
                        label={SecurityRulesLabelField.VALUE}
                        variant="outlined"
                        disabled={props.disabled}
                        fullWidth
                        helperText={
                          !!get(
                            errors,
                            `strictCondition.${props.index}.value`
                          ) &&
                          getErrorMessage(
                            get(errors, `strictCondition.${props.index}.value`)
                          )
                        }
                      />
                    )}
                  />
                )}
              />
            </React.Fragment>
          );
        }}
      </ConnectForm>
    );
  };

const getDefaultValue = (values: string[]): string[] => {
  if (!Array.isArray(values)) {
    return [];
  }
  return isEmpty(values) ? [] : values;
};

const getAllOptionValues = (
  values: string[],
  options: IOptionSelection[]
): string[] => {
  const selectedOptions: IOptionSelection[] = JSON.parse(
    JSON.stringify(options)
  );
  if (selectedOptions.find((element) => element.value === OPTION_ALL.value)) {
    selectedOptions.splice(0, 1);
    values =
      values.find((element) => element === OPTION_ALL.value) != undefined
        ? selectedOptions.map((option) => option.value)
        : values;
  }
  return values;
};
export default AutocompleteMultipleFieldComponent;
