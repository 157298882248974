/**
 * TransactionStatus
 */
export enum TransactionTypeEnum {
  SALE = "SALE",
}

export const getTransactionTypeTranslate = (value: string): string => {
  switch (value) {
    case TransactionTypeEnum.SALE:
      return "Venta";
    default:
      return value;
  }
};
