import { Grid, MenuItem } from "@mui/material";
import React, { FC } from "react";
import { INVOICE_FREQUENCY } from "../../shared/constants/invoice_frequency";
import { InvoiceModeCatalog } from "../../shared/constants/invoice_mode";
import {
  FREQUENCY_SELECT_LABEL,
  MODE_SELECT_LABEL,
} from "../../shared/constants/labels/billing_form_labels";
import { BillingFormInputNames } from "../../shared/enums/billing_form_names";
import { FrequencyEnum } from "../../shared/enums/frequencyEnum";
import { InvoiceModeEnum } from "../../shared/enums/InvoiceModeEnum";
import SelectItemsField from "../FormInputFields/SelectItemsField/SelectItemsField";
import { IBillingForm } from "./BillingForm.interfaces";
import { useBillingForm } from "./state/useBillingForm";
import TransactionSection from "../TransactionForm/TransactionSection";

const BillingForm: FC<IBillingForm> = ({ control, errors }: IBillingForm) => {
  const { country, showBillingMode, isCentralized } = useBillingForm({
    control,
  });

  return (
    <Grid container columnGap={2}>
      <Grid item md={5.85} xs={12}>
        <SelectItemsField
          disabled={isCentralized}
          name={BillingFormInputNames.INVOICE_FREQUENCY}
          control={control}
          errors={errors}
          label={FREQUENCY_SELECT_LABEL}
          defaultValue={FrequencyEnum.NONE}
          items={INVOICE_FREQUENCY.map((item, index) => (
            <MenuItem key={`if-${index}`} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        />
      </Grid>
      <Grid item md={5.85} xs={12}>
        {showBillingMode && (
          <SelectItemsField
            disabled={isCentralized}
            name={BillingFormInputNames.INVOICE_MODE}
            control={control}
            isRequired={showBillingMode}
            errors={errors}
            label={MODE_SELECT_LABEL}
            defaultValue={InvoiceModeEnum.WITH_IVA}
            items={InvoiceModeCatalog.map((item, index) => (
              <MenuItem key={`im-${index}`} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          />
        )}
      </Grid>
      <TransactionSection country={country} isCentralized={isCentralized} />
    </Grid>
  );
};

export default BillingForm;
