import { createNamedStyles } from "../../shared/utils";

export const transactionsEmptyStateStyles = createNamedStyles({
  container: {
    alignItems: "center",
    backgroundColor: "neutral.white",
    display: "flex",
    gap: 5,
    height: 495,
    justifyContent: "center",
  },
  inboxIcon: {
    color: "neutral.grey4",
    height: 95,
    width: 99,
  },
  title: {
    color: "neutral.grey7",
    fontSize: (theme) => theme.spacing(2.375),
    fontWeight: 500,
  },
  subtitle: {
    color: "neutral.grey7",
    fontSize: (theme) => theme.spacing(1.5),
    fontWeight: "normal",
  },
});
