import React from "react";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Trash2, X } from "react-feather";
import SimpleChip from "../commons/SimpleChip/SimpleChip";
import { BankModal } from "../commons/BankModal/BankModal";
import { get } from "lodash";
import { useMexicanVariantSection } from "./useMexicanVariantSection/useMexicanVariantSection";
import { IBank } from "../MexicoSection/state/useMexicoSection";
import { defaultTo, set } from "lodash";
import { lengthBanksInModalEnum } from "../../shared/infrastructure/constants/lengthBanksInModalEnum";
import { MexicoMonthSection } from "../MexicoVariantMonthSection/MexicoMonthSection";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  bankListMobile: {
    background: "#F7FAFC",
    color: "#293036",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "100%",
    margin: "6px",
    overflow: "hidden",
    padding: "6px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap" as "nowrap",
  },
  cardH2: {
    color: "#293036",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "19.6px",
  },
  divider: {
    marginTop: "30px",
  },
  inputBank: {
    paddingLeft: "10px",
  },
  label: {
    color: "#293036",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "18px",
    paddingBottom: "8px",
  },
  labelH2: {
    color: "#293036",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "16px",
    paddingTop: "20px",
  },
  labelSelect: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 2,
    paddingBottom: "0px",
    paddingTop: "0px",
    width: "270px",
    wordWrap: "break-word",
  },
  linkModal: {
    position: "absolute",
    right: "10px",
    top: "60px",
  },
  menuItem: {
    paddingBottom: "0px",
    paddingTop: "0px",
  },
  padding: {
    paddingBottom: "15px",
  },
  select: {
    borderStyle: "solid",
    marginLeft: "2px",
    maxWidth: "328px",
  },
  tittle: {
    color: "#6D7781",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    paddingBottom: "8px",
  },
  toggleButtonGroup: {
    flexWrap: "wrap",
  },
  trashIcon: {
    color: "#000000",
  },
}));

export interface MexicanVariantProps {
  banks: IBank[];
  isMobile: boolean;
  selectedBanks: string[];
  index: number;
  months: string[];
  monthsOfGrace: string[];
  merchantMonths: string[];
  merchantMonthsOfGrace: string[];
  handleDeleteVariant(id: number): void;
  handleSetMonthVariant(
    event: React.MouseEvent<HTMLElement>,
    value: string[],
    index: number
  ): void;
  handleSetMonthOfGraceVariant(
    event: React.MouseEvent<HTMLElement>,
    value: string[],
    index: number
  ): void;
  handleSetBank(name: string, index: number): void;
  handleDeleteBank(name: string, index: number): void;
  handleBankModal: (banks: string[], index: number) => void;
  bankModalIsOpen: boolean[];
  handleCloseBankModal: () => void;
}

export const MexicanVariantSection = (props: MexicanVariantProps) => {
  const classes = useStyles();

  const { searchText, handleFilterBanks, handleSearch } =
    useMexicanVariantSection(props.index);

  return (
    <>
      {props.index === 0 && <Divider />}
      <Box>
        <Toolbar disableGutters>
          <Box display="flex" flexGrow={1}>
            <Box mt={"26px"}>
              <Typography className={classes.tittle}>
                Personalizar variante
              </Typography>
              <Typography className={classes.label}>
                Selecciona uno o más bancos a los que deseas realizar la
                variante:
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            <IconButton
              aria-label="Delete"
              onClick={() => props.handleDeleteVariant(props.index)}
            >
              <Trash2 size={24} className={classes.trashIcon} />
            </IconButton>
          </Box>
        </Toolbar>
      </Box>
      <Box mt={"8px"}>
        <Grid container>
          <Grid item xs={12} md={4}>
            <Autocomplete
              id="selected_banks"
              options={handleFilterBanks(props.banks)}
              disableCloseOnSelect
              getOptionLabel={(option) => option.label}
              ListboxProps={{ style: { maxHeight: "350px" } }}
              renderOption={(propsAuto) => (
                <MenuItem
                  key={get(propsAuto, "label")}
                  value={get(propsAuto, "label")}
                  className={classes.menuItem}
                  {...propsAuto}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={propsAuto.label}
                        color="primary"
                        checked={get(propsAuto, "checked")}
                        name={propsAuto.label}
                        size="small"
                        onChange={() =>
                          props.handleSetBank(
                            get(propsAuto, "label"),
                            props.index
                          )
                        }
                      />
                    }
                    label={
                      <Typography
                        noWrap
                        color="textPrimary"
                        className={classes.labelSelect}
                      >
                        {propsAuto.label}
                      </Typography>
                    }
                    value={propsAuto.label}
                  />
                </MenuItem>
              )}
              style={{ width: 450 }}
              renderInput={(params) => {
                const value = get(params, "inputProps.value");

                value === searchText
                  ? set(params, "inputProps.value", value)
                  : set(params, "inputProps.value", searchText);

                set(params, "InputProps.endAdornment.props.children[0]", null);

                return (
                  <TextField
                    {...params}
                    label="Red de bancos"
                    placeholder="Red de bancos..."
                    variant="outlined"
                    className={classes.select}
                    onChange={handleSearch}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </Box>
      {!props.isMobile ? (
        <div>
          {props.selectedBanks.length > 0 &&
            props.selectedBanks
              .sort()
              .map(
                (selectedBank, index) =>
                  index < lengthBanksInModalEnum.BANK_IN_MODAL_FROM_INDEX && (
                    <SimpleChip
                      key={index}
                      label={selectedBank}
                      onDelete={() =>
                        props.handleDeleteBank(selectedBank, props.index)
                      }
                      deleteIcon={<X color={"#293036"} />}
                    />
                  )
              )}
          {props.selectedBanks.length >
            lengthBanksInModalEnum.BANK_IN_MODAL_FROM_INDEX && (
            <Link
              component="button"
              onClick={() =>
                props.handleBankModal(props.selectedBanks, props.index)
              }
            >
              Ver más
            </Link>
          )}
        </div>
      ) : (
        <Grid container>
          <Grid item xs={12} className={classes.labelH2}>
            {props.selectedBanks.length > 0 &&
              props.selectedBanks.sort().map(
                (selectedBank, index) =>
                  index <
                    lengthBanksInModalEnum.BANK_IN_MODAL_FROM_INDEX_MOBILE && (
                    <Typography
                      key={index}
                      onClick={() =>
                        props.handleDeleteBank(selectedBank, props.index)
                      }
                      className={classes.bankListMobile}
                    >
                      {selectedBank}
                    </Typography>
                  )
              )}
            {props.selectedBanks.length >
              lengthBanksInModalEnum.BANK_IN_MODAL_FROM_INDEX_MOBILE && (
              <Link
                component="button"
                onClick={() =>
                  props.handleBankModal(props.selectedBanks, props.index)
                }
              >
                Ver más
              </Link>
            )}
          </Grid>
        </Grid>
      )}
      <Box mt={"24px"}>
        <Typography className={classes.label}>
          Selecciona las cuotas o meses que deseas modificar:
        </Typography>
      </Box>
      <MexicoMonthSection
        index={props.index}
        mainTittle={"Mensualidades"}
        secondTittle={"Meses de gracia"}
        mainMonths={props.months}
        secondMonths={props.monthsOfGrace}
        mainMerchantMonths={props.merchantMonths}
        secondMerchantMonth={props.merchantMonthsOfGrace}
        handleSetMonthVariant={props.handleSetMonthVariant}
        handleSetMonthOfGraceVariant={props.handleSetMonthOfGraceVariant}
      />
      <Divider />
      <BankModal
        open={props.bankModalIsOpen[props.index]}
        banks={
          defaultTo(props.selectedBanks.sort().length, 0) >
          lengthBanksInModalEnum.BANK_IN_MODAL_FROM_INDEX
            ? props.selectedBanks
                .sort()
                .slice(lengthBanksInModalEnum.BANK_IN_MODAL_FROM_INDEX)
            : []
        }
        handleCloseDialog={props.handleCloseBankModal}
      />
    </>
  );
};
