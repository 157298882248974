import { Box } from "@mui/material";
import { FC } from "react";
import { transactionsTableFooterStyles } from "./TransactionTableFooter.styles";
import TransactionTableFooterLimitSelect from "./TransactionTableFooterLimitSelect/TransactionTableFooterLimitSelect";
import TransactionTableFooterPagination from "./TransactionTableFooterPagination/TransactionTableFooterPagination";

export const TransactionTableFooter: FC = () => (
  <Box sx={transactionsTableFooterStyles.container}>
    <TransactionTableFooterLimitSelect />
    <TransactionTableFooterPagination />
  </Box>
);
