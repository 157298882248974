import { ActionTypes } from "./actionTypes";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import {
  IAppState,
  INotification,
  IUserListResponse,
  INITIAL_STATE,
  MerchantRequest,
  CardMerchantResponse,
  CommissionResponse,
  SubscriptionsResponse,
  VPOSResponse,
} from "./reducer";
import axiosInstance, { AxiosError, AxiosResponse } from "axios";
import axios from "../shared/axios-util";
import { environment } from "../environments/environment";
import { IAuthInfo } from "../shared/infrastructure/interfaces/IAuthInfo";
import { auth } from "../shared/auth";
import { MerchantResponse } from "../../types/merchant_response";
import { BillingConfig } from "../../types/billing_config";
import { get, isEmpty } from "lodash";
import { Color } from "@material-ui/lab/Alert";
import { Webhook } from "../../types/webhook";
import {
  SmartlinksCustomization,
  SmartlinksLogos,
} from "../../types/smartlink_customization";
import { INotificationState } from "../shared/infrastructure/interfaces/INotificationState";
import { INotificationResponse } from "../shared/infrastructure/interfaces/INotificationResponse";
import { SmartlinkResponse } from "../../types/smartlink_get_response";
import { SmartlinkRequest } from "../../types/smartlink_post_request";
import { ProcessorFetch } from "../shared/infrastructure/interfaces/ProcessorFetch";
import { MerchantCredential } from "../shared/infrastructure/interfaces/MerchantCredential";
import { MerchantSettings } from "../shared/infrastructure/interfaces/MerchantSettings";
import { ResponseTrxRates } from "../../types/response_trx_rates";
import { RateResponse } from "../../types/rate_response";
import { CurrencyEnum } from "../shared/infrastructure/constants/currency-enum";
import { CURRENCY_COUNTRY } from "../shared/infrastructure/constants/MerchantInformationConstant";
import {
  MerchantProcessorFetch,
  Processors,
} from "../shared/interfaces/MerchantProcessorFetch";
import { IDiscountResponse } from "../../types/discount_response";
import {
  ConfigIndexServicesConstants,
  PaymentMethods,
} from "../shared/infrastructure/constants/MerchantConfigConstants";
import {
  DeferredOption,
  DeferredOptions,
} from "../shared/infrastructure/interfaces/DeferredOptions";
import { WebhookListFetch } from "../shared/interfaces/WebhookListFetch";
import { ImageInsert } from "@kushki/frontend-molecules/gallery/interfaces/ImageInsert";
import { MerchantProcessorsRules } from "../../types/merchant_processors_rules_response";
import { DispersionConfigurationResponse } from "../../types/dispersion_configuration";
import { GetAppConfigPaymentMethodsResponse } from "../../types/get_app_config_payment_method_response";

export type IAppAction = { type: string } & IAppState;

export const setRule = (payload: MerchantProcessorsRules): IAppAction => {
  return {
    type: ActionTypes.SET_RULES,
    rules: payload,
  };
};

export interface MerchantIdResponse {
  publicMerchantId?: string;
}

export interface RateMerchantRequest {
  merchantId: string;
  country: string;
}

export const setMerchant = (payload: MerchantResponse): IAppAction => {
  return {
    type: ActionTypes.SET_MERCHANT,
    merchant: payload,
  };
};

export const setCardOptions = (payload: CardMerchantResponse): IAppAction => {
  return {
    type: ActionTypes.SET_CARD_OPTIONS_REQUEST,
    cardOptions: payload,
  };
};

export const setVposOptions = (payload: VPOSResponse): IAppAction => {
  return {
    type: ActionTypes.SET_VPOS_OPTIONS_REQUEST,
    vposOptions: payload,
  };
};

export const setComissionOptions = (
  payload: CommissionResponse
): IAppAction => {
  return {
    type: ActionTypes.SET_COMISSION_OPTIONS_REQUEST,
    comissionOptions: payload,
  };
};

export const setSubscriptionOptions = (
  payload: SubscriptionsResponse
): IAppAction => {
  return {
    type: ActionTypes.SET_SUBSCRIPTION_OPTIONS_REQUEST,
    subscriptionOptions: payload,
  };
};

export const setLoading = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_IS_LOADING,
    isLoading: payload,
  };
};

export const setWaitingForRequest = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_WAITING_FOR_REQUEST,
    waitingForRequest: payload,
  };
};

export const setTrxRates = (payload: ResponseTrxRates[]) => {
  return {
    type: ActionTypes.SET_TRX_RATES,
    transactionRatesData: payload,
  };
};

export const setRateMerchant = (payload: RateResponse): IAppAction => {
  const formatRequest: RateResponse = {
    country: payload.country,
    defaultCurrency: payload.defaultCurrency,
    taxpayerKind: payload.taxpayerKind,
    fraudPercentage: payload.fraudPercentage,
    isB2C: payload.isB2C,
    merchantId: payload.merchantId,
    specialTaxpayer: payload.specialTaxpayer,
    keepFraud: payload.keepFraud,
    retentionPeriod: payload.retentionPeriod,
  };

  return {
    type: ActionTypes.SET_RATE_MERCHANT,
    rate: formatRequest,
  };
};

export const setWalletRechargeConfig = (
  payload: DispersionConfigurationResponse
) => {
  return {
    type: ActionTypes.SET_WALLET_RECHARGE_CONFIGURATION,
    walletRechargeConfig: payload,
  };
};

export const getRateDefault = (country: string): RateResponse => {
  return {
    country: country,
    defaultCurrency: CURRENCY_COUNTRY.get(country) as CurrencyEnum,
    merchantId: "",
    isB2C: false,
    fraudPercentage: 0,
    specialTaxpayer: false,
    taxpayerKind: "0",
    keepFraud: true,
    retentionPeriod: 0,
  };
};

export const setBillingConfig = (payload: BillingConfig): IAppAction => {
  return {
    type: ActionTypes.SET_BILLING_CONFIG,
    billingConfig: payload,
  };
};

export const setMerchantResponse = (payload: {
  publicMerchantId: string;
}): IAppAction => {
  return {
    type: ActionTypes.SET_MERCHANT_RESPONSE,
    merchantResponse: payload,
  };
};

export const setSmartlinkInfo = (payload: SmartlinkResponse): IAppAction => {
  return {
    type: ActionTypes.SET_SMARTLINK_INFO,
    smartLinkInfo: payload,
  };
};

export const setBillingConfigResponse = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_BILLING_CONFIG_RESPONSE,
    billingConfigResponse: payload,
  };
};

export const setNotification = (notify: INotification): IAppAction => {
  return {
    type: ActionTypes.SET_NOTIFICATION,
    notification: notify,
  };
};

export const setWebhooks = (payload: Webhook[]): IAppAction => {
  return {
    type: ActionTypes.SET_WEBHOOKS,
    webhooks: payload,
  };
};

export const setCustomization = (payload: MerchantResponse): IAppAction => {
  return {
    type: ActionTypes.SET_CUSTOMIZATION,
    customization: payload,
  };
};
// export const setLoading = (payload: boolean): IAppAction => {
//   return {
//     type: ActionTypes.SET_LOADING,
//     loading: payload,
//   };
// };
export const setLoadingConfig = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_LOADING_CONFIG,
    loadingConfig: payload,
  };
};

export const setProcessors = (payload: ProcessorFetch[]): IAppAction => {
  return {
    type: ActionTypes.SET_PROCESSORS,
    processors: payload,
  };
};

const dispatchNotification = (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>,
  msg: string,
  type: Color
): void => {
  dispatch(
    setNotification({
      message: msg,
      open: true,
      type: type,
    })
  );
};

export const setAuthInfo = (payload: IAuthInfo): IAppAction => {
  return {
    type: ActionTypes.SET_AUTH_INFO,
    authInfo: payload,
  };
};

export const setWebCheckout = (payload: any) => {
  return {
    type: ActionTypes.SET_WEB_CHECKOUT,
    webCheckout: payload,
  };
};

export const setIsWebcheckoutLoaded = (payload: boolean) => {
  return {
    type: ActionTypes.SET_IS_WEBCHECKOOUT_LOADED,
    isWebcheckoutLoaded: payload,
  };
};

export const setMerchantRequest = (payload: MerchantRequest): IAppAction => {
  return {
    type: ActionTypes.SET_MERCHANT_REQUEST,
    merchantRequest: payload,
  };
};

export const setDiscountInfo = (payload: IDiscountResponse): IAppAction => {
  return {
    type: ActionTypes.SET_DISCOUNT_INFO,
    discountInfo: payload,
  };
};

export const setUserListAction = (payload: IUserListResponse) => ({
  type: ActionTypes.SET_USER_LIST,
  fetchUsers: payload,
});

export const setMerchantProcessor = (
  payload: MerchantProcessorFetch[] | undefined
) => {
  return {
    type: ActionTypes.SET_MERCHANT_PROCESSORS,
    merchantProcessors: payload,
  };
};

export const setIsLoadingGetMerchantProcessors = (
  payload: boolean
): IAppAction => {
  return {
    type: ActionTypes.SET_IS_LOADING_GET_MERCHANT_PROCESSORS,
    isLoadingGetMerchantProcessors: payload,
  };
};

export const setDefaultProcessor = (payload: object): IAppAction => {
  return {
    type: ActionTypes.SET_DEFAULT_PROCESSOR,
    defaultProcessor: payload,
  };
};

export const setIsLoadingDefaultProcessor = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_IS_LOADING_DEFAULT_PROCESSOR,
    isLoadingDefaultProcessor: payload,
  };
};

export const setDeferred = (payload: DeferredOption[]): IAppAction => {
  return {
    type: ActionTypes.SET_DEFERRED,
    deferred: payload,
  };
};

export const setIsDeferredLoaded = (payload: boolean) => {
  return {
    type: ActionTypes.SET_IS_DEFERRED_LOADED,
    isDeferredLoaded: payload,
  };
};

export const setIsRuleLoaded = (payload: boolean) => {
  return {
    type: ActionTypes.SET_IS_DEFERRED_LOADED,
    isRuleLoaded: payload,
  };
};

export const setPaymentCredentials = (
  payload: MerchantCredential[]
): IAppAction => {
  return {
    type: ActionTypes.SET_PAYMENT_CREDENTIALS,
    paymentCredentials: payload,
  };
};

export const setAppConfigPaymentMethods = (
  payload: GetAppConfigPaymentMethodsResponse
): IAppAction => {
  return {
    type: ActionTypes.SET_APPCONFIG_PAYMENT_METHODS,
    appConfigPaymentMethods: payload,
  };
};

export const setIsLoadingPaymentCredentials = (
  payload: boolean
): IAppAction => {
  return {
    type: ActionTypes.SET_IS_LOADING_PAYMENT_CREDENTIALS,
    isLoadingPaymentCredentials: payload,
  };
};

export const setWebhookList = (payload: WebhookListFetch): IAppAction => {
  return {
    type: ActionTypes.SET_WEBHOOK_LIST,
    webhookList: payload,
  };
};

export const setIsLoadingWebhookList = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_IS_LOADING_WEBHOOK_LIST,
    isLoadingWebhookList: payload,
  };
};

export const setImageSelected = (payload: ImageInsert | undefined) => {
  return {
    type: ActionTypes.SET_IMAGE_SELECTED,
    imageSelected: payload,
  };
};

export const setIsImageSelected = (payload: boolean) => {
  return {
    type: ActionTypes.SET_IS_IMAGE_SELECTED,
    isImageSelected: payload,
  };
};

export const getWebCheckout = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    dispatch(setIsWebcheckoutLoaded(false));
    const response: AxiosResponse<any> = await axios.get(
      `${environment.kushkiUrl}/smartlink/v1/admin/merchant/${merchantId}`
    );
    dispatch(setWebCheckout(response.data));
    dispatch(setIsWebcheckoutLoaded(true));
  };
};

export const getCardOptions = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .get(`${environment.kushkiUrl}/card/v1/admin/merchant/${merchantId}`)
      .then((response: AxiosResponse<CardMerchantResponse>) => {
        dispatch(setCardOptions(response.data));
      })
      .catch(() => {
        dispatch(
          setNotification({
            message: "Se ha producido un error vuelve a intentarlo",
            open: true,
            type: "error",
          })
        );
      });
  };
};

export const getVposOptions = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .get(`${environment.kushkiUrl}/vpos/v1/admin/merchant/${merchantId}`)
      .then((response: AxiosResponse<VPOSResponse>) => {
        dispatch(setVposOptions(response.data));
      })
      .catch(() => {
        dispatch(
          setNotification({
            message: "Se ha producido un error vuelve a intentarlo",
            open: true,
            type: "error",
          })
        );
      });
  };
};

export const getComissionOptions = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .get(
        `${environment.kushkiUrl}/commission/v1/admin/merchant/${merchantId}`
      )
      .then((response: AxiosResponse<CommissionResponse>) => {
        dispatch(setComissionOptions(response.data));
      })
      .catch(() => {
        dispatch(
          setNotification({
            message: "Se ha producido un error vuelve a intentarlo",
            open: true,
            type: "error",
          })
        );
      });
  };
};

// export const setWebcheckoutLoadedStatus = (
//   isLoaded: boolean
// ): ThunkAction<void, IAppState, undefined, IAppAction> => {
//   return async (
//     dispatch: ThunkDispatch<IAppState, any, IAppAction>
//   ): Promise<void> => {
//     dispatch(setIsWebcheckoutLoaded(isLoaded));
//   };
// };

export const getMerchantWebhooks = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .post(`${environment.kushkiUrl}/webhook/v1/list`, {
        merchantId,
        limit: 5,
      })
      .then((response: AxiosResponse<Webhook[]>) => {
        dispatch(setWebhooks(response.data));
      })
      .catch((e) => {
        console.log(e);
      });
  };
};

export const getSubscriptionOptions = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .get(
        `${environment.kushkiUrl}/v1/subscriptions/admin/merchant/${merchantId}`
      )
      .then((response: AxiosResponse<SubscriptionsResponse>) => {
        dispatch(setSubscriptionOptions(response.data));
      })

      .catch(() => {
        dispatch(
          setNotification({
            message: "Se ha producido un error vuelve a intentarlo",
            open: true,
            type: "error",
          })
        );
      });
  };
};

export const getDiscountInfo = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
) => {
  const url: string = `${environment.kushkiUrl}/rates/merchantDiscount/${merchantId}`;
  axios
    .get(url)
    .then((axios_response: AxiosResponse<IDiscountResponse>) => {
      dispatch(setDiscountInfo(axios_response.data));
    })
    .catch((e) => {
      console.log(e);
    });
};

export const getMerchantCustomization = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .get(`${environment.kushkiUrl}/merchant/v1/customization/${merchantId}`)

      .then((response: AxiosResponse) => {
        let customization: MerchantResponse;
        customization = get(response, "data", {});

        dispatch(setCustomization(customization));
      })
      .catch((e) => {
        console.log(e);
      });
  };
};

export const setMerchantCustomization = (
  merchantId: string,
  body: SmartlinksCustomization
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    dispatch(setLoading(true));
    const path = auth.isAdmin()
      ? `${environment.kushkiUrl}/merchant/v1/admin/merchant/${merchantId}`
      : `${environment.kushkiUrl}/merchant/v1/merchant/update`;

    const requestBody = auth.isAdmin()
      ? { customization: { ...body } }
      : { ...body };

    if (body.logos!.length > 0) {
      let logos: SmartlinksLogos[] = [];
      for (let i = 0; i < body.logos!.length; i++) {
        const isUrl =
          body.logos![i].image && body.logos![i].image!.match("https");
        if (isUrl) {
          try {
            const resImage = await axiosInstance.get(body.logos![i].image!, {
              responseType: "arraybuffer",
            });
            const image = Buffer.from(resImage.data, "binary").toString(
              "base64"
            );
            logos.push({ ...body.logos![i], image });
          } catch (e) {
            logos.push({ ...body.logos![i] });
          }
        } else {
          logos.push({ ...body.logos![i] });
        }
      }
      body.logos = logos;
    }
    axios
      .patch(path, requestBody)
      .then(() => {
        dispatch(setLoading(false));
        dispatch(
          setNotification({
            message: "Se ha guardado correctamente la información",
            open: true,
            type: "success",
          })
        );
        window.history.back();
      })
      .catch((_) => {
        dispatch(setLoading(false));
      });
  };
};

export const setSmartlink = (
  body: SmartlinkRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    const merchantId = localStorage.getItem("merchantId");
    axios
      .patch(
        `${environment.kushkiUrl}/smartlink/v1/admin/merchant/${merchantId}`,
        body
      )
      .then(() => {
        dispatch(
          setNotification({
            message: "Se ha guardado correctamente la información",
            open: true,
            type: "success",
          })
        );
      })
      .catch((e) => {
        setNotification({
          message: `${e.response.data.message}`,
          open: true,
          type: "error",
        });
      });
  };
};

export const setWebhookNotify = (
  merchantId: string,
  notificationState: INotificationState
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setLoading(true));
    if (notificationState.WebcheckoutPlus || notificationState.Webcheckout)
      axios
        .patch(
          `${environment.kushkiUrl}/smartlink/v1/admin/merchant/${merchantId}`,
          {
            notifyTransactionStatus: true,
          }
        )
        .then(() => {
          setEmailNotification(merchantId, notificationState, dispatch);
        })
        .catch(() => {
          dispatch(setLoading(false));
          dispatch(
            setNotification({
              message: "Se ha producido un error vuelve a intentarlo",
              open: true,
              type: "error",
            })
          );
        });
    else
      axios
        .patch(
          `${environment.kushkiUrl}/smartlink/v1/admin/merchant/${merchantId}`,
          {
            notifyTransactionStatus: false,
          }
        )
        .then(() => {
          setEmailNotification(merchantId, notificationState, dispatch);
        })
        .catch(() => {
          dispatch(setLoading(false));
          dispatch(
            setNotification({
              message: "Se ha producido un error vuelve a intentarlo",
              open: true,
              type: "error",
            })
          );
        });
  };
};

// export const setWebhookNotify = (payload: INotificationResponse) => {
//   return {
//     type: ActionTypes.SET_WEB_CHECKOUT,
//     webCheckout: payload,
//   };
// };

export const getWebhookNotify = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>) => {
    dispatch(setLoadingConfig(true));
    axios
      .get(`${environment.kushkiUrl}/webhook/v1/admin/webhook/${merchantId}`)
      .then((response: AxiosResponse<INotificationResponse>) => {
        dispatch(setWebCheckout(response.data));
        dispatch(setLoadingConfig(false));
      })
      .catch(() => {
        dispatch(
          setNotification({
            message: "Hubo un problema al obtener la configuración",
            open: true,
            type: "error",
          })
        );
      });
  };
};

export const setEmailNotification = (
  merchantId: string,
  notificationState: INotificationState,
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
) => {
  axios
    .patch(
      `${environment.kushkiUrl}/webhook/v1/admin/webhooks/notify/${merchantId}`,
      {
        sendEmailNotification: setNotificationBody(notificationState),
      }
    )
    .then(() => {
      dispatch(setLoading(false));
      dispatch(
        setNotification({
          message: "Se ha guardado la información correctamente",
          open: true,
          type: "success",
        })
      );

      window.location.href = auth.isAdmin()
        ? `/config-merchant/merchant/${merchantId}`
        : "/config-merchant";
    })
    .catch(() => {
      dispatch(setLoading(false));
      dispatch(
        setNotification({
          message: "Se ha producido un error vuelve a intentarlo",
          open: true,
          type: "error",
        })
      );
    });
};

export const setNotificationBody = (notificationState: INotificationState) => {
  return Object.keys(notificationState).reduce(
    (accumulator: string[], key: string) => {
      if (notificationState[key]) accumulator.push(key);
      return accumulator;
    },
    []
  );
};

export const getMerchant = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    try {
      const response = await axios.post<MerchantResponse>(
        `${environment.kushkiUrl}/billing-core/v1/merchant/merchantInfo`,
        {
          publicMerchantId: merchantId,
        }
      );
      const merchant = response.data;

      if (auth.isAdmin()) {
        const statusMerchant: AxiosResponse = await axios.post(
          `${environment.kushkiUrl}/analytics/v1/admin/merchant/get`,
          {
            merchant_id: merchantId,
            limit: 1,
            offset: 0,
          }
        );
        merchant.isActive = get(
          statusMerchant,
          "data.data[0]._source.isActive",
          false
        );
      }
      dispatch(setLoading(false));
      dispatch(setMerchant(merchant));
      const country: string = response.data.country;
      dispatch(getRateMerchant({ merchantId, country }));
      const taxId: string = response.data.taxId;
      dispatch(getDispersionConfiguration(taxId, merchant));
    } catch (e) {
      console.log(e);
    }
  };
};

export const getDispersionConfiguration = (
  taxId: string,
  merchant: MerchantResponse
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    try {
      const response = await axios.get<DispersionConfigurationResponse>(
        `${environment.kushkiUrl}/dispersions/v1/dispersion/configuration/${taxId}`
      );

      merchant.dispersionFrequency = response.data.frequency;
      dispatch(setMerchant(merchant));
      if (!!response.data.walletConfig)
        dispatch(setWalletRechargeConfig(response.data));
    } catch (e) {
      console.error(e);
    }
  };
};

export const getRateMerchant = (
  payload: RateMerchantRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): void => {
  axios
    .post<RateResponse>(`${environment.kushkiUrl}/costs/costs/configRates`, {
      merchantId: payload.merchantId,
    })
    .then((axios_response: AxiosResponse<RateResponse>) => {
      if (!isEmpty(axios_response.data))
        dispatch(setRateMerchant(axios_response.data));
      else {
        const rateDefault: RateResponse = getRateDefault(payload.country);
        dispatch(
          setRateMerchant({ ...rateDefault, merchantId: payload.merchantId })
        );
      }
    })
    .catch((_e) => {
      console.log(_e);
    });
};

export const getBillingConfig = (
  publicMerchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    try {
      const response = await axios.get<BillingConfig>(
        `${environment.kushkiUrl}/rates/billingConfig/${publicMerchantId}`
      );
      if (!isEmpty(response.data)) dispatch(setBillingConfig(response.data));
    } catch (e) {
      console.log(e);
    }
  };
};

export const getSmartlinkInfo = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    try {
      const response = await axios.get<SmartlinkResponse>(
        `${environment.kushkiUrl}/smartlink/v1/admin/merchant/${merchantId}`
      );

      dispatch(setSmartlinkInfo(response.data));
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateDispersion = (
  merchantInfo: MerchantResponse
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .post(`${environment.kushkiUrl}/billing-core/v1/merchant/create`, {
        ...merchantInfo,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201)
          dispatchNotification(
            dispatch,
            "La información ha sido actualizada exitosamente.",
            "success"
          );
      })
      .catch((err) => {
        console.log(err);
        dispatchNotification(
          dispatch,
          "Para actualizar los datos de dispersión es necesario agregar la información bancaria en la sección Cobros.",
          "error"
        );
      });
  };
};

export const updateMerchantAndBillingConfig = (
  merchantInfo: MerchantResponse,
  billingConfig: BillingConfig
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .post(`${environment.kushkiUrl}/billing-core/v1/merchant/create`, {
        ...merchantInfo,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          axios
            .post(`${environment.kushkiUrl}/rates/billingConfig`, {
              ...billingConfig,
            })
            .then((response) => {
              if (response.status === 200)
                dispatchNotification(
                  dispatch,
                  "La información ha sido actualizada exitosamente.",
                  "success"
                );
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatchNotification(
          dispatch,
          "Lo sentimos, no se pudo actualizar su información.",
          "error"
        );
      });
  };
};

export const getMerchantProcessors = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    try {
      const settingsResponse: AxiosResponse<MerchantSettings> = await axios.get(
        `${environment.kushkiUrl}/merchant/v1/admin/merchant/settings/${merchantId}`
      );
      dispatch(setProcessors(settingsResponse.data.processors.transfer));
    } catch (e) {
      dispatch(setProcessors([]));
      console.log(e);
    }
  };
};

export const updateBillingConfig = (
  billingConfig: BillingConfig
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .post(`${environment.kushkiUrl}/rates/setBillingConfig`, {
        ...billingConfig,
      })
      .then((response) => {
        if (response.status === 200)
          dispatchNotification(
            dispatch,
            "La información ha sido actualizada exitosamente.",
            "success"
          );
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getAuthMerchant = (): ThunkAction<
  void,
  IAppState,
  undefined,
  IAppAction
> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    const authMerchant: MerchantResponse = auth.getAuthMerchant();
    !isEmpty(authMerchant) && dispatch(setMerchant(authMerchant));
  };
};

export const getAuthInfo = (): ThunkAction<
  void,
  IAppState,
  undefined,
  IAppAction
> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    const authInfo: IAuthInfo = {
      isAdmin: auth.isAdmin(),
    };
    dispatch(setAuthInfo(authInfo));
  };
};

export const getTrxList = (body: {
  publicMerchantId: string;
}): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    const method: string = "/rates/ratesConfigs";
    try {
      const response: AxiosResponse<ResponseTrxRates[]> = await axios.post<
        ResponseTrxRates[]
      >(`${environment.kushkiUrl}${method}`, body);

      const responseData: ResponseTrxRates[] = response.data;
      dispatch(setTrxRates(responseData));
    } catch (e) {
      console.log(e);
    }
  };
};

export const getProcessors = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): void => {
  const processorsList: MerchantProcessorFetch[] = [];

  dispatch(setIsLoadingGetMerchantProcessors(true));
  axios
    .post<MerchantProcessorFetch[]>(
      `${environment.kushkiUrl}/analytics/v1/admin/${merchantId}/processors`,
      {
        limit: 10,
        offset: 0,
      }
    )
    .then((axiosResponse) => {
      get(axiosResponse, "data.data", []).forEach((processor: Processors) =>
        processorsList.push({ ...processor._source })
      );

      const card_processors: number = processorsList.filter(
        (processor: MerchantProcessorFetch) =>
          processor.paymentMethod === PaymentMethods.CARD
      ).length;
      window.localStorage.setItem("cardProcessors", card_processors.toString());
      dispatch(setMerchantProcessor(processorsList));
      dispatch(setIsLoadingGetMerchantProcessors(false));
    })
    .catch((_e: AxiosError) => {
      dispatch(setMerchantProcessor(undefined));
      dispatch(
        setNotification({
          message:
            ConfigIndexServicesConstants.GET_MERCHANT_PROCESSORS_ERROR_TITLE,
          open: true,
          type: "error",
        })
      );
      dispatch(setIsLoadingGetMerchantProcessors(false));
    });
};

export const getDefaultProcessor = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): void => {
  const url: string = `${environment.kushkiUrl}/rules/v1/admin/merchant/${merchantId}`;
  dispatch(setIsLoadingDefaultProcessor(true));
  axios
    .get(url)
    .then((axios_response: object) => {
      const response: object = get(axios_response, "data");
      dispatch(setDefaultProcessor(response));
      dispatch(setIsLoadingDefaultProcessor(false));
    })
    .catch((_e: AxiosError) => {
      dispatch(
        setNotification({
          message:
            ConfigIndexServicesConstants.GET_DEFAULT_PROCESSOR_ERROR_TITLE,
          open: true,
          type: "error",
        })
      );
      dispatch(setIsLoadingDefaultProcessor(false));
    });
};

export const getUserList = (
  merchantId: String
): ThunkAction<void, IAppState, any, IAppAction> => (
  dispatch: ThunkDispatch<IAppState, any, IAppAction>
): void => {
  const user_path: string = "/authorizer/v1/user";
  const kushki_url: string = environment.kushkiUrl + user_path;

  let queryString = {
    limit: "5",
    merchantId: merchantId,
    searchSupport: true,
  };

  axios
    .get<IUserListResponse>(kushki_url, {
      params: queryString,
    })
    .then((response: AxiosResponse<IUserListResponse>) => {
      const resp = isEmpty(get(response, "data.Users"))
        ? []
        : get(response, "data.Users");
      dispatch(
        setUserListAction({
          Users: resp.filter((user: { Enabled: boolean }) => user.Enabled),
        })
      );
    })
    .catch((_error) => {
      dispatchNotification(
        dispatch,
        "Algo salió mal! No pudimos cargar los usuarios",
        "error"
      );
      dispatch(
        setUserListAction({
          Users: [],
        })
      );
    });
};

export const getBusinessRules = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    axios
      .post<MerchantProcessorsRules>(
        `${environment.kushkiUrl}/rules/v1/admin/business-type/rules`,
        {
          merchantId: merchantId,
          limit: 5,
        }
      )
      .then((response) => {
        if (!isEmpty(response.data.items))
          dispatch(setRule({ items: response.data.items }));
        else dispatch(setRule({ items: [] }));
      })
      .catch(() => {
        // @ts-ignore
        dispatch(
          setRule({
            items: [],
          })
        );
        dispatch(setIsRuleLoaded(false));
      });
  };
};

export const getDeferred = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    dispatch(setIsDeferredLoaded(true));
    try {
      const response: AxiosResponse<DeferredOptions> = await axios.get(
        `${environment.kushkiUrl}/deferred/v1/admin/deferred/${merchantId}`
      );
      if (!isEmpty(response.data.deferredOptions))
        dispatch(setDeferred(response.data.deferredOptions));
      dispatch(setIsDeferredLoaded(false));
    } catch (e) {
      dispatch(setDeferred([]));
      dispatch(setIsDeferredLoaded(false));
    }
  };
};

export const getPaymentCredentials = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    try {
      dispatch(setIsLoadingPaymentCredentials(true));
      const url: string = `${environment.kushkiUrl}/payment-credentials/v1/admin/credentials/${merchantId}`;
      const axiosResponse: AxiosResponse<
        MerchantCredential[]
      > = await axios.get(url);
      const response: MerchantCredential[] = get(axiosResponse, "data");
      dispatch(setPaymentCredentials(response));
      dispatch(setIsLoadingPaymentCredentials(false));
    } catch (e) {
      dispatch(setPaymentCredentials([]));
      dispatch(setIsLoadingPaymentCredentials(false));
      dispatch(
        setNotification({
          message: "Error no se pudo obtener las credenciales",
          open: true,
          type: "error",
        })
      );
    }
  };
};

export const getWebhookList = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    try {
      dispatch(setIsLoadingWebhookList(true));
      const url: string = `${environment.kushkiUrl}/webhook/v1/list`;
      const axiosResponse: AxiosResponse<WebhookListFetch> = await axios.post(
        url,
        {
          merchantId,
          limit: 5,
        }
      );
      const response: WebhookListFetch = get(axiosResponse, "data");
      dispatch(setWebhookList(response));
      dispatch(setIsLoadingWebhookList(false));
    } catch (e) {
      dispatch(setWebhookList(INITIAL_STATE.webhookList!));
      dispatch(setIsLoadingWebhookList(false));
      dispatch(
        setNotification({
          message: "Error no se pudo obtener las lista de webhooks",
          open: true,
          type: "error",
        })
      );
    }
  };
};

export const getAppConfigPaymentMethods = (): ThunkAction<
  void,
  IAppState,
  undefined,
  IAppAction
> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    try {
      const url: string = `${environment.kushkiUrl}/config-manager/v1/admin/configProfile?appName=HiddenPaymentMethods&env=develop&profileName=generalConfig`;
      const axiosResponse: AxiosResponse<GetAppConfigPaymentMethodsResponse> = await axios.get(
        url
      );
      const response: GetAppConfigPaymentMethodsResponse = get(
        axiosResponse,
        "data"
      );
      dispatch(setAppConfigPaymentMethods(response));
    } catch (e) {
      dispatch(setAppConfigPaymentMethods([]));
    }
  };
};
