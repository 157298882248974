/**
 * CatalogEc
 */
import { CatalogsEnum } from "../infrastructure/constants/catalogs-enum";
import { Category } from "../infrastructure/constants/MerchantInformationConstant";

export const CatalogEc: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { value: "0", name: "Cédula" },
    { value: "1", name: "Pasaporte" },
    { value: "2", name: "Ruc" },
  ],
  [CatalogsEnum.Provinces]: [
    { value: "A", name: "Azuay" },
    { value: "B", name: "Bolívar" },
    { value: "F", name: "Cañar" },
    { value: "C", name: "Carchi" },
    { value: "H", name: "Chimborazo" },
    { value: "X", name: "Cotopaxi" },
    { value: "O", name: "El Oro" },
    { value: "E", name: "Esmeraldas" },
    { value: "W", name: "Galápagos" },
    { value: "G", name: "Guayas" },
    { value: "I", name: "Imbabura" },
    { value: "L", name: "Loja" },
    { value: "R", name: "Los Ríos" },
    { value: "M", name: "Manabí" },
    { value: "S", name: "Morona Santiago" },
    { value: "N", name: "Napo" },
    { value: "D", name: "Orellana" },
    { value: "Y", name: "Pastaza" },
    { value: "P", name: "Pichincha" },
    { value: "SE", name: "Santa Elena" },
    { value: "SD", name: "Santo Domingo de los Tsáchilas" },
    { value: "U", name: "Sucumbíos" },
    { value: "T", name: "Tungurahua" },
    { value: "Z", name: "Zamora Chinchipe" },
  ],
  [CatalogsEnum.Banks]: [
    { value: "4077", name: "COOP  DE AHORRO Y CREDITO LA MERCED LTDA" },
    { value: "0229", name: "COOP.AHORRO Y CREDITO CHONE LTDA" },
    { value: "0231", name: "COOPERATIVA DE AHORRO Y CREDITO SANTA ANA LTDA" },
    { value: "0232", name: "FINANCIERA - DINERS CLUB DEL ECUADOR" },
    { value: "0233", name: "MUTUALISTA AMBATO" },
    { value: "0234", name: "MUTUALISTA AZUAY" },
    { value: "0236", name: "MUTUALISTA IMBABURA" },
    { value: "0238", name: "MUTUALISTA PICHINCHA" },
    { value: "0001", name: "BCE" },
    { value: "0039", name: "COMERCIAL DE MANABI" },
    { value: "0082", name: "FINANCOOP" },
    { value: "0017", name: "BANCO DE GUAYAQUIL S.A" },
    { value: "0024", name: "BANCO CITY BANK" },
    { value: "0025", name: "BANCO MACHALA" },
    { value: "0029", name: "BANCO DE LOJA" },
    { value: "0030", name: "BANCO DEL PACIFICO" },
    { value: "0032", name: "BANCO INTERNACIONAL" },
    { value: "0034", name: "BANCO AMAZONAS" },
    { value: "0035", name: "BANCO DEL AUSTRO" },
    { value: "0036", name: "BANCO DE LA PRODUCCION_PROMERICA" },
    { value: "0037", name: "BANCO BOLIVARIANO" },
    { value: "0042", name: "BANCO GENERAL RUMINAHUI" },
    { value: "0043", name: "BANCO DEL LITORAL S.A." },
    { value: "0059", name: "BANCO SOLIDARIO" },
    { value: "0027", name: "BANCO DELBANK S.A." },
    { value: "0202", name: "BANCO ECUATORIANO DE LA VIVIENDA" },
    { value: "0203", name: "CACPECO LTDA" },
    { value: "0205", name: "COOP. AHORRO Y CREDITO 23 DE JULIO" },
    { value: "0206", name: "COOP. AHORRO Y CREDITO 29 DE OCTUBRE" },
    { value: "0207", name: "COOP. AHORRO Y CREDITO ANDALUCIA" },
    { value: "0208", name: "COOP. AHORRO Y CREDITO COTOCOLLAO" },
    { value: "0209", name: "BANCO DESARROLLO DE LOS PUEBLOS" },
    { value: "0210", name: "COOP. AHORRO Y CREDITO EL SAGRARIO" },
    { value: "0211", name: "COOP. AHORRO Y CREDITO GUARANDA LTDA" },
    { value: "0215", name: "BANCO COOPNACIONAL" },
    { value: "0216", name: "COOP. AHORRO Y CREDITO OSCUS" },
    { value: "0217", name: "COOP. AHORRO Y CREDITO PABLO MUNOZ VEGA" },
    { value: "0218", name: "COOP. AHORRO Y CREDITO PROGRESO" },
    { value: "0219", name: "COOP. AHORRO Y CREDITO RIOBAMBA" },
    { value: "0220", name: "COOP. AHORRO Y CREDITO SAN FRANCISCO" },
    { value: "0222", name: "COOP. AHORRO Y CREDITO TULCAN" },
    { value: "0223", name: "COOP. DE AHORRO Y CREDITO ATUNTAQUI LTDA." },
    {
      value: "0224",
      name: "COOP. DE AHORRO Y CREDITO COMERCIO LTDA PORTOVIEJO",
    },
    { value: "0225", name: "COOP. DE AHORRO Y CREDITO LA DOLOROSA LTDA" },
    { value: "0226", name: "COOP. PREVISION AHORRO Y DESARROLLO" },
    { value: "0227", name: "COOP.AHORRO Y CREDITO ALIANZA DEL VALLE LTDA" },
    { value: "0228", name: "COOP.AHORRO Y CREDITO CAMARA DE COMERCIO QUITO" },
    { value: "0060", name: "BANCO PROCREDIT" },
    { value: "0061", name: "BANCO CAPITAL" },
    { value: "1182", name: "COOPERATIVA SAN FRANCISCO DE ASIS" },
    { value: "3304", name: "COOPERATIVA PADRE JOSE JULIAN LORENTE" },
    { value: "1141", name: "COOPERATIVA STA ROSA" },
    { value: "2129", name: "COOPMECO C MANUEL ESTEBAN GODO" },
    { value: "0213", name: "COOPERATIVA JUVENTUD ECUATORIANA  PROGRESISTA" },
    { value: "2753", name: "COOPERATIVA 9 DE OCTUBRE" },
    { value: "3352", name: "COOPERATIVA PEQUENA EMPRESA DE BIBLIAN" },
    { value: "3364", name: "COOPERATIVA SAN JOSE DE CHIMBO" },
    { value: "3615", name: "COOPERATIVA JARDIN AZUAYO" },
    { value: "9995", name: "COOPERATIVA 15 DE ABRIL" },
    { value: "9987", name: "COOP.CALCETA LTDA" },
    { value: "1119", name: "COOPERATIVA 11 DE JUNIO" },
    { value: "0010", name: "BANCO PICHINCHA" },
    {
      value: "0204",
      name: "COOP. AHORRO Y CRED. DE LA PEQ. EMPRESA DE PASTAZA",
    },
    { value: "9997", name: "COOPERATIVA POLICIA NACIONAL" },
    {
      value: "4001",
      name: "BANCO DEL INSTITUTO ECUATORIANO DE SEGURIDAD SOCIA",
    },
    { value: "4002", name: "BANCO PARA LA ASISTENCIA COMUNITARIA FINCA S.A." },
    { value: "4003", name: "BANCO-D-MIRO S.A." },
    { value: "4004", name: "COOP. A Y C DE LA PEQ. EMP. CACPE ZAMORA LTDA." },
    { value: "4005", name: "COOP. A. Y C. CARROCEROS DE TUNGURAHUA" },
    { value: "4006", name: "COOP. AHO.Y CRED.NUEVOS HORIZONTES EL ORO LTDA." },
    {
      value: "4007",
      name: "COOP. AHORRO Y CREDITO AGRARIA MUSHUK KAWSAY LTDA.",
    },
    { value: "4008", name: "COOP. AHORRO Y CREDITO ALIANZA MINAS LTDA." },
    {
      value: "4009",
      name: "COOP. AHORRO Y CREDITO CAMARA DE COMERCIO DEL CANT",
    },
    {
      value: "4010",
      name: "COOP. AHORRO Y CREDITO CAMARA DE COMERCIO INDIGENA",
    },
    { value: "4011", name: "COOP. AHORRO Y CREDITO CARIAMANGA LTDA." },
    {
      value: "4012",
      name: "COOP. AHORRO Y CREDITO DE LA CAMARA DE COMERCIO DE",
    },
    {
      value: "4013",
      name: "COOP AHORRO Y CREDITO DE LA PEQUENA EMPRESA GUALA",
    },
    { value: "4015", name: "COOP. AHORRO Y CREDITO FUNDESARROLLO" },
    { value: "4016", name: "COOP. AHORRO Y CREDITO JUAN DE SALINAS LTDA." },
    { value: "4018", name: "COOP. AHORRO Y CREDITO MALCHINGUI LTDA." },
    { value: "4019", name: "COOP. AHORRO Y CREDITO MANANTIAL DE ORO LTDA." },
    { value: "4020", name: "COOP. AHORRO Y CREDITO MI TIERRA" },
    { value: "4021", name: "COOP. AHORRO Y CREDITO NUEVA JERUSALEN" },
    { value: "4022", name: "COOP. AHORRO Y CREDITO PUELLARO LTDA" },
    { value: "4023", name: "COOP. AHORRO Y CREDITO SAN ANTONIO LTDA." },
    { value: "4024", name: "COOP. AHORRO Y CREDITO SAN GABRIEL LTDA." },
    { value: "4025", name: "COOP. AHORRO Y CREDITO SAN MIGUEL DE LOS BANCOS" },
    {
      value: "4026",
      name: "COOP. AHORRO Y CREDITO SEMILLA DEL PROGRESO LTDA.",
    },
    { value: "4027", name: "COOP. AHORRO Y CREDITO SENOR DE GIRON" },
    { value: "4028", name: "COOP. AHORRO Y CREDITO TENA LTDA." },
    { value: "4029", name: "COOP. AHORRO Y CREDITO TUNGURAHUA LTDA." },
    {
      value: "4030",
      name: "COOP. AHORRO. Y CREDI. MUJERES UNIDAS TANTANAKUSHK",
    },
    { value: "4031", name: "COOP. DE A Y C EDUCADORES DE PASTAZA LTDA." },
    { value: "4032", name: "COOP. DE A Y C GONZANAMA (MIES)" },
    { value: "4033", name: "COOP. DE A Y C JUAN PIO DE MORA LTDA." },
    { value: "4034", name: "COOP. DE A. Y C. 23 DE MAYO LTDA." },
    { value: "4035", name: "COOP. DE A. Y C. BANOS LTDA." },
    { value: "4036", name: "COOP. DE A. Y C. CASAG LTDA" },
    { value: "4037", name: "COOP. DE A. Y C. CREDISUR LTDA." },
    { value: "4038", name: "COOP. DE A. Y C. DE LA PEQ. EMPRESA CACPE MACARA" },
    {
      value: "4039",
      name: "COOP. DE A. Y C. DE LOS SERV. PUBL. DEL MIN. DE ED",
    },
    {
      value: "4040",
      name: "COOP. DE A. Y C. DEL COL. FISC. EXPER. VICENTE ROC",
    },
    { value: "4041", name: "COOP. DE A. Y C. DESARROLLO INTEGRAL LTDA." },
    { value: "4042", name: "COOP. DE A. Y C. ECUAFUTURO LTDA." },
    { value: "4043", name: "COOP. DE A. Y C. ESCENCIA INDIGENA LTDA." },
    { value: "4044", name: "COOP. DE A. Y C. FOCLA" },
    {
      value: "4045",
      name: "COOP. DE A. Y C. FUTURO Y PROGRESO DE GALAPAGOS LT",
    },
    { value: "4046", name: "COOP. DE A. Y C. GENERAL RUMINAHUI" },
    { value: "4047", name: "COOP. DE A. Y C. GRAMEEN AMAZONAS" },
    { value: "4048", name: "COOP. DE A. Y C. GUAMOTE LTDA." },
    { value: "4116", name: "INTERDIN S.A." },
    {
      value: "4115",
      name: "FONDO DE CESANTIA DEL MAGISTERIO ECUATORIANO FCME",
    },
    {
      value: "4113",
      name: "COOPERATIVA DE AHORRO Y CREDITO SANTA ANITA LTDA.",
    },
    { value: "4112", name: "COOPERATIVA DE AHORRO Y CREDITO SAN JOSE S.J." },
    { value: "4111", name: "COOPERATIVA DE AHORRO Y CREDITO QUILANGA LTDA." },
    { value: "4110", name: "COOPERATIVA DE AHORRO Y CREDITO PUCARA LTDA." },
    { value: "4109", name: "COOPERATIVA DE AHORRO Y CREDITO PROVIDA" },
    { value: "4108", name: "COOPERATIVA DE AHORRO Y CREDITO PILAHUIN" },
    { value: "4049", name: "COOP. DE A. Y C. LUCHA CAMPESINA LTDA." },
    { value: "4050", name: "COOP. DE A. Y C. MAQUITA CUSHUN LTDA." },
    { value: "4051", name: "COOP. DE A. Y C. MAQUITA CUSHUNCHIC LTDA." },
    { value: "4052", name: "COOP. DE A. Y C. PIJAL" },
    {
      value: "4053",
      name: "COOP. DE A. Y C. PROFESIONALES DEL VOLANTE UNION L",
    },
    { value: "4054", name: "COOP. DE A. Y C. SANTA ROSA DE PATUTAN LTDA." },
    { value: "4055", name: "COOP. DE A. Y C. SIERRA CENTRO LTDA." },
    { value: "4056", name: "COOP. DE A. Y C. SINCHI RUNA LTDA" },
    { value: "4057", name: "COOP. DE A. Y C. SUMAC LLACTA LTDA." },
    { value: "4058", name: "COOP. DE A. Y C. UNION MERCEDARIA LTDA." },
    { value: "4059", name: "COOP. DE A. Y C. VALLES DEL LIRIO" },
    { value: "4060", name: "COOP. DE A. Y C. VENCEDORES DE TUNGURAHUA" },
    { value: "4061", name: "COOP. DE AHORRO Y CREDITO  SIMIATUG LTDA" },
    { value: "4062", name: "COOP. DE AHORRO Y CREDITO 4 DE OCTUBRE LTDA." },
    {
      value: "4063",
      name: "COOP. DE AHORRO Y CREDITO ACCION Y DESARROLLO LTDA",
    },
    {
      value: "4064",
      name: "COOP. DE AHORRO Y CREDITO ALFONSO JARAMILLO C.C.C.",
    },
    { value: "4065", name: "COOP. DE AHORRO Y CREDITO ANDINA LTDA." },
    {
      value: "4066",
      name: "COOP. DE AHORRO Y CREDITO CAMARA DE COMERCIO DE AM",
    },
    { value: "4067", name: "COOP. DE AHORRO Y CREDITO CREDI FACIL LTDA." },
    {
      value: "4068",
      name: "COOP. DE AHORRO Y CREDITO CREDIAMIGO LTDA. LOJA (M",
    },
    { value: "4069", name: "COOP. DE AHORRO Y CREDITO CRISTO REY" },
    {
      value: "4070",
      name: "COOP. DE AHORRO Y CREDITO DE LA PEQ. EMP. CACPE YA",
    },
    { value: "4071", name: "COOP. DE AHORRO Y CREDITO DORADO LTDA." },
    {
      value: "4072",
      name: "COOP. DE AHORRO Y CREDITO EDUC.DEL TUNGURAHUA LTDA",
    },
    {
      value: "4073",
      name: "COOP. DE AHORRO Y CREDITO EDUCADORES DE CHIMBORAZO",
    },
    { value: "4074", name: "COOP. DE AHORRO Y CREDITO HUAICANA LTDA" },
    { value: "4075", name: "COOP. DE AHORRO Y CREDITO HUAQUILLAS LTDA." },
    { value: "4076", name: "COOP. DE AHORRO Y CREDITO JADAN LTDA. (MIES)" },
    { value: "4078", name: "COOP. DE AHORRO Y CREDITO MUSHUC RUNA LTDA." },
    { value: "4079", name: "COOP. DE AHORRO Y CREDITO NUESTROS ABUELOS LTDA." },
    {
      value: "4080",
      name: "COOP. DE AHORRO Y CREDITO NUEVA HUANCAVILCA LTDA.",
    },
    { value: "4081", name: "COOP. DE AHORRO Y CREDITO PEDRO MONCAYO LTDA." },
    { value: "4082", name: "COOP. DE AHORRO Y CREDITO PILAHUIN TIO LTDA" },
    { value: "4083", name: "COOP. DE AHORRO Y CREDITO PUERTO LOPEZ LTDA" },
    { value: "4084", name: "COOP. DE AHORRO Y CREDITO SAN MIGUEL DE SIGCHOS" },
    {
      value: "4085",
      name: "COOP. ESFUERZO UNIDO PARA EL DESARR. DEL CHILCO LA",
    },
    {
      value: "4086",
      name: "COOP.AHORRO Y CREDITO DE LA PEQUENA EMPRESA DE LOJ",
    },
    { value: "4088", name: "COOP.DE AHORRO Y CREDITO COCA LTDA" },
    { value: "4089", name: "COOP.DE AHORRO Y CREDITO HUAYCO PUNGO LTDA." },
    { value: "4091", name: "COOPERATIVA 15 DE AGOSTO PILACOTO" },
    { value: "4092", name: "COOPERATIVA DE AHORRO Y CREDITO SAN JORGE LTDA" },
    { value: "4093", name: "COOPERATIVA DE AHORRO Y CREDITO 27 DE ABRIL LOJA" },
    { value: "4094", name: "COOPERATIVA DE AHORRO Y CREDITO AGRICOLA JUNIN L" },
    { value: "4095", name: "COOPERATIVA DE AHORRO Y CREDITO AMBATO LTDA." },
    { value: "4096", name: "COOPERATIVA DE AHORRO Y CREDITO ARTESANOS LTDA." },
    { value: "4097", name: "COOPERATIVA DE AHORRO Y CREDITO CAC-CICA (MIES)" },
    { value: "4098", name: "COOPERATIVA DE AHORRO Y CREDITO CACPE CELICA" },
    {
      value: "4099",
      name: "COOPERATIVA DE AHORRO Y CREDITO CATAMAYO LTDA. (MI",
    },
    {
      value: "4100",
      name: "COOPERATIVA DE AHORRO Y CREDITO EL CALVARIO LTDA.",
    },
    { value: "4101", name: "COOPERATIVA DE AHORRO Y CREDITO ERCO LTDA." },
    {
      value: "4102",
      name: "COOPERATIVA DE AHORRO Y CREDITO FERNANDO DAQUILEMA",
    },
    { value: "4103", name: "COOPERATIVA DE AHORRO Y CREDITO FORTUNA (MIES)" },
    { value: "4104", name: "COOPERATIVA DE AHORRO Y CREDITO INTEGRAL" },
    { value: "4105", name: "COOPERATIVA DE AHORRO Y CREDITO LLANGANATES" },
    { value: "4106", name: "COOPERATIVA DE AHORRO Y CREDITO MARCABELI LTDA" },
    { value: "4107", name: "COOPERATIVA DE AHORRO Y CREDITO NUEVA ESPERANZA" },
    { value: "9988", name: "COOPERATIVA DE AHORRO Y CREDITO MINGA LTDA" },
    { value: "9989", name: "COOPERATIVA AHORRO Y CREDITO ACCION RURAL" },
    { value: "4118", name: "COOP. DE A. Y C. FINANCIERA INDIGENA LTDA." },
    { value: "4119", name: "COOP. DE A. Y C. 20 DE FEBRERO LTDA." },
    { value: "4120", name: "COOP. DE A. Y C. EDUCADORES TULCAN LTDA." },
    { value: "4121", name: "CAMARA DE COMERCIO JOYA DE LOS SACHAS" },
    { value: "4122", name: "COOP. DE AHORRO Y CREDITO FCO. DE ORELLANA" },
    { value: "4123", name: "DE AHORRO Y CREDITO CRECIENDO JUNTOS" },
    { value: "4124", name: "COOP. DE AHORRO Y CREDITO GUEL LTDA." },
    { value: "4125", name: "COOP. DE AHORRO Y CREDITO FASAYNAN LTDA." },
    { value: "4126", name: "COOP. DE AHORRO Y CREDITO SOL DE LOS ANDES" },
    { value: "4127", name: "COOP. DE AHORRO Y CREDITO 22 DE JUNIO" },
    { value: "4129", name: "COOP. DE AHORRO Y CREDITO POPULAR Y SOLIDARIA" },
    { value: "4130", name: "COOP. DE AHORRO Y CREDITO LA FLORIDA" },
    {
      value: "4131",
      name: "COOP. DE AHORRO Y CREDITO SAN SANTIAGO DE MOLLETUR",
    },
    { value: "4132", name: "COOP. DE AHORRO Y CREDITO CUMBENITA LTDA." },
    { value: "4133", name: "COOP. DE AHORRO Y CREDITO GUACHAPALA LTDA." },
    { value: "4136", name: "CORPORACION EN LAS HUELLAS DEL BANCO GRAMEEN" },
    {
      value: "4138",
      name: "COOP. DE LA PEQUENA Y MEDIANA EMPRESA CIUDADANA DE",
    },
    { value: "4139", name: "CAJA DE AHORRO Y CREDITO ZHONDELEG" },
    { value: "4140", name: "CAJA DE AHORRO Y CREDITO MANU" },
    { value: "4141", name: "CAJA DE AHORRO Y CREDITO FRONTERA SUR" },
    { value: "4142", name: "CAJA DE AHORRO Y CREDITO SAN FRANCISCO" },
    { value: "4143", name: "CAJA DE AHORRO Y CREDITO HORIZONTE FAMILIAR" },
    {
      value: "4144",
      name: "COOP. DE AHORRO Y CREDITO CAMARA DE COMERICO GONZA",
    },
    { value: "4145", name: "COOP. CACIQUE GURITAVE" },
    { value: "4146", name: "COOP. SOLIDARIDAD Y PROGRESO ORIENTAL" },
    { value: "4147", name: "COOPERATIVA DE AHORRO Y CREDITO SAN MARCOS" },
    { value: "4148", name: "COOP. DE AHORRO Y CREDITO MIGRANTE SOLIDARIO" },
    { value: "4149", name: "CAJA DE AHORRO Y CREDITO EL INGENIO" },
    { value: "4150", name: "COOP. DE AHORRO Y CREDITO SAN JOSE (EL AIRO)" },
    { value: "4151", name: "CAJA DE AHO Y CRED NUESTRA SENORA DE LA MERCED" },
    { value: "4152", name: "CAJA DE AHORRO Y CREDITO FRANCISCA CHIGUA" },
    { value: "4153", name: "CAJA DE AHORRO Y CREDITO EL MANIZAL" },
    { value: "4154", name: "COOP. DE A Y C MUSHUK YUYAY" },
    { value: "4155", name: "COOP. SOLIDARIA DE GUALAQUIZA" },
    { value: "4156", name: "CAJA SOLIDARIA CHIQUICHA" },
    { value: "4157", name: "CAJA SOLIDARIA ESTRELLA DEL MUNDO" },
    { value: "4158", name: "COOP. DE A Y C DESARROLLO POPULAR" },
    { value: "4159", name: "COOP. DE A Y C KURI WASI" },
    { value: "4160", name: "COOP. DE A Y C WUAMANLOMA" },
    { value: "4161", name: "CORPORACION VIENTOS SOLIDARIOS" },
    { value: "4162", name: "COOP. AGUILAS DE CRISTO" },
    { value: "4163", name: "COOP. FUENTE DE VIDA" },
    { value: "4164", name: "COOP. CREDIUNION" },
    { value: "4166", name: "FINANCIERA - CONSULCREDITO S.A." },
    {
      value: "4167",
      name: "COOPERATIVA DE AHORRO Y CREDITO HUINARA LTDA. (MIE",
    },
    { value: "4182", name: "COOP. DE A. Y C. CHIBULEO LTDA." },
    { value: "4183", name: "COOP. DE A. Y C. EL TESORO PILLARENO" },
    { value: "4184", name: "COOP. DE A. Y C. KISAPINCHA LTDA." },
    { value: "4185", name: "COOP. DE A. Y C. JUVENTUD UNIDA LTDA." },
    { value: "4186", name: "COOP. DE A. Y C. UNION QUISAPINCHA LTDA." },
    { value: "4187", name: "COOP. DE A. Y C. 13 DE ABRIL LTDA" },
    { value: "4188", name: "COOP. DE A. Y C. SALINAS LTDA." },
    { value: "4189", name: "COOP. DE A. Y C. SAN PEDRO LTDA." },
    { value: "4190", name: "COOP. DE A. Y C. VIRGEN DEL CISNE" },
    { value: "4191", name: "COOP. DE A. Y C. LOS CHASQUIS PASTOCALLE LTDA." },
    { value: "4192", name: "COOP. DE A. Y C. COOPINDIGENA LTDA." },
    { value: "4193", name: "COOP. DE A. Y C  EDUCADORES DE ZAMORA CHINCHIPE" },
    {
      value: "4194",
      name: "COOPERATIVA DE AHORRO Y CREDITO LAS LAGUNAS (MIESS",
    },
    { value: "4195", name: "COOP.DE A.Y C. EL COMERCIANTE LTDA (MIES)" },
    {
      value: "4196",
      name: "COOP. DE AHORRO Y CREDITO EDUCADORES DE EL ORO LTD",
    },
    {
      value: "4197",
      name: "COOP. DE A. Y C. EMPLEADOS BANCARIOS DEL ORO LTDA",
    },
    { value: "4198", name: "COOPERATIVA DE AHORRO Y CREDITO RIOCHICO" },
    { value: "4199", name: "COOP. DE A. Y C. LA UNION LTDA." },
    { value: "4200", name: "COOP. DE A. Y C. SAN MARTIN DE TISALEO LTDA." },
    { value: "4201", name: "COOP. DE A. Y C. ALLI TARPUC LTDA." },
    {
      value: "4202",
      name: "COOP. DE AHORRO Y CREDITO SAN MIGUEL DE PALLATANGA",
    },
    { value: "4203", name: "COOP. DE A. Y C. PADRE VICENTE PONCE RUBIO" },
    {
      value: "4204",
      name: "CORPORACION DE DESARROLLO SOCIAL Y FINANCIERO ISLA",
    },
    { value: "4205", name: "COOP. DE SERVICIOS MULTIPLES AGRO VIDA" },
    { value: "4206", name: "COOP. DE LA MICROEMPRESA DE CHIMBORAZO" },
    {
      value: "4207",
      name: "COOP. DE A Y C 4 DE OCTUBRE SAN FRANCISCO DE CHAMB",
    },
    { value: "4208", name: "COOP. DE A Y C 26 DE SEPTIEMBRE LAZARO CONDO" },
    { value: "4209", name: "CAJA SOLIDARIA DE A Y C CORDTUCH" },
    { value: "4210", name: "COOP. SAN PABLO" },
    { value: "4211", name: "COOP. 15 DE DICIEMBRE" },
    { value: "4213", name: "COOP DE A Y C EL TRANSPORTISTA CACET" },
    { value: "4168", name: "COOP. DE A. Y C. INKA KIPU LTDA." },
    { value: "4169", name: "COOP. DE A. Y C. ACCION TUNGURAHUA LTDA." },
    { value: "4170", name: "COOP. DE A. Y C. 16 DE JUNIO" },
    {
      value: "4171",
      name: "COOP. A.Y C. ESC.SUP.POLITEC. AGROP. DE MANABI MAN",
    },
    {
      value: "4172",
      name: "COOP. DE A. Y C. INDIGENA ALFA Y OMEGA LTDA.ALFA Y",
    },
    { value: "4173", name: "COOP. DE A. Y C. FENIX" },
    {
      value: "4174",
      name: "COOP. DE AHORRO Y CREDITO LOS ANDES LATINOS LTDA.",
    },
    { value: "4177", name: "COOP. DE A. Y C. COOPAC AUSTRO LTDA (MIESS)" },
    {
      value: "4178",
      name: "COOPERATIVA DE AHORRO Y CREDITO CREA LTDA ( MIES)",
    },
    { value: "4179", name: "CCOP. DE A. Y C. SALASACA" },
    { value: "4180", name: "COOP. DE A. Y C. SUMAK SAMY LTDA." },
    { value: "4181", name: "COOP. DE A. Y C. INTERCULT. TARPUK RUNA LTDA." },
    { value: "4216", name: "COOP DE A Y C  LUZ DEL VALLE" },
    { value: "4217", name: "COOP DE A Y C  ESPERANZA Y PROGRESO DEL VALLE" },
    { value: "4228", name: "COOP AYC DE LA PEQ EMP CACPE ZAMORA LTDA" },
    { value: "4229", name: "COOP DE AHORRO Y CREDITO CRISTO REY" },
    { value: "4224", name: "COOP DE A Y C  RUNA SHUNGO LTDA" },
    { value: "5000", name: "CORPORACION FINANCIERA" },
    { value: "5001", name: "COOP. DE A. Y C. CORDILLERA DE LOS ANDES LTDA." },
    { value: "5002", name: "COOP. DE A. Y C. PUERTO FRANCISCO DE ORELLANA" },
    { value: "5003", name: "COOP. DE A. Y C. CHOCO TUNGURAHUA RUNA LTDA" },
    { value: "5004", name: "COOP. DE A. Y C. COOPARTAMOS LTDA" },
    { value: "5005", name: "COOP. DE A. Y C. CORPORACION CENTRO LTDA." },
    { value: "5006", name: "COOP DE A. Y C. SAN JUAN DE COTOGCHOA" },
    {
      value: "5007",
      name: "COOP. DE A. Y C. EMPRENDEDORES COOPEMPRENDER LTDA.",
    },
    { value: "5008", name: "COOP. DE A. Y C. NUEVA LOJA LTDA." },
    { value: "5009", name: "COOP. DE A. Y C. PICHNCHA LTDA." },
    { value: "5010", name: "COOP.DE AHORRO Y CREDITO CACEC LTDA. (COTOPAXI)" },
    { value: "5011", name: "COOP.DE A. Y C. VENCEDORES DE PICHINCHA LTDA." },
    { value: "5012", name: "COOPERATIVA DE AHORRO Y CREDITO SAN BARTOLO LTDA" },
    { value: "5013", name: "COOP. DE AHORRO Y CREDITO EL DISCAPACITADO LTDA." },
    {
      value: "5014",
      name: "COOP. DE A. Y C. DEL EMIGRANTE ECUATORIANO Y SU FA",
    },
    { value: "5015", name: "COOP. AHORRO Y CREDITO LA LIBERTAD LTDA" },
    { value: "5016", name: "COAC CUNA DE LA NACIONALIDAD LTDA." },
    { value: "5017", name: "COOP. DE A Y C. SERVIDORES MUNICIPALES DE CUENCA" },
    { value: "5018", name: "COOP. DE A. Y C. SAN MARCOS" },
    { value: "5019", name: "COOP. DE AHORRO Y CREDITO PROFUTURO LTDA." },
    { value: "5020", name: "COOPERATIVA DE AHORRO Y CREDITO MACODES LTDA" },
    { value: "5021", name: "COOPERATIVA DE AHORRO Y CREDITO GUACHAPALA LTDA" },
    {
      value: "5022",
      name: "COOPERATIVA DE AHORRO Y CREDITO SANTA ISABEL LTDA.",
    },
    { value: "5023", name: "COOPERATIVA DE AHORRO Y CREDITO GANANSOL LTDA" },
    { value: "5024", name: "COOPERATIVA DE AHORRO Y CREDITO DEL AZUAY" },
    {
      value: "5025",
      name: "COAC DEL SINDICATO DE CHOFERES PROFESIONALES DEL A",
    },
    {
      value: "5026",
      name: "COOPERATIVA DE AHORRO Y CREDITO DEL COLEGIO DE ARQ",
    },
    { value: "5027", name: "COOPERATIVA DE AHORRO Y CREDITO NUKANCHIK" },
    {
      value: "5029",
      name: "COOPERATIVA DE AHORRO Y CREDITO MULTIEMPRESARIAL L",
    },
    { value: "5030", name: "COOP. DE AHORRO Y CREDITO CHOLA CUENCANA LTDA." },
    { value: "5031", name: "COOP. DE A. Y C. INDIGENA SAC PELILEO" },
    {
      value: "5032",
      name: "COOP. DE A. Y C. INTERCULTURAL TAWANTINSUYU LTDA.",
    },
    { value: "5033", name: "COOP. DE A. Y C. OCIPSA" },
    { value: "5034", name: "COOP. DE A. Y C. MUSHUG CAUSAY LTDA." },
    { value: "5035", name: "COOP. DE A. Y C. 21 DE NOVIEMBRE LTDA." },
    { value: "5036", name: "COOP. DE A. Y C. LA FLORESTA LTDA." },
    {
      value: "5037",
      name: "COOP. DE A. Y C. CORP. ORG. CAMPESINAS DE QUISAPIN",
    },
    {
      value: "5038",
      name: "COOP. DE A. Y C. MULTICULTURAL BANCO INDIGENA LTDA",
    },
    { value: "5039", name: "COOP DE A. Y C. CRECER WINARI LTDA." },
    { value: "5040", name: "COOP. DE A. Y C. BANOS DE AGUA SANTA LTDA." },
    { value: "5041", name: "COOP. DE AHORRO Y CREDITO 1 DE JULIO" },
    { value: "5042", name: "COOP. DE A. Y C. SUMAK NAN LTDA." },
    { value: "5043", name: "COOPERATIVA DE AHORRO Y CREDITO CANAR LTDA." },
    {
      value: "5044",
      name: "COOPERATIVA DE AHORRO Y CREDITO SAN ANTONIO LTDA.",
    },
    { value: "5045", name: "COOP. DE AHORRO Y CREDITO FUNDAR" },
    { value: "5047", name: "COOP. AHORRO Y CREDITO METROPOLIS LTDA." },
    { value: "5048", name: "COOP. DE AHORRO Y CREDITO EL CAFETAL" },
    { value: "5049", name: "COOP.DE AHORRO Y CREDITO MICROEMPRESARIAL SUCRE" },
    {
      value: "5050",
      name: "COOP. DE A. Y C. AFROECUATORIANA DE LA PEQ. EMP. L",
    },
    { value: "5051", name: "COOPERATIVA DE AHORRO Y CREDITO JOYOCOTO LTDA." },
    { value: "5052", name: "COOP. DE A. Y C. UNIOTAVALO LTDA." },
    { value: "5053", name: "COOP. DE A. Y C. UNION EL EJIDO" },
    { value: "5054", name: "COOP. DE A. Y C. GENESIS LTDA." },
    {
      value: "5055",
      name: "COOP. DE A Y C. MARIA AUXILIADORA DE QUIROGA LTDA",
    },
    { value: "5056", name: "COOP. DE A. Y C. FORTALEZA" },
    { value: "5057", name: "COOP. DE A. Y C. PUJILI LTDA" },
    { value: "5058", name: "COOP. DE A. Y C. CREDIL LTDA." },
    { value: "5059", name: "COOP. DE A. Y C. COOPTOPAXI LTDA." },
    { value: "5060", name: "COOP. DE A. Y C. ILINIZA LTDA." },
    { value: "5061", name: "COOP. DE A. Y C. MUSHUK PAKARI LTDA." },
    { value: "5062", name: "COOP. DE A. Y C. UNIBLOCK Y SERVICIOS LTDA." },
    { value: "5063", name: "COOP. DE A. Y C. SAN FERNANDO LIMITADA" },
    { value: "5064", name: "COOP. DE A. Y C. FUTURO LAMANENSE" },
    { value: "5065", name: "COOP. DE A. Y C. SAQUISILI LTDA." },
    { value: "5066", name: "COOP. DE A. Y C. INNOVACION ANDINA LTDA." },
    { value: "5067", name: "COOP. DE A. Y C. MUSHUK WASI LTDA ( MIES )" },
    { value: "5068", name: "COOP. DE AHORRO Y CREDITO SARAGUROS" },
    { value: "5069", name: "COOPERATIVA DE AHORRO Y CREDITO INTI WASI  LTDA." },
    { value: "5071", name: "COOPERATIVA DE AHORRO Y CREDITO SOLIDARIA LTDA" },
    { value: "5072", name: "COOPERATIVA DE AHORRO Y CREDITO ECONOMIA DEL SUR" },
    {
      value: "5073",
      name: "COOPERATIVA DE AHORRO Y CREDITO MIGRANTES Y EMPREN",
    },
    { value: "5074", name: "COOPERATIVA DE AHORRO Y CREDITO SAN SEBASTIAN" },
    {
      value: "5075",
      name: "COOP. DE A. Y C. DEL SINDICATO DE CHOFERES PROFESI",
    },
    { value: "5076", name: "COOP.DE AHORRO Y CREDITO 29 DE ENERO" },
    { value: "5077", name: "COOPERATIVA DE AHORRO Y CREDITO SOCIO AMIGO" },
    { value: "5078", name: "COOP. DE A. Y C. INDIGENAS GALAPAGOS LTDA." },
    {
      value: "5079",
      name: "COOPERATIVA DE AHORRO Y CREDITO LA BENEFICA LTDA.",
    },
    { value: "5080", name: "COOPERATIVA DE AHORRO Y CREDITO SAN ISIDRO LTDA." },
    { value: "5081", name: "COOP.DE AHORRO Y CREDITO ABDON CALDERON LTDA." },
    {
      value: "5082",
      name: "COOP. A Y C CAMARA DE COMERCIO CANTON -EL CARMEN L",
    },
    {
      value: "5083",
      name: "COOP.DE AHORRO Y CREDITO AGROPRODUCTIVA MANABI LTD",
    },
    {
      value: "5084",
      name: "COOP. DE AHORRO Y CRED. LA INMACULADA DE SAN PLACI",
    },
    { value: "5085", name: "COOP. AHORRO Y CREDITO CACPE MANABI" },
    {
      value: "5086",
      name: "COOP.AHORRO Y CREDITO MAGISTERIO MANABITA LIMITADA",
    },
    { value: "5087", name: "COAC TIENDA DE DINERO LTDA." },
    {
      value: "5088",
      name: "COOP.A.Y C. SANTA MARIA DE LA MANGA DEL CURA LTDA.",
    },
    { value: "5089", name: "COOP. DE A. Y C. SOL DE LOS ANDES LTDA." },
    {
      value: "5090",
      name: "COOP. DE A.Y C. PRODUC. AHORRO INVERS. SERVICIO P.",
    },
    { value: "5091", name: "COOP. DE A. Y C. FRANDESC LTDA." },
    { value: "5092", name: "COOP. DE A. Y C. NUKA LLAKTA LTDA." },
    { value: "5093", name: "COOP. DE A Y C. CAMARA DE COMERCIO RIOBAMBA" },
    { value: "5094", name: "COOP. DE A. Y C. BASHALAN LTDA." },
    {
      value: "5095",
      name: "COOP. DE A. Y C. CAMARA DE COMERCIO SANTO DOMINGO",
    },
    { value: "5096", name: "COOP. OLMEDO" },
    { value: "5097", name: "CAJA DE A Y C JEVENTUD Y DESARROLLO" },
    { value: "5098", name: "COOP. PROD. Y DES. AGR. COOPRODESA LTDA" },
    { value: "5099", name: "COOP. SIMON BOLIVAR LTDA." },
    { value: "5100", name: "COOP. SAN ANTONIO DE TOACASO" },
    { value: "5101", name: "CORPORACION DE DESARROLLO FINANCIERA RHUMY WARA" },
    { value: "5102", name: "CORPORACION DE DESARROLLO FINANCIERA PRODVISION" },
    { value: "5103", name: "CAJA DE AHORRO Y CREDITO ORIENTE" },
    { value: "5104", name: "COOP. NUEVA FUERZA ALIANZA" },
    { value: "5105", name: "CAJA SOLIDARIA WARMIKUNA KAWSAY" },
    { value: "5106", name: "COOP. SAN PEDRO DE PELILEO" },
    { value: "5107", name: "CAJA SOLIDARIA PASA" },
    { value: "5108", name: "CORPORACION ORIENTE" },
    { value: "5109", name: "COOP. WARMIKUNAPAK RIKCHARI" },
    { value: "5110", name: "CAJA CREDITO DESARROLLO" },
    { value: "5111", name: "COOP. MUSHUK PAKARIK" },
    { value: "5112", name: "COOP. SAN BARTOLOME" },
    { value: "5113", name: "CORPORACION SANTANDER" },
    { value: "5114", name: "CORPORACION SAN FRANCISCO DE CHIBULEO" },
    { value: "5115", name: "CAJA SOLIDARIA JATUN NAN" },
    { value: "5116", name: "COOP. 15 DE MAYO" },
    { value: "5117", name: "COOP. SANTA LUCIA" },
    { value: "5118", name: "COOP. CAMINO DE ORO KURINAN" },
    { value: "5119", name: "COOPERATIVA SAN FERNANDO" },
    { value: "5120", name: "COOP. SALATE" },
    { value: "5121", name: "CAJA ECUAESPANA" },
    { value: "5122", name: "CAJA DE AHORRO Y CREDITO BANCAJA" },
    {
      value: "5124",
      name: "COOP DE AHORR Y CR CONSTRUCCION COMERCIO Y PRODUCC",
    },
    { value: "5125", name: "BANECUADOR B.P." },
    { value: "5126", name: "COOP. DE A. Y C. 29 DE AGOSTO" },
    {
      value: "5127",
      name: "COOP. DE A. Y C. FONDO PARA EL DESARROLLO Y LA VID",
    },
    { value: "5128", name: "COOPERATIVA DE A Y C NUEVA VISION" },
    { value: "5129", name: "COOP. DE AHORRO Y CREDITO FOCASH LTDA." },
    { value: "5130", name: "COOP. DE A. Y C. SAN VICENTE DEL SUR LTDA." },
    {
      value: "5131",
      name: "COOP. DE A. Y C. PARA LA VIVIENDA ORDEN Y SEGURIDA",
    },
    {
      value: "5132",
      name: "COOP DE A. Y C. CAMARA DE COMERCIO JOYA DE LOS SAC",
    },
    { value: "5133", name: "COOP. DE A. Y C. FOCAP" },
    { value: "5134", name: "COOP. DE A. Y C. 18 DE NOVIEMBRE" },
    {
      value: "5135",
      name: "COOP. DE A. Y C. ALIANZA SOCIAL ECUAT. ALSEC LTDA",
    },
    {
      value: "5136",
      name: "COOP. DE A. Y C. RENOVADORA ECUATORIANA CON ACCION",
    },
    { value: "5137", name: "COOP. DE A. Y C. MUSHUK YUYAY - NAPO" },
    { value: "5138", name: "COOP. DE A. Y C. SANTA ANA DE NAYON" },
    { value: "5139", name: "COOP. DE A. Y C. EDUCADORES DEL NAPO" },
    { value: "5140", name: "COOPERATIVA DE A. Y C. TEXTIL 14 DE MARZO" },
    { value: "5141", name: "COOP. DE A. Y C. UNION Y DESARROLLO" },
    { value: "5142", name: "COOP. DE A. Y C. ESPERANZA DEL FUTURO LTDA." },
    { value: "5143", name: "COOP. DE A. Y C. 17 DE MARZO LTDA" },
    { value: "5144", name: "COOP. DE A. Y C. CATAR LTDA" },
    { value: "5145", name: "COOP. DE A. Y C. DE ACCION POPULAR" },
    { value: "5146", name: "COOP. DE A. Y C. ALLI TARPUK LTDA" },
    { value: "5147", name: "COOP. DE A. Y C. 16 DE JULIO LTDA" },
    {
      value: "5148",
      name: "COOP. DE A. Y C. SUBOFICIALES DE LA POLICIA NACION",
    },
    { value: "5149", name: "COOP. DE A. Y C. NACIONAL LLANO GRANDE LTDA." },
    { value: "5150", name: "COOP. DE A. Y C. SAN VALENTIN" },
    { value: "5151", name: "BCE-TRANSFERENCIAS REMESAS" },
    {
      value: "5152",
      name: "COOPERATIVA DE AHORRO Y CREDITO DR. CORNELIO SAENZ",
    },
    { value: "5153", name: "COOP. DE A Y C SAN CARLOS LTDA." },
    { value: "5154", name: "COAC A Y C ESPERANZA DE VALLE DE LA VIRGEN LTDA." },
    { value: "5155", name: "COAC A Y C ZONA DE CAPITAL CORCIMOL" },
    { value: "5156", name: "COOP. DE AHORRO Y CREDITO BASE DE TAURA" },
    { value: "5157", name: "COOP.DE A. Y C. ARTESANAL DEL AZUAY" },
    { value: "5158", name: "COOP DE A. Y C. EDUCADORES DEL AZUAY" },
    { value: "5159", name: "COOP A Y C. FASAYNAN LTDA" },
    { value: "5160", name: "COOPERATIVA DE AHORRO Y CREDITO SUMAK SISA" },
    { value: "5161", name: "COOP. DE A. Y C. WARMIKUNAPAK RIKCHARI LTDA" },
    { value: "5162", name: "COOP. DE A. Y C. REY DAVID LTDA" },
    { value: "5163", name: "COOP. DE A. Y C. KULLKI WASI LTDA." },
    { value: "5164", name: "COOP. DE A. Y C. INDIGENA SAC LTDA" },
    { value: "5165", name: "COOP. DE A. Y C. CREDI YA LTDA" },
    { value: "5166", name: "COOP. DE A. Y C. SAN BARTOLOME LTDA" },
    { value: "5167", name: "COOP. DE A. Y C. PRODUACTIVA LTDA" },
    { value: "5168", name: "COOP. DE A. Y C. TAMBOLOMA LTDA." },
    { value: "5169", name: "COOP DE A. Y C. PUSHAK RUNA HOMBRE LIDER" },
    { value: "5170", name: "COOP. DE A. Y C. 15 DE AGOSTO LTDA." },
    { value: "5171", name: "COOP. DE A. Y C. MIGRANTES DEL ECUADOR LTDA" },
    { value: "5172", name: "COOPERATIVA DE AHORRO Y CREDITO WUAMANLOMA LTDA." },
    { value: "5173", name: "COOP. DE A. Y C. SALATE LTDA." },
    { value: "5174", name: "COOP. DE A. Y C. UNION POPULAR LTDA." },
    { value: "5175", name: "COOP. DE AHORRO Y CREDITO MUSHUK YUYAY" },
    { value: "5176", name: "COOP DE A. Y C. SISAY KANARI" },
    { value: "5177", name: "COOP DE A. Y C. SINCHI CODEFIS" },
    { value: "5178", name: "COOP. DE A. Y C. FUERZA DE LOS ANDES" },
    { value: "5179", name: "COOP. DE A. Y C. CACIQUE GURITAVE" },
    { value: "5180", name: "COAC SOLIDARIDAD Y PROGRESO ORIENTAL" },
    { value: "5181", name: "COAC ACHIK INTI LTDA" },
    { value: "5182", name: "COOP. DE A. Y C. ANTORCHA LTDA." },
    { value: "5183", name: "COOP DE A. Y C. CAMINO DE LUZ LTDA." },
    { value: "5184", name: "COAC A Y C EDUCADORES DE BOLIVAR" },
    { value: "5185", name: "COAC SAN MIGUEL LTDA." },
    { value: "5186", name: "COOP AC LAS NAVES" },
    { value: "5187", name: "COOP. DE A. Y C. SALINERITA" },
    { value: "5188", name: "COOP. DE A. Y C. BOLA AMARILLA" },
    { value: "5189", name: "COOP. DE A. Y C. DE IMBABURA AMAZONAS" },
    { value: "5190", name: "COOP. A. Y C. DE INDIGENAS CHUCHUQUI LTDA" },
    { value: "5191", name: "COOPERATIVA DE A Y C ACCION IMBABURAPAK LTDA." },
    { value: "5192", name: "COOP. AHORRO Y CREDITO FOCAZSUM LTDA." },
    {
      value: "5193",
      name: "COOPERATIVA DE AHORRO Y CREDITO VISION DE LOS ANDE",
    },
    { value: "5194", name: "COOP DE A. Y C. HERMES GAIBOR VERDESOTO" },
    { value: "5195", name: "COOP. DE A. Y C. SEMILLAS DE PANGUA" },
    { value: "5196", name: "COOPERATIVA DE A Y C MUSHUK SOLIDARIA" },
    { value: "5197", name: "COOP DE A. Y C. PANAMERICANA LTDA" },
    { value: "5198", name: "COOP. DE A. Y C. SENOR DEL ARBOL" },
    {
      value: "5199",
      name: "COOP. DE A. Y C. ALIANZA FINANCIERA DE COTOPAXI LT",
    },
    { value: "5200", name: "COOP. DE A. Y C. CAMARA DE COMERCIO DE LA MANA" },
    { value: "5201", name: "COOP DE A. Y C. INDIGENA SAC LATACUNGA LTDA" },
    { value: "5202", name: "COOP. DE A. Y C. SOLIDARIA LTDA.- COTOPAXI" },
    { value: "5203", name: "COOP. DE A. Y C. UNIDAD Y PROGRESO" },
    {
      value: "5204",
      name: "COOP.DE AHORRO Y CREDITO CAMARA DE COMERCIO DE LOJ",
    },
    {
      value: "5205",
      name: "COOP.DE A. Y C. DE CRECIMIENTO ECONOMICO RENTABLE",
    },
    { value: "5206", name: "COOP. DE A. Y C. VILCABAMBA CACVIL" },
    { value: "5207", name: "COOPERATIVA DE AHORRO Y CREDITO SANTIAGO LTDA" },
    { value: "5208", name: "COOP DE A. Y C. EDUCADORES DE LOJA" },
    { value: "5209", name: "COOP DE A.Y C. CADECOG - GONZANAMA" },
    { value: "5210", name: "COAC COOPYMEC - MACARA" },
    { value: "5211", name: "COAC CADECOM - MACARA" },
    { value: "5212", name: "COOP DE A. Y C. 22 DE JUNIO-ORIANGA" },
    { value: "5213", name: "COAC PROBIENESTAR LTDA" },
    { value: "5214", name: "COAC LOJA INTERNACIONAL LTDA." },
    { value: "5215", name: "COAC 23 DE ENERO" },
    { value: "5216", name: "COAC OBRAS PUBLICAS FISCALES DE LOJA Y ZAMORA" },
    { value: "5217", name: "COOP.DE A. Y C. POPULAR Y SOLIDARIA" },
    { value: "5218", name: "COOP. DE A. Y C. FAMILIA SOLIDARIA" },
    { value: "5219", name: "COOP.DE AHORRO Y CREDITO SAN PLACIDO LTDA." },
    {
      value: "5220",
      name: "COOPERATIVA DE AHORRO Y CREDITO EL PARAISO MANGA D",
    },
    {
      value: "5221",
      name: "COAC DE LOS PROFESORES EMPLEADOS Y TRABAJADORES DE",
    },
    { value: "5222", name: "COAC SAN MIGUEL DE CHIRIJOS LTDA" },
    { value: "5223", name: "COAC. SANTA ROSA DE SAN CARLOS LTDA." },
    { value: "5224", name: "COOP. AHORRO Y CREDITO QUEVEDO LTDA." },
    {
      value: "5225",
      name: "COOP. DE A. Y C. CONSTRUCTOR DEL DESARROLLO SOLIDA",
    },
    { value: "5226", name: "COOP DE A. Y C. MUSHUK YUYAY LTDA" },
    {
      value: "5227",
      name: "COOP. A.YC.SAGRADA FAMILIA SOLIDARIDAD PASTORAL IN",
    },
    { value: "5228", name: "COOP. DE A. Y C. NAUPA KAUSAY" },
    { value: "5229", name: "COOP. DE A. Y C. EL ALTAR LTDA." },
    {
      value: "5230",
      name: "COOP. DE A. Y C. NUEVA ALIANZA DE CHIMBORAZO LTDA",
    },
    { value: "5231", name: "COOP. DE A. Y C. LUIS FELIPE DUCHICELA XXVII" },
    { value: "5232", name: "COOP. DE A. Y C. NIZAG LTDA." },
    { value: "5233", name: "COOP. DE A. Y C. MAKITA KUNCHIK" },
    { value: "5234", name: "COOP. DE A. Y C. CERRADA MANUELA LEON" },
    { value: "5235", name: "COOP. DE A. Y C. EL BUEN SEMBRADOR LTDA." },
    { value: "5236", name: "COOP.DE AHORRO Y CREDITO CIUDAD DE ZAMORA" },
    { value: "5237", name: "CCOP DE A. Y C. DE APECAP CAC-APECAP LTDA" },
    {
      value: "5238",
      name: "COOP. DE AHORRO Y CREDITO DE LA PEQUENA EMPRESA DE",
    },
    { value: "5239", name: "COOP. DE A. Y C. CREDISOCIO" },
    {
      value: "5240",
      name: "COOP. DE A. Y C. 5 DE MAYO DE SANTA MARTHA DE CUBA",
    },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { value: "0", name: "Corriente" },
    { value: "1", name: "Ahorros" },
  ],
  [CatalogsEnum.Currency]: [{ value: "USD", name: "USD" }],
  [CatalogsEnum.MerchantTypes]: [
    { value: "01", name: "Natural" },
    { value: "02", name: "Jurídica" },
  ],
};
