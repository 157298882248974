import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    panel: {
      boxShadow: "rgba(17, 17, 26, 0.1) 0px 0px 0px",
      border: 0,
      padding: 0,
      marginBottom: "20px",
    },
    titlePanel: {
      padding: 0,
    },
    head: {
      backgroundColor: "#F7FAFC",
    },
    panelDetails: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    emptyContainer: {
      paddingTop: "80px",
      paddingBottom: "80px",
      textAlign: "center",
      justifyContent: "center",
      width: "100%",
    },
    emptyMessage: {
      color: "rgba(41, 48, 54, 0.5) !important",
      fontSize: "16px",
      lineHeight: "27px",
    },
    iconButton: {
      width: "40px",
      height: "40px",
    },
    trashIcon: {
      color: "#c53d4f",
    },
    editIcon: {
      color: "#023365",
    },
    iconCol: {
      width: "15%",
    },
  })
);

export default useStyles;
