import { IStyles } from "../../shared/interfaces/Styles.interfaces";

const columnConfigurationStyles: IStyles = {
  defaultColor: {
    color: "#023365",
    fill: "none",
    margin: 0,
  },
  buttonConfig: {
    border: "1px solid #CBD5E0",
    borderRadius: "4px",
    color: "#293036",
    fontWeight: 500,
    height: "44px",
    lineHeight: "150%",
    padding: "0px",
    ml: 2,
    maxWidth: "44px",
    minWidth: "44px",
  },
  modal: {
    "& .MuiDialog-paper": { maxWidth: 506, width: "100%", maxHeight: 500 },
  },
  acceptButton: {
    background: "#023365",
    borderRadius: "4px",
    color: "#FFFFFF",
    marginRight: "2%",
    borderColor: "#023365",
    "&:hover, &:focus, &:active": {
      background: "#023365",
      borderRadius: "4px",
      color: "#FFFFFF",
      opacity: 1,
      marginRight: "2%",
    },
    "&:disabled": {
      opacity: 0.7,
      background: "#023365",
    },
  },
  buttonText: {
    "&.MuiTypography-root": {
      fontSize: "16px",
    },
  },
  cancelButton: {
    backgroundColor: "#FAFCFD",
    borderRadius: "4px 4px",
    color: "#293036",
    "&:hover, &:focus, &:active:": {
      backgroundColor: "#FAFCFD",
      borderRadius: "4px 4px",
      boxShadow: "none",
      color: "#293036",
    },
    mr: 2,
  },
  popover: {
    "&.MuiPopover-root .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "&.MuiPopover-root .MuiInput-underline:before": {
      borderBottom: "none",
    },
  },
  title: {
    pl: 3,
    pt: 3,
  },
  content: {
    maxHeight: "220px",
    mb: 3,
    mt: 4,
    pl: 6,
    overflow: "scroll",
  },
  actionButton: {
    height: 76,
  },
  divider: {
    boxShadow: "0px -2px 4px rgb(50 50 50 / 30%)",
  },
};

export { columnConfigurationStyles };
