import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const timelineTabStyles = createNamedStyles({
  container: {
    height: "80%",
    overflowY: "auto",
    backgroundColor: "#F0F4F8",
    padding: "1rem 1rem 0px 1rem",
    "& ul li div a": {
      fontSize: "12px !important",
    },
    "& ul li  div:first-of-type p:first-of-type": {
      fontWeight: 400,
      fontSize: "14px",
    },
    "& ul li div:nth-of-type(3) > div": {
      rowGap: "3px",
    },
    "& ul li div:nth-of-type(3)": {
      lineHeight: "normal",
    },
  },
  btnFiles: {
    "& > span:nth-child(2)": {
      fontSize: "12px",
    },
  },
});

export const iconStyles: object = {
  marginTop: "0px !important",
};
