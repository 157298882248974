import { IBasicMerchantInformation } from "../infrastructure/interfaces/IBasicMerchantInformation";

import { defaultTo, isEmpty } from "lodash";
import { LocalStoragePathEnum } from "../infrastructure/enums/LocalStoragePathEnum";
import { GetBusinessRulesResponse } from "../../../types/get_business_rules_response";
import { IMerchantInfo } from "../infrastructure/interfaces/IMerchantInfo";
import { ICustomerBatchInformation } from "../infrastructure/interfaces/ICustomerBatchInformation";

const defaultBasicMerchantInformation: IBasicMerchantInformation = {
  name: "",
  origin: "",
  nodeId: "",
  publicMerchantId: "",
  country: "",
  redirectPath: "",
  constitutionalCountry: "",
};

const defaultMerchantInfo: IMerchantInfo = {
  country: "",
  merchantName: "",
  publicMerchantId: "",
  isEditing: false,
};

const defaultCustomerBatchInformation: ICustomerBatchInformation = {
  name: "",
  nodeId: "",
  origin: "",
  publicMerchantId: "",
};

const defaultBusinessRules: GetBusinessRulesResponse = {
  items: [],
};

export const getBasicMerchantInformation = (): IBasicMerchantInformation => {
  const storageMerchant = window.localStorage.getItem(
    LocalStoragePathEnum.BASIC_MERCHANT_INFORMATION
  );
  if (isEmpty(storageMerchant)) return defaultBasicMerchantInformation;
  const actualValues = JSON.parse(
    defaultTo(storageMerchant, JSON.stringify(defaultBasicMerchantInformation))
  );

  return defaultTo(actualValues, defaultBasicMerchantInformation);
};

export const getMerchantInfo = (): IMerchantInfo => {
  const storageMerchant = window.localStorage.getItem(
    LocalStoragePathEnum.MERCHANT_INFO
  );
  const actualValues = JSON.parse(
    defaultTo(storageMerchant, JSON.stringify(defaultMerchantInfo))
  );

  return actualValues;
};

export const getCustomerBatchInformation = (): ICustomerBatchInformation => {
  const storageMerchant = window.localStorage.getItem(
    LocalStoragePathEnum.CUSTOMER_BATCH_INFORMATION
  );
  const actualValues = JSON.parse(
    defaultTo(storageMerchant, JSON.stringify(defaultCustomerBatchInformation))
  );

  return actualValues;
};

export const getIsMassiveRules = (): boolean => {
  const item = window.localStorage.getItem(
    LocalStoragePathEnum.IS_MASSIVE_RULES
  );

  return JSON.parse(item || "false");
};

export const setBasicMerchantInformation = (
  newValue: Partial<IBasicMerchantInformation>
) => {
  const oldValue = getBasicMerchantInformation();
  const newItem: IBasicMerchantInformation = { ...oldValue, ...newValue };
  window.localStorage.setItem(
    LocalStoragePathEnum.BASIC_MERCHANT_INFORMATION,
    JSON.stringify(newItem)
  );
};
export const getBusinessRulesStorage = (): GetBusinessRulesResponse => {
  const businessRules = window.localStorage.getItem(
    LocalStoragePathEnum.BUSINESS_RULES_BATCH
  );

  if (isEmpty(businessRules)) return defaultBusinessRules;
  const actualValues = JSON.parse(
    defaultTo(businessRules, JSON.stringify(defaultBusinessRules))
  );

  return defaultTo(actualValues, defaultBusinessRules);
};
