import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../src/shared/axios-util";
import { MerchantData } from "../../../../types/merchant-data";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { ConfigIdEnum } from "../../../shared/constants/initial_state_legal_details";
import { prefixes } from "../../../shared/constants/prefixes";
import { defaultTo } from "lodash";
import { HierarchyNodeInfoRequest } from "../../../../types/hierarchy_node_info_request";
import { TabItemStatusEnum } from "../../../shared/enum/tabItemStatusEnum";
import { GetNodeInfoResponse } from "../../../../types/get_node_info_response";
import getTime from "date-fns/getTime";

export interface updateMerchanteInterface {
  publicMerchantId: string;
  objectToSave: Partial<MerchantData>;
  nodeId: string;
  entityName: string;
  status: TabItemStatusEnum;
  nodeInfo: GetNodeInfoResponse;
}

export const saveContactDataMerchant = createAsyncThunk<
  boolean,
  updateMerchanteInterface
>(
  prefixes.SAVE_CONTACT_DATA_MERCHANT,
  async (data: updateMerchanteInterface) => {
    const publicMerchant: string = data.publicMerchantId;

    await axios.patch<MerchantData>(
      API_ROUTES.UPDATE_MERCHANT_NODE,
      data.objectToSave
    );

    const hierachyRequest: HierarchyNodeInfoRequest = {
      configs: [
        {
          configuration: ConfigIdEnum.CN002,
          status: data.status,
          updatedAt: getTime(Date.now()),
          updatedBy: defaultTo(localStorage.getItem("username"), ""),
          value: publicMerchant,
        },
      ],
      nodeId: data.nodeId,
    };

    await axios.put<boolean>(API_ROUTES.HIERARCHY_NODE_INFO, hierachyRequest);

    return true;
  }
);
