import { HeadCell } from "../../components/Table/TableHead/TableHead.interfaces";
import { IConfigCells } from "../../components/Table/TableNodes/constants";
import {
  CatalogConfigTableBranches,
  CatalogConfigTableRetention,
  headCellsBranches,
  headCellsRetention,
} from "./CatalogConfigTableBranches";
import { FieldNameEnum } from "../enums/FieldNameEnum";

export enum NodeTypeEnum {
  BRANCH = "BRANCH",
}

export enum TableTypeEnum {
  RETENTION = "RETENTION",
  BRANCH = "BRANCH",
}

export const CATALOG_HEADS: Record<TableTypeEnum, HeadCell[]> = {
  [TableTypeEnum.RETENTION]: headCellsRetention,
  [TableTypeEnum.BRANCH]: headCellsBranches,
};

export const CATALOG_ROWS: Record<TableTypeEnum, IConfigCells[]> = {
  [TableTypeEnum.RETENTION]: CatalogConfigTableRetention,
  [TableTypeEnum.BRANCH]: CatalogConfigTableBranches,
};

export const FIELD_SORT_CATALOGUE = {
  [FieldNameEnum.RETENTION_CYCLE]: "cells[0].props.fromDate",
  [FieldNameEnum.PROCESSOR_TYPE]: "cells[1].props.text",
  [FieldNameEnum.RET_IVA]: "cells[2].props.text",
  [FieldNameEnum.RET_ICA]: "cells[3].props.text",
  [FieldNameEnum.RET_FUE]: "cells[4].props.text",
};
