import * as React from "react";
import { Button, Grid, Modal, Typography, makeStyles } from "@material-ui/core";
export interface ImageModalProps {
  open: boolean;
  handleClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  headerText: string;
  bodyText: string;
  firstButtonText?: string;
  onClickFirstButton?: () => void;
  secondButtonText?: string;
  onClickSecondButton?: () => void;
  imageSrc?: string;
}

const useStyles = makeStyles(() => ({
  imageModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalHeader: {
    fontSize: "1.5rem",
  },
  modalImage: {
    width: "80%",
  },
  imageModalContainer: {
    padding: "40px 32px",
    background: "#FFFFFF",
    boxShadow:
      "0 4px 6px rgba(50, 50, 93, 0.12), 0 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "20px",
    outline: "none",
    width: "30%",
    textAlign: "center",
  },
  imageModalBody: {
    paddingTop: "10px",
    paddingBottom: "20px",
  },
}));

const ImageModal: React.FC<ImageModalProps> = (props) => {
  const {
    open,
    headerText,
    bodyText,
    firstButtonText,
    onClickFirstButton,
    secondButtonText,
    onClickSecondButton,
    imageSrc,
  } = props;
  const classes = useStyles();
  return (
    <Modal open={open} disableAutoFocus={true} className={classes.imageModal}>
      <div className={classes.imageModalContainer}>
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item>
            <img src={imageSrc} alt="merchant" className={classes.modalImage} />
          </Grid>
          <Typography className={classes.modalHeader} color="primary">
            {headerText}
          </Typography>
          <Typography
            className={classes.imageModalBody}
            variant={"body1"}
            color={"textSecondary"}
          >
            {bodyText}
          </Typography>
          {firstButtonText && (
            <Grid item>
              <Button
                onClick={onClickFirstButton}
                variant="contained"
                type="button"
                color={"primary"}
              >
                <Typography variant={"body1"}>{firstButtonText}</Typography>
              </Button>
            </Grid>
          )}
          {secondButtonText && (
            <Grid item>
              <Button
                onClick={onClickSecondButton}
                color={"primary"}
                size="large"
                type="button"
              >
                <Typography variant={"body1"}>{secondButtonText}</Typography>
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </Modal>
  );
};

export default ImageModal;
