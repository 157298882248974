import { ActionTypes } from "./actionTypes";
import axios from "../shared/utils/axios-util";
import { DispersionRequest } from "../../types/dispersion_request";
import { DispersionResponse } from "../../types/dispersion_response";
import { environment } from "../environments/environment";
import { IAppState } from "./reducer";
import { INotification } from "../shared/infrastructure/interfaces/INotification";
import { ThunkDispatch } from "redux-thunk";

export type IAppAction = { type: string } & IAppState;

export const setNotification = (payload: INotification) => ({
  notification: payload,
  type: ActionTypes.SET_NOTIFICATION,
});

export const setDispersionResponse = (
  payload: DispersionResponse
): IAppAction => ({
  dispersionResponse: payload,
  type: ActionTypes.SET_DISPERSION_RESPONSE,
});

export const setIsLoadingDispersion = (payload: boolean): IAppAction => ({
  isLoadingDispersion: payload,
  type: ActionTypes.SET_IS_LOADING_DISPERSION,
});

export const uploadDispersionCycles =
  (request: DispersionRequest) =>
  async (dispatch: ThunkDispatch<IAppState, any, IAppAction>) => {
    const url: string = `${environment.kushkiUrl}/dispersions/v1/dispersion/config-file`;

    dispatch(setIsLoadingDispersion(true));
    try {
      const resp = await axios.post(url, request);

      dispatch(setDispersionResponse(resp.data));
      dispatch(setIsLoadingDispersion(false));
    } catch (e) {
      dispatch(setIsLoadingDispersion(false));
    }
  };
