import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { DateRange } from "@material-ui/pickers";
import { Skeleton } from "@material-ui/lab";
import { KshDateRangePicker } from "@kushki/frontend-molecules/date-range-picker";

export interface SupportDatePickerProps {
  handleDate?: (dateRange: DateRange) => void;
  disableFuture?: boolean;
  value?: DateRange;
  isLoading?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  datePicker: {
    [theme.breakpoints.down(769)]: {
      marginLeft: "inherit",
      marginRight: "inherit",
      border: "none",
    },
  },
  gridContainer: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    width: "315px",
    marginTop: "4px",
    height: "39px",
    borderRadius: 4,
    [theme.breakpoints.down(769)]: {
      border: "none",
      borderRadius: 0,
    },
    "& .button-date-range-picker-wrap": {
      float: "left",
      margin: "auto",
      marginLeft: "-9px",
      marginBottom: "8px",
    },
  },
}));

export const SupportDatePicker: React.FC<SupportDatePickerProps> = (
  props: SupportDatePickerProps
) => {
  const classes = useStyles();

  return (
    <div className={classes.datePicker}>
      <Grid container>
        <Grid className={classes.gridContainer} item xs={12}>
          {props.isLoading ? (
            <Skeleton
              animation="wave"
              variant="rect"
              width={"100%"}
              height={35}
            />
          ) : (
            // eslint-disable-next-line react/jsx-no-undef
            <KshDateRangePicker
              handleDate={props.handleDate!}
              disableFuture={props.disableFuture!}
              value={props.value!}
              enableTimePicker
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};
