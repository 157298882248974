import { SnackBarProvider, theme } from "@kushki/connect-ui";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import BranchListContainer from "./containers/BranchListContainer/BranchListContainer";
import MassiveUserContainer from "./containers/MassiveUserContainer/MassiveUserContainer";

import { store } from "./store/store";
import { environment } from "./environments/environment";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
import { ROUTES } from "./shared/constants/routes";
import UsersContainer from "./containers/UsersContainer/UsersContainer";
import UserCreateEdit from "./containers/UserCreateEdit/UserCreateEdit";
import { SecurityWrapperRoles } from "./shared/enum/SecurityWrapperRoles";
import { Provider as RollbarProvider } from "@rollbar/react";
import { getRollbarConfig } from "./shared/constants/GetRollbarConfig";

const Root = () => {
  return (
    <React.StrictMode>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&display=swap');"
        }
      </style>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500&display=swap');"
        }
      </style>
      {
        // @ts-ignore
        <RollbarProvider config={getRollbarConfig}>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <SnackBarProvider>
                <ModuleSecurityWrapper
                  basePath={environment.kushkiUrl}
                  componentId={SecurityWrapperRoles.M_USERS_ROOT}
                >
                  <BrowserRouter basename={ROUTES.SPA_BASENAME}>
                    <Routes>
                      <Route index element={<BranchListContainer />} />
                      <Route
                        path={ROUTES.FORM}
                        element={<MassiveUserContainer />}
                      />
                      <Route path={ROUTES.USERS} element={<UsersContainer />} />
                      <Route
                        path={ROUTES.CREATE_USER}
                        element={<UserCreateEdit />}
                      />
                      <Route
                        path={ROUTES.EDIT_USER}
                        element={<UserCreateEdit />}
                      />
                    </Routes>
                  </BrowserRouter>
                </ModuleSecurityWrapper>
              </SnackBarProvider>
            </ThemeProvider>
          </Provider>
        </RollbarProvider>
      }
    </React.StrictMode>
  );
};

export default Root;
