import React, { ReactNode } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useBoardMemberContent } from "./State/useBoardMemberContent";
import { boardMembersContentStyles as styles } from "./BoardMembers.styles";
import { IBoardMember } from "../../../../store/interfaces/LegalDetailsState.interfaces";
import { BoardMemberContentEnum } from "../../../../shared/enum/BoardMemberContentEnum";
import { isEmpty } from "lodash";
import { BoardMemberLabel } from "../../../../shared/enum/BoardMembersEnum";
import { Flag } from "../../../Flag/Flag";
import { CountryEnum } from "../../../../shared/countryEnum";

interface IBoardMemberContentProps {
  boardMember: IBoardMember;
  variant: BoardMemberContentEnum;
}

const SecondaryBMContentItem = ({
  label,
  value,
  valuePrefix = <></>,
}: {
  label: string;
  value: string | number;
  valuePrefix?: ReactNode | string;
}) => (
  <Grid container mb={2}>
    <Grid item xs={2}>
      <Typography sx={styles.text}>{label}</Typography>
    </Grid>
    <Grid item xs={6} sx={styles.flex}>
      {valuePrefix}
      <Typography sx={styles.textBlack}>{value}</Typography>
    </Grid>
  </Grid>
);

const BoardMemberContentVariant = (
  countryLabel: string,
  date: string | number,
  dateLabel: string,
  documentLabel: string,
  country: string,
  document: string,
  name: string,
  nameLabel: string,
  idType: string,
  idTypeLabel: string,
  documentSecondary: string,
  birthdateLabel: string
) => ({
  [BoardMemberContentEnum.MAIN]: (
    <Box sx={styles.container}>
      <Box>
        <Typography sx={styles.text}>{countryLabel}</Typography>
        <Typography sx={styles.text}>{country}</Typography>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box>
        <Typography sx={styles.text}>{dateLabel}</Typography>
        <Typography sx={styles.text}>{date}</Typography>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box>
        <Typography sx={styles.text}>{documentLabel}</Typography>
        <Typography sx={styles.text}>{document}</Typography>
      </Box>
      <Divider orientation="vertical" flexItem />
    </Box>
  ),
  [BoardMemberContentEnum.SECONDARY]: (
    <Box>
      <SecondaryBMContentItem label={nameLabel} value={name} />
      {!isEmpty(idTypeLabel) && (
        <SecondaryBMContentItem label={idTypeLabel} value={idType} />
      )}
      <SecondaryBMContentItem
        label={BoardMemberLabel.ID_NUMBER}
        value={documentSecondary}
      />
      <SecondaryBMContentItem label={birthdateLabel} value={date} />
      <SecondaryBMContentItem
        label={countryLabel}
        value={country}
        valuePrefix={
          <Box sx={styles.country}>
            <Flag country={country as CountryEnum} />
          </Box>
        }
      />
    </Box>
  ),
});

const BoardMemberContent: React.FC<IBoardMemberContentProps> = ({
  boardMember,
  variant,
}: IBoardMemberContentProps) => {
  const {
    date,
    dateLabel,
    documentLabel,
    document,
    countryLabel,
    country,
    name,
    nameLabel,
    idType,
    idTypeLabel,
    documentSecondary,
    birthdateLabel,
  } = useBoardMemberContent(boardMember);

  return BoardMemberContentVariant(
    countryLabel,
    date,
    dateLabel,
    documentLabel,
    country,
    document,
    name,
    nameLabel,
    idType,
    idTypeLabel,
    documentSecondary,
    birthdateLabel
  )[variant];
};

export default BoardMemberContent;
