/**
 * Paths redirect enum
 */
export enum PathEnum {
  MERCHANT_LIST = "/merchant-list",
  MERCHANT_DETAILS = "/create-merchant/details",
  MERCHANT_DETAILS_PARENT = "/create-merchant/details-parent",
  MERCHANT_DETAILS_CHILD = "/create-merchant/details-child",
  MERCHANT_CONFIG = "/config-merchant/merchant",
}
