import { IMerchantBillingData } from "../../interfaces/merchantBillingData/merchantBillingData.interfaces";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getCatalogZip,
  saveBillingData,
} from "../../thunks/merchantBillingData/merchantBillingData.thunks";
import { CatalogZipResponse } from "../../../../types/catalog_zip_response";
import { get } from "lodash";
import { getMerchantInfo } from "../../thunks/general/general.thunk";
import { NotificationTypeEnum } from "../../../shared/enums/SnackbarEnum";
import {
  buildNotification,
  defaultDataSnackbar,
} from "../../../shared/constants/snackbar";
import { ISnackBar } from "@kushki/connect-ui/dist/Components/Atoms/DataDisplay/SnackBar/SnackBar.interface";
import { getBillingData } from "../../../shared/utils/billing_data_utils";
import { MerchantResponse } from "../../../../types/get_merchant_response";
import { ConfigIdEnum } from "../../../shared/constants/initial_state_legal_details";

export const initialState: IMerchantBillingData = {
  catalogSatZip: {
    areaCode: "",
    city: "",
    municipality: "",
    populationCode: "",
    province: "",
    settlement: [],
    state: "",
    stateCode: "",
    zip: "",
  },
  catalogZip: {
    areaCode: "",
    city: "",
    municipality: "",
    populationCode: "",
    province: "",
    settlement: [],
    state: "",
    stateCode: "",
    zip: "",
  },
  isLoadingBillingData: false,
  isLoadingGetCatalogZip: false,
  isLoadingGetNodeInfo: false,
  merchantCompany: {
    activityId: "",
    address: "",
    areaCode: "",
    city: "",
    cologne: "",
    countryCode: "",
    department: "",
    incomeSourceConceptId: "",
    isPaymentAggregator: false,
    municipality: "",
    obligation: "",
    phoneNumber: "",
    populationCode: "",
    province: "",
    sat: {
      address: "",
      areaCode: "",
      cologne: "",
      countryCode: "",
      phoneNumber: "",
      populationCode: "",
      stateCode: "",
      zipCode: "",
    },
    stateCode: "",
    taxpayerTypeId: "",
    taxRegime: "",
    useSameInfoSat: true,
    zipCode: "",
  },
  notification: undefined,
  typeButton: "none",
};

export const merchantBillingDataSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getCatalogZip.pending, (state) => {
        state.isLoadingGetCatalogZip = true;
      })
      .addCase(
        getCatalogZip.fulfilled,
        (state, { payload }: PayloadAction<object>) => {
          if (get(payload, "isSat", false))
            state.catalogSatZip = get(
              payload,
              "data",
              {} as CatalogZipResponse
            );
          else
            state.catalogZip = get(payload, "data", {} as CatalogZipResponse);

          state.isLoadingGetCatalogZip = false;
        }
      )
      .addCase(getCatalogZip.rejected, (state) => {
        state.isLoadingGetCatalogZip = false;
      })
      .addCase(getMerchantInfo.pending, (state) => {
        state.isLoadingGetNodeInfo = true;
      })
      .addCase(getMerchantInfo.fulfilled, (state, { payload }) => {
        if (payload.configId === ConfigIdEnum.CN016) {
          state.isLoadingGetNodeInfo = false;
          state.merchantCompany = getBillingData(
            payload.data as MerchantResponse
          );
        }
      })
      .addCase(getMerchantInfo.rejected, (state) => {
        state.isLoadingGetNodeInfo = false;
      })
      .addCase(saveBillingData.pending, (state) => {
        state.isLoadingBillingData = true;
      })
      .addCase(saveBillingData.fulfilled, (state) => {
        state.isLoadingBillingData = false;
        state.notification = buildNotification(
          NotificationTypeEnum.SUCCESS,
          get(state, "notification", defaultDataSnackbar)
        );
      })
      .addCase(saveBillingData.rejected, (state) => {
        state.isLoadingBillingData = false;
        state.notification = buildNotification(
          NotificationTypeEnum.FAILED,
          get(state, "notification", defaultDataSnackbar)
        );
      });
  },
  initialState,
  name: "merchantCompany",
  reducers: {
    setIsLoadingGetNodeInfo: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingGetNodeInfo = payload;
    },
    setNotification: (
      state,
      { payload }: PayloadAction<ISnackBar | undefined>
    ) => {
      state.notification = payload;
    },
    setTypeButtonBilling: (state, { payload }: PayloadAction<string>) => {
      state.typeButton = payload;
    },
  },
});

export default merchantBillingDataSlice.reducer;
