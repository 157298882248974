export const CENTRALIZE = "Centralizados";
export const DESCENTRALIZE = "Descentralizados";

export const MODIFY = "Modificado";
export const NO_MODIFY = "Sin modificar";
export const INCOMPLETE = "Incompletos";

export const VARIABLE_ARRAY_CONFIRM_MODAL: Array<{
  name: string;
  value: number;
}> = [
  {
    name: CENTRALIZE,
    value: 30,
  },
  {
    name: DESCENTRALIZE,
    value: 10,
  },
];
