import { appSlice } from "../reducers/app/app";

export const {
  showLoading,
  hideLoading,
  hideRefundModal,
  showRefundModal,
  setLoadingText,
  setRefundModalData,
} = appSlice.actions;
