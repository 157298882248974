import React, { useEffect } from "react";
import { TextField } from "@material-ui/core";
import { useTypedController } from "@hookform/strictly-typed";
import ConnectForm from "../../commons/ConnectForm/ConnectForm";
import { ISecurityRuleForm } from "../../../shared/infrastructure/interfaces/ISecurityRuleForm";
import { SecurityRulesLabelField } from "../../../shared/infrastructure/constants/SecurityRulesConstants";
import { get, isEmpty } from "lodash";
import { useWatch } from "react-hook-form";

export interface PatternValidation {
  pattern: string;
  message: string;
}

export interface TextFieldComponentProps {
  index: number;
  disabled: boolean;
  type?: string;
  mask?: any;
  pattern?: PatternValidation;
}

export const getRulesByField = (pattern?: PatternValidation) => {
  if (pattern) {
    return {
      required: true,
      pattern: {
        value: new RegExp(pattern.pattern),
        message: pattern.message,
      },
    };
  }

  return {
    required: true,
  };
};

export const TextFieldComponent: React.FC<TextFieldComponentProps> = (
  props: TextFieldComponentProps
) => {
  return (
    <ConnectForm<ISecurityRuleForm>>
      {({ control, errors, getErrorMessage }) => {
        const TypedController = useTypedController<ISecurityRuleForm>({
          control: control,
        });

        const value = useWatch({
          name: `strictCondition[${props.index}].value`,
        }) as string;

        useEffect(() => {
          if (isEmpty(value) && !isEmpty(errors)) {
            errors.strictCondition?.splice(props.index, 1);
          }
        }, [value]);

        return (
          <React.Fragment>
            <TypedController
              name={["strictCondition", props.index, "value"]}
              rules={getRulesByField(props.pattern)}
              defaultValue={""}
              render={(properties) => (
                <TextField
                  {...properties}
                  id="value"
                  value={isEmpty(value) ? "" : value}
                  label={SecurityRulesLabelField.VALUE}
                  type={props.type}
                  variant="outlined"
                  error={!!get(errors, `strictCondition.${props.index}.value`)}
                  fullWidth
                  InputProps={{
                    inputComponent: props.mask as any,
                  }}
                  helperText={
                    !!get(errors, `strictCondition.${props.index}.value`) &&
                    getErrorMessage(
                      get(errors, `strictCondition.${props.index}.value`)
                    )
                  }
                />
              )}
            />
          </React.Fragment>
        );
      }}
    </ConnectForm>
  );
};

export default TextFieldComponent;
