/**
 *
 */
export enum CountriesEnum {
  ECUADOR = "Ecuador",
  COLOMBIA = "Colombia",
  PERU = "Peru",
  CHILE = "Chile",
  MEXICO = "Mexico",
  BRAZIL = "Brazil",
  EL_SALVADOR = "ElSalvador",
  GUATEMALA = "Guatemala",
  HONDURAS = "Honduras",
  NICARAGUA = "Nicaragua",
  PANAMA = "Panama",
  COSTA_RICA = "CostaRica",
  CENTRO_AMERICA = "CentroAmerica",
}

export const REGULATED_COUNTRIES: string[] = [
  CountriesEnum.ECUADOR,
  CountriesEnum.COLOMBIA,
  CountriesEnum.CHILE,
  CountriesEnum.GUATEMALA,
  CountriesEnum.HONDURAS,
  CountriesEnum.EL_SALVADOR,
  CountriesEnum.PANAMA,
];

export const COUNTRY_DEFERRED: CountriesEnum[] = [
  CountriesEnum.ECUADOR,
  CountriesEnum.PERU,
  CountriesEnum.MEXICO,
  CountriesEnum.COSTA_RICA,
  CountriesEnum.EL_SALVADOR,
  CountriesEnum.GUATEMALA,
  CountriesEnum.HONDURAS,
  CountriesEnum.NICARAGUA,
  CountriesEnum.PANAMA,
  CountriesEnum.BRAZIL,
];
