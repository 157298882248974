import {
  getBranchListFromSessionStorage,
  setItemInLocalStorage,
  setItemInSessionStorage,
} from "../../../shared/utils/session_storage_utils";
import { BranchEditItem } from "../../../../types/branch_edit_item";
import { IUseSectionContainer } from "./useSectionContainer.interfaces";
import { useNavigate } from "react-router-dom";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { useEffect, useState } from "react";
import { ISectionValueInterface } from "../../../shared/interfaces/ISectionValue.interface";
import {
  ALMOST_ONE_SELECTION,
  buildItemsForSideBar,
  getMassiveUrlByTitle,
  setChecked,
  validatedBranchesDescentralized,
  validateEditForCompanyAndFinancial,
} from "../../../shared/constants/selected_section";
import { SectionValuesEnum } from "../../../shared/enums/SecctionValuesEnum";
import { IButtonCardProps } from "../../../shared/interfaces/ButtonCardProps.interface";
import {
  validateAllBranchHaveSameCountry,
  validateClientTypeIsBTB_BTC,
  validateClientTypeIsPSP,
} from "../../../shared/utils/select_section_utils";
import { defaultTo, get, isEmpty } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { searchMerchantByNode } from "../../../store/thunks/createNode/createNode.thunks";
import { OriginEnum } from "../../../shared/enums/OriginEnum";
import { LocalStoragePaths } from "../../../shared/enums/LocalStoragePathsEnum";

export const useSectionContainer = (): IUseSectionContainer => {
  const pathBranchEditList: string = "branchEditList";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isDisableFinishBtn, setIsDisableFinishBtn] = useState<boolean>(false);
  const editBranchList: BranchEditItem[] = get(
    getBranchListFromSessionStorage(pathBranchEditList),
    "data",
    []
  ).filter((branchEdit: BranchEditItem) => branchEdit.edit);
  const { getNodeInfoResponse } = useAppSelector(
    (state) => state.nodeInfoByNodeId
  );

  const validateIfIsPossibleEdit = (): boolean => {
    const branchesHaveSameCountry =
      validateAllBranchHaveSameCountry(editBranchList);
    const branchesAreB2BorBTC = validateClientTypeIsBTB_BTC(editBranchList);
    const branchesArePSP = validateClientTypeIsPSP(editBranchList);

    return (branchesAreB2BorBTC || branchesArePSP) && branchesHaveSameCountry;
  };

  const [sectionValues, setSectionValues] = useState<ISectionValueInterface[]>(
    validateEditForCompanyAndFinancial(
      validateIfIsPossibleEdit(),
      editBranchList
    )
  );

  const verifyCountryAndDescentralized = () => {
    const isAnyBranchSectionNotDescentralized: boolean =
      !validatedBranchesDescentralized.every((val) => !val);

    return (
      isAnyBranchSectionNotDescentralized ||
      !validateAllBranchHaveSameCountry(editBranchList)
    );
  };

  const [isInvalidEdition] = useState<boolean>(
    verifyCountryAndDescentralized()
  );

  const [batchSections, setBatchSections] = useState<IButtonCardProps[]>([]);

  const handleChecked = (title: SectionValuesEnum) => {
    setSectionValues(setChecked(title, sectionValues));
  };

  const handleBatchSections = () => {
    const checkedSections = sectionValues.filter((section) => section.checked);

    setBatchSections(buildItemsForSideBar(checkedSections));
  };

  const handleValidateFinishEdition = () => {
    const checkedSections = sectionValues.filter((section) => section.checked);

    if (checkedSections.length >= ALMOST_ONE_SELECTION) {
      setIsDisableFinishBtn(true);
    } else {
      setIsDisableFinishBtn(false);
    }
  };

  const handleReturn = () => {
    navigate(`${API_ROUTES.EDIT_BRANCHES}`);
  };

  const setMerchantBasicInformation = (): void => {
    const processionSection: IButtonCardProps[] = batchSections.filter(
      (section: IButtonCardProps) =>
        section.checked &&
        (section.title === SectionValuesEnum.PROCESSING ||
          section.title === SectionValuesEnum.SERVICES)
    );

    if (!isEmpty(processionSection))
      setItemInLocalStorage("customerBatchInformation", {
        name: get(getNodeInfoResponse, "name", ""),
        nodeId: get(getNodeInfoResponse, "nodeId", ""),
        origin: OriginEnum.BATCH_PROCESSING,
        publicMerchantId: get(getNodeInfoResponse, "merchantId", ""),
      });
  };

  const handleContinue = () => {
    setMerchantBasicInformation();
    setItemInSessionStorage("batchSections", batchSections);
    localStorage.removeItem(LocalStoragePaths.MERCHANT_DEFERRED_DATA);
    window.location.href = getMassiveUrlByTitle.get(
      get(batchSections, "[0].title", "")
    ) as string;
  };

  const getNodeInfo = (): void => {
    const nodeIds: string[] = get(editBranchList, "[0].path", "").split(":");

    dispatch(searchMerchantByNode({ nodeId: defaultTo(nodeIds[1], "") }));
  };

  useEffect(() => {
    handleValidateFinishEdition();
    handleBatchSections();
  }, [sectionValues]);

  useEffect(() => {
    getNodeInfo();
  }, []);

  return {
    handleChecked,
    handleContinue,
    handleReturn,
    isDisableFinishBtn,
    isInvalidEdition,
    sectionValues,
  };
};
