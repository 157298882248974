import React from "react";
export const CrearPrimerBranch = () => {
  return (
    <svg
      width="325"
      height="291"
      viewBox="0 0 325 291"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M288.86 47.7707C249.891 17.9241 212.093 42.2486 169.933 33.6482C127.469 24.9856 110.551 -3.85809 62.1851 9.45606C19.341 21.2498 -6.65549 68.5351 16.8974 108.123C23.6092 119.404 35.0431 127.914 39.7158 140.537C47.2532 160.899 35.78 176.531 38.0181 196.542C47.3182 279.686 174.776 247.481 221.675 221.613C295.227 181.044 344.984 90.7576 288.86 47.7707Z"
        fill="url(#paint0_linear_28_3918)"
        fill-opacity="0.3"
      />
      <path
        opacity="0.24"
        d="M252.5 17C250.322 17 248.145 16.1707 246.487 14.5133C243.171 11.198 243.171 5.80293 246.487 2.48697C249.802 -0.828989 255.198 -0.828989 258.513 2.48697C261.829 5.80293 261.829 11.1974 258.513 14.5133C256.856 16.1707 254.678 17 252.5 17ZM252.501 5.86216C251.824 5.86216 251.149 6.11904 250.634 6.63397C249.606 7.66265 249.606 9.33706 250.634 10.3663C251.663 11.395 253.338 11.3956 254.367 10.3663C255.396 9.33764 255.396 7.66324 254.367 6.63397C253.852 6.11904 253.176 5.86216 252.501 5.86216Z"
        fill="#FAFCFD"
      />
      <path
        opacity="0.24"
        d="M29 85C26.6942 85 24.3878 84.1707 22.6329 82.5132C19.1224 79.1971 19.1224 73.8019 22.6329 70.4864C26.1434 67.1715 31.8566 67.1709 35.3671 70.4864C38.8776 73.8025 38.8776 79.1971 35.3671 82.5132C33.6122 84.1707 31.3058 85 29 85ZM29 73.8617C28.2846 73.8617 27.5686 74.1186 27.024 74.6336C25.9341 75.6623 25.9341 77.3368 27.024 78.3661C28.1132 79.3954 29.8868 79.3948 30.976 78.3661C32.0659 77.3373 32.0659 75.6629 30.976 74.6336C30.4314 74.1186 29.716 73.8617 29 73.8617Z"
        fill="#4498EE"
      />
      <path
        opacity="0.48"
        d="M25.0686 112C24.3182 112 23.5685 111.7 22.9958 111.099L19.4997 107.429L16.0036 111.099C14.8588 112.3 13.0035 112.3 11.8586 111.099C10.7138 109.897 10.7138 107.949 11.8586 106.747L17.4275 100.901C18.5723 99.6997 20.4277 99.6997 21.5725 100.901L27.1414 106.747C28.2862 107.949 28.2862 109.898 27.1414 111.099C26.5687 111.699 25.8189 112 25.0686 112Z"
        fill="#FAFCFD"
      />
      <path
        opacity="0.48"
        d="M262.069 222C261.318 222 260.569 221.7 259.996 221.099L256.5 217.429L253.004 221.099C251.859 222.3 250.003 222.3 248.859 221.099C247.714 219.897 247.714 217.949 248.859 216.747L254.427 210.901C255.572 209.7 257.428 209.7 258.573 210.901L264.141 216.747C265.286 217.949 265.286 219.898 264.141 221.099C263.569 221.699 262.819 222 262.069 222Z"
        fill="#1E65AE"
      />
      <g filter="url(#filter0_dddd_28_3918)">
        <rect
          x="78.5208"
          y="60"
          width="183"
          height="109"
          rx="8"
          transform="rotate(7.12553 78.5208 60)"
          fill="#FBFCFE"
        />
      </g>
      <g filter="url(#filter1_dddd_28_3918)">
        <rect
          x="65.3037"
          y="76.7969"
          width="183"
          height="109"
          rx="8"
          transform="rotate(-5.36674 65.3037 76.7969)"
          fill="#FBFCFE"
        />
      </g>
      <path
        opacity="0.48"
        d="M273.5 50C271.324 50.0006 269.147 49.1713 267.49 47.5133C265.884 45.9069 265 43.7715 265 41.5C265 39.2286 265.884 37.0932 267.49 35.4868C270.803 32.1714 276.196 32.1708 279.51 35.4868C281.116 37.0932 282 39.2286 282 41.5C282 43.7721 281.116 45.9075 279.51 47.5133C277.854 49.1707 275.677 50 273.5 50ZM273.5 38.862C272.825 38.862 272.149 39.1189 271.635 39.6338C271.136 40.1323 270.862 40.7951 270.862 41.5C270.862 42.205 271.137 42.8677 271.635 43.3662C272.664 44.3955 274.337 44.3955 275.366 43.3662C275.865 42.8677 276.138 42.205 276.138 41.5C276.138 40.7951 275.864 40.1329 275.366 39.6338C274.852 39.1189 274.176 38.862 273.5 38.862Z"
        fill="#CBD5E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M234.224 153.743L243.287 160.248C243.717 160.557 244.253 160.683 244.777 160.597C245.3 160.511 245.767 160.22 246.077 159.79C246.386 159.359 246.511 158.823 246.425 158.3C246.339 157.777 246.049 157.309 245.618 157L236.54 150.484L242.945 149.445C243.205 149.403 243.454 149.309 243.678 149.17C243.902 149.031 244.096 148.849 244.25 148.635C244.404 148.421 244.514 148.179 244.574 147.922C244.634 147.665 244.643 147.399 244.6 147.139C244.558 146.879 244.464 146.63 244.325 146.406C244.186 146.182 244.004 145.988 243.79 145.834C243.576 145.68 243.334 145.57 243.077 145.51C242.82 145.45 242.554 145.441 242.294 145.484L231.192 147.308C231.173 147.311 231.153 147.314 231.134 147.317C230.609 147.403 230.139 147.695 229.829 148.127C229.675 148.341 229.565 148.584 229.505 148.84C229.445 149.097 229.436 149.363 229.479 149.623L231.312 160.782C231.355 161.042 231.45 161.29 231.59 161.513C231.73 161.736 231.912 161.929 232.127 162.081C232.34 162.236 232.581 162.347 232.837 162.408C233.093 162.469 233.358 162.479 233.618 162.438C233.878 162.396 234.128 162.303 234.353 162.164C234.577 162.025 234.772 161.844 234.926 161.629C235.08 161.415 235.19 161.172 235.249 160.915C235.309 160.658 235.317 160.392 235.273 160.131L234.224 153.743Z"
        fill="#CBD5E0"
      />
      <rect
        x="81"
        y="88.1074"
        width="50"
        height="5.55556"
        rx="2.77778"
        transform="rotate(-5.37 81 88.1074)"
        fill="#CBD5E0"
      />
      <rect
        x="87"
        y="156.679"
        width="106.561"
        height="5.55556"
        rx="2.77778"
        transform="rotate(-5.37 87 156.679)"
        fill="#CBD5E0"
      />
      <rect
        x="81"
        y="104.679"
        width="154.297"
        height="5.55556"
        rx="2.77778"
        transform="rotate(-5.37 81 104.679)"
        fill="#CBD5E0"
      />
      <rect
        x="83"
        y="121.44"
        width="154.297"
        height="5.55556"
        rx="2.77778"
        transform="rotate(-5.37 83 121.44)"
        fill="#CBD5E0"
      />
      <rect
        x="85"
        y="139.44"
        width="154.297"
        height="5.55556"
        rx="2.77778"
        transform="rotate(-5.37 85 139.44)"
        fill="#CBD5E0"
      />
      <defs>
        <filter
          id="filter0_dddd_28_3918"
          x="0.929688"
          y="31.9297"
          width="323.248"
          height="258.999"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.575" />
          <feGaussianBlur stdDeviation="1.87891" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.415686 0 0 0 0 0.654902 0 0 0 0 0.772549 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_28_3918"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="7.2" />
          <feGaussianBlur stdDeviation="5.28125" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.415686 0 0 0 0 0.654902 0 0 0 0 0.772549 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_28_3918"
            result="effect2_dropShadow_28_3918"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="18.225" />
          <feGaussianBlur stdDeviation="14.168" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.415686 0 0 0 0 0.654902 0 0 0 0 0.772549 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_28_3918"
            result="effect3_dropShadow_28_3918"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="36" />
          <feGaussianBlur stdDeviation="32.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.415686 0 0 0 0 0.654902 0 0 0 0 0.772549 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="effect3_dropShadow_28_3918"
            result="effect4_dropShadow_28_3918"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect4_dropShadow_28_3918"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_dddd_28_3918"
          x="1.01636"
          y="31.3936"
          width="320.967"
          height="254.213"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.575" />
          <feGaussianBlur stdDeviation="1.87891" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.415686 0 0 0 0 0.654902 0 0 0 0 0.772549 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_28_3918"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="7.2" />
          <feGaussianBlur stdDeviation="5.28125" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.415686 0 0 0 0 0.654902 0 0 0 0 0.772549 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_28_3918"
            result="effect2_dropShadow_28_3918"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="18.225" />
          <feGaussianBlur stdDeviation="14.168" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.415686 0 0 0 0 0.654902 0 0 0 0 0.772549 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_28_3918"
            result="effect3_dropShadow_28_3918"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="36" />
          <feGaussianBlur stdDeviation="32.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.415686 0 0 0 0 0.654902 0 0 0 0 0.772549 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="effect3_dropShadow_28_3918"
            result="effect4_dropShadow_28_3918"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect4_dropShadow_28_3918"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_28_3918"
          x1="216.931"
          y1="293.4"
          x2="341.765"
          y2="29.6322"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E1E8F2" />
          <stop offset="1" stop-color="#E2E8F0" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
