import { ChargebackStatusEnum } from "../enums/ChargebackStatusEnum";

interface OptionInformation {
  status: string;
  color: string;
  sendStatus?: string;
}

export const getOptions = (status: string): OptionInformation[] => {
  switch (status) {
    /*    case ChargebackStatusEnum.FAILED:
      return [
        {
          color: "black",
          sendStatus: ChargebackStatusEnum.DOCSENT,
          status: ChargebackStatusEnum.DOCSENT,
        },
      ];*/
    case ChargebackStatusEnum.REVIEW:
      return [
        {
          color: "black",
          sendStatus: ChargebackStatusEnum.APPROVAL,
          status: ChargebackStatusEnum.WON,
        },
        {
          color: "#AD0C2A",
          sendStatus: ChargebackStatusEnum.DECLINED,
          status: ChargebackStatusEnum.LOST,
        },
      ];
    /*    case ChargebackStatusEnum.DOCSENT:
      return [
        {
          color: "black",
          status: ChargebackStatusEnum.APPROVAL,
        },
        {
          color: "#AD0C2A",
          status: ChargebackStatusEnum.DECLINED,
        },
      ];*/
    default:
      return [];
  }
};
