import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const listBranchCreationContainerStyles = createNamedStyles({
  boxContainer: {
    display: "flex",
    justifyContent: "center",
    width: 1,
    pb: 5,
  },
});
