export enum DispersionsFrequencyLabelEnum {
  DAILY = "Diario",
  WEEKLY = "Semanal",
  MONTHLY = "Mensual",
  TWO_WEEKLY = "Mar-Vie",
  THREE_WEEKLY = "Lun-Mie-Vie",
  BI_WEEKLY = "Quincenal",
  NONE = "Ninguno",
}

export enum YesNoEnum {
  YES = "Sí",
  NO = "No",
}

export enum DispersionFields {
  DISPERSION_FREQUENCY = "frequency",
  KEEP_COMMISSION = "keepCommission",
  KEEP_FRAUD = "keepFraud",
  FRAUD_PERCENTAGE = "fraudPercentage",
  DAYS_RETENTION = "retentionPeriod",
  GMF_4_X_1000 = "enable4x1000",
}

export enum BankAccountFields {
  COUNTRY = "country",
  BENEFICIARY_NAME = "nameBeneficiary",
  ROUTING_NUMBER = "routingNumber",
  BANK_ID = "bankId",
  ACCOUNT_TYPE = "accountType",
  ACCOUNT_NUMBER = "accountNumber",
  KEY_ACCOUNT_NUMBER = "interbankAccountCode",
  CURRENCY = "currency",
  MAIN_ACCOUNT = "mainAccount",
  ABA_CODE = "codeABA",
  SWIFT_CODE = "codeSwift",
  FINANCIAL_INSTITUTION_ADDRESS = "address",
  BANK_NAME = "bankName",
  IBAN_CODE = "codeIban",
  BANK_CITY_OF_ORIGIN = "bankCitiOfOrigin",
  BANK_POSTAL_CODE_ORIGIN = "bankPostalCodeOrigin",
  TYPE_ACCOUNT = "typeAccount",
}

export enum BankAccountPlaceholders {
  BENEFICIARY_NAME = "Nombre del beneficiario",
  ROUTING_NUMBER = "Número de enrutamiento",
  ABA_CODE = "Código ABA",
  IBAN_CODE = "Código IBAN",
  SWIFT_CODE = "Código SWIFT",
  FINANCIAL_INSTITUTION_ADDRESS = "Dirección de la institución financiera",
  BANK_NAME = "Nombre de la institución financiera",
  BANK_CITY_OF_ORIGIN = "Ciudad de origen de la cuenta bancaria",
  BANK_POSTAL_CODE_ORIGIN = "Código postal de origen de la cuenta bancaria",
}

export enum ConfigIdDispersion {
  CN001 = "cn001",
  CN004 = "cn004",
}

export enum ConfigIdCharge {
  CN017 = "cn017",
}

export enum ClientType {
  B2C = "B2C",
}
