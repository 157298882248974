import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const footerButtonsWrapperStyles = createNamedStyles({
  footerContainer: {
    paddingTop: "24px",
    ".footer-item": {
      display: "flex",
      ".footer-box": {
        marginLeft: "auto",
      },
    },
  },
});
