export enum InputTypeEnum {
  TEXT = "text",
  RADIO = "radio",
  AUTOCOMPLETE = "autocomplete",
  EMAIL = "email",
  CHECKBOX = "checkbox",
}

export enum UserTypeFormEnum {
  CREATE = "create",
  EDIT = "edit",
}

export enum StatusTypeEnum {
  OK = "OK",
  FAIL = "FAIL",
}
