import React from "react";
import { CountryEnum } from "../../shared/countryEnum";
import { get } from "lodash";
import { FlagsNames } from "../../shared/enum/CountryEnumArray";

export interface FlagProps {
  country: CountryEnum;
}

const defautlFlag = <div data-testid={"default-flag"}></div>;

export const Flag: React.FC<FlagProps> = (props: FlagProps) => {
  return get(FlagsNames[props.country], "flag", defautlFlag);
};
