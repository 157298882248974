import React from "react";
import { TextField } from "@mui/material";
import { Datepicker, TextfieldPhone } from "@kushki/connect-ui";
import SelectItemComplete from "../SelectItemComplete/SelectItemComplete";
import { COUNTRIES } from "../../shared/constants/Countries";
import RadioLegalDetails from "../LegalDetailsBody/RadioButtonLegalDetails/RadioLegalDetails";
import {
  documentTypeBM,
  documentTypeSh,
} from "../../shared/constants/initial_state_legal_details";
import SelectInput from "../LegalDetailsBody/SelectInput/SelectInput";
import CheckBoxInput from "../LegalDetailsBody/CheckBox/CheckBox";
import { InputTypeEnum } from "../../shared/enum/inputTypeEnum";
import { textInputStyles as styles } from "./InputsForm.styles";
import { InputsPropsComponent } from "./FormControl.interfaces";
import { DateFormatEnum } from "../../shared/enum/DateFormatEnum";
import { getPersonByCountry } from "../../shared/services/CatalogPersonTypeByCountry";
import { defaultTo, get } from "lodash";
import { CountryEnum } from "../../shared/countryEnum";
import {
  getCountryCodeMessage,
  getCountryPhoneCode,
} from "../../shared/services/CatalogCountryPhoneCode";
import { PubliclyExposedEnum } from "../../shared/enums/RadioButtonEnum";

const InputsForm: React.FC<InputsPropsComponent> = ({
  inputComponent,
  inputProps,
  onChange,
}: InputsPropsComponent) => {
  const { field, label, error, isDisable, country, constitutionalCountry } =
    inputProps;

  switch (inputComponent) {
    case InputTypeEnum.TEXT:
      return (
        <TextField
          sx={styles.error1}
          value={field.value}
          label={label}
          variant="outlined"
          disabled={isDisable}
          fullWidth
          helperText={error ? error.message : null}
          error={!!error}
          onChange={field.onChange}
        />
      );
    case InputTypeEnum.PHONE:
      return (
        <TextfieldPhone
          selectedCountry={getCountryPhoneCode(
            get(inputProps, "country", "") as CountryEnum
          )}
          isDisabled={isDisable}
          placeholder={label}
          defaultValue={field.value}
          onChange={field.onChange}
          helperText={getCountryCodeMessage(
            defaultTo(error?.message, ""),
            defaultTo(field.value, ""),
            country as CountryEnum,
            constitutionalCountry as CountryEnum
          )}
          error={!!error}
          removeLada={true}
        />
      );
    case InputTypeEnum.DATE:
      return (
        <Datepicker
          disableFuture={true}
          placeholder={label}
          isOpen={false}
          dateFormat={DateFormatEnum.DD_MMM_YYYY}
          onDateChange={(formattedDate) => {
            field.onChange(formattedDate);
          }}
          defaultValue={field.value}
          error={!!error}
          helperText={error?.message}
          onlySelect={true}
        />
      );
    case InputTypeEnum.COUNTRIES:
      return (
        <SelectItemComplete
          items={COUNTRIES}
          disabled={isDisable}
          label={label}
          error={!!error}
          value={field.value}
          onChange={field.onChange}
          helperText={error ? error.message : null}
        />
      );
    case InputTypeEnum.RADIO:
      return (
        <RadioLegalDetails
          options={getPersonByCountry(
            get(inputProps, "country", "") as CountryEnum
          )}
          onChange={field.onChange}
          disable={isDisable}
          value={field.value}
          error={error}
        />
      );

    case InputTypeEnum.RADIO_PUBLICLY_EXPOSED:
      return (
        <RadioLegalDetails
          options={[
            {
              name: PubliclyExposedEnum.YES,
              value: PubliclyExposedEnum.YES,
            },
            {
              name: PubliclyExposedEnum.NO,
              value: PubliclyExposedEnum.NO,
            },
          ]}
          onChange={field.onChange}
          disable={isDisable}
          value={field.value}
          error={error}
        />
      );
    case InputTypeEnum.RADIO_SH:
      return (
        <RadioLegalDetails
          options={inputProps.options}
          onChange={onChange || field.onChange}
          disable={isDisable}
          value={field.value}
          error={error}
        />
      );
    case InputTypeEnum.SELECT:
      return (
        <SelectInput
          options={inputProps.options!!}
          onChange={field.onChange}
          disable={isDisable}
          label={label}
          value={field.value}
          error={error}
          helperText={error ? error.message : null}
        />
      );
    case InputTypeEnum.SELECT_SH:
      return (
        <SelectInput
          options={documentTypeSh}
          onChange={field.onChange}
          disable={isDisable}
          label={label}
          value={field.value}
          error={error}
          helperText={error ? error.message : null}
        />
      );
    case InputTypeEnum.SELECT_BM:
      return (
        <SelectInput
          options={documentTypeBM}
          onChange={field.onChange}
          disable={isDisable}
          label={label}
          value={field.value}
          error={error}
          helperText={error && error.message}
        />
      );
    case InputTypeEnum.EMAIL:
      return (
        <TextField
          value={field.value}
          label={label}
          variant="outlined"
          type={"email"}
          disabled={isDisable}
          fullWidth
          helperText={error ? error.message : null}
          error={!!error}
          onChange={field.onChange}
        />
      );
    case InputTypeEnum.CHECKBOX:
      return (
        <CheckBoxInput
          label={label}
          value={field.value}
          onChange={field.onChange}
        />
      );
    default:
      return <></>;
  }
};

export default InputsForm;
