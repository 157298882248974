import React from "react";
import errorImage from "../../../assets/image/error.svg";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

export interface IErrorPageProps {
  handleButtonAction: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    color: theme.palette.primary.main,
    fontWeight: "lighter",
    letterSpacing: "1px",
  },
  button: {
    width: "200px",
  },
  imageContainer: {
    marginBottom: "25px",
  },
}));

export const ErrorPage: React.FC<IErrorPageProps> = (
  props: IErrorPageProps
) => {
  const classes = useStyles();
  const { handleButtonAction } = props;

  return (
    <Card>
      <CardContent>
        <Box p={8}>
          <Grid container justify={"center"} xs={12}>
            <Grid
              item
              container
              xs={12}
              justifyContent={"center"}
              className={classes.imageContainer}
            >
              <Grid item>
                <img src={errorImage} />
              </Grid>
            </Grid>
            <Grid
              container
              item
              direction={"column"}
              alignItems={"center"}
              spacing={3}
            >
              <Grid item>
                <Typography variant={"h5"} className={classes.label}>
                  Se ha producido un error al cargar las configuraciones
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  size="large"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleButtonAction}
                  className={classes.button}
                >
                  Regresar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};
