import { IStyles } from "../../shared/interfaces/Styles.interfaces";

const ModalDetailInfoStyles: IStyles = {
  modalContainer: {
    alignItems: "flex-start",
    backgroundColor: "background.paper",
    flexDirection: "column",
    display: "flex",
    margin: "0px 0px",
    height: "100%",
    width: "100%",
    padding: "40px 48px",
    top: "0%",
    position: "absolute",
  },
  modalInformation: {
    flexDirection: "column",
    display: "flex",
    padding: "8px",
    width: "100%",
    height: "100%",
  },
  iconButton: {
    paddingBottom: "24px",
  },
  detailModal: {
    padding: "24px 8px",
    overflowY: "scroll",
    flexGrow: "1",
    width: "100%",
    height: "100%",
  },
  boxTabs: {
    borderRadius: "4px",
    marginTop: "20px",
    marginBottom: "16px",
    paddingBottom: 0,
  },
  boxTabsNoMargin: {
    borderRadius: "4px",
    marginTop: 0,
    marginBottom: "16px",
    paddingBottom: 0,
  },
  icon: {
    transform: "scaleX(-1)",
  },
};

export { ModalDetailInfoStyles };
