import {
  MerchantNodeData,
  SearchMerchantResponse,
} from "../../../../types/search_merchant_response";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { searchMerchants } from "../../thunks/merchantNode/merchantNode.thunks";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";

export interface ListButtons {
  label: string;
  description: string;
  value: string;
  selected: boolean;
}

export interface CustomerData {
  customerList: SearchMerchantResponse;
  listButton: ListButtons[];
  selectItems?: ICategory[];
}

export const initialState: CustomerData = {
  customerList: {
    data: [],
    total: 0,
  },
  listButton: [],
};

const transformData = (data: SearchMerchantResponse): ListButtons[] => {
  const newArray: ListButtons[] = data!.data!.map(
    (merchantNodeData: MerchantNodeData) => {
      return {
        description: merchantNodeData.publicMerchantId!,
        label: merchantNodeData.name!,
        selected: false,
        value: merchantNodeData.nodeId!,
      };
    }
  );

  return newArray;
};

export const merchantNodeSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(searchMerchants.fulfilled, (state, action) => {
      state.customerList = action.payload;
      state.listButton = transformData(action.payload);
    });
  },
  initialState,
  name: "CustomerData",
  reducers: {
    resetCustomerList: (state) => {
      state.customerList = {
        data: [],
        total: 0,
      };
      state.listButton = [];
      state.selectItems = [];
    },
    setSelectItems: (state, { payload }: PayloadAction<ICategory[]>) => {
      state.selectItems = payload;
    },
  },
});

export default merchantNodeSlice.reducer;
