import {
  Button,
  ButtonGroup,
  Grid,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { buildOptionsFormatWithCountry } from "../../../shared/infrastructure/Enums/FormatUpload";
import React from "react";
import { useHistory } from "react-router-dom";
import { ButtonOptionRetentionsProps } from "./ButtonOptionRetentions.interfaces";
import { useButtonOptionRetentionsStyles } from "./ButtonOptionRetentions.styles";
import { IOptionFormat } from "../../../shared/infrastructure/interfaces/IOptionFormat";
import { get } from "lodash";
import { TabEnum } from "../../../shared/infrastructure/Enums/TabEnum";
import { useDispatch } from "react-redux";
import { setIsOpenCatalogFormPopup } from "../../../store/actionCreators";

export const ButtonOptionRetentions: React.FC<ButtonOptionRetentionsProps> = (
  props: ButtonOptionRetentionsProps
) => {
  const dispatch = useDispatch();
  const classes = useButtonOptionRetentionsStyles();
  const history = useHistory();
  const { country, isCatalogTab } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const isOpen: boolean = Boolean(anchorEl);
  const id: string | undefined = isOpen ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const triggerEvent = (option: IOptionFormat): void => {
    switch (option.event) {
      case TabEnum.CATALOG:
        dispatch(setIsOpenCatalogFormPopup(true));
        break;
      default:
        history.push(option.url);
    }
    handleClose();
  };

  const renderMenuItem = (option: IOptionFormat) => (
    <MenuItem
      className={classes.menuItem}
      key={option.value}
      disabled={get(option, "disabled", false)}
      onClick={() => triggerEvent(option)}
    >
      {option.name}
    </MenuItem>
  );

  return (
    <React.Fragment>
      <Grid item className={classes.btnContain}>
        <ButtonGroup
          variant="contained"
          color="primary"
          size={"small"}
          onClick={handleClick}
          className={classes.btnPopover}
        >
          <Button fullWidth className={classes.btnChild}>
            <Typography variant="h6">
              {isCatalogTab
                ? "Cargar catálogos"
                : "Cargar impuestos y retenciones"}
            </Typography>
          </Button>
          <Button className={classes.btnArrow}>
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popover
          id={id}
          open={isOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuList id="split-button-menu">
            {buildOptionsFormatWithCountry(country, isCatalogTab).map(
              renderMenuItem
            )}
          </MenuList>
        </Popover>
      </Grid>
    </React.Fragment>
  );
};
