import { IUseAmountFilter, RangeAmount } from "./useAmountFilter.interfaces";
import * as React from "react";
import { get, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { FilterAmountValueEnum } from "../../../shared/enums/TransactionEnum";
import { useAppSelector } from "../../../store/hooks/storeHook";
import { CURRENCY_BY_COUNTRY } from "../../../shared/enums/CurrencyEnum";
import { IAmountFilter } from "../AmountFilter.interfaces";

export const useAmountFilter = ({
  setRangeAmount,
  setIsApplyFilter,
  isResetFilter,
}: IAmountFilter): IUseAmountFilter => {
  const [valueAmount, setValueAmount] = useState<string>("");
  const [valueAdditionalAmount, setValueAdditionalAmount] =
    useState<string>("");
  const [valueRange, setValueRange] = useState<string>("");
  const [isBetween, setIsBetween] = useState<boolean>(false);
  const [errorRange, setErrorRange] = useState<boolean>(false);
  const [currencyCode, setCurrencyCode] = useState<string>("");
  const regex = new RegExp("^[0-9.]*(\\.\\d+)?$");

  const { nodeInfo } = useAppSelector((state) => ({
    ...state.app,
  }));

  useEffect(() => {
    const country: string = get(nodeInfo, "generalInfo.country", "");

    if (!isEmpty(country)) {
      setCurrencyCode(CURRENCY_BY_COUNTRY[country]);
    }
  }, [nodeInfo]);

  const onChangeRange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValueRange(get(e, "target.value", ""));
  };

  const onChangeAmount = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (regex.test(e.target.value)) {
      setValueAmount(() => get(e, "target.value", ""));
    }
  };
  const onChangeAdditionalAmount = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (regex.test(e.target.value)) {
      setValueAdditionalAmount(() => get(e, "target.value", ""));
    }
  };

  useEffect(() => {
    setIsBetween(valueRange === FilterAmountValueEnum.BET);
  }, [valueRange]);

  const buildRangeAmount = (): RangeAmount | undefined => {
    switch (valueRange) {
      case FilterAmountValueEnum.MAX:
        return { max: Number(valueAmount) };
      case FilterAmountValueEnum.MIN:
        return { min: Number(valueAmount) };
      case FilterAmountValueEnum.EQ:
        return { eq: Number(valueAmount) };
      case FilterAmountValueEnum.BET:
        return {
          max: Number(valueAdditionalAmount),
          min: Number(valueAmount),
        };
      default:
        return undefined;
    }
  };

  useEffect(() => {
    isBetween &&
      setErrorRange(Number(valueAmount) > Number(valueAdditionalAmount));
  }, [valueAmount, valueAdditionalAmount]);

  useEffect(() => {
    setRangeAmount(buildRangeAmount()!);
    setIsApplyFilter(false);
  }, [valueRange, valueAmount, valueAdditionalAmount]);

  useEffect(() => {
    if (isResetFilter) {
      setValueRange("");
      setValueAmount("");
      setValueAdditionalAmount("");
    }
  }, [isResetFilter]);

  return {
    currencyCode,
    errorRange,
    isBetween,
    onChangeAdditionalAmount,
    onChangeAmount,
    onChangeRange,
    valueAdditionalAmount,
    valueAmount,
    valueRange,
  };
};
