import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@material-ui/core";
import CashPaymentMethod from "../../../assets/images/payment_methods_cash.svg";
import TransferPaymentMethod from "../../../assets/images/payment_methods_transfer.svg";
import { CustomMenuItem } from "../CustomMenuItem/CustomMenuItem";
import {
  ALLOWED_INTERNATIONAL_TRANSFER_COUNTRY,
  PayInOutPopupConstants,
} from "../../../shared/infrastructure/constants/MerchantConfigConstants";
import { routes } from "../../../shared/infrastructure/constants/routes";
import { get } from "lodash";
import { LocalStorageEnum } from "../../../shared/infrastructure/constants/LocalStorageEnum";

export interface IAccordionPopupProcessorMenuPayOut {
  id: string | null;
  country?: string | undefined;
}

export const AccordionPopupProcessorMenuPayOut: React.FC<IAccordionPopupProcessorMenuPayOut> = (
  props: React.PropsWithChildren<IAccordionPopupProcessorMenuPayOut>
) => {
  const isAllowedRenderInternationalTransferOption = (): boolean => {
    return ALLOWED_INTERNATIONAL_TRANSFER_COUNTRY.includes(
      get(props, "country", "")
    );
  };

  const setInternationalRedirectUrl = () => {
    localStorage.setItem(
      LocalStorageEnum.INTERNATIONAL_REDIRECT_URL,
      `${routes.CONFIG_MERCHANT}${props.id}`
    );
  };

  return (
    <Accordion defaultExpanded={false} elevation={0}>
      <AccordionSummary>
        <Typography>{PayInOutPopupConstants.PAY_OUT}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction={"column"}>
          <Grid item>
            <CustomMenuItem
              keyName={PayInOutPopupConstants.TRANSFER_PAY_OUT}
              navigateTo={`${routes.PROCESSOR_PAYOUT_TRANSFER}/${props.id}`}
              menuTitle={PayInOutPopupConstants.TRANSFER_TITLE}
              menuIcon={TransferPaymentMethod}
              country={props.country}
            />
          </Grid>
          <Grid item>
            <CustomMenuItem
              keyName={PayInOutPopupConstants.CASH_PAY_OUT}
              navigateTo={`${routes.PROCESSOR_PAYOUT_CASH}/${props.id}`}
              menuTitle={PayInOutPopupConstants.CASH_TITLE}
              menuIcon={CashPaymentMethod}
              country={props.country}
            />
          </Grid>
          {isAllowedRenderInternationalTransferOption() && (
            <Grid
              item
              onClick={() => {
                setInternationalRedirectUrl();
              }}
            >
              <CustomMenuItem
                keyName={PayInOutPopupConstants.INTERNATIONAL_TRANSFER_PAY_OUT}
                navigateTo={`${routes.PROCESSOR_INTERNATIONAL_TRANSFER}/${props.id}`}
                menuTitle={PayInOutPopupConstants.INTERNATIONAL_TRANSFER}
                menuIcon={TransferPaymentMethod}
                country={props.country}
              />
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
