import React, { FC } from "react";
import { TextField } from "@mui/material";
import { get } from "lodash";
import { Controller } from "react-hook-form";
import { IFormFieldProps } from "../FormFieldProps.interfaces";

const SelectInputField: FC<IFormFieldProps> = ({
  control,
  errors,
  name,
  itemsSelect = [],
  defaultValue,
  label,
  disabled,
  rules = {},
  triggerOnBlur,
}: IFormFieldProps) => {
  return (
    <Controller
      name={name!}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField
          {...field}
          onBlur={() => {
            field.onBlur();
            triggerOnBlur && triggerOnBlur();
          }}
          helperText={get(errors, name!)?.message ?? ""}
          select
          id={`${name}-select-input-field`}
          variant="outlined"
          disabled={disabled}
          fullWidth
          label={label}
          error={!!get(errors, name!)}
        >
          {itemsSelect}
        </TextField>
      )}
    />
  );
};

export default SelectInputField;
