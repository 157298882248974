import React from "react";
import { TableCell } from "@material-ui/core";

export interface TableRow {
  data: object;
}

export const TableRowContainer = (props: TableRow) => {
  return (
    <React.Fragment>
      {Object.values(props.data).map((value: string, index: number) => {
        return (
          <TableCell id={"alias"} key={index}>
            {value}
          </TableCell>
        );
      })}
    </React.Fragment>
  );
};
