import React from "react";
import { formatDDMMYYYY } from "../utils/date_utils";
import { CELL_TYPES } from "./enums/CellTypesEnum";
import { CustomHeaderCellProps } from "./types/CustomHeaderCellProps.type";
import { WebhookService, WebhookStatus } from "./enums/WebhookServiceEnum";
import {
  TagsCell,
  TagsCellSkeleton,
} from "../../components/common/TagsCell/TagsCell";
import { ITableInfoCellValue } from "../interfaces/ITableInfoCellValue.interfaces";
import { ICustomerWebhooksTable } from "../../containers/WebhooksContainer/state/useWebhooksContainer.interfaces";
import { OptionsEnum } from "../enums/OptionsEnum";

export enum WebhooksProperty {
  CREATED = "created",
  ALIAS = "alias",
  EVENTS = "events",
  STATUS = "status",
  OPTIONS = "options",
  SIMULATE = "simulate",
  EDIT = "edit",
  DELETE = "delete",
}

export const ALL_TABLE_COLUMNS_WEBHOOK: CustomHeaderCellProps[] = [
  {
    align: "left",
    cellType: CELL_TYPES.TITLE,
    key: WebhooksProperty.CREATED,
    spacing: 0,
    text: "Creado",
  },
  {
    align: "left",
    cellType: CELL_TYPES.TEXT,
    key: WebhooksProperty.ALIAS,
    spacing: 0,
    text: "Alias",
  },
  {
    align: "left",
    cellType: CELL_TYPES.BASE,
    key: WebhooksProperty.EVENTS,
    spacing: 0,
    text: "Medio de pago o producto",
  },
  {
    align: "left",
    cellType: CELL_TYPES.TAG,
    key: WebhooksProperty.STATUS,
    spacing: 0,
    text: "Estado de conexión",
  },
  {
    align: "left",
    cellType: CELL_TYPES.OPTIONS,
    key: WebhooksProperty.OPTIONS,
    spacing: 0,
    text: "",
  },
];

export const ALL_TABLE_COLUMNS_WEBHOOK_CENTRALIZED: CustomHeaderCellProps[] = [
  {
    align: "left",
    cellType: CELL_TYPES.TITLE,
    key: WebhooksProperty.CREATED,
    spacing: 0,
    text: "Creado",
  },
  {
    align: "left",
    cellType: CELL_TYPES.TEXT,
    key: WebhooksProperty.ALIAS,
    spacing: 0,
    text: "Alias",
  },
  {
    align: "left",
    cellType: CELL_TYPES.BASE,
    key: WebhooksProperty.EVENTS,
    spacing: 0,
    text: "Medio de pago o producto",
  },
  {
    align: "left",
    cellType: CELL_TYPES.TAG,
    key: WebhooksProperty.STATUS,
    spacing: 0,
    text: "Estado de conexión",
  },
];

export const WEBHOOK_STATUS_TEXT: { [K in WebhookStatus]: string } = {
  [WebhookStatus.FAILED]: "Fallido",
  [WebhookStatus.OK]: "Exitoso",
  [WebhookStatus.EMPTY]: "No validado",
};

export const WEBHOOK_SERVICE_TEXT: { [K in WebhookService]: string } = {
  [WebhookService.CARD]: OptionsEnum.CARD,
  [WebhookService.CASH]: OptionsEnum.CASH,
  [WebhookService.PAYOUTS_CASH]: OptionsEnum.PAYOUTS_CASH,
  [WebhookService.TRANSFER_SUBSCRIPTIONS]: OptionsEnum.TRANSFER_SUBSCRIPTIONS,
  [WebhookService.TRANSFER]: OptionsEnum.TRANSFER,
  [WebhookService.PAYOUTS_TRANSFER]: OptionsEnum.PAYOUTS_TRANSFER,
  [WebhookService.SUBSCRIPTIONS]: OptionsEnum.SUBSCRIPTIONS,
  [WebhookService.WEBCHECKOUT]: OptionsEnum.WEBCHECKOUT,
  [WebhookService.VPOS]: OptionsEnum.VPOS,
  [WebhookService.CARD_CHARGEBACK]: OptionsEnum.CARD_CHARGEBACK_VOID,
  [WebhookService.CARD_CHARGEBACK_VOID]: OptionsEnum.CARD_CHARGEBACK,
};

export const onRenderValue = (
  column: string,
  t: ICustomerWebhooksTable
): ITableInfoCellValue => {
  const value = t[column];

  const renderValues: {
    [K: string]: () => ITableInfoCellValue;
  } = {
    default: () => ({ line1: t[column] }),
    [WebhooksProperty.ALIAS]: () => {
      return {
        line1: value || "No asignado",
      };
    },
    [WebhooksProperty.CREATED]: () => {
      return {
        line1: value ? formatDDMMYYYY(value) : value,
      };
    },
    [WebhooksProperty.EVENTS]: () => {
      const items: { service: string }[] = value as { service: string }[];

      const services: string[] = items.reduce<string[]>((result, item) => {
        const service = WEBHOOK_SERVICE_TEXT[item.service];

        if (service && !result.includes(service)) {
          result.push(service);
        }

        return result;
      }, []);

      return {
        base: {
          child: <TagsCell tags={services} />,
          childSkeleton: <TagsCellSkeleton />,
        },
      };
    },
    [WebhooksProperty.STATUS]: () => {
      const items: { status: string }[] = value
        ? (value as { status: string }[])
        : [];

      const isFailed: boolean = items.some(
        (item: { status: string }): boolean =>
          item.status === WebhookStatus.FAILED
      );

      let status: WebhookStatus;
      let color: "success" | "warning" | "error" = "warning";

      if (items.length === 0) {
        status = WebhookStatus.EMPTY;
      } else if (isFailed) {
        status = WebhookStatus.FAILED;
        color = "error";
      } else {
        status = WebhookStatus.OK;
        color = "success";
      }

      return {
        tag: {
          color: color,
          text: WEBHOOK_STATUS_TEXT[status],
        },
      };
    },
    [WebhooksProperty.OPTIONS]: () => {
      return {
        options: value,
      };
    },
    [WebhooksProperty.SIMULATE]: () => {
      return {
        action: value,
      };
    },
    [WebhooksProperty.EDIT]: () => {
      return {
        action: value,
      };
    },
    [WebhooksProperty.DELETE]: () => {
      return {
        action: value,
      };
    },
  };

  const renderValue = renderValues[column] || renderValues.default;

  return renderValue();
};
