import {
  hideLoading,
  setNotification,
  setSuccessUpdate,
} from "../../actions/app/app";
import { UpdateConciliationTransactionsResponse } from "../../../../types/update_conciliation_transactions_response";
import { WEBSOCKETS_ENDPOINTS } from "../../../shared/constants/websockets_endpoints";
import { AnyAction, createAsyncThunk, ThunkDispatch } from "@reduxjs/toolkit";
import { updateTransactionStateWS } from "../../../shared/utils/websockets/updateTransactionState/update_transaction_state_ws";
import { IHandleResponseWS } from "../../../shared/utils/websockets/updateTransactionState/update_transaction_state_ws.interfaces";
import { UpdateTransactionUpdateRequestWS } from "./updateTransactionState.interfaces";
import { MessageAlertEnum } from "../../../shared/infrastructure/enums/MessageAlertEnum";
import { defaultTo, get } from "lodash";
import { WebsocketStatusEnum } from "../../../shared/infrastructure/enums/WebsocketStatusEnum";

const getMessage = (conciliationType: string, isSuccess: boolean) => {
  const message = {
    omitted: isSuccess
      ? MessageAlertEnum.OMITTED_SUCCESS
      : MessageAlertEnum.OMITTED_ERROR,
    pending_refund: isSuccess
      ? MessageAlertEnum.REFUND_SUCCESS
      : MessageAlertEnum.REFUND_ERROR,
    review_kushki: isSuccess
      ? MessageAlertEnum.CHECK_SUCCESS
      : MessageAlertEnum.CHECK_ERROR,
    review_processor: isSuccess
      ? MessageAlertEnum.CHECK_SUCCESS
      : MessageAlertEnum.CHECK_ERROR,
  };

  return get(message, conciliationType);
};

export const handleResponse = (
  conciliationType: string,
  response: MessageEvent<string>,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>
): IHandleResponseWS => {
  const { status, error, message }: UpdateConciliationTransactionsResponse =
    JSON.parse(response.data);

  const messageError = error ? message : undefined;

  if (status === "ERROR" || error) {
    dispatch(hideLoading());
    dispatch(
      setNotification({
        message: getMessage(conciliationType, false),
        open: true,
        type: "error",
      })
    );

    return {
      closeConnection: true,
      messageError,
    };
  }

  if (status === WebsocketStatusEnum.COMPLETE) {
    dispatch(setSuccessUpdate(true));
    dispatch(hideLoading());
    dispatch(
      setNotification({
        message: getMessage(conciliationType, true),
        open: true,
        type: "success",
      })
    );
    dispatch(setSuccessUpdate(false));

    return {
      closeConnection: true,
    };
  }

  return {
    closeConnection: false,
  };
};

const updateTransactionState = createAsyncThunk(
  "transactions/updateTransaction",
  async (data: UpdateTransactionUpdateRequestWS, thunkAPI) => {
    const payload = JSON.parse(
      defaultTo(localStorage.getItem("payload"), "{}")
    );

    const request: UpdateTransactionUpdateRequestWS = {
      ...data,
      data: {
        ...data.data,
        observationInfo: {
          ...get(data, "data.observationInfo", {}),
          userName: get(payload, "cognito:username", ""),
        },
      },
    };

    return await updateTransactionStateWS(
      data.data.conciliationAction,
      WEBSOCKETS_ENDPOINTS.updateTransactionsState,
      {
        data: request,
        dispatch: thunkAPI.dispatch,
        handleResponse,
      }
    );
  }
);

export default updateTransactionState;
