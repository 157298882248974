import { environment } from "../../environments/environment";

export interface MerchantSettingsRequest {
  publicMerchantId: string;
  publicCredentialId?: string;
}

export const API_ROUTES = {
  CREATE_WEBHOOK: `${environment.kushkiUrl}/webhook/v1/webhooks`,
  GET_ADMIN_MERCHANT: (publicMerchantId: string) =>
    `${environment.kushkiUrl}/vpos/v1/admin/merchant/${publicMerchantId}`,
  GET_NODE_INFO: `${environment.kushkiUrl}/billing-core-node/v1/merchant-node/info`,
  GET_PROCESSORS: (publicMerchantId: string) =>
    `${environment.kushkiUrl}/merchant/v1/admin/merchant/${publicMerchantId}/processors`,
  GET_SMARTLINKS: (publicMerchantId: string) =>
    `${environment.kushkiUrl}/smartlink/v1/admin/merchant/${publicMerchantId}`,
  GET_WEBHOOK: (webhookId: string) =>
    `${environment.kushkiUrl}/webhook/v1/webhooks/${webhookId}`,
  HIERARCHY_NODE_INFO: `${environment.kushkiUrl}/hierarchy/v1/merchant/v1/node/config`,
  LIST_WEBHOOK: "webhook/v1/search",
  NODE_INFO: `${environment.kushkiUrl}/billing-core-node/v1/node/info`,
  PATCH_WEBHOOK: (webhookId: string) =>
    `${environment.kushkiUrl}/webhook/v1/webhooks/${webhookId}`,
  SEARCH_MERCHANT_NODE: "/billing-core-node/v1/merchant-node/search",
  TEST_CONNECTION: "/webhook/v1/testConnection",
  webhook: `${environment.kushkiUrl}/webhook/v1/testConnection`,
  WEBHOOK_FIRST_PARENT: `${environment.kushkiUrl}/hierarchy/v1/node/first-parent`,
  WEBHOOK_SIGNATURE: (publicMerchantId: string) =>
    `${environment.kushkiUrl}/webhook/v1/admin/signature/${publicMerchantId}`,
  WEBHOOKS_LIST_NOTIFICATIONS: "/webhook/v1/webhooks/listNotifications",
  WEBHOOKS_NOTIFICATION: "/webhook/v1/webhooks/notification",
};
