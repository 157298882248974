import React from "react";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Trash2, X } from "react-feather";
import { StyledToggleButtonGroup } from "../commons/StyledToggleButtonGroup/StyledToggleButtonGroup";
import { Autocomplete, ToggleButton } from "@material-ui/lab";
import SimpleChip from "../commons/SimpleChip/SimpleChip";
import { StyledToggleButtonGroupGrace } from "../commons/StyledToggleButtonGroup/StyledToggleButtonGroupGrace";
import { get, set } from "lodash";
import { useVariantSection } from "./useVariantSection/useVariantSection";
import { IBank } from "../EcuadorSection/useEcuadorSection/useEcuadorSection";

const useStyles = makeStyles(() => ({
  bankListMobile: {
    background: "#F7FAFC",
    color: "#293036",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "100%",
    margin: "6px",
    overflow: "hidden",
    padding: "6px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap" as "nowrap",
  },
  cardH2: {
    color: "#293036",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "19.6px",
  },
  divider: {
    marginTop: "30px",
  },
  label: {
    color: "#293036",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "18px",
    paddingBottom: "8px",
  },
  labelH2: {
    color: "#293036",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "16px",
    paddingTop: "20px",
  },
  labelSelect: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 2,
    paddingBottom: "0px",
    paddingTop: "0px",
    width: "270px",
    wordWrap: "break-word",
  },
  menuItem: {
    paddingBottom: "0px",
    paddingTop: "0px",
  },
  padding: {
    paddingBottom: "15px",
  },
  select: {
    marginLeft: "2px",
    maxWidth: "328px",
  },
  tittle: {
    color: "#6D7781",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    paddingBottom: "8px",
  },
  toggleButtonGroup: {
    flexWrap: "wrap",
  },
  trashIcon: {
    color: "#000000",
  },
}));

export interface VariantProps {
  banks: IBank[];
  isMobile: boolean;
  selectedBanks: string[];
  index: number;
  months: string[];
  monthsOfGrace: string[];
  merchantMonths: string[];
  merchantMonthsOfGrace: string[];
  handleDeleteVariant(id: number): void;
  handleSetMonth(
    event: React.MouseEvent<HTMLElement>,
    value: string[],
    index: number
  ): void;
  handleSetMonthOfGrace(
    event: React.MouseEvent<HTMLElement>,
    value: string[],
    index: number
  ): void;
  handleSetBank(event: any, index: number): void;
  handleDeleteBank(name: string, index: number): void;
}

export const VariantSection = (props: VariantProps) => {
  const classes = useStyles();
  const { searchText, handleFilterBanks, handleSearch } = useVariantSection(
    props.index
  );

  return (
    <>
      {props.index === 0 && <Divider />}
      <Box>
        <Toolbar disableGutters>
          <Box display="flex" flexGrow={1}>
            <Box mt={"26px"}>
              <Typography className={classes.tittle}>
                Personalizar variante
              </Typography>
              <Typography className={classes.label}>
                Selecciona uno o más bancos a los que deseas realizar la
                variante:
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            <IconButton
              aria-label="Delete"
              onClick={() => props.handleDeleteVariant(props.index)}
            >
              <Trash2 size={24} className={classes.trashIcon} />
            </IconButton>
          </Box>
        </Toolbar>
      </Box>
      <Box mt={"8px"}>
        <Grid container>
          <Grid item xs={12} md={4}>
            <Autocomplete
              id="selected_banks"
              options={handleFilterBanks(props.banks)}
              disableCloseOnSelect
              getOptionLabel={(option) => option.label}
              ListboxProps={{ style: { maxHeight: "350px" } }}
              renderOption={(propsAuto) => (
                <MenuItem
                  key={get(propsAuto, "label")}
                  value={get(propsAuto, "label")}
                  className={classes.menuItem}
                  {...propsAuto}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={propsAuto.label}
                        color="primary"
                        checked={get(propsAuto, "checked")}
                        name={propsAuto.label}
                        size="small"
                        onChange={() =>
                          props.handleSetBank(
                            get(propsAuto, "label"),
                            props.index
                          )
                        }
                      />
                    }
                    label={
                      <Typography
                        noWrap
                        color="textPrimary"
                        className={classes.labelSelect}
                      >
                        {propsAuto.label}
                      </Typography>
                    }
                    value={propsAuto.label}
                  />
                </MenuItem>
              )}
              style={{ width: 450 }}
              renderInput={(params) => {
                const value = get(params, "inputProps.value");

                value === searchText
                  ? set(params, "inputProps.value", value)
                  : set(params, "inputProps.value", searchText);

                set(params, "InputProps.endAdornment.props.children[0]", null);

                return (
                  <TextField
                    {...params}
                    label="Red de bancos"
                    placeholder="Red de bancos..."
                    variant="outlined"
                    className={classes.select}
                    onChange={handleSearch}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </Box>
      {!props.isMobile ? (
        <Box mt={"24px"}>
          {props.selectedBanks.map((selectedBank, index) => (
            <SimpleChip
              key={index}
              label={selectedBank}
              onDelete={() => props.handleDeleteBank(selectedBank, props.index)}
              deleteIcon={<X color={"#293036"} />}
            />
          ))}
        </Box>
      ) : (
        <Grid container>
          <Grid item xs={12} className={classes.labelH2}>
            {props.selectedBanks.map((selectedBank, index) => (
              <Typography
                key={index}
                onClick={() =>
                  props.handleDeleteBank(selectedBank, props.index)
                }
                className={classes.bankListMobile}
              >
                {selectedBank}
              </Typography>
            ))}
          </Grid>
        </Grid>
      )}

      <Box mt={"24px"}>
        <Typography className={classes.label}>
          Selecciona las cuotas o meses que deseas modificar:
        </Typography>
      </Box>
      <Box>
        <Grid container spacing={1} className={classes.padding}>
          <Grid
            item
            xs={12}
            md={6}
            container
            alignItems="center"
            justify="flex-start"
            direction="row"
          >
            <Box pl={"47px"} pt={"26px"}>
              <Typography>
                <Typography className={classes.cardH2}>Cuotas</Typography>
              </Typography>
              <Box pt={"10px"} display={"flex"} flexDirection={"row"}>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  width={"100%"}
                  justifyContent={"flex-start"}
                  flexWrap={"wrap"}
                >
                  <StyledToggleButtonGroup
                    value={props.merchantMonths}
                    defaultValue={props.merchantMonths}
                    onChange={(e, value) =>
                      props.handleSetMonth(e, value, props.index)
                    }
                    className={classes.toggleButtonGroup}
                  >
                    {props.months.map((month: string) => (
                      <ToggleButton key={month} value={month}>
                        {month}
                      </ToggleButton>
                    ))}
                  </StyledToggleButtonGroup>
                </Box>
              </Box>
            </Box>
          </Grid>
          {props.monthsOfGrace && props.monthsOfGrace.length > 0 && (
            <Grid
              item
              xs={12}
              md={6}
              container
              justify="flex-start"
              direction="row"
            >
              {!props.isMobile && (
                <Divider
                  orientation="vertical"
                  flexItem
                  className={classes.divider}
                />
              )}

              <Box pl={"47px"} mt={"26px"}>
                <Typography>
                  <Typography className={classes.cardH2}>
                    Meses de gracias
                  </Typography>
                </Typography>
                <Box pt={"10px"} display={"flex"} flexDirection={"row"}>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    width={"100%"}
                    justifyContent={"flex-start"}
                    flexWrap={"wrap"}
                  >
                    <StyledToggleButtonGroupGrace
                      value={props.merchantMonthsOfGrace}
                      defaultValue={props.merchantMonthsOfGrace}
                      onChange={(e, value) =>
                        props.handleSetMonthOfGrace(e, value, props.index)
                      }
                      className={classes.toggleButtonGroup}
                    >
                      {props.monthsOfGrace.map((month: string) => (
                        <ToggleButton key={month} value={month}>
                          {month}
                        </ToggleButton>
                      ))}
                    </StyledToggleButtonGroupGrace>
                  </Box>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <Divider />
    </>
  );
};
export default VariantSection;
