import { useEffect, useState } from "react";
import { HISTORICAL_TABLE_HEADERS_COLUMNS } from "../../../shared/constants/historical_table_columns";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import {
  setEmptyTable,
  setHistoricalDateTimeFilter,
  setHistoricalRefunds,
  setLoadingTable,
  setSelectedHistorical,
  toggleSortHistoricalFilter,
} from "../../../store/reducers/refunds/refunds";
import { useDispatch, useSelector } from "react-redux";
import { isEmptyFilters } from "../../../shared/utils/refunds/filters";
import getCurrentDateTime from "../../RefundsTable/utils/getCurrentDateTime";
import { mapDateTimeToRequest } from "../../RefundsTable/utils/buildRequest";
import { RootState } from "../../../store/store";
import { GetRefundTransactionListResponse } from "../../../../types/get-refund-transaction-list-response";
import { IHistoricalRefundsFilters } from "../../../store/interfaces/RefundsState.interfaces";
import { GetRefundTransactionListRequest } from "../../../../types/get-refund-transaction-list-request";
import { RefundStatusEnum } from "../../../shared/enums/RefundStatusEnum";
import { selectIsLoadingTable } from "../../../store/selectors/refunds/refunds.selectors";

export const useHistoricalTable = () => {
  const dispatch = useDispatch();
  const historicalFilters = useSelector(
    (state: RootState) => state.refunds.historicalFilters
  );

  const data = useSelector(
    (state) => state["refunds"]["historicalData"]["historicalRefunds"]
  );
  const total = useSelector(
    (state) => state["refunds"]["historicalData"]["totalHistoricalRefunds"]
  );
  const page = useSelector((state) => state["refunds"]["pagination"]["page"]);
  const rowsPerPage = useSelector(
    (state) => state["refunds"]["pagination"]["rowsPerPage"]
  );

  const isLoadingTable = useSelector(selectIsLoadingTable);

  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);

  useEffect(() => {
    if (isEmptyFilters(historicalFilters)) {
      const dateTimeInfo = getCurrentDateTime();

      dispatch(setHistoricalDateTimeFilter(dateTimeInfo));

      return;
    }

    (async ({ generalFilter, dateTime, sort }: IHistoricalRefundsFilters) => {
      try {
        dispatch(setLoadingTable(true));

        const generalFilters = <RefundStatusEnum[]>(
          generalFilter!.status!.map((name) => name.toUpperCase())
        );

        const filtersBody: GetRefundTransactionListRequest = {
          from: mapDateTimeToRequest(dateTime.from),
          limit: rowsPerPage,
          offset: rowsPerPage * (page - 1),
          sort: sort.order,
          status:
            generalFilters.length === 0
              ? [
                  RefundStatusEnum.APPROVED,
                  RefundStatusEnum.EXPIRED,
                  RefundStatusEnum.MANUAL,
                  RefundStatusEnum.INITIALIZED,
                ]
              : generalFilters,
          timeZone: dateTime.timezone,
          to: mapDateTimeToRequest(dateTime.to),
        };

        const { data, total } = (
          await axios.post<GetRefundTransactionListResponse>(
            API_ROUTES.HISTORICAL_REFUNDS,
            filtersBody
          )
        ).data;

        dispatch(setEmptyTable(data.length === 0));
        dispatch(
          setHistoricalRefunds({
            historicalRefunds: data,
            totalHistoricalRefunds: total.value,
          })
        );
      } catch (error) {
      } finally {
        dispatch(setLoadingTable(false));
      }
    })(historicalFilters);
  }, [rowsPerPage, page, historicalFilters]);

  const onRowClick = (id: string) => {
    dispatch(setSelectedHistorical(id));
    setIsRefundModalOpen(true);
  };

  const onCloseRefundModal = () => {
    // TODO: DESELECT THE CHOSEN TRANSACTION WHEN ROW WAS CLICKED
    setIsRefundModalOpen(false);
  };

  const handleSortTable = () => {
    dispatch(toggleSortHistoricalFilter());
  };

  const getColumns = () => {
    return HISTORICAL_TABLE_HEADERS_COLUMNS.map((column) => {
      if (column.sortable) {
        return {
          ...column,
          onSortClick: handleSortTable,
        };
      }

      return column;
    });
  };

  return {
    data,
    getColumns,
    isLoadingTable,
    isRefundModalOpen,
    onCloseRefundModal,
    onRowClick,
    total,
  };
};
