import { ISelectAutocomplete } from "../Interfaces/SelectAutocomplete.interfaces";

export const ciiuCatalog: ISelectAutocomplete[] = [
  {
    text: "0111 - CULTIVO DE CEREALES (EXCEPTO ARROZ), LEGUMBRES Y SEMILLAS OLEAGINOSAS",
    value: "0111",
  },
  { text: "0112 - CULTIVO DE ARROZ", value: "0112" },
  {
    text: "0113 - CULTIVO DE HORTALIZAS Y MELONES, RAÍCES Y TUBÉRCULOS.",
    value: "0113",
  },
  { text: "0114 - CULTIVO DE CAÑA DE AZÚCAR", value: "0114" },
  { text: "0115 - CULTIVO DE TABACO", value: "0115" },
  { text: "0116 - CULTIVO DE PLANTAS DE FIBRA", value: "0116" },
  { text: "0119 - CULTIVO DE OTRAS PLANTAS NO PERENNES", value: "0119" },
  { text: "0121 - CULTIVO DE UVA", value: "0121" },
  {
    text: "0122 - CULTIVO DE FRUTAS TROPICALES Y SUBTROPICALES",
    value: "0122",
  },
  { text: "0123 - CULTIVO DE  CÍTRICOS", value: "0123" },
  { text: "0124 - CULTIVO DE FRUTAS DE PEPITA Y DE HUESO", value: "0124" },
  {
    text: "0125 - CULTIVO DE OTROS FRUTOS Y NUECES DE ÁRBOLES Y ARBUSTOS ",
    value: "0125",
  },
  { text: "0126 - CULTIVO DE FRUTOS OLEAGINOSOS", value: "0126" },
  {
    text: "0127 - CULTIVO DE PLANTAS CON LAS QUE SE PREPARAN BEBIDAS",
    value: "0127",
  },
  {
    text: "0128 - CULTIVO DE ESPECIAS Y DE PLANTAS AROMÁTICAS, MEDICINALES Y FARMACEUTICAS",
    value: "0128",
  },
  { text: "0129 - CULTIVO DE OTRAS PLANTAS NO PERENNES", value: "0129" },
  { text: "0130 - PROPAGACIÓN DE PLANTAS", value: "0130" },
  { text: "0141 - CRÍA DE GANADO BOVINO Y BÚFALOS", value: "0141" },
  { text: "0142 - CRÍA DE CABALLOS Y OTROS EQUINOS", value: "0142" },
  { text: "0143 - CRÍA DE CAMELLOS Y OTROS CAMÉLIDOS", value: "0143" },
  { text: "0144 - CRÍA DE OVEJAS Y CABRAS", value: "0144" },
  { text: "0145 - CRÍA DE CERDOS", value: "0145" },
  { text: "0146 - CRÍA DE AVES DE CORRAL", value: "0146" },
  { text: "0149 - CRÍA DE OTROS ANIMALES", value: "0149" },
  {
    text: "0150 - CULTIVO DE PRODUCTOS AGRÍCOLAS EN COMBINACIÓN CON LA CRÍA DE ANIMALES",
    value: "0150",
  },
  { text: "0161 - ACTIVIDADES DE APOYO A LA AGRICULTURA", value: "0161" },
  { text: "0162 - ACTIVIDADES DE APOYO A LA GANADERÍA", value: "0162" },
  { text: "0163 - ACTIVIDADES POSCOSECHA", value: "0163" },
  { text: "0164 - TRATAMIENTO DE SEMILLAS PARA PROPAGACIÓN", value: "0164" },
  {
    text: "0170 - CAZA ORDINARIA Y MEDIANTE TRAMPAS Y ACTIVIDADES DE SERVICIOS CONEXAS",
    value: "0170",
  },
  { text: "0210 - SILVICULTURA Y OTRAS ACTIVIDADES FORESTALES", value: "0210" },
  { text: "0220 - EXTRACCIÓN DE MADERA", value: "0220" },
  {
    text: "0230 - RECOLECCIÓN DE PRODUCTOS FORESTALES DISTINTOS DE LA MADERA",
    value: "0230",
  },
  { text: "0240 - SERVICIOS DE APOYO A LA SILVICULTURA", value: "0240" },
  { text: "0311 - PESCA MARÍTIMA", value: "0311" },
  { text: "0312 - PESCA DE AGUA DULCE", value: "0312" },
  { text: "0321 - ACUICULTURA MARÍTIMA", value: "0321" },
  { text: "0322 - ACUICULTURA DE AGUA DULCE", value: "0322" },
  { text: "0510 - EXTRACCIÓN DE CARBÓN DE PIEDRA", value: "0510" },
  { text: "0520 - EXTRACCIÓN DE LIGNITO", value: "0520" },
  { text: "0610 - EXTRACCIÓN DE PETRÓLEO CRUDO", value: "0610" },
  { text: "0620 - EXTRACCIÓN DE GAS NATURAL", value: "0620" },
  { text: "0710 - EXTRACCIÓN DE MINERALES DE HIERRO", value: "0710" },
  { text: "0721 - EXTRACCIÓN DE MINERALES DE URANIO Y TORIO", value: "0721" },
  {
    text: "0729 - EXTRACCIÓN DE OTROS MINERALES METALÍFEROS NO FERROSOS",
    value: "0729",
  },
  { text: "0810 - EXTRACCIÓN DE PIEDRA, ARENA Y ARCILLA", value: "0810" },
  {
    text: "0891 - EXTRACCIÓN DE MINERALES PARA LA FABRICACIÓN DE ABONOS Y PRODUCTOS QUÍMICOS",
    value: "0891",
  },
  { text: "0892 - EXTRACCIÓN DE TURBA", value: "0892" },
  { text: "0893 - EXTRACCIÓN DE SAL", value: "0893" },
  {
    text: "0899 - EXPLOTACIÓN DE OTRAS MINAS Y CANTERAS N.C.P.",
    value: "0899",
  },
  {
    text: "0910 - ACTIVIDADES DE APOYO PARA LA EXTRACCIÓN DE PETRÓLEO Y GAS NATURAL",
    value: "0910",
  },
  {
    text: "0990 - ACTIVIDADES DE APOYO PARA OTRAS ACTIVIDADES DE EXPLOTACIÓN DE MINAS Y CANTERAS",
    value: "0990",
  },
  { text: "1010 - ELABORACIÓN Y CONSERVACIÓN DE CARNE", value: "1010" },
  {
    text: "1020 - ELABORACIÓN Y CONSERVACIÓN DE PESCADOS, CRUSTÁCEOS Y MOLUSCOS",
    value: "1020",
  },
  {
    text: "1030 - ELABORACIÓN Y CONSERVACIÓN DE FRUTAS,LEGUMBRES Y HORTALIZAS",
    value: "1030",
  },
  {
    text: "1040 - ELABORACIÓN DE ACEITES Y GRASAS DE ORIGEN VEGETAL Y ANIMAL",
    value: "1040",
  },
  { text: "1050 - ELABORACIÓN DE PRODUCTOS LÁCTEOS", value: "1050" },
  { text: "1061 - ELABORACIÓN DE PRODUCTOS DE MOLINERÍA", value: "1061" },
  {
    text: "1062 - ELABORACIÓN DE ALMIDONES Y PRODUCTOS DERIVADOS DEL ALMIDÓN",
    value: "1062",
  },
  { text: "1071 - ELABORACIÓN DE PRODUCTOS DE PANADERÍA", value: "1071" },
  { text: "1072 - ELABORACIÓN DE AZÚCAR", value: "1072" },
  {
    text: "1073 - ELABORACIÓN DE CACAO Y CHOCOLATE Y DE PRODUCTOS DE CONFITERÍA",
    value: "1073",
  },
  {
    text: "1074 - ELABORACIÓN DE MACARRONES, FIDEOS, ALCUZCUS Y PRODUCTOS FARINÁCEOS SIMILARES",
    value: "1074",
  },
  { text: "1075 - ELABORACIÓN DE COMIDAS Y PLATOS PREPARADOS", value: "1075" },
  {
    text: "1079 - ELABORACIÓN DE OTROS PRODUCTOS ALIMENTICIOS N.C.P.",
    value: "1079",
  },
  {
    text: "1080 - ELABORACIÓN DE PIENSOS PREPARADOS PARA ANIMALES",
    value: "1080",
  },
  {
    text: "1101 - DESTILACIÓN, RECTIFICACIÓN Y MEZCLA DE BEBIDAS ALCOHÓLICAS",
    value: "1101",
  },
  { text: "1102 - ELABORACIÓN DE VINOS", value: "1102" },
  { text: "1103 - ELABORACIÓN DE BEBIDAS MALTEADAS Y DE MALTA", value: "1103" },
  {
    text: "1104 - ELABORACIÓN DE BEBIDAS NO ALCOHÓLICAS; PRODUCCIÓN DE AGUAS MINERALES Y OTRAS AGUAS EMBOTELLADAS",
    value: "1104",
  },
  { text: "1200 - ELABORACIÓN DE PRODUCTOS DE TABACO", value: "1200" },
  { text: "1311 - PREPARACIÓN E HILATURA DE FIBRAS TEXTILES", value: "1311" },
  { text: "1312 - TEJEDURA DE PRODUCTOS TEXTILES", value: "1312" },
  { text: "1313 - ACABADO DE PRODUCTOS TEXTILES", value: "1313" },
  { text: "1391 - FABRICACIÓN DE TEJIDOS DE PUNTO Y GANCHILLO", value: "1391" },
  {
    text: "1392 - FABRICACIÓN DE ARTÍCULOS CONFECCIONADOS DE MATERIALES TEXTILES, EXCEPTO PRENDAS DE VESTIR",
    value: "1392",
  },
  { text: "1393 - FABRICACIÓN DE TAPICES Y ALFOMBRAS", value: "1393" },
  {
    text: "1394 - FABRICACIÓN DE CUERDAS, CORDELES, BRAMANTES Y REDES",
    value: "1394",
  },
  {
    text: "1399 - FABRICACIÓN DE OTROS PRODUCTOS TEXTILES N.C.P.",
    value: "1399",
  },
  {
    text: "1410 - FABRICACIÓN DE PRENDAS DE VESTIR, EXCEPTO PRENDAS DE PIEL",
    value: "1410",
  },
  { text: "1420 - FABRICACIÓN DE ARTÍCULOS DE PIEL", value: "1420" },
  {
    text: "1430 - FABRICACIÓN DE ARTICULOS DE PUNTO Y GANCHILLO",
    value: "1430",
  },
  {
    text: "1511 - CURTIDO Y ADOBO DE CUEROS; ADOBO Y TEÑIDO DE PIELES",
    value: "1511",
  },
  {
    text: "1512 - FABRICACIÓN DE MALETAS, BOLSOS DE MANO, Y ARTÍCULOS SIMILARES,Y DE ARTICULOS DE TALABARTERÍA Y GUARNICIONERÍA",
    value: "1512",
  },
  { text: "1520 - FABRICACIÓN DE CALZADO", value: "1520" },
  { text: "1610 - ASERRADOS Y ACEPILLADURA DE MADERA", value: "1610" },
  {
    text: "1621 - FABRICACIÓN DE HOJAS DE MADERA PARA ENCHAPADO Y TABLEROS A BASE DE MADERA",
    value: "1621",
  },
  {
    text: "1622 - FABRICACIÓN DE PARTES Y PIEZAS DE CARPINTERÍA PARA EDIFICIOS Y CONSTRUCCIONES",
    value: "1622",
  },
  { text: "1623 - FABRICACIÓN DE RECIPIENTES DE MADERA", value: "1623" },
  {
    text: "1629 - FABRICACIÓN DE OTROS PRODUCTOS DE MADERA; FABRICACIÓN DE ARTÍCULOS DE CORCHO, PAJA Y MATERIALES TRENZABLES.",
    value: "1629",
  },
  {
    text: "1701 - FABRICACIÓN DE PASTA DE MADERA, PAPEL Y CARTÓN",
    value: "1701",
  },
  {
    text: "1702 - FABRICACIÓN DEL PAPEL Y CARTÓN ONDULADO Y DE ENVASES DE PAPEL Y CARTÓN",
    value: "1702",
  },
  {
    text: "1709 - FABRICACIÓN DE OTROS ARTÍCULOS DEL PAPEL Y CARTÓN",
    value: "1709",
  },
  { text: "1811 - IMPRESIÓN", value: "1811" },
  {
    text: "1812 - ACTIVIDADES DE SERVICIOS RELACIONADAS CON LA IMPRESIÓN",
    value: "1812",
  },
  { text: "1820 - REPRODUCCIÓN DE GRABACIONES", value: "1820" },
  { text: "1910 - FABRICACIÓN DE PRODUCTOS DE HORNOS DE COQUE", value: "1910" },
  {
    text: "1920 - FABRICACIÓN DE PRODUCTOS DE LA REFINACIÓN DEL PETRÓLEO",
    value: "1920",
  },
  { text: "2011 - FABRICACIÓN DE SUSTANCIAS QUÍMICAS BÁSICAS", value: "2011" },
  {
    text: "2012 - FABRICACIÓN ABONOS Y COMPUESTOS DE NITRÓGENO",
    value: "2012",
  },
  {
    text: "2013 - FABRICACIÓN DE PLÁSTICOS Y DE CAUCHO SINTÉTICO EN FORMAS PRIMARIAS",
    value: "2013",
  },
  {
    text: "2021 - FABRICACIÓN DE PLAGUICIDAS Y OTROS PRODUCTOS QUÍMICOS DE USO AGROPECUARIO",
    value: "2021",
  },
  {
    text: "2022 - FABRICACIÓN DE PINTURAS, BARNICES Y PRODUCTOS DE REVESTIMIENTO SIMILARES, TINTAS DE IMPRENTA Y MASILLAS",
    value: "2022",
  },
  {
    text: "2023 - FABRICACIÓN DE JABONES Y DETERGENTES, PREPARADOS PARA LIMPIAR Y PULIR, PERFUMES Y PREPARADOS DE TOCADOR.",
    value: "2023",
  },
  {
    text: "2029 - FABRICACIÓN DE OTROS PRODUCTOS QUÍMICOS N.C.P.",
    value: "2029",
  },
  { text: "2030 - FABRICACIÓN DE FIBRAS ARTIFICIALES", value: "2030" },
  {
    text: "2100 - FABRICACIÓN DE PRODUCTOS FARMACÉUTICOS, SUSTANCIAS QUÍMICAS MEDICINALES Y PRODUCTOS BOTÁNICOS DE USO FARMACÉUTICO",
    value: "2100",
  },
  {
    text: "2211 - FABRICACIÓN DE CUBIERTAS Y CÁMARAS DE CAUCHO; RECAUCHUTADO Y RENOVACIÓN DE CUBIERTAS DE CAUCHO",
    value: "2211",
  },
  { text: "2219 - FABRICACIÓN DE OTROS PRODUCTOS DE CAUCHO", value: "2219" },
  { text: "2220 - FABRICACIÓN DE PRODUCTOS DE PLÁSTICO", value: "2220" },
  {
    text: "2310 - FABRICACIÓN DE VIDRIO Y DE PRODUCTOS DE VIDRIO",
    value: "2310",
  },
  { text: "2391 - FABRICACIÓN DE PRODUCTOS REFRACTARIOS", value: "2391" },
  {
    text: "2392 - FABRICACIÓN DE MATERIALES DE CONSTRUCCIÓN DE ARCILLA",
    value: "2392",
  },
  {
    text: "2393 - FABRICACIÓN DE OTROS PRODUCTOS DE PORCELANA Y DE CERÁMICA",
    value: "2393",
  },
  { text: "2394 - FABRICACIÓN DE CEMENTO, CAL Y YESO", value: "2394" },
  {
    text: "2395 - FABRICACIÓN DE ARTÍCULOS DE HORMIGÓN, DE CEMENTO Y DE YESO",
    value: "2395",
  },
  { text: "2396 - CORTE, TALLA Y ACABADO DE LA PIEDRA", value: "2396" },
  {
    text: "2399 - FABRICACIÓN DE OTROS PRODUCTOS MINERALES NO METÁLICOS N.C.P.",
    value: "2399",
  },
  { text: "2410 - INDUSTRIAS BÁSICAS DE HIERRO Y ACERO ", value: "2410" },
  {
    text: "2420 - FABRICACIÓN DE PRODUCTOS PRIMARIOS DE METALES PRECIOSOS Y OTROS METALES NO FERROSOS",
    value: "2420",
  },
  { text: "2431 - FUNDICIÓN DE HIERRO Y ACERO", value: "2431" },
  { text: "2432 - FUNDICIÓN DE METALES NO FERROSOS", value: "2432" },
  {
    text: "2511 - FABRICACIÓN DE PRODUCTOS METÁLICOS PARA USO ESTRUCTURAL",
    value: "2511",
  },
  {
    text: "2512 - FABRICACIÓN DE TANQUES, DEPÓSITOS Y RECIPIENTES DE METAL",
    value: "2512",
  },
  {
    text: "2513 - FABRICACIÓN DE LOS GENERADORES DEL VAPOR, EXCEPTO CALDERAS DE AGUA CALIENTE PARA CALEFACCIÓN CENTRAL",
    value: "2513",
  },
  { text: "2520 - FABRICACIÓN DE ARMAS Y MUNICIONES", value: "2520" },
  {
    text: "2591 - FORJA, PRENSADO, ESTAMPADO Y LAMINADO DE METALES; PULVIMETALURGIA",
    value: "2591",
  },
  {
    text: "2592 - TRATAMIENTO Y REVESTIMIENTO DE METALES; MAQUINADO",
    value: "2592",
  },
  {
    text: "2593 - FABRICACIÓN DE ARTÍCULOS DE CUCHILLERÍA, HERRAMIENTAS DE MANO Y ARTÍCULOS DE FERRETERÍA",
    value: "2593",
  },
  {
    text: "2599 - FABRICACIÓN DE OTROS PRODUCTOS ELABORADOS DE METAL N.C.P.",
    value: "2599",
  },
  {
    text: "2610 - FABRICACIÓN DE COMPONENTES Y TABLEROS ELECTRÓNICOS",
    value: "2610",
  },
  {
    text: "2620 - FABRICACIÓN DE ORDENADORES Y EQUIPO PERIFÉRICO",
    value: "2620",
  },
  { text: "2630 - FABRICACIÓN DE EQUIPOS DE COMUNICACIONES", value: "2630" },
  {
    text: "2640 - FABRICACIÓN DE APARATOS ELECTRÓNICOS DE CONSUMO",
    value: "2640",
  },
  {
    text: "2651 - FABRICACIÓN DE EQUIPO DE MEDICIÓN, PRUEBA, NAVEGACIÓN Y CONTROL",
    value: "2651",
  },
  { text: "2652 - FABRICACIÓN DE RELOJES", value: "2652" },
  {
    text: "2660 - FABRICACIÓN DE EQUIPO DE IRRADIACIÓN Y EQUIPO ELECTRÓNICO DE USO MÉDICO Y TERAPÉUTICO",
    value: "2660",
  },
  {
    text: "2670 - FABRICACIÓN DE INSTRUMENTOS ÓPTICOS Y EQUIPO FOTOGRÁFICO",
    value: "2670",
  },
  {
    text: "2680 - FABRICACIÓN DE SOPORTES MAGNÉTICOS Y ÓPTICOS",
    value: "2680",
  },
  {
    text: "2710 - FABRICACIÓN DE MOTORES, GENERADORES Y TRANSFORMADORES ELÉCTRICOS Y APARATOS DE DISTRIBUCIÓN Y CONTROL DE LA ENERGÍA ELÉCTRICA",
    value: "2710",
  },
  {
    text: "2720 - FABRICACIÓN DE PILAS, BATERÍAS Y ACUMULADORES",
    value: "2720",
  },
  { text: "2731 - FABRICACIÓN DE CABLES DE FIBRA ÓPTICA", value: "2731" },
  {
    text: "2732 - FABRICACIÓN DE OTROS HILOS Y CABLES ELÉCTRICOS",
    value: "2732",
  },
  { text: "2733 - FABRICACIÓN DE DISPOSITIVOS DE CABLEADO", value: "2733" },
  {
    text: "2740 - FABRICACIÓN DE EQUIPO ELÉCTRICO DE ILUMINACIÓN",
    value: "2740",
  },
  { text: "2750 - FABRICACIÓN DE APARATOS DE USO DOMÉSTICO", value: "2750" },
  {
    text: "2790 - FABRICACIÓN DE OTROS TIPOS DE EQUIPO ELÉCTRICO",
    value: "2790",
  },
  {
    text: "2811 - FABRICACIÓN DE MOTORES Y TURBINAS, EXCEPTO MOTORES PARA AERONAVES, VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS",
    value: "2811",
  },
  {
    text: "2812 - FABRICACIÓN DE EQUIPO DE PROPULSIÓN DE FLUIDOS",
    value: "2812",
  },
  {
    text: "2813 - FABRICACIÓN DE BOMBAS, COMPRESORES, GRIFOS Y VÁLVULAS",
    value: "2813",
  },
  {
    text: "2814 - FABRICACIÓN DE COJINETES, ENGRANAJES, TRENES DE ENGRANAJES Y PIEZAS DE TRANSMISIÓN",
    value: "2814",
  },
  { text: "2815 - FABRICACIÓN DE HORNOS, HOGARES Y QUEMADORES", value: "2815" },
  {
    text: "2816 - FABRICACIÓN DE EQUIPO DE ELEVACIÓN Y MANIPULACIÓN",
    value: "2816",
  },
  {
    text: "2817 - FABRICACIÓN DE MAQUINARIA Y EQUIPO DE OFICINA (EXCEPTO ORDENADORES Y EQUIPO PERIFÉRICO)",
    value: "2817",
  },
  {
    text: "2818 - FABRICACIÓN DE HERRAMIENTAS DE MANO MOTORIZADAS",
    value: "2818",
  },
  {
    text: "2819 - FABRICACIÓN DE OTROS TIPOS DE MAQUINARIA DE USO GENERAL",
    value: "2819",
  },
  {
    text: "2821 - FABRICACIÓN DE MAQUINARIA AGROPECUARIA Y FORESTAL",
    value: "2821",
  },
  {
    text: "2822 - FABRICACIÓN DE MAQUINARIA PARA LA CONFORMACIÓN DE METALES Y DE MÁQUINAS HERRAMIENTA",
    value: "2822",
  },
  { text: "2823 - FABRICACIÓN DE MAQUINARIA METALÚRGICA", value: "2823" },
  {
    text: "2824 - FABRICACIÓN DE MAQUINARIA PARA EXPLOTACIÓN DE MINAS Y CANTERAS Y PARA OBRAS DE CONSTRUCCIÓN",
    value: "2824",
  },
  {
    text: "2825 - FABRICACIÓN DE MAQUINARIA PARA LA ELABORACIÓN DE ALIMENTOS, BEBIDAS Y TABACO",
    value: "2825",
  },
  {
    text: "2826 - FABRICACIÓN DE MAQUINARIA PARA LA ELABORACIÓN DE PRODUCTOS TEXTILES, PRENDAS DE VESTIR Y CUEROS",
    value: "2826",
  },
  {
    text: "2829 - FABRICACIÓN DE OTROS TIPOS DE MAQUINARIA DE USO ESPECIAL",
    value: "2829",
  },
  { text: "2910 - FABRICACIÓN DE VEHÍCULOS AUTOMOTORES", value: "2910" },
  {
    text: "2920 - FABRICACIÓN DE CARROCERÍAS PARA VEHÍCULOS AUTOMOTORES; FABRICACIÓN DE REMOLQUES Y SEMIRREMOLQUES",
    value: "2920",
  },
  {
    text: "2930 - FABRICACIÓN DE PARTES, PIEZAS Y ACCESORIOS PARA VEHÍCULOS DE AUTOMOTORES",
    value: "2930",
  },
  {
    text: "3011 - CONSTRUCCIÓN DE BUQUES Y ESTRUCTURAS FLOTANTES",
    value: "3011",
  },
  {
    text: "3012 - CONSTRUCCIÓN DE EMBARCACIONES DE RECREO Y DEPORTE",
    value: "3012",
  },
  {
    text: "3020 - FABRICACIÓN DE LOCOMOTORAS Y DE MATERIAL RODANTE",
    value: "3020",
  },
  {
    text: "3030 - FABRICACIÓN DE AERONAVES Y  NAVES ESPACIALES Y MAQUINARIA CONEXA",
    value: "3030",
  },
  {
    text: "3040 - FABRICACIÓN DE VEHÍCULOS MILITARES DE COMBATE",
    value: "3040",
  },
  { text: "3091 - FABRICACIÓN DE MOTOCICLETAS", value: "3091" },
  {
    text: "3092 - FABRICACIÓN DE BICICLETAS Y DE SILLONES DE RUEDAS PARA INVÁLIDOS",
    value: "3092",
  },
  {
    text: "3099 - FABRICACIÓN DE OTROS TIPOS DE EQUIPO DE TRANSPORTE N.C.P.",
    value: "3099",
  },
  { text: "3100 - FABRICACIÓN DE MUEBLES", value: "3100" },
  { text: "3211 - FABRICACIÓN DE JOYAS Y ARTÍCULOS CONEXOS", value: "3211" },
  {
    text: "3212 - FABRICACIÓN DE BISUTERÍA Y ARTÍCULOS CONEXOS",
    value: "3212",
  },
  { text: "3220 - FABRICACIÓN DE INSTRUMENTOS DE MÚSICA", value: "3220" },
  { text: "3230 - FABRICACIÓN DE ARTÍCULOS DE DEPORTE", value: "3230" },
  { text: "3240 - FABRICACIÓN DE JUEGOS Y JUGUETES", value: "3240" },
  {
    text: "3250 - FABRICACIÓN DE INSTRUMENTOS Y MATERIALES MÉDICOS Y ODONTOLÓGICOS",
    value: "3250",
  },
  { text: "3290 - OTRAS INDUSTRIAS MANUFACTURERAS N.C.P.", value: "3290" },
  { text: "3311 - REPARACIÓN DE PRODUCTOS ELABORADOS DE METAL", value: "3311" },
  { text: "3312 - REPARACIÓN DE MAQUINARIA", value: "3312" },
  { text: "3313 - REPARACIÓN DE EQUIPO ELECTRÓNICO Y ÓPTICO", value: "3313" },
  { text: "3314 - REPARACIÓN DE EQUIPO ELÉCTRICO", value: "3314" },
  {
    text: "3315 - REPARACIÓN DE EQUIPO DE TRANSPORTE, EXCEPTO VEHÍCULOS AUTOMOTORES",
    value: "3315",
  },
  { text: "3319 - REPARACIÓN DE OTROS TIPOS DE EQUIPO", value: "3319" },
  {
    text: "3320 - INSTALACIÓN DE MAQUINARIA Y EQUIPO INDUSTRIALES",
    value: "3320",
  },
  {
    text: "3510 - GENERACIÓN, TRANSMISIÓN Y DISTRIBUCIÓN DE ENERGÍA ELÉCTRICA",
    value: "3510",
  },
  {
    text: "3520 - FABRICACIÓN DEL GAS; DISTRIBUCIÓN DE COMBUSTIBLES GASEOSOS POR TUBERÍAS",
    value: "3520",
  },
  { text: "3530 - SUMINISTRO DE VAPOR Y AIRE ACONDICIONADO", value: "3530" },
  {
    text: "3600 - CAPTACIÓN, TRATAMIENTO Y DISTRIBUCIÓN DE AGUA",
    value: "3600",
  },
  { text: "3700 - EVACUACIÓN DE AGUAS RESIDUALES", value: "3700" },
  { text: "3811 - RECOGIDA DE DESECHOS NO PELIGROSOS", value: "3811" },
  { text: "3812 - RECOGIDA DE DESECHOS PELIGROSOS", value: "3812" },
  {
    text: "3821 - TRATAMIENTO Y ELIMINACIÓN DE DESECHOS NO PELIGROSOS",
    value: "3821",
  },
  {
    text: "3822 - TRATAMIENTO Y ELIMINACIÓN DE DESECHOS PELIGROSOS",
    value: "3822",
  },
  { text: "3830 - RECUPERACIÓN DE MATERIALES", value: "3830" },
  {
    text: "3900 - ACTIVIDADES DE DESCONTAMINACIÓN Y OTROS SERVICIOS DE GESTIÓN DE DESECHOS",
    value: "3900",
  },
  { text: "4100 - CONSTRUCCIÓN DE EDIFICIOS", value: "4100" },
  {
    text: "4210 - CONSTRUCCIÓN DE CARRETERAS Y LÍNEAS DE FERROCARRIL",
    value: "4210",
  },
  {
    text: "4220 - CONSTRUCCIÓN DE PROYECTOS DE SERVICIO PÚBLICO",
    value: "4220",
  },
  {
    text: "4290 - CONSTRUCCIÓN DE OTRAS OBRAS DE INGENIERÍA CIVIL",
    value: "4290",
  },
  { text: "4311 - DEMOLICIÓN", value: "4311" },
  { text: "4312 - PREPARACIÓN DEL TERRENO", value: "4312" },
  { text: "4321 - INSTALACIONES ELÉCTRICAS", value: "4321" },
  {
    text: "4322 - INSTALACIONES DE FONTANERÍA, CALEFACCIÓN Y AIRE ACONDICIONADO",
    value: "4322",
  },
  {
    text: "4329 - OTRAS INSTALACIONES PARA OBRAS DE CONSTRUCCIÓN",
    value: "4329",
  },
  { text: "4330 - TERMINACIÓN Y ACABADO DE EDIFICIOS", value: "4330" },
  {
    text: "4390 - OTRAS ACTIVIDADES ESPECIALIZADAS DE LA CONSTRUCCIÓN",
    value: "4390",
  },
  { text: "4510 - VENTA DE VEHÍCULOS AUTOMOTORES", value: "4510" },
  {
    text: "4520 - MANTENIMIENTO Y REPARACIÓN DE VEHÍCULOS AUTOMOTORES",
    value: "4520",
  },
  {
    text: "4530 - VENTAS DE PARTES, PIEZAS Y ACCESORIOS PARA VEHÍCULOS AUTOMOTORES",
    value: "4530",
  },
  {
    text: "4540 - VENTA, MANTENIMIENTO Y REPARACIÓN DE MOTOCICLETAS Y DE SUS PARTES, PIEZAS Y ACCESORIOS.",
    value: "4540",
  },
  {
    text: "4610 - VENTA AL POR MAYOR A CAMBIO DE UNA RETRIBUCIÓN O POR CONTRATA",
    value: "4610",
  },
  {
    text: "4620 - VENTA AL POR MAYOR DE MATERIAS PRIMAS AGROPECUARIAS Y ANIMALES VIVOS.",
    value: "4620",
  },
  {
    text: "4630 - VENTA AL POR MAYOR DE ALIMENTOS, BEBIDAS Y TABACO.",
    value: "4630",
  },
  {
    text: "4641 - VENTA AL POR MAYOR DE PRODUCTOS TEXTILES, PRENDAS DE VESTIR Y CALZADO",
    value: "4641",
  },
  {
    text: "4649 - VENTA AL POR MAYOR DE OTROS ENSERES DOMÉSTICOS",
    value: "4649",
  },
  {
    text: "4651 - VENTA AL POR MAYOR DE ORDENADORES, EQUIPO PERIFÉRICO Y PROGRAMAS DE INFORMÁTICA",
    value: "4651",
  },
  {
    text: "4652 - VENTA AL POR MAYOR DE EQUIPO, PARTES Y PIEZAS ELECTRÓNICOS Y DE TELECOMUNICACIONES",
    value: "4652",
  },
  {
    text: "4653 - VENTA AL POR MAYOR DE MAQUINARIA, EQUIPO Y MATERIALES AGROPECUARIOS ",
    value: "4653",
  },
  {
    text: "4659 - VENTA AL POR MAYOR DE OTROS TIPOS DE MAQUINARIA Y EQUIPO",
    value: "4659",
  },
  {
    text: "4661 - VENTA AL POR MAYOR DE COMBUSTIBLES SÓLIDOS, LÍQUIDOS Y GASEOSOS Y  PRODUCTOS CONEXOS",
    value: "4661",
  },
  {
    text: "4662 - VENTA AL POR MAYOR DE METALES Y MINERALES METALÍFEROS",
    value: "4662",
  },
  {
    text: "4663 - VENTA AL POR MAYOR DE MATERIALES DE CONSTRUCCIÓN, ARTÍCULOS DE FERRETERÍA Y EQUIPO Y MATERIALES DE FONTANERÍA Y CALEFACCIÓN.",
    value: "4663",
  },
  {
    text: "4669 - VENTA AL POR MAYOR DE DESPERDICIOS, DESECHOS, CHATARRA Y OTROS PRODUCTOS N.C.P ",
    value: "4669",
  },
  { text: "4690 - VENTA AL POR MAYOR NO ESPECIALIZADA ", value: "4690" },
  {
    text: "4711 - VENTA AL POR MENOR EN COMERCIOS NO ESPECIALIZADOS CON PREDOMINIO DE LA VENTA DE ALIMENTOS, BEBIDAS O TABACO",
    value: "4711",
  },
  { text: "4632 - COMERCIO AL POR MAYOR DE BEBIDAS Y TABACO", value: "4632" },
  {
    text: "4719 - OTRAS ACTIVIDADES DE VENTA AL POR MENOR EN COMERCIOS NO ESPECIALIZADOS",
    value: "4719",
  },
  {
    text: "4721 - VENTA AL POR MENOR DE ALIMENTOS EN COMERCIOS ESPECIALIZADOS",
    value: "4721",
  },
  {
    text: "4722 - VENTA AL POR MENOR DE BEBIDAS EN COMERCIOS ESPECIALIZADOS",
    value: "4722",
  },
  {
    text: "4723 - VENTA AL POR MENOR DE PRODUCTOS DE TABACO EN COMERCIOS ESPECIALIZADOS",
    value: "4723",
  },
  {
    text: "4730 - VENTA AL POR MENOR DE COMBUSTIBLES PARA VEHÍCULOS AUTOMOTORES EN COMERCIOS ESPECIALIZADOS",
    value: "4730",
  },
  {
    text: "4741 - VENTA AL POR MENOR DE ORDENADORES, EQUIPO PERIFÉRICO, PROGRAMAS INFORMÁTICOS Y EQUIPO DE TELECOMUNICACIONES EN COMERCIOS ESPECIALIZADOS",
    value: "4741",
  },
  {
    text: "4742 - VENTA AL POR MENOR DE EQUIPO DE SONIDO Y DE VÍDEO EN COMERCIOS ESPECIALIZADOS",
    value: "4742",
  },
  {
    text: "4751 - VENTA AL POR MENOR DE PRODUCTOS TEXTILES EN COMERCIOS ESPECIALIZADOS",
    value: "4751",
  },
  {
    text: "4752 - VENTA AL POR MENOR DE ARTÍCULOS DE FERRETERÍA, PINTURAS Y PRODUCTOS DE VIDRIO EN COMERCIOS ESPECIALIZADOS",
    value: "4752",
  },
  {
    text: "4753 - VENTA AL POR MENOR DE TAPICES, ALFOMBRAS Y CUBRIMIENTOS PARA PAREDES Y PISOS EN COMERCIOS ESPECIALIZADOS",
    value: "4753",
  },
  {
    text: "4759 - VENTA AL POR MENOR DE APARATOS ELÉCTRICOS DE USO DOMÉSTICO,  MUEBLES, EQUIPO DE ILUMINACIÓN Y OTROS ENSERES DOMÉSTICOS EN COMERCIOS ESPECIALIZADOS",
    value: "4759",
  },
  {
    text: "4761 - VENTA AL POR MENOR DE LIBROS, PERIÓDICOS Y ARTÍCULOS DE PAPELERÍA EN COMERCIOS ESPECIALIZADOS",
    value: "4761",
  },
  {
    text: "4762 - VENTA AL POR MENOR DE GRABACIONES DE MÚSICA Y DE VÍDEO EN COMERCIOS ESPECIALIZADOS",
    value: "4762",
  },
  {
    text: "4763 - VENTA AL POR MENOR DE EQUIPO DE DEPORTE EN COMERCIOS ESPECIALIZADOS",
    value: "4763",
  },
  {
    text: "4764 - VENTA AL POR MENOR DE JUEGOS Y  JUGUETES EN COMERCIOS ESPECIALIZADOS",
    value: "4764",
  },
  {
    text: "4771 - VENTA AL POR MENOR DE PRENDAS DE VESTIR, CALZADO Y ARTÍCULOS DE CUERO EN COMERCIOS ESPECIALIZADOS",
    value: "4771",
  },
  {
    text: "4772 - VENTA AL POR MENOR DE PRODUCTOS FARMACÉUTICOS Y MEDICINALES, COSMÉTICOS Y ARTÍCULOS DE TOCADOR EN COMERCIOS ESPECIALIZADOS   ",
    value: "4772",
  },
  {
    text: "4773 - VENTA AL POR MENOR DE OTROS PRODUCTOS NUEVOS EN COMERCIOS ESPECIALIZADOS",
    value: "4773",
  },
  {
    text: "4775 - VENTA AL POR MENOR DE ARTÍCULOS DE SEGUNDA MANO",
    value: "4775",
  },
  {
    text: "4781 - VENTA AL POR MENOR DE ALIMENTOS, BEBIDAS Y TABACO EN PUESTOS DE VENTA Y MERCADOS ",
    value: "4781",
  },
  {
    text: "4782 - VENTA AL POR MENOR DE PRODUCTOS TEXTILES, PRENDAS DE VESTIR Y CALZADO EN PUESTOS DE VENTA Y MERCADOS",
    value: "4782",
  },
  {
    text: "4789 - VENTA AL POR MENOR DE OTROS PRODUCTOS EN PUESTOS DE VENTA Y MERCADOS",
    value: "4789",
  },
  {
    text: "4791 - VENTA AL POR MENOR POR CORREO Y POR INTERNET",
    value: "4791",
  },
  {
    text: "4792 - COMERCIO AL POR MENOR REALIZADO A TRAVÉS DE CASAS DE VENTA O POR CORREO",
    value: "4792",
  },
  {
    text: "4799 - OTRAS ACTIVIDADES DE VENTA AL POR MENOR NO REALIZADAS EN COMERCIOS, PUESTOS DE VENTA O MERCADOS",
    value: "4799",
  },
  {
    text: "4911 - TRANSPORTE INTERURBANO DE PASAJEROS POR FERROCARRIL",
    value: "4911",
  },
  { text: "4912 - TRANSPORTE DE CARGA POR FERROCARRIL", value: "4912" },
  {
    text: "4921 - TRANSPORTE URBANO Y SUBURBANO DE PASAJEROS POR VÍA TERRESTRE ",
    value: "4921",
  },
  {
    text: "4922 - OTRAS ACTIVIDADES DE TRANSPORTE POR VÍA TERRESTRE",
    value: "4922",
  },
  { text: "4923 - TRANSPORTE DE CARGA POR CARRETERA", value: "4923" },
  { text: "4930 - TRANSPORTE POR TUBERÍAS", value: "4930" },
  {
    text: "5011 - TRANSPORTE DE PASAJEROS MARÍTIMO Y DE CABOTAJE",
    value: "5011",
  },
  { text: "5012 - TRANSPORTE DE CARGA MARÍTIMO Y DE CABOTAJE", value: "5012" },
  {
    text: "5021 - TRANSPORTE DE PASAJEROS POR VÍAS DE NAVEGACIÓN INTERIORES",
    value: "5021",
  },
  {
    text: "5022 - TRANSPORTE DE CARGA, POR VÍAS DE NAVEGACIÓN INTERIORES",
    value: "5022",
  },
  { text: "5110 - TRANSPORTE DE PASAJEROS POR VÍA AÉREA", value: "5110" },
  { text: "5120 - TRANSPORTE DE CARGA POR VÍA AÉREA", value: "5120" },
  { text: "5210 - ALMACENAMIENTO Y DEPÓSITO", value: "5210" },
  {
    text: "5221 - ACTIVIDADES DE SERVICIOS VINCULADAS AL TRANSPORTE TERRESTRE",
    value: "5221",
  },
  {
    text: "5222 - ACTIVIDADES DE SERVICIOS VINCULADAS AL TRANSPORTE ACUÁTICO",
    value: "5222",
  },
  {
    text: "5223 - ACTIVIDADES DE SERVICIOS VINCULADAS AL TRANSPORTE AÉREO",
    value: "5223",
  },
  { text: "5224 - MANIPULACIÓN DE CARGA", value: "5224" },
  { text: "5229 - OTRAS ACTIVIDADES DE APOYO AL TRANSPORTE", value: "5229" },
  { text: "5310 - ACTIVIDADES POSTALES", value: "5310" },
  { text: "5320 - ACTIVIDADES DE MENSAJERÍA", value: "5320" },
  {
    text: "5510 - ACTIVIDADES DE ALOJAMIENTO PARA ESTANCIAS CORTAS",
    value: "5510",
  },
  { text: "5519 - OTROS TIPOS DE ALOJAMIENTOS PARA VISITANTES", value: "5519" },
  {
    text: "5520 - ACTIVIDADES DE CAMPAMENTOS, PARQUES DE VEHÍCULOS RECREATIVOS Y PARQUES DE CARAVANAS",
    value: "5520",
  },
  { text: "5530 - SERVICIO DE ESTANCIA POR HORAS", value: "5530" },
  { text: "5590 - OTRAS ACTIVIDADES DE ALOJAMIENTO", value: "5590" },
  {
    text: "5610 - ACTIVIDADES DE RESTAURANTES Y DE SERVICIO MÓVIL DE COMIDAS",
    value: "5610",
  },
  { text: "5621 - SUMINISTRO DE COMIDAS POR ENCARGO", value: "5621" },
  { text: "5629 - OTRAS ACTIVIDADES DE SERVICIO DE COMIDAS", value: "5629" },
  { text: "5630 - ACTIVIDADES DE SERVICIO DE BEBIDAS", value: "5630" },
  { text: "5811 - EDICIÓN DE LIBROS", value: "5811" },
  { text: "5812 - EDICIÓN DE DIRECTORIOS Y LISTAS DE CORREO", value: "5812" },
  {
    text: "5813 - EDICIÓN DE PERIÓDICOS, REVISTAS Y OTRAS PUBLICACIONES PERIÓDICAS",
    value: "5813",
  },
  { text: "5819 - OTRAS ACTIVIDADES DE EDICIÓN", value: "5819" },
  { text: "5820 - EDICIÓN DE PROGRAMAS DE INFORMÁTICOS", value: "5820" },
  {
    text: "5911 - ACTIVIDADES DE PRODUCCIÓN DE PELÍCULAS CINEMATOGRÁFICAS, VÍDEOS Y PROGRAMAS DE TELEVISIÓN",
    value: "5911",
  },
  {
    text: "5912 - ACTIVIDADES DE POSTPRODUCCIÓN DE PELÍCULAS CINEMATOGRÁFICAS, VÍDEOS Y PROGRAMAS DE TELEVISIÓN",
    value: "5912",
  },
  {
    text: "5913 - ACTIVIDADES DE DISTRIBUCIÓN DE PELÍCULAS CINEMATOGRÁFICAS, VÍDEOS Y PROGRAMAS DE TELEVISIÓN",
    value: "5913",
  },
  {
    text: "5914 - ACTIVIDADES DE EXHIBICIÓN DE PELÍCULAS CINEMATOGRÁFICAS Y CINTAS DE VÍDEO ",
    value: "5914",
  },
  {
    text: "5920 - ACTIVIDADES DE GRABACIÓN DE SONIDO Y EDICIÓN DE MÚSICA",
    value: "5920",
  },
  { text: "6010 - TRANSMISIONES DE RADIO", value: "6010" },
  {
    text: "6110 - ACTIVIDADES DE TELECOMUNICACIONES ALÁMBRICAS",
    value: "6110",
  },
  {
    text: "6120 - ACTIVIDADES DE TELECOMUNICACIONES INALÁMBRICAS",
    value: "6120",
  },
  {
    text: "6130 - ACTIVIDADES DE TELECOMUNICACIONES POR SATÉLITE.",
    value: "6130",
  },
  { text: "6190 - OTRAS ACTIVIDADES DE TELECOMUNICACIÓN.", value: "6190" },
  { text: "6201 - PROGRAMACIÓN INFORMÁTICA", value: "6201" },
  {
    text: "6202 - CONSULTORÍA DE INFORMÁTICA Y DE GESTIÓN DE INSTALACIONES INFORMÁTICAS",
    value: "6202",
  },
  {
    text: "6209 - OTRAS ACTIVIDADES DE TECNOLOGÍA DE LA INFORMACIÓN Y DE SERVICIOS INFORMÁTICOS",
    value: "6209",
  },
  {
    text: "6311 - PROCESAMIENTO DE DATOS, HOSPEDAJE Y ACTIVIDADES CONEXAS",
    value: "6311",
  },
  { text: "6312 - PORTALES WEB", value: "6312" },
  { text: "6391 - ACTIVIDADES DE AGENCIAS DE NOTICIAS", value: "6391" },
  { text: "6411 - BANCA CENTRAL", value: "6411" },
  { text: "6419 - OTROS TIPOS DE INTERMEDIACIÓN MONETARIA.", value: "6419" },
  { text: "6420 - ACTIVIDADES DE SOCIEDADES DE CARTERA", value: "6420" },
  {
    text: "6430 - FONDOS Y SOCIEDADES DE INVERSIÓN Y ENTIDADES FINANCIERAS SIMILARES",
    value: "6430",
  },
  { text: "6491 - ARRENDAMIENTO FINANCIERO", value: "6491" },
  { text: "6492 - OTRAS ACTIVIDADES DE CONCESIÓN DE CRÉDITO", value: "6492" },
  {
    text: "6499 - OTRAS ACTIVIDADES DE SERVICIOS FINANCIEROS, EXCEPTO LAS DE SEGUROS Y FONDOS DE PENSIONES, N.C.P.",
    value: "6499",
  },
  { text: "6511 - SEGUROS DE VIDA", value: "6511" },
  { text: "6512 - SEGUROS GENERALES", value: "6512" },
  { text: "6520 - REASEGUROS", value: "6520" },
  { text: "6530 - FONDOS DE PENSIONES", value: "6530" },
  { text: "6611 - ADMINISTRACIÓN DE MERCADOS FINANCIEROS", value: "6611" },
  {
    text: "6612 - CORRETAJE DE VALORES Y DE CONTRATOS DE PRODUCTOS BÁSICOS",
    value: "6612",
  },
  {
    text: "6619 - OTRAS ACTIVIDADES AUXILIARES DE LAS ACTIVIDADES DE SERVICIOS FINANCIEROS",
    value: "6619",
  },
  { text: "6621 - EVALUACIÓN DE RIESGOS Y DAÑOS", value: "6621" },
  {
    text: "6622 - ACTIVIDADES DE AGENTES Y CORREDORES DE SEGUROS",
    value: "6622",
  },
  {
    text: "6629 - OTRAS ACTIVIDADES AUXILIARES DE LAS ACTIVIDADES DE SEGUROS Y FONDOS DE PENSIONES",
    value: "6629",
  },
  { text: "6630 - ACTIVIDADES DE GESTIÓN DE FONDOS", value: "6630" },
  {
    text: "6810 - ACTIVIDADES INMOBILIARIAS REALIZADAS CON BIENES PROPIOS O ARRENDADOS",
    value: "6810",
  },
  {
    text: "6820 - ACTIVIDADES INMOBILIARIAS REALIZADAS A CAMBIO DE UNA RETRIBUCIÓN O POR CONTRATA",
    value: "6820",
  },
  { text: "6910 - ACTIVIDADES JURÍDICAS", value: "6910" },
  {
    text: "6920 - ACTIVIDADES DE CONTABILIDAD, TENEDURÍA DE LIBROS Y AUDITORÍA; CONSULTORÍA FISCAL",
    value: "6920",
  },
  { text: "7010 - ACTIVIDADES DE OFICINAS CENTRALES", value: "7010" },
  { text: "7020 - ACTIVIDADES DE CONSULTORÍA DE GESTIÓN", value: "7020" },
  {
    text: "7110 - ACTIVIDADES DE ARQUITECTURA E INGENIERÍA Y ACTIVIDADES CONEXAS DE CONSULTORÍA TÉCNICA",
    value: "7110",
  },
  { text: "7120 - ENSAYOS Y ANÁLISIS TÉCNICOS", value: "7120" },
  {
    text: "7210 - INVESTIGACIÓN Y DESARROLLO EXPERIMENTAL EN EL CAMPO DE LAS CIENCIAS NATURALES Y LA INGENIERÍA",
    value: "7210",
  },
  {
    text: "7220 - INVESTIGACIÓN Y DESARROLLO EXPERIMENTAL EN EL CAMPO DE LAS CIENCIAS SOCIALES Y LAS HUMANIDADES",
    value: "7220",
  },
  { text: "7310 - PUBLICIDAD", value: "7310" },
  {
    text: "7320 - ESTUDIOS DE MERCADO Y ENCUESTAS DE OPINIÓN PÚBLICA",
    value: "7320",
  },
  { text: "7410 - ACTIVIDADES ESPECIALIZADAS DE DISEÑO", value: "7410" },
  { text: "7420 - ACTIVIDADES DE FOTOGRAFÍA", value: "7420" },
  {
    text: "7490 - OTRAS ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS N.C.P.",
    value: "7490",
  },
  { text: "7500 - ACTIVIDADES VETERINARIAS", value: "7500" },
  {
    text: "7710 - ALQUILER Y ARRENDAMIENTO DE VEHÍCULOS AUTOMOTORES",
    value: "7710",
  },
  {
    text: "7721 - ALQUILER Y ARRENDAMIENTO DE EQUIPO RECREATIVO Y DEPORTIVO",
    value: "7721",
  },
  { text: "7722 - ALQUILER DE CINTAS DE VÍDEO Y DISCOS", value: "7722" },
  {
    text: "7729 - ALQUILER Y ARRENDAMIENTO DE OTROS EFECTOS PERSONALES Y ENSERES DOMÉSTICOS ",
    value: "7729",
  },
  {
    text: "7730 - ALQUILER Y ARRENDAMIENTO DE OTROS TIPOS DE MAQUINARIA, EQUIPO Y BIENES TANGIBLES",
    value: "7730",
  },
  {
    text: "7740 - ARRENDAMIENTO DE PROPIEDAD INTELECTUAL Y PRODUCTOS SIMILARES, EXCEPTO OBRAS PROTEGIDAS  POR DERECHOS DE AUTOR",
    value: "7740",
  },
  { text: "7810 - ACTIVIDADES DE AGENCIAS DE EMPLEO", value: "7810" },
  { text: "7820 - ACTIVIDADES DE AGENCIAS DE EMPLEO TEMPORAL", value: "7820" },
  {
    text: "7830 - OTRAS ACTIVIDADES DE DOTACIÓN DE RECURSOS HUMANOS",
    value: "7830",
  },
  { text: "7911 - ACTIVIDADES DE AGENCIAS DE VIAJES", value: "7911" },
  { text: "7912 - ACTIVIDADES DE OPERADORES TURÍSTICOS", value: "7912" },
  {
    text: "7990 - OTROS SERVICIOS DE RESERVAS Y ACTIVIDADES CONEXAS",
    value: "7990",
  },
  { text: "8010 - ACTIVIDADES DE SEGURIDAD PRIVADA", value: "8010" },
  {
    text: "8020 - ACTIVIDADES DE SERVICIO DE SISTEMAS DE SEGURIDAD",
    value: "8020",
  },
  { text: "8030 - ACTIVIDADES DE INVESTIGACIÓN", value: "8030" },
  {
    text: "8110 - ACTIVIDADES COMBINADAS DE APOYO A INSTALACIONES",
    value: "8110",
  },
  { text: "8121 - LIMPIEZA GENERAL DE EDIFICIOS", value: "8121" },
  {
    text: "8129 - OTRAS ACTIVIDADES DE LIMPIEZA DE EDIFICIOS E INSTALACIONES INDUSTRIALES",
    value: "8129",
  },
  {
    text: "8130 - ACTIVIDADES DE PAISAJISMO Y SERVICIOS DE MANTENIMIENTO CONEXOS",
    value: "8130",
  },
  {
    text: "8211 - ACTIVIDADES COMBINADAS DE SERVICIOS ADMINISTRATIVOS DE OFICINA",
    value: "8211",
  },
  {
    text: "8219 - FOTOCOPIADO, PREPARACIÓN DE DOCUMENTOS Y OTRAS ACTIVIDADES ESPECIALIZADAS DE APOYO DE OFICINA",
    value: "8219",
  },
  { text: "8220 - ACTIVIDADES DE CENTROS DE LLAMADAS", value: "8220" },
  {
    text: "8230 - ORGANIZACIÓN DE CONVENCIONES Y EXPOSICIONES COMERCIALES",
    value: "8230",
  },
  {
    text: "8291 - ACTIVIDADES DE AGENCIAS DE COBRO Y AGENCIAS DE CALIFICACIÓN CREDITICIA",
    value: "8291",
  },
  { text: "8292 - ACTIVIDADES DE ENVASADO Y EMPAQUETADO", value: "8292" },
  {
    text: "8299 - OTRAS ACTIVIDADES DE SERVICIOS DE APOYO A LAS EMPRESAS N.C.P",
    value: "8299",
  },
  {
    text: "8411 - ACTIVIDADES DE LA ADMINISTRACIÓN PÚBLICA EN GENERAL",
    value: "8411",
  },
  {
    text: "8412 - REGULACIÓN DE LAS ACTIVIDADES DE ORGANISMOS QUE PRESTAN SERVICIOS SANITARIOS, EDUCATIVOS, CULTURALES Y OTROS SERVICIOS SOCIALES, EXCEPTO SERVICIOS DE SEGURIDAD SOCIAL",
    value: "8412",
  },
  {
    text: "8413 - REGULACIÓN Y FACILITACIÓN DE LA ACTIVIDAD ECONÓMICA",
    value: "8413",
  },
  { text: "8421 - RELACIONES EXTERIORES", value: "8421" },
  { text: "8422 - ACTIVIDADES DE DEFENSA", value: "8422" },
  {
    text: "8423 - ACTIVIDADES DE MANTENIMIENTO DEL ORDEN PÚBLICO Y DE SEGURIDAD",
    value: "8423",
  },
  {
    text: "8430 - ACTIVIDADES DE PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA",
    value: "8430",
  },
  { text: "8510 - ENSEÑANZA PREESCOLAR Y PRIMARIA", value: "8510" },
  { text: "8521 - ENSEÑANZA SECUNDARIA DE FORMACIÓN GENERAL", value: "8521" },
  {
    text: "8522 - ENSEÑANZA SECUNDARIA DE FORMACIÓN TÉCNICA Y PROFESIONAL",
    value: "8522",
  },
  { text: "8530 - ENSEÑANZA SUPERIOR", value: "8530" },
  { text: "8541 - EDUCACIÓN DEPORTIVA Y RECREATIVA", value: "8541" },
  { text: "8542 - ENSEÑANZA CULTURAL", value: "8542" },
  { text: "8549 - OTROS TIPOS DE ENSEÑANZA N.C.P.", value: "8549" },
  { text: "8550 - ACTIVIDADES DE APOYO A LA ENSEÑANZA", value: "8550" },
  { text: "8610 - ACTIVIDADES DE HOSPITALES", value: "8610" },
  { text: "8620 - ACTIVIDADES DE MÉDICOS Y ODONTÓLOGOS", value: "8620" },
  {
    text: "8690 - OTRAS ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA",
    value: "8690",
  },
  {
    text: "8710 - ACTIVIDADES DE ATENCIÓN DE ENFERMERÍA EN INSTITUCIONES",
    value: "8710",
  },
  {
    text: "8720 - ACTIVIDADES DE ATENCIÓN EN INSTITUCIONES PARA PERSONAS CON RETRASO MENTAL, ENFERMOS MENTALES Y TOXICÓMANOS",
    value: "8720",
  },
  {
    text: "8730 - ACTIVIDADES DE ATENCIÓN EN INSTITUCIONES PARA PERSONAS DE EDAD PERSONAS CON DISCAPACIDAD",
    value: "8730",
  },
  {
    text: "8790 - OTRAS ACTIVIDADES DE ATENCIÓN EN INSTITUCIONES",
    value: "8790",
  },
  {
    text: "8810 - ACTIVIDADES DE ASISTENCIA SOCIAL SIN ALOJAMIENTO PARA PERSONAS DE EDAD Y PERSONAS CON DISCAPACIDAD",
    value: "8810",
  },
  {
    text: "8890 - OTRAS ACTIVIDADES DE ASISTENCIA SOCIAL SIN ALOJAMIENTO",
    value: "8890",
  },
  {
    text: "9000 - ACTIVIDADES CREATIVAS, ARTÍSTICAS Y DE ENTRETENIMIENTO",
    value: "9000",
  },
  { text: "9101 - ACTIVIDADES DE BIBLIOTECAS Y ARCHIVOS", value: "9101" },
  {
    text: "9102 - ACTIVIDADES DE MUSEOS Y GESTIÓN DE LUGARES Y EDIFICIOS HISTÓRICOS",
    value: "9102",
  },
  {
    text: "9103 - ACTIVIDADES DE JARDINES BOTÁNICOS Y ZOOLÓGICOS Y RESERVAS NATURALES",
    value: "9103",
  },
  { text: "9200 - ACTIVIDADES DE JUEGOS DE AZAR Y APUESTAS", value: "9200" },
  { text: "9311 - GESTIÓN DE INSTALACIONES DEPORTIVAS", value: "9311" },
  { text: "9312 - ACTIVIDADES DE CLUBES DEPORTIVOS", value: "9312" },
  { text: "9319 - OTRAS ACTIVIDADES DEPORTIVAS", value: "9319" },
  {
    text: "9321 - ACTIVIDADES DE PARQUES DE ATRACCIONES Y PARQUES TEMÁTICOS",
    value: "9321",
  },
  {
    text: "9329 - OTRAS ACTIVIDADES DE ESPARCIMIENTO Y RECREATIVAS N.C.P.",
    value: "9329",
  },
  {
    text: "9411 - ACTIVIDADES DE ASOCIACIONES EMPRESARIALES Y DE EMPLEADORES",
    value: "9411",
  },
  { text: "9412 - ACTIVIDADES DE ASOCIACIONES PROFESIONALES", value: "9412" },
  { text: "9420 - ACTIVIDADES DE SINDICATOS", value: "9420" },
  { text: "9491 - ACTIVIDADES DE ORGANIZACIONES RELIGIOSAS", value: "9491" },
  { text: "9492 - ACTIVIDADES DE ORGANIZACIONES POLÍTICAS", value: "9492" },
  { text: "9499 - ACTIVIDADES DE OTRAS ASOCIACIONES N.C.P.", value: "9499" },
  {
    text: "9511 - REPARACIÓN DE ORDENADORES Y EQUIPO PERIFÉRICO",
    value: "9511",
  },
  { text: "9512 - REPARACIÓN DE EQUIPOS COMUNICACIONALES", value: "9512" },
  {
    text: "9521 - REPARACIÓN DE APARATOS ELECTRÓNICOS DE CONSUMO",
    value: "9521",
  },
  {
    text: "9522 - REPARACIÓN DE APARATOS DE USO DOMÉSTICO Y EQUIPO DOMÉSTICO Y DE JARDINERÍA",
    value: "9522",
  },
  { text: "9523 - REPARACIÓN DE CALZADO Y ARTÍCULOS DE CUERO", value: "9523" },
  {
    text: "9524 - REPARACIÓN DE MUEBLES Y ACCESORIOS DOMÉSTICOS",
    value: "9524",
  },
  {
    text: "9529 - REPARACIÓN DE OTROS EFECTOS PERSONALES Y ENSERES DOMÉSTICOS",
    value: "9529",
  },
  {
    text: "9601 - LAVADO Y LIMPIEZA, INCLUIDA LA LIMPIEZA EN SECO, DE PRODUCTOS TEXTILES Y DE PIEL",
    value: "9601",
  },
  { text: "9602 - PELUQUERÍA Y OTROS TRATAMIENTOS DE BELLEZA", value: "9602" },
  { text: "9603 - POMPAS FÚNEBRES Y ACTIVIDADES CONEXAS", value: "9603" },
  {
    text: "9609 - OTRAS ACTIVIDADES DE SERVICIOS PERSONALES N.C.P.",
    value: "9609",
  },
  {
    text: "9700 - ACTIVIDADES DE LOS HOGARES COMO EMPLEADORES DE PERSONAL DOMÉSTICO",
    value: "9700",
  },
  {
    text: "9900 - ACTIVIDADES DE ORGANIZACIONES Y ÓRGANOS EXTRATERRITORIALES",
    value: "9900",
  },
  {
    text: "0722  - EXTRACCIÓN DE ORO Y OTROS METALES PRECIOSOS",
    value: "0722 ",
  },
  { text: "0723  - EXTRACCIÓN DE MINERALES DE NÍQUEL", value: "0723 " },
  {
    text: "0811  - EXTRACCIÓN DE PIEDRA, ARENA, ARCILLAS COMUNES, YESO Y ANHIDRITA",
    value: "0811 ",
  },
  { text: "2421 - INDUSTRIAS BÁSICAS DE METALES PRECIOSOS", value: "2421" },
  {
    text: "2429 - INDUSTRIAS BÁSICAS DE OTROS METALES NO FERROSOS",
    value: "2429",
  },
  {
    text: "3210 - FABRICACIÓN DE JOYAS, BISUTERÍA Y ARTÍCULOS CONEXOS",
    value: "3210",
  },
  { text: "6494 - OTRAS ACTIVIDADES DE DISTRIBUCIÓN DE FONDOS", value: "6494" },
  {
    text: "6614 - ACTIVIDADES DE LAS SOCIEDADES DE INTERMEDIACIÓN CAMBIARIA Y DE SERVICIOS FINANCIEROS ESPECIALES",
    value: "6614",
  },
  {
    text: "6615 - ACTIVIDADES DE LOS PROFESIONALES DE COMPRA Y VENTA DE DIVISAS",
    value: "6615",
  },
  {
    text: "4541 - COMERCIO DE MOTOCICLETAS Y DE SUS PARTES, PIEZAS Y ACCESORIOS",
    value: "4541",
  },
  { text: "4112 - CONSTRUCCIÓN DE EDIFICIOS NO RESIDENCIALES", value: "4112" },
  { text: "5511 - ALOJAMIENTO EN HOTELES", value: "5511" },
  { text: "5512 - ALOJAMIENTO EN APARTAHOTELES", value: "5512" },
  {
    text: "8414 - ACTIVIDADES REGULADORAS Y FACILITADORAS DE LA ACTIVIDAD ECONÓMICA",
    value: "8414",
  },
  { text: "8551 - FORMACIÓN PARA EL TRABAJO", value: "8551" },
  {
    text: "1690 - FABRICACIÓN DE OTROS PRODUCTOS DE MADERA; FABRICACIÓN DE ARTÍCULOS DE CORCHO, CESTERÍA Y ESPARTERÍA",
    value: "1690",
  },
  { text: "2229 - FABRICACIÓN DE ARTÍCULOS DE PLÁSTICO N.C.P.", value: "2229" },
  {
    text: "4774 - COMERCIO AL POR MENOR DE OTROS PRODUCTOS NUEVOS EN ESTABLECIMIENTOS ESPECIALIZADOS",
    value: "4774",
  },
  {
    text: "6399 - OTRAS ACTIVIDADES DE SERVICIOS DE INFORMACIÓN N.C.P.",
    value: "6399",
  },
  {
    text: "8621 - ACTIVIDADES DE LA PRÁCTICA MÉDICA, SIN INTERNACIÓN",
    value: "8621",
  },
  { text: "1084 - ELABORACIÓN DE COMIDAS Y PLATOS PREPARADOS", value: "1084" },
  {
    text: "1521 - FABRICACIÓN DE CALZADO DE CUERO Y PIEL, CON CUALQUIER TIPO DE SUELA",
    value: "1521",
  },
  {
    text: "4631 - COMERCIO AL POR MAYOR DE PRODUCTOS ALIMENTICIOS",
    value: "4631",
  },
  {
    text: "4729 - COMERCIO AL POR MENOR DE OTROS PRODUCTOS ALIMENTICIOS N.C.P., EN ESTABLECIMIENTOS ESPECIALIZADOS",
    value: "4729",
  },
  { text: "8544 - EDUCACIÓN DE UNIVERSIDADES10", value: "8544" },
  { text: "8552 - ENSEÑANZA DEPORTIVA Y RECREATIVA", value: "8552" },
  { text: "8692 - ACTIVIDADES DE APOYO TERAPÉUTICO", value: "8692" },
  {
    text: "8699 - OTRAS ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA",
    value: "8699",
  },
  { text: "4512 - COMERCIO DE VEHÍCULOS AUTOMOTORES USADOS", value: "4512" },
  {
    text: "5619 - OTROS TIPOS DE EXPENDIO DE COMIDAS PREPARADAS N.C.P.",
    value: "5619",
  },
  { text: "8559 - OTROS TIPOS DE EDUCACIÓN N.C.P.", value: "8559" },
  {
    text: "6431 - FIDEICOMISOS, FONDOS Y ENTIDADES FINANCIERAS SIMILARES",
    value: "6431",
  },
  {
    text: "3511 - GENERACIÓN DE ENERGÍA ELÉCTRICA EN OTRAS CENTRALES N.C.P",
    value: "3511",
  },
  { text: "4812 - SERVICIO TELEFONIA Y EQUIPOS", value: "4812" },
  {
    text: "8543 - ENSEÑANZA SUPERIOR EN UNIVERSIDADES PRIVADAS",
    value: "8543",
  },
  {
    text: "1089 - ELABORACIÓN DE TÉ, CAFÉ, MATE E INFUSIONES DE HIERBAS",
    value: "1089",
  },
  { text: "5964 - VENTA POR CATALOGO (BOTON DE PAGOS)", value: "5964" },
  {
    text: "4900 - SERVICIOS PUBLICOS (LUZ, AGUA, TELEFONO, ALCANTARILLADO, GAS)",
    value: "4900",
  },
  { text: "7298 - GIMNASIOS - CLUB DE SALUD Y ADELGAZAMIENTO", value: "7298" },
  { text: "4511 - AEROLINEAS", value: "4511" },
  { text: "8999 - SERVICIOS PROFESIONALES", value: "8999" },
  { text: "6300 - VENTA DE SEGUROS", value: "6300" },
  { text: "5311 - TIENDAS DEPARTAMENTALES", value: "5311" },
];
