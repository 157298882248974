import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { TEXTS } from "../../shared/infrastructure/constants/ModalTexts";
import {
  WindowSizeEnum,
  Direction,
} from "../../shared/infrastructure/constants/WindowSizeEnum";

export interface ModalPreauthConfirmationProps {
  open: boolean;
  clearEventsExceptPreauth?: () => void;
  handleCloseModalPreauth: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction={Direction.UP} ref={ref} {...props} />;
});

export const ModalPreauthConfirmation: React.FC<ModalPreauthConfirmationProps> =
  (props: ModalPreauthConfirmationProps) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down(WindowSizeEnum.SM));
    return (
      <React.Fragment>
        <Dialog
          open={props.open}
          fullScreen={fullScreen}
          TransitionComponent={Transition}
          maxWidth={WindowSizeEnum.SM}
          fullWidth
        >
          <DialogTitle>
            <Typography color={"primary"}>
              {TEXTS.preauthConfirmationTitle}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              spacing={1}
              justify="flex-start"
              alignItems="flex-start"
            >
              <Typography>{TEXTS.preauthConfirmationMainText}</Typography>
              <Typography style={{ marginTop: "2px" }}>
                {TEXTS.preauthConfirmationQuestion}
              </Typography>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleCloseModalPreauth}>Cancelar</Button>
            <Button color="primary" onClick={props.clearEventsExceptPreauth}>
              Si, confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  };
