import React from "react";
import {
  Button,
  ButtonGroup,
  Grid,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useHistory } from "react-router-dom";
import {
  buildOptionsFormatWithCountry,
  FormatUpload,
} from "../../shared/infrastructure/Enums/FormatUpload";
import { CountryValueEnum } from "../../shared/infrastructure/Enums/CountryEnum";
const useStyles = makeStyles({
  btnPopover: {
    margin: "35px 0 0 0",
    display: "flex",
    maxWidth: "320px",
  },
  btnChild: {
    padding: "15px 25px",
  },
  btnArrow: {
    padding: "0px",
    borderLeft: "1px solid #FFFFFF",
  },
  text: {
    fontSize: "18px",
    textAlign: "center",
  },
  menuItem: {
    minWidth: "320px",
  },
});

export const UploadSelectableEC: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Grid item xs={7}>
        <Typography variant={"h5"} className={classes.text}>
          Carga los impuestos y retenciones{" "}
          <strong>subiendo un archivo .csv</strong> (sin límite de registros) o
          de manera manual <strong>llenando un formulario</strong>
          (máximo 20 registros por carga).
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <ButtonGroup
          variant="contained"
          color="primary"
          size={"medium"}
          onClick={handleClick}
          className={classes.btnPopover}
        >
          <Button className={classes.btnChild}>
            <Typography variant="h6">Cargar impuestos y retenciones</Typography>
          </Button>
          <Button className={classes.btnArrow}>
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuList id="split-button-menu">
            {buildOptionsFormatWithCountry(CountryValueEnum.Ecuador, false).map(
              (option) => (
                <MenuItem
                  className={classes.menuItem}
                  key={option.value}
                  onClick={() => {
                    handleClose();
                    history.push(option.url);
                  }}
                  disabled={option.value === FormatUpload.form}
                >
                  {option.name}
                </MenuItem>
              )
            )}
          </MenuList>
        </Popover>
      </Grid>
    </React.Fragment>
  );
};
