import React from "react";
import { RulesInformationProps } from "./RulesInformation.interfaces";
import { RulesInformationData } from "./RulesInformation.data";
import { IconAlertTriangle } from "@kushki/connect-ui";
import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../shared/infrastructure/routes";
import { getBasicMerchantInformation } from "../../../shared/utils/local-storage/basic-merchant-information";

export const RulesInformation: React.FC<RulesInformationProps> = ({
  existMoreThanFiveRules,
  isPendingDraft,
  isBatch,
  hasPayouts,
}: RulesInformationProps) => {
  const { publicMerchantId } = getBasicMerchantInformation();
  const ruleListRoute = (
    publicMerchantId: string,
    isBatch?: boolean,
    hasPayouts?: boolean
  ): string => {
    if (isBatch) {
      return hasPayouts
        ? AppRoutes.PAYOUT_RULES_LIST_MASSIVE_BATCH
        : AppRoutes.RULES_LIST_MASSIVE_BATCH;
    } else {
      return hasPayouts
        ? AppRoutes.PAYOUT_RULES_LIST(publicMerchantId)
        : AppRoutes.RULES_LIST(publicMerchantId);
    }
  };

  const navigate = useNavigate();
  return (
    <Grid container justifyContent="space-between" paddingBottom={"1rem"}>
      <Grid item display={"flex"} alignItems={"center"}>
        {isPendingDraft && (
          <>
            <IconAlertTriangle color={"secondary"} />
            <Typography paddingLeft={"1rem"} variant={"caption"} color={"gray"}>
              {RulesInformationData.DraftRulesMessage}
            </Typography>
          </>
        )}
      </Grid>
      <Grid paddingRight={"1rem"} item display={"flex"} alignItems={"center"}>
        {existMoreThanFiveRules && (
          <Button
            className={"buttonLink"}
            color="primary"
            onClick={() =>
              navigate(ruleListRoute(publicMerchantId, isBatch, hasPayouts))
            }
          >
            {RulesInformationData.MoreRulesMessage}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
