import React, { useEffect, useState } from "react";
import { IUseFilterComponentState } from "../../../shared/infrastructure/interfaces/useFilterComponentState.interface";
import {
  chipStatus,
  FilterProperties,
  FilterSortEnum,
} from "../../../shared/infrastructure/constants/RequestDispersionConstants";
import { get, isEmpty, omit } from "lodash";
import { FilterDispersionRequest } from "../../../../types/filter_dispersion_request";
import { FilterEnum } from "../../../shared/infrastructure/constants/FilterEnum";
import moment from "moment";
import { IAppState } from "../../../store/reducer";
import { ValidateFilesResponse } from "../../../../types/validate_files_response";
import { useDispatch, useSelector } from "react-redux";
import {
  getProcessFileDispersion,
  setFilters,
} from "../../../store/actionCreators";

export const useFilterComponentState = (): IUseFilterComponentState => {
  const dispatch = useDispatch();
  const [amountValue, setAmountValue] = useState<string>(FilterEnum.MAX);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [defaultExpanded, setDefaultExpanded] = useState<boolean>(false);
  const [defaultExpandedStatus, setDefaultExpandedStatus] =
    useState<boolean>(false);
  const [defaultExpandedAmount, setDefaultExpandedAmount] =
    useState<boolean>(false);
  const [onProcessSelected, setOnProcessSelected] = useState<boolean>(false);
  const [processedSelected, setProcessedSelected] = useState<boolean>(false);
  const [generalAmountValue, setGeneralAmountValue] = useState<string>("");
  const [eqAmountValueMin, setEqAmountValueMin] = useState<string>("");
  const [eqAmountValueMax, setEqAmountValueMax] = useState<string>("");
  const [filterDispersionRequest, setFilterDispersionRequest] =
    useState<FilterDispersionRequest | null>(null);
  const validateFileResponse: ValidateFilesResponse | undefined = useSelector(
    (state: IAppState) => state.validateFilesResponse
  );
  const filterDispersionRequestState: FilterDispersionRequest | undefined =
    useSelector((state: IAppState) => state.filterDispersionRequest);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const handleCloseMenu = (): void => {
    setAnchor(null);
  };

  const handleClickFilter = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setAnchor(event.currentTarget);
  };

  const handleExpandedOrCollapsed = (): void => {
    setDefaultExpanded(!defaultExpanded);
    setDefaultExpandedStatus(!defaultExpandedStatus);
    setDefaultExpandedAmount(!defaultExpandedAmount);
  };

  const handleExpandedOrCollapsedStatus = (): void => {
    setDefaultExpandedStatus(!defaultExpandedStatus);
  };

  const handleExpandedOrCollapsedAmount = (): void => {
    setDefaultExpandedAmount(!defaultExpandedAmount);
  };

  const isChipSelected = (data: string): boolean => {
    return (
      (onProcessSelected && data === chipStatus[1]) ||
      (processedSelected && data === chipStatus[0])
    );
  };

  const handleClick = (data: string): void => {
    if (data === chipStatus[1]) {
      setOnProcessSelected(!onProcessSelected);
      setProcessedSelected(false);
    }

    if (data === chipStatus[0]) {
      setProcessedSelected(!processedSelected);
      setOnProcessSelected(false);
    }
  };

  const handleOnchangeSelect = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ): void => {
    setAmountValue(getTargetValue(event));
  };

  const handleOnchangeGeneralAmount = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setGeneralAmountValue(getTargetValue(event));
  };

  const handleEqMinAmount = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setEqAmountValueMin(getTargetValue(event));
  };

  const handleEqMaxAmount = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setEqAmountValueMax(getTargetValue(event));
  };

  const getTargetValue = (
    event:
      | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ): string => {
    return get(event, "target.value", "");
  };

  const handleDeleteFilters = (): void => {
    setOnProcessSelected(false);
    setProcessedSelected(false);
    setAmountValue(FilterEnum.MAX);
    setGeneralAmountValue("");
    setEqAmountValueMin("");
    setEqAmountValueMax("");
  };

  const handleApplyFilters = (): void => {
    setAnchor(null);

    if (filterDispersionRequest) {
      dispatch(
        setFilters({
          ...filterDispersionRequest,
          ...filterDispersionRequestState,
        })
      );
      dispatch(getProcessFileDispersion(filterDispersionRequest));
      setPageNumber(0);
      setPageSize(10);
    }
  };

  const handlePagination = (pageSize: number, pageNumber: number): void => {
    setAnchor(null);

    if (filterDispersionRequestState) {
      const newFilterDispersionRequest: FilterDispersionRequest = {
        ...filterDispersionRequestState,
        limit: pageSize,
        offset: pageNumber * pageSize,
      };
      setPageSize(pageSize);
      setPageNumber(pageNumber);
      setFilterDispersionRequest(newFilterDispersionRequest);
      dispatch(getProcessFileDispersion(newFilterDispersionRequest));
    }
  };

  useEffect(() => {
    const yesterday: string = moment().subtract(1, "day").format("YYYY-MM-DD");
    const today: string = moment(new Date()).format("YYYY-MM-DD");
    const filterStateFrom: string | undefined =
      filterDispersionRequestState?.from;
    const filterStateTo: string | undefined = filterDispersionRequestState?.to;
    let status: FilterProperties["status"] = undefined;
    let type: FilterProperties["type"] =
      amountValue as FilterProperties["type"];
    let min: string | undefined =
      type === FilterEnum.MIN
        ? generalAmountValue
        : type === FilterEnum.RANGE
        ? eqAmountValueMin
        : undefined;
    let max: string | undefined =
      type === FilterEnum.MAX
        ? generalAmountValue
        : type === FilterEnum.RANGE
        ? eqAmountValueMax
        : undefined;
    let eq: string | undefined =
      type === FilterEnum.EQ ? generalAmountValue : undefined;

    if (onProcessSelected) {
      status = FilterEnum.PROCESSING;
    }

    if (processedSelected) {
      status = FilterEnum.PROCESSED;
    }

    let filterDispersionRequest: FilterDispersionRequest = {
      from: filterStateFrom ? filterStateFrom : `${yesterday}T00:00:00`,
      to: filterStateTo ? filterStateTo : `${today}T23:59:59`,
      limit: pageSize,
      offset: pageNumber,
      referenceNumber: get(validateFileResponse, "referenceNumber"),
      sort: {
        field: FilterSortEnum.CREATED,
        order: FilterSortEnum.ASC,
      },
      filter: {
        status,
        rangeAmount: {
          type,
          min: Number(min),
          max: Number(max),
          eq: Number(eq),
        },
      },
    };

    if (isEmpty(status)) {
      filterDispersionRequest = omit(filterDispersionRequest, "filter.status");
    }

    if (isEmpty(min)) {
      filterDispersionRequest = omit(
        filterDispersionRequest,
        "filter.rangeAmount.min"
      );
    }

    if (isEmpty(max)) {
      filterDispersionRequest = omit(
        filterDispersionRequest,
        "filter.rangeAmount.max"
      );
    }

    if (isEmpty(eq)) {
      filterDispersionRequest = omit(
        filterDispersionRequest,
        "filter.rangeAmount.eq"
      );
    }

    dispatch(
      setFilters({
        ...filterDispersionRequestState,
        ...filterDispersionRequest,
      })
    );
    setFilterDispersionRequest(filterDispersionRequest);
  }, [
    onProcessSelected,
    processedSelected,
    amountValue,
    generalAmountValue,
    eqAmountValueMin,
    eqAmountValueMax,
  ]);

  return {
    anchor,
    defaultExpanded,
    defaultExpandedStatus,
    defaultExpandedAmount,
    amountValue,
    generalAmountValue,
    eqAmountValueMin,
    eqAmountValueMax,
    pageSize,
    pageNumber,
    handleCloseMenu,
    handleClickFilter,
    handleExpandedOrCollapsed,
    handleExpandedOrCollapsedStatus,
    handleExpandedOrCollapsedAmount,
    isChipSelected,
    handleClick,
    handleOnchangeSelect,
    handleOnchangeGeneralAmount,
    handleEqMinAmount,
    handleEqMaxAmount,
    handleApplyFilters,
    handleDeleteFilters,
    handlePagination,
  };
};
