/* istanbul ignore file */
import React from "react";
import { applyMiddleware, compose, createStore, Store } from "redux";
import thunk from "redux-thunk";
// @ts-ignore
import Normalize from "react-normalize";
import { reducer } from "./store/reducer";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import BillingDashboard from "./containers/BillingDashboard/BillingDashboard";
import { environment } from "./environments/environment";
import esLocale from "date-fns/locale/es";
import { LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import BillingValidatorDashboard from "./containers/BillingValidatorDashboard/BillingValidatorDashboard";
import BillingInvoice from "./containers/BillingInvoice/BillingInvoice";
import { BillingManualContingency } from "./containers/ManualContingency/BillingManualContingency";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
import { JwtComponent } from "./shared/useJWTUtils";

const composeEnhancers = environment.devTools
  ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose;

const store: Store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunk))
);

const Root = () => {
  return (
    <React.StrictMode>
      <Normalize />
      <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
        <Provider store={store}>
          <JwtComponent />
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <ModuleSecurityWrapper
                basePath={environment.kushkiUrl}
                componentId={"M_BILLING"}
              >
                <Switch>
                  <Route
                    exact
                    path="/billing-dashboard/executor"
                    component={BillingDashboard}
                  />
                  <Route
                    exact
                    path="/billing-dashboard/validator"
                    component={BillingValidatorDashboard}
                  />
                  <Route
                    exact
                    path="/billing-dashboard/invoice"
                    component={BillingInvoice}
                  />
                  <Route
                    exact
                    path="/billing-dashboard/manual-contingency"
                    component={BillingManualContingency}
                  />
                </Switch>
              </ModuleSecurityWrapper>
            </BrowserRouter>
          </ThemeProvider>
        </Provider>
      </LocalizationProvider>
    </React.StrictMode>
  );
};

export default Root;
