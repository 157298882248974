import React, { useEffect, useState } from "react";
import preferences from "../../assets/images/preferences.svg";
import {
  Avatar,
  Container,
  Typography,
  Box,
  Paper,
  Card,
  CardContent,
  Grid,
  Button,
  Divider,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { LogoSelect } from "../../components/LogoSelect/LogoSelect";
import { useAccountPreferencesState } from "./state/useAccountPreferencesState";
import { LogoUpload } from "../../components/LogoUpload/LogoUpload";
import { IAppState } from "../../store/reducer";
import { MerchantResponse } from "../../../types/merchant_response";
import { connect } from "react-redux";
import { KshColorPicker } from "../../components/KshColorPicker/KshColorPicker";
import { Dispatch } from "redux";
import { SmartlinksCustomization } from "../../../types/smartlink_customization";
import { ThunkDispatch } from "redux-thunk";
import {
  getAppConfigPaymentMethods,
  IAppAction,
  setMerchantCustomization,
  setSmartlink,
} from "../../store/actionCreators";
import { auth } from "../../shared/auth";
import { routes } from "../../shared/infrastructure/constants/routes";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { SmartlinkResponse } from "../../../types/smartlink_get_response";
import { SmartlinkRequest } from "../../../types/smartlink_post_request";
import AccountStyle from "./styles/AccountPreferencesStyle";
import { ConfigurationSubdomainPopUp } from "../../components/ConfirmationSubdomainPopUp/ConfigurationSubdomainPopUp";
import instance from "../../shared/axios-util";
import { AxiosResponse } from "axios";
import { environment } from "../../environments/environment";
import { Subdomain } from "../../components/Subdomain/Subdomain";
import { SecurityWrapperEnum } from "../../shared/infrastructure/constants/SecurityWrapperEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { LOGO_UPLOAD_LABELS } from "../../shared/infrastructure/constants/LogoUploadConstants";
// @ts-ignore
const useStyles = makeStyles({
  ...AccountStyle,
});

export interface AccountPreferencesIndexStateProps {
  customization?: MerchantResponse;
  smartLinkInfo?: SmartlinkResponse;
}

export interface AccountPreferencesIndexFunctionProps {
  setMerchantCustomization: (
    merchantId: string,
    body: SmartlinksCustomization
  ) => void;
  setSmartLink: (body: SmartlinkRequest) => void;
}

export type AccountPreferencesIndexProps = AccountPreferencesIndexStateProps &
  AccountPreferencesIndexFunctionProps;

export const AccountPreferencesIndex: React.FC<AccountPreferencesIndexProps> = (
  props: AccountPreferencesIndexProps
) => {
  const styles = useStyles();
  const history = useHistory();
  const returnHandler = () => {
    history.goBack();
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [subdomainError, setSubdomainError] = useState<boolean | null>(false);
  const [
    showSubdomainConfirmation,
    setShowSubdomainConfirmation,
  ] = useState<boolean>(false);
  const [isSubdomainChanged, setIsSubdomainChanged] = useState<boolean>(false);
  const [currentSubdomain, setcurrentSubdomain] = useState<string>("");

  const updateSubdomain = (newSubdomain: string) => {
    setcurrentSubdomain(newSubdomain);
    setIsSubdomainChanged(true);
    handler.handlerSetValue(newSubdomain, "smartLinkValue");
  };
  const handleValidateSubdomain = async (subdomain: string) => {
    if (subdomain === "") {
      setSubdomainError(null);
      return;
    }

    const subdomainRegex = new RegExp(/^[a-z0-9]+$/);

    if (!subdomainRegex.test(subdomain)) {
      setSubdomainError(true);
      return;
    }

    instance
      .post(
        `${environment.kushkiUrl}/smartlink/v1/admin/subdomain/${subdomain}`
      )
      .then((response: AxiosResponse<any>) => {
        setSubdomainError(!response.data);
      })
      .catch(() => {
        setSubdomainError(true);
      });
  };

  const {
    accountPreference,
    handler,
    form,
    currentColorPicker,
  } = useAccountPreferencesState({
    merchant: props.customization,
    setMerchantCustomization: props.setMerchantCustomization,
    smartLinkInfo: props.smartLinkInfo,
    setSmartlink: props.setSmartLink,
  });
  useEffect(() => {
    getAppConfigPaymentMethods();
  }, []);
  const isSubdomainEnabled = () => {
    if (!props.smartLinkInfo) return false;

    const status: boolean = props.smartLinkInfo?.status === true;
    const webheckoutStatus: boolean =
      props.smartLinkInfo?.webcheckoutStatus === true;
    const isSubdomainOnProps: boolean = props.smartLinkInfo?.subdomain !== "";

    return status || webheckoutStatus || isSubdomainOnProps;
  };

  const getBreadcrumbs = (
    isAdmin: boolean
  ): { label: string; url: string }[] => {
    return isAdmin
      ? [
          { label: "Comercios", url: routes.MERCHANT_LIST },
          {
            label: "Configuración",
            url: `${routes.INDEX}/merchant/${props.customization?.publicMerchantId}`,
          },
        ]
      : [
          {
            label: "Configuración",
            url: `${routes.INDEX}/merchant/${props.customization?.publicMerchantId}`,
          },
        ];
  };
  const breadcrumbs = getBreadcrumbs(auth.isAdmin());
  return (
    <React.Fragment>
      <Container fixed>
        <Breadcrumb items={breadcrumbs} lastItem={"Preferencias de Cuenta"} />
        <Typography className={styles.indexTitle}>
          Preferencias de Cuenta
        </Typography>
        <Typography className={styles.commerceTitle}>
          {props.customization?.name}
        </Typography>
        <Paper>
          <Card>
            <CardContent style={{ marginLeft: 15 }}>
              <Box display="flex" flexGrow={1} mt={1} alignItems="flex-start">
                <Box display="flex" flexGrow={0} pl={1}>
                  <Avatar src={preferences} />
                </Box>
                <Box
                  display="flex"
                  flexGrow={1}
                  pl={3}
                  pt={1}
                  alignItems="flex-start"
                >
                  <Box display="flex" flexGrow={1} flexDirection="column">
                    <Box mb={2}>
                      <Typography className={styles.cardTitle}>
                        Preferencias de cuenta
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Grid container style={{ paddingBottom: 30 }}>
                <Grid item xs={12}>
                  <Typography
                    className={styles.cardTitle}
                    style={{ paddingBottom: 10, paddingTop: 48 }}
                  >
                    Personalización de la marca
                  </Typography>
                  <Typography className={styles.message}>
                    Puedes agregar una imagen que se mostrará como logotipo del
                    comercio en los productos que tengas activados. De no contar
                    con una imagen cargada, se mostrará el logotipo de Kushki
                    por defecto.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={10} lg={8}>
                  <Grid container>
                    {!auth.isAdmin() && (
                      <React.Fragment>
                        <Grid item sm={12} xs={12}>
                          <LogoUpload
                            prop="logos"
                            files={accountPreference.logos}
                            onChange={handler.handleFileChange}
                            message={LOGO_UPLOAD_LABELS.informationMessage}
                          />
                        </Grid>
                        {accountPreference.logos.length > 0 && (
                          <Grid item xs={12} style={{ marginTop: 28 }}>
                            <LogoSelect
                              accountPreference={accountPreference}
                              setValueHandler={handler.handlerSetValue}
                              removeHandler={handler.handlerRemoveLogo}
                              editLogoNameHandler={handler.handlerEditLogoName}
                            />
                          </Grid>
                        )}
                      </React.Fragment>
                    )}
                    <Grid
                      item
                      xs={12}
                      style={{ paddingTop: 30, paddingBottom: 15 }}
                    >
                      <Typography className={styles.sectionTitle}>
                        Colores de la marca
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        style={{
                          flexWrap: "wrap",
                        }}
                      >
                        <Box width={{ xs: "100%", sm: "45%" }}>
                          <KshColorPicker
                            prop={"mainColor"}
                            color={accountPreference.mainColor}
                            setValueHandler={handler.handlerSetValue}
                            label="Color principal"
                            selectedPicker={currentColorPicker}
                            setSelectedPicker={handler.setCurrentColorPicker}
                          />
                        </Box>
                        <Box width={{ xs: "0%", sm: "10%" }}>{""}</Box>
                        <Box width={{ xs: "100%", sm: "45%" }}>
                          <KshColorPicker
                            prop={"secondaryColor"}
                            color={accountPreference.secondaryColor}
                            setValueHandler={handler.handlerSetValue}
                            label="Color secundario"
                            selectedPicker={currentColorPicker}
                            setSelectedPicker={handler.setCurrentColorPicker}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {isSubdomainEnabled() ? (
                <Subdomain
                  isPrimary={environment.envName === "primary"}
                  setSubdomain={updateSubdomain}
                  validateSubdomain={handleValidateSubdomain}
                  styles={styles}
                  currentUrl={props.smartLinkInfo?.subdomain}
                  error={subdomainError}
                />
              ) : null}

              <Divider variant="fullWidth" />

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "20px",
                      lineHeight: "140%",
                      color: " #023365",
                      paddingTop: 49,
                    }}
                  >
                    Información de soporte
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    id="emailField"
                    name="emailField"
                    variant="outlined"
                    label="Email"
                    value={accountPreference.email}
                    inputRef={form.register({
                      pattern: {
                        value: new RegExp(
                          "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$"
                        ),
                        message: "Email no válido",
                      },
                    })}
                    helperText={
                      form.errors.emailField
                        ? form.errors.emailField.message
                        : ""
                    }
                    error={!!form.errors.emailField}
                    onChange={(e) => {
                      handler.handlerSetValue(e.target.value, "email");
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    id="phoneField"
                    name="phoneField"
                    variant="outlined"
                    label="Teléfono"
                    value={accountPreference.phone}
                    onChange={(e) => {
                      handler.handlerSetValue(e.target.value, "phone");
                    }}
                    inputRef={form.register({
                      pattern: {
                        value: new RegExp("^[0-9]{7,13}$"),
                        message: "Teléfono inválido",
                      },
                    })}
                    helperText={
                      form.errors.phoneField
                        ? form.errors.phoneField.message
                        : ""
                    }
                    error={!!form.errors.phoneField}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={styles.kshMultiline}
                    id="termsField"
                    name="termsField"
                    variant="outlined"
                    label="Términos y condiciones"
                    fullWidth
                    multiline
                    value={accountPreference.termsAndConditions}
                    helperText={
                      form.errors.termsField
                        ? form.errors.termsField.message
                        : ""
                    }
                    error={!!form.errors.termsField}
                    onChange={(e) => {
                      handler.handlerSetValue(
                        e.target.value,
                        "termsAndConditions"
                      );
                    }}
                    rows={4}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Paper>
        <Box display={"flex"} justifyContent="space-between" pt={2}>
          <Box px={1}>
            <Button
              id="returnBtn"
              onClick={() => {
                returnHandler();
              }}
              className={styles.returnLabel}
            >
              Regresar
            </Button>
          </Box>
          <Box px={1}>
            <ComponentSecurityWrapper
              componentId={SecurityWrapperEnum.SAVE_ACCOUNT_PREFERENCES}
            >
              <Button
                id="continueBtn"
                variant="contained"
                color="primary"
                className={styles.continueBtn}
                disabled={isLoading}
                onClick={() => {
                  if (isSubdomainChanged) {
                    setShowSubdomainConfirmation(true);
                    return;
                  }
                  setIsLoading(true);
                  handler.handlerContinue(true);
                }}
              >
                Guardar
                {isLoading ? (
                  <CircularProgress color="primary" size={16} />
                ) : null}
              </Button>
            </ComponentSecurityWrapper>
          </Box>
        </Box>
        <ConfigurationSubdomainPopUp
          show={showSubdomainConfirmation}
          closePopUp={() => {
            setShowSubdomainConfirmation(false);
            setIsLoading(true);
            handler.handlerContinue();
          }}
          cancel={() => {
            setShowSubdomainConfirmation(false);
            updateSubdomain(`${props.smartLinkInfo?.subdomain}`);
            setIsLoading(true);
            handler.handlerContinue();
          }}
          subdomain={currentSubdomain}
        />
      </Container>
    </React.Fragment>
  );
};

export const mapStateToProps: (
  state: IAppState
) => AccountPreferencesIndexStateProps = (
  state: IAppState
): AccountPreferencesIndexStateProps => ({
  customization: state.customization!,
  smartLinkInfo: state.smartLinkInfo!,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => AccountPreferencesIndexFunctionProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): AccountPreferencesIndexFunctionProps => ({
  setMerchantCustomization: (
    merchantId: string,
    body: SmartlinksCustomization
  ): void => dispatch(setMerchantCustomization(merchantId, body)),
  setSmartLink: (body: SmartlinkRequest): void => dispatch(setSmartlink(body)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountPreferencesIndex);
