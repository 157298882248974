export enum AlertEnum {
  ORIGIN_DISABLE = "disable",
  ORIGIN_DELETE = "delete",
  ORIGIN_EMPTY = "empty",
}

enum AlertMsgEnum {
  ERROR_DISABLE_MSG = "Ocurrió un error al deshabilitar la regla. Inténtalo nuevamente.",
  SUCCESS_DISABLE_MSG = "Se deshabilitó la regla con éxito.",
  ERROR_DELETE_MSG = "Ocurrió un error al eliminar la regla. Inténtalo nuevamente.",
  SUCCESS_DELETE_MSG = "Se eliminó la regla con éxito.",
  ERROR_EMPTY_RULE = "Todas las condiciones agregadas deben tener al menos un valor.",
}

export const ERROR_MESSAGE_ALERT: Record<string, string> = {
  [AlertEnum.ORIGIN_DISABLE]: AlertMsgEnum.ERROR_DISABLE_MSG,
  [AlertEnum.ORIGIN_DELETE]: AlertMsgEnum.ERROR_DELETE_MSG,
  [AlertEnum.ORIGIN_EMPTY]: AlertMsgEnum.ERROR_EMPTY_RULE,
};

export const SUCCESS_MESSAGE_ALERT: Record<string, string> = {
  [AlertEnum.ORIGIN_DISABLE]: AlertMsgEnum.SUCCESS_DISABLE_MSG,
  [AlertEnum.ORIGIN_DELETE]: AlertMsgEnum.SUCCESS_DELETE_MSG,
};
