import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { downloadFile } from "../../thunks/downloadFile/downloadFile.thunk";
import { DownloadFileResponse } from "../../../../types/download_file_response";
import { ISnackBar } from "@kushki/connect-ui";
import {
  buildNotification,
  defaultDataSnackbar,
} from "../../../shared/constants/snackbar";
import { NotificationTypeEnum } from "../../../shared/enums/SnackbarEnum";
import { get } from "lodash";

export interface IDownloadFileState {
  isLoadingDownloadFile: boolean;
  downloadFile: DownloadFileResponse;
  kindOption?: { id_registro: string; kind: string; type: string };
  notification: ISnackBar | undefined;
  notificationPDF?: boolean;
  pdfRetention?: string;
}

export const initialState: IDownloadFileState = {
  downloadFile: {
    url: "",
  },
  isLoadingDownloadFile: false,
  kindOption: { id_registro: "", kind: "", type: "" },
  notification: undefined,
  notificationPDF: false,
  pdfRetention: "",
};

export const downloadFileAppSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(downloadFile.pending, (state) => {
        state.isLoadingDownloadFile = true;
      })
      .addCase(downloadFile.fulfilled, (state, action) => {
        state.downloadFile = action.payload;
        state.isLoadingDownloadFile = false;
        state.notification = buildNotification(
          NotificationTypeEnum.SUCCESS,
          get(state, "notification", defaultDataSnackbar)
        );
      })
      .addCase(downloadFile.rejected, (state) => {
        state.isLoadingDownloadFile = false;
        state.notification = buildNotification(
          NotificationTypeEnum.FAILED,
          get(state, "notification", defaultDataSnackbar)
        );
      });
  },
  initialState,
  name: "downloadFileApp",
  reducers: {
    setKindOption: (state, action) => {
      state.kindOption = action.payload;
    },
    setNotification: (
      state: Draft<IDownloadFileState>,
      { payload }: PayloadAction<ISnackBar | undefined>
    ) => {
      state.notification = payload;
    },
    setNotificationPDF: (state, action) => {
      state.notificationPDF = action.payload;
    },
    setPdfRetention: (state, action) => {
      state.pdfRetention = action.payload;
    },
  },
});

export default downloadFileAppSlice.reducer;
