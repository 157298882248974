import { Box, Chip, Typography } from "@mui/material";
import React, { FC } from "react";
import {
  INVOICE_FRECUENCY,
  InvoiceModeCatalog,
} from "../../shared/constants/MerchantInformationConstants";
import { ParameterizationCatalog } from "../../shared/infrastructure/parameterization-enum";
import { BillingResumeStyles } from "./BillingResume.styles";
import useBillingResume from "./state/useBillingResume";
import { useSelector } from "react-redux";
import {
  selectBillingConfig,
  selectMerchantBillingInfo,
} from "../../store/selectors/selectors";
import { MissingInfoAlert } from "../MissingInfoAlert/MissingInfoAlert";
import { searchBykey } from "../../shared/utils/search";
import { CountriesEnum } from "../../shared/infrastructure/countries-enum";

const BillingResume: FC = () => {
  const { showBillingResume } = useBillingResume();
  const billingConfigData = useSelector(selectBillingConfig);
  const merchantBillingInfoData = useSelector(selectMerchantBillingInfo);

  return (
    <>
      {showBillingResume ? (
        <Box sx={BillingResumeStyles.papper}>
          <Box>
            <Typography
              color="text.dark"
              variant="subtitle2"
              sx={BillingResumeStyles.title}
            >
              Facturación{" "}
              {merchantBillingInfoData?.invoiceFrequency
                ? searchBykey(
                    merchantBillingInfoData.invoiceFrequency,
                    INVOICE_FRECUENCY
                  )
                : null}
            </Typography>
            <Typography color="text.primary" variant="overline">
              {merchantBillingInfoData?.country === CountriesEnum.MEXICO ? (
                <>
                  {merchantBillingInfoData?.invoiceFrequency
                    ? searchBykey(
                        merchantBillingInfoData.invoiceMode,
                        InvoiceModeCatalog
                      )
                    : null}
                </>
              ) : null}
            </Typography>
          </Box>
          <Box sx={BillingResumeStyles.fields}>
            {billingConfigData?.valuesBilling?.statusApproved ? (
              <>
                <Typography color="text.grey" variant="caption" fontSize={15}>
                  Facturar:
                </Typography>
                <Box>
                  {billingConfigData?.valuesBilling.elementsApproved.map(
                    (tag: string, index: number) => (
                      <Chip
                        key={`elementBilling-${index}`}
                        label={searchBykey(tag, ParameterizationCatalog)}
                        sx={BillingResumeStyles.chip}
                      />
                    )
                  )}
                </Box>
              </>
            ) : null}
          </Box>
          <Box sx={BillingResumeStyles.fields}>
            {billingConfigData?.valuesSubtractBilling?.statusApproved ? (
              <>
                <Typography color="text.grey" variant="caption" fontSize={15}>
                  Restar:
                </Typography>
                <Box>
                  {billingConfigData?.valuesSubtractBilling.elementsApproved.map(
                    (tag: string, index: number) => (
                      <Chip
                        key={`elementSubtract-${index}`}
                        label={searchBykey(tag, ParameterizationCatalog)}
                        sx={BillingResumeStyles.chip}
                      />
                    )
                  )}
                </Box>
              </>
            ) : null}
          </Box>
        </Box>
      ) : (
        <MissingInfoAlert />
      )}
    </>
  );
};

export default BillingResume;
