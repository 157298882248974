import { useEffect, useMemo, useState } from "react";
import { RegisterOptions, useForm, UseFormReturn } from "react-hook-form";
import { MerchantCompanyRequest } from "../../../../types/merchant_company_request";
import { Category } from "../../constants/information_constants";
import { CatalogsEnum } from "../../interfaces/catalogs-enum";
import {
  buildBillingData,
  findEconomicActivitySRI,
  getBillingDataInitialValue,
  getConfigById,
  getValuesPhoneNumber,
  validatePhoneNumber,
} from "../billing_data_utils";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { defaultTo, get, has, isEmpty, isEqual, isNil, set } from "lodash";
import { ErrorEnum } from "../../enums/ErrorEnum";
import {
  getCatalogZip,
  saveBillingData,
} from "../../../store/thunks/merchantBillingData/merchantBillingData.thunks";
import { TabItemStatusEnum } from "../../enum/tabItemStatusEnum";
import { useHistory, useLocation } from "react-router";
import { CountryEnum } from "../../countryEnum";
import {
  ConfigIdEnum,
  countriesWithDepartment,
} from "../../constants/initial_state_legal_details";
import { getMerchantInfo } from "../../../store/thunks/general/general.thunk";
import { ILabel } from "../../../components/FooterOptions/FooterOptions.interfaces";
import { format } from "date-fns";
import { useSnackbar } from "@kushki/connect-ui";
import { debounce } from "@mui/material";
import { FieldsEnum } from "../../enums/FieldsEnum";
import {
  setIsLoadingGetNodeInfo,
  setNotification,
} from "../../../store/actions/merchantBillingData.actions";
import { initialState } from "../../../store/reducers/merchantBillingData/merchantBillingData.slice";
import { CatalogByCountry } from "../../catalogs/GetCatalogsBilling";
import { isAvailableCountry } from "../../services/CatalogPersonTypeByCountry";
import { getPhoneInputRules } from "../../constants/billing_form_constants";
import { EntityNameEnum } from "../../enum/EntityNameEnum";
import { ErrorBillingData } from "../../enum/BasicStateMX/ErrorMessageMX";
import { MerchantBillingData } from "../../../../types/merchant_billing_data";
import { objectMerchantCompanyInfo } from "../company_data_utils";
import { IBillingDataInterfaceGeneral } from "../../interfaces/IBillingData.interface";
import { Configs } from "../../../../types/get_node_info_response";
import { isCentralizedConfig } from "../isCentralizedConfig";
import { FieldsNamesRender } from "../../enum/FieldsEnumRenderEnum";

export const useBillingDataGeneral = (
  isMassive: boolean
): IBillingDataInterfaceGeneral => {
  const query = new URLSearchParams(useLocation().search);
  const publicMerchantId: string = query.get("publicMerchantId") as string;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { showSnackbar } = useSnackbar();
  const isEdit: boolean = (query.get("operation") as string) === "edit";

  const {
    catalogSatZip,
    catalogZip,
    isLoadingBillingData,
    isLoadingGetNodeInfo,
    merchantCompany,
    merchantNodeInfo,
    nodeInfo,
    notification,
    typeButton,
    configsInfo,
    nodeInfoMassiveBilling,
  } = useAppSelector((state) => ({
    ...state.merchantCompany,
    merchantNodeInfo: state.basicDataForm.merchantNodeInfo,
    ...state.initialData,
  }));

  const { countriesInfo, headerProps, handleChangeStatusNodeInfo } =
    objectMerchantCompanyInfo(isMassive);
  const [zipCodeCatalogs, setZipCodeCatalogs] = useState<string[]>([]);
  const [zipCodeSatCatalogs, setZipCodeSatCatalogs] = useState<string[]>([]);
  const [zipCodeTimeOut, setZipCodeTimeOut] = useState<any>(0);
  const [country, setCountry] = useState<string>(CountryEnum.mexico);
  const [city, setCity] = useState<string>("");
  const [province, setProvince] = useState<string>("");
  const [municipality, setMunicipality] = useState<string>("");
  const [satCity, setSatCity] = useState<string>("");
  const [satProvince, setSatProvince] = useState<string>("");
  const [satMunicipality, setSatMunicipality] = useState<string>("");
  const [areaCode, setAreaCode] = useState<string>("");
  const [stateCode, setStateCode] = useState<string>("");
  const [populationCode, setPopulationCode] = useState<string>("");
  const [satStateCode, setSatStateCode] = useState<string>("");
  const [satPopulationCode, setSatPopulationCode] = useState<string>("");
  const [satAreaCode, setSatAreaCode] = useState<string>("");
  const [useSameInfoSat, setUseSameInfoSat] = useState<boolean>(true);
  const [showSave, setShowSave] = useState<boolean>(false);
  const [isContinue, setIsContinue] = useState<boolean>(false);
  const [isCentralizedNode, setIsCentralizedNode] = useState<boolean>(false);
  const [provincesList, setProvincesList] = useState<Category[]>([]);
  const [citiesList, setCitiesList] = useState<Category[]>([]);
  const [activeSaveButton, setActiveSaveButton] = useState<boolean>(false);
  const constitutionalCountry = get(
    isMassive ? nodeInfoMassiveBilling : nodeInfo,
    "generalInfo.constitutionalCountry"
  );
  let form: UseFormReturn<MerchantCompanyRequest> =
    useForm<MerchantCompanyRequest>({
      defaultValues: {
        ...getBillingDataInitialValue(merchantCompany),
      },
      mode: "onChange",
      reValidateMode: "onChange",
    });

  const billingConfig: Configs = get(configsInfo, "cn016");

  const addValidationPhoneNumber = () => {
    if (constitutionalCountry !== CountryEnum.mexico) {
      const validations: RegisterOptions<MerchantCompanyRequest> =
        getPhoneInputRules({})[
          isAvailableCountry(constitutionalCountry)
            ? constitutionalCountry
            : CountryEnum.generic
        ] as RegisterOptions<MerchantCompanyRequest>;

      form.register(FieldsEnum.phoneNumber, {
        ...validations,
      });
      setTimeout(() => {
        form.trigger(FieldsEnum.phoneNumber);
      }, 300);

      return;
    }
    form.register(FieldsEnum.phoneNumber, {
      required: true,
      validate: validatePhoneNumber.bind(null),
    });
  };

  const getError = (value: string, type: string) => {
    const formValue: string = defaultTo(form.getValues(value), "");

    if (formValue.length <= 0) {
      form.register(value, { required: true });
      form.setError(value, { type: type });
    }
  };

  const validateForm = (useSatInfo: boolean) => {
    addValidationPhoneNumber();
    switch (constitutionalCountry) {
      case CountryEnum.mexico:
        getError(FieldsEnum.taxRegime, ErrorEnum.required);
        getError(FieldsEnum.zipCode, ErrorEnum.required);
        getError(FieldsEnum.address, ErrorEnum.required);
        getError(FieldsEnum.phoneNumber, ErrorEnum.required);

        if (!useSatInfo) {
          getError(FieldsEnum.satAddress, ErrorEnum.required);
          getError(FieldsEnum.satPhoneNumber, ErrorEnum.required);
          getError(FieldsEnum.satZipCode, ErrorEnum.required);
        }

        return form.trigger();
      case CountryEnum.peru:
        getError(FieldsEnum.zipCode, ErrorEnum.required);
        getError(FieldsEnum.address, ErrorEnum.required);
        getError(FieldsEnum.phoneNumber, ErrorEnum.required);
        getError(FieldsEnum.department, ErrorEnum.required);
        getError(FieldsEnum.city, ErrorEnum.required);
        getError(FieldsEnum.province, ErrorEnum.required);

        return form.trigger();
      case CountryEnum.colombia:
        getError(FieldsEnum.city, ErrorEnum.required);
        getError(FieldsEnum.province, ErrorEnum.required);
        getError(FieldsEnum.zipCode, ErrorEnum.required);
        getError(FieldsEnum.address, ErrorEnum.required);
        getError(FieldsEnum.phoneNumber, ErrorEnum.required);
        getError(FieldsEnum.taxRegime, ErrorEnum.required);
        getError(FieldsEnum.obligation, ErrorEnum.required);
        getError(FieldsNamesRender.retention_ICA, ErrorEnum.required);

        return form.trigger();
      case CountryEnum.el_salvador:
        getError(FieldsEnum.province, ErrorEnum.required);
        getError(FieldsEnum.city, ErrorEnum.required);
        getError(FieldsEnum.zipCode, ErrorEnum.required);
        getError(FieldsEnum.address, ErrorEnum.required);
        getError(FieldsEnum.phoneNumber, ErrorEnum.required);

        return form.trigger();
      case CountryEnum.chile:
        getError(FieldsEnum.department, ErrorEnum.required);
        getError(FieldsEnum.province, ErrorEnum.required);
        getError(FieldsEnum.city, ErrorEnum.required);
        getError(FieldsEnum.zipCode, ErrorEnum.required);
        getError(FieldsEnum.address, ErrorEnum.required);
        getError(FieldsEnum.phoneNumber, ErrorEnum.required);

        return form.trigger();
      case CountryEnum.brasil:
        getError(FieldsEnum.province, ErrorEnum.required);
        getError(FieldsEnum.city, ErrorEnum.required);
        getError(FieldsEnum.zipCode, ErrorEnum.required);
        getError(FieldsEnum.address, ErrorEnum.required);
        getError(FieldsEnum.phoneNumber, ErrorEnum.required);

        return form.trigger();
      case CountryEnum.guatemala:
        getError(FieldsEnum.province, ErrorEnum.required);
        getError(FieldsEnum.city, ErrorEnum.required);
        getError(FieldsEnum.zipCode, ErrorEnum.required);
        getError(FieldsEnum.address, ErrorEnum.required);
        getError(FieldsEnum.phoneNumber, ErrorEnum.required);

        return form.trigger();
      case CountryEnum.ecuador:
        getError(FieldsEnum.province, ErrorEnum.required);
        getError(FieldsEnum.city, ErrorEnum.required);
        getError(FieldsEnum.zipCode, ErrorEnum.required);
        getError(FieldsEnum.address, ErrorEnum.required);

        return form.trigger();
      default:
        return form.trigger();
    }
  };

  const saveData = async (validate: boolean, status: string) => {
    const useSatInfo: boolean = defaultTo(
      form.getValues(FieldsEnum.useSameInfoSat),
      false
    );
    const validForm: boolean = validate ? await validateForm(useSatInfo) : true;

    if (validForm) {
      const billingData = buildBillingData(
        form,
        useSatInfo,
        {
          areaCode,
          city,
          municipality,
          populationCode,
          province,
          satAreaCode,
          satPopulationCode,
          satStateCode,
          stateCode,
        },
        get(nodeInfo, "merchantId", ""),
        country,
        constitutionalCountry
      );

      await dispatch(
        saveBillingData({
          merchantCompany: billingData,
          nodeId: get(nodeInfo, "nodeId", ""),
          status,
        })
      );
    }
  };

  const isFormInProgress = (): boolean =>
    !form.formState.isValid ||
    form.getValues(FieldsEnum.phoneNumber)!.length < 5 ||
    isCentralizedConfig(nodeInfo, ConfigIdEnum.CN016);

  const handleSaveInfo = async () => {
    setShowSave(true);
    const status = isFormInProgress()
      ? TabItemStatusEnum.IN_PROCESS
      : TabItemStatusEnum.COMPLETE;

    await saveData(
      false,
      status === TabItemStatusEnum.IN_PROCESS
        ? TabItemStatusEnum.PENDING
        : status
    );

    handleChangeStatusNodeInfo(status, ConfigIdEnum.CN016, publicMerchantId);
  };

  const handleNextInfo = async () => {
    setIsContinue(true);
    await saveData(true, TabItemStatusEnum.COMPLETE);
    handleChangeStatusNodeInfo(
      TabItemStatusEnum.COMPLETE,
      ConfigIdEnum.CN016,
      publicMerchantId
    );
  };
  const handleResetCologneMX = (isSat: boolean) => {
    form.setValue(isSat ? FieldsEnum.satCologne : FieldsEnum.cologne, "");
  };
  const handleGetCatalogZipcode = (isSat: boolean, zipCode: string) => {
    if (zipCodeTimeOut) clearTimeout(zipCodeTimeOut);
    handleResetCologneMX(isSat);
    setZipCodeTimeOut(
      setTimeout(() => {
        dispatch(
          getCatalogZip({
            isSat,
            zipCode,
          })
        );
      }, 1500)
    );
  };

  const handleUseSameInfoSat = (checked: boolean) => {
    setUseSameInfoSat(checked);
  };

  const revalidateCologne = (field: FieldsEnum) => {
    setTimeout(() => {
      if (!isEmpty(form.getValues(field))) form.trigger(field);
    }, 200);
  };

  const setValuesForm = (colognes: string[]) => {
    const firstCologne: string = colognes.length <= 0 ? "" : colognes[0];

    setZipCodeCatalogs(defaultTo(colognes, []));
    if (isEmpty(form.getValues(FieldsEnum.cologne)) || colognes.length <= 0)
      form.setValue(FieldsEnum.cologne, firstCologne);
    revalidateCologne(FieldsEnum.cologne);
    setCity(defaultTo(catalogZip.city, ""));
    setProvince(defaultTo(catalogZip.state, ""));
    setMunicipality(defaultTo(catalogZip.municipality, ""));
    setStateCode(defaultTo(catalogZip.stateCode, ""));
    setPopulationCode(defaultTo(catalogZip.populationCode, ""));
    setAreaCode(defaultTo(catalogZip.areaCode, ""));
  };

  const footerLabel: ILabel = {
    date: format(
      get(configsInfo, `${ConfigIdEnum.CN016}.updatedAt`, 0),
      "dd/MM/yyyy '-' HH:mm"
    ),
    editDetail: false,
    isHidden: true,
    text:
      "Modificado por " +
      get(configsInfo, `${ConfigIdEnum.CN016}.updatedBy`, ""),
  };

  const setSatValuesForm = (colognes: string[]) => {
    const firstCologne: string = colognes.length <= 0 ? "" : colognes[0];

    setZipCodeSatCatalogs(defaultTo(colognes, []));
    if (isEmpty(form.getValues(FieldsEnum.satCologne)))
      form.setValue(FieldsEnum.satCologne, firstCologne);
    revalidateCologne(FieldsEnum.satCologne);
    setSatCity(catalogSatZip.city);
    setSatProvince(catalogSatZip.state);
    setSatMunicipality(catalogSatZip.municipality);
    setSatStateCode(defaultTo(catalogSatZip.stateCode, ""));
    setSatPopulationCode(defaultTo(catalogSatZip.populationCode, ""));
    setSatAreaCode(defaultTo(catalogSatZip.areaCode, ""));
  };

  const handleShowSave = debounce(() => {
    setShowSave(false);
  }, 3000);

  const getCountryNodeInfo = (): CountryEnum => {
    return get(
      nodeInfo,
      "constitutionalCountry",
      get(
        isMassive ? nodeInfoMassiveBilling : nodeInfo,
        "generalInfo.constitutionalCountry",
        CountryEnum.mexico
      )
    );
  };

  const validateFormByField = () => {
    if (form.formState.isValid) {
      setActiveSaveButton(true);

      return;
    }
    if (Object.keys(form.formState.dirtyFields).length === 0) {
      setActiveSaveButton(false);

      return;
    }
    const { is_dirty } = getValuesPhoneNumber(
      form.getValues(FieldsEnum.phoneNumber)!
    );

    if (
      Object.keys(form.formState.dirtyFields).length === 1 &&
      has(form.formState.dirtyFields, FieldsEnum.phoneNumber)
    ) {
      setActiveSaveButton(is_dirty);

      return;
    } else if (Object.keys(form.formState.dirtyFields).length > 0) {
      setActiveSaveButton(true);
    }
  };

  const validateIfHasCatalog = (value: string) =>
    (Object.keys(CatalogByCountry) as Array<string>).includes(value);

  const isComplete: boolean = useMemo(
    () => billingConfig.status === TabItemStatusEnum.COMPLETE,
    [billingConfig.status]
  );

  const newEconomicActivitySRI: object | undefined = useMemo(() => {
    if (merchantNodeInfo.country === CountryEnum.ecuador) {
      return findEconomicActivitySRI(
        merchantNodeInfo.economicActivityId,
        get(merchantCompany, "economicActivitySRI")
      );
    }

    return undefined;
  }, [
    merchantNodeInfo.economicActivityId,
    merchantCompany.economicActivitySRI,
  ]);

  const validateLocationValueByCountry = (
    locationValue: string | undefined,
    countryLocationList: Category[]
  ) =>
    !isEmpty(
      countryLocationList.find((location) => location.value === locationValue)
    );

  const resetLocationsInputs = (
    hasDepartment: boolean,
    merchantValue: MerchantBillingData
  ) => {
    if (hasDepartment) {
      const departmentValue =
        getBillingDataInitialValue(merchantValue).department;

      if (
        !validateLocationValueByCountry(
          departmentValue,
          validateIfHasCatalog(getCountryNodeInfo())
            ? CatalogByCountry[getCountryNodeInfo()](CatalogsEnum.Departments)
            : []
        )
      ) {
        form.setValue(FieldsEnum.department, null as any);
        form.setValue(FieldsEnum.province, null as any);
        form.setValue(FieldsEnum.city, null as any);
      }

      return;
    }

    const provinceValue = getBillingDataInitialValue(merchantValue).province;

    if (
      validateIfHasCatalog(getCountryNodeInfo()) &&
      !validateLocationValueByCountry(
        provinceValue,
        CatalogByCountry[getCountryNodeInfo()](CatalogsEnum.Provinces)
      )
    ) {
      form.setValue(FieldsEnum.province, null as any);
      form.setValue(FieldsEnum.city, null as any);
    }
  };

  useEffect(() => {
    const colognes: string[] =
      typeof catalogZip.settlement === "string"
        ? [catalogZip.settlement]
        : catalogZip.settlement;

    setValuesForm(defaultTo(colognes, []));
  }, [catalogZip]);

  useEffect(() => {
    const satColognes: string[] =
      typeof catalogSatZip.settlement === "string"
        ? [catalogSatZip.settlement]
        : catalogSatZip.settlement;

    setSatValuesForm(defaultTo(satColognes, []));
  }, [catalogSatZip]);

  useEffect(() => {
    if (isMassive) return;
    dispatch(
      getMerchantInfo({ configId: ConfigIdEnum.CN001, publicMerchantId })
    );
  }, []);

  useEffect(() => {
    if (constitutionalCountry === CountryEnum.ecuador) {
      form.setValue(
        FieldsEnum.economicActivityId,
        merchantNodeInfo.economicActivityId
      );
    }
  }, [merchantNodeInfo, form.getValues(FieldsEnum.economicActivityId)]);

  useEffect(() => {
    const info = isMassive ? nodeInfoMassiveBilling : nodeInfo;
    const config = getConfigById(info, ConfigIdEnum.CN016);

    if (config) {
      dispatch(
        getMerchantInfo({
          configId: ConfigIdEnum.CN016,
          publicMerchantId: get(config, "value", ""),
        })
      );
      const entityBranch: string = isMassive
        ? get(nodeInfoMassiveBilling, "entityName", "")
        : get(nodeInfo, "entityName", "");

      const isCentralize: boolean =
        entityBranch === EntityNameEnum.BRANCH &&
        !isEmpty(get(config, "centralizedNodesId", ""));

      setIsCentralizedNode(isCentralize);
    } else if (info.configCoreId) {
      dispatch(setIsLoadingGetNodeInfo(false));
    }
    setCountry(
      isMassive
        ? get(
            nodeInfoMassiveBilling,
            "country",
            get(
              nodeInfoMassiveBilling,
              "generalInfo.country",
              CountryEnum.mexico
            )
          )
        : get(
            nodeInfo,
            "country",
            get(nodeInfo, "generalInfo.country", CountryEnum.mexico)
          )
    );
    setTimeout(() => {
      form.clearErrors(FieldsEnum.phoneNumber);
    }, 200);
  }, [isMassive ? nodeInfoMassiveBilling : nodeInfo]);

  useEffect(() => {
    if (isMassive) return;
    if (showSave && !isLoadingBillingData) {
      handleShowSave();
    } else if (!showSave && !isLoadingBillingData && isContinue) {
      setIsContinue(false);
      history.push(headerProps.tabsNodeInfo.tabs[2].redirectPath);
    }
  }, [isLoadingBillingData]);

  useEffect(() => {
    if (notification) {
      showSnackbar(notification);
      dispatch(setNotification(undefined));
    }
  }, [notification]);

  useEffect(() => {
    if (isAvailableCountry(getCountryNodeInfo()))
      setProvincesList(
        CatalogByCountry[getCountryNodeInfo()](
          CatalogsEnum.Provinces,
          form.getValues(FieldsEnum.department)
        )
      );

    setCitiesList([]);
  }, [form.getValues(FieldsEnum.department)]);

  useEffect(() => {
    const provinceValue: string | undefined = form.getValues(
      FieldsEnum.province
    );

    if (isAvailableCountry(getCountryNodeInfo()) && provinceValue)
      setCitiesList(
        CatalogByCountry[getCountryNodeInfo()](
          CatalogsEnum.Cities,
          provinceValue
        )
      );
  }, [form.getValues(FieldsEnum.province)]);

  useEffect(() => {
    const departmentValue: string | undefined = form.getValues(
      FieldsEnum.department
    );

    if (
      isAvailableCountry(getCountryNodeInfo()) &&
      ![CountryEnum.chile, CountryEnum.costa_rica, CountryEnum.peru].includes(
        getCountryNodeInfo()
      ) &&
      !!departmentValue
    )
      setCitiesList(
        CatalogByCountry[getCountryNodeInfo()](
          CatalogsEnum.Cities,
          departmentValue
        )
      );
  }, [form.getValues(FieldsEnum.department)]);

  useEffect(() => {
    if (form.watch(FieldsEnum.isPaymentAggregator) === "no") {
      form.unregister([
        FieldsEnum.economicActivityId,
        FieldsEnum.activityId,
        FieldsEnum.incomeSourceConceptId,
        FieldsEnum.useSameInfoSat,
        FieldsEnum.taxpayerTypeId,
      ]);
    } else {
      form.setValue(
        FieldsEnum.economicActivityId,
        merchantNodeInfo.economicActivityId
      );
    }
  }, [form.watch(FieldsEnum.isPaymentAggregator)]);

  useEffect(() => {
    if (useSameInfoSat) {
      form.unregister([
        FieldsEnum.satCologne,
        FieldsEnum.satStateCode,
        FieldsEnum.satZipCode,
        FieldsEnum.satPopulationCode,
        FieldsEnum.satAddress,
        FieldsEnum.satPhoneNumber,
        FieldsEnum.satAreaCode,
      ]);
    } else {
      setTimeout(() => {
        form.clearErrors(FieldsEnum.satPhoneNumber);
      }, 200);
    }
  }, [useSameInfoSat]);

  useEffect(() => {
    form.setValue(
      FieldsEnum.economicActivitySRI,
      get(newEconomicActivitySRI, "id")
    );
  }, [newEconomicActivitySRI]);

  useEffect(() => {
    if (!isEqual(merchantCompany, initialState.merchantCompany)) {
      const merchantValue: MerchantBillingData = { ...merchantCompany };

      if (
        merchantNodeInfo.country === CountryEnum.ecuador &&
        newEconomicActivitySRI
      ) {
        set(
          merchantValue,
          "economicActivitySRI",
          get(newEconomicActivitySRI, "id")
        );
      }
      form.reset(getBillingDataInitialValue(merchantValue));

      resetLocationsInputs(
        countriesWithDepartment.includes(
          get(merchantValue, "country", "") as CountryEnum
        ),
        merchantValue
      );

      const useInfoSat: boolean = has(merchantCompany, "useSameInfoSat")
        ? merchantCompany.useSameInfoSat
        : form.getValues(FieldsEnum.satCologne) ===
          form.getValues(FieldsEnum.cologne);

      setUseSameInfoSat(useInfoSat);
      if (constitutionalCountry === CountryEnum.mexico) {
        dispatch(
          getCatalogZip({
            isSat: false,
            zipCode: merchantCompany.zipCode,
          })
        );
        if (merchantCompany.useSameInfoSat === false)
          dispatch(
            getCatalogZip({
              isSat: true,
              zipCode: merchantCompany.sat!.zipCode,
            })
          );
      }
    }
  }, [isLoadingGetNodeInfo]);

  useEffect(() => {
    const { is_dirty } = getValuesPhoneNumber(
      form.getValues(FieldsEnum.phoneNumber)!
    );

    if (is_dirty) {
      addValidationPhoneNumber();
    }
  }, [form.getValues(FieldsEnum.phoneNumber)]);

  useEffect(() => {
    validateFormByField();

    const fields = Object.keys(form.formState.errors);

    for (let i = 0; i < fields.length; i++) {
      if (
        !isNil(form.formState.errors[fields[i]]) &&
        form.formState.errors[fields[i]]?.type !== "required" &&
        fields[i] !== FieldsEnum.phoneNumber
      ) {
        setActiveSaveButton(false);

        break;
      } else if (
        form.formState.errors[fields[i]]?.type === "validate" &&
        form.formState.errors[fields[i]]?.message !==
          ErrorBillingData.ADD_PHONE_NUMBER
      ) {
        setActiveSaveButton(false);
        break;
      } else setActiveSaveButton(true);
    }

    if (Object.keys(form.formState.dirtyFields).length === 0) {
      setActiveSaveButton(false);
    }

    if (isComplete) setActiveSaveButton(!isFormInProgress());
  }, [form.formState]);

  return {
    activeSaveButton,
    areaCode,
    citiesList,
    clientType: get(merchantNodeInfo, "clientType", ""),
    configsInfo,
    countriesInfo: {
      constitutionalCountry: countriesInfo.constitutionalCountry,
      country: countriesInfo.country,
    },
    country,
    economicActivityId: merchantNodeInfo.economicActivityId,
    footerLabel: !isEmpty(get(configsInfo, `${ConfigIdEnum.CN016}.updatedBy`))
      ? footerLabel
      : {
          date: "",
          editDetail: false,
          isHidden: true,
          text: "",
        },
    form,
    handleChangeStatusNodeInfo,
    handleGetCatalogZipcode,
    handleSaveInfo,
    handleShowSave,
    handleUseSameInfoSat,
    headerProps,
    infoBilling: {
      city,
      constitutionalCountry,
      country,
      municipality,
      populationCode,
      province,
      satCity,
      satMunicipality,
      satPopulationCode,
      satProvince,
      satStateCode,
      stateCode,
      useSameInfoSat,
    },
    isCentralizedNode,
    isContinue,
    isDisableEconomicActivity: isCentralizedNode,
    isEdit,
    isLoadingBillingData,
    isLoadingGetNodeInfo,
    merchantNodeInfo,
    newEconomicActivitySRI,
    nodeInfo: isMassive ? nodeInfoMassiveBilling : nodeInfo,
    primaryButton: {
      isDisabled: isFormInProgress(),
      isHidden: true,
      onAction: handleNextInfo,
    },
    provincesList,
    satAreaCode,
    satStateCode,
    secondaryButton: {
      isDisabled: !activeSaveButton || isCentralizedNode,
      isHidden: true,
      onAction: handleSaveInfo,
    },
    setIsContinue,
    setShowSave,
    showSave,
    typeButton,
    validateForm,
    zipCodeCatalogs,
    zipCodeSatCatalogs,
  };
};
