import { Container, Grid, Typography } from "@material-ui/core";
import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
import { DashboardHeader } from "../../components/DashboardHeader/DashboardHeader";
import { DashboardTabs } from "../../components/DashboardTabs/DashboardTabs";
import React from "react";
import { useRefundIndexState } from "./state/useRefundIndexState";

export const RefundIndex: React.FC = () => {
  const {
    country,
    paymentMethod,
    actions,
    breadCrumbs,
    tabs,
    state,
    pagination,
    orderSort,
    check,
    data,
    filterColumns,
    menuOptions,
    modal,
    statusFlow,
    detailsCard,
    refundBox,
    tableOptions,
    handleCloseAlertModal,
    filterProps,
    checkHistory,
    massiveTransactionModal,
    massiveTransactionModalResponse,
    actionNavBarExecutor,
    minDate,
    setActionFilter,
  } = useRefundIndexState();

  return (
    <Container fixed>
      <Grid container>
        <Grid container item xs={9} md={7} direction={"column"}>
          <Grid item>
            <Breadcrumb
              items={breadCrumbs.items}
              lastItem={breadCrumbs.lastItem}
            />
          </Grid>
          <Grid item>
            <Typography variant={"h1"} color={"primary"}>
              Reembolsos
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <DashboardHeader
        country={country}
        paymentMethod={paymentMethod}
        handleSelectCountryChange={actions.handleSelectCountryChange}
        handleSelectPaymentMethodChange={
          actions.handleSelectPaymentMethodChange
        }
      />
      <Grid container>
        <DashboardTabs
          actions={actions}
          state={state}
          pagination={pagination}
          orderSort={orderSort}
          check={check}
          data={data}
          filterColumns={filterColumns}
          menuOptions={menuOptions}
          modal={modal}
          statusFlow={statusFlow}
          detailsCard={detailsCard}
          refundBox={refundBox}
          tableOptions={tableOptions}
          handleCloseAlertModal={handleCloseAlertModal}
          filterProps={filterProps}
          checkHistory={checkHistory}
          massiveTransactionModal={massiveTransactionModal}
          massiveTransactionModalResponse={massiveTransactionModalResponse}
          actionNavBarExecutor={actionNavBarExecutor}
          minDate={minDate}
          value={tabs.value}
          handleTabsChange={tabs.handleTabsChange}
          setActionFilter={setActionFilter}
        />
      </Grid>
    </Container>
  );
};
