import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MerchantResponse } from "../../../../types/merchant_response";
import {
  concatObjectValuesFromObject,
  getCIIU,
  getComplianceResponse,
  getMCC,
} from "../../../shared/utils";
import { IAppState } from "../../../store/reducer";
import { IUseMerchantInformationState } from "./useMerchantInformationState.interfaces";
import {
  IComplianceResponse,
  ILegalRepresentative,
  ILegalShareholder,
  INaturalRepresentative,
  INaturalShareholder,
} from "../../../../types/compliance_response";
import {
  getAppConfigPaymentMethods,
  setNotification,
} from "../../../store/actionCreators";
import { useParams } from "react-router-dom";
import { IPageParams } from "../MerchantInformation.interfaces";
import { REGULATED_COUNTRIES } from "../../../shared/infrastructure/constants/countries-enum";
import { getCatalog } from "../../../shared/infrastructure/constants/MerchantInformationConstant";
import { CatalogsEnum } from "../../../shared/infrastructure/constants/catalogs-enum";

export const useMerchantInformationState = (): IUseMerchantInformationState => {
  const dispatch = useDispatch();
  const params = useParams<IPageParams>();
  const merchant: MerchantResponse = useSelector(
    (state: IAppState) => state.merchant!
  );
  const [complianceResponse, setComplianceResponse] = useState<
    IComplianceResponse | undefined
  >();

  const [showPartnerInformation, setShowPartnerInformation] = useState(false);

  const ciiu = getCIIU(merchant.ciiu);
  const mcc = getMCC(merchant.mcc);

  const isLoading = !Boolean(complianceResponse);

  useEffect(() => {
    dispatch(getAppConfigPaymentMethods());
  }, []);
  const naturalShareholders = complianceResponse?.shareholders?.filter(
    (shareholder) => shareholder.personType === "NATURAL"
  ) as INaturalShareholder[];
  const legalShareholders = complianceResponse?.shareholders?.filter(
    (shareholder) => shareholder.personType === "LEGAL"
  ) as ILegalShareholder[];

  const [
    mainLegalRepresentative,
    setMainLegalRepresentative,
  ] = useState<string>();

  useEffect(() => {
    if (!complianceResponse) return;

    const representative = complianceResponse.legalRepresentatives?.find(
      (representative) => representative.isMain
    );

    if (!representative) return;

    if (representative.personType === "LEGAL") {
      setMainLegalRepresentative(
        (representative as ILegalRepresentative).socialReason
      );

      return;
    }

    const {
      firstName,
      secondName,
      surName,
      lastName,
    } = representative as INaturalRepresentative;

    const displayedText = concatObjectValuesFromObject({
      firstName,
      secondName,
      surName,
      lastName,
    });

    setMainLegalRepresentative(displayedText);
  }, [complianceResponse]);

  useEffect(() => {
    getComplianceResponse(params.merchantId)
      .then((data) => {
        setComplianceResponse(data);
      })
      .catch(() => {
        dispatch(
          setNotification({
            message: "No se ha podido cargar la información de la empresa",
            open: true,
            type: "error",
          })
        );
      });
  }, []);

  useEffect(() => {
    const isRegulatedCountry = REGULATED_COUNTRIES.includes(merchant.country);

    if (!isRegulatedCountry) {
      return;
    }

    const merchantType = getCatalog(
      merchant.country,
      CatalogsEnum.MerchantTypes
    )?.find((item) => item.value === merchant.merchantType);

    // Where 02 means merchant is either legal or moral entity
    merchantType && setShowPartnerInformation(merchantType.value === "02");
  }, []);

  return {
    ciiu,
    isLoading,
    legalShareholders,
    mainLegalRepresentative,
    naturalShareholders,
    mcc,
    showPartnerInformation,
  };
};
