import React from "react";
import { Container } from "@material-ui/core";
import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
import { useRetentionsIndexState } from "./state/useRetentionsIndexState";
import { TitlePage } from "../../components/common/TitlePage/TitlePage";
import { UploadSelectable } from "../../components/UploadSelectable/UploadSelectable";

export const RetentionsIndex: React.FC = () => {
  const { breadCrumbs, country } = useRetentionsIndexState();
  return (
    <React.Fragment>
      <Container fixed>
        <Breadcrumb {...breadCrumbs} />
        <TitlePage
          title={"Impuestos y retenciones para comercios"}
          country={country!}
        />
        <UploadSelectable country={country!} />
      </Container>
    </React.Fragment>
  );
};
