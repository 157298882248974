import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Box, Card } from "@material-ui/core";
import { EmptyList } from "./EmptyList";
import { SkeletonListContainer } from "../Skeleton/SkeletonListContainer";
import HistorySessionTable from "./Table/HistorySessionTable";
import { PaginationTableProps } from "./Pagination/PaginationTable";

export interface ListHistoryProps {
  historyDashboard: object[] | undefined;
  isAdmin: boolean;
  pagination: PaginationTableProps;
  timeZoneRegion: string;
  isLoading?: boolean;
  isEmptyHistory?: boolean;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  paper: {
    backgroundColor: "#F7FAFC",
    borderRadius: 4,
    boxShadow: "0 0px 0px rgba(0,0,0,0), 0 0px 0px rgba(0,0,0,0)",
  },
});

export type ListHistoryContainerProps = ListHistoryProps;

export const ListHistoryContainer: React.FC<ListHistoryContainerProps> = (
  props: ListHistoryContainerProps
) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        {!props.isEmptyHistory ? (
          !props.isLoading ? (
            <HistorySessionTable
              historyDashboard={props.historyDashboard}
              isAdmin={props.isAdmin}
              pagination={props.pagination}
              timeZoneRegion={props.timeZoneRegion}
            />
          ) : (
            <SkeletonListContainer />
          )
        ) : (
          <Box>
            <Card style={{ paddingRight: 30, paddingLeft: 30 }}>
              <EmptyList />
            </Card>
          </Box>
        )}
      </Paper>
    </React.Fragment>
  );
};
