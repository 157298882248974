import React, { ReactElement } from "react";
import { Box, Typography } from "@mui/material";
import { infoContentCardStyles as style } from "./InfoContentCard.styles";

export interface InfoContentCardProps {
  children: ReactElement;
  title: string;
  legend: string;
  isFromTable?: boolean;
}

export const InfoContentCard: React.FC<InfoContentCardProps> = (
  props: InfoContentCardProps
) => {
  const { title, legend, isFromTable, children } = props;

  return (
    <Box sx={!isFromTable ? style.cardContainer : style.cardContainerTable}>
      <Box pt={1} sx={!isFromTable ? style.cardInfo : style.cardInfoTable}>
        {!isFromTable && (
          <Box>
            <Typography sx={style.infoTitle}> {title}</Typography>
            <Typography sx={style.infoLegend}>{legend}</Typography>
          </Box>
        )}
        <Box pt={2}>{children}</Box>
      </Box>
    </Box>
  );
};
