import React from "react";
import { Container, Grid } from "@material-ui/core";
import { useStateApp } from "../../shared/state/useStateApp";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { PayoutsTransactionHeader } from "../../components/PayoutsTransactionHeader/PayoutsTransactionHeader";
import { usePayoutsTransactionState } from "./state/usePayoutsTransactionState";
import PayoutsTransactionTable from "../../components/PayoutsTransactionTable/PayoutsTransactionTable";
import { PayoutsTransactionSelectedTable } from "../../components/PayoutsTransactionSelectedTable/PayoutsTransactionSelectedTable";
import { TransactionDetail } from "../../components/TransactionDetail/TransactionDetail";
import { PayoutsListBox } from "../../components/PayoutsListBox/PayoutsListBox";
import {
  IAppAction,
  MerchantsData,
  searchMerchantsList,
  getPaymentReceipt,
} from "../../store/actionCreators";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import SearchField from "../../components/Filters/SearchField/SearchField";
import { IAppState } from "../../store/reducer";

export interface PayoutsTransactionStateProps {
  merchantsList: MerchantsData | undefined;
}

export interface PayoutsTransactionFunctionsProps {
  receipt?: Buffer;
  searchMerchantsList: (payload: {
    offset: number;
    text: string;
    merchant_id?: string;
  }) => void;
  getPaymentReceipt: (payload: string) => void;
}

export type PayoutsTransactionIndexProps = PayoutsTransactionStateProps &
  PayoutsTransactionFunctionsProps;

export const PayoutsTransaction: React.FC<PayoutsTransactionIndexProps> = (
  props: PayoutsTransactionIndexProps
) => {
  const { isMobile } = useStateApp();
  const { isAdmin, state, filters, handlers } =
    usePayoutsTransactionState(props);

  return (
    <React.Fragment>
      <Container fixed>
        <Grid container>
          <Grid item xs={12}>
            {!isMobile && (
              <Grid item xs={12}>
                <Breadcrumb
                  items={[{ label: "Transacciones", url: "/transactions" }]}
                  lastItem={"Pago"}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: 30 }}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <PayoutsTransactionHeader
                  title={"Transacciones"}
                  isMobile={isMobile}
                />
              </Grid>
              <Grid item>
                <SearchField
                  label={
                    isAdmin
                      ? "Busca por comercio o número de ticket..."
                      : "Busca por número de ticket..."
                  }
                  handleSearchField={filters.actions.handleEnterSearchText}
                  handleChangeSearchField={
                    filters.actions.handleChangeSearchText
                  }
                  value={filters.searchText}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <PayoutsListBox
              isAdmin={isAdmin}
              merchantList={props.merchantsList!}
              filtersKind={state.filterKind}
              filters={filters}
              selectedColumns={state.selectedColumns}
              handleSelectColumns={handlers.handleSelectColumns}
              handleDownloadTransactions={handlers.handleDownloadTransactions}
            />
          </Grid>
          <Grid container direction="row">
            <Grid item xs={12}>
              <PayoutsTransactionSelectedTable
                payoutsTransactionsSelected={state.payoutsTransactionsSelected}
                selectedColumns={state.selectedColumns}
                handleCheckSelectedPayoutsTransaction={
                  handlers.handleCheckSelectedPayoutsTransaction
                }
                handleSelectPayoutsTransaction={
                  handlers.handleSelectPayoutsTransaction
                }
                handleDownloadSelectedTransactions={
                  handlers.handleDownloadSelectedTransactions
                }
                handleSetPayuotTransactionSelected={
                  handlers.handleSetPayuotTransactionSelected
                }
              />
              <PayoutsTransactionTable
                payoutsTransactions={state.payoutsTransactions}
                selectedColumns={state.selectedColumns}
                handleCheckSelectedPayoutsTransaction={
                  handlers.handleCheckSelectedPayoutsTransaction
                }
                handleSelectPayoutsTransaction={
                  handlers.handleSelectPayoutsTransaction
                }
                sortBy={{
                  sortByKey: state.sortByKey,
                  direction: state.sortDirection,
                  handleSortByKey: handlers.handleSortByKey,
                }}
                pagination={{
                  handleChangePage: handlers.handleChangePage,
                  handleChangePageSize: handlers.handleChangePageSize,
                  total: state.total,
                  page: state.page,
                  size: state.pageSize,
                }}
                handleSetPayuotTransactionSelected={
                  handlers.handleSetPayuotTransactionSelected
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {state.payoutTransactionSelected && (
        <TransactionDetail
          openModal={state.openModal}
          transaction={state.payoutTransactionSelected}
          handleCloseModal={handlers.handleCloseModal}
          handlerDownloadReceipt={handlers.handlerDownloadReceipt}
        />
      )}
    </React.Fragment>
  );
};

export const mapStateToProps: (
  state: IAppState
) => PayoutsTransactionStateProps = (
  state: IAppState
): PayoutsTransactionStateProps => ({
  merchantsList: state.merchantsList,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => PayoutsTransactionFunctionsProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): PayoutsTransactionFunctionsProps =>
  ({
    searchMerchantsList: (payload: {
      offset: number;
      text: string;
      merchant_id?: string;
    }): void => dispatch(searchMerchantsList(payload)),
    getPaymentReceipt: (payload: string): void =>
      dispatch(getPaymentReceipt(payload)),
  } as PayoutsTransactionFunctionsProps);

export default connect(mapStateToProps, mapDispatchToProps)(PayoutsTransaction);
