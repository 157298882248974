import { createSlice } from "@reduxjs/toolkit";
import { IAppState } from "../../interfaces/AppState.interfaces";
import { getNodeInfo, getSemaphoreData } from "../../thunks/app/app.thunks";
import { CountriesEnum } from "../../../shared/constants/enums/countriesEnum";
import { get, set } from "lodash";
import { removeAccents } from "../../../shared/utils/parseData/parse_data";

export const initialState: IAppState = {
  loading: false,
  loadingText: "",
  showAlert: false,
  typeAlert: "success",
  valueTab: "1",
};

export const appSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getNodeInfo.fulfilled, (state, { payload }) => {
      const constitutionalCountry = removeAccents(
        get(payload, "generalInfo.constitutionalCountry")
      );
      const country = removeAccents(
        get(payload, "generalInfo.country", CountriesEnum.MEXICO)
      );

      set(payload, "generalInfo.constitutionalCountry", constitutionalCountry);
      set(payload, "generalInfo.country", country);
      state.nodeInfo = payload;
    });
    builder.addCase(getSemaphoreData.fulfilled, (state, { payload }) => {
      state.semaphoreData = payload;
    });
  },
  initialState,
  name: "app",
  reducers: {},
});

export default appSlice.reducer;
