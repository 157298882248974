import { IBankAccount } from "../../store/interfaces/dispersion.interfaces";
import { defaultTo, get, has, isEmpty, isEqual, pickBy } from "lodash";
import { BankAccountInfo } from "../../../types/bank_account_info";
import {
  AccountOrder,
  AccountType,
} from "../constants/dispersions/account_bank_constants";
import { NodeInfoResponse } from "../../../types/node_info_response";
import { ChargeConfigId } from "../enums/chargeEnum";
import { MerchantResponse } from "../../../types/merchant_response";
import { removeAccents } from "./parseData/parse_data";
import { sortBanksAccount } from "./bankAccountUtils";

export const isBasicDataResponse = (response: MerchantResponse) =>
  has(response, "taxId") && !has(response, "charge");

export const isChargeDataResponse = (response: MerchantResponse) =>
  has(response, "charge") && !has(response, "taxId");

const getConfigCn017 = (nodeInfo: NodeInfoResponse) =>
  nodeInfo.configs.find(
    (c) =>
      c.configuration === ChargeConfigId.CN017 &&
      !isEmpty(c.value) &&
      !isEqual(c.value, "null")
  );

const getConfigCn001 = (nodeInfo: NodeInfoResponse) =>
  nodeInfo.configs.find(
    (c) => c.configuration === ChargeConfigId.CN001 && !isEmpty(c.value)
  );

export const getConfigsCharge = (nodeInfo: NodeInfoResponse) => ({
  configCn001: getConfigCn001(nodeInfo),
  configCn017: getConfigCn017(nodeInfo),
});

export const getBankAccounts = (
  merchantNode: MerchantResponse
): IBankAccount[] => {
  const accounts: BankAccountInfo[] = get(
    merchantNode,
    "charge.accountInfo",
    []
  );
  const bankAccounts: IBankAccount[] = accounts.map((acc) => {
    return {
      ...acc,
      ...(acc.bankName && { bankName: acc.bankName }),
      accountNumber: defaultTo(acc.accountNumber, ""),
      accountOrder: defaultTo(acc.accountOrder, ""),
      accountType: defaultTo(acc.accountType, ""),
      country: removeAccents(acc.bankOfOrigin),
      currency: defaultTo(acc.currency, ""),
      interbankAccountCode: defaultTo(acc.interbankAccountCode, ""),
      nameBeneficiary: defaultTo(acc.nameBeneficiary, ""),
    };
  });

  return sortBanksAccount(bankAccounts);
};

export const getAccountInfoToSave = (
  bankAccount?: IBankAccount
): BankAccountInfo | undefined => {
  let accountInfo: BankAccountInfo;

  accountInfo = pickBy<BankAccountInfo>({
    ...bankAccount,
    accountOrder: bankAccount?.accountOrder as AccountOrder,
    accountType: bankAccount?.accountType as AccountType,
    bankOfOrigin: bankAccount?.country,
  });

  delete accountInfo["country"];
  delete accountInfo["accountOrder"];

  return accountInfo;
};
