export enum CountriesEnum {
  ECUADOR = "Ecuador",
  COLOMBIA = "Colombia",
  PERU = "Peru",
  CHILE = "Chile",
  MEXICO = "Mexico",
  BRAZIL = "Brazil",
  EL_SALVADOR = "ElSalvador",
  PANAMA = "Panama",
  COSTA_RICA = "CostaRica",
  GUATEMALA = "Guatemala",
  HONDURAS = "Honduras",
  NICARAGUA = "Nicaragua",
  UNITED_STATES = "EEUU",
}
