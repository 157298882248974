import { SnackBarProvider, theme } from "@kushki/connect-ui";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { TransactionContainer } from "./containers/TransactionContainer/TransactionContainer";
import { environment } from "./environments/environment";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
import { SecurityWrapperEnum } from "./shared/infraestructure/SecurityWrapperEnum";
import { ROUTES, SPA_BASENAME } from "./shared/constants/routes";
import { store } from "./store/store";
import { Provider as RollbarProvider } from "@rollbar/react";
import { getRollbarConfig } from "./shared/constants/GetRollbarConfig";

const Root = () => {
  return (
    <React.StrictMode>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&display=swap');"
        }
      </style>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500&display=swap');"
        }
      </style>
      <ModuleSecurityWrapper
        basePath={environment.kushkiUrl}
        componentId={SecurityWrapperEnum.MODULE_ID}
      >
        <RollbarProvider config={getRollbarConfig}>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <SnackBarProvider>
                <BrowserRouter basename={SPA_BASENAME}>
                  <Routes>
                    <Route
                      path={ROUTES.INDEX}
                      element={<TransactionContainer />}
                    />
                  </Routes>
                </BrowserRouter>
              </SnackBarProvider>
            </ThemeProvider>
          </Provider>
        </RollbarProvider>
      </ModuleSecurityWrapper>
    </React.StrictMode>
  );
};

export default Root;
