enum ModalInvestigationLabels {
  ALARM_INFO = "Información de la alarma",
  ALARM_INFO_PLACEHOLDER = "Descripción de las alertas",
  ADDITIONAL_INFO_PLACEHOLDER = "Si se solicitó algún soporte de origen de fondos, entre otros procesos de KYC, solicitar las carpetas de afiliación de los usuarios relacionados en el Excel adjunto. Esta solicitud puede variar." +
    "\r\n\n" +
    "Lo siguiente solo aplica si el cliente tiene más de un año sin actualizar información. Adicionalmente, solicitar la actualización de información, para lo cual el comercio debe ingresar al siguiente enlace:" +
    "\r\n" +
    "https://forms.gle/GAUNBTmt3oSrS7Gn9. Este formulario consta de 6 secciones así:" +
    "\r\n\n" +
    "1. Información general." +
    "\r\n\n" +
    "2. Identificación de Accionistas. (Es importante que lleguemos hasta el Beneficiario final - Persona natural)." +
    "\r\n\n" +
    "3. Actividad en Operaciones Internacionales." +
    "\r\n\n" +
    "4. Cuestionario PLA." +
    "\r\n\n" +
    "5. Declaración de origen de fondos. (Información de EEFF)." +
    "\r\n\n" +
    "6. Consideraciones y Autorizaciones." +
    "\r\n\n" +
    "Finalmente, les agradecemos adjuntar los documentos que se solicitan en algunas de las secciones del formulario, o en tal caso, también pueden remitirlos al correo compliance@kushkipagos.com.",
  NOTE_INFO = "Nota: Esta información es de carácter interno de uso exclusivo y confidencial de la entidad con el fin de suministrar y aportar información a los procesos correspondientes, para una adecuada toma de decisiones que no afecte de manera individual al funcionario y no exponga a la empresa a riesgos innecesarios. Por este motivo, este correo no puede ser reenviado a instancias externas de la empresa.",
}

export { ModalInvestigationLabels };
