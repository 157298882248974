import { ActionTypes } from "./actionTypes";
import { IAppState } from "./reducer";
import { INotification } from "../shared/infrastructure/interfaces/INotification";
import { DownloadFileResponse } from "../../types/download_file_response";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import axios from "../shared/utils/axios-util";
import { environment } from "../environments/environment";
import { AxiosResponse } from "axios";
import { get } from "lodash";
import { FileTypeEnum } from "../shared/infrastructure/constants/FileTypeEnum";
import { ValidateFilesResponse } from "../../types/validate_files_response";
import { ValidateFilesRequest } from "../../types/validate_files_request";
import { UploadFiles } from "../../types/upload_files";
import { ProcessFileRequest } from "../../types/process_file_request";
import { ProcessFileResponse } from "../../types/process_file_response";
import { FilterDispersionResponse } from "../../types/filter_dispersion_response";
import { FilterDispersionRequest } from "../../types/filter_dispersion_request";
import { DownloadResponseFilesRequest } from "../../types/download_response_files_request";
import { DownloadResponseFilesResponse } from "../../types/download_response_files_response";
import { StatusEnum } from "../shared/infrastructure/constants/RequestDispersionConstants";
import { FileValidationDetailsResponse } from "../../types/file_validation_details_response";
import { Routes } from "../shared/infrastructure/routes";
import { FileStateDispersionResponse } from "../../types/file_state_dispersion_response";

export type IAppAction = { type: string } & IAppState;

const basePathUsrv: string = "payouts/transfer/v1";

export const setNotification = (payload: INotification) => ({
  notification: payload,
  type: ActionTypes.SET_NOTIFICATION,
});

export const setDownloadFilesResponse = (
  payload: DownloadFileResponse
): IAppAction => ({
  type: ActionTypes.SET_DOWNLOAD_FILES_RESPONSE,
  downloadFilesResponse: payload,
});

export const setValidateFilesResponse = (
  payload: ValidateFilesResponse | undefined
): IAppAction => ({
  type: ActionTypes.SET_VALIDATE_FILES_RESPONSE,
  validateFilesResponse: payload,
});

export const setShowValidationAlert = (payload: boolean): IAppAction => ({
  type: ActionTypes.SET_SHOW_VALIDATION_ALERT,
  showValidationErrorAlert: payload,
});

export const setProcessFileDispersionResponse = (
  payload: ProcessFileResponse
): IAppAction => ({
  type: ActionTypes.SET_PROCESS_FILE_DISPERSIONS_RESPONSE,
  processFileDispersionsResponse: payload,
});

export const setShowAlertOnProcessFileRequest = (
  payload: boolean
): IAppAction => ({
  type: ActionTypes.SET_SHOW_ALERT_ON_PROCESS_FILE_REQUEST,
  showAlertOnProcessFileRequest: payload,
});

export const setShowAlertOnGetDispersionFile = (
  payload: boolean
): IAppAction => ({
  type: ActionTypes.SET_SHOW_ALERT_ON_GET_DISPERSION_FILE,
  showAlertOnGetDispersionFile: payload,
});

export const setGetDispersionFileResponse = (
  payload: FilterDispersionResponse
): IAppAction => ({
  type: ActionTypes.SET_DISPERSION_FILE_RESPONSE,
  getFileDispersionResponse: payload,
});

export const setIsLoadingGetDispersionFile = (
  payload: boolean
): IAppAction => ({
  type: ActionTypes.SET_IS_LOADING_GET_DISPERSION_FILE,
  isLoadingGetDispersionFile: payload,
});

export const setFilters = (payload: FilterDispersionRequest): IAppAction => ({
  type: ActionTypes.SET_FILTERS,
  filterDispersionRequest: payload,
});

export const setFileValidationDetails = (
  payload: FileValidationDetailsResponse
): IAppAction => ({
  type: ActionTypes.SET_FILE_VALIDATION_DETAILS,
  fileValidationDetails: payload,
});

export const setFileStateDispersionDetails = (
  payload: FileStateDispersionResponse
): IAppAction => ({
  type: ActionTypes.SET_FILE_DISPERSION_DETAILS,
  fileStateDispersionDetails: payload,
});

export const setIsLoadingDetails = (payload: boolean): IAppAction => ({
  type: ActionTypes.SET_IS_LOADING_DETAILS,
  isLoadingDetails: payload,
});

export const setShowAlertOnDispersionsValidation = (
  payload: boolean
): IAppAction => ({
  type: ActionTypes.SET_SHOW_ALERT_ON_DISPERSIONS_VALIDATION,
  showAlertOnDispersionsValidation: payload,
});

export const setSelectedIndexDispersionTab = (payload: number): IAppAction => ({
  type: ActionTypes.SET_SELECTED_DISPERSIONS_TAB,
  selectedIndexDispersionTab: payload,
});

export const downloadFiles = (
  type: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    const method: string = `${basePathUsrv}/download-template`;

    axios
      .get<DownloadFileResponse>(`${environment.kushkiUrl}/${method}`)
      .then((axios_response: AxiosResponse<DownloadFileResponse>) => {
        const response: DownloadFileResponse = axios_response.data;
        dispatch(setDownloadFilesResponse(response));

        if (type === FileTypeEnum.INPUT) {
          window.open(get(response, "templates.inputFile", ""), "_blank");
        } else {
          window.open(get(response, "templates.uafFile", ""), "_blank");
        }
      })
      .catch((_e) => {});
  };
};

export const setUploadFiles = (payload: UploadFiles): IAppAction => ({
  type: ActionTypes.SET_UPLOAD_FILES,
  uploadFiles: payload,
});

export const validateFiles = (
  request: ValidateFilesRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    const method: string = `${basePathUsrv}/init-dispersion-request`;
    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post<ValidateFilesResponse>(
        `${environment.kushkiUrl}/${method}`,
        request,
        {
          headers: headers,
        }
      )
      .then((axios_response: AxiosResponse<ValidateFilesResponse>) => {
        const response: ValidateFilesResponse = axios_response.data;

        dispatch(setUploadFiles(request.files));
        dispatch(setValidateFilesResponse(response));
      })
      .catch((_e) => {
        dispatch(setShowValidationAlert(true));
      });
  };
};

export const processFileDispersions = (
  request: ProcessFileRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    const method: string = `${basePathUsrv}/processFileDispersions`;

    axios
      .post<ProcessFileResponse>(`${environment.kushkiUrl}/${method}`, request)
      .then((axios_response: AxiosResponse<ProcessFileResponse>) => {
        const response: ProcessFileResponse = axios_response.data;
        dispatch(setProcessFileDispersionResponse(response));
      })
      .catch((_e) => {
        dispatch(setShowAlertOnProcessFileRequest(true));
      });
  };
};

export const getProcessFileDispersion = (
  request: FilterDispersionRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setIsLoadingGetDispersionFile(true));
    const method: string = `${basePathUsrv}/dispersionFiles`;
    const requestData: FilterDispersionRequest = {
      ...request,
      filter: {
        ...request.filter,
        merchantId: localStorage.getItem("merchantId"),
      },
    };

    axios
      .post<FilterDispersionResponse>(
        `${environment.kushkiUrl}/${method}`,
        requestData
      )
      .then((axios_response: AxiosResponse<FilterDispersionResponse>) => {
        const response: FilterDispersionResponse = axios_response.data;
        dispatch(setGetDispersionFileResponse(response));
        dispatch(setIsLoadingGetDispersionFile(false));
      })
      .catch((_e) => {
        dispatch(setShowAlertOnGetDispersionFile(true));
        dispatch(setIsLoadingGetDispersionFile(false));
      });
  };
};

export const downloadResponseFiles = (
  request: DownloadResponseFilesRequest
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    const method: string = `${basePathUsrv}/downloadResponseFiles`;

    axios
      .post<DownloadResponseFilesResponse>(
        `${environment.kushkiUrl}/${method}`,
        request
      )
      .then((axios_response: AxiosResponse<DownloadResponseFilesResponse>) => {
        const response: DownloadResponseFilesResponse = axios_response.data;

        if (response.status === StatusEnum.COMPLETE) {
          window.open(get(response, "url", ""), "_blank");
        }
      })
      .catch((_e) => {
        dispatch(setShowAlertOnGetDispersionFile(true));
      });
  };
};

export const getFileValidationDetails = (
  referenceNumber: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    const method: string = `${basePathUsrv}/dispersion-files/${referenceNumber}`;

    dispatch(setIsLoadingDetails(true));
    axios
      .get<FileValidationDetailsResponse>(`${environment.kushkiUrl}/${method}`)
      .then((axios_response: AxiosResponse<FileValidationDetailsResponse>) => {
        const response: FileValidationDetailsResponse = axios_response.data;
        dispatch(setFileValidationDetails(response));
        setInterval(() => {
          dispatch(setIsLoadingDetails(false));
        }, 2000);
      })
      .catch((_e) => {
        window.location.href = `${Routes.BASE_PATH_DISPERSION}`;
      });
  };
};

export const getFileStateDispersionByMerchant = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    const method: string = `${basePathUsrv}/dispersion-files/merchant/${merchantId}`;
    axios
      .get<FileStateDispersionResponse>(`${environment.kushkiUrl}/${method}`)
      .then((axios_response: AxiosResponse<FileStateDispersionResponse>) => {
        const response: FileStateDispersionResponse = axios_response.data;

        dispatch(setFileStateDispersionDetails(response));

        if (response.status === StatusEnum.COMPLETE)
          dispatch(setValidateFilesResponse(undefined));
      })
      .catch((_e) => {
        dispatch(setShowAlertOnDispersionsValidation(true));
      });
  };
};
