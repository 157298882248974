import * as React from "react";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import {
  selectApp,
  selectChargeback,
} from "../../../store/selectors/selectors";
import { setSearchChargebackRequest } from "../../../store/actions/chargeback.actions";
import { cloneDeep, concat, get } from "lodash";
import { IUseHeaderFiltersState } from "./useHeaderFiltersState.interfaces";
import { ColumnsItem } from "@kushki/connect-ui";
import { IHeaderFiltersProps } from "../HeaderFilters.interfaces";
import { FilterSideBarProps } from "../../Filters/FilterSideBar/FilterSideBar.interfaces";
import {
  FILTER_SIDE_BAR_INITIAL_STATE,
  FILTER_SIDE_BAR_INITIAL_STATE_ADMIN,
  getItemsFilterDetailBar,
} from "../../../shared/utils/header_filters_utils";
import {
  ItemCategoryItem,
  ItemCategoryProps,
} from "../../Filters/ItemCategory/interfaces";
import {
  column_selected,
  column_selected_hierarchy,
} from "../../../shared/utils/column_selected";
import { set } from "lodash";
import {
  TFilterDetailBar,
  TOnChangeFilters,
} from "@kushki/connect-ui/dist/Components/Molecules/Filters/FilterDetailBar/FilterDetailBar.interfaces";
import { auth } from "../../../shared/auth";
import {
  getEndDate,
  getMinDate,
  getStartDate,
} from "../../../shared/utils/date_utils";
import { TimeEnum } from "../../../shared/enums/TimeEnum";
import { FileMassiveData } from "../../../../types/update_chargeback_status_massive_request";

export const useHeaderFiltersState = ({
  getItemsSelected,
  setPage,
}: IHeaderFiltersProps): IUseHeaderFiltersState => {
  const dispatch = useAppDispatch();
  const { searchChargebackRequest } = useAppSelector(selectChargeback);
  const { isLoadingMassiveStatus } = useAppSelector(selectApp);
  const [valueSearch, setValueSearch] = useState<string>("");
  const [selected] = useState<ColumnsItem[]>(
    auth.isAdmin()
      ? cloneDeep(concat(column_selected_hierarchy, column_selected))
      : cloneDeep(column_selected)
  );
  const [filterSideBarProps, setFilterSideBarProps] =
    useState<FilterSideBarProps>(
      auth.isAdmin()
        ? FILTER_SIDE_BAR_INITIAL_STATE_ADMIN
        : FILTER_SIDE_BAR_INITIAL_STATE
    );
  const [massiveStatusFile, setMassiveStatusFile] = useState<
    FileMassiveData | undefined
  >(undefined);
  const [itemsDetailBar, setItemsDetailBar] = useState<TFilterDetailBar[]>([]);

  const onEnterName: (e: React.KeyboardEvent<HTMLDivElement>) => void = (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    const value = get(e, "target.value", "");

    if (e.key === "Enter" || (e.key === "Backspace" && value === "")) {
      setPage(0);
      const searchChargebackState: object = {
        ...searchChargebackRequest,
        offset: 0,
        text: value,
      };

      dispatch(setSearchChargebackRequest(searchChargebackState));
    }
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValueSearch(get(e, "target.value", ""));
  };

  const onClickFilterSideBar = (itemCategory: ItemCategoryProps[]) => {
    setPage(0);
    const filterSelected = getItemsFilterDetailBar(itemCategory);

    filterSideBarProps.onClick(itemCategory);
    setItemsDetailBar(filterSelected);
    getItemsSelected(filterSelected);
  };

  const changeCategoryItems = (newCategoryItems: ItemCategoryProps[]) => {
    setPage(0);
    setFilterSideBarProps({
      ...filterSideBarProps,
      categoryItems: newCategoryItems,
    });
  };

  const onChangeFiltersDetailBar = (changes: TOnChangeFilters) => {
    setPage(0);
    let itemsSideBar: ItemCategoryProps[] = get(
      filterSideBarProps,
      "categoryItems"
    );
    let itemsSideBarFinal: ItemCategoryProps[] = [];

    itemsSideBar.forEach((itemSideBar: ItemCategoryProps) => {
      let itemsFilterSideBar: ItemCategoryItem[] = [];

      itemSideBar.items.forEach((itemFilterSideBar: ItemCategoryItem) => {
        if (
          changes.affected.itemDeleted.label === itemFilterSideBar.label &&
          changes.affected.title === itemSideBar.groupSection
        )
          set(itemFilterSideBar, "selected", false);

        itemsFilterSideBar.push(itemFilterSideBar);
      });
      set(itemSideBar, "items", itemsFilterSideBar);
      itemsSideBarFinal.push(itemSideBar);
    });
    changeCategoryItems(itemsSideBarFinal);
    onClickFilterSideBar(itemsSideBarFinal);
  };

  return {
    columnsItems: selected,
    defaultEndDate: getEndDate() + ` ${TimeEnum.defaultFinalTime}`,
    defaultStartDate: getStartDate() + ` ${TimeEnum.defaultInitialTime}`,
    filterSideBarProps,
    isLoadingMassiveStatus,
    itemsDetailBar,
    massiveStatusFile,
    minDate: getMinDate(),
    onChange,
    onChangeFiltersDetailBar,
    onClickFilterSideBar,
    onEnterName,
    setMassiveStatusFile,
    valueSearch,
  };
};
