/**
 * Country Enumeration
 */
export enum CountryEnum {
  ecuador = "Ecuador",
  peru = "Peru",
  colombia = "Colombia",
  chile = "Chile",
  mexico = "Mexico",
}

export const LEDGER_FLOW_COUNTRIES: CountryEnum[] = [
  CountryEnum.colombia,
  CountryEnum.mexico,
];
