/**
 *  Status Refund Enumeration
 */
import { StatusFlowEnum } from "./StatusFlowEnum";

export enum StatusRefundEnum {
  IVLDTN = "IVLDTN",
  PNDRFN = "PNDRFN|RTRY|NTHRZD",
}

export const defaultStateRefund = {
  [StatusFlowEnum.EXECUTOR]: StatusRefundEnum.PNDRFN,
  [StatusFlowEnum.VALIDATOR]: StatusRefundEnum.IVLDTN,
  [StatusFlowEnum.VIEWER]: StatusRefundEnum.PNDRFN,
};
