import { StatusEnum } from "../enums/StatusEnum";

export const CatalogTranslateStatus: Record<StatusEnum, string> = {
  [StatusEnum.ACTIVE]: "ACTIVO",
  [StatusEnum.INACTIVE]: "INACTIVO",
  [StatusEnum.PENDING]: "PENDIENTE",
};

export const CatalogTranslateChipStatus: Record<StatusEnum, string> = {
  [StatusEnum.ACTIVE]: "Activo",
  [StatusEnum.INACTIVE]: "Inactivo",
  [StatusEnum.PENDING]: "Pendiente",
};
