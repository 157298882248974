import React from "react";
import { DeferredSection } from "../../components/DeferredSection/DeferredSection";
import { Container } from "@material-ui/core";
import Breadcrumb from "../../components/commons/Breadcrumb/Breadcrumb";
import { DeferredHeader } from "../../components/commons/Header/DeferredHeader";
import { routes } from "../../shared/infrastructure/constants/routes";
import { defaultTo, get, isEmpty } from "lodash";
import { useContainerIndexState } from "./state/useContainerIndexState";
import { Loader } from "../../components/commons/Loader/Loader";
import { NotDeferred } from "../../components/NotDeferred/NotDeferred";
import { DeferredTableBrazil } from "../../components/CustomDeferredTable/DeferredTableBrazil/DeferredTableBrazil";
import { FilterComponent } from "../../components/FilterComponent/FilterComponent";
import { PROCESSOR_NAMES } from "../../shared/infrastructure/ProcessorNames";
import { CountriesEnum } from "../../shared/infrastructure/constants/CountriesEnum";

export const ContainerIndex: React.FC = () => {
  const {
    isDeferredLoaded,
    deferredOption,
    merchant,
    hasCustomList,
    merchantDeferred,
    brazilDeferredOption,
  } = useContainerIndexState();

  return (
    <>
      <Container fixed>
        <Breadcrumb
          items={[{ label: "Comercios", url: routes.MERCHANT_LIST }]}
          lastItem={get(merchant, "name", "") + " /  Diferidos"}
        />
        <DeferredHeader
          title="Diferidos"
          merchant={merchant}
          isActive={true}
          isDeferredLoaded={false}
          hasDeferred={!isEmpty(defaultTo(deferredOption, []))}
        />

        <FilterComponent
          processorNames={PROCESSOR_NAMES[CountriesEnum.BRAZIL]}
          consortium={[]}
          months={[]}
          fee={brazilDeferredOption}
          merchantDeferred={merchantDeferred}
        />

        {!isDeferredLoaded ? (
          <Loader />
        ) : isEmpty(defaultTo(deferredOption, [])) ? (
          <NotDeferred />
        ) : hasCustomList ? (
          <DeferredTableBrazil
            merchantDeferred={deferredOption}
            isDeferredLoaded={isDeferredLoaded}
          />
        ) : (
          <DeferredSection
            merchantDeferred={deferredOption}
            isDeferredLoaded={isDeferredLoaded}
          />
        )}
      </Container>
    </>
  );
};
