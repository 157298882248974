/**
 * CociliationTitle Enumeration
 */
export enum ConciliationTitleEnum {
  registro = "Conciliar Registro",
  trxs = "Conciliar transacciones",
  trx = "Conciliar transacción",
  valueLiquidate = "Ingrese el valor liquidado:",
  mustDecimal = "El valor debe ser un número decimal",
  commentAction = "Ingrese un comentario de la acción que está ejecutando:",
  max100 = "Máximo 100 caracteres",
  exc100 = "El texto excede el límite de 100 caracteres",
  conciliationAction = "Ten en cuenta que la conciliación es una acción irreversible.",
  greyColor = "#6D7781",
  redColor = "#D62C4B",
  blackColor = "#293036",
}
