import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { setNotification } from "../../../store/actionCreators";
import { ICatalogDownloadReportState } from "../CatalogDownloadReport.interfaces";
import { MessageEnum } from "../../../shared/infrastructure/Enums/MessageEnum";

export const useCatalogDownloadReportState = (): ICatalogDownloadReportState => {
  const dispatch: Dispatch<any> = useDispatch();

  const handleDownloadCsvFileSuccessMessage = (): void => {
    dispatch(
      setNotification({
        type: "success",
        message: MessageEnum.DOWNLOAD_SUCCESS,
        open: true,
      })
    );
  };

  return {
    handleDownloadCsvFileSuccessMessage,
  };
};
