import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const executivesTemplateFileStyles = createNamedStyles({
  buttonContainer: {
    "&:active, &[disabled], &:focus": {
      background: "transparent",
      border: "none",
      boxShadow: "none",
    },
    "&:hover": {
      background: "transparent",
      border: "none",
      boxShadow: "none",
      textDecoration: "underline",
    },
    border: "none",
    marginLeft: "1.5em",
    paddingLeft: "2px",
  },
});
