import React, { useState } from "react";
import { IRuleAuthenticationForm } from "../../shared/infrastructure/interfaces/IRuleAuthenticationForm";
import ConnectForm from "../../components/common/ConnectForm/ConnectForm";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { AuthenticationRulesSection } from "../../shared/infrastructure/constants/AuthenticationRulesConstants";
import { ComponentText } from "../../components/ComponentText/ComponentText";
import ConfigurationInformationSectionProps from "../../components/AuthenticationRulesSection/ConfigurationInformationSection";
import { SpeedRules } from "../../components/AuthenticationRulesSection/SpeedRules/SpeedRules";
import { ArrayField, useFieldArray } from "react-hook-form";
import { IOptionSelection } from "../../shared/infrastructure/constants/RuleRequestConstants";
import { makeStyles } from "@material-ui/core/styles";
import { initialCondition } from "./state/useRuleAuthenticationIndexState";
import { ConfirmModal } from "../../components/common/ConfirmModal/ConfirmModal";

interface AuthenticationSettingsProps {
  processors: IOptionSelection[];
  credentials: IOptionSelection[];
}
const useStyles = makeStyles(() => ({
  defaultPd: {
    padding: "15px",
  },
  authRules: {
    height: "fit-content",
    width: "220",
  },
}));
export const AuthenticationSettings: React.FC<AuthenticationSettingsProps> = (
  props: AuthenticationSettingsProps
) => {
  const [idx, setIdx] = useState(-1);
  const [open, setOpen] = useState(false);

  const classes = useStyles();
  const { processors, credentials } = props;

  const handleDeleteRule = (index: number) => {
    setOpen(true);
    setIdx(index);
  };

  const handleClose = () => {
    setIdx(-1);
    setOpen(false);
  };

  return (
    <ConnectForm<IRuleAuthenticationForm>>
      {({ control }) => {
        const { fields, append, remove } = useFieldArray({
          control: control, // control props comes from useForm (optional: if you are using FormContext)
          name: "strictCondition", // unique name for your Field Array
          // keyName: "id", default to "id", you can change the key name
        });
        return (
          <Box className={classes.authRules}>
            <Box className={classes.defaultPd}>
              <Typography
                variant="h5"
                color="primary"
                style={{ marginBottom: "12px" }}
              >
                {AuthenticationRulesSection.TITLE}
              </Typography>
              {
                <ComponentText
                  label={AuthenticationRulesSection.SUBTITLE}
                  type={"lighter"}
                  params={{ variant: "body2", color: "inherit" }}
                />
              }
            </Box>
            {fields.map((field: Partial<ArrayField>, index) => (
              <React.Fragment key={field.id}>
                <Box
                  className={classes.defaultPd}
                  display={"flex"}
                  flexDirection={"column"}
                  marginTop={index !== 0 ? "3em" : "0em"}
                >
                  <Grid container>
                    <Grid xs={12} md={6}>
                      <ComponentText
                        label={`Regla de autenticación Nº ${index + 1}`}
                        type={"normal"}
                        params={{ variant: "h6", color: "primary" }}
                      />
                    </Grid>
                    {fields.length > 1 && (
                      <Grid xs={12} md={6}>
                        <Grid
                          container
                          justifyContent={"flex-end"}
                          alignItems={"center"}
                        >
                          <Button
                            variant={"text"}
                            onClick={() => {
                              handleDeleteRule(index);
                            }}
                          >
                            <ComponentText
                              label={"Eliminar regla"}
                              type={"normal"}
                              params={{
                                variant: "body2",
                                color: "error",
                              }}
                            />
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <ComponentText
                    label={"Agrega condiciones para configurar la regla"}
                    type={"lighter"}
                    params={{ variant: "body2" }}
                  />
                  <section style={{ marginTop: "1rem" }}>
                    <ConfigurationInformationSectionProps
                      index={index}
                      processors={processors}
                      credentials={credentials}
                    />
                  </section>
                </Box>
                <SpeedRules item={field} indexParent={index} />
              </React.Fragment>
            ))}
            <ConfirmModal
              open={open}
              titleText={"¿Deseas eliminar esta regla de autenticación?"}
              acceptText="Eliminar regla"
              acceptColor="error"
              handleClose={handleClose}
              handleAccept={() => {
                remove(idx);
                handleClose();
              }}
            >
              <Grid item md={12}>
                <Typography>
                  Se eliminará la regla de autenticación y todas las condiciones
                  que se hayan configurado.
                </Typography>
              </Grid>
            </ConfirmModal>
            <Box
              width={"100%"}
              padding={"60px 15px 15px 15px"}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <Button
                variant={"text"}
                onClick={() => append({ ...initialCondition })}
              >
                <ComponentText
                  label={"Agregar regla"}
                  type={"normal"}
                  params={{
                    variant: "body1",
                    color: "primary",
                  }}
                />
              </Button>
            </Box>
          </Box>
        );
      }}
    </ConnectForm>
  );
};
