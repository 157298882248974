import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { breadcrumbStyles } from "./Breadcrumb.styles";
import { BreadcrumItem, BreadcrumProps } from "./Breadcrumb.interfaces";

export const Breadcrumb = (props: BreadcrumProps) => (
  <Breadcrumbs separator={">"} aria-label="breadcrumb">
    {props.items.map((item: BreadcrumItem) => (
      <Link key={item.label} sx={breadcrumbStyles.text} href={item.url}>
        {item.label}
      </Link>
    ))}
    <Typography sx={breadcrumbStyles.text}>{props.lastItem}</Typography>
  </Breadcrumbs>
);
