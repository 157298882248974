import { SecurityRulesByType } from "../../../../types/security_rules_by_type";
import { useEffect, useState } from "react";
import { RulesIndexProps } from "../RulesIndex";
import {
  LabelField,
  PathRoutes,
  RulesNotificationResultEnum,
} from "../../../shared/infrastructure/constants/RulesConstants";
import { SecurityRule } from "../../../../types/security_rule";
import { useMediaQuery } from "@material-ui/core";
import { ModalContainerType } from "../../../components/ModalContainer/ModalContainer";
import { WhitelistRules } from "../../../../types/whitelist_rules";
import { get, isEmpty, isUndefined, set } from "lodash";
import { MAP_TYPE_CARD } from "../../../shared/infrastructure/constants/BusinessRulesEnum";

export interface IRulesIndexState {
  showModal: boolean;
  rule: SecurityRule;
  ruleType: ModalContainerType;
  commerceRules: SecurityRulesByType;
  isMobile: boolean;
  rulesByType: SecurityRulesByType;
  lastEvaluatedKey: object[];
  whitelistRules: WhitelistRules;
  lastEvaluatedKeyWhiteList: object[];
  merchantName: string;
  merchantId: string;
  actions: {
    handlerShowModal: () => void;
    handlerEditRule: (ruleId: string) => void;
    handlerDeleteRule: (ruleId: string) => void;
    handlerChangeRuleType: () => void;
    handleNextAction: () => void;
    handleBackAction: () => void;
    handleNextActionCommerceRules: () => void;
    handleBackActionCommerceRules: () => void;
    handleNextActionWhitelist: () => void;
    handleBackActionWhitelist: () => void;
    handleCloseNotification: () => void;
    handleDeleteRule: (ruleId: string) => void;
    handleDeleteWhitelistRule: (merchantId: string) => void;
    handleSearchSecurityRulesCommerce: (merchantIdParam: string) => void;
    handleSearchWhitelistRule: (merchantIdParam: string) => void;
    handleSetMerchantName: (merchantNameParam: string) => void;
    handleSearchTextMerchantList: (key: string) => void;
    handlerShowModalRuleByType: (ruleId: string, ruleType: string) => void;
    handlerSetMerchantNameMigrateRules: (merchantNameInput: string) => void;
    handlerMigrateRules: () => void;
    handlerUpdateStateRule: (rule: SecurityRule, ruleId: string) => void;
  };
  handlerSetIndexRedirect: (index: number) => void;
  routeRedirect: () => void;
  labelRedirect: string;
}

export const useRuleIndexState = (props: RulesIndexProps): IRulesIndexState => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [ruleType, setRuleType] = useState<ModalContainerType>("commerce");
  const isMobile: boolean = useMediaQuery("(max-width:767px)");
  const [rule, setRule] = useState<SecurityRule>({
    rule: {
      name: "Alias rule",
      action: "block",
      transactionBank: "=|test",
      transactionBrand: "contain|test1",
      transactionIsDeferred: "!=|test3",
      transactionProcessor: "=|test4",
      transactionBIN: "!contain|1234",
      transactionCountry: "=|tes5t",
      limit: "2",
      maxAmount: "4",
      periodicitySum: "month",
      periodicityCount: "biannual",
      transactionStatusSum: "DECLINED",
      transactionStatusCount: "APPROVAL",
    },
    type: "",
    service: "",
    merchantName: "Merchant Prueba",
    id: "da4412s1231da23sd0123131",
    createdAt: 1608938073,
  });
  const [rulesByType, setRulesByType] = useState<SecurityRulesByType>({
    items: [],
    lastEvaluatedKey: {},
    limit: 10,
  });
  const [whitelistRules, setWhitelistRules] = useState<WhitelistRules>({
    items: [],
    lastEvaluatedKey: {},
    limit: 4,
  });
  const [commerceRules, setCommerceRules] = useState<SecurityRulesByType>({
    items: [],
    lastEvaluatedKey: {},
    limit: 10,
  });
  const [lastEvaluatedKey] = useState([{}]);
  const [lastEvaluatedKeyWhiteList] = useState([{}]);
  const [merchantName, setMerchantName] = useState<string>("");
  const [merchantId, setMerchantId] = useState<string>("");

  const [labelRedirect, setLabelRedirect] = useState<string>(
    LabelField.ADD_RULE
  );
  const [indexRedirect, setIndexRedirect] = useState<number>(0);
  const [merchantNameMigrateRules, setMerchantNameMigrateRules] =
    useState<string>("");

  useEffect(() => {
    props.getRulesByType({ ...rulesByType });
    props.getWhitelistRules({ ...whitelistRules });
    props.getCommerceRules({ ...commerceRules });
  }, []);

  useEffect(() => {
    setRulesByType({
      ...rulesByType,
      lastEvaluatedKey: props.rulesByType?.lastEvaluatedKey!,
      items: props.rulesByType?.items,
    });
  }, [props.rulesByType?.lastEvaluatedKey]);

  useEffect(() => {
    setCommerceRules({
      ...commerceRules,
      lastEvaluatedKey: props.commerceRules?.lastEvaluatedKey!,
      items: props.commerceRules?.items,
    });
  }, [props.commerceRules?.lastEvaluatedKey, props.commerceRules]);

  useEffect(() => {
    setWhitelistRules({
      ...whitelistRules,
      lastEvaluatedKey: props.whitelistRules?.lastEvaluatedKey!,
      items: props.whitelistRules?.items,
    });
  }, [props.whitelistRules?.lastEvaluatedKey, props.whitelistRules]);

  useEffect(() => {
    props.searchMerchantsList({
      offset: 0,
      limit: 6,
    });
  }, []);

  useEffect(() => {
    if (!isUndefined(commerceRules.items) && !isEmpty(commerceRules.items)) {
      props.searchMerchantsList({
        offset: 0,
        limit: 9,
        text: commerceRules.items.map((item) => item.merchantId).join("||"),
      });
    }
  }, [commerceRules]);

  const handleNextAction = (): void => {
    delete rulesByType.items;
    lastEvaluatedKey.push(rulesByType.lastEvaluatedKey);
    props.getRulesByType({ ...rulesByType });
  };

  const handleNextActionCommerceRules = (): void => {
    delete commerceRules.items;
    set(commerceRules, "items", [{ merchantId: merchantId }]);
    lastEvaluatedKey.push(commerceRules.lastEvaluatedKey);
    props.getCommerceRules({ ...commerceRules });
  };

  const handleNextActionWhitelist = (): void => {
    delete whitelistRules.items;
    lastEvaluatedKeyWhiteList.push(whitelistRules.lastEvaluatedKey!);
    props.getWhitelistRules({ ...whitelistRules });
  };

  const handleBackAction = (): void => {
    const arraySize: number = lastEvaluatedKey.length;
    props.getRulesByType({
      ...rulesByType,
      lastEvaluatedKey: lastEvaluatedKey[arraySize - 2],
    });
    lastEvaluatedKey.pop();
  };

  const handleBackActionCommerceRules = (): void => {
    const arraySize: number = lastEvaluatedKey.length;
    props.getRulesByType({
      ...commerceRules,
      lastEvaluatedKey: lastEvaluatedKey[arraySize - 2],
    });
    lastEvaluatedKey.pop();
  };

  const handleBackActionWhitelist = (): void => {
    const arraySize: number = lastEvaluatedKeyWhiteList.length;
    props.getWhitelistRules({
      ...whitelistRules,
      lastEvaluatedKey: lastEvaluatedKeyWhiteList[arraySize - 2],
    });
    lastEvaluatedKeyWhiteList.pop();
  };

  const handleCloseNotification = (): void => {
    props.setNotification({
      type: props.notification!.type!,
      open: false,
      message: "",
      action: RulesNotificationResultEnum.RULES_REQUEST_ERROR,
    });
  };

  const handleDeleteRule = (ruleId: string): void => {
    props.deleteRule(ruleId);
  };

  const handleDeleteWhitelistRule = (merchantId: string): void => {
    props.deleteWhitelistRule(merchantId);
    for (let i: number = 0; i < whitelistRules.items?.length!; i++) {
      if (whitelistRules.items![i].merchantId === merchantId) {
        whitelistRules.items?.splice(i, 1);
      }
    }
  };

  const handlerShowModal = (): void => {
    setShowModal(!showModal);
  };

  const handlerEditRule = (ruleId: string): void => {
    window.location.href = `/rules/security/rule/${ruleId}`;
  };

  const handlerDeleteRule = (ruleId: string): void => {
    props.deleteSecurityRule(ruleId);
  };

  const handlerUpdateStateRule = (rule: SecurityRule, ruleId: string): void => {
    props.updateSecurityRuleStatus({
      ruleId,
      rule,
      updatingStatus: true,
    });
  };

  const handlerChangeRuleType = (): void => {
    setRuleType("country");
    setRule({
      ...rule,
      country: ["Ecuador", "Chile", "Colombia", "México", "Perú"],
    });
  };

  const handleSearchSecurityRulesCommerce = (merchantIdParam: string): void => {
    delete commerceRules.items;
    setMerchantId(merchantIdParam);
    props.setShowMigrationRule(false);
    const securityRulesByTypeParam: SecurityRulesByType = {
      lastEvaluatedKey: {},
      limit: 4,
      items: [
        {
          merchantId: merchantIdParam,
        },
      ],
    };
    props.getCommerceRules(securityRulesByTypeParam);
  };

  const handleSearchWhitelistRule = (merchantIdParam: string): void => {
    delete whitelistRules.items;
    setMerchantId(merchantIdParam);
    const whitelistRulesParam: WhitelistRules = {
      lastEvaluatedKey: {},
      limit: 4,
      items: [
        {
          merchantId: merchantIdParam,
          merchantName: "",
          country: "",
          maskedCards: [],
          validationType: "",
          cardIDs: [],
        },
      ],
    };
    props.getWhitelistRules(whitelistRulesParam);
  };

  const handleSetMerchantName = (merchantNameParam: string): void => {
    setMerchantName(merchantNameParam);
  };

  const handleSearchTextMerchantList = (key: string) => {
    if (key !== "Enter") return;
    props.searchMerchantsList({
      offset: 0,
      limit: 6,
      text: merchantName,
    });
  };

  const handlerShowModalRuleByType = (ruleId: string, ruleType: string) => {
    let resultSecurityRule: any;
    if (ruleType === "securityCommerce") {
      resultSecurityRule = get(props.commerceRules, "items", []).find(
        (rule) => rule.id === ruleId
      );
      setRuleType("commerce");
      if (get(resultSecurityRule, "rule.transactionIsCreditCard", "") !== "") {
        let res: String = get(
          resultSecurityRule,
          "rule.transactionIsCreditCard",
          ""
        );
        let valor: String = MAP_TYPE_CARD[`${res}`];
        if (valor === "=|true")
          resultSecurityRule.rule.transactionIsCreditCard = "=|Crédito";
        else resultSecurityRule.rule.transactionIsCreditCard = "=|Débito";
      }
      setRule(resultSecurityRule);
      setShowModal(!showModal);
      return;
    }
    setRuleType("country");
    resultSecurityRule = get(props.rulesByType, "items", []).find(
      (rule) => rule.id === ruleId
    );
    setRule(resultSecurityRule);
    setShowModal(!showModal);
  };
  const handlerSetIndexRedirect = (index: number): void => {
    setIndexRedirect(index);
    index === 2
      ? setLabelRedirect(LabelField.ADD_WHITELIST)
      : setLabelRedirect(LabelField.ADD_RULE);
  };
  const routeRedirect = (): void => {
    indexRedirect === 2
      ? (window.location.href = PathRoutes.WHITELIST)
      : (window.location.href = PathRoutes.SECURITY);
  };
  const handlerSetMerchantNameMigrateRules = (
    merchantNameInput: string
  ): void => {
    setMerchantNameMigrateRules(merchantNameInput);
  };
  const handlerMigrateRules = (): void => {
    props.getMigrateRules(commerceRules, merchantNameMigrateRules, merchantId);
  };

  return {
    showModal,
    isMobile,
    rule,
    ruleType,
    rulesByType,
    commerceRules,
    lastEvaluatedKey,
    whitelistRules,
    lastEvaluatedKeyWhiteList,
    merchantName,
    merchantId,
    actions: {
      handlerShowModal,
      handlerEditRule,
      handlerDeleteRule,
      handlerUpdateStateRule,
      handlerChangeRuleType,
      handleNextAction,
      handleBackAction,
      handleNextActionCommerceRules,
      handleBackActionCommerceRules,
      handleNextActionWhitelist,
      handleBackActionWhitelist,
      handleCloseNotification,
      handleDeleteRule,
      handleDeleteWhitelistRule,
      handleSearchSecurityRulesCommerce,
      handleSearchWhitelistRule,
      handleSetMerchantName,
      handleSearchTextMerchantList,
      handlerShowModalRuleByType,
      handlerSetMerchantNameMigrateRules,
      handlerMigrateRules,
    },
    handlerSetIndexRedirect,
    routeRedirect,
    labelRedirect,
  };
};
