import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import * as React from "react";
import { FC } from "react";
import { TableSkeletonStyles } from "./TableSkeleton.styles";
import { TableNodesStyle } from "../../Table/TableTransactions/TableTransactions.styles";
import { TableBodyStyles } from "../../Table/TableBody/TableBody.style";
import { defaultTo, times } from "lodash";
import { TableHeadStyles } from "../../Table/TableHead/TableHead.style";

function SkeletonTableHead() {
  const rowsShow: string[] = ["1", "2", "3", "4", "5", "6"];

  return (
    <TableHead>
      <TableRow sx={TableHeadStyles.row}>
        <TableCell
          padding="checkbox"
          sx={{ paddingLeft: "3%", paddingRight: "3%" }}
        />
        {defaultTo(rowsShow, []).map((headCell) => (
          <TableCell
            sx={TableHeadStyles.label}
            key={`ID-${headCell}-key`}
            align={"center"}
            data-testid="TableCellHeader"
          >
            <Skeleton
              variant={"rectangular"}
              animation="wave"
              sx={TableSkeletonStyles.titleSkeletonMobile}
            />
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const TableSkeleton: FC = () => {
  const rowsShow: string[] = ["1", "2", "3", "4", "5", "6"];

  return (
    <Box data-testid="TableSkeleton" width={"100%"}>
      <TableContainer sx={{ width: "100%" }}>
        <Box sx={{ margin: 0, width: "100%" }}>
          <Table sx={TableNodesStyle.table} aria-labelledby="tableTitle">
            <SkeletonTableHead />
            <TableBody>
              {times(rowsShow.length, (num) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  sx={TableBodyStyles.row}
                  key={`row-${num}`}
                >
                  <TableCell
                    scope="row"
                    padding="none"
                    align={"center"}
                    sx={{ paddingLeft: "2.5%", width: "6%" }}
                  >
                    <Skeleton variant={"rectangular"} animation="wave" />
                  </TableCell>
                  {rowsShow.map((item) => (
                    <TableCell
                      key={`Key-${item}-${num}`}
                      scope="row"
                      padding="none"
                      align={"center"}
                      data-testid={`TableCell-${item}`}
                    >
                      <Skeleton
                        variant={"rectangular"}
                        animation="wave"
                        sx={TableSkeletonStyles.titleSkeletonMobile}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
    </Box>
  );
};

export { TableSkeleton };
