import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { get, includes } from "lodash";
import React, { FC } from "react";
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormGetValues,
} from "react-hook-form";
import { AddIcon } from "../../assets/images/addIcon";
import { EditIcon } from "../../assets/images/editIcon";
import { SaveIcon } from "../../assets/images/saveIcon";
import { PersonType } from "../../shared/constants/PersonTypeEnum";
import {
  DOCUMENT_TYPE_LABEL,
  DOCUMENT_TYPE_VALUES,
  MAX_SHAREHOLDERS,
  PERSON_TYPE_ITEMS,
  PERSON_TYPE_LABEL,
  SHAREHOLDER_FORM_OPTIONAL_KEYS,
  SHAREHOLDER_KEY,
} from "../../shared/constants/ShareholdersConstants";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import OnlyLettersInput from "../InputForms/OnlyLettersInput/OnlyLettersInput";
import OnlyNumbersInput from "../InputForms/OnlyNumbersInput/OnlyNumbersInput";
import SelectItemsInput from "../InputForms/SelectItemsInput/SelectItemsInput";
import TextInput from "../InputForms/TextInput/TextInput";
import ToggleInput from "../InputForms/ToggleInput/ToggleInput";
import { IShareholdersFormProps } from "./Shareholders.interfaces";
import { shareholdersFormStyles } from "./Shareholders.styles";
import { useShareholderFormState } from "./state/useShareholderFormState";
import { SecurityWrapperEnum } from "../../shared/constants/SecurityWrapperEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

const getFormNatural = (
  index: number,
  control: Control<FieldValues, Object>,
  errors: FieldErrors
) => (
  <>
    <Grid item xs={12} md={6}>
      <OnlyLettersInput
        control={control}
        errors={errors}
        isRequired={true}
        name={`${SHAREHOLDER_KEY}.${index}.firstName`}
        controlName={`${SHAREHOLDER_KEY}[${index}]firstName`}
        label={"Primer nombre*"}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <OnlyLettersInput
        control={control}
        errors={errors}
        name={`${SHAREHOLDER_KEY}.${index}.secondName`}
        label={"Segundo nombre (Opcional)"}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <OnlyLettersInput
        control={control}
        errors={errors}
        isRequired={true}
        name={`${SHAREHOLDER_KEY}.${index}.surName`}
        controlName={`${SHAREHOLDER_KEY}[${index}]surName`}
        label={"Primer apellido*"}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <OnlyLettersInput
        control={control}
        errors={errors}
        name={`${SHAREHOLDER_KEY}.${index}.lastName`}
        label={"Segundo apellido (Opcional)"}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <SelectItemsInput
        name={`${SHAREHOLDER_KEY}.${index}.documentType`}
        controlName={`${SHAREHOLDER_KEY}[${index}]documentType`}
        isRequired={true}
        control={control}
        errors={errors}
        label={"Tipo de documento*"}
        items={DOCUMENT_TYPE_VALUES}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <OnlyNumbersInput
        control={control}
        errors={errors}
        isRequired={true}
        name={`${SHAREHOLDER_KEY}.${index}.documentNumber`}
        controlName={`${SHAREHOLDER_KEY}[${index}]documentNumber`}
        label={"Número de documento*"}
      />
    </Grid>
  </>
);

const mapSummaryValues = (
  value: JSX.Element,
  indexMapSummary: number,
  position: number
) => (
  <Grid
    container
    item
    xs={12}
    md={6}
    columnSpacing={2}
    key={`summary-${indexMapSummary}.${position}`}
  >
    {value}
  </Grid>
);

const getNaturalSummaryForm = (
  getValues: UseFormGetValues<FieldValues>,
  index: number
) => {
  const summaryValues = [
    <>
      <Grid item xs={4}>
        <Typography sx={shareholdersFormStyles.summaryLabel}>
          Nombre 1
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography sx={shareholdersFormStyles.summaryValue}>
          {getValues(`${SHAREHOLDER_KEY}.${index}.firstName`)}
        </Typography>
      </Grid>
    </>,
    <>
      <Grid item xs={4}>
        <Typography sx={shareholdersFormStyles.summaryLabel}>
          Nombre 2
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography sx={shareholdersFormStyles.summaryValue}>
          {getValues(`${SHAREHOLDER_KEY}.${index}.secondName`)}
        </Typography>
      </Grid>
    </>,
    <>
      <Grid item xs={4}>
        <Typography sx={shareholdersFormStyles.summaryLabel}>
          Apellido 1
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography sx={shareholdersFormStyles.summaryValue}>
          {getValues(`${SHAREHOLDER_KEY}.${index}.surName`)}
        </Typography>
      </Grid>
    </>,
    <>
      <Grid item xs={4}>
        <Typography sx={shareholdersFormStyles.summaryLabel}>
          Apellido 2
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography sx={shareholdersFormStyles.summaryValue}>
          {getValues(`${SHAREHOLDER_KEY}.${index}.lastName`)}
        </Typography>
      </Grid>
    </>,
    <>
      <Grid item xs={6}>
        <Typography sx={shareholdersFormStyles.summaryLabel}>
          Tipo de documento
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={shareholdersFormStyles.summaryValue}>
          {
            DOCUMENT_TYPE_LABEL[
              getValues(`${SHAREHOLDER_KEY}.${index}.documentType`)
            ]
          }
        </Typography>
      </Grid>
    </>,
    <>
      <Grid item xs={7}>
        <Typography sx={shareholdersFormStyles.summaryLabel}>
          Número de documento
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography sx={shareholdersFormStyles.summaryValue}>
          {getValues(`${SHAREHOLDER_KEY}.${index}.documentNumber`)}
        </Typography>
      </Grid>
    </>,
    <>
      <Grid item xs={6}>
        <Typography sx={shareholdersFormStyles.summaryLabel}>
          Tipo de persona
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={shareholdersFormStyles.summaryValue}>
          {
            PERSON_TYPE_LABEL[
              getValues(`${SHAREHOLDER_KEY}.${index}.personType`)
            ]
          }
        </Typography>
      </Grid>
    </>,
  ];

  return summaryValues.map((value, indexSummary) =>
    mapSummaryValues(value, indexSummary, index)
  );
};

const getLegalSummaryForm = (
  getValues: UseFormGetValues<FieldValues>,
  index: number
) => {
  const summaryValues = [
    <>
      <Grid item xs={5}>
        <Typography sx={shareholdersFormStyles.summaryLabel}>
          Razón social
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography sx={shareholdersFormStyles.summaryValue}>
          {getValues(`${SHAREHOLDER_KEY}.${index}.socialReason`)}
        </Typography>
      </Grid>
    </>,
    <>
      <Grid item xs={8}>
        <Typography sx={shareholdersFormStyles.summaryLabel}>
          Número de documento
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography sx={shareholdersFormStyles.summaryValue}>
          {getValues(`${SHAREHOLDER_KEY}.${index}.documentNumber`)}
        </Typography>
      </Grid>
    </>,
  ];

  return summaryValues.map((value, indexSummary) =>
    mapSummaryValues(value, indexSummary, index)
  );
};

const getFormLegal = (
  index: number,
  control: Control<FieldValues, Object>,
  errors: FieldErrors
) => (
  <>
    <Grid item xs={12} md={6}>
      <TextInput
        control={control}
        errors={errors}
        isRequired={true}
        name={`${SHAREHOLDER_KEY}.${index}.socialReason`}
        controlName={`${SHAREHOLDER_KEY}[${index}]socialReason`}
        label={"Razón social*"}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <TextInput
        control={control}
        errors={errors}
        isRequired={true}
        name={`${SHAREHOLDER_KEY}.${index}.documentNumber`}
        controlName={`${SHAREHOLDER_KEY}[${index}]documentNumber`}
        label={"Número de documento*"}
      />
    </Grid>
  </>
);

const isTheLastShareholderComplete = (
  fieldsLength: number,
  getValues: UseFormGetValues<FieldValues>
) => {
  const values = Object.entries(
    getValues(`${SHAREHOLDER_KEY}.${fieldsLength - 1}`) || { foo: "" }
  );

  return values
    .filter((value) => !includes(SHAREHOLDER_FORM_OPTIONAL_KEYS, value[0]))
    .every((value) => value[1] !== "");
};

const showSaveButton = (index: number, fieldsLength: number): boolean => {
  return fieldsLength === MAX_SHAREHOLDERS || index < fieldsLength - 1;
};

const ShareholdersForm: FC<IShareholdersFormProps> = ({
  clearErrors,
  control,
  errors,
  getValues,
  watch,
}: IShareholdersFormProps) => {
  const {
    fields,
    formSummaryState,
    handleAddForm,
    handleChangeForm,
    handleClickEditButton,
    handleCloseDeleteModal,
    handleDeleteShareholder,
    handleOpenDeleteModal,
    handleSaveForm,
    openDeleteModal,
  } = useShareholderFormState(control, clearErrors);

  watch(SHAREHOLDER_KEY);

  return (
    <>
      <Box sx={shareholdersFormStyles.boxContainer}>
        <Grid container>
          <Grid item xs={12}>
            <Typography sx={shareholdersFormStyles.title}>
              Detallar socios/accionistas del capital social o aportes.
            </Typography>
            <Typography sx={shareholdersFormStyles.subtitle}>
              *Debes ingresar mínimo 1 y máximo 12 Socios/Accionistas.
            </Typography>
          </Grid>
          {fields.map((field, index) => (
            <Grid container item xs={12} key={field.key} id={"grid-form"}>
              <Grid item xs={12}>
                <Box py={4}>
                  <Typography sx={shareholdersFormStyles.title}>
                    Socio {index + 1}
                  </Typography>
                  <Typography sx={shareholdersFormStyles.subtitle}>
                    * Estos datos son obligatorios
                  </Typography>
                </Box>
              </Grid>
              {formSummaryState[index] ? (
                <Box sx={shareholdersFormStyles.boxSummary}>
                  <Grid container>
                    {watch(`${SHAREHOLDER_KEY}.${index}.personType`) ===
                    PersonType.NATURAL
                      ? getNaturalSummaryForm(getValues, index)
                      : getLegalSummaryForm(getValues, index)}
                    <Grid item xs={12} id={"summary-actions"}>
                      <Box sx={shareholdersFormStyles.boxSummaryButtons}>
                        {fields.length > 1 && (
                          <Button
                            variant={"text"}
                            size={"large"}
                            sx={shareholdersFormStyles.summaryDeleteButton}
                            onClick={() => {
                              handleOpenDeleteModal(index);
                            }}
                          >
                            Eliminar
                          </Button>
                        )}
                        <Button
                          variant={"contained"}
                          color={"primary"}
                          size={"large"}
                          startIcon={<EditIcon />}
                          onClick={() => {
                            handleClickEditButton(index);
                          }}
                          sx={shareholdersFormStyles.editButton}
                        >
                          Editar
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Box pb={4}>
                      <Typography sx={shareholdersFormStyles.subtitle}>
                        Selecciona el tipo de persona
                      </Typography>
                      <ToggleInput
                        control={control}
                        errors={errors}
                        name={`${SHAREHOLDER_KEY}.${index}.personType`}
                        defaultValue={PersonType.NATURAL}
                        items={PERSON_TYPE_ITEMS}
                        onChange={(event) => {
                          handleChangeForm(
                            index,
                            get(
                              event,
                              "target.value",
                              PersonType.LEGAL
                            ) as PersonType
                          );
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid container item xs={12} columnSpacing={2} rowSpacing={2}>
                    {watch(`${SHAREHOLDER_KEY}.${index}.personType`) ===
                    PersonType.NATURAL
                      ? getFormNatural(index, control, errors)
                      : getFormLegal(index, control, errors)}

                    {fields.length > 1 && (
                      <>
                        <Grid
                          item
                          xs={12}
                          id={"form-actions"}
                          justifyContent={"flex-end"}
                          display={"flex"}
                        >
                          <Box sx={shareholdersFormStyles.boxSummaryButtons}>
                            <Button
                              variant={"text"}
                              color={"warning"}
                              size={"large"}
                              onClick={() => {
                                handleOpenDeleteModal(index);
                              }}
                              sx={shareholdersFormStyles.deleteButton}
                            >
                              Eliminar
                            </Button>
                            {showSaveButton(index, fields.length) && (
                              <ComponentSecurityWrapper
                                componentId={SecurityWrapperEnum.SAVE}
                              >
                                <Button
                                  variant={"contained"}
                                  color={"primary"}
                                  size={"large"}
                                  startIcon={<SaveIcon />}
                                  onClick={() => {
                                    handleSaveForm(index);
                                  }}
                                  sx={shareholdersFormStyles.editButton}
                                >
                                  Guardar
                                </Button>
                              </ComponentSecurityWrapper>
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider sx={shareholdersFormStyles.divider} />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          ))}
          <Grid item xs={12} justifyContent={"flex-end"} display={"flex"}>
            {fields.length < MAX_SHAREHOLDERS && (
              <Button
                variant={"contained"}
                color={"primary"}
                size={"large"}
                onClick={handleAddForm}
                disabled={
                  !isTheLastShareholderComplete(fields.length, getValues)
                }
                startIcon={<AddIcon />}
                sx={shareholdersFormStyles.addButton}
              >
                Agregar socio
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
      <ConfirmationModal
        handleCancel={handleCloseDeleteModal}
        handleAccept={handleDeleteShareholder}
        isOpen={openDeleteModal}
      />
    </>
  );
};

export default ShareholdersForm;
