import { ITabItem } from "../../components/WrappedTabs/TabItem/TabItem.interfaces";
import { ROUTES } from "./routes";
import { TabItemStatusEnum } from "./enums/tabItemStatusEnum";
import { getDate } from "../utils/date_utils";
import { ILastModify } from "../interfaces/LastModify.interfaces";

export const TABS_DEFAULT_STATE: ITabItem[] = [
  {
    isDisabled: true,
    redirectPath: ROUTES.CREDENTIALS,
    status: TabItemStatusEnum.IN_PROCESS,
    title: "Credenciales",
  },
  {
    isDisabled: false,
    redirectPath: ROUTES.INDEX,
    status: TabItemStatusEnum.IN_PROCESS,
    title: "Webhooks",
  },
];

export const buildLastModifyProps = (
  updatedAt: number,
  userName: string
): ILastModify => ({
  author: userName,
  date: getDate(updatedAt, "dd/MM/yyyy - HH:mm"),
});
