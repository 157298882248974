import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import React, { FC } from "react";
import InputMask from "react-input-mask";
import { IInputsFormProps } from "../InputsFormProps.interfaces";
import { ERROR_MESSAGES } from "../../../shared/constants/inputFormConstants";
import { get } from "lodash";

const OnlyNumbersInput: FC<IInputsFormProps> = ({
  name,
  control,
  controlName = name,
  errors,
  label,
  defaultValue = "",
  sx = {},
  isRequired,
}) => {
  return (
    <Controller
      name={name}
      rules={{
        ...(isRequired && { required: ERROR_MESSAGES.required }),
      }}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <InputMask
          mask="99999999999999"
          value={value as string}
          onChange={onChange}
          // @ts-ignore
          maskChar={null}
        >
          {(inputProps: any) => (
            <TextField
              {...inputProps}
              label={label}
              variant="outlined"
              size="small"
              fullWidth
              error={get(errors, controlName) !== undefined}
              helperText={get(errors, controlName)?.message ?? ""}
              sx={sx}
            />
          )}
        </InputMask>
      )}
    />
  );
};

export default OnlyNumbersInput;
