export const ACCOUNT_PREFERENCE_IMAGE_LABEL = "Imagen (logotipo del comercio)";
export const COLOR_LABEL = "Colores de la marca";
export const PRIMARY_COLOR = "Color principal:";
export const SECONDARY_COLOR = "Color secundario:";
export const SUPPORT_LABEL = "Información de soporte";
export const PHONE_PLACE_HOLDER =
  "El teléfono corresponde al país de constitución.";
export const TERMS_CONDITIONS_LABEL = "Términos y condiciones";
export const ERROR_EMPTY_IMAGE = "Es necesario seleccionar una imagen";
export const ERROR_EMPTY_EMAIL =
  "Por favor ingresa un correo electrónico válido";
export const ERROR_EMPTY_PHONE = "Es necesario ingresar el número de teléfono";
export const ERROR_EMPTY_COLOR = "Este campo es obligatorio";
export const ERROR_EMPTY_TERMS =
  "Es necesario ingresar los términos y condiciones de la empresa";
