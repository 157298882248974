import { useAppSelector } from "../../../store/hooks/storeHook";
import { useEffect, useState } from "react";
import {
  IUseSnackBarAlert,
  IUseSnackBarAlertProps,
} from "./useSnackBarAlert.interfaces";

export const useSnackBarAlert = ({
  rootStoreKey,
}: IUseSnackBarAlertProps): IUseSnackBarAlert => {
  const { alert } = useAppSelector((state) => state[rootStoreKey]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (alert) handleOpen();
  }, [alert]);

  return {
    alert,
    handleClose,
    open,
  };
};
