import { SxProps, Theme } from "@mui/material";
import { createNamedStyles } from "../../../../shared/utils";

export const expandableStyles = createNamedStyles({
  container: {
    borderBottomColor: "neutral.grey5",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    overflow: "hidden",
  },
  colorPrimary: {
    color: "neutral.grey8",
  },
  title: {
    fontSize: "14px",
  },
  header: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    mb: 1,
  },
});

export const buttonStyle = (expanded: boolean): SxProps<Theme> => ({
  height: "40px",
  transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
  transition: (theme) =>
    theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  p: 1,
  width: "40px",
});
