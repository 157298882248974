import React, { FC, useState } from "react";
import { ModalSimple, useSnackbar } from "@kushki/connect-ui";
import { buildNotification } from "../shared/constants/snackBar";
import { NotificationTypeEnum } from "../shared/enums/SnackbarEnum";
import {
  CANCELAR,
  CENTRALIZAR,
  CENTRALIZE_TEXT,
  CENTRALIZE_TITLE,
  DECENTRALIZE_DEFAULT_TEXT,
  DESCENTRALIZAR,
  DESCENTRALIZE_TITLE,
} from "../shared/constants/labels/main_containter_labels";

const TestModal: FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const { showSnackbar } = useSnackbar();

  return (
    <div>
      <button data-testid={"b1"} onClick={() => setOpenModal(true)}>
        CENTRLIZAR BRANCH
      </button>
      <button data-testid={"b2"} onClick={() => setOpenModal2(true)}>
        DESCENTRLIZAR BRANCH
      </button>
      <ModalSimple
        onClickPrimary={() => {
          showSnackbar(
            buildNotification(NotificationTypeEnum.CENTRALIZE_SUCCES, {
              color: "success",
              message: "Se centralizó los 8 Branches con éxito",
              variant: "simple",
              withIcon: false,
            })
          );
        }}
        onClickSecondary={() => setOpenModal(false)}
        buttonPrimary={CENTRALIZAR}
        buttonSecondary={CANCELAR}
        descriptionText={CENTRALIZE_TEXT}
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        titleText={CENTRALIZE_TITLE(4)}
        typeModal={1}
      />
      <ModalSimple
        onClickPrimary={() => {
          showSnackbar(
            buildNotification(NotificationTypeEnum.DESCENTRALIZE_SUCCESS, {
              color: "success",
              message: "Se descentralizó los 4 Branches con éxito",
              variant: "simple",
              withIcon: false,
            })
          );
        }}
        onClickSecondary={() => setOpenModal2(false)}
        buttonPrimary={DESCENTRALIZAR}
        buttonSecondary={CANCELAR}
        descriptionText={DECENTRALIZE_DEFAULT_TEXT}
        isOpen={openModal2}
        onClose={() => setOpenModal2(false)}
        titleText={DESCENTRALIZE_TITLE(7)}
        typeModal={1}
      />
    </div>
  );
};

export default TestModal;
