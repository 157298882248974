import { ChargebackStatusEnum } from "../enums/ChargebackStatusEnum";

export const REDIRECT_CHARGEBACK_STATUS: string[] = [
  ChargebackStatusEnum.INITIALIZED,
];

export enum TitleSection {
  MERCHANT_DETAILS = "Datos del comercio",
  CHARGEBACK_DETAILS = "Detalle de contracargo",
  TRANSACTION_DETAILS = "Detalle de transacción",
  NOTIFICATION_DETAILS = "Notificación de contracargo",
  DOCUMENTATION_DETAILS = "Documentación de respaldo",
}

export enum ChargebackRoles {
  M_TRANSACTIONS = "M_TRANSACTIONS",
  M_TRANSACTIONS_CHARGEBACK = "M_TRANSACTIONS.CHARGEBACK",
  UPLOAD_FILE = "Contracargos.CargarArchivo",
  CHARGEBACK_CHANGE_STATUS = "Contracargos.CambiarEstado",
  CHARGEBACK_DETAIL_DOWNLOAD_LETTER = "Contracargos.DescargarCartaDeEstablecimiento",
  CHARGEBACK_DETAIL_DOWNLOAD_DOC = "Contracargos.DescargarDocumentos",
}
