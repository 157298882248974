import { IUseMainContainer } from "./useCheckoutContainer.interfaces";
import {
  initialCheckoutData,
  useCheckoutContext,
} from "../context/useCheckoutContext";
import { StepperEnum } from "../../../shared/infrastructure/StepperEnum";
import { useAppDispatch } from "../../../store/hooks/storeHook";
import {
  setCreateExpressCheckoutStatus,
  setExpressCheckout,
  setPaymentMethods,
  setPaymentMethodsStatus,
} from "../../../store/actions/app.actions";
import { RequestStatusEnum } from "../../../shared/enums/RequestStatusEnum";

export const useCheckoutContainer = (): IUseMainContainer => {
  const dispatch = useAppDispatch();
  const {
    isMobile,
    step,
    renderContent,
    setStep,
    setWebcheckoutUrl,
    setCheckoutData,
  } = useCheckoutContext();

  const handleStepNext = () => {
    setStep(step + 1);
  };

  const handleStepBack = () => {
    setStep(step - 1);
  };

  const handleStepInit = () => {
    dispatch(setPaymentMethodsStatus(RequestStatusEnum.PENDING));
    dispatch(setCreateExpressCheckoutStatus(RequestStatusEnum.PENDING));
    dispatch(setPaymentMethods([]));
    dispatch(setExpressCheckout(undefined));

    setWebcheckoutUrl("");
    setCheckoutData(initialCheckoutData);

    setStep(StepperEnum.FORM);
  };

  return {
    handleStepBack,
    handleStepInit,
    handleStepNext,
    isMobile,
    renderContent,
    step,
  };
};
