export enum TransactionDetailEnum {
  OPERATION_TYPE = "Tipo de Operación",
  ORIGIN_TICKET_NUMBER = "Ticket number origen",
  ORIGIN = "Origen",
  ECONOMIC_ACTIVITY = "Actividad económica",
  OPERATION_AMOUNT = "Monto de operación",
  NAME_BENEFIT = "Nombre del beneficiario",
  ACCOUNT = "Cuenta CLABE",
  CONCEPT = "Concepto de pago",
  DATA_BENEFIT = "Datos para transferencia",
  ORDER_NUMBER = "No. de orden",
}
