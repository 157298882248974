import { ICountryConditionSectionProps } from "../../../../../shared/infrastructure/interfaces/ICountryConditionSectionProps";
import { useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";
import { defaultTo, get } from "lodash";
import { Country } from "../../../../../../types/geography_list";
import { isFormEditMode } from "../../../../../utils/utilsFile";
import { RuleEnum } from "../../../../../shared/infrastructure/enums/RuleEnum";
import { IUseCountryConditionSectionState } from "../../../../../shared/infrastructure/interfaces/IUseCountryConditionSectionState";

export const useCountryConditionSectionState = (
  props: ICountryConditionSectionProps
): IUseCountryConditionSectionState => {
  const { ruleId } = useParams();
  const { errors, control, setValue, watch, getValues } = useFormContext();
  const [selectedRegionAccumulator, setSelectedRegionAccumulator] =
    useState<number>(0);
  const [countryToShowEdit, setCountryToShowEdit] = useState<ICategory[]>([]);
  const [referenceCode, setReferenceCode] = useState<string>("");

  const countrySelected: string[] = getValues(`condition.${props.index}.value`);
  const continents: string[] = defaultTo(watch("continents"), []);
  const regions: string[] = defaultTo(watch("regions"), []);

  const [countryEdited, setCountryEdited] = useState<string[]>(countrySelected);

  const isRenderEditValues = (): boolean => {
    return (
      isFormEditMode(ruleId) &&
      selectedRegionAccumulator === 0 &&
      get(props, "defaultValueList.length", 0) > 0
    );
  };

  useEffect(() => {
    const countryToRetrieve: ICategory[] = [];

    if (
      isFormEditMode(ruleId) &&
      get(props, "defaultValueList.length", 0) > 0
    ) {
      props.defaultValueList.forEach((country: string) => {
        countryToRetrieve.push({
          label: country,
          value: country,
          selected: true,
        });
      });
      setCountryToShowEdit(countryToRetrieve);
    }
  }, [ruleId]);

  useEffect(() => {
    if (countryToShowEdit.length > 0) {
      const codeCountry: (string | undefined)[] = defaultTo(
        get(props.geographyList, "countries"),
        []
      )
        .filter((data: Country) => data.name === countryToShowEdit[0].label)
        .map((data: Country) => data.referenceCode);

      if (codeCountry.length > 0 && codeCountry) {
        setReferenceCode(codeCountry[0]!.toString());
      }
    }
  }, [countryToShowEdit, props.geographyList]);

  useEffect(() => {
    if (
      isFormEditMode(ruleId) &&
      get(props, "defaultValueList.length", []) > 0
    ) {
      setValue("continents", "America");
      props.setSelectedItems([
        {
          label: RuleEnum.COUNTRY,
          value: countryEdited.toString(),
          selected: true,
        },
      ]);
    }
  }, [ruleId, countryEdited]);

  return {
    control,
    continents,
    regions,
    errors,
    selectedRegionAccumulator,
    countryToShowEdit,
    referenceCode,
    actions: {
      setSelectedRegionAccumulator,
      setCountryEdited,
      setValue,
      isRenderEditValues,
    },
    geographyList: props.geographyList,
  };
};
