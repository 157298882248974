import { useAppSelector } from "../../../store/hooks/hooks";
import { selectAreThereFiltersSelected } from "../../../store/selectors/filters/filters";
import { IUseTransactionsEmptyState } from "./useTransactionsEmptyState.interfaces";

export const TITLE_WITH_NO_FILTERS =
  "Bandeja de registros de transacciones vacía";
export const SUBTITLE_WITH_NO_FILTERS =
  "Esta bandeja no presenta mas registros que revisar.";

export const TITLE_WITH_FILTERS =
  "No se han encontrado registros que coincidan con la búsqueda";
export const SUBTITLE_WITH_FILTERS = "Intenta con una nueva búsqueda.";

export const useTransactionsEmptyState = (): IUseTransactionsEmptyState => {
  const areThereFiltersSelected = useAppSelector(selectAreThereFiltersSelected);

  return {
    subtitle: areThereFiltersSelected
      ? SUBTITLE_WITH_FILTERS
      : SUBTITLE_WITH_NO_FILTERS,
    title: areThereFiltersSelected ? TITLE_WITH_FILTERS : TITLE_WITH_NO_FILTERS,
  };
};
