export enum NodeSelectionLabels {
  HEADER_TITLE = "Configuración de alarmas  -  Crear alarma por cliente - *Selecciona los clientes*",
  ALARM_CONFIG = "Configuración de alarmas  -  *Crear alarma por cliente(s)*",
  GENERAL_ALARM_CONFIG = "Configuración de alarmas  -  *Crear alarma general*",
  EDIT_ALARM_CONFIG = "Edición de alarmas  -  *Editar alarma por cliente(s)*",
  EDIT_GENERAL_ALARM_CONFIG = "Edición de alarmas  -  *Editar alarma general*",
  SEARCH_LABEL = "Escribe el nombre del cliente...",
  TITLE = "Selecciona uno o varios clientes para configurar la alarma",
  LOCAL_SEARCH = "Buscar en la tabla...",
}
