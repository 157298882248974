import React, { FC } from "react";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { mainContainerStyles as styles } from "../MainContainer/MainContainer.styles";
import { Flag } from "../../components/Flag/Flag";
import { RadioButtonRow } from "../../components/RadioButtonRow/RadioButtonRow";
import { Category } from "../../shared/enum/BasicStateGeneral/CatalogBasicData";
import TextInput from "../../components/TextInput/TextInput";
import { BasicDataInterface } from "../../shared/interfaces/BasicData.interface";
import DatePickers from "../../components/DatePickers/DatePicker";
import {
  BasicDataLabels,
  ErrorMessageMX,
  RegularExp,
  TypeElement,
  TypeListMX,
} from "../../shared/enum/BasicStateMX/ErrorMessageMX";
import { DateFormatEnum } from "../../shared/enum/DateFormatEnum";
import { IBasicContainerProps } from "../../shared/interfaces/IBasicContainerProps.interface";
import { basicContainerStyles } from "./BasicContainer.styles";
import ComboBox from "../../components/ComboBox/ComboBox";
import { defaultTo, get } from "lodash";
import BoxGenericInput from "../../components/BoxGenericInput/BoxGenericInput";
import { validCountries } from "../../shared/constants/validCountries";
import { CountryEnum } from "../../shared/countryEnum";
import { getCountryName } from "../../shared/constants/Countries";
import TextInputCapitalLetters from "../../components/TextInputCapitalLetters/TextInputCapitalLetters";

export const BasicContainer: FC<IBasicContainerProps> = ({
  country,
  title,
  form,
  countryElements,
  auxRules,
  showGenericInput,
  isDisable,
}: IBasicContainerProps) => {
  const listCombo = (list: string): Array<string | Category> => {
    switch (list) {
      case TypeListMX.MCC:
        return countryElements.mcc ?? [];
      case TypeListMX.INDUSTRY:
        return countryElements.industryType;
      case TypeListMX.COMPANY:
        return countryElements.companyType;
      case TypeListMX.TAX_ID_EC:
        return countryElements.typeOfTaxId ?? [];
      case TypeListMX.ICA_RETENTIONS:
        return defaultTo(countryElements.retentionICA, []);
      default:
        return [];
    }
  };

  return (
    <>
      <Card sx={styles.cardContent}>
        <CardContent>
          <Box sx={styles.boxContent2}>
            <Typography
              color="text.primary"
              fontSize={26}
              variant="h1"
              fontWeight={600}
              sx={basicContainerStyles.title}
            >
              {BasicDataLabels.DATOS}
            </Typography>
            <Divider sx={basicContainerStyles.divider} />
            <Grid
              container
              spacing={1}
              alignItems="center"
              sx={basicContainerStyles.componentSpace}
            >
              <Grid item>
                <Typography variant="body2" color="text.disabled" fontSize={16}>
                  {BasicDataLabels.PAIS_CONST}
                </Typography>
              </Grid>
              <Grid item alignItems="center">
                <Box sx={basicContainerStyles.flag}>
                  {validCountries.includes(country) ? (
                    <Flag country={country} />
                  ) : (
                    <Flag country={CountryEnum.generic} />
                  )}
                </Box>
              </Grid>
              <Grid item alignItems="center">
                <Typography variant="body2" color="text.disabled" fontSize={16}>
                  {getCountryName(country)} - Cliente {title}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center">
              <Grid item sx={basicContainerStyles.componentSpaceTitle}>
                <Typography color="text.grey" fontSize={20}>
                  {BasicDataLabels.INFO_PRINCIPAL}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              alignItems="center"
              sx={basicContainerStyles.componentSpace}
            >
              <Grid item>
                <Typography variant="body2" color="text.dark" fontSize={16}>
                  {BasicDataLabels.TIPO_PERSONA}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <RadioButtonRow
                  isDisable={isDisable}
                  options={countryElements.radioListPersons}
                  control={form.control}
                  name={"typePerson"}
                  errors={form.formState.errors}
                  rules={{
                    required: {
                      message: ErrorMessageMX.PF_TIPO_PERSONA,
                      value: true,
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              alignItems="center"
              sx={basicContainerStyles.componentSpace}
            >
              <Grid item>
                <Typography variant="body2" color="text.dark" fontSize={16}>
                  {BasicDataLabels.INFO_BASICA}
                </Typography>
              </Grid>
            </Grid>

            <Grid container alignItems="flex-start" spacing={3}>
              {countryElements.fieldsRender
                .filter((data) => {
                  return (
                    data.filter === form.getValues("typePerson")! &&
                    data.type.includes(TypeElement.TEXT)
                  );
                })
                .map((elementField) => {
                  return (
                    <Grid item xs={elementField.value} key={elementField.label}>
                      {elementField.type === TypeElement.TEXT && (
                        <TextInput
                          control={form.control}
                          name={elementField.name as keyof BasicDataInterface}
                          label={elementField.label}
                          errors={form.formState.errors}
                          rules={
                            get(elementField, "needCombo", false)
                              ? auxRules
                              : elementField.rules
                          }
                          regExp={
                            get(elementField, "extraRegExp", false)
                              ? get(
                                  auxRules,
                                  "pattern.value",
                                  RegularExp.ONLY_NUMBERS
                                )
                              : elementField.regExp
                          }
                          disabled={isDisable}
                        />
                      )}

                      {elementField.type === TypeElement.TEXT_CAPITAL && (
                        <TextInputCapitalLetters
                          control={form.control}
                          name={elementField.name as keyof BasicDataInterface}
                          label={elementField.label}
                          errors={form.formState.errors}
                          rules={elementField.rules}
                          regExp={elementField.regExp}
                          disabled={isDisable}
                        />
                      )}

                      {elementField.type === TypeElement.TEXT_COMBO && (
                        <ComboBox
                          label={elementField.label}
                          options={listCombo(elementField.name)}
                          control={form.control}
                          errors={form.formState.errors}
                          name={elementField.name as keyof BasicDataInterface}
                          typeCombo={elementField.typeArray!}
                          rules={elementField.rules}
                          isMcc={elementField.label === BasicDataLabels.MCC}
                          isDisable={isDisable}
                        />
                      )}
                    </Grid>
                  );
                })}
            </Grid>

            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} sx={basicContainerStyles.componentSpace}>
                <div></div>
              </Grid>
            </Grid>

            <Divider sx={basicContainerStyles.divider} />

            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12}>
                <div></div>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              alignItems="center"
              sx={basicContainerStyles.componentSpace}
            >
              <Grid item>
                <Typography color="text.grey" fontSize={20}>
                  {BasicDataLabels.INFO_NEGOCIO}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              {countryElements.fieldsRender
                .filter((data) => {
                  return (
                    data.filter === form.getValues("typePerson")! &&
                    !data.type.includes("text")
                  );
                })
                .map((elementField) => {
                  return (
                    <Grid item xs={elementField.value} key={elementField.label}>
                      {elementField.type === TypeElement.COMBO && (
                        <ComboBox
                          label={elementField.label}
                          options={listCombo(elementField.name)}
                          control={form.control}
                          errors={form.formState.errors}
                          name={elementField.name as keyof BasicDataInterface}
                          typeCombo={elementField.typeArray!}
                          rules={elementField.rules}
                          isMcc={elementField.label === BasicDataLabels.MCC}
                          isDisable={isDisable}
                        />
                      )}

                      {elementField.type === TypeElement.WEB && (
                        <TextInput
                          control={form.control}
                          name={elementField.name as keyof BasicDataInterface}
                          label={elementField.label}
                          errors={form.formState.errors}
                          rules={elementField.rules}
                          regExp={elementField.regExp}
                          disabled={isDisable}
                        />
                      )}

                      {elementField.type === TypeElement.DATE_PICKER && (
                        <DatePickers
                          name={elementField.name as keyof BasicDataInterface}
                          control={form.control}
                          label={elementField.label}
                          errors={form.formState.errors}
                          rules={elementField.rules}
                          formateDate={DateFormatEnum.DD_MMM_YYYY}
                          isDisable={isDisable}
                        />
                      )}
                      {elementField.type === TypeElement.GENERIC &&
                        showGenericInput.companyType && (
                          <BoxGenericInput
                            direction={{ justifyContent: "flex-start" }}
                            spacing={{ paddingRight: "15px" }}
                          >
                            <TextInput
                              control={form.control}
                              name={
                                elementField.name as keyof BasicDataInterface
                              }
                              label={elementField.label}
                              errors={form.formState.errors}
                              rules={elementField.rules}
                              regExp={elementField.regExp}
                              disabled={isDisable}
                            />
                          </BoxGenericInput>
                        )}
                      {elementField.type === TypeElement.GENERIC_ACTIVITY &&
                        showGenericInput.economicActivityGeneric && (
                          <BoxGenericInput
                            direction={{ justifyContent: "flex-end" }}
                            spacing={{ paddingLeft: "15px" }}
                          >
                            <TextInput
                              control={form.control}
                              name={
                                elementField.name as keyof BasicDataInterface
                              }
                              label={elementField.label}
                              errors={form.formState.errors}
                              rules={elementField.rules}
                              regExp={elementField.regExp}
                              disabled={isDisable}
                            />
                          </BoxGenericInput>
                        )}
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};
