import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ratesRangeTableStyles as style } from "../../RatesInfo.styles";
import { Configuration } from "../../../../../types/rates_config_dynamo";
import { tail } from "lodash";
import { RatesRangesTableItem } from "./RatesRangeTableItem";
import { RatesTableLabelEnum } from "../../../../shared/enums/RatesTableLabelEnum";

export interface RatesRangesTablePropsInterface {
  configuration: Configuration[];
  country?: string;
  ratesType?: string;
  currency?: string;
}

export const RatesRangesTable: React.FC<RatesRangesTablePropsInterface> = (
  props: RatesRangesTablePropsInterface
) => {
  return (
    <Grid item xs={12} data-testid={"rates-range-table"}>
      <TableContainer sx={style.tableContainer}>
        <Table sx={style.table} padding="none">
          <TableHead>
            <TableRow>
              <TableCell align="right" sx={style.cellHeader}>
                <Typography sx={style.labelSubTitle}>
                  {RatesTableLabelEnum.FROM}
                </Typography>
              </TableCell>
              <TableCell align="right" sx={style.cellHeader}>
                <Typography sx={style.labelSubTitle}>
                  {RatesTableLabelEnum.FIXED_AMOUNT}
                </Typography>
              </TableCell>
              <TableCell align="right" sx={style.cellHeader}>
                <Typography sx={style.labelSubTitle}>
                  {RatesTableLabelEnum.VARIABLE}
                </Typography>
              </TableCell>
              <TableCell
                align="right"
                sx={style.cellHeader}
                style={{ paddingRight: "20px" }}
              >
                <Typography sx={style.labelSubTitle}>
                  {RatesTableLabelEnum.MAX_AMOUNT}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tail(props.configuration).map((row, index) => (
              <RatesRangesTableItem
                key={"rates-ranges-table-item-" + index}
                id={index}
                row={row}
                country={props.country}
                ratesType={props.ratesType}
                currency={props.currency}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};
