import { TransactionStatusEnum } from "../enums/TransactionStatusEnum";
import { TransactionTypeEnum } from "../enums/TransactionTypeEnum";
import { PaymentMethodsEnum } from "../infraestructure/payment-method";
import { ContentTaxes } from "../../components/CardInformation/CardInformation.interfaces";

export const catalogTaxListByTransaction = (
  taxList: ContentTaxes[]
): Record<string, object> => {
  const approval_state = {
    [TransactionTypeEnum.SALE]: {
      [PaymentMethodsEnum.CARD]: taxList,
      [PaymentMethodsEnum.CASH]: taxList,
      [PaymentMethodsEnum.TRANSFER]: taxList,
    },
    [TransactionTypeEnum.CAPTURE]: {
      [PaymentMethodsEnum.CARD]: taxList,
    },
    [TransactionTypeEnum.DEFFERRED]: {
      [PaymentMethodsEnum.CARD]: taxList,
    },
    [TransactionTypeEnum.PREAUTHORIZATION]: {
      [PaymentMethodsEnum.CARD]: taxList,
    },
    [TransactionTypeEnum.CHARGEBACK]: {
      [PaymentMethodsEnum.CARD]: taxList,
    },
  };

  const taxesByType: Record<string, object> = {
    [TransactionStatusEnum.APPROVAL]: approval_state,
    [TransactionStatusEnum.APPROVED]: approval_state,
    [TransactionStatusEnum.INITIALIZED]: {
      [TransactionTypeEnum.SALE]: {
        [PaymentMethodsEnum.CARD]: taxList,
        [PaymentMethodsEnum.CASH]: taxList,
        [PaymentMethodsEnum.TRANSFER]: taxList,
      },
    },
    [TransactionStatusEnum.DECLINED]: {
      [TransactionTypeEnum.SALE]: {
        [PaymentMethodsEnum.CARD]: taxList,
        [PaymentMethodsEnum.CASH]: taxList,
        [PaymentMethodsEnum.TRANSFER]: taxList,
      },
      [TransactionTypeEnum.PREAUTHORIZATION]: {
        [PaymentMethodsEnum.CARD]: taxList,
      },
      [TransactionTypeEnum.DEFFERRED]: {
        [PaymentMethodsEnum.CARD]: taxList,
      },
    },
  };

  return taxesByType;
};
