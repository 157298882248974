import { retrieveChildSlice } from "../reducers/retrieveChild/retrieveChild.slice";

export const {
  resetRetrieveChild,
  setRetrieveChildRequest,
  setRetrieveChildResponse,
  setNotification,
  setIsWaitingForRequestChild,
  setIsLoading,
} = retrieveChildSlice.actions;
