import { IOptionFilter } from "../../../components/DashboardList/BoxFilter/BoxFilter";
import { KindEnum } from "../KindEnum";
import { CycleEnum } from "../CycleEnum";
import { InvoiceModeEnum } from "../InvoiceModeEnum";
import { FilterTypeEnum } from "../FilterTypeEnum";
import { CountryEnum } from "../CountryEnum";
import { ModelsEnum } from "../ModelEnum";
import { TransactionTypeEnum } from "../TransactionTypeEnum";
import { StatusEnum } from "../StatusEnum";
import { MUNICIPALITIES, MUNICIPALITY } from "../constants/RetentionConstants";
import { KindModelEnum, KindModelLabel } from "../KindModelEnum";
import { BillingTransactionTypeEnum } from "../BillingTransactionTypeEnum";

export const DefaultHistoricFilter: IOptionFilter[] = [
  {
    id: FilterTypeEnum.transactionTypeHistoric,
    title: "Tipo de transacción",
    options: [
      {
        key: TransactionTypeEnum.PAYIN_COMISSION,
        label: "Pay-In",
        selected: false,
        canDisable: [BillingTransactionTypeEnum.MANUAL_FROM_FILE],
      },
      {
        key: TransactionTypeEnum.PAYOUT_COMISSION,
        label: "Pay-Out",
        selected: false,
        canDisable: [BillingTransactionTypeEnum.MANUAL_FROM_FILE],
      },
      {
        key: TransactionTypeEnum.MINIMAL_COMISSION,
        label: "Mínimo comisional",
        selected: false,
        canDisable: [BillingTransactionTypeEnum.MANUAL_FROM_FILE],
      },
    ],
    countries: [
      CountryEnum.colombia,
      CountryEnum.peru,
      CountryEnum.mexico,
      CountryEnum.ecuador,
      CountryEnum.chile,
    ],
    canDisableMultiple: true,
    multiple: true, // validar
  },
  {
    id: FilterTypeEnum.kind,
    title: "Tipo de documento",
    options: [
      { key: KindEnum.INVOICE, label: "Factura", selected: false },
      { key: KindEnum.VOUCHER, label: "Boleta", selected: false },
      { key: KindEnum.CHARGE, label: "Cobro", selected: false },
      { key: KindEnum.RECEIVABLE, label: "Por cobrar", selected: false },
      { key: KindEnum.DISPERSION, label: "Dispersión", selected: false },
      {
        key: InvoiceModeEnum.WITHOUT_IVA,
        label: "Factura sin IVA",
        selected: false,
      },
      {
        key: InvoiceModeEnum.WITH_IVA,
        label: "Factura con IVA",
        selected: false,
      },
      { key: KindEnum.CREDIT_NOTE, label: "N. Crédito", selected: false },
      { key: KindEnum.DEBIT_NOTE, label: "N. Débito", selected: false },
      {
        key: KindEnum.RETENTION,
        label: "Retención",
        selected: false,
      },
      { key: KindEnum.RETENTION_EC, label: "Retención", selected: false },
    ],
  },
  {
    id: FilterTypeEnum.cycle,
    title: "Ciclo",
    options: [
      { key: CycleEnum.DAILY, label: "Diaria", selected: false },
      {
        key: CycleEnum.THREEWEEKLY,
        label: "Lun.-Miérc.-Vier.",
        selected: false,
      },
      { key: CycleEnum.TWOWEEKLY, label: "Mar.-Vier.", selected: false },
      { key: CycleEnum.WEEKLY, label: "Semanal", selected: false },
      { key: CycleEnum.BIWEEKLY, label: "Quincenal", selected: false },
      { key: CycleEnum.MONTHLY, label: "Mensual", selected: false },
    ],
  },
  {
    id: FilterTypeEnum.currency,
    title: "Moneda",
    options: [{ key: "USD", label: "Dólares", selected: false }],
    countries: [CountryEnum.ecuador],
  },
  {
    id: FilterTypeEnum.currency,
    title: "Moneda",
    options: [{ key: "COP", label: "Peso Colombiano", selected: false }],
    countries: [CountryEnum.colombia],
  },
  {
    id: FilterTypeEnum.processingType,
    title: "Tipo de Procesamiento",
    options: [
      {
        key: BillingTransactionTypeEnum.FROM_CONSOLE,
        label: "Procesado por backoffice",
        selected: false,
        canDisable: [BillingTransactionTypeEnum.MANUAL_FROM_FILE],
      },
      {
        key: BillingTransactionTypeEnum.MANUAL_FROM_FILE,
        label: "Procesado Manualmente",
        selected: false,
        canDisable: [
          TransactionTypeEnum.PAYOUT_COMISSION,
          TransactionTypeEnum.PAYIN_COMISSION,
          TransactionTypeEnum.MINIMAL_COMISSION,
          BillingTransactionTypeEnum.FROM_CONSOLE,
        ],
      },
    ],
    canDisableMultiple: true,
  },
  {
    id: FilterTypeEnum.initialState,
    title: "Estado inicial (Kushki)",
    options: [
      { key: "approve", label: "Aprobada", selected: false },
      { key: "omitted_is", label: "Omitida", selected: false },
      { key: "reject", label: "Rechazada", selected: false },
    ],
  },
  {
    id: FilterTypeEnum.status,
    title: "Estado final",
    options: [
      {
        key: "completed_invoice",
        label: "Emitida",
        selected: false,
      },
      {
        key: "completed_dispersion",
        label: "Exitoso",
        selected: false,
      },
      {
        key: "manual",
        label: "Manual",
        selected: false,
      },
      {
        key: "omitted",
        label: "Omitida",
        selected: false,
      },
      {
        key: "expired",
        label: "Expirada",
        selected: false,
      },
      {
        key: "rejected",
        label: "Rechazada",
        selected: false,
      },
    ],
    countries: [CountryEnum.chile],
  },
  {
    id: FilterTypeEnum.status,
    title: "Estado final",
    options: [
      {
        key: "completed_invoice",
        label: "Emitida",
        selected: false,
      },
      {
        key: "completed_charge",
        label: "Exitoso",
        selected: false,
      },
      {
        key: "completed_dispersion",
        label: "Liquidada",
        selected: false,
      },
      {
        key: "manual",
        label: "Manual",
        selected: false,
      },
      {
        key: "omitted",
        label: "Omitida",
        selected: false,
      },
      {
        key: "expired",
        label: "Expirada",
        selected: false,
      },
      {
        key: "completed_retention",
        label: "Aprobada",
        selected: false,
      },
      {
        key: "rejected",
        label: "Rechazada",
        selected: false,
      },
      {
        key: "deleted",
        label: "Anulada",
        selected: false,
      },
      {
        key: "partially_completed",
        label: "Aprobado Parcialmente",
        selected: false,
      },
    ],
    countries: [CountryEnum.ecuador],
  },
  {
    id: FilterTypeEnum.status,
    title: "Estado final",
    options: [
      {
        key: "completed_invoice",
        label: "Emitida",
        selected: false,
      },
      {
        key: "completed_charge",
        label: "Exitosa",
        selected: false,
      },
      {
        key: "completed_dispersion",
        label: "Liquidada",
        selected: false,
      },
      {
        key: "manual",
        label: "Manual",
        selected: false,
      },
      {
        key: "omitted",
        label: "Omitida",
        selected: false,
      },
      {
        key: "expired",
        label: "Expirada",
        selected: false,
      },
      {
        key: "rejected",
        label: "Rechazada(Por KUSHKI)",
        selected: false,
      },
      {
        key: "annulled",
        label: "Anulada",
        selected: false,
      },
      {
        key: "pending_verify",
        label: "Pendiente verficación",
        selected: false,
      },
      {
        key: "completed",
        label: "Aprobada",
        selected: false,
      },
    ],
    countries: [CountryEnum.colombia],
  },
  {
    id: FilterTypeEnum.status,
    title: "Estado final",
    options: [
      {
        key: "completed_charge",
        label: "Exitoso",
        selected: false,
      },
      {
        key: "completed_dispersion",
        label: "Liquidada",
        selected: false,
      },
      {
        key: "completed_invoice",
        label: "Emitida",
        selected: false,
      },
      {
        key: "manual",
        label: "Manual",
        selected: false,
      },
      {
        key: "omitted",
        label: "Omitida",
        selected: false,
      },
      {
        key: "expired",
        label: "Expirada",
        selected: false,
      },
      {
        key: "annulled",
        label: "Anulada",
        selected: false,
      },
      {
        key: "rejected",
        label: "Rechazada",
        selected: false,
      },
      {
        key: "approved",
        label: "Aprobada",
        selected: false,
      },
    ],
    countries: [CountryEnum.peru],
  },
  {
    id: FilterTypeEnum.status,
    title: "Estado final",
    options: [
      {
        key: "processed",
        label: "Procesado",
        selected: false,
      },
      {
        key: "completed_dispersion",
        label: "Liquidada",
        selected: false,
      },
      {
        key: "manual",
        label: "Manual",
        selected: false,
      },
      {
        key: "omitted",
        label: "Omitida",
        selected: false,
      },
      {
        key: "expired",
        label: "Expirada",
        selected: false,
      },
      {
        key: "completed_invoice",
        label: "Emitida",
        selected: false,
      },
      {
        key: "annulled",
        label: "Anulada",
        selected: false,
      },
      {
        key: "pending",
        label: "Anulación pendiente",
        selected: false,
      },
      {
        key: "pre_processed",
        label: "Anulación en aprobación",
        selected: false,
      },
    ],
    countries: [CountryEnum.mexico],
  },
  {
    id: FilterTypeEnum.currency,
    title: "Moneda",
    options: [{ key: "USD", label: "Dólares", selected: false }],
    countries: [CountryEnum.chile, CountryEnum.mexico, CountryEnum.peru],
  },
  {
    id: FilterTypeEnum.currency,
    title: "Moneda",
    options: [{ key: "COP", label: "Peso Colombiano", selected: false }],
    countries: [CountryEnum.colombia],
  },
  {
    id: FilterTypeEnum.cityId,
    title: "Municipio",
    options: MUNICIPALITY.map((cityId) => ({
      key: cityId,
      label: MUNICIPALITIES[cityId],
      selected: false,
    })),
    countries: [CountryEnum.colombia],
  },
  {
    id: FilterTypeEnum.processorType,
    title: "Modelo",
    options: [
      { key: KindModelEnum.AGP, label: KindModelLabel.AGP, selected: false },
      { key: KindModelEnum.AGF, label: KindModelLabel.AGF, selected: false },
    ],
    countries: [CountryEnum.colombia],
  },
  {
    id: FilterTypeEnum.models,
    title: "Modelo",
    options: [
      { key: ModelsEnum.GATEWAY, label: "Gateway", selected: false },
      { key: ModelsEnum.AGGREGATOR, label: "Agregador ", selected: false },
    ],
    onlyWith: `${FilterTypeEnum.transactionTypeHistoric}-${TransactionTypeEnum.PAYIN_COMISSION}`,
    countries: [
      CountryEnum.ecuador,
      CountryEnum.peru,
      CountryEnum.chile,
      CountryEnum.mexico,
    ],
    multiple: true,
  },
];

export const ValidateCompletedFilter = {
  [CountryEnum.ecuador]: [
    {
      kind: KindEnum.CHARGE,
      status: StatusEnum.COMPLETED_CHARGE,
    },
    {
      kind: KindEnum.DISPERSION,
      status: StatusEnum.COMPLETED_CHARGE,
    },
    {
      kind: KindEnum.INVOICE,
      status: StatusEnum.COMPLETED_INVOICE,
    },
    {
      kind: KindEnum.RETENTION_EC,
      status: StatusEnum.COMPLETED_RETENTION,
    },
  ],
  [CountryEnum.colombia]: [
    {
      kind: KindEnum.DISPERSION,
      status: StatusEnum.COMPLETED_DISPERSION,
    },
    {
      kind: KindEnum.INVOICE,
      status: StatusEnum.COMPLETED_INVOICE,
    },
    {
      kind: KindEnum.DEBIT_NOTE,
      status: StatusEnum.COMPLETED_INVOICE,
    },
    {
      kind: KindEnum.CREDIT_NOTE,
      status: StatusEnum.COMPLETED_INVOICE,
    },
  ],
  [CountryEnum.peru]: [
    {
      kind: KindEnum.CHARGE,
      status: StatusEnum.COMPLETED_CHARGE,
    },
    {
      kind: KindEnum.DISPERSION,
      status: StatusEnum.COMPLETED_DISPERSION,
    },
    {
      kind: KindEnum.INVOICE,
      status: StatusEnum.COMPLETED_INVOICE,
    },
    {
      kind: KindEnum.VOUCHER,
      status: StatusEnum.COMPLETED_INVOICE,
    },
    {
      kind: KindEnum.DEBIT_NOTE,
      status: StatusEnum.COMPLETED_INVOICE,
    },
    {
      kind: KindEnum.CREDIT_NOTE,
      status: StatusEnum.COMPLETED_INVOICE,
    },
  ],
  [CountryEnum.mexico]: [
    {
      kind: KindEnum.CHARGE,
      status: StatusEnum.COMPLETED_CHARGE,
    },
    {
      kind: KindEnum.DISPERSION,
      status: StatusEnum.COMPLETED_DISPERSION,
    },
    {
      kind: KindEnum.INVOICE,
      status: StatusEnum.COMPLETED_INVOICE,
    },
  ],
  [CountryEnum.chile]: [
    {
      kind: KindEnum.CHARGE,
      status: StatusEnum.COMPLETED_CHARGE,
    },
    {
      kind: KindEnum.DISPERSION,
      status: StatusEnum.COMPLETED_DISPERSION,
    },
    {
      kind: KindEnum.INVOICE,
      status: StatusEnum.COMPLETED_INVOICE,
    },
    {
      kind: KindEnum.RECEIVABLE,
      status: StatusEnum.COMPLETED_CHARGE,
    },
  ],
};
