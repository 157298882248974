import { createSlice } from "@reduxjs/toolkit";
import { IMerchantNodeInfoResponse } from "../../../types/merchant_node_info_response";
import { INodeInfoResponse } from "../../../types/node_info_response";
import { IRules } from "../../components/BusinessRules/state/useBusinessRules.interface";
import { IAppState } from "./app.interface";
import { IPostMerchantBillingInfoResponse } from "../../../types/merchant_billing_info_response";
import { IGetBillingConfigResponse } from "../../../types/billing_config_response";
import { IGetConfigurationRatesInfoResponse } from "../../../types/config_rates_info_response";
import { IGetMerchantDispersionInfoResponse } from "../../../types/merchant_dispersion_info_response";
import { IContactsDataResponse } from "../../../types/basic_data";
import { IMerchantCompliance } from "../../../types/merchant_compliance";
import {
  IDeferredConfig,
  IDeferredItem,
} from "../../components/DeferredList/state/useDeferredList.interface";
import { IGetMerchantCardInfoResponse } from "../../../types/get_merchant_card_info_response";
import { WebHookListResponse } from "../../../types/webhook_list_response";
import { IGetMerchantSettingsDataResponse } from "../../../types/get_merchant_settings_data_response";
import { IGetSmartlinkMerchantResponse } from "../../../types/get_smartlink_merchant_response";
import { IGetVposMerchantResponse } from "../../../types/get_vpos_merchant_response";
import { ICredentials } from "../../components/Credentials/state/useCredentials.interface";
import { GetMerchantProcessorsResponse } from "../../../types/get_merchant_processors_response";
import { GetMerchantDefaultProcessorsResponse } from "../../../types/get_merchant_default_processors_response";
import { IGetServiceSubscriptionResponse } from "../../../types/get_service_subscription_response";
import { IGetMerchantCommissionInfoResponse } from "../../../types/get_merchant_commission_info_response";
import {
  ISearchMerchantNode,
  IWebhookNotification,
} from "../../components/WebhookNotifications/state/useWebhookNotification.interface";
import {
  getBalance,
  getPayoutsRules,
  getUsers,
  getWebhookList,
} from "../thunks/app/app.thunks";
import { CompliancePeopleNodeResponse } from "../../../types/get_compliance_people_response";
import { IBalanceResponse } from "../../../types/balance_response";
import { PayoutRulesResponse } from "../../../types/payout_rules";

export const appInitialState: IAppState = {
  balanceData: {
    balance: 0,
    countableBalance: 0,
    unprocessedBalance: 0,
  },
  credentials: [],
  deferred: {
    deferredOptions: [],
  },
  mainAccordion: {
    isCompanyOpen: true,
    isDeveloperOpen: false,
    isDiscountOpen: false,
    isFinancialOpen: false,
    isProcessOpen: false,
    isServiceOpen: false,
    isUserOpen: false,
    isWalletOpen: false,
  },
  merchantCardInfo: {
    acceptCreditCards: [],
    commission: false,
    country: "Ecuador",
    sandboxEnable: undefined,
    whiteList: true,
  },
  merchantCardInfoSandbox: {
    sandboxEnable: undefined,
  },
  payoutRules: {
    data: [],
    total: 0,
  },
  rules: {
    items: [],
    lastEvaluatedKey: null,
  },
  serviceConfiguration: { isCentralized: false, publicMerchantId: "" },
  users: {
    PaginationToken: "",
    Users: [],
  },
  webhooks: {
    items: [],
  },
};

export const appSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getWebhookList.fulfilled, (state, { payload }) => {
        state.webhooks = payload;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload;
      })
      .addCase(getBalance.fulfilled, (state, action) => {
        state.balanceData = action.payload;
      })
      .addCase(getPayoutsRules.fulfilled, (state, action) => {
        state.payoutRules = action.payload;
      });
  },
  initialState: appInitialState,
  name: "app",
  reducers: {
    setBalanceResponse(state, action: { payload: IBalanceResponse }) {
      state.balanceData = action.payload;
    },
    setBillingConfigResponse(
      state,
      action: { payload: IGetBillingConfigResponse }
    ) {
      state.billingConfig = action.payload;
    },
    setCompliancePeople(
      state,
      action: { payload: CompliancePeopleNodeResponse }
    ) {
      state.compliancePeople = action.payload;
    },
    setConfigurationRatesInfoResponse(
      state,
      action: { payload: IGetConfigurationRatesInfoResponse }
    ) {
      state.configurationRatesInfo = action.payload;
    },
    setContactsDataResponse(state, action: { payload: IContactsDataResponse }) {
      state.contactsData = action.payload;
    },
    setCredentials(state, action: { payload: ICredentials[] }) {
      state.credentials = action.payload;
    },
    setDeferredConfig(state, action: { payload: IDeferredConfig[] }) {
      state.deferredConfig = action.payload;
    },
    setDeferredList(state, action: { payload: IDeferredItem[] }) {
      state.deferred.deferredOptions = action.payload;
    },
    setMainAccordionCompany(state, action: { payload: boolean }) {
      state.mainAccordion = {
        ...state.mainAccordion,
        isCompanyOpen: action.payload,
      };
    },
    setMainAccordionDeveloper(state, action: { payload: boolean }) {
      state.mainAccordion = {
        ...state.mainAccordion,
        isDeveloperOpen: action.payload,
      };
    },
    setMainAccordionDiscount(state, action: { payload: boolean }) {
      state.mainAccordion = {
        ...state.mainAccordion,
        isDiscountOpen: action.payload,
      };
    },
    setMainAccordionFinancial(state, action: { payload: boolean }) {
      state.mainAccordion = {
        ...state.mainAccordion,
        isFinancialOpen: action.payload,
      };
    },
    setMainAccordionProcess(state, action: { payload: boolean }) {
      state.mainAccordion = {
        ...state.mainAccordion,
        isProcessOpen: action.payload,
      };
    },
    setMainAccordionService(state, action: { payload: boolean }) {
      state.mainAccordion = {
        ...state.mainAccordion,
        isServiceOpen: action.payload,
      };
    },
    setMainAccordionUser(state, action: { payload: boolean }) {
      state.mainAccordion = {
        ...state.mainAccordion,
        isUserOpen: action.payload,
      };
    },
    setMainAccordionWallet(state, action: { payload: boolean }) {
      state.mainAccordion = {
        ...state.mainAccordion,
        isWalletOpen: action.payload,
      };
    },
    setMerchantBillingInfoResponse(
      state,
      action: { payload: IPostMerchantBillingInfoResponse }
    ) {
      state.merchantBillingInfo = action.payload;
    },
    setMerchantCardInformation(
      state,
      action: { payload: IGetMerchantCardInfoResponse }
    ) {
      state.merchantCardInfo = action.payload;
    },
    setMerchantCardInfoSandbox(
      state,
      action: { payload: IGetMerchantCardInfoResponse }
    ) {
      state.merchantCardInfoSandbox = action.payload;
    },
    setMerchantCommission(
      state,
      action: { payload: IGetMerchantCommissionInfoResponse }
    ) {
      state.merchantCommission = action.payload;
    },
    setMerchantCompliance(state, action: { payload: IMerchantCompliance }) {
      state.merchantCompliance = action.payload;
    },
    setMerchantDefaultProcessors(
      state,
      action: { payload: GetMerchantDefaultProcessorsResponse }
    ) {
      state.merchantDefaultProcessors = action.payload;
    },
    setMerchantDispersionInfoResponse(
      state,
      action: { payload: IGetMerchantDispersionInfoResponse }
    ) {
      state.merchantDispersionInfo = action.payload;
    },
    setMerchantInfoResponse(
      state,
      action: { payload: IMerchantNodeInfoResponse }
    ) {
      state.merchantInfo = action.payload;
    },
    setMerchantProcessors(
      state,
      action: { payload: GetMerchantProcessorsResponse[] }
    ) {
      state.merchantProcessors = action.payload;
    },
    setMerchantSettingsResponse(
      state,
      action: { payload: IGetMerchantSettingsDataResponse }
    ) {
      state.merchantSettings = action.payload;
    },
    setNodeInfoResponse(state, action: { payload: INodeInfoResponse }) {
      state.nodeInfo = action.payload;
    },
    setNotifications(state, action: { payload: IWebhookNotification }) {
      state.notifications = action.payload;
    },
    setPayoutRulesResponse(state, action: { payload: PayoutRulesResponse }) {
      state.payoutRules = action.payload;
    },
    setRulesInfoResponse(state, action: { payload: IRules }) {
      state.rules = action.payload;
    },
    setSearchNodes(state, action: { payload: ISearchMerchantNode[] }) {
      state.searchNodes = action.payload;
    },
    setServiceConfiguration(
      state,
      action: {
        payload: {
          publicMerchantId: string | null;
          isCentralized: boolean;
        };
      }
    ) {
      state.serviceConfiguration = action.payload;
    },
    setServiceSubscriptionData(
      state,
      action: { payload: IGetServiceSubscriptionResponse }
    ) {
      state.serviceSubscriptions = action.payload;
    },
    setSmartlinkMerchantData(
      state,
      action: { payload: IGetSmartlinkMerchantResponse }
    ) {
      state.smartlinkMerchant = action.payload;
    },
    setStatusMerchant(state, action: { payload: string }) {
      state.statusMerchant = action.payload;
    },
    setVposMerchantData(state, action: { payload: IGetVposMerchantResponse }) {
      state.vposMerchant = action.payload;
    },
    setWebhookList(state, action: { payload: WebHookListResponse }) {
      state.webhooks = action.payload;
    },
  },
});

export default appSlice.reducer;
