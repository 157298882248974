import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { useEffect } from "react";
import { isEmpty } from "lodash";
import {
  IUseDashboardComponent,
  IUseDashboardComponentProps,
} from "./useDashboardComponent.interfaces";
import { RootState } from "../../../store/store";
import { getBalanceByMerchantId } from "../../../store/thunks/balance/balance.thunk";

export const useDashboardComponent = ({
  publicMerchantId,
}: IUseDashboardComponentProps): IUseDashboardComponent => {
  const dispatch = useAppDispatch();
  const { balanceData } = useAppSelector((state: RootState) => ({
    ...state.balanceReducer,
  }));
  const requestGetBalanceByMerchantId = (publicMerchantId: string) => {
    dispatch(getBalanceByMerchantId(publicMerchantId));
  };

  useEffect(() => {
    if (isEmpty(publicMerchantId)) return;

    requestGetBalanceByMerchantId(publicMerchantId!);
  }, [publicMerchantId]);

  return {
    balanceData,
  };
};
