import { AppState } from "../../interfaces/layout/layout.interfaces";
import { createSlice } from "@reduxjs/toolkit";
import {
  getBusinessRules,
  getSemaphoreData,
  updateSemaphoreData,
  getNodeInfo,
  getBusinessListConfiguration,
  getDeferredList,
  getDeferredConfig,
  getCardAdminMerchant,
  updateMerchantDeferredData,
  putHierarchyNodeConfig,
  getProcessors,
  getMerchantInformation,
  getDefaultProcessor,
  deleteProcessorByPaymentMethod,
  updateFailoverProcessor,
  deleteDeferred,
  createMassiveRule,
  updateMerchantMassiveDeferredData,
  centralizeBranch,
  centralizeProcessors,
  getAppConfigPaymentMethods,
  getDefaultPayoutProcessor,
  getPayoutBusinessRules,
  createMassivePayoutRule,
} from "../../thunks/layout/layout.thunks";
import { SnackBarColors } from "../../../shared/infrastructure/enums/SnackBarColorsEnum";
import type { PayloadAction } from "@reduxjs/toolkit";
import { SnackBarInfo } from "../../interfaces/layout/snackbar-info.interface";
import { ModalInfo } from "../../interfaces/layout/modal-info.interface";
import { TabIndex } from "../../../shared/enums/TabIndexEnum";
import { defaultTo, get, set } from "lodash";
import {
  LoadingConfig,
  LoadingModal,
} from "../../interfaces/layout/loading-modal.interface";
import { DeferredOptionPath } from "../../../shared/enums/DeferredOptionPath";
import { MessageAlertEnum } from "../../../shared/enums/AlertEnum";
import { DeferredOption } from "../../../../types/get_deferred_admin_merchant_response";
import { getMessageSnackBar } from "../../../utils/utils";
import { SnackBarTextEnum } from "../../../shared/infrastructure/enums/SnackBarTextEnum";
import { BranchEditList } from "../../../../types/branch_edit_list";
import { IDeferredPeru } from "../../../shared/infrastructure/interfaces/IDeferredPeru";
import { MerchantDeferred } from "../../../../types/merchan_deferred";
import { LocalStoragePathEnum } from "../../../shared/infrastructure/enums/LocalStoragePathEnum";
import { MassiveInformation } from "../../../components/SelectedBranch/state/IUseSelectedBranch.interfaces";
import { SaveCentralizeBranchInterface } from "../../interfaces/layout/save-centralize-branch.interface";
import { ProcessorConstants } from "../../../shared/constants/ProcessorConstants";
import { EXPECTED_RESULT } from "../../../shared/constants/modal-info";
import { isDeferredAllowed } from "../../../containers/ProcessingConfigIndex/state/useOnInitProcessing";
import { MassiveDeferredAlert } from "../../../shared/enums/MassiveDeferredEnum";
import { StatusDeferred } from "../../../components/Deferred/Deferred.data";
import { GetProcessorResponse } from "../../../../types/get_processor_response";
import { setBusinessRulesBatch } from "../../../shared/utils/local-storage/massive-rules-information";
import { setIsModifyByBranch } from "../../../shared/utils/sessionStorage";
import { GetPayoutsBusinessRulesResponse } from "../../../../types/get_payouts_business_rules_response";

const DEFAULT_SNACKBAR_INFO: SnackBarInfo = {
  isOpen: false,
  isError: false,
};
const DEFAULT_MODAL_INFO: ModalInfo = {
  descriptionText: "",
  isOpen: false,
  buttonPrimaryText: "",
  buttonSecondaryText: "",
  titleText: "",
};

const DEFAULT_LOADING_MODAL: LoadingModal = {
  message: "",
  isOpen: false,
};

const DEFAULT_SAVE_CENTRALIZE_BRANCH: SaveCentralizeBranchInterface = {
  openAlert: false,
  result: "",
};

export const initialState: AppState = {
  nodeInfo: { configCoreId: "" },
  semaphoreData: { publicMerchantId: "" },
  businessRules: { items: [] },
  payoutsBusinessRules: [],
  snackBarInfo: DEFAULT_SNACKBAR_INFO,
  modalInfo: DEFAULT_MODAL_INFO,
  deleteModal: DEFAULT_MODAL_INFO,
  businessConfiguration: { businessConditions: [] },
  tabSelected: TabIndex.PROCESSORS,
  deferred: [],
  loadingModal: DEFAULT_LOADING_MODAL,
  deferredConfig: [],
  cardMerchant: { public_id: "", sift_science: [], merchant_name: "" },
  configMerchantDeferred: false,
  openAlertDeferredPE: false,
  openAlertProcessing: false,
  processor: [],
  merchantInformation: { data: [] },
  defaultProcessor: undefined,
  defaultPayoutProcessor: undefined,
  branchEditList: {
    data: [],
  },
  isMassiveBranches: false,
  deferredPeruMassive: {
    statusMerchantDeferred: StatusDeferred.STATUS_DISABLED,
    statusDeferred: StatusDeferred.STATUS_DISABLED,
  },
  merchantDeferredData: JSON.parse(
    defaultTo(
      localStorage.getItem(LocalStoragePathEnum.MERCHANT_DEFERRED_DATA),
      "{}"
    )
  ),
  successSaveMassiveDeferred: false,
  openMassiveModal: false,
  massiveInformation: {
    disableBusinessRulesTab: false,
    disableProcessorsTab: false,
    listBranches: [],
    title: "",
  },
  openAlertCentralizeBranch: DEFAULT_SAVE_CENTRALIZE_BRANCH,
  loadingProcessor: true,
  appConfigPaymentMethods: {},
};

export const layoutSlice = createSlice({
  initialState,
  name: "layout",
  reducers: {
    showSnackBar(state, action: PayloadAction<SnackBarInfo>) {
      state.snackBarInfo = action.payload;
    },
    dismissSnackBar(state) {
      state.snackBarInfo = { ...state.snackBarInfo, isOpen: false };
    },
    showModalInfo(state, action: PayloadAction<ModalInfo>) {
      state.modalInfo = action.payload;
    },
    dismissModalInfo(state) {
      state.modalInfo = DEFAULT_MODAL_INFO;
    },
    showDeleteModal(state, action: PayloadAction<ModalInfo>) {
      state.deleteModal = action.payload;
    },
    dismissDeleteModal(state) {
      state.deleteModal = DEFAULT_MODAL_INFO;
    },
    showLoadingModal(state, action: PayloadAction<LoadingModal>) {
      state.loadingModal = action.payload;
    },
    changeLoadingValue(state, action: PayloadAction<LoadingConfig>) {
      state[action.payload.property] = action.payload.value;
    },
    dismissLoadingModal(state) {
      state.loadingModal = DEFAULT_LOADING_MODAL;
    },
    changeTab(state, action: PayloadAction<TabIndex>) {
      state.tabSelected = action.payload;
    },
    setBranchEditList(state, action: PayloadAction<BranchEditList>) {
      state.branchEditList = action.payload;
    },
    setMassiveBranches(state, action: PayloadAction<boolean>) {
      state.isMassiveBranches = action.payload;
    },
    setOpenMassiveModal(state, action: PayloadAction<boolean>) {
      state.openMassiveModal = action.payload;
    },
    setSuccessSaveMassiveDeferred(state, action: PayloadAction<boolean>) {
      state.successSaveMassiveDeferred = action.payload;
    },
    setDeferredPeruMassive(state, action: PayloadAction<IDeferredPeru>) {
      state.deferredPeruMassive = action.payload;
    },
    setMerchantDeferredData(state, action: PayloadAction<MerchantDeferred>) {
      state.merchantDeferredData = action.payload;
    },
    setMassiveInformation(
      state,
      action: PayloadAction<Partial<MassiveInformation>>
    ) {
      state.massiveInformation = {
        ...state.massiveInformation,
        ...action.payload,
      };
    },
    setProcessors(state, action: PayloadAction<GetProcessorResponse[]>) {
      state.processor = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDeferredList.fulfilled, (state, { payload }) => {
        const sortField = DeferredOptionPath.Created;
        state.deferred = payload.sort((a, b) => {
          return get(b, sortField, 0) - get(a, sortField, 0);
        });
        state.loadingDeferred = false;
      })
      .addCase(getBusinessRules.fulfilled, (state, { payload }) => {
        if (!payload.items) {
          payload.items = [];
        }
        state.businessRules = payload;
      })
      .addCase(getPayoutBusinessRules.fulfilled, (state, { payload }) => {
        state.payoutsBusinessRules = defaultTo(payload.data, []);
      })
      .addCase(getSemaphoreData.fulfilled, (state, { payload }) => {
        state.semaphoreData = payload;
      })
      .addCase(updateSemaphoreData.fulfilled, (state, { payload }) => {
        state.semaphoreData = payload;
      })
      .addCase(getNodeInfo.fulfilled, (state, { payload }) => {
        state.nodeInfo = payload;
        if (isDeferredAllowed(get(payload, "countryCode", ""))) {
          state.loadingDeferred = true;
        }

        state.loadingNodeInfo = false;
      })
      .addCase(getBusinessListConfiguration.fulfilled, (state, { payload }) => {
        if (payload) {
          state.businessConfiguration = payload;
        }
      })
      .addCase(getDeferredConfig.fulfilled, (state, { payload }) => {
        if (payload) {
          state.deferredConfig = payload;
        }
      })
      .addCase(getCardAdminMerchant.fulfilled, (state, { payload }) => {
        if (payload) {
          state.cardMerchant = payload;
        }
      })
      .addCase(putHierarchyNodeConfig.fulfilled, (state, { payload }) => {
        if (payload) {
          state.configMerchantDeferred = payload;
        }
      })
      .addCase(updateMerchantDeferredData.rejected, (state) => {
        state.loadingModal = { isOpen: false, message: "" };
        state.snackBarInfo = {
          color: SnackBarColors.DANGER,
          message: MessageAlertEnum.UPDATE_STATUS_DEFERRED_REJECTED,
          isOpen: true,
          isError: true,
        };
        state.openAlertDeferredPE = true;
      })
      .addCase(updateMerchantDeferredData.fulfilled, (state, { payload }) => {
        const currentState: AppState = JSON.parse(JSON.stringify(state));
        const currentDeferredOption: DeferredOption = get(
          currentState,
          "deferred[0]",
          {}
        );
        const deferredOption: DeferredOption = get(
          payload,
          "deferredOptions[0]",
          {}
        );

        set(state, "deferred[0].merchantStatus", deferredOption.merchantStatus);
        set(state, "deferred[0].status", deferredOption.status);

        state.loadingModal = { isOpen: false, message: "" };
        state.snackBarInfo = {
          message: getMessageSnackBar(currentDeferredOption, deferredOption),
          isOpen: true,
          isError: false,
        };
        state.openAlertDeferredPE = true;
      })
      .addCase(updateMerchantMassiveDeferredData.rejected, (state) => {
        state.snackBarInfo = {
          isOpen: true,
          message: MassiveDeferredAlert.ERROR_MSG,
        };
      })
      .addCase(getProcessors.fulfilled, (state, { payload }) => {
        state.processor = payload;
        state.loadingProcessor = false;
      })
      .addCase(getMerchantInformation.fulfilled, (state, { payload }) => {
        state.merchantInformation = payload;
      })
      .addCase(getDefaultProcessor.fulfilled, (state, { payload }) => {
        state.defaultProcessor = payload;
      })
      .addCase(getDefaultPayoutProcessor.fulfilled, (state, { payload }) => {
        state.defaultPayoutProcessor = payload;
      })
      .addCase(deleteProcessorByPaymentMethod.fulfilled, (state, {}) => {
        state.loadingModal = { isOpen: false, message: "" };
        state.snackBarInfo = {
          message: SnackBarTextEnum.SUCCESS_DELETED_PROCESSOR,
          isOpen: true,
          isError: false,
        };
      })
      .addCase(deleteProcessorByPaymentMethod.rejected, (state, {}) => {
        state.loadingModal = { isOpen: false, message: "" };
        state.snackBarInfo = {
          color: SnackBarColors.DANGER,
          message: SnackBarTextEnum.ERROR_DELETED_PROCESSOR,
          isOpen: true,
          isError: true,
        };
      })
      .addCase(updateFailoverProcessor.fulfilled, (state, {}) => {
        state.loadingModal = { isOpen: false, message: "" };
        state.snackBarInfo = {
          message: SnackBarTextEnum.SUCCESS_FAILOVER_CONFIG,
          isOpen: true,
          isError: false,
        };
      })
      .addCase(updateFailoverProcessor.rejected, (state, {}) => {
        state.loadingModal = { isOpen: false, message: "" };
        state.snackBarInfo = {
          color: SnackBarColors.DANGER,
          message: SnackBarTextEnum.ERROR_FAILOVER_CONFIG,
          isOpen: true,
          isError: true,
        };
      })
      .addCase(deleteDeferred.rejected, (state) => {
        state.loadingModal = { isOpen: false, message: "" };
        state.deleteModal = { ...state.deleteModal, isOpen: false };
        state.snackBarInfo = {
          color: SnackBarColors.DANGER,
          message: SnackBarTextEnum.ERROR_DEFERRED,
          isOpen: true,
          isError: true,
        };
      })
      .addCase(deleteDeferred.fulfilled, (state) => {
        state.loadingModal = { isOpen: false, message: "" };
        state.deleteModal = { ...state.deleteModal, isOpen: false };
        state.snackBarInfo = {
          message: SnackBarTextEnum.SUCCESS_DELETED_DEFERRED,
          isOpen: true,
          isError: false,
        };
      })
      .addCase(createMassiveRule.fulfilled, (_state, {}) => {
        _state.businessRules.items.map((item) => {
          item.isSent = true;
        });
        setBusinessRulesBatch(
          _state.businessRules,
          LocalStoragePathEnum.BUSINESS_RULES_BATCH
        );
        setIsModifyByBranch();
      })
      .addCase(createMassivePayoutRule.fulfilled, (_state) => {
        _state.payoutsBusinessRules.map((item) => {
          item.isSent = true;
        });
        setBusinessRulesBatch<GetPayoutsBusinessRulesResponse>(
          {
            data: _state.payoutsBusinessRules,
            total: _state.payoutsBusinessRules.length,
          },
          LocalStoragePathEnum.PAYOUTS_BUSINESS_RULES_BATCH
        );
        setIsModifyByBranch();
      })
      .addCase(centralizeBranch.fulfilled, (state) => {
        state.loadingModal = { isOpen: false, message: "" };
        state.openAlertCentralizeBranch = {
          openAlert: true,
          result: EXPECTED_RESULT.OK,
        };
        state.snackBarInfo = {
          message: ProcessorConstants.PROCESSOR_ADDED_SUCCESSFUL,
          isOpen: true,
          isError: false,
        };
      })
      .addCase(centralizeBranch.rejected, (state) => {
        state.loadingModal = { isOpen: false, message: "" };
        state.openAlertCentralizeBranch = {
          openAlert: true,
          result: EXPECTED_RESULT.ERROR,
        };
        state.snackBarInfo = {
          color: SnackBarColors.DANGER,
          message: SnackBarTextEnum.ERROR_MASSIVE_CENTRALIZE,
          isOpen: true,
          isError: true,
        };
      })
      .addCase(centralizeProcessors.fulfilled, (_state) => {})
      .addCase(getAppConfigPaymentMethods.fulfilled, (state, { payload }) => {
        state.appConfigPaymentMethods = payload;
      });
  },
});

export const {
  showSnackBar,
  dismissSnackBar,
  showModalInfo,
  dismissModalInfo,
  showDeleteModal,
  dismissDeleteModal,
  showLoadingModal,
  dismissLoadingModal,
  changeLoadingValue,
  changeTab,
  setBranchEditList,
  setMassiveBranches,
  setDeferredPeruMassive,
  setMerchantDeferredData,
  setOpenMassiveModal,
  setSuccessSaveMassiveDeferred,
  setMassiveInformation,
  setProcessors,
} = layoutSlice.actions;

export default layoutSlice.reducer;
