import { TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import { B2CRefundTransaction } from "../../../../../types/b2c_refund_transaction";
import { ITableColumn } from "../../../../shared/interfaces/Table.interfaces";
import StatusChip from "../../../common/StatusChip/StatusChip";
import { TableBodyStyles } from "../../../common/Table/TableBody/TableBody.styles";
import TableRowDate from "../TableRowDate/TableRowDate";

interface Props {
  data: B2CRefundTransaction[];
  columns: ITableColumn[];
  onRowClick?: (id: string) => void;
}

export const formatField = (columnId: string, object: B2CRefundTransaction) => {
  if (columnId === "amount") {
    return `${object[columnId]
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} ${object.currency}`;
  }

  return object[columnId];
};

const TableHistoricalBody = ({ data, columns, onRowClick }: Props) => {
  return (
    <>
      {data.map((row) => {
        return (
          <TableRow
            key={row["transaction_reference"]}
            onClick={() =>
              onRowClick && onRowClick(row["transaction_reference"])
            }
          >
            {columns.map((column) => {
              const value = row[column.id];

              if (column.id === "created") {
                return <TableRowDate key={column.id} value={value} />;
              }

              return (
                <TableCell
                  align={column.align}
                  key={column.id}
                  sx={TableBodyStyles.cell}
                >
                  {column.type === "chip" ? (
                    <StatusChip status={value} />
                  ) : (
                    <Typography sx={TableBodyStyles.label}>
                      {formatField(column.id, row)}
                    </Typography>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </>
  );
};

export default TableHistoricalBody;
