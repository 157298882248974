import { chargebackSlice } from "../reducers/chargeback/chargeback.slice";

export const {
  resetChargebackRequest,
  setChargebackTransaction,
  setDisableActionButtons,
  setIsLoading,
  setIsWaitingForRequest,
  setNotification,
  setNotificationStatus,
  setSearchingChargeback,
  setSearchChargebackResponse,
  setSearchChargebackRequest,
  setSetIsLoadingStatus,
} = chargebackSlice.actions;
