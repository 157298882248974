import { createSlice } from "@reduxjs/toolkit";
import { IAppState } from "../../interfaces/AppState.interfaces";
import {
  createProcessor,
  getBillingCoreNodeResponse,
  getMerchantProcessorInfo,
  getMerchantProcessors,
  getProcessor,
  setAsDefaultProcessor,
  updateProcessor,
} from "../../thunks/app/app.thunks";
import { dismissLoader, showLoader } from "../util";
import { ErrorMessageEnum } from "../../../shared/constants/textEnum";

export const initialState: IAppState = {
  billingCoreNodeResponse: {},
  createProcessorResponse: {
    publicProcessorId: "",
  },
  getProcessorResponse: {},
  loadingModal: {
    firstTimeLoad: true,
    isLoading: false,
  },
  merchantProcessorInfo: {},
  merchantProcessors: [],
  pendingRequest: 0,
  processorDataSaved: false,
  setDefaultProcessorResponse: {
    message: "",
  },
  showCreatingProcessorModal: false,
  showFinalModal: false,
  snackbarError: {
    errorMessage: "",
    showSnackbar: false,
  },
};

export const appSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getMerchantProcessorInfo.pending, (state) => {
        showLoader(state);
      })
      .addCase(getMerchantProcessorInfo.fulfilled, (state, action) => {
        state.merchantProcessorInfo = action.payload;
        state.loadingModal.firstTimeLoad = false;
        dismissLoader(state);
      })
      .addCase(getMerchantProcessorInfo.rejected, (state) => {
        state.loadingModal.firstTimeLoad = false;
        state.snackbarError.showSnackbar = true;
        state.snackbarError.errorMessage = ErrorMessageEnum.ProcessorInfoError;
        dismissLoader(state);
      })
      .addCase(getBillingCoreNodeResponse.pending, (state) => {
        showLoader(state);
      })
      .addCase(getBillingCoreNodeResponse.fulfilled, (state, action) => {
        state.billingCoreNodeResponse = action.payload;
        state.loadingModal.firstTimeLoad = false;
        dismissLoader(state);
      })
      .addCase(getBillingCoreNodeResponse.rejected, (state) => {
        state.loadingModal.firstTimeLoad = false;
        state.snackbarError.showSnackbar = true;
        state.snackbarError.errorMessage = ErrorMessageEnum.MerchantInfoError;
        dismissLoader(state);
      })
      .addCase(getMerchantProcessors.pending, (state) => {
        showLoader(state);
      })
      .addCase(getMerchantProcessors.fulfilled, (state, action) => {
        state.merchantProcessors = action.payload;
        state.loadingModal.firstTimeLoad = false;
        dismissLoader(state);
      })
      .addCase(getMerchantProcessors.rejected, (state) => {
        state.loadingModal.firstTimeLoad = false;
        state.snackbarError.showSnackbar = true;
        state.snackbarError.errorMessage =
          ErrorMessageEnum.MerchantProcessorsInfoError;
        dismissLoader(state);
      })
      .addCase(getProcessor.pending, (state) => {
        showLoader(state);
      })
      .addCase(getProcessor.fulfilled, (state, action) => {
        state.getProcessorResponse = action.payload;
        state.loadingModal.firstTimeLoad = false;
        dismissLoader(state);
      })
      .addCase(getProcessor.rejected, (state) => {
        state.loadingModal.firstTimeLoad = false;
        state.snackbarError.showSnackbar = true;
        state.snackbarError.errorMessage = ErrorMessageEnum.ProcessorsInfoError;
        dismissLoader(state);
      })
      .addCase(createProcessor.pending, (state) => {
        state.showCreatingProcessorModal = true;
      })
      .addCase(createProcessor.fulfilled, (state, action) => {
        state.createProcessorResponse = action.payload;
        state.showCreatingProcessorModal = false;
        state.showFinalModal = true;
        state.processorDataSaved = true;
      })
      .addCase(createProcessor.rejected, (state) => {
        state.showCreatingProcessorModal = false;
        state.snackbarError.showSnackbar = true;
        state.snackbarError.errorMessage =
          ErrorMessageEnum.CreateProcessorError;
      })
      .addCase(updateProcessor.pending, (state) => {
        state.showCreatingProcessorModal = true;
      })
      .addCase(updateProcessor.fulfilled, (state, action) => {
        state.createProcessorResponse = action.payload;
        state.showCreatingProcessorModal = false;
        state.processorDataSaved = true;
      })
      .addCase(updateProcessor.rejected, (state) => {
        state.showCreatingProcessorModal = false;
        state.snackbarError.showSnackbar = true;
        state.snackbarError.errorMessage =
          ErrorMessageEnum.UpdateProcessorError;
      })
      .addCase(setAsDefaultProcessor.pending, () => {})
      .addCase(setAsDefaultProcessor.fulfilled, (state, action) => {
        state.setDefaultProcessorResponse = action.payload;
      })
      .addCase(setAsDefaultProcessor.rejected, (state) => {
        state.snackbarError.showSnackbar = true;
        state.snackbarError.errorMessage =
          ErrorMessageEnum.DefaultProcessorError;
      });
  },
  initialState,
  name: "app",
  reducers: {
    dismissFinalModal(state) {
      state.showFinalModal = false;
    },
    dismissSnackbar(state) {
      state.snackbarError.showSnackbar = !state.snackbarError.showSnackbar;
    },
  },
});

export const { dismissFinalModal, dismissSnackbar } = appSlice.actions;

export default appSlice.reducer;
