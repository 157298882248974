import { TransactionInfo } from "../../../types/transactions_data";
import { isEqual } from "lodash";
import { base64ToBlob } from "./general_utils";

export const verifySelectedRows = (
  selectedRows: TransactionInfo[],
  data: TransactionInfo
) => {
  let isSelected: boolean = false;

  selectedRows.forEach((item: TransactionInfo) => {
    if (isEqual(item, data)) isSelected = true;
  });

  return isSelected;
};

export const verifyAllSelectedRows = (
  selectedRows: TransactionInfo[],
  rows: TransactionInfo[]
) => {
  let count: number = 0;

  selectedRows.map((item: TransactionInfo) => {
    rows.map((row) => {
      if (isEqual(item, row)) count++;
    });
  });

  return count === rows.length;
};

export const revokeReceipt = (receipt: string) => {
  const blob: Blob = base64ToBlob(receipt.toString());

  const url: string = window.URL.createObjectURL(blob);

  window.open(url, "_blank");
  window.URL.revokeObjectURL(url);
};
