/**
 * Catalog Panama
 */
import { Category } from "../infrastructure/constants/MerchantInformationConstant";
import { CatalogsEnum } from "../infrastructure/constants/catalogs-enum";

export const CatalogPa: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { value: "0", name: "CIP" },
    { value: "1", name: "Pasaporte" },
  ],
  [CatalogsEnum.Provinces]: [
    { value: "1", name: "Bocas del Toro" },
    { value: "4", name: "Chiriquí" },
    { value: "2", name: "Coclé" },
    { value: "3", name: "Colón" },
    { value: "5", name: "Darién" },
    { value: "EM", name: "Emberá" },
    { value: "KY", name: "Guna Yala" },
    { value: "6", name: "Herrera" },
    { value: "7", name: "Los Santos" },
    { value: "NB", name: "Ngöbe-Buglé" },
    { value: "8", name: "Panamá" },
    { value: "10", name: "Panamá Oeste" },
    { value: "9", name: "Veraguas" },
  ],
  [CatalogsEnum.Banks]: [
    { value: "1100001", name: "AUSTROBANK (OVERSEAS) PANAMA S.A." },
    { value: "1100002", name: "BANCAFE (BANCO CAFETERO)" },
    { value: "1100003", name: "BANCO ALIADO S.A." },
    { value: "1100004", name: "BANCO BILBAO VIZCAYA ARGENTARIA (PANAMA) S.A." },
    { value: "1100005", name: "BANCO CUSCATLAN DE PANAMA S.A." },
    { value: "1100006", name: "BANCO DE BOGOTA S.A." },
    { value: "1100007", name: "BANCO DE GUAYAQUIL (PANAMA) S.A." },
    { value: "1100008", name: "BANCO DE LA NACION ARGENTINA" },
    { value: "1100009", name: "BANCO DE OCCIDENTE (PANAMA) S.A." },
    { value: "1100010", name: "BANCO DEL PACIFICO (PANAMA) S.A." },
    { value: "1100011", name: "BANCO GENERAL S.A." },
    { value: "1100012", name: "BANCO INTERNACIONAL DE COSTA RICA S.A." },
    { value: "1100013", name: "BANCO LAFISE PANAMA" },
    {
      value: "1100014",
      name: "BANCO LATINOAMERICANO DE COMERCIO EXTERIOR S.A.",
    },
    { value: "1100015", name: "BANCO NACIONAL DE PANAMA" },
    { value: "1100016", name: "BANCO PICHINCHA PANAMA" },
    { value: "1100017", name: "BANCO TRASATLANTICO S.A." },
    { value: "1100018", name: "BANCO UNION SUCURSAL PANAMA" },
    { value: "1100019", name: "BANCO UNIVERSAL S.A." },
    { value: "1100020", name: "BANCOLOMBIA PANAMA S.A." },
    { value: "1100021", name: "BANESCO INTERNATIONAL BANK. INC." },
    { value: "1100022", name: "BANK LEUMI LE ISRAEL B.M" },
    { value: "1100023", name: "BANK OF CHINA PANAMA BRANCH" },
    { value: "1100024", name: "BANK OF NOVA SCOTIA" },
    { value: "1100025", name: "BCT BANK INTERNATIONAL S.A." },
    { value: "1100026", name: "BLUBANK LTD." },
    { value: "1100027", name: "BNP PARIBAS WEALTH MANAGEMENT SUCURSAL PANAMA" },
    { value: "1100028", name: "BNP PARIBAS-PANAMA BRANCH" },
    { value: "1100029", name: "CAPITAL BANK INC." },
    { value: "1100030", name: "CITIBANK N.A. SUCURSAL PANAMA" },
    { value: "1100031", name: "CREDICORP BANK S.A" },
    { value: "1100032", name: "ES BANK (PANAMA) S.A." },
    { value: "1100033", name: "FIRST CENTRAL INTERNATIONAL BANK INC." },
    { value: "1100034", name: "FPB INTERNATIONAL BANK INC." },
    { value: "1100035", name: "GLOBAL BANK CORPORATION" },
    { value: "1100036", name: "GNB SUDAMERIS BANK S.A." },
    { value: "1100037", name: "HELM BANK (PANAMA)" },
    { value: "1100038", name: "HSBC BANK USA PANAMA" },
    { value: "1100039", name: "INTERNATIONAL UNION BANK S.A." },
    { value: "1100040", name: "KOREA EXCHANGE BANK" },
    { value: "1100041", name: "MEGA INTERNATIONAL COMMERCIAL BANK CO. LTD." },
    { value: "1100042", name: "MERCANTIL BANK (PANAMA) S.A." },
    { value: "1100043", name: "METROBANK S.A." },
    { value: "1100044", name: "MMG BANK CORPORATION" },
    { value: "1100045", name: "MULTIBANK INC." },
    { value: "1100046", name: "POPULAR BANK LTD. INC." },
    { value: "1100047", name: "PRODUBANK PANAMA S.A." },
    { value: "1100048", name: "STANFORD BANK (PANAMA) S.A." },
    { value: "1100049", name: "TOWERBANK INTERNATIONAL INC." },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { value: "0", name: "Corriente" },
    { value: "1", name: "Ahorros" },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { value: "0", name: "Corriente" },
    { value: "1", name: "Ahorros" },
  ],
  [CatalogsEnum.Currency]: [{ value: "PAB", name: "PAB" }],
  [CatalogsEnum.MerchantTypes]: [
    { value: "01", name: "Natural" },
    { value: "02", name: "Jurídica" },
  ],
};
