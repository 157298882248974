import { ChargebackStatusEnum } from "../enums/ChargebackStatusEnum";

export const RESOLVED_DATE_CHARGEBACK_STATUS: string[] = [
  ChargebackStatusEnum.APPROVAL,
  ChargebackStatusEnum.DECLINED,
  ChargebackStatusEnum.FAILED,
];

export const REVIEW_DATE_CHARGEBACK_STATUS: string[] = [
  ChargebackStatusEnum.DOCSENT,
  ChargebackStatusEnum.REVIEW,
];

export enum SubtitleSection {
  MERCHANT_NAME = "Nombre del comercio:",
  MERCHANT_ID = "ID del comercio:",
  INIT_DATE = "Fecha de inicio:",
  RESPONSE_DATE = "Fecha de respuesta:",
  REVIEW_DATE = "Recepción de documentos:",
  RESOLVED_DATE = "Fecha de resolución:",
  DAYS_PASSED = "Días transcurridos para la carga de documentación:",
}
