import { RuleEnum } from "./RuleEnum";
import { ErrorsEnum } from "./ErrorEnum";
import { CardTypeValueEnum, Labels } from "../constants/BusinessRuleConstants";
import { ILabelItemProps } from "../interfaces/ILabelItemProps";
import { RegExpEnum } from "./RegExpEnum";

export const DEFAULT_ITEM_BY_TAG: Record<string, ILabelItemProps> = {
  [RuleEnum.IP]: {
    name: RuleEnum.IP,
    errorMessage: ErrorsEnum.VALID_IP,
    placeholder: Labels.IP,
    label: "Ingresa un IP:",
    tag: RuleEnum.IP,
    disable: false,
    patternInput: RegExpEnum.REGEXP_NUMBER_AND_POINTS,
    pattern: RegExpEnum.REGEXP_VALID_IP_V6_V4,
    isError: true,
    isSaved: false,
  },
};
export const ITEM_SIMPLE_CONDITION: Record<string, object> = {
  [RuleEnum.CARD_TYPE]: {
    name: RuleEnum.CARD_TYPE,
    label: "Selecciona el tipo de tarjeta:",
    firstValue: "false",
    secondValue: "true",
    firstValueLabel: CardTypeValueEnum.DEBIT,
    secondValueLabel: CardTypeValueEnum.CREDIT,
  },
  [RuleEnum.DEFERRED]: {
    name: RuleEnum.DEFERRED,
    label: "Aplica diferido",
    firstValue: "true",
    secondValue: "false",
    firstValueLabel: "Sí",
    secondValueLabel: "No",
  },
};

export const TRANSACTION_TYPE_MAPPING_CREATION: Record<string, string> = {
  true: "true",
  false: "false",
  credit: "true",
  debit: "false",
};

export const TRANSACTION_TYPE_MAPPING_MODAL: Record<string, string> = {
  true: CardTypeValueEnum.CREDIT,
  false: CardTypeValueEnum.DEBIT,
  credit: CardTypeValueEnum.CREDIT,
  debit: CardTypeValueEnum.DEBIT,
};
