import { Box, TableBody, TableRow } from "@mui/material";
import React, { FC, ReactElement } from "react";
import { CellCheckItem } from "../../TableCell/CellCheck/CellCheck";
import { useTableContext } from "../TableContext";
import {
  CELL_COMPONENTS_BY_TABLE_ENUM,
  TableBodyCellEnum,
} from "../TableSimple/constants";
import { ITableCellProps, ITableRowProps } from "../TableSimple/interfaces";
import { ITableBodySimpleProps } from "./interfaces";
import { ICellCheckProps } from "../../TableCell/CellCheck/CellCheck.interfaces";
import { CellRow } from "../../TableCell/CellRow/CellRow";
import { noop } from "../../../shared/constants/noop";
import { useMobileState } from "../../../shared/state/useMobileState";
import { ICellDeleteProps } from "../../TableCell/CellDelete/CellDelete.interfaces";
import { get } from "lodash";
import { tableBodySimpleStyles } from "./TableBodySimple.styles";
import { EditStatusEnum } from "../../../shared/enums/EditStatusEnum";
import { editStatusPath } from "../../../shared/constants/edit_container_paths";

export const TableBodySimple: FC<ITableBodySimpleProps> = ({
  rows,
  deletionProps,
  disableCheck,
  setItemCheckCurrent,
  setEditProcess,
}) => {
  const {
    state: { allowDeletion, allowSelection, selectedRows },
    handler: { handleAppendSelectedRow },
  } = useTableContext();

  const { isMobile } = useMobileState();

  const getSelectRowProps = (
    row: ITableRowProps,
    rowIndex: number
  ): ICellCheckProps => {
    const isChecked: boolean = selectedRows.some(
      (sRow: ITableRowProps) => sRow.id === row.id
    );

    return {
      cellProps: { align: "left", spacing: 1 },
      defaultValue: row.rowProps.isSelected,
      id: row.id,
      isChecked,
      isDisabled: !!disableCheck,
      label: {
        check: get(row, "rowProps.labelCheck.check") ?? "",
        unCheck: get(row, "rowProps.labelCheck.unCheck") ?? "",
      },
      onChecked: (id: string) => {
        handleAppendSelectedRow(row);
        row.rowProps.onChecked && row.rowProps.onChecked(id);
        if (setItemCheckCurrent) {
          setItemCheckCurrent(row);
        }
        if (setEditProcess) setEditProcess(true);
      },
      rowIndex,
    };
  };

  const getDeleteRowProps = (
    row: ITableRowProps,
    rowIndex: number
  ): ICellDeleteProps => {
    return {
      cellProps: { align: "center", spacing: 1 },
      handleDeleteRow: deletionProps?.handleDeleteRow ?? noop,
      icon: deletionProps?.icon,
      id: row.id,
      isDisabled: deletionProps?.isDisabled ?? false,
      rowIndex,
      variant: deletionProps?.variant,
    };
  };

  const rowsToRender: ITableRowProps[] = rows.map(
    (row: ITableRowProps, index: number) => {
      const cells: ITableRowProps["cells"] = [...row.cells];

      if (!isMobile && allowSelection)
        cells.unshift({
          props: getSelectRowProps(row, index),
          type: TableBodyCellEnum.CHECK,
        });

      if (allowDeletion)
        cells.push({
          props: getDeleteRowProps(row, index),
          type: TableBodyCellEnum.DELETE,
        });

      return {
        ...row,
        cells,
      };
    }
  );

  const FirstRow: FC<{ row: ITableRowProps; rowIndex: number }> = ({
    row,
    rowIndex,
  }: {
    row: ITableRowProps;
    rowIndex: number;
  }): ReactElement => {
    const cell = get(row, "cells[0]");

    const CellComponent = CELL_COMPONENTS_BY_TABLE_ENUM[cell.type];
    const checkProps = getSelectRowProps(row, rowIndex);

    return (
      <CellRow {...cell.props.cellProps}>
        <Box display="flex" justifyContent="space-between">
          <CellComponent {...cell.props} />
          <CellCheckItem {...checkProps} />
        </Box>
      </CellRow>
    );
  };

  return (
    <TableBody>
      {rowsToRender.map((row: ITableRowProps, index: number) => {
        const cell: ITableCellProps | undefined = row.cells.find(
          (cell: ITableCellProps) =>
            get(cell, "props.id", "") === editStatusPath
        );

        return !row.rowProps.hidden ? (
          <TableRow
            onClick={row.rowProps.onClick ?? noop}
            key={`row-interactive-${index}`}
            sx={
              get(cell, "props.value", "") === EditStatusEnum.complete
                ? tableBodySimpleStyles.tableRow
                : tableBodySimpleStyles.tableRowBorderRadius
            }
          >
            {isMobile && row.cells[0] && allowSelection && (
              <FirstRow row={row} rowIndex={index} />
            )}
            {row.cells
              .slice(
                isMobile && row.cells.length >= 1 && allowSelection ? 1 : 0
              )
              .map(({ type, props }: ITableCellProps, cellIndex: number) => {
                const CellComponent = CELL_COMPONENTS_BY_TABLE_ENUM[type];

                return (
                  <CellRow
                    {...props.cellProps}
                    key={`row-${index}-cell-${cellIndex}-component-${type}`}
                  >
                    <CellComponent
                      {...props}
                      rowIndex={index}
                      key={`row-${index}-cell-${cellIndex}-component-${type}`}
                    />
                  </CellRow>
                );
              })}
          </TableRow>
        ) : null;
      })}
    </TableBody>
  );
};
