import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

export const CellTagStyles = createNamedStyles({
  skeleton: {
    display: "inline-flex",
    width: "64px",
    height: "21px",
  },
  box: {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
    padding: "4px",
    ".MuiChip-root": {
      minWidth: "auto",
    },
  },
  toolTipTitle: {
    textAlign: "left",
  },
  toolTipText: {
    textAlign: "left",
    fontWeight: "400!important",
  },
});
