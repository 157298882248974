import Switch from "@material-ui/core/Switch";
import React from "react";
import { useSwitchTableState } from "./useSwitchTableState/useSwitchTableState";
import { StatusDialogTexts } from "../../../shared/infrastructure/constants/CreateDeferredConstants";
import { StatusEnum } from "../../../shared/infrastructure/constants/statusEnum";
import { TDeferred } from "../../../shared/infrastructure/interfaces/TDeferred";

export interface SwitchTableProps {
  deferredItem: TDeferred;
  handleModal: (id: string, status: StatusEnum) => void;
  changeDialogText: (text: StatusDialogTexts) => void;
}

export const SwitchTable = (props: SwitchTableProps) => {
  const { active, handleSave } = useSwitchTableState(props);

  return <Switch color={"primary"} checked={active} onChange={handleSave} />;
};
