import { Grid, Typography } from "@mui/material";
import React from "react";
import { IConfiguredConditionsComponent } from "../../shared/infrastructure/interfaces/IConfiguredConditionsComponent";
import { SubTitleSection } from "../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { generalStyles } from "../../shared/styles/generalStyles.styles";

export const ConfiguredConditionsComponent: React.FC<
  IConfiguredConditionsComponent
> = (props: IConfiguredConditionsComponent) => {
  const classes = generalStyles();

  return (
    <>
      {props.isCommerce ? (
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <Typography className={classes.subtitleTypography}>
              {SubTitleSection.CONFIGURED_CONDITIONS_COMMERCE}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {props.commerceConditions.map((item: string) => (
              <Typography key={item} className={classes.generalText}>
                {item}
              </Typography>
            ))}
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <Typography className={classes.subtitleTypography}>
              {SubTitleSection.CONFIGURED_CONDITIONS_COUNTRY}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {props.countryConditions.map((item: any) => (
              <>
                <Typography key={item} className={classes.titleCountry}>
                  {`${item.country}:`}
                </Typography>
                {item.conditions.map((condition: string) => (
                  <Typography
                    key={condition}
                    className={classes.generalText}
                    sx={{ mb: 0.5 }}
                  >
                    {condition}
                  </Typography>
                ))}
              </>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
};
