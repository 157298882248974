import { AnyAction, AsyncThunk } from "@reduxjs/toolkit";
import { defaultTo, get, isEmpty, isNil } from "lodash";
import { IGetModalInfo } from "../../components/WalletBalanceForm/state/useWalletBalanceForm.interfaces";
import { TransactionType } from "../../shared/enums/transaction_types";
import { CATALOG_MODAL_CONFIRM_INFO } from "../../shared/catalogs/CatalogModalConfirmInfo";
import { RECHARGE_FORM_TEXTS } from "../../shared/constants/labels/form_labels";
import {
  CATALOG_FILE_TYPE,
  FILE_MAX_MEGAS,
} from "../../shared/constants/main_container_constants";

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk["pending"]>;
type RejectedAction = ReturnType<GenericAsyncThunk["rejected"]>;

export const isPendingAction = (action: AnyAction): action is PendingAction =>
  get(action, "type", "").endsWith("/pending");

export const isRejectedAction = (action: AnyAction): action is RejectedAction =>
  get(action, "type", "").endsWith("/rejected");

const toBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
  });
};

export const getValueBase64 = async (
  file: File | undefined
): Promise<string> => {
  if (isNil(file)) return "";

  return await toBase64(file);
};

export const getModalConfirmInfo = (
  transactionType: TransactionType
): IGetModalInfo => {
  return CATALOG_MODAL_CONFIRM_INFO[transactionType];
};

const convertByteToMega = (bytes: number): number => {
  return bytes / Math.pow(1024, 2);
};

export const validateFileInput = (file: FileList | undefined) => {
  if (isEmpty(file)) return RECHARGE_FORM_TEXTS.uploadFileErrorMessage;
  const fileObject: File = get(file, "[0]");
  const sizeMega: number = convertByteToMega(fileObject.size);

  if (sizeMega > FILE_MAX_MEGAS) {
    return RECHARGE_FORM_TEXTS.uploadFileSizeErrorMessage;
  }

  const typeFile = get(defaultTo(fileObject.type, "").split("/"), "[1]", "");

  if (!CATALOG_FILE_TYPE.includes(typeFile))
    return RECHARGE_FORM_TEXTS.uploadFileTypeErrorMessage;

  return true;
};

export const validateFile = (value: object | undefined) =>
  validateFileInput(value as FileList);
