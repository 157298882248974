export const merchantInformationStyles = {
  countryBlock: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    justifyContent: "center",
  },
  editButton: {
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
      border: "1px solid transparent",
    },
    backgroundColor: "transparent",
    minWidth: 0,
    padding: 0,
  },
  iconEdit: {
    color: "primary.main",
    fontSize: "x-large",
  },
  iconEffect: {
    color: "#023365",
    "&:hover": {
      backgroundColor: "#ECF0F1",
    },
    "&:active": {
      backgroundColor: "#F7F9F9",
    },
  },
};
