/**
 * catalog time-zone
 */
import { UtcEnum } from "./UtcEnum";

export const TimeZoneCatalog: ICatalog[] = [
  {
    description: "UTC-03 (SaoPaulo)",
    region: "America/Sao_Paulo",
    timeZone: " SaoPaulo",
    utc: UtcEnum.utc_03,
  },
  {
    description: "UTC-03 (Santiago)",
    region: "America/Santiago",
    timeZone: " Santiago",
    utc: UtcEnum.utc_03,
  },
  {
    description: "UTC-05 (Bogota, Quito, Lima)",
    region: "America/Guayaquil",
    timeZone: " Bogota, Quito, Lima",
    utc: UtcEnum.utc_05,
  },
  {
    description: "UTC-06 (Ciudad de México)",
    region: "America/Mexico_City",
    timeZone: " Ciudad de México",
    utc: UtcEnum.utc_06,
  },
  {
    description: "UTC-11 (Samoa)",
    region: "Pacific/Midway",
    timeZone: " Samoa",
    utc: UtcEnum.utc_11,
  },
  {
    description: "UTC-10 (Hawaii)",
    region: "US/Hawaii",
    timeZone: " Hawaii",
    utc: UtcEnum.utc_10,
  },
  {
    description: "UTC-09 (Anchorage, Juneau)",
    region: "America/Anchorage",
    timeZone: " Anchorage, Juneau",
    utc: UtcEnum.utc_09,
  },
  {
    description: "UTC-08 (Seattle, San Francisco, Los Angeles)",
    region: "America/Los_Angeles",
    timeZone: " Seattle, San Francisco, Los Angeles",
    utc: UtcEnum.utc_08,
  },
  {
    description: "UTC-07 (Edmonton, Denver, Phoenix)",
    region: "America/Edmonton",
    timeZone: " Edmonton, Denver, Phoenix",
    utc: UtcEnum.utc_07,
  },
  {
    description: "UTC-06 (Winnipeg, Chicago, Houston, Mexico)",
    region: "America/Mexico_City",
    timeZone: " Winnipeg, Chicago, Houston, Mexico",
    utc: UtcEnum.utc_06,
  },
  {
    description: "UTC-06 (Tegucigalpa, Managua, San Jose)",
    region: "America/Managua",
    timeZone: " Tegucigalpa, Managua, San Jose",
    utc: UtcEnum.utc_06,
  },
  {
    description: "UTC-05 (New York, Miami, La Habana)",
    region: "America/New_York",
    timeZone: " New York, Miami, La Habana",
    utc: UtcEnum.utc_05,
  },
  {
    description: "UTC-05 (Bogota, Quito, Lima)",
    region: "America/Guayaquil",
    timeZone: " Bogota, Quito, Lima",
    utc: UtcEnum.utc_05,
  },
  {
    description: "UTC-05 (Puerto Principe, Panama)",
    region: "America/Panama",
    timeZone: " Puerto Principe, Panama",
    utc: UtcEnum.utc_05,
  },
  {
    description: "UTC-04 (Halifax, Santo Domingo, Caracas)",
    region: "America/Halifax",
    timeZone: " Halifax, Santo Domingo, Caracas",
    utc: UtcEnum.utc_04,
  },
  {
    description: "UTC-04 (Georgetown, Manaus, La Paz)",
    region: "America/Manaus",
    timeZone: " Georgetown, Manaus, La Paz",
    utc: UtcEnum.utc_04,
  },
  {
    description: "UTC-04 (Asuncion, Santiago de Chile)",
    region: "America/Asuncion",
    timeZone: " Asuncion, Santiago de Chile",
    utc: UtcEnum.utc_04,
  },
  {
    description: "UTC-03 (Brasilia, Rio De Janeiro, Montevideo, Buenos Aires)",
    region: "America/Montevideo",
    timeZone: " Brasilia, Rio De Janeiro, Montevideo, Buenos Aires",
    utc: UtcEnum.utc_03,
  },
  {
    description: "UTC-02 (Recife)",
    region: "America/Recife",
    timeZone: " Recife",
    utc: UtcEnum.utc_02,
  },
  {
    description: "UTC-01 (Azores)",
    region: "Atlantic/Azores",
    timeZone: " Azores",
    utc: UtcEnum.utc_01,
  },
  {
    description: "UTC+00 (Londres, Dublín, Lisboa, Casablanca, Dakar, Accra)",
    region: "Europe/London",
    timeZone: " Londres, Dublín, Lisboa, Casablanca, Dakar, Accra",
    utc: UtcEnum.utc00,
  },
  {
    description: "UTC+01 (Paris, Madrid, Roma, Berlín, Praga)",
    region: "Europe/Paris",
    timeZone: " Paris, Madrid, Roma, Berlín, Praga",
    utc: UtcEnum.utc01,
  },
  {
    description: "UTC+01 (Belgrado, Varsovia, Estocolmo, Oslo)",
    region: "Europe/Oslo",
    timeZone: " Belgrado, Varsovia, Estocolmo, Oslo",
    utc: UtcEnum.utc01,
  },
  {
    description: "UTC+01 (Argel, Lagos, Brazzaville, Luanda)",
    region: "Africa/Luanda",
    timeZone: " Argel, Lagos, Brazzaville, Luanda",
    utc: UtcEnum.utc01,
  },
  {
    description: "UTC+02 (Helsinki, Minks, Bucarest, Estambul)",
    region: "Europe/Helsinki",
    timeZone: " Helsinki, Minks, Bucarest, Estambul",
    utc: UtcEnum.utc02,
  },
  {
    description:
      "UTC+02 (Atenas, Beirut, Cairo, Tripoli, Harare, Ciudad del Cabo)",
    region: "Asia/Beirut",
    timeZone: " Atenas, Beirut, Cairo, Tripoli, Harare, Ciudad del Cabo",
    utc: UtcEnum.utc02,
  },
  {
    description: "UTC+03 (San Petersburgo, Moscow, Bagdad, Riad)",
    region: "Europe/Moscow",
    timeZone: " San Petersburgo, Moscow, Bagdad, Riad",
    utc: UtcEnum.utc03,
  },
  {
    description: "UTC+03 (Addis Abeba, Kampala, Nairobi, Mogadisco)",
    region: "Europe/Moscow",
    timeZone: " Addis Abeba, Kampala, Nairobi, Mogadisco",
    utc: UtcEnum.utc03,
  },
  {
    description: "UTC+04 (Samara, Baku, Tbilisi, Dubai)",
    region: "Europe/Samara",
    timeZone: " Samara, Baku, Tbilisi, Dubai",
    utc: UtcEnum.utc04,
  },
  {
    description: "UTC+05 (Sheliabinsk, Karachi, Islamabad)",
    region: "Asia/Karachi",
    timeZone: " Sheliabinsk, Karachi, Islamabad",
    utc: UtcEnum.utc05,
  },
  {
    description: "UTC+06 (Omsk, Tashkent, Dacca)",
    region: "Asia/Omsk",
    timeZone: " Omsk, Tashkent, Dacca",
    utc: UtcEnum.utc06,
  },
  {
    description: "UTC+07 (Novosibirsk, Bangkok, Hanoi, Yakarta)",
    region: "Asia/Novosibirsk",
    timeZone: " Novosibirsk, Bangkok, Hanoi, Yakarta",
    utc: UtcEnum.utc07,
  },
  {
    description: "UTC+08 (Irkutsk, Lhasa, Beijing, Hong Kong)",
    region: "Asia/Irkutsk",
    timeZone: " Irkutsk, Lhasa, Beijing, Hong Kong",
    utc: UtcEnum.utc08,
  },
  {
    description: "UTC+08 (Kuala Lumpur, Singapur, Manila, Perth)",
    region: "Asia/Irkutsk",
    timeZone: " Kuala Lumpur, Singapur, Manila, Perth",
    utc: UtcEnum.utc08,
  },
  {
    description: "UTC+09 (Tokyo, Seul)",
    region: "Asia/Tokyo",
    timeZone: " Tokyo, Seul",
    utc: UtcEnum.utc09,
  },
  {
    description: "UTC+10 (Vladivostok, Sydney, Melbourne)",
    region: "Australia/Sydney",
    timeZone: " Vladivostok, Sydney, Melbourne",
    utc: UtcEnum.utc10,
  },
  {
    description: "UTC+11 (Noumea, Magaban)",
    region: "Pacific/Noumea",
    timeZone: " Noumea, Magaban",
    utc: UtcEnum.utc11,
  },
  {
    description: "UTC+12 (Wellington Nueva Zelanda)",
    region: "Pacific/Noumea",
    timeZone: " Wellington Nueva Zelanda",
    utc: UtcEnum.utc12,
  },
];

export interface ICatalog {
  description: string;
  region?: string;
  timeZone?: string;
  utc?: string;
}
