export const LANGUAGE_CATALOG: Record<string, any> = {
  frequency: {
    none: "Ninguno",
    daily: "Diaria",
    weekly: "Semanal",
    twoWeekly: "Mar-Vie",
    biWeekly: "Quincenal",
    threeWeekly: "Lun-Mie-Vie",
    monthly: "Mensual",
  },
  paymentType: {
    payOut: "Pay - Out",
    payIn: "Pay - In",
    additionalServices: "Servicio Adicional",
  },
  paymentMethod: {
    cash: "Efectivo",
    card: "Tarjeta",
    transfer: "Transferencia",
    siftScience: "SiftScience",
    "3ds": "3DS",
    otp: "OTP",
    transUnion: "TransUnion",
  },
};
