import { useEffect, useState } from "react";
import { isEmpty } from "lodash";

export interface OnChangeTimePickerTagState {
  onChange(value: string): void;
  value: string;
}

export interface IUseTimePickerTagState {
  calculateTimeStamp(value?: string): void;
  time: string | undefined;
  checkEmptyValue: (value: any) => void;
}

export const useTimePickerTagState = (
  props: OnChangeTimePickerTagState
): IUseTimePickerTagState => {
  const [timeStamp, setTimeStamp] = useState<string>("");
  const [time, setTime] = useState<Date | null>(null);

  useEffect(() => {
    props.onChange(timeStamp);
  }, [timeStamp]);

  useEffect(() => {
    if (props.value) {
      setTime(new Date(parseInt(props.value)));
      setTimeStamp(props.value);
    }
  }, [props.value]);

  const calculateTimeStamp = (value?: string): void => {
    if (value) {
      const time = new Date(`1970-01-01 ${value}`);
      setTimeStamp(time.getTime().toString());
      setTime(time);
    }
  };

  const checkEmptyValue = (value: string): void => {
    if (isEmpty(value)) {
      setTimeStamp("");
      setTime(null);
    }
  };

  const formatTime = (time: Date | null): string | undefined => {
    if (time) {
      return time.toTimeString().split(" ")[0];
    }
    return undefined;
  };

  return {
    calculateTimeStamp,
    time: formatTime(time),
    checkEmptyValue,
  };
};
