import { UserMasterIndexProps } from "../UserMasterIndex";
import { ICardSupportUser } from "../../../components/CardSupportUser/CardSupportUser";
import { routes } from "../../../shared/infrastructure/constants/routes";
import userImage from "../../../assets/images/userImage.svg";
import React from "react";
import { SupportWrapperIdEmun } from "../../../shared/infrastructure/catalogues/SupportWrapperIdEmun";

export interface IUserMasterIndexState {
  cards: ICardSupportUser;
  handleCloseAccept: (url: string) => void;
  redirectZendesk: () => void;
  closeNotification: (_event?: React.SyntheticEvent, reason?: string) => void;
}

export const useUserMasterIndexState = (
  props: UserMasterIndexProps
): IUserMasterIndexState => {
  const redirectZendesk = () => {
    props.redirectZendesk();
  };

  const handleCloseAccept = (url: string) => {
    if (url) {
      window.location.href = url;
    }
    props.setRedirectModal({ show: false, url: "" });
  };

  const closeNotification = (
    _event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    props.setNotification({ show: false, message: "", type: "success" });
  };

  return {
    closeNotification,
    handleCloseAccept,
    redirectZendesk,
    cards: {
      title: "Soporte Kushki",
      elements: [
        {
          idComponent: SupportWrapperIdEmun.ASISTENCIA_REMOTA,
          subtitle: "Asistencia remota",
          description:
            "Genera una clave de acceso para la visualización de tu Consola de Cliente al equipo de soporte. Esta opción es solo lectura.",
          redirect: routes.SUPPORT_CONNECT,
        },
        {
          idComponent: SupportWrapperIdEmun.HISTORIRAL_ASISTENCIA_REMOTA,
          subtitle: "Historial de asistencia remota",
          description:
            "Visualiza el historial de asistencia remota del comercio.",
          redirect: routes.SUPPORT_HISTORY,
        },
        {
          idComponent: SupportWrapperIdEmun.REDIRECCIONAR_ZENDESK,
          subtitle: "Centro de ayuda",
          description: "Redireccionar a Zendesk",
          redirect: "",
          tooltip:
            "Si tiene problemas al ser redireccionado, use la última contraseña que haya utilizado",
          onClick: redirectZendesk,
        },
      ],
      image: userImage,
      isMedium: false,
    },
  };
};
