import React from "react";
import { CustomerListProps } from "./CustomerList.interfaces";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { customerHeaderStyles as styles } from "../CustomerHeader/customerHeader.styles";
import { CustomerEnum } from "../../shared/enums/CustomerEnum";
import { IconCircleInformation } from "@kushki/connect-ui";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { ComponentsSecurity } from "../../shared/enums/SecurityWrapperEnum";
import Dashboard from "../Dashboard/Dashboard";
import Slider from "../Slider/Slider";
import AddIcon from "@mui/icons-material/Add";
import { MerchantNodeData } from "../../../types/search_merchant_response";

export const CustomerList: React.FC<CustomerListProps> = (
  props: CustomerListProps
) => {
  return (
    <>
      <Box sx={styles.createCustomerWrapper}>
        <Box sx={styles.tooltipWrapper}>
          <Typography variant="h5" color="text.dark">
            {CustomerEnum.HEADER_TITLE}
          </Typography>
          <Tooltip title={CustomerEnum.MESSAGE_TOOLTIP} placement={"top"}>
            <IconButton>
              <IconCircleInformation />
            </IconButton>
          </Tooltip>
        </Box>
        <ComponentSecurityWrapper
          componentId={ComponentsSecurity.CREATE_CUSTOMER}
        >
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={props.actionBtn}
            disabled={props.isLoadingMerchants}
          >
            {CustomerEnum.CREATE_CUSTOMER}
          </Button>
        </ComponentSecurityWrapper>
      </Box>
      <Slider
        isOpen={props.slide.isOpen}
        closeModal={props.slide.closeModal}
        queryParam={props.slide.queryParam}
      />
      <Dashboard
        basicNodeInfo={props.dashboard.basicNodeInfo}
        customerNode={{} as MerchantNodeData}
        emptyAddText={props.dashboard.emptyAddText}
        handleChangeStatus={props.dashboard.handleChangeStatus}
        modalStatusDashboard={props.dashboard.modalStatusDashboard}
        nodeData={props.dashboard.nodeData}
        showEmptyScreen={props.dashboard.showEmptyScreen}
      />
    </>
  );
};
