import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { FC } from "react";

const SlashIcon: FC<SvgIconProps> = ({ sx, ...rest }: SvgIconProps) => (
  <SvgIcon
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    sx={{ p: "1px", ...sx }}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.16716 10.9996C4.16716 9.3074 4.74925 7.75273 5.7145 6.51065L15.9894 16.7856C14.7473 17.7517 13.1927 18.3329 11.5005 18.3329C7.45708 18.3329 4.16716 15.043 4.16716 10.9996ZM18.8334 10.9995C18.8334 12.6917 18.2513 14.2464 17.2861 15.4885L7.01116 5.21354C8.25324 4.24737 9.80791 3.6662 11.5001 3.6662C15.5435 3.6662 18.8334 6.95612 18.8334 10.9995ZM11.5002 1.83301C6.44566 1.83301 2.3335 5.94517 2.3335 10.9997C2.3335 16.0542 6.44566 20.1663 11.5002 20.1663C16.5547 20.1663 20.6668 16.0542 20.6668 10.9997C20.6668 5.94517 16.5547 1.83301 11.5002 1.83301Z"
      fill="#231F20"
    />
    <mask
      id="mask0_978_41370"
      maskUnits="userSpaceOnUse"
      x="2"
      y="1"
      width="19"
      height="20"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16716 10.9996C4.16716 9.3074 4.74925 7.75273 5.7145 6.51065L15.9894 16.7856C14.7473 17.7517 13.1927 18.3329 11.5005 18.3329C7.45708 18.3329 4.16716 15.043 4.16716 10.9996ZM18.8334 10.9995C18.8334 12.6917 18.2513 14.2464 17.2861 15.4885L7.01116 5.21354C8.25324 4.24737 9.80791 3.6662 11.5001 3.6662C15.5435 3.6662 18.8334 6.95612 18.8334 10.9995ZM11.5002 1.83301C6.44566 1.83301 2.3335 5.94517 2.3335 10.9997C2.3335 16.0542 6.44566 20.1663 11.5002 20.1663C16.5547 20.1663 20.6668 16.0542 20.6668 10.9997C20.6668 5.94517 16.5547 1.83301 11.5002 1.83301Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_978_41370)">
      <rect x="0.5" width="22" height="22" fill="white" />
    </g>
  </SvgIcon>
);
export default SlashIcon;
