import { environment } from "../../environments/environment";

export const API_ROUTES = {
  CHARGEBACK_STATUS: `${environment.kushkiUrl}/chargeback/v1/admin/chargeback/`,
  CHARGEBACK_TRANSACTION: `${environment.kushkiUrl}/analytics/v1/search/chargeback`,
  CHILDREN_MERCHANTS_CORE: `${environment.kushkiUrl}/billing-core-node/v1/childrenMerchants`,
  DOWNLOAD_ANALYTICS: `${environment.kushkiUrl}/analytics/v1/admin/admin/chargeback/download`,
  DOWNLOAD_MERCHANT_ANALYTICS: `${environment.kushkiUrl}/analytics/v1/admin/merchant/download`,
  DOWNLOAD_TRANSACTIONS: `${environment.envName}/usrv-analytics/file/transaction/`,
  MERCHANT_INFO: `${environment.kushkiUrl}/billing-core/v1/merchant/merchantInfo`,
  MERCHANTS_CORE: `${environment.kushkiUrl}/billing-core-node/v1/merchants`,
  NODE_INFO: `${environment.kushkiUrl}/billing-core-node/v1/node/info`,
  RETRIEVE_CHILD: `${environment.kushkiUrl}/hierarchy/v1/node/retrieveChildren`,
  SEARCH_CHARGEBACK: `${environment.kushkiUrl}/analytics/v1/admin/merchant/search/chargeback`,
  SEARCH_CHARGEBACK_ADMIN: `${environment.kushkiUrl}/analytics/v1/admin/admin/search/chargeback`,
  UPDATE_CHARGEBACK_STATUS_MASSIVE: `${environment.kushkiUrl}/chargeback/v1/update/massive/status`,
  UPLOAD_FILES: `${environment.kushkiUrl}/chargeback/v1/admin/documentation`,
};
