import { HeaderCellProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";

export enum LabelEnum {
  ADD_BUSINESS_RULE = "Agregar regla de negocio",
  ALIAS = "Alias",
  BACK = "Regresar",
  DESCRIPTION_GENERAL_DATA = "Genera un alias que te permita identificar la regla",
  DESCRIPTION_RULE_CONFIGURATION = "Selecciona el procesador con el que se va a configurar la regla de negocio",
  GENERAL_DATA = "Datos generales",
  MERCHANT_ID = "ID:",
  OPERATION_COUNTRY = "País de operación:",
  RULE_CONFIGURATION = "Configuración de la regla",
  SELECT_PROCESSOR = "Seleccionar procesador",
  BTN_SAVE_DRAFT = "Guardar borrador",
  BTN_EDIT_RULE_ENABLE_PRIMARY = "Guardar cambios",
  BTN_EDIT_RULE_DRAFT_PRIMARY = "Editar regla",
  BTN_ENABLE_RULE_DRAFT_PRIMARY = "Habilitar regla",
  BTN_ADD_RULE = "Agregar regla",
  PROCESSOR_PUBLIC_ID = "Public ID:",
  BUSINESS = "business",
  BUSINESS_RULES = "Reglas de negocio",
  BUSINESS_RULES_CENTRALIZED = "Reglas de negocio centralizado",
  PROCESSOR_NAME = "Nombre del procesador",
  PUBLIC_ID = "Public ID",
  RULES_SETTINGS_TITLE = "Configuración de la regla",
  CONDITIONS = "Condiciones",
  HEADER_CREATED_PROPERTY = "Fecha y hora de creación",
  NO_APPLY = "No aplica",
  FILTER_MODAL_TITLE = "Filtrar por",
  FOOTER_PAGINATION_TITLE = "Reglas por página",
  TABLE_INSTRUCTION = "Desde aquí puedes crear, editar y eliminar tus reglas de negocio",
  ADD_BUSINESS_RULES = "Agrega las reglas de negocio.",
  NO_INFORMATION = "Aún no tienes información registrada",
}

export const ModalSimpleData = {
  ACCEPT_BUTTON: "Aceptar",
  CANCEL_BTN: "Cancelar",
  CONTINUE_BTN: "Continuar",
  CREATE: "create",
  CREATE_DRAFT: "create draft",
  DESCRIPTION_REPEATED: (conditions: string, alias: string) =>
    `Ten en cuenta que la(s) condicion(es) ${conditions} que intentas crear ya existe(n) en la regla ${alias}`,
  DRAFT_DESCRIPTION:
    "Deberás completar la configuración más adelante y agregar la regla para que pueda estar habilitada",
  DRAFT_RULE_TITLE: "Guardar como borrador",
  EDIT: "edit",
  EDIT_RULE_CUSTOMER_DESCRIPTION:
    "Los cambios realizados aplicarán a todos los branches centralizados de este Customer.",
  EDIT_RULE_CUSTOMER_TITLE: "Guardar cambios a la regla de negocio",
  ENABLE_DRAFT_DESCRIPTION:
    "Al habilitar esta regla, las condiciones configuradas tendrán efecto sobre las transacciones de los branches centralizados",
  ENABLE_EDIT_RULE_DESCRIPTION:
    "Al habilitar esta regla, las condiciones configuradas tendrán efecto sobre las transacciones de los branches centralizados.",
  ENABLE_EDIT_RULE_TITLE: "¿Deseas habilitar la regla?",
  ENABLE_RULE_TITLE:
    "La regla fue creada con éxito, ¿Deseas agregar otra\n regla de negocio? ",
  MODAL_BTN_ADD_NEW_RULE: "Agregar nueva regla",
  MODAL_BTN_END: "Finalizar",
  UPDATE: "update",
};

const enum HeaderTextColumn {
  CREATED_DATE = "Fecha de creación",
  STATUS = "Estado",
  ALIAS_BRANCH = "Alias",
  CATEGORY = "Categoría",
  SEND_TO = "Enviar a",
  CONDITIONAL = "Condicional:",
  VALUE = "Valor:",
  OPTIONS = "Op",
}

const tableBusinessRules: HeaderCellProps[] = [
  {
    align: "left",
    spacing: 1,
    text: HeaderTextColumn.CREATED_DATE,
    type: "default",
    width: 200,
  },
  {
    align: "left",
    spacing: 0,
    text: HeaderTextColumn.ALIAS_BRANCH,
    type: "default",
    width: 200,
  },
  {
    align: "left",
    spacing: 0,
    text: HeaderTextColumn.CATEGORY,
    type: "default",
    width: 600,
  },
  {
    align: "left",
    spacing: 0,
    text: HeaderTextColumn.SEND_TO,
    type: "default",
    width: 125,
  },
  {
    align: "left",
    spacing: 0,
    text: HeaderTextColumn.STATUS,
    type: "default",
    width: 200,
  },
];

export const TABLE_COLUMNS: Record<string, HeaderCellProps[]> = {
  [LabelEnum.BUSINESS_RULES]: [
    ...tableBusinessRules,
    {
      align: "left",
      spacing: 0,
      text: HeaderTextColumn.OPTIONS,
      type: "default",
    },
  ],
  [LabelEnum.BUSINESS_RULES_CENTRALIZED]: [...tableBusinessRules],
  [LabelEnum.CONDITIONS]: [
    {
      align: "left",
      spacing: 1,
      text: HeaderTextColumn.CATEGORY.concat(":"),
      type: "default",
      width: 300,
    },
    {
      align: "left",
      spacing: 0,
      text: HeaderTextColumn.CONDITIONAL,
      type: "default",
      width: 200,
    },
    {
      align: "left",
      spacing: 0,
      text: HeaderTextColumn.VALUE,
      type: "default",
      width: 900,
    },
  ],
};
