import React from "react";
import { TextField, MenuItem } from "@mui/material";
import { get } from "lodash";
import { useSelectComponentSectionState } from "./state/useSelectComponentSectionState";
import { IOptionSelection } from "../../../../shared/infrastructure/interfaces/IOptionSelection";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";

export interface ISelectComponentProps {
  conditionProperty: string;
  items: IOptionSelection[];
  message: string;
  placeHolder: string;
  selectedValue: string[] | undefined;
  setSelectedItems: (value: ICategory[]) => void;
}
export const SelectComponentSection: React.FC<ISelectComponentProps> = (
  props: ISelectComponentProps
) => {
  const { selectValue, handleChange } = useSelectComponentSectionState(props);

  return (
    <TextField
      value={selectValue}
      onChange={handleChange}
      fullWidth
      label={props.placeHolder}
      select
    >
      {props.items.map((item: IOptionSelection) => {
        return (
          <MenuItem
            key={`item-${get(item, "label", "")}`}
            value={get(item, "value", "")}
          >
            {get(item, "label", "")}
          </MenuItem>
        );
      })}
    </TextField>
  );
};
