import React, { FC } from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { get } from "lodash";
import { ISnackBarAlertComponent } from "./SnackBarAlertComponent.interfaces";
import { useSnackBarAlert } from "./state/useSnackBarAlert";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackBarAlertComponent: FC<ISnackBarAlertComponent> = ({
  rootStoreKey,
}: ISnackBarAlertComponent) => {
  const { alert, handleClose, open } = useSnackBarAlert({ rootStoreKey });

  return (
    <Snackbar
      open={open}
      autoHideDuration={1500}
      onClose={handleClose}
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
    >
      <Alert onClose={handleClose} severity={get(alert, "type")}>
        {get(alert, "message", "")}
      </Alert>
    </Snackbar>
  );
};

export default SnackBarAlertComponent;
