import { Conciliation } from "../../../types/get_conciliation_transactions_response";
export const formatNumber = (value: string | number): string => {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(Number(value));
};

export const getColumns = (
  columnsKey: string[],
  conciliationTrx: Conciliation
) =>
  columnsKey.map((col: string) => ({
    description: conciliationTrx[col],
    type: col,
  }));
