import { createSlice } from "@reduxjs/toolkit";
import { ILegalDetailsState } from "../../interfaces/LegalDetailsState.interfaces";
import {
  compliancePeopleDefaultState,
  merchantNodeInfoDefaultState,
  PersonTypeEnum,
} from "../../../shared/constants/initial_state_legal_details";
import {
  getCompliancePeople,
  getMerchantNodeInfo,
  saveLegalDetails,
} from "../../thunks/legalDetails/legalDetails.thunk";
import {
  editBoardMemberAction,
  editLegalRepresentativeAction,
  getCompliancePeopleNodeAction,
  getMerchantNodeInfoAction,
  removeBoardMemberAction,
  removeLegalRepresentativeAction,
  setBoardMemberAction,
  setBoardMembersArrayAction,
  setDisabledInputsAction,
  setEditedAction,
  setEditedBoardMemberAction,
  setGeneralPercentageAction,
  setHierarchyNodeInfoResponseAction,
  setIsLoadingCompliancePeopleAction,
  setIsPubliclyExposedAction,
  setLegalRepresentativeAction,
  setLegalRepresentativesArrayAction,
  setNaturalPersonLastNameAction,
  setNaturalPersonNameAction,
  setNotificationSnackbarLD,
  setOpenModalAction,
  setOpenModalActionSh,
  setPersonTypeAction,
  setSaveLegalDetailsErrorAction,
  setShareHolderAction,
} from "../../actions/leaglDetails/legaldetails.actions";
import { updateHierarchyNodeInfo } from "../../thunks/general/general.thunk";
import {
  buildNotification,
  defaultDataSnackbar,
} from "../../../shared/constants/snackbar";
import { NotificationTypeEnum } from "../../../shared/enums/SnackbarEnum";
import { get } from "lodash";

export const initialState: ILegalDetailsState = {
  boardMembers: [],
  compliancePeopleNode: compliancePeopleDefaultState,
  disabledInputs: false,
  generalPercentage: "0",
  hierarchyNodeResponse: undefined,
  isEdited: { id: "", value: false },
  isEditedBoardMember: { id: "", value: false },
  isLoadingCompliancePeople: true,
  isPubliclyExposed: null,
  legalRepresentative: [],
  merchantNodeInfo: merchantNodeInfoDefaultState,
  notification: undefined,
  openModal: false,
  openModalSh: false,
  personType: PersonTypeEnum.MORAL,
  saveLegalDetailsError: true,
  shareHolders: [],
};

export const legalDetailsSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getCompliancePeople.fulfilled, (state, action) => {
        state.compliancePeopleNode = action.payload;
        state.isLoadingCompliancePeople = false;
      })
      .addCase(getCompliancePeople.pending, (state) => {
        state.isLoadingCompliancePeople = true;
      })
      .addCase(getCompliancePeople.rejected, (state) => {
        state.isLoadingCompliancePeople = false;
      })
      .addCase(getMerchantNodeInfo.fulfilled, getMerchantNodeInfoAction)
      .addCase(saveLegalDetails.fulfilled, (state) => {
        state.notification = buildNotification(
          NotificationTypeEnum.SUCCESS,
          get(state, "notification", defaultDataSnackbar)
        );
        state.saveLegalDetailsError = false;
      })
      .addCase(saveLegalDetails.rejected, (state) => {
        state.notification = buildNotification(
          NotificationTypeEnum.FAILED,
          get(state, "notification", defaultDataSnackbar)
        );
        state.saveLegalDetailsError = true;
      })
      .addCase(
        updateHierarchyNodeInfo.fulfilled,
        setHierarchyNodeInfoResponseAction
      )
      .addCase(updateHierarchyNodeInfo.rejected, (state) => {
        state.hierarchyNodeResponse = false;
      });
  },
  initialState,
  name: "legalDetails",
  reducers: {
    editBoardMember: editBoardMemberAction,
    editLegalRepresentative: editLegalRepresentativeAction,
    removeBoardMember: removeBoardMemberAction,
    removeLegalRepresentative: removeLegalRepresentativeAction,
    setBoardMember: setBoardMemberAction,
    setBoardMembersArray: setBoardMembersArrayAction,
    setCompliancePeopleNode: getCompliancePeopleNodeAction,
    setDisabledInputs: setDisabledInputsAction,
    setEdited: setEditedAction,
    setEditedBoardMember: setEditedBoardMemberAction,
    setGeneralPercentage: setGeneralPercentageAction,
    setHierarchyNodeInfoResponse: setHierarchyNodeInfoResponseAction,
    setIsLoadingCompliancePeople: setIsLoadingCompliancePeopleAction,
    setIsPubliclyExposed: setIsPubliclyExposedAction,
    setLegalRepresentative: setLegalRepresentativeAction,
    setLegalRepresentativesArray: setLegalRepresentativesArrayAction,
    setNaturalPersonLastName: setNaturalPersonLastNameAction,
    setNaturalPersonName: setNaturalPersonNameAction,
    setNotificationLD: setNotificationSnackbarLD,
    setOpenModal: setOpenModalAction,
    setOpenModalSh: setOpenModalActionSh,
    setPersonType: setPersonTypeAction,
    setSaveLegalDetailsError: setSaveLegalDetailsErrorAction,
    setShareHolder: setShareHolderAction,
  },
});

export default legalDetailsSlice.reducer;
export const {
  setDisabledInputs,
  editLegalRepresentative,
  removeBoardMember,
  removeLegalRepresentative,
  editBoardMember,
  setBoardMember,
  setEdited,
  setEditedBoardMember,
  setGeneralPercentage,
  setHierarchyNodeInfoResponse,
  setIsLoadingCompliancePeople,
  setIsPubliclyExposed,
  setLegalRepresentative,
  setLegalRepresentativesArray,
  setBoardMembersArray,
  setNaturalPersonLastName,
  setNaturalPersonName,
  setNotificationLD,
  setOpenModal,
  setOpenModalSh,
  setPersonType,
  setSaveLegalDetailsError,
  setShareHolder,
  setCompliancePeopleNode,
} = legalDetailsSlice.actions;
