import React, { FC, PropsWithChildren } from "react";
import { Box, Grid } from "@mui/material";
import { FilterDetailBar } from "@kushki/connect-ui";
import { headerFiltersStyles as styles } from "./HeaderFilters.styles";
import { FilterSideBar } from "../Filters/FilterSideBar/FilterSideBar";
import { HeaderFiltersProps } from "./HeaderFilters.interfaces";
import { useHeaderFiltersState } from "./state/useHeaderFiltersState";
import SearchText from "../SearchText/SearchText";
import { EntityNameEnum } from "../../shared/enums/EntityNameEnum";
import { BranchEnum } from "../../shared/enums/BranchEnum";
import { CustomerEnum } from "../../shared/enums/CustomerEnum";

export const HeaderFilters: FC<HeaderFiltersProps> = (
  props: PropsWithChildren<HeaderFiltersProps>
) => {
  const {
    filterSideBarProps,
    itemsDetailBar,
    onChangeFiltersDetailBar,
    onClickFilterSideBar,
    onEnterName,
    onDateSelected,
    valueSearch,
    onChange,
    showRangePicker,
  } = useHeaderFiltersState(props);

  return (
    <Grid container>
      <Grid item container xs={12}>
        <SearchText
          onEnter={onEnterName}
          onDateSelected={onDateSelected}
          searchMessage={
            props.entityNameType === EntityNameEnum.BRANCH
              ? BranchEnum.SEARCH_MESSAGE
              : CustomerEnum.SEARCH_MESSAGE
          }
          value={valueSearch}
          onChange={onChange}
          entityName={props.entityNameType}
          showRangePicker={showRangePicker}
        />
        <Grid item xs={2} sx={styles.gridFilter}>
          <Box mt={2} mb={2}>
            <FilterSideBar
              data-testid={"filterBar"}
              {...filterSideBarProps}
              categoryItems={filterSideBarProps.categoryItems}
              onClick={onClickFilterSideBar}
              resetFilters={props.resetFilters}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={styles.gridFilterCard}>
        <FilterDetailBar
          filters={itemsDetailBar}
          onChangeFilters={onChangeFiltersDetailBar}
        />
      </Grid>
    </Grid>
  );
};
