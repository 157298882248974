import React, { useEffect, useState } from "react";
import { WebhookIndexProps } from "../WebhookIndex";
import {
  Webhook,
  WebhookEvent,
  WebhookResponse,
} from "../../../../types/webhook_response";
import { PaginationTableProps } from "../../../components/PaginationTable/PaginationTable";
import { auth } from "../../../shared/auth";
import { useHistory, useParams } from "react-router-dom";
import { get, isEmpty } from "lodash";
import { ConfirmMessageProps } from "../../../components/ConfirmMessage/ConfirmMessage";
import { ModalTestConnectionProps } from "../../../components/ModalTestConnection/ModalTestConnection";
import { BreadcrumProps } from "../../../components/Breadcrumb/Breadcrumb";
import { INotification } from "../../../shared/infrastructure/interfaces/INotification";
import { useDispatch, useSelector } from "react-redux";
import {
  getMerchantStatus,
  setPreauthEnable,
} from "../../../store/actionCreators";
import { IAppState } from "../../../store/reducer";
import {
  PAYMENT_METHODS_EVENTS,
  ServiceOptionEnum,
  WebhookEventsEnum,
} from "../../../shared/infrastructure/constants/OptionsEnum";
import { GetNodeInfoResponse } from "../../../../types/get_node_info_response";
import { isCentralizedConfig } from "../../../shared/utils/Hierarchy.utils";
import { ConfigurationEnum } from "../../../shared/infrastructure/constants/ConfigurationEnum";

export interface IWebhookIndexState {
  webhookList: WebhookResponse;
  breadcrumbs: BreadcrumProps;
  isAdmin: boolean;
  webhookSelected?: Webhook;
  openDetail: boolean;
  handler: {
    handleOnDelete: (webhook: Webhook) => void;
    handleOpenTestConnection: (webhook: Webhook) => void;
    handleOpenNotification: () => void;
    handleEdit: (webhookSelected: Webhook) => void;
    handleOpenDetail: (webhookSelected: Webhook) => void;
    handlerClose: (_event?: React.SyntheticEvent, reason?: string) => void;
    handleCloseDetail: () => void;
    handleCopyTextMetadata: (value: string) => void;
    navigateCreate: () => void;
  };
  pagination: PaginationTableProps;
  confirmMessage: ConfirmMessageProps;
  modalTestConnection: ModalTestConnectionProps;
  merchantName: string;
  merchantId: string;
  snackbar: INotification;
  loadingTestConnection: boolean;
  hasLinkAlertNotification: boolean;
  merchantStatusIsPending: boolean;
  enableEdit: boolean;
}
export interface ConfirmMessage {
  title: string;
  message: string;
  textPrimaryButton?: string;
  textSecondaryButton?: string;
  colorPrimaryButton?: string;
  loading: boolean;
}
const pages: number[] = [5, 10, 15, 20];
const defaultValueTestConnection: {
  kindTransaction: string;
  body: string;
  service: string;
  urls: string[];
} = {
  kindTransaction: "approvedTransaction",
  body: "{}",
  service: "",
  urls: [],
};

export const useWebhookList = (
  props: WebhookIndexProps
): IWebhookIndexState => {
  const merchantId = get(useParams(), "merchantId");
  const history = useHistory();
  const [pageSize, setPageSize] = useState<number>(5);
  const [webhookList, setWebhookList] = useState<WebhookResponse>();
  const [webhook, setWebhook] = useState<Webhook | undefined>();
  const dispatch = useDispatch();
  const [firstEvaluatedKey, setFirstEvaluatedKey] =
    useState<undefined | object>(undefined);
  const [nextEvaluatedKey, setNextEvaluatedKey] = useState<undefined | object>(
    {}
  );
  const [previousEvaluatedKey, setPreviousEvaluatedKey] =
    useState<undefined | object>(undefined);
  const [openConfirmMessage, setOpenConfirmMessage] = useState<boolean>(false);
  const [openTestConnection, setOpenTestConnection] = useState<boolean>(false);
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [loadingTestConnection, setLoadingTestConnection] =
    useState<boolean>(false);
  const [webhookSelected, setWebhookSelected] =
    useState<Webhook | undefined>(undefined);
  const [actionSelected, setActionSelected] = useState<string>();
  const [breadcrumbs, setBreadcrumbs] = useState([
    { label: "Comercios", url: "" },
  ]);
  const [merchantName] = useState("");
  const [alertNotification, setAlertNotification] = useState<INotification>(
    props.notification
  );
  const [confirmMessage, setConfirmMessage] = useState<ConfirmMessage>({
    title: "",
    message: "",
    loading: false,
  });
  const [hasLinkAlertNotification, setHasLinkAlertNotification] =
    useState<boolean>(false);
  const [bodyTestConnection, setTestBodyConnection] = useState<{
    kindTransaction: string;
    service: string;
    body: string;
    urls: string[];
  }>(defaultValueTestConnection);
  const merchantStatus: string | undefined = useSelector(
    (state: IAppState) => state.merchantStatus
  );
  const nodeInfo: GetNodeInfoResponse | undefined = useSelector(
    (state: IAppState) => state.nodeInfo
  );
  const handleGetMerchantStatus = () => dispatch(getMerchantStatus(merchantId));
  const merchantStatusIsPending = () => merchantStatus === "pending";
  const [chargeback, handleChargeback] = useState(false);
  const origin = get(auth.getAuthMerchant(), "origin", "");
  const [enableEdit, setEnableEdit] = useState(true);

  useEffect(() => {
    let breadcrumbsTmp: { label: string; url: string }[];
    if (auth.isAdmin()) {
      breadcrumbsTmp = [{ label: "Comercios", url: "/merchant-list" }];

      if (merchantStatusIsPending() || origin === "createMerchant") {
        breadcrumbsTmp.push({
          label: "Crear comercio",
          url: `/create-merchant/details?publicMerchantId=${merchantId}&hideSideBar=true`,
        });
      } else {
        breadcrumbsTmp.push({
          label: "Desarrolladores",
          url: `/config-merchant/merchant/${merchantId}`,
        });
      }
    } else {
      breadcrumbsTmp = [
        { label: "Desarrolladores", url: "/config-merchant/developers" },
      ];
    }
    setBreadcrumbs(breadcrumbsTmp);
  }, [merchantStatus]);

  useEffect(() => {
    props.getMerchantInfo(merchantId);
    props.getWebhookSignature(merchantId);
    props.getNodeInfo(merchantId);
    handleGetMerchantStatus();
    dispatch(setPreauthEnable(false));
  }, []);

  useEffect(() => {
    if (auth.isAdmin()) {
      props.getWebhookList({
        merchantId: merchantId,
        limit: pageSize,
      });
      return;
    }
    props.getWebhookList({
      merchantId: auth.getAuthMerchant().publicMerchantId,
      limit: pageSize,
    });
  }, [pageSize]);

  useEffect(() => {
    if (props.webhookList) {
      setWebhookList(props.webhookList);
      setNextEvaluatedKey(
        get(props.webhookList, "lastEvaluatedKey", undefined)
      );
    }
  }, [props.webhookList]);

  useEffect(() => {
    setAlertNotification(props.notification);
    if (actionSelected === "deleteWebhook") {
      setConfirmMessage({
        ...confirmMessage,
        loading: false,
      });
      setOpenConfirmMessage(false);
      setHasLinkAlertNotification(false);
      setTimeout(() => {
        props.notification.type === "info" ? location.reload() : "";
      }, 3000);
    } else {
      setLoadingTestConnection(false);
      setOpenTestConnection(false);
      setHasLinkAlertNotification(props.notification.type !== "error");
    }
    setActionSelected(undefined);
  }, [props.notification]);

  useEffect(() => {
    if (
      nodeInfo &&
      isCentralizedConfig(nodeInfo, ConfigurationEnum.CN014, merchantId)
    )
      setEnableEdit(false);
  }, [nodeInfo]);

  const hasChargebackApprovedTrx = (currentEvents: WebhookEvent): boolean => {
    return (
      currentEvents.findIndex(
        (event: { service?: string; status?: string }) =>
          event.service === ServiceOptionEnum.CHARGEBACK &&
          event.status === WebhookEventsEnum.approvedTransaction
      ) > -1
    );
  };

  const hasChargebackDeclinedTrx = (currentEvents: WebhookEvent): boolean => {
    return (
      currentEvents.findIndex(
        (event: { service?: string; status?: string }) =>
          event.service === ServiceOptionEnum.CHARGEBACK &&
          event.status === WebhookEventsEnum.declinedTransaction
      ) > -1
    );
  };

  const hasAllChargeback = (currentEvents: WebhookEvent): boolean => {
    return (
      currentEvents.findIndex(
        (event: { service?: string; status?: string }) =>
          event.status === WebhookEventsEnum.chargeback
      ) > -1
    );
  };

  const buildEventsChargeVoid = (currentEvents: WebhookEvent) =>
    currentEvents.map((event: { service?: string; status?: string }) => {
      if (
        event.service === ServiceOptionEnum.CHARGEBACK &&
        event.status === WebhookEventsEnum.approvedTransaction
      )
        return {
          service: ServiceOptionEnum.CHARGEBACK_VOID,
          status: WebhookEventsEnum.approvedTransaction,
        };

      if (
        event.service === ServiceOptionEnum.CHARGEBACK &&
        event.status === WebhookEventsEnum.declinedTransaction
      )
        return {
          service: ServiceOptionEnum.CHARGEBACK_VOID,
          status: WebhookEventsEnum.declinedTransaction,
        };
      return event;
    });

  const checkEventChargeBack = (currentEvents: WebhookEvent): WebhookEvent => {
    const isChargebackApprovedTrx: boolean =
      hasChargebackApprovedTrx(currentEvents);

    const isChargebackDeclinedTrx: boolean =
      hasChargebackDeclinedTrx(currentEvents);

    const isAllChargeback: boolean = hasAllChargeback(currentEvents);

    if (isAllChargeback) {
      currentEvents = currentEvents.filter(
        (event: { service?: string; status?: string }) =>
          event.status !== WebhookEventsEnum.chargeback
      );

      Object.entries(
        PAYMENT_METHODS_EVENTS[ServiceOptionEnum.CHARGEBACK]
      ).forEach(([status]) => {
        currentEvents.push({
          service: ServiceOptionEnum.CHARGEBACK,
          status: status,
        });
      });
    }

    if (isChargebackApprovedTrx) {
      currentEvents = buildEventsChargeVoid(currentEvents);
    }

    if (isChargebackDeclinedTrx) {
      currentEvents = buildEventsChargeVoid(currentEvents);
    }

    return currentEvents;
  };

  const handleOnDelete = (webhook: Webhook) => {
    setOpenConfirmMessage(true);
    setActionSelected("deleteWebhook");
    setConfirmMessage({
      title: "¿Deseas eliminar este webhook?",
      message:
        "Si eliminas este webhook, estarás eliminando la notificación por eventos que fueron configurados",
      textPrimaryButton: "Eliminar webhook",
      textSecondaryButton: "Cancelar",
      colorPrimaryButton: "#E24763",
      loading: false,
    });
    setWebhookSelected(webhook);
  };

  const handleChangePages = (value: number) => {
    setPageSize(value);
  };

  const handleCancel = (kind: string) => {
    setCancelMessage(kind);
    setWebhookSelected(undefined);
  };

  const setCancelMessage = (kind: string) => {
    setActionSelected(undefined);
    switch (kind) {
      case "delete":
        setOpenConfirmMessage(false);
        break;
      case "testConnection":
        setOpenTestConnection(false);
        break;
    }
  };

  const handleDeleteWebhook = () => {
    setConfirmMessage({
      ...confirmMessage,
      loading: true,
    });
    props.deleteWebhook(webhookSelected!.id);
  };

  const handlePrimaryActionConfirm = () => {
    switch (actionSelected) {
      case "deleteWebhook":
        handleDeleteWebhook();
        break;
      case "notification":
        setOpenConfirmMessage(false);
        break;
    }
  };

  const handleOpenTestConnection = (webhook: Webhook) => {
    setWebhook(webhook);
    setTestBodyConnection(defaultValueTestConnection);
    setOpenTestConnection(true);
    setWebhookSelected(webhook);
    handleChargeback(false);
  };

  const handleChangeKind = (kind: string, value: string) => {
    if (kind === "service") {
      let urls: string[] = [];
      webhookSelected!.urls.map((url) => {
        if (url.startsWith("{") && url.endsWith("}")) {
          let filteredUrls: { service: string; urls: string[] }[] = [];
          filteredUrls = webhookSelected!.urls
            .map((urls: string) => JSON.parse(urls))
            .filter(
              (urls: { service: string; urls: string[] }) =>
                urls.service === value || urls.service === "general"
            );
          urls = filteredUrls[0].urls;
        } else {
          urls = webhookSelected!.urls;
        }
      });
      setTestBodyConnection({
        ...bodyTestConnection,
        urls: isEmpty(urls) ? [] : urls,
        [kind]: value,
      });
      return;
    }
    setTestBodyConnection({
      ...bodyTestConnection,
      [kind]: value,
    });
  };

  const handleNextPage = () => {
    if (firstEvaluatedKey === undefined) setFirstEvaluatedKey(nextEvaluatedKey);
    setPreviousEvaluatedKey(nextEvaluatedKey);
    props.getWebhookList({
      merchantId: merchantId,
      limit: pageSize,
      lastEvaluatedKey: nextEvaluatedKey,
    });
  };

  const handlePrevious = () => {
    if (firstEvaluatedKey === previousEvaluatedKey) {
      setFirstEvaluatedKey(undefined);
      props.getWebhookList({
        merchantId: merchantId,
        limit: pageSize,
      });
    } else {
      props.getWebhookList({
        merchantId: merchantId,
        limit: pageSize,
        lastEvaluatedKey: previousEvaluatedKey,
      });
    }
  };

  const handleTestConnection = () => {
    if (!getErrorsFields()) return;
    setLoadingTestConnection(true);
    bodyTestConnection.body = bodyTestConnection.body.replace(
      /(\r\n|\n|\r)/gm,
      ""
    );
    bodyTestConnection.body = bodyTestConnection.body.replace(/ /g, "");
    bodyTestConnection.body = bodyTestConnection.body.replace(/​/g, "");
    // eslint-disable-next-line
    bodyTestConnection.body = bodyTestConnection.body.replace(/'/g, '"');

    props.testConnection(bodyTestConnection);
  };

  const getErrorsFields = () => {
    return (
      bodyTestConnection.service !== "" &&
      bodyTestConnection.body !== "" &&
      bodyTestConnection.kindTransaction !== ""
    );
  };

  const handleOpenNotification = () => {
    setActionSelected("notification");
    setOpenConfirmMessage(true);
    setConfirmMessage({
      title: "Intervalo de notificaciones",
      message:
        "Se generarán hasta 4 reintentos en intervalos de 30 minutos. Podrás ver el detalle en cada evento de webhook.",
      textPrimaryButton: "Aceptar",
      colorPrimaryButton: "#023365",
      loading: false,
    });
  };

  const handleEdit = (webhookSelected: Webhook) => {
    webhookSelected = {
      ...webhookSelected,
      events: checkEventChargeBack(webhookSelected.events),
    };
    props.setWebhookSelected(webhookSelected);
    history.push({ pathname: `${merchantId}/edit` });
  };

  const handlerClose = (_event?: React.SyntheticEvent, _reason?: string) => {
    props.setNotification({
      message: alertNotification.message,
      open: false,
      type: "info",
    });
  };
  const navigateCreate = () => {
    history.push({ pathname: `${merchantId}/create` });
  };

  const handleOpenDetail = (webhook: Webhook) => {
    const eventTransform = buildEvent(webhook);
    setOpenDetail(true);
    setWebhookSelected({ ...webhook, eventTransform: eventTransform });
  };

  const handleCopyTextMetadata = (value: string) => {
    navigator.clipboard.writeText(value);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
    setWebhookSelected(undefined);
  };

  const buildEvent = (webhookSelected: Webhook) => {
    const arr: { service: string; status: string[]; urls?: string[] }[] = [];
    const urls: { service: string; urls: string[] }[] = [];
    const urlsTmp: string[] = [];
    webhookSelected.urls.map((url) => {
      if (url.startsWith("{") && url.endsWith("}")) urls.push(JSON.parse(url));
      else urlsTmp.push(url);
    });
    if (urlsTmp.length > 0) {
      urls.push({ service: "general", urls: urlsTmp });
    }
    webhookSelected.events.map(
      (evt1: { service?: string; status?: string }) => {
        const eventsFiltered: {
          service?: string;
          status?: string;
        }[] = webhookSelected.events.filter(
          (evt) => evt.service === evt1.service!
        );
        const newEventsFiltered: {
          service: string;
          status: string[];
        }[] = arr.filter((newEvt) => newEvt.service === evt1.service!);
        const urlFiltered = urls.filter(
          (url) => url.service === evt1.service || url.service === "general"
        );

        if (newEventsFiltered.length === 0) {
          if (urlFiltered.length > 0) {
            const tmpUrl = urlFiltered.map((url) => url);
            arr.push({
              service: eventsFiltered[0].service!,
              status: eventsFiltered.map(({ status }) => status!),
              urls: tmpUrl[0].urls,
            });
          } else {
            arr.push({
              service: eventsFiltered[0].service!,
              status: eventsFiltered.map(({ status }) => status!),
            });
          }
        }
        if (newEventsFiltered.length > 0) {
          const status: string[] = eventsFiltered.map(({ status }) => status!);
          const index = arr.findIndex(
            ({ service }) => service === evt1.service
          );
          arr[index].status = status;
        }
      }
    );
    return arr;
  };

  return {
    breadcrumbs: {
      lastItem: "Webhooks",
      items: breadcrumbs,
    },
    isAdmin: auth.isAdmin(),
    webhookList: webhookList!,
    merchantName,
    merchantId,
    webhookSelected,
    openDetail,
    handler: {
      handleOnDelete,
      handleOpenTestConnection,
      handleOpenNotification,
      handleEdit,
      handlerClose,
      handleOpenDetail,
      handleCloseDetail,
      handleCopyTextMetadata,
      navigateCreate,
    },
    pagination: {
      pages,
      currentPages: pageSize,
      handleChangePages,
      handlerClickNext: handleNextPage,
      handleClickPrevious: handlePrevious,
      disableNext: !nextEvaluatedKey,
      disablePrevious: !firstEvaluatedKey,
    },
    confirmMessage: {
      open: openConfirmMessage,
      title: confirmMessage.title,
      message: confirmMessage.message,
      colorPrimaryButton: confirmMessage.colorPrimaryButton,
      textSecondaryButton: confirmMessage.textSecondaryButton,
      textPrimaryButton: confirmMessage.textPrimaryButton,
      loading: confirmMessage.loading,
      handleSecondaryButton: handleCancel,
      handlePrimaryButton: handlePrimaryActionConfirm,
    },
    modalTestConnection: {
      open: openTestConnection,
      handleChangeKind,
      value: "",
      handleCancel,
      handleTestConnection,
      loadingTestConnection,
      content: bodyTestConnection,
      webhook: webhook,
      chargeback,
      handleChargeback,
    },
    snackbar: alertNotification,
    loadingTestConnection,
    hasLinkAlertNotification,
    merchantStatusIsPending:
      merchantStatusIsPending() || origin === "createMerchant",
    enableEdit,
  };
};
