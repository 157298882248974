import { defaultTo, get } from "lodash";
import { Catalog, Data } from "../../../types/catalog_response";
import { CatalogPropertyEnum } from "../constants/catalogs/CatalogsNameEnum";
import { Category } from "../interfaces/category";

export const getDataFromCatalogByName = (
  catalogList: Catalog[] | undefined,
  catalogName: string
): Data[] =>
  get(
    defaultTo(catalogList, []).find(
      (catalog: Catalog) =>
        get(catalog, CatalogPropertyEnum.NAME) === catalogName
    ),
    CatalogPropertyEnum.DATA,
    []
  );

export const getCatalogDataByCode = (
  catalogsList: Catalog[] | undefined,
  catalogName: string,
  code: string
): Data =>
  defaultTo(
    getDataFromCatalogByName(catalogsList, catalogName).find(
      (data: Data) => get(data, CatalogPropertyEnum.CODE) === code
    ),
    { code: "-", value: "-" }
  );

export const catalogsDataToCategory = (catalogsData: Data[]): Category[] => {
  return catalogsData.map((item) => ({
    name: item.value,
    value: item.code,
  }));
};
