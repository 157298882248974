import React, { ChangeEvent, FC } from "react";
import {
  Box,
  Grid,
  MenuItem,
  Pagination,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import { useMobileState } from "../../../shared/state/useMobileState";
import { useTableContext } from "../../Table/TableContext";
import { paginationFooterStyles } from "./FooterPagination.styles";
import { IPaginationFooterProps } from "./FooterPagination.interfaces";

const FooterPagination: FC<IPaginationFooterProps> = (
  props: IPaginationFooterProps
) => {
  const { isMobile } = useMobileState();
  const {
    state: { isLoading },
  } = useTableContext();

  return (
    <>
      <Grid
        container
        sx={paginationFooterStyles.container}
        style={props.isDisable ? { opacity: "0.4", pointerEvents: "none" } : {}}
      >
        <Grid item display="flex" alignItems="center">
          {!isLoading ? (
            <Box display="flex" alignItems="center">
              <Box pr={1.5} sx={paginationFooterStyles.paginationTextBlock}>
                <Typography color={"text.primary"} variant={"overline"}>
                  {props.paginationText}
                </Typography>
              </Box>
              <Box pr={3}>
                <Select
                  id={"select"}
                  data-testid={"selectT"}
                  disableUnderline
                  value={props.itemsPerPage}
                  variant={"standard"}
                  sx={paginationFooterStyles.optionText}
                  onChange={(event) =>
                    props.onItemsPerPageChange(Number(event.target.value))
                  }
                  renderValue={(value) => (
                    <Typography color="text.primary" variant="overline">
                      {value}
                    </Typography>
                  )}
                >
                  {props.textFieldSelector.map((item: number, i: number) => (
                    <MenuItem value={item} key={i + item + ""}>
                      <Typography color={"text.dark"} variant={"body2"}>
                        {item}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box style={{ whiteSpace: "nowrap" }}>
                <Typography color={"text.primary"} variant={"overline"}>
                  {`${
                    (props.itemsPerPage * (props.selectedPage - 1) || 0) + 1
                  }-${props.itemsPerPage * props.selectedPage} de ${
                    props.totalItems
                  }`}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box
              display="flex"
              alignItems="center"
              sx={paginationFooterStyles.paginationTextSkeleton}
            >
              <Skeleton
                variant="rectangular"
                width={104}
                height={16}
                className={"desktop"}
                style={{ marginRight: "24px" }}
              />
              <Skeleton
                variant="rectangular"
                width={40}
                height={16}
                style={{ marginRight: "8px" }}
              />
              <Skeleton variant="rectangular" width={67} height={16} />
            </Box>
          )}
        </Grid>
        <Grid item container sx={paginationFooterStyles.paginationBlock}>
          {!isLoading ? (
            <Pagination
              id={"pagination"}
              count={Math.ceil(Number(props.totalItems / props.itemsPerPage))}
              color={"primary"}
              onChange={(_event: ChangeEvent<unknown>, value: number) =>
                props.onPageClick(Number(value))
              }
              sx={paginationFooterStyles.pagination}
              page={props.selectedPage}
              boundaryCount={isMobile ? 0 : 1}
              size={"small"}
              shape="rounded"
              style={{ display: "inline-block", verticalAlign: "middle" }}
            />
          ) : (
            <Box
              sx={paginationFooterStyles.paginationSkeleton}
              data-testid="containerSkeleton"
            >
              <Skeleton variant="rectangular" width={32} height={32} />
              <Skeleton variant="rectangular" width={32} height={32} />
              <Skeleton variant="rectangular" width={32} height={32} />
              <Skeleton variant="rectangular" width={32} height={32} />
              <Skeleton variant="rectangular" width={32} height={32} />
              <Skeleton variant="rectangular" width={32} height={32} />
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export { FooterPagination };
