import React, { useEffect, useState } from "react";
import {
  cloneDeep,
  defaultTo,
  get,
  includes,
  isEmpty,
  isEqual,
  isNil,
} from "lodash";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { RootState } from "../../../store/store";
import { TransactionEnum } from "../../../shared/enums/TransactionEnum";
import { IUseTransactionList } from "./IUseTransactionList.interfaces";
import {
  resetTransactionList,
  setClearTrxResult,
  setNotification,
} from "../../../store/actions/app.actions";
import {
  Transaction,
  TransactionData,
} from "../../../../types/transactions_data";
import { validateVoidTrx } from "../../../shared/utils/dashboard_transaction_utils";
import moment from "moment";
import {
  chargebackTransaction,
  getRetrieveChildren,
  getRetrieveChildrenMongo,
  validateReceivable,
  validateTimeRefund,
  voidTransaction,
} from "../../../store/thunks/app/app.thunks";
import { ProcessorEnum } from "../../../shared/infraestructure/ProcessorEnum";
import { CountryEnum } from "../../../shared/infraestructure/CountryEnum";
import { format } from "date-fns";
import { VoidBody } from "../../../store/interfaces/AppState.interfaces";
import { isSupportSessionActive } from "../../../store/utils/store-utils";
import {
  countriesWithPartialRefund,
  getColumnsUser,
  getKindTrx,
  getRefundAmount,
  validationMXProcessorKushki,
} from "../../../shared/utils/transaction_list_utils";
import { useSnackbar } from "@kushki/connect-ui";
import {
  buildNotification,
  defaultDataSnackbar,
} from "../../../shared/constants/snackbar";
import { NotificationTypeEnum } from "../../../shared/enum/SnackbarEnum";
import { IAllFilters } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheckChips/SelectCheckChips.interfaces";
import { IUserData } from "../../DashboardTransaction/state/useDashboardTransaction.interfaces";
import { getPaymentMethod } from "../../../shared/utils/chargeback_utils";
import { PaymentMethodsEnum } from "../../../shared/infraestructure/payment-method";

export const useTransactionList = (
  merchantFilter: IAllFilters
): IUseTransactionList => {
  const dispatch = useAppDispatch();

  const {
    isLoadingTransaction,
    transactionData,
    nodeInfo,
    timeRefund,
    receivable,
    isLoadingTimeRefund,
    isMongo,
    notification,
    voidDialogError,
    voidChargeBackError,
    resultTrx,
    countSuccessResults,
    merchantInfo,
    retrieveChildren,
  } = useAppSelector((state: RootState) => ({
    ...state.app,
  }));
  const { showSnackbar } = useSnackbar();

  const user_profile: IUserData = getColumnsUser();
  const [nodeId, setNodeId] = useState<string>("");
  const [emptyAddText, setEmptyAddText] = useState<string>(
    TransactionEnum.ADD_TRANSACTIONS
  );
  const [entityName, setEntityName] = useState<string>("");
  const [openDialogVoid, setOpenDialogVoid] = useState<boolean>(false);
  const [openDialogAlertTimeRefund, setOpenDialogAlertTimeRefund] =
    useState<boolean>(false);
  const [openReturnRequestDialog, setOpenReturnRequestDialog] =
    useState<boolean>(false);
  const [openNotReceivableDialog, setOpenNotReceivableDialog] =
    useState<boolean>(false);
  const [typeTrx, setTypeTrx] = useState<string>("");
  const [amountTrx, setAmountTrx] = useState<number>(0);
  const [trxInfo, setTrxInfo] = useState<Transaction>({});
  const [transactionList, setTransactionList] = useState<TransactionData>({
    data: [],
  });
  const [trxSelected] = useState<Transaction[]>([]);
  const [value, setValue] = useState<number>(amountTrx);
  const [errorAmount, setErrorAmount] = useState<boolean>(false);
  const [voidAmount, setVoidAmount] = useState<number | undefined>(undefined);
  const [typeRefund, setTypeRefund] = useState<string>("total");
  const [valorRefund, setValorRefund] = useState<number>(amountTrx);
  const [openVoidErrorDialog, setOpenVoidErrorDialog] =
    useState<boolean>(false);

  const handleReturn = () => window.history.go(-1);

  const validationCountry = (country: string | undefined) =>
    country === CountryEnum.MEXICO;

  const handleOpenDialogAlertTimeRefund = () =>
    setOpenDialogAlertTimeRefund(true);

  const handleOpenNotDialogVoid = () => {
    setOpenReturnRequestDialog(false);
    setOpenDialogVoid(true);
  };

  const handleOpenNotReceivable = () => {
    setOpenReturnRequestDialog(false);
    setOpenNotReceivableDialog(true);
  };

  const handleOpenDialogVoid = () => {
    if (
      new Date(
        moment(timeRefund?.expirationConsoleDay.dateResult!).format(
          "MM-DD-YYYY"
        )
      ) < new Date(moment(new Date()).format("MM-DD-YYYY")) &&
      validationCountry(trxInfo._source?.country) &&
      trxInfo._source?.payment_method === "card"
    ) {
      handleOpenDialogAlertTimeRefund();
    } else {
      const merchantId = defaultTo(
        JSON.parse(localStorage.getItem("merchantId")!),
        ""
      );

      let flagCountry = validationCountry(trxInfo._source?.country);

      dispatch(validateReceivable({ merchantId }));
      if (
        trxInfo._source?.processor_name !==
        ProcessorEnum.KushkiAcquirerProcessor
      ) {
        setOpenReturnRequestDialog(false);
        setOpenDialogVoid(true);
      } else {
        if (receivable) {
          if (flagCountry) {
            setOpenReturnRequestDialog(true);
            handleOpenNotReceivable();
          } else {
            setOpenReturnRequestDialog(true);
            setOpenDialogVoid(true);
          }
        } else {
          if (flagCountry) {
            setOpenReturnRequestDialog(true);
            handleOpenNotDialogVoid();
          } else setOpenDialogVoid(true);
        }
      }
    }
  };

  const handleCloseDialogVoid = () => {
    setOpenDialogVoid(false);
    setTrxInfo({});
    setTypeTrx("");
    setAmountTrx(0);
  };

  const handleCloseNotReceivableDialogVoid = () =>
    setOpenNotReceivableDialog(false);

  const handleValorRefund = (
    trx: Transaction,
    type: string,
    amount: number
  ) => {
    const valor_trx: number = get(trx, "_source.approved_transaction_amount");
    const valor: number = type === "refund" ? valor_trx - amount : amount;

    const valor_refund: number = type === "void" ? valor_trx : valor;

    setValorRefund(valor_refund);
  };

  const handleDialogAlertTimeRefund = (
    trx: Transaction,
    type: string,
    amount: number
  ) => {
    setTrxInfo(trx);
    setTypeTrx(type);
    setAmountTrx(amount);
    handleValorRefund(trx, type, amount);
    if (trx._source?.country === CountryEnum.MEXICO) {
      dispatch(
        validateTimeRefund({
          date: format(new Date(trx._source?.created!), "yyyy/MM/dd"),
          processorName: trx._source?.processor_name as string,
        })
      );
    } else {
      handleOpenDialogVoid();
    }
  };

  const handleOpenNotReceivableDialogVoid = () =>
    setOpenNotReceivableDialog(true);

  const handleCloseDialogAlertTimeRefund = () =>
    setOpenDialogAlertTimeRefund(false);

  const handleOnDelete = (type: string, row?: Transaction) => {
    let trxVoid: VoidBody[];
    let trx: Transaction[] = row === undefined ? [...trxSelected] : [row];

    setOpenDialogVoid(false);

    if (isSupportSessionActive()) {
      dispatch(
        setNotification(
          buildNotification(
            NotificationTypeEnum.FAILED,
            defaultTo(notification, defaultDataSnackbar)
          )
        )
      );
    } else {
      if (type == "chargeback") {
        trxVoid = trx.map((elementCharge: Transaction) => {
          const paymentMethod: string = getPaymentMethod(
            get(elementCharge, "_source", {})
          );
          const isPos: boolean = isEqual(
            PaymentMethodsEnum.CARD_PRESENT,
            paymentMethod
          );

          return {
            id: isPos
              ? get(elementCharge, "_source.transaction_reference", "")
              : get(elementCharge, "_source.ticket_number", ""),
            kind: getKindTrx(elementCharge),
            paymentMethod: paymentMethod,
            ticketNumber: get(elementCharge, "_source.ticket_number", ""),
          };
        }) as VoidBody[];

        dispatch(chargebackTransaction(trxVoid));
      } else {
        const currencyCode = trx[0]._source!.currency_code!;

        const refundAmount = getRefundAmount(
          currencyCode,
          trx[0]._source!,
          voidAmount
        );

        trxVoid = trx.map((elementVoid: Transaction) => ({
          body: refundAmount,
          id: elementVoid._source!.ticket_number,
        })) as VoidBody[];

        dispatch(voidTransaction(trxVoid));
      }
    }

    setTrxInfo({});
    setTypeTrx("");
    setAmountTrx(0);
  };

  const handleTypeRefund = (event: React.ChangeEvent<HTMLInputElement>) =>
    setTypeRefund(event.target.value);

  const handleChangeValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value: number = Number(event.target.value.replace(/,/g, ""));

    setErrorAmount(false);

    if (value > valorRefund) {
      setErrorAmount(true);
    } else {
      setValue(value);
    }
  };

  const handleOnCloseModalResponse = (): void => {
    setOpenVoidErrorDialog(false);
    dispatch(setClearTrxResult());
  };

  const validateEntityName = () => {
    if (isEmpty(get(nodeInfo, "entityName", "")))
      setEntityName(get(merchantInfo, "entityName"));
  };

  const handleValidateTrx = (transaction: TransactionData) => {
    if (!isNil(transaction)) {
      if (defaultTo(transaction.data, []).length > 0) {
        const data_aux: TransactionData = cloneDeep(transaction);
        const transactions_aux = data_aux.data!.map((element: Transaction) => {
          return validateVoidTrx(element, defaultTo(retrieveChildren, []));
        });

        setTransactionList({ ...transaction, data: transactions_aux });
      } else setTransactionList({ data: [] });
    }
  };

  useEffect(() => {
    if (get(nodeInfo, "configs", []).length > 1) {
      !isEmpty(nodeInfo) && setNodeId(get(nodeInfo, "path", ""));
      setEmptyAddText(TransactionEnum.ADD_TRANSACTIONS);
      setEntityName(get(nodeInfo, "entityName", ""));
    }
  }, [nodeInfo]);

  useEffect(() => validateEntityName(), [merchantInfo]);

  useEffect(() => {
    dispatch(resetTransactionList());
  }, []);

  useEffect(() => {
    if (merchantFilter.itemsList.length === 0 && user_profile.admin) {
      if (defaultTo(get(transactionData, "data", []), []).length > 0) {
        let selected_request: { publicMerchantId: string }[] = [];

        defaultTo(transactionData!.data, []).forEach((item: Transaction) => {
          selected_request.push({
            publicMerchantId: get(item, "_source.merchant_id", ""),
          });
        });

        if (isMongo)
          dispatch(
            getRetrieveChildrenMongo({
              body: [
                {
                  entityName: "",
                  publicMerchantId: "",
                },
              ],
              isTransactionSearch: true,
            })
          );
        else
          dispatch(
            getRetrieveChildren({
              body: selected_request,
              isTransactionSearch: true,
            })
          );
      } else setTransactionList({ data: [] });
    } else handleValidateTrx(transactionData!);
  }, [transactionData]);

  useEffect(() => handleValidateTrx(transactionData!), [retrieveChildren]);

  useEffect(() => {
    if (!isEmpty(trxInfo)) {
      handleOpenDialogVoid();
    }
  }, [timeRefund]);

  useEffect(() => {
    setValue(
      typeTrx === "refund"
        ? get(trxInfo, "_source.approved_transaction_amount") - amountTrx
        : amountTrx
    );
  }, [typeTrx]);

  useEffect(() => {
    if (includes(countriesWithPartialRefund, trxInfo._source?.country))
      setVoidAmount(value);
    else setVoidAmount(undefined);

    setErrorAmount(false);
  }, [value]);

  useEffect(() => {
    setOpenVoidErrorDialog(voidDialogError);
  }, [voidDialogError]);

  useEffect(() => {
    if (
      !includes(countriesWithPartialRefund, get(trxInfo, "_source.country")) &&
      get(trxInfo, "_source.country") != ""
    ) {
      setTypeRefund("partial");
    } else {
      if (get(trxInfo, "_source.country") != "") {
        if (validationMXProcessorKushki(trxInfo)) setTypeRefund("partial");
      } else {
        setTypeRefund("partial");
      }
    }
  }, [trxInfo._source?.country, typeRefund]);

  useEffect(() => {
    if (notification) {
      showSnackbar(notification);
      dispatch(setNotification(undefined));
    }
  }, [notification]);

  return {
    dialogAlert: {
      amountTrx,
      countSuccessResults,
      errorAmount,
      handleChangeValue,
      handleCloseDialogAlertTimeRefund,
      handleCloseDialogVoid,
      handleCloseNotReceivableDialogVoid,
      handleDialogAlertTimeRefund,
      handleOnCloseModalResponse,
      handleOnDelete,
      handleOpenDialogAlertTimeRefund,
      handleOpenDialogVoid,
      handleOpenNotReceivableDialogVoid,
      handleTypeRefund,
      isLoadingTimeRefund,
      openDialogAlertTimeRefund,
      openDialogVoid,
      openNotReceivableDialog,
      openReturnRequestDialog,
      openVoidErrorDialog,
      resultTrx,
      trxInfo,
      typeRefund,
      typeTrx,
      valorRefund,
      value,
      voidAmount,
      voidChargeBackError,
    },
    emptyAddText,
    entityName,
    handleReturn,
    isLoadingTransactions: isLoadingTransaction,
    pathCustomer: nodeId,
    showEmptyScreen: isEmpty(get(transactionData, "data", [])),
    transactionList,
    trxSelected,
  };
};
