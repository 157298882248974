import * as React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import { Box, Button, Divider, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { HelpCircle } from "react-feather";
import { CountryNameEnum } from "../../shared/infrastructure/enums/CountryEnum";
import { CURRENCY_TABLE } from "../../shared/infrastructure/enums/CurrencyValueEnum";
import { useBalanceDescription } from "./state/useBalanceDescription";
import { BalanceTitlesEnum } from "../../shared/infrastructure/enums/BalanceTitlesEnum";
import { ToolTipTitlesEnum } from "../../shared/infrastructure/enums/ToolTipTitlesEnum";
import { makeStyles } from "@material-ui/core/styles";
import { get } from "lodash";
import { countriesPayOut, formatNumber } from "../../shared/utils/utils";

export default function BalanceDescription() {
  const {
    balanceResponse,
    country,
    handleCheckoutV2,
    merchantPayout: { isPayout },
  } = useBalanceDescription();
  const currency: string = CURRENCY_TABLE[country];
  const useStyles = makeStyles({
    root: {
      color: "#293036",
      fontWeight: 400,
    },
    button: {
      background: "#023365",
      color: "#FFFFFF",
      width: "171px",
      height: "40px",
      "&:hover": {
        background: "#023365",
      },
    },
  });
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid container>
        <Grid>
          <Typography
            variant={"subtitle1"}
            color={"primary"}
            style={{ color: "#293036", fontWeight: 500 }}
          >
            {BalanceTitlesEnum.AvailableBalance}
            <Tooltip
              title={ToolTipTitlesEnum.AvailableBalance}
              placement="top-start"
            >
              <IconButton>
                <HelpCircle />
              </IconButton>
            </Tooltip>
          </Typography>

          <Typography
            variant={"body1"}
            color={"primary"}
            className={classes.root}
          >
            {formatNumber(balanceResponse.balance, currency, country)}
          </Typography>
        </Grid>
        <Divider
          orientation="vertical"
          variant={"middle"}
          flexItem
          style={{ background: "black" }}
        />
        <Grid>
          <Typography
            variant={"subtitle1"}
            color={"primary"}
            className={classes.root}
          >
            {BalanceTitlesEnum.CountableBalance}
            <Tooltip
              title={ToolTipTitlesEnum.CountableBalance}
              placement="top-start"
            >
              <IconButton>
                <HelpCircle />
              </IconButton>
            </Tooltip>
          </Typography>

          <Typography
            variant={"body1"}
            color={"primary"}
            className={classes.root}
          >
            {formatNumber(
              get(balanceResponse, "countableBalance", 0),
              currency,
              country
            )}
          </Typography>
        </Grid>
        <Divider
          orientation="vertical"
          variant={"middle"}
          flexItem
          style={{ background: "black" }}
        />
        <Grid>
          <Typography
            variant={"subtitle1"}
            color={"primary"}
            className={classes.root}
          >
            {BalanceTitlesEnum.UnprocessedBalance}
            <Tooltip
              title={ToolTipTitlesEnum.UnprocessedBalance}
              placement="top-start"
            >
              <IconButton>
                <HelpCircle />
              </IconButton>
            </Tooltip>
          </Typography>

          <Typography
            variant={"body1"}
            color={"primary"}
            className={classes.root}
          >
            {formatNumber(
              get(balanceResponse, "unprocessedBalance", 0),
              currency,
              country
            )}
          </Typography>
        </Grid>
      </Grid>
      {isPayout &&
        country != CountryNameEnum.chile &&
        countriesPayOut[country] && (
          <Button
            variant="contained"
            className={classes.button}
            onClick={handleCheckoutV2}
          >
            {"Recargar Saldo"}
          </Button>
        )}
    </Box>
  );
}
