import React from "react";
import { CardBlueProps, CardBlue } from "../CardBlue/CardBlue";
import { get } from "lodash";
import { LANGUAGE_CATALOG } from "../../../infrastructure/constants/LanguageCatalog";
export interface InformationProps {
  merchantInfo?: any;
  ratesConfig?: any;
}

export const InformationTab: React.FC<InformationProps> = (
  props: InformationProps
) => {
  const { merchantInfo, ratesConfig } = props;

  const merchantToProps: CardBlueProps = {
    title: "Datos del Comercio",
    items: [
      { key: "Nombre", value: merchantInfo.name },
      { key: "Razón social", value: merchantInfo.socialReason },
      { key: "Tax ID", value: merchantInfo.taxId },
      { key: "URL", value: merchantInfo.webSite },
    ],
  };
  const billingToProps: CardBlueProps = {
    title: "Facturación, cobro y dispersión",
    items: [],
  };

  if (
    get(merchantInfo, "dispersionFrequency") ||
    get(merchantInfo, "chargeFrequency") ||
    get(merchantInfo, "invoiceFrequency") ||
    get(ratesConfig, "keepFraud")
  ) {
    billingToProps.items = [
      {
        key: "Frecuencia de facturación",
        value: get(
          LANGUAGE_CATALOG,
          `frequency.${get(merchantInfo, "invoiceFrequency", "").trim()}`,
          ""
        ),
      },
      {
        key: "Frecuencia de cobro",
        value: get(
          LANGUAGE_CATALOG,
          `frequency.${get(merchantInfo, "chargeFrequency", "").trim()}`,
          ""
        ),
      },
      {
        key: "Frecuencia de dispersión",
        value: get(
          LANGUAGE_CATALOG,
          `frequency.${get(merchantInfo, "dispersionFrequency", "").trim()}`,
          ""
        ),
      },
      {
        key: "Retención de fraude",
        value: get(ratesConfig, "keepFraud", "") ? "Si" : "No",
      },
    ];
  }

  return (
    <>
      {merchantInfo && <CardBlue {...merchantToProps} />}
      {billingToProps.items.length > 0 && <CardBlue {...billingToProps} />}
    </>
  );
};
