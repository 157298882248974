import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const CustomLoaderStyles = createNamedStyles({
  modalContainer: {
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    boxShadow:
      "0px 8px 17px rgba(106, 167, 197, 0.15), 0px 1.6px 2.7625px rgba(106, 167, 197, 0.075)",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    left: "50%",
    margin: "0px 0px",
    minHeight: "117px",
    minWidth: { md: "542px", sm: "370px", xs: "280px" },
    padding: { md: "16px 40px", xs: "16px 24px" },
    position: "absolute" as "absolute",
    textAlign: { md: "left", xs: "center" },
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    ".modalHeader": {
      padding: 0,
    },
  },
  itemLottie: {
    paddingRight: { md: "10px" },
  },
  boxLottie: {
    height: "84px",
    position: "relative",
    top: { md: "2px", xs: "0px" },
    width: "84px",
  },
});
