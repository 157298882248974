import { Box, CircularProgress, Dialog, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectLoading } from "../../../store/selectors/app/app";
import { GenericLoaderStyles as styles } from "./GenericLoader.styles";

const GenericLoader = () => {
  const loading = useSelector(selectLoading);

  return (
    <Dialog data-testid="dialog" maxWidth="md" open={loading}>
      <Box sx={styles.container}>
        <Box>
          <CircularProgress
            data-testid="circular-progress"
            sx={styles.circularColor}
            thickness={4}
            size={60}
          />
        </Box>
        <Box sx={styles.dataContainer}>
          <Typography data-testid="text" sx={styles.label} variant="h5">
            Estamos procesando los registros.
          </Typography>
          <Typography data-testid="text" sx={styles.label} variant="h5">
            No cierres esta ventana.
          </Typography>
          <Typography data-testid="text" sx={styles.subLabel} variant="h6">
            Por favor, espera...
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
};

export default GenericLoader;
