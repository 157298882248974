import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { X } from "react-feather";
import {
  ADD_BUTTON,
  EDIT_BUTTON,
  EDIT_COMPONENT,
  EDIT_MODULE,
  NEW_COMPONENT,
  NEW_MODULE,
} from "../../shared/constants/labels/modules_container_labels";
import CancelIcon from "@material-ui/icons/Cancel";
import { useModalDialog } from "./state/useModalDialog";
import ConnectForm from "../../containers/ConnectForm/ConnectForm";
import { Controller } from "react-hook-form";
import { CreateEditRequest } from "../../../types/create_edit_request";
import { FieldsEnum } from "../../shared/enums/fieldsEnun";
import { FormHelperTextCustom } from "../FormHelperTextCustom/FormHelperTextCustom";
import { IModalDialog } from "./ModalDialog.interfaces";
import { useStyles } from "./ModalDialog.styles";
import { TypeEnum } from "../../shared/enums/typeEnum";
import { get } from "lodash";

export const ModalDialog: React.FC<IModalDialog> = (props: IModalDialog) => {
  const {
    filteredRoles,
    labelRolModule,
    labelRolComponent,
    labelId,
    moduleSelected,
  } = useModalDialog(props);

  const styles = useStyles();

  const isRequired = (): boolean => {
    if (props.type == TypeEnum.MODULE) {
      return true;
    } else if (props.type == TypeEnum.COMPONENT && props.isNew) {
      return true;
    }

    return false;
  };

  const renderSelectOptions = (roles: any) => {
    const arrayRoles = Array.isArray(roles) ? roles : [roles];

    return (
      <div>
        {arrayRoles.map((value: string) => (
          <Chip
            className={styles.chip}
            key={`chipSelected-${value}`}
            label={value}
            onDelete={(e) => props.handlerDeleteRoles(e, value)}
            clickable
            deleteIcon={
              <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
            }
          />
        ))}
      </div>
    );
  };

  return (
    <ConnectForm<CreateEditRequest>>
      {({ control, watch, formState: { errors } }) => {
        const id = watch(FieldsEnum.id);
        const description = watch(FieldsEnum.description);
        const validateRoles = watch(FieldsEnum.roles);

        return (
          <Dialog
            data-testid="dialogContent"
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"md"}
          >
            <div style={{ padding: 10 }}>
              <Box className={styles.dialogTitle} display="flex" flexGrow={1}>
                <Box display="flex" alignItems="center" flexGrow={1}>
                  <Typography variant={"h5"} color="primary">
                    {props.isNew
                      ? props.type === TypeEnum.MODULE
                        ? NEW_MODULE
                        : NEW_COMPONENT
                      : props.type === TypeEnum.MODULE
                      ? EDIT_MODULE
                      : EDIT_COMPONENT}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="flex-start">
                  <IconButton
                    aria-label="close"
                    className={styles.closeButton}
                    onClick={props.handleClose}
                  >
                    <X size={18} />
                  </IconButton>
                </Box>
              </Box>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Controller
                    name={FieldsEnum.id}
                    control={control}
                    rules={{ required: false }}
                    defaultValue={id}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="outlined-basic"
                        label={labelId}
                        variant="outlined"
                        className={styles.dialogId}
                        disabled={!props.isNew}
                      />
                    )}
                  />
                  <FormHelperTextCustom errors={errors} name={FieldsEnum.id} />
                </DialogContentText>
              </DialogContent>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Controller
                    name={FieldsEnum.description}
                    control={control}
                    rules={
                      props.isNew ? { required: true } : { required: false }
                    }
                    defaultValue={description}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="outlined-basic"
                        label="Descripción"
                        className={styles.dialogId}
                        defaultValue={
                          moduleSelected !== undefined
                            ? moduleSelected!.description
                            : ""
                        }
                        variant="outlined"
                      />
                    )}
                  />
                  <FormHelperTextCustom
                    errors={errors}
                    name={FieldsEnum.description}
                  />
                </DialogContentText>
              </DialogContent>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Typography
                    variant={"h6"}
                    className={styles.text}
                    style={{ fontWeight: "bold" }}
                  >
                    {props.type === TypeEnum.MODULE
                      ? labelRolModule
                      : labelRolComponent}
                  </Typography>
                </DialogContentText>
                <Controller
                  name={FieldsEnum.roles}
                  control={control}
                  rules={{ required: isRequired() }}
                  defaultValue={validateRoles}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className={styles.dialogSelect}
                      labelId="mutiple-select-label"
                      multiple={props.type == TypeEnum.COMPONENT}
                      renderValue={(rolesSelected: any) =>
                        renderSelectOptions(rolesSelected)
                      }
                    >
                      {filteredRoles!.map((value) => (
                        <MenuItem
                          className={styles.dialogId}
                          key={`menuItem-${value}`}
                          value={value}
                        >
                          <ListItemIcon>
                            <Checkbox
                              checked={(watch(FieldsEnum.roles) || []).includes(
                                value
                              )}
                            />
                          </ListItemIcon>
                          <ListItemText primary={value} />
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <FormHelperTextCustom errors={errors} name={FieldsEnum.roles} />
              </DialogContent>
              <DialogActions style={{ padding: "8px 25px" }}>
                <Button
                  data-testid="buttonCancel"
                  onClick={props.handleClose}
                  className={styles.buttonClose}
                >
                  Cancelar
                </Button>
                <Button
                  data-testid="buttonCreateEdit"
                  variant={"contained"}
                  color={"primary"}
                  onClick={() => {
                    props.handleCreateOrEdit({
                      parent: get(moduleSelected, "parent"),
                      status: get(moduleSelected, "status"),
                      type: props.type,
                    });
                  }}
                >
                  {props.isNew ? ADD_BUTTON : EDIT_BUTTON}
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        );
      }}
    </ConnectForm>
  );
};
