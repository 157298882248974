import { ActionKeyEnum } from "../../../shared/infrastructure/enums/ActionEnum";
import { AnalysisStateEnum } from "../../../shared/infrastructure/constants/AnalysisStateEnum";
import { DetailTitle } from "../../../shared/infrastructure/constants/RefundDashboardConstans";
import { formatZoned } from "../../../shared/utils/formatZoned";
import { get } from "lodash";
import { IModalHeaderInfoProps } from "./ModalHeaderInfo.interfaces";
import { ModalHeader } from "@kushki/frontend-molecules/modal-header";
import React from "react";
import { StatusFlowValidatorExecutor } from "../../../shared/infrastructure/enums/StatusFlowEnum";
import { TransactionData } from "../../../../types/transaction_data";

export const ModalHeaderInfo = (props: IModalHeaderInfoProps) => {
  const title = get(
    props,
    "cardTitle",
    get(props, `transaction.${get(props, "cardTitleFrom", "")}`, "")
  );

  const transaction: TransactionData = get(props, "transaction", {});

  const subtitle: Record<StatusFlowValidatorExecutor, string> = {
    [StatusFlowValidatorExecutor.EXECUTOR]:
      get(transaction, "stateRefund") === AnalysisStateEnum.NTHRZD
        ? DetailTitle.TRX_DETAIL
        : DetailTitle.REFUND_DETAIL,
    [StatusFlowValidatorExecutor.VALIDATOR]:
      get(transaction, "action", ActionKeyEnum.RPY) === ActionKeyEnum.RPY
        ? DetailTitle.REFUND_DETAIL
        : DetailTitle.TRX_DETAIL,
    [StatusFlowValidatorExecutor.VIEWER]:
      get(transaction, "stateRefund") === AnalysisStateEnum.NTHRZD
        ? DetailTitle.TRX_DETAIL
        : DetailTitle.REFUND_DETAIL,
  };

  const date = new Date(get(props, "created", ""));

  return (
    <>
      <ModalHeader
        highlightValue={title}
        tittle={
          subtitle[
            get(props, "statusFlow", StatusFlowValidatorExecutor.EXECUTOR)
          ]
        }
        secondaryValue={`Creación: ${
          formatZoned(date, "dd MMM'.' yyyy") + " " + formatZoned(date, "HH:mm")
        }`}
        chipList={props.chips}
      />
    </>
  );
};
