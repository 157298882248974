import React, { FC } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { MODAL_WEBHOOK_MODAL_LABELS } from "../../shared/constants/labels/add_webhook_labels";
import { IconSquareCheck, IconSquareCross } from "@kushki/connect-ui";
import {
  GeneralWebhook,
  WebhookResponse,
} from "../../../types/webhook_response_schema";
import { get } from "lodash";

export interface IWebhookGeneral {
  general: WebhookResponse;
}

export const WebhookGeneral: FC<IWebhookGeneral> = ({
  general,
}: IWebhookGeneral) => {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography color="text.primary" variant="caption">
            {MODAL_WEBHOOK_MODAL_LABELS.webhook}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {get(general, "general", []).map(
              (webhook: GeneralWebhook, index: number) => (
                <Grid item xs={12} key={index}>
                  <Stack direction="row" spacing={1}>
                    {get(webhook, "status", "") === "FAILED" ? (
                      <IconSquareCross color="error" fontSize="medium" />
                    ) : (
                      <IconSquareCheck color="success" fontSize="medium" />
                    )}
                    <Typography variant="body2" color="text.dark">
                      {get(webhook, "url", "")}
                    </Typography>
                  </Stack>
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
