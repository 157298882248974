import React, { FC } from "react";
import { Box } from "@mui/material";
import { IModalDialog } from "./ModalDialog.interfaces";
import { ModalSimple } from "@kushki/connect-ui";

const ModalDialog: FC<IModalDialog> = (props: IModalDialog) => {
  return (
    <Box>
      <div style={{ width: "min-content" }}>
        <ModalSimple
          onClickPrimary={props.onClickAcceptButton}
          onClickSecondary={props.onClickCancelButton}
          buttonPrimary={props.acceptButtonText}
          buttonSecondary={props.cancelButtonText}
          descriptionText={props.descriptionText}
          isOpen={props.openModalDialog}
          onClose={props.onClose}
          titleText={props.titleText}
          typeModal={1}
          buttonPrimaryDisabled={false}
        />
      </div>
    </Box>
  );
};

export default ModalDialog;
