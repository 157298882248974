export enum RetentionButtonLabelsEnum {
  EXECUTE = "Ejecutar",
}

export enum ConfirmRetentionModalEnum {
  EXECUTOR_CONFIRM_MESSAGE = "¿Deseas mandar a revisión todas las retenciones?",
  VALIDATOR_CONFIRM_MESSAGE = "¿Deseas ejecutar todas las retenciones?",
  EXECUTOR_CONTENT_LABEL = "Estás a punto de procesar estos registros. Esta acción no se puede deshacer.",
  VALIDATOR_CONTENT_LABEL = "Estás a punto de ejecutar estos registros. Esta acción no se puede deshacer.",
  EXECUTOR_ACCEPT_LABEL = "Mandar a revisión",
  VALIDATOR_ACCEPT_LABEL = "Ejecutar",
}

export enum LoaderRetentionModalEnum {
  LOADER_MAIN_MESSAGE = "Estamos procesando los registros.",
  LOADER_SECONDARY_MESSAGE = "No cierres esta ventana.",
}

export enum StatusRetentionModalEnum {
  APPROVED_RETENTION = "Retenciones aprobadas",
  REJECTED_RETENTION = "Retenciones rechazadas",
  PENDING_RETENTION = "Retenciones pendientes",
  MODIFIED_RETENTION = "Retenciones modificadas",
  OMITTED_RETENTION = "Retenciones omitidas",
  TOTAL = "Total a procesar",
  VALIDATOR_ALERT_MESSAGE = "*Las retenciones pendientes regresarán al dashboard del Ejecutor.",
}
