import { debounce } from "lodash";
import { useAppDispatch } from "../../../../../store/hooks/hooks";
import { setSearchFilter } from "../../../../../store/actions/filters/filters";
import { IUseSearchFilterState } from "./useSearchFilterState.interfaces";
import React from "react";

export const useSearchFilterState = (): IUseSearchFilterState => {
  const dispatch = useAppDispatch();

  const handleSetSearchFilter = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setSearchFilter(event.target.value));
  };

  const debouncedHandleSetSearchFilter = debounce(handleSetSearchFilter, 1000);

  return {
    debouncedHandleSetSearchFilter,
  };
};
