import { applyMiddleware, compose, createStore, Store } from "redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { environment } from "./environments/environment";
// @ts-ignore
import Normalize from "react-normalize";
import React from "react";
import theme from "./theme";
import thunk from "redux-thunk";
import { PayoutsDispersionIndex } from "./containers/PayoutsDispersionIndex/PayoutsDispersionIndex";
import { Provider } from "react-redux";
import { reducer } from "./store/reducer";
import { Routes } from "./shared/infrastructure/routes";
import { ThemeProvider } from "@material-ui/core/styles";
import { ValidationDetails } from "./containers/ValidationDetails/ValidationDetails";
import { ValidationDetailsConstants } from "./shared/infrastructure/constants/ValidationDetailsConstants";

const composeEnhancers = environment.devTools
  ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose;

const store: Store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunk))
);

const Root = () => {
  return (
    <React.StrictMode>
      <Normalize />
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Switch>
              <Route
                path={Routes.BASE_PATH_DISPERSION}
                exact
                component={PayoutsDispersionIndex}
              />
              <Route
                path={`${Routes.BASE_PATH_DISPERSION}/${ValidationDetailsConstants.REFERENCE_NUMBER}/${ValidationDetailsConstants.REFERENCE_NUMBER_PARAM}`}
                exact
                component={ValidationDetails}
              />
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
};

export default Root;
