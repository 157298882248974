import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { SnackBarAlert } from "../SnackBarAlert";
import { dialogStyles } from "./Dialogs.styles";
import { useDialogsState } from "./state/useDialogsState";
import { IDialogsProps } from "./Dialogs.interfaces";
import { ConciliationActionEnum } from "../../shared/infrastructure/enums/ConciliationActionEnum";

const Dialogs: FC<IDialogsProps> = (props) => {
  const {
    handleChange,
    handleOnCloseSnackBarAlert,
    handleReset,
    handleTextChange,
    handleUpdateTransactionState,
    isDisabledAcceptBtn,
    isSaveButton,
    isSkipButton,
    notification,
    transactionState,
  } = useDialogsState(props);

  const { dialog, handleClose, open } = props;

  return (
    <>
      <Dialog
        sx={dialogStyles.modalWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography sx={dialogStyles.contentTitleFont} variant={"h4"}>
            {dialog.title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={dialogStyles.font600}>
            {dialog.merchantName}
          </DialogContentText>
          <DialogContentText sx={dialogStyles.font500}>
            {dialog.merchantId}
          </DialogContentText>
          <Box mb={3} />
          <DialogContentText sx={dialogStyles.font500}>
            {dialog.description}
          </DialogContentText>
          {isSkipButton && (
            <TextField
              sx={dialogStyles.skipField}
              label="Escribe el motivo"
              onChange={handleTextChange}
              multiline
              rows={4}
              maxRows={4}
              inputProps={{ maxLength: 200 }}
              variant="outlined"
            />
          )}
          {isSaveButton && (
            <TextField
              sx={dialogStyles.formControl}
              label={transactionState === "" ? "Seleccionar" : ""}
              select
              value={transactionState}
              onChange={handleChange}
              InputLabelProps={{ shrink: false }}
            >
              <MenuItem value={ConciliationActionEnum.REVIEW_PROCESSOR}>
                En revisión procesador
              </MenuItem>
              <MenuItem value={ConciliationActionEnum.REVIEW_KUSHI}>
                En revisión interna
              </MenuItem>
            </TextField>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            sx={dialogStyles.cancelButton}
            onClick={handleReset}
            variant="text"
          >
            Cancelar
          </Button>
          <Button
            sx={dialogStyles.acceptButton}
            disabled={isDisabledAcceptBtn}
            variant="contained"
            color="primary"
            onClick={handleUpdateTransactionState}
          >
            {dialog.buttonText}
          </Button>
        </DialogActions>
      </Dialog>
      <SnackBarAlert
        {...notification}
        handlerClose={handleOnCloseSnackBarAlert}
      />
    </>
  );
};

export { Dialogs };
