import { CatalogsNameEnum } from "../Enums/CatalogsNameEnum";
import { ICatalogConfig } from "../../../components/CatalogInfoPopup/CatalogInfoPopup.interfaces";
import { FilterTypeEsEnum } from "../Enums/FilterTypeEnum";
import { ColumnsEnumEC } from "../Table/ColumnsTable";
import { RetentionTitleEnum } from "../Enums/RetentionTextEnum";

export enum RetentionType {
  taxpayerType = "sTaxpayerType",
  activity = "sActivity",
  incomeSourceConcept = "sIncomeSourceConcept",
  ivaRetention = "sIvaRetention",
  codeDatilTranslate = "codeDatilTranslate",
}

export enum IvaRetentionEnum {
  I10 = "I10",
  I20 = "I20",
  I30 = "I30",
  I70 = "I70",
  I100 = "I100",
  I0R = "I0R",
  I0NR = "I0NR",
}

export enum RetentionPercentCodeEnum {
  RP303 = "303",
}

export enum CodeDatilEnum {
  DatilIva1 = "1",
  DatilIva2 = "2",
  DatilIva3 = "3",
  DatilIva7 = "7",
  DatilIva8 = "8",
  DatilIva9 = "9",
  DatilIva10 = "10",
  DatilRent303 = "303",
  DatilRent304 = "304",
  DatilRent304A = "304A",
  DatilRent304B = "304B",
  DatilRent304C = "304C",
  DatilRent304D = "304C",
  DatilRent304E = "304C",
}

export const RetentionCatalog: Record<string, object> = {
  [RetentionType.ivaRetention]: {
    [IvaRetentionEnum.I10]: "10",
    [IvaRetentionEnum.I20]: "20",
    [IvaRetentionEnum.I30]: "30",
    [IvaRetentionEnum.I70]: "70",
    [IvaRetentionEnum.I100]: "100",
    [IvaRetentionEnum.I0R]: "Retención 0%",
    [IvaRetentionEnum.I0NR]: "No procede Retención",
  },
  [RetentionType.codeDatilTranslate]: {
    [CodeDatilEnum.DatilIva1]: "30%",
    [CodeDatilEnum.DatilIva2]: "70%",
    [CodeDatilEnum.DatilIva3]: "100%",
    [CodeDatilEnum.DatilIva7]: "Retención 0%",
    [CodeDatilEnum.DatilIva8]: "No procede Retención",
    [CodeDatilEnum.DatilIva9]: "10%",
    [CodeDatilEnum.DatilIva10]: "20%",
    [CodeDatilEnum.DatilRent303]: "10%",
    [CodeDatilEnum.DatilRent304]: "8%",
    [CodeDatilEnum.DatilRent304A]: "8%",
    [CodeDatilEnum.DatilRent304B]: "8%",
    [CodeDatilEnum.DatilRent304C]: "8%",
    [CodeDatilEnum.DatilRent304D]: "8%",
    [CodeDatilEnum.DatilRent304E]: "8%",
  },
};

export enum TypeRetentionEnum {
  IVA = "iva",
  RETENTION = "retention",
  ICA = "ica",
}

export const RetentionDatil = {
  I10: { value: "9" },
  I20: { value: "10" },
  I30: { value: "1" },
  I70: { value: "2" },
  I100: { value: "3" },
  I0R: { value: "7" },
  I0NR: { value: "8" },
};

export const RetentionIva = {
  I10: { value: "10" },
  I20: { value: "20" },
  I30: { value: "30" },
  I70: { value: "70" },
  I100: { value: "100" },
  I0R: { value: "0" },
  I0NR: { value: "0" },
};
export const RetentionIvaEdit = {
  "9": "I10",
  "10": "I20",
  "1": "I30",
  "2": "I70",
  "3": "I100",
  "7": "I0R",
  "8": "I0NR",
};

export const RetentionCatalogColumns: Record<string, string[]> = {
  [TypeRetentionEnum.IVA]: [
    FilterTypeEsEnum.taxpayerType,
    FilterTypeEsEnum.activity,
    RetentionTitleEnum.IVA,
  ],
  [TypeRetentionEnum.RETENTION]: [
    FilterTypeEsEnum.incomeSourceConcept,
    RetentionTitleEnum.RENTA,
  ],
};

export const RetentionCatalogFields: Record<string, ICatalogConfig[]> = {
  [TypeRetentionEnum.IVA]: [
    {
      catalogType: CatalogsNameEnum.TAX_PAYER_TYPE,
    },
    { catalogType: CatalogsNameEnum.ACTIVITY },
    { concatValue: "%", field: ColumnsEnumEC.retIva },
  ],
  [TypeRetentionEnum.RETENTION]: [
    {
      catalogType: CatalogsNameEnum.INCOME_SOURCE_CONCEPT,
    },
    { concatValue: "%", field: ColumnsEnumEC.retFue },
  ],
};
