/**
 * Catalog Peru
 * Catalog Peru
 */
import { Category } from "../infrastructure/constants/MerchantInformationConstant";
import { CatalogsEnum } from "../infrastructure/constants/catalogs-enum";

export const CatalogPe: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { value: "0", name: "DNI" },
    { value: "1", name: "Pasaporte" },
    { value: "2", name: "Ruc" },
    { value: "3", name: "Carnet Ext" },
  ],
  [CatalogsEnum.Provinces]: [
    {
      value: "AMA",
      name: "Amazonas",
      provinces: [
        "Chachapoyas",
        "Bagua",
        "Bongara",
        "Condorcanqui",
        "Luya",
        "Rodríguez de Mendoza",
        "Utcubamba",
      ],
    },
    {
      value: "ANC",
      name: "Ancash",
      provinces: [
        "Huaraz",
        "Aija",
        "Antonio raymondi",
        "Asunción",
        "Bolognesi",
        "Carhuaz",
        "Carlos Fermín Fitzcarrald",
        "Casma",
        "Corongo",
        "Huari",
        "Huarmey",
        "Huaylas",
        "Mariscal Luzuriaga",
        "Ocros",
        "Pallasca",
        "Pomabamba",
        "Recuay",
        "Santa",
        "Sihuas",
        "Sihuas",
        "Yungay",
      ],
    },
    {
      value: "APU",
      name: "Apurimac",
      provinces: [
        "Abancay",
        "Andahuaylas",
        "Antabamba",
        "Aymaraes",
        "Cotabambas",
        "Chincheros",
        "Grau",
      ],
    },
    {
      value: "ARE",
      name: "Arequipa",
      provinces: [
        "Arequipa",
        "Camana",
        "Caraveli",
        "Castilla",
        "Caylloma",
        "Condesuyos",
        "Islay",
        "La unión",
      ],
    },
    {
      value: "AYA",
      name: "Ayacucho",
      provinces: [
        "Huamanga",
        "Cangallo",
        "Huanca sancos",
        "Huanta",
        "La Mar",
        "Lucanas",
        "Parinacochas",
        "Páucar del Sara Sara",
        "Sucre",
        "Víctor Fajardo",
        "Vilcas Huaman",
      ],
    },
    {
      value: "CAJ",
      name: "Cajamarca",
      provinces: [
        "Cajamarca",
        "Cajabamba",
        "Celendin",
        "Chota",
        "Contumaza",
        "Cutervo",
        "Hualgayoc",
        "Jaén",
        "San Ignacio",
        "San Marcos",
        "San Miguel",
        "San Pablo",
        "Santa Cruz",
      ],
    },
    { value: "CAL", name: "Callao", provinces: ["Prov. Const. del Callao"] },
    {
      value: "CUS",
      name: "Cuzco",
      provinces: [
        "Cusco",
        "Acomayo",
        "Anta",
        "Calca",
        "Canas",
        "Canchis",
        "Chumbivilcas",
        "Espinar",
        "La Convención",
        "Paruro",
        "Paucartambo",
        "Quispicanchi",
        "Urubamba",
      ],
    },
    {
      value: "HUV",
      name: "Huancavelica",
      provinces: [
        "Huancavelica",
        "Acobamba",
        "Angaraes",
        "Castrovirreyna",
        "Churcampa",
        "Huaytara",
        "Tayacaja",
      ],
    },
    {
      value: "HUC",
      name: "Huanuco",
      provinces: [
        "Huanuco",
        "Ambo",
        "Dos de Mayo",
        "Huacaybamba",
        "Huamalies",
        "Leoncio Prado",
        "Marañon",
        "Pachitea",
        "Puerto inca",
        "Lauricocha",
        "Yarowilca",
      ],
    },
    {
      value: "ICA",
      name: "Ica",
      provinces: ["Ica", "Chincha", "Nasca", "Palpa", "Pisco"],
    },
    {
      value: "JUN",
      name: "Junin",
      provinces: [
        "Huancayo",
        "Concepción",
        "Chanchamayo",
        "Jauja",
        "Junín",
        "Satipo",
        "Tarma",
        "Yauli",
        "Chupaca",
      ],
    },
    {
      value: "LAL",
      name: "La Libertad",
      provinces: [
        "Trujillo",
        "Ascope",
        "Bolívar",
        "Chepen",
        "Julcan",
        "Otuzco",
        "Pacasmayo",
        "Pataz",
        "Sánchez Carrión",
        "Santiago de Chuco",
        "Gran Chimú",
        "Virú",
      ],
    },
    {
      value: "LAM",
      name: "Lambayeque",
      provinces: ["Chiclayo", "Ferreñafe", "Lambayeque"],
    },
    {
      value: "LIM",
      name: "Lima Region",
      provinces: [
        "Lima",
        "Barranca",
        "Cajatambo",
        "Canta",
        "Cañete",
        "Huaral",
        "Huarochiri",
        "Huaura",
        "Oyon",
        "Yauyos",
      ],
    },
    {
      value: "LOR",
      name: "Loreto",
      provinces: [
        "Maynas",
        "Alto amazonas",
        "Loreto",
        "Mariscal Ramón Castilla",
        "Requena",
        "Ucayali",
        "Datem del Marañon",
        "Putumayo",
      ],
    },
    {
      value: "MDD",
      name: "Madre de Dios",
      provinces: ["Tambopata", "Manu", "Tahuamanu"],
    },
    {
      value: "MOQ",
      name: "Moquegua",
      provinces: ["Mariscal Nieto", "General Sánchez Cerro", "Ilo"],
    },
    {
      value: "PAS",
      name: "Pasco",
      provinces: ["Pasco", "Daniel Alcides Carrión", "Oxapampa"],
    },
    {
      value: "PIU",
      name: "Piura",
      provinces: [
        "Piura",
        "Ayabaca",
        "Huancabamba",
        "Morropón",
        "Paita",
        "Sullana",
        "Talara",
        "Sechura",
      ],
    },
    {
      value: "PUN",
      name: "Puno",
      provinces: [
        "Puno",
        "Azangaro",
        "Carabaya",
        "Chucuito",
        "El Collao",
        "Huancane",
        "Lampa",
        "Melgar",
        "Moho",
        "San Antonio de Putina",
        "San Roman",
        "Sandia",
        "Yunguyo",
      ],
    },
    {
      value: "SAM",
      name: "San Martin",
      provinces: [
        "Moyobamba",
        "Bellavista",
        "El Dorado",
        "Huallaga",
        "Lamas",
        "Mariscal Cáceres",
        "Picota",
        "Rioja",
        "San Martín",
        "Tocache",
      ],
    },
    {
      value: "TAC",
      name: "Tacna",
      provinces: ["Tacna", "Candarave", "Jorge Basadre", "Tarata"],
    },
    {
      value: "TUM",
      name: "Tumbes",
      provinces: ["Tumbes", "Contralmirante Villar", "Zarumilla"],
    },
    {
      value: "UCA",
      name: "Ucayali",
      provinces: ["Coronel Portillo", "Atalaya", "Padre Abad", "Purus"],
    },
  ],
  [CatalogsEnum.Banks]: [
    { value: "0002", name: "Banco de Crédito del Perú" },
    { value: "0003", name: "Interbank" },
    { value: "0007", name: "Citibank del Perú S.A." },
    { value: "0009", name: "Scotiabank Perú S.A.A." },
    { value: "0011", name: "Banco Continental" },
    { value: "0018", name: "Banco de la Nación" },
    { value: "0023", name: "Banco de Comercio" },
    { value: "0035", name: "Banco Financiero del Perú" },
    { value: "0038", name: "Banco Interamericano de Finanzas (BIF)" },
    { value: "0053", name: "Banco Falabella" },
    { value: "0055", name: "Banco Ripley" },
    { value: "0056", name: "Banco Santander Perú" },
    { value: "0058", name: "Banco Azteca" },
    { value: "0054", name: "Banco GNB Perú S.A." },
    { value: "0802", name: "Caja Municipal de Ahorro y Crédito Trujillo" },
    { value: "0803", name: "Caja Municipal de Ahorro y Crédito Arequipa" },
    { value: "0805", name: "Caja Municipal de Ahorro y Crédito Sullana" },
    { value: "0808", name: "Caja Municipal de Ahorro y Crédito Huancayo" },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { value: "0", name: "Corriente" },
    { value: "1", name: "Ahorros" },
  ],
  [CatalogsEnum.Currency]: [
    { value: "PEN", name: "PEN" },
    { value: "USD", name: "USD" },
  ],
};
