import * as React from "react";
import { FC, PropsWithChildren } from "react";
import { IAccordionProps } from "./Accordion.interfaces";
import {
  Accordion as Acc,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { useAccordion } from "./state/useAccordion";
import AccordionStyles from "./Accordion.styles";
import { IconChevronDown, IconChevronUp } from "../Icons/index.export";
import { myMerchantContainerStyles as styles } from "../../containers/MyMerchantContainer/MyMerchantContainer.styles";

const Accordion: FC<IAccordionProps> = (
  props: PropsWithChildren<IAccordionProps>
) => {
  const {
    text,
    text2,
    iconLeft,
    iconSummaryDown,
    sectionComponent,
    iconRight,
  } = props;
  const { openAccordion, handleClickAccordion } = useAccordion(props);

  return (
    <Box sx={styles.boxAccordion}>
      <Acc
        data-testid={"acc"}
        sx={AccordionStyles.accordion}
        expanded={openAccordion}
        onChange={handleClickAccordion}
      >
        <AccordionSummary
          expandIcon={
            iconSummaryDown ? (
              <IconChevronDown
                color="primary"
                fontSize="medium"
                sx={AccordionStyles.iconAccordionDown}
              />
            ) : (
              <IconChevronUp
                color="primary"
                fontSize="medium"
                sx={AccordionStyles.iconAccordionUp}
              />
            )
          }
          sx={AccordionStyles.accordionSummary}
        >
          <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
            <Box display={"flex"} alignItems={"center"}>
              <Box sx={AccordionStyles.boxIcon}>{iconLeft}</Box>
              <Box display={"flex"} flexDirection={"column"}>
                <Box display={"flex"} alignItems={"center"}>
                  <Typography variant="h5" color="primary">
                    {text}
                  </Typography>
                </Box>
                {text2 && (
                  <Typography variant="h6" color="text.grey">
                    {text2}
                  </Typography>
                )}
              </Box>
              {iconRight && <Box sx={AccordionStyles.boxIcon}>{iconRight}</Box>}
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={AccordionStyles.accordionDetails}>
          <Box display="grid">{sectionComponent}</Box>
        </AccordionDetails>
      </Acc>
    </Box>
  );
};

export default Accordion;
