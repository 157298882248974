import { Popover } from "@mui/material";
import React, { FC, useState } from "react";
import {
  DateTimeRangePickerProps,
  IDateTime,
} from "./DateTimeRangePicker.interfaces";
import Picker from "./Picker/Picker";

const DateTimeRangePicker: FC<DateTimeRangePickerProps> = ({
  disableFuture = false,
  disablePast = false,
  from = "",
  onChange = () => undefined,
  renderComponent,
  to = "",
}: DateTimeRangePickerProps) => {
  const [anchorEl, setAnchorEl] = useState<Element>();

  const textValue = `${from} - ${to}`;

  const innerComponent = renderComponent({
    setAnchor: setAnchorEl,
    textValue,
  });

  const open = Boolean(anchorEl);

  const handleClose = () => setAnchorEl(undefined);

  const handleChange = (params: IDateTime) => {
    handleClose();
    onChange(params);
  };

  return (
    <>
      {innerComponent}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <Picker
          disableFuture={disableFuture}
          disablePast={disablePast}
          from={from}
          to={to}
          onChange={handleChange}
        />
      </Popover>
    </>
  );
};

export default DateTimeRangePicker;
