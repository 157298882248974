import { ActionTypes } from "./actionTypes";
import { TransactionData } from "../../types/transactions_data";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { environment } from "../environments/environment";
import axios from "../shared/axios-util";
import { AxiosError, AxiosResponse } from "axios";
import { ErrorResponse } from "../../types/error_analytics";
import { ErrorEnum } from "../shared/infrastructure/error_enum";
import { MerchantInfo } from "../../types/merchant-info";
import { IAppState } from "./reducer";
import {
  WebsocketActionsEnum,
  WebsocketStatusEnum,
} from "../shared/infrastructure/constants/websocket-enum";
import { get, isEmpty } from "lodash";
import { TransactionsRequest } from "../../types/transactions_request";
import { INotification } from "../shared/infrastructure/interfaces/INotification";
import { filterMerchants } from "../shared/infrastructure/utils";

export type IAppAction = { type: string } & Partial<IAppState>;

export type MerchantsData = {
  data: { _source: MerchantInfo }[];
  total: number;
};

export const setLoading = (loading: boolean) => ({
  type: ActionTypes.SET_LOADING,
  loading,
});

export const setTransactions = (payload: TransactionData): IAppAction => ({
  type: ActionTypes.SET_TRANSACTIONS,
  transactionData: payload,
});

export const setMerchantsList = (payload: MerchantsData): IAppAction => {
  return {
    type: ActionTypes.SET_MERCHANTS_LIST,
    merchantsList: payload,
  };
};

export const setLoadingDownload = (payload: boolean): IAppAction => ({
  type: ActionTypes.SET_LOADING_DOWNLOAD,
  loadingDownload: payload,
});

export const setLoadingReceipt = (payload: boolean): IAppAction => ({
  type: ActionTypes.SET_LOADING_RECEIPT,
  loadingReceipt: payload,
});

export const setLoadingDownloadById = (payload: boolean): IAppAction => ({
  type: ActionTypes.SET_LOADING_DOWNLOAD_BY_ID,
  loadingDownloadById: payload,
});

export const search = (
  body: TransactionsRequest,
  retryUrl?: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setLoading(true));
    const end_point: string = body.userType!;
    const method: string = `/analytics/v1/admin/${end_point}/search`;

    if (body.userType !== "admin") delete body.publicMerchantId;
    delete body.userType;

    const url: string = retryUrl
      ? retryUrl
      : `${environment.kushkiUrl}${method}`;
    axios
      .post(url, {
        ...body,
        text: body.text,
        type: "payout",
      })
      .then((axios_response: AxiosResponse<TransactionData>) => {
        const response: TransactionData = axios_response.data;
        dispatch(setTransactions({ ...response }));
      })
      .catch((e: AxiosError<ErrorResponse>) => {
        if (e.response?.data.code === ErrorEnum.E015)
          dispatch(retryCallToSearch(body, get(e, "response.config.url")));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const searchMerchantsList = (payload: {
  offset: number;
  text?: string;
  merchant_id?: string;
}): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    if (payload.text === "") delete payload.text;
    const body: {
      limit: number;
      offset: number;
      text?: string;
      merchant_id?: string;
    } = {
      ...payload,
      limit: 15,
    };
    axios
      .post<MerchantsData>(
        `${environment.kushkiUrl}/analytics/v1/admin/merchant/get`,
        body
      )
      .then((axios_response: AxiosResponse<MerchantsData>) => {
        const response: MerchantsData = filterMerchants(axios_response.data);
        dispatch(setMerchantsList(response));
      })
      .catch(() => {
        dispatch(
          setNotification({
            message: "Hubo un error en la consulta de comercios",
            open: true,
            type: "error",
          })
        );
      });
  };
};

export const setReceipt = (payload: Buffer): IAppAction => {
  return {
    type: ActionTypes.SET_RECEIPT,
    receipt: payload,
  };
};

export const setNotification = (notify: INotification): IAppAction => {
  return {
    type: ActionTypes.SET_NOTIFICATION,
    notification: notify,
  };
};

export const getPaymentReceipt = (
  transactionReference: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setLoadingReceipt(true));
    const path: string = `${environment.kushkiUrl}/analytics/v1/receipt/${transactionReference}`;
    axios
      .get<string>(path)
      .then((response: AxiosResponse<string>) => {
        const buffer = new Buffer(response.data);

        dispatch(setReceipt(buffer));
        dispatch(
          setNotification({
            type: "success",
            message: "Descarga de recibo completa",
            open: true,
          })
        );
      })
      .catch(() => {
        dispatch(
          setNotification({
            type: "error",
            message: "No se puede generar el archivo",
            open: true,
          })
        );
      })
      .finally(() => {
        dispatch(setLoadingReceipt(false));
      });
  };
};

export const retryCallToSearch = (
  body: TransactionsRequest,
  retryUrl: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void =>
    dispatch(search(body, retryUrl));
};

export const downloadTransactions = (
  filters: TransactionsRequest,
  downloadById: boolean
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return async (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>
  ): Promise<void> => {
    downloadById
      ? dispatch(setLoadingDownloadById(true))
      : dispatch(setLoadingDownload(true));
    try {
      const action = downloadById
        ? WebsocketActionsEnum.DOWNLOAD_TRANSACTIONS_BY_ID
        : WebsocketActionsEnum.DOWNLOAD_TRANSACTIONS_ALL;
      const websocket = new WebSocket(environment.kushkiWsUrl);
      websocket.onopen = () => {
        websocket.send(JSON.stringify({ action, data: filters }));
      };

      websocket.onerror = (_event) => {
        dispatch(setLoadingDownloadById(false));
        dispatch(setLoadingDownload(false));
        dispatch(
          setNotification({
            message: "Hubo un error en la descarga del archivo",
            open: true,
            type: "error",
          })
        );
      };

      websocket.onmessage = (event: MessageEvent) => {
        const response = JSON.parse(event.data);
        switch (response.status) {
          case WebsocketStatusEnum.COMPLETED_SUCCESS:
            const fileUrl = get(response, "payload.url", "");

            if (!isEmpty(fileUrl)) {
              const downloadLink: HTMLAnchorElement =
                document.createElement("a");
              downloadLink.href = fileUrl;
              downloadLink.click();
              window.URL.revokeObjectURL(fileUrl);
              dispatch(
                setNotification({
                  message: "Descarga de archivo completa",
                  open: true,
                  type: "success",
                })
              );
            } else {
              dispatch(
                setNotification({
                  message: get(
                    response.payload,
                    "message",
                    "Hubo un error en la descarga del archivo, inténtelo de nuevo más tarde"
                  ),
                  open: true,
                  type: "error",
                })
              );
            }
            websocket.close();
            dispatch(setLoadingDownloadById(false));
            dispatch(setLoadingDownload(false));
            break;
          case WebsocketStatusEnum.COMPLETED_EMPTY:
            dispatch(
              setNotification({
                message: "No existen resultados de transacciones",
                open: true,
                type: "success",
              })
            );
            websocket.close();
            dispatch(setLoadingDownloadById(false));
            dispatch(setLoadingDownload(false));
            break;
          case WebsocketStatusEnum.ERROR:
            dispatch(
              setNotification({
                message:
                  "Hubo un error en la descarga del archivo, inténtelo de nuevo",
                open: true,
                type: "error",
              })
            );
            websocket.close();
            dispatch(setLoadingDownloadById(false));
            dispatch(setLoadingDownload(false));
            break;
        }
      };
    } catch (error) {
      dispatch(
        setNotification({
          message:
            "Hubo un error en la descarga del archivo, inténtelo de nuevo más tarde",
          open: true,
          type: "error",
        })
      );
      dispatch(setLoadingDownloadById(false));
      dispatch(setLoadingDownload(false));
    }
  };
};
