import React from "react";
import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import { RuleComponentStyles } from "../RuleComponent/RuleComponent.styles";
import { LabelEnum } from "../../shared/enums/LabelEnum";
import {
  getBasicMerchantInformation,
  getMerchantInfo,
} from "../../shared/utils/localStorage";
import { getCountryFlag } from "../../shared/constants/flags/countryFlag";
import { defaultTo, isEmpty } from "lodash";
import { clipboard } from "../../shared/utils/clipboard";
import { Copy } from "react-feather";
import { merchantBasicInfoStyles as styles } from "./MerchantBasicInfoComponent.styles";
import { COUNTRY_TRANSLATOR } from "../../shared/enums/CountryEnum";

export const MerchantBasicInfoComponent = () => {
  const classes = RuleComponentStyles();
  const merchantInformation = getBasicMerchantInformation();
  const merchantId: string = ((id: string) => {
    return isEmpty(id) ? getMerchantInfo().publicMerchantId : id;
  })(merchantInformation.publicMerchantId);
  const country = defaultTo(
    merchantInformation.constitutionalCountry,
    merchantInformation.country
  );

  return (
    <React.Fragment>
      <Grid item xs={12} className={classes.sectionOne}>
        <Typography variant="h3" color="text.primary">
          {merchantInformation.name}
        </Typography>
      </Grid>
      <Grid item container xs={6} className={classes.sectionTwo}>
        <Grid item xs={2.5} alignSelf={"center"}>
          <Typography variant="caption" color="text.primary">
            {LabelEnum.OPERATION_COUNTRY}
          </Typography>
        </Grid>
        <Grid item container xs={3} alignSelf={"center"}>
          <Grid item xs={4}>
            <Box>
              {
                getCountryFlag[
                  defaultTo(
                    merchantInformation.constitutionalCountry,
                    merchantInformation.country
                  )
                ]
              }
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" color="text.primary">
              {defaultTo(COUNTRY_TRANSLATOR[country], country)}
            </Typography>
          </Grid>
        </Grid>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Grid item container xs={6} alignSelf={"center"}>
          <Grid
            item
            xs={3}
            alignSelf={"center"}
            className={classes.paddingLeft24}
          >
            <Typography variant="caption" color="text.primary">
              {LabelEnum.MERCHANT_ID}
            </Typography>
          </Grid>
          <Grid item xs={8} alignSelf={"center"}>
            <Typography variant="inherit" color="text.primary">
              {merchantId}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              sx={styles.iconEffect}
              data-testid={"IconCopy"}
              onClick={(e) => {
                clipboard(merchantId);
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Copy />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
