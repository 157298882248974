import React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { SecurityWrapperEnum } from "../../shared/infrastructure/constants/SecurityWrapperEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

export interface ConfirmMessageProps {
  open?: boolean;
  handleOnClose?: () => void;
  title?: string;
  message?: string;
  handleSecondaryButton?: (kind: string) => void;
  handlePrimaryButton?: () => void;
  textPrimaryButton?: string;
  textSecondaryButton?: string;
  colorPrimaryButton?: string;
  loading: boolean;
}

export const ConfirmMessage: React.FC<ConfirmMessageProps> = (
  props: ConfirmMessageProps
) => {
  return (
    <React.Fragment>
      <Dialog open={props.open!} onClose={props.handleOnClose}>
        <DialogTitle>
          <Typography variant={"h6"} color={"primary"}>
            {props.title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>{props.message}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {props.handleSecondaryButton && props.textSecondaryButton ? (
            <Button
              onClick={() => {
                props.handleSecondaryButton!("delete");
              }}
            >
              {props.textSecondaryButton}
            </Button>
          ) : null}
          <ComponentSecurityWrapper componentId={SecurityWrapperEnum.DELETE}>
            <Button
              disabled={props.loading}
              onClick={props.handlePrimaryButton}
              style={{ color: props.colorPrimaryButton }}
            >
              {props.textPrimaryButton}
            </Button>
          </ComponentSecurityWrapper>
          {props.loading && (
            <CircularProgress
              size={24}
              style={{ color: props.colorPrimaryButton }}
            />
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
