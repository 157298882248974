/* istanbul ignore file */
import cardImage from "../../../assets/images/card.svg";
import transferImage from "../../../assets/images/transfer.svg";

import {
  IOptionImageSelection,
  IOptionSelection,
  RuleSchemaEnum,
} from "./RulesConstants";
import { CountryEnum } from "../enums/CountryEnum";
import { PaymentMethodEnum } from "../enums/PaymentMethodEnum";
import { BANKS_ECUADOR, CURRENCY_ECUADOR } from "./CatalogEC";
import { BANKS_PERU, CURRENCY_PERU } from "./CatalogPE";
import { BANKS_MEXICO, CURRENCY_MEXICO } from "./CatalogMX";
import { BANKS_COLOMBIA, CURRENCY_COLOMBIA } from "./CatalogCO";
import { BANKS_CHILE, CURRENCY_CHILE } from "./CatalogCL";
import { BANKS_BRAZIL, CURRENCY_BRAZIL } from "./CatalogBR";
import { union } from "lodash";
import { BANKS_NICARAGUA, CURRENCY_NICARAGUA } from "./CatalogNI";
import { BANKS_GUATEMALA, CURRENCY_GUATEMALA } from "./CatalogGT";
import { BANKS_HONDURAS, CURRENCY_HONDURAS } from "./CatalogHN";
import { BANKS_COSTA_RICA, CURRENCY_COSTA_RICA } from "./CatalogCR";
import { BANKS_PANAMA, CURRENCY_PANAMA } from "./CatalogPA";
import { BANKS_SALVADOR, CURRENCY_SALVADOR } from "./CatalogSV";
import { GeneralActionsEnum } from "../enums/GeneralActionsEnum";
import { LabelEnum, NameLabelEnum } from "../enums/LabelEnum";

export enum SecurityRulesTitleSection {
  SECURITY_RULES = "Reglas de seguridad",
  GENERAL_INFORMATION = "Datos Generales",
  CONFIGURATION_INFORMATION = "Configuración de la regla",
}

export enum SecurityRulesDescriptionSection {
  GENERAL_INFORMATION = "Configura la información básica de la regla a crear.",
  CONFIGURATION_INFORMATION = "Agrega la acción que cumplirá la regla de seguridad y las condiciones que se le aplicarán.",
}

export enum SecurityRulesSubtitleSection {
  ALIAS_HEADER = "Genera un alias que te permita identificar la regla",
  TYPE_HEADER = "Selecciona el tipo de regla",
  COUNTRY_HEADER = "Seleccionar países",
  MERCHANT_HEADER = "Buscar comercio",
  FILTER_HEADER = "Buscar comercio o ID",
  CREDENTIAL_HEADER = "Buscar credencial",
  SERVICE_HEADER = "¿Qué medio de pago afectará esta regla?",
  GENERAL_ACTION_HEADER = "Acción General",
  GENERAL_ACTION_SUBTITLE = "Selecciona la acción principal que se cumplirá la regla de seguridad.",
  STRICT_CONDITION_HEADER = "Condiciones",
  STRICT_CONDITION_SUBTITLE = "Indica las condiciones que deben cumplirse para realizar la acción indicada en la sección anterior.",
  STRICT_CONDITION_SUBTITLE2 = "Agrega tantas condiciones como sean necesarias.",
  SPEED_CONDITION_HEADER = "Condiciones de velocidad",
  SPEED_CONDITION_SUBTITLE = "Agrega acciones secundarias a tu regla.",
  MERCHANT_INFORMATION = "Datos del comercio",
  RULE_INFORMATION = "Datos de la regla",
}

export enum AuthRulesAdditionalInfo {
  ADDITIONAL_INFO = "Configuración adicional",
  ADDITIONAL_INFO_DESCRIPTION = "Activa los parámetros que deben cumplirse para este servicio de autenticación. Recuerda que puedes activar más de un parámetro.",
  ASSUME_TRANSACTIONAL_RISK = "Asumir riesgo transaccional",
  ASSUME_TRANSACTIONAL_RISK_DESCRIPTION = "Todas las transacciones no seguras se enviarán a procesar si este switch esta encendido.",
}

export enum SecurityRulesTitleButton {
  RETURN = "Regresar",
  SAVE = "Guardar Regla",
  SEND_REQUEST = "Enviar Solicitud",
}

export enum SecurityRulesTitleModal {
  CONFIRM_RETURN = "¿Estás seguro de regresar?",
  ACTION_WARNING = "Si ejecutas la acción, se perderán todos los cambios.",
  ACCEPT = "Aceptar",
  CANCEL = "Cancelar",
}

export enum SecurityRulesLabelField {
  ACTION = "Acción",
  VALIDATOR = "Validador externo",
  CODE = "Código",
  MESSAGE = "Mensaje",
  LABEL = "Etiqueta",
  OPERATOR = "Operador",
  VALUE = "Valor",
  PERIODICITY = "Periodicidad",
  TRANSACTION = "Transacción",
  SCHEDULE_FROM = "Desde 00:00",
  SCHEDULE_UNTIL = "Hasta 00:00",
}

export const SERVICE_VALUES: IOptionImageSelection[] = [
  {
    name: "Tarjeta",
    value: "card",
    image: cardImage,
  },
  {
    name: "Transferencia",
    value: "transfer",
    image: transferImage,
  },
];

export const COUNTRY_VALUES: IOptionSelection[] = [
  {
    name: "Brasil",
    value: CountryEnum.BRAZIL,
  },
  {
    name: "Chile",
    value: CountryEnum.CHILE,
  },
  {
    name: "Colombia",
    value: CountryEnum.COLOMBIA,
  },
  {
    name: "Ecuador",
    value: CountryEnum.ECUADOR,
  },
  {
    name: "México",
    value: CountryEnum.MEXICO,
  },
  {
    name: "Perú",
    value: CountryEnum.PERU,
  },
  {
    name: "Guatemala",
    value: CountryEnum.GUATEMALA,
  },
  {
    name: "Panamá",
    value: CountryEnum.PANAMA,
  },
  {
    name: "Nicaragua",
    value: CountryEnum.NICARAGUA,
  },
  {
    name: "Honduras",
    value: CountryEnum.HONDURAS,
  },
  {
    name: "El Salvador",
    value: CountryEnum.SALVADOR,
  },
  {
    name: "Costa Rica",
    value: CountryEnum.COSTA_RICA,
  },
];

export const ACTION_VALUES: IOptionSelection[] = [
  {
    name: "Bloquear",
    value: "block",
  },
  {
    name: "Autenticar (OTP)",
    value: "otp",
  },
  {
    name: "Alertar",
    value: "warning",
  },
  {
    name: "Autenticar (OTP) Diners",
    value: "protectedByDiners",
  },
  {
    name: "3DS",
    value: "3dsecure",
  },
];

export const PARTNER_VALIDATOR_VALUES: IOptionSelection[] = [
  {
    name: "Sin validador",
    value: "",
  },
  {
    name: "Transunion",
    value: "transunion",
  },
  {
    name: "Experian",
    value: "experian",
  },
  {
    name: "Sift Science",
    value: "siftscience",
  },
];

export const STRICT_CONDITION_CARD_VALUES: IOptionSelection[] = [
  {
    name: "Banco",
    value: "transactionBank",
  },
  {
    name: "Marca",
    value: "transactionBrand",
  },
  {
    name: "Diferido",
    value: "transactionIsDeferred",
  },
  {
    name: "País",
    value: "transactionCountry",
  },
  {
    name: "Monto",
    value: "transactionTotalAmount",
  },
  {
    name: "IP",
    value: "transactionIP",
  },
  {
    name: "Bin",
    value: "transactionBIN",
  },
  {
    name: "Tipo de tarjeta",
    value: "transactionIsCreditCard",
  },
  {
    name: "Tarjeta enmascarada",
    value: "transactionMaskedCreditCard",
  },
  {
    name: "Emision de la tarjeta",
    value: "transactionCardIssuer",
  },
  {
    name: "Moneda",
    value: "transactionCurrency",
  },
  {
    name: "Credencial",
    value: "transactionCredential",
  },
  {
    name: "Tipo de transacción",
    value: RuleSchemaEnum.transactionType,
  },
  {
    name: "Horario",
    value: RuleSchemaEnum.transactionCreated,
  },
  {
    name: "Kushki ID",
    value: RuleSchemaEnum.transactionCardId,
  },
];

export const STRICT_CONDITION_CARD_VALUES_ACTION_BLOCK: IOptionSelection[] = [
  {
    name: NameLabelEnum.BANK,
    value: LabelEnum.BANK,
  },
  {
    name: NameLabelEnum.BRAND,
    value: LabelEnum.BRAND,
  },
  {
    name: NameLabelEnum.DEFERRED,
    value: LabelEnum.DEFERRED,
  },
  {
    name: NameLabelEnum.COUNTRY,
    value: LabelEnum.COUNTRY,
  },
  {
    name: NameLabelEnum.AMOUNT,
    value: LabelEnum.AMOUNT,
  },
  {
    name: NameLabelEnum.IP,
    value: LabelEnum.IP,
  },
  {
    name: NameLabelEnum.BIN,
    value: LabelEnum.BIN,
  },
  {
    name: NameLabelEnum.CARD_TYPE,
    value: LabelEnum.CARD_TYPE,
  },
  {
    name: NameLabelEnum.MASKED_CARD,
    value: LabelEnum.MASKED_CARD,
  },
  {
    name: NameLabelEnum.CARD_ISSUER,
    value: LabelEnum.CARD_ISSUER,
  },
  {
    name: NameLabelEnum.CURRENCY,
    value: LabelEnum.CURRENCY,
  },
  {
    name: NameLabelEnum.CREDENTIAL,
    value: LabelEnum.CREDENTIAL,
  },
  {
    name: NameLabelEnum.TRANSACTION_TYPE,
    value: LabelEnum.TRANSACTION_TYPE,
  },
  {
    name: NameLabelEnum.SCHEDULE,
    value: LabelEnum.SCHEDULE,
  },
  {
    name: NameLabelEnum.PHONE_NUMBER,
    value: LabelEnum.PHONE_NUMBER,
  },
  {
    name: NameLabelEnum.EMAIL,
    value: LabelEnum.EMAIL,
  },
  {
    name: NameLabelEnum.CARD_HOLDER_NAME,
    value: LabelEnum.CARD_HOLDER_NAME,
  },
  {
    name: NameLabelEnum.CARD_ID,
    value: LabelEnum.CARD_ID,
  },
];

export const STRICT_CONDITION_CARD_COUNTRY_VALUES: IOptionSelection[] = [
  {
    name: "Banco",
    value: "transactionBank",
  },
  {
    name: "Marca",
    value: "transactionBrand",
  },
  {
    name: "Diferido",
    value: "transactionIsDeferred",
  },
  {
    name: "País",
    value: "transactionCountry",
  },
  {
    name: "Monto",
    value: "transactionTotalAmount",
  },
  {
    name: "IP",
    value: "transactionIP",
  },
  {
    name: "Bin",
    value: "transactionBIN",
  },
  {
    name: "Tipo de tarjeta",
    value: "transactionIsCreditCard",
  },
  {
    name: "Tarjeta enmascarada",
    value: "transactionMaskedCreditCard",
  },
  {
    name: "Emision de la tarjeta",
    value: "transactionCardIssuer",
  },
  {
    name: "Moneda",
    value: "transactionCurrency",
  },
  {
    name: "Kushki ID",
    value: "transactionCardId",
  },
];

export const STRICT_CONDITION_TRANSFER_VALUES: IOptionSelection[] = [
  {
    name: "Monto",
    value: "transactionTotalAmount",
  },
];

export const SPEED_ACTION_VALUES: IOptionSelection[] = [
  {
    name: "Contar",
    value: "count",
  },
  {
    name: "Sumar",
    value: "sum",
  },
];

export const SPEED_TRANSACTION_STATUS_VALUES: IOptionSelection[] = [
  {
    name: "Aprobadas",
    value: "APPROVAL",
  },
  {
    name: "Declinadas",
    value: "DECLINED",
  },
  {
    name: "Todas",
    value: "ALL",
  },
];

export const SPEED_PERIODICITY_VALUES: IOptionSelection[] = [
  { name: "Por minuto", value: "minute" },
  { name: "Por hora", value: "hour" },
  { name: "Diaria", value: "day" },
  { name: "Semanal", value: "week" },
  { name: "Quincenal", value: "fortnight" },
  { name: "Mensual", value: "month" },
  { name: "Trimestral", value: "quarterly" },
  { name: "Semestral", value: "biannual" },
];

export const YES_NO_VALUES: IOptionSelection[] = [
  { name: "Si", value: "true" },
  { name: "No", value: "false" },
];

export const CARD_TYPE_VALUES: IOptionSelection[] = [
  { name: "Crédito", value: "true" },
  { name: "Débito", value: "false" },
];

export const CARD_ISSUER_VALUES: IOptionSelection[] = [
  { name: "Nacional", value: "national" },
  { name: "Internacional", value: "international" },
];

export const OPERATOR_VALUES_1: IOptionSelection[] = [
  {
    name: "Igual",
    value: "=",
  },
  {
    name: "Diferente",
    value: "!=",
  },
  {
    name: "Contiene",
    value: "contain",
  },
  {
    name: "No Contiene",
    value: "!contain",
  },
];

export const OPERATOR_VALUES_2: IOptionSelection[] = [
  {
    name: "Igual",
    value: "=",
  },
  {
    name: "Diferente",
    value: "!=",
  },
];

export const OPERATOR_VALUES_3: IOptionSelection[] = [
  {
    name: "Contiene",
    value: "contain",
  },
  {
    name: "No Contiene",
    value: "!contain",
  },
];

export const OPERATOR_VALUES_4: IOptionSelection[] = [
  {
    name: "Igual",
    value: "=",
  },
];

export const OPERATOR_VALUES_CUSTOM_CREDENTIAL: IOptionSelection[] = [
  ...OPERATOR_VALUES_3,
  ...OPERATOR_VALUES_4,
];

export const OPERATOR_VALUES_5: IOptionSelection[] = [
  {
    name: "Mayor que",
    value: ">",
  },
  {
    name: "Menor que",
    value: "<",
  },
];

export const OPERATOR_VALUES_6: IOptionSelection[] = [
  {
    name: "Contiene",
    value: "contain",
  },
  {
    name: "No Contiene",
    value: "!contain",
  },
  {
    name: "Todos",
    value: "=|all",
  },
];

export const OPERATOR_VALUES_7: IOptionSelection[] = [
  {
    name: "Igual",
    value: "=",
  },
  {
    name: "Contiene",
    value: "contain",
  },
  {
    name: "No Contiene",
    value: "!contain",
  },
  {
    name: "Todos",
    value: "=|all",
  },
];

export const OPERATOR_VALUES_8: IOptionSelection[] = [
  {
    name: "Igual",
    value: "=",
  },
  {
    name: "Diferente",
    value: "!=",
  },
  {
    name: "Contiene",
    value: "contain",
  },
  {
    name: "No Contiene",
    value: "!contain",
  },
  {
    name: "Todos",
    value: "=|all",
  },
];

export const MAP_OPERATORS_VALUES: IOptionSelection[] = [
  ...OPERATOR_VALUES_1,
  ...OPERATOR_VALUES_2,
  ...OPERATOR_VALUES_3,
  ...OPERATOR_VALUES_4,
  ...OPERATOR_VALUES_5,
  ...OPERATOR_VALUES_6,
];

export const MAP_TYPE_SECURITY_RULE: IOptionSelection[] = [
  {
    name: "Comercio",
    value: "commerce",
  },
  {
    name: "Países",
    value: "country",
  },
];

export const getOperatorValues = (
  value: string,
  generalAction: string
): IOptionSelection[] => {
  switch (value) {
    case "transactionBank":
    case "transactionBrand":
    case "transactionProcessor":
      return OPERATOR_VALUES_1;
    case "transactionType":
      return generalAction === GeneralActionsEnum.OTP
        ? OPERATOR_VALUES_4
        : OPERATOR_VALUES_1;
    case "transactionIsCreditCard":
    case "transactionIsDeferred":
    case "transactionCardIssuer":
    case "transactionCurrency":
      return OPERATOR_VALUES_4;
    case "transactionCredential":
      return OPERATOR_VALUES_CUSTOM_CREDENTIAL;
    case "transactionCountry":
    case "transactionBIN":
      return OPERATOR_VALUES_3;
    case "transactionIP":
      return OPERATOR_VALUES_7;
    case "transactionPhoneNumber":
      return OPERATOR_VALUES_7;
    case "transactionEmail":
      return OPERATOR_VALUES_7;
    case "transactionCardHolderName":
      return OPERATOR_VALUES_7;
    case "transactionMaskedCreditCard":
      return OPERATOR_VALUES_6;
    case "transactionTotalAmount":
      return OPERATOR_VALUES_5;
    case "transactionCardId":
      return OPERATOR_VALUES_8;
    default:
      return OPERATOR_VALUES_3;
  }
};

export const getLabelValues = (
  service: string,
  type: string,
  action?: string
): IOptionSelection[] => {
  if (type === "securityCountry" && service === PaymentMethodEnum.CARD)
    return STRICT_CONDITION_CARD_COUNTRY_VALUES;

  switch (service) {
    case PaymentMethodEnum.TRANSFER:
      return STRICT_CONDITION_TRANSFER_VALUES;
    default:
      switch (action) {
        case GENERAL_ACTIONS.BLOCK:
          return STRICT_CONDITION_CARD_VALUES_ACTION_BLOCK;
        default:
          return STRICT_CONDITION_CARD_VALUES;
      }
  }
};

export const getBanksByCountry = (countries: string[]): IOptionSelection[] => {
  let banks: IOptionSelection[] = [];

  if (countries.includes(CountryEnum.ECUADOR))
    banks = [...banks, ...BANKS_ECUADOR];
  if (countries.includes(CountryEnum.PERU)) banks = [...banks, ...BANKS_PERU];
  if (countries.includes(CountryEnum.MEXICO))
    banks = [...banks, ...BANKS_MEXICO];
  if (countries.includes(CountryEnum.COLOMBIA))
    banks = [...banks, ...BANKS_COLOMBIA];
  if (countries.includes(CountryEnum.CHILE)) banks = [...banks, ...BANKS_CHILE];
  if (countries.includes(CountryEnum.BRAZIL))
    banks = [...banks, ...BANKS_BRAZIL];
  if (countries.includes(CountryEnum.NICARAGUA))
    banks = [...banks, ...BANKS_NICARAGUA];
  if (countries.includes(CountryEnum.GUATEMALA))
    banks = [...banks, ...BANKS_GUATEMALA];
  if (countries.includes(CountryEnum.HONDURAS))
    banks = [...banks, ...BANKS_HONDURAS];
  if (countries.includes(CountryEnum.PANAMA))
    banks = [...banks, ...BANKS_PANAMA];
  if (countries.includes(CountryEnum.SALVADOR))
    banks = [...banks, ...BANKS_SALVADOR];
  if (countries.includes(CountryEnum.COSTA_RICA))
    banks = [...banks, ...BANKS_COSTA_RICA];
  return banks;
};

export const getCurrencyByCountry = (
  countries: string[]
): IOptionSelection[] => {
  let currencies: IOptionSelection[] = [];

  if (countries.includes(CountryEnum.ECUADOR))
    currencies = union(currencies, CURRENCY_ECUADOR);
  if (countries.includes(CountryEnum.PERU))
    currencies = union(currencies, CURRENCY_PERU);
  if (countries.includes(CountryEnum.MEXICO))
    currencies = union(currencies, CURRENCY_MEXICO);
  if (countries.includes(CountryEnum.COLOMBIA))
    currencies = union(currencies, CURRENCY_COLOMBIA);
  if (countries.includes(CountryEnum.CHILE))
    currencies = union(currencies, CURRENCY_CHILE);
  if (countries.includes(CountryEnum.BRAZIL))
    currencies = union(currencies, CURRENCY_BRAZIL);
  if (countries.includes(CountryEnum.NICARAGUA))
    currencies = union(currencies, CURRENCY_NICARAGUA);
  if (countries.includes(CountryEnum.GUATEMALA))
    currencies = union(currencies, CURRENCY_GUATEMALA);
  if (countries.includes(CountryEnum.HONDURAS))
    currencies = union(currencies, CURRENCY_HONDURAS);
  if (countries.includes(CountryEnum.COSTA_RICA))
    currencies = union(currencies, CURRENCY_COSTA_RICA);
  if (countries.includes(CountryEnum.PANAMA))
    currencies = union(currencies, CURRENCY_PANAMA);
  if (countries.includes(CountryEnum.SALVADOR))
    currencies = union(currencies, CURRENCY_SALVADOR);
  return currencies;
};

export const getNames = (list: IOptionSelection[]): string[] => {
  let values: string[] = [];

  list?.forEach((value) => values.push(value.name));

  return values;
};

export const ALL_COUNTRY_VALUES: string[] = [
  CountryEnum.BRAZIL,
  CountryEnum.CHILE,
  CountryEnum.COLOMBIA,
  CountryEnum.ECUADOR,
  CountryEnum.MEXICO,
  CountryEnum.PERU,
];

export const PARTNER_VALIDATOR_VALIDATION: string[] = ["block", "warning"];
export const CODE_MESSAGE_VALIDATION: string[] = [
  "block",
  "warning",
  "protectedByDiners",
];
export const SPEED_CONDITION_VALIDATION: string[] = [
  "block",
  "otp",
  "3dsecure",
];

export enum GENERAL_ACTIONS {
  BLOCK = "block",
  WARNING = "warning",
  PROTECTED_BY_DINERS = "protectedByDiners",
  OTP = "otp",
  "3DS" = "3dsecure",
}

export const AMEX_VARIANTS: string[] = [
  "American Express",
  "AmericanExpress",
  "Amex",
];

export const PAYMENT_BRANDS_OPTIONS: IOptionSelection[] = [
  { name: "Visa", value: "visa" },
  { name: "Mastercard", value: "mastercard" },
  { name: "American Express", value: AMEX_VARIANTS.join(",") },
  { name: "Diners", value: "diners" },
  { name: "Discover", value: "discover" },
  { name: "Redcompra", value: "redcompra" },
  { name: "Alia", value: "alia" },
  { name: "Credencial", value: "credencial" },
  { name: "Maestro", value: "maestro" },
  { name: "Propia", value: "propia" },
  { name: "Cmrfalabella", value: "cmrfalabella" },
  { name: "JCB", value: "jcb" },
  { name: "Serfinanza", value: "serfinanza" },
  { name: "Unionpay", value: "unionpay" },
];

export enum SecurityRulesCredentials {
  MASTER_CREDENTIAL = "master",
  CREDENTIAL_TITLE_MASTER = "Llave maestra",
}

export const PAYMENT_BRANDS_OPTIONS_GUATEMALA: IOptionSelection[] = [
  { name: "Visa", value: "visa" },
  { name: "Mastercard", value: "mastercard" },
  { name: "American Express", value: "amex" },
  { name: "Diners", value: "diners" },
  { name: "Discover", value: "discover" },
  { name: "JCB", value: "jcb" },
];

export const PAYMENT_BRANDS_OPTIONS_PANAMA: IOptionSelection[] = [
  { name: "Visa", value: "visa" },
  { name: "Mastercard", value: "mastercard" },
  { name: "American Express", value: "amex" },
];

export const getBrandsByCountry = (countries: string[]): IOptionSelection[] => {
  let brands: IOptionSelection[] = [];

  if (
    countries.includes(CountryEnum.ECUADOR) ||
    countries.includes(CountryEnum.PERU) ||
    countries.includes(CountryEnum.MEXICO) ||
    countries.includes(CountryEnum.COLOMBIA) ||
    countries.includes(CountryEnum.CHILE) ||
    countries.includes(CountryEnum.BRAZIL)
  )
    brands = union(brands, PAYMENT_BRANDS_OPTIONS);
  if (
    countries.includes(CountryEnum.NICARAGUA) ||
    countries.includes(CountryEnum.GUATEMALA) ||
    countries.includes(CountryEnum.HONDURAS) ||
    countries.includes(CountryEnum.SALVADOR) ||
    countries.includes(CountryEnum.COSTA_RICA)
  )
    brands = union(brands, PAYMENT_BRANDS_OPTIONS_GUATEMALA);
  if (countries.includes(CountryEnum.PANAMA))
    brands = union(brands, PAYMENT_BRANDS_OPTIONS_PANAMA);

  return brands;
};

export enum SecurityRulesRoles {
  M_FRAUD_PREVENTION = "M_MERCHANTS",
  MERCHANT_DELETE = "ReglasDeSeguridad.Comercio.Eliminar",
  MERCHANT_DETAIL_EDIT_DELETE = "ReglasDeSeguridad.ComercioDetalle.EditarEliminar",
  COUNTRY_DELETE = "ReglasDeSeguridad.Paises.Eliminar",
  COUNTRY_DETAIL_EDIT_DELETE = "ReglasDeSeguridad.PaisesDetalle.EditarEliminar",
  WHITELIST_ADD = "ReglasDeSeguridad.ListaBlanca.Agregar",
  WHITELIST_DELETE = "ReglasDeSeguridad.ListaBlanca.Eliminar",
  COUNTRY_MERCHANT_SAVE_RULE = "ReglasDeSeguridad.PaisesComercio.GuardarRegla",
}

export const ROLE_EDIT_BY_RULE_TYPE: Record<string, string> = {
  ["securityCountry"]: SecurityRulesRoles.COUNTRY_DETAIL_EDIT_DELETE,
  ["securityCommerce"]: SecurityRulesRoles.MERCHANT_DETAIL_EDIT_DELETE,
};

export enum TimeLineConstants {
  DISABLED_RULE = "Regla deshabilitada por",
  DATE_REQUEST = "Fecha de Solicitud:",
  REASON = "Motivo:",
  OK = "Entendido",
  VIEW_DETAIL = "Ver detalle",
}

export const RULE_INFORMATION_VALUES: IOptionSelection[] = [
  {
    name: "Medio de pago:",
    value: "service",
  },
  {
    name: "Autenticador:",
    value: "action",
  },
  {
    name: "Fecha de creación:",
    value: "createdAt",
  },
  {
    name: "Solicitado por:",
    value: "username",
  },
];

export const MERCHANT_INFORMATION_VALUES: IOptionSelection[] = [
  {
    name: "Nombre:",
    value: "name",
  },
  {
    name: "Razón social:",
    value: "socialReason",
  },
  {
    name: "ID de comercio:",
    value: "merchantId",
  },
  {
    name: "Tamaño del comercio:",
    value: "merchantSize",
  },
  {
    name: "País:",
    value: "country",
  },
];

export const getServiceValues = (value: string): string => {
  switch (value) {
    case PaymentMethodEnum.TRANSFER:
      return "Transferencia";
    default:
      return "Tarjeta";
  }
};

export const MAP_STATUS_SECURITY_RULE: IOptionSelection[] = [
  {
    name: "HABILITADA",
    value: "ENABLED",
  },
  {
    name: "DESHABILITADA",
    value: "DISABLED",
  },
];

export enum SecurityRuleActionValue {
  "3DS" = "3dsecure",
  OTP = "otp",
}

export enum SecurityRuleStatusValue {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export const getChipBGValue = (value: string, status: string): string => {
  switch (value) {
    case SecurityRuleActionValue["3DS"]:
    case SecurityRuleActionValue.OTP:
      if (status === SecurityRuleStatusValue.ENABLED) return "#E0FFF8";
      return "#E2E8F0";
    default:
      return "#F7FAFC";
  }
};

export const getChipValue = (value: string, status: string): string => {
  switch (value) {
    case SecurityRuleActionValue["3DS"]:
    case SecurityRuleActionValue.OTP:
      if (status === SecurityRuleStatusValue.ENABLED) return "#0DC298";
      return "#677684";
    default:
      return "#293036";
  }
};

export enum DisableRuleDialogConstants {
  TITLE = "Deshabilitar regla",
  DESCRIPTION = "Para realizar la acción escribe el motivo por el cual deseas deshabilitar la regla:",
  TEXT_FIELD = "Motivo...",
  ACCEPT_BUTTON_TEXT = "Aceptar",
  CANCEL_BUTTON_TEXT = "Cancelar",
}

export enum GeneralRuleDetailConstants {
  COUNTRY = "País",
  COMMERCE = "Comercio",
}

export enum CreateSecurityRule {
  ERROR_RULE = "No se logró crear la regla.",
  ERROR_APPROVAL_RULE = "No se logró envíar la solicitud para crear la regla.",
}

export enum UpdateSecurityRule {
  SUCCESS_STATUS = "Regla deshabilitada con éxito.",
  ERROR_STATUS = "Ha ocurrido un error al deshabilitar la regla. Inténtalo nuevamente.",
  ERROR_UPDATE = "No se logró actualizar la regla.",
}
