import { TransactionType } from "../../enums/transaction_types";

export enum FORM_FIELDS_NAMES {
  totalAmount = "totalAmount",
  description = "description",
  file = "file",
}

export const GET_TRANSACTION_TYPE: Record<string, string> = {
  [TransactionType.CREDIT]: "la recarga",
  [TransactionType.DEBIT]: "el débito",
};

export const UPLOAD_FILE_INPUT_TEXTS = {
  alertSubtitle: (transactionType: string) =>
    `No se podrá realizar ${GET_TRANSACTION_TYPE[transactionType]} porque el recibo de transacción es obligatorio.`,
  alertTitle: (transactionType: string) =>
    `No se puede realizar ${GET_TRANSACTION_TYPE[transactionType]}`,
  subTitle: ".png, .jpg, .pdf, si es más de 1 archivo puedes usar un .zip",
  title: "Recibo de transacción*",
  uploadFile: "Subir archivo",
};

export const RECHARGE_FORM_TEXTS = {
  amountErrorMessage: "El monto es un campo obligatorio",
  amountLabel: "Monto*",
  amountValueDecimalsLimit: "El monto no puede tener más de 2 decimales",
  amountValueErrorMessage: "El monto no puede ser menor a 1",
  description: "Descripción*",
  textAreaErrorMessage: "La descripción debe tener máximo 500 caracteres",
  textAreaRequiredMessage: "La descripción es un campo obligatorio",
  title: "Información básica",
  uploadFileErrorMessage: "El archivo es un campo obligatorio",
  uploadFileSizeErrorMessage:
    "El archivo seleccionado excede el tamaño máximo permitido de 1 MB",
  uploadFileTypeErrorMessage:
    "Solo se permiten archivos en formato .png, .jpg, .pdf y .zip",
};

export const ACCEPTED_FILE_FORMATS = ".jpg, .jpeg, .png, .zip, .pdf";

export const FORM_LABEL = {
  amountLabel: "Monto",
  amountMessage: "0",
  descriptionLabel: "Descripción",
  descriptionMessage: "Ingresar descripción",
};
