import { IUseCellActionOptionMenuState } from "./useCellActionOptionMenuState.interfaces";
import { getRetentionsFile } from "../../../../store/thunks/retention/retention.thunks";
import { get, has, set } from "lodash";
import { store } from "../../../../store/store";
import { KindDocumentEnum } from "../constantsCellOptionMenu";
import {
  setKindOption,
  setNotificationPDF,
  setPdfRetention,
} from "../../../../store/actions/downloadFile.actions";

export const useCellActionOptionMenuState =
  (): IUseCellActionOptionMenuState => {
    const onClick = async (e: object): Promise<void> => {
      store.dispatch(setNotificationPDF(false));
      set(e, "kind", KindDocumentEnum[get(e, "kind")]);
      store.dispatch(setKindOption(e));
      const dataPDF = await store.dispatch(
        getRetentionsFile({
          kindRetention: get(e, "kind"),
          transactionId: get(e, "id_registro"),
        })
      );

      if (has(dataPDF, "error")) {
        store.dispatch(setNotificationPDF(true));
        store.dispatch(setPdfRetention(""));
      } else {
        store.dispatch(setNotificationPDF(false));
        store.dispatch(setPdfRetention(dataPDF));
      }
    };

    const handleToggle = (prevOpen: boolean): boolean => {
      return !prevOpen;
    };

    return {
      handleToggle,
      onClick,
    };
  };
