import React from "react";
import { Container, makeStyles, Typography } from "@material-ui/core";
import Breadcrumb from "../../components/commons/Breadcrumb/Breadcrumb";
import { routes } from "../../shared/infrastructure/constants/routes";
import { CountriesEnum } from "../../shared/infrastructure/constants/CountriesEnum";
import { useCreateDeferredState } from "./state/useCreateDeferredState";
import SimpleDeferredTable from "../../components/SimpleDeferredTable/SimpleDeferredTable";
import DeferredStatusModal from "../../components/DeferredStatusModal/DeferredStatusModal";

const useStyles = makeStyles(() => ({
  h1: {
    fontSize: "40px",
    fontWeight: 200,
    lineHeight: "52px",
  },
}));

const CreateDeferred: React.FC = () => {
  const classes = useStyles();
  const {
    country,
    deferredStatus,
    dialogConfirm,
    handleClickSwitchStatus,
    name,
    merchantDeferred,
  } = useCreateDeferredState();

  return (
    <>
      <Container maxWidth="lg">
        <Breadcrumb
          items={[
            {
              label: "Comercios",
              url: routes.MERCHANT_LIST,
            },
            { label: name, url: "/" },
          ]}
          lastItem={"Crear diferido"}
        />
        <Typography className={classes.h1} color={"primary"}>
          Configuración de diferidos
        </Typography>
        {country === CountriesEnum.ECUADOR && <h1> tabla para ecuador </h1>}
        {country === CountriesEnum.PERU && (
          <SimpleDeferredTable
            merchantDeferred={merchantDeferred}
            handleClickSwitchStatus={handleClickSwitchStatus}
            deferredStatus={deferredStatus}
          />
        )}
      </Container>
      <DeferredStatusModal {...dialogConfirm} />
    </>
  );
};

export default CreateDeferred;
