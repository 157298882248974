import React from "react";
import { Box, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { IModalsSliderProps } from "./modalSlider.interfaces";
import { ModalSliderStyles as styles } from "./modalSlider.styles";

const ModalSlider: React.FC<IModalsSliderProps> = ({
  children,
  isOpen,
  closeModal,
}: IModalsSliderProps) => {
  return (
    <Modal open={isOpen}>
      <Box sx={styles.container}>
        <Box sx={styles.closeButtonWrapper}>
          <IconButton
            color="secondary"
            onClick={closeModal}
            size="small"
            sx={styles.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {children}
      </Box>
    </Modal>
  );
};

export default ModalSlider;
