import React, { ChangeEvent } from "react";
import { TextField } from "@material-ui/core";
import { get } from "lodash";
import { TransactionTable } from "../../../types/transaction_table";
export interface ModalBodyOmitProps {
  value: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  rowData?: TransactionTable | undefined;
}

export const ModalBodyOmit: React.FC<ModalBodyOmitProps> = (
  props: ModalBodyOmitProps
) => {
  const { value, handleChange } = props;
  return (
    <TextField
      autoComplete="off"
      variant="outlined"
      placeholder={
        get(props.rowData, "transaction.kind", "") === "charge"
          ? "Detalle del cobro"
          : "Indica el motivo de la omisión"
      }
      fullWidth
      value={value}
      id="alias"
      onChange={handleChange}
    />
  );
};
