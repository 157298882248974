import { KindRuleEnum } from "./kindRuleEnum";

export enum ActionRuleEnum {
  "3dsecure" = "3dsecure",
  "otp" = "otp",
}
export const ActionRuleParse = {
  [KindRuleEnum["3DS"]]: ActionRuleEnum["3dsecure"],
  [KindRuleEnum.OTP]: ActionRuleEnum.otp,
};
