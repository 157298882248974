import {createNamedStyles} from "../../../shared/interfaces/create_named_styles";

export const paymentProcessorComponentStyles = createNamedStyles({
  radioSkeleton: {
    marginLeft:"20px",
    width: 100,
    height: 60,
  },
  titleChip:{
    marginRight: "5px",
    marginBottom:"2px",
    marginLeft:"20px"
  },
  chipContainer: {
    marginTop: "1p",
  },
  chip: {
    marginRight: "5px",
    marginLeft:"5px"
  },
});
