import { IStyles } from "../../shared/infrastructure/interfaces/Styles.interfaces";
import theme from "../../theme";

export const conciliationTabsStyles: IStyles = {
  main: {
    marginBottom: "32px",
  },
  emptyRecords: {
    textAlign: "center",
    padding: "50px",
  },
  title: {
    typography: "h1",
    fontSize: "40px",
    fontWeight: "300",
    lineHeight: "120%",
    letterSpacing: "-0.015em",
    color: "primary.main",
    fontStyle: "normal",
  },
  box: {
    borderBottom: 1,
    borderColor: "divider",
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "primary.main",
      height: "2px",
    },
    "& .MuiTabs-indicatorSpan": {
      backgroundColor: "primary.main",
    },
  },
  dotIconNotRefundable: {
    color: "warning.main",
    height: "auto",
    width: "15px",
  },
  dotIconReview: {
    color: "neutral.components.disabled",
    height: "auto",
    width: "15px",
  },
  multipleRefundText: {
    fontSize: "15px",
    color: "text.primary",
    fontFamily: "IBM Plex Sans,sans-serif",
  },
  notRefundableTransaction: {
    fontSize: "13px",
    fontFamily: "IBM Plex Sans,sans-serif",
  },
  tab: {
    textTransform: "none",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "13px",
    lineHeight: "15.6px",
    letterSpacing: "0.01em",
    fontFamily: "",
    color: "neutral.grey8",
    "&.Mui-selected": {
      color: "neutral.grey8",
    },
  },
  refundButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "4px",
    border: 0,
    textTransform: "none",
    "&:disabled": {
      color: theme.palette.background.default,
      backgroundColor: theme.palette.primary.main,
      opacity: 0.5,
    },
    "&:hover, &:focus, &:active": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default,
      border: 0,
    },
  },
  buttonsText: {
    fontSize: "16px",
  },
  skipButton: {
    borderColor: "neutral.500",
    borderRadius: "4px",
  },
};
