import React from "react";
import { Box, IconButton } from "@mui/material";
import TrashIcon from "../../assets/icons/TrashIcon";
import KeyIcon from "../../assets/icons/KeyIcon";
import EditIcon from "../../assets/icons/EditIcon";
import { userActionsStyles as styles } from "./UserActions.styles";
import { IUserActionsProps } from "./UserActions.interfaces";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { SecurityWrapperRoles } from "../../shared/enum/SecurityWrapperRoles";
import { useUserActions } from "./state/useUserActions";
import { ActionsUserTypeEnum } from "../../shared/enum/UsersTableEnum";
import { Link } from "react-router-dom";
import { setUrl } from "../../shared/utils/urlPathUtils";
import { get } from "lodash";

const UserActions: React.FC<IUserActionsProps> = ({
  user,
}: IUserActionsProps) => {
  const {
    handleModalActions,
    handleEditAction,
    isResetPasswordDisabled,
    merchantName,
    hideSideBar,
    isUserEnabled,
  } = useUserActions(user);

  return (
    <Box sx={styles.container}>
      <ComponentSecurityWrapper
        componentId={SecurityWrapperRoles.USERS_RESET_PASSWORD}
      >
        <IconButton
          data-testid={"reset_password"}
          sx={isResetPasswordDisabled(user) ? styles.disabled : styles.icon}
          onClick={() => handleModalActions(ActionsUserTypeEnum.PASSWORD, user)}
          disabled={isResetPasswordDisabled(user)}
        >
          <KeyIcon />
        </IconButton>
      </ComponentSecurityWrapper>
      <ComponentSecurityWrapper componentId={SecurityWrapperRoles.USERS_EDIT}>
        <IconButton
          disabled={!isUserEnabled}
          sx={!isUserEnabled ? styles.disabled : styles.icon}
          data-testid={"editButton"}
        >
          <Link
            to={setUrl(
              user.userName,
              merchantName,
              hideSideBar,
              get(user, "publicMerchantId", "")
            )}
            onClick={() => {
              handleEditAction(user);
            }}
          >
            <EditIcon />
          </Link>
        </IconButton>
      </ComponentSecurityWrapper>
      <ComponentSecurityWrapper componentId={SecurityWrapperRoles.USERS_DELETE}>
        <IconButton
          data-testid={"delete"}
          sx={!isUserEnabled ? styles.disabled : styles.icon}
          disabled={!isUserEnabled}
          onClick={() => handleModalActions(ActionsUserTypeEnum.DELETE, user)}
        >
          <TrashIcon color={"#023365"} />
        </IconButton>
      </ComponentSecurityWrapper>
    </Box>
  );
};

export default UserActions;
