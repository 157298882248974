import React, { PropsWithChildren } from "react";
import { ModalSimple } from "@kushki/connect-ui";
import { ProcessorConstants } from "../../shared/constants/ProcessorConstants";
import { ISimpleModalProps } from "./ISimpleModalProps.interfaces";
import {
  ButtonColorEnum,
  ButtonVariantEnum,
} from "../../shared/enums/ButtonStylesEnum";

export const SimpleModal: React.FC<ISimpleModalProps> = (
  props: PropsWithChildren<ISimpleModalProps>
) => {
  return (
    <ModalSimple
      onClickPrimary={
        props.isDefaultProcessor
          ? props.handleOnCloseAlert
          : props.handleOnAcceptModalDeleteProcessor
      }
      onClickSecondary={props.handleOnCloseAlert}
      buttonPrimary={
        props.isDefaultProcessor
          ? ProcessorConstants.ACCEPT
          : ProcessorConstants.DELETE_PROCESSOR
      }
      descriptionText={
        props.isDefaultProcessor
          ? ProcessorConstants.MESSAGE_DELETE_DEFAULT_PROCESSOR
          : ProcessorConstants.MESSAGE_DELETE_NOT_DEFAULT_PROCESSOR
      }
      isOpen={props.isOpenDeleteProcessorAlert}
      onClose={props.handleOnCloseAlert}
      titleText={ProcessorConstants.TITLE_DELETE_PROCESSOR}
      typeModal={1}
      buttonSecondary={
        props.isDefaultProcessor
          ? ProcessorConstants.EMPTY
          : ProcessorConstants.CANCEL
      }
      buttonPrimaryVariant={
        props.isDefaultProcessor
          ? ButtonVariantEnum.Contained
          : ButtonVariantEnum.Outlined
      }
      buttonPrimaryColor={
        props.isDefaultProcessor
          ? ButtonColorEnum.Primary
          : ButtonColorEnum.Error
      }
    />
  );
};
