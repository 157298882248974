import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppState } from "../../interfaces/AppState.interfaces";
import { downloadHistoricRefunds } from "../../thunks/downloadHistoricRefunds/download_historic_refunds";
import { LoadingTextEnum } from "../../../shared/enums/loading_text_enum";
import { AlertColor } from "@mui/material";
import { MessageAlertEnum } from "../../../shared/enums/message_alert_enum";

export const initialState: IAppState = {
  loading: false,
  loadingText: "",
  messageAlert: MessageAlertEnum.DEFAULT,
  showAlert: false,
  typeAlert: "success",
  valueTab: "1",
};

export const appSlice = createSlice({
  extraReducers: {
    [downloadHistoricRefunds.pending.toString()]: (state) => {
      state.loading = true;
      state.loadingText = LoadingTextEnum.DOWNLOAD;
    },
    [downloadHistoricRefunds.fulfilled.toString()]: (
      state,
      { payload }: { payload: boolean }
    ) => {
      state.loading = false;
      state.typeAlert = payload ? "success" : "error";
      state.messageAlert = payload
        ? MessageAlertEnum.DOWNLOAD_SUCCESS
        : MessageAlertEnum.DOWNLOAD_ERROR;
      state.showAlert = true;
      state.loadingText = LoadingTextEnum.DEFAULT;
    },
    [downloadHistoricRefunds.rejected.toString()]: (state) => {
      state.loading = false;
      state.typeAlert = "error";
      state.messageAlert = MessageAlertEnum.DOWNLOAD_ERROR;
      state.showAlert = true;
      state.loadingText = LoadingTextEnum.DEFAULT;
    },
  },
  initialState,
  name: "app",
  reducers: {
    hideAlert: (state) => {
      state.showAlert = false;
    },
    hideLoading: (state) => {
      state.loading = false;
    },
    setLoadingText: (state, action: PayloadAction<string>) => {
      state.loadingText = action.payload;
    },
    setMessageAlert: (state, action: PayloadAction<string>) => {
      state.messageAlert = action.payload;
    },
    setTypeAlert: (state, action: PayloadAction<AlertColor>) => {
      state.typeAlert = action.payload;
    },
    setValueTab: (state, action: PayloadAction<string>) => {
      state.valueTab = action.payload;
    },
    showAlert: (state) => {
      state.showAlert = true;
    },
    showLoading: (state) => {
      state.loading = true;
    },
  },
});

export default appSlice.reducer;
