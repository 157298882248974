import { defaultTo, get, isEmpty, set } from "lodash";
import { useEffect, useState } from "react";
import { MerchantData } from "../../../../types/merchant-data";
import { ConfigIdEnum } from "../../../shared/constants/initial_state_legal_details";
import { TabItemStatusEnum } from "../../../shared/enum/tabItemStatusEnum";
import { ActionFooterEnum } from "../../../shared/enums/ActionFooterEnum";
import {
  setIsSaveButton,
  setTypeButton,
} from "../../../store/actions/basicDataForm.action";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { RootState } from "../../../store/store";
import { getMerchantInfo } from "../../../store/thunks/general/general.thunk";
import { IUseBasicFormStateContainer } from "./useBasicFormStateContainer.interfaces";
import { objectSaveMassiveBranch } from "../../../shared/utils/massiveBranchEdit";
import { useDispatch } from "react-redux";
import { setStatusSocket } from "../../../store/actions/massiveBranch/massiveBranch.actions";
import { updateMassiveBranch } from "../../../store/thunks/massiveBranch/massiveBranchs.thunks";
import {
  createMerchantData,
  getConstitutionalCountry,
  getOperationCountry,
} from "../../../shared/utils/basic_data_utils";
import { useBasicFormStateContainerGeneral } from "../../../shared/utils/state/useBasicFormStateContainerGeneral";
import { IMerchantPeopleNodeRequest } from "../../../store/interfaces/LegalDetailsState.interfaces";
import { NONE, StatusSocketEnum } from "../../../shared/enum/socketEnum";
import { PersonType_Generic_Enum } from "../../../shared/enum/BasicStateGeneral/PersonType";
import {
  setCompliancePeopleNode,
  setLegalRepresentativesArray,
  setNaturalPersonLastName,
  setNaturalPersonName,
  setShareHolder,
} from "../../../store/reducers/legalDetails/legalDetails";
import { useSnackbar } from "@kushki/connect-ui";
import { setNotificationMassive } from "../../../store/actions/massiveBranch.actions";
import { Routes } from "../../../shared/constants/routes";
import { useHistory } from "react-router";
import { redirectPath } from "../../../shared/utils/company_data_utils";

export const useBasicFormStateContainerMassive =
  (): IUseBasicFormStateContainer => {
    const {
      auxRules,
      continueButton,
      countriesInfo,
      countryElements,
      footerLabel,
      form,
      headerProps,
      hourComputer,
      isDisable,
      isEdit,
      nodeInfo,
      saveButton,
      showGenericInput,
      textSave,
      merchantNodeInfo,
      typeButton,
      newBasicData,
      validateEditBranch,
      handleChangeStatusNodeInfo,
      constructRequestCompliance,
      compliancePeopleNode,
      isLoadingBasicInformation,
    } = useBasicFormStateContainerGeneral(true);
    const dispatch = useAppDispatch();
    const dispatchWS = useDispatch();
    const { showSnackbar } = useSnackbar();
    const history = useHistory();

    const {
      branchBatch,
      title,
      listMerchants,
      configsInfo,
      listBranchesMassive,
      notificationMassive,
    } = useAppSelector((state: RootState) => ({
      ...state.branchBatch,
      ...state.initialData,
    }));

    const { updateMassiveBranchStatus, statusSocket } = useAppSelector(
      (state: RootState) => state.massiveBranch
    );

    const operationCountry = getOperationCountry(merchantNodeInfo!);

    const constitutionalCountry = getConstitutionalCountry(merchantNodeInfo!);

    const [isModalFinalization, setModalFinalization] =
      useState<boolean>(false);

    const setFinalization = (stateModal: boolean) => {
      setModalFinalization(stateModal);
    };

    const isDifferentPersonType = (): boolean =>
      get(
        merchantNodeInfo,
        "merchantType",
        PersonType_Generic_Enum.JURIDICA
      ) !==
      defaultTo(form.getValues("typePerson"), PersonType_Generic_Enum.JURIDICA);

    const createLegalData = (
      basicData: MerchantData
    ): IMerchantPeopleNodeRequest => {
      if (
        !isEmpty(form.getValues("surname")) &&
        !isEmpty(form.getValues("names"))
      ) {
        dispatch(
          setNaturalPersonLastName(defaultTo(form.getValues("surname"), ""))
        );
        dispatch(setNaturalPersonName(defaultTo(form.getValues("names"), "")));
      }

      const legalDetails: IMerchantPeopleNodeRequest =
        constructRequestCompliance!(basicData);

      if (isDifferentPersonType()) {
        set(legalDetails, "shareholders", []);
        set(legalDetails, "legalRepresentatives", []);

        dispatch(setLegalRepresentativesArray([]));
        dispatch(setShareHolder([]));

        dispatch(
          setCompliancePeopleNode({
            ...compliancePeopleNode,
            legalRepresentatives: [],
            shareholders: [],
          })
        );

        handleChangeStatusNodeInfo(
          TabItemStatusEnum.PENDING,
          ConfigIdEnum.CN015
        );
      } else {
        set(
          legalDetails,
          "legalRepresentatives",
          compliancePeopleNode.legalRepresentatives
        );
        set(legalDetails, "shareholders", compliancePeopleNode.shareholders);
      }

      return legalDetails;
    };
    const buildNamesByPersonType = (objectToSave: MerchantData): object => {
      return objectToSave.merchantType === PersonType_Generic_Enum.NATURAL
        ? {
            naturalPerson: {
              lastname: defaultTo(objectToSave.naturalPerson?.lastname, ""),
              name: defaultTo(objectToSave.naturalPerson?.name, ""),
            },
            socialReason:
              defaultTo(objectToSave.naturalPerson?.name, "").toUpperCase() +
              " " +
              defaultTo(objectToSave.naturalPerson?.lastname, "").toUpperCase(),
          }
        : {
            socialReason: defaultTo(objectToSave.socialReason, ""),
          };
    };

    const saveData = async (typeButton: ActionFooterEnum) => {
      dispatch(setTypeButton(typeButton));

      if (typeButton === ActionFooterEnum.SAVE) {
        dispatch(setIsSaveButton(true));
      }

      const isValidForm = await form.trigger();

      if (isValidForm) {
        const objectToSave: MerchantData = {
          ...createMerchantData({
            constitutionalCountry,
            form,
            merchantNodeInfo: merchantNodeInfo!,
            operationCountry,
            showGenericInput,
          }),
        };

        const objectMassiveSave: MerchantData = {
          ...objectToSave,
          companyType: defaultTo(objectToSave.companyType, ""),
          constitutionDate: defaultTo(objectToSave.constitutionDate, 0),
          economicActivityId: defaultTo(objectToSave.economicActivityId, ""),
          industryType: defaultTo(objectToSave.industryType, ""),
          mcc: defaultTo(objectToSave.mcc, ""),
          naturalPerson: { lastname: "", name: "" },
          socialReason: "",
          ...buildNamesByPersonType(objectToSave),
          webSite: defaultTo(objectToSave.webSite, ""),
        };

        const legalDetails = await createLegalData(objectMassiveSave);

        await dispatchWS(
          updateMassiveBranch(
            objectSaveMassiveBranch(
              {
                ...objectMassiveSave,
                legalDataUpdate: {
                  ...legalDetails,
                  isUpdateConfig15: isDifferentPersonType(),
                },
              },
              ConfigIdEnum.CN001,
              listBranchesMassive,
              form.formState.isValid
                ? TabItemStatusEnum.COMPLETE
                : TabItemStatusEnum.PENDING
            )
          )
        );
      }
    };

    const changeStatusNodeInfo = (status: TabItemStatusEnum) => {
      handleChangeStatusNodeInfo(
        status,
        ConfigIdEnum.CN001,
        get(branchBatch[0], "publicMerchantId")
      );
    };

    useEffect(() => {
      if (get(branchBatch[0], "publicMerchantId", "") != "")
        dispatch(
          getMerchantInfo({
            configId: ConfigIdEnum.CN001,
            publicMerchantId: get(branchBatch[0], "publicMerchantId"),
          })
        );
      newBasicData!();
      validateEditBranch!();
    }, [nodeInfo]);

    const redirectFinal = () => {
      isDifferentPersonType()
        ? history.push(`${Routes.EDIT_BRANCH_LEGAL_DETAILS}?menuItem=true`)
        : redirectPath("/create-node/branch/edit");
    };

    useEffect(() => {
      if (statusSocket === StatusSocketEnum.SUCCESS) {
        changeStatusNodeInfo(
          form.formState.isValid
            ? TabItemStatusEnum.COMPLETE
            : TabItemStatusEnum.IN_PROCESS
        );
        if (typeButton === ActionFooterEnum.CONTINUE) {
          redirectFinal();
        }
        dispatch(setTypeButton(NONE));
        dispatchWS(setStatusSocket(StatusSocketEnum.NONE));
      }
    }, [statusSocket]);

    useEffect(() => {
      if (notificationMassive) {
        showSnackbar(notificationMassive);
        dispatch(setNotificationMassive(undefined));
      }
    }, [notificationMassive]);

    return {
      auxRules,
      continueButton,
      countriesInfo,
      countryElements,
      footerLabel: !isEmpty(get(configsInfo, `${ConfigIdEnum.CN001}.updatedBy`))
        ? footerLabel
        : {
            date: "",
            editDetail: false,
            isHidden: true,
            text: "",
          },
      form,
      handleChangeStatusNodeInfo,
      headerProps,
      hourComputer,
      isDisable,
      isEdit,
      isLoadingBasicInformation,
      isModalFinalization,
      modalOpen: updateMassiveBranchStatus!,
      nodeInfo,
      numberBranches: `${listMerchants.split(",").length}`,
      saveButton,
      saveValues: saveData,
      setFinalization,
      showGenericInput,
      textSave,
      title,
    };
  };
