export const TYPE_LIST_KUSHKI = "kushki";

export enum CountryOrderType {
  ALPHABETIC_ORDER = "alphabetic",
  LATIN_FIRST = "latinFirst",
}

export enum CountryListType {
  ALL = "all",
  KUSHKI = "kushki",
  LATINOAMERICA = "latinAmerica",
}
