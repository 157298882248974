import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMerchantNodeState } from "../../interfaces/merchant-node.interfaces";
import {
  patchMerchantPreferences,
  patchMerchantSemaphore,
  putMerchantHierarchyConfig,
} from "../../thunks/merchant-node/merchant-node.thunks";
import { FetchStateEnum } from "../../../shared/constants/enums/fetchStateEnum";

export const initialState: IMerchantNodeState = {
  error: false,
  patchMerchantPreferencesState: FetchStateEnum.FAILED,
};

const merchantNodeSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(
        putMerchantHierarchyConfig.rejected,
        (state: IMerchantNodeState) => {
          state.error = true;
          state.putMerchantHierarchyConfigState = FetchStateEnum.FAILED;
        }
      )
      .addCase(
        putMerchantHierarchyConfig.pending,
        (state: IMerchantNodeState) => {
          state.putMerchantHierarchyConfigState = FetchStateEnum.PENDING;
        }
      )
      .addCase(
        putMerchantHierarchyConfig.fulfilled,
        (state: IMerchantNodeState) => {
          state.putMerchantHierarchyConfigState = FetchStateEnum.SUCCESS;
        }
      )
      .addCase(patchMerchantSemaphore.rejected, (state: IMerchantNodeState) => {
        state.error = true;
        state.patchMerchantSemaphoreState = FetchStateEnum.FAILED;
      })
      .addCase(patchMerchantSemaphore.pending, (state: IMerchantNodeState) => {
        state.patchMerchantSemaphoreState = FetchStateEnum.PENDING;
      })
      .addCase(
        patchMerchantSemaphore.fulfilled,
        (state: IMerchantNodeState) => {
          state.patchMerchantSemaphoreState = FetchStateEnum.SUCCESS;
        }
      )
      .addCase(
        patchMerchantPreferences.rejected,
        (state: IMerchantNodeState) => {
          state.error = true;
          state.patchMerchantPreferencesState = FetchStateEnum.FAILED;
        }
      )
      .addCase(
        patchMerchantPreferences.pending,
        (state: IMerchantNodeState) => {
          state.patchMerchantPreferencesState = FetchStateEnum.PENDING;
        }
      )
      .addCase(
        patchMerchantPreferences.fulfilled,
        (state: IMerchantNodeState) => {
          state.error = false;
          state.patchMerchantPreferencesState = FetchStateEnum.SUCCESS;
        }
      );
  },
  initialState,
  name: "merchant-node",
  reducers: {
    SHOW_SNACKBAR: (
      state: IMerchantNodeState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.error = payload;
    },
  },
});

export const { SHOW_SNACKBAR } = merchantNodeSlice.actions;

export default merchantNodeSlice.reducer;
