import { ActionTypes } from "./actionTypes";
import { AxiosResponse } from "axios";
import { IAppState } from "../shared/infrastructure/interfaces/IAppState";
import { InsertCredential } from "../../types/insert_credential";
import { environment } from "../environments/environment";
import {
  GetCredentialsResponse,
  ItemGetCredentialsResponse,
} from "../../types/get_credentials_response";
import { GetCredentialsRequest } from "../../types/get_credentials_request";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import axios from "../shared/utils/axios-utils";
import { IAlert } from "../shared/infrastructure/interfaces/IAlert";
import { UpdateCredentialRequest } from "../../types/update_credential_request";
import { DeleteCredentialRequest } from "../../types/delete_credential_request";
import { defaultTo, get, isEmpty } from "lodash";
import { IMerchantCredential } from "../shared/infrastructure/interfaces/IMerchantCredential";
import { NotificationResultEnum } from "../shared/infrastructure/constants/CredentialManagerConstants";
export type IAppAction = { type: string } & IAppState;
import { INotification } from "../shared/infrastructure/interfaces/INotification";
import {
  IMerchantCategory,
  MerchantItem,
} from "../shared/infrastructure/interfaces/IMerchantCategory";
import { IParentNodeInfo } from "../shared/infrastructure/interfaces/IParentNodeInfo";
import { GetNodeInfoResponse } from "../../types/get_node_info_response";
import { SearchMerchantRequest } from "../../types/search_merchant_request";
import { SearchMerchantResponse } from "../../types/search_merchant_response";
import { ApiRoutesConstants } from "../shared/infrastructure/constants/ApiRoutesConstants";

export const setCredentialsList = (
  payload: GetCredentialsResponse
): IAppAction => ({
  credentialsList: payload,
  type: ActionTypes.SET_CREDENTIALS_LIST,
});

export const setCredentialsListModal = (
  payload: GetCredentialsResponse
): IAppAction => ({
  credentialsListModal: payload,
  type: ActionTypes.SET_CREDENTIALS_LIST_MODAL,
});

export const setIsLoading = (payload: boolean): IAppAction => ({
  isLoading: payload,
  type: ActionTypes.SET_IS_LOADING,
});

export const setIsLoadingCredentials = (payload: boolean): IAppAction => ({
  isLoadingCredentials: payload,
  type: ActionTypes.SET_IS_LOADING_CREDENTIALS,
});

export const setOpenAlert = (payload: IAlert): IAppAction => {
  return {
    type: ActionTypes.SET_OPEN_ALERT,
    openAlert: payload,
  };
};

export const setRequestError = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_REQUEST_ERROR,
    requestError: payload,
  };
};

export const setIsOpenCustomModal = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_IS_OPEN_CUSTOM_MODAL,
    isOpenCustomModal: payload,
  };
};

export const setIsOpenCredentialModal = (payload: boolean): IAppAction => {
  return {
    type: ActionTypes.SET_IS_OPEN_CREDENTIAL_MODAL,
    isOpenCredentialModal: payload,
  };
};

export const setInsertCredentialsResponse = (payload: object) => ({
  credentialInsert: payload,
  type: ActionTypes.SET_INSERT_CREDENTIALS_RESPONSE,
});

export const getCredentialsList = (
  body: GetCredentialsRequest,
  isModal: boolean
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    if (!isModal) {
      dispatch(setIsLoading(true));
      dispatch(setIsLoadingCredentials(true));
    }
    axios
      .post(
        `${environment.kushkiUrl}${ApiRoutesConstants.adminCredentialSearch}`,
        body
      )
      .then((axios_response: AxiosResponse<GetCredentialsResponse>) => {
        const response: GetCredentialsResponse = axios_response.data;
        if (!isModal) {
          dispatch(setIsLoadingCredentials(false));
          dispatch(setCredentialsList(response));
          dispatch(setIsLoading(false));
        } else {
          dispatch(setCredentialsListModal(response));
        }
        if (isEmpty(body.filter)) {
          localStorage.setItem(
            "totalCredentials",
            axios_response.data.total.toString()
          );
        }
      })
      .catch(() => {
        if (!isModal) {
          dispatch(setIsLoadingCredentials(false));
          dispatch(setRequestError(true));
        }
        dispatch(setIsLoading(false));
      });
  };
};

export const insertCredentials =
  (
    dataSend: InsertCredential
  ): ThunkAction<void, IAppState, undefined, IAppAction> =>
  (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setIsLoading(true));
    axios
      .post(
        `${environment.kushkiUrl}${ApiRoutesConstants.adminCredentials}`,
        dataSend
      )
      .then((axios_response: AxiosResponse<object>) => {
        const createdCredential: IMerchantCredential =
          axios_response.data as IMerchantCredential;
        dispatch(
          updateCredentialList(
            get(createdCredential, "credentialId"),
            false,
            true,
            undefined,
            {
              enable: true,
              credentialId: get(createdCredential, "credentialId"),
              merchantId: dataSend.merchantId,
              alias: dataSend.alias,
              publicCredentialId: get(createdCredential, "publicCredentialId"),
              privateCredentialId: get(
                createdCredential,
                "privateCredentialId"
              ),
            }
          )
        );
        dispatch(setInsertCredentialsResponse(axios_response.data));
        dispatch(setIsOpenCredentialModal(false));
        dispatch(setIsLoading(false));
      });
  };

export const updateCredential =
  (
    body: UpdateCredentialRequest
  ): ThunkAction<void, IAppState, undefined, IAppAction> =>
  (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setIsLoading(true));
    axios
      .patch(
        `${environment.kushkiUrl}${ApiRoutesConstants.adminCredentials}`,
        body
      )
      .then(() => {
        dispatch(setIsLoading(false));
        dispatch(setIsOpenCredentialModal(false));
        dispatch(updateCredentialList(body.credentialId, false, false, body));
        dispatch(setOpenAlert({ open: true, isError: false, origin: "" }));
      })
      .catch(() => {
        dispatch(setOpenAlert({ open: true, isError: true, origin: "" }));
        dispatch(setIsLoading(false));
        dispatch(setIsOpenCredentialModal(false));
      });
  };

export const deleteCredential =
  (
    body: DeleteCredentialRequest
  ): ThunkAction<void, IAppState, undefined, IAppAction> =>
  (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setIsLoading(true));
    axios
      .delete(
        `${environment.kushkiUrl}${ApiRoutesConstants.adminCredentials}`,
        {
          data: {
            ...body,
          },
        }
      )
      .then(() => {
        dispatch(setIsLoading(false));
        dispatch(setIsOpenCustomModal(false));
        dispatch(updateCredentialList(body.credentialId, true));
        dispatch(setOpenAlert({ open: true, isError: false, origin: "" }));
      })
      .catch(() => {
        dispatch(setOpenAlert({ open: true, isError: true, origin: "" }));
        dispatch(setIsLoading(false));
        dispatch(setIsOpenCustomModal(false));
      });
  };

export const regenerateCredential =
  (
    body: UpdateCredentialRequest
  ): ThunkAction<void, IAppState, undefined, IAppAction> =>
  (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setIsLoading(true));
    axios
      .put(`${environment.kushkiUrl}${ApiRoutesConstants.adminCredentials}`, {
        ...body,
      })
      .then(() => {
        setTimeout(() => {
          axios
            .post(
              `${environment.kushkiUrl}${ApiRoutesConstants.adminCredentialSearch}`,
              {
                filter: {
                  credentialId: body.credentialId,
                },
                offset: 0,
                limit: 1,
                merchantId: body.merchantId,
              }
            )
            .then((axios_response: AxiosResponse<GetCredentialsResponse>) => {
              const newCredential: ItemGetCredentialsResponse =
                axios_response.data.data[0];
              dispatch(setIsLoading(false));
              dispatch(setIsOpenCustomModal(false));
              dispatch(
                updateCredentialList(body.credentialId, false, false, {
                  ...body,
                  publicCredentialId: newCredential._source.publicCredentialId,
                  privateCredentialId:
                    newCredential._source.privateCredentialId,
                })
              );
              dispatch(
                setOpenAlert({ open: true, isError: false, origin: "" })
              );
            })
            .catch(() => {
              dispatch(setOpenAlert({ open: true, isError: true, origin: "" }));
              dispatch(setIsLoading(false));
              dispatch(setIsOpenCustomModal(false));
            });
        }, 2000); // Two-second delay for the dynamo-elastic synchronization to be completed
      })
      .catch(() => {
        dispatch(setOpenAlert({ open: true, isError: true, origin: "" }));
        dispatch(setIsLoading(false));
        dispatch(setIsOpenCustomModal(false));
      });
  };

// Due to the fact that synchronization between Dynamo and Elasticsearch is not instantaneous,
// we cannot perform a getCredentialsList request again once one credential has been
// modified or deleted. It will cause outdated data to be retrieved.
// This is why we wrote this function to update the credentials list state so that the changes
// performed reflect the data that is being displayed in the table.
export const updateCredentialList =
  (
    credentialId: string,
    isDelete: boolean,
    createdCredential: boolean = false,
    newCredential?: UpdateCredentialRequest,
    insertedCredential?: IMerchantCredential
  ): ThunkAction<void, IAppState, undefined, IAppAction> =>
  (
    dispatch: ThunkDispatch<IAppState, any, IAppAction>,
    getState: () => IAppState
  ): void => {
    dispatch(setIsLoading(true));
    const credentialsList: GetCredentialsResponse = get(
      getState(),
      "credentialsList",
      { data: [], total: 0 }
    );
    if (createdCredential) {
      const newCredential: ItemGetCredentialsResponse = {
        _source: {
          merchantId: defaultTo(insertedCredential?.merchantId, ""),
          privateCredentialId: defaultTo(
            insertedCredential?.privateCredentialId,
            ""
          ),
          publicCredentialId: defaultTo(
            insertedCredential?.publicCredentialId,
            ""
          ),
          enable: defaultTo(insertedCredential?.enable, false),
          alias: defaultTo(insertedCredential?.alias, ""),
          created: defaultTo(insertedCredential?.created, 0),
          deleteAt: defaultTo(insertedCredential?.deleteAt, 0),
          hidden: defaultTo(insertedCredential?.hidden, false),
          credentialId: defaultTo(insertedCredential?.credentialId, ""),
          type: defaultTo(insertedCredential?.type, ""),
        },
      };

      let newResponse: GetCredentialsResponse;

      if (credentialsList.total >= 5) {
        credentialsList.data.splice(0, 1, newCredential);
        newResponse = {
          data: [...credentialsList.data],
          total: credentialsList.total + 1,
        };
      } else {
        newResponse = {
          data: [newCredential, ...credentialsList.data],
          total: credentialsList.total + 1,
        };
      }
      dispatch(setCredentialsList(newResponse));
      dispatch(setIsLoading(false));
      return;
    }
    const foundIndex = credentialsList.data.findIndex(
      (credential: ItemGetCredentialsResponse) =>
        credential._source.credentialId === credentialId
    );
    if (isDelete) {
      credentialsList.data.splice(foundIndex, 1);
      const newResponse: GetCredentialsResponse = {
        data: [...credentialsList.data],
        total: credentialsList.total - 1,
      };
      dispatch(setCredentialsList(newResponse));
      dispatch(setIsLoading(false));
      return;
    }

    if (newCredential) {
      const targetCredential: ItemGetCredentialsResponse | undefined =
        credentialsList.data.find(
          (credential: ItemGetCredentialsResponse) =>
            credential._source.credentialId === credentialId
        );
      const builtCredential: ItemGetCredentialsResponse = {
        _source: {
          enable: newCredential.enable,
          credentialId: credentialId,
          alias: newCredential.alias,
          merchantId: newCredential.merchantId,
          type: defaultTo(targetCredential?._source.type, ""),
          hidden: defaultTo(targetCredential?._source.hidden, false),
          publicCredentialId: newCredential
            ? get(
                newCredential,
                "publicCredentialId",
                targetCredential?._source.publicCredentialId
              )
            : defaultTo(targetCredential?._source.publicCredentialId, ""),
          deleteAt: defaultTo(targetCredential?._source.deleteAt, 0),
          created: defaultTo(targetCredential?._source.created, 0),
          privateCredentialId: newCredential
            ? get(
                newCredential,
                "privateCredentialId",
                targetCredential?._source.privateCredentialId
              )
            : defaultTo(targetCredential?._source.privateCredentialId, ""),
        },
      };
      credentialsList.data.splice(foundIndex, 1, builtCredential);
      const newResponse: GetCredentialsResponse = {
        data: [...credentialsList.data],
        total: credentialsList.total,
      };
      dispatch(setCredentialsList(newResponse));
      dispatch(setIsLoading(false));
    }
  };

export const getSearchKeyCredentials = (
  merchantId: string,
  searchTerm: string,
  path?: string,
  entityName?: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setIsLoadingCredentialSuggested(true));
    const bodyRequest: object = {
      merchantId: merchantId,
      searchTerm: searchTerm,
      path: path,
      entityName: entityName,
    };

    axios
      .post<IMerchantCredential[]>(
        `${environment.kushkiUrl}${ApiRoutesConstants.adminCredentialSuggestions}`,
        bodyRequest
      )
      .then((axios_response: AxiosResponse<IMerchantCredential[]>) => {
        const response: IMerchantCredential[] = get(
          axios_response,
          "data.data"
        );

        const merchantCategories = getMerchantCategories(response);

        dispatch(setCredentialsForBox(merchantCategories));
        dispatch(setIsLoadingCredentialSuggested(false));
      })
      .catch(() => {
        dispatch(
          setNotification({
            action: NotificationResultEnum.REQUEST_ERROR,
            message: "No se encontró la credencial.",
            open: true,
            type: "error",
          })
        );
        dispatch(setIsLoadingCredentialSuggested(false));
      });
  };
};

export const setParentMerchantId = (payload: string): IAppAction => {
  return {
    type: ActionTypes.SET_PARENT_MERCHANT_ID,
    parentMerchantId: payload,
  };
};

export const getParentMerchantId = (
  publicMerchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    dispatch(setRequestError(false));
    axios
      .post(`${environment.kushkiUrl}${ApiRoutesConstants.nodeFirstParent}`, {
        publicMerchantId,
      })
      .then((response: AxiosResponse<IParentNodeInfo>) => {
        dispatch(setParentMerchantId(response.data.merchantId));
      })
      .catch(() => {
        dispatch(setRequestError(true));
      });
  };
};

export const getNodeInfo = (
  merchantId: string
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    setIsLoading(true);
    axios
      .get<GetNodeInfoResponse>(
        `${environment.kushkiUrl}${ApiRoutesConstants.hierarchyMerchant}${merchantId}`
      )
      .then((response: AxiosResponse<GetNodeInfoResponse>) => {
        dispatch(setNodeInfo(response.data));
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        dispatch(setNodeInfo({ path: "" }));
      });
  };
};

export const searchMerchants = (
  request: SearchMerchantRequest,
  setMerchants: Function
): ThunkAction<void, IAppState, undefined, IAppAction> => {
  return (dispatch: ThunkDispatch<IAppState, any, IAppAction>): void => {
    setIsLoading(true);
    axios
      .post(
        `${environment.kushkiUrl}${ApiRoutesConstants.merchantNodeSearch}`,
        request
      )
      .then((response: AxiosResponse<SearchMerchantResponse>) => {
        setIsLoading(false);
        dispatch(setMerchants(response.data));
      })
      .catch(() => {
        setIsLoading(false);
        dispatch(setMerchants({ data: [], total: 0 }));
      });
  };
};

export const setIsLoadingCredentialSuggested = (
  payload: boolean
): IAppAction => {
  return {
    type: ActionTypes.SET_IS_LOADING_GET_SUGGESTED_CREDENTIALS,
    isLoadingGetSuggestedCredentials: payload,
  };
};

export const setCredentialsForBox = (
  payload: IMerchantCategory[]
): IAppAction => {
  return {
    type: ActionTypes.SET_CREDENTIALS_FOR_BOX,
    credentialsForBox: payload,
  };
};

export const setNotification = (payload: INotification): IAppAction => {
  return {
    type: ActionTypes.SET_NOTIFICATION,
    notification: payload,
  };
};

export const getMerchantItem = (
  payload: IMerchantCredential[],
  key: string
): MerchantItem[] => {
  return payload
    .map((merchantCredential) => merchantCredential[key])
    .filter((value, index, self) => {
      return self.indexOf(value) === index;
    })
    .map((value) => {
      return {
        text: value,
        secondaryText: "",
        id: value,
      } as MerchantItem;
    });
};

const getMerchantCategories = (
  response: IMerchantCredential[]
): IMerchantCategory[] => {
  return [
    {
      categoryId: "credentialId",
      categoryName: "ID Credenciales",
      items: getMerchantItem(response, "credentialId"),
    },
    {
      categoryId: "publicCredentialId",
      categoryName: "Llave pública",
      items: getMerchantItem(response, "publicCredentialId"),
    },
    {
      categoryId: "privateCredentialId",
      categoryName: "Llave privada",
      items: getMerchantItem(response, "privateCredentialId"),
    },
    {
      categoryId: "alias",
      categoryName: "Alias",
      items: getMerchantItem(response, "alias"),
    },
  ];
};

export const setNodeInfo = (payload: GetNodeInfoResponse): IAppAction => {
  return {
    type: ActionTypes.SET_NODE_INFO,
    nodeInfo: payload,
  };
};

export const setBranchesData = (
  payload: SearchMerchantResponse
): IAppAction => {
  return {
    type: ActionTypes.SET_BRANCHES_DATA,
    branchesData: payload,
  };
};
