import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

export type SnackbarAlertType = "success" | "info" | "warning" | "error";

export interface SnackbarAlertProps {
  msg: string;
  open: boolean;
  type: SnackbarAlertType;
  handlerClose: (event: React.SyntheticEvent | Event, reason?: string) => void;
}

export const SnackBarAlert: React.FC<SnackbarAlertProps> = (
  props: SnackbarAlertProps
) => {
  return (
    <Snackbar
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      open={props.open}
      autoHideDuration={4000}
      onClose={props.handlerClose}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={props.handlerClose}
        severity={props.type}
      >
        {props.msg}
      </MuiAlert>
    </Snackbar>
  );
};
