import {
  BranchesSelectedList,
  MassiveInformation,
} from "../../../types/massive_information";
import {
  MerchantNodeData,
  SearchMerchantResponse,
} from "../../../types/search_merchant_response";
import { defaultTo, get, map } from "lodash";

export const merchantLocalStorage: string = "merchantBasicInformation";
export const merchantInfoLocalStorage: string = "merchantInfo";
export const merchantIdLocalStorage: string = "merchantId";

export const setLocalStorageObject = (key: string, object: object | string) => {
  let objectToSet = object;

  if (typeof object !== "string") objectToSet = JSON.stringify(object);

  localStorage.setItem(key, <string>objectToSet);
};

export const listBranchesSelected = (): MassiveInformation => {
  const branchesList: BranchesSelectedList[] = [];
  const list: SearchMerchantResponse = JSON.parse(
    sessionStorage.getItem("branchEditList") || "[]"
  );

  defaultTo(list.data, [])
    .filter((branch: MerchantNodeData) => branch.edit)
    .map((branch: MerchantNodeData) => {
      branchesList.push({
        clientType: get(branch, "client_type", ""),
        name: get(branch, "name", ""),
        nodeId: get(branch, "node_id", ""),
        publicMerchantId: get(branch, "public_merchant_id", ""),
      });
    });

  const title: string = map(branchesList.slice(0, 3), "name").join(", ");

  return {
    listBranches: branchesList,
    title,
  };
};
