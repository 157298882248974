import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const contactDataContainerStyles = createNamedStyles({
  boxContainer: {
    width: 1,
    height: "100%",
    position: "fixed",
    display: "flex",
  },
  boxSidebar: {
    width: {
      md: "10%",
      sm: "30%",
    },
    height: 1,
    bgcolor: {
      md: "primary.light",
      sm: "secondary.main",
    },
  },
  box: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
  },
  boxContent: {
    height: "90%",
    marginBottom: "6em",
    padding: "48px 64px 48px 64px",
    width: "100%",
  },
  boxContentEdit: {
    height: "90%",
    marginBottom: "6em",
    padding: "48px 200px 48px 200px",
    width: "100%",
  },
  labelTitle: {
    color: "#023365",
    fontSize: "20px",
    fontWeight: 500,
    fontStyle: "normal",
  },
  subTitleText: {
    fontSize: "15px",
    color: "#6D7781",
  },
  boxFooter: {
    zIndex: "1000",
    width: "100%",
    position: "fixed",
    bottom: "0",
  },
  boxContent2: {
    minHeight: "600px",
    bgcolor: "#FFFFFF",
    width: "100%",
    padding: "48px 64px 48px 64px",
  },
});
