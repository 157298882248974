import React from "react";
const IconRefundSecondary = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g transform="matrix(1 0 0 1 0 0)">
        <path
          d="M4 5.99072L4 10.9907L9 10.9907"
          stroke="#4498EE"
          stroke-width="2"
          stroke-linecap="square"
          fill="none"
        />
        <path
          d="M6.00814 14.5932C 6.52689 16.0656 7.51012 17.3296 8.80967 18.1946C 10.1092 19.0596 11.6547 19.4788 13.2132 19.3891C 14.7718 19.2994 16.2589 18.7056 17.4507 17.6972C 18.6424 16.6888 19.4741 15.3204 19.8205 13.7982C 20.1669 12.276 20.0092 10.6825 19.3712 9.25772C 18.7332 7.83293 17.6494 6.65409 16.2831 5.89881C 14.9169 5.14353 13.3422 4.85274 11.7963 5.07024C 10.2504 5.28773 8.81707 6.00174 7.71226 7.10469L7.71226 7.10469L4 10.5929"
          stroke="#4498EE"
          stroke-width="2"
          stroke-linecap="square"
          fill="none"
        />
      </g>
    </svg>
  );
};

export default IconRefundSecondary;
