import React, { FC } from "react";
import FormWrapper from "../../components/FormWrapper/FormWrapper";
import WrapperContainer from "../../components/WrapperContainer/WrapperContainer";
import { ROUTES } from "../../shared/constants/routes";
import { SnackBarProvider } from "@kushki/connect-ui";
import { useChargeContainerState } from "./state/useChargeContainerState";
import ChargeForm from "../../components/ChargeForm/ChargeForm";
import { CHARGE } from "../../shared/constants/labels/main_containter_labels";
import ChargeBankAccount from "../../components/ChargeBankAccount/ChargeBankAccount";
import ChargeContainerSkeleton from "../../components/Skeletons/ChargeContainerSkeleton/ChargeContainerSkeleton";

const ChargeContainer: FC = () => {
  const { form, isCentralizedBranch, loading, isLoadingChargeInformation } =
    useChargeContainerState();

  return (
    <WrapperContainer currentRoute={ROUTES.CHARGE} type={CHARGE}>
      {isLoadingChargeInformation ? (
        <ChargeContainerSkeleton />
      ) : (
        <FormWrapper title={"Cobro"}>
          <SnackBarProvider>
            <ChargeForm
              form={form}
              isBranchCentralized={isCentralizedBranch}
              loading={loading}
            />
            <ChargeBankAccount isCentralizedBranch={isCentralizedBranch} />
          </SnackBarProvider>
        </FormWrapper>
      )}
    </WrapperContainer>
  );
};

export default ChargeContainer;
