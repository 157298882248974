import { IOptionFilter } from "../../../components/DashboardList/BoxFilter/BoxFilter";
import { CycleEnum } from "../CycleEnum";
import { StatusEnum } from "../StatusEnum";
import { CountryEnum } from "../CountryEnum";
import { CitiesCoEnum } from "../CitiesCoEnum";
import { KindModelEnum } from "../KindModelEnum";

export const DefaultFilterRetentionExecutor: IOptionFilter[] = [
  {
    id: "cityId",
    title: "Municipalidad",
    options: [
      {
        key: CitiesCoEnum.BARRANQUILLA,
        label: "BARRANQUILLA",
        selected: false,
      },
      {
        key: CitiesCoEnum.BOGOTA,
        label: "BOGOTA",
        selected: false,
      },
      {
        key: CitiesCoEnum.BUCARAMANGA,
        label: "BUCARAMANGA",
        selected: false,
      },
      {
        key: CitiesCoEnum.CALI,
        label: "CALI",
        selected: false,
      },
      {
        key: CitiesCoEnum.MEDELLIN,
        label: "MEDELLIN",
        selected: false,
      },
      {
        key: CitiesCoEnum.PEREIRA,
        label: "PEREIRA",
        selected: false,
      },
    ],
    countries: [CountryEnum.colombia],
  },
  {
    id: "processorType",
    title: "Modelo",
    options: [
      {
        key: KindModelEnum.AGF,
        label: "Agregador - formal",
        selected: false,
      },
      {
        key: KindModelEnum.AGP,
        label: "Agregador - pasarela",
        selected: false,
      },
    ],
    countries: [CountryEnum.ecuador],
  },
];

export const DefaultFilterRetentionValidator: IOptionFilter[] = [
  {
    id: "cycle",
    title: "Ciclo",
    options: [
      { key: CycleEnum.DAILY, label: "Diario", selected: false },
      {
        key: CycleEnum.THREEWEEKLY,
        label: "Lun.-Miérc.-Vier.",
        selected: false,
      },
      { key: CycleEnum.TWOWEEKLY, label: "Mar.-Vier.", selected: false },
      { key: CycleEnum.WEEKLY, label: "Semanal", selected: false },
      { key: CycleEnum.BIWEEKLY, label: "Quincenal", selected: false },
      { key: CycleEnum.MONTHLY, label: "Mensual", selected: false },
    ],
    countries: [CountryEnum.ecuador],
  },
  {
    id: "status",
    title: "Estado",
    options: [
      { key: StatusEnum.PRE_PROCESSED, label: "En proceso", selected: false },
      { key: StatusEnum.FAILED, label: "Fallido", selected: false },
    ],
    countries: [CountryEnum.ecuador],
  },
  {
    id: "currency",
    title: "Moneda",
    options: [{ key: "USD", label: "USD", selected: false }],
  },
];

export const DefaultFilterRetentionExecutorEcuador: IOptionFilter[] = [
  {
    id: "cycle",
    title: "Ciclo",
    options: [
      { key: CycleEnum.DAILY, label: "Diario", selected: false },
      {
        key: CycleEnum.THREEWEEKLY,
        label: "Lun.-Miérc.-Vier.",
        selected: false,
      },
      { key: CycleEnum.TWOWEEKLY, label: "Mar.-Vier.", selected: false },
      { key: CycleEnum.WEEKLY, label: "Semanal", selected: false },
      { key: CycleEnum.BIWEEKLY, label: "Quincenal", selected: false },
      { key: CycleEnum.MONTHLY, label: "Mensual", selected: false },
    ],
    countries: [CountryEnum.ecuador],
  },
  {
    id: "currency",
    title: "Moneda",
    options: [{ key: "USD", label: "USD", selected: false }],
  },
];
