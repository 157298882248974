export enum LabelEnum {
  BACK = "Regresar",
  SEE_MORE = "Ver más",
  COMMISSION = "Comisiones a terceros",
  YES = "Si",
  CAJITA = "Cajita",
  SUBSCRIPTION = "Cobros por subscripción",
  SANDBOX = "Sandbox",
  ACTIVE = "Activado",
  HEADER_MASSIVE_TITLE = "branches a editar:",
  OPERATION_COUNTRY = "País de operación: ",
  ID = "ID: ",
}
