import React, { FC } from "react";
import { IUsersContainerProps } from "./UsersContainer.interfaces";
import { useUsersContainer } from "./state/useUsersContainer";
import {
  FilterSideBar,
  IconSearch,
  ModalSimple,
  TableSimple,
} from "@kushki/connect-ui";
import {
  Box,
  Button,
  Collapse,
  Container,
  Grid,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import { GenericBreadcrumb } from "../../components/GenericBreadcrumb/GenericBreadcrumb";
import { UsersLabel } from "../../shared/constants/labels/UsersLabel";
import {
  errorStyle,
  usersContainerStyles as styles,
} from "./UsersContainer.styles";
import {
  ActionsUserTypeEnum,
  PaginationLabelEnum,
  ROWS_PER_PAGE_OPTIONS,
} from "../../shared/enum/UsersTableEnum";
import { EntityEnum } from "../../shared/enum/EntityEnum";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import InputAdornment from "@mui/material/InputAdornment";
import AddIcon from "@mui/icons-material/Add";
import { ButtonEnum } from "../../shared/enum/ButtonEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { SecurityWrapperRoles } from "../../shared/enum/SecurityWrapperRoles";
import HeaderFiltersUser from "../../components/HeaderFiltersUser/HeaderFilterUser";
import { isBackofficeMasterCustomer } from "../../shared/utils/getRolesUtils";
import MassiveActionsUsers from "../../components/MassiveActionsUsers/MassiveActionsUsers";
import { WrapperContainer } from "../WrapperContainer/WrapperContainer";
import { TITLE_SIDEBAR } from "../../shared/enum/FilterLabelUsersEnum";

const UsersContainer: FC<IUsersContainerProps> = ({}: IUsersContainerProps) => {
  const {
    actionsModal,
    breadcrumbItems,
    canAddUser,
    canSearchUser,
    entityName,
    handleAddUser,
    handlers,
    handleSearchInput,
    header,
    isLoadingTable,
    pagination: {
      disableNext,
      disablePrevious,
      previousPage,
      changeRowsPerPage,
      nextPage,
      page,
      rowsPerPage,
      count,
      hidePagination,
    },
    rowsTable,
    usernameError,
    massiveActions,
    onChangeFilterSidebar,
    path,
    isDisabledTable,
    categoryItems,
    handleApplyFilter,
  } = useUsersContainer();

  return (
    <WrapperContainer single>
      <Container sx={styles.container} fixed>
        <Grid container>
          <Box pb={2}>
            {entityName === EntityEnum.CUSTOMER ? (
              <Button
                variant="text"
                sx={styles.backBtnNewConsole}
                startIcon={<ArrowBackIosIcon />}
                onClick={handlers.handleReturn}
              >
                {UsersLabel.RETURN}
              </Button>
            ) : (
              <GenericBreadcrumb breadcrumbItems={breadcrumbItems} />
            )}
          </Box>
          <Grid container>
            <Grid item xs={6}>
              <Box pb={2}>
                <Typography variant={"h2"} color="text.primary">
                  {UsersLabel.TITLE}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              {canAddUser && (
                <ComponentSecurityWrapper
                  componentId={SecurityWrapperRoles.USERS_ADD}
                >
                  <Box sx={styles.floatRight}>
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={handleAddUser}
                    >
                      {`${ButtonEnum.AGREGAR}`}
                    </Button>
                  </Box>
                </ComponentSecurityWrapper>
              )}
            </Grid>
          </Grid>
          <Grid container sx={styles.filterContainer}>
            <Grid item xs={6}>
              <Box>
                {canSearchUser() && (
                  <>
                    <TextField
                      sx={styles.searchInput}
                      placeholder={UsersLabel.SEARCH_INPUT_LABEL}
                      margin="normal"
                      fullWidth
                      onChange={handleSearchInput}
                      onKeyDown={handleSearchInput}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconSearch color="primary" fontSize="medium" />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {usernameError && (
                      <small style={errorStyle}>{UsersLabel.ERROR_INPUT}</small>
                    )}
                  </>
                )}
              </Box>
            </Grid>
            {isBackofficeMasterCustomer() ? (
              <HeaderFiltersUser
                path={path}
                onChangeFilterSidebar={onChangeFilterSidebar}
              />
            ) : (
              <Box sx={styles.filter}>
                <FilterSideBar
                  key={page}
                  categoryItems={categoryItems}
                  filtersCount={1}
                  isMinimized={false}
                  isOpen={false}
                  onClick={handleApplyFilter}
                  title={TITLE_SIDEBAR}
                />
              </Box>
            )}
            <Collapse
              in={massiveActions.showMassiveActions}
              sx={styles.actionsMassive}
            >
              <MassiveActionsUsers
                totalSelected={massiveActions.totalSelected}
              />
            </Collapse>
          </Grid>
        </Grid>
        <Box sx={isBackofficeMasterCustomer() && styles.tableContainer}>
          <TableSimple
            header={{
              cells: header,
              headerProps: {
                isDisable: isDisabledTable,
              },
            }}
            rows={rowsTable}
            isLoading={isLoadingTable}
            showPagination={false}
            allowSelection={isBackofficeMasterCustomer()}
            onSelectedRows={handlers.onSelectedRows}
          />
          {hidePagination ? null : (
            <TablePagination
              id="pagination-users"
              sx={styles.pagination}
              labelDisplayedRows={() => `${page}-${count} de ${count}`}
              labelRowsPerPage={PaginationLabelEnum.USERS_PER_PAGE}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={() => {}}
              onRowsPerPageChange={changeRowsPerPage}
              component={"div"}
              nextIconButtonProps={{
                disabled: disableNext,
                onClick: nextPage,
              }}
              backIconButtonProps={{
                disabled: disablePrevious,
                onClick: previousPage,
              }}
            />
          )}
        </Box>
        <ModalSimple
          data-testis={"modalActionsUsers"}
          buttonPrimaryColor={
            [
              ActionsUserTypeEnum.DELETE,
              ActionsUserTypeEnum.MASSIVE_DELETE,
            ].includes(actionsModal.type)
              ? "error"
              : "primary"
          }
          onClickPrimary={handlers.handleConfirmAction}
          onClickSecondary={handlers.handleCloseModal}
          buttonPrimary={actionsModal.confirmButton}
          descriptionText={actionsModal.description}
          isOpen={actionsModal.openModal}
          onClose={handlers.handleCloseModal}
          titleText={actionsModal.title}
          typeModal={1}
          buttonSecondary={actionsModal.cancelButton}
        />
      </Container>
    </WrapperContainer>
  );
};

export default UsersContainer;
