/* istanbul ignore file */
import { ChargebackStatusEnum } from "../enums/ChargebackStatusEnum";
import { EmailStatusEnum } from "../enums/EmailStatusEnum";

export const LanguageCatalog: ICategory[] = [
  {
    es: "Iniciado",
    lang: ChargebackStatusEnum.INITIALIZED,
  },
  {
    es: "Pendiente",
    lang: ChargebackStatusEnum.PENDING,
  },
  {
    es: "En revisión",
    lang: ChargebackStatusEnum.REVIEW,
  },
  {
    es: "Ganado",
    lang: ChargebackStatusEnum.WON,
  },
  {
    es: "Perdido",
    lang: ChargebackStatusEnum.LOST,
  },
  {
    es: "Fallido",
    lang: ChargebackStatusEnum.FAILED,
  },
  {
    es: "Documentación enviada",
    lang: ChargebackStatusEnum.DOCSENT,
  },
  {
    es: "Vencido",
    lang: ChargebackStatusEnum.EXPIRED,
  },
  {
    es: "Entregado",
    lang: EmailStatusEnum.SENT,
  },
  {
    es: "Fallido",
    lang: EmailStatusEnum.BOUNCED,
  },
  {
    es: "Tarjeta",
    lang: "card",
  },
];

export interface ICategory {
  lang: string;
  es?: string;
}

export const translate = (text: string): string => {
  if (text === undefined) return "";
  const index: number = LanguageCatalog.findIndex(
    (detail: ICategory) => detail.lang === text
  );

  if (index >= 0) {
    const lang: string | undefined = LanguageCatalog[index]["es"];

    return lang !== undefined ? lang : text;
  }

  return text;
};
