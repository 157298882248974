import { IDialog } from "../../components/Dialogs/state/useDialogsState.interfaces";

const REFUND_DIALOG: IDialog = {
  buttonText: "Enviar",

  description:
    "La transacción pasará al módulo de reembolsos para ser evaluada por un analista, ¿quieres realizar esta acción?",
  merchantId: "Merchant ID: 1234123",
  merchantName: "Panificadora La Central S.A",
  title: "Enviar a reembolsar",
  type: "delete",
};

const SKIP_DIALOG: IDialog = {
  buttonText: "Omitir",
  description: "Escribe el motivo del porque se omite la transacción",
  merchantId: "Merchant ID: 1234123",
  merchantName: "Panificadora La Central S.A",
  title: "Omitir transacción",
  type: "skip",
};

const CHECK_DIALOG: IDialog = {
  buttonText: "Guardar",
  description:
    "Seleccione el estado de la solicitud en el que se encuentra esta transacción:",
  merchantId: "Merchant ID: 1234123",
  merchantName: "Panificadora La Central S.A",
  title: "Transacción pendiente",
  type: "check",
};

export { CHECK_DIALOG, REFUND_DIALOG, SKIP_DIALOG };
