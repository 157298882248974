import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store.interfaces";
import { AppState } from "../interfaces/AppState.interfaces";
import { defaultTo } from "lodash";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useGetMerchantInfo = () =>
  JSON.parse(defaultTo(localStorage.getItem("merchantBasicInformation"), "{}"));
export const useGetMerchantCountry = () =>
  defaultTo(useGetMerchantInfo()["country"], "Ecuador");

export const useKshRedux = () => {
  const store: AppState = useAppSelector((state: RootState) => state.app);

  return {
    actions: {},
    store,
  };
};
