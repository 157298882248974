/* istanbul ignore file */
/* eslint-disable */
export const environment: {
kushkiUrl: string;
envName: string;
rollbarId: string;
appName: string;
appEnv: string;
appProfileName: string;
devTools: boolean;
} = {
kushkiUrl: "https://api.kushkipagos.com",
envName: "primary",
rollbarId: "7f47a342a0fe4c608ee73c0b1372f2af",
appName: "IvaValues",
appEnv: "develop",
appProfileName: "IvaConfig",
devTools: false,
};
