import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./reducers/app/app";
import filtersReducers from "./reducers/filters/filters";
import transactionsReducer from "./reducers/transactions/transactions";

export const store = configureStore({
  devTools: process.env.NEXT_PUBLIC_ENV_NAME !== "primary",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    app: appReducer,
    filters: filtersReducers,
    transactions: transactionsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
