import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const checkoutStepperStyles = createNamedStyles({
  connectorStepperDesktop: {
    "& span": {
      height: "70px",
    },
    marginTop: "5px",
  },
  connectorStepperMobile: {},
  labelStepper: {
    ".Mui-disabled": {
      color: "#6D7781",
    },
    ".MuiStepLabel-label": {
      fontWeight: "400",
    },
  },
  stepperDesktop: {
    height: "40%",
    padding: "20px 0px 0px 0px",
  },
  stepperMobile: {
    padding: "20px 0px 0px 0px",
  },
});
