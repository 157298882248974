import React, { FC } from "react";
import { IInputDateProps } from "@components/InputsForm/Inputs/InputDate/InputDate.interfaces";
import { Datepicker } from "@kushki/connect-ui";
import { Box } from "@mui/material";
import { inputSelectStyles as styles } from "@components/InputsForm/Inputs/InputSelect/InputSelect.styles";
import { DateFormatEnum, defaultDate } from "@shared/constants/parseMonths";
import { validateDate } from "@shared/utils/filterUtils";
import { get } from "lodash";

const InputDate: FC<IInputDateProps> = ({
  field,
  label,
  error,
}: IInputDateProps) => {
  return (
    <Box sx={styles.input}>
      <Datepicker
        disableFuture={true}
        placeholder={label}
        isOpen={false}
        dateFormat={DateFormatEnum.DD_MMM_YYYY}
        onDateChange={(formattedDate) => {
          field.onChange(formattedDate);
        }}
        defaultValue={validateDate(get(field, "value", defaultDate))}
        error={!!error}
        helperText={error?.message}
        onlySelect={true}
      />
    </Box>
  );
};

export default InputDate;
