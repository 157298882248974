import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INodeSelectionState } from "@store/interfaces/NodeSelectionState.interfaces";
import { EntityName } from "@shared/enum/entityNameEnum";
import { ModeEnum } from "@shared/enum/modeEnum";
import {
  queryMerchants,
  searchMerchants,
} from "@store/thunks/nodeSelection/nodeSelection.thunk";
import { get } from "lodash";
import { INodeRuleSessionStorage } from "@shared/interfaces/INodeRuleSessionStorage";
import { SearchMerchantsResponse } from "../../../../types/search_merchants_response";

export const nodeSelectionState: INodeSelectionState = {
  actualView: EntityName.OWNER,
  filterSearchData: {
    data: [],
    total: 0,
  },
  isLoadingTable: false,
  isSearchLoading: false,
  mode: ModeEnum.SEARCH,
  nodeType: EntityName.OWNER,
  searchNode: "",
  searchResultTitle: { entityName: "", isSuccess: false, total: 0 },
  selectedNodes: [],
  viewData: {
    data: [],
  },
};

export const nodeSelectionSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(searchMerchants.fulfilled, (state, action) => {
        const aux_data = get(action, "payload.data", { data: [] });

        switch (action.payload.type) {
          case "create":
            state.viewData = aux_data;
            state.searchResultTitle = {
              entityName: state.actualView,
              isSuccess: true,
              total: get(action, "payload.data.total", 0),
            };
            if (aux_data.data?.length == 0) {
              state.isLoadingTable = false;
            }
            break;
          case "searchFilter":
            state.isSearchLoading = false;
            state.filterSearchData = aux_data;
            break;
        }
      })
      .addCase(searchMerchants.pending, (state) => {
        state.isLoadingTable = true;
        state.viewData = { data: [] };
        state.searchResultTitle = {
          entityName: "",
          isSuccess: false,
          total: 0,
        };
      })
      .addCase(queryMerchants.pending, (state) => {
        state.isLoadingTable = true;
      })
      .addCase(queryMerchants.fulfilled, (state, action) => {
        state.isLoadingTable = false;
        state.viewData = action.payload;
      });
  },
  initialState: nodeSelectionState,
  name: "nodeSelection",
  reducers: {
    setActualView: (state, { payload }: PayloadAction<string>) => {
      state.actualView = payload;
    },
    setIsLoadingTable: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingTable = payload;
    },
    setIsSearchLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isSearchLoading = payload;
    },
    setMode: (state, { payload }: PayloadAction<string>) => {
      state.mode = payload;
    },
    setNodeType: (state, { payload }: PayloadAction<string>) => {
      state.nodeType = payload;
    },
    setSearchNode: (state, { payload }: PayloadAction<string>) => {
      state.searchNode = payload;
    },
    setSelectedNodes: (
      state,
      { payload }: PayloadAction<INodeRuleSessionStorage[]>
    ) => {
      state.selectedNodes = payload;
    },
    setViewData: (
      state,
      { payload }: PayloadAction<SearchMerchantsResponse>
    ) => {
      state.viewData = payload;
    },
  },
});

export default nodeSelectionSlice.reducer;

export const {
  setSelectedNodes,
  setNodeType,
  setSearchNode,
  setActualView,
  setViewData,
  setMode,
  setIsLoadingTable,
  setIsSearchLoading,
} = nodeSelectionSlice.actions;
