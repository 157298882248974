import { DeepMap, FieldError } from "react-hook-form";
import { SearchRuleResponse } from "../../types/search_rule_response";
import _, { defaultTo, get, isUndefined } from "lodash";
import {
  ConfigurationCodeEnum,
  EntityNameEnum,
} from "../shared/infrastructure/enums/NodeInfoEnum";
import {
  Configs,
  GetNodeInfoResponse,
} from "../../types/get_node_info_response";
import { NodeInfoPathEnum } from "../shared/infrastructure/enums/NodeInfoPathEnum";
import { RegexExpressionEnum } from "../shared/infrastructure/enums/RegexExpressionEnum";

export const retrieveFormError = (
  errors: DeepMap<Record<string, any>, FieldError>,
  pathProperty: string
): [boolean, string] => {
  const isFormError: boolean = !isUndefined(get(errors, pathProperty));
  return [
    isFormError,
    isFormError ? get(errors, `${pathProperty}.message`) : "",
  ];
};

export const isOperatorEqualOrDifferent = (operator: string): boolean => {
  return ["=", "!="].includes(operator);
};

export const isFormEditMode = (ruleId: string | undefined): boolean => {
  return !isUndefined(ruleId);
};

export function deleteDuplicatedElements<T>(array: T[]): T[] {
  return array.reduce((acc: T[], cur: T) => {
    if (!acc.includes(cur)) {
      acc.push(cur);
    }
    return acc;
  }, []);
}

export const fromAnyToSearchRuleResponse = (value: any): SearchRuleResponse => {
  const data = get(value, "items", []);
  const searchRuleResponse: SearchRuleResponse = {
    data: data,
    total: data.length,
  };
  return searchRuleResponse;
};
export function isCentralizedBranch() {
  const basicMerchantInformation = JSON.parse(
    defaultTo(localStorage.getItem("basicMerchantInformation"), "{}")
  );
  const isCentralizedNode = defaultTo(
    basicMerchantInformation.isCentralized,
    false
  );
  const isCustomerNode =
    defaultTo(basicMerchantInformation.entityName, "") !==
    EntityNameEnum.CUSTOMER;
  return isCentralizedNode && isCustomerNode;
}

export function getCustomerId(nodeInfoMerchant: GetNodeInfoResponse) {
  const configs: Configs[] = get(
    nodeInfoMerchant,
    NodeInfoPathEnum.CONFIGS,
    []
  );
  for (const config of configs) {
    if (get(config, "configuration", "") === ConfigurationCodeEnum.CN008) {
      return get(config, "value", "");
    }
  }
  return "";
}

export const camelize = (obj: any) =>
  _.transform(obj, (acc: any, value, key: any, target) => {
    const camelKey = _.isArray(target) ? key : _.camelCase(key);
    acc[camelKey] = _.isObject(value) ? camelize(value) : value;
  });

export function isAmex(value: string): boolean {
  return value.startsWith("37") || value.startsWith("34");
}

export function maskCard(cardNumber: string): string {
  const isCardAmex: boolean = isAmex(cardNumber);
  if (isCardAmex) cardNumber = cardNumber.substring(0, 15);
  const pattern: RegExp = new RegExp(RegexExpressionEnum.CARD_BRAND_PATTERN);

  return cardNumber.replace(
    pattern,
    (_, firstNumbers, maskedNumbers, lastNumbers) =>
      `${firstNumbers}${"X".repeat(maskedNumbers.length)}${lastNumbers}`
  );
}
