import { CustomStyles } from "../../shared/infrastructure/interfaces/CustomStyles";

export const boardMembersStyles: CustomStyles = {
  container: {
    marginTop: "4rem",
  },
  text: {
    lineHeight: "180%",
    mr: "4.5rem",
  },
  registeredBoardMembers: {
    left: "1.5rem",
  },

  showAllWapper: {
    display: "grid",
    placeItems: "center",
  },

  showAll: {
    fontSize: "1rem",
    marginTop: "2rem",
    textAlign: "center",
    textDecoration: "underline",
    cursor: "pointer",
  },
};
