import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { FC } from "react";

type FallbackDevSectionProps = {
  sectionName: string;
  onClickHandler: () => void;
};

const useStyles = makeStyles({
  label: {
    marginBottom: "10px",
  },
  button: {
    width: "220px",
  },
});

export const FallbackDevSection: FC<FallbackDevSectionProps> = (props) => {
  const { sectionName, onClickHandler } = props;
  const classes = useStyles();

  return (
    <Grid container direction="column" style={{ padding: "35px" }}>
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        className={classes.label}
      >
        <Typography align="center" variant="h5">
          Aún no has configurado tus
        </Typography>
        <Typography align="center" variant="h5">
          {sectionName}
        </Typography>
      </Grid>
      <Grid item container direction="row" justify="center">
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onClickHandler}
        >
          <Typography variant="h6">{`Configurar ${sectionName}`}</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};
