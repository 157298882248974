import { createTheme } from "@mui/material/styles";
import themeOptions from "./thememui";

const theme = createTheme({
  ...themeOptions,
  breakpoints: {
    ...themeOptions.breakpoints,
  },
  components: {
    ...themeOptions.components,
  },
  palette: {
    ...themeOptions.palette,
  },
  typography: {
    ...themeOptions.typography,
  },
});

export default theme;
