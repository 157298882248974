import React from "react";
import {
  createStyles,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

export interface PaginationTableProps {
  total: number;
  limit: number;
  page: number;
  isMobile: boolean;
  trxPerPage: number;
  handleChangePage: (value: number) => void;
  handleChangePageSize: (newPageSize: number) => void;
  size?: "small" | "medium" | "large";
  siblingCount?: number;
}
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: "8px 27px 8px 20px",
      backgroundColor: "#FFFFFF",
    },
    buttonIconPagination: {
      textTransform: "none",
    },
    marginButton: {
      marginTop: -6,
      display: "flex",
    },
    itemTotalCount: {
      marginTop: -6,
      display: "flex",
      marginLeft: "30px",
      marginRight: "30px",
    },
    typographyLabelPagination: {
      marginTop: "auto",
      marginBottom: "auto",
      marginRight: 11,
    },
  })
);
export const PaginationTableV2: React.FC<PaginationTableProps> = (
  props: PaginationTableProps
) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid container>
          <Grid item className={classes.marginButton}>
            <Typography
              color={"textSecondary"}
              variant={"body2"}
              className={classes.typographyLabelPagination}
            >
              Solicitudes por página
            </Typography>
            <Select
              id={"page-size"}
              style={{ margin: "0 15px" }}
              disableUnderline
              value={props.limit}
              onChange={(e) =>
                props.handleChangePageSize(Number(e.target.value))
              }
            >
              <MenuItem value={5}>
                <Typography variant={"body2"} color={"textSecondary"}>
                  5
                </Typography>
              </MenuItem>
              <MenuItem value={10}>
                <Typography variant={"body2"} color={"textSecondary"}>
                  10
                </Typography>
              </MenuItem>
              <MenuItem value={15}>
                <Typography variant={"body2"} color={"textSecondary"}>
                  15
                </Typography>
              </MenuItem>
              <MenuItem value={20}>
                <Typography variant={"body2"} color={"textSecondary"}>
                  20
                </Typography>
              </MenuItem>
              <MenuItem value={25}>
                <Typography variant={"body2"} color={"textSecondary"}>
                  25
                </Typography>
              </MenuItem>
            </Select>
          </Grid>
          <Grid className={classes.itemTotalCount}>
            <Typography
              color={"textSecondary"}
              variant={"body2"}
              className={classes.typographyLabelPagination}
            >
              {`${(props.page - 1) * props.limit + 1}-${
                props.page === Math.ceil(Number(props.total / props.limit))
                  ? props.total
                  : (props.page - 1) * props.limit + props.trxPerPage
              } of ${props.total}`}
            </Typography>
          </Grid>
          <Grid
            item
            xl={8}
            lg={8}
            md={5}
            sm={5}
            xs={5}
            container
            justify="flex-end"
            alignItems="center"
            direction="row"
          >
            <Pagination
              count={Math.ceil(Number(props.total / props.limit))}
              onChange={(_e, value: number) => props.handleChangePage(value)}
              page={props.page}
              size={props.size}
              siblingCount={props.siblingCount}
              color={"primary"}
            />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};
