import React from "react";
import { createStyles, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export interface CardBlueProps {
  title?: string;
  subTitle?: string;
  items: CardBlueItem[];
}

interface CardBlueItem {
  key: string;
  value: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      boxShadow: "none !important",
      borderRadius: "5px !important",
      backgroundColor: "#F7FAFC !important",
      marginBottom: "20px !important",
    },
  })
);

export const CardBlue: React.FC<CardBlueProps> = (props: CardBlueProps) => {
  const classes = useStyles();
  return (
    <>
      <Paper className={classes.paper}>
        <Grid
          container
          spacing={2}
          color="secondary"
          style={{
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 15,
          }}
        >
          {props.title && (
            <Grid item xs={12} spacing={2} style={{ paddingBottom: 1 }}>
              <Typography component={"div"} variant="h5" color="textPrimary">
                {props.title}
              </Typography>
            </Grid>
          )}
          {props.subTitle && (
            <Grid item xs={12} spacing={2} style={{ paddingTop: 0 }}>
              <Typography
                component={"div"}
                variant="subtitle1"
                color="textSecondary"
              >
                {props.subTitle}
              </Typography>
            </Grid>
          )}
          {props.items.map((item, index) => (
            <>
              <Grid
                item
                xs={5}
                spacing={2}
                style={{ paddingBottom: 0 }}
                key={"item-grid-" + item.key}
              >
                <Typography
                  variant="body2"
                  color="textSecondary"
                  key={"item-typo-" + item.key}
                >
                  {item.key}:
                </Typography>
              </Grid>
              <Grid
                item
                xs={7}
                spacing={2}
                style={{ paddingBottom: 0 }}
                key={"item-grid-2-" + item.key}
              >
                <Typography variant="body2" key={"item-typo-2-" + item.key}>
                  {item.value}
                </Typography>
              </Grid>
            </>
          ))}
        </Grid>
      </Paper>
    </>
  );
};
