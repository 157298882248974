import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const transactionSectionStyles = createNamedStyles({
  subtitle: {
    p: (theme) => theme.spacing(2, 0, 0.75, 0),
    color: "text.dark",
  },
  title: {
    color: "text.dark",
  },
  boxContainer: {
    display: "flex",
    justifyContent: "space-between",
    height: (theme) => theme.spacing(12.875),
  },
  optionsContainer: { marginTop: "40px" },
});
