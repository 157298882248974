import React, { FC } from "react";
import { Box, MenuItem, Select, Skeleton, Typography } from "@mui/material";
import { transactionsTableLimitSelectStyles } from "./TransactionsTableLimitSelect.styles";
import { TRANSACTIONS_PER_PAGE_LIMITS } from "../../../../shared/constants";
import { useTransactionsTableLimitSelectState } from "./state/useTransactionsTableLimitSelectState";

const TransactionsTableLimitSelect: FC = () => {
  const {
    areTransactionsLoading,
    handleSetTransactionsLimit,
    limit,
    pagesRange,
    total,
  } = useTransactionsTableLimitSelectState();

  return (
    <Box sx={transactionsTableLimitSelectStyles.container}>
      <Typography sx={transactionsTableLimitSelectStyles.label}>
        Transacciones por página
      </Typography>
      <Select
        disabled={areTransactionsLoading}
        MenuProps={{
          sx: transactionsTableLimitSelectStyles.menu,
        }}
        onChange={(e) => handleSetTransactionsLimit(e.target.value as number)}
        sx={transactionsTableLimitSelectStyles.select}
        value={limit}
      >
        {TRANSACTIONS_PER_PAGE_LIMITS.map((limit, index) => (
          <MenuItem
            disabled={limit > total}
            key={index}
            sx={transactionsTableLimitSelectStyles.menuItem}
            value={limit}
          >
            {limit}
          </MenuItem>
        ))}
      </Select>
      <Typography sx={transactionsTableLimitSelectStyles.label}>
        {areTransactionsLoading ? (
          <Skeleton width={80} />
        ) : (
          <>{total > 0 && `${pagesRange} de ${total}`}</>
        )}
      </Typography>
    </Box>
  );
};

export default TransactionsTableLimitSelect;
