import {
  ActionHistoricEnum,
  ActionResponseEnum,
  RequestActionEnum,
} from "../enums/ActionEnum";
import { ProcessoNameEnum, ProcessorEnum } from "../enums/ProcessorEnum";
import { CountryEnum } from "../enums/CountryEnum";
import { CurrencyCountry } from "../catalogs/CurrencyCatalog";
import { CurrencyEnum } from "../enums/CurrencyEnum";
import { FilterTypeEnum } from "../enums/FilterTypeEnum";
import { IOptionFilter } from "../../../../types/option_filter";
import { TransactionStatusEnum } from "../enums/TransactionStatusEnum";

export const HistoryFilters: IOptionFilter[] = [
  {
    countries: [CountryEnum.mexico],
    id: FilterTypeEnum.processorName,
    options: [
      {
        key: ProcessoNameEnum.billpocket,
        label: ProcessorEnum.billpocket,
        selected: false,
      },
      {
        key: ProcessoNameEnum.elavon,
        label: ProcessorEnum.elavon,
        selected: false,
      },
      {
        key: ProcessoNameEnum.prosa,
        label: ProcessorEnum.prosa,
        selected: false,
      },
      {
        key: ProcessoNameEnum.kushki,
        label: ProcessorEnum.kushki,
        selected: false,
      },
      {
        key: ProcessoNameEnum.amex,
        label: ProcessorEnum.amex,
        selected: false,
      },
    ],
    title: "Procesadores",
  },
  {
    countries: [CountryEnum.mexico],
    id: FilterTypeEnum.transactionType,
    options: [
      {
        key: "sale",
        label: TransactionStatusEnum.sale,
        selected: false,
      },
      {
        key: "refund",
        label: TransactionStatusEnum.refund,
        selected: false,
      },
    ],
    title: "Tipo de la Transacción",
  },
  {
    countries: [CountryEnum.mexico],
    id: FilterTypeEnum.actionResponse,
    options: [
      {
        key: "APRRFN",
        label: ActionResponseEnum.refundApprove,
        primaryKey: RequestActionEnum.APRRFN,
        secondaryKey: ActionHistoricEnum.approved_by_processor,
        selected: false,
      },
      {
        key: "DCLRFN",
        label: ActionResponseEnum.refundDeclined,
        primaryKey: RequestActionEnum.APRRFN,
        secondaryKey: ActionHistoricEnum.declined_by_processor,
        selected: false,
      },
      {
        key: "SNDRFN",
        label: ActionResponseEnum.refundSend,
        primaryKey: RequestActionEnum.APRRFN,
        secondaryKey: ActionHistoricEnum.send_to_processor,
        selected: false,
      },
      {
        key: "APRGBCK",
        label: ActionResponseEnum.givebackApprove,
        primaryKey: RequestActionEnum.APRGBCK,
        secondaryKey: ActionHistoricEnum.approved_fin_ops,
        selected: false,
      },
      {
        key: "DCLGBCK",
        label: ActionResponseEnum.givebackDeclined,
        primaryKey: RequestActionEnum.APRGBCK,
        secondaryKey: ActionHistoricEnum.declined_fin_ops,
        selected: false,
      },
      {
        key: "SNDGBCK",
        label: ActionResponseEnum.givebackSend,
        primaryKey: RequestActionEnum.APRGBCK,
        secondaryKey: ActionHistoricEnum.send_by_fin_ops,
        selected: false,
      },
      {
        key: "APRCNL",
        label: ActionResponseEnum.cancelApprove,
        primaryKey: RequestActionEnum.APRCNL,
        secondaryKey: ActionHistoricEnum.approved_fin_ops,
        selected: false,
      },
      {
        key: "DCLCNL",
        label: ActionResponseEnum.cancelDeclined,
        primaryKey: RequestActionEnum.APRCNL,
        secondaryKey: ActionHistoricEnum.declined_fin_ops,
        selected: false,
      },
      {
        key: "SNDCNL",
        label: ActionResponseEnum.cancelSend,
        primaryKey: RequestActionEnum.APRCNL,
        secondaryKey: ActionHistoricEnum.send_by_fin_ops,
        selected: false,
      },
      {
        key: "OMT",
        label: ActionResponseEnum.omit,
        primaryKey: RequestActionEnum.APROMT,
        secondaryKey: ActionHistoricEnum.notDefined,
        selected: false,
      },
    ],
    primaryId: FilterTypeEnum.response,
    title: "Acción y respuesta",
  },
  {
    countries: [CountryEnum.mexico],
    id: FilterTypeEnum.currency,
    options: [
      {
        key: CurrencyEnum.MXN,
        label: CurrencyEnum.MXN,
        selected: false,
      },
    ],
    title: "Moneda",
  },
];

export const getCurrencyByCountryHistory = (
  filters: IOptionFilter[],
  country: CountryEnum
): IOptionFilter[] => {
  filters[3].countries = [country];
  filters[3].options = CurrencyCountry[country];

  return [...filters];
};
