import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const modalErrorBranchesStyles = createNamedStyles({
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "1rem",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#ffffff",
    borderRadius: "1rem",
    boxShadow: 24,
    boxSizing: "border-box",
    padding: "2rem",
    width: "500px",
    height: "500px",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    overflowY: "auto",
    backgroundColor: "#f0f4f9",
    height: "100%",
    padding: "1.5rem",
    borderRadius: "1rem",
  },
  title: {
    color: "#0d3365",
    fontSize: "22px",
  },
  label: {
    color: "#293036",
    display: "inline",
  },
  divider: {
    borderWidth: 0,
    borderStyle: "solid",
    borderColor: "rgba(13,51,101, 0.12)",
    borderBottomWidth: "thin",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  closeBtn: {
    boxShadow: 1,
    width: "30px",
    height: "30px",
  },
});
