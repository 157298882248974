export enum ROUTES {
  CONSOLE_WALLET = "/console-wallet",
  CONSOLE_WALLET_DASHBOARD = "/console-wallet-dashboard",
  CLIENTS = "/clients/",
  MERCHANT_LIST = "/merchant-list",
  CREATE_NODE_BRANCH_OPTIONS = "/create-node/branch/options",
  CREATE_NODE_BRANCH_BATCH = "/create-node/branch/batch",
  CREATE_NODE_BRANCH_EDIT = "/create-node/branch/edit",
  CREATE_NODE_BRANCH_FILE_UPLOAD = "/create-node/branch/upload-file",
  CREATE_NODE_OWNER = "/create-node/owner",
  CREATE_NODE_CUSTOMER = "/create-node/customer",
  CREATE_NODE_BRANCH_SELECT_EDITION = "/create-node/branch/selection",
  MERCHANT_PREFERENCES = "/merchant-preferences",
  MERCHANT_RESUME = "/merchant-resume",
  CREATE_MERCHANT_WEBHOOK = "/merchant-webhook/create",
  LIST_MERCHANT_WEBHOOK = "/merchant-webhook/webhook",
  LIST_NOTIFICATION_WEBHOOK = "/merchant-webhook/notifications",
  USER_USERS = "/users/user/",
  USERS = "users",
}
export const MERCHANT_RESUME_CUSTOMER = (merchantId: string) => {
  return (
    ROUTES.MERCHANT_RESUME + `/?publicMerchantId=${merchantId}&mode=edition`
  );
};
export const OWNER_MERCHANT_LIST_CUSTOMER = (rootId: string) => {
  return ROUTES.CLIENTS + `${rootId}`;
};

export const BRANCH_LIST_OF_CUSTOMER = (rootId: string, nodeId: string) =>
  `/clients/${rootId}/customers/${nodeId}`;
