import React, { ChangeEvent } from "react";
import { Box } from "@mui/material";
import {
  ModalContent,
  ModalContentButtonTypeEnum,
  ModalContentTypeEnum,
  TextArea,
  TextFieldEmail,
} from "@kushki/connect-ui";
import { Typography } from "@mui/material";
import { useModalInvestigationStyles as styles } from "./ModalInvestigation.styles";
import UserAddIcon from "../../assets/icons/UserAddIcon";
import { ItemEmail } from "@kushki/connect-ui/dist/Components/Molecules/Form/TextFieldEmail/TextFieldEmail.interfaces";
import { Alarm } from "../../../types/alarm";
import { alarmsStyles } from "@containers/Alarms/Alarms.styles";
import { ModalInvestigationLabels } from "@shared/constants/labels/modalInvestigationLabels";
import {
  CloseModalLabels,
  FileUploadLabels,
} from "@shared/constants/labels/fileUploadLabels";
import FileUploadContainer from "@components/FileUploadContainer/FileUploadContainer";
import { defaultTo } from "lodash";

export interface IModalInvestigation {
  onClose: () => void;
  open: boolean;
  merchantData: Alarm;
  handleEmails: (newEmail: ItemEmail[]) => void;
  emailsArray: ItemEmail[];
  disabledButtonInvestigation: boolean;
  handleInvestigation: () => void;
  handleAlarmInfoChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleAdditionalInfoChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ModalInvestigation: React.FC<IModalInvestigation> = ({
  onClose,
  open,
  emailsArray,
  merchantData,
  handleEmails,
  handleInvestigation,
  handleAlarmInfoChange,
  handleAdditionalInfoChange,
  disabledButtonInvestigation,
}: IModalInvestigation) => {
  const { merchantName, commercialExecutive } = merchantData;

  return (
    <ModalContent
      onClose={onClose}
      isOpen={open}
      type={ModalContentTypeEnum.DEFAULT}
      buttonType={ModalContentButtonTypeEnum.PRIMARY}
      buttonAction={handleInvestigation}
      descriptionText={""}
      titleText={"¿Deseas iniciar una investigación al comercio?"}
      buttonSecondaryText={CloseModalLabels.CANCEL}
      buttonText={"Iniciar Investigación"}
      buttonPrimaryDisabled={disabledButtonInvestigation}
    >
      <Box sx={styles.content}>
        <Box sx={styles.contentWrapper}>
          <Box>
            <Typography variant={"body2"} sx={styles.textSpacing}>
              Se enviará la siguiente notificación al Ejecutivo Comercial a
              cargo de {defaultTo(merchantName, "")} para que realice las
              investigaciones necesarias y verifique las operaciones.
            </Typography>
            <Typography variant={"body2"} sx={styles.textSpacing}>
              Puedes modificar el texto de la notificación:
            </Typography>
            <Typography variant={"body2"} sx={styles.textSpacing}>
              Hola {defaultTo(commercialExecutive, "")} espero estes muy bien,
              Como resultado de la ejecución del proceso de monitoreo AML sobre
              las transacciones procesadas por el comercio{" "}
              {defaultTo(merchantName, "")}, se han identificado transacciones
              que se encuentran fuera de un comportamiento normal, y por tanto
              el comercio ha sido clasificado dentro de un nivel de riesgo alto
              para la compañía.
            </Typography>
          </Box>
          <Box sx={alarmsStyles.commentsBox}>
            <Typography sx={styles.textMargin} variant={"body2"}>
              {ModalInvestigationLabels.ALARM_INFO}
            </Typography>
            <Box sx={styles.textAreaInfo}>
              <TextArea
                onChange={handleAlarmInfoChange}
                height={150}
                maxCounter={500}
                name="comment"
                placeholder={ModalInvestigationLabels.ALARM_INFO_PLACEHOLDER}
                ref={React.createRef()}
              />
            </Box>
          </Box>
          <Box>
            <Typography variant={"body2"} sx={styles.textSpacing}>
              Por lo anterior, se requiere de tu gestión para conocer los
              procesos del comercio frente a este tipo de operaciones
              consideradas de mayor valor, así como las reglas establecidas de
              monitoreo para estas transacciones (Si aplica)
            </Typography>
          </Box>
          <Box my={3}>
            <FileUploadContainer maxSize={+FileUploadLabels.SIZE_LIMIT} />
          </Box>
          <Box sx={alarmsStyles.commentsBox}>
            <Box sx={styles.textArea}>
              <TextArea
                onChange={handleAdditionalInfoChange}
                height={300}
                maxCounter={2000}
                placeholder={""}
                name="comment"
                value={ModalInvestigationLabels.ADDITIONAL_INFO_PLACEHOLDER}
                ref={React.createRef()}
              />
            </Box>
          </Box>
          <Box my={3}>
            <Typography variant={"caption"}>
              {ModalInvestigationLabels.NOTE_INFO}
            </Typography>
          </Box>
          <Box>
            <Typography variant={"body2"} sx={styles.textSpacing}>
              Agrega uno o más correos para enviar una copia de la notificación:
            </Typography>
            <Box sx={styles.emailContainer}>
              <UserAddIcon />
              <TextFieldEmail
                placeholder={"Correo Electrónico"}
                items={emailsArray}
                onItemsChange={handleEmails}
              ></TextFieldEmail>
            </Box>
          </Box>
        </Box>
      </Box>
    </ModalContent>
  );
};

export default ModalInvestigation;
