/**
 *
 */
export enum TransactionType {
  DECLINED = "DECLINED",
  DEFERRED = "DEFFERED",
  CHARGEBACK = "CHARGEBACK",
  SALE = "SALE",
  VOID = "VOID",
  REFUND = "REFUND",
  CAPTURE = "CAPTURE",
  PREAUTHORIZATION = "PREAUTHORIZATION",
  REFUND_TOTAL = "REFUND TOTAL",
  REFUND_PARTIAL = "REFUND PARTIAL",
  VOID_PARTIAL = "VOID PARTIAL",
  REVERSE = "REVERSE",
}
