import React from "react";
import { Chip, ChipProps, Tooltip } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { TransactionStatusEnum } from "../../shared/infrastructure/constants/transaction-status-enum";

export interface ChipTransactionsStatusProps extends ChipProps {
  status: string | undefined;
  message: string;
  disableHover: boolean;
  text: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    borderRadius: "4px !important",
    padding: "2px !important",
    background: (props: ChipTransactionsStatusProps) => {
      switch (props.status) {
        case TransactionStatusEnum.DECLINED:
          return theme.palette.error.light + " !important";
        case TransactionStatusEnum.APPROVAL:
          return theme.palette.success.light + " !important";
        case TransactionStatusEnum.INITIALIZED:
          return theme.palette.grey["100"] + " !important";
        case TransactionStatusEnum.PROCESS:
          return theme.palette.warning.light + " !important";
        default:
          return theme.palette.grey["100"] + " !important";
      }
    },
    color: (props: ChipTransactionsStatusProps) => {
      switch (props.status) {
        case TransactionStatusEnum.DECLINED:
          return theme.palette.error.dark + " !important";
        case TransactionStatusEnum.APPROVAL:
          return theme.palette.success.dark + " !important";
        case TransactionStatusEnum.INITIALIZED:
          return theme.palette.grey.A700 + " !important";
        case TransactionStatusEnum.PROCESS:
          return theme.palette.warning.dark + " !important";
        default:
          return theme.palette.grey.A700 + " !important";
      }
    },
  },
}));

export const ChipTransactionsStatus: React.FC<ChipTransactionsStatusProps> = (
  props: ChipTransactionsStatusProps
) => {
  const classes = useStyles(props);
  return (
    <React.Fragment>
      <Tooltip disableHoverListener={props.disableHover} title={props.message}>
        <Chip label={props.text} className={classes.chip} {...props} />
      </Tooltip>
    </React.Fragment>
  );
};
