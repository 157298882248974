import React, { FC } from "react";
import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { useWebhookEventsCheckList } from "./state/useWebhookEventsCheckList";
import { PAYMENT_METHOD_LABELS } from "../../shared/constants/enums/PAYMENT_METHOD_LABEL";
import {
  OTHER_EVENTS,
  OTHER_OPTIONS,
  PAYIN_EVENTS,
  PAYIN_OPTIONS,
  PAYOUTS_EVENTS,
  PAYOUTS_OPTIONS,
  PRODUCT_EVENTS,
  PRODUCT_OPTIONS,
} from "./constants";
import { EventCheckListRenderer } from "./EventCheckListRenderer/EventCheckListRenderer";
import { ModalSimple } from "@kushki/connect-ui";
import { FormHelperText } from "@mui/material";
import { get } from "lodash";

export const WebhookEventsCheckList: FC = () => {
  const {
    options,
    handler: {
      handleStatus,
      handleCheck,
      handleSelectAllEvents,
      handleAreAllEventsSelected,
      hasEvents,
    },
    modalPreath,
    errors,
  } = useWebhookEventsCheckList();

  if (options.length > 0)
    return (
      <>
        <ModalSimple
          typeModal={1}
          onClose={console.log}
          isOpen={modalPreath.openModalPreauth}
          onClickPrimary={modalPreath.clearEventsExceptPreauth}
          buttonPrimary="Si, confirmar"
          onClickSecondary={modalPreath.handleCloseModal}
          buttonSecondary="Cancelar"
          titleText="Confirmación de activación de preautorización"
          descriptionText={
            (
              <>
                <span>
                  Ten en cuenta que al utilizar el evento de preautorización se
                  desactivan otros eventos y medios de pago seleccionados,
                  debido a que tiene una configuración diferente.
                </span>
                <br />
                <br />
                <span>¿Deseas configurar preautorización?</span>
              </>
            ) as unknown as string
          }
        />

        <Typography variant="body1" color="primary">
          Eventos
        </Typography>

        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              onChange={handleSelectAllEvents}
              checked={handleAreAllEventsSelected()}
            />
          }
          label={"Seleccionar todo"}
        />
        <Grid container spacing={3} pt={2}>
          {hasEvents(PAYIN_OPTIONS) && (
            <EventCheckListRenderer
              title={PAYMENT_METHOD_LABELS.PAYIN}
              optionsList={Object.keys(PAYIN_OPTIONS)}
              events={PAYIN_EVENTS}
              options={options}
              handleStatus={handleStatus}
              handleCheck={handleCheck}
            />
          )}

          {hasEvents(PAYOUTS_OPTIONS) && (
            <EventCheckListRenderer
              title={PAYMENT_METHOD_LABELS.PAYOUTS}
              optionsList={Object.keys(PAYOUTS_OPTIONS)}
              events={PAYOUTS_EVENTS}
              options={options}
              handleStatus={handleStatus}
              handleCheck={handleCheck}
            />
          )}

          {hasEvents(PRODUCT_OPTIONS) && (
            <EventCheckListRenderer
              title={PAYMENT_METHOD_LABELS.PRODUCT}
              optionsList={Object.keys(PRODUCT_OPTIONS)}
              events={PRODUCT_EVENTS}
              options={options}
              handleStatus={handleStatus}
              handleCheck={handleCheck}
            />
          )}

          {hasEvents(OTHER_OPTIONS) && (
            <EventCheckListRenderer
              title={PAYMENT_METHOD_LABELS.OTHER}
              optionsList={Object.keys(OTHER_OPTIONS)}
              events={OTHER_EVENTS}
              options={options}
              handleStatus={handleStatus}
              handleCheck={handleCheck}
            />
          )}
        </Grid>

        {errors["events"] && (
          <FormHelperText error>{get(errors, "events.message")}</FormHelperText>
        )}
      </>
    );

  return null;
};
