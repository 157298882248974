import { appSlice } from "../reducers/app/app";

export const {
  resetTransactionList,
  setAllBranchColumn,
  setBranchColumn,
  deleteBranchColumn,
  setIsLoadingDownload,
  setWatchFieldName,
  setClickCount,
} = appSlice.actions;
