import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const variablesWrapperStyles = createNamedStyles({
  container: {
    marginBottom: "30px",
  },
  fieldContainer: {
    display: "flex",
    gap: "30px",
    marginTop: "30px",
  },
  deleteButton: {
    flex: "1 1 100%",
  },
  wrapper: {
    flex: 1,
    display: "flex",
  },
});
