import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const mainContainerStyles = createNamedStyles({
  boxContainer: {
    width: 1,
    height: "100%",
    display: "flex",
  },
  containerBody: {
    display: "flex",
    flexDirection: "column",
    rowGap: (theme) => theme.spacing(5),
    pt: 5,
    pl: 11.75,
    backgroundColor: "background.paper",
  },
  containerForm: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "150px",
  },
  boxFooter: {
    zIndex: "1000",
    width: "100%",
    position: "fixed",
    bottom: "0",
  },
  boxSidebar: {
    width: {
      md: "10%",
      sm: "30%",
    },
    height: 1,
  },
  box: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
  },
  boxHeader: {
    padding: "15px",
    width: 1,
    heigth: {
      md: "10%",
      sm: "30%",
    },
    display: "flex",
    flexDirection: {
      sm: "column",
    },
  },
  boxContent: {
    height: "90%",
  },
  menuItemContent: {
    borderRight: "1px solid #CBD5E0",
  },
  menuItemDivider: {
    borderBottom: "2px solid #E2E8F0",
  },
});
