export enum ActionTypes {
  SET_AUTH_INFO = "[SUPPORT] SET AUTH INFO",
  SET_NOTIFICATION = "[SUPPORT] SET_NOTIFICATION",
  SET_ACCESS_CODE = "[SUPPORT] SET_ACCESS_CODE",
  SET_VERIFY_ACCESS_CODE_MERCHANT = "[SUPPORT] SET_VERIFY_ACCESS_CODE_MERCHANT",
  SET_CONNECTION_STATUS = "[SUPPORT] SET_CONNECTION_STATUS",
  SET_HISTORY_DATA = "[SUPPORT] SET_HISTORY_DATA",
  SET_MERCHANTS = "[SUPPORT] SET_MERCHANTS",
  SET_REDIRECT_MODAL = "[SUPPORT] SET_REDIRECT_MODAL",
  SET_IS_LOADING = "[SUPPORT] SET_IS_LOADING",
}
