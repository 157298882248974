import React from "react";
import { Box, Divider, Grid } from "@mui/material";
import { InformationTabStyles } from "./InformationTab.styles";
import ConciliationCustomerInfo from "./components/ConciliationCustomerInfo/ConciliationCustomerInfo";
import InformationRow from "./components/InformationRow/InformationRow";
import { InformationTabProps } from "./InformationTab.interfaces";
import ConciliationProcessorInfo from "./components/ConciliationProcessorInfo/ConciliationProcessorInfo";
import { ObservationItemStatus } from "../../../../../store/interfaces/TransactionsState.interfaces";

const InformationTab = ({ transaction }: InformationTabProps) => {
  const {
    conciliationAction,
    approvedTransactionAmount,
    issuingBank,
    currencyCode,
    cardHolderName,
    approvalCode,
    subtotalIVA,
    subtotalIva0,
    ivaValue,
    processorName,
    paymentMethod,
    responseCode,
    transactionReference,
  } = transaction;

  const showAmountIVA = (): boolean => {
    return [
      ObservationItemStatus.OMITTED,
      ObservationItemStatus.PENDING_REFUND,
      ObservationItemStatus.CONCILIATED,
    ].includes(conciliationAction as ObservationItemStatus);
  };

  return (
    <Box sx={InformationTabStyles.container}>
      <Grid container sx={InformationTabStyles.informationCard}>
        <ConciliationCustomerInfo
          customerName={cardHolderName}
          bankName={issuingBank}
          paymentMethod={paymentMethod}
          approvalCode={approvalCode}
          PaymentMethodIcon={false}
        />
      </Grid>
      <Divider sx={InformationTabStyles.divider} />
      <Grid container sx={InformationTabStyles.informationCard}>
        <InformationRow label="Subtotal IVA" value={subtotalIVA} />
        <InformationRow label="Subtotal IVA 0" value={subtotalIva0} />
        {showAmountIVA() && (
          <>
            <InformationRow label="Monto del IVA" value={ivaValue} />
            <InformationRow
              label="Monto aprobado"
              value={`${approvedTransactionAmount} ${currencyCode}`}
            />
          </>
        )}
      </Grid>
      <Divider sx={InformationTabStyles.divider} />
      <Grid container sx={InformationTabStyles.informationCard}>
        <ConciliationProcessorInfo
          description={"TEST DESCRIPCION"}
          processorName={processorName || "--"}
          responseCode={responseCode || "--"}
          transactionReference={transactionReference}
        />
      </Grid>
    </Box>
  );
};

export default InformationTab;
