export enum ButtonText {
  GO_BACK = "Regresar",
  UPDATE = "Actualizar",
}

export enum DeferredSectionsConstants {
  BASIC_INFORMATION_TITTLE = "Información básica",
  BASIC_INFORMATION_DESCRIPTION = "Ingresa los datos principales para la configuración del diferido.",
  SETTINGS_TITTLE = "Configuraciones",
  ADVANCED_SETTINGS_TITTLE = "Configuraciones avanzada",
  ADVANCED_SETTINGS_DESCRIPTION = "Configura opciones avanzadas requeridas para este diferido.",
  PROCESSOR_TITTLE = "Procesador para:",
}

export enum DeferredSettingsSectionConstants {
  QUOTAS_TITLE = "Cuotas disponibles para el comercio (Meses)",
  QUOTAS_DESCRIPTION = "Selecciona el número de cuotas que mejor se adapten a tu comercio.",
}

export enum DeferredAdvancedSettingsSectionConstants {
  DEFERRED_SCHEDULE_TITLE = "Programar diferido",
  DEFERRED_SCHEDULE_INFORMATION = "Información",
}

export enum DeferredAlertInformation {
  DEFERRED_SCHEDULE_INFORMATION_DESCRIPTION = "El diferido solo estará activo",
  DEFERRED_SCHEDULE_INFORMATION_DESCRIPTION_BOLD = "durante las fechas asignadas.",
  DEFERRED_DEFERRED_SCHEDULE_INFORMATION_ALERT = "Se aplicará el diferido desde la fecha actual.",
}

export enum DeferredSectionPlaceholder {
  SELECT_QUOTAS = "Selecciona cuotas disponibles",
}

export enum ListDialogType {
  LIST_DEFERRED_MONTHS = "listDeferredMonths",
}

export enum MerchantStatus {
  ENABLED = "enabled",
  DISABLED = "disabled",
}

export enum TableEnum {
  EMPTY = "Aún no has agregado diferidos para este comercio.",
}

export enum SortDirectionEnum {
  ASC = "asc",
  DESC = "desc",
}

export enum ListTitleDialogType {
  DEFERRED_MONTHS = "Número de cuotas (meses)",
}
