export const getBranchListFromSessionStorage = (item: string) => {
  return JSON.parse(sessionStorage.getItem(item) || "[]");
};

export const setItemInSessionStorage = (itemName: string, item: any) => {
  sessionStorage.setItem(itemName, JSON.stringify(item));
};

export const getItemFromSessionStorage = (item: string) =>
  JSON.parse(sessionStorage.getItem(item) || "{}");

export const setItemInLocalStorage = (itemName: string, item: any) => {
  localStorage.setItem(itemName, JSON.stringify(item));
};
