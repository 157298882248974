import React from "react";
import {
  Box,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import { StatusSteps } from "../../../../types/remote/semaphore_data";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { get } from "lodash";
import { RatesConfigDynamo } from "../../../../types/rates_config_dynamo";
import RateOptions from "../RateOptions/RateOptions";
import { PaymentTypeEnum } from "../../../shared/infrastructure/catalogs/PaymentTypeEnum";
import { AdditionalServiceEnum } from "../../../shared/infrastructure/catalogs/AdditionalServiceEnum";
import { PaymentMethodEnum } from "../../../shared/infrastructure/catalogs/PaymentMethodEnum";
import {
  configType,
  StatusSemaphore,
} from "../../../shared/infrastructure/constants/CreateMerchantConstants";
import { GridPaper } from "../../common/GridPaper/GridPaper";
import { IProcessorsType } from "../../../store/actionCreators";
import { Indicators } from "../Indicators/Indicators";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  paper: {
    backgroundColor: theme.palette.background.default,
  },
  head: {
    backgroundColor: "#eff6fe",
  },
  table: {
    backgroundColor: "rgba(247, 250, 252, 1)",
  },
  link: {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));
export interface IRateSectionSummary {
  publicMerchantId: string;
  rates?: RatesConfigDynamo[];
  status: StatusSteps;
  currency?:
    | "COP"
    | "USD"
    | "PEN"
    | "UF"
    | "MXN"
    | "BRL"
    | "PAB"
    | "CRC"
    | "CLP";
  processors?: IProcessorsType;
  stepConfigRatesAndInvoice?: object;
}

export const RateSectionSummary: React.FC<Partial<IRateSectionSummary>> = (
  _: Partial<IRateSectionSummary>
) => {
  const classes = useStyles();
  const stateProps: Partial<IRateSectionSummary> = useSelector(
    (state: IAppState) => ({
      publicMerchantId: state.merchantAffiliation?.publicMerchantId,
      rates: state.rates,
      status: get(
        state,
        "semaphore.stepConfigRatesAndInvoice.statusRates",
        undefined
      ),
      processors: state.processors,
      stepConfigRatesAndInvoice: get(
        state,
        "semaphore.stepConfigRatesAndInvoice",
        undefined
      ),
      currency: get(state, "rate.defaultCurrency", "USD"),
    })
  );

  const rates: RatesConfigDynamo[] = get(stateProps, "rates", []).filter(
    (_rate, idx) => idx < 5
  );
  const ratesTitle: string[] = [
    "Alias",
    "Tipo Tarifa",
    "M. de pago/Serv.",
    "Monto Fijo",
    "M. Variable",
    "M. Mínimo",
    "M. Máximo",
  ];
  const shouldDisplayResume = (status: StatusSteps) => {
    return [StatusSemaphore.inProcess, StatusSemaphore.complete].includes(
      get(StatusSemaphore, status, StatusSemaphore.pending)
    );
  };

  if (!shouldDisplayResume(stateProps.status!)) return null;

  return (
    <React.Fragment>
      <GridPaper>
        <TableContainer className={classes.table}>
          <Table aria-label="rates table">
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography variant="h6" color="textPrimary">
                    <Box fontWeight={500}>Tarifas Agregadas</Box>
                  </Typography>
                </TableCell>
                <TableCell colSpan={4}>
                  <Indicators
                    stepConfigRatesAndInvoice={
                      stateProps.stepConfigRatesAndInvoice
                    }
                    processors={stateProps.processors}
                    rates={stateProps.rates}
                    publicMerchantId={stateProps.publicMerchantId}
                  />
                </TableCell>
                <TableCell align="right">
                  <RateOptions
                    publicMerchantId={get(stateProps, "publicMerchantId", "")}
                  />
                </TableCell>
              </TableRow>
              <TableRow className={classes.head}>
                {ratesTitle.map((row: string) => (
                  <TableCell key={row}>
                    {<Box fontWeight={600}>{row}</Box>}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rates.map((row: RatesConfigDynamo) => (
                <TableRow key={row.id}>
                  <TableCell>
                    {<Box fontWeight={300}>{row.alias}</Box>}
                  </TableCell>
                  <TableCell>
                    {
                      <Box fontWeight={300}>
                        {PaymentTypeEnum[row.paymentType]}
                      </Box>
                    }
                  </TableCell>
                  <TableCell>
                    {
                      <Box fontWeight={300}>
                        {row.paymentMethod
                          .map((payMethod: string) =>
                            PaymentMethodEnum[payMethod]
                              ? PaymentMethodEnum[payMethod]
                              : AdditionalServiceEnum[payMethod]
                              ? AdditionalServiceEnum[payMethod]
                              : payMethod.toUpperCase()
                          )
                          .join(",")}
                      </Box>
                    }
                  </TableCell>
                  <TableCell>
                    {
                      <Box fontWeight={300}>
                        {row.configuration[0].fixedAmount.toFixed(2)}{" "}
                        {stateProps.currency}
                      </Box>
                    }
                  </TableCell>
                  <TableCell>
                    {
                      <Box fontWeight={300}>
                        {row.configuration[0].variableAmount} %
                      </Box>
                    }
                  </TableCell>
                  <TableCell>
                    {
                      <Box fontWeight={300}>
                        {row.minimumAmount.toFixed(2)} {stateProps.currency}
                      </Box>
                    }
                  </TableCell>
                  <TableCell>
                    {
                      <Box fontWeight={300}>
                        {row.maximumAmount
                          ? row.maximumAmount.toFixed(2) +
                            " " +
                            stateProps.currency
                          : ""}
                      </Box>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={3} px={2}>
          <Link
            href={`/rate/${stateProps.publicMerchantId}?configType=${configType.configRate}&hideSideBar=true`}
            color="inherit"
            variant="h6"
            className={classes.link}
          >
            Edita y revisa todas las tarifas
          </Link>
        </Box>
      </GridPaper>
    </React.Fragment>
  );
};

export const RateSummary: React.FC<any> = (
  _: string,
  _0: (step: string, status: StatusSteps) => void
) => {
  return (
    <React.Fragment>
      <RateSectionSummary />
    </React.Fragment>
  );
};
