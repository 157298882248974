import { IOptionFilter } from "../../../components/DashboardList/BoxFilter/BoxFilter";
import { KindEnum } from "../KindEnum";
import { CycleEnum } from "../CycleEnum";
import { InvoiceModeEnum } from "../InvoiceModeEnum";
import { FilterTypeEnum } from "../FilterTypeEnum";
import { TransactionTypeEnum } from "../TransactionTypeEnum";
import { ModelsEnum } from "../ModelEnum";

export const DefaultFilter: IOptionFilter[] = [
  {
    id: FilterTypeEnum.transactionType,
    title: "Tipo de transacción",
    options: [
      {
        key: TransactionTypeEnum.PAYIN_COMISSION,
        label: "Pay-In",
        selected: false,
      },
      {
        key: TransactionTypeEnum.PAYOUT_COMISSION,
        label: "Pay-Out",
        selected: false,
      },
      {
        key: TransactionTypeEnum.MINIMAL_COMISSION,
        label: "Mínimo comisional",
        selected: false,
      },
    ],
    multiple: true,
  },
  {
    id: FilterTypeEnum.models,
    title: "Modelo",
    options: [
      { key: ModelsEnum.GATEWAY, label: "Gateway", selected: false },
      { key: ModelsEnum.AGGREGATOR, label: "Agregador ", selected: false },
    ],
    multiple: true,
    onlyWith: `${FilterTypeEnum.transactionType}-${TransactionTypeEnum.PAYIN_COMISSION}`,
  },
  {
    id: FilterTypeEnum.kind,
    title: "Tipo de documento",
    options: [
      { key: KindEnum.CHARGE, label: "Cobro", selected: false },
      { key: KindEnum.DISPERSION, label: "Dispersión", selected: false },
      { key: KindEnum.INVOICE, label: "Factura", selected: false },
      {
        key: InvoiceModeEnum.WITHOUT_IVA,
        label: "Factura sin IVA",
        selected: false,
      },
      {
        key: InvoiceModeEnum.WITH_IVA,
        label: "Factura con IVA",
        selected: false,
      },
      { key: KindEnum.VOUCHER, label: "Boleta", selected: false },
      { key: KindEnum.RECEIVABLE, label: "Por cobrar", selected: false },
      { key: KindEnum.CREDIT_NOTE, label: "Nota de crédito", selected: false },
      { key: KindEnum.DEBIT_NOTE, label: "Nota de débito", selected: false },
      { key: KindEnum.ANNUL_INVOICE, label: "Anulación", selected: false },
      {
        key: KindEnum.RETENTION,
        label: "Retención",
        selected: false,
      },
      {
        key: KindEnum.RETENTION_EC,
        label: "Retención",
        selected: false,
      },
    ],
  },
  {
    id: FilterTypeEnum.cycle,
    title: "Ciclo",
    options: [
      { key: CycleEnum.DAILY, label: "Diaria", selected: false },
      {
        key: CycleEnum.THREEWEEKLY,
        label: "Lun.-Miérc.-Vier.",
        selected: false,
      },
      { key: CycleEnum.TWOWEEKLY, label: "Mar.-Vier.", selected: false },
      { key: CycleEnum.WEEKLY, label: "Semanal", selected: false },
      { key: CycleEnum.BIWEEKLY, label: "Quincenal", selected: false },
      { key: CycleEnum.MONTHLY, label: "Mensual", selected: false },
    ],
  },
  {
    id: FilterTypeEnum.currency,
    title: "Moneda",
    options: [{ key: "USD", label: "Dólares", selected: false }],
  },
];
