import { Credential } from "../../../shared/infrastructure/interfaces/Credential";
import {
  Webhook,
  WebhookEvent,
} from "../../../shared/infrastructure/interfaces/Webhook";
import { ThunkAction } from "redux-thunk";
import { IAppState } from "../../../store/reducer";
import { IAppAction } from "../../../store/actionCreators";
import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { FC, memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FallbackDevSection } from "./FallbackSection";
import { setMerchantInfoStorage } from "../../../shared/infrastructure/constants/CreateMerchantConstants";

type Item = Credential | Webhook;
type SectionContentProps = {
  publicMerchantID: string;
  items: Item[];
  fetchItems: (
    merchantId: string
  ) => ThunkAction<void, IAppState, undefined, IAppAction>;
  labelPlural: string;
  newItemURL: string;
  allItemsURL: string;
  allTheItemsMessage: string;
};

function isCredential(item: Item): item is Credential {
  return (item as Credential).credentialId !== undefined;
}

function summarizeWebhookEvents(webhook: Webhook): string {
  let events: WebhookEvent[] = webhook.events || [];
  let allEventNames: string[] = events.map((event) => event.service);
  let eventNames = [...Array.from(new Set(allEventNames))];
  if (eventNames.length > 3) {
    eventNames = [...eventNames.slice(0, 3), "..."];
  }
  return eventNames.join(", ");
}

const useStyles = makeStyles({
  root: {
    padding: "16px",
  },
  header: {
    paddingBottom: "16px",
    "& h6": {
      fontWeight: "500",
    },
  },
  content: {
    padding: "16px 0",
  },
  row: {
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  buttonFooter: {
    "& .MuiButton-text": {
      padding: "0px",
    },
    "& h6": {
      textDecorationLine: "underline",
      fontWeight: "normal",
    },
  },
});
export const SectionContent: FC<SectionContentProps> = memo((props) => {
  const {
    publicMerchantID,
    items,
    fetchItems,
    labelPlural,
    newItemURL,
    allItemsURL,
    allTheItemsMessage,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchItems(publicMerchantID));
  }, [publicMerchantID]);

  const goToCreateItemPage = () => {
    setMerchantInfoStorage();
    window.location.assign(newItemURL);
  };
  const goToAllItemsPage = () => {
    setMerchantInfoStorage();
    history.push(allItemsURL);
  };

  if (!items || !items.length) {
    return (
      <FallbackDevSection
        sectionName={labelPlural.toLowerCase()}
        onClickHandler={goToCreateItemPage}
      />
    );
  }

  return (
    <Grid container direction="column" wrap={"nowrap"} className={classes.root}>
      <Grid
        item
        container
        direction={"row"}
        alignItems={"center"}
        justify={"space-between"}
        className={classes.header}
      >
        <Grid item>
          <Typography variant={"h6"}>{labelPlural}</Typography>
        </Grid>
        <Grid item>
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={goToCreateItemPage}
          >{`Agregar ${labelPlural.toLowerCase()}`}</Button>
        </Grid>
      </Grid>
      <Divider />
      <Grid item className={classes.content}>
        {items.map((item: Item) => (
          <div key={item.alias}>
            <Grid container direction={"row"}>
              <Grid item xs={6}>
                <Typography className={classes.row} variant={"subtitle1"}>
                  {item.alias}
                </Typography>
              </Grid>
              {!isCredential(item) && (
                <Grid item xs={6}>
                  <Typography className={classes.row} variant={"subtitle1"}>
                    {summarizeWebhookEvents(item as Webhook)}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Divider />
          </div>
        ))}
      </Grid>
      <Grid item className={classes.buttonFooter}>
        <Button>
          <Typography variant={"h6"} onClick={goToAllItemsPage}>
            {`Edita y revisa ${allTheItemsMessage}`}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
});

SectionContent.displayName = "SectionContent";
