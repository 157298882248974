import { Country, SelectCountryProps } from "./SelectCountry.interfaces";
import React, { FC } from "react";
import {
  Autocomplete,
  Box,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { selectCountryStyles } from "./SelectCountry.styles";
import { useSelectCountryState } from "./use/useSelectCountryState";

const SelectCountry: FC<SelectCountryProps> = (props: SelectCountryProps) => {
  const {
    value,
    countryList,
    handlers: { onChange },
  } = useSelectCountryState(props);

  const CountryFlag: FC | undefined = value?.FlagIcon;

  return (
    <Autocomplete
      id={"select-country"}
      options={countryList}
      sx={selectCountryStyles.autoComplete}
      ListboxProps={{
        // @ts-ignore
        "data-testid": "list-box",
        sx: selectCountryStyles.listBox,
      }}
      disabled={props.isDisabled}
      value={value}
      onChange={(_event, value) => {
        onChange(value);
      }}
      placeholder={props.placeHolder}
      renderOption={(props, { label, FlagIcon }: Country) => (
        <Box
          component="li"
          height={"40px"}
          sx={selectCountryStyles.option}
          data-testId={"options"}
          {...props}
        >
          <FlagIcon />
          <Typography pl={1} variant={"body2"}>
            {label}
          </Typography>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          data-testId={"textField"}
          error={props.error}
          label={props.placeHolder}
          helperText={props.helperText}
          InputLabelProps={{ ...params.InputLabelProps }}
          InputProps={{
            ...params.InputProps,
            startAdornment: CountryFlag && (
              <Box pl={1} data-testId={"startAdornment"}>
                <InputAdornment
                  position="start"
                  sx={selectCountryStyles.inputAdornment}
                >
                  <CountryFlag />
                </InputAdornment>
              </Box>
            ),
            sx: selectCountryStyles.inputProps,
          }}
        />
      )}
    />
  );
};

export { SelectCountry };
