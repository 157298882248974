import { CheckBoxOutlined } from "@mui/icons-material";
import { Checkbox, FormControlLabel } from "@mui/material";
import React, { FC, ReactElement } from "react";
import { CellHeaderCheckStateEnum } from "../CellHeader/enums";
import { ICellHeaderCheckProps } from "./CellHeaderCheck.interfaces";

export const CellHeaderCheck: FC<ICellHeaderCheckProps> = ({
  state,
  isDisabled,
  onChecked,
  label,
}: ICellHeaderCheckProps): ReactElement => {
  return (
    <FormControlLabel
      label={label || ""}
      control={
        <Checkbox
          checkedIcon={<CheckBoxOutlined />}
          checked={
            state === CellHeaderCheckStateEnum.CHECKED ||
            state === CellHeaderCheckStateEnum.INDETERMINATE
          }
          indeterminate={state === CellHeaderCheckStateEnum.INDETERMINATE}
          onChange={() => {
            const value = !(
              state === CellHeaderCheckStateEnum.CHECKED ||
              state === CellHeaderCheckStateEnum.INDETERMINATE
            );

            return onChecked ? onChecked(value) : null;
          }}
          data-testid={"checkbox"}
          value={state}
          color="primary"
          disabled={isDisabled}
        />
      }
    />
  );
};
