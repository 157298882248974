import { CountryEnum } from "./CountryEnum";

export enum CurrencyEnum {
  MXN = "MXN",
  CLP = "CLP",
}

export const CurrencyByCountry: Record<CountryEnum, string> = {
  [CountryEnum.MEXICO]: CurrencyEnum.MXN,
  [CountryEnum.CHILE]: CurrencyEnum.CLP,
};
