/* istanbul ignore file */
import axios from "axios";
import { defaultTo } from "lodash";

const authorization: string | number | boolean = defaultTo(
  localStorage.getItem("jwt"),
  ""
);

const instance = axios.create({
  headers: {
    Authorization: authorization,
    "Content-type": "application/json",
  },
});

export default instance;
