import React from "react";
import { Button, Grid, MenuItem, Select, Typography } from "@material-ui/core";
import { ChevronRight, ChevronLeft } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";
import { themeOptions } from "../../../themeui";

export interface DashboardListPaginationProps {
  page: number;
  pageSize: number;
  disableNext: boolean;
  disablePrevious: boolean;
  handleChangePage: (newPage: number) => void;
  handleChangePageSize: (newPageSize: number) => void;
  isMobile: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    padding: (props: DashboardListPaginationProps) => {
      return props.isMobile ? "2px 0px 4px 4px" : "8px 27px 8px 20px";
    },
  },
  buttonIconPagination: {
    textTransform: "none",
  },
  marginButton: {
    display: "flex",
  },
  typographyLabelPagination: {
    marginTop: "auto",
    marginBottom: "auto",
    marginRight: 11,
    color: `${themeOptions.palette.text.grey}`,
  },
  perPageItem: {
    color: `${themeOptions.palette.text.grey}`,
  },
}));

export const DashboardListPagination: React.FC<DashboardListPaginationProps> = (
  props: DashboardListPaginationProps
) => {
  const classes = useStyles(props);

  const handlePage = (action: string): void => {
    let new_page: number;

    if (action === "next") new_page = props.page + props.pageSize;
    else new_page = props.page - props.pageSize;

    props.handleChangePage(new_page);
  };

  const handlePageSize = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    props.handleChangePageSize(event.target.value as number);
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid container>
          <Grid
            item
            xs={2}
            lg={8}
            xl={8}
            md={4}
            sm={6}
            className={classes.marginButton}
          >
            {props.isMobile ? (
              ""
            ) : (
              <Typography
                variant={"body2"}
                className={classes.typographyLabelPagination}
              >
                Transacciones por página
              </Typography>
            )}
            <Select
              disableUnderline
              value={props.pageSize}
              onChange={handlePageSize}
            >
              <MenuItem value={10}>
                <Typography variant={"body2"} className={classes.perPageItem}>
                  10
                </Typography>
              </MenuItem>
              <MenuItem value={20}>
                <Typography variant={"body2"} className={classes.perPageItem}>
                  20
                </Typography>
              </MenuItem>
              <MenuItem value={40}>
                <Typography variant={"body2"} className={classes.perPageItem}>
                  40
                </Typography>
              </MenuItem>
            </Select>
          </Grid>
          <Grid
            item
            xs={10}
            lg={4}
            xl={4}
            md={8}
            sm={6}
            container
            alignItems="flex-start"
            justify="flex-end"
            direction="row"
          >
            <div>
              <Button
                className={classes.buttonIconPagination}
                startIcon={<ChevronLeft size={25} />}
                disabled={props.disablePrevious}
                onClick={() => handlePage("previous")}
              >
                Anterior
              </Button>
            </div>
            <div>
              <Button
                className={classes.buttonIconPagination}
                endIcon={<ChevronRight size={25} />}
                disabled={props.disableNext}
                onClick={() => handlePage("next")}
              >
                Siguiente
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};
