import { IStyles } from "../../shared/interfaces/create_named_styles";

export const ticketOrderStyles: IStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  divider: {
    borderWidth: "1px",
    borderStyle: "solid",
    mb: "40px",
    mt: "40px",
  },
  copyIcon: {
    color: "text.dark",
  },
  gridDetails: {
    paddingLeft: "72px",
    paddingRight: "72px",
  },
  disclaimerContainer: {
    marginTop: "48px",
    marginBottom: "24px",
  },
  labelTitle: {
    fontWeight: 500,
    paddingBottom: "16px",
    paddingTop: "24px",
    fontSize: "28px !important",
    color: "text.dark",
    textAlign: "center",
  },
  labelDescriptionL1: {
    fontWeight: 400,
    fontSize: "16px !important",
    color: "text.dark",
    textAlign: "center",
    paddingBottom: "48px",
  },
  labelAmount: {
    fontWeight: 500,
    fontSize: "16px !important",
    color: "text.grey",
    textAlign: "center",
    paddingBottom: "8px",
  },
  labelAmountValue: {
    fontWeight: 500,
    paddingBottom: "40px",
    fontSize: "24px !important",
    color: "text.dark",
    textAlign: "center",
  },
  labelService: {
    fontWeight: 500,
    paddingBottom: "8px",
    fontSize: "16px !important",
    color: "text.grey",
    textAlign: "left",
  },
  labelServiceDescription: {
    fontWeight: 500,
    paddingBottom: "8px",
    fontSize: "20px !important",
    color: "text.dark",
    textAlign: "left",
  },
  labelDetailsTitle: {
    fontWeight: 500,
    paddingBottom: "40px",
    fontSize: "28px !important",
    color: "text.dark",
    textAlign: "center",
  },
  labelSubtitle: {
    fontWeight: 500,
    paddingBottom: "8px",
    fontSize: "16px !important",
    color: "text.grey",
    textAlign: "left",
  },
  labelDetailsValue: {
    display: "flex",
    fontWeight: 500,
    fontSize: "20px !important",
    color: "text.dark",
    textAlign: "left",
    wordBreak: "break-all",
  },
  labelDisclaimer: {
    fontWeight: 500,
    fontSize: "16px !important",
    color: "text.dark",
    textAlign: "center",
  },
  labelExpiration: {
    fontWeight: 500,
    fontSize: "15px !important",
    color: "text.dark",
    textAlign: "center",
    marginTop: "10px",
  },
  labelDisclaimerVerify: {
    marginTop: "5px",
    marginRight: "15px",
    fontWeight: 500,
    fontSize: "16px !important",
    color: "text.dark",
    textAlign: "center",
  },
  boxDisclaimer: {
    marginTop: "20px",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    textAlign: "center",
  },
};
