import {
  Operators,
  PlaceHolders,
  TextFieldsNames,
} from "../../../../../shared/infrastructure/constants/BusinessRuleConstants";
import { useEffect, useState } from "react";
import { isEqual, isNaN, toNumber, isEmpty } from "lodash";
import { ErrorsEnum } from "../../../../../shared/infrastructure/enums/ErrorEnum";
import { IUseAmountResponseInterface } from "../../../../../shared/infrastructure/interfaces/IUseAmountResponseInterface";
import { IUseAmountRequestInterface } from "../../../../../shared/infrastructure/interfaces/IUseAmountRequestInterface";
import { RuleEnum } from "../../../../../shared/infrastructure/enums/RuleEnum";

export const useAmountConditionState = (
  props: IUseAmountRequestInterface
): IUseAmountResponseInterface => {
  const { operator, firstValue, secondValue, clearErrors, setValue, name } =
    props;
  const [firstPlaceHolder, setFirstPlaceHolder] = useState<string>("");
  const [secondPlaceHolder, setSecondPlaceHolder] = useState<string>("");

  const getFirstPlaceHolder = (): void => {
    operator != Operators.BETWEEN
      ? setFirstPlaceHolder(PlaceHolders.AMOUNT_VALUE)
      : setFirstPlaceHolder(PlaceHolders.AMOUNT_FIRST_VALUE);
  };

  const getSecondPlaceHolder = (): void => {
    setSecondPlaceHolder(PlaceHolders.AMOUNT_SECOND_VALUE);
  };

  const getOtherPlaceholder = (firstValue: string): void => {
    firstValue.length > 0 && operator != Operators.BETWEEN
      ? setFirstPlaceHolder(PlaceHolders.AMOUNT_SINGLE_VALUE)
      : getFirstPlaceHolder();
  };

  const validateAmounts = (
    firstValue: string,
    secondValue: string,
    type: string
  ): string | undefined => {
    if (
      operator != Operators.BETWEEN ||
      isEmpty(firstValue) ||
      isEmpty(secondValue)
    )
      return undefined;

    if (type === TextFieldsNames.FirstAmount)
      return toNumber(firstValue) >= toNumber(secondValue)
        ? ErrorsEnum.LESS_THAN_ERROR
        : undefined;

    return toNumber(firstValue) >= toNumber(secondValue)
      ? ErrorsEnum.GREATER_THAN_ERROR
      : undefined;
  };

  const keyDownHandler = (e: KeyboardEvent): void => {
    const key: string = e.key;
    const isNotNumber: boolean = isNaN(Number(key));
    const isPoint: boolean = isEqual(key, ".");
    const isBackspace: boolean = isEqual(key, "Backspace");

    if (isNotNumber && !isPoint && !isBackspace) e.preventDefault();
  };

  const getValues = (): number[] => {
    if (operator != Operators.BETWEEN) {
      props.setSelectedItems([
        {
          label: RuleEnum.AMOUNT,
          value: [Number(firstValue)].toString(),
          selected: true,
        },
      ]);

      return [Number(firstValue)];
    } else {
      props.setSelectedItems([
        {
          label: RuleEnum.AMOUNT,
          value: [Number(firstValue), Number(secondValue)].toString(),
          selected: true,
        },
      ]);

      return [Number(firstValue), Number(secondValue)];
    }
  };

  const clearData = (): void => {
    setFirstPlaceHolder("");
    setSecondPlaceHolder("");
    clearErrors(TextFieldsNames.FirstAmount);
    clearErrors(TextFieldsNames.SecondAmount);
    setValue(TextFieldsNames.SecondAmount, "");
    setValue(name, "");
  };

  useEffect(() => {
    clearData();
    getFirstPlaceHolder();
    getSecondPlaceHolder();

    if (firstValue.length > 0) getOtherPlaceholder(firstValue);
  }, [operator]);

  return {
    actions: {
      getFirstPlaceHolder,
      getSecondPlaceHolder,
      getOtherPlaceholder,
      keyDownHandler,
      validateAmounts,
      getValues,
    },
    firstPlaceHolder,
    secondPlaceHolder,
  };
};
