/* istanbul ignore file */

import { IOptionSelection } from "../interfaces/IOptionSelection";

export const BANKS_PANAMA: IOptionSelection[] = [
  { value: "1100001", label: "AUSTROBANK (OVERSEAS) PANAMA S.A." },
  { value: "1100002", label: "BANCAFE (BANCO CAFETERO)" },
  { value: "1100003", label: "BANCO ALIADO S.A." },
  { value: "1100004", label: "BANCO BILBAO VIZCAYA ARGENTARIA (PANAMA) S.A." },
  { value: "1100005", label: "BANCO CUSCATLAN DE PANAMA S.A." },
  { value: "1100006", label: "BANCO DE BOGOTA S.A." },
  { value: "1100007", label: "BANCO DE GUAYAQUIL (PANAMA) S.A." },
  { value: "1100008", label: "BANCO DE LA NACION ARGENTINA" },
  { value: "1100009", label: "BANCO DE OCCIDENTE (PANAMA) S.A." },
  { value: "1100010", label: "BANCO DEL PACIFICO (PANAMA) S.A." },
  { value: "1100011", label: "BANCO GENERAL S.A." },
  { value: "1100012", label: "BANCO INTERNACIONAL DE COSTA RICA S.A." },
  { value: "1100013", label: "BANCO LAFISE PANAMA" },
  {
    value: "1100014",
    label: "BANCO LATINOAMERICANO DE COMERCIO EXTERIOR S.A.",
  },
  { value: "1100015", label: "BANCO NACIONAL DE PANAMA" },
  { value: "1100016", label: "BANCO PICHINCHA PANAMA" },
  { value: "1100017", label: "BANCO TRASATLANTICO S.A." },
  { value: "1100018", label: "BANCO UNION SUCURSAL PANAMA" },
  { value: "1100019", label: "BANCO UNIVERSAL S.A." },
  { value: "1100020", label: "BANCOLOMBIA PANAMA S.A." },
  { value: "1100021", label: "BANESCO INTERNATIONAL BANK. INC." },
  { value: "1100022", label: "BANK LEUMI LE ISRAEL B.M" },
  { value: "1100023", label: "BANK OF CHINA PANAMA BRANCH" },
  { value: "1100024", label: "BANK OF NOVA SCOTIA" },
  { value: "1100025", label: "BCT BANK INTERNATIONAL S.A." },
  { value: "1100026", label: "BLUBANK LTD." },
  { value: "1100027", label: "BNP PARIBAS WEALTH MANAGEMENT SUCURSAL PANAMA" },
  { value: "1100028", label: "BNP PARIBAS-PANAMA BRANCH" },
  { value: "1100029", label: "CAPITAL BANK INC." },
  { value: "1100030", label: "CITIBANK N.A. SUCURSAL PANAMA" },
  { value: "1100031", label: "CREDICORP BANK S.A" },
  { value: "1100032", label: "ES BANK (PANAMA) S.A." },
  { value: "1100033", label: "FIRST CENTRAL INTERNATIONAL BANK INC." },
  { value: "1100034", label: "FPB INTERNATIONAL BANK INC." },
  { value: "1100035", label: "GLOBAL BANK CORPORATION" },
  { value: "1100036", label: "GNB SUDAMERIS BANK S.A." },
  { value: "1100037", label: "HELM BANK (PANAMA)" },
  { value: "1100038", label: "HSBC BANK USA PANAMA" },
  { value: "1100039", label: "INTERNATIONAL UNION BANK S.A." },
  { value: "1100040", label: "KOREA EXCHANGE BANK" },
  { value: "1100041", label: "MEGA INTERNATIONAL COMMERCIAL BANK CO. LTD." },
  { value: "1100042", label: "MERCANTIL BANK (PANAMA) S.A." },
  { value: "1100043", label: "METROBANK S.A." },
  { value: "1100044", label: "MMG BANK CORPORATION" },
  { value: "1100045", label: "MULTIBANK INC." },
  { value: "1100046", label: "POPULAR BANK LTD. INC." },
  { value: "1100047", label: "PRODUBANK PANAMA S.A." },
  { value: "1100048", label: "STANFORD BANK (PANAMA) S.A." },
  { value: "1100049", label: "TOWERBANK INTERNATIONAL INC." },
];
