import { useEffect, useState } from "react";
import { CheckoutAlertProps } from "../CheckoutAlert";
import { IUseCheckoutAlertInterface } from "./useCheckoutAlertInterface.interfaces";

export const useCheckoutAlertState = (
  props: CheckoutAlertProps
): IUseCheckoutAlertInterface => {
  const [open, setOpen] = useState(props.open);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (!open && props.handlerClose) props.handlerClose();
  }, [open]);

  return { handleClose, open };
};
