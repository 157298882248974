import { makeStyles } from "@mui/styles";
import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const listBranchCreationFormStyles = createNamedStyles({
  item: {
    "&.MuiListItem-root": {
      padding: 0,
      margin: 0,
      justifyContent: "center",
    },
  },
  boxContent: {
    display: "flex",
    alignItems: "center",
    mt: (theme) => theme.spacing(6),
    mb: (theme) => theme.spacing(6),
    width: "35%",
  },
  boxSection: {
    height: 1,
    width: "100% !important",
    display: "inline-table",
    p: (theme) => theme.spacing(1, 1, 3, 1),
  },
  boxTableBranch: {
    minHeight: "260px !important",
    maxHeight: "260px !important",
    width: "100% !important",
    display: "inline-table",
    border: "2px dashed #CBD5E0",
    p: (theme) => theme.spacing(1, 1, 1, 1),
  },
  title: {
    fontSize: "24px !important",
    lineHeight: "120%",
    pb: 6,
    fontWeight: "bold !important",
  },
  subtitle: {
    fontSize: "13px !important",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "120%",
    padding: "5px 0",
    textAlign: "center",
  },
  subtitle_error: {
    display: "flex",
    alignItems: "center",
    fontSize: "13px !important",
    fontStyle: "normal",
    textAlign: "center",
    marginBottom: 0,
  },
  fileFormat: {
    fontSize: "13px !important",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "120%",
    color: "#6D7781",
    textAlign: "center",
    p: (theme) => theme.spacing(0, 0, 1, 0),
  },
  fileDropArea: {
    fontSize: "13px !important",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "120%",
    color: "#B4B9BE",
    p: (theme) => theme.spacing(1.5, 0, 0.5, 0),
  },
  nextButton: {
    fontSize: (theme) => theme.spacing(1.75),
    width: "100%",
    p: (theme) => theme.spacing(0, 4, 0, 4),
  },
  labelSubtitle: {
    fontSize: "16px !important",
    p: (theme) => theme.spacing(0, 0.5, 2, 0),
    color: "#023365",
  },
  labelLoadingText: {
    fontSize: "16px !important",
    p: (theme) => theme.spacing(2, 0, 2, 0),
    color: "#023365",
  },
  labelDownloadFile: {
    fontSize: "16px !important",
    p: (theme) => theme.spacing(0, 0.5, 1, 0),
    color: "#023365",
    fontWeight: "bold !important",
  },
  downloadFileNameArea: {
    fontSize: "14px !important",
    p: (theme) => theme.spacing(0, 0, 1, 0),
    color: "#4498EE",
  },
  downloadFileName: {
    fontSize: "14px !important",
    p: (theme) => theme.spacing(0, 1, 1, 0),
    color: "#4498EE",
  },
  labelStep: {
    fontSize: {
      sm: "16px !important",
      lg: "20px !important",
    },
    color: "#023365",
    fontWeight: "bold !important",
    lineHeight: "140% !important",
    p: (theme) => theme.spacing(0, 2, 2, 0),
  },
  boxFooter: {
    zIndex: "1000",
    width: "100%",
    position: "fixed",
    bottom: "0",
  },
  uploadFilesSection: {
    p: (theme) => theme.spacing(2, 3, 2, 3),
  },
  downloadFileLink: {
    p: (theme) => theme.spacing(0, 0, 0, 2),
  },
  uploadFilesDivider: {
    p: (theme) => theme.spacing(0, 3, 0, 3),
  },
  uploadFileArea: {
    alignItems: "center",
    display: "flex",
    width: "100%",
  },
  uploadFileAreaDrop: {
    alignItems: "center",
    display: "flex",
    width: "100%",
    color: "#B4B9BE",
  },
  uploadFileAreaList: {
    alignItems: "center",
    display: "flex",
    width: "100%",
    color: "#B4B9BE",
    p: (theme) => theme.spacing(0, 0, 2, 0),
  },
  divider: {
    alignItems: "center",
    display: "flex",
    width: "100%",
    border: "0px",
  },
  gridFileUploaded: {
    backgroundColor: "#E9EFF3",
    gap: "49px",
    borderRadius: "8px",
    width: "100%",
  },
  labelFileUploaded: {
    fontSize: "16px !important",
    p: (theme) => theme.spacing(2, 0, 2, 2),
    color: "#023365",
  },
  deleteFileUploaded: {
    width: "100%",
    p: (theme) => theme.spacing(2, 0, 2, 0),
  },
  labelContainer: {
    width: "250px",
  },
});

export const useStyles = makeStyles(() => ({
  lottie: { height: "60%", padding: 4, width: "60%" },
}));
