import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { ConfigurationIdEnum } from "../../../shared/enums";
import {
  setContactsDataResponse,
  setMerchantCompliance,
} from "../../../store/actions/actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectNodeInfo } from "../../../store/selectors/selectors";
import { IContactsIndex } from "./useContactIndex.interface";
import { EntityNameEnum } from "../../../shared/enums/entityName";
import {
  getSectionInfo,
  statusNodeInfo,
} from "../../../shared/utils/node_info";

export const useContactsIndex = (): IContactsIndex => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const nodeInfoData = useSelector(selectNodeInfo);
  const [showContactCardIndex, sertShowContactCardIndex] =
    useState<boolean>(false);
  const dispatch = useDispatch();

  const getData = async (publicMerchantId: string) => {
    try {
      const [ContactsData, MerchantCompliance] = await Promise.all([
        axios.post(API_ROUTES.BILLING_CORE_MERCHANT_NODE_INFO, {
          configId: ConfigurationIdEnum.CN002,
          publicMerchantId: `${publicMerchantId}`,
        }),
        axios.get(`${API_ROUTES.MERCHANT_COMPLIANCE}/${publicMerchantId}`),
      ]);

      dispatch(setContactsDataResponse(ContactsData.data));
      dispatch(setMerchantCompliance(MerchantCompliance.data));
      sertShowContactCardIndex(true);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (nodeInfoData) {
      if (
        nodeInfoData.entityName === EntityNameEnum.CUSTOMER ||
        nodeInfoData.entityName === EntityNameEnum.BRANCH
      ) {
        let sectionInfo = getSectionInfo(
          ConfigurationIdEnum.CN002,
          nodeInfoData.configs
        );

        if (sectionInfo) {
          if (sectionInfo.status === statusNodeInfo.COMPLETE) {
            getData(sectionInfo.value);
          }
        }
      }
    }
  }, [nodeInfoData]);

  return { isLoading, showContactCardIndex };
};

export default useContactsIndex;
