import * as React from "react";
import { FC, PropsWithChildren } from "react";
import { IAccordionProps } from "./Accordion.interfaces";
import {
  Accordion as Acc,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import { useAccordion } from "./state/useAccordion";
import {
  ADD_TEXT,
  BIRTH_DATE_TEXT,
  NAME_TEXT,
  NID_TEXT,
  PARTICIPATION_SHAREHOLDERS_TEXT,
  RECIDENCE_COUNTRY_TEXT,
  REGISTERED_SHAREHOLDERS_TEXT,
  SOCIAL_REASON_TEXT,
} from "../../shared/constants/labels/AccordionConstants";
import DropdownList from "../DropDownList/DropdownList";
import AccordionStyles from "./Accordion.styles";
import {
  IconChevronDown,
  IconChevronUp,
  IconMore,
  IconPlus,
} from "../Icons/index.export";
import { get, isEmpty } from "lodash";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { ComponentIdsEnum } from "../../shared/constants/labels/main_containter_labels";
import { getNestingLabel } from "../../shared/utils/shareholderUtils";

const Accordion: FC<IAccordionProps> = (
  props: PropsWithChildren<IAccordionProps>
) => {
  const {
    badgeColor,
    badgeText,
    buttonAddSubShareholders,
    text,
    text2,
    tagText,
    tagColor,
    iconRight,
    iconLeft,
    listDropdown,
    divider,
    nesting,
    nextNesting,
    shareholderInfo,
    iconSummaryDown = false,
    isDisableDropdownList,
  } = props;
  const {
    handleClick,
    iconRef,
    openOptionList,
    anchorEl,
    openAccordion,
    handleClose,
    handleClickAccordion,
  } = useAccordion(props);
  const nestingArray = nesting.split("-");
  const isNestingEqualOne = nestingArray.length === 1;

  return (
    <>
      <Box>
        {nesting && (
          <Box marginLeft={`${40 * nestingArray.length - 24}px`}>
            {!isNestingEqualOne && <Box sx={AccordionStyles.nestedLineUp} />}
            <Badge
              badgeContent={getNestingLabel(nesting)}
              sx={
                isNestingEqualOne
                  ? AccordionStyles.nestedBadgeComponentLvl1
                  : AccordionStyles.nestedBadgeComponent
              }
            />
            <Box
              sx={
                isNestingEqualOne
                  ? AccordionStyles.nestedLine
                  : AccordionStyles.nestedLineDown
              }
            />
          </Box>
        )}
        <Acc
          data-testid={"acc"}
          sx={AccordionStyles.accordion}
          expanded={openAccordion}
          onChange={handleClickAccordion}
        >
          <AccordionSummary
            expandIcon={
              iconSummaryDown ? (
                <IconChevronDown
                  color="primary"
                  fontSize="medium"
                  sx={AccordionStyles.iconAccordionDown}
                />
              ) : (
                <IconChevronUp
                  color="primary"
                  fontSize="medium"
                  sx={AccordionStyles.iconAccordionUp}
                />
              )
            }
            sx={
              iconSummaryDown
                ? AccordionStyles.summaryAccordionDown
                : AccordionStyles.summaryAccordionUp
            }
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Box display={"flex"} alignItems={"center"}>
                <Box sx={AccordionStyles.boxIcon}>{iconLeft}</Box>
                <Box display={"flex"} flexDirection={"column"}>
                  <Box display={"flex"}>
                    <Typography variant="h5" color="primary">
                      {text}
                    </Typography>
                    <Box sx={AccordionStyles.boxIcon}>{iconRight}</Box>
                  </Box>
                  {text2 && (
                    <Typography variant="h6" color="text.grey">
                      {text2}
                    </Typography>
                  )}
                </Box>
                {badgeText && badgeColor && (
                  <Box sx={AccordionStyles.normalBox}>
                    <Badge color={badgeColor} badgeContent={badgeText} />
                  </Box>
                )}
                {tagText && tagColor && (
                  <Box sx={AccordionStyles.normalBox}>
                    <Chip label={tagText} color={tagColor} />
                  </Box>
                )}
              </Box>
              {listDropdown && (
                <Box data-testid={"listDropdown"} sx={AccordionStyles.boxIcon}>
                  <IconButton
                    data-testid={"iconButton"}
                    size={"medium"}
                    ref={iconRef}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClick();
                    }}
                  >
                    <IconMore />
                  </IconButton>
                  <Popover
                    data-testid={"popover"}
                    sx={AccordionStyles.optionList}
                    anchorOrigin={{
                      horizontal: "center",
                      vertical: "bottom",
                    }}
                    transformOrigin={{
                      horizontal: "right",
                      vertical: "top",
                    }}
                    anchorEl={anchorEl}
                    open={openOptionList}
                    onClose={(e: { stopPropagation: () => void }) => {
                      e.stopPropagation();
                      handleClose();
                    }}
                  >
                    <DropdownList
                      isOpen={openOptionList}
                      items={listDropdown}
                      handleClose={handleClose}
                      isSearchable={false}
                      isDisabled={isDisableDropdownList}
                    />
                  </Popover>
                </Box>
              )}
            </Box>
          </AccordionSummary>
          {divider && <Divider sx={AccordionStyles.divider} />}
          {isEmpty(shareholderInfo) && (
            <AccordionDetails>{props.children}</AccordionDetails>
          )}
          {!isEmpty(shareholderInfo) && (
            <AccordionDetails>
              {props.children}
              <Box display="grid" padding={2}>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <Grid
                      item
                      xs={12}
                      marginBottom={2}
                      sx={AccordionStyles.gridFlex}
                    >
                      <Box>
                        <Typography sx={AccordionStyles.typographyLabel}>
                          {get(shareholderInfo, "isMoral", false)
                            ? SOCIAL_REASON_TEXT
                            : NAME_TEXT}
                        </Typography>
                      </Box>
                      <Box sx={AccordionStyles.normalValue}>
                        {get(shareholderInfo, "isMoral", false)
                          ? get(shareholderInfo, "socialReason", "")
                          : get(shareholderInfo, "name", "")}
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      marginBottom={2}
                      sx={AccordionStyles.gridFlex}
                    >
                      <Box>
                        <Typography sx={AccordionStyles.typographyLabel}>
                          {NID_TEXT}
                        </Typography>
                      </Box>
                      <Box sx={AccordionStyles.normalValue}>
                        {get(shareholderInfo, "id", "")}
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid sx={AccordionStyles.gridFlexDivider} item xs={0.5}>
                    <Divider
                      sx={AccordionStyles.divider}
                      variant={"fullWidth"}
                      orientation={"vertical"}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      item
                      xs={12}
                      marginBottom={2}
                      sx={AccordionStyles.gridFlex}
                    >
                      <Box>
                        <Typography sx={AccordionStyles.typographyLabel}>
                          {get(shareholderInfo, "isMoral", false)
                            ? REGISTERED_SHAREHOLDERS_TEXT
                            : RECIDENCE_COUNTRY_TEXT}
                        </Typography>
                      </Box>
                      <Box>
                        {get(shareholderInfo, "isMoral", false) ? (
                          <Box sx={AccordionStyles.normalBox}>
                            <Badge
                              badgeContent={get(
                                shareholderInfo,
                                "registeredShareholders",
                                ""
                              )}
                              sx={AccordionStyles.nestedBadgeComponentLvl1}
                            />
                          </Box>
                        ) : (
                          <Box sx={AccordionStyles.normalValue}>
                            {get(shareholderInfo, "country", "")}
                          </Box>
                        )}
                      </Box>
                    </Grid>
                    {get(shareholderInfo, "isMoral", false) && (
                      <Grid
                        item
                        xs={12}
                        marginBottom={2}
                        sx={AccordionStyles.gridFlex}
                      >
                        <Box>
                          <Typography sx={AccordionStyles.typographyLabel}>
                            {PARTICIPATION_SHAREHOLDERS_TEXT}
                          </Typography>
                        </Box>
                        <Box>
                          <Box sx={AccordionStyles.normalBox}>
                            <Badge
                              color={badgeColor}
                              badgeContent={get(
                                shareholderInfo,
                                "participationPercentage",
                                ""
                              )}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    )}
                    {!get(shareholderInfo, "isMoral", false) && (
                      <Grid
                        item
                        xs={12}
                        marginBottom={2}
                        sx={AccordionStyles.gridFlex}
                      >
                        <Box>
                          <Typography sx={AccordionStyles.typographyLabel}>
                            {BIRTH_DATE_TEXT}
                          </Typography>
                        </Box>
                        <Box sx={AccordionStyles.normalValue}>
                          {get(shareholderInfo, "date", "")}
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Box>
              {!get(buttonAddSubShareholders, "hidden") && (
                <ComponentSecurityWrapper
                  componentId={
                    ComponentIdsEnum.M_CLIENTS_ADD_SUB_SHAREHOLDER_BUTTON
                  }
                >
                  <Button
                    startIcon={<IconPlus />}
                    variant="outlined"
                    onClick={() =>
                      buttonAddSubShareholders!.handleClick(nextNesting!)
                    }
                    disabled={buttonAddSubShareholders!.isDisabled}
                    sx={AccordionStyles.addButton}
                  >
                    {ADD_TEXT}
                  </Button>
                </ComponentSecurityWrapper>
              )}
            </AccordionDetails>
          )}
        </Acc>
      </Box>
    </>
  );
};

export default Accordion;
