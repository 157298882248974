/* istanbul ignore file */
/* eslint-disable */
export const environment: {
kushkiUrl: string;
envName: string;
devTools: boolean;
authority: string;
clientId: string;
redirectUri: string;
scope: string;
} = {
kushkiUrl: "https://api.kushkipagos.com",
envName: "primary",
devTools: false,
authority: "https://login.microsoftonline.com/51ac4ef1-c542-49dd-aad9-ce5e360b0286",
clientId: "280f6356-d84a-4756-819a-d4741f5232e9",
redirectUri: "https://console.kushkipagos.com/login-internal/",
scope: "api://PROD_INT_BACKOFFICE_CONSOLE_BACK/USE"
};
