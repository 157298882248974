import React from "react";
import { Edit, Trash2 } from "react-feather";
import { ModalFooter } from "@kushki/frontend-molecules/modal-footer";
import { Webhook } from "../../../types/webhook_response";
import { auth } from "../../shared/auth";
import { SecurityWrapperEnum } from "../../shared/infrastructure/constants/SecurityWrapperEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { Box } from "@material-ui/core";

export interface FooterDetailProps {
  webhookSelected: Webhook;
  handleEdit: (webhookSelected: Webhook) => void;
  handleOnDelete: (webhookSelected: Webhook) => void;
  enableEdit: boolean;
}
export const FooterDetail: React.FC<FooterDetailProps> = (
  props: FooterDetailProps
) => {
  return (
    <React.Fragment>
      <ComponentSecurityWrapper
        componentId={SecurityWrapperEnum.EDIT_DELETE_WEBHOOK}
      >
        <Box style={{ background: "#F7FAFC" }}>
          {props.enableEdit && (
            <ModalFooter
              primaryActionIcon={<Edit size={14} />}
              primaryLabelButton={"Editar Webhook"}
              primaryActionButton={() =>
                props.handleEdit(props.webhookSelected)
              }
              secondaryActionButton={() =>
                props.handleOnDelete(props.webhookSelected)
              }
              secondaryLabelButton={
                auth.isAdmin() ? "Eliminar webhook" : undefined
              }
              secondaryActionIcon={<Trash2 size={14} />}
              secondaryFontColor={"error"}
            />
          )}
        </Box>
      </ComponentSecurityWrapper>
    </React.Fragment>
  );
};
