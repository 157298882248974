import React from "react";

const ChevronSort: React.FC = (props) => {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83325 11.6667L9.99992 15.8334L14.1666 11.6667"
        stroke="#6D7781"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1667 8.33325L10.0001 4.16659L5.83341 8.33325"
        stroke="#6D7781"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChevronSort;
