import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Controller } from "react-hook-form";
import React, { FC } from "react";
import { IInputsFormProps } from "../InputsFormProps.interfaces";
import { questionInputStyles } from "./QuestionInput.styles";
import { QuestionAnswersEnum } from "../../../shared/constants/QuestionAnswersEnum";
import { QUESTION_ANSWERS } from "../../../shared/constants/inputFormConstants";

const QuestionInput: FC<IInputsFormProps> = ({
  name,
  control,
  label,
  defaultValue = "",
  onBlur,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue as string}
      render={({ field }) => (
        <FormControl>
          <FormLabel sx={questionInputStyles.title}>{label}</FormLabel>
          <RadioGroup {...field} row onBlurCapture={onBlur}>
            <FormControlLabel
              value={QuestionAnswersEnum.YES}
              control={<Radio />}
              label={QUESTION_ANSWERS[QuestionAnswersEnum.YES]}
            />
            <FormControlLabel
              value={QuestionAnswersEnum.NO}
              control={<Radio />}
              label={QUESTION_ANSWERS[QuestionAnswersEnum.NO]}
            />
          </RadioGroup>
        </FormControl>
      )}
    />
  );
};

export default QuestionInput;
