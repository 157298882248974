/**
 * CatalogsEnum
 */
export enum CatalogsEnum {
  BankAccountTypes = "sBankAccountTypes",
  IdTypes = "sIdTypes",
  Banks = "sBanks",
  Provinces = "sProvinces",
  Cities = "sCities",
}
