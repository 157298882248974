import { useState } from "react";
import { IUseMassiveHeader } from "./IUseMassiveHeader.interfaces";
import { defaultTo } from "lodash";
import { useAppSelector } from "../../../../store/hooks/storeHook";
import { AppRoutes } from "../../../../shared/infrastructure/routes";

export const useMassiveHeader = (): IUseMassiveHeader => {
  const {
    massiveInformation: { listBranches, title },
  } = useAppSelector((store) => store.layout);
  const [openReturnModal, setOpenReturnModal] = useState<boolean>(false);
  const [openSearchBranchModal, setOpenSearchBranchModal] = useState<boolean>(
    false
  );
  const handleOpenSearchBranchModal = (): void => {
    setOpenSearchBranchModal(true);
  };
  const handleCloseSearchBranchModal = (): void => {
    setOpenSearchBranchModal(false);
  };
  const handleOpenReturnModal = (): void => {
    setOpenReturnModal(true);
  };
  const handleCloseReturnModal = (): void => {
    setOpenReturnModal(false);
  };
  const branchesTitle = (): string =>
    defaultTo(listBranches.length, 0) > 3 ? title.concat("...") : title;
  const handlePrimaryButtonModal = (): void => {
    window.location.href = AppRoutes.CREATE_NODE_BRANCH_SELECTION;
  };

  return {
    handleOpenSearchBranchModal,
    handleCloseSearchBranchModal,
    handlePrimaryButtonModal,
    handleOpenReturnModal,
    handleCloseReturnModal,
    totalBranches: listBranches.length,
    branchesTitle: branchesTitle(),
    openSearchBranchModal,
    openReturnModal,
  };
};
