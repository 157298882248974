export enum PersonTypeMXEnum {
  MORAL = "02",
  FISICA = "01",
}

export enum PersonType_EC_PE_Enum {
  JURIDICA = "02",
  NATURAL = "01",
}

export enum PersonType_CR_Enum {
  JURIDICA = "02",
  FISICA = "01",
}

export enum PersonTypeHNEnum {
  MORAL = "02",
  FISICA = "01",
}

export enum PersonTypeGUAEnum {
  MORAL = "02",
  FISICA = "01",
}

export enum TipoPersonaValueEnum {
  UNO = "01",
  DOS = "02",
}

export enum PersonType_NI_Enum {
  JURIDICA = "02",
  FISICA = "01",
}

export enum PersonType_SV_Enum {
  MORAL = "02",
  FISICA = "01",
}

export enum PersonType_Generic_Enum {
  JURIDICA = "02",
  NATURAL = "01",
}

export const PersonTypeCatalog: string[] = [
  PersonType_Generic_Enum.JURIDICA,
  PersonType_Generic_Enum.NATURAL,
];
