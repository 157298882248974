import React, { Fragment } from "react";
import { DialogContent, Grid, Typography } from "@mui/material";
import { SingleInformativeModalStyles } from "./SingleInformativeModalDownloads.styles";
import { SingleInformativeModalProps } from "./SingleInformativeModalDownloads.interfaces";

export const SingleInformativeModalDownloads = (
  props: SingleInformativeModalProps
) => {
  const modalSubTitleAmount: string = "Monto de la transacción:";

  return (
    <Fragment>
      <DialogContent>
        <Grid container>
          <Grid item justifyContent={"space-between"} alignItems={"center"}>
            <Typography sx={SingleInformativeModalStyles.titleText}>
              {props.merchantName}
            </Typography>
          </Grid>
          <Grid
            container
            item
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography sx={SingleInformativeModalStyles.titleText}>
              Merchant ID: {props.merchantId}
            </Typography>
          </Grid>

          <Grid
            container
            item
            justifyContent={"space-between"}
            sx={SingleInformativeModalStyles.dialogFootText}
          >
            <Typography sx={SingleInformativeModalStyles.titleText}>
              {modalSubTitleAmount}
            </Typography>
            <Typography sx={SingleInformativeModalStyles.titleText}>
              {`${props.approvedTransactionAmount} ${props.currency}`}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Fragment>
  );
};
