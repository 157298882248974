import {
  Box,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import React from "react";
import { FormSkeleton } from "../FormSkeleton/FormSkeleton";
import { chargeContainerSkeletonStyles as styles } from "./ChargeContainerSkeleton.styles";

export const ChargeContainerSkeleton: React.FC = () => {
  return (
    <Card sx={styles.cardContent} data-testid="charge-skeleton">
      <CardContent>
        <Box sx={styles.boxContent2}>
          <Typography>
            <Skeleton />
          </Typography>
          <Grid
            container
            spacing={1}
            alignItems="center"
            sx={{ padding: "15px 5px", width: "100%" }}
          >
            <Grid md={12} xs={12} item>
              <Grid
                container
                spacing={1}
                alignItems="center"
                sx={{ marginTop: "1em", width: "60%" }}
              >
                <Grid item sx={{ pt: "32px" }} md={12} xs={12}>
                  <Typography>
                    <Skeleton />
                  </Typography>
                </Grid>
                <Grid
                  md={12}
                  xs={12}
                  item
                  sx={{ marginBottom: "1em", marginTop: "1em" }}
                >
                  <FormSkeleton rows={1} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ChargeContainerSkeleton;
