import { Grid, IconButton } from "@mui/material";
import { Accordion, IconTrash } from "@kushki/connect-ui";
import { LabelEnum } from "../../../../shared/infrastructure/enums/LabelEnum";
import React, { PropsWithChildren } from "react";
import { IConditionItemComponentProps } from "../../../../shared/infrastructure/interfaces/IConditionItemComponentProps";
import { useConditionItemComponentState } from "./state/useConditionItemComponentState";
import { globalInformationComponentStyles } from "../../../GlobalInformationComponent/GlobalInformationComponent.styles";
import { GenericSelectComponent } from "../GenericSelectComponent/GenericSelectComponent";
import { ErrorsEnum } from "../../../../shared/infrastructure/enums/ErrorEnum";
import { get } from "lodash";

export const ConditionItemComponent = (
  props: PropsWithChildren<IConditionItemComponentProps>
) => {
  const classes = globalInformationComponentStyles();

  const {
    selectConditions,
    control,
    errors,
    getOperatorsByConditions,
    isFilledTypeOperator,
    retrieveTextFieldsByRuleAndOperator,
  } = useConditionItemComponentState({
    conditionsItemComponentProps: props,
    classes,
  });

  return (
    <React.Fragment>
      <Accordion
        divider={false}
        button={
          <IconButton
            color="secondary"
            onClick={(event) => {
              props.handleRemoveCondition(props.index);
              event.stopPropagation();
            }}
          >
            <IconTrash />
          </IconButton>
        }
        nesting={1}
        text={`${LabelEnum.CONDITION_TITLE_ACCORDION} ${
          props.fieldsLength - props.index
        }`}
        isOpen={true}
        nestingLabel={""}
      >
        <Grid container className={classes.itemCondition} spacing={1}>
          <Grid item xs={4}>
            <GenericSelectComponent
              mainProps={props}
              isRuleRequired={undefined}
              className={classes.errorColor}
              isRenderLabel={true}
              placeHolder={LabelEnum.SELECT_CATEGORY}
              name={"label"}
              control={control}
              selectConditions={selectConditions}
              getOperatorsByConditions={undefined}
              errors={errors}
            />
          </Grid>
          {get(getOperatorsByConditions(), "length", 0) > 0 && (
            <Grid item xs={4}>
              <GenericSelectComponent
                mainProps={props}
                isRuleRequired={{
                  required: {
                    message: ErrorsEnum.REQUIRED_FIELD,
                    value: true,
                  },
                }}
                className={classes.errorColor}
                isRenderLabel={false}
                placeHolder={LabelEnum.SELECT_CONDITIONAL}
                name={"operator"}
                control={control}
                selectConditions={undefined}
                getOperatorsByConditions={getOperatorsByConditions()}
                errors={errors}
              />
            </Grid>
          )}
          {isFilledTypeOperator() && retrieveTextFieldsByRuleAndOperator()}
        </Grid>
      </Accordion>
    </React.Fragment>
  );
};
