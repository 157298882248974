import { Box, Typography } from "@mui/material";
import BaseDataContainer from "../commons/BaseDataContainer/BaseDataContainer";
import { useProcessors } from "./state/useProcessors";
import { CustomStyles } from "../../shared/infrastructure/interfaces/CustomStyles";
import { IconCircleFill, TableInfo } from "@kushki/connect-ui";
import { EntityNameEnum } from "../../shared/enums/entityName";
import { FC } from "react";
import { AddProcessorButton } from "./components/AddProcessorButton/AddProcessorButton";
import {
  DEFAULT_PROCESSOR,
  EDIT_REVIEW_LINK_PROCESSOR,
  EMPTY_PROCESSOR_LIST,
  PROCESSOR_TITLE,
  PROCESSORS_CREATED,
  REVIEW_LINK_PROCESSOR,
} from "../../shared/constants/labels/processors_form_labels";

const styles: CustomStyles = {
  emptyBox: {
    ".addButton": {
      paddingBottom: "30px",
      paddingTop: "12px",
    },
    ".message": {
      paddingBottom: "12px",
      paddingTop: "30px",
    },
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  heading: {
    display: "flex",
    paddingBottom: "1.3rem",
  },
};

const Processors: FC = () => {
  const {
    country,
    table,
    handleAddProcessor,
    handleEdit,
    isCentralized,
    entityName,
    publicMerchantId,
    isShowAddButton,
  } = useProcessors();

  return (
    <BaseDataContainer
      isCentralized={isCentralized}
      entityName={entityName}
      title={PROCESSOR_TITLE}
      textEditButton={
        isCentralized && entityName === EntityNameEnum.BRANCH
          ? REVIEW_LINK_PROCESSOR
          : EDIT_REVIEW_LINK_PROCESSOR
      }
      onClickEdit={handleEdit}
      withoutEdit={table.rows.length === 0}
    >
      <Box bgcolor="neutral.grey3" borderRadius="8px" p={3}>
        {table.rows.length === 0 ? (
          <Box sx={styles.emptyBox}>
            <Typography color="primary" variant="h6" className={"message"}>
              {EMPTY_PROCESSOR_LIST}
            </Typography>
            {isShowAddButton && (
              <AddProcessorButton
                country={country}
                onItemSelected={handleAddProcessor}
                publicMerchantId={publicMerchantId}
                className={"addButton"}
              />
            )}
          </Box>
        ) : (
          <>
            <Typography
              color="text.dark"
              variant="subtitle2"
              sx={styles.heading}
            >
              {PROCESSORS_CREATED}
            </Typography>

            <Box>
              <Typography
                color="text.dark"
                variant="caption"
                sx={styles.heading}
              >
                <Box marginRight={"8px"}>
                  <IconCircleFill fontSize={"small"} color={"info"} />
                </Box>
                {DEFAULT_PROCESSOR}
              </Typography>
              <Box overflow={"auto"}>
                <TableInfo
                  isLoading={false}
                  header={{
                    cells: table.columns,
                    headerProps: {
                      isDisable: false,
                    },
                  }}
                  rows={table.rows}
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </BaseDataContainer>
  );
};

export default Processors;
