import React, { FC, memo } from "react";
import EmptyTable from "../common/EmptyTable/EmptyTable";
import { Table } from "../common/Table";
import RefundDetailModal from "../modals/RefundDetailsModal/RefundDetailModal";
import { useRefundsTableState } from "./state/useRefundsTableState";

const RefundsTable: FC = () => {
  const {
    getColumns,
    isEmptyTable,
    isLoadingTable,
    isRefundModalOpen,
    onCheckboxClick,
    onCloseRefundModal,
    onRowClick,
    refunds,
    selectedRefund,
  } = useRefundsTableState();

  if (isLoadingTable) {
    return <Table columns={getColumns()} data={[]} rowId="refundId" loading />;
  }

  if (isEmptyTable) {
    return <EmptyTable />;
  }

  return (
    <>
      <Table
        columns={getColumns()}
        data={refunds}
        loading={false}
        rowId={"refundId"}
        onCheckboxClick={onCheckboxClick}
        onRowClick={onRowClick}
      />

      <RefundDetailModal
        refundTransaction={selectedRefund}
        isOpen={isRefundModalOpen}
        isHistoricRefundTransaction={false}
        onCloseModal={onCloseRefundModal}
      />
    </>
  );
};

export default memo(RefundsTable);
