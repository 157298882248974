import { QuestionAnswersEnum } from "./QuestionAnswersEnum";

export const ERROR_MESSAGES = {
  invalid_email: "El correo ingresado es inválido",
  only_letters: "Este campo sólo permite letras",
  only_numbers: "Este campo solo permite números",
  required: "*Campo requerido",
};

export const QUESTION_ANSWERS = {
  [QuestionAnswersEnum.YES]: "Si",
  [QuestionAnswersEnum.NO]: "No",
};
