import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import useStyles from "./CatalogInfoPopup.styles";
import { CatalogsTextEnum } from "../../shared/infrastructure/Enums/CatalogsTextEnum";
import {
  CatalogInfoPopupProps,
  ICatalogConfig,
} from "./CatalogInfoPopup.interfaces";
import {
  RetentionCatalogColumns,
  RetentionCatalogFields,
  TypeRetentionEnum,
} from "../../shared/infrastructure/constants/RetentionConstants";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/reducer";
import { RetentionMerchantConfigDynamo } from "../../../types/retention_merchant_configuration";
import { defaultTo, get } from "lodash";
import {
  CatalogPropertyEnum,
  RetentionCatalogId,
} from "../../shared/infrastructure/Enums/CatalogEnum";
import {
  getCatalogDataByCode,
  getCatalogPropertyById,
  getRetentionsConfig,
  getRetentionType,
  verifyExistsItemCatalog,
} from "../../utils/functions/Catalog";
import { useCatalogInfoPopupState } from "./state/useCatalogInfoPopupState";
import { CatalogsNameEnum } from "../../shared/infrastructure/Enums/CatalogsNameEnum";
import { RetentionTextEnum } from "../../shared/infrastructure/Enums/RetentionTextEnum";
import { CatalogDownloadReport } from "../CatalogDownloadReport/CatalogDownloadReport";

export const CatalogInfoPopup: React.FC<CatalogInfoPopupProps> = (
  props: CatalogInfoPopupProps
) => {
  const classes = useStyles();
  const { handleClosePopup } = useCatalogInfoPopupState();
  const { retention, catalogsList, catalogRequest, base64Csv } = useSelector<
    IAppState,
    IAppState
  >((state: IAppState) => state);
  const catalogId: string = defaultTo(
    get(catalogRequest, CatalogPropertyEnum.ID),
    ""
  );
  const catalogName: string = getCatalogPropertyById(
    catalogsList,
    CatalogPropertyEnum.NAME,
    "",
    catalogId
  );
  const retentionType: TypeRetentionEnum = getRetentionType(catalogName);
  const { itemCatalog } = verifyExistsItemCatalog(
    catalogsList!,
    CatalogPropertyEnum.ID,
    catalogId,
    defaultTo(get(catalogRequest, CatalogPropertyEnum.CODE), "")
  );
  const { isOpenPopup } = props;
  const isLoading: boolean = false;
  const columns: string[] = RetentionCatalogColumns[retentionType];
  const fields: ICatalogConfig[] = RetentionCatalogFields[retentionType];
  const retentionFilters: RetentionMerchantConfigDynamo[] = getRetentionsConfig(
    retention!,
    catalogName as CatalogsNameEnum,
    defaultTo(get(itemCatalog, CatalogPropertyEnum.CODE), "")
  );

  const getDescriptionByType = (
    data: RetentionMerchantConfigDynamo,
    config: ICatalogConfig
  ): string => {
    return config.catalogType
      ? get(
          getCatalogDataByCode(
            catalogsList!,
            config.catalogType,
            get(data, RetentionCatalogId[config.catalogType], "")
          ),
          CatalogPropertyEnum.VALUE
        )
      : `${defaultTo(get(data, config.field!), "")}${get(
          config,
          "concatValue"
        )}`;
  };

  const buildColumnRetention = (
    data: RetentionMerchantConfigDynamo
  ): JSX.Element[] =>
    fields.map((field: ICatalogConfig, index: number) => (
      <TableCell key={index} className={classes.columnItem}>
        {getDescriptionByType(data, field)}
      </TableCell>
    ));

  return (
    <Dialog open={isOpenPopup} maxWidth={"md"}>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.title}>
              No se puede eliminar la opción actual del catálogo
            </Typography>
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.subtitle}>
                Se encontraron dependencias en las configuraciones de{" "}
                {retentionType === TypeRetentionEnum.IVA
                  ? RetentionTextEnum.IVA
                  : RetentionTextEnum.RENTA}
                .
                <br />
                Primero elimine las configuraciones dependientes y luego elimine
                la opción actual del catálogo.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.legend}>
                Configuraciones dependientes encontradas
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TableContainer style={{ marginTop: "24px" }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow className={classes.headTitle}>
                    {columns.map((column: string, index: number) => (
                      <TableCell key={index} style={{ paddingLeft: "0" }}>
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {retentionFilters.map(
                    (data: RetentionMerchantConfigDynamo, index: number) => (
                      <TableRow key={index}>
                        {buildColumnRetention(data)}
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <CatalogDownloadReport base64Csv={base64Csv} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          color={"primary"}
          className={classes.btnAgreed}
          onClick={handleClosePopup}
          disabled={isLoading}
        >
          {CatalogsTextEnum.AGREED}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
