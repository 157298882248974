import { useEffect, useState } from "react";
import {
  ALL_TABLE_COLUMNS,
  TAG_CELL,
  TEXT_CELL,
} from "../../../shared/infrastructure/constants/CredentialsConstants";
import { useHistory } from "react-router";
import { ITableRowProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { useDispatch, useSelector } from "react-redux";
import instance from "../../../shared/axios-util";
import { ICredentials } from "./useCredentials.interface";
import {
  selectCredential,
  selectNodeInfo,
} from "../../../store/selectors/selectors";
import { setCredentials } from "../../../store/actions/actions";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { routes } from "../../../shared/infrastructure/constants/routes";
import { IRowInteractiveProps } from "@kushki/connect-ui/dist/Components/Molecules/Table/RowInteractiveInfo/RowInteractive.interfaces";

export const useCredentials = () => {
  const dispatch = useDispatch();

  const credentials = useSelector(selectCredential);

  const nodeInfo = useSelector(selectNodeInfo);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [rows, setRows] = useState<ITableRowProps[]>([]);

  const history = useHistory();

  const showCredentials = () => {
    history.push({
      pathname: routes.CREDENTIALS + nodeInfo?.merchantId,
    });
  };

  const onClickRow = () => {};

  useEffect(() => {
    if (!credentials) {
      return;
    }

    const valuesToRow = credentials.map(
      (column: ICredentials, index: number): ITableRowProps => {
        const cells = [
          TEXT_CELL(column.alias),
          TEXT_CELL(column.credentialId),
          TAG_CELL(column.enable),
        ];

        return {
          cells,
          id: `credential ${index}`,
          rowProps: {
            color: "default",
            onClick: onClickRow,
          } as IRowInteractiveProps,
        };
      }
    );

    setRows(valuesToRow);
    setIsLoading(false);
  }, [credentials]);

  useEffect(() => {
    const credentialCall = async () => {
      if (!nodeInfo?.merchantId) {
        return;
      }
      const credentialsResponse = await instance.request<ICredentials[]>({
        method: "GET",
        url: `${API_ROUTES.CREDENTIALS}/${nodeInfo!.merchantId}`,
      });

      dispatch(setCredentials(credentialsResponse.data));
    };

    credentialCall();
  }, [nodeInfo]);

  return {
    isLoading,
    showCredentials,
    table: {
      columns: ALL_TABLE_COLUMNS,
      rows,
    },
  };
};
