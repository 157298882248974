import { IStyles } from "../../shared/interfaces/create_named_styles";

export const footerStyles: IStyles = {
  buttonContainer: {
    paddingLeft: { xs: "10%", md: "17%" },
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    padding: {
      xs: "15px 25px 15px 25px",
      sm: "30px 50px 30px 50px",
    },
    backgroundColor: "#FAFCFD",
  },
  footerContainer: {
    position: "absolute",
    bottom: "0",
  },
  labelBox: {
    display: "inline-block",
    padding: { xs: "10px 20px 0px 0px", lg: "14px 10px 0px 0px" },
  },
  label: {
    color: "#64798D",
    fontWeight: 400,
    fontSize: { xs: "15px", md: "13px" },
    fontFamily: "IBM Plex Sans",
  },
  labelContainer: {
    paddingLeft: { sm: "3%", md: "15%", lg: "0%" },
  },
  saveButton: { marginRight: "50px" },
  buttonStyle: {
    border: "1px solid #4498EE",
    color: "#4498EE",
    "&:hover": {
      color: "#023365",
    },
  },
};
