import { useNavigate, useParams } from "react-router-dom";
import { useForm, UseFormReturn } from "react-hook-form";
import {
  AlarmTypeParse,
  initialValuesForm,
  TypeEnum,
} from "@shared/constants/AlarmConfigConstants";
import {
  IAlarmConfigForm,
  IRuleAlarmRequest,
  IUseAlarmConfig,
} from "@containers/AlarmConfig/AlarmConfig.interfaces";
import { useEffect, useState } from "react";
import { defaultTo, get, isEmpty } from "lodash";
import { getItemSessionStorage } from "@shared/utils/sessionStorageUtil";
import { SessionStorageKey } from "@shared/enum/sessionStorageKeyEnum";
import { useAppDispatch, useAppSelector } from "@store/hooks/storeHook";
import {
  generateRuleAlarm,
  getRuleAlarm,
} from "@store/thunks/alarmConfig/alarmConfig.thunk";
import { buildNodesRules, parseVariables } from "@shared/utils/alarmConfigUtil";
import { RootState } from "@store/store";
import { clearSelectedNodes } from "@shared/utils/clearSelectedNodes";
import { INodeRuleSessionStorage } from "@shared/interfaces/INodeRuleSessionStorage";

export const useAlarmConfig = (): IUseAlarmConfig => {
  let { type } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isDisabledButton, alarmRule, isLoadingEditForm } = useAppSelector(
    (state: RootState) => state.alarmConfig
  );
  const { ruleId } = useParams();

  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const form: UseFormReturn<IAlarmConfigForm> = useForm<IAlarmConfigForm>({
    defaultValues: initialValuesForm,
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const handleCloseCancelModal = () => {
    setOpenCancelModal(false);
  };

  const handleOpenCancelModal = () => {
    setOpenCancelModal(true);
  };

  const handlePrimaryButtonModal = () => {
    clearSelectedNodes(dispatch);
    navigate(`/${defaultTo(type, "")}`);
  };

  const handleSaveRules = async () => {
    const validForm = await form.trigger();
    const request: IRuleAlarmRequest = {
      alarmType: defaultTo(AlarmTypeParse[defaultTo(type, "")], ""),
      alias: form.getValues("alias")!,
      frequency: form.getValues("frequency")!,
      nodes: buildNodesRules(),
      ruleId,
      variables: parseVariables(form.getValues("variables")!),
    };

    if (validForm) {
      dispatch(generateRuleAlarm(request));
    }
  };

  useEffect(() => {
    if (!isEmpty(ruleId)) {
      setIsEdit(true);

      return;
    }

    setIsEdit(false);
  }, [ruleId]);

  useEffect(() => {
    if (!isEdit) return;

    dispatch(getRuleAlarm(defaultTo(ruleId, "")));
  }, [isEdit]);

  useEffect(() => {
    if (!isEdit) return;

    form.setValue("alias", get(alarmRule, "alias", ""));
    form.setValue("frequency", get(alarmRule, "frequency", ""));
    form.setValue("variables", get(alarmRule, "variables", []));
  }, [alarmRule]);

  return {
    form,
    handleCloseCancelModal,
    handleOpenCancelModal,
    handlePrimaryButtonModal,
    handleSaveRules,
    isDisabledButton,
    isEdit,
    isLoadingEditForm,
    openCancelModal,
    pathParam: type,
    selectedNodes: getItemSessionStorage<INodeRuleSessionStorage[]>(
      SessionStorageKey.SELECTED_NODES,
      "[]"
    ),
    showSelectedNodes: type === TypeEnum.INDIVIDUAL,
  };
};
