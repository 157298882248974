import React, { FC } from "react";
import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import ChevronsSortIcon from "../../../assets/icons/ChevronsSortIcon/ChevronsSortIcon";
import { TRANSACTIONS_TABLE_COLUMNS } from "../../../shared/constants";
import { transactionsTableHeadStyles } from "./TransactionsTableHead.styles";
import { ITransactionsTableHeadProps } from "./TransactionsTableHead.interfaces";

const TransactionsTableHead: FC<ITransactionsTableHeadProps> = ({
  areAllItemsSelected,
  disabled = false,
  onChangeSelection = () => {},
  onClickSortDate = () => {},
}: ITransactionsTableHeadProps) => (
  <TableHead>
    <TableRow>
      {TRANSACTIONS_TABLE_COLUMNS.map((column) => (
        <TableCell
          key={column.id}
          padding={column.padding}
          sx={transactionsTableHeadStyles.cell}
        >
          {column.type === "checkbox" ? (
            <Checkbox
              checked={areAllItemsSelected}
              disabled={disabled}
              onChange={onChangeSelection}
            />
          ) : (
            <>
              {column.sortable && column.id === "date" ? (
                <TableSortLabel
                  IconComponent={ChevronsSortIcon}
                  onClick={onClickSortDate}
                  sx={transactionsTableHeadStyles.sortLabel}
                >
                  {column.label}
                </TableSortLabel>
              ) : (
                <span>{column.label}</span>
              )}
            </>
          )}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

export default TransactionsTableHead;
