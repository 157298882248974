import { SnackBarProvider, theme } from "@kushki/connect-ui";
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import ContactDataContainer from "./containers/ContactDataContainer/ContactDataContainer";
import { Routes, SPA_BASENAME } from "./shared/constants/routes";
import { store } from "./store/store";
import LegalDatailsContainer from "./containers/LegalDetailsContainer/LegalDatailsContainer";
import BasicStateFormContainer from "./containers/BasicFormStateContainer/BasicStateFormContainer";
import BillingDataContainer from "./containers/BillingData/BillingDataContainer";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { M_CLIENTS } from "./shared/constants/labels/main_containter_labels";
import { environment } from "./environments/environment";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
import BasicStateFormContainerMassive from "./containers/BasicFormStateContainerMasive/BasicStateFormContainerMassive";
import BillingDataContainerMassive from "./containers/BillingDataMassive/BillingDataContainerMassive";
import ContactDataContainerMassive from "./containers/ContactDataContainerMassive/ContactDataContainerMassive";
import LegalDatailsContainerMassive from "./containers/LegalDetailContainerMassive/LegalDetailsContainerMassive";
import { Provider as RollbarProvider } from "@rollbar/react";
import { getRollbarConfig } from "./shared/constants/GetRollbarConfig";

const Root = () => {
  return (
    <React.StrictMode>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&display=swap');"
        }
      </style>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500&display=swap');"
        }
      </style>
      <RollbarProvider config={getRollbarConfig}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <SnackBarProvider>
              <BrowserRouter basename={SPA_BASENAME}>
                <ModuleSecurityWrapper
                  basePath={environment.kushkiUrl}
                  componentId={M_CLIENTS}
                >
                  <Switch>
                    <Route
                      path={Routes.BASIC_DETAILS}
                      exact
                      component={BasicStateFormContainer}
                    />
                    <Route
                      path={Routes.EDIT_BRANCH_BASIC_DETAILS}
                      exact
                      component={BasicStateFormContainerMassive}
                    />
                    <Route
                      path={Routes.BILLING_DETAILS}
                      exact
                      component={BillingDataContainer}
                    />
                    <Route
                      path={Routes.EDIT_BRANCH_BILLING_DETAILS}
                      exact
                      component={BillingDataContainerMassive}
                    />
                    <Route
                      path={Routes.CONTACT_DETAIL}
                      exact
                      component={ContactDataContainer}
                    />
                    <Route
                      path={Routes.EDIT_BRANCH_CONTACT_DETAIL}
                      exact
                      component={ContactDataContainerMassive}
                    />
                    <Route
                      path={Routes.LEGAL_DETAILS}
                      exact
                      component={LegalDatailsContainer}
                    />
                    <Route
                      path={Routes.EDIT_BRANCH_LEGAL_DETAILS}
                      exact
                      component={LegalDatailsContainerMassive}
                    />
                  </Switch>
                </ModuleSecurityWrapper>
              </BrowserRouter>
            </SnackBarProvider>
          </ThemeProvider>
        </Provider>
      </RollbarProvider>
    </React.StrictMode>
  );
};

export default Root;
