import { useState } from "react";
import { environment } from "../../../../environments/environment";
import axiosInstance from "../../../../shared/axios-util";
import { SaveCredential } from "../../../../../types/save_credential_request";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { DomainByMerchant } from "../../../../shared/interfaces/DomainByMerchant";
import { PluginTypeEnum } from "../../../../shared/infrastructure/constants/PluginTypeEnum";
import { ChannelEnum } from "../../../../shared/infrastructure/constants/ChannelEnum";
import { INotification } from "../../../../store/reducer";
import { AliasCredentialEnum } from "../../../../shared/infrastructure/constants/AliasCredentialEnum";
import {
  MerchantCredentialResponse,
  MerchantCredentialRequest,
  MerchantCredentialsState,
} from "../ShopifyDomain.interfaces";
import { PathEnum } from "../../../../shared/infrastructure/constants/PathEnum";

const REGEX_SHOPIFY_DOMAIN = /^(?!https?:)(?:[\w-]+\.)+[a-z]{2,6}$/i;

const useShopifyDomainState = () => {
  const [disableSave, setDisableSave] = useState(true);
  const [savedDomain, setSavedDomain] = useState<DomainByMerchant>();
  const [showChangeUrlModal, setShowChangeUrlModal] = useState(false);
  const [isValid, setValid] = useState(false);
  const [shopifyDomain, setShopifyDomain] = useState("");
  const [isSaveDomain, setIsSaveDomain] = useState(false);
  const [errorDomain, setErrorDomain] = useState<string>(
    "Hubo un problema al guardar tu dominio. Por favor intenta ingresar otro."
  );
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
  const [notification, setNotification] = useState<INotification>({
    message: "",
    type: "error",
    open: false,
  });
  const [executeCredential, setExecuteCredential] = useState<boolean>(false);
  const [hasDomain, setHasDomain] = useState<boolean>(false);
  const [
    merchantCredentials,
    setMerchantCredentials,
  ] = useState<MerchantCredentialsState>({
    publicCredential: "",
    privateCredential: "",
    hasCredentials: false,
  });
  const [showBlurredText, setShowBlurredText] = useState<boolean>(false);
  const merchantId = localStorage.getItem("merchantId")!;
  const history = useHistory();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await axiosInstance.get<DomainByMerchant[]>(
          `${environment.kushkiUrl}/plugins/v1/getDomainsByMerchant/${merchantId}`
        );

        const domainByMerchant = data.find(
          (d) => d.channel === ChannelEnum.SHOPIFY
        );

        if (domainByMerchant) {
          setShopifyDomain(domainByMerchant.domain);
          setSavedDomain(domainByMerchant);
          setHasDomain(true);
        }

        setValid(Boolean(domainByMerchant));
        setIsSaveDomain(Boolean(domainByMerchant));
      } catch {
        setValid(false);
        setIsSaveDomain(false);
        setNotification({
          message: "Ha ocurrido un error al cargar la información del dominio.",
          open: true,
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (hasDomain && !merchantCredentials.hasCredentials) {
        const method: string = PathEnum.GET_PAYMENT_CREDENTIALS_SUGGESTIONS;

        const uri = `${environment.kushkiUrl}${method}`;

        const request: MerchantCredentialRequest = {
          searchTerm: PluginTypeEnum.SHOPIFY,
          merchantId,
        };

        setLoading(true);
        try {
          const { data } = await axiosInstance.post<MerchantCredentialResponse>(
            uri,
            request
          );

          const credentials = data.data;

          if (credentials.length > 0) {
            const merchantCredential = credentials[0];

            setMerchantCredentials({
              privateCredential: merchantCredential.privateCredentialId,
              publicCredential: merchantCredential.publicCredentialId,
              hasCredentials:
                Boolean(merchantCredential.privateCredentialId) &&
                Boolean(merchantCredential.publicCredentialId),
            });
          }
        } catch {
          setNotification({
            message: "Ha ocurrido un error al cargar las credenciales.",
            open: true,
            type: "error",
          });
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [hasDomain]);

  const handleSubmitDomain = async () => {
    setShowChangeUrlModal(false);
    const body: SaveCredential = {
      domain: shopifyDomain,
      merchantId: merchantId,
      pluginType: PluginTypeEnum.SHOPIFY,
    };

    setLoading(true);
    try {
      await axiosInstance.post(
        `${environment.kushkiUrl}/plugins/v1/saveCredentials`,
        body
      );

      if (hasDomain) {
        setNotification({
          message: "Tu dominio se ha actualizado con éxito.",
          open: true,
          type: "success",
        });
      }

      setIsSaveDomain(true);
      setDisableSave(true);
      setSavedDomain({
        domain: shopifyDomain,
        channel: PluginTypeEnum.SHOPIFY,
      });
      setExecuteCredential(true);
    } catch (error) {
      const messageError = (error as {
        response?: {
          data?: {
            message: string;
          };
        };
      }).response?.data?.message;
      if (
        messageError &&
        messageError === "Este Dominio se encuentra vinculado a otro comercio."
      ) {
        setErrorDomain(
          "Este dominio ya es utilizado por otro comercio. Por favor intenta ingresar otro."
        );
        setShowDialog(true);
      } else {
        setNotification({
          message:
            "Ha ocurrido un error al guardar el dominio. Vuelve a intentarlo.",
          open: true,
          type: "error",
        });
      }

      setValid(false);
      setIsSaveDomain(false);
      setExecuteCredential(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!hasDomain && executeCredential) {
      setLoading(true);
      const save = async () => {
        const credentialsRequest = {
          alias: AliasCredentialEnum.SHOPIFY,
          merchantId: merchantId,
          hidden: false,
        };

        const credentialsResponse = await axiosInstance.post(
          `${environment.kushkiUrl}/payment-credentials/v1/admin/credentials`,
          credentialsRequest
        );
        setMerchantCredentials({
          privateCredential: credentialsResponse.data.privateCredentialId,
          publicCredential: credentialsResponse.data.publicCredentialId,
          hasCredentials:
            Boolean(credentialsResponse.data.privateCredentialId) &&
            Boolean(credentialsResponse.data.publicCredentialId),
        });
        setShowSuccessDialog(true);
        setLoading(false);
      };

      save().catch((_error) => {
        setNotification({
          message:
            "Ha ocurrido un error al guardar las credenciales. Vuelve a intentarlo.",
          open: true,
          type: "error",
        });
        setLoading(false);
      });
      setExecuteCredential(false);
      setHasDomain(true);
    }
  }, [executeCredential]);

  const handleSaveButton = async () => {
    if (savedDomain?.domain) {
      setShowChangeUrlModal(true);
    } else {
      await handleSubmitDomain();
    }
  };

  const handleCloseModal = () => {
    setShowChangeUrlModal(false);
  };

  const handleChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    setShopifyDomain(e.target.value);
    setValid(REGEX_SHOPIFY_DOMAIN.test(e.target.value));
    setIsSaveDomain(false);

    if (savedDomain?.domain === e.target.value) setDisableSave(true);
    else setDisableSave(!REGEX_SHOPIFY_DOMAIN.test(e.target.value));
  };

  const handleOnClose = () => {
    setShowDialog(false);
  };

  const handleCloseSuccessDialog = () => {
    setShowSuccessDialog(false);
  };

  const handleOnClikConfigurateButton = () => {
    history.push("/webcheckout");
  };

  const handleOnCloseNotification = () => {
    setNotification({
      message: "",
      type: "success",
      open: false,
    });
  };

  const handleClickShowBlurredText = () => {
    setShowBlurredText(!showBlurredText);
  };

  const handleClickCopyPublicCredential = () => {
    navigator.clipboard.writeText(merchantCredentials.publicCredential!);
    setNotification({
      message: "Credencial Pública copiada al portapapeles",
      type: "info",
      open: true,
    });
  };

  const handleClickCopyPrivateCredential = () => {
    navigator.clipboard.writeText(merchantCredentials.privateCredential!);
    setNotification({
      message: "Credencial Privada copiada al portapapeles",
      type: "info",
      open: true,
    });
    handleClickShowBlurredText();
  };

  return {
    actions: {
      handleChange,
      handleOnClikConfigurateButton,
      handleOnClose,
      handleSaveButton,
      handleCloseModal,
      handleSubmitDomain,
      handleClickShowBlurredText,
      handleClickCopyPublicCredential,
      handleClickCopyPrivateCredential,
    },
    disableSave,
    errorDomain,
    isValid,
    loading,
    shopifyDomain,
    showChangeUrlModal,
    showDialog,
    isSaveDomain,
    showSuccessDialog,
    merchantCredentials,
    showBlurredText,
    handleCloseSuccessDialog,
    notification,
    handleOnCloseNotification,
    hasDomain,
  };
};

export default useShopifyDomainState;
