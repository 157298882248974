/**
 * ConfirmModalEnum
 */
import { LabelEnum } from "./LabelEnum";
import { IModalUpdateStatusComponents } from "../interfaces/IModalRejectComponent";

export enum ConfirmModalEnum {
  TITLE = "¿Desea enviar a revisión esta solicitud?",
  TITLE_EDIT = "¿Desea editar esta regla?",
  TITLE_DISABLE_REQUEST = "¿Desea deshabilitar esta solicitud?",
  TITLE_DISABLE_RULE = "¿Desea deshabilitar esta regla?",
  TITLE_DISABLE = "Deshabilitar",
  TITLE_APPROVAL_REQUEST = "¿Desea aprobar esta solicitud?",
  BODY = "Al crearla afectarás lo siguiente:",
  BODY_EDIT = "Al editar esta regla afectarás lo siguiente:",
  BODY_DISABLE = "Al deshabilitar esta regla afectarás lo siguiente:",
  BODY_APPROVE = "Al crear esta regla afectarás lo siguiente:",
  CUSTOMER = "Customer: ",
  BRANCH = "Branch: ",
  SEND_BUTTON = "Enviar solicitud",
  ERROR_MSG_ALERT = "Hubo un error al enviar la solicitud. Por favor vuelve a intentarlo.",
  DISABLE_RULE_ERROR = "Hubo un error al deshabilitar la regla. Por favor vuelve a intentarlo.",
  SUCCESS_MSG_ALERT = "La solicitud fue enviada a revisión con éxito.",
  SUCCESS_REJECTED_RULE = "La solicitud ha sido rechazada",
  SUCCESS_APPROVED_RULE = "La regla ha sido creada con éxito",
  ERROR_APPROVED_RULE = "Hubo un error en la creación de la regla. Por favor vuelve a intentarlo.",
  REJECT_RULE_TITLE = "¿Desea rechazar esta solicitud?",
  REJECT_RULE_DESCRIPTION = "Por favor, escribe el motivo por el cual desea rechazar esta solicitud:",
  DISABLE_RULE_DESCRIPTION = "Por favor, escribe el motivo por el cual desea deshabilitar esta solicitud:",
  DISABLE_WHITE_LIST_DESCRIPTION = "Por favor, escribe el motivo por el cual deseas realizar esta acción:",
  REJECT_RULE_ACTION_BUTTON = "Rechazar",
  REJECT_RULE_PLACEHOLDER = "Motivo del rechazo",
  DISABLE_RULE_PLACEHOLDER = "Motivo de la deshabilitación",
  WHITELIST_PLACEHOLDER = "Motivo...",
  PROCESSING_TITLE = "Estamos procesando tu solicitud",
  PROCESSING_DESCRIPTION = "Este proceso puede tardar unos minutos...",
  SUCCESS_DISABLE_RULE = "La regla ha sido deshabilitada con éxito",
  REJECT_RULE_ERROR = "Hubo un error al rechazar la solicitud. Por favor vuelve a intentarlo.",
  SUCCESS_MSG_APPROVED_ALERT = "La solicitud fue aprobada con éxito.",
  SUCCESS_DELETED_RULE_MSG_ALERT = "La regla ha sido eliminada con éxito",
  REJECT_DELETED_RULE_MSG_ALERT = "Hubo un error al eliminar la regla",
}

export const MODAL_UPDATE_STATUS_COMPONENTS: Record<
  string,
  IModalUpdateStatusComponents
> = {
  [LabelEnum.PENDING_REVIEW]: {
    title: ConfirmModalEnum.REJECT_RULE_TITLE,
    description: ConfirmModalEnum.REJECT_RULE_DESCRIPTION,
    actionButton: ConfirmModalEnum.REJECT_RULE_ACTION_BUTTON,
    placeholder: ConfirmModalEnum.REJECT_RULE_PLACEHOLDER,
    buttonSecondaryText: LabelEnum.BUTTON_CANCEL,
  },
  [LabelEnum.FIRST_LEVEL_RULES]: {
    title: ConfirmModalEnum.TITLE_DISABLE_RULE,
    description: ConfirmModalEnum.DISABLE_RULE_DESCRIPTION,
    actionButton: LabelEnum.BUTTON_DISABLE_RULE,
    placeholder: ConfirmModalEnum.DISABLE_RULE_PLACEHOLDER,
    buttonSecondaryText: LabelEnum.BUTTON_CANCEL,
  },
  [LabelEnum.WHITE_LISTS]: {
    title: ConfirmModalEnum.TITLE_DISABLE,
    description: ConfirmModalEnum.DISABLE_WHITE_LIST_DESCRIPTION,
    actionButton: LabelEnum.BUTTON_DISABLE_RULE,
    placeholder: ConfirmModalEnum.WHITELIST_PLACEHOLDER,
    buttonSecondaryText: LabelEnum.BUTTON_CANCEL,
  },
};
