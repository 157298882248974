import React from "react";
import {
  ClickAwayListener,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { IconCopy } from "@kushki/connect-ui";
import { CardEntityName } from "../CardEntity/CardEntityName";
import { useStyles } from "../EntityStyles/EntityStyles";
import { MerchantNodeData } from "../../../../types/search_merchant_response";
import { VerticalSpace } from "../VerticalSpace/VerticalSpace";
import { StatusEntity } from "../StatusEntity/StatusEntity";
import { clipboard } from "../../../shared/utils/tableUtils";
import { defaultTo } from "lodash";
import { getSimplifiedFormatLocal } from "../../../shared/utils/date_utils";
import { get } from "lodash";

export interface ICustomerEntity {
  selected: boolean;
  customer: MerchantNodeData;
}

export const CustomerEntity: React.FC<ICustomerEntity> = ({
  selected,
  customer,
}: ICustomerEntity) => {
  const [toolTip, setToolTip] = React.useState(false);
  const styles = useStyles();
  const merchantId: string = defaultTo(
    customer.merchant_id,
    get(customer, "merchantId", "")
  );

  const handleTooltipClose = () => {
    setToolTip(false);
  };

  const handleTooltipOpen = () => {
    setToolTip(true);
  };

  return (
    <React.Fragment>
      <CardEntityName selected={selected}>
        <Typography className={styles.subtitle} variant="inherit">
          Customer
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          columns={2}
        >
          <Typography
            onClick={() => {
              window.location.href = `/merchant-resume?publicMerchantId=${merchantId}`;
            }}
            className={styles.primaryTitle2}
            variant="inherit"
          >
            {customer.name}
          </Typography>
          <StatusEntity
            status={defaultTo(
              customer.merchant_status,
              get(customer, "status", "")
            )}
          />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          columns={3}
        >
          <Typography className={styles.text} variant="inherit">
            {merchantId}
          </Typography>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={toolTip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              placement="top"
              title="¡Copiado!"
              arrow
            >
              <IconButton
                data-testid="copy-paste-modalFamily"
                aria-label="IconCopyPaste"
                size="small"
                onClick={() => {
                  handleTooltipOpen();
                  clipboard(merchantId);
                  setTimeout(() => {
                    handleTooltipClose();
                  }, 5000);
                }}
              >
                <IconCopy fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </ClickAwayListener>
          <Typography className={styles.text} variant="inherit">
            Creado:
            {customer.created && getSimplifiedFormatLocal(customer.created)}
          </Typography>
        </Grid>
      </CardEntityName>
      <VerticalSpace selected width={80} />
    </React.Fragment>
  );
};
