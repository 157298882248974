import { ICellText, IconTransferCurve } from "@kushki/connect-ui";
import { TableBodyCellEnum } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/constants";
import { ITableCellProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { CELL_TYPES } from "./enums/CellTypesEnum";
import { ITableInfoCellValue } from "../interfaces/ITableInfoCellValue.interfaces";
import { CustomHeaderCellProps } from "./types/CustomHeaderCellProps.type";

export enum statusColor {
  FAILED = "FAILED",
  SUCCESS = "200",
}

const textAndColorSelector = (text?: string) => {
  switch (text) {
    case statusColor.FAILED:
      return { color: "error", text: "FALLIDO" };
    case statusColor.SUCCESS:
      return { color: "success", text: "EXITOSO" };
    default:
      return { color: "primary", text };
  }
};

export const TEXT_CELL = ({ line1, line2 }: ITableInfoCellValue) =>
  ({
    props: {
      cellProps: {
        align: "left",
        spacing: 1,
      },
      line1,
      line2: line2 ? line2 : "",
      type: line2 ? "twoLines" : "oneLine",
    } as ICellText,
    type: "TEXT" as TableBodyCellEnum,
  } as ITableCellProps);

export const TAG_CELL = ({ line1 }: ITableInfoCellValue) => ({
  props: {
    cellProps: {
      align: "left",
      spacing: 1,
    },
    color: textAndColorSelector(line1).color,
    text: textAndColorSelector(line1).text,
  },
  type: "TAG" as TableBodyCellEnum,
});

export const TITLE_CELL = ({ line1, line2 }: ITableInfoCellValue) => ({
  props: {
    cellProps: {
      align: "left",
      spacing: 1,
    },
    line1,
    line2: line2 ? line2 : "",
    type: line2 ? "twoLines" : "oneLine",
  },
  type: "TITLE" as TableBodyCellEnum,
});

export const OPTIONS_CELL = ({ onClick }: ITableInfoCellValue) => ({
  props: {
    cellProps: {
      align: "left",
      spacing: 1,
    },
    isDisabled: false,
    optionListItems: [
      {
        disable: false,
        Icon: IconTransferCurve,
        onClick,
        optionText: "Reenviar notificación",
        type: "type1",
      },
    ],
    optionText: "Reenvio de notificación",
  },
  type: "OPTIONS" as TableBodyCellEnum,
});

export const TABLE_ROW = {
  [CELL_TYPES.TAG]: TAG_CELL,
  [CELL_TYPES.TITLE]: TITLE_CELL,
  [CELL_TYPES.TEXT]: TEXT_CELL,
  [CELL_TYPES.OPTIONS]: OPTIONS_CELL,
};

export enum NotificationsProperties {
  DATE_AND_TIME = "created",
  ALIAS = "alias",
  PAYMENT_METHOD = "paymentMethod",
  BRANCH_ID = "merchantId",
  STATUS = "status",
  OPTION = "option",
}

export const ALL_TABLE_COLUMNS_NOTIFICATIONS_CUSTOMER: CustomHeaderCellProps[] =
  [
    {
      align: "left",
      cellType: CELL_TYPES.TITLE,
      key: NotificationsProperties.DATE_AND_TIME,
      spacing: 0,
      text: "Fecha y hora",
    },
    {
      align: "left",
      cellType: CELL_TYPES.TEXT,
      key: NotificationsProperties.ALIAS,
      spacing: 0,
      text: "Alias",
      type: "default",
    },
    {
      align: "left",
      cellType: CELL_TYPES.TAG,
      key: NotificationsProperties.PAYMENT_METHOD,
      spacing: 0,
      text: "Medio de pago o producto",
      type: "default",
    },
    {
      align: "left",
      cellType: CELL_TYPES.TITLE,
      key: NotificationsProperties.BRANCH_ID,
      spacing: 0,
      text: "Branch ID",
      type: "default",
    },
    {
      align: "left",
      cellType: CELL_TYPES.TAG,
      key: NotificationsProperties.STATUS,
      spacing: 0,
      text: "Estado",
      type: "default",
    },
    {
      align: "left",
      cellType: CELL_TYPES.OPTIONS,
      key: NotificationsProperties.OPTION,
      spacing: 0,
      text: "",
      type: "default",
    },
  ];

export const ALL_TABLE_COLUMNS_NOTIFICATIONS_BRANCH: CustomHeaderCellProps[] = [
  {
    align: "left",
    cellType: CELL_TYPES.TITLE,
    key: NotificationsProperties.DATE_AND_TIME,
    spacing: 0,
    text: "Fecha y hora",
  },
  {
    align: "left",
    cellType: CELL_TYPES.TEXT,
    key: NotificationsProperties.ALIAS,
    spacing: 0,
    text: "Alias",
    type: "default",
  },
  {
    align: "left",
    cellType: CELL_TYPES.TAG,
    key: NotificationsProperties.PAYMENT_METHOD,
    spacing: 0,
    text: "Medio de pago o producto",
    type: "default",
  },
  {
    align: "left",
    cellType: CELL_TYPES.TAG,
    key: NotificationsProperties.STATUS,
    spacing: 0,
    text: "Estado",
    type: "default",
  },
  {
    align: "left",
    cellType: CELL_TYPES.OPTIONS,
    key: NotificationsProperties.OPTION,
    spacing: 0,
    text: "",
    type: "default",
  },
];
