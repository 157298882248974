import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { IStyles } from "../../../shared/interfaces/Styles.interfaces";

const TableTitleStyles: IStyles = {
  disabledHead: {
    opacity: 0.3,
  },
  enabledHead: {
    opacity: 1,
  },
  row: {
    "&.MuiTableRow-root": {
      "th:first-child": {
        borderBottomLeftRadius: "8px",
        borderTopLeftRadius: "8px",
      },
      "th:last-child": {
        borderBottomRightRadius: "8px",
        borderTopRightRadius: "8px",
      },
    },
  },
};

const TABLE_HEAD_DISABLE_SX: Record<string, SxProps<Theme>> = {
  false: TableTitleStyles.enabledHead,
  true: TableTitleStyles.disabledHead,
};

export { TableTitleStyles, TABLE_HEAD_DISABLE_SX };
