import React, { FC } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { layoutStepContainerStyles as styles } from "./LayoutStepContainer.styles";
import FooterOptions from "../../components/FooterOptions/FooterOptions";
import { IFooterOptions } from "../../components/FooterOptions/FooterOptions.interfaces";
import { IconChevronLeft, IconEdit } from "@kushki/connect-ui";
import Subheader from "../../components/SubHeader/Subheader";
import { IHeaderProps } from "./LayoutStepContainer.interfaces";

export interface ILayoutStepContainer {
  headerProps: IHeaderProps;
  renderComponent: JSX.Element;
  footerProps: IFooterOptions;
}

export const LayoutStepContainer: FC<ILayoutStepContainer> = (
  props: ILayoutStepContainer
) => {
  return (
    <React.Fragment>
      <Box>
        <Box sx={styles.boxContent}>
          <Box sx={styles.headerStyle}>
            <Button
              data-testid={"backButton"}
              sx={styles.link}
              onClick={() => window.history.go(-1)}
            >
              <IconChevronLeft fontSize="medium" display="inline" />
              <Typography
                fontSize={18}
                variant="h1"
                fontWeight={400}
                display="inline"
                sx={styles.linkText}
              >
                Regresar
              </Typography>
            </Button>

            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Subheader
                  country={props.headerProps.country}
                  id={props.headerProps.id}
                  redirectPath={props.headerProps.apiUrl}
                  title={props.headerProps.title}
                  subtitle={props.headerProps.subTitle}
                  iconType={<IconEdit color="primary" fontSize="medium" />}
                />
              </Grid>
            </Grid>
          </Box>

          <React.Fragment>{props.renderComponent}</React.Fragment>
        </Box>

        <Box sx={styles.boxFooter}>
          <FooterOptions
            primaryButton={props.footerProps.primaryButton}
            secondaryButton={props.footerProps.secondaryButton}
            isLoading={props.footerProps.isLoading}
            label={props.footerProps.label}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};
