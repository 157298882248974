import { useEffect, useState } from "react";
import {
  HeaderCellProps,
  ITableCellProps,
  ITableRowProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import {
  Operators,
  PayoutBusinessConditions,
} from "../../../../types/configuration_response";
import { get, isEmpty } from "lodash";
import { buildOneCell } from "../../../shared/utils/utilsFile";
import { IUseDataTableComponent } from "../../../shared/interfaces/IUseDataTableComponent";
import { LabelEnum, TABLE_COLUMNS } from "../../../shared/enums/LabelEnum";
import { useAppSelector } from "../../../store/hooks/storeHook";
import { RootState } from "../../../store/store";
import { Condition, Rule } from "../../../../types/get_payouts_rules_response";
import {
  COLUMNS_BY_TABS,
  TableBodyCellEnum,
} from "../../../shared/enums/DataTableEnum";
import { RuleEnum } from "../../../shared/enums/RuleEnum";
import { BANKS_COLOMBIA } from "../../../shared/constants/CatalogCO";
import { IOptionSelection } from "../../../shared/interfaces/option_selection";

export const useConditions = (): IUseDataTableComponent => {
  const [rows, setRows] = useState<ITableRowProps[]>([]);
  const [cells] = useState<HeaderCellProps[]>(
    TABLE_COLUMNS[LabelEnum.CONDITIONS]
  );

  const { editRule, configList } = useAppSelector((state: RootState) => ({
    ...state.app,
  }));

  const buildCellsConditions = (
    item: PayoutBusinessConditions
  ): ITableCellProps[] => {
    const cells: ITableCellProps[] = [];
    const itemsByTabs: string[] = COLUMNS_BY_TABS[LabelEnum.CONDITIONS];

    itemsByTabs.forEach((_: string, index: number) => {
      const itemText: string = get(item[index], "text", "");

      if (isEmpty(itemText)) {
        cells.push(buildOneCell(TableBodyCellEnum.TEXT, LabelEnum.NO_APPLY));
      } else {
        cells.push(buildOneCell(TableBodyCellEnum.TEXT, itemText));
      }
    });

    return cells;
  };

  const getConditionValue = (condition: Condition): string => {
    const value: string = get(condition, "value", "").toString();

    if (condition.field == RuleEnum.BANK) {
      const banksId: string[] = value.split(",");
      const banksNames: string[] = banksId.map((id: string) => {
        const bank: IOptionSelection | undefined = BANKS_COLOMBIA.find(
          (item: IOptionSelection) => item.value == id
        );

        return get(bank, "label", "");
      });

      return banksNames.toString();
    }

    return value;
  };

  function generateSectionConditionGeneral(item: Rule | undefined) {
    let tableRowsProps: ITableRowProps[] = [];
    const rows: PayoutBusinessConditions[][] = [];

    get(configList, "payoutBusinessConditions", []).map(
      (businessCondition: PayoutBusinessConditions) => {
        get(item, "conditions", []).map((condition: Condition) => {
          if (condition.field === businessCondition.field) {
            const row: any[] = [];

            row.push({ text: businessCondition.title });
            row.push({
              text: get(configList, "operators", []).find(
                (operator: Operators) =>
                  operator.value === get(condition, "operator", "")
              )?.title,
            });
            row.push({
              text: getConditionValue(condition),
            });
            rows.push(row);
          }
        });
      }
    );

    rows.forEach((item: PayoutBusinessConditions, index: number) => {
      tableRowsProps = [
        ...tableRowsProps,
        {
          cells: buildCellsConditions(item),
          id: index.toString(),
          rowProps: {
            color: "default",
            onClick: () => {},
          },
        },
      ];
    });

    setRows(tableRowsProps);
  }

  useEffect(() => {
    generateSectionConditionGeneral(editRule);
  }, [editRule, configList]);

  return {
    cells,
    rows,
  };
};
