import { CurrencyEnum } from "./CurrencyEnum";

export const CurrencyCountry = {
  Ecuador: [{ key: CurrencyEnum.USD, label: "USD", selected: false }],
  Peru: [
    { key: CurrencyEnum.PEN, label: "PEN", selected: false },
    { key: CurrencyEnum.USD, label: "USD", selected: false },
  ],
  Chile: [
    { key: CurrencyEnum.CLP, label: "CLP", selected: false },
    { key: CurrencyEnum.UF, label: "UF", selected: false },
  ],
  Colombia: [{ key: CurrencyEnum.COP, label: "COP", selected: false }],
  Mexico: [{ key: CurrencyEnum.MXN, label: "MXN", selected: false }],
};
