import React from "react";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from "@mui/material";
import { TextEnum } from "../../shared/constants/textEnum";
import { loadingDialogComponentStyles } from "./LoadingDialogComponent.styles";
import { ILoadingDialogComponentProps } from "./LoadingDialogComponent.interfaces";

export const LoadingDialogComponent: React.FC<ILoadingDialogComponentProps> = (
  props: ILoadingDialogComponentProps
) => {
  return (
    <Dialog open={props.open} fullWidth>
      <DialogContent className={"loadingDialog"}>
        <DialogContentText>
          <Grid container>
            <Grid item xs={3}>
              <CircularProgress size={60} thickness={5} />
            </Grid>
            <Grid item xs={9} container alignItems="center" direction="row">
              <Grid item xs={12}>
                <Typography sx={loadingDialogComponentStyles.title}>
                  {props.isEdit
                    ? TextEnum.updatingProcessor
                    : TextEnum.saveProcessor}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={loadingDialogComponentStyles.subtitle}>
                  {TextEnum.waiting}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
