import React from "react";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import Edit from "../../assets/images/Edit";
import { toDate, format, differenceInDays, startOfDay } from "date-fns";
import { useDiscountData } from "./state/useCardDiscount";

const useStyles = makeStyles(() => ({
  padding: {
    paddingTop: "25.4px",
  },
  typo: {
    color: "#6D7781",
    marginBottom: 6,
    fontSize: "14px",
  },
  typo2: {
    color: "#293036",
    marginBottom: 6,
    fontSize: "14px",
  },
  gridItem: {
    width: "300px",
  },
  containerPadding: {
    paddingTop: "24px",
  },
  cardContainer: {
    width: "100%",
    backgroundColor: "#F7FAFC",
  },
  headerTitle: {
    fontWeight: "bold",
    marginRight: "2",
  },
  headerGrid: {
    textAlign: "left",
  },
  chip: {
    borderRadius: 4,
    background: "#F0F4F9",
    fontSize: "13px",
  },
  button: {
    backgroundColor: "#F0F4F9",
    borderRadius: 4,
  },
  link: {
    color: "#1E65AE",
    lineHeight: "170%",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    cursor: "pointer",
  },
}));

export interface ICardDiscount {
  merchantId: string;
}

export const InvoiceDiscount: React.FC<ICardDiscount> = (
  props: React.PropsWithChildren<ICardDiscount>
) => {
  const style = useStyles();
  const { dis, start, end } = useDiscountData();
  const startDate = toDate(start);
  const formatStartDate = format(startDate, "dd/MM/yyyy");
  const endDate = toDate(end);
  const formatEndDate = format(endDate, "dd/MM/yyyy");
  const diff = differenceInDays(endDate, startOfDay(new Date()));

  return (
    <React.Fragment>
      <Card className={style.cardContainer} elevation={0}>
        <CardContent>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justify="flex-start"
            direction="row"
          >
            <Grid item>
              <Typography className={style.headerTitle}>Descuentos</Typography>
            </Grid>
            <Grid item>
              <IconButton className={style.button} href={props.merchantId}>
                <Edit />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            container
            className={style.containerPadding}
            direction={"row"}
            justify={"flex-start"}
            alignItems={"flex-start"}
            spacing={3}
          >
            <Grid item className={style.gridItem}>
              <Typography className={style.typo}>Porcentaje:</Typography>
            </Grid>
            <Grid item>
              <Chip label={`${dis}%`} className={style.chip} />
            </Grid>
          </Grid>
          <Grid
            container
            direction={"row"}
            justify={"flex-start"}
            alignItems={"flex-start"}
          >
            <Grid item className={style.gridItem}>
              <Typography className={style.typo}>Inicio:</Typography>
            </Grid>
            <Grid item alignItems={"center"}>
              {diff > 0 ? (
                <Typography
                  className={style.typo}
                >{`${formatStartDate}`}</Typography>
              ) : (
                <Typography className={style.typo2}>dd/mm/aaaa</Typography>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            direction={"row"}
            justify={"flex-start"}
            alignItems={"flex-start"}
          >
            <Grid item className={style.gridItem}>
              <Typography className={style.typo}>Fin:</Typography>
            </Grid>
            <Grid item>
              {diff > 0 ? (
                <Typography
                  className={style.typo}
                >{`${formatEndDate}`}</Typography>
              ) : (
                <Typography className={style.typo2}>dd/mm/aaaa</Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};
