import React from "react";
import { Grid, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const InfoBox: React.FC = () => {
  return (
    <Grid container sx={{ border: "1px solid #E2E8F0", padding: "24px 32px" }}>
      <Grid item alignItems={"center"} sx={{ marginBottom: "10px" }}>
        <Typography
          variant={"body1"}
          color={"text.blue"}
          sx={{ alignItems: "center", display: "flex" }}
        >
          <InfoOutlinedIcon />
          Representante Legal principal
        </Typography>
      </Grid>
      <Grid>
        <Typography variant={"caption"} color={"text.dark"}>
          La persona que estás por agregar será clasificada como tu
          representante legal Principal. Por favor, verifica que los datos
          ingresados sean los correctos.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default InfoBox;
