import { get } from "lodash";
import { ConfigEnum } from "../enums/ConfigEnum";
import { Configs } from "../../../types/get_node_info_response";

export const findConfigurationValue = (
  configurations: Configs[],
  configurationId: ConfigEnum,
  defaultValue: string
): string => {
  const configFound = configurations.find(
    (config: Configs) => config.configuration === configurationId
  );

  return get(configFound, "value", defaultValue);
};
