import { CountryValueEnum } from "./CountryEnum";

export const enum CurrencyEnum {
  USD = "USD",
  COP = "COP",
  PEN = "PEN",
  CLP = "CLP",
  MXN = "MXN",
}

export const CurrencyMapByCountry: Record<CountryValueEnum, CurrencyEnum> = {
  [CountryValueEnum.ECUADOR]: CurrencyEnum.USD,
  [CountryValueEnum.COLOMBIA]: CurrencyEnum.COP,
  [CountryValueEnum.PERU]: CurrencyEnum.PEN,
  [CountryValueEnum.CHILE]: CurrencyEnum.CLP,
  [CountryValueEnum.MEXICO]: CurrencyEnum.MXN,
};

export const COUNTRY_ISO_CODE: Record<CountryValueEnum, string> = {
  [CountryValueEnum.ECUADOR]: "EC",
  [CountryValueEnum.COLOMBIA]: "CO",
  [CountryValueEnum.PERU]: "PE",
  [CountryValueEnum.CHILE]: "CL",
  [CountryValueEnum.MEXICO]: "MX",
};
