import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-okaidia.css";
import React from "react";
import { codeSnippetStyles as styles } from "./CodeSnippet.styles";
import { Box } from "@mui/material";

interface Props {
  value: string;
  handleChange?: (value: string) => void;
}

// eslint-disable-next-line react/prop-types
export const CodeSnippet: React.FC<Props> = ({ value, handleChange }) => (
  <Box sx={styles.container}>
    <Editor
      value={value}
      onValueChange={handleChange!}
      highlight={(code) => highlight(code, languages.js, "javascript")}
      padding={{
        bottom: "8px",
        left: "16px",
        right: "16px",
        top: "8px",
      }}
      preClassName={"preSnippet"}
    />
  </Box>
);
