import { useEffect, useState } from "react";
import { CountryValueEnum } from "../../../shared/infrastructure/Enums/CountryEnum";
import { BreadCrumsItems } from "../../../shared/infrastructure/constants/BreadCrums";
import { getCountry } from "../../../shared/infrastructure/constants/MerchantContactConstants";
import { useHistory, useLocation } from "react-router-dom";
import { BreadcrumProps } from "../../../components/common/Breadcrumb/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  getIdRetailConfigEc,
  getRetentionByCountry,
  indexTabEditRetentionRender,
  setAddRetentionDirectly,
  setNotificationConfig,
  setRetentionConfig,
  setEditRetention,
  setIsEditRetention,
  setEditRetentionDashboard,
  setIndexTabEditRetention,
  getCatalogsList,
  setFileSentCatalog,
} from "../../../store/actionCreators";
import { IAppState } from "../../../store/reducer";
import {
  columnsRetentionIVATable,
  columnsRetentionRENTATable,
  columnsRetentionTableCO,
  columnsRetentionTableEC,
  IColumnsTable,
} from "../../../shared/infrastructure/Table/ColumnsTable";
import {
  capitalize,
  cloneDeep,
  defaultTo,
  filter,
  get,
  isNil,
  set,
} from "lodash";
import { IOptionFilter } from "../../../components/common/BoxFilter/BoxFilter";
import { GetIdRetailsConfigEcRequest } from "../../../../types/get_id_retails_config_ec_request";
import { RetentionMerchantConfigDynamo } from "../../../../types/retention_merchant_configuration";
import { routes } from "../../../shared/infrastructure/routes";
import { INotification } from "../../../shared/infrastructure/interfaces/INotification";
import { TypeRetentionEnum } from "../../../shared/infrastructure/constants/RetentionConstants";
import { isEqual } from "lodash";
import {
  DefaultFilterCO,
  FILTERS_TO_BUILD_CO,
} from "../../../shared/infrastructure/Filter/DefaultFilterCO";
import { ActivityEconomicCol } from "../../../shared/infrastructure/constants/RetentionConstantsCO";
import { SecurityWrapperEnum } from "../../../shared/infrastructure/Enums/SecurityWrapperEnum";
import { ISecurityAzureRoles } from "../../../shared/infrastructure/interfaces/ISecurityAzureRoles";
import { TabEnum } from "../../../shared/infrastructure/Enums/TabEnum";
import { CatalogsRequestEnum } from "../../../shared/infrastructure/Enums/CatalogsRequestEnum";
import {
  defaultFilterOptions,
  filterOptionsEnabled,
} from "../../../shared/infrastructure/Filter/DefaultFilter";

export interface IRetentionsDashboardState {
  state: IState;
  handlers: {};
  breadCrumbs: BreadcrumProps;
  country: CountryValueEnum | null;
  data: {
    retentionsData: RetentionMerchantConfigDynamo[];
    retentionColumns: IColumnsTable[];
    isLoading: boolean;
    onHandleEditAction: (row: RetentionMerchantConfigDynamo) => void;
    retentionType: number;
  };
  filterProps: {
    handleSetFilters: (filters: { [key: string]: boolean }) => void;
    filterChips: { [key: string]: boolean };
    filters?: IOptionFilter[];
    counter: number;
  };
  pagination: {
    page: number;
    pageSize: number;
    pageTotal: number;
    maxPage: number;
    handleChangePage: (newPage: number) => void;
    handleChangePageSize: (newPageSize: number) => void;
  };
  modalDeleteProps: {
    openDeleteModal: boolean;
    handleAcceptModal: () => void;
    handleOpenModal: (index: number) => void;
    handleCloseModal: () => void;
    isLoadingDelete: boolean;
  };
  tabTableDashboard: {
    valueTabTable: number;
    handleChangeTableDashboard: (value: number) => void;
  };
  notificationConfigActions: {
    notificationConfig?: INotification | unknown;
    handleCloseNotificationConfig: () => void;
  };
  handleOpenEditForm: (index: number) => void;
  handleSetFiltersInput?: (name: string | null) => void;
  securityAzure: ISecurityAzureRoles;
}
interface IFiltersToBuild {
  data: any[];
  key: string;
  temArray?: any[];
}
export interface IState {}
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const useRetentionsDashboardState = (): IRetentionsDashboardState => {
  const stateStore = useSelector<IAppState, IAppState>(
    (state: IAppState) => state
  );
  const notificationConfig = useSelector<IAppState>(
    (state) => state.notificationConfig
  );
  const history = useHistory();
  const [state] = useState<IState>({});
  const dispatch = useDispatch();
  const query = useQuery();
  const country: CountryValueEnum = query.get("country") as CountryValueEnum;
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageTotal, setPageTotal] = useState<number>(0);
  const [retentionsData, setRetentionData] = useState<
    RetentionMerchantConfigDynamo[]
  >([]);
  const [retentionsDataFiltered, setRetentionDataFiltered] = useState<
    RetentionMerchantConfigDynamo[]
  >([]);
  const [maxPage] = useState<number>(27);
  const [filterChips, setFilterChips] = useState({});
  const [counter, setCounter] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [retentionRow, setRetentionRow] = useState<
    RetentionMerchantConfigDynamo
  >({ country: "ecuador", created: 0, id: "", userName: "" });
  const [valueTabTable, setValueTabTable] = useState<number>(0);
  const [retentionType, setRetentionType] = useState<number>(1);
  const [filters, setFilters] = useState<IOptionFilter[]>(
    defaultFilterOptions(stateStore.catalogsList!, retentionType)
  );
  const [securityRolAzure, setSecurityRolAzure] = useState<ISecurityAzureRoles>(
    { rolDashboard: SecurityWrapperEnum.RETENCIONES_ELIMINAR_IVA }
  );

  // Define Columns By COUNTRY
  const defineColumnsByCountry = ():
    | typeof columnsRetentionTableEC
    | typeof columnsRetentionTableCO => {
    switch (capitalize(country!)) {
      case CountryValueEnum.Colombia:
        return columnsRetentionTableCO;
      case CountryValueEnum.Ecuador:
      default:
        return get(
          stateStore,
          "columnsConfigRetentionEc",
          columnsRetentionIVATable
        );
    }
  };

  const defineDefaultFilterByCountry = (): IOptionFilter[] => {
    switch (capitalize(country!)) {
      case CountryValueEnum.Colombia:
        return DefaultFilterCO;
      case CountryValueEnum.Ecuador:
      default:
        return defaultFilterOptions(stateStore.catalogsList!, retentionType);
    }
  };

  const defineDefaultFilterToBuild = (): { key: string; data: string[] }[] => {
    switch (capitalize(country!)) {
      case CountryValueEnum.Colombia:
        return FILTERS_TO_BUILD_CO;
      case CountryValueEnum.Ecuador:
      default:
        return filterOptionsEnabled(stateStore.catalogsList!);
    }
  };

  const handleSetFilters = (filters: { [key: string]: boolean }): void => {
    setFilterChips(filters);
    const selectedFilters: string[] = Object.keys(filters).filter(
      (key: string) => filters[key]
    );

    setCounter(selectedFilters.length);

    if (selectedFilters.length <= 0) {
      onChangeRetention();
      return;
    }
    const filtersByType: object = getSelectedFiltersByType(selectedFilters);
    const retentionFiltered: RetentionMerchantConfigDynamo[] = getFilteredDataByType(
      filtersByType
    );

    setRetentionData(retentionFiltered);
    setPage(1);
  };

  const handleSetFiltersInput = (name: string | null) => {
    if (name === null) dispatch(getRetentionByCountry(country));
    let auxRetention: RetentionMerchantConfigDynamo[] = cloneDeep(
      stateStore.retention!
    );
    const valide = filter(ActivityEconomicCol, (value) => value.name === name);
    if (valide.length === 0) return;
    const retentionFiltered = filter(
      auxRetention,
      (item) => item.activityId === valide[0].value
    );

    if (retentionFiltered.length === 0) return;

    setRetentionData(retentionFiltered);
    setPage(1);
  };

  const getFilteredDataByType = (
    filtersByType: object
  ): RetentionMerchantConfigDynamo[] => {
    let auxRetention: RetentionMerchantConfigDynamo[] = cloneDeep(
      stateStore.retention!
    );

    return auxRetention.filter((elem: RetentionMerchantConfigDynamo) => {
      let filterResponse = true;
      Object.keys(filtersByType).forEach((key) => {
        if (!filtersByType[key].includes(String(elem[key]))) {
          filterResponse = false;
        }
      });
      return filterResponse;
    });
  };

  const getSelectedFiltersByType = (selectedFilters: string[]): object => {
    let filtersToBuild: IFiltersToBuild[] = cloneDeep(
      defineDefaultFilterToBuild()
    );
    filtersToBuild.forEach((filter: IFiltersToBuild) => {
      set(filter, "temArray", []);
    });
    return selectedFilters.reduce((obj, key) => {
      filtersToBuild.forEach((filter: IFiltersToBuild) => {
        if (filter.data.includes(key)) {
          filter.temArray!.push(key);
        }
      });
      filtersToBuild.forEach((filter: IFiltersToBuild) => {
        filter.temArray!.length > 0
          ? (obj = { ...obj, [filter.key]: filter.temArray })
          : (obj = { ...obj });
      });
      return obj;
    }, {});
  };

  // PAGINATION
  const handleChangePage = (newPage: number) => {
    if (newPage > maxPage) return;
    setPage(newPage);
  };

  const handleChangePageSize = (newPageSize: number) => {
    setPage(1);
    setPageSize(newPageSize);
  };

  const calculateDataPagination = () => {
    const auxRetention = cloneDeep(retentionsData);
    const filteredData = auxRetention.slice(
      (page - 1) * pageSize,
      page * pageSize
    );
    setRetentionDataFiltered(filteredData);
    setPageTotal(retentionsData.length);
  };
  //Edit configuration form
  const handleOpenEditForm = (index: number) => {
    dispatch(setRetentionConfig(retentionsData[index]));
    history.push(
      `${routes.BASE_PATH_RETENTIONS}${routes.EDIT_FORM}?country=${country}`
    );
  };

  const handleCloseNotificationConfig = (): void => {
    dispatch(
      setNotificationConfig({
        type: "dark",
        message: "",
        open: false,
      })
    );
  };

  //Modal Delete retention logic
  const handleOpenModalDelete = (index: number) => {
    setRetentionRow(retentionsData[index]);
    setOpenDeleteModal(!openDeleteModal);
  };
  const handleCloseModalDelete = () => {
    setOpenDeleteModal(!openDeleteModal);
  };
  const handleAcceptModalDelete = () => {
    if (retentionRow) {
      const retailConfigRequest: GetIdRetailsConfigEcRequest = _buildQueryDeleteRetention(
        retentionRow
      );
      dispatch(getIdRetailConfigEc(retailConfigRequest));
    }
  };

  const onHandleEditAction = (row: RetentionMerchantConfigDynamo) => {
    dispatch(setEditRetention(row));
    dispatch(setEditRetentionDashboard(true));
    dispatch(setIsEditRetention(true));
    history.push(
      `${routes.BASE_PATH_RETENTIONS}${routes.WITH_FORM}?country=${country}`
    );
  };

  const onChangeRetention = () => {
    setRetentionData(stateStore.retention!);
  };

  const handleChangeTableDashboard = (value: number) => {
    _getConfigRetentionEc(value);
    setRetentionType(value);
    dispatch(indexTabEditRetentionRender(value));
  };

  const _getConfigRetentionEc = (typeIva: number) => {
    setValueTabTable(typeIva);
    if (typeIva === TabEnum.RENTA) {
      dispatch(
        getRetentionByCountry(country, {
          typeRetention: TypeRetentionEnum.RETENTION,
        })
      );
      dispatch(setIndexTabEditRetention(TabEnum.RENTA));
      return;
    }
    if (typeIva === TabEnum.CATALOG) {
      dispatch(
        getCatalogsList({
          productLine: CatalogsRequestEnum.PLA,
          configCode: CatalogsRequestEnum.CN003,
          countryCode: CatalogsRequestEnum.ECUADOR,
        })
      );
      dispatch(setIndexTabEditRetention(TabEnum.CATALOG));
      return;
    }
    dispatch(
      getRetentionByCountry(country, { typeRetention: TypeRetentionEnum.IVA })
    );
  };

  const _buildQueryDeleteRetention = (data: RetentionMerchantConfigDynamo) => {
    if (isNil(defaultTo(data.incomeSourceConceptId, null))) {
      return {
        activityId: data.activityId,
        taxpayerTypeId: data.taxpayerTypeId,
        ivaRetentionId: data.ivaRetention,
      };
    }
    return {
      incomeSourceConceptId: data.incomeSourceConceptId,
    };
  };

  // USE EFFECTS
  useEffect(() => {
    if (CountryValueEnum.Ecuador !== capitalize(country))
      handleChangeTableDashboard(TabEnum.IVA);
    else {
      dispatch(
        getCatalogsList({
          productLine: CatalogsRequestEnum.PLA,
          configCode: CatalogsRequestEnum.CN003,
          countryCode: CatalogsRequestEnum.ECUADOR,
        })
      );
      handleChangeTableDashboard(TabEnum.IVA);
      dispatch(setFileSentCatalog(false));
    }
  }, []);

  useEffect(() => {
    if (
      isEqual(
        get(stateStore, "columnsConfigRetentionEc", columnsRetentionIVATable),
        columnsRetentionRENTATable
      )
    ) {
      setSecurityRolAzure({
        rolDashboard: SecurityWrapperEnum.RETENCIONES_ELIMINAR_RENTA,
      });
      setValueTabTable(1);
      return;
    }
    setSecurityRolAzure({
      rolDashboard: SecurityWrapperEnum.RETENCIONES_ELIMINAR_IVA,
    });
    setValueTabTable(0);
  }, [stateStore.columnsConfigRetentionEc!]);

  useEffect(() => {
    setFilters(defineDefaultFilterByCountry());
  }, [stateStore.catalogsList!]);

  useEffect(() => {
    onChangeRetention();
  }, [stateStore.retention!]);

  useEffect(() => {
    calculateDataPagination();
  }, [retentionsData, page, pageSize]);

  useEffect(() => {
    if (stateStore.isCompletedDelete) {
      dispatch(
        getRetentionByCountry(country, {
          typeRetention:
            valueTabTable === TabEnum.RENTA
              ? TypeRetentionEnum.RETENTION
              : TypeRetentionEnum.IVA,
        })
      );
      setOpenDeleteModal(false);
    }
  }, [stateStore.isCompletedDelete]);

  useEffect(() => {
    dispatch(setAddRetentionDirectly(false));
  }, []);

  useEffect(() => {
    setFilters(defineDefaultFilterByCountry());
    setCounter(0);
  }, [valueTabTable]);

  return {
    state,
    handlers: {},
    country,
    filterProps: {
      filters,
      counter,
      filterChips,
      handleSetFilters,
    },
    data: {
      retentionsData: retentionsDataFiltered,
      retentionColumns: defineColumnsByCountry(),
      isLoading: stateStore.isLoadingRetentionDashboard!,
      onHandleEditAction,
      retentionType,
    },
    pagination: {
      page,
      pageSize,
      pageTotal,
      maxPage,
      handleChangePage,
      handleChangePageSize,
    },
    breadCrumbs: {
      items: [
        BreadCrumsItems.GlobalConfig,
        { ...BreadCrumsItems.Country, label: getCountry(country).name },
      ],
      lastItem:
        BreadCrumsItems.RetentionsMerchant.label[getCountry(country).value],
    },
    modalDeleteProps: {
      handleAcceptModal: handleAcceptModalDelete,
      handleOpenModal: handleOpenModalDelete,
      handleCloseModal: handleCloseModalDelete,
      openDeleteModal,
      isLoadingDelete: stateStore.isLoadingDeleteRetailConfig!,
    },
    tabTableDashboard: {
      valueTabTable,
      handleChangeTableDashboard,
    },
    notificationConfigActions: {
      notificationConfig,
      handleCloseNotificationConfig,
    },
    handleOpenEditForm: handleOpenEditForm,
    handleSetFiltersInput,
    securityAzure: securityRolAzure,
  };
};
