import { useEffect, useState } from "react";
import { capitalize } from "@mui/material";

export interface OnChangeTimePickerTagState {
  onChange(value: string): void;
  value: string;
}

export interface IUseDatePicker {
  disabled: boolean;
  date: string | undefined;
}

export const useDatePicker = (
  props: OnChangeTimePickerTagState
): IUseDatePicker => {
  const [date, setDate] = useState<Date | null>(null);
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (props.value) {
      setDisabled(true);
      setDate(new Date(parseInt(props.value)));
    }
  }, [props.value]);

  const formatDate = (date: Date | null): string | undefined => {
    if (date) {
      const day = date.toLocaleString("default", { day: "2-digit" });
      const month = capitalize(
        date.toLocaleString("default", { month: "short" })
      );
      const year = date.toLocaleString("default", { year: "numeric" });
      return `${day}/${month}/${year}`;
    }
    return undefined;
  };

  return {
    disabled,
    date: formatDate(date),
  };
};
