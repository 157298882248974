import React, { useState } from "react";
import { IUseFilterComponentState } from "../../../shared/infrastructure/interfaces/useFilterComponentState.interface";
import { chipStatus } from "../../../shared/constants/RequestDispersionConstants";
import {
  cloneDeep,
  get,
  includes,
  isEqual,
  isNil,
  remove,
  union,
} from "lodash";
import { useDispatch } from "react-redux";

import { IFilterComponent } from "../../../shared/infrastructure/interfaces/IFilterComponent.interface";

import { setDeferred, setNotification } from "../../../store/actionCreators";
import { DateRange } from "@material-ui/pickers/DateRangePicker/RangeTypes";
import { DeferredOption } from "../../../../types/deferred_options";
import { calculateDateUtcNumber } from "../../../shared/utils/utils";
import { MerchantDeferred } from "../../../../types/merchant_deferred";
import { DeferredOptionPath } from "../../../shared/infrastructure/DeferredOptionPathEnum";
import { MerchantStatus } from "../../../shared/infrastructure/constants/DeferredConstants";
import {
  PROCESSOR_VALUES,
  ProcessorNameEnum,
  ProcessorsEnum,
} from "../../../shared/infrastructure/constants/ProcessorsEnum";

export const useFilterComponentState = (
  props: IFilterComponent
): IUseFilterComponentState => {
  const dispatch = useDispatch();
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const [defaultExpanded, setDefaultExpanded] = useState<boolean>(false);
  const [defaultExpandedProcessor, setDefaultExpandedProcessor] =
    useState<boolean>(false);
  const [defaultExpandedStatus, setDefaultExpandedStatus] =
    useState<boolean>(false);
  const [defaultExpandedDeferred, setDefaultExpandedDeferred] =
    useState<boolean>(false);
  const [defaultExpandedFee, setDefaultExpandedFee] = useState<boolean>(false);
  const [defaultExpandedMonths, setDefaultExpandedMonths] =
    useState<boolean>(false);

  const [inactiveSelected, setInactiveSelected] = useState<boolean>(false);
  const [activeSelected, setActiveSelected] = useState<boolean>(false);
  const [monthsSelected, setMonthsSelected] = useState<string[]>([]);
  const [feeSelected, setFeeSelected] = useState<string[]>([]);

  const country: string | undefined = get(props, "merchantDeferred.country");

  const handleCloseMenu = (): void => {
    setAnchor(null);
  };

  const handleClickFilter = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setAnchor(event.currentTarget);
  };

  const [processorNameSelected, setProcessorNameSelected] = useState<string>(
    ProcessorNameEnum.FIS
  );

  let merchantDeferred: MerchantDeferred | undefined = get(
    props,
    "merchantDeferred"
  );

  const [dateRange, setDateRange] = useState<DateRange<Date>>([null, null]);
  const handleExpandedOrCollapsed = (expand: boolean): void => {
    setDefaultExpanded(expand);
    setDefaultExpandedStatus(expand);
    setDefaultExpandedProcessor(expand);
    setDefaultExpandedFee(expand);
    setDefaultExpandedMonths(expand);
    setDefaultExpandedDeferred(expand);
  };
  const handleExpandedOrCollapsedProcessor = (): void => {
    setDefaultExpandedProcessor(!defaultExpandedProcessor);
  };
  const handleExpandedOrCollapsedStatus = (): void => {
    setDefaultExpandedStatus(!defaultExpandedStatus);
  };
  const handleExpandedOrCollapsedDeferred = (): void => {
    setDefaultExpandedDeferred(!defaultExpandedDeferred);
  };
  const handleExpandedOrCollapsedFee = (): void => {
    setDefaultExpandedFee(!defaultExpandedFee);
  };
  const handleExpandedOrCollapsedMonths = (): void => {
    setDefaultExpandedMonths(!defaultExpandedMonths);
  };

  const isChipSelected = (data: string): boolean => {
    return (
      (inactiveSelected && data === chipStatus[1]) ||
      (activeSelected && data === chipStatus[0])
    );
  };
  const isChipMonthsSelected = (data: string): boolean => {
    return includes(monthsSelected, data);
  };

  const isChipFeeSelected = (data: string): boolean => {
    return includes(feeSelected, data);
  };

  const handleClickMonths = (data: string): void => {
    if (includes(monthsSelected, data)) {
      const newMonth = cloneDeep(monthsSelected);

      remove(newMonth, (item) => isEqual(item, data));
      setMonthsSelected(newMonth);
    } else {
      setMonthsSelected(union(monthsSelected, [data]));
    }
  };

  const handleClickFee = (data: string): void => {
    if (includes(feeSelected, data)) {
      const newFee = cloneDeep(feeSelected);

      remove(newFee, (item) => isEqual(item, data));
      setFeeSelected(newFee);
    } else {
      setFeeSelected(union(feeSelected, [data]));
    }
  };

  const handleClick = (data: string): void => {
    if (data === chipStatus[1]) {
      setActiveSelected(false);
      setInactiveSelected(true);
    }

    if (data === chipStatus[0]) {
      setInactiveSelected(false);
      setActiveSelected(true);
    }
  };

  const equalStatusMethod = (deferred: DeferredOption): boolean => {
    const merchantStatus: string = get(
      deferred,
      DeferredOptionPath.MERCHANT_STATUS,
      ""
    );

    if (activeSelected) {
      return merchantStatus === MerchantStatus.ENABLED;
    }

    if (inactiveSelected) {
      return merchantStatus === MerchantStatus.DISABLED;
    }

    return true;
  };
  const equalMonthsMethod = (deferred: DeferredOption): boolean => {
    if (feeSelected.length === 0) {
      return true;
    }

    const months: string[] = get(
      deferred,
      DeferredOptionPath.MERCHANT_MONTHS,
      []
    );
    const commonMonths = months.filter((month) =>
      feeSelected.some((f) => month === f)
    );

    return commonMonths.length > 0;
  };
  const equalProcessorMethod = (deferred: DeferredOption): boolean => {
    if (ProcessorsEnum.ALL === processorNameSelected) {
      return true;
    }
    const processor: string =
      PROCESSOR_VALUES[get(deferred, DeferredOptionPath.PROCESSOR_NAME, "")]
        .label;

    return processor
      .toUpperCase()
      .includes(processorNameSelected.toUpperCase());
  };
  const insideDateRangeMethod = (
    deferred: DeferredOption,
    dateRangePicker: DateRange<Date>
  ): boolean => {
    if (isNil(dateRangePicker[0]) || isNil(dateRangePicker[1])) return true;
    const dateStartUtc: number = calculateDateUtcNumber(
      get(dateRangePicker, "[0]", 0),
      true
    );
    const startDateFilter: number = Math.trunc(dateStartUtc / 1000);
    const dateEndUtc: number = calculateDateUtcNumber(
      get(dateRangePicker, "[1]", 0),
      false
    );
    const endDateFilter: number = Math.trunc(dateEndUtc / 1000);
    const startDate: number = new Date(get(deferred, "startDate", 0)).getTime();
    const endDate: number = new Date(get(deferred, "endDate", 0)).getTime();

    return startDate >= startDateFilter && endDate <= endDateFilter;
  };

  const handleDeleteFilters = (): void => {
    handleExpandedOrCollapsed(false);
    if (merchantDeferred) {
      dispatch(
        setDeferred(
          merchantDeferred.filter((d: DeferredOption) =>
            insideDateRangeMethod(d, dateRange)
          )
        )
      );
    }
    setProcessorNameSelected("");
    setActiveSelected(false);
    setInactiveSelected(false);
    setMonthsSelected([]);
    setFeeSelected([]);
  };

  const applyFilters = (dateRangePicker: DateRange<Date>): void => {
    if (merchantDeferred) {
      const tempDeferredOptions: DeferredOption[] = merchantDeferred.filter(
        (deferredOp: DeferredOption) => {
          const equalProcessor = equalProcessorMethod(deferredOp);
          const equalStatus = equalStatusMethod(deferredOp);
          const equalMonths = equalMonthsMethod(deferredOp);
          const insideDateRange = insideDateRangeMethod(
            deferredOp,
            dateRangePicker
          );

          return (
            insideDateRange && equalProcessor && equalStatus && equalMonths
          );
        }
      );

      dispatch(setDeferred(tempDeferredOptions));
    }
  };

  const handleResetDateFilter = () => {
    const emptyDateRange: DateRange<Date> = [null, null];

    setDateRange(emptyDateRange);
    applyFilters(emptyDateRange);
  };

  const handleDateFilter = (dateRangePicker: DateRange<Date>) => {
    setDateRange(dateRangePicker);
    applyFilters(dateRangePicker);
  };

  const handleApplyFilters = (): void => {
    setAnchor(null);
    dispatch(
      setNotification({
        message: "",
        open: false,
        type: "success",
      })
    );
    applyFilters(dateRange);
  };

  const handleChangeRadio = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const selectedName = (event.target as HTMLInputElement).value;

    setProcessorNameSelected(selectedName);
  };

  return {
    anchor,
    country,
    dateRange,
    defaultExpanded,
    defaultExpandedDeferred,
    defaultExpandedFee,
    defaultExpandedMonths,
    defaultExpandedProcessor,
    defaultExpandedStatus,
    handle: {
      handleApplyFilters,
      handleChangeRadio,
      handleClick,
      handleClickFee,
      handleClickFilter,
      handleClickMonths,
      handleCloseMenu,
      handleDateFilter,
      handleDeleteFilters,
      handleExpandedOrCollapsed,
      handleExpandedOrCollapsedDeferred,
      handleExpandedOrCollapsedFee,
      handleExpandedOrCollapsedMonths,
      handleExpandedOrCollapsedProcessor,
      handleExpandedOrCollapsedStatus,
      handleResetDateFilter,
      isChipFeeSelected,
      isChipMonthsSelected,
      isChipSelected,
    },
    processorNameSelected,
  };
};
