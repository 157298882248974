import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Control,
  Controller,
  FieldError,
  NestDataObject,
} from "react-hook-form";
import { Autocomplete, AutocompleteRenderInputParams } from "@material-ui/lab";
import React, { RefObject, useEffect, useState } from "react";
import {
  COUNTRIES,
  ICountry,
  not_apply,
  suppliers,
} from "../../../shared/infrastructure/constants/CreateMerchantConstants";
import { makeStyles } from "@material-ui/core/styles";
import { IValueName } from "../../../shared/infrastructure/interfaces/IValueName";
import { AffiliationLead } from "../../../../types/affiliation_lead";
import { defaultTo, get } from "lodash";
import { Link } from "react-router-dom";
import { routes } from "../../../shared/infrastructure/routes";
import { format, parse } from "date-fns";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { SecurityWrapperIdEnum } from "../../../shared/infrastructure/catalogs/SecurityWrapperIdEnum";

const useStyles = makeStyles({
  bgImage: {
    textAlign: "center",
    background: "#EEF6FF",
    display: "inline-flex",
  },
  cardImg: { width: "100%", padding: "50px" },
  marginAuto: {
    margin: "auto",
  },
  titleCard: {
    color: "#023365",
    marginBottom: 5,
    fontWeight: "lighter",
    paddingTop: 20,
  },
  marginTopXS: {
    marginTop: 10,
  },
  marginBottomS: {
    marginBottom: 30,
  },
  marginTopS: {
    marginTop: 30,
  },
  marginRightS: {
    marginRight: "20px",
  },
  marginXS: {
    margin: 10,
  },
  paddingS: {
    padding: 40,
  },
  fontSize11: {
    fontSize: "11px",
  },
  switchLabel: {
    fontSize: "0.8rem",
    color: "gray",
  },
  rowTitleCheckboxList: {
    padding: "30px 0 15px",
  },
  rowDivider: {
    padding: "15px 0 0",
  },
  labelTitleCheckboxList: {
    fontSize: "12px",
    color: "#023365",
  },
  rowAuthenticationRuleCheckboxList: {
    marginTop: "16px",
  },
  labelAuthenticationRuleCheckboxList: {
    fontSize: "14px",
    color: "#023365",
    marginBottom: "4px",
  },
});

export interface ICreateMerchantCard {
  src: string;
  isEdit: boolean;
  isLoading: boolean;
  autocomplete: {
    options: AffiliationLead[];
    currentLead?: AffiliationLead;
  };
  merchantData: {
    country?: string;
    merchantName?: string;
  };
  countryDisabled: boolean;
  publicMerchantId?: string;
  handleFormChange(name: string, value: string | boolean): void;
  handleCheckboxChange(
    supplier: string,
    authenticationRule: string,
    status: boolean
  ): void;
  control: Control<Record<string, any>>;
  register(
    obj: object
  ): ((instance: any) => void) | RefObject<any> | null | undefined;
  errors: NestDataObject<Record<string, any>, FieldError>;
  onSubmit(): void;
}

export const CreateMerchantCard = (props: ICreateMerchantCard) => {
  const classes = useStyles();
  const [
    showAuthenticationRuleSection,
    setShowAuthenticationRuleSection,
  ] = useState<boolean>(false);
  const [listAuthenticationRules, setListAuthenticationRules] = useState(
    Array(suppliers.length).fill(false)
  );

  const updateAffiliationLead = (affiliationLead?: AffiliationLead) => ({
    value: get(affiliationLead, "id", ""),
    name: `${get(affiliationLead, "merchantInfo.name", "")} ${get(
      affiliationLead,
      "merchantInfo.created",
      ""
    )}`,
  });

  const getDefaultValue = (lead: object, property: string) =>
    get(lead, `currentLead.merchantInfo.${property}`);

  const isValidDate = (value: string) =>
    !isNaN(parse(value, "dd-MM-yyyy", new Date()).getTime());

  const getAffiliationLeadDetails = (properties: ICreateMerchantCard) => {
    const detailsAffiliation = [];
    if (properties.isEdit)
      detailsAffiliation.push({
        name: "Nombre de afiliación:",
        value: getDefaultValue(properties.autocomplete, "name"),
      });

    return detailsAffiliation
      .concat([
        {
          name: "Fecha:",
          value: isValidDate(
            getDefaultValue(properties.autocomplete, "created")
          )
            ? format(
                parse(
                  getDefaultValue(properties.autocomplete, "created"),
                  "dd-MM-yyyy",
                  new Date()
                ),
                "dd/MM/yyyy"
              )
            : format(new Date(), "dd/MM/yyyy"),
        },
        {
          name: "Razón social:",
          value: getDefaultValue(properties.autocomplete, "socialReason"),
        },
        {
          name: "RUT:",
          value: getDefaultValue(properties.autocomplete, "documentNumber"),
        },
      ])
      .map((text: IValueName) => (
        <Typography
          color="textSecondary"
          className={!properties.isEdit ? classes.fontSize11 : ""}
          key={text.name}
        >
          {text.name} {text.value}
        </Typography>
      ));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setShowAuthenticationRuleSection(checked);
  };

  const handleAuthenticationRule = (supplier: string, idx: number) => {
    setListAuthenticationRules((prevState: boolean[]) => {
      const newState = [...prevState];
      newState[idx] = !prevState[idx];
      if (newState[idx]) delete props.errors.authenticationRule;

      props.handleCheckboxChange(
        supplier.toLowerCase(),
        suppliers[idx].authenticationType[0].toLowerCase(),
        newState[idx]
      );

      return newState;
    });
  };

  useEffect(() => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  }, [showAuthenticationRuleSection, listAuthenticationRules]);

  return (
    <Paper elevation={3}>
      <Grid container>
        <Grid item xs={3} lg={4} xl={4} className={classes.bgImage}>
          <img src={props.src} alt="merchant" className={classes.cardImg} />
        </Grid>
        <Grid item xs={9} lg={8} xl={8} className={classes.marginAuto}>
          <div className={classes.paddingS}>
            <Typography
              variant="h1"
              color="primary"
              className={classes.titleCard}
            >
              {props.isEdit
                ? "Editar datos de comercio"
                : "Crea un nuevo comercio"}
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              className={`${classes.marginTopXS} ${classes.marginBottomS}`}
              hidden={
                props.isEdit && props.autocomplete.currentLead?.id === not_apply
              }
            >
              {props.isEdit
                ? "Datos del comercio afiliado:"
                : "Ingresa el nombre del comercio para verificar si existe un comercio afiliado:"}
            </Typography>
            <Grid container spacing={2}>
              {!props.isEdit && (
                <React.Fragment>
                  <Grid item xs={7}>
                    <Autocomplete
                      id="lead"
                      multiple={false}
                      onChange={(_event, value) => {
                        if (value && value.value)
                          props.handleFormChange(
                            "lead",
                            defaultTo(value!.value, "")
                          );
                      }}
                      options={props.autocomplete.options.map(
                        (affiliation: AffiliationLead) => ({
                          value: affiliation.id,
                          name: `${defaultTo(
                            affiliation.merchantInfo?.name,
                            ""
                          )} ${defaultTo(
                            affiliation.merchantInfo?.created,
                            ""
                          )}`,
                        })
                      )}
                      getOptionLabel={(option) => `${option.name}`}
                      value={updateAffiliationLead(
                        props.autocomplete.currentLead
                      )}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextField
                          {...params}
                          label="Nombre de afiliación"
                          name="affiliationName"
                          variant="outlined"
                          inputRef={props.register({
                            required: "Debe escribir un nombre.",
                          })}
                          error={!!props.errors.affiliationName}
                          helperText={
                            props.errors.affiliationName
                              ? props.errors.affiliationName.message
                              : ""
                          }
                        />
                      )}
                    />
                  </Grid>
                  {props.autocomplete.currentLead?.id &&
                    props.autocomplete.currentLead?.id !== not_apply && (
                      <Grid item xs={5}>
                        {getAffiliationLeadDetails(props)}
                      </Grid>
                    )}
                </React.Fragment>
              )}
              {props.isEdit &&
                props.autocomplete.currentLead?.id !== not_apply && (
                  <Grid item xs={12}>
                    {getAffiliationLeadDetails(props)}
                  </Grid>
                )}
            </Grid>
            <Divider
              hidden={
                props.isEdit && props.autocomplete.currentLead?.id === not_apply
              }
              className={`${classes.marginTopS} ${classes.marginBottomS}`}
            />
            <Typography
              color="textSecondary"
              gutterBottom
              className={`${classes.marginTopXS}`}
              hidden={
                props.isEdit && props.autocomplete.currentLead?.id === not_apply
              }
            >
              Asigna un nombre de comercio y selecciona el país:
            </Typography>
            <Grid container spacing={2} className={classes.marginTopS}>
              <Grid item xs={7}>
                <TextField
                  autoComplete="off"
                  value={props.merchantData.merchantName}
                  variant="outlined"
                  name="merchantName"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label="Nombre del comercio"
                  placeholder="Nombre del comercio"
                  inputRef={props.register({
                    required: "Debe escribir un nombre.",
                    pattern: {
                      value: new RegExp("^[a-zA-Z0-9 ]{1,50}$"),
                      message:
                        "Debe tener entre 1 y 50 caracteres alfanuméricos",
                    },
                  })}
                  helperText={
                    props.errors.merchantName
                      ? props.errors.merchantName.message
                      : ""
                  }
                  error={!!props.errors.merchantName}
                  onChange={(e) =>
                    props.handleFormChange("merchantName", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <FormControl
                  id="country-form"
                  disabled={props.isEdit}
                  variant="outlined"
                  fullWidth
                  error={!!props.errors.country}
                >
                  <InputLabel htmlFor="country-label">País</InputLabel>
                  <Controller
                    name="country"
                    id="country-controller"
                    control={props.control}
                    as={
                      <Select
                        disabled={props.isEdit ? true : props.countryDisabled}
                        label="País"
                        value={props.merchantData.country}
                      >
                        {COUNTRIES.map((country: ICountry) => (
                          <MenuItem key={country.value} value={country.value}>
                            <img
                              src={country.flag}
                              width="24px"
                              height="24px"
                              alt={country.flag}
                              className={classes.marginRightS}
                            />
                            {country.name}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                    defaultValue={props.merchantData.country}
                    valueName="countrySel"
                    onChange={([countrySel]) =>
                      props.handleFormChange(
                        "country",
                        countrySel.target.value as string
                      )
                    }
                  />
                  {!!props.errors.country && (
                    <FormHelperText>
                      {props.errors.country.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {!props.isEdit && (
                <Grid item xs={12} className={classes.marginTopXS}>
                  <Grid container justify="flex-start">
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={showAuthenticationRuleSection}
                            onChange={handleChange}
                            color="primary"
                          />
                        }
                        label="Regla de autenticación"
                        classes={{ label: classes.switchLabel }}
                      />
                    </Grid>
                    {showAuthenticationRuleSection && (
                      <Grid id="supplier-section" item xs={12}>
                        <Box className={classes.rowTitleCheckboxList}>
                          <FormLabel className={classes.labelTitleCheckboxList}>
                            Nombre del Proveedor
                          </FormLabel>
                        </Box>
                        <Grid item xs={3}>
                          {suppliers.map((val, index) => (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={listAuthenticationRules[index]}
                                  onChange={() =>
                                    handleAuthenticationRule(val.name, index)
                                  }
                                />
                              }
                              label={val.name}
                            />
                          ))}
                        </Grid>
                        {listAuthenticationRules.filter(Boolean).length > 0 && (
                          <Grid id="authentication-type-section" item xs={12}>
                            <Box className={classes.rowDivider}>
                              <Divider />
                            </Box>
                            <Box className={classes.rowTitleCheckboxList}>
                              <FormLabel
                                className={classes.labelTitleCheckboxList}
                              >
                                Tipo de Autenticación
                              </FormLabel>
                            </Box>
                            {listAuthenticationRules.map(
                              (isChecked: boolean, idx: number) => {
                                if (isChecked) {
                                  return (
                                    <Grid
                                      key={idx}
                                      container
                                      className={
                                        classes.rowAuthenticationRuleCheckboxList
                                      }
                                      direction="column"
                                    >
                                      <FormLabel
                                        className={
                                          classes.labelAuthenticationRuleCheckboxList
                                        }
                                      >
                                        {suppliers[idx].name}
                                      </FormLabel>
                                      {suppliers[idx].authenticationType.map(
                                        (v, i) => (
                                          <FormControlLabel
                                            key={i}
                                            control={
                                              <Checkbox
                                                color="primary"
                                                checked={true}
                                              />
                                            }
                                            label={v}
                                          />
                                        )
                                      )}
                                    </Grid>
                                  );
                                }

                                return null;
                              }
                            )}
                          </Grid>
                        )}
                        {props.errors.authenticationRule && (
                          <FormHelperText error={true}>
                            <Typography style={{ fontSize: 14 }}>
                              Debes seleccionar al menos un proveedor
                            </Typography>
                          </FormHelperText>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12} className={classes.marginTopXS}>
                <Grid container justify="flex-end">
                  <Grid item xs={12} md={2}>
                    <Button
                      size="large"
                      fullWidth
                      component={Link}
                      to={
                        props.isEdit && props.publicMerchantId
                          ? `${routes.BASE_PATH_CREATE_MERCHANT}${routes.DETAILS}?publicMerchantId=${props.publicMerchantId}&hideSideBar=true`
                          : routes.MERCHANTS
                      }
                      color="default"
                    >
                      Salir
                    </Button>
                  </Grid>
                  <ComponentSecurityWrapper
                    componentId={SecurityWrapperIdEnum.CREAR_COMERCIO}
                  >
                    <Grid item xs={12} md={3}>
                      <Button
                        size="large"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          delete props.errors.authenticationRule;
                          if (
                            showAuthenticationRuleSection &&
                            listAuthenticationRules.filter(Boolean).length === 0
                          )
                            props.errors.authenticationRule = true;

                          props.onSubmit();
                        }}
                        disabled={props.isLoading}
                      >
                        {props.isEdit ? "Guardar cambios" : "Crear Comercio"}{" "}
                        {props.isLoading && (
                          <CircularProgress
                            style={{ marginLeft: 5 }}
                            color="inherit"
                            size={20}
                          />
                        )}
                      </Button>
                    </Grid>
                  </ComponentSecurityWrapper>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};
