import React from "react";
import { Grid, Skeleton } from "@mui/material";
import { SubHeaderSkeletonStyles } from "./SubHeaderSkeleton.styles";

export const SubHeaderSkeleton: React.FC = () => {
  return (
    <Grid
      container
      sx={SubHeaderSkeletonStyles.container}
      spacing={1}
      alignItems="center"
      data-testid="subheader-skeleton"
    >
      <Grid item md={7} xs={7} sx={SubHeaderSkeletonStyles.skeletonText}>
        <Skeleton />
      </Grid>
      <Grid item md={1} xs={1}>
        <Skeleton sx={SubHeaderSkeletonStyles.flagCountry} />
      </Grid>
      <Grid item md={4} xs={4} sx={SubHeaderSkeletonStyles.skeletonText}>
        <Skeleton />
      </Grid>
    </Grid>
  );
};
