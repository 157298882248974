import React, { useEffect, useState } from "react";
import { IMenuItem, TTableMenuProps } from "../TableMenu";
import { ConfirmModalProps } from "../../ConfirmModal/ConfirmModal";
import {
  ModalInfoButtonTextEnum,
  ModalInfoDescriptionEnum,
  ModalInfoTitleEnum,
  ModalInfoTypesEnum,
} from "../../../../shared/infrastructure/constants/ModalInfoEnum";
import { ProcessorFetch } from "../../../../shared/infrastructure/interfaces/ProcessorFetch";
import { defaultTo, get, isEqual, set, size } from "lodash";
import { OriginEnum } from "../../../../shared/infrastructure/constants/OriginEnum";
import { formatPaymentRoute } from "../../../TableComponent/TableComponent";
import { MenuItemTitleEnum } from "../../../../shared/infrastructure/constants/MenuItemEnum";
import { PaymentMethodEnum } from "../../../../shared/infrastructure/constants/PaymentMethodEnum";
import {
  ProcessorsEnum,
  ProcessorStatusEnum,
} from "../../../../shared/infrastructure/constants/ProcessorsEnum";
import { NotificationMessagesEnum } from "../../../../shared/infrastructure/constants/NotificationEnum";
import { auth } from "../../../../shared/auth";
import { MerchantResponse } from "../../../../../types/merchant_response";
import {
  ButtonColorEnum,
  ButtonVariantEnum,
} from "../../../../shared/infrastructure/constants/ButtonStylesEnum";
import { StyleNamesEnum } from "../../../../shared/infrastructure/constants/StyleNamesEnum";

export interface IUseTableMenuState {
  anchorEl: null | HTMLElement;
  modalInfo: ConfirmModalProps;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleCloseEl: () => void;
  menus: IMenuItem[];
}

export const useTableMenuState = (
  props: TTableMenuProps
): IUseTableMenuState => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModalInfo, setOpenModalInfo] = useState<boolean>(false);
  const [menuActive, setMenuActive] = useState<string>("");
  const basicInformation: MerchantResponse = auth.getAuthMerchant();

  const handleCloseEl = (): void => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenDeleteProcessorModal = (): void => {
    handleCloseEl();
    setMenuActive(
      props.isDefaultProcessor
        ? ModalInfoTypesEnum.DELETE_DEFAULT_PROCESSOR
        : ModalInfoTypesEnum.DELETE_PROCESSOR
    );
    setOpenModalInfo(true);
  };

  const handleCloseModalInfo = (): void => {
    setOpenModalInfo(false);
  };

  const handleEditProcessor = (): void => {
    const { paymentMethod, merchantId, publicProcessorId } = props.processor;

    window.location.href = `/${formatPaymentRoute(
      defaultTo(paymentMethod, "-")
    )}/${merchantId}/${publicProcessorId}`;

    setOpenModalInfo(false);
  };

  const handleDeleteProcessor = () => {
    props.deleteProcessor(
      props.processor.publicProcessorId,
      props.processor.merchantId,
      props.processor.paymentMethod,
      props.processors
    );
  };

  const handleUpdateProcessor = (): void => {
    const {
      merchantId,
      publicProcessorId,
      failOverProcessor,
      paymentMethod,
    } = props.processor;

    const isEnabled: boolean = isEqual(
      props.processor.status,
      ProcessorStatusEnum.ENABLED
    );

    const updatePayload: Partial<ProcessorFetch> = {
      merchantId,
      status: isEnabled
        ? ProcessorStatusEnum.DISABLED
        : ProcessorStatusEnum.ENABLED,
    };

    if (isEqual(paymentMethod, PaymentMethodEnum.CARD))
      set(updatePayload, "failoverAlias", defaultTo(failOverProcessor, ""));

    if (isEqual(paymentMethod, PaymentMethodEnum.PAYOUTS_CARD))
      set(updatePayload, "onlyChangeStatus", true);

    handleCloseModalInfo();
    props.handleUpdateProcessor({
      isDisableProcessorAction: true,
      notificationMessage: isEnabled
        ? NotificationMessagesEnum.DISABLE_PROCESSOR_SUCCESS
        : NotificationMessagesEnum.ENABLE_PROCESSOR_SUCCESS,
      notificationMessageError:
        NotificationMessagesEnum.UPDATE_STATUS_PROCESSOR_ERROR,
      data: updatePayload,
      merchantId,
      publicProcessorId,
      paymentMethod,
    });
  };

  const getFailOverProcessorsNames = (): string => {
    const failOverProcessors: string[] = defaultTo(props.processors, [])
      .filter((processor: ProcessorFetch) =>
        isEqual(processor.failOverProcessor, get(props.processor, "alias"))
      )
      .map((processor: ProcessorFetch) =>
        defaultTo(processor.alias, processor.processorName)
      );

    return failOverProcessors[0].concat(
      size(failOverProcessors) > 1 ? " y para otros" : ""
    );
  };

  const getModalInfoData = (): ConfirmModalProps => {
    let modalInfo: ConfirmModalProps;

    switch (menuActive) {
      case ModalInfoTypesEnum.DISABLE_DEFAULT_PROCESSOR:
        modalInfo = {
          open: openModalInfo,
          openLoadingDialog: props.loadingDialog,
          titleText: ModalInfoTitleEnum.DISABLE_PROCESSOR,
          contentText: ModalInfoDescriptionEnum.DISABLE_DEFAULT_PROCESSOR,
          acceptText: ModalInfoButtonTextEnum.AGREE,
          handleAccept: handleCloseModalInfo,
          acceptStyle: {
            color: ButtonColorEnum.Primary,
            variant: ButtonVariantEnum.Contained,
            error: false,
          },
          loading: props.loading,
        };
        break;
      case ModalInfoTypesEnum.DISABLE_FAILOVER_PROCESSOR:
        modalInfo = {
          open: openModalInfo,
          titleText: ModalInfoTitleEnum.DISABLE_PROCESSOR,
          openLoadingDialog: props.loadingDialog,
          contentText: ModalInfoDescriptionEnum.DISABLE_FAILOVER_PROCESSOR.replace(
            "{processorName}",
            getFailOverProcessorsNames()
          ),
          acceptText: ModalInfoButtonTextEnum.DISABLE_PROCESSOR,
          cancelText: ModalInfoButtonTextEnum.CANCEL,
          handleClose: handleCloseModalInfo,
          handleAccept: handleUpdateProcessor,
          acceptStyle: { error: true },
          loading: props.loading,
        };
        break;
      case ModalInfoTypesEnum.DISABLE_PROCESSOR:
        modalInfo = {
          open: openModalInfo,
          openLoadingDialog: props.loadingDialog,
          titleText: ModalInfoTitleEnum.DISABLE_PROCESSOR,
          contentText: ModalInfoDescriptionEnum.DISABLE_PROCESSOR,
          acceptText: ModalInfoButtonTextEnum.DISABLE_PROCESSOR,
          cancelText: ModalInfoButtonTextEnum.CANCEL,
          handleClose: handleCloseModalInfo,
          handleAccept: handleUpdateProcessor,
          acceptStyle: { error: true },
          loading: props.loading,
        };
        break;
      case ModalInfoTypesEnum.ENABLE_PROCESSOR:
        modalInfo = {
          open: openModalInfo,
          openLoadingDialog: props.loadingDialog,
          titleText: ModalInfoTitleEnum.ENABLE_PROCESSOR,
          contentText: ModalInfoDescriptionEnum.ENABLE_PROCESSOR,
          acceptText: ModalInfoButtonTextEnum.ENABLE_PROCESSOR,
          cancelText: ModalInfoButtonTextEnum.EDIT_PROCESSOR,
          handleClose: handleEditProcessor,
          handleAccept: handleUpdateProcessor,
          acceptStyle: { error: false },
          loading: props.loading,
        };
        break;
      case ModalInfoTypesEnum.DELETE_DEFAULT_PROCESSOR:
        modalInfo = {
          open: openModalInfo,
          titleText: ModalInfoTitleEnum.DELETE_DEFAULT_PROCESSOR,
          contentText: ModalInfoDescriptionEnum.DELETE_DEFAULT_PROCESSOR,
          acceptText: ModalInfoButtonTextEnum.AGREE,
          handleAccept: handleCloseModalInfo,
          acceptStyle: {
            color: ButtonColorEnum.Primary,
            variant: ButtonVariantEnum.Contained,
            error: false,
          },
          loading: props.loading,
        };
        break;
      case ModalInfoTypesEnum.DELETE_PROCESSOR:
      default:
        modalInfo = {
          open: openModalInfo,
          titleText: ModalInfoTitleEnum.DELETE_PROCESSOR.replace(
            "{processorName}",
            props.processor.processorName
          ),
          contentText: ModalInfoDescriptionEnum.DELETE_PROCESSOR,
          acceptText: ModalInfoButtonTextEnum.DELETE_PROCESSOR,
          handleClose: handleCloseModalInfo,
          handleAccept: handleDeleteProcessor,
          acceptStyle: { error: true },
          loading: props.loading,
          cancelText: ModalInfoButtonTextEnum.CANCEL,
        };
        break;
    }

    return modalInfo;
  };

  const getDisableData = (): void => {
    if (props.isDefaultProcessor)
      setMenuActive(ModalInfoTypesEnum.DISABLE_DEFAULT_PROCESSOR);
    else if (
      defaultTo(props.processors, []).some((processorItem: ProcessorFetch) =>
        isEqual(processorItem.failOverProcessor, get(props.processor, "alias"))
      )
    )
      setMenuActive(ModalInfoTypesEnum.DISABLE_FAILOVER_PROCESSOR);
    else setMenuActive(ModalInfoTypesEnum.DISABLE_PROCESSOR);

    setOpenModalInfo(true);
  };

  const getMenusByProcessor = (): IMenuItem[] => {
    const {
      status,
      paymentMethod,
      merchantId,
      publicProcessorId,
      processorName,
    } = props.processor;
    const origin: string = get(basicInformation, "origin", "");
    const exclude_paths: string[] = [
      OriginEnum.CREATE_MERCHANT,
      OriginEnum.CREATE_MERCHANT_V3,
    ];
    const hasFailOver: boolean = isEqual(paymentMethod, PaymentMethodEnum.CARD);
    const processorIsEnabled: boolean = !isEqual(
      status,
      ProcessorStatusEnum.DISABLED
    );
    const redirectEditProcessor: string = `/${formatPaymentRoute(
      defaultTo(paymentMethod, "-")
    )}/${merchantId}/${publicProcessorId}`;
    const processorIsBancoBogota: boolean = isEqual(
      processorName,
      ProcessorsEnum.BANCO_DE_BOGOTA
    );

    return [
      {
        activeMenu: processorIsEnabled && !processorIsBancoBogota,
        label: MenuItemTitleEnum.EDIT_PROCESSOR,
        to: redirectEditProcessor,
      },
      {
        activeMenu: hasFailOver && processorIsEnabled,
        label: MenuItemTitleEnum.ASSIGN_FAILOVER,
        handleClick: () => {
          props.onFailOver(props.processor);
          handleCloseEl();
        },
      },
      {
        activeMenu: !exclude_paths.includes(origin) && !processorIsEnabled,
        label: MenuItemTitleEnum.ENABLED_PROCESSOR,
        handleClick: () => {
          setMenuActive(ModalInfoTypesEnum.ENABLE_PROCESSOR);
          setOpenModalInfo(true);
          handleCloseEl();
        },
      },
      {
        activeMenu:
          !exclude_paths.includes(origin) &&
          processorIsEnabled &&
          !processorIsBancoBogota,
        label: MenuItemTitleEnum.DISABLED_PROCESSOR,
        handleClick: () => {
          getDisableData();
          handleCloseEl();
        },
      },
      {
        activeMenu: true,
        label: MenuItemTitleEnum.DELETE_PROCESSOR,
        handleClick: handleOpenDeleteProcessorModal,
        className: StyleNamesEnum.DELETE_PROCESSOR_BUTTON,
      },
    ];
  };

  useEffect(() => {
    if (!props.dialogClosed) return;

    handleCloseModalInfo();
    props.setCloseDialog(false);
  }, [props.dialogClosed]);

  return {
    anchorEl,
    modalInfo: getModalInfoData(),
    handleClick,
    handleCloseEl,
    menus: getMenusByProcessor(),
  };
};
