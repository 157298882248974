import React from "react";

export const CheckCircleIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
        fill="#4498EE"
      />
      <path
        d="M9.41391 12.5C9.35038 12.5004 9.28741 12.4883 9.2286 12.4646C9.16979 12.4409 9.11629 12.406 9.07119 12.3618L7.14037 10.4563C7.05046 10.3671 7 10.2463 7 10.1205C7 9.99462 7.05046 9.87388 7.14037 9.78462C7.18524 9.73997 7.23863 9.70453 7.29745 9.68035C7.35627 9.65616 7.41937 9.64371 7.48309 9.64371C7.54681 9.64371 7.6099 9.65616 7.66873 9.68035C7.72755 9.70453 7.78094 9.73997 7.82581 9.78462L9.41391 11.3519L13.1742 7.64091C13.2191 7.59626 13.2725 7.56082 13.3313 7.53664C13.3901 7.51245 13.4532 7.5 13.5169 7.5C13.5806 7.5 13.6437 7.51245 13.7025 7.53664C13.7614 7.56082 13.8148 7.59626 13.8596 7.64091C13.9495 7.73017 14 7.85091 14 7.97676C14 8.10261 13.9495 8.22335 13.8596 8.31261L9.75663 12.3618C9.71153 12.406 9.65804 12.4409 9.59923 12.4646C9.54041 12.4883 9.47744 12.5004 9.41391 12.5Z"
        fill="white"
      />
    </svg>
  );
};
