import React, { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { BRANCH_LABELS } from "../../shared/constants/labels/branch_container_labels";
import { InfoCentralizedTooltipComponent } from "../InfoCentralizedTooltip/InfoCentralizedTooltipComponent";
import { TooltipTitleEnums } from "../../shared/enums/TooltipTitleEnums";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { ComponentIdsEnum } from "../../shared/constants/labels/main_containter_labels";
import {
  Alert,
  FilterDetailBar,
  IconSearch,
  IFilterItem,
  ItemCategoryItem,
} from "@kushki/connect-ui";
import { FilterSideBar } from "../Filters/FilterSideBar/FilterSideBar";
import { FilterSideBarProps } from "../Filters/FilterSideBar/FilterSideBar.interfaces";
import { ItemCategoryProps } from "../Filters/ItemCategory/interfaces";
import { headerTableStyles as styles } from "./HeaderTable.styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IHeaderProps } from "./HeaderTable.interfaces";
import { BRANCH_MANUAL_LABELS } from "../../shared/constants/labels/branch_manual_creation_labels";
import { CountryEnum } from "../../shared/enums/countryEnum";
import {
  TFilterDetailBar,
  TOnChangeFilters,
} from "@kushki/connect-ui/dist/Components/Molecules/Filters/FilterDetailBar/FilterDetailBar.interfaces";
import { cloneDeep, get, isEmpty, set } from "lodash";

export const HeaderTable: FC<IHeaderProps> = (props: IHeaderProps) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [filterSideBarProps, setFilterSideBarProps] =
    useState<FilterSideBarProps>(cloneDeep(props.initialFiltersSideBars));
  const [itemsDetailBar, setItemsDetailBar] = useState<TFilterDetailBar[]>([]);

  useEffect(() => {
    let filterSideBar: FilterSideBarProps = cloneDeep(filterSideBarProps);

    const index: number = filterSideBar.categoryItems.findIndex(
      (item: ItemCategoryProps) =>
        item.groupSection === BRANCH_MANUAL_LABELS.COUNTRY
    );

    set(filterSideBar.categoryItems, `[${index}]`, {
      groupSection: BRANCH_MANUAL_LABELS.COUNTRY,
      isMinimize: true,
      items:
        props.filterCountries.length > 0
          ? props.filterCountries
          : [
              {
                label: CountryEnum.mexico,
                selected: false,
                value: "Mexico",
              },
            ],
      selectType: "multiple",
      type: "byChips",
    });

    setFilterSideBarProps(filterSideBar);
  }, [props.filterCountries]);

  const getItemsFilterDetailBar = (
    itemCategory: ItemCategoryProps[]
  ): TFilterDetailBar[] => {
    let itemsFilterDetailBar: TFilterDetailBar[] = [];

    itemCategory.map((itemSideBar: ItemCategoryProps) => {
      let itemsFilterBar: IFilterItem[] = [];

      itemSideBar.items.map((itemFilterSideBar: ItemCategoryItem) => {
        return (
          itemFilterSideBar.selected &&
          itemsFilterBar.push({
            label: itemFilterSideBar.label,
            value: itemFilterSideBar.value,
          })
        );
      });
      if (itemsFilterBar.length > 0)
        itemsFilterDetailBar.push({
          items: itemsFilterBar,
          title: itemSideBar.groupSection,
        });
    });

    return itemsFilterDetailBar;
  };

  const onClickFilterSideBar = (itemCategory: ItemCategoryProps[]) => {
    filterSideBarProps.onClick(itemCategory);
    props.handleOnFilter(itemCategory);
    const itemsFilterDetailBar = getItemsFilterDetailBar(
      cloneDeep(itemCategory)
    );

    setItemsDetailBar(itemsFilterDetailBar);
    if (props.setCountFilters)
      props.setCountFilters(itemsFilterDetailBar.length);
  };

  const onChangeFiltersDetailBar = (changes: TOnChangeFilters) => {
    let filterSideBar: FilterSideBarProps = { ...filterSideBarProps };

    let itemsSideBar: ItemCategoryProps[] = get(
      filterSideBarProps,
      "categoryItems"
    );
    let itemsSideBarFinal: ItemCategoryProps[] = [];

    itemsSideBar.forEach((itemSideBar) => {
      let itemsFilterSideBar: ItemCategoryItem[] = [];
      let itemCategoryPropsElement: ItemCategoryProps = itemSideBar;

      itemCategoryPropsElement.items.forEach((itemFilterSideBar) => {
        let itemFilterSideBarElement: ItemCategoryItem = itemFilterSideBar;

        if (
          changes.affected.itemDeleted.label === itemFilterSideBarElement.label
        )
          set(itemFilterSideBarElement, "selected", false);

        itemsFilterSideBar.push(itemFilterSideBarElement);
      });
      set(itemCategoryPropsElement, "items", itemsFilterSideBar);
      itemsSideBarFinal.push(itemCategoryPropsElement);
    });
    const itemsFilterDetailBar = getItemsFilterDetailBar(
      cloneDeep(filterSideBar.categoryItems)
    );

    set(filterSideBar, "categoryItems", itemsSideBarFinal);
    setFilterSideBarProps(filterSideBar);
    setItemsDetailBar(itemsFilterDetailBar);
    if (props.setCountFilters)
      props.setCountFilters(itemsFilterDetailBar.length);
    filterSideBarProps.onClick(itemsSideBarFinal);
    props.handleOnFilter(itemsSideBarFinal);
  };

  return (
    <>
      <Box sx={styles.boxContentAddBranch}>
        <Grid container>
          <Grid item xs={10}>
            <Grid container>
              <Grid item xs={12}>
                <Typography sx={styles.labelTitle}>{props.title}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item>
                    <Typography sx={styles.labelSubtitle}>
                      {props.subtitle}
                    </Typography>
                  </Grid>
                  {props.hasToolTipHeader && (
                    <Grid item>
                      <InfoCentralizedTooltipComponent
                        icon={<InfoOutlinedIcon />}
                        title={TooltipTitleEnums.INFO_CENTRALIZED}
                        placement={"top"}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item>
                  <Typography sx={styles.labelSubtitle}>
                    {props.additionalAlertMessage}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <ComponentSecurityWrapper
              componentId={ComponentIdsEnum.M_CLIENTS_BRANCH_MANUAL_NUEVO}
            >
              {!props.allBranchesNotModified && (
                <Button
                  variant="contained"
                  startIcon={props.iconBtn}
                  color="primary"
                  onClick={() => props.handleBtn()}
                  disabled={props.disableEditButton}
                >
                  {props.titleBtn}
                </Button>
              )}
            </ComponentSecurityWrapper>
          </Grid>
          {get(props, "showAlert", false) && (
            <Grid item xs={12}>
              <Alert
                title={props.textValueAlert}
                type="warning"
                onClose={() => props.showAlertAction!(false)}
                closeButton={true}
              />
            </Grid>
          )}
          <Grid container>
            <Grid item xs={4}>
              <Box mt={2} mb={2}>
                <TextField
                  placeholder="Buscar por nombre..."
                  margin="normal"
                  fullWidth
                  className="solidTextfield"
                  variant="outlined"
                  onChange={(e) => {
                    const valueSearch = e.target.value.toUpperCase().trim();

                    setSearchValue(valueSearch);
                    if (isEmpty(valueSearch)) {
                      props.handleOnSearch("");
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") props.handleOnSearch(searchValue);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          onClick={() => props.handleOnSearch(searchValue)}
                        >
                          <IconSearch color="primary" fontSize="medium" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={8} sx={styles.gridFilter}>
              <Box mt={2} mb={2}>
                <FilterSideBar
                  data-testid={"filterBar"}
                  {...filterSideBarProps}
                  categoryItems={filterSideBarProps.categoryItems}
                  onClick={onClickFilterSideBar}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid sx={styles.gridFilterCard}>
            <FilterDetailBar
              filters={itemsDetailBar}
              onChangeFilters={onChangeFiltersDetailBar}
            />
          </Grid>
          <Grid container sx={styles.totalBranches}>
            {props.total && (
              <Grid item xs={6} md={6}>
                <Typography sx={styles.labelSubtitle}>
                  {BRANCH_LABELS.TOTAL_BRANCHES(props.total!)}
                </Typography>
              </Grid>
            )}
            {props.totalEdit && (
              <Grid item xs={6} md={6}>
                <Typography sx={styles.labelSubtitle}>
                  {BRANCH_LABELS.TOTAL_EDIT_BRANCHES(props.totalEdit!)}
                </Typography>
              </Grid>
            )}
            {props.totalSelected && (
              <Grid item xs={6} md={6} sx={styles.totalSelectedEdit}>
                <Typography sx={styles.labelSubtitle}>
                  {BRANCH_LABELS.TOTAL_EDIT_SELECTED_BRANCHES(
                    props.totalSelected!
                  )}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
