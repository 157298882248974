import React, { FC } from "react";
import {
  Box,
  Checkbox,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import TransactionStatusChip from "../TransactionStatusChip/TransactionStatusChip";
import { ITransactionTableItemProps } from "./TransactionTableItem.interfaces";
import { useTransactionTableItemState } from "./state/useTransactionTableItemState";
import { transactionTableItemStyles } from "./TransactionTableItem.styles";
import {
  PAYMENT_METHOD_ENUM_LOOKUP,
  PAYMENT_METHOD_LABEL_LOOKUP,
} from "../../../shared/types";
import ActionTransactionButton from "../../ActionTransactionButton/ActionTransactionButton";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";

const TransactionTableItem: FC<ITransactionTableItemProps> = ({
  isSelected,
  transaction,
}: ITransactionTableItemProps) => {
  const {
    handlers: { toggleClick, toggleSelection },
    tooltipTitle,
  } = useTransactionTableItemState(transaction);

  return (
    <TableRow hover sx={transactionTableItemStyles.container}>
      <TableCell padding="checkbox">
        <Checkbox checked={isSelected} onChange={toggleSelection} />
      </TableCell>
      <TableCell onClick={toggleClick}>
        <Typography sx={transactionTableItemStyles.label}>
          {transaction.date}
        </Typography>
        <Typography sx={transactionTableItemStyles.subLabel}>
          {transaction.hour}
        </Typography>
      </TableCell>
      <TableCell onClick={toggleClick}>
        <Typography sx={transactionTableItemStyles.label}>
          {transaction.ticketNumber}
        </Typography>
      </TableCell>
      <TableCell onClick={toggleClick}>
        <Typography sx={transactionTableItemStyles.label}>
          {transaction.email}
        </Typography>
      </TableCell>
      <TableCell onClick={toggleClick}>
        <Typography sx={transactionTableItemStyles.label}>
          {PAYMENT_METHOD_LABEL_LOOKUP[transaction.paymentMethod]}
        </Typography>
      </TableCell>
      <TableCell onClick={toggleClick}>
        <Typography sx={transactionTableItemStyles.label}>
          {transaction.vehiclePlate}
        </Typography>
      </TableCell>
      <TableCell onClick={toggleClick}>
        <Typography sx={transactionTableItemStyles.label}>
          {transaction.transactionAmount}
        </Typography>
      </TableCell>
      <TableCell onClick={toggleClick}>
        <TransactionStatusChip status={transaction.status} />
      </TableCell>
      <TableCell>
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                ...(transactionTableItemStyles.actionTooltip as SxProps<Theme>),
              },
            },
          }}
          title={tooltipTitle}
          placement="top-start"
          arrow
        >
          <Box>
            <ActionTransactionButton
              payMethod={PAYMENT_METHOD_ENUM_LOOKUP[transaction.paymentMethod]}
              transaction={transaction}
            />
          </Box>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default TransactionTableItem;
