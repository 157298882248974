import { ICellSelectItemProps } from "./CellSelectItem.interfaces";
import { useEffect, useMemo, useState } from "react";
import { useTableContext } from "../../Table/TableContext";
import { pushOrReplaceObjectInValueCells } from "../../Table/TableContext/tableUtils";
import { get, isArray, isEmpty } from "lodash";
import { IValueCell } from "../CellHeader/interfaces";
import { InputProps as StandardInputProps } from "@mui/material/Input/Input";
import { ValueCellToBlock } from "../../Table/TableSimple/interfaces";

interface IUseCellRow {
  value?: string;
  error: boolean;
  isLoading: boolean;
  handleChange: StandardInputProps["onChange"];
  required?: boolean;
  disable: boolean;
}

const useCellSelectItem = ({
  defaultValue,
  id,
  rowIndex,
  required,
  isCountry,
  showAlertAction,
  isDisabled,
}: ICellSelectItemProps): IUseCellRow => {
  const [valueI, setValueI] = useState(defaultValue);
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(!!isDisabled);

  const {
    state: { isLoading, valueCells, valuesCellsToBlock, selectedRows },
    handler: { handleSetCellValues },
  } = useTableContext();

  const validateUpdateStore = (inputValue?: string) => {
    let notValid: boolean = error;

    if (required) {
      notValid = isEmpty(inputValue);
      setError(notValid);
    }

    handleSetCellValues(
      pushOrReplaceObjectInValueCells(valueCells, {
        error: notValid,
        id: `${rowIndex}-${id}`,
        rowIndex,
        value: inputValue,
      })
    );
  };

  const handleChange = (event: { target: { value: string } }) => {
    if (isCountry) {
      if (showAlertAction) showAlertAction(true);
    }
    const inputValue: string = event.target.value;

    validateUpdateStore(inputValue);
    setValueI(inputValue);
  };

  const valueStore: IValueCell | undefined = useMemo(
    () =>
      valueCells.find((cell: IValueCell) => cell.id === `${rowIndex}-${id}`),
    []
  );

  const valueStoreCheck: IValueCell | undefined = useMemo(() => {
    if (isArray(valuesCellsToBlock) && valuesCellsToBlock.length > 0)
      return valueCells.find(
        (cell: IValueCell) => cell.id === `${rowIndex}-table-check`
      );

    return undefined;
  }, [selectedRows.length]);

  useEffect(() => {
    if (isArray(valuesCellsToBlock) && valuesCellsToBlock.length > 0) {
      valuesCellsToBlock.map((valueToChange: ValueCellToBlock) => {
        if (valueToChange.id === id) {
          const isDisabled: boolean = !valueStoreCheck?.value;

          if (isDisabled) {
            validateUpdateStore(valueToChange.defaultValue);
            setValueI(valueToChange.defaultValue);
          }
          setDisable(isDisabled);
        }
      });
    }
  }, [valueStoreCheck]);

  useEffect(() => {
    if (valueStore) {
      setValueI(get(valueStore, "value", valueI) as string);
    } else {
      validateUpdateStore(defaultValue);
      setValueI(defaultValue);
    }
  }, [valueStore, defaultValue]);

  return {
    disable,
    error,
    handleChange,
    isLoading,
    required,
    value: valueI,
  };
};

export default useCellSelectItem;
