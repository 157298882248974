import React from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import {
  Box,
  FormControl,
  Grid,
  InputBase,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { StatusFlowEnum } from "../../shared/infrastructure/StatusFlowEnum";
import { IBillingDashboardState } from "../../store/reducer";
import { useSelector } from "react-redux";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import {
  CountriesDefault,
  CountriesName,
  isValidCountry,
} from "../../shared/infrastructure/catalog/countries-catalog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "70%",
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  })
);

export interface DashboardHeaderProps {
  country: string;
  handleSelectChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  statusFlow: StatusFlowEnum;
}

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: "10px!important",
      position: "relative",
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      "&:focus": {
        borderRadius: "10px!important",
      },
    },
  })
)(InputBase);

export const DashboardHeader: React.FC<DashboardHeaderProps> = (
  props: DashboardHeaderProps
) => {
  const {
    country,
    statusFlow: statusFlowStore,
    countryList,
    isAzure,
  } = useSelector((state: IBillingDashboardState) => state);
  const classes = useStyles(props);

  const getCountryList = () => {
    let countriesArray: string[] = [];

    if (isAzure) {
      countryList?.forEach((item) => {
        const checkCountry = isValidCountry(item);
        if (checkCountry) {
          countriesArray.push(checkCountry);
        }
      });
      return countriesArray;
    }
    return CountriesName;
  };

  const localCountryList = getCountryList();

  return (
    <React.Fragment>
      <Grid
        container
        alignItems="center"
        justify="flex-start"
        direction="row"
        spacing={1}
      >
        <Box justifyContent="left" display="flex" paddingRight={2}>
          <Typography color={"primary"} variant={"h1"}>
            Facturación
          </Typography>
        </Box>
        {statusFlowStore === StatusFlowEnum.EXECUTOR &&
        country !== CountryEnum.ecuador ? (
          <Grid item xs={4} md={3}>
            <Typography variant={"h6"} color={"textSecondary"}>
              Las operaciones aquí procesadas, se mandarán a revisión para su
              posterior aprobación.
            </Typography>
          </Grid>
        ) : null}
        <Grid item xs={4} md={3}>
          <FormControl className={classes.formControl}>
            <Select
              id="simple-select-outlined"
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              value={props.country}
              onChange={props.handleSelectChange}
              input={<BootstrapInput />}
              classes={{ icon: classes.icon }}
            >
              {localCountryList.map((item) => {
                return (
                  <MenuItem
                    key={CountriesDefault[item].value}
                    value={CountriesDefault[item].value}
                  >
                    {CountriesDefault[item].name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        {statusFlowStore === StatusFlowEnum.EXECUTOR &&
        country === CountryEnum.ecuador ? (
          <Grid item xs={5} md={5}>
            <Grid container justify="flex-end">
              <Grid item xs={12}>
                <Typography variant={"h6"} color={"textSecondary"}>
                  Las operaciones aquí procesadas, se mandarán a revisión para
                  su posterior aprobación.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </React.Fragment>
  );
};
