import { IStyles } from "../../../shared/interfaces/Styles.interfaces";

export const styles: IStyles = {
  button: {
    backgroundColor: "neutral.white",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px !important",
    borderColor: "neutral.grey4",
    borderLeftStyle: "solid",
    borderLeftWidth: "1px !important",
    borderRadius: "0px 4px !important",
  },
  icon: {
    color: "neutral.grey7",
  },
};
