import React from "react";
import {
  FormGroup,
  FormControlLabel,
  Box,
  Grid,
  Typography,
  FormControl,
  Switch,
} from "@mui/material";
import {
  Conditions,
  ConfigurationResponse,
} from "../../../types/configuration_response";
import {
  SPECIAL_CONDITIONS,
  SubTitleSection,
  Values,
} from "../../shared/infrastructure/constants/RuleRequestManagerConstants";
import {
  Control,
  Controller,
  useFormContext,
  UseFormMethods,
} from "react-hook-form";
import { ISecurityRuleForm } from "../../shared/infrastructure/interfaces/ISecurityRuleForm";
import { useSpecialConditionSectionState } from "./state/useSpecialConditionSectionState";
import { GeneralConditionSection } from "../GeneralConditionSection/GeneralConditionSection";
import { defaultTo, get } from "lodash";
import { generalStyles } from "../../shared/styles/generalStyles.styles";
import { LabelEnum } from "../../shared/infrastructure/enums/LabelEnum";
export interface SpecialConditionSectionProps {
  configuration?: ConfigurationResponse;
  country: string[];
  form?: UseFormMethods<ISecurityRuleForm>;
  setIsDisabledSpeed: React.Dispatch<React.SetStateAction<boolean>>;
  isDisabledSpecial: boolean;
  fieldsCond: Object[];
}

export const SpecialConditionSection = (
  props: SpecialConditionSectionProps
): JSX.Element => {
  const { setValue } = useFormContext();
  const control: Control<Record<string, any>> = useFormContext().control;
  const {
    handle: { enableSpecial },
    isSpecial,
    availableConditions,
    configurationValue,
    fields,
  } = useSpecialConditionSectionState(props);
  const classes = generalStyles();
  const EMPTY_ARRAY: any[] = [];
  const EMPTY_FUNC = function () {};

  const renderSelectProps = ({ ...selectProps }) => (
    <FormGroup {...selectProps}>
      <FormControlLabel
        defaultChecked={false}
        control={
          <Switch
            color="primary"
            size="small"
            value={
              isSpecial ? Values.RULES_KIND_STRICT : Values.RULES_KIND_SPECIAL
            }
            checked={isSpecial}
            disabled={props.isDisabledSpecial}
            onChange={(evt) => {
              setValue("kind", Values.RULES_KIND_STRICT);
              enableSpecial(evt.target.value === Values.RULES_KIND_SPECIAL);
            }}
          />
        }
        label={""}
      />
    </FormGroup>
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={4} mt={3}>
        <Typography variant="h6">
          {LabelEnum.SPECIAL_CONDITION_RULES}
        </Typography>
      </Grid>
      <Grid item xs={6} mt={3}>
        <Box>
          <FormControl variant="outlined" fullWidth>
            <Controller
              name="subKind"
              control={control}
              render={renderSelectProps}
            />
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {isSpecial && (
          <>
            <Grid container spacing={1.5} marginTop={1} marginBottom={1}>
              <Grid item xs={12}>
                <Typography className={classes.subtitleTypography}>
                  {SubTitleSection.SPECIAL_CONDITION_SUBTITLE}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1.5}>
              <Grid item xs={12}>
                {fields.map(
                  (
                    item: Partial<Record<string, any> & Record<"id", string>>,
                    index: number
                  ) => (
                    <React.Fragment key={item.id}>
                      <GeneralConditionSection
                        append={EMPTY_FUNC}
                        country={EMPTY_ARRAY}
                        credentials={EMPTY_ARRAY}
                        generalConditions={defaultTo(
                          availableConditions,
                          []
                        ).filter((condition: Conditions) =>
                          SPECIAL_CONDITIONS.includes(
                            get(condition, "field", "")
                          )
                        )}
                        generalOperators={get(
                          configurationValue,
                          "operators",
                          []
                        )}
                        generalTransactionsType={EMPTY_ARRAY}
                        mccList={EMPTY_ARRAY}
                        merchantCategoryList={EMPTY_ARRAY}
                        geographyList={EMPTY_ARRAY}
                        isLoading={false}
                        item={item}
                        key={item.id}
                        processors={EMPTY_ARRAY}
                        remove={EMPTY_FUNC}
                        total={fields}
                        isWhiteList={true}
                        index={index}
                        isSpecialCond={true}
                      />
                    </React.Fragment>
                  )
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};
export default SpecialConditionSection;
