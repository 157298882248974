import { Box, MenuItem, Select, Skeleton, Typography } from "@mui/material";
import { FC } from "react";
import { TRANSACTIONS_PER_PAGE_LIMITS } from "../../../../shared/constants/transactions_per_page_limits";
import { useTransactionTableFooterLimitSelectState } from "./state/useTransactionTableFooterLimitSelectState";
import { transactionTableFooterLimitSelectStyles } from "./TransactionTableFooterLimitSelect.styles";

const TransactionTableFooterLimitSelect: FC = () => {
  const {
    areTransactionsLoading,
    handleSetContentLimit,
    limit,
    pagesRange,
    total,
  } = useTransactionTableFooterLimitSelectState();

  return (
    <Box sx={transactionTableFooterLimitSelectStyles.container}>
      <Typography sx={transactionTableFooterLimitSelectStyles.label}>
        Transacciones por página
      </Typography>
      <Select
        MenuProps={{
          sx: transactionTableFooterLimitSelectStyles.menu,
        }}
        onChange={(e) => handleSetContentLimit(e.target.value as number)}
        sx={transactionTableFooterLimitSelectStyles.select}
        value={limit}
      >
        {TRANSACTIONS_PER_PAGE_LIMITS.map((limit, index) => (
          <MenuItem
            disabled={limit > total}
            key={index}
            sx={transactionTableFooterLimitSelectStyles.menuItem}
            value={limit}
          >
            {limit}
          </MenuItem>
        ))}
      </Select>
      <Typography sx={transactionTableFooterLimitSelectStyles.label}>
        {areTransactionsLoading ? (
          <Skeleton width={80} />
        ) : (
          <>{total > 0 && `${pagesRange} de ${total}`}</>
        )}
      </Typography>
    </Box>
  );
};

export default TransactionTableFooterLimitSelect;
