import { makeStyles, Theme } from "@material-ui/core/styles";
import { DateRange } from "@material-ui/pickers";
import { Grid } from "@material-ui/core";
import { KshDateRangePicker } from "@kushki/frontend-molecules/date-range-picker";
import React from "react";
import { Skeleton } from "@material-ui/lab";

export interface DatePickerProps {
  handleDate?: (dateRange: DateRange<Date>) => void;
  disableFuture?: boolean;
  value?: DateRange<Date>;
  isLoading?: boolean;
  minDate?: Date;
}

const useStyles = makeStyles((theme: Theme) => ({
  datePicker: {
    [theme.breakpoints.down(768)]: {
      border: "none",
      marginLeft: "inherit",
      marginRight: "inherit",
    },
    marginRight: "20px",
  },
  gridContainer: {
    marginTop: "20px",
    paddingBottom: "3px",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down(768)]: {
      border: "none",
      borderRadius: 0,
    },
  },
}));

export const DatePicker: React.FC<DatePickerProps> = (
  props: DatePickerProps
) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid className={classes.gridContainer} item xs={12}>
        {props.isLoading ? (
          <Skeleton
            animation="wave"
            variant="rect"
            width={"100%"}
            height={35}
          />
        ) : (
          <KshDateRangePicker
            handleDate={props.handleDate!}
            disableFuture={true}
            value={props.value!}
          />
        )}
      </Grid>
    </Grid>
  );
};
