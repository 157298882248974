import React from "react";
import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { defaultTo, take } from "lodash";
import { MerchantProcessors } from "../../../../types/merchant_processors";
import theme from "../../../theme";
import { KshSelectInput } from "../../common/KshSelectInput/KshSelectInput";
import {
  IDefaultProcessors,
  IProcessorsType,
} from "../../../store/actionCreators";
import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import { capitalize, get } from "lodash";
import { IOptionSelection } from "../../../shared/infrastructure/constants/RulesConstants";
import { translateProcessor, translateRuleKeys } from "../../../shared/utils";
import { CountryEnum } from "../../../shared/infrastructure/catalogs/CountryEnum";

const useStyles = makeStyles(() => ({
  tableContainer: { background: "transparent", paddingBottom: "25px" },
  tableCell: { paddingTop: "10px", paddingBottom: "10px" },
  width200: {
    width: "200px",
  },
}));

export const PROCESSOR_MODEL_TYPES: IOptionSelection[] = [
  { name: "Agregador", value: "AGGREGATOR" },
];

interface ITableRows {
  type: string;
  rows: MerchantProcessors[];
  defaultProcessor: string | undefined;
}

export type IProcessorsTable = {
  merchantId: string;
  defaultProcessors?: IDefaultProcessors;
  defaultPayoutProcessor?: IDefaultProcessors;
  loadingFailover: boolean;
  processors?: IProcessorsType;
  saveFailoverProcessor(
    merchantId: string,
    publicProcessorId: string,
    failover: {
      failoverAlias: string;
    }
  ): void;
  country?: string;
};

export const ProcessorsTable: React.FC<IProcessorsTable> = (
  props: IProcessorsTable
) => {
  const classes = useStyles();
  const getTableHeaders = (): string[] => {
    const card_processors: MerchantProcessors[] = defaultTo(
      props.processors?.card,
      []
    );
    let headers: string[] = ["Public ID", "Alias", "Procesador", "Modelo"];

    if (card_processors.length > 1 && validateFailOverShow())
      headers.push("Failover");

    return headers;
  };
  const getTableRows = (): ITableRows[] => {
    const processor:
      | MerchantProcessors
      | undefined = props.processors?.transferPayout.find(
      (processor: MerchantProcessors) =>
        processor.publicProcessorId ===
        get(props.defaultPayoutProcessor, "payoutsTransfer")
    );
    let rows: {
      type: string;
      rows: MerchantProcessors[];
      defaultProcessor: string | undefined;
    }[] = [];
    const card_processors: MerchantProcessors[] = defaultTo(
      props.processors?.card,
      []
    );
    const cash_processors: MerchantProcessors[] = defaultTo(
      props.processors?.cash,
      []
    );
    const cash_payout_processors: MerchantProcessors[] = defaultTo(
      props.processors?.cashPayout,
      []
    );
    const transfer_processors: MerchantProcessors[] = defaultTo(
      props.processors?.transfer,
      []
    );
    const transfer_payout_processors: MerchantProcessors[] = defaultTo(
      props.processors?.transferPayout,
      []
    );
    const subscription_processors: MerchantProcessors[] = defaultTo(
      props.processors?.ach,
      []
    );

    if (card_processors.length > 0)
      rows.push({
        type: "Tarjeta",
        rows: take(card_processors, 5),
        defaultProcessor: props.defaultProcessors?.card,
      });

    if (transfer_processors.length > 0)
      rows.push({
        type: "Pay in Transferencias",
        rows: take(transfer_processors, 5),
        defaultProcessor: props.defaultProcessors?.transfer,
      });

    if (transfer_payout_processors.length > 0)
      rows.push({
        type: "Pay out Transferencias",
        rows: take(transfer_payout_processors, 5),
        defaultProcessor: get(
          processor,
          "alias",
          props.defaultProcessors?.transferPayout
        ),
      });

    if (cash_processors.length > 0)
      rows.push({
        type: "Pay in Efectivo",
        rows: take(cash_processors, 5),
        defaultProcessor: undefined,
      });

    if (cash_payout_processors.length > 0)
      rows.push({
        type: "Pay out Efectivo",
        rows: take(cash_payout_processors, 5),
        defaultProcessor: undefined,
      });

    if (subscription_processors.length > 0)
      rows.push({
        type: "Débito Recurrente",
        rows: take(subscription_processors, 5),
        defaultProcessor: props.defaultProcessors?.ach,
      });

    return rows;
  };
  const validateFailOverShow = () => {
    const card_processors: MerchantProcessors[] = defaultTo(
      props.processors?.card,
      []
    );
    if (card_processors.length <= 0) return false;
    return (
      card_processors.filter(
        (row: MerchantProcessors) =>
          row.processorName !== card_processors[0].processorName
      ).length > 0
    );
  };
  return (
    <TableContainer className={classes.tableContainer}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {getTableHeaders().map((header: string) => (
              <>
                {header !== "Failover" ? (
                  <TableCell
                    key={header}
                    align="left"
                    className={classes.tableCell}
                  >
                    <strong>{header}</strong>
                  </TableCell>
                ) : validateFailOverShow() ? (
                  <TableCell
                    key={header}
                    align="left"
                    className={
                      header !== "Failover"
                        ? classes.tableCell
                        : `${classes.tableCell} ${classes.width200}`
                    }
                  >
                    <strong>{header}</strong>
                  </TableCell>
                ) : null}
              </>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {getTableRows().map(
            (pro: {
              rows: MerchantProcessors[];
              type: string;
              defaultProcessor?: string;
            }) => (
              <React.Fragment key={pro.type}>
                <TableRow style={{ backgroundColor: "rgb(239,246,254)" }}>
                  <TableCell className={classes.tableCell}>
                    {pro.type}
                  </TableCell>
                  {getTableHeaders().includes("Failover")
                    ? [1, 2, 3, 4].map((i) => (
                        <TableCell key={i} className={classes.tableCell} />
                      ))
                    : [1, 2, 3].map((i) => (
                        <TableCell key={i} className={classes.tableCell} />
                      ))}
                </TableRow>
                {pro.rows.map((processor: MerchantProcessors) => (
                  <TableRow key={processor.publicProcessorId}>
                    <TableCell
                      align="left"
                      component="th"
                      scope="row"
                      className={classes.tableCell}
                    >
                      {processor.publicProcessorId}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {processor.alias}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {props.country === CountryEnum.BRAZIL
                        ? translateProcessor(
                            get(processor, "processorName", "")
                          )
                        : get(processor, "processorName", "")
                            .replace(/Processor/g, "")
                            .trim()}{" "}
                      {pro.defaultProcessor === processor.alias && (
                        <Chip
                          size="small"
                          label="Por defecto"
                          style={{
                            backgroundColor: `${theme.palette.secondary.light}`,
                            fontSize: "12px",
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {capitalize(
                        translateRuleKeys(
                          defaultTo(processor.processorType, ""),
                          PROCESSOR_MODEL_TYPES
                        )
                      )}
                    </TableCell>
                    {pro.type === "Tarjeta" &&
                      pro.rows.length > 1 &&
                      validateFailOverShow() && (
                        <TableCell align="left" style={{ paddingTop: "8px" }}>
                          {!props.loadingFailover ? (
                            <KshSelectInput
                              id="failoverAlias"
                              disableValue="Selecciona..."
                              styleSelect={{}}
                              value={defaultTo(processor.failOverProcessor, "")}
                              size="small"
                              options={pro.rows
                                .filter(
                                  (row: MerchantProcessors) =>
                                    row.processorName !==
                                    processor.processorName
                                )
                                .map((row: MerchantProcessors) => ({
                                  key: row.alias,
                                  value: get(row, "processorName", "")
                                    .replace(/Processor/g, "")
                                    .trim(),
                                }))}
                              onChange={(e) =>
                                props.saveFailoverProcessor(
                                  props.merchantId,
                                  processor.publicProcessorId,
                                  {
                                    failoverAlias: e.target.value as string,
                                  }
                                )
                              }
                            />
                          ) : (
                            <Skeleton variant="rect" width="100%" height={30} />
                          )}
                        </TableCell>
                      )}
                  </TableRow>
                ))}
              </React.Fragment>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
