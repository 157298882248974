import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import {
  isLoadingCatalog,
  listMccCatalog,
  selectNodeInfo,
} from "../../../store/selectors/selectors";
import { IMerchantNodeInfoResponse } from "../../../../types/merchant_node_info_response";
import { ConfigurationIdEnum } from "../../../shared/enums";
import { CountriesEnum } from "../../../shared/infrastructure/countries-enum";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import axios from "../../../shared/axios-util";
import { CatalogsEnum } from "../../../shared/infrastructure/catalogs-enum";
import { getCatalog } from "../../../shared/constants/MerchantInformationConstants";
import {
  getSectionInfo,
  statusNodeInfo,
} from "../../../shared/utils/node_info";
import { EntityNameEnum } from "../../../shared/enums/entityName";
import { personTypeEnum } from "../../../shared/enums/personType";
import {
  COUNTRIES,
  DEFAULT_COUNTRY,
  EEUU_PSP,
} from "../../../shared/infrastructure/constants/BasicDataConstants";
import { defaultTo, get } from "lodash";
import {
  setMerchantInfoResponse,
  setStatusMerchant,
} from "../../../store/actions/actions";
import { ClientTypeEnum } from "../../../shared/enums/ClientTypeEnum";
import { BasicPropertiesValueEnum } from "../../../shared/enums/BasicPropertiesEnum";
import { europeCountriesPSPCatalog } from "../../../shared/countryEnum";
import { getListCatalogs } from "../../../store/thunks/catalogs/catalogs.thunk";
import { CountryCodeEnum } from "../../../shared/enums/CountryCodeEnum";
import { ProductLineEnum } from "../../../shared/enums/ProductLineEnum";
import { getCatalogByName } from "../../../shared/utils/listCatalogUtils";
import { TypeCatalogEnum } from "../../../shared/enums/TypeCatalog";
import { Data } from "../../../../types/catalog_response";
import { ColumnItems, Items, IuseBasicData } from "./useBasicData.interfaces";

const getMerchantType = (
  country: CountriesEnum,
  merchantType: string
): string => {
  let arr = getCatalog(country, CatalogsEnum.PersonTypes);
  let result = "";

  arr.forEach((personType) => {
    if (personType.value === merchantType) {
      result = personType.name;
    }
  });

  return result;
};

const getFormattedDate = (date: number): string =>
  format(new Date(date), "dd/MM/yyyy");

const getIdType = (country: string, value: string) => {
  let idTypeArr = getCatalog(country, CatalogsEnum.IdTypes);

  let idType: string = "";

  idTypeArr.forEach((idT) => {
    if (idT.value === value) {
      idType = idT.name;
    }
  });

  return idType;
};

export const onRenderValue = (
  column: ColumnItems,
  columnValue: string,
  country: CountriesEnum,
  object: object,
  personType: string | number | undefined
) => {
  let label: string = "";
  const renderValues = {
    [BasicPropertiesValueEnum.CONSTITUTION_DATE]: () => {
      return getFormattedDate(Number(columnValue));
    },
    [BasicPropertiesValueEnum.MERCHANT_TYPE]: () => {
      return getMerchantType(country, columnValue);
    },
    [BasicPropertiesValueEnum.TAX_ID]: () => {
      if (country === CountriesEnum.ESTADOS_UNIDOS) {
        if (personType === personTypeEnum.LEGAL) {
          return;
        }
      }

      return getIdType(country, columnValue);
    },
    [BasicPropertiesValueEnum.TAX_ID_NUMBER]: () => {
      if (country === CountriesEnum.ESTADOS_UNIDOS) {
        if (personType === personTypeEnum.LEGAL) {
          let documentType = get(object, "documentType");

          label = getIdType(country, documentType);
        }
      }

      return columnValue;
    },
    [BasicPropertiesValueEnum.TAX_ID_WITH_ID_NUMBER]: () => {
      let documentType = get(object, "documentType");

      label = getIdType(country, documentType);

      return get(object, BasicPropertiesValueEnum.TAX_ID_NUMBER);
    },
    [BasicPropertiesValueEnum.COMPANY_REGISTRATION_NUMBER]: () => {
      return get(object, BasicPropertiesValueEnum.COMPANY_REGISTRATION_NUMBER);
    },
    default: () => {
      return columnValue;
    },
  };
  let text = get(renderValues, column.key, renderValues.default)();

  return {
    label: label ? label : column.label,
    text: text,
  };
};

const getColumns = (
  object: object,
  country: CountriesEnum,
  columns: ColumnItems[],
  personType: string | number | undefined
): Items[] => {
  let allColumns: Items[] = [];

  columns.forEach((column: ColumnItems) => {
    const value = onRenderValue(
      column,
      get(object, column.key),
      country,
      object,
      personType
    );

    if (value.label && value.text) {
      allColumns.push({
        label: value.label,
        text: value.text,
      });
    }
  });

  return allColumns;
};

export const useBasicData = (): IuseBasicData => {
  const [isCompletedSection, setIsCompletedSection] = useState<boolean>(false);
  const [publicMerchantId, setPublicMerchantId] = useState<string | undefined>(
    ""
  );
  const [name, setName] = useState<string>("");
  const [mccDescription, setMccDescription] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [basicDataFields, setBasicDataFields] = useState<Array<Items>>([]);
  const [mccFields, setMccFields] = useState<Array<Items>>([]);
  const [clientType, setClientType] = useState<string>("");
  const [merchantNodeInfoResponse, setMerchantNodeInfoResponse] = useState<any>(
    {}
  );
  const mccCatalog = useSelector(listMccCatalog);
  const loadingCatalog = useSelector(isLoadingCatalog);
  const nodeInfo = useSelector(selectNodeInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (nodeInfo) {
      setClientType(get(nodeInfo, "generalInfo.clientType", ""));
      if (
        nodeInfo.entityName === EntityNameEnum.CUSTOMER ||
        nodeInfo.entityName === EntityNameEnum.BRANCH
      ) {
        let sectionInfo = getSectionInfo(
          ConfigurationIdEnum.CN001,
          nodeInfo.configs
        );

        if (sectionInfo) {
          if (sectionInfo.status === statusNodeInfo.COMPLETE) {
            setPublicMerchantId(sectionInfo.value);
            setIsCompletedSection(true);
          } else {
            setIsLoading(false);
            setIsCompletedSection(false);
          }
        } else {
          setIsLoading(false);
          setIsCompletedSection(false);
        }
      } else {
        setIsLoading(false);
        setIsCompletedSection(false);
      }
    }
  }, [nodeInfo]);

  useEffect(() => {
    const callApi = async () => {
      let result = await axios.post<IMerchantNodeInfoResponse>(
        API_ROUTES.BILLING_CORE_MERCHANT_NODE_INFO,
        {
          configId: `${ConfigurationIdEnum.CN001}`,
          publicMerchantId: publicMerchantId,
        }
      );

      setMerchantNodeInfoResponse(result.data);
      dispatch(setStatusMerchant(get(result.data, "status", "")));
      dispatch(setMerchantInfoResponse(result.data));
    };

    if (isCompletedSection) {
      callApi();
    }
  }, [isCompletedSection]);

  useEffect(() => {
    if (merchantNodeInfoResponse && isCompletedSection) {
      let dataResponse = merchantNodeInfoResponse;

      let personType = getCatalog(
        dataResponse.constitutionalCountry,
        CatalogsEnum.PersonTypes
      ).find(
        (personType) => personType.value === dataResponse.merchantType
      )?.value;

      setName(() => {
        if (personType === personTypeEnum.NATURAL) {
          if (dataResponse.constitutionalCountry === CountriesEnum.PANAMA) {
            return dataResponse.socialReason;
          }

          const { name, lastname } = dataResponse.naturalPerson;

          return name + " " + lastname;
        } else if (personType === personTypeEnum.LEGAL) {
          return dataResponse.socialReason;
        } else {
          return "";
        }
      });

      setMccDescription((): string => {
        const catalog: Data[] = getCatalogByName(
          mccCatalog,
          TypeCatalogEnum.MCC
        );
        let result = catalog.find((mcc: Data) => mcc.code === dataResponse.mcc);

        if (result) {
          return result.value.toUpperCase();
        } else {
          return "";
        }
      });
      let rows = getColumns(
        dataResponse,
        dataResponse.constitutionalCountry,
        (clientType === ClientTypeEnum.PSP &&
          dataResponse.constitutionalCountry ===
            CountriesEnum.ESTADOS_UNIDOS) ||
          europeCountriesPSPCatalog.includes(dataResponse.constitutionalCountry)
          ? EEUU_PSP
          : COUNTRIES[dataResponse.constitutionalCountry] || DEFAULT_COUNTRY,
        personType
      );

      setBasicDataFields(rows);

      setMccFields(() => {
        return [
          {
            label: "MCC",
            text: dataResponse.mcc,
          },
        ];
      });

      setIsLoading(false);
    }
  }, [merchantNodeInfoResponse]);

  useEffect(() => {
    if (defaultTo(mccCatalog, []).length === 0 && !loadingCatalog) {
      dispatch(
        getListCatalogs({
          configCode: ConfigurationIdEnum.CN001,
          countryCode: CountryCodeEnum.GLOBAL,
          productLine: ProductLineEnum.PLA,
          typeCatalog: TypeCatalogEnum.MCC,
        })
      );
    }
  });

  return {
    basicDataFields,
    isCompletedSection,
    isLoading: isLoading || loadingCatalog,
    mccDescription,
    mccFields,
    name,
  };
};
