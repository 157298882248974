import { format } from "date-fns";
import { format as formatTz, OptionsWithTZ, utcToZonedTime } from "date-fns-tz";
import { DateFormatEnum, parseMonthsToES } from "@shared/constants/parseMonths";
import { TimeZoneCatalog } from "@shared/catalogs/timeZoneList";
import { ITimeZoneCatalog } from "../../../types/time_zone_catalog";
import { get } from "lodash";

const _timeZoneDefault: string = "America/Guayaquil";

export const formatValidDate = (
  timestamp: number,
  dateFormat: DateFormatEnum,
  separator: string
): string => {
  const dateSplited: string[] = format(new Date(timestamp), dateFormat).split(
    separator
  );

  dateSplited[1] = parseMonthsToES[dateSplited[1]].toLowerCase();

  return dateSplited.join(separator);
};

function formatTimestampWithTimezone(
  timestamp: number,
  dateFormat: string,
  options?: OptionsWithTZ
): string {
  const date: Date = new Date(timestamp);

  if (get(options, "timeZone")) {
    const zonedDate: Date = utcToZonedTime(date, get(options, "timeZone")!);

    return formatTz(zonedDate, dateFormat, options);
  }

  return formatTz(date, dateFormat, options);
}

function timeZoneHour(text: string | undefined, value: string): string {
  let index: number;

  if (text === undefined) return value === "utc" ? "-05:00" : _timeZoneDefault;

  index = TimeZoneCatalog.findIndex(
    (data: ITimeZoneCatalog) => data.description === text
  );

  if (index >= 0) {
    const lang: string | undefined = get(
      TimeZoneCatalog[index],
      value,
      undefined
    );

    return lang !== undefined
      ? lang
      : value === "utc"
      ? "-05:00"
      : _timeZoneDefault;
  }

  return value === "utc" ? "-05:00" : _timeZoneDefault;
}

export { formatTimestampWithTimezone, timeZoneHour };
