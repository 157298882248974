import React from "react";
import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
export const BasicInfoSkeleton: React.FC = () => {
  return (
    <React.Fragment>
      <Grid container xs={12} spacing={3}>
        <Grid item xs={12}>
          <Skeleton
            animation="wave"
            variant="rect"
            width={"100%"}
            height={48}
          />
        </Grid>
        <Grid item container xs={6}>
          <Grid
            container
            spacing={2}
            justify={"flex-start"}
            alignItems={"baseline"}
          >
            <Grid item xs={6}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={"100%"}
                height={48}
              />
            </Grid>
            <Grid item xs={6}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={"100%"}
                height={48}
              />
            </Grid>
            <Grid item xs={6}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={"100%"}
                height={48}
              />
            </Grid>
            <Grid item xs={6}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={"100%"}
                height={48}
              />
            </Grid>
            <Grid item xs={6}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={"100%"}
                height={48}
              />
            </Grid>
            <Grid item xs={6}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={"100%"}
                height={48}
              />
            </Grid>
            <Grid item xs={6}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={"100%"}
                height={48}
              />
            </Grid>
            <Grid item xs={6}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={"100%"}
                height={48}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
