import ApprovedTransactionIcon from "../../assets/icons/trasanctionsStates/ApprovedTransactionIcon";
import FailedTransactionIcon from "../../assets/icons/trasanctionsStates/FailedTransactionIcon";
import GeneratPenIcon from "../../assets/icons/trasanctionsStates/GeneratPenIcon";
import RetriedTransactionIcon from "../../assets/icons/trasanctionsStates/RetriedTransactionIcon";
import TransactionWaitingIcon from "../../assets/icons/trasanctionsStates/TransactionWaitingIcon";
import {
  HistoricalItemEventTypeEnum,
  HistoricalItemStatus,
} from "../../store/interfaces/TransactionsState.interfaces";

export const transactionIconMap = {
  [`${HistoricalItemEventTypeEnum.TRANSACTION}_${HistoricalItemStatus.APPROVED}`]:
    ApprovedTransactionIcon,
  [`${HistoricalItemEventTypeEnum.TRANSACTION}_${HistoricalItemStatus.APPROVAL}`]:
    ApprovedTransactionIcon,
  [`${HistoricalItemEventTypeEnum.TRANSACTION}_${HistoricalItemStatus.DECLINED}`]:
    FailedTransactionIcon,
  [`${HistoricalItemEventTypeEnum.TRANSACTION}_${HistoricalItemStatus.INITIALIZED}`]:
    TransactionWaitingIcon,
  [`${HistoricalItemEventTypeEnum.TRANSACTION}_${HistoricalItemStatus.VOID}`]:
    ApprovedTransactionIcon,
  [`${HistoricalItemEventTypeEnum.TRANSACTION}_${HistoricalItemStatus.REFUND}`]:
    TransactionWaitingIcon,
  [`${HistoricalItemEventTypeEnum.TRANSACTION}_${HistoricalItemStatus.GENERATED}`]:
    TransactionWaitingIcon,
  [`${HistoricalItemEventTypeEnum.FILE}_${HistoricalItemStatus.GENERATED}`]:
    GeneratPenIcon,
  [`${HistoricalItemEventTypeEnum.FILE}_${HistoricalItemStatus.FAILED}`]:
    FailedTransactionIcon,
  [`${HistoricalItemEventTypeEnum.FILE}_${HistoricalItemStatus.COMPLETED}`]:
    ApprovedTransactionIcon,
  [`${HistoricalItemEventTypeEnum.FILE}_${HistoricalItemStatus.REQUESTED}`]:
    GeneratPenIcon,
  [`${HistoricalItemEventTypeEnum.REFUND}_${HistoricalItemStatus.GENERATED}`]:
    TransactionWaitingIcon,
  [`${HistoricalItemEventTypeEnum.REFUND}_${HistoricalItemStatus.INITIALIZED}`]:
    TransactionWaitingIcon,
  [`${HistoricalItemEventTypeEnum.REFUND}_${HistoricalItemStatus.APPROVED}`]:
    ApprovedTransactionIcon,
  [`${HistoricalItemEventTypeEnum.REFUND}_${HistoricalItemStatus.APPROVAL}`]:
    ApprovedTransactionIcon,
  [`${HistoricalItemEventTypeEnum.REFUND}_${HistoricalItemStatus.RETRIED}`]:
    RetriedTransactionIcon,
  [`${HistoricalItemEventTypeEnum.REFUND}_${HistoricalItemStatus.REFUND}`]:
    TransactionWaitingIcon,
};
