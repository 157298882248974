import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Theme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DeferredDialogTitle } from "./DeferredDialogTitle";
import { DeferredDialogList } from "./DeferredDialogList";
import { get } from "lodash";

export interface DeferredDialogProps {
  open: boolean;
  title: string;
  buttonText: string;
  buttonAction: string;
  buttonColor: "error" | "default" | "primary";
  onClose: (value: string) => void;
  isModalVariant?: boolean;
  indexVariant: number;
  subBodyText?: string;
  listBanksOrMonths?: string[];
}

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    textTransform: "none",
  },
  buttonDelete: {
    color: theme.palette.error.main,
  },
  buttonPrincipal: {
    color: (props: DeferredDialogProps) => {
      switch (props.buttonColor) {
        case "error":
          return theme.palette.error.main;
        case "primary":
          return theme.palette.primary.main;
        default:
          return theme.palette.text.primary;
      }
    },
  },
  dialog: {
    width: 535,
  },
}));

export const DeferredDialog: React.FC<DeferredDialogProps> = (
  props: DeferredDialogProps
) => {
  const classes = useStyles(props);

  const handleClose = (value: string) => {
    props.onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={props.open}>
      <DeferredDialogTitle id="simple-dialog-title" onClose={handleClose}>
        {props.title}
      </DeferredDialogTitle>
      <DialogContent className={classes.dialog}>
        <DeferredDialogList
          listBanksOrMonths={get(props, "listBanksOrMonths", [])}
        />
        <DialogContentText>{props.subBodyText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          onClick={() => handleClose(props.buttonAction)}
        >
          {props.buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
