import {
  LayoutLocalState,
  LayoutState,
} from "../../interfaces/layout/layout.interfaces";
import { createSlice } from "@reduxjs/toolkit";
import {
  createProcessor,
  getAllProcessorInfo,
  getProcessorDefaults,
  getProcessorInfo,
  updateDefaultProcessor,
  updateProcessor,
} from "../../thunks/layout/layout.thunks";
import { MerchantBasicInfo } from "../../../../types/merchant_basic_info";
import { defaultTo } from "lodash";

export const initialState: LayoutState = {
  isLoading: true,
  isOpenExitModal: false,
};

export const layoutSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(
        createProcessor.fulfilled,
        (state: LayoutLocalState, { payload }) => {
          state.successSubmit = payload;
        }
      )
      .addCase(
        updateProcessor.fulfilled,
        (state: LayoutLocalState, { payload }) => {
          state.successSubmit = payload;
        }
      )
      .addCase(
        getProcessorDefaults.fulfilled,
        (state: LayoutLocalState, { payload }) => {
          state.configProcessor = payload;
        }
      )
      .addCase(
        getAllProcessorInfo.fulfilled,
        (state: LayoutLocalState, { payload }) => {
          state.processorMerchantValidator = payload;
          state.isLoading = false;
        }
      )
      .addCase(
        updateDefaultProcessor.fulfilled,
        (state: LayoutLocalState, { payload }) => {
          state.isDefaultSubmit = payload;
        }
      )
      .addCase(
        getProcessorInfo.fulfilled,
        (state: LayoutLocalState, { payload }) => {
          state.modeEdit = true;
          state.processorInfo = payload;
        }
      );
  },
  initialState,
  name: "layout",
  reducers: {
    closeModal: (state) => {
      state.successSubmit = false;
    },
    getMerchantBasicInfo: (state) => {
      const merchant: MerchantBasicInfo = JSON.parse(
        defaultTo(localStorage.getItem("merchantBasicInformation"), "{}")
      ) as MerchantBasicInfo;

      const url: string | undefined = localStorage.getItem(
        "internationalRedirectUrl"
      )!;

      state.onBackUrl = url;

      state.merchantBasicInfo = merchant;
    },
    openModal: (state) => {
      state.isOpenExitModal = true;
    },
  },
});

export default layoutSlice.reducer;
