import React, { useEffect, useState } from "react";
import { FileInformation } from "../../../../types/file_information";
import { defaultTo, get, last, split, toString } from "lodash";
import { IUseUploadFileDispersionState } from "../../../shared/infrastructure/interfaces/useUploadFileDispersionState.interface";
import { FileTypeEnum } from "../../../shared/infrastructure/constants/FileTypeEnum";
import { validateFiles } from "../../../store/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import { ValidateFilesRequest } from "../../../../types/validate_files_request";
import { ValidateFilesResponse } from "../../../../types/validate_files_response";
import { IAppState } from "../../../store/reducer";

export const useUploadFileDispersionState =
  (): IUseUploadFileDispersionState => {
    const dispatch = useDispatch();
    let validateFileResponse: ValidateFilesResponse | undefined = useSelector(
      (state: IAppState) => state.validateFilesResponse
    );

    let showValidationAlert: boolean | undefined = useSelector(
      (state: IAppState) => state.showValidationErrorAlert
    );
    const [isChooseInputFile, setIsChooseInputFile] = useState<boolean>(false);
    const [isChooseUafFile, setIsChooseUafFile] = useState<boolean>(false);
    const [isLoadInputFileSuccessful, setIsLoadInputFileSuccessful] =
      useState<boolean>(false);
    const [isLoadUafFileSuccessful, setIsLoadUafFileSuccessful] =
      useState<boolean>(false);
    const [isProcessButtonEnabled, setIsProcessButtonEnabled] =
      useState<boolean>(false);
    const [isSuccessInputFileSize, setIsSuccessInputFileSize] =
      useState<boolean>(false);
    const [isSuccessInputFormatFile, setIsSuccessInputFormatFile] =
      useState<boolean>(false);
    const [isSuccessUafFileSize, setIsSuccessUafFileSize] =
      useState<boolean>(false);
    const [isSuccessUafFormatFile, setIsSuccessUafFormatFile] =
      useState<boolean>(false);
    const [inputFileName, setInputFileName] = useState<string>("");
    const [uafFileName, setUafFileName] = useState<string>("");
    const [inputFileSize, setInputFileSize] = useState<number>(0);
    const [uafFileSize, setUafFileSize] = useState<number>(0);
    const [inputFileBase64, setInputFileBase64] = useState<
      string | ArrayBuffer | null
    >(null);
    const [uafFileBase64, setUafFileBase64] = useState<
      string | ArrayBuffer | null
    >(null);
    const [openLoadingModal, setOpenLoadingModal] = useState<boolean>(false);

    const handleOnChangeInputFile = (
      event: React.ChangeEvent<HTMLInputElement>
    ): void => {
      const fileInformation: FileInformation = get(event, "target.files[0]");

      if (fileInformation) {
        setIsChooseInputFile(true);
        setInputFileName(retrieveFileName(fileInformation));
        setInputFileSize(retrieveFileSize(fileInformation));

        setIsLoadInputFileSuccessful(
          isFileSizeSuccessful(fileInformation) &&
            isFormatFileSuccessFul(fileInformation)
        );

        setIsSuccessInputFileSize(isFileSizeSuccessful(fileInformation));
        setIsSuccessInputFormatFile(isFormatFileSuccessFul(fileInformation));

        prepareFileToSend(event, FileTypeEnum.INPUT);
      }
    };

    const handleOnChangeUafFile = (
      event: React.ChangeEvent<HTMLInputElement>
    ): void => {
      const fileInformation: FileInformation = get(event, "target.files[0]");

      if (fileInformation) {
        setIsChooseUafFile(true);
        setUafFileName(retrieveFileName(fileInformation));
        setUafFileSize(retrieveFileSize(fileInformation));

        setIsLoadUafFileSuccessful(
          isFileSizeSuccessful(fileInformation) &&
            isFormatFileSuccessFul(fileInformation)
        );

        setIsSuccessUafFileSize(isFileSizeSuccessful(fileInformation));
        setIsSuccessUafFormatFile(isFormatFileSuccessFul(fileInformation));

        prepareFileToSend(event, FileTypeEnum.UAF);
      }
    };

    const retrieveFileName = (fileInformation: FileInformation): string => {
      return fileInformation.name;
    };

    const retrieveFileSize = (fileInformation: FileInformation): number => {
      return Math.round(fileInformation.size / 1000000);
    };

    const isFileSizeSuccessful = (
      fileInformation: FileInformation
    ): boolean => {
      const fileSizeInMb: number = fileInformation.size / 1000000;

      return fileSizeInMb <= 2;
    };

    const isFormatFileSuccessFul = (
      fileInformation: FileInformation
    ): boolean => {
      return (
        fileInformation.type === FileTypeEnum.XLSX ||
        fileInformation.type === FileTypeEnum.XLS
      );
    };

    useEffect(() => {
      setIsProcessButtonEnabled(
        isLoadInputFileSuccessful && isLoadUafFileSuccessful
      );
    }, [isLoadInputFileSuccessful, isLoadUafFileSuccessful]);

    const prepareFileToSend = (event: any, type: string): void => {
      const reader: FileReader = new FileReader();

      reader.readAsDataURL(get(event, "target.files[0]"));
      reader.onload = (): void => {
        const fileValueToSend: string | ArrayBuffer | null = defaultTo(
          last(split(String(reader.result), ",")),
          ""
        );

        if (type === FileTypeEnum.INPUT) {
          setInputFileBase64(fileValueToSend);
        } else {
          setUafFileBase64(fileValueToSend);
        }
      };
    };

    const handleProcessFile = (): void => {
      setOpenLoadingModal(true);
      const merchantId: string = defaultTo(
        window.localStorage.getItem("merchantId"),
        ""
      );
      const email: string = defaultTo(window.localStorage.getItem("email"), "");
      const validationRequest: ValidateFilesRequest = {
        files: [
          {
            content: toString(inputFileBase64),
            filename: FileTypeEnum.INPUT_FILE_NAME,
            size: inputFileSize,
          },
          {
            content: toString(uafFileBase64),
            filename: FileTypeEnum.UAF_FILE_NAME,
            size: uafFileSize,
          },
        ],
        merchantId,
        email,
      };
      dispatch(validateFiles(validationRequest));
    };

    useEffect(() => {
      if (validateFileResponse || showValidationAlert) {
        setOpenLoadingModal(false);
      }
    }, [validateFileResponse, showValidationAlert]);

    return {
      handleOnChangeInputFile,
      handleOnChangeUafFile,
      handleProcessFile,
      isChooseInputFile,
      isChooseUafFile,
      isLoadInputFileSuccessful,
      isLoadUafFileSuccessful,
      inputFileName,
      uafFileName,
      isProcessButtonEnabled,
      openLoadingModal,
      validationResults: validateFileResponse,
      isSuccessInputFileSize,
      isSuccessInputFormatFile,
      isSuccessUafFileSize,
      isSuccessUafFormatFile,
    };
  };
