export enum routes {
  GLOBAL_CONFIG = "/global-config",
  WITH_CSV = "/file-form",
  WITH_FORM = "/form",
  EDIT_FORM = "/edit-form",
  BASE_PATH_RETENTIONS = "/retentions",
  INDEX = "/",
  DASHBOARD = "/dashboard",
  CATALOG_FILE = "/catalog-file",
}
