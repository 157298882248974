import { Category } from "../constants/information_constants";
import { CountryEnum } from "../countryEnum";
import { get } from "lodash";
import { CatalogPe } from "./catalog-pe";
import { CatalogsEnum } from "../interfaces/catalogs-enum";
import { CatalogBr } from "./catalog-br";
import { CatalogCl } from "./catalog-cl";
import { CatalogCo } from "./catalog-co";
import { CatalogCr } from "./catalog-cr";
import { CatalogEc } from "./catalog-ec";
import { CatalogEl } from "./catalog-el";
import { CatalogGt } from "./catalog-gt";
import { CatalogHn } from "./catalog-hn";
import { CatalogMx } from "./catalog-mx";
import { CatalogNi } from "./catalog-ni";
import { CatalogPan } from "./catalog-pan";
import { documentType } from "../constants/initial_state_legal_details";
import { CatalogEeuu } from "./catalog-eeuu";

const DEFAULT_DOCUMENT_TYPE: Category[] = [
  {
    defaultShareholderDocType: "0",
    name: "Documento de Identidad",
    value: "0",
  },
  { defaultShareholderDocType: "1", name: "Pasaporte", value: "1" },
];

export const DOCUMENT_TYPE_BY_COUNTRY_VALUES = {
  [CountryEnum.brasil]: CatalogBr[CatalogsEnum.IdTypes],
  [CountryEnum.chile]: CatalogCl[CatalogsEnum.IdTypes],
  [CountryEnum.colombia]: CatalogCo[CatalogsEnum.IdTypes],
  [CountryEnum.costa_rica]: CatalogCr[CatalogsEnum.IdTypes],
  [CountryEnum.ecuador]: CatalogEc[CatalogsEnum.IdTypes],
  [CountryEnum.usa]: CatalogEeuu[CatalogsEnum.IdTypes],
  [CountryEnum.el_salvador]: CatalogEl[CatalogsEnum.IdTypes],
  [CountryEnum.guatemala]: CatalogGt[CatalogsEnum.IdTypes],
  [CountryEnum.honduras]: CatalogHn[CatalogsEnum.IdTypes],
  [CountryEnum.mexico]: CatalogMx[CatalogsEnum.IdTypes],
  [CountryEnum.nicaragua]: CatalogNi[CatalogsEnum.IdTypes],
  [CountryEnum.panama]: CatalogPan[CatalogsEnum.IdTypes],
  [CountryEnum.peru]: CatalogPe[CatalogsEnum.IdTypes],
  [CountryEnum.generic]: documentType,
};

export const isAvailableCountry = (country: string) =>
  Object.keys(DOCUMENT_TYPE_BY_COUNTRY_VALUES).some(
    (catalogCountry) => catalogCountry === country
  );

export const getCatalogDocumentTypeByCountry = (
  country: string
): Category[] => {
  if (DOCUMENT_TYPE_BY_COUNTRY_VALUES[country]) {
    return DOCUMENT_TYPE_BY_COUNTRY_VALUES[country];
  }

  return DEFAULT_DOCUMENT_TYPE;
};

export const translate_document_type = (
  country: string,
  type: string
): string => {
  const catalog: Category[] = getCatalogDocumentTypeByCountry(country);
  const index: number = catalog.findIndex(
    (data: Category) => data.value === type
  );

  return get(catalog, `[${index}].name`, "");
};
