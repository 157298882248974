import {
  getBranchListFromSessionStorage,
  setItemInSessionStorage,
} from "../session_storage_utils";
import { flatten, get, isEmpty, maxBy } from "lodash";
import {
  Configs,
  MerchantNodeData,
} from "../../../../types/search_merchant_response";
import { IMostUpdateState } from "../../interfaces/MassiveUtilsInterfaces";
import { editStatusPath } from "../../constants/edit_container_paths";
import { BranchCreation } from "../../../components/BranchManualCreationForm/state/useBranchManualCreation.interfaces";
import {
  IBranchNameCatalog,
  IMassiveBranchResponse,
  IProcessedBranches,
} from "../../../store/interfaces/massiveBranch/massiveBranch.interfaces";

export const processCreatedBranches = (
  massiveResponse: IMassiveBranchResponse[]
): IProcessedBranches => {
  const catalog: IBranchNameCatalog = {};
  const errorBranches: IMassiveBranchResponse[] = [];

  massiveResponse.forEach((branch: IMassiveBranchResponse) => {
    if (isEmpty(catalog[branch.branchName]) && !branch.error) {
      catalog[branch.branchName] = branch;
    }
    if (branch.error) {
      errorBranches.push(branch);
    }
  });

  return { catalog, errorBranches };
};

export const addMerchantIdToEditList = (
  branchNameCatalog: IBranchNameCatalog
) => {
  const newBranchList: any[] = [];

  getBranchListFromSessionStorage("branchList").forEach((branchItem: any) => {
    if (!isEmpty(branchNameCatalog[branchItem.branchName]))
      newBranchList.push({
        ...branchItem,
        publicMerchantId:
          branchNameCatalog[branchItem.branchName].publicMerchantId,
      });
  });

  setItemInSessionStorage("branchList", newBranchList);

  return newBranchList;
};

const getMostUpdatedStatusInConfigs = (configs: Configs[]) => {
  return get(maxBy(flatten(configs), "updatedAt"), "status");
};

const getMostUpdateState = (
  searchResponse: MerchantNodeData[]
): IMostUpdateState[] =>
  searchResponse.map((sr) => {
    return {
      merchantId: get(sr, "merchant_id", ""),
      status: getMostUpdatedStatusInConfigs(get(sr, "configs", [])),
    };
  });

const getStatusByMerchantId = (merchantId: string, resp: IMostUpdateState[]) =>
  get(
    resp.find((rs) => rs.merchantId === merchantId),
    "status"
  );

export const getBranchEditListWithUpdatedStatus = (
  searchResponse: MerchantNodeData[]
) => {
  const resp = getMostUpdateState(searchResponse);

  return searchResponse.map((sr) => {
    return {
      ...sr,
      editStatus: get(sr, "isModify", false)
        ? getStatusByMerchantId(sr.merchant_id!, resp)
        : get(sr, editStatusPath),
    };
  });
};

export const sliceBranchesInBatch = (
  branches: BranchCreation[],
  batchSize: number
): BranchCreation[][] => {
  const response: BranchCreation[][] = [];

  for (let i = 0; i < branches.length; i += batchSize) {
    const branchesSliced: BranchCreation[] = branches.slice(i, i + batchSize);

    response.push(branchesSliced);
  }

  return response;
};

export const awaitTimeout = (delay: number) =>
  new Promise((resolve) => setTimeout(resolve, delay));
