export enum ActionEnum {
  DELETE = "remove",
  NEW = "add",
  UPDATE = "update",
}

export const ButtonNameCatalogConfirm: Record<string, string> = {
  [ActionEnum.DELETE]: "Eliminar",
  [ActionEnum.NEW]: "Agregar",
  [ActionEnum.UPDATE]: "Guardar",
};
