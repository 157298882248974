import { ICellTextCopyComponent } from "./CellTextCopy.interfaces";
import { FC } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { clipboard } from "../../../shared/utils/tableUtils";
import { defaultTo } from "lodash";
import { IconCopy } from "@kushki/connect-ui";

export const CellTextCopy: FC<ICellTextCopyComponent> = ({
  title,
  textToCopy,
  align,
}: ICellTextCopyComponent) => {
  return (
    <Box
      sx={{ alignItems: "center", display: "flex" }}
      justifyContent={defaultTo(align, "center")}
    >
      <Tooltip title={`${textToCopy}`} placement="top">
        <Typography>{title}</Typography>
      </Tooltip>

      <IconButton
        onClick={(e) => {
          clipboard(textToCopy);
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <IconCopy />
      </IconButton>
    </Box>
  );
};
