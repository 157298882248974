import React, { useEffect } from "react";
import { InvoiceRecord } from "../../../types/remote/response_search_blling_by_filters";
import {
  createStyles,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { get } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns-tz";
import NumberFormat from "react-number-format";
import { StatusFlowEnum } from "../../shared/infrastructure/StatusFlowEnum";
import {
  renderMerchantInfoPaper,
  renderRetentionInfoPaper,
} from "./ModalBodyRetention";
import { IKshTab, KshTab } from "../Common/KshTab/KshTab";
import TimeLineTabComponent from "../TabComponent/TimeLineTabComponent/TimeLineTabComponent";
import { translate } from "../../shared/infrastructure/LanguageCatatog";
import { TransactionTable } from "../../../types/transaction_table";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import {
  RetentionContentEnum,
  RetentionType,
} from "../../shared/infrastructure/constants/RetentionConstants";
import { retentionText } from "./ModalBodyRetentionColombia";
import { ButtonDownloadFile } from "../ButtonDownloadFile/ButtonDownloadFile";
import { RetentionEnum } from "../../shared/infrastructure/RetentionEnum";
import { KindRetentionEnum } from "../../shared/infrastructure/KindRetentionEnum";
import { PdfViewer } from "../Common/PdfViewer/PdfViewer";
import { StatusEnum } from "../../shared/infrastructure/StatusEnum";

export interface InfoTrxProps {
  trxInfo: InvoiceRecord | TransactionTable | undefined;
  statusFlow?: StatusFlowEnum;
  isHistoric?: boolean;
  handleOpenDialogDetail?: (data: InvoiceRecord) => void;
  handleCloseDialogDetail?: () => void;
  handleChangeTab?: (newValue: number) => void;
  handleModalModifyInconsistence?: (value: boolean) => void;
  openModifyInconsistence?: boolean;
  contentFooter?: string;
  isRetColombia?: boolean;
  pdfViewer?: {
    fileName: string;
    openModal: boolean;
    base64File: string;
    handleCloseModal: () => void;
    handleOpenModal: () => void;
    handlePdfViewer: (
      transaction_id: string,
      kindRentenion: KindRetentionEnum
    ) => void;
  };
  downloadFileState?: {
    isLoading: boolean;
    downloadFile: (format: string) => void;
  };
  isModify?: boolean;
  isRetention?: boolean;
}
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: "#ccc",
      zIndex: 1,
      color: "#fff",
      width: 32,
      height: 32,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
    },
    divider: {
      width: "100%",
      marginTop: 5,
      marginBottom: 3,
    },
    paper: {
      boxShadow: "none !important",
      borderRadius: "5px !important",
      backgroundColor: "#F7FAFC !important",
      marginBottom: "20px !important",
      marginTop: "30px",
    },
    active: {
      backgroundColor: "#0DC298",
    },
    completed: {
      backgroundColor: "#0DC298",
    },
    linearProgress: {
      marginTop: 3,
    },
    grid: {
      paddingTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
    },
    margin: {
      marginTop: 10,
    },
    paddingTop: {
      paddingTop: "2px !important",
    },
    paddingBottom: {
      paddingBottom: "2px !important",
    },
    footer: {
      fontSize: "13px !important",
    },
  })
);

const hideViewDownload = (trxInfo: InvoiceRecord | undefined): boolean => {
  const amountRetIva = get(trxInfo, "ret_iva", 0);
  const amountRetSource = get(trxInfo, "ret_fue", 0);
  const amountRetIca = get(trxInfo, "ret_ica", 0);
  const processorType = get(trxInfo, "processor_type", "");
  const status = get(trxInfo, "status", "");
  return (
    amountRetIca <= 0 ||
    amountRetSource <= 0 ||
    amountRetIva <= 0 ||
    processorType !== "5" ||
    status !== StatusEnum.COMPLETED
  );
};

const formatDate = (trxDate: any, formatDate: string): string => {
  let finalDate: string = "";
  try {
    let date: string;
    date = new Date(
      trxDate.toString().length === 10 ? trxDate * 1000 : trxDate
    ).toISOString();

    finalDate = format(new Date(date.replace("Z", "")), formatDate, {
      timeZone: "UTC",
    });
  } catch (e) {
    finalDate = "";
  }
  return finalDate;
};

const formatNumber = (value: number, currency: string) => {
  return (
    <NumberFormat
      value={value}
      thousandSeparator
      displayType={"text"}
      decimalScale={2}
      suffix={` ${currency}`}
    />
  );
};

export const renderTrxInfoPaperHistoric = (props: InfoTrxProps) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2} color="secondary" className={classes.grid}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2" id={"kindData"}>
                Tipo de Documento:
              </Typography>
              <Typography variant="body2">
                {translate(get(props.trxInfo, "kind", ""))}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Ciclo:</Typography>
              <Typography variant="body2">
                {`${translate(get(props.trxInfo, "cycle"))} ${formatDate(
                  get(props.trxInfo, "start_date"),
                  "dd-MM-yyyy"
                )} - ${formatDate(
                  get(props.trxInfo, "end_date"),
                  "dd-MM-yyyy"
                )}`}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Monto de Retención IVA:</Typography>
              <Typography variant="body2">
                {formatNumber(
                  get(props.trxInfo, "ret_iva", 0),
                  get(props.trxInfo, "currency_code", "USD")
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                Monto de Retención Fuente:
              </Typography>
              <Typography variant="body2">
                {formatNumber(
                  get(props.trxInfo, "ret_fue", 0),
                  get(props.trxInfo, "currency_code", "USD")
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">Monto Total:</Typography>
              <Typography variant="body2">
                {formatNumber(
                  get(props.trxInfo, "ret_total_amount", 0),
                  get(props.trxInfo, "currency_code", "USD")
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                Fecha de emisión del comprobante:
              </Typography>
              <Typography variant="body2">
                {get(props.trxInfo, "created")
                  ? formatDate(get(props.trxInfo, "created"), "dd-MM-yyyy")
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">Número de Documento:</Typography>
              <Typography variant="body2">
                {get(props.trxInfo, "retention_number", "-")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">Error:</Typography>
              <Typography variant="body2">
                {get(props.trxInfo, "datil_error", "-")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">Motivo de rechazo:</Typography>
              <Typography variant="body2">
                {get(props.trxInfo, "reject.reason", "-")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const renderGrid = (
  title: string,
  value: string | number,
  sizeGrid: 3 | 4 | 5 | 6 | 8 | 12,
  sizeTitle: 3 | 4 | 6 | 8 | 12,
  sizeValue: 3 | 4 | 6 | 8 | 12,
  customClass?: string
) => {
  return (
    <Grid item container xs={sizeGrid} className={customClass}>
      <Grid item xs={sizeTitle}>
        <Typography variant="body2" color="textSecondary" id={"kindData"}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={sizeValue}>
        <Typography variant="body2">{value}</Typography>
      </Grid>
    </Grid>
  );
};

export const renderTrxInfoPaperHistoricCO = (props: InfoTrxProps) => {
  const classes = useStyles();
  const formattedCycle: string = `
   ${formatDate(get(props, "trxInfo.start_date"), "dd-MM-yyyy")} - ${formatDate(
    get(props, "trxInfo.end_date"),
    "dd-MM-yyyy"
  )}`;
  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2} color="secondary" className={classes.grid}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {renderGrid(
              "Nombre comercial:",
              get(props, "trxInfo.merchant_name", ""),
              12,
              4,
              8
            )}
            {renderGrid(
              "Ciclo:",
              translate(get(props, "trxInfo.cycle", "")),
              12,
              4,
              8,
              classes.paddingBottom
            )}
            {renderGrid("", formattedCycle, 12, 4, 8, classes.paddingTop)}
            {renderGrid(
              "Municipio",
              retentionText(
                RetentionType.city,
                get(props, "trxInfo.city_id", "-")
              ),
              12,
              4,
              8
            )}
            {renderGrid(
              "Modelo",
              retentionText(
                RetentionType.processorType,
                get(props, "trxInfo.processor_type", "-")
              ),
              12,
              4,
              8
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const renderRetentionInfoPaperCol = (props: InfoTrxProps) => {
  const classes = useStyles();
  const showDownload: boolean = hideViewDownload(props.trxInfo);
  return (
    <Paper className={classes.paper}>
      <Grid container spacing={1} color="secondary" className={classes.grid}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography variant="body2">Tipo de retención</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">Cálculo Davivienda</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">Cálculo Kushki</Typography>
            </Grid>
            <Divider className={classes.divider} />
            <Grid item xs={4}>
              <Typography variant="body2">Retención IVA</Typography>
              <Typography variant="body2" color="textSecondary">
                Base gravable
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                {formatNumber(
                  get(props.trxInfo, "ret_iva_davi", 0),
                  get(props.trxInfo, "currency_code")
                )}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {formatNumber(
                  get(props.trxInfo, "base_ret_iva", 0),
                  get(props.trxInfo, "currency_code")
                )}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">
                {formatNumber(
                  get(props.trxInfo, "ret_iva", 0),
                  get(props.trxInfo, "currency_code")
                )}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {formatNumber(
                  get(props.trxInfo, "base_ret_percent_iva", 0),
                  get(props.trxInfo, "currency_code")
                )}
              </Typography>
            </Grid>
            <Grid item xs={1} hidden={showDownload}>
              <ButtonDownloadFile
                buttonV2={false}
                small={true}
                options={[
                  {
                    text: "Visualizar documento",
                    value: `${RetentionEnum.VIEW},${get(
                      props.trxInfo,
                      "transaction_id",
                      ""
                    )},${KindRetentionEnum.IVA}`,
                  },
                  {
                    text: "Descargar documento",
                    value: `${RetentionEnum.DOWNLOAD},${get(
                      props.trxInfo,
                      "transaction_id",
                      ""
                    )},${KindRetentionEnum.IVA}`,
                  },
                ]}
                isLoading={get(props.downloadFileState, "isLoading", false)}
                downloadFile={
                  props.downloadFileState
                    ? props.downloadFileState!.downloadFile
                    : () => {}
                }
                stateApprove={true}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">Retención ICA</Typography>
              <Typography variant="body2">Retención Fuente</Typography>
              <Typography variant="body2" color="textSecondary">
                *Base gravable
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                {formatNumber(
                  get(props.trxInfo, "ret_ica_davi", 0),
                  get(props.trxInfo, "currency_code")
                )}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {formatNumber(
                  get(props.trxInfo, "ret_fue_davi", 0),
                  get(props.trxInfo, "currency_code")
                )}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {formatNumber(
                  get(props.trxInfo, "base_ret_fue", 0),
                  get(props.trxInfo, "currency_code")
                )}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">
                {formatNumber(
                  get(props.trxInfo, "ret_ica", 0),
                  get(props.trxInfo, "currency_code")
                )}
              </Typography>
              <Typography variant="body2">
                {formatNumber(
                  get(props.trxInfo, "ret_fue", 0),
                  get(props.trxInfo, "currency_code")
                )}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {formatNumber(
                  get(props.trxInfo, "base_ret_percent_fue", 0),
                  get(props.trxInfo, "currency_code")
                )}
              </Typography>
            </Grid>
            <Grid item xs={1} hidden={showDownload}>
              <ButtonDownloadFile
                small={true}
                buttonV2={false}
                options={[
                  {
                    text: "Visualizar documento",
                    value: `${RetentionEnum.VIEW},${get(
                      props.trxInfo,
                      "transaction_id",
                      ""
                    )},${KindRetentionEnum.ICA}`,
                  },
                  {
                    text: "Descargar documento",
                    value: `${RetentionEnum.DOWNLOAD},${get(
                      props.trxInfo,
                      "transaction_id",
                      ""
                    )},${KindRetentionEnum.ICA}`,
                  },
                ]}
                isLoading={get(props.downloadFileState, "isLoading", false)}
                downloadFile={
                  props.downloadFileState
                    ? props.downloadFileState!.downloadFile
                    : () => {}
                }
                stateApprove={true}
              />
              <ButtonDownloadFile
                small={true}
                buttonV2={false}
                options={[
                  {
                    text: "Visualizar documento",
                    value: `${RetentionEnum.VIEW},${get(
                      props.trxInfo,
                      "transaction_id",
                      ""
                    )},${KindRetentionEnum.SOURCE}`,
                  },
                  {
                    text: "Descargar documento",
                    value: `${RetentionEnum.DOWNLOAD},${get(
                      props.trxInfo,
                      "transaction_id",
                      ""
                    )},${KindRetentionEnum.SOURCE}`,
                  },
                ]}
                isLoading={get(props.downloadFileState, "isLoading", false)}
                downloadFile={
                  props.downloadFileState
                    ? props.downloadFileState!.downloadFile
                    : () => {}
                }
                stateApprove={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography variant="body2">Retención Total</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary">
                {formatNumber(
                  get(props.trxInfo, "ret_total_amount_davi", 0),
                  get(props.trxInfo, "currency_code")
                )}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">
                {formatNumber(
                  get(props.trxInfo, "ret_total_amount", 0),
                  get(props.trxInfo, "currency_code")
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const renderRetentionInfoPaperHistoric = (props: InfoTrxProps) => {
  const classes = useStyles();
  const footerStyle: object = {
    fontWeight: "500 !important",
  };
  return (
    <>
      {renderRetentionInfoPaperCol(props)}

      <Grid item xs={12}>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Typography
              className={classes.footer}
              style={footerStyle}
              variant="subtitle2"
            >
              {RetentionContentEnum.FOOTER_CO}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const showInfoContent = (propsModal: InfoTrxProps) => {
  if (propsModal.isRetColombia) {
    return (
      <>
        {propsModal.pdfViewer && (
          <PdfViewer
            fileName={propsModal.pdfViewer!.fileName}
            base64File={propsModal.pdfViewer!.base64File}
            handleCloseModal={propsModal.pdfViewer!.handleCloseModal}
            openModal={propsModal.pdfViewer!.openModal}
          />
        )}
        {renderTrxInfoPaperHistoricCO(propsModal)}
        {renderRetentionInfoPaperHistoric(propsModal)}
      </>
    );
  }
  return (
    <React.Fragment>
      {renderTrxInfoPaperHistoric(propsModal)}
      {renderRetentionInfoPaper(propsModal)}
      {renderMerchantInfoPaper(propsModal)}
    </React.Fragment>
  );
};

const ModalBodyRetentionHistoric = (props: InfoTrxProps) => {
  const dataTabs: { title: string; content: JSX.Element }[] = [];
  const propsModal: InfoTrxProps = {
    ...props,
    isHistoric: true,
    isRetColombia: props.trxInfo!.country === CountryEnum.colombia,
    isModify: true,
  };

  useEffect(() => {
    props.handleChangeTab!(0);
  }, []);

  dataTabs.push({
    title: "Información",
    content: showInfoContent(propsModal),
  });

  dataTabs.push({
    title: "Linea de tiempo",
    content: <TimeLineTabComponent {...propsModal} />,
  });

  if (
    propsModal.isRetColombia &&
    (!propsModal.trxInfo?.oldTransactions ||
      propsModal.trxInfo?.oldTransactions?.length === 0)
  ) {
    dataTabs.pop();
  }

  const tabs: IKshTab = {
    tabs: [...dataTabs],
  };

  return <KshTab {...tabs} onTabClick={props.handleChangeTab} />;
};

export default ModalBodyRetentionHistoric;
