import React from "react";
import { Grid, Typography, useMediaQuery, Paper } from "@material-ui/core";
import Filters from "../../components/Filters/Filters";
import { useNotificationsIndexState } from "./state/useNotificationsIndexState";
import { NotificationsTable } from "../../components/NotificationsTable/NotificationsTable";
import { IAppState } from "../../store/reducer";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  getWebhookNotifications,
  IAppAction,
  resendWebhookNotification,
  setNotification,
} from "../../store/actionCreators";
import { connect } from "react-redux";
import { NotificationsSearchRequest } from "../../../types/query";
import { NotificationsData } from "../../../types/notifications_data";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { get } from "lodash";
import { auth } from "../../shared/auth";
import { WebhookNotification } from "../../../types/webhook_notification";
import { SnackBarAlert } from "../../components/Snackbar/SnackBarAlert";
import { INotification } from "../../shared/infrastructure/interfaces/INotification";

export interface NotificationsIndexStateProps {
  notificationsData?: NotificationsData;
  isLoading?: boolean;
  notification: INotification;
}

export interface NotificationsIndexFunctionProps {
  getWebhookNotifications: (body: NotificationsSearchRequest) => void;
  resendWebhookNotification: (payload: WebhookNotification) => void;
  setNotification: (notification: INotification) => void;
}

export type NotificationsIndexProps = NotificationsIndexStateProps &
  NotificationsIndexFunctionProps;

export const getBreadcrumbs = (
  isAdmin: boolean,
  merchantID: string
): { label: string; url: string }[] => {
  return isAdmin
    ? [
        { label: "Comercios", url: "/merchant-list" },
        {
          label: "Desarrolladores",
          url: `/config-merchant/merchant/${merchantID}`,
        },
      ]
    : [{ label: "Desarrolladores", url: "/config-merchant/developers" }];
};

export const NotificationsIndex: React.FC<NotificationsIndexProps> = (
  props: NotificationsIndexProps
) => {
  const isMobile = useMediaQuery("(max-width:769px)");
  const {
    datePicker,
    filterComponent,
    handler,
    state,
    user_profile,
    publicMerchantId,
  } = useNotificationsIndexState(props);
  const breadcrumbs = getBreadcrumbs(auth.isAdmin(), publicMerchantId);
  return (
    <React.Fragment>
      <Grid container style={{ marginBottom: 10 }}>
        <Grid item xs={12}>
          <Breadcrumb
            items={breadcrumbs}
            lastItem={"Notificaciones de webhooks"}
          />
        </Grid>
        <Typography
          style={{ fontWeight: 200 }}
          color={"primary"}
          variant={"h1"}
        >
          Notificaciones de Webhooks
        </Typography>

        <Grid
          item
          xs={12}
          style={{
            marginTop: 27,
          }}
        >
          <Paper>
            <Filters
              datePicker={{
                handleDate: datePicker.handleDate,
                dateValue: state.dateValue,
              }}
              filterComponent={filterComponent}
              isMobile={isMobile}
            />
            <NotificationsTable
              pagination={{
                ...state.pagination,
                handleChangePage: handler.handleChangePage,
                handleChangePageSize: handler.handleChangePageSize,
              }}
              isLoading={!!props.isLoading}
              timeZoneRegion={user_profile.timeZoneRegion}
              webhooks={get(props, "notificationsData.notifications", [])}
              reprocessWebhookNotification={props.resendWebhookNotification}
            />
          </Paper>
        </Grid>
      </Grid>
      <SnackBarAlert
        msg={props.notification.message}
        open={props.notification.open}
        type={props.notification.type}
        handlerClose={() =>
          props.setNotification({ ...props.notification, open: false })
        }
      />
    </React.Fragment>
  );
};

export const mapStateToProps: (
  state: IAppState
) => NotificationsIndexStateProps = (
  state: IAppState
): NotificationsIndexStateProps => ({
  notificationsData: state.notificationsData,
  isLoading: state.loading,
  notification: state.notification!,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => NotificationsIndexFunctionProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): NotificationsIndexFunctionProps => ({
  getWebhookNotifications: (body: NotificationsSearchRequest): void =>
    dispatch(getWebhookNotifications(body)),
  resendWebhookNotification: (payload: WebhookNotification): void =>
    dispatch(resendWebhookNotification(payload)),
  setNotification: (notification: INotification) =>
    dispatch(setNotification(notification)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsIndex);
