import { IStyles } from "../../../../shared/interfaces/Styles.interfaces";

export const DetailFooterStyles: IStyles = {
  box: {
    backgroundColor: "#F0F4F9",
    display: "flex",
    height: "68px",
    paddingBottom: "16px",
    paddingTop: "16px",
    width: "100%",
  },
};
