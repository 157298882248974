import React, { PropsWithChildren } from "react";

import { Box, CardContent, Grid, Skeleton } from "@mui/material";
import { IProcessingLoadingPanel } from "./IProcessingLoadingPanel.interfaces";
import { processorsStyles } from "../Processors.styles";

export const ProcessingLoadingPanel: React.FC<IProcessingLoadingPanel> = (
  _props: PropsWithChildren<IProcessingLoadingPanel>
) => {
  const classes = processorsStyles();

  return (
    <CardContent>
      <Box>
        <Box>
          <Skeleton className={classes.titleSkeleton} />
          <Grid
            container
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justifyItems={"center"}
            textAlign={"center"}
            justifyContent={"space-between"}
          >
            <Grid item>
              <Skeleton className={classes.subTitleSkeleton} />
              <Skeleton className={classes.subTitleEndSkeleton} />
            </Grid>
            <Grid item>
              <Skeleton className={classes.buttonProcessorSkeleton} />
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.boxButton}>
          <Skeleton className={classes.linkSkeleton} />
        </Box>
        <Box>
          <Grid container direction={"column"}>
            <Skeleton className={classes.accordionSkeleton} />
          </Grid>
        </Box>
      </Box>
    </CardContent>
  );
};
