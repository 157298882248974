/**
 * ComponentTextTitleEnum
 */
export enum ComponentTextTitleEnum {
  UPDATE_RULE = "Editar regla de seguridad",
  SECURITY_RULES_TITLE = "Reglas de seguridad",
  FRAUD_PREVENTION_TILE = "Prevención de Fraude",
  FOOTER_PAGINATION_TITLE = "Transacciones por página",
  CREATE_WHITELIST = "Agregar Lista Blanca",
  UPDATE_WHITELIST = "Editar Lista Blanca",
}
