import React, { FC } from "react";
import { Step, StepConnector, StepLabel, Stepper } from "@mui/material";
import { checkoutStepperStyles as styles } from "./CheckoutStepperStyles";
import { useCheckoutContext } from "../../containers/CheckoutContainer/context/useCheckoutContext";
import { LabelStepper } from "../../shared/infrastructure/StepperEnum";

export const CheckoutStepper: FC = () => {
  const { isMobile, step, steps } = useCheckoutContext();

  return (
    <Stepper
      activeStep={step}
      orientation={isMobile ? "horizontal" : "vertical"}
      alternativeLabel={isMobile}
      sx={isMobile ? styles.stepperDesktop : styles.stepperMobile}
      connector={
        <StepConnector
          sx={
            isMobile
              ? styles.connectorStepperMobile
              : styles.connectorStepperDesktop
          }
        />
      }
    >
      {steps.map((step) => (
        <Step key={step}>
          <StepLabel sx={styles.labelStepper}>{LabelStepper[step]}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
