import { BreadcrumProps } from "../../../components/common/Breadcrumb/Breadcrumb";
import { routes } from "../../../shared/infrastructure/routes";
import {
  OrderSortEnum,
  OriginTransactionsEnum,
  RequestDownloadEnum,
  STATUSFLOW,
  TabsEnum,
} from "../../../shared/infrastructure/constants/StatusFlowDashboardConstants";
import { CountryEnum } from "../../../shared/infrastructure/CountryEnum";
import { IPagination } from "../../../components/DashboardList/Table/DashboardConciliationTable/DashboardTable";
import { TransactionTable } from "../../../../types/transaction_table";
import { IOrderSort } from "../../../shared/infrastructure/interfaces/IOrderSort";
import { IStatusFlowData } from "../../../shared/infrastructure/interfaces/IStatusFlow";
import {
  defaultHeadersTransactions,
  defaultHeadersTransactionsHistory,
  IHeaderTable,
} from "../../../shared/infrastructure/headers/defaultHeaders";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  downloadProcessFileWS,
  getConciliationDataTransactions,
  setActualHeaders,
  setConciliationDataRowTransaction,
  setModalConciliation,
  setModalOmit,
  setOpenModalDetail,
} from "../../../store/actionCreators";
import { IAppState } from "../../../store/reducer";
import { cloneDeep, filter, get, split } from "lodash";
import { useLocation } from "react-router-dom";
import { IModalConciliation } from "@kushki/frontend-molecules/modal-conciliation";
import {
  getModalDetailValues,
  MODAL_TRANSITION,
} from "../../../shared/infrastructure/constants/ModalDetailConstants";
import { TransactionData } from "../../../../types/transaction_data";
import { TransactionSummaryData } from "../../../../types/transaction_summary_data";
import { OriginEnum } from "../../../shared/infrastructure/OriginEnum";
import { DateRange } from "@material-ui/pickers";
import { getInfoDetailConciliationComp } from "../../../shared/infrastructure/constants/InformationDetailConstants";
import { SnackbarAlertProps } from "../../../components/common/SnackBar/SnackBarAlert";
import { endOfDay, format, startOfDay, subDays } from "date-fns";
import { PendingFiltersProps } from "../../../components/Filters/PendingFilters/PendingFilters";
import { DefaultConciliationFilter } from "../../../shared/infrastructure/filter/DefaultConciliationFilter";
import { DateEnum } from "../../../shared/infrastructure/DateEnum";
import { getDownloadTransactionRequest } from "../../../shared/infrastructure/constants/DownloadTransactionsConstants";
import {
  getDayBefore,
  getMinDate,
  isValidDates,
  nowDate,
} from "../../../shared/infrastructure/utils/date";
import {
  buildFiltersObject,
  getDataRequestBody,
} from "../../../shared/infrastructure/utils/buildFiltersObject";
import { DefaultConciliationHistoricFilter } from "../../../shared/infrastructure/filter/DefaultConciliationHistoricFilter";
import { getTimelineConciliationComp } from "../../../shared/infrastructure/constants/TimelineDetailConstants";
import { getUserRole } from "../../../shared/infrastructure/constants/UserRoleConstants";
import { UserRolesEnum } from "../../../shared/infrastructure/UserRolesEnum";
import {
  DashboardTableEmptyTypeEnum,
  DashboardTableMessage,
  DashboardTableTypeMessageEnum,
} from "../../../shared/DashboardTableEnum";
import { DocumentRequestData } from "../../../../types/get_download_file_request";

export interface IConciliationDashboardState {
  trxReconcile: TrxReconcileProps;
  state: IState;
  breadCrumbs: BreadcrumProps;
  actions: {
    handleCheckboxChange: (
      rowValue: TransactionData,
      selected: boolean
    ) => void;
    handleCheckboxChangeAll: (value: TransactionData[]) => void;
    handleDateRangeChange: (dateRange: DateRange<Date>) => void;
    handleDownloadFile: () => void;
  };
  pagination: IPagination;
  filterProps: FiltersProps;
  pendingFiltersProps: PendingFiltersProps;
  check?: {
    selectedCheck: boolean;
    noSelectedCheck: boolean;
  };
  data: {
    conciliationData: TransactionTable;
    titleNoData?: string;
    sbtNoData?: string;
  };
  modalOver: ModalOverUtils;
  orderSort: IOrderSort;
  date: {
    dateRange: DateRange<Date>;
    minDate: Date;
  };
  notification: SnackbarAlertProps;
  loadingFileDownload?: boolean;
  modalDetail: {
    open: boolean;
    handleOpenDialogDetail: () => void;
    transactionTimelineSelected: TransactionData;
    handleCloseDialogDetail: () => void;
  };
}

export interface TrxReconcileProps {
  trxSelectedCheck: TransactionData[];
  trxNoSelectedCheck: TransactionData[];
  origin: string;
  actionReconcile: () => void;
  actionReconcileIndividual: (item?: TransactionData[]) => void;
  openMasiveModal: () => void;
  closeModal: () => void;
  openIndividualModal: (item?: TransactionData[]) => void;
  openOmittedModal: boolean;
  openOmittedMasiveModal: boolean;
  isFinOpsBackOffice: boolean;
}

export interface FiltersProps {
  filterHeaders?: () => IHeaderTable[];
}

export type ModalOverUtils = {
  handlerSelectTrxItem: (
    row: TransactionData | TransactionSummaryData | undefined
  ) => void;
  selectedRow: TransactionData | TransactionSummaryData;
  modalDetail: IModalConciliation;
  handleOpenModal: () => void;
  handleOpenTimelineDetailModal: (trx: TransactionData) => void;
};

export interface IState {
  country: CountryEnum;
  statusFlow: IStatusFlowData;
  isLoadingConciliation?: boolean;
  isLoadingHistoric?: boolean;
}

export const useConciliationDashboardState =
  (): IConciliationDashboardState => {
    const dispatch = useDispatch();
    const location = useLocation();

    const [state] = useState<IState>({
      country: CountryEnum.mexico,
      statusFlow: STATUSFLOW.transactions,
    });

    const {
      loadingFileDownload,
      conciliationData,
      openModalDetail,
      indexTransactionSummaryTab: actualTab,
      notification,
      actualHeaders,
    } = useSelector((state: IAppState) => state);

    state.isLoadingConciliation = useSelector(
      (state: IAppState) => state.isLoadingTransactionData!
    );

    const [trxSelectedCheck, setTrxSelectedCheck] = useState<TransactionData[]>(
      []
    );

    const [trxNoSelectedCheck, setTrxNoSelectedCheck] = useState<
      TransactionData[]
    >([]);
    const [selectedCheck, setSelectedCheck] = useState<boolean>(false);
    const [noSelectedCheck, setNoSelectedCheck] = useState<boolean>(false);
    const [order, setOrder] = useState<OrderSortEnum>(OrderSortEnum.ASC);
    const [orderBy, setOrderBy] = useState<string>("created");
    const [limit, setLimit] = useState<number>(100);
    const [page, setPage] = useState<number>(1);
    const [conciliationTrx, setConciliationtrxTrx] = useState<
      TransactionData | TransactionSummaryData
    >();
    const [selectedDateRange, setSelectedDateRange] = useState<DateRange<Date>>(
      [getDayBefore(), nowDate]
    );
    const [openOmittedModal, setOpenOmittedModal] = useState<boolean>(false);
    const [openOmittedMasiveModal, setOpenOmittedMassiveModal] =
      useState<boolean>(false);
    const [minDate] = useState<Date>(getMinDate());
    const [openModal, setOpenModal] = useState(false);
    const [modalDetail, setModalDetail] = useState(getModalDetailValues({}));
    const [transactionTimelineSelected, setTransactionTimelineSelected] =
      useState<TransactionData>({});
    const [fromDate, setFromDate] = useState(
      format(startOfDay(subDays(getDayBefore(), 0)), DateEnum.YMD)
    );
    const [toDate, setToDate] = useState(
      format(endOfDay(new Date()), DateEnum.YMD)
    );

    const [filtersDashboard, setFiltersDashboard] = useState(
      cloneDeep(DefaultConciliationFilter)
    );
    const [counterFilter, setCounterFilter] = useState<number>(0);
    const [filters, setFilters] = useState<object>({});

    const [body, setBody] = useState(
      getDataRequestBody(actualTab, { from: fromDate, to: toDate })
    );
    const isFinOpsBackOffice: boolean = getUserRole(
      UserRolesEnum.FinOpsBackOffice
    );
    const [titleNoData, setTitleNoData] = useState<string>(
      DashboardTableMessage[DashboardTableEmptyTypeEnum.EMPTY_FILTER][
        DashboardTableTypeMessageEnum.TITLE
      ]
    );
    const [sbtNoData, setSbtNoData] = useState<string>("");

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const handleOpenOmittedModal = (value: boolean) => {
      setOpenOmittedModal(true);
      setOpenOmittedMassiveModal(value);
    };
    const handleCloseOmittedModal = (value: boolean) => {
      setOpenOmittedModal(false);
      setOpenOmittedMassiveModal(value);
    };
    const openMasiveModal = () => {
      dispatch(
        setModalOmit({
          omitTransaction: OriginEnum.TRANSACTIONS,
          omitMasive: true,
          transactions: trxSelectedCheck!,
          transactionsNoSelected: trxNoSelectedCheck,
        })
      );
      handleOpenOmittedModal(true);
    };
    const closeModal = () => {
      handleCloseOmittedModal(true);
    };
    const openIndividualModal = (item?: TransactionData[]) => {
      dispatch(
        setModalOmit({
          omitTransaction: OriginEnum.TRANSACTIONS,
          omitMasive: false,
          transactions: item ? item : trxSelectedCheck!,
          transactionsNoSelected: [],
        })
      );
      handleOpenOmittedModal(false);
    };
    const handleCheckboxChange = (
      rowValue: TransactionData,
      selected: boolean
    ): void => {
      dispatch(
        setConciliationDataRowTransaction({
          ...rowValue,
          selected,
        })
      );
    };

    const handleCheckboxChangeAll = (value: TransactionData[]) => {
      value.map((item) => {
        dispatch(
          setConciliationDataRowTransaction({
            ...item,
            selected: !selectedCheck,
          })
        );
      });

      setSelectedCheck(!selectedCheck);
      setNoSelectedCheck(selectedCheck);
    };

    const actionReconcile = () => {
      dispatch(
        setModalConciliation({
          conciliation: OriginEnum.TRANSACTIONS,
          conciliationMasive: true,
          transactions: trxSelectedCheck!,
          transactionsNoSelected: trxNoSelectedCheck!,
          open: true,
          total: 0,
        })
      );
    };

    const actionReconcileIndividual = (item?: TransactionData[]) => {
      dispatch(
        setModalConciliation({
          conciliation: OriginEnum.TRANSACTIONS,
          conciliationMasive: false,
          transactions: item ? item : trxSelectedCheck!,
          transactionsNoSelected: [],
          open: true,
          total: 0,
        })
      );
    };
    const handleChangePage = (
      _event: React.ChangeEvent<unknown> | undefined,
      value: number,
      trxData: (TransactionData | TransactionSummaryData)[]
    ): void => {
      trxData.map((item) => {
        dispatch(
          setConciliationDataRowTransaction({
            ...item,
            selected: false,
          } as TransactionData)
        );
      });
      setSelectedCheck(false);
      setNoSelectedCheck(true);
      setPage(value);
      setBody({
        ...body,
        from: fromDate,
        to: toDate,
        limit: limit,
        offset: limit * (value - 1),
      });
    };

    const handleChangeLimit = (newLimit: number): void => {
      setPage(1);
      setLimit(newLimit);
    };

    const handleRequestSort = (
      _event: React.MouseEvent<unknown>,
      property: string
    ) => {
      setOrder(
        order === OrderSortEnum.ASC ? OrderSortEnum.DESC : OrderSortEnum.ASC
      );
      setOrderBy(property);
    };

    const handlerSelectTrxItem = (
      row: TransactionData | TransactionSummaryData | undefined
    ) => {
      if (row) {
        setModalDetailInformation(row);
        setConciliationtrxTrx(row);
        handleOpenModal();
      }
    };

    const setModalDetailInformation = (
      trx: TransactionData | TransactionSummaryData
    ) => {
      setModalDetail(
        getModalDetailValues({
          trx,
          informationComponent: getInfoDetailConciliationComp(trx),
          ...(actualTab === TabsEnum.HISTORIC && {
            timeLineComponent: getTimelineConciliationComp(
              trx,
              handleOpenTimelineDetailModal
            ),
          }),
        })
      );
    };

    const handleConciliationData = (request: object): void => {
      dispatch(getConciliationDataTransactions(request));
    };

    const filterHeaders = (): IHeaderTable[] => {
      const defaultValues: IHeaderTable[] =
        actualTab === TabsEnum.PENDING
          ? defaultHeadersTransactions
          : defaultHeadersTransactionsHistory;
      return actualHeaders.length !== 0 ? actualHeaders : defaultValues;
    };

    const handleDateChange = (dateRange: DateRange<Date>) => {
      if (!isValidDates(dateRange, getDayBefore(), nowDate)) {
        setSelectedDateRange(dateRange);
        setToDate(format(endOfDay(dateRange[1]!), DateEnum.YMD));
        setFromDate(format(startOfDay(dateRange[0]!), DateEnum.YMD));
        setBody({
          ...body,
          from: format(startOfDay(dateRange[0]!), DateEnum.YMD),
          to: format(endOfDay(dateRange[1]!), DateEnum.YMD),
        });
        setPage(1);
        handleCustomTitles(
          DashboardTableMessage[DashboardTableEmptyTypeEnum.EMPTY_CALENDAR][
            DashboardTableTypeMessageEnum.TITLE
          ],
          DashboardTableMessage[DashboardTableEmptyTypeEnum.EMPTY_CALENDAR][
            DashboardTableTypeMessageEnum.SUBTITLE
          ]
        );
      }
    };

    const handleOpenDialogDetail = () => {
      dispatch(setOpenModalDetail(true));
    };

    const handleCloseDialogDetail = () => {
      setTransactionTimelineSelected({});
      dispatch(setOpenModalDetail(false));
    };

    const handleDownloadFile = () => {
      dispatch(
        downloadProcessFileWS(
          getDownloadTransactionRequest({
            body: body as DocumentRequestData,
            origin: OriginTransactionsEnum.TRANSACTIONS,
            request: RequestDownloadEnum.CONCILIATION,
          })
        )
      );
    };

    const handleOpenTimelineDetailModal = (trx: TransactionData) => {
      setTransactionTimelineSelected(trx);
      handleOpenDialogDetail();
    };

    const handleSetFilters = (filter: { [key: string]: boolean }) => {
      const export_filter_selected = buildFiltersObject(filter);
      const selectedFilters = Object.keys(filter).filter(
        (key: string) => filter[key]
      );
      setCounterFilter(selectedFilters.length);
      setFilters(export_filter_selected);
      setBody({ ...body, filter: export_filter_selected, offset: 0 });
      handleCustomTitles(
        DashboardTableMessage[DashboardTableEmptyTypeEnum.EMPTY_FILTER][
          DashboardTableTypeMessageEnum.TITLE
        ],
        DashboardTableMessage[DashboardTableEmptyTypeEnum.EMPTY_FILTER][
          DashboardTableTypeMessageEnum.SUBTITLE
        ]
      );
      setPage(1);
    };

    const handleCustomTitles = (title: string, subTitle: string): void => {
      setTitleNoData(title);
      setSbtNoData(subTitle);
    };

    useEffect(() => {
      const trxCheck: TransactionData[] = filter(
        conciliationData.data,
        (item: TransactionData) => get(item, "selected", 0) === true
      ) as TransactionData[];

      const trxNoCheck: TransactionData[] = filter(
        conciliationData.data,
        (item: TransactionData) => get(item, "selected", 0) !== true
      ) as TransactionData[];

      setTrxSelectedCheck(trxCheck);
      setTrxNoSelectedCheck(trxNoCheck);
    }, [conciliationData.data]);

    useEffect(() => {
      setPage(1);

      setBody({ ...body, limit: limit, offset: limit * (page - 1) });
    }, [limit]);

    useEffect(() => {
      setBody(
        getDataRequestBody(actualTab, {
          limit: limit,
          offset: 0,
          filter: filters,
          from: format(startOfDay(subDays(getDayBefore(), 0)), DateEnum.YMD),
          to: format(endOfDay(new Date()), DateEnum.YMD),
        })
      );
      dispatch(setActualHeaders([]));
    }, []);

    useEffect(() => {
      setPage(1);
      setSelectedDateRange([getDayBefore(), nowDate]);
      setFiltersDashboard(
        actualTab === TabsEnum.PENDING
          ? cloneDeep(DefaultConciliationFilter)
          : cloneDeep(DefaultConciliationHistoricFilter)
      );
      setFilters({});
      setCounterFilter(0);
      setBody(
        getDataRequestBody(actualTab, {
          limit: limit,
          offset: 0,
          from: format(startOfDay(subDays(getDayBefore(), 0)), DateEnum.YMD),
          to: format(endOfDay(new Date()), DateEnum.YMD),
        })
      );
      handleCustomTitles(
        DashboardTableMessage[DashboardTableEmptyTypeEnum.EMPTY_FILTER][
          DashboardTableTypeMessageEnum.TITLE
        ],
        DashboardTableMessage[DashboardTableEmptyTypeEnum.EMPTY_FILTER][
          DashboardTableTypeMessageEnum.SUBTITLE
        ]
      );
      dispatch(setActualHeaders([]));
    }, [actualTab]);

    useEffect(() => {
      handleConciliationData(body);
    }, [body]);

    return {
      trxReconcile: {
        trxSelectedCheck,
        trxNoSelectedCheck,
        origin: split(location.pathname, "/")[2],
        actionReconcile,
        actionReconcileIndividual,
        openMasiveModal,
        closeModal,
        openIndividualModal,
        openOmittedModal,
        openOmittedMasiveModal,
        isFinOpsBackOffice,
      },
      state,
      actions: {
        handleCheckboxChange,
        handleCheckboxChangeAll,
        handleDateRangeChange: handleDateChange,
        handleDownloadFile,
      },
      pagination: {
        page,
        limit: Number(limit),
        text: "Transacciones por página",
        trxPerPage: Number(limit),
        handleChangePage,
        handleChangeLimit,
        size: "small",
        isMobile: false,
        siblingCount: 0,
      },
      check: {
        selectedCheck,
        noSelectedCheck,
      },
      filterProps: {
        filterHeaders,
      },
      pendingFiltersProps: {
        filters: filtersDashboard,
        handleDateRangeChange: handleDateChange,
        handleSetFilters: handleSetFilters,
        counterFilter: counterFilter,
        dateRange: selectedDateRange,
        minDate,
      },
      orderSort: {
        order,
        orderBy,
        handleRequestSort,
      },
      modalOver: {
        handlerSelectTrxItem,
        selectedRow: conciliationTrx!,
        modalDetail: {
          openModal,
          onCloseModal: handleCloseModal,
          transition: MODAL_TRANSITION,
          ...modalDetail,
          footer:
            actualTab === 0
              ? {
                  rol: isFinOpsBackOffice,
                  handleOnLeftClick: () => {
                    openIndividualModal([conciliationTrx!]);
                  },
                  handleOnRightClick: () => {
                    actionReconcileIndividual([conciliationTrx!]);
                  },
                }
              : undefined,
        },
        handleOpenModal,
        handleOpenTimelineDetailModal,
      },
      data: {
        conciliationData,
        titleNoData,
        sbtNoData,
      },
      breadCrumbs: {
        items: [
          {
            label: "Inicio",
            url: routes.BASE_PATH_BANK_CONCILIATION,
            disabled: true,
          },
        ],
        lastItem: STATUSFLOW.transactions.name,
      },
      date: {
        dateRange: selectedDateRange,
        minDate,
      },
      notification: notification as SnackbarAlertProps,
      loadingFileDownload,
      modalDetail: {
        open: openModalDetail,
        handleOpenDialogDetail,
        handleCloseDialogDetail,
        transactionTimelineSelected,
      },
    };
  };
