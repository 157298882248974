import React from "react";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "./EmptyList.styles";
import { EmptyListComponentProps } from "./EmptyList.interfaces";
import { RetentionEnum } from "../../shared/infrastructure/Enums/RetentionEnum";
import { TabEnum } from "../../shared/infrastructure/Enums/TabEnum";

export const EmptyList: React.FC<EmptyListComponentProps> = (
  props: EmptyListComponentProps
) => {
  const { retentionType } = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid
        container
        className={classes.grid}
        alignItems="center"
        direction="row"
      >
        <Typography variant="h3" className={classes.text}>
          No existen impuestos y retenciones configurados. Asegúrate de cargar
          las <br /> configuraciones para Retención{""}
          {retentionType == TabEnum.IVA
            ? " " + RetentionEnum.IVA.toUpperCase()
            : " " + RetentionEnum.RENTA.toUpperCase()}
          .
        </Typography>
      </Grid>
    </React.Fragment>
  );
};
