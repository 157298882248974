import { ILanguage } from "infrastructure/interfaces/ILanguage";

export const DEFAULT_LANGUAGE = {
  code: "es-LA",
  fullName: "Español (ES)",
  shortName: "ES",
};

export const LANGUAGES: ILanguage[] = [
  DEFAULT_LANGUAGE,
  {
    code: "en",
    fullName: "English (EN)",
    shortName: "EN",
  },
];

export const DISPLAYED_LANGUAGES: ILanguage[] = LANGUAGES.filter(
  (language) => language.code !== DEFAULT_LANGUAGE.code
);
