import { IStyles } from "../../shared/infrastructure/interfaces/Styles.interfaces";

export const SelectCheckStyles: IStyles = {
  chipContainer: {
    display: "flex",
  },
  list: {
    "& .MuiAutocomplete-groupLabel": {
      backgroundColor: "text.white",
      color: "text.dark",
      fontWeight: "500",
    },
    "& .MuiAutocomplete-option": {
      fontWeight: "400",
    },
    "& .MuiListSubheader-root": {
      fontSize: "14px",
    },
    "& li": {
      color: "text.dark",
      fontSize: "14px",
    },
  },
  listFirstItem: {
    borderBottom: "1px solid",
    borderBottomColor: "neutral.grey4",
    borderTop: "1px solid",
    borderTopColor: "neutral.grey4",
  },
  noOptions: {
    color: "text.dark",
    fontSize: "14px",
  },
  optionTextField: {
    "&.MuiAutocomplete-inputRoot": {
      padding: "8px 8px 8px 8px !important",
    },
    ".MuiAutocomplete-input": {
      margin: "2px 0px !important",
      padding: "0px !important",
    },
    height: "auto",
    minHeight: "44px",
  },
  selectCategory: {
    ".MuiChip-root": {
      marginRight: "10px",
    },
    fontSize: "16px",
    fontWeight: "400",
  },
  selectCategorySolid: {
    ".MuiChip-root": {
      marginRight: "10px",
    },
    ".MuiInputBase-root": {
      borderRadius: "16px !important",
    },
    fontSize: "16px",
    fontWeight: "400",
  },
};
