import React, { useEffect } from "react";
import {
  Box,
  Checkbox,
  createStyles,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import { DashboardListPagination } from "../../Pagination/DashboardListPagination";
import {
  DataTableFilters,
  DataTableFiltersProps,
} from "../../Filters/DataTableFilters";
import { EmptyList } from "../../EmptyList/EmptyList";
import { IColumnsTable } from "../../../shared/infrastructure/Table/ColumnsTable";
import { DashboardTableRow } from "./DashboardTableRow";
import { cloneDeep, defaultTo, get, sortBy } from "lodash";
import {
  AuthenticationRules,
  ResponseSearchRulesByFilters,
} from "../../../../types/response_search_rules_by_filters";
import { SkeletonTable } from "../../Skeletons/SkeletonTable";
import { PaginationTableV2 } from "../../Pagination/PaginationTableV2";
import {
  RULE_STATUS,
  StatusEnum,
} from "../../../shared/infrastructure/enums/StatusEnum";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../../theme";
import { ModalOverlay } from "@kushki/frontend-molecules/modal";
import { ModalHeader } from "@kushki/frontend-molecules/modal-header";
import { ModalBody } from "@kushki/frontend-molecules/modal-body";
import { ModalBodyTabs } from "../../ModalBodyInfo/ModalBodyTabs";
import { AuthenticationElastic } from "../../../../types/remote/authentication_elastic";
import { ProcessorInfoResponse } from "../../../../types/processor_info_response";
import { CredentialInfoResponse } from "../../../../types/credential_info_response";
import { format, utcToZonedTime } from "date-fns-tz";
import { tzRegion } from "../../../shared/infrastructure/constants/RuleRequestAuthenticationConstants";
import { translate } from "../../../shared/infrastructure/Utils";

export interface DashboardHistoricTableProps {
  mainTable?: boolean;
  country?: string;
  historicRulesData: ResponseSearchRulesByFilters;
  trxSelected?: AuthenticationRules[];
  page: number;
  pageSize: number;
  handleChangePage: (newPage: number) => void;
  handleChangePageSize: (newPageSize: number) => void;
  hideNext: boolean;
  hidePrevious: boolean;
  filterProps?: DataTableFiltersProps;
  modalOver?: {
    openModalCard: boolean;
    handlerCloseModal: () => void;
    handlerSelectTrx: (
      row: AuthenticationRules | AuthenticationElastic | undefined,
      type: string
    ) => void;
    trx: AuthenticationRules | AuthenticationElastic | undefined;
    processorData: ProcessorInfoResponse[];
    credentialData: CredentialInfoResponse[];
  };
  isLoading?: boolean;
  orderDataDashboard?: (payload: AuthenticationElastic[]) => void;
  tableColumns: {
    renderColumns: IColumnsTable[];
  };
  isAnalyst: boolean;
  paginationV2?: boolean;
  selectionMode?: boolean;
  handleCheckboxChange?: (
    type: StatusEnum,
    value: boolean,
    index: number
  ) => void;
  handleOpenAnalystAssignmentModal?: (
    historicRulesRequestData?: AuthenticationRules[]
  ) => void;
  checkboxActions?: {
    handleCheckboxChange: (index: number) => void;
  };
  hideFilters?: boolean;
  handleSelectTrx?: (
    _event: unknown,
    row: AuthenticationRules | undefined
  ) => void;
  validateExistTrxs?: (row: AuthenticationRules | undefined) => boolean;
  handleAddRule?: (row: AuthenticationRules) => void;
}

export const StyledTableCell = withStyles(
  createStyles({
    head: {
      backgroundColor: "#FFF",
      color: "#293036",
      padding: "8px 16px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const useStyles = makeStyles({
  titlesHeader: {
    fontWeight: 500,
  },
  titlesCheck: {
    fontWeight: "lighter",
  },
  textFooterButton: {
    color: "#FFF",
    fontSize: "15px",
  },
});

const getChipBackgroundColor = (status: string) => {
  switch (status) {
    case StatusEnum.PENDING:
      return `${"#F0F4F9"}`;
    case StatusEnum.IN_PROCESS:
      return `${"#EEF6FF"}`;
    case StatusEnum.SENT:
      return theme.palette.background.default;
    case StatusEnum.COMPLETED:
      return `${theme.palette.success.light}`;
    case StatusEnum.REJECT:
      return `${theme.palette.error.light}`;
    default:
      return "inherit";
  }
};

const getChipColor = (status: string) => {
  switch (status) {
    case StatusEnum.PENDING:
      return `${"#293036"}`;
    case StatusEnum.IN_PROCESS:
      return `${theme.palette.primary.dark}`;
    case StatusEnum.SENT:
      return `${theme.palette.grey.A700}`;
    case StatusEnum.COMPLETED:
      return `${theme.palette.success.dark}`;
    case StatusEnum.REJECT:
      return `${theme.palette.error.dark}`;
    default:
      return "inherit";
  }
};

export const DashboardTable: React.FC<DashboardHistoricTableProps> = (
  props: DashboardHistoricTableProps
) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState<string>("");
  const handleRequestSort = (property: string) => {
    const isAsc = order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  useEffect(() => {
    if (props.orderDataDashboard) {
      const records: AuthenticationElastic[] = cloneDeep(
        props.historicRulesData.records
      ) as AuthenticationElastic[];
      props.orderDataDashboard!(
        order === "desc"
          ? sortBy(records, [
              (ruleItem: AuthenticationElastic) =>
                get(ruleItem, orderBy, "created"),
            ]).reverse()
          : sortBy(records, [
              (ruleItem: AuthenticationElastic) =>
                get(ruleItem, orderBy, "created"),
            ])
      );
    }
  }, [order, orderBy]);

  const ruleStatus: string | Record<string, string> = defaultTo(
    RULE_STATUS[get(props.modalOver, "trx.status", "")],
    ""
  );

  const labelStatus: string = defaultTo(
    ruleStatus[get(props.modalOver, "trx.request_type", "")],
    translate(get(props.modalOver, "trx.status", ""))
  );

  return (
    <React.Fragment>
      <TableContainer
        style={{ maxHeight: `${props.selectionMode ? "356px" : "inherit"}` }}
      >
        {props.isLoading ? (
          <SkeletonTable />
        ) : (
          <React.Fragment>
            {props.isAnalyst ? (
              ""
            ) : !props.hideFilters ? (
              <DataTableFilters {...props.filterProps!} />
            ) : (
              ""
            )}
            {get(props.historicRulesData, "records.length", 0) > 0 ? (
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {props.tableColumns.renderColumns.map(
                      (column: IColumnsTable) => (
                        <React.Fragment key={column.id}>
                          {column.orderBy ? (
                            <StyledTableCell>
                              <Box
                                id={"sort_cell_custom"}
                                display={"flex"}
                                justifyContent={"flex-start"}
                                alignItems={"center"}
                                style={{ cursor: "pointer" }}
                                onClick={(_event) =>
                                  handleRequestSort(column.id)
                                }
                              >
                                <Typography
                                  variant={"body2"}
                                  className={classes.titlesHeader}
                                >
                                  {column.label}
                                </Typography>
                                <Box
                                  display={"flex"}
                                  flexDirection={"column"}
                                  alignItems={"center"}
                                  justifyContent={"center"}
                                  position={"relative"}
                                >
                                  <Box
                                    display={"flex"}
                                    alignItems={"flex-end"}
                                    justifyContent={"center"}
                                    position={"absolute"}
                                    bottom={"-6px"}
                                    left={0}
                                  >
                                    <ExpandLessIcon
                                      style={{ fontSize: "20px" }}
                                    />
                                  </Box>
                                  <Box
                                    display={"flex"}
                                    alignItems={"flex-start"}
                                    justifyContent={"center"}
                                    position={"absolute"}
                                    top={"-4px"}
                                    left={0}
                                  >
                                    <ExpandMoreIcon
                                      style={{ fontSize: "20px" }}
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            </StyledTableCell>
                          ) : column.checkboxHeader ? (
                            <StyledTableCell>
                              <Box
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                width={"100%"}
                              >
                                <FormControlLabel
                                  style={{
                                    marginRight:
                                      column.label === "Aceptar"
                                        ? "-15px"
                                        : "0px",
                                    width: "100%",
                                  }}
                                  id={column.id}
                                  control={
                                    <Checkbox
                                      checked={column.checkboxHeader!.checked}
                                      onChange={(e) =>
                                        column.checkboxHeader!.handleChange(
                                          column.checkboxHeader!.name,
                                          get(e, "target.checked", false)
                                        )
                                      }
                                      name={column.checkboxHeader!.name}
                                      color="primary"
                                    />
                                  }
                                  label={
                                    <Typography
                                      variant={"body2"}
                                      className={classes.titlesCheck}
                                    >
                                      {column.label}
                                    </Typography>
                                  }
                                />
                              </Box>
                            </StyledTableCell>
                          ) : (
                            <StyledTableCell>
                              <Typography
                                variant={"body2"}
                                className={classes.titlesHeader}
                              >
                                {column.label}
                              </Typography>
                            </StyledTableCell>
                          )}
                        </React.Fragment>
                      )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {defaultTo(props.historicRulesData.records, []).map(
                    (
                      item: AuthenticationRules | AuthenticationElastic,
                      index: number
                    ) => {
                      return (
                        <DashboardTableRow
                          checkboxActions={props.checkboxActions}
                          index={index}
                          mainTable={props.mainTable}
                          renderColumns={props.tableColumns.renderColumns}
                          handleCheckboxChange={props.handleCheckboxChange}
                          key={`rowtb_${index}`}
                          rulesInitialStatus={get(item, "initial_status", "")}
                          rulesState={get(item, "status", "")}
                          historicRulesRequestData={item}
                          handlerOpenRow={props.modalOver?.handlerSelectTrx!}
                          handleOpenAnalystAssignmentModal={
                            props.handleOpenAnalystAssignmentModal
                          }
                          checkBoxSelects={{
                            validateExistTrxs: props.validateExistTrxs!,
                            handleSelectTrx: props.handleSelectTrx!,
                          }}
                          handleAddRule={props.handleAddRule}
                        />
                      );
                    }
                  )}
                </TableBody>
              </Table>
            ) : (
              <EmptyList />
            )}
            {get(props.historicRulesData, "records.length", 0) > 0 &&
              !props.paginationV2 &&
              !props.selectionMode && (
                <DashboardListPagination
                  disableNext={props.hideNext}
                  disablePrevious={props.hidePrevious}
                  handleChangePage={props.handleChangePage}
                  handleChangePageSize={props.handleChangePageSize}
                  isMobile={false}
                  page={props.page}
                  pageSize={props.pageSize}
                />
              )}
            {get(props.historicRulesData, "records.length", 0) > 0 &&
              props.paginationV2 &&
              !props.selectionMode && (
                <PaginationTableV2
                  total={get(props.historicRulesData, "total", 0)}
                  limit={props.pageSize}
                  page={props.page}
                  isMobile={false}
                  trxPerPage={get(props.historicRulesData, "records.length", 0)}
                  size={"small"}
                  handleChangePage={props.handleChangePage}
                  handleChangePageSize={props.handleChangePageSize}
                  siblingCount={1}
                />
              )}
          </React.Fragment>
        )}
      </TableContainer>
      <ModalOverlay
        header={
          <ModalHeader
            chipBackGround={getChipBackgroundColor(
              get(props.modalOver, "trx.status", "")
            )}
            chipColor={getChipColor(get(props.modalOver, "trx.status", ""))}
            highlightValue={get(props.modalOver, "trx.merchant_name", "")}
            tittle={"Detalle de la solicitud"}
            secondaryValue={
              "Creación: " +
              format(
                utcToZonedTime(
                  get(props.modalOver, "trx.created", 1),
                  tzRegion
                ),
                "dd MMM'.' yyyy HH:mm"
              )
            }
            statusValue={labelStatus}
          />
        }
        body={
          <ModalBody>
            <ModalBodyTabs
              trxInfo={props.modalOver?.trx!}
              processorData={props.modalOver?.processorData!}
              credentialData={props.modalOver?.credentialData!}
            ></ModalBodyTabs>
          </ModalBody>
        }
        openModal={props.modalOver?.openModalCard!}
        onCloseModal={props.modalOver?.handlerCloseModal!}
        overlay={false}
        backdrop={false}
        transition={"left"}
      />
    </React.Fragment>
  );
};
