/* istanbul ignore file */
export enum TimeLineStatusEnum {
  CREATED = "CREATED",
  SENT = "SENT",
  EDITED = "EDITED",
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
}

export const RULE_STATUS: Record<string, string> = {
  [TimeLineStatusEnum.CREATED]: "Regla creada",
  [TimeLineStatusEnum.SENT]: "Regla enviada a revisión",
  [TimeLineStatusEnum.EDITED]: "Regla editada",
  [TimeLineStatusEnum.DISABLED]: "Regla deshabilitada",
  [TimeLineStatusEnum.ENABLED]: "Regla habilitada",
};
