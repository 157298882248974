import React from "react";
import { Box, Button, Grid } from "@mui/material";
import {
  IActionButtonsProps,
  useActionButtonsState,
} from "./state/useActionButtonsState";
import { createStyles, makeStyles } from "@mui/styles";
import { GeneralActionEnum } from "../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { ComponentIdEnum } from "../../shared/infrastructure/enums/ComponentIdEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { checkIsGeneralRule } from "../../utils/utilsFile";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(() =>
  createStyles({
    textGray: {
      color: "#6D7781",
    },
  })
);

export const ActionButtons: React.FC<IActionButtonsProps> = (
  props: IActionButtonsProps
) => {
  const { handleCreate, disableButton } = useActionButtonsState(props);
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-end"
          direction={"row"}
        >
          <Box mr={3} pt={4}>
            <Button
              className={classes.textGray}
              variant="text"
              size="large"
              onClick={props.onCancel}
            >
              {GeneralActionEnum.RETURN}
            </Button>
          </Box>
          {props.form && (
            <ComponentSecurityWrapper
              componentId={
                checkIsGeneralRule(useLocation().pathname)
                  ? ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_SAVE_GENERAL_RULE
                  : ComponentIdEnum.M_FRAUD_PREVENTION_SECURITY_RULES_THREE_DETAIL_SENT_TO_REVISION
              }
            >
              <Box pt={4}>
                <Button
                  disabled={disableButton}
                  size="large"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={props.form!.handleSubmit(handleCreate)}
                >
                  {checkIsGeneralRule(useLocation().pathname)
                    ? GeneralActionEnum.SAVE_RULE
                    : GeneralActionEnum.SEND_TO_REQUEST}
                </Button>
              </Box>
            </ComponentSecurityWrapper>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
