import { makeStyles } from "@material-ui/core/styles";
import { Palette } from "../../themeui";

export const tableComponentStyles = makeStyles({
  container: {
    backgroundColor: "white",
  },
  bodyCell: {
    color: Palette.neutral[700],
    fontFamily: "IBM Plex Sans",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16.32px",
    maxWidth: 317,
    wordBreak: "break-all",
  },
  headerCell: {
    color: Palette.neutral[700],
    fontFamily: "IBM Plex Sans",
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "140%",
  },
  paginationRoot: {
    padding: "8px 27px 8px 20px",
    backgroundColor: "#FFFFFF",
  },
  paginationButtonIconPagination: {
    textTransform: "none",
  },
  paginationMarginButton: {
    marginTop: -6,
    display: "flex",
  },
  paginationItemTotalCount: {
    marginTop: -6,
    display: "flex",
    marginLeft: "30px",
    marginRight: "30px",
  },
  paginationTypographyLabel: {
    marginTop: "auto",
    marginBottom: "auto",
    marginRight: 11,
  },
});
