import arrowsIcon from "../../../../assets/images/arrows.svg";
import { Icon } from "@material-ui/core";
import React from "react";

export const ArrowsIcon = () => {
  return (
    <Icon>
      <img alt={"declined icon"} src={arrowsIcon} />
    </Icon>
  );
};
