import { IUseFilterComponentState } from "../../../shared/infrastructure/interfaces/useFilterComponentState.interface";
import React, { useEffect, useState } from "react";
import _, { defaultTo, findIndex, get } from "lodash";
import {
  DEFAULT_ACCORDION,
  DEFAULT_RANGE_AMOUNT,
  defaultFilterStatus,
  FilterItems,
  OPERATION_TYPE_BY_TITLE,
  operationTypeItemsValues,
  statusFilterByCountry,
} from "../../../shared/infrastructure/constants/WalletDashboardConstants";
import { FilterWalletDashboardRequest } from "../../../../types/filter_wallet_dashboard_request";
import { useDispatch, useSelector } from "react-redux";
import {
  getTransactions,
  setFilterWalletDashboardRequest,
  setSearchType,
} from "../../../store/actionCreators";
import { IAppState } from "../../../store/reducer";
import { getLocalStorageValueByPath } from "../../../shared/utils/localStorage-utils";
import { LocalStoragePathsEnum } from "../../../shared/infrastructure/enums/LocalStoragePathsEnum";
import { SearchTypeEnum } from "../../../shared/infrastructure/enums/SearchTypeEnum";
import { IRangeAmount } from "../../../shared/infrastructure/interfaces/IRangeAmount";
import { RangeAmountTypeEnum } from "../../../shared/infrastructure/enums/RangeAmountTypesEnum";

export const buildRangeAmount = (rangeAmount: IRangeAmount) => {
  const updatedRangeAmount = {};

  if (rangeAmount.value === "") {
    return updatedRangeAmount;
  }

  const cleanRangeAmount = _.pickBy(
    rangeAmount,
    (value, key) => key !== "type" && value
  );

  const keys = Object.keys(cleanRangeAmount);

  keys.forEach((key) => {
    updatedRangeAmount[key] = +cleanRangeAmount[key];
  });

  return {
    ...updatedRangeAmount,
    type: rangeAmount.type,
  };
};

export const useFilterComponentState = (): IUseFilterComponentState => {
  const dispatch = useDispatch();
  const country = get(
    getLocalStorageValueByPath(
      LocalStoragePathsEnum.BASIC_MERCHANT_INFORMATION
    ),
    "country",
    ""
  );
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [defaultExpanded, setDefaultExpanded] = useState<boolean>(false);
  const [operationTypeItems, setOperationTypeItems] = useState<FilterItems[]>(
    operationTypeItemsValues
  );
  const [statusItems, setStatusItems] = useState<FilterItems[]>(
    defaultTo(statusFilterByCountry[country], defaultFilterStatus)
  );
  const [indexToChangeOperationType, setIndexToChangeOperationType] = useState<
    number | undefined
  >(undefined);
  const [isSelectedOperationType, setIsSelectedOperationType] = useState<
    boolean | undefined
  >(undefined);
  const [indexToChangeStatus, setIndexToChangeStatus] = useState<
    number | undefined
  >(undefined);
  const [isSelectedStatus, setIsSelectedStatus] = useState<boolean | undefined>(
    undefined
  );
  const [errorRange, setErrorRange] = React.useState<boolean>(false);
  const [rangeAmount, setRangeAmount] =
    useState<IRangeAmount>(DEFAULT_RANGE_AMOUNT);
  const [isActiveAccordion, setActiveAccordion] = useState(DEFAULT_ACCORDION());
  const filterWalletDashboardRequest: FilterWalletDashboardRequest | undefined =
    useSelector((state: IAppState) => state.filterWalletDashboardRequest);

  const handleCloseMenu = (): void => {
    setAnchor(null);
  };

  const handleClickFilter = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setAnchor(event.currentTarget);
  };

  const handleExpandedOrCollapsed = (): void => {
    setDefaultExpanded(!defaultExpanded);

    setActiveAccordion((prevState) =>
      Object.keys(prevState).reduce((acc, key) => {
        acc[key] = !defaultExpanded;

        return acc;
      }, {})
    );
  };

  const retrieveIndexOfFilterItemValues = (
    arrayValues: FilterItems[],
    title: string
  ): number => {
    return arrayValues.findIndex((item: FilterItems) => item.title === title);
  };

  const handleClickOnChip = (title: string, isSelected: boolean): void => {
    const indexSelected = findIndex(statusItems, ["title", title]);

    if (indexSelected !== -1) {
      statusItems[indexSelected].isSelected = isSelected;
      setIndexToChangeStatus(indexSelected);
      setIsSelectedStatus(isSelected);
    } else {
      const indexOperationTypeItem: number = retrieveIndexOfFilterItemValues(
        operationTypeItems,
        title
      );

      operationTypeItems[indexOperationTypeItem].isSelected = isSelected;

      setIndexToChangeOperationType(indexOperationTypeItem);
      setIsSelectedOperationType(isSelected);
    }
  };

  useEffect(() => {
    setOperationTypeItems(operationTypeItems);
  }, [indexToChangeOperationType, isSelectedOperationType]);

  useEffect(() => {
    setStatusItems(statusItems);
  }, [indexToChangeStatus, isSelectedStatus]);

  const handleApplyFilters = (): void => {
    dispatch(setSearchType(SearchTypeEnum.FILTER));
    setAnchor(null);

    const statusItemSelected: FilterItems[] = statusItems.filter(
      (item: FilterItems) => item.isSelected
    );
    const operationTypes: string[] =
      buildFilterOperationTypes(operationTypeItems);

    let filterWalletRequest: FilterWalletDashboardRequest = {
      ...filterWalletDashboardRequest!,
      filter: {
        origin_ticket_number: get(
          filterWalletDashboardRequest,
          "filter.origin_ticket_number",
          ""
        ),
        operationType: operationTypes.join("|"),
        transaction_status: buildTransactionStatus(statusItemSelected),
        rangeAmount: buildRangeAmount(rangeAmount),
      },
    };

    //@ts-ignore
    filterWalletRequest.filter = _.pickBy(
      filterWalletRequest.filter,
      (value) => !_.isEmpty(value)
    );

    filterWalletRequest = _.isEmpty(filterWalletRequest.filter)
      ? _.omit(filterWalletRequest, "filter")
      : filterWalletRequest;

    const merchantId: string =
      new URLSearchParams(location.search).get("publicMerchantId") || "";

    dispatch(setFilterWalletDashboardRequest(filterWalletRequest));
    dispatch(getTransactions(filterWalletRequest, merchantId));
  };

  const buildFilterOperationTypes = (
    operationTypeItems: FilterItems[]
  ): string[] => {
    const operationTypeSelected: FilterItems[] = operationTypeItems.filter(
      (item: FilterItems) => item.isSelected
    );

    const operationTypes: string[] = operationTypeSelected.map(
      (itemOperation: FilterItems) => {
        return OPERATION_TYPE_BY_TITLE[itemOperation.title];
      }
    );

    return operationTypes;
  };

  const buildTransactionStatus = (
    statusItemSelected: FilterItems[]
  ): string => {
    return get(statusItemSelected, "length", 0) <= 0
      ? ""
      : statusItemSelected
          .filter((item) => item.title !== "")
          .map((data: FilterItems) => data.value)
          .toString()
          .replace(/\,/g, "|");
  };

  const handleRestFilters = (): void => {
    setOperationTypeItems(resetFilterItems(operationTypeItemsValues));
    setStatusItems(resetFilterItems(statusItems));
    setRangeAmount(DEFAULT_RANGE_AMOUNT());
  };

  const resetFilterItems = (filterArray: FilterItems[]): FilterItems[] => {
    return filterArray.map((data: FilterItems) => {
      data.isSelected = false;

      return data;
    });
  };

  const handleChangeSelectRange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const defaultRangeAmount: IRangeAmount = DEFAULT_RANGE_AMOUNT(
      event.target.value as RangeAmountTypeEnum
    );

    setErrorRange(false);
    setRangeAmount({ ...defaultRangeAmount });
  };

  const handleChangeAmount = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: string
  ) => {
    setErrorRange(false);

    const newRangeAmount: IRangeAmount = { ...rangeAmount };

    const { value } = event.target;

    newRangeAmount[type] = value;
    newRangeAmount.value = value;

    if (
      newRangeAmount.type === RangeAmountTypeEnum.RANGE &&
      +newRangeAmount.min >= +newRangeAmount.max
    ) {
      setErrorRange(true);
    }
    setRangeAmount(newRangeAmount);
  };

  const toggleAccordionItem = (key: number) => {
    setActiveAccordion((prevState) => ({
      ...prevState,
      [key]: !Boolean(prevState[key]),
    }));
  };

  return {
    anchor,
    country,
    defaultExpanded,
    operationTypeItems,
    statusItems,
    handleCloseMenu,
    handleClickFilter,
    handleExpandedOrCollapsed,
    handleClickOnChip,
    handleApplyFilters,
    handleRestFilters,
    rangeAmountProps: {
      handlers: {
        handleChangeSelectRange,
        handleChangeAmount,
      },
      values: {
        errorRange,
        rangeAmount,
      },
    },
    isActiveAccordion,
    toggleAccordionItem,
  };
};
