/* istanbul ignore file */
import React, { Suspense } from "react";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { IAppState } from "./store/reducer";
import { IAppAction, setNotification } from "./store/actionCreators";
import { SnackBarAlert } from "./components/common/SnackBar/SnackBarAlert";
import { INotification } from "./shared/infrastructure/interfaces/INotification";

// @ts-ignore
import Normalize from "react-normalize";
import { routes } from "./shared/infrastructure/routes";
import { RetentionsIndex } from "./containers/RetentionsIndex/RetentionsIndex";
import { FileUploadFormIndex } from "./containers/FileUploadFormIndex/FileUploadFormIndex";
import { RetentionDashboard } from "./containers/RetentionDashboard/RetentionDashboard";
import { FormEditIndex } from "./containers/FormEditIndex/FormEditIndex";
import { FormIndex } from "./containers/FormIndex/FormIndex";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
import { environment } from "./environments/environment";
import { SecurityWrapperEnum } from "./shared/infrastructure/Enums/SecurityWrapperEnum";
import { FileUploadFormIndexEcuador } from "./containers/FileUploadFormIndexEcuador/FileUploadFormIndexEcuador";

interface INavigationFunctionsProps {
  setNotification(payload: INotification): void;
}

interface INavigationStateProps {
  notification: INotification;
}

type TNavigationProps = INavigationFunctionsProps & INavigationStateProps;
const Navigation: React.FC<TNavigationProps> = (props: TNavigationProps) => {
  const handleCloseSnackbar = () => {
    props.setNotification({
      open: false,
      message: props.notification.message,
      type: props.notification.type,
    });
  };

  return (
    <>
      <Normalize />
      <BrowserRouter>
        <Suspense fallback={<div />}>
          <ModuleSecurityWrapper
            basePath={environment.kushkiUrl}
            componentId={SecurityWrapperEnum.ROOT_MODULE}
          >
            <Switch>
              <Route
                path={`${routes.BASE_PATH_RETENTIONS}${routes.INDEX}`}
                exact
                component={RetentionsIndex}
              />
              <Route
                path={`${routes.BASE_PATH_RETENTIONS}${routes.WITH_CSV}`}
                exact
                component={FileUploadFormIndex}
              />
              <Route
                path={`${routes.BASE_PATH_RETENTIONS}${routes.CATALOG_FILE}`}
                exact
                component={FileUploadFormIndexEcuador}
              />
              <Route
                path={`${routes.BASE_PATH_RETENTIONS}${routes.EDIT_FORM}`}
                exact
                component={FormEditIndex}
              />

              <Route
                path={`${routes.BASE_PATH_RETENTIONS}${routes.WITH_FORM}`}
                exact
                component={FormIndex}
              />
              <Route
                path={`${routes.BASE_PATH_RETENTIONS}${routes.DASHBOARD}`}
                exact
                component={RetentionDashboard}
              />
            </Switch>
          </ModuleSecurityWrapper>
        </Suspense>
        <SnackBarAlert
          type={props.notification.type}
          msg={props.notification.message}
          open={props.notification.open}
          handlerClose={handleCloseSnackbar}
        />
      </BrowserRouter>
    </>
  );
};

export const mapStateToProps: (state: IAppState) => INavigationStateProps = (
  state: IAppState
): INavigationStateProps => ({
  notification: state.notification!,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => INavigationFunctionsProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): INavigationFunctionsProps => ({
  setNotification: (payload: INotification) =>
    dispatch(setNotification(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
