import React from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  EventList,
  OPTIONS,
  OTHER_EVENTS,
  OTHER_OPTIONS,
  PAYIN_EVENTS,
  PAYIN_OPTIONS,
  PAYMENT_METHOD_LABELS,
  PAYOUTS_EVENTS,
  PAYOUTS_OPTIONS,
  PRODUCT_EVENTS,
  PRODUCT_OPTIONS,
} from "../../shared/infrastructure/constants/OptionsEnum";
import { EventCheckList } from "../EventCheckList/EventCheckList";
import { intersection } from "lodash";
import { useWebhookEventsCheckList } from "./useWebhookEventsCheckList/useWebhookEventsCheckList";
import { ModalPreauthConfirmation } from "../ModalPreauthConfirmation/ModalPreauthConformation";

const useStyles = makeStyles({
  title: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "140%",
    color: "#293036",
  },
  listContainer: {
    marginBottom: 16,
    border: "1px solid #F7FAFC",
    boxSizing: "border-box",
    borderRadius: "4px",
  },
});

const WebhookEventsCheckList: React.FC = () => {
  const classes = useStyles();
  const {
    options,
    handler: {
      handleStatus,
      handleCheck,
      handleSelectAllEvents,
      handleAreAllEventsSelected,
      hasEvents,
    },
    modalPreath,
  } = useWebhookEventsCheckList();

  const renderCheckList = (
    title: string,
    optionsList: string[],
    events: EventList
  ) => {
    const filteredOptions = intersection(optionsList, options);

    return (
      options.length > 0 && (
        <>
          <Grid item xs={12}>
            <Typography className={classes.title}>{title}</Typography>
          </Grid>
          {filteredOptions.map((option: string, index: number) => (
            <Grid
              key={index}
              item
              xs={4}
              className={classes.listContainer}
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <EventCheckList
                title={OPTIONS[option]}
                handleStatus={handleStatus}
                handleCheck={handleCheck}
                option={option}
                items={events}
              />
            </Grid>
          ))}
          <ModalPreauthConfirmation
            open={modalPreath.openModalPreauth}
            clearEventsExceptPreauth={modalPreath.clearEventsExceptPreauth}
            handleCloseModalPreauth={modalPreath.handleCloseModal}
          />
        </>
      )
    );
  };

  return options.length > 0 ? (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" color="primary" gutterBottom>
          Eventos
        </Typography>
        <Typography variant="subtitle2" color="textPrimary">
          Selecciona todos los eventos que apliquen al webhook de acuerdo a los
          medios de pago. De ser el caso de utilizar el evento de
          preautorización no será posible seleccionar más eventos.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              onChange={handleSelectAllEvents}
              checked={handleAreAllEventsSelected()}
            />
          }
          label={"Seleccionar todo"}
        />
      </Grid>

      {hasEvents(PAYIN_OPTIONS) &&
        renderCheckList(
          PAYMENT_METHOD_LABELS.PAYIN,
          Object.keys(PAYIN_OPTIONS),
          PAYIN_EVENTS
        )}
      {hasEvents(PAYOUTS_OPTIONS) &&
        renderCheckList(
          PAYMENT_METHOD_LABELS.PAYOUTS,
          Object.keys(PAYOUTS_OPTIONS),
          PAYOUTS_EVENTS
        )}
      {hasEvents(PRODUCT_OPTIONS) &&
        renderCheckList(
          PAYMENT_METHOD_LABELS.PRODUCT,
          Object.keys(PRODUCT_OPTIONS),
          PRODUCT_EVENTS
        )}
      {hasEvents(OTHER_OPTIONS) &&
        renderCheckList(
          PAYMENT_METHOD_LABELS.OTHER,
          Object.keys(OTHER_OPTIONS),
          OTHER_EVENTS
        )}
    </>
  ) : null;
};

export { WebhookEventsCheckList };
