import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const footerNewConsoleStyles = createNamedStyles({
  footer: {
    alignItems: "center",
    backgroundColor: "#FAFCFD",
    bottom: "0px",
    display: "flex",
    columnGap: 12,
    height: "10vh",
    "@media (max-width: 768px)": {
      height: "8vh",
      marginTop: "4em",
    },
    justifyContent: "space-between",
    left: "0px",
    padding: "0 10%",
    position: "fixed",
    width: "100%",
    zIndex: 10,
    filter:
      "drop-shadow(0px -8px 17px rgba(106, 167, 197, 0.15)) drop-shadow(0px -1.6px 2.7625px rgba(106, 167, 197, 0.075))",
  },
  footerButton: {
    backgroundColor: "#4499EE",
    borderRadius: "16px",
    color: "#ffffff",
    fontSize: "16px",
    padding: "10px 30px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#4499EE",
    },
  },
  footerTextWrapper: {
    display: "flex",
    justifyContent: "space-between",
    columnGap: 12,
  },
  footerText: {
    color: "#64798D",
    fontSize: "13px",
  },
});
