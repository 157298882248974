export enum CategoryEnum {
  transactionBank = "bankId",
  documentType = "documentType",
  accountType = "accountType",
  enrollment = "enroll",
  transactionTotalAmount = "totalAmount",
}

export enum LabelCategoryEnum {
  transactionBank = "Institución Financiera",
  documentType = "Tipo de documento",
  accountType = "Tipo de cuenta",
  enrollment = "Enrolamiento",
  transactionTotalAmount = "Monto",
}
