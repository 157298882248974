export enum routes {
  MERCHANTS = "/merchants",
  MERCHANT_LIST = "/merchant-list",
  INDEX = "/config-merchant",
  BILLING = "/billing-merchant/invoice-configuration",
  CHARGE = "/billing-merchant/charge-configuration",
  RATES = "/rate",
  PERSONALIZATION = "/config-merchant/personalization",
  SUPPORT_INFO = "/config-merchant/support-info",
  BANK_TRANSFER = "/config-merchant/bank-transfer",
  WEBHOOKS_NOTIFICATIONS = "/config-merchant/webhooks-notifications",
  LOCALIZATION = "/config-merchant/localization",
  PROCESSORS = "/config-merchant/processors",
  DEFERRED = "/config-merchant/deferred",
  SECURITY_RULES = "/config-merchant/security_rules",
  CREDENTIALS = "/config-merchant/credentials",
  DEVELOPERS_CREDENTIALS = "/credentials",
  WEBHOOKS = "/webhook",
  USERS_LIST = "/config-merchant/users-list",
  CREATE_USER = "/config-merchant/users-list",
  NOTIFICATIONS = "/webhook/notifications",
  TRANSACTION_NOTIFICATION = "/config-merchant/transaction-notification",
  ETPAY_CUSTOMIZATION = "/etpay-customization",
  CREATE_MERCHANT = "/create-merchant/details",
  DASHBOARD = "/dashboard",
  MERCHANT_RESUME = "/merchant-resume",
  MERCHANT_PROCESSING = "/processing",
  BATCH_PROCESSING = "/processing/batch",
  PROCESSOR_LIST = "/processor-list?merchantId=",
}
