import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { defaultTo } from "lodash";
import { IUseModalCatalogGeneralRulesState } from "../../../shared/infrastructure/interfaces/IUseModalCatalogGeneralRulesState";
import {
  getRuleRequestCatalog,
  setDetailCatalogGeneralRules,
  setOpenModalCatalogGeneralRules,
} from "../../../store/actionCreators";

import { IModalHeaderProps } from "@kushki/connect-ui/dist/Components/Molecules";
import { SearchRuleCatalogRequest } from "../../../../types/search_rule_catalog_request";
import { RuleGeneralRequest } from "../../../../types/rule_general_request";
import { SearchCatalogEnum } from "../../../shared/infrastructure/enums/FilterEnum";

export const useModalCatalogGeneralRulesState =
  (): IUseModalCatalogGeneralRulesState => {
    const dispatch = useDispatch();

    const [header, setHeader] = useState<IModalHeaderProps>({
      buttonSecondary: undefined,
      isLoading: true,
      subtitle: undefined,
      buttonPrimary: undefined,
      title: "Catálogo de reglas generales",
    });

    const isOpenModal: boolean = useSelector((state: IAppState) =>
      defaultTo(state.openModalCatalogGeneralRules?.openModal, false)
    );
    const showButton: boolean = useSelector((state: IAppState) =>
      defaultTo(state.openModalCatalogGeneralRules?.showButton, false)
    );

    const [selectedRule, setSelectedRule] = useState<
      RuleGeneralRequest | undefined
    >(undefined);

    const defaultFilters: SearchRuleCatalogRequest = {
      limit: 100,
      offset: 0,
      sort: {
        field: SearchCatalogEnum.CREATED,
        order: SearchCatalogEnum.ORDER_ASC,
      },
    };

    useEffect(() => {
      setHeader({ ...header, isLoading: false });
      dispatch(getRuleRequestCatalog(defaultFilters));
    }, []);

    const onClose = () => {
      dispatch(setOpenModalCatalogGeneralRules({ openModal: false }));
      dispatch(setDetailCatalogGeneralRules(undefined));
      dispatch(getRuleRequestCatalog(defaultFilters));
      setSelectedRule(undefined);
    };
    return {
      header,
      isOpenModal,
      showButton,
      actions: {
        onClose,
        setSelectedRule,
      },
      selectedRule,
    };
  };
