import React from "react";
import WrapperContainer from "../../components/WrapperContainer/WrapperContainer";
import FormWrapper from "../../components/FormWrapper/FormWrapper";
import { WrapperLabelEnum } from "../../shared/enums/WrapperLabelEnum";
import { WebhookConfig } from "../../components/WebhookConfig/WebhookConfig";
import { CustomerWebhooks } from "../../components/CustomerWebhooks/CustomerWebhooks";
import { useMainContainer } from "./state/useMainContainer";
import { ROUTES } from "../../shared/constants/routes_tab";

const MainContainer: React.FC = () => {
  const { onRedirectWebhook, isEmpty, isCentralized } = useMainContainer();

  return (
    <WrapperContainer
      currentRoute={ROUTES.DEVELOPERS}
      isCentralized={isCentralized}
    >
      <FormWrapper title={WrapperLabelEnum.TITLE}>
        <>
          {isEmpty ? (
            <WebhookConfig
              title={WrapperLabelEnum.WEBHOOK_TITLE}
              buttonTitle={WrapperLabelEnum.WEBHOOK_BUTTON}
              onRedirectWebhook={onRedirectWebhook}
              isCentralized={isCentralized}
            />
          ) : (
            <CustomerWebhooks />
          )}
        </>
      </FormWrapper>
    </WrapperContainer>
  );
};

export default MainContainer;
