import React, { ChangeEvent, useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  AppBar,
  Box,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { SkeletonListContainer } from "../Skeleton/SkeletonListContainer";
import { TransactionTable } from "../../../types/transaction_table";
import { Indicators } from "../Indicators/Indicators";
import {
  InvoiceRecord,
  ResponseSearchBillingByFilters,
} from "../../../types/remote/response_search_blling_by_filters";
import { get } from "lodash";
import DashboardTable from "../DashboardList/Table/DashboardBillingTable/DashboardTable";
import DashboardHistoricTable, {
  DownloadDocumentParams,
} from "../DashboardList/Table/DashboardHistoricTable/DashboardHistoricTable";
import { FilterDashboardStateProps } from "../DashboardList/FilterDashboard";
import { HistoricFiltersProps } from "../Filters/HistoricFilters/HistoricFilters";
import { CountryList } from "../DashboardList/Table/DashboardBillingTable/DashboardTableRow/DashboardTableRow";
import { StatusFlowEnum } from "../../shared/infrastructure/StatusFlowEnum";
import {
  ISort,
  ITextArea,
} from "../../containers/BillingDashboard/state/useBillingDashboardState";
import { IColumnsTable } from "../../shared/infrastructure/table/IColumnsTable";
import { RetentionCountry } from "../../shared/infrastructure/RetentionCountry";
import { RetentionDashboard } from "../RetentionDashboard/RetentionDashboard";
import { IRetentionDashboardState } from "../RetentionDashboard/state/useRetentionDashboardState";
import { BillingTabTitleEnum } from "../../shared/infrastructure/BillingTabTitleEnum";
import { KindRetentionEnum } from "../../shared/infrastructure/KindRetentionEnum";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      fontWeight: 200,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: "75%",
      height: "10%",
    },
    root: { background: "transparent", boxShadow: "none" },
    tabStyle: {
      textTransform: "none",
    },
    paper: {
      backgroundColor: "#F7FAFC",
      marginTop: (tabValue: number) => (tabValue === 0 ? 0 : theme.spacing(4)),
    },
  })
);

export interface DashboardTabsProps {
  stateRentetionDashboard?: IRetentionDashboardState;
  historicBillingData: ResponseSearchBillingByFilters;
  country: CountryList;
  isLoading: boolean;
  isLoadingProcessBilling: boolean;
  isLoadingHistoric: boolean;
  handleCheckboxChange: (
    rowValue: TransactionTable
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  page: number;
  pageSize: number;
  handleChangePage: (newPage: number) => void;
  handleChangePageSize: (newPageSize: number) => void;
  hideNext: boolean;
  hidePrevious: boolean;
  filterProps: FilterDashboardStateProps;
  historicFilterProps: HistoricFiltersProps;
  modalOver: {
    openModal: boolean;
    openModalRetention: boolean;
    handlerOpenModalRetention: () => void;
    handlerCloseModalRetention: () => void;
    handleInputReasonDeleteRetention: (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    handlerDeleteRetention: () => void;
    handlerCloseModal: () => void;
    handlerSelectHistoricTrx: (row: InvoiceRecord | undefined) => void;
    handleOpenDialogDetail?: (data: InvoiceRecord) => void;
    handleCloseDialogDetail?: () => void;
    pdfViewer?: {
      fileName: string;
      openModal: boolean;
      base64File: string;
      handleCloseModal: () => void;
      handleOpenModal: () => void;
      handlePdfViewer: (
        transactionId: string,
        kindRentenion: KindRetentionEnum
      ) => void;
    };
    downloadFileRetention: (
      transactionId: string,
      extension: "pdf" | "xml" | "csv"
    ) => void;
    historicTrx: InvoiceRecord;
    downloadFilePDF: (
      invoiceId: string,
      merchantName: string,
      extension: string
    ) => void;
    downloadFilePDFS3: (invoiceId: string) => void;
    isLoadingDownloadEfact: boolean;
    isHistoricMinAmountTrx: boolean;
    downloadPreprocessFileWS: (params: DownloadDocumentParams) => void;
    isLoadingFileDetail: boolean;
    isLoadingDownloadPreProcess: boolean;
    isLoadingDeleteRetention?: boolean;
    availableDeleteRetentionButton: boolean;
    downloadPdfProps: string;
    reasonDeleteRetention: string | undefined;
    countCharacterReasonDeleteRetention: number;
    downloadPdfLoading: boolean;
    isLoadingFileRetention: boolean;
  };
  setActiveTabExecutor: (payload: number) => void;
  modalOverBilling: {
    openModalBilling: boolean;
    handlerCloseModalTrxBilling: () => void;
    handlerSelectTrxBilling: (
      row: TransactionTable | undefined,
      index: number
    ) => void;
    handleOpenDialogDetail?: (data: InvoiceRecord) => void;
    handleCloseDialogDetail?: () => void;
    trxBilling: { trx: TransactionTable | undefined; index: number };
    isMinAmountTrx: boolean;
  };
  modalBackRetention: {
    openModalBackRetention: boolean;
    handlerOpenModalBackRetention: (row: InvoiceRecord | undefined) => void;
    handlerCloseModalBackRetention: () => void;
    handlerAcceptTransaction: () => void;
    isLoading: boolean;
  };
  reprocess: { reprocessNumber: number; reprocessTransactions: () => void };
  statusFlow: StatusFlowEnum;
  tableColumns: {
    renderColumns: IColumnsTable[];
  };
  textArea?: ITextArea;
  sortProps: ISort;
  downloadFileState?: {
    isLoading: boolean;
    downloadFile: (format: string) => void;
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function tabProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const DashboardTabs: React.FC<DashboardTabsProps> = (
  props: DashboardTabsProps
) => {
  const {
    isLoading,
    isLoadingProcessBilling,
    statusFlow,
    country,
    handleCheckboxChange,
    checked,
    filterProps,
    modalOverBilling,
    isLoadingHistoric,
    historicFilterProps,
    handleChangePageSize,
    handleChangePage,
    modalOver,
    modalBackRetention,
    sortProps,
    tableColumns,
    textArea,
    stateRentetionDashboard,
    reprocess,
  } = props;
  const validateRetentionCountry = (): boolean => {
    return RetentionCountry[props.country].value;
  };
  const classes = useStyles(0);
  const [value, setValue] = useState<number>(0);
  const handleTabsChange = (
    _event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setValue(newValue);
    props.setActiveTabExecutor(newValue);
  };

  useEffect(() => {
    if (!validateRetentionCountry() && value === 2) setValue(1);
    if (validateRetentionCountry() && value === 1) setValue(2);
  }, [props.country]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <AppBar position="static" className={classes.root}>
          <Tabs
            value={value}
            onChange={handleTabsChange}
            aria-label="billing tabs"
            variant="fullWidth"
            indicatorColor={"primary"}
          >
            <Tab
              label={
                <Typography variant={"h6"} color={"textPrimary"}>
                  {BillingTabTitleEnum.BILLING_EXECUTOR}
                </Typography>
              }
              {...tabProps(0)}
              className={classes.tabStyle}
            />
            {validateRetentionCountry() && (
              <Tab
                label={
                  <Typography variant={"h6"} color={"textPrimary"}>
                    Retención
                  </Typography>
                }
                {...tabProps(1)}
                className={classes.tabStyle}
              />
            )}
            <Tab
              label={
                <Typography variant={"h6"} color={"textPrimary"}>
                  Histórico
                </Typography>
              }
              {...tabProps(validateRetentionCountry() ? 2 : 1)}
              className={classes.tabStyle}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Indicators statusFlow={statusFlow} />
          {isLoading && !isLoadingProcessBilling ? (
            <SkeletonListContainer />
          ) : (
            <Paper className={classes.paper} elevation={0}>
              <DashboardTable
                country={country}
                isLoadingProcessBilling={isLoadingProcessBilling}
                isLoading={isLoading}
                handleCheckboxChange={handleCheckboxChange}
                checked={checked}
                filterProps={filterProps}
                modalOverBilling={modalOverBilling}
                statusFlow={statusFlow}
              />
            </Paper>
          )}
        </TabPanel>
        {validateRetentionCountry() && (
          <TabPanel value={value} index={1}>
            <RetentionDashboard
              country={country}
              stateRentetionDashboard={stateRentetionDashboard!}
              indicatorsFlow={StatusFlowEnum.EXECUTOR}
            />
          </TabPanel>
        )}
        <TabPanel value={value} index={validateRetentionCountry() ? 2 : 1}>
          <Indicators statusFlow={statusFlow} isHistoric={true} />
          <React.Fragment>
            {isLoadingHistoric ? (
              <SkeletonListContainer />
            ) : (
              <Paper className={classes.paper} elevation={0}>
                <DashboardHistoricTable
                  statusFlow={statusFlow}
                  historicFilterProps={historicFilterProps}
                  pageSize={get(props, "pageSize", 0)}
                  page={get(props, "page", 0)}
                  handleChangePageSize={handleChangePageSize}
                  handleChangePage={handleChangePage}
                  hideNext={get(props, "hideNext", false)}
                  hidePrevious={get(props, "hidePrevious", false)}
                  modalOver={modalOver}
                  modalBackRetention={modalBackRetention}
                  textArea={textArea}
                  country={country}
                  tableColumns={tableColumns}
                  sortProps={sortProps}
                  reprocess={reprocess}
                  downloadFileState={props.downloadFileState}
                />
              </Paper>
            )}
          </React.Fragment>
        </TabPanel>
      </Grid>
    </Grid>
  );
};
