import React, { FC } from "react";
import ModalActiveFilter from "../../components/ModalActiveFilter/ModalActiveFilter";
import { branchEditContainerStyles as styles } from "./BranchEditContainer.styles";
import { Box, Grid } from "@mui/material";
import { HeaderTable } from "../../components/HeaderTable/HeaderTable";
import { BRANCH_LABELS } from "../../shared/constants/labels/branch_container_labels";
import {
  CANCEL_TEXT,
  DESCRIPTION_EDIT_MODAL_CONFIRM,
  EDIT_BRANCH,
  MODAL_CONFIRM,
  MODAL_EDIT_CONFIRM,
  YES_CONTINUE_TEXT,
} from "../../shared/constants/labels/buttons_labels";
import FooterOptions from "../../components/FooterOptions/FooterOptions";
import { TableSimple } from "../../components/Table/TableSimple/TableSimple";
import {
  CLIENT_TYPE_LABEL,
  COUNTRY_CONSTITUTION,
} from "../../shared/constants/labels/table_labels";
import { TEXTS_BRANCH_MANUAL } from "../../shared/constants/labels/branch_manual_creation_labels";
import { useBranchEditContainerState } from "./state/useBranchEditContainerState";
import { INITIAL_EDIT_FILTERS } from "../../shared/constants/initial_filters_side_bar";
import { ModalLoader } from "../../components/ModalLoader/ModalLoader";
import {
  LOADER_CONFIG_BRANCHES_TITLE,
  LOADER_CREATE_BRANCHES_DESCRIPTION,
} from "../../shared/constants/labels/branches_labels";
import { ModalConfirm } from "../../components/ModalConfirm/ModalConfirm";
import { IConfirmModal } from "../../shared/interfaces/confirmModal.interfaces";
import {
  INCOMPLETE,
  MODIFY,
  NO_MODIFY,
} from "../../shared/constants/array_option_confirm_data";
import IconTooltipArrow from "../../components/IconTooltipArrow/IconTooltipArrow";
import { WARMING_EDIT_CHECK_TITLE } from "../../shared/constants/snackbar_messages";
import { editStatusPath } from "../../shared/constants/edit_container_paths";

const BranchEditContainer: FC = () => {
  const {
    footerActions,
    getRowValues,
    rows,
    totalEdit,
    totalSelected,
    handleOnFilter,
    handleOnSearch,
    showConfirmModal,
    saveBranches,
    openConfirmModal,
    openLoader,
    filterCountries,
    modalActiveFilter,
    itemsPerPage,
    initialLoading,
    selectedPage,
    totalItems,
    showAlert,
    onItemsPerPageChange,
    onPageClick,
    setCountFilters,
    handleOrderByHeader,
    showAlertAction,
    setItemCheckCurrent,
    setEditProcess,
    setAllCheck,
    handleNavigateBranchSelection,
    disableEditButton,
    allBranchesNotModified,
    primaryBtnLabel,
  } = useBranchEditContainerState();

  const contentModal: IConfirmModal = {
    hasContent: true,
    options: [
      {
        name: MODIFY,
        value: Number(
          Number(totalSelected.total) -
            (Number(totalEdit.noModify) + Number(totalEdit.incomplete))
        ),
      },
      {
        name: NO_MODIFY,
        value: Number(totalEdit.noModify),
      },
      {
        name: INCOMPLETE,
        value: Number(totalEdit.incomplete),
      },
    ],
    subtitle: MODAL_CONFIRM.TOTAL_BRANCH(totalSelected.total.toString()),
  };

  return (
    <>
      <Box sx={styles.boxContainer}>
        <Box sx={styles.boxContentTable}>
          <Grid container>
            <Grid item xs={12}>
              <HeaderTable
                allBranchesNotModified={allBranchesNotModified}
                initialFiltersSideBars={INITIAL_EDIT_FILTERS}
                setCountFilters={setCountFilters}
                disableEditButton={disableEditButton}
                handleBtn={handleNavigateBranchSelection}
                filterCountries={filterCountries}
                handleOnSearch={handleOnSearch}
                handleOnFilter={handleOnFilter}
                hasToolTipHeader={false}
                title={BRANCH_LABELS.EDITION_TITLE}
                textValueAlert={WARMING_EDIT_CHECK_TITLE}
                titleBtn={EDIT_BRANCH}
                totalEdit={{
                  incomplete: totalEdit.incomplete,
                  noModify: totalEdit.noModify,
                }}
                totalSelected={{
                  selected: totalSelected.selected,
                  total: totalSelected.total,
                }}
                subtitle={BRANCH_LABELS.EDITION_SUBTITLE}
                showAlert={showAlert}
                showAlertAction={showAlertAction}
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={styles.boxTable}>
                <TableSimple
                  data-testid={"tableCountries"}
                  allowDeletion={false}
                  allowSelection={true}
                  setItemCheckCurrent={setItemCheckCurrent}
                  setEditProcess={setEditProcess}
                  setAllCheck={setAllCheck}
                  getRowValues={getRowValues}
                  isInitialLoading={initialLoading}
                  header={{
                    cells: [
                      {
                        align: "left",
                        component: (
                          <IconTooltipArrow
                            onClick={handleOrderByHeader}
                            valueOrder={"name"}
                          />
                        ),
                        spacing: 0,
                        text: "Nombre",
                        type: "default",
                        width: 136,
                      },
                      {
                        align: "center",
                        component: (
                          <IconTooltipArrow
                            onClick={handleOrderByHeader}
                            valueOrder={"country"}
                          />
                        ),
                        spacing: 0,
                        text: COUNTRY_CONSTITUTION,
                        type: "default",
                        width: 680,
                      },
                      {
                        align: "center",
                        component: (
                          <IconTooltipArrow
                            onClick={handleOrderByHeader}
                            valueOrder={"client_type"}
                          />
                        ),
                        spacing: 0,
                        text: CLIENT_TYPE_LABEL,
                        type: "default",
                        width: 480,
                      },
                      {
                        align: "center",
                        component: (
                          <IconTooltipArrow
                            onClick={handleOrderByHeader}
                            valueOrder={editStatusPath}
                          />
                        ),
                        spacing: 0,
                        text: "Estado",
                        type: "default",
                        width: 340,
                      },
                    ],
                    headerProps: {
                      isDisable: false,
                      labelCheck: "",
                    },
                  }}
                  footerPagination={{
                    isDisable: false,
                    itemsPerPage,
                    onItemsPerPageChange,
                    onPageClick,
                    paginationText: TEXTS_BRANCH_MANUAL.TRANSACTION_PER_PAGE,
                    selectedPage,
                    textFieldSelector: [10, 25, 50],
                    totalItems: totalItems,
                  }}
                  rows={rows}
                  showPagination={rows.length > 0}
                />
              </Box>
            </Grid>
          </Grid>
          <Box sx={styles.boxConfirmModal}>
            <ModalLoader
              descriptionText={LOADER_CREATE_BRANCHES_DESCRIPTION}
              titleText={LOADER_CONFIG_BRANCHES_TITLE}
              isOpen={openLoader}
            />
          </Box>
          <Box sx={styles.boxConfirmModal}>
            <ModalConfirm
              data-testid={"ModalConfirm"}
              onClickPrimary={() => {
                saveBranches();
                showConfirmModal(false);
              }}
              onClickSecondary={() => {
                showConfirmModal(false);
              }}
              buttonPrimary={YES_CONTINUE_TEXT}
              buttonSecondary={CANCEL_TEXT}
              descriptionText={DESCRIPTION_EDIT_MODAL_CONFIRM}
              isOpen={openConfirmModal}
              onClose={() => {
                showConfirmModal(false);
              }}
              titleText={MODAL_EDIT_CONFIRM.TITLE_MODAL_CONFIRM(
                totalSelected.total.toString()
              )}
              contentModal={contentModal}
            />
          </Box>
        </Box>
        <Box sx={styles.boxFooter}>
          <FooterOptions
            primaryButton={footerActions.primaryButton}
            secondaryButton={footerActions.secondaryButton}
            isLoading={footerActions.saveLoader}
            isValidate={false}
            textBtn={primaryBtnLabel}
          />
        </Box>
      </Box>
      <ModalActiveFilter {...modalActiveFilter} />
    </>
  );
};

export default BranchEditContainer;
