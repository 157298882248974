import { styled } from "@mui/styles";
import React from "react";
import {
  Grid,
  Skeleton,
  Stack,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import HelperIcon from "../../../assets/HelperIcon";
import { useRefundsTableState } from "../../RefundsTable/state/useRefundsTableState";
import { infoHelperStyles } from "./InfoHelper.styles";

const InfoHelper: React.FC = () => {
  const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: "none",
    },
  });
  const { isLoadingTable, totalRemoteRefunds } = useRefundsTableState();
  const helperDisclaimer = `Te quedan ${totalRemoteRefunds} operaciones pendientes por revisar`;

  return (
    <Grid item md={4} sx={infoHelperStyles.container}>
      {isLoadingTable ? (
        <Stack spacing={0} data-testid="loading-stack">
          <Skeleton variant="text" width={262} />
          <Skeleton variant="text" width={231} />
        </Stack>
      ) : (
        <>
          <Grid item xs={3} md={1.25} sx={infoHelperStyles.help}>
            <NoMaxWidthTooltip
              title="Los pendientes pertenecen al listado que no ha sido seleccionado aún"
              placement="top"
              arrow
            >
              <Typography>
                <HelperIcon />
              </Typography>
            </NoMaxWidthTooltip>
          </Grid>
          <Grid item xs={9} md={8}>
            <Typography data-testid="indicator" sx={infoHelperStyles.infoText}>
              {helperDisclaimer}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default InfoHelper;
