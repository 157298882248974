import React, { useEffect } from "react";
import {
  mapStatusDeferredMerchant,
  mapStatusMerchant,
  mapStatusMerchantBase,
  PeruDeferredPath,
  StatusDeferred,
  StatusMerchantDeferred,
} from "../../../Deferred.data";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/hooks/storeHook";
import { defaultTo, get, isEmpty, isEqual, set } from "lodash";
import { NodeInfoPath } from "../../../../../shared/enums/NodeInfoPath";
import { Configs } from "../../../../../../types/get_node_info_response";
import { ConfigurationCodeEnum } from "../../../../../shared/enums/ProcessingStepEnum";
import { DeferredOptions } from "../../../../../../types/get_deferred_admin_merchant_response";
import {
  putHierarchyNodeConfig,
  updateMerchantDeferredData,
} from "../../../../../store/thunks/layout/layout.thunks";
import {
  DeferredOption,
  UpdateMerchantRequest,
} from "../../../../../../types/update_merchant_request";
import { useSearchParams } from "react-router-dom";
import { PathParamsEnum } from "../../../../../shared/enums/PathParamsEnum";
import { getBasicMerchantInformation } from "../../../../../shared/utils/local-storage/basic-merchant-information";
import { IBasicMerchantInformation } from "../../../../../shared/infrastructure/interfaces/IBasicMerchantInformation";
import {
  Config,
  UpdateConfigHierarchyRequest,
} from "../../../../../../types/update_config_hierarchy_request";
import {
  setDeferredPeruMassive,
  setSuccessSaveMassiveDeferred,
  showLoadingModal,
} from "../../../../../store/reducers/layout/layout.slice";
import { useSnackbar } from "@kushki/connect-ui";
import { Done, HighlightOffOutlined } from "@mui/icons-material";
import { MessageAlertEnum } from "../../../../../shared/enums/AlertEnum";
import { findConfigById } from "../../../../../containers/ProcessingConfigIndex/state/useOnInitProcessing";
import { DeferredOptionPath } from "../../../../../shared/enums/DeferredOptionPath";
import { isCentralizedBranchDeferred } from "../../../../../utils/branch/branch-util";
import { getDeferredStatusStep } from "../../../../../shared/utils/get-business-rules-status-step";
import { StatusStepEnum } from "../../../../../shared/enums/StatusStepEnum";
import { DeferredPeruEnum } from "../../../../../shared/enums/DeferredStatusEnum";
import {
  MerchantDeferred,
  PeruDeferredOption,
} from "../../../../../../types/merchan_deferred";
import { LocalStoragePathEnum } from "../../../../../shared/infrastructure/enums/LocalStoragePathEnum";
import { evaluateDeferredNotSent } from "../../../../../utils/utilsFile";

export interface IUseDeferredSettingPeState {
  actions: {
    selectStatusDeferred: (event: any) => void;
    selectStatusMerchantDeferred: (event: any) => void;
  };
  statusDeferred: string | undefined;
  statusMerchantDeferred: string | undefined;
  isBranchCentralized: boolean;
  isMassiveBranches: boolean;
  hasUnsentDeferred: boolean;
}

export const useDeferredSettingPEState = (): IUseDeferredSettingPeState => {
  const [searchParams] = useSearchParams();
  const publicMerchantId = searchParams.get(PathParamsEnum.PUBLIC_MERCHANT_ID);
  const {
    nodeInfo,
    deferred,
    snackBarInfo,
    openAlertDeferredPE,
    isMassiveBranches,
    branchEditList,
    successSaveMassiveDeferred,
  } = useAppSelector((store) => store.layout);
  const dispatch = useAppDispatch();
  const { showSnackbar } = useSnackbar();
  const [hasUnsentDeferred, setHasUnsentDeferred] = React.useState<boolean>(
    true
  );
  const [statusDeferred, setStatusDeferred] = React.useState<string>(
    StatusDeferred.STATUS_DISABLED
  );
  const [statusMerchantDeferred, setStatusMerchantDeferred] = React.useState<
    string
  >(StatusMerchantDeferred.STATUS_DISABLED);

  useEffect(() => {
    if (isMassiveBranches)
      dispatch(
        setDeferredPeruMassive({
          statusDeferred,
          statusMerchantDeferred,
        })
      );
  }, [statusMerchantDeferred, statusDeferred]);

  useEffect(() => {
    if (isMassiveBranches) {
      const isDisabledStatus: boolean = isEqual(
        statusDeferred,
        StatusDeferred.STATUS_DISABLED
      );
      const deferred_option: PeruDeferredOption = {
        merchantStatus: isDisabledStatus
          ? StatusDeferred.STATUS_DISABLED
          : mapStatusDeferredMerchant[statusMerchantDeferred],
        status: statusDeferred,
      };

      set(deferred_option, DeferredOptionPath.IsSent, false);

      const country: string = get(branchEditList, "data[0].country", "");
      const new_merchant_deferred_data: MerchantDeferred = {
        country,
        deferredOptions: [deferred_option],
      };

      localStorage.setItem(
        LocalStoragePathEnum.MERCHANT_DEFERRED_DATA,
        JSON.stringify(new_merchant_deferred_data)
      );

      setHasUnsentDeferred(true);
      dispatch(setSuccessSaveMassiveDeferred(false));
    }
  }, [statusMerchantDeferred, statusDeferred]);

  const updateStatus = (options: DeferredOption[]) => {
    const basicMerchantInformation: IBasicMerchantInformation = getBasicMerchantInformation();
    const country: string = get(
      nodeInfo.generalInfo,
      "country",
      basicMerchantInformation.country
    );
    const merchantIdBase: string = get(
      nodeInfo,
      "merchantId",
      basicMerchantInformation.publicMerchantId
    );
    const updateRequest: UpdateMerchantRequest = {
      country: country,
      countryCode: nodeInfo.countryCode,
      deferredOptions: options,
    };
    const merchantID: string = defaultTo(publicMerchantId, merchantIdBase);
    dispatch(
      updateMerchantDeferredData({
        merchantId: merchantID,
        payload: updateRequest,
      })
    );
  };

  const parseConfigsToConfig = (configs: Configs[]): Config[] => {
    const stepSectionStatus: StatusStepEnum = getDeferredStatusStep();

    return configs.map((c: Configs) => {
      return {
        configuration: defaultTo(c.configuration, ""),
        value: defaultTo(c.value, ""),
        status: stepSectionStatus,
        updatedBy: "",
        updatedAt: 0,
      } as Config;
    });
  };

  const updateHierarchyStatus = () => {
    const node010: Configs = findConfigById(
      ConfigurationCodeEnum.DEFERRED,
      get(nodeInfo, NodeInfoPath.Configs, [])
    );
    const deferredValue: string = get(node010, NodeInfoPath.Value, "");
    if (!isEmpty(deferredValue)) {
      const hierarchyRequest: UpdateConfigHierarchyRequest = {
        nodeId: get(nodeInfo, NodeInfoPath.NodeID, ""),
        configs: parseConfigsToConfig(defaultTo([node010], [])),
      };
      dispatch(putHierarchyNodeConfig(hierarchyRequest));
    } else if (!isEmpty(get(nodeInfo, NodeInfoPath.NodeID, ""))) {
      const hierarchyRequest: UpdateConfigHierarchyRequest = {
        nodeId: get(nodeInfo, NodeInfoPath.NodeID, ""),
        configs: [
          {
            configuration: ConfigurationCodeEnum.DEFERRED,
            value: defaultTo(publicMerchantId, ""),
            status: get(node010, "status", ""),
            updatedBy: "",
            updatedAt: 0,
          },
        ],
      };
      dispatch(putHierarchyNodeConfig(hierarchyRequest));
    }
  };

  const selectStatusDeferred = (event: any) => {
    if (deferred[0] !== undefined) {
      const merchantStatus: string = get(
        deferred[0],
        DeferredOptionPath.MerchantStatus,
        ""
      );
      const idOption: string = get(deferred[0], DeferredOptionPath.Id, "");

      const options: DeferredOptions = [];
      options.push({
        merchantStatus: merchantStatus,
        status: event.target.value,
        id: idOption,
        months: [],
        monthsOfGrace: [],
        deferredType: [],
      });
      if (!isMassiveBranches) {
        updateHierarchyStatus();
        updateStatus(options);
      }
    } else {
      const options: DeferredOptions = [];
      options.push({
        merchantStatus: StatusDeferred.STATUS_DISABLED,
        status: event.target.value,
        id: DeferredPeruEnum.ID,
        months: [],
        monthsOfGrace: [],
        deferredType: [],
      });
      if (!isMassiveBranches) {
        updateHierarchyStatus();
        updateStatus(options);
      }
    }
    if (!isMassiveBranches)
      dispatch(
        showLoadingModal({
          isOpen: true,
          message:
            event.target.value === StatusDeferred.STATUS_ENABLED
              ? MessageAlertEnum.LOADING_MESSAGE_ENABLING
              : MessageAlertEnum.LOADING_MESSAGE_DISABLING,
        })
      );
    setStatusDeferred(event.target.value);
  };
  const selectStatusMerchantDeferred = (event: any) => {
    if (deferred[0] !== undefined) {
      const status: string = get(
        deferred[0],
        PeruDeferredPath.deferredStatus,
        ""
      );
      const idOption: string = get(
        deferred[0],
        PeruDeferredPath.deferredId,
        ""
      );

      const options: DeferredOptions = [];
      options.push({
        merchantStatus: mapStatusMerchantBase[event.target.value],
        status: status,
        id: idOption,
        months: [],
        monthsOfGrace: [],
        deferredType: [],
      });
      if (!isMassiveBranches) {
        updateHierarchyStatus();
        updateStatus(options);
      }
    }
    if (!isMassiveBranches) {
      dispatch(
        showLoadingModal({
          isOpen: true,
          message:
            event.target.value === StatusMerchantDeferred.STATUS_ENABLED
              ? MessageAlertEnum.LOADING_MESSAGE_ENABLED
              : MessageAlertEnum.LOADING_MESSAGE_DISABLED,
        })
      );
    }
    setStatusMerchantDeferred(event.target.value);
  };

  const initializeStatus = (deferred: DeferredOptions): void => {
    if (deferred[0] !== undefined) {
      const statusDeferred: string = defaultTo(
        deferred[0].status,
        StatusDeferred.STATUS_DISABLED
      );
      const statusMerchantDeferred: string = defaultTo(
        deferred[0].merchantStatus,
        StatusMerchantDeferred.STATUS_DISABLED
      );
      setStatusDeferred(statusDeferred);
      setStatusMerchantDeferred(mapStatusMerchant[statusMerchantDeferred]);
    }
  };

  useEffect(() => {
    initializeStatus(deferred);
  }, [deferred]);

  useEffect(() => {
    if (snackBarInfo.isOpen && openAlertDeferredPE) {
      showSnackbar({
        message: defaultTo(snackBarInfo.message, ""),
        variant: "simple",
        color: defaultTo(snackBarInfo.color, "info"),
        withIcon: true,
        icon: snackBarInfo.isError ? (
          <HighlightOffOutlined fontSize={"inherit"} color={"inherit"} />
        ) : (
          <Done fontSize={"inherit"} color={"inherit"} />
        ),
      });
    }
  }, [snackBarInfo]);

  useEffect(() => {
    const hasDeferredNotSent: boolean = evaluateDeferredNotSent();
    setHasUnsentDeferred(hasDeferredNotSent);
  }, [successSaveMassiveDeferred]);

  return {
    actions: {
      selectStatusDeferred,
      selectStatusMerchantDeferred,
    },
    statusDeferred,
    statusMerchantDeferred,
    isBranchCentralized: isCentralizedBranchDeferred(nodeInfo),
    isMassiveBranches,
    hasUnsentDeferred,
  };
};
