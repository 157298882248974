/* eslint-disable */
export const environment: {
    kushkiUrl: string;
    envName: string;
    devTools: boolean;
    firebaseConfig: {
        apiKey: string;
        authDomain: string;
        databaseURL: string;
        projectId: string;
        storageBucket: string;
        messagingSenderId: string;
    };
  merchantsBlockedNavigation: {
      merchantId: string;
      routes: string[];
      modules: string[];
    }[];
   complianceHistoric: boolean;
} = {
    kushkiUrl: "https://api.kushkipagos.com",
    envName: "primary",
    devTools: false,
    firebaseConfig: {
        apiKey:'AIzaSyAXtQlq6sXC3-cVMCUvwp6DUC1RteuT4VA',
        authDomain:'kushki-97eba.firebaseapp.com',
        databaseURL: 'https://kushki-97eba.firebaseio.com',
        projectId: 'kushki-97eba',
        storageBucket: 'kushki-97eba.appspot.com',
        messagingSenderId: '821114948879'
    },
    merchantsBlockedNavigation: [{"merchantId":"20000000100798820000","routes":["/transactions","/chargeback","/payouts-transaction"],"modules":["Transacciones"]}],
    complianceHistoric: true
};

