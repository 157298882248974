import { ModalInfo } from "../../store/interfaces/layout/modal-info.interface";
import { isBatchPath } from "../../utils/utilsFile";
import { SaveDeferredModalDescription } from "../enums/MassiveDeferredEnum";
import { userMail } from "../../utils/utilsFile";

const MODAL_DESCRIPTION = {
  PENDING_RULE:
    "Tienes reglas en estado borrador. Debes completar la configuración para concluir la creación del Customer.",
  IN_PROCESS_RULE: (merchantName: string) =>
    `Recuerda que nos has agregado las reglas de negocio para ${merchantName}, pero podrás agregarlas más adelante.`,
  DELETE_PROCESSOR:
    "Si eliminas este diferido el comercio no podrá ofrecer pagos en cuotas a sus clientes.",
  DELETE_RULE:
    "Si eliminas esta regla, las condiciones configuradas dejarán de tener efecto sobre las transacciones de los branches centralizados.",
  DELETE_RULE_STORAGE:
    "Si eliminas esta regla, las condiciones configuradas dejarán de tener efecto sobre las transacciones",
  SAVE_RULE:
    "Esto puede demorar mucho tiempo por lo que el proceso se realizará internamente." +
    "Se enviará al correo user@kushkipagos.com con el detalle de las reglas de negocio cuando el guardado haya concluido.",
  FINISH_RULE:
    "Si finalizas la edición sin guardar, la información que has ingresado se perderán y no se verá reflejada en los branches. Es necesario que guardes los cambios antes de finalizar la edición. ",
  FINISH_EDITION_INCOMPLETE:
    "Si finalizas la edición sin guardar, la información que has ingresado se perderán y no se verá reflejada en los branches. Es necesario que guardes los cambios antes de finalizar la edición.",
  RETURN_MASSIVE_EDITION:
    "Al ejecutar esta acción la información que has ingresado se perderá y no se verá reflejada en los branches. Es necesario que guardes los cambios antes de finalizar la edición.",
  FINISH_EDITION_COMPLETE:
    "Al ejecutar esta acción, todos los datos ingresados quedarán almacenados correctamente.",
};

const PRIMARY_TEXT = {
  PENDING_RULE: "Configurar después",
  IN_PROCESS_RULE: "Aceptar",
  CONTINUE: "Continuar",
  DELETE: "Eliminar diferido",
  AGREED: "Entendido",
  FINISH_EDITION: "Finalizar edición",
  RETURN_MASSIVE_EDITION: "Sí, salir",
  FINISH_EDITION_OK: "Finalizar",
};

const SECONDARY_TEXT = {
  CANCEL: "Cancelar",
  EMPTY_TEXT: "",
};

export const EXPECTED_RESULT = {
  OK: "OK",
  ERROR: "ERROR",
};

const TITLE_TEXT = {
  PENDING_RULE: "Reglas de negocio sin completar",
  IN_PROCESS_RULE: "No haz agregado reglas de negocio",
  EMPTY_DEFERRED: "¿Desea continuar sin agregar diferidos?",
  DELETE_PROCESSOR: "¿Desea eliminar el diferido?",
  DELETE_RULE: "¿Deseas eliminar esta regla?",
  SAVE_RULE: "Se realizará el proceso de guardado de las reglas  de negocio. ",
  FINISH_RULE:
    "No has guardado los reglas de negocio masivos  ¿Estas seguro de finalizar la edición?",
  FINISH_EDITION:
    "No has guardado los procesadores masivos ¿Estas seguro de finalizar la edición?",
  FINISH_EDITION_OK: "¿Estás seguro de finalizar esta edición?",
  RETURN_MASSIVE_EDITION:
    "¿Estás seguro que deseas salir sin guardar los cambios?",
};

export const ACTION_TYPE_MODAL = {
  BATCH: "batch",
  FINISH: "finish",
};

export const RuleModal = {
  IN_PROCESS: (commerceName: string): ModalInfo => {
    return {
      descriptionText: MODAL_DESCRIPTION.IN_PROCESS_RULE(commerceName),
      isOpen: true,
      buttonPrimaryText: PRIMARY_TEXT.IN_PROCESS_RULE,
      buttonSecondaryText: SECONDARY_TEXT.CANCEL,
      titleText: TITLE_TEXT.IN_PROCESS_RULE,
    };
  },
  PENDING: {
    descriptionText: MODAL_DESCRIPTION.PENDING_RULE,
    isOpen: true,
    buttonPrimaryText: PRIMARY_TEXT.PENDING_RULE,
    buttonSecondaryText: SECONDARY_TEXT.CANCEL,
    titleText: TITLE_TEXT.PENDING_RULE,
  },
};

export const DeferredModal = {
  EMPTY_DEFERRED: {
    descriptionText: "",
    isOpen: true,
    buttonPrimaryText: PRIMARY_TEXT.CONTINUE,
    buttonSecondaryText: SECONDARY_TEXT.CANCEL,
    titleText: TITLE_TEXT.EMPTY_DEFERRED,
  },
};

export const ProcessorModal = {
  TEST: {
    descriptionText: "Hello this is other section",
    isOpen: true,
    buttonPrimaryText: "Continue",
    buttonSecondaryText: "Cancel",
    titleText: "Go to next section",
  },
};

export const DeleteProcessorModal = {
  DELETE_PROCESSOR_MODAL: {
    descriptionText: MODAL_DESCRIPTION.DELETE_PROCESSOR,
    isOpen: true,
    buttonPrimaryText: PRIMARY_TEXT.DELETE,
    buttonSecondaryText: SECONDARY_TEXT.CANCEL,
    titleText: TITLE_TEXT.DELETE_PROCESSOR,
  },
};

export const DeleteRuleModalProperties = {
  DELETE_RULE_MODAL: {
    descriptionText: isBatchPath()
      ? MODAL_DESCRIPTION.DELETE_RULE_STORAGE
      : MODAL_DESCRIPTION.DELETE_RULE,
    isOpen: true,
    buttonPrimaryText: PRIMARY_TEXT.IN_PROCESS_RULE,
    buttonSecondaryText: SECONDARY_TEXT.CANCEL,
    titleText: TITLE_TEXT.DELETE_RULE,
  },
};

export const RuleModalProperties = {
  SAVE: {
    descriptionText: SaveDeferredModalDescription(userMail()),
    isOpen: true,
    buttonPrimaryText: PRIMARY_TEXT.AGREED,
    buttonSecondaryText: SECONDARY_TEXT.EMPTY_TEXT,
    titleText: TITLE_TEXT.SAVE_RULE,
    actionType: ACTION_TYPE_MODAL.BATCH,
  },
  FINISH: {
    descriptionText: MODAL_DESCRIPTION.FINISH_RULE,
    isOpen: true,
    buttonPrimaryText: PRIMARY_TEXT.FINISH_EDITION,
    buttonSecondaryText: SECONDARY_TEXT.CANCEL,
    titleText: TITLE_TEXT.FINISH_RULE,
    actionType: ACTION_TYPE_MODAL.FINISH,
  },
};

export const FINISH_EDITION_MODAL_INCOMPLETE: ModalInfo = {
  descriptionText: MODAL_DESCRIPTION.FINISH_EDITION_INCOMPLETE,
  isOpen: true,
  buttonPrimaryText: PRIMARY_TEXT.FINISH_EDITION,
  buttonSecondaryText: SECONDARY_TEXT.CANCEL,
  titleText: TITLE_TEXT.FINISH_EDITION,
};

export const FINISH_EDITION_MODAL_COMPLETE: ModalInfo = {
  descriptionText: MODAL_DESCRIPTION.FINISH_EDITION_COMPLETE,
  isOpen: true,
  buttonPrimaryText: PRIMARY_TEXT.FINISH_EDITION_OK,
  buttonSecondaryText: SECONDARY_TEXT.CANCEL,
  titleText: TITLE_TEXT.FINISH_EDITION_OK,
};

export const RETURN_MASSIVE_EDITION_MODAL: ModalInfo = {
  descriptionText: MODAL_DESCRIPTION.RETURN_MASSIVE_EDITION,
  isOpen: true,
  buttonPrimaryText: PRIMARY_TEXT.RETURN_MASSIVE_EDITION,
  buttonSecondaryText: SECONDARY_TEXT.CANCEL,
  titleText: TITLE_TEXT.RETURN_MASSIVE_EDITION,
};
