import { HeaderTableLabel } from "@shared/enum/HeaderTableLabel";
import { ColumnsItem } from "@kushki/connect-ui";
import { SourceEnum } from "@shared/utils/activeAlarmsEnum";
import { cloneDeep } from "lodash";
import { AlarmFieldNameEnum } from "./AlarmsTableCells";

export interface SortColumnsItem extends ColumnsItem {
  isSort?: boolean;
  sortField?: string;
}

const initialColumns: SortColumnsItem[] = [
  {
    field: HeaderTableLabel.ACTIVATION_DATE,
    isDisabled: false,
    isSelected: true,
    isSort: true,
    label: HeaderTableLabel.ACTIVATION_DATE,
    sortField: AlarmFieldNameEnum.ACTIVATION_DATE,
  },
  {
    field: HeaderTableLabel.ALIAS,
    isDisabled: false,
    isSelected: true,
    isSort: true,
    label: HeaderTableLabel.ALIAS,
    sortField: AlarmFieldNameEnum.ALIAS,
  },
  {
    field: HeaderTableLabel.BRANCH_MERCHANT,
    isDisabled: false,
    isSelected: true,
    isSort: true,
    label: HeaderTableLabel.BRANCH_MERCHANT,
    sortField: AlarmFieldNameEnum.BRANCH_MERCHANT,
  },
  {
    field: HeaderTableLabel.OWNER,
    isDisabled: false,
    isSelected: false,
    isSort: true,
    label: HeaderTableLabel.OWNER,
    sortField: AlarmFieldNameEnum.OWNER,
  },
  {
    field: HeaderTableLabel.CUSTOMER,
    isDisabled: false,
    isSelected: false,
    isSort: true,
    label: HeaderTableLabel.CUSTOMER,
    sortField: AlarmFieldNameEnum.CUSTOMER,
  },
  {
    field: HeaderTableLabel.CATEGORY,
    isDisabled: false,
    isSelected: false,
    isSort: true,
    label: HeaderTableLabel.CATEGORY,
    sortField: AlarmFieldNameEnum.CATEGORY,
  },
  {
    field: HeaderTableLabel.COUNTRY,
    isDisabled: false,
    isSelected: true,
    isSort: true,
    label: HeaderTableLabel.COUNTRY,
    sortField: AlarmFieldNameEnum.COUNTRY,
  },
  {
    field: HeaderTableLabel.FREQUENCY,
    isDisabled: false,
    isSelected: false,
    isSort: true,
    label: HeaderTableLabel.FREQUENCY,
    sortField: AlarmFieldNameEnum.FREQUENCY,
  },
  {
    field: HeaderTableLabel.VARIABLES,
    isDisabled: false,
    isSelected: true,
    label: HeaderTableLabel.VARIABLES,
  },
  {
    field: HeaderTableLabel.STATE,
    isDisabled: false,
    isSelected: true,
    isSort: true,
    label: HeaderTableLabel.STATE,
    sortField: AlarmFieldNameEnum.STATE,
  },
];

const historyColumns: ColumnsItem[] = [
  ...cloneDeep(initialColumns),
  {
    field: HeaderTableLabel.CLOSE_DATE,
    isDisabled: false,
    isSelected: false,
    isSort: true,
    label: HeaderTableLabel.CLOSE_DATE,
    sortField: AlarmFieldNameEnum.CLOSE_DATE,
  },
];

const columnsBySource: object = {
  [SourceEnum.ALARMS]: initialColumns,
  [SourceEnum.HISTORY]: historyColumns,
};

export { columnsBySource };
