import React from "react";
import { Typography } from "@mui/material";
import { IUseMetricsContainer } from "./useMetricsContainer.interfaces";
import { useChannelMetricsContext } from "../context/useChannelMetricsContext";
import { IResumeBoxProps } from "../../../components/ResumeBox/ResumeBox.interfaces";
import {
  ResumeBarLabels,
  ResumeBarTooltips,
} from "../../../shared/constants/ResumeBar.labels";
import { useAppSelector } from "../../../store/hooks/storeHook";
import { selectMetricsResponse } from "../../../store/selectors/selectors";
import { formatAmount } from "../../../shared/utils/currency-util";

export const useMetricsContainer = (): IUseMetricsContainer => {
  const {
    isEmptyResults,
    isMobile,
    isLoading,
    emptyResultMessage,
    metricsFilters: { countryFilter },
    showChartBar,
  } = useChannelMetricsContext();
  const metrics = useAppSelector(selectMetricsResponse);

  const resumeBarItems: IResumeBoxProps[] = [
    {
      children: (
        <Typography variant="h5" color="text.grey">
          {metrics
            ? formatAmount(
                metrics.aggregators.counters.commission,
                countryFilter
              )
            : "0"}
        </Typography>
      ),
      title: ResumeBarLabels.COMMISSION,
      tooltip: ResumeBarTooltips[ResumeBarLabels.COMMISSION],
    },
    {
      children: (
        <Typography variant="h5" color="text.grey">
          {metrics ? metrics.aggregators.counters.totalTransactions : 0}
        </Typography>
      ),
      title: ResumeBarLabels.TOTAL_TRX,
      tooltip: ResumeBarTooltips[ResumeBarLabels.TOTAL_TRX],
    },
    {
      children: (
        <Typography variant="h5" color="text.grey">
          {metrics ? metrics.aggregators.counters.totalMerchants : 0}
        </Typography>
      ),
      title: ResumeBarLabels.MERCHANTS,
      tooltip: ResumeBarTooltips[ResumeBarLabels.MERCHANTS],
    },
    {
      children: (
        <Typography variant="h5" color="text.grey">
          {metrics
            ? formatAmount(
                metrics.aggregators.totals.totalTransactionsAmount,
                countryFilter
              )
            : "0"}
        </Typography>
      ),
      title: ResumeBarLabels.TOTAL_AMOUNT,
      tooltip: ResumeBarTooltips[ResumeBarLabels.TOTAL_AMOUNT],
    },
  ];

  return {
    emptyResultMessage,
    isEmptyResults,
    isLoading,
    isMobile,
    resumeBarItems,
    showChartBar,
  };
};
