import React from "react";
import { Grid, Typography } from "@mui/material";
import FormControl from "../../../FormControl/FormControl";
import { CountryEnum } from "../../../../shared/countryEnum";
import { IMultipleFormValues } from "../../../../shared/constants/initial_state_legal_details";
import { Control } from "react-hook-form";

export type FormItem = {
  constitutionalCountry?: CountryEnum;
  control?: Control;
  country?: CountryEnum;
  inputComponent: string;
  isDisable?: boolean;
  label: string;
  md: number;
  name: string;
  options?: IMultipleFormValues[];
  rules?: object;
  xs: number;
  values?: IMultipleFormValues[];
};

type Props = {
  formItems: FormItem[];
  subtitle?: string;
};

const BuildableForm: React.FC<Props> = ({ formItems, subtitle }: Props) => {
  return (
    <Grid container rowSpacing={2} columnSpacing={1}>
      <Grid item xs={12}>
        <Typography variant={"body2"} color={"text.dark"}>
          {subtitle}
        </Typography>
      </Grid>
      {formItems.map((formItem, index) => (
        <Grid
          item
          xs={formItem.xs}
          md={formItem.md}
          key={`form-item-${formItem.name}-${index}`}
        >
          <FormControl
            control={formItem.control!}
            inputComponent={formItem.inputComponent}
            label={formItem.label}
            name={formItem.name}
            isDisable={formItem.isDisable}
            rules={formItem.rules}
            values={formItem.options}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default BuildableForm;
