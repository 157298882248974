import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { ICustomBreadcrumbs } from "./CustomBreadcrumbs.interfaces";
import { customBreadcrumbsStyles } from "./CustomBreadcrumbs.styles";

const CustomBreadcrumbs: React.FC<ICustomBreadcrumbs> = (
  props: ICustomBreadcrumbs
) => {
  const { routes } = props;
  const lengthRoutes = routes.length;

  return (
    <Breadcrumbs
      data-testid="breadcrumbs"
      separator=">"
      sx={customBreadcrumbsStyles.main}
    >
      {routes.map((value, index) =>
        index === lengthRoutes - 1 ? (
          <Typography
            data-testid="lastItem"
            key={`lastItem-${index}`}
            sx={customBreadcrumbsStyles.item}
          >
            {value.title}
          </Typography>
        ) : (
          <Link
            key={`link-${index}`}
            data-testid="link"
            underline="hover"
            href={value.to}
            sx={customBreadcrumbsStyles.item}
          >
            {value.title}
          </Link>
        )
      )}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;
