import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BRANCH_DATA } from "../../../shared/constants/branch_data";
import { ConfigIdEnum } from "../../../shared/enums/configIdEnums";
import { CountryEnum } from "../../../shared/enums/countryEnum";
import { SessionStoragePaths } from "../../../shared/enums/sessionStoragePaths";
import { handleSetSelectedRows } from "../../../store/actions/tableData.actions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { getNodeInfo } from "../../../store/thunks/createNode/createNode.thunks";
import { getSessionStorageValueByPath } from "../../../store/utils/sessionStorage-utils";
import { IButtonOptions } from "../../FooterOptions/FooterOptions.interfaces";
import {
  BranchCreation,
  IBranchToEdit,
  ITotalBranches,
  ITotalBranchesNumbers,
  IUseBranchManualCreationForm,
} from "./useBranchManualCreation.interfaces";
import { cloneDeep, flatMap, get, isEmpty, isNil, set } from "lodash";
import { useDispatch } from "react-redux";
import { ITableRowProps } from "../../Table/TableSimple/interfaces";
import {
  ItemCategoryItem,
  ItemCategoryProps,
} from "../../Filters/ItemCategory/interfaces";
import { IValueCell } from "../../TableCell/CellHeader/interfaces";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import {
  BRANCH_NAME,
  CLIENT_TYPE_LABEL,
  COUNTRY_CONSTITUTION,
} from "../../../shared/constants/labels/table_labels";
import { ValidationTypeEnum } from "../../TableCell/CellInput/CellInput.enums";
import { ErrorEnum } from "../../../shared/enums/ErrorEnum";
import { TableBodyCellEnum } from "../../Table/TableSimple/constants";
import { ALL_COUNTRIES } from "../../../shared/infrastructure/AllCountries";
import { CLIENT_TYPE } from "../../../shared/infrastructure/ClientType";
import {
  DEFAULT_PATHS,
  LABELS_GROUP_HEADER,
} from "../../../shared/constants/labels/branch_manual_creation_labels";
import { createMassiveBranch } from "../../../store/thunks/massiveBranch/massiveBranchs.thunks";
import { setNotification } from "../../../store/actions/createMerchantNode.actions";
import { useSnackbar } from "@kushki/connect-ui";
import { ICountry } from "../../../shared/infrastructure/Countries";
import {
  DELETE_ROW_SUCCESS,
  MAX_RECORDS_OVER_500,
} from "../../../shared/constants/snackbar_messages";
import { applyFiltersForItems } from "../../../shared/utils/filters_utils";
import {
  setErrorBranches,
  setOpenModalError,
  setRestartCounters,
  setTotalMessages,
} from "../../../store/actions/massiveBranch.actions";
import {
  addMerchantIdToEditList,
  awaitTimeout,
  processCreatedBranches,
  sliceBranchesInBatch,
} from "../../../shared/utils/massiveUtils/massiveUtils";
import { IProcessedBranches } from "../../../store/interfaces/massiveBranch/massiveBranch.interfaces";
import { BatchSizeEnum } from "../../../shared/enums/batchSizeEnum";
export const useBranchManualCreation = (): IUseBranchManualCreationForm => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const { showSnackbar } = useSnackbar();
  const [prepareAddBranches, setPrepareAddBranches] = useState<boolean>(true);
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);
  const [disableContinue, setDisableContinue] = useState<boolean>(true);
  const [addButtonDisable, setAddButtonDisable] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const uDispatch = useDispatch();
  const query = useQuery();
  const publicMerchantId: string = query.get("publicMerchantId") as string;
  const [rows, setRows] = React.useState<ITableRowProps[]>([]);
  const [total, setTotal] = useState<ITotalBranches>({
    centralize: "0",
    descentralize: "0",
    total: "0",
  });
  const [value, setValue] = useState<object>({});
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [rowIdDelete, setRowIdDelete] = useState<number | undefined>(undefined);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [branchesSave, setBranchesSave] = useState<BranchCreation[]>([]);
  const [filterCountries, setFilterCountries] = useState<ItemCategoryItem[]>(
    []
  );
  const navigate = useNavigate();
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [isOnClickPage, setIsOnClickPage] = useState<boolean>(false);
  const [isOnClickTransitions, setIsOnClickTransitions] =
    useState<boolean>(false);
  const [isEmptySearch, setIsEmptySearch] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const showAlertAction = (value: boolean) => {
    setShowAlert(value);
  };
  const [openModalActiveFilter, setOpenModalActiveFilter] = useState(false);
  const changeOpenModalActiveFilter = (value: boolean) =>
    setOpenModalActiveFilter(value);
  const [countFilters, setCountFilters] = useState<number>(0);

  const {
    getNodeInfoResponse,
    statusError,
    totalMessages,
    statusComplete,
    massiveResponse,
    openModalError,
  } = useAppSelector((state) => ({
    ...state.getNodeInfo,
    ...state.massiveBranch,
  }));
  const { notification } = useAppSelector((state) => state.createMerchant);
  const [rowValues, setRowValues] = useState<Array<IValueCell>>([
    {
      error: false,
      id: "",
      rowIndex: 0,
      value: false,
    },
  ]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const defaultBranchName: string = "";
  const listBranchLocal: BranchCreation[] = cloneDeep(
    getSessionStorageValueByPath(SessionStoragePaths.BRANCH_LIST)
  );
  const editBranches: string = `${API_ROUTES.EDIT_BRANCHES}`;

  const getCountryNodeInfo = () => {
    return get(getNodeInfoResponse, "generalInfo.country", "");
  };

  const getConstitutionalCountryNodeInfo = () => {
    return get(getNodeInfoResponse, "generalInfo.constitutionalCountry", "");
  };

  const getClientType = () => {
    return get(getNodeInfoResponse, "generalInfo.clientType", "");
  };

  const idGenerator = (itemPosition: number): string => {
    const date = new Date();

    date.setMilliseconds(date.getMilliseconds() + 10 * (itemPosition + 1));

    return date.getTime().toString();
  };

  const onPageClick = (page: number) => {
    setSelectedPage(page);
    setIsOnClickPage(true);
    setIsOnClickTransitions(false);
    setIsEmptySearch(false);
  };

  const validatePages = (rowsLength: number, limit: number) => {
    const page: number = rows.length / itemsPerPage;

    if (rowsLength > limit) {
      onPageClick(Math.floor(page) + 1);
    }
    if (rowsLength <= limit) {
      setSelectedPage(Math.floor(page));
    }
  };

  const addRow = (listBranch: BranchCreation[]) => {
    const rowsState: ITableRowProps[] = [];
    const rowSelected: ITableRowProps[] = [];

    for (let i in listBranch) {
      let rowData: ITableRowProps = {
        cells: [
          {
            props: {
              cellProps: {
                align: "center",
                minWidth: 8,
                spacing: 1,
              },
              defaultValue: get(listBranch[i], "branchName", "").toUpperCase(),
              id: "name",
              label: BRANCH_NAME,
              rowIndex: Number(i),
              upperCase: true,
              validations: [
                {
                  errorMessage: "Alfanumérico",
                  regex: /^[A-Z\d](?!.*?\s$)[A-Z\d\s]{0,254}$/g,
                  type: ValidationTypeEnum.regex,
                },
                {
                  errorMessage: "Requerido",
                  type: ValidationTypeEnum.required,
                },
                {
                  errorMessage: ErrorEnum.repeated_name,
                  type: ValidationTypeEnum.item_repeated,
                },
              ],
            },
            type: TableBodyCellEnum.INPUT_TEXT,
          },
          {
            props: {
              cellProps: {
                align: "center",
                spacing: 1,
              },
              defaultValue: listBranch[i].centralize
                ? getConstitutionalCountryNodeInfo()
                : listBranch[i].constitutionalCountry,
              id: "country",
              isCountry: true,
              isDisabled: listBranch[i].centralize,
              items: ALL_COUNTRIES,
              label: COUNTRY_CONSTITUTION,
              required: true,
              rowIndex: Number(i),
              showAlertAction,
            },
            type: TableBodyCellEnum.SELECT_ITEM,
          },
          {
            props: {
              cellProps: {
                align: "center",
                spacing: 1,
              },
              defaultValue: listBranch[i].centralize
                ? getClientType()
                : listBranch[i].clientType,
              id: "client",
              isDisabled: listBranch[i].centralize,
              items: CLIENT_TYPE,
              label: CLIENT_TYPE_LABEL,
              required: true,
              rowIndex: Number(i),
              showAlertAction,
            },
            type: TableBodyCellEnum.SELECT_ITEM,
          },
        ],
        id: idGenerator(Number(i)),
        rowProps: {
          hidden: false,
          isSelected: !listBranch[i].centralize,
          labelCheck: {
            check: LABELS_GROUP_HEADER.DESCENTRALIZE,
            unCheck: LABELS_GROUP_HEADER.CENTRALIZE,
          },
        },
      };

      if (!listBranch[i].centralize) {
        rowSelected.push(rowData);
      }
      rowsState.push(rowData);
    }

    dispatch(handleSetSelectedRows(rowSelected));
    setRows(rowsState);
    if (rowsState.length >= BRANCH_DATA.MAX_BRANCH) {
      showSnackbar(MAX_RECORDS_OVER_500);
      setAddButtonDisable(true);
    }
    setIsOnClickPage(false);
  };

  const addRowInitialValues = () => {
    const branch: BranchCreation[] = [...listBranchLocal];

    branch.push({
      branchName: defaultBranchName,
      centralize: true,
      clientType: getClientType(),
      constitutionalCountry: getCountryNodeInfo(),
    });

    addRow(branch);
  };

  const handleDeleteRowHook = (rowId: number) => {
    setIsDeleting(true);

    setRowIdDelete(rowId);
    setIsEmptySearch(false);
    showSnackbar(DELETE_ROW_SUCCESS);
  };

  const mappingIdsTable = (itemValue: IValueCell) => {
    const mapperIds = {
      [`${itemValue.rowIndex}-table-check`]: "centralize",
      [`${itemValue.rowIndex}-name`]: "branchName",
      [`${itemValue.rowIndex}-country`]: "constitutionalCountry",
      [`${itemValue.rowIndex}-client`]: "clientType",
    };

    return mapperIds[itemValue.id];
  };

  const createDataBranch = (elements: IValueCell[][]) => {
    const branches: BranchCreation[] = [];

    elements.map((item: IValueCell[]) => {
      if (item.length === 4) {
        let branch: BranchCreation = {
          branchName: "",
          centralize: false,
          clientType: "",
          constitutionalCountry: "",
        };

        item.map((itemValues: IValueCell) => {
          branch[mappingIdsTable(itemValues)] =
            typeof itemValues.value === "string"
              ? itemValues.value
              : !itemValues.value;
        });

        branches.push(branch);
      }
    });
    sessionStorage.setItem(
      SessionStoragePaths.BRANCH_LIST,
      JSON.stringify(branches)
    );
    setBranchesSave(branches);
  };

  const findNameInAllCountries = (countryValue: string): string => {
    const country: ICountry | undefined = ALL_COUNTRIES.find(
      (country: ICountry) => country.value === countryValue
    );

    return country ? country.name : "Country no found";
  };

  const createItemsFilter = (elements: IValueCell[][]) => {
    const countries: string[] = [];
    const newCountriesFilter: ItemCategoryItem[] = [];
    let uniqueCountries: string[];

    elements.map((item: IValueCell[]) => {
      if (item.length === 4) {
        countries.push(item[2].value as string);
      }
    });
    uniqueCountries = countries.filter(
      (elem: string, index: number, self: string[]) =>
        index === self.indexOf(elem)
    );
    uniqueCountries.map((country: string) => {
      switch (country) {
        case "Mexico":
          newCountriesFilter.push({
            label: CountryEnum.mexico,
            selected: false,
            value: country,
          });
          break;
        case "Panama":
          newCountriesFilter.push({
            label: CountryEnum.panama,
            selected: false,
            value: country,
          });
          break;
        case "Peru":
          newCountriesFilter.push({
            label: "Perú",
            selected: false,
            value: country,
          });
          break;
        default:
          newCountriesFilter.push({
            label: findNameInAllCountries(country),
            selected: false,
            value: country,
          });
          break;
      }
    });
    setFilterCountries(newCountriesFilter);
  };

  const getArrayValueCell = (items: object) =>
    Object.keys(items)
      .map((key: string) => {
        return items[key];
      })
      .filter((item) => item.length === 4);

  const getRowValues = (valueFromTable: object) => {
    const elements: IValueCell[][] = getArrayValueCell(valueFromTable);

    const totalValues: ITotalBranchesNumbers = {
      centralize: 0,
      descentralize: 0,
      total: 0,
    };

    const flatenItems: IValueCell[] = flatMap(elements, (element) => element);
    const checkedItems: IValueCell[] = flatenItems.filter(
      (item: IValueCell) => item.id.includes("table-check") && item.value
    );

    totalValues.descentralize = checkedItems.length;
    totalValues.centralize = elements.length - checkedItems.length;
    totalValues.total = elements.length;

    setTotal({
      centralize: totalValues.centralize + "",
      descentralize: totalValues.descentralize + "",
      total: totalValues.total + "",
    });
    setValue(valueFromTable);

    if (!isEmpty(elements)) {
      createDataBranch(elements);
      createItemsFilter(elements);

      const element: Array<IValueCell> = getArrayValueCell(valueFromTable);

      setRowValues(element);
    }
  };

  const updateAllRows = () => {
    let range = {
      max: itemsPerPage * selectedPage - 1,
      min: itemsPerPage * selectedPage - itemsPerPage,
    };

    if (isEmptySearch) {
      range = {
        max: itemsPerPage * 1 - 1,
        min: 0,
      };
    }
    const rowIndexShowed: number[] = [];
    const getRowShow: ITableRowProps[] = [];

    rows.map((item: ITableRowProps) => {
      if (
        get(item, DEFAULT_PATHS.CELL_ROW_INDEX) <= range.max &&
        get(item, DEFAULT_PATHS.CELL_ROW_INDEX) >= range.min
      ) {
        rowIndexShowed.push(get(item, DEFAULT_PATHS.CELL_ROW_INDEX));
      }
    });
    rows.map((item: ITableRowProps) => {
      let rowsItem: ITableRowProps;

      if (rowIndexShowed.includes(get(item, DEFAULT_PATHS.CELL_ROW_INDEX))) {
        rowsItem = {
          ...item,
          rowProps: { ...item.rowProps, hidden: false },
        };
        getRowShow.push(rowsItem);
      } else {
        rowsItem = {
          ...item,
          rowProps: { ...item.rowProps, hidden: true },
        };
        getRowShow.push(rowsItem);
      }
    });
    setRows(getRowShow);
  };

  const handleOnFilter = (filters: ItemCategoryProps[]) => {
    const valueFilters: IValueCell[][] = getArrayValueCell(value);
    let valueElementsFound: IValueCell[][] = [];
    const valuesIndex: number[] = [];
    let allowFilters: boolean = filters.some((elem) => !isEmpty(elem.items));

    const rowsCopy: ITableRowProps[] = [];

    if (allowFilters) {
      for (let i = 0; i < filters.length; i++) {
        if (i === 0) {
          valueElementsFound = applyFiltersForItems(filters[i], valueFilters);
        } else {
          valueElementsFound = applyFiltersForItems(
            filters[i],
            valueElementsFound
          );
        }
      }

      valueElementsFound.map((value) => {
        valuesIndex.push(value[0].rowIndex);
      });

      for (let i in rows) {
        const rowsItem: ITableRowProps = {
          ...rows[i],
          rowProps: {
            hidden: true,
            isSelected: get(rows[i], "rowProps.isSelected"),
            labelCheck: {
              check: LABELS_GROUP_HEADER.DESCENTRALIZE,
              unCheck: LABELS_GROUP_HEADER.CENTRALIZE,
            },
          },
        };

        rowsCopy.push(rowsItem);
      }

      rowsCopy.map((cell) => {
        if (valuesIndex.length > 0) {
          if (!valuesIndex.includes(get(cell, DEFAULT_PATHS.CELL_ROW_INDEX))) {
            set(cell, DEFAULT_PATHS.HIDDEN, true);
          } else {
            set(cell, DEFAULT_PATHS.HIDDEN, false);
          }
        } else {
          set(cell, DEFAULT_PATHS.HIDDEN, true);
        }
      });
      setRows(rowsCopy);
      const filterRowsHi: ITableRowProps[] = rowsCopy.filter(
        (row: ITableRowProps) => row.rowProps.hidden !== true
      );
      const totalSearch: ITotalBranchesNumbers = {
        centralize: 0,
        descentralize: 0,
        total: filterRowsHi.length,
      };

      filterRowsHi.map((item) => {
        if (item.rowProps.isSelected) totalSearch.descentralize += 1;
        else totalSearch.centralize += 1;
      });
      setTotal({
        centralize: totalSearch.centralize.toString(),
        descentralize: totalSearch.descentralize.toString(),
        total: totalSearch.total.toString(),
      });

      setTotalItems(filterRowsHi.length);
    } else {
      setTotalItems(rows.length);
      for (let i in rows) {
        const rowsItem: ITableRowProps = {
          ...rows[i],
          rowProps: {
            hidden: false,
            isSelected: get(rows[i], "rowProps.isSelected"),
            labelCheck: {
              check: LABELS_GROUP_HEADER.DESCENTRALIZE,
              unCheck: LABELS_GROUP_HEADER.CENTRALIZE,
            },
          },
        };

        rowsCopy.push(rowsItem);
      }
      onPageClick(selectedPage);
      setRows(rowsCopy);
      updateAllRows();

      const totalSearchElse: ITotalBranchesNumbers = {
        centralize: 0,
        descentralize: 0,
        total: rowsCopy.length,
      };

      rowsCopy.map((item) => {
        if (item.rowProps.isSelected) totalSearchElse.descentralize += 1;
        else totalSearchElse.centralize += 1;
      });

      setTotal({
        centralize: totalSearchElse.centralize.toString(),
        descentralize: totalSearchElse.descentralize.toString(),
        total: totalSearchElse.total.toString(),
      });
    }
  };

  function validateRowErrors(rowValues: Array<IValueCell>): boolean {
    let disableValidation: boolean = true;

    for (let item of rowValues) {
      const element: Array<IValueCell> = Object.keys(item).map(
        (key: string) => {
          return item[key];
        }
      );

      for (let value of element) {
        if (!value.error) {
          disableValidation = false;
        } else {
          return (disableValidation = true);
        }
      }
    }

    return disableValidation;
  }

  function validateEmptyName(rowValues: Array<IValueCell>): boolean {
    let disableValidation: boolean = true;

    for (let item of rowValues) {
      const element: Array<IValueCell> = Object.keys(item).map(
        (key: string) => {
          return item[key];
        }
      );

      for (let value of element) {
        if (value.id) {
          if (value.id.includes("name")) {
            if (isEmpty(value.value)) {
              return (disableValidation = true);
            } else {
              disableValidation = false;
            }
          }
        }
      }
    }

    return disableValidation;
  }

  const handleAddBranches = (option: boolean) => {
    sessionStorage.setItem(SessionStoragePaths.BRANCH_LIST, JSON.stringify([]));
    setPrepareAddBranches(option);
    setIsEmptySearch(false);
  };

  const primaryButtonAction = () => {
    if (countFilters > 0) changeOpenModalActiveFilter(true);
    else {
      setOpenConfirmModal(true);
    }
  };

  const buildRedirect = () => {
    sessionStorage.setItem(SessionStoragePaths.BRANCH_LIST, JSON.stringify([]));
    window.location.href = `/create-node/branch/options?publicMerchantId=${publicMerchantId}`;
  };

  const showConfirmModal = (isOpen: boolean) => {
    setOpenConfirmModal(isOpen);
  };

  const saveBranches = () => {
    const batchBranches: BranchCreation[][] = sliceBranchesInBatch(
      branchesSave,
      BatchSizeEnum.LIMIT
    );

    dispatch(setTotalMessages(batchBranches.length));
    batchBranches.forEach((batch: BranchCreation[]) => {
      uDispatch(createMassiveBranch(batch, publicMerchantId));
    });

    setOpenLoader(true);
  };

  const primaryButton: IButtonOptions = {
    isDisabled: disableContinue,
    isHidden: true,
    onAction: () => primaryButtonAction(),
  };

  const secondaryButton: IButtonOptions = {
    isDisabled: false,
    isHidden: false,
    onAction: () => buildRedirect(),
  };

  const handleOnSearch = (name: string) => {
    const valueElements: IValueCell[][] = getArrayValueCell(value);
    const rowsCopy: ITableRowProps[] = [];
    const totalValues: ITotalBranchesNumbers = {
      centralize: 0,
      descentralize: 0,
      total: 0,
    };

    for (let i in rows) {
      const rowsItem: ITableRowProps = {
        ...rows[i],
        rowProps: {
          ...rows[i].rowProps,
          hidden: true,
        },
      };

      rowsCopy.push(rowsItem);
    }

    if (!isEmpty(name)) {
      valueElements.forEach((item: IValueCell[]) => {
        item.forEach((elementRow) => {
          if (
            elementRow.id.includes("name") &&
            `${elementRow.value}`.includes(name)
          ) {
            rowsCopy.forEach((cell) => {
              if (
                elementRow.rowIndex === get(cell, DEFAULT_PATHS.CELL_ROW_INDEX)
              ) {
                set(cell, DEFAULT_PATHS.HIDDEN, false);
              }
            });
            item[0].value
              ? (totalValues.descentralize += 1)
              : (totalValues.centralize += 1);
            totalValues.total += 1;
          }
        });
      });
    } else {
      valueElements.map((item: IValueCell[]) => {
        rowsCopy.map((cell) => {
          set(cell, DEFAULT_PATHS.HIDDEN, false);
        });
        if (item[0].id === `${item[0].rowIndex}-table-check`) {
          item[0].value
            ? (totalValues.descentralize += 1)
            : (totalValues.centralize += 1);
        }
        totalValues.total = valueElements.length;
      });
    }
    setTotal({
      centralize: totalValues.centralize + "",
      descentralize: totalValues.descentralize + "",
      total: totalValues.total + "",
    });
    setRows(rowsCopy);
    const filterRowsHi: ITableRowProps[] = rowsCopy.filter(
      (row: ITableRowProps) => row.rowProps.hidden !== true
    );

    setTotalItems(filterRowsHi.length);
    if (name.length <= 0) {
      onPageClick(1);
      setIsEmptySearch(true);
    }
  };

  const onItemsPerPageChange = (value: number) => {
    setSelectedPage(1);
    setItemsPerPage(value);
    setIsOnClickPage(false);
    setIsOnClickTransitions(true);
    setIsEmptySearch(false);
  };

  const handleConfirmModalActiveFilter = () => {
    setOpenConfirmModal(true);
  };

  const handleCloseModalError = () => {
    dispatch(setOpenModalError(false));
  };

  useEffect(() => {
    !isNil(publicMerchantId) &&
      dispatch(
        getNodeInfo({ configIds: ConfigIdEnum.CN001, publicMerchantId })
      );

    if (isEmpty(listBranchLocal) && Object.keys(listBranchLocal).length == 0) {
      sessionStorage.setItem(
        SessionStoragePaths.BRANCH_LIST,
        JSON.stringify([])
      );
      setPrepareAddBranches(false);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(get(getNodeInfoResponse, "generalInfo", {}))) {
      if (!isEmpty(getCountryNodeInfo())) {
        setAddButtonDisable(false);
        if (rows.length === 0) {
          if (!isEmpty(listBranchLocal)) {
            addRow(listBranchLocal as BranchCreation[]);
            onPageClick(1);
          } else {
            addRowInitialValues();
          }
        }
      }
    }
  }, [getNodeInfoResponse]);

  useEffect(() => {
    const rowsCopy: ITableRowProps[] = [...rows];

    if (isDeleting && rowIdDelete !== undefined) {
      rowsCopy.splice(rowIdDelete, 1);

      for (let i in rowsCopy) {
        set(rowsCopy[i], "cells[0].props.rowIndex", Number(i));
        set(rowsCopy[i], "cells[1].props.rowIndex", Number(i));
        set(rowsCopy[i], "cells[2].props.rowIndex", Number(i));
      }

      setRows(rowsCopy);
    }
    validatePages(rows.length, itemsPerPage * selectedPage);
    if (rowsCopy.length < BRANCH_DATA.MAX_BRANCH) {
      setAddButtonDisable(false);
    }
  }, [isDeleting]);

  useEffect(() => {
    if (validateEmptyName(rowValues) || validateRowErrors(rowValues)) {
      setDisableContinue(true);
    } else {
      setDisableContinue(false);
      setIsInitialLoading(false);
    }
  }, [rowValues]);

  useEffect(() => {
    (async () => {
      if (totalMessages === statusComplete + statusError) {
        const { catalog, errorBranches }: IProcessedBranches =
          processCreatedBranches(massiveResponse);

        dispatch(setErrorBranches(errorBranches));
        const merchantsToEdit: IBranchToEdit[] =
          addMerchantIdToEditList(catalog);

        const decentralizedBranches: IBranchToEdit[] = merchantsToEdit.filter(
          (branch: IBranchToEdit) => !branch.centralize
        );

        if (errorBranches.length > 0) {
          setOpenLoader(false);
          dispatch(setOpenModalError(true));

          await awaitTimeout(5000);
        }

        if (decentralizedBranches.length > 0) navigate(editBranches);
        else {
          window.location.href = API_ROUTES.CLIENTS(
            get(getNodeInfoResponse, "rootId", ""),
            get(getNodeInfoResponse, "nodeId", "")
          );
        }
        setOpenLoader(false);
        dispatch(setRestartCounters());
      }
    })();
  }, [statusComplete, statusError]);

  useEffect(() => {
    const limit: number = itemsPerPage * selectedPage;

    if (isOnClickPage) {
      setSelectedPage(selectedPage);
      setIsOnClickPage(false);
      setIsOnClickTransitions(false);
    } else {
      validatePages(rows.length, limit);
    }
    if (isOnClickTransitions) {
      onPageClick(1);
      setIsOnClickPage(false);
      setIsOnClickTransitions(false);
    }
    if (isDeleting) {
      setSelectedPage(selectedPage);
      setIsDeleting(false);
    }
    updateAllRows();
  }, [itemsPerPage, selectedPage, rows.length, isEmptySearch]);

  useEffect(() => {
    setTotalItems(rows.length);
  }, [rows.length]);

  useEffect(() => {
    if (notification) {
      showSnackbar(notification);
    }
    dispatch(setNotification(undefined));
  }, [notification]);

  return {
    addButtonDisable,
    addRowInitialValues,
    customerInfo: {
      clientType: getClientType(),
      constitutionalCountry: getConstitutionalCountryNodeInfo(),
    },
    filterCountries,
    footerActions: {
      primaryButton,
      saveLoader: false,
      secondaryButton,
    },
    getRowValues,
    handleAddBranches,
    handleCloseModalError,
    handleDeleteRowHook,
    handleOnFilter,
    handleOnSearch,
    isInitialLoading,
    itemsPerPage,
    modalActiveFilter: {
      handleNextAction: handleConfirmModalActiveFilter,
      openModal: openModalActiveFilter,
      setOpenModal: changeOpenModalActiveFilter,
    },
    onItemsPerPageChange,
    onPageClick,
    openConfirmModal,
    openLoader,
    openModalError,
    prepareAddBranches,
    rows,
    saveBranches,
    selectedPage,
    setCountFilters,
    setIsDeleting,
    setRows,
    showAlert,
    showAlertAction,
    showConfirmModal,
    total,
    totalItems,
  };
};
