import { filterTableComponentStyles as useStyles } from "./FilterTableComponent.interfaces";
import { FilterBar } from "@kushki/connect-ui";
import React from "react";
import { useFilterTableComponentState } from "./state/useFilterTableComponentState";
import { get, isEmpty, isUndefined } from "lodash";
import { LabelEnum } from "../../shared/enums/LabelEnum";
import { IFilterTableComponent } from "../../shared/interfaces/IFilterTableProps";

export const FilterTableComponent: React.FC<IFilterTableComponent> = (
  props: IFilterTableComponent
) => {
  useStyles();
  const { categoryItems, setRequestFilter, changeFilter } =
    useFilterTableComponentState(props);

  if (!isUndefined(categoryItems)) {
    return (
      <FilterBar
        filterDetailBar={{
          filters: [],
          onChangeFilters: changeFilter,
        }}
        filterSideBar={{
          categoryItems,
          filtersCount: 0,
          isMinimized: false,
          isOpen: true,
          onClick: setRequestFilter,
          title: LabelEnum.FILTER_MODAL_TITLE,
        }}
        textFieldSearchBy={{
          items: props.filterSelectList,
          onChange: (event) => {
            if (!isEmpty(get(event, "target.value", "")))
              props.handleDataChange(event.target.value);
          },
          onItemSelected: (selected) => props.handleSelectItem(selected),
          placeholder: "Buscar por alias o procesador",
        }}
      />
    );
  } else {
    return null;
  }
};
