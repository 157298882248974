import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { KshRadioInput } from "../../../Common/KshRadioInput/KshRadioInput";
import { KshInput } from "../../../Common/KshInput/KshInput";
import { KshTextArea } from "../../../Common/KshTextArea/KshTextArea";
import { BoxProps } from "@material-ui/core/Box/Box";
import { defaultTo } from "lodash";
import { EmptyStateTab } from "../../../Common/EmptyStateTab/EmptyStateTab";
import { CountryEnum } from "../../../../shared/infrastructure/CountryEnum";
import {
  chileReferences,
  commonReferences,
} from "../../../../shared/infrastructure/constants/ReferencesContants";

const boxPros = (): BoxProps => ({
  display: "flex",
  justifyContent: "center",
  style: { paddingTop: 20 },
  flexWrap: "no",
});

type TReference = "HES" | "OC" | "Comment" | "NA";

export interface ReferenceProps {
  optionReference?: TReference;
  codeReference?: string;
  comments?: string;
  country?: string;
}

interface ReferenceFunctions {
  handleReferenceData(referenceData: {
    prop: string;
    value?: string | number;
  }): void;
}

export interface OptionLabel {
  label: string;
  value: string;
}

export type IReference = ReferenceProps &
  ReferenceFunctions & { errors?: { [k: string]: boolean } };

export const References = (props: IReference) => {
  const [optionReference, setOptionReference] = useState<
    TReference | undefined
  >(props.optionReference);
  const handleRadioChange = (option: TReference) => {
    setOptionReference(option);
    props.handleReferenceData({
      prop: "optionReference",
      value: option,
    });
  };

  let options: OptionLabel[] = [];
  let isCommonReferences: boolean = true;

  switch (props.country) {
    case CountryEnum.mexico:
    case CountryEnum.ecuador:
    case CountryEnum.peru:
    case CountryEnum.colombia:
      options = commonReferences;
      break;
    case CountryEnum.chile:
      options = chileReferences;
      isCommonReferences = false;
      break;
  }

  return (
    <React.Fragment>
      <Box {...boxPros()}>
        <KshRadioInput
          value={optionReference}
          id="referenceType"
          options={options}
          onChange={({ value }: { value: string }) =>
            handleRadioChange(value as TReference)
          }
          isCommonReferences={isCommonReferences}
        />
      </Box>

      {optionReference === "NA" ? (
        <EmptyStateTab title="Esta factura no tiene referencias" />
      ) : (
        <React.Fragment>
          {optionReference !== "Comment" && (
            <Box {...boxPros()}>
              <KshInput
                label={defaultTo(optionReference, "NA")}
                id="codeReference"
                value={props.codeReference}
                labelWith={8}
                onBlur={props.handleReferenceData}
                maxLength={20}
                error={props.errors?.codeReference}
                labelError="Campo requerido"
              />
            </Box>
          )}
          <Box {...boxPros()}>
            <KshTextArea
              id="comments"
              value={props.comments}
              onBlur={props.handleReferenceData}
              label="Comentarios"
              maxLength={300}
            />
          </Box>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
