import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const stepsLayoutStyles = createNamedStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
    width: "100%",
  },
  stepSection: {
    display: "flex",
    gap: "1rem",
    alignItems: "flex-start",
  },
  label: {
    minWidth: "70px",
  },
});
