import {
  ClickAwayListener,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TableCell,
  Tooltip,
} from "@material-ui/core";
import React, { ReactNode } from "react";
import { get } from "lodash";
import { IHandleOption } from "@kushki/frontend-molecules/backoffice-table";
import { makeStyles } from "@material-ui/core/styles";
import { TransactionData } from "../../../types/transaction_data";

export interface ButtonOptionProps {
  options: Options<TransactionData>[];
  item: TransactionData;
  hidden: boolean;
}
const useStyles = makeStyles({
  containerTable: {
    height: "42vh",
  },
  header: {
    "& .MuiTableSortLabel-root:hover": {
      color: "#023365 !important",
    },
    color: "#023365",
  },
  icon: {
    background: "#F0F4F9",
    borderRadius: "4px",
    margin: "2px",
  },
  paper: {
    backgroundColor: "#FFFFFF",
  },
  table: {},
  toolbar: {
    backgroundColor: "#F7FAFC",
    borderRadius: "4px 4px 0px 0px",
  },
});

type TFontColor = "danger" | "default";
type TEditOptions<TransactionData> = (item: TransactionData) => void;

export interface Options<TransactionData> {
  disabled?: boolean;
  title: string;
  icon: ReactNode;
  handleOnMoreOptions:
    | IHandleOption<TransactionData>[]
    | TEditOptions<TransactionData>;
}
export const ButtonOption: React.FC<ButtonOptionProps> = (
  props: ButtonOptionProps
) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open: boolean = Boolean(anchorEl);
  const [selectedRow, setSelectedRow] = React.useState<TransactionData | null>(
    null
  );
  const onClickAway = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleOptions = (e: any, row: TransactionData) => {
    setSelectedRow(row);
    handleClick(e);
    e.preventDefault();
    e.stopPropagation();
  };
  const styledMenuItem = (props: TFontColor): string => {
    switch (props) {
      case "danger":
        return "#D62C4B";

      default:
        return "#293036";
    }
  };

  return (
    <React.Fragment>
      {props.options && (
        <TableCell align="left" hidden={!props.hidden}>
          {props.options.map((option: Options<TransactionData>) => {
            return (
              <React.Fragment key={option.title}>
                <Tooltip title={option.title}>
                  <IconButton
                    disabled={get(option, "disabled", false)}
                    onClick={(e) => {
                      if (Array.isArray(option.handleOnMoreOptions)) {
                        handleOptions(e, props.item);
                      } else {
                        option.handleOnMoreOptions(props.item);
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    }}
                    className={classes.icon}
                    size="small"
                  >
                    {option.icon}
                  </IconButton>
                </Tooltip>
                {selectedRow === props.item &&
                  Array.isArray(option.handleOnMoreOptions) && (
                    <Popper
                      open={open}
                      anchorEl={anchorEl}
                      style={{ zIndex: 5 }}
                    >
                      <Paper>
                        {option.handleOnMoreOptions.map(
                          (op: IHandleOption<TransactionData>) => {
                            return (
                              <ClickAwayListener
                                onClickAway={onClickAway}
                                key={option.title}
                              >
                                <MenuList
                                  onClick={(e) => {
                                    op.method(props.item);
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }}
                                  hidden={op.disabled}
                                  id="menu-list-grow"
                                >
                                  <MenuItem
                                    style={{
                                      color: styledMenuItem(op.fontColor),
                                    }}
                                    disabled={op.disabled}
                                  >
                                    {op.name}
                                  </MenuItem>
                                </MenuList>
                              </ClickAwayListener>
                            );
                          }
                        )}
                      </Paper>
                    </Popper>
                  )}
              </React.Fragment>
            );
          })}
        </TableCell>
      )}
    </React.Fragment>
  );
};
