import { useFormContext } from "react-hook-form";
import { Country } from "../../../../../types/country_response";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../../store/reducer";
import { useEffect, useState } from "react";
import { ListSelectCountries } from "../../../../../types/list_select_countries";
import { defaultTo, get, isEmpty, isUndefined } from "lodash";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";
import { IUseCountrySelectedState } from "../../../../shared/infrastructure/interfaces/IUseCountrySelectedState";
import { ICountrySelectedComponentProps } from "../../../../shared/infrastructure/interfaces/ICountrySelectedComponentProps";
import { getWhiteListByCountry } from "../../../../store/actionCreators";
import { FieldsRule } from "../../../../shared/infrastructure/constants/RuleRequestConstants";
import { RuleDetail } from "../../../../../types/rule_detail";
import { checkIsWhiteList } from "../../../../utils/utilsFile";
import { useLocation } from "react-router-dom";

export const useCountrySelectedState = (
  props: ICountrySelectedComponentProps
): IUseCountrySelectedState => {
  const dispatch = useDispatch();
  const { control, errors, setValue, watch } = useFormContext();
  const isUpdatedRule: boolean | undefined = watch("isUpdatedRule");
  const countries: string[] = watch("countries");
  const countries_array: Country[] | undefined = useSelector(
    (state: IAppState) => state.countries
  );
  const ruleDetails: RuleDetail | undefined = useSelector(
    (state: IAppState) => state.ruleDetails
  );
  const [listSelect, setListSelect] = useState<ListSelectCountries[]>([]);

  const isWhiteList = checkIsWhiteList(useLocation().pathname);

  const isSelectedItem = (
    country: Country,
    countriesSelected?: string[]
  ): boolean => {
    return (
      (isUpdatedRule &&
        !isUndefined(countries) &&
        countries.includes(country.name)) ||
      (!isUndefined(countriesSelected) &&
        countriesSelected.includes(country.name))
    );
  };

  const setDefaultItems = (countriesSelected?: string[]): void => {
    const data: ListSelectCountries[] = defaultTo(countries_array, [])
      .filter((country: Country) => country.securityCountry === true)
      .map((country: Country) => {
        const isSelected: boolean = isSelectedItem(country, countriesSelected);

        return {
          selected: isSelected,
          label: country.title,
          value: country.name,
        };
      });

    setListSelect(data);
  };

  const countrySelect = (index: ICategory[]): void => {
    const countriesSelected: string[] = index.map((c) => c.value);

    countriesSelected.length === 0
      ? setValue("countries", undefined)
      : setValue("countries", countriesSelected);

    setDefaultItems(countriesSelected);

    if (!isEmpty(countriesSelected))
      dispatch(
        getWhiteListByCountry({
          countries: countriesSelected,
          configType: FieldsRule.WHITE_LIST,
        })
      );

    if (props.setIsItemCountrySelected) props.setIsItemCountrySelected(true);
  };

  useEffect(() => {
    const countriesRule: string[] = get(ruleDetails, "country", []);
    setDefaultItems(countriesRule.length !== 0 ? countriesRule : undefined);
  }, []);

  useEffect(() => {
    const countriesRule: string[] = get(ruleDetails, "country", []);
    setDefaultItems(countriesRule.length !== 0 ? countriesRule : undefined);
  }, [countries_array]);

  return {
    isUpdatedRule,
    listSelect,
    countries,
    control,
    errors,
    isWhiteList,
    action: {
      countrySelect,
    },
  };
};
