export enum ProcessorsTypeEnum {
  GATEWAY = "GATEWAY",
  AGGREGATOR = "AGGREGATOR",
}

export enum DefaultProcessorType {
  CARD = "card",
  TRANSFER = "transfer",
  PAYOUTS_TRANSFER = "payoutsTransfer",
  TRANSFER_SUBSCRIPTIONS = "achTransfer",
}

export const HasDefaultProcessor: Record<string, boolean> = {
  [DefaultProcessorType.CARD]: true,
  [DefaultProcessorType.TRANSFER]: true,
  [DefaultProcessorType.PAYOUTS_TRANSFER]: true,
  [DefaultProcessorType.TRANSFER_SUBSCRIPTIONS]: true,
};
