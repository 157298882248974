/**
 * Catalog Panama
 */
import { Category } from "../constants/MerchantInformationConstants";
import { CatalogsEnum } from "../infrastructure/catalogs-enum";
import { CurrencyEnum } from "../infrastructure/currency-enum";

export const CatalogHn: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { name: "RNP", value: "0" },
    { name: "RTN", value: "1" },
  ],

  [CatalogsEnum.Banks]: [
    { name: "BANCO ATLÁNTIDA S.A.", value: "1001" },
    { name: "BANCO DE OCCIDENTE S.A.", value: "1002" },
    { name: "BANCO DE LOS TRABAJADORES", value: "1003" },
    { name: "BANCO FINANCIERA CENTROAMERICANA S.A (FICENSA)", value: "1004" },
    { name: "BANCO HONDUREÑO DEL CAFÉ S.A (BANHCAFE)", value: "1005" },
    { name: "BANCO DEL PAÍS S.A.", value: "1006" },
    {
      name: "BANCO FINANCIERA COMERCIAL HONDUREÑA S.A. (FICOHSA)",
      value: "1007",
    },
    { name: "BANCO LAFISE HONDURAS S.A.", value: "1008" },
    { name: "BANCO AZTECA HONDURAS", value: "1009" },
  ],

  [CatalogsEnum.Currency]: [
    { name: "Lempiras", value: CurrencyEnum.HNL },
    { name: "Dólares", value: CurrencyEnum.USD },
  ],

  [CatalogsEnum.BankAccountTypes]: [
    { name: "Corriente", value: "0" },
    { name: "Ahorros", value: "1" },
  ],

  [CatalogsEnum.Provinces]: [
    { name: "Atlántida", value: "01" },
    { name: "Colón", value: "02" },
    { name: "Comayagua", value: "03" },
    { name: "Copán", value: "04" },
    { name: "Cortés", value: "05" },
    { name: "Choluteca", value: "06" },
    { name: "El Paraíso", value: "07" },
    { name: "Francisco Morazán", value: "08" },
    { name: "Gracias a Dios", value: "09" },
    { name: "Intibucá", value: "10" },
    { name: "Islas de la Bahía", value: "11" },
    { name: "La Paz", value: "12" },
    { name: "Lempira", value: "13" },
    { name: "Ocotepeque", value: "14" },
    { name: "Olancho", value: "15" },
    { name: "Santa Bárbara", value: "16" },
    { name: "Valle", value: "17" },
    { name: "Yoro", value: "18" },
  ],

  [CatalogsEnum.Cities]: [
    { name: "La Ceiba", parent: "01", value: "0101" },
    { name: "El Porvenir", parent: "01", value: "0102" },
    { name: "Esparta", parent: "01", value: "0103" },
    { name: "Jutiapa", parent: "01", value: "0104" },
    { name: "La Masica", parent: "01", value: "0105" },
    { name: "San Francisco", parent: "01", value: "0106" },
    { name: "Tela", parent: "01", value: "0107" },
    { name: "Arizona", parent: "01", value: "0108" },
    { name: "Trujillo", parent: "02", value: "0201" },
    { name: "Balfate", parent: "02", value: "0202" },
    { name: "Iriona", parent: "02", value: "0203" },
    { name: "Limón", parent: "02", value: "0204" },
    { name: "Sabá", parent: "02", value: "0205" },
    { name: "Santa Fe", parent: "02", value: "0206" },
    { name: "Santa Rosa de Aguán", parent: "02", value: "0207" },
    { name: "Sonaguera", parent: "02", value: "0208" },
    { name: "Tocoa", parent: "02", value: "0209" },
    { name: "Bonito Oriental", parent: "02", value: "0210" },
    { name: "Comayagua", parent: "03", value: "0301" },
    { name: "Ajuterique", parent: "03", value: "0302" },
    { name: "El Rosario", parent: "03", value: "0303" },
    { name: "Esquías", parent: "03", value: "0304" },
    { name: "Humuya", parent: "03", value: "0305" },
    { name: "La libertad", parent: "03", value: "0306" },
    { name: "Lamaní", parent: "03", value: "0307" },
    { name: "La Trinidad", parent: "03", value: "0308" },
    { name: "Lejamani", parent: "03", value: "0309" },
    { name: "Meambar", parent: "03", value: "0310" },
    { name: "Minas de Oro", parent: "03", value: "0311" },
    { name: "Ojos de Agua", parent: "03", value: "0312" },
    { name: "San Jerónimo", parent: "03", value: "0313" },
    { name: "San José de Comayagua", parent: "03", value: "0314" },
    { name: "San José del Potrero", parent: "03", value: "0315" },
    { name: "San Luis", parent: "03", value: "0316" },
    { name: "San Sebastián", parent: "03", value: "0317" },
    { name: "Siguatepeque", parent: "03", value: "0318" },
    { name: "Villa de San Antonio", parent: "03", value: "0319" },
    { name: "Las Lajas", parent: "03", value: "0320" },
    { name: "Taulabé", parent: "03", value: "0321" },
    { name: "Santa Rosa de Copán", parent: "04", value: "0401" },
    { name: "Cabañas", parent: "04", value: "0402" },
    { name: "Concepción", parent: "04", value: "0403" },
    { name: "Copán Ruinas", parent: "04", value: "0404" },
    { name: "Corquín", parent: "04", value: "0405" },
    { name: "Cucuyagua", parent: "04", value: "0406" },
    { name: "Dolores", parent: "04", value: "0407" },
    { name: "Dulce Nombre", parent: "04", value: "0408" },
    { name: "El Paraíso", parent: "04", value: "0409" },
    { name: "Florida", parent: "04", value: "0410" },
    { name: "La Jigua", parent: "04", value: "0411" },
    { name: "La Unión", parent: "04", value: "0412" },
    { name: "Nueva Arcadia", parent: "04", value: "0413" },
    { name: "San Agustín", parent: "04", value: "0414" },
    { name: "San Antonio", parent: "04", value: "0415" },
    { name: "San Jerónimo", parent: "04", value: "0416" },
    { name: "San José", parent: "04", value: "0417" },
    { name: "San Juan de Opoa", parent: "04", value: "0418" },
    { name: "San Nicolás", parent: "04", value: "0419" },
    { name: "San Pedro", parent: "04", value: "0420" },
    { name: "Santa Rita", parent: "04", value: "0421" },
    { name: "Trinidad de Copán", parent: "04", value: "0422" },
    { name: "Veracruz", parent: "04", value: "0423" },
    { name: "San Pedro Sula", parent: "05", value: "0501" },
    { name: "Choloma", parent: "05", value: "0502" },
    { name: "Omoa", parent: "05", value: "0503" },
    { name: "Pimienta", parent: "05", value: "0504" },
    { name: "Potrerillos", parent: "05", value: "0505" },
    { name: "Puerto Cortés", parent: "05", value: "0506" },
    { name: "San Antonio de Cortés", parent: "05", value: "0507" },
    { name: "San Francisco de Yojoa", parent: "05", value: "0508" },
    { name: "San Manuel", parent: "05", value: "0509" },
    { name: "Santa Cruz de Yojoa", parent: "05", value: "0510" },
    { name: "Villanueva", parent: "05", value: "0511" },
    { name: "La Lima", parent: "05", value: "0512" },
    { name: "Choluteca", parent: "06", value: "0601" },
    { name: "Apacilagua", parent: "06", value: "0602" },
    { name: "Concepción de María", parent: "06", value: "0603" },
    { name: "Duyure", parent: "06", value: "0604" },
    { name: "El Corpus", parent: "06", value: "0605" },
    { name: "El Triunfo", parent: "06", value: "0606" },
    { name: "Marcovia", parent: "06", value: "0607" },
    { name: "Morolica", parent: "06", value: "0608" },
    { name: "Namasigüe", parent: "06", value: "0609" },
    { name: "Orocuina", parent: "06", value: "0610" },
    { name: "Pespire", parent: "06", value: "0611" },
    { name: "San Antonio de Flores", parent: "06", value: "0612" },
    { name: "San Isidro", parent: "06", value: "0613" },
    { name: "San José", parent: "06", value: "0614" },
    { name: "San Marcos de Colón", parent: "06", value: "0615" },
    { name: "Santa Ana de Yusguare", parent: "06", value: "0616" },
    { name: "Yuscarán", parent: "07", value: "0701" },
    { name: "Alauca", parent: "07", value: "0702" },
    { name: "Danlí", parent: "07", value: "0703" },
    { name: "El Paraíso", parent: "07", value: "0704" },
    { name: "Güinope", parent: "07", value: "0705" },
    { name: "Jacaleapa", parent: "07", value: "0706" },
    { name: "Liure", parent: "07", value: "0707" },
    { name: "Morocelí", parent: "07", value: "0708" },
    { name: "Oropolí", parent: "07", value: "0709" },
    { name: "Potrerillos", parent: "07", value: "0710" },
    { name: "San Antonio de Flores", parent: "07", value: "0711" },
    { name: "San Lucas", parent: "07", value: "0712" },
    { name: "San Matías", parent: "07", value: "0713" },
    { name: "Soledad", parent: "07", value: "0714" },
    { name: "Teupasenti", parent: "07", value: "0715" },
    { name: "Texiguat", parent: "07", value: "0716" },
    { name: "Vado Ancho", parent: "07", value: "0717" },
    { name: "Yauyupe", parent: "07", value: "0718" },
    { name: "Trojes", parent: "07", value: "0719" },
    { name: "Tegucigalpa", parent: "08", value: "0801" },
    { name: "Alubarén", parent: "08", value: "0802" },
    { name: "Cedros", parent: "08", value: "0803" },
    { name: "Curarén", parent: "08", value: "0804" },
    { name: "El Porvenir", parent: "08", value: "0805" },
    { name: "Guaimaca", parent: "08", value: "0806" },
    { name: "La Libertad", parent: "08", value: "0807" },
    { name: "La Venta", parent: "08", value: "0808" },
    { name: "Lepaterique", parent: "08", value: "0809" },
    { name: "Maraita", parent: "08", value: "0810" },
    { name: "Marale", parent: "08", value: "0811" },
    { name: "Nueva Armenia", parent: "08", value: "0812" },
    { name: "Ojojona", parent: "08", value: "0813" },
    { name: "Orica", parent: "08", value: "0814" },
    { name: "Reitoca", parent: "08", value: "0815" },
    { name: "Sabanagrande", parent: "08", value: "0816" },
    { name: "San Antonio de Oriente", parent: "08", value: "0817" },
    { name: "San Buenaventura", parent: "08", value: "0818" },
    { name: "San Ignacio", parent: "08", value: "0819" },
    { name: "San Juan de Flores", parent: "08", value: "0820" },
    { name: "San Miguelito", parent: "08", value: "0821" },
    { name: "Santa Ana", parent: "08", value: "0822" },
    { name: "Santa Lucía", parent: "08", value: "0823" },
    { name: "Talanga", parent: "08", value: "0824" },
    { name: "Tatumbla", parent: "08", value: "0826" },
    { name: "Valle de Ángeles", parent: "08", value: "0826" },
    { name: "Villa de San Francisco", parent: "08", value: "0827" },
    { name: "Vallecillo ", parent: "08", value: "0828" },
    { name: "Puerto Lempira", parent: "09", value: "0901" },
    { name: "Brus Laguna", parent: "09", value: "0902" },
    { name: "Ahuas", parent: "09", value: "0903" },
    { name: "Juan Francisco Bulnes", parent: "09", value: "0904" },
    { name: "Ramón Villeda Morales", parent: "09", value: "0905" },
    { name: "Wampusirpe", parent: "09", value: "0906" },
    { name: "La Esperanza", parent: "10", value: "1001" },
    { name: "Camasca", parent: "10", value: "1002" },
    { name: "Colomoncagua", parent: "10", value: "1003" },
    { name: "Concepción", parent: "10", value: "1004" },
    { name: "Dolores", parent: "10", value: "1005" },
    { name: "Intibucá", parent: "10", value: "1006" },
    { name: "Jesús de Otoro", parent: "10", value: "1007" },
    { name: "Magdalena", parent: "10", value: "1008" },
    { name: "Masaguara", parent: "10", value: "1009" },
    { name: "San Antonio", parent: "10", value: "1010" },
    { name: "San Isidro", parent: "10", value: "1011" },
    { name: "San Juan", parent: "10", value: "1012" },
    { name: "San Marcos de la Sierra", parent: "10", value: "1013" },
    { name: "San Miguel Guancapla", parent: "10", value: "1014" },
    { name: "Santa Lucía", parent: "10", value: "1015" },
    { name: "Yamaranguila", parent: "10", value: "1016" },
    { name: "San Francisco de Opalaca", parent: "10", value: "1017" },
    { name: "Roatán", parent: "11", value: "1101" },
    { name: "Guanaja", parent: "11", value: "1102" },
    { name: "José Santos Guardiola", parent: "11", value: "1103" },
    { name: "Utila", parent: "11", value: "1104" },
    { name: "La Paz", parent: "12", value: "1201" },
    { name: "Aguanqueterique", parent: "12", value: "1202" },
    { name: "Cabañas", parent: "12", value: "1203" },
    { name: "Cane", parent: "12", value: "1204" },
    { name: "Chinacla", parent: "12", value: "1205" },
    { name: "Guajiquiro", parent: "12", value: "1206" },
    { name: "Lauterique", parent: "12", value: "1207" },
    { name: "Marcala", parent: "12", value: "1208" },
    { name: "Mercedes de Oriente", parent: "12", value: "1209" },
    { name: "Opatoro", parent: "12", value: "1210" },
    { name: "San Antonio del Norte", parent: "12", value: "1211" },
    { name: "San José", parent: "12", value: "1212" },
    { name: "San Juan", parent: "12", value: "1213" },
    { name: "San Pedro de Tutule", parent: "12", value: "1214" },
    { name: "Santa Ana", parent: "12", value: "1215" },
    { name: "Santa Elena", parent: "12", value: "1216" },
    { name: "Santa María", parent: "12", value: "1217" },
    { name: "Santiago de Puringla", parent: "12", value: "1218" },
    { name: "Yarula", parent: "12", value: "1219" },
    { name: "Gracias", parent: "13", value: "1301" },
    { name: "Belén", parent: "13", value: "1302" },
    { name: "Candelaria", parent: "13", value: "1303" },
    { name: "Cololaca", parent: "13", value: "1304" },
    { name: "Erandique", parent: "13", value: "1305" },
    { name: "Gualcince", parent: "13", value: "1306" },
    { name: "Guarita", parent: "13", value: "1307" },
    { name: "La Campa", parent: "13", value: "1308" },
    { name: "La Iguala", parent: "13", value: "1309" },
    { name: "Las Flores", parent: "13", value: "1310" },
    { name: "La Unión", parent: "13", value: "1311" },
    { name: "La Virtud", parent: "13", value: "1312" },
    { name: "Lepaera", parent: "13", value: "1313" },
    { name: "Mapulaca", parent: "13", value: "1314" },
    { name: "Piraera", parent: "13", value: "1315" },
    { name: "San Andrés", parent: "13", value: "1316" },
    { name: "San Francisco", parent: "13", value: "1317" },
    { name: "San Juan Guarita", parent: "13", value: "1318" },
    { name: "San Manuel Colohete", parent: "13", value: "1319" },
    { name: "San Rafael", parent: "13", value: "1320" },
    { name: "San Sebastián", parent: "13", value: "1321" },
    { name: "Santa Cruz", parent: "13", value: "1322" },
    { name: "Talgua", parent: "13", value: "1323" },
    { name: "Tambla", parent: "13", value: "1324" },
    { name: "Tomalá", parent: "13", value: "1325" },
    { name: "Valladolid", parent: "13", value: "1326" },
    { name: "Virginia", parent: "13", value: "1327" },
    { name: "San Marcos de Caiquín", parent: "13", value: "1328" },
    { name: "Ocotepeque", parent: "14", value: "1401" },
    { name: "Belén Gualcho", parent: "14", value: "1402" },
    { name: "Concepción", parent: "14", value: "1403" },
    { name: "Dolores Merendón", parent: "14", value: "1404" },
    { name: "Fraternidad", parent: "14", value: "1405" },
    { name: "La Encarnación", parent: "14", value: "1406" },
    { name: "La Labor", parent: "14", value: "1407" },
    { name: "Lucerna", parent: "14", value: "1408" },
    { name: "Mercedes", parent: "14", value: "1409" },
    { name: "San Fernando", parent: "14", value: "1410" },
    { name: "San Francisco del Valle", parent: "14", value: "1411" },
    { name: "San Jorge", parent: "14", value: "1412" },
    { name: "San Marcos", parent: "14", value: "1413" },
    { name: "Santa Fe", parent: "14", value: "1414" },
    { name: "Sensenti", parent: "14", value: "1415" },
    { name: "Sinuapa", parent: "14", value: "1416" },
    { name: "Juticalpa", parent: "15", value: "1501" },
    { name: "Campamento", parent: "15", value: "1502" },
    { name: "Catacamas ", parent: "15", value: "1503" },
    { name: "Concordia", parent: "15", value: "1504" },
    { name: "Dulce Nombre de Culmí", parent: "15", value: "1505" },
    { name: "El Rosario", parent: "15", value: "1506" },
    { name: "Esquipulas del Norte", parent: "15", value: "1507" },
    { name: "Gualaco", parent: "15", value: "1508" },
    { name: "Guarizama", parent: "15", value: "1509" },
    { name: "Guata", parent: "15", value: "1510" },
    { name: "Guayape", parent: "15", value: "1511" },
    { name: "Jano", parent: "15", value: "1512" },
    { name: "La Unión", parent: "15", value: "1513" },
    { name: "Mangulile", parent: "15", value: "1514" },
    { name: "Manto", parent: "15", value: "1515" },
    { name: "Salamá", parent: "15", value: "1516" },
    { name: "San Esteban", parent: "15", value: "1517" },
    { name: "San Francisco de Becerra", parent: "15", value: "1518" },
    { name: "San Francisco de la Paz", parent: "15", value: "1519" },
    { name: "Santa María del Real", parent: "15", value: "1520" },
    { name: "Silca", parent: "15", value: "1521" },
    { name: "Yocón", parent: "15", value: "1522" },
    { name: "Patuca", parent: "15", value: "1523" },
    { name: "Santa Bárbara", parent: "16", value: "1601" },
    { name: "Arada", parent: "16", value: "1602" },
    { name: "Atima", parent: "16", value: "1603" },
    { name: "Azacualpa", parent: "16", value: "1604" },
    { name: "Ceguaca", parent: "16", value: "1605" },
    { name: "San José de las Colinas", parent: "16", value: "1606" },
    { name: "Concepción del Norte", parent: "16", value: "1607" },
    { name: "Concepción del Sur", parent: "16", value: "1608" },
    { name: "Chinda", parent: "16", value: "1609" },
    { name: "El Níspero", parent: "16", value: "1610" },
    { name: "Gualala", parent: "16", value: "1611" },
    { name: "Ilama", parent: "16", value: "1612" },
    { name: "Macuelizo", parent: "16", value: "1613" },
    { name: "Naranjito", parent: "16", value: "1614" },
    { name: "Nuevo Celilac", parent: "16", value: "1615" },
    { name: "Petoa", parent: "16", value: "1616" },
    { name: "Protección", parent: "16", value: "1617" },
    { name: "Quimistán", parent: "16", value: "1618" },
    { name: "San Francisco de Ojuera", parent: "16", value: "1619" },
    { name: "San Luis", parent: "16", value: "1620" },
    { name: "San Marcos", parent: "16", value: "1621" },
    { name: "San Nicolás", parent: "16", value: "1622" },
    { name: "San Pedro Zacapa", parent: "16", value: "1623" },
    { name: "Santa Rita", parent: "16", value: "1624" },
    { name: "San Vicente Centenario", parent: "16", value: "1625" },
    { name: "Trinidad", parent: "16", value: "1626" },
    { name: "Las Vegas", parent: "16", value: "1627" },
    { name: "Nueva Frontera", parent: "16", value: "1628" },
    { name: "Nacaome", parent: "17", value: "1701" },
    { name: "Alianza", parent: "17", value: "1702" },
    { name: "Amapala", parent: "17", value: "1703" },
    { name: "Aramecina", parent: "17", value: "1704" },
    { name: "Caridad", parent: "17", value: "1705" },
    { name: "Goascorán", parent: "17", value: "1706" },
    { name: "Langue", parent: "17", value: "1707" },
    { name: "San Francisco de Coray", parent: "17", value: "1708" },
    { name: "San Lorenzo", parent: "17", value: "1709" },
    { name: "Yoro", parent: "18", value: "1801" },
    { name: "Arenal", parent: "18", value: "1802" },
    { name: "El Negrito", parent: "18", value: "1803" },
    { name: "El Progreso", parent: "18", value: "1804" },
    { name: "Jocón", parent: "18", value: "1805" },
    { name: "Morazán", parent: "18", value: "1806" },
    { name: "Olanchito", parent: "18", value: "1807" },
    { name: "Santa Rita", parent: "18", value: "1808" },
    { name: "Sulaco", parent: "18", value: "1809" },
    { name: "Victoria", parent: "18", value: "1810" },
    { name: "Yorito", parent: "18", value: "1811" },
  ],
  [CatalogsEnum.PersonTypes]: [
    { name: "Física", value: "01" },
    { name: "Moral", value: "02" },
  ],
};
