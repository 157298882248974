import { useForm, UseFormReturn } from "react-hook-form";
import { ModalFields } from "../../../../../types/modal_fields";
import { KshFile } from "../../FileUpload/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { IFileUpload } from "../../../UploadConciliation/UploadCard";
import { FileInfo } from "../../../../../types/file_info";
import {
  processTransactions,
  setIsUploadedConciliation,
  setModalConciliation,
  setModalConciliationProps,
  uploadFileSignedUrl,
} from "../../../../store/actionCreators";
import { IAppState, IModalConciliation } from "../../../../store/reducer";
import { OriginEnum } from "../../../../shared/infrastructure/OriginEnum";
import { camelCase, defaultTo, get, map } from "lodash";
import { ActionModalContentEnum } from "../../../../shared/infrastructure/ActionModalContentEnum";
import { StateEnum } from "../../../../shared/infrastructure/StateEnum";
import {
  TransactionData,
  UpdateTransactionRequest,
} from "../../../../../types/process_transaction_request";
import { ConciliationRequest } from "../../../../../types/conciliation_request";
import { ActionEnum } from "../../../../shared/infrastructure/interfaces/ActionEnum";
import { UpdateTransactionSummaryRequest } from "../../../../../types/process_transaction_summary_request";
import { auth } from "../../../../shared/auth";

export interface IActionModalProps {
  title: string;
  buttonLbl: string;
}

export interface IUser {
  userName: string;
  userId: string;
  userRole: string;
}

export interface IConciliationState {
  form: UseFormReturn<ModalFields>;
  submit: () => void;
  fileUploader: IFileUpload;
  uploadError: boolean;
  isUploadedConciliation: boolean;
  isUploadingConciliation: boolean;
  openAlertModal: boolean;
  handleCloseAlertModal: () => void;
  handleAlertModalSubmit: () => void;
  actionModalProps: IActionModalProps;
}

export const useConciliationState = (): IConciliationState => {
  const dispatch = useDispatch();

  const store = useSelector((state: IAppState) => state);

  const form: UseFormReturn<ModalFields> = useForm({
    defaultValues: {
      liquidationAmount: "",
      comment: "",
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const decodedJWT = auth.getJwtAuth()
    ? JSON.parse(atob(auth.getJwtAuth().split(".")[1]))
    : {};

  const user: IUser = {
    userName: get(decodedJWT, "username"),
    userId: get(decodedJWT, "client_id"),
    userRole: get(decodedJWT, "cognito:groups", "").toString(),
  };

  const [openAlertModal, setOpenAlertModal] = React.useState(false);
  const [actionModalProps, setActionModalProps] = useState<IActionModalProps>({
    title: "",
    buttonLbl: "",
  });

  /* UploadFile Methods */
  const [fileInfo, setFileInfo] = useState<FileInfo>();
  const [files, setFiles] = useState<KshFile[] | undefined>();
  const [invalidFile, setInvalidFile] = useState<boolean>(false);

  const setAlertModalProps = () => {
    setActionModalProps({
      ...actionModalProps,
      title: store.modalConciliation?.conciliationMasive
        ? ActionModalContentEnum.conciliationMassiveTitle
        : ActionModalContentEnum.conciliationTittle,
      buttonLbl: ActionModalContentEnum.confirmButton,
    });
    setOpenAlertModal(!openAlertModal);
  };

  const handleAlertModalSubmit = () => {
    const isMassive: boolean = get(
      store.modalConciliation,
      "conciliationMasive",
      false
    );
    const originTransaction: string = get(
      store.modalConciliation,
      "conciliation",
      ""
    );

    const dataRequest = store.modalProps;
    if (dataRequest) {
      dataRequest.data = defaultTo(
        dataRequest?.data!.map((transaction) =>
          store.modalConciliation?.conciliation === OriginEnum.SUMMARY
            ? buildData(convertToCamelCase(transaction))
            : convertToCamelCase(transaction)
        ),
        []
      );
    }
    let requestObj: UpdateTransactionRequest | UpdateTransactionSummaryRequest =
      {
        action:
          store.modalConciliation?.conciliation === OriginEnum.TRANSACTIONS
            ? ActionEnum.PROCESS_TRANSACTIONS
            : ActionEnum.PROCESS_SUMMARY_TRANSACTIONS,
        data: dataRequest!,
      };
    dispatch(processTransactions(requestObj, isMassive, originTransaction));
    setOpenAlertModal(false);
  };

  const buildData = (item: any) => {
    return {
      id: get(item, "id", ""),
      conciliationCreated: get(item, "conciliationCreated"),
      country: get(item, "country"),
      paymentMethod: get(item, "paymentMethod"),
      processorName: get(item, "processorName"),
      currencyCode: get(item, "currencyCode"),
      netAmountTotal: get(item, "netAmountTotal"),
      bankConciliationStatus: get(item, "bankConciliationStatus"),
      comment: get(item, "comment"),
      liquidationAmount: get(item, "liquidationAmount"),
      path: get(item, "path"),
      fileName: get(item, "fileName"),
      fileSize: get(item, "fileSize"),
      userId: get(item, "userId"),
      userName: get(item, "userName"),
      userRole: get(item, "userRole"),
      actionStatus: get(item, "actionStatus"),
      source: get(item, "source"),
      created: get(item, "created"),
      updatedAt: get(item, "updatedAt"),
      deletedAt: get(item, "deletedAt"),
    };
  };
  const convertToCamelCase = (transaction: object): TransactionData => {
    return Object.entries(transaction).reduce((acc, curr) => {
      const keyInCamel = camelCase(curr[0]);
      acc[keyInCamel] = curr[1];
      return acc;
    }, {});
  };

  const handleCloseAlertModal = () => {
    setOpenAlertModal(false);
  };

  const handlerDelete = (resultFiles: { prop: string; files?: KshFile }) => {
    setInvalidFile(false);
    setFileInfo({
      ...fileInfo,
      [resultFiles.prop]: undefined,
    });
    setFiles(undefined);
    dispatch(setIsUploadedConciliation(false));
  };
  const setFile = (file: KshFile, property: string, origin: OriginEnum) => {
    setInvalidFile(false);
    const { name, blob, base64, extension, fileSize } = file;
    const id = name.toLowerCase().replace(/\s/g, "_");
    const newFile = {
      id,
      name: name,
      blob: blob,
      base64: base64,
      fileSize: fileSize,
      extension: extension,
    };
    setFileInfo({
      [property]: {
        name: name,
        file: base64,
        extension: extension,
      },
    });
    setFiles([newFile]);
    dispatch(uploadFileSignedUrl(blob, origin));
  };
  const handlerCaptureFile = (value: {
    prop: string;
    files?: KshFile;
    origin: OriginEnum;
  }) => {
    if (value.files) {
      setFile(value.files, value.prop, value.origin);
    }
  };

  const submitComission = () => {
    let modalConciliationData: IModalConciliation = store.modalConciliation!;
    const dataObject: TransactionData[] = map(
      get(store.modalConciliation, "transactions", []),
      (item: any) => ({
        ...item,
        created: new Date(item.created || 0).getTime(),
        conciliationCreated: new Date(
          get(item, "conciliation_created", 0)
        ).getTime(),
        bankConciliationDate: new Date(
          get(item, "bank_conciliation_date", 0)
        ).getTime(),
        transactionCreated: new Date(
          get(item, "transaction_created", 0)
        ).getTime(),
      })
    );

    const requestObject: ConciliationRequest = {
      state: StateEnum.CONCILIATION,
      comment: form.getValues("comment"),
      source: "pending",
      ...(store.modalConciliation?.conciliation === OriginEnum.SUMMARY && {
        liquidationAmount: Number.parseFloat(
          form.getValues("liquidationAmount")
        ),
      }),
      path: store.conciliationFileKey!,
      fileName: get(files, "[0].name", ""),
      fileSize: get(files, "[0].fileSize", ""),
      userName: user.userName,
      userId: user.userId,
      userRole: user.userRole,
      data: dataObject,
    };

    modalConciliationData = {
      ...modalConciliationData,
      open: false,
    };
    dispatch(setModalConciliation(modalConciliationData));
    dispatch(setModalConciliationProps(requestObject));
    setAlertModalProps();
    form.reset();
    handlerDelete({ files: undefined, prop: "conciliationFile" });
  };

  return {
    fileUploader: {
      files,
      handlerCaptureFile,
      handlerDelete,
      invalidFile,
    },
    handleCloseAlertModal,
    handleAlertModalSubmit,
    openAlertModal,
    form,
    submit: submitComission,
    uploadError: store.conciliationUploadError!,
    isUploadedConciliation: store.isUploadedConciliation!,
    isUploadingConciliation: store.isUploadingConciliation!,
    actionModalProps,
  };
};
