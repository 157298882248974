import { createStyles, Theme } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AlertCircle } from "react-feather";

export interface INotificationProps {
  msg: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alertContent: {
      alignItems: "center",
      backgroundColor: theme.palette.primary.main,
      display: "flex",
      justifyContent: "space-between",
      marginTop: 30,
      padding: theme.spacing(0, 1.25),
      width: "fit-content",
    },
    alertIcon: {
      transform: "rotate(180deg)",
    },
    alertText: {
      color: "white",
      fontSize: "12px",
      marginLeft: "10px",
    },
  })
);
export const Notification = (props: INotificationProps) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.alertContent}>
        <AlertCircle className={classes.alertIcon} color="white" size={25} />
        <p className={classes.alertText}>{props.msg}</p>
      </div>
    </React.Fragment>
  );
};

export default Notification;
