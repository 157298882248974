import { environment } from "../../../environments/environment";

export const API_ROUTES = {
  CREATE_WALLET_REPORT: `${environment.kushkiUrl}/wallet/v1/createReport`,
  DOWNLOAD_WALLET_REPORT: (jwt: string) =>
    `${environment.wsUrl}/wallet?Authorization=${jwt}`,
  GET_BALANCE: (merchantId: string) =>
    `${environment.kushkiUrl}/wallet/v1/balance/${merchantId}`,
  GET_MERCHANT_NODE_INFO: `${environment.kushkiUrl}/billing-core-node/v1/merchant-node/info`,
  GET_WALLET_TRANSACTIONS: (merchantId: string) =>
    `${environment.kushkiUrl}/wallet/v1/transactions/${merchantId}`,
};
