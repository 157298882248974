import React from "react";
import { mainSubHeaderStyles as styles } from "./SubHeader.styles";
import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";

import { CountryEnum } from "../../shared/enums/countryEnum";
import { Flag } from "../Flag/Flag";
import { get } from "lodash";
import { FlagsNames } from "../../shared/enums/CountryEnumArray";
import { validCountries } from "../../shared/constants/validCountries";
import { useLocation } from "react-router-dom";
import { Copy } from "react-feather";
import { clipboard } from "../../shared/clipboard";

export interface SubHeaderProps {
  country: CountryEnum;
  id: string;
  redirectPath: string;
  title: string;
  subtitle: string;
  iconType: JSX.Element;
}

export const Subheader: React.FC<SubHeaderProps> = (props: SubHeaderProps) => {
  const idTitlte = "ID:";
  const location = useLocation();
  const routeSessionStorage = { layout: { lastRoute: location } };

  return (
    <Box>
      <Grid container alignItems="center" sx={styles.gridTitle} spacing={1}>
        <Grid item>
          <Typography
            color="text.primary"
            fontSize={26}
            variant="h1"
            fontWeight={600}
          >
            {props.title}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            onClick={() => {
              sessionStorage.setItem(
                "basicInformation",
                JSON.stringify(routeSessionStorage)
              );
              window.location.href = props.redirectPath;
            }}
          >
            {props.iconType}
          </IconButton>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item container xs={12} md={"auto"} alignItems="center">
          <Grid item sx={styles.gridText}>
            <Typography variant="caption" color="text.primary">
              {props.subtitle}
            </Typography>
          </Grid>
          <Grid item>
            <Box sx={styles.flagPadding}>
              {validCountries.includes(props.country) ? (
                <Flag country={props.country} />
              ) : (
                <Flag country={CountryEnum.generic} />
              )}
            </Box>
          </Grid>

          <Grid item sx={styles.gridCountry}>
            <Typography variant="body1" color="text.primary">
              {get(FlagsNames[props.country], "title", props.country)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={"auto"} alignItems="center">
          <Divider orientation="vertical" flexItem sx={styles.divider} />
          <Grid item sx={styles.gridId}>
            <Typography variant="caption" color="text.primary">
              {idTitlte}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" color="text.primary">
              {props.id}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              sx={styles.iconEffect}
              data-testid={"IconCopy"}
              onClick={(e) => {
                clipboard(props.id);
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Copy />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Subheader;
