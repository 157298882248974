export enum ConditionEnum {
  CARD_HOLDER = "transactionCardHolderName",
  BIN = "transactionBIN",
  CARD = "transactionMaskedCreditCard",
  EMAIL = "transactionEmail",
  IP = "transactionIP",
  PHONE_NUMBER = "transactionPhoneNumber",
  DOCUMENT_NUMBER = "transactionDocumentNumber",
  SCHEDULE = "transactionSchedule",
  DATE = "transactionDate",
  CARD_ID = "transactionCardId",
}

export const ConditionLabels: Record<string, string> = {
  [ConditionEnum.CARD_HOLDER]: "Cardholder name",
  [ConditionEnum.CARD]: "Tarjeta Enmascarada",
  [ConditionEnum.EMAIL]: "Email",
  [ConditionEnum.IP]: "IP",
  [ConditionEnum.DOCUMENT_NUMBER]: "Número de documento",
  [ConditionEnum.PHONE_NUMBER]: "Número de teléfono",
  [ConditionEnum.SCHEDULE]: "Hora del comercio",
  [ConditionEnum.DATE]: "Fecha",
  [ConditionEnum.CARD_ID]: "Kushki Id",
  [ConditionEnum.BIN]: "BIN",
};

export const ConditionOperators: Record<string, string> = {
  "=": "igual a",
  ">": "mayor a",
  "<": "menor a",
  betweendate: "Durante este horario",
  contains: "Contiene",
  contain: "Contiene",
  multiplematch: "Igual Varios",
};
