export const M_BANK_CONCILIATION_TRANSACTIONAL =
  "M_BANK_CONCILIATION.TRANSACTIONAL";

export enum ComponentIdsEnum {
  M_BANK_CONCILIATION_TRANSACTIONAL_PENDING_DOWNLOAD = "M_BANK_CONCILIATION.TRANSACTIONAL.Transaccional.Pendientes.Descargar",
  M_BANK_CONCILIATION_TRANSACTIONAL_PENDING_REFUND = "M_BANK_CONCILIATION.TRANSACTIONAL.Transaccional.Pendientes.ReembolsarIndividualMasivo",
  M_BANK_CONCILIATION_TRANSACTIONAL_PENDING_DISCHARGE = "M_BANK_CONCILIATION.TRANSACTIONAL.Transaccional.Pendientes.Liquidar",
  M_BANK_CONCILIATION_TRANSACTIONAL_PENDING_OMIT = "M_BANK_CONCILIATION.TRANSACTIONAL.Transaccional.Pendientes.OmitirIndividualMasivo",
  M_BANK_CONCILIATION_TRANSACTIONAL_PENDING_REVIEW = "M_BANK_CONCILIATION.TRANSACTIONAL.Transaccional.Pendientes.Revisar",
  M_BANK_CONCILIATION_TRANSACTIONAL_HISTORIC_DOWNLOAD = "M_BANK_CONCILIATION.TRANSACTIONAL.Transaccional.Historicos.Descargar",
}
