import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITransactionsState } from "../../interfaces/TransactionsState.interfaces";
import getTransactionsPendingReview from "../../thunks/transactions/getTransactionsPendingReview/get_transactions_pending_review";

export const initialState: ITransactionsState = {
  areTransactionsLoading: false,
  loadingTransactionsPendingReview: false,
  selectedTransactions: [],
  transactions: [],
  transactionsPendingReview: [],
};

export const transactionsSlice = createSlice({
  extraReducers: {
    [getTransactionsPendingReview.pending.toString()]: (state) => {
      state.loadingTransactionsPendingReview = true;
    },
    [getTransactionsPendingReview.fulfilled.toString()]: (
      state,
      { payload }
    ) => {
      state.transactionsPendingReview = payload;
      state.loadingTransactionsPendingReview = false;
    },
    [getTransactionsPendingReview.rejected.toString()]: (state) => {
      state.loadingTransactionsPendingReview = false;
    },
  },
  initialState,
  name: "transactions",
  reducers: {
    addSelected: (state, { payload }: PayloadAction<string>) => {
      state.selectedTransactions = [...state.selectedTransactions!, payload];
    },
    removeSelected: (state, { payload }: PayloadAction<string>) => {
      state.selectedTransactions = state.selectedTransactions.filter(
        (item) => item !== payload
      );
    },
  },
});

export default transactionsSlice.reducer;
