/* istanbul ignore file */

import { IOptionSelection } from "./RulesConstants";
import { CurrencyEnum } from "../enums/CurrencyEnum";

export const BANKS_COSTA_RICA: IOptionSelection[] = [
  { value: "11100006", name: "BANCO DE COSTA RICA" },
  { value: "11100001", name: "BANCO BAC SAN JOSE" },
  { value: "11100002", name: "BANCO BCT S.A." },
  { value: "11100003", name: "BANCO CATHAY DE COSTA RICA S.A." },
  { value: "11100004", name: "BANCO CENTRAL DE COSTA RICA" },
  { value: "11100005", name: "BANCO CITIBANK DE COSTA RICA S.A." },
  { value: "11100007", name: "BANCO GENERAL DE COSTA RICA S.A." },
  { value: "11100008", name: "BANCO HSBC DE COSTA RICA S.A." },
  { value: "11100009", name: "BANCO IMPROSA S.A." },
  { value: "11100010", name: "BANCO LAFISE S.A." },
  { value: "11100011", name: "BANCO NACIONAL DE COSTA RICA" },
  { value: "11100012", name: "BANCO PROMERICA" },
  { value: "11100013", name: "SCOTIABANK DE COSTA RICA" },
  { value: "11100014", name: "COSTA RICA" },
  { value: "11100015", name: "BANCO INTERFIN DE COSTA RICA" },
  { value: "11100016", name: "BANCO INTERNACIONAL DE COSTA RICA" },
];

export const CURRENCY_COSTA_RICA: IOptionSelection[] = [
  { value: CurrencyEnum.CRC, name: CurrencyEnum.CRC },
  { value: CurrencyEnum.USD, name: CurrencyEnum.USD },
];
