export const SIDEBAR = "sidebar";
export const SUB_MERCHANT = "Subcomercio";
export const OPEN_MODAL = "abrir modalExit";

export const M_CLIENTS = "M_CLIENTS";

export enum CompomentIdsEnum {
  M_CLIENTS_MERCHANT_DATA = "M_CLIENTS.DatosEmpresa",
  M_CLIENTS_BILLING_CONFIGURATION = "M_CLIENTS.ConfiguraciónFinanciera",
  M_CLIENTS_PROCESSING = "M_CLIENTS.Procesamiento",
  M_CLIENTS_SERVICES = "M_CLIENTS.Servicios",
  M_CLIENTS_RESUME = "M_CLIENTS.Resumen",
}

export const COMPONENTS_IDS = {
  BILLING_CONFIGURATION: CompomentIdsEnum.M_CLIENTS_BILLING_CONFIGURATION,
  MERCHANT_DATA: CompomentIdsEnum.M_CLIENTS_MERCHANT_DATA,
  PROCESSING: CompomentIdsEnum.M_CLIENTS_PROCESSING,
  RESUME: CompomentIdsEnum.M_CLIENTS_RESUME,
  SERVICES: CompomentIdsEnum.M_CLIENTS_SERVICES,
};
