import React from "react";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import { ChipTransactionsStatus } from "../../ChipTransactionStatus/ChipTransactionStatus";
import { format } from "date-fns-tz";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useWalletTableState } from "../../WalletTable/state/useWalletTableState";
import { TransactionHeaderEnum } from "../../../shared/infrastructure/enums/TransactionHeaderEnum";

const useStyles = makeStyles((theme: Theme) => ({
  dateStyle: {
    color: theme.palette.grey["600"],
    fontSize: "14px !important",
  },
  h2: {
    color: "46525C",
  },
  h4: {
    color: "#293036",
  },
  subtitle: {
    color: theme.palette.grey.A700,
    fontSize: "12px !important",
    lineHeight: "136%",
  },
}));

export interface IPayoutsTransactionHeader {
  ticketNumber: string;
  status: string;
  created: string;
}

export const TransactionHeader: React.FC<IPayoutsTransactionHeader> = (
  props: IPayoutsTransactionHeader
) => {
  const { ticketNumber, status, created } = props;
  const { renderStatusText } = useWalletTableState();
  const classes = useStyles();

  return (
    <Container>
      <Box py={1.5} mb={1}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.subtitle}>
              {TransactionHeaderEnum.MOVEMENT_DETAILS}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              className={classes.h4}
            >{`ID: ${ticketNumber}`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.dateStyle}>
              {`Creación: ${format(
                new Date(created),
                "dd MMM'.' yyyy"
              )} ${format(new Date(created), "HH:mm")}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ChipTransactionsStatus
              status={status}
              message={""}
              disableHover={true}
              text={renderStatusText(status)}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
