import {
  Box,
  Collapse,
  InputAdornment,
  ListItem,
  Paper,
  TextField,
} from "@mui/material";
import List from "@mui/material/List";
import {
  dropdownListStyle,
  getDropdownListStyles,
} from "./DropdownList.styles";
import React from "react";
import { FC } from "react";
import { useStateDropdownList } from "./state/useStateDropdownList";
import { IDropdownListProps } from "./DropdownList.interfaces";
import { DEFAULT_ITEM_HEIGHT } from "../../shared/constants/dimensions";
import DropDownItem from "../DropDownItem/DropdownItem";

const DropdownList: FC<IDropdownListProps> = (props: IDropdownListProps) => {
  const {
    minWidth,
    maxHeight = DEFAULT_ITEM_HEIGHT * (minWidth ? 4.5 : 4),
    isOpen,
    isSearchable,
    searchablePlaceholder = "Buscar",
    handleClose,
    isDisabled,
  } = props;

  const { list, handleChange } = useStateDropdownList(props);

  return (
    <Paper elevation={1} sx={dropdownListStyle.paper} data-testid={"paper"}>
      <Collapse
        data-testid={"collapse"}
        in={isOpen}
        timeout="auto"
        unmountOnExit
      >
        {isSearchable && (
          <Box sx={dropdownListStyle.textSearch}>
            <TextField
              data-testid={"textField"}
              placeholder={searchablePlaceholder}
              margin="normal"
              fullWidth
              variant="outlined"
              onChange={handleChange}
              InputProps={{
                endAdornment: <InputAdornment position="start" />,
              }}
            />
          </Box>
        )}
        <List component="div" sx={getDropdownListStyles(maxHeight, minWidth)}>
          {list.map((item, index) => (
            <ListItem sx={dropdownListStyle.listItem} key={index}>
              <DropDownItem
                {...item}
                handleClose={handleClose}
                isDisable={isDisabled}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </Paper>
  );
};

export default DropdownList;
