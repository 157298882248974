import { IUseRequestDispersionState } from "../../../shared/infrastructure/interfaces/useRequestDispersionState.interface";
import { useDispatch, useSelector } from "react-redux";
import { ValidateFilesResponse } from "../../../../types/validate_files_response";
import { IAppState } from "../../../store/reducer";
import { useEffect } from "react";
import {
  setShowAlertOnProcessFileRequest,
  setShowValidationAlert,
} from "../../../store/actionCreators";

export const useRequestDispersionCompleteState =
  (): IUseRequestDispersionState => {
    const dispatch = useDispatch();
    let validateFileResponse: ValidateFilesResponse | undefined = useSelector(
      (state: IAppState) => state.validateFilesResponse
    );
    let showValidationAlert: boolean | undefined = useSelector(
      (state: IAppState) => state.showValidationErrorAlert
    );
    const showAlertOnProcessFileRequest: boolean | undefined = useSelector(
      (state: IAppState) => state.showAlertOnProcessFileRequest
    );

    useEffect(() => {
      if (showValidationAlert || showAlertOnProcessFileRequest) {
        setTimeout(() => {
          dispatch(setShowValidationAlert(false));
          dispatch(setShowAlertOnProcessFileRequest(false));
        }, 5000);
      }
    }, [showValidationAlert, showAlertOnProcessFileRequest]);

    return {
      showValidationAlert,
      validationResults: validateFileResponse,
      showAlertOnProcessFileRequest,
    };
  };
