import { IStyles } from "../../shared/utils";

export const contactDataAreasStyles: IStyles = {
  container: {
    padding: "8px 16px",
    backgroundColor: "#FBFCFE",
    borderRadius: "8px",
    display: "flex",
    alignItems: "baseline",
    flexDirection: "row",
    marginTop: "8px",
  },
  containerWithoutData: {
    padding: "8px 16px",
    backgroundColor: "#FBFCFE",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "8px",
  },
  dataContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 24px",
    background: "#F0F4F8",
    borderRadius: "8px",
    lineHeight: "21.6px",
  },
  boxSample: {
    background: "#F0F4F8",
    flexDirection: "row",
    borderRadius: "8px",
    padding: "8px 24px",
    display: "flex",
    alignItems: "center",
    lineHeight: "21.6px",
  },
  icon: {
    marginRight: "9px",
    color: "#6d7781",
    height: "22px",
    width: "22px",
  },
  boxInternal: {
    width: "100%",
    padding: "4px",
  },
  cardTesting: {
    padding: "24px 48px 24px 48px",
  },
  buttonEdit: {
    background: "#F0F4F8",
    color: "#023365",
    height: "22px",
    width: "22px",
  },
  nameContent: {
    color: "#6D7781",
    fontStyle: "normal",
    fontHeight: 400,
    fontSize: "15px",
    lineHeight: "21.6px",
  },
  emailContent: {
    color: "#293036",
    fontStyle: "normal",
    fontHeight: 400,
    fontSize: "15px",
    lineHeight: "21.6px",
  },
  boxPopUp: {
    width: "20%",
  },
  buttonGrid: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  emailGrid: {
    paddingLeft: "8px",
  },
};
