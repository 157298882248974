import {
  defaultTo,
  get,
  has,
  includes,
  isEmpty,
  isUndefined,
  map,
  uniqBy,
} from "lodash";
import { GetProcessorResponse } from "../../types/get_processor_response";
import { PaymentMethodEnum } from "../shared/enums/PaymentMethodEnum";
import { format } from "date-fns-tz";
import { GetDefaultProcessorResponse } from "../../types/get_default_processor_response";
import {
  DeferredOption1,
  MerchantDeferred,
} from "../../types/merchan_deferred";
import {
  DeferredOptionPath,
  DeferredPath,
} from "../shared/enums/DeferredOptionPath";
import { LocalStoragePathEnum } from "../shared/infrastructure/enums/LocalStoragePathEnum";
import {
  BranchesSelectedList,
  MassiveInformation,
} from "../components/SelectedBranch/state/IUseSelectedBranch.interfaces";
import {
  MerchantNodeData,
  SearchMerchantResponse,
} from "../../types/search_merchant_response";
import { Configs } from "../../types/get_node_info_response";
import { ConfigurationCodeEnum } from "../shared/enums/ProcessingStepEnum";
import { BranchEditList, Data } from "../../types/branch_edit_list";
import { SessionStoragePathEnum } from "../shared/infrastructure/enums/SessionStoragePathEnum";
import { EMPTY_OBJECT_STRING } from "../shared/constants/empty_object";
import { CountriesEnum } from "../shared/infrastructure/enums/CountriesEnum";

export const getMerchantId = (): string => {
  const queryString: string = window.location.search;
  const params: URLSearchParams = new URLSearchParams(queryString);
  const publicMerchantId: string | null = params.get("publicMerchantId");
  const customerBatchInformation: object = JSON.parse(getCustomerBatchInfo());
  const customerBatchId: string = get(
    customerBatchInformation,
    "publicMerchantId",
    ""
  );

  return defaultTo(publicMerchantId, customerBatchId);
};

export const emptyHandle = (): void => {};

export const isAvailableCardProcessor = (
  processor: GetProcessorResponse[],
  sizeToCompare: number = 0,
  country: CountriesEnum = CountriesEnum.ECUADOR
): boolean => {
  const countriesWithOutFailOver = [CountriesEnum.BRAZIL];
  return (
    defaultTo(processor, []).filter(
      (data: GetProcessorResponse) =>
        data.paymentMethod === PaymentMethodEnum.CARD
    ).length > sizeToCompare && !countriesWithOutFailOver.includes(country)
  );
};

export const notifySuccessAlert = (): string => {
  return defaultTo(localStorage.getItem("notifySuccessAlert"), "");
};

export const notifyErrorAlert = (): string => {
  return defaultTo(localStorage.getItem("notifyErrorAlert"), "");
};

export const userMail = (): string => {
  return defaultTo(localStorage.getItem("email"), "");
};

export const isAllCardProcessorsWithFailover = (
  processors: GetProcessorResponse[]
): boolean => {
  const cardProcessors: GetProcessorResponse[] = processors.filter(
    (processor) => processor.paymentMethod === PaymentMethodEnum.CARD
  );
  const uniqueCardProcessorFilter: GetProcessorResponse[] = uniqBy(
    cardProcessors,
    "processorName"
  );
  const withoutFailover: GetProcessorResponse[] = uniqueCardProcessorFilter.filter(
    (processor) => !has(processor, "failOverProcessor")
  );

  return (
    (uniqueCardProcessorFilter.length > 1 && withoutFailover.length === 0) ||
    uniqueCardProcessorFilter.length === 1
  );
};

const restrictByFailover = (processors: GetProcessorResponse[]): boolean => {
  const cardProcessors: GetProcessorResponse[] = processors.filter(
    (processor) => processor.paymentMethod === PaymentMethodEnum.CARD
  );
  if (cardProcessors.length > 1) {
    const totalProcessors: object = cardProcessors.reduce(
      (acc, processor: GetProcessorResponse) => {
        acc[processor.processorName] = ++acc[processor.processorName] || 0;
        return acc;
      },
      {}
    );
    return Object.keys(totalProcessors).length > 1;
  }
  return false;
};

export const disableContinueButton = (
  processors: GetProcessorResponse[],
  defaultProcessors: GetDefaultProcessorResponse | undefined
): boolean => {
  let disabled: boolean = false;
  const allAliases: string[] = processors.map((processor) => processor.alias);
  const validateFailoverProcessors: boolean = restrictByFailover(processors);

  for (let i = 0; i < processors.length; i++) {
    if (
      processors[i].paymentMethod === PaymentMethodEnum.CARD &&
      (!includes(allAliases, get(defaultProcessors, "defaultProcessor.card")) ||
        (validateFailoverProcessors &&
          isUndefined(processors[i].failOverProcessor)))
    ) {
      disabled = true;
    }

    if (
      processors[i].paymentMethod === PaymentMethodEnum.TRANSFER &&
      !includes(allAliases, get(defaultProcessors, "defaultProcessor.transfer"))
    ) {
      disabled = true;
    }

    if (
      processors[i].paymentMethod ===
        PaymentMethodEnum.TRANSFER_SUBSCRIPTIONS &&
      !includes(
        allAliases,
        get(defaultProcessors, "defaultProcessor.achTransfer")
      )
    ) {
      disabled = true;
    }

    if (
      processors[i].paymentMethod === PaymentMethodEnum.PAYOUTS_TRANSFER &&
      !includes(
        allAliases,
        get(defaultProcessors, "defaultProcessor.payoutsTransfer")
      )
    ) {
      disabled = true;
    }
  }

  return disabled;
};

export const showUpdatedAt = (updatedAt: number): string => {
  if (updatedAt > 0) {
    return format(updatedAt, "dd-MM-yyyy HH:mm:ss");
  }
  return updatedAt.toString();
};

export const isBatchPath = (): boolean => {
  const urlPath: string = window.location.pathname;

  return includes(["/processing/batch/", "/processing/batch"], urlPath);
};
export const getCustomerBatchInfo = (): string => {
  return defaultTo(localStorage.getItem("customerBatchInformation"), "{}");
};

export const evaluateDeferredNotSent = (): boolean => {
  const merchant_deferred_data: MerchantDeferred = JSON.parse(
    defaultTo(
      localStorage.getItem(LocalStoragePathEnum.MERCHANT_DEFERRED_DATA),
      "{}"
    )
  );
  let hasDeferredNotSent: boolean = false;
  const deferred: DeferredOption1[] = get(
    merchant_deferred_data,
    DeferredPath.DeferredOptions,
    []
  );

  deferred.map((def: DeferredOption1) => {
    if (!get(def, DeferredOptionPath.IsSent, false)) hasDeferredNotSent = true;
  });

  return hasDeferredNotSent;
};

export const listBranchesSelected = (): MassiveInformation => {
  const branchesList: BranchesSelectedList[] = [];
  const list: SearchMerchantResponse = JSON.parse(
    sessionStorage.getItem("branchEditList") || "[]"
  );

  defaultTo(list.data, [])
    .filter((branch: MerchantNodeData) => branch.edit)
    .map((branch: MerchantNodeData) => {
      branchesList.push({
        clientType: get(branch, "client_type", ""),
        name: get(branch, "name", ""),
        nodeId: get(branch, "node_id", ""),
        publicMerchantId: get(branch, "public_merchant_id", ""),
        configs: defaultTo(branch.configs, []),
      });
    });

  const title: string = map(branchesList.slice(0, 3), "name").join(", ");
  const disableProcessorsTab: boolean = !branchesList.every(
    (branch: BranchesSelectedList) => {
      const position: number = defaultTo(branch.configs, []).findIndex(
        (config: Configs) =>
          config.configuration === ConfigurationCodeEnum.PROCESSOR
      );
      const config: Configs = get(branch.configs, `[${position}]`, {});

      return isEmpty(defaultTo(get(config, "centralizedNodesId"), ""));
    }
  );

  return {
    disableBusinessRulesTab: true,
    disableProcessorsTab,
    listBranches: branchesList,
    title,
  };
};

export const getStorageBranchEditList = (): BranchEditList =>
  JSON.parse(
    defaultTo(
      sessionStorage.getItem(SessionStoragePathEnum.BRANCH_EDIT_LIST),
      EMPTY_OBJECT_STRING
    )
  );

export const setStorageBranchEditList = (value: BranchEditList) =>
  sessionStorage.setItem(
    SessionStoragePathEnum.BRANCH_EDIT_LIST,
    JSON.stringify({
      data: get(value, "data", []),
      total: get(value, "data", []).length,
    })
  );

export const updateBranchIsModify = () => {
  const branchList: BranchEditList = getStorageBranchEditList();
  const updateBranchList: Data[] = (get(branchList, "data", []) as Data[]).map(
    (branch: Data) => ({
      ...branch,
      isModify: true,
    })
  );
  setStorageBranchEditList({ ...branchList, data: updateBranchList });
};
