import { SnackBarProvider, theme } from "@kushki/connect-ui";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { ROUTES, SPA_BASENAME } from "./shared/constants/routes";

import { store } from "./store/store";
import { CheckoutContainer } from "./containers/CheckoutContainer/CheckoutContainer";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
import { environment } from "./environments/environment";
import { SecurityWrapperEnum } from "./shared/enums/SecurityWrapperEnum";
import { CheckoutProvider } from "./containers/CheckoutContainer/context/useCheckoutContext";
import { Provider as RollbarProvider } from "@rollbar/react";
import Rollbar from "rollbar";

const Root = () => {
  const rollbarConfig: Rollbar.Configuration = {
    accessToken: environment.rollbarId,
    addErrorContext: true,
    autoInstrument: true,
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: environment.envName,
    payload: {
      environment: environment.envName,
    },
  };

  return (
    <React.StrictMode>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&display=swap');"
        }
      </style>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500&display=swap');"
        }
      </style>
      <ModuleSecurityWrapper
        basePath={environment.kushkiUrl}
        componentId={SecurityWrapperEnum.M_EXPRESS_WEBCHECKOUT}
      >
        <RollbarProvider config={rollbarConfig}>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <CheckoutProvider>
                <SnackBarProvider>
                  <BrowserRouter basename={SPA_BASENAME}>
                    <Routes>
                      <Route
                        path={ROUTES.INDEX}
                        element={<CheckoutContainer />}
                      />
                    </Routes>
                  </BrowserRouter>
                </SnackBarProvider>
              </CheckoutProvider>
            </ThemeProvider>
          </Provider>
        </RollbarProvider>
      </ModuleSecurityWrapper>
    </React.StrictMode>
  );
};

export default Root;
