import { AlertColor } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppState } from "../../interfaces/AppState.interfaces";
import downloadTransactions from "../../thunks/transactions/downloadTransactions/download_transactions";
import { LoadingTextEnum } from "../../../shared/enums/loading_text_enum";
import { MessageAlertEnum } from "../../../shared/enums/message_alert_enum";
import { EmptyRefundData } from "../../../shared/enums/empty_refund_data";
import { IResendRefundData } from "../../interfaces/ResendRefundData.interfaces";

export const initialState: IAppState = {
  loading: false,
  loadingText: "",
  messageAlert: MessageAlertEnum.DEFAULT,
  openRefundModal: false,
  refundModalData: EmptyRefundData,
  showAlert: false,
  typeAlert: "success",
};

export const appSlice = createSlice({
  extraReducers: {
    [downloadTransactions.pending.toString()]: (state) => {
      state.loading = true;
      state.loadingText = LoadingTextEnum.DOWNLOAD;
    },
    [downloadTransactions.fulfilled.toString()]: (state, { payload }) => {
      state.loading = false;
      state.typeAlert = payload ? "success" : "error";
      state.messageAlert = payload
        ? MessageAlertEnum.DOWNLOAD_SUCCESS
        : MessageAlertEnum.DOWNLOAD_ERROR;
      state.showAlert = true;
      state.loadingText = LoadingTextEnum.DEFAULT;
    },
  },
  initialState,
  name: "app",
  reducers: {
    hideAlert: (state) => {
      state.showAlert = false;
    },
    hideLoading: (state) => {
      state.loading = false;
    },
    hideRefundModal: (state) => {
      state.openRefundModal = false;
    },
    setLoadingText: (state: IAppState, action: PayloadAction<string>) => {
      state.loadingText = action.payload;
    },
    setMessageAlert: (state, action: PayloadAction<string>) => {
      state.messageAlert = action.payload;
    },
    setRefundModalData: (state, action: PayloadAction<IResendRefundData>) => {
      state.refundModalData = { ...action.payload };
    },
    setTypeAlert: (state, action: PayloadAction<AlertColor>) => {
      state.typeAlert = action.payload;
    },
    showAlert: (state) => {
      state.showAlert = true;
    },
    showLoading: (state) => {
      state.loading = true;
    },
    showRefundModal: (state) => {
      state.openRefundModal = true;
    },
  },
});

export default appSlice.reducer;
