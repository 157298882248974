import { TransactionStatusEnum } from "../enums/transaction_status";

export type TTransactionStatus = "approved" | "declined" | "pending";

export const TRANSACTION_STATUS_LABEL_LOOKUP: Record<
  TTransactionStatus,
  string
> = {
  approved: "Aprobada",
  declined: "Declinada",
  pending: "Pendiente",
};

export const TRANSACTION_STATUS_FROM_RESPONSE_LOOKUP: Record<
  string,
  TTransactionStatus
> = {
  APPROVED: "approved",
  DECLINED: "declined",
  PENDING: "pending",
};

export type TStatusColor =
  | "default"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning"
  | undefined;

export const TRANSACTION_STATUS_COLOR_LOOKUP: Record<
  TTransactionStatus,
  TStatusColor
> = {
  approved: "secondary",
  declined: "error",
  pending: "default",
};

export const TRANSACTION_STATUS_ENUM_LOOKUP: Record<
  TTransactionStatus,
  string
> = {
  approved: TransactionStatusEnum.APPROVED,
  declined: TransactionStatusEnum.DECLINED,
  pending: TransactionStatusEnum.REFUNDING,
};
