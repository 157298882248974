import { IOptionFilter } from "@kushki/frontend-molecules/filter-container";
import { ProcessorStatusEnum } from "../infrastructure/constants/ProcessorsEnum";

export enum ProcessorProperties {
  PAYMENT_METHOD_OUT = "paymentMethodOut",
  PROCESSOR_TYPE = "processorType",
  PAYMENT_METHOD_IN = "paymentMethodIn",
  PROCESSOR_STATUS = "status",
}

enum paymentMethodPayInEnum {
  CARD = "card",
  TRANSFER = "transfer",
  CASH = "cash",
  SUBSCRIPTIONS = "transfer-subscriptions",
}

export enum paymentMethodPayOutEnum {
  TRANSFER = "payouts-transfer",
  CARD = "payouts-card",
  CASH = "payouts-cash",
}

enum processorTypeEnum {
  AGGREGATOR = "aggregator",
  GATEWAY = "gateway",
}

export enum processorTypeLabelEnum {
  PAYOUTS_CARD = "payoutsCard",
}

export enum filterLabels {
  CARD = "Tarjeta",
  TRANSFER = "Transferencia",
  CASH = "Efectivo",
  SUBSCRIPTIONS = "Débito recurrente",
  AGGREGATOR = "Agregador",
  GATEWAY = "Gateway",
  ENABLED = "Habilitado",
  DISABLED = "Deshabilitado",
  TRANSF_INTERNATIONAL = "Transf. Internacional",
}

export const DEFAULT_FILTERS: IOptionFilter[] = [
  {
    id: ProcessorProperties.PAYMENT_METHOD_OUT,
    options: [
      {
        key: paymentMethodPayInEnum.CARD,
        label: filterLabels.CARD,
        selected: false,
      },
      {
        key: paymentMethodPayInEnum.TRANSFER,
        label: filterLabels.TRANSFER,
        selected: false,
      },
      {
        key: paymentMethodPayInEnum.CASH,
        label: filterLabels.CASH,
        selected: false,
      },
      {
        key: paymentMethodPayInEnum.SUBSCRIPTIONS,
        label: filterLabels.SUBSCRIPTIONS,
        selected: false,
      },
    ],
    title: "Medios de pago - Pay In",
  },
  {
    id: ProcessorProperties.PAYMENT_METHOD_IN,
    options: [
      {
        key: paymentMethodPayOutEnum.TRANSFER,
        label: filterLabels.TRANSFER,
        selected: false,
      },
      {
        key: paymentMethodPayOutEnum.CASH,
        label: filterLabels.CASH,
        selected: false,
      },
    ],
    title: "Medios de pago - Pay Out",
  },
  {
    id: ProcessorProperties.PROCESSOR_TYPE,
    options: [
      {
        key: processorTypeEnum.AGGREGATOR,
        label: filterLabels.AGGREGATOR,
        selected: false,
      },
      {
        key: processorTypeEnum.GATEWAY,
        label: filterLabels.GATEWAY,
        selected: false,
      },
    ],
    title: "Modelo",
  },
  {
    id: ProcessorProperties.PROCESSOR_STATUS,
    options: [
      {
        key: ProcessorStatusEnum.ENABLED,
        label: filterLabels.ENABLED,
        selected: false,
      },
      {
        key: ProcessorStatusEnum.DISABLED,
        label: filterLabels.DISABLED,
        selected: false,
      },
    ],
    title: "Estado",
  },
];

export enum NotificationsTextsEnum {
  COULD_NOT_GET_PROCESSORS = "Lo sentimos, no pudimos obtener procesadores de este comercio, inténtalo más tarde",
}
