import React, { PropsWithChildren } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { get, isUndefined } from "lodash";
import { ITEM_SIMPLE_CONDITION } from "../../../../../../shared/infrastructure/enums/ItemByTagEnum";
import { IRadioGroupContainerProps } from "../../../../../../shared/infrastructure/interfaces/IRadioGroupContainerProps";

export const RadioGroupContainer: React.FC<IRadioGroupContainerProps> = (
  props: PropsWithChildren<IRadioGroupContainerProps>
) => {
  return (
    <>
      <RadioGroup
        key={props.simpleCondition}
        row
        defaultValue={
          !isUndefined(props.simpleCondition) && props.simpleCondition
        }
        value={!isUndefined(props.simpleCondition) && props.simpleCondition}
        onChange={props.selectSimpleCondition}
      >
        <FormControlLabel
          {...props.selectProps}
          value={get(ITEM_SIMPLE_CONDITION[props.tag], "firstValue", "")}
          control={<Radio color={"primary"} />}
          label={get(ITEM_SIMPLE_CONDITION[props.tag], "firstValueLabel", "")}
          disabled={props.isDisable}
        />
        <FormControlLabel
          {...props.selectProps}
          value={get(ITEM_SIMPLE_CONDITION[props.tag], "secondValue", "")}
          control={<Radio color={"primary"} />}
          label={get(ITEM_SIMPLE_CONDITION[props.tag], "secondValueLabel", "")}
          disabled={props.isDisable}
        />
      </RadioGroup>
    </>
  );
};
