import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const chargebackDetailStyles = createNamedStyles({
  containerSnackbar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "4px",
    gap: "8px",
    flex: "none",
    order: 10,
    flexGrow: 0,
    marginLeft: "-2px !important",
  },
  gridDetailChargeback: {
    paddingTop: "0px !important",
    paddingBottom: "16px !important",
  },
  iconButton: {
    paddingLeft: "-10px !important",
    paddingRight: "2px !important",
    color: "#6D7781 !important",
  },
  label: {
    color: "#023365",
    lineHeight: " 140%",
    letterSpacing: "-0.015em",
    paddingTop: 5,
  },
  textDetailChargebackTitle: {
    color: "#6D7781",
  },
  textDetailChargeback: {
    color: "#293036",
    fontSize: {
      xs: "15px",
      md: "15px",
    },
    fontWeight: 400,
    lineHeight: "144%",
  },
  textSnackbar: {
    fontSize: "16px !important",
    fontFamily: "IBM Plex Sans !important",
    fontStyle: "normal !important",
    color: "#6D7781",
    paddingLeft: "-8px !important",
  },
  textTicketNumber: {
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: {
      md: "15px",
      xs: "15px",
    },
    lineHeight: "144%",
    color: "#293036",
  },
  titleTransactionData: {
    fontWeight: 500,
    paddingBottom: "-10px !important",
  },
  textInformationMerchant: {
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: {
      md: "16px",
      xs: "16px",
    },
    lineHeight: "150% !important",
    color: "#6D7781",
    flex: "none",
    order: 0,
    flexGrow: 0,
  },
  chargebackDetail: {
    paddingBottom: "2px !important",
  },
  merchantInfoContainer: {
    paddingBottom: "4px !important",
    paddingTop: "5px !important",
  },
  chipContainer: {
    paddingTop: "0px !important",
    paddingBottom: "28.5px !important",
  },
  containerSnackbarInformation: {
    paddingLeft: "-10px !important",
    paddingTop: "41px !important",
    paddingBottom: "40px !important",
  },
});
