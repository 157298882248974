import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    filterPopover: {
      "&.MuiPopover-root .MuiInput-underline.Mui-focused:after": {
        borderBottom: "2px solid #0DC298",
      },
      "&.MuiPopover-root .MuiInput-underline.Mui-focused:before": {
        borderBottom: "2px solid #0DC298",
      },
      "&.MuiPopover-root .MuiInput-underline:focus:not(.Mui-disabled):before": {
        borderBottom: "2px solid #0DC298",
      },
      "&.MuiPopover-root .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "2px solid #0DC298",
      },
    },
  })
);
