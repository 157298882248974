import { createSlice } from "@reduxjs/toolkit";
import { getSmartLinks, getVpos } from "../../thunks/services/services.thunk";
import { IServicesAppState } from "../../interfaces/Services.interfaces";

export const initialState: IServicesAppState = {
  isLoadingSmartlinks: false,
  isLoadingVpos: false,
  smartlinks: {
    status: false,
    webcheckoutStatus: false,
  },
  vpos: {
    active: false,
  },
};

export const servicesAppSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getSmartLinks.pending, (state) => {
        state.isLoadingSmartlinks = true;
      })
      .addCase(getSmartLinks.fulfilled, (state, action) => {
        state.smartlinks = action.payload;
        state.isLoadingSmartlinks = false;
      })
      .addCase(getSmartLinks.rejected, (state) => {
        state.isLoadingSmartlinks = false;
      });
    builder
      .addCase(getVpos.pending, (state) => {
        state.isLoadingVpos = true;
      })
      .addCase(getVpos.fulfilled, (state, action) => {
        state.vpos = action.payload;
        state.isLoadingVpos = false;
      })
      .addCase(getVpos.rejected, (state) => {
        state.isLoadingVpos = false;
      });
  },
  initialState,
  name: "servicesApp",
  reducers: {},
});

export default servicesAppSlice.reducer;
