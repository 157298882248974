import { MenuPathKeysEnum } from "../infrastructure/MenuPathKeysEnum";
import { ConfigsEnum } from "../infrastructure/ConfigsEnum";
import { includes, isEmpty } from "lodash";
import { CountryEnum, DEFERRED_COUNTRIES } from "../infrastructure/CountryEnum";

export const getConfigsByStep = (
  step: MenuPathKeysEnum,
  country?: string
): string[] => {
  switch (step) {
    case MenuPathKeysEnum.MERCHANT_DATA:
      return [
        ConfigsEnum.CN001,
        ConfigsEnum.CN002,
        ConfigsEnum.CN015,
        ConfigsEnum.CN016,
      ];
    case MenuPathKeysEnum.BILLING_CONFIGURATION:
      return !isEmpty(country) && country === CountryEnum.ECUADOR
        ? [ConfigsEnum.CN017, ConfigsEnum.CN003]
        : [ConfigsEnum.CN003, ConfigsEnum.CN004];
    case MenuPathKeysEnum.RATES_AND_DISCOUNTS:
      return [ConfigsEnum.CN005, ConfigsEnum.CN006, ConfigsEnum.CN018];
    case MenuPathKeysEnum.SERVICES:
      return [ConfigsEnum.CN011];
    case MenuPathKeysEnum.PROCESSING:
      return !isEmpty(country) && includes(DEFERRED_COUNTRIES, country)
        ? [ConfigsEnum.CN007, ConfigsEnum.CN008, ConfigsEnum.CN010]
        : [ConfigsEnum.CN007, ConfigsEnum.CN008];
    case MenuPathKeysEnum.DEVELOPERS:
      return [ConfigsEnum.CN014];
    default:
      return [];
  }
};
