import React, { ChangeEvent, DragEvent, useEffect, useState } from "react";
import {
  IErrorUpload,
  IFileUpload,
  IUseFileUploadContainerProps,
} from "../FileUploadContainer.interfaces";
import { IUseFileUpload } from "./useFileUploadContainer.interfaces";
import {
  DragTypeEnum,
  FileStatusEnum,
  FileValidation,
  MessageError,
} from "@shared/enum/FileUploadEnum";
import { errorUploadingFileInit } from "@shared/constants/FileUpload";
import { uploadingFileDelay } from "@shared/utils/uploadFileDelay";
import { defaultTo, filter, isEmpty, isNil, sumBy } from "lodash";
import { useAppDispatch } from "@store/hooks/storeHook";
import { setUploadedFiles } from "@store/reducers/alarms/alarms.slice";

export const useFileUploadContainer = ({
  totalMaxSize,
}: IUseFileUploadContainerProps): IUseFileUpload => {
  const [dragActive, setDragActive] = React.useState(false);
  const [filesSelected, setFilesSelected] = useState<IFileUpload[]>([]);
  const [fileStatus, setFileStatus] = useState<string>(FileStatusEnum.DEFAULT);
  const [errorUploadingFile, setErrorUploadingFile] = useState<IErrorUpload>(
    errorUploadingFileInit
  );
  const [filteredFiles, setFilteredFiles] = useState<File[]>([]);
  const dispatch = useAppDispatch();

  const removeFileFromFileList = (index: number, files: File[]): File[] =>
    filter(files, (_, i) => i !== index);

  const fileValidations = (
    trueCondition: boolean,
    falseCondition: boolean,
    msgErrorTrue: string,
    msgErrorFalse: string
  ): object => {
    return {
      ["false"]: {
        condition: falseCondition,
        msgError: msgErrorFalse,
      },
      ["true"]: {
        condition: trueCondition,
        msgError: msgErrorTrue,
      },
    };
  };

  const changeUploadFileProcess = async (files: FileList) => {
    setErrorUploadingFile(errorUploadingFileInit);
    setFileStatus(FileStatusEnum.UPLOADING);
    await uploadingFileDelay(1000);

    const filesData: IFileUpload[] = [];
    let totalFilesSize: number = sumBy(filesSelected, "size");
    let filesList: File[] = Array.from(files);

    Array.from(files).map((file, index) => {
      const type = file.type.split("/")[1];
      const size = file.size / 1000000;
      const name = file.name;
      const closeAlarmCondition: boolean = size > FileValidation.MAX_SIZE;

      totalFilesSize += size;
      const initInvestigationCondition: boolean =
        size > defaultTo(totalMaxSize, 0) ||
        totalFilesSize > defaultTo(totalMaxSize, 0);

      const validations: object = fileValidations(
        closeAlarmCondition,
        initInvestigationCondition,
        MessageError.MAX_SIZE,
        MessageError.TOTAL_MAX_SIZE
      );

      if (validations[`${isNil(totalMaxSize)}`].condition) {
        setErrorUploadingFile({
          errorFile: true,
          message: validations[`${isNil(totalMaxSize)}`].msgError,
        });

        isEmpty(filesSelected)
          ? setFileStatus(FileStatusEnum.DEFAULT)
          : setFileStatus(FileStatusEnum.WITH_FILE);
        filesList = removeFileFromFileList(index, filesList);
      } else {
        filesData.push({
          name,
          size,
          type,
        });
      }
    });

    for (let i = 0; i < filesData.length; i++) {
      setFilesSelected((prevState: IFileUpload[]) => {
        const filteredPrevState: IFileUpload[] = prevState.filter(
          ({ name }) => !filesData.find((file) => file.name === name)
        );

        return [...filteredPrevState, ...filesData];
      });
      setFileStatus(FileStatusEnum.WITH_FILE);
    }
    setFilteredFiles((prevState: File[]) => [
      ...Array.from(filesList),
      ...prevState.filter(
        ({ name }) => !filesData.find((file) => file.name === name)
      ),
    ]);
  };

  useEffect(() => {
    if (isEmpty(filesSelected)) setFilteredFiles([]);
    setFilteredFiles(
      defaultTo(filteredFiles, []).filter((file) =>
        filesSelected.find(({ name }) => file.name === name)
      )
    );

    return () => {
      setFilteredFiles([]);
    };
  }, [filesSelected]);

  useEffect(() => {
    dispatch(setUploadedFiles(filteredFiles));
  }, [filteredFiles]);

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!e.target.files || !e.target.files[0]) {
      setFileStatus(FileStatusEnum.DEFAULT);

      return setFilesSelected([]);
    }
    const files: FileList = e.target.files;

    await changeUploadFileProcess(files);
  };

  const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (!e.dataTransfer.files || !e.dataTransfer.files[0]) {
      setFileStatus(FileStatusEnum.DEFAULT);

      return setFilesSelected([]);
    }
    const files: FileList = e.dataTransfer.files;

    await changeUploadFileProcess(files);
  };

  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === DragTypeEnum.ENTER || e.type === DragTypeEnum.OVER) {
      setDragActive(true);
    } else if (e.type === DragTypeEnum.LEAVE) {
      setDragActive(false);
    }
  };

  const handleCloseFile = (fileName: string) => {
    if (isEmpty(filesSelected)) {
      setFileStatus(FileStatusEnum.DEFAULT);

      return;
    }

    setFilesSelected((prevState: IFileUpload[]) =>
      prevState.filter(({ name }) => fileName !== name)
    );
  };

  return {
    handlers: {
      handleChange,
      handleCloseFile,
      handleDrag,
      handleDrop,
    },
    state: {
      dragActive,
      errorUploadingFile,
      filesSelected,
      fileStatus,
    },
  };
};
