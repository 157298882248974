import React, { Fragment } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import xlsxIcon from "../../assets/image/xlsx.svg";
import step1 from "../../assets/image/step1-csv.svg";
import { FileUploadSubCardComponent } from "../FileUploadSubCardComponent/FileUploadSubCardComponent";
import { fileUploadCardComponentEcuadorStyle } from "../FileUploadCardComponentEcuador/FileUploadCardComponentEcuador.style";
import { FileUploadCardComponentState } from "./FileUploadCardComponentEcuador.interfaces";
import step2 from "../../assets/image/step2-csv.svg";
import { FileUpload } from "../common/FileUpload/FileUpload";
import { FormatUpload } from "../../shared/infrastructure/Enums/FormatUpload";

export type FileUploadCardComponentProps = FileUploadCardComponentState;

export const FileUploadCardComponentEcuador: React.FC<FileUploadCardComponentProps> = (
  props: FileUploadCardComponentProps
) => {
  const classes = fileUploadCardComponentEcuadorStyle(0);
  return (
    <>
      <Card>
        <CardContent>
          <Container>
            <Grid container spacing={3} direction="row">
              <Grid item xs={8}>
                <Typography
                  className={classes.labelTitle}
                  variant="h5"
                  align="left"
                  color="inherit"
                >
                  <b>
                    Instrucciones para configuración de catálogos de impuestos y
                    retenciones para comercios
                  </b>
                </Typography>
                <Box
                  display="flex"
                  flexGrow={1}
                  alignItems={"center"}
                  height={"100%"}
                >
                  <Typography
                    className={classes.labelDescription}
                    variant="h5"
                    align="left"
                    color="inherit"
                  >
                    Es necesario abrir los archivos de Google Sheets para que el
                    sistema los reconozca.
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box
                  display="flex"
                  flexGrow={1}
                  flexDirection="row"
                  alignItems={"center"}
                  justifyContent={"end"}
                  height={"100%"}
                >
                  <img src={xlsxIcon} alt="xlsxIcon" />
                </Box>
              </Grid>
            </Grid>
            <br />
            <br />
            <Grid container spacing={3} direction="row">
              <Grid item xs={12}>
                <Box
                  display="flex"
                  flexGrow={1}
                  alignItems={"center"}
                  height={"100%"}
                >
                  <Typography
                    className={classes.labelTitleStep}
                    variant="h5"
                    align="left"
                    color="inherit"
                  >
                    Pasos
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <FileUploadSubCardComponent
                  labelTitleInitial={true}
                  imgSource={step1}
                  titleCard={"Descarga y completa la plantilla"}
                  subtitleCard={
                    <div>
                      En este archivo .xlsx encontrarás tres pestañas:
                      <ul>
                        <li>
                          Pestaña 1 “Actividad”: aquí debes completar el código
                          y descripción correspondiente.
                        </li>
                        <li>
                          Pestaña 2 “Tipo de contribuyente”: aquí debes
                          completar el código y descripción correspondiente.
                        </li>
                        <li>
                          Pestaña 3 “Concepto”: aquí debes completar el código y
                          descripción correspondiente.
                        </li>
                      </ul>
                    </div>
                  }
                  reactBtn={
                    <Fragment>
                      <Button
                        id={"btnDownloadTemplateCsv"}
                        variant="outlined"
                        startIcon={<GetApp />}
                        size={"medium"}
                        disabled={props.isLoadingDownloadFile}
                        className={classes.contentWidth}
                        onClick={props.downloadTemplateCsv}
                      >
                        Descargar
                      </Button>

                      <Typography
                        className={classes.LabelTitleDownload}
                        variant="body2"
                        align="left"
                        color="inherit"
                      >
                        Archivo con datos actuales
                      </Typography>
                      <Typography
                        className={classes.subLabelTitleDownload}
                        variant="body2"
                        align="left"
                        color={"inherit"}
                      >
                        En este archivo .xlsx encontrarás las configuraciones
                        actuales de las retenciones.
                      </Typography>
                      <Button
                        id={"btnDownloadTemplateExcel"}
                        variant="outlined"
                        startIcon={<GetApp />}
                        size={"medium"}
                        className={classes.contentWidth}
                        disabled={props.isLoadingDownloadFileData}
                        onClick={props.downloadTemplateExcel}
                      >
                        Descargar
                      </Button>
                    </Fragment>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FileUploadSubCardComponent
                  imgSource={step2}
                  titleCard={"Agregar el archivo a continuación"}
                  subtitleCard={
                    "Selecciona el archivo .xlsx con los catálogos que deseas cargar."
                  }
                  reactBtn={
                    <Box
                      pt={1}
                      display="flex"
                      flexGrow={1}
                      flexDirection="row"
                      alignItems={"start"}
                    >
                      <FileUpload
                        prop={"notificationFile"}
                        files={
                          props.fileUploader!.files !== undefined
                            ? [props.fileUploader!.files![0]]
                            : undefined
                        }
                        label={`Haz clic en "Elegir archivo" para buscar el
                          documento en tu dispositivo`}
                        maxSize={25}
                        onChange={props.fileUploader!.handlerCaptureFile}
                        onDelete={props.fileUploader!.handlerDelete}
                        multiple={false}
                        handlerError={props.fileUploader!.handleSetError}
                        formatUpload={FormatUpload.xlsx}
                      />
                    </Box>
                  }
                />
              </Grid>
            </Grid>
          </Container>
        </CardContent>
      </Card>
    </>
  );
};
