import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import { Box, Checkbox, ThemeProvider, Typography } from "@mui/material";
import { dropdownItemStyle } from "./DropdownItem.styles";
import ListItemIcon from "@mui/material/ListItemIcon";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import theme from "../../Themes/themekushki";
import {
  IDropdownItemAdditionalProps,
  IDropdownItemProps,
} from "./DropdownItem.interfaces";

const DropDownItem = ({
  icon,
  id,
  isDisable = false,
  onSelect,
  onChecked,
  text,
  text2,
  variant = "text",
  handleClose,
}: IDropdownItemProps & IDropdownItemAdditionalProps) => (
  <ThemeProvider theme={theme}>
    <ListItemButton
      data-testid={"listButtons"}
      onClick={(e) => {
        e.stopPropagation();
        onSelect && onSelect();
        handleClose();
      }}
      disabled={isDisable}
      sx={dropdownItemStyle.root}
      key={id}
    >
      <Box sx={dropdownItemStyle.rowBox}>
        {variant === "withIcon" && (
          <ListItemIcon sx={dropdownItemStyle.icon}>{icon}</ListItemIcon>
        )}
        {variant === "checked" && (
          <Checkbox
            data-testid={"checkbox"}
            sx={dropdownItemStyle.checkBox}
            onChange={(event) => {
              if (onChecked) {
                onChecked(event.target.checked, id);
              }
            }}
            checkedIcon={<CheckBoxOutlinedIcon />}
            edge="start"
            tabIndex={-1}
            disableRipple
          />
        )}
        <Box sx={dropdownItemStyle.rowColumn}>
          <Typography variant={"body2"} sx={dropdownItemStyle.primaryText}>
            {text}
          </Typography>
          {text2 && (
            <Typography
              variant={"overline"}
              sx={dropdownItemStyle.secondaryText}
            >
              {text2}
            </Typography>
          )}
        </Box>
      </Box>
    </ListItemButton>
  </ThemeProvider>
);

export default DropDownItem;
