import { defaultTo, get } from "lodash";
import React from "react";
import { ModalHeader } from "@kushki/frontend-molecules/modal-header";
import { InvoiceRecord } from "../../../types/remote/response_search_blling_by_filters";
import {
  setBackgroundColor,
  setColor,
} from "../../shared/infrastructure/Colors";
import { KindEnum } from "../../shared/infrastructure/KindEnum";
import { ModalStyles } from "../Common/ModalCard/ModalStyles";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import { InvoiceModeEnum } from "../../shared/infrastructure/InvoiceModeEnum";
import { BillingTransactionTypeEnum } from "../../shared/infrastructure/BillingTransactionTypeEnum";
import { getCatalogFinalStatusInfo } from "../../shared/infrastructure/CatalogFinalStatus";
import { getTransactionTypeTraduce } from "../../shared/infrastructure/TransactionTypeTraduce";
import { translate } from "../../shared/infrastructure/LanguageCatatog";
import {
  FinalStatusEnum,
  InitialStatusEnum,
  StatusEnum,
} from "../../shared/infrastructure/StatusEnum";
import { formatDate } from "../../shared/utils/date-time-util";
import { additionalServiceKey } from "../../shared/infrastructure/enum/AdicionalServicesEnum";
import { TRADUCE_TYPE } from "../../shared/infrastructure/TransactionTypeEnum";
import { is3DSbilling } from "../../shared/infrastructure/constants/AditionalServicesConstants";
import { TitlesEnum } from "../../shared/infrastructure/enum/TitlesEnum";

export interface TrxInfoProps {
  trxInfo: InvoiceRecord | undefined;
  isMinAmount: boolean;
}

export interface IChip {
  background?: string;
  color?: string;
  value?: string;
}

const ModalHeaderInfo = (props: TrxInfoProps) => {
  const trx: InvoiceRecord | undefined = get(props, "trxInfo", {});
  const classes = ModalStyles();

  const setTittle = (kind: string, invoiceMode?: string): string => {
    let title: string;

    switch (kind) {
      case KindEnum.RETENTION:
        title = TitlesEnum.RETENTION_DETAIL;
        break;
      case KindEnum.RETENTION_EC:
        title = TitlesEnum.RETENTION_DETAIL_EC;
        break;
      case KindEnum.INVOICE:
        if (invoiceMode === InvoiceModeEnum.WITH_IVA)
          title = TitlesEnum.INVOICE_WITH_IVA;
        else if (invoiceMode === InvoiceModeEnum.WITHOUT_IVA)
          title = TitlesEnum.INVOICE_WITHOUT_IVA;
        else if (is3DSbilling(trx))
          title = TitlesEnum.ADITIONAL_SERVICE_THREE_DS;
        else title = TitlesEnum.INVOICE_DETAIL;
        break;
      case KindEnum.CHARGE:
        title = TitlesEnum.CHARGE_DETAIL;
        break;
      case KindEnum.CREDIT_NOTE:
        title = TitlesEnum.CREDIT_NOTE_DETAIL;
        break;
      case KindEnum.DEBIT_NOTE:
        title = TitlesEnum.DEBIT_NOTE_DETAIL;
        break;
      case KindEnum.DISPERSION:
        title = TitlesEnum.DISPERSION_DETAIL;
        break;
      case KindEnum.VOUCHER:
        title = TitlesEnum.VOUCHER_DETAIL;
        break;
      case KindEnum.ANNUL_INVOICE:
        if (invoiceMode === InvoiceModeEnum.WITH_IVA)
          title = TitlesEnum.ANNUL_INVOICE_WITH_IVA;
        else if (invoiceMode === InvoiceModeEnum.WITHOUT_IVA)
          title = TitlesEnum.ANNUL_INVOICE_WITHOUT_IVA;
        else title = TitlesEnum.INVOICE_DETAIL;
        break;
      case KindEnum.RECEIVABLE:
        title = TitlesEnum.RECEIVABLE_DETAIL;
        break;
      default:
        title = "";
        break;
    }

    return title;
  };
  const hasPayinPayoutCommission = (): IChip[] => {
    const getColor = getCatalogFinalStatusInfo(
      get(trx, "kind", ""),
      get(trx, "country", ""),
      get(trx, "status", "")
    );

    const chipList: IChip[] = [
      {
        background:
          trx.kind === KindEnum.ANNUL_INVOICE &&
          trx.status === StatusEnum.PENDING
            ? "#FEECF8"
            : [
                KindEnum.INVOICE,
                KindEnum.CREDIT_NOTE,
                KindEnum.CHARGE,
                KindEnum.DISPERSION,
                KindEnum.VOUCHER,
                KindEnum.DEBIT_NOTE,
              ].includes(trx.kind)
            ? getColor.backgroundColor
            : setBackgroundColor(trx.status),
        color:
          trx.kind === KindEnum.ANNUL_INVOICE &&
          trx.status === StatusEnum.PENDING
            ? "#8F4F8B"
            : trx.kind === KindEnum.INVOICE ||
              trx.kind === KindEnum.CREDIT_NOTE ||
              trx.kind === KindEnum.ANNUL_INVOICE ||
              trx.kind === KindEnum.CHARGE ||
              trx.kind === KindEnum.VOUCHER ||
              trx.kind === KindEnum.DISPERSION ||
              trx.kind === KindEnum.DEBIT_NOTE
            ? getColor.color
            : setColor(trx.status),
        value:
          trx.kind === KindEnum.INVOICE ||
          trx.kind === KindEnum.CREDIT_NOTE ||
          trx.kind === KindEnum.CHARGE ||
          trx.kind === KindEnum.VOUCHER ||
          trx.kind === KindEnum.DISPERSION ||
          trx.kind === KindEnum.ANNUL_INVOICE ||
          trx.kind === KindEnum.DEBIT_NOTE
            ? getColor.value
            : translate(get(trx, "status", "")),
      },
    ];

    if (is3DSbilling(trx)) {
      chipList.push({
        background: "#F0F4F9",
        color: "inherit",
        value: TRADUCE_TYPE[additionalServiceKey.KEY],
      });
      return chipList;
    }

    if (
      trx.transaction_type !==
        BillingTransactionTypeEnum.AUTOMATIC_COMMISSION &&
      ![KindEnum.VOUCHER, KindEnum.DISPERSION, KindEnum.RECEIVABLE].includes(
        trx.kind
      )
    ) {
      chipList.push({
        background: "#F0F4F9",
        color: "inherit",
        value: getTransactionTypeTraduce(get(trx, "transaction_type", "")),
      });
    } else {
      const defaultValues: object = {
        background: "#F0F4F9",
        color: "inherit",
      };
      if (
        trx.transaction_type !== BillingTransactionTypeEnum.AUTOMATIC_COMMISSION
      )
        chipList.push({
          ...defaultValues,
          value: translate(get(trx, "transaction_type", "")),
        });
    }
    if (trx.transaction_type !== BillingTransactionTypeEnum.MANUAL_FROM_FILE) {
      chipList.push({
        background: "#F0F4F9",
        color: "inherit",
        value: translate("fromconsole"),
      });
    }
    return chipList;
  };

  return trx.country === CountryEnum.ecuador &&
    trx.kind === KindEnum.RETENTION_EC ? (
    <ModalHeader
      className={classes.modalHeader}
      highlightValue={trx.social_reason}
      tittle={setTittle(trx.kind, trx.invoice_mode)}
      secondaryValue={trx.tax_id}
      subtitle={"Tax ID"}
    />
  ) : trx.country === CountryEnum.colombia &&
    trx.kind === KindEnum.RETENTION_CO &&
    trx.status === FinalStatusEnum.OMITTED &&
    trx.initial_status === FinalStatusEnum.OMITTED ? (
    <ModalHeader
      className={classes.modalHeader}
      highlightValue={trx.social_reason}
      tittle={setTittle(trx.kind, trx.invoice_mode)}
      secondaryValue={`${
        defaultTo(get(trx, "is_exclusive"), false)
          ? translate("exclusive")
          : translate(trx.cycle)
      } ${formatDate(trx.start_date, "dd/MM/yyyy")} - ${formatDate(
        trx.end_date,
        "dd/MM/yyyy"
      )}`}
      subtitle={"Merchant ID"}
      chipList={[
        {
          background: "#F0F4F9",
          color: "inherit",
          value: translate(get(trx, "initial_status", "")),
        },
      ]}
    />
  ) : trx.country === CountryEnum.colombia &&
    [StatusEnum.COMPLETED, StatusEnum.REJECTED].includes(trx.status) &&
    [InitialStatusEnum.COMPLETED, InitialStatusEnum.REJECT].includes(
      trx.initial_status
    ) ? (
    <ModalHeader
      className={classes.modalHeader}
      highlightValue={trx.merchant_name}
      tittle={setTittle(trx.kind)}
      secondaryValue={trx.tax_id}
      subtitle={"Tax ID"}
      chipList={[
        {
          background: setBackgroundColor(trx.status),
          color: setColor(trx.status),
          value: translate(
            trx.status === StatusEnum.COMPLETED
              ? StatusEnum.APPROVED
              : trx.status
          ),
        },
      ]}
    />
  ) : (
    <ModalHeader
      className={classes.modalHeader}
      highlightValue={trx.social_reason}
      tittle={setTittle(trx.kind, trx.invoice_mode)}
      secondaryValue={`${
        defaultTo(get(trx, "is_exclusive"), false)
          ? translate("exclusive")
          : translate(trx.cycle)
      } ${formatDate(trx.start_date, "dd/MM/yyyy")} - ${formatDate(
        trx.end_date,
        "dd/MM/yyyy"
      )}`}
      chipList={hasPayinPayoutCommission()}
    />
  );
};

export default ModalHeaderInfo;
