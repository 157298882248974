import React from "react";
import { CircularProgress, TextField, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { get } from "lodash";
import { Autocomplete, AutocompleteRenderInputParams } from "@material-ui/lab";
import { SecurityRulesCredentials } from "../../shared/infrastructure/constants/SecurityRulesConstants";

export interface AutocompleteTextFieldSuggestedComponentProps {
  property: string;
  label: string;
  value: string;
  index: number;
  options: object[];
  pathValue: string;
  pathName: string;
  isLoading: boolean;
  renderOption?: string;
  handleEnterSearchText: (key: string) => void;
  handleSetSearchText: (searchText: string) => void;
  setValue: (value: object, index: number) => void;
  disabled: boolean;
  error: boolean;
  errorMessage: string;
}

const useStyles = makeStyles(() => ({
  textField: {
    marginTop: 0,
  },
  publicCredential: {
    fontSize: 14,
    color: "#293036",
  },
  masterCredential: {
    fontSize: 12,
    color: "#293036",
  },
  aliasName: {
    fontSize: 12,
    color: "#023365",
  },
}));

export const AutocompleteTextFieldSuggestedComponent: React.FC<AutocompleteTextFieldSuggestedComponentProps> =
  (props: AutocompleteTextFieldSuggestedComponentProps) => {
    const classes = useStyles();
    return (
      <React.Fragment>
        <Autocomplete
          id={"autocomplete"}
          onKeyDown={(event) => props.handleEnterSearchText(event.key)}
          inputValue={props.value}
          options={props.options}
          disabled={props.disabled}
          onInputChange={(_event: any, newInputValue) =>
            props.handleSetSearchText(newInputValue)
          }
          onChange={(_event: any, value: any) =>
            props.setValue(value, props.index)
          }
          getOptionLabel={(option) => get(option, props.pathName, "")}
          renderOption={(option) => (
            <React.Fragment>
              <Grid container xs={12} md={12} lg={12} spacing={3} item>
                <Grid container item>
                  <Grid item xs={6}>
                    <div className={classes.publicCredential}>
                      {get(option, "publicCredentialId", "")}
                    </div>
                  </Grid>
                  <Grid
                    xs={12}
                    md={12}
                    lg={12}
                    spacing={3}
                    className={classes.aliasName}
                  >
                    {get(option, "alias", "")}
                  </Grid>
                  <Grid xs={12} md={12} lg={12} spacing={3}>
                    {get(option, "type", "") ===
                    SecurityRulesCredentials.MASTER_CREDENTIAL ? (
                      <b>
                        <div className={classes.masterCredential}>
                          {SecurityRulesCredentials.CREDENTIAL_TITLE_MASTER}
                        </div>
                      </b>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {props.isLoading ? (
                      <CircularProgress color="primary" size={18} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              fullWidth
              className={classes.textField}
              error={props.error}
              disabled={props.disabled}
              label={props.label}
              variant="outlined"
              helperText={!!props.error && props.errorMessage}
            />
          )}
        />
      </React.Fragment>
    );
  };
