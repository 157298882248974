import { CurrencyEnum } from "./CurrencyEnum";

export const CurrencyCountry = {
  Chile: [{ key: CurrencyEnum.CLP, label: "CLP", selected: false }],
  Colombia: [{ key: CurrencyEnum.COP, label: "COP", selected: false }],
  Ecuador: [{ key: CurrencyEnum.USD, label: "USD", selected: false }],
  Mexico: [{ key: CurrencyEnum.MXN, label: "MXN", selected: false }],
  Peru: [
    { key: CurrencyEnum.PEN, label: "PEN", selected: false },
    { key: CurrencyEnum.USD, label: "USD", selected: false },
  ],
};
