import { useEffect, useState } from "react";
import { CurrencyEnum } from "../../../shared/infrastructure/CurrencyEnum";
import { get, set } from "lodash";
import { BillingInvoiceProps } from "../BillingInvoice";
import { CountryEnum } from "../../../shared/infrastructure/CountryEnum";
import { getCatalog } from "../../../shared/infrastructure/constants/MerchantInformationConstants";
import { CatalogsEnum } from "../../../shared/infrastructure/catalogs-enum";
import { InvoiceBilling, ItemInvoice } from "../../../../types/invoice_billing";
import { useHistory } from "react-router-dom";
import {
  Product,
  RenewInvoiceRequest,
} from "../../../../types/renew_invoice_request";
import { MessageNotificationEnum } from "../../../shared/infrastructure/MessageNotificationEnum";
import { routes } from "../../../shared/infrastructure/routes";

export interface BillingInvoiceState {
  redirectNextStep: (nextStepUrl: string) => void;
  handleCloseNotification: () => void;
  invoiceBilling: InvoiceBilling;
  form: {
    formChange: (
      name: string,
      value: string | boolean | number,
      isUpdated?: boolean
    ) => void;
    submit: () => void;
  };
}
export const useBillingInvoiceState = (
  props: BillingInvoiceProps
): BillingInvoiceState => {
  const history = useHistory();
  const [invoiceBilling, setinvoiceBilling] = useState({
    country: get(props, "country", CountryEnum.peru),
    merchant_name: get(props.historicTrx, "merchant_name", ""),
    invoice_id: get(props.historicTrx, "invoice_id", ""),
    tax_id: get(props.historicTrx, "tax_id", ""),
    selected_currency: get(
      props.historicTrx,
      "currency_code",
      CurrencyEnum.USD
    ),
    items: get(props.invoiceTrx, "information.products", []),
    subTotal: 0,
    taxes: 0,
    total: 0,
  });

  const calculateSubTotalAmount = (): void => {
    setinvoiceBilling((previnvoiceBilling) => {
      const subTotal = get(previnvoiceBilling, "items", []).reduce(
        (acc: number, invoice: object) => acc + get(invoice, "unitPrice"),
        0
      );

      return {
        ...previnvoiceBilling,
        subTotal,
      };
    });
  };

  const calculateTaxesAmount = (): void => {
    const taxes: number = getCatalog(
      invoiceBilling.country,
      CatalogsEnum.Taxes
    )[0].value as number;

    setinvoiceBilling((previnvoiceBilling) => ({
      ...previnvoiceBilling,
      taxes: previnvoiceBilling.subTotal * taxes,
    }));
  };

  const calculateTotalAmount = (): void => {
    setinvoiceBilling((previnvoiceBilling) => ({
      ...previnvoiceBilling,
      total: previnvoiceBilling.subTotal + previnvoiceBilling.taxes,
    }));
  };

  const loadSelectedCurrency = (): void => {
    setinvoiceBilling((previnvoiceBilling) => {
      let currency_code: string = get(
        props.historicTrx,
        "currency_code",
        getCatalog(previnvoiceBilling.country, CatalogsEnum.Currency)[0]
          .value as string
      );

      return {
        ...previnvoiceBilling,
        selected_currency: currency_code,
      };
    });
  };

  const redirectNextStep = (nextStepUrl: string) => {
    history.push(nextStepUrl);
  };

  useEffect(() => {
    return () => {
      props.setRedirectCreateInvoice(false);
      props.setOpenModalAnnul(false);
    };
  });

  useEffect(() => {
    props.setLoadingInvoice(true);
    props.getInvoiceData({ invoiceId: invoiceBilling.invoice_id });
  }, [invoiceBilling.invoice_id]);

  useEffect(() => {
    setinvoiceBilling((prevInvoiceBilling) => ({
      ...prevInvoiceBilling,
      merchant_name: get(props.historicTrx, "merchant_name", ""),
      invoice_id: get(props.historicTrx, "invoice_id", ""),
      tax_id: get(props.historicTrx, "tax_id", ""),
      items: get(props.invoiceTrx, "information.products", []),
    }));
  }, [props.invoiceTrx, props.historicTrx]);

  useEffect(() => {
    setinvoiceBilling((previnvoiceBilling) => ({
      ...previnvoiceBilling,
      country: get(props, "country", CountryEnum.peru),
    }));
    loadSelectedCurrency();
  }, [props.country]);

  useEffect(() => {
    setinvoiceBilling((previnvoiceBilling) => ({
      ...previnvoiceBilling,
      selected_currency: loadSelectedCurrency(),
    }));
  }, [invoiceBilling.country]);

  useEffect(() => {
    calculateSubTotalAmount();
    calculateTaxesAmount();
    calculateTotalAmount();
  }, [invoiceBilling.items]);

  useEffect(() => {
    calculateTaxesAmount();
    // Este useEffect y el de abajo se pueden unir en uno con dependencia de subTotal
  }, [invoiceBilling.subTotal]);

  useEffect(() => {
    calculateTotalAmount();
  }, [invoiceBilling.taxes]);

  const onSubmit = () => {
    handleSubmitDefault();
  };

  const handleSubmitDefault = () => {
    const createInvoiceRequest: RenewInvoiceRequest = {
      transactionId: get(props.historicTrx, "transaction_id", ""),
      country: props.country as CountryEnum,
      products: buildProducts(),
      taxId: invoiceBilling.tax_id,
      currencyCode: invoiceBilling.selected_currency,
    };
    props.createInvoice(createInvoiceRequest);
  };
  const buildProducts = (): Product[] => {
    return invoiceBilling.items.map((item: ItemInvoice) => {
      return {
        description: item.description,
        amount: get(item, "unitPrice"),
      };
    });
  };
  const handleFormChange = (
    name: string,
    value: string | boolean | number,
    isUpdated?: boolean
  ) => {
    setinvoiceBilling((previnvoiceBilling) => {
      const current_invoice = { ...previnvoiceBilling };
      set(current_invoice, name, value);
      return current_invoice;
    });

    if (isUpdated) calculateSubTotalAmount();
  };

  const handleCloseNotification = () => {
    props.setNotification({
      open: false,
      message: props.notification.message,
      type: props.notification.type,
    });
  };
  useEffect(() => {
    if (props.notification.message === MessageNotificationEnum.createInvoice)
      redirectNextStep(
        `${routes.BASE_PATH_DASHBOARD}${routes.DASHBOARD_EXECUTOR}`
      );
  }, [props.notification.message]);
  return {
    redirectNextStep,
    handleCloseNotification,
    invoiceBilling: invoiceBilling,
    form: {
      submit: onSubmit,
      formChange: handleFormChange,
    },
  };
};
