import React from "react";
import {
  ITableCellProps,
  ITableRowProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { get } from "lodash";
import { TableBodyCellEnum } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/constants";
import { HierarchyEnum } from "@shared/enum/HierarchyEnum";
import { CellFlagCountryType } from "@kushki/connect-ui/dist/Components/Atoms/Table/CellFlag/CellFlag.enum";
import { IconCopy } from "@kushki/connect-ui";
import { clipboard } from "@shared/utils/clipboardUtils";
import { INodeRuleSessionStorage } from "@shared/interfaces/INodeRuleSessionStorage";
import { Merchant } from "../../../types/search_merchants_response";

export const buildTextCell = (value: string): ITableCellProps => ({
  props: {
    cellProps: {
      align: "left",
      spacing: 1,
    },
    line1: value,
    type: "oneLine",
  },
  type: TableBodyCellEnum.TITLE,
});

export const buildClipboardTextCell = (value: string): ITableCellProps => ({
  props: {
    cellProps: {
      align: "center",
      spacing: 0,
    },
    icon: <IconCopy />,
    id: value,
    isDisabled: false,
    line1: "",
    line2: value,
    onSelected: async (value: { line1: string; line2: string; id: string }) => {
      await clipboard(value.line2);
    },
  },
  type: TableBodyCellEnum.TEXT_ACTION,
});

export const buildFlagCell = (value: string): ITableCellProps => ({
  props: {
    cellProps: {
      align: "center",
      spacing: 1,
    },
    country: value as CellFlagCountryType,
    type: "twolines",
  },
  type: TableBodyCellEnum.FLAG,
});

const buildOwnerCells = (row: Merchant): ITableCellProps[] => {
  const cells: ITableCellProps[] = [];

  cells.push(buildTextCell(get(row, "name", "")));

  return cells;
};

const buildCustomerCells = (row: Merchant): ITableCellProps[] => {
  const cells: ITableCellProps[] = [];

  cells.push(buildTextCell(get(row, "name", "")));
  cells.push(buildClipboardTextCell(get(row, "publicMerchantId", "")));
  cells.push(buildFlagCell(get(row, "constitutionalCountry", "")));

  return cells;
};

const buildBranchCells = (row: Merchant): ITableCellProps[] => {
  const cells: ITableCellProps[] = [];

  cells.push(buildTextCell(get(row, "name", "")));
  cells.push(buildClipboardTextCell(get(row, "publicMerchantId", "")));
  cells.push(buildFlagCell(get(row, "constitutionalCountry", "")));
  cells.push(buildFlagCell(get(row, "country", "")));
  cells.push(buildTextCell(get(row, "categoryMerchant", "")));

  return cells;
};

const buildCells = {
  [HierarchyEnum.CUSTOMER]: buildCustomerCells,
  [HierarchyEnum.OWNER]: buildOwnerCells,
  [HierarchyEnum.BRANCH]: buildBranchCells,
};

export const buildRows = (
  data: Merchant[],
  idField: keyof Merchant,
  actualView: string,
  selectedNodes: INodeRuleSessionStorage[]
): ITableRowProps[] => {
  return data.map((row: Merchant) => {
    const isChecked: boolean = !!selectedNodes.find(
      (node: INodeRuleSessionStorage) => node.nodeId === get(row, idField, "")
    );

    return {
      cells: buildCells[actualView](row),
      id: get(row, idField, "") as string,
      rowProps: {
        color: "default",
        isChecked,
        onClick: () => {},
      },
    };
  });
};

enum tableTitleLabels {
  LIST_OF = "Listado de ",
  OWNER = "Owners",
  CUSTOMER = "Customers",
  BRANCH = "Branches",
}

export const tableTitles = {
  [HierarchyEnum.CUSTOMER]: `${tableTitleLabels.LIST_OF}${tableTitleLabels.CUSTOMER}`,
  [HierarchyEnum.OWNER]: `${tableTitleLabels.LIST_OF}${tableTitleLabels.OWNER}`,
  [HierarchyEnum.BRANCH]: `${tableTitleLabels.LIST_OF}${tableTitleLabels.BRANCH}`,
};
