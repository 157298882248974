export enum COUNTRIES {
  BRAZIL = "Brazil",
}

export enum COUNTRIES_LABEL {
  BRASIL = "Brasil",
}

export const LABEL_COUNTRY_LIST: Record<string, string> = {
  [COUNTRIES.BRAZIL]: COUNTRIES_LABEL.BRASIL,
  [COUNTRIES_LABEL.BRASIL]: COUNTRIES.BRAZIL,
};
