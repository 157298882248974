import React, { FC } from "react";
import { DialogSuccessProps } from "./DialogSuccess.interfaces";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import confirmHeader from "../../assets/images/dialogHeader.svg";
import { useStyles } from "./DialogSuccess.styles";

const DialogSuccess: FC<DialogSuccessProps> = (props: DialogSuccessProps) => {
  const classes = useStyles();

  return (
    <Dialog open={props.open} classes={{ paper: classes.dialog }}>
      <img src={confirmHeader} alt={props.title} />
      <DialogTitle>
        <Typography className={classes.dialogTitle}>{props.title}</Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText>{props.description}</DialogContentText>
      </DialogContent>
      <DialogContent className={classes.dialogContentDescription}>
        <DialogContentText className={classes.dialogContentText}>
          {props.secondaryDescription}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={props.onClose} className={classes.closeButton}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogSuccess;
