/**
 * Security Wrapper ENUM
 */
export enum SecurityWrapperEnum {
  MODULE_ID = "M_MERCHANTS",
  RECHARGE_BALANCE = "M_MERCHANTS.Comercios.RecargarSaldo",
  DEBIT_BALANCE = "M_MERCHANTS.Comercios.DebitarSaldo",
  ACTIVATE_MERCHANTS = "M_MERCHANTS.Comercios.ActivarComercio",
  DEACTIVATE_MERCHANTS = "M_MERCHANTS.Comercios.DesactivarComercio",
  COMPLIANCE = "M_MERCHANTS.Comercios.Compliance",
}
