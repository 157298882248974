import React from "react";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from "@mui/material";
import { LabelsEnum } from "../../shared/constants/LabelsEnum";
import { dialogStyles } from "./Dialog.styles";

export interface LoadingDialogProps {
  open: boolean;
  isEdit: boolean;
}

export const LoadingDialog: React.FC<LoadingDialogProps> = (
  props: LoadingDialogProps
) => {
  return (
    <Dialog open={props.open} sx={dialogStyles.dialogLoading} fullWidth>
      <DialogContent>
        <DialogContentText>
          <Grid container>
            <Grid item xs={2}>
              <CircularProgress size={79} thickness={5} />
            </Grid>
            <Grid item xs={10} container alignItems="center" direction="row">
              <Grid item xs={12}>
                <Typography sx={dialogStyles.dialogSubtitle}>
                  {props.isEdit
                    ? LabelsEnum.UPDATING_PROCESSOR_LABEL
                    : LabelsEnum.SAVING_PROCESSOR_LABEL}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={dialogStyles.dialogSubtitle}>
                  {LabelsEnum.PLEASE_WAIT}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
