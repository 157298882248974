import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";

export const emptyTableStyle = makeStyles({
  container: {
    justifyContent: "center",
    width: theme.spacing(33.625),
  },
  description: {
    color: "#023365",
    fontSize: theme.spacing(2),
    fontWeight: 500,
    textAlign: "center",
  },
  image: {
    marginTop: theme.spacing(2.5),
  },
  title: {
    color: "#6D7781",
    fontSize: theme.spacing(1.625),
    fontWeight: 400,
    textAlign: "center",
  },
});
