import { makeStyles } from "@mui/styles";

export const cardSectionStyles = makeStyles({
  description: {
    color: "#293036 !important",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "150% !important",
  },
  rootCard: {
    borderRadius: "16px !important",
  },
});
