import { makeStyles } from "@material-ui/core/styles";

export const dateRangerPickerStyle = makeStyles({
  containerDateRange: {
    alignItems: "center",
    marginLeft: "15px",
    maxWidth: "80% !important",
  },
  dateRangePicker: {
    alignItems: "center",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    marginLeft: "5px",
    maxWidth: "fit-content",
  },
  dateRangeText: {
    fontSize: "16px !important",
    fontStyle: "normal",
    lineHeight: "24px !important",
  },
  reloadButton: {
    backgroundColor: "white",
    height: "24px",
    marginLeft: "5px",
    marginRight: "5px",
    minWidth: "0px",
    padding: "5px",
    width: "24px",
  },
  reloadIcon: {
    color: "#293036",
    size: "15px",
  },
});
