import { ReactNode } from "react";
import {
  IconCalculator,
  IconCardSwipe,
  IconCash,
  IconTransferCurve,
  IDropdownItemProps,
} from "@kushki/connect-ui";
import { GetProcessorResponse } from "../../../types/get_processor_response";
import { get, isEmpty, isUndefined, orderBy, set, remove } from "lodash";
import { AppRoutes } from "../infrastructure/routes";
import { getMerchantId, showUpdatedAt } from "../../utils/utilsFile";
import {
  PaymentMethodAlias,
  PaymentMethodEnum,
} from "../enums/PaymentMethodEnum";
import { format } from "date-fns-tz";
import { ButtonColorEnum } from "../enums/ButtonStylesEnum";
import {
  CENTRO_AMERICA_COUNTRIES,
  CountriesEnum,
} from "../infrastructure/enums/CountriesEnum";
import { translateProcessor } from "../../utils/utils";
import { TagColorEnum } from "../enums/TagColorEnum";

export enum ProcessorConstants {
  STATUS_DISABLED = "DISABLED",
  STATUS_ENABLED = "ENABLED",
  CHIP_DISABLED_LABEL = "DESHABILITADO",
  CHIP_ENABLED_LABEL = "HABILITADO",
  PROCESSORS = "Procesadores",
  PROCESSORS_DESCRIPTION = "Debes agregar al menos un <b> procesador.</b>",
  ADD_PROCESSOR = "Agregar procesador",
  DEFAULT = "Por defecto",
  FAIL_OVER_TEXT = "El failover asignado es",
  ACCEPT = "Aceptar",
  DELETE_PROCESSOR = "Eliminar procesador",
  MESSAGE_DELETE_DEFAULT_PROCESSOR = "Para eliminar este procesador deberás configurar otro procesador por defecto.",
  MESSAGE_DELETE_NOT_DEFAULT_PROCESSOR = "Si eliminas este procesador, esta acción no podrá deshacerse.",
  TITLE_DELETE_PROCESSOR = "¿Deseas eliminar este procesador?",
  CANCEL = "Cancelar",
  EMPTY = "",
  ACTIVE = "Activo",
  EDIT_REVIEW_ALL_PROCESSORS = "Edita y revisa todos los procesadores",
  REVIEW_ALL_PROCESSORS = "Revisa todos los procesadores",
  ORIGIN = "createMerchantV3",
  USERNAME_BO = "backoffice",
  PROCESSOR_ADDED_SUCCESSFUL = "Se agregó el procesador con éxito.",
  PROCESSOR_UPDATED_SUCCESSFUL = "Se actualizó el procesador con éxito.",
  ERROR_ON_PROCESSOR = "Se presentó un error inesperado. Inténtalo nuevamente.",
  FAILOVER_MESSAGE = "Debes <b>asignar Failover</b> a los procesadores de tarjeta agregados.",
  MASSIVE_MESSAGE = "Los cambios se aplicarán al <b>customer</b> y afectarán a todos los <b>branches centralizados</b>, incluyendo a los previamente creados",
  SELECT_FAILOVER_PROCESSOR_TITLE = "Selecciona un procesador para configurarlo como Failover",
  SELECT_PROCESSOR = "Seleccionar procesador",
  CONFIGURE = "Configurar",
  ALERT_DECENTRALIZE_PROCESSORS = "Para realizar esta configuración todos los branches deben estar descentralizados en procesadores",
  ALERT_DECENTRALIZE_PROCESSORS_EDIT_RULES = "Para realizar esta configuración todos los branches deben estar descentralizados para reglas de negocio y centralizados en procesadores",
}

export const ChipProcessorData: Record<
  string,
  { label: string; type: "default" | "warning" | "success" | "info" | "error" }
> = {
  [ProcessorConstants.STATUS_ENABLED]: {
    label: ProcessorConstants.CHIP_ENABLED_LABEL,
    type: TagColorEnum.Success,
  },
  [ProcessorConstants.STATUS_DISABLED]: {
    label: ProcessorConstants.CHIP_DISABLED_LABEL,
    type: TagColorEnum.Warning,
  },
};

export const dropdownProcessorItemValues = (
  cardIcon: ReactNode,
  transferIcon: ReactNode,
  debitIcon: ReactNode,
  cashIcon: ReactNode
): any[] => {
  return [
    {
      categoryName: "Tarjeta",
      categoryId: "card",
      categoryIcon: cardIcon,
      categoryItems: [
        {
          text: "Pay-In",
          onSelect: () => {
            window.location.href = AppRoutes.CREATE_CARD_PROCESSOR(
              getMerchantId()
            );
          },
          id: "pay-in-card",
          variant: "text",
        },
      ],
    },
    {
      categoryName: "Transferencia",
      categoryId: "transfer",
      categoryIcon: transferIcon,
      categoryItems: [
        {
          text: "Pay-In",
          onSelect: () => {
            window.location.href = AppRoutes.CREATE_TRANSFER_PROCESSOR(
              getMerchantId()
            );
          },
          id: "pay-in-transfer",
          variant: "text",
        },
        {
          text: "Pay-Out",
          onSelect: () => {
            window.location.href = AppRoutes.CREATE_PAYOUTS_TRANSFER_PROCESSOR(
              getMerchantId()
            );
          },
          id: "pay-out-transfer",
          variant: "text",
        },
      ],
    },
    {
      categoryName: "Débito recurrente",
      categoryId: "debit",
      categoryIcon: debitIcon,
      categoryItems: [
        {
          text: "Pay-In",
          onSelect: () => {
            window.location.href = AppRoutes.CREATE_TRANSFER_SUBS_PROCESSOR(
              getMerchantId()
            );
          },
          id: "pay-in-debit",
          variant: "text",
        },
      ],
    },
    {
      categoryName: "Efectivo",
      categoryId: "cash",
      categoryIcon: cashIcon,
      categoryItems: [
        {
          text: "Pay-In",
          onSelect: () => {
            window.location.href = AppRoutes.CREATE_CASH_PROCESSOR(
              getMerchantId()
            );
          },
          id: "pay-in-cash",
          variant: "text",
        },
        {
          text: "Pay-Out",
          onSelect: () => {
            window.location.href = AppRoutes.CREATE_PAYOUTS_CASH_PROCESSOR(
              getMerchantId()
            );
          },
          id: "pay-out-cash",
          variant: "text",
        },
      ],
    },
  ];
};
export interface CategoryItem {
  text: string;
  onSelect: () => void;
  id: string;
  variant: string;
}
export const categoryItemsByPaymentMethod: Record<PaymentMethodEnum, object> = {
  [PaymentMethodEnum.CASH]: {
    text: "Pay-In",
    onSelect: () => {
      window.location.href = AppRoutes.CREATE_CASH_PROCESSOR(getMerchantId());
    },
    id: "pay-in-cash",
    variant: "text",
  },
  [PaymentMethodEnum.PAYOUTS_CASH]: {
    text: "Pay-Out",
    onSelect: () => {
      window.location.href = AppRoutes.CREATE_PAYOUTS_CASH_PROCESSOR(
        getMerchantId()
      );
    },
    id: "pay-out-cash",
    variant: "text",
  },
  [PaymentMethodEnum.TRANSFER]: {
    text: "Pay-In",
    onSelect: () => {
      window.location.href = AppRoutes.CREATE_TRANSFER_PROCESSOR(
        getMerchantId()
      );
    },
    id: "pay-in-transfer",
    variant: "text",
  },
  [PaymentMethodEnum.PAYOUTS_TRANSFER]: {
    text: "Pay-Out",
    onSelect: () => {
      window.location.href = AppRoutes.CREATE_PAYOUTS_TRANSFER_PROCESSOR(
        getMerchantId()
      );
    },
    id: "pay-out-transfer",
    variant: "text",
  },
  [PaymentMethodEnum.TRANSFER_INTERNATIONAL]: {
    text: "Dispersión internacional",
    onSelect: () => {
      window.location.href = AppRoutes.CREATE_TRANSFER_INTERNATIONAL_PROCESSOR(
        getMerchantId()
      );
    },
    id: "transfer-internationalTransfer",
    variant: "text",
  },
  [PaymentMethodEnum.CARD]: {
    text: "Pay-In",
    onSelect: () => {
      window.location.href = AppRoutes.CREATE_CARD_PROCESSOR(getMerchantId());
    },
    id: "pay-in-card",
    variant: "text",
  },
  [PaymentMethodEnum.PAYOUTS_CARD]: {
    text: "Pay-Out",
    onSelect: () => {
      window.location.href = AppRoutes.CREATE_PAYOUT_CARD_PROCESSOR(
        getMerchantId()
      );
    },
    id: "pay-out-card",
    variant: "text",
  },
  [PaymentMethodEnum.TRANSFER_SUBSCRIPTIONS]: {
    text: "Pay-In",
    onSelect: () => {
      window.location.href = AppRoutes.CREATE_TRANSFER_SUBS_PROCESSOR(
        getMerchantId()
      );
    },
    id: "pay-in-debit",
    variant: "text",
  },
};
export const processorMethodsByCountry = (
  country: CountriesEnum,
  storePaymentMethods: object
): any[] => {
  const isCentralAmericaCountry = CENTRO_AMERICA_COUNTRIES.includes(country);
  const mainPath = isCentralAmericaCountry
    ? CountriesEnum.CENTRO_AMERICA
    : `${country}`;
  const paymentMethods = get(
    storePaymentMethods,
    mainPath,
    get(storePaymentMethods, "Default")
  );
  let countryAvailableMethods: object[] = [];

  const categoryMap: CategoryItem[] = paymentMethods.map(
    (method: string) =>
      categoryItemsByPaymentMethod[method as PaymentMethodEnum]
  );

  const cashCategory = categoryByPaymentMethod(
    categoryMap,
    PaymentMethodEnum.CASH
  );
  const transferCategory = categoryByPaymentMethod(
    categoryMap,
    PaymentMethodEnum.TRANSFER
  );
  const internationalTransferCategory = categoryByPaymentMethod(
    categoryMap,
    PaymentMethodEnum.TRANSFER_INTERNATIONAL
  );
  const cardCategory = categoryByPaymentMethod(
    categoryMap,
    PaymentMethodEnum.CARD
  );
  const subscriptionCategory = categoryByPaymentMethod(
    categoryMap,
    PaymentMethodAlias[PaymentMethodEnum.TRANSFER_SUBSCRIPTIONS]
  );
  buildAllowedPaymentMethods(
    countryAvailableMethods,
    cashCategory,
    transferCategory,
    cardCategory,
    subscriptionCategory,
    internationalTransferCategory
  );

  countryAvailableMethods = orderMethods(countryAvailableMethods);

  return countryAvailableMethods;
};

const orderMethods = (countryAvailableMethods: object[]): object[] =>
  countryAvailableMethods.map((method: object) => {
    const category_items = orderBy(
      get(method, "categoryItems", []),
      ["text"],
      ["asc"]
    );
    set(method, "categoryItems", category_items);
    return method;
  });

const categoryByPaymentMethod = (
  categoryItems: CategoryItem[],
  paymentMethod: string
) =>
  categoryItems.filter((item: CategoryItem) => {
    const itemCategory: string[] = get(item, "id", "").split("-");
    const paymentMethodPosition = itemCategory.length - 1;
    return (
      itemCategory[paymentMethodPosition] === paymentMethod &&
      !isUndefined(item)
    );
  });

const buildAllowedPaymentMethods = (
  countryAvailableMethods: object[],
  cashCategory: CategoryItem[],
  transferCategory: CategoryItem[],
  cardCategory: CategoryItem[],
  subscriptionCategory: CategoryItem[],
  internationalTransfer: CategoryItem[]
) => {
  if (!isEmpty(cardCategory))
    countryAvailableMethods.push({
      categoryName: "Tarjeta",
      categoryId: "card",
      categoryIcon: IconCardSwipe,
      categoryItems: cardCategory,
    });
  if (!isEmpty(cashCategory))
    countryAvailableMethods.push({
      categoryName: "Efectivo",
      categoryId: "cash",
      categoryIcon: IconCash,
      categoryItems: cashCategory,
    });
  if (!isEmpty(transferCategory))
    countryAvailableMethods.push({
      categoryName: "Transferencia",
      categoryId: "transfer",
      categoryIcon: IconTransferCurve,
      categoryItems: transferCategory,
    });
  if (!isEmpty(internationalTransfer))
    countryAvailableMethods.push({
      categoryName: "Dispersión",
      categoryId: "transfer",
      categoryIcon: IconCalculator,
      categoryItems: internationalTransfer,
    });
  if (!isEmpty(subscriptionCategory))
    countryAvailableMethods.push({
      categoryName: "Débito recurrente",
      categoryId: "debit",
      categoryIcon: IconCalculator,
      categoryItems: subscriptionCategory,
    });
  return countryAvailableMethods;
};
export const listDropDownAccordion = (
  editIcon: ReactNode,
  deleteIcon: ReactNode,
  failoverIcon: ReactNode,
  handleOnClickEditProcessor: (processor: GetProcessorResponse) => void,
  handleOnClickDeleteProcessor: (processor: GetProcessorResponse) => void,
  handleOnClickFailover: (processor: GetProcessorResponse) => void,
  isRenderFailover: boolean,
  processorData: GetProcessorResponse
): IDropdownItemProps[] => {
  const defaultValuesToRetrieve: IDropdownItemProps[] = [
    {
      text: "Editar",
      id: "edit",
      variant: "withIcon",
      onSelect: handleOnClickEditProcessor,
      icon: editIcon,
    },
    {
      text: "Eliminar",
      id: "delete",
      variant: "withIcon",
      onSelect: handleOnClickDeleteProcessor,
      icon: deleteIcon,
      rgbColor: ButtonColorEnum.Pink,
    },
  ];
  const failoverObject: IDropdownItemProps = {
    text: "Failover",
    id: "failover",
    variant: "withIcon",
    onSelect: handleOnClickFailover,
    icon: failoverIcon,
  };

  const isCardPayment: boolean =
    get(processorData, "paymentMethod", "") === PaymentMethodEnum.CARD;

  return isRenderFailover && isCardPayment
    ? [defaultValuesToRetrieve[0], failoverObject, defaultValuesToRetrieve[1]]
    : defaultValuesToRetrieve;
};

export const accordionBodyValues = (
  processor: GetProcessorResponse
): { title: string; value: string }[] => {
  const responseToRetrieve: { title: string; value: string }[] = [
    {
      title: "Creado",
      value: format(get(processor, "created"), "dd-MM-yyyy HH:mm:ss"),
    },
    {
      title: "Modificado",
      value: showUpdatedAt(get(processor, "updatedAt", 0)),
    },
    {
      title: "Alias",
      value: get(processor, "alias"),
    },
    {
      title: "Public ID",
      value: get(processor, "publicProcessorId"),
    },
    {
      title: "Procesador",
      value: translateProcessor(get(processor, "processorName")),
    },
    {
      title: "Modelo",
      value: get(processor, "processorType", ""),
    },
    {
      title: "Categoría",
      value: get(processor, "categoryModel", ""),
    },
    {
      title: "Medio de Pago",
      value: buildPaymentMethod(get(processor, "paymentMethod", "")),
    },
  ];

  return remove(
    responseToRetrieve,
    (data) => data.value != "0" && !isEmpty(data.value)
  );
};

const buildPaymentMethod = (paymentMethod: string): string => {
  switch (paymentMethod) {
    case PaymentMethodEnum.PAYOUTS_TRANSFER:
      return "Transferencia / Pay Out";
    case PaymentMethodEnum.TRANSFER:
      return "Transferencia / Pay In";
    case PaymentMethodEnum.PAYOUTS_CASH:
      return "Efectivo / Pay Out";
    case PaymentMethodEnum.PAYOUTS_CARD:
      return "Tarjeta / Pay Out";
    case PaymentMethodEnum.CASH:
      return "Efectivo / Pay In";
    case PaymentMethodEnum.TRANSFER_SUBSCRIPTIONS:
      return "Débito recurrente / Pay In";
    case PaymentMethodEnum.CARD:
      return "Tarjeta / Pay In";
    case PaymentMethodEnum.TRANSFER_INTERNATIONAL:
      return "Transferencia / International";

    default:
      return ProcessorConstants.EMPTY;
  }
};

export enum NOTIFY_SUCCESS_AND_ERROR_ALERT_ENUM {
  EDIT_PROCESSOR = "editProcessor",
  CREATE_PROCESSOR = "createProcessor",
  CREATE_DEFERRED = "createDeferred",
  EDIT_DEFERRED = "editDeferred",
}
