import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const payoutsCardProcessorComponentStyles = createNamedStyles({
  actionButtons: {
    marginBottom: "40px",
  },
  title: {
    fontFamily: "IBM Plex Sans",
    fontSize: "30px",
    fontWeight: 200,
    color: "#023365",
    marginBottom: "40px",
    marginTop: "15px",
  },
});
