import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
} from "@mui/material";
import React, { FC, PropsWithChildren } from "react";
import { useTableSimpleStyles } from "./styles";
import { TableProvider } from "../TableContext";
import { TableProps } from "./interfaces";
import { IconLensTable, MessageBlock } from "@kushki/connect-ui";
import { TableHeadSimple } from "../TableHeadSimple/TableHeadSimple";
import { TableBodySimple } from "../TableBodySimple/TableBodySimple";
import { useMobileState } from "../../../shared/state/useMobileState";
import { FooterPagination } from "../FooterPagination/FooterPagination";

export const TableSimple: FC<PropsWithChildren<TableProps>> = (
  props: PropsWithChildren<TableProps>
) => {
  const { isMobile } = useMobileState();

  const { styles } = useTableSimpleStyles(props);

  const enableHeaders: boolean = props.header.cells.length > 0;
  const showSkeletonRows: boolean = !!(
    props.isInitialLoading &&
    enableHeaders &&
    props.rows.length < 1
  );

  const renderMessageBlock: boolean =
    props.rows.length === 0 && !props.isLoading && !props.isInitialLoading;

  return (
    <TableProvider {...props}>
      <Box sx={styles.contentTable}>
        <Table sx={styles.table}>
          {!isMobile && props.header.cells.length > 0 && (
            <TableHeadSimple
              headers={{
                ...props.header,
                headerProps: {
                  ...props.header.headerProps,
                  isDisable:
                    props.header.headerProps.isDisable ||
                    props.rows.length === 0,
                },
              }}
              setAllCheck={props.setAllCheck}
            />
          )}
          {showSkeletonRows && (
            <TableBody>
              {[...Array(3)].map((_: string, index: number) => (
                <TableRow key={index}>
                  {[...Array(6)].map((_: string, innerIndex: number) => (
                    <TableCell key={innerIndex}>
                      <Skeleton variant="text" />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          )}
          {props.rows.length > 0 && (
            <TableBodySimple
              rows={props.rows}
              deletionProps={props.deletionProps}
              disableCheck={props.header.headerProps.isDisable}
              typesCellsToBlock={props.typesCellsToBlock}
              valuesCellsToBlock={props.valuesCellsToBlock}
              setItemCheckCurrent={props.setItemCheckCurrent}
              setEditProcess={props.setEditProcess}
            />
          )}
          {renderMessageBlock && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={5}>
                  {props.messageBlock ? (
                    <MessageBlock {...props.messageBlock} />
                  ) : (
                    <MessageBlock
                      image={<IconLensTable />}
                      title={"No se encontró ninguna coincidencia"}
                      body={
                        "Modifica la búsqueda para encontrar nuevos resultados"
                      }
                      typeMessage={1}
                    />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {!!props.footerPagination && (
            <TableFooter>
              <TableRow>
                <TableCell colSpan={5} sx={styles.footer}>
                  {props.showPagination && (
                    <FooterPagination
                      {...props.footerPagination}
                      isDisable={
                        props.footerPagination.isDisable ||
                        props.rows.length === 0
                      }
                    />
                  )}
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
          {props.children}
        </Table>
      </Box>
    </TableProvider>
  );
};
