import { MerchantNodeData } from "../../../types/search_merchant_response";
import { cloneDeep } from "lodash";

export const selectedDataUtils = (
  id: MerchantNodeData,
  selectedData: MerchantNodeData[]
): MerchantNodeData[] => {
  const selected = cloneDeep(selectedData);
  const selectedIndex = selected.findIndex(
    (s) => s.merchant_id === id.merchant_id!
  );
  let newSelected: MerchantNodeData[] = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, id);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1)
    );
  }

  return newSelected;
};
