import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      paddingTop: 24,
      paddingRight: 32,
      paddingBottom: 16,
      paddingLeft: 32,
    },
    dialogContentText: {
      fontSize: 15,
    },
    dialogContent: {
      paddingTop: 8,
      paddingLeft: 32,
      paddingBottom: 8,
      paddingRight: 32,
    },
    dialogActions: {
      display: "flex",
      gap: 24,
      paddingTop: 8,
      paddingLeft: 32,
      paddingBottom: 24,
      paddingRight: 32,
    },
    primaryColor: {
      color: theme.palette.primary.main,
      "background-color": theme.palette.background.default,
    },
    errorColor: {
      color: theme.palette.error.main,
    },
    cancelAction: {
      color: theme.palette.primary.dark,
    },
  })
);

export interface ConfirmModalProps {
  open: boolean;
  openLoadingDialog?: boolean;
  titleText: string;
  contentText?: string | (string | React.ReactElement)[];
  acceptText?: string;
  cancelText?: string;
  acceptStyle: {
    color?: "primary";
    variant?: "text" | "outlined" | "contained";
    error?: boolean;
  };
  fullWidth?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  handleClose?: () => void;
  handleAccept: () => void;
  loading?: boolean;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = (
  props: React.PropsWithChildren<ConfirmModalProps>
) => {
  const classes = useStyles(props);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth={props.fullWidth}
      maxWidth={props.maxWidth}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box className={classes.dialogTitle} display="flex" flexGrow={1}>
        <Box display="flex" alignItems="center" flexGrow={1}>
          <Typography variant="h5" color="primary">
            {props.titleText}
          </Typography>
        </Box>
      </Box>
      <DialogContent className={classes.dialogContent}>
        {props.contentText && (
          <DialogContentText
            className={classes.dialogContentText}
            id="alert-dialog-description"
          >
            {props.contentText}
          </DialogContentText>
        )}
        {props.children}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {props.handleClose && (
          <Button onClick={props.handleClose} className={classes.cancelAction}>
            {props.cancelText}
          </Button>
        )}
        <Button
          onClick={props.handleAccept}
          variant={props.acceptStyle.variant}
          color={props.acceptStyle.color}
          className={props.acceptStyle.error ? classes.errorColor : undefined}
          autoFocus
        >
          {props.acceptText}
          {props.loading && (
            <Box ml={1} display="flex" alignItems="center">
              <CircularProgress
                size={20}
                className={
                  props.acceptStyle.error
                    ? classes.errorColor
                    : classes.primaryColor
                }
              />
            </Box>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmModal.defaultProps = {
  acceptStyle: {
    error: true,
  },
  acceptText: "Aceptar",
  cancelText: "Cancelar",
  fullWidth: true,
  maxWidth: "sm",
};
