import { Box } from "@mui/material";
import React from "react";
import { DetailFooterStyles as style } from "./DetailFooter.styles";

const DetailFooter = () => {
  return (
    <>
      <Box sx={style.box} />
    </>
  );
};

export default DetailFooter;
