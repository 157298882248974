import React from "react";
import { Grid, Skeleton } from "@mui/material";
import { servicesInfoStyles as styles } from "../../../containers/ServicesInfo/ServicesInfo.styles";

export const ServicesInfoSkeleton: React.FC = () => {
  return (
    <Grid
      container
      spacing={3}
      sx={styles.contentGrid}
      data-testid={"services-info-skeleton"}
    >
      <Grid item spacing={1} xs={12} md={4} sx={styles.gridItem}>
        <Skeleton variant="rectangular" sx={styles.skeleton} />
      </Grid>
      <Grid item spacing={1} xs={12} md={4} sx={styles.gridItem}>
        <Skeleton variant="rectangular" sx={styles.skeleton} />
      </Grid>
      <Grid item spacing={1} xs={12} md={4} sx={styles.gridItem}>
        <Skeleton variant="rectangular" sx={styles.skeleton} />
      </Grid>
    </Grid>
  );
};
