import React from "react";
import { useStyles } from "./TooltipRefund.styles";
import {
  validateHiddenTrx,
  validateTooltipVoid,
} from "../../../shared/utils/tooltip_refund";
import { Box, IconButton, Tooltip } from "@mui/material";
import { ITooltipRefund } from "./TooltipRefund.interfaces";
import { RefreshOutlined } from "@mui/icons-material";

export const TooltipRefund: React.FC<ITooltipRefund> = (
  props: ITooltipRefund
) => {
  const classes = useStyles();
  const conditionVoid: {
    child: {
      hasChild: boolean;
      typeChild: string;
      amount: number;
    };
    disable: boolean;
    message: string;
  } = validateTooltipVoid(props.transaction, props.timeZone);

  return (
    <Box className={classes.iconButton}>
      {!validateHiddenTrx(props.transaction) && (
        <Tooltip
          data-testid={"refund-tooltip"}
          disableHoverListener={false}
          title={conditionVoid.message}
          arrow
          placement="top-end"
        >
          <span>
            <IconButton
              disabled={conditionVoid.disable}
              className={classes.icon}
              onClick={(e) => {
                props.handleOpenDialogVoid!(
                  props.transaction,
                  conditionVoid.child.typeChild,
                  conditionVoid.child.amount
                );
                e.stopPropagation();
              }}
            >
              <RefreshOutlined />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </Box>
  );
};
