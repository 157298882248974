// istanbul ignore file
import React from "react";
import { applyMiddleware, compose, createStore, Store } from "redux";
import thunk from "redux-thunk";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import MerchantList from "./containers/Merchant/Merchant";
import { ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import ErrorBoundary from "./components/ErrorBoundary";
import { environment } from "./environments/environment";
import { merchantReducer } from "./store/reducer";
import { SecurityWrapperEnum } from "./infrastructure/SecurityWrapperEnum";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";

const composeEnhancers = environment.devTools
  ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose;

const store: Store = createStore(
  merchantReducer,
  composeEnhancers(applyMiddleware(thunk))
);
const Root = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <ModuleSecurityWrapper
              basePath={environment.kushkiUrl}
              componentId={SecurityWrapperEnum.MODULE_ID}
            >
              <ErrorBoundary>
                <Switch>
                  <Route path="/merchant-list" exact component={MerchantList} />
                </Switch>
              </ErrorBoundary>
            </ModuleSecurityWrapper>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
};

export default Root;
