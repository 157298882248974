export enum EntityName {
  OWNER = "OWNER",
  CUSTOMER = "CUSTOMER",
  BRANCH = "BRANCH",
  NA = "N/A",
}

export const SearchAllMerchants = [
  EntityName.OWNER,
  EntityName.CUSTOMER,
  EntityName.BRANCH,
  EntityName.NA,
];
