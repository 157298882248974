import React from "react";
import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export interface CardSectionProps {
  sectionTitle: string;
  sectionDescription: string;
  sectionComponent: JSX.Element;
}

const useStyles = makeStyles({
  h4: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
  },
  subtitle1: {
    color: "#293036",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "26px",
  },
});

export const CardSection: React.FC<CardSectionProps> = (
  props: CardSectionProps
) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid item xs={12} lg={3}>
        <Typography className={classes.subtitle1}>
          {props.sectionTitle}
        </Typography>
        <Box pt={1}>
          <Typography className={classes.h4} color={"textSecondary"}>
            {props.sectionDescription}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Card>
          <CardContent>
            <Grid
              container
              spacing={2}
              direction="column"
              style={{ display: "block" }}
            >
              {props.sectionComponent}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </React.Fragment>
  );
};
