import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const formWrapperStyles = createNamedStyles({
  cardBody: {
    width: "100%",
    ".card-content": {
      padding: "24px 48px 24px 48px",
    },
  },
});
