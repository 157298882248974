import React, { FC } from "react";
import { Box } from "@mui/material";
import { ownerContainerStyles as styles } from "./OwnerContainer.styles";
import InformationForm from "../../components/InformationForm/InformationForm";

const OwnerContainer: FC = () => {
  return (
    <Box sx={styles.boxContainer}>
      <InformationForm />
    </Box>
  );
};

export default OwnerContainer;
