import {
  CardAlia,
  CardAmex,
  CardBbva,
  CardCarnet,
  CardDinners,
  CardDiscover,
  CardJbc,
  CardMastercard,
  CardVisa,
  IconCreditCard,
} from "@kushki/connect-ui";
import { CardBrandsEnum } from "../enums/CardBrandsEnum";
import React from "react";
import UnionPay from "../../assets/images/unionpay.png";
import Magna from "../../assets/images/magna.png";

export const CARD_BRAND_ICON: Record<CardBrandsEnum, React.FC> = {
  [CardBrandsEnum.ALIA]: CardAlia,
  [CardBrandsEnum.AMEX]: CardAmex,
  [CardBrandsEnum.BBVA]: CardBbva,
  [CardBrandsEnum.CARNET]: CardCarnet,
  [CardBrandsEnum.DEFAULT]: IconCreditCard,
  [CardBrandsEnum.DINERS]: CardDinners,
  [CardBrandsEnum.DISCOVER]: CardDiscover,
  [CardBrandsEnum.JCB]: CardJbc,
  [CardBrandsEnum.MASTERCARD]: CardMastercard,
  [CardBrandsEnum.VISA]: CardVisa,
  [CardBrandsEnum.MAGNA]: Magna,
  [CardBrandsEnum.UNIONPAY]: UnionPay,
};

export const getBrandIcon = (brand: CardBrandsEnum): JSX.Element => {
  const Icon: React.FC =
    CARD_BRAND_ICON[brand] || CARD_BRAND_ICON[CardBrandsEnum.DEFAULT];

  return <Icon />;
};
