import { configureStore } from "@reduxjs/toolkit";
import modulesReducer from "./reducers/modules/modules.slice";
import componentsReducer from "./reducers/components/components.slice";

export const store = configureStore({
  devTools: process.env.NEXT_PUBLIC_ENV_NAME !== "primary",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    components: componentsReducer,
    modules: modulesReducer,
  },
});
