import React from "react";
import { Grid, Typography } from "@mui/material";
import { ConditionsComponent } from "../ConditionsComponent/ConditionsComponent";
import { ClassNameMap } from "@mui/styles";
import { LabelEnum } from "../../shared/constants/labels/rule_labels";
import { IRuleComponentProps } from "../../shared/interfaces/rule_component_props";
import { RuleComponentStyles } from "./RuleComponent.styles";
import { MerchantBasicInfoComponent } from "../MerchantBasicInfoComponent/MerchantBasicInfoComponent";
import { GeneralInfoComponent } from "../GeneralInfoComponent/GeneralInfoComponent";
import { RuleConfigurationComponent } from "../RuleConfigurationComponent/RuleConfigurationComponent";

export const RuleComponent = (props: IRuleComponentProps) => {
  const classes: ClassNameMap = RuleComponentStyles();

  return (
    <Grid container item>
      {!props.isMassive && <MerchantBasicInfoComponent />}
      <Grid item container xs={12} className={classes.sectionThree}>
        <Grid item xs={12}>
          <Typography variant="h2" color="text.primary">
            {LabelEnum.ADD_BUSINESS_RULE}
          </Typography>
          <GeneralInfoComponent />
          <RuleConfigurationComponent
            handleSetSearchTextProcessors={
              props.actions.handleSetSearchTextProcessors
            }
            handleEnterSearchTextProcessors={
              props.actions.handleEnterSearchTextProcessors
            }
          />
          <ConditionsComponent {...props} />
        </Grid>
      </Grid>
    </Grid>
  );
};
