import { Box, Button, Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { footerStyles } from "./FooterOptions.styles";
import CheckIcon from "@mui/icons-material/Check";
import { IFooterOptions } from "./FooterOptions.interfaces";
import {
  CONTINUE_TEXT,
  DEFAULT_IS_DISABLE_BUTTON,
  DEFAULT_IS_EDIT_LABEL,
  DEFAULT_IS_HIDDEN_BUTTON,
  DEFAULT_IS_HIDDEN_LABEL,
  FINALIZAR_EDITION,
  SAVE_TEXT,
  SAVED_TEXT,
} from "../../shared/constants/labels/FooterOptionsConstants";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { ComponentIdsEnum } from "../../shared/constants/labels/main_containter_labels";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const FooterOptions: FC<IFooterOptions> = ({
  primaryButton = {
    isDisabled: DEFAULT_IS_DISABLE_BUTTON,
    isHidden: DEFAULT_IS_HIDDEN_BUTTON,
  },
  secondaryButton = {
    isDisabled: DEFAULT_IS_DISABLE_BUTTON,
    isHidden: DEFAULT_IS_HIDDEN_BUTTON,
  },
  isLoading,
  label = {
    date: "Jun/4/2022",
    editDetail: DEFAULT_IS_EDIT_LABEL,
    isHidden: DEFAULT_IS_HIDDEN_LABEL,
    text: "",
  },
  isEdit,
  isMassive,
}: IFooterOptions) => {
  const textSecondButton: string = isMassive
    ? FINALIZAR_EDITION
    : CONTINUE_TEXT;

  return (
    <Box sx={footerStyles.footer}>
      <Grid container spacing={0}>
        <Grid item xs={12} lg={6}>
          <Box sx={footerStyles.buttonContainer}>
            <ComponentSecurityWrapper
              componentId={
                isMassive
                  ? ComponentIdsEnum.M_CLIENTS_BRANCHEDIT_GUARDAR
                  : ComponentIdsEnum.M_CLIENTS_SAVED
              }
            >
              <Button
                data-testid="idButtonTest"
                variant="outlined"
                sx={footerStyles.saveButton}
                disabled={secondaryButton.isDisabled}
                onClick={() => secondaryButton.onAction!()}
                startIcon={isLoading && <CheckIcon />}
              >
                {isLoading ? SAVED_TEXT : SAVE_TEXT}
              </Button>
            </ComponentSecurityWrapper>
            <ComponentSecurityWrapper
              componentId={
                isMassive
                  ? ComponentIdsEnum.M_CLIENTS_BRANCHEDIT_FINALIZAREDICION
                  : ComponentIdsEnum.M_CLIENTS_CONTINUE
              }
            >
              {!isEdit && (
                <Button
                  data-testid="idButtonContinue"
                  variant="contained"
                  disabled={primaryButton.isDisabled}
                  onClick={() => primaryButton.onAction!()}
                  sx={footerStyles.continueButton}
                  endIcon={!isMassive && <ArrowForwardIcon />}
                >
                  {textSecondButton}
                </Button>
              )}
            </ComponentSecurityWrapper>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box sx={footerStyles.labelContainer}>
            <Box sx={footerStyles.labelBox}>
              <Typography sx={footerStyles.label}>{label.text}</Typography>
            </Box>
            <Box sx={footerStyles.labelBox}>
              <Typography sx={footerStyles.label}>{label.date}</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FooterOptions;
