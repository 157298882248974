/**
 *  BANK_LIST available Banks
 */

export enum CountryEnum {
  CHILE = "Chile",
  COLOMBIA = "Colombia",
  ECUADOR = "Ecuador",
}

export const BANK_LIST: Record<CountryEnum, { code: string; name: string }[]> =
  {
    [CountryEnum.CHILE]: [
      { code: "001", name: "BANCO DE CHILE" },
      { code: "009", name: "BANCO INTERNACIONAL" },
      { code: "014", name: "SCOTIABANK CHILE" },
      { code: "016", name: "BANCO DE CREDITO E INVERSIONES" },
      { code: "028", name: "BANCO BICE" },
      { code: "031", name: "HSBC BANK" },
      { code: "037", name: "BANCO SANTANDER" },
      { code: "039", name: "ITAÚ CORPBANCA" },
      { code: "049", name: "BANCO SECURITY" },
      { code: "051", name: "BANCO FALABELLA" },
      { code: "053", name: "BANCO RIPLEY" },
      { code: "055", name: "BANCO CONSORCIO" },
      { code: "054", name: "SCOTIABANK AZUL (ex BBVA)" },
      { code: "059", name: "BANCO BTG PACTUAL CHILE" },
    ],
    [CountryEnum.COLOMBIA]: [
      { code: "000000", name: "BANCO DE LA REPUBLICA" },
      { code: "000001", name: "BANCO DE BOGOTA" },
      { code: "000002", name: "BANCO POPULAR" },
      { code: "000006", name: "BANCO CORPBANCA" },
      { code: "000007", name: "BANCOLOMBIA S.A." },
      { code: "000009", name: "CITIBANK COLOMBIA" },
      { code: "000012", name: "BANCO GNB SUDAMERIS" },
      { code: "000013", name: "BBVA COLOMBIA" },
      { code: "000014", name: "ITAU" },
      { code: "000019", name: "BANCO COLPATRIA" },
      { code: "000023", name: "BANCO DE OCCIDENTE" },
      { code: "000031", name: "BANCO BANCOLDEX" },
      { code: "000032", name: "BANCO CAJA SOCIAL" },
      { code: "000040", name: "BANCO AGRARIO DE COLOMBIA" },
      { code: "000042", name: "BNP PARIBAS" },
      { code: "000051", name: "BANCO DAVIVIENDA S.A" },
      { code: "000052", name: "BANCO AV VILLAS" },
      { code: "000053", name: "BANCO W S.A" },
      { code: "000058", name: "BANCO PROCREDIT" },
      { code: "000059", name: "BANCAMIA" },
      { code: "000060", name: "BANCO PICHINCHA S.A." },
      { code: "000061", name: "BANCOOMEVA" },
      { code: "000062", name: "BANCO FALABELLA S.A." },
      { code: "000063", name: "BANCO FINANDINA S.A." },
      { code: "000064", name: "BANCO MULTIBANK" },
      { code: "000065", name: "BANCO SANTANDER DE NEGOCIOS COLOMBIA S.A." },
      { code: "000066", name: "BANCO COOPERATIVO COOPCENTRAL" },
      { code: "000067", name: "BANCO COMPARTIR S.A" },
      { code: "000069", name: "BANCO SERFINANZA S.A" },
      { code: "000090", name: "CORFICOLOMBIANA" },
      { code: "000121", name: "FINANCIERA JURIDISCOOP" },
      { code: "000283", name: "COOPERATIVA FINANCIERA DE ANTIOQUIA" },
      { code: "000289", name: "COTRAFA COOPERATIVA FINANCIERA" },
      { code: "000292", name: "CONFIAR S.A" },
      { code: "000370", name: "COLTEFINANCIERA" },
      { code: "000507", name: "NEQUI" },
    ],
    [CountryEnum.ECUADOR]: [
      { code: "5935125", name: "BANECUADOR B.P." },
      {
        code: "5934001",
        name: "BANCO DEL INSTITUTO ECUATORIANO DE SEGURIDAD SOCIAL",
      },
      { code: "5930202", name: "BANCO ECUATORIANO DE LA VIVIENDA" },
      { code: "5935000", name: "CORPORACION FINANCIERA" },
      { code: "5930010", name: "BANCO PICHINCHA" },
      { code: "5930061", name: "BANCO CAPITAL S.A." },
      {
        code: "5934002",
        name: "BANCO PARA LA ASISTENCIA COMUNITARIA FINCA S.A.",
      },
      { code: "5930034", name: "BANCO AMAZONAS" },
      { code: "5930024", name: "BANCO CITY BANK" },
      { code: "5930032", name: "BANCO INTERNACIONAL" },
      { code: "5930036", name: "BANCO DE LA PRODUCCION" },
      { code: "5930060", name: "BANCO PROCREDIT S.A." },
      { code: "5930042", name: "BANCO GENERAL RUMINAHUI" },
      { code: "5930059", name: "BANCO SOLIDARIO" },
      {
        code: "5934019",
        name: "COOP. AHORRO Y CREDITO MANANTIAL DE ORO LTDA.",
      },
      { code: "5934016", name: "COOP. AHORRO Y CREDITO JUAN DE SALINAS LTDA." },
      { code: "5934022", name: "COOP. AHORRO Y CREDITO PUELLARO LTDA" },
      { code: "5934021", name: "COOP. AHORRO Y CREDITO NUEVA JERUSALEN" },
      {
        code: "5934007",
        name: "COOP. AHORRO Y CREDITO AGRARIA MUSHUK KAWSAY LTDA.",
      },
      { code: "5934028", name: "COOP. AHORRO Y CREDITO TENA LTDA." },
      {
        code: "5934013",
        name: "COOP. AHORRO Y CREDITO DE LA PEQUEÑA EMPRESA GUALAQUIZA",
      },
      { code: "5934008", name: "COOP. AHORRO Y CREDITO ALIANZA MINAS LTDA." },
      { code: "5930238", name: "MUTUALISTA PICHINCHA" },
      { code: "5934020", name: "COOP. AHORRO Y CREDITO MI TIERRA" },
      {
        code: "5934070",
        name: "COOP. DE AHORRO Y CREDITO DE LA PEQ. EMP. CACPE YANZATZA LTDA.",
      },
      { code: "5934015", name: "COOP. AHORRO Y CREDITO FUNDESARROLLO" },
      {
        code: "5934004",
        name: "COOP. A Y C DE LA PEQ. EMP. CACPE ZAMORA LTDA.",
      },
      { code: "5934031", name: "COOP. DE A Y C EDUCADORES DE PASTAZA LTDA." },
      { code: "5934047", name: "COOP. DE A. Y C. GRAMEEN AMAZONAS" },
      {
        code: "5934039",
        name: "COOP. DE A. Y C. DE LOS SERV. PUBL. DEL MIN. DE EDUCACION Y CULTURA",
      },
      { code: "5934034", name: "COOP. DE A. Y C. 23 DE MAYO LTDA." },
      { code: "5934088", name: "COOP.DE AHORRO Y CRÉDITO COCA LTDA" },
      { code: "5934082", name: "COOP. DE AHORRO Y CRÉDITO PILAHUIN TIO LTDA" },
      { code: "5934074", name: "COOP. DE AHORRO Y CRÉDITO HUAICANA LTDA" },
      { code: "5934037", name: "COOP. DE A. Y C. CREDISUR LTDA." },
      { code: "5934051", name: "COOP. DE A. Y C. MAQUITA CUSHUNCHIC LTDA." },
      { code: "5934036", name: "COOP. DE A. Y C. CASAG LTDA" },
      { code: "5934044", name: "COOP. DE A. Y C. FOCLA" },
      { code: "5934118", name: "COOP. DE A. Y C. FORMACION INDIGENA LTDA" },
      { code: "5934119", name: "COOP. DE A. Y C. 20 DE FEBRERO LTDA." },
      {
        code: "5934172",
        name: "COOP. DE A. Y C. INDIGENA ALFA Y OMEGA LTDA.ALFA Y OMEGA LTDA.",
      },
      { code: "5934213", name: "COOP. DE A. Y C. EL TRANSPORTISTA CACET" },
      {
        code: "5935001",
        name: "COOP. DE A. Y C. CORDILLERA DE LOS ANDES LTDA.",
      },
      {
        code: "5935002",
        name: "COOP. DE A. Y C. PUERTO FRANCISCO DE ORELLANA",
      },
      { code: "5934216", name: "COOP. DE A Y C. LUZ DEL VALLE" },
      {
        code: "5934217",
        name: "COOP. DE A Y C. ESPERANZA Y PROGRESO DEL VALLE",
      },
      { code: "5935004", name: "COOP. DE A. Y C. COOPARTAMOS LTDA" },
      { code: "5935005", name: "COOP. DE A. Y C. CORPORACION CENTRO LTDA." },
      { code: "5935006", name: "COOP DE A. Y C. SAN JUAN DE COTOGCHOA" },
      {
        code: "5935007",
        name: "COOP. DE A. Y C. EMPRENDEDORES COOPEMPRENDER LTDA.",
      },
      { code: "5935008", name: "COOP. DE A. Y C. NUEVA LOJA LTDA." },
      {
        code: "5934224",
        name: "COOPERATIVA DE AHORRO Y CREDITO RUNA SHUNGO LTDA.",
      },
      { code: "5935009", name: "COOP. DE A. Y C. PICHNCHA LTDA." },
      {
        code: "5935010",
        name: "COOP.DE AHORRO Y CREDITO CACEC LTDA. (COTOPAXI)",
      },
      {
        code: "5935127",
        name: "COOP. DE A. Y C. FONDO PARA EL DESARROLLO Y LA VIDA FOND VIDA",
      },
      { code: "5935128", name: "COOPERATIVA DE A Y C NUEVA VISION" },
      { code: "5935130", name: "COOP. DE A. Y C. SAN VICENTE DEL SUR LTDA." },
      {
        code: "5935131",
        name: "COOP. DE A. Y C. PARA LA VIVIENDA ORDEN Y SEGURIDAD",
      },
      {
        code: "5935132",
        name: "COOP DE A. Y C. CAMARA DE COMERCIO JOYA DE LOS SACHAS LTDA.",
      },
      { code: "5935133", name: "COOP. DE A. Y C. FOCAP" },
      { code: "5935134", name: "COOP. DE A. Y C. 18 DE NOVIEMBRE" },
      {
        code: "5935135",
        name: "COOP. DE A. Y C. ALIANZA SOCIAL ECUAT. ALSEC LTDA PRODUCC. SERVICIOS Y CONSTRUC.",
      },
      {
        code: "5935136",
        name: "COOP. DE A. Y C. RENOVADORA ECUATORIANA CON ACCION RESPONSABLE",
      },
      { code: "5935137", name: "COOP. DE A. Y C. MUSHUK YUYAY - NAPO" },
      { code: "5935138", name: "COOP. DE A. Y C. SANTA ANA DE NAYON" },
      { code: "5935139", name: "COOP. DE A. Y C. EDUCADORES DEL NAPO" },
      { code: "5935140", name: "COOPERATIVA DE A. Y C. TEXTIL 14 DE MARZO" },
      { code: "5935141", name: "COOP. DE A. Y C. UNION Y DESARROLLO" },
      { code: "5935142", name: "COOP. DE A. Y C. ESPERANZA DEL FUTURO LTDA." },
      { code: "5935143", name: "COOP. DE A. Y C. 17 DE MARZO LTDA" },
      { code: "5935144", name: "COOP. DE A. Y C. CATAR LTDA" },
      { code: "5935145", name: "COOP. DE A. Y C. DE ACCION POPULAR" },
      { code: "5935146", name: "COOP. DE A. Y C. ALLI TARPUK LTDA" },
      { code: "5935147", name: "COOP. DE A. Y C. 16 DE JULIO LTDA" },
      {
        code: "5935148",
        name: "COOP. DE A. Y C. SUBOFICIALES DE LA POLICIA NACIONAL",
      },
      { code: "5934214", name: "COOP. DE A. Y C. POLITECNICA LTDA." },
      { code: "5935149", name: "COOP. DE A. Y C. NACIONAL LLANO GRANDE LTDA." },
      { code: "5935150", name: "COOP. DE A. Y C. SAN VALENTIN" },
      { code: "5930232", name: "FINANCIERA - DINERS CLUB DEL ECUADOR S A" },
      { code: "5930226", name: "COOP. PREVISION AHORRO Y DESARROLLO" },
      { code: "5930207", name: "COOP. AHORRO Y CREDITO ANDALUCIA" },
      { code: "5930204", name: "COOP. DE LA PEQUEÑA EMPRESA DE PASTAZA" },
      { code: "5930218", name: "COO.  AHORRO Y CREDITO COOPROGRESO" },
      { code: "5930206", name: "COOP. AHORRO Y CREDITO 29 DE OCTUBRE" },
      {
        code: "5934081",
        name: "COOP. DE AHORRO Y CREDITO PEDRO MONCAYO LTDA.",
      },
      { code: "5930208", name: "COOP. AHORRO Y CREDITO COTOCOLLAO" },
      {
        code: "5930227",
        name: "COOP.AHORRO Y CREDITO ALIANZA DEL VALLE LTDA.",
      },
      {
        code: "5934025",
        name: "COOP. AHORRO Y CREDITO SAN MIGUEL DE LOS BANCOS",
      },
      {
        code: "5939997",
        name: "COOP. DE AHORRO Y CREDITO POLICIA NACIONAL LTDA.",
      },
      { code: "5930082", name: "FINANCIERA - FINANCOOP" },
      { code: "5930209", name: "BANCO DESARROLLO DE LOS PUEBLOS S.A." },
      { code: "5930205", name: "COOP. AHORRO Y CREDITO 23 DE JULIO" },
      {
        code: "5931182",
        name: "COOP. DE AHORRO Y CREDITO SAN FRANCISCO DE ASIS LTDA.",
      },
      {
        code: "5934219",
        name: "ACCIONES VALORES CASA DE VALORES S.A. ACCIVAL",
      },
      { code: "5934220", name: "SANTA FE CASA DE VALORES S.A. SANTAFEVALORES" },
      { code: "5934221", name: "PICAVAL CASA DE VALORES S.A" },
      { code: "5934222", name: "ANALYTICA SECURTIES C.A. CASA DE VALORES" },
      { code: "5934223", name: "ORION CASA DE VALORES S.A" },
      { code: "5934225", name: "STRATEGA CASA DE VALORES S.A." },
      { code: "5934226", name: "ECOFSA CASA DE VALORES S.A." },
      { code: "5934227", name: "MERCHANTVALORES CASA DE VALORES S.A." },
      { code: "5934230", name: "CASA DE VALORES VALUE S.A." },
      { code: "5934231", name: "INMOVALOR CASA DE VALORES S.A." },
      { code: "5934232", name: "ECUABURSATIL CASA DE VALORES S.A." },
      { code: "5934233", name: "PLUS VALORES CASA DE VALORES S.A." },
      {
        code: "5934115",
        name: "FONDO DE CESANTÍA DEL MAGISTERIO ECUATORIANO FCME-FCPC",
      },
      { code: "5934234", name: "MERCAPITAL CASA DE VALORES S.A." },
      { code: "5934235", name: "PORTAFOLIO CASA DE VALORES S.A. PORTAVALOR" },
      { code: "5934236", name: "METROVALORES CASA DE VALORES S.A." },
      { code: "5934237", name: "VECTORGLOBAL WMG CASA DE VALORES S.A." },
      { code: "5934238", name: "HOLDUNPARTNERS CASA DE VALORES S.A." },
      { code: "5935151", name: "BCE-TRANSFERENCIAS REMESAS" },
      { code: "5930001", name: "BCE - SISTEMA DE PAGOS INTERBANCARIO - SPI" },
      { code: "5930017", name: "BANCO DE GUAYAQUIL S.A" },
      { code: "5930030", name: "BANCO DEL PACIFICO" },
      { code: "5930037", name: "BANCO BOLIVARIANO" },
      { code: "5930027", name: "BANCO DELBANK S.A." },
      { code: "5930043", name: "BANCO DEL LITORAL S.A." },
      { code: "5934003", name: "BANCO-D-MIRO S.A." },
      {
        code: "5934080",
        name: "COOP. DE AHORRO Y CREDITO NUEVA HUANCAVILCA LTDA.",
      },
      { code: "5934075", name: "COOP. DE AHORRO Y CREDITO HUAQUILLAS LTDA." },
      {
        code: "5934040",
        name: "COOP. DE A. Y C. DEL COL. FISC. EXPER. VICENTE ROCAFUERTE",
      },
      {
        code: "5934174",
        name: "COOP. DE AHORRO Y CREDITO LOS ANDES LATINOS LTDA.",
      },
      {
        code: "5935012",
        name: "COOPERATIVA DE AHORRO Y CREDITO SAN BARTOLO LTDA",
      },
      {
        code: "5935014",
        name: "COOP. DE A. Y C. DEL EMIGRANTE ECUATORIANO Y SU FAMILIA LTDA.",
      },
      { code: "5935015", name: "COOP. AHORRO Y CREDITO LA LIBERTAD LTDA" },
      { code: "5935016", name: "COAC CUNA DE LA NACIONALIDAD LTDA." },
      {
        code: "5935152",
        name: "COOPERATIVA DE AHORRO Y CREDITO DR. CORNELIO SAENZ VERA LTDA.",
      },
      { code: "5935153", name: "COOP. DE A Y C SAN CARLOS LTDA." },
      {
        code: "5935154",
        name: "COAC A Y C ESPERANZA DE VALLE DE LA VIRGEN LTDA.",
      },
      { code: "5935155", name: "COAC A Y C ZONA DE CAPITAL CORCIMOL" },
      {
        code: "5934239",
        name: "COOPERATIVA AHORRO Y CREDITO UNIVERSIDAD DE GUAYAQUIL LTDA",
      },
      { code: "5930215", name: "BANCO COOPNACIONAL S.A." },
      { code: "5930225", name: "COOP. AHORRO Y CREDITO LA DOLOROSA LTDA." },
      { code: "5935156", name: "COOP. DE AHORRO Y CREDITO BASE DE TAURA" },
      { code: "5934240", name: "VENTURA CASA DE VALORES VENCASA S.A." },
      { code: "5935242", name: "CASA DE VALORES BANRIO S.A." },
      { code: "5934242", name: "ALBION CASA DE VALORES S.A." },
      { code: "5934243", name: "MASVALORES CASA DE VALORES S.A CAVAMASA" },
      {
        code: "5934244",
        name: "CASA DE VALORES DEL PACIFICO (VALPACIFICO) S.A.",
      },
      { code: "5934245", name: "CASA DE VALORES ADVFIN S.A." },
      { code: "5934246", name: "SILVERCROSS S.A CASA DE VALORES SCCV" },
      { code: "5934247", name: "REAL CASA DE VALORES DE GUAYAQUIL S.A." },
      { code: "5934248", name: "KAPITAL ONE CASA DE VALORES S.A. KAOVALSA" },
      { code: "5934249", name: "PLUSBURSATIL CASA DE VALORES S.A" },
      {
        code: "5934250",
        name: "ACTIVA ASES.E INTERMED.VALORES ACTIVALORES CASA DE VALORES",
      },
      { code: "5934251", name: "CITADEL CASA DE VALORES S.A." },
      { code: "5934252", name: "INTERVALORES CASA DE VALORES S. A." },
      {
        code: "5934253",
        name: "COMPAÑIA DEPOSITO CENTRALIZADO DE COMPENSACION Y LIQUIDACION DE VALORES DECEVALE S.A.",
      },
      { code: "5930035", name: "BANCO DEL AUSTRO" },
      { code: "5930234", name: "MUTUALISTA AZUAY" },
      {
        code: "5934064",
        name: "COOP. DE AHORRO Y CREDITO ALFONSO JARAMILLO C.C.C.",
      },
      { code: "5930213", name: "COOP. JUVENTUD ECUATORIANA PROGRESISTA LTDA." },
      { code: "5933615", name: "COOP. DE AHORRO Y CREDITO JARDIN AZUAYO" },
      { code: "5934077", name: "COOP. DE AHORRO Y CREDITO LA MERCED LTDA." },
      { code: "5934109", name: "COOPERATIVA DE AHORRO Y CREDITO PROVIDA" },
      {
        code: "5934112",
        name: "COOPERATIVA DE AHORRO Y CREDITO SAN JOSE S.J.",
      },
      { code: "5934027", name: "COOP. AHORRO Y CREDITO SEÑOR DE GIRÓN" },
      { code: "5934101", name: "COOPERATIVA DE AHORRO Y CREDITO ERCO LTDA." },
      {
        code: "5934097",
        name: "COOPERATIVA DE AHORRO Y CREDITO CAC-CICA (MIES)",
      },
      { code: "5934076", name: "COOP. DE AHORRO Y CREDITO JADAN LTDA. (MIES)" },
      {
        code: "5934178",
        name: "COOPERATIVA DE AHORRO Y CREDITO CREA LTDA ( MIES)",
      },
      {
        code: "5935017",
        name: "COOP. DE A Y C. SERVIDORES MUNICIPALES DE CUENCA",
      },
      { code: "5935018", name: "COOP. DE A. Y C. SAN MARCOS" },
      {
        code: "5935022",
        name: "COOPERATIVA DE AHORRO Y CREDITO SANTA ISABEL LTDA.",
      },
      {
        code: "5935023",
        name: "COOPERATIVA DE AHORRO Y CREDITO GAÑANSOL LTDA",
      },
      { code: "5935024", name: "COOPERATIVA DE AHORRO Y CREDITO DEL AZUAY" },
      {
        code: "5935025",
        name: "COAC DEL SINDICATO DE CHOFERES PROFESIONALES DEL AZUAY",
      },
      { code: "5935027", name: "COOPERATIVA DE AHORRO Y CREDITO ÑUKANCHIK" },
      { code: "5935028", name: "COOP. DE A. Y C. SANTA ANA LTDA.-AZUAY" },
      {
        code: "5935029",
        name: "COOPERATIVA DE AHORRO Y CREDITO MULTIEMPRESARIAL LTDA.",
      },
      {
        code: "5935030",
        name: "COOP. DE AHORRO Y CREDITO CHOLA CUENCANA LTDA.",
      },
      { code: "5935157", name: "COOP.DE A. Y C. ARTESANAL DEL AZUAY" },
      { code: "5935158", name: "COOP DE A. Y C. EDUCADORES DEL AZUAY" },
      { code: "5935159", name: "COOP A Y C. FASAYÑAN LTDA" },
      { code: "5930216", name: "COOP. AHORRO Y CREDITO OSCUS" },
      { code: "5935160", name: "COOPERATIVA DE AHORRO Y CREDITO SUMAK SISA" },
      { code: "5935161", name: "COOP. DE A. Y C. WARMIKUNAPAK RIKCHARI LTDA" },
      { code: "5935162", name: "COOP. DE A. Y C. REY DAVID LTDA" },
      { code: "5935163", name: "COOP. DE A. Y C. KULLKI WASI LTDA." },
      { code: "5930233", name: "MUTUALISTA AMBATO" },
      { code: "5930210", name: "COOP. AHORRO Y CREDITO EL SAGRARIO" },
      { code: "5930220", name: "CAJA DE AHORRO Y CRÉDITO SAN FRANCISCO " },
      {
        code: "5934066",
        name: "COOP. DE AHORRO Y CREDITO CAMARA DE COMERCIO DE AMBATO LTDA.",
      },
      { code: "5934078", name: "COOP. DE AHORRO Y CREDITO MUSHUC RUNA LTDA." },
      {
        code: "5934072",
        name: "COOP. DE AHORRO Y CREDITO EDUC.DEL TUNGURAHUA LTDA.",
      },
      { code: "5934095", name: "COOPERATIVA DE AHORRO Y CREDITO AMBATO LTDA." },
      { code: "5934105", name: "COOPERATIVA DE AHORRO Y CREDITO LLANGANATES" },
      { code: "5934071", name: "COOP. DE AHORRO Y CREDITO DORADO LTDA." },
      {
        code: "5934100",
        name: "COOPERATIVA DE AHORRO Y CREDITO EL CALVARIO LTDA.",
      },
      { code: "5934067", name: "COOP. DE AHORRO Y CREDITO CREDI FACIL LTDA." },
      { code: "5934060", name: "COOP. DE A. Y C. VENCEDORES DE TUNGURAHUA" },
      { code: "5934042", name: "COOP. DE A. Y C. ECUAFUTURO LTDA." },
      { code: "5934050", name: "COOP. DE A. Y C. MAQUITA CUSHUN LTDA." },
      { code: "5934059", name: "COOP. DE A. Y C. VALLES DEL LIRIO" },
      {
        code: "5934085",
        name: "COOP. ESFUERZO UNIDO PARA EL DESARR. DEL CHILCO LA ESPERANZA",
      },
      { code: "5934005", name: "COOP. A. Y C. CARROCEROS DE TUNGURAHUA" },
      { code: "5934169", name: "COOP. DE A. Y C. ACCIÓN TUNGURAHUA LTDA." },
      { code: "5934180", name: "COOP. DE A. Y C. SUMAK SAMY LTDA." },
      { code: "5934182", name: "COOP. DE A. Y C. CHIBULEO LTDA." },
      { code: "5934183", name: "COOP. DE A. Y C. EL TESORO PILLAREÑO" },
      { code: "5934184", name: "COOP. DE A. Y C. KISAPINCHA LTDA." },
      { code: "5934185", name: "COOP. DE A. Y C. JUVENTUD UNIDA LTDA." },
      { code: "5934186", name: "COOP. DE A. Y C. UNION QUISAPINCHA LTDA." },
      { code: "5934272", name: "COOP. DE A. Y C. SAINT MICHEL LTDA." },
      { code: "5935031", name: "COOP. DE A. Y C. INDÍGENA SAC PELILEO" },
      { code: "5935033", name: "COOPERATIVA CREDIMAS" },
      { code: "5935034", name: "COOP. DE A. Y C. MUSHUG CAUSAY LTDA." },
      { code: "5935035", name: "COOP. DE A. Y C. 21 DE NOVIEMBRE LTDA." },
      { code: "5935036", name: "COOP. DE A. Y C. LA FLORESTA LTDA." },
      {
        code: "5935037",
        name: "COOP. DE A. Y C. CORP. ORG. CAMPESINAS DE QUISAPINCHA COCIQ",
      },
      {
        code: "5935038",
        name: "COOP. DE A. Y C. MULTICULTURAL BANCO INDIGENA LTDA.",
      },
      { code: "5935039", name: "COOP DE A. Y C. CRECER WIÑARI LTDA." },
      { code: "5935040", name: "COOP. DE A. Y C. BAÑOS DE AGUA SANTA LTDA." },
      { code: "5935041", name: "COOP. DE AHORRO Y CREDITO 1 DE JULIO" },
      { code: "5935164", name: "COOP. DE A. Y C. INDIGENA SAC LTDA" },
      { code: "5935165", name: "COOP. DE A. Y C. CREDI YA LTDA" },
      { code: "5935166", name: "COOP. DE A. Y C. SAN BARTOLOME LTDA" },
      { code: "5935167", name: "COOP. DE A. Y C. PRODUACTIVA LTDA" },
      { code: "5935168", name: "COOP. DE A. Y C. TAMBOLOMA LTDA." },
      { code: "5935169", name: "COOP DE A. Y C. PUSHAK RUNA HOMBRE LIDER" },
      { code: "5935170", name: "COOP. DE A. Y C. 15 DE AGOSTO LTDA." },
      { code: "5935171", name: "COOP. DE A. Y C. MIGRANTES DEL ECUADOR LTDA" },
      {
        code: "5935172",
        name: "COOPERATIVA DE AHORRO Y CREDITO WUAMANLOMA LTDA.",
      },
      { code: "5935173", name: "COOP. DE A. Y C. SALATE LTDA." },
      { code: "5935174", name: "COOP. DE A. Y C. UNION POPULAR LTDA." },
      {
        code: "5933352",
        name: "COOP. AHORRO Y CREDITO DE LA LA PEQUEÑA EMPRESA CACPE BIBLIAN LTDA.",
      },
      { code: "5935043", name: "COOPERATIVA DE AHORRO Y CREDITO CAÑAR LTDA." },
      { code: "5935175", name: "COOP. DE AHORRO Y CRÉDITO MUSHUK YUYAY" },
      { code: "5935176", name: "COOP DE A. Y C. SISAY KAÑARI" },
      { code: "5935178", name: "COOP. DE A. Y C. FUERZA DE LOS ANDES" },
      { code: "5935179", name: "COOP. DE A. Y C. CACIQUE GURITAVE" },
      { code: "5935180", name: "COAC SOLIDARIDAD Y PROGRESO ORIENTAL" },
      { code: "5935181", name: "COAC ACHIK INTI LTDA" },
      { code: "5934187", name: "COOP. DE A. Y C. 13 DE ABRIL LTDA" },
      {
        code: "5935044",
        name: "COOPERATIVA DE AHORRO Y CREDITO SAN ANTONIO LTDA.",
      },
      { code: "5935045", name: "COOP. DE AHORRO Y CREDITO FUNDAR" },
      { code: "5935047", name: "COOP. AHORRO Y CREDITO METROPOLIS LTDA." },
      { code: "5935048", name: "COOP. DE AHORRO Y CREDITO EL CAFETAL" },
      {
        code: "5934259",
        name: "COOPERATIVA DE AHORRO Y CREDITO MOCACHE LTDA.",
      },
      {
        code: "5934260",
        name: "COOPERATIVA DE AHORRO Y CREDITO UNIVERSIDAD TECNICA DE BABAHOYO",
      },
      {
        code: "5935049",
        name: "COOP.DE AHORRO Y CREDITO MICROEMPRESARIAL SUCRE",
      },
      {
        code: "5935050",
        name: "COOP. DE A. Y C. AFROECUATORIANA DE LA PEQ. EMP. LTDA CACAEPE",
      },
      { code: "5935182", name: "COOP. DE A. Y C. ANTORCHA LTDA." },
      {
        code: "5934079",
        name: "COOP. DE AHORRO Y CREDITO NUESTROS ABUELOS LTDA.",
      },
      { code: "5930211", name: "COOP. AHORRO Y CREDITO GUARANDA LTDA." },
      { code: "5934033", name: "COOP. DE A Y C JUAN PIO DE MORA LTDA." },
      { code: "5934061", name: "COOP. DE AHORRO Y CRÉDITO SIMIATUG LTDA" },
      { code: "5934188", name: "COOP. DE A. Y C. SALINAS LTDA." },
      { code: "5934189", name: "COOP. DE A. Y C. SAN PEDRO LTDA." },
      { code: "5935183", name: "COOP DE A. Y C. CAMINO DE LUZ LTDA." },
      { code: "5933364", name: "COOP. AHORRO Y CREDITO SAN JOSE LTDA." },
      {
        code: "5935051",
        name: "COOPERATIVA DE AHORRO Y CREDITO JOYOCOTO LTDA.",
      },
      { code: "5935184", name: "COAC A Y C EDUCADORES DE BOLIVAR" },
      { code: "5935185", name: "COAC SAN MIGUEL LTDA." },
      { code: "5935186", name: "COOP AC LAS NAVES" },
      { code: "5934261", name: "SOCIEDAD FINANCIERA VISIONFUND ECUADOR S.A." },
      { code: "5935187", name: "COOP. DE A. Y C. SALINERITA" },
      { code: "5935188", name: "COOP. DE A. Y C. BOLA AMARILLA" },
      { code: "5935189", name: "COOP. DE A. Y C. DE IMBABURA AMAZONAS" },
      { code: "5935190", name: "COOP. A. Y C. DE INDIGENAS CHUCHUQUI LTDA" },
      { code: "5930236", name: "MUTUALISTA IMBABURA" },
      { code: "5930223", name: "COOP. ATUNTAQUI LTDA." },
      {
        code: "5934096",
        name: "COOPERATIVA DE AHORRO Y CREDITO ARTESANOS LTDA.",
      },
      {
        code: "5934113",
        name: "COOPERATIVA DE AHORRO Y CREDITO SANTA ANITA LTDA.",
      },
      {
        code: "5934030",
        name: "COOP. AHORRO. Y CREDI. MUJERES UNIDAS TANTANAKUSHKA WARMIKUNAPAK CACMU LTDA.",
      },
      { code: "5934023", name: "COOPERATIVA SAN ANTONIO LTDA - IMBABURA" },
      { code: "5934089", name: "COOP.DE AHORRO Y CREDITO HUAYCO PUNGO LTDA." },
      { code: "5934052", name: "COOP. DE A. Y C. PIJAL" },
      { code: "5935052", name: "COOP. DE A. Y C. UNIOTAVALO LTDA." },
      { code: "5935053", name: "COOP. DE A. Y C. UNION EL EJIDO" },
      {
        code: "5935191",
        name: "COOPERATIVA DE A Y C ACCION IMBABURAPAK LTDA.",
      },
      { code: "5935192", name: "COOP. AHORRO Y CREDITO FOCAZSUM LTDA." },
      {
        code: "5935193",
        name: "COOPERATIVA DE AHORRO Y CREDITO VISION DE LOS ANDES VISANDES",
      },
      { code: "5932753", name: "COOP. AHORRO Y CREDITO 9 DE OCTUBRE LTDA" },
      { code: "5930203", name: "CACPECO LTDA" },
      { code: "5934065", name: "COOP. DE AHORRO Y CREDITO ANDINA LTDA." },
      { code: "5934091", name: "COOPERATIVA 15 DE AGOSTO PILACOTO" },
      { code: "5934108", name: "COOPERATIVA DE AHORRO Y CREDITO PILAHUIN" },
      { code: "5934110", name: "COOPERATIVA DE AHORRO Y CREDITO PUCARÁ LTDA." },
      { code: "5934056", name: "COOP. DE A. Y C. SINCHI RUNA LTDA" },
      { code: "5934054", name: "COOP. DE A. Y C. SANTA ROSA DE PATUTAN LTDA." },
      {
        code: "5934084",
        name: "COOP. DE AHORRO Y CREDITO SAN MIGUEL DE SIGCHOS",
      },
      { code: "5934055", name: "COOP. DE A. Y C. SIERRA CENTRO LTDA." },
      { code: "5934058", name: "COOP. DE A. Y C. UNION MERCEDARIA LTDA." },
      { code: "5934190", name: "COOP. DE A. Y C. VIRGEN DEL CISNE" },
      {
        code: "5934191",
        name: "COOP. DE A. Y C. LOS CHASQUIS PASTOCALLE LTDA.",
      },
      { code: "5935057", name: "COOP. DE A. Y C. PUJILI LTDA" },
      { code: "5935058", name: "COOP. DE A. Y C. CREDIL LTDA." },
      { code: "5935060", name: "COOP. DE A. Y C. ILINIZA LTDA." },
      { code: "5935061", name: "COOP. DE A. Y C. MUSHUK PAKARI LTDA." },
      { code: "5935062", name: "COOP. DE A. Y C. UNIBLOCK Y SERVICIOS LTDA." },
      { code: "5935064", name: "COOP. DE A. Y C. FUTURO LAMANENSE" },
      { code: "5935065", name: "COOP. DE A. Y C. SAQUISILI LTDA." },
      { code: "5935066", name: "COOP. DE A. Y C. INNOVACION ANDINA LTDA." },
      { code: "5935194", name: "COOP DE A. Y C. HERMES GAIBOR VERDESOTO" },
      { code: "5935195", name: "COOP. DE A. Y C. SEMILLAS DE PANGUA" },
      { code: "5935196", name: "COOPERATIVA DE A Y C MUSHUK SOLIDARIA" },
      { code: "5935197", name: "COOP DE A. Y C. PANAMERICANA LTDA" },
      { code: "5935198", name: "COOP. DE A. Y C. SEÑOR DEL ARBOL" },
      {
        code: "5935200",
        name: "COOP. DE A. Y C. CÁMARA DE COMERCIO DE LA MANÁ",
      },
      { code: "5935201", name: "COOP DE A. Y C. INDIGENA SAC LATACUNGA LTDA" },
      { code: "5935202", name: "COOP. DE A. Y C. SOLIDARIA LTDA.- COTOPAXI" },
      { code: "5935203", name: "COOP. DE A. Y C. UNIDAD Y PROGRESO" },
      { code: "5930029", name: "BANCO DE LOJA" },
      {
        code: "5932129",
        name: "COOP. MANUEL ESTEBAN GODOY ORTEGA LTDA. COOPMEGO",
      },
      {
        code: "5933304",
        name: "COOP. DE AHORRO Y CREDITO PADRE JULIAN LORENTE LTDA.",
      },
      {
        code: "5934086",
        name: "COOP.AHORRO Y CREDITO DE LA PEQUEÑA EMPRESA DE LOJA CACPE LOJA LTDA.",
      },
      {
        code: "5934026",
        name: "COOP. AHORRO Y CREDITO SEMILLA DEL PROGRESO LTDA.",
      },
      { code: "5934011", name: "COOP. AHORRO Y CREDITO CARIAMANGA LTDA." },
      {
        code: "5934012",
        name: "COOP. AHORRO Y CREDITO DE LA CAMARA DE COMERCIO DE PINDAL CADECOPI",
      },
      { code: "5934032", name: "COOP. DE A Y C GONZANAMÁ (MIES)" },
      {
        code: "5934111",
        name: "COOPERATIVA DE AHORRO Y CREDITO QUILANGA LTDA.",
      },
      {
        code: "5934068",
        name: "COOP. DE AHORRO Y CREDITO CREDIAMIGO LTDA. LOJA (MIES)",
      },
      {
        code: "5934103",
        name: "COOPERATIVA DE AHORRO Y CREDITO FORTUNA (MIES)",
      },
      { code: "5934098", name: "COOPERATIVA DE AHORRO Y CREDITO CACPE CELICA" },
      {
        code: "5934099",
        name: "COOPERATIVA DE AHORRO Y CREDITO CATAMAYO LTDA. (MIES)",
      },
      {
        code: "5934193",
        name: "COOP. DE A. Y C EDUCADORES DE ZAMORA CHINCHIPE",
      },
      {
        code: "5934194",
        name: "COOPERATIVA DE AHORRO Y CREDITO LAS LAGUNAS (MIESS)",
      },
      { code: "5934195", name: "COOP.DE A.Y C. EL COMERCIANTE LTDA (MIES)" },
      { code: "5935067", name: "COOP. DE A. Y C. MUSHUK WASI LTDA ( MIES )" },
      { code: "5935068", name: "COOP. DE AHORRO Y CREDITO SARAGUROS" },
      {
        code: "5935069",
        name: "COOPERATIVA DE AHORRO Y CREDITO INTI WASI LTDA. (MIES)",
      },
      {
        code: "5935071",
        name: "COOPERATIVA DE AHORRO Y CREDITO SOLIDARIA LTDA",
      },
      {
        code: "5935072",
        name: "COOPERATIVA DE AHORRO Y CREDITO ECONOMIA DEL SUR ECOSUR",
      },
      {
        code: "5935074",
        name: "COOPERATIVA DE AHORRO Y CREDITO SAN SEBASTIAN",
      },
      { code: "5935206", name: "COOP. DE A. Y C. VILCABAMBA CACVIL" },
      {
        code: "5935207",
        name: "COOPERATIVA DE AHORRO Y CRÉDITO SANTIAGO LTDA",
      },
      { code: "5935208", name: "COOP DE A. Y C. EDUCADORES DE LOJA" },
      { code: "5935209", name: "COOP DE A.Y C. CADECOG - GONZANAMA" },
      { code: "5935210", name: "COAC COOPYMEC - MACARA" },
      { code: "5935211", name: "COAC CADECOM - MACARA" },
      { code: "5935212", name: "COOP DE A. Y C. 22 DE JUNIO-ORIANGA" },
      { code: "5935215", name: "COAC 23 DE ENERO" },
      {
        code: "59359700043",
        name: "COAC OBRAS PUBLICAS FISCALES DE LOJA Y ZAMORA",
      },
      { code: "5930025", name: "BANCO MACHALA" },
      { code: "5934170", name: "COOP. DE A. Y C. 16 DE JUNIO" },
      {
        code: "5934196",
        name: "COOP. DE AHORRO Y CREDITO EDUCADORES DE EL ORO LTDA",
      },
      {
        code: "5934197",
        name: "COOP. DE A. Y C. EMPLEADOS BANCARIOS DEL ORO LTDA",
      },
      { code: "5935077", name: "COOPERATIVA DE AHORRO Y CREDITO SOCIO AMIGO" },
      { code: "5935217", name: "COOP.DE A. Y C. POPULAR Y SOLIDARIA" },
      { code: "5935218", name: "COOP. DE A. Y C. FAMILIA SOLIDARIA" },
      { code: "5934262", name: "COAC DEL SIND CHOF PROF VIRGEN DEL CISNE" },
      { code: "5931119", name: "COOP. DE AHORRO Y CREDITO ONCE DE JUNIO" },
      { code: "5931141", name: "COOP. DE AHORRO Y CRED. SANTA ROSA LTDA." },
      {
        code: "5934106",
        name: "COOPERATIVA DE AHORRO Y CREDITO MARCABELÍ LTDA",
      },
      {
        code: "5934045",
        name: "COOP. DE A. Y C. FUTURO Y PROGRESO DE GALAPAGOS LTDA.",
      },
      { code: "5935078", name: "COOP. DE A. Y C. INDIGENAS GALAPAGOS LTDA." },
      {
        code: "5934263",
        name: "COOPERATIVA DE AHORRO Y CREDITO GALAPAGOS LTDA.",
      },
      { code: "5930039", name: "BANCO COMERCIAL DE MANABI" },
      { code: "5930229", name: "COOP.AHORRO Y CREDITO CHONE LTDA." },
      {
        code: "5934094",
        name: "COOPERATIVA DE AHORRO Y CREDITO AGRICOLA JUNIN LTDA",
      },
      { code: "5934083", name: "COOP. DE AHORRO Y CREDITO PUERTO LOPEZ LTDA." },
      {
        code: "5934009",
        name: "COOP. AHORRO Y CREDITO CAMARA DE COMERCIO DEL CANTON BOLIVAR LTDA.",
      },
      {
        code: "5934171",
        name: "COOP. A.Y C. ESC.SUP.POLITEC. AGROP. DE MANABI MANUEL FELIX LOPEZ LTDA",
      },
      { code: "5934198", name: "COOPERATIVA DE AHORRO Y CREDITO RIOCHICO" },
      { code: "5934199", name: "COOP. DE A. Y C. LA UNIÓN LTDA." },
      {
        code: "5935079",
        name: "COOPERATIVA DE AHORRO Y CRÉDITO LA BENÉFICA LTDA.",
      },
      {
        code: "5935080",
        name: "COOPERATIVA DE AHORRO Y CREDITO SAN ISIDRO LTDA.",
      },
      {
        code: "5935081",
        name: "COOP.DE AHORRO Y CRÉDITO ABDON CALDERON LTDA.",
      },
      {
        code: "5935082",
        name: "COOP. A Y C CAMARA DE COMERCIO CANTON -EL CARMEN LTDA",
      },
      {
        code: "5935083",
        name: "COOP.DE AHORRO Y CREDITO AGROPRODUCTIVA MANABI LTDA.",
      },
      {
        code: "5935084",
        name: "COOP. DE AHORRO Y CRED. LA INMACULADA DE SAN PLACIDO LTDA.",
      },
      { code: "5935085", name: "COOP. AHORRO Y CREDITO CACPE MANABI" },
      {
        code: "5935086",
        name: "COOP.AHORRO Y CREDITO MAGISTERIO MANABITA LIMITADA",
      },
      { code: "5935087", name: "COAC TIENDA DE DINERO LTDA." },
      {
        code: "5935088",
        name: "COOP.A.Y C. SANTA MARIA DE LA MANGA DEL CURA LTDA.",
      },
      { code: "5935219", name: "COOP.DE AHORRO Y CREDITO SAN PLACIDO LTDA." },
      {
        code: "5935220",
        name: "COOPERATIVA DE AHORRO Y CREDITO EL PARAISO MANGA DEL CURA",
      },
      {
        code: "5935221",
        name: "COAC DE LOS PROFESORES EMPLEADOS Y TRABAJADORES DE LA U.T.M.",
      },
      { code: "5935222", name: "COAC SAN MIGUEL DE CHIRIJOS LTDA" },
      { code: "5930224", name: "COOP. COMERCIO LTDA. (PORTOVIEJO)" },
      { code: "5930231", name: "COOP. DE A. Y C. SANTA ANA LTDA.-MANABI" },
      { code: "5939987", name: "COOP. CALCETA LTDA" },
      { code: "5939995", name: "COOP. AHORRO Y CREDITO 15 DE ABRIL LTDA" },
      { code: "5935223", name: "COAC. SANTA ROSA DE SAN CARLOS LTDA." },
      {
        code: "5934107",
        name: "COOPERATIVA DE AHORRO Y CREDITO NUEVA ESPERANZA",
      },
      {
        code: "5934092",
        name: "COOPERATIVA DE AHORRO Y CREDITO SAN JORGE LTDA",
      },
      { code: "5930219", name: "COOP. AHORRO Y CREDITO RIOBAMBA" },
      {
        code: "5934073",
        name: "COOP. DE AHORRO Y CREDITO EDUCADORES DE CHIMBORAZO",
      },
      { code: "5939988", name: "COOPERATIVA DE AHORRO Y CREDITO MINGA LTDA." },
      { code: "5934062", name: "COOP. DE AHORRO Y CREDITO 4 DE OCTUBRE LTDA." },
      {
        code: "5934010",
        name: "COOP. AHORRO Y CREDITO CAMARA DE COMERCIO INDIGENA DE GUAMOTE LTDA.",
      },
      {
        code: "5934102",
        name: "COOPERATIVA DE AHORRO Y CREDITO FERNANDO DAQUILEMA",
      },
      { code: "5934057", name: "COOP. DE A. Y C. SUMAC LLACTA LTDA." },
      { code: "5934049", name: "COOP. DE A. Y C. LUCHA CAMPESINA LTDA." },
      { code: "5934048", name: "COOP. DE A. Y C. GUAMOTE LTDA." },
      { code: "5934200", name: "COOP. DE A. Y C. SAN MARTIN DE TISALEO LTDA." },
      { code: "5934201", name: "COOP. DE A. Y C. ALLI TARPUC LTDA." },
      {
        code: "5934202",
        name: "COOP. DE AHORRO Y CREDITO SAN MIGUEL DE PALLATANGA",
      },
      {
        code: "5935090",
        name: "COOP. DE A.Y C. PRODUC. AHORRO INVERS. SERVICIO P.A.I.S. LTDA",
      },
      { code: "5935091", name: "COOP. DE A. Y C. FRANDESC LTDA." },
      { code: "5935092", name: "COOP. DE A. Y C. ÑUKA LLAKTA LTDA." },
      { code: "5935093", name: "COOP. DE A Y C. CAMARA DE COMERCIO RIOBAMBA" },
      { code: "5935094", name: "COOP. DE A. Y C. BASHALAN LTDA." },
      {
        code: "5935225",
        name: "COOP. DE A. Y C. CONSTRUCTOR DEL DESARROLLO SOLIDARIO CODESO LTDA.",
      },
      {
        code: "5935227",
        name: "COOP. A.YC.SAGRADA FAMILIA SOLIDARIDAD PASTORAL INDIGENA DE CACHA LTDA",
      },
      { code: "5935228", name: "COOP. DE A. Y C. ÑAUPA KAUSAY" },
      { code: "5935229", name: "COOP. DE A. Y C. EL ALTAR LTDA." },
      { code: "5935230", name: "COOPERATIVA DE AHORRO Y CREDITO TOTORAS" },
      { code: "5935231", name: "COOP. DE A. Y C. LUIS FELIPE DUCHICELA XXVII" },
      { code: "5935232", name: "COOP. DE A. Y C. NIZAG LTDA." },
      { code: "5935233", name: "COOP. DE A. Y C. MAKITA KUNCHIK" },
      { code: "5935234", name: "COOP. DE A. Y C. CERRADA MANUELA LEÓN" },
      { code: "5935235", name: "COOP. DE A. Y C. EL BUEN SEMBRADOR LTDA." },
      { code: "5934264", name: "COOP. DE A. Y C. ACHIK PAKARI LTDA" },
      { code: "5935236", name: "COOP.DE AHORRO Y CRÉDITO CIUDAD DE ZAMORA" },
      {
        code: "5934274",
        name: "COAC SINDICATO DE CHOFERES PROFESIONALES DE YANTZAZA",
      },
      {
        code: "5935095",
        name: "COOP. DE A. Y C. CÁMARA DE COMERCIO SANTO DOMINGO",
      },
      { code: "5935239", name: "COOP. DE A. Y C. CREDISOCIO" },
      { code: "5930217", name: "COOP. PABLO MUÑOZ VEGA." },
      { code: "5930222", name: "COOP. AHORRO Y CREDITO TULCAN" },
      { code: "5934024", name: "COOP. AHORRO Y CREDITO SAN GABRIEL LTDA." },
      { code: "5934120", name: "COOP. DE A. Y C. EDUCADORES TULCAN LTDA." },
      { code: "5934203", name: "COOP. DE A. Y C. PADRE VICENTE PONCE RUBIO" },
      { code: "5934121", name: "CAMARA DE COMERCIO JOYA DE LOS SACHAS" },
      { code: "5934136", name: "CORPORACIÓN EN LAS HUELLAS DEL BANCO GRAMEEN" },
      { code: "5934140", name: "CAJA DE AHORRO Y CRÉDITO MANU" },
      { code: "5934141", name: "CAJA DE AHORRO Y CRÉDITO FRONTERA SUR" },
      { code: "5934149", name: "CAJA DE AHORRO Y CRÉDITO EL INGENIO" },
      { code: "5934150", name: "COOP. DE AHORRO Y CRÉDITO SAN JOSÉ (EL AIRO)" },
      {
        code: "5934151",
        name: "CAJA DE AHORRO Y CRÉDITO NUESTRA SEÑORA DE LA MERCED",
      },
      { code: "5934152", name: "CAJA DE AHORRO Y CRÉDITO FRANCISCA CHIGUA" },
      { code: "5934153", name: "CAJA DE AHORRO Y CRÉDITO EL MANIZAL" },
      { code: "5934156", name: "CAJA SOLIDARIA CHIQUICHA" },
      { code: "5934157", name: "CAJA SOLIDARIA ESTRELLA DEL MUNDO" },
      { code: "5934158", name: "COOP. DE A Y C DESARROLLO POPULAR" },
      { code: "5934161", name: "CORPORACIÓN VIENTOS SOLIDARIOS" },
      { code: "5934162", name: "COOP. ÁGUILAS DE CRISTO" },
      { code: "5934164", name: "COOP. CREDIUNIÓN" },
      { code: "5935096", name: "COOP. OLMEDO" },
      {
        code: "5934204",
        name: "CORPORACIÓN DE DESARROLLO SOCIAL Y FINANCIERO ISLAS ENCANTADAS",
      },
      { code: "5934205", name: "COOP. DE SERVICIOS MÚLTIPLES AGRO VIDA" },
      { code: "5934206", name: "COOP. DE LA MICROEMPRESA DE CHIMBORAZO" },
      {
        code: "5934207",
        name: "COOP. DE A Y C 4 DE OCTUBRE SAN FRANCISCO DE CHAMBO",
      },
      { code: "5934208", name: "COOP. DE A Y C 26 DE SEPTIEMBRE LÁZARO CONDO" },
      { code: "5934209", name: "CAJA SOLIDARIA DE A Y C CORDTUCH" },
      { code: "5934211", name: "COOP. 15 DE DICIEMBRE" },
      { code: "5935098", name: "COOP. PROD. Y DES. AGR. COOPRODESA LTDA" },
      { code: "5935100", name: "COOP. SAN ANTONIO DE TOACASO" },
      {
        code: "5935101",
        name: "CORPORACIÓN DE DESARROLLO FINANCIERA RHUMY WARA",
      },
      {
        code: "5935102",
        name: "CORPORACIÓN DE DESARROLLO FINANCIERA PRODVISIÓN",
      },
      { code: "5935103", name: "CAJA DE AHORRO Y CRÉDITO ORIENTE" },
      { code: "5935104", name: "COOP. NUEVA FUERZA ALIANZA" },
      { code: "5935105", name: "CAJA SOLIDARIA WARMIKUNA KAWSAY" },
      { code: "5935106", name: "COOP. SAN PEDRO DE PELILEO" },
      { code: "5935107", name: "CAJA SOLIDARIA PASA" },
      { code: "5935108", name: "CORPORACIÓN ORIENTE" },
      { code: "5935110", name: "CAJA CREDITO DESARROLLO" },
      { code: "5935111", name: "COOP. MUSHUK PAKARIK" },
      { code: "5935113", name: "CORPORACIÓN SANTANDER" },
      { code: "5935114", name: "CORPORACIÓN SAN FRANCISCO DE CHIBULEO" },
      { code: "5935115", name: "CAJA SOLIDARIA JATUN ÑAN" },
      { code: "5935116", name: "COOP. 15 DE MAYO" },
      { code: "5935118", name: "COOP. CAMINO DE ORO KURIÑAN" },
      { code: "5935119", name: "COOPERATIVA SAN FERNANDO" },
      { code: "5935121", name: "CAJA ECUAESPAÑA" },
      { code: "5935122", name: "CAJA DE AHORRO Y CRÉDITO BANCAJA" },
      { code: "5935243", name: "COAC CORPUCOOP LTDA" },
      { code: "5935244", name: "COAC CHUNCHI" },
      { code: "5935245", name: "COAC LOS RIOS" },
      {
        code: "5935248",
        name: "Cooperativa de Ahorro  y Crédito El Molino Ltda",
      },
      {
        code: "5935250",
        name: "Cooperativa  de Ahorro y Crédito para el Progreso Microempresarial COOPROMIC LTDA",
      },
      {
        code: "5935252",
        name: "Cooperativa de Ahorro y Crédito  CAMPESINA COOPAC ",
      },
      {
        code: "5935253",
        name: "COOP. DE A. Y C. INTEGRACION DESARROLLO Y FUTURO INDESFUT LTDA.",
      },
      { code: "5935254", name: "BCE-DINERO ELECTRONICO-SCI" },
      { code: "5935256", name: "COAC AHORRISTA SOLIDARIO" },
      {
        code: "5935258",
        name: "Cooperativa  de Ahorro y Crédito Distrito Metropolitano Quito Amazonas",
      },
      {
        code: "5935259",
        name: "Cooperativa  de Ahorro y Crédito Armada Nacional",
      },
      { code: "5935261", name: "COOP. DE A. Y C. INTEGRACION SOLIDARIA LTDA." },
      { code: "5935262", name: "COOP. DE A. Y C. SUMAK KAWSAY" },
      {
        code: "5935266",
        name: "COOP. DE A. Y C. INDIGENAS DE CEBADAS COICE LTDA.",
      },
      {
        code: "593228",
        name: "COOP.AHORRO Y CREDITO CAMARA DE COMERCIO QUITO",
      },
      {
        code: "5934063",
        name: "COOP. DE AHORRO Y CREDITO ACCION Y DESARROLLO LTDA",
      },
      { code: "5934069", name: "COOP. DE AHORRO Y CREDITO CRISTO REY" },
      { code: "5934177", name: "COOP. DE A. Y C. COOPAC AUSTRO LTDA (MIESS)" },
      { code: "5934215", name: "COOP. DE A. Y C. TOTALIFE LTDA" },
      { code: "5934254", name: "COOPERATIVA DE AHORRO Y CREDITO ETAPA" },
      { code: "5934255", name: "COOP. AHORRO Y CREDITO MASCOOP" },
      { code: "5934256", name: "COOP. DE A. Y C. SANTA LUCIA LTDA" },
      { code: "5934257", name: "COOP. DE A. Y C. SAINT MICHEL LTDA." },
      { code: "5934258", name: "COAC EL MIGRANTE SOLIDARIO" },
      {
        code: "5934265",
        name: "COAC SINDICATO DE CHOFERES PROFESIONALES DE YANTZA",
      },
      { code: "5935076", name: "COOP.DE AHORRO Y CREDITO 29 DE ENERO" },
      { code: "5935089", name: "COOP. DE A. Y C. SOL DE LOS ANDES LTDA." },
      {
        code: "5935273",
        name: "Cooperativa de Ahorro y Crédito General Angel Flores ",
      },
      { code: "5935274", name: "Cooperativa de Ahorro y Crédito 14 de Mayo  " },
      {
        code: "5935275",
        name: "Cooperativa de Ahorro y Crédito San Francisco",
      },
      {
        code: "5935276",
        name: "Cooperativa de Ahorro y Crédito Banco Central del Ecuador",
      },
      {
        code: "5935277",
        name: "Cooperativa de Ahorro y Crédito Simón Bolívar",
      },
      { code: "5935278", name: "COOPERATIVA DE AHORRO Y CREDITO FINANDER" },
      { code: "5935279", name: "COOP. DE A. Y C. DON BOSCO" },
      { code: "5935280", name: "COOP. DE A. Y C. ECUACREDITOS LTDA" },
      {
        code: "5935281",
        name: "COOP.DE A.Y C.UNIÓN FERROVIARIA ECUATORIANA LTDA.",
      },
      {
        code: "5935283",
        name: "COOPERATIVA DE AHORRO  Y CREDITO  RURAL SIERRA NORTE ",
      },
      {
        code: "5935284",
        name: "COOPERATIVA DE AHORRO  Y CREDITO  DIVINO NIÑO",
      },
      {
        code: "5935285",
        name: "COOPERATIVA DE AHORRO Y CREDITO COSTA AZUL LTDA",
      },
      {
        code: "5935286",
        name: "COOPERATIVA DE AHORRO  Y CREDITO LA NUESTRA LTDA ",
      },
      {
        code: "5935287",
        name: "BOLSA DE VALORES DE QUITO BVQ SOCIEDAD ANONIMA",
      },
      {
        code: "5935289",
        name: "COOPERATIVA DE AHORRO  Y CREDITO SEMBRANDO FUTURO",
      },
      { code: "5935290", name: "COAC ATLANTIDA" },
      { code: "5935291", name: "COOPERATIVA DE AHORRO Y CREDITO LLACTA PURA " },
      {
        code: "5935294",
        name: "COOPERATIVA DE AHORRO Y CREDITO CRECIENDO JUNTOS LTDA",
      },
    ],
  };
