import { Box, Button, Grid, Typography } from "@mui/material";
import { PlusCircle } from "react-feather";
import { TableSimple } from "@kushki/connect-ui";
import React from "react";
import { FilterTableComponent } from "../FilterTableComponent/FilterTableComponent";
import { useFilterTableState } from "./state/useFilterTableState";
import { ItemsPerPage } from "../../shared/infrastructure/constants/BusinessRuleConstants";
import { PathsEnum } from "../../shared/enums/PathsEnum";
import { get } from "lodash";
import { ModalSimple } from "@kushki/connect-ui";
import { ConfirmModalEnum } from "../../shared/enums/ConfirmModalEnum";
import { IFilterTableProps } from "../../shared/interfaces/IFilterTableProps";
import { LabelEnum } from "../../shared/enums/LabelEnum";

export const FilterTable: React.FC<IFilterTableProps> = (
  props: IFilterTableProps
) => {
  const {
    openConfirmModal,
    cells,
    selectedPage,
    itemsPerPage,
    rows,
    actions,
    searchRuleRequest,
    searchRuleResponse,
    filterSelectList,
    isLoading,
  } = useFilterTableState(props.isBatch);

  return (
    <Box
      width={{
        md: 1200,
      }}
    >
      <Grid container spacing={2} marginTop={"25px"} marginBottom={"25px"}>
        <Grid item xs={9}>
          <Typography color="text.primary" variant={"h1"}>
            {props.title}
          </Typography>
        </Grid>

        {props.addRuleButton ? (
          <Grid item xs={3}>
            <Button
              variant="contained"
              color={"primary"}
              size={"large"}
              startIcon={<PlusCircle size={16} />}
              fullWidth
              onClick={actions.handleAddRule}
              data-testid={"buttonTest"}
            >
              {LabelEnum.BTN_ADD_RULE}
            </Button>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Typography color="text.primary" variant={"body1"}>
            {LabelEnum.TABLE_INSTRUCTION}
          </Typography>
        </Grid>
      </Grid>
      <FilterTableComponent
        searchRuleRequest={searchRuleRequest}
        setSearchRuleRequest={actions.handleSearchRuleRequest}
        handleSelectItem={actions.handleSelectItem}
        filterSelectList={filterSelectList}
        handleDataChange={actions.handleDataChange}
        setOffset={actions.handleSetOffset}
      />
      <TableSimple
        isLoading={isLoading}
        footerPagination={{
          isDisable: false,
          itemsPerPage,
          onItemsPerPageChange: (value: number) =>
            actions.handleItemsPerPage(value),
          onPageClick: (value: number) => actions.handleSelectedPage(value),
          paginationText: LabelEnum.FOOTER_PAGINATION_TITLE,
          selectedPage,
          textFieldSelector: ItemsPerPage,
          totalItems: get(searchRuleResponse, PathsEnum.SEARCH_RULE_TOTAL, 0),
        }}
        header={{
          cells,
          headerProps: {
            isDisable: false,
          },
        }}
        messageBlock={{
          body: LabelEnum.ADD_BUSINESS_RULES,
          image: <></>,
          title: LabelEnum.NO_INFORMATION,
          typeMessage: 1,
        }}
        rows={rows}
        showPagination
      />
      <ModalSimple
        isOpen={openConfirmModal}
        onClose={actions.handleCancelConfirmModal}
        typeModal={1}
        onClickPrimary={actions.handleUpdateStatusRule}
        onClickSecondary={actions.handleCancelConfirmModal}
        buttonPrimary={ConfirmModalEnum.ACCEPT_BUTTON}
        buttonSecondary={ConfirmModalEnum.CANCEL_BUTTON}
        descriptionText={ConfirmModalEnum.DISABLE_DESCRIPTION}
        titleText={ConfirmModalEnum.DISABLE_TITTLE}
        buttonPrimaryDisabled={false}
      />
    </Box>
  );
};
