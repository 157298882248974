import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./reducers/app/app";
import accountPreferencesReducer from "./reducers/accountPrefrences/accountPreferences.slice";
import merchantNodePreferencesReducer from "./reducers/merchantNode/merchantNode.slice";

export const store = configureStore({
  devTools: process.env.NEXT_PUBLIC_ENV_NAME !== "primary",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    accountPreference: accountPreferencesReducer,
    app: appReducer,
    merchantNode: merchantNodePreferencesReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
