import * as React from "react";
import { Grid, TextField, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { RangeDatePicker } from "@kushki/connect-ui";
import { searchTextStyles as styles } from "./SearchText.styles";
import { CatalogTextByEntityName } from "../../shared/catalogs/CatalogTextByEntityName";
import { get } from "lodash";

export interface searchTextProps {
  entityName: string;
  onEnter: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onDateSelected: (startDate: string, finishDate: string) => void;
  searchMessage: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  value?: string;
  showRangePicker: boolean;
}

export const SearchText: React.FC<searchTextProps> = (
  props: searchTextProps
) => {
  return (
    <Grid container item xs={8} sx={{ alignItems: "center" }}>
      <Grid item xs={6}>
        <Tooltip
          title={`${get(
            CatalogTextByEntityName[props.entityName],
            "tooltipSearchText",
            ""
          )}`}
        >
          <TextField
            placeholder={`${get(
              CatalogTextByEntityName[props.entityName],
              "searchText",
              ""
            )}`}
            sx={styles.searchText}
            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) =>
              props.onEnter(e)
            }
            value={props.value}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => props.onChange(e)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {" "}
                  <SearchIcon color={"primary"} />{" "}
                </InputAdornment>
              ),
            }}
            size="medium"
          />
        </Tooltip>
      </Grid>
      <Grid item xs={6} sx={styles.rangeDate}>
        {props.showRangePicker && (
          <RangeDatePicker
            dateFormat="dd/MMM/yyyy"
            onDateSelect={props.onDateSelected}
            placeholder={`${get(
              CatalogTextByEntityName[props.entityName],
              "rangeDateText",
              ""
            )}`}
            disabledFutureDate={false}
            isOpen={false}
            isFilled={true}
            disabledOldDate={false}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default SearchText;
