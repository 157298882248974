import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const AlertComponentStyles = createNamedStyles({
  alertContainer: {
    backgroundColor: "#FFEAEE",
    borderRadius: "8px",
    boxShadow:
      "0px 8px 17px rgba(106, 167, 197, 0.15), 0px 1.6px 2.7625px rgba(106, 167, 197, 0.075);\n" +
      "border-radius: 8px",
    padding: "24px",
    width: "80%",
    marginBottom: "24px",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: "16px",
  },
  title: {
    color: "#AD0C2A",
  },
  warningIcon: {
    color: "#AD0C2A !important",
    marginRight: "20px !important",
  },
  description: {
    fontSize: { sm: "13px" },
    color: "#6D7781",
  },
});
