import { Box, Grid, Tab, Tabs } from "@mui/material";
import { FC } from "react";
import { conciliationTabsStyles } from "./ConciliationTabs.styles";
import { useKshRedux } from "../../store/hooks/hooks";

const ConciliationTabs: FC = () => {
  const {
    actions: { setValueTab },
    store: { valueTab },
  } = useKshRedux();

  return (
    <Grid
      data-testid="conciliationTabs"
      container
      sx={conciliationTabsStyles.main}
    >
      <Grid item md={12}>
        <Box sx={conciliationTabsStyles.box}>
          <Tabs
            data-testid="tabs"
            value={valueTab}
            variant="fullWidth"
            onChange={(_, newValue) => setValueTab(newValue)}
            sx={conciliationTabsStyles.tabs}
          >
            <Tab label="Pendientes" value="1" sx={conciliationTabsStyles.tab} />
            <Tab
              data-testid="tabHistorical"
              label="Históricos"
              value="2"
              sx={conciliationTabsStyles.tab}
            />
          </Tabs>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ConciliationTabs;
