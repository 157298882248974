import { IStyles } from "../../shared/interfaces/Styles.interfaces";

const breadcrumbStyles: IStyles = {
  text: {
    color: "primary.main",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "170%",
  },
};

export { breadcrumbStyles };
