import React, { Fragment } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GetApp } from "@material-ui/icons";
import csvImage from "../../assets/image/csv.svg";
import alertImage from "../../assets/image/alert-icon.svg";
import step1 from "../../assets/image/step1-csv.svg";
import step2 from "../../assets/image/step2-csv.svg";
import step3 from "../../assets/image/step3-csv.svg";
import { FileUploadSubCardComponent } from "../FileUploadSubCardComponent/FileUploadSubCardComponent";
import { FileUpload, KshFile } from "../common/FileUpload/FileUpload";
import { RetentionEnum } from "../../shared/infrastructure/Enums/RetentionEnum";
import { CountryValueEnum } from "../../shared/infrastructure/Enums/CountryEnum";
import { capitalize, get } from "lodash";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { ISecurityAzureRoles } from "../../shared/infrastructure/interfaces/ISecurityAzureRoles";

const useStyles = makeStyles((theme: Theme) => ({
  labelDescription: {
    marginLeft: theme.spacing(2),
    fontSize: "18px",
  },
  labelAlert: {
    marginLeft: theme.spacing(2),
  },
  labelTitle: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    color: theme.palette.primary.main,
    fontSize: "28px",
  },
  labelDescriptionButton: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  labelTitleStep: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  contentWidth: {
    maxWidth: "fit-content",
  },
  cardStyle: { backgroundColor: "#F7FAFC", border: "none" },

  subLabelTitleDownload: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    color: "#6D7781",
    fontWeight: "lighter",
  },
  LabelTitleDownload: {
    color: "#023365",
    Weight: 500,
    Size: "16px",
    lineHeight: "24px",
    marginTop: theme.spacing(2),
  },
}));

export interface IFileUploadRetention {
  files: KshFile[] | undefined;
  handlerCaptureFile: (value: { prop: string; files?: KshFile }) => void;
  handlerDelete: (resultFiles: { prop: string; files?: KshFile }) => void;
  handleSetError: (payload: boolean) => void;
}

export interface FileUploadCardComponentState {
  fileUploader: IFileUploadRetention;
  country: CountryValueEnum | string;
  downloadTemplateCsv: () => void;
  downloadTemplateExcel: () => void;
  isLoadingDownloadFile: boolean;
  tab: number;
  securityAzure?: ISecurityAzureRoles;
}

export type FileUploadCardComponentProps = FileUploadCardComponentState;

export const FileUploadCardComponent: React.FC<FileUploadCardComponentProps> = (
  props: FileUploadCardComponentProps
) => {
  const classes = useStyles();
  return (
    <>
      <Card>
        <CardContent>
          <Container>
            <Grid container spacing={3} direction="row">
              <Grid item xs={8}>
                {[CountryValueEnum.Colombia].includes(
                  capitalize(props.country!) as CountryValueEnum
                ) && (
                  <Typography
                    className={classes.labelTitle}
                    variant="h5"
                    align="left"
                    color="inherit"
                  >
                    <b>Instrucciones:</b>
                  </Typography>
                )}
                <Box
                  display="flex"
                  flexGrow={1}
                  alignItems={"center"}
                  height={"100%"}
                >
                  <Typography
                    className={classes.labelDescription}
                    variant="h5"
                    align="left"
                    color="inherit"
                  >
                    Es necesario abrir los archivos de Google Sheets para que el
                    sistema los reconozca.
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box
                  display="flex"
                  flexGrow={1}
                  flexDirection="row"
                  alignItems={"center"}
                  justifyContent={"end"}
                  height={"100%"}
                >
                  <img src={csvImage} alt="csvImage" />
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={3} direction="row">
              <Grid item xs={12}>
                <Box
                  display="flex"
                  flexGrow={1}
                  alignItems={"center"}
                  height={"100%"}
                >
                  <Typography
                    className={classes.labelTitleStep}
                    variant="h5"
                    align="left"
                    color="inherit"
                  >
                    Pasos
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <FileUploadSubCardComponent
                  labelTitleInitial={true}
                  imgSource={step1}
                  titleCard={"Descarga y completa la plantilla"}
                  subtitleCard={
                    <div>
                      En este archivo excel encontrarás dos pestañas:
                      {[CountryValueEnum.Ecuador].includes(
                        capitalize(props.country!) as CountryValueEnum
                      ) && (
                        <ul>
                          <li>
                            Pestaña 1 “Códigos”: aquí encontrarás{" "}
                            {props.tab == 0
                              ? "los códigos cargados actualmente y las indicaciones permitidas para completar la pestaña “Retención  " +
                                RetentionEnum.IVA.toUpperCase()
                              : "las indicaciones permitidas para completar la pestaña “Retención  " +
                                RetentionEnum.RENTA.toUpperCase()}
                            ”.
                          </li>
                          <li>
                            Pestaña 2 “Retención{" "}
                            {props.tab == 0
                              ? " " + RetentionEnum.IVA.toUpperCase()
                              : " " + RetentionEnum.RENTA.toUpperCase()}
                            ”: aquí reemplazarás los valores NAN con los códigos
                            correspondientes. Recuerda borrar las
                            configuraciones que no deseas.
                          </li>
                        </ul>
                      )}
                      {[CountryValueEnum.Colombia].includes(
                        capitalize(props.country!) as CountryValueEnum
                      ) && (
                        <ul>
                          <li>
                            Pestaña 1 “Instrucciones”: aquí encontrarás las
                            indicaciones permitidas para completar la pestaña
                            “Retención ICA”.
                          </li>
                          <li>
                            Pestaña 2 “Retención ICA”: aquí llenarás los campos
                            con los códigos correspondiente.
                          </li>
                        </ul>
                      )}
                    </div>
                  }
                  reactBtn={
                    <Fragment>
                      <ComponentSecurityWrapper
                        componentId={get(
                          props,
                          "securityAzure.rolDownloadTemplate",
                          ""
                        )}
                      >
                        <Button
                          variant="outlined"
                          startIcon={<GetApp />}
                          size={"medium"}
                          disabled={props.isLoadingDownloadFile}
                          className={classes.contentWidth}
                          onClick={props.downloadTemplateCsv}
                        >
                          Descargar
                        </Button>
                      </ComponentSecurityWrapper>

                      <Typography
                        className={classes.LabelTitleDownload}
                        variant="body2"
                        align="left"
                        color="inherit"
                      >
                        Archivo con datos actuales
                      </Typography>
                      <Typography
                        className={classes.subLabelTitleDownload}
                        variant="body2"
                        align="left"
                        color="inherit"
                      >
                        En este archivo excel encontrarás las configuraciones
                        actuales de las retenciones.
                      </Typography>
                      <ComponentSecurityWrapper
                        componentId={get(
                          props,
                          "securityAzure.rolDownloadExcel",
                          ""
                        )}
                      >
                        <Button
                          variant="outlined"
                          startIcon={<GetApp />}
                          size={"medium"}
                          className={classes.contentWidth}
                          onClick={props.downloadTemplateExcel}
                        >
                          Descargar
                        </Button>
                      </ComponentSecurityWrapper>
                    </Fragment>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                {[CountryValueEnum.Ecuador].includes(
                  capitalize(props.country!) as CountryValueEnum
                ) && (
                  <FileUploadSubCardComponent
                    imgSource={step2}
                    titleCard={"Guardar el archivo"}
                    subtitleCard={
                      "Cuando termines de llenar los campos con las configuraciones elimina la pestaña 1 “Códigos” y guarda el archivo en formato .csv"
                    }
                  />
                )}
                {[CountryValueEnum.Colombia].includes(
                  capitalize(props.country!) as CountryValueEnum
                ) && (
                  <FileUploadSubCardComponent
                    imgSource={step2}
                    titleCard={"Guarda el archivo como .csv"}
                    subtitleCard={
                      <div>
                        <Box
                          display="flex"
                          flexGrow={1}
                          flexDirection="row"
                          alignItems={"space-around"}
                          justifyContent={"space-around"}
                          height={"100%"}
                        >
                          <img src={alertImage} alt="alertImage" />
                          <div className={classes.labelAlert}>
                            Cuando termines de llenar los campos con las
                            configuraciones elimina la pestaña 1 “Instrucciones”
                            y guarda el archivo en formato .csv
                          </div>
                        </Box>
                      </div>
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {[CountryValueEnum.Ecuador].includes(
                  capitalize(props.country!) as CountryValueEnum
                ) && (
                  <FileUploadSubCardComponent
                    imgSource={step3}
                    titleCard={"Agregar el archivo a continuación"}
                    subtitleCard={
                      "Selecciona el archivo .csv con las configuraciones que deseas cargar. Ten en cuenta que el archivo nuevo sustituirá cualquier configuración cargada anteriormente."
                    }
                    reactBtn={
                      <Box
                        pt={1}
                        display="flex"
                        flexGrow={1}
                        flexDirection="row"
                        alignItems={"start"}
                      >
                        <FileUpload
                          prop={"notificationFile"}
                          files={
                            props.fileUploader!.files !== undefined
                              ? [props.fileUploader!.files![0]]
                              : undefined
                          }
                          label={`Haz clic en "Elegir archivo" para buscar el
                          documento en tu dispositivo`}
                          maxSize={25}
                          onChange={props.fileUploader!.handlerCaptureFile}
                          onDelete={props.fileUploader!.handlerDelete}
                          multiple={false}
                          handlerError={props.fileUploader!.handleSetError}
                        />
                      </Box>
                    }
                  />
                )}
                {[CountryValueEnum.Colombia].includes(
                  capitalize(props.country!) as CountryValueEnum
                ) && (
                  <FileUploadSubCardComponent
                    imgSource={step3}
                    titleCard={"Agrega el archivo a continuación"}
                    subtitleCard={
                      "Selecciona el archivo .csv con las configuraciones que deseas cargar. Ten en cuenta que el archivo nuevo sustituirá cualquier configuración cargada anteriormente."
                    }
                    reactBtn={
                      <Box
                        pt={1}
                        display="flex"
                        flexGrow={1}
                        flexDirection="row"
                        alignItems={"start"}
                      >
                        <FileUpload
                          prop={"notificationFile"}
                          files={
                            props.fileUploader!.files !== undefined
                              ? [props.fileUploader!.files![0]]
                              : undefined
                          }
                          label={`Haz clic en "Elegir archivo" para buscar el
                          documento en tu dispositivo`}
                          maxSize={25}
                          onChange={props.fileUploader!.handlerCaptureFile}
                          onDelete={props.fileUploader!.handlerDelete}
                          multiple={false}
                          handlerError={props.fileUploader!.handleSetError}
                        />
                      </Box>
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Container>
        </CardContent>
      </Card>
    </>
  );
};
