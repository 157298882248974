import { IUseFilterTableState } from "../../../shared/infrastructure/interfaces/IFilterTableProps";
import { AppRoutes } from "../../../shared/infrastructure/routes";
import { useEffect, useState } from "react";
import React from "react";
import {
  HeaderCellProps,
  ITableCellProps,
  ITableRowProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { ItemSearchRuleResponse } from "../../../../types/item_search_rule_response";
import { buildOneCell } from "../../../shared/utils/utilsFile";
import {
  LabelEnum,
  TABLE_COLUMNS,
} from "../../../shared/infrastructure/enums/LabelEnum";
import { useSnackbar } from "@kushki/connect-ui";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import CheckIcon from "@mui/icons-material/Check";
import {
  COLUMNS_BY_TABS,
  COLUMNS_BY_TABS_STORAGE,
  TableBodyCellEnum,
  TableFieldsEnum,
} from "../../../shared/infrastructure/enums/DataTableEnum";
import { format } from "date-fns-tz";
import {
  deleteRule,
  getRequestById,
  getRequestRules,
  setOpenAlert,
  setOpenConfirmModal,
  setOpenModalInfo,
  setRequestRuleById,
  setSearchRequestRulesResponse,
  updateOrDeleteRuleInList,
  updateRule,
} from "../../../store/actionCreators";
import { defaultTo, get, isEmpty, isUndefined, set, remove } from "lodash";
import { useDispatch } from "react-redux";
import {
  OptionsTypeEnum,
  RuleStatusEnum,
  TableOptionsEnum,
} from "../../../shared/infrastructure/enums/TableBodyCellEnum";
import {
  EndpointsPathsEnum,
  PathsEnum,
} from "../../../shared/infrastructure/enums/PathsEnum";
import { SearchRuleRequest } from "../../../../types/search_rule_request";
import { SearchRuleResponse } from "../../../../types/search_rule_response";
import {
  STATUS_SPANISH,
  StatusEnum,
} from "../../../shared/infrastructure/enums/StatusEnum";
import {
  CATEGORY_BY_CONDITIONS,
  CATEGORY_BY_CONDITIONS_BATCH,
  CONDITIONS_PATHS,
  DateFormatEnum,
} from "../../../shared/infrastructure/constants/BusinessRuleConstants";
import { useAppSelector } from "../../../store/hooks/store-hook";
import {
  AlertEnum,
  ERROR_MESSAGE_ALERT,
  SUCCESS_MESSAGE_ALERT,
} from "../../../shared/infrastructure/enums/AlertEnum";
import { ISearchByList } from "../../../shared/infrastructure/interfaces/ISearchByList";
import { StylesEnum } from "../../../shared/infrastructure/constants/StyleEnum";
import { useParams } from "react-router-dom";
import {
  getBusinessRulesStorage,
  getIsMassiveRules,
} from "../../../shared/utils/localStorage";
import {
  camelize,
  fromAnyToSearchRuleResponse,
} from "../../../utils/utilsFile";
import { isCentralizedBranch } from "../../../utils/utilsFile";
import { GetNodeInfoResponse } from "../../../../types/get_node_info_response";
import { TIME_OUT_VALUE } from "../../../shared/infrastructure/constants/TimeOutValue";
import {
  GetBusinessRulesResponse,
  Rule,
} from "../../../../types/get_business_rules_response";
import { LocalStoragePathEnum } from "../../../shared/infrastructure/enums/LocalStoragePathEnum";
import { ItemBusinessRule } from "../../../../types/item_business-rule";

export const useFilterTableState = (
  isBatch?: boolean
): IUseFilterTableState => {
  const dispatch = useDispatch();
  const { showSnackbar } = useSnackbar();
  const { merchantId } = useParams();
  const cells: HeaderCellProps[] = isCentralizedBranch()
    ? TABLE_COLUMNS[LabelEnum.BUSINESS_RULES_CENTRALIZED]
    : TABLE_COLUMNS[LabelEnum.BUSINESS_RULES];
  const [rows, setRows] = useState<ITableRowProps[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [item, setItem] = useState<ItemSearchRuleResponse>({
    status: "",
    request_type: "",
    entity_name: "",
    config_type: "",
    merchant_name: "",
    merchant_id: "",
    country: [],
    sub_type: "",
    service: "",
    regions: [],
    continents: [],
    node_id: "",
    rule: {
      general_action: "",
      code: "",
      partner_validator: "",
      message: "",
      name: "",
    },
  });
  const [ruleId, setRuleId] = useState<string>("");
  const [offset, setOffset] = useState<number>(0);
  const [isFilterSearch, setIsFilterSearch] = useState<boolean>(false);
  const [ruleTimeOut, setRuleTimeOut] = useState<any>(0);
  const defaultStartDate = `${format(new Date("0"), "yyyy-MM-dd")}T00:00:00`; // TODO remove "0" only for testing purposes.
  const defaultEndDate = `${format(new Date(), "yyyy-MM-dd")}T23:59:59`;
  const searchRuleResponse: SearchRuleResponse | undefined = useAppSelector(
    (state) => state.layout.searchRequestRulesResponse
  );
  const searchFilterRuleResponse: SearchRuleResponse | undefined =
    useAppSelector((state) => state.layout.searchFilterRulesResponse);
  const nodeInfoMerchant: GetNodeInfoResponse | undefined = useAppSelector(
    (state) => get(state, "layout.nodeInfoMerchant", undefined)
  );
  const openConfirmModal: boolean = useAppSelector((state) =>
    get(state.layout, "openConfirmModal", false)
  );
  const [openAlert, isError, originAlert] = useAppSelector((state) => {
    return [
      get(state.layout, "openAlert.open", false),
      get(state.layout, "openAlert.isError", false),
      get(state.layout, "openAlert.originAlert", ""),
    ];
  });
  const [searchRuleRequest, setSearchRuleRequest] = useState<
    SearchRuleRequest | undefined
  >();
  const [filterSelectList, setFilterSelectList] = useState<ISearchByList[]>([]);
  const defaultFilters: SearchRuleRequest = {
    from: "",
    to: "",
    limit: 0,
    offset: 0,
  };

  const isLoading: boolean | undefined = useAppSelector(
    (state) => state.layout.isLoadingRules
  );

  useEffect(() => {
    if (openAlert) {
      showSnackbar({
        message: isError
          ? ERROR_MESSAGE_ALERT[originAlert]
          : SUCCESS_MESSAGE_ALERT[originAlert],
        variant: "simple",
        color: isError ? "danger" : "info",
        icon: isError ? (
          <ErrorOutlinedIcon
            fontSize={StylesEnum.INHERIT}
            color={StylesEnum.INHERIT}
          />
        ) : (
          <CheckIcon fontSize={StylesEnum.INHERIT} color={StylesEnum.PRIMARY} />
        ),
        withIcon: true,
      });
      dispatch(setOpenAlert({ open: false, isError: false }));
    }
  }, [openAlert]);

  const handleAddRule = (): void => {
    window.location.href = AppRoutes.CREATE_BUSINESS_RULE;
  };

  const handleEditRule = (ruleId: string): void => {
    window.location.href = `/business-rules/edit/${ruleId}`;
  };

  const handleUpdateStatusRule = (): void => {
    dispatch(updateRule(ruleId, item));
    dispatch(setOpenConfirmModal(false));
  };

  const handleCancelConfirmModal = (): void => {
    dispatch(setOpenConfirmModal(false));
  };

  const handleAcceptConfirmModal = (): void => {
    dispatch(setOpenConfirmModal(true));
  };

  const handleDeleteRule = (ruleId: string): void => {
    if (!getIsMassiveRules()) {
      dispatch(deleteRule(ruleId));
      return;
    }

    const businessRulesStorage: GetBusinessRulesResponse =
      getBusinessRulesStorage();
    remove(businessRulesStorage.items, { id: ruleId });
    localStorage.setItem(
      LocalStoragePathEnum.BUSINESS_RULES_BATCH,
      JSON.stringify(businessRulesStorage)
    );
    dispatch(updateOrDeleteRuleInList(ruleId));
    dispatch(
      setOpenAlert({
        open: true,
        isError: false,
        originAlert: AlertEnum.ORIGIN_DELETE,
      })
    );
  };

  const handleSearchRuleRequest = (value: SearchRuleRequest | undefined) => {
    setSearchRuleRequest(value);
  };

  const calculateOffset = (page: number, itemsPerPage: number): void => {
    setOffset((page - 1) * itemsPerPage);
    setItemsPerPage(itemsPerPage);
  };

  const handleItemsPerPage = (value: number) => {
    setSelectedPage(1);
    calculateOffset(1, value);
  };

  const handleSelectedPage = (value: number) => {
    setSelectedPage(value);
    calculateOffset(value, itemsPerPage);
  };

  const transformDataToSchemaRowTable = (
    data: ItemSearchRuleResponse[]
  ): void => {
    let tableRowsProps: ITableRowProps[] = [];

    data.forEach((item: ItemSearchRuleResponse, index: number) => {
      tableRowsProps = [
        ...tableRowsProps,
        {
          rowProps: {
            color: "default",
            onClick: () => {
              dispatch(setOpenModalInfo(true));
              if (isBatch) {
                setRuleItem(item);
                return;
              }
              handleRequestRuleByIdBranch(get(item, PathsEnum.ID, ""));
            },
          },
          cells: buildCells(item),
          id: index.toString(),
        },
      ];
    });

    setRows(tableRowsProps);
  };

  const handleRequestRuleByIdBranch = (id: string) => {
    dispatch(getRequestById(EndpointsPathsEnum.RULE_REQUEST_BY_ID, id));
  };

  const setRuleItem = (item: ItemSearchRuleResponse) => {
    const ruleCreatedAt = isNaN(Number(item.id)) ? 0 : Number(item.id);
    const itemRule: ItemBusinessRule = {
      rule: { ...camelize(item), createdAt: ruleCreatedAt },
    };
    dispatch(setRequestRuleById(itemRule));
  };

  const buildCategoryByConditions = (item: ItemSearchRuleResponse): string => {
    let category: string = "";
    const ruleItem: Rule = {
      action: "",
      kind: "",
      metric: "",
      name: "",
      periodicity: "",
      periodicityCountDay: "",
      periodicityCountHour: "",
      transactionStatus: "",
    };

    if (isBatch) {
      for (const condition of CONDITIONS_PATHS) {
        if (!isEmpty(item.rule[condition]))
          ruleItem[condition] = item.rule[condition];
      }
    }

    Object.keys(isBatch ? ruleItem : item.rule).map((key: string) => {
      const value: string = isBatch
        ? CATEGORY_BY_CONDITIONS_BATCH[key]
        : CATEGORY_BY_CONDITIONS[key];
      if (!isUndefined(value)) {
        category += isEmpty(category) ? value : `, ${value}`;
      }
    });

    return category;
  };

  const buildCells = (item: ItemSearchRuleResponse): ITableCellProps[] => {
    const cells: ITableCellProps[] = [];
    const itemsByTabs: string[] = isBatch
      ? COLUMNS_BY_TABS_STORAGE[LabelEnum.BUSINESS_RULES]
      : COLUMNS_BY_TABS[LabelEnum.BUSINESS_RULES];

    const id: string = get(item, PathsEnum.ID, "");
    const status: string = defaultTo(
      get(item, TableFieldsEnum.STATUS),
      StatusEnum.ENABLE
    );
    const category: string = buildCategoryByConditions(item);
    set(item, "category", category);
    itemsByTabs.forEach((key: string) => {
      const itemText: string = defaultTo(get(item, key), "");
      switch (key) {
        case TableFieldsEnum.CREATED:
          if (getIsMassiveRules()) {
            if (isEmpty(item.ruleCreatedAt)) {
              cells.push(
                buildOneCell(TableBodyCellEnum.TEXT, LabelEnum.NO_APPLY)
              );
            } else {
              cells.push(
                buildOneCell(TableBodyCellEnum.TEXT, item.ruleCreatedAt!)
              );
            }
          } else {
            if (isEmpty(itemText)) {
              cells.push(
                buildOneCell(TableBodyCellEnum.TEXT, LabelEnum.NO_APPLY)
              );
            } else {
              cells.push(
                buildOneCell(
                  TableBodyCellEnum.TEXT,
                  format(new Date(itemText), DateFormatEnum.yyyy_mm_dd_hh_mm)
                )
              );
            }
          }
          break;
        case TableFieldsEnum.ALIAS:
          if (isEmpty(itemText)) {
            cells.push(
              buildOneCell(TableBodyCellEnum.TITLE, LabelEnum.NO_APPLY)
            );
          } else {
            cells.push(buildOneCell(TableBodyCellEnum.TITLE, itemText));
          }
          break;
        case TableFieldsEnum.CATEGORY:
          if (isEmpty(itemText)) {
            cells.push(
              buildOneCell(TableBodyCellEnum.TEXT, LabelEnum.NO_APPLY)
            );
          } else {
            cells.push(buildOneCell(TableBodyCellEnum.TEXT, itemText));
          }
          break;
        case isBatch
          ? TableFieldsEnum.PROCESSOR_RULE
          : TableFieldsEnum.PROCESSOR:
          if (isEmpty(itemText)) {
            cells.push(
              buildOneCell(TableBodyCellEnum.TEXT, LabelEnum.NO_APPLY)
            );
          } else {
            cells.push(buildOneCell(TableBodyCellEnum.TEXT, itemText));
          }
          break;
        case TableFieldsEnum.STATUS:
          const status: string = defaultTo(
            STATUS_SPANISH[itemText.toLowerCase()],
            STATUS_SPANISH[StatusEnum.ENABLE]
          ).toUpperCase();
          cells.push(
            buildOneCell(
              TableBodyCellEnum.TAG,
              status,
              undefined,
              {
                align: "center",
                spacing: 0,
              },
              itemText || StatusEnum.ENABLE
            )
          );
          break;
      }
    });
    if (!isCentralizedBranch()) {
      cells.push(getActionsTable(id, status, item));
    }

    return cells;
  };

  const getActionsTable = (
    ruleId: string,
    status: string,
    item: ItemSearchRuleResponse
  ): ITableCellProps => {
    const deleteDp = {
      disable: item.isSent,
      onClick: () => {
        handleDeleteRule(ruleId);
      },
      optionText: TableOptionsEnum.DELETE,
      type: OptionsTypeEnum.TYPE1,
      handleOptionClick: () => {},
    };
    const enableDp = {
      disable: isBatch,
      onClick: () => {
        handleEditRule(ruleId);
      },
      optionText: TableOptionsEnum.ENABLE,
      type: OptionsTypeEnum.TYPE1,
      handleOptionClick: () => {},
    };
    const disableDp = {
      disable: isBatch,
      onClick: () => {
        setItem({
          ...item,
          status: RuleStatusEnum.DISABLE,
        });
        setRuleId(ruleId);
        handleAcceptConfirmModal();
      },
      optionText: TableOptionsEnum.DISABLE,
      type: OptionsTypeEnum.TYPE1,
      handleOptionClick: () => {},
    };
    const editOp = {
      disable: item.isSent,
      onClick: () => {
        handleEditRule(ruleId);
      },
      optionText: TableOptionsEnum.EDIT,
      type: OptionsTypeEnum.TYPE1,
      handleOptionClick: () => {},
    };

    switch (status.toLowerCase()) {
      case StatusEnum.ENABLE:
        return {
          props: {
            cellProps: {
              spacing: 0,
            },
            isDisabled: false,
            optionListItems: [editOp, deleteDp, disableDp],
          },
          type: TableBodyCellEnum.OPTIONS,
        };
      case StatusEnum.DISABLE:
        return {
          props: {
            cellProps: {
              spacing: 0,
            },
            isDisabled: false,
            optionListItems: [enableDp, deleteDp],
          },
          type: TableBodyCellEnum.OPTIONS,
        };
      case StatusEnum.DRAFT:
        return {
          props: {
            cellProps: {
              spacing: 0,
            },
            isDisabled: false,
            optionListItems: [enableDp, deleteDp],
          },
          type: TableBodyCellEnum.OPTIONS,
        };
      default:
        return {
          props: {
            cellProps: {
              spacing: 0,
            },
            isDisabled: false,
            optionListItems: [enableDp, deleteDp],
          },
          type: TableBodyCellEnum.OPTIONS,
        };
    }
  };

  const mapFilterList = (): void => {
    const filterLastList: ISearchByList[] = [];
    const items: ItemSearchRuleResponse[] = get(
      searchFilterRuleResponse,
      "data",
      []
    );

    items.map((item: ItemSearchRuleResponse) =>
      filterLastList.push({
        secondaryText: get(item, "rule.processor_name", ""),
        text: get(item, "rule.name", ""),
        id: get(item, "id", ""),
      })
    );
    setFilterSelectList(filterLastList);
  };

  useEffect(() => {
    transformDataToSchemaRowTable(get(searchRuleResponse, "data", []));
  }, [searchRuleResponse]);

  useEffect(() => {
    mapFilterList();
  }, [searchFilterRuleResponse]);

  useEffect(() => {
    // TODO: Verify from and to field when date filter is implemented.
    const search: SearchRuleRequest = {
      from: isEmpty(get(searchRuleRequest, "from", ""))
        ? defaultStartDate
        : get(searchRuleRequest, "from", ""),
      to: isEmpty(get(searchRuleRequest, "to", ""))
        ? defaultEndDate
        : get(searchRuleRequest, "to", ""),
      limit: itemsPerPage,
      offset,
      filter: {
        status: get(searchRuleRequest, "filter.status", ""),
        type: LabelEnum.BUSINESS,
        category: get(searchRuleRequest, "filter.business_conditions", ""),
        name: get(searchRuleRequest, "filter.name", ""),
        processorName: get(searchRuleRequest, "filter.processorName", ""),
        merchant: get(searchRuleRequest, "filter.merchant", merchantId),
        rule_id: get(searchRuleRequest, "filter.rule_id", ""),
      },
    };

    Object.keys(search.filter!).forEach((key) => {
      if (isEmpty(search.filter![key])) {
        delete search.filter![key];
      }
    });
    isFilterSearch && delete search.filter![PathsEnum.RULE_ID];

    if (isBatch) {
      const storageRuleResponse: SearchRuleResponse =
        fromAnyToSearchRuleResponse(getBusinessRulesStorage());
      if (isEmpty(searchRuleResponse)) {
        dispatch(setSearchRequestRulesResponse(storageRuleResponse));
      }
      return;
    }

    if (!isEmpty(get(nodeInfoMerchant, "configCoreId", "")))
      dispatch(
        getRequestRules("rules", search, nodeInfoMerchant, isFilterSearch)
      );
  }, [itemsPerPage, offset, searchRuleRequest, nodeInfoMerchant]);

  const handleSelectItem = (selectedItem: ISearchByList): void => {
    setIsFilterSearch(false);
    const search: SearchRuleRequest = {
      ...defaultFilters,
    };

    set(search, "filter.rule_id", get(selectedItem, "id", ""));
    setSearchRuleRequest(search);
  };

  const handleDataChange = (value: string): void => {
    if (ruleTimeOut) {
      clearTimeout(ruleTimeOut);
    }
    if (value.trim().length > 0) {
      setRuleTimeOut(
        setTimeout(() => {
          setIsFilterSearch(true);
          const search: SearchRuleRequest = {
            ...defaultTo(searchRuleRequest, defaultFilters),
          };

          set(search, "filter.name", value);
          set(search, "filter.processorName", value);
          setSearchRuleRequest(search);
        }, TIME_OUT_VALUE.MILLISECONDS)
      );
    }
  };

  return {
    cells,
    searchRuleResponse,
    rows,
    searchRuleRequest,
    itemsPerPage,
    selectedPage,
    nodeInfoMerchant,
    openConfirmModal,
    filterSelectList,
    isLoading,
    actions: {
      handleItemsPerPage,
      handleSelectedPage,
      handleAddRule,
      handleSearchRuleRequest,
      handleSelectItem,
      handleDataChange,
      handleCancelConfirmModal,
      handleUpdateStatusRule,
    },
  };
};
