import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { X } from "react-feather";
import theme from "../../theme";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { InvoiceRecord } from "../../../types/remote/response_search_blling_by_filters";
import { defaultTo, get, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { IBillingDashboardState } from "../../store/reducer";
import { invoiceAnnul, setOpenModalAnnul } from "../../store/actionCreators";
import { Controller, useForm } from "react-hook-form";
import { useInvoiceAnnulStyles } from "./InvoiceAnnulModal.style";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import NumberFormat from "react-number-format";
import { InvoiceAnnulRequest } from "../../../types/invoice_annul_request";
import { translate } from "../../shared/infrastructure/LanguageCatatog";
import { useHistory } from "react-router-dom";
import { routes } from "../../shared/infrastructure/routes";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import { CausalReason } from "../../shared/infrastructure/CausalReasonCatalog";

export interface IInvoiceAnnulModal {
  trxInfo: InvoiceRecord | undefined;
}

interface InvoiceAnnulFields {
  causal: string;
  reason: string;
  folio: string;
  newInvoice: boolean;
  fileInvoice: any;
}

const accept_files_notify: string[] = [
  "application/pdf",
  "image/png",
  "image/jpg",
];
const cancellationReasonsPE: string[] = [
  "Anulación de la operación",
  "Anulación por error en el RUC",
  "Corrección por error en la descripción",
  "Descuento global",
  "Devolución total",
  "Disminución en el valor",
  "Otros conceptos",
];
export const InvoiceAnnulModal: React.FC<IInvoiceAnnulModal> = (
  props: IInvoiceAnnulModal
) => {
  const history = useHistory();
  const { trxInfo } = props;
  const [fileName, setFileName] = useState<string>("");
  const [fileBase64, setFileBase64] = useState<string>("");
  const dispatch = useDispatch();
  const classes = useInvoiceAnnulStyles();
  const {
    handleSubmit,
    control,
    errors,
    getValues,
    setError,
    clearErrors,
    reset,
  } = useForm<InvoiceAnnulFields>();
  const { openModal } = useSelector((state: IBillingDashboardState) => ({
    openModal: state.openAnnulModal,
  }));
  const [hideFolioFiscal, setHideFolioFiscal] = useState<boolean>(false);
  const [hideNewInvoiceCheck, setHideNewInvoiceCheck] = useState<boolean>(true);
  const [checkedCheckbox, setCheckedCheckbox] = useState<boolean>(false);
  const { redirectCreateInvoice } = useSelector(
    (state: IBillingDashboardState) => ({
      redirectCreateInvoice: state.redirectCreateInvoice,
    })
  );

  const onSubmit = (data: InvoiceAnnulFields) => {
    const invoiceAnnulData: InvoiceAnnulRequest = {
      fileName,
      file: defaultTo(fileBase64.split(",")[1], ""),
      invoiceId: get(trxInfo, "invoice_id"),
      reason: get(data, "reason", ""),
      motive: get(data, "causal", ""),
      uuid: get(data, "folio", ""),
      generateInvoice: checkedCheckbox,
    };
    dispatch(invoiceAnnul(invoiceAnnulData, checkedCheckbox));
  };

  useEffect(() => {
    if (redirectCreateInvoice) {
      history.push(`${routes.BASE_PATH_DASHBOARD}${routes.INVOICE}`);
    }
  }, [redirectCreateInvoice]);

  useEffect(() => {
    reset();
  }, []);

  const onFileChange = (fileList: FileList | null) => {
    if (!fileList || fileList.length === 0) return;
    const reader = new FileReader();
    const file: File = fileList[0];
    setFileName(file.name);
    clearErrors("fileInvoice");
    if (file.size > 10000000) {
      setError("fileInvoice", {
        type: "fileSize",
        message: "Tamaño del archivo",
      });
      return;
    }
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFileBase64(reader.result as string);
    };
  };

  const getTotalAmount = () => {
    const amount = get(trxInfo, "amount", undefined);
    const currency = get(trxInfo, "currency_code", "USD");

    if (isNaN(amount)) return "";

    return (
      <NumberFormat
        value={amount}
        thousandSeparator
        displayType={"text"}
        decimalScale={2}
        suffix={` ${currency || "USD"}`}
      />
    );
  };
  const onCausalChange = () => {
    const value: string = getValues("causal");
    const disableInputs: boolean = value === "02" || value === "03";

    if (!disableInputs) setHideNewInvoiceCheck(true);
    setHideFolioFiscal(disableInputs);
    setCheckedCheckbox(!disableInputs);
  };

  return get(trxInfo, "country", "") === CountryEnum.mexico ? (
    <Dialog
      open={openModal!}
      PaperProps={{
        style: {
          maxWidth: "600px",
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} id={"onSubmit-Mexico"}>
        <DialogTitle>
          <Typography variant={"body1"} className={classes.bold}>
            ¿Deseas solicitar la anulación este documento?
          </Typography>
          <IconButton
            onClick={() => dispatch(setOpenModalAnnul(false))}
            aria-label="close"
            className={classes.closeButton}
            id={"invoice-annul-close-dialog"}
          >
            <X size={18} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant={"body2"}
              color="inherit"
              id="invoice-annul-details"
            >
              Estás solicitando la anulación de la{" "}
              <span className={classes.subTextBlue}>
                {translate(trxInfo!.kind)} {get(trxInfo, "type_document", "")}
              </span>{" "}
              <span className={classes.subTextBlue}>
                {get(trxInfo, "invoice_number", "")}
              </span>{" "}
              por un monto total de{" "}
              <span className={classes.subTextBlue}>{getTotalAmount()}</span>.
              Para anular dale click en{" "}
              <span className={classes.subTextBlue}>
                &quot;Solicitar anulación de documento,&quot;
              </span>{" "}
              recuerda que esta acción es irreversible.
            </Typography>
          </DialogContentText>
          <Typography variant={"h6"} className={classes.bold}>
            Selecciona el motivo de la anulación interno Kushki*
          </Typography>
          <FormControl variant="outlined">
            <Controller
              id={"cancellation-reasons-controller"}
              style={{
                minWidth: "20rem",
              }}
              as={
                <TextField
                  id="cancellation-reasons-controller-select"
                  select
                  variant="outlined"
                  fullWidth
                  label="Motivo de anulación"
                  error={!!errors.reason}
                  helperText={
                    !!errors.reason && "Selecciona el motivo de anulación"
                  }
                >
                  {cancellationReasonsPE.map((reason) => (
                    <MenuItem value={reason} key={reason}>
                      {reason}
                    </MenuItem>
                  ))}
                </TextField>
              }
              name="reason"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
            />
          </FormControl>
          <Typography variant={"h6"} className={classes.bold}>
            Selecciona el causal de la anulación SAT*
          </Typography>
          <FormControl variant="outlined">
            <Controller
              id={"cancellation-causal-controller"}
              style={{
                minWidth: "20rem",
              }}
              as={
                <TextField
                  id="cancellation-causal-controller-select"
                  select
                  variant="outlined"
                  fullWidth
                  label="Motivo de la cancelación"
                  error={!!errors.causal}
                  helperText={
                    !!errors.causal && "Selecciona el motivo de la cancelación"
                  }
                  onClick={onCausalChange}
                >
                  {CausalReason.map((reason) => (
                    <MenuItem key={reason.value} value={reason.value}>
                      {reason.description}
                    </MenuItem>
                  ))}
                </TextField>
              }
              name="causal"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
            />
          </FormControl>
          {hideFolioFiscal && (
            <>
              <Typography variant={"h6"} className={classes.bold}>
                Ingresar folio
              </Typography>
              <FormControl variant="outlined">
                <Controller
                  id={"folio-fiscal-controller"}
                  style={{
                    minWidth: "20rem",
                  }}
                  as={
                    <TextField
                      id="outlined-folio"
                      key={"outlinedFolio"}
                      autoFocus
                      variant={"outlined"}
                      margin={"none"}
                      fullWidth
                      placeholder={"Ingrese nuevo folio fiscal"}
                      error={!!errors.folio}
                      helperText={
                        !!errors.folio && "Se debe ingresar 36 caracteres"
                      }
                    />
                  }
                  name="folio"
                  control={control}
                  defaultValue=""
                  rules={{
                    minLength: 36,
                    maxLength: 36,
                  }}
                />
              </FormControl>
            </>
          )}
          <Grid className={classes.uploadFileContainer}>
            <Typography variant={"h6"} className={classes.bold}>
              Adjunte el sustento de la anulación (opcional)
            </Typography>
            <Typography variant={"body2"} className={classes.sizeMessage}>
              Tamaño máximo de archivo 10 MB .png, .jpg .pdf
            </Typography>
          </Grid>
          <Grid className={classes.uploadFileContainer}>
            <FormControl variant="outlined">
              <Controller
                id={"folio-fiscal-controller"}
                style={{
                  minWidth: "20rem",
                }}
                as={
                  <label htmlFor={"invoiceAnnulFile"}>
                    <input
                      id="invoiceAnnulFile"
                      color="primary"
                      type="file"
                      accept={accept_files_notify.join(",")}
                      style={{ display: "none" }}
                      name="fileInvoice"
                      onChange={(event) => {
                        onFileChange(event.target.files);
                      }}
                    />
                    <Grid container>
                      <Grid item xs={12} style={{ paddingRight: 10 }}>
                        <Box display="flex">
                          {!fileName ? (
                            <Grid container alignItems={"center"}>
                              <Grid item>
                                <Button
                                  variant="text"
                                  component="span"
                                  className={classes.buttonFile}
                                  size={"small"}
                                >
                                  Elegir Archivo
                                </Button>
                              </Grid>
                              <Grid item sm>
                                <Typography
                                  variant={"body2"}
                                  color="inherit"
                                  style={{ paddingLeft: "6px" }}
                                >
                                  No se ha seleccionado ningún documento
                                </Typography>
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid container alignItems={"center"}>
                              <Grid item sm>
                                <Button
                                  variant="text"
                                  component="span"
                                  className={classes.buttonFile}
                                  size={"small"}
                                >
                                  Elegir Archivo
                                </Button>
                              </Grid>
                              <Grid item xs>
                                <Typography
                                  variant="body2"
                                  className={
                                    !!errors.fileInvoice
                                      ? classes.fileSelectedError
                                      : classes.fileSelected
                                  }
                                  id="invoice-annul-file-name"
                                >
                                  {fileName}
                                  {!errors.fileInvoice ? (
                                    <CheckIcon />
                                  ) : (
                                    <CloseIcon />
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                        </Box>
                        <Typography
                          variant={"caption"}
                          className={classes.fileMessage}
                        >
                          Haz clic en “Elegir archivo” para buscar el documento
                          en tu dispositivo.
                        </Typography>
                        <Box>
                          {!!errors.fileInvoice && (
                            <Typography variant={"caption"} color={"error"}>
                              El archivo {fileName} excede el tamaño permitido.
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </label>
                }
                name="fileInvoice"
                control={control}
                defaultValue=""
              />
            </FormControl>
          </Grid>
          {hideNewInvoiceCheck && (
            <>
              <Controller
                id={"check-controller-mexico"}
                as={
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={"check-controller-mexico-check"}
                        color={"primary"}
                        checked={checkedCheckbox || !hideFolioFiscal}
                        disabled={!hideFolioFiscal}
                        onChange={() => setCheckedCheckbox(!checkedCheckbox)}
                      />
                    }
                    disabled={!hideFolioFiscal}
                    hidden={isEmpty(getValues("causal"))}
                    color={!hideFolioFiscal ? "inherit" : "primary"}
                    label="Generar una nueva factura al finalizar la anulación aqui"
                    className={classes.messageText}
                  />
                }
                name="newInvoice"
                control={control}
                defaultValue={checkedCheckbox}
              />
            </>
          )}
          <Grid container alignItems={"center"}>
            <Grid
              item
              xs
              style={{ flexGrow: 0.06 }}
              className={classes.infoIcon}
            >
              <InfoOutlinedIcon style={{ color: "#cc6600" }} />
            </Grid>
            <Grid item xs>
              <Typography variant="overline">
                Pueden existir documentos asociados a esta factura que se
                reasignarán a la nueva factura creada.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Button
            id={"cancel-button-action"}
            onClick={() => dispatch(setOpenModalAnnul(false))}
            className={classes.cancelButton}
          >
            Cancelar
          </Button>
          <Button type={"submit"} autoFocus className={classes.annulButton}>
            Solicitar anulación de documento
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  ) : (
    <Dialog
      open={openModal!}
      PaperProps={{
        style: {
          maxWidth: "650px",
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <Typography variant={"h5"} className={classes.bold}>
            ¿Deseas anular este documento?
          </Typography>
          <IconButton
            onClick={() => dispatch(setOpenModalAnnul(false))}
            aria-label="close"
            className={classes.closeButton}
            id={"invoice-annul-close-dialog"}
          >
            <X size={18} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="h6"
              color="textPrimary"
              id="invoice-annul-details"
            >
              Estas a punto de anular la{" "}
              <span>
                {translate(trxInfo!.kind)} {get(trxInfo, "invoice_number", "")}
              </span>{" "}
              por un monto total de {getTotalAmount()}. Para anular dar click en
              &quot;Anular documento&quot; recuerda que esta acción es
              inreversible.
            </Typography>
          </DialogContentText>
          <Typography variant={"h6"} className={classes.bold}>
            Selecciona el motivo de la anulación
          </Typography>
          <FormControl variant="outlined">
            <InputLabel id="cancellation-reasons-controller-select">
              Motivo de anulación
            </InputLabel>
            <Controller
              id={"cancellation-reasons-controller"}
              style={{
                minWidth: "20rem",
              }}
              as={
                <Select
                  labelId="cancellation-reasons-controller-select"
                  id="demo-simple-select-outlined"
                  value={3}
                  label={"Motivo de anulación"}
                >
                  {cancellationReasonsPE.map((reason) => (
                    <MenuItem value={reason} key={reason}>
                      {reason}
                    </MenuItem>
                  ))}
                </Select>
              }
              name="reason"
              control={control}
              defaultValue=""
            />
          </FormControl>
          <Grid className={classes.uploadFileContainer}>
            <Typography variant={"subtitle1"} className={classes.bold}>
              Adjunte el sustento de la anulación
            </Typography>
            <Typography variant={"caption"} className={classes.sizeMessage}>
              Tamaño máximo de archivo 10 MB .png, .jpg .pdf
            </Typography>
          </Grid>
          <Grid className={classes.uploadFileContainer}>
            <label htmlFor={"invoiceAnnulFile"}>
              <input
                id="invoiceAnnulFile"
                color="primary"
                type="file"
                style={{ display: "none" }}
                name="fileInvoice"
                onChange={(event) => onFileChange(event.target.files)}
              />
              <Grid container>
                <Grid item xs={12} style={{ paddingRight: 10 }}>
                  <Box display="flex">
                    <Box>
                      <Button
                        variant="outlined"
                        component="span"
                        style={{
                          color: theme.palette.primary.dark,
                          border: `1px solid ${theme.palette.primary.dark}`,
                        }}
                        size={"medium"}
                      >
                        Elegir Archivo
                      </Button>
                    </Box>
                    {fileName && (
                      <Box pl={1}>
                        <Typography
                          variant="body1"
                          className={classes.fileSelected}
                          id="invoice-annul-file-name"
                        >
                          {fileName}
                          <CheckIcon />
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Typography
                    variant={"caption"}
                    className={classes.fileMessage}
                  >
                    Haz clic en “Elegir archivo” para buscar el documento en tu
                    dispositivo.
                  </Typography>
                </Grid>
              </Grid>
            </label>
          </Grid>
          <Divider className={classes.divider} />

          {![CountryEnum.peru].includes(get(trxInfo, "country", "")) && (
            <Controller
              id={"check-controller"}
              as={
                <FormControlLabel
                  control={<Checkbox color={"primary"} />}
                  label="Generar una nueva factura al finalizar la anulación"
                />
              }
              name="newInvoice"
              control={control}
              defaultValue={true}
            />
          )}

          <Grid container alignItems={"center"}>
            <Grid item xs className={classes.infoIcon}>
              <InfoOutlinedIcon color={"primary"} />
            </Grid>
            <Grid item xs>
              <Typography variant="body1">
                Pueden existir documentos asociados a esta factura que se
                reasignarán a la nueva factura creada.
              </Typography>
            </Grid>
          </Grid>

          {[CountryEnum.peru].includes(get(trxInfo, "country", "")) && (
            <Grid container alignItems={"center"}>
              <Grid item xs className={classes.infoIcon}>
                <InfoOutlinedIcon color={"primary"} />
              </Grid>
              <Grid item xs>
                <Typography variant="body1" style={{ marginTop: 10 }}>
                  El proceso de anulación tomará un mínimo de 10 minutos en
                  hacerse efectivo. Transcurrido este tiempo, podrás ver la
                  factura anulada en la sección Histórico de la tabla de
                  Facturación.
                </Typography>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => dispatch(setOpenModalAnnul(false))}
            className={classes.cancelButton}
          >
            Cancelar
          </Button>
          <Button type={"submit"} autoFocus className={classes.annulButton}>
            Anular documento
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
