import { makeStyles } from "@material-ui/core/styles";

export const filterComponentStyle = makeStyles({
  applyButton: {
    fontSize: "14px",
    fontWeight: 500,
    marginTop: "8px",
    width: "150px",
  },
  banksValidationStyle: {
    color: "#023365",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "120%",
    paddingLeft: "10px",
  },
  borderCard: {
    border: "1px solid #CBD5E0",
    borderRadius: "4px",
  },
  buttonText: {
    color: "#293036 !important",
    fontSize: "14px !important",
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: "140% !important",
  },
  checkbox: {
    display: "flex",
    flexDirection: "row",
    fontSize: "14px",
    fontWeight: "bold",
    marginLeft: "10px",
  },
  chip: {
    backgroundColor: "#F7FAFC",
    borderRadius: "4px",
    color: "#677684",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    height: "30px",
    lineHeight: "17px",
    padding: "8px",
    width: "auto",
  },
  chipIcon: {
    color: "#293036",
  },
  chipLabel: {
    backgroundColor: "#F7FAFC",
    borderRadius: "5px",
    color: "#293036",
    fontSize: "14px",
    fontWeight: 400,
  },
  chipRounded: {
    background: "#F0F4F9",
    borderRadius: 5,
    height: "auto",
    marginLeft: "8px",
    width: "auto",
  },
  chipSelected: {
    backgroundColor: "#0DC298",
    borderRadius: "4px",
    color: "#FFFFFF",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    height: "30px",
    lineHeight: "17px",
    padding: "8px",
    width: "auto",
  },
  deleteButton: {
    color: "#293036",
    fontSize: "14px",
    fontWeight: 500,
    marginTop: "8px",
    width: "150px",
  },
  divider: {
    border: "1px #CBD5E0",
  },
  expandedCollapsed: {
    color: "#023365",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
  },
  filterButton: {
    backgroundColor: "#FFFFFF",
    height: "36px",
    width: "15%!important",
  },
  filterByText: {
    color: "#293036",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: "24px",
  },
  firstDivider: {
    color: "#F0F4F9",
  },
  gridRight: {
    textAlign: "right",
  },
  innerLabel: {
    color: "#6D7781",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    marginBottom: "5px",
  },
  innerSubLabel: {
    color: "#6D7781",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
    marginBottom: "10px",
  },

  mainGrid: {
    maxWidth: "500px",
    minWidth: "400px",
    padding: "12px",
  },

  nonSpace: {
    margin: "0px",
    padding: "0px",
  },

  paddingContent: {
    padding: "20px 22px",
  },
  placeContent: {
    color: "#293036",
    fontSize: "14px!important",
    fontStyle: "normal",
    fontWeight: 400,
  },
  rootGrid: {
    marginBottom: "30px",
    marginTop: "30px",
  },
  secondDivider: {
    color: "#CBD5E0",
  },

  titleContent: {
    color: "#023365",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    paddingBottom: "20px",
  },
});
