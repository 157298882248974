import { IAppAction } from "./actionCreators";
import { INotification } from "../shared/infrastructure/interfaces/INotification";
import { ActionTypes } from "./actionTypes";
import CheckIcon from "@material-ui/icons/Check";
import { TransactionTable } from "../../types/transaction_table";
import { OriginEnum } from "../shared/infrastructure/OriginEnum";
import { ConciliationRequest } from "../../types/conciliation_request";
import { IHeaderTable } from "../shared/infrastructure/headers/defaultHeaders";
import {
  TransactionSummaryData,
  TransactionTableSummary,
} from "../../types/transaction_table_summary";
import { LoadStatusEnum } from "../shared/infrastructure/LoadStatus";
import { TransactionData } from "../../types/transaction_data";

export interface IModalConciliation {
  conciliation: OriginEnum;
  conciliationMasive: boolean;
  transactions:
    | TransactionData[]
    | TransactionTableSummary[]
    | TransactionTable[];
  transactionsNoSelected: TransactionData[];
  open: boolean;
  total: number;
}

export interface IModalOmit {
  omitTransaction: OriginEnum;
  omitMasive: boolean;
  transactions: TransactionData[] | TransactionSummaryData;
  transactionsNoSelected: TransactionData[];
}

export interface IAppState {
  notification?: INotification;
  loadingFileDownload?: boolean;
  conciliationData: TransactionTable | TransactionTableSummary;
  actualHeaders: IHeaderTable[];
  loadStatus?: LoadStatusEnum;
  isUploadingConciliation?: boolean;
  isUploadedConciliation?: boolean;
  conciliationUploadError?: boolean;
  conciliationFileKey?: string;
  modalConciliation?: IModalConciliation;
  modalOmit?: IModalOmit;
  modalProps?: ConciliationRequest;
  openModalDetail: boolean;
  indexTransactionSummaryTab: number;
  isLoadingSummaryData?: boolean;
  isLoadingTransactionData?: boolean;
  loadingMassive?: boolean;
}

export const INITIAL_STATE: IAppState = {
  isUploadingConciliation: false,
  isUploadedConciliation: false,
  conciliationFileKey: "",
  conciliationUploadError: false,
  notification: {
    type: "success",
    open: false,
    message: "",
    horizontal: "right",
    vertical: "bottom",
    icon: CheckIcon,
  },
  modalConciliation: {
    conciliation: OriginEnum.TRANSACTIONS,
    conciliationMasive: false,
    transactions: [],
    transactionsNoSelected: [],
    open: false,
    total: 0,
  },
  modalOmit: {
    omitTransaction: OriginEnum.TRANSACTIONS,
    omitMasive: false,
    transactions: [],
    transactionsNoSelected: [],
  },
  conciliationData: { data: [], total: 0 },
  actualHeaders: [],
  loadStatus: LoadStatusEnum.NONE,
  loadingFileDownload: false,
  modalProps: {
    state: "CONCILIATION",
    liquidationAmount: -5,
    comment: "",
    source: "",
    path: "",
    fileName: "",
    data: [],
  },
  openModalDetail: false,
  indexTransactionSummaryTab: 0,
  loadingMassive: false,
};

export const reducer = (
  state: IAppState = INITIAL_STATE,
  action: IAppAction
): IAppState => {
  switch (action.type) {
    case ActionTypes.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.notification,
      };
    case ActionTypes.SET_CONCILIATION_DATA:
      return {
        ...state,
        conciliationData: action.conciliationData!,
      };
    case ActionTypes.SET_CONCILIATION_TRX_DATA:
      return {
        ...state,
        conciliationData: action.conciliationData!,
      };
    case ActionTypes.SET_IS_UPLOADING_CONCILIATION:
      return {
        ...state,
        isUploadingConciliation: action.isUploadingConciliation,
      };
    case ActionTypes.SET_IS_UPLOADED_CONCILIATION:
      return {
        ...state,
        isUploadedConciliation: action.isUploadedConciliation,
      };
    case ActionTypes.SET_UPLOAD_CONCILIATION_ERROR:
      return {
        ...state,
        conciliationUploadError: action.conciliationUploadError,
      };
    case ActionTypes.SET_CONCILIATION_FILE_KEY:
      return {
        ...state,
        conciliationFileKey: action.conciliationFileKey,
      };
    case ActionTypes.SET_MODAL_CONCILIATION:
      return {
        ...state,
        modalConciliation: action.modalConciliation,
      };
    case ActionTypes.SET_MODAL_OMIT:
      return {
        ...state,
        modalOmit: action.modalOmit,
      };
    case ActionTypes.SET_MODAL_CONCILIATION_PROPS:
      return {
        ...state,
        modalProps: action.modalProps,
      };
    case ActionTypes.SET_ACTUAL_HEADERS:
      return {
        ...state,
        actualHeaders: action.actualHeaders!,
      };
    case ActionTypes.SET_LOAD_STATUS:
      return {
        ...state,
        loadStatus: action.loadStatus,
      };
    case ActionTypes.SET_LOADING_DOWNLOAD_PROCESS:
      return {
        ...state,
        loadingFileDownload: action.loadingFileDownload!,
      };
    case ActionTypes.SET_OPEN_MODAL_DETAIL:
      return {
        ...state,
        openModalDetail: action.openModalDetail!,
      };
    case ActionTypes.SET_INDEX_TAB_TRANSACTION_SUMMARY:
      return {
        ...state,
        indexTransactionSummaryTab: action.indexTransactionSummaryTab!,
      };
    case ActionTypes.SET_IS_LOADING_SUMMARY_DATA:
      return {
        ...state,
        isLoadingSummaryData: action.isLoadingSummaryData,
      };
    case ActionTypes.SET_IS_LOADING_TRANSACTION_DATA:
      return {
        ...state,
        isLoadingTransactionData: action.isLoadingTransactionData,
      };
    case ActionTypes.SET_LOADING_MASSIVE:
      return {
        ...state,
        loadingMassive: action.loadingMassive!,
      };
    default:
      return state;
  }
};
