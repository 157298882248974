import { Box } from "@mui/material";
import React, { FC, useState } from "react";
import IconSheet from "../../assets/icons/IconSheet";
import IconUpload from "../../assets/icons/IconUpload";
import { ROUTES } from "../../shared/constants/routes";
import { ChoiceValuesEnum } from "../../shared/enums/ChoiceValuesEnum";
import ChoiceButton from "../ChoiceButton/ChoiceButton";
import { ISelectMethod } from "./SelectMethod.interfaces";

const SelectMethod: FC<ISelectMethod> = ({ onClick }: ISelectMethod) => {
  const [choicesValues, setChoicesValues] = useState([
    {
      bodyText: ChoiceValuesEnum.INDIVIDUAL_BODY,
      checked: false,
      icon: <IconSheet />,
      title: ChoiceValuesEnum.INDIVIDUAL_TITLE,
      value: ROUTES.BRANCH_BATCH,
    },
    {
      bodyText: ChoiceValuesEnum.MULTIPLE_BODY,
      checked: false,
      icon: <IconUpload />,
      title: ChoiceValuesEnum.MULTIPLE_TITLE,
      value: ROUTES.BRANCH_UPLOAD_FILE,
    },
  ]);

  const handleChecked = (value: string) => {
    const tempChoices = [...choicesValues];

    tempChoices.forEach((item) => {
      item.checked = value === item.value;
    });

    setChoicesValues(tempChoices);
    onClick(value);
  };

  return (
    <Box sx={{ display: "flex" }}>
      {choicesValues.map((choice) => (
        <ChoiceButton
          {...choice}
          key={choice.value}
          onClick={() => handleChecked(choice.value)}
        />
      ))}
    </Box>
  );
};

export default SelectMethod;
