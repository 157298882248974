import { EntityNameEnum } from "../enums/EntityNameEnum";
import { TransactionEnum } from "../enums/TransactionEnum";

export interface ITextByEntityNameResponse {
  rangeDateText: string;
  searchText: string;
  tooltipSearchText: string;
}

export const PlaceholderFilterCatalogs = {
  rangeDateText: TransactionEnum.SEARCH_DATE_MESSAGE,
  searchText: TransactionEnum.SEARCH_MESSAGE,
  tooltipSearchText: TransactionEnum.SEARCH_MESSAGE,
};

export const CatalogTextByEntityName: Record<
  string,
  ITextByEntityNameResponse
> = {
  [EntityNameEnum.CUSTOMER]: {
    rangeDateText: TransactionEnum.SEARCH_DATE_MESSAGE,
    searchText: "Buscar por número de ticket",
    tooltipSearchText: "Buscar",
  },
  [EntityNameEnum.BRANCH]: {
    rangeDateText: TransactionEnum.SEARCH_DATE_MESSAGE,
    searchText: TransactionEnum.SEARCH_MESSAGE,
    tooltipSearchText: TransactionEnum.SEARCH_MESSAGE,
  },
  [EntityNameEnum.EMPTY]: {
    rangeDateText: TransactionEnum.SEARCH_DATE_MESSAGE,
    searchText: TransactionEnum.SEARCH_MESSAGE,
    tooltipSearchText: TransactionEnum.SEARCH_MESSAGE,
  },
};
