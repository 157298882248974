import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Inbox } from "react-feather";

export interface EmptyListProps {
  title: string;
  subtitle: string;
}

export const EmptyList: React.FC<EmptyListProps> = (props: EmptyListProps) => {
  return (
    <React.Fragment>
      <Grid container style={{ background: "#FFF" }}>
        <Grid
          style={{ height: 400 }}
          item
          xs={12}
          container
          alignItems="center"
          justify="center"
          direction="row"
        >
          <Grid container>
            <Grid item xs={12} md={8} alignItems="center" justify="center">
              <div>
                <Typography variant="h4">{props.subtitle}</Typography>
                <Typography variant="h6">{props.title}</Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <Inbox size={150} color="rgba(226,232,240,1)" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
