import React from "react";
import { useMaskedCardConditionState } from "./state/useMaskedCardConditionState";
import { Grid, Typography } from "@mui/material";
import MaskedCardField from "./MaskedCardField/MaskedCardField";
import { TextFieldCard } from "@kushki/connect-ui";
import {
  Labels,
  SubTitleSection,
} from "../../../../shared/infrastructure/constants/RuleRequestManagerConstants";
import { isOperatorEqualOrDifferent } from "../../../../utils/utilsFile";
import { GenericAddButton } from "../GenericAddButton/GenericAddButton";
import { isArray, isEmpty } from "lodash";

export interface IMaskedCardConditionProps {
  setSelectedItems: (value: string[]) => void;
  operator: string;
  defaultListCard: string[];
  isDisabled: boolean;
}

const MaskedCardCondition = (props: IMaskedCardConditionProps) => {
  const {
    cardList,
    handleAddCard,
    handleEditCard,
    handleDeleteCard,
    handleOnChangeCard,
    setValue,
    validCard,
    value,
  } = useMaskedCardConditionState(props);
  const isMaskedCard: boolean = true;

  return (
    <>
      {isArray(cardList) &&
        cardList.map((cardValue: string, index: number) => (
          <React.Fragment key={`maskedCardField-${cardValue}-${index}`}>
            <MaskedCardField
              onEdit={(value) =>
                isEmpty(value)
                  ? handleDeleteCard(cardValue.toString())
                  : handleEditCard(value, index)
              }
              onDelete={() => handleDeleteCard(cardValue.toString())}
              value={cardValue.toString()}
              isDisabled={props.isDisabled}
              isMaskedCard={isMaskedCard}
            />
          </React.Fragment>
        ))}

      {((isOperatorEqualOrDifferent(props.operator) && cardList.length < 1) ||
        !isOperatorEqualOrDifferent(props.operator)) &&
        !props.isDisabled &&
        !(
          cardList.length >= 1 && isOperatorEqualOrDifferent(props.operator)
        ) && (
          <Grid item xs={4}>
            <Typography variant={"h6"} sx={{ mb: 1 }}>
              {SubTitleSection.RULES_MASKED_CREDIT_CARD}
            </Typography>
            <TextFieldCard
              isDisabled={false}
              maskedCard="middle"
              onBlur={setValue}
              onChange={handleOnChangeCard}
              placeholder=""
              value={value}
              enableMaskedInput={isMaskedCard}
            />
          </Grid>
        )}
      {validCard && (
        <GenericAddButton
          handleOnClick={handleAddCard}
          buttonTitle={Labels.ADD_CARD}
        />
      )}
    </>
  );
};

export default MaskedCardCondition;
