import { IStyles } from "../../shared/utils";

export const basicContainerStyles: IStyles = {
  title: {
    paddingBottom: "16px",
  },
  componentSpace: {
    paddingBottom: "16px",
    paddingTop: "16px",
  },
  componentSpaceTitle: {
    paddingBottom: "32px",
    paddingTop: "32px",
  },
  flag: { paddingTop: "6px" },
  divider: {
    border: "1px solid #E2E8F0",
  },
  disablePicker: {
    pointerEvents: "none",
    backgroundColor: "transparent",
    input: {
      color: "#B4B9BE !important",
    },
    label: {
      color: "#B4B9BE !important",
    },
  },
  emptyPicker: {},
};
