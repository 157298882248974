import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const basicStateContainerStyles = createNamedStyles({
  box: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
  },
  boxContainer: {
    display: "flex",
    height: "100%",
    position: "fixed",
    width: 1,
  },
  boxContent: {
    height: "90%",
    marginBottom: "6em",
    padding: "48px 64px 48px 64px",
    width: "100%",
    borderRadius: "16px",
  },

  boxContentEdit: {
    height: "90%",
    marginBottom: "6em",
    padding: "48px 200px 48px 200px",
    width: "100%",
  },

  boxFooter: {
    bottom: "0",
    position: "fixed",
    width: "100%",
    zIndex: "1",
  },
  boxHeader: {
    bgcolor: {
      md: "#B6C5EE",
      sm: "lightskyblue",
    },
    display: "flex",
    flexDirection: {
      sm: "column",
    },
    heigth: {
      md: "10%",
      sm: "30%",
    },
    width: 1,
  },

  boxSidebar: {
    bgcolor: {
      md: "primary.light",
      sm: "secondary.main",
    },
    height: 1,
    width: {
      md: "10%",
      sm: "30%",
    },
  },
});

export const billingDataContainerStyles = createNamedStyles({
  errorMessage: {
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#AD0C2A !important",
    },
  },
});
