import { makeStyles } from "@material-ui/core/styles";

export const useNotificationsTableStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHeader: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    alignItems: "center",
    color: "#6D7781",
    alignSelf: "center",
  },
  paper: {
    backgroundColor: "#F7FAFC",
    paddingTop: "8px",
    marginTop: "22px",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  aliasCell: {
    fontSize: "14px",
    lineHeight: "140%",
    display: "flex",
    alignItems: "center",
    color: "#293036",
    order: 0,
    alignSelf: "center",
    flexGrow: 0,
  },
  aliasCellCenter: {
    fontSize: "14px",
    lineHeight: "140%",
    display: "flex",
    alignItems: "center",
    color: "#293036",
    order: 0,
    alignSelf: "center",
    flexGrow: 0,
    justifyContent: "center",
  },
  paymentMethodLabel: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "100%",
    color: "#6D7781",
    alignSelf: "center",
    flexGrow: 0,
  },
  urlLabel: {
    fontSize: "14px",
    lineHeight: "140%",
    display: "flex",
    alignItems: "center",
    color: "#6D7781",
    order: 1,
    alignSelf: "center",
    flexGrow: 1,
    overflowWrap: "break-word",
    wordWrap: "break-word",
  },
  timeLabel: {
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: "14px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  dateLabel: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "140%",
    display: "flex",
    alignItems: "center",
    color: "#293036",
  },
});
