import React from "react";
import { Filter } from "react-feather";
import { Badge, Button, createStyles, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CountryType } from "../../../shared/infrastructure/CountryType";
import { Filter as FilterKushki } from "@kushki/frontend-molecules/filter";
import { IFilterResponse } from "@kushki/frontend-molecules/filter-container";
import { IOptionFilter } from "../BoxFilter/BoxFilter";
import { FilterTypeEnum } from "../../../shared/infrastructure/FilterTypeEnum";
import { get, set } from "lodash";

const useStyles = makeStyles(() =>
  createStyles({
    filterButton: {
      height: 40,
      borderRadius: "4px",
      backgroundColor: "#FFFFFF",
      color: "#023365",
    },
    filterPopover: {
      "&.MuiPopover-root .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "2px solid #FFFFFF",
      },
    },
  })
);

export interface PopoverFilterProps {
  handleSetFilters: (filters: { [key: string]: boolean }) => void;
  filterChips: { [key: string]: boolean };
  counterFilter: number;
  country: CountryType;
  filters: IOptionFilter[];
}

export const PopoverFilter: React.FC<PopoverFilterProps> = (
  props: PopoverFilterProps
) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleFilterResponse = (response: IFilterResponse) => {
    props.handleSetFilters(mapFilterResponse(response.listFilter));
  };

  const mapFilterResponse = (filters: IOptionFilter[]) => {
    let result: { [key: string]: boolean } = {};
    filters.map((option) => {
      option.options.map((item) => {
        result[item.key] = item.selected;
      });
    });
    return result;
  };

  const handleClearFilters = () => {
    props.filters.map((filter) => {
      filter.options.map((option) => {
        get(filter, "id") === FilterTypeEnum.models &&
          set(option, "disabled", true);
        option.selected = false;
      });
    });
    const filters = mapFilterResponse(props.filters);
    props.handleSetFilters(filters);
  };

  return (
    <React.Fragment>
      <Button
        className={classes.filterButton}
        aria-describedby={id}
        size={"small"}
        startIcon={<Filter style={{ color: "#023365" }} size={14} />}
        variant="outlined"
        onClick={handleClick}
      >
        <Badge color="secondary" badgeContent={props.counterFilter}>
          <Typography variant="body2">Filtrar</Typography>
        </Badge>
      </Button>
      <FilterKushki
        idPopover={id}
        openPopover={open}
        isMobile={false}
        openModal={false}
        anchorPopover={anchorEl}
        handleClearFilters={handleClearFilters}
        className={classes.filterPopover}
        styles={{
          applyButtonStyle: "contained",
          applyButtonText: "Aplicar filtros",
          removeButtonText: "Restablecer",
        }}
        filters={{
          listFilters: {
            filters: props.filters,
            multiple: true,
            txtToolTip: `Para seleccionar este modelo necesitas
              activar el tipo de transacción “Pay - In”
            `,
          },
        }}
        handleApplyFilters={handleFilterResponse}
        handleClose={handleClose}
      />
    </React.Fragment>
  );
};
