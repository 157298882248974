import React from "react";
import { Badge, Button, createStyles } from "@material-ui/core";
import { Filter } from "react-feather";
import { Filter as FilterMolecules } from "@kushki/frontend-molecules/filter";
import { IFilterResponse } from "@kushki/frontend-molecules/filter-container";
import { makeStyles } from "@material-ui/core/styles";
import { IRangeAmount } from "@kushki/frontend-molecules/filter-range-amount";
import { FILTER_MAX_VALUE } from "../../../shared/infrastructure/constants/PayoutsListConstants";
import { IOptionFilter } from "../../../containers/PayoutsTransaction/state/usePayoutsTransactionState";

export interface FilterComponentProps {
  rangeFilter: IRangeAmount;
  filtersKind: IOptionFilter[];
  onApplyFilter: (response: IFilterResponse) => void;
  onClearFilter: () => void;
  filterCount: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    badgedFilter: {
      "& .MuiBadge-anchorOriginTopRightRectangle": {
        transform: "scale(1) translate(0%, -20%)",
      },
      "& .MuiBadge-colorSecondary": {
        color: "rgba(240, 244, 248, 1)",
        backgroundColor: "rgba(13,194,152,1)",
      },
    },
    buttonBadge: {
      marginRight: "8px",
      height: 40,
    },
  })
);

const FilterComponent = (props: FilterComponentProps): JSX.Element => {
  const classes = useStyles();
  const [openModal, setOpenModal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenModal(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenModal(false);
  };
  const handleApply = (response: IFilterResponse) => {
    props.onApplyFilter(response);
    handleClose();
  };
  const handleClear = () => {
    handleClose();
    props.onClearFilter();
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <Badge
        color="secondary"
        badgeContent={props.filterCount}
        className={classes.badgedFilter}
      >
        <Button
          className={classes.buttonBadge}
          variant="outlined"
          startIcon={<Filter size={16} />}
          onClick={handleClick}
        >
          Filtrar
        </Button>
      </Badge>
      <FilterMolecules
        idPopover={"simple-popover"}
        filters={{
          listFilters: {
            multiple: true,
            filters: [...props.filtersKind],
          },
          rangeFilter: {
            rangeAmount: props.rangeFilter,
            maxAmount: FILTER_MAX_VALUE,
          },
        }}
        openPopover={open}
        openModal={openModal}
        anchorPopover={anchorEl}
        handleApplyFilters={handleApply}
        handleClose={handleClose}
        handleClearFilters={handleClear}
        isMobile={false}
      />
    </React.Fragment>
  );
};

export default FilterComponent;
