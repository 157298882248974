import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { ICatalogsData } from "../../interfaces/catalogs/catalogs.interfaces";
import { Catalog } from "../../../../types/catalog_response";
import { getListCatalogs } from "../../thunks/catalogs/catalogs.thunk";

export const initialState: ICatalogsData = {
  isLoading: false,
  listCatalogs: [],
};

export const catalogsSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getListCatalogs.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getListCatalogs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getListCatalogs.fulfilled, (state, action) => {
        state.listCatalogs = action.payload;
        state.isLoading = false;
      });
  },
  initialState,
  name: "catalogs",
  reducers: {
    setIsLoading: (
      state: Draft<ICatalogsData>,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLoading = payload;
    },
    setListCatalogs: (
      state: Draft<ICatalogsData>,
      { payload }: PayloadAction<Catalog[]>
    ) => {
      state.listCatalogs = payload;
    },
  },
});

export default catalogsSlice.reducer;
