import { SnackBarProvider, theme } from "@kushki/connect-ui";
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { store } from "./store/store";
import Navigation from "./navigation.component";
const Root = () => {
  return (
    <React.StrictMode>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&display=swap');"
        }
      </style>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500&display=swap');"
        }
      </style>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <SnackBarProvider>
            <Navigation />
          </SnackBarProvider>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
};

export default Root;
