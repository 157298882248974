import React from "react";
import { FileUploadContainerStyles as styles } from "../FileUploadContainer.styles";
import { Box, IconButton, Typography } from "@mui/material";
import { IFileNameProps } from "../FileUploadContainer.interfaces";
import { IconCircleCross, IconFileText } from "@kushki/connect-ui";

const FileName: React.FC<IFileNameProps> = ({
  name,
  handleCloseFile,
}: IFileNameProps) => {
  return (
    <Box sx={styles.fileUploadContainer}>
      <Box sx={styles.nameContainer}>
        <Box sx={styles.name}>
          <Box sx={styles.iconFile}>
            <IconFileText />
          </Box>
          <Typography variant="h6" sx={styles.nameText}>{`${name}`}</Typography>
          <IconButton
            sx={styles.crossIcon}
            onClick={() => handleCloseFile(name)}
          >
            <IconCircleCross color={"primary"} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default FileName;
