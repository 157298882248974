import React from "react";
export const DevelopersImage = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18" cy="18" r="18" fill="#023365" />
      <path
        d="M25.9798 28H10.0202C8.90909 28 8 27.0576 8 25.9058V10.0942C8 8.94241 8.90909 8 10.0202 8H25.9798C27.0909 8 28 8.94241 28 10.0942V25.9058C28 27.0576 27.0909 28 25.9798 28ZM10.0202 9.04712C9.51515 9.04712 9.0101 9.57068 9.0101 10.0942V25.9058C9.0101 26.534 9.51515 26.9529 10.0202 26.9529H25.9798C26.5859 26.9529 26.9899 26.4293 26.9899 25.9058V10.0942C26.9899 9.46597 26.4848 9.04712 25.9798 9.04712H10.0202Z"
        fill="white"
      />
      <path d="M27.495 10.9319H8.50507V11.9791H27.495V10.9319Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8571 16.1497C17.0375 16.3294 17.0131 16.5999 16.8027 16.754L14.1789 18.6746C13.9453 18.8456 13.9453 19.1544 14.1789 19.3254L16.8027 21.246C17.0131 21.4001 17.0375 21.6706 16.8571 21.8503C16.6768 22.03 16.36 22.0509 16.1495 21.8968L13.5257 19.9762C12.8248 19.4631 12.8248 18.5369 13.5257 18.0238L16.1495 16.1032C16.36 15.9491 16.6768 15.97 16.8571 16.1497Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1429 16.1497C18.9625 16.3294 18.9869 16.5999 19.1973 16.754L21.8211 18.6746C22.0547 18.8456 22.0547 19.1544 21.8211 19.3254L19.1973 21.246C18.9869 21.4001 18.9625 21.6706 19.1429 21.8503C19.3232 22.03 19.64 22.0509 19.8505 21.8968L22.4743 19.9762C23.1752 19.4631 23.1752 18.5369 22.4743 18.0238L19.8505 16.1032C19.64 15.9491 19.3232 15.97 19.1429 16.1497Z"
        fill="white"
      />
    </svg>
  );
};
