import { KindEnum } from "../KindEnum";

export const EmptyObservationMessage = {
  [KindEnum.INVOICE]: "Esta factura no tiene referencias",
  [KindEnum.CREDIT_NOTE]: "Esta nota de Crédito no tiene referencias",
  [KindEnum.DEBIT_NOTE]: "Esta nota de Débito no tiene referencias",
  [KindEnum.CHARGE]: "Este cobro no tiene referencias",
  [KindEnum.RECEIVABLE]: "Esta cuenta por cobrar no tiene referencias",
  [KindEnum.DISPERSION]: "Esta dispersión no tiene referencias",
  [KindEnum.VOUCHER]: "Esta boleta no tiene referencias",
};

export const EmptyAdvanceConfigurationMessage = {
  [KindEnum.INVOICE]: "Esta factura no tiene Configuración Avanzada",
  [KindEnum.CREDIT_NOTE]:
    "Esta nota de Crédito no tiene Configuración Avanzada",
  [KindEnum.DEBIT_NOTE]: "Esta nota de Débito no tiene Configuración Avanzada",
  [KindEnum.CHARGE]: "Este cobro no tiene Configuración Avanzada",
  [KindEnum.RECEIVABLE]:
    "Esta cuenta por cobrar no tiene Configuración Avanzada",
  [KindEnum.DISPERSION]: "Esta dispersión no tiene Configuración Avanzada",
  [KindEnum.VOUCHER]: "Esta boleta no tiene Configuración Avanzada",
};
