export enum PlatformValueEnum {
  API = "API",
  KUSHKIJS = "KUSHKIJS",
  ANDROID = "ANDROID",
  IOS = "IOS",
  SMARTLINKS = "SMARTLINKS",
  KAJITA = "KAJITA",
  CAJITA = "CAJITA",
  VTEX = "VTEX",
  MAGENTO = "MAGENTO",
  PRESTASHOP = "PRESTASHOP",
  WOOCOMERCE = "WOOCOMERCE",
  SHOPIFY = "SHOPIFY",
  WEBCHECKOUT = "WEBCHECKOUT",
  WEBCHECKOUT_EXPRESS = "WEBCHECKOUT_EXPRESS",
}
