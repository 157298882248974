import React, { FC, PropsWithChildren } from "react";
import { Box, Grid } from "@mui/material";
import { FilterDetailBar } from "@kushki/connect-ui";
import { headerFiltersStyles as styles } from "./HeaderFilters.styles";
import { FilterSideBarMassive } from "../Filters/FilterSideBar/FilterSideBarMassive";
import { HeaderFiltersProps } from "./HeaderFilters.interfaces";
import { useHeaderFiltersState } from "./state/useHeaderFiltersState";
import SearchText from "../SearchText/SearchText";
import { isBackofficeMasterCustomer } from "../../shared/utils/getRolesUtils";

export const HeaderFilters: FC<HeaderFiltersProps> = (
  props: PropsWithChildren<HeaderFiltersProps>
) => {
  const {
    filterSideBarProps,
    itemsDetailBar,
    onChangeFiltersDetailBar,
    onClickFilterSideBar,
  } = useHeaderFiltersState(props);

  return (
    <Box>
      <Grid container>
        <Grid item container xs={12}>
          <SearchText
            handleFilterMerchants={props.handleOnFilterMerchants}
            handleResetPage={props.handleResetPage}
          />
          <Grid item xs={2} sx={styles.gridFilter}>
            <Box mt={2} mb={2}>
              {!isBackofficeMasterCustomer() && (
                <FilterSideBarMassive
                  data-testid={"filterBar"}
                  {...filterSideBarProps}
                  categoryItems={filterSideBarProps.categoryItems}
                  onClick={onClickFilterSideBar}
                  resetFilters={props.resetFilters}
                />
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={styles.gridFilterCard}>
          <FilterDetailBar
            filters={itemsDetailBar}
            onChangeFilters={onChangeFiltersDetailBar}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
