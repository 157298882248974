import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";
import React, { useEffect, useMemo, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { CommercialExecutiveList } from "../../../../types/commercial_executive_list";
import { ContactData, ContactInfoBody } from "../../../../types/contact_data";
import { ContactDataRequest } from "../../../../types/contact_data_request";
import { Configs } from "../../../../types/get_node_info_response";
import { MerchantData } from "../../../../types/merchant-data";
import { MerchantComplianceData } from "../../../../types/merchant_compliance_data";
import {
  IButtonOptions,
  ILabel,
} from "../../../components/FooterOptions/FooterOptions.interfaces";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { VARIABLE_ARRAY_CONTACT_DATA } from "../../../shared/constants/array_contact_data";
import { ContactAreaEnum } from "../../../shared/constants/contact_area";
import { ConfigIdEnum } from "../../../shared/constants/initial_state_legal_details";
import { DynamoName } from "../../../shared/enum/ContactDataStateMX/MerchantCompanyInfo";
import { ActionFooterEnum } from "../../../shared/enums/ActionFooterEnum";
import { FieldsEnum } from "../../../shared/enums/FieldsEnum";
import { getContactDataInitialValue } from "../../../shared/utils/contact_data_utils";
import {
  contactDataStore,
  setDisableAddPersonBtn,
  setIsLoadingContactData,
  setLoaderModalOpen,
  setNotificationCD,
} from "../../../store/actions/contactData.actions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { RootState } from "../../../store/store";
import getCommercialExecutives from "../../../store/thunks/commercialExecutiveList/commercialExecutiveList.thunks";
import {
  saveContactDataMerchant,
  updateMerchanteInterface,
} from "../../../store/thunks/contactData/contactData.thunks";
import { getMerchantInfo } from "../../../store/thunks/general/general.thunk";
import {
  getMerchantComplianceData,
  postMerchantComplianceData,
} from "../../../store/thunks/merchantComplianceData/merchantComplianceData.thunks";

import { defaultTo, get, isEmpty } from "lodash";
import { ContactDataEditDelete } from "../../../components/ContactDataAreasComponent/ContactDataAreas.interfaces";
import { useMerchantCompanyInfo } from "../../../shared/hooks/merchantCompanyInfo/useMerchantCompanyInfo";
import { TabItemStatusEnum } from "../../../shared/enum/tabItemStatusEnum";
import { format } from "date-fns";
import { useSnackbar } from "@kushki/connect-ui";
import { EntityNames } from "../../../shared/enum/ContactDataStateMX/ContactDataLabelMX";
import { IContactDataStateGeneral } from "../../../containers/ContactDataContainer/state/useContactDataContainer.interfaces";
import { searchParams } from "../useQuery_utils";
import { MERCHANT_VALUE } from "../../enum/ContactDataTextsEnum";

export const useContactDataContainerGeneral = (
  isMassive: boolean,
  isContainer?: boolean
): IContactDataStateGeneral => {
  let addContactData: ContactData = {};
  const { showSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const query = searchParams();
  const publicMerchantId: string = query.publicMerchantId;
  const isEdit: boolean = query.isEdit;
  const [openModal, setOpenModal] = useState(false);
  const [saveLoader, setsaveLoader] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isContinueDisabled, setIsContinueDisabled] = useState(true);
  const [isCentralizedBranch, setIsCentralizedBranch] = useState(false);
  const [isDataCompleted, setIsDataCompleted] = useState(false);
  const [isDataNoErrors, setIsDataNoErrors] = useState(true);
  const [isContinueAction, setIsContinueAction] = useState(false);
  const [isAreasSelected, setIsAreasSelected] = useState(true);
  const [commercialExecutive, setCommercialExecutive] = useState("");
  const [commercialExecutiveEmail, setCommercialExecutiveEmail] = useState("");
  const [merchantSize, setMerchantSize] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactAreas, setContactAreas] = useState<string[]>([]);
  const [isNameValid, setIsNameValid] = useState(true);
  const [executiveList, setExecutiveList] = useState<CommercialExecutiveList[]>(
    []
  );
  const [categoryAreas, setCategoryAreas] = useState(ContactAreaEnum);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [valuesItem, setValuesItem] = useState<object>({});

  const { commercialExecutiveList } = useAppSelector(
    (state: RootState) => state.commercialExecutiveList
  );
  const { merchantComplianceData, isUpdate } = useAppSelector(
    (state: RootState) => state.merchantComplianceData
  );
  const {
    nodeInfo: nodeSingle,
    configsInfo,
    nodeInfoMassiveContact,
  } = useAppSelector((state: RootState) => state.initialData);

  const nodeInfo = isMassive ? nodeInfoMassiveContact : nodeSingle;
  const {
    contactData,
    loaderModalOpen,
    notification,
    isSaved,
    merchantInfo,
    isDisableAddPersonBtn,
    isLoadingContactData,
  } = useAppSelector((state: RootState) => state.contactData);

  let form: UseFormReturn<ContactDataRequest> = useForm<ContactDataRequest>({
    defaultValues: {
      ...getContactDataInitialValue({}),
    },
    mode: "onChange",
    reValidateMode: "onBlur",
  });

  const contactDataConfig: Configs = get(configsInfo, "cn002");

  function addDefaultCommercialExecutive() {
    const defaultData: CommercialExecutiveList = {
      commercialEmail: "soporte@kushkipagos.com",
      commercialName: "SOPORTE",
      id: "support",
    };

    const newCommercialExecutive: CommercialExecutiveList[] = [
      ...commercialExecutiveList,
    ];

    newCommercialExecutive.push(defaultData);

    setExecutiveList(newCommercialExecutive);
  }

  const handleSaveData = async () => {
    const stateDataContact: ContactData = {
      ...contactData,
      categoryMerchant: merchantSize,
      merchantRank: merchantSize,
      merchantSize: merchantSize,
    };

    await dispatch(contactDataStore(stateDataContact));
  };

  const handleIsDataCompleted = (isDataCompleted: boolean) =>
    setIsDataCompleted(isDataCompleted);
  const handleIsDataNoErrors = (isDataNoErrors: boolean) =>
    setIsDataNoErrors(isDataNoErrors);
  const handleIsNameValid = (isNameValid: boolean) =>
    setIsNameValid(isNameValid);
  const handleOpenModal = (isOpen: boolean) => {
    form.reset({
      ...getContactDataInitialValue({ commercialExecutive, merchantSize }),
    });
    setOpenModal(isOpen);
    handleIsDataCompleted(true);
  };
  const handleEditContact = (isEditing: boolean) => setIsEditing(isEditing);

  const handleCancelModal = (isOpen: boolean) => {
    setIsDataCompleted(true);
    setIsAreasSelected(true);
    setIsNameValid(true);
    setOpenModal(isOpen);
    setContactAreas([]);
    form.reset({
      ...getContactDataInitialValue({ commercialExecutive, merchantSize }),
    });
  };

  const handleSaveContactData = (addContactDataObject: ContactData) => {
    let stateDataContact: ContactData = { ...contactData };

    for (let i in VARIABLE_ARRAY_CONTACT_DATA) {
      if (addContactDataObject[VARIABLE_ARRAY_CONTACT_DATA[i]]) {
        stateDataContact[VARIABLE_ARRAY_CONTACT_DATA[i]] = [
          ...addContactDataObject[VARIABLE_ARRAY_CONTACT_DATA[i]],
          ...stateDataContact[VARIABLE_ARRAY_CONTACT_DATA[i]],
        ];
      }
    }
    dispatch(contactDataStore(stateDataContact));
    handleCancelModal(false);
  };

  const onSaveContact = () => {
    if (isDataNoErrors) {
      for (let i in contactAreas) {
        addContactData = {
          ...addContactData,
          [contactAreas[i]]: [
            {
              email,
              name: name,
            },
          ],
        };
      }
      handleSaveContactData(addContactData);
    }
  };

  const onHandleEditData = (
    categoryAreasDefault: ICategory[],
    validateName: boolean
  ) => {
    const index: number = contactData[get(valuesItem, "area", "")].findIndex(
      (element: ContactInfoBody) =>
        element.name === get(valuesItem, "item.name", "")
    );

    let dataContact: ContactData = {};
    const itemRemoved: ContactData = contactData[
      get(valuesItem, "area", "")
    ].filter(
      (value: ContactInfoBody) =>
        value.name !== get(valuesItem, "item.name", "")
    );

    if (get(valuesItem, "item.name", "") != form.getValues(FieldsEnum.name)!) {
      dataContact = {
        ...contactData,
        [get(valuesItem, "area", "")]: itemRemoved,
      };

      categoryAreasDefault.map((type) => {
        contactData[type.value].map((contact: ContactInfoBody) => {
          if (contact.name === form.getValues(FieldsEnum.name)!)
            validateName = false;
        });
      });
    } else {
      dataContact = {
        ...contactData,
        [get(valuesItem, "area", "")]: itemRemoved,
      };

      validateName = true;
    }

    if (validateName) {
      dataContact[get(valuesItem, "area", "")].splice(index, 0, {
        email: form.getValues(FieldsEnum.email)!,
        name: form.getValues(FieldsEnum.name)!,
      });

      dispatch(contactDataStore(dataContact));
      handleCancelModal(false);
    }
    setIsEditing(!validateName);
    handleIsNameValid(validateName);
  };

  const getError = (value: string): boolean => {
    return !!defaultTo(get(form, "formState.errors"), {})[value];
  };

  const onSubmit = async () => {
    let validateName: boolean = true;
    let validateDataCompleted: boolean = true;
    const categoryAreasDefault: ICategory[] = ContactAreaEnum;

    await form.trigger();
    form.clearErrors(FieldsEnum.merchantSize);
    form.clearErrors(FieldsEnum.commercialExecutive);

    if (contactAreas.length === 0) {
      validateDataCompleted = false;
      setIsAreasSelected(false);
    } else {
      setIsAreasSelected(true);
    }

    if (!getError(FieldsEnum.email) && !getError(FieldsEnum.name)) {
      if (validateDataCompleted) {
        if (isEditing) {
          onHandleEditData(categoryAreasDefault, validateName);
        } else {
          categoryAreasDefault.map((type) => {
            contactData[type.value].map((contact: ContactInfoBody) => {
              if (contact.name === name && contactAreas.includes(type.value))
                validateName = false;
            });
          });
          if (validateName) {
            onSaveContact();
          }
          handleIsNameValid(validateName);
          setIsEditing(false);
        }
      }
      handleIsDataCompleted(validateDataCompleted);
    }
  };

  const handleFormChange = (
    name: string,
    value: string,
    selectedItems?: ICategory[]
  ): void => {
    switch (name) {
      case DynamoName.COMMERCIAL_EXECUTIVE:
        const getEmails: object[] = executiveList.filter(
          (item) => item.id == value
        );

        setCommercialExecutive(value);
        setCommercialExecutiveEmail(get(getEmails, "[0].commercialEmail"));
        break;
      case DynamoName.MERCHANT_SIZE:
        setMerchantSize(value);
        break;
      case DynamoName.NAME:
        setName(value);
        handleIsNameValid(true);
        break;
      case DynamoName.EMAIL:
        setEmail(value);
        break;
      case DynamoName.CONTACT_AREAS:
        const items: string[] = [];

        for (let i in selectedItems) {
          items.push(selectedItems[i].value);
        }
        setContactAreas(items);
        break;
    }
  };
  const { countriesInfo, headerProps, handleChangeStatusNodeInfo } =
    useMerchantCompanyInfo(isMassive);

  const saveContactDataDB = async (status: TabItemStatusEnum) => {
    const getEmails: object[] = executiveList.filter(
      (item) => item.id == commercialExecutive
    );

    const new_merchant_compliance: MerchantComplianceData = {
      categoryMerchant: merchantSize,
      commercialExecutive: get(getEmails, "[0].commercialName", ""),
      commercialExecutiveEmail: get(
        getEmails,
        "[0].commercialEmail",
        commercialExecutiveEmail
      ),
      constitutionalCountry: countriesInfo.constitutionalCountry,
      country: countriesInfo.country,
      kind: get(
        merchantComplianceData,
        "merchantComplianceData.kind",
        MERCHANT_VALUE
      ),
      merchantName: get(
        merchantComplianceData,
        "merchantComplianceData.merchantName",
        get(nodeSingle, "generalInfo.name")
      ),
      publicMerchantId,
      status: get(
        merchantComplianceData,
        "merchantComplianceData.status",
        get(merchantInfo, "status")
      ),
    };

    await dispatch(postMerchantComplianceData(new_merchant_compliance));

    const general_contact_data = {
      ...contactData,
      contactCommercial: {
        email: get(contactData, "contactCommercialArea[0].email"),
        name: get(contactData, "contactCommercialArea[0].name"),
      },
      contactPerson: get(contactData, "contactBillingArea[0].name", ""),
      contactTechnical: {
        email: get(contactData, "contactTechnicalArea[0].email"),
        name: get(contactData, "contactTechnicalArea[0].name"),
      },
      email: get(contactData, "contactBillingArea[0].email"),
    };
    const update_data: Partial<MerchantData> = {
      ...general_contact_data,
      categoryMerchant: merchantSize,
      configId: ConfigIdEnum.CN002,
      constitutionalCountry: countriesInfo.constitutionalCountry,
      country: countriesInfo.country,
      merchantRank: merchantSize,
      merchantSize: merchantSize,
      publicMerchantId,
    };

    const objectToSave: updateMerchanteInterface = {
      entityName: get(nodeInfo, "entityName", ""),
      nodeId: get(nodeInfo, "nodeId", ""),
      nodeInfo,
      objectToSave: update_data,
      publicMerchantId: publicMerchantId,
      status: status,
    };

    await dispatch(saveContactDataMerchant(objectToSave));
  };

  const handleSaveLoader = (isOpen: boolean) => setsaveLoader(isOpen);

  const handleIsContinueAction = (isContinueAction: boolean) => {
    setIsContinueAction(isContinueAction);
  };

  const saveValues = async (typeButton: ActionFooterEnum) => {
    await handleSaveData();

    switch (typeButton) {
      case ActionFooterEnum.SAVE:
        handleIsContinueAction(false);
        const status: TabItemStatusEnum = !isContinueDisabled
          ? TabItemStatusEnum.COMPLETE
          : TabItemStatusEnum.IN_PROCESS;

        dispatch(setLoaderModalOpen(true));
        handleSaveLoader(true);
        await saveContactDataDB(status);

        handleChangeStatusNodeInfo(
          status === TabItemStatusEnum.IN_PROCESS
            ? TabItemStatusEnum.PENDING
            : status,
          ConfigIdEnum.CN002,
          publicMerchantId
        );
        break;
      case ActionFooterEnum.CONTINUE:
        dispatch(setLoaderModalOpen(true));
        await saveContactDataDB(TabItemStatusEnum.COMPLETE);

        handleChangeStatusNodeInfo(
          TabItemStatusEnum.COMPLETE,
          ConfigIdEnum.CN002,
          publicMerchantId
        );
        handleIsContinueAction(true);
        break;
    }
  };

  const primaryButton: IButtonOptions = {
    isDisabled: isContinueDisabled || isCentralizedBranch,
    isHidden: true,
    onAction: () => saveValues(ActionFooterEnum.CONTINUE),
  };
  const secondaryButton: IButtonOptions = {
    isDisabled:
      saveLoader ||
      isSaveDisabled ||
      (isCentralizedBranch && isEdit) ||
      isCentralizedBranch,
    isHidden: true,
    onAction: () => saveValues(ActionFooterEnum.SAVE),
  };
  const footerLabel: ILabel = {
    date: format(
      get(configsInfo, `${ConfigIdEnum.CN002}.updatedAt`, 0),
      "dd/MM/yyyy '-' HH:mm"
    ),
    editDetail: false,
    isHidden: true,
    text:
      "Modificado por " +
      get(configsInfo, `${ConfigIdEnum.CN002}.updatedBy`, ""),
  };

  const handlerOnCancelEdit = () => {
    setIsEditing(false);
    handleCancelModal(false);
  };

  const removeDuplicates = (elements: string[]): string[] => {
    return elements.filter((c: string, index: number) => {
      return elements.indexOf(c) === index;
    });
  };

  const openPopover = Boolean(anchorEl);

  const findSelectedItem = (
    info: ContactDataEditDelete
  ): {
    data: ContactData;
    area: string;
    item: ContactInfoBody;
    areasInterest: string[];
  } => {
    const { data, area, item } = info;
    let areasInterest: string[] = [];

    Object.keys(contactData).map((aria: string) => {
      if (VARIABLE_ARRAY_CONTACT_DATA.includes(aria)) {
        contactData[aria].filter((element: ContactInfoBody) => {
          return element.name === item.name && areasInterest.push(aria);
        });
      }
    });

    areasInterest = removeDuplicates(areasInterest);

    return { area, areasInterest, data, item };
  };

  const handleOpenEdit = () => {
    form.reset({
      ...getContactDataInitialValue({
        commercialExecutive,
        email: get(valuesItem, "item.email", ""),
        merchantSize,
        name: get(valuesItem, "item.name", ""),
      }),
    });

    setIsEditing(true);
    const areasSelectedItem: string[] = [get(valuesItem, "area", "")];

    categoryAreas.map((item: ICategory) => {
      areasSelectedItem.includes(item.value)
        ? (item.selected = true)
        : (item.selected = false);
    });

    setContactAreas(areasSelectedItem);
    setOpenModal(true);
  };

  const handleOpenModalDelete = (isOpen: boolean) => {
    setOpenModalDelete(isOpen);
  };
  const handleSetValuesItem = (values: ContactDataEditDelete) => {
    setValuesItem(values);
  };
  const handleOpenPopover = (
    event: React.MouseEvent<HTMLButtonElement>,
    info: ContactDataEditDelete
  ) => {
    setAnchorEl(event.currentTarget);
    handleSetValuesItem(findSelectedItem(info));
  };

  const handleClosePopover = () => setAnchorEl(null);
  const handlerDeleteContactPerson = () => {
    setIsEditing(false);
    const itemRemoved: ContactData = contactData[
      get(valuesItem, "area", "")
    ].filter(
      (value: ContactInfoBody) =>
        value.name !== get(valuesItem, "item.name", "")
    );

    dispatch(
      contactDataStore({
        ...contactData,
        [get(valuesItem, "area", "")]: itemRemoved,
      })
    );
  };

  const defaultValuesForm = (
    merchantComplianceDataObj: MerchantComplianceData,
    getEmails: object[]
  ) => {
    return {
      commercialExecutive: get(getEmails, "[0].id", ""),
      merchantSize: get(merchantComplianceDataObj, "categoryMerchant", ""),
    };
  };

  const verififyContactInfoSections = (contactData: ContactData) => {
    const sectionsLength = VARIABLE_ARRAY_CONTACT_DATA.map(
      (item) => contactData[item].length
    );

    return sectionsLength.every((item) => item === 3);
  };

  const isComplete: boolean = useMemo(
    () => contactDataConfig.status === TabItemStatusEnum.COMPLETE,
    [contactDataConfig.status]
  );

  const isEmptyConfig = (arrayConfig: Configs[]) => {
    const getConfig: Configs = arrayConfig.filter(
      (item) => item.configuration === ConfigIdEnum.CN002
    );

    if (isEmpty(getConfig.value))
      setTimeout(() => {
        dispatch(setIsLoadingContactData(false));
      }, 300);
  };

  useEffect(() => {
    dispatch(setIsLoadingContactData(true));
    if (isMassive) return;
    dispatch(getCommercialExecutives());
  }, []);

  useEffect(() => {
    if (notification && isContainer) {
      showSnackbar(notification);
      dispatch(setNotificationCD(undefined));
    }
  }, [notification]);

  useEffect(() => {
    if (!openModal) {
      form.clearErrors(FieldsEnum.name);
      form.clearErrors(FieldsEnum.email);
    }
  }, [openModal]);

  useEffect(() => {
    addDefaultCommercialExecutive();
  }, [commercialExecutiveList]);

  useEffect(() => {
    const entityName: string = get(nodeInfo, "entityName", "");
    const contactDataConfig: Configs | undefined = get(
      nodeInfo,
      "configs",
      []
    ).find((item) => item.configuration == ConfigIdEnum.CN002 && item.value);

    const isCentralizedValidations: boolean =
      entityName === EntityNames.BRANCH &&
      !isEmpty(get(contactDataConfig, "centralizedNodesId", ""));

    setIsCentralizedBranch(isCentralizedValidations);

    if (contactDataConfig) {
      const configValue: string = get(contactDataConfig, "value", "");

      dispatch(
        getMerchantInfo({
          configId: ConfigIdEnum.CN002,
          publicMerchantId: configValue,
        })
      );
      dispatch(
        getMerchantComplianceData({
          publicMerchantId: configValue,
        })
      );
    }
    if (nodeInfo.configs) isEmptyConfig(nodeInfo.configs);
  }, [nodeInfo]);

  useEffect(() => {
    const getEmails: object[] = executiveList.filter(
      (item) =>
        item.commercialName ==
          get(merchantComplianceData, "commercialExecutive") &&
        item.commercialEmail ==
          get(merchantComplianceData, "commercialExecutiveEmail")
    );

    handleFormChange(
      DynamoName.COMMERCIAL_EXECUTIVE,
      get(getEmails, "[0].id", "")
    );

    form.reset({ ...defaultValuesForm(merchantComplianceData, getEmails) });
  }, [executiveList]);

  useEffect(() => {
    const merchantComplianceDataObj: MerchantComplianceData = {
      ...merchantComplianceData,
    };

    const getEmails: object[] = executiveList.filter(
      (item) =>
        item.commercialName ==
          get(merchantComplianceDataObj, "commercialExecutive") &&
        item.commercialEmail ==
          get(merchantComplianceDataObj, "commercialExecutiveEmail")
    );

    if (!isEmpty(get(getEmails, "[0].id", ""))) {
      form.clearErrors("commercialExecutive");
      setCommercialExecutive(get(getEmails, "[0].id", ""));
    }
    if (!isEmpty(get(merchantComplianceDataObj, "categoryMerchant", ""))) {
      form.clearErrors("merchantSize");
      setMerchantSize(get(merchantComplianceDataObj, "categoryMerchant", ""));
    }

    form.reset({ ...defaultValuesForm(merchantComplianceDataObj, getEmails) });
  }, [merchantComplianceData]);

  useEffect(() => {
    let addInitialContactData: ContactData = {};

    for (let i in VARIABLE_ARRAY_CONTACT_DATA) {
      if (merchantInfo[VARIABLE_ARRAY_CONTACT_DATA[i]]) {
        addInitialContactData = {
          ...addInitialContactData,
          [VARIABLE_ARRAY_CONTACT_DATA[i]]:
            merchantInfo[VARIABLE_ARRAY_CONTACT_DATA[i]],
        };
      }
    }

    if (Object.keys(addInitialContactData).length !== 0) {
      dispatch(contactDataStore(addInitialContactData));
    }
  }, [merchantInfo]);

  useEffect(() => {
    if (isMassive) return;
    if (isUpdate && isSaved && isContinueAction) {
      window.location.href =
        API_ROUTES.FINANCIAL_CONFIGURATION(publicMerchantId);
    }
  }, [isUpdate, isSaved, isContinueAction]);

  useEffect(() => {
    if (saveLoader) {
      setTimeout(() => {
        handleSaveLoader(false);
      }, 3000);
    }
  }, [saveLoader]);

  useEffect(() => {
    let new_category_area: Array<ICategory> = ContactAreaEnum;

    if (!isEditing) {
      for (let i in VARIABLE_ARRAY_CONTACT_DATA) {
        if (contactData[VARIABLE_ARRAY_CONTACT_DATA[i]].length === 3) {
          new_category_area = new_category_area.filter((data) => {
            return data.value !== VARIABLE_ARRAY_CONTACT_DATA[i];
          });
        }
        setCategoryAreas(new_category_area);
      }

      categoryAreas.map((item: ICategory) => {
        item.selected = false;
      });
    } else {
      const areasSelectedItem: string[] = [get(valuesItem, "area", "")];

      new_category_area.map((item: ICategory) => {
        areasSelectedItem.includes(item.value)
          ? (item.selected = true)
          : (item.selected = false);
      });
      setCategoryAreas(new_category_area);
    }
  }, [openModal]);

  useEffect(() => {
    let stateDataContact: ContactData = { ...contactData };
    let hasEmptyContactData: boolean = false;

    form.watch(FieldsEnum.merchantSize);
    form.watch(FieldsEnum.commercialExecutive);

    for (let i in VARIABLE_ARRAY_CONTACT_DATA) {
      if (stateDataContact[VARIABLE_ARRAY_CONTACT_DATA[i]].length === 0) {
        hasEmptyContactData = true;
        break;
      }
    }

    if (
      !isEmpty(commercialExecutive) ||
      !isEmpty(merchantSize) ||
      !hasEmptyContactData
    ) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
    if (
      !isEmpty(commercialExecutive) &&
      !isEmpty(merchantSize) &&
      !hasEmptyContactData
    ) {
      setIsContinueDisabled(false);
    } else {
      setIsContinueDisabled(true);
    }
  }, [contactData, commercialExecutive, merchantSize]);

  useEffect(() => {
    if (isComplete) setIsSaveDisabled(isContinueDisabled);
  }, [isContinueDisabled]);

  useEffect(() => {
    if (verififyContactInfoSections(contactData)) {
      dispatch(setDisableAddPersonBtn(true));
    } else {
      dispatch(setDisableAddPersonBtn(false));
    }
  }, [contactData]);

  useEffect(() => {
    handleFormChange(DynamoName.EMAIL, form.getValues(FieldsEnum.email)!);
  }, [form.watch(FieldsEnum.email)]);

  useEffect(() => {
    handleFormChange(DynamoName.NAME, form.getValues(FieldsEnum.name)!);
  }, [form.watch(FieldsEnum.name)]);

  useEffect(() => {
    if (form.getValues(FieldsEnum.merchantSize)) {
      handleFormChange(
        DynamoName.MERCHANT_SIZE,
        form.getValues(FieldsEnum.merchantSize)!
      );
    }
  }, [form.getValues(FieldsEnum.merchantSize)]);

  useEffect(() => {
    if (form.getValues(FieldsEnum.commercialExecutive)) {
      handleFormChange(
        DynamoName.COMMERCIAL_EXECUTIVE,
        form.getValues(FieldsEnum.commercialExecutive)!
      );
    }
  }, [form.getValues(FieldsEnum.commercialExecutive)]);

  return {
    anchorEl,
    categoryAreas,
    commercialExecutive,
    commercialExecutiveEmail,
    commercialExecutiveList,
    configsInfo,
    contactAreas,
    contactDataAreasProps: {
      anchorEl,
      handleClosePopover,
      handleOpenEdit,
      handleOpenModalDelete,
      handleOpenPopover,
      handlerDeleteContactPerson,
      handlerOnCancelEdit,
      handleSetValuesItem,
      openModalDelete,
      openPopover,
      valuesItem,
    },
    contactDataModal: {
      email,
      name,
    },
    countriesInfo,
    dataCompleted: {
      handleIsDataCompleted,
      isDataCompleted,
    },
    dataNoErrors: {
      handleIsDataNoErrors,
      isDataNoErrors,
    },
    email,
    executiveList,
    footerActions: {
      footerLabel: !isEmpty(get(configsInfo, `${ConfigIdEnum.CN002}.updatedBy`))
        ? footerLabel
        : {
            date: "",
            editDetail: false,
            isHidden: true,
            text: "",
          },
      handleSaveLoader,
      primaryButton,
      saveLoader,
      secondaryButton,
    },
    form,
    handleCancelModal,
    handleChangeStatusNodeInfo,
    handleClosePopover,
    handleEditContact,
    handleFormChange,
    handleIsContinueAction,
    handleIsDataCompleted,
    handleIsDataNoErrors,
    handleIsNameValid,
    handleOpenEdit,
    handleOpenModal,
    handleOpenModalDelete,
    handleOpenPopover,
    handlerDeleteContactPerson,
    handleSaveData,
    handleSaveLoader,
    handleSetValuesItem,
    headerProps,
    isAreasSelected,
    isCentralizedBranch,
    isContinueAction,
    isContinueDisabled,
    isDataCompleted,
    isDataNoErrors,
    isDisableAddPersonBtn,
    isEdit,
    isEditing,
    isLoadingContactData,
    isNameValid,
    isSaveDisabled,
    isUpdate,
    merchantComplianceData,
    merchantSize,
    name,
    nameValidation: {
      handleIsNameValid,
      isNameValid,
    },
    onSubmit,
    openModal,
    openModalDelete,
    openModalLoader: loaderModalOpen,
    saveLoader,
    setExecutiveList,
    setIsEditing,
    valuesItem,
  };
};
