import { PersonTypeCOEnum } from "./PersonTypeCoEnum";
import {
  BasicDataLabelsCO,
  ErrorMessageCO,
  TypeElement,
} from "./ErrorMessagesCO";
import { ErrorMessageMX, RegularExp } from "../BasicStateMX/ErrorMessageMX";
import { FieldsNamesRender } from "../FieldsEnumRenderEnum";
import { WEB_SITE_REGEX } from "../../constants/billing_form_constants";

export interface IPersonTypeInputCO {
  filter: string;
  label: string;
  name: string;
  regExp?: string;
  rules?: object;
  type: string;
  value: number;
  typeArray?: string;
}

export interface IPersonTypeCategoryCO {
  name: string;
  value: string;
}

export const PersonTypeColist: Array<IPersonTypeCategoryCO> = [
  { name: "Jurídica", value: "02" },
  { name: "Natural", value: "01" },
];

export const fieldsRenderCO: Array<IPersonTypeInputCO> = [
  {
    filter: PersonTypeCOEnum.LEGAL,
    label: BasicDataLabelsCO.RAZON_SOCIAL,
    name: FieldsNamesRender.social_reason,
    regExp: RegularExp.CAPITAL_LETTER_NUMBERS,
    rules: {
      maxLength: {
        message: ErrorMessageCO.MAX_1_50_CHAR,
        value: 50,
      },
      minLength: {
        message: ErrorMessageCO.MAX_1_50_CHAR,
        value: 1,
      },
      required: {
        message: ErrorMessageCO.PF_RAZON_SOCIAL,
        value: true,
      },
    },
    type: TypeElement.TEXT_CAPITAL,
    value: 6,
  },
  {
    filter: PersonTypeCOEnum.LEGAL,
    label: BasicDataLabelsCO.NIT,
    name: FieldsNamesRender.rfc,
    regExp: RegularExp.ONLY_NUMBERS,
    rules: {
      maxLength: {
        message: ErrorMessageCO.LIMIT_NIT,
        value: 10,
      },
      minLength: {
        message: ErrorMessageCO.LIMIT_NIT,
        value: 10,
      },
      required: {
        message: ErrorMessageCO.PF_NUM_ID,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 6,
  },
  {
    filter: PersonTypeCOEnum.LEGAL,
    label: BasicDataLabelsCO.TIPO_EMPRESA,
    name: FieldsNamesRender.type_company,
    rules: {
      required: {
        message: ErrorMessageMX.PF_COMPANY,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    value: 6,
  },
  {
    filter: PersonTypeCOEnum.LEGAL,
    label: BasicDataLabelsCO.TIPO_INDUSTRIA,
    name: FieldsNamesRender.type_industry,
    rules: {
      required: {
        message: ErrorMessageMX.PF_INDUSTRIA,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    value: 6,
  },
  {
    filter: PersonTypeCOEnum.LEGAL,
    label: BasicDataLabelsCO.MCC,
    name: FieldsNamesRender.mcc,
    rules: {
      required: {
        message: ErrorMessageCO.PF_MCC,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    typeArray: TypeElement.COMBO_ARRAY,
    value: 6,
  },
  {
    filter: PersonTypeCOEnum.LEGAL,
    label: BasicDataLabelsCO.FECHA_CONST,
    name: FieldsNamesRender.date,
    type: TypeElement.DATE_PICKER,
    value: 6,
  },
  {
    filter: PersonTypeCOEnum.LEGAL,
    label: BasicDataLabelsCO.WEB,
    name: FieldsNamesRender.web,
    regExp: RegularExp.WEB,
    rules: {
      pattern: {
        message: ErrorMessageMX.REG_EXP_WEB,
        value: WEB_SITE_REGEX,
      },
      required: {
        message: ErrorMessageCO.PF_WEB,
        value: true,
      },
    },
    type: TypeElement.WEB,
    value: 6,
  },
  {
    filter: PersonTypeCOEnum.PHYSICAL,
    label: BasicDataLabelsCO.NOMBRE,
    name: FieldsNamesRender.names,
    regExp: RegularExp.ONLY_LETTERS,
    rules: {
      maxLength: {
        message: ErrorMessageCO.MAX_1_50_CHAR,
        value: 50,
      },
      minLength: {
        message: ErrorMessageCO.MAX_1_50_CHAR,
        value: 1,
      },
      pattern: {
        message: ErrorMessageCO.LETRAS,
        value: new RegExp(RegularExp.ONLY_LETTERS),
      },
      required: {
        message: ErrorMessageCO.PF_NOMBRE,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 6,
  },
  {
    filter: PersonTypeCOEnum.PHYSICAL,
    label: BasicDataLabelsCO.APELLIDO,
    name: FieldsNamesRender.surname,
    regExp: RegularExp.ONLY_LETTERS,
    rules: {
      maxLength: {
        message: ErrorMessageCO.MAX_1_50_CHAR,
        value: 50,
      },
      minLength: {
        message: ErrorMessageCO.MAX_1_50_CHAR,
        value: 1,
      },
      pattern: {
        message: ErrorMessageCO.LETRAS,
        value: new RegExp(RegularExp.ONLY_LETTERS),
      },
      required: {
        message: ErrorMessageCO.PF_APELLIDO,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 6,
  },
  {
    filter: PersonTypeCOEnum.PHYSICAL,
    label: BasicDataLabelsCO.NIT,
    name: FieldsNamesRender.rfc,
    regExp: RegularExp.ONLY_NUMBERS,
    rules: {
      maxLength: {
        message: ErrorMessageCO.LIMIT_NIT,
        value: 10,
      },
      minLength: {
        message: ErrorMessageCO.LIMIT_NIT,
        value: 10,
      },
      required: {
        message: ErrorMessageCO.PF_NUM_ID,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 12,
  },
  {
    filter: PersonTypeCOEnum.PHYSICAL,
    label: BasicDataLabelsCO.TIPO_INDUSTRIA,
    name: FieldsNamesRender.type_industry,
    rules: {
      required: {
        message: ErrorMessageMX.PF_INDUSTRIA,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    value: 12,
  },
  {
    filter: PersonTypeCOEnum.PHYSICAL,
    label: BasicDataLabelsCO.MCC,
    name: FieldsNamesRender.mcc,
    rules: {
      required: {
        message: ErrorMessageCO.PF_MCC,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    typeArray: TypeElement.COMBO_ARRAY,
    value: 6,
  },
  {
    filter: PersonTypeCOEnum.PHYSICAL,
    label: BasicDataLabelsCO.FECHA_CONST,
    name: FieldsNamesRender.date,
    type: TypeElement.DATE_PICKER,
    value: 6,
  },
  {
    filter: PersonTypeCOEnum.PHYSICAL,
    label: BasicDataLabelsCO.WEB,
    name: FieldsNamesRender.web,
    regExp: RegularExp.WEB,
    rules: {
      pattern: {
        message: ErrorMessageMX.REG_EXP_WEB,
        value: WEB_SITE_REGEX,
      },
      required: {
        message: ErrorMessageCO.PF_WEB,
        value: true,
      },
    },
    type: TypeElement.WEB,
    value: 6,
  },
];
