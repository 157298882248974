import {
  clearSelectedTrasactionTable,
  orderSelectedTransactionTable,
} from "../../../store/actions/transactions/transactions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/hooks";
import {
  selectSelectedTransactions,
  selectSelectedTransactionsIDs,
} from "../../../store/selectors/transactions/transactions";
clearSelectedTrasactionTable;
export const useSelectedTransactionState = () => {
  const dispatch = useAppDispatch();
  const selectedTransactionsIDs = useAppSelector(selectSelectedTransactionsIDs);
  const selectedTransactions = useAppSelector(selectSelectedTransactions);

  const handleclearSelectedTable = () => {
    dispatch(clearSelectedTrasactionTable());
  };

  const handleOrderSelectedTable = () => {
    dispatch(orderSelectedTransactionTable());
  };

  return {
    handleclearSelectedTable,
    handleOrderSelectedTable,
    selectedTransactions,
    selectedTransactionsIDs,
  };
};
