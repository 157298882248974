import { Card, CardContent, Grid } from "@mui/material";
import React, { FC } from "react";
import { formWrapperStyles as styles } from "./FormWrapper.styles";
import { CheckoutAlert } from "../CheckoutAlert/CheckoutAlert";
import { store } from "../../store/store";
import {
  closeErrorAlert,
  closeSuccessAlert,
} from "../../store/actions/app/actions";
import { useAppDispatch } from "../../store/hooks/hooks";

export interface IFormWrapper {
  children: JSX.Element;
}

const FormWrapper: FC<IFormWrapper> = ({ children }: IFormWrapper) => {
  const dispatch = useAppDispatch();

  return (
    <Grid container>
      <CheckoutAlert
        msg={store.getState().app.alertErrorMessage}
        open={store.getState().app.isAlertError}
        type="error"
        handlerClose={() => dispatch(closeErrorAlert())}
      />
      <CheckoutAlert
        msg={store.getState().app.alertSuccessMessage}
        open={store.getState().app.isAlertSuccess}
        type="success"
        handlerClose={() => dispatch(closeSuccessAlert())}
      />
      <Grid item md={10} xs={12}>
        <Card sx={styles.cardBody}>
          <CardContent className={"card-content"}>{children}</CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default FormWrapper;
