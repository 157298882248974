import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import { ROUTES } from "./shared/constants/routes";
import PayoutsCardProcessorComponent from "./containers/CreateProcessor/PayoutsCardProcessorComponent";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
import { environment } from "./environments/environment";
import { SecurityRulesRoles } from "./shared/constants/processorConstants";

const Navigation: React.FC = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <ModuleSecurityWrapper
          basePath={environment.kushkiUrl}
          componentId={SecurityRulesRoles.MODULE_ID}
        >
          <Routes>
            <Route
              path={ROUTES.CREATE_PROCESSOR}
              element={<PayoutsCardProcessorComponent />}
            />
            <Route
              path={ROUTES.EDIT_PROCESSOR}
              element={<PayoutsCardProcessorComponent />}
            />
          </Routes>
        </ModuleSecurityWrapper>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default connect()(Navigation);
