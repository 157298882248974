import React from "react";
import { Card, CardContent, Divider } from "@material-ui/core";
import { CardContainerFooter } from "./CardContainerFooter/CardContainerFooter";
import { CardContainerHeader } from "./CardContainerHeader/CardContainerHeader";
import { makeStyles } from "@material-ui/core/styles";
import { IMenuControl } from "../../containers/TableRates/state/useTableRates";

const useStyles = makeStyles(() => ({
  cardContainer: {
    width: "100%",
    backgroundColor: "#F7FAFC",
    paddingTop: "9.4px",
  },
  cardContainerDivider: {
    marginBottom: "15px",
  },
  cardContainerBox: {
    height: "24.5px",
  },
}));

export interface ICardContainerProps {
  headerCardTitle: string;
  headerButtonTitle?: string;
  footerCardHrefLocation?: string;
  footerTitleHrefLocation?: string;
  menuControl?: IMenuControl;
  headerButtonOnClick?: () => void;
  isMenuButton?: boolean;
  merchantId?: string;
  country?: string | undefined;
  childrenArrayCardComponent: JSX.Element[];
  buttonCreate?: boolean;
}

export const CardContainer: React.FC<ICardContainerProps> = (
  props: React.PropsWithChildren<ICardContainerProps>
) => {
  const style = useStyles();
  return (
    <Card className={style.cardContainer} elevation={0}>
      <CardContent>
        <CardContainerHeader
          headerTitle={props.headerCardTitle}
          buttonTitle={props.headerButtonTitle}
          handlerOnclick={props.headerButtonOnClick}
          menuControl={props.menuControl}
          merchantId={props.merchantId}
          isMenuButton={props.isMenuButton}
          country={props.country}
          buttonCreate={props.buttonCreate}
        />
        <Divider className={style.cardContainerDivider} />
        {props.childrenArrayCardComponent.map((component, index) => (
          <React.Fragment key={index}>{component}</React.Fragment>
        ))}
      </CardContent>
      <CardContainerFooter
        href={props.footerCardHrefLocation}
        footerTitle={props.footerTitleHrefLocation}
      />
    </Card>
  );
};
