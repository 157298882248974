/* eslint-disable */
export const COLOMBIA_CITIES = [
  {
    value: "11001",
    text: "BOGOTÁ D.C.",
    provinceCode: "11",
  },
  {
    value: "110151",
    text: "BOGOTÁ",
    provinceCode: "11",
  },
  {
    value: "05001",
    text: "MEDELLÍN",
    provinceCode: "05",
  },
  {
    value: "05002",
    text: "ABEJORRAL",
    provinceCode: "05",
  },
  {
    value: "05004",
    text: "ABRIAQUÍ",
    provinceCode: "05",
  },
  {
    value: "05021",
    text: "ALEJANDRÍA",
    provinceCode: "05",
  },
  {
    value: "05030",
    text: "AMAGÁ",
    provinceCode: "05",
  },
  {
    value: "05031",
    text: "AMALFI",
    provinceCode: "05",
  },
  {
    value: "05034",
    text: "ANDES",
    provinceCode: "05",
  },
  {
    value: "05036",
    text: "ANGELÓPOLIS",
    provinceCode: "05",
  },
  {
    value: "05038",
    text: "ANGOSTURA",
    provinceCode: "05",
  },
  {
    value: "05040",
    text: "ANORÍ",
    provinceCode: "05",
  },
  {
    value: "68176",
    text: "CHIMÁ",
    provinceCode: "68",
  },
  {
    value: "05044",
    text: "ANZA",
    provinceCode: "05",
  },
  {
    value: "05045",
    text: "APARTADÓ",
    provinceCode: "05",
  },
  {
    value: "05051",
    text: "ARBOLETES",
    provinceCode: "05",
  },
  {
    value: "05055",
    text: "ARGELIA",
    provinceCode: "05",
  },
  {
    value: "05059",
    text: "ARMENIA",
    provinceCode: "05",
  },
  {
    value: "05079",
    text: "BARBOSA",
    provinceCode: "05",
  },
  {
    value: "05088",
    text: "BELLO",
    provinceCode: "05",
  },
  {
    value: "05091",
    text: "BETANIA",
    provinceCode: "05",
  },
  {
    value: "05093",
    text: "BETULIA",
    provinceCode: "05",
  },
  {
    value: "05101",
    text: "CIUDAD BOLÍVAR",
    provinceCode: "05",
  },
  {
    value: "05107",
    text: "BRICEÑO",
    provinceCode: "05",
  },
  {
    value: "052040",
    text: "SAN ANDRÉS",
    provinceCode: "05",
  },
  {
    value: "05113",
    text: "BURITICÁ",
    provinceCode: "05",
  },
  {
    value: "05120",
    text: "CÁCERES",
    provinceCode: "05",
  },
  {
    value: "05125",
    text: "CAICEDO",
    provinceCode: "05",
  },
  {
    value: "05129",
    text: "CALDAS",
    provinceCode: "05",
  },
  {
    value: "05134",
    text: "CAMPAMENTO",
    provinceCode: "05",
  },
  {
    value: "05138",
    text: "CAÑASGORDAS",
    provinceCode: "05",
  },
  {
    value: "05142",
    text: "CARACOLÍ",
    provinceCode: "05",
  },
  {
    value: "05145",
    text: "CARAMANTA",
    provinceCode: "05",
  },
  {
    value: "05147",
    text: "CAREPA",
    provinceCode: "05",
  },
  {
    value: "70670",
    text: "SAMPUÉS",
    provinceCode: "70",
  },
  {
    value: "05150",
    text: "CAROLINA",
    provinceCode: "05",
  },
  {
    value: "05154",
    text: "CAUCASIA",
    provinceCode: "05",
  },
  {
    value: "05172",
    text: "CHIGORODÓ",
    provinceCode: "05",
  },
  {
    value: "05190",
    text: "CISNEROS",
    provinceCode: "05",
  },
  {
    value: "05197",
    text: "COCORNÁ",
    provinceCode: "05",
  },
  {
    value: "05206",
    text: "CONCEPCIÓN",
    provinceCode: "05",
  },
  {
    value: "05209",
    text: "CONCORDIA",
    provinceCode: "05",
  },
  {
    value: "05212",
    text: "COPACABANA",
    provinceCode: "05",
  },
  {
    value: "05234",
    text: "DABEIBA",
    provinceCode: "05",
  },
  {
    value: "05237",
    text: "DON MATÍAS",
    provinceCode: "05",
  },
  {
    value: "05240",
    text: "EBÉJICO",
    provinceCode: "05",
  },
  {
    value: "05250",
    text: "EL BAGRE",
    provinceCode: "05",
  },
  {
    value: "05264",
    text: "ENTRERRIOS",
    provinceCode: "05",
  },
  {
    value: "05266",
    text: "ENVIGADO",
    provinceCode: "05",
  },
  {
    value: "05282",
    text: "FREDONIA",
    provinceCode: "05",
  },
  {
    value: "05306",
    text: "GIRALDO",
    provinceCode: "05",
  },
  {
    value: "05308",
    text: "GIRARDOTA",
    provinceCode: "05",
  },
  {
    value: "05310",
    text: "GÓMEZ PLATA",
    provinceCode: "05",
  },
  {
    value: "85225",
    text: "NUNCHÍA",
    provinceCode: "85",
  },
  {
    value: "05315",
    text: "GUADALUPE",
    provinceCode: "05",
  },
  {
    value: "05318",
    text: "GUARNE",
    provinceCode: "05",
  },
  {
    value: "05321",
    text: "GUATAPÉ",
    provinceCode: "05",
  },
  {
    value: "05347",
    text: "HELICONIA",
    provinceCode: "05",
  },
  {
    value: "05353",
    text: "HISPANIA",
    provinceCode: "05",
  },
  {
    value: "05360",
    text: "ITAGUI",
    provinceCode: "05",
  },
  {
    value: "05361",
    text: "ITUANGO",
    provinceCode: "05",
  },
  {
    value: "54518",
    text: "PAMPLONA",
    provinceCode: "54",
  },
  {
    value: "05368",
    text: "JERICÓ",
    provinceCode: "05",
  },
  {
    value: "05376",
    text: "LA CEJA",
    provinceCode: "05",
  },
  {
    value: "05380",
    text: "LA ESTRELLA",
    provinceCode: "05",
  },
  {
    value: "05390",
    text: "LA PINTADA",
    provinceCode: "05",
  },
  {
    value: "05400",
    text: "LA UNIÓN",
    provinceCode: "05",
  },
  {
    value: "05411",
    text: "LIBORINA",
    provinceCode: "05",
  },
  {
    value: "05425",
    text: "MACEO",
    provinceCode: "05",
  },
  {
    value: "05440",
    text: "MARINILLA",
    provinceCode: "05",
  },
  {
    value: "05467",
    text: "MONTEBELLO",
    provinceCode: "05",
  },
  {
    value: "05475",
    text: "MURINDÓ",
    provinceCode: "05",
  },
  {
    value: "05480",
    text: "MUTATÁ",
    provinceCode: "05",
  },
  {
    value: "05483",
    text: "NARIÑO",
    provinceCode: "05",
  },
  {
    value: "05490",
    text: "NECOCLÍ",
    provinceCode: "05",
  },
  {
    value: "05495",
    text: "NECHÍ",
    provinceCode: "05",
  },
  {
    value: "05501",
    text: "OLAYA",
    provinceCode: "05",
  },
  {
    value: "05541",
    text: "PEÑOL",
    provinceCode: "05",
  },
  {
    value: "05543",
    text: "PEQUE",
    provinceCode: "05",
  },
  {
    value: "05576",
    text: "PUEBLORRICO",
    provinceCode: "05",
  },
  {
    value: "05579",
    text: "PUERTO BERRÍO",
    provinceCode: "05",
  },
  {
    value: "05585",
    text: "PUERTO NARE",
    provinceCode: "05",
  },
  {
    value: "05591",
    text: "PUERTO TRIUNFO",
    provinceCode: "05",
  },
  {
    value: "05604",
    text: "REMEDIOS",
    provinceCode: "05",
  },
  {
    value: "05607",
    text: "RETIRO",
    provinceCode: "05",
  },
  {
    value: "05615",
    text: "RIONEGRO",
    provinceCode: "05",
  },
  {
    value: "05628",
    text: "SABANALARGA",
    provinceCode: "05",
  },
  {
    value: "05631",
    text: "SABANETA",
    provinceCode: "05",
  },
  {
    value: "05642",
    text: "SALGAR",
    provinceCode: "05",
  },
  {
    value: "25019",
    text: "ALBÁN",
    provinceCode: "25",
  },
  {
    value: "97889",
    text: "YAVARATÉ",
    provinceCode: "97",
  },
  {
    value: "05652",
    text: "SAN FRANCISCO",
    provinceCode: "05",
  },
  {
    value: "05656",
    text: "SAN JERÓNIMO",
    provinceCode: "05",
  },
  {
    value: "23466",
    text: "MONTELÍBANO",
    provinceCode: "23",
  },
  {
    value: "86568",
    text: "PUERTO ASÍS",
    provinceCode: "86",
  },
  {
    value: "05660",
    text: "SAN LUIS",
    provinceCode: "05",
  },
  {
    value: "05664",
    text: "SAN PEDRO",
    provinceCode: "05",
  },
  {
    value: "70215",
    text: "COROZAL",
    provinceCode: "70",
  },
  {
    value: "05667",
    text: "SAN RAFAEL",
    provinceCode: "05",
  },
  {
    value: "05670",
    text: "SAN ROQUE",
    provinceCode: "05",
  },
  {
    value: "05674",
    text: "SAN VICENTE",
    provinceCode: "05",
  },
  {
    value: "05679",
    text: "SANTA BÁRBARA",
    provinceCode: "05",
  },
  {
    value: "52110",
    text: "BUESACO",
    provinceCode: "52",
  },
  {
    value: "05690",
    text: "SANTO DOMINGO",
    provinceCode: "05",
  },
  {
    value: "05697",
    text: "EL SANTUARIO",
    provinceCode: "05",
  },
  {
    value: "05736",
    text: "SEGOVIA",
    provinceCode: "05",
  },
  {
    value: "05761",
    text: "SOPETRÁN",
    provinceCode: "05",
  },
  {
    value: "05789",
    text: "TÁMESIS",
    provinceCode: "05",
  },
  {
    value: "05790",
    text: "TARAZÁ",
    provinceCode: "05",
  },
  {
    value: "05792",
    text: "TARSO",
    provinceCode: "05",
  },
  {
    value: "05809",
    text: "TITIRIBÍ",
    provinceCode: "05",
  },
  {
    value: "05819",
    text: "TOLEDO",
    provinceCode: "05",
  },
  {
    value: "05837",
    text: "TURBO",
    provinceCode: "05",
  },
  {
    value: "05842",
    text: "URAMITA",
    provinceCode: "05",
  },
  {
    value: "05847",
    text: "URRAO",
    provinceCode: "05",
  },
  {
    value: "05854",
    text: "VALDIVIA",
    provinceCode: "05",
  },
  {
    value: "05856",
    text: "VALPARAÍSO",
    provinceCode: "05",
  },
  {
    value: "05858",
    text: "VEGACHÍ",
    provinceCode: "05",
  },
  {
    value: "05861",
    text: "VENECIA",
    provinceCode: "05",
  },
  {
    value: "85139",
    text: "MANÍ",
    provinceCode: "85",
  },
  {
    value: "05885",
    text: "YALÍ",
    provinceCode: "05",
  },
  {
    value: "05887",
    text: "YARUMAL",
    provinceCode: "05",
  },
  {
    value: "05890",
    text: "YOLOMBÓ",
    provinceCode: "05",
  },
  {
    value: "05893",
    text: "YONDÓ",
    provinceCode: "05",
  },
  {
    value: "05895",
    text: "ZARAGOZA",
    provinceCode: "05",
  },
  {
    value: "08001",
    text: "BARRANQUILLA",
    provinceCode: "08",
  },
  {
    value: "08078",
    text: "BARANOA",
    provinceCode: "08",
  },
  {
    value: "13268",
    text: "EL PEÑÓN",
    provinceCode: "13",
  },
  {
    value: "08141",
    text: "CANDELARIA",
    provinceCode: "08",
  },
  {
    value: "08296",
    text: "GALAPA",
    provinceCode: "08",
  },
  {
    value: "76834",
    text: "TULUÁ",
    provinceCode: "76",
  },
  {
    value: "73152",
    text: "CASABIANCA",
    provinceCode: "73",
  },
  {
    value: "08421",
    text: "LURUACO",
    provinceCode: "08",
  },
  {
    value: "08433",
    text: "MALAMBO",
    provinceCode: "08",
  },
  {
    value: "08436",
    text: "MANATÍ",
    provinceCode: "08",
  },
  {
    value: "25040",
    text: "ANOLAIMA",
    provinceCode: "25",
  },
  {
    value: "08549",
    text: "PIOJÓ",
    provinceCode: "08",
  },
  {
    value: "08558",
    text: "POLONUEVO",
    provinceCode: "08",
  },
  {
    value: "25175",
    text: "CHÍA",
    provinceCode: "25",
  },
  {
    value: "52835",
    text: "SAN ANDRÉS DE TUMACO",
    provinceCode: "52",
  },
  {
    value: "08634",
    text: "SABANAGRANDE",
    provinceCode: "08",
  },
  {
    value: "08638",
    text: "SABANALARGA",
    provinceCode: "08",
  },
  {
    value: "08675",
    text: "SANTA LUCÍA",
    provinceCode: "08",
  },
  {
    value: "08685",
    text: "SANTO TOMÁS",
    provinceCode: "08",
  },
  {
    value: "08758",
    text: "SOLEDAD",
    provinceCode: "08",
  },
  {
    value: "08770",
    text: "SUAN",
    provinceCode: "08",
  },
  {
    value: "08832",
    text: "TUBARÁ",
    provinceCode: "08",
  },
  {
    value: "08849",
    text: "USIACURÍ",
    provinceCode: "08",
  },
  {
    value: "18460",
    text: "MILÁN",
    provinceCode: "18",
  },
  {
    value: "68147",
    text: "CAPITANEJO",
    provinceCode: "68",
  },
  {
    value: "13006",
    text: "ACHÍ",
    provinceCode: "13",
  },
  {
    value: "73043",
    text: "ANZOÁTEGUI",
    provinceCode: "73",
  },
  {
    value: "13042",
    text: "ARENAL",
    provinceCode: "13",
  },
  {
    value: "13052",
    text: "ARJONA",
    provinceCode: "13",
  },
  {
    value: "13062",
    text: "ARROYOHONDO",
    provinceCode: "13",
  },
  {
    value: "76275",
    text: "FLORIDA",
    provinceCode: "76",
  },
  {
    value: "13140",
    text: "CALAMAR",
    provinceCode: "13",
  },
  {
    value: "13160",
    text: "CANTAGALLO",
    provinceCode: "13",
  },
  {
    value: "13188",
    text: "CICUCO",
    provinceCode: "13",
  },
  {
    value: "13212",
    text: "CÓRDOBA",
    provinceCode: "13",
  },
  {
    value: "13222",
    text: "CLEMENCIA",
    provinceCode: "13",
  },
  {
    value: "08606",
    text: "REPELÓN",
    provinceCode: "08",
  },
  {
    value: "13248",
    text: "EL GUAMO",
    provinceCode: "13",
  },
  {
    value: "05284",
    text: "FRONTINO",
    provinceCode: "05",
  },
  {
    value: "13430",
    text: "MAGANGUÉ",
    provinceCode: "13",
  },
  {
    value: "13433",
    text: "MAHATES",
    provinceCode: "13",
  },
  {
    value: "13440",
    text: "MARGARITA",
    provinceCode: "13",
  },
  {
    value: "13458",
    text: "MONTECRISTO",
    provinceCode: "13",
  },
  {
    value: "13468",
    text: "MOMPÓS",
    provinceCode: "13",
  },
  {
    value: "13473",
    text: "MORALES",
    provinceCode: "13",
  },
  {
    value: "13490",
    text: "NOROSÍ",
    provinceCode: "13",
  },
  {
    value: "13549",
    text: "PINILLOS",
    provinceCode: "13",
  },
  {
    value: "13580",
    text: "REGIDOR",
    provinceCode: "13",
  },
  {
    value: "13600",
    text: "RÍO VIEJO",
    provinceCode: "13",
  },
  {
    value: "13647",
    text: "SAN ESTANISLAO",
    provinceCode: "13",
  },
  {
    value: "13650",
    text: "SAN FERNANDO",
    provinceCode: "13",
  },
  {
    value: "25258",
    text: "EL PEÑÓN",
    provinceCode: "25",
  },
  {
    value: "54520",
    text: "PAMPLONITA",
    provinceCode: "54",
  },
  {
    value: "13657",
    text: "SAN JUAN NEPOMUCENO",
    provinceCode: "13",
  },
  {
    value: "91460",
    text: "MIRITI - PARANÁ",
    provinceCode: "91",
  },
  {
    value: "85400",
    text: "TÁMARA",
    provinceCode: "85",
  },
  {
    value: "13673",
    text: "SANTA CATALINA",
    provinceCode: "13",
  },
  {
    value: "13683",
    text: "SANTA ROSA",
    provinceCode: "13",
  },
  {
    value: "15806",
    text: "TIBASOSA",
    provinceCode: "15",
  },
  {
    value: "13744",
    text: "SIMITÍ",
    provinceCode: "13",
  },
  {
    value: "13760",
    text: "SOPLAVIENTO",
    provinceCode: "13",
  },
  {
    value: "13780",
    text: "TALAIGUA NUEVO",
    provinceCode: "13",
  },
  {
    value: "13810",
    text: "TIQUISIO",
    provinceCode: "13",
  },
  {
    value: "13836",
    text: "TURBACO",
    provinceCode: "13",
  },
  {
    value: "13838",
    text: "TURBANÁ",
    provinceCode: "13",
  },
  {
    value: "13873",
    text: "VILLANUEVA",
    provinceCode: "13",
  },
  {
    value: "19517",
    text: "PÁEZ",
    provinceCode: "19",
  },
  {
    value: "15001",
    text: "TUNJA",
    provinceCode: "15",
  },
  {
    value: "15022",
    text: "ALMEIDA",
    provinceCode: "15",
  },
  {
    value: "15047",
    text: "AQUITANIA",
    provinceCode: "15",
  },
  {
    value: "15051",
    text: "ARCABUCO",
    provinceCode: "15",
  },
  {
    value: "73001",
    text: "IBAGUÉ",
    provinceCode: "73",
  },
  {
    value: "15090",
    text: "BERBEO",
    provinceCode: "15",
  },
  {
    value: "15092",
    text: "BETÉITIVA",
    provinceCode: "15",
  },
  {
    value: "15097",
    text: "BOAVITA",
    provinceCode: "15",
  },
  {
    value: "15104",
    text: "BOYACÁ",
    provinceCode: "15",
  },
  {
    value: "856030",
    text: "CHAMEZA",
    provinceCode: "85",
  },
  {
    value: "853030",
    text: "SAN LUIS DE PALENQUE",
    provinceCode: "85",
  },
  {
    value: "15106",
    text: "BRICEÑO",
    provinceCode: "15",
  },
  {
    value: "15109",
    text: "BUENA VISTA",
    provinceCode: "15",
  },
  {
    value: "15114",
    text: "BUSBANZÁ",
    provinceCode: "15",
  },
  {
    value: "15131",
    text: "CALDAS",
    provinceCode: "15",
  },
  {
    value: "15135",
    text: "CAMPOHERMOSO",
    provinceCode: "15",
  },
  {
    value: "15162",
    text: "CERINZA",
    provinceCode: "15",
  },
  {
    value: "15172",
    text: "CHINAVITA",
    provinceCode: "15",
  },
  {
    value: "15176",
    text: "CHIQUINQUIRÁ",
    provinceCode: "15",
  },
  {
    value: "15180",
    text: "CHISCAS",
    provinceCode: "15",
  },
  {
    value: "15183",
    text: "CHITA",
    provinceCode: "15",
  },
  {
    value: "15185",
    text: "CHITARAQUE",
    provinceCode: "15",
  },
  {
    value: "15187",
    text: "CHIVATÁ",
    provinceCode: "15",
  },
  {
    value: "15204",
    text: "CÓMBITA",
    provinceCode: "15",
  },
  {
    value: "15212",
    text: "COPER",
    provinceCode: "15",
  },
  {
    value: "15215",
    text: "CORRALES",
    provinceCode: "15",
  },
  {
    value: "15218",
    text: "COVARACHÍA",
    provinceCode: "15",
  },
  {
    value: "15223",
    text: "CUBARÁ",
    provinceCode: "15",
  },
  {
    value: "15224",
    text: "CUCAITA",
    provinceCode: "15",
  },
  {
    value: "15226",
    text: "CUÍTIVA",
    provinceCode: "15",
  },
  {
    value: "15232",
    text: "CHÍQUIZA",
    provinceCode: "15",
  },
  {
    value: "15236",
    text: "CHIVOR",
    provinceCode: "15",
  },
  {
    value: "15238",
    text: "DUITAMA",
    provinceCode: "15",
  },
  {
    value: "15244",
    text: "EL COCUY",
    provinceCode: "15",
  },
  {
    value: "15248",
    text: "EL ESPINO",
    provinceCode: "15",
  },
  {
    value: "15272",
    text: "FIRAVITOBA",
    provinceCode: "15",
  },
  {
    value: "15276",
    text: "FLORESTA",
    provinceCode: "15",
  },
  {
    value: "15293",
    text: "GACHANTIVÁ",
    provinceCode: "15",
  },
  {
    value: "15296",
    text: "GAMEZA",
    provinceCode: "15",
  },
  {
    value: "15299",
    text: "GARAGOA",
    provinceCode: "15",
  },
  {
    value: "15317",
    text: "GUACAMAYAS",
    provinceCode: "15",
  },
  {
    value: "15322",
    text: "GUATEQUE",
    provinceCode: "15",
  },
  {
    value: "15325",
    text: "GUAYATÁ",
    provinceCode: "15",
  },
  {
    value: "15332",
    text: "GÜICÁN",
    provinceCode: "15",
  },
  {
    value: "15362",
    text: "IZA",
    provinceCode: "15",
  },
  {
    value: "15367",
    text: "JENESANO",
    provinceCode: "15",
  },
  {
    value: "15368",
    text: "JERICÓ",
    provinceCode: "15",
  },
  {
    value: "15377",
    text: "LABRANZAGRANDE",
    provinceCode: "15",
  },
  {
    value: "15380",
    text: "LA CAPILLA",
    provinceCode: "15",
  },
  {
    value: "15401",
    text: "LA VICTORIA",
    provinceCode: "15",
  },
  {
    value: "08573",
    text: "PUERTO COLOMBIA",
    provinceCode: "08",
  },
  {
    value: "52083",
    text: "BELÉN",
    provinceCode: "52",
  },
  {
    value: "15425",
    text: "MACANAL",
    provinceCode: "15",
  },
  {
    value: "15442",
    text: "MARIPÍ",
    provinceCode: "15",
  },
  {
    value: "15455",
    text: "MIRAFLORES",
    provinceCode: "15",
  },
  {
    value: "15464",
    text: "MONGUA",
    provinceCode: "15",
  },
  {
    value: "15466",
    text: "MONGUÍ",
    provinceCode: "15",
  },
  {
    value: "15469",
    text: "MONIQUIRÁ",
    provinceCode: "15",
  },
  {
    value: "15480",
    text: "MUZO",
    provinceCode: "15",
  },
  {
    value: "15491",
    text: "NOBSA",
    provinceCode: "15",
  },
  {
    value: "15494",
    text: "NUEVO COLÓN",
    provinceCode: "15",
  },
  {
    value: "15500",
    text: "OICATÁ",
    provinceCode: "15",
  },
  {
    value: "15507",
    text: "OTANCHE",
    provinceCode: "15",
  },
  {
    value: "15511",
    text: "PACHAVITA",
    provinceCode: "15",
  },
  {
    value: "15514",
    text: "PÁEZ",
    provinceCode: "15",
  },
  {
    value: "15516",
    text: "PAIPA",
    provinceCode: "15",
  },
  {
    value: "15518",
    text: "PAJARITO",
    provinceCode: "15",
  },
  {
    value: "15522",
    text: "PANQUEBA",
    provinceCode: "15",
  },
  {
    value: "15531",
    text: "PAUNA",
    provinceCode: "15",
  },
  {
    value: "15533",
    text: "PAYA",
    provinceCode: "15",
  },
  {
    value: "25758",
    text: "SOPÓ",
    provinceCode: "25",
  },
  {
    value: "15542",
    text: "PESCA",
    provinceCode: "15",
  },
  {
    value: "15550",
    text: "PISBA",
    provinceCode: "15",
  },
  {
    value: "15572",
    text: "PUERTO BOYACÁ",
    provinceCode: "15",
  },
  {
    value: "15580",
    text: "QUÍPAMA",
    provinceCode: "15",
  },
  {
    value: "15599",
    text: "RAMIRIQUÍ",
    provinceCode: "15",
  },
  {
    value: "15600",
    text: "RÁQUIRA",
    provinceCode: "15",
  },
  {
    value: "15621",
    text: "RONDÓN",
    provinceCode: "15",
  },
  {
    value: "15632",
    text: "SABOYÁ",
    provinceCode: "15",
  },
  {
    value: "15638",
    text: "SÁCHICA",
    provinceCode: "15",
  },
  {
    value: "15646",
    text: "SAMACÁ",
    provinceCode: "15",
  },
  {
    value: "15660",
    text: "SAN EDUARDO",
    provinceCode: "15",
  },
  {
    value: "27150",
    text: "CARMEN DEL DARIEN",
    provinceCode: "27",
  },
  {
    value: "25299",
    text: "GAMA",
    provinceCode: "25",
  },
  {
    value: "15673",
    text: "SAN MATEO",
    provinceCode: "15",
  },
  {
    value: "25718",
    text: "SASAIMA",
    provinceCode: "25",
  },
  {
    value: "52240",
    text: "CHACHAGÜÍ",
    provinceCode: "52",
  },
  {
    value: "15686",
    text: "SANTANA",
    provinceCode: "15",
  },
  {
    value: "15690",
    text: "SANTA MARÍA",
    provinceCode: "15",
  },
  {
    value: "54001",
    text: "CÚCUTA",
    provinceCode: "54",
  },
  {
    value: "15696",
    text: "SANTA SOFÍA",
    provinceCode: "15",
  },
  {
    value: "15720",
    text: "SATIVANORTE",
    provinceCode: "15",
  },
  {
    value: "15723",
    text: "SATIVASUR",
    provinceCode: "15",
  },
  {
    value: "15740",
    text: "SIACHOQUE",
    provinceCode: "15",
  },
  {
    value: "15753",
    text: "SOATÁ",
    provinceCode: "15",
  },
  {
    value: "15755",
    text: "SOCOTÁ",
    provinceCode: "15",
  },
  {
    value: "15757",
    text: "SOCHA",
    provinceCode: "15",
  },
  {
    value: "15759",
    text: "SOGAMOSO",
    provinceCode: "15",
  },
  {
    value: "15761",
    text: "SOMONDOCO",
    provinceCode: "15",
  },
  {
    value: "15762",
    text: "SORA",
    provinceCode: "15",
  },
  {
    value: "15763",
    text: "SOTAQUIRÁ",
    provinceCode: "15",
  },
  {
    value: "15764",
    text: "SORACÁ",
    provinceCode: "15",
  },
  {
    value: "15774",
    text: "SUSACÓN",
    provinceCode: "15",
  },
  {
    value: "15776",
    text: "SUTAMARCHÁN",
    provinceCode: "15",
  },
  {
    value: "15778",
    text: "SUTATENZA",
    provinceCode: "15",
  },
  {
    value: "15790",
    text: "TASCO",
    provinceCode: "15",
  },
  {
    value: "15798",
    text: "TENZA",
    provinceCode: "15",
  },
  {
    value: "15804",
    text: "TIBANÁ",
    provinceCode: "15",
  },
  {
    value: "15808",
    text: "TINJACÁ",
    provinceCode: "15",
  },
  {
    value: "15810",
    text: "TIPACOQUE",
    provinceCode: "15",
  },
  {
    value: "15814",
    text: "TOCA",
    provinceCode: "15",
  },
  {
    value: "13001",
    text: "CARTAGENA",
    provinceCode: "13",
  },
  {
    value: "15820",
    text: "TÓPAGA",
    provinceCode: "15",
  },
  {
    value: "15822",
    text: "TOTA",
    provinceCode: "15",
  },
  {
    value: "15835",
    text: "TURMEQUÉ",
    provinceCode: "15",
  },
  {
    value: "05313",
    text: "GRANADA",
    provinceCode: "05",
  },
  {
    value: "15839",
    text: "TUTAZÁ",
    provinceCode: "15",
  },
  {
    value: "15842",
    text: "UMBITA",
    provinceCode: "15",
  },
  {
    value: "15861",
    text: "VENTAQUEMADA",
    provinceCode: "15",
  },
  {
    value: "15879",
    text: "VIRACACHÁ",
    provinceCode: "15",
  },
  {
    value: "15897",
    text: "ZETAQUIRA",
    provinceCode: "15",
  },
  {
    value: "17001",
    text: "MANIZALES",
    provinceCode: "17",
  },
  {
    value: "17013",
    text: "AGUADAS",
    provinceCode: "17",
  },
  {
    value: "17042",
    text: "ANSERMA",
    provinceCode: "17",
  },
  {
    value: "17050",
    text: "ARANZAZU",
    provinceCode: "17",
  },
  {
    value: "17088",
    text: "BELALCÁZAR",
    provinceCode: "17",
  },
  {
    value: "17174",
    text: "CHINCHINÁ",
    provinceCode: "17",
  },
  {
    value: "17272",
    text: "FILADELFIA",
    provinceCode: "17",
  },
  {
    value: "17380",
    text: "LA DORADA",
    provinceCode: "17",
  },
  {
    value: "17388",
    text: "LA MERCED",
    provinceCode: "17",
  },
  {
    value: "17433",
    text: "MANZANARES",
    provinceCode: "17",
  },
  {
    value: "17442",
    text: "MARMATO",
    provinceCode: "17",
  },
  {
    value: "17446",
    text: "MARULANDA",
    provinceCode: "17",
  },
  {
    value: "17486",
    text: "NEIRA",
    provinceCode: "17",
  },
  {
    value: "17495",
    text: "NORCASIA",
    provinceCode: "17",
  },
  {
    value: "17513",
    text: "PÁCORA",
    provinceCode: "17",
  },
  {
    value: "17524",
    text: "PALESTINA",
    provinceCode: "17",
  },
  {
    value: "17541",
    text: "PENSILVANIA",
    provinceCode: "17",
  },
  {
    value: "17614",
    text: "RIOSUCIO",
    provinceCode: "17",
  },
  {
    value: "17616",
    text: "RISARALDA",
    provinceCode: "17",
  },
  {
    value: "17653",
    text: "SALAMINA",
    provinceCode: "17",
  },
  {
    value: "17662",
    text: "SAMANÁ",
    provinceCode: "17",
  },
  {
    value: "17665",
    text: "SAN JOSÉ",
    provinceCode: "17",
  },
  {
    value: "17777",
    text: "SUPÍA",
    provinceCode: "17",
  },
  {
    value: "17867",
    text: "VICTORIA",
    provinceCode: "17",
  },
  {
    value: "17873",
    text: "VILLAMARÍA",
    provinceCode: "17",
  },
  {
    value: "17877",
    text: "VITERBO",
    provinceCode: "17",
  },
  {
    value: "18001",
    text: "FLORENCIA",
    provinceCode: "18",
  },
  {
    value: "18029",
    text: "ALBANIA",
    provinceCode: "18",
  },
  {
    value: "47720",
    text: "SANTA BÁRBARA DE PINTO",
    provinceCode: "47",
  },
  {
    value: "13442",
    text: "MARÍA LA BAJA",
    provinceCode: "13",
  },
  {
    value: "18205",
    text: "CURILLO",
    provinceCode: "18",
  },
  {
    value: "18247",
    text: "EL DONCELLO",
    provinceCode: "18",
  },
  {
    value: "18256",
    text: "EL PAUJIL",
    provinceCode: "18",
  },
  {
    value: "18479",
    text: "MORELIA",
    provinceCode: "18",
  },
  {
    value: "18592",
    text: "PUERTO RICO",
    provinceCode: "18",
  },
  {
    value: "18410",
    text: "LA MONTAÑITA",
    provinceCode: "18",
  },
  {
    value: "18753",
    text: "SAN VICENTE DEL CAGUÁN",
    provinceCode: "18",
  },
  {
    value: "18756",
    text: "SOLANO",
    provinceCode: "18",
  },
  {
    value: "18785",
    text: "SOLITA",
    provinceCode: "18",
  },
  {
    value: "18860",
    text: "VALPARAÍSO",
    provinceCode: "18",
  },
  {
    value: "19001",
    text: "POPAYÁN",
    provinceCode: "19",
  },
  {
    value: "19022",
    text: "ALMAGUER",
    provinceCode: "19",
  },
  {
    value: "19050",
    text: "ARGELIA",
    provinceCode: "19",
  },
  {
    value: "19075",
    text: "BALBOA",
    provinceCode: "19",
  },
  {
    value: "19100",
    text: "BOLÍVAR",
    provinceCode: "19",
  },
  {
    value: "232010",
    text: "CHIMA",
    provinceCode: "23",
  },
  {
    value: "19110",
    text: "BUENOS AIRES",
    provinceCode: "19",
  },
  {
    value: "19130",
    text: "CAJIBÍO",
    provinceCode: "19",
  },
  {
    value: "19137",
    text: "CALDONO",
    provinceCode: "19",
  },
  {
    value: "19142",
    text: "CALOTO",
    provinceCode: "19",
  },
  {
    value: "19212",
    text: "CORINTO",
    provinceCode: "19",
  },
  {
    value: "19256",
    text: "EL TAMBO",
    provinceCode: "19",
  },
  {
    value: "19290",
    text: "FLORENCIA",
    provinceCode: "19",
  },
  {
    value: "19300",
    text: "GUACHENÉ",
    provinceCode: "19",
  },
  {
    value: "19318",
    text: "GUAPI",
    provinceCode: "19",
  },
  {
    value: "19355",
    text: "INZÁ",
    provinceCode: "19",
  },
  {
    value: "19364",
    text: "JAMBALÓ",
    provinceCode: "19",
  },
  {
    value: "19392",
    text: "LA SIERRA",
    provinceCode: "19",
  },
  {
    value: "19397",
    text: "LA VEGA",
    provinceCode: "19",
  },
  {
    value: "19418",
    text: "LÓPEZ",
    provinceCode: "19",
  },
  {
    value: "19450",
    text: "MERCADERES",
    provinceCode: "19",
  },
  {
    value: "19455",
    text: "MIRANDA",
    provinceCode: "19",
  },
  {
    value: "19473",
    text: "MORALES",
    provinceCode: "19",
  },
  {
    value: "19513",
    text: "PADILLA",
    provinceCode: "19",
  },
  {
    value: "19532",
    text: "PATÍA",
    provinceCode: "19",
  },
  {
    value: "19533",
    text: "PIAMONTE",
    provinceCode: "19",
  },
  {
    value: "19548",
    text: "PIENDAMÓ",
    provinceCode: "19",
  },
  {
    value: "19573",
    text: "PUERTO TEJADA",
    provinceCode: "19",
  },
  {
    value: "19585",
    text: "PURACÉ",
    provinceCode: "19",
  },
  {
    value: "19622",
    text: "ROSAS",
    provinceCode: "19",
  },
  {
    value: "68250",
    text: "EL PEÑÓN",
    provinceCode: "68",
  },
  {
    value: "05364",
    text: "JARDÍN",
    provinceCode: "05",
  },
  {
    value: "19701",
    text: "SANTA ROSA",
    provinceCode: "19",
  },
  {
    value: "19743",
    text: "SILVIA",
    provinceCode: "19",
  },
  {
    value: "19760",
    text: "SOTARA",
    provinceCode: "19",
  },
  {
    value: "19780",
    text: "SUÁREZ",
    provinceCode: "19",
  },
  {
    value: "19785",
    text: "SUCRE",
    provinceCode: "19",
  },
  {
    value: "272037",
    text: "UNIÓN PANAMERICANA",
    provinceCode: "27",
  },
  {
    value: "476060",
    text: "CHIBOLO",
    provinceCode: "47",
  },
  {
    value: "478040",
    text: "PUEBLOVIEJO",
    provinceCode: "47",
  },
  {
    value: "507001",
    text: "ACACÍAS",
    provinceCode: "50",
  },
  {
    value: "446001",
    text: "DIBULLA",
    provinceCode: "44",
  },
  {
    value: "504061",
    text: "VISTAHERMOSA",
    provinceCode: "50",
  },
  {
    value: "19807",
    text: "TIMBÍO",
    provinceCode: "19",
  },
  {
    value: "19809",
    text: "TIMBIQUÍ",
    provinceCode: "19",
  },
  {
    value: "19821",
    text: "TORIBIO",
    provinceCode: "19",
  },
  {
    value: "19824",
    text: "TOTORÓ",
    provinceCode: "19",
  },
  {
    value: "19845",
    text: "VILLA RICA",
    provinceCode: "19",
  },
  {
    value: "20001",
    text: "VALLEDUPAR",
    provinceCode: "20",
  },
  {
    value: "20011",
    text: "AGUACHICA",
    provinceCode: "20",
  },
  {
    value: "20013",
    text: "AGUSTÍN CODAZZI",
    provinceCode: "20",
  },
  {
    value: "20032",
    text: "ASTREA",
    provinceCode: "20",
  },
  {
    value: "20045",
    text: "BECERRIL",
    provinceCode: "20",
  },
  {
    value: "20060",
    text: "BOSCONIA",
    provinceCode: "20",
  },
  {
    value: "20175",
    text: "CHIMICHAGUA",
    provinceCode: "20",
  },
  {
    value: "20178",
    text: "CHIRIGUANÁ",
    provinceCode: "20",
  },
  {
    value: "20228",
    text: "CURUMANÍ",
    provinceCode: "20",
  },
  {
    value: "20238",
    text: "EL COPEY",
    provinceCode: "20",
  },
  {
    value: "20250",
    text: "EL PASO",
    provinceCode: "20",
  },
  {
    value: "20295",
    text: "GAMARRA",
    provinceCode: "20",
  },
  {
    value: "20310",
    text: "GONZÁLEZ",
    provinceCode: "20",
  },
  {
    value: "20383",
    text: "LA GLORIA",
    provinceCode: "20",
  },
  {
    value: "76364",
    text: "JAMUNDÍ",
    provinceCode: "76",
  },
  {
    value: "20443",
    text: "MANAURE",
    provinceCode: "20",
  },
  {
    value: "20517",
    text: "PAILITAS",
    provinceCode: "20",
  },
  {
    value: "20550",
    text: "PELAYA",
    provinceCode: "20",
  },
  {
    value: "20570",
    text: "PUEBLO BELLO",
    provinceCode: "20",
  },
  {
    value: "27787",
    text: "TADÓ",
    provinceCode: "27",
  },
  {
    value: "20621",
    text: "LA PAZ",
    provinceCode: "20",
  },
  {
    value: "20710",
    text: "SAN ALBERTO",
    provinceCode: "20",
  },
  {
    value: "20750",
    text: "SAN DIEGO",
    provinceCode: "20",
  },
  {
    value: "20770",
    text: "SAN MARTÍN",
    provinceCode: "20",
  },
  {
    value: "20787",
    text: "TAMALAMEQUE",
    provinceCode: "20",
  },
  {
    value: "23001",
    text: "MONTERÍA",
    provinceCode: "23",
  },
  {
    value: "23068",
    text: "AYAPEL",
    provinceCode: "23",
  },
  {
    value: "23079",
    text: "BUENAVISTA",
    provinceCode: "23",
  },
  {
    value: "155067",
    text: "BUENAVISTA",
    provinceCode: "15",
  },
  {
    value: "23090",
    text: "CANALETE",
    provinceCode: "23",
  },
  {
    value: "23162",
    text: "CERETÉ",
    provinceCode: "23",
  },
  {
    value: "23168",
    text: "CHIMÁ",
    provinceCode: "23",
  },
  {
    value: "23182",
    text: "CHINÚ",
    provinceCode: "23",
  },
  {
    value: "85230",
    text: "OROCUÉ",
    provinceCode: "85",
  },
  {
    value: "23300",
    text: "COTORRA",
    provinceCode: "23",
  },
  {
    value: "73411",
    text: "LÍBANO",
    provinceCode: "73",
  },
  {
    value: "23417",
    text: "LORICA",
    provinceCode: "23",
  },
  {
    value: "23419",
    text: "LOS CÓRDOBAS",
    provinceCode: "23",
  },
  {
    value: "23464",
    text: "MOMIL",
    provinceCode: "23",
  },
  {
    value: "23500",
    text: "MOÑITOS",
    provinceCode: "23",
  },
  {
    value: "23555",
    text: "PLANETA RICA",
    provinceCode: "23",
  },
  {
    value: "23570",
    text: "PUEBLO NUEVO",
    provinceCode: "23",
  },
  {
    value: "23574",
    text: "PUERTO ESCONDIDO",
    provinceCode: "23",
  },
  {
    value: "25885",
    text: "YACOPÍ",
    provinceCode: "25",
  },
  {
    value: "23586",
    text: "PURÍSIMA",
    provinceCode: "23",
  },
  {
    value: "23660",
    text: "SAHAGÚN",
    provinceCode: "23",
  },
  {
    value: "23670",
    text: "SAN ANDRÉS SOTAVENTO",
    provinceCode: "23",
  },
  {
    value: "23672",
    text: "SAN ANTERO",
    provinceCode: "23",
  },
  {
    value: "63130",
    text: "CALARCÁ",
    provinceCode: "63",
  },
  {
    value: "05756",
    text: "SONSÓN",
    provinceCode: "05",
  },
  {
    value: "54245",
    text: "EL CARMEN",
    provinceCode: "54",
  },
  {
    value: "23686",
    text: "SAN PELAYO",
    provinceCode: "23",
  },
  {
    value: "23807",
    text: "TIERRALTA",
    provinceCode: "23",
  },
  {
    value: "23815",
    text: "TUCHÍN",
    provinceCode: "23",
  },
  {
    value: "23855",
    text: "VALENCIA",
    provinceCode: "23",
  },
  {
    value: "73408",
    text: "LÉRIDA",
    provinceCode: "73",
  },
  {
    value: "25035",
    text: "ANAPOIMA",
    provinceCode: "25",
  },
  {
    value: "25053",
    text: "ARBELÁEZ",
    provinceCode: "25",
  },
  {
    value: "25086",
    text: "BELTRÁN",
    provinceCode: "25",
  },
  {
    value: "25095",
    text: "BITUIMA",
    provinceCode: "25",
  },
  {
    value: "25099",
    text: "BOJACÁ",
    provinceCode: "25",
  },
  {
    value: "25120",
    text: "CABRERA",
    provinceCode: "25",
  },
  {
    value: "25123",
    text: "CACHIPAY",
    provinceCode: "25",
  },
  {
    value: "25126",
    text: "CAJICÁ",
    provinceCode: "25",
  },
  {
    value: "25148",
    text: "CAPARRAPÍ",
    provinceCode: "25",
  },
  {
    value: "25151",
    text: "CAQUEZA",
    provinceCode: "25",
  },
  {
    value: "23350",
    text: "LA APARTADA",
    provinceCode: "23",
  },
  {
    value: "25168",
    text: "CHAGUANÍ",
    provinceCode: "25",
  },
  {
    value: "25178",
    text: "CHIPAQUE",
    provinceCode: "25",
  },
  {
    value: "25181",
    text: "CHOACHÍ",
    provinceCode: "25",
  },
  {
    value: "25183",
    text: "CHOCONTÁ",
    provinceCode: "25",
  },
  {
    value: "25200",
    text: "COGUA",
    provinceCode: "25",
  },
  {
    value: "25214",
    text: "COTA",
    provinceCode: "25",
  },
  {
    value: "25224",
    text: "CUCUNUBÁ",
    provinceCode: "25",
  },
  {
    value: "25245",
    text: "EL COLEGIO",
    provinceCode: "25",
  },
  {
    value: "25260",
    text: "EL ROSAL",
    provinceCode: "25",
  },
  {
    value: "25279",
    text: "FOMEQUE",
    provinceCode: "25",
  },
  {
    value: "25281",
    text: "FOSCA",
    provinceCode: "25",
  },
  {
    value: "25286",
    text: "FUNZA",
    provinceCode: "25",
  },
  {
    value: "25288",
    text: "FÚQUENE",
    provinceCode: "25",
  },
  {
    value: "25293",
    text: "GACHALA",
    provinceCode: "25",
  },
  {
    value: "25295",
    text: "GACHANCIPÁ",
    provinceCode: "25",
  },
  {
    value: "25297",
    text: "GACHETÁ",
    provinceCode: "25",
  },
  {
    value: "13620",
    text: "SAN CRISTÓBAL",
    provinceCode: "13",
  },
  {
    value: "25307",
    text: "GIRARDOT",
    provinceCode: "25",
  },
  {
    value: "25312",
    text: "GRANADA",
    provinceCode: "25",
  },
  {
    value: "25317",
    text: "GUACHETÁ",
    provinceCode: "25",
  },
  {
    value: "25320",
    text: "GUADUAS",
    provinceCode: "25",
  },
  {
    value: "25322",
    text: "GUASCA",
    provinceCode: "25",
  },
  {
    value: "25324",
    text: "GUATAQUÍ",
    provinceCode: "25",
  },
  {
    value: "25326",
    text: "GUATAVITA",
    provinceCode: "25",
  },
  {
    value: "25290",
    text: "FUSAGASUGÁ",
    provinceCode: "25",
  },
  {
    value: "25335",
    text: "GUAYABETAL",
    provinceCode: "25",
  },
  {
    value: "25339",
    text: "GUTIÉRREZ",
    provinceCode: "25",
  },
  {
    value: "25368",
    text: "JERUSALÉN",
    provinceCode: "25",
  },
  {
    value: "25372",
    text: "JUNÍN",
    provinceCode: "25",
  },
  {
    value: "25377",
    text: "LA CALERA",
    provinceCode: "25",
  },
  {
    value: "25386",
    text: "LA MESA",
    provinceCode: "25",
  },
  {
    value: "25394",
    text: "LA PALMA",
    provinceCode: "25",
  },
  {
    value: "25398",
    text: "LA PEÑA",
    provinceCode: "25",
  },
  {
    value: "25402",
    text: "LA VEGA",
    provinceCode: "25",
  },
  {
    value: "25407",
    text: "LENGUAZAQUE",
    provinceCode: "25",
  },
  {
    value: "25426",
    text: "MACHETA",
    provinceCode: "25",
  },
  {
    value: "25430",
    text: "MADRID",
    provinceCode: "25",
  },
  {
    value: "25436",
    text: "MANTA",
    provinceCode: "25",
  },
  {
    value: "25438",
    text: "MEDINA",
    provinceCode: "25",
  },
  {
    value: "25473",
    text: "MOSQUERA",
    provinceCode: "25",
  },
  {
    value: "25483",
    text: "NARIÑO",
    provinceCode: "25",
  },
  {
    value: "25486",
    text: "NEMOCÓN",
    provinceCode: "25",
  },
  {
    value: "25488",
    text: "NILO",
    provinceCode: "25",
  },
  {
    value: "25489",
    text: "NIMAIMA",
    provinceCode: "25",
  },
  {
    value: "25491",
    text: "NOCAIMA",
    provinceCode: "25",
  },
  {
    value: "25506",
    text: "VENECIA",
    provinceCode: "25",
  },
  {
    value: "25513",
    text: "PACHO",
    provinceCode: "25",
  },
  {
    value: "25518",
    text: "PAIME",
    provinceCode: "25",
  },
  {
    value: "25524",
    text: "PANDI",
    provinceCode: "25",
  },
  {
    value: "25530",
    text: "PARATEBUENO",
    provinceCode: "25",
  },
  {
    value: "25535",
    text: "PASCA",
    provinceCode: "25",
  },
  {
    value: "25572",
    text: "PUERTO SALGAR",
    provinceCode: "25",
  },
  {
    value: "25580",
    text: "PULÍ",
    provinceCode: "25",
  },
  {
    value: "25592",
    text: "QUEBRADANEGRA",
    provinceCode: "25",
  },
  {
    value: "25594",
    text: "QUETAME",
    provinceCode: "25",
  },
  {
    value: "25596",
    text: "QUIPILE",
    provinceCode: "25",
  },
  {
    value: "25599",
    text: "APULO",
    provinceCode: "25",
  },
  {
    value: "25612",
    text: "RICAURTE",
    provinceCode: "25",
  },
  {
    value: "13894",
    text: "ZAMBRANO",
    provinceCode: "13",
  },
  {
    value: "25649",
    text: "SAN BERNARDO",
    provinceCode: "25",
  },
  {
    value: "25653",
    text: "SAN CAYETANO",
    provinceCode: "25",
  },
  {
    value: "25658",
    text: "SAN FRANCISCO",
    provinceCode: "25",
  },
  {
    value: "15403",
    text: "LA UVITA",
    provinceCode: "15",
  },
  {
    value: "25899",
    text: "ZIPAQUIRÁ",
    provinceCode: "25",
  },
  {
    value: "25736",
    text: "SESQUILÉ",
    provinceCode: "25",
  },
  {
    value: "25740",
    text: "SIBATÉ",
    provinceCode: "25",
  },
  {
    value: "25743",
    text: "SILVANIA",
    provinceCode: "25",
  },
  {
    value: "25745",
    text: "SIMIJACA",
    provinceCode: "25",
  },
  {
    value: "25754",
    text: "SOACHA",
    provinceCode: "25",
  },
  {
    value: "25769",
    text: "SUBACHOQUE",
    provinceCode: "25",
  },
  {
    value: "25772",
    text: "SUESCA",
    provinceCode: "25",
  },
  {
    value: "25777",
    text: "SUPATÁ",
    provinceCode: "25",
  },
  {
    value: "25779",
    text: "SUSA",
    provinceCode: "25",
  },
  {
    value: "25781",
    text: "SUTATAUSA",
    provinceCode: "25",
  },
  {
    value: "25785",
    text: "TABIO",
    provinceCode: "25",
  },
  {
    value: "63302",
    text: "GÉNOVA",
    provinceCode: "63",
  },
  {
    value: "25793",
    text: "TAUSA",
    provinceCode: "25",
  },
  {
    value: "25797",
    text: "TENA",
    provinceCode: "25",
  },
  {
    value: "25799",
    text: "TENJO",
    provinceCode: "25",
  },
  {
    value: "25805",
    text: "TIBACUY",
    provinceCode: "25",
  },
  {
    value: "25807",
    text: "TIBIRITA",
    provinceCode: "25",
  },
  {
    value: "25815",
    text: "TOCAIMA",
    provinceCode: "25",
  },
  {
    value: "25817",
    text: "TOCANCIPÁ",
    provinceCode: "25",
  },
  {
    value: "25823",
    text: "TOPAIPÍ",
    provinceCode: "25",
  },
  {
    value: "25839",
    text: "UBALÁ",
    provinceCode: "25",
  },
  {
    value: "25841",
    text: "UBAQUE",
    provinceCode: "25",
  },
  {
    value: "73770",
    text: "SUÁREZ",
    provinceCode: "73",
  },
  {
    value: "25845",
    text: "UNE",
    provinceCode: "25",
  },
  {
    value: "25851",
    text: "ÚTICA",
    provinceCode: "25",
  },
  {
    value: "50150",
    text: "CASTILLA LA NUEVA",
    provinceCode: "50",
  },
  {
    value: "25867",
    text: "VIANÍ",
    provinceCode: "25",
  },
  {
    value: "25871",
    text: "VILLAGÓMEZ",
    provinceCode: "25",
  },
  {
    value: "25873",
    text: "VILLAPINZÓN",
    provinceCode: "25",
  },
  {
    value: "25875",
    text: "VILLETA",
    provinceCode: "25",
  },
  {
    value: "25878",
    text: "VIOTÁ",
    provinceCode: "25",
  },
  {
    value: "25898",
    text: "ZIPACÓN",
    provinceCode: "25",
  },
  {
    value: "27001",
    text: "QUIBDÓ",
    provinceCode: "27",
  },
  {
    value: "27006",
    text: "ACANDÍ",
    provinceCode: "27",
  },
  {
    value: "27025",
    text: "ALTO BAUDO",
    provinceCode: "27",
  },
  {
    value: "27050",
    text: "ATRATO",
    provinceCode: "27",
  },
  {
    value: "27073",
    text: "BAGADÓ",
    provinceCode: "27",
  },
  {
    value: "27075",
    text: "BAHÍA SOLANO",
    provinceCode: "27",
  },
  {
    value: "27077",
    text: "BAJO BAUDÓ",
    provinceCode: "27",
  },
  {
    value: "15087",
    text: "BELÉN",
    provinceCode: "15",
  },
  {
    value: "27099",
    text: "BOJAYA",
    provinceCode: "27",
  },
  {
    value: "27810",
    text: "UNIÓN PAtextRICANA",
    provinceCode: "27",
  },
  {
    value: "47570",
    text: "PUEBLO VIEJO",
    provinceCode: "47",
  },
  {
    value: "27160",
    text: "CÉRTEGUI",
    provinceCode: "27",
  },
  {
    value: "27205",
    text: "CONDOTO",
    provinceCode: "27",
  },
  {
    value: "86885",
    text: "VILLAGARZÓN",
    provinceCode: "86",
  },
  {
    value: "25269",
    text: "FACATATIVÁ",
    provinceCode: "25",
  },
  {
    value: "27372",
    text: "JURADÓ",
    provinceCode: "27",
  },
  {
    value: "27413",
    text: "LLORÓ",
    provinceCode: "27",
  },
  {
    value: "27425",
    text: "MEDIO ATRATO",
    provinceCode: "27",
  },
  {
    value: "27430",
    text: "MEDIO BAUDÓ",
    provinceCode: "27",
  },
  {
    value: "27450",
    text: "MEDIO SAN JUAN",
    provinceCode: "27",
  },
  {
    value: "27491",
    text: "NÓVITA",
    provinceCode: "27",
  },
  {
    value: "27495",
    text: "NUQUÍ",
    provinceCode: "27",
  },
  {
    value: "27580",
    text: "RÍO IRO",
    provinceCode: "27",
  },
  {
    value: "27600",
    text: "RÍO QUITO",
    provinceCode: "27",
  },
  {
    value: "27615",
    text: "RIOSUCIO",
    provinceCode: "27",
  },
  {
    value: "23580",
    text: "PUERTO LIBERTADOR",
    provinceCode: "23",
  },
  {
    value: "27745",
    text: "SIPÍ",
    provinceCode: "27",
  },
  {
    value: "27800",
    text: "UNGUÍA",
    provinceCode: "27",
  },
  {
    value: "41001",
    text: "NEIVA",
    provinceCode: "41",
  },
  {
    value: "41006",
    text: "ACEVEDO",
    provinceCode: "41",
  },
  {
    value: "41013",
    text: "AGRADO",
    provinceCode: "41",
  },
  {
    value: "41016",
    text: "AIPE",
    provinceCode: "41",
  },
  {
    value: "41020",
    text: "ALGECIRAS",
    provinceCode: "41",
  },
  {
    value: "41026",
    text: "ALTAMIRA",
    provinceCode: "41",
  },
  {
    value: "41078",
    text: "BARAYA",
    provinceCode: "41",
  },
  {
    value: "41132",
    text: "CAMPOALEGRE",
    provinceCode: "41",
  },
  {
    value: "41206",
    text: "COLOMBIA",
    provinceCode: "41",
  },
  {
    value: "41244",
    text: "ELÍAS",
    provinceCode: "41",
  },
  {
    value: "41298",
    text: "GARZÓN",
    provinceCode: "41",
  },
  {
    value: "41306",
    text: "GIGANTE",
    provinceCode: "41",
  },
  {
    value: "41319",
    text: "GUADALUPE",
    provinceCode: "41",
  },
  {
    value: "41349",
    text: "HOBO",
    provinceCode: "41",
  },
  {
    value: "41357",
    text: "IQUIRA",
    provinceCode: "41",
  },
  {
    value: "41359",
    text: "ISNOS",
    provinceCode: "41",
  },
  {
    value: "41378",
    text: "LA ARGENTINA",
    provinceCode: "41",
  },
  {
    value: "41396",
    text: "LA PLATA",
    provinceCode: "41",
  },
  {
    value: "17444",
    text: "MARQUETALIA",
    provinceCode: "17",
  },
  {
    value: "41483",
    text: "NÁTAGA",
    provinceCode: "41",
  },
  {
    value: "41503",
    text: "OPORAPA",
    provinceCode: "41",
  },
  {
    value: "41518",
    text: "PAICOL",
    provinceCode: "41",
  },
  {
    value: "41524",
    text: "PALERMO",
    provinceCode: "41",
  },
  {
    value: "41530",
    text: "PALESTINA",
    provinceCode: "41",
  },
  {
    value: "41548",
    text: "PITAL",
    provinceCode: "41",
  },
  {
    value: "41551",
    text: "PITALITO",
    provinceCode: "41",
  },
  {
    value: "41615",
    text: "RIVERA",
    provinceCode: "41",
  },
  {
    value: "41660",
    text: "SALADOBLANCO",
    provinceCode: "41",
  },
  {
    value: "52051",
    text: "ARBOLEDA",
    provinceCode: "52",
  },
  {
    value: "41676",
    text: "SANTA MARÍA",
    provinceCode: "41",
  },
  {
    value: "41770",
    text: "SUAZA",
    provinceCode: "41",
  },
  {
    value: "41791",
    text: "TARQUI",
    provinceCode: "41",
  },
  {
    value: "41797",
    text: "TESALIA",
    provinceCode: "41",
  },
  {
    value: "41799",
    text: "TELLO",
    provinceCode: "41",
  },
  {
    value: "41801",
    text: "TERUEL",
    provinceCode: "41",
  },
  {
    value: "41807",
    text: "TIMANÁ",
    provinceCode: "41",
  },
  {
    value: "41872",
    text: "VILLAVIEJA",
    provinceCode: "41",
  },
  {
    value: "41885",
    text: "YAGUARÁ",
    provinceCode: "41",
  },
  {
    value: "44001",
    text: "RIOHACHA",
    provinceCode: "44",
  },
  {
    value: "44035",
    text: "ALBANIA",
    provinceCode: "44",
  },
  {
    value: "44078",
    text: "BARRANCAS",
    provinceCode: "44",
  },
  {
    value: "44090",
    text: "DIBULA",
    provinceCode: "44",
  },
  {
    value: "44098",
    text: "DISTRACCIÓN",
    provinceCode: "44",
  },
  {
    value: "44110",
    text: "EL MOLINO",
    provinceCode: "44",
  },
  {
    value: "44279",
    text: "FONSECA",
    provinceCode: "44",
  },
  {
    value: "44378",
    text: "HATONUEVO",
    provinceCode: "44",
  },
  {
    value: "44430",
    text: "MAICAO",
    provinceCode: "44",
  },
  {
    value: "44560",
    text: "MANAURE",
    provinceCode: "44",
  },
  {
    value: "44847",
    text: "URIBIA",
    provinceCode: "44",
  },
  {
    value: "44855",
    text: "URUMITA",
    provinceCode: "44",
  },
  {
    value: "44874",
    text: "VILLANUEVA",
    provinceCode: "44",
  },
  {
    value: "47001",
    text: "SANTA MARTA",
    provinceCode: "47",
  },
  {
    value: "47030",
    text: "ALGARROBO",
    provinceCode: "47",
  },
  {
    value: "47053",
    text: "ARACATACA",
    provinceCode: "47",
  },
  {
    value: "47058",
    text: "ARIGUANÍ",
    provinceCode: "47",
  },
  {
    value: "47161",
    text: "CERRO SAN ANTONIO",
    provinceCode: "47",
  },
  {
    value: "47170",
    text: "CHIVOLO",
    provinceCode: "47",
  },
  {
    value: "47205",
    text: "CONCORDIA",
    provinceCode: "47",
  },
  {
    value: "47245",
    text: "EL BANCO",
    provinceCode: "47",
  },
  {
    value: "47258",
    text: "EL PIÑON",
    provinceCode: "47",
  },
  {
    value: "47268",
    text: "EL RETÉN",
    provinceCode: "47",
  },
  {
    value: "47288",
    text: "FUNDACIÓN",
    provinceCode: "47",
  },
  {
    value: "47318",
    text: "GUAMAL",
    provinceCode: "47",
  },
  {
    value: "47460",
    text: "NUEVA GRANADA",
    provinceCode: "47",
  },
  {
    value: "47541",
    text: "PEDRAZA",
    provinceCode: "47",
  },
  {
    value: "47551",
    text: "PIVIJAY",
    provinceCode: "47",
  },
  {
    value: "47555",
    text: "PLATO",
    provinceCode: "47",
  },
  {
    value: "47605",
    text: "REMOLINO",
    provinceCode: "47",
  },
  {
    value: "47675",
    text: "SALAMINA",
    provinceCode: "47",
  },
  {
    value: "47703",
    text: "SAN ZENÓN",
    provinceCode: "47",
  },
  {
    value: "47707",
    text: "SANTA ANA",
    provinceCode: "47",
  },
  {
    value: "47745",
    text: "SITIONUEVO",
    provinceCode: "47",
  },
  {
    value: "47798",
    text: "TENERIFE",
    provinceCode: "47",
  },
  {
    value: "47960",
    text: "ZAPAYÁN",
    provinceCode: "47",
  },
  {
    value: "47980",
    text: "ZONA BANANERA",
    provinceCode: "47",
  },
  {
    value: "50001",
    text: "VILLAVICENCIO",
    provinceCode: "50",
  },
  {
    value: "50006",
    text: "ACACIAS",
    provinceCode: "50",
  },
  {
    value: "50124",
    text: "CABUYARO",
    provinceCode: "50",
  },
  {
    value: "50223",
    text: "CUBARRAL",
    provinceCode: "50",
  },
  {
    value: "50226",
    text: "CUMARAL",
    provinceCode: "50",
  },
  {
    value: "50245",
    text: "EL CALVARIO",
    provinceCode: "50",
  },
  {
    value: "50251",
    text: "EL CASTILLO",
    provinceCode: "50",
  },
  {
    value: "50270",
    text: "EL DORADO",
    provinceCode: "50",
  },
  {
    value: "76109",
    text: "BUENAVENTURA",
    provinceCode: "76",
  },
  {
    value: "50313",
    text: "GRANADA",
    provinceCode: "50",
  },
  {
    value: "50318",
    text: "GUAMAL",
    provinceCode: "50",
  },
  {
    value: "50325",
    text: "MAPIRIPÁN",
    provinceCode: "50",
  },
  {
    value: "50330",
    text: "MESETAS",
    provinceCode: "50",
  },
  {
    value: "50350",
    text: "LA MACARENA",
    provinceCode: "50",
  },
  {
    value: "50370",
    text: "URIBE",
    provinceCode: "50",
  },
  {
    value: "50400",
    text: "LEJANÍAS",
    provinceCode: "50",
  },
  {
    value: "50450",
    text: "PUERTO CONCORDIA",
    provinceCode: "50",
  },
  {
    value: "50568",
    text: "PUERTO GAITÁN",
    provinceCode: "50",
  },
  {
    value: "50573",
    text: "PUERTO LÓPEZ",
    provinceCode: "50",
  },
  {
    value: "50577",
    text: "PUERTO LLERAS",
    provinceCode: "50",
  },
  {
    value: "50590",
    text: "PUERTO RICO",
    provinceCode: "50",
  },
  {
    value: "50606",
    text: "RESTREPO",
    provinceCode: "50",
  },
  {
    value: "47189",
    text: "CIÉNAGA",
    provinceCode: "47",
  },
  {
    value: "08560",
    text: "PONEDERA",
    provinceCode: "08",
  },
  {
    value: "50686",
    text: "SAN JUANITO",
    provinceCode: "50",
  },
  {
    value: "50689",
    text: "SAN MARTÍN",
    provinceCode: "50",
  },
  {
    value: "50711",
    text: "VISTA HERMOSA",
    provinceCode: "50",
  },
  {
    value: "52001",
    text: "PASTO",
    provinceCode: "52",
  },
  {
    value: "52019",
    text: "ALBÁN",
    provinceCode: "52",
  },
  {
    value: "52022",
    text: "ALDANA",
    provinceCode: "52",
  },
  {
    value: "52036",
    text: "ANCUYÁ",
    provinceCode: "52",
  },
  {
    value: "15832",
    text: "TUNUNGUÁ",
    provinceCode: "15",
  },
  {
    value: "52079",
    text: "BARBACOAS",
    provinceCode: "52",
  },
  {
    value: "15476",
    text: "MOTAVITA",
    provinceCode: "15",
  },
  {
    value: "23675",
    text: "SAN BERNARDO DEL VIENTO",
    provinceCode: "23",
  },
  {
    value: "52203",
    text: "COLÓN",
    provinceCode: "52",
  },
  {
    value: "52207",
    text: "CONSACA",
    provinceCode: "52",
  },
  {
    value: "52210",
    text: "CONTADERO",
    provinceCode: "52",
  },
  {
    value: "52215",
    text: "CÓRDOBA",
    provinceCode: "52",
  },
  {
    value: "528001",
    text: "MAGÜI",
    provinceCode: "52",
  },
  {
    value: "862020",
    text: "VALLE DEL GUAMUEZ",
    provinceCode: "86",
  },
  {
    value: "632001",
    text: "CALARCA",
    provinceCode: "63",
  },
  {
    value: "52224",
    text: "CUASPUD",
    provinceCode: "52",
  },
  {
    value: "52227",
    text: "CUMBAL",
    provinceCode: "52",
  },
  {
    value: "52233",
    text: "CUMBITARA",
    provinceCode: "52",
  },
  {
    value: "52250",
    text: "EL CHARCO",
    provinceCode: "52",
  },
  {
    value: "52254",
    text: "EL PEÑOL",
    provinceCode: "52",
  },
  {
    value: "52256",
    text: "EL ROSARIO",
    provinceCode: "52",
  },
  {
    value: "27361",
    text: "ISTMINA",
    provinceCode: "27",
  },
  {
    value: "52260",
    text: "EL TAMBO",
    provinceCode: "52",
  },
  {
    value: "52287",
    text: "FUNES",
    provinceCode: "52",
  },
  {
    value: "52317",
    text: "GUACHUCAL",
    provinceCode: "52",
  },
  {
    value: "52320",
    text: "GUAITARILLA",
    provinceCode: "52",
  },
  {
    value: "52323",
    text: "GUALMATÁN",
    provinceCode: "52",
  },
  {
    value: "52352",
    text: "ILES",
    provinceCode: "52",
  },
  {
    value: "52354",
    text: "IMUÉS",
    provinceCode: "52",
  },
  {
    value: "52356",
    text: "IPIALES",
    provinceCode: "52",
  },
  {
    value: "52378",
    text: "LA CRUZ",
    provinceCode: "52",
  },
  {
    value: "52381",
    text: "LA FLORIDA",
    provinceCode: "52",
  },
  {
    value: "52385",
    text: "LA LLANADA",
    provinceCode: "52",
  },
  {
    value: "52390",
    text: "LA TOLA",
    provinceCode: "52",
  },
  {
    value: "52399",
    text: "LA UNIÓN",
    provinceCode: "52",
  },
  {
    value: "52405",
    text: "LEIVA",
    provinceCode: "52",
  },
  {
    value: "52411",
    text: "LINARES",
    provinceCode: "52",
  },
  {
    value: "52418",
    text: "LOS ANDES",
    provinceCode: "52",
  },
  {
    value: "52427",
    text: "MAGÜÍ",
    provinceCode: "52",
  },
  {
    value: "52435",
    text: "MALLAMA",
    provinceCode: "52",
  },
  {
    value: "52473",
    text: "MOSQUERA",
    provinceCode: "52",
  },
  {
    value: "52480",
    text: "NARIÑO",
    provinceCode: "52",
  },
  {
    value: "52490",
    text: "OLAYA HERRERA",
    provinceCode: "52",
  },
  {
    value: "52506",
    text: "OSPINA",
    provinceCode: "52",
  },
  {
    value: "52520",
    text: "FRANCISCO PIZARRO",
    provinceCode: "52",
  },
  {
    value: "52540",
    text: "POLICARPA",
    provinceCode: "52",
  },
  {
    value: "52560",
    text: "POTOSÍ",
    provinceCode: "52",
  },
  {
    value: "52565",
    text: "PROVIDENCIA",
    provinceCode: "52",
  },
  {
    value: "52573",
    text: "PUERRES",
    provinceCode: "52",
  },
  {
    value: "52585",
    text: "PUPIALES",
    provinceCode: "52",
  },
  {
    value: "52612",
    text: "RICAURTE",
    provinceCode: "52",
  },
  {
    value: "52621",
    text: "ROBERTO PAYÁN",
    provinceCode: "52",
  },
  {
    value: "52678",
    text: "SAMANIEGO",
    provinceCode: "52",
  },
  {
    value: "52683",
    text: "SANDONÁ",
    provinceCode: "52",
  },
  {
    value: "52685",
    text: "SAN BERNARDO",
    provinceCode: "52",
  },
  {
    value: "52687",
    text: "SAN LORENZO",
    provinceCode: "52",
  },
  {
    value: "52693",
    text: "SAN PABLO",
    provinceCode: "52",
  },
  {
    value: "05086",
    text: "BELMIRA",
    provinceCode: "05",
  },
  {
    value: "15189",
    text: "CIÉNEGA",
    provinceCode: "15",
  },
  {
    value: "52696",
    text: "SANTA BÁRBARA",
    provinceCode: "52",
  },
  {
    value: "52720",
    text: "SAPUYES",
    provinceCode: "52",
  },
  {
    value: "52786",
    text: "TAMINANGO",
    provinceCode: "52",
  },
  {
    value: "52788",
    text: "TANGUA",
    provinceCode: "52",
  },
  {
    value: "52699",
    text: "SANTACRUZ",
    provinceCode: "52",
  },
  {
    value: "52838",
    text: "TÚQUERRES",
    provinceCode: "52",
  },
  {
    value: "52885",
    text: "YACUANQUER",
    provinceCode: "52",
  },
  {
    value: "68575",
    text: "PUERTO WILCHES",
    provinceCode: "68",
  },
  {
    value: "68573",
    text: "PUERTO PARRA",
    provinceCode: "68",
  },
  {
    value: "63001",
    text: "ARMENIA",
    provinceCode: "63",
  },
  {
    value: "63111",
    text: "BUENAVISTA",
    provinceCode: "63",
  },
  {
    value: "63190",
    text: "CIRCASIA",
    provinceCode: "63",
  },
  {
    value: "63212",
    text: "Córdoba",
    provinceCode: "63",
  },
  {
    value: "63272",
    text: "FILANDIA",
    provinceCode: "63",
  },
  {
    value: "63401",
    text: "LA TEBAIDA",
    provinceCode: "63",
  },
  {
    value: "63470",
    text: "MONTENEGRO",
    provinceCode: "63",
  },
  {
    value: "63548",
    text: "PIJAO",
    provinceCode: "63",
  },
  {
    value: "63594",
    text: "QUIMBAYA",
    provinceCode: "63",
  },
  {
    value: "63690",
    text: "SALENTO",
    provinceCode: "63",
  },
  {
    value: "66001",
    text: "PEREIRA",
    provinceCode: "66",
  },
  {
    value: "66045",
    text: "APÍA",
    provinceCode: "66",
  },
  {
    value: "66075",
    text: "BALBOA",
    provinceCode: "66",
  },
  {
    value: "66170",
    text: "DOSQUEBRADAS",
    provinceCode: "66",
  },
  {
    value: "66318",
    text: "GUÁTICA",
    provinceCode: "66",
  },
  {
    value: "66383",
    text: "LA CELIA",
    provinceCode: "66",
  },
  {
    value: "66400",
    text: "LA VIRGINIA",
    provinceCode: "66",
  },
  {
    value: "66440",
    text: "MARSELLA",
    provinceCode: "66",
  },
  {
    value: "66456",
    text: "MISTRATÓ",
    provinceCode: "66",
  },
  {
    value: "66572",
    text: "PUEBLO RICO",
    provinceCode: "66",
  },
  {
    value: "66594",
    text: "QUINCHÍA",
    provinceCode: "66",
  },
  {
    value: "66687",
    text: "SANTUARIO",
    provinceCode: "66",
  },
  {
    value: "68001",
    text: "BUCARAMANGA",
    provinceCode: "68",
  },
  {
    value: "68013",
    text: "AGUADA",
    provinceCode: "68",
  },
  {
    value: "68020",
    text: "ALBANIA",
    provinceCode: "68",
  },
  {
    value: "68051",
    text: "ARATOCA",
    provinceCode: "68",
  },
  {
    value: "68077",
    text: "BARBOSA",
    provinceCode: "68",
  },
  {
    value: "68079",
    text: "BARICHARA",
    provinceCode: "68",
  },
  {
    value: "68081",
    text: "BARRANCABERMEJA",
    provinceCode: "68",
  },
  {
    value: "68092",
    text: "BETULIA",
    provinceCode: "68",
  },
  {
    value: "68101",
    text: "BOLÍVAR",
    provinceCode: "68",
  },
  {
    value: "68121",
    text: "CABRERA",
    provinceCode: "68",
  },
  {
    value: "68132",
    text: "CALIFORNIA",
    provinceCode: "68",
  },
  {
    value: "68152",
    text: "CARCASÍ",
    provinceCode: "68",
  },
  {
    value: "68160",
    text: "CEPITÁ",
    provinceCode: "68",
  },
  {
    value: "68162",
    text: "CERRITO",
    provinceCode: "68",
  },
  {
    value: "68167",
    text: "CHARALÁ",
    provinceCode: "68",
  },
  {
    value: "68169",
    text: "CHARTA",
    provinceCode: "68",
  },
  {
    value: "68179",
    text: "CHIPATÁ",
    provinceCode: "68",
  },
  {
    value: "68190",
    text: "CIMITARRA",
    provinceCode: "68",
  },
  {
    value: "68207",
    text: "CONCEPCIÓN",
    provinceCode: "68",
  },
  {
    value: "68209",
    text: "CONFINES",
    provinceCode: "68",
  },
  {
    value: "68211",
    text: "CONTRATACIÓN",
    provinceCode: "68",
  },
  {
    value: "68217",
    text: "COROMORO",
    provinceCode: "68",
  },
  {
    value: "68229",
    text: "CURITÍ",
    provinceCode: "68",
  },
  {
    value: "68245",
    text: "EL GUACAMAYO",
    provinceCode: "68",
  },
  {
    value: "68255",
    text: "EL PLAYÓN",
    provinceCode: "68",
  },
  {
    value: "68264",
    text: "ENCINO",
    provinceCode: "68",
  },
  {
    value: "68266",
    text: "ENCISO",
    provinceCode: "68",
  },
  {
    value: "68271",
    text: "FLORIÁN",
    provinceCode: "68",
  },
  {
    value: "68276",
    text: "FLORIDABLANCA",
    provinceCode: "68",
  },
  {
    value: "68296",
    text: "GALÁN",
    provinceCode: "68",
  },
  {
    value: "68298",
    text: "GAMBITA",
    provinceCode: "68",
  },
  {
    value: "68307",
    text: "GIRÓN",
    provinceCode: "68",
  },
  {
    value: "68318",
    text: "GUACA",
    provinceCode: "68",
  },
  {
    value: "68320",
    text: "GUADALUPE",
    provinceCode: "68",
  },
  {
    value: "68322",
    text: "GUAPOTÁ",
    provinceCode: "68",
  },
  {
    value: "68324",
    text: "GUAVATÁ",
    provinceCode: "68",
  },
  {
    value: "68327",
    text: "GÜEPSA",
    provinceCode: "68",
  },
  {
    value: "68368",
    text: "JESÚS MARÍA",
    provinceCode: "68",
  },
  {
    value: "68370",
    text: "JORDÁN",
    provinceCode: "68",
  },
  {
    value: "68377",
    text: "LA BELLEZA",
    provinceCode: "68",
  },
  {
    value: "68385",
    text: "LANDÁZURI",
    provinceCode: "68",
  },
  {
    value: "68397",
    text: "LA PAZ",
    provinceCode: "68",
  },
  {
    value: "68406",
    text: "LEBRÍJA",
    provinceCode: "68",
  },
  {
    value: "68418",
    text: "LOS SANTOS",
    provinceCode: "68",
  },
  {
    value: "68425",
    text: "MACARAVITA",
    provinceCode: "68",
  },
  {
    value: "68432",
    text: "MÁLAGA",
    provinceCode: "68",
  },
  {
    value: "68444",
    text: "MATANZA",
    provinceCode: "68",
  },
  {
    value: "68464",
    text: "MOGOTES",
    provinceCode: "68",
  },
  {
    value: "68468",
    text: "MOLAGAVITA",
    provinceCode: "68",
  },
  {
    value: "68498",
    text: "OCAMONTE",
    provinceCode: "68",
  },
  {
    value: "68500",
    text: "OIBA",
    provinceCode: "68",
  },
  {
    value: "68502",
    text: "ONZAGA",
    provinceCode: "68",
  },
  {
    value: "68522",
    text: "PALMAR",
    provinceCode: "68",
  },
  {
    value: "68533",
    text: "PÁRAMO",
    provinceCode: "68",
  },
  {
    value: "68547",
    text: "PIEDECUESTA",
    provinceCode: "68",
  },
  {
    value: "68549",
    text: "PINCHOTE",
    provinceCode: "68",
  },
  {
    value: "68572",
    text: "PUENTE NACIONAL",
    provinceCode: "68",
  },
  {
    value: "68615",
    text: "RIONEGRO",
    provinceCode: "68",
  },
  {
    value: "68669",
    text: "SAN ANDRÉS",
    provinceCode: "68",
  },
  {
    value: "68679",
    text: "SAN GIL",
    provinceCode: "68",
  },
  {
    value: "68682",
    text: "SAN JOAQUÍN",
    provinceCode: "68",
  },
  {
    value: "68686",
    text: "SAN MIGUEL",
    provinceCode: "68",
  },
  {
    value: "68705",
    text: "SANTA BÁRBARA",
    provinceCode: "68",
  },
  {
    value: "68745",
    text: "SIMACOTA",
    provinceCode: "68",
  },
  {
    value: "68755",
    text: "SOCORRO",
    provinceCode: "68",
  },
  {
    value: "68770",
    text: "SUAITA",
    provinceCode: "68",
  },
  {
    value: "68773",
    text: "SUCRE",
    provinceCode: "68",
  },
  {
    value: "68780",
    text: "SURATÁ",
    provinceCode: "68",
  },
  {
    value: "733590",
    text: "CARMEN DE APICALÁ",
    provinceCode: "73",
  },
  {
    value: "735580",
    text: "RIOBLANCO",
    provinceCode: "73",
  },
  {
    value: "68820",
    text: "TONA",
    provinceCode: "68",
  },
  {
    value: "68861",
    text: "VÉLEZ",
    provinceCode: "68",
  },
  {
    value: "68867",
    text: "VETAS",
    provinceCode: "68",
  },
  {
    value: "68872",
    text: "VILLANUEVA",
    provinceCode: "68",
  },
  {
    value: "68895",
    text: "ZAPATOCA",
    provinceCode: "68",
  },
  {
    value: "70001",
    text: "SINCELEJO",
    provinceCode: "70",
  },
  {
    value: "528501",
    text: "TUMACO",
    provinceCode: "52",
  },
  {
    value: "702070",
    text: "SINCÉ",
    provinceCode: "70",
  },
  {
    value: "70110",
    text: "BUENAVISTA",
    provinceCode: "70",
  },
  {
    value: "70124",
    text: "CAIMITO",
    provinceCode: "70",
  },
  {
    value: "70204",
    text: "COLOSO",
    provinceCode: "70",
  },
  {
    value: "70221",
    text: "COVEÑAS",
    provinceCode: "70",
  },
  {
    value: "70230",
    text: "CHALÁN",
    provinceCode: "70",
  },
  {
    value: "70233",
    text: "EL ROBLE",
    provinceCode: "70",
  },
  {
    value: "70235",
    text: "GALERAS",
    provinceCode: "70",
  },
  {
    value: "70265",
    text: "GUARANDA",
    provinceCode: "70",
  },
  {
    value: "70400",
    text: "LA UNIÓN",
    provinceCode: "70",
  },
  {
    value: "70418",
    text: "LOS PALMITOS",
    provinceCode: "70",
  },
  {
    value: "70429",
    text: "MAJAGUAL",
    provinceCode: "70",
  },
  {
    value: "70473",
    text: "MORROA",
    provinceCode: "70",
  },
  {
    value: "70508",
    text: "OVEJAS",
    provinceCode: "70",
  },
  {
    value: "70523",
    text: "PALMITO",
    provinceCode: "70",
  },
  {
    value: "70678",
    text: "SAN BENITO ABAD",
    provinceCode: "70",
  },
  {
    value: "70708",
    text: "SAN MARCOS",
    provinceCode: "70",
  },
  {
    value: "70713",
    text: "SAN ONOFRE",
    provinceCode: "70",
  },
  {
    value: "70717",
    text: "SAN PEDRO",
    provinceCode: "70",
  },
  {
    value: "70771",
    text: "Sucre",
    provinceCode: "70",
  },
  {
    value: "70823",
    text: "TOLÚ VIEJO",
    provinceCode: "70",
  },
  {
    value: "73024",
    text: "ALPUJARRA",
    provinceCode: "73",
  },
  {
    value: "73026",
    text: "ALVARADO",
    provinceCode: "73",
  },
  {
    value: "73030",
    text: "AMBALEMA",
    provinceCode: "73",
  },
  {
    value: "73055",
    text: "ARMERO",
    provinceCode: "73",
  },
  {
    value: "73067",
    text: "ATACO",
    provinceCode: "73",
  },
  {
    value: "73124",
    text: "CAJAMARCA",
    provinceCode: "73",
  },
  {
    value: "73168",
    text: "CHAPARRAL",
    provinceCode: "73",
  },
  {
    value: "73200",
    text: "COELLO",
    provinceCode: "73",
  },
  {
    value: "73217",
    text: "COYAIMA",
    provinceCode: "73",
  },
  {
    value: "73226",
    text: "CUNDAY",
    provinceCode: "73",
  },
  {
    value: "73236",
    text: "DOLORES",
    provinceCode: "73",
  },
  {
    value: "73268",
    text: "ESPINAL",
    provinceCode: "73",
  },
  {
    value: "73270",
    text: "FALAN",
    provinceCode: "73",
  },
  {
    value: "73275",
    text: "FLANDES",
    provinceCode: "73",
  },
  {
    value: "73283",
    text: "FRESNO",
    provinceCode: "73",
  },
  {
    value: "73319",
    text: "GUAMO",
    provinceCode: "73",
  },
  {
    value: "73347",
    text: "HERVEO",
    provinceCode: "73",
  },
  {
    value: "73349",
    text: "HONDA",
    provinceCode: "73",
  },
  {
    value: "73352",
    text: "ICONONZO",
    provinceCode: "73",
  },
  {
    value: "73443",
    text: "MARIQUITA",
    provinceCode: "73",
  },
  {
    value: "73449",
    text: "MELGAR",
    provinceCode: "73",
  },
  {
    value: "73461",
    text: "MURILLO",
    provinceCode: "73",
  },
  {
    value: "73483",
    text: "NATAGAIMA",
    provinceCode: "73",
  },
  {
    value: "73504",
    text: "ORTEGA",
    provinceCode: "73",
  },
  {
    value: "73520",
    text: "PALOCABILDO",
    provinceCode: "73",
  },
  {
    value: "73547",
    text: "PIEDRAS",
    provinceCode: "73",
  },
  {
    value: "73555",
    text: "PLANADAS",
    provinceCode: "73",
  },
  {
    value: "73563",
    text: "PRADO",
    provinceCode: "73",
  },
  {
    value: "73585",
    text: "PURIFICACIÓN",
    provinceCode: "73",
  },
  {
    value: "73616",
    text: "RIO BLANCO",
    provinceCode: "73",
  },
  {
    value: "73622",
    text: "RONCESVALLES",
    provinceCode: "73",
  },
  {
    value: "73624",
    text: "ROVIRA",
    provinceCode: "73",
  },
  {
    value: "73671",
    text: "SALDAÑA",
    provinceCode: "73",
  },
  {
    value: "73686",
    text: "SANTA ISABEL",
    provinceCode: "73",
  },
  {
    value: "73861",
    text: "VENADILLO",
    provinceCode: "73",
  },
  {
    value: "73870",
    text: "VILLAHERMOSA",
    provinceCode: "73",
  },
  {
    value: "73873",
    text: "VILLARRICA",
    provinceCode: "73",
  },
  {
    value: "81065",
    text: "ARAUQUITA",
    provinceCode: "81",
  },
  {
    value: "81220",
    text: "CRAVO NORTE",
    provinceCode: "81",
  },
  {
    value: "81300",
    text: "FORTUL",
    provinceCode: "81",
  },
  {
    value: "81591",
    text: "PUERTO RONDÓN",
    provinceCode: "81",
  },
  {
    value: "81736",
    text: "SARAVENA",
    provinceCode: "81",
  },
  {
    value: "81794",
    text: "TAME",
    provinceCode: "81",
  },
  {
    value: "81001",
    text: "ARAUCA",
    provinceCode: "81",
  },
  {
    value: "85001",
    text: "YOPAL",
    provinceCode: "85",
  },
  {
    value: "85010",
    text: "AGUAZUL",
    provinceCode: "85",
  },
  {
    value: "85015",
    text: "CHÁMEZA",
    provinceCode: "85",
  },
  {
    value: "85125",
    text: "HATO COROZAL",
    provinceCode: "85",
  },
  {
    value: "85136",
    text: "LA SALINA",
    provinceCode: "85",
  },
  {
    value: "85162",
    text: "MONTERREY",
    provinceCode: "85",
  },
  {
    value: "85263",
    text: "PORE",
    provinceCode: "85",
  },
  {
    value: "85279",
    text: "RECETOR",
    provinceCode: "85",
  },
  {
    value: "85300",
    text: "SABANALARGA",
    provinceCode: "85",
  },
  {
    value: "85315",
    text: "SÁCAMA",
    provinceCode: "85",
  },
  {
    value: "85410",
    text: "TAURAMENA",
    provinceCode: "85",
  },
  {
    value: "85430",
    text: "TRINIDAD",
    provinceCode: "85",
  },
  {
    value: "85440",
    text: "VILLANUEVA",
    provinceCode: "85",
  },
  {
    value: "86001",
    text: "MOCOA",
    provinceCode: "86",
  },
  {
    value: "86219",
    text: "COLÓN",
    provinceCode: "86",
  },
  {
    value: "86320",
    text: "ORITO",
    provinceCode: "86",
  },
  {
    value: "86569",
    text: "PUERTO CAICEDO",
    provinceCode: "86",
  },
  {
    value: "86571",
    text: "PUERTO GUZMÁN",
    provinceCode: "86",
  },
  {
    value: "86573",
    text: "LEGUÍZAMO",
    provinceCode: "86",
  },
  {
    value: "86749",
    text: "SIBUNDOY",
    provinceCode: "86",
  },
  {
    value: "86755",
    text: "SAN FRANCISCO",
    provinceCode: "86",
  },
  {
    value: "86757",
    text: "SAN MIGUEL",
    provinceCode: "86",
  },
  {
    value: "86760",
    text: "SANTIAGO",
    provinceCode: "86",
  },
  {
    value: "91001",
    text: "LETICIA",
    provinceCode: "91",
  },
  {
    value: "91263",
    text: "EL ENCANTO",
    provinceCode: "91",
  },
  {
    value: "91405",
    text: "LA CHORRERA",
    provinceCode: "91",
  },
  {
    value: "91407",
    text: "LA PEDRERA",
    provinceCode: "91",
  },
  {
    value: "91430",
    text: "LA VICTORIA",
    provinceCode: "91",
  },
  {
    value: "91536",
    text: "PUERTO ARICA",
    provinceCode: "91",
  },
  {
    value: "91540",
    text: "PUERTO NARIÑO",
    provinceCode: "91",
  },
  {
    value: "91669",
    text: "PUERTO SANTANDER",
    provinceCode: "91",
  },
  {
    value: "91798",
    text: "TARAPACÁ",
    provinceCode: "91",
  },
  {
    value: "94001",
    text: "INÍRIDA",
    provinceCode: "94",
  },
  {
    value: "94343",
    text: "BARRANCO MINAS",
    provinceCode: "94",
  },
  {
    value: "94663",
    text: "MAPIRIPANA",
    provinceCode: "94",
  },
  {
    value: "94883",
    text: "SAN FELIPE",
    provinceCode: "94",
  },
  {
    value: "94884",
    text: "PUERTO COLOMBIA",
    provinceCode: "94",
  },
  {
    value: "94885",
    text: "LA GUADALUPE",
    provinceCode: "94",
  },
  {
    value: "94886",
    text: "CACAHUAL",
    provinceCode: "94",
  },
  {
    value: "94887",
    text: "PANA PANA",
    provinceCode: "94",
  },
  {
    value: "94888",
    text: "MORICHAL",
    provinceCode: "94",
  },
  {
    value: "97001",
    text: "MITÚ",
    provinceCode: "97",
  },
  {
    value: "97161",
    text: "CARURU",
    provinceCode: "97",
  },
  {
    value: "97511",
    text: "PACOA",
    provinceCode: "97",
  },
  {
    value: "97666",
    text: "TARAIRA",
    provinceCode: "97",
  },
  {
    value: "97777",
    text: "PAPUNAUA",
    provinceCode: "97",
  },
  {
    value: "99001",
    text: "PUERTO CARREÑO",
    provinceCode: "99",
  },
  {
    value: "99524",
    text: "LA PRIMAVERA",
    provinceCode: "99",
  },
  {
    value: "99624",
    text: "SANTA ROSALÍA",
    provinceCode: "99",
  },
  {
    value: "99773",
    text: "CUMARIBO",
    provinceCode: "99",
  },
  {
    value: "18610",
    text: "SAN JOSÉ DEL FRAGUA",
    provinceCode: "18",
  },
  {
    value: "50110",
    text: "BARRANCA DE UPÍA",
    provinceCode: "50",
  },
  {
    value: "68524",
    text: "PALMAS DEL SOCORRO",
    provinceCode: "68",
  },
  {
    value: "25662",
    text: "SAN JUAN DE RÍO SECO",
    provinceCode: "25",
  },
  {
    value: "08372",
    text: "JUAN DE ACOSTA",
    provinceCode: "08",
  },
  {
    value: "50287",
    text: "FUENTE DE ORO",
    provinceCode: "50",
  },
  {
    value: "27250",
    text: "EL LITORAL DEL SAN JUAN",
    provinceCode: "27",
  },
  {
    value: "25843",
    text: "VILLA DE SAN DIEGO DE UBATE",
    provinceCode: "25",
  },
  {
    value: "13074",
    text: "BARRANCO DE LOBA",
    provinceCode: "13",
  },
  {
    value: "15816",
    text: "TOGÜÍ",
    provinceCode: "15",
  },
  {
    value: "13688",
    text: "SANTA ROSA DEL SUR",
    provinceCode: "13",
  },
  {
    value: "27135",
    text: "EL CANTÓN DEL SAN PABLO",
    provinceCode: "27",
  },
  {
    value: "15407",
    text: "VILLA DE LEYVA",
    provinceCode: "15",
  },
  {
    value: "47692",
    text: "SAN SEBASTIÁN DE BUENAVISTA",
    provinceCode: "47",
  },
  {
    value: "15537",
    text: "PAZ DE RÍO",
    provinceCode: "15",
  },
  {
    value: "13300",
    text: "HATILLO DE LOBA",
    provinceCode: "13",
  },
  {
    value: "47660",
    text: "SABANAS DE SAN ANGEL",
    provinceCode: "47",
  },
  {
    value: "95015",
    text: "CALAMAR",
    provinceCode: "95",
  },
  {
    value: "20614",
    text: "RÍO DE ORO",
    provinceCode: "20",
  },
  {
    value: "05665",
    text: "SAN PEDRO DE URABA",
    provinceCode: "05",
  },
  {
    value: "95001",
    text: "SAN JOSÉ DEL GUAVIARE",
    provinceCode: "95",
  },
  {
    value: "15693",
    text: "SANTA ROSA DE VITERBO",
    provinceCode: "15",
  },
  {
    value: "19698",
    text: "SANTANDER DE QUILICHAO",
    provinceCode: "19",
  },
  {
    value: "95200",
    text: "MIRAFLORES",
    provinceCode: "95",
  },
  {
    value: "05042",
    text: "SANTAFÉ DE ANTIOQUIA",
    provinceCode: "05",
  },
  {
    value: "50680",
    text: "SAN CARLOS DE GUAROA",
    provinceCode: "50",
  },
  {
    value: "08520",
    text: "PALMAR DE VARELA",
    provinceCode: "08",
  },
  {
    value: "05686",
    text: "SANTA ROSA DE OSOS",
    provinceCode: "05",
  },
  {
    value: "05647",
    text: "SAN ANDRÉS DE CUERQUÍA",
    provinceCode: "05",
  },
  {
    value: "73854",
    text: "VALLE DE SAN JUAN",
    provinceCode: "73",
  },
  {
    value: "68689",
    text: "SAN VICENTE DE CHUCURÍ",
    provinceCode: "68",
  },
  {
    value: "68684",
    text: "SAN JOSÉ DE MIRANDA",
    provinceCode: "68",
  },
  {
    value: "88564",
    text: "PROVIDENCIA",
    provinceCode: "88",
  },
  {
    value: "66682",
    text: "SANTA ROSA DE CABAL",
    provinceCode: "66",
  },
  {
    value: "25328",
    text: "GUAYABAL DE SIQUIMA",
    provinceCode: "25",
  },
  {
    value: "18094",
    text: "BELÉN DE LOS ANDAQUIES",
    provinceCode: "18",
  },
  {
    value: "85250",
    text: "PAZ DE ARIPORO",
    provinceCode: "85",
  },
  {
    value: "68720",
    text: "SANTA HELENA DEL OPÓN",
    provinceCode: "68",
  },
  {
    value: "15681",
    text: "SAN PABLO DE BORBUR",
    provinceCode: "15",
  },
  {
    value: "44420",
    text: "LA JAGUA DEL PILAR",
    provinceCode: "44",
  },
  {
    value: "20400",
    text: "LA JAGUA DE IBIRICO",
    provinceCode: "20",
  },
  {
    value: "70742",
    text: "SAN LUIS DE SINCÉ",
    provinceCode: "70",
  },
  {
    value: "15667",
    text: "SAN LUIS DE GACENO",
    provinceCode: "15",
  },
  {
    value: "13244",
    text: "EL CARMEN DE BOLÍVAR",
    provinceCode: "13",
  },
  {
    value: "27245",
    text: "EL CARMEN DE ATRATO",
    provinceCode: "27",
  },
  {
    value: "70702",
    text: "SAN JUAN DE BETULIA",
    provinceCode: "70",
  },
  {
    value: "47545",
    text: "PIJIÑO DEL CARMEN",
    provinceCode: "47",
  },
  {
    value: "05873",
    text: "VIGÍA DEL FUERTE",
    provinceCode: "05",
  },
  {
    value: "13667",
    text: "SAN MARTÍN DE LOBA",
    provinceCode: "13",
  },
  {
    value: "13030",
    text: "ALTOS DEL ROSARIO",
    provinceCode: "13",
  },
  {
    value: "73148",
    text: "CARMEN DE APICALA",
    provinceCode: "73",
  },
  {
    value: "25645",
    text: "SAN ANTONIO DEL TEQUENDAMA",
    provinceCode: "25",
  },
  {
    value: "68655",
    text: "SABANA DE TORRES",
    provinceCode: "68",
  },
  {
    value: "95025",
    text: "EL RETORNO",
    provinceCode: "95",
  },
  {
    value: "23682",
    text: "SAN JOSÉ DE URÉ",
    provinceCode: "23",
  },
  {
    value: "52694",
    text: "SAN PEDRO DE CARTAGO",
    provinceCode: "52",
  },
  {
    value: "08137",
    text: "CAMPO DE LA CRUZ",
    provinceCode: "08",
  },
  {
    value: "50683",
    text: "SAN JUAN DE ARAMA",
    provinceCode: "50",
  },
  {
    value: "05658",
    text: "SAN JOSÉ DE LA MONTAÑA",
    provinceCode: "05",
  },
  {
    value: "18150",
    text: "CARTAGENA DEL CHAIRÁ",
    provinceCode: "18",
  },
  {
    value: "27660",
    text: "SAN JOSÉ DEL PALMAR",
    provinceCode: "27",
  },
  {
    value: "25001",
    text: "AGUA DE DIOS",
    provinceCode: "25",
  },
  {
    value: "13655",
    text: "SAN JACINTO DEL CAUCA",
    provinceCode: "13",
  },
  {
    value: "41668",
    text: "SAN AGUSTÍN",
    provinceCode: "41",
  },
  {
    value: "52258",
    text: "EL TABLÓN DE GÓMEZ",
    provinceCode: "52",
  },
  {
    value: "88001",
    text: "SAN ANDRÉS",
    provinceCode: "88",
  },
  {
    value: "15664",
    text: "SAN JOSÉ DE PARE",
    provinceCode: "15",
  },
  {
    value: "86865",
    text: "VALLE DE GUAMEZ",
    provinceCode: "86",
  },
  {
    value: "13670",
    text: "SAN PABLO",
    provinceCode: "13",
  },
  {
    value: "70820",
    text: "SANTIAGO DE TOLÚ",
    provinceCode: "70",
  },
  {
    value: "25154",
    text: "CARMEN DE CARUPA",
    provinceCode: "25",
  },
  {
    value: "23189",
    text: "CIÉNAGA DE ORO",
    provinceCode: "23",
  },
  {
    value: "05659",
    text: "SAN JUAN DE URABÁ",
    provinceCode: "05",
  },
  {
    value: "44650",
    text: "SAN JUAN DEL CESAR",
    provinceCode: "44",
  },
  {
    value: "68235",
    text: "EL CARMEN DE CHUCURÍ",
    provinceCode: "68",
  },
  {
    value: "05148",
    text: "EL CARMEN DE VIBORAL",
    provinceCode: "05",
  },
  {
    value: "66088",
    text: "BELÉN DE UMBRÍA",
    provinceCode: "66",
  },
  {
    value: "27086",
    text: "BELÉN DE BAJIRÁ",
    provinceCode: "27",
  },
  {
    value: "68855",
    text: "VALLE DE SAN JOSÉ",
    provinceCode: "68",
  },
  {
    value: "73678",
    text: "SAN LUIS",
    provinceCode: "73",
  },
  {
    value: "15676",
    text: "SAN MIGUEL DE SEMA",
    provinceCode: "15",
  },
  {
    value: "73675",
    text: "SAN ANTONIO",
    provinceCode: "73",
  },
  {
    value: "68673",
    text: "SAN BENITO",
    provinceCode: "68",
  },
  {
    value: "25862",
    text: "VERGARA",
    provinceCode: "25",
  },
  {
    value: "23678",
    text: "SAN CARLOS",
    provinceCode: "23",
  },
  {
    value: "91530",
    text: "PUERTO ALEGRÍA",
    provinceCode: "91",
  },
  {
    value: "68344",
    text: "HATO",
    provinceCode: "68",
  },
  {
    value: "13654",
    text: "SAN JACINTO",
    provinceCode: "13",
  },
  {
    value: "19693",
    text: "SAN SEBASTIÁN",
    provinceCode: "19",
  },
  {
    value: "05649",
    text: "SAN CARLOS",
    provinceCode: "05",
  },
  {
    value: "15837",
    text: "TUTA",
    provinceCode: "15",
  },
  {
    value: "54743",
    text: "SILOS",
    provinceCode: "54",
  },
  {
    value: "54125",
    text: "CÁCOTA",
    provinceCode: "54",
  },
  {
    value: "76250",
    text: "EL DOVIO",
    provinceCode: "76",
  },
  {
    value: "54820",
    text: "TOLEDO",
    provinceCode: "54",
  },
  {
    value: "76622",
    text: "ROLDANILLO",
    provinceCode: "76",
  },
  {
    value: "54480",
    text: "MUTISCUA",
    provinceCode: "54",
  },
  {
    value: "76054",
    text: "ARGELIA",
    provinceCode: "76",
  },
  {
    value: "54261",
    text: "EL ZULIA",
    provinceCode: "54",
  },
  {
    value: "54660",
    text: "SALAZAR",
    provinceCode: "54",
  },
  {
    value: "76736",
    text: "SEVILLA",
    provinceCode: "76",
  },
  {
    value: "76895",
    text: "ZARZAL",
    provinceCode: "76",
  },
  {
    value: "54223",
    text: "CUCUTILLA",
    provinceCode: "54",
  },
  {
    value: "76248",
    text: "EL CERRITO",
    provinceCode: "76",
  },
  {
    value: "76147",
    text: "CARTAGO",
    provinceCode: "76",
  },
  {
    value: "76122",
    text: "CAICEDONIA",
    provinceCode: "76",
  },
  {
    value: "54553",
    text: "PUERTO SANTANDER",
    provinceCode: "54",
  },
  {
    value: "54313",
    text: "GRAMALOTE",
    provinceCode: "54",
  },
  {
    value: "76246",
    text: "EL CAIRO",
    provinceCode: "76",
  },
  {
    value: "54250",
    text: "EL TARRA",
    provinceCode: "54",
  },
  {
    value: "76400",
    text: "LA UNIÓN",
    provinceCode: "76",
  },
  {
    value: "76606",
    text: "RESTREPO",
    provinceCode: "76",
  },
  {
    value: "54800",
    text: "TEORAMA",
    provinceCode: "54",
  },
  {
    value: "76233",
    text: "DAGUA",
    provinceCode: "76",
  },
  {
    value: "54051",
    text: "ARBOLEDAS",
    provinceCode: "54",
  },
  {
    value: "76318",
    text: "GUACARÍ",
    provinceCode: "76",
  },
  {
    value: "54418",
    text: "LOURDES",
    provinceCode: "54",
  },
  {
    value: "76041",
    text: "ANSERMANUEVO",
    provinceCode: "76",
  },
  {
    value: "54099",
    text: "BOCHALEMA",
    provinceCode: "54",
  },
  {
    value: "76113",
    text: "BUGALAGRANDE",
    provinceCode: "76",
  },
  {
    value: "54206",
    text: "CONVENCIÓN",
    provinceCode: "54",
  },
  {
    value: "54344",
    text: "HACARÍ",
    provinceCode: "54",
  },
  {
    value: "76403",
    text: "LA VICTORIA",
    provinceCode: "76",
  },
  {
    value: "54347",
    text: "HERRÁN",
    provinceCode: "54",
  },
  {
    value: "76306",
    text: "GINEBRA",
    provinceCode: "76",
  },
  {
    value: "76892",
    text: "YUMBO",
    provinceCode: "76",
  },
  {
    value: "76497",
    text: "OBANDO",
    provinceCode: "76",
  },
  {
    value: "54810",
    text: "TIBÚ",
    provinceCode: "54",
  },
  {
    value: "54673",
    text: "SAN CAYETANO",
    provinceCode: "54",
  },
  {
    value: "54670",
    text: "SAN CALIXTO",
    provinceCode: "54",
  },
  {
    value: "76100",
    text: "BOLÍVAR",
    provinceCode: "76",
  },
  {
    value: "54398",
    text: "LA PLAYA",
    provinceCode: "54",
  },
  {
    value: "76001",
    text: "CALI",
    provinceCode: "76",
  },
  {
    value: "76670",
    text: "SAN PEDRO",
    provinceCode: "76",
  },
  {
    value: "76111",
    text: "GUADALAJARA DE BUGA",
    provinceCode: "76",
  },
  {
    value: "54172",
    text: "CHINÁCOTA",
    provinceCode: "54",
  },
  {
    value: "54599",
    text: "RAGONVALIA",
    provinceCode: "54",
  },
  {
    value: "54385",
    text: "LA ESPERANZA",
    provinceCode: "54",
  },
  {
    value: "54874",
    text: "VILLA DEL ROSARIO",
    provinceCode: "54",
  },
  {
    value: "54174",
    text: "CHITAGÁ",
    provinceCode: "54",
  },
  {
    value: "76126",
    text: "CALIMA",
    provinceCode: "76",
  },
  {
    value: "54720",
    text: "SARDINATA",
    provinceCode: "54",
  },
  {
    value: "76036",
    text: "ANDALUCÍA",
    provinceCode: "76",
  },
  {
    value: "76563",
    text: "PRADERA",
    provinceCode: "76",
  },
  {
    value: "54003",
    text: "ABREGO",
    provinceCode: "54",
  },
  {
    value: "54405",
    text: "LOS PATIOS",
    provinceCode: "54",
  },
  {
    value: "54498",
    text: "OCAÑA",
    provinceCode: "54",
  },
  {
    value: "54109",
    text: "BUCARASICA",
    provinceCode: "54",
  },
  {
    value: "76890",
    text: "YOTOCO",
    provinceCode: "76",
  },
  {
    value: "76520",
    text: "PALMIRA",
    provinceCode: "76",
  },
  {
    value: "76616",
    text: "RIOFRÍO",
    provinceCode: "76",
  },
  {
    value: "54680",
    text: "SANTIAGO",
    provinceCode: "54",
  },
  {
    value: "76020",
    text: "ALCALÁ",
    provinceCode: "76",
  },
  {
    value: "76863",
    text: "VERSALLES",
    provinceCode: "76",
  },
  {
    value: "54377",
    text: "LABATECA",
    provinceCode: "54",
  },
  {
    value: "54128",
    text: "CACHIRÁ",
    provinceCode: "54",
  },
  {
    value: "54871",
    text: "VILLA CARO",
    provinceCode: "54",
  },
  {
    value: "54239",
    text: "DURANIA",
    provinceCode: "54",
  },
  {
    value: "76243",
    text: "EL ÁGUILA",
    provinceCode: "76",
  },
  {
    value: "76823",
    text: "TORO",
    provinceCode: "76",
  },
  {
    value: "76130",
    text: "CANDELARIA",
    provinceCode: "76",
  },
  {
    value: "76377",
    text: "LA CUMBRE",
    provinceCode: "76",
  },
  {
    value: "76845",
    text: "ULLOA",
    provinceCode: "76",
  },
  {
    value: "76828",
    text: "TRUJILLO",
    provinceCode: "76",
  },
  {
    value: "76869",
    text: "VIJES",
    provinceCode: "76",
  },
  {
    value: "053840",
    text: "GUATAPE",
    provinceCode: "05",
  },
  {
    value: "054820",
    text: "SONSON",
    provinceCode: "05",
  },
];
