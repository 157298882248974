import { makeStyles, Theme } from "@material-ui/core";

export const useStylesMerchantMigration = makeStyles((theme: Theme) => ({
  content: {
    padding: "24px !important",
  },
  contentBox: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: "#F7FAFC",
  },
  titles: {
    color: "#293036",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19.2px",
    marginBottom: "16px",
  },
  downloadButton: {
    fontSize: "16px",
    marginTop: "4px !important",
    height: "36px",
    paddingInline: "32px !important",
    paddingBlock: "10px !important",
  },
  boxText: {
    fontSize: "12px",
    textAlign: "justify",
    marginBottom: "16px",
    paddingRight: "16px",
  },
  boxTitle: {
    marginBottom: "34px",
    height: "52px !important",
  },
  ul: {
    color: "#6D7781",
    paddingLeft: "22px",
  },
}));
