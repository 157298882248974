import { useEffect, useState } from "react";
import { SubmitHandler, useForm, UseFormMethods } from "react-hook-form";
import { Merchants } from "../../../../types/merchants";
import { SiftScienceCredentials } from "../../../../types/siftscience_credentials";
import {
  LabelField,
  SiftScienceNotificationResultEnum,
} from "../../../shared/infrastructure/constants/SiftScienceConstants";
import { ICredentialsRequest } from "../../../shared/infrastructure/interfaces/ICredentialsRequest";
import { ISiftScienceCredentialsForm } from "../../../shared/infrastructure/interfaces/ISiftScienceCredentialsForm";
import { SiftScienceProps } from "../SiftScience";
import { get, isEmpty } from "lodash";
import { MigrateSiftScienceResponse } from "../../../../types/migrate_siftscience_response";

export interface SiftScienceState {
  form: UseFormMethods<ISiftScienceCredentialsForm>;
  data: {
    merchants: Merchants;
    queryMerchants: Merchants;
    credentials: SiftScienceCredentials;
  };
  actions: {
    handleSetQuery: (query: string) => void;
    handleSearchBar: (query: string) => void;
    handleClick: (item: object) => void;
    resetCredentialsForm: (credentials: SiftScienceCredentials) => void;
  };
  dialog: {
    openDialog: boolean;
    openDialogSave: boolean;
    handleCloseDialog: () => void;
    handleOpenDialog: () => void;
    handleClickDialog: () => void;
    handleOpenDialogSave: () => void;
    handleClickDialogSave: () => void;
    handleCloseDialogSave: () => void;
    handleSubmitForm: SubmitHandler<ISiftScienceCredentialsForm>;
  };
  credentials: {
    isMigrated?: boolean;
    handleClickMigrated: () => void;
  };

  values: {
    queryValue: string;
  };
  disabled?: boolean;
  handler: {
    handleCloseNotification: () => void;
  };
}

export const useSiftScienceState = (
  props: SiftScienceProps
): SiftScienceState => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openDialogSave, setOpenDialogSave] = useState<boolean>(false);
  const [currentMerchantId, setCurrentMerchantId] = useState<string>("");
  const [queryMerchant, setQueryMerchant] = useState<string>("");
  const [merchantId, setMerchantId] = useState<string>("");
  const [migrateSiftScienceResponse, setMigrateSiftScienceResponse] =
    useState<MigrateSiftScienceResponse | undefined>(undefined);

  const form = useForm<ISiftScienceCredentialsForm>({
    mode: "all",
    defaultValues: {
      sandboxApiKey: "",
      prodApiKey: "",
      sandboxAccountId: "",
      prodAccountId: "",
      baconProdApiKey: "",
      baconSandboxApiKey: "",
    },
  });

  const handleClick = (item: object) => {
    const merchantBasicInformation = {
      country: get(item, "country", ""),
      name: get(item, "name", ""),
      publicMerchantId: get(item, "publicMerchantId", ""),
    };

    setCurrentMerchantId(merchantBasicInformation.publicMerchantId);
    handleVisible();
    handleSearchCredentials(merchantBasicInformation.publicMerchantId);
  };

  const handleSetQuery = (query: string) => {
    setQueryMerchant(query);
  };
  const handleSearchBar = (value: string) => {
    if (value !== "Enter") return;
    props.getMerchants({
      offset: 0,
      pageSize: 6,
      text: queryMerchant,
    });
  };

  const handleCloseNotification = (): void => {
    props.setNotification({
      type: props.notification!.type!,
      open: false,
      message: "",
      action: SiftScienceNotificationResultEnum.NO_ACTION,
    });
  };

  const handleSearchCredentials = (value: string) => {
    props.getCredentials(value);
    setMerchantId(value);
  };

  const handleVisible = () => {
    props.setIsVisible(true);
  };

  const handleClickMigrated = () => {
    props.setIsMigrated(true);
    props.setDisabled(false);
    props.setShowButtonMigrated(false);
    props.migrateSiftScience(merchantId);
  };

  useEffect(() => {
    if (props.migrateSiftScienceResponse) {
      setMigrateSiftScienceResponse(props.migrateSiftScienceResponse);
    }
  }, [props.migrateSiftScienceResponse]);

  useEffect(() => {
    if (migrateSiftScienceResponse) {
      const apiKeyP: string = get(
        migrateSiftScienceResponse,
        "siftScience.prodApiKey",
        ""
      );
      const apiKeyS: string = get(
        migrateSiftScienceResponse,
        "siftScience.sandboxApiKey",
        ""
      );
      const accountIdP: string = get(
        migrateSiftScienceResponse,
        "siftScience.prodAccountId",
        ""
      );
      const accountIdS: string = get(
        migrateSiftScienceResponse,
        "siftScience.sandboxAccountId",
        ""
      );

      const baconApiKeyP: string = get(
        migrateSiftScienceResponse,
        "siftScience.baconProdApiKey",
        ""
      );
      const baconApiKeyS: string = get(
        migrateSiftScienceResponse,
        "siftScience.baconSandboxApiKey",
        ""
      );

      form.setValue("prodApiKey", apiKeyP);
      form.setValue("sandboxApiKey", apiKeyS);
      form.setValue("prodAccountId", accountIdP);
      form.setValue("sandboxAccountId", accountIdS);
      form.setValue("baconProdApiKey", baconApiKeyP);
      form.setValue("baconSandboxApiKey", baconApiKeyS);
    }
  }, [migrateSiftScienceResponse]);

  const handleOpenDialogSave = () => {
    setOpenDialogSave(true);
  };

  const handleCloseDialogSave = () => {
    setOpenDialogSave(false);
  };

  const handleSubmitForm: SubmitHandler<ISiftScienceCredentialsForm> = async (
    formData
  ) => {
    const credentials: ICredentialsRequest = {
      merchantId: currentMerchantId,
      accountId: get(formData, "prodAccountId"),
      accountIdSandbox: isEmpty(get(formData, "sandboxAccountId"))
        ? LabelField.BLANK_SPACES
        : get(formData, "sandboxAccountId"),
      apiKey: get(formData, "prodApiKey"),
      apiKeySandbox: isEmpty(get(formData, "sandboxApiKey"))
        ? LabelField.BLANK_SPACES
        : get(formData, "sandboxApiKey"),
      baconApiKey: get(formData, "baconProdApiKey"),
      baconApiKeySandbox: isEmpty(get(formData, "baconSandboxApiKey"))
        ? LabelField.BLANK_SPACES
        : get(formData, "baconSandboxApiKey"),
    };

    props.createCredentials(credentials, "/monitoring");
    handleCloseDialogSave();
  };

  const handleClickDialogSave = () => {
    handleCloseDialogSave();
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickDialog = () => {
    handleCloseDialog();
    window.location.href = "/monitoring";
  };

  const resetCredentialsForm = (credentials: SiftScienceCredentials) => {
    const formObject: object = {
      prodAccountId: get(credentials, "siftCredentials.prodAccountId"),
      sandboxAccountId: get(
        credentials,
        "siftCredentials.sandboxAccountId",
        ""
      ),
      prodApiKey: get(credentials, "siftCredentials.prodApiKey"),
      sandboxApiKey: get(credentials, "siftCredentials.sandboxApiKey", ""),
      baconProdApiKey: get(credentials, "siftCredentials.baconProdApiKey"),
      baconSandboxApiKey: get(
        credentials,
        "siftCredentials.baconSandboxApiKey",
        ""
      ),
    };

    form.reset(formObject);
  };

  useEffect(() => {
    props.getMerchants({
      text: "",
      pageSize: 6,
      offset: 0,
    });
  }, []);

  useEffect(() => {
    if (!props.isVisible) {
      props.setDisabled(true);
    }
  }, [props.disabled]);

  useEffect(() => {
    if (props.showButtonMigrated) {
      props.setDisabled(true);
    }
  }, [props.showButtonMigrated]);

  useEffect(() => {
    if (!isEmpty(props.credentials.siftCredentials)) {
      resetCredentialsForm(props.credentials);
    } else {
      const formObject: object = {
        prodAccountId: "",
        sandboxAccountId: "",
        prodApiKey: "",
        sandboxApiKey: "",
        baconProdApiKey: "",
        baconSandboxApiKey: "",
      };

      form.reset(formObject);
    }
  }, [props.credentials]);

  return {
    form,
    data: {
      merchants: props.merchants,
      queryMerchants: props.queryMerchants,
      credentials: props.credentials,
    },
    actions: {
      handleSetQuery,
      handleSearchBar,
      handleClick,
      resetCredentialsForm,
    },
    dialog: {
      handleClickDialog,
      handleCloseDialog,
      handleOpenDialog,
      openDialog,
      openDialogSave,
      handleOpenDialogSave,
      handleClickDialogSave,
      handleCloseDialogSave,
      handleSubmitForm,
    },
    credentials: {
      isMigrated: props.isMigrated,
      handleClickMigrated,
    },
    values: {
      queryValue: queryMerchant,
    },
    disabled: props.disabled,
    handler: {
      handleCloseNotification,
    },
  };
};
