/**
 * Catalog Colombia
 */
import { Category } from "../../interfaces/category";
import { CatalogsEnum } from "../../enums/CatalogsEnum";
import { CurrencyEnum } from "../../enums/currencyEnum";

export const CatalogCo: Record<string, Category[]> = {
  [CatalogsEnum.Banks]: [
    { name: "Asocajas", value: "086" },
    { name: "Ban Agrario", value: "040" },
    { name: "Ban AVVillas", value: "052" },
    { name: "Ban BBVA", value: "013" },
    { name: "Ban Colmena", value: "057" },
    { name: "Ban GNB Sudameris", value: "012" },
    { name: "Ban Popular", value: "002" },
    { name: "Ban Republica", value: "000" },
    { name: "Ban Scotiabank Colpatria", value: "019" },
    { name: "Ban Occidente", value: "023" },
    { name: "BANCO BTG PACTUAL", value: "805" },
    { name: "BanCajaSocial", value: "032" },
    { name: "Banco Coomeva", value: "061" },
    { name: "Banco Cooperativo Coopcentral", value: "066" },
    { name: "BANCO CREDIFINANCIERA SA.", value: "558" },
    { name: "BANCO DE BOGOTA", value: "001" },
    { name: "Banco de las Microfinanzas-Bancamia S.A.", value: "059" },
    { name: "Banco Falabella S.A.", value: "062" },
    { name: "Banco Finandina S.A.", value: "063" },
    { name: "BANCO J.P. MORGAN COLOMBIA S.A.", value: "071" },
    { name: "Banco Mundo Mujer S.A", value: "047" },
    { name: "Banco Pichincha", value: "060" },
    { name: "Banco Santander de Negocios", value: "065" },
    { name: "Banco Serfinanza S.A.", value: "069" },
    { name: "Banco W S.A.", value: "053" },
    { name: "BanColombia", value: "007" },
    { name: "BNP Paribas Colombia Corp.finan.S.AS", value: "042" },
    { name: "Citibank", value: "009" },
    { name: "COLTEColtefinanciera S.A.FINANCIERA", value: "370" },
    {
      name: "FINANCIERA JURISCOOP S.A. COMPANIA DE FINANCIAMIENTO",
      value: "121",
    },
    { name: "Compensar", value: "083" },
    { name: "Confiar", value: "292" },
    { name: "Coomeva", value: "082" },
    { name: "Coop. Financiera de Antioquia", value: "283" },
    { name: "COOFINEP COOPERATIVA FINANCIERA", value: "291" },
    { name: "Banco Corpbanca Colombia", value: "006" },
    { name: "Cotrafa Cooperativa Financiera", value: "289" },
    { name: "BanDavivienda", value: "051" },
    { name: "DALE", value: "097" },
    { name: "DAVIPLATA", value: "551" },
    { name: "Deceval-DepV", value: "550" },
    { name: "DING Tecnipagos S.A.", value: "802" },
    { name: "Dir.TesoroNal", value: "683" },
    { name: "DGCPTN Sistema General de Regalias", value: "685" },
    { name: "Enlace Operativo", value: "089" },
    { name: "Fedecajas", value: "087" },
    { name: "Final", value: "999" },
    { name: "FOGAFIN", value: "684" },
    { name: "Gestion y Contacto S.A.", value: "084" },
    { name: "GIROS Y FINANZAS S.A.", value: "303" },
    { name: "ITAU", value: "014" },
    { name: "IRIS FINANCIERA DANN REGIONAL", value: "637" },
    { name: "LULO BANK S.A.", value: "070" },
    { name: "MIBANCO S.A.", value: "067" },
    { name: "MINHACIENDA REGALIAS", value: "685" },
    { name: "MOVII", value: "801" },
    { name: "NEQUI", value: "507" },
    { name: "PSE ProSerEle", value: "101" },
    { name: "Rappipay", value: "151" },
    { name: "Servicios Financieros S.A. Serfinansa", value: "342" },
    { name: "Simple S.A.", value: "088" },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { name: "Corriente", value: "0" },
    { name: "Ahorros", value: "1" },
  ],
  [CatalogsEnum.Currency]: [
    { name: `${CurrencyEnum.COP} - Pesos`, value: CurrencyEnum.COP },
  ],
};
