import * as React from "react";
import { SvgIcon as MuiSvgIcon, styled, SvgIconProps } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "@kushki/connect-ui";

// import theme from "../../../Themes/themekushki";

const SvgIcon = styled(MuiSvgIcon, {
  name: "MopeimIcon",
  shouldForwardProp: (prop) => prop !== "fill",
})<SvgIconProps>(({ color, fontSize }) => ({
  fill: color + "!important",
  fontSize: fontSize,
}));

SvgIcon.defaultProps = {
  viewBox: "0 0 24 24",
  focusable: "false",
  "aria-hidden": "true",
};

const IconPlus: React.FunctionComponent<SvgIconProps> = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <SvgIcon
        {...props}
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7.99967 13.9379C7.82286 13.9379 7.65329 13.8256 7.52827 13.6255C7.40325 13.4255 7.33301 13.1542 7.33301 12.8713V4.33794C7.33301 4.05504 7.40325 3.78373 7.52827 3.58369C7.65329 3.38365 7.82286 3.27127 7.99967 3.27127C8.17649 3.27127 8.34605 3.38365 8.47108 3.58369C8.5961 3.78373 8.66634 4.05504 8.66634 4.33794V12.8713C8.66634 13.1542 8.5961 13.4255 8.47108 13.6255C8.34605 13.8256 8.17649 13.9379 7.99967 13.9379Z" />
        <path d="M12.267 9.27129H3.73366C3.45076 9.27129 3.17945 9.20105 2.97941 9.07603C2.77937 8.951 2.66699 8.78144 2.66699 8.60462C2.66699 8.42781 2.77937 8.25824 2.97941 8.13322C3.17945 8.0082 3.45076 7.93796 3.73366 7.93796H12.267C12.5499 7.93796 12.8212 8.0082 13.0212 8.13322C13.2213 8.25824 13.3337 8.42781 13.3337 8.60462C13.3337 8.78144 13.2213 8.951 13.0212 9.07603C12.8212 9.20105 12.5499 9.27129 12.267 9.27129Z" />
      </SvgIcon>
    </ThemeProvider>
  );
};

export default IconPlus;
