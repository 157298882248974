import { useEffect, useState } from "react";
import { PersonTypeEnum } from "../../../../shared/constants/initial_state_legal_details";
import { UseBoardMembersStateBody } from "./useBoardMembersState.interfaces";
import { FieldValues, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { FormItem } from "../../Forms/Generics/BuildableForm";
import { physicalPersonRules } from "../../../../shared/constants/form_labels_legal_details";
import { formComponentBoardMember } from "../../../../shared/utils/formComponent_utils";
import { IBoardMember } from "../../../../store/interfaces/LegalDetailsState.interfaces";
import {
  removeBoardMember,
  setEditedBoardMember,
} from "../../../../store/reducers/legalDetails/legalDetails";

export const useBoardMembersState = (): UseBoardMembersStateBody => {
  const { isEditedBoardMember, boardMembers } = useSelector(
    (state: RootState) => ({
      ...state.legalDetails,
    })
  );
  const dispatch = useDispatch();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [selectedBoardMember, setSelectedBoardMember] =
    useState<IBoardMember>();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const defaultValuesForm = () => {
    if (isEditedBoardMember.value) {
      return boardMembers.find((item) => item.id === isEditedBoardMember.id);
    }

    return {
      lastNames: "",
      names: "",
      personType: PersonTypeEnum.FISICA,
    };
  };

  const { control, watch, handleSubmit, reset } = useForm<FieldValues>({
    defaultValues: defaultValuesForm(),
  });

  const closeModal = () => {
    dispatch(setEditedBoardMember({ id: "", value: false }));
    setIsOpenModal(false);
  };

  const closeDeleteModal = () => {
    setIsOpenDeleteModal(false);
  };

  const deleteBoardMember = () => {
    const filteredBMembers = [
      ...boardMembers.filter((bmItem) => bmItem.id !== selectedBoardMember!.id),
    ];

    dispatch(removeBoardMember(filteredBMembers));
    setSelectedBoardMember(undefined);
  };

  const openDeleteModal = () => {
    setIsOpenDeleteModal(true);
  };

  const openModal = () => {
    if (isEditedBoardMember.value) {
      reset(defaultValuesForm());
    }
    setIsOpenModal(true);
    if (!isEditedBoardMember.value) reset();
  };

  const onClickDeleteConfirm = () => {
    deleteBoardMember!();
    closeDeleteModal!();
  };

  useEffect(() => {
    if (isEditedBoardMember.value) {
      openModal();
    } else {
      reset(defaultValuesForm());
    }
  }, [isEditedBoardMember.value]);

  const personalInformationPhysical: FormItem[] = [
    {
      control,
      inputComponent: "text",
      label: "Nombre(s)",
      md: 6,
      name: "names",
      rules: physicalPersonRules.requiredNameRule,
      xs: 12,
    },
    {
      control,
      inputComponent: "text",
      label: "Apellido(s)",
      md: 6,
      name: "lastNames",
      rules: physicalPersonRules.requiredLastNameRule,
      xs: 12,
    },
    {
      control,
      inputComponent: "date",
      label: "Fecha de nacimiento",
      md: 12,
      name: "birthdate",
      rules: physicalPersonRules.birthdate,
      xs: 12,
    },
    {
      control,
      inputComponent: "countries",
      label: "País de residencia",
      md: 12,
      name: "residencyCountry",
      rules: physicalPersonRules.countryResidence,
      xs: 12,
    },
  ];

  const personalInformationMoral: FormItem[] = [
    {
      control,
      inputComponent: "text",
      label: "Razón social",
      md: 6,
      name: "socialReason",
      rules: physicalPersonRules.requiredSocialReason,
      xs: 12,
    },
    {
      control,
      inputComponent: "countries",
      label: "País de domicilio",
      md: 6,
      name: "addressCountry",
      rules: physicalPersonRules.country,
      xs: 12,
    },
    {
      control,
      inputComponent: "text",
      label: "Número de TAX ID",
      md: 6,
      name: "documentNumber",
      xs: 12,
    },
    {
      control,
      inputComponent: "date",
      label: "Fecha de constitución de la empresa (Opcional)",
      md: 6,
      name: "constitutionDate",
      xs: 12,
    },
  ];

  const identityInformationPhysical: FormItem[] = [
    {
      control,
      inputComponent: "select_bm",
      label: "Tipo de identificación",
      md: 6,
      name: "documentType",
      xs: 12,
    },
    {
      control,
      inputComponent: "text",
      label: "Nro. de identificación",
      md: 6,
      name: "documentNumber",
      xs: 12,
    },
  ];

  const personTypeWatch = watch("personType");

  return {
    closeDeleteModal,
    closeModal,
    control,
    formComponent: formComponentBoardMember(
      closeModal,
      handleSubmit,
      identityInformationPhysical,
      personalInformationMoral,
      personalInformationPhysical,
      reset,
      control
    )[`${personTypeWatch}`],
    handleSubmit,
    isOpenDeleteModal,
    isOpenModal,
    onClickDeleteConfirm,
    openDeleteModal,
    openModal,
    setSelectedBoardMember,
  };
};
