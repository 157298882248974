import { makeStyles } from "@material-ui/core/styles";

export const walletDashboardStyle = makeStyles({
  errorAlert: {
    "& svg": {
      fill: "white",
    },
    backgroundColor: "#EF4444 !important",
    color: "white !important",
    justifyContent: "center",
    left: "calc(50vw - 300px)",
    position: "absolute",
    top: "84px",
    width: "40%",
  },
  mainGrid: {
    backgroundColor: "white",
    borderRadius: "5px",
    margin: "0px",
    marginTop: "20px",
    padding: "0px",
  },
});
