export const ACCEPT_BUTTON_TEXT = "Sí, salir";
export const CANCEL_BUTTON_TEXT = "Cancelar";
export const DESCRIPTION_EDIT_TEXT =
  "Al ejecutar está acción la información que has ingresado hasta ahora se perderá y se mantendrá la información anterior.";
export const TITLE_TEXT =
  "¿Estás seguro que deseas salir sin guardar los cambios?";
export const SUMMARY_BTN_TEXT = "Ver más";
export const FINISH_ACCEPT_BUTTON_TEXT = "Finalizar";
export const FINISH_DESCRIPTION_TEXT =
  "Al ejecutar esta acción, todos los datos ingresados quedarán almacenados correctamente.";
export const FINISH_TITLE_TEXT = "¿Estás seguro que finalizar esta edición?";
