export const CREATE_BRANCHS = "Branches a crear";
export const INVALID_BRANCHS = "Branches inválidos";

export const VARIABLE_ARRAY_VALIDATE_HEADER_MODAL: Array<{
  name: string;
  value: number;
  error?: boolean;
}> = [
  {
    error: false,
    name: CREATE_BRANCHS,
    value: 30,
  },
];

export const VARIABLE_ARRAY_VALIDATE_ERROR_HEADER_MODAL: Array<{
  name: string;
  value: number;
  error?: boolean;
}> = [
  {
    error: false,
    name: CREATE_BRANCHS,
    value: 30,
  },
  {
    error: true,
    name: INVALID_BRANCHS,
    value: 10,
  },
];

export const VARIABLE_ARRAY_VALIDATE_BODY_MODAL: Array<{
  rowNumber: string;
  branchName: string;
  errorMessage: string;
}> = [
  {
    branchName: "WALMART_TIJUANA",
    errorMessage: "No es alfanumerico",
    rowNumber: "Fila: 03",
  },
  {
    branchName: "WALMART",
    errorMessage: "No es alfanumerico",
    rowNumber: "Fila: 16",
  },
  {
    branchName: "WALMART MONTERREY",
    errorMessage: "Duplicado en el listado",
    rowNumber: "Fila: 03",
  },
  {
    branchName: "WALMART MONTERREY",
    errorMessage: "Duplicado en el listado",
    rowNumber: "Fila: 03",
  },
  {
    branchName: "WALMART MONTERREY",
    errorMessage: "Duplicado en el listado",
    rowNumber: "Fila: 03",
  },
  {
    branchName: "WALMART MONTERREY",
    errorMessage: "Duplicado en el listado",
    rowNumber: "Fila: 03",
  },
];
