import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import { StepperEnum } from "../../../shared/infrastructure/StepperEnum";
import { useMobileState } from "../../../shared/hooks/useMobileState/useMobileState";
import { QRInformation } from "../../../components/QRInformation/QRInformation";
import BasicInformation from "../../../components/BasicInformation/BasicInformation";
import { ICheckoutData } from "../../../components/BasicInformation/BasicInformation.interfaces";
import { FormSummary } from "../../../components/FormSummary/FormSummary";
import { useCalculateAmount } from "../../../shared/hooks/useCalculateAmount/useCalculateAmount";
import { LoadingSpinner } from "../../../components/LoadingSpinner/LoadingSpinner";
import { CountryEnum } from "../../../shared/enums/CountryEnum";
import {
  setCreateExpressCheckoutStatus,
  setExpressCheckout,
  setPaymentMethods,
  setPaymentMethodsStatus,
} from "../../../store/actions/app.actions";
import { RequestStatusEnum } from "../../../shared/enums/RequestStatusEnum";
import { useAppDispatch } from "../../../store/hooks/storeHook";

const { createContext, useContext } = React;

export interface IStepperCheckoutContext {
  renderContent: (stepIndex: number) => JSX.Element;
  setStep: (stepIndex: number) => void;
  step: number;
  steps: string[];
  isMobile: boolean;
  iva: number;
  configAmount: {
    calculateIvaAmount: (subtotalIva: number) => string;
    calculateTotalAmount: (subtotalIva: number, subtotalIva0: number) => string;
    getIvaAmountFormat: (subtotalIva: number) => string;
    getTotalAmountFormat: (subtotalIva: number, subtotalIva0: number) => string;
  };
  currency: string;
  country: CountryEnum;
  webcheckoutUrl: string;
  setWebcheckoutUrl: (url: string) => void;
  checkoutData: ICheckoutData;
  setCheckoutData: (data: ICheckoutData) => void;
  setIsLoading: (state: boolean) => void;
  setHideLoadingLabels: (hide: boolean) => void;
}

export const initialCheckoutData: ICheckoutData = {
  description: "",
  email: "",
  ivaAmount: 0,
  name: "",
  subtotalIva: 0,
  subtotalIva0: 0,
  totalAmount: 0,
};

const CheckoutContext = createContext<IStepperCheckoutContext>(undefined!);

export type TStepperPaymentContext = Partial<IStepperCheckoutContext>;

export const CheckoutProvider: FC<TStepperPaymentContext> = (
  props: PropsWithChildren<TStepperPaymentContext>
) => {
  const dispatch = useAppDispatch();
  const [checkoutData, setCheckoutData] =
    useState<ICheckoutData>(initialCheckoutData);
  const [webcheckoutUrl, setWebcheckoutUrl] = useState<string>("");
  const [activeStep, setActiveStep] = useState(0);
  const length = Object.keys(StepperEnum).length;
  const steps = Object.keys(StepperEnum).slice(length / 2, length);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [hideLoadingLabels, setHideLoadingLabels] = React.useState(false);

  const {
    iva,
    merchantInfo: { currency, country },
    calculateIvaAmount,
    calculateTotalAmount,
    getIvaAmountFormat,
    getTotalAmountFormat,
  } = useCalculateAmount({ setHideLoadingLabels, setIsLoading });

  const setStep = (stepIndex: number): void => {
    setActiveStep(stepIndex);
  };

  const { isMobile } = useMobileState();

  const onBasicInformationSubmit = (formData: ICheckoutData) => {
    setCheckoutData(formData);
    setStep(StepperEnum.SUMMARY);
  };

  const renderContent = (stepper: StepperEnum): JSX.Element => {
    return (
      <>
        {stepper === StepperEnum.FORM && (
          <BasicInformation
            onSubmit={onBasicInformationSubmit}
            defaultValues={checkoutData}
          />
        )}
        {stepper === StepperEnum.SUMMARY && <FormSummary />}
        {stepper === StepperEnum.CODE_QR && <QRInformation />}
      </>
    );
  };

  useEffect(() => {
    dispatch(setPaymentMethodsStatus(RequestStatusEnum.PENDING));
    dispatch(setCreateExpressCheckoutStatus(RequestStatusEnum.PENDING));
    dispatch(setPaymentMethods([]));
    dispatch(setExpressCheckout(undefined));

    setWebcheckoutUrl("");
  }, []);

  const value: IStepperCheckoutContext = {
    checkoutData,
    configAmount: {
      calculateIvaAmount,
      calculateTotalAmount,
      getIvaAmountFormat,
      getTotalAmountFormat,
    },
    country: country,
    currency,
    isMobile,
    iva,
    renderContent: props.renderContent || renderContent,
    setCheckoutData,
    setHideLoadingLabels,
    setIsLoading,
    setStep: props.setStep || setStep,
    setWebcheckoutUrl,
    step: props.step || activeStep,
    steps: props.steps || steps,
    webcheckoutUrl,
  };

  return (
    <CheckoutContext.Provider value={value}>
      <LoadingSpinner isLoading={isLoading} hideLabels={hideLoadingLabels} />
      {props.children}
    </CheckoutContext.Provider>
  );
};

export const useCheckoutContext = () => {
  return useContext(CheckoutContext);
};
