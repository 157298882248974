import React, { FC, useState } from "react";
import { TableNodes } from "../../components/Table/TableNodes/TableNodes";
import { HeaderFilters } from "../../components/HeaderFilters/HeaderFilters";
import { EntityNameEnum } from "../../shared/enums/EntityNameEnum";
import { ItemCategoryItem } from "../../components/Filters/ItemCategory/interfaces";
import { CUSTOMER_PER_PAGE } from "../../shared/constants/labels/label_rows_per_page";
import { useDashboard } from "./state/useDashboard";
import EmptyResults from "../EmptyResults/EmptyResults";
import { IDashboardProps } from "./Dashboard.interfaces";
import MassiveStatusActions from "../MassiveStatusActions/MassiveStatusActions";
import ModalSimple from "../ModalSimple/ModalSimple";
import { ModalButtonEnum } from "../../shared/enums/DashboardEnum";
import { CATALOG_HEADS_NODE } from "../../shared/catalogs/CatalogConfigTable";
import { ModalFamily } from "../ModalFamily/ModalFamily";
import { OwnerEnum } from "../../shared/enums/OwnerEnum";

const Dashboard: FC<IDashboardProps> = ({
  showEmptyScreen,
  emptyAddText,
  basicNodeInfo: { entityName, path },
  nodeData,
  modalStatusDashboard: { checkModalStatusText, modalStatusText },
  handleChangeStatus,
  customerNode,
}: IDashboardProps) => {
  const {
    countData,
    handleChangeFilterDetails,
    handleSelectedRows,
    paginationProps: {
      handleChangePage,
      handleChangeRowsPerPage,
      page,
      rowsPerPage,
      totalData,
    },
    showMassiveActions,
    itemsSelected,
    rows,
    setNodeDataSelected,
    modalStatus,
    massiveActions,
    resetFilters,
    resetItemChecked,
    modalFamilyProps,
    showEmptyScreen: showEmptyScreenHook,
  } = useDashboard({
    checkModalStatusText,
    customerNode,
    entityName,
    handleChangeStatus,
    nodeData,
    path,
    showEmptyScreen,
  });

  const [additionalCountries] = useState<ItemCategoryItem[]>([]);

  return (
    <React.Fragment>
      {showEmptyScreenHook && countData < 1 ? (
        <EmptyResults addText={emptyAddText} title={OwnerEnum.NO_DATA} />
      ) : (
        <>
          <HeaderFilters
            entityNameType={entityName as EntityNameEnum}
            additionalCountries={additionalCountries}
            getItemsSelected={handleChangeFilterDetails}
            resetFilters={resetFilters}
          />
          {showMassiveActions && (
            <MassiveStatusActions
              entityName={entityName as EntityNameEnum}
              selectedItems={itemsSelected}
              activeStatusAction={massiveActions.activeStatusAction}
              inactiveStatusAction={massiveActions.inactiveStatusAction}
              isActivateStatus={massiveActions.isActivateStatus}
              showBtnMassiveAction={massiveActions.showBtnMassiveAction}
              isEditBranch={massiveActions.isEditBranch}
              redirectEditBranch={massiveActions.redirectEditBranch}
              isDisabledEditBtn={massiveActions.isDisabledEditBtn}
              isDisabledActiveBtn={massiveActions.isDisabledActiveBtn}
            />
          )}
          <TableNodes
            handleSelectedRows={handleSelectedRows}
            headTable={CATALOG_HEADS_NODE[entityName]}
            rows={rows}
            labelRowsPerPage={CUSTOMER_PER_PAGE}
            paginationProps={{
              handleChangePage,
              handleChangeRowsPerPage,
              page,
              rowsPerPage,
              totalData,
            }}
            setNodeDataSelected={setNodeDataSelected}
            resetItemChecked={resetItemChecked}
          />
        </>
      )}
      <ModalSimple
        isOpen={modalStatus.isOpen}
        closeModal={modalStatus.closeModal}
        title={modalStatusText.title}
        description={modalStatusText.description}
        primaryButton={{
          onClick: modalStatus.handleClick,
          text: ModalButtonEnum.DESACTIVAR,
        }}
        secondaryButton={ModalButtonEnum.CANCELAR}
      />
      <ModalFamily {...modalFamilyProps} />
    </React.Fragment>
  );
};

export default Dashboard;
