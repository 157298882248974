export const BRANCH_OPTIONS_INITIAL_MESSAGE =
  "Selecciona el método para crear los branches";
export const CONTINUE_BUTTON = "Continuar";

export const LOADER_CHECKING_FILE_TITLE = "Validando archivo";
export const LOADER_CHECKING_FILE_DESCRIPTION =
  "Este proceso puede tardar un poco...";

export const LOADER_CREATE_BRANCHES_TITLE = "Estamos creando los branches";
export const LOADER_CONFIG_BRANCHES_TITLE = "Estamos configurando los branches";
export const LOADER_CREATE_BRANCHES_DESCRIPTION =
  "Este proceso puede tardar un poco...";
