import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  root: {
    "& .MuiInputBase-input": {
      textAlign: "left !important",
    },
  },
  loading: {
    position: "absolute",
  },
  contentTitle: {
    position: "relative",
    color: "#023365",
    fontWeight: 500,
    fontSize: "20px",
    width: "310px",
  },
  contentSubtitle: {
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    position: "relative",
    width: "215px",
  },
  containerDialog: {
    position: "static",
    float: "right",
    width: "390px",
  },
}));
