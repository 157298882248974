import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRechargeFetchState } from "../../interfaces/RechargeState.interfaces";
import {
  postCheckOutInitRequest,
  postCheckOutTokenRequest,
} from "../../thunks/RechargeWallet/rechargeWallet.thunks";

export const initialState: IRechargeFetchState = {
  checkoutInitError: false,
  checkoutTokenError: false,
};

export const rechargeWalletSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(postCheckOutTokenRequest.fulfilled, (state, { payload }) => {
        state.checkoutToken = payload;
        state.checkoutTokenError = false;
      })
      .addCase(postCheckOutTokenRequest.rejected, (state) => {
        state.checkoutTokenError = true;
      })
      .addCase(postCheckOutInitRequest.fulfilled, (state, { payload }) => {
        state.checkoutInitResponse = payload;
        state.checkoutInitError = false;
      })
      .addCase(postCheckOutInitRequest.rejected, (state) => {
        state.checkoutInitError = true;
      });
  },
  initialState,
  name: "rechargeWallet",
  reducers: {
    clearCheckoutInitResponse: (state: IRechargeFetchState) => {
      state.checkoutInitResponse = undefined;
    },
    clearCheckoutToken: (state: IRechargeFetchState) => {
      state.checkoutToken = undefined;
    },
    setCheckoutInitError: (
      state: IRechargeFetchState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.checkoutInitError = payload;
    },
    setCheckoutTokenError: (
      state: IRechargeFetchState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.checkoutTokenError = payload;
    },
  },
});

export default rechargeWalletSlice.reducer;
