import { CustomStyles } from "@shared/interfaces/create_named_styles";

export const breadcrumbsStyles: CustomStyles = {
  breadcrumb: {
    color: "#6D7781",
  },
  label: {
    color: "#6D7781",
  },
  current: {
    color: "#4498EE",
  },
};
