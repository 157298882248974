import { KindEnum } from "./KindEnum";
import { CountryEnum } from "./CountryEnum";
import { InitialStatusEnum, StatusEnum } from "./StatusEnum";
import { get } from "lodash";
import { themeOptions } from "../../themeui";
import { translate } from "./LanguageCatatog";
import { setBackgroundColor, setColor } from "./Colors";

export interface IChipProps {
  value: string;
  backgroundColor: string;
  color: string;
}

export enum ValueStatusFinalEnum {
  Emitida = "Emitida",
  Aprobada = "Aprobada",
  Manual = "Manual",
  Anulada = "Anulada",
  Omitida = "Omitida",
  Fallida = "Fallida",
  Rechazada = "Rechazada",
  Exitoso = "Exitoso",
  Exitoso_Note = "Exitosa",
  Liquidada = "Liquidada",
  Aprobado_Parcialmente = "Aprobado Parcialmente",
  Guion = "-",
  Proceso_fallido = "Proceso fallido",
}

export const CatalogFinalStatus = {
  [KindEnum.INVOICE]: {
    [StatusEnum.COMPLETED]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: {
        value: ValueStatusFinalEnum.Emitida,
        backgroundColor: `${themeOptions.palette.primary.light3}`,
        color: `${themeOptions.palette.primary.main}`,
      },
    },
    [StatusEnum.MANUAL]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: {
        value: ValueStatusFinalEnum.Manual,
        backgroundColor: `${themeOptions.palette.warning.light3}`,
        color: `${themeOptions.palette.neutral.grey8}`,
      },
    },
    [StatusEnum.ANNUL]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: {
        value: ValueStatusFinalEnum.Anulada,
        backgroundColor: `${themeOptions.palette.error.light3}`,
        color: `${themeOptions.palette.error.dark2}`,
      },
    },
    [StatusEnum.OMITTED]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: {
        value: ValueStatusFinalEnum.Omitida,
        backgroundColor: `${themeOptions.palette.primary.light3}`,
        color: `${themeOptions.palette.primary.main}`,
      },
    },
    [StatusEnum.FAILED]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: {
        value: ValueStatusFinalEnum.Fallida,
        backgroundColor: `${themeOptions.palette.error.light3}`,
        color: `${themeOptions.palette.error.dark2}`,
      },
    },
    [StatusEnum.REJECTED]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: {
        value: ValueStatusFinalEnum.Rechazada,
        backgroundColor: `${themeOptions.palette.error.light3}`,
        color: `${themeOptions.palette.error.dark2}`,
      },
    },
    [StatusEnum.EXPIRED]: {
      [`${CountryEnum.chile}`]: {
        value: ValueStatusFinalEnum.Omitida,
        backgroundColor: `${themeOptions.palette.error.light3}`,
        color: `${themeOptions.palette.error.dark2}`,
      },
    },
  },
  [KindEnum.CHARGE]: {
    [StatusEnum.COMPLETED]: {
      [CountryEnum.ecuador]: {
        value: ValueStatusFinalEnum.Exitoso,
        backgroundColor: `${themeOptions.palette.secondary.light3}`,
        color: `${themeOptions.palette.secondary.dark}`,
      },
    },
    [StatusEnum.MANUAL]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: {
        value: ValueStatusFinalEnum.Manual,
        backgroundColor: `${themeOptions.palette.warning.light3}`,
        color: `${themeOptions.palette.neutral.grey8}`,
      },
    },
    [StatusEnum.OMITTED]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: {
        value: ValueStatusFinalEnum.Omitida,
        backgroundColor: `${themeOptions.palette.primary.light3}`,
        color: `${themeOptions.palette.primary.main}`,
      },
    },
    [StatusEnum.REJECTED]: {
      [CountryEnum.ecuador]: {
        value: ValueStatusFinalEnum.Rechazada,
        backgroundColor: `${themeOptions.palette.error.light3}`,
        color: `${themeOptions.palette.error.dark2}`,
      },
    },
  },
  [KindEnum.DISPERSION]: {
    [StatusEnum.COMPLETED]: {
      [`${CountryEnum.mexico}|${CountryEnum.peru}`]: {
        value: ValueStatusFinalEnum.Liquidada,
        backgroundColor: `${themeOptions.palette.secondary.light3}`,
        color: `${themeOptions.palette.secondary.dark}`,
      },
      [`${CountryEnum.colombia}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: {
        value: ValueStatusFinalEnum.Exitoso,
        backgroundColor: `${themeOptions.palette.secondary.light3}`,
        color: `${themeOptions.palette.secondary.dark}`,
      },
    },
    [StatusEnum.PARTIALLY_COMPLETED]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: {
        value: ValueStatusFinalEnum.Aprobado_Parcialmente,
        backgroundColor: `${themeOptions.palette.warning.light3}`,
        color: `${themeOptions.palette.warning.dark2}`,
      },
    },
    [StatusEnum.MANUAL]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: {
        value: ValueStatusFinalEnum.Manual,
        backgroundColor: `${themeOptions.palette.warning.light3}`,
        color: `${themeOptions.palette.neutral.grey8}`,
      },
    },
    [StatusEnum.OMITTED]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: {
        value: ValueStatusFinalEnum.Omitida,
        backgroundColor: `${themeOptions.palette.primary.light3}`,
        color: `${themeOptions.palette.primary.main}`,
      },
    },
    [StatusEnum.REJECTED]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: {
        value: ValueStatusFinalEnum.Rechazada,
        backgroundColor: `${themeOptions.palette.error.light3}`,
        color: `${themeOptions.palette.error.dark2}`,
      },
    },
    [StatusEnum.FAILED_PROCESS]: {
      [`${CountryEnum.colombia}`]: {
        value: ValueStatusFinalEnum.Proceso_fallido,
        backgroundColor: `${themeOptions.palette.error.light3}`,
        color: `${themeOptions.palette.error.dark2}`,
      },
    },
  },
  [KindEnum.RECEIVABLE]: {
    [StatusEnum.REJECTED]: {
      [CountryEnum.chile]: {
        value: ValueStatusFinalEnum.Rechazada,
        backgroundColor: `${themeOptions.palette.error.light3}`,
        color: `${themeOptions.palette.error.dark2}`,
      },
    },
  },
  [KindEnum.DEBIT_NOTE]: {
    [StatusEnum.COMPLETED]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: {
        value: ValueStatusFinalEnum.Emitida,
        backgroundColor: `${themeOptions.palette.primary.light3}`,
        color: `${themeOptions.palette.primary.main}`,
      },
    },
  },
  [KindEnum.CREDIT_NOTE]: {
    [StatusEnum.COMPLETED]: {
      [`${CountryEnum.colombia}|${CountryEnum.mexico}|${CountryEnum.peru}|${CountryEnum.chile}|${CountryEnum.ecuador}`]: {
        value: ValueStatusFinalEnum.Emitida,
        backgroundColor: `${themeOptions.palette.primary.light3}`,
        color: `${themeOptions.palette.primary.main}`,
      },
    },
  },
  [KindEnum.RETENTION_EC]: {
    [StatusEnum.COMPLETED]: {
      [CountryEnum.ecuador]: {
        value: ValueStatusFinalEnum.Aprobada,
        backgroundColor: `${themeOptions.palette.secondary.light3}`,
        color: `${themeOptions.palette.secondary.dark}`,
      },
    },
    [StatusEnum.REJECTED]: {
      [CountryEnum.ecuador]: {
        value: ValueStatusFinalEnum.Rechazada,
        backgroundColor: `${themeOptions.palette.error.light3}`,
        color: `${themeOptions.palette.error.dark2}`,
      },
    },
    [StatusEnum.ANNULLED]: {
      [CountryEnum.ecuador]: {
        value: ValueStatusFinalEnum.Anulada,
        backgroundColor: `${themeOptions.palette.error.light3}`,
        color: `${themeOptions.palette.error.dark2}`,
      },
    },
  },
  [KindEnum.VOUCHER]: {
    [StatusEnum.COMPLETED]: {
      [CountryEnum.peru]: {
        value: ValueStatusFinalEnum.Emitida,
        backgroundColor: `${themeOptions.palette.secondary.light3}`,
        color: `${themeOptions.palette.secondary.dark}`,
      },
    },
    [StatusEnum.REJECTED]: {
      [CountryEnum.peru]: {
        value: ValueStatusFinalEnum.Rechazada,
        backgroundColor: `${themeOptions.palette.error.light3}`,
        color: `${themeOptions.palette.error.dark2}`,
      },
    },
    [StatusEnum.OMITTED]: {
      [CountryEnum.peru]: {
        value: ValueStatusFinalEnum.Omitida,
        backgroundColor: `${themeOptions.palette.primary.light3}`,
        color: `${themeOptions.palette.primary.main}`,
      },
    },
  },
  [KindEnum.RECEIVABLE]: {
    [StatusEnum.COMPLETED]: {
      [CountryEnum.chile]: {
        value: ValueStatusFinalEnum.Exitoso,
        backgroundColor: `${themeOptions.palette.secondary.light3}`,
        color: `${themeOptions.palette.secondary.dark}`,
      },
    },
    [StatusEnum.REJECTED]: {
      [CountryEnum.chile]: {
        value: ValueStatusFinalEnum.Rechazada,
        backgroundColor: `${themeOptions.palette.error.light3}`,
        color: `${themeOptions.palette.error.dark2}`,
      },
    },
    [StatusEnum.OMITTED]: {
      [CountryEnum.chile]: {
        value: ValueStatusFinalEnum.Omitida,
        backgroundColor: `${themeOptions.palette.primary.light3}`,
        color: `${themeOptions.palette.primary.main}`,
      },
    },
  },
};

export const getCatalogFinalStatusInfo = (
  kind: KindEnum,
  country: CountryEnum,
  status: StatusEnum
): IChipProps => {
  const isOmitted = status === StatusEnum.OMITTED;
  let catalogInfo: IChipProps = {
    value: translate(
      kind === KindEnum.RETENTION_EC && status === StatusEnum.COMPLETED
        ? InitialStatusEnum.APPROVE
        : isOmitted
        ? "-"
        : status
    ),
    backgroundColor: isOmitted ? "transparent" : setBackgroundColor(status),
    color: setColor(status, kind),
  };
  const catalogTabs: object = get(
    CatalogFinalStatus,
    `[${kind}][${status}]`,
    {}
  );
  Object.keys(catalogTabs).map((key: string) => {
    if (key.split("|").includes(country)) catalogInfo = catalogTabs[key];
  });
  return catalogInfo;
};
