import React, { FC } from "react";
import { Divider, Grid, Stack } from "@mui/material";
import { IBalanceInfo } from "./BalanceInfo.interfaces";
import { balanceInfoStyles as styles } from "./BalanceInfo.styles";
import BalanceData from "../BalanceData/BalanceData";

const BalanceInfo: FC<IBalanceInfo> = ({
  balanceData,
  country,
}: IBalanceInfo) => {
  return (
    <Stack spacing={3}>
      <Grid item>
        <Divider
          orientation="horizontal"
          flexItem
          sx={styles.dividerHorizontal}
        />
      </Grid>
      <Grid item>
        <BalanceData balanceData={balanceData} country={country} />
      </Grid>
    </Stack>
  );
};

export default BalanceInfo;
