import { Box, Card, CardContent, Grid } from "@mui/material";
import React from "react";
import { useDeferredStyles as styles } from "../Deferred.styles";
import { Alert } from "@kushki/connect-ui";
import { DeferredAlertEnum } from "../../../shared/enums/AlertEnum";

export const NoDecentralizedDeferred: React.FC = () => {
  return (
    <Card sx={styles.mainCard}>
      <CardContent>
        <Box>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={styles.gridList}
          >
            <Grid item xs={12}>
              <Alert
                title={DeferredAlertEnum.NO_DECENTRALIZED_DEFERRED}
                type="warning"
              />
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};
