import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITicketOrderData } from "../../../shared/interfaces/TicketOrderData.interfaces";

export const initialState: ITicketOrderData = {
  amount: "",
  beneficiaryName: "KUSHKI S DE RL DE CV",
  keyAccount: "",
  merchantName: "",
  paymentDetails: "",
  referenceNumber: "",
  transactionType: "Recarga de saldo en Wallet",
};

export const ticketOrderSlice = createSlice({
  initialState,
  name: "ticketOrder",
  reducers: {
    addTicketOrder: (state, { payload }: PayloadAction<ITicketOrderData>) => {
      state.amount = payload.amount;
      state.beneficiaryName = payload.beneficiaryName;
      state.keyAccount = payload.keyAccount;
      state.merchantName = payload.merchantName;
      state.paymentDetails = payload.paymentDetails;
      state.referenceNumber = payload.referenceNumber;
      state.transactionType = payload.transactionType;
    },
  },
});

export default ticketOrderSlice.reducer;
