import * as MASSIVE_MODAL from "../../../shared/constants/labels/massive_modal_labels";
import ModalDialog from "../ModalDialog/ModalDialog";
import React from "react";
import { useMassiveModalDialog } from "./state/useMassiveModalDialog";
import { TypeRequestEnum } from "../../../shared/enums/typeRequestEnum";

const MassiveModalDialog = () => {
  const {
    openMassiveModal,
    footerBtnType,
    handleAcceptMassiveModal,
    handleCloseModal,
  } = useMassiveModalDialog();

  return (
    <ModalDialog
      acceptButtonText={
        footerBtnType === TypeRequestEnum.FINISHED
          ? MASSIVE_MODAL.FINISH_ACCEPT_BUTTON_TEXT
          : MASSIVE_MODAL.ACCEPT_BUTTON_TEXT
      }
      cancelButtonText={MASSIVE_MODAL.CANCEL_BUTTON_TEXT}
      descriptionText={
        footerBtnType === TypeRequestEnum.FINISHED
          ? MASSIVE_MODAL.FINISH_DESCRIPTION_TEXT
          : MASSIVE_MODAL.DESCRIPTION_EDIT_TEXT
      }
      onClickAcceptButton={() => {
        handleAcceptMassiveModal(footerBtnType as TypeRequestEnum);
      }}
      onClickCancelButton={() => handleCloseModal(false)}
      onClose={() => handleCloseModal(false)}
      openModalDialog={openMassiveModal}
      titleText={
        footerBtnType === TypeRequestEnum.FINISHED
          ? MASSIVE_MODAL.FINISH_TITLE_TEXT
          : MASSIVE_MODAL.TITLE_TEXT
      }
    />
  );
};

export default MassiveModalDialog;
