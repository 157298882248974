import React from "react";
import TimelineItem from "@mui/lab/TimelineItem";
import { ChildrenProps } from "../../Timeline.interfaces";
import { styles } from "./TimeLineItem.styles";

const TimeLineItem = ({ children }: ChildrenProps) => {
  return <TimelineItem sx={styles.container}>{children}</TimelineItem>;
};

export default TimeLineItem;
