import { CardBrandsEnum } from "../enums/PaymentMethodEnum";
import React from "react";
import { CardVisa } from "@kushki/connect-ui/dist/Components/Atoms/Cards/CardVisa";
import { CardMastercard } from "@kushki/connect-ui/dist/Components/Atoms/Cards/CardMastercard";
import { CardAmex } from "@kushki/connect-ui/dist/Components/Atoms/Cards/CardAmex";
import { CardDinners } from "@kushki/connect-ui/dist/Components/Atoms/Cards/CardDinners";
import { CardDiscover } from "@kushki/connect-ui/dist/Components/Atoms/Cards/CardDiscover";
import { CardAlia } from "@kushki/connect-ui/dist/Components/Atoms/Cards/CardAlia";
import { CardIcon } from "../../components/CardIcon/CardIcon";

export const ICON_CARD_NAME: Record<string, any> = {
  [CardBrandsEnum.VISA]: <CardIcon icon={<CardVisa fontSize={"medium"} />} />,
  [CardBrandsEnum.MASTERCARD]: (
    <CardIcon icon={<CardMastercard fontSize={"medium"} />} />
  ),
  [CardBrandsEnum.AMERICANEXPRESS]: (
    <CardIcon icon={<CardAmex fontSize={"medium"} />} background={"#006FCF"} />
  ),
  [CardBrandsEnum.DINERS]: (
    <CardIcon icon={<CardDinners fontSize={"medium"} />} />
  ),
  [CardBrandsEnum.DISCOVER]: (
    <CardIcon icon={<CardDiscover fontSize={"medium"} />} />
  ),
  [CardBrandsEnum.ALIA]: (
    <CardIcon icon={<CardAlia fontSize={"medium"} />} background={"#77B41F"} />
  ),
};
