import React, { PropsWithChildren } from "react";
import { Fab, Grid } from "@mui/material";
import { IconEdit, IconTrash } from "@kushki/connect-ui";
import { AriaLabels } from "../../../../shared/infrastructure/constants/BusinessRuleConstants";
import { ITextFieldAndFabButtonsContainerProps } from "../../../../shared/infrastructure/interfaces/ITextFieldAndFabButtonsContainerProps";

export const FabButtonsContainer: React.FC<
  ITextFieldAndFabButtonsContainerProps
> = (props: PropsWithChildren<ITextFieldAndFabButtonsContainerProps>) => {
  return (
    <React.Fragment>
      <Grid item>
        <Fab
          sx={{ boxShadow: 2, backgroundColor: "#FFFF" }}
          size="small"
          aria-label={AriaLabels.EDIT}
          onClick={props.handleEditFabButton}
        >
          <IconEdit />
        </Fab>
      </Grid>
      <Grid item>
        <Fab
          sx={{ boxShadow: 2, backgroundColor: "#FFFF" }}
          size="small"
          aria-label={AriaLabels.DELETE}
          onClick={props.handleDeleteFabButton}
        >
          <IconTrash />
        </Fab>
      </Grid>
    </React.Fragment>
  );
};
