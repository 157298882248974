/* istanbul ignore file */
/* eslint-disable */
export const environment: {
kushkiUrl: string;
envName: string;
devTools: boolean;
} = {
kushkiUrl: "https://api.kushkipagos.com",
envName: "primary",
devTools: false,
};
