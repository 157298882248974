import { InputErrorMessages } from "../../interfaces/InputFieldsForm.interfaces";
import {
  INPUT_ERROR_KEYS,
  INPUT_ERROR_MESSAGES,
} from "../../constants/input_form";

export const getInvalidErrorMessages = (
  label: string,
  messages?: InputErrorMessages
) => {
  return (
    messages?.invalid ??
    `${INPUT_ERROR_MESSAGES.invalid.replace(INPUT_ERROR_KEYS.target, label)}`
  );
};

export const getRequiredErrorMessages = (messages?: InputErrorMessages) => {
  return messages?.required ?? INPUT_ERROR_MESSAGES.required;
};

export const getErrorMessagesMinLength = (messages?: InputErrorMessages) => {
  return messages?.minLength ?? INPUT_ERROR_MESSAGES.minLength;
};

export const getErrorMessagesMaxLength = (messages?: InputErrorMessages) => {
  return messages?.maxLength ?? INPUT_ERROR_MESSAGES.maxLength;
};

export const getErrorMessagePatter = (messages?: InputErrorMessages) => {
  return messages?.pattern ?? INPUT_ERROR_MESSAGES.pattern;
};
export const getDispErrorMessages = (field: string) => {
  return {
    invalid: `Debe ingresar un ${field} válido`,
  };
};
