import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const processorInfoComponentStyles = createNamedStyles({
  fieldSkeleton: {
    width: 260,
    height: 80,
  },
  checkSkeleton: {
    marginLeft: "20px",
    width: 400,
    height: 40,
  },
  basicContainer: {
    marginBottom: "5px",
    marginLeft: "20px",
    marginRight: "20px",
  },
  formContainer: {
    display: "flex",
    marginBottom: "16px",
  },
  currencyContainer: {
    marginRight: "5px",
  },
  titleBasic: {
    marginBottom: "15px",
  },
  title: {
    fontFamily: "IBM Plex Sans",
    fontWeight: 500,
    fontSize: "16px",
    color: "#023365",
  },
  checkBox: {
    "&.Mui-checked": {
      color: "#023365",
    },
  },
  cardContent: {
    paddingTop: "30px",
    paddingBottom: "30px",
  },
  checkboxLabel: {
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontWeight: 400,
    color: "#677784",
  },
  checkboxCheckButton: {
    marginTop: 1,
  },
});
