export enum CardBrandsEnum {
  ALIA = "alia",
  AMEX = "amex",
  BBVA = "bbva",
  CARNET = "carnet",
  DEFAULT = "default",
  DINERS = "diners",
  DISCOVER = "discover",
  JCB = "jcb",
  MASTERCARD = "masterCard",
  VISA = "visa",
  UNIONPAY = "union pay",
  MAGNA = "magna",
}
