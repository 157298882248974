import React, { RefObject } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { get } from "lodash";
import { KeyValue } from "../../../types/key_value";
import { Percent } from "react-feather";
import { FieldError, NestDataObject } from "react-hook-form";
import { FormIvaComponent } from "./FormIvaComponent";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { SecurityWrapperEnum } from "../../shared/infrastructure/Enums/SecurityWrapperEnum";
import { Catalog, Data } from "../../../types/catalog";
import { IAppState } from "../../store/reducer";
import { useSelector } from "react-redux";
import {
  catalogsDataToKeyValues,
  getDataFromCatalogByName,
} from "../../utils/functions/Catalog";
import { CatalogsNameEnum } from "../../shared/infrastructure/Enums/CatalogsNameEnum";

const useStyles = makeStyles((theme: Theme) => ({
  labelDescription: {
    marginTop: theme.spacing(1),
    fontSize: "18px",
  },
  labelTitle: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  labelDescriptionButton: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  labelTitleStep: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  contentWidth: {
    maxWidth: "fit-content",
  },
  cardStyle: { backgroundColor: "#F7FAFC", border: "none" },
  subtitle2: {
    ...theme.typography.body1,
    fontSize: "16px",
    fontWeight: 400,
    color: "#6D7781",
  },
  content: {
    marginTop: 10,
    padding: 10,
  },
}));

export interface ICardUploadComponentProps {
  ivaRetention?: KeyValue[] | undefined;
  methods: {
    handleOnChangeSelection: (
      taxpayer: KeyValue | null,
      identifier: string
    ) => void;
    handleValueRetentionChange: (value: string, identifier: string) => void;
  };
  values: {
    taxpayerValue?: KeyValue | null;
    activityValue?: KeyValue | null;
    ivaRetentionValues?: KeyValue | null;
    incomeSourceConceptValue?: KeyValue | null;
    rentRetentionValue?: string;
  };
  register(
    obj: object
  ): ((instance: any) => void) | RefObject<any> | null | undefined;
  errors: NestDataObject<Record<string, any>, FieldError>;
  title: string;
  isIva: boolean;
  editDisable?: boolean;
  rolAzure?: SecurityWrapperEnum;
}

export const CardFormUploadComponent: React.FC<ICardUploadComponentProps> = (
  props: ICardUploadComponentProps
) => {
  const classes = useStyles();
  const catalogsList: Catalog[] | undefined = useSelector(
    (state: IAppState) => state.catalogsList
  );
  const incomeSourceConceptTypes: Data[] = getDataFromCatalogByName(
    catalogsList,
    CatalogsNameEnum.INCOME_SOURCE_CONCEPT
  );
  const incomeSourceConceptTypesKeyValues: KeyValue[] = catalogsDataToKeyValues(
    incomeSourceConceptTypes
  );
  return (
    <Card>
      <CardContent>
        <Container>
          <Grid container spacing={3} direction="row">
            <Grid item xs={8}>
              <Box
                flexGrow={1}
                display="flex"
                alignItems={"center"}
                height={"100%"}
              >
                <Typography
                  className={classes.labelDescription}
                  variant="h5"
                  align="left"
                  color="inherit"
                >
                  Completa los campos de las configuraciones que desea asignar
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={3} direction="row">
            <Grid item xs={12}>
              <Box
                display="flex"
                flexGrow={1}
                alignItems={"center"}
                height={"100%"}
              >
                <Typography className={classes.subtitle2} align={"left"}>
                  *Recuerde que tienes un límite de 20 registros por carga.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box
                display="flex"
                flexGrow={1}
                alignItems={"center"}
                height={"100%"}
              >
                <Typography
                  variant="h5"
                  align="left"
                  color="primary"
                  style={{ marginTop: "0" }}
                >
                  {props.title}
                </Typography>
              </Box>
            </Grid>
            {props.isIva ? (
              <FormIvaComponent {...props} />
            ) : (
              <Grid container spacing={2} className={classes.content} xs={10}>
                <Grid item xs={6}>
                  <Autocomplete
                    id="size-small-outlined"
                    size="small"
                    disabled={props.editDisable}
                    options={incomeSourceConceptTypesKeyValues}
                    getOptionLabel={(option: KeyValue) =>
                      `${option.key} - ${option.value}`
                    }
                    getOptionSelected={(option: KeyValue, value: KeyValue) =>
                      option.key === value.key
                    }
                    value={get(props, "values.incomeSourceConceptValue", {
                      key: 0,
                      value: "Elegir",
                    })}
                    onChange={(_event: any, newValue: KeyValue | null) => {
                      props.methods.handleOnChangeSelection(newValue, "3");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Concepto retención en la renta"
                        name="incomeSourceConceptType"
                        variant="outlined"
                        inputRef={props.register({
                          required:
                            "Debe ingresar el concepto de retencion de la renta.",
                        })}
                        error={!!props.errors.incomeSourceConceptType}
                        helperText={
                          props.errors.incomeSourceConceptType
                            ? props.errors.incomeSourceConceptType.message
                            : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ComponentSecurityWrapper
                    componentId={get(props, "rolAzure", "")}
                  >
                    <TextField
                      autoComplete="off"
                      id="input-edit"
                      fullWidth
                      name="rentRetention"
                      label={"Ingresa la retención renta"}
                      variant="outlined"
                      value={props.values.rentRetentionValue}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        props.methods.handleValueRetentionChange(
                          event.target.value,
                          "1"
                        )
                      }
                      inputRef={props.register({
                        required:
                          "Debe ingresar el porcentaje de retención de la renta.",
                      })}
                      error={!!props.errors.rentRetention}
                      helperText={
                        props.errors.rentRetention
                          ? props.errors.rentRetention.message
                          : ""
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton edge="end" disabled={true}>
                              <Percent />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </ComponentSecurityWrapper>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Container>
      </CardContent>
    </Card>
  );
};
