import React from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
import { TitlePage } from "../../components/common/TitlePage/TitlePage";
import { useFormIndexState } from "./state/useFormIndexState";
import { UploadActionButtonsComponent } from "../../components/UploadActionButtonsComponent/UploadActionButtonsComponent";
import { FormUploadCardComponent } from "../../components/FormUploadCardComponent/FormUploadCardComponent";
import { RedirectionModal } from "@kushki/frontend-molecules/redirection-modal";
import { ErrorPage } from "../../components/common/ErrorPage/ErrorPape";
import { ModalDialog } from "../../components/ModalDialog/ModalDialog";
import { DashboardTable } from "../../components/Table/DashboardTable/DashboardTable";
import { get, isEmpty } from "lodash";

export const FormIndex: React.FC = () => {
  const {
    breadCrumbs,
    country,
    ivaRetention,
    methods,
    values,
    form,
    textButton,
    modalDetailProps,
    modalDeleteProps,
    data,
    actionVerifyRetention,
    edit,
    securityAzure,
  } = useFormIndexState();

  return (
    <React.Fragment>
      <Container fixed>
        <Breadcrumb {...breadCrumbs} />
        <TitlePage title={"Carga con Formulario"} country={country!} />
        {!form.errorOnSubmit ? (
          <React.Fragment>
            <form>
              <FormUploadCardComponent
                ivaRetention={ivaRetention}
                methods={methods}
                values={values}
                errors={form.errors}
                register={form.register}
                tabPosition={edit.value}
                editDisable={edit.isEdit}
              />
              <Box mb={2} mt={2}>
                <UploadActionButtonsComponent
                  onSave={actionVerifyRetention.handlerValidateDatilCode}
                  handleBack={modalDetailProps.setOpenModalDetail}
                  buttonText={textButton}
                  isLoading={values.isDataSavedLimit}
                  stateFile={{
                    isLoading: actionVerifyRetention.isLoadingVerifyRetention,
                    error: false,
                  }}
                  onSubmit={methods.onSaveConfiguration}
                  editDisable={edit.isEdit}
                  onSubmitItem={edit.onSubmitItem}
                  addRetentionDirectly={edit.addRetentionDirectly}
                  onSaveItemDirectly={methods.onSaveConfiguration}
                  isLoadingCreateItem={edit.isLoadingCreateItem}
                  hasErrorForm={!isEmpty(form.errors)}
                  rolAzure={get(securityAzure, "rolFormActions")}
                />
              </Box>
            </form>
            {values.isDataSaved && !edit.addRetentionDirectly ? (
              <form>
                <form>
                  <Card style={{ background: "#F7FAFC" }}>
                    <CardContent>
                      <Container>
                        <Grid container spacing={3} direction="row">
                          <Grid item xs={8}>
                            <Box
                              display="flex"
                              flexGrow={1}
                              alignItems={"center"}
                              height={"100%"}
                            >
                              <Typography
                                style={{
                                  fontSize: "18px",
                                }}
                                variant="h5"
                                align="left"
                                color="inherit"
                              >
                                Retenciones configuradas.
                              </Typography>
                            </Box>
                          </Grid>
                          {!values.isDataSavedLimit ? (
                            <Grid item xs={12}>
                              <Typography
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 400,
                                  color: "#6D7781",
                                }}
                                align={"left"}
                              >
                                *Límite de 20 registros por carga.
                              </Typography>
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              <Typography
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 400,
                                  color: "red",
                                }}
                                align={"left"}
                              >
                                *Has llegado al ímite de 20 registros por carga.
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </Container>
                    </CardContent>
                  </Card>
                </form>
                <DashboardTable
                  isLoading={data.isLoading}
                  tableColumns={data.retentionColumns}
                  retentionData={data.retentionsData}
                  onHandleEditAction={data.onHandleEditAction}
                  isFilterProps={false}
                  isPaginatorProps={false}
                  filterProps={{
                    filterChips: {
                      retention_dispersion: false,
                    },
                    counter: 1,
                  }}
                  modalDeleteProps={modalDeleteProps}
                />
                <Box mb={2} mt={2}>
                  <UploadActionButtonsComponent
                    isLoading={values.isSaving}
                    onSubmit={methods.onSaveConfiguration}
                    handleBack={modalDetailProps.setOpenModalDetail}
                    buttonText={"Guardar configuraciones"}
                    rolAzure={get(securityAzure, "rolFormActions")}
                  />
                </Box>
              </form>
            ) : null}
          </React.Fragment>
        ) : (
          <ErrorPage handleButtonAction={methods.handleButtonAction} />
        )}
      </Container>
      {edit.isEdit ? (
        <RedirectionModal
          open={edit.openModalEdit}
          headerText={"Las configuraciones que deseas editar ya no existe."}
          handleClose={actionVerifyRetention.handlerCloseChangeConfigRetention}
          bodyText={"Agregalas nuevamente"}
          firstButtonText={"Salir"}
          onClickFirstButton={() => {
            modalDetailProps.returnWithoutSave();
            edit.handleNotificationAfter();
          }}
          secondButtonText={"Agregar"}
          onClickSecondButton={edit.returnNewRetention}
          styleProps={{
            firstButton: {
              paddingLeft: "20px",
              paddingRight: "50px",
            },
            secondButton: {
              paddingLeft: "30px",
              paddingRight: "30px",
              background: "#023365",
              "&:hover": {
                background: "#023365",
              },
            },
            textSecondButton: {
              color: "#FFFFFF",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "14px",
            },
            textHeader: {
              fontSize: "16px",
            },
            textBody: {
              color: "#023365",
              fontSize: "16px",
              fontWeight: "bolder",
            },
          }}
        />
      ) : (
        <RedirectionModal
          open={actionVerifyRetention.existRetention}
          headerText={
            "Se ha encontrado una coincidencia con las configuraciones ingresadas"
          }
          handleClose={actionVerifyRetention.handlerCloseChangeConfigRetention}
          bodyText={"¿Deseas reemplazar las configuraciones actuales?"}
          firstButtonText={"Cancelar"}
          onClickFirstButton={
            actionVerifyRetention.handlerCloseChangeConfigRetention
          }
          secondButtonText={"Reemplazar configuraciones"}
          onClickSecondButton={() => {
            methods.onSubmit();
            actionVerifyRetention.handlerCloseChangeConfigRetention();
          }}
          styleProps={{
            firstButton: {
              paddingLeft: "20px",
              paddingRight: "50px",
            },
            secondButton: {
              paddingLeft: "30px",
              paddingRight: "30px",
              background: "#023365",
              "&:hover": {
                background: "#023365",
              },
            },
            textSecondButton: {
              color: "#FFFFFF",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "14px",
            },
            textHeader: {
              color: "#023365",
            },
          }}
        />
      )}

      <ModalDialog
        open={modalDetailProps.openModal}
        handleOk={modalDetailProps.returnWithoutSave}
        handleClose={modalDetailProps.setCloseModalDetail}
        title={"¿Desea regresar sin guardar las configuraciones?"}
        labelBtnAccept={"Regresar sin guardar"}
      />
    </React.Fragment>
  );
};
