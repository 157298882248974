import { cloneDeep, defaultTo, get, set } from "lodash";
import {
  MerchantNodeData,
  SearchMerchantResponse,
} from "../../../types/search_merchant_response";

export const addBalanceValueText = (
  merchantsData: SearchMerchantResponse,
  merchantsDataWithBalance: SearchMerchantResponse
): SearchMerchantResponse => {
  const responseMerchanstData: MerchantNodeData[] = cloneDeep(
    defaultTo(merchantsData.data, [])
  );

  defaultTo(merchantsDataWithBalance.data, []).forEach(
    (merchantDataWithBalance: MerchantNodeData) => {
      const position: number = responseMerchanstData.findIndex(
        (merchantData: MerchantNodeData) =>
          merchantData.merchant_id === merchantDataWithBalance.merchant_id
      );

      if (position > -1) {
        const foundMerchantData: MerchantNodeData = get(
          responseMerchanstData,
          `[${position}]`,
          {}
        );

        set(responseMerchanstData, `[${position}]`, {
          ...foundMerchantData,
          balance_amount_with_currency:
            merchantDataWithBalance.balance_amount_with_currency,
        });
      }
    }
  );

  return {
    ...merchantsData,
    data: responseMerchanstData,
  };
};
