export enum StatusEnum {
  REJECTED = "rejected",
  ENABLE = "enable",
  DISABLE = "disable",
  PENDING = "pending",
  APPROVED = "approved",
  DELETED = "deleted",
  DESACTIVATION = "desactivation",
  RULES_ENABLED = "ENABLED",
  RULES_DISABLED = "DISABLED",
}
