import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TabItemStatusEnum } from "../../../shared/enum/tabItemStatusEnum";
import { ISemaphoreInfoStore } from "../../interfaces/semaphore.interfaces";
import { SemaphoreData } from "../../../../types/semaphore_data";
import { IWrappedTabs } from "../../../components/WrappedTabs/WrappedTabs.interfaces";

export const initialState: ISemaphoreInfoStore = {
  isLoadingSemaphore: true,
  semaphoreInfo: {
    publicMerchantId: "",
    stepBasicData: {
      statusBasicDetail: TabItemStatusEnum.PENDING,
      statusBillingDetail: TabItemStatusEnum.PENDING,
      statusContactDetail: TabItemStatusEnum.PENDING,
      statusLegalDetail: TabItemStatusEnum.PENDING,
    },
  },
  tabsNodeInfo: {
    tabs: [],
  },
};

export const semaphoreSlice = createSlice({
  initialState,
  name: "semaphoreSlice",

  reducers: {
    setSemaphore: (state, { payload }: PayloadAction<SemaphoreData>) => {
      state.semaphoreInfo = payload;
    },
    setTabsInfo: (state, { payload }: PayloadAction<IWrappedTabs>) => {
      state.tabsNodeInfo = payload;
    },
  },
});

export default semaphoreSlice.reducer;
