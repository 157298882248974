import React, { FC } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useFooterStyles as styles } from "./Footer.styles";
import { IconArrowRight } from "@kushki/connect-ui";
import LastModify from "./LastModify/LastModify";
import { useShowModal } from "./state/useShowModal";
import { FooterData } from "./Footer.data";
import { defaultTo, get } from "lodash";
import { FooterProps } from "./Footer.interface";
import { NodeInfoPath } from "../../shared/enums/NodeInfoPath";
import { TabIndex } from "../../shared/enums/TabIndexEnum";
import { MassiveDeferredModal } from "../MassiveDeferredModal/MassiveDeferredModal";
import {
  FinishModalValues,
  SaveDeferredModalValues,
} from "../../shared/enums/MassiveDeferredEnum";
import {
  ButtonColorEnum,
  ButtonVariantEnum,
} from "../../shared/enums/ButtonStylesEnum";
import { LocalStoragePathEnum } from "../../shared/infrastructure/enums/LocalStoragePathEnum";

export const Footer: FC<FooterProps> = (props: FooterProps) => {
  const { actions, data } = useShowModal(props);
  const { nodeInfoConfig, isMassive, tabSelected } = props;
  const isBusinessRules: boolean = tabSelected === TabIndex.BUSINESS_RULES;
  const isDeferredTab: boolean = tabSelected === TabIndex.DEFERRED;
  const isMassiveDeferred: boolean =
    data.consoleMerchantMassive && isDeferredTab;
  const emailUser: string = defaultTo(
    localStorage.getItem(LocalStoragePathEnum.USER_EMAIL),
    ""
  );
  const modalMassiveDescription: string = SaveDeferredModalValues.DESCRIPTION.replace(
    SaveDeferredModalValues.REPLACE_EMAIL,
    emailUser
  );

  const isSaveButtonDisabled: boolean = ((
    imd: boolean,
    ibr: boolean
  ): boolean => {
    if (imd) return data.isDisabled;
    if (ibr) return actions.disabledButtonSave();
    return data.disabledSaveButton;
  })(isMassiveDeferred, isBusinessRules);

  return (
    <Box sx={styles.containerFooter}>
      <Grid container>
        <Grid item xs={4} sx={styles.gridFooter}>
          <Box sx={styles.buttonsContainer}>
            {(isMassive || isMassiveDeferred) && (
              <Button
                data-testid="idButtonSave"
                variant={ButtonVariantEnum.Outlined}
                color={ButtonColorEnum.Info}
                sx={styles.saveButton}
                onClick={actions.handleSecondButtonAction}
                disabled={isSaveButtonDisabled}
              >
                <Typography variant="body2" sx={styles.button}>
                  {FooterData.SaveButtonText}
                </Typography>
              </Button>
            )}
            <Button
              data-testid="idButtonNext"
              variant={ButtonVariantEnum.Contained}
              endIcon={!isMassive && <IconArrowRight />}
              onClick={actions.handleFirstButtonAction}
              disabled={
                isMassiveDeferred || isBusinessRules
                  ? false
                  : data.disabledNextButton
              }
            >
              <Typography variant="body2" sx={styles.button}>
                {isMassive || isMassiveDeferred
                  ? FooterData.FinishButtonText
                  : FooterData.ContinueButtonText}
              </Typography>
            </Button>
          </Box>
        </Grid>
        <Grid item xs={8} sx={styles.labelContainer}>
          <Box>
            <LastModify
              author={get(nodeInfoConfig, NodeInfoPath.UpdatedBy, "")}
              date={get(nodeInfoConfig, NodeInfoPath.UpdatedAt, 0)}
            />
          </Box>
        </Grid>
        <MassiveDeferredModal
          isOpenModal={data.openFinishEditionModal || data.openMassiveDefModal}
          handleCloseModal={actions.handleCloseModal}
          titleText={
            data.openMassiveDefModal
              ? SaveDeferredModalValues.TITLE
              : FinishModalValues.TITLE
          }
          descriptionText={
            data.openMassiveDefModal
              ? modalMassiveDescription
              : FinishModalValues.DESCRIPTION
          }
          buttonPrimaryText={
            data.openMassiveDefModal
              ? SaveDeferredModalValues.UNDERSTOOD_BUTTON
              : FinishModalValues.FINISH_EDITION_TEXT_BUTTON
          }
          buttonSecondaryText={
            data.openMassiveDefModal ? "" : FinishModalValues.CANCEL_TEXT_BUTTON
          }
          onClickPrimary={() => {
            if (data.openMassiveDefModal) {
              actions.handleCloseModal();
            } else actions.handleDeferredContinue();
          }}
          onClickSecondary={() => {
            if (!data.openMassiveDefModal) actions.handleCloseModal();
          }}
        />
      </Grid>
    </Box>
  );
};

export default Footer;
