/**
 * Catalog ElSalvador
 */
import { Category } from "../infrastructure/constants/MerchantInformationConstant";
import { CatalogsEnum } from "../infrastructure/constants/catalogs-enum";

export const CatalogEl: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { value: "0", name: "DUI" },
    { value: "1", name: "Pasaporte" },
  ],
  [CatalogsEnum.Provinces]: [
    { value: "AH", name: "Ahuachapán" },
    { value: "CA", name: "Cabañas" },
    { value: "CH", name: "Chalatenango" },
    { value: "CU", name: "Cuscatlán" },
    { value: "LI", name: "La Libertad" },
    { value: "PA", name: "La Paz" },
    { value: "UN", name: "La Unión" },
    { value: "MO", name: "Morazán" },
    { value: "SM", name: "San Miguel" },
    { value: "SS", name: "San Salvador" },
    { value: "SV", name: "San Vicente" },
    { value: "SA", name: "Santa Ana" },
    { value: "SO", name: "Sonsonate" },
    { value: "US", name: "Usulután" },
  ],
  [CatalogsEnum.Banks]: [
    { value: "10100003", name: "BANCO CITIBANK DE EL SALVADOR S.A." },
    { value: "10100001", name: "BANCO AGRICOLA S.A." },
    { value: "10100002", name: "BANCO CENTRAL DE RESERVA DE EL SALVADOR" },
    { value: "10100004", name: "BANCO DE AMERICA CENTRAL S.A." },
    { value: "10100005", name: "BANCO HIPOTECARIO DE EL SALVADOR S.A." },
    { value: "10100006", name: "BANCO HSBC SALVADORENO S.A." },
    { value: "10100007", name: "BANCO MULTISECTORIAL DE INVERSIONES" },
    { value: "10100008", name: "BANCO PROCREDIT S.A." },
    { value: "10100009", name: "SCOTIABANK EL SALVADOR S.A." },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { value: "0", name: "Corriente" },
    { value: "1", name: "Ahorros" },
  ],
  [CatalogsEnum.Currency]: [{ value: "USD", name: "USD" }],
  [CatalogsEnum.MerchantTypes]: [
    { value: "01", name: "Física" },
    { value: "02", name: "Moral" },
  ],
};
