import React from "react";
import { Login } from "../components/Login/Login";

export const ContainerIndex: React.FC = () => {
  return (
    <div data-testid={"container-index"}>
      <Login />
    </div>
  );
};
