import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { SearchMerchantResponse } from "../../../../types/search_merchant_response";
import { prefixes } from "../../../shared/constants/prefixes";
import { SearchMerchantsByIdRequest } from "../../../../types/search_merchants_by_id_request";
import { orderBy } from "lodash";
import retryAxiosInstance from "../../../shared/axios-retry-util";

export const searchMerchantNodes = createAsyncThunk(
  prefixes.MERCHANT_RESPONSE,
  async (payload: SearchMerchantsByIdRequest) => {
    const responseMerchantNodes =
      await retryAxiosInstance.post<SearchMerchantResponse>(
        API_ROUTES.MERCHANT_RESPONSE,
        payload
      );

    return {
      data: orderBy(responseMerchantNodes.data.data, ["name"], ["asc"]),
      total: responseMerchantNodes.data.total,
    };
  }
);

export default searchMerchantNodes;
