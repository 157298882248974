export const FREQUENCY_SELECT_LABEL = "Frecuencia de Facturación";
export const MODE_SELECT_LABEL = "Modalidad de facturación";
export const TRANSACTION_TYPE_TITTLE =
  "Tipo de transacciones y servicios adicionales a facturar";
export const MODIFY_QUESTION =
  "¿Deseas modificar las transacciones a facturar?";
export const TRANSACTION_TO_BILLING = "Transacciones a facturar";
export const TRANSACTION_TO_DECLINED = "Transacciones declinadas";
export const TRANSACTION_TO_SUBTRACT = "Transacciones a restar";
export const BILLING_DESCRIPTION =
  "Estas son las transacciones que se facturarán en el comprobante de pago.";
export const SUBTRACT_DESCRIPTION =
  "Estas son las transacciones que se restarán en el comprobante de pago.";
export const DECLINED_DESCRIPTION = "";
export const DECLINED_DESCRIPTION_TOOLTIP_LBL =
  "Las transacciones declinadas sólo se facturan para el servicio adicional de 3DS";
export const TOOLTIP_LBL =
  "Dentro de los Servicios adicionales están 3DS, SiftScience, OTP y TransUnion";
export const TOOLTIP_LBL_EMPTY = "";
export const LOADER_TITLE = "Guardando información";
export const DESCRIPTION_LOADER = "Este proceso puede tardar un poco...";
export const SNACKBAR_LBL = "Se ha guardado la información con éxito.";
export const SNACKBAR_FETCH_ERROR =
  "Ha ocurrido un error al guardar la configuración.";
export const SNACKBAR_FETCH_SUCCESS = "Se ha guardado datos básicos con éxito.";
export const RETURN_BUTTON_OPERATION_EDIT_LABEL = "Regresar";
