/* istanbul ignore file */
import React from "react";
import {
  createStyles,
  Divider,
  Grid,
  IconButton,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { Minus, Plus } from "react-feather";
import ConnectForm from "../commons/ConnectForm/ConnectForm";
import { useTypedController } from "@hookform/strictly-typed";
import { makeStyles } from "@material-ui/core/styles";
import { TWebhookForm } from "../../containers/WebhookFormIndex/state/useWebhookForm";
import { useWatch } from "react-hook-form";
import { PaymentUrl } from "../../shared/infrastructure/interfaces/IWebhookForm";
import { OPTIONS } from "../../shared/infrastructure/constants/OptionsEnum";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
    rowContent: {
      padding: 8,
    },
    itemStyle: {
      backgroundColor: "#F7FAFC",
      padding: theme.spacing(1),
      color: "#6D7781",
    },
    iconStyle: { background: "#F0F4F9" },
  })
);
export const PaymentsUrl: React.FC = () => {
  const classes = useStyles();

  return (
    <ConnectForm<TWebhookForm>>
      {({ control, setValue, getValues }) => {
        const TypedController = useTypedController<TWebhookForm>({
          control: control,
        });
        const { options } = getValues();
        const paymentUrls = useWatch({ name: "paymentUrls" }) as PaymentUrl[];

        const addUrl = (service: string, indexValue: number) => {
          const { textValue } = getValues();
          const index: number = paymentUrls
            .map((e) => e.service)
            .indexOf(service);
          if (index > -1) {
            paymentUrls[index].urls.push(textValue[indexValue]);
            // @ts-ignore Estos ts-ignore son temporales hasta que el componente sea removido en otra historia
            setValue("paymentUrls", [...paymentUrls]);
          } else {
            paymentUrls.push({ service, urls: [textValue[indexValue]] });
            // @ts-ignore Estos ts-ignore son temporales hasta que el componente sea removido en otra historia
            setValue("paymentUrls", [...paymentUrls]);
          }
          textValue[indexValue] = "";
          setValue("textValue", [...textValue]);
        };
        const removeUrl = (service: string, indexValue: number) => {
          const { paymentUrls } = getValues();
          const index: number = paymentUrls
            .map((e) => e.service)
            .indexOf(service);
          paymentUrls[index].urls.splice(indexValue, 1);
          if (paymentUrls[index].urls.length === 0)
            paymentUrls.splice(index, 1);
          setValue("paymentUrls", [...paymentUrls]);
        };
        const editUrl = (
          event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
          service: string,
          index: number
        ) => {
          const id: number = paymentUrls.map((e) => e.service).indexOf(service);
          paymentUrls[id].urls[index] = event.target.value;
          // @ts-ignore Estos ts-ignore son temporales hasta que el componente sea removido en otra historia
          setValue("paymentUrls", [...paymentUrls]);
        };
        return (
          <Grid container spacing={1} alignItems="center">
            {options !== undefined &&
              options.map((option, index: number) => (
                <React.Fragment key={option}>
                  <Grid item xs={3}>
                    <Typography variant="h6" color="textPrimary">
                      {OPTIONS[option]}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    justify="space-between"
                    xs={9}
                    spacing={1}
                  >
                    <Grid item xs={11}>
                      <TypedController
                        name={["textValue", index]}
                        render={(props) => (
                          <TextField
                            {...props}
                            id="url-general"
                            label="URL"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        className={classes.iconStyle}
                        onClick={() => addUrl(option, index)}
                      >
                        {" "}
                        <Plus />
                      </IconButton>
                    </Grid>
                    {paymentUrls.map((e) => e.service).indexOf(option) > -1 &&
                      paymentUrls
                        .filter(
                          (paymentUrl) => paymentUrl.service === option
                        )[0]
                        .urls.map((url: string, index: number) => (
                          <Grid
                            item
                            container
                            key={index}
                            xs={12}
                            alignItems="center"
                            spacing={1}
                            style={{
                              marginLeft: 0,
                              marginRight: 0,
                              paddingRight: 0,
                              paddingLeft: 0,
                            }}
                          >
                            <Grid item xs={11}>
                              <TextField
                                id={`url-option-${index}`}
                                label="URL"
                                variant="outlined"
                                required
                                fullWidth
                                margin="normal"
                                defaultValue={url}
                                onBlur={(
                                  event: React.FocusEvent<
                                    HTMLInputElement | HTMLTextAreaElement
                                  >
                                ) => {
                                  editUrl(event, option, index);
                                }}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <IconButton
                                className={classes.iconStyle}
                                onClick={() => removeUrl(option, index)}
                              >
                                <Minus />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </React.Fragment>
              ))}
          </Grid>
        );
      }}
    </ConnectForm>
  );
};
