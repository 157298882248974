import { IDownloadOption } from "../interfaces/DownloadOption.interface";

export const downloadOptions: IDownloadOption[] = [
  {
    option: "Descargar en .csv",
    value: "CSV",
  },
  {
    option: "Descargar en JSON",
    value: "JSON",
  },
];
