import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../../store/reducer";
import { SwitchTableProps } from "../SwitchTable";
import {
  ACTIVE_TEXTS,
  DEACTIVATE_TEXTS,
} from "../../../../shared/infrastructure/constants/CreateDeferredConstants";
import { StatusEnum } from "../../../../shared/infrastructure/constants/statusEnum";
import {
  setCanceledChangeStatus,
  setErrorChangeStatusDeferred,
} from "../../../../store/actionCreators";

export interface ISwitchTableState {
  active: boolean;
  handleChange?(event: any): void;
  handleSave(event: any): void;
}

export const useSwitchTableState = (
  props: SwitchTableProps
): ISwitchTableState => {
  const dispatch = useDispatch();

  const { canceledChangeStatus, errorChangeStatus } = useSelector(
    (state: IAppState) => state!
  );

  const checkStatus = (): boolean =>
    props.deferredItem.status! !== StatusEnum.DISABLED;
  const [active, setActive] = React.useState<boolean>(checkStatus());

  useEffect(() => {
    if (
      (canceledChangeStatus!.status || errorChangeStatus!.status) &&
      (canceledChangeStatus!.id === props.deferredItem.id ||
        errorChangeStatus!.id === props.deferredItem.id)
    ) {
      setActive(!active);
      dispatch(
        setCanceledChangeStatus({
          id: "",
          status: false,
        })
      );
      dispatch(
        setErrorChangeStatusDeferred({
          id: "",
          status: false,
        })
      );
    }
  }, [canceledChangeStatus, errorChangeStatus]);

  const handleChange = (event: any) => {
    setActive(event.target.checked);
  };

  const handleSave = (event: any) => {
    const status = event.target.checked
      ? StatusEnum.ENABLED
      : StatusEnum.DISABLED;

    handleChange(event);
    status === "enabled"
      ? props.changeDialogText(ACTIVE_TEXTS)
      : props.changeDialogText(DEACTIVATE_TEXTS);

    props.handleModal(props.deferredItem.id!, status);
  };

  return {
    active,
    handleChange,
    handleSave,
  };
};
