import React, { FC } from "react";
import { Box, Skeleton, TableCell, TableRow } from "@mui/material";
import { transactionsSkeletonStyles } from "./TransactionsSkeleton.styles";

const TransactionsSkeleton: FC = () => (
  <>
    {[...Array(7)].map((_, index) => (
      <TableRow key={index} data-testid="skeleton-row">
        <TableCell padding="checkbox">
          <Box sx={transactionsSkeletonStyles.centerBox}>
            <Skeleton
              animation="wave"
              height={18}
              variant="rectangular"
              width={18}
            />
          </Box>
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell />
      </TableRow>
    ))}
  </>
);

export default TransactionsSkeleton;
