import { IDropDownItem } from "../DropDownItem.interfaces";
import {
  getAccountTypeByCountry,
  getBankNameByCountryAndId,
  isBankAccountDifferentOfConstitutionCountry,
} from "../../../shared/utils/bankAccountUtils";
import { isEmpty, isNil } from "lodash";
import { IUseDropdownItemState } from "./useDropdownItemState.interfaces";
import { CountriesEnum } from "../../../shared/enums/countriesEnum";
import { useAppSelector } from "../../../store/hooks/storeHook";
import { ICatalogsData } from "../../../store/interfaces/catalogs/catalogs.interfaces";
import { CatalogsNameEnum } from "../../../shared/constants/catalogs/CatalogsNameEnum";
import { getCatalogDataByCode } from "../../../shared/utils/catalog";

export const useDropdownItemState = (
  item: IDropDownItem
): IUseDropdownItemState => {
  const isCountryAccountDifferentOfConstitution =
    isBankAccountDifferentOfConstitutionCountry(item.country);

  const { listCatalogs }: ICatalogsData = useAppSelector(
    (state) => state.catalogs
  );

  const getBankName = (country: string, bankId: string): string => {
    if (country !== CountriesEnum.CHILE)
      return getBankNameByCountryAndId(country, bankId);

    return getCatalogDataByCode(
      listCatalogs,
      CatalogsNameEnum.BANK_LIST_DISPERSION,
      bankId
    ).value;
  };

  const bankName =
    isNil(item.bankId) || isEmpty(item.bankId)
      ? item.bankName!
      : getBankName(item.country, item.bankId!);

  const accountTypeName = getAccountTypeByCountry(
    item.country,
    item.accountType
  );

  return {
    isCountryAccountDifferentOfConstitution,
    summaryItem: { ...item, accountType: accountTypeName, bankName: bankName },
  };
};
