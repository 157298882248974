import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./reducers/app/app.slice";
import { createEpicMiddleware } from "redux-observable";
import appEpic from "./epics/epics";

export const epicMiddleware = createEpicMiddleware();

export const store = configureStore({
  devTools: process.env.NEXT_PUBLIC_ENV_NAME !== "primary",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
    }).concat(epicMiddleware),
  reducer: {
    app: appReducer,
  },
});

epicMiddleware.run((action$, state$) =>
  appEpic({ action$, dispatch: store.dispatch, state$ })
);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
