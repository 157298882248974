import { IRowInteractiveProps, TableInfo } from "@kushki/connect-ui";
import {
  ITableCellProps,
  ITableRowProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { Box, Link } from "@mui/material";
import { get } from "lodash";
import React from "react";
import { UserList } from "../../../types/user_list";
import {
  TEXT_CELL,
  usersTextEnum,
} from "../../shared/infrastructure/constants/UserResumeConstants";
import {
  ALL_TABLE_COLUMNS_USERS,
  TABLE_ROW_USERS,
} from "../../shared/infrastructure/constants/UserResumeConstants";
import { UserListResumeStyles } from "./UserListResume.styles";
import { MissingInfoAlert } from "../MissingInfoAlert/MissingInfoAlert";
import { IUserListResumeProps } from "./UserListResume.interface";

const UserListResume: React.FC<IUserListResumeProps> = ({
  users,
  link,
  isPendingUser,
  isNodeCustomer,
  requiredConfigs,
}: IUserListResumeProps) => {
  const showEditLabel = users.length > 0 || !isNodeCustomer;

  const styles = UserListResumeStyles();

  const onRenderValue = (key: string, columnValue: string) => {
    const renderValues = {
      default: () => columnValue,
    };

    return get(renderValues, key, renderValues.default)();
  };

  const getColumns = (object: UserList) => {
    return ALL_TABLE_COLUMNS_USERS.map((column) => {
      const value = onRenderValue(column.key, get(object, column.key));

      const tableCell = get(TABLE_ROW_USERS, column.cellType, TEXT_CELL);

      return tableCell(value) as ITableCellProps;
    });
  };

  const buildRows = (): ITableRowProps[] => {
    let data: ITableRowProps[] = [];

    users.map((item: UserList) => {
      data.push({
        cells: getColumns(item),
        id: "discount",
        rowProps: {
          color: "default",
          onClick: () => {},
        } as IRowInteractiveProps,
      });
    });

    return data;
  };

  return (
    <>
      {isPendingUser && <MissingInfoAlert />}
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        mt={1}
        width={"100%"}
      >
        <Box sx={styles.contentBox}>
          {users!.length > 0 && (
            <Box bgcolor="neutral.grey3" sx={styles.paper}>
              <Box sx={styles.paperBox}>
                <TableInfo
                  header={{
                    cells: ALL_TABLE_COLUMNS_USERS,
                    headerProps: {
                      isDisable: false,
                    },
                  }}
                  rows={buildRows()}
                />
              </Box>
            </Box>
          )}
          {showEditLabel && (
            <Link
              key={"link_users"}
              component="button"
              variant="body2"
              onClick={() => {
                window.location.href = link!;
              }}
              sx={styles.link}
              disabled={!requiredConfigs}
            >
              {usersTextEnum.LINK}
            </Link>
          )}
        </Box>
      </Box>
    </>
  );
};

export default UserListResume;
