import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  GridSize,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { LabelChip } from "../ChipNotificationStatus/LabelChip";
import { ChipTypeEnum } from "../../../shared/infrastructure/constants/ChipTypeEnum";
import { sizeEnum } from "../../../shared/infrastructure/constants/SizeEnum";

export interface BankModalProps {
  open: boolean;
  banks: string[];
  handleCloseDialog: (banks: string[]) => void;
}

type SizeType = {
  width: false | "xs" | "sm" | "md" | "lg" | "xl" | undefined;
  xs: GridSize;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const BankModal: React.FC<BankModalProps> = (props: BankModalProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const getSize = (): SizeType => {
    if (props.banks.length <= 10) return { width: sizeEnum.SM, xs: 12 };
    if (props.banks.length <= 20) return { width: sizeEnum.MD, xs: 6 };
    if (props.banks.length <= 30) return { width: sizeEnum.LG, xs: 4 };
    if (props.banks.length <= 40) return { width: sizeEnum.XL, xs: 3 };

    return { width: sizeEnum.MD, xs: 6 };
  };

  const size: SizeType = getSize();

  return (
    <Dialog
      open={props.open}
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      maxWidth={size.width}
      fullWidth
    >
      <DialogTitle>
        <Typography color={"primary"}>Red de Bancos asociados</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container direction={"row"} justify={"space-between"}>
          {props.banks.map((bank, index) => (
            <Grid key={`${index}`} item xs={size.xs!}>
              <Box key={`${index}`} style={{ padding: 10 }}>
                <LabelChip
                  key={`${index}`}
                  text={bank}
                  type={ChipTypeEnum.BANK}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            props.handleCloseDialog([]);
          }}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
