import { useTypedController } from "@hookform/strictly-typed";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { SiftScienceCredentials } from "../../../types/siftscience_credentials";
import ConnectForm from "../../components/commons/ConnectForm/ConnectForm";
import { environment } from "../../environments/environment";
import {
  LabelField,
  SiftScienceNotificationResultEnum,
} from "../../shared/infrastructure/constants/SiftScienceConstants";
import { ISiftScienceCredentialsForm } from "../../shared/infrastructure/interfaces/ISiftScienceCredentialsForm";
import { Skeleton } from "@material-ui/lab";
import { MigrateSiftScienceResponse } from "../../../types/migrate_siftscience_response";

const useStyles = makeStyles(() => ({
  labelTitle: {
    color: "#023365",
    fontSize: "20px",
    fontWeight: 500,
    fontStyle: "normal",
  },
  innerLabel: {
    color: "#6D7781",
    fontSize: "14px",
    fontWeight: 500,
    fontStyle: "normal",
    paddingBottom: "6px",
    marginBottom: "14px",
  },
  label: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "170%",
    color: "#677784",
    padding: "12px",
    paddingLeft: "0px",
  },
}));

export interface SiftScienceCredentialsSectionProps {
  isMigrated: boolean | undefined;
  credentials: SiftScienceCredentials;
  handleClickMigrated: () => void;
  migrateSiftScienceResponse: MigrateSiftScienceResponse | undefined;
  isLoading: boolean | undefined;
}

export const SiftScienceCredentialsSection: React.FC<SiftScienceCredentialsSectionProps> =
  (props: SiftScienceCredentialsSectionProps) => {
    const classes = useStyles();
    const isProduction: boolean = environment.envName === "primary";

    return (
      <ConnectForm<ISiftScienceCredentialsForm>>
        {({ control, errors }) => {
          const TypedController =
            useTypedController<ISiftScienceCredentialsForm>({
              control: control,
            });

          return (
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={3}>
                <Typography className={classes.labelTitle}>
                  Configuración de Sift Science
                </Typography>
                <Box pt={1}>
                  <Typography
                    variant={"subtitle2"}
                    color={"textPrimary"}
                    className={classes.innerLabel}
                  >
                    Configura los datos de pago y valores de transacciones
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={12} lg={9}>
                <Card>
                  {props.isLoading ? (
                    <Skeleton
                      variant="rect"
                      width={"100%"}
                      height={204}
                      animation={"wave"}
                    />
                  ) : (
                    <>
                      {props.isMigrated ? (
                        <CardContent>
                          <Box>
                            <Typography
                              className={classes.innerLabel}
                              variant={"subtitle2"}
                              color={"textPrimary"}
                            >
                              Credenciales
                            </Typography>
                          </Box>
                          <Grid container direction={"row"} spacing={3}>
                            <Grid item xs={6}>
                              <TypedController
                                data-testid="sandboxAccountId"
                                name={["sandboxAccountId"]}
                                rules={{ required: false }}
                                render={(properties) => (
                                  <TextField
                                    {...properties}
                                    id="prodAccountId"
                                    label={LabelField.SANDBOX_ACCOUNT_ID}
                                    variant="outlined"
                                    fullWidth
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TypedController
                                data-testid="prodAccountId"
                                name={["prodAccountId"]}
                                rules={{ required: isProduction }}
                                render={(properties) => (
                                  <TextField
                                    {...properties}
                                    id="prodAccountId"
                                    label={LabelField.PROD_ACCOUNT_ID}
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.prodAccountId}
                                    helperText={
                                      !!errors.prodAccountId &&
                                      SiftScienceNotificationResultEnum.REQUIRED_FIELD
                                    }
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TypedController
                                data-testid="sandboxApiKey"
                                name={["sandboxApiKey"]}
                                rules={{ required: false }}
                                render={(properties) => (
                                  <TextField
                                    {...properties}
                                    id="prodApiKey"
                                    label={LabelField.API_KEY_SANDBOX}
                                    variant="outlined"
                                    fullWidth
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TypedController
                                data-testid="prodApiKey"
                                name={["prodApiKey"]}
                                rules={{
                                  required: isProduction,
                                }}
                                render={(properties) => (
                                  <TextField
                                    {...properties}
                                    id="prodApiKey"
                                    label={LabelField.API_KEY_PROD}
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.prodApiKey}
                                    helperText={
                                      !!errors.prodApiKey &&
                                      SiftScienceNotificationResultEnum.REQUIRED_FIELD
                                    }
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TypedController
                                data-testid="baconSandboxApiKey"
                                name={["baconSandboxApiKey"]}
                                rules={{ required: false }}
                                render={(properties) => (
                                  <TextField
                                    {...properties}
                                    id="baconProdApiKey"
                                    label={LabelField.BACON_SANDBOX_API_KEY}
                                    variant="outlined"
                                    fullWidth
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TypedController
                                data-testid="baconProdApiKey"
                                name={["baconProdApiKey"]}
                                rules={{ required: isProduction }}
                                render={(properties) => (
                                  <TextField
                                    {...properties}
                                    id="baconProdApiKey"
                                    label={LabelField.BACON_PROD_API_KEY}
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.baconProdApiKey}
                                    helperText={
                                      !!errors.baconProdApiKey &&
                                      SiftScienceNotificationResultEnum.REQUIRED_FIELD
                                    }
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      ) : (
                        <CardContent>
                          <Box>
                            <Typography
                              className={classes.innerLabel}
                              variant={"subtitle2"}
                              color={"textPrimary"}
                            >
                              Credenciales
                            </Typography>
                          </Box>
                          <Box pt={1}>
                            <Typography
                              className={classes.label}
                              color={"textPrimary"}
                            >
                              Este comercio ya cuenta actualmente con
                              credenciales de Sift Science
                            </Typography>
                          </Box>
                          <Box pt={3}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={props.handleClickMigrated}
                            >
                              Migrar credenciales
                            </Button>
                          </Box>
                        </CardContent>
                      )}
                    </>
                  )}
                </Card>
              </Grid>
            </Grid>
          );
        }}
      </ConnectForm>
    );
  };

export default SiftScienceCredentialsSection;
