import React from "react";
import { Grid } from "@mui/material";
import { IBoxGenericInput } from "./BoxGenericInput.interfaces";

const BoxGenericInput: React.FC<IBoxGenericInput> = ({
  spacing,
  direction,
  children,
}: IBoxGenericInput) => {
  return (
    <Grid container item xs={12} sx={direction}>
      <Grid item xs={6} sx={spacing}>
        {children}
      </Grid>
    </Grid>
  );
};

export default BoxGenericInput;
