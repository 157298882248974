/* istanbul ignore file */
import axios from "axios";
import { defaultTo } from "lodash";
import { LocalStoragePath } from "./enums/local_storage_path";

const authorization: string = defaultTo(
  localStorage.getItem(LocalStoragePath.JWT),
  ""
);

const instance = axios.create({
  headers: {
    Authorization: authorization,
    "Content-type": "application/json",
  },
});

export default instance;
