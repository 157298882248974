import { IStyles } from "../../../../shared/interfaces/Styles.interfaces";

export const DetailHeaderStyles: IStyles = {
  clipboardGrid: {
    paddingLeft: "30px",
  },
  copyURLButton: {
    typography: "subtitle2",
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "140%",
    color: "text.primary",
    padding: "0 0 7px 0",
  },
  modalTitle: {
    typography: "body2",
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "136%",
    color: "text.grey",
    margin: "4px 0px",
  },
  tab: {
    color: "neutral.grey8",
    fontFamily: "IBM Plex Sans, Helvetica, sans-serif",
    fontSize: "14px",
    lineHeight: "170%",
    width: "33.33%",
  },
  tabContainer: {
    borderBottom: 1,
    borderColor: "neutral.grey5",
  },
  transactionDate: {
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "140%",
    color: "text.grey",
    margin: "8px 0px",
  },
  transactionId: {
    typography: "h4",
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: "120%",
    color: "neutral.grey7",
  },
};
