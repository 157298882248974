import React from "react";
import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { TableNodesStyle } from "../../Table/TableNodes/TableNodes.styles";
import { TableBodyStyles } from "../../Table/TableBody/TableBody.style";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { tableSkeletonStyles as styles } from "./TableSkeleton.styles";

type TableSkeletonProps = {
  columns: number;
  rows: number;
};
const TableSkeleton: React.FC<TableSkeletonProps> = ({
  columns,
  rows,
}: TableSkeletonProps) => {
  return (
    <TableContainer>
      <Box>
        <Table sx={TableNodesStyle.table} aria-labelledby="tableSkeleton">
          <TableHead sx={TableNodesStyle.headerSkeleton}>
            <TableRow>
              <TableCell sx={TableNodesStyle.selectAllCell}>
                <HorizontalRuleIcon />
              </TableCell>
              {[...Array(columns)].map((_head, headIndex) => (
                <TableCell
                  sx={styles(columns, headIndex).cellRadius}
                  key={headIndex}
                >
                  <Skeleton width={"100%"} height="28px" />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={TableBodyStyles.body}>
            {[...Array(rows)].map((_row, rowIndex) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  key={rowIndex}
                  sx={TableBodyStyles.row}
                >
                  <TableCell>
                    <Skeleton
                      variant="rectangular"
                      width="20px"
                      height="20px"
                    />
                  </TableCell>
                  {[...Array(columns)].map((_col, cellIndex) => {
                    return (
                      <TableCell key={cellIndex}>
                        <Typography align={"center"}>
                          <Skeleton
                            variant="rectangular"
                            sx={styles(columns, cellIndex).skeletonSize}
                          />
                        </Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </TableContainer>
  );
};

export default TableSkeleton;
