import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  _selectableColumnsConciliation,
  _selectableColumnsConciliationHistoric,
  _selectableColumnsDeposit,
} from "../../../shared/infrastructure/constants/ColumnsDashboardTables";
import {
  IHeaderTable,
  lastStaticColumn,
  staticColumns,
} from "../../../shared/infrastructure/headers/defaultHeaders";
import { setActualHeaders } from "../../../store/actionCreators";
import { ColumnsConfigProps } from "../ColumnsConfig";
import { SourceTransactionsEnum } from "../../../shared/infrastructure/constants/StatusFlowDashboardConstants";

export const _defaultColumnsConciliation: string[] = [
  "merchantName",
  "merchantId",
  "approvedTransactionAmount",
  "netAmount",
];

const _defaultColumnsDeposit: string[] = [
  "country",
  "paymentMethod",
  "processorName",
  "currencyCode",
  "netAmountTotal",
];

export interface ColumnsConfigState {
  handleCloseColumns: (_event: unknown) => void;
  handleChangeColumnCheck: (
    event: React.BaseSyntheticEvent,
    maxLen?: number
  ) => void;
  handleRestoreColumns: () => void;
  anchorElement: HTMLButtonElement | null;
  handleClickColumns: (event: React.BaseSyntheticEvent) => void;
  selectedColumns: string[];
  selectableColumns: IHeaderTable[];
  maxSelection: number;
  handleDisplayedColumn: () => void;
}

export const useColumnsConfigState = (
  props: ColumnsConfigProps
): ColumnsConfigState => {
  const dispatch = useDispatch();

  const moduleConfig: object = {
    ["transactions"]: {
      defaultColumns: _defaultColumnsConciliation,
      selectableColumns: props.isHistoric
        ? _selectableColumnsConciliationHistoric
        : _selectableColumnsConciliation,
      maxSelection: 4,
    },
    ["summary"]: {
      defaultColumns: _defaultColumnsDeposit,
      selectableColumns: _selectableColumnsDeposit,
      maxSelection: 5,
    },
  };
  const [action, setAction] = useState<number>(0);

  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(
    null
  );

  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    moduleConfig[props.origin]?.defaultColumns
  );

  // Methods for selectable columns
  const handleCloseColumns = () => {
    setAnchorElement(null);
  };

  const auxlen: number = moduleConfig[props.origin]?.maxSelection;

  const handleChangeColumnCheck = (
    event: React.BaseSyntheticEvent,
    maxLen: number = auxlen
  ) => {
    const column: string = event.target.name;
    const auxColumnsSelected: string[] = [...selectedColumns];

    if (!event.target.checked) {
      const index: number = auxColumnsSelected.findIndex(
        (col: string) => col === column
      );

      if (index !== -1) auxColumnsSelected.splice(index, 1);
    } else {
      maxLen = typeof maxLen !== "number" ? auxlen : maxLen;
      if (selectedColumns.length >= maxLen) return;

      auxColumnsSelected.push(column);
    }
    setSelectedColumns(auxColumnsSelected);
  };

  const handleRestoreColumns = async () => {
    setAction(1);
    setSelectedColumns(moduleConfig[props.origin]?.defaultColumns);
  };

  const handleClickColumns = (event: React.BaseSyntheticEvent) => {
    setAnchorElement(event.currentTarget);
  };

  const handleDisplayedColumn = () => {
    dispatch(setActualHeaders([]));
    setAction(0);
    const actualHeaders: IHeaderTable[] = [
      ...staticColumns[props.origin][
        props.isHistoric
          ? SourceTransactionsEnum.HISTORIC
          : SourceTransactionsEnum.PENDING
      ],
    ];
    selectedColumns.forEach((columnId) => {
      const headerToPush: IHeaderTable = moduleConfig[
        props.origin
      ]?.selectableColumns.find(
        (colItem: IHeaderTable) => colItem.id === columnId
      );
      actualHeaders.push(headerToPush);
    });

    actualHeaders.push(
      lastStaticColumn[props.origin][
        props.isHistoric
          ? SourceTransactionsEnum.HISTORIC
          : SourceTransactionsEnum.PENDING
      ]
    );
    dispatch(setActualHeaders(actualHeaders));
    handleCloseColumns();
  };

  useEffect(() => {
    if (action === 1) {
      handleDisplayedColumn();
    }
  }, [selectedColumns]);

  return {
    anchorElement,
    handleChangeColumnCheck,
    handleCloseColumns,
    handleRestoreColumns,
    handleClickColumns,
    selectedColumns,
    selectableColumns: moduleConfig[props.origin]?.selectableColumns,
    maxSelection: moduleConfig[props.origin]?.maxSelection,
    handleDisplayedColumn,
  };
};
