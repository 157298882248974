import { ISubheaderProps } from "../../components/Subheader/Subheader.interfaces";
import { MerchantNodeResponse } from "../../../types/merchant_node_response";
import { Routes } from "./routes/routes";
import { FileType } from "../enums/file_type";

export const MERCHANT_QUERY_PARAM = "merchantId";
export const TRANSACTION_TYPE_QUERY_PARAM = "type";
export const FILE_PATH = "file.0";
export const FILE_NAME_PATH: string = FILE_PATH + ".name";
export const FILE_TYPE_PATH: string = FILE_PATH + ".type";
export const PREVIOUS_PAGE: number = -1;
export const FILE_MAX_MEGAS: number = 1;
export const TIMEOUT_REDIRECT: number = 5000;
export const CATALOG_FILE_TYPE: FileType[] = [
  FileType.PNG,
  FileType.JPG,
  FileType.JPEG,
  FileType.PDF,
  FileType.ZIP,
];
export const defaultSubheaderValues: ISubheaderProps = {
  country: "",
  id: "",
  redirectPath: "",
  title: "",
};

export const defaultMerchantNodeValues: MerchantNodeResponse = {
  constitutionalCountry: "",
  country: "",
  name: "",
  publicMerchantId: "",
};

export const buildSubheaderInfo = (merchantNode: MerchantNodeResponse) => ({
  country: merchantNode.country,
  id: merchantNode.publicMerchantId,
  redirectPath: `${Routes.CREATE_CUSTOMER}${merchantNode.publicMerchantId}`,
  title: merchantNode.name,
});
