import React from "react";
import {
  Button,
  ButtonGroup,
  MenuItem,
  MenuList,
  Popover,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { SyncRounded } from "@material-ui/icons";
import { CreditDebitNoteEnum } from "../../shared/infrastructure/CreditDebitNoteEnum";
import { InvoiceRecord } from "../../../types/remote/response_search_blling_by_filters";

export interface ButtonGenerateNcNdProps {
  isLoading: boolean;
  options?: { text: string; value: string }[];
  historicTrx: InvoiceRecord;
  modalKind: (value: CreditDebitNoteEnum) => void;
}

export const ButtonGenerateNcNd: React.FC<ButtonGenerateNcNdProps> = (
  props: ButtonGenerateNcNdProps
) => {
  const options: { value: CreditDebitNoteEnum; text: string }[] = [
    {
      value: CreditDebitNoteEnum.CREDIT,
      text: "Generar nota de crédito",
    },
    {
      value: CreditDebitNoteEnum.DEBIT,
      text: "Generar nota de débito",
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <ButtonGroup
        onClick={handleClick}
        variant="outlined"
        color="primary"
        size={"small"}
        disabled={props.isLoading}
        style={{ display: "flex", marginRight: "2rem" }}
      >
        <Button startIcon={<SyncRounded />}>Generar documento</Button>
        <Button style={{ minWidth: "1em" }} id="button_arrow">
          {props.isLoading ? (
            <CircularProgress size={10} color={"primary"} />
          ) : (
            <ArrowDropDownIcon />
          )}
        </Button>
      </ButtonGroup>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <MenuList id="split-button-menu">
          {options.map((option) => (
            <MenuItem
              style={{
                padding: "0.5rem 1.5rem",
                display: "flex",
              }}
              key={option.value}
              onClick={() => {
                setAnchorEl(null);
                props.modalKind(option.value);
              }}
            >
              {option.text}
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </React.Fragment>
  );
};
