import React from "react";
import { Box, Button, Grid } from "@material-ui/core";
import { ButtonText } from "../../shared/infrastructure/constants/DeferredConstants";
import { makeStyles } from "@material-ui/core/styles";

export interface ISaveDeferred {
  handleSave: () => void;
  handleCancel: () => void;
  disableSaveButton: boolean;
}

const useStyles = makeStyles(() => ({
  cancelButton: {
    background: "#F7FAFC",
    border: "1px solid #023365",
    color: "#023365",
    fontSize: "15px",
    fontWeight: 500,
    lineHeight: "10px",
    paddingBottom: "15px",
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingTop: "15px",
  },
}));

export const ButtonsSaveDeferred: React.FC<ISaveDeferred> = (
  props: ISaveDeferred
) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Box pt={3} display={"flex"} justifyContent={"flex-end"} width={"100%"}>
        <Box pl={2}>
          <Button
            variant="outlined"
            size="large"
            onClick={props.handleCancel}
            className={classes.cancelButton}
          >
            {ButtonText.GO_BACK}
          </Button>
        </Box>
        <Box pl={2}>
          <Button
            size="large"
            variant="contained"
            color={"primary"}
            onClick={props.handleSave}
            disabled={props.disableSaveButton}
          >
            {ButtonText.UPDATE}
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};
