import { useEffect, useState } from "react";
import { IUseModalDetailInfo } from "./useModalDetailInfo.interfaces";
import { IModalDetailInfo } from "../ModalDetailInfo.interfaces";
import { defaultTo, get, isEmpty } from "lodash";
import { TransactionInfo } from "../../../../types/transactions_data";

export const useModalDetailInfo = ({
  transactionInfo,
  modal,
}: IModalDetailInfo): IUseModalDetailInfo => {
  const [currentTransaction, setCurrentTransaction] = useState<TransactionInfo>(
    { ticket_number: "" }
  );

  const handleClose = (): void => {
    modal.setIsOpenModalDetailInfo(false);
  };

  useEffect(() => {
    if (!isEmpty(defaultTo(transactionInfo, undefined))) {
      setCurrentTransaction(get(transactionInfo, "_source", {}));
    }
  }, [transactionInfo]);

  return {
    handleClose,
    transactionInfo: currentTransaction,
  };
};
