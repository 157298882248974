import { ITableCellProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { TableBodyCellEnum } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/constants";
import { CellTextTooltipType } from "@kushki/connect-ui/dist/Components/Atoms/Table/CellTextTooltip/CellTextTooltip.enum";
import { filter, get, isEmpty, maxBy } from "lodash";
import { TooltipText } from "@shared/enum/TooltipEnum";
import { VariableRule } from "../../../types/rule_alarm";
import { VARIABLE_RECORD } from "@shared/constants/AlarmConfigConstants";
import { DateFormatEnum } from "@shared/constants/parseMonths";
import {
  flagCatalog,
  translateAlarmStatus,
} from "@shared/constants/AlarmTableCatalog";
import { ICellTagProps } from "@kushki/connect-ui/dist/Components/Atoms/Table/CellTag/CellTag.interfaces";
import { Alarm, Timeline } from "../../../types/alarm";
import { ActiveAlarmStatus } from "@shared/enum/ActiveAlarmStatusEnum";
import { formatTimestampWithTimezone } from "@shared/utils/parseDatesUtils";
import * as Locales from "date-fns/locale";

interface ITagOptions {
  status: string;
  color: ICellTagProps["color"];
}

export const buildTextCell = (
  line1: string,
  lines: CellTextTooltipType,
  line2?: string
): ITableCellProps => ({
  props: {
    cellProps: {
      align: "left",
      spacing: 1,
    },
    line1: line1,
    line2: line2 ?? "",
    type: lines,
  },
  type: TableBodyCellEnum.TEXT,
});

export const buildTagCell = (tag: ITagOptions): ITableCellProps => ({
  props: {
    cellProps: {
      align: "left",
      spacing: 1,
    },
    color: tag.color,
    text: tag.status,
  },
  type: TableBodyCellEnum.TAG,
});

export const buildTooltipCell = (
  value: string,
  tooltipText: string
): ITableCellProps => ({
  props: {
    align: "left",
    cellProps: {
      align: "left",
      spacing: 1,
    },
    icon: "right",
    line1: value,
    tooltipText: tooltipText,
    type: "oneLine",
  },
  type: TableBodyCellEnum.TEXT_TOOLTIP,
});

export const buildFlagCell = (value: string): ITableCellProps => ({
  props: {
    cellProps: {
      align: "left",
      spacing: 1,
    },
    country: flagCatalog[value] ?? "Default",
    type: "oneline",
  },
  type: TableBodyCellEnum.FLAG,
});

export const verifyHierarchy = (node: string): ITableCellProps => {
  if (isEmpty(node)) return buildTooltipCell("Kushki 2.0", TooltipText.Label);

  return buildTextCell(node, "oneLine");
};

export const getVariablesRule = (variables: VariableRule[]): string[] => {
  return variables.map(
    (variable: VariableRule) => VARIABLE_RECORD[variable.field]
  );
};

export const getLastCloseDate = (timeline: Timeline[]): number => {
  const valuesToFilter: string[] = [
    ActiveAlarmStatus.ROS,
    ActiveAlarmStatus.NORMAL,
  ];
  const closedAlarms = filter(timeline, (item) =>
    valuesToFilter.includes(get(item, "status", ""))
  );

  return get(maxBy(closedAlarms, "createdAt") as Timeline, "createdAt", 0);
};

export const parseDateTable = (timestamp: number, regionTz: string) => {
  if (timestamp === 0) return "";

  const date = formatTimestampWithTimezone(
    timestamp,
    DateFormatEnum.dd_MMM_yyyy,
    {
      locale: Locales.es,
      timeZone: regionTz,
    }
  ).split("/");

  date[1] = date[1].toUpperCase();

  return date.join("/");
};

const colorByAlarmStatus: Record<ActiveAlarmStatus, string> = {
  [ActiveAlarmStatus.PENDING]: "primary",
  [ActiveAlarmStatus.ROS]: "primary",
  [ActiveAlarmStatus.NORMAL]: "primary",
  [ActiveAlarmStatus.BLOCKED]: "primary",
  [ActiveAlarmStatus.UNLOCK]: "primary",
  [ActiveAlarmStatus.IN_PROCESS]: "primary",
  [ActiveAlarmStatus.INVESTIGATION]: "warning",
};

export const getTagOptions = (alarm: Alarm): ITagOptions => ({
  color: colorByAlarmStatus[alarm.status],
  status: translateAlarmStatus[alarm.status],
});
