import { AnalysisStateEnum } from "./constants/AnalysisStateEnum";

export const LanguageCatalog: ICategory[] = [
  {
    es: "Sale",
    lang: "sale",
  },
  {
    es: "Tarjeta",
    lang: "card",
  },
  {
    es: "Efectivo",
    lang: "cash",
  },
  {
    es: "Void",
    lang: "void",
  },
  {
    es: "Refund",
    lang: "refund",
  },
  {
    es: "Aprobado",
    lang: "approval",
  },
  {
    es: "Declinado",
    lang: "declined",
  },
  {
    es: "Inicializado",
    lang: "initialized",
  },
  {
    es: "Deffered",
    lang: "deffered",
  },
  {
    es: "PENDIENTE",
    lang: AnalysisStateEnum.PNDRFN.toLowerCase(),
  },
  {
    es: "REINGRESO",
    lang: AnalysisStateEnum.RTRY.toLowerCase(),
  },
  {
    es: "NO AUTORIZADO",
    lang: AnalysisStateEnum.NTHRZD.toLowerCase(),
  },
  {
    es: "AUTORIZADO",
    lang: AnalysisStateEnum.AUTHORIZED.toLowerCase(),
  },
];
export const translate = (text: string): string => {
  if (text === undefined || text === null) return "";
  const index: number = LanguageCatalog.findIndex(
    (detail: ICategory) => detail.lang === text.toLowerCase()
  );

  if (index >= 0) {
    const lang: string | undefined = LanguageCatalog[index]["es"];

    return lang !== undefined ? lang : text;
  }

  return text;
};

export interface ICategory {
  lang: string;
  es?: string;
}
