import React from "react";

const DownIcon = () => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 5.5L0 0.5H10L5 5.5Z" fill="#023365" />
  </svg>
);

export default DownIcon;
