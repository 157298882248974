import { defaultTo, get } from "lodash";
import { LocalStoragePath } from "../enums/local_storage_path";
import { RolesPath } from "../enums/roles_path";

export const getLocalStorageValueByPath = (path: string) =>
  JSON.parse(get(localStorage, path, "{}"));

export const getEmailValue = (): string =>
  defaultTo(localStorage.getItem(LocalStoragePath.EMAIL), "");

export const getMerchantIdValue = (): string =>
  defaultTo(localStorage.getItem(LocalStoragePath.MERCHANT_ID), "");

export const isInternalConsole = (): boolean => {
  const roles: object = getLocalStorageValueByPath(LocalStoragePath.ROLES);

  return get(roles, RolesPath.BACKOFFICE_ADMIN, false);
};
