/**
 * Catalog Brasil
 */
import { Category } from "../constants/information_constants";
import { CatalogsEnum } from "../interfaces/catalogs-enum";
import { CurrencyEnum } from "../interfaces/currency-enum";

export const CatalogBr: Record<string, Category[]> = {
  [CatalogsEnum.IdTypes]: [
    { defaultShareholderDocType: "0", name: "CPF", value: "0" },
    { defaultShareholderDocType: "1", name: "Pasaporte", value: "1" },
    { defaultShareholderDocType: "0", name: "CPFJ", value: "2" },
  ],
  [CatalogsEnum.Provinces]: [
    { name: "Acre", value: "AC" },
    { name: "Alagoas", value: "AL" },
    { name: "Amapá", value: "AP" },
    { name: "Amazonas", value: "AM" },
    { name: "Bahia", value: "BA" },
    { name: "Ceará", value: "CE" },
    { name: "Distrito Federal", value: "DF" },
    { name: "Espírito Santo", value: "ES" },
    { name: "Goiás", value: "GO" },
    { name: "Maranhão", value: "MA" },
    { name: "Mato Grosso", value: "MT" },
    { name: "Mato Grosso do Sul", value: "MS" },
    { name: "Minas Gerais", value: "MG" },
    { name: "Pará", value: "PA" },
    { name: "Paraíba", value: "PB" },
    { name: "Paraná", value: "PR" },
    { name: "Pernambuco", value: "PE" },
    { name: "Piauí", value: "PI" },
    { name: "Rio de Janeiro", value: "RJ" },
    { name: "Rio Grande do Norte", value: "RN" },
    { name: "Rio Grande do Sul", value: "RS" },
    { name: "Rondônia", value: "RO" },
    { name: "Roraima", value: "RR" },
    { name: "Santa Catarina", value: "SC" },
    { name: "São Paulo", value: "SP" },
    { name: "Sergipe", value: "SE" },
    { name: "Tocantins", value: "TO" },
  ],
  [CatalogsEnum.Cities]: [
    { name: "Acrelândia", parent: "AC", value: "Acrelandia" },
    { name: "Assis Brasil", parent: "AC", value: "Assis Brasil" },
    { name: "Brasiléia", parent: "AC", value: "Brasileia" },
    { name: "Bujari", parent: "AC", value: "Bujari" },
    { name: "Capixaba", parent: "AC", value: "Capixaba" },
    { name: "Cruzeiro do Sul", parent: "AC", value: "Cruzeiro do Sul" },
    { name: "Epitaciolândia", parent: "AC", value: "Epitaciolandia" },
    { name: "Feijó", parent: "AC", value: "Feijo" },
    { name: "Jordão", parent: "AC", value: "Jordao" },
    { name: "Mâncio Lima", parent: "AC", value: "Mancio Lima" },
    { name: "Manoel Urbano", parent: "AC", value: "Manoel Urbano" },
    {
      name: "Marechal Thaumaturgo",
      parent: "AC",
      value: "Marechal Thaumaturgo",
    },
    { name: "Plácido de Castro", parent: "AC", value: "Placido de Castro" },
    { name: "Porto Acre", parent: "AC", value: "Porto Acre" },
    { name: "Porto Walter", parent: "AC", value: "Porto Walter" },
    { name: "Rio Branco", parent: "AC", value: "Rio Branco" },
    { name: "Rodrigues Alves", parent: "AC", value: "Rodrigues Alves" },
    { name: "Santa Rosa do Purus", parent: "AC", value: "Santa Rosa do Purus" },
    { name: "Sena Madureira", parent: "AC", value: "Sena Madureira" },
    { name: "Senador Guiomard", parent: "AC", value: "Senador Guiomard" },
    { name: "Tarauacá", parent: "AC", value: "Tarauaca" },
    { name: "Xapuri", parent: "AC", value: "Xapuri" },
    { name: "Anadia", parent: "AL", value: "Anadia" },
    { name: "Arapiraca", parent: "AL", value: "Arapiraca" },
    { name: "Atalaia", parent: "AL", value: "Atalaia" },
    {
      name: "Barra de Santo Antônio",
      parent: "AL",
      value: "Barra de Santo Antonio",
    },
    { name: "Barra de São Miguel", parent: "AL", value: "Barra de Sao Miguel" },
    { name: "Batalha", parent: "AL", value: "Batalha" },
    { name: "Belém", parent: "AL", value: "Belem" },
    { name: "Belo Monte", parent: "AL", value: "Belo Monte" },
    { name: "Boca da Mata", parent: "AL", value: "Boca da Mata" },
    { name: "Branquinha", parent: "AL", value: "Branquinha" },
    { name: "Cacimbinhas", parent: "AL", value: "Cacimbinhas" },
    { name: "Cajueiro", parent: "AL", value: "Cajueiro" },
    { name: "Campestre", parent: "AL", value: "Campestre" },
    { name: "Campo Alegre", parent: "AL", value: "Campo Alegre" },
    { name: "Campo Grande", parent: "AL", value: "Campo Grande" },
    { name: "Canapi", parent: "AL", value: "Canapi" },
    { name: "Capela", parent: "AL", value: "Capela" },
    { name: "Carneiros", parent: "AL", value: "Carneiros" },
    { name: "Chã Preta", parent: "AL", value: "Cha Preta" },
    { name: "Coité do Nóia", parent: "AL", value: "Coite do Noia" },
    { name: "Colônia Leopoldina", parent: "AL", value: "Colonia Leopoldina" },
    { name: "Coqueiro Seco", parent: "AL", value: "Coqueiro Seco" },
    { name: "Coruripe", parent: "AL", value: "Coruripe" },
    { name: "Craíbas", parent: "AL", value: "Craibas" },
    { name: "Delmiro Gouveia", parent: "AL", value: "Delmiro Gouveia" },
    { name: "Dois Riachos", parent: "AL", value: "Dois Riachos" },
    { name: "Estrela de Alagoas", parent: "AL", value: "Estrela de Alagoas" },
    { name: "Feira Grande", parent: "AL", value: "Feira Grande" },
    { name: "Feliz Deserto", parent: "AL", value: "Feliz Deserto" },
    { name: "Flexeiras", parent: "AL", value: "Flexeiras" },
    { name: "Girau do Ponciano", parent: "AL", value: "Girau do Ponciano" },
    { name: "Ibateguara", parent: "AL", value: "Ibateguara" },
    { name: "Igaci", parent: "AL", value: "Igaci" },
    { name: "Igreja Nova", parent: "AL", value: "Igreja Nova" },
    { name: "Inhapi", parent: "AL", value: "Inhapi" },
    { name: "Jacaré dos Homens", parent: "AL", value: "Jacare dos Homens" },
    { name: "Jacuípe", parent: "AL", value: "Jacuipe" },
    { name: "Japaratinga", parent: "AL", value: "Japaratinga" },
    { name: "Jaramataia", parent: "AL", value: "Jaramataia" },
    { name: "Jequiá da Praia", parent: "AL", value: "Jequia da Praia" },
    { name: "Joaquim Gomes", parent: "AL", value: "Joaquim Gomes" },
    { name: "Jundiá", parent: "AL", value: "Jundia" },
    { name: "Junqueiro", parent: "AL", value: "Junqueiro" },
    { name: "Lagoa da Canoa", parent: "AL", value: "Lagoa da Canoa" },
    { name: "Limoeiro de Anadia", parent: "AL", value: "Limoeiro de Anadia" },
    { name: "Luziápolis", parent: "AL", value: "Luziapolis" },
    { name: "Maceió", parent: "AL", value: "Maceio" },
    { name: "Major Isidoro", parent: "AL", value: "Major Isidoro" },
    { name: "Mar Vermelho", parent: "AL", value: "Mar Vermelho" },
    { name: "Maragogi", parent: "AL", value: "Maragogi" },
    { name: "Maravilha", parent: "AL", value: "Maravilha" },
    { name: "Marechal Deodoro", parent: "AL", value: "Marechal Deodoro" },
    { name: "Maribondo", parent: "AL", value: "Maribondo" },
    { name: "Mata Grande", parent: "AL", value: "Mata Grande" },
    {
      name: "Matriz de Camaragibe",
      parent: "AL",
      value: "Matriz de Camaragibe",
    },
    { name: "Messias", parent: "AL", value: "Messias" },
    { name: "Minador do Negrão", parent: "AL", value: "Minador do Negrao" },
    { name: "Monteirópolis", parent: "AL", value: "Monteiropolis" },
    { name: "Murici", parent: "AL", value: "Murici" },
    { name: "Novo Lino", parent: "AL", value: "Novo Lino" },
    {
      name: "Olho D'Água das Flores",
      parent: "AL",
      value: "Olho D'Agua das Flores",
    },
    {
      name: "Olho D'Água do Casado",
      parent: "AL",
      value: "Olho D'Agua do Casado",
    },
    { name: "Olho D'Água Grande", parent: "AL", value: "Olho D'Agua Grande" },
    { name: "Olivença", parent: "AL", value: "Olivenca" },
    { name: "Ouro Branco", parent: "AL", value: "Ouro Branco" },
    { name: "Palestina", parent: "AL", value: "Palestina" },
    { name: "Palmeira dos Índios", parent: "AL", value: "Palmeira dos Indios" },
    { name: "Pão de Açúcar", parent: "AL", value: "Pao de Acucar" },
    { name: "Pariconha", parent: "AL", value: "Pariconha" },
    { name: "Paripueira", parent: "AL", value: "Paripueira" },
    { name: "Passo de Camaragibe", parent: "AL", value: "Passo de Camaragibe" },
    { name: "Paulo Jacinto", parent: "AL", value: "Paulo Jacinto" },
    { name: "Penedo", parent: "AL", value: "Penedo" },
    { name: "Piaçabuçu", parent: "AL", value: "Piacabucu" },
    { name: "Pilar", parent: "AL", value: "Pilar" },
    { name: "Pindoba", parent: "AL", value: "Pindoba" },
    { name: "Piranhas", parent: "AL", value: "Piranhas" },
    {
      name: "Poço das Trincheiras",
      parent: "AL",
      value: "Poco das Trincheiras",
    },
    { name: "Porto Calvo", parent: "AL", value: "Porto Calvo" },
    { name: "Porto de Pedras", parent: "AL", value: "Porto de Pedras" },
    {
      name: "Porto Real do Colégio",
      parent: "AL",
      value: "Porto Real do Colegio",
    },
    { name: "Quebrangulo", parent: "AL", value: "Quebrangulo" },
    { name: "Rio Largo", parent: "AL", value: "Rio Largo" },
    { name: "Roteiro", parent: "AL", value: "Roteiro" },
    {
      name: "Santa Luzia do Norte",
      parent: "AL",
      value: "Santa Luzia do Norte",
    },
    { name: "Santana do Ipanema", parent: "AL", value: "Santana do Ipanema" },
    { name: "Santana do Mundaú", parent: "AL", value: "Santana do Mundau" },
    { name: "São Brás", parent: "AL", value: "Sao Bras" },
    { name: "São José da Laje", parent: "AL", value: "Sao Jose da Laje" },
    { name: "São José da Tapera", parent: "AL", value: "Sao Jose da Tapera" },
    {
      name: "São Luís do Quitunde",
      parent: "AL",
      value: "Sao Luis do Quitunde",
    },
    {
      name: "São Miguel dos Campos",
      parent: "AL",
      value: "Sao Miguel dos Campos",
    },
    {
      name: "São Miguel dos Milagres",
      parent: "AL",
      value: "Sao Miguel dos Milagres",
    },
    { name: "São Sebastião", parent: "AL", value: "Sao Sebastiao" },
    { name: "Satuba", parent: "AL", value: "Satuba" },
    {
      name: "Senador Rui Palmeira",
      parent: "AL",
      value: "Senador Rui Palmeira",
    },
    { name: "Tanque D'Arca", parent: "AL", value: "Tanque D'Arca" },
    { name: "Taquarana", parent: "AL", value: "Taquarana" },
    { name: "Teotônio Vilela", parent: "AL", value: "Teotonio Vilela" },
    { name: "Traipu", parent: "AL", value: "Traipu" },
    { name: "União dos Palmares", parent: "AL", value: "Uniao dos Palmares" },
    { name: "Viçosa", parent: "AL", value: "Vicosa" },

    { name: "Alvaraes", parent: "AM", value: "Alvaraes" },
    { name: "Amatura", parent: "AM", value: "Amatura" },
    { name: "Anama", parent: "AM", value: "Anama" },
    { name: "Anori", parent: "AM", value: "Anori" },
    { name: "Apui", parent: "AM", value: "Apui" },
    { name: "Atalaia do Norte", parent: "AM", value: "Atalaia do Norte" },
    { name: "Autazes", parent: "AM", value: "Autazes" },
    { name: "B", parent: "AM", value: "B" },
    { name: "Barcelos", parent: "AM", value: "Barcelos" },
    { name: "Barreirinha", parent: "AM", value: "Barreirinha" },
    {
      name: "Benjamin Constant",
      parent: "AM",
      value: "Benjamin Constant",
    },
    { name: "Beruri", parent: "AM", value: "Beruri" },
    {
      name: "Boa Vista do Ramos",
      parent: "AM",
      value: "Boa Vista do Ramos",
    },
    { name: "Boca do Acre", parent: "AM", value: "Boca do Acre" },
    { name: "Borba", parent: "AM", value: "Borba" },
    { name: "Caapiranga", parent: "AM", value: "Caapiranga" },
    { name: "Canutama", parent: "AM", value: "Canutama" },
    { name: "Carauari", parent: "AM", value: "Carauari" },
    { name: "Careiro", parent: "AM", value: "Careiro" },
    {
      name: "Careiro da Varzea",
      parent: "AM",
      value: "Careiro da Varzea",
    },
    { name: "Coari", parent: "AM", value: "Coari" },
    { name: "Codajas", parent: "AM", value: "Codajas" },
    { name: "Eirunepe", parent: "AM", value: "Eirunepe" },
    { name: "Envira", parent: "AM", value: "Envira" },
    { name: "Fonte Boa", parent: "AM", value: "Fonte Boa" },
    { name: "Guajara", parent: "AM", value: "Guajara" },
    { name: "Humaita", parent: "AM", value: "Humaita" },
    { name: "Ipixuna", parent: "AM", value: "Ipixuna" },
    { name: "Iranduba", parent: "AM", value: "Iranduba" },
    { name: "Itacoatiara", parent: "AM", value: "Itacoatiara" },
    { name: "Itamarati", parent: "AM", value: "Itamarati" },
    { name: "Itapiranga", parent: "AM", value: "Itapiranga" },
    { name: "Japura", parent: "AM", value: "Japura" },
    { name: "Jurua", parent: "AM", value: "Jurua" },
    { name: "Jutai", parent: "AM", value: "Jutai" },
    { name: "Labrea", parent: "AM", value: "Labrea" },
    { name: "Manacapuru", parent: "AM", value: "Manacapuru" },
    { name: "Manaquiri", parent: "AM", value: "Manaquiri" },
    { name: "Manaus", parent: "AM", value: "Manaus" },
    { name: "Manicore", parent: "AM", value: "Manicore" },
    { name: "Maraa", parent: "AM", value: "Maraa" },
    { name: "Maues", parent: "AM", value: "Maues" },
    { name: "Nhamunda", parent: "AM", value: "Nhamunda" },
    {
      name: "Nova Olinda do Norte",
      parent: "AM",
      value: "Nova Olinda do Norte",
    },
    { name: "Novo Airao", parent: "AM", value: "Novo Airao" },
    { name: "Novo Aripuana", parent: "AM", value: "Novo Aripuana" },
    { name: "Parintins", parent: "AM", value: "Parintins" },
    { name: "Pauini", parent: "AM", value: "Pauini" },
    {
      name: "Presidente Figueiredo",
      parent: "AM",
      value: "Presidente Figueiredo",
    },
    { name: "Rio Preto da Eva", parent: "AM", value: "Rio Preto da Eva" },
    {
      name: "Santa Isabel do Rio Negro",
      parent: "AM",
      value: "Santa Isabel do Rio Negro",
    },
    {
      name: "Santo Antonio do Ica",
      parent: "AM",
      value: "Santo Antonio do Ica",
    },
    {
      name: "Sao Gabriel da Cachoeira",
      parent: "AM",
      value: "Sao Gabriel da Cachoeira",
    },
    {
      name: "Sao Paulo de Olivenca",
      parent: "AM",
      value: "Sao Paulo de Olivenca",
    },
    {
      name: "Sao Sebastiao do Uatuma",
      parent: "AM",
      value: "Sao Sebastiao do Uatuma",
    },
    { name: "Silves", parent: "AM", value: "Silves" },
    { name: "Tabatinga", parent: "AM", value: "Tabatinga" },
    { name: "Tapaua", parent: "AM", value: "Tapaua" },
    { name: "Tefe", parent: "AM", value: "Tefe" },
    { name: "Tonantins", parent: "AM", value: "Tonantins" },
    { name: "Uarini", parent: "AM", value: "Uarini" },
    { name: "Urucara", parent: "AM", value: "Urucara" },
    { name: "Urucurituba", parent: "AM", value: "Urucurituba" },

    { name: "Amapá", parent: "AP", value: "Amapa" },
    { name: "Calçoene", parent: "AP", value: "Calcoene" },
    { name: "Cutias", parent: "AP", value: "Cutias" },
    { name: "Ferreira Gomes", parent: "AP", value: "Ferreira Gomes" },
    { name: "Itaubal", parent: "AP", value: "Itaubal" },
    { name: "Laranjal do Jari", parent: "AP", value: "Laranjal do Jari" },
    { name: "Macapá", parent: "AP", value: "Macapa" },
    { name: "Mazagão", parent: "AP", value: "Mazagao" },
    { name: "Oiapoque", parent: "AP", value: "Oiapoque" },
    {
      name: "Pedra Branca do Amaparí",
      parent: "AP",
      value: "Pedra Branca do Amapari",
    },
    { name: "Porto Grande", parent: "AP", value: "Porto Grande" },
    { name: "Pracuúba", parent: "AP", value: "Pracuuba" },
    { name: "Santana", parent: "AP", value: "Santana" },
    { name: "Serra do Navio", parent: "AP", value: "Serra do Navio" },
    { name: "Tartarugalzinho", parent: "AP", value: "Tartarugalzinho" },
    { name: "Vitoria do Jari", parent: "AP", value: "Vitoria do Jari" },

    { name: "Abaira", parent: "BA", value: "Abaira" },
    { name: "Abare", parent: "BA", value: "Abare" },
    { name: "Acajutiba", parent: "BA", value: "Acajutiba" },
    { name: "Adustina", parent: "BA", value: "Adustina" },
    { name: "Agua Fria", parent: "BA", value: "Agua Fria" },
    { name: "Aiquara", parent: "BA", value: "Aiquara" },
    { name: "Alagoinhas", parent: "BA", value: "Alagoinhas" },
    { name: "Alcobaca", parent: "BA", value: "Alcobaca" },
    { name: "Almadina", parent: "BA", value: "Almadina" },
    { name: "Amargosa", parent: "BA", value: "Amargosa" },
    { name: "Amelia Rodrigues", parent: "BA", value: "Amelia Rodrigues" },
    { name: "America Dourada", parent: "BA", value: "America Dourada" },
    { name: "Anage", parent: "BA", value: "Anage" },
    { name: "Andarai", parent: "BA", value: "Andarai" },
    { name: "Andorinha", parent: "BA", value: "Andorinha" },
    { name: "Angical", parent: "BA", value: "Angical" },
    { name: "Anguera", parent: "BA", value: "Anguera" },
    { name: "Antas", parent: "BA", value: "Antas" },
    { name: "Antonio Cardoso", parent: "BA", value: "Antonio Cardoso" },
    { name: "Antonio Goncalves", parent: "BA", value: "Antonio Goncalves" },
    { name: "Apora", parent: "BA", value: "Apora" },
    { name: "Apuarema", parent: "BA", value: "Apuarema" },
    { name: "Aracas", parent: "BA", value: "Aracas" },
    { name: "Aracatu", parent: "BA", value: "Aracatu" },
    { name: "Araci", parent: "BA", value: "Araci" },
    { name: "Aramari", parent: "BA", value: "Aramari" },
    { name: "Arataca", parent: "BA", value: "Arataca" },
    { name: "Aratuipe", parent: "BA", value: "Aratuipe" },
    { name: "Aurelino Leal", parent: "BA", value: "Aurelino Leal" },
    { name: "Baianopolis", parent: "BA", value: "Baianopolis" },
    { name: "Baixa Grande", parent: "BA", value: "Baixa Grande" },
    { name: "Banzae", parent: "BA", value: "Banzae" },
    { name: "Barra", parent: "BA", value: "Barra" },
    { name: "Barra da Estiva", parent: "BA", value: "Barra da Estiva" },
    { name: "Barra do Choca", parent: "BA", value: "Barra do Choca" },
    { name: "Barra do Mendes", parent: "BA", value: "Barra do Mendes" },
    { name: "Barra do Rocha", parent: "BA", value: "Barra do Rocha" },
    { name: "Barreiras", parent: "BA", value: "Barreiras" },
    { name: "Barro Alto", parent: "BA", value: "Barro Alto" },
    { name: "Barro Preto", parent: "BA", value: "Barro Preto" },
    { name: "Belmonte", parent: "BA", value: "Belmonte" },
    { name: "Belo Campo", parent: "BA", value: "Belo Campo" },
    { name: "Biritinga", parent: "BA", value: "Biritinga" },
    { name: "Boa Nova", parent: "BA", value: "Boa Nova" },
    { name: "Boa Vista do Tupim", parent: "BA", value: "Boa Vista do Tupim" },
    { name: "Bom Jesus da Lapa", parent: "BA", value: "Bom Jesus da Lapa" },
    { name: "Bom Jesus da Serra", parent: "BA", value: "Bom Jesus da Serra" },
    { name: "Boninal", parent: "BA", value: "Boninal" },
    { name: "Bonito", parent: "BA", value: "Bonito" },
    { name: "Boquira", parent: "BA", value: "Boquira" },
    { name: "Botupora", parent: "BA", value: "Botupora" },
    { name: "Brejoes", parent: "BA", value: "Brejoes" },
    { name: "Brejolandia", parent: "BA", value: "Brejolandia" },
    { name: "Brotas de Macaubas", parent: "BA", value: "Brotas de Macaubas" },
    { name: "Brumado", parent: "BA", value: "Brumado" },
    { name: "Buerarema", parent: "BA", value: "Buerarema" },
    { name: "Buritirama", parent: "BA", value: "Buritirama" },
    { name: "Caatiba", parent: "BA", value: "Caatiba" },
    {
      name: "Cabaceiras do Paraguacu",
      parent: "BA",
      value: "Cabaceiras do Paraguacu",
    },
    { name: "Cachoeira", parent: "BA", value: "Cachoeira" },
    { name: "Cacule", parent: "BA", value: "Cacule" },
    { name: "Caem", parent: "BA", value: "Caem" },
    { name: "Caetanos", parent: "BA", value: "Caetanos" },
    { name: "Caetite", parent: "BA", value: "Caetite" },
    { name: "Cafarnaum", parent: "BA", value: "Cafarnaum" },
    { name: "Cairu", parent: "BA", value: "Cairu" },
    { name: "Caldeirao Grande", parent: "BA", value: "Caldeirao Grande" },
    { name: "Camacan", parent: "BA", value: "Camacan" },
    { name: "Camacari", parent: "BA", value: "Camacari" },
    { name: "Camamu", parent: "BA", value: "Camamu" },
    {
      name: "Campo Alegre de Lourdes",
      parent: "BA",
      value: "Campo Alegre de Lourdes",
    },
    { name: "Campo Formoso", parent: "BA", value: "Campo Formoso" },
    { name: "Canapolis", parent: "BA", value: "Canapolis" },
    { name: "Canarana", parent: "BA", value: "Canarana" },
    { name: "Canavieiras", parent: "BA", value: "Canavieiras" },
    { name: "Candeal", parent: "BA", value: "Candeal" },
    { name: "Candeias", parent: "BA", value: "Candeias" },
    { name: "Candiba", parent: "BA", value: "Candiba" },
    { name: "Candido Sales", parent: "BA", value: "Candido Sales" },
    { name: "Cansancao", parent: "BA", value: "Cansancao" },
    { name: "Canudos", parent: "BA", value: "Canudos" },
    {
      name: "Capela do Alto Alegre",
      parent: "BA",
      value: "Capela do Alto Alegre",
    },
    { name: "Capim Grosso", parent: "BA", value: "Capim Grosso" },
    { name: "Caraibas", parent: "BA", value: "Caraibas" },
    { name: "Caravelas", parent: "BA", value: "Caravelas" },
    { name: "Cardeal da Silva", parent: "BA", value: "Cardeal da Silva" },
    { name: "Carinhanha", parent: "BA", value: "Carinhanha" },
    { name: "Casa Nova", parent: "BA", value: "Casa Nova" },
    { name: "Castro Alves", parent: "BA", value: "Castro Alves" },
    { name: "Catolandia", parent: "BA", value: "Catolandia" },
    { name: "Catu", parent: "BA", value: "Catu" },
    { name: "Caturama", parent: "BA", value: "Caturama" },
    { name: "Central", parent: "BA", value: "Central" },
    { name: "Chorrocho", parent: "BA", value: "Chorrocho" },
    { name: "Cicero Dantas", parent: "BA", value: "Cicero Dantas" },
    { name: "Cipo", parent: "BA", value: "Cipo" },
    { name: "Coaraci", parent: "BA", value: "Coaraci" },
    { name: "Cocos", parent: "BA", value: "Cocos" },
    { name: "Conceicao da Feira", parent: "BA", value: "Conceicao da Feira" },
    {
      name: "Conceicao do Almeida",
      parent: "BA",
      value: "Conceicao do Almeida",
    },
    { name: "Conceicao do Coite", parent: "BA", value: "Conceicao do Coite" },
    {
      name: "Conceicao do Jacuipe",
      parent: "BA",
      value: "Conceicao do Jacuipe",
    },
    { name: "Conde", parent: "BA", value: "Conde" },
    { name: "Condeuba", parent: "BA", value: "Condeuba" },
    {
      name: "Contendas do Sincora",
      parent: "BA",
      value: "Contendas do Sincora",
    },
    { name: "Coracao de Maria", parent: "BA", value: "Coracao de Maria" },
    { name: "Cordeiros", parent: "BA", value: "Cordeiros" },
    { name: "Coribe", parent: "BA", value: "Coribe" },
    { name: "Coronel Joao Sa", parent: "BA", value: "Coronel Joao Sa" },
    { name: "Correntina", parent: "BA", value: "Correntina" },
    { name: "Cotegipe", parent: "BA", value: "Cotegipe" },
    { name: "Cravolandia", parent: "BA", value: "Cravolandia" },
    { name: "Crisopolis", parent: "BA", value: "Crisopolis" },
    { name: "Cristopolis", parent: "BA", value: "Cristopolis" },
    { name: "Cruz das Almas", parent: "BA", value: "Cruz das Almas" },
    { name: "Curaca", parent: "BA", value: "Curaca" },
    { name: "Dario Meira", parent: "BA", value: "Dario Meira" },
    { name: "Dias d'Avila", parent: "BA", value: "Dias d'Avila" },
    { name: "Dom Basilio", parent: "BA", value: "Dom Basilio" },
    { name: "Dom Macedo Costa", parent: "BA", value: "Dom Macedo Costa" },
    { name: "Elisio Medrado", parent: "BA", value: "Elisio Medrado" },
    { name: "Encruzilhada", parent: "BA", value: "Encruzilhada" },
    { name: "Entre Rios", parent: "BA", value: "Entre Rios" },
    { name: "Erico Cardoso", parent: "BA", value: "Erico Cardoso" },
    { name: "Esplanada", parent: "BA", value: "Esplanada" },
    { name: "Euclides da Cunha", parent: "BA", value: "Euclides da Cunha" },
    { name: "Eunapolis", parent: "BA", value: "Eunapolis" },
    { name: "Fatima", parent: "BA", value: "Fatima" },
    { name: "Feira da Mata", parent: "BA", value: "Feira da Mata" },
    { name: "Feira de Santana", parent: "BA", value: "Feira de Santana" },
    { name: "Filadelfia", parent: "BA", value: "Filadelfia" },
    { name: "Firmino Alves", parent: "BA", value: "Firmino Alves" },
    { name: "Floresta Azul", parent: "BA", value: "Floresta Azul" },
    {
      name: "Formosa do Rio Preto",
      parent: "BA",
      value: "Formosa do Rio Preto",
    },
    { name: "Gandu", parent: "BA", value: "Gandu" },
    { name: "Gaviao", parent: "BA", value: "Gaviao" },
    { name: "Gentio do Ouro", parent: "BA", value: "Gentio do Ouro" },
    { name: "Gloria", parent: "BA", value: "Gloria" },
    { name: "Gongogi", parent: "BA", value: "Gongogi" },
    {
      name: "Governador Mangabeira",
      parent: "BA",
      value: "Governador Mangabeira",
    },
    { name: "Guajeru", parent: "BA", value: "Guajeru" },
    { name: "Guanambi", parent: "BA", value: "Guanambi" },
    { name: "Guaratinga", parent: "BA", value: "Guaratinga" },
    { name: "Heliopolis", parent: "BA", value: "Heliopolis" },
    { name: "Iacu", parent: "BA", value: "Iacu" },
    { name: "Ibiassuce", parent: "BA", value: "Ibiassuce" },
    { name: "Ibicarai", parent: "BA", value: "Ibicarai" },
    { name: "Ibicoara", parent: "BA", value: "Ibicoara" },
    { name: "Ibicui", parent: "BA", value: "Ibicui" },
    { name: "Ibipeba", parent: "BA", value: "Ibipeba" },
    { name: "Ibipitanga", parent: "BA", value: "Ibipitanga" },
    { name: "Ibiquera", parent: "BA", value: "Ibiquera" },
    { name: "Ibirapitanga", parent: "BA", value: "Ibirapitanga" },
    { name: "Ibirapua", parent: "BA", value: "Ibirapua" },
    { name: "Ibirataia", parent: "BA", value: "Ibirataia" },
    { name: "Ibitiara", parent: "BA", value: "Ibitiara" },
    { name: "Ibitita", parent: "BA", value: "Ibitita" },
    { name: "Ibotirama", parent: "BA", value: "Ibotirama" },
    { name: "Ichu", parent: "BA", value: "Ichu" },
    { name: "Igapora", parent: "BA", value: "Igapora" },
    { name: "Igrapiuna", parent: "BA", value: "Igrapiuna" },
    { name: "Iguai", parent: "BA", value: "Iguai" },
    { name: "Ilheus", parent: "BA", value: "Ilheus" },
    { name: "Inhambupe", parent: "BA", value: "Inhambupe" },
    { name: "Ipecaeta", parent: "BA", value: "Ipecaeta" },
    { name: "Ipiau", parent: "BA", value: "Ipiau" },
    { name: "Ipira", parent: "BA", value: "Ipira" },
    { name: "Ipupiara", parent: "BA", value: "Ipupiara" },
    { name: "Irajuba", parent: "BA", value: "Irajuba" },
    { name: "Iramaia", parent: "BA", value: "Iramaia" },
    { name: "Iraquara", parent: "BA", value: "Iraquara" },
    { name: "Irara", parent: "BA", value: "Irara" },
    { name: "Irece", parent: "BA", value: "Irece" },
    { name: "Itabela", parent: "BA", value: "Itabela" },
    { name: "Itaberaba", parent: "BA", value: "Itaberaba" },
    { name: "Itabuna", parent: "BA", value: "Itabuna" },
    { name: "Itacare", parent: "BA", value: "Itacare" },
    { name: "Itaete", parent: "BA", value: "Itaete" },
    { name: "Itagi", parent: "BA", value: "Itagi" },
    { name: "Itagiba", parent: "BA", value: "Itagiba" },
    { name: "Itagimirim", parent: "BA", value: "Itagimirim" },
    { name: "Itaguacu da Bahia", parent: "BA", value: "Itaguacu da Bahia" },
    { name: "Itaju do Colonia", parent: "BA", value: "Itaju do Colonia" },
    { name: "Itajuipe", parent: "BA", value: "Itajuipe" },
    { name: "Itamaraju", parent: "BA", value: "Itamaraju" },
    { name: "Itamari", parent: "BA", value: "Itamari" },
    { name: "Itambe", parent: "BA", value: "Itambe" },
    { name: "Itanagra", parent: "BA", value: "Itanagra" },
    { name: "Itanhem", parent: "BA", value: "Itanhem" },
    { name: "Itaparica", parent: "BA", value: "Itaparica" },
    { name: "Itape", parent: "BA", value: "Itape" },
    { name: "Itapebi", parent: "BA", value: "Itapebi" },
    { name: "Itapetinga", parent: "BA", value: "Itapetinga" },
    { name: "Itapicuru", parent: "BA", value: "Itapicuru" },
    { name: "Itapitanga", parent: "BA", value: "Itapitanga" },
    { name: "Itaquara", parent: "BA", value: "Itaquara" },
    { name: "Itarantim", parent: "BA", value: "Itarantim" },
    { name: "Itatim", parent: "BA", value: "Itatim" },
    { name: "Itirucu", parent: "BA", value: "Itirucu" },
    { name: "Itiuba", parent: "BA", value: "Itiuba" },
    { name: "Itororo", parent: "BA", value: "Itororo" },
    { name: "Ituacu", parent: "BA", value: "Ituacu" },
    { name: "Itubera", parent: "BA", value: "Itubera" },
    { name: "Iuiu", parent: "BA", value: "Iuiu" },
    { name: "Jaborandi", parent: "BA", value: "Jaborandi" },
    { name: "Jacaraci", parent: "BA", value: "Jacaraci" },
    { name: "Jacobina", parent: "BA", value: "Jacobina" },
    { name: "Jaguaquara", parent: "BA", value: "Jaguaquara" },
    { name: "Jaguarari", parent: "BA", value: "Jaguarari" },
    { name: "Jaguaripe", parent: "BA", value: "Jaguaripe" },
    { name: "Jandaira", parent: "BA", value: "Jandaira" },
    { name: "Jequie", parent: "BA", value: "Jequie" },
    { name: "Jeremoabo", parent: "BA", value: "Jeremoabo" },
    { name: "Jiquirica", parent: "BA", value: "Jiquirica" },
    { name: "Jitauna", parent: "BA", value: "Jitauna" },
    { name: "Joao Dourado", parent: "BA", value: "Joao Dourado" },
    { name: "Juazeiro", parent: "BA", value: "Juazeiro" },
    { name: "Jucurucu", parent: "BA", value: "Jucurucu" },
    { name: "Jussara", parent: "BA", value: "Jussara" },
    { name: "Jussari", parent: "BA", value: "Jussari" },
    { name: "Jussiape", parent: "BA", value: "Jussiape" },
    { name: "Lafaiete Coutinho", parent: "BA", value: "Lafaiete Coutinho" },
    { name: "Lagoa Real", parent: "BA", value: "Lagoa Real" },
    { name: "Laje", parent: "BA", value: "Laje" },
    { name: "Lajedao", parent: "BA", value: "Lajedao" },
    { name: "Lajedinho", parent: "BA", value: "Lajedinho" },
    { name: "Lajedo do Tabocal", parent: "BA", value: "Lajedo do Tabocal" },
    { name: "Lamarao", parent: "BA", value: "Lamarao" },
    { name: "Lapao", parent: "BA", value: "Lapao" },
    { name: "Lauro de Freitas", parent: "BA", value: "Lauro de Freitas" },
    { name: "Lencois", parent: "BA", value: "Lencois" },
    { name: "Licinio de Almeida", parent: "BA", value: "Licinio de Almeida" },
    {
      name: "Livramento do Brumado",
      parent: "BA",
      value: "Livramento do Brumado",
    },
    { name: "Macajuba", parent: "BA", value: "Macajuba" },
    { name: "Macarani", parent: "BA", value: "Macarani" },
    { name: "Macaubas", parent: "BA", value: "Macaubas" },
    { name: "Macurure", parent: "BA", value: "Macurure" },
    { name: "Madre de Deus", parent: "BA", value: "Madre de Deus" },
    { name: "Maetinga", parent: "BA", value: "Maetinga" },
    { name: "Maiquinique", parent: "BA", value: "Maiquinique" },
    { name: "Mairi", parent: "BA", value: "Mairi" },
    { name: "Malhada", parent: "BA", value: "Malhada" },
    { name: "Malhada de Pedras", parent: "BA", value: "Malhada de Pedras" },
    { name: "Manoel Vitorino", parent: "BA", value: "Manoel Vitorino" },
    { name: "Mansidao", parent: "BA", value: "Mansidao" },
    { name: "Maracas", parent: "BA", value: "Maracas" },
    { name: "Maragogipe", parent: "BA", value: "Maragogipe" },
    { name: "Marau", parent: "BA", value: "Marau" },
    { name: "Marcionilio Souza", parent: "BA", value: "Marcionilio Souza" },
    { name: "Mascote", parent: "BA", value: "Mascote" },
    { name: "Mata de Sao Joao", parent: "BA", value: "Mata de Sao Joao" },
    { name: "Matina", parent: "BA", value: "Matina" },
    { name: "Medeiros Neto", parent: "BA", value: "Medeiros Neto" },
    { name: "Miguel Calmon", parent: "BA", value: "Miguel Calmon" },
    { name: "Milagres", parent: "BA", value: "Milagres" },
    { name: "Mirangaba", parent: "BA", value: "Mirangaba" },
    { name: "Mirante", parent: "BA", value: "Mirante" },
    { name: "Monte Santo", parent: "BA", value: "Monte Santo" },
    { name: "Morpara", parent: "BA", value: "Morpara" },
    { name: "Morro do Chapeu", parent: "BA", value: "Morro do Chapeu" },
    { name: "Mortugaba", parent: "BA", value: "Mortugaba" },
    { name: "Mucuge", parent: "BA", value: "Mucuge" },
    { name: "Mucuri", parent: "BA", value: "Mucuri" },
    { name: "Mulungu do Morro", parent: "BA", value: "Mulungu do Morro" },
    { name: "Mundo Novo", parent: "BA", value: "Mundo Novo" },
    { name: "Muniz Ferreira", parent: "BA", value: "Muniz Ferreira" },
    {
      name: "Muquem de Sao Francisco",
      parent: "BA",
      value: "Muquem de Sao Francisco",
    },
    { name: "Muritiba", parent: "BA", value: "Muritiba" },
    { name: "Mutuipe", parent: "BA", value: "Mutuipe" },
    { name: "Nazare", parent: "BA", value: "Nazare" },
    { name: "Nilo Pecanha", parent: "BA", value: "Nilo Pecanha" },
    { name: "Nordestina", parent: "BA", value: "Nordestina" },
    { name: "Nova Canaa", parent: "BA", value: "Nova Canaa" },
    { name: "Nova Fatima", parent: "BA", value: "Nova Fatima" },
    { name: "Nova Ibia", parent: "BA", value: "Nova Ibia" },
    { name: "Nova Itarana", parent: "BA", value: "Nova Itarana" },
    { name: "Nova Redencao", parent: "BA", value: "Nova Redencao" },
    { name: "Nova Soure", parent: "BA", value: "Nova Soure" },
    { name: "Nova Vicosa", parent: "BA", value: "Nova Vicosa" },
    { name: "Novo Horizonte", parent: "BA", value: "Novo Horizonte" },
    { name: "Novo Triunfo", parent: "BA", value: "Novo Triunfo" },
    { name: "Olindina", parent: "BA", value: "Olindina" },
    {
      name: "Oliveira dos Brejinhos",
      parent: "BA",
      value: "Oliveira dos Brejinhos",
    },
    { name: "Ouricangas", parent: "BA", value: "Ouricangas" },
    { name: "Ourolandia", parent: "BA", value: "Ourolandia" },
    {
      name: "Palmas de Monte Alto",
      parent: "BA",
      value: "Palmas de Monte Alto",
    },
    { name: "Palmeiras", parent: "BA", value: "Palmeiras" },
    { name: "Paramirim", parent: "BA", value: "Paramirim" },
    { name: "Paratinga", parent: "BA", value: "Paratinga" },
    { name: "Paripiranga", parent: "BA", value: "Paripiranga" },
    { name: "Pau Brasil", parent: "BA", value: "Pau Brasil" },
    { name: "Paulo Afonso", parent: "BA", value: "Paulo Afonso" },
    { name: "Pe de Serra", parent: "BA", value: "Pe de Serra" },
    { name: "Pedrao", parent: "BA", value: "Pedrao" },
    { name: "Pedro Alexandre", parent: "BA", value: "Pedro Alexandre" },
    { name: "Piata", parent: "BA", value: "Piata" },
    { name: "Pilao Arcado", parent: "BA", value: "Pilao Arcado" },
    { name: "Pindai", parent: "BA", value: "Pindai" },
    { name: "Pindobacu", parent: "BA", value: "Pindobacu" },
    { name: "Pintadas", parent: "BA", value: "Pintadas" },
    { name: "Pirai do Norte", parent: "BA", value: "Pirai do Norte" },
    { name: "Piripa", parent: "BA", value: "Piripa" },
    { name: "Piritiba", parent: "BA", value: "Piritiba" },
    { name: "Planaltino", parent: "BA", value: "Planaltino" },
    { name: "Planalto", parent: "BA", value: "Planalto" },
    { name: "Pocoes", parent: "BA", value: "Pocoes" },
    { name: "Pojuca", parent: "BA", value: "Pojuca" },
    { name: "Ponto Novo", parent: "BA", value: "Ponto Novo" },
    { name: "Porto Seguro", parent: "BA", value: "Porto Seguro" },
    { name: "Potiragua", parent: "BA", value: "Potiragua" },
    { name: "Prado", parent: "BA", value: "Prado" },
    { name: "Presidente Dutra", parent: "BA", value: "Presidente Dutra" },
    {
      name: "Presidente Janio Quadros",
      parent: "BA",
      value: "Presidente Janio Quadros",
    },
    {
      name: "Presidente Tancredo Neves",
      parent: "BA",
      value: "Presidente Tancredo Neves",
    },
    { name: "Queimadas", parent: "BA", value: "Queimadas" },
    { name: "Quijingue", parent: "BA", value: "Quijingue" },
    { name: "Quixabeira", parent: "BA", value: "Quixabeira" },
    { name: "Rafael Jambeiro", parent: "BA", value: "Rafael Jambeiro" },
    { name: "Remanso", parent: "BA", value: "Remanso" },
    { name: "Retirolandia", parent: "BA", value: "Retirolandia" },
    { name: "Riachao das Neves", parent: "BA", value: "Riachao das Neves" },
    { name: "Riachao do Jacuipe", parent: "BA", value: "Riachao do Jacuipe" },
    { name: "Riacho de Santana", parent: "BA", value: "Riacho de Santana" },
    { name: "Ribeira do Amparo", parent: "BA", value: "Ribeira do Amparo" },
    { name: "Ribeira do Pombal", parent: "BA", value: "Ribeira do Pombal" },
    { name: "Ribeirao do Largo", parent: "BA", value: "Ribeirao do Largo" },
    { name: "Rio Real", parent: "BA", value: "Rio Real" },
    { name: "Rio de Contas", parent: "BA", value: "Rio de Contas" },
    { name: "Rio do Antonio", parent: "BA", value: "Rio do Antonio" },
    { name: "Rio do Pires", parent: "BA", value: "Rio do Pires" },
    { name: "Rodelas", parent: "BA", value: "Rodelas" },
    { name: "Ruy Barbosa", parent: "BA", value: "Ruy Barbosa" },
    {
      name: "Salinas da Margarida",
      parent: "BA",
      value: "Salinas da Margarida",
    },
    { name: "Salvador", parent: "BA", value: "Salvador" },
    { name: "Santa Barbara", parent: "BA", value: "Santa Barbara" },
    { name: "Santa Brigida", parent: "BA", value: "Santa Brigida" },
    { name: "Santa Cruz Cabralia", parent: "BA", value: "Santa Cruz Cabralia" },
    {
      name: "Santa Cruz da Vitoria",
      parent: "BA",
      value: "Santa Cruz da Vitoria",
    },
    { name: "Santa Ines", parent: "BA", value: "Santa Ines" },
    { name: "Santa Luzia", parent: "BA", value: "Santa Luzia" },
    {
      name: "Santa Maria da Vitoria",
      parent: "BA",
      value: "Santa Maria da Vitoria",
    },
    {
      name: "Santa Rita de Cassia",
      parent: "BA",
      value: "Santa Rita de Cassia",
    },
    { name: "Santa Teresinha", parent: "BA", value: "Santa Teresinha" },
    { name: "Santaluz", parent: "BA", value: "Santaluz" },
    { name: "Santana", parent: "BA", value: "Santana" },
    { name: "Santanopolis", parent: "BA", value: "Santanopolis" },
    { name: "Santo Amaro", parent: "BA", value: "Santo Amaro" },
    {
      name: "Santo Antonio de Jesus",
      parent: "BA",
      value: "Santo Antonio de Jesus",
    },
    { name: "Santo Estevao", parent: "BA", value: "Santo Estevao" },
    { name: "Sao Desiderio", parent: "BA", value: "Sao Desiderio" },
    { name: "Sao Domingos", parent: "BA", value: "Sao Domingos" },
    { name: "Sao Felipe", parent: "BA", value: "Sao Felipe" },
    { name: "Sao Felix", parent: "BA", value: "Sao Felix" },
    { name: "Sao Felix do Coribe", parent: "BA", value: "Sao Felix do Coribe" },
    {
      name: "Sao Francisco do Conde",
      parent: "BA",
      value: "Sao Francisco do Conde",
    },
    { name: "Sao Gabriel", parent: "BA", value: "Sao Gabriel" },
    {
      name: "Sao Goncalo dos Campos",
      parent: "BA",
      value: "Sao Goncalo dos Campos",
    },
    { name: "Sao Jose da Vitoria", parent: "BA", value: "Sao Jose da Vitoria" },
    { name: "Sao Jose do Jacuipe", parent: "BA", value: "Sao Jose do Jacuipe" },
    {
      name: "Sao Miguel das Matas",
      parent: "BA",
      value: "Sao Miguel das Matas",
    },
    {
      name: "Sao Sebastiao do Passe",
      parent: "BA",
      value: "Sao Sebastiao do Passe",
    },
    { name: "Sapeacu", parent: "BA", value: "Sapeacu" },
    { name: "Satiro Dias", parent: "BA", value: "Satiro Dias" },
    { name: "Saubara", parent: "BA", value: "Saubara" },
    { name: "Saude", parent: "BA", value: "Saude" },
    { name: "Seabra", parent: "BA", value: "Seabra" },
    {
      name: "Sebastiao Laranjeiras",
      parent: "BA",
      value: "Sebastiao Laranjeiras",
    },
    { name: "Senhor do Bonfim", parent: "BA", value: "Senhor do Bonfim" },
    { name: "Sento Se", parent: "BA", value: "Sento Se" },
    { name: "Serra Dourada", parent: "BA", value: "Serra Dourada" },
    { name: "Serra Preta", parent: "BA", value: "Serra Preta" },
    { name: "Serra do Ramalho", parent: "BA", value: "Serra do Ramalho" },
    { name: "Serrinha", parent: "BA", value: "Serrinha" },
    { name: "Serrolandia", parent: "BA", value: "Serrolandia" },
    { name: "Simoes Filho", parent: "BA", value: "Simoes Filho" },
    { name: "Sitio do Mato", parent: "BA", value: "Sitio do Mato" },
    { name: "Sitio do Quinto", parent: "BA", value: "Sitio do Quinto" },
    { name: "Sobradinho", parent: "BA", value: "Sobradinho" },
    { name: "Souto Soares", parent: "BA", value: "Souto Soares" },
    {
      name: "Tabocas do Brejo Velho",
      parent: "BA",
      value: "Tabocas do Brejo Velho",
    },
    { name: "Tanhacu", parent: "BA", value: "Tanhacu" },
    { name: "Tanque Novo", parent: "BA", value: "Tanque Novo" },
    { name: "Tanquinho", parent: "BA", value: "Tanquinho" },
    { name: "Taperoa", parent: "BA", value: "Taperoa" },
    { name: "Tapiramuta", parent: "BA", value: "Tapiramuta" },
    { name: "Teixeira de Freitas", parent: "BA", value: "Teixeira de Freitas" },
    { name: "Teodoro Sampaio", parent: "BA", value: "Teodoro Sampaio" },
    { name: "Teofilandia", parent: "BA", value: "Teofilandia" },
    { name: "Teolandia", parent: "BA", value: "Teolandia" },
    { name: "Terra Nova", parent: "BA", value: "Terra Nova" },
    { name: "Tremedal", parent: "BA", value: "Tremedal" },
    { name: "Tucano", parent: "BA", value: "Tucano" },
    { name: "Uaua", parent: "BA", value: "Uaua" },
    { name: "Ubaira", parent: "BA", value: "Ubaira" },
    { name: "Ubaitaba", parent: "BA", value: "Ubaitaba" },
    { name: "Ubata", parent: "BA", value: "Ubata" },
    { name: "Uibai", parent: "BA", value: "Uibai" },
    { name: "Umburanas", parent: "BA", value: "Umburanas" },
    { name: "Una", parent: "BA", value: "Una" },
    { name: "Urandi", parent: "BA", value: "Urandi" },
    { name: "Urucuca", parent: "BA", value: "Urucuca" },
    { name: "Utinga", parent: "BA", value: "Utinga" },
    { name: "Valenca", parent: "BA", value: "Valenca" },
    { name: "Valente", parent: "BA", value: "Valente" },
    { name: "Varzea Nova", parent: "BA", value: "Varzea Nova" },
    { name: "Varzea da Roca", parent: "BA", value: "Varzea da Roca" },
    { name: "Varzea do Poco", parent: "BA", value: "Varzea do Poco" },
    { name: "Varzedo", parent: "BA", value: "Varzedo" },
    { name: "Vera Cruz", parent: "BA", value: "Vera Cruz" },
    { name: "Vereda", parent: "BA", value: "Vereda" },
    {
      name: "Vitoria da Conquista",
      parent: "BA",
      value: "Vitoria da Conquista",
    },
    { name: "Wagner", parent: "BA", value: "Wagner" },
    { name: "Wanderley", parent: "BA", value: "Wanderley" },
    { name: "Wenceslau Guimaraes", parent: "BA", value: "Wenceslau Guimaraes" },
    { name: "Xique-Xique", parent: "BA", value: "Xique-Xique" },

    { name: "Abaiara", parent: "CE", value: "Abaiara" },
    { name: "Acarape", parent: "CE", value: "Acarape" },
    { name: "Acarau", parent: "CE", value: "Acarau" },
    { name: "Acopiara", parent: "CE", value: "Acopiara" },
    { name: "Aiuaba", parent: "CE", value: "Aiuaba" },
    { name: "Alcantaras", parent: "CE", value: "Alcantaras" },
    { name: "Altaneira", parent: "CE", value: "Altaneira" },
    { name: "Alto Santo", parent: "CE", value: "Alto Santo" },
    { name: "Amontada", parent: "CE", value: "Amontada" },
    { name: "Antonina do Norte", parent: "CE", value: "Antonina do Norte" },
    { name: "Apuiares", parent: "CE", value: "Apuiares" },
    { name: "Aquiraz", parent: "CE", value: "Aquiraz" },
    { name: "Aracati", parent: "CE", value: "Aracati" },
    { name: "Aracoiaba", parent: "CE", value: "Aracoiaba" },
    { name: "Ararenda", parent: "CE", value: "Ararenda" },
    { name: "Araripe", parent: "CE", value: "Araripe" },
    { name: "Aratuba", parent: "CE", value: "Aratuba" },
    { name: "Arneiroz", parent: "CE", value: "Arneiroz" },
    { name: "Assare", parent: "CE", value: "Assare" },
    { name: "Aurora", parent: "CE", value: "Aurora" },
    { name: "Baixio", parent: "CE", value: "Baixio" },
    { name: "Banabuiu", parent: "CE", value: "Banabuiu" },
    { name: "Barbalha", parent: "CE", value: "Barbalha" },
    { name: "Barreira", parent: "CE", value: "Barreira" },
    { name: "Barro", parent: "CE", value: "Barro" },
    { name: "Barroquinha", parent: "CE", value: "Barroquinha" },
    { name: "Baturite", parent: "CE", value: "Baturite" },
    { name: "Beberibe", parent: "CE", value: "Beberibe" },
    { name: "Bela Cruz", parent: "CE", value: "Bela Cruz" },
    { name: "Boa Viagem", parent: "CE", value: "Boa Viagem" },
    { name: "Brejo Santo", parent: "CE", value: "Brejo Santo" },
    { name: "Camocim", parent: "CE", value: "Camocim" },
    { name: "Campos Sales", parent: "CE", value: "Campos Sales" },
    { name: "Caninde", parent: "CE", value: "Caninde" },
    { name: "Capistrano", parent: "CE", value: "Capistrano" },
    { name: "Caridade", parent: "CE", value: "Caridade" },
    { name: "Carire", parent: "CE", value: "Carire" },
    { name: "Caririacu", parent: "CE", value: "Caririacu" },
    { name: "Carius", parent: "CE", value: "Carius" },
    { name: "Carnaubal", parent: "CE", value: "Carnaubal" },
    { name: "Cascavel", parent: "CE", value: "Cascavel" },
    { name: "Catarina", parent: "CE", value: "Catarina" },
    { name: "Catunda", parent: "CE", value: "Catunda" },
    { name: "Caucaia", parent: "CE", value: "Caucaia" },
    { name: "Cedro", parent: "CE", value: "Cedro" },
    { name: "Chaval", parent: "CE", value: "Chaval" },
    { name: "Choro", parent: "CE", value: "Choro" },
    { name: "Chorozinho", parent: "CE", value: "Chorozinho" },
    { name: "Coreau", parent: "CE", value: "Coreau" },
    { name: "Crateus", parent: "CE", value: "Crateus" },
    { name: "Crato", parent: "CE", value: "Crato" },
    { name: "Croata", parent: "CE", value: "Croata" },
    { name: "Cruz", parent: "CE", value: "Cruz" },
    {
      name: "Deputado Irapuan Pinheiro",
      parent: "CE",
      value: "Deputado Irapuan Pinheiro",
    },
    { name: "Erere", parent: "CE", value: "Erere" },
    { name: "Eusebio", parent: "CE", value: "Eusebio" },
    { name: "Farias Brito", parent: "CE", value: "Farias Brito" },
    { name: "Forquilha", parent: "CE", value: "Forquilha" },
    { name: "Fortaleza", parent: "CE", value: "Fortaleza" },
    { name: "Fortim", parent: "CE", value: "Fortim" },
    { name: "Frecheirinha", parent: "CE", value: "Frecheirinha" },
    { name: "General Sampaio", parent: "CE", value: "General Sampaio" },
    { name: "Graca", parent: "CE", value: "Graca" },
    { name: "Granja", parent: "CE", value: "Granja" },
    { name: "Granjeiro", parent: "CE", value: "Granjeiro" },
    { name: "Groairas", parent: "CE", value: "Groairas" },
    { name: "Guaiuba", parent: "CE", value: "Guaiuba" },
    { name: "Guaraciaba do Norte", parent: "CE", value: "Guaraciaba do Norte" },
    { name: "Guaramiranga", parent: "CE", value: "Guaramiranga" },
    { name: "Hidrolandia", parent: "CE", value: "Hidrolandia" },
    { name: "Horizonte", parent: "CE", value: "Horizonte" },
    { name: "Ibaretama", parent: "CE", value: "Ibaretama" },
    { name: "Ibiapina", parent: "CE", value: "Ibiapina" },
    { name: "Ibicuitinga", parent: "CE", value: "Ibicuitinga" },
    { name: "Icapui", parent: "CE", value: "Icapui" },
    { name: "Ico", parent: "CE", value: "Ico" },
    { name: "Iguatu", parent: "CE", value: "Iguatu" },
    { name: "Independencia", parent: "CE", value: "Independencia" },
    { name: "Ipaporanga", parent: "CE", value: "Ipaporanga" },
    { name: "Ipaumirim", parent: "CE", value: "Ipaumirim" },
    { name: "Ipu", parent: "CE", value: "Ipu" },
    { name: "Ipueiras", parent: "CE", value: "Ipueiras" },
    { name: "Iracema", parent: "CE", value: "Iracema" },
    { name: "Iraucuba", parent: "CE", value: "Iraucuba" },
    { name: "Itaicaba", parent: "CE", value: "Itaicaba" },
    { name: "Itaitinga", parent: "CE", value: "Itaitinga" },
    { name: "Itapage", parent: "CE", value: "Itapage" },
    { name: "Itapipoca", parent: "CE", value: "Itapipoca" },
    { name: "Itapiuna", parent: "CE", value: "Itapiuna" },
    { name: "Itarema", parent: "CE", value: "Itarema" },
    { name: "Itatira", parent: "CE", value: "Itatira" },
    { name: "Jaguaretama", parent: "CE", value: "Jaguaretama" },
    { name: "Jaguaribara", parent: "CE", value: "Jaguaribara" },
    { name: "Jaguaribe", parent: "CE", value: "Jaguaribe" },
    { name: "Jaguaruana", parent: "CE", value: "Jaguaruana" },
    { name: "Jardim", parent: "CE", value: "Jardim" },
    { name: "Jati", parent: "CE", value: "Jati" },
    {
      name: "Jijoca de Jericoacoara",
      parent: "CE",
      value: "Jijoca de Jericoacoara",
    },
    { name: "Juazeiro do Norte", parent: "CE", value: "Juazeiro do Norte" },
    { name: "Jucas", parent: "CE", value: "Jucas" },
    {
      name: "Lavras da Mangabeira",
      parent: "CE",
      value: "Lavras da Mangabeira",
    },
    { name: "Limoeiro do Norte", parent: "CE", value: "Limoeiro do Norte" },
    { name: "Madalena", parent: "CE", value: "Madalena" },
    { name: "Maracanau", parent: "CE", value: "Maracanau" },
    { name: "Maranguape", parent: "CE", value: "Maranguape" },
    { name: "Marco", parent: "CE", value: "Marco" },
    { name: "Martinopole", parent: "CE", value: "Martinopole" },
    { name: "Massape", parent: "CE", value: "Massape" },
    { name: "Mauriti", parent: "CE", value: "Mauriti" },
    { name: "Meruoca", parent: "CE", value: "Meruoca" },
    { name: "Milagres", parent: "CE", value: "Milagres" },
    { name: "Milha", parent: "CE", value: "Milha" },
    { name: "Miraima", parent: "CE", value: "Miraima" },
    { name: "Missao Velha", parent: "CE", value: "Missao Velha" },
    { name: "Mombaca", parent: "CE", value: "Mombaca" },
    { name: "Monsenhor Tabosa", parent: "CE", value: "Monsenhor Tabosa" },
    { name: "Morada Nova", parent: "CE", value: "Morada Nova" },
    { name: "Moraujo", parent: "CE", value: "Moraujo" },
    { name: "Morrinhos", parent: "CE", value: "Morrinhos" },
    { name: "Mucambo", parent: "CE", value: "Mucambo" },
    { name: "Mulungu", parent: "CE", value: "Mulungu" },
    { name: "Nova Olinda", parent: "CE", value: "Nova Olinda" },
    { name: "Nova Russas", parent: "CE", value: "Nova Russas" },
    { name: "Novo Oriente", parent: "CE", value: "Novo Oriente" },
    { name: "Ocara", parent: "CE", value: "Ocara" },
    { name: "Oros", parent: "CE", value: "Oros" },
    { name: "Pacajus", parent: "CE", value: "Pacajus" },
    { name: "Pacatuba", parent: "CE", value: "Pacatuba" },
    { name: "Pacoti", parent: "CE", value: "Pacoti" },
    { name: "Pacuja", parent: "CE", value: "Pacuja" },
    { name: "Palhano", parent: "CE", value: "Palhano" },
    { name: "Palmacia", parent: "CE", value: "Palmacia" },
    { name: "Paracuru", parent: "CE", value: "Paracuru" },
    { name: "Paraipaba", parent: "CE", value: "Paraipaba" },
    { name: "Parambu", parent: "CE", value: "Parambu" },
    { name: "Paramoti", parent: "CE", value: "Paramoti" },
    { name: "Pedra Branca", parent: "CE", value: "Pedra Branca" },
    { name: "Penaforte", parent: "CE", value: "Penaforte" },
    { name: "Pentecoste", parent: "CE", value: "Pentecoste" },
    { name: "Pereiro", parent: "CE", value: "Pereiro" },
    { name: "Pindoretama", parent: "CE", value: "Pindoretama" },
    { name: "Piquet Carneiro", parent: "CE", value: "Piquet Carneiro" },
    { name: "Pires Ferreira", parent: "CE", value: "Pires Ferreira" },
    { name: "Poranga", parent: "CE", value: "Poranga" },
    { name: "Porteiras", parent: "CE", value: "Porteiras" },
    { name: "Potengi", parent: "CE", value: "Potengi" },
    { name: "Potiretama", parent: "CE", value: "Potiretama" },
    { name: "Quiterianopolis", parent: "CE", value: "Quiterianopolis" },
    { name: "Quixada", parent: "CE", value: "Quixada" },
    { name: "Quixela", parent: "CE", value: "Quixela" },
    { name: "Quixeramobim", parent: "CE", value: "Quixeramobim" },
    { name: "Quixere", parent: "CE", value: "Quixere" },
    { name: "Redencao", parent: "CE", value: "Redencao" },
    { name: "Reriutaba", parent: "CE", value: "Reriutaba" },
    { name: "Russas", parent: "CE", value: "Russas" },
    { name: "Saboeiro", parent: "CE", value: "Saboeiro" },
    { name: "Salitre", parent: "CE", value: "Salitre" },
    { name: "Santa Quiteria", parent: "CE", value: "Santa Quiteria" },
    { name: "Santana do Acarau", parent: "CE", value: "Santana do Acarau" },
    { name: "Santana do Cariri", parent: "CE", value: "Santana do Cariri" },
    { name: "Sao Benedito", parent: "CE", value: "Sao Benedito" },
    {
      name: "Sao Goncalo do Amarante",
      parent: "CE",
      value: "Sao Goncalo do Amarante",
    },
    {
      name: "Sao Joao do Jaguaribe",
      parent: "CE",
      value: "Sao Joao do Jaguaribe",
    },
    { name: "Sao Luis do Curu", parent: "CE", value: "Sao Luis do Curu" },
    { name: "Senador Pompeu", parent: "CE", value: "Senador Pompeu" },
    { name: "Senador Sa", parent: "CE", value: "Senador Sa" },
    { name: "Sobral", parent: "CE", value: "Sobral" },
    { name: "Solonopole", parent: "CE", value: "Solonopole" },
    { name: "Tabuleiro do Norte", parent: "CE", value: "Tabuleiro do Norte" },
    { name: "Tamboril", parent: "CE", value: "Tamboril" },
    { name: "Tarrafas", parent: "CE", value: "Tarrafas" },
    { name: "Taua", parent: "CE", value: "Taua" },
    { name: "Tejucuoca", parent: "CE", value: "Tejucuoca" },
    { name: "Tiangua", parent: "CE", value: "Tiangua" },
    { name: "Trairi", parent: "CE", value: "Trairi" },
    { name: "Tururu", parent: "CE", value: "Tururu" },
    { name: "Ubajara", parent: "CE", value: "Ubajara" },
    { name: "Umari", parent: "CE", value: "Umari" },
    { name: "Umirim", parent: "CE", value: "Umirim" },
    { name: "Uruburetama", parent: "CE", value: "Uruburetama" },
    { name: "Uruoca", parent: "CE", value: "Uruoca" },
    { name: "Varjota", parent: "CE", value: "Varjota" },
    { name: "Varzea Alegre", parent: "CE", value: "Varzea Alegre" },
    { name: "Vicosa do Ceara", parent: "CE", value: "Vicosa do Ceara" },

    { name: "Brasília", parent: "DF", value: "Brasilia" },
    { name: "Brazlândia", parent: "DF", value: "Brazlandia" },
    { name: "Candangolândia", parent: "DF", value: "Candangolandia" },
    { name: "Ceilândia", parent: "DF", value: "Ceilandia" },
    { name: "Cruzeiro", parent: "DF", value: "Cruzeiro" },
    { name: "Fercal", parent: "DF", value: "Fercal" },
    { name: "Gama", parent: "DF", value: "Gama" },
    { name: "Guará", parent: "DF", value: "Guara" },
    { name: "Itapoã", parent: "DF", value: "Itapoa" },
    { name: "Jardim Botânico", parent: "DF", value: "Jardim Botanico" },
    { name: "Lago Norte", parent: "DF", value: "Lago Norte" },
    { name: "Lago Sul", parent: "DF", value: "Lago Sul" },
    { name: "Núcleo Bandeirante", parent: "DF", value: "Nucleo Bandeirante" },
    { name: "Paranoá", parent: "DF", value: "Paranoa" },
    { name: "Park Way", parent: "DF", value: "Park Way" },
    { name: "Planaltina", parent: "DF", value: "Planaltina" },
    { name: "Recanto das Emas", parent: "DF", value: "Recanto das Emas" },
    { name: "Riacho Fundo", parent: "DF", value: "Riacho Fundo" },
    { name: "Riacho Fundo II", parent: "DF", value: "Riacho Fundo II" },
    { name: "Samambaia", parent: "DF", value: "Samambaia" },
    { name: "Santa Maria", parent: "DF", value: "Santa Maria" },
    { name: "Sobradinho", parent: "DF", value: "Sobradinho" },
    { name: "Sobradinho II", parent: "DF", value: "Sobradinho II" },
    { name: "Sudoeste/Octogonal", parent: "DF", value: "Sudoeste/Octogonal" },
    { name: "São Sebastião", parent: "DF", value: "Sao Sebastiao" },
    { name: "Taguatinga", parent: "DF", value: "Taguatinga" },
    { name: "Varjão", parent: "DF", value: "Varjao" },
    { name: "Vicente Pires", parent: "DF", value: "Vicente Pires" },
    { name: "Águas Claras", parent: "DF", value: "Aguas Claras" },

    { name: "Afonso Claudio", parent: "ES", value: "Afonso Claudio" },
    { name: "Agua Doce do Norte", parent: "ES", value: "Agua Doce do Norte" },
    { name: "Aguia Branca", parent: "ES", value: "Aguia Branca" },
    { name: "Alegre", parent: "ES", value: "Alegre" },
    { name: "Alfredo Chaves", parent: "ES", value: "Alfredo Chaves" },
    { name: "Alto Rio Novo", parent: "ES", value: "Alto Rio Novo" },
    { name: "Anchieta", parent: "ES", value: "Anchieta" },
    { name: "Apiaca", parent: "ES", value: "Apiaca" },
    { name: "Aracruz", parent: "ES", value: "Aracruz" },
    { name: "Atilio Vivacqua", parent: "ES", value: "Atilio Vivacqua" },
    { name: "Baixo Guandu", parent: "ES", value: "Baixo Guandu" },
    {
      name: "Barra de Sao Francisco",
      parent: "ES",
      value: "Barra de Sao Francisco",
    },
    { name: "Boa Esperanca", parent: "ES", value: "Boa Esperanca" },
    { name: "Bom Jesus do Norte", parent: "ES", value: "Bom Jesus do Norte" },
    { name: "Brejetuba", parent: "ES", value: "Brejetuba" },
    {
      name: "Cachoeiro de Itapemirim",
      parent: "ES",
      value: "Cachoeiro de Itapemirim",
    },
    { name: "Cariacica", parent: "ES", value: "Cariacica" },
    { name: "Castelo", parent: "ES", value: "Castelo" },
    { name: "Colatina", parent: "ES", value: "Colatina" },
    { name: "Conceicao da Barra", parent: "ES", value: "Conceicao da Barra" },
    {
      name: "Conceicao do Castelo",
      parent: "ES",
      value: "Conceicao do Castelo",
    },
    {
      name: "Divino de Sao Lourenco",
      parent: "ES",
      value: "Divino de Sao Lourenco",
    },
    { name: "Domingos Martins", parent: "ES", value: "Domingos Martins" },
    { name: "Dores do Rio Preto", parent: "ES", value: "Dores do Rio Preto" },
    { name: "Ecoporanga", parent: "ES", value: "Ecoporanga" },
    { name: "Fundao", parent: "ES", value: "Fundao" },
    { name: "Guacui", parent: "ES", value: "Guacui" },
    { name: "Guarapari", parent: "ES", value: "Guarapari" },
    { name: "Ibatiba", parent: "ES", value: "Ibatiba" },
    { name: "Ibiracu", parent: "ES", value: "Ibiracu" },
    { name: "Ibitirama", parent: "ES", value: "Ibitirama" },
    { name: "Iconha", parent: "ES", value: "Iconha" },
    { name: "Irupi", parent: "ES", value: "Irupi" },
    { name: "Itaguacu", parent: "ES", value: "Itaguacu" },
    { name: "Itapemirim", parent: "ES", value: "Itapemirim" },
    { name: "Itarana", parent: "ES", value: "Itarana" },
    { name: "Iuna", parent: "ES", value: "Iuna" },
    { name: "Jaguare", parent: "ES", value: "Jaguare" },
    { name: "Jeronimo Monteiro", parent: "ES", value: "Jeronimo Monteiro" },
    { name: "Joao Neiva", parent: "ES", value: "Joao Neiva" },
    { name: "Laranja da Terra", parent: "ES", value: "Laranja da Terra" },
    { name: "Linhares", parent: "ES", value: "Linhares" },
    { name: "Mantenopolis", parent: "ES", value: "Mantenopolis" },
    { name: "Marataizes", parent: "ES", value: "Marataizes" },
    { name: "Marechal Floriano", parent: "ES", value: "Marechal Floriano" },
    { name: "Marilandia", parent: "ES", value: "Marilandia" },
    { name: "Mimoso do Sul", parent: "ES", value: "Mimoso do Sul" },
    { name: "Montanha", parent: "ES", value: "Montanha" },
    { name: "Mucurici", parent: "ES", value: "Mucurici" },
    { name: "Muniz Freire", parent: "ES", value: "Muniz Freire" },
    { name: "Muqui", parent: "ES", value: "Muqui" },
    { name: "Nova Venecia", parent: "ES", value: "Nova Venecia" },
    { name: "Pancas", parent: "ES", value: "Pancas" },
    { name: "Pedro Canario", parent: "ES", value: "Pedro Canario" },
    { name: "Pinheiros", parent: "ES", value: "Pinheiros" },
    { name: "Piuma", parent: "ES", value: "Piuma" },
    { name: "Ponto Belo", parent: "ES", value: "Ponto Belo" },
    { name: "Presidente Kennedy", parent: "ES", value: "Presidente Kennedy" },
    { name: "Rio Bananal", parent: "ES", value: "Rio Bananal" },
    { name: "Rio Novo do Sul", parent: "ES", value: "Rio Novo do Sul" },
    { name: "Santa Leopoldina", parent: "ES", value: "Santa Leopoldina" },
    {
      name: "Santa Maria de Jetiba",
      parent: "ES",
      value: "Santa Maria de Jetiba",
    },
    { name: "Santa Teresa", parent: "ES", value: "Santa Teresa" },
    {
      name: "Sao Domingos do Norte",
      parent: "ES",
      value: "Sao Domingos do Norte",
    },
    {
      name: "Sao Gabriel da Palha",
      parent: "ES",
      value: "Sao Gabriel da Palha",
    },
    { name: "Sao Jose do Calcado", parent: "ES", value: "Sao Jose do Calcado" },
    { name: "Sao Mateus", parent: "ES", value: "Sao Mateus" },
    { name: "Sao Roque do Canaa", parent: "ES", value: "Sao Roque do Canaa" },
    { name: "Serra", parent: "ES", value: "Serra" },
    { name: "Sooretama", parent: "ES", value: "Sooretama" },
    { name: "Vargem Alta", parent: "ES", value: "Vargem Alta" },
    {
      name: "Venda Nova do Imigrante",
      parent: "ES",
      value: "Venda Nova do Imigrante",
    },
    { name: "Viana", parent: "ES", value: "Viana" },
    { name: "Vila Pavao", parent: "ES", value: "Vila Pavao" },
    { name: "Vila Valerio", parent: "ES", value: "Vila Valerio" },
    { name: "Vila Velha", parent: "ES", value: "Vila Velha" },
    { name: "Vitoria", parent: "ES", value: "Vitoria" },

    { name: "Abadia de Goiás", parent: "GO", value: "Abadia de Goias" },
    { name: "Abadiânia", parent: "GO", value: "Abadiania" },
    { name: "Acreúna", parent: "GO", value: "Acreuna" },
    { name: "Adelândia", parent: "GO", value: "Adelandia" },
    { name: "Alexânia", parent: "GO", value: "Alexania" },
    { name: "Aloândia", parent: "GO", value: "Aloandia" },
    { name: "Alto Horizonte", parent: "GO", value: "Alto Horizonte" },
    {
      name: "Alto Paraíso de Goiás",
      parent: "GO",
      value: "Alto Paraiso de Goias",
    },
    { name: "Alvorada do Norte", parent: "GO", value: "Alvorada do Norte" },
    { name: "Amaralina", parent: "GO", value: "Amaralina" },
    { name: "Americano do Brasil", parent: "GO", value: "Americano do Brasil" },
    { name: "Amorinópolis", parent: "GO", value: "Amorinopolis" },
    { name: "Anhanguera", parent: "GO", value: "Anhanguera" },
    { name: "Anicuns", parent: "GO", value: "Anicuns" },
    { name: "Anápolis", parent: "GO", value: "Anapolis" },
    {
      name: "Aparecida de Goiânia",
      parent: "GO",
      value: "Aparecida de Goiania",
    },
    {
      name: "Aparecida do Rio Doce",
      parent: "GO",
      value: "Aparecida do Rio Doce",
    },
    { name: "Aporé", parent: "GO", value: "Apore" },
    { name: "Aragarças", parent: "GO", value: "Aragarcas" },
    { name: "Aragoiânia", parent: "GO", value: "Aragoiania" },
    { name: "Araguapaz", parent: "GO", value: "Araguapaz" },
    { name: "Araçu", parent: "GO", value: "Aracu" },
    { name: "Arenópolis", parent: "GO", value: "Arenopolis" },
    { name: "Aruanã", parent: "GO", value: "Aruana" },
    { name: "Aurilândia", parent: "GO", value: "Aurilandia" },
    { name: "Avelinópolis", parent: "GO", value: "Avelinopolis" },
    { name: "Baliza", parent: "GO", value: "Baliza" },
    { name: "Barro Alto", parent: "GO", value: "Barro Alto" },
    { name: "Bela Vista de Goiás", parent: "GO", value: "Bela Vista de Goias" },
    { name: "Bom Jardim de Goiás", parent: "GO", value: "Bom Jardim de Goias" },
    { name: "Bom Jesus de Goiás", parent: "GO", value: "Bom Jesus de Goias" },
    { name: "Bonfinópolis", parent: "GO", value: "Bonfinopolis" },
    { name: "Bonópolis", parent: "GO", value: "Bonopolis" },
    { name: "Brazabrantes", parent: "GO", value: "Brazabrantes" },
    { name: "Britânia", parent: "GO", value: "Britania" },
    { name: "Buriti Alegre", parent: "GO", value: "Buriti Alegre" },
    { name: "Buriti de Goiás", parent: "GO", value: "Buriti de Goias" },
    { name: "Buritinópolis", parent: "GO", value: "Buritinopolis" },
    { name: "Cabeceiras", parent: "GO", value: "Cabeceiras" },
    { name: "Cachoeira Alta", parent: "GO", value: "Cachoeira Alta" },
    { name: "Cachoeira Dourada", parent: "GO", value: "Cachoeira Dourada" },
    { name: "Cachoeira de Goiás", parent: "GO", value: "Cachoeira de Goias" },
    { name: "Caiapônia", parent: "GO", value: "Caiaponia" },
    { name: "Caldas Novas", parent: "GO", value: "Caldas Novas" },
    { name: "Caldazinha", parent: "GO", value: "Caldazinha" },
    { name: "Campestre de Goiás", parent: "GO", value: "Campestre de Goias" },
    { name: "Campinaçu", parent: "GO", value: "Campinacu" },
    { name: "Campinorte", parent: "GO", value: "Campinorte" },
    {
      name: "Campo Alegre de Goiás",
      parent: "GO",
      value: "Campo Alegre de Goias",
    },
    {
      name: "Campo Limpo de Goiás",
      parent: "GO",
      value: "Campo Limpo de Goias",
    },
    { name: "Campos Belos", parent: "GO", value: "Campos Belos" },
    { name: "Campos Verdes", parent: "GO", value: "Campos Verdes" },
    { name: "Carmo do Rio Verde", parent: "GO", value: "Carmo do Rio Verde" },
    { name: "Castelândia", parent: "GO", value: "Castelandia" },
    { name: "Catalão", parent: "GO", value: "Catalao" },
    { name: "Caturaí", parent: "GO", value: "Caturai" },
    { name: "Cavalcante", parent: "GO", value: "Cavalcante" },
    { name: "Caçu", parent: "GO", value: "Cacu" },
    { name: "Ceres", parent: "GO", value: "Ceres" },
    { name: "Cezarina", parent: "GO", value: "Cezarina" },
    { name: "Chapadão do Céu", parent: "GO", value: "Chapadao do Ceu" },
    { name: "Cidade Ocidental", parent: "GO", value: "Cidade Ocidental" },
    { name: "Cocalzinho de Goiás", parent: "GO", value: "Cocalzinho de Goias" },
    { name: "Colinas do Sul", parent: "GO", value: "Colinas do Sul" },
    { name: "Corumbaíba", parent: "GO", value: "Corumbaiba" },
    { name: "Corumbá de Goiás", parent: "GO", value: "Corumba de Goias" },
    { name: "Cristalina", parent: "GO", value: "Cristalina" },
    { name: "Cristianópolis", parent: "GO", value: "Cristianopolis" },
    { name: "Crixás", parent: "GO", value: "Crixas" },
    { name: "Cromínia", parent: "GO", value: "Crominia" },
    { name: "Cumari", parent: "GO", value: "Cumari" },
    { name: "Córrego do Ouro", parent: "GO", value: "Corrego do Ouro" },
    { name: "Damianópolis", parent: "GO", value: "Damianopolis" },
    { name: "Damolândia", parent: "GO", value: "Damolandia" },
    { name: "Davinópolis", parent: "GO", value: "Davinopolis" },
    { name: "Diorama", parent: "GO", value: "Diorama" },
    {
      name: "Divinópolis de Goiás",
      parent: "GO",
      value: "Divinopolis de Goias",
    },
    { name: "Doverlândia", parent: "GO", value: "Doverlandia" },
    { name: "Edealina", parent: "GO", value: "Edealina" },
    { name: "Edéia", parent: "GO", value: "Edeia" },
    { name: "Estrela do Norte", parent: "GO", value: "Estrela do Norte" },
    { name: "Faina", parent: "GO", value: "Faina" },
    { name: "Fazenda Nova", parent: "GO", value: "Fazenda Nova" },
    { name: "Firminópolis", parent: "GO", value: "Firminopolis" },
    { name: "Flores de Goiás", parent: "GO", value: "Flores de Goias" },
    { name: "Formosa", parent: "GO", value: "Formosa" },
    { name: "Formoso", parent: "GO", value: "Formoso" },
    { name: "Gameleira de Goiás", parent: "GO", value: "Gameleira de Goias" },
    { name: "Goiandira", parent: "GO", value: "Goiandira" },
    { name: "Goianira", parent: "GO", value: "Goianira" },
    { name: "Goianápolis", parent: "GO", value: "Goianapolis" },
    { name: "Goianésia", parent: "GO", value: "Goianesia" },
    { name: "Goiatuba", parent: "GO", value: "Goiatuba" },
    { name: "Goiás", parent: "GO", value: "Goias" },
    { name: "Goiânia", parent: "GO", value: "Goiania" },
    { name: "Gouvelândia", parent: "GO", value: "Gouvelandia" },
    { name: "Guapó", parent: "GO", value: "Guapo" },
    { name: "Guarani de Goiás", parent: "GO", value: "Guarani de Goias" },
    { name: "Guaraíta", parent: "GO", value: "Guaraita" },
    { name: "Guarinos", parent: "GO", value: "Guarinos" },
    { name: "Heitoraí", parent: "GO", value: "Heitorai" },
    { name: "Hidrolina", parent: "GO", value: "Hidrolina" },
    { name: "Hidrolândia", parent: "GO", value: "Hidrolandia" },
    { name: "Iaciara", parent: "GO", value: "Iaciara" },
    { name: "Inaciolândia", parent: "GO", value: "Inaciolandia" },
    { name: "Indiara", parent: "GO", value: "Indiara" },
    { name: "Inhumas", parent: "GO", value: "Inhumas" },
    { name: "Ipameri", parent: "GO", value: "Ipameri" },
    { name: "Ipiranga de Goiás", parent: "GO", value: "Ipiranga de Goias" },
    { name: "Iporá", parent: "GO", value: "Ipora" },
    { name: "Israelândia", parent: "GO", value: "Israelandia" },
    { name: "Itaberaí", parent: "GO", value: "Itaberai" },
    { name: "Itaguari", parent: "GO", value: "Itaguari" },
    { name: "Itaguaru", parent: "GO", value: "Itaguaru" },
    { name: "Itajá", parent: "GO", value: "Itaja" },
    { name: "Itapaci", parent: "GO", value: "Itapaci" },
    { name: "Itapirapuã", parent: "GO", value: "Itapirapua" },
    { name: "Itapuranga", parent: "GO", value: "Itapuranga" },
    { name: "Itarumã", parent: "GO", value: "Itaruma" },
    { name: "Itauçu", parent: "GO", value: "Itaucu" },
    { name: "Itumbiara", parent: "GO", value: "Itumbiara" },
    { name: "Ivolândia", parent: "GO", value: "Ivolandia" },
    { name: "Jandaia", parent: "GO", value: "Jandaia" },
    { name: "Jaraguá", parent: "GO", value: "Jaragua" },
    { name: "Jataí", parent: "GO", value: "Jatai" },
    { name: "Jaupaci", parent: "GO", value: "Jaupaci" },
    { name: "Jesúpolis", parent: "GO", value: "Jesupolis" },
    { name: "Joviânia", parent: "GO", value: "Joviania" },
    { name: "Jussara", parent: "GO", value: "Jussara" },
    { name: "Lagoa Santa", parent: "GO", value: "Lagoa Santa" },
    { name: "Leopoldo de Bulhões", parent: "GO", value: "Leopoldo de Bulhoes" },
    { name: "Luziânia", parent: "GO", value: "Luziania" },
    { name: "Mairipotaba", parent: "GO", value: "Mairipotaba" },
    { name: "Mambaí", parent: "GO", value: "Mambai" },
    { name: "Mara Rosa", parent: "GO", value: "Mara Rosa" },
    { name: "Marzagão", parent: "GO", value: "Marzagao" },
    { name: "Matrinchã", parent: "GO", value: "Matrincha" },
    { name: "Maurilândia", parent: "GO", value: "Maurilandia" },
    { name: "Mimoso de Goiás", parent: "GO", value: "Mimoso de Goias" },
    { name: "Minaçu", parent: "GO", value: "Minacu" },
    { name: "Mineiros", parent: "GO", value: "Mineiros" },
    { name: "Moiporá", parent: "GO", value: "Moipora" },
    {
      name: "Monte Alegre de Goiás",
      parent: "GO",
      value: "Monte Alegre de Goias",
    },
    {
      name: "Montes Claros de Goiás",
      parent: "GO",
      value: "Montes Claros de Goias",
    },
    { name: "Montividiu", parent: "GO", value: "Montividiu" },
    { name: "Montividiu do Norte", parent: "GO", value: "Montividiu do Norte" },
    { name: "Morrinhos", parent: "GO", value: "Morrinhos" },
    {
      name: "Morro Agudo de Goiás",
      parent: "GO",
      value: "Morro Agudo de Goias",
    },
    { name: "Mossâmedes", parent: "GO", value: "Mossamedes" },
    { name: "Mozarlândia", parent: "GO", value: "Mozarlandia" },
    { name: "Mundo Novo", parent: "GO", value: "Mundo Novo" },
    { name: "Mutunópolis", parent: "GO", value: "Mutunopolis" },
    { name: "Nazário", parent: "GO", value: "Nazario" },
    { name: "Nerópolis", parent: "GO", value: "Neropolis" },
    { name: "Niquelândia", parent: "GO", value: "Niquelandia" },
    { name: "Nova América", parent: "GO", value: "Nova America" },
    { name: "Nova Aurora", parent: "GO", value: "Nova Aurora" },
    { name: "Nova Crixás", parent: "GO", value: "Nova Crixas" },
    { name: "Nova Glória", parent: "GO", value: "Nova Gloria" },
    {
      name: "Nova Iguaçu de Goiás",
      parent: "GO",
      value: "Nova Iguacu de Goias",
    },
    { name: "Nova Roma", parent: "GO", value: "Nova Roma" },
    { name: "Nova Veneza", parent: "GO", value: "Nova Veneza" },
    { name: "Novo Brasil", parent: "GO", value: "Novo Brasil" },
    { name: "Novo Gama", parent: "GO", value: "Novo Gama" },
    { name: "Novo Planalto", parent: "GO", value: "Novo Planalto" },
    { name: "Orizona", parent: "GO", value: "Orizona" },
    { name: "Ouro Verde de Goiás", parent: "GO", value: "Ouro Verde de Goias" },
    { name: "Ouvidor", parent: "GO", value: "Ouvidor" },
    { name: "Padre Bernardo", parent: "GO", value: "Padre Bernardo" },
    { name: "Palestina de Goiás", parent: "GO", value: "Palestina de Goias" },
    { name: "Palmeiras de Goiás", parent: "GO", value: "Palmeiras de Goias" },
    { name: "Palmelo", parent: "GO", value: "Palmelo" },
    { name: "Palminópolis", parent: "GO", value: "Palminopolis" },
    { name: "Panamá", parent: "GO", value: "Panama" },
    { name: "Paranaiguara", parent: "GO", value: "Paranaiguara" },
    { name: "Paraúna", parent: "GO", value: "Parauna" },
    { name: "Perolândia", parent: "GO", value: "Perolandia" },
    { name: "Petrolina de Goiás", parent: "GO", value: "Petrolina de Goias" },
    { name: "Pilar de Goiás", parent: "GO", value: "Pilar de Goias" },
    { name: "Piracanjuba", parent: "GO", value: "Piracanjuba" },
    { name: "Piranhas", parent: "GO", value: "Piranhas" },
    { name: "Pirenópolis", parent: "GO", value: "Pirenopolis" },
    { name: "Pires do Rio", parent: "GO", value: "Pires do Rio" },
    { name: "Planaltina", parent: "GO", value: "Planaltina" },
    { name: "Pontalina", parent: "GO", value: "Pontalina" },
    { name: "Porangatu", parent: "GO", value: "Porangatu" },
    { name: "Porteirão", parent: "GO", value: "Porteirao" },
    { name: "Portelândia", parent: "GO", value: "Portelandia" },
    { name: "Posse", parent: "GO", value: "Posse" },
    { name: "Professor Jamil", parent: "GO", value: "Professor Jamil" },
    { name: "Quirinópolis", parent: "GO", value: "Quirinopolis" },
    { name: "Rialma", parent: "GO", value: "Rialma" },
    { name: "Rianápolis", parent: "GO", value: "Rianapolis" },
    { name: "Rio Quente", parent: "GO", value: "Rio Quente" },
    { name: "Rio Verde", parent: "GO", value: "Rio Verde" },
    { name: "Rubiataba", parent: "GO", value: "Rubiataba" },
    { name: "Sanclerlândia", parent: "GO", value: "Sanclerlandia" },
    {
      name: "Santa Bárbara de Goiás",
      parent: "GO",
      value: "Santa Barbara de Goias",
    },
    { name: "Santa Cruz de Goiás", parent: "GO", value: "Santa Cruz de Goias" },
    { name: "Santa Fé de Goiás", parent: "GO", value: "Santa Fe de Goias" },
    {
      name: "Santa Helena de Goiás",
      parent: "GO",
      value: "Santa Helena de Goias",
    },
    { name: "Santa Isabel", parent: "GO", value: "Santa Isabel" },
    {
      name: "Santa Rita do Araguaia",
      parent: "GO",
      value: "Santa Rita do Araguaia",
    },
    {
      name: "Santa Rita do Novo Destino",
      parent: "GO",
      value: "Santa Rita do Novo Destino",
    },
    { name: "Santa Rosa de Goiás", parent: "GO", value: "Santa Rosa de Goias" },
    {
      name: "Santa Tereza de Goiás",
      parent: "GO",
      value: "Santa Tereza de Goias",
    },
    {
      name: "Santa Terezinha de Goiás",
      parent: "GO",
      value: "Santa Terezinha de Goias",
    },
    {
      name: "Santo Antônio da Barra",
      parent: "GO",
      value: "Santo Antonio da Barra",
    },
    {
      name: "Santo Antônio de Goiás",
      parent: "GO",
      value: "Santo Antonio de Goias",
    },
    {
      name: "Santo Antônio do Descoberto",
      parent: "GO",
      value: "Santo Antonio do Descoberto",
    },
    { name: "Senador Canedo", parent: "GO", value: "Senador Canedo" },
    { name: "Serranópolis", parent: "GO", value: "Serranopolis" },
    { name: "Silvânia", parent: "GO", value: "Silvania" },
    { name: "Simolândia", parent: "GO", value: "Simolandia" },
    { name: "São Domingos", parent: "GO", value: "Sao Domingos" },
    {
      name: "São Francisco de Goiás",
      parent: "GO",
      value: "Sao Francisco de Goias",
    },
    { name: "São João d`Aliança", parent: "GO", value: "Sao Joao d`Alianca" },
    { name: "São João da Paraúna", parent: "GO", value: "Sao Joao da Parauna" },
    {
      name: "São Luís de Montes Belos",
      parent: "GO",
      value: "Sao Luis de Montes Belos",
    },
    { name: "São Luíz do Norte", parent: "GO", value: "Sao Luiz do Norte" },
    {
      name: "São Miguel do Araguaia",
      parent: "GO",
      value: "Sao Miguel do Araguaia",
    },
    {
      name: "São Miguel do Passa Quatro",
      parent: "GO",
      value: "Sao Miguel do Passa Quatro",
    },
    { name: "São Patrício", parent: "GO", value: "Sao Patricio" },
    { name: "São Simão", parent: "GO", value: "Sao Simao" },
    { name: "Sítio d'Abadia", parent: "GO", value: "Sitio d'Abadia" },
    { name: "Taquaral de Goiás", parent: "GO", value: "Taquaral de Goias" },
    { name: "Teresina de Goiás", parent: "GO", value: "Teresina de Goias" },
    {
      name: "Terezópolis de Goiás",
      parent: "GO",
      value: "Terezopolis de Goias",
    },
    { name: "Trindade", parent: "GO", value: "Trindade" },
    { name: "Trombas", parent: "GO", value: "Trombas" },
    { name: "Três Ranchos", parent: "GO", value: "Tres Ranchos" },
    { name: "Turvelândia", parent: "GO", value: "Turvelandia" },
    { name: "Turvânia", parent: "GO", value: "Turvania" },
    { name: "Uirapuru", parent: "GO", value: "Uirapuru" },
    { name: "Uruana", parent: "GO", value: "Uruana" },
    { name: "Uruaçu", parent: "GO", value: "Uruacu" },
    { name: "Urutaí", parent: "GO", value: "Urutai" },
    { name: "Valparaíso de Goiás", parent: "GO", value: "Valparaiso de Goias" },
    { name: "Varjão", parent: "GO", value: "Varjao" },
    { name: "Vianópolis", parent: "GO", value: "Vianopolis" },
    { name: "Vicentinópolis", parent: "GO", value: "Vicentinopolis" },
    { name: "Vila Boa", parent: "GO", value: "Vila Boa" },
    { name: "Vila Propício", parent: "GO", value: "Vila Propicio" },
    { name: "Água Fria de Goiás", parent: "GO", value: "Agua Fria de Goias" },
    { name: "Água Limpa", parent: "GO", value: "Agua Limpa" },
    {
      name: "Águas Lindas de Goiás",
      parent: "GO",
      value: "Aguas Lindas de Goias",
    },

    { name: "Acailandia", parent: "MA", value: "Acailandia" },
    { name: "Afonso Cunha", parent: "MA", value: "Afonso Cunha" },
    {
      name: "Agua Doce do Maranhao",
      parent: "MA",
      value: "Agua Doce do Maranhao",
    },
    { name: "Alcantara", parent: "MA", value: "Alcantara" },
    { name: "Aldeias Altas", parent: "MA", value: "Aldeias Altas" },
    {
      name: "Altamira do Maranhao",
      parent: "MA",
      value: "Altamira do Maranhao",
    },
    {
      name: "Alto Alegre do Maranhao",
      parent: "MA",
      value: "Alto Alegre do Maranhao",
    },
    {
      name: "Alto Alegre do Pindare",
      parent: "MA",
      value: "Alto Alegre do Pindare",
    },
    { name: "Alto Parnaiba", parent: "MA", value: "Alto Parnaiba" },
    { name: "Amapa do Maranhao", parent: "MA", value: "Amapa do Maranhao" },
    {
      name: "Amarante do Maranhao",
      parent: "MA",
      value: "Amarante do Maranhao",
    },
    { name: "Anajatuba", parent: "MA", value: "Anajatuba" },
    { name: "Anapurus", parent: "MA", value: "Anapurus" },
    { name: "Apicum-Acu", parent: "MA", value: "Apicum-Acu" },
    { name: "Araguana", parent: "MA", value: "Araguana" },
    { name: "Araioses", parent: "MA", value: "Araioses" },
    { name: "Arame", parent: "MA", value: "Arame" },
    { name: "Arari", parent: "MA", value: "Arari" },
    { name: "Axixa", parent: "MA", value: "Axixa" },
    { name: "Bacabal", parent: "MA", value: "Bacabal" },
    { name: "Bacabeira", parent: "MA", value: "Bacabeira" },
    { name: "Bacuri", parent: "MA", value: "Bacuri" },
    { name: "Bacurituba", parent: "MA", value: "Bacurituba" },
    { name: "Balsas", parent: "MA", value: "Balsas" },
    { name: "Barao de Grajau", parent: "MA", value: "Barao de Grajau" },
    { name: "Barra do Corda", parent: "MA", value: "Barra do Corda" },
    { name: "Barreirinhas", parent: "MA", value: "Barreirinhas" },
    {
      name: "Bela Vista do Maranhao",
      parent: "MA",
      value: "Bela Vista do Maranhao",
    },
    { name: "Belagua", parent: "MA", value: "Belagua" },
    { name: "Benedito Leite", parent: "MA", value: "Benedito Leite" },
    { name: "Bequimao", parent: "MA", value: "Bequimao" },
    { name: "Bernardo do Mearim", parent: "MA", value: "Bernardo do Mearim" },
    { name: "Boa Vista do Gurupi", parent: "MA", value: "Boa Vista do Gurupi" },
    { name: "Bom Jardim", parent: "MA", value: "Bom Jardim" },
    {
      name: "Bom Jesus das Selvas",
      parent: "MA",
      value: "Bom Jesus das Selvas",
    },
    { name: "Bom Lugar", parent: "MA", value: "Bom Lugar" },
    { name: "Brejo", parent: "MA", value: "Brejo" },
    { name: "Brejo de Areia", parent: "MA", value: "Brejo de Areia" },
    { name: "Buriti", parent: "MA", value: "Buriti" },
    { name: "Buriti Bravo", parent: "MA", value: "Buriti Bravo" },
    { name: "Buriticupu", parent: "MA", value: "Buriticupu" },
    { name: "Buritirama", parent: "MA", value: "Buritirama" },
    { name: "Cachoeira Grande", parent: "MA", value: "Cachoeira Grande" },
    { name: "Cajapio", parent: "MA", value: "Cajapio" },
    { name: "Cajari", parent: "MA", value: "Cajari" },
    {
      name: "Campestre do Maranhao",
      parent: "MA",
      value: "Campestre do Maranhao",
    },
    { name: "Candido Mendes", parent: "MA", value: "Candido Mendes" },
    { name: "Cantanhede", parent: "MA", value: "Cantanhede" },
    { name: "Capinzal do Norte", parent: "MA", value: "Capinzal do Norte" },
    { name: "Carolina", parent: "MA", value: "Carolina" },
    { name: "Carutapera", parent: "MA", value: "Carutapera" },
    { name: "Caxias", parent: "MA", value: "Caxias" },
    { name: "Cedral", parent: "MA", value: "Cedral" },
    { name: "Central do Maranhao", parent: "MA", value: "Central do Maranhao" },
    {
      name: "Centro Novo do Maranhao",
      parent: "MA",
      value: "Centro Novo do Maranhao",
    },
    { name: "Centro do Guilherme", parent: "MA", value: "Centro do Guilherme" },
    { name: "Chapadinha", parent: "MA", value: "Chapadinha" },
    { name: "Cidelandia", parent: "MA", value: "Cidelandia" },
    { name: "Codo", parent: "MA", value: "Codo" },
    { name: "Coelho Neto", parent: "MA", value: "Coelho Neto" },
    { name: "Colinas", parent: "MA", value: "Colinas" },
    {
      name: "Conceicao do Lago-Acu",
      parent: "MA",
      value: "Conceicao do Lago-Acu",
    },
    { name: "Coroata", parent: "MA", value: "Coroata" },
    { name: "Cururupu", parent: "MA", value: "Cururupu" },
    { name: "Davinopolis", parent: "MA", value: "Davinopolis" },
    { name: "Dom Pedro", parent: "MA", value: "Dom Pedro" },
    { name: "Duque Bacelar", parent: "MA", value: "Duque Bacelar" },
    { name: "Esperantinopolis", parent: "MA", value: "Esperantinopolis" },
    { name: "Estreito", parent: "MA", value: "Estreito" },
    {
      name: "Feira Nova do Maranhao",
      parent: "MA",
      value: "Feira Nova do Maranhao",
    },
    { name: "Fernando Falcao", parent: "MA", value: "Fernando Falcao" },
    {
      name: "Formosa da Serra Negra",
      parent: "MA",
      value: "Formosa da Serra Negra",
    },
    {
      name: "Fortaleza dos Nogueiras",
      parent: "MA",
      value: "Fortaleza dos Nogueiras",
    },
    { name: "Fortuna", parent: "MA", value: "Fortuna" },
    { name: "Godofredo Viana", parent: "MA", value: "Godofredo Viana" },
    { name: "Goncalves Dias", parent: "MA", value: "Goncalves Dias" },
    { name: "Governador Archer", parent: "MA", value: "Governador Archer" },
    {
      name: "Governador Edison Lobao",
      parent: "MA",
      value: "Governador Edison Lobao",
    },
    {
      name: "Governador Eugenio Barros",
      parent: "MA",
      value: "Governador Eugenio Barros",
    },
    {
      name: "Governador Luiz Rocha",
      parent: "MA",
      value: "Governador Luiz Rocha",
    },
    {
      name: "Governador Newton Bello",
      parent: "MA",
      value: "Governador Newton Bello",
    },
    {
      name: "Governador Nunes Freire",
      parent: "MA",
      value: "Governador Nunes Freire",
    },
    { name: "Graca Aranha", parent: "MA", value: "Graca Aranha" },
    { name: "Grajau", parent: "MA", value: "Grajau" },
    { name: "Guimaraes", parent: "MA", value: "Guimaraes" },
    { name: "Humberto de Campos", parent: "MA", value: "Humberto de Campos" },
    { name: "Icatu", parent: "MA", value: "Icatu" },
    { name: "Igarape Grande", parent: "MA", value: "Igarape Grande" },
    { name: "Igarape do Meio", parent: "MA", value: "Igarape do Meio" },
    { name: "Imperatriz", parent: "MA", value: "Imperatriz" },
    { name: "Itaipava do Grajau", parent: "MA", value: "Itaipava do Grajau" },
    { name: "Itapecuru Mirim", parent: "MA", value: "Itapecuru Mirim" },
    { name: "Itinga do Maranhao", parent: "MA", value: "Itinga do Maranhao" },
    { name: "Jatoba", parent: "MA", value: "Jatoba" },
    {
      name: "Jenipapo dos Vieiras",
      parent: "MA",
      value: "Jenipapo dos Vieiras",
    },
    { name: "Joao Lisboa", parent: "MA", value: "Joao Lisboa" },
    { name: "Joselandia", parent: "MA", value: "Joselandia" },
    { name: "Junco do Maranhao", parent: "MA", value: "Junco do Maranhao" },
    { name: "Lago Verde", parent: "MA", value: "Lago Verde" },
    { name: "Lago da Pedra", parent: "MA", value: "Lago da Pedra" },
    { name: "Lago do Junco", parent: "MA", value: "Lago do Junco" },
    {
      name: "Lagoa Grande do Maranhao",
      parent: "MA",
      value: "Lagoa Grande do Maranhao",
    },
    { name: "Lagoa do Mato", parent: "MA", value: "Lagoa do Mato" },
    { name: "Lagoa dos Rodrigues", parent: "MA", value: "Lagoa dos Rodrigues" },
    { name: "Lajeado Novo", parent: "MA", value: "Lajeado Novo" },
    { name: "Lima Campos", parent: "MA", value: "Lima Campos" },
    { name: "Loreto", parent: "MA", value: "Loreto" },
    { name: "Luis Domingues", parent: "MA", value: "Luis Domingues" },
    {
      name: "Magalhaes de Almeida",
      parent: "MA",
      value: "Magalhaes de Almeida",
    },
    { name: "Maracacume", parent: "MA", value: "Maracacume" },
    { name: "Maraja do Sena", parent: "MA", value: "Maraja do Sena" },
    { name: "Maranhaozinho", parent: "MA", value: "Maranhaozinho" },
    { name: "Mata Roma", parent: "MA", value: "Mata Roma" },
    { name: "Matinha", parent: "MA", value: "Matinha" },
    { name: "Matoes", parent: "MA", value: "Matoes" },
    { name: "Matoes do Norte", parent: "MA", value: "Matoes do Norte" },
    {
      name: "Milagres do Maranhao",
      parent: "MA",
      value: "Milagres do Maranhao",
    },
    { name: "Mirador", parent: "MA", value: "Mirador" },
    { name: "Miranda do Norte", parent: "MA", value: "Miranda do Norte" },
    { name: "Mirinzal", parent: "MA", value: "Mirinzal" },
    { name: "Moncao", parent: "MA", value: "Moncao" },
    { name: "Montes Altos", parent: "MA", value: "Montes Altos" },
    { name: "Morros", parent: "MA", value: "Morros" },
    { name: "Nina Rodrigues", parent: "MA", value: "Nina Rodrigues" },
    { name: "Nova Colinas", parent: "MA", value: "Nova Colinas" },
    { name: "Nova Iorque", parent: "MA", value: "Nova Iorque" },
    {
      name: "Nova Olinda do Maranhao",
      parent: "MA",
      value: "Nova Olinda do Maranhao",
    },
    {
      name: "Olho d'Agua das Cunhas",
      parent: "MA",
      value: "Olho d'Agua das Cunhas",
    },
    {
      name: "Olinda Nova do Maranhao",
      parent: "MA",
      value: "Olinda Nova do Maranhao",
    },
    { name: "Paco do Lumiar", parent: "MA", value: "Paco do Lumiar" },
    { name: "Palmeirandia", parent: "MA", value: "Palmeirandia" },
    { name: "Paraibano", parent: "MA", value: "Paraibano" },
    { name: "Parnarama", parent: "MA", value: "Parnarama" },
    { name: "Passagem Franca", parent: "MA", value: "Passagem Franca" },
    { name: "Pastos Bons", parent: "MA", value: "Pastos Bons" },
    { name: "Paulino Neves", parent: "MA", value: "Paulino Neves" },
    { name: "Paulo Ramos", parent: "MA", value: "Paulo Ramos" },
    { name: "Pedreiras", parent: "MA", value: "Pedreiras" },
    { name: "Pedro do Rosario", parent: "MA", value: "Pedro do Rosario" },
    { name: "Penalva", parent: "MA", value: "Penalva" },
    { name: "Peri Mirim", parent: "MA", value: "Peri Mirim" },
    { name: "Peritoro", parent: "MA", value: "Peritoro" },
    { name: "Pindare Mirim", parent: "MA", value: "Pindare Mirim" },
    { name: "Pinheiro", parent: "MA", value: "Pinheiro" },
    { name: "Pio XII", parent: "MA", value: "Pio XII" },
    { name: "Pirapemas", parent: "MA", value: "Pirapemas" },
    { name: "Pocao de Pedras", parent: "MA", value: "Pocao de Pedras" },
    { name: "Porto Franco", parent: "MA", value: "Porto Franco" },
    {
      name: "Porto Rico do Maranhao",
      parent: "MA",
      value: "Porto Rico do Maranhao",
    },
    { name: "Presidente Dutra", parent: "MA", value: "Presidente Dutra" },
    {
      name: "Presidente Juscelino",
      parent: "MA",
      value: "Presidente Juscelino",
    },
    { name: "Presidente Medici", parent: "MA", value: "Presidente Medici" },
    { name: "Presidente Sarney", parent: "MA", value: "Presidente Sarney" },
    { name: "Presidente Vargas", parent: "MA", value: "Presidente Vargas" },
    { name: "Primeira Cruz", parent: "MA", value: "Primeira Cruz" },
    { name: "Raposa", parent: "MA", value: "Raposa" },
    { name: "Riachao", parent: "MA", value: "Riachao" },
    { name: "Ribamar Fiquene", parent: "MA", value: "Ribamar Fiquene" },
    { name: "Rosario", parent: "MA", value: "Rosario" },
    { name: "Sambaiba", parent: "MA", value: "Sambaiba" },
    {
      name: "Santa Filomena do Maranhao",
      parent: "MA",
      value: "Santa Filomena do Maranhao",
    },
    { name: "Santa Helena", parent: "MA", value: "Santa Helena" },
    { name: "Santa Ines", parent: "MA", value: "Santa Ines" },
    { name: "Santa Luzia", parent: "MA", value: "Santa Luzia" },
    {
      name: "Santa Luzia do Parua",
      parent: "MA",
      value: "Santa Luzia do Parua",
    },
    {
      name: "Santa Quiteria do Maranhao",
      parent: "MA",
      value: "Santa Quiteria do Maranhao",
    },
    { name: "Santa Rita", parent: "MA", value: "Santa Rita" },
    { name: "Santana do Maranhao", parent: "MA", value: "Santana do Maranhao" },
    {
      name: "Santo Amaro do Maranhao",
      parent: "MA",
      value: "Santo Amaro do Maranhao",
    },
    {
      name: "Santo Antonio dos Lopes",
      parent: "MA",
      value: "Santo Antonio dos Lopes",
    },
    {
      name: "Sao Benedito do Rio Preto",
      parent: "MA",
      value: "Sao Benedito do Rio Preto",
    },
    { name: "Sao Bento", parent: "MA", value: "Sao Bento" },
    { name: "Sao Bernardo", parent: "MA", value: "Sao Bernardo" },
    {
      name: "Sao Domingos do Azeitao",
      parent: "MA",
      value: "Sao Domingos do Azeitao",
    },
    {
      name: "Sao Domingos do Maranhao",
      parent: "MA",
      value: "Sao Domingos do Maranhao",
    },
    { name: "Sao Felix de Balsas", parent: "MA", value: "Sao Felix de Balsas" },
    {
      name: "Sao Francisco do Brejao",
      parent: "MA",
      value: "Sao Francisco do Brejao",
    },
    {
      name: "Sao Francisco do Maranhao",
      parent: "MA",
      value: "Sao Francisco do Maranhao",
    },
    { name: "Sao Joao Batista", parent: "MA", value: "Sao Joao Batista" },
    { name: "Sao Joao do Caru", parent: "MA", value: "Sao Joao do Caru" },
    { name: "Sao Joao do Paraiso", parent: "MA", value: "Sao Joao do Paraiso" },
    { name: "Sao Joao do Soter", parent: "MA", value: "Sao Joao do Soter" },
    { name: "Sao Joao dos Patos", parent: "MA", value: "Sao Joao dos Patos" },
    { name: "Sao Jose de Ribamar", parent: "MA", value: "Sao Jose de Ribamar" },
    {
      name: "Sao Jose dos Basilios",
      parent: "MA",
      value: "Sao Jose dos Basilios",
    },
    { name: "Sao Luis", parent: "MA", value: "Sao Luis" },
    {
      name: "Sao Luis Gonzaga do Maranhao",
      parent: "MA",
      value: "Sao Luis Gonzaga do Maranhao",
    },
    {
      name: "Sao Mateus do Maranhao",
      parent: "MA",
      value: "Sao Mateus do Maranhao",
    },
    {
      name: "Sao Pedro da Agua Branca",
      parent: "MA",
      value: "Sao Pedro da Agua Branca",
    },
    {
      name: "Sao Pedro dos Crentes",
      parent: "MA",
      value: "Sao Pedro dos Crentes",
    },
    {
      name: "Sao Raimundo das Mangabeiras",
      parent: "MA",
      value: "Sao Raimundo das Mangabeiras",
    },
    {
      name: "Sao Raimundo do Doca Bezerra",
      parent: "MA",
      value: "Sao Raimundo do Doca Bezerra",
    },
    { name: "Sao Roberto", parent: "MA", value: "Sao Roberto" },
    { name: "Sao Vicente Ferrer", parent: "MA", value: "Sao Vicente Ferrer" },
    { name: "Satubinha", parent: "MA", value: "Satubinha" },
    {
      name: "Senador Alexandre Costa",
      parent: "MA",
      value: "Senador Alexandre Costa",
    },
    { name: "Senador La Rocque", parent: "MA", value: "Senador La Rocque" },
    { name: "Serrano do Maranhao", parent: "MA", value: "Serrano do Maranhao" },
    { name: "Sitio Novo", parent: "MA", value: "Sitio Novo" },
    { name: "Sucupira do Norte", parent: "MA", value: "Sucupira do Norte" },
    { name: "Sucupira do Riachao", parent: "MA", value: "Sucupira do Riachao" },
    { name: "Tasso Fragoso", parent: "MA", value: "Tasso Fragoso" },
    { name: "Timbiras", parent: "MA", value: "Timbiras" },
    { name: "Timon", parent: "MA", value: "Timon" },
    { name: "Trizidela do Vale", parent: "MA", value: "Trizidela do Vale" },
    { name: "Tufilandia", parent: "MA", value: "Tufilandia" },
    { name: "Tuntum", parent: "MA", value: "Tuntum" },
    { name: "Turiacu", parent: "MA", value: "Turiacu" },
    { name: "Turilandia", parent: "MA", value: "Turilandia" },
    { name: "Tutoia", parent: "MA", value: "Tutoia" },
    { name: "Urbano Santos", parent: "MA", value: "Urbano Santos" },
    { name: "Vargem Grande", parent: "MA", value: "Vargem Grande" },
    { name: "Viana", parent: "MA", value: "Viana" },
    {
      name: "Vila Nova dos Martirios",
      parent: "MA",
      value: "Vila Nova dos Martirios",
    },
    { name: "Vitoria do Mearim", parent: "MA", value: "Vitoria do Mearim" },
    { name: "Vitorino Freire", parent: "MA", value: "Vitorino Freire" },
    { name: "Ze Doca", parent: "MA", value: "Ze Doca" },

    { name: " Abaete", parent: "MG", value: " Abaete" },
    { name: " Abre Campo", parent: "MG", value: " Abre Campo" },
    { name: " Acaiaca", parent: "MG", value: " Acaiaca" },
    { name: " Acucena", parent: "MG", value: " Acucena" },
    { name: " Agua Boa", parent: "MG", value: " Agua Boa" },
    { name: " Agua Comprida", parent: "MG", value: " Agua Comprida" },
    { name: " Aguanil", parent: "MG", value: " Aguanil" },
    { name: " Aguas Formosas", parent: "MG", value: " Aguas Formosas" },
    { name: " Aguas Vermelhas", parent: "MG", value: " Aguas Vermelhas" },
    { name: " Aimores", parent: "MG", value: " Aimores" },
    { name: " Aiuruoca", parent: "MG", value: " Aiuruoca" },
    { name: " Alagoa", parent: "MG", value: " Alagoa" },
    { name: " Albertina", parent: "MG", value: " Albertina" },
    { name: " Alem Paraiba", parent: "MG", value: " Alem Paraiba" },
    { name: " Alfenas", parent: "MG", value: " Alfenas" },
    {
      name: " Alfredo Vasconcelos",
      parent: "MG",
      value: " Alfredo Vasconcelos",
    },
    { name: " Almenara", parent: "MG", value: " Almenara" },
    { name: " Alpercata", parent: "MG", value: " Alpercata" },
    { name: " Alpinopolis", parent: "MG", value: " Alpinopolis" },
    { name: " Alterosa", parent: "MG", value: " Alterosa" },
    { name: " Alto Caparao", parent: "MG", value: " Alto Caparao" },
    { name: " Alto Jequitiba", parent: "MG", value: " Alto Jequitiba" },
    { name: " Alto Rio Doce", parent: "MG", value: " Alto Rio Doce" },
    { name: " Alvarenga", parent: "MG", value: " Alvarenga" },
    { name: " Alvinopolis", parent: "MG", value: " Alvinopolis" },
    { name: " Alvorada de Minas", parent: "MG", value: " Alvorada de Minas" },
    { name: " Amparo do Serra", parent: "MG", value: " Amparo do Serra" },
    { name: " Andradas", parent: "MG", value: " Andradas" },
    { name: " Andrelandia", parent: "MG", value: " Andrelandia" },
    { name: " Angelandia", parent: "MG", value: " Angelandia" },
    { name: " Antonio Carlos", parent: "MG", value: " Antonio Carlos" },
    { name: " Antonio Dias", parent: "MG", value: " Antonio Dias" },
    {
      name: " Antonio Prado de Minas",
      parent: "MG",
      value: " Antonio Prado de Minas",
    },
    { name: " Aracai", parent: "MG", value: " Aracai" },
    { name: " Aracitaba", parent: "MG", value: " Aracitaba" },
    { name: " Aracuai", parent: "MG", value: " Aracuai" },
    { name: " Araguari", parent: "MG", value: " Araguari" },
    { name: " Arantina", parent: "MG", value: " Arantina" },
    { name: " Araponga", parent: "MG", value: " Araponga" },
    { name: " Arapora", parent: "MG", value: " Arapora" },
    { name: " Arapua", parent: "MG", value: " Arapua" },
    { name: " Araujos", parent: "MG", value: " Araujos" },
    { name: " Araxa", parent: "MG", value: " Araxa" },
    { name: " Arceburgo", parent: "MG", value: " Arceburgo" },
    { name: " Arcos", parent: "MG", value: " Arcos" },
    { name: " Areado", parent: "MG", value: " Areado" },
    { name: " Argirita", parent: "MG", value: " Argirita" },
    { name: " Aricanduva", parent: "MG", value: " Aricanduva" },
    { name: " Arinos", parent: "MG", value: " Arinos" },
    { name: " Astolfo Dutra", parent: "MG", value: " Astolfo Dutra" },
    { name: " Ataleia", parent: "MG", value: " Ataleia" },
    { name: " Augusto de Lima", parent: "MG", value: " Augusto de Lima" },
    { name: " Baependi", parent: "MG", value: " Baependi" },
    { name: " Baldim", parent: "MG", value: " Baldim" },
    { name: " Bambui", parent: "MG", value: " Bambui" },
    { name: " Bandeira", parent: "MG", value: " Bandeira" },
    { name: " Bandeira do Sul", parent: "MG", value: " Bandeira do Sul" },
    { name: " Barao de Cocais", parent: "MG", value: " Barao de Cocais" },
    {
      name: " Barao de Monte Alto",
      parent: "MG",
      value: " Barao de Monte Alto",
    },
    { name: " Barbacena", parent: "MG", value: " Barbacena" },
    { name: " Barra Longa", parent: "MG", value: " Barra Longa" },
    { name: " Barroso", parent: "MG", value: " Barroso" },
    {
      name: " Bela Vista de Minas",
      parent: "MG",
      value: " Bela Vista de Minas",
    },
    { name: " Belmiro Braga", parent: "MG", value: " Belmiro Braga" },
    { name: " Belo Horizonte", parent: "MG", value: " Belo Horizonte" },
    { name: " Belo Oriente", parent: "MG", value: " Belo Oriente" },
    { name: " Belo Vale", parent: "MG", value: " Belo Vale" },
    { name: " Berilo", parent: "MG", value: " Berilo" },
    { name: " Berizal", parent: "MG", value: " Berizal" },
    { name: " Bertopolis", parent: "MG", value: " Bertopolis" },
    { name: " Betim", parent: "MG", value: " Betim" },
    { name: " Bias Fortes", parent: "MG", value: " Bias Fortes" },
    { name: " Bicas", parent: "MG", value: " Bicas" },
    { name: " Biquinhas", parent: "MG", value: " Biquinhas" },
    { name: " Boa Esperanca", parent: "MG", value: " Boa Esperanca" },
    { name: " Bocaina de Minas", parent: "MG", value: " Bocaina de Minas" },
    { name: " Bocaiuva", parent: "MG", value: " Bocaiuva" },
    { name: " Bom Despacho", parent: "MG", value: " Bom Despacho" },
    {
      name: " Bom Jardim de Minas",
      parent: "MG",
      value: " Bom Jardim de Minas",
    },
    { name: " Bom Jesus da Penha", parent: "MG", value: " Bom Jesus da Penha" },
    {
      name: " Bom Jesus do Amparo",
      parent: "MG",
      value: " Bom Jesus do Amparo",
    },
    { name: " Bom Jesus do Galho", parent: "MG", value: " Bom Jesus do Galho" },
    { name: " Bom Repouso", parent: "MG", value: " Bom Repouso" },
    { name: " Bom Sucesso", parent: "MG", value: " Bom Sucesso" },
    { name: " Bonfim", parent: "MG", value: " Bonfim" },
    {
      name: " Bonfinopolis de Minas",
      parent: "MG",
      value: " Bonfinopolis de Minas",
    },
    { name: " Bonito de Minas", parent: "MG", value: " Bonito de Minas" },
    { name: " Borda da Mata", parent: "MG", value: " Borda da Mata" },
    { name: " Botelhos", parent: "MG", value: " Botelhos" },
    { name: " Botumirim", parent: "MG", value: " Botumirim" },
    { name: " Bras Pires", parent: "MG", value: " Bras Pires" },
    {
      name: " Brasilandia de Minas",
      parent: "MG",
      value: " Brasilandia de Minas",
    },
    { name: " Brasilia de Minas", parent: "MG", value: " Brasilia de Minas" },
    { name: " Brasopolis", parent: "MG", value: " Brasopolis" },
    { name: " Braunas", parent: "MG", value: " Braunas" },
    { name: " Brumadinho", parent: "MG", value: " Brumadinho" },
    { name: " Bueno Brandao", parent: "MG", value: " Bueno Brandao" },
    { name: " Buenopolis", parent: "MG", value: " Buenopolis" },
    { name: " Bugre", parent: "MG", value: " Bugre" },
    { name: " Buritis", parent: "MG", value: " Buritis" },
    { name: " Buritizeiro", parent: "MG", value: " Buritizeiro" },
    { name: " Cabeceira Grande", parent: "MG", value: " Cabeceira Grande" },
    { name: " Cabo Verde", parent: "MG", value: " Cabo Verde" },
    { name: " Cachoeira Dourada", parent: "MG", value: " Cachoeira Dourada" },
    { name: " Cachoeira da Prata", parent: "MG", value: " Cachoeira da Prata" },
    { name: " Cachoeira de Minas", parent: "MG", value: " Cachoeira de Minas" },
    { name: " Cachoeira de Pajeu", parent: "MG", value: " Cachoeira de Pajeu" },
    { name: " Caetanopolis", parent: "MG", value: " Caetanopolis" },
    { name: " Caete", parent: "MG", value: " Caete" },
    { name: " Caiana", parent: "MG", value: " Caiana" },
    { name: " Cajuri", parent: "MG", value: " Cajuri" },
    { name: " Caldas", parent: "MG", value: " Caldas" },
    { name: " Camacho", parent: "MG", value: " Camacho" },
    { name: " Camanducaia", parent: "MG", value: " Camanducaia" },
    { name: " Cambui", parent: "MG", value: " Cambui" },
    { name: " Cambuquira", parent: "MG", value: " Cambuquira" },
    { name: " Campanario", parent: "MG", value: " Campanario" },
    { name: " Campanha", parent: "MG", value: " Campanha" },
    { name: " Campestre", parent: "MG", value: " Campestre" },
    { name: " Campina Verde", parent: "MG", value: " Campina Verde" },
    { name: " Campo Azul", parent: "MG", value: " Campo Azul" },
    { name: " Campo Belo", parent: "MG", value: " Campo Belo" },
    { name: " Campo Florido", parent: "MG", value: " Campo Florido" },
    { name: " Campo do Meio", parent: "MG", value: " Campo do Meio" },
    { name: " Campos Altos", parent: "MG", value: " Campos Altos" },
    { name: " Campos Gerais", parent: "MG", value: " Campos Gerais" },
    { name: " Cana Verde", parent: "MG", value: " Cana Verde" },
    { name: " Canaa", parent: "MG", value: " Canaa" },
    { name: " Canapolis", parent: "MG", value: " Canapolis" },
    { name: " Candeias", parent: "MG", value: " Candeias" },
    { name: " Cantagalo", parent: "MG", value: " Cantagalo" },
    { name: " Caparao", parent: "MG", value: " Caparao" },
    { name: " Capela Nova", parent: "MG", value: " Capela Nova" },
    { name: " Capelinha", parent: "MG", value: " Capelinha" },
    { name: " Capetinga", parent: "MG", value: " Capetinga" },
    { name: " Capim Branco", parent: "MG", value: " Capim Branco" },
    { name: " Capinopolis", parent: "MG", value: " Capinopolis" },
    { name: " Capitao Andrade", parent: "MG", value: " Capitao Andrade" },
    { name: " Capitao Eneas", parent: "MG", value: " Capitao Eneas" },
    { name: " Capitolio", parent: "MG", value: " Capitolio" },
    { name: " Caputira", parent: "MG", value: " Caputira" },
    { name: " Carai", parent: "MG", value: " Carai" },
    { name: " Caranaiba", parent: "MG", value: " Caranaiba" },
    { name: " Carandai", parent: "MG", value: " Carandai" },
    { name: " Carangola", parent: "MG", value: " Carangola" },
    { name: " Caratinga", parent: "MG", value: " Caratinga" },
    { name: " Carbonita", parent: "MG", value: " Carbonita" },
    { name: " Careacu", parent: "MG", value: " Careacu" },
    { name: " Carlos Chagas", parent: "MG", value: " Carlos Chagas" },
    { name: " Carmesia", parent: "MG", value: " Carmesia" },
    { name: " Carmo da Cachoeira", parent: "MG", value: " Carmo da Cachoeira" },
    { name: " Carmo da Mata", parent: "MG", value: " Carmo da Mata" },
    { name: " Carmo de Minas", parent: "MG", value: " Carmo de Minas" },
    { name: " Carmo do Cajuru", parent: "MG", value: " Carmo do Cajuru" },
    { name: " Carmo do Paranaiba", parent: "MG", value: " Carmo do Paranaiba" },
    { name: " Carmo do Rio Claro", parent: "MG", value: " Carmo do Rio Claro" },
    {
      name: " Carmopolis de Minas",
      parent: "MG",
      value: " Carmopolis de Minas",
    },
    { name: " Carneirinho", parent: "MG", value: " Carneirinho" },
    { name: " Carrancas", parent: "MG", value: " Carrancas" },
    { name: " Carvalhopolis", parent: "MG", value: " Carvalhopolis" },
    { name: " Carvalhos", parent: "MG", value: " Carvalhos" },
    { name: " Casa Grande", parent: "MG", value: " Casa Grande" },
    { name: " Cascalho Rico", parent: "MG", value: " Cascalho Rico" },
    { name: " Cassia", parent: "MG", value: " Cassia" },
    { name: " Cataguases", parent: "MG", value: " Cataguases" },
    { name: " Catas Altas", parent: "MG", value: " Catas Altas" },
    {
      name: " Catas Altas da Noruega",
      parent: "MG",
      value: " Catas Altas da Noruega",
    },
    { name: " Catuji", parent: "MG", value: " Catuji" },
    { name: " Catuti", parent: "MG", value: " Catuti" },
    { name: " Caxambu", parent: "MG", value: " Caxambu" },
    { name: " Cedro do Abaete", parent: "MG", value: " Cedro do Abaete" },
    { name: " Central de Minas", parent: "MG", value: " Central de Minas" },
    { name: " Centralina", parent: "MG", value: " Centralina" },
    { name: " Chacara", parent: "MG", value: " Chacara" },
    { name: " Chale", parent: "MG", value: " Chale" },
    { name: " Chapada Gaucha", parent: "MG", value: " Chapada Gaucha" },
    { name: " Chapada do Norte", parent: "MG", value: " Chapada do Norte" },
    { name: " Chiador", parent: "MG", value: " Chiador" },
    { name: " Cipotanea", parent: "MG", value: " Cipotanea" },
    { name: " Claraval", parent: "MG", value: " Claraval" },
    { name: " Claro dos Pocoes", parent: "MG", value: " Claro dos Pocoes" },
    { name: " Claudio", parent: "MG", value: " Claudio" },
    { name: " Coimbra", parent: "MG", value: " Coimbra" },
    { name: " Coluna", parent: "MG", value: " Coluna" },
    { name: " Comendador Gomes", parent: "MG", value: " Comendador Gomes" },
    { name: " Comercinho", parent: "MG", value: " Comercinho" },
    {
      name: " Conceicao da Aparecida",
      parent: "MG",
      value: " Conceicao da Aparecida",
    },
    {
      name: " Conceicao da Barra de Minas",
      parent: "MG",
      value: " Conceicao da Barra de Minas",
    },
    {
      name: " Conceicao das Alagoas",
      parent: "MG",
      value: " Conceicao das Alagoas",
    },
    {
      name: " Conceicao das Pedras",
      parent: "MG",
      value: " Conceicao das Pedras",
    },
    {
      name: " Conceicao de Ipanema",
      parent: "MG",
      value: " Conceicao de Ipanema",
    },
    {
      name: " Conceicao do Mato Dentro",
      parent: "MG",
      value: " Conceicao do Mato Dentro",
    },
    { name: " Conceicao do Para", parent: "MG", value: " Conceicao do Para" },
    {
      name: " Conceicao do Rio Verde",
      parent: "MG",
      value: " Conceicao do Rio Verde",
    },
    {
      name: " Conceicao dos Ouros",
      parent: "MG",
      value: " Conceicao dos Ouros",
    },
    { name: " Conego Marinho", parent: "MG", value: " Conego Marinho" },
    { name: " Confins", parent: "MG", value: " Confins" },
    { name: " Congonhal", parent: "MG", value: " Congonhal" },
    { name: " Congonhas", parent: "MG", value: " Congonhas" },
    { name: " Congonhas do Norte", parent: "MG", value: " Congonhas do Norte" },
    { name: " Conquista", parent: "MG", value: " Conquista" },
    {
      name: " Conselheiro Lafaiete",
      parent: "MG",
      value: " Conselheiro Lafaiete",
    },
    { name: " Conselheiro Pena", parent: "MG", value: " Conselheiro Pena" },
    { name: " Consolacao", parent: "MG", value: " Consolacao" },
    { name: " Contagem", parent: "MG", value: " Contagem" },
    { name: " Coqueiral", parent: "MG", value: " Coqueiral" },
    { name: " Coracao de Jesus", parent: "MG", value: " Coracao de Jesus" },
    { name: " Cordisburgo", parent: "MG", value: " Cordisburgo" },
    { name: " Cordislandia", parent: "MG", value: " Cordislandia" },
    { name: " Corinto", parent: "MG", value: " Corinto" },
    { name: " Coroaci", parent: "MG", value: " Coroaci" },
    { name: " Coromandel", parent: "MG", value: " Coromandel" },
    { name: " Coronel Fabriciano", parent: "MG", value: " Coronel Fabriciano" },
    { name: " Coronel Murta", parent: "MG", value: " Coronel Murta" },
    { name: " Coronel Pacheco", parent: "MG", value: " Coronel Pacheco" },
    {
      name: " Coronel Xavier Chaves",
      parent: "MG",
      value: " Coronel Xavier Chaves",
    },
    { name: " Corrego Danta", parent: "MG", value: " Corrego Danta" },
    { name: " Corrego Fundo", parent: "MG", value: " Corrego Fundo" },
    { name: " Corrego Novo", parent: "MG", value: " Corrego Novo" },
    {
      name: " Corrego do Bom Jesus",
      parent: "MG",
      value: " Corrego do Bom Jesus",
    },
    {
      name: " Couto de Magalhaes de Minas",
      parent: "MG",
      value: " Couto de Magalhaes de Minas",
    },
    { name: " Crisolita", parent: "MG", value: " Crisolita" },
    { name: " Cristais", parent: "MG", value: " Cristais" },
    { name: " Cristalia", parent: "MG", value: " Cristalia" },
    { name: " Cristiano Otoni", parent: "MG", value: " Cristiano Otoni" },
    { name: " Cristina", parent: "MG", value: " Cristina" },
    { name: " Crucilandia", parent: "MG", value: " Crucilandia" },
    {
      name: " Cruzeiro da Fortaleza",
      parent: "MG",
      value: " Cruzeiro da Fortaleza",
    },
    { name: " Cruzilia", parent: "MG", value: " Cruzilia" },
    { name: " Cuparaque", parent: "MG", value: " Cuparaque" },
    { name: " Curral de Dentro", parent: "MG", value: " Curral de Dentro" },
    { name: " Curvelo", parent: "MG", value: " Curvelo" },
    { name: " Datas", parent: "MG", value: " Datas" },
    { name: " Delfim Moreira", parent: "MG", value: " Delfim Moreira" },
    { name: " Delfinopolis", parent: "MG", value: " Delfinopolis" },
    { name: " Delta", parent: "MG", value: " Delta" },
    { name: " Descoberto", parent: "MG", value: " Descoberto" },
    {
      name: " Desterro de Entre Rios",
      parent: "MG",
      value: " Desterro de Entre Rios",
    },
    { name: " Desterro do Melo", parent: "MG", value: " Desterro do Melo" },
    { name: " Diamantina", parent: "MG", value: " Diamantina" },
    {
      name: " Diogo de Vasconcelos",
      parent: "MG",
      value: " Diogo de Vasconcelos",
    },
    { name: " Dionisio", parent: "MG", value: " Dionisio" },
    { name: " Divinesia", parent: "MG", value: " Divinesia" },
    { name: " Divino", parent: "MG", value: " Divino" },
    {
      name: " Divino das Laranjeiras",
      parent: "MG",
      value: " Divino das Laranjeiras",
    },
    {
      name: " Divinolandia de Minas",
      parent: "MG",
      value: " Divinolandia de Minas",
    },
    { name: " Divinopolis", parent: "MG", value: " Divinopolis" },
    { name: " Divisa Alegre", parent: "MG", value: " Divisa Alegre" },
    { name: " Divisa Nova", parent: "MG", value: " Divisa Nova" },
    { name: " Divisopolis", parent: "MG", value: " Divisopolis" },
    { name: " Dom Bosco", parent: "MG", value: " Dom Bosco" },
    { name: " Dom Cavati", parent: "MG", value: " Dom Cavati" },
    { name: " Dom Joaquim", parent: "MG", value: " Dom Joaquim" },
    { name: " Dom Silverio", parent: "MG", value: " Dom Silverio" },
    { name: " Dom Vicoso", parent: "MG", value: " Dom Vicoso" },
    { name: " Dona Euzebia", parent: "MG", value: " Dona Euzebia" },
    { name: " Dores de Campos", parent: "MG", value: " Dores de Campos" },
    { name: " Dores de Guanhaes", parent: "MG", value: " Dores de Guanhaes" },
    { name: " Dores do Indaia", parent: "MG", value: " Dores do Indaia" },
    { name: " Dores do Turvo", parent: "MG", value: " Dores do Turvo" },
    { name: " Doresopolis", parent: "MG", value: " Doresopolis" },
    { name: " Douradoquara", parent: "MG", value: " Douradoquara" },
    { name: " Durande", parent: "MG", value: " Durande" },
    { name: " Eloi Mendes", parent: "MG", value: " Eloi Mendes" },
    { name: " Engenheiro Caldas", parent: "MG", value: " Engenheiro Caldas" },
    { name: " Engenheiro Navarro", parent: "MG", value: " Engenheiro Navarro" },
    { name: " Entre Folhas", parent: "MG", value: " Entre Folhas" },
    {
      name: " Entre Rios de Minas",
      parent: "MG",
      value: " Entre Rios de Minas",
    },
    { name: " Ervalia", parent: "MG", value: " Ervalia" },
    { name: " Esmeraldas", parent: "MG", value: " Esmeraldas" },
    { name: " Espera Feliz", parent: "MG", value: " Espera Feliz" },
    { name: " Espinosa", parent: "MG", value: " Espinosa" },
    {
      name: " Espirito Santo do Dourado",
      parent: "MG",
      value: " Espirito Santo do Dourado",
    },
    { name: " Estiva", parent: "MG", value: " Estiva" },
    { name: " Estrela Dalva", parent: "MG", value: " Estrela Dalva" },
    { name: " Estrela do Indaia", parent: "MG", value: " Estrela do Indaia" },
    { name: " Estrela do Sul", parent: "MG", value: " Estrela do Sul" },
    { name: " Eugenopolis", parent: "MG", value: " Eugenopolis" },
    { name: " Ewbank da Camara", parent: "MG", value: " Ewbank da Camara" },
    { name: " Extrema", parent: "MG", value: " Extrema" },
    { name: " Fama", parent: "MG", value: " Fama" },
    { name: " Faria Lemos", parent: "MG", value: " Faria Lemos" },
    { name: " Felicio dos Santos", parent: "MG", value: " Felicio dos Santos" },
    { name: " Felisburgo", parent: "MG", value: " Felisburgo" },
    { name: " Felixlandia", parent: "MG", value: " Felixlandia" },
    { name: " Fernandes Tourinho", parent: "MG", value: " Fernandes Tourinho" },
    { name: " Ferros", parent: "MG", value: " Ferros" },
    { name: " Fervedouro", parent: "MG", value: " Fervedouro" },
    { name: " Florestal", parent: "MG", value: " Florestal" },
    { name: " Formiga", parent: "MG", value: " Formiga" },
    { name: " Formoso", parent: "MG", value: " Formoso" },
    { name: " Fortaleza de Minas", parent: "MG", value: " Fortaleza de Minas" },
    { name: " Fortuna de Minas", parent: "MG", value: " Fortuna de Minas" },
    { name: " Francisco Badaro", parent: "MG", value: " Francisco Badaro" },
    { name: " Francisco Dumont", parent: "MG", value: " Francisco Dumont" },
    { name: " Francisco Sa", parent: "MG", value: " Francisco Sa" },
    { name: " Franciscopolis", parent: "MG", value: " Franciscopolis" },
    { name: " Frei Gaspar", parent: "MG", value: " Frei Gaspar" },
    { name: " Frei Inocencio", parent: "MG", value: " Frei Inocencio" },
    { name: " Frei Lagonegro", parent: "MG", value: " Frei Lagonegro" },
    { name: " Fronteira", parent: "MG", value: " Fronteira" },
    {
      name: " Fronteira dos Vales",
      parent: "MG",
      value: " Fronteira dos Vales",
    },
    { name: " Fruta de Leite", parent: "MG", value: " Fruta de Leite" },
    { name: " Frutal", parent: "MG", value: " Frutal" },
    { name: " Funilandia", parent: "MG", value: " Funilandia" },
    { name: " Galileia", parent: "MG", value: " Galileia" },
    { name: " Gameleiras", parent: "MG", value: " Gameleiras" },
    { name: " Glaucilandia", parent: "MG", value: " Glaucilandia" },
    { name: " Goiabeira", parent: "MG", value: " Goiabeira" },
    { name: " Goiana", parent: "MG", value: " Goiana" },
    { name: " Goncalves", parent: "MG", value: " Goncalves" },
    { name: " Gonzaga", parent: "MG", value: " Gonzaga" },
    { name: " Gouvea", parent: "MG", value: " Gouvea" },
    {
      name: " Governador Valadares",
      parent: "MG",
      value: " Governador Valadares",
    },
    { name: " Grao Mogol", parent: "MG", value: " Grao Mogol" },
    { name: " Grupiara", parent: "MG", value: " Grupiara" },
    { name: " Guanhaes", parent: "MG", value: " Guanhaes" },
    { name: " Guape", parent: "MG", value: " Guape" },
    { name: " Guaraciaba", parent: "MG", value: " Guaraciaba" },
    { name: " Guaraciama", parent: "MG", value: " Guaraciama" },
    { name: " Guaranesia", parent: "MG", value: " Guaranesia" },
    { name: " Guarani", parent: "MG", value: " Guarani" },
    { name: " Guarara", parent: "MG", value: " Guarara" },
    { name: " Guarda-Mor", parent: "MG", value: " Guarda-Mor" },
    { name: " Guaxupe", parent: "MG", value: " Guaxupe" },
    { name: " Guidoval", parent: "MG", value: " Guidoval" },
    { name: " Guimarania", parent: "MG", value: " Guimarania" },
    { name: " Guiricema", parent: "MG", value: " Guiricema" },
    { name: " Gurinhata", parent: "MG", value: " Gurinhata" },
    { name: " Heliodora", parent: "MG", value: " Heliodora" },
    { name: " Iapu", parent: "MG", value: " Iapu" },
    { name: " Ibertioga", parent: "MG", value: " Ibertioga" },
    { name: " Ibia", parent: "MG", value: " Ibia" },
    { name: " Ibiai", parent: "MG", value: " Ibiai" },
    { name: " Ibiracatu", parent: "MG", value: " Ibiracatu" },
    { name: " Ibiraci", parent: "MG", value: " Ibiraci" },
    { name: " Ibirite", parent: "MG", value: " Ibirite" },
    { name: " Ibitiura de Minas", parent: "MG", value: " Ibitiura de Minas" },
    { name: " Ibituruna", parent: "MG", value: " Ibituruna" },
    { name: " Icarai de Minas", parent: "MG", value: " Icarai de Minas" },
    { name: " Igarape", parent: "MG", value: " Igarape" },
    { name: " Igaratinga", parent: "MG", value: " Igaratinga" },
    { name: " Iguatama", parent: "MG", value: " Iguatama" },
    { name: " Ijaci", parent: "MG", value: " Ijaci" },
    { name: " Ilicinea", parent: "MG", value: " Ilicinea" },
    { name: " Imbe de Minas", parent: "MG", value: " Imbe de Minas" },
    { name: " Inconfidentes", parent: "MG", value: " Inconfidentes" },
    { name: " Indaiabira", parent: "MG", value: " Indaiabira" },
    { name: " Indianopolis", parent: "MG", value: " Indianopolis" },
    { name: " Ingai", parent: "MG", value: " Ingai" },
    { name: " Inhapim", parent: "MG", value: " Inhapim" },
    { name: " Inhauma", parent: "MG", value: " Inhauma" },
    { name: " Inimutaba", parent: "MG", value: " Inimutaba" },
    { name: " Ipaba", parent: "MG", value: " Ipaba" },
    { name: " Ipanema", parent: "MG", value: " Ipanema" },
    { name: " Ipatinga", parent: "MG", value: " Ipatinga" },
    { name: " Ipiacu", parent: "MG", value: " Ipiacu" },
    { name: " Ipuiuna", parent: "MG", value: " Ipuiuna" },
    { name: " Irai de Minas", parent: "MG", value: " Irai de Minas" },
    { name: " Itabira", parent: "MG", value: " Itabira" },
    {
      name: " Itabirinha de Mantena",
      parent: "MG",
      value: " Itabirinha de Mantena",
    },
    { name: " Itabirito", parent: "MG", value: " Itabirito" },
    { name: " Itacambira", parent: "MG", value: " Itacambira" },
    { name: " Itacarambi", parent: "MG", value: " Itacarambi" },
    { name: " Itaguara", parent: "MG", value: " Itaguara" },
    { name: " Itaipe", parent: "MG", value: " Itaipe" },
    { name: " Itajuba", parent: "MG", value: " Itajuba" },
    { name: " Itamarandiba", parent: "MG", value: " Itamarandiba" },
    { name: " Itamarati de Minas", parent: "MG", value: " Itamarati de Minas" },
    { name: " Itambacuri", parent: "MG", value: " Itambacuri" },
    {
      name: " Itambe do Mato Dentro",
      parent: "MG",
      value: " Itambe do Mato Dentro",
    },
    { name: " Itamogi", parent: "MG", value: " Itamogi" },
    { name: " Itamonte", parent: "MG", value: " Itamonte" },
    { name: " Itanhandu", parent: "MG", value: " Itanhandu" },
    { name: " Itanhomi", parent: "MG", value: " Itanhomi" },
    { name: " Itaobim", parent: "MG", value: " Itaobim" },
    { name: " Itapagipe", parent: "MG", value: " Itapagipe" },
    { name: " Itapecerica", parent: "MG", value: " Itapecerica" },
    { name: " Itapeva", parent: "MG", value: " Itapeva" },
    { name: " Itatiaiucu", parent: "MG", value: " Itatiaiucu" },
    { name: " Itau de Minas", parent: "MG", value: " Itau de Minas" },
    { name: " Itauna", parent: "MG", value: " Itauna" },
    { name: " Itaverava", parent: "MG", value: " Itaverava" },
    { name: " Itinga", parent: "MG", value: " Itinga" },
    { name: " Itueta", parent: "MG", value: " Itueta" },
    { name: " Ituiutaba", parent: "MG", value: " Ituiutaba" },
    { name: " Itumirim", parent: "MG", value: " Itumirim" },
    { name: " Iturama", parent: "MG", value: " Iturama" },
    { name: " Itutinga", parent: "MG", value: " Itutinga" },
    { name: " Jaboticatubas", parent: "MG", value: " Jaboticatubas" },
    { name: " Jacinto", parent: "MG", value: " Jacinto" },
    { name: " Jacui", parent: "MG", value: " Jacui" },
    { name: " Jacutinga", parent: "MG", value: " Jacutinga" },
    { name: " Jaguaracu", parent: "MG", value: " Jaguaracu" },
    { name: " Jaiba", parent: "MG", value: " Jaiba" },
    { name: " Jampruca", parent: "MG", value: " Jampruca" },
    { name: " Janauba", parent: "MG", value: " Janauba" },
    { name: " Januaria", parent: "MG", value: " Januaria" },
    { name: " Japaraiba", parent: "MG", value: " Japaraiba" },
    { name: " Japonvar", parent: "MG", value: " Japonvar" },
    { name: " Jeceaba", parent: "MG", value: " Jeceaba" },
    { name: " Jenipapo de Minas", parent: "MG", value: " Jenipapo de Minas" },
    { name: " Jequeri", parent: "MG", value: " Jequeri" },
    { name: " Jequitai", parent: "MG", value: " Jequitai" },
    { name: " Jequitiba", parent: "MG", value: " Jequitiba" },
    { name: " Jequitinhonha", parent: "MG", value: " Jequitinhonha" },
    { name: " Jesuania", parent: "MG", value: " Jesuania" },
    { name: " Joaima", parent: "MG", value: " Joaima" },
    { name: " Joanesia", parent: "MG", value: " Joanesia" },
    { name: " Joao Monlevade", parent: "MG", value: " Joao Monlevade" },
    { name: " Joao Pinheiro", parent: "MG", value: " Joao Pinheiro" },
    { name: " Joaquim Felicio", parent: "MG", value: " Joaquim Felicio" },
    { name: " Jordania", parent: "MG", value: " Jordania" },
    {
      name: " Jose Goncalves de Minas",
      parent: "MG",
      value: " Jose Goncalves de Minas",
    },
    { name: " Jose Raydan", parent: "MG", value: " Jose Raydan" },
    { name: " Josenopolis", parent: "MG", value: " Josenopolis" },
    { name: " Juatuba", parent: "MG", value: " Juatuba" },
    { name: " Juiz de Fora", parent: "MG", value: " Juiz de Fora" },
    { name: " Juramento", parent: "MG", value: " Juramento" },
    { name: " Juruaia", parent: "MG", value: " Juruaia" },
    { name: " Juvenilia", parent: "MG", value: " Juvenilia" },
    { name: " Ladainha", parent: "MG", value: " Ladainha" },
    { name: " Lagamar", parent: "MG", value: " Lagamar" },
    { name: " Lagoa Dourada", parent: "MG", value: " Lagoa Dourada" },
    { name: " Lagoa Formosa", parent: "MG", value: " Lagoa Formosa" },
    { name: " Lagoa Grande", parent: "MG", value: " Lagoa Grande" },
    { name: " Lagoa Santa", parent: "MG", value: " Lagoa Santa" },
    { name: " Lagoa da Prata", parent: "MG", value: " Lagoa da Prata" },
    { name: " Lagoa dos Patos", parent: "MG", value: " Lagoa dos Patos" },
    { name: " Lagoas", parent: "MG", value: " Lagoas" },
    { name: " Lajinha", parent: "MG", value: " Lajinha" },
    { name: " Lambari", parent: "MG", value: " Lambari" },
    { name: " Lamim", parent: "MG", value: " Lamim" },
    { name: " Laranjal", parent: "MG", value: " Laranjal" },
    { name: " Lassance", parent: "MG", value: " Lassance" },
    { name: " Lavras", parent: "MG", value: " Lavras" },
    { name: " Leandro Ferreira", parent: "MG", value: " Leandro Ferreira" },
    { name: " Leme do Prado", parent: "MG", value: " Leme do Prado" },
    { name: " Leopoldina", parent: "MG", value: " Leopoldina" },
    { name: " Liberdade", parent: "MG", value: " Liberdade" },
    { name: " Lima Duarte", parent: "MG", value: " Lima Duarte" },
    { name: " Limeira do Oeste", parent: "MG", value: " Limeira do Oeste" },
    { name: " Lontra", parent: "MG", value: " Lontra" },
    { name: " Luisburgo", parent: "MG", value: " Luisburgo" },
    { name: " Luislandia", parent: "MG", value: " Luislandia" },
    { name: " Luminarias", parent: "MG", value: " Luminarias" },
    { name: " Luz", parent: "MG", value: " Luz" },
    { name: " Machacalis", parent: "MG", value: " Machacalis" },
    { name: " Machado", parent: "MG", value: " Machado" },
    {
      name: " Madre de Deus de Minas",
      parent: "MG",
      value: " Madre de Deus de Minas",
    },
    { name: " Malacacheta", parent: "MG", value: " Malacacheta" },
    { name: " Mamonas", parent: "MG", value: " Mamonas" },
    { name: " Manga", parent: "MG", value: " Manga" },
    { name: " Manhuacu", parent: "MG", value: " Manhuacu" },
    { name: " Manhumirim", parent: "MG", value: " Manhumirim" },
    { name: " Mantena", parent: "MG", value: " Mantena" },
    { name: " Mar de Espanha", parent: "MG", value: " Mar de Espanha" },
    { name: " Maravilhas", parent: "MG", value: " Maravilhas" },
    { name: " Maria da Fe", parent: "MG", value: " Maria da Fe" },
    { name: " Mariana", parent: "MG", value: " Mariana" },
    { name: " Marilac", parent: "MG", value: " Marilac" },
    { name: " Mario Campos", parent: "MG", value: " Mario Campos" },
    { name: " Maripa de Minas", parent: "MG", value: " Maripa de Minas" },
    { name: " Marlieria", parent: "MG", value: " Marlieria" },
    { name: " Marmelopolis", parent: "MG", value: " Marmelopolis" },
    { name: " Martinho Campos", parent: "MG", value: " Martinho Campos" },
    { name: " Martins Soares", parent: "MG", value: " Martins Soares" },
    { name: " Mata Verde", parent: "MG", value: " Mata Verde" },
    { name: " Materlandia", parent: "MG", value: " Materlandia" },
    { name: " Mateus Leme", parent: "MG", value: " Mateus Leme" },
    { name: " Mathias Lobato", parent: "MG", value: " Mathias Lobato" },
    { name: " Matias Barbosa", parent: "MG", value: " Matias Barbosa" },
    { name: " Matias Cardoso", parent: "MG", value: " Matias Cardoso" },
    { name: " Matipo", parent: "MG", value: " Matipo" },
    { name: " Mato Verde", parent: "MG", value: " Mato Verde" },
    { name: " Matozinhos", parent: "MG", value: " Matozinhos" },
    { name: " Matutina", parent: "MG", value: " Matutina" },
    { name: " Medeiros", parent: "MG", value: " Medeiros" },
    { name: " Medina", parent: "MG", value: " Medina" },
    { name: " Mendes Pimentel", parent: "MG", value: " Mendes Pimentel" },
    { name: " Merces", parent: "MG", value: " Merces" },
    { name: " Mesquita", parent: "MG", value: " Mesquita" },
    { name: " Minas Novas", parent: "MG", value: " Minas Novas" },
    { name: " Minduri", parent: "MG", value: " Minduri" },
    { name: " Mirabela", parent: "MG", value: " Mirabela" },
    { name: " Miradouro", parent: "MG", value: " Miradouro" },
    { name: " Mirai", parent: "MG", value: " Mirai" },
    { name: " Miravania", parent: "MG", value: " Miravania" },
    { name: " Moeda", parent: "MG", value: " Moeda" },
    { name: " Moema", parent: "MG", value: " Moema" },
    { name: " Monjolos", parent: "MG", value: " Monjolos" },
    { name: " Monsenhor Paulo", parent: "MG", value: " Monsenhor Paulo" },
    { name: " Montalvania", parent: "MG", value: " Montalvania" },
    {
      name: " Monte Alegre de Minas",
      parent: "MG",
      value: " Monte Alegre de Minas",
    },
    { name: " Monte Azul", parent: "MG", value: " Monte Azul" },
    { name: " Monte Belo", parent: "MG", value: " Monte Belo" },
    { name: " Monte Carmelo", parent: "MG", value: " Monte Carmelo" },
    { name: " Monte Formoso", parent: "MG", value: " Monte Formoso" },
    {
      name: " Monte Santo de Minas",
      parent: "MG",
      value: " Monte Santo de Minas",
    },
    { name: " Monte Siao", parent: "MG", value: " Monte Siao" },
    { name: " Montes Claros", parent: "MG", value: " Montes Claros" },
    { name: " Montezuma", parent: "MG", value: " Montezuma" },
    {
      name: " Morada Nova de Minas",
      parent: "MG",
      value: " Morada Nova de Minas",
    },
    { name: " Morro da Garca", parent: "MG", value: " Morro da Garca" },
    { name: " Morro do Pilar", parent: "MG", value: " Morro do Pilar" },
    { name: " Munhoz", parent: "MG", value: " Munhoz" },
    { name: " Muriae", parent: "MG", value: " Muriae" },
    { name: " Mutum", parent: "MG", value: " Mutum" },
    { name: " Muzambinho", parent: "MG", value: " Muzambinho" },
    { name: " Nacip Raydan", parent: "MG", value: " Nacip Raydan" },
    { name: " Nanuque", parent: "MG", value: " Nanuque" },
    { name: " Naque", parent: "MG", value: " Naque" },
    { name: " Natalandia", parent: "MG", value: " Natalandia" },
    { name: " Natercia", parent: "MG", value: " Natercia" },
    { name: " Nazareno", parent: "MG", value: " Nazareno" },
    { name: " Nepomuceno", parent: "MG", value: " Nepomuceno" },
    { name: " Ninheira", parent: "MG", value: " Ninheira" },
    { name: " Nova Belem", parent: "MG", value: " Nova Belem" },
    { name: " Nova Era", parent: "MG", value: " Nova Era" },
    { name: " Nova Lima", parent: "MG", value: " Nova Lima" },
    { name: " Nova Modica", parent: "MG", value: " Nova Modica" },
    { name: " Nova Ponte", parent: "MG", value: " Nova Ponte" },
    { name: " Nova Porteirinha", parent: "MG", value: " Nova Porteirinha" },
    { name: " Nova Resende", parent: "MG", value: " Nova Resende" },
    { name: " Nova Serrana", parent: "MG", value: " Nova Serrana" },
    { name: " Nova Uniao", parent: "MG", value: " Nova Uniao" },
    { name: " Novo Cruzeiro", parent: "MG", value: " Novo Cruzeiro" },
    {
      name: " Novo Oriente de Minas",
      parent: "MG",
      value: " Novo Oriente de Minas",
    },
    { name: " Novorizonte", parent: "MG", value: " Novorizonte" },
    { name: " Olaria", parent: "MG", value: " Olaria" },
    { name: " Olhos-d'Agua", parent: "MG", value: " Olhos-d'Agua" },
    { name: " Olimpio Noronha", parent: "MG", value: " Olimpio Noronha" },
    { name: " Oliveira", parent: "MG", value: " Oliveira" },
    { name: " Oliveira Fortes", parent: "MG", value: " Oliveira Fortes" },
    { name: " Onca de Pitangui", parent: "MG", value: " Onca de Pitangui" },
    { name: " Oratorios", parent: "MG", value: " Oratorios" },
    { name: " Orizania", parent: "MG", value: " Orizania" },
    { name: " Ouro Branco", parent: "MG", value: " Ouro Branco" },
    { name: " Ouro Fino", parent: "MG", value: " Ouro Fino" },
    { name: " Ouro Preto", parent: "MG", value: " Ouro Preto" },
    {
      name: " Ouro Verde de Minas",
      parent: "MG",
      value: " Ouro Verde de Minas",
    },
    { name: " Padre Carvalho", parent: "MG", value: " Padre Carvalho" },
    { name: " Padre Paraiso", parent: "MG", value: " Padre Paraiso" },
    { name: " Pai Pedro", parent: "MG", value: " Pai Pedro" },
    { name: " Paineiras", parent: "MG", value: " Paineiras" },
    { name: " Pains", parent: "MG", value: " Pains" },
    { name: " Paiva", parent: "MG", value: " Paiva" },
    { name: " Palma", parent: "MG", value: " Palma" },
    { name: " Palmopolis", parent: "MG", value: " Palmopolis" },
    { name: " Papagaios", parent: "MG", value: " Papagaios" },
    { name: " Para de Minas", parent: "MG", value: " Para de Minas" },
    { name: " Paracatu", parent: "MG", value: " Paracatu" },
    { name: " Paraguacu", parent: "MG", value: " Paraguacu" },
    { name: " Paraisopolis", parent: "MG", value: " Paraisopolis" },
    { name: " Paraopeba", parent: "MG", value: " Paraopeba" },
    { name: " Passa Quatro", parent: "MG", value: " Passa Quatro" },
    { name: " Passa Tempo", parent: "MG", value: " Passa Tempo" },
    { name: " Passa Vinte", parent: "MG", value: " Passa Vinte" },
    { name: " Passabem", parent: "MG", value: " Passabem" },
    { name: " Passos", parent: "MG", value: " Passos" },
    { name: " Patis", parent: "MG", value: " Patis" },
    { name: " Patos de Minas", parent: "MG", value: " Patos de Minas" },
    { name: " Patrocinio", parent: "MG", value: " Patrocinio" },
    {
      name: " Patrocinio do Muriae",
      parent: "MG",
      value: " Patrocinio do Muriae",
    },
    { name: " Paula Candido", parent: "MG", value: " Paula Candido" },
    { name: " Paulistas", parent: "MG", value: " Paulistas" },
    { name: " Pavao", parent: "MG", value: " Pavao" },
    { name: " Pecanha", parent: "MG", value: " Pecanha" },
    { name: " Pedra Azul", parent: "MG", value: " Pedra Azul" },
    { name: " Pedra Bonita", parent: "MG", value: " Pedra Bonita" },
    { name: " Pedra Dourada", parent: "MG", value: " Pedra Dourada" },
    { name: " Pedra do Anta", parent: "MG", value: " Pedra do Anta" },
    { name: " Pedra do Indaia", parent: "MG", value: " Pedra do Indaia" },
    { name: " Pedralva", parent: "MG", value: " Pedralva" },
    {
      name: " Pedras de Maria da Cruz",
      parent: "MG",
      value: " Pedras de Maria da Cruz",
    },
    { name: " Pedrinopolis", parent: "MG", value: " Pedrinopolis" },
    { name: " Pedro Leopoldo", parent: "MG", value: " Pedro Leopoldo" },
    { name: " Pedro Teixeira", parent: "MG", value: " Pedro Teixeira" },
    { name: " Pequeri", parent: "MG", value: " Pequeri" },
    { name: " Pequi", parent: "MG", value: " Pequi" },
    { name: " Perdigao", parent: "MG", value: " Perdigao" },
    { name: " Perdizes", parent: "MG", value: " Perdizes" },
    { name: " Perdoes", parent: "MG", value: " Perdoes" },
    { name: " Periquito", parent: "MG", value: " Periquito" },
    { name: " Pescador", parent: "MG", value: " Pescador" },
    { name: " Piau", parent: "MG", value: " Piau" },
    {
      name: " Piedade de Caratinga",
      parent: "MG",
      value: " Piedade de Caratinga",
    },
    {
      name: " Piedade de Ponte Nova",
      parent: "MG",
      value: " Piedade de Ponte Nova",
    },
    {
      name: " Piedade do Rio Grande",
      parent: "MG",
      value: " Piedade do Rio Grande",
    },
    { name: " Piedade dos Gerais", parent: "MG", value: " Piedade dos Gerais" },
    { name: " Pimenta", parent: "MG", value: " Pimenta" },
    { name: " Pingo d'Agua", parent: "MG", value: " Pingo d'Agua" },
    { name: " Pintopolis", parent: "MG", value: " Pintopolis" },
    { name: " Piracema", parent: "MG", value: " Piracema" },
    { name: " Pirajuba", parent: "MG", value: " Pirajuba" },
    { name: " Piranga", parent: "MG", value: " Piranga" },
    { name: " Pirangucu", parent: "MG", value: " Pirangucu" },
    { name: " Piranguinho", parent: "MG", value: " Piranguinho" },
    { name: " Pirapetinga", parent: "MG", value: " Pirapetinga" },
    { name: " Pirapora", parent: "MG", value: " Pirapora" },
    { name: " Pirauba", parent: "MG", value: " Pirauba" },
    { name: " Pitangui", parent: "MG", value: " Pitangui" },
    { name: " Piui", parent: "MG", value: " Piui" },
    { name: " Planura", parent: "MG", value: " Planura" },
    { name: " Poco Fundo", parent: "MG", value: " Poco Fundo" },
    { name: " Pocos de Caldas", parent: "MG", value: " Pocos de Caldas" },
    { name: " Pocrane", parent: "MG", value: " Pocrane" },
    { name: " Pompeu", parent: "MG", value: " Pompeu" },
    { name: " Ponte Nova", parent: "MG", value: " Ponte Nova" },
    { name: " Ponto Chique", parent: "MG", value: " Ponto Chique" },
    { name: " Ponto dos Volantes", parent: "MG", value: " Ponto dos Volantes" },
    { name: " Porteirinha", parent: "MG", value: " Porteirinha" },
    { name: " Porto Firme", parent: "MG", value: " Porto Firme" },
    { name: " Pote", parent: "MG", value: " Pote" },
    { name: " Pouso Alegre", parent: "MG", value: " Pouso Alegre" },
    { name: " Pouso Alto", parent: "MG", value: " Pouso Alto" },
    { name: " Prados", parent: "MG", value: " Prados" },
    { name: " Prata", parent: "MG", value: " Prata" },
    { name: " Pratapolis", parent: "MG", value: " Pratapolis" },
    { name: " Pratinha", parent: "MG", value: " Pratinha" },
    {
      name: " Presidente Bernardes",
      parent: "MG",
      value: " Presidente Bernardes",
    },
    {
      name: " Presidente Juscelino",
      parent: "MG",
      value: " Presidente Juscelino",
    },
    {
      name: " Presidente Kubitschek",
      parent: "MG",
      value: " Presidente Kubitschek",
    },
    {
      name: " Presidente Olegario",
      parent: "MG",
      value: " Presidente Olegario",
    },
    { name: " Prudente de Morais", parent: "MG", value: " Prudente de Morais" },
    { name: " Quartel Geral", parent: "MG", value: " Quartel Geral" },
    { name: " Queluzita", parent: "MG", value: " Queluzita" },
    { name: " Raposos", parent: "MG", value: " Raposos" },
    { name: " Raul Soares", parent: "MG", value: " Raul Soares" },
    { name: " Recreio", parent: "MG", value: " Recreio" },
    { name: " Reduto", parent: "MG", value: " Reduto" },
    { name: " Resende Costa", parent: "MG", value: " Resende Costa" },
    { name: " Resplendor", parent: "MG", value: " Resplendor" },
    { name: " Ressaquinha", parent: "MG", value: " Ressaquinha" },
    { name: " Riachinho", parent: "MG", value: " Riachinho" },
    {
      name: " Riacho dos Machados",
      parent: "MG",
      value: " Riacho dos Machados",
    },
    { name: " Ribeirao Vermelho", parent: "MG", value: " Ribeirao Vermelho" },
    { name: " Ribeirao das Neves", parent: "MG", value: " Ribeirao das Neves" },
    { name: " Rio Acima", parent: "MG", value: " Rio Acima" },
    { name: " Rio Casca", parent: "MG", value: " Rio Casca" },
    { name: " Rio Doce", parent: "MG", value: " Rio Doce" },
    { name: " Rio Espera", parent: "MG", value: " Rio Espera" },
    { name: " Rio Manso", parent: "MG", value: " Rio Manso" },
    { name: " Rio Novo", parent: "MG", value: " Rio Novo" },
    { name: " Rio Paranaiba", parent: "MG", value: " Rio Paranaiba" },
    { name: " Rio Pardo de Minas", parent: "MG", value: " Rio Pardo de Minas" },
    { name: " Rio Piracicaba", parent: "MG", value: " Rio Piracicaba" },
    { name: " Rio Pomba", parent: "MG", value: " Rio Pomba" },
    { name: " Rio Preto", parent: "MG", value: " Rio Preto" },
    { name: " Rio Vermelho", parent: "MG", value: " Rio Vermelho" },
    { name: " Rio do Prado", parent: "MG", value: " Rio do Prado" },
    { name: " Ritapolis", parent: "MG", value: " Ritapolis" },
    { name: " Rochedo de Minas", parent: "MG", value: " Rochedo de Minas" },
    { name: " Rodeiro", parent: "MG", value: " Rodeiro" },
    { name: " Romaria", parent: "MG", value: " Romaria" },
    { name: " Rosario da Limeira", parent: "MG", value: " Rosario da Limeira" },
    { name: " Rubelita", parent: "MG", value: " Rubelita" },
    { name: " Rubim", parent: "MG", value: " Rubim" },
    { name: " Sabara", parent: "MG", value: " Sabara" },
    { name: " Sabinopolis", parent: "MG", value: " Sabinopolis" },
    { name: " Sacramento", parent: "MG", value: " Sacramento" },
    { name: " Salinas", parent: "MG", value: " Salinas" },
    { name: " Salto da Divisa", parent: "MG", value: " Salto da Divisa" },
    { name: " Santa Barbara", parent: "MG", value: " Santa Barbara" },
    {
      name: " Santa Barbara do Leste",
      parent: "MG",
      value: " Santa Barbara do Leste",
    },
    {
      name: " Santa Barbara do Monte Verde",
      parent: "MG",
      value: " Santa Barbara do Monte Verde",
    },
    {
      name: " Santa Barbara do Tugurio",
      parent: "MG",
      value: " Santa Barbara do Tugurio",
    },
    {
      name: " Santa Cruz de Minas",
      parent: "MG",
      value: " Santa Cruz de Minas",
    },
    {
      name: " Santa Cruz de Salinas",
      parent: "MG",
      value: " Santa Cruz de Salinas",
    },
    {
      name: " Santa Cruz do Escalvado",
      parent: "MG",
      value: " Santa Cruz do Escalvado",
    },
    {
      name: " Santa Efigenia de Minas",
      parent: "MG",
      value: " Santa Efigenia de Minas",
    },
    { name: " Santa Fe de Minas", parent: "MG", value: " Santa Fe de Minas" },
    {
      name: " Santa Helena de Minas",
      parent: "MG",
      value: " Santa Helena de Minas",
    },
    { name: " Santa Juliana", parent: "MG", value: " Santa Juliana" },
    { name: " Santa Luzia", parent: "MG", value: " Santa Luzia" },
    { name: " Santa Margarida", parent: "MG", value: " Santa Margarida" },
    {
      name: " Santa Maria de Itabira",
      parent: "MG",
      value: " Santa Maria de Itabira",
    },
    {
      name: " Santa Maria do Salto",
      parent: "MG",
      value: " Santa Maria do Salto",
    },
    {
      name: " Santa Maria do Suacui",
      parent: "MG",
      value: " Santa Maria do Suacui",
    },
    {
      name: " Santa Rita de Caldas",
      parent: "MG",
      value: " Santa Rita de Caldas",
    },
    {
      name: " Santa Rita de Ibitipoca",
      parent: "MG",
      value: " Santa Rita de Ibitipoca",
    },
    {
      name: " Santa Rita de Jacutinga",
      parent: "MG",
      value: " Santa Rita de Jacutinga",
    },
    {
      name: " Santa Rita de Minas",
      parent: "MG",
      value: " Santa Rita de Minas",
    },
    {
      name: " Santa Rita do Itueto",
      parent: "MG",
      value: " Santa Rita do Itueto",
    },
    {
      name: " Santa Rita do Sapucai",
      parent: "MG",
      value: " Santa Rita do Sapucai",
    },
    {
      name: " Santa Rosa da Serra",
      parent: "MG",
      value: " Santa Rosa da Serra",
    },
    { name: " Santa Vitoria", parent: "MG", value: " Santa Vitoria" },
    { name: " Santana da Vargem", parent: "MG", value: " Santana da Vargem" },
    {
      name: " Santana de Cataguases",
      parent: "MG",
      value: " Santana de Cataguases",
    },
    {
      name: " Santana de Pirapama",
      parent: "MG",
      value: " Santana de Pirapama",
    },
    { name: " Santana do Deserto", parent: "MG", value: " Santana do Deserto" },
    {
      name: " Santana do Garambeu",
      parent: "MG",
      value: " Santana do Garambeu",
    },
    { name: " Santana do Jacare", parent: "MG", value: " Santana do Jacare" },
    {
      name: " Santana do Manhuacu",
      parent: "MG",
      value: " Santana do Manhuacu",
    },
    { name: " Santana do Paraiso", parent: "MG", value: " Santana do Paraiso" },
    { name: " Santana do Riacho", parent: "MG", value: " Santana do Riacho" },
    { name: " Santana dos Montes", parent: "MG", value: " Santana dos Montes" },
    {
      name: " Santo Antonio do Amparo",
      parent: "MG",
      value: " Santo Antonio do Amparo",
    },
    {
      name: " Santo Antonio do Aventureiro",
      parent: "MG",
      value: " Santo Antonio do Aventureiro",
    },
    {
      name: " Santo Antonio do Grama",
      parent: "MG",
      value: " Santo Antonio do Grama",
    },
    {
      name: " Santo Antonio do Itambe",
      parent: "MG",
      value: " Santo Antonio do Itambe",
    },
    {
      name: " Santo Antonio do Jacinto",
      parent: "MG",
      value: " Santo Antonio do Jacinto",
    },
    {
      name: " Santo Antonio do Monte",
      parent: "MG",
      value: " Santo Antonio do Monte",
    },
    {
      name: " Santo Antonio do Retiro",
      parent: "MG",
      value: " Santo Antonio do Retiro",
    },
    {
      name: " Santo Antonio do Rio Abaixo",
      parent: "MG",
      value: " Santo Antonio do Rio Abaixo",
    },
    { name: " Santo Hipolito", parent: "MG", value: " Santo Hipolito" },
    { name: " Santos Dumont", parent: "MG", value: " Santos Dumont" },
    { name: " Sao Bento Abade", parent: "MG", value: " Sao Bento Abade" },
    { name: " Sao Bras do Suacui", parent: "MG", value: " Sao Bras do Suacui" },
    {
      name: " Sao Domingos das Dores",
      parent: "MG",
      value: " Sao Domingos das Dores",
    },
    {
      name: " Sao Domingos do Prata",
      parent: "MG",
      value: " Sao Domingos do Prata",
    },
    { name: " Sao Felix de Minas", parent: "MG", value: " Sao Felix de Minas" },
    { name: " Sao Francisco", parent: "MG", value: " Sao Francisco" },
    {
      name: " Sao Francisco de Paula",
      parent: "MG",
      value: " Sao Francisco de Paula",
    },
    {
      name: " Sao Francisco de Sales",
      parent: "MG",
      value: " Sao Francisco de Sales",
    },
    {
      name: " Sao Francisco do Gloria",
      parent: "MG",
      value: " Sao Francisco do Gloria",
    },
    { name: " Sao Geraldo", parent: "MG", value: " Sao Geraldo" },
    {
      name: " Sao Geraldo da Piedade",
      parent: "MG",
      value: " Sao Geraldo da Piedade",
    },
    {
      name: " Sao Geraldo do Baixio",
      parent: "MG",
      value: " Sao Geraldo do Baixio",
    },
    {
      name: " Sao Goncalo do Abaete",
      parent: "MG",
      value: " Sao Goncalo do Abaete",
    },
    {
      name: " Sao Goncalo do Para",
      parent: "MG",
      value: " Sao Goncalo do Para",
    },
    {
      name: " Sao Goncalo do Rio Abaixo",
      parent: "MG",
      value: " Sao Goncalo do Rio Abaixo",
    },
    {
      name: " Sao Goncalo do Rio Preto",
      parent: "MG",
      value: " Sao Goncalo do Rio Preto",
    },
    {
      name: " Sao Goncalo do Sapucai",
      parent: "MG",
      value: " Sao Goncalo do Sapucai",
    },
    { name: " Sao Gotardo", parent: "MG", value: " Sao Gotardo" },
    {
      name: " Sao Joao Batista do Gloria",
      parent: "MG",
      value: " Sao Joao Batista do Gloria",
    },
    {
      name: " Sao Joao Evangelista",
      parent: "MG",
      value: " Sao Joao Evangelista",
    },
    {
      name: " Sao Joao Nepomuceno",
      parent: "MG",
      value: " Sao Joao Nepomuceno",
    },
    { name: " Sao Joao da Lagoa", parent: "MG", value: " Sao Joao da Lagoa" },
    { name: " Sao Joao da Mata", parent: "MG", value: " Sao Joao da Mata" },
    { name: " Sao Joao da Ponte", parent: "MG", value: " Sao Joao da Ponte" },
    {
      name: " Sao Joao das Missoes",
      parent: "MG",
      value: " Sao Joao das Missoes",
    },
    { name: " Sao Joao del Rei", parent: "MG", value: " Sao Joao del Rei" },
    {
      name: " Sao Joao do Manhuacu",
      parent: "MG",
      value: " Sao Joao do Manhuacu",
    },
    {
      name: " Sao Joao do Manteninha",
      parent: "MG",
      value: " Sao Joao do Manteninha",
    },
    {
      name: " Sao Joao do Oriente",
      parent: "MG",
      value: " Sao Joao do Oriente",
    },
    { name: " Sao Joao do Pacui", parent: "MG", value: " Sao Joao do Pacui" },
    {
      name: " Sao Joao do Paraiso",
      parent: "MG",
      value: " Sao Joao do Paraiso",
    },
    {
      name: " Sao Joaquim de Bicas",
      parent: "MG",
      value: " Sao Joaquim de Bicas",
    },
    { name: " Sao Jose da Barra", parent: "MG", value: " Sao Jose da Barra" },
    { name: " Sao Jose da Lapa", parent: "MG", value: " Sao Jose da Lapa" },
    { name: " Sao Jose da Safira", parent: "MG", value: " Sao Jose da Safira" },
    {
      name: " Sao Jose da Varginha",
      parent: "MG",
      value: " Sao Jose da Varginha",
    },
    { name: " Sao Jose do Alegre", parent: "MG", value: " Sao Jose do Alegre" },
    { name: " Sao Jose do Divino", parent: "MG", value: " Sao Jose do Divino" },
    {
      name: " Sao Jose do Goiabal",
      parent: "MG",
      value: " Sao Jose do Goiabal",
    },
    { name: " Sao Jose do Jacuri", parent: "MG", value: " Sao Jose do Jacuri" },
    {
      name: " Sao Jose do Mantimento",
      parent: "MG",
      value: " Sao Jose do Mantimento",
    },
    { name: " Sao Lourenco", parent: "MG", value: " Sao Lourenco" },
    { name: " Sao Miguel do Anta", parent: "MG", value: " Sao Miguel do Anta" },
    { name: " Sao Pedro da Uniao", parent: "MG", value: " Sao Pedro da Uniao" },
    {
      name: " Sao Pedro do Suacui",
      parent: "MG",
      value: " Sao Pedro do Suacui",
    },
    {
      name: " Sao Pedro dos Ferros",
      parent: "MG",
      value: " Sao Pedro dos Ferros",
    },
    { name: " Sao Romao", parent: "MG", value: " Sao Romao" },
    { name: " Sao Roque de Minas", parent: "MG", value: " Sao Roque de Minas" },
    {
      name: " Sao Sebastiao da Bela Vista",
      parent: "MG",
      value: " Sao Sebastiao da Bela Vista",
    },
    {
      name: " Sao Sebastiao da Vargem Alegre",
      parent: "MG",
      value: " Sao Sebastiao da Vargem Alegre",
    },
    {
      name: " Sao Sebastiao do Anta",
      parent: "MG",
      value: " Sao Sebastiao do Anta",
    },
    {
      name: " Sao Sebastiao do Maranhao",
      parent: "MG",
      value: " Sao Sebastiao do Maranhao",
    },
    {
      name: " Sao Sebastiao do Oeste",
      parent: "MG",
      value: " Sao Sebastiao do Oeste",
    },
    {
      name: " Sao Sebastiao do Paraiso",
      parent: "MG",
      value: " Sao Sebastiao do Paraiso",
    },
    {
      name: " Sao Sebastiao do Rio Preto",
      parent: "MG",
      value: " Sao Sebastiao do Rio Preto",
    },
    {
      name: " Sao Sebastiao do Rio Verde",
      parent: "MG",
      value: " Sao Sebastiao do Rio Verde",
    },
    {
      name: " Sao Thome das Letras",
      parent: "MG",
      value: " Sao Thome das Letras",
    },
    { name: " Sao Tiago", parent: "MG", value: " Sao Tiago" },
    {
      name: " Sao Tomas de Aquino",
      parent: "MG",
      value: " Sao Tomas de Aquino",
    },
    {
      name: " Sao Vicente de Minas",
      parent: "MG",
      value: " Sao Vicente de Minas",
    },
    { name: " Sapucai-Mirim", parent: "MG", value: " Sapucai-Mirim" },
    { name: " Sardoa", parent: "MG", value: " Sardoa" },
    { name: " Sarzedo", parent: "MG", value: " Sarzedo" },
    { name: " Sem-Peixe", parent: "MG", value: " Sem-Peixe" },
    { name: " Senador Amaral", parent: "MG", value: " Senador Amaral" },
    { name: " Senador Cortes", parent: "MG", value: " Senador Cortes" },
    { name: " Senador Firmino", parent: "MG", value: " Senador Firmino" },
    { name: " Senador Jose Bento", parent: "MG", value: " Senador Jose Bento" },
    {
      name: " Senador Modestino Goncalves",
      parent: "MG",
      value: " Senador Modestino Goncalves",
    },
    {
      name: " Senhora de Oliveira",
      parent: "MG",
      value: " Senhora de Oliveira",
    },
    { name: " Senhora do Porto", parent: "MG", value: " Senhora do Porto" },
    {
      name: " Senhora dos Remedios",
      parent: "MG",
      value: " Senhora dos Remedios",
    },
    { name: " Sericita", parent: "MG", value: " Sericita" },
    { name: " Seritinga", parent: "MG", value: " Seritinga" },
    {
      name: " Serra Azul de Minas",
      parent: "MG",
      value: " Serra Azul de Minas",
    },
    { name: " Serra da Saudade", parent: "MG", value: " Serra da Saudade" },
    { name: " Serra do Salitre", parent: "MG", value: " Serra do Salitre" },
    { name: " Serra dos Aimores", parent: "MG", value: " Serra dos Aimores" },
    { name: " Serrania", parent: "MG", value: " Serrania" },
    {
      name: " Serranopolis de Minas",
      parent: "MG",
      value: " Serranopolis de Minas",
    },
    { name: " Serranos", parent: "MG", value: " Serranos" },
    { name: " Serro", parent: "MG", value: " Serro" },
    { name: " Sete Lagoas", parent: "MG", value: " Sete Lagoas" },
    { name: " Setubinha", parent: "MG", value: " Setubinha" },
    { name: " Silveirania", parent: "MG", value: " Silveirania" },
    { name: " Silvianopolis", parent: "MG", value: " Silvianopolis" },
    { name: " Simao Pereira", parent: "MG", value: " Simao Pereira" },
    { name: " Simonesia", parent: "MG", value: " Simonesia" },
    { name: " Sobralia", parent: "MG", value: " Sobralia" },
    { name: " Soledade de Minas", parent: "MG", value: " Soledade de Minas" },
    { name: " Tabuleiro", parent: "MG", value: " Tabuleiro" },
    { name: " Taiobeiras", parent: "MG", value: " Taiobeiras" },
    { name: " Taparuba", parent: "MG", value: " Taparuba" },
    { name: " Tapira", parent: "MG", value: " Tapira" },
    { name: " Tapirai", parent: "MG", value: " Tapirai" },
    { name: " Taquaracu de Minas", parent: "MG", value: " Taquaracu de Minas" },
    { name: " Tarumirim", parent: "MG", value: " Tarumirim" },
    { name: " Teixeiras", parent: "MG", value: " Teixeiras" },
    { name: " Teofilo Otoni", parent: "MG", value: " Teofilo Otoni" },
    { name: " Timoteo", parent: "MG", value: " Timoteo" },
    { name: " Tiradentes", parent: "MG", value: " Tiradentes" },
    { name: " Tiros", parent: "MG", value: " Tiros" },
    { name: " Tocantins", parent: "MG", value: " Tocantins" },
    { name: " Tocos do Moji", parent: "MG", value: " Tocos do Moji" },
    { name: " Toledo", parent: "MG", value: " Toledo" },
    { name: " Tombos", parent: "MG", value: " Tombos" },
    { name: " Tres Coracoes", parent: "MG", value: " Tres Coracoes" },
    { name: " Tres Marias", parent: "MG", value: " Tres Marias" },
    { name: " Tres Pontas", parent: "MG", value: " Tres Pontas" },
    { name: " Tumiritinga", parent: "MG", value: " Tumiritinga" },
    { name: " Tupaciguara", parent: "MG", value: " Tupaciguara" },
    { name: " Turmalina", parent: "MG", value: " Turmalina" },
    { name: " Turvolandia", parent: "MG", value: " Turvolandia" },
    { name: " Uba", parent: "MG", value: " Uba" },
    { name: " Ubai", parent: "MG", value: " Ubai" },
    { name: " Ubaporanga", parent: "MG", value: " Ubaporanga" },
    { name: " Uberaba", parent: "MG", value: " Uberaba" },
    { name: " Uberlandia", parent: "MG", value: " Uberlandia" },
    { name: " Umburatiba", parent: "MG", value: " Umburatiba" },
    { name: " Unai", parent: "MG", value: " Unai" },
    { name: " Uniao de Minas", parent: "MG", value: " Uniao de Minas" },
    { name: " Uruana de Minas", parent: "MG", value: " Uruana de Minas" },
    { name: " Urucania", parent: "MG", value: " Urucania" },
    { name: " Urucuia", parent: "MG", value: " Urucuia" },
    { name: " Vargem Alegre", parent: "MG", value: " Vargem Alegre" },
    { name: " Vargem Bonita", parent: "MG", value: " Vargem Bonita" },
    {
      name: " Vargem Grande do Rio Pardo",
      parent: "MG",
      value: " Vargem Grande do Rio Pardo",
    },
    { name: " Varginha", parent: "MG", value: " Varginha" },
    { name: " Varjao de MInas", parent: "MG", value: " Varjao de MInas" },
    { name: " Varzea da Palma", parent: "MG", value: " Varzea da Palma" },
    { name: " Varzelandia", parent: "MG", value: " Varzelandia" },
    { name: " Vazante", parent: "MG", value: " Vazante" },
    { name: " Verdelandia", parent: "MG", value: " Verdelandia" },
    { name: " Veredinha", parent: "MG", value: " Veredinha" },
    { name: " Verissimo", parent: "MG", value: " Verissimo" },
    { name: " Vermelho Novo", parent: "MG", value: " Vermelho Novo" },
    { name: " Vespasiano", parent: "MG", value: " Vespasiano" },
    { name: " Vicosa", parent: "MG", value: " Vicosa" },
    { name: " Vieiras", parent: "MG", value: " Vieiras" },
    { name: " Virgem da Lapa", parent: "MG", value: " Virgem da Lapa" },
    { name: " Virginia", parent: "MG", value: " Virginia" },
    { name: " Virginopolis", parent: "MG", value: " Virginopolis" },
    { name: " Virgolandia", parent: "MG", value: " Virgolandia" },
    {
      name: " Visconde do Rio Branco",
      parent: "MG",
      value: " Visconde do Rio Branco",
    },
    { name: " Volta Grande", parent: "MG", value: " Volta Grande" },
    { name: " Wenceslau Braz", parent: "MG", value: " Wenceslau Braz" },
    { name: "Abadia dos Dourados", parent: "MG", value: "Abadia dos Dourados" },

    { name: "Agua Clara", parent: "MS", value: "Agua Clara" },
    { name: "Alcinopolis", parent: "MS", value: "Alcinopolis" },
    { name: "Amambai", parent: "MS", value: "Amambai" },
    { name: "Anastacio", parent: "MS", value: "Anastacio" },
    { name: "Anaurilandia", parent: "MS", value: "Anaurilandia" },
    { name: "Angelica", parent: "MS", value: "Angelica" },
    { name: "Antonio Joao", parent: "MS", value: "Antonio Joao" },
    {
      name: "Aparecida do Taboado",
      parent: "MS",
      value: "Aparecida do Taboado",
    },
    { name: "Aquidauana", parent: "MS", value: "Aquidauana" },
    { name: "Aral Moreira", parent: "MS", value: "Aral Moreira" },
    { name: "Bandeirantes", parent: "MS", value: "Bandeirantes" },
    { name: "Bataguassu", parent: "MS", value: "Bataguassu" },
    { name: "Bataipora", parent: "MS", value: "Bataipora" },
    { name: "Bela Vista", parent: "MS", value: "Bela Vista" },
    { name: "Bodoquena", parent: "MS", value: "Bodoquena" },
    { name: "Bonito", parent: "MS", value: "Bonito" },
    { name: "Brasilandia", parent: "MS", value: "Brasilandia" },
    { name: "Caarapo", parent: "MS", value: "Caarapo" },
    { name: "Camapua", parent: "MS", value: "Camapua" },
    { name: "Campo Grande", parent: "MS", value: "Campo Grande" },
    { name: "Caracol", parent: "MS", value: "Caracol" },
    { name: "Cassilandia", parent: "MS", value: "Cassilandia" },
    { name: "Chapadao do Sul", parent: "MS", value: "Chapadao do Sul" },
    { name: "Corguinho", parent: "MS", value: "Corguinho" },
    { name: "Coronel Sapucaia", parent: "MS", value: "Coronel Sapucaia" },
    { name: "Corumba", parent: "MS", value: "Corumba" },
    { name: "Costa Rica", parent: "MS", value: "Costa Rica" },
    { name: "Coxim", parent: "MS", value: "Coxim" },
    { name: "Deodapolis", parent: "MS", value: "Deodapolis" },
    {
      name: "Dois Irmaos do Buriti",
      parent: "MS",
      value: "Dois Irmaos do Buriti",
    },
    { name: "Douradina", parent: "MS", value: "Douradina" },
    { name: "Dourados", parent: "MS", value: "Dourados" },
    { name: "Eldorado", parent: "MS", value: "Eldorado" },
    { name: "Fatima do Sul", parent: "MS", value: "Fatima do Sul" },
    { name: "Gloria de Dourados", parent: "MS", value: "Gloria de Dourados" },
    {
      name: "Guia Lopes da Laguna",
      parent: "MS",
      value: "Guia Lopes da Laguna",
    },
    { name: "Iguatemi", parent: "MS", value: "Iguatemi" },
    { name: "Inocencia", parent: "MS", value: "Inocencia" },
    { name: "Itapora", parent: "MS", value: "Itapora" },
    { name: "Itaquirai", parent: "MS", value: "Itaquirai" },
    { name: "Ivinhema", parent: "MS", value: "Ivinhema" },
    { name: "Japora", parent: "MS", value: "Japora" },
    { name: "Jaraguari", parent: "MS", value: "Jaraguari" },
    { name: "Jardim", parent: "MS", value: "Jardim" },
    { name: "Jatei", parent: "MS", value: "Jatei" },
    { name: "Juti", parent: "MS", value: "Juti" },
    { name: "Ladario", parent: "MS", value: "Ladario" },
    { name: "Laguna Carapa", parent: "MS", value: "Laguna Carapa" },
    { name: "Maracaju", parent: "MS", value: "Maracaju" },
    { name: "Miranda", parent: "MS", value: "Miranda" },
    { name: "Mundo Novo", parent: "MS", value: "Mundo Novo" },
    { name: "Navirai", parent: "MS", value: "Navirai" },
    { name: "Nioaque", parent: "MS", value: "Nioaque" },
    {
      name: "Nova Alvorada do Sul",
      parent: "MS",
      value: "Nova Alvorada do Sul",
    },
    { name: "Nova Andradina", parent: "MS", value: "Nova Andradina" },
    {
      name: "Novo Horizonte do Sul",
      parent: "MS",
      value: "Novo Horizonte do Sul",
    },
    { name: "Paranaiba", parent: "MS", value: "Paranaiba" },
    { name: "Paranhos", parent: "MS", value: "Paranhos" },
    { name: "Pedro Gomes", parent: "MS", value: "Pedro Gomes" },
    { name: "Ponta Pora", parent: "MS", value: "Ponta Pora" },
    { name: "Porto Murtinho", parent: "MS", value: "Porto Murtinho" },
    { name: "Ribas do Rio Pardo", parent: "MS", value: "Ribas do Rio Pardo" },
    { name: "Rio Brilhante", parent: "MS", value: "Rio Brilhante" },
    { name: "Rio Negro", parent: "MS", value: "Rio Negro" },
    {
      name: "Rio Verde de Mato Grosso",
      parent: "MS",
      value: "Rio Verde de Mato Grosso",
    },
    { name: "Rochedo", parent: "MS", value: "Rochedo" },
    { name: "Santa Rita do Pardo", parent: "MS", value: "Santa Rita do Pardo" },
    {
      name: "Sao Gabriel do Oeste",
      parent: "MS",
      value: "Sao Gabriel do Oeste",
    },
    { name: "Selviria", parent: "MS", value: "Selviria" },
    { name: "Sete Quedas", parent: "MS", value: "Sete Quedas" },
    { name: "Sidrolandia", parent: "MS", value: "Sidrolandia" },
    { name: "Sonora", parent: "MS", value: "Sonora" },
    { name: "Tacuru", parent: "MS", value: "Tacuru" },
    { name: "Taquarussu", parent: "MS", value: "Taquarussu" },
    { name: "Terenos", parent: "MS", value: "Terenos" },
    { name: "Tres Lagoas", parent: "MS", value: "Tres Lagoas" },
    { name: "Vicentina", parent: "MS", value: "Vicentina" },

    { name: "Acorizal", parent: "MT", value: "Acorizal" },
    { name: "Agua Boa", parent: "MT", value: "Agua Boa" },
    { name: "Alta Floresta", parent: "MT", value: "Alta Floresta" },
    { name: "Alto Araguaia", parent: "MT", value: "Alto Araguaia" },
    { name: "Alto Boa Vista", parent: "MT", value: "Alto Boa Vista" },
    { name: "Alto Garcas", parent: "MT", value: "Alto Garcas" },
    { name: "Alto Paraguai", parent: "MT", value: "Alto Paraguai" },
    { name: "Alto Taquari", parent: "MT", value: "Alto Taquari" },
    { name: "Apiacas", parent: "MT", value: "Apiacas" },
    { name: "Araguaiana", parent: "MT", value: "Araguaiana" },
    { name: "Araguainha", parent: "MT", value: "Araguainha" },
    { name: "Araputanga", parent: "MT", value: "Araputanga" },
    { name: "Arenapolis", parent: "MT", value: "Arenapolis" },
    { name: "Aripuana", parent: "MT", value: "Aripuana" },
    { name: "Barao de Melgaco", parent: "MT", value: "Barao de Melgaco" },
    { name: "Barra do Bugres", parent: "MT", value: "Barra do Bugres" },
    { name: "Barra do Garcas", parent: "MT", value: "Barra do Garcas" },
    { name: "Brasnorte", parent: "MT", value: "Brasnorte" },
    { name: "Caceres", parent: "MT", value: "Caceres" },
    { name: "Campinapolis", parent: "MT", value: "Campinapolis" },
    {
      name: "Campo Novo do Parecis",
      parent: "MT",
      value: "Campo Novo do Parecis",
    },
    { name: "Campo Verde", parent: "MT", value: "Campo Verde" },
    { name: "Campos de Julio", parent: "MT", value: "Campos de Julio" },
    { name: "Cana Brava do Norte", parent: "MT", value: "Cana Brava do Norte" },
    { name: "Canarana", parent: "MT", value: "Canarana" },
    { name: "Carlinda", parent: "MT", value: "Carlinda" },
    { name: "Castanheira", parent: "MT", value: "Castanheira" },
    {
      name: "Chapada dos Guimaraes",
      parent: "MT",
      value: "Chapada dos Guimaraes",
    },
    { name: "Claudia", parent: "MT", value: "Claudia" },
    { name: "Cocalinho", parent: "MT", value: "Cocalinho" },
    { name: "Colider", parent: "MT", value: "Colider" },
    { name: "Comodoro", parent: "MT", value: "Comodoro" },
    { name: "Confresa", parent: "MT", value: "Confresa" },
    { name: "Cotriguacu", parent: "MT", value: "Cotriguacu" },
    { name: "Cuiaba", parent: "MT", value: "Cuiaba" },
    { name: "Denise", parent: "MT", value: "Denise" },
    { name: "Diamantino", parent: "MT", value: "Diamantino" },
    { name: "Dom Aquino", parent: "MT", value: "Dom Aquino" },
    { name: "Feliz Natal", parent: "MT", value: "Feliz Natal" },
    {
      name: "Figueiropolis d'Oeste",
      parent: "MT",
      value: "Figueiropolis d'Oeste",
    },
    { name: "Gaucha do Norte", parent: "MT", value: "Gaucha do Norte" },
    { name: "General Carneiro", parent: "MT", value: "General Carneiro" },
    { name: "Gloria d'Oeste", parent: "MT", value: "Gloria d'Oeste" },
    { name: "Guaranta do Norte", parent: "MT", value: "Guaranta do Norte" },
    { name: "Guiratinga", parent: "MT", value: "Guiratinga" },
    { name: "Indiavai", parent: "MT", value: "Indiavai" },
    { name: "Itauba", parent: "MT", value: "Itauba" },
    { name: "Itiquira", parent: "MT", value: "Itiquira" },
    { name: "Jaciara", parent: "MT", value: "Jaciara" },
    { name: "Jangada", parent: "MT", value: "Jangada" },
    { name: "Jauru", parent: "MT", value: "Jauru" },
    { name: "Juara", parent: "MT", value: "Juara" },
    { name: "Juina", parent: "MT", value: "Juina" },
    { name: "Juruena", parent: "MT", value: "Juruena" },
    { name: "Juscimeira", parent: "MT", value: "Juscimeira" },
    { name: "Lambari d'Oeste", parent: "MT", value: "Lambari d'Oeste" },
    { name: "Lucas do Rio Verde", parent: "MT", value: "Lucas do Rio Verde" },
    { name: "Luciara", parent: "MT", value: "Luciara" },
    { name: "Marcelandia", parent: "MT", value: "Marcelandia" },
    { name: "Matupa", parent: "MT", value: "Matupa" },
    { name: "Mirassol d'Oeste", parent: "MT", value: "Mirassol d'Oeste" },
    { name: "Nobres", parent: "MT", value: "Nobres" },
    { name: "Nortelandia", parent: "MT", value: "Nortelandia" },
    {
      name: "Nossa Senhora do Livramento",
      parent: "MT",
      value: "Nossa Senhora do Livramento",
    },
    { name: "Nova Bandeirantes", parent: "MT", value: "Nova Bandeirantes" },
    { name: "Nova Brasilandia", parent: "MT", value: "Nova Brasilandia" },
    { name: "Nova Canaa do Norte", parent: "MT", value: "Nova Canaa do Norte" },
    { name: "Nova Guarita", parent: "MT", value: "Nova Guarita" },
    { name: "Nova Lacerda", parent: "MT", value: "Nova Lacerda" },
    { name: "Nova Marilandia", parent: "MT", value: "Nova Marilandia" },
    { name: "Nova Maringa", parent: "MT", value: "Nova Maringa" },
    { name: "Nova Monte Verde", parent: "MT", value: "Nova Monte Verde" },
    { name: "Nova Mutum", parent: "MT", value: "Nova Mutum" },
    { name: "Nova Olimpia", parent: "MT", value: "Nova Olimpia" },
    { name: "Nova Ubirata", parent: "MT", value: "Nova Ubirata" },
    { name: "Nova Xavantina", parent: "MT", value: "Nova Xavantina" },
    {
      name: "Novo Horizonte do Norte",
      parent: "MT",
      value: "Novo Horizonte do Norte",
    },
    { name: "Novo Mundo", parent: "MT", value: "Novo Mundo" },
    { name: "Novo Sao Joaquim", parent: "MT", value: "Novo Sao Joaquim" },
    { name: "Paranaita", parent: "MT", value: "Paranaita" },
    { name: "Paranatinga", parent: "MT", value: "Paranatinga" },
    { name: "Pedra Preta", parent: "MT", value: "Pedra Preta" },
    { name: "Peixoto de Azevedo", parent: "MT", value: "Peixoto de Azevedo" },
    { name: "Planalto da Serra", parent: "MT", value: "Planalto da Serra" },
    { name: "Pocone", parent: "MT", value: "Pocone" },
    { name: "Pontal do Araguaia", parent: "MT", value: "Pontal do Araguaia" },
    { name: "Ponte Branca", parent: "MT", value: "Ponte Branca" },
    { name: "Pontes e Lacerda", parent: "MT", value: "Pontes e Lacerda" },
    {
      name: "Porto Alegre do Norte",
      parent: "MT",
      value: "Porto Alegre do Norte",
    },
    { name: "Porto Esperidiao", parent: "MT", value: "Porto Esperidiao" },
    { name: "Porto Estrela", parent: "MT", value: "Porto Estrela" },
    { name: "Porto dos Gauchos", parent: "MT", value: "Porto dos Gauchos" },
    { name: "Poxoreo", parent: "MT", value: "Poxoreo" },
    { name: "Primavera do Leste", parent: "MT", value: "Primavera do Leste" },
    { name: "Querencia", parent: "MT", value: "Querencia" },
    { name: "Reserva do Cabacal", parent: "MT", value: "Reserva do Cabacal" },
    {
      name: "Ribeirao Cascalheira",
      parent: "MT",
      value: "Ribeirao Cascalheira",
    },
    { name: "Ribeiraozinho", parent: "MT", value: "Ribeiraozinho" },
    { name: "Rio Branco", parent: "MT", value: "Rio Branco" },
    { name: "Rondonopolis", parent: "MT", value: "Rondonopolis" },
    { name: "Rosario Oeste", parent: "MT", value: "Rosario Oeste" },
    { name: "Salto do Ceu", parent: "MT", value: "Salto do Ceu" },
    { name: "Santa Carmem", parent: "MT", value: "Santa Carmem" },
    { name: "Santa Terezinha", parent: "MT", value: "Santa Terezinha" },
    { name: "Santo Afonso", parent: "MT", value: "Santo Afonso" },
    {
      name: "Santo Antonio do Leverger",
      parent: "MT",
      value: "Santo Antonio do Leverger",
    },
    {
      name: "Sao Felix do Araguaia",
      parent: "MT",
      value: "Sao Felix do Araguaia",
    },
    { name: "Sao Jose do Povo", parent: "MT", value: "Sao Jose do Povo" },
    {
      name: "Sao Jose do Rio Claro",
      parent: "MT",
      value: "Sao Jose do Rio Claro",
    },
    { name: "Sao Jose do Xingu", parent: "MT", value: "Sao Jose do Xingu" },
    {
      name: "Sao Jose dos Quatro Marcos",
      parent: "MT",
      value: "Sao Jose dos Quatro Marcos",
    },
    { name: "Sao Pedro da Cipa", parent: "MT", value: "Sao Pedro da Cipa" },
    { name: "Sapezal", parent: "MT", value: "Sapezal" },
    { name: "Sinop", parent: "MT", value: "Sinop" },
    { name: "Sorriso", parent: "MT", value: "Sorriso" },
    { name: "Tabapora", parent: "MT", value: "Tabapora" },
    { name: "Tangara da Serra", parent: "MT", value: "Tangara da Serra" },
    { name: "Tapurah", parent: "MT", value: "Tapurah" },
    { name: "Terra Nova do Norte", parent: "MT", value: "Terra Nova do Norte" },
    { name: "Tesouro", parent: "MT", value: "Tesouro" },
    { name: "Torixoreu", parent: "MT", value: "Torixoreu" },
    { name: "Uniao do Sul", parent: "MT", value: "Uniao do Sul" },
    { name: "Varzea Grande", parent: "MT", value: "Varzea Grande" },
    { name: "Vera", parent: "MT", value: "Vera" },
    {
      name: "Vila Bela da Santissima Trindade",
      parent: "MT",
      value: "Vila Bela da Santissima Trindade",
    },
    { name: "Vila Rica", parent: "MT", value: "Vila Rica" },

    { name: "Abaetetuba", parent: "PA", value: "Abaetetuba" },
    { name: "Abel Figueiredo", parent: "PA", value: "Abel Figueiredo" },
    { name: "Acara", parent: "PA", value: "Acara" },
    { name: "Afua", parent: "PA", value: "Afua" },
    { name: "Agua Azul do Norte", parent: "PA", value: "Agua Azul do Norte" },
    { name: "Alenquer", parent: "PA", value: "Alenquer" },
    { name: "Almeirim", parent: "PA", value: "Almeirim" },
    { name: "Altamira", parent: "PA", value: "Altamira" },
    { name: "Anajas", parent: "PA", value: "Anajas" },
    { name: "Ananindeua", parent: "PA", value: "Ananindeua" },
    { name: "Anapu", parent: "PA", value: "Anapu" },
    { name: "Augusto Correa", parent: "PA", value: "Augusto Correa" },
    { name: "Aurora do Para", parent: "PA", value: "Aurora do Para" },
    { name: "Aveiro", parent: "PA", value: "Aveiro" },
    { name: "Bagre", parent: "PA", value: "Bagre" },
    { name: "Baiao", parent: "PA", value: "Baiao" },
    { name: "Bannach", parent: "PA", value: "Bannach" },
    { name: "Barcarena", parent: "PA", value: "Barcarena" },
    { name: "Belem", parent: "PA", value: "Belem" },
    { name: "Belterra", parent: "PA", value: "Belterra" },
    { name: "Benevides", parent: "PA", value: "Benevides" },
    {
      name: "Bom Jesus do Tocantins",
      parent: "PA",
      value: "Bom Jesus do Tocantins",
    },
    { name: "Bonito", parent: "PA", value: "Bonito" },
    { name: "Braganca", parent: "PA", value: "Braganca" },
    { name: "Brasil Novo", parent: "PA", value: "Brasil Novo" },
    {
      name: "Brejo Grande do Araguaia",
      parent: "PA",
      value: "Brejo Grande do Araguaia",
    },
    { name: "Breu Branco", parent: "PA", value: "Breu Branco" },
    { name: "Breves", parent: "PA", value: "Breves" },
    { name: "Bujaru", parent: "PA", value: "Bujaru" },
    { name: "Cachoeira do Arari", parent: "PA", value: "Cachoeira do Arari" },
    { name: "Cachoeira do Piria", parent: "PA", value: "Cachoeira do Piria" },
    { name: "Cameta", parent: "PA", value: "Cameta" },
    { name: "Canaa dos Carajas", parent: "PA", value: "Canaa dos Carajas" },
    { name: "Capanema", parent: "PA", value: "Capanema" },
    { name: "Capitao Poco", parent: "PA", value: "Capitao Poco" },
    { name: "Castanhal", parent: "PA", value: "Castanhal" },
    { name: "Chaves", parent: "PA", value: "Chaves" },
    { name: "Colares", parent: "PA", value: "Colares" },
    {
      name: "Conceicao do Araguaia",
      parent: "PA",
      value: "Conceicao do Araguaia",
    },
    { name: "Concordia do Para", parent: "PA", value: "Concordia do Para" },
    { name: "Cumaru do Norte", parent: "PA", value: "Cumaru do Norte" },
    { name: "Curionopolis", parent: "PA", value: "Curionopolis" },
    { name: "Curralinho", parent: "PA", value: "Curralinho" },
    { name: "Curua", parent: "PA", value: "Curua" },
    { name: "Curuca", parent: "PA", value: "Curuca" },
    { name: "Dom Eliseu", parent: "PA", value: "Dom Eliseu" },
    {
      name: "Eldorado dos Carajas",
      parent: "PA",
      value: "Eldorado dos Carajas",
    },
    { name: "Faro", parent: "PA", value: "Faro" },
    {
      name: "Floresta do Araguaia",
      parent: "PA",
      value: "Floresta do Araguaia",
    },
    { name: "Garrafao do Norte", parent: "PA", value: "Garrafao do Norte" },
    { name: "Goianesia do Para", parent: "PA", value: "Goianesia do Para" },
    { name: "Gurupa", parent: "PA", value: "Gurupa" },
    { name: "Igarape-Acu", parent: "PA", value: "Igarape-Acu" },
    { name: "Igarape-Miri", parent: "PA", value: "Igarape-Miri" },
    { name: "Inhangapi", parent: "PA", value: "Inhangapi" },
    { name: "Ipixuna do Para", parent: "PA", value: "Ipixuna do Para" },
    { name: "Irituia", parent: "PA", value: "Irituia" },
    { name: "Itaituba", parent: "PA", value: "Itaituba" },
    { name: "Itupiranga", parent: "PA", value: "Itupiranga" },
    { name: "Jacareacanga", parent: "PA", value: "Jacareacanga" },
    { name: "Jacunda", parent: "PA", value: "Jacunda" },
    { name: "Juruti", parent: "PA", value: "Juruti" },
    { name: "Limoeiro do Ajuru", parent: "PA", value: "Limoeiro do Ajuru" },
    { name: "Mae do Rio", parent: "PA", value: "Mae do Rio" },
    { name: "Magalhaes Barata", parent: "PA", value: "Magalhaes Barata" },
    { name: "Maraba", parent: "PA", value: "Maraba" },
    { name: "Maracana", parent: "PA", value: "Maracana" },
    { name: "Marapanim", parent: "PA", value: "Marapanim" },
    { name: "Marituba", parent: "PA", value: "Marituba" },
    { name: "Medicilandia", parent: "PA", value: "Medicilandia" },
    { name: "Melgaco", parent: "PA", value: "Melgaco" },
    { name: "Mocajuba", parent: "PA", value: "Mocajuba" },
    { name: "Moju", parent: "PA", value: "Moju" },
    { name: "Monte Alegre", parent: "PA", value: "Monte Alegre" },
    { name: "Muana", parent: "PA", value: "Muana" },
    {
      name: "Nova Esperanca do Piria",
      parent: "PA",
      value: "Nova Esperanca do Piria",
    },
    { name: "Nova Ipixuna", parent: "PA", value: "Nova Ipixuna" },
    { name: "Nova Timboteua", parent: "PA", value: "Nova Timboteua" },
    { name: "Novo Progresso", parent: "PA", value: "Novo Progresso" },
    { name: "Novo Repartimento", parent: "PA", value: "Novo Repartimento" },
    { name: "Obidos", parent: "PA", value: "Obidos" },
    { name: "Oeiras do Para", parent: "PA", value: "Oeiras do Para" },
    { name: "Oriximina", parent: "PA", value: "Oriximina" },
    { name: "Ourem", parent: "PA", value: "Ourem" },
    { name: "Ourilandia do Norte", parent: "PA", value: "Ourilandia do Norte" },
    { name: "Pacaja", parent: "PA", value: "Pacaja" },
    { name: "Palestina do Para", parent: "PA", value: "Palestina do Para" },
    { name: "Paragominas", parent: "PA", value: "Paragominas" },
    { name: "Parauapebas", parent: "PA", value: "Parauapebas" },
    { name: "Pau d'Arco", parent: "PA", value: "Pau d'Arco" },
    { name: "Peixe-Boi", parent: "PA", value: "Peixe-Boi" },
    { name: "Picarra", parent: "PA", value: "Picarra" },
    { name: "Placas", parent: "PA", value: "Placas" },
    { name: "Ponta de Pedras", parent: "PA", value: "Ponta de Pedras" },
    { name: "Portel", parent: "PA", value: "Portel" },
    { name: "Porto de Moz", parent: "PA", value: "Porto de Moz" },
    { name: "Prainha", parent: "PA", value: "Prainha" },
    { name: "Primavera", parent: "PA", value: "Primavera" },
    { name: "Quatipuru", parent: "PA", value: "Quatipuru" },
    { name: "Redencao", parent: "PA", value: "Redencao" },
    { name: "Rio Maria", parent: "PA", value: "Rio Maria" },
    { name: "Rondon do Para", parent: "PA", value: "Rondon do Para" },
    { name: "Ruropolis", parent: "PA", value: "Ruropolis" },
    { name: "Salinopolis", parent: "PA", value: "Salinopolis" },
    { name: "Salvaterra", parent: "PA", value: "Salvaterra" },
    {
      name: "Santa Barbara do Para",
      parent: "PA",
      value: "Santa Barbara do Para",
    },
    { name: "Santa Cruz do Arari", parent: "PA", value: "Santa Cruz do Arari" },
    {
      name: "Santa Isabel do Para",
      parent: "PA",
      value: "Santa Isabel do Para",
    },
    { name: "Santa Luzia do Para", parent: "PA", value: "Santa Luzia do Para" },
    {
      name: "Santa Maria das Barreiras",
      parent: "PA",
      value: "Santa Maria das Barreiras",
    },
    { name: "Santa Maria do Para", parent: "PA", value: "Santa Maria do Para" },
    { name: "Santana do Araguaia", parent: "PA", value: "Santana do Araguaia" },
    { name: "Santarem", parent: "PA", value: "Santarem" },
    { name: "Santarem Novo", parent: "PA", value: "Santarem Novo" },
    {
      name: "Santo Antonio do Taua",
      parent: "PA",
      value: "Santo Antonio do Taua",
    },
    {
      name: "Sao Caetano de Odivelas",
      parent: "PA",
      value: "Sao Caetano de Odivelas",
    },
    {
      name: "Sao Domingos do Araguaia",
      parent: "PA",
      value: "Sao Domingos do Araguaia",
    },
    {
      name: "Sao Domingos do Capim",
      parent: "PA",
      value: "Sao Domingos do Capim",
    },
    { name: "Sao Felix do Xingu", parent: "PA", value: "Sao Felix do Xingu" },
    {
      name: "Sao Francisco do Para",
      parent: "PA",
      value: "Sao Francisco do Para",
    },
    {
      name: "Sao Geraldo do Araguaia",
      parent: "PA",
      value: "Sao Geraldo do Araguaia",
    },
    { name: "Sao Joao da Ponta", parent: "PA", value: "Sao Joao da Ponta" },
    { name: "Sao Joao de Pirabas", parent: "PA", value: "Sao Joao de Pirabas" },
    {
      name: "Sao Joao do Araguaia",
      parent: "PA",
      value: "Sao Joao do Araguaia",
    },
    { name: "Sao Miguel do Guama", parent: "PA", value: "Sao Miguel do Guama" },
    {
      name: "Sao Sebastiao da Boa Vista",
      parent: "PA",
      value: "Sao Sebastiao da Boa Vista",
    },
    { name: "Sapucaia", parent: "PA", value: "Sapucaia" },
    {
      name: "Senador Jose Porfirio",
      parent: "PA",
      value: "Senador Jose Porfirio",
    },
    { name: "Soure", parent: "PA", value: "Soure" },
    { name: "Tailandia", parent: "PA", value: "Tailandia" },
    { name: "Terra Alta", parent: "PA", value: "Terra Alta" },
    { name: "Terra Santa", parent: "PA", value: "Terra Santa" },
    { name: "Tome-Acu", parent: "PA", value: "Tome-Acu" },
    { name: "Tracuateua", parent: "PA", value: "Tracuateua" },
    { name: "Trairao", parent: "PA", value: "Trairao" },
    { name: "Tucuma", parent: "PA", value: "Tucuma" },
    { name: "Tucurui", parent: "PA", value: "Tucurui" },
    { name: "Ulianopolis", parent: "PA", value: "Ulianopolis" },
    { name: "Uruara", parent: "PA", value: "Uruara" },
    { name: "Vigia", parent: "PA", value: "Vigia" },
    { name: "Viseu", parent: "PA", value: "Viseu" },
    { name: "Vitoria do Xingu", parent: "PA", value: "Vitoria do Xingu" },
    { name: "Xinguara", parent: "PA", value: "Xinguara" },

    { name: "Agua Branca", parent: "PB", value: "Agua Branca" },
    { name: "Aguiar", parent: "PB", value: "Aguiar" },
    { name: "Alagoa Grande", parent: "PB", value: "Alagoa Grande" },
    { name: "Alagoa Nova", parent: "PB", value: "Alagoa Nova" },
    { name: "Alagoinha", parent: "PB", value: "Alagoinha" },
    { name: "Alcantil", parent: "PB", value: "Alcantil" },
    { name: "Algodao de Jandaira", parent: "PB", value: "Algodao de Jandaira" },
    { name: "Alhandra", parent: "PB", value: "Alhandra" },
    { name: "Amparo", parent: "PB", value: "Amparo" },
    { name: "Aparecida", parent: "PB", value: "Aparecida" },
    { name: "Aracagi", parent: "PB", value: "Aracagi" },
    { name: "Arara", parent: "PB", value: "Arara" },
    { name: "Araruna", parent: "PB", value: "Araruna" },
    { name: "Areia", parent: "PB", value: "Areia" },
    { name: "Areia de Baraunas", parent: "PB", value: "Areia de Baraunas" },
    { name: "Areial", parent: "PB", value: "Areial" },
    { name: "Aroeiras", parent: "PB", value: "Aroeiras" },
    { name: "Assuncao", parent: "PB", value: "Assuncao" },
    { name: "Baia da Traicao", parent: "PB", value: "Baia da Traicao" },
    { name: "Bananeiras", parent: "PB", value: "Bananeiras" },
    { name: "Barauna", parent: "PB", value: "Barauna" },
    { name: "Barra de Santa Rosa", parent: "PB", value: "Barra de Santa Rosa" },
    { name: "Barra de Santana", parent: "PB", value: "Barra de Santana" },
    { name: "Barra de Sao Miguel", parent: "PB", value: "Barra de Sao Miguel" },
    { name: "Bayeux", parent: "PB", value: "Bayeux" },
    { name: "Belem", parent: "PB", value: "Belem" },
    {
      name: "Belem do Brejo do Cruz",
      parent: "PB",
      value: "Belem do Brejo do Cruz",
    },
    { name: "Bernardino Batista", parent: "PB", value: "Bernardino Batista" },
    { name: "Boa Ventura", parent: "PB", value: "Boa Ventura" },
    { name: "Boa Vista", parent: "PB", value: "Boa Vista" },
    { name: "Bom Jesus", parent: "PB", value: "Bom Jesus" },
    { name: "Bom Sucesso", parent: "PB", value: "Bom Sucesso" },
    { name: "Bonito de Santa Fe", parent: "PB", value: "Bonito de Santa Fe" },
    { name: "Boqueirao", parent: "PB", value: "Boqueirao" },
    { name: "Borborema", parent: "PB", value: "Borborema" },
    { name: "Brejo do Cruz", parent: "PB", value: "Brejo do Cruz" },
    { name: "Brejo dos Santos", parent: "PB", value: "Brejo dos Santos" },
    { name: "Caapora", parent: "PB", value: "Caapora" },
    { name: "Cabaceiras", parent: "PB", value: "Cabaceiras" },
    { name: "Cabedelo", parent: "PB", value: "Cabedelo" },
    {
      name: "Cachoeira dos ?ndios",
      parent: "PB",
      value: "Cachoeira dos ?ndios",
    },
    { name: "Cacimba de Areia", parent: "PB", value: "Cacimba de Areia" },
    { name: "Cacimba de Dentro", parent: "PB", value: "Cacimba de Dentro" },
    { name: "Cacimbas", parent: "PB", value: "Cacimbas" },
    { name: "Caicara", parent: "PB", value: "Caicara" },
    { name: "Cajazeiras", parent: "PB", value: "Cajazeiras" },
    { name: "Cajazeirinhas", parent: "PB", value: "Cajazeirinhas" },
    { name: "Caldas Brandao", parent: "PB", value: "Caldas Brandao" },
    { name: "Camalau", parent: "PB", value: "Camalau" },
    { name: "Campina Grande", parent: "PB", value: "Campina Grande" },
    { name: "Capim", parent: "PB", value: "Capim" },
    { name: "Caraubas", parent: "PB", value: "Caraubas" },
    { name: "Carrapateira", parent: "PB", value: "Carrapateira" },
    { name: "Casserengue", parent: "PB", value: "Casserengue" },
    { name: "Catingueira", parent: "PB", value: "Catingueira" },
    { name: "Catole do Rocha", parent: "PB", value: "Catole do Rocha" },
    { name: "Caturite", parent: "PB", value: "Caturite" },
    { name: "Conceicao", parent: "PB", value: "Conceicao" },
    { name: "Condado", parent: "PB", value: "Condado" },
    { name: "Conde", parent: "PB", value: "Conde" },
    { name: "Congo", parent: "PB", value: "Congo" },
    { name: "Coremas", parent: "PB", value: "Coremas" },
    { name: "Coxixola", parent: "PB", value: "Coxixola" },
    {
      name: "Cruz do Espirito Santo",
      parent: "PB",
      value: "Cruz do Espirito Santo",
    },
    { name: "Cubati", parent: "PB", value: "Cubati" },
    { name: "Cuite", parent: "PB", value: "Cuite" },
    { name: "Cuite de Mamanguape", parent: "PB", value: "Cuite de Mamanguape" },
    { name: "Cuitegi", parent: "PB", value: "Cuitegi" },
    { name: "Curral Velho", parent: "PB", value: "Curral Velho" },
    { name: "Curral de Cima", parent: "PB", value: "Curral de Cima" },
    { name: "Damiao", parent: "PB", value: "Damiao" },
    { name: "Desterro", parent: "PB", value: "Desterro" },
    { name: "Diamante", parent: "PB", value: "Diamante" },
    { name: "Dona Ines", parent: "PB", value: "Dona Ines" },
    { name: "Duas Estradas", parent: "PB", value: "Duas Estradas" },
    { name: "Emas", parent: "PB", value: "Emas" },
    { name: "Esperanca", parent: "PB", value: "Esperanca" },
    { name: "Fagundes", parent: "PB", value: "Fagundes" },
    { name: "Frei Martinho", parent: "PB", value: "Frei Martinho" },
    { name: "Gado Bravo", parent: "PB", value: "Gado Bravo" },
    { name: "Guarabira", parent: "PB", value: "Guarabira" },
    { name: "Gurinhem", parent: "PB", value: "Gurinhem" },
    { name: "Gurjao", parent: "PB", value: "Gurjao" },
    { name: "Ibiara", parent: "PB", value: "Ibiara" },
    { name: "Igaracy", parent: "PB", value: "Igaracy" },
    { name: "Imaculada", parent: "PB", value: "Imaculada" },
    { name: "Inga", parent: "PB", value: "Inga" },
    { name: "Itabaiana", parent: "PB", value: "Itabaiana" },
    { name: "Itaporanga", parent: "PB", value: "Itaporanga" },
    { name: "Itapororoca", parent: "PB", value: "Itapororoca" },
    { name: "Itatuba", parent: "PB", value: "Itatuba" },
    { name: "Jacarau", parent: "PB", value: "Jacarau" },
    { name: "Jerico", parent: "PB", value: "Jerico" },
    { name: "Joao Pessoa", parent: "PB", value: "Joao Pessoa" },
    { name: "Juarez Tavora", parent: "PB", value: "Juarez Tavora" },
    { name: "Juazeirinho", parent: "PB", value: "Juazeirinho" },
    { name: "Junco do Serido", parent: "PB", value: "Junco do Serido" },
    { name: "Juripiranga", parent: "PB", value: "Juripiranga" },
    { name: "Juru", parent: "PB", value: "Juru" },
    { name: "Lagoa", parent: "PB", value: "Lagoa" },
    { name: "Lagoa Seca", parent: "PB", value: "Lagoa Seca" },
    { name: "Lagoa de Dentro", parent: "PB", value: "Lagoa de Dentro" },
    { name: "Lastro", parent: "PB", value: "Lastro" },
    { name: "Livramento", parent: "PB", value: "Livramento" },
    { name: "Logradouro", parent: "PB", value: "Logradouro" },
    { name: "Lucena", parent: "PB", value: "Lucena" },
    { name: "Mae d'Agua", parent: "PB", value: "Mae d'Agua" },
    { name: "Malta", parent: "PB", value: "Malta" },
    { name: "Mamanguape", parent: "PB", value: "Mamanguape" },
    { name: "Manaira", parent: "PB", value: "Manaira" },
    { name: "Marcacao", parent: "PB", value: "Marcacao" },
    { name: "Mari", parent: "PB", value: "Mari" },
    { name: "Marizopolis", parent: "PB", value: "Marizopolis" },
    { name: "Massaranduba", parent: "PB", value: "Massaranduba" },
    { name: "Mataraca", parent: "PB", value: "Mataraca" },
    { name: "Matinhas", parent: "PB", value: "Matinhas" },
    { name: "Mato Grosso", parent: "PB", value: "Mato Grosso" },
    { name: "Matureia", parent: "PB", value: "Matureia" },
    { name: "Mogeiro", parent: "PB", value: "Mogeiro" },
    { name: "Montadas", parent: "PB", value: "Montadas" },
    { name: "Monte Horebe", parent: "PB", value: "Monte Horebe" },
    { name: "Monteiro", parent: "PB", value: "Monteiro" },
    { name: "Mulungu", parent: "PB", value: "Mulungu" },
    { name: "Natuba", parent: "PB", value: "Natuba" },
    { name: "Nazarezinho", parent: "PB", value: "Nazarezinho" },
    { name: "Nova Floresta", parent: "PB", value: "Nova Floresta" },
    { name: "Nova Olinda", parent: "PB", value: "Nova Olinda" },
    { name: "Nova Palmeira", parent: "PB", value: "Nova Palmeira" },
    { name: "Olho d'Agua", parent: "PB", value: "Olho d'Agua" },
    { name: "Olivedos", parent: "PB", value: "Olivedos" },
    { name: "Ouro Velho", parent: "PB", value: "Ouro Velho" },
    { name: "Parari", parent: "PB", value: "Parari" },
    { name: "Passagem", parent: "PB", value: "Passagem" },
    { name: "Patos", parent: "PB", value: "Patos" },
    { name: "Paulista", parent: "PB", value: "Paulista" },
    { name: "Pedra Branca", parent: "PB", value: "Pedra Branca" },
    { name: "Pedra Lavrada", parent: "PB", value: "Pedra Lavrada" },
    { name: "Pedras de Fogo", parent: "PB", value: "Pedras de Fogo" },
    { name: "Pedro Regio", parent: "PB", value: "Pedro Regio" },
    { name: "Pianco", parent: "PB", value: "Pianco" },
    { name: "Picui", parent: "PB", value: "Picui" },
    { name: "Pilar", parent: "PB", value: "Pilar" },
    { name: "Piloes", parent: "PB", value: "Piloes" },
    { name: "Piloezinhos", parent: "PB", value: "Piloezinhos" },
    { name: "Pirpirituba", parent: "PB", value: "Pirpirituba" },
    { name: "Pitimbu", parent: "PB", value: "Pitimbu" },
    { name: "Pocinhos", parent: "PB", value: "Pocinhos" },
    { name: "Poco Dantas", parent: "PB", value: "Poco Dantas" },
    {
      name: "Poco de Jose de Moura",
      parent: "PB",
      value: "Poco de Jose de Moura",
    },
    { name: "Pombal", parent: "PB", value: "Pombal" },
    { name: "Prata", parent: "PB", value: "Prata" },
    { name: "Princesa Isabel", parent: "PB", value: "Princesa Isabel" },
    { name: "Puxinana", parent: "PB", value: "Puxinana" },
    { name: "Queimadas", parent: "PB", value: "Queimadas" },
    { name: "Quixaba", parent: "PB", value: "Quixaba" },
    { name: "Remigio", parent: "PB", value: "Remigio" },
    { name: "Riachao", parent: "PB", value: "Riachao" },
    {
      name: "Riachao do Bacamarte",
      parent: "PB",
      value: "Riachao do Bacamarte",
    },
    { name: "Riachao do Poco", parent: "PB", value: "Riachao do Poco" },
    {
      name: "Riacho de Santo Antonio",
      parent: "PB",
      value: "Riacho de Santo Antonio",
    },
    { name: "Riacho dos Cavalos", parent: "PB", value: "Riacho dos Cavalos" },
    { name: "Rio Tinto", parent: "PB", value: "Rio Tinto" },
    { name: "Salgadinho", parent: "PB", value: "Salgadinho" },
    {
      name: "Salgado de Sao Felix",
      parent: "PB",
      value: "Salgado de Sao Felix",
    },
    { name: "Santa Cecilia", parent: "PB", value: "Santa Cecilia" },
    { name: "Santa Cruz", parent: "PB", value: "Santa Cruz" },
    { name: "Santa Helena", parent: "PB", value: "Santa Helena" },
    { name: "Santa Ines", parent: "PB", value: "Santa Ines" },
    { name: "Santa Luzia", parent: "PB", value: "Santa Luzia" },
    { name: "Santa Rita", parent: "PB", value: "Santa Rita" },
    { name: "Santa Teresinha", parent: "PB", value: "Santa Teresinha" },
    {
      name: "Santana de Mangueira",
      parent: "PB",
      value: "Santana de Mangueira",
    },
    {
      name: "Santana dos Garrotes",
      parent: "PB",
      value: "Santana dos Garrotes",
    },
    { name: "Santarem", parent: "PB", value: "Santarem" },
    { name: "Santo Andre", parent: "PB", value: "Santo Andre" },
    { name: "Sao Bento", parent: "PB", value: "Sao Bento" },
    { name: "Sao Bento de Pombal", parent: "PB", value: "Sao Bento de Pombal" },
    {
      name: "Sao Domingos de Pombal",
      parent: "PB",
      value: "Sao Domingos de Pombal",
    },
    {
      name: "Sao Domingos do Cariri",
      parent: "PB",
      value: "Sao Domingos do Cariri",
    },
    { name: "Sao Francisco", parent: "PB", value: "Sao Francisco" },
    { name: "Sao Joao do Cariri", parent: "PB", value: "Sao Joao do Cariri" },
    {
      name: "Sao Joao do Rio do Peixe",
      parent: "PB",
      value: "Sao Joao do Rio do Peixe",
    },
    { name: "Sao Joao do Tigre", parent: "PB", value: "Sao Joao do Tigre" },
    {
      name: "Sao Jose da Lagoa Tapada",
      parent: "PB",
      value: "Sao Jose da Lagoa Tapada",
    },
    { name: "Sao Jose de Caiana", parent: "PB", value: "Sao Jose de Caiana" },
    {
      name: "Sao Jose de Espinharas",
      parent: "PB",
      value: "Sao Jose de Espinharas",
    },
    {
      name: "Sao Jose de Piranhas",
      parent: "PB",
      value: "Sao Jose de Piranhas",
    },
    {
      name: "Sao Jose de Princesa",
      parent: "PB",
      value: "Sao Jose de Princesa",
    },
    { name: "Sao Jose do Bonfim", parent: "PB", value: "Sao Jose do Bonfim" },
    {
      name: "Sao Jose do Brejo do Cruz",
      parent: "PB",
      value: "Sao Jose do Brejo do Cruz",
    },
    { name: "Sao Jose do Sabugi", parent: "PB", value: "Sao Jose do Sabugi" },
    {
      name: "Sao Jose dos Cordeiros",
      parent: "PB",
      value: "Sao Jose dos Cordeiros",
    },
    { name: "Sao Jose dos Ramos", parent: "PB", value: "Sao Jose dos Ramos" },
    { name: "Sao Mamede", parent: "PB", value: "Sao Mamede" },
    { name: "Sao Miguel de Taipu", parent: "PB", value: "Sao Miguel de Taipu" },
    {
      name: "Sao Sebastiao de Lagoa de Roca",
      parent: "PB",
      value: "Sao Sebastiao de Lagoa de Roca",
    },
    {
      name: "Sao Sebastiao do Umbuzeiro",
      parent: "PB",
      value: "Sao Sebastiao do Umbuzeiro",
    },
    { name: "Sape", parent: "PB", value: "Sape" },
    { name: "Serido", parent: "PB", value: "Serido" },
    { name: "Serra Branca", parent: "PB", value: "Serra Branca" },
    { name: "Serra Grande", parent: "PB", value: "Serra Grande" },
    { name: "Serra Redonda", parent: "PB", value: "Serra Redonda" },
    { name: "Serra da Raiz", parent: "PB", value: "Serra da Raiz" },
    { name: "Serraria", parent: "PB", value: "Serraria" },
    { name: "Sertaozinho", parent: "PB", value: "Sertaozinho" },
    { name: "Sobrado", parent: "PB", value: "Sobrado" },
    { name: "Solanea", parent: "PB", value: "Solanea" },
    { name: "Soledade", parent: "PB", value: "Soledade" },
    { name: "Sossego", parent: "PB", value: "Sossego" },
    { name: "Sousa", parent: "PB", value: "Sousa" },
    { name: "Sume", parent: "PB", value: "Sume" },
    { name: "Tacima", parent: "PB", value: "Tacima" },
    { name: "Taperoa", parent: "PB", value: "Taperoa" },
    { name: "Tavares", parent: "PB", value: "Tavares" },
    { name: "Teixeira", parent: "PB", value: "Teixeira" },
    { name: "Tenorio", parent: "PB", value: "Tenorio" },
    { name: "Triunfo", parent: "PB", value: "Triunfo" },
    { name: "Uirauna", parent: "PB", value: "Uirauna" },
    { name: "Umbuzeiro", parent: "PB", value: "Umbuzeiro" },
    { name: "Varzea", parent: "PB", value: "Varzea" },
    { name: "Vieiropolis", parent: "PB", value: "Vieiropolis" },
    { name: "Vista Serrana", parent: "PB", value: "Vista Serrana" },
    { name: "Zabele", parent: "PB", value: "Zabele" },

    { name: "Abreu e Lima", parent: "PE", value: "Abreu e Lima" },
    {
      name: "Afogados da Ingazeira",
      parent: "PE",
      value: "Afogados da Ingazeira",
    },
    { name: "Afranio", parent: "PE", value: "Afranio" },
    { name: "Agrestina", parent: "PE", value: "Agrestina" },
    { name: "Agua Preta", parent: "PE", value: "Agua Preta" },
    { name: "Aguas Belas", parent: "PE", value: "Aguas Belas" },
    { name: "Alagoinha", parent: "PE", value: "Alagoinha" },
    { name: "Alianca", parent: "PE", value: "Alianca" },
    { name: "Altinho", parent: "PE", value: "Altinho" },
    { name: "Amaraji", parent: "PE", value: "Amaraji" },
    { name: "Angelim", parent: "PE", value: "Angelim" },
    { name: "Aracoiaba", parent: "PE", value: "Aracoiaba" },
    { name: "Araripina", parent: "PE", value: "Araripina" },
    { name: "Arcoverde", parent: "PE", value: "Arcoverde" },
    { name: "Barra de Guabiraba", parent: "PE", value: "Barra de Guabiraba" },
    { name: "Barreiros", parent: "PE", value: "Barreiros" },
    { name: "Belem de Maria", parent: "PE", value: "Belem de Maria" },
    {
      name: "Belem de Sao Francisco",
      parent: "PE",
      value: "Belem de Sao Francisco",
    },
    { name: "Belo Jardim", parent: "PE", value: "Belo Jardim" },
    { name: "Betania", parent: "PE", value: "Betania" },
    { name: "Bezerros", parent: "PE", value: "Bezerros" },
    { name: "Bodoco", parent: "PE", value: "Bodoco" },
    { name: "Bom Conselho", parent: "PE", value: "Bom Conselho" },
    { name: "Bom Jardim", parent: "PE", value: "Bom Jardim" },
    { name: "Bonito", parent: "PE", value: "Bonito" },
    { name: "Brejao", parent: "PE", value: "Brejao" },
    { name: "Brejinho", parent: "PE", value: "Brejinho" },
    {
      name: "Brejo da Madre de Deus",
      parent: "PE",
      value: "Brejo da Madre de Deus",
    },
    { name: "Buenos Aires", parent: "PE", value: "Buenos Aires" },
    { name: "Buique", parent: "PE", value: "Buique" },
    {
      name: "Cabo de Santo Agostinho",
      parent: "PE",
      value: "Cabo de Santo Agostinho",
    },
    { name: "Cabrobo", parent: "PE", value: "Cabrobo" },
    { name: "Cachoeirinha", parent: "PE", value: "Cachoeirinha" },
    { name: "Caetes", parent: "PE", value: "Caetes" },
    { name: "Calcado", parent: "PE", value: "Calcado" },
    { name: "Calumbi", parent: "PE", value: "Calumbi" },
    { name: "Camaragibe", parent: "PE", value: "Camaragibe" },
    {
      name: "Camocim de Sao Felix",
      parent: "PE",
      value: "Camocim de Sao Felix",
    },
    { name: "Camutanga", parent: "PE", value: "Camutanga" },
    { name: "Canhotinho", parent: "PE", value: "Canhotinho" },
    { name: "Capoeiras", parent: "PE", value: "Capoeiras" },
    { name: "Carnaiba", parent: "PE", value: "Carnaiba" },
    {
      name: "Carnaubeira da Penha",
      parent: "PE",
      value: "Carnaubeira da Penha",
    },
    { name: "Carpina", parent: "PE", value: "Carpina" },
    { name: "Caruaru", parent: "PE", value: "Caruaru" },
    { name: "Casinhas", parent: "PE", value: "Casinhas" },
    { name: "Catende", parent: "PE", value: "Catende" },
    { name: "Cedro", parent: "PE", value: "Cedro" },
    { name: "Cha Grande", parent: "PE", value: "Cha Grande" },
    { name: "Cha de Alegria", parent: "PE", value: "Cha de Alegria" },
    { name: "Condado", parent: "PE", value: "Condado" },
    { name: "Correntes", parent: "PE", value: "Correntes" },
    { name: "Cortes", parent: "PE", value: "Cortes" },
    { name: "Cumaru", parent: "PE", value: "Cumaru" },
    { name: "Cupira", parent: "PE", value: "Cupira" },
    { name: "Custodia", parent: "PE", value: "Custodia" },
    { name: "Dormentes", parent: "PE", value: "Dormentes" },
    { name: "Escada", parent: "PE", value: "Escada" },
    { name: "Exu", parent: "PE", value: "Exu" },
    { name: "Feira Nova", parent: "PE", value: "Feira Nova" },
    { name: "Ferreiros", parent: "PE", value: "Ferreiros" },
    { name: "Flores", parent: "PE", value: "Flores" },
    { name: "Floresta", parent: "PE", value: "Floresta" },
    { name: "Frei Miguelinho", parent: "PE", value: "Frei Miguelinho" },
    { name: "Gameleira", parent: "PE", value: "Gameleira" },
    { name: "Garanhuns", parent: "PE", value: "Garanhuns" },
    { name: "Gloria do Goita", parent: "PE", value: "Gloria do Goita" },
    { name: "Goiana", parent: "PE", value: "Goiana" },
    { name: "Granito", parent: "PE", value: "Granito" },
    { name: "Gravata", parent: "PE", value: "Gravata" },
    { name: "Iati", parent: "PE", value: "Iati" },
    { name: "Ibimirim", parent: "PE", value: "Ibimirim" },
    { name: "Ibirajuba", parent: "PE", value: "Ibirajuba" },
    { name: "Igarassu", parent: "PE", value: "Igarassu" },
    { name: "Iguaraci", parent: "PE", value: "Iguaraci" },
    { name: "Inaja", parent: "PE", value: "Inaja" },
    { name: "Ingazeira", parent: "PE", value: "Ingazeira" },
    { name: "Ipojuca", parent: "PE", value: "Ipojuca" },
    { name: "Ipubi", parent: "PE", value: "Ipubi" },
    { name: "Itacuruba", parent: "PE", value: "Itacuruba" },
    { name: "Itaiba", parent: "PE", value: "Itaiba" },
    { name: "Itamaraca", parent: "PE", value: "Itamaraca" },
    { name: "Itambe", parent: "PE", value: "Itambe" },
    { name: "Itapetim", parent: "PE", value: "Itapetim" },
    { name: "Itapissuma", parent: "PE", value: "Itapissuma" },
    { name: "Itaquitinga", parent: "PE", value: "Itaquitinga" },
    {
      name: "Jaboatao dos Guararapes",
      parent: "PE",
      value: "Jaboatao dos Guararapes",
    },
    { name: "Jaqueira", parent: "PE", value: "Jaqueira" },
    { name: "Jatauba", parent: "PE", value: "Jatauba" },
    { name: "Jatoba", parent: "PE", value: "Jatoba" },
    { name: "Joao Alfredo", parent: "PE", value: "Joao Alfredo" },
    { name: "Joaquim Nabuco", parent: "PE", value: "Joaquim Nabuco" },
    { name: "Jucati", parent: "PE", value: "Jucati" },
    { name: "Jupi", parent: "PE", value: "Jupi" },
    { name: "Jurema", parent: "PE", value: "Jurema" },
    { name: "Lagoa Grande", parent: "PE", value: "Lagoa Grande" },
    { name: "Lagoa do Carro", parent: "PE", value: "Lagoa do Carro" },
    { name: "Lagoa do Itaenga", parent: "PE", value: "Lagoa do Itaenga" },
    { name: "Lagoa do Ouro", parent: "PE", value: "Lagoa do Ouro" },
    { name: "Lagoa dos Gatos", parent: "PE", value: "Lagoa dos Gatos" },
    { name: "Lajedo", parent: "PE", value: "Lajedo" },
    { name: "Limoeiro", parent: "PE", value: "Limoeiro" },
    { name: "Macaparana", parent: "PE", value: "Macaparana" },
    { name: "Machados", parent: "PE", value: "Machados" },
    { name: "Manari", parent: "PE", value: "Manari" },
    { name: "Maraial", parent: "PE", value: "Maraial" },
    { name: "Mirandiba", parent: "PE", value: "Mirandiba" },
    { name: "Moreilandia", parent: "PE", value: "Moreilandia" },
    { name: "Moreno", parent: "PE", value: "Moreno" },
    { name: "Nazare da Mata", parent: "PE", value: "Nazare da Mata" },
    { name: "Olinda", parent: "PE", value: "Olinda" },
    { name: "Orobo", parent: "PE", value: "Orobo" },
    { name: "Oroco", parent: "PE", value: "Oroco" },
    { name: "Ouricuri", parent: "PE", value: "Ouricuri" },
    { name: "Palmares", parent: "PE", value: "Palmares" },
    { name: "Palmeirina", parent: "PE", value: "Palmeirina" },
    { name: "Panelas", parent: "PE", value: "Panelas" },
    { name: "Paranatama", parent: "PE", value: "Paranatama" },
    { name: "Parnamirim", parent: "PE", value: "Parnamirim" },
    { name: "Passira", parent: "PE", value: "Passira" },
    { name: "Paudalho", parent: "PE", value: "Paudalho" },
    { name: "Paulista", parent: "PE", value: "Paulista" },
    { name: "Pedra", parent: "PE", value: "Pedra" },
    { name: "Pesqueira", parent: "PE", value: "Pesqueira" },
    { name: "Petrolandia", parent: "PE", value: "Petrolandia" },
    { name: "Petrolina", parent: "PE", value: "Petrolina" },
    { name: "Pocao", parent: "PE", value: "Pocao" },
    { name: "Pombos", parent: "PE", value: "Pombos" },
    { name: "Primavera", parent: "PE", value: "Primavera" },
    { name: "Quipapa", parent: "PE", value: "Quipapa" },
    { name: "Quixaba", parent: "PE", value: "Quixaba" },
    { name: "Recife", parent: "PE", value: "Recife" },
    { name: "Riacho das Almas", parent: "PE", value: "Riacho das Almas" },
    { name: "Ribeirao", parent: "PE", value: "Ribeirao" },
    { name: "Rio Formoso", parent: "PE", value: "Rio Formoso" },
    { name: "Saire", parent: "PE", value: "Saire" },
    { name: "Salgadinho", parent: "PE", value: "Salgadinho" },
    { name: "Salgueiro", parent: "PE", value: "Salgueiro" },
    { name: "Saloa", parent: "PE", value: "Saloa" },
    { name: "Sanharo", parent: "PE", value: "Sanharo" },
    { name: "Santa Cruz", parent: "PE", value: "Santa Cruz" },
    {
      name: "Santa Cruz da Baixa Verde",
      parent: "PE",
      value: "Santa Cruz da Baixa Verde",
    },
    {
      name: "Santa Cruz do Capibaribe",
      parent: "PE",
      value: "Santa Cruz do Capibaribe",
    },
    { name: "Santa Filomena", parent: "PE", value: "Santa Filomena" },
    {
      name: "Santa Maria da Boa Vista",
      parent: "PE",
      value: "Santa Maria da Boa Vista",
    },
    {
      name: "Santa Maria do Cambuca",
      parent: "PE",
      value: "Santa Maria do Cambuca",
    },
    { name: "Santa Terezinha", parent: "PE", value: "Santa Terezinha" },
    { name: "Sao Benedito do Sul", parent: "PE", value: "Sao Benedito do Sul" },
    { name: "Sao Bento do Una", parent: "PE", value: "Sao Bento do Una" },
    { name: "Sao Caitano", parent: "PE", value: "Sao Caitano" },
    { name: "Sao Joao", parent: "PE", value: "Sao Joao" },
    {
      name: "Sao Joaquim do Monte",
      parent: "PE",
      value: "Sao Joaquim do Monte",
    },
    {
      name: "Sao Jose da Coroa Grande",
      parent: "PE",
      value: "Sao Jose da Coroa Grande",
    },
    {
      name: "Sao Jose do Belmonte",
      parent: "PE",
      value: "Sao Jose do Belmonte",
    },
    { name: "Sao Jose do Egito", parent: "PE", value: "Sao Jose do Egito" },
    {
      name: "Sao Lourenco da Mata",
      parent: "PE",
      value: "Sao Lourenco da Mata",
    },
    { name: "Sao Vicente Ferrer", parent: "PE", value: "Sao Vicente Ferrer" },
    { name: "Serra Talhada", parent: "PE", value: "Serra Talhada" },
    { name: "Serrita", parent: "PE", value: "Serrita" },
    { name: "Sertania", parent: "PE", value: "Sertania" },
    { name: "Sirinhaem", parent: "PE", value: "Sirinhaem" },
    { name: "Solidao", parent: "PE", value: "Solidao" },
    { name: "Surubim", parent: "PE", value: "Surubim" },
    { name: "Tabira", parent: "PE", value: "Tabira" },
    { name: "Tacaimbo", parent: "PE", value: "Tacaimbo" },
    { name: "Tacaratu", parent: "PE", value: "Tacaratu" },
    { name: "Tamandare", parent: "PE", value: "Tamandare" },
    {
      name: "Taquaritinga do Norte",
      parent: "PE",
      value: "Taquaritinga do Norte",
    },
    { name: "Terezinha", parent: "PE", value: "Terezinha" },
    { name: "Terra Nova", parent: "PE", value: "Terra Nova" },
    { name: "Timbauba", parent: "PE", value: "Timbauba" },
    { name: "Toritama", parent: "PE", value: "Toritama" },
    { name: "Tracunhaem", parent: "PE", value: "Tracunhaem" },
    { name: "Trindade", parent: "PE", value: "Trindade" },
    { name: "Triunfo", parent: "PE", value: "Triunfo" },
    { name: "Tupanatinga", parent: "PE", value: "Tupanatinga" },
    { name: "Tuparetama", parent: "PE", value: "Tuparetama" },
    { name: "Venturosa", parent: "PE", value: "Venturosa" },
    { name: "Verdejante", parent: "PE", value: "Verdejante" },
    { name: "Vertente do Lerio", parent: "PE", value: "Vertente do Lerio" },
    { name: "Vertentes", parent: "PE", value: "Vertentes" },
    { name: "Vicencia", parent: "PE", value: "Vicencia" },
    {
      name: "Vitoria de Santo Antao",
      parent: "PE",
      value: "Vitoria de Santo Antao",
    },
    { name: "Xexeu", parent: "PE", value: "Xexeu" },

    { name: "Acaua", parent: "PI", value: "Acaua" },
    { name: "Agricolandia", parent: "PI", value: "Agricolandia" },
    { name: "Agua Branca", parent: "PI", value: "Agua Branca" },
    { name: "Alagoinha do Piaui", parent: "PI", value: "Alagoinha do Piaui" },
    { name: "Alegrete do Piaui", parent: "PI", value: "Alegrete do Piaui" },
    { name: "Alto Longa", parent: "PI", value: "Alto Longa" },
    { name: "Altos", parent: "PI", value: "Altos" },
    {
      name: "Alvorada do Gurgueia",
      parent: "PI",
      value: "Alvorada do Gurgueia",
    },
    { name: "Amarante", parent: "PI", value: "Amarante" },
    { name: "Angical do Piaui", parent: "PI", value: "Angical do Piaui" },
    { name: "Anisio de Abreu", parent: "PI", value: "Anisio de Abreu" },
    { name: "Antonio Almeida", parent: "PI", value: "Antonio Almeida" },
    { name: "Aroazes", parent: "PI", value: "Aroazes" },
    { name: "Arraial", parent: "PI", value: "Arraial" },
    { name: "Assuncao do Piaui", parent: "PI", value: "Assuncao do Piaui" },
    { name: "Avelino Lopes", parent: "PI", value: "Avelino Lopes" },
    {
      name: "Baixa Grande do Ribeiro",
      parent: "PI",
      value: "Baixa Grande do Ribeiro",
    },
    { name: "Barra d'Alcantara", parent: "PI", value: "Barra d'Alcantara" },
    { name: "Barras", parent: "PI", value: "Barras" },
    { name: "Barreiras do Piaui", parent: "PI", value: "Barreiras do Piaui" },
    { name: "Barro Duro", parent: "PI", value: "Barro Duro" },
    { name: "Batalha", parent: "PI", value: "Batalha" },
    { name: "Bela Vista do Piaui", parent: "PI", value: "Bela Vista do Piaui" },
    { name: "Belem do Piaui", parent: "PI", value: "Belem do Piaui" },
    { name: "Beneditinos", parent: "PI", value: "Beneditinos" },
    { name: "Bertolinia", parent: "PI", value: "Bertolinia" },
    { name: "Betania do Piaui", parent: "PI", value: "Betania do Piaui" },
    { name: "Boa Hora", parent: "PI", value: "Boa Hora" },
    { name: "Bocaina", parent: "PI", value: "Bocaina" },
    { name: "Bom Jesus", parent: "PI", value: "Bom Jesus" },
    {
      name: "Bom Principio do Piaui",
      parent: "PI",
      value: "Bom Principio do Piaui",
    },
    { name: "Bonfim do Piaui", parent: "PI", value: "Bonfim do Piaui" },
    { name: "Boqueirao do Piaui", parent: "PI", value: "Boqueirao do Piaui" },
    { name: "Brasileira", parent: "PI", value: "Brasileira" },
    { name: "Brejo do Piaui", parent: "PI", value: "Brejo do Piaui" },
    { name: "Buriti dos Lopes", parent: "PI", value: "Buriti dos Lopes" },
    { name: "Buriti dos Montes", parent: "PI", value: "Buriti dos Montes" },
    { name: "Cabeceiras do Piaui", parent: "PI", value: "Cabeceiras do Piaui" },
    { name: "Cajazeiras do Piaui", parent: "PI", value: "Cajazeiras do Piaui" },
    { name: "Cajueiro da Praia", parent: "PI", value: "Cajueiro da Praia" },
    {
      name: "Caldeirao Grande do Piaui",
      parent: "PI",
      value: "Caldeirao Grande do Piaui",
    },
    { name: "Campinas do Piaui", parent: "PI", value: "Campinas do Piaui" },
    {
      name: "Campo Alegre do Fidalgo",
      parent: "PI",
      value: "Campo Alegre do Fidalgo",
    },
    {
      name: "Campo Grande do Piaui",
      parent: "PI",
      value: "Campo Grande do Piaui",
    },
    {
      name: "Campo Largo do Piaui",
      parent: "PI",
      value: "Campo Largo do Piaui",
    },
    { name: "Campo Maior", parent: "PI", value: "Campo Maior" },
    { name: "Canavieira", parent: "PI", value: "Canavieira" },
    { name: "Canto do Buriti", parent: "PI", value: "Canto do Buriti" },
    {
      name: "Capitao Gervasio Oliveira",
      parent: "PI",
      value: "Capitao Gervasio Oliveira",
    },
    { name: "Capitao de Campos", parent: "PI", value: "Capitao de Campos" },
    { name: "Caracol", parent: "PI", value: "Caracol" },
    { name: "Caraubas do Piaui", parent: "PI", value: "Caraubas do Piaui" },
    { name: "Caridade do Piaui", parent: "PI", value: "Caridade do Piaui" },
    { name: "Castelo do Piaui", parent: "PI", value: "Castelo do Piaui" },
    { name: "Caxingo", parent: "PI", value: "Caxingo" },
    { name: "Cocal", parent: "PI", value: "Cocal" },
    { name: "Cocal de Telha", parent: "PI", value: "Cocal de Telha" },
    { name: "Cocal dos Alves", parent: "PI", value: "Cocal dos Alves" },
    { name: "Coivaras", parent: "PI", value: "Coivaras" },
    { name: "Colonia do Gurgueia", parent: "PI", value: "Colonia do Gurgueia" },
    { name: "Colonia do Piaui", parent: "PI", value: "Colonia do Piaui" },
    {
      name: "Conceicao do Caninde",
      parent: "PI",
      value: "Conceicao do Caninde",
    },
    { name: "Coronel Jose Dias", parent: "PI", value: "Coronel Jose Dias" },
    { name: "Corrente", parent: "PI", value: "Corrente" },
    {
      name: "Cristalandia do Piaui",
      parent: "PI",
      value: "Cristalandia do Piaui",
    },
    { name: "Cristino Castro", parent: "PI", value: "Cristino Castro" },
    { name: "Curimata", parent: "PI", value: "Curimata" },
    { name: "Currais", parent: "PI", value: "Currais" },
    {
      name: "Curral Novo do Piaui",
      parent: "PI",
      value: "Curral Novo do Piaui",
    },
    { name: "Curralinhos", parent: "PI", value: "Curralinhos" },
    { name: "Demerval Lobao", parent: "PI", value: "Demerval Lobao" },
    { name: "Dirceu Arcoverde", parent: "PI", value: "Dirceu Arcoverde" },
    { name: "Dom Expedito Lopes", parent: "PI", value: "Dom Expedito Lopes" },
    { name: "Dom Inocencio", parent: "PI", value: "Dom Inocencio" },
    { name: "Domingos Mourao", parent: "PI", value: "Domingos Mourao" },
    { name: "Elesbao Veloso", parent: "PI", value: "Elesbao Veloso" },
    { name: "Eliseu Martins", parent: "PI", value: "Eliseu Martins" },
    { name: "Esperantina", parent: "PI", value: "Esperantina" },
    { name: "Fartura do Piaui", parent: "PI", value: "Fartura do Piaui" },
    { name: "Flores do Piaui", parent: "PI", value: "Flores do Piaui" },
    { name: "Floresta do Piaui", parent: "PI", value: "Floresta do Piaui" },
    { name: "Floriano", parent: "PI", value: "Floriano" },
    { name: "Francinopolis", parent: "PI", value: "Francinopolis" },
    { name: "Francisco Ayres", parent: "PI", value: "Francisco Ayres" },
    { name: "Francisco Macedo", parent: "PI", value: "Francisco Macedo" },
    { name: "Francisco Santos", parent: "PI", value: "Francisco Santos" },
    { name: "Fronteiras", parent: "PI", value: "Fronteiras" },
    { name: "Geminiano", parent: "PI", value: "Geminiano" },
    { name: "Gilbues", parent: "PI", value: "Gilbues" },
    { name: "Guadalupe", parent: "PI", value: "Guadalupe" },
    { name: "Guaribas", parent: "PI", value: "Guaribas" },
    { name: "Hugo Napoleao", parent: "PI", value: "Hugo Napoleao" },
    { name: "Ilha Grande", parent: "PI", value: "Ilha Grande" },
    { name: "Inhuma", parent: "PI", value: "Inhuma" },
    { name: "Ipiranga do Piaui", parent: "PI", value: "Ipiranga do Piaui" },
    { name: "Isaias Coelho", parent: "PI", value: "Isaias Coelho" },
    { name: "Itainopolis", parent: "PI", value: "Itainopolis" },
    { name: "Itaueira", parent: "PI", value: "Itaueira" },
    { name: "Jacobina do Piaui", parent: "PI", value: "Jacobina do Piaui" },
    { name: "Jaicos", parent: "PI", value: "Jaicos" },
    { name: "Jardim do Mulato", parent: "PI", value: "Jardim do Mulato" },
    { name: "Jatoba do Piaui", parent: "PI", value: "Jatoba do Piaui" },
    { name: "Jerumenha", parent: "PI", value: "Jerumenha" },
    { name: "Joao Costa", parent: "PI", value: "Joao Costa" },
    { name: "Joaquim Pires", parent: "PI", value: "Joaquim Pires" },
    { name: "Joca Marques", parent: "PI", value: "Joca Marques" },
    { name: "Jose de Freitas", parent: "PI", value: "Jose de Freitas" },
    { name: "Juazeiro do Piaui", parent: "PI", value: "Juazeiro do Piaui" },
    { name: "Julio Borges", parent: "PI", value: "Julio Borges" },
    { name: "Jurema", parent: "PI", value: "Jurema" },
    { name: "Lagoa Alegre", parent: "PI", value: "Lagoa Alegre" },
    {
      name: "Lagoa de Sao Francisco",
      parent: "PI",
      value: "Lagoa de Sao Francisco",
    },
    {
      name: "Lagoa do Barro do Piaui",
      parent: "PI",
      value: "Lagoa do Barro do Piaui",
    },
    { name: "Lagoa do Piaui", parent: "PI", value: "Lagoa do Piaui" },
    { name: "Lagoa do Sitio", parent: "PI", value: "Lagoa do Sitio" },
    { name: "Lagoinha do Piaui", parent: "PI", value: "Lagoinha do Piaui" },
    { name: "Landri Sales", parent: "PI", value: "Landri Sales" },
    { name: "Luis Correia", parent: "PI", value: "Luis Correia" },
    { name: "Luzilandia", parent: "PI", value: "Luzilandia" },
    { name: "Madeiro", parent: "PI", value: "Madeiro" },
    { name: "Manoel Emidio", parent: "PI", value: "Manoel Emidio" },
    { name: "Marcolandia", parent: "PI", value: "Marcolandia" },
    { name: "Marcos Parente", parent: "PI", value: "Marcos Parente" },
    { name: "Massape do Piaui", parent: "PI", value: "Massape do Piaui" },
    { name: "Matias Olimpio", parent: "PI", value: "Matias Olimpio" },
    { name: "Miguel Alves", parent: "PI", value: "Miguel Alves" },
    { name: "Miguel Leao", parent: "PI", value: "Miguel Leao" },
    { name: "Milton Brandao", parent: "PI", value: "Milton Brandao" },
    { name: "Monsenhor Gil", parent: "PI", value: "Monsenhor Gil" },
    { name: "Monsenhor Hipolito", parent: "PI", value: "Monsenhor Hipolito" },
    {
      name: "Monte Alegre do Piaui",
      parent: "PI",
      value: "Monte Alegre do Piaui",
    },
    {
      name: "Morro Cabeca no Tempo",
      parent: "PI",
      value: "Morro Cabeca no Tempo",
    },
    {
      name: "Morro do Chapeu do Piaui",
      parent: "PI",
      value: "Morro do Chapeu do Piaui",
    },
    { name: "Murici dos Portelas", parent: "PI", value: "Murici dos Portelas" },
    { name: "Nazare do Piaui", parent: "PI", value: "Nazare do Piaui" },
    {
      name: "Nossa Senhora de Nazare",
      parent: "PI",
      value: "Nossa Senhora de Nazare",
    },
    {
      name: "Nossa Senhora dos Remedios",
      parent: "PI",
      value: "Nossa Senhora dos Remedios",
    },
    { name: "Nova Santa Rita", parent: "PI", value: "Nova Santa Rita" },
    {
      name: "Novo Oriente do Piaui",
      parent: "PI",
      value: "Novo Oriente do Piaui",
    },
    { name: "Novo Santo Antonio", parent: "PI", value: "Novo Santo Antonio" },
    { name: "Oeiras", parent: "PI", value: "Oeiras" },
    {
      name: "Olho d'Agua do Piaui",
      parent: "PI",
      value: "Olho d'Agua do Piaui",
    },
    { name: "Padre Marcos", parent: "PI", value: "Padre Marcos" },
    { name: "Paes Landim", parent: "PI", value: "Paes Landim" },
    { name: "Pajeu do Piaui", parent: "PI", value: "Pajeu do Piaui" },
    { name: "Palmeira do Piaui", parent: "PI", value: "Palmeira do Piaui" },
    { name: "Palmeirais", parent: "PI", value: "Palmeirais" },
    { name: "Paqueta", parent: "PI", value: "Paqueta" },
    { name: "Parnagua", parent: "PI", value: "Parnagua" },
    { name: "Parnaiba", parent: "PI", value: "Parnaiba" },
    {
      name: "Passagem Franca do Piaui",
      parent: "PI",
      value: "Passagem Franca do Piaui",
    },
    { name: "Patos do Piaui", parent: "PI", value: "Patos do Piaui" },
    { name: "Paulistana", parent: "PI", value: "Paulistana" },
    { name: "Pavussu", parent: "PI", value: "Pavussu" },
    { name: "Pedro II", parent: "PI", value: "Pedro II" },
    { name: "Pedro Laurentino", parent: "PI", value: "Pedro Laurentino" },
    { name: "Picos", parent: "PI", value: "Picos" },
    { name: "Pimenteiras", parent: "PI", value: "Pimenteiras" },
    { name: "Pio IX", parent: "PI", value: "Pio IX" },
    { name: "Piracuruca", parent: "PI", value: "Piracuruca" },
    { name: "Piripiri", parent: "PI", value: "Piripiri" },
    { name: "Porto", parent: "PI", value: "Porto" },
    {
      name: "Porto Alegre do Piaui",
      parent: "PI",
      value: "Porto Alegre do Piaui",
    },
    { name: "Prata do Piaui", parent: "PI", value: "Prata do Piaui" },
    { name: "Queimada Nova", parent: "PI", value: "Queimada Nova" },
    {
      name: "Redencao do Gurgueia",
      parent: "PI",
      value: "Redencao do Gurgueia",
    },
    { name: "Regeneracao", parent: "PI", value: "Regeneracao" },
    { name: "Riacho Frio", parent: "PI", value: "Riacho Frio" },
    { name: "Ribeira do Piaui", parent: "PI", value: "Ribeira do Piaui" },
    { name: "Ribeiro Goncalves", parent: "PI", value: "Ribeiro Goncalves" },
    { name: "Rio Grande do Piaui", parent: "PI", value: "Rio Grande do Piaui" },
    { name: "Santa Cruz do Piaui", parent: "PI", value: "Santa Cruz do Piaui" },
    {
      name: "Santa Cruz dos Milagres",
      parent: "PI",
      value: "Santa Cruz dos Milagres",
    },
    { name: "Santa Filomena", parent: "PI", value: "Santa Filomena" },
    { name: "Santa Luz", parent: "PI", value: "Santa Luz" },
    { name: "Santa Rosa do Piaui", parent: "PI", value: "Santa Rosa do Piaui" },
    { name: "Santana do Piaui", parent: "PI", value: "Santana do Piaui" },
    {
      name: "Santo Antonio de Lisboa",
      parent: "PI",
      value: "Santo Antonio de Lisboa",
    },
    {
      name: "Santo Antonio dos Milagres",
      parent: "PI",
      value: "Santo Antonio dos Milagres",
    },
    {
      name: "Santo Inacio do Piaui",
      parent: "PI",
      value: "Santo Inacio do Piaui",
    },
    { name: "Sao Braz do Piaui", parent: "PI", value: "Sao Braz do Piaui" },
    { name: "Sao Felix do Piaui", parent: "PI", value: "Sao Felix do Piaui" },
    {
      name: "Sao Francisco de Assis do Piaui",
      parent: "PI",
      value: "Sao Francisco de Assis do Piaui",
    },
    {
      name: "Sao Francisco do Piaui",
      parent: "PI",
      value: "Sao Francisco do Piaui",
    },
    {
      name: "Sao Goncalo do Gurgueia",
      parent: "PI",
      value: "Sao Goncalo do Gurgueia",
    },
    {
      name: "Sao Goncalo do Piaui",
      parent: "PI",
      value: "Sao Goncalo do Piaui",
    },
    {
      name: "Sao Joao da Canabrava",
      parent: "PI",
      value: "Sao Joao da Canabrava",
    },
    {
      name: "Sao Joao da Fronteira",
      parent: "PI",
      value: "Sao Joao da Fronteira",
    },
    { name: "Sao Joao da Serra", parent: "PI", value: "Sao Joao da Serra" },
    { name: "Sao Joao da Varjota", parent: "PI", value: "Sao Joao da Varjota" },
    { name: "Sao Joao do Arraial", parent: "PI", value: "Sao Joao do Arraial" },
    { name: "Sao Joao do Piaui", parent: "PI", value: "Sao Joao do Piaui" },
    { name: "Sao Jose do Divino", parent: "PI", value: "Sao Jose do Divino" },
    { name: "Sao Jose do Peixe", parent: "PI", value: "Sao Jose do Peixe" },
    { name: "Sao Jose do Piaui", parent: "PI", value: "Sao Jose do Piaui" },
    { name: "Sao Juliao", parent: "PI", value: "Sao Juliao" },
    {
      name: "Sao Lourenco do Piaui",
      parent: "PI",
      value: "Sao Lourenco do Piaui",
    },
    { name: "Sao Luis do Piaui", parent: "PI", value: "Sao Luis do Piaui" },
    {
      name: "Sao Miguel da Baixa Grande",
      parent: "PI",
      value: "Sao Miguel da Baixa Grande",
    },
    {
      name: "Sao Miguel do Fidalgo",
      parent: "PI",
      value: "Sao Miguel do Fidalgo",
    },
    {
      name: "Sao Miguel do Tapuio",
      parent: "PI",
      value: "Sao Miguel do Tapuio",
    },
    { name: "Sao Pedro do Piaui", parent: "PI", value: "Sao Pedro do Piaui" },
    { name: "Sao Raimundo Nonato", parent: "PI", value: "Sao Raimundo Nonato" },
    { name: "Sebastiao Barros", parent: "PI", value: "Sebastiao Barros" },
    { name: "Sebastiao Leal", parent: "PI", value: "Sebastiao Leal" },
    { name: "Sigefredo Pacheco", parent: "PI", value: "Sigefredo Pacheco" },
    { name: "Simoes", parent: "PI", value: "Simoes" },
    { name: "Simplicio Mendes", parent: "PI", value: "Simplicio Mendes" },
    { name: "Socorro do Piaui", parent: "PI", value: "Socorro do Piaui" },
    { name: "Sussuapara", parent: "PI", value: "Sussuapara" },
    { name: "Tamboril do Piaui", parent: "PI", value: "Tamboril do Piaui" },
    { name: "Tanque do Piaui", parent: "PI", value: "Tanque do Piaui" },
    { name: "Teresina", parent: "PI", value: "Teresina" },
    { name: "Uniao", parent: "PI", value: "Uniao" },
    { name: "Urucui", parent: "PI", value: "Urucui" },
    { name: "Valenca do Piaui", parent: "PI", value: "Valenca do Piaui" },
    { name: "Varzea Branca", parent: "PI", value: "Varzea Branca" },
    { name: "Varzea Grande", parent: "PI", value: "Varzea Grande" },
    { name: "Vera Mendes", parent: "PI", value: "Vera Mendes" },
    { name: "Vila Nova do Piaui", parent: "PI", value: "Vila Nova do Piaui" },
    { name: "Wall Ferraz", parent: "PI", value: "Wall Ferraz" },

    { name: "Abatia", parent: "PR", value: "Abatia" },
    { name: "Adrianopolis", parent: "PR", value: "Adrianopolis" },
    { name: "Agudos do Sul", parent: "PR", value: "Agudos do Sul" },
    { name: "Almirante Tamandare", parent: "PR", value: "Almirante Tamandare" },
    { name: "Altamira do Parana", parent: "PR", value: "Altamira do Parana" },
    { name: "Altania", parent: "PR", value: "Altania" },
    { name: "Alto Parana", parent: "PR", value: "Alto Parana" },
    { name: "Alto Piquiri", parent: "PR", value: "Alto Piquiri" },
    { name: "Alvorada do Sul", parent: "PR", value: "Alvorada do Sul" },
    { name: "Amapora", parent: "PR", value: "Amapora" },
    { name: "Ampere", parent: "PR", value: "Ampere" },
    { name: "Anahy", parent: "PR", value: "Anahy" },
    { name: "Andira", parent: "PR", value: "Andira" },
    { name: "Angulo", parent: "PR", value: "Angulo" },
    { name: "Antonina", parent: "PR", value: "Antonina" },
    { name: "Antonio Olinto", parent: "PR", value: "Antonio Olinto" },
    { name: "Apucarana", parent: "PR", value: "Apucarana" },
    { name: "Arapongas", parent: "PR", value: "Arapongas" },
    { name: "Arapoti", parent: "PR", value: "Arapoti" },
    { name: "Arapua", parent: "PR", value: "Arapua" },
    { name: "Araruna", parent: "PR", value: "Araruna" },
    { name: "Araucaria", parent: "PR", value: "Araucaria" },
    { name: "Ariranha do Ivai", parent: "PR", value: "Ariranha do Ivai" },
    { name: "Assai", parent: "PR", value: "Assai" },
    { name: "Assis Chateaubriand", parent: "PR", value: "Assis Chateaubriand" },
    { name: "Astorga", parent: "PR", value: "Astorga" },
    { name: "Atalaia", parent: "PR", value: "Atalaia" },
    { name: "Balsa Nova", parent: "PR", value: "Balsa Nova" },
    { name: "Bandeirantes", parent: "PR", value: "Bandeirantes" },
    { name: "Barbosa Ferraz", parent: "PR", value: "Barbosa Ferraz" },
    { name: "Barra do Jacare", parent: "PR", value: "Barra do Jacare" },
    { name: "Barracao", parent: "PR", value: "Barracao" },
    {
      name: "Bela Vista da Caroba",
      parent: "PR",
      value: "Bela Vista da Caroba",
    },
    {
      name: "Bela Vista do Paraiso",
      parent: "PR",
      value: "Bela Vista do Paraiso",
    },
    { name: "Bituruna", parent: "PR", value: "Bituruna" },
    { name: "Boa Esperanca", parent: "PR", value: "Boa Esperanca" },
    {
      name: "Boa Esperanca do Iguacu",
      parent: "PR",
      value: "Boa Esperanca do Iguacu",
    },
    {
      name: "Boa Ventura de Sao Roque",
      parent: "PR",
      value: "Boa Ventura de Sao Roque",
    },
    {
      name: "Boa Vista da Aparecida",
      parent: "PR",
      value: "Boa Vista da Aparecida",
    },
    { name: "Bocaiuva do Sul", parent: "PR", value: "Bocaiuva do Sul" },
    { name: "Bom Jesus do Sul", parent: "PR", value: "Bom Jesus do Sul" },
    { name: "Bom Sucesso", parent: "PR", value: "Bom Sucesso" },
    { name: "Bom Sucesso do Sul", parent: "PR", value: "Bom Sucesso do Sul" },
    { name: "Borrazopolis", parent: "PR", value: "Borrazopolis" },
    { name: "Braganey", parent: "PR", value: "Braganey" },
    { name: "Brasilandia do Sul", parent: "PR", value: "Brasilandia do Sul" },
    { name: "Cafeara", parent: "PR", value: "Cafeara" },
    { name: "Cafelandia", parent: "PR", value: "Cafelandia" },
    { name: "Cafezal do Sul", parent: "PR", value: "Cafezal do Sul" },
    { name: "California", parent: "PR", value: "California" },
    { name: "Cambara", parent: "PR", value: "Cambara" },
    { name: "Cambe", parent: "PR", value: "Cambe" },
    { name: "Cambira", parent: "PR", value: "Cambira" },
    {
      name: "Campina Grande do Sul",
      parent: "PR",
      value: "Campina Grande do Sul",
    },
    { name: "Campina da Lagoa", parent: "PR", value: "Campina da Lagoa" },
    { name: "Campina do Simao", parent: "PR", value: "Campina do Simao" },
    { name: "Campo Bonito", parent: "PR", value: "Campo Bonito" },
    { name: "Campo Largo", parent: "PR", value: "Campo Largo" },
    { name: "Campo Magro", parent: "PR", value: "Campo Magro" },
    { name: "Campo Mourao", parent: "PR", value: "Campo Mourao" },
    { name: "Campo do Tenente", parent: "PR", value: "Campo do Tenente" },
    { name: "Candido de Abreu", parent: "PR", value: "Candido de Abreu" },
    { name: "Candoi", parent: "PR", value: "Candoi" },
    { name: "Cantagalo", parent: "PR", value: "Cantagalo" },
    { name: "Capanema", parent: "PR", value: "Capanema" },
    {
      name: "Capitao Leonidas Marques",
      parent: "PR",
      value: "Capitao Leonidas Marques",
    },
    { name: "Carambei", parent: "PR", value: "Carambei" },
    { name: "Carlopolis", parent: "PR", value: "Carlopolis" },
    { name: "Cascavel", parent: "PR", value: "Cascavel" },
    { name: "Castro", parent: "PR", value: "Castro" },
    { name: "Catanduvas", parent: "PR", value: "Catanduvas" },
    { name: "Centenario do Sul", parent: "PR", value: "Centenario do Sul" },
    { name: "Cerro Azul", parent: "PR", value: "Cerro Azul" },
    { name: "Ceu Azul", parent: "PR", value: "Ceu Azul" },
    { name: "Chopinzinho", parent: "PR", value: "Chopinzinho" },
    { name: "Cianorte", parent: "PR", value: "Cianorte" },
    { name: "Cidade Gaucha", parent: "PR", value: "Cidade Gaucha" },
    { name: "Clevelandia", parent: "PR", value: "Clevelandia" },
    { name: "Colombo", parent: "PR", value: "Colombo" },
    { name: "Colorado", parent: "PR", value: "Colorado" },
    { name: "Congonhinhas", parent: "PR", value: "Congonhinhas" },
    {
      name: "Conselheiro Mairinck",
      parent: "PR",
      value: "Conselheiro Mairinck",
    },
    { name: "Contenda", parent: "PR", value: "Contenda" },
    { name: "Corbelia", parent: "PR", value: "Corbelia" },
    { name: "Cornelio Procopio", parent: "PR", value: "Cornelio Procopio" },
    {
      name: "Coronel Domingos Soares",
      parent: "PR",
      value: "Coronel Domingos Soares",
    },
    { name: "Coronel Vivida", parent: "PR", value: "Coronel Vivida" },
    { name: "Corumbatai do Sul", parent: "PR", value: "Corumbatai do Sul" },
    { name: "Cruz Machado", parent: "PR", value: "Cruz Machado" },
    { name: "Cruzeiro do Iguacu", parent: "PR", value: "Cruzeiro do Iguacu" },
    { name: "Cruzeiro do Oeste", parent: "PR", value: "Cruzeiro do Oeste" },
    { name: "Cruzeiro do Sul", parent: "PR", value: "Cruzeiro do Sul" },
    { name: "Cruzmaltina", parent: "PR", value: "Cruzmaltina" },
    { name: "Curitiba", parent: "PR", value: "Curitiba" },
    { name: "Curiuva", parent: "PR", value: "Curiuva" },
    { name: "Diamante d'Oeste", parent: "PR", value: "Diamante d'Oeste" },
    { name: "Diamante do Norte", parent: "PR", value: "Diamante do Norte" },
    { name: "Diamante do Sul", parent: "PR", value: "Diamante do Sul" },
    { name: "Dois Vizinhos", parent: "PR", value: "Dois Vizinhos" },
    { name: "Douradina", parent: "PR", value: "Douradina" },
    { name: "Doutor Camargo", parent: "PR", value: "Doutor Camargo" },
    { name: "Doutor Ulysses", parent: "PR", value: "Doutor Ulysses" },
    { name: "Eneas Marques", parent: "PR", value: "Eneas Marques" },
    { name: "Engenheiro Beltrao", parent: "PR", value: "Engenheiro Beltrao" },
    { name: "Entre Rios do Oeste", parent: "PR", value: "Entre Rios do Oeste" },
    { name: "Esperanca Nova", parent: "PR", value: "Esperanca Nova" },
    {
      name: "Espigao Alto do Iguacu",
      parent: "PR",
      value: "Espigao Alto do Iguacu",
    },
    { name: "Farol", parent: "PR", value: "Farol" },
    { name: "Faxinal", parent: "PR", value: "Faxinal" },
    { name: "Fazenda Rio Grande", parent: "PR", value: "Fazenda Rio Grande" },
    { name: "Fenix", parent: "PR", value: "Fenix" },
    { name: "Fernandes Pinheiro", parent: "PR", value: "Fernandes Pinheiro" },
    { name: "Figueira", parent: "PR", value: "Figueira" },
    {
      name: "Flor da Serra do Sul",
      parent: "PR",
      value: "Flor da Serra do Sul",
    },
    { name: "Florai", parent: "PR", value: "Florai" },
    { name: "Floresta", parent: "PR", value: "Floresta" },
    { name: "Florestopolis", parent: "PR", value: "Florestopolis" },
    { name: "Florida", parent: "PR", value: "Florida" },
    { name: "Formosa do Oeste", parent: "PR", value: "Formosa do Oeste" },
    { name: "Foz do Iguacu", parent: "PR", value: "Foz do Iguacu" },
    { name: "Foz do Jordao", parent: "PR", value: "Foz do Jordao" },
    { name: "Francisco Alves", parent: "PR", value: "Francisco Alves" },
    { name: "Francisco Beltrao", parent: "PR", value: "Francisco Beltrao" },
    { name: "General Carneiro", parent: "PR", value: "General Carneiro" },
    { name: "Godoy Moreira", parent: "PR", value: "Godoy Moreira" },
    { name: "Goioere", parent: "PR", value: "Goioere" },
    { name: "Goioxim", parent: "PR", value: "Goioxim" },
    { name: "Grandes Rios", parent: "PR", value: "Grandes Rios" },
    { name: "Guaira", parent: "PR", value: "Guaira" },
    { name: "Guairaca", parent: "PR", value: "Guairaca" },
    { name: "Guamiranga", parent: "PR", value: "Guamiranga" },
    { name: "Guapirama", parent: "PR", value: "Guapirama" },
    { name: "Guaporema", parent: "PR", value: "Guaporema" },
    { name: "Guaraci", parent: "PR", value: "Guaraci" },
    { name: "Guaraniacu", parent: "PR", value: "Guaraniacu" },
    { name: "Guarapuava", parent: "PR", value: "Guarapuava" },
    { name: "Guaraquecaba", parent: "PR", value: "Guaraquecaba" },
    { name: "Guaratuba", parent: "PR", value: "Guaratuba" },
    { name: "Honorio Serpa", parent: "PR", value: "Honorio Serpa" },
    { name: "Ibaiti", parent: "PR", value: "Ibaiti" },
    { name: "Ibema", parent: "PR", value: "Ibema" },
    { name: "Ibipora", parent: "PR", value: "Ibipora" },
    { name: "Icaraima", parent: "PR", value: "Icaraima" },
    { name: "Iguaracu", parent: "PR", value: "Iguaracu" },
    { name: "Iguatu", parent: "PR", value: "Iguatu" },
    { name: "Imbau", parent: "PR", value: "Imbau" },
    { name: "Imbituva", parent: "PR", value: "Imbituva" },
    { name: "Inacio Martins", parent: "PR", value: "Inacio Martins" },
    { name: "Inaja", parent: "PR", value: "Inaja" },
    { name: "Indianopolis", parent: "PR", value: "Indianopolis" },
    { name: "Ipiranga", parent: "PR", value: "Ipiranga" },
    { name: "Ipora", parent: "PR", value: "Ipora" },
    { name: "Iracema do Oeste", parent: "PR", value: "Iracema do Oeste" },
    { name: "Irati", parent: "PR", value: "Irati" },
    { name: "Iretama", parent: "PR", value: "Iretama" },
    { name: "Itaguaje", parent: "PR", value: "Itaguaje" },
    { name: "Itaipulandia", parent: "PR", value: "Itaipulandia" },
    { name: "Itambaraca", parent: "PR", value: "Itambaraca" },
    { name: "Itambe", parent: "PR", value: "Itambe" },
    { name: "Itapejara d'Oeste", parent: "PR", value: "Itapejara d'Oeste" },
    { name: "Itaperucu", parent: "PR", value: "Itaperucu" },
    { name: "Itauna do Sul", parent: "PR", value: "Itauna do Sul" },
    { name: "Ivai", parent: "PR", value: "Ivai" },
    { name: "Ivaipora", parent: "PR", value: "Ivaipora" },
    { name: "Ivate", parent: "PR", value: "Ivate" },
    { name: "Ivatuba", parent: "PR", value: "Ivatuba" },
    { name: "Jaboti", parent: "PR", value: "Jaboti" },
    { name: "Jacarezinho", parent: "PR", value: "Jacarezinho" },
    { name: "Jaguapita", parent: "PR", value: "Jaguapita" },
    { name: "Jaguariaiva", parent: "PR", value: "Jaguariaiva" },
    { name: "Jandaia do Sul", parent: "PR", value: "Jandaia do Sul" },
    { name: "Janiopolis", parent: "PR", value: "Janiopolis" },
    { name: "Japira", parent: "PR", value: "Japira" },
    { name: "Japura", parent: "PR", value: "Japura" },
    { name: "Jardim Alegre", parent: "PR", value: "Jardim Alegre" },
    { name: "Jardim Olinda", parent: "PR", value: "Jardim Olinda" },
    { name: "Jataizinho", parent: "PR", value: "Jataizinho" },
    { name: "Jesuitas", parent: "PR", value: "Jesuitas" },
    { name: "Joaquim Tavora", parent: "PR", value: "Joaquim Tavora" },
    { name: "Jundiai do Sul", parent: "PR", value: "Jundiai do Sul" },
    { name: "Juranda", parent: "PR", value: "Juranda" },
    { name: "Jussara", parent: "PR", value: "Jussara" },
    { name: "Kalore", parent: "PR", value: "Kalore" },
    { name: "Lapa", parent: "PR", value: "Lapa" },
    { name: "Laranjal", parent: "PR", value: "Laranjal" },
    { name: "Laranjeiras do Sul", parent: "PR", value: "Laranjeiras do Sul" },
    { name: "Leopolis", parent: "PR", value: "Leopolis" },
    { name: "Lidianopolis", parent: "PR", value: "Lidianopolis" },
    { name: "Lindoeste", parent: "PR", value: "Lindoeste" },
    { name: "Loanda", parent: "PR", value: "Loanda" },
    { name: "Lobato", parent: "PR", value: "Lobato" },
    { name: "Londrina", parent: "PR", value: "Londrina" },
    { name: "Luiziana", parent: "PR", value: "Luiziana" },
    { name: "Lunardelli", parent: "PR", value: "Lunardelli" },
    { name: "Lupionopolis", parent: "PR", value: "Lupionopolis" },
    { name: "Mallet", parent: "PR", value: "Mallet" },
    { name: "Mambore", parent: "PR", value: "Mambore" },
    { name: "Mandaguacu", parent: "PR", value: "Mandaguacu" },
    { name: "Mandaguari", parent: "PR", value: "Mandaguari" },
    { name: "Mandirituba", parent: "PR", value: "Mandirituba" },
    { name: "Manfrinopolis", parent: "PR", value: "Manfrinopolis" },
    { name: "Mangueirinha", parent: "PR", value: "Mangueirinha" },
    { name: "Manoel Ribas", parent: "PR", value: "Manoel Ribas" },
    {
      name: "Marechal Candido Rondon",
      parent: "PR",
      value: "Marechal Candido Rondon",
    },
    { name: "Maria Helena", parent: "PR", value: "Maria Helena" },
    { name: "Marialva", parent: "PR", value: "Marialva" },
    { name: "Marilandia do Sul", parent: "PR", value: "Marilandia do Sul" },
    { name: "Marilena", parent: "PR", value: "Marilena" },
    { name: "Mariluz", parent: "PR", value: "Mariluz" },
    { name: "Maringa", parent: "PR", value: "Maringa" },
    { name: "Mariopolis", parent: "PR", value: "Mariopolis" },
    { name: "Maripa", parent: "PR", value: "Maripa" },
    { name: "Marmeleiro", parent: "PR", value: "Marmeleiro" },
    { name: "Marquinho", parent: "PR", value: "Marquinho" },
    { name: "Marumbi", parent: "PR", value: "Marumbi" },
    { name: "Matelandia", parent: "PR", value: "Matelandia" },
    { name: "Matinhos", parent: "PR", value: "Matinhos" },
    { name: "Mato Rico", parent: "PR", value: "Mato Rico" },
    { name: "Maua da Serra", parent: "PR", value: "Maua da Serra" },
    { name: "Medianeira", parent: "PR", value: "Medianeira" },
    { name: "Mercedes", parent: "PR", value: "Mercedes" },
    { name: "Mirador", parent: "PR", value: "Mirador" },
    { name: "Miraselva", parent: "PR", value: "Miraselva" },
    { name: "Missal", parent: "PR", value: "Missal" },
    { name: "Moreira Sales", parent: "PR", value: "Moreira Sales" },
    { name: "Morretes", parent: "PR", value: "Morretes" },
    { name: "Munhoz de Melo", parent: "PR", value: "Munhoz de Melo" },
    {
      name: "Nossa Senhora das Gracas",
      parent: "PR",
      value: "Nossa Senhora das Gracas",
    },
    {
      name: "Nova Alianca do Ivai",
      parent: "PR",
      value: "Nova Alianca do Ivai",
    },
    {
      name: "Nova America da Colina",
      parent: "PR",
      value: "Nova America da Colina",
    },
    { name: "Nova Aurora", parent: "PR", value: "Nova Aurora" },
    { name: "Nova Cantu", parent: "PR", value: "Nova Cantu" },
    { name: "Nova Esperanca", parent: "PR", value: "Nova Esperanca" },
    {
      name: "Nova Esperanca do Sudoeste",
      parent: "PR",
      value: "Nova Esperanca do Sudoeste",
    },
    { name: "Nova Fatima", parent: "PR", value: "Nova Fatima" },
    { name: "Nova Laranjeiras", parent: "PR", value: "Nova Laranjeiras" },
    { name: "Nova Londrina", parent: "PR", value: "Nova Londrina" },
    { name: "Nova Olimpia", parent: "PR", value: "Nova Olimpia" },
    {
      name: "Nova Prata do Iguacu",
      parent: "PR",
      value: "Nova Prata do Iguacu",
    },
    { name: "Nova Santa Barbara", parent: "PR", value: "Nova Santa Barbara" },
    { name: "Nova Santa Rosa", parent: "PR", value: "Nova Santa Rosa" },
    { name: "Nova Tebas", parent: "PR", value: "Nova Tebas" },
    { name: "Novo Itacolomi", parent: "PR", value: "Novo Itacolomi" },
    { name: "Ortigueira", parent: "PR", value: "Ortigueira" },
    { name: "Ourizona", parent: "PR", value: "Ourizona" },
    { name: "Ouro Verde do Oeste", parent: "PR", value: "Ouro Verde do Oeste" },
    { name: "Paicandu", parent: "PR", value: "Paicandu" },
    { name: "Palmas", parent: "PR", value: "Palmas" },
    { name: "Palmeira", parent: "PR", value: "Palmeira" },
    { name: "Palmital", parent: "PR", value: "Palmital" },
    { name: "Palotina", parent: "PR", value: "Palotina" },
    { name: "Paraiso do Norte", parent: "PR", value: "Paraiso do Norte" },
    { name: "Paranacity", parent: "PR", value: "Paranacity" },
    { name: "Paranagua", parent: "PR", value: "Paranagua" },
    { name: "Paranapoema", parent: "PR", value: "Paranapoema" },
    { name: "Paranavai", parent: "PR", value: "Paranavai" },
    { name: "Pato Bragado", parent: "PR", value: "Pato Bragado" },
    { name: "Pato Branco", parent: "PR", value: "Pato Branco" },
    { name: "Paula Freitas", parent: "PR", value: "Paula Freitas" },
    { name: "Paulo Frontin", parent: "PR", value: "Paulo Frontin" },
    { name: "Peabiru", parent: "PR", value: "Peabiru" },
    { name: "Perobal", parent: "PR", value: "Perobal" },
    { name: "Perola", parent: "PR", value: "Perola" },
    { name: "Perola d'Oeste", parent: "PR", value: "Perola d'Oeste" },
    { name: "Pien", parent: "PR", value: "Pien" },
    { name: "Pinhais", parent: "PR", value: "Pinhais" },
    { name: "Pinhal de Sao Bento", parent: "PR", value: "Pinhal de Sao Bento" },
    { name: "Pinhalao", parent: "PR", value: "Pinhalao" },
    { name: "Pinhao", parent: "PR", value: "Pinhao" },
    { name: "Pirai do Sul", parent: "PR", value: "Pirai do Sul" },
    { name: "Piraquara", parent: "PR", value: "Piraquara" },
    { name: "Pitanga", parent: "PR", value: "Pitanga" },
    { name: "Pitangueiras", parent: "PR", value: "Pitangueiras" },
    {
      name: "Planaltina do Parana",
      parent: "PR",
      value: "Planaltina do Parana",
    },
    { name: "Planalto", parent: "PR", value: "Planalto" },
    { name: "Ponta Grossa", parent: "PR", value: "Ponta Grossa" },
    { name: "Pontal do Parana", parent: "PR", value: "Pontal do Parana" },
    { name: "Porecatu", parent: "PR", value: "Porecatu" },
    { name: "Porto Amazonas", parent: "PR", value: "Porto Amazonas" },
    { name: "Porto Barreiro", parent: "PR", value: "Porto Barreiro" },
    { name: "Porto Rico", parent: "PR", value: "Porto Rico" },
    { name: "Porto Vitoria", parent: "PR", value: "Porto Vitoria" },
    { name: "Prado Ferreira", parent: "PR", value: "Prado Ferreira" },
    { name: "Pranchita", parent: "PR", value: "Pranchita" },
    {
      name: "Presidente Castelo Branco",
      parent: "PR",
      value: "Presidente Castelo Branco",
    },
    { name: "Primeiro de Maio", parent: "PR", value: "Primeiro de Maio" },
    { name: "Prudentopolis", parent: "PR", value: "Prudentopolis" },
    { name: "Quarto Centenario", parent: "PR", value: "Quarto Centenario" },
    { name: "Quatigua", parent: "PR", value: "Quatigua" },
    { name: "Quatro Barras", parent: "PR", value: "Quatro Barras" },
    { name: "Quatro Pontes", parent: "PR", value: "Quatro Pontes" },
    { name: "Quedas do Iguacu", parent: "PR", value: "Quedas do Iguacu" },
    { name: "Querencia do Norte", parent: "PR", value: "Querencia do Norte" },
    { name: "Quinta do Sol", parent: "PR", value: "Quinta do Sol" },
    { name: "Quitandinha", parent: "PR", value: "Quitandinha" },
    { name: "Ramilandia", parent: "PR", value: "Ramilandia" },
    { name: "Rancho Alegre", parent: "PR", value: "Rancho Alegre" },
    {
      name: "Rancho Alegre d'Oeste",
      parent: "PR",
      value: "Rancho Alegre d'Oeste",
    },
    { name: "Realeza", parent: "PR", value: "Realeza" },
    { name: "Reboucas", parent: "PR", value: "Reboucas" },
    { name: "Renascenca", parent: "PR", value: "Renascenca" },
    { name: "Reserva", parent: "PR", value: "Reserva" },
    { name: "Reserva do Iguacu", parent: "PR", value: "Reserva do Iguacu" },
    { name: "Ribeirao Claro", parent: "PR", value: "Ribeirao Claro" },
    { name: "Ribeirao do Pinhal", parent: "PR", value: "Ribeirao do Pinhal" },
    { name: "Rio Azul", parent: "PR", value: "Rio Azul" },
    { name: "Rio Bom", parent: "PR", value: "Rio Bom" },
    {
      name: "Rio Bonito do Iguacu",
      parent: "PR",
      value: "Rio Bonito do Iguacu",
    },
    { name: "Rio Branco do Ivai", parent: "PR", value: "Rio Branco do Ivai" },
    { name: "Rio Branco do Sul", parent: "PR", value: "Rio Branco do Sul" },
    { name: "Rio Negro", parent: "PR", value: "Rio Negro" },
    { name: "Rolandia", parent: "PR", value: "Rolandia" },
    { name: "Roncador", parent: "PR", value: "Roncador" },
    { name: "Rondon", parent: "PR", value: "Rondon" },
    { name: "Rosario do Ivai", parent: "PR", value: "Rosario do Ivai" },
    { name: "Sabaudia", parent: "PR", value: "Sabaudia" },
    { name: "Salgado Filho", parent: "PR", value: "Salgado Filho" },
    { name: "Salto do Itarare", parent: "PR", value: "Salto do Itarare" },
    { name: "Salto do Lontra", parent: "PR", value: "Salto do Lontra" },
    { name: "Santa Amelia", parent: "PR", value: "Santa Amelia" },
    {
      name: "Santa Cecilia do Pavao",
      parent: "PR",
      value: "Santa Cecilia do Pavao",
    },
    {
      name: "Santa Cruz de Monte Castelo",
      parent: "PR",
      value: "Santa Cruz de Monte Castelo",
    },
    { name: "Santa Fe", parent: "PR", value: "Santa Fe" },
    { name: "Santa Helena", parent: "PR", value: "Santa Helena" },
    { name: "Santa Ines", parent: "PR", value: "Santa Ines" },
    {
      name: "Santa Isabel do Ivai",
      parent: "PR",
      value: "Santa Isabel do Ivai",
    },
    {
      name: "Santa Izabel do Oeste",
      parent: "PR",
      value: "Santa Izabel do Oeste",
    },
    { name: "Santa Lucia", parent: "PR", value: "Santa Lucia" },
    {
      name: "Santa Maria do Oeste",
      parent: "PR",
      value: "Santa Maria do Oeste",
    },
    { name: "Santa Mariana", parent: "PR", value: "Santa Mariana" },
    { name: "Santa Monica", parent: "PR", value: "Santa Monica" },
    {
      name: "Santa Tereza do Oeste",
      parent: "PR",
      value: "Santa Tereza do Oeste",
    },
    {
      name: "Santa Terezinha de Itaipu",
      parent: "PR",
      value: "Santa Terezinha de Itaipu",
    },
    { name: "Santana do Itarare", parent: "PR", value: "Santana do Itarare" },
    {
      name: "Santo Antonio da Platina",
      parent: "PR",
      value: "Santo Antonio da Platina",
    },
    {
      name: "Santo Antonio do Caiua",
      parent: "PR",
      value: "Santo Antonio do Caiua",
    },
    {
      name: "Santo Antonio do Paraiso",
      parent: "PR",
      value: "Santo Antonio do Paraiso",
    },
    {
      name: "Santo Antonio do Sudoeste",
      parent: "PR",
      value: "Santo Antonio do Sudoeste",
    },
    { name: "Santo Inacio", parent: "PR", value: "Santo Inacio" },
    { name: "Sao Carlos do Ivai", parent: "PR", value: "Sao Carlos do Ivai" },
    {
      name: "Sao Jeronimo da Serra",
      parent: "PR",
      value: "Sao Jeronimo da Serra",
    },
    { name: "Sao Joao", parent: "PR", value: "Sao Joao" },
    { name: "Sao Joao do Caiua", parent: "PR", value: "Sao Joao do Caiua" },
    { name: "Sao Joao do Ivai", parent: "PR", value: "Sao Joao do Ivai" },
    { name: "Sao Joao do Triunfo", parent: "PR", value: "Sao Joao do Triunfo" },
    { name: "Sao Jorge d'Oeste", parent: "PR", value: "Sao Jorge d'Oeste" },
    { name: "Sao Jorge do Ivai", parent: "PR", value: "Sao Jorge do Ivai" },
    {
      name: "Sao Jorge do Patrocinio",
      parent: "PR",
      value: "Sao Jorge do Patrocinio",
    },
    {
      name: "Sao Jose da Boa Vista",
      parent: "PR",
      value: "Sao Jose da Boa Vista",
    },
    {
      name: "Sao Jose das Palmeiras",
      parent: "PR",
      value: "Sao Jose das Palmeiras",
    },
    {
      name: "Sao Jose dos Pinhais",
      parent: "PR",
      value: "Sao Jose dos Pinhais",
    },
    {
      name: "Sao Manoel do Parana",
      parent: "PR",
      value: "Sao Manoel do Parana",
    },
    { name: "Sao Mateus do Sul", parent: "PR", value: "Sao Mateus do Sul" },
    {
      name: "Sao Miguel do Iguacu",
      parent: "PR",
      value: "Sao Miguel do Iguacu",
    },
    { name: "Sao Pedro do Iguacu", parent: "PR", value: "Sao Pedro do Iguacu" },
    { name: "Sao Pedro do Ivai", parent: "PR", value: "Sao Pedro do Ivai" },
    { name: "Sao Pedro do Parana", parent: "PR", value: "Sao Pedro do Parana" },
    {
      name: "Sao Sebastiao da Amoreira",
      parent: "PR",
      value: "Sao Sebastiao da Amoreira",
    },
    { name: "Sao Tome", parent: "PR", value: "Sao Tome" },
    { name: "Sapopema", parent: "PR", value: "Sapopema" },
    { name: "Sarandi", parent: "PR", value: "Sarandi" },
    { name: "Saudade do Iguacu", parent: "PR", value: "Saudade do Iguacu" },
    { name: "Senges", parent: "PR", value: "Senges" },
    {
      name: "Serranopolis do Iguacu",
      parent: "PR",
      value: "Serranopolis do Iguacu",
    },
    { name: "Sertaneja", parent: "PR", value: "Sertaneja" },
    { name: "Sertanopolis", parent: "PR", value: "Sertanopolis" },
    { name: "Siqueira Campos", parent: "PR", value: "Siqueira Campos" },
    { name: "Sulina", parent: "PR", value: "Sulina" },
    { name: "Tamarana", parent: "PR", value: "Tamarana" },
    { name: "Tamboara", parent: "PR", value: "Tamboara" },
    { name: "Tapejara", parent: "PR", value: "Tapejara" },
    { name: "Tapira", parent: "PR", value: "Tapira" },
    { name: "Teixeira Soares", parent: "PR", value: "Teixeira Soares" },
    { name: "Telemaco Borba", parent: "PR", value: "Telemaco Borba" },
    { name: "Terra Boa", parent: "PR", value: "Terra Boa" },
    { name: "Terra Rica", parent: "PR", value: "Terra Rica" },
    { name: "Terra Roxa", parent: "PR", value: "Terra Roxa" },
    { name: "Tibagi", parent: "PR", value: "Tibagi" },
    { name: "Tijucas do Sul", parent: "PR", value: "Tijucas do Sul" },
    { name: "Toledo", parent: "PR", value: "Toledo" },
    { name: "Tomazina", parent: "PR", value: "Tomazina" },
    {
      name: "Tres Barras do Parana",
      parent: "PR",
      value: "Tres Barras do Parana",
    },
    { name: "Tunas do Parana", parent: "PR", value: "Tunas do Parana" },
    { name: "Tuneiras do Oeste", parent: "PR", value: "Tuneiras do Oeste" },
    { name: "Tupassi", parent: "PR", value: "Tupassi" },
    { name: "Turvo", parent: "PR", value: "Turvo" },
    { name: "Ubirata", parent: "PR", value: "Ubirata" },
    { name: "Umuarama", parent: "PR", value: "Umuarama" },
    { name: "Uniao da Vitoria", parent: "PR", value: "Uniao da Vitoria" },
    { name: "Uniflor", parent: "PR", value: "Uniflor" },
    { name: "Urai", parent: "PR", value: "Urai" },
    { name: "Ventania", parent: "PR", value: "Ventania" },
    { name: "Vera Cruz do Oeste", parent: "PR", value: "Vera Cruz do Oeste" },
    { name: "Vere", parent: "PR", value: "Vere" },
    { name: "Vila Alta", parent: "PR", value: "Vila Alta" },
    { name: "Virmond", parent: "PR", value: "Virmond" },
    { name: "Vitorino", parent: "PR", value: "Vitorino" },
    { name: "Wenceslau Braz", parent: "PR", value: "Wenceslau Braz" },
    { name: "Xambre", parent: "PR", value: "Xambre" },

    { name: "Angra dos Reis", parent: "RJ", value: "Angra dos Reis" },
    { name: "Aperibe", parent: "RJ", value: "Aperibe" },
    { name: "Araruama", parent: "RJ", value: "Araruama" },
    { name: "Areal", parent: "RJ", value: "Areal" },
    { name: "Armacao de Buzios", parent: "RJ", value: "Armacao de Buzios" },
    { name: "Arraial do Cabo", parent: "RJ", value: "Arraial do Cabo" },
    { name: "Barra Mansa", parent: "RJ", value: "Barra Mansa" },
    { name: "Barra do Pirai", parent: "RJ", value: "Barra do Pirai" },
    { name: "Belford Roxo", parent: "RJ", value: "Belford Roxo" },
    { name: "Bom Jardim", parent: "RJ", value: "Bom Jardim" },
    {
      name: "Bom Jesus do Itabapoana",
      parent: "RJ",
      value: "Bom Jesus do Itabapoana",
    },
    { name: "Cabo Frio", parent: "RJ", value: "Cabo Frio" },
    {
      name: "Cachoeiras de Macacu",
      parent: "RJ",
      value: "Cachoeiras de Macacu",
    },
    { name: "Cambuci", parent: "RJ", value: "Cambuci" },
    {
      name: "Campos dos Goytacazes",
      parent: "RJ",
      value: "Campos dos Goytacazes",
    },
    { name: "Cantagalo", parent: "RJ", value: "Cantagalo" },
    { name: "Carapebus", parent: "RJ", value: "Carapebus" },
    { name: "Cardoso Moreira", parent: "RJ", value: "Cardoso Moreira" },
    { name: "Carmo", parent: "RJ", value: "Carmo" },
    { name: "Casimiro de Abreu", parent: "RJ", value: "Casimiro de Abreu" },
    {
      name: "Comendador Levy Gasparian",
      parent: "RJ",
      value: "Comendador Levy Gasparian",
    },
    { name: "Conceicao de Macabu", parent: "RJ", value: "Conceicao de Macabu" },
    { name: "Cordeiro", parent: "RJ", value: "Cordeiro" },
    { name: "Duas Barras", parent: "RJ", value: "Duas Barras" },
    { name: "Duque de Caxias", parent: "RJ", value: "Duque de Caxias" },
    {
      name: "Engenheiro Paulo de Frontin",
      parent: "RJ",
      value: "Engenheiro Paulo de Frontin",
    },
    { name: "Guapimirim", parent: "RJ", value: "Guapimirim" },
    { name: "Iguaba Grande", parent: "RJ", value: "Iguaba Grande" },
    { name: "Itaborai", parent: "RJ", value: "Itaborai" },
    { name: "Itaguai", parent: "RJ", value: "Itaguai" },
    { name: "Italva", parent: "RJ", value: "Italva" },
    { name: "Itaocara", parent: "RJ", value: "Itaocara" },
    { name: "Itaperuna", parent: "RJ", value: "Itaperuna" },
    { name: "Itatiaia", parent: "RJ", value: "Itatiaia" },
    { name: "Japeri", parent: "RJ", value: "Japeri" },
    { name: "Laje do Muriae", parent: "RJ", value: "Laje do Muriae" },
    { name: "Macae", parent: "RJ", value: "Macae" },
    { name: "Macuco", parent: "RJ", value: "Macuco" },
    { name: "Mage", parent: "RJ", value: "Mage" },
    { name: "Mangaratiba", parent: "RJ", value: "Mangaratiba" },
    { name: "Marica", parent: "RJ", value: "Marica" },
    { name: "Mendes", parent: "RJ", value: "Mendes" },
    { name: "Miguel Pereira", parent: "RJ", value: "Miguel Pereira" },
    { name: "Miracema", parent: "RJ", value: "Miracema" },
    { name: "Natividade", parent: "RJ", value: "Natividade" },
    { name: "Nilopolis", parent: "RJ", value: "Nilopolis" },
    { name: "Niteroi", parent: "RJ", value: "Niteroi" },
    { name: "Nova Friburgo", parent: "RJ", value: "Nova Friburgo" },
    { name: "Nova Iguacu", parent: "RJ", value: "Nova Iguacu" },
    { name: "Paracambi", parent: "RJ", value: "Paracambi" },
    { name: "Paraiba do Sul", parent: "RJ", value: "Paraiba do Sul" },
    { name: "Parati", parent: "RJ", value: "Parati" },
    { name: "Paty do Alferes", parent: "RJ", value: "Paty do Alferes" },
    { name: "Petropolis", parent: "RJ", value: "Petropolis" },
    { name: "Pinheiral", parent: "RJ", value: "Pinheiral" },
    { name: "Pirai", parent: "RJ", value: "Pirai" },
    { name: "Porciuncula", parent: "RJ", value: "Porciuncula" },
    { name: "Porto Real", parent: "RJ", value: "Porto Real" },
    { name: "Quatis", parent: "RJ", value: "Quatis" },
    { name: "Queimados", parent: "RJ", value: "Queimados" },
    { name: "Quissama", parent: "RJ", value: "Quissama" },
    { name: "Resende", parent: "RJ", value: "Resende" },
    { name: "Rio Bonito", parent: "RJ", value: "Rio Bonito" },
    { name: "Rio Claro", parent: "RJ", value: "Rio Claro" },
    { name: "Rio das Flores", parent: "RJ", value: "Rio das Flores" },
    { name: "Rio das Ostras", parent: "RJ", value: "Rio das Ostras" },
    { name: "Rio de Janeiro", parent: "RJ", value: "Rio de Janeiro" },
    {
      name: "Santa Maria Madalena",
      parent: "RJ",
      value: "Santa Maria Madalena",
    },
    {
      name: "Santo Antonio de Padua",
      parent: "RJ",
      value: "Santo Antonio de Padua",
    },
    { name: "Sao Fidelis", parent: "RJ", value: "Sao Fidelis" },
    {
      name: "Sao Francisco de Itabapoana",
      parent: "RJ",
      value: "Sao Francisco de Itabapoana",
    },
    { name: "Sao Goncalo", parent: "RJ", value: "Sao Goncalo" },
    { name: "Sao Joao da Barra", parent: "RJ", value: "Sao Joao da Barra" },
    { name: "Sao Joao de Meriti", parent: "RJ", value: "Sao Joao de Meriti" },
    { name: "Sao Jose de Uba", parent: "RJ", value: "Sao Jose de Uba" },
    {
      name: "Sao Jose do Vale do Rio Preto",
      parent: "RJ",
      value: "Sao Jose do Vale do Rio Preto",
    },
    { name: "Sao Pedro da Aldeia", parent: "RJ", value: "Sao Pedro da Aldeia" },
    {
      name: "Sao Sebastiao do Alto",
      parent: "RJ",
      value: "Sao Sebastiao do Alto",
    },
    { name: "Sapucaia", parent: "RJ", value: "Sapucaia" },
    { name: "Saquarema", parent: "RJ", value: "Saquarema" },
    { name: "Seropedica", parent: "RJ", value: "Seropedica" },
    { name: "Silva Jardim", parent: "RJ", value: "Silva Jardim" },
    { name: "Sumidouro", parent: "RJ", value: "Sumidouro" },
    { name: "Tangua", parent: "RJ", value: "Tangua" },
    { name: "Teresopolis", parent: "RJ", value: "Teresopolis" },
    { name: "Trajano de Morais", parent: "RJ", value: "Trajano de Morais" },
    { name: "Tres Rios", parent: "RJ", value: "Tres Rios" },
    { name: "Valenca", parent: "RJ", value: "Valenca" },
    { name: "Varre-Sai", parent: "RJ", value: "Varre-Sai" },
    { name: "Vassouras", parent: "RJ", value: "Vassouras" },
    { name: "Volta Redonda", parent: "RJ", value: "Volta Redonda" },

    { name: "Acari", parent: "RN", value: "Acari" },
    { name: "Acu", parent: "RN", value: "Acu" },
    { name: "Afonso Bezerra", parent: "RN", value: "Afonso Bezerra" },
    { name: "Agua Nova", parent: "RN", value: "Agua Nova" },
    { name: "Alexandria", parent: "RN", value: "Alexandria" },
    { name: "Almino Afonso", parent: "RN", value: "Almino Afonso" },
    { name: "Alto do Rodrigues", parent: "RN", value: "Alto do Rodrigues" },
    { name: "Angicos", parent: "RN", value: "Angicos" },
    { name: "Antonio Martins", parent: "RN", value: "Antonio Martins" },
    { name: "Apodi", parent: "RN", value: "Apodi" },
    { name: "Areia Branca", parent: "RN", value: "Areia Branca" },
    { name: "Ares", parent: "RN", value: "Ares" },
    { name: "Augusto Severo", parent: "RN", value: "Augusto Severo" },
    { name: "Baia Formosa", parent: "RN", value: "Baia Formosa" },
    { name: "Barauna", parent: "RN", value: "Barauna" },
    { name: "Barcelona", parent: "RN", value: "Barcelona" },
    { name: "Bento Fernandes", parent: "RN", value: "Bento Fernandes" },
    { name: "Bodo", parent: "RN", value: "Bodo" },
    { name: "Bom Jesus", parent: "RN", value: "Bom Jesus" },
    { name: "Brejinho", parent: "RN", value: "Brejinho" },
    { name: "Caicara do Norte", parent: "RN", value: "Caicara do Norte" },
    {
      name: "Caicara do Rio do Vento",
      parent: "RN",
      value: "Caicara do Rio do Vento",
    },
    { name: "Caico", parent: "RN", value: "Caico" },
    { name: "Campo Redondo", parent: "RN", value: "Campo Redondo" },
    { name: "Canguaretama", parent: "RN", value: "Canguaretama" },
    { name: "Caraubas", parent: "RN", value: "Caraubas" },
    { name: "Carnauba dos Dantas", parent: "RN", value: "Carnauba dos Dantas" },
    { name: "Carnaubais", parent: "RN", value: "Carnaubais" },
    { name: "Ceara-Mirim", parent: "RN", value: "Ceara-Mirim" },
    { name: "Cerro Cora", parent: "RN", value: "Cerro Cora" },
    { name: "Coronel Ezequiel", parent: "RN", value: "Coronel Ezequiel" },
    { name: "Coronel Joao Pessoa", parent: "RN", value: "Coronel Joao Pessoa" },
    { name: "Cruzeta", parent: "RN", value: "Cruzeta" },
    { name: "Currais Novos", parent: "RN", value: "Currais Novos" },
    { name: "Doutor Severiano", parent: "RN", value: "Doutor Severiano" },
    { name: "Encanto", parent: "RN", value: "Encanto" },
    { name: "Equador", parent: "RN", value: "Equador" },
    { name: "Espirito Santo", parent: "RN", value: "Espirito Santo" },
    { name: "Extremoz", parent: "RN", value: "Extremoz" },
    { name: "Felipe Guerra", parent: "RN", value: "Felipe Guerra" },
    { name: "Fernando Pedroza", parent: "RN", value: "Fernando Pedroza" },
    { name: "Florania", parent: "RN", value: "Florania" },
    { name: "Francisco Dantas", parent: "RN", value: "Francisco Dantas" },
    { name: "Frutuoso Gomes", parent: "RN", value: "Frutuoso Gomes" },
    { name: "Galinhos", parent: "RN", value: "Galinhos" },
    { name: "Goianinha", parent: "RN", value: "Goianinha" },
    {
      name: "Governador Dix-Sept Rosado",
      parent: "RN",
      value: "Governador Dix-Sept Rosado",
    },
    { name: "Grossos", parent: "RN", value: "Grossos" },
    { name: "Guamare", parent: "RN", value: "Guamare" },
    { name: "Ielmo Marinho", parent: "RN", value: "Ielmo Marinho" },
    { name: "Ipanguacu", parent: "RN", value: "Ipanguacu" },
    { name: "Ipueira", parent: "RN", value: "Ipueira" },
    { name: "Itaja", parent: "RN", value: "Itaja" },
    { name: "Itau", parent: "RN", value: "Itau" },
    { name: "Jacana", parent: "RN", value: "Jacana" },
    { name: "Jandaira", parent: "RN", value: "Jandaira" },
    { name: "Janduis", parent: "RN", value: "Janduis" },
    { name: "Januario Cicco", parent: "RN", value: "Januario Cicco" },
    { name: "Japi", parent: "RN", value: "Japi" },
    { name: "Jardim de Angicos", parent: "RN", value: "Jardim de Angicos" },
    { name: "Jardim de Piranhas", parent: "RN", value: "Jardim de Piranhas" },
    { name: "Jardim do Serido", parent: "RN", value: "Jardim do Serido" },
    { name: "Joao Camara", parent: "RN", value: "Joao Camara" },
    { name: "Joao Dias", parent: "RN", value: "Joao Dias" },
    { name: "Jose da Penha", parent: "RN", value: "Jose da Penha" },
    { name: "Jucurutu", parent: "RN", value: "Jucurutu" },
    { name: "Lagoa Nova", parent: "RN", value: "Lagoa Nova" },
    { name: "Lagoa Salgada", parent: "RN", value: "Lagoa Salgada" },
    { name: "Lagoa d'Anta", parent: "RN", value: "Lagoa d'Anta" },
    { name: "Lagoa de Pedras", parent: "RN", value: "Lagoa de Pedras" },
    { name: "Lagoa de Velhos", parent: "RN", value: "Lagoa de Velhos" },
    { name: "Lajes", parent: "RN", value: "Lajes" },
    { name: "Lajes Pintadas", parent: "RN", value: "Lajes Pintadas" },
    { name: "Lucrecia", parent: "RN", value: "Lucrecia" },
    { name: "Luis Gomes", parent: "RN", value: "Luis Gomes" },
    { name: "Macaiba", parent: "RN", value: "Macaiba" },
    { name: "Macau", parent: "RN", value: "Macau" },
    { name: "Major Sales", parent: "RN", value: "Major Sales" },
    { name: "Marcelino Vieira", parent: "RN", value: "Marcelino Vieira" },
    { name: "Martins", parent: "RN", value: "Martins" },
    { name: "Maxaranguape", parent: "RN", value: "Maxaranguape" },
    { name: "Messias Targino", parent: "RN", value: "Messias Targino" },
    { name: "Montanhas", parent: "RN", value: "Montanhas" },
    { name: "Monte Alegre", parent: "RN", value: "Monte Alegre" },
    {
      name: "Monte das Gameleiras",
      parent: "RN",
      value: "Monte das Gameleiras",
    },
    { name: "Mossoro", parent: "RN", value: "Mossoro" },
    { name: "Natal", parent: "RN", value: "Natal" },
    { name: "Nisia Floresta", parent: "RN", value: "Nisia Floresta" },
    { name: "Nova Cruz", parent: "RN", value: "Nova Cruz" },
    {
      name: "Olho-d'Agua do Borges",
      parent: "RN",
      value: "Olho-d'Agua do Borges",
    },
    { name: "Ouro Branco", parent: "RN", value: "Ouro Branco" },
    { name: "Parana", parent: "RN", value: "Parana" },
    { name: "Parau", parent: "RN", value: "Parau" },
    { name: "Parazinho", parent: "RN", value: "Parazinho" },
    { name: "Parelhas", parent: "RN", value: "Parelhas" },
    { name: "Parnamirim", parent: "RN", value: "Parnamirim" },
    { name: "Passa e Fica", parent: "RN", value: "Passa e Fica" },
    { name: "Passagem", parent: "RN", value: "Passagem" },
    { name: "Patu", parent: "RN", value: "Patu" },
    { name: "Pau dos Ferros", parent: "RN", value: "Pau dos Ferros" },
    { name: "Pedra Grande", parent: "RN", value: "Pedra Grande" },
    { name: "Pedra Preta", parent: "RN", value: "Pedra Preta" },
    { name: "Pedro Avelino", parent: "RN", value: "Pedro Avelino" },
    { name: "Pedro Velho", parent: "RN", value: "Pedro Velho" },
    { name: "Pendencias", parent: "RN", value: "Pendencias" },
    { name: "Piloes", parent: "RN", value: "Piloes" },
    { name: "Poco Branco", parent: "RN", value: "Poco Branco" },
    { name: "Portalegre", parent: "RN", value: "Portalegre" },
    { name: "Porto do Mangue", parent: "RN", value: "Porto do Mangue" },
    {
      name: "Presidente Juscelino",
      parent: "RN",
      value: "Presidente Juscelino",
    },
    { name: "Pureza", parent: "RN", value: "Pureza" },
    { name: "Rafael Fernandes", parent: "RN", value: "Rafael Fernandes" },
    { name: "Rafael Godeiro", parent: "RN", value: "Rafael Godeiro" },
    { name: "Riacho da Cruz", parent: "RN", value: "Riacho da Cruz" },
    { name: "Riacho de Santana", parent: "RN", value: "Riacho de Santana" },
    { name: "Riachuelo", parent: "RN", value: "Riachuelo" },
    { name: "Rio do Fogo", parent: "RN", value: "Rio do Fogo" },
    { name: "Rodolfo Fernandes", parent: "RN", value: "Rodolfo Fernandes" },
    { name: "Ruy Barbosa", parent: "RN", value: "Ruy Barbosa" },
    { name: "Santa Cruz", parent: "RN", value: "Santa Cruz" },
    { name: "Santa Maria", parent: "RN", value: "Santa Maria" },
    { name: "Santana do Matos", parent: "RN", value: "Santana do Matos" },
    { name: "Santana do Serido", parent: "RN", value: "Santana do Serido" },
    { name: "Santo Antonio", parent: "RN", value: "Santo Antonio" },
    { name: "Sao Bento do Norte", parent: "RN", value: "Sao Bento do Norte" },
    { name: "Sao Bento do Trairi", parent: "RN", value: "Sao Bento do Trairi" },
    { name: "Sao Fernando", parent: "RN", value: "Sao Fernando" },
    {
      name: "Sao Francisco do Oeste",
      parent: "RN",
      value: "Sao Francisco do Oeste",
    },
    {
      name: "Sao Goncalo do Amarante",
      parent: "RN",
      value: "Sao Goncalo do Amarante",
    },
    { name: "Sao Joao do Sabugi", parent: "RN", value: "Sao Joao do Sabugi" },
    { name: "Sao Jose de Mipibu", parent: "RN", value: "Sao Jose de Mipibu" },
    {
      name: "Sao Jose do Campestre",
      parent: "RN",
      value: "Sao Jose do Campestre",
    },
    { name: "Sao Jose do Serido", parent: "RN", value: "Sao Jose do Serido" },
    { name: "Sao Miguel", parent: "RN", value: "Sao Miguel" },
    {
      name: "Sao Miguel de Touros",
      parent: "RN",
      value: "Sao Miguel de Touros",
    },
    {
      name: "Sao Paulo do Potengi",
      parent: "RN",
      value: "Sao Paulo do Potengi",
    },
    { name: "Sao Pedro", parent: "RN", value: "Sao Pedro" },
    { name: "Sao Rafael", parent: "RN", value: "Sao Rafael" },
    { name: "Sao Tome", parent: "RN", value: "Sao Tome" },
    { name: "Sao Vicente", parent: "RN", value: "Sao Vicente" },
    {
      name: "Senador Eloi de Souza",
      parent: "RN",
      value: "Senador Eloi de Souza",
    },
    {
      name: "Senador Georgino Avelino",
      parent: "RN",
      value: "Senador Georgino Avelino",
    },
    {
      name: "Serra Negra do Norte",
      parent: "RN",
      value: "Serra Negra do Norte",
    },
    { name: "Serra de Sao Bento", parent: "RN", value: "Serra de Sao Bento" },
    { name: "Serra do Mel", parent: "RN", value: "Serra do Mel" },
    { name: "Serrinha", parent: "RN", value: "Serrinha" },
    { name: "Serrinha dos Pintos", parent: "RN", value: "Serrinha dos Pintos" },
    { name: "Severiano Melo", parent: "RN", value: "Severiano Melo" },
    { name: "Sitio Novo", parent: "RN", value: "Sitio Novo" },
    { name: "Taboleiro Grande", parent: "RN", value: "Taboleiro Grande" },
    { name: "Taipu", parent: "RN", value: "Taipu" },
    { name: "Tangara", parent: "RN", value: "Tangara" },
    { name: "Tenente Ananias", parent: "RN", value: "Tenente Ananias" },
    {
      name: "Tenente Laurentino Cruz",
      parent: "RN",
      value: "Tenente Laurentino Cruz",
    },
    { name: "Tibau", parent: "RN", value: "Tibau" },
    { name: "Tibau do Sul", parent: "RN", value: "Tibau do Sul" },
    {
      name: "Timbauba dos Batistas",
      parent: "RN",
      value: "Timbauba dos Batistas",
    },
    { name: "Touros", parent: "RN", value: "Touros" },
    { name: "Triunfo Potiguar", parent: "RN", value: "Triunfo Potiguar" },
    { name: "Umarizal", parent: "RN", value: "Umarizal" },
    { name: "Upanema", parent: "RN", value: "Upanema" },
    { name: "Varzea", parent: "RN", value: "Varzea" },
    { name: "Venha-Ver", parent: "RN", value: "Venha-Ver" },
    { name: "Vera Cruz", parent: "RN", value: "Vera Cruz" },
    { name: "Vicosa", parent: "RN", value: "Vicosa" },
    { name: "Vila Flor", parent: "RN", value: "Vila Flor" },

    {
      name: "Alta Floresta d'Oeste",
      parent: "RO",
      value: "Alta Floresta d'Oeste",
    },
    {
      name: "Alto Alegre do Parecis",
      parent: "RO",
      value: "Alto Alegre do Parecis",
    },
    { name: "Alto Paraiso", parent: "RO", value: "Alto Paraiso" },
    { name: "Alvorada d'Oeste", parent: "RO", value: "Alvorada d'Oeste" },
    { name: "Ariquemes", parent: "RO", value: "Ariquemes" },
    { name: "Buritis", parent: "RO", value: "Buritis" },
    { name: "Cabixi", parent: "RO", value: "Cabixi" },
    { name: "Cacaulandia", parent: "RO", value: "Cacaulandia" },
    { name: "Cacoal", parent: "RO", value: "Cacoal" },
    {
      name: "Campo Novo de Rondonia",
      parent: "RO",
      value: "Campo Novo de Rondonia",
    },
    { name: "Candeias do Jamari", parent: "RO", value: "Candeias do Jamari" },
    { name: "Castanheiras", parent: "RO", value: "Castanheiras" },
    { name: "Cerejeiras", parent: "RO", value: "Cerejeiras" },
    { name: "Chupinguaia", parent: "RO", value: "Chupinguaia" },
    { name: "Colorado do Oeste", parent: "RO", value: "Colorado do Oeste" },
    { name: "Corumbiara", parent: "RO", value: "Corumbiara" },
    { name: "Costa Marques", parent: "RO", value: "Costa Marques" },
    { name: "Cujubim", parent: "RO", value: "Cujubim" },
    { name: "Espigao d'Oeste", parent: "RO", value: "Espigao d'Oeste" },
    {
      name: "Governador Jorge Teixeira",
      parent: "RO",
      value: "Governador Jorge Teixeira",
    },
    { name: "Guajara-Mirim", parent: "RO", value: "Guajara-Mirim" },
    { name: "Jamari", parent: "RO", value: "Jamari" },
    { name: "Jaru", parent: "RO", value: "Jaru" },
    { name: "Ji-Parana", parent: "RO", value: "Ji-Parana" },
    { name: "Machadinho d'Oeste", parent: "RO", value: "Machadinho d'Oeste" },
    { name: "Ministro Andreazza", parent: "RO", value: "Ministro Andreazza" },
    { name: "Mirante da Serra", parent: "RO", value: "Mirante da Serra" },
    { name: "Monte Negro", parent: "RO", value: "Monte Negro" },
    {
      name: "Nova Brasilandia d'Oeste",
      parent: "RO",
      value: "Nova Brasilandia d'Oeste",
    },
    { name: "Nova Mamore", parent: "RO", value: "Nova Mamore" },
    { name: "Nova Uniao", parent: "RO", value: "Nova Uniao" },
    {
      name: "Novo Horizonte do Oeste",
      parent: "RO",
      value: "Novo Horizonte do Oeste",
    },
    { name: "Ouro Preto do Oeste", parent: "RO", value: "Ouro Preto do Oeste" },
    { name: "Parecis", parent: "RO", value: "Parecis" },
    { name: "Pimenta Bueno", parent: "RO", value: "Pimenta Bueno" },
    {
      name: "Pimenteiras do Oeste",
      parent: "RO",
      value: "Pimenteiras do Oeste",
    },
    { name: "Porto Velho", parent: "RO", value: "Porto Velho" },
    { name: "Presidente Medici", parent: "RO", value: "Presidente Medici" },
    {
      name: "Primavera de Rondonia",
      parent: "RO",
      value: "Primavera de Rondonia",
    },
    { name: "Rio Crespo", parent: "RO", value: "Rio Crespo" },
    { name: "Rolim de Moura", parent: "RO", value: "Rolim de Moura" },
    { name: "Santa Luzia d'Oeste", parent: "RO", value: "Santa Luzia d'Oeste" },
    { name: "Sao Felipe d'Oeste", parent: "RO", value: "Sao Felipe d'Oeste" },
    {
      name: "Sao Francisco do Guapore",
      parent: "RO",
      value: "Sao Francisco do Guapore",
    },
    {
      name: "Sao Miguel do Guapore",
      parent: "RO",
      value: "Sao Miguel do Guapore",
    },
    { name: "Seringueiras", parent: "RO", value: "Seringueiras" },
    { name: "Teixeiropolis", parent: "RO", value: "Teixeiropolis" },
    { name: "Theobroma", parent: "RO", value: "Theobroma" },
    { name: "Urupa", parent: "RO", value: "Urupa" },
    { name: "Vale do Anari", parent: "RO", value: "Vale do Anari" },
    { name: "Vale do Paraiso", parent: "RO", value: "Vale do Paraiso" },
    { name: "Vilhena", parent: "RO", value: "Vilhena" },

    { name: "Alto Alegre", parent: "RR", value: "Alto Alegre" },
    { name: "Amajari", parent: "RR", value: "Amajari" },
    { name: "Boa Vista", parent: "RR", value: "Boa Vista" },
    { name: "Bonfim", parent: "RR", value: "Bonfim" },
    { name: "Canta", parent: "RR", value: "Canta" },
    { name: "Caracarai", parent: "RR", value: "Caracarai" },
    { name: "Caroebe", parent: "RR", value: "Caroebe" },
    { name: "Iracema", parent: "RR", value: "Iracema" },
    { name: "Mucajai", parent: "RR", value: "Mucajai" },
    { name: "Normandia", parent: "RR", value: "Normandia" },
    { name: "Pacaraima", parent: "RR", value: "Pacaraima" },
    { name: "Rorainopolis", parent: "RR", value: "Rorainopolis" },
    { name: "Sao Joao da Baliza", parent: "RR", value: "Sao Joao da Baliza" },
    { name: "Sao Luiz", parent: "RR", value: "Sao Luiz" },
    { name: "Uiramuta", parent: "RR", value: "Uiramuta" },

    { name: "Agua Santa", parent: "RS", value: "Agua Santa" },
    { name: "Agudo", parent: "RS", value: "Agudo" },
    { name: "Ajuricaba", parent: "RS", value: "Ajuricaba" },
    { name: "Alecrim", parent: "RS", value: "Alecrim" },
    { name: "Alegrete", parent: "RS", value: "Alegrete" },
    { name: "Alegria", parent: "RS", value: "Alegria" },
    { name: "Alpestre", parent: "RS", value: "Alpestre" },
    { name: "Alto Alegre", parent: "RS", value: "Alto Alegre" },
    { name: "Alto Feliz", parent: "RS", value: "Alto Feliz" },
    { name: "Alvorada", parent: "RS", value: "Alvorada" },
    { name: "Amaral Ferrador", parent: "RS", value: "Amaral Ferrador" },
    { name: "Ametista do Sul", parent: "RS", value: "Ametista do Sul" },
    { name: "Andre da Rocha", parent: "RS", value: "Andre da Rocha" },
    { name: "Anta Gorda", parent: "RS", value: "Anta Gorda" },
    { name: "Antonio Prado", parent: "RS", value: "Antonio Prado" },
    { name: "Arambare", parent: "RS", value: "Arambare" },
    { name: "Ararica", parent: "RS", value: "Ararica" },
    { name: "Aratiba", parent: "RS", value: "Aratiba" },
    { name: "Arroio Grande", parent: "RS", value: "Arroio Grande" },
    { name: "Arroio do Meio", parent: "RS", value: "Arroio do Meio" },
    { name: "Arroio do Sal", parent: "RS", value: "Arroio do Sal" },
    { name: "Arroio do Tigre", parent: "RS", value: "Arroio do Tigre" },
    { name: "Arroio dos Ratos", parent: "RS", value: "Arroio dos Ratos" },
    { name: "Arvorezinha", parent: "RS", value: "Arvorezinha" },
    { name: "Augusto Pestana", parent: "RS", value: "Augusto Pestana" },
    { name: "Aurea", parent: "RS", value: "Aurea" },
    { name: "Bage", parent: "RS", value: "Bage" },
    { name: "Balneario Pinhal", parent: "RS", value: "Balneario Pinhal" },
    { name: "Barao", parent: "RS", value: "Barao" },
    { name: "Barao de Cotegipe", parent: "RS", value: "Barao de Cotegipe" },
    { name: "Barao do Triunfo", parent: "RS", value: "Barao do Triunfo" },
    { name: "Barra Funda", parent: "RS", value: "Barra Funda" },
    { name: "Barra do Guarita", parent: "RS", value: "Barra do Guarita" },
    { name: "Barra do Quarai", parent: "RS", value: "Barra do Quarai" },
    { name: "Barra do Ribeiro", parent: "RS", value: "Barra do Ribeiro" },
    { name: "Barra do Rio Azul", parent: "RS", value: "Barra do Rio Azul" },
    { name: "Barracao", parent: "RS", value: "Barracao" },
    { name: "Barros Cassal", parent: "RS", value: "Barros Cassal" },
    {
      name: "Benjamin Constant do Sul",
      parent: "RS",
      value: "Benjamin Constant do Sul",
    },
    { name: "Bento Goncalves", parent: "RS", value: "Bento Goncalves" },
    {
      name: "Boa Vista das Missoes",
      parent: "RS",
      value: "Boa Vista das Missoes",
    },
    { name: "Boa Vista do Burica", parent: "RS", value: "Boa Vista do Burica" },
    { name: "Boa Vista do Sul", parent: "RS", value: "Boa Vista do Sul" },
    { name: "Bom Jesus", parent: "RS", value: "Bom Jesus" },
    { name: "Bom Principio", parent: "RS", value: "Bom Principio" },
    { name: "Bom Progresso", parent: "RS", value: "Bom Progresso" },
    { name: "Bom Retiro do Sul", parent: "RS", value: "Bom Retiro do Sul" },
    { name: "Boqueirao do Leao", parent: "RS", value: "Boqueirao do Leao" },
    { name: "Bossoroca", parent: "RS", value: "Bossoroca" },
    { name: "Braga", parent: "RS", value: "Braga" },
    { name: "Brochier", parent: "RS", value: "Brochier" },
    { name: "Butia", parent: "RS", value: "Butia" },
    { name: "Cacapava do Sul", parent: "RS", value: "Cacapava do Sul" },
    { name: "Cacequi", parent: "RS", value: "Cacequi" },
    { name: "Cachoeira do Sul", parent: "RS", value: "Cachoeira do Sul" },
    { name: "Cachoeirinha", parent: "RS", value: "Cachoeirinha" },
    { name: "Cacique Doble", parent: "RS", value: "Cacique Doble" },
    { name: "Caibate", parent: "RS", value: "Caibate" },
    { name: "Caicara", parent: "RS", value: "Caicara" },
    { name: "Camaqua", parent: "RS", value: "Camaqua" },
    { name: "Camargo", parent: "RS", value: "Camargo" },
    { name: "Cambara do Sul", parent: "RS", value: "Cambara do Sul" },
    { name: "Campestre da Serra", parent: "RS", value: "Campestre da Serra" },
    { name: "Campina das Missoes", parent: "RS", value: "Campina das Missoes" },
    { name: "Campinas do Sul", parent: "RS", value: "Campinas do Sul" },
    { name: "Campo Bom", parent: "RS", value: "Campo Bom" },
    { name: "Campo Novo", parent: "RS", value: "Campo Novo" },
    { name: "Campos Borges", parent: "RS", value: "Campos Borges" },
    { name: "Candelaria", parent: "RS", value: "Candelaria" },
    { name: "Candido Godoi", parent: "RS", value: "Candido Godoi" },
    { name: "Candiota", parent: "RS", value: "Candiota" },
    { name: "Canela", parent: "RS", value: "Canela" },
    { name: "Cangucu", parent: "RS", value: "Cangucu" },
    { name: "Canoas", parent: "RS", value: "Canoas" },
    { name: "Capao da Canoa", parent: "RS", value: "Capao da Canoa" },
    { name: "Capao do Leao", parent: "RS", value: "Capao do Leao" },
    { name: "Capela de Santana", parent: "RS", value: "Capela de Santana" },
    { name: "Capitao", parent: "RS", value: "Capitao" },
    { name: "Capivari do Sul", parent: "RS", value: "Capivari do Sul" },
    { name: "Caraa", parent: "RS", value: "Caraa" },
    { name: "Carazinho", parent: "RS", value: "Carazinho" },
    { name: "Carlos Barbosa", parent: "RS", value: "Carlos Barbosa" },
    { name: "Carlos Gomes", parent: "RS", value: "Carlos Gomes" },
    { name: "Casca", parent: "RS", value: "Casca" },
    { name: "Caseiros", parent: "RS", value: "Caseiros" },
    { name: "Catuipe", parent: "RS", value: "Catuipe" },
    { name: "Caxias do Sul", parent: "RS", value: "Caxias do Sul" },
    { name: "Centenario", parent: "RS", value: "Centenario" },
    { name: "Cerrito", parent: "RS", value: "Cerrito" },
    { name: "Cerro Branco", parent: "RS", value: "Cerro Branco" },
    { name: "Cerro Grande", parent: "RS", value: "Cerro Grande" },
    { name: "Cerro Grande do Sul", parent: "RS", value: "Cerro Grande do Sul" },
    { name: "Cerro Largo", parent: "RS", value: "Cerro Largo" },
    { name: "Chapada", parent: "RS", value: "Chapada" },
    { name: "Charqueadas", parent: "RS", value: "Charqueadas" },
    { name: "Charrua", parent: "RS", value: "Charrua" },
    { name: "Chiapeta", parent: "RS", value: "Chiapeta" },
    { name: "Chui", parent: "RS", value: "Chui" },
    { name: "Chuvisca", parent: "RS", value: "Chuvisca" },
    { name: "Cidreira", parent: "RS", value: "Cidreira" },
    { name: "Ciriaco", parent: "RS", value: "Ciriaco" },
    { name: "Colinas", parent: "RS", value: "Colinas" },
    { name: "Colorado", parent: "RS", value: "Colorado" },
    { name: "Condor", parent: "RS", value: "Condor" },
    { name: "Constantina", parent: "RS", value: "Constantina" },
    { name: "Coqueiros do Sul", parent: "RS", value: "Coqueiros do Sul" },
    { name: "Coronel Barros", parent: "RS", value: "Coronel Barros" },
    { name: "Coronel Bicaco", parent: "RS", value: "Coronel Bicaco" },
    { name: "Cotipora", parent: "RS", value: "Cotipora" },
    { name: "Coxilha", parent: "RS", value: "Coxilha" },
    { name: "Crissiumal", parent: "RS", value: "Crissiumal" },
    { name: "Cristal", parent: "RS", value: "Cristal" },
    { name: "Cristal do Sul", parent: "RS", value: "Cristal do Sul" },
    { name: "Cruz Alta", parent: "RS", value: "Cruz Alta" },
    { name: "Cruzeiro do Sul", parent: "RS", value: "Cruzeiro do Sul" },
    { name: "David Canabarro", parent: "RS", value: "David Canabarro" },
    { name: "Derrubadas", parent: "RS", value: "Derrubadas" },
    {
      name: "Dezesseis de Novembro",
      parent: "RS",
      value: "Dezesseis de Novembro",
    },
    { name: "Dilermano de Aguiar", parent: "RS", value: "Dilermano de Aguiar" },
    { name: "Dois Irmaos", parent: "RS", value: "Dois Irmaos" },
    {
      name: "Dois Irmaos das Missoes",
      parent: "RS",
      value: "Dois Irmaos das Missoes",
    },
    { name: "Dois Lajeados", parent: "RS", value: "Dois Lajeados" },
    { name: "Dom Feliciano", parent: "RS", value: "Dom Feliciano" },
    { name: "Dom Pedrito", parent: "RS", value: "Dom Pedrito" },
    {
      name: "Dom Pedro de Alcantara",
      parent: "RS",
      value: "Dom Pedro de Alcantara",
    },
    { name: "Dona Francisca", parent: "RS", value: "Dona Francisca" },
    {
      name: "Doutor Mauricio Cardoso",
      parent: "RS",
      value: "Doutor Mauricio Cardoso",
    },
    { name: "Doutor Ricardo", parent: "RS", value: "Doutor Ricardo" },
    { name: "Eldorado do Sul", parent: "RS", value: "Eldorado do Sul" },
    { name: "Encantado", parent: "RS", value: "Encantado" },
    { name: "Encruzilhada do Sul", parent: "RS", value: "Encruzilhada do Sul" },
    { name: "Engenho Velho", parent: "RS", value: "Engenho Velho" },
    { name: "Entre Rios do Sul", parent: "RS", value: "Entre Rios do Sul" },
    { name: "Entre-Ijuis", parent: "RS", value: "Entre-Ijuis" },
    { name: "Erebango", parent: "RS", value: "Erebango" },
    { name: "Erechim", parent: "RS", value: "Erechim" },
    { name: "Ernestina", parent: "RS", value: "Ernestina" },
    { name: "Erval Grande", parent: "RS", value: "Erval Grande" },
    { name: "Erval Seco", parent: "RS", value: "Erval Seco" },
    { name: "Esmeralda", parent: "RS", value: "Esmeralda" },
    { name: "Esperanca do Sul", parent: "RS", value: "Esperanca do Sul" },
    { name: "Espumoso", parent: "RS", value: "Espumoso" },
    { name: "Estacao", parent: "RS", value: "Estacao" },
    { name: "Estancia Velha", parent: "RS", value: "Estancia Velha" },
    { name: "Esteio", parent: "RS", value: "Esteio" },
    { name: "Estrela", parent: "RS", value: "Estrela" },
    { name: "Estrela Velha", parent: "RS", value: "Estrela Velha" },
    { name: "Eugenio de Castro", parent: "RS", value: "Eugenio de Castro" },
    { name: "Fagundes Varela", parent: "RS", value: "Fagundes Varela" },
    { name: "Farroupilha", parent: "RS", value: "Farroupilha" },
    { name: "Faxinal do Soturno", parent: "RS", value: "Faxinal do Soturno" },
    { name: "Faxinalzinho", parent: "RS", value: "Faxinalzinho" },
    { name: "Fazenda Vilanova", parent: "RS", value: "Fazenda Vilanova" },
    { name: "Feliz", parent: "RS", value: "Feliz" },
    { name: "Flores da Cunha", parent: "RS", value: "Flores da Cunha" },
    { name: "Floriano Peixoto", parent: "RS", value: "Floriano Peixoto" },
    { name: "Fontoura Xavier", parent: "RS", value: "Fontoura Xavier" },
    { name: "Formigueiro", parent: "RS", value: "Formigueiro" },
    { name: "Fortaleza dos Valos", parent: "RS", value: "Fortaleza dos Valos" },
    {
      name: "Frederico Westphalen",
      parent: "RS",
      value: "Frederico Westphalen",
    },
    { name: "Garibaldi", parent: "RS", value: "Garibaldi" },
    { name: "Garruchos", parent: "RS", value: "Garruchos" },
    { name: "Gaurama", parent: "RS", value: "Gaurama" },
    { name: "General Camara", parent: "RS", value: "General Camara" },
    { name: "Gentil", parent: "RS", value: "Gentil" },
    { name: "Getulio Vargas", parent: "RS", value: "Getulio Vargas" },
    { name: "Girua", parent: "RS", value: "Girua" },
    { name: "Glorinha", parent: "RS", value: "Glorinha" },
    { name: "Gramado", parent: "RS", value: "Gramado" },
    { name: "Gramado Xavier", parent: "RS", value: "Gramado Xavier" },
    {
      name: "Gramado dos Loureiros",
      parent: "RS",
      value: "Gramado dos Loureiros",
    },
    { name: "Gravatai", parent: "RS", value: "Gravatai" },
    { name: "Guabiju", parent: "RS", value: "Guabiju" },
    { name: "Guaiba", parent: "RS", value: "Guaiba" },
    { name: "Guapore", parent: "RS", value: "Guapore" },
    { name: "Guarani das Missoes", parent: "RS", value: "Guarani das Missoes" },
    { name: "Harmonia", parent: "RS", value: "Harmonia" },
    { name: "Herval", parent: "RS", value: "Herval" },
    { name: "Herveiras", parent: "RS", value: "Herveiras" },
    { name: "Horizontina", parent: "RS", value: "Horizontina" },
    { name: "Hulha Negra", parent: "RS", value: "Hulha Negra" },
    { name: "Humaita", parent: "RS", value: "Humaita" },
    { name: "Ibarama", parent: "RS", value: "Ibarama" },
    { name: "Ibiaca", parent: "RS", value: "Ibiaca" },
    { name: "Ibiraiaras", parent: "RS", value: "Ibiraiaras" },
    { name: "Ibirapuita", parent: "RS", value: "Ibirapuita" },
    { name: "Ibiruba", parent: "RS", value: "Ibiruba" },
    { name: "Igrejinha", parent: "RS", value: "Igrejinha" },
    { name: "Ijui", parent: "RS", value: "Ijui" },
    { name: "Ilopolis", parent: "RS", value: "Ilopolis" },
    { name: "Imbe", parent: "RS", value: "Imbe" },
    { name: "Imigrante", parent: "RS", value: "Imigrante" },
    { name: "Independencia", parent: "RS", value: "Independencia" },
    { name: "Inhacora", parent: "RS", value: "Inhacora" },
    { name: "Ipe", parent: "RS", value: "Ipe" },
    { name: "Ipiranga do Sul", parent: "RS", value: "Ipiranga do Sul" },
    { name: "Irai", parent: "RS", value: "Irai" },
    { name: "Itaara", parent: "RS", value: "Itaara" },
    { name: "Itacurubi", parent: "RS", value: "Itacurubi" },
    { name: "Itapuca", parent: "RS", value: "Itapuca" },
    { name: "Itaqui", parent: "RS", value: "Itaqui" },
    { name: "Itatiba do Sul", parent: "RS", value: "Itatiba do Sul" },
    { name: "Ivora", parent: "RS", value: "Ivora" },
    { name: "Ivoti", parent: "RS", value: "Ivoti" },
    { name: "Jaboticaba", parent: "RS", value: "Jaboticaba" },
    { name: "Jacutinga", parent: "RS", value: "Jacutinga" },
    { name: "Jaguarao", parent: "RS", value: "Jaguarao" },
    { name: "Jaguari", parent: "RS", value: "Jaguari" },
    { name: "Jaquirana", parent: "RS", value: "Jaquirana" },
    { name: "Jari", parent: "RS", value: "Jari" },
    { name: "Joia", parent: "RS", value: "Joia" },
    { name: "Julio de Castilhos", parent: "RS", value: "Julio de Castilhos" },
    { name: "Lagoa Vermelha", parent: "RS", value: "Lagoa Vermelha" },
    {
      name: "Lagoa dos Tres Cantos",
      parent: "RS",
      value: "Lagoa dos Tres Cantos",
    },
    { name: "Lagoao", parent: "RS", value: "Lagoao" },
    { name: "Lajeado", parent: "RS", value: "Lajeado" },
    { name: "Lajeado do Bugre", parent: "RS", value: "Lajeado do Bugre" },
    { name: "Lavras do Sul", parent: "RS", value: "Lavras do Sul" },
    { name: "Liberato Salzano", parent: "RS", value: "Liberato Salzano" },
    { name: "Lindolfo Collor", parent: "RS", value: "Lindolfo Collor" },
    { name: "Linha Nova", parent: "RS", value: "Linha Nova" },
    { name: "Macambara", parent: "RS", value: "Macambara" },
    { name: "Machadinho", parent: "RS", value: "Machadinho" },
    { name: "Mampituba", parent: "RS", value: "Mampituba" },
    { name: "Manoel Viana", parent: "RS", value: "Manoel Viana" },
    { name: "Maquine", parent: "RS", value: "Maquine" },
    { name: "Marata", parent: "RS", value: "Marata" },
    { name: "Marau", parent: "RS", value: "Marau" },
    { name: "Marcelino Ramos", parent: "RS", value: "Marcelino Ramos" },
    { name: "Mariana Pimentel", parent: "RS", value: "Mariana Pimentel" },
    { name: "Mariano Moro", parent: "RS", value: "Mariano Moro" },
    { name: "Marques de Souza", parent: "RS", value: "Marques de Souza" },
    { name: "Mata", parent: "RS", value: "Mata" },
    { name: "Mato Castelhano", parent: "RS", value: "Mato Castelhano" },
    { name: "Mato Leitao", parent: "RS", value: "Mato Leitao" },
    {
      name: "Maximiliano de Almeida",
      parent: "RS",
      value: "Maximiliano de Almeida",
    },
    { name: "Minas do Leao", parent: "RS", value: "Minas do Leao" },
    { name: "Miraguai", parent: "RS", value: "Miraguai" },
    { name: "Montauri", parent: "RS", value: "Montauri" },
    {
      name: "Monte Alegre dos Campos",
      parent: "RS",
      value: "Monte Alegre dos Campos",
    },
    { name: "Monte Belo do Sul", parent: "RS", value: "Monte Belo do Sul" },
    { name: "Montenegro", parent: "RS", value: "Montenegro" },
    { name: "Mormaco", parent: "RS", value: "Mormaco" },
    { name: "Morrinhos do Sul", parent: "RS", value: "Morrinhos do Sul" },
    { name: "Morro Redondo", parent: "RS", value: "Morro Redondo" },
    { name: "Morro Reuter", parent: "RS", value: "Morro Reuter" },
    { name: "Mostardas", parent: "RS", value: "Mostardas" },
    { name: "Mucum", parent: "RS", value: "Mucum" },
    { name: "Muitos Capoes", parent: "RS", value: "Muitos Capoes" },
    { name: "Muliterno", parent: "RS", value: "Muliterno" },
    { name: "Nao-Me-Toque", parent: "RS", value: "Nao-Me-Toque" },
    { name: "Nicolau Vergueiro", parent: "RS", value: "Nicolau Vergueiro" },
    { name: "Nonoai", parent: "RS", value: "Nonoai" },
    { name: "Nova Alvorada", parent: "RS", value: "Nova Alvorada" },
    { name: "Nova Araca", parent: "RS", value: "Nova Araca" },
    { name: "Nova Bassano", parent: "RS", value: "Nova Bassano" },
    { name: "Nova Boa Vista", parent: "RS", value: "Nova Boa Vista" },
    { name: "Nova Brescia", parent: "RS", value: "Nova Brescia" },
    { name: "Nova Candelaria", parent: "RS", value: "Nova Candelaria" },
    {
      name: "Nova Esperanca do Sul",
      parent: "RS",
      value: "Nova Esperanca do Sul",
    },
    { name: "Nova Hartz", parent: "RS", value: "Nova Hartz" },
    { name: "Nova Padua", parent: "RS", value: "Nova Padua" },
    { name: "Nova Palma", parent: "RS", value: "Nova Palma" },
    { name: "Nova Petropolis", parent: "RS", value: "Nova Petropolis" },
    { name: "Nova Prata", parent: "RS", value: "Nova Prata" },
    { name: "Nova Ramada", parent: "RS", value: "Nova Ramada" },
    { name: "Nova Roma do Sul", parent: "RS", value: "Nova Roma do Sul" },
    { name: "Nova Santa Rita", parent: "RS", value: "Nova Santa Rita" },
    { name: "Novo Barreiro", parent: "RS", value: "Novo Barreiro" },
    { name: "Novo Cabrais", parent: "RS", value: "Novo Cabrais" },
    { name: "Novo Hamburgo", parent: "RS", value: "Novo Hamburgo" },
    { name: "Novo Machado", parent: "RS", value: "Novo Machado" },
    { name: "Novo Tiradentes", parent: "RS", value: "Novo Tiradentes" },
    { name: "Osorio", parent: "RS", value: "Osorio" },
    { name: "Paim Filho", parent: "RS", value: "Paim Filho" },
    { name: "Palmares do Sul", parent: "RS", value: "Palmares do Sul" },
    {
      name: "Palmeira das Missoes",
      parent: "RS",
      value: "Palmeira das Missoes",
    },
    { name: "Palmitinho", parent: "RS", value: "Palmitinho" },
    { name: "Panambi", parent: "RS", value: "Panambi" },
    { name: "Pantano Grande", parent: "RS", value: "Pantano Grande" },
    { name: "Parai", parent: "RS", value: "Parai" },
    { name: "Paraiso do Sul", parent: "RS", value: "Paraiso do Sul" },
    { name: "Pareci Novo", parent: "RS", value: "Pareci Novo" },
    { name: "Parobe", parent: "RS", value: "Parobe" },
    { name: "Passa Sete", parent: "RS", value: "Passa Sete" },
    { name: "Passo Fundo", parent: "RS", value: "Passo Fundo" },
    { name: "Passo do Sobrado", parent: "RS", value: "Passo do Sobrado" },
    { name: "Paverama", parent: "RS", value: "Paverama" },
    { name: "Pedro Osorio", parent: "RS", value: "Pedro Osorio" },
    { name: "Pejucara", parent: "RS", value: "Pejucara" },
    { name: "Pelotas", parent: "RS", value: "Pelotas" },
    { name: "Picada Cafe", parent: "RS", value: "Picada Cafe" },
    { name: "Pinhal", parent: "RS", value: "Pinhal" },
    { name: "Pinhal Grande", parent: "RS", value: "Pinhal Grande" },
    { name: "Pinheirinho do Vale", parent: "RS", value: "Pinheirinho do Vale" },
    { name: "Pinheiro Machado", parent: "RS", value: "Pinheiro Machado" },
    { name: "Pirapo", parent: "RS", value: "Pirapo" },
    { name: "Piratini", parent: "RS", value: "Piratini" },
    { name: "Planalto", parent: "RS", value: "Planalto" },
    { name: "Poco das Antas", parent: "RS", value: "Poco das Antas" },
    { name: "Pontao", parent: "RS", value: "Pontao" },
    { name: "Ponte Preta", parent: "RS", value: "Ponte Preta" },
    { name: "Portao", parent: "RS", value: "Portao" },
    { name: "Porto Alegre", parent: "RS", value: "Porto Alegre" },
    { name: "Porto Lucena", parent: "RS", value: "Porto Lucena" },
    { name: "Porto Maua", parent: "RS", value: "Porto Maua" },
    { name: "Porto Vera Cruz", parent: "RS", value: "Porto Vera Cruz" },
    { name: "Porto Xavier", parent: "RS", value: "Porto Xavier" },
    { name: "Pouso Novo", parent: "RS", value: "Pouso Novo" },
    { name: "Presidente Lucena", parent: "RS", value: "Presidente Lucena" },
    { name: "Progresso", parent: "RS", value: "Progresso" },
    { name: "Protasio Alves", parent: "RS", value: "Protasio Alves" },
    { name: "Putinga", parent: "RS", value: "Putinga" },
    { name: "Quarai", parent: "RS", value: "Quarai" },
    { name: "Quevedos", parent: "RS", value: "Quevedos" },
    { name: "Quinze de Novembro", parent: "RS", value: "Quinze de Novembro" },
    { name: "Redentora", parent: "RS", value: "Redentora" },
    { name: "Relvado", parent: "RS", value: "Relvado" },
    { name: "Restinga Seca", parent: "RS", value: "Restinga Seca" },
    { name: "Rio Grande", parent: "RS", value: "Rio Grande" },
    { name: "Rio Pardo", parent: "RS", value: "Rio Pardo" },
    { name: "Rio dos Indios", parent: "RS", value: "Rio dos Indios" },
    { name: "Riozinho", parent: "RS", value: "Riozinho" },
    { name: "Roca Sales", parent: "RS", value: "Roca Sales" },
    { name: "Rodeio Bonito", parent: "RS", value: "Rodeio Bonito" },
    { name: "Rolante", parent: "RS", value: "Rolante" },
    { name: "Ronda Alta", parent: "RS", value: "Ronda Alta" },
    { name: "Rondinha", parent: "RS", value: "Rondinha" },
    { name: "Roque Gonzales", parent: "RS", value: "Roque Gonzales" },
    { name: "Rosario do Sul", parent: "RS", value: "Rosario do Sul" },
    { name: "Sagrada Familia", parent: "RS", value: "Sagrada Familia" },
    { name: "Saldanha Marinho", parent: "RS", value: "Saldanha Marinho" },
    { name: "Salto do Jacui", parent: "RS", value: "Salto do Jacui" },
    {
      name: "Salvador das Missoes",
      parent: "RS",
      value: "Salvador das Missoes",
    },
    { name: "Salvador do Sul", parent: "RS", value: "Salvador do Sul" },
    { name: "Sananduva", parent: "RS", value: "Sananduva" },
    {
      name: "Santa Barbara do Sul",
      parent: "RS",
      value: "Santa Barbara do Sul",
    },
    { name: "Santa Clara do Sul", parent: "RS", value: "Santa Clara do Sul" },
    { name: "Santa Cruz do Sul", parent: "RS", value: "Santa Cruz do Sul" },
    { name: "Santa Maria", parent: "RS", value: "Santa Maria" },
    {
      name: "Santa Maria do Herval",
      parent: "RS",
      value: "Santa Maria do Herval",
    },
    { name: "Santa Rosa", parent: "RS", value: "Santa Rosa" },
    { name: "Santa Tereza", parent: "RS", value: "Santa Tereza" },
    {
      name: "Santa Vitoria do Palmar",
      parent: "RS",
      value: "Santa Vitoria do Palmar",
    },
    {
      name: "Santana da Boa Vista",
      parent: "RS",
      value: "Santana da Boa Vista",
    },
    {
      name: "Santana do Livramento",
      parent: "RS",
      value: "Santana do Livramento",
    },
    { name: "Santiago", parent: "RS", value: "Santiago" },
    { name: "Santo Angelo", parent: "RS", value: "Santo Angelo" },
    {
      name: "Santo Antonio da Patrulha",
      parent: "RS",
      value: "Santo Antonio da Patrulha",
    },
    {
      name: "Santo Antonio das Missoes",
      parent: "RS",
      value: "Santo Antonio das Missoes",
    },
    {
      name: "Santo Antonio do Palma",
      parent: "RS",
      value: "Santo Antonio do Palma",
    },
    {
      name: "Santo Antonio do Planalto",
      parent: "RS",
      value: "Santo Antonio do Planalto",
    },
    { name: "Santo Augusto", parent: "RS", value: "Santo Augusto" },
    { name: "Santo Cristo", parent: "RS", value: "Santo Cristo" },
    {
      name: "Santo Expedito do Sul",
      parent: "RS",
      value: "Santo Expedito do Sul",
    },
    { name: "Sao Borja", parent: "RS", value: "Sao Borja" },
    { name: "Sao Domingos do Sul", parent: "RS", value: "Sao Domingos do Sul" },
    {
      name: "Sao Francisco de Assis",
      parent: "RS",
      value: "Sao Francisco de Assis",
    },
    {
      name: "Sao Francisco de Paula",
      parent: "RS",
      value: "Sao Francisco de Paula",
    },
    { name: "Sao Gabriel", parent: "RS", value: "Sao Gabriel" },
    { name: "Sao Jeronimo", parent: "RS", value: "Sao Jeronimo" },
    { name: "Sao Joao da Urtiga", parent: "RS", value: "Sao Joao da Urtiga" },
    {
      name: "Sao Joao do Polesine",
      parent: "RS",
      value: "Sao Joao do Polesine",
    },
    { name: "Sao Jorge", parent: "RS", value: "Sao Jorge" },
    {
      name: "Sao Jose das Missoes",
      parent: "RS",
      value: "Sao Jose das Missoes",
    },
    { name: "Sao Jose do Herval", parent: "RS", value: "Sao Jose do Herval" },
    {
      name: "Sao Jose do Hortencio",
      parent: "RS",
      value: "Sao Jose do Hortencio",
    },
    {
      name: "Sao Jose do Inhacora",
      parent: "RS",
      value: "Sao Jose do Inhacora",
    },
    { name: "Sao Jose do Norte", parent: "RS", value: "Sao Jose do Norte" },
    { name: "Sao Jose do Ouro", parent: "RS", value: "Sao Jose do Ouro" },
    {
      name: "Sao Jose dos Ausentes",
      parent: "RS",
      value: "Sao Jose dos Ausentes",
    },
    { name: "Sao Leopoldo", parent: "RS", value: "Sao Leopoldo" },
    { name: "Sao Lourenco do Sul", parent: "RS", value: "Sao Lourenco do Sul" },
    { name: "Sao Luiz Gonzaga", parent: "RS", value: "Sao Luiz Gonzaga" },
    { name: "Sao Marcos", parent: "RS", value: "Sao Marcos" },
    { name: "Sao Martinho", parent: "RS", value: "Sao Martinho" },
    {
      name: "Sao Martinho da Serra",
      parent: "RS",
      value: "Sao Martinho da Serra",
    },
    {
      name: "Sao Miguel das Missoes",
      parent: "RS",
      value: "Sao Miguel das Missoes",
    },
    { name: "Sao Nicolau", parent: "RS", value: "Sao Nicolau" },
    {
      name: "Sao Paulo das Missoes",
      parent: "RS",
      value: "Sao Paulo das Missoes",
    },
    { name: "Sao Pedro da Serra", parent: "RS", value: "Sao Pedro da Serra" },
    { name: "Sao Pedro do Butia", parent: "RS", value: "Sao Pedro do Butia" },
    { name: "Sao Pedro do Sul", parent: "RS", value: "Sao Pedro do Sul" },
    {
      name: "Sao Sebastiao do Cai",
      parent: "RS",
      value: "Sao Sebastiao do Cai",
    },
    { name: "Sao Sepe", parent: "RS", value: "Sao Sepe" },
    { name: "Sao Valentim", parent: "RS", value: "Sao Valentim" },
    { name: "Sao Valentim do Sul", parent: "RS", value: "Sao Valentim do Sul" },
    { name: "Sao Valerio do Sul", parent: "RS", value: "Sao Valerio do Sul" },
    { name: "Sao Vendelino", parent: "RS", value: "Sao Vendelino" },
    { name: "Sao Vicente do Sul", parent: "RS", value: "Sao Vicente do Sul" },
    { name: "Sapiranga", parent: "RS", value: "Sapiranga" },
    { name: "Sapucaia do Sul", parent: "RS", value: "Sapucaia do Sul" },
    { name: "Sarandi", parent: "RS", value: "Sarandi" },
    { name: "Seberi", parent: "RS", value: "Seberi" },
    { name: "Sede Nova", parent: "RS", value: "Sede Nova" },
    { name: "Segredo", parent: "RS", value: "Segredo" },
    { name: "Selbach", parent: "RS", value: "Selbach" },
    {
      name: "Senador Salgado Filho",
      parent: "RS",
      value: "Senador Salgado Filho",
    },
    { name: "Sentinela do Sul", parent: "RS", value: "Sentinela do Sul" },
    { name: "Serafina Correa", parent: "RS", value: "Serafina Correa" },
    { name: "Serio", parent: "RS", value: "Serio" },
    { name: "Sertao", parent: "RS", value: "Sertao" },
    { name: "Sertao Santana", parent: "RS", value: "Sertao Santana" },
    { name: "Sete de Setembro", parent: "RS", value: "Sete de Setembro" },
    {
      name: "Severiano de Almeida",
      parent: "RS",
      value: "Severiano de Almeida",
    },
    { name: "Silveira Martins", parent: "RS", value: "Silveira Martins" },
    { name: "Sinimbu", parent: "RS", value: "Sinimbu" },
    { name: "Sobradinho", parent: "RS", value: "Sobradinho" },
    { name: "Soledade", parent: "RS", value: "Soledade" },
    { name: "Tabai", parent: "RS", value: "Tabai" },
    { name: "Tapejara", parent: "RS", value: "Tapejara" },
    { name: "Tapera", parent: "RS", value: "Tapera" },
    { name: "Tapes", parent: "RS", value: "Tapes" },
    { name: "Taquara", parent: "RS", value: "Taquara" },
    { name: "Taquari", parent: "RS", value: "Taquari" },
    { name: "Taquarucu do Sul", parent: "RS", value: "Taquarucu do Sul" },
    { name: "Tavares", parent: "RS", value: "Tavares" },
    { name: "Tenente Portela", parent: "RS", value: "Tenente Portela" },
    { name: "Terra de Areia", parent: "RS", value: "Terra de Areia" },
    { name: "Teutonia", parent: "RS", value: "Teutonia" },
    { name: "Tiradentes do Sul", parent: "RS", value: "Tiradentes do Sul" },
    { name: "Toropi", parent: "RS", value: "Toropi" },
    { name: "Torres", parent: "RS", value: "Torres" },
    { name: "Tramandai", parent: "RS", value: "Tramandai" },
    { name: "Travesseiro", parent: "RS", value: "Travesseiro" },
    { name: "Tres Arroios", parent: "RS", value: "Tres Arroios" },
    { name: "Tres Cachoeiras", parent: "RS", value: "Tres Cachoeiras" },
    { name: "Tres Coroas", parent: "RS", value: "Tres Coroas" },
    { name: "Tres Forquilhas", parent: "RS", value: "Tres Forquilhas" },
    { name: "Tres Palmeiras", parent: "RS", value: "Tres Palmeiras" },
    { name: "Tres Passos", parent: "RS", value: "Tres Passos" },
    { name: "Tres de Maio", parent: "RS", value: "Tres de Maio" },
    { name: "Trindade do Sul", parent: "RS", value: "Trindade do Sul" },
    { name: "Triunfo", parent: "RS", value: "Triunfo" },
    { name: "Tucunduva", parent: "RS", value: "Tucunduva" },
    { name: "Tunas", parent: "RS", value: "Tunas" },
    { name: "Tupanci do Sul", parent: "RS", value: "Tupanci do Sul" },
    { name: "Tupancireta", parent: "RS", value: "Tupancireta" },
    { name: "Tupandi", parent: "RS", value: "Tupandi" },
    { name: "Tuparendi", parent: "RS", value: "Tuparendi" },
    { name: "Turucu", parent: "RS", value: "Turucu" },
    { name: "Ubiretama", parent: "RS", value: "Ubiretama" },
    { name: "Uniao da Serra", parent: "RS", value: "Uniao da Serra" },
    { name: "Unistalda", parent: "RS", value: "Unistalda" },
    { name: "Uruguaiana", parent: "RS", value: "Uruguaiana" },
    { name: "Vacaria", parent: "RS", value: "Vacaria" },
    { name: "Vale Real", parent: "RS", value: "Vale Real" },
    { name: "Vale Verde", parent: "RS", value: "Vale Verde" },
    { name: "Vale do Sol", parent: "RS", value: "Vale do Sol" },
    { name: "Vanini", parent: "RS", value: "Vanini" },
    { name: "Venancio Aires", parent: "RS", value: "Venancio Aires" },
    { name: "Vera Cruz", parent: "RS", value: "Vera Cruz" },
    { name: "Veranopolis", parent: "RS", value: "Veranopolis" },
    { name: "Vespasiano Correa", parent: "RS", value: "Vespasiano Correa" },
    { name: "Viadutos", parent: "RS", value: "Viadutos" },
    { name: "Viamao", parent: "RS", value: "Viamao" },
    { name: "Vicente Dutra", parent: "RS", value: "Vicente Dutra" },
    { name: "Victor Graeff", parent: "RS", value: "Victor Graeff" },
    { name: "Vila Flores", parent: "RS", value: "Vila Flores" },
    { name: "Vila Langaro", parent: "RS", value: "Vila Langaro" },
    { name: "Vila Maria", parent: "RS", value: "Vila Maria" },
    { name: "Vila Nova do Sul", parent: "RS", value: "Vila Nova do Sul" },
    { name: "Vista Alegre", parent: "RS", value: "Vista Alegre" },
    {
      name: "Vista Alegre do Prata",
      parent: "RS",
      value: "Vista Alegre do Prata",
    },
    { name: "Vista Gaucha", parent: "RS", value: "Vista Gaucha" },
    { name: "Vitoria das Missoes", parent: "RS", value: "Vitoria das Missoes" },
    { name: "Xangri-la", parent: "RS", value: "Xangri-la" },

    { name: "Abdon Batista", parent: "SC", value: "Abdon Batista" },
    { name: "Abelardo Luz", parent: "SC", value: "Abelardo Luz" },
    { name: "Agrolandia", parent: "SC", value: "Agrolandia" },
    { name: "Agronomica", parent: "SC", value: "Agronomica" },
    { name: "Agua Doce", parent: "SC", value: "Agua Doce" },
    { name: "Aguas Frias", parent: "SC", value: "Aguas Frias" },
    { name: "Aguas Mornas", parent: "SC", value: "Aguas Mornas" },
    { name: "Aguas de Chapeco", parent: "SC", value: "Aguas de Chapeco" },
    { name: "Alfredo Wagner", parent: "SC", value: "Alfredo Wagner" },
    { name: "Alto Bela Vista", parent: "SC", value: "Alto Bela Vista" },
    { name: "Anchieta", parent: "SC", value: "Anchieta" },
    { name: "Angelina", parent: "SC", value: "Angelina" },
    { name: "Anita Garibaldi", parent: "SC", value: "Anita Garibaldi" },
    { name: "Anitapolis", parent: "SC", value: "Anitapolis" },
    { name: "Antonio Carlos", parent: "SC", value: "Antonio Carlos" },
    { name: "Apiuna", parent: "SC", value: "Apiuna" },
    { name: "Arabuta", parent: "SC", value: "Arabuta" },
    { name: "Araquari", parent: "SC", value: "Araquari" },
    { name: "Ararangua", parent: "SC", value: "Ararangua" },
    { name: "Armazem", parent: "SC", value: "Armazem" },
    { name: "Arroio Trinta", parent: "SC", value: "Arroio Trinta" },
    { name: "Arvoredo", parent: "SC", value: "Arvoredo" },
    { name: "Ascurra", parent: "SC", value: "Ascurra" },
    { name: "Atalanta", parent: "SC", value: "Atalanta" },
    { name: "Aurora", parent: "SC", value: "Aurora" },
    {
      name: "Balneario Arroio do Silva",
      parent: "SC",
      value: "Balneario Arroio do Silva",
    },
    {
      name: "Balneario Barra do Sul",
      parent: "SC",
      value: "Balneario Barra do Sul",
    },
    { name: "Balneario Camboriu", parent: "SC", value: "Balneario Camboriu" },
    { name: "Balneario Gaivota", parent: "SC", value: "Balneario Gaivota" },
    { name: "Bandeirante", parent: "SC", value: "Bandeirante" },
    { name: "Barra Bonita", parent: "SC", value: "Barra Bonita" },
    { name: "Barra Velha", parent: "SC", value: "Barra Velha" },
    { name: "Bela Vista do Toldo", parent: "SC", value: "Bela Vista do Toldo" },
    { name: "Belmonte", parent: "SC", value: "Belmonte" },
    { name: "Benedito Novo", parent: "SC", value: "Benedito Novo" },
    { name: "Biguacu", parent: "SC", value: "Biguacu" },
    { name: "Blumenau", parent: "SC", value: "Blumenau" },
    { name: "Bocaina do Sul", parent: "SC", value: "Bocaina do Sul" },
    { name: "Bom Jardim da Serra", parent: "SC", value: "Bom Jardim da Serra" },
    { name: "Bom Jesus", parent: "SC", value: "Bom Jesus" },
    { name: "Bom Jesus do Oeste", parent: "SC", value: "Bom Jesus do Oeste" },
    { name: "Bom Retiro", parent: "SC", value: "Bom Retiro" },
    { name: "Bombinhas", parent: "SC", value: "Bombinhas" },
    { name: "Botuvera", parent: "SC", value: "Botuvera" },
    { name: "Braco do Norte", parent: "SC", value: "Braco do Norte" },
    { name: "Braco do Trombudo", parent: "SC", value: "Braco do Trombudo" },
    { name: "Brunopolis", parent: "SC", value: "Brunopolis" },
    { name: "Brusque", parent: "SC", value: "Brusque" },
    { name: "Cacador", parent: "SC", value: "Cacador" },
    { name: "Caibi", parent: "SC", value: "Caibi" },
    { name: "Calmon", parent: "SC", value: "Calmon" },
    { name: "Camboriu", parent: "SC", value: "Camboriu" },
    { name: "Campo Alegre", parent: "SC", value: "Campo Alegre" },
    { name: "Campo Belo do Sul", parent: "SC", value: "Campo Belo do Sul" },
    { name: "Campo Ere", parent: "SC", value: "Campo Ere" },
    { name: "Campos Novos", parent: "SC", value: "Campos Novos" },
    { name: "Canelinha", parent: "SC", value: "Canelinha" },
    { name: "Canoinhas", parent: "SC", value: "Canoinhas" },
    { name: "Capao Alto", parent: "SC", value: "Capao Alto" },
    { name: "Capinzal", parent: "SC", value: "Capinzal" },
    { name: "Capivari de Baixo", parent: "SC", value: "Capivari de Baixo" },
    { name: "Catanduvas", parent: "SC", value: "Catanduvas" },
    { name: "Caxambu do Sul", parent: "SC", value: "Caxambu do Sul" },
    { name: "Celso Ramos", parent: "SC", value: "Celso Ramos" },
    { name: "Cerro Negro", parent: "SC", value: "Cerro Negro" },
    { name: "Chapadao do Lageado", parent: "SC", value: "Chapadao do Lageado" },
    { name: "Chapeco", parent: "SC", value: "Chapeco" },
    { name: "Cocal do Sul", parent: "SC", value: "Cocal do Sul" },
    { name: "Concordia", parent: "SC", value: "Concordia" },
    { name: "Cordilheira Alta", parent: "SC", value: "Cordilheira Alta" },
    { name: "Coronel Freitas", parent: "SC", value: "Coronel Freitas" },
    { name: "Coronel Martins", parent: "SC", value: "Coronel Martins" },
    { name: "Correia Pinto", parent: "SC", value: "Correia Pinto" },
    { name: "Corupa", parent: "SC", value: "Corupa" },
    { name: "Criciuma", parent: "SC", value: "Criciuma" },
    { name: "Cunha Pora", parent: "SC", value: "Cunha Pora" },
    { name: "Cunhatai", parent: "SC", value: "Cunhatai" },
    { name: "Curitibanos", parent: "SC", value: "Curitibanos" },
    { name: "Descanso", parent: "SC", value: "Descanso" },
    { name: "Dionisio Cerqueira", parent: "SC", value: "Dionisio Cerqueira" },
    { name: "Dona Emma", parent: "SC", value: "Dona Emma" },
    { name: "Doutor Pedrinho", parent: "SC", value: "Doutor Pedrinho" },
    { name: "Entre Rios", parent: "SC", value: "Entre Rios" },
    { name: "Ermo", parent: "SC", value: "Ermo" },
    { name: "Erval Velho", parent: "SC", value: "Erval Velho" },
    { name: "Faxinal dos Guedes", parent: "SC", value: "Faxinal dos Guedes" },
    { name: "Flor do Sertao", parent: "SC", value: "Flor do Sertao" },
    { name: "Florianopolis", parent: "SC", value: "Florianopolis" },
    { name: "Formosa do Sul", parent: "SC", value: "Formosa do Sul" },
    { name: "Forquilhinha", parent: "SC", value: "Forquilhinha" },
    { name: "Fraiburgo", parent: "SC", value: "Fraiburgo" },
    { name: "Frei Rogerio", parent: "SC", value: "Frei Rogerio" },
    { name: "Galvao", parent: "SC", value: "Galvao" },
    { name: "Garopaba", parent: "SC", value: "Garopaba" },
    { name: "Garuva", parent: "SC", value: "Garuva" },
    { name: "Gaspar", parent: "SC", value: "Gaspar" },
    {
      name: "Governador Celso Ramos",
      parent: "SC",
      value: "Governador Celso Ramos",
    },
    { name: "Grao Para", parent: "SC", value: "Grao Para" },
    { name: "Gravatal", parent: "SC", value: "Gravatal" },
    { name: "Guabiruba", parent: "SC", value: "Guabiruba" },
    { name: "Guaraciaba", parent: "SC", value: "Guaraciaba" },
    { name: "Guaramirim", parent: "SC", value: "Guaramirim" },
    { name: "Guaruja do Sul", parent: "SC", value: "Guaruja do Sul" },
    { name: "Guatambu", parent: "SC", value: "Guatambu" },
    { name: "Herval d'Oeste", parent: "SC", value: "Herval d'Oeste" },
    { name: "Ibiam", parent: "SC", value: "Ibiam" },
    { name: "Ibicare", parent: "SC", value: "Ibicare" },
    { name: "Ibirama", parent: "SC", value: "Ibirama" },
    { name: "Icara", parent: "SC", value: "Icara" },
    { name: "Ilhota", parent: "SC", value: "Ilhota" },
    { name: "Imarui", parent: "SC", value: "Imarui" },
    { name: "Imbituba", parent: "SC", value: "Imbituba" },
    { name: "Imbuia", parent: "SC", value: "Imbuia" },
    { name: "Indaial", parent: "SC", value: "Indaial" },
    { name: "Iomere", parent: "SC", value: "Iomere" },
    { name: "Ipira", parent: "SC", value: "Ipira" },
    { name: "Ipora do Oeste", parent: "SC", value: "Ipora do Oeste" },
    { name: "Ipuacu", parent: "SC", value: "Ipuacu" },
    { name: "Ipumirim", parent: "SC", value: "Ipumirim" },
    { name: "Iraceminha", parent: "SC", value: "Iraceminha" },
    { name: "Irani", parent: "SC", value: "Irani" },
    { name: "Irati", parent: "SC", value: "Irati" },
    { name: "Irineopolis", parent: "SC", value: "Irineopolis" },
    { name: "Ita", parent: "SC", value: "Ita" },
    { name: "Itaiopolis", parent: "SC", value: "Itaiopolis" },
    { name: "Itajai", parent: "SC", value: "Itajai" },
    { name: "Itapema", parent: "SC", value: "Itapema" },
    { name: "Itapiranga", parent: "SC", value: "Itapiranga" },
    { name: "Itapoa", parent: "SC", value: "Itapoa" },
    { name: "Ituporanga", parent: "SC", value: "Ituporanga" },
    { name: "Jabora", parent: "SC", value: "Jabora" },
    { name: "Jacinto Machado", parent: "SC", value: "Jacinto Machado" },
    { name: "Jaguaruna", parent: "SC", value: "Jaguaruna" },
    { name: "Jaragua do Sul", parent: "SC", value: "Jaragua do Sul" },
    { name: "Jardinopolis", parent: "SC", value: "Jardinopolis" },
    { name: "Joacaba", parent: "SC", value: "Joacaba" },
    { name: "Joinville", parent: "SC", value: "Joinville" },
    { name: "Jose Boiteux", parent: "SC", value: "Jose Boiteux" },
    { name: "Jupia", parent: "SC", value: "Jupia" },
    { name: "Lacerdopolis", parent: "SC", value: "Lacerdopolis" },
    { name: "Lages", parent: "SC", value: "Lages" },
    { name: "Laguna", parent: "SC", value: "Laguna" },
    { name: "Lajeado Grande", parent: "SC", value: "Lajeado Grande" },
    { name: "Laurentino", parent: "SC", value: "Laurentino" },
    { name: "Lauro Muller", parent: "SC", value: "Lauro Muller" },
    { name: "Lebon Regis", parent: "SC", value: "Lebon Regis" },
    { name: "Leoberto Leal", parent: "SC", value: "Leoberto Leal" },
    { name: "Lindoia do Sul", parent: "SC", value: "Lindoia do Sul" },
    { name: "Lontras", parent: "SC", value: "Lontras" },
    { name: "Luiz Alves", parent: "SC", value: "Luiz Alves" },
    { name: "Luzerna", parent: "SC", value: "Luzerna" },
    { name: "Macieira", parent: "SC", value: "Macieira" },
    { name: "Mafra", parent: "SC", value: "Mafra" },
    { name: "Major Gercino", parent: "SC", value: "Major Gercino" },
    { name: "Major Vieira", parent: "SC", value: "Major Vieira" },
    { name: "Maracaja", parent: "SC", value: "Maracaja" },
    { name: "Maravilha", parent: "SC", value: "Maravilha" },
    { name: "Marema", parent: "SC", value: "Marema" },
    { name: "Massaranduba", parent: "SC", value: "Massaranduba" },
    { name: "Matos Costa", parent: "SC", value: "Matos Costa" },
    { name: "Meleiro", parent: "SC", value: "Meleiro" },
    { name: "Mirim Doce", parent: "SC", value: "Mirim Doce" },
    { name: "Modelo", parent: "SC", value: "Modelo" },
    { name: "Mondai", parent: "SC", value: "Mondai" },
    { name: "Monte Carlo", parent: "SC", value: "Monte Carlo" },
    { name: "Monte Castelo", parent: "SC", value: "Monte Castelo" },
    { name: "Morro Grande", parent: "SC", value: "Morro Grande" },
    { name: "Morro da Fumaca", parent: "SC", value: "Morro da Fumaca" },
    { name: "Navegantes", parent: "SC", value: "Navegantes" },
    { name: "Nova Erechim", parent: "SC", value: "Nova Erechim" },
    { name: "Nova Itaberaba", parent: "SC", value: "Nova Itaberaba" },
    { name: "Nova Trento", parent: "SC", value: "Nova Trento" },
    { name: "Nova Veneza", parent: "SC", value: "Nova Veneza" },
    { name: "Novo Horizonte", parent: "SC", value: "Novo Horizonte" },
    { name: "Orleans", parent: "SC", value: "Orleans" },
    { name: "Otacilio Costa", parent: "SC", value: "Otacilio Costa" },
    { name: "Ouro", parent: "SC", value: "Ouro" },
    { name: "Ouro Verde", parent: "SC", value: "Ouro Verde" },
    { name: "Paial", parent: "SC", value: "Paial" },
    { name: "Painel", parent: "SC", value: "Painel" },
    { name: "Palhoca", parent: "SC", value: "Palhoca" },
    { name: "Palma Sola", parent: "SC", value: "Palma Sola" },
    { name: "Palmeira", parent: "SC", value: "Palmeira" },
    { name: "Palmitos", parent: "SC", value: "Palmitos" },
    { name: "Papanduva", parent: "SC", value: "Papanduva" },
    { name: "Paraiso", parent: "SC", value: "Paraiso" },
    { name: "Passo de Torres", parent: "SC", value: "Passo de Torres" },
    { name: "Passos Maia", parent: "SC", value: "Passos Maia" },
    { name: "Paulo Lopes", parent: "SC", value: "Paulo Lopes" },
    { name: "Pedras Grandes", parent: "SC", value: "Pedras Grandes" },
    { name: "Penha", parent: "SC", value: "Penha" },
    { name: "Peritiba", parent: "SC", value: "Peritiba" },
    { name: "Petrolandia", parent: "SC", value: "Petrolandia" },
    { name: "Picarras", parent: "SC", value: "Picarras" },
    { name: "Pinhalzinho", parent: "SC", value: "Pinhalzinho" },
    { name: "Pinheiro Preto", parent: "SC", value: "Pinheiro Preto" },
    { name: "Piratuba", parent: "SC", value: "Piratuba" },
    { name: "Planalto Alegre", parent: "SC", value: "Planalto Alegre" },
    { name: "Pomerode", parent: "SC", value: "Pomerode" },
    { name: "Ponte Alta", parent: "SC", value: "Ponte Alta" },
    { name: "Ponte Alta do Norte", parent: "SC", value: "Ponte Alta do Norte" },
    { name: "Ponte Serrada", parent: "SC", value: "Ponte Serrada" },
    { name: "Porto Belo", parent: "SC", value: "Porto Belo" },
    { name: "Porto Uniao", parent: "SC", value: "Porto Uniao" },
    { name: "Pouso Redondo", parent: "SC", value: "Pouso Redondo" },
    { name: "Praia Grande", parent: "SC", value: "Praia Grande" },
    {
      name: "Presidente Castelo Branco",
      parent: "SC",
      value: "Presidente Castelo Branco",
    },
    { name: "Presidente Getulio", parent: "SC", value: "Presidente Getulio" },
    { name: "Presidente Nereu", parent: "SC", value: "Presidente Nereu" },
    { name: "Princesa", parent: "SC", value: "Princesa" },
    { name: "Quilombo", parent: "SC", value: "Quilombo" },
    { name: "Rancho Queimado", parent: "SC", value: "Rancho Queimado" },
    { name: "Rio Fortuna", parent: "SC", value: "Rio Fortuna" },
    { name: "Rio Negrinho", parent: "SC", value: "Rio Negrinho" },
    { name: "Rio Rufino", parent: "SC", value: "Rio Rufino" },
    { name: "Rio d'Oeste", parent: "SC", value: "Rio d'Oeste" },
    { name: "Rio das Antas", parent: "SC", value: "Rio das Antas" },
    { name: "Rio do Campo", parent: "SC", value: "Rio do Campo" },
    { name: "Rio do Sul", parent: "SC", value: "Rio do Sul" },
    { name: "Rio dos Cedros", parent: "SC", value: "Rio dos Cedros" },
    { name: "Riqueza", parent: "SC", value: "Riqueza" },
    { name: "Rodeio", parent: "SC", value: "Rodeio" },
    { name: "Romelandia", parent: "SC", value: "Romelandia" },
    { name: "Salete", parent: "SC", value: "Salete" },
    { name: "Saltinho", parent: "SC", value: "Saltinho" },
    { name: "Salto Veloso", parent: "SC", value: "Salto Veloso" },
    { name: "Sangao", parent: "SC", value: "Sangao" },
    { name: "Santa Cecilia", parent: "SC", value: "Santa Cecilia" },
    { name: "Santa Helena", parent: "SC", value: "Santa Helena" },
    { name: "Santa Rosa de Lima", parent: "SC", value: "Santa Rosa de Lima" },
    { name: "Santa Rosa do Sul", parent: "SC", value: "Santa Rosa do Sul" },
    { name: "Santa Terezinha", parent: "SC", value: "Santa Terezinha" },
    {
      name: "Santa Terezinha do Progresso",
      parent: "SC",
      value: "Santa Terezinha do Progresso",
    },
    { name: "Santiago do Sul", parent: "SC", value: "Santiago do Sul" },
    {
      name: "Santo Amaro da Imperatriz",
      parent: "SC",
      value: "Santo Amaro da Imperatriz",
    },
    { name: "Sao Bento do Sul", parent: "SC", value: "Sao Bento do Sul" },
    { name: "Sao Bernardino", parent: "SC", value: "Sao Bernardino" },
    { name: "Sao Bonifacio", parent: "SC", value: "Sao Bonifacio" },
    { name: "Sao Carlos", parent: "SC", value: "Sao Carlos" },
    {
      name: "Sao Cristovao do Sul",
      parent: "SC",
      value: "Sao Cristovao do Sul",
    },
    { name: "Sao Domingos", parent: "SC", value: "Sao Domingos" },
    {
      name: "Sao Francisco do Sul",
      parent: "SC",
      value: "Sao Francisco do Sul",
    },
    { name: "Sao Joao Batista", parent: "SC", value: "Sao Joao Batista" },
    {
      name: "Sao Joao do Itaperiu",
      parent: "SC",
      value: "Sao Joao do Itaperiu",
    },
    { name: "Sao Joao do Oeste", parent: "SC", value: "Sao Joao do Oeste" },
    { name: "Sao Joao do Sul", parent: "SC", value: "Sao Joao do Sul" },
    { name: "Sao Joaquim", parent: "SC", value: "Sao Joaquim" },
    { name: "Sao Jose", parent: "SC", value: "Sao Jose" },
    { name: "Sao Jose do Cedro", parent: "SC", value: "Sao Jose do Cedro" },
    { name: "Sao Jose do Cerrito", parent: "SC", value: "Sao Jose do Cerrito" },
    {
      name: "Sao Lourenco d'Oeste",
      parent: "SC",
      value: "Sao Lourenco d'Oeste",
    },
    { name: "Sao Ludgero", parent: "SC", value: "Sao Ludgero" },
    { name: "Sao Martinho", parent: "SC", value: "Sao Martinho" },
    { name: "Sao Miguel d'Oeste", parent: "SC", value: "Sao Miguel d'Oeste" },
    {
      name: "Sao Miguel da Boa Vista",
      parent: "SC",
      value: "Sao Miguel da Boa Vista",
    },
    {
      name: "Sao Pedro de Alcantara",
      parent: "SC",
      value: "Sao Pedro de Alcantara",
    },
    { name: "Saudades", parent: "SC", value: "Saudades" },
    { name: "Schroeder", parent: "SC", value: "Schroeder" },
    { name: "Seara", parent: "SC", value: "Seara" },
    { name: "Serra Alta", parent: "SC", value: "Serra Alta" },
    { name: "Sideropolis", parent: "SC", value: "Sideropolis" },
    { name: "Sombrio", parent: "SC", value: "Sombrio" },
    { name: "Sul Brasil", parent: "SC", value: "Sul Brasil" },
    { name: "Taio", parent: "SC", value: "Taio" },
    { name: "Tangara", parent: "SC", value: "Tangara" },
    { name: "Tigrinhos", parent: "SC", value: "Tigrinhos" },
    { name: "Tijucas", parent: "SC", value: "Tijucas" },
    { name: "Timbe do Sul", parent: "SC", value: "Timbe do Sul" },
    { name: "Timbo", parent: "SC", value: "Timbo" },
    { name: "Timbo Grande", parent: "SC", value: "Timbo Grande" },
    { name: "Tres Barras", parent: "SC", value: "Tres Barras" },
    { name: "Treviso", parent: "SC", value: "Treviso" },
    { name: "Treze Tilias", parent: "SC", value: "Treze Tilias" },
    { name: "Treze de Maio", parent: "SC", value: "Treze de Maio" },
    { name: "Trombudo Central", parent: "SC", value: "Trombudo Central" },
    { name: "Tubarao", parent: "SC", value: "Tubarao" },
    { name: "Tunapolis", parent: "SC", value: "Tunapolis" },
    { name: "Turvo", parent: "SC", value: "Turvo" },
    { name: "Uniao do Oeste", parent: "SC", value: "Uniao do Oeste" },
    { name: "Urubici", parent: "SC", value: "Urubici" },
    { name: "Urupema", parent: "SC", value: "Urupema" },
    { name: "Urussanga", parent: "SC", value: "Urussanga" },
    { name: "Vargeao", parent: "SC", value: "Vargeao" },
    { name: "Vargem", parent: "SC", value: "Vargem" },
    { name: "Vargem Bonita", parent: "SC", value: "Vargem Bonita" },
    { name: "Vidal Ramos", parent: "SC", value: "Vidal Ramos" },
    { name: "Videira", parent: "SC", value: "Videira" },
    { name: "Vitor Meireles", parent: "SC", value: "Vitor Meireles" },
    { name: "Witmarsum", parent: "SC", value: "Witmarsum" },
    { name: "Xanxere", parent: "SC", value: "Xanxere" },
    { name: "Xavantina", parent: "SC", value: "Xavantina" },
    { name: "Xaxim", parent: "SC", value: "Xaxim" },
    { name: "Zortea", parent: "SC", value: "Zortea" },

    {
      name: "Amparo de Sao Francisco",
      parent: "SE",
      value: "Amparo de Sao Francisco",
    },
    { name: "Aquidaba", parent: "SE", value: "Aquidaba" },
    { name: "Aracaju", parent: "SE", value: "Aracaju" },
    { name: "Araua", parent: "SE", value: "Araua" },
    { name: "Areia Branca", parent: "SE", value: "Areia Branca" },
    { name: "Barra dos Coqueiros", parent: "SE", value: "Barra dos Coqueiros" },
    { name: "Boquim", parent: "SE", value: "Boquim" },
    { name: "Brejo Grande", parent: "SE", value: "Brejo Grande" },
    { name: "Campo do Brito", parent: "SE", value: "Campo do Brito" },
    { name: "Canhoba", parent: "SE", value: "Canhoba" },
    {
      name: "Caninde de Sao Francisco",
      parent: "SE",
      value: "Caninde de Sao Francisco",
    },
    { name: "Capela", parent: "SE", value: "Capela" },
    { name: "Carira", parent: "SE", value: "Carira" },
    { name: "Carmopolis", parent: "SE", value: "Carmopolis" },
    { name: "Cedro de Sao Joao", parent: "SE", value: "Cedro de Sao Joao" },
    { name: "Cristinapolis", parent: "SE", value: "Cristinapolis" },
    { name: "Cumbe", parent: "SE", value: "Cumbe" },
    { name: "Divina Pastora", parent: "SE", value: "Divina Pastora" },
    { name: "Estancia", parent: "SE", value: "Estancia" },
    { name: "Feira Nova", parent: "SE", value: "Feira Nova" },
    { name: "Frei Paulo", parent: "SE", value: "Frei Paulo" },
    { name: "Gararu", parent: "SE", value: "Gararu" },
    { name: "General Maynard", parent: "SE", value: "General Maynard" },
    { name: "Gracho Cardoso", parent: "SE", value: "Gracho Cardoso" },
    { name: "Ilha das Flores", parent: "SE", value: "Ilha das Flores" },
    { name: "Indiaroba", parent: "SE", value: "Indiaroba" },
    { name: "Itabaiana", parent: "SE", value: "Itabaiana" },
    { name: "Itabaianinha", parent: "SE", value: "Itabaianinha" },
    { name: "Itabi", parent: "SE", value: "Itabi" },
    { name: "Itaporanga d'Ajuda", parent: "SE", value: "Itaporanga d'Ajuda" },
    { name: "Japaratuba", parent: "SE", value: "Japaratuba" },
    { name: "Japoata", parent: "SE", value: "Japoata" },
    { name: "Lagarto", parent: "SE", value: "Lagarto" },
    { name: "Laranjeiras", parent: "SE", value: "Laranjeiras" },
    { name: "Macambira", parent: "SE", value: "Macambira" },
    { name: "Malhada dos Bois", parent: "SE", value: "Malhada dos Bois" },
    { name: "Malhador", parent: "SE", value: "Malhador" },
    { name: "Maruim", parent: "SE", value: "Maruim" },
    { name: "Moita Bonita", parent: "SE", value: "Moita Bonita" },
    {
      name: "Monte Alegre de Sergipe",
      parent: "SE",
      value: "Monte Alegre de Sergipe",
    },
    { name: "Muribeca", parent: "SE", value: "Muribeca" },
    { name: "Neopolis", parent: "SE", value: "Neopolis" },
    {
      name: "Nossa Senhora Aparecida",
      parent: "SE",
      value: "Nossa Senhora Aparecida",
    },
    {
      name: "Nossa Senhora da Gloria",
      parent: "SE",
      value: "Nossa Senhora da Gloria",
    },
    {
      name: "Nossa Senhora das Dores",
      parent: "SE",
      value: "Nossa Senhora das Dores",
    },
    {
      name: "Nossa Senhora de Lourdes",
      parent: "SE",
      value: "Nossa Senhora de Lourdes",
    },
    {
      name: "Nossa Senhora do Socorro",
      parent: "SE",
      value: "Nossa Senhora do Socorro",
    },
    { name: "Pacatuba", parent: "SE", value: "Pacatuba" },
    { name: "Pedra Mole", parent: "SE", value: "Pedra Mole" },
    { name: "Pedrinhas", parent: "SE", value: "Pedrinhas" },
    { name: "Pinhao", parent: "SE", value: "Pinhao" },
    { name: "Pirambu", parent: "SE", value: "Pirambu" },
    { name: "Poco Redondo", parent: "SE", value: "Poco Redondo" },
    { name: "Poco Verde", parent: "SE", value: "Poco Verde" },
    { name: "Porto da Folha", parent: "SE", value: "Porto da Folha" },
    { name: "Propria", parent: "SE", value: "Propria" },
    { name: "Riachao do Dantas", parent: "SE", value: "Riachao do Dantas" },
    { name: "Riachuelo", parent: "SE", value: "Riachuelo" },
    { name: "Ribeiropolis", parent: "SE", value: "Ribeiropolis" },
    { name: "Rosario do Catete", parent: "SE", value: "Rosario do Catete" },
    { name: "Salgado", parent: "SE", value: "Salgado" },
    {
      name: "Santa Luzia do Itanhy",
      parent: "SE",
      value: "Santa Luzia do Itanhy",
    },
    { name: "Santa Rosa de Lima", parent: "SE", value: "Santa Rosa de Lima" },
    {
      name: "Santana do Sao Francisco",
      parent: "SE",
      value: "Santana do Sao Francisco",
    },
    {
      name: "Santo Amaro das Brotas",
      parent: "SE",
      value: "Santo Amaro das Brotas",
    },
    { name: "Sao Cristovao", parent: "SE", value: "Sao Cristovao" },
    { name: "Sao Domingos", parent: "SE", value: "Sao Domingos" },
    { name: "Sao Francisco", parent: "SE", value: "Sao Francisco" },
    {
      name: "Sao Miguel do Aleixo",
      parent: "SE",
      value: "Sao Miguel do Aleixo",
    },
    { name: "Simao Dias", parent: "SE", value: "Simao Dias" },
    { name: "Siriri", parent: "SE", value: "Siriri" },
    { name: "Telha", parent: "SE", value: "Telha" },
    { name: "Tobias Barreto", parent: "SE", value: "Tobias Barreto" },
    { name: "Tomar do Geru", parent: "SE", value: "Tomar do Geru" },
    { name: "Umbauba", parent: "SE", value: "Umbauba" },

    { name: "  Americana", parent: "SP", value: "  Americana" },
    { name: "  Araraquara", parent: "SP", value: "  Araraquara" },
    { name: "  Araras", parent: "SP", value: "  Araras" },
    { name: "  Araçatuba", parent: "SP", value: "  Aracatuba" },
    { name: "  Assis", parent: "SP", value: "  Assis" },
    { name: "  Atibaia", parent: "SP", value: "  Atibaia" },
    { name: "  Bady Bassitt", parent: "SP", value: "  Bady Bassitt" },
    { name: "  Barretos", parent: "SP", value: "  Barretos" },
    { name: "  Barueri", parent: "SP", value: "  Barueri" },
    { name: "  Bauru", parent: "SP", value: "  Bauru" },
    { name: "  Birigui", parent: "SP", value: "  Birigui" },
    { name: "  Borá", parent: "SP", value: "  Bora" },
    { name: "  Botucatu", parent: "SP", value: "  Botucatu" },
    { name: "  Bragança Paulista", parent: "SP", value: "  Braganca Paulista" },
    { name: "  Campinas", parent: "SP", value: "  Campinas" },
    { name: "  Caraguatatuba", parent: "SP", value: "  Caraguatatuba" },
    { name: "  Carapicuíba", parent: "SP", value: "  Carapicuiba" },
    { name: "  Catanduva", parent: "SP", value: "  Catanduva" },
    { name: "  Cotia", parent: "SP", value: "  Cotia" },
    { name: "  Cubatão", parent: "SP", value: "  Cubatao" },
    { name: "  Diadema", parent: "SP", value: "  Diadema" },
    { name: "  Echaporã", parent: "SP", value: "  Echapora" },
    { name: "  Eldorado", parent: "SP", value: "  Eldorado" },
    { name: "  Embu das Artes", parent: "SP", value: "  Embu das Artes" },
    {
      name: "  Ferraz de Vasconcelos",
      parent: "SP",
      value: "  Ferraz de Vasconcelos",
    },
    { name: "  Franca", parent: "SP", value: "  Franca" },
    { name: "  Francisco Morato", parent: "SP", value: "  Francisco Morato" },
    { name: "  Franco da Rocha", parent: "SP", value: "  Franco da Rocha" },
    { name: "  Guaratinguetá", parent: "SP", value: "  Guaratingueta" },
    { name: "  Guarulhos", parent: "SP", value: "  Guarulhos" },
    { name: "  Hortolândia", parent: "SP", value: "  Hortolandia" },
    { name: "  Indaiatuba", parent: "SP", value: "  Indaiatuba" },
    {
      name: "  Itapecerica da Serra",
      parent: "SP",
      value: "  Itapecerica da Serra",
    },
    { name: "  Itapetininga", parent: "SP", value: "  Itapetininga" },
    { name: "  Itapevi", parent: "SP", value: "  Itapevi" },
    { name: "  Itaquaquecetuba", parent: "SP", value: "  Itaquaquecetuba" },
    { name: "  Itatiba", parent: "SP", value: "  Itatiba" },
    { name: "  Itu", parent: "SP", value: "  Itu" },
    { name: "  Jacareí", parent: "SP", value: "  Jacarei" },
    { name: "  Jandira", parent: "SP", value: "  Jandira" },
    { name: "  Jaú", parent: "SP", value: "  Jau" },
    { name: "  Jundiaí", parent: "SP", value: "  Jundiai" },
    { name: "  Limeira", parent: "SP", value: "  Limeira" },
    { name: "  Marília", parent: "SP", value: "  Marilia" },
    { name: "  Mauá", parent: "SP", value: "  Maua" },
    { name: "  Mogi Guaçu", parent: "SP", value: "  Mogi Guacu" },
    { name: "  Mogi das Cruzes", parent: "SP", value: "  Mogi das Cruzes" },
    { name: "  Nova Castilho", parent: "SP", value: "  Nova Castilho" },
    { name: "  Osasco", parent: "SP", value: "  Osasco" },
    { name: "  Ourinhos", parent: "SP", value: "  Ourinhos" },
    { name: "  Ouroeste", parent: "SP", value: "  Ouroeste" },
    { name: "  Panorama", parent: "SP", value: "  Panorama" },
    { name: "  Pindamonhangaba", parent: "SP", value: "  Pindamonhangaba" },
    { name: "  Piracicaba", parent: "SP", value: "  Piracicaba" },
    { name: "  Poá", parent: "SP", value: "  Poa" },
    { name: "  Praia Grande", parent: "SP", value: "  Praia Grande" },
    {
      name: "  Presidente Prudente",
      parent: "SP",
      value: "  Presidente Prudente",
    },
    { name: "  Ribeirão Pires", parent: "SP", value: "  Ribeirao Pires" },
    { name: "  Ribeirão Preto", parent: "SP", value: "  Ribeirao Preto" },
    { name: "  Rio Claro", parent: "SP", value: "  Rio Claro" },
    { name: "  Salto", parent: "SP", value: "  Salto" },
    {
      name: "  Santa Bárbara do Oeste",
      parent: "SP",
      value: "  Santa Barbara do Oeste",
    },
    { name: "  Santa Salete", parent: "SP", value: "  Santa Salete" },
    {
      name: "  Santana de Parnaíba",
      parent: "SP",
      value: "  Santana de Parnaiba",
    },
    { name: "  Santo André", parent: "SP", value: "  Santo Andre" },
    { name: "  Santos", parent: "SP", value: "  Santos" },
    { name: "  Sertãozinho", parent: "SP", value: "  Sertaozinho" },
    { name: "  Sorocaba", parent: "SP", value: "  Sorocaba" },
    { name: "  Sumaré", parent: "SP", value: "  Sumare" },
    { name: "  Suzano", parent: "SP", value: "  Suzano" },
    {
      name: "  São Bernardo do Campo",
      parent: "SP",
      value: "  Sao Bernardo do Campo",
    },
    {
      name: "  São Caetano do Sul",
      parent: "SP",
      value: "  Sao Caetano do Sul",
    },
    { name: "  São Carlos", parent: "SP", value: "  Sao Carlos" },
    {
      name: "  São José do Rio Preto",
      parent: "SP",
      value: "  Sao Jose do Rio Preto",
    },
    {
      name: "  São José dos Campos",
      parent: "SP",
      value: "  Sao Jose dos Campos",
    },
    { name: "  São Vicente", parent: "SP", value: "  Sao Vicente" },
    { name: "  Taboão da Serra", parent: "SP", value: "  Taboao da Serra" },
    { name: "  Tatuí", parent: "SP", value: "  Tatui" },
    { name: "  Taubaté", parent: "SP", value: "  Taubate" },
    { name: "  Uru", parent: "SP", value: "  Uru" },
    { name: "  Valinhos", parent: "SP", value: "  Valinhos" },
    { name: "  Votorantim", parent: "SP", value: "  Votorantim" },
    { name: "  Várzea Paulista", parent: "SP", value: "  Varzea Paulista" },
    { name: "Adamantina", parent: "SP", value: "Adamantina" },
    { name: "Adolfo", parent: "SP", value: "Adolfo" },
    { name: "Aguaí", parent: "SP", value: "Aguai" },
    { name: "Agudos", parent: "SP", value: "Agudos" },
    { name: "Alambari", parent: "SP", value: "Alambari" },
    { name: "Alfredo Marcondes", parent: "SP", value: "Alfredo Marcondes" },
    { name: "Altair", parent: "SP", value: "Altair" },
    { name: "Altinópolis", parent: "SP", value: "Altinopolis" },
    { name: "Alto Alegre", parent: "SP", value: "Alto Alegre" },
    { name: "Alumínio", parent: "SP", value: "Aluminio" },
    { name: "Alvinlândia", parent: "SP", value: "Alvinlandia" },
    { name: "Amparo", parent: "SP", value: "Amparo" },
    { name: "Américo Brasiliense", parent: "SP", value: "Americo Brasiliense" },
    { name: "Américo de Campos", parent: "SP", value: "Americo de Campos" },
    { name: "Analândia", parent: "SP", value: "Analandia" },
    { name: "Andradina", parent: "SP", value: "Andradina" },
    { name: "Angatuba", parent: "SP", value: "Angatuba" },
    { name: "Anhembi", parent: "SP", value: "Anhembi" },
    { name: "Anhumas", parent: "SP", value: "Anhumas" },
    { name: "Aparecida", parent: "SP", value: "Aparecida" },
    { name: "Aparecida d'Oeste", parent: "SP", value: "Aparecida d'Oeste" },
    { name: "Apiaí", parent: "SP", value: "Apiai" },
    { name: "Aramina", parent: "SP", value: "Aramina" },
    { name: "Arandu", parent: "SP", value: "Arandu" },
    { name: "Arapeí", parent: "SP", value: "Arapei" },
    { name: "Araçariguama", parent: "SP", value: "Aracariguama" },
    { name: "Araçoiaba da Serra", parent: "SP", value: "Aracoiaba da Serra" },
    { name: "Arco-Íris", parent: "SP", value: "Arco-Iris" },
    { name: "Arealva", parent: "SP", value: "Arealva" },
    { name: "Areias", parent: "SP", value: "Areias" },
    { name: "Areiópolis", parent: "SP", value: "Areiopolis" },
    { name: "Ariranha", parent: "SP", value: "Ariranha" },
    { name: "Artur Nogueira", parent: "SP", value: "Artur Nogueira" },
    { name: "Arujá", parent: "SP", value: "Aruja" },
    { name: "Aspásia", parent: "SP", value: "Aspasia" },
    { name: "Auriflama", parent: "SP", value: "Auriflama" },
    { name: "Avanhandava", parent: "SP", value: "Avanhandava" },
    { name: "Avaré", parent: "SP", value: "Avare" },
    { name: "Avaí", parent: "SP", value: "Avai" },
    { name: "Balbinos", parent: "SP", value: "Balbinos" },
    { name: "Bananal", parent: "SP", value: "Bananal" },
    { name: "Barbosa", parent: "SP", value: "Barbosa" },
    { name: "Bariri", parent: "SP", value: "Bariri" },
    { name: "Barra Bonita", parent: "SP", value: "Barra Bonita" },
    { name: "Barra do Chapéu", parent: "SP", value: "Barra do Chapeu" },
    { name: "Barra do Turvo", parent: "SP", value: "Barra do Turvo" },
    { name: "Barrinha", parent: "SP", value: "Barrinha" },
    { name: "Barão de Antonina", parent: "SP", value: "Barao de Antonina" },
    { name: "Bastos", parent: "SP", value: "Bastos" },
    { name: "Batatais", parent: "SP", value: "Batatais" },
    { name: "Bebedouro", parent: "SP", value: "Bebedouro" },
    { name: "Bento de Abreu", parent: "SP", value: "Bento de Abreu" },
    {
      name: "Bernardino de Campos",
      parent: "SP",
      value: "Bernardino de Campos",
    },
    { name: "Bertioga", parent: "SP", value: "Bertioga" },
    { name: "Bilac", parent: "SP", value: "Bilac" },
    { name: "Biritiba Mirim", parent: "SP", value: "Biritiba Mirim" },
    {
      name: "Boa Esperança do Sul",
      parent: "SP",
      value: "Boa Esperanca do Sul",
    },
    { name: "Bocaina", parent: "SP", value: "Bocaina" },
    { name: "Bofete", parent: "SP", value: "Bofete" },
    { name: "Boituva", parent: "SP", value: "Boituva" },
    {
      name: "Bom Jesus dos Perdões",
      parent: "SP",
      value: "Bom Jesus dos Perdoes",
    },
    {
      name: "Bom Sucesso de Itararé",
      parent: "SP",
      value: "Bom Sucesso de Itarare",
    },
    { name: "Boracéia", parent: "SP", value: "Boraceia" },
    { name: "Borborema", parent: "SP", value: "Borborema" },
    { name: "Borebi", parent: "SP", value: "Borebi" },
    { name: "Braúna", parent: "SP", value: "Brauna" },
    { name: "Brejo Alegre", parent: "SP", value: "Brejo Alegre" },
    { name: "Brodowski", parent: "SP", value: "Brodowski" },
    { name: "Brotas", parent: "SP", value: "Brotas" },
    { name: "Buri", parent: "SP", value: "Buri" },
    { name: "Buritama", parent: "SP", value: "Buritama" },
    { name: "Buritizal", parent: "SP", value: "Buritizal" },
    { name: "Bálsamo", parent: "SP", value: "Balsamo" },
    { name: "Cabreúva", parent: "SP", value: "Cabreuva" },
    { name: "Cabrália Paulista", parent: "SP", value: "Cabralia Paulista" },
    { name: "Cachoeira Paulista", parent: "SP", value: "Cachoeira Paulista" },
    { name: "Caconde", parent: "SP", value: "Caconde" },
    { name: "Cafelândia", parent: "SP", value: "Cafelandia" },
    { name: "Caiabu", parent: "SP", value: "Caiabu" },
    { name: "Caieiras", parent: "SP", value: "Caieiras" },
    { name: "Caiuá", parent: "SP", value: "Caiua" },
    { name: "Cajamar", parent: "SP", value: "Cajamar" },
    { name: "Cajati", parent: "SP", value: "Cajati" },
    { name: "Cajobi", parent: "SP", value: "Cajobi" },
    { name: "Cajuru", parent: "SP", value: "Cajuru" },
    {
      name: "Campina do Monte Alegre",
      parent: "SP",
      value: "Campina do Monte Alegre",
    },
    {
      name: "Campo Limpo Paulista",
      parent: "SP",
      value: "Campo Limpo Paulista",
    },
    {
      name: "Campos Novos Paulista",
      parent: "SP",
      value: "Campos Novos Paulista",
    },
    { name: "Campos do Jordão", parent: "SP", value: "Campos do Jordao" },
    { name: "Cananéia", parent: "SP", value: "Cananeia" },
    { name: "Canas", parent: "SP", value: "Canas" },
    { name: "Canitar", parent: "SP", value: "Canitar" },
    { name: "Capela do Alto", parent: "SP", value: "Capela do Alto" },
    { name: "Capivari", parent: "SP", value: "Capivari" },
    { name: "Capão Bonito", parent: "SP", value: "Capao Bonito" },
    { name: "Cardoso", parent: "SP", value: "Cardoso" },
    { name: "Casa Branca", parent: "SP", value: "Casa Branca" },
    { name: "Castilho", parent: "SP", value: "Castilho" },
    { name: "Catiguá", parent: "SP", value: "Catigua" },
    { name: "Caçapava", parent: "SP", value: "Cacapava" },
    { name: "Cedral", parent: "SP", value: "Cedral" },
    { name: "Cerqueira César", parent: "SP", value: "Cerqueira Cesar" },
    { name: "Cerquilho", parent: "SP", value: "Cerquilho" },
    { name: "Cesário Lange", parent: "SP", value: "Cesario Lange" },
    { name: "Charqueada", parent: "SP", value: "Charqueada" },
    { name: "Chavantes", parent: "SP", value: "Chavantes" },
    { name: "Clementina", parent: "SP", value: "Clementina" },
    { name: "Colina", parent: "SP", value: "Colina" },
    { name: "Colômbia", parent: "SP", value: "Colombia" },
    { name: "Conchal", parent: "SP", value: "Conchal" },
    { name: "Conchas", parent: "SP", value: "Conchas" },
    { name: "Cordeirópolis", parent: "SP", value: "Cordeiropolis" },
    { name: "Coroados", parent: "SP", value: "Coroados" },
    { name: "Coronel Macedo", parent: "SP", value: "Coronel Macedo" },
    { name: "Corumbataí", parent: "SP", value: "Corumbatai" },
    { name: "Cosmorama", parent: "SP", value: "Cosmorama" },
    { name: "Cosmópolis", parent: "SP", value: "Cosmopolis" },
    { name: "Cravinhos", parent: "SP", value: "Cravinhos" },
    { name: "Cristais Paulista", parent: "SP", value: "Cristais Paulista" },
    { name: "Cruzeiro", parent: "SP", value: "Cruzeiro" },
    { name: "Cruzália", parent: "SP", value: "Cruzalia" },
    { name: "Cunha", parent: "SP", value: "Cunha" },
    {
      name: "Cássia dos Coqueiros",
      parent: "SP",
      value: "Cassia dos Coqueiros",
    },
    { name: "Cândido Mota", parent: "SP", value: "Candido Mota" },
    { name: "Cândido Rodrigues", parent: "SP", value: "Candido Rodrigues" },
    { name: "Descalvado", parent: "SP", value: "Descalvado" },
    { name: "Dirce Reis", parent: "SP", value: "Dirce Reis" },
    { name: "Divinolândia", parent: "SP", value: "Divinolandia" },
    { name: "Dobrada", parent: "SP", value: "Dobrada" },
    { name: "Dois Córregos", parent: "SP", value: "Dois Corregos" },
    { name: "Dolcinópolis", parent: "SP", value: "Dolcinopolis" },
    { name: "Dourado", parent: "SP", value: "Dourado" },
    { name: "Dracena", parent: "SP", value: "Dracena" },
    { name: "Duartina", parent: "SP", value: "Duartina" },
    { name: "Dumont", parent: "SP", value: "Dumont" },
    { name: "Elias Fausto", parent: "SP", value: "Elias Fausto" },
    { name: "Elisiário", parent: "SP", value: "Elisiario" },
    { name: "Embaúba", parent: "SP", value: "Embauba" },
    { name: "Embu-Guaçu", parent: "SP", value: "Embu-Guacu" },
    { name: "Emilianópolis", parent: "SP", value: "Emilianopolis" },
    { name: "Engenheiro Coelho", parent: "SP", value: "Engenheiro Coelho" },
    {
      name: "Espírito Santo do Pinhal",
      parent: "SP",
      value: "Espirito Santo do Pinhal",
    },
    {
      name: "Espírito Santo do Turvo",
      parent: "SP",
      value: "Espirito Santo do Turvo",
    },
    { name: "Estiva Gerbi", parent: "SP", value: "Estiva Gerbi" },
    { name: "Estrela d'Oeste", parent: "SP", value: "Estrela d'Oeste" },
    { name: "Estrela do Norte", parent: "SP", value: "Estrela do Norte" },
    {
      name: "Euclides da Cunha Paulista",
      parent: "SP",
      value: "Euclides da Cunha Paulista",
    },
    { name: "Fartura", parent: "SP", value: "Fartura" },
    { name: "Fernando Prestes", parent: "SP", value: "Fernando Prestes" },
    { name: "Fernandópolis", parent: "SP", value: "Fernandopolis" },
    { name: "Fernão", parent: "SP", value: "Fernao" },
    { name: "Flora Rica", parent: "SP", value: "Flora Rica" },
    { name: "Floreal", parent: "SP", value: "Floreal" },
    { name: "Florínea", parent: "SP", value: "Florinea" },
    { name: "Flórida Paulista", parent: "SP", value: "Florida Paulista" },
    { name: "Gabriel Monteiro", parent: "SP", value: "Gabriel Monteiro" },
    { name: "Garça", parent: "SP", value: "Garca" },
    { name: "Gastão Vidigal", parent: "SP", value: "Gastao Vidigal" },
    { name: "Gavião Peixoto", parent: "SP", value: "Gaviao Peixoto" },
    { name: "General Salgado", parent: "SP", value: "General Salgado" },
    { name: "Getulina", parent: "SP", value: "Getulina" },
    { name: "Glicério", parent: "SP", value: "Glicerio" },
    { name: "Guaimbê", parent: "SP", value: "Guaimbe" },
    { name: "Guaiçara", parent: "SP", value: "Guaicara" },
    { name: "Guapiara", parent: "SP", value: "Guapiara" },
    { name: "Guapiaçu", parent: "SP", value: "Guapiacu" },
    { name: "Guaraci", parent: "SP", value: "Guaraci" },
    { name: "Guarani d'Oeste", parent: "SP", value: "Guarani d'Oeste" },
    { name: "Guarantã", parent: "SP", value: "Guaranta" },
    { name: "Guararapes", parent: "SP", value: "Guararapes" },
    { name: "Guararema", parent: "SP", value: "Guararema" },
    { name: "Guaraçaí", parent: "SP", value: "Guaracai" },
    { name: "Guareí", parent: "SP", value: "Guarei" },
    { name: "Guariba", parent: "SP", value: "Guariba" },
    { name: "Guarujá", parent: "SP", value: "Guaruja" },
    { name: "Guará", parent: "SP", value: "Guara" },
    { name: "Guatapará", parent: "SP", value: "Guatapara" },
    { name: "Guaíra", parent: "SP", value: "Guaira" },
    { name: "Guzolândia", parent: "SP", value: "Guzolandia" },
    { name: "Gália", parent: "SP", value: "Galia" },
    { name: "Herculândia", parent: "SP", value: "Herculandia" },
    { name: "Holambra", parent: "SP", value: "Holambra" },
    { name: "Iacanga", parent: "SP", value: "Iacanga" },
    { name: "Iacri", parent: "SP", value: "Iacri" },
    { name: "Iaras", parent: "SP", value: "Iaras" },
    { name: "Ibaté", parent: "SP", value: "Ibate" },
    { name: "Ibirarema", parent: "SP", value: "Ibirarema" },
    { name: "Ibirá", parent: "SP", value: "Ibira" },
    { name: "Ibitinga", parent: "SP", value: "Ibitinga" },
    { name: "Ibiúna", parent: "SP", value: "Ibiuna" },
    { name: "Icém", parent: "SP", value: "Icem" },
    { name: "Iepê", parent: "SP", value: "Iepe" },
    { name: "Igarapava", parent: "SP", value: "Igarapava" },
    { name: "Igaratá", parent: "SP", value: "Igarata" },
    { name: "Igaraçu do Tietê", parent: "SP", value: "Igaracu do Tiete" },
    { name: "Iguape", parent: "SP", value: "Iguape" },
    { name: "Ilha Comprida", parent: "SP", value: "Ilha Comprida" },
    { name: "Ilha Solteira", parent: "SP", value: "Ilha Solteira" },
    { name: "Ilhabela", parent: "SP", value: "Ilhabela" },
    { name: "Indiana", parent: "SP", value: "Indiana" },
    { name: "Indiaporã", parent: "SP", value: "Indiapora" },
    { name: "Inúbia Paulista", parent: "SP", value: "Inubia Paulista" },
    { name: "Ipaussu", parent: "SP", value: "Ipaussu" },
    { name: "Iperó", parent: "SP", value: "Ipero" },
    { name: "Ipeúna", parent: "SP", value: "Ipeuna" },
    { name: "Ipiguá", parent: "SP", value: "Ipigua" },
    { name: "Iporanga", parent: "SP", value: "Iporanga" },
    { name: "Ipuã", parent: "SP", value: "Ipua" },
    { name: "Iracemápolis", parent: "SP", value: "Iracemapolis" },
    { name: "Irapuru", parent: "SP", value: "Irapuru" },
    { name: "Irapuã", parent: "SP", value: "Irapua" },
    { name: "Itaberá", parent: "SP", value: "Itabera" },
    { name: "Itajobi", parent: "SP", value: "Itajobi" },
    { name: "Itaju", parent: "SP", value: "Itaju" },
    { name: "Itanhaém", parent: "SP", value: "Itanhaem" },
    { name: "Itapeva", parent: "SP", value: "Itapeva" },
    { name: "Itapira", parent: "SP", value: "Itapira" },
    { name: "Itapirapuã Paulista", parent: "SP", value: "Itapirapua Paulista" },
    { name: "Itaporanga", parent: "SP", value: "Itaporanga" },
    { name: "Itapura", parent: "SP", value: "Itapura" },
    { name: "Itapuí", parent: "SP", value: "Itapui" },
    { name: "Itararé", parent: "SP", value: "Itarare" },
    { name: "Itariri", parent: "SP", value: "Itariri" },
    { name: "Itatinga", parent: "SP", value: "Itatinga" },
    { name: "Itaí", parent: "SP", value: "Itai" },
    { name: "Itaóca", parent: "SP", value: "Itaoca" },
    { name: "Itirapina", parent: "SP", value: "Itirapina" },
    { name: "Itirapuã", parent: "SP", value: "Itirapua" },
    { name: "Itobi", parent: "SP", value: "Itobi" },
    { name: "Itupeva", parent: "SP", value: "Itupeva" },
    { name: "Ituverava", parent: "SP", value: "Ituverava" },
    { name: "Itápolis", parent: "SP", value: "Itapolis" },
    { name: "Jaborandi", parent: "SP", value: "Jaborandi" },
    { name: "Jaboticabal", parent: "SP", value: "Jaboticabal" },
    { name: "Jaci", parent: "SP", value: "Jaci" },
    { name: "Jacupiranga", parent: "SP", value: "Jacupiranga" },
    { name: "Jaguariúna", parent: "SP", value: "Jaguariuna" },
    { name: "Jales", parent: "SP", value: "Jales" },
    { name: "Jambeiro", parent: "SP", value: "Jambeiro" },
    { name: "Jardinópolis", parent: "SP", value: "Jardinopolis" },
    { name: "Jarinu", parent: "SP", value: "Jarinu" },
    { name: "Jeriquara", parent: "SP", value: "Jeriquara" },
    { name: "Joanópolis", parent: "SP", value: "Joanopolis" },
    { name: "José Bonifácio", parent: "SP", value: "Jose Bonifacio" },
    { name: "João Ramalho", parent: "SP", value: "Joao Ramalho" },
    { name: "Jumirim", parent: "SP", value: "Jumirim" },
    { name: "Junqueirópolis", parent: "SP", value: "Junqueiropolis" },
    { name: "Juquitiba", parent: "SP", value: "Juquitiba" },
    { name: "Juquiá", parent: "SP", value: "Juquia" },
    { name: "Júlio Mesquita", parent: "SP", value: "Julio Mesquita" },
    { name: "Lagoinha", parent: "SP", value: "Lagoinha" },
    { name: "Laranjal Paulista", parent: "SP", value: "Laranjal Paulista" },
    { name: "Lavrinhas", parent: "SP", value: "Lavrinhas" },
    { name: "Lavínia", parent: "SP", value: "Lavinia" },
    { name: "Leme", parent: "SP", value: "Leme" },
    { name: "Lençóis Paulista", parent: "SP", value: "Lencois Paulista" },
    { name: "Lindóia", parent: "SP", value: "Lindoia" },
    { name: "Lins", parent: "SP", value: "Lins" },
    { name: "Lorena", parent: "SP", value: "Lorena" },
    { name: "Lourdes", parent: "SP", value: "Lourdes" },
    { name: "Louveira", parent: "SP", value: "Louveira" },
    { name: "Lucianópolis", parent: "SP", value: "Lucianopolis" },
    { name: "Lucélia", parent: "SP", value: "Lucelia" },
    { name: "Luiziânia", parent: "SP", value: "Luiziania" },
    { name: "Lupércio", parent: "SP", value: "Lupercio" },
    { name: "Lutécia", parent: "SP", value: "Lutecia" },
    { name: "Luís Antônio", parent: "SP", value: "Luis Antonio" },
    { name: "Macatuba", parent: "SP", value: "Macatuba" },
    { name: "Macaubal", parent: "SP", value: "Macaubal" },
    { name: "Macedônia", parent: "SP", value: "Macedonia" },
    { name: "Magda", parent: "SP", value: "Magda" },
    { name: "Mairinque", parent: "SP", value: "Mairinque" },
    { name: "Mairiporã", parent: "SP", value: "Mairipora" },
    { name: "Manduri", parent: "SP", value: "Manduri" },
    { name: "Marabá Paulista", parent: "SP", value: "Maraba Paulista" },
    { name: "Maracaí", parent: "SP", value: "Maracai" },
    { name: "Marapoama", parent: "SP", value: "Marapoama" },
    { name: "Marinópolis", parent: "SP", value: "Marinopolis" },
    { name: "Mariápolis", parent: "SP", value: "Mariapolis" },
    { name: "Martinópolis", parent: "SP", value: "Martinopolis" },
    { name: "Matão", parent: "SP", value: "Matao" },
    { name: "Mendonça", parent: "SP", value: "Mendonca" },
    { name: "Meridiano", parent: "SP", value: "Meridiano" },
    { name: "Mesópolis", parent: "SP", value: "Mesopolis" },
    { name: "Miguelópolis", parent: "SP", value: "Miguelopolis" },
    { name: "Mineiros do Tietê", parent: "SP", value: "Mineiros do Tiete" },
    { name: "Mira Estrela", parent: "SP", value: "Mira Estrela" },
    { name: "Miracatu", parent: "SP", value: "Miracatu" },
    { name: "Mirandópolis", parent: "SP", value: "Mirandopolis" },
    {
      name: "Mirante do Paranapanema",
      parent: "SP",
      value: "Mirante do Paranapanema",
    },
    { name: "Mirassol", parent: "SP", value: "Mirassol" },
    { name: "Mirassolândia", parent: "SP", value: "Mirassolandia" },
    { name: "Mococa", parent: "SP", value: "Mococa" },
    { name: "Mogi Mirim", parent: "SP", value: "Mogi Mirim" },
    { name: "Mombuca", parent: "SP", value: "Mombuca" },
    { name: "Mongaguá", parent: "SP", value: "Mongagua" },
    { name: "Monte Alegre do Sul", parent: "SP", value: "Monte Alegre do Sul" },
    { name: "Monte Alto", parent: "SP", value: "Monte Alto" },
    { name: "Monte Aprazível", parent: "SP", value: "Monte Aprazivel" },
    { name: "Monte Azul Paulista", parent: "SP", value: "Monte Azul Paulista" },
    { name: "Monte Castelo", parent: "SP", value: "Monte Castelo" },
    { name: "Monte Mor", parent: "SP", value: "Monte Mor" },
    { name: "Monteiro Lobato", parent: "SP", value: "Monteiro Lobato" },
    { name: "Monções", parent: "SP", value: "Moncoes" },
    { name: "Morro Agudo", parent: "SP", value: "Morro Agudo" },
    { name: "Morungaba", parent: "SP", value: "Morungaba" },
    { name: "Motuca", parent: "SP", value: "Motuca" },
    { name: "Murutinga do Sul", parent: "SP", value: "Murutinga do Sul" },
    { name: "Nantes", parent: "SP", value: "Nantes" },
    { name: "Narandiba", parent: "SP", value: "Narandiba" },
    { name: "Natividade da Serra", parent: "SP", value: "Natividade da Serra" },
    { name: "Nazaré Paulista", parent: "SP", value: "Nazare Paulista" },
    { name: "Neves Paulista", parent: "SP", value: "Neves Paulista" },
    { name: "Nhandeara", parent: "SP", value: "Nhandeara" },
    { name: "Nipoã", parent: "SP", value: "Nipoa" },
    { name: "Nova Aliança", parent: "SP", value: "Nova Alianca" },
    { name: "Nova Campina", parent: "SP", value: "Nova Campina" },
    { name: "Nova Canaã Paulista", parent: "SP", value: "Nova Canaa Paulista" },
    { name: "Nova Europa", parent: "SP", value: "Nova Europa" },
    { name: "Nova Granada", parent: "SP", value: "Nova Granada" },
    { name: "Nova Guataporanga", parent: "SP", value: "Nova Guataporanga" },
    { name: "Nova Independência", parent: "SP", value: "Nova Independencia" },
    { name: "Nova Luzitânia", parent: "SP", value: "Nova Luzitania" },
    { name: "Nova Odessa", parent: "SP", value: "Nova Odessa" },
    { name: "Novais", parent: "SP", value: "Novais" },
    { name: "Novo Horizonte", parent: "SP", value: "Novo Horizonte" },
    { name: "Nuporanga", parent: "SP", value: "Nuporanga" },
    { name: "Ocauçu", parent: "SP", value: "Ocaucu" },
    { name: "Olímpia", parent: "SP", value: "Olimpia" },
    { name: "Onda Verde", parent: "SP", value: "Onda Verde" },
    { name: "Oriente", parent: "SP", value: "Oriente" },
    { name: "Orindiúva", parent: "SP", value: "Orindiuva" },
    { name: "Orlândia", parent: "SP", value: "Orlandia" },
    { name: "Oscar Bressane", parent: "SP", value: "Oscar Bressane" },
    { name: "Osvaldo Cruz", parent: "SP", value: "Osvaldo Cruz" },
    { name: "Ouro Verde", parent: "SP", value: "Ouro Verde" },
    { name: "Pacaembu", parent: "SP", value: "Pacaembu" },
    { name: "Palestina", parent: "SP", value: "Palestina" },
    { name: "Palmares Paulista", parent: "SP", value: "Palmares Paulista" },
    { name: "Palmeira d'Oeste", parent: "SP", value: "Palmeira d'Oeste" },
    { name: "Palmital", parent: "SP", value: "Palmital" },
    { name: "Paraguaçu Paulista", parent: "SP", value: "Paraguacu Paulista" },
    { name: "Paraibuna", parent: "SP", value: "Paraibuna" },
    { name: "Paranapanema", parent: "SP", value: "Paranapanema" },
    { name: "Paranapuã", parent: "SP", value: "Paranapua" },
    { name: "Parapuã", parent: "SP", value: "Parapua" },
    { name: "Paraíso", parent: "SP", value: "Paraiso" },
    { name: "Pardinho", parent: "SP", value: "Pardinho" },
    { name: "Pariquera-Açu", parent: "SP", value: "Pariquera-Acu" },
    { name: "Parisi", parent: "SP", value: "Parisi" },
    { name: "Patrocínio Paulista", parent: "SP", value: "Patrocinio Paulista" },
    { name: "Paulicéia", parent: "SP", value: "Pauliceia" },
    { name: "Paulistânia", parent: "SP", value: "Paulistania" },
    { name: "Paulo de Faria", parent: "SP", value: "Paulo de Faria" },
    { name: "Paulínia", parent: "SP", value: "Paulinia" },
    { name: "Pederneiras", parent: "SP", value: "Pederneiras" },
    { name: "Pedra Bela", parent: "SP", value: "Pedra Bela" },
    { name: "Pedranópolis", parent: "SP", value: "Pedranopolis" },
    { name: "Pedregulho", parent: "SP", value: "Pedregulho" },
    { name: "Pedreira", parent: "SP", value: "Pedreira" },
    { name: "Pedrinhas Paulista", parent: "SP", value: "Pedrinhas Paulista" },
    { name: "Pedro de Toledo", parent: "SP", value: "Pedro de Toledo" },
    { name: "Penápolis", parent: "SP", value: "Penapolis" },
    { name: "Pereira Barreto", parent: "SP", value: "Pereira Barreto" },
    { name: "Pereiras", parent: "SP", value: "Pereiras" },
    { name: "Peruíbe", parent: "SP", value: "Peruibe" },
    { name: "Piacatu", parent: "SP", value: "Piacatu" },
    { name: "Piedade", parent: "SP", value: "Piedade" },
    { name: "Pilar do Sul", parent: "SP", value: "Pilar do Sul" },
    { name: "Pindorama", parent: "SP", value: "Pindorama" },
    { name: "Pinhalzinho", parent: "SP", value: "Pinhalzinho" },
    { name: "Piquerobi", parent: "SP", value: "Piquerobi" },
    { name: "Piquete", parent: "SP", value: "Piquete" },
    { name: "Piracaia", parent: "SP", value: "Piracaia" },
    { name: "Piraju", parent: "SP", value: "Piraju" },
    { name: "Pirajuí", parent: "SP", value: "Pirajui" },
    { name: "Pirangi", parent: "SP", value: "Pirangi" },
    {
      name: "Pirapora do Bom Jesus",
      parent: "SP",
      value: "Pirapora do Bom Jesus",
    },
    { name: "Pirapozinho", parent: "SP", value: "Pirapozinho" },
    { name: "Pirassununga", parent: "SP", value: "Pirassununga" },
    { name: "Piratininga", parent: "SP", value: "Piratininga" },
    { name: "Pitangueiras", parent: "SP", value: "Pitangueiras" },
    { name: "Planalto", parent: "SP", value: "Planalto" },
    { name: "Platina", parent: "SP", value: "Platina" },
    { name: "Poloni", parent: "SP", value: "Poloni" },
    { name: "Pompeia", parent: "SP", value: "Pompeia" },
    { name: "Pongaí", parent: "SP", value: "Pongai" },
    { name: "Pontal", parent: "SP", value: "Pontal" },
    { name: "Pontalinda", parent: "SP", value: "Pontalinda" },
    { name: "Pontes Gestal", parent: "SP", value: "Pontes Gestal" },
    { name: "Populina", parent: "SP", value: "Populina" },
    { name: "Porangaba", parent: "SP", value: "Porangaba" },
    { name: "Porto Feliz", parent: "SP", value: "Porto Feliz" },
    { name: "Porto Ferreira", parent: "SP", value: "Porto Ferreira" },
    { name: "Potim", parent: "SP", value: "Potim" },
    { name: "Potirendaba", parent: "SP", value: "Potirendaba" },
    { name: "Pracinha", parent: "SP", value: "Pracinha" },
    { name: "Pradópolis", parent: "SP", value: "Pradopolis" },
    { name: "Pratânia", parent: "SP", value: "Pratania" },
    { name: "Presidente Alves", parent: "SP", value: "Presidente Alves" },
    {
      name: "Presidente Bernardes",
      parent: "SP",
      value: "Presidente Bernardes",
    },
    { name: "Presidente Epitácio", parent: "SP", value: "Presidente Epitacio" },
    {
      name: "Presidente Venceslau",
      parent: "SP",
      value: "Presidente Venceslau",
    },
    { name: "Promissão", parent: "SP", value: "Promissao" },
    { name: "Quadra", parent: "SP", value: "Quadra" },
    { name: "Quatá", parent: "SP", value: "Quata" },
    { name: "Queiroz", parent: "SP", value: "Queiroz" },
    { name: "Queluz", parent: "SP", value: "Queluz" },
    { name: "Quintana", parent: "SP", value: "Quintana" },
    { name: "Rafard", parent: "SP", value: "Rafard" },
    { name: "Rancharia", parent: "SP", value: "Rancharia" },
    { name: "Redenção da Serra", parent: "SP", value: "Redencao da Serra" },
    { name: "Regente Feijó", parent: "SP", value: "Regente Feijo" },
    { name: "Reginópolis", parent: "SP", value: "Reginopolis" },
    { name: "Registro", parent: "SP", value: "Registro" },
    { name: "Restinga", parent: "SP", value: "Restinga" },
    { name: "Ribeira", parent: "SP", value: "Ribeira" },
    { name: "Ribeirão Bonito", parent: "SP", value: "Ribeirao Bonito" },
    { name: "Ribeirão Branco", parent: "SP", value: "Ribeirao Branco" },
    { name: "Ribeirão Corrente", parent: "SP", value: "Ribeirao Corrente" },
    { name: "Ribeirão Grande", parent: "SP", value: "Ribeirao Grande" },
    { name: "Ribeirão do Sul", parent: "SP", value: "Ribeirao do Sul" },
    { name: "Ribeirão dos Índios", parent: "SP", value: "Ribeirao dos Indios" },
    { name: "Rifaina", parent: "SP", value: "Rifaina" },
    { name: "Rincão", parent: "SP", value: "Rincao" },
    { name: "Rinópolis", parent: "SP", value: "Rinopolis" },
    { name: "Rio Grande da Serra", parent: "SP", value: "Rio Grande da Serra" },
    { name: "Rio das Pedras", parent: "SP", value: "Rio das Pedras" },
    { name: "Riolândia", parent: "SP", value: "Riolandia" },
    { name: "Riversul", parent: "SP", value: "Riversul" },
    { name: "Rosana", parent: "SP", value: "Rosana" },
    { name: "Roseira", parent: "SP", value: "Roseira" },
    { name: "Rubinéia", parent: "SP", value: "Rubineia" },
    { name: "Rubiácea", parent: "SP", value: "Rubiacea" },
    { name: "Sabino", parent: "SP", value: "Sabino" },
    { name: "Sagres", parent: "SP", value: "Sagres" },
    { name: "Sales", parent: "SP", value: "Sales" },
    { name: "Sales Oliveira", parent: "SP", value: "Sales Oliveira" },
    { name: "Salesópolis", parent: "SP", value: "Salesopolis" },
    { name: "Salmourão", parent: "SP", value: "Salmourao" },
    { name: "Saltinho", parent: "SP", value: "Saltinho" },
    { name: "Salto Grande", parent: "SP", value: "Salto Grande" },
    { name: "Salto de Pirapora", parent: "SP", value: "Salto de Pirapora" },
    { name: "Sandovalina", parent: "SP", value: "Sandovalina" },
    { name: "Santa Adélia", parent: "SP", value: "Santa Adelia" },
    { name: "Santa Albertina", parent: "SP", value: "Santa Albertina" },
    { name: "Santa Branca", parent: "SP", value: "Santa Branca" },
    { name: "Santa Clara d'Oeste", parent: "SP", value: "Santa Clara d'Oeste" },
    {
      name: "Santa Cruz da Conceição",
      parent: "SP",
      value: "Santa Cruz da Conceicao",
    },
    {
      name: "Santa Cruz da Esperança",
      parent: "SP",
      value: "Santa Cruz da Esperanca",
    },
    {
      name: "Santa Cruz das Palmeiras",
      parent: "SP",
      value: "Santa Cruz das Palmeiras",
    },
    {
      name: "Santa Cruz do Rio Pardo",
      parent: "SP",
      value: "Santa Cruz do Rio Pardo",
    },
    { name: "Santa Ernestina", parent: "SP", value: "Santa Ernestina" },
    { name: "Santa Fé do Sul", parent: "SP", value: "Santa Fe do Sul" },
    { name: "Santa Gertrudes", parent: "SP", value: "Santa Gertrudes" },
    { name: "Santa Isabel", parent: "SP", value: "Santa Isabel" },
    { name: "Santa Lúcia", parent: "SP", value: "Santa Lucia" },
    {
      name: "Santa Maria da Serra",
      parent: "SP",
      value: "Santa Maria da Serra",
    },
    { name: "Santa Mercedes", parent: "SP", value: "Santa Mercedes" },
    { name: "Santa Rita d'Oeste", parent: "SP", value: "Santa Rita d'Oeste" },
    {
      name: "Santa Rita do Passa Quatro",
      parent: "SP",
      value: "Santa Rita do Passa Quatro",
    },
    {
      name: "Santa Rosa de Viterbo",
      parent: "SP",
      value: "Santa Rosa de Viterbo",
    },
    {
      name: "Santana da Ponte Pensa",
      parent: "SP",
      value: "Santana da Ponte Pensa",
    },
    { name: "Santo Anastácio", parent: "SP", value: "Santo Anastacio" },
    {
      name: "Santo Antônio da Alegria",
      parent: "SP",
      value: "Santo Antonio da Alegria",
    },
    {
      name: "Santo Antônio de Posse",
      parent: "SP",
      value: "Santo Antonio de Posse",
    },
    {
      name: "Santo Antônio do Aracanguá",
      parent: "SP",
      value: "Santo Antonio do Aracangua",
    },
    {
      name: "Santo Antônio do Jardim",
      parent: "SP",
      value: "Santo Antonio do Jardim",
    },
    {
      name: "Santo Antônio do Pinhal",
      parent: "SP",
      value: "Santo Antonio do Pinhal",
    },
    { name: "Santo Expedito", parent: "SP", value: "Santo Expedito" },
    {
      name: "Santópolis do Aguapeí",
      parent: "SP",
      value: "Santopolis do Aguapei",
    },
    { name: "Sarapuí", parent: "SP", value: "Sarapui" },
    { name: "Sarutaiá", parent: "SP", value: "Sarutaia" },
    {
      name: "Sebastianópolis do Sul",
      parent: "SP",
      value: "Sebastianopolis do Sul",
    },
    { name: "Serra Azul", parent: "SP", value: "Serra Azul" },
    { name: "Serra Negra", parent: "SP", value: "Serra Negra" },
    { name: "Serrana", parent: "SP", value: "Serrana" },
    { name: "Sete Barras", parent: "SP", value: "Sete Barras" },
    { name: "Severínia", parent: "SP", value: "Severinia" },
    { name: "Silveiras", parent: "SP", value: "Silveiras" },
    { name: "Socorro", parent: "SP", value: "Socorro" },
    { name: "Sud Mennucci", parent: "SP", value: "Sud Mennucci" },
    { name: "Suzanápolis", parent: "SP", value: "Suzanapolis" },
    {
      name: "São Bento do Sapucaí",
      parent: "SP",
      value: "Sao Bento do Sapucai",
    },
    { name: "São Francisco", parent: "SP", value: "Sao Francisco" },
    {
      name: "São Joaquim da Barra",
      parent: "SP",
      value: "Sao Joaquim da Barra",
    },
    {
      name: "São José da Bela Vista",
      parent: "SP",
      value: "Sao Jose da Bela Vista",
    },
    {
      name: "São José do Barreiro",
      parent: "SP",
      value: "Sao Jose do Barreiro",
    },
    {
      name: "São José do Rio Pardo",
      parent: "SP",
      value: "Sao Jose do Rio Pardo",
    },
    {
      name: "São João da Boa Vista",
      parent: "SP",
      value: "Sao Joao da Boa Vista",
    },
    {
      name: "São João das Duas Pontes",
      parent: "SP",
      value: "Sao Joao das Duas Pontes",
    },
    { name: "São João de Iracema", parent: "SP", value: "Sao Joao de Iracema" },
    {
      name: "São João do Pau-d'Alho",
      parent: "SP",
      value: "Sao Joao do Pau-d'Alho",
    },
    {
      name: "São Lourenço da Serra",
      parent: "SP",
      value: "Sao Lourenco da Serra",
    },
    {
      name: "São Luiz do Paraitinga",
      parent: "SP",
      value: "Sao Luiz do Paraitinga",
    },
    { name: "São Manuel", parent: "SP", value: "Sao Manuel" },
    { name: "São Miguel Arcanjo", parent: "SP", value: "Sao Miguel Arcanjo" },
    { name: "São Paulo", parent: "SP", value: "Sao Paulo" },
    { name: "São Pedro", parent: "SP", value: "Sao Pedro" },
    { name: "São Pedro do Turvo", parent: "SP", value: "Sao Pedro do Turvo" },
    { name: "São Roque", parent: "SP", value: "Sao Roque" },
    { name: "São Sebastião", parent: "SP", value: "Sao Sebastiao" },
    {
      name: "São Sebastião da Grama",
      parent: "SP",
      value: "Sao Sebastiao da Grama",
    },
    { name: "São Simão", parent: "SP", value: "Sao Simao" },
    { name: "Tabapuã", parent: "SP", value: "Tabapua" },
    { name: "Tabatinga", parent: "SP", value: "Tabatinga" },
    { name: "Taciba", parent: "SP", value: "Taciba" },
    { name: "Taguaí", parent: "SP", value: "Taguai" },
    { name: "Taiaçu", parent: "SP", value: "Taiacu" },
    { name: "Taiuva", parent: "SP", value: "Taiuva" },
    { name: "Tambaú", parent: "SP", value: "Tambau" },
    { name: "Tanabi", parent: "SP", value: "Tanabi" },
    { name: "Tapiratiba", parent: "SP", value: "Tapiratiba" },
    { name: "Tapiraí", parent: "SP", value: "Tapirai" },
    { name: "Taquaral", parent: "SP", value: "Taquaral" },
    { name: "Taquaritinga", parent: "SP", value: "Taquaritinga" },
    { name: "Taquarituba", parent: "SP", value: "Taquarituba" },
    { name: "Taquarivaí", parent: "SP", value: "Taquarivai" },
    { name: "Tarabai", parent: "SP", value: "Tarabai" },
    { name: "Tarumã", parent: "SP", value: "Taruma" },
    { name: "Tejupá", parent: "SP", value: "Tejupa" },
    { name: "Teodoro Sampaio", parent: "SP", value: "Teodoro Sampaio" },
    { name: "Terra Roxa", parent: "SP", value: "Terra Roxa" },
    { name: "Tietê", parent: "SP", value: "Tiete" },
    { name: "Timburi", parent: "SP", value: "Timburi" },
    { name: "Torre de Pedra", parent: "SP", value: "Torre de Pedra" },
    { name: "Torrinha", parent: "SP", value: "Torrinha" },
    { name: "Trabiju", parent: "SP", value: "Trabiju" },
    { name: "Tremembé", parent: "SP", value: "Tremembe" },
    { name: "Três Fronteiras", parent: "SP", value: "Tres Fronteiras" },
    { name: "Tuiuti", parent: "SP", value: "Tuiuti" },
    { name: "Tupi Paulista", parent: "SP", value: "Tupi Paulista" },
    { name: "Tupã", parent: "SP", value: "Tupa" },
    { name: "Turiúba", parent: "SP", value: "Turiuba" },
    { name: "Turmalina", parent: "SP", value: "Turmalina" },
    { name: "Ubarana", parent: "SP", value: "Ubarana" },
    { name: "Ubatuba", parent: "SP", value: "Ubatuba" },
    { name: "Ubirajara", parent: "SP", value: "Ubirajara" },
    { name: "Uchoa", parent: "SP", value: "Uchoa" },
    { name: "União Paulista", parent: "SP", value: "Uniao Paulista" },
    { name: "Urupês", parent: "SP", value: "Urupes" },
    { name: "Urânia", parent: "SP", value: "Urania" },
    { name: "Valentim Gentil", parent: "SP", value: "Valentim Gentil" },
    { name: "Valparaíso", parent: "SP", value: "Valparaiso" },
    { name: "Vargem", parent: "SP", value: "Vargem" },
    {
      name: "Vargem Grande Paulista",
      parent: "SP",
      value: "Vargem Grande Paulista",
    },
    {
      name: "Vargem Grande do Sul",
      parent: "SP",
      value: "Vargem Grande do Sul",
    },
    { name: "Vera Cruz", parent: "SP", value: "Vera Cruz" },
    { name: "Vinhedo", parent: "SP", value: "Vinhedo" },
    { name: "Viradouro", parent: "SP", value: "Viradouro" },
    {
      name: "Vista Alegre do Alto",
      parent: "SP",
      value: "Vista Alegre do Alto",
    },
    { name: "Vitória Brasil", parent: "SP", value: "Vitoria Brasil" },
    { name: "Votuporanga", parent: "SP", value: "Votuporanga" },
    { name: "Zacarias", parent: "SP", value: "Zacarias" },
    { name: "Águas da Prata", parent: "SP", value: "Aguas da Prata" },
    { name: "Águas de Lindóia", parent: "SP", value: "Aguas de Lindoia" },
    {
      name: "Águas de Santa Bárbara",
      parent: "SP",
      value: "Aguas de Santa Barbara",
    },
    { name: "Águas de São Pedro", parent: "SP", value: "Aguas de Sao Pedro" },
    { name: "Álvares Florence", parent: "SP", value: "Alvares Florence" },
    { name: "Álvares Machado", parent: "SP", value: "Alvares Machado" },
    { name: "Álvaro de Carvalho", parent: "SP", value: "Alvaro de Carvalho" },
    { name: "Óleo", parent: "SP", value: "Oleo" },

    { name: "Abreulandia", parent: "TO", value: "Abreulandia" },
    { name: "Aguiarnopolis", parent: "TO", value: "Aguiarnopolis" },
    {
      name: "Alianca do Tocantins",
      parent: "TO",
      value: "Alianca do Tocantins",
    },
    { name: "Almas", parent: "TO", value: "Almas" },
    { name: "Alvorada", parent: "TO", value: "Alvorada" },
    { name: "Ananas", parent: "TO", value: "Ananas" },
    { name: "Angico", parent: "TO", value: "Angico" },
    {
      name: "Aparecida do Rio Negro",
      parent: "TO",
      value: "Aparecida do Rio Negro",
    },
    { name: "Aragominas", parent: "TO", value: "Aragominas" },
    { name: "Araguacema", parent: "TO", value: "Araguacema" },
    { name: "Araguacu", parent: "TO", value: "Araguacu" },
    { name: "Araguaina", parent: "TO", value: "Araguaina" },
    { name: "Araguana", parent: "TO", value: "Araguana" },
    { name: "Araguatins", parent: "TO", value: "Araguatins" },
    { name: "Arapoema", parent: "TO", value: "Arapoema" },
    { name: "Arraias", parent: "TO", value: "Arraias" },
    { name: "Augustinopolis", parent: "TO", value: "Augustinopolis" },
    { name: "Aurora do Tocantins", parent: "TO", value: "Aurora do Tocantins" },
    { name: "Axixa do Tocantins", parent: "TO", value: "Axixa do Tocantins" },
    { name: "Babaculandia", parent: "TO", value: "Babaculandia" },
    {
      name: "Bandeirantes do Tocantins",
      parent: "TO",
      value: "Bandeirantes do Tocantins",
    },
    { name: "Barra do Ouro", parent: "TO", value: "Barra do Ouro" },
    { name: "Barrolandia", parent: "TO", value: "Barrolandia" },
    { name: "Bernardo Sayao", parent: "TO", value: "Bernardo Sayao" },
    {
      name: "Bom Jesus do Tocantins",
      parent: "TO",
      value: "Bom Jesus do Tocantins",
    },
    {
      name: "Brasilandia do Tocantins",
      parent: "TO",
      value: "Brasilandia do Tocantins",
    },
    { name: "Brejinho de Nazare", parent: "TO", value: "Brejinho de Nazare" },
    { name: "Buriti do Tocantins", parent: "TO", value: "Buriti do Tocantins" },
    { name: "Cachoeirinha", parent: "TO", value: "Cachoeirinha" },
    { name: "Campos Lindos", parent: "TO", value: "Campos Lindos" },
    { name: "Cariri do Tocantins", parent: "TO", value: "Cariri do Tocantins" },
    { name: "Carmolandia", parent: "TO", value: "Carmolandia" },
    { name: "Carrasco Bonito", parent: "TO", value: "Carrasco Bonito" },
    { name: "Caseara", parent: "TO", value: "Caseara" },
    { name: "Centenario", parent: "TO", value: "Centenario" },
    {
      name: "Chapada da Natividade",
      parent: "TO",
      value: "Chapada da Natividade",
    },
    { name: "Chapada de Areia", parent: "TO", value: "Chapada de Areia" },
    {
      name: "Colinas do Tocantins",
      parent: "TO",
      value: "Colinas do Tocantins",
    },
    { name: "Colmeia", parent: "TO", value: "Colmeia" },
    { name: "Combinado", parent: "TO", value: "Combinado" },
    {
      name: "Conceicao do Tocantins",
      parent: "TO",
      value: "Conceicao do Tocantins",
    },
    { name: "Couto de Magalhaes", parent: "TO", value: "Couto de Magalhaes" },
    { name: "Cristalandia", parent: "TO", value: "Cristalandia" },
    { name: "Crixas do Tocantins", parent: "TO", value: "Crixas do Tocantins" },
    { name: "Darcinopolis", parent: "TO", value: "Darcinopolis" },
    { name: "Dianopolis", parent: "TO", value: "Dianopolis" },
    {
      name: "Divinopolis do Tocantins",
      parent: "TO",
      value: "Divinopolis do Tocantins",
    },
    {
      name: "Dois Irmaos do Tocantins",
      parent: "TO",
      value: "Dois Irmaos do Tocantins",
    },
    { name: "Duere", parent: "TO", value: "Duere" },
    { name: "Esperantina", parent: "TO", value: "Esperantina" },
    { name: "Fatima", parent: "TO", value: "Fatima" },
    { name: "Figueiropolis", parent: "TO", value: "Figueiropolis" },
    { name: "Filadelfia", parent: "TO", value: "Filadelfia" },
    { name: "Formoso do Araguaia", parent: "TO", value: "Formoso do Araguaia" },
    {
      name: "Fortaleza do Tabocao",
      parent: "TO",
      value: "Fortaleza do Tabocao",
    },
    { name: "Goianorte", parent: "TO", value: "Goianorte" },
    { name: "Goiatins", parent: "TO", value: "Goiatins" },
    { name: "Guarai", parent: "TO", value: "Guarai" },
    { name: "Gurupi", parent: "TO", value: "Gurupi" },
    { name: "Ipueiras", parent: "TO", value: "Ipueiras" },
    { name: "Itacaja", parent: "TO", value: "Itacaja" },
    { name: "Itaguatins", parent: "TO", value: "Itaguatins" },
    { name: "Itapiratins", parent: "TO", value: "Itapiratins" },
    {
      name: "Itapora do Tocantins",
      parent: "TO",
      value: "Itapora do Tocantins",
    },
    { name: "Jau do Tocantins", parent: "TO", value: "Jau do Tocantins" },
    { name: "Juarina", parent: "TO", value: "Juarina" },
    { name: "Lagoa da Confusao", parent: "TO", value: "Lagoa da Confusao" },
    { name: "Lagoa do Tocantins", parent: "TO", value: "Lagoa do Tocantins" },
    { name: "Lajeado", parent: "TO", value: "Lajeado" },
    { name: "Lavandeira", parent: "TO", value: "Lavandeira" },
    { name: "Lizarda", parent: "TO", value: "Lizarda" },
    { name: "Luzinopolis", parent: "TO", value: "Luzinopolis" },
    {
      name: "Marianopolis do Tocantins",
      parent: "TO",
      value: "Marianopolis do Tocantins",
    },
    { name: "Mateiros", parent: "TO", value: "Mateiros" },
    {
      name: "Maurilandia do Tocantins",
      parent: "TO",
      value: "Maurilandia do Tocantins",
    },
    {
      name: "Miracema do Tocantins",
      parent: "TO",
      value: "Miracema do Tocantins",
    },
    { name: "Miranorte", parent: "TO", value: "Miranorte" },
    {
      name: "Monte Santo do Tocantins",
      parent: "TO",
      value: "Monte Santo do Tocantins",
    },
    { name: "Monte do Carmo", parent: "TO", value: "Monte do Carmo" },
    { name: "Mosquito", parent: "TO", value: "Mosquito" },
    { name: "Muricilandia", parent: "TO", value: "Muricilandia" },
    { name: "Natividade", parent: "TO", value: "Natividade" },
    { name: "Nazare", parent: "TO", value: "Nazare" },
    { name: "Nova Olinda", parent: "TO", value: "Nova Olinda" },
    { name: "Nova Rosalandia", parent: "TO", value: "Nova Rosalandia" },
    { name: "Novo Acordo", parent: "TO", value: "Novo Acordo" },
    { name: "Novo Alegre", parent: "TO", value: "Novo Alegre" },
    { name: "Novo Jardim", parent: "TO", value: "Novo Jardim" },
    { name: "Oliveira de Fatima", parent: "TO", value: "Oliveira de Fatima" },
    { name: "Palmas", parent: "TO", value: "Palmas" },
    { name: "Palmeirante", parent: "TO", value: "Palmeirante" },
    { name: "Palmeiropolis", parent: "TO", value: "Palmeiropolis" },
    {
      name: "Paraiso do Tocantins",
      parent: "TO",
      value: "Paraiso do Tocantins",
    },
    { name: "Parana", parent: "TO", value: "Parana" },
    { name: "Pau d'Arco", parent: "TO", value: "Pau d'Arco" },
    { name: "Pedro Afonso", parent: "TO", value: "Pedro Afonso" },
    { name: "Peixe", parent: "TO", value: "Peixe" },
    { name: "Pequizeiro", parent: "TO", value: "Pequizeiro" },
    {
      name: "Pindorama do Tocantins",
      parent: "TO",
      value: "Pindorama do Tocantins",
    },
    { name: "Piraque", parent: "TO", value: "Piraque" },
    { name: "Pium", parent: "TO", value: "Pium" },
    {
      name: "Ponte Alta do Bom Jesus",
      parent: "TO",
      value: "Ponte Alta do Bom Jesus",
    },
    {
      name: "Ponte Alta do Tocantins",
      parent: "TO",
      value: "Ponte Alta do Tocantins",
    },
    {
      name: "Porto Alegre do Tocantins",
      parent: "TO",
      value: "Porto Alegre do Tocantins",
    },
    { name: "Porto Nacional", parent: "TO", value: "Porto Nacional" },
    { name: "Praia Norte", parent: "TO", value: "Praia Norte" },
    { name: "Presidente Kennedy", parent: "TO", value: "Presidente Kennedy" },
    { name: "Pugmil", parent: "TO", value: "Pugmil" },
    { name: "Recursolandia", parent: "TO", value: "Recursolandia" },
    { name: "Riachinho", parent: "TO", value: "Riachinho" },
    { name: "Rio Sono", parent: "TO", value: "Rio Sono" },
    { name: "Rio da Conceicao", parent: "TO", value: "Rio da Conceicao" },
    { name: "Rio dos Bois", parent: "TO", value: "Rio dos Bois" },
    { name: "Sampaio", parent: "TO", value: "Sampaio" },
    { name: "Sandolandia", parent: "TO", value: "Sandolandia" },
    {
      name: "Santa Fe do Araguaia",
      parent: "TO",
      value: "Santa Fe do Araguaia",
    },
    {
      name: "Santa Maria do Tocantins",
      parent: "TO",
      value: "Santa Maria do Tocantins",
    },
    {
      name: "Santa Rita do Tocantins",
      parent: "TO",
      value: "Santa Rita do Tocantins",
    },
    {
      name: "Santa Rosa do Tocantins",
      parent: "TO",
      value: "Santa Rosa do Tocantins",
    },
    {
      name: "Santa Tereza do Tocantins",
      parent: "TO",
      value: "Santa Tereza do Tocantins",
    },
    {
      name: "Santa Terezinha do Tocantins",
      parent: "TO",
      value: "Santa Terezinha do Tocantins",
    },
    {
      name: "Sao Bento do Tocantins",
      parent: "TO",
      value: "Sao Bento do Tocantins",
    },
    {
      name: "Sao Felix do Tocantins",
      parent: "TO",
      value: "Sao Felix do Tocantins",
    },
    {
      name: "Sao Miguel do Tocantins",
      parent: "TO",
      value: "Sao Miguel do Tocantins",
    },
    {
      name: "Sao Salvador do Tocantins",
      parent: "TO",
      value: "Sao Salvador do Tocantins",
    },
    {
      name: "Sao Sebastiao do Tocantins",
      parent: "TO",
      value: "Sao Sebastiao do Tocantins",
    },
    {
      name: "Sao Valerio da Natividade",
      parent: "TO",
      value: "Sao Valerio da Natividade",
    },
    { name: "Silvanopolis", parent: "TO", value: "Silvanopolis" },
    {
      name: "Sitio Novo do Tocantins",
      parent: "TO",
      value: "Sitio Novo do Tocantins",
    },
    { name: "Sucupira", parent: "TO", value: "Sucupira" },
    { name: "Taguatinga", parent: "TO", value: "Taguatinga" },
    { name: "Taipas do Tocantins", parent: "TO", value: "Taipas do Tocantins" },
    { name: "Talisma", parent: "TO", value: "Talisma" },
    { name: "Tocantinia", parent: "TO", value: "Tocantinia" },
    { name: "Tocantinopolis", parent: "TO", value: "Tocantinopolis" },
    { name: "Tupirama", parent: "TO", value: "Tupirama" },
    { name: "Tupiratins", parent: "TO", value: "Tupiratins" },
    { name: "Wanderlandia", parent: "TO", value: "Wanderlandia" },
    { name: "Xambioa", parent: "TO", value: "Xambioa" },
  ],
  [CatalogsEnum.Banks]: [
    { name: "BANCO DO BRASIL S.A.", value: "00000000" },
    { name: "BRB - BANCO DE BRASILIA S.A.", value: "00000208" },
    { name: "CAIXA ECONOMICA FEDERAL", value: "00360305" },
    { name: "BANCO INTER S.A.", value: "00416968" },
    { name: "BANCO RIBEIRAO PRETO S.A.", value: "00517645" },
    {
      name: "BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A.",
      value: "00556603",
    },
    { name: "BANCO CETELEM S.A.", value: "00558456" },
    { name: "BANCO SEMEAR S.A.", value: "00795423" },
    { name: "BANCO B3 S.A.", value: "00997185" },
    { name: "BANCO RABOBANK INTERNATIONAL BRASIL S.A.", value: "01023570" },
    { name: "BANCO COOPERATIVO SICREDI S.A.", value: "01181521" },
    { name: "BANCO BNP PARIBAS BRASIL S.A.", value: "01522368" },
    { name: "KIRTON BANK S.A. - BANCO MÚLTIPLO", value: "01701201" },
    { name: "BANCO COOPERATIVO DO BRASIL S.A. - BANCOOB", value: "02038232" },
    { name: "BANCO KEB HANA DO BRASIL S.A.", value: "02318507" },
    { name: "BANCO CATERPILLAR S.A.", value: "02658435" },
    { name: "BANCO MORGAN STANLEY S.A.", value: "02801938" },
    { name: "BANCO CNH INDUSTRIAL CAPITAL S.A.", value: "02992446" },
    { name: "HIPERCARD BANCO MÚLTIPLO S.A.", value: "03012230" },
    { name: "BANCO J. SAFRA S.A.", value: "03017677" },
    { name: "BANCO TOYOTA DO BRASIL S.A.", value: "03215790" },
    { name: "BANCO ALFA S.A.", value: "03323840" },
    { name: "BANCO PSA FINANCE BRASIL S.A.", value: "03502961" },
    { name: "BANCO ABN AMRO S.A.", value: "03532415" },
    { name: "BANCO CARGILL S.A.", value: "03609817" },
    { name: "BANCO HONDA S.A.", value: "03634220" },
    { name: "BANCO BRADESCARD S.A.", value: "04184779" },
    { name: "GOLDMAN SACHS DO BRASIL BANCO MULTIPLO S.A.", value: "04332281" },
    { name: "BANCO INBURSA S.A.", value: "04866275" },
    { name: "BANCO DA AMAZONIA S.A.", value: "04902979" },
    { name: "BANCO DO ESTADO DO PARÁ S.A.", value: "04913711" },
    { name: "BANCO DE LAGE LANDEN BRASIL S.A.", value: "05040481" },
    { name: "BANCO BRADESCO BBI S.A.", value: "06271464" },
    { name: "BANCO BRADESCO FINANCIAMENTOS S.A.", value: "07207996" },
    { name: "BANCO DO NORDESTE DO BRASIL S.A.", value: "07237373" },
    { name: "BANCO MONEO S.A.", value: "07441209" },
    {
      name: "CHINA CONSTRUCTION BANK (BRASIL) BANCO MÚLTIPLO S/A",
      value: "07450604",
    },
    { name: "BANCO KDB DO BRASIL S.A.", value: "07656500" },
    { name: "BANCO TOPÁZIO S.A.", value: "07679404" },
    { name: "BANCO CSF S.A.", value: "08357240" },
    { name: "MONEYCORP BANCO DE CÂMBIO S.A.", value: "08609934" },
    { name: "STATE STREET BRASIL S.A. – BANCO COMERCIAL", value: "09274232" },
    { name: "BANCO ORIGINAL DO AGRONEGÓCIO S.A.", value: "09516419" },
    { name: "BANCO VIPAL S.A.", value: "09526594" },
    { name: "BANCOSEGURO S.A.", value: "10264663" },
    { name: "BANCO YAMAHA MOTOR DO BRASIL S.A.", value: "10371492" },
    { name: "BANCO AGIBANK S.A.", value: "10664513" },
    { name: "BANCO DA CHINA BRASIL S.A.", value: "10690848" },
    { name: "BANCO BANDEPE S.A.", value: "10866788" },
    { name: "SCANIA BANCO S.A.", value: "11417016" },
    { name: "BANCO RANDON S.A.", value: "11476673" },
    { name: "TRAVELEX BANCO DE CÂMBIO S.A.", value: "11703662" },
    { name: "BANCO FINAXIS S.A.", value: "11758741" },
    { name: "BANCO DO ESTADO DE SERGIPE S.A.", value: "13009717" },
    { name: "BEXS BANCO DE CÂMBIO S/A", value: "13059145" },
    { name: "BANCO WESTERN UNION DO BRASIL S.A.", value: "13720915" },
    { name: "PARANÁ BANCO S.A.", value: "14388334" },
    { name: "BANCO BOCOM BBM S.A.", value: "15114366" },
    { name: "BANCO CAPITAL S.A.", value: "15173776" },
    { name: "BANCO WOORI BANK DO BRASIL S.A.", value: "15357060" },
    { name: "BANCO MERCANTIL DO BRASIL S.A.", value: "17184037" },
    { name: "BANCO ITAUCARD S.A.", value: "17192451" },
    { name: "BANCO ITAÚ BBA S.A.", value: "17298092" },
    { name: "BANCO TRIANGULO S.A.", value: "17351180" },
    { name: "ICBC DO BRASIL BANCO MÚLTIPLO S.A.", value: "17453575" },
    { name: "MS BANK S.A. BANCO DE CÂMBIO", value: "19307785" },
    { name: "COMMERZBANK BRASIL S.A. - BANCO MÚLTIPLO", value: "23522214" },
    { name: "BANCO KOMATSU DO BRASIL S.A.", value: "23903068" },
    { name: "BANCO DIGIO S.A.", value: "27098060" },
    {
      name: "BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO",
      value: "28127603",
    },
    { name: "BANCO ABC BRASIL S.A.", value: "28195667" },
    { name: "BANCO PACCAR S.A.", value: "28517628" },
    { name: "INTL FCSTONE BANCO DE CÂMBIO S.A.", value: "28811341" },
    { name: "SCOTIABANK BRASIL S.A. BANCO MÚLTIPLO", value: "29030467" },
    { name: "BANCO HYUNDAI CAPITAL BRASIL S.A.", value: "30172491" },
    { name: "BANCO BTG PACTUAL S.A.", value: "30306294" },
    { name: "BANCO MODAL S.A.", value: "30723886" },
    { name: "BANCO CLASSICO S.A.", value: "31597552" },
    { name: "BANCO C6 S.A.", value: "31872495" },
    { name: "BANCO GUANABARA S.A.", value: "31880826" },
    { name: "BANCO INDUSTRIAL DO BRASIL S.A.", value: "31895683" },
    { name: "BANCO CREDIT SUISSE (BRASIL) S.A.", value: "32062580" },
    { name: "BANCO DE LA NACION ARGENTINA", value: "33042151" },
    { name: "CITIBANK N.A.", value: "33042953" },
    { name: "BANCO CEDULA S.A.", value: "33132044" },
    { name: "BANCO BRADESCO BERJ S.A.", value: "33147315" },
    { name: "BANCO J.P. MORGAN S.A.", value: "33172537" },
    { name: "BANCO LOSANGO S.A. - BANCO MÚLTIPLO", value: "33254319" },
    { name: "BANCO XP S.A.", value: "33264668" },
    { name: "BANCO CAIXA GERAL - BRASIL S.A.", value: "33466988" },
    { name: "BANCO CITIBANK S.A.", value: "33479023" },
    { name: "BANCO RODOBENS S.A.", value: "33603457" },
    { name: "BANCO FATOR S.A.", value: "33644196" },
    { name: "BANCO ITAÚ CONSIGNADO S.A.", value: "33885724" },
    { name: "BANCO MÁXIMA S.A.", value: "33923798" },
    { name: "BANCO IBM S.A.", value: "34270520" },
    { name: "BNY MELLON BANCO S.A.", value: "42272526" },
    { name: "DAYCOVAL LEASING - BANCO MÚLTIPLO S.A.", value: "43818780" },
    { name: "BANCO DE LA PROVINCIA DE BUENOS AIRES", value: "44189447" },
    { name: "PLURAL S.A. BANCO MÚLTIPLO", value: "45246410" },
    { name: "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION", value: "46518205" },
    { name: "BANCO ANDBANK (BRASIL) S.A.", value: "48795256" },
    { name: "ING BANK N.V.", value: "49336860" },
    { name: "BANCO ITAULEASING S.A.", value: "49925225" },
    { name: "BCV - BANCO DE CRÉDITO E VAREJO S.A.", value: "50585090" },
    { name: "BANCO DE LA REPUBLICA ORIENTAL DEL URUGUAY", value: "51938876" },
    { name: "BANCO ARBI S.A.", value: "54403563" },
    { name: "INTESA SANPAOLO BRASIL S.A. - BANCO MÚLTIPLO", value: "55230916" },
    { name: "BANCO TRICURY S.A.", value: "57839805" },
    { name: "BANCO VOLVO BRASIL S.A.", value: "58017179" },
    { name: "BANCO SAFRA S.A.", value: "58160789" },
    { name: "BANCO SMARTBANK S.A.", value: "58497702" },
    { name: "BANCO FIBRA S.A.", value: "58616418" },
    { name: "BANCO VOLKSWAGEN S.A.", value: "59109165" },
    { name: "BANCO LUSO BRASILEIRO S.A.", value: "59118133" },
    { name: "BANCO GMAC S.A.", value: "59274605" },
    { name: "BANCO PAN S.A.", value: "59285411" },
    { name: "BANCO VOTORANTIM S.A.", value: "59588111" },
    { name: "BANCO ITAUBANK S.A.", value: "60394079" },
    { name: "BANCO MUFG BRASIL S.A.", value: "60498557" },
    { name: "BANCO SUMITOMO MITSUI BRASILEIRO S.A.", value: "60518222" },
    { name: "ITAÚ UNIBANCO S.A.", value: "60701190" },
    { name: "BANCO BRADESCO S.A.", value: "60746948" },
    { name: "BANCO MERCEDES-BENZ DO BRASIL S.A.", value: "60814191" },
    { name: "OMNI BANCO S.A.", value: "60850229" },
    { name: "ITAÚ UNIBANCO HOLDING S.A.", value: "60872504" },
    { name: "BANCO SOFISA S.A.", value: "60889128" },
    { name: "BANCO INDUSVAL S.A.", value: "61024352" },
    { name: "BANCO CREFISA S.A.", value: "61033106" },
    { name: "BANCO MIZUHO DO BRASIL S.A.", value: "61088183" },
    { name: "BANCO INVESTCRED UNIBANCO S.A.", value: "61182408" },
    { name: "BANCO BMG S.A.", value: "61186680" },
    { name: "BANCO ITAÚ VEÍCULOS S.A.", value: "61190658" },
    { name: "BANCO FICSA S.A.", value: "61348538" },
    { name: "BANCO SOCIETE GENERALE BRASIL S.A.", value: "61533584" },
    { name: "BANCO PAULISTA S.A.", value: "61820817" },
    {
      name: "BANK OF AMERICA MERRILL LYNCH BANCO MÚLTIPLO S.A.",
      value: "62073200",
    },
    { name: "BANCO PINE S.A.", value: "62144175" },
    { name: "BANCO DAYCOVAL S.A.", value: "62232889" },
    { name: "BANCO FIDIS S/A", value: "62237425" },
    { name: "BANCO RCI BRASIL S.A.", value: "62307848" },
    { name: "DEUTSCHE BANK S.A. - BANCO ALEMAO", value: "62331228" },
    { name: "BANCO CIFRA S.A.", value: "62421979" },
    { name: "BANCO RENDIMENTO S.A.", value: "68900810" },
    { name: "BANCO BS2 S.A.", value: "71027866" },
    { name: "BANCO OLÉ BONSUCESSO CONSIGNADO S.A.", value: "71371686" },
    { name: "NOVO BANCO CONTINENTAL S.A. - BANCO MÚLTIPLO", value: "74828799" },
    { name: "BANCO CRÉDIT AGRICOLE BRASIL S.A.", value: "75647891" },
    { name: "BANCO SISTEMA S.A.", value: "76543115" },
    { name: "BANCO VR S.A.", value: "78626983" },
    { name: "BANCO OURINVEST S.A.", value: "78632767" },
    { name: "BANCO MAXINVEST S.A.", value: "80271455" },
    { name: "BANCO SANTANDER (BRASI  L) S.A.", value: "90400888" },
    { name: "BANCO FORD S.A.", value: "90731688" },
    { name: "BANCO JOHN DEERE S.A.", value: "91884981" },
    { name: "BANCO DO ESTADO DO RIO GRANDE DO SUL S.A.", value: "92702067" },
    { name: "BANCO A.J. RENNER S.A.", value: "92874270" },
    { name: "BANCO ORIGINAL S.A.", value: "92894922" },
  ],
  [CatalogsEnum.BankAccountTypes]: [
    { name: "Corriente", value: "0" },
    { name: "Ahorros", value: "1" },
  ],
  [CatalogsEnum.Currency]: [
    { name: "Real Brasileño", value: CurrencyEnum.BRL },
  ],
};
