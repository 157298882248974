import CircularProgress from "@material-ui/core/CircularProgress";
import SaveAltOutlinedIcon from "@material-ui/icons/SaveAltOutlined";
import React from "react";
import {
  Button,
  ButtonGroup,
  MenuItem,
  MenuList,
  Popover,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useDownloadButtonComponentState } from "./state/useDownloadButtonComponentState";
import { Alert } from "@material-ui/lab";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import DoneIcon from "@material-ui/icons/Done";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import { LabelsWallet } from "../../shared/infrastructure/enums/LabelWalletEnum";
import { downloadButtonComponentStyle } from "./DownloadButtonComponent.style";
import { downloadOptions } from "../../shared/infrastructure/constants/DowloadOptionsConstants";
import { AlertModal } from "../AlertModalComponent/AlertModal";
import { AlertModalConstants } from "../../shared/infrastructure/constants/WalletDashboardConstants";

export const DownloadButtonComponent = () => {
  const classes = downloadButtonComponentStyle();
  const {
    alertModalMessage,
    alertModalTitle,
    handleDownloadRequest,
    handleClick,
    handleClose,
    isDownloadingFile,
    open,
    id,
    anchor,
    setAnchor,
    showAlertOnDownloadFileSuccess,
    showAlertOnDownloadFileError,
    handleSetShowAlertDownloadFileError,
    handleSetShowAlertDownloadFileSuccess,
    handleOpenModal,
    openModal,
  } = useDownloadButtonComponentState();

  return (
    <React.Fragment>
      {(showAlertOnDownloadFileError || showAlertOnDownloadFileSuccess) && (
        <Alert
          icon={
            showAlertOnDownloadFileError ? (
              <NotInterestedIcon fontSize="inherit" />
            ) : (
              <DoneIcon fontSize="inherit" />
            )
          }
          className={
            showAlertOnDownloadFileError
              ? classes.errorAlert
              : classes.successAlert
          }
        >
          {showAlertOnDownloadFileError
            ? LabelsWallet.ERROR_DOWNLOAD_FILE
            : LabelsWallet.SUCCESS_DOWNLOAD_FILE}{" "}
          <Button
            className={classes.buttonAlert}
            onClick={
              showAlertOnDownloadFileError
                ? handleSetShowAlertDownloadFileError
                : handleSetShowAlertDownloadFileSuccess
            }
          >
            <HighlightOffIcon
              style={{ verticalAlign: "top" }}
              fontSize="medium"
            />
          </Button>
        </Alert>
      )}
      <div onClick={handleClick}>
        <ButtonGroup
          variant="outlined"
          color="primary"
          size={"medium"}
          style={{ display: "flex" }}
          disabled={isDownloadingFile}
        >
          <Button
            id={"btnDownload"}
            className={classes.buttonStyled}
            startIcon={<SaveAltOutlinedIcon />}
          >
            Descargar
          </Button>
          <Button
            id="button_arrow"
            onClick={handleClick}
            className={classes.buttonIconStyled}
          >
            {isDownloadingFile ? (
              <CircularProgress size={20} color={"primary"} />
            ) : (
              <ArrowDropDownIcon />
            )}
          </Button>
        </ButtonGroup>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuList id="split-button-menu" className={classes.popover}>
          {downloadOptions.map((item) => (
            <MenuItem
              key={item.option}
              className={classes.menuItemStyled}
              onClick={() => {
                setAnchor(null);
                handleDownloadRequest(item.value);
              }}
            >
              {item.option}
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
      <AlertModal
        buttonText={AlertModalConstants.buttonLbl}
        description={alertModalMessage}
        open={openModal}
        title={alertModalTitle}
        handleOpenModal={handleOpenModal}
      />
    </React.Fragment>
  );
};
