import React, { FC } from "react";
import { ModalOverlay } from "@kushki/frontend-molecules/modal";
import DetailHeader from "./DetailHeader/DetailHeader";
import DetailFooter from "./DetailFooter/DetailFooter";
import { useSelectedTransactionModalState } from "./state/useTransactionDetailModalState";
import { ConciliationDetailModalProps } from "./TransactionDetailModal.interfaces";
import { getSimplifiedFormatedDate } from "../../../shared/utils/date_utils";
import { get } from "lodash";

const TransactionDetailModal: FC<ConciliationDetailModalProps> = ({
  isOpen,
  onCloseModal,
  conciliationTransaction,
  dialogActions,
}: ConciliationDetailModalProps) => {
  const {
    currentTab,
    handlers: { getTabContent, handleChangeTab },
  } = useSelectedTransactionModalState();
  const TabContent = getTabContent(currentTab);
  const refundDate = getSimplifiedFormatedDate(conciliationTransaction.created);
  const conciliationAction: string = get(
    conciliationTransaction,
    "conciliationAction",
    ""
  );

  return (
    <ModalOverlay
      header={
        <DetailHeader
          currentTab={currentTab}
          handleChangeTab={handleChangeTab}
          title={"Detalle de Transacción"}
          headerLineText={conciliationTransaction.merchantName}
          subheaderLineText={`Creación: ${refundDate}`}
          conciliationAction={conciliationAction}
        />
      }
      body={<TabContent transaction={conciliationTransaction} />}
      footer={
        <DetailFooter
          conciliationTransaction={conciliationTransaction}
          dialogActions={dialogActions}
        />
      }
      openModal={isOpen}
      onCloseModal={onCloseModal}
      transition="left"
    />
  );
};

export default TransactionDetailModal;
