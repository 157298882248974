import { useLocation, useParams, useSearchParams } from "react-router-dom";
import {
  IBreadCrumbItemProps,
  IconBolt,
  IconCircleInformation,
  IconCircleWarn,
  IconEdit,
  IconTrash,
  ItemCategoryItem,
  ItemCategoryProps,
  useSnackbar,
} from "@kushki/connect-ui";
import { NotificationDetailsBCEnum } from "../../../shared/constants/enums/BreadCrumbEnum";
import { ROUTES } from "../../../shared/constants/routes_tab";
import { ITableRowProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import React, { useEffect, useMemo, useState } from "react";
import {
  OPTIONS_FILTER_PAYMENT_METHOD,
  OPTIONS_FILTER_STATUS,
} from "../../../shared/constants/options_filter_notifications";
import {
  IPostWebhooksListResponse,
  Webhook,
} from "../../../../types/post_webhooks_list_response";
import {
  ALL_TABLE_COLUMNS_WEBHOOK,
  ALL_TABLE_COLUMNS_WEBHOOK_CENTRALIZED,
  onRenderValue,
} from "../../../shared/constants/webhooks_container";
import { assign, get, has } from "lodash";
import { ITableCellProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableInfo/interfaces";
import { TABLE_ROW } from "../../../shared/constants/table_info";
import { CustomHeaderCellProps } from "../../../shared/constants/types/CustomHeaderCellProps.type";
import {
  ICustomerWebhooksTable,
  IUseWebhooks,
} from "./useWebhooksContainer.interfaces";
import { TEXT_CELL } from "../../../shared/constants/notifications_container";
import { WebhookActionsEnum } from "../../../shared/constants/enums/WebhookActions";
import { usePromise } from "../../../shared/hooks/usePromise/usePromise";
import axios, { AxiosError, CancelTokenSource } from "axios";
import useModal from "../../../shared/hooks/useModal/useModal";
import { deleteWebhookReq } from "../../../shared/utils/webhooks/deleteWebhookReq";
import { WebhookSnackbarStatus } from "../../../shared/enums/WebhookModals";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { getWebhookList } from "../../../store/thunks/app/app.thunks";
import { useAppNavigate } from "../../../shared/hooks/useNavigate";
import { useSelector } from "react-redux";
import { selectWebhookList } from "../../../store/selectors/selectors";
import { isMaster } from "../../../shared/utils/cognito";
import { getWebhookSignature } from "../../../store/thunks/webhook/webhook.thunks";
import { SnackBarColorEnum } from "../../../shared/enums/SnackBarColorEnum";
import {
  END_UTC_SUFFIX,
  FORMAT_DATE,
  MOMENT_LANGUAGE,
  START_UTC_SUFFIX,
} from "../../../shared/constants/date_values";
import moment from "moment";
import { TextFieldSearchByItem } from "@kushki/connect-ui/dist/Components/Molecules/Form/TextFieldSearchBy/TextFieldSearchBy.interfaces";
import instance from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { IPostWebhooksListRequest } from "../../../../types/post_webhooks_list_request";
import { closeWebhookSnackbar } from "../../../store/actions/webhook/webhook.actions";
import { QueryParamsEnum } from "../../../shared/enums/queryParamsEnum";

export const useWebhooksContainer = (): IUseWebhooks => {
  moment.locale(MOMENT_LANGUAGE);
  const [searchParams] = useSearchParams();
  const centralized =
    searchParams.get(QueryParamsEnum.IS_CENTRALIZED) === "true";
  let cancelToken: CancelTokenSource;
  const { publicMerchantId } = useParams();
  const dispatch = useAppDispatch();
  const { navigate } = useAppNavigate();
  const { showSnackbar } = useSnackbar();
  const [rowsOptions, setRowsOptions] = useState<ICustomerWebhooksTable[]>([]);
  const [filterPaymentMethods, setFilterPaymentMethods] = useState<
    ItemCategoryItem[]
  >(OPTIONS_FILTER_PAYMENT_METHOD);
  const [filterStatusOptions, setFilterStatusOptions] = useState<
    ItemCategoryItem[]
  >(OPTIONS_FILTER_STATUS);
  const [isLoading, setIsLoading] = useState(true);
  const [paymentMethodsFilter, setPaymentMethodsFilter] = useState<string[]>(
    []
  );
  const [itemsPerPage, setItemsPerPage] = useState<number>(5);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const webhookList = useSelector(selectWebhookList);
  const [selectedWebhook, setSelectedWebhook] = useState<Webhook | null>(null);
  const [fromDateQuery, setFromDateQuery] = useState<string>();
  const [toDateQuery, setToDateQuery] = useState<string>();
  const [searchItems, setSearchItems] = useState<TextFieldSearchByItem[]>([]);
  const [searchInput, setSearchInput] = useState<string>();
  const [searchWebhookId, setSearchWebhookId] = useState<string>();
  const deleteWebhookModalSimple = useModal();
  const deleteWebhookModalLoader = useModal();
  const simulateWebhookModal = useModal();
  const { webhookActions } = useAppSelector((state) => ({
    ...state.webhook,
  }));
  const location = useLocation();
  const deleteWebhook = usePromise<
    unknown,
    AxiosError<{ code: string; message: string }>
  >(() => deleteWebhookReq(selectedWebhook!.id!), {
    onError: () => {
      showSnackbar({
        color: SnackBarColorEnum.DANGER,
        icon: <IconCircleWarn />,
        message: WebhookSnackbarStatus.ERROR,
        variant: "simple",
        withIcon: true,
      });
    },
    onFinally: () => {
      deleteWebhookModalLoader.close();
    },
    onFulfilled: async () => {
      showSnackbar({
        color: SnackBarColorEnum.INFO,
        icon: <IconCircleInformation />,
        message: WebhookSnackbarStatus.SUCCESS,
        variant: "simple",
        withIcon: true,
      });
      await dispatch(
        getWebhookList({
          limit: itemsPerPage,
          merchantIds: [publicMerchantId!],
          offset: itemsPerPage * (selectedPage - 1),
        })
      );
    },
    onPending: () => {
      deleteWebhookModalSimple.close();
      deleteWebhookModalLoader.open();
    },
  });

  const handlers = {
    delete: () => {
      deleteWebhook.dispatch();
    },
    edit: (webhook: Webhook) => {
      dispatch(closeWebhookSnackbar(false));
      location.search = `?publicMerchantId=${publicMerchantId}&mode=edition&webhookId=${webhook.id}`;
      navigate("/../merchant-webhook/create");
    },
  };

  const buildBreadCrumbItems = (): IBreadCrumbItemProps[] => [
    {
      id: NotificationDetailsBCEnum.LEVEL0,
      locationRef: true,
      text: NotificationDetailsBCEnum.LEVEL0,
      url: `${ROUTES.MERCHANT_LIST}`,
    },
    {
      id: NotificationDetailsBCEnum.LEVEL1,
      text: NotificationDetailsBCEnum.LEVEL1,
      url: `/..${ROUTES.RESUME}/?publicMerchantId=${publicMerchantId}`,
    },
    {
      id: NotificationDetailsBCEnum.WEBHOOKS,
      text: NotificationDetailsBCEnum.WEBHOOKS,
      url: `${publicMerchantId}/${ROUTES.WEBHOOKS}`,
    },
  ];

  const filterItemsSelected = (
    filter: ItemCategoryItem[],
    positionArrayFilter: number,
    itemsSelected: ItemCategoryProps[]
  ): ItemCategoryItem[] => {
    return filter.map((item: ItemCategoryItem) => {
      if (
        itemsSelected[positionArrayFilter].items
          .map((i: ItemCategoryItem) => i.value)
          .includes(item.value)
      )
        return { ...item, selected: true };

      return item;
    });
  };

  const changeItemsPerPage = (items: number) => {
    setSelectedPage(1);
    setItemsPerPage(items);
  };

  const handlePageClick = (page: number) => {
    setSelectedPage(page);
  };

  const handleApplyFilter = (itemsSelected: ItemCategoryProps[]) => {
    const paymentMethods: string[] = itemsSelected[0].items.map(
      (item: ItemCategoryItem) => item.value
    );
    const status: string[] = itemsSelected[1].items.map(
      (item: ItemCategoryItem) => item.value
    );

    setFilterPaymentMethods(
      filterItemsSelected(filterPaymentMethods, 0, itemsSelected)
    );
    setFilterStatusOptions(
      filterItemsSelected(filterStatusOptions, 1, itemsSelected)
    );

    setPaymentMethodsFilter(paymentMethods);
    setStatusFilter(status);
    setSelectedPage(1);
  };

  const getCells = (
    row: ICustomerWebhooksTable,
    columns: CustomHeaderCellProps[]
  ): ITableCellProps[] => {
    return columns.map((column) => {
      const cellProps = onRenderValue(column.key, row);
      const tableCell = get(TABLE_ROW, column.cellType, TEXT_CELL);

      return tableCell(cellProps) as ITableCellProps;
    });
  };
  const mockFn = () => {};

  const getRows = (rows: ICustomerWebhooksTable[]): ITableRowProps[] => {
    return rows.map((row, index) => ({
      cells: getCells(
        row,
        centralized
          ? ALL_TABLE_COLUMNS_WEBHOOK_CENTRALIZED
          : ALL_TABLE_COLUMNS_WEBHOOK
      ),
      id: `row_${index}`,
      rowProps: {
        color: "default",
        onClick: mockFn,
      },
    }));
  };

  const getOptionListItems = (item: Webhook) => {
    const optionListItems = [
      {
        handleOptionClick: mockFn,
        Icon: IconBolt,
        onClick: () => {
          setSelectedWebhook(item);
          simulateWebhookModal.open();
        },
        optionText: WebhookActionsEnum.SIMULATE,
        type: "type1",
      },
      {
        handleOptionClick: mockFn,
        Icon: IconEdit,
        onClick: () => handlers.edit(item),
        optionText: WebhookActionsEnum.EDIT,
        type: "type1",
      },
      {
        handleOptionClick: mockFn,
        Icon: IconTrash,
        onClick: () => {
          setSelectedWebhook(item);
          deleteWebhookModalSimple.open();
        },
        optionText: WebhookActionsEnum.DELETE,
        type: "type2",
      },
    ];

    if (isMaster()) optionListItems.pop();

    return optionListItems;
  };

  const createRows = (webhooks: IPostWebhooksListResponse) => {
    if (!webhooks || !webhooks.hits) return;

    let values = webhooks.hits.map((item: Webhook) => {
      const itemWebhook = {
        ...item,
      } as ICustomerWebhooksTable;

      if (centralized) return itemWebhook;
      else
        return {
          ...itemWebhook,
          options: {
            isDisabled: false,
            optionListItems: getOptionListItems(item),
          },
        } as ICustomerWebhooksTable;
    });

    values = values.filter((webhook: Webhook) =>
      searchWebhookId ? webhook.id === searchWebhookId : true
    );
    setRowsOptions(values);
  };

  const postWebhooksList = async () => {
    createRows(webhookList);
    setTotalItems(webhookList.total!);
    setIsLoading(false);
    setSearchWebhookId("");
  };

  const passingFilter = (
    filter: Partial<IPostWebhooksListRequest>,
    path: string,
    filters: string[] | string
  ) => {
    if (has(filter, "filter")) {
      assign(filter.filter, { [path]: filters });
    } else {
      assign(filter, { filter: { [path]: filters } });
    }
  };

  const buildFilters = (textSearch?: string) => {
    const filter: object = {};

    if (fromDateQuery) assign(filter, { from: fromDateQuery });
    if (toDateQuery) assign(filter, { to: toDateQuery });
    if (textSearch) passingFilter(filter, "alias", textSearch);
    if (paymentMethodsFilter.length > 0)
      passingFilter(filter, "paymentMethod", paymentMethodsFilter);
    if (statusFilter.length > 0) passingFilter(filter, "status", statusFilter);

    return filter;
  };

  const fetchSearchBy = async (alias: string) => {
    if (cancelToken !== undefined) {
      cancelToken.cancel();
    }

    cancelToken = axios.CancelToken.source();

    return await instance.post<IPostWebhooksListResponse>(
      API_ROUTES.LIST_WEBHOOK,
      {
        limit: 5,
        merchantIds: [publicMerchantId],
        ...buildFilters(`*${alias}*`),
      },
      {
        cancelToken: cancelToken.token,
      }
    );
  };

  const mapSearchedItems = (searchedWebhooks: Webhook[]) => {
    const items: TextFieldSearchByItem[] = [];

    searchedWebhooks.map((webhook: Webhook) => {
      items.push({
        id: webhook.id,
        secondaryText: "",
        text: get(webhook, "alias", ""),
      });
    });
    setSearchItems(items);
  };

  const handleSearchByDate = (startDate: string, endDate: string) => {
    const from = moment(startDate, FORMAT_DATE.FORMAT_DATE_RANGE_PICKER);
    const to = moment(endDate, FORMAT_DATE.FORMAT_DATE_RANGE_PICKER);

    setFromDateQuery(
      `${from.format(FORMAT_DATE.FORMAT_DATE_FILTER)}${START_UTC_SUFFIX}`
    );
    setToDateQuery(
      `${to.format(FORMAT_DATE.FORMAT_DATE_FILTER)}${END_UTC_SUFFIX}`
    );
    setSelectedPage(1);
  };

  const handleChangeSearchBy = async (event: object) => {
    const searchInput = get(event, "target.value");

    if (searchInput) {
      const searchedWebhooks = await fetchSearchBy(searchInput);

      setSearchItems([]);
      mapSearchedItems(searchedWebhooks.data.hits!);
    } else {
      if (cancelToken !== undefined) {
        cancelToken.cancel();
      }
      setSearchInput("");
      setSearchItems([]);
    }
  };

  const handleSelectSearchBy = async (event: TextFieldSearchByItem) => {
    setSelectedPage(1);
    setSearchInput(event.text);
    setSearchItems([]);
    setSearchWebhookId(event.id);
  };

  useEffect(() => {
    postWebhooksList();
  }, [paymentMethodsFilter, statusFilter, webhookList, centralized]);

  useEffect(() => {
    if (publicMerchantId) {
      setIsLoading(true);
      dispatch(
        getWebhookList({
          limit: itemsPerPage,
          merchantIds: [publicMerchantId],
          offset: itemsPerPage * (selectedPage - 1),
          ...buildFilters(searchInput),
        })
      );
    }
  }, [
    publicMerchantId,
    selectedPage,
    fromDateQuery,
    toDateQuery,
    searchInput,
    itemsPerPage,
    paymentMethodsFilter,
    statusFilter,
  ]);

  useEffect(() => {
    dispatch(
      getWebhookSignature({
        publicMerchantId: publicMerchantId!,
      })
    );
  }, []);

  useMemo(() => {
    if (paymentMethodsFilter.length === 0 && statusFilter.length === 0) {
      const paymentMethods: string[] = filterPaymentMethods
        .filter((item: ItemCategoryItem) => item.selected)
        .map((item: ItemCategoryItem) => item.value);
      const status: string[] = filterStatusOptions
        .filter((item: ItemCategoryItem) => item.selected)
        .map((item: ItemCategoryItem) => item.value);

      if (paymentMethods.length > 0 || status.length > 0) {
        setPaymentMethodsFilter(paymentMethods);
        setStatusFilter(status);
      }
    }
  }, [filterPaymentMethods, filterStatusOptions]);

  return {
    buildBreadCrumbItems,
    centralized,
    changeItemsPerPage,
    columns: centralized
      ? ALL_TABLE_COLUMNS_WEBHOOK_CENTRALIZED
      : ALL_TABLE_COLUMNS_WEBHOOK,
    filterPaymentMethods,
    filterStatusOptions,
    handleApplyFilter,
    handleChangeSearchBy,
    handlePageClick,
    handlers,
    handleSearchByDate,
    handleSelectSearchBy,
    isLoading,
    itemsPerPage,
    mockFn,
    modals: {
      deleteWebhookModalLoader,
      deleteWebhookModalSimple,
      simulateWebhookModal,
    },
    rows: getRows(rowsOptions),
    searchItems,
    selectedPage,
    selectedWebhook,
    totalItems,
    webhookSignature: webhookActions.webhookSignature,
  };
};
