import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { styles } from "./RefundCustomerInfo.styles";
import { RefundCustomerInfoProps } from "./RefundCustomerInfo.interfaces";

const RefundCustomerInfo = ({
  bankName,
  customerName,
  documentNumber,
  paymentMethod,
  PaymentMethodIcon,
}: RefundCustomerInfoProps) => {
  return (
    <Box sx={styles.informationContainer}>
      <Stack>
        <Typography sx={styles.text}>Cliente: </Typography>
        <Typography sx={styles.value}>{customerName}</Typography>
      </Stack>
      <Grid container sx={styles.paymentInfoContainer}>
        <Grid item xs={4}>
          <Typography sx={styles.text}>Medio de pago:</Typography>
          <Typography sx={styles.value}>
            {PaymentMethodIcon}
            {paymentMethod}
          </Typography>
        </Grid>
        <Grid item xs={4} pb={2}>
          <Typography sx={styles.text}>Banco:</Typography>
          <Typography sx={styles.value}>{bankName}</Typography>
        </Grid>
        <Grid item xs={4} pb={2}>
          <Typography sx={styles.text}>No. de documento:</Typography>
          <Typography sx={styles.value}>{documentNumber}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RefundCustomerInfo;
