import { FieldsNamesRender } from "../FieldsEnumRenderEnum";
import { PersonTypeBRAEnum } from "./PersonTypeBRAEnum";
import {
  BasicDataLabelsBRA,
  ErrorMessageBRA,
  RegularExp,
  TypeElement,
} from "./ErrorMessageBRA";
import {
  PersonTypeInterface,
  PersonTypeObjectInterface,
} from "../BasicStateGeneral/FieldsBasicData";
import { ErrorMessageMX } from "../BasicStateMX/ErrorMessageMX";
import { WEB_SITE_REGEX } from "../../constants/billing_form_constants";

export const PersonTypeBRAlist: Array<PersonTypeObjectInterface> = [
  { name: "Jurídica", value: "02" },
  { name: "Física", value: "01" },
];

export const docTypeBRA: PersonTypeObjectInterface[] = [
  { name: "CPF", value: "0" },
  { name: "Pasaporte", value: "1" },
  { default: true, name: "CNPJ", value: "2" },
];

export const fieldsRenderBra: Array<PersonTypeInterface> = [
  {
    filter: PersonTypeBRAEnum.JURIDICA,
    label: BasicDataLabelsBRA.RAZON_SOCIAL,
    name: FieldsNamesRender.social_reason,
    regExp: RegularExp.CAPITAL_LETTER_NUMBERS,
    rules: {
      maxLength: {
        message: ErrorMessageBRA.MAX_1_50_CHAR,
        value: 50,
      },
      minLength: {
        message: ErrorMessageBRA.MAX_1_50_CHAR,
        value: 1,
      },

      required: {
        message: ErrorMessageBRA.PF_RAZON_SOCIAL,
        value: true,
      },
    },
    type: TypeElement.TEXT_CAPITAL,
    value: 6,
  },
  {
    filter: PersonTypeBRAEnum.JURIDICA,
    label: BasicDataLabelsBRA.CNPJ,
    name: "rfc",
    regExp: RegularExp.ONLY_NUMBERS,
    rules: {
      maxLength: {
        message: ErrorMessageBRA.CNPJ_LENGTH,
        value: 20,
      },
      minLength: {
        message: ErrorMessageBRA.CNPJ_LENGTH,
        value: 11,
      },
      required: {
        message: ErrorMessageBRA.PF_NUM_ID,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 6,
  },
  {
    filter: PersonTypeBRAEnum.JURIDICA,
    label: BasicDataLabelsBRA.TIPO_EMPRESA,
    name: "typeCompany",
    rules: {
      required: {
        message: ErrorMessageBRA.PF_COMPANY,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    value: 6,
  },
  {
    filter: PersonTypeBRAEnum.JURIDICA,
    label: BasicDataLabelsBRA.TIPO_INDUSTRIA,
    name: "typeIndustry",
    rules: {
      required: {
        message: ErrorMessageBRA.PF_INDUSTRIA,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    value: 6,
  },
  {
    filter: PersonTypeBRAEnum.JURIDICA,
    label: BasicDataLabelsBRA.MCC,
    name: "mcc",
    rules: {
      required: {
        message: ErrorMessageBRA.PF_MCC,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    typeArray: TypeElement.COMBO_ARRAY,
    value: 6,
  },
  {
    filter: PersonTypeBRAEnum.JURIDICA,
    label: BasicDataLabelsBRA.FECHA_CONST,
    name: "date",
    type: TypeElement.DATE_PICKER,
    value: 6,
  },
  {
    filter: PersonTypeBRAEnum.JURIDICA,
    label: BasicDataLabelsBRA.WEB,
    name: "web",
    regExp: RegularExp.WEB,
    rules: {
      pattern: {
        message: ErrorMessageMX.REG_EXP_WEB,
        value: WEB_SITE_REGEX,
      },
      required: {
        message: ErrorMessageBRA.PF_WEB,
        value: true,
      },
    },
    type: TypeElement.WEB,
    value: 6,
  },

  {
    filter: PersonTypeBRAEnum.FISICA,
    label: BasicDataLabelsBRA.NOMBRE,
    name: "names",
    regExp: RegularExp.ONLY_LETTERS,
    rules: {
      maxLength: {
        message: ErrorMessageBRA.MAX_1_50_CHAR,
        value: 50,
      },
      minLength: {
        message: ErrorMessageBRA.MAX_1_50_CHAR,
        value: 1,
      },
      pattern: {
        message: ErrorMessageBRA.LETRAS,
        value: new RegExp(RegularExp.ONLY_LETTERS),
      },
      required: {
        message: ErrorMessageBRA.PF_NOMBRE,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 6,
  },
  {
    filter: PersonTypeBRAEnum.FISICA,
    label: BasicDataLabelsBRA.APELLIDO,
    name: "surname",
    regExp: RegularExp.ONLY_LETTERS,
    rules: {
      maxLength: {
        message: ErrorMessageBRA.MAX_1_50_CHAR,
        value: 50,
      },
      minLength: {
        message: ErrorMessageBRA.MAX_1_50_CHAR,
        value: 1,
      },
      pattern: {
        message: ErrorMessageBRA.LETRAS,
        value: new RegExp(RegularExp.ONLY_LETTERS),
      },
      required: {
        message: ErrorMessageBRA.PF_APELLIDO,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 6,
  },
  {
    filter: PersonTypeBRAEnum.FISICA,
    label: BasicDataLabelsBRA.CPF,
    name: "rfc",
    regExp: RegularExp.ONLY_NUMBERS,
    rules: {
      maxLength: {
        message: ErrorMessageBRA.CPF_LENGTH,
        value: 11,
      },
      minLength: {
        message: ErrorMessageBRA.CPF_LENGTH,
        value: 11,
      },
      required: {
        message: ErrorMessageBRA.PF_NUM_ID,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 12,
  },
  {
    filter: PersonTypeBRAEnum.FISICA,
    label: BasicDataLabelsBRA.TIPO_INDUSTRIA,
    name: "typeIndustry",
    rules: {
      required: {
        message: ErrorMessageBRA.PF_INDUSTRIA,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    value: 6,
  },
  {
    filter: PersonTypeBRAEnum.FISICA,
    label: BasicDataLabelsBRA.MCC,
    name: "mcc",
    rules: {
      required: {
        message: ErrorMessageBRA.PF_MCC,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    typeArray: TypeElement.COMBO_ARRAY,
    value: 6,
  },
  {
    filter: PersonTypeBRAEnum.FISICA,
    label: BasicDataLabelsBRA.FECHA_CONST,
    name: "date",
    type: TypeElement.DATE_PICKER,
    value: 6,
  },
  {
    filter: PersonTypeBRAEnum.FISICA,
    label: BasicDataLabelsBRA.WEB,
    name: "web",
    regExp: RegularExp.WEB,
    rules: {
      pattern: {
        message: ErrorMessageMX.REG_EXP_WEB,
        value: WEB_SITE_REGEX,
      },
      required: {
        message: ErrorMessageBRA.PF_WEB,
        value: true,
      },
    },
    type: TypeElement.WEB,
    value: 12,
  },
];
