import {
  ItemCategoryItem,
  ItemCategoryProps,
} from "../../components/Filters/ItemCategory/interfaces";
import { IValueCell } from "../../components/TableCell/CellHeader/interfaces";
import { ICountry } from "../infrastructure/Countries";
import { ALL_COUNTRIES } from "../infrastructure/AllCountries";
import { ITableRowProps } from "../../components/Table/TableSimple/interfaces";
import { get } from "lodash";

export const applyFiltersForItems = (
  filter: ItemCategoryProps,
  valuesFilter: IValueCell[][]
): IValueCell[][] => {
  const valueElementsFound: IValueCell[][] = [];
  let countSelectedItems: number = 0;

  if (filter.items.length === 0) return valuesFilter;

  valuesFilter.map((item: IValueCell[]) => {
    filter.items.map((itemFilter: ItemCategoryItem) => {
      if (itemFilter.selected === true) {
        for (const cell of item) {
          if (`${cell.value}`.includes(itemFilter.value)) {
            valueElementsFound.push(item);
          }
        }
        countSelectedItems++;
      }
    });
  });

  if (countSelectedItems > 0) return valueElementsFound;
  else return valuesFilter;
};

export const applyFiltersForItems2 = (
  filter: ItemCategoryProps,
  rowsValues: ITableRowProps[]
): ITableRowProps[] => {
  const valueElementsFound: ITableRowProps[] = [];
  let countSelectedItems: number = 0;

  if (filter.items.length === 0) return rowsValues;

  rowsValues.map((rowValue) => {
    filter.items.map((itemFilter: ItemCategoryItem) => {
      if (itemFilter.selected === true) {
        for (const cell of rowValue.cells) {
          if (
            get(cell, "props.line1", "").includes(itemFilter.value) ||
            get(cell, "props.country", "").includes(itemFilter.value) ||
            get(cell, "props.value", "").includes(itemFilter.value)
          ) {
            valueElementsFound.push(rowValue);
          }
        }
        countSelectedItems++;
      }
    });
  });

  if (countSelectedItems > 0) return valueElementsFound;
  else return rowsValues;
};

export const findNameInAllCountries = (countryValue: string): string => {
  const country: ICountry | undefined = ALL_COUNTRIES.find(
    (country: ICountry) => country.value === countryValue
  );

  return country ? country.name : "Country no found";
};
