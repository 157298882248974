import { CountriesEnum } from "../enums/CountriesEnum";
import { CurrencyNameEnum } from "../enums/CurrencyNameEnum";

export const getCurrencyName = (country: string) => {
  switch (country) {
    case CountriesEnum.ECUADOR:
      return CurrencyNameEnum.USD;
    case CountriesEnum.BRAZIL:
      return CurrencyNameEnum.BRL;
    case CountriesEnum.CHILE:
      return CurrencyNameEnum.CLP;
    case CountriesEnum.COLOMBIA:
      return CurrencyNameEnum.COP;
    case CountriesEnum.COSTA_RICA:
      return CurrencyNameEnum.CRC;
    case CountriesEnum.EL_SALVADOR:
      return CurrencyNameEnum.COP;
    case CountriesEnum.MEXICO:
      return CurrencyNameEnum.MXN;
    case CountriesEnum.PANAMA:
      return CurrencyNameEnum.PAB;
    case CountriesEnum.PERU:
      return CurrencyNameEnum.PEN;
    default:
      return country;
  }
};
