import { CountryTitleEnum } from "@shared/enum/CountryEnum";
import { ISelectOption } from "@components/InputsForm/Inputs/InputSelect/InputSelect.interfaces";

export const countriesOption: ISelectOption[] = [
  { label: CountryTitleEnum.AFGANISTAN, value: "AF" },
  { label: CountryTitleEnum.ALBANIA, value: "AL" },
  { label: CountryTitleEnum.ARGELIA, value: "DZ" },
  { label: CountryTitleEnum.ANDORRA, value: "AD" },
  { label: CountryTitleEnum.ANGOLA, value: "AO" },
  { label: CountryTitleEnum.ANGUILA, value: "AI" },
  { label: CountryTitleEnum.ANTARTIDA, value: "AQ" },
  { label: CountryTitleEnum.ANTIGUA_Y_BARBUDA, value: "AG" },
  { label: CountryTitleEnum.ARGENTINA, value: "AR" },
  { label: CountryTitleEnum.ARMENIA, value: "AM" },
  { label: CountryTitleEnum.ARUBA, value: "AW" },
  { label: CountryTitleEnum.AUSTRALIA, value: "AU" },
  { label: CountryTitleEnum.AUSTRIA, value: "AT" },
  { label: CountryTitleEnum.AZERBAIYAN, value: "AZ" },
  { label: CountryTitleEnum.BAHAMAS, value: "BS" },
  { label: CountryTitleEnum.BAHREIN, value: "BH" },
  { label: CountryTitleEnum.BANGLADESH, value: "BD" },
  { label: CountryTitleEnum.BARBADOS, value: "BB" },
  { label: CountryTitleEnum.BIELORRUSIA, value: "BY" },
  { label: CountryTitleEnum.BELGICA, value: "BE" },
  { label: CountryTitleEnum.BELICE, value: "BZ" },
  { label: CountryTitleEnum.BENIN, value: "BJ" },
  { label: CountryTitleEnum.ISLAS_BERMUDAS, value: "BM" },
  { label: CountryTitleEnum.BHUTAN, value: "BT" },
  {
    label: CountryTitleEnum.BOLIVIA,
    value: "BO",
  },
  { label: CountryTitleEnum.BOSNIA_Y_HERZEGOVIA, value: "BA" },
  { label: CountryTitleEnum.BOTSUANA, value: "BW" },
  { label: CountryTitleEnum.ISLA_BOUVET, value: "BV" },
  { label: CountryTitleEnum.brasil, value: "BR" },
  { label: CountryTitleEnum.BRUNEI, value: "BN" },
  { label: CountryTitleEnum.BULGARIA, value: "BG" },
  { label: CountryTitleEnum.BURKINA_FASO, value: "BF" },
  { label: CountryTitleEnum.BURUNDI, value: "BI" },
  { label: CountryTitleEnum.CABO_VERDE, value: "CV" },
  { label: CountryTitleEnum.CAMBOYA, value: "KH" },
  { label: CountryTitleEnum.CAMERUN, value: "CM" },
  { label: CountryTitleEnum.CANADA, value: "CA" },
  { label: CountryTitleEnum.ISLAS_CAIMAN, value: "KY" },
  {
    label: CountryTitleEnum.REPUBLICA_CENTROAFRICANA,
    value: "CF",
  },
  { label: CountryTitleEnum.CHAD, value: "TD" },
  { label: CountryTitleEnum.chile, value: "CL" },
  { label: CountryTitleEnum.CHINA, value: "CN" },
  { label: CountryTitleEnum.ISLA_DE_NAVIDAD, value: "CX" },
  {
    label: CountryTitleEnum.ISLAS_COCOS,
    value: "CC",
  },
  { label: CountryTitleEnum.colombia, value: "CO" },
  { label: CountryTitleEnum.COMORAS, value: "KM" },
  {
    label: CountryTitleEnum.REPUBLICA_DEL_CONGO,
    value: "CD",
  },
  { label: CountryTitleEnum.ISLAS_COOK, value: "CK" },
  { label: CountryTitleEnum.costa_rica, value: "CR" },
  { label: CountryTitleEnum.CROACIA, value: "HR" },
  { label: CountryTitleEnum.CUBA, value: "CU" },
  { label: CountryTitleEnum.CURAZAO, value: "CW" },
  { label: CountryTitleEnum.REPUBLICA_CHECA, value: "CZ" },
  { label: CountryTitleEnum.COSTA_DE_MARFIL, value: "CI" },
  { label: CountryTitleEnum.DINAMARCA, value: "DK" },
  { label: CountryTitleEnum.DOMINICA, value: "DM" },
  {
    label: CountryTitleEnum.REPUBLICA_DOMINICANA,
    value: "DO",
  },
  { label: CountryTitleEnum.ecuador, value: "EC" },
  { label: CountryTitleEnum.EGIPTO, value: "EG" },
  { label: CountryTitleEnum.el_salvador, value: "SV" },
  { label: CountryTitleEnum.GUINEA_ECUATORIAL, value: "GQ" },
  { label: CountryTitleEnum.ERITREA, value: "ER" },
  { label: CountryTitleEnum.ESTONIA, value: "EE" },
  { label: CountryTitleEnum.ETIOPIA, value: "ET" },
  {
    label: CountryTitleEnum.ISLAS_MALVINAS,
    value: "FK",
  },
  { label: CountryTitleEnum.FIYI, value: "FJ" },
  { label: CountryTitleEnum.FINLANDIA, value: "FI" },
  { label: CountryTitleEnum.FRANCIA, value: "FR" },
  { label: CountryTitleEnum.GUAYANA_FRANCESA, value: "GF" },
  { label: CountryTitleEnum.POLINESIA_FRANCESA, value: "PF" },
  { label: CountryTitleEnum.GABON, value: "GA" },
  { label: CountryTitleEnum.GAMBIA, value: "GM" },
  { label: CountryTitleEnum.GEORGIA, value: "GE" },
  { label: CountryTitleEnum.ALEMANIA, value: "DE" },
  { label: CountryTitleEnum.GHANA, value: "GH" },
  { label: CountryTitleEnum.GIBRALTAR, value: "GI" },
  { label: CountryTitleEnum.GRECIA, value: "GR" },
  { label: CountryTitleEnum.GROENLANDIA, value: "GL" },
  { label: CountryTitleEnum.GRANADA, value: "GD" },
  { label: CountryTitleEnum.GUADALUPE, value: "GP" },
  { label: CountryTitleEnum.GUAM, value: "GU" },
  { label: CountryTitleEnum.guatemala, value: "GT" },
  { label: CountryTitleEnum.GUERNSEY, value: "GG" },
  { label: CountryTitleEnum.GUINEA, value: "GN" },
  { label: CountryTitleEnum.GUINEA_BISSAU, value: "GW" },
  { label: CountryTitleEnum.GUYANA, value: "GY" },
  { label: CountryTitleEnum.HAITI, value: "HT" },
  {
    label: CountryTitleEnum.ISLAS_HEARD_Y_MCDONALD,
    value: "HM",
  },
  { label: CountryTitleEnum.honduras, value: "HN" },
  { label: CountryTitleEnum.HONG_KONG, value: "HK" },
  { label: CountryTitleEnum.HUNGRIA, value: "HU" },
  { label: CountryTitleEnum.ISLANDIA, value: "IS" },
  { label: CountryTitleEnum.INDIA, value: "IN" },
  { label: CountryTitleEnum.INDONESIA, value: "ID" },
  {
    label: CountryTitleEnum.IRAN,
    value: "IR",
  },
  { label: CountryTitleEnum.IRAK, value: "IQ" },
  { label: CountryTitleEnum.IRLANDA, value: "IE" },
  { label: CountryTitleEnum.ISLA_DE_MAN, value: "IM" },
  { label: CountryTitleEnum.ISRAEL, value: "IL" },
  { label: CountryTitleEnum.ITALIA, value: "IT" },
  { label: CountryTitleEnum.JAMAICA, value: "JM" },
  { label: CountryTitleEnum.JAPON, value: "JP" },
  { label: CountryTitleEnum.JERSEY, value: "JE" },
  { label: CountryTitleEnum.JORDANIA, value: "JO" },
  { label: CountryTitleEnum.KAZAJISTAN, value: "KZ" },
  { label: CountryTitleEnum.KENIA, value: "KE" },
  { label: CountryTitleEnum.KIRIBATI, value: "KI" },
  {
    label: CountryTitleEnum.COREA_DEL_NORTE,
    value: "KP",
  },
  { label: CountryTitleEnum.COREA_DEL_SUR, value: "KR" },
  { label: CountryTitleEnum.KUWAIT, value: "KW" },
  {
    label: CountryTitleEnum.LAOS,
    value: "LA",
  },
  { label: CountryTitleEnum.LESOTO, value: "LS" },
  { label: CountryTitleEnum.LIBERIA, value: "LR" },
  { label: CountryTitleEnum.LIBIA, value: "LY" },
  { label: CountryTitleEnum.LIECHTENSTEIN, value: "LI" },
  { label: CountryTitleEnum.LITUANIA, value: "LT" },
  { label: CountryTitleEnum.LUXEMBURGO, value: "LU" },
  { label: CountryTitleEnum.MACAO, value: "MO" },
  { label: CountryTitleEnum.MADAGASCAR, value: "MG" },
  { label: CountryTitleEnum.MALASIA, value: "MY" },
  { label: CountryTitleEnum.MALI, value: "ML" },
  { label: CountryTitleEnum.MALTA, value: "MT" },
  { label: CountryTitleEnum.ISLAS_MARSHALL, value: "MH" },
  { label: CountryTitleEnum.MARTINICA, value: "MQ" },
  { label: CountryTitleEnum.MAURITANIA, value: "MR" },
  { label: CountryTitleEnum.MAURICIO, value: "MU" },
  { label: CountryTitleEnum.MAYOTTE, value: "YT" },
  { label: CountryTitleEnum.mexico, value: "MX" },
  {
    label: CountryTitleEnum.MICRONESIA,
    value: "FM",
  },
  {
    label: CountryTitleEnum.MOLDAVIA,
    value: "MD",
  },
  { label: CountryTitleEnum.MONACO, value: "MC" },
  { label: CountryTitleEnum.MONGOLIA, value: "MN" },
  { label: CountryTitleEnum.MONTENEGRO, value: "ME" },
  { label: CountryTitleEnum.MONTSERRAT, value: "MS" },
  { label: CountryTitleEnum.MARRUECOS, value: "MA" },
  { label: CountryTitleEnum.MOZAMBIQUE, value: "MZ" },
  { label: CountryTitleEnum.NAMIBIA, value: "NA" },
  { label: CountryTitleEnum.NAURU, value: "NR" },
  { label: CountryTitleEnum.NEPAL, value: "NP" },
  { label: CountryTitleEnum.PAISES_BAJOS, value: "NL" },
  { label: CountryTitleEnum.NUEVA_CALEDONIA, value: "NC" },
  { label: CountryTitleEnum.NUEVA_ZELANDA, value: "NZ" },
  { label: CountryTitleEnum.nicaragua, value: "NI" },
  { label: CountryTitleEnum.NIGER, value: "NE" },
  { label: CountryTitleEnum.NIGERIA, value: "NG" },
  { label: CountryTitleEnum.NIUE, value: "NU" },
  {
    label: CountryTitleEnum.ISLAS_MARIANAS_DEL_NORTE,
    value: "MP",
  },
  { label: CountryTitleEnum.NORUEGA, value: "NO" },
  { label: CountryTitleEnum.OMAN, value: "OM" },
  { label: CountryTitleEnum.PAKISTAN, value: "PK" },
  { label: CountryTitleEnum.PALAU, value: "PW" },
  { label: CountryTitleEnum.PALESTINA, value: "PS" },
  { label: CountryTitleEnum.panama, value: "PA" },
  { label: CountryTitleEnum.PAPUA_NUEVA_GUINEA, value: "PG" },
  { label: CountryTitleEnum.PARAGUAY, value: "PY" },
  { label: CountryTitleEnum.peru, value: "PE" },
  { label: CountryTitleEnum.FILIPINAS, value: "PH" },
  { label: CountryTitleEnum.ISLAS_PITCAIRN, value: "PN" },
  { label: CountryTitleEnum.POLONIA, value: "PL" },
  { label: CountryTitleEnum.PORTUGAL, value: "PT" },
  { label: CountryTitleEnum.PUERTO_RICO, value: "PR" },
  { label: CountryTitleEnum.QATAR, value: "QA" },
  {
    label: CountryTitleEnum.MACEDONIA,
    value: "MK",
  },
  { label: CountryTitleEnum.RUMANIA, value: "RO" },
  {
    label: CountryTitleEnum.RUSIA,
    value: "RU",
  },
  { label: CountryTitleEnum.RUANDA, value: "RW" },
  { label: CountryTitleEnum.REUNION, value: "RE" },
  { label: CountryTitleEnum.SANTA_LUCIA, value: "LC" },
  {
    label: CountryTitleEnum.SAN_MARTIN,
    value: "MF",
  },
  {
    label: CountryTitleEnum.SAN_VICENTE_Y_LAS_GRANADINAS,
    value: "VC",
  },
  { label: CountryTitleEnum.SAMOA, value: "WS" },
  { label: CountryTitleEnum.SAN_MARINO, value: "SM" },
  { label: CountryTitleEnum.SANTO_TOME_Y_PRINCIPE, value: "ST" },
  { label: CountryTitleEnum.ARABIASAUDITA, value: "SA" },
  { label: CountryTitleEnum.SENEGAL, value: "SN" },
  { label: CountryTitleEnum.SERBIA, value: "RS" },
  { label: CountryTitleEnum.SEYCHELLES, value: "SC" },
  { label: CountryTitleEnum.SIERRA_LEONA, value: "SL" },
  { label: CountryTitleEnum.SINGAPUR, value: "SG" },
  { label: CountryTitleEnum.ESLOVAQUIA, value: "SK" },
  { label: CountryTitleEnum.ESLOVENIA, value: "SI" },
  { label: CountryTitleEnum.ISLAS_SALOMON, value: "SB" },
  { label: CountryTitleEnum.SOMALIA, value: "SO" },
  { label: CountryTitleEnum.SUDAFRICA, value: "ZA" },
  { label: CountryTitleEnum.REPUBLICA_DE_SUDAN_DEL_SUR, value: "SS" },
  { label: CountryTitleEnum.ESPANIA, value: "ES" },
  { label: CountryTitleEnum.SRI_LANKA, value: "LK" },
  { label: CountryTitleEnum.SUDAN, value: "SD" },
  { label: CountryTitleEnum.SURINAM, value: "SR" },
  { label: CountryTitleEnum.SVALBARD_Y_JAN_MAYEN, value: "SJ" },
  { label: CountryTitleEnum.SUECIA, value: "SE" },
  { label: CountryTitleEnum.SUIZA, value: "CH" },
  { label: CountryTitleEnum.SIRIA, value: "SY" },
  {
    label: CountryTitleEnum.TAIWAN,
    value: "TW",
  },
  { label: CountryTitleEnum.TAYIKISTAN, value: "TJ" },
  {
    label: CountryTitleEnum.TANZANIA,
    value: "TZ",
  },
  { label: CountryTitleEnum.TAILANDIA, value: "TH" },
  { label: CountryTitleEnum.TIMOR_ORIENTAL, value: "TL" },
  { label: CountryTitleEnum.TOGO, value: "TG" },
  { label: CountryTitleEnum.TOKELAU, value: "TK" },
  { label: CountryTitleEnum.TONGA, value: "TO" },
  { label: CountryTitleEnum.TRINIDAD_Y_TOBAGO, value: "TT" },
  { label: CountryTitleEnum.TUNEZ, value: "TN" },
  { label: CountryTitleEnum.TURQUIA, value: "TR" },
  { label: CountryTitleEnum.TURKMENISTAN, value: "TM" },
  {
    label: CountryTitleEnum.ISLAS_TURCAS_Y_CAICOS,
    value: "TC",
  },
  { label: CountryTitleEnum.TUVALU, value: "TV" },
  { label: CountryTitleEnum.UGANDA, value: "UG" },
  { label: CountryTitleEnum.UCRANIA, value: "UA" },
  {
    label: CountryTitleEnum.EMIRATOS_ARABES_UNIDOS,
    value: "AE",
  },
  {
    label: CountryTitleEnum.REINO_UNIDO,
    value: "GB",
  },
  {
    label: CountryTitleEnum.ESTADOS_UNIDOS,
    value: "US",
  },
  { label: CountryTitleEnum.URUGUAY, value: "UY" },
  { label: CountryTitleEnum.UZBEKISTAN, value: "UZ" },
  { label: CountryTitleEnum.VANUATU, value: "VU" },
  {
    label: CountryTitleEnum.VENEZUELA,
    value: "VE",
  },
  { label: CountryTitleEnum.VIETNAM, value: "VN" },
  {
    label: CountryTitleEnum.ISLAS_VIRGENES_DE_LOS_ESTADOS_UNIDOS,
    value: "VI",
  },
  { label: CountryTitleEnum.WALLIS_Y_FUTUNA, value: "WF" },
  { label: CountryTitleEnum.SAHARA_OCCIDENTAL, value: "EH" },
  { label: CountryTitleEnum.YEMEN, value: "YE" },
  { label: CountryTitleEnum.ZAMBIA, value: "ZM" },
  { label: CountryTitleEnum.ZIMBABUE, value: "ZW" },
  { label: CountryTitleEnum.ISLAS_DE_ALAND, value: "AX" },
];
