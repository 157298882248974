import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export interface CountryToggleProps {
  name: string;
  image: string;
  type: string;
}

interface AtributtesStyles {
  card: {
    width: string;
    height: string;
  };
  image: {
    marginTop: string;
    width: string;
    height: string;
  };
  text: {
    marginTop: string;
    fontSize: string;
  };
}

export const PaymentMethodToogle = (props: CountryToggleProps) => {
  const verifyStyles = () => {
    let valuesStyle: AtributtesStyles = {
      card: {
        height: "30px",
        width: "45px",
      },
      image: {
        marginTop: "0px",
        height: "25px",
        width: "40px",
      },
      text: {
        marginTop: props.name === "Efectivo" ? "5px" : "",
        fontSize: "11px",
      },
    };
    return valuesStyle;
  };

  const useStyles = makeStyles({
    countryCardClass: {
      height: verifyStyles().card.height,
      width: verifyStyles().card.width,
      textTransform: "none",
      background: "#FFFFFF",
      border: "1px solid #e6ecf2",
      marginTop: "5px",
      marginLeft: "5px",
      borderRadius: "4px",
      boxSizing: "border-box",
    },
    imageClass: {
      display: "block",
      marginTop: verifyStyles().image.marginTop,
      margin: "auto",
      height: verifyStyles().image.height,
      width: verifyStyles().image.width,
    },
    countryText: {
      textAlign: "center",
      fontSize: verifyStyles().text.fontSize,
      color: "#293036",
      fontWeight: 500,
      lineHeight: "140%",
      marginTop: verifyStyles().text.marginTop,
    },
  });
  const classes = useStyles();

  return (
    <Grid container className={classes.countryCardClass}>
      <Grid item xs={12}>
        <img
          src={props.image}
          alt={props.name}
          className={classes.imageClass}
        />
      </Grid>
    </Grid>
  );
};
