import { makeStyles } from "@material-ui/core/styles";

export const accordionPopUpStyle = makeStyles({
  filterTitles: {
    color: "#293036",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: "20px",
  },
  nonSpace: {
    margin: "0px",
    padding: "0px",
  },
});
