import React, { useState } from "react";
import { Box, Chip, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useRuleDetailStyles as classesSx } from "./RuleDetail.styles";
import { Category, RuleDetailProps } from "./RuleDetail.interfaces";
import { BusinessRuleNames } from "../../shared/enums/BusinessRulesNames";
import { Rule } from "../../../types/get_business_rules_response";
import {
  BusinessConfiguration,
  ConditionData,
} from "../../../types/business_configuration";
import _, { defaultTo, isEmpty } from "lodash";
import {
  RuleConditions,
  RuleConditionsValues,
  RuleConditionsValuesByCardType,
  RuleDetailLabels,
} from "./RuleDetail.data";
import { RuleEnum } from "../../shared/enums/RuleEnum";

const ruleDetailStyles = makeStyles({
  chip: {
    filter: "grayscale(100%)",
    backgroundColor: "transparent !important",
  },
});

const getCategoryConfiguration = (
  field: string,
  configuration: BusinessConfiguration
): ConditionData | undefined => {
  return configuration.businessConditions.find(
    (category) => category.field == field
  );
};

export const getCategories = (
  rule: Rule,
  configuration: BusinessConfiguration
): Category[] => {
  const categories: Category[] = [];
  Object.keys(rule).forEach((key) => {
    const config: ConditionData | undefined = getCategoryConfiguration(
      key,
      configuration
    );
    if (!_.isEmpty(rule[key]) && !!config) {
      const [conditional, values] = (rule[key] as string).split("|");
      categories.push({
        name: config.title,
        values,
        conditional,
      });
    }
  });
  return categories;
};

export const getCategoryPlural = (category: string): string => {
  const categoryPlural: Record<string, string> = {
    [BusinessRuleNames.AMOUNT]: "Monto(s)",
    [BusinessRuleNames.BIN]: "Bin(es)",
    [BusinessRuleNames.BRAND]: "Marca(s)",
    [BusinessRuleNames.CARD_TYPE]: "Tipo(s) de tarjeta",
    [BusinessRuleNames.COUNTRY]: "País(es)",
    [BusinessRuleNames.CREDENTIAL]: "Crendencial(es)",
    [BusinessRuleNames.CURRENCY]: "Moneda(s)",
    [BusinessRuleNames.DEFERRED]: "Diferido(s)",
    [BusinessRuleNames.INSTITUTION]: "Instituciones",
    [BusinessRuleNames.IP]: "Ip(s)",
    [BusinessRuleNames.MASKED_CARD]: "Tarjeta(s) enmascarada(s)",
    [BusinessRuleNames.TRANSACTION_TYPE]: "Tipo(s) de transacción",
    [BusinessRuleNames.ACCOUNT_TYPE]: "Tipo(s) de cuenta",
    [BusinessRuleNames.DOCUMENT_TYPE]: "Tipo(s) de documento",
  };
  const plural = categoryPlural[category];

  if (plural) {
    return plural;
  }

  return `${category}(es)`;
};

export const getValueByTag = ({ values, name }: Category): string => {
  return name === BusinessRuleNames.CARD_TYPE
    ? RuleConditionsValuesByCardType[values]
    : defaultTo(RuleConditionsValues[values], values);
};

export const mapFieldToBusinessRuleName = (ruleField: string): string => {
  const fieldToBusinessRuleNameMapper: Record<RuleEnum, BusinessRuleNames> = {
    [RuleEnum.ACCOUNT_TYPE]: BusinessRuleNames.ACCOUNT_TYPE,
    [RuleEnum.AMOUNT]: BusinessRuleNames.AMOUNT,
    [RuleEnum.BANK]: BusinessRuleNames.INSTITUTION,
    [RuleEnum.DOCUMENT_TYPE]: BusinessRuleNames.DOCUMENT_TYPE,
    [RuleEnum.VALIDATE_ENROLL]: BusinessRuleNames.ENROLL,
  };

  return defaultTo(fieldToBusinessRuleNameMapper[ruleField], ruleField);
};

export const RuleDetail: React.FC<RuleDetailProps> = ({
  processorName,
  categories,
}: RuleDetailProps) => {
  const [categoryIndex, setCategoryIndex] = useState<number>(0);
  const styles = ruleDetailStyles();
  const category = categories[categoryIndex];

  return (
    <Grid container sx={classesSx.detailWrapper}>
      <Grid item xs={2} sx={classesSx.firstGrid}>
        <Typography variant={"h6"} color={"text.grey"} paddingBottom={1}>
          {RuleDetailLabels.SendTo}
        </Typography>
        <Typography color={"text.lightGrey"} variant={"overline"}>
          {processorName || ""}
        </Typography>
      </Grid>
      <Grid item xs={4} sx={classesSx.middleGrid}>
        <Typography variant={"h6"} color={"text.grey"} paddingBottom={1}>
          {RuleDetailLabels.Category}
        </Typography>
        {categories.map((condition, index) => (
          <Box key={index} sx={classesSx.chipWrapper}>
            <Chip
              data-test-id={"chip"}
              clickable
              label={condition.name}
              className={`${
                categoryIndex == index ? "" : styles.chip
              } sizeLarge filterChip`}
              onClick={() => setCategoryIndex(index)}
            />
          </Box>
        ))}
      </Grid>
      <Grid item xs={6} sx={classesSx.lastGrid}>
        {categories.length > 0 && (
          <Grid container sx={classesSx.categoryDetail} spacing={1}>
            <Grid item xs={12}>
              <Typography color="text.grey" variant="h6">
                {RuleDetailLabels.Conditional}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="text.dark" variant="body1">
                {RuleConditions[category.conditional] || category.conditional}
              </Typography>
            </Grid>
            {!isEmpty(category.values) && (
              <Grid item xs={12}>
                <Typography color="text.grey" variant="h6">
                  {getCategoryPlural(category.name)}:
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography color="text.dark" variant="body1">
                {getValueByTag(category)}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
