import React, { ReactElement } from "react";
import { UserResponse } from "../../../types/user_response";
import {
  IGroupName,
  IUserAttributes,
  IUserList,
} from "../interfaces/IUseUsersContainerState";
import { get } from "lodash";
import { ITableRowProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableInfo/interfaces";
import { ITableCellProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import DropDown from "../../components/DropDown/DropDown";
import TableCellSkeleton from "../../components/Skeletons/TableCellSkeleton/TableCellSkeleton";
import { TableBodyCellEnum } from "../enum/UsersTableEnum";
import UserActions from "../../components/UserActions/UserActions";
import { isBackofficeMasterCustomer } from "./getRolesUtils";
import { LabelStatusEnum, StatusTagColor } from "../enum/StatusUserEnum";

export const getAttributeUser = (
  attributes: IUserAttributes[],
  attributeName: string
): string => {
  const attr: IUserAttributes | undefined = attributes.find(
    (attribute: IUserAttributes) => attribute.Name === attributeName
  );

  return get(attr, "Value", "");
};

const buildTextCell = (value: string): ITableCellProps => ({
  props: {
    cellProps: {
      align: "left",
      spacing: 1,
    },
    line1: value,
    type: "oneLine",
  },
  type: TableBodyCellEnum.TEXT,
});

const buildCellCustom = (
  component: ReactElement,
  skeleton: ReactElement
): ITableCellProps => ({
  props: {
    cellProps: {
      align: "center",
      spacing: 1,
    },
    child: component,
    childSkeleton: skeleton,
  },
  type: TableBodyCellEnum.BASE,
});

const buildTagCell = (activeUser: boolean): ITableCellProps => {
  return {
    props: {
      cellProps: {
        align: "left",
        spacing: 1,
      },
      color: activeUser ? StatusTagColor.SUCCESS : StatusTagColor.ERROR,
      text: activeUser ? LabelStatusEnum.ACTIVE : LabelStatusEnum.INACTIVE,
    },

    type: TableBodyCellEnum.TAG,
  };
};

const buildCells = (row: IUserList): ITableCellProps[] => {
  const cells: ITableCellProps[] = [];

  if (isBackofficeMasterCustomer()) {
    cells.push(buildTextCell(get(row, "publicMerchantId", "")));
    cells.push(buildTextCell(get(row, "merchantName", "")));
  }

  cells.push(buildTextCell(row.name));
  cells.push(buildTextCell(row.userName));
  cells.push(buildTextCell(row.email));
  cells.push(
    buildCellCustom(<DropDown items={row.roles} />, <TableCellSkeleton />)
  );
  cells.push(buildTagCell(row.enabled));
  cells.push(
    buildCellCustom(<UserActions user={row} />, <TableCellSkeleton />)
  );

  return cells;
};

export const parseUserList = (userList: UserResponse[]): IUserList[] => {
  return userList.map((user: UserResponse) => {
    const attributes = get(user, "Attributes", []);

    return {
      email: getAttributeUser(attributes, "email"),
      enabled: get(user, "Enabled", true),
      familyName: getAttributeUser(attributes, "family_name"),
      merchantName: get(user, "BranchName", ""),
      name: getAttributeUser(attributes, "name"),
      publicMerchantId: getAttributeUser(attributes, "preferred_username")!,
      roles: get(user, "Groups", []).map((g: IGroupName) => g.GroupName),
      status: get(user, "UserStatus", ""),
      userName: get(user, "Username", ""),
    };
  });
};

export const buildUsersRow = (data: IUserList[]): ITableRowProps[] =>
  data.map((row: IUserList) => ({
    cells: buildCells(row),
    id: row.userName,
    rowProps: {
      color: "default",
      onClick: () => {},
    },
  }));

export const usersTableRows = (userList: UserResponse[]): ITableRowProps[] => {
  const users: IUserList[] = parseUserList(userList);
  const rows: ITableRowProps[] = buildUsersRow(users);

  return rows;
};

const buildCellsSkeleton = (): ITableCellProps[] => {
  const cells: ITableCellProps[] = [];

  for (let i = 0; i <= 6; i++) {
    cells.push(buildCellCustom(<TableCellSkeleton />, <TableCellSkeleton />));
  }

  return cells;
};

export const buildUsersRowSkeleton = (): ITableRowProps => ({
  cells: buildCellsSkeleton(),
  id: "skeleton-row",
  rowProps: {
    color: "default",
    onClick: () => {},
  },
});
