import React from "react";
import { Box, Skeleton } from "@mui/material";
import { modelStartChargebackSkeletonStyles as styles } from "./ModalStartChargebackSkeleton.styles";

export const ModelStartChargebackSkeleton: React.FC = () => {
  const numberRows = 3;

  return (
    <Box data-testid="modal-start-chargeback-skeleton">
      {[...Array(numberRows)].map((item) => (
        <Box key={`Key-${item}`}>
          <Box sx={styles.boxSubHeader}>
            <Box sx={styles.checkBoxTitle}>
              <Skeleton variant="rectangular" sx={styles.skeleton} />
            </Box>
            <Box sx={styles.text}>
              <Skeleton variant="rectangular" sx={styles.skeleton} />
            </Box>
          </Box>
          <Box sx={styles.boxSubHeader}>
            <Box sx={styles.checkBoxEmail}>
              <Skeleton variant="rectangular" sx={styles.skeleton} />
            </Box>
            <Box sx={styles.text}>
              <Skeleton variant="rectangular" sx={styles.skeleton} />
            </Box>
          </Box>
          <Skeleton variant="rectangular" sx={styles.skeletonBar} />
        </Box>
      ))}
    </Box>
  );
};
