import React, { FC, PropsWithChildren } from "react";
import { TableHead, TableRow } from "@mui/material";
import { ITableTitleInterfaces } from "./TableTitle.interfaces";
import { TABLE_HEAD_DISABLE_SX, TableTitleStyles } from "./TableTitle.style";

const TableTitle: FC<ITableTitleInterfaces> = ({
  isDisable,
  children,
}: PropsWithChildren<ITableTitleInterfaces>) => {
  return (
    <TableHead sx={TABLE_HEAD_DISABLE_SX[`${!!isDisable}`]}>
      <TableRow sx={TableTitleStyles.row}>{children}</TableRow>
    </TableHead>
  );
};

export { TableTitle };
