import React, { FC } from "react";
import {
  createStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Section1Icon from "../../../assets/image/create-merchant-section1.svg";
import Section1PendingIcon from "../../../assets/image/create-merchant-section1-pending.svg";
import Section2Icon from "../../../assets/image/create-merchant-section2.svg";
import Section2PendingIcon from "../../../assets/image/create-merchant-section2-pending.svg";
import Section3Icon from "../../../assets/image/create-merchant-section3.svg";
import Section4Icon from "../../../assets/image/create-merchant-section4.svg";
import Section4PendingIcon from "../../../assets/image/create-merchant-section4-pending.svg";
import Section5Icon from "../../../assets/image/create-merchant-section5.svg";
import SectionComplete from "../../../assets/image/create-merchant-section-complete.svg";
import { StatusStepsState } from "../../../store/reducer";
import { StepData } from "../../../shared/infrastructure/constants/CreateMerchantConstants";
import { makeStyles } from "@material-ui/core/styles";
import { StatusSteps } from "../../../../types/remote/semaphore_data";

export const SectionNumberIcon: FC<{
  source: string;
  alt: string;
}> = ({ source, alt }) => (
  <img
    src={source}
    width="48px"
    height="48px"
    alt={alt}
    style={{ marginRight: 24 }}
  />
);

const expandedAndPendingColor = "#1E65AE";
const expandedAndInProcessColor = "#CF711A";
const expandedAndCompleteColor = "#0DC298";
const COLORS_BY_STATUS_MAPPING: { [key in StatusSteps]: object } = {
  ["pending"]: {
    1: {
      icon: Section1Icon.toString(),
      borderLeft: expandedAndPendingColor,
    },
    2: {
      icon: Section2Icon.toString(),
      borderLeft: expandedAndPendingColor,
    },
    3: {
      icon: Section3Icon.toString(),
      borderLeft: expandedAndPendingColor,
    },
    4: {
      icon: Section4Icon.toString(),
      borderLeft: expandedAndPendingColor,
    },
    5: {
      icon: Section5Icon.toString(),
      borderLeft: expandedAndPendingColor,
    },
  },
  ["complete"]: {
    default: {
      icon: SectionComplete.toString(),
      borderLeft: expandedAndCompleteColor,
    },
  },
  ["inProcess"]: {
    1: {
      icon: Section1PendingIcon.toString(),
      borderLeft: expandedAndInProcessColor,
    },
    2: {
      icon: Section2PendingIcon.toString(),
      borderLeft: expandedAndInProcessColor,
    },
    4: {
      icon: Section4PendingIcon.toString(),
      borderLeft: expandedAndInProcessColor,
    },
    default: {
      1: {
        icon: Section1Icon.toString(),
        borderLeft: expandedAndPendingColor,
      },
      3: {
        icon: Section3Icon.toString(),
        borderLeft: expandedAndPendingColor,
      },
      5: {
        icon: Section5Icon.toString(),
        borderLeft: expandedAndPendingColor,
      },
    },
  },
  ["omitted"]: {
    3: {
      icon: SectionComplete.toString(),
      borderLeft: expandedAndCompleteColor,
    },
    6: {
      borderLeft: expandedAndPendingColor,
    },
    7: {
      borderLeft: expandedAndPendingColor,
    },
    default: null,
  },
  ["blocked"]: {
    2: {
      icon: Section2Icon.toString(),
      borderLeft: expandedAndPendingColor,
    },
    3: {
      icon: Section3Icon.toString(),
      borderLeft: expandedAndPendingColor,
    },
  },
};

const buildIconComponent = (
  src: string,
  sectionNumber: number,
  status: string
) => (
  <SectionNumberIcon
    source={src}
    alt={`icon-section-${sectionNumber}-${status}`}
  />
);

type ColorResource = "icon" | "borderLeft";

const buildResourceByStatus = (
  status: StatusSteps,
  sectionNumber: number,
  type: ColorResource
) => {
  const colorValue = findColorByStatus(status, sectionNumber, type);

  if (!colorValue) return null;

  if (type === "icon")
    return buildIconComponent(colorValue, sectionNumber, status);
  else if (type === "borderLeft") return colorValue;

  return null;
};

const findColorByStatus = (
  status: StatusSteps,
  sectionNumber: number,
  type: ColorResource
): string | null => {
  const mapping = COLORS_BY_STATUS_MAPPING[status];
  if (!mapping) {
    return null;
  }

  const containsMapping = (obj: any) => typeof obj === "object" && type in obj;
  const lookForDefault = () => {
    const defaultFirstTry = mapping["default"];
    if (!defaultFirstTry) return null;

    if (containsMapping(defaultFirstTry)) return defaultFirstTry[type];

    const defaultSecondTry = defaultFirstTry[sectionNumber];
    if (!defaultSecondTry) return null;

    if (containsMapping(defaultSecondTry)) return defaultSecondTry[type];

    return null;
  };

  const src = mapping[sectionNumber];
  if (!src) return lookForDefault();

  if (containsMapping(src)) return src[type];

  return lookForDefault();
};

const useStyles = makeStyles((theme) =>
  createStyles({
    stepsExpansionPanel: ({
      stepStatus,
      sectionNumber,
    }: MerchantStepSectionProps) => {
      const leftBorderColor = buildResourceByStatus(
        stepStatus.status,
        sectionNumber,
        "borderLeft"
      );

      return {
        "& .MuiExpansionPanel-root": {
          border: "1px solid rgba(0, 0, 0, .06)",
          boxShadow: "none",
          "&:not(:last-child)": {
            borderBottom: 0,
          },
          "&:before": {
            display: "none",
          },
          "&.Mui-expanded": {
            margin: "auto",
          },
          "&.Mui-disabled": {
            backgroundColor: "#fff",
          },
        },
        "& .MuiExpansionPanelSummary-root": {
          height: 86,
          borderBottom: "1px solid rgba(0, 0, 0, .125)",
          marginBottom: "-1px",
          "&.Mui-expanded": {
            borderLeft: `2px solid ${leftBorderColor}`,
            backgroundColor: "#F7FAFC",
          },
        },
        "& .MuiExpansionPanelSummary-content": {
          "&.Mui-expanded": {
            margin: "12px 0",
          },
          paddingLeft: "32 px",
        },
        "& .MuiExpansionPanelDetails-root": {
          padding: theme.spacing(2),
        },
        "& .MuiExpansionPanelSummary-expandIcon": {
          margin: "auto",
        },
        "& .PanelSummaryContainer": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        },
      };
    },
  })
);

export type MerchantStepSectionProps = {
  stepStatus: StatusStepsState;
  sectionData: StepData;
  expanded: boolean;
  handleChange: (event: React.ChangeEvent<object>, isExpanded: boolean) => void;
  sectionNumber: number;
  disabled: boolean;
};

export const MerchantStepSection: FC<MerchantStepSectionProps> = (props) => {
  const {
    expanded,
    handleChange,
    sectionData,
    children,
    sectionNumber,
    stepStatus,
    disabled,
  } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.stepsExpansionPanel}>
      <ExpansionPanel
        square
        expanded={expanded}
        onChange={handleChange}
        disabled={disabled}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${stepStatus.name.toLowerCase()}-content}`}
          id={`${stepStatus.name.toLowerCase()}-header`}
        >
          <div className="PanelSummaryContainer">
            {buildResourceByStatus(stepStatus.status, sectionNumber, "icon")}
            <Typography variant="h4" color="primary" data-testid="label">
              {sectionData.label}
            </Typography>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};
