import ApprovedTransactionIcon from "../../assets/icons/transactionsStates/ApprovedTransactionIcon";
import { ObservationItemStatus } from "../../store/interfaces/TransactionsState.interfaces";

export const transactionIconMap = {
  [ObservationItemStatus.CONCILIATED]: ApprovedTransactionIcon,
  [ObservationItemStatus.REVIEW_KUSHKI]: ApprovedTransactionIcon,
  [ObservationItemStatus.REVIEW_PROCESSOR]: ApprovedTransactionIcon,
  [ObservationItemStatus.PENDING_REFUND]: ApprovedTransactionIcon,
  [ObservationItemStatus.OMITTED]: ApprovedTransactionIcon,
};
