import React from "react";
import {
  Button,
  createStyles,
  Grid,
  IconButton,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { StatusSteps } from "../../../../types/remote/semaphore_data";
import {
  CARD_BRANDS,
  navigateWithMerchantInfoStorage,
  StepsEnum,
} from "../../../shared/infrastructure/constants/CreateMerchantConstants";
import { useDispatch, useSelector } from "react-redux";
import { IAppState, StatusStepsState } from "../../../store/reducer";
import { Dispatch } from "redux";
import Step from "@material-ui/core/Step";
import EditIcon from "../../../assets/image/edit.svg";
import { get, join } from "lodash";
import { PaymentMethodToogle } from "../PaymentMethod/PaymentMethodToogle";
import { activateServices } from "../../../store/actionCreators";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(2, 0),
      margin: theme.spacing(1, 1),
    },
    omitButton: {
      color: "rgba(109, 119, 129, 1)",
    },
    copyIcon: {
      marginLeft: "4px",
      cursor: "pointer",
    },
    stepIcon: {
      cursor: "pointer",
      backgroundColor: "#F0F4F9",
      borderRadius: "4px",
    },
    fixGrid: {
      flexBasis: "inherit",
    },
  })
);

export type IServicesPendingSection = {
  publicMerchantId: string;
  merchantServices?: object;
  status: StatusSteps;
  changeStepStatus: (step: string, status: StatusSteps) => void;
};

export const ServicesPendingSection: React.FC<
  Partial<IServicesPendingSection>
> = (_: Partial<IServicesPendingSection>) => {
  const stateProps: Partial<IServicesPendingSection> = useSelector(
    (state: IAppState) => ({
      publicMerchantId: state.merchantAffiliation?.publicMerchantId,
      status: state.steps?.find(
        (step: StatusStepsState) => step.name === StepsEnum.stepServices
      )?.status,
      merchantServices: state.merchantServices,
    })
  );
  const dispatch: Dispatch<any> = useDispatch();
  const merchantServicesURL: string = "/merchant-properties?hideSideBar=true";
  const classes = useStyles();

  const omitServicesStep = () => {
    dispatch(
      activateServices(
        StepsEnum.stepServices,
        "omitted",
        stateProps.publicMerchantId!
      )
    );
  };

  const redirectServicesPage = () => {
    if (!stateProps.publicMerchantId) return;
    navigateWithMerchantInfoStorage(merchantServicesURL);
  };
  let servicesActive = [];
  let cardEnable = CARD_BRANDS;
  let condition: string = "";

  if (stateProps.status !== "pending") {
    if (get(stateProps.merchantServices, "webcheckoutResponse.status"))
      servicesActive.push("Smartlinks");
    if (get(stateProps.merchantServices, "vposResponse.active"))
      servicesActive.push("Virtual POS");
    if (get(stateProps.merchantServices, "cashResponse.preAuthUrl"))
      servicesActive.push("Efectivo (URL de Pre autorización)");
    cardEnable = CARD_BRANDS.filter((card) =>
      get(
        stateProps.merchantServices,
        "cardResponse.acceptCreditCards",
        []
      ).includes(card.value)
    );

    condition = get(stateProps.merchantServices, "cardResponse.sandboxEnable")
      ? "Sandbox"
      : "";
  }

  return stateProps.status === "pending" ? (
    <Grid
      container
      direction={"column"}
      component={Paper}
      elevation={0}
      className={classes.paper}
      justify={"center"}
      alignItems={"center"}
      spacing={1}
    >
      <Grid item xs={12} className={classes.fixGrid}>
        <Typography variant={"h5"} align={"center"} color={"textPrimary"}>
          Aún no has ingresado información sobre servicios
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.fixGrid}>
        <Button
          id={"btn-redirect"}
          variant={"contained"}
          color={"primary"}
          onClick={redirectServicesPage}
        >
          Agregar información
        </Button>
      </Grid>
      <Grid item xs={12} className={classes.fixGrid}>
        <Button
          id={"btn-omit"}
          variant={"text"}
          color={"default"}
          className={classes.omitButton}
          onClick={omitServicesStep}
        >
          Omitir este paso
        </Button>
      </Grid>
      <Grid item xs={12} className={classes.fixGrid}>
        <Typography
          variant={"subtitle2"}
          align={"center"}
          color={"textPrimary"}
        >
          Al omitir este paso, se activarán por defecto los servicios de
          Smartlinks y las tarjetas Visa y Mastercard (aplica si se agregó un
          procesador de tarjeta).
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <Grid
      container
      direction="column"
      component={Paper}
      elevation={0}
      className={classes.paper}
      justify="center"
      alignItems="center"
      spacing={1}
    >
      <Grid item container xs={12} spacing={1} direction={"column"}>
        <Grid container item spacing={6}>
          <Grid item xs={2}>
            <Typography variant="subtitle1" color="initial">
              Servicios activos
            </Typography>
          </Grid>
          <Grid item xs={"auto"} style={{ paddingLeft: "14px" }}>
            <Step className={classes.stepIcon}>
              <IconButton
                onClick={redirectServicesPage}
                id="copy_btn"
                size="small"
                aria-label="copy"
              >
                <img width={26} src={EditIcon} alt={EditIcon.toString()} />
              </IconButton>
            </Step>
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={2}>
            <Typography variant={"subtitle2"} color={"textSecondary"}>
              Servicios activos:
            </Typography>
          </Grid>
          <Grid item xs={"auto"}>
            <Typography variant={"body2"} color={"textPrimary"}>
              {join(servicesActive, ", ")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={2}>
            <Typography variant={"subtitle2"} color={"textSecondary"}>
              Condiciones:
            </Typography>
          </Grid>
          <Grid item xs={"auto"}>
            <Typography variant={"body2"} color={"textPrimary"}>
              {condition}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item spacing={6}>
          <Grid item xs={2}>
            <Typography variant={"subtitle2"} color={"textSecondary"}>
              Tarjetas para cajita:
            </Typography>
          </Grid>
          <Grid item container xs={8} style={{ paddingLeft: "12px" }}>
            {cardEnable.map((brand) => (
              <Grid key={brand.value} item>
                <div id={brand.value}>
                  <PaymentMethodToogle
                    name={""}
                    image={brand.img}
                    type={"cardBrand"}
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={2}>
            <Typography variant={"subtitle2"} color={"textSecondary"}>
              Cobros de validación:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant={"body2"} color={"textPrimary"}>
              {get(
                stateProps.merchantServices,
                "subscriptionsResponse.subscriptionValidation"
              )
                ? "Si"
                : "No"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
