import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { Calendar } from "react-feather";
import PageLogo from "../../../assets/images/icons/pageLogo.svg";
import { defaultTo, get, isEmpty, isEqual } from "lodash";

import UnfoldMoreOutlinedIcon from "@material-ui/icons/UnfoldMoreOutlined";
import KeyboardArrowUpOutlinedIcon from "@material-ui/icons/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";
import { format } from "date-fns";
import {
  ProcessorsEnum,
  translateProcessor,
} from "../../../shared/infrastructure/constants/ProcessorsEnum";
import { BrandCell } from "../../BrandCell/BrandCell";
import { CountriesEnum } from "../../../shared/infrastructure/constants/CountriesEnum";
import TableContainer from "@material-ui/core/TableContainer";
import {
  ListDialogType,
  ListTitleDialogType,
  SortDirectionEnum,
  TableEnum,
} from "../../../shared/infrastructure/constants/DeferredConstants";
import {
  DeferredStatusEnum,
  StatusEnum,
} from "../../../shared/infrastructure/constants/DeferredStatusEnum";
import { HeaderEnum } from "../../../shared/infrastructure/constants/HeaderTableEnum";
import { deferredTableStyle } from "./DeferredTable.style";
import { SkeletonTable } from "../SkeletonTable/SkeletonTable";
import TablePagination from "../TablePagination/TablePagination";
import { DeferredOption } from "../../../../types/deferred_options";
import { useDeferredTableBrazil } from "./state/useDeferredTableBrazil";
import { ChipMonths } from "./ChipMonths";
import { RowMenuTable } from "../../RowMenuTable/RowMenuTable";
import { calculateDateUtc } from "../../../shared/utils/utils";
import { DeferredDialog } from "../../Dialog/DeferredDialog";

export interface DeferredTableProps {
  merchantDeferred: DeferredOption[] | undefined;
  isDeferredLoaded: boolean;
}
export const DeferredTableBrazil: React.FC<DeferredTableProps> = (
  props: DeferredTableProps
) => {
  const classes = deferredTableStyle(props);
  const deferredOptions = defaultTo(props.merchantDeferred, []);
  const formatDeferredDate = (dateTimestamp: number, isStart: boolean) => {
    return format(
      new Date(calculateDateUtc(dateTimestamp * 1000, isStart)),
      "dd/MM/yyyy"
    );
  };

  const {
    handleChangeSortDirection,
    handleEvents,
    handlePaginationData,
    openMonthsModal,
    paginationData,
    sortDirection,
    monthsList,
    closeMonthsModal,
  } = useDeferredTableBrazil();

  return (
    <>
      {!props.isDeferredLoaded ? (
        <SkeletonTable />
      ) : !isEmpty(deferredOptions) ? (
        <>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.title}>
                      <TableSortLabel
                        active
                        direction={
                          sortDirection
                            ? SortDirectionEnum.ASC
                            : SortDirectionEnum.DESC
                        }
                        onClick={handleChangeSortDirection}
                        IconComponent={
                          isEmpty(sortDirection)
                            ? UnfoldMoreOutlinedIcon
                            : isEqual(sortDirection, SortDirectionEnum.ASC)
                            ? KeyboardArrowDownOutlinedIcon
                            : KeyboardArrowUpOutlinedIcon
                        }
                      >
                        {HeaderEnum.PROCESSOR}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.title}>
                      {HeaderEnum.CARD_BRAND}
                    </TableCell>

                    <TableCell className={classes.title}>
                      {HeaderEnum.MONTHS}
                    </TableCell>

                    <TableCell className={classes.title}>
                      {HeaderEnum.PROGRAMMED_DATE}
                    </TableCell>

                    <TableCell className={classes.title}>
                      {HeaderEnum.STATE}
                    </TableCell>
                    <TableCell className={classes.title} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {defaultTo(paginationData, []).map(
                    (row: DeferredOption, index: number) => (
                      <>
                        <TableRow key={row.id}>
                          <TableCell>
                            <div className={classes.tableStyle}>
                              {
                                translateProcessor[
                                  get(row, "processorName", "").trim()
                                ]
                              }
                              <>
                                {get(row, "startDate", 0) > 0 &&
                                  get(row, "endDate", 0) > 0 && (
                                    <div className={classes.calendarStyle}>
                                      <Tooltip title="Diferido programado">
                                        <Calendar size={16} color="#677784" />
                                      </Tooltip>
                                    </div>
                                  )}
                              </>
                            </div>
                          </TableCell>

                          <TableCell>
                            <BrandCell
                              processorName={ProcessorsEnum.FIS}
                              country={CountriesEnum.BRAZIL}
                            />
                          </TableCell>
                          <TableCell>
                            <ChipMonths
                              months={get(row, "merchantMonths", [""])}
                              listType={ListDialogType.LIST_DEFERRED_MONTHS}
                              rowIndex={index}
                              handleEvents={handleEvents}
                            />
                          </TableCell>
                          {get(row, "startDate", 0) == 0 ? (
                            <TableCell align={"left"}>
                              <> - </>
                            </TableCell>
                          ) : (
                            <TableCell>
                              <>
                                {formatDeferredDate(
                                  get(row, "startDate", 0),
                                  true
                                )}{" "}
                                -{" "}
                                {formatDeferredDate(
                                  get(row, "endDate", 0),
                                  false
                                )}
                              </>
                            </TableCell>
                          )}

                          <TableCell>
                            <Box
                              className={
                                get(row, "merchantStatus", "") ===
                                DeferredStatusEnum.ENABLED
                                  ? classes.greenChip
                                  : classes.redChip
                              }
                            >
                              <Typography
                                className={
                                  get(row, "merchantStatus", "") ===
                                  DeferredStatusEnum.ENABLED
                                    ? classes.greenChipText
                                    : classes.redChipText
                                }
                              >
                                {get(row, "merchantStatus", "") ===
                                DeferredStatusEnum.ENABLED
                                  ? StatusEnum.ENABLED
                                  : StatusEnum.DISABLED}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <RowMenuTable
                              id={row.id}
                              status={get(row, "merchantStatus", "")}
                              row={row}
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    )
                  )}
                </TableBody>
              </Table>
              {props.isDeferredLoaded && !isEmpty(deferredOptions) && (
                <TablePagination
                  {...props}
                  handlePaginationData={handlePaginationData}
                  sortDirection={sortDirection}
                />
              )}
            </TableContainer>
          </Paper>
          <DeferredDialog
            open={openMonthsModal}
            title={ListTitleDialogType.DEFERRED_MONTHS}
            listBanksOrMonths={monthsList}
            buttonText="Aceptar"
            buttonColor="default"
            buttonAction="exit"
            onClose={closeMonthsModal}
            indexVariant={0}
          ></DeferredDialog>
        </>
      ) : (
        <div className={classes.noDeferredStyle}>
          <p>{TableEnum.EMPTY}</p>
          <img className={classes.image} src={PageLogo} />
        </div>
      )}
    </>
  );
};
