import { IStyles } from "../../../../shared/interfaces/Styles.interfaces";

export const listDownloadButtonStyles: IStyles = {
  paper: {
    width: "170px",
  },
  menuItem: {
    color: "#293036",
    fontSize: "14px",
    fontWeight: "22.4px",
    height: "45px",
    width: "170px",
  },
};
