import React, { PropsWithChildren } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  Modal,
  Typography,
} from "@material-ui/core";
import { fileLoadingModalStyles } from "./FileLoadingModal.styles";
import { FileLoadingModalProps } from "../../shared/infrastructure/interfaces/FileLoadingModal.interface";
import { LabelsDispersion } from "../../shared/infrastructure/constants/RequestDispersionConstants";

export const FileLoadingModal = (
  props: PropsWithChildren<FileLoadingModalProps>
) => {
  const classes = fileLoadingModalStyles();

  return (
    <Grid container>
      <Modal
        open={props.openLoadingModal}
        aria-labelledby="modal-loading"
        BackdropProps={{ style: { backgroundColor: "rgba(255,255,255,0.8)" } }}
      >
        <Box className={classes.modalBox}>
          <CircularProgress size={60} thickness={4} />
          <Box className={classes.modalContent}>
            <Typography variant={"h5"} color={"primary"}>
              {LabelsDispersion.LOADING_TITLE}
            </Typography>
            <Typography variant={"h5"} color={"primary"}>
              {LabelsDispersion.LOADING_SUBTITLE}
            </Typography>
            <Typography
              variant={"h6"}
              color={"primary"}
              className={classes.modalText}
            >
              {LabelsDispersion.LOADING_MESSAGE}
            </Typography>
          </Box>
        </Box>
      </Modal>
    </Grid>
  );
};
