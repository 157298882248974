import React from "react";
import { Grid, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  footerTitle: {
    fontWeight: "normal",
    paddingLeft: "20px",
    paddingBottom: "20px",
    textDecoration: "underline",
  },
}));

export interface ICardContainerFooterProps {
  href?: string;
  footerTitle?: string;
}

export const CardContainerFooter: React.FC<ICardContainerFooterProps> = (
  props: React.PropsWithChildren<ICardContainerFooterProps>
) => {
  const style = useStyles();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Link href={props.href} color="inherit">
          <Typography className={style.footerTitle} variant="body1">
            {props.footerTitle}
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
};
