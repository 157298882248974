/* istanbul ignore file */
import { defaultTo, get } from "lodash";
import { UserRolesEnum } from "../infrastructure/UserRolesEnum";

export class auth {
  public static isAdmin(): boolean {
    return get(auth.getPayload(), "[cognito:groups]", []).includes(
      "BackofficeAdmin"
    );
  }
  public static getPayload(): object {
    return JSON.parse(defaultTo(localStorage.getItem("payload"), "{}"));
  }
  public static isBackOfficeRefundApprover(): boolean {
    return get(auth.getPayload(), "[cognito:groups]", []).includes(
      "BackOfficeRefundApprover"
    );
  }
  public static getJwtAuth(): string {
    return localStorage.getItem("jwt") || "";
  }
  public static isBackOfficeRefundExecutor(): boolean {
    return get(auth.getPayload(), "[cognito:groups]", []).includes(
      UserRolesEnum.BackOfficeRefundExecutor
    );
  }
}
