import React from "react";
import { Chip, ChipProps, Tooltip } from "@material-ui/core";
import { chipTransactionStatusStyle } from "./ChipTransactionStatus.style";

export interface ChipTransactionsStatusProps extends ChipProps {
  status: string | undefined;
  message: string;
  disableHover: boolean;
  text: string;
}

export const ChipTransactionsStatus: React.FC<ChipTransactionsStatusProps> = (
  props: ChipTransactionsStatusProps
) => {
  const classes = chipTransactionStatusStyle(props);

  return (
    <React.Fragment>
      <Tooltip disableHoverListener={props.disableHover} title={props.message}>
        <Chip label={props.text} className={classes.chip} {...props} />
      </Tooltip>
    </React.Fragment>
  );
};
