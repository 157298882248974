import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const searchTextStyles = createNamedStyles({
  searchText: {
    m: 1,
    marginRight: "1rem",
    width: "90%",
    "& fieldset": {
      borderRadius: "1rem !important",
      transition: "all 300ms",
    },
  },
});
