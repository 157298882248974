import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RouteEnum } from "./shared/infrastructure/routes";
import { App } from "./containers/App";
import { TicketOrder } from "./containers/TicketOrder/TicketOrder";
import { RechargeWallet } from "./containers/RechargeWallet/RechargeWallet";

const Root = () => {
  return (
    <React.StrictMode>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500&display=swap');"
        }
      </style>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@100;200;300;400;500&display=swap');"
        }
      </style>
      <BrowserRouter>
        <Routes>
          <Route path={RouteEnum.CHECKOUT} element={<App />}>
            <Route index element={<RechargeWallet />} />
          </Route>
          <Route path={RouteEnum.SUMMARY_CHECKOUT} element={<App />}>
            <Route index element={<TicketOrder />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  );
};

export default Root;
