import { FC } from "react";
import { CellCheckItem } from "../../TableCell/CellCheck/CellCheck";
import { CellInputItem } from "../../TableCell/CellInput/CellInput";
import {
  CellContentItem,
  CellOptionsItem,
  CellSwitchItem,
  CellTextActionItem,
  CellTextItem,
  CellTextTooltipItem,
} from "@kushki/connect-ui";
import { CellSelectItem } from "../../TableCell/CellSelectItem/CellSelectItem";
import { CellDeleteItem } from "../../TableCell/CellDelete/CellDelete";
import { CellTitleItem } from "../../TableCell/CellTitle/CellTitle";
import { CellTagItem } from "../../TableCell/CellTag/CellTag";
import { CellFlagItem } from "../../TableCell/CellFlag/CellFlag";

enum TableBodyCellEnum {
  TITLE = "TITLE",
  TEXT = "TEXT",
  TAG = "TAG",
  OPTIONS = "OPTIONS",
  SWITCH = "SWITCH",
  DELETE = "DELETE",
  INPUT_TEXT = "INPUT_TEXT",
  SELECT_ITEM = "SELECT_ITEM",
  CHECK = "CHECK",
  FLAG = "FLAG",
  TEXT_TOOLTIP = "TEXT_TOOLTIP",
  TEXT_ACTION = "TEXT_ACTION",
  CONTENT = "CONTENT",
}

const CELL_COMPONENTS_BY_TABLE_ENUM: Record<TableBodyCellEnum, FC<any>> = {
  [TableBodyCellEnum.TITLE]: CellTitleItem,
  [TableBodyCellEnum.TEXT]: CellTextItem,
  [TableBodyCellEnum.TAG]: CellTagItem,
  [TableBodyCellEnum.OPTIONS]: CellOptionsItem,
  [TableBodyCellEnum.SWITCH]: CellSwitchItem,
  [TableBodyCellEnum.INPUT_TEXT]: CellInputItem,
  [TableBodyCellEnum.SELECT_ITEM]: CellSelectItem,
  [TableBodyCellEnum.CHECK]: CellCheckItem,
  [TableBodyCellEnum.DELETE]: CellDeleteItem,
  [TableBodyCellEnum.FLAG]: CellFlagItem,
  [TableBodyCellEnum.TEXT_TOOLTIP]: CellTextTooltipItem,
  [TableBodyCellEnum.TEXT_ACTION]: CellTextActionItem,
  [TableBodyCellEnum.CONTENT]: CellContentItem,
};

export { TableBodyCellEnum, CELL_COMPONENTS_BY_TABLE_ENUM };
