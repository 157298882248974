export const companyTypeGUA: Array<string> = [
  "Sociedad Anonima (S.A.)",
  "Sociedad Comercial de Responsabilidad Limitada (S.R.L.)",
  "Sociedad colectiva",
  "Sociedad en comandita simple",
  "Sociedad en comandita por acciones",
];

export const docTypeGUA = [
  { id: "1", label: "NIT" },
  { id: "0", label: "DPI", selected: true },
];
