import { createNamedStyles } from "../../../../shared/interfaces/create_named_styles";

export const shareholderFormStyle = createNamedStyles({
  closeButton: { paddingTop: "16px", paddingRight: "10px" },
  container: {
    backgroundColor: "background.default",
  },
  divider: {
    border: "1px solid",
    color: "neutral.grey4",
  },
  dividerContainer: {
    margin: "auto",
    maxWidth: "90%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "12px",
    paddingRight: "12px",
  },
  footer: {
    backgroundColor: "background.paper",
    width: "100%",
    paddingTop: "25px",
    paddingBottom: "20px",
    paddingRight: "6%",
  },
  infoTitle: { padding: "15px 0px 15px 0px" },
  sourceName: {
    mt: 1,
  },
});
