export enum ROUTES {
  INDEX = "/",
  BATCH = "/batch",
}

export const SPA_BASENAME: string = "service-configuration";

export const CONFIGURE_REDIRECT: string =
  "/merchant-properties?hideSideBar=true&hideTopBar=true";

export const CONFIGURE_REDIRECT_BATCH: string = "/merchant-properties/batch";

export const VIEW_BRANCHES_REDIRECT: string = "/create-node/branch/edit";

export const CONTINUE_REDIRECT_RATES = (merchantId: string) =>
  `/console-rates?publicMerchantId=${merchantId}&menuItem=true`;
