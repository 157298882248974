import { IStyles } from "../../../shared/interfaces/Styles.interfaces";

export const tableBodySimpleStyles: IStyles = {
  tableRow: {
    background: "rgba(224, 255, 248, 0.5)",
    "&.MuiTableRow-root td": {
      borderTop: "1px solid rgba(0, 252, 193, 0.5)",
      borderBottom: "1px solid rgba(0, 252, 193, 0.5)",
      "&:first-child": {
        borderTopLeftRadius: "8px",
        borderBottomLeftRadius: "8px",
        borderLeft: "1px solid rgba(0, 252, 193, 0.5)",
      },
      "&:last-child": {
        borderTopRightRadius: "8px",
        borderBottomRightRadius: "8px",
        borderRight: "1px solid rgba(0, 252, 193, 0.5)",
      },
    },
  },
  tableRowBorderRadius: {
    "&.MuiTableRow-root td": {
      "&:first-child": {
        borderTopLeftRadius: "8px",
        borderBottomLeftRadius: "8px",
      },
      "&:last-child": {
        borderTopRightRadius: "8px",
        borderBottomRightRadius: "8px",
      },
    },
  },
};
