export enum ChargeFields {
  FREQUENCY = "frequency",
  KEEP_FRAUD = "keepFraud",
  FRAUD_PERCENTAGE = "fraudPercentage",
  DAYS_RETENTION = "retentionPeriod",
}
export enum ChargeConfigId {
  CN001 = "cn001",
  CN017 = "cn017",
}
