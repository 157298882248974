import React from "react";
import { Box, Button, Container, Paper } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { footerStyles as styles } from "@components/Footer/Footer.styles";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import {
  IFooterProps,
  IWrapperProps,
} from "@components/Footer/Footer.interfaces";

const Footer: React.FC<IFooterProps> = ({
  handlePrimaryButton,
  handleSecondaryButton,
  componentIdPrimaryButton,
  componentIdSecondaryButton,
  primaryButtonText,
  secondaryButtonText,
  primaryButtonDisabled,
  primaryButtonLoading,
  componentIdAltPrimaryButton,
  isEdit,
}: IFooterProps) => {
  const AltSecurityWrapper = ({ children }: IWrapperProps) => (
    <ComponentSecurityWrapper componentId={componentIdAltPrimaryButton}>
      <Box>{children}</Box>
    </ComponentSecurityWrapper>
  );

  const MainSecurityWrapper = ({ children }: IWrapperProps) => (
    <ComponentSecurityWrapper componentId={componentIdPrimaryButton}>
      <Box>{children}</Box>
    </ComponentSecurityWrapper>
  );

  const PrimaryButton = () => (
    <LoadingButton
      sx={styles.button}
      onClick={handlePrimaryButton}
      loading={primaryButtonLoading}
      loadingPosition="start"
      variant="contained"
      disabled={primaryButtonDisabled}
    >
      <Box component={"span"} sx={styles.textPrimary}>
        {primaryButtonText}
      </Box>
    </LoadingButton>
  );

  return (
    <Paper sx={styles.wrapper}>
      <Container sx={styles.container}>
        <ComponentSecurityWrapper componentId={componentIdSecondaryButton}>
          <Button
            variant={"text"}
            sx={styles.button}
            onClick={handleSecondaryButton}
          >
            {secondaryButtonText}
          </Button>
        </ComponentSecurityWrapper>
        {isEdit ? (
          <AltSecurityWrapper>
            <PrimaryButton />
          </AltSecurityWrapper>
        ) : (
          <MainSecurityWrapper>
            <PrimaryButton />
          </MainSecurityWrapper>
        )}
      </Container>
    </Paper>
  );
};

export default Footer;
