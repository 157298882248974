import { createSlice } from "@reduxjs/toolkit";
import { MainPrefix } from "@shared/constants/thunkPrefixes";
import { IExecutivesState } from "@store/interfaces/ExecutivesState.interfaces";
import {
  downloadExecutiveTemplate,
  getExecutiveTemplate,
  uploadCommercialExecutive,
  validateCommercialExecutives,
} from "@store/thunks/executives/executives.thunk";

export const executiveState: IExecutivesState = {
  base64File: "",
  executiveConfigResponse: {},
  fileValidated: null,
  isLoadingDownloadExecutives: false,
  isLoadingUploadCommercialExecutives: false,
  isOpenUploadCommercialExecutivesModal: false,
  isUploadedSuccessfully: false,
};

export const executiveSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(downloadExecutiveTemplate.fulfilled, (state) => {
        state.isLoadingDownloadExecutives = false;
      })
      .addCase(downloadExecutiveTemplate.pending, (state) => {
        state.isLoadingDownloadExecutives = true;
      })
      .addCase(downloadExecutiveTemplate.rejected, (state) => {
        state.isLoadingDownloadExecutives = false;
      })
      .addCase(getExecutiveTemplate.fulfilled, (state, action) => {
        state.isLoadingDownloadExecutives = false;
        state.executiveConfigResponse = action.payload;
      })
      .addCase(getExecutiveTemplate.pending, (state) => {
        state.isLoadingDownloadExecutives = true;
      })
      .addCase(getExecutiveTemplate.rejected, (state) => {
        state.isLoadingDownloadExecutives = false;
        state.executiveConfigResponse = {};
      })
      .addCase(validateCommercialExecutives.fulfilled, (state) => {
        state.fileValidated = true;
        state.isOpenUploadCommercialExecutivesModal = true;
      })
      .addCase(validateCommercialExecutives.pending, (state) => {
        state.fileValidated = null;
      })
      .addCase(validateCommercialExecutives.rejected, (state) => {
        state.fileValidated = false;
      })
      .addCase(uploadCommercialExecutive.fulfilled, (state) => {
        state.isLoadingUploadCommercialExecutives = false;
        state.isUploadedSuccessfully = true;
        state.isOpenUploadCommercialExecutivesModal = false;
        state.base64File = "";
        state.fileValidated = null;
      })
      .addCase(uploadCommercialExecutive.pending, (state) => {
        state.isLoadingUploadCommercialExecutives = true;
        state.isUploadedSuccessfully = false;
      })
      .addCase(uploadCommercialExecutive.rejected, (state) => {
        state.isLoadingUploadCommercialExecutives = false;
        state.isOpenUploadCommercialExecutivesModal = false;
      });
  },
  initialState: executiveState,
  name: MainPrefix.EXECUTIVES,
  reducers: {
    setBase64File: (state, { payload }) => {
      state.base64File = payload;
    },
    setFileValidated: (state, { payload }) => {
      state.fileValidated = payload;
    },
    setIsOpenUploadCommercialExecutivesModal: (state, action) => {
      state.isOpenUploadCommercialExecutivesModal = action.payload;
    },
  },
});

export const { setFileValidated, setBase64File } = executiveSlice.actions;

export default executiveSlice.reducer;
