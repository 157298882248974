import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getHierarchyNodeInfo,
  getNodeInfo,
  searchMerchantNodeInfo,
} from "../../thunks/general/general.thunk";
import {
  GeneralData,
  IRedirect,
} from "../../interfaces/generalData.interfaces";
import { IPaginationData } from "../../../components/Table/TableNodes/TableNodes.interfaces";
import { MerchantNodeData } from "../../../../types/search_merchant_response";
import { isEmpty } from "lodash";

export const initialState: GeneralData = {
  hierarchyNodeInfo: {},
  isLoadingMerchants: false,
  merchantInfo: {},
  needRedirect: {
    path: "",
    type: "inside",
    withQuery: false,
  },
  nodeInfo: {
    configCoreId: "",
  },
  notification: null,
  paginationData: {
    page: 0,
    rowsPerPage: 0,
    totalData: 0,
  },
  pathCustomer: "",
  selectedData: [],
};

export const generalDataSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getNodeInfo.fulfilled, (state, { payload }) => {
        state.nodeInfo = payload;
      })
      .addCase(getHierarchyNodeInfo.fulfilled, (state, { payload }) => {
        state.hierarchyNodeInfo = payload;
      })
      .addCase(searchMerchantNodeInfo.pending, (state) => {
        state.isLoadingMerchants = true;
      })
      .addCase(searchMerchantNodeInfo.rejected, (state) => {
        state.isLoadingMerchants = true;
      })
      .addCase(searchMerchantNodeInfo.fulfilled, (state, { payload }) => {
        state.merchantInfo = payload;
        state.isLoadingMerchants = isEmpty(state.pathCustomer);
      });
  },
  initialState,
  name: "generalData",
  reducers: {
    setNeedRedirect: (
      state,
      {
        payload: { path, type = "inside", withQuery = false },
      }: PayloadAction<IRedirect>
    ) => {
      state.needRedirect = { path, type, withQuery };
    },
    setNotification: (state, { payload }) => {
      state.notification = payload;
    },
    setPaginationData: (state, { payload }: PayloadAction<IPaginationData>) => {
      state.paginationData = payload;
    },
    setPathCustomer: (state, { payload }: PayloadAction<string>) => {
      state.pathCustomer = payload;
    },
    setSelectedData: (
      state,
      { payload }: PayloadAction<MerchantNodeData[]>
    ) => {
      state.selectedData = payload;
    },
  },
});

export default generalDataSlice.reducer;
