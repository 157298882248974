import React from "react";
import { WebhookEvent } from "../../../types/webhook_response";
import { Box, Chip } from "@material-ui/core";
import {
  OptionsEnum,
  ServiceOptionEnum,
  WebhookServiceEnum,
} from "../../shared/infrastructure/constants/OptionsEnum";
import { useChipLabelState } from "./state/useChipLabelState";

export const WEBHOOK_SERVICE_TEXT: { [K in WebhookServiceEnum]: string } = {
  [ServiceOptionEnum.CARD]: OptionsEnum.CARD,
  [ServiceOptionEnum.CASH]: OptionsEnum.CASH,
  [ServiceOptionEnum.PAYOUTS_CASH]: OptionsEnum.PAYOUTS_CASH,
  [ServiceOptionEnum.TRANSFER_SUBSCRIPTIONS]:
    OptionsEnum.TRANSFER_SUBSCRIPTIONS,
  [ServiceOptionEnum.TRANSFER]: OptionsEnum.TRANSFER,
  [ServiceOptionEnum.PAYOUTS_TRANSFER]: OptionsEnum.PAYOUTS_TRANSFER,
  [ServiceOptionEnum.SUBSCRIPTIONS]: OptionsEnum.SUBSCRIPTIONS,
  [ServiceOptionEnum.WEBCHECKOUT]: OptionsEnum.WEBCHECKOUT,
  [ServiceOptionEnum.VPOS]: OptionsEnum.VPOS,
  [ServiceOptionEnum.CHARGEBACK]: OptionsEnum.CARD_CHARGEBACK_VOID,
  [ServiceOptionEnum.CHARGEBACK_VOID]: OptionsEnum.CARD_CHARGEBACK,
};

export interface ChipLabelProps {
  events: WebhookEvent;
}
export const ChipLabel: React.FC<ChipLabelProps> = (props: ChipLabelProps) => {
  const { eventsTransformed } = useChipLabelState(props);

  return (
    <React.Fragment>
      <Box
        key={"box_chiplabel"}
        display={"flex"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        flexWrap={"wrap"}
      >
        {eventsTransformed.map(
          (label: { service: string; status: string[] }, index: number) => (
            <Chip
              key={index}
              size={"small"}
              label={WEBHOOK_SERVICE_TEXT[label.service]}
              style={{
                margin: "4px",
                backgroundColor: "#F7FAFC",
                borderRadius: "4px",
                textAlign: "center",
              }}
            />
          )
        )}
      </Box>
    </React.Fragment>
  );
};
