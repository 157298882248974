import { createAsyncThunk } from "@reduxjs/toolkit";
import { thunkPrefix } from "@shared/constants/thunkPrefixes";
import axios, { CancelTokenSource } from "axios";
import { API_ROUTES } from "@shared/constants/api_routes";
import { AxiosResponse } from "axios";
import { SearchAlarmByFilterRequest } from "../../../../types/search_alarm_by_filter_request";
import { SearchAlarmByFilterResponse } from "../../../../types/search_alarm_by_filter_response";
import {
  SignedUrls,
  SignedUrlsResponse,
} from "../../../../types/signed_urls_response";
import { getTime } from "date-fns";
import { filter, get, isEmpty, isEqual, uniq } from "lodash";
import { setNotification } from "@store/reducers/general/general.slice";
import { buildSnackbar } from "@shared/utils/snackBarUtils";
import { StatusSnackbarEnum } from "@shared/enum/StatusSnackbarEnum";
import { ActionSignedEnum } from "@shared/constants/AlarmActionConstants";
import { SNACKBAR_MESSAGE } from "@shared/constants/AlarmConstants";
import {
  setDetailCardAlarms,
  setIsLoadingDownload,
} from "@store/reducers/alarms/alarms.slice";
import { SnackBarMessages } from "@shared/enum/snackBarMessages";
import { getJwtAuth } from "@shared/utils/getJwtAuthUtil";
import {
  IAlarmActionThunk,
  IFileRequest,
  IFiles,
  ISaveFileRequestThunk,
} from "@store/interfaces/Alarms.interfaces";
import { WebSocketAction } from "@shared/enum/webSocketActionEnum";
import {
  handleDownloadFileMessageSocket,
  handleFileDownloadErrorSocket,
  handleOpenSocket,
} from "@shared/utils/webSocketUtils";
import { IFileCommentRequest } from "@store/thunks/alarms/alarms.thunk.interfaces";
import { downloadBase64File } from "@shared/utils/fileUtils";

let cancelToken: CancelTokenSource | null = null;

function addHeaders(): object {
  return {
    headers: {
      Authorization: localStorage.getItem("jwt"),
      "Content-type": "application/json",
    },
  };
}

function _buildFileRequest(files: File[]): IFileRequest {
  const files_request: IFiles[] = [];
  const keys: string[] = [];

  Array.from(files).forEach((file: File) => {
    const now: number = getTime(new Date());
    const name: string = `v2/${now}_${file.name}`;

    files_request.push({
      file,
      name,
    });
    keys.push(name);
  });

  return {
    files: files_request,
    keys,
  };
}

function buildCallSaveFileS3(
  signedUrls: SignedUrlsResponse,
  filesRequest: IFileRequest
): Promise<AxiosResponse>[] {
  const request: Promise<AxiosResponse>[] = [];

  for (const fileConfig of filesRequest.files) {
    const signed_urls: SignedUrls = signedUrls.urls.find((item: SignedUrls) =>
      isEqual(fileConfig.name, item.key)
    )!;

    request.push(
      axios.put(signed_urls.url, fileConfig.file, {
        headers: {
          "Content-Type": "",
        },
      })
    );
  }

  return request;
}

function buildCallSaveActionAlarm(
  fileKeys: string[],
  payload: ISaveFileRequestThunk[]
): Promise<AxiosResponse | string>[] {
  const request: Promise<AxiosResponse | string>[] = [];

  for (const saveAction of payload) {
    const data: ISaveFileRequestThunk = { ...saveAction };

    delete data.merchantId;

    const axiosPromise = axios
      .post(
        API_ROUTES.SET_ALARM_ACTION_V2,
        {
          ...data,
          files: fileKeys,
        },
        addHeaders()
      )
      .then((resp) => resp)
      .catch(() => get(saveAction, "merchantId", ""));

    request.push(axiosPromise);
  }

  return request;
}

export const saveActionAlarm = createAsyncThunk<boolean, ISaveFileRequestThunk>(
  thunkPrefix.updateAlarm,
  async (payload: ISaveFileRequestThunk, { rejectWithValue, dispatch }) => {
    const files_body: IFileRequest = _buildFileRequest(
      get(payload, "files", [])
    );

    try {
      if (!isEmpty(files_body.keys)) {
        const response_signed_urls: AxiosResponse<SignedUrlsResponse> =
          await axios.post(
            API_ROUTES.GET_FILES_SIGNED,
            {
              action: ActionSignedEnum.PUT,
              key: files_body.keys,
            },
            addHeaders()
          );

        await Promise.all(
          buildCallSaveFileS3(response_signed_urls.data, files_body)
        );
      }

      await axios.post(
        API_ROUTES.SET_ALARM_ACTION_V2,
        {
          ...payload,
          files: files_body.keys,
        },
        addHeaders()
      );
      dispatch(
        setNotification(
          buildSnackbar(
            StatusSnackbarEnum.SUCCESS,
            SNACKBAR_MESSAGE[payload.action].success
          )
        )
      );

      return true;
    } catch (error) {
      dispatch(
        setNotification(
          buildSnackbar(
            StatusSnackbarEnum.DANGER,
            SNACKBAR_MESSAGE[payload.action].error
          )
        )
      );

      return rejectWithValue(false);
    }
  }
);

export const saveMassiveAlarm = createAsyncThunk<
  boolean,
  ISaveFileRequestThunk[]
>(
  thunkPrefix.actionMassiveAlarm,
  async (payload: ISaveFileRequestThunk[], { rejectWithValue, dispatch }) => {
    const files_body: IFileRequest = _buildFileRequest(
      get(payload, "[0].files", [])
    );

    try {
      if (!isEmpty(files_body.keys)) {
        const response_signed_urls: AxiosResponse<SignedUrlsResponse> =
          await axios.post(
            API_ROUTES.GET_FILES_SIGNED,
            {
              action: ActionSignedEnum.PUT,
              key: files_body.keys,
            },
            addHeaders()
          );

        await Promise.all(
          buildCallSaveFileS3(response_signed_urls.data, files_body)
        );
      }

      const responsesOrMerchantIds: (AxiosResponse | string)[] =
        await Promise.all(buildCallSaveActionAlarm(files_body.keys, payload));

      const failedMerchantIds: string[] = filter(
        responsesOrMerchantIds,
        (merchantId) => typeof merchantId === "string"
      ) as string[];

      if (!isEmpty(failedMerchantIds)) {
        dispatch(
          setNotification(
            buildSnackbar(
              StatusSnackbarEnum.DANGER,
              `${SNACKBAR_MESSAGE[payload[0].action].error} Comercios: ${uniq(
                failedMerchantIds
              ).join(",")}`
            )
          )
        );

        return false;
      }

      dispatch(
        setNotification(
          buildSnackbar(
            StatusSnackbarEnum.SUCCESS,
            SNACKBAR_MESSAGE[payload[0].action].success
          )
        )
      );

      return true;
    } catch (error) {
      dispatch(
        setNotification(
          buildSnackbar(
            StatusSnackbarEnum.DANGER,
            SNACKBAR_MESSAGE[payload[0].action].error
          )
        )
      );

      return rejectWithValue(false);
    }
  }
);

export const searchAlarmByFilters = createAsyncThunk(
  thunkPrefix.searchAlarmByFilters,
  async (payload: SearchAlarmByFilterRequest) => {
    const response: AxiosResponse<SearchAlarmByFilterResponse> =
      await axios.post(
        API_ROUTES.SEARCH_ALARM_BY_FILTER,
        payload,
        addHeaders()
      );

    return response.data;
  }
);

export const setAlarmAction = createAsyncThunk(
  thunkPrefix.setAlarmAction,
  async (payload: IAlarmActionThunk, { dispatch }) => {
    if (cancelToken) {
      cancelToken.cancel("Nueva petición, cancelando la antigua");
    }
    cancelToken = axios.CancelToken.source();
    const response = await axios.post(
      API_ROUTES.SET_ALARM_ACTION_V2,
      payload.alarmsActionRequest,
      {
        cancelToken: get(cancelToken, "token"),
        headers: {
          Authorization: getJwtAuth(),
          "Content-type": "application/json",
        },
      }
    );

    if (response.status === 201) {
      dispatch(searchAlarmByFilters(payload.searchAlarmsRequest));
      dispatch(setDetailCardAlarms({ isOpen: false, selectedAlarm: null }));
      dispatch(
        setNotification(buildSnackbar("success", SnackBarMessages.UPDATE_ALARM))
      );
    } else {
      dispatch(
        setNotification(
          buildSnackbar("danger", SnackBarMessages.ERROR_UPDATE_ALARM)
        )
      );
    }

    return response.data;
  }
);

export const downloadFileAlarms = createAsyncThunk(
  thunkPrefix.downloadFileAlarms,
  async (payload: SearchAlarmByFilterRequest, { dispatch }) => {
    const jwt: string | null = localStorage.getItem("jwt");
    let webSocket: WebSocket | undefined;
    const params: object = {
      action: WebSocketAction.DOWNLOAD_ALARMS,
      data: payload,
    };

    webSocket = new WebSocket(
      `${API_ROUTES.DOWNLOAD_ALARMS}?Authorization=${jwt}`
    );

    dispatch(setIsLoadingDownload(true));

    webSocket.onopen = handleOpenSocket(webSocket, params);

    webSocket.onerror = handleFileDownloadErrorSocket(webSocket, dispatch);

    webSocket.onmessage = handleDownloadFileMessageSocket(webSocket, dispatch);
  }
);

export const getFileComments = createAsyncThunk(
  thunkPrefix.getFileComment,
  async (payload: IFileCommentRequest, { rejectWithValue, dispatch }) => {
    try {
      const response: AxiosResponse<{ buffer: string }> = await axios.get(
        API_ROUTES.GET_FILE_COMMENTS,
        {
          params: {
            filePath: payload.key,
          },
          ...addHeaders(),
        }
      );

      downloadBase64File(get(response.data, "buffer"), payload.fileName);

      return true;
    } catch (error) {
      dispatch(
        setNotification(
          buildSnackbar(
            StatusSnackbarEnum.DANGER,
            SnackBarMessages.ERROR_DOWNLOAD_FILES_COMMENTS
          )
        )
      );

      return rejectWithValue(false);
    }
  }
);
