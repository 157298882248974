import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { IModalDetailBranchHeaderProps } from "../../shared/interfaces/ModalDetailHeaderBranch";
import { LabelEnum } from "../../shared/enums/LabelEnum";

const modalDetailStyles = makeStyles({
  mainBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  rightBox: {
    alignItems: "center",
  },
  tag: {
    height: "21px",
    marginTop: "12px",
  },
  title: {
    color: "#023365",
    fontSize: "26px",
    fontWeight: "bold",
  },
});

export const ModalDetailHeader: React.FC<IModalDetailBranchHeaderProps> = (
  props: IModalDetailBranchHeaderProps
) => {
  const classes = modalDetailStyles();

  return (
    <>
      <Grid className={classes.rightBox}>
        <Typography className={classes.title}>{props.title}</Typography>
      </Grid>
      <Box display="flex" gap={"5px"} mt={2} mb={2}>
        <Typography variant={"overline"} color={"text.grey"}>
          {props.secondaryText
            ? LabelEnum.HEADER_CREATED_PROPERTY.concat(": ")
            : ""}
          <span>{props.secondaryText ? props.secondaryText : ""}</span>
        </Typography>
      </Box>
    </>
  );
};
