import React, { FC } from "react";
import { Box, Grid } from "@mui/material";
import { ResumeBox } from "../ResumeBox/ResumeBox";
import { ResumeBarProps } from "./ResumeBar.interfaces";

const ResumeBar: FC<ResumeBarProps> = ({ items }: ResumeBarProps) => {
  return (
    <Grid item md={12}>
      <Box>
        <Grid container spacing={2}>
          {items.map((data, index) => (
            <ResumeBox
              title={data.title}
              tooltip={data.tooltip}
              key={`BoxSummary-${index.toString()}`}
            >
              {data.children}
            </ResumeBox>
          ))}
        </Grid>
      </Box>
    </Grid>
  );
};

export { ResumeBar };
