import React, { useEffect } from "react";
import { SelectCheck } from "@kushki/connect-ui";
import { IOptionSelection } from "../../../../shared/infrastructure/interfaces/IOptionSelection";
import { ISelectFieldComponentProps } from "../../../../shared/infrastructure/interfaces/ISelectFieldComponentProps";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";
import { isEmpty, isEqual } from "lodash";
import { RuleEnum } from "../../../../shared/infrastructure/enums/RuleEnum";

export const SelectFieldComponent: React.FC<ISelectFieldComponentProps> = (
  props: ISelectFieldComponentProps
) => {
  useEffect(() => {
    const dataToRetrieve: ICategory[] = (
      Array.isArray(props.selected) ? props.selected : []
    )
      .filter((data: string) => !isEmpty(data))
      .map((data) => {
        return {
          label: props.conditionProperty,
          value: data,
          selected: true,
        };
      });

    props.setSelectedItems(dataToRetrieve);
  }, [props.selected]);

  return (
    <SelectCheck
      maxSelectedItems={
        props.maxSelectedItems ? props.maxSelectedItems : undefined
      }
      maxWidthDeskt={120}
      maxWidthMob={70}
      isActiveSelectAll
      items={props.items.map((item: IOptionSelection) => {
        const value: string = isEqual(props.conditionProperty, RuleEnum.BANK)
          ? item.label
          : item.value;
        if (props.selected.includes(value))
          return { label: item.label, value: item.value, selected: true };
        return { label: item.label, value: item.value, selected: false };
      })}
      onItemSelect={props.setSelectedItems}
      placeholder={props.placeHolder}
      type="withoutCategory"
      isLoading={false}
      onChangeTextField={() => {}}
    />
  );
};
