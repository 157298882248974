/**
 * Country Enumeration
 */
export enum CountryEnum {
  ecuador = "Ecuador",
  peru = "Peru",
  colombia = "Colombia",
  chile = "Chile",
  mexico = "Mexico",
}
export const COUNTRIES = [
  CountryEnum.ecuador,
  CountryEnum.peru,
  CountryEnum.colombia,
  CountryEnum.chile,
  CountryEnum.mexico,
];
