export enum CredentialEnum {
  TITLE = "Credenciales",
  DESCRIPTION = "Establece las credenciales transaccionales públicas y privadas.",
}

export enum WebhooksEnum {
  TITLE = "Webhooks",
  DESCRIPTION = "Configura las notificaciones por webhooks que el comercio tendrá disponibles.",
}

export enum NotificationEnum {
  TITLE = "Notificaciones de webhooks",
  DESCRIPTION = "Haz seguimiento de tus URLs de notificaciones de tus webhooks y los datos de envío y respuesta.",
}

export enum BranchEnum {
  TITLE = "Branches",
  DESCRIPTION = "Establece las credenciales transaccionales públicas y privadas.",
  TOOLTIP_TEXT = "Únicamente la sección de credenciales va a agrupar la información los branches.",
}

export enum CustomerEnum {
  TITLE = "Customer",
  DESCRIPTION = "Establece las credenciales públicas y privadas.",
  TOOLTIP_TEXT = "Aquí podrás consultar las credenciales de tus customer y automatizar las credenciales de tus branches",
}
