/* eslint-disable sort-keys */
import {
  ICellOptionsProps,
  IconBolt,
  IconEdit,
  IconTrash,
} from "@kushki/connect-ui";
import { AxiosError } from "axios";
import { useSelector } from "react-redux";
import {
  selectGetWebhookList,
  selectNodeInfo,
  selectWebhookList,
} from "../../../store/selectors/selectors";
import { useEffect, useState } from "react";
import useModal from "../../../hooks/useModal/useModal";
import { usePromise } from "../../../hooks/usePromise/usePromise";
import {
  ALL_TABLE_COLUMNS,
  onRenderValue,
} from "../../../shared/constants/customer_webhooks";
import { WebhookActionsEnum } from "../../../shared/enums/WebhookActions";
import { WebhookSnackbarStatus } from "../../../shared/enums/WebhookModals";
import { deleteWebhookReq } from "../../../shared/utils/webhooks/deleteWebhookReq";
import { IUseCustomerWebhooks } from "./useCustomerWebhooks.interface";
import { useAppDispatch } from "../../../store/hooks/hooks";
import { getWebhookList } from "../../../store/thunks/developers/developers.thunks";
import { defaultTo, get } from "lodash";
import { Webhook } from "../../../shared/interfaces/Webhook.interface";
import { useAppNavigate } from "../../../shared/hooks/useNavigate";
import { useLocation, useSearchParams } from "react-router-dom";
import { validIsCentralized } from "../../../shared/utils/centralized/validIsCentralized";
import { ConfigPropName } from "../../../shared/enums/ConfigurationIdEnum";
import { QueryParamsEnum } from "../../../shared/enums/queryParamsEnum";

export interface IWebhookListResponse {
  items: ICustomerWebhooksTable[];
  [key: string]: unknown;
}

export interface ICustomerWebhooksTable {
  id: string;
  merchantId: string;
  created: number;
  alias: string;
  headers: unknown[];
  events: {
    service: string;
    status: string;
  }[];
  status: {
    status: string;
    url: string;
  }[];
  urls: string[];
  options?: Partial<ICellOptionsProps>;
}

export const DEFAULT_LIMIT_WEBHOOK_LIST = 5;

export interface ILastEvaluatedKey {
  id: string;
  merchantId: string;
}

export const useCustomerWebhooks = (): IUseCustomerWebhooks => {
  const dispatch = useAppDispatch();
  const { navigate } = useAppNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const merchantId = defaultTo(
    searchParams.get(QueryParamsEnum.PUBLIC_MERCHANT_ID),
    ""
  );

  const [totalItems, setTotalItems] = useState(5);
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState<ICustomerWebhooksTable[]>();
  const [lastEvaluatedKey, setLasEvaluatedKey] = useState<ILastEvaluatedKey[]>(
    []
  );
  const [selectedWebhook, setSelectedWebhook] = useState<Webhook | null>(null);
  const [deleteWebhookSnackbar, setDeleteWebhookSnackbar] = useState<
    IUseCustomerWebhooks["snackbars"][keyof IUseCustomerWebhooks["snackbars"]]
  >({
    isOpen: false,
    message: "",
    type: "info",
    close: () => setDeleteWebhookSnackbar((s) => ({ ...s, isOpen: false })),
  });

  const [isCentralized, setIsCentralized] = useState<boolean>(false);

  const deleteWebhookModalSimple = useModal();
  const deleteWebhookModalLoader = useModal();
  const deleteWebhook = usePromise<
    unknown,
    AxiosError<{ code: string; message: string }>
  >(() => deleteWebhookReq(selectedWebhook!.id!), {
    onPending: () => {
      deleteWebhookModalSimple.close();
      deleteWebhookModalLoader.open();
    },
    onFulfilled: async () => {
      setDeleteWebhookSnackbar((s) => ({
        ...s,
        isOpen: true,
        message: WebhookSnackbarStatus.SUCCESS,
        type: "success",
      }));
      await dispatch(
        getWebhookList({ merchantId, limit: DEFAULT_LIMIT_WEBHOOK_LIST + 1 })
      );
    },
    onError: () => {
      setDeleteWebhookSnackbar((s) => ({
        ...s,
        isOpen: true,
        message: WebhookSnackbarStatus.ERROR,
        type: "error",
      }));
    },
    onFinally: () => {
      deleteWebhookModalLoader.close();
    },
  });
  const simulateWebhookModal = useModal();
  const webhookList = useSelector(selectWebhookList);
  const webhookResponse = useSelector(selectGetWebhookList);
  const nodeInfo = useSelector(selectNodeInfo);

  const handlers = {
    delete: () => {
      deleteWebhook.dispatch();
    },
    edit: (webhook: Webhook) => {
      location.search = `?publicMerchantId=${merchantId}&menuItem=true&mode=edition&webhookId=${webhook.id}`;
      navigate<Webhook>("/../merchant-webhook/create");
    },
  };

  const onPageClick = (initial: number, last: number) => {
    return { initial, last };
  };

  const addOptions = () => {
    const values = webhookList?.items?.map(
      (item: Webhook) =>
        //@ts-ignore
        ({
          ...item,
          options: {
            isDisabled: isCentralized,
            optionListItems: [
              {
                Icon: IconBolt,
                onClick: () => {
                  setSelectedWebhook(item);
                  simulateWebhookModal.open();
                },
                optionText: WebhookActionsEnum.SIMULATE,
                type: "type1",
              },
              {
                Icon: IconEdit,
                onClick: () => handlers.edit(item),
                optionText: WebhookActionsEnum.EDIT,
                type: "type1",
              },
              {
                Icon: IconTrash,
                onClick: () => {
                  setSelectedWebhook(item);
                  deleteWebhookModalSimple.open();
                },
                optionText: WebhookActionsEnum.DELETE,
                type: "type2",
              },
            ],
          },
        } as ICustomerWebhooksTable)
    );

    if (values.length === DEFAULT_LIMIT_WEBHOOK_LIST + 1) {
      values.length = DEFAULT_LIMIT_WEBHOOK_LIST;

      let lastId = {
        id: values[values.length - 1].id,
        merchantId: values[values.length - 1].merchantId,
      };

      setLasEvaluatedKey((prev) => {
        return [...prev, lastId];
      });
    } else {
      setLasEvaluatedKey((prev) => {
        return [...prev, get(webhookList, "lastEvaluatedKey", undefined)];
      });
    }

    setRows(values);
    setIsLoading(false);
  };

  useEffect(() => {
    if (webhookResponse === "success") {
      addOptions();
    }
    if (deleteWebhookSnackbar.type === "success") {
      addOptions();
    }
  }, [webhookResponse, deleteWebhookSnackbar, merchantId, webhookList]);

  useEffect(() => {
    setTotalItems(webhookList.items.length);
  }, [webhookList]);

  useEffect(() => {
    if (nodeInfo) {
      const entityName: string = nodeInfo.entityName;

      nodeInfo?.configs.forEach((obj: any) => {
        if (obj.configuration === "cn014") {
          setIsCentralized(
            validIsCentralized(
              entityName,
              get(obj, ConfigPropName.CentralizedNodeId, "")
            )
          );
        }
      });
    }
  }, [nodeInfo]);

  const handleNextPage = () => {
    setIsLoading(true);
    dispatch(
      getWebhookList({
        limit: DEFAULT_LIMIT_WEBHOOK_LIST + 1,
        merchantId: merchantId,
        lastEvaluatedKey: lastEvaluatedKey[lastEvaluatedKey.length - 1],
      })
    );
  };

  const handlePrevious = () => {
    setIsLoading(true);
    dispatch(
      getWebhookList({
        limit: DEFAULT_LIMIT_WEBHOOK_LIST + 1,
        merchantId: merchantId,
        lastEvaluatedKey: lastEvaluatedKey[lastEvaluatedKey.length - 3],
      })
    );

    setLasEvaluatedKey((prev) => {
      return prev.slice(0, prev.length - 2);
    });
  };

  return {
    selectedWebhook,
    handlers,
    modals: {
      deleteWebhookModalLoader,
      deleteWebhookModalSimple,
      simulateWebhookModal,
    },
    snackbars: {
      deleteWebhookSnackbar,
    },
    table: {
      columns: ALL_TABLE_COLUMNS,
      isLoading: isLoading || rows === undefined,
      onRenderValue,
      pagination: {
        onPageClick,
        totalItems,
        handleNextPage,
        handlePrevious,
        disableNext:
          lastEvaluatedKey[lastEvaluatedKey.length - 1] === undefined,
      },
      rows: rows ?? [],
      setIsLoading,
    },
  };
};
