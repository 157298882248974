import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { MerchantResponse } from "../../../../types/merchant_response";
import { IAuthInfo } from "../../../shared/infrastructure/interfaces/IAuthInfo";
import { MerchantProcessorFetch } from "../../../shared/interfaces/MerchantProcessorFetch";
import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { defaultTo, get, isEmpty } from "lodash";
import { PaymentMethods } from "../../../shared/infrastructure/constants/MerchantConfigConstants";
import {
  getDefaultProcessor,
  getProcessors,
} from "../../../store/actionCreators";
import { routes } from "../../../shared/infrastructure/constants/routes";
import { CountriesEnum } from "../../../shared/infrastructure/constants/countries-enum";
import { CountriesCredomaticEnum } from "../../../shared/infrastructure/constants/countries-credomatic-enum";
import { GetAppConfigPaymentMethodsResponse } from "../../../../types/get_app_config_payment_method_response";

export interface IUseAdminConfiguration {
  merchant: MerchantResponse;
  authInfo: IAuthInfo;
  merchantProcessorsName?: MerchantProcessorFetch[] | undefined;
  cardProcessors?: MerchantProcessorFetch[] | undefined;
  cashProcessors?: MerchantProcessorFetch[] | undefined;
  transferProcessors?: MerchantProcessorFetch[] | undefined;
  cashOutProcessors?: MerchantProcessorFetch[] | undefined;
  transferOutProcessors?: MerchantProcessorFetch[] | undefined;
  transferSubscriptionProcessors?: MerchantProcessorFetch[] | undefined;
  transferInternationalProcessors?: MerchantProcessorFetch[] | undefined;
  defaultProcessors?: object | undefined;
  isLoadingGetMerchantProcessors: boolean | undefined;
  showDeferred: boolean;
  showButtonCreateDeferred: () => boolean;
  handleRedirectCreateDeferred: () => void;
  appConfigPaymentMethods: GetAppConfigPaymentMethodsResponse;
}

export const useAdminConfiguration = (): IUseAdminConfiguration => {
  const { merchantId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const merchant: MerchantResponse = useSelector(
    (state: IAppState) => state.merchant!
  );
  const authInfo: IAuthInfo = useSelector(
    (state: IAppState) => state.authInfo!
  );
  const merchantProcessorsName:
    | MerchantProcessorFetch[]
    | undefined = useSelector((state: IAppState) => state.merchantProcessors);

  const defaultProcessors: object | undefined = useSelector(
    (state: IAppState) => state.defaultProcessor!
  );
  const isLoadingGetMerchantProcessors: boolean | undefined = useSelector(
    (state: IAppState) => state.isLoadingGetMerchantProcessors
  );
  const [cardProcessors, setCardProcessors] = React.useState<
    MerchantProcessorFetch[] | undefined
  >([]);
  const appConfigPaymentMethods: GetAppConfigPaymentMethodsResponse = useSelector(
    (state: IAppState) => state.appConfigPaymentMethods!
  );
  const [transferProcessors, setTransferProcessors] = React.useState<
    MerchantProcessorFetch[] | undefined
  >([]);
  const [cashProcessors, setCashProcessors] = React.useState<
    MerchantProcessorFetch[] | undefined
  >([]);
  const [cashOutProcessors, setCashOutProcessors] = React.useState<
    MerchantProcessorFetch[] | undefined
  >([]);
  const [transferOutProcessors, setTransferOutProcessors] = React.useState<
    MerchantProcessorFetch[] | undefined
  >([]);
  const [
    transferSubscriptionProcessors,
    setTransferSubscriptionProcessors,
  ] = React.useState<MerchantProcessorFetch[] | undefined>([]);
  const [
    transferInternationalProcessors,
    setTransferInternationalProcessors,
  ] = React.useState<MerchantProcessorFetch[] | undefined>([]);
  const [showDeferred, setShowDeferred] = React.useState<boolean>(false);

  const merchantBasicInformation: string = defaultTo(
    localStorage.getItem("merchantBasicInformation"),
    // eslint-disable-next-line
    `{"country":"","publicMerchantId":"0000000","name":"sin merchant"}`
  );
  const parseMerchantBasicInformation = JSON.parse(merchantBasicInformation);
  const isDeferred = () => {
    const country: string = parseMerchantBasicInformation.country;
    if (
      Object.values(CountriesCredomaticEnum).includes(
        country as CountriesCredomaticEnum
      )
    )
      setShowDeferred(true);
  };
  const showButtonCreateDeferred = () => true;
  const handleRedirectCreateDeferred = () => {
    const country: string = parseMerchantBasicInformation.country;
    if (country === CountriesEnum.ECUADOR) {
      history.push(routes.DEFERRED_CREATE);

      return;
    }

    history.push(routes.DEFERRED);
  };

  useEffect(() => {
    isDeferred();
  }, []);

  useEffect(() => {
    if (!merchant) return;
    dispatch(getProcessors(get(merchant, "publicMerchantId", merchantId)));
    dispatch(
      getDefaultProcessor(get(merchant, "publicMerchantId", merchantId))
    );

    if (!isEmpty(merchant)) {
      localStorage.setItem(
        "merchantBasicInformation",
        JSON.stringify({
          country: get(merchant, "country"),
          name: get(merchant, "name"),
          origin: "configMerchant",
          publicMerchantId: get(merchant, "publicMerchantId", merchantId),
          webSite: get(merchant, "webSite"),
        })
      );
      if (get(merchant, "status") === "pending") {
        history.push(
          `/create-merchant/details?publicMerchantId=${merchant.publicMerchantId}&hideSideBar=true`
        );
      }
    }
  }, [merchant]);

  useEffect(() => {
    setCardProcessors(
      merchantProcessorsName?.filter(
        (data) => data.paymentMethod === PaymentMethods.CARD
      )
    );
    setTransferProcessors(
      merchantProcessorsName?.filter(
        (data) => data.paymentMethod === PaymentMethods.TRANSFER
      )
    );
    setCashProcessors(
      merchantProcessorsName?.filter(
        (data) => data.paymentMethod === PaymentMethods.CASH
      )
    );
    setCashOutProcessors(
      merchantProcessorsName?.filter(
        (data) => data.paymentMethod === PaymentMethods.CASH_OUT
      )
    );
    setTransferOutProcessors(
      merchantProcessorsName?.filter(
        (data) => data.paymentMethod === PaymentMethods.TRANSFER_OUT
      )
    );
    setTransferSubscriptionProcessors(
      merchantProcessorsName?.filter(
        (data) => data.paymentMethod === PaymentMethods.TRANSFER_SUBSCRIPTIONS
      )
    );
    setTransferInternationalProcessors(
      merchantProcessorsName?.filter(
        (data) => data.paymentMethod === PaymentMethods.INTERNATIONAL_TRANSFER
      )
    );
  }, [merchantProcessorsName]);

  return {
    merchant,
    authInfo,
    merchantProcessorsName,
    cardProcessors,
    transferProcessors,
    cashProcessors,
    cashOutProcessors,
    defaultProcessors,
    transferOutProcessors,
    transferSubscriptionProcessors,
    transferInternationalProcessors,
    isLoadingGetMerchantProcessors,
    showDeferred,
    showButtonCreateDeferred,
    handleRedirectCreateDeferred,
    appConfigPaymentMethods,
  };
};
