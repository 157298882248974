export enum ActionTypes {
  SET_BILLING_DATA = "[BillingDashboard] SET_BILLING_DATA",
  SET_BILLING_DATA_COUNTER = "[BillingDashboard] SET_BILLING_DATA_COUNTER",
  SET_LOADING_BILLING = "[BillingDashboard] SET_LOADING_BILLING",
  SET_PATH_LOADING_PROCESS_BILLING = "[BillingDashboard] SET_PATH_LOADING_PROCESS_BILLING",
  SET_LOADING_HISTORIC = "[BillingDashboard] SET_LOADING_HISTORIC",
  SET_LOADING_INVOICE = "[BillingInvoice] SET_LOADING_INVOICE",
  SET_BILLING_TRX_DATA = "[BillingDashboard] SET_BILLING_TRX_DATA",
  SET_LOADING_UPDATE_DISPERSION = "[BillingDashboard] SET_LOADING_UPDATE_DISPERSION",
  SET_HISTORIC_DATA = "[BillingDashboard] SET_HISTORIC_DATA",
  SET_HISTORIC_TRX = "[BillingInvoice] SET_HISTORIC_TRX",
  SET_INVOICE_TRX = "[BillingInvoice] SET_INVOICE_TRX",
  SET_FIREBASE_ID = "[BillingDashboard] SET_FIREBASE_ID",
  SET_TRANSACTIONS_FILE = "[BillingDashboard] SET_TRANSACTIONS_FILE",
  SET_COUNTRY = "[BillingDashboard] SET_COUNTRY",
  SET_PAGINATION = "[BillingDashboard] SET_PAGINATION",
  SET_NOTIFICATION = "[BillingDashboard] SET_NOTIFICATION",
  SET_DOWNLOADFILE = "[BillingDashboard] SET_DOWNLOADFILE",
  SET_TRANSACTION_TABLE_DOWNLOAD_FILE = "[BillingDashboard] SET_TRANSACTION_TABLE_DOWNLOAD_FILE",
  SET_LOADINGDOWNLOADFILE = "[BillingDashboard] SET_LOADINGDOWNLOADFILE",
  SET_ISLOADINGDOWNLOADFILE = "[BillingDashboard] SET_ISLOADINGDOWNLOADFILE",
  SET_DOWNLOAD_FILE_DETAIL = "[BillingDashboard] SET_DOWNLOADFILE_DETAIL",
  SET_DOWNLOAD_FILE_MANUAL_CONTINGENCY = "[BillingDashboard] SET_DOWNLOADFILE_MANUAL_CONTINGENCY",
  SET_LOADINGDOWNLOAD_PREPROCESS = "[BillingDashboard] SET_LOADINGDOWNLOADPREPROCESS",
  SET_LOADINGDOWNLOADMANUALCONTINGECY_PREPROCESS = "[BillingDashboard] SET_LOADINGDOWNLOADMANUALCONTINGECY_PREPROCESS",
  SET_STATUS_FLOW = "[BillingDashboard] SET_STATUSFLOW",
  SET_REPROCESS_COUNT = "[BillingDashboard] SET_REPROCESS_COUNT",
  SET_DOWNLOAD_PDF = "SET_DOWNLOAD_PDF",
  SET_DOWNLOAD_PDF_LOADING = "SET_DOWNLOAD_PDF_LOADING",
  SET_IS_LOADING_DELETE_RETENTION = "[BillingDashboard] SET_IS_LOADING_DELETE_RETENTION",
  SET_RETENTION_DATA = "SET_RETENTION_DATA",
  SET_ACTIVE_TAB_EXECUTOR = "SET_ACTIVE_TAB_EXECUTOR",
  SET_STEP_TIME_LINE_DATA = "SET_STEP_TIME_LINE_DATA",
  SET_OPEN_MODAL_DETAIL_TIME_LINE = "SET_OPEN_MODAL_DETAIL_TIME_LINE",
  SET_OPEN_MODAL_MODIFY_INCONSISTENCE = "SET_OPEN_MODAL_MODIFY_INCONSISTENCE",
  SET_RETENTIONS_DATA_COUNTER = "SET_RETENTIONS_DATA_COUNTER",
  SET_RETENTIONS_PROCESS_LOADER = "SET_RETENTIONS_PROCESS_LOADER",
  SET_IS_LOADING = "SET_IS_LOADING",
  SET_OPEN_MODAL_BACK_RETENTION = "SET_OPEN_MODAL_BACK_RETENTION",
  SET_PATH_LOADING_RETENTION = "SET_PATH_LOADING_RETENTION",
  SET_PROCESS_DATA = "SET_PROCESS_DATA",
  SET_OPEN_MODAL_ANNUL = "[BillingDashboard] SET_OPEN_MODAL_ANNUL",
  SET_OPEN_MODAL_CREDIT_DEBIT_NOTE = "[BillingDashboard] SET_OPEN_MODAL_CREDIT_DEBIT_NOTE",
  SET_REDIRECT_CREATE_INVOICE = "[BillingDashboard] SET_REDIRECT_CREATE_INVOICE",
  SET_DOWNLOAD_FILE_EVIDENCE = "[BillingDashboard] SET_DOWNLOAD_FILE_EVIDENCE",
  SET_TRANSACTIONID_ADD = "[BillingDashboard] SET_TRANSACTIONID_ADD",
  SET_TRANSACTIONIDHISTORY_ADD = "[BillingDashboard] SET_TRANSACTIONIDHISTORY_ADD",
  SET_TRANSACTIONIDHISTORY_DEFAULT = "[BillingDashboard] SET_TRANSACTIONIDHISTORY_DEFAULT",
  SET_DISPERSION_ID = "SET_DISPERSION_ID",
  SET_CHARGE_ID = "SET_CHARGE_ID",
  SET_COUNTRY_LIST = "SET_COUNTRY_LIST",
  SET_ANNUL_DEAD_LINE = "[BillingDashboard] SET_ANNUL_DEAD_LINE",
  SET_ANNUL_DEAD_LINE_VALIDATE = "[BillingDashboard] SET_ANNUL_DEAD_LINE_VALIDATE",
  SET_PDF_RETENTION = "[BillingDashboard] SET_PDF_RETENTION",
  SET_MODIFY_INCONSISTENCE_AMOUNT_TRX = "[BillingDashboard] SET_MODIFY_INCONSISTENCE_AMOUNT_TRX",
  SET_CATALOG_LIST = "[BillingDashboard] SET_CATALOG_LIST",
}
