export enum ActionTypes {
  SET_NOTIFICATION = "[REFUND] SET_NOTIFICATION",
  SET_REFUND_DATA = "[REFUND] SET_REFUND_DATA",
  SET_REFUND_TRX_DATA = "[REFUND] SET_REFUND_TRX_DATA",
  SET_UPDATE_TRX_STATUS = "[REFUND] SET_UPDATE_TRX_STATUS",
  SET_IS_LOADING_REFUND_DATA = "[REFUND] SET_IS_LOADING_REFUND_DATA",
  SET_LOADING_MASSIVE = "[REFUND] SET_LOADING_MASSIVE",
  SET_ERROR_MASSIVE_ASYNC = "[REFUND] SET_ERROR_MASSIVE_ASYNC",
  SET_ERROR_MASSIVE_ASYNC_RESULT = "[REFUND] SET_ERROR_MASSIVE_ASYNC_RESULT",
  SET_ACTION_MASSIVE_TRANSACTION = "[REFUND] SET_ACTION_MASSIVE_TRANSACTION",
  SET_WS_RESPONSE = "[REFUND] SET_WS_RESPONSE",
  SET_TAB = "[REFUND] SET_TAB",
  SET_REFUND_HISTORY_DATA = "[REFUND] SET_REFUND_HISTORY_DATA",
  SET_ACTIVE_TAB = "[REFUND] SET_ACTIVE_TAB",
}
