import { TableSimple } from "@kushki/connect-ui";
import React from "react";
import { useDataTableComponentStateBranch } from "./state/useDataTable";
import { ModalContainer } from "../ModalContainerBranch/ModalContainerBranch";
import { LabelEnum } from "../../shared/infrastructure/enums/LabelEnum";
import { IDataTableBranch } from "../ContentModalBranch/ContentModal.interface";

export const DataTable: React.FC<IDataTableBranch> = (
  props: IDataTableBranch
) => {
  // TODO : ESTO DEBE SER CAMBIADO CUANDO ESTE LA HISTORIA DE LISTADO DE REGLAS
  const { cells, rows } = useDataTableComponentStateBranch(props);

  return (
    <React.Fragment>
      <TableSimple
        header={{
          cells,
          headerProps: {
            isDisable: false,
          },
        }}
        rows={rows}
        showPagination={false}
      />
      <ModalContainer tabName={LabelEnum.BUSINESS_RULES} />
    </React.Fragment>
  );
};
