import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { defaultTo } from "lodash";
import { OperationEnum } from "../../../shared/enums/sections";

export interface UseSideBar {
  showSideBar: boolean;
}

export const useSideBar = (): UseSideBar => {
  const [showSideBar, setShowSideBar] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const showSidebar = () => {
    const getQuery: string = defaultTo(searchParams.get("menuItem"), "");
    const operation: string = defaultTo(searchParams.get("operation"), "");

    const allowValues: string[] = ["false", "true"];

    if (operation == OperationEnum.EDIT) {
      setShowSideBar(false);
    } else {
      if (allowValues.includes(getQuery)) {
        getQuery === "false" ? setShowSideBar(false) : setShowSideBar(true);
      } else {
        setShowSideBar(false);
      }
    }
  };

  useEffect(() => {
    showSidebar();
  }, [location]);

  return {
    showSideBar,
  };
};
