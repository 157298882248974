import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MerchantResponse } from "../../../types/merchant_response";
import { get } from "lodash";
import {
  getCatalog,
  INVOICE_FRECUENCY,
} from "../../shared/infrastructure/constants/MerchantInformationConstant";
import { CatalogsEnum } from "../../shared/infrastructure/constants/catalogs-enum";

export interface PaymentSectionProps {
  merchant: MerchantResponse | undefined;
}

const useStyles = makeStyles(() => ({
  labelTitle2: {
    color: "#023365",
    fontSize: "17px",
    fontWeight: 500,
    fontStyle: "normal",
  },
  labelTitle3: {
    color: "#46525C",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  labelColumns: {
    color: "#6D7781",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
  },
  labelValues: {
    color: "#293036",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "normal",
  },
}));

export const PaymentSection: React.FC<PaymentSectionProps> = (
  props: PaymentSectionProps
) => {
  const style = useStyles();

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item container>
          <Grid item xs={12}>
            <Box display={"flex"} alignItems={"center"}>
              <Box flexGrow={1}>
                <Typography className={style.labelTitle2}>Cobros</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className={style.labelTitle3}>
              Configura cómo cobramos nuestras comisiones.
            </Typography>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid
            container
            spacing={2}
            justify={"flex-start"}
            alignItems={"baseline"}
          >
            <Grid item xs={12} md={2}>
              <Typography className={style.labelColumns}>Frecuencia</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className={style.labelValues} align={"left"}>
                {get(
                  INVOICE_FRECUENCY.filter(
                    (frequency) =>
                      frequency.value === props.merchant!.chargeFrequency
                  )[0],
                  "name",
                  ""
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography className={style.labelColumns}>Banco</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className={style.labelValues} align={"left"}>
                {get(
                  getCatalog(
                    props.merchant!.country,
                    CatalogsEnum.Banks
                  ).filter((bank) => bank.value === props.merchant?.bankId)[0],
                  "name",
                  ""
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography className={style.labelColumns}>
                Cobro mínimo
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className={style.labelValues} align={"left"}>
                {props.merchant?.chargeMinAmount}
                {"  "}
                {props.merchant!.chargeCurrency === "" ||
                props.merchant!.chargeCurrency === null ||
                props.merchant!.chargeCurrency === undefined
                  ? get(
                      getCatalog(
                        props.merchant!.country,
                        CatalogsEnum.Currency
                      )[0],
                      "name",
                      ""
                    )
                  : props.merchant!.chargeCurrency}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography className={style.labelColumns}>
                Cta. Bancaria
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className={style.labelValues} align={"left"}>
                {props.merchant?.accountNumber === "N/A" ||
                props.merchant?.accountNumber === undefined ||
                props.merchant?.accountNumber === null
                  ? props.merchant!.accountNumber
                  : `****${props.merchant!.accountNumber.substr(-4)}`}
              </Typography>
            </Grid>
            <Grid item md={6} />
            <Grid item xs={12} md={2}>
              <Typography className={style.labelColumns}>
                Tipo de cuenta
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className={style.labelValues}>
                {get(
                  getCatalog(
                    props.merchant!.country,
                    CatalogsEnum.BankAccountTypes
                  ).filter(
                    (accountType) =>
                      accountType.value === props.merchant!.accountType
                  )[0],
                  "name",
                  ""
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
