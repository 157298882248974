import { useEffect, useState } from "react";
import { SmartlinksLogos } from "../../../../types/smartlink_customization";
import { get } from "lodash";
import { ILogoUpload } from "../LogoUpload";

export interface IUseLogoUpload {
  openGallery: boolean;
  handler: {
    handleOpenGallery: (value: boolean) => void;
    handleInsertedImage: (url: string, name: string) => void;
  };
}

export const useLogoUpload = (props: ILogoUpload): IUseLogoUpload => {
  const generateNameFiles = (files: SmartlinksLogos[]) =>
    files.map((f: SmartlinksLogos, index) => ({
      id: get(f, "alias", `${index}`).replace(/ /g, "_") + index,
      name: get(f, "alias", `${index}`),
      alias: f.alias,
      image: f.image,
      extension: f.extension,
    }));

  const [nameFiles, setNameFiles] = useState<SmartlinksLogos[]>(
    generateNameFiles(get(props, "files", []))
  );
  const [openGallery, setOpenGallery] = useState<boolean>(false);

  useEffect(() => setNameFiles(generateNameFiles(get(props, "files", []))), [
    props.files,
  ]);

  const handleInsertedImage = (url: string, name: string) => {
    const currentFile: SmartlinksLogos = {
      id: getLastElement(url, "/"),
      image: url,
      name,
      alias: getFileName(name),
      extension: `.${getLastElement(name, ".")}`,
    };
    const copy_name_files: SmartlinksLogos[] = [...nameFiles];
    copy_name_files.push(currentFile);
    setNameFiles(copy_name_files);
    props.onChange?.({ prop: props.prop, files: currentFile });
  };

  const getFileName = (nameFile: string) => {
    const names: string[] = nameFile.split(".");
    names.pop();
    return names.join(".");
  };

  const getLastElement = (name: string, separator: string) => {
    const elements: string[] = name.split(separator);
    return elements[elements.length - 1];
  };

  return {
    openGallery,
    handler: {
      handleOpenGallery: setOpenGallery,
      handleInsertedImage,
    },
  };
};
