import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { ChevronRight } from "react-feather";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import { BreadcrumItem, BreadcrumProps } from "./Breadcrum.interfaces";
import { useBreadcrumStyles } from "./Breadcrum.styles";

const Breadcrumb = (props: BreadcrumProps) => {
  const classes = useBreadcrumStyles(props);

  return (
    <React.Fragment>
      <Breadcrumbs
        separator={<ChevronRight size={14} />}
        aria-label="breadcrumb"
      >
        {props.items.map((item: BreadcrumItem) => (
          <Link
            key={item.label}
            component={RouterLink}
            to={item.url}
            className={classes.linkLabel}
          >
            {item.label}
          </Link>
        ))}
        <Typography className={classes.lastLabel}>{props.lastItem}</Typography>
      </Breadcrumbs>
    </React.Fragment>
  );
};

export default Breadcrumb;
