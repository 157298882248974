import {
  IOptionImageSelection,
  IOptionSelection,
  RuleSchemaEnum,
} from "./RuleRequestConstants";
import { CountryEnum } from "../enums/CountryEnum";
import { BANKS_ECUADOR, CURRENCY_ECUADOR } from "./CatalogEC";
import { BANKS_PERU, CURRENCY_PERU } from "./CatalogPE";
import { BANKS_MEXICO, CURRENCY_MEXICO } from "./CatalogMX";
import { BANKS_COLOMBIA, CURRENCY_COLOMBIA } from "./CatalogCO";
import { BANKS_CHILE, CURRENCY_CHILE } from "./CatalogCL";
import { BANKS_BRAZIL, CURRENCY_BRAZIL } from "./CatalogBR";
import { PaymentMethodEnum } from "../enums/PaymentMethodEnum";
import { find, get } from "lodash";
import cardImage from "../../../assets/images/card.svg";
import transferImage from "../../../assets/images/transfer.svg";

export enum AuthenticationRulesSection {
  TITLE = "Reglas de autenticación",
  SUBTITLE = "Indique las condiciones que deben cumplirse para realizar la acción de autenticación. Recuerda que puedes agregar más de una regla.",
  HEADERRULE = "Regla de autenticación N°",
  CONDITIONRULE = "Agrega condiciones para configurar la regla",
}

export enum AuthenticationRulesDashboard {
  TITLE = "Solicitudes de reglas de autenticación",
}

export enum AuthenticationRulesTitleButton {
  RETURN = "Regresar",
  SAVE = "Guardar Regla",
}

export enum AuthenticationRulesSubtitleSection {
  ALIAS_HEADER = "Genera un alias que te permita identificar la regla",
  TYPE_HEADER = "Selecciona el tipo de regla",
  COUNTRY_HEADER = "Seleccionar países",
  MERCHANT_HEADER = "Buscar comercio",
  SERVICE_HEADER = "¿Qué medio de pago afectará esta regla?",
  GENERAL_ACTION_HEADER = "Acción General",
  GENERAL_ACTION_SUBTITLE = "Selecciona la acción principal que se cumplirá la regla de seguridad.",
  STRICT_CONDITION_HEADER = "Condiciones",
  STRICT_CONDITION_SUBTITLE = "Indica las condiciones que deben cumplirse para realizar la acción indicada en la sección anterior.",
  STRICT_CONDITION_SUBTITLE2 = "Agrega tantas condiciones como sean necesarias.",
  SPEED_CONDITION_HEADER = "Condiciones de velocidad",
  SPEED_CONDITION_SUBTITLE = "Agrega acciones secundarias a tu regla.",
}

export enum AuthenticationRulesLabelField {
  ACTION = "Acción",
  COUNTRY_HEADER = "Seleccionar países",
  VALIDATOR = "Validador externo",
  CODE = "Código",
  MESSAGE = "Mensaje",
  LABEL = "Etiqueta",
  OPERATOR = "Operador",
  VALUE = "Valor",
  PERIODICITY = "Periodicidad",
  TRANSACTION = "Transacción",
}

export const STRICT_CONDITION_CARD_VALUES: IOptionSelection[] = [
  {
    name: "Banco",
    value: "transactionBank",
  },
  {
    name: "Marca",
    value: "transactionBrand",
  },
  {
    name: "Diferido",
    value: "transactionIsDeferred",
  },
  {
    name: "Procesador",
    value: "transactionProcessor",
  },
  {
    name: "Horario",
    value: RuleSchemaEnum.transactionCreated,
  },
  { name: "Tipo de transacción", value: RuleSchemaEnum.transactionType },
  {
    name: "País",
    value: "transactionCountry",
  },
  {
    name: "Monto",
    value: "transactionTotalAmount",
  },
  {
    name: "IP",
    value: "transactionIP",
  },
  {
    name: "Bin",
    value: "transactionBIN",
  },
  {
    name: "Tipo de tarjeta",
    value: "transactionIsCreditCard",
  },
  {
    name: "Tarjeta enmascarada",
    value: "transactionMaskedCreditCard",
  },
  {
    name: "Kushki ID",
    value: "transactionCardId",
  },
  {
    name: "Emision de la tarjeta",
    value: "transactionCardIssuer",
  },
  {
    name: "Moneda",
    value: "transactionCurrency",
  },
  {
    name: "Credencial",
    value: "transactionCredential",
  },
];

export const CARD_ISSUER_VALUES: IOptionSelection[] = [
  { name: "Nacional", value: "national" },
  { name: "Internacional", value: "international" },
];

export const CARD_TYPE_VALUES: IOptionSelection[] = [
  { name: "Crédito", value: "true" },
  { name: "Débito", value: "false" },
];

export const getBanksByCountry = (countries: string[]): IOptionSelection[] => {
  let banks: IOptionSelection[] = [];

  if (countries.includes(CountryEnum.ECUADOR))
    banks = [...banks, ...BANKS_ECUADOR];
  if (countries.includes(CountryEnum.PERU)) banks = [...banks, ...BANKS_PERU];
  if (countries.includes(CountryEnum.MEXICO))
    banks = [...banks, ...BANKS_MEXICO];
  if (countries.includes(CountryEnum.COLOMBIA))
    banks = [...banks, ...BANKS_COLOMBIA];
  if (countries.includes(CountryEnum.CHILE)) banks = [...banks, ...BANKS_CHILE];
  if (countries.includes(CountryEnum.BRAZIL))
    banks = [...banks, ...BANKS_BRAZIL];

  return banks;
};

export const getCurrencyByCountry = (
  countries: string[]
): IOptionSelection[] => {
  let currencies: IOptionSelection[] = [];

  if (countries.includes(CountryEnum.ECUADOR))
    currencies = [...currencies, ...CURRENCY_ECUADOR];
  if (countries.includes(CountryEnum.PERU))
    currencies = [...currencies, ...CURRENCY_PERU];
  if (countries.includes(CountryEnum.MEXICO))
    currencies = [...currencies, ...CURRENCY_MEXICO];
  if (countries.includes(CountryEnum.COLOMBIA))
    currencies = [...currencies, ...CURRENCY_COLOMBIA];
  if (countries.includes(CountryEnum.CHILE))
    currencies = [...currencies, ...CURRENCY_CHILE];
  if (countries.includes(CountryEnum.BRAZIL))
    currencies = [...currencies, ...CURRENCY_BRAZIL];

  return currencies;
};
export const getLabelValues = (
  service: string,
  type: string
): IOptionSelection[] => {
  if (type === "authenticationCountry" && service === PaymentMethodEnum.CARD)
    return STRICT_CONDITION_CARD_COUNTRY_VALUES;

  switch (service) {
    case PaymentMethodEnum.CARD:
      return STRICT_CONDITION_CARD_VALUES;
    case PaymentMethodEnum.TRANSFER:
      return STRICT_CONDITION_TRANSFER_VALUES;
    default:
      return STRICT_CONDITION_CARD_VALUES;
  }
};

export const STRICT_CONDITION_CARD_COUNTRY_VALUES: IOptionSelection[] = [
  {
    name: "Banco",
    value: "transactionBank",
  },
  {
    name: "Marca",
    value: "transactionBrand",
  },
  {
    name: "Diferido",
    value: "transactionIsDeferred",
  },
  {
    name: "País",
    value: "transactionCountry",
  },
  {
    name: "Monto",
    value: "transactionTotalAmount",
  },
  {
    name: "IP",
    value: "transactionIP",
  },
  {
    name: "Bin",
    value: "transactionBIN",
  },
  {
    name: "Tipo de tarjeta",
    value: "transactionIsCreditCard",
  },
  {
    name: "Tarjeta enmascarada",
    value: "transactionMaskedCreditCard",
  },
  {
    name: "Kushki ID",
    value: "transactionCardId",
  },
  {
    name: "Emision de la tarjeta",
    value: "transactionCardIssuer",
  },
  {
    name: "Moneda",
    value: "transactionCurrency",
  },
  { name: "Tipo de transacción", value: RuleSchemaEnum.transactionType },
];

export const STRICT_CONDITION_TRANSFER_VALUES: IOptionSelection[] = [
  {
    name: "Monto",
    value: "transactionTotalAmount",
  },
];

export const getNames = (list: IOptionSelection[]): string[] => {
  return list.map((item) => item.name);
};

export const getOperatorValues = (value: string): IOptionSelection[] => {
  switch (value) {
    case "transactionBank":
    case "transactionBrand":
    case "transactionProcessor":
    case RuleSchemaEnum.transactionType:
      return OPERATOR_VALUES_2;
    case "transactionIsCreditCard":
    case "transactionIsDeferred":
      return [
        {
          name: "Igual",
          value: "=",
        },
      ];
    case "transactionCardIssuer":
    case "transactionCurrency":
    case "transactionCredential":
      return OPERATOR_VALUES_4;
    case "transactionCountry":
      return OPERATOR_VALUES_3;
    case "transactionBIN":
      return OPERATOR_VALUES_3;
    case "transactionIP":
    case "transactionMaskedCreditCard":
      return OPERATOR_VALUES_6;
    case "transactionCardId":
      return OPERATOR_VALUES_7;
    case "transactionTotalAmount":
      return OPERATOR_VALUES_5;
    default:
      return OPERATOR_VALUES_3;
  }
};
export const OPERATOR_VALUES_1: IOptionSelection[] = [
  {
    name: "Igual",
    value: "=",
  },
  {
    name: "Diferente",
    value: "!=",
  },
  {
    name: "Contiene",
    value: "contain",
  },
  {
    name: "No Contiene",
    value: "!contain",
  },
];

export const OPERATOR_VALUES_2: IOptionSelection[] = [
  {
    name: "Igual",
    value: "=",
  },
  {
    name: "Diferente",
    value: "!=",
  },
];

export const OPERATOR_VALUES_3: IOptionSelection[] = [
  {
    name: "Contiene",
    value: "contain",
  },
  {
    name: "No Contiene",
    value: "!contain",
  },
];

export const OPERATOR_VALUES_4: IOptionSelection[] = [
  {
    name: "Igual",
    value: "=",
  },
];

export const OPERATOR_VALUES_5: IOptionSelection[] = [
  {
    name: "Mayor que",
    value: ">",
  },
  {
    name: "Menor que",
    value: "<",
  },
];

export const OPERATOR_VALUES_6: IOptionSelection[] = [
  {
    name: "Contiene",
    value: "contain",
  },
  {
    name: "No Contiene",
    value: "!contain",
  },
  {
    name: "Todos",
    value: "=|all",
  },
];

export const OPERATOR_VALUES_7: IOptionSelection[] = [
  {
    name: "Igual",
    value: "=",
  },
  {
    name: "Diferente",
    value: "!=",
  },
  {
    name: "Contiene",
    value: "contain",
  },
  {
    name: "No Contiene",
    value: "!contain",
  },
  {
    name: "Todos",
    value: "=|all",
  },
];

export const getValues = (list: IOptionSelection[]): string[] => {
  let values: string[] = [];

  list.forEach((value) => values.push(value.value));

  return values;
};

export const PAYMENT_BRANDS_OPTIONS: IOptionSelection[] = [
  { name: "Visa", value: "visa" },
  { name: "Mastercard", value: "mastercard" },
  { name: "American Express", value: "amex" },
  { name: "Diners", value: "diners" },
  { name: "Discover", value: "discover" },
  { name: "Redcompra", value: "redcompra" },
  { name: "Alia", value: "alia" },
  { name: "Credencial", value: "credencial" },
  { name: "Maestro", value: "maestro" },
  { name: "Propia", value: "propia" },
  { name: "Cmrfalabella", value: "cmrfalabella" },
  { name: "JCB", value: "jcb" },
  { name: "Serfinanza", value: "serfinanza" },
  { name: "Unionpay", value: "unionpay" },
];
export const YES_NO_VALUES: IOptionSelection[] = [
  { name: "Si", value: "true" },
  { name: "No", value: "false" },
];

export const findName = (
  list: IOptionSelection[],
  value: string,
  compare: "name" | "value",
  path: "name" | "value"
): string => {
  return get(
    find(list, (item) => {
      return value === get(item, compare);
    }),
    path,
    ""
  );
};
export const SPEED_ACTION_VALUES: IOptionSelection[] = [
  {
    name: "Contar",
    value: "count",
  },
  {
    name: "Sumar",
    value: "sum",
  },
];

export const SPEED_TRANSACTION_STATUS_VALUES: IOptionSelection[] = [
  {
    name: "Aprobadas",
    value: "APPROVAL",
  },
  {
    name: "Declinadas",
    value: "DECLINED",
  },
  {
    name: "Todas",
    value: "ALL",
  },
];

export const SPEED_PERIODICITY_VALUES: IOptionSelection[] = [
  { name: "Por minuto", value: "minute" },
  { name: "Por hora", value: "hour" },
  { name: "Diaria", value: "day" },
  { name: "Semanal", value: "week" },
  { name: "Quincenal", value: "fortnight" },
  { name: "Mensual", value: "month" },
  { name: "Trimestral", value: "quarterly" },
  { name: "Semestral", value: "biannual" },
];

export const ALL_COUNTRY_VALUES: string[] = [
  CountryEnum.BRAZIL,
  CountryEnum.CHILE,
  CountryEnum.COLOMBIA,
  CountryEnum.ECUADOR,
  CountryEnum.MEXICO,
  CountryEnum.PERU,
];

export const SPEED_CONDITION_VALIDATION: string[] = ["block", "otp"];
export const COUNTRY_VALUES: IOptionSelection[] = [
  {
    name: "Brasil",
    value: CountryEnum.BRAZIL,
  },
  {
    name: "Chile",
    value: CountryEnum.CHILE,
  },
  {
    name: "Colombia",
    value: CountryEnum.COLOMBIA,
  },
  {
    name: "Ecuador",
    value: CountryEnum.ECUADOR,
  },
  {
    name: "México",
    value: CountryEnum.MEXICO,
  },
  {
    name: "Perú",
    value: CountryEnum.PERU,
  },
];

export const SERVICE_VALUES: IOptionImageSelection[] = [
  {
    name: "Tarjeta",
    value: "card",
    image: cardImage,
  },
  {
    name: "Transferencia",
    value: "transfer",
    image: transferImage,
  },
];

export const ACTION_VALUES: IOptionSelection[] = [
  {
    name: "Bloquear",
    value: "block",
  },
  {
    name: "Autenticar (OTP)",
    value: "otp",
  },
  {
    name: "Alertar",
    value: "warning",
  },
  {
    name: "Autenticar (OTP) Diners",
    value: "protectedByDiners",
  },
  {
    name: "3DS",
    value: "3dsecure",
  },
];

export const CODE_MESSAGE_VALIDATION: string[] = [
  "block",
  "warning",
  "protectedByDiners",
];
export const PARTNER_VALIDATOR_VALIDATION: string[] = ["block", "warning"];

export const PARTNER_VALIDATOR_VALUES: IOptionSelection[] = [
  {
    name: "Sin validador",
    value: "",
  },
  {
    name: "Transunion",
    value: "transunion",
  },
  {
    name: "Experian",
    value: "experian",
  },
];

export const TRANSACTION_TYPES: IOptionSelection[] = [
  { value: "token", name: "Token" },
  { value: "charge", name: "Cobro" },
  { value: "deferred", name: "Diferido" },
  { value: "preauthorization", name: "Preautorización" },
  { value: "capture", name: "Captura" },
  { value: "subscriptionToken", name: "Token de subscripción" },
  { value: "subscription", name: "Crear subscripción" },
  { value: "subscriptionCharge", name: "Cobro de subscripción" },
  {
    value: "subscriptionChargeValidation",
    name: "Cobro de validación de subscripción",
  },
  {
    value: "subscriptionPreauthorization",
    name: "Preautorización de subscripción",
  },
  { value: "subscriptionCapture", name: "Captura de subscripción" },
];

export const TRANSACTION_TYPES_1: IOptionSelection[] = [
  { value: "token", name: "Token" },
  { value: "subscriptionToken", name: "Token de subscripción" },
];

export const TRANSACTION_TYPES_TO_CONTAINS: IOptionSelection[] = [
  ...TRANSACTION_TYPES_1,
];
