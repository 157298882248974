import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { RedirectObject } from "../../shared/infrastructure/constants/RulesConstants";

export interface RulesDialogProps {
  open: boolean;
  onClose: (url: string) => void;
  redirectObject: RedirectObject;
}

const useStyles = makeStyles({
  actionButton: {
    color: "#6D7781",
    textTransform: "none",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
  },
  buttonPrincipal: {
    color: "#023365",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
  },
  title: {
    color: "#293036",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "140%",
  },
  subtitle: {
    color: "#6D7781",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "120%",
  },
});

export const RulesDialog: React.FC<RulesDialogProps> = (
  props: RulesDialogProps
) => {
  const classes = useStyles(props);

  const handleClose = (value: string) => {
    props.onClose(value);
  };

  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <DialogContent>
        <Box mb={2}>
          <Grid container>
            <Grid item xs={12}>
              <Box mb={2}>
                <Typography className={classes.title}>
                  Hemos configurado tu regla de negocio con éxito
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.subtitle}>
                {props.redirectObject.bodyDialog}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClose(props.redirectObject.redirectPath)}
          className={classes.actionButton}
        >
          {props.redirectObject.redirectButtonLabel}
        </Button>
        {props.redirectObject.nextStepButtonLabel ? (
          <Button
            className={classes.buttonPrincipal}
            onClick={() => handleClose(props.redirectObject.nextStepPath!)}
          >
            {props.redirectObject.nextStepButtonLabel}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};
