import { Container, Grid, Typography } from "@material-ui/core";
import React from "react";
import Breadcrumb from "../../components/common/Breadcrumb/Breadcrumb";
import {
  breadcrumbValues,
  LabelsDispersion,
} from "../../shared/infrastructure/constants/RequestDispersionConstants";
import { FileValidationDetails } from "../../components/FileValidationDetails/FileValidationDetails";

export const ValidationDetails: React.FC = () => {
  return (
    <Container fixed>
      <Breadcrumb {...breadcrumbValues} />
      <Grid container>
        <Grid container item xs={12} direction={"column"}>
          <Grid item>
            <Typography variant={"h1"} color={"primary"}>
              {LabelsDispersion.DISPERSION_REQUEST}
            </Typography>
            <FileValidationDetails />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
