import { isNil } from "lodash";
import { databaseRef } from "../firebase";
import { API_ROUTES } from "../constants/api_routes";
import { ITransactionFile } from "../../../types/transaction_file";
import { setLoadingDownload } from "../../store/actions/app.actions";

export function downloadTransactions(firebaseId: string, dispatch: any) {
  databaseRef
    .child(`${API_ROUTES.DOWNLOAD_TRANSACTIONS}${firebaseId}`)
    .on("value", (snapshot: any) => {
      const file: ITransactionFile = snapshot.val();

      if (!isNil(file.url)) {
        const a: HTMLElementTagNameMap["a"] = document.createElement("a");

        a.setAttribute("style", "display: none");
        a.href = `${file.url}`;
        document.body.appendChild(a);
        a.click();
        a.remove();

        dispatch(setLoadingDownload(false));
      }
    });
}
