import React from "react";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { ChevronRight } from "react-feather";

interface BreadcrumItem {
  label: string;
  url: string;
}

export interface BreadcrumProps {
  items: BreadcrumItem[];
  lastItem: string;
}

const Breadcrumb = (props: BreadcrumProps) => {
  return (
    <React.Fragment>
      <Breadcrumbs
        separator={<ChevronRight size={14} />}
        aria-label="breadcrumb"
      >
        {props.items.map((item: BreadcrumItem) => (
          <Link
            key={item.label}
            component={RouterLink}
            to={item.url}
            style={{ color: "#677784" }}
          >
            {item.label}
          </Link>
        ))}
        <Typography>{props.lastItem}</Typography>
      </Breadcrumbs>
    </React.Fragment>
  );
};

export default Breadcrumb;
