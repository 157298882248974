import React from "react";
import { Box, Button, Container } from "@material-ui/core";
import { WebhookBody } from "../../components/WebhookBody/WebhookBody";
import { FormProvider } from "react-hook-form";
import { IAppState } from "../../store/reducer";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  createWebhook,
  getMerchantSettings,
  IAppAction,
  setNotification,
  updateWebhook,
  validateUrl,
} from "../../store/actionCreators";
import { connect } from "react-redux";
import { WebhookCreateRequest } from "../../../types/webhook_create_request";
import { useWebhookForm } from "./state/useWebhookForm";
import { Webhook } from "../../../types/webhook_response";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { WebhookHeaders } from "../../components/WebhookHeaders/WebhookHeaders";
import { ModalTestUrl } from "../../components/ModalTestConnection/ModalTestURL/ModalTestURL";
import { PaymentUrl } from "../../shared/infrastructure/interfaces/IWebhookForm";
import { IValidUrl } from "../../shared/infrastructure/interfaces/IValidUrl";
import { INotification } from "../../shared/infrastructure/interfaces/INotification";
import { SnackBarAlert } from "../../components/Snackbar/SnackBarAlert";
import { auth } from "../../shared/auth";
import { MerchantSettingsResponse } from "../../shared/infrastructure/interfaces/IMerchantSettingsResponse";
import { SecurityWrapperEnum } from "../../shared/infrastructure/constants/SecurityWrapperEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

export interface IWebhookFormIndexStateProps {
  webhookSelected: Webhook;
  verifiedUrls: IValidUrl;
  loading: boolean;
  notification: INotification;
  merchantSettings: MerchantSettingsResponse;
}
export interface IWebhookFormIndexFunctionsProps {
  createWebhook(createRequest: WebhookCreateRequest): void;
  updateWebhook(updateRequest: WebhookCreateRequest, webhookId: string): void;
  validateUrl(request: PaymentUrl[]): void;
  setNotification(notification: INotification): void;
  getMerchantSettings: (merchantId: string) => void;
}

export type WebhookIndexFormProps = IWebhookFormIndexStateProps &
  IWebhookFormIndexFunctionsProps;
export const WebhookFormIndex: React.FC<WebhookIndexFormProps> = (
  props: WebhookIndexFormProps
) => {
  const { form, actions, breadcrumbs, formInfo, modalTestUrl, snackBar } =
    useWebhookForm(props);

  return (
    <React.Fragment>
      <Container maxWidth={"lg"} disableGutters>
        <SnackBarAlert
          msg={snackBar.message}
          open={snackBar.open}
          type={snackBar.type}
          handlerClose={actions.handlerClose}
          hasLink={false}
        />
        <Box pb={3}>
          <PageHeader
            isAdmin={true}
            isWebhookList={false}
            title={formInfo.title}
            breadcrumbs={breadcrumbs}
            handleNotification={() => {}}
          />
        </Box>
        <FormProvider {...form}>
          {auth.isAdmin() && <WebhookBody />}
          <WebhookHeaders />
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Box display="flex">
              <Button
                variant="outlined"
                disableElevation
                onClick={actions.handleGoBack}
              >
                Regresar
              </Button>
              <Box ml={2}>
                <ComponentSecurityWrapper
                  componentId={SecurityWrapperEnum.SAVE_WEBHOOK}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={form.handleSubmit(actions.handleSubmitForm)}
                  >
                    Guardar Webhook
                  </Button>
                </ComponentSecurityWrapper>
              </Box>
            </Box>
          </Box>
        </FormProvider>
      </Container>
      <ModalTestUrl
        open={modalTestUrl.open}
        loading={modalTestUrl.loading}
        verifiedUrls={modalTestUrl.verifiedUrls}
        handleCloseDialog={modalTestUrl.handleCloseDialog}
        handleSave={modalTestUrl.handleSave}
        loadingModal={modalTestUrl.loadingModal}
      />
    </React.Fragment>
  );
};

export const mapStateToProps: (
  state: IAppState
) => IWebhookFormIndexStateProps = (
  state: IAppState
): IWebhookFormIndexStateProps => ({
  webhookSelected: state.webhookSelected!,
  verifiedUrls: state.verifiedUrls!,
  loading: state.loading!,
  notification: state.notification!,
  merchantSettings: state.merchantSettings!,
});
export const mapDispatchToProps: (
  dispatch: Dispatch
) => IWebhookFormIndexFunctionsProps = (
  dispatch: ThunkDispatch<IAppState, undefined, IAppAction>
): IWebhookFormIndexFunctionsProps => ({
  createWebhook: (createRequest: WebhookCreateRequest): void =>
    dispatch(createWebhook(createRequest)),
  updateWebhook: (
    updateRequest: WebhookCreateRequest,
    webhookId: string
  ): void => dispatch(updateWebhook(updateRequest, webhookId)),
  validateUrl: (request: PaymentUrl[]): void => dispatch(validateUrl(request)),
  setNotification: (notification: INotification) =>
    dispatch(setNotification(notification)),
  getMerchantSettings: (request: string): void =>
    dispatch(getMerchantSettings(request)),
});
export default connect(mapStateToProps, mapDispatchToProps)(WebhookFormIndex);
