import { get, defaultTo } from "lodash";
import { TransactionTypeEnum } from "./TransactionTypeEnum";
import { translate } from "./LanguageCatatog";
import { BillingTransactionTypeEnum } from "./BillingTransactionTypeEnum";

export const getTransactionTypeObject = (transactionType: string) => {
  const split = defaultTo(transactionType, "").split("_");

  if (
    transactionType === TransactionTypeEnum.MINIMAL_COMISSION ||
    transactionType === BillingTransactionTypeEnum.MANUAL_FROM_FILE
  )
    return {
      merchantType: "",
      transactionTypeNormal: translate(transactionType),
      transactionType: translate(transactionType),
      processorType: "",
    };
  return {
    merchantType: get(split, "[0]", ""),
    transactionTypeNormal: translate(get(split, "[1]", "")),
    transactionType: translate(get(split, "[1]", ""))
      .toUpperCase()
      .replace(/\s/, "-"),
    processorType: translate(get(split, "[2]", "")),
  };
};
