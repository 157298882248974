import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RulesAlarm from "@containers/RulesAlarm/RulesAlarm";
import { PATH_PARAMS, ROUTES } from "@shared/constants/routes";
import AlarmConfig from "@containers/AlarmConfig/AlarmConfig";
import NodeSelection from "@containers/NodeSelection/NodeSelection";
import RuleDetail from "@containers/RuleDetail/RuleDetail";
import Alarms from "@containers/Alarms/Alarms";
import Executives from "@containers/Executives/Executives";

const Navigation = () => {
  return (
    <BrowserRouter basename={ROUTES.SPA_BASENAME}>
      <Routes>
        <Route index element={<RulesAlarm />} />
        <Route path={`${PATH_PARAMS.TYPE}`} element={<RulesAlarm />} />
        <Route path={ROUTES.CONFIG_ALARM} element={<AlarmConfig />} />
        <Route path={ROUTES.CONFIG_ALARM_EDIT} element={<AlarmConfig />} />
        <Route path={`${ROUTES.NODE_SELECTION}`} element={<NodeSelection />} />
        <Route path={ROUTES.RULE_DETAIL} element={<RuleDetail />} />
        <Route path={`${ROUTES.ALARMS}`} element={<Alarms />} />
        <Route path={`${ROUTES.EXECUTIVES}`} element={<Executives />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Navigation;
