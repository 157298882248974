import React from "react";
import {
  createStyles,
  Grid,
  Typography,
  MenuItem,
  Select,
  Box,
} from "@material-ui/core";
import { Pagination as PaginationMui } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

export interface PaginationProps {
  total: number;
  size: number;
  page: number;
  handleChangePage: (page: number) => void;
  handleChangePageSize: (pageSize: number) => void;
  title?: string;
}
const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: "12px",
      backgroundColor: "white",
    },
    paginationText: {
      fontSize: "14px",
      color: "#6D7781",
      fontWeight: "normal",
      lineHeight: "170%",
      fontStyle: "normal",
    },
  })
);

const itemsPerPage: number[] = [10, 15, 20];

const Pagination: React.FC<PaginationProps> = (props: PaginationProps) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} md={7}>
        <Box display="flex" alignItems="center">
          <Box pr={1.5}>
            <Typography className={classes.paginationText}>
              {props.title}
            </Typography>
          </Box>
          <Box pr={3}>
            <Select
              id={"select"}
              disableUnderline
              value={props.size}
              onChange={(event) =>
                props.handleChangePageSize(event.target.value as number)
              }
            >
              {itemsPerPage.map((item, i) => (
                <MenuItem value={item} key={i + item + ""}>
                  <Typography className={classes.paginationText}>
                    {item}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <Typography className={classes.paginationText}>
              {`${(props.page - 1) * props.size + 1}-${
                (props.page - 1) * props.size + props.size
              } de ${props.total}`}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        container
        justify="flex-end"
        alignItems="flex-start"
        direction="row"
      >
        <PaginationMui
          id={"pagination"}
          count={Math.ceil(Number(props.total / props.size))}
          color={"primary"}
          onChange={(_event, value) => props.handleChangePage(value as number)}
          page={props.page}
          size={"small"}
        />
      </Grid>
    </Grid>
  );
};

export default Pagination;
