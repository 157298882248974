import { makeStyles } from "@material-ui/core/styles";

export const fileLoadingModalStyles = makeStyles({
  title: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "150%",
    color: "#293036",
  },
  buttonText: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "140%",
    color: "#293036",
    marginTop: "20px",
    height: "34px",
  },
  fileStatusNormal: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "120%",
    color: "#293036",
    marginTop: "25px",
    marginLeft: "12px",
  },
  description: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "120%",
    color: "#6D7781",
    marginTop: "10px",
  },
  modalBox: {
    alignItems: "center",
    backgroundColor: "white",
    bgcolor: "background.paper",
    boxShadow:
      "0px 7px 14px rgba(60, 66, 87, 0.12), 0px 3px 6px rgba(0, 0, 0, 0.12)",
    display: "flex",
    height: 126,
    left: "50%",
    padding: "24px 32px",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: 462,
  },
  modalText: {
    fontSize: "15px",
    marginTop: "3px",
  },
  modalContent: {
    marginLeft: "32px",
  },
});
