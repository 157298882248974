import { Grid, Typography } from "@mui/material";
import { useDropDownStyles as styles } from "../DropDownItem/DropDownItem.styles";
import {
  DROPDOWN_ACCOUNT_NUMBER_LABEL,
  DROPDOWN_ACCOUNT_ORIGIN,
  DROPDOWN_ACCOUNT_TYPE,
  DROPDOWN_BENEFICIARY_NAME,
  DROPDOWN_CURRENCY_TYPE,
  DROPDOWN_FINANCIAL_INSTITUTION_NATIONAL,
  DROPDOWN_KEY_ACCOUNT_NUMBER,
} from "../../shared/constants/labels/dropdown_container_labels";
import { getCountryFlag } from "../../shared/constants/flags/countryFlag";
import React from "react";
import { IDropDownItem } from "../DropDownItem/DropDownItem.interfaces";
import { getLabelCountryFromValue } from "../../shared/utils/bankAccountUtils";
import { get, isEmpty } from "lodash";

export const SummaryChargeBankAccount = (item: IDropDownItem) => {
  return (
    <>
      <Grid sx={styles.gridFlexIcon}>
        <Grid item xs={5}>
          <Typography sx={styles.detailHeader}>
            {DROPDOWN_ACCOUNT_ORIGIN}
          </Typography>
        </Grid>
        <Grid item xs={7} sx={styles.gridFlex}>
          {getCountryFlag[item.country] && (
            <Grid sx={styles.flagIcon}>{getCountryFlag[item.country]}</Grid>
          )}
          <Typography sx={styles.detailDescription}>
            {getLabelCountryFromValue(item.country)}
          </Typography>
        </Grid>
      </Grid>
      <Grid sx={styles.gridFlexDesc}>
        <Grid item xs={5}>
          <Typography sx={styles.detailHeader}>
            {DROPDOWN_FINANCIAL_INSTITUTION_NATIONAL}
          </Typography>
        </Grid>
        <Grid item xs={7} sx={styles.gridFlex}>
          <Typography sx={styles.detailDescription}>{item.bankName}</Typography>
        </Grid>
      </Grid>
      <Grid sx={styles.gridFlexDesc}>
        <Grid item xs={5}>
          <Typography sx={styles.detailHeader}>
            {DROPDOWN_BENEFICIARY_NAME}
          </Typography>
        </Grid>
        <Grid item xs={7} sx={styles.gridFlex}>
          <Typography sx={styles.detailDescription}>
            {item.nameBeneficiary}
          </Typography>
        </Grid>
      </Grid>
      <Grid sx={styles.gridFlexDesc}>
        <Grid item xs={5}>
          <Typography sx={styles.detailHeader}>
            {DROPDOWN_ACCOUNT_TYPE}
          </Typography>
        </Grid>
        <Grid item xs={7} sx={styles.gridFlex}>
          <Typography sx={styles.detailDescription}>
            {item.accountType}
          </Typography>
        </Grid>
      </Grid>
      <Grid sx={styles.gridFlexDesc}>
        <Grid item xs={5}>
          <Typography sx={styles.detailHeader}>
            {DROPDOWN_ACCOUNT_NUMBER_LABEL}
          </Typography>
        </Grid>
        <Grid item xs={7} sx={styles.gridFlex}>
          <Typography sx={styles.detailDescription}>
            {item.accountNumber}
          </Typography>
        </Grid>
      </Grid>
      {!isEmpty(get(item, "interbankAccountCode", "")) && (
        <Grid sx={styles.gridFlexDesc}>
          <Grid item xs={5}>
            <Typography sx={styles.detailHeader}>
              {DROPDOWN_KEY_ACCOUNT_NUMBER}
            </Typography>
          </Grid>
          <Grid item xs={7} sx={styles.gridFlex}>
            <Typography sx={styles.detailDescription}>
              {item.interbankAccountCode}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid sx={styles.gridFlexDesc}>
        <Grid item xs={5}>
          <Typography sx={styles.detailHeader}>
            {DROPDOWN_CURRENCY_TYPE}
          </Typography>
        </Grid>
        <Grid item xs={7} sx={styles.gridFlex}>
          <Typography sx={styles.detailDescription}>{item.currency}</Typography>
        </Grid>
      </Grid>
    </>
  );
};
