export const ADD_BANK_ACCOUNT_MODAL_LABELS = {
  alternative: {
    titleAdd: "Agregar cuenta bancaria - Alterno",
    titleEdit: "Editar cuenta bancaria - Alterno",
  },
  btnText: {
    addText: "Agregar",
    saveText: "Guardar",
  },
  principal: {
    beneficiaryHelperText:
      "Si deseas cambiar el nombre del beneficiario debes editar la Información principal en Datos básicos.",
    countryHelperText:
      "Esta información queda predeterminada de acuerdo al país de constitución del customer.",
    currencyHelperText:
      "Transferencias Internacionales está solo disponible en: EUR, HKD, SGD, GBP, USD",
    internationalDispersionHelperText:
      "Cuenta configurada para dispersiones internacionales",
    titleAdd: "Agregar cuenta bancaria - Principal",
    titleEdit: "Editar cuenta bancaria - Principal",
    titleInternationalTransfer: "Transferencias Internacionales",
  },
};
