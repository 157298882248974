import { createAsyncThunk } from "@reduxjs/toolkit";
import { thunkPrefix } from "@shared/constants/thunkPrefixes";
import { API_ROUTES } from "@shared/constants/api_routes";
import { WebSocketAction } from "@shared/enum/webSocketActionEnum";
import { IRuleAlarmRequest } from "@containers/AlarmConfig/AlarmConfig.interfaces";

import {
  handleErrorSocket,
  handleMessageSocket,
  handleOpenSocket,
} from "@shared/utils/webSocketUtils";
import { setIsLoadingButton } from "@store/reducers/alarmConfig/alarmConfig.slice";
import { SearchRuleAlarmRequest } from "../../../../types/search_rule_alarm_request";
import { AxiosResponse, CancelTokenSource } from "axios";
import axios from "@shared/utils/axios-util";
import {
  RuleAlarm,
  SearchRuleAlarmResponse,
} from "../../../../types/search_rule_alarm_response";
import { get } from "lodash";

export const generateRuleAlarm = createAsyncThunk(
  thunkPrefix.createRules,
  async (payload: IRuleAlarmRequest, { dispatch }) => {
    const jwt: string | null = localStorage.getItem("jwt");
    let webSocket: WebSocket | undefined;
    const params: object = {
      action: WebSocketAction.GENERATE_ALARM,
      data: payload,
    };

    webSocket = new WebSocket(
      `${API_ROUTES.generateRuleAlarm}?Authorization=${jwt}`
    );

    dispatch(setIsLoadingButton(true));

    webSocket.onopen = handleOpenSocket(webSocket, params);

    webSocket.onerror = handleErrorSocket(webSocket, dispatch);

    webSocket.onmessage = handleMessageSocket(webSocket, dispatch, payload);
  }
);

type GetRuleAlarmRequest = { request: SearchRuleAlarmRequest } & {
  cancelToken?: CancelTokenSource | undefined;
};

export const getRuleAlarms = createAsyncThunk(
  thunkPrefix.getRuleAlarms,
  async ({ request, cancelToken }: GetRuleAlarmRequest) => {
    const response: AxiosResponse<SearchRuleAlarmResponse> = await axios.post(
      API_ROUTES.GET_RULE_ALARMS,
      request,
      {
        cancelToken: get(cancelToken, "token"),
      }
    );

    return response;
  }
);

export const getRuleAlarm = createAsyncThunk(
  thunkPrefix.getRuleAlarm,
  async (request: string) => {
    const response: AxiosResponse<RuleAlarm> = await axios.get(
      `${API_ROUTES.GET_RULE_ALARM}${request}`
    );

    return response.data;
  }
);
