import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const informationFormStyles = createNamedStyles({
  boxContent: {
    display: "flex",
    height: "45%",
    alignItems: "center",
    mt: (theme) => theme.spacing(12),
    maxWidth: (theme) => theme.spacing(60),
    width: "34%",
  },
  title: {
    fontSize: (theme) => theme.spacing(3.25),
    fontWeight: 600,
    mb: 5,
  },
  subtitle: {
    alignSelf: "self-start",
    fontSize: (theme) => theme.spacing(2),
    fontWeight: 500,
    mb: 4,
  },
  information: {
    alignSelf: "self-start",
    color: "#6D7781",
    fontSize: "14px",
    fontWeight: 400,
    ml: 1.25,
  },
  detail: {
    alignSelf: "self-start",
    fontSize: (theme) => theme.spacing(2),
    fontWeight: 500,
  },
  boxUl: {
    color: "#023365",
    fontSize: (theme) => theme.spacing(2),
    fontWeight: 400,
    mb: 4,
  },
  bodyStyle: {
    alignSelf: "self-start",
    fontSize: (theme) => theme.spacing(2),
    fontWeight: 400,
    mb: 4,
    width: "100%",
  },
  containerBox: {
    width: "100%",
  },
  containerCheckBox: {
    alignItems: "center",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    mb: 5,
  },
  containerInformation: {
    alignItems: "center",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    mb: 5,
  },
  containerButton: {
    alignItems: "center",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    mb: 5,
    pl: 11.25,
    pr: 11.25,
    width: "100%",
  },
  checkBox: {
    border: "1.5px",
    color: "#4498EE",
    fontSize: (theme) => theme.spacing(2),
  },
  checkBoxLabel: {
    border: "1.5px",
    color: "#293036",
    fontSize: "14px",
    ml: 1.5,
  },
  nextButton: {
    fontSize: (theme) => theme.spacing(1.75),
    h: (theme) => theme.spacing(4),
  },
  backButton: {
    borderColor: "#4498EE",
    color: "#4498EE",
    fontSize: (theme) => theme.spacing(1.75),
    h: (theme) => theme.spacing(4),
  },
  error: {
    color: "red",
  },
});
