import { CountryEnum } from "../countryEnum";
import { CatalogCo } from "../services/catalog-co";
import { CatalogsEnum } from "../interfaces/catalogs-enum";
import { Category } from "../constants/information_constants";
import { get, isNil } from "lodash";
import { CatalogEc } from "../services/catalog-ec";
import { CatalogEeuu } from "../services/catalog-eeuu";
import { CatalogBr } from "../services/catalog-br";
import { CatalogPan } from "../services/catalog-pan";
import { CatalogPe } from "../services/catalog-pe";
import { CatalogMx } from "../services/catalog-mx";
import { CatalogCl } from "../services/catalog-cl";
import { CatalogHn } from "../services/catalog-hn";
import { CatalogGt } from "../services/catalog-gt";
import { CatalogCr } from "../services/catalog-cr";
import { CatalogEl } from "../services/catalog-el";
import { CatalogNi } from "../services/catalog-ni";

const countryCatalog = {
  [CountryEnum.colombia]: CatalogCo,
  [CountryEnum.ecuador]: CatalogEc,
  [CountryEnum.usa]: CatalogEeuu,
  [CountryEnum.brasil]: CatalogBr,
  [CountryEnum.panama]: CatalogPan,
  [CountryEnum.peru]: CatalogPe,
  [CountryEnum.mexico]: CatalogMx,
  [CountryEnum.chile]: CatalogCl,
  [CountryEnum.honduras]: CatalogHn,
  [CountryEnum.guatemala]: CatalogGt,
  [CountryEnum.costa_rica]: CatalogCr,
  [CountryEnum.el_salvador]: CatalogEl,
  [CountryEnum.nicaragua]: CatalogNi,
};

export const getLocationCatalog = (
  country: string,
  value: string,
  catalogType: CatalogsEnum
) => {
  if (
    !countryCatalog[country] ||
    !catalogType ||
    !value ||
    isNil(countryCatalog[country][catalogType])
  ) {
    return "";
  }

  const locationItem = countryCatalog[country][catalogType].find(
    (item: Category) =>
      item.value === value || item.name.toLowerCase() === value.toLowerCase()
  );

  return get(locationItem, "name", "");
};
