import { navigateToUrl } from "single-spa";
import { ROUTES } from "../../../shared/constants/routes";
import { IUseWebhookConfig } from "./useWebhookConfig.interface";
import { QueryParamsEnum } from "../../../shared/enums/queryParamsEnum";
import { useLocation } from "react-router";

export const useWebhookConfig = (): IUseWebhookConfig => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const publicMerchantId = searchParams.get(QueryParamsEnum.PUBLIC_MERCHANT_ID);
  const handleRedirectUrl = () => {
    navigateToUrl(
      ROUTES.MERCHANT_WEBHOOK_CREATE.replace("{merchantId}", publicMerchantId!)
    );
  };

  return {
    handleRedirectUrl,
  };
};
