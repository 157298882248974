import { CountryEnum } from "../enums/CountryEnum";
import { RouteEnum } from "../infrastructure/routes";
import { get } from "lodash";
import { ICheckoutInitResponse } from "../interfaces/RechargeWalletData.interfaces";
import { NavigateFunction } from "react-router";

export const countriesList: string[] = [CountryEnum.MEXICO, CountryEnum.CHILE];

export const createCallbackUrl = (
  country: CountryEnum,
  merchantInfo: object
) => {
  switch (country) {
    case CountryEnum.CHILE:
      return `${window.location.origin}${RouteEnum.WALLET_DASHBORAD}`;
    default:
    case CountryEnum.MEXICO:
      return get(merchantInfo, "webSite", undefined);
  }
};

export const NavigateToRedirectURL = (
  country: CountryEnum,
  checkoutInitResponse: ICheckoutInitResponse | undefined,
  navigate: NavigateFunction
) => {
  switch (country) {
    case CountryEnum.CHILE:
      return window.location.replace(
        `${get(
          checkoutInitResponse,
          "redirectUrl",
          `${window.location.origin}${RouteEnum.WALLET_DASHBORAD}`
        )}`
      );
    case CountryEnum.MEXICO:
      return navigate(RouteEnum.SUMMARY_CHECKOUT);
  }
};
