import { Grid, Typography, Button } from "@material-ui/core";
import React from "react";
import { useStyles } from "./ExecutorNavbar.styles";

export interface ExecutorNavbarProps {
  disabledButtonsAction: boolean;
  handleApproved: () => void;
  handleDeclined: () => void;
}

export const ExecutorNavbar = (props: ExecutorNavbarProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid container style={{ marginBottom: "24px", marginTop: "24px" }}>
      <Grid item xs={8}>
        <Typography style={{ color: "#64798D", fontSize: "15px" }}>
          Para hacer una selección masiva e ingresar una respuesta primero
          deberás seleccionar solo un filtro de “acción y respuesta”
          (Reembolso-Enviado, Cancelar-Enviado o Devolver-Enviado) y un filtro
          de “Procesador”.
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant="outlined"
          className={classes.executorButton}
          onClick={props.handleApproved}
          disabled={props.disabledButtonsAction}
        >
          Aprobado
        </Button>
        <Button
          variant="outlined"
          className={classes.executorButton}
          disabled={props.disabledButtonsAction}
          onClick={props.handleDeclined}
        >
          Declinado
        </Button>
      </Grid>
    </Grid>
  );
};
