import * as React from "react";
import { SvgIcon as MuiSvgIcon, styled, SvgIconProps } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../Themes/themekushki";

const SvgIcon = styled(MuiSvgIcon, {
  shouldForwardProp: (prop) => prop !== "fill",
})<SvgIconProps>(({ color, fontSize }) => ({
  fill: color + "!important",
  fontSize: fontSize,
}));

SvgIcon.defaultProps = {
  "aria-hidden": "true",
  focusable: "false",
  viewBox: "0 0 24 24",
};

const IconBank: React.FunctionComponent<SvgIconProps> = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <SvgIcon
        {...props}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M22 22H2C1.73478 22 1.48043 21.8946 1.29289 21.7071C1.10536 21.5196 1 21.2652 1 21C1 20.7348 1.10536 20.4804 1.29289 20.2929C1.48043 20.1054 1.73478 20 2 20H22C22.2652 20 22.5196 20.1054 22.7071 20.2929C22.8946 20.4804 23 20.7348 23 21C23 21.2652 22.8946 21.5196 22.7071 21.7071C22.5196 21.8946 22.2652 22 22 22Z" />
        <path d="M20 22C19.7348 22 19.4804 21.8947 19.2929 21.7071C19.1054 21.5196 19 21.2652 19 21V19H5V21C5 21.2652 4.89464 21.5196 4.70711 21.7071C4.51957 21.8947 4.26522 22 4 22C3.73478 22 3.48043 21.8947 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V18C3 17.7348 3.10536 17.4805 3.29289 17.2929C3.48043 17.1054 3.73478 17 4 17H20C20.2652 17 20.5196 17.1054 20.7071 17.2929C20.8946 17.4805 21 17.7348 21 18V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8947 20.2652 22 20 22Z" />
        <path d="M7 16C6.73478 16 6.48043 15.8946 6.29289 15.7071C6.10536 15.5196 6 15.2652 6 15V9C6 8.73478 6.10536 8.48043 6.29289 8.29289C6.48043 8.10536 6.73478 8 7 8C7.26522 8 7.51957 8.10536 7.70711 8.29289C7.89464 8.48043 8 8.73478 8 9V15C8 15.2652 7.89464 15.5196 7.70711 15.7071C7.51957 15.8946 7.26522 16 7 16Z" />
        <path d="M12 16C11.7348 16 11.4804 15.8946 11.2929 15.7071C11.1054 15.5196 11 15.2652 11 15V7C11 6.73478 11.1054 6.48043 11.2929 6.29289C11.4804 6.10536 11.7348 6 12 6C12.2652 6 12.5196 6.10536 12.7071 6.29289C12.8946 6.48043 13 6.73478 13 7V15C13 15.2652 12.8946 15.5196 12.7071 15.7071C12.5196 15.8946 12.2652 16 12 16Z" />
        <path d="M17 16C16.7348 16 16.4804 15.8946 16.2929 15.7071C16.1054 15.5196 16 15.2652 16 15V9C16 8.73478 16.1054 8.48043 16.2929 8.29289C16.4804 8.10536 16.7348 8 17 8C17.2652 8 17.5196 8.10536 17.7071 8.29289C17.8946 8.48043 18 8.73478 18 9V15C18 15.2652 17.8946 15.5196 17.7071 15.7071C17.5196 15.8946 17.2652 16 17 16Z" />
        <path d="M2.50028 9.00001C2.31561 8.99945 2.13471 8.94777 1.97761 8.85069C1.82052 8.75361 1.69338 8.61492 1.61028 8.45001C1.49167 8.21384 1.47137 7.94032 1.55382 7.68923C1.63626 7.43814 1.81475 7.2299 2.05028 7.11001L12.0503 2.11001C12.195 2.03297 12.3564 1.99268 12.5203 1.99268C12.6842 1.99268 12.8456 2.03297 12.9903 2.11001L21.9903 7.11001C22.1045 7.17567 22.2047 7.26319 22.2852 7.36758C22.3656 7.47196 22.4247 7.59116 22.459 7.71838C22.4934 7.8456 22.5023 7.97833 22.4854 8.10902C22.4685 8.2397 22.4259 8.36576 22.3603 8.48001C22.2946 8.59426 22.2071 8.69446 22.1027 8.77489C21.9983 8.85531 21.8791 8.91439 21.7519 8.94875C21.6247 8.9831 21.492 8.99207 21.3613 8.97513C21.2306 8.95819 21.1045 8.91567 20.9903 8.85001L12.4803 4.13001L3.00028 8.89001C2.84719 8.97273 2.67396 9.01084 2.50028 9.00001Z" />
      </SvgIcon>
    </ThemeProvider>
  );
};

export default IconBank;
