import React from "react";

export const DisableStickyFilled: React.FC = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.1 33H9.9C8.07001 33 6.31496 32.273 5.02096 30.979C3.72696 29.685 3 27.93 3 26.1V9.9C3 8.07001 3.72696 6.31496 5.02096 5.02096C6.31496 3.72696 8.07001 3 9.9 3H26.1C27.0061 3 27.9034 3.17847 28.7405 3.52523C29.5777 3.87199 30.3383 4.38024 30.979 5.02096C31.6198 5.66169 32.128 6.42234 32.4748 7.25949C32.8215 8.09663 33 8.99388 33 9.9V26.1C33 27.0061 32.8215 27.9034 32.4748 28.7405C32.128 29.5777 31.6198 30.3383 30.979 30.979C30.3383 31.6198 29.5777 32.128 28.7405 32.4748C27.9034 32.8215 27.0061 33 26.1 33ZM9.9 6C8.86687 6.00395 7.87719 6.41611 7.14665 7.14665C6.41611 7.87719 6.00395 8.86687 6 9.9V26.1C6.00395 27.1331 6.41611 28.1228 7.14665 28.8534C7.87719 29.5839 8.86687 29.9961 9.9 30H26.1C27.1331 29.9961 28.1228 29.5839 28.8534 28.8534C29.5839 28.1228 29.996 27.1331 30 26.1V9.9C29.996 8.86687 29.5839 7.87719 28.8534 7.14665C28.1228 6.41611 27.1331 6.00395 26.1 6H9.9Z"
        fill="#E2E8F0"
      />
      <path
        d="M21.7039 33.0003C21.3061 33.0003 20.9246 32.8422 20.6433 32.5609C20.362 32.2796 20.2039 31.8981 20.2039 31.5003V26.5203C20.1925 25.9144 20.3035 25.3125 20.5303 24.7506C20.7571 24.1887 21.0951 23.6784 21.5239 23.2503C21.9447 22.8169 22.4485 22.4728 23.0051 22.2383C23.5618 22.0037 24.1599 21.8837 24.7639 21.8853H31.4989C31.8968 21.8853 32.2783 22.0433 32.5596 22.3246C32.8409 22.6059 32.9989 22.9874 32.9989 23.3853C32.9989 23.7831 32.8409 24.1646 32.5596 24.4459C32.2783 24.7272 31.8968 24.8853 31.4989 24.8853H24.7039C24.4968 24.8819 24.2913 24.9215 24.1003 25.0016C23.9092 25.0816 23.7368 25.2003 23.5939 25.3503C23.4448 25.4962 23.3278 25.6716 23.2503 25.8653C23.1728 26.059 23.1366 26.2668 23.1439 26.4753V31.4703C23.1439 31.8681 22.9859 32.2496 22.7046 32.5309C22.4233 32.8122 22.0418 32.9703 21.6439 32.9703L21.7039 33.0003Z"
        fill="#E2E8F0"
      />
      <path
        d="M24.3012 12.2998H11.7012C11.3033 12.2998 10.9218 12.1418 10.6405 11.8605C10.3592 11.5792 10.2012 11.1976 10.2012 10.7998C10.2012 10.402 10.3592 10.0204 10.6405 9.73914C10.9218 9.45784 11.3033 9.2998 11.7012 9.2998H24.3012C24.699 9.2998 25.0805 9.45784 25.3618 9.73914C25.6431 10.0204 25.8012 10.402 25.8012 10.7998C25.8012 11.1976 25.6431 11.5792 25.3618 11.8605C25.0805 12.1418 24.699 12.2998 24.3012 12.2998Z"
        fill="#E2E8F0"
      />
      <path
        d="M24.3012 19.5H11.7012C11.3033 19.5 10.9218 19.342 10.6405 19.0607C10.3592 18.7794 10.2012 18.3978 10.2012 18C10.2012 17.6022 10.3592 17.2206 10.6405 16.9393C10.9218 16.658 11.3033 16.5 11.7012 16.5H24.3012C24.699 16.5 25.0805 16.658 25.3618 16.9393C25.6431 17.2206 25.8012 17.6022 25.8012 18C25.8012 18.3978 25.6431 18.7794 25.3618 19.0607C25.0805 19.342 24.699 19.5 24.3012 19.5Z"
        fill="#E2E8F0"
      />
    </svg>
  );
};
