export enum BasicInformationLabels {
  FULLNAMES = "Nombre y apellido",
  EMAIL = "Correo electrónico",
  SUBTOTAL_IVA = "Subtotal IVA",
  SUBTOTAL_IVA_0 = "Subtotal IVA 0",
  DESCRIPTION = "Descripción (opcional)",
}

export enum BasicInformationTitles {
  COMPLETE_INFO_CLIENT = "Completa la información del cliente",
  SUBTOTALS = "Ingresa al menos un campo de subtotal para generar el QR de pago.",
  IVA = "IVA ",
  TOTAL = "Total",
}

export enum BasicInformationErrors {
  FIELD_REQUIRED = "Este campo es obligatorio.",
  EMIAL_VALIDATION = "El formato de correo es incorrecto.",
  ALPHANUMERIC_VALIDATION = "Solo ingrese letras o números.",
  NUMBER_VALIDATION = "Ingrese una cantidad válida",
  NEGATIVE_NUMBER_VALIDATION = "Ingrese una cantidad positiva",
  MAX_LENGTH_500_VALIDATION = "No puede ingresar más de 500 caracteres",
}
