export enum NodeInfoPath {
  CentralizedNodesId = "centralizedNodesId",
  Configs = "configs",
  InfoConstitutionalCountry = "generalInfo.constitutionalCountry",
  InfoCountry = "generalInfo.country",
  InfoName = "generalInfo.name",
  InfoPublicMerchantID = "generalInfo.publicMerchantId",
  MerchantID = "merchantId",
  NodeID = "nodeId",
  Value = "value",
  Status = "status",
  UpdatedBy = "updatedBy",
  UpdatedAt = "updatedAt",
}
