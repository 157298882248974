import {
  IConfigCells,
  TableBodyCellEnum,
} from "../../components/Table/TableNodes/constants";
import { HeadCell } from "../../components/Table/TableHead/TableHead.interfaces";

export const headCellsBranchesOwner: HeadCell[] = [
  {
    align: "left",
    disablePadding: false,
    id: "line0",
    label: "País de operación",
  },
  {
    disablePadding: false,
    id: "line1",
    label: "Nombre",
  },
  {
    disablePadding: false,
    id: "title",
    label: "Branch ID",
  },
  {
    disablePadding: false,
    id: "country",
    label: "País de Constitución",
  },
  {
    disablePadding: false,
    id: "line123121231",
    label: "Modificado",
  },
  {
    disablePadding: false,
    id: "text",
    label: "Estado",
  },
  {
    disablePadding: false,
    id: "options",
    label: "Acción",
  },
];

export const CatalogConfigTableBranchesOwner: IConfigCells[] = [
  {
    align: "center",
    configMain: {
      defaultValue: "Colombia",
      path: "country_code",
      text: "$var",
    },
    isCodeCountry: true,
    type: TableBodyCellEnum.FLAG,
  },
  {
    align: "left",
    configMain: {
      defaultValue: "Name Default",
      hasUrl: true,
      path: "name",
      text: "$var",
    },
    configSecondary: {
      defaultValue: "created default",
      isDate: true,
      path: "created",
      text: "Creado: $var",
    },
    type: TableBodyCellEnum.TITLE,
  },
  {
    align: "left",
    configMain: {
      defaultValue: "MerchantId Default",
      path: "merchant_id",
      text: "$var",
    },
    configSecondary: {
      defaultValue: "",
      isDate: false,
      path: "balance_amount_with_currency",
      text: "$var",
    },
    type: TableBodyCellEnum.TEXT_COPY,
  },
  {
    align: "center",
    configMain: {
      defaultValue: "Colombia",
      path: "constitutional_country",
      text: "$var",
    },
    isCodeCountry: false,
    type: TableBodyCellEnum.FLAG,
  },
  {
    align: "center",
    configMain: {
      defaultValue: "updated default",
      isDate: true,
      path: "updated_at",
      text: "$var",
    },
    configSecondary: {
      defaultValue: "created by default",
      path: "created_by",
      text: "$var",
    },
    type: TableBodyCellEnum.TITLE,
  },
  {
    align: "center",
    configMain: {
      defaultValue: "inactive",
      path: "merchant_status",
      text: "$var",
    },
    configSecondary: {
      defaultValue: "",
      path: "pending_section",
      text: "$var",
    },
    type: TableBodyCellEnum.TAG,
  },
  {
    align: "center",
    configMain: {
      defaultValue: "inactive",
      path: "merchant_status",
      text: "$var",
    },
    type: TableBodyCellEnum.ACTION_MENU,
  },
];
