import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { KshTab } from "../../Common/KshTab/KshTab";
import { ReferenceProps, References } from "./References/References";
import { Parameters, ParametersProps } from "./Parameters/Parameters";
import { TransactionTable } from "../../../../types/transaction_table";
import { defaultTo, get, isEmpty, pickBy } from "lodash";
import {
  PaymentMethodCodeEnum,
  PaymentMethodEnum,
} from "../../../shared/infrastructure/PaymentMethodEnum";
import { makeStyles } from "@material-ui/core/styles";
import { getTime, set as setDateKsh } from "date-fns";

const useStyles = makeStyles(() => ({
  withoutScroll: {
    "& .MuiDialog-paper, & .MuiDialogContent-root": {
      overflowY: "initial",
    },
  },
}));

export interface IAdvanceSettingsDialog {
  open: boolean;
  trxBilling: TransactionTable;

  onOpenAdvanceSettings(open: boolean): void;

  onOk(trx: TransactionTable): void;

  country: string;
}

export const AdvanceSettingsDialog = (props: IAdvanceSettingsDialog) => {
  const classes = useStyles();

  const [referenceData, setReferenceData] = useState<ReferenceProps>({
    optionReference: "NA",
  });
  const [errors, setErrors] = useState<{ [k: string]: boolean }>();
  const [parametersData, setParametersData] = useState<ParametersProps>({});
  const handleOk = () => {
    const advanceSettingsParameters: ParametersProps = pickBy({
      paymentDate:
        parametersData.paymentDate &&
        typeof parametersData.paymentDate === "boolean"
          ? getTime(
              setDateKsh(get(parametersData, "paymentDate_value", new Date()), {
                hours: 0,
                milliseconds: 0,
                minutes: 0,
                seconds: 0,
              })
            )
          : undefined,
      expirationDate:
        parametersData.expirationDate &&
        typeof parametersData.expirationDate === "boolean"
          ? getTime(
              setDateKsh(
                get(parametersData, "expirationDate_value", new Date()),
                {
                  hours: 0,
                  milliseconds: 0,
                  minutes: 0,
                  seconds: 0,
                }
              )
            )
          : undefined,
      paymentMethod: parametersData.paymentMethod
        ? get(parametersData, "paymentMethod_value", PaymentMethodEnum.CASH)
        : undefined,
      paymentMethodCode: parametersData.paymentMethod
        ? PaymentMethodCodeEnum[
            get(parametersData, "paymentMethod_value", PaymentMethodEnum.CASH)
          ]
        : undefined,
    });

    let advanceSettingsReference: ReferenceProps = { codeReference: undefined };

    if (defaultTo(referenceData.optionReference, "NA") !== "NA")
      advanceSettingsReference = pickBy({
        optionReference: defaultTo(referenceData.optionReference, undefined),
        codeReference:
          referenceData.optionReference === "Comment"
            ? undefined
            : defaultTo(referenceData.codeReference, undefined),
        comments: defaultTo(referenceData.comments, undefined),
      });

    if (
      ["HES", "OC"].includes(get(referenceData, "optionReference", "NA")) &&
      !advanceSettingsReference.codeReference
    ) {
      setErrors({ ...errors, codeReference: true });
      return;
    } else setErrors({});

    if (!isEmpty(advanceSettingsParameters))
      props.trxBilling.advanceSettingsParameters = advanceSettingsParameters;
    else delete props.trxBilling.advanceSettingsParameters;
    if (get(referenceData, "optionReference", "NA") !== "NA")
      props.trxBilling.advanceSettingsReference = advanceSettingsReference;
    else delete props.trxBilling.advanceSettingsReference;
    props.onOk(props.trxBilling);
    props.onOpenAdvanceSettings(false);
  };

  const handleReferenceData = (value: {
    prop: string;
    value?: string | number;
  }) => {
    setReferenceData({ ...referenceData, [value.prop]: value.value });
  };

  const handleParametersData = (value: {
    prop: string;
    value?: string | number | Date | null | boolean;
  }) => {
    setParametersData({ ...parametersData, [value.prop]: value.value });
  };

  useEffect(() => {
    const referencesKey: string =
      "trxBilling.transaction.modify.advanceSettingsReference";
    const referencesLocalKey = "trxBilling.advanceSettingsReference";
    const parametersKey: string =
      "trxBilling.transaction.modify.advanceSettingsParameters";
    const parametersLocalKey = "trxBilling.advanceSettingsParameters";

    setReferenceData(
      get(
        props,
        referencesKey,
        get(props, referencesLocalKey, {
          optionReference: "NA",
          codeReference: "",
          comments: "",
        })
      )
    );
    let parameters: ParametersProps = {};

    if (get(props, parametersKey, get(props, parametersLocalKey)))
      parameters = {
        paymentDate_value: get(
          props,
          `${parametersKey}.paymentDate`,
          get(props, `${parametersLocalKey}.paymentDate`, new Date())
        ),
        expirationDate_value: get(
          props,
          `${parametersKey}.expirationDate`,
          get(props, `${parametersLocalKey}.expirationDate`, new Date())
        ),
        paymentMethod_value: get(
          props,
          `${parametersKey}.paymentMethod`,
          get(
            props,
            `${parametersLocalKey}.paymentMethod`,
            PaymentMethodEnum.CASH
          )
        ),
      };
    parameters.paymentDate = !!get(
      props,
      `${parametersKey}.paymentDate`,
      get(props, `${parametersLocalKey}.paymentDate`)
    );
    parameters.expirationDate = !!get(
      props,
      `${parametersKey}.expirationDate`,
      get(props, `${parametersLocalKey}.expirationDate`)
    );
    parameters.paymentMethod = !!get(
      props,
      `${parametersKey}.paymentMethod`,
      get(props, `${parametersLocalKey}.paymentMethod`)
    );
    setParametersData(parameters);
  }, [props.trxBilling]);

  return (
    <Dialog
      open={props.open}
      onClose={() => props.onOpenAdvanceSettings(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.withoutScroll}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h5">Configuración Avanzada</Typography>
      </DialogTitle>
      <DialogContent>
        <KshTab
          tabs={[
            {
              title: "Referencias",
              content: (
                <References
                  handleReferenceData={handleReferenceData}
                  optionReference={referenceData.optionReference}
                  codeReference={referenceData.codeReference}
                  comments={referenceData.comments}
                  errors={errors}
                  country={props.country}
                />
              ),
            },
            {
              title: "Parámetros",
              content: (
                <Parameters
                  country={props.country}
                  handleParametersData={handleParametersData}
                  expirationDate={parametersData.expirationDate}
                  paymentDate={parametersData.paymentDate}
                  paymentMethod={parametersData.paymentMethod}
                  expirationDate_value={parametersData.expirationDate_value}
                  paymentDate_value={parametersData.paymentDate_value}
                  paymentMethod_value={parametersData.paymentMethod_value}
                />
              ),
            },
          ]}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => props.onOpenAdvanceSettings(false)}
          color="primary"
        >
          <Typography variant="caption" color="inherit">
            Cancelar
          </Typography>
        </Button>
        <Button
          id="button_ok"
          onClick={handleOk}
          color="primary"
          variant="contained"
          autoFocus
        >
          <Typography variant="caption">Guardar</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
