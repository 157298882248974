import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

export const ContactContainerSkeletonStyles = createNamedStyles({
  containerForm: {
    margin: "3em auto",
    padding: "30px",
    width: "75%",
  },
  containerSelects: {
    paddingBottom: 2,
    width: "100%",
  },
  select: {
    width: "60%",
  },
  skeletonTxt: {
    color: "#6D7781",
    fontSize: "16px",
    lineHeight: "24px",
    paddingBottom: 4,
    width: "30%",
  },
});
