import React, { FC } from "react";
import { Box } from "@mui/material";
import ListBranchCreationForm from "../../components/ListBranchCreationForm/ListBranchCreationForm";
import { listBranchCreationContainerStyles as styles } from "./ListBranchCreationContainer.styles";

const ListBranchCreationContainer: FC = () => {
  return (
    <>
      <Box sx={styles.boxContainer}>
        <ListBranchCreationForm />
      </Box>
    </>
  );
};

export default ListBranchCreationContainer;
