import { Dispatch } from "redux";
import axios from "../../../shared/axios-util";
import { API_ENDPOINTS } from "../../../shared/constants/api_endpoints";
import { MessageAlertEnum } from "../../../shared/enums/message_alert_enum";
import {
  setMessageAlert,
  setTypeAlert,
  showAlert,
} from "../../actions/app_actions";

export const resendRefundEpic = async (data: Object, dispatch: Dispatch) => {
  try {
    //@ts-ignore
    const response = await axios.post(API_ENDPOINTS.RESEND_REFUND, data);

    if (response!.data!.forwardRefundResult) {
      dispatch(setTypeAlert("success"));
      dispatch(setMessageAlert(MessageAlertEnum.RESEND_REFUND));
      dispatch(showAlert());

      return true;
    } else {
      dispatch(setTypeAlert("error"));
      dispatch(setMessageAlert(MessageAlertEnum.ERROR));
      dispatch(showAlert());

      return false;
    }
  } catch (e) {
    dispatch(setTypeAlert("error"));
    dispatch(setMessageAlert(MessageAlertEnum.ERROR));
    dispatch(showAlert());

    return false;
  }
};
