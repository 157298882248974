import React from "react";
import { InvoiceRecord } from "../../../types/remote/response_search_blling_by_filters";
import { ModalBody } from "@kushki/frontend-molecules/modal-body";
import { get, isEmpty } from "lodash";
import ModalBodyInfo from "./ModalBodyInfo";
import { KshTab } from "../Common/KshTab/KshTab";
import { EmptyStateTab } from "../Common/EmptyStateTab/EmptyStateTab";
import { AdvanceConfigTabDetail } from "../ModalTabs/AdvanceConfigTabDetail/AdvanceConfigTabDetail";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import {
  ITabInfo,
  tabEnum,
} from "../../shared/infrastructure/CatalogRenderTab";
import { DynamoReferenceEnum } from "../../shared/infrastructure/constants/DynamoElasticMap";
import { ModalBodyObservations } from "../ModalTabs/ModalBodyObservations";
import { TimeLineTabHistoricComponent } from "../TabComponent/TimeLineTabHistoricComponent/TimeLineTabHistoricComponent";
import { getCatalogInfo, getEmptyTitleByKind } from "./utilsModalBody";
import { TransactionTable } from "../../../types/transaction_table";
import { ITextArea } from "../../containers/BillingDashboard/state/useBillingDashboardState";

import {
  StatusEnum,
  StatusFlowEnum,
} from "../../shared/infrastructure/StatusEnum";
import { KindRetentionEnum } from "../../shared/infrastructure/KindRetentionEnum";
export interface InfoTrxProps {
  trxInfo: InvoiceRecord | TransactionTable | undefined;
  country: string;
  isMinAmount: boolean;
  handleChangeTab?: (newValue: number) => void;
  handleOpenDialogDetail?: (data: InvoiceRecord) => void;
  handleCloseDialogDetail?: () => void;
  valueTab?: number;
  isHistoric: boolean;
  textArea?: ITextArea;
  statusFlow?: StatusFlowEnum;
  tabDefault?: tabEnum;
  pdfViewer?: {
    fileName: string;
    openModal: boolean;
    base64File: string;
    handleCloseModal: () => void;
    handleOpenModal: () => void;
    handlePdfViewer: (
      transactionId: string,
      kindRentenion: KindRetentionEnum
    ) => void;
  };
  downloadFileState?: {
    isLoading: boolean;
    downloadFile: (format: string) => void;
  };
}

const getTabsRender = (
  props: InfoTrxProps,
  catalog: ITabInfo[]
): { title: string; content: JSX.Element }[] => {
  const tabsRender: { title: string; content: JSX.Element }[] = [];

  catalog.map((tabInfo: ITabInfo) => {
    switch (tabInfo.tab) {
      case tabEnum.information:
        tabsRender.push({
          title: tabEnum.information,
          content: <ModalBodyInfo {...props} />,
        });
        break;
      case tabEnum.advancedConfig:
        tabsRender.push({
          title: tabEnum.advancedConfig,
          content:
            !isEmpty(get(props.trxInfo, "advance_settings_reference")) ||
            !isEmpty(get(props.trxInfo, "advance_settings_parameters")) ? (
              <AdvanceConfigTabDetail
                InfoTrxProps={props}
                country={props.country as CountryEnum}
                isHistoric={props.isHistoric}
              />
            ) : (
              <EmptyStateTab
                title="Esta factura no tiene Configuración Avanzada"
                subtitle='Para agregarlas, haz click en "Configuración Avanzada"'
              />
            ),
        });
        break;
      case tabEnum.observation:
        const transactionType = get(props.trxInfo, "kind", "");
        tabsRender.push({
          title: tabEnum.observation,
          content:
            get(props.trxInfo, "status") === StatusEnum.REJECTED ? (
              <ModalBodyObservations
                InfoTrxProps={props}
                country={props.country as CountryEnum}
              />
            ) : (
              <EmptyStateTab
                title={getEmptyTitleByKind(transactionType, true)}
                isProcessData={true}
              />
            ),
        });
        break;
      case tabEnum.timeLine:
        tabsRender.push({
          title: tabEnum.timeLine,
          content:
            get(props.trxInfo, DynamoReferenceEnum.OLD_TRANSACTIONS, [])
              .length !== 0 ? (
              <TimeLineTabHistoricComponent {...props} />
            ) : (
              <EmptyStateTab title="Esta factura no tiene Línea de Tiempo" />
            ),
        });
        break;
    }
  });
  return tabsRender;
};

export const ModalBodyTabs: React.FC<InfoTrxProps> = (props: InfoTrxProps) => {
  let catalogTabs: ITabInfo[] = getCatalogInfo(props);
  const country: string = props.country;

  if (!isEmpty(props.tabDefault))
    catalogTabs = catalogTabs.filter((item) => item.tab === props.tabDefault);

  return (
    <ModalBody>
      <React.Fragment>
        {(!isEmpty(catalogTabs) &&
          ![CountryEnum.colombia, CountryEnum.peru].includes(
            country as CountryEnum
          )) ||
        !isEmpty(props.tabDefault) ? (
          <KshTab tabs={getTabsRender(props, catalogTabs)} />
        ) : (
          <ModalBodyInfo {...props} />
        )}
      </React.Fragment>
    </ModalBody>
  );
};
