import {
  defaultTo,
  each,
  union,
  find,
  get,
  set,
  flatten,
  sortBy,
  uniqWith,
  uniqBy,
  pick,
} from "lodash";
import axios from "../shared/axios-util";
import { IMerchantForm } from "../infrastructure/interfaces/IMerchantForm";
import { AxiosResponse } from "axios";
import { IRoleInfo } from "../infrastructure/interfaces/IRoleInfo";
import { routes } from "../infrastructure/constants/Routes";
import { IPayload } from "../infrastructure/interfaces/IPayload";
import { IKushkiMultiRol } from "../infrastructure/interfaces/IKushkiMultiRol";
import { IKushkiMenu } from "../infrastructure/interfaces/IKushkiMenu";
import { IKushkiRol } from "../infrastructure/interfaces/IKushkiRol";
import { ROLES, SYSTEM_ROLES } from "../infrastructure/constants/Roles";
import { isAfter, differenceInMilliseconds, getUnixTime } from "date-fns";
import { environment } from "../environments/environment";
import { LOCALSTORAGE } from "../infrastructure/constants/LocalStorage_Enum";
import { SessionService } from "./SessionService";
import { ISessionSupport } from "../infrastructure/interfaces/ISessionSupport";
import * as moment from "moment-timezone";
import { TIMEZONE_COUNTRY } from "../infrastructure/constants/TimeZoneCountry";
import {
  CountriesEnum,
  SpanishCountriesEnum,
} from "../infrastructure/constants/Countries";
import { TokenService } from "./TokenService";
import { TypeIssuerEnum } from "../infrastructure/constants/TypeIssuerEnum";

export class RoleService {
  public static async getRoleInfo(): Promise<IRoleInfo> {
    return await RoleService._initAuth();
  }

  public static validateRolUser(rolUser: string): boolean {
    const payload = RoleService._getPayload();
    return get(payload, "[cognito:groups]", []).includes(rolUser);
  }

  public static validateCountry(country: string): boolean {
    const merchantBasicInformation: IMerchantForm = RoleService._getMerchantBasicInfo();
    return get(merchantBasicInformation, "country", "") === country;
  }

  static hasPayload(): boolean {
    return localStorage.getItem(LOCALSTORAGE.PAYLOAD) !== null;
  }

  static hasMerchantBasicInfo(): boolean {
    return (
      localStorage.getItem(LOCALSTORAGE.MERCHANT_BASIC_INFORMATION) !== null
    );
  }

  static logout(): void {
    const local_cache: string = defaultTo(
      localStorage.getItem(LOCALSTORAGE.CACHE),
      "{}"
    );
    localStorage.clear();
    localStorage.setItem(LOCALSTORAGE.CACHE, local_cache);

    window.location.href = routes.AUTH;
  }

  static restoreAuthPayload(): void {
    localStorage.setItem(
      "payload",
      JSON.stringify(RoleService._getOriginalAuthPayload())
    );
    localStorage.removeItem("authPayload");
    localStorage.removeItem("supportSessionTimeConnectStart");
  }

  static _getMerchantId(): string {
    return get(RoleService._getPayload(), "preferred_username", "");
  }

  static async getMerchant(): Promise<IMerchantForm> {
    if (localStorage.getItem(LOCALSTORAGE.MERCHANT) !== null)
      return JSON.parse(
        defaultTo(localStorage.getItem(LOCALSTORAGE.MERCHANT), "{}")
      );

    const response: AxiosResponse<IMerchantForm> = await axios.get<
      IMerchantForm
    >(`${environment.kushkiUrl}/merchant/v1/merchant`);

    localStorage.setItem(LOCALSTORAGE.MERCHANT, JSON.stringify(response.data));

    return response.data;
  }

  static async getMerchantInfo(envName: string): Promise<IMerchantForm> {
    if (localStorage.getItem(LOCALSTORAGE.MERCHANT_BASIC_INFORMATION) !== null)
      return JSON.parse(
        defaultTo(
          localStorage.getItem(LOCALSTORAGE.MERCHANT_BASIC_INFORMATION),
          "{}"
        )
      );

    const url: string =
      envName === "primary"
        ? "https://api.kushkipagos.com"
        : environment.kushkiUrl;
    const response: AxiosResponse<IMerchantForm> = await axios.post<
      IMerchantForm
    >(`${url}/billing-core/v1/merchant/merchantInfo`, {
      publicMerchantId: RoleService._getMerchantId(),
    });

    localStorage.setItem(
      LOCALSTORAGE.MERCHANT_BASIC_INFORMATION,
      JSON.stringify(response.data)
    );
    localStorage.setItem(
      LOCALSTORAGE.BASIC_MERCHANT_INFORMATION,
      JSON.stringify(pick(response.data, ["country", "publicMerchantId"]))
    );

    return response.data;
  }

  static isJWTExpired(): boolean {
    const payload = RoleService._getPayload();
    if (!payload.exp) return true;

    const now_date = new Date();
    const expire = payload.exp * 1000;

    return isAfter(now_date, expire);
  }

  static getJWTLeftTime(): number {
    const now_date = new Date();
    const expire = RoleService._getPayload().exp! * 1000;

    return differenceInMilliseconds(expire, now_date);
  }

  static setTimeZone(country: string): void {
    if (!RoleService._hasTimeZone()) RoleService._setSystemTimeZone(country);
  }

  private static async _initAuth(): Promise<IRoleInfo> {
    const payload = RoleService._getPayload();

    RoleService._setRolObject(payload);
    RoleService._setMerchantId();
    RoleService._setUserInfo(payload);
    RoleService._setEnv();
    if (RoleService._isAdmin(payload)) RoleService._initSessionSupport();

    return {
      firstName: RoleService._getFirstName(payload),
      lastName: RoleService._getLastName(payload),
      fullName: RoleService._getFullUserName(payload),
      userName: RoleService._getUserName(payload),
      userInitials: RoleService._getUserInitials(payload),
      kushkiMenu: await RoleService._getMenus(payload),
      isAdmin: RoleService._isAdmin(payload),
      supportSessionTimeConnect: RoleService._getSuppportSessionTimeConnect(
        payload
      ),
      supportSessionAccessCode: RoleService._getSupportSessionAccessCode(
        payload
      ),
      supportSessionTimeConnectStart: RoleService._getSupportSessionTimeConnectStart(
        payload
      ),
      supportSessionActive: RoleService._isSupportSessionActive(payload),
      isCompliance: RoleService._isComplianceUser(payload),
    };
  }

  public static isComplianceRoleUnique(): boolean {
    const groups: string[] = get(
      RoleService._getPayload(),
      "[cognito:groups]",
      [""]
    );
    return groups.length === 1 && groups.includes("BackofficeCompliance");
  }

  public static isMasterCredentials() {
    const groups: string[] = get(
      RoleService._getPayload(),
      "[cognito:groups]",
      [""]
    );
    return groups.includes("BackofficeMasterCredentials");
  }

  public static isMaster() {
    const groups: string[] = get(
      RoleService._getPayload(),
      "[cognito:groups]",
      [""]
    );
    return groups.includes("BackofficeUserMaster");
  }

  public static isChargebackManagerUser() {
    const groups: string[] = get(
      RoleService._getPayload(),
      "[cognito:groups]",
      [""]
    );
    return groups.includes("ChargebackManager");
  }

  private static _isComplianceUser(payload: IPayload) {
    return get(payload, "[cognito:groups]", []).includes(
      "BackofficeCompliance"
    );
  }

  private static _initSessionSupport() {
    const now = new Date();
    const sessionSupport: ISessionSupport = defaultTo(
      SessionService.getSessionSupport(),
      {}
    );
    const authPayload: IPayload = JSON.parse(
      defaultTo(localStorage.getItem("payload"), "")
    );

    const expirationDate = new Date(
      Number(defaultTo(sessionSupport.expired, now))
    );

    if (
      now >= expirationDate ||
      localStorage.getItem("username") !== sessionSupport.userSupport
    )
      return;

    const merchantPayload: IPayload = JSON.parse(
      get(sessionSupport, "merchantPayload", "{}")
    );
    merchantPayload.exp = authPayload.exp;

    localStorage.removeItem("merchantId");
    localStorage.removeItem("roles");
    localStorage.setItem("authPayload", JSON.stringify(authPayload));
    localStorage.setItem("payload", JSON.stringify(merchantPayload));
    localStorage.setItem(
      "supportSessionTimeConnectStart",
      defaultTo(sessionSupport.supportSessionTimeConnectStart, "")
    );
    window.location.reload();
  }

  private static _getSupportSessionTimeConnectStart(
    payload: IPayload
  ): string | undefined {
    if (!RoleService._isSupportSessionActive(payload)) return;

    if (localStorage.getItem("supportSessionTimeConnectStart") !== null)
      return JSON.parse(
        defaultTo(localStorage.getItem("supportSessionTimeConnectStart"), "{}")
      );

    const currentUnixTime = getUnixTime(new Date());
    localStorage.setItem(
      "supportSessionTimeConnectStart",
      String(currentUnixTime)
    );

    return String(currentUnixTime);
  }

  private static _isSupportSessionActive(payload: object): boolean {
    return `${get(payload, "accessCode")}` !== "undefined";
  }

  private static _getSupportSessionAccessCode(payload: object): string {
    return `${get(payload, "accessCode")}`;
  }

  private static _getSuppportSessionTimeConnect(payload: object): string {
    return `${get(payload, "timeConnect")}`;
  }

  private static _setRolObject(payload: IPayload): void {
    //if (localStorage.getItem("roles") !== null) return;

    const obj_roles: object = {};
    const roles: string[] = get(payload, "[cognito:groups]", []);

    ROLES.forEach((systemRol: string) =>
      set(obj_roles, systemRol, roles.includes(systemRol))
    );

    localStorage.setItem(LOCALSTORAGE.ROLES, JSON.stringify(obj_roles));
  }

  private static _getPayload(): IPayload {
    return JSON.parse(
      defaultTo(localStorage.getItem(LOCALSTORAGE.PAYLOAD), "{}")
    );
  }

  private static _getMerchantBasicInfo(): IMerchantForm {
    return JSON.parse(
      defaultTo(
        localStorage.getItem(LOCALSTORAGE.MERCHANT_BASIC_INFORMATION),
        "{}"
      )
    );
  }

  private static _getOriginalAuthPayload(): IPayload {
    return JSON.parse(defaultTo(localStorage.getItem("authPayload"), "{}"));
  }

  private static _setMerchantId(): void {
    //if (localStorage.getItem("merchantId") !== null) return;

    const merchantId: string = get(
      RoleService._getPayload(),
      "preferred_username",
      ""
    );

    localStorage.setItem(LOCALSTORAGE.MERCHANT_ID, merchantId);
  }

  private static _setUserInfo(payload: object): void {
    localStorage.setItem(
      LOCALSTORAGE.USERNAME,
      RoleService._getUsername(payload)
    );
    localStorage.setItem(LOCALSTORAGE.EMAIL, RoleService._getEmail(payload));
    localStorage.setItem(
      LOCALSTORAGE.FULL_NAME,
      RoleService._getFullUserName(payload)
    );
    localStorage.setItem(LOCALSTORAGE.TIMEZONE, RoleService._getTimeZone());
  }

  private static _setEnv(): void {
    localStorage.setItem(LOCALSTORAGE.ENV, environment.envName);
  }

  private static _getUsername(payload: object): string {
    return `${get(payload, "[cognito:username]")}`;
  }

  private static _getEmail(payload: object): string {
    return `${get(payload, "email")}`;
  }

  private static _getFirstName(payload: object): string {
    return `${get(payload, "[name]")}`;
  }

  private static _getLastName(payload: object): string {
    return `${get(payload, "[family_name]")}`;
  }

  private static _getFullUserName(payload: object): string {
    if (RoleService._isSupportSessionActive(payload))
      return `${get(payload, "[cognito:username]")}`;

    return `${get(payload, "[name]")} ${get(payload, "[family_name]")}`;
  }

  private static _getUserName(payload: object): string {
    return get(payload, "[cognito:username]");
  }

  private static _getUserInitials(payload: object): string {
    const firstName: string = get(payload, "[name]");
    const lastName: string = get(payload, "[family_name]");
    const firstLetter: string = get(firstName, [0], "")
      .substring(0, 1)
      .toUpperCase();
    const secondLetter: string = get(lastName, [0], "")
      .substring(0, 1)
      .toUpperCase();

    return firstLetter + secondLetter;
  }

  private static async _getMenus(payload: IPayload): Promise<IKushkiMenu[]> {
    if (TokenService._getTokenIssuer() === TypeIssuerEnum.COGNITO) {
      const roles: IKushkiMultiRol = RoleService._getRolByArrayOfNames(
        get(payload, "[cognito:groups]", [])
      );
      return get(roles, "menu", []);
    } else {
      return await TokenService._getAzureMenus();
    }
  }

  private static _getRolByArrayOfNames(rolName: string[]): IKushkiMultiRol {
    const rols: IKushkiMultiRol = {
      name: null,
      menu: [],
      permissions: [],
      rols: null,
    };
    const ListMenus: [IKushkiMenu[]] = [[]];

    each(rolName, (rol: string) => {
      const rol_selected: IKushkiRol = RoleService._getRolByName(rol);
      if (rol_selected === undefined) return;
      rols.permissions = union(rol_selected.permissions, rols.permissions);
      ListMenus.push(rol_selected.menu);
      rols.rols = {
        ...rols.rols,
        [rol_selected.name]: rol_selected.permissions,
      };
    });
    rols.menu = RoleService._mergeMenus(ListMenus);
    return rols;
  }

  private static _mergeMenus(menus: [IKushkiMenu[]]) {
    let menu: IKushkiMenu[];
    menu = flatten(menus);
    menu = sortBy(menu, ["priority"]);
    menu = uniqWith(menu, function (arr1, arr2) {
      if (arr1.title === arr2.title) {
        arr2.list = union(arr1.list, arr2.list);
        arr2.list = uniqBy(arr2.list, "title");
      }
      return arr1.title === arr2.title;
    });
    each(menu, (newMenu) => {
      let list_menu = get(newMenu, "list", []);
      list_menu = sortBy(list_menu, ["priority"]);
      set(newMenu, "list", list_menu);
    });
    return menu;
  }

  private static _getRolByName(query: string): IKushkiRol {
    return find(
      SYSTEM_ROLES,
      (rol: IKushkiRol) => rol.cognito_group === query
    )!;
  }

  private static _isAdmin(payload: IPayload): boolean {
    return get(payload, "[cognito:groups]", []).includes("BackofficeAdmin");
  }

  private static _getTimeZone(): string {
    if (RoleService._hasTimeZone())
      return defaultTo(localStorage.getItem(LOCALSTORAGE.TIME_ZONE), "");

    return RoleService._validateZoneInfo(
      get(RoleService._getPayload(), "zoneinfo"),
      CountriesEnum.ECUADOR
    );
  }

  private static _hasTimeZone(): boolean {
    return localStorage.getItem(LOCALSTORAGE.TIME_ZONE) !== null;
  }

  private static _setSystemTimeZone(country: string): void {
    localStorage.setItem(
      LOCALSTORAGE.TIME_ZONE,
      RoleService._validateZoneInfo(
        get(RoleService._getPayload(), "zoneinfo"),
        country
      )
    );
  }

  private static _validateZoneInfo(
    zoneInfo: string | undefined,
    country: string
  ) {
    switch (zoneInfo) {
      case "(UTC-05 / UTC-06) (Ciudad de México)":
      case "UTC-05 (Ciudad de México)":
      case "UTC-06 (Ciudad de México)":
        return RoleService._getZoneCountry(CountriesEnum.MEXICO);
      case "UTC-06 (Asuncion, Santiago de Chile)":
      case "UTC-05 (Asuncion, Santiago de Chile)":
      case "UTC-04 (Asuncion, Santiago de Chile)":
      case "UTC-03 (Santiago)":
        return RoleService._getZoneCountry(CountriesEnum.CHILE);
      default:
        return RoleService._getZoneCountry(country);
    }
  }
  private static _getZoneCountry(country: string): string {
    switch (country) {
      case SpanishCountriesEnum.BRASIL:
      case CountriesEnum.BRAZIL:
        return "UTC-03 (SaoPaulo)";

      case CountriesEnum.CHILE:
        const time_zone_num: string = moment
          .tz(TIMEZONE_COUNTRY.get(country)!)
          .format("Z")
          .split(":")[0];
        return `UTC${time_zone_num} ${
          time_zone_num === "-03"
            ? "(Santiago)"
            : "(Asuncion, Santiago de Chile)"
        }`;

      case CountriesEnum.MEXICO:
        return `UTC${
          moment.tz(TIMEZONE_COUNTRY.get(country)!).format("Z").split(":")[0]
        } (Ciudad de México)`;

      default:
        return defaultTo(
          get(RoleService._getPayload(), "zoneinfo"),
          "UTC-05 (Bogota, Quito, Lima)"
        );
    }
  }
}
