import { IShareHolder } from "../../store/interfaces/LegalDetailsState.interfaces";
import { cloneDeep, drop, get, initial, isEmpty, isNil, set } from "lodash";
import {
  DEFAULT_PERCENTAGE,
  LENGTH_FOR_INSERT_ROOT,
  MAX_NUMBER_LEVELS,
  MAX_NUMBER_SHAREHOLDER,
  SHAREHOLDER,
  ShareholderFields,
  ZERO_PERCENTAGE,
} from "../constants/shareholder_constants";
import { PersonTypeEnum } from "../constants/initial_state_legal_details";
import { v4 } from "uuid";

export const countChildren = (obj: IShareHolder): number =>
  get(obj, ShareholderFields.CHILDREN, []).length +
  (get(obj, ShareholderFields.CHILDREN, []) as IShareHolder[]).reduce(
    (acc, c) => acc + countChildren(c),
    0
  );

export const getAllShareholders = (shareholders: IShareHolder[]): number => {
  const result = shareholders.reduce((acc, c) => acc + countChildren(c), 0);

  return result + shareholders.length;
};

const getDepthShareholder = (object: IShareHolder): number => {
  return (
    1 +
    Math.max(
      0,
      ...(get(object, ShareholderFields.CHILDREN, []) || []).map(
        getDepthShareholder
      )
    )
  );
};

export const getLevelShareholder = (shareholders: IShareHolder[]) => {
  if (shareholders.length === 0) {
    return 0;
  }
  const depth = shareholders.map((sh) => getDepthShareholder(sh));

  return Math.max(...depth) - 1;
};

export const getPathOfShareholder = (position: string) => {
  if (position.length <= LENGTH_FOR_INSERT_ROOT) {
    return `[${position}]`;
  }

  const positionArr = position.split("-");
  const base = `[${positionArr[0]}].`;
  let child: any[] = [];

  drop(positionArr).forEach((e) => {
    child.push(`children[${e}]`);
  });

  const path = child.join(".");

  return base + path;
};

export const getUsedPercentage = (shareholders: IShareHolder[]): number =>
  shareholders.reduce(
    (previousValue, currentValue) =>
      previousValue + Number(currentValue.participationPercentage),
    0
  );

export const getAvailablePercentage = (shareholders: IShareHolder[]): number =>
  DEFAULT_PERCENTAGE - getUsedPercentage(shareholders);

export const hasAvailablePercentage = (shareholders: IShareHolder[]) =>
  ZERO_PERCENTAGE < getAvailablePercentage(shareholders);

export const getParentPosition = (position: string) => {
  const positionArr = position.split("-");

  return initial(positionArr).join("-").toString();
};

export const getAvailablePercentageByPosition = (
  position: string,
  shareholders: IShareHolder[]
) => {
  const position_for_root = position.length <= LENGTH_FOR_INSERT_ROOT;

  const path_shareholder = getPathOfShareholder(position);
  const shareholder = get(shareholders, path_shareholder);
  const idShareholder = get(shareholder, "id");

  if (!position_for_root) {
    const position_father = getParentPosition(position);
    const path_to_shareholder = getPathOfShareholder(position_father);
    const father = get(shareholders, path_to_shareholder);
    const noEditShareholders = get(father, "children", []).filter(
      (sh: IShareHolder) => sh.id !== idShareholder
    );

    return DEFAULT_PERCENTAGE - getUsedPercentage(noEditShareholders);
  }
  const noEditShareholders = shareholders.filter(
    (sh) => sh.id !== idShareholder
  );

  return DEFAULT_PERCENTAGE - getUsedPercentage(noEditShareholders);
};

export const getFatherShareholderChildren = (
  position: string,
  shareholders: IShareHolder[]
) => {
  const position_father = getParentPosition(position);
  const path_to_shareholder = getPathOfShareholder(position_father);

  return get(shareholders, path_to_shareholder);
};

export const getLevelByPosition = (position: string) => {
  return position.split("-").length - 1;
};

export const getSourceShareholderName = (
  position: string,
  shareHolders: IShareHolder[]
) => {
  if (position.length <= LENGTH_FOR_INSERT_ROOT) {
    return "";
  }
  const fatherInfo = getFatherShareholderChildren(position, shareHolders);

  return fatherInfo.personType === PersonTypeEnum.FISICA
    ? `${get(fatherInfo, ShareholderFields.NAME, "")} ${get(
        fatherInfo,
        ShareholderFields.LAST_NAME,
        ""
      )}`.trim()
    : get(fatherInfo, ShareholderFields.SOCIAL_REASON);
};
const getParentId = (
  position: string,
  shareholders: IShareHolder[]
): string | undefined => {
  const parent_position = getParentPosition(position);
  const path_to_shareholder_parent = getPathOfShareholder(parent_position);
  const parent: IShareHolder = get(shareholders, path_to_shareholder_parent);

  return parent ? parent.id : undefined;
};

export const insertShareholderByPosition = (
  position: string,
  shareholders: IShareHolder[],
  data: IShareHolder,
  fromLegalRepresentative?: boolean
) => {
  const path_to_shareholder = getPathOfShareholder(position);
  const clean_percentage = get(
    data,
    ShareholderFields.PARTICIPATION_PERCENTAGE
  ).replace("%", "");
  const new_shareholder =
    data.personType === PersonTypeEnum.FISICA
      ? {
          birthdate: get(data, ShareholderFields.BIRTHDATE),
          country: get(data, ShareholderFields.COUNTRY),
          documentNumber: get(data, ShareholderFields.DOCUMENT_NUMBER),
          documentType: get(data, ShareholderFields.DOCUMENT_TYPE),
          lastName: get(data, ShareholderFields.LAST_NAME),
          name: get(data, ShareholderFields.NAME),
          participationPercentage: clean_percentage,
          personType: PersonTypeEnum.FISICA,
          uid: get(data, "uid"),
        }
      : {
          companyCountry: isEmpty(
            get(data, ShareholderFields.MERCHANT_ADDRESS, "")
          )
            ? get(data, ShareholderFields.COMPANY_COUNTRY)
            : get(data, ShareholderFields.MERCHANT_ADDRESS, ""),
          merchantCountry: get(data, ShareholderFields.MERCHANT_COUNTRY),
          participationPercentage: clean_percentage,
          personType: PersonTypeEnum.MORAL,
          socialReason: get(data, ShareholderFields.SOCIAL_REASON),
          taxId: get(data, ShareholderFields.TAX_ID),
          uid: get(data, "uid"),
        };

  data.personType === PersonTypeEnum.FISICA
    ? set(new_shareholder, ShareholderFields.CHILDREN, [])
    : set(
        new_shareholder,
        ShareholderFields.CHILDREN,
        get(data, ShareholderFields.CHILDREN, [])
      );

  set(new_shareholder, ShareholderFields.LEVEL, getLevelByPosition(position));
  set(new_shareholder, ShareholderFields.PERSON_CATEGORY, SHAREHOLDER);
  set(new_shareholder, ShareholderFields.ID, v4());
  let parentId: string | undefined = "";

  if (fromLegalRepresentative) {
    set(new_shareholder, ShareholderFields.PARENT, parentId);
  } else {
    parentId = getParentId(position, shareholders);

    if (parentId) set(new_shareholder, ShareholderFields.PARENT, parentId);
  }

  const copy = cloneDeep(shareholders);

  const children = get(copy, `${path_to_shareholder}.children`);

  if (!isNil(children)) {
    set(new_shareholder, ShareholderFields.CHILDREN, children);
  }

  return set(copy, path_to_shareholder, new_shareholder);
};

export const getNestingLabel = (nesting: string) =>
  nesting
    .split("-")
    .map((c) => +c + 1)
    .join(".");

const deleteShareholder = (shareholder: IShareHolder[], position: number) =>
  shareholder.splice(position, 1);

const deleteShareholderChildren = (
  shareholder: IShareHolder,
  positions: string[]
) => {
  const currentPosition = +positions[0];

  if (positions.length === 1) {
    deleteShareholder(shareholder.children, currentPosition);
  } else {
    deleteShareholderChildren(
      shareholder.children[currentPosition],
      drop(positions)
    );
  }
};

export const deleteShareholders = (
  position: string,
  shareholders: IShareHolder[]
) => {
  const shareholderAux = cloneDeep(shareholders);

  if (position.length <= LENGTH_FOR_INSERT_ROOT) {
    deleteShareholder(shareholderAux, +position);
  } else {
    const positionArr = position.split("-");

    deleteShareholderChildren(
      shareholderAux[+positionArr[0]],
      drop(positionArr)
    );
  }

  return shareholderAux;
};

export const isMoralShareholder = (personType: string): boolean =>
  personType === PersonTypeEnum.MORAL;

export const getTotalParticipationPercentage = (
  shareholders: IShareHolder[]
): number =>
  shareholders.reduce(
    (accumulator, { participationPercentage }) =>
      accumulator + Number(participationPercentage),
    0
  );

export const getTotalShareholderNumber = (
  shareholders: IShareHolder[]
): number => {
  let totalChildren = shareholders.reduce(
    (accumulator, data) => accumulator + countChildren(data),
    0
  );

  return shareholders.length + totalChildren;
};

export const validateIsPossibleAddShareholder = (
  shareholders: IShareHolder[]
) => {
  const newShareholders = cloneDeep(shareholders);
  const level = getLevelShareholder(newShareholders);
  const number_of_child = getAllShareholders(newShareholders);

  return (
    level > MAX_NUMBER_LEVELS ||
    number_of_child >= MAX_NUMBER_SHAREHOLDER ||
    !hasAvailablePercentage(newShareholders)
  );
};

export const validateIsPossibleAddShareholderChildren = (
  nesting: string,
  currentShareholder: IShareHolder,
  shareHolders: IShareHolder[]
) => {
  const newShareholders = cloneDeep(shareHolders);
  const allShareholders = getAllShareholders(newShareholders);
  const firstFather = shareHolders[+nesting.split("-")[0]];
  const count_of_children = countChildren(firstFather);

  return (
    allShareholders < MAX_NUMBER_SHAREHOLDER &&
    count_of_children < MAX_NUMBER_LEVELS &&
    hasAvailablePercentage(
      get(currentShareholder, ShareholderFields.CHILDREN, [])
    )
  );
};
