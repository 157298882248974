import { IStyles } from "./../../../../../../shared/interfaces/Styles.interfaces";

export const styles: IStyles = {
  entryTitle: {
    color: "neutral.grey7",
    display: "block",
    fontFamily: "IBM Plex Sans, Helvetica !important",
    fontWeight: "400 !important",
    lineHeight: "140% !important",
  },
  entryBody: {
    color: "text.grey",
    display: "block",
    fontFamily: "IBM Plex Sans, Helvetica !important",
    fontWeight: "400 !important",
    lineHeight: "136% !important",
  },
};
