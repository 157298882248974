import React from "react";
import { TableCell, TableRow, TableSortLabel } from "@mui/material";
import { ITableColumn } from "../../../../shared/interfaces/Table.interfaces";
import ChevronsSortIcon from "../../../../assets/icons/ChevronsSortIcon/ChevronsSortIcon";
import { TableHeadStyles } from "../../../common/Table/TableHead/TableHead.styles";

interface Props {
  columns: ITableColumn[];
}

const TableHistoricalHead = ({ columns }: Props) => {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.id}
          align={column.align}
          sx={TableHeadStyles.cell}
        >
          {column.sortable ? (
            <TableSortLabel
              onClick={column.onSortClick}
              IconComponent={ChevronsSortIcon}
              sx={TableHeadStyles.sortLabel}
            >
              {column.label}
            </TableSortLabel>
          ) : (
            <span>{column.label}</span>
          )}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default TableHistoricalHead;
