import React, {
  ChangeEvent,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import {
  Box,
  Checkbox,
  createStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  withStyles,
} from "@material-ui/core";
import DashboardTableRow, {
  CountryList,
} from "./DashboardTableRow/DashboardTableRow";
import { TransactionTable } from "../../../../../types/transaction_table";
import FilterDashboard, {
  FilterDashboardStateProps,
} from "../../FilterDashboard";
import { get, includes, lowerCase, pick, set, sortBy, split } from "lodash";
import { EmptyList } from "../../../EmptyList/EmptyList";
import { IBillingDashboardState } from "../../../../store/reducer";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  BillingActions,
  downloadPreprocessFileWS,
  orderBillingData,
  setBillingDataRow,
  setBillingTrxData,
  transactionIdFilter,
} from "../../../../store/actionCreators";
import { ModalOverlay } from "@kushki/frontend-molecules/modal";
import { ModalFooter } from "@kushki/frontend-molecules/modal-footer";
import { AdvanceSettingsDialog } from "../../../ModalBodyBilling/AdvanceSettingsDialog/AdvanceSettingsDialog";
import { KindEnum } from "../../../../shared/infrastructure/KindEnum";
import { InvoiceInfoDialog } from "../../../ModalBodyBilling/InvoiceInfoDialog/InvoiceInfoDialog";
import {
  CountryEnum,
  LEDGER_FLOW_COUNTRIES,
} from "../../../../shared/infrastructure/CountryEnum";
import {
  Download as IconDownload,
  Settings as IconSettings,
  Settings,
} from "react-feather";
import { DownloadInvoiceDetail } from "../../../../../types/download_invoice_detail";
import { DownloadLiquidationDetail } from "../../../../../types/download_liquidation_detail";
import { TransactionDynamo } from "../../../../../types/remote/transaction_dynamo";
import { ModalStyles } from "../../../Common/ModalCard/ModalStyles";
import { StatusFlowEnum } from "../../../../shared/infrastructure/StatusFlowEnum";
import { SkeletonListContainer } from "../../../Skeleton/SkeletonListContainer";
import {
  RowStatusEnum,
  StatusEnum,
} from "../../../../shared/infrastructure/StatusEnum";
import { useDashboardTableState } from "./state/useDashboardTableState";
import { OmitConfirmModal } from "../../../ConfirmModal/OmitConfirmModal/OmitConfirmModal";
import { RejectConfirmModal } from "../../../ConfirmModal/RejectConfirmModal/RejectConfirmModal";
import { BillingTransactionTypeEnum } from "../../../../shared/infrastructure/BillingTransactionTypeEnum";
import { translate } from "../../../../shared/infrastructure/LanguageCatatog";
import { format } from "date-fns-tz";
import { InvoiceModeEnum } from "../../../../shared/infrastructure/InvoiceModeEnum";
import { ModalHeader } from "@kushki/frontend-molecules/modal-header";
import {
  setBackgroundColor,
  setColor,
} from "../../../../shared/infrastructure/Colors";
import { ModalHeaderBilling } from "../../../ModalHeaderBiling/ModalHeaderBilling";
import { IColumnsTable } from "../../../../shared/infrastructure/table/IColumnsTable";
import { TableContainerDownload } from "../../../ToDownloadTable/TableContainerDownload/TableContainerDownload";
import { FooterTable } from "../../../ToDownloadTable/FooterTable/FooterTable";
import { connect } from "react-redux";
import { ModalBodyTrxTabs } from "../../../ModalBodyBilling/ModalBodyTrxTabs/ModalBodyTrxTabs";
import { UnfoldMore } from "@material-ui/icons";
import { transactionTypeEnum } from "../../../../shared/infrastructure/PaymentMethodEnum";
import { getTransactionTypeObject } from "../../../../shared/infrastructure/TransactionTypeObject";
import { DownloadsEnums } from "../../../../shared/historicTableEnum";
import { MINIMAL_COMMISSION_ARRAY } from "../../../../shared/infrastructure/constants/MinimalCommissions";
import { DownloadDocumentParams } from "../DashboardHistoricTable/DashboardHistoricTable";
import { WebsocketActionEnum } from "../../../../shared/infrastructure/WebsocketActionEnum";
import { DownloadLedgerDetail } from "../../../../../types/download_ledger_detail";
import {
  RolesExecutorEnum,
  RolesValidatorEnum,
} from "../../../../shared/infrastructure/enum/Roles/RolesEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { DownloadChargeDetail } from "../../../../../types/download_charge_detail";
import {
  DISPERSION_KINDS,
  LEDGER_FLOW_KINDS,
} from "../../../../shared/infrastructure/KindCatalog";

export interface DashboardTableProps {
  invoiceMode?: InvoiceModeEnum;
  country: CountryList;
  isLoading: boolean;
  isLoadingProcessBilling: boolean;
  statusFlow: StatusFlowEnum;
  handleCheckboxChange: (
    rowValue: TransactionTable
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  filterProps: FilterDashboardStateProps;
  modalOverBilling: {
    openModalBilling: boolean;
    handlerCloseModalTrxBilling: () => void;
    handlerSelectTrxBilling: (
      row: TransactionTable | undefined,
      index: number
    ) => void;
    trxBilling: { trx: TransactionTable | undefined; index: number };
    isMinAmountTrx: boolean;
  };
  tab?: number;
}

export interface DashboardTableStateProps {
  billingData: TransactionTable[];
  isLoadingDownloadPreProcess: boolean;
  transactionTableCollections: TransactionTable[] | undefined;
}

export interface DashboardTableFunctionsProps {
  orderBillingData: (payload: TransactionTable[]) => void;
  setBillingTrxData: (payload: TransactionTable[]) => void;

  setBillingDataRow(billingDataRow: TransactionTable, index: number): void;
  setBillingRow: (billingDataRow: TransactionTable) => void;
  downloadPreprocessFileWS: (params: DownloadDocumentParams) => void;
  transactionIdFilter: () => void;
}

export type DashboardTableComponentProps = DashboardTableProps &
  DashboardTableStateProps &
  DashboardTableFunctionsProps;

export const StyledTableCell = withStyles(
  createStyles({
    root: {
      boxShadow: "none",
      borderTop: 0,
    },
    head: {
      backgroundColor: "#FFFFFF",
      color: "#293036",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const TRANSACTION_KINDS: KindEnum[] = [
  KindEnum.CREDIT_NOTE,
  KindEnum.DEBIT_NOTE,
  KindEnum.VOUCHER,
  KindEnum.RETENTION,
  KindEnum.RETENTION_EC,
];

export const StyledTableCellCheck = withStyles(
  createStyles({
    head: {
      backgroundColor: "#FFFFFF",
      color: "#293036",
      textAlign: "center",
    },
  })
)(TableCell);

export const getValue = (trx: DashboardTableComponentProps, value: string) =>
  get(trx, `modalOverBilling.trxBilling.trx.transaction.${value}`);

const formatDate = (trxDate: number, formatDate: string): string => {
  if (!trxDate) return "";
  const date: string = new Date(
    trxDate.toString().length === 10 ? trxDate * 1000 : trxDate
  ).toISOString();

  return format(new Date(date.replace("Z", "")), formatDate, {
    timeZone: "UTC",
  });
};

const getTitle = (kind: string, invoiceMode: string = ""): string => {
  let title;
  switch (kind) {
    case KindEnum.INVOICE:
      if (invoiceMode === InvoiceModeEnum.WITH_IVA) title = "Factura con IVA";
      else if (invoiceMode === InvoiceModeEnum.WITHOUT_IVA)
        title = "Factura sin IVA";
      else title = "Factura";
      break;
    case KindEnum.CREDIT_NOTE:
      title = "Nota de Crédito";
      break;
    case KindEnum.DEBIT_NOTE:
      title = "Nota de Débito";
      break;
    case KindEnum.CHARGE:
      title = "Cobro";
      break;
    case KindEnum.RECEIVABLE:
      title = "Cuenta por Cobrar";
      break;
    case KindEnum.DISPERSION:
      title = "Dispersión";
      break;
    case KindEnum.VOUCHER:
      title = "Boleta";
      break;
    default:
      title = "";
      break;
  }
  return title;
};
const getTableCellWithSortButton = (
  column: IColumnsTable,
  order: "desc" | "asc" | undefined,
  orderBy: string,
  handleRequestSort: (
    event: React.MouseEvent<unknown>,
    property: string
  ) => void
) => {
  switch (column.id) {
    case "socialReason":
      return (
        <StyledTableCell>
          <TableSortLabel
            id={"tableSortLabelsocialReason"}
            IconComponent={UnfoldMore}
            direction={orderBy === "socialReason.keyword" ? order : "asc"}
            onClick={(event) =>
              handleRequestSort(event, "socialReason.keyword")
            }
          >
            Razón social
          </TableSortLabel>
        </StyledTableCell>
      );

    case "amount":
      return (
        <StyledTableCell>
          <TableSortLabel
            id={"tableSortLabelAmount"}
            IconComponent={UnfoldMore}
            direction={orderBy === "amount.keyword" ? order : "asc"}
            onClick={(event) => handleRequestSort(event, "amount.keyword")}
          >
            Monto Total
          </TableSortLabel>
        </StyledTableCell>
      );

    default:
      return <StyledTableCell key={column.id}>{column.label}</StyledTableCell>;
  }
};

const getLabelFooterButton = (kind: string): string => {
  switch (kind) {
    case KindEnum.INVOICE:
      return "Configuración Avanzada";
    case KindEnum.CREDIT_NOTE:
      return "Agregar información de la factura";
    case KindEnum.CHARGE:
      return "Configuración Avanzada";
    case KindEnum.VOUCHER:
      return "Configuración Avanzada";
    default:
      return "";
  }
};

const getIconFooterButton = (kind: string): JSX.Element | undefined => {
  switch (kind) {
    case KindEnum.INVOICE:
    case KindEnum.CHARGE:
    case KindEnum.VOUCHER:
      return <Settings xlinkTitle={"Configurar"} size={20} />;
    default:
      return undefined;
  }
};

const verifyCountry = (countryVal: CountryEnum) => (country: CountryEnum) =>
  countryVal === country;

const verifyTransactionType = (kindValue: KindEnum) =>
  TRANSACTION_KINDS.includes(kindValue);

const buildModalOverlayFooterInvoice = (
  props: DashboardTableComponentProps,
  openDialogsHandler: () => void
): JSX.Element | undefined => {
  const classes = ModalStyles();

  const getPaymentMethod = (transactionType: string): string => {
    const getPaymentMethod: string[] = split(transactionType, "_");
    if (MINIMAL_COMMISSION_ARRAY.includes(transactionType)) return "";
    switch (getPaymentMethod.length) {
      case 2:
        return getPaymentMethod[1];
      case 3:
        return getPaymentMethod[2];
      case 4:
        return getPaymentMethod[3];
      default:
        return "";
    }
  };

  const getTransactionType = (transactionType: string): string => {
    const transactionTypeSplit: string[] = split(transactionType, "_");
    if (MINIMAL_COMMISSION_ARRAY.includes(transactionType))
      return DownloadsEnums.TRANSACTION_TYPE_MINIMAL_COMMISSION;
    if (transactionTypeSplit[1] === transactionTypeEnum.payout)
      return DownloadsEnums.TRANSACTION_TYPE_PAYOUT;
    return DownloadsEnums.TRANSACTION_TYPE_ALL;
  };

  const verifyInvoiceTransaction = (trx: TransactionTable) => {
    const trxInvoiceWsData = {
      startDate: get(trx, "transaction.startDate"),
      endDate: get(trx, "transaction.endDate"),
      merchantId: get(trx, "transaction.merchantId"),
      discount: get(trx, "transaction.kushkiCommissionDiscountPercentage"),
      country: get(trx, "transaction.country"),
      paymentMethod: lowerCase(
        getPaymentMethod(get(trx, "transaction.transactionType"))
      ),
      transactionType: getTransactionType(
        get(trx, "transaction.transactionType")
      ),
    };

    return get(trx, "transaction.additionalServices", []).includes("3ds") &&
      get(trx, "transaction.hasAdditionalServices", false)
      ? { ...trxInvoiceWsData, additionalService: "3ds" }
      : trxInvoiceWsData;
  };

  const params: DownloadInvoiceDetail = {
    action: "trxInvoice",
    data: verifyInvoiceTransaction(
      get(props.modalOverBilling, "trxBilling.trx")
    ),
  };

  return (
    <ComponentSecurityWrapper
      componentId={
        props.statusFlow == StatusFlowEnum.EXECUTOR
          ? RolesExecutorEnum.M_BILLING_MODAL_FOOTER
          : RolesValidatorEnum.M_BILLING_MODAL_FOOTER
      }
    >
      <Box>
        <ModalFooter
          className={classes.modalDownloadFooter}
          primaryButtonVariant="outlined"
          primaryButtonColor="primary"
          primaryLabelButton="Descargar"
          primaryActionIcon={
            <IconDownload xlinkTitle={"Descargar"} size={20} />
          }
          primaryActionButton={() => props.downloadPreprocessFileWS(params)}
          loading={props.isLoadingDownloadPreProcess}
          secondaryLabelButton={getLabelFooterButton(getValue(props, "kind"))}
          secondaryActionIcon={<IconSettings size={20} />}
          secondaryActionButton={openDialogsHandler}
          secondaryFontColor="default"
          separate={true}
        />
      </Box>
    </ComponentSecurityWrapper>
  );
};

const validateFooterModalOver = (
  props: DashboardTableComponentProps,
  openDialogsHandler: () => void
): JSX.Element | undefined => {
  if (
    includes(
      [KindEnum.INVOICE, KindEnum.VOUCHER],
      get(props.modalOverBilling.trxBilling.trx, "transaction.kind")
    )
  )
    return buildModalOverlayFooterInvoice(props, openDialogsHandler);
  return buildModalOverlayFooter(props, openDialogsHandler);
};

const buildModalOverlayFooter = (
  props: DashboardTableComponentProps,
  openDialogsHandler: () => void
): JSX.Element | undefined => {
  const countryVal = get(props, "country", "") as CountryEnum;
  const isCountry = verifyCountry(countryVal);
  const isChile = isCountry(CountryEnum.chile);
  const isEcuador = isCountry(CountryEnum.ecuador);
  const isColombia = isCountry(CountryEnum.colombia);
  const isPeru = isCountry(CountryEnum.peru);
  const isMexico = isCountry(CountryEnum.mexico);
  const kindVal = getValue(props, "kind") as KindEnum;
  const isNotDispersion = verifyTransactionType(kindVal);
  const isTransaction = verifyTransactionType(kindVal);
  const isInvoice = kindVal === KindEnum.INVOICE;
  const classes = ModalStyles();
  const isLedgerListFlow: boolean =
    LEDGER_FLOW_COUNTRIES.includes(countryVal) &&
    LEDGER_FLOW_KINDS.includes(kindVal);
  const isPeruDispersion: boolean =
    isPeru && DISPERSION_KINDS.includes(kindVal);
  const dynamoTransaction: TransactionDynamo = props.modalOverBilling.trxBilling
    .trx?.transaction!;
  const transactionRequiredProps = pick(dynamoTransaction, [
    "created",
    "currency",
    "merchantId",
    "merchantName",
    "transactionId",
    "transactionType",
  ]);
  const transactionPayload = {
    ...transactionRequiredProps,
    amountRetention: get(dynamoTransaction, "amountRetention", 0),
    amountCharge: get(dynamoTransaction, "amountCharge", 0),
    amountSumChargePending: get(dynamoTransaction, "amountSumChargePending", 0),
    createdCharge: get(
      dynamoTransaction,
      "createdCharge",
      get(dynamoTransaction, "created")
    ),
    createdRetention: get(
      dynamoTransaction,
      "createdRetention",
      get(dynamoTransaction, "created")
    ),
    hasCharge: get(
      dynamoTransaction,
      "hasCharge",
      get(dynamoTransaction, "kind") === KindEnum.CHARGE
    ),
    hasFraudRetention: get(dynamoTransaction, "hasFraudRetention", false),
    hasFraudRetentionPending: get(
      dynamoTransaction,
      "hasFraudRetentionPending",
      false
    ),
    hasMinAmountCharge: get(dynamoTransaction, "hasMinAmountCharge", false),
    minAmount: get(dynamoTransaction, "minAmount", 0),
  };
  const action: WebsocketActionEnum =
    isInvoice || (isNotDispersion && !isPeruDispersion)
      ? WebsocketActionEnum.trxInvoice
      : isLedgerListFlow
      ? WebsocketActionEnum.listLedgerDetail
      : kindVal === KindEnum.CHARGE
      ? WebsocketActionEnum.chargeDetail
      : WebsocketActionEnum.liquidationDetail;
  const payload =
    isInvoice || (isNotDispersion && !isPeruDispersion)
      ? {
          merchantId: get(
            props.modalOverBilling.trxBilling.trx,
            "transaction.merchantId"
          ),
          discount: get(
            props.modalOverBilling.trxBilling.trx,
            "transaction.kushkiCommissionDiscountPercentage"
          ),
          country: get(
            props.modalOverBilling.trxBilling.trx,
            "transaction.country"
          ),
        }
      : isLedgerListFlow
      ? {
          merchantId: get(transactionPayload, "merchantId"),
          transactionId: get(transactionPayload, "transactionId"),
          country: get(
            props.modalOverBilling.trxBilling.trx,
            "transaction.country"
          ),
        }
      : {
          dynamoTransaction: transactionPayload,
          country: get(
            props.modalOverBilling.trxBilling.trx,
            "transaction.country"
          ),
        };

  const params:
    | DownloadInvoiceDetail
    | DownloadLiquidationDetail
    | DownloadLedgerDetail
    | DownloadChargeDetail = {
    action,
    data: {
      startDate: get(
        props.modalOverBilling.trxBilling.trx,
        "transaction.startDate"
      ),
      endDate: get(
        props.modalOverBilling.trxBilling.trx,
        "transaction.endDate"
      ),
      ...payload,
    },
  };

  let modalOverlayFooter: JSX.Element | undefined;
  if (isMexico && isTransaction) {
    modalOverlayFooter = <Box height={"5%"} bgcolor={"#F7FAFC"} />;
  } else {
    if (
      (!isTransaction &&
        [
          KindEnum.CHARGE,
          KindEnum.RECEIVABLE,
          KindEnum.DISPERSION,
          KindEnum.VOUCHER,
        ].includes(kindVal)) ||
      (props.statusFlow === StatusFlowEnum.VALIDATOR &&
        getValue(props, "country") === CountryEnum.mexico &&
        isMexico)
    ) {
      modalOverlayFooter = (
        <ComponentSecurityWrapper
          componentId={
            props.statusFlow == StatusFlowEnum.EXECUTOR
              ? RolesExecutorEnum.M_BILLING_MODAL_FOOTER
              : RolesValidatorEnum.M_BILLING_MODAL_FOOTER
          }
        >
          <Box>
            <ModalFooter
              className={classes.modalRetentionFooterHistoric}
              dropDwLabelButton={"Descargar"}
              dropDwButtonVariant={"contained"}
              dropDwButtonColor={"primary"}
              classNamedrowButtonPrimary="splitBtn"
              classNamedrowButton="splitBtn splitArrow"
              dropDwActionIcon={
                <IconDownload xlinkTitle={"Descargar"} size={15} />
              }
              dropDwMenuItems={[
                {
                  label: "Descargar detalle",
                  disable: false,
                  hidden: false,
                  menuItemActionButton: () => {
                    props.downloadPreprocessFileWS(params);
                  },
                },
              ]}
              loading={props.isLoadingDownloadPreProcess}
            />
          </Box>
        </ComponentSecurityWrapper>
      );
    } else if (
      (isMexico || isChile || isColombia || isEcuador || isPeru) &&
      [KindEnum.INVOICE, KindEnum.VOUCHER, KindEnum.CREDIT_NOTE].includes(
        kindVal
      )
    ) {
      modalOverlayFooter = (
        <ComponentSecurityWrapper
          componentId={
            props.statusFlow == StatusFlowEnum.EXECUTOR
              ? RolesExecutorEnum.M_BILLING_MODAL_FOOTER
              : RolesValidatorEnum.M_BILLING_MODAL_FOOTER
          }
        >
          <Box>
            <ModalFooter
              dropDwLabelButton={"Descargar"}
              dropDwButtonVariant={"contained"}
              dropDwButtonColor={"primary"}
              classNamedrowButtonPrimary="splitBtn"
              classNamedrowButton="splitBtn splitArrow"
              dropDwActionIcon={
                <IconDownload xlinkTitle={"Descargar"} size={15} />
              }
              dropDwMenuItems={[
                {
                  label: "Descargar detalle",
                  disable: false,
                  hidden: true,
                  menuItemActionButton: () => {
                    props.downloadPreprocessFileWS(params);
                  },
                },
              ]}
              secondaryLabelButton={getLabelFooterButton(
                getValue(props, "kind")
              )}
              secondaryActionIcon={<IconSettings size={20} />}
              secondaryActionButton={openDialogsHandler}
              secondaryFontColor="default"
              separate={true}
            />
          </Box>
        </ComponentSecurityWrapper>
      );
    } else if (
      (isColombia || isPeru) &&
      [KindEnum.DEBIT_NOTE].includes(kindVal)
    ) {
      modalOverlayFooter = (
        <ComponentSecurityWrapper
          componentId={
            props.statusFlow == StatusFlowEnum.EXECUTOR
              ? RolesExecutorEnum.M_BILLING_MODAL_FOOTER
              : RolesValidatorEnum.M_BILLING_MODAL_FOOTER
          }
        >
          <Box>
            <ModalFooter
              className={classes.modalRetentionFooterHistoric}
              dropDwLabelButton={"Descargar"}
              dropDwButtonVariant={"contained"}
              dropDwButtonColor={"primary"}
              classNamedrowButtonPrimary="splitBtn"
              classNamedrowButton="splitBtn splitArrow"
              dropDwActionIcon={
                <IconDownload xlinkTitle={"Descargar"} size={15} />
              }
              dropDwMenuItems={[
                {
                  label: "Descargar detalle",
                  disable: false,
                  hidden: false,
                  menuItemActionButton: () => {
                    props.downloadPreprocessFileWS(params);
                  },
                },
              ]}
              loading={props.isLoadingDownloadPreProcess}
            />
          </Box>
        </ComponentSecurityWrapper>
      );
    } else {
      modalOverlayFooter =
        kindVal === KindEnum.CHARGE ||
        (isPeru &&
          [KindEnum.INVOICE, KindEnum.VOUCHER].includes(
            kindVal
          )) ? undefined : (
          <ComponentSecurityWrapper
            componentId={
              props.statusFlow == StatusFlowEnum.EXECUTOR
                ? RolesExecutorEnum.M_BILLING_MODAL_FOOTER
                : RolesValidatorEnum.M_BILLING_MODAL_FOOTER
            }
          >
            <Box>
              <ModalFooter
                primaryButtonVariant="contained"
                primaryButtonColor="primary"
                primaryLabelButton={getLabelFooterButton(
                  getValue(props, "kind")
                )}
                primaryActionIcon={getIconFooterButton(getValue(props, "kind"))}
                primaryActionButton={openDialogsHandler}
              />
            </Box>
          </ComponentSecurityWrapper>
        );
    }
  }
  return modalOverlayFooter;
};

export const DashboardTable = (props: DashboardTableComponentProps) => {
  const { statusFlow, isLoadingProcessBilling } = props;
  const { selectProps, actions, settingsColumns } = useDashboardTableState(
    props
  );
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState<string>("");
  const [checkValue, setCheckValue] = useState<boolean>(false);
  const [valueTab, setValueTab] = useState<number>(0);
  const classes = ModalStyles();

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangeTab = (newValue: number) => {
    setValueTab(newValue);
  };

  const fixedHeaders = [
    {
      id: "statusAll",
      label: "Tipo",
      view: true,
      filter: false,
    },
    ...settingsColumns.renderColumns,
  ];

  const update = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: TransactionTable[]
  ) => {
    let indi = 0;
    if (!event.target.checked) {
      data.map((row: TransactionTable) => {
        props.setBillingDataRow({ ...row, statusAll: false }, indi);
        indi++;
      });
    }
    if (event.target.checked) {
      data.map((row: TransactionTable) => {
        props.setBillingDataRow({ ...row, statusAll: true }, indi);
        indi++;
      });
    }
    setCheckValue(!checkValue);
    props.transactionIdFilter();
  };
  useEffect(() => {
    props.orderBillingData(
      order === "desc"
        ? sortBy(props.billingData, [
            (billing: TransactionTable) => billing.transaction?.merchantName,
          ]).reverse()
        : sortBy(props.billingData, [
            (billing: TransactionTable) => billing.transaction?.merchantName,
          ])
    );
  }, [order, orderBy]);

  useLayoutEffect(() => {
    const data_aux: TransactionTable[] = props.billingData.map((txr) => {
      if (
        includes(
          [
            KindEnum.INVOICE,
            KindEnum.VOUCHER,
            KindEnum.CHARGE,
            KindEnum.DISPERSION,
            KindEnum.RECEIVABLE,
          ],
          get(txr, "transaction.kind", "")
        ) &&
        get(txr, "transaction.statusRow", "") === RowStatusEnum.Reprocess
      ) {
        set(txr, "status", get(txr, "transaction.status", ""));
        set(txr, "statusRow", get(txr, "transaction.statusRow", ""));
      }

      return txr;
    });

    props.setBillingTrxData(data_aux);
    props.billingData.forEach((trx: TransactionTable) => {
      if (
        includes(
          [
            KindEnum.INVOICE,
            KindEnum.VOUCHER,
            KindEnum.CHARGE,
            KindEnum.DISPERSION,
            KindEnum.RECEIVABLE,
          ],
          get(trx, "transaction.kind", "")
        ) &&
        get(trx, "transaction.statusRow", "") === RowStatusEnum.Reprocess
      ) {
        set(trx, "transaction.status", get(trx, "transaction.status", ""));
        set(
          trx,
          "transaction.statusRow",
          get(trx, "transaction.statusRow", "")
        );
        setBillingDataRow(trx);
      }
    });
  }, []);
  const verifyCountryAndDocumentKind = () => {
    const countryConditions: boolean = [
      CountryEnum.ecuador,
      CountryEnum.peru,
      CountryEnum.chile,
      CountryEnum.mexico,
      CountryEnum.colombia,
    ].includes(getValue(props, "country"));
    const kindConditions: boolean = [
      KindEnum.VOUCHER,
      KindEnum.CHARGE,
      KindEnum.DISPERSION,
      KindEnum.INVOICE,
      KindEnum.CREDIT_NOTE,
      KindEnum.DEBIT_NOTE,
      KindEnum.RECEIVABLE,
    ].includes(getValue(props, "kind"));
    return countryConditions && kindConditions;
  };
  return (
    <React.Fragment>
      <FilterDashboard
        settingsColumns={settingsColumns}
        country={props.country}
        filters={props.filterProps.filters}
        range={props.filterProps.range}
        rangeAmount={props.filterProps.rangeAmount}
        handleClose={props.filterProps.handleClose}
        handleGetFilters={props.filterProps.handleGetFilters}
        handleChangeSearch={props.filterProps.handleChangeSearch}
        handleSearchBar={props.filterProps.handleSearchBar}
        handleSetDate={props.filterProps.handleSetDate}
        merchantSearch={props.filterProps.merchantSearch}
        counterFilter={props.filterProps.counterFilter}
        downloadFileState={props.filterProps.downloadFileState}
        statusFlow={statusFlow}
      />
      <TableContainer component={Paper} elevation={0}>
        {get(props, "transactionTableCollections", []).length > 0 && (
          <TableContainerDownload
            fixedHeaders={fixedHeaders}
            selectProps={selectProps}
            actions={actions}
            settingsColumns={settingsColumns}
            handleRequestSort={handleRequestSort}
            data={props.transactionTableCollections!}
            statusFlow={statusFlow}
            orderBy={orderBy}
            order={order}
            country={props.country}
            checkValue={checkValue}
            dataOriginal={props.billingData}
            setBillingDataRow={props.setBillingDataRow}
            transactionIdFilter={props.transactionIdFilter}
            update={update}
            tableRowProps={{
              modalOverBilling: props.modalOverBilling,
              handleCheckboxChange: props.handleCheckboxChange,
              checked: checkValue,
            }}
          >
            <FooterTable
              count={get(props, "transactionTableCollections", []).length}
              onClick={() => {
                props.filterProps.downloadFileState.downloadFile(
                  "csv",
                  false,
                  props.transactionTableCollections
                );
              }}
              isLoading={props.filterProps.downloadFileState.isDownloadingCheck}
            />
          </TableContainerDownload>
        )}
      </TableContainer>

      <TableContainer component={Paper} elevation={0}>
        {isLoadingProcessBilling ? (
          <SkeletonListContainer />
        ) : (
          <>
            {get(props, "billingData", []).length > 0 ? (
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      <Checkbox
                        id={"checkStatusAll"}
                        color="primary"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          update(e, props.billingData)
                        }
                        checked={checkValue}
                      />
                    </StyledTableCell>
                    {settingsColumns.renderColumns.map(
                      (column: IColumnsTable) =>
                        getTableCellWithSortButton(
                          column,
                          selectProps.order,
                          selectProps.orderBy,
                          actions.handleRequestSort
                        )
                    )}
                    <StyledTableCellCheck>
                      <Checkbox
                        color={"primary"}
                        inputProps={{ "aria-label": "procesar" }}
                        checked={selectProps.statusRows === StatusEnum.PROCESS}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          actions.handleChangeCheckbox(
                            event,
                            StatusEnum.PROCESS,
                            statusFlow
                          )
                        }
                      />
                      {statusFlow === StatusFlowEnum.VALIDATOR
                        ? "Aprobar"
                        : "Procesar"}
                    </StyledTableCellCheck>
                    {statusFlow === StatusFlowEnum.EXECUTOR && (
                      <StyledTableCellCheck>
                        <Checkbox
                          color={"primary"}
                          inputProps={{ "aria-label": "pendiente" }}
                          checked={
                            selectProps.statusRows === StatusEnum.PENDING
                          }
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            actions.handleChangeCheckbox(
                              event,
                              StatusEnum.PENDING,
                              statusFlow
                            )
                          }
                        />
                        Pendiente
                      </StyledTableCellCheck>
                    )}

                    {statusFlow === StatusFlowEnum.VALIDATOR && (
                      <StyledTableCellCheck>
                        <Checkbox
                          color={"primary"}
                          inputProps={{ "aria-label": "rechazar" }}
                          checked={selectProps.statusRows === StatusEnum.REJECT}
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            actions.handleChangeCheckbox(
                              event,
                              StatusEnum.REJECT,
                              statusFlow
                            )
                          }
                        />
                        Rechazar
                      </StyledTableCellCheck>
                    )}

                    <StyledTableCellCheck>
                      <Checkbox
                        color={"primary"}
                        inputProps={{ "aria-label": "Reproceso" }}
                        checked={
                          selectProps.statusRows === StatusEnum.REPROCESS
                        }
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          actions.handleChangeCheckbox(
                            event,
                            StatusEnum.REPROCESS,
                            statusFlow
                          )
                        }
                      />
                      Reproceso
                    </StyledTableCellCheck>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.billingData?.map(
                    (row: TransactionTable, index: number) => (
                      <DashboardTableRow
                        tab={get(props, "tab", undefined)}
                        renderColumns={fixedHeaders}
                        billingData={row}
                        country={props.country}
                        handleCheckboxChange={props.handleCheckboxChange}
                        checked={props.checked}
                        index={index}
                        key={index}
                        handlerOpenCardRow={
                          props.modalOverBilling.handlerSelectTrxBilling
                        }
                        validateCheckboxByTaxId={
                          actions.validateCheckboxByTaxId
                        }
                        statusFlow={statusFlow}
                        statusRows={selectProps.statusRows}
                        reasonCheckBox={selectProps.reasonCheckBox}
                        handleDefaultValuesRows={
                          actions.handleDefaultValuesRows
                        }
                      />
                    )
                  )}
                </TableBody>
              </Table>
            ) : (
              <EmptyList />
            )}
          </>
        )}
      </TableContainer>

      <OmitConfirmModal
        open={selectProps.openModalOmit}
        handleCloseOmit={actions.handleCloseOmit}
        handleAcceptOmit={actions.handleAcceptOmit}
        isIndividual={false}
      />
      <RejectConfirmModal
        open={selectProps.openRejectModal}
        handleCloseReject={actions.handleCloseReject}
        handleAcceptReject={actions.handleAcceptReject}
        title={"¿Deseas rechazar todas las operaciones?"}
        acceptText={"Aceptar"}
        label={"Indique el motivo de rechazo"}
        maxCharacter={200}
        content={
          <>
            <p>
              Estas a punto de rechazar todas las operaciones, al dar click en
              “Aceptar” estas operaciones regresarán a la bandeja del Usuario
              Ejecutador en su apartado de “Facturación y dispersión”.
            </p>
            <p>Indique el motivo del rechazo, para guardar la modificación.</p>
          </>
        }
      />
      <ModalOverlay
        header={
          verifyCountryAndDocumentKind() ? (
            <ModalHeaderBilling {...props} />
          ) : getValue(props, "country") === CountryEnum.mexico ||
            ((props.country === CountryEnum.peru || CountryEnum.colombia) &&
              !(
                getValue(props, "transactionType") ===
                  BillingTransactionTypeEnum.PAYIN_COMMISSION ||
                getValue(props, "transactionType") ===
                  BillingTransactionTypeEnum.PAYOUT_COMMISSION ||
                getValue(props, "transactionType") ===
                  BillingTransactionTypeEnum.MINIMAL_COMMISSION
              )) ? (
            // eslint-disable-next-line react/jsx-no-undef
            <ModalHeader
              className={classes.modalHeader}
              highlightValue={getValue(props, "socialReason")}
              tittle={`Detalle de ${getTitle(
                getValue(props, "kind"),
                getValue(props, "invoiceMode")
              )}`}
              subtitle={"Tax ID"}
              secondaryValue={getValue(props, "taxId")}
              statusValue={
                getValue(props, "kind") === KindEnum.CREDIT_NOTE
                  ? translate(getValue(props, "status"))
                  : undefined
              }
              chipColor={
                getValue(props, "kind") === KindEnum.CREDIT_NOTE
                  ? setColor(getValue(props, "status"))
                  : "#FFFFFF"
              }
              chipBackGround={
                getValue(props, "kind") === KindEnum.CREDIT_NOTE
                  ? setBackgroundColor(getValue(props, "status"))
                  : "#FFFFFF"
              }
            />
          ) : (getValue(props, "country") === CountryEnum.peru ||
              CountryEnum.colombia) &&
            (getValue(props, "transactionType") ===
              BillingTransactionTypeEnum.PAYIN_COMMISSION ||
              getValue(props, "transactionType") ===
                BillingTransactionTypeEnum.PAYOUT_COMMISSION ||
              getValue(props, "transactionType") ===
                BillingTransactionTypeEnum.MINIMAL_COMMISSION) ? (
            <ModalHeader
              className={classes.modalHeader}
              highlightValue={getValue(props, "socialReason")}
              tittle={`Detalle de ${getTitle(
                getValue(props, "kind"),
                getValue(props, "invoiceMode")
              )}`}
              subtitle={"Tax ID"}
              secondaryValue={getValue(props, "taxId")}
              chipList={[
                {
                  background: "#F0F4F9",
                  color: "inherit",
                  value: getTransactionTypeObject(
                    getValue(props, "transactionType")
                  ).transactionType,
                },
                {
                  background:
                    getValue(props, "kind") === KindEnum.CREDIT_NOTE
                      ? setBackgroundColor(getValue(props, "status"))
                      : "#FFFFFF",
                  color:
                    getValue(props, "kind") === KindEnum.CREDIT_NOTE
                      ? setColor(getValue(props, "status"))
                      : "#FFFFFF",
                  value:
                    getValue(props, "kind") === KindEnum.CREDIT_NOTE
                      ? translate(getValue(props, "status"))
                      : undefined,
                },
              ]}
            />
          ) : getValue(props, "country") === CountryEnum.colombia &&
            (getValue(props, "transactionType") ===
              BillingTransactionTypeEnum.PAYIN_COMMISSION ||
              getValue(props, "transactionType") ===
                BillingTransactionTypeEnum.PAYOUT_COMMISSION ||
              getValue(props, "transactionType") ===
                BillingTransactionTypeEnum.MINIMAL_COMMISSION) ? (
            <ModalHeader
              className={classes.modalHeader}
              highlightValue={getValue(props, "socialReason")}
              tittle={`Detalle de ${getTitle(
                getValue(props, "kind"),
                get(props, "modalOverBilling.isMinAmountTrx", false)
              )}`}
              secondaryValue={`${translate(
                getValue(props, "cycle")
              )} ${formatDate(
                getValue(props, "startDate"),
                "dd-MM-yyyy"
              )} - ${formatDate(getValue(props, "endDate"), "dd-MM-yyyy")}`}
              chipList={[
                {
                  background: "#F0F4F9",
                  color: "inherit",
                  value: getTransactionTypeObject(
                    getValue(props, "transactionType")
                  ).transactionType,
                },
                {
                  background: setBackgroundColor(getValue(props, "status")),
                  color: setColor(getValue(props, "status")),
                  value: translate(getValue(props, "status")),
                },
              ]}
            />
          ) : (
            <ModalHeader
              className={classes.modalHeader}
              highlightValue={getValue(props, "socialReason")}
              tittle={`Detalle de ${getTitle(
                getValue(props, "kind"),
                get(props, "modalOverBilling.isMinAmountTrx", false)
              )}`}
              secondaryValue={`${translate(
                getValue(props, "cycle")
              )} ${formatDate(
                getValue(props, "startDate"),
                "dd-MM-yyyy"
              )} - ${formatDate(getValue(props, "endDate"), "dd-MM-yyyy")}`}
              statusValue={translate(getValue(props, "status"))}
              chipColor={setColor(getValue(props, "status"))}
              chipBackGround={setBackgroundColor(getValue(props, "status"))}
            />
          )
        }
        body={
          <ModalBodyTrxTabs
            trxInfo={props.modalOverBilling.trxBilling.trx}
            country={props.country}
            isMinAmount={props.modalOverBilling.isMinAmountTrx}
            handleChangeTab={handleChangeTab}
            valueTab={valueTab}
            isHistoric={false}
            statusFlow={props.statusFlow}
          />
        }
        footer={validateFooterModalOver(props, actions.openDialogsHandler)}
        openModal={props.modalOverBilling.openModalBilling}
        onCloseModal={props.modalOverBilling.handlerCloseModalTrxBilling}
        transition="left"
      />
      <AdvanceSettingsDialog
        onOpenAdvanceSettings={actions.handleOpenAdvanceSettings}
        open={selectProps.openAdvanceSettingsDialog}
        trxBilling={props.modalOverBilling.trxBilling.trx!}
        onOk={actions.addAdditionalData}
        country={props.country}
      />
      <InvoiceInfoDialog
        onOpenInvoiceInfo={actions.handleOpenInvoiceInfo}
        open={selectProps.openInvoiceInfoDialog}
        trxBilling={props.modalOverBilling.trxBilling.trx!}
        onOk={actions.addAdditionalData}
      />
    </React.Fragment>
  );
};

export const mapStateToProps: (
  state: IBillingDashboardState
) => DashboardTableStateProps = (
  state: IBillingDashboardState
): DashboardTableStateProps => ({
  billingData: state.billingData,
  isLoadingDownloadPreProcess: state.isLoadingFileDownloadDetail,
  transactionTableCollections: state.transactionTableCollections,
});

export const mapDispatchToProps: (
  dispatch: Dispatch
) => DashboardTableFunctionsProps = (
  dispatch: ThunkDispatch<IBillingDashboardState, undefined, BillingActions>
): DashboardTableFunctionsProps => ({
  orderBillingData: (payload: TransactionTable[]) => {
    dispatch(orderBillingData(payload));
  },
  setBillingTrxData: (payload: TransactionTable[]) => {
    dispatch(setBillingTrxData(payload));
  },
  setBillingDataRow: (billingDataRow: TransactionTable): void =>
    dispatch(setBillingDataRow(billingDataRow)),
  setBillingRow: (billingDataRow: TransactionTable): void =>
    dispatch(setBillingDataRow(billingDataRow)),
  downloadPreprocessFileWS: (params: DownloadDocumentParams) => {
    dispatch(downloadPreprocessFileWS(params));
  },
  transactionIdFilter: (): void => {
    dispatch(transactionIdFilter());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardTable);
