import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import { Avatar, Box, Divider, Typography, useTheme } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import kushkiLogo from "assets/logo.svg";
import kushkiLogoDark from "assets/kushki_logo_dark.svg";
import Chip from "@material-ui/core/Chip";
import { ChevronDown, LogOut, User } from "react-feather";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Theme } from "@material-ui/core/styles";
import { RoleService } from "../services/RoleService";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ButtonBase from "@material-ui/core/ButtonBase";
import { navigateToUrl } from "single-spa";
import { routes } from "../infrastructure/constants/Routes";
import { isNil, find, get } from "lodash";
import { IMerchantForm } from "../infrastructure/interfaces/IMerchantForm";
import { IRoleInfo } from "../infrastructure/interfaces/IRoleInfo";
import SupportSessionTimer from "./SupportSessionTimer";
import { INotification } from "../store/reducer";
import { COUNTRIES } from "../infrastructure/constants/Countries";
import { LanguageSelector } from "./LanguageSelector/LanguageSelector";

const styles = (theme: Theme, props: TopBarProps) => ({
  appBar: {
    zIndex: theme.zIndex.drawer,
    backgroundColor: props.roleInfo?.supportSessionActive
      ? theme.palette.primary.main
      : "white",
  },
  topBar: {
    background: "linear-gradient(268.59deg, #0093A2 -1.58%, #00E6B2 100.09%)",
    width: "100%",
    height: 6,
  },
  marginBar: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    marginLeft: 20,
  },
  chipModeTest: {
    marginLeft: 20,
    backgroundColor: props.roleInfo?.supportSessionActive
      ? "#F7FAFC"
      : theme.palette.primary.main,
    color: props.roleInfo?.supportSessionActive
      ? theme.palette.info.dark
      : "white",
    borderRadius: 4,
  },
  divider: {
    backgroundColor: props.roleInfo?.supportSessionActive
      ? "#F7FAFC"
      : theme.palette.primary.main,
    marginRight: 20,
    marginLeft: 20,
  },
  avatarCountry: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: 10,
  },
  chipAdmin: {
    marginRight: 10,
    backgroundColor: props.roleInfo?.supportSessionActive
      ? "#F7FAFC"
      : theme.palette.primary.light,
    color: props.roleInfo?.supportSessionActive
      ? theme.palette.primary.light
      : "white",
    borderRadius: 4,
  },
  avatarUser: {
    backgroundColor: props.roleInfo?.supportSessionActive
      ? "#F7FAFC"
      : theme.palette.primary.light,
    color: props.roleInfo?.supportSessionActive
      ? theme.palette.primary.light
      : "white",
    fontSize: 13,
    marginRight: 10,
  },
  titleUser: {
    color: props.roleInfo?.supportSessionActive
      ? "white"
      : theme.palette.primary.main,
    fontSize: 14,
  },
  chevronDownIcon: {
    color: props.roleInfo?.supportSessionActive
      ? "white"
      : theme.palette.primary.main,
    marginRight: 20,
    marginLeft: 10,
  },
  menuItem: {
    overflow: "hidden",
    fontSize: "1rem",
    minHeight: "48px",
    fontWeight: 400,
    lineHeight: 1.5,
    whiteSpace: "nowrap" as "nowrap",
    paddingLeft: 16,
    paddingRight: 16,
    width: "100%",
    display: "flex",
    position: "relative" as "relative",
    boxSizing: "border-box" as "border-box",
    textAlign: "left" as "left",
    alignItems: "center",
    paddingTop: 8,
    paddingBottom: 8,
    justifyContent: "flex-start",
    textDecoration: "none",
  },
  titleCountry: {
    whiteSpace: "nowrap" as "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    color: props.roleInfo?.supportSessionActive
      ? "white"
      : theme.palette.primary.main,
  },
  languageSelector: {
    marginRight: 10,
  },
});

interface TopBarProps {
  setActiveRoute: (path: string) => void;
  isProduction: boolean;
  merchantInfo: IMerchantForm | null | undefined;
  roleInfo: IRoleInfo | null | undefined;
  setNotification: (notify: INotification) => void;
  logoutSupportSessionRequest: (
    supportClose: object,
    closeBackoffice?: boolean
  ) => void;
}

const TopBar = (props: TopBarProps) => {
  const theme = useTheme();
  const inlineStyles = styles(theme, props);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (url: string) => {
    handleSelectedMenu(url);
    return navigateToUrl(url);
  };

  const handleSelectedMenu = (basePath: string) => {
    props.setActiveRoute(basePath);
  };

  const handlerLogout = (): void => {
    if (localStorage.getItem("authPayload") !== null) {
      props.setNotification({
        message:
          "No es posible realizar ninguna acción en modo Asistencia Remota",
        show: true,
        type: "error",
      });
      return;
    } else if (localStorage.getItem("accessCodeSupport") !== null) {
      props.logoutSupportSessionRequest(
        {
          accessCode: localStorage.getItem("accessCodeSupport"),
          isCompleted: false,
          messageCloseSession: "Sessión cerrada por parte de Comercio",
        },
        true
      );
      return;
    }
    RoleService.logout();
  };

  const renderMenu = () => {
    return (
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        elevation={1}
        getContentAnchorEl={null}
      >
        <MenuItem
          key="1"
          onClick={() => navigateToUrl(routes.PROFILE)}
          style={inlineStyles.menuItem}
        >
          <ListItemIcon>
            <User />
          </ListItemIcon>
          <Typography variant="inherit" color="textPrimary">
            Mi Comercio
          </Typography>
        </MenuItem>
        <MenuItem key="2" onClick={handlerLogout} style={inlineStyles.menuItem}>
          <ListItemIcon>
            <LogOut />
          </ListItemIcon>
          <Typography variant="inherit" color="textPrimary">
            Cerrar Sesión
          </Typography>
        </MenuItem>
      </Menu>
    );
  };

  return (
    <AppBar position="fixed" style={inlineStyles.appBar} elevation={0}>
      <div style={inlineStyles.topBar} />
      <Toolbar disableGutters>
        <Box style={inlineStyles.marginBar}>
          <ButtonBase>
            <img
              src={
                props.roleInfo?.supportSessionActive
                  ? kushkiLogoDark
                  : kushkiLogo
              }
              alt="Logo"
            />
          </ButtonBase>
          {!props.isProduction ? (
            <Chip
              style={inlineStyles.chipModeTest}
              size="small"
              label="Modo de Prueba"
            />
          ) : null}

          <Divider
            style={inlineStyles.divider}
            orientation="vertical"
            flexItem
            hidden={isNil(props.merchantInfo)}
          />
          <div hidden={isNil(props.merchantInfo)}>
            <Avatar
              src={get(
                find(COUNTRIES, {
                  value: props.merchantInfo?.country,
                }),
                "flag"
              )}
              style={inlineStyles.avatarCountry}
            />
          </div>
          <Typography
            variant="h6"
            color={"primary"}
            hidden={isNil(props.merchantInfo)}
            style={inlineStyles.titleCountry}
          >
            {props.merchantInfo?.name}
          </Typography>
        </Box>
        {props.roleInfo?.supportSessionActive && <SupportSessionTimer />}
        <LanguageSelector style={inlineStyles.languageSelector} />
        <ButtonBase onClick={handleMenu}>
          <Toolbar disableGutters>
            {!props.roleInfo?.supportSessionActive && (
              <Chip
                style={inlineStyles.chipAdmin}
                size="small"
                label="Admin"
                hidden={!props.roleInfo?.isAdmin}
              />
            )}
            {props.roleInfo?.userInitials !== "" && (
              <Avatar style={inlineStyles.avatarUser}>
                {props.roleInfo?.userInitials}
              </Avatar>
            )}
            <Typography
              variant="body1"
              color={"primary"}
              style={inlineStyles.titleUser}
            >
              {props.roleInfo?.fullName}
            </Typography>
            <ChevronDown style={inlineStyles.chevronDownIcon} />
          </Toolbar>
        </ButtonBase>
        {renderMenu()}
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
