export enum ErrorCodeEnum {
  AUT006 = "AUT006",
  AUT011 = "AUT011",
  ACCESS_CODE_ERROR = "ACCESS_CODE_ERROR",
  DEFAULT = "DEFAULT",
  VERIFY_ACCESS_CODE = "VERIFY_ACCESS_CODE",
  CONNECT_ACCESS_CODE = "CONNECT_ACCESS_CODE",
  ZENDESK_DEFAULT = "ZENDESK_DEFAULT",
}
export type ErrorCode = {
  code: string;
  message: string;
};

export const errorCodes: ErrorCode[] = [
  {
    code: ErrorCodeEnum.AUT006,
    message: "No es posible realizar ninguna acción en modo Asistencia Remota",
  },
  {
    code: ErrorCodeEnum.ACCESS_CODE_ERROR,
    message: "Lo sentimos, no se pudo crear el código de acceso para soporte.",
  },
  {
    code: ErrorCodeEnum.VERIFY_ACCESS_CODE,
    message:
      "Lo sentimos, no se pudo verificar el código de accesso, por favor inténtalo más tarde.",
  },
  {
    code: ErrorCodeEnum.CONNECT_ACCESS_CODE,
    message: "Lo sentimos, no se pudo realizar la conexión con el comercio",
  },
  {
    code: ErrorCodeEnum.AUT011,
    message: "No existe código de sesión",
  },
  {
    code: ErrorCodeEnum.DEFAULT,
    message: "¡Algo salió mal! No pudimos eliminar el usuario",
  },
  {
    code: ErrorCodeEnum.ZENDESK_DEFAULT,
    message: "¡Algo salió mal! Inténtelo de nuevo más tarde",
  },
];

export const mapErrors = (code: string, message?: string): string => {
  switch (code) {
    case ErrorCodeEnum.AUT006:
      return errorCodes[0].message;
    case ErrorCodeEnum.ACCESS_CODE_ERROR:
      return errorCodes[1].message;
    case ErrorCodeEnum.VERIFY_ACCESS_CODE:
      return `${errorCodes[2].message} ${message}`;
    case ErrorCodeEnum.CONNECT_ACCESS_CODE:
      return errorCodes[3].message;
    case ErrorCodeEnum.AUT011:
      return `${errorCodes[4].message}`;
    default:
      return errorCodes[errorCodes.length - 1].message;
  }
};
