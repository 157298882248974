import React from "react";
import { IconPlus, SplitButton } from "@kushki/connect-ui";
import { Box } from "@mui/material";
import { IAddProcessorButton } from "./CreateProcessorRoutes.interface";
import { ADD_PROCESSOR } from "../../../../shared/constants/labels/processors_form_labels";
import { createDropdownProcessorItem } from "../../../../shared/utils/processors/CreateProcessorsDropdownList";

export const AddProcessorButton: React.ElementType<IAddProcessorButton> = (
  props
) => {
  return (
    <Box display={"flex"} className={props.className}>
      <SplitButton
        onItemSelected={props.onItemSelected}
        items={createDropdownProcessorItem(
          props.publicMerchantId,
          props.country
        )}
        type={"accordionSingle"}
        variant={"secondaryOutlined"}
        size={"medium"}
        text={ADD_PROCESSOR}
        icon={<IconPlus />}
      />
    </Box>
  );
};
