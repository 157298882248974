import React from "react";
import { Box, Divider } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export const ConfigurationInformationSkeleton: React.FC = () => {
  return (
    <React.Fragment>
      <div>
        <Box pb={3}>
          <Skeleton variant="rect" width={"50%"} height={15} />
        </Box>
        <Box pb={3}>
          <Skeleton variant="rect" width={"50%"} height={15} />
        </Box>
        <Box pb={3}>
          <Skeleton variant="rect" width={"50%"} height={30} />
        </Box>
        <Box pb={3}>
          <br />
          <Divider />
        </Box>
        <Box pb={3}>
          <Skeleton variant="rect" width={"50%"} height={15} />
        </Box>
        <Box pb={3}>
          <Skeleton variant="rect" width={"50%"} height={15} />
        </Box>
        <Box pb={3}>
          <Skeleton variant="rect" width={"50%"} height={30} />
        </Box>
      </div>
    </React.Fragment>
  );
};
