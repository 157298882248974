import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { CountriesEnum } from "../../../shared/infrastructure/constants/CountriesEnum";
import { defaultTo, get, isArray } from "lodash";
import { auth } from "../../../shared/auth";
import { useEffect, useState } from "react";
import {
  getDeferredMerchant,
  updateDeferredStatusMerchant,
} from "../../../store/actionCreators";
import { DeferredOption } from "../../../../types/get_merchant_deferred_response";
import {
  ACTIVE_TEXTS,
  DEACTIVATE_TEXTS,
  DEFERRED_DEFAULT_VALUE,
  StatusDialogTexts,
} from "../../../shared/infrastructure/constants/CreateDeferredConstants";
import { StatusEnum } from "../../../shared/infrastructure/constants/statusEnum";

export interface IUseCreateDeferredState {
  country: string;
  name: string;
  merchantDeferred: DeferredOption;
  deferredStatus: boolean;
  dialogConfirm: {
    dialogTexts: StatusDialogTexts;
    handleCloseDialog: () => void;
    handleConfirmChangeStatusDeferred: () => void;
    openDialog: boolean;
  };
  handleClickSwitchStatus: () => void;
}

export const useCreateDeferredState = (): IUseCreateDeferredState => {
  const dispatch = useDispatch();
  const { merchantDeferred: merchantDeferredResponse, errorChangeStatus } =
    useSelector((state: IAppState) => state);
  const [merchantDeferred, setMerchantDeferred] = useState<DeferredOption>(
    DEFERRED_DEFAULT_VALUE
  );
  const [deferredStatus, setDeferredStatus] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [dialogTexts, setDialogTexts] =
    useState<StatusDialogTexts>(ACTIVE_TEXTS);
  const merchant = auth.getAuthMerchant();
  const country = get(merchant, "country", CountriesEnum.ECUADOR);

  useEffect(() => {
    dispatch(getDeferredMerchant());
  }, []);

  useEffect(() => {
    if (
      !isArray(merchantDeferredResponse) ||
      merchantDeferredResponse!.length === 0
    )
      return;

    setMerchantDeferred(merchantDeferredResponse![0]);
    if (country === CountriesEnum.PERU)
      setDeferredStatus(
        get(merchantDeferredResponse, "[0].status") !== StatusEnum.DISABLED
      );
  }, [merchantDeferredResponse]);

  useEffect(() => {
    if (errorChangeStatus?.status) {
      setDeferredStatus(!deferredStatus);
    }
  }, [errorChangeStatus]);

  const handleClickSwitchStatus = () => {
    setDialogTexts(deferredStatus ? DEACTIVATE_TEXTS : ACTIVE_TEXTS);
    setOpenDialog(true);
  };

  const handleConfirmChangeStatusDeferred = () => {
    setDeferredStatus(!deferredStatus);
    setOpenDialog(false);
    dispatch(
      updateDeferredStatusMerchant(
        country as CountriesEnum,
        merchantDeferred.id!,
        deferredStatus ? StatusEnum.DISABLED : StatusEnum.ENABLED,
        defaultTo(localStorage.getItem("merchantId"), "")
      )
    );
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return {
    country,
    deferredStatus,
    dialogConfirm: {
      dialogTexts,
      handleCloseDialog,
      handleConfirmChangeStatusDeferred,
      openDialog,
    },
    handleClickSwitchStatus,
    merchantDeferred,
    name: get(merchant, "name", ""),
  };
};
