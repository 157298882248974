import {
  isAllRefundSelectedSelector,
  refundsSelector,
  selectedRefundsSelector,
} from "../../../../../store/selectors/refunds/refunds.selectors";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/hooks/storeHook";
import {
  clearSelectedRefunds,
  selectAllRefunds,
  setIsAllSelectedRefunds,
} from "../../../../../store/actions/refunds/refunds";

export const useTableHeadState = () => {
  const dispatch = useAppDispatch();
  const isAllRefundSelected = useAppSelector(isAllRefundSelectedSelector);
  const selectedRefunds = useAppSelector(selectedRefundsSelector);
  const refunds = useAppSelector(refundsSelector);

  const handleChangeSelectAllRefundsTransactions = () => {
    if (isAllRefundSelected) {
      dispatch(clearSelectedRefunds());
      dispatch(setIsAllSelectedRefunds(false));

      return;
    } else {
      dispatch(selectAllRefunds());
      dispatch(setIsAllSelectedRefunds(true));

      return;
    }
  };

  return {
    handleChangeSelectAllRefundsTransactions,
    isAllRefundSelected,
    refunds,
    selectedRefunds,
  };
};
