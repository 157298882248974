import { useEffect, useState } from "react";
import { SnackbarAlertProps } from "../SnackBarAlert.interfaces";
import { SnackBarAlertState } from "./useSnackBarAlertState.interfaces";

export const useSnackBarAlertState = (
  props: SnackbarAlertProps
): SnackBarAlertState => {
  const [open, setOpen] = useState(props.open);

  useEffect(() => setOpen(props.open), [props.open]);

  return { open };
};
