import React from "react";
import { Box } from "@mui/material";
import { IWrappedTabs } from "./WrappedTabs.interfaces";
import TabItem from "./TabItem/TabItem";
import { wrappedTabsStyles as styles } from "./WrappedTabs.styles";
import { ITabItem } from "./TabItem/TabItem.interfaces";

const WrappedTabs: React.FC<IWrappedTabs> = ({ tabs }: IWrappedTabs) => {
  return (
    <Box sx={styles.container}>
      {tabs.map((tab: ITabItem) => {
        return (
          <TabItem
            key={tab.redirectPath}
            title={tab.title}
            status={tab.status}
            redirectPath={tab.redirectPath}
            isDisabled={tab.isDisabled}
          />
        );
      })}
    </Box>
  );
};

export default WrappedTabs;
