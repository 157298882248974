import { PersonTypeEnum } from "../../shared/constants/initial_state_legal_details";

export interface ILegalRepresentative {
  id: string;
  name: string;
  lastName: string;
  birthdate: string | number;
  type?: string;
  nationality: string;
  country: string;
  documentType: string;
  documentNumber?: string;
  email: string;
  countryCode?: string;
  areaCode?: string;
  cellphoneNumber: string;
  personType?: string;
  personCategory?: string;
  isShareHolder?: boolean | string;
  merchantAdress?: string;
  merchantCountry?: string;
  socialReason?: string;
  taxId?: string;
  participationPercentage?: string;
  constitutionDate?: string;
  uid?: string; // Omitir al guardar en la base
  isMain: boolean;
  residencyCountry?: string;
}

export interface IShareHolder {
  id: string;
  personType: string;
  socialReason?: string;
  taxId?: string;
  name?: string;
  lastName?: string;
  documentType?: string;
  documentNumber?: string;
  birthdate?: string; //Pasar a numerico al guardar en la base
  constitutionDate?: string; //Pasar a numerico al guardar en la base
  country: string;
  companyCountry?: string;
  personCategory: string;
  participationPercentage: string; //Pasar a numerico al guardar en la base
  parent?: string;
  riskStatus?: boolean;
  level: number;
  merchantCountry?: string;
  children: IShareHolder[];
  uid?: string; // Omitir al guardar en la base
}

export interface IMerchantPeopleNodeRequest {
  merchantId: string;
  merchantName: string;
  merchantStatus?: string;
  country: string;
  constitutionalCountry?: string;
  legalRepresentatives: ILegalRepresentativeRequest[];
  shareholders: IShareHolderRequest[];
  socialReason: ISocialReasonRequest;
}

export interface ISocialReasonRequest {
  id?: string;
  documentType?: PersonTypeEnum;
  personCategory?: PersonCategoryEnum;
  personType?: PersonTypeEnum;
  names?: string;
  lastNames?: string;
  socialReason?: string;
  documentNumber?: string;
}

export enum PersonCategoryEnum {
  REPRESENTATIVE = "REPRESENTATIVE",
  SHAREHOLDER = "SHAREHOLDER",
  SOCIAL_REASON = "SOCIAL_REASON",
  BOARD_MEMBER = "BOARD_MEMBER",
}

export interface ILegalRepresentativeRequest {
  id?: string;
  uid?: string;
  personCategory: PersonCategoryEnum;
  personType: PersonTypeEnum;
  isMain?: boolean;
  isShareHolder?: boolean;
  participationPercentage?: string;
  names?: string;
  lastNames?: string;
  nationality?: string;
  birthDate?: number;
  residencyCountry?: string;
  documentType?: string;
  documentNumber: string;
  email: string;
  cellphoneNumber: string;
  areaCode?: string;
  countryCode?: string;

  socialReason?: string;
  addressCountry?: string;
  originCountry?: string;
  constitutionDate?: number;
}

export interface IShareHolderRequest {
  id?: string;
  personCategory: string;
  personType: string;
  participationPercentage: string;
  names?: string;
  lastNames?: string;
  documentType?: string;
  documentNumber?: string;
  residencyCountry?: string;
  birthDate?: number;
  parent?: string;

  socialReason?: string;
  addressCountry?: string;
  originCountry?: string;
  constitutionDate?: number;
  uid?: string;
}
