import React from "react";
import { createStyles, Grid, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { StatusSteps } from "../../../../types/remote/semaphore_data";
import { RateSummary } from "./RateSectionSummary";
import { InvoiceConfigResume } from "./InvoiceConfigResume";
import { DiscountResume } from "./DiscountResume";
import { InvoiceConfigPending } from "./InvoiceConfigPending";
import { DiscountPending } from "./DiscountPending";
import { RateSectionPending } from "./RateSectionPending";
import { WalletPending } from "./WalletPending";
import { COUNTRY_WALLET } from "../../../shared/infrastructure/catalogs/CountryEnum";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/reducer";
import { get } from "lodash";
import { StatusSemaphore } from "../../../shared/infrastructure/constants/CreateMerchantConstants";

export interface ConfigRatesInvoiceProps {
  stepName: string;
  changeStepStatus: (step: string, status: StatusSteps) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containedButton: {
      fontSize: "16px",
    },
    button: {
      color: "#6D7781",
      fontSize: "16px",
    },
    container: {
      padding: theme.spacing(1, 1),
    },
  })
);

export const ConfigRatesAndInvoiceSection: React.FC<ConfigRatesInvoiceProps> = (
  _props: ConfigRatesInvoiceProps
) => {
  const classes = useStyles();
  const { statusProcessors, country } = useSelector((state: IAppState) => ({
    statusProcessors: get(
      state,
      "semaphore.stepProcessor.statusProcessor",
      StatusSemaphore.pending
    ),
    country: get(state, "merchantAffiliation.country", ""),
  }));

  return (
    <Grid
      container
      component={"div"}
      className={classes.container}
      spacing={2}
      justify="space-around"
    >
      <Grid item xs={12}>
        <InvoiceConfigPending />
        <InvoiceConfigResume />
      </Grid>
      <Grid item xs={12}>
        <RateSectionPending />
        <RateSummary />
      </Grid>
      <Grid item xs={12}>
        <DiscountPending />
        <DiscountResume />
      </Grid>
      {COUNTRY_WALLET.includes(country) &&
        statusProcessors === StatusSemaphore.complete && (
          <Grid item xs={12}>
            <WalletPending />
          </Grid>
        )}
    </Grid>
  );
};

export const ConfigRatesInvoiceSection: React.FC<any> = (
  _: string,
  _0: (step: string, status: StatusSteps) => void
) => (
  <React.Fragment>
    <ConfigRatesAndInvoiceSection changeStepStatus={_0} stepName={_} />
  </React.Fragment>
);
