import React from "react";
import { QRCodeCanvas } from "qrcode.react";
import { IQRCode } from "./QRCode.interfaces";
import { KushkiLogoEnum } from "../../../shared/enums/KushkiLogoEnum";

const QRCode = ({ url, size }: IQRCode) => {
  return (
    <QRCodeCanvas
      id="qrCode"
      value={url}
      size={size ?? 300}
      level={"Q"}
      imageSettings={{
        excavate: true,
        height: 40,
        src: KushkiLogoEnum.primary,
        width: 100,
      }}
    />
  );
};

export { QRCode };
