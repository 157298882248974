import * as React from "react";
import { styled, SvgIcon as MuiSvgIcon, SvgIconProps } from "@mui/material";

const SvgIcon = styled(MuiSvgIcon, {
  name: "IconCreditCard",
  shouldForwardProp: (prop) => prop !== "fill",
})<SvgIconProps>(({ color }) => ({
  fill: color + "!important",
  fontSize: "22px !important",
}));

SvgIcon.defaultProps = {
  viewBox: "0 0 24 24",
  focusable: "false",
  "aria-hidden": "true",
};

const IconCreditCardLocal: React.FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={"creditCardIcon"}
    >
      <path
        d="M19 19.5H5C3.93913 19.5 2.92172 19.0786 2.17157 18.3284C1.42143 17.5783 1 16.5609 1 15.5V8.5C1 7.43913 1.42143 6.42172 2.17157 5.67157C2.92172 4.92143 3.93913 4.5 5 4.5H19C20.0609 4.5 21.0783 4.92143 21.8284 5.67157C22.5786 6.42172 23 7.43913 23 8.5V15.5C23 16.5609 22.5786 17.5783 21.8284 18.3284C21.0783 19.0786 20.0609 19.5 19 19.5ZM5 6.5C4.46957 6.5 3.96086 6.71071 3.58579 7.08579C3.21071 7.46086 3 7.96957 3 8.5V15.5C3 16.0304 3.21071 16.5391 3.58579 16.9142C3.96086 17.2893 4.46957 17.5 5 17.5H19C19.5304 17.5 20.0391 17.2893 20.4142 16.9142C20.7893 16.5391 21 16.0304 21 15.5V8.5C21 7.96957 20.7893 7.46086 20.4142 7.08579C20.0391 6.71071 19.5304 6.5 19 6.5H5Z"
        fill="#023365"
      />
      <path
        d="M10 15.5H6C5.73478 15.5 5.48043 15.3946 5.29289 15.2071C5.10536 15.0196 5 14.7652 5 14.5C5 14.2348 5.10536 13.9804 5.29289 13.7929C5.48043 13.6054 5.73478 13.5 6 13.5H10C10.2652 13.5 10.5196 13.6054 10.7071 13.7929C10.8946 13.9804 11 14.2348 11 14.5C11 14.7652 10.8946 15.0196 10.7071 15.2071C10.5196 15.3946 10.2652 15.5 10 15.5Z"
        fill="#023365"
      />
      <path
        d="M16 15.5H13C12.7348 15.5 12.4804 15.3946 12.2929 15.2071C12.1054 15.0196 12 14.7652 12 14.5C12 14.2348 12.1054 13.9804 12.2929 13.7929C12.4804 13.6054 12.7348 13.5 13 13.5H16C16.2652 13.5 16.5196 13.6054 16.7071 13.7929C16.8946 13.9804 17 14.2348 17 14.5C17 14.7652 16.8946 15.0196 16.7071 15.2071C16.5196 15.3946 16.2652 15.5 16 15.5Z"
        fill="#023365"
      />
    </SvgIcon>
  );
};

export default IconCreditCardLocal;
