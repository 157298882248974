import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import React, { useState } from "react";
import {
  DeferredOptionMerchant,
  UpdateDeferredMerchantRequest,
  VariantDeferredOption,
} from "../../../../types/update_deferred_merchant_request";
import { auth } from "../../../shared/auth";
import { defaultTo, get, indexOf, set } from "lodash";
import { CountriesEnum } from "../../../shared/infrastructure/constants/CountriesEnum";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsDeferredLoaded,
  setNotification,
  updateDeferredMerchant,
} from "../../../store/actionCreators";
import { TDeferred } from "../../../shared/infrastructure/interfaces/TDeferred";
import { IAppState } from "../../../store/reducer";
import { hasValidVariants } from "../../../shared/utils/hasValidVariants";

export interface IEcuadorSectionState {
  canAddVariant: boolean;
  banksModal: string[];
  type: string;
  months: string[];
  banks: IBank[];
  bankListName: string[];
  merchantMonths: string[];
  merchantMonthsOfGrace: string[];
  monthsOfGrace: string[];
  handleBankWebModal: (banks: string[]) => void;
  bankModalWebIsOpen: boolean;
  handleCancel(): void;
  handleSetMonth(
    event: React.MouseEvent<HTMLElement>,
    value: string[],
    index: number
  ): void;
  handleSetMonthOfGrace(
    event: React.MouseEvent<HTMLElement>,
    value: string[],
    index: number
  ): void;
  handleSetBank(name: string, index: number): void;
  handleSetVariant(): void;
  handleDeleteVariant(id: number): void;
  handleDeleteBank(name: string, index: number): void;
  handleSave(): void;
  handleOpenConfirmModal(): void;
  handleCloseConfirmModal(): void;
  isOpenConfirmModal: boolean;
  variants: VariantDeferredOption[];
}

export interface IBank {
  checked: boolean;
  label: string;
  index?: number;
}

export interface ILocationStateEc {
  entity?: string;
  status?: string;
  processorName?: string;
  id: string;
  deferredType: string[];
  deferredName?: string[];
  months: string[];
  merchantMonths: string[];
  merchantStatus: string;
  bank?: string[];
  monthsOfGrace: string[];
  merchantMonthsOfGrace: string[];
  variants?: VariantDeferredOption[];
  type: string;
  bins?: string[];
  merchantBank?: string[];
  allDeferred: TDeferred[];
}

const getBanks = (
  banks: string[],
  variants?: VariantDeferredOption[]
): IBank[] => {
  if (banks === undefined) return [];
  if (variants === undefined)
    return banks.map((bank) => ({
      checked: false,
      index: undefined,
      label: bank,
    }));

  let bankList: IBank[] = banks
    .sort()
    .map((bank) => ({ checked: false, index: undefined, label: bank }));

  variants.forEach((variants, indexVariant) => {
    variants.bank?.forEach((bank) => {
      const index: number = banks.indexOf(bank);

      if (index >= 0) {
        bankList[index].checked = true;
        bankList[index].index = indexVariant;
      }
    });
  });

  return bankList;
};

const getBankListWeb = (bankList: IBank[]): string[] => {
  const newBankList: string[] = [];

  bankList.forEach((item) => {
    if (!item.checked) newBankList.push(item.label);
  });

  return newBankList.sort();
};

export const useEcuadorSection = (): IEcuadorSectionState => {
  const history = useHistory();
  const allDeferred = useSelector((state: IAppState) => state.merchantDeferred);
  const dispatch = useDispatch();
  const location = useLocation<ILocationStateEc>();
  const {
    entity,
    status,
    processorName,
    deferredName,
    deferredType,
    merchantBank,
    bins,
    id,
    merchantMonths,
    merchantMonthsOfGrace,
    monthsOfGrace,
    type,
    months,
    bank,
    variants,
  } = location.state;

  const withMonthsOfGrace = merchantMonthsOfGrace.length !== 0;

  const [bankList, setBankList] = React.useState<IBank[]>(
    getBanks(bank!, variants)!
  );

  const [availableBanks, setAvailableBanks] = useState<number>(
    bankList.filter((bank) => !bank.checked && bank.index === undefined).length
  );

  const handleAvailableBanks = () => {
    const checkedBanks = bankList.filter((bank) => bank.index === undefined);
    const numAvailableBanks = defaultTo(checkedBanks.length, 0);

    setAvailableBanks(numAvailableBanks);
  };

  const [bankListName, setBankListWeb] = React.useState<string[]>(
    getBankListWeb(bankList)
  );

  const [merchantVariants, setMerchantVariants] = React.useState<
    VariantDeferredOption[]
  >(variants !== undefined ? variants : []);

  const [bankModalWebIsOpen, setBankModalWebIsOpen] = useState<boolean>(false);
  const [banksModal, setBanksModal] = useState<string[]>(["bank1", "bank2"]);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);

  const canAddVariant =
    availableBanks > 0 &&
    hasValidVariants(merchantVariants, {
      bank: true,
      month: false,
      monthOfGrace: false,
    });

  const handleSetMonthOfGrace = (
    _event: React.MouseEvent<HTMLElement>,
    value: string[],
    index: number
  ) => {
    const data: VariantDeferredOption[] = [...merchantVariants];

    data[index].monthsOfGrace = value;
    setMerchantVariants(data);
  };

  const merchant = auth.getAuthMerchant();
  const country = get(merchant, "country", CountriesEnum.ECUADOR);

  const handleBankWebModal = (deferredBanks: string[]) => {
    setBanksModal(deferredBanks.filter((_bank, index) => index > 9));
    bankModalWebIsOpen
      ? setBankModalWebIsOpen(false)
      : setBankModalWebIsOpen(true);
  };

  const handleSetMonth = (
    _event: React.MouseEvent<HTMLElement>,
    value: string[],
    index: number
  ) => {
    const data: VariantDeferredOption[] = [...merchantVariants];

    data[index].months = value;
    setMerchantVariants(data);
  };

  const handleSetBank = (name: string, index: number) => {
    const data: VariantDeferredOption[] = [...merchantVariants];
    const dataBankList: IBank[] = [...bankList];

    const bankSelected = bankList.find((bank) => name === get(bank, "label"));

    const indexBankSelected = indexOf(bankList, bankSelected);

    bankList[indexBankSelected].checked = !bankList[indexBankSelected].checked;

    bankList[indexBankSelected].index =
      bankList[indexBankSelected].index === undefined ? index : undefined;

    setBankList(bankList);

    const indexTemp = data[index].bank!.indexOf(name);

    indexTemp >= 0
      ? (data[index].bank = data[index].bank!.filter((e) => e !== name))
      : data[index].bank!.push(name);

    setMerchantVariants(data);

    setBankListWeb(
      getBankListWeb(dataBankList.filter((bank) => bank.label !== name))
    );

    handleAvailableBanks();
  };

  const handleSetVariant = () => {
    setMerchantVariants([
      ...merchantVariants,
      {
        bank: [],
        months: merchantMonths!,
        monthsOfGrace: merchantMonthsOfGrace!,
      },
    ]);
  };

  const handleDeleteVariant = (id: number) => {
    let banks: IBank[] = [...bankList];

    banks = banks.map((bank) => {
      if (bank.index === id) {
        set(bank, "checked", false);
        set(bank, "index", undefined);
      } else if (bank.index !== undefined && bank.index > id) {
        set(bank, "index", bank.index - 1);
      }

      return bank;
    });

    setMerchantVariants(
      merchantVariants.filter((_item, index) => index !== id)
    );
    setBankList(banks);
    setBankListWeb(getBankListWeb(banks));
    handleAvailableBanks();
  };

  const handleDeleteBank = (name: string, index: number) => {
    const data: VariantDeferredOption[] = [...merchantVariants];

    const bankDelete = bankList.find((bank) => name === get(bank, "label"));

    const indexBankDelete = indexOf(bankList, bankDelete);

    if (indexBankDelete >= 0) {
      bankList[indexBankDelete].checked = !bankList[indexBankDelete].checked;
      bankList[indexBankDelete].index = undefined;
    }

    setBankList(bankList);
    setBankListWeb(getBankListWeb(bankList));

    data[index].bank = data[index].bank!.filter(
      (bank: string) => bank !== name
    );
    setMerchantVariants(data);
    handleAvailableBanks();
  };

  const handleCancel = () => {
    dispatch(setIsDeferredLoaded(false));
    history.goBack();
  };

  const handleOpenConfirmModal = () => {
    setConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setConfirmModal(false);
  };

  const handleSave = () => {
    const merchantDeferred: UpdateDeferredMerchantRequest = {
      country,
      deferredOptions: [
        {
          bank,
          bins,
          deferredName,
          deferredType,
          entity,
          id,
          merchantBank,
          merchantMonths,
          merchantMonthsOfGrace,
          months,
          monthsOfGrace,
          processorName,
          status,
          variants: merchantVariants,
        },
      ],
    };

    if (
      !hasValidVariants(
        get(merchantDeferred, "deferredOptions[0].variants", []),
        {
          bank: true,
          month: true,
          monthOfGrace: withMonthsOfGrace,
        }
      )
    ) {
      dispatch(
        setNotification({
          message: withMonthsOfGrace
            ? "Todas las variantes deben tener almenos un banco, una mensualidad  y un periodo de gracia seleccionados."
            : "Todas las variantes deben tener almenos un banco y una mensualidad seleccionados.",
          open: true,
          type: "error",
        })
      );
    } else {
      merchantDeferred.deferredOptions = allDeferred!.map((deferred) =>
        deferred.id === id ? merchantDeferred.deferredOptions[0] : deferred
      ) as DeferredOptionMerchant[];
      dispatch(updateDeferredMerchant(merchantDeferred));
      handleCancel();
      handleCloseConfirmModal();
    }
  };

  return {
    bankListName: bankListName,
    bankModalWebIsOpen,
    banks: bankList!,
    banksModal,
    canAddVariant,
    handleBankWebModal,
    handleCancel,
    handleCloseConfirmModal,
    handleDeleteBank,
    handleDeleteVariant,
    handleOpenConfirmModal,
    handleSave,
    handleSetBank,
    handleSetMonth,
    handleSetMonthOfGrace,
    handleSetVariant,
    isOpenConfirmModal: confirmModal,
    merchantMonths,
    merchantMonthsOfGrace,
    months,
    monthsOfGrace,
    type,
    variants: merchantVariants,
  };
};
