export enum HeaderSection {
  COUNTRY_OPERATION = "País de operación:",
  CUSTOMER_ID = "Customer ID:",
  ID = "ID:",
  SUB_TITLE_CREDENTIALS = "Desde aquí puedes crear, renovar, desactivar y crear tus credenciales.",
  SUB_TITLE_CREDENTIALS_CUSTOMER = "Desde aqui puedes crear, renovar, desactivar y crear tus credenciales.",
  TITLE_CREDENTIALS = "Credenciales",
}

export enum Country {
  CHILE = "Chile",
  COLOMBIA = "Colombia",
  ECUADOR = "Ecuador",
  MEXICO = "Mexico",
  PERU = "Peru",
}

export enum GeneralAction {
  ADD_CREDENTIAL = "Agregar Credencial",
  RETURN = "Regresar",
}

export enum NotificationResultEnum {
  REQUEST_ERROR = "REQUEST_ERROR",
  REQUEST_SUCCESS = "REQUEST_SUCCESS",
  COPY_LINK = "COPY_LINK",
  NO_ACTION = "NO_ACTION",
}
