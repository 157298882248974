import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export interface ITransactionContainer {
  labels: [string, string][];
}

const useStyles = makeStyles(() => ({
  scroll: {
    marginBottom: "20px",
  },
  container: {
    paddingTop: "12px",
    paddingBottom: "20px",
    marginTop: "20px",
    background: "#F7FAFC",
    borderRadius: "8px",
    borderBottom: "0px !important",
  },
  label: {
    color: "#293036 !important",
    lineHeight: "170% !important",
    fontStyle: "normal !important",
    fontWeight: "normal !important" as "normal",
    fontSize: "16px !important",
    textTransform: "none" as "none",
  },
  subtitle: {
    paddingTop: "8px !important",
    color: "#6D7781 !important",
    fontSize: "14px !important",
  },
  title: {
    paddingTop: "8px !important",
    fontSize: "14px !important",
    color: "#293036",
  },
  paper: {
    padding: "0 !important",
    backgroundColor: "#F7FAFC !important",
    borderRadius: 2,
    boxShadow:
      "0px 0px 0px rgba(0,0,0,0), 0px 0px 0px rgba(0,0,0,0) !important",
  },
  gridContainer: {
    "&..MuiContainer-root": {
      padding: "0 !important",
    },
    display: "block",
    position: "relative",
  },
  positionButton: {
    right: "-23px",
    position: "relative",
  },
  copyButton: {
    "&.MuiIconButton-root": {
      backgroundColor: "#FFFFFF",
      borderColor: "#E2E8F0",
      borderStyle: "solid",
      borderWidth: 1,
      borderRadius: "0px 8px",
    },
  },
  paperContainer: {
    borderRadius: "8px",
    border: "0px !important",
    boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 20%) !important",
  },
}));

export const TransactionContainer: React.FC<ITransactionContainer> = (
  props: ITransactionContainer
) => {
  const classes = useStyles();
  const { labels } = props;

  return (
    <Container className={classes.container}>
      <Grid container>
        {labels.map((label, index) => {
          return (
            <React.Fragment key={index}>
              <Grid item xs={4}>
                <Typography variant="body2" className={classes.subtitle}>
                  {label[0]}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h6" className={classes.title}>
                  {label[1]}
                </Typography>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </Container>
  );
};

export default TransactionContainer;
