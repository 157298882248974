import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const dashboardComponentStyles = createNamedStyles({
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: 1,
    rowGap: (theme) => theme.spacing(4),
  },
});
