export enum ActionTypes {
  SET_MERCHANT = "[CONFIGURATION] SET MERCHANT",
  SET_RATE_MERCHANT = "[CONFIGURATION] SET_RATE_MERCHANT",
  SET_BILLING_CONFIG = "[CONFIGURATION] SET BILLING CONFIG",
  SET_AUTH_INFO = "[CONFIGURATION] SET AUTH INFO",
  SET_MERCHANT_RESPONSE = "[CONFIGURATION] SET MERCHANT RESPONSE",
  SET_BILLING_CONFIG_RESPONSE = "[CONFIGURATION] SET BILLING CONFIG RESPONSE",
  SET_NOTIFICATION = "[CONFIGURATION] SET_NOTIFICATION",
  SET_DISCOUNT_INFO = "[CONFIGURATION] SET_DISCOUNT_INFO",
  SET_WEBHOOKS = "[CONFIGURATION] SET_WEBHOOKS",
  SET_CUSTOMIZATION = "[CONFIGURATION] SET_CUSTOMIZATION",
  SET_WEB_CHECKOUT = "[CONFIGURATION] SET_WEBCHECKOUT",
  SET_IS_LOADING = "[CONFIGURATION] SET_IS_LOADING",
  SET_LOADING = "[CONFIGURATION] SET_LOADING",
  SET_LOADING_CONFIG = "[CONFIGURATION] SET_LOADING_CONFIG",
  SET_SMARTLINK_INFO = "[CONFIGURATION] SET_SMARTLINK_INFO",
  SET_SUBDOMAIN_CONFIRMATION = "[CONFIGURATION] SET_SUBDOMAIN_CONFIRMATION",
  SET_IS_WEBCHECKOOUT_LOADED = "[CONFIGURATION] SET_WEBCHECKOUT_LOADED",
  SET_PROCESSORS = "[CONFIGURATION] SET_PROCESSORS",
  SET_USER_LIST = "[USERS] SET_USER_LIST",
  SET_WAITING_FOR_REQUEST = "[CONFIGURATION] SET_WAITING_FOR_REQUEST",
  SET_TRX_RATES = "[CONFIGURATION] SET_TRX_RATES",
  SET_MERCHANT_PROCESSORS = "[CONFIGURATION] SET_MERCHANT_PROCESSORS",
  SET_IS_LOADING_GET_MERCHANT_PROCESSORS = "[CONFIGURATION] SET_IS_LOADING_GET_MERCHANT_PROCESSORS",
  SET_DEFAULT_PROCESSOR = "[CONFIGURATION] SET_DEFAULT_PROCESSOR",
  SET_IS_LOADING_DEFAULT_PROCESSOR = "[CONFIGURATION] SET_IS_LOADING_DEFAULT_PROCESSOR",
  SET_RULES = "[CONFIGURATION] SET_RULES",
  SET_DEFERRED = "[CONFIGURATION] SET_DEFERRED",
  SET_IS_DEFERRED_LOADED = "[CONFIGURATION] SET_DEFERRED_LOADED",
  SET_PAYMENT_CREDENTIALS = "[CONFIGURATION] SET_PAYMENT_CREDENTIALS",
  SET_IS_LOADING_PAYMENT_CREDENTIALS = "[CONFIGURATION] SET_IS_LOADING_PAYMENT_CREDENTIALS",
  SET_WEBHOOK_LIST = "[CONFIGURATION] SET_WEBHOOK_LIST",
  SET_IS_LOADING_WEBHOOK_LIST = "[CONFIGURATION] SET_IS_LOADING_WEBHOOK_LIST",
  SET_MERCHANT_REQUEST = "[CONFIGURATION] SET MERCHANT REQUEST",
  SET_CARD_OPTIONS_REQUEST = "[CONFIGURATION] SET_CARD_OPTIONS_REQUEST",
  SET_WEBHOOK_OPTIONS_REQUEST = "[CONFIGURATION] SET_WEBHOOK_OPTIONS_REQUEST",
  SET_VPOS_OPTIONS_REQUEST = "[CONFIGURATION] SET REQUEST",
  SET_WEBCHECKOUT_OPTIONS_REQUEST = "[CONFIGURATION] SET_VPOS_OPTIONS_REQUEST",
  SET_CASH_OPTIONS_REQUEST = "[CONFIGURATION] SET_CASH_OPTIONS_REQUEST",
  SET_COMISSION_OPTIONS_REQUEST = "[CONFIGURATION] SET_COMISSION_OPTIONS_REQUEST",
  SET_SUBSCRIPTION_OPTIONS_REQUEST = "[CONFIGURATION] SET_SUBSCRIPTION_OPTIONS_REQUEST",
  SET_IMAGE_SELECTED = "[CONFIGURATION] SET_IMAGE_SELECTED",
  SET_IS_IMAGE_SELECTED = "[CONFIGURATION] SET_IS_IMAGE_SELECTED",
  SET_WALLET_RECHARGE_CONFIGURATION = "[CONFIGURATION] SET_WALLET_RECHARGE_CONFIGURATION",
  SET_APPCONFIG_PAYMENT_METHODS = "[CONFIGURATION] SET_APPCONFIG_PAYMENT_METHODS",
}
