import { RuleItem } from "../../../types/get_business_rules_response";
import { StatusStepEnum } from "../enums/StatusStepEnum";
import { StatusRuleEnum } from "../enums/StatusRuleEnum";
import { Branch, MassiveRule } from "../../../types/massive_rules_request";
import { defaultTo, get } from "lodash";
import { getBranchEditListStorage } from "./sessionStorage";
import { PayoutRule } from "../../../types/get_payouts_business_rules_response";

export const getBusinessRulesStatusStep = (
  rules: RuleItem[],
  payoutRules: PayoutRule[]
): StatusStepEnum => {
  if (isRuleCompletedStep(rules) && isRuleCompletedStep(payoutRules)) {
    return StatusStepEnum.COMPLETE;
  }
  if (isRulePendingStep(rules) || isRulePendingStep(payoutRules)) {
    return StatusStepEnum.PENDING;
  }
  return StatusStepEnum.IN_PROCESS;
};

export const getProcessorsStatusStep = (): StatusStepEnum => {
  // TODO: add logic here to change status of processors change from this file
  return StatusStepEnum.COMPLETE;
};

export const getDeferredStatusStep = (): StatusStepEnum => {
  // TODO: add logic here to change status of processors change from this file
  return StatusStepEnum.COMPLETE;
};

const isRuleCompletedStep = <T>(rules: T[]): boolean => {
  return rules.every((rule) => get(rule, "status") == StatusRuleEnum.ENABLED);
};

export const isRulePendingStep = (rules: { [k: string]: any }[]): boolean => {
  return rules.some((rule) => rule.status == StatusRuleEnum.DRAFT);
};

export const containsEnabledRule = <T>(rules: T[]): boolean => {
  return rules.some((rule) => get(rule, "status") == StatusRuleEnum.ENABLED);
};

export const existPendingRulesSent = <T>(rules: T[]): boolean => {
  return rules.some((rule) => !get(rule, "isSent", false));
};

export const filterPendingRules = <T>(rules: T[]): T[] => {
  return rules.filter((rule) => !get(rule, "isSent", false));
};

export const getMassiveRule = (rules: RuleItem[]): MassiveRule[] => {
  return filterPendingRules<RuleItem>(rules).map((rule) => ({
    alias: defaultTo(rule.rule.name, ""),
    payload: rule,
  }));
};

export const arePendingRules = <T>(rules: T[]): boolean => {
  return rules.length > 0 && existPendingRulesSent<T>(rules);
};

export const getBranches = (): Branch[] => {
  const { data } = getBranchEditListStorage();
  return defaultTo(data, []).map((branch) => ({
    branchId: defaultTo(branch.merchant_id, ""),
    name: defaultTo(branch.name, ""),
  }));
};
