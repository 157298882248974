export enum UserFormLabel {
  EDIT_USER = "Editar usuario",
  CREATE_USER = "Agregar usuario",
  LOADER_TITLE = "Estamos agregando tu usuario",
  LOADER_BODY = "Por favor, espera...",
  MODAL_TITLE = "¿Deseas regresar a la sección anterior?",
  MODAL_CANCEL = "Cancelar",
  MODAL_NEXT = "Continuar",
  MODAL_BODY = "Si sales de este formulario, la información ingresada no se guardará. Te recomendamos guardar los datos antes de\n" +
    "regresar a la sección anterior.",
}

export enum FooterNewConsoleEnum {
  SAVE = "Guardar",
  ADD_USER = "Agregar Usuario",
}
