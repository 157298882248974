import * as React from "react";
import { FC } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Chip } from "@mui/material";
import { IModalSimpleSecurityProps } from "./ModalSimpleSecurity.interfaces";
import { ModalSimpleSecurityStyles } from "./ModalSimpleSecurity.styles";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { get } from "lodash";

export const ModalSimpleSecurity: FC<IModalSimpleSecurityProps> = ({
  buttonPrimary,
  buttonSecondary,
  descriptionText,
  isOpen,
  onClose,
  titleText,
  typeModal,
  tagText,
  tagColor,
}: IModalSimpleSecurityProps) => {
  return (
    <>
      <div>
        <Modal
          open={isOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid sx={ModalSimpleSecurityStyles.modalContainer}>
            <Box className="modalHeader">
              <Grid container className="itemsTop">
                <Box className="headerIconsContainer">
                  <Typography
                    id="modal-modal-title"
                    variant="h3"
                    color="primary"
                    sx={{
                      flex: "1",
                    }}
                  >
                    {titleText}{" "}
                    {!!tagText && (
                      <Chip
                        label={tagText}
                        color={tagColor}
                        className="titleTag"
                        data-testid={"security-chip"}
                      />
                    )}
                  </Typography>
                </Box>
                <Grid item height={36} width={36}>
                  {typeModal === 1 && (
                    <IconButton
                      color="secondary"
                      onClick={onClose}
                      size="small"
                      data-testid={"security-iconbtn"}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
              <Grid className="itemsBottom">
                <Typography
                  id="modal-modal-description"
                  variant="body2"
                  color="text.dark"
                >
                  {descriptionText}
                </Typography>
              </Grid>
            </Box>
            <Box className="modalFooter">
              <Grid container className="itemsButtons">
                {typeModal === 1 && (
                  <ComponentSecurityWrapper
                    componentId={get(buttonSecondary, "securityRol", "")}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={get(buttonSecondary, "action")}
                      disabled={get(buttonSecondary, "isDisabled", false)}
                      sx={{ mr: 2 }}
                      data-testid={"secondary-btn-security"}
                    >
                      {get(buttonSecondary, "text")}
                    </Button>
                  </ComponentSecurityWrapper>
                )}
                <ComponentSecurityWrapper
                  componentId={get(buttonPrimary, "securityRol", "")}
                >
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={buttonPrimary.action}
                    disabled={get(buttonPrimary, "isDisabled", false)}
                    data-testid={"primary-btn-security"}
                  >
                    {buttonPrimary.text}
                  </Button>
                </ComponentSecurityWrapper>
              </Grid>
            </Box>
          </Grid>
        </Modal>
      </div>
    </>
  );
};
