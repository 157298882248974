import { CountriesEnum } from "../enums/CountriesEnum";
import { CatalogEc } from "../services/catalog-ec";
import { CatalogBr } from "../services/catalog-br";
import { CatalogCl } from "../services/catalog-cl";
import { CatalogCo } from "../services/catalog-co";
import { CatalogCr } from "../services/catalog-cr";
import { CatalogEl } from "../services/catalog-el";
import { CatalogMx } from "../services/catalog-mx";
import { CatalogPa } from "../services/catalog-pa";
import { CatalogPe } from "../services/catalog-pe";
import { CurrencyEnum } from "../enums/CurrencyEnum";
import { get } from "lodash";
import { IPaymentMethodObject } from "../infrastructure/interface/IPaymentMethodObject";
import { PaymentMethodEnum } from "../enums/PaymentMethodEnum";
import { PaymentMethodTypeEnum } from "../enums/PaymentMethodTypeEnum";
import { StatusTypeEnum } from "../enums/StatusTypeEnum";

export const CURRENCY_COUNTRY: Map<string, string> = new Map()
  .set(`${CountriesEnum.COLOMBIA}`, CurrencyEnum.COP)
  .set(`${CountriesEnum.ECUADOR}`, CurrencyEnum.USD)
  .set(`${CountriesEnum.PERU}`, CurrencyEnum.PEN)
  .set(`${CountriesEnum.CHILE}`, CurrencyEnum.CLP)
  .set(`${CountriesEnum.MEXICO}`, CurrencyEnum.MXN)
  .set(`${CountriesEnum.BRAZIL}`, CurrencyEnum.BRL)
  .set(`${CountriesEnum.EL_SALVADOR}`, CurrencyEnum.USD)
  .set(`${CountriesEnum.PANAMA}`, CurrencyEnum.PAB)
  .set(`${CountriesEnum.COSTA_RICA}`, CurrencyEnum.CRC);

export interface Category {
  name: string;
  value: string | number;

  [k: string]: any;
}

export const getCatalog = (country: string, catalog: string) => {
  switch (country) {
    case CountriesEnum.ECUADOR:
      return get(CatalogEc, catalog, []);
    case CountriesEnum.BRAZIL:
      return get(CatalogBr, catalog, []);
    case CountriesEnum.CHILE:
      return get(CatalogCl, catalog, []);
    case CountriesEnum.COLOMBIA:
      return get(CatalogCo, catalog, []);
    case CountriesEnum.COSTA_RICA:
      return get(CatalogCr, catalog, []);
    case CountriesEnum.EL_SALVADOR:
      return get(CatalogEl, catalog, []);
    case CountriesEnum.MEXICO:
      return get(CatalogMx, catalog, []);
    case CountriesEnum.PANAMA:
      return get(CatalogPa, catalog, []);
    case CountriesEnum.PERU:
      return get(CatalogPe, catalog, []);
    default:
      return [];
  }
};

export const CARD_NAME_TRANSLATE = "Tarjeta de Crédito";
export const CASH_NAME_TRANSLATE = "Efectivo";
export const TRANSFER_NAME_TRANSLATE = "Transferencia";
export const NOTCHARGE = "notCharge";

export const PaymentMethodObject: IPaymentMethodObject = {
  card: {
    icon: "iconCard",
    name: PaymentMethodEnum.CARD,
    processorType: "",
    status: StatusTypeEnum.INACTIVE,
    translatedName: CARD_NAME_TRANSLATE,
    type: PaymentMethodTypeEnum.FUNDRAISING,
  },
  cash: {
    icon: "iconCash",
    name: PaymentMethodEnum.CASH,
    processorType: "",
    status: StatusTypeEnum.INACTIVE,
    translatedName: CASH_NAME_TRANSLATE,
    type: PaymentMethodTypeEnum.FUNDRAISING,
  },
  payouts_card: {
    icon: "iconCard",
    name: PaymentMethodEnum.CARD,
    processorType: "",
    status: StatusTypeEnum.INACTIVE,
    translatedName: CARD_NAME_TRANSLATE,
    type: PaymentMethodTypeEnum.DISPERSION,
  },
  payouts_cash: {
    icon: "iconCash",
    name: PaymentMethodEnum.PAYOUTS_CASH,
    processorType: "",
    status: StatusTypeEnum.INACTIVE,
    translatedName: CASH_NAME_TRANSLATE,
    type: PaymentMethodTypeEnum.DISPERSION,
  },
  payouts_transfer: {
    icon: "iconTransfer",
    name: PaymentMethodEnum.PAYOUTS_TRANSFER,
    processorType: "",
    status: StatusTypeEnum.INACTIVE,
    translatedName: TRANSFER_NAME_TRANSLATE,
    type: PaymentMethodTypeEnum.DISPERSION,
  },
  transfer: {
    icon: "iconTransfer",
    name: PaymentMethodEnum.TRANSFER,
    processorType: "",
    status: StatusTypeEnum.INACTIVE,
    translatedName: TRANSFER_NAME_TRANSLATE,
    type: PaymentMethodTypeEnum.FUNDRAISING,
  },
};
