import { createNamedStyles } from "../../../shared/interfaces/create_named_styles";

const TableSkeletonStyles = createNamedStyles({
  body: {
    "&.MuiTableBody-root": {
      backgroundColor: "transparent",
      width: "100%",
      tableLayout: "fixed",
      display: "block",
      height: "150px",
      overflowY: "scroll",
    },
  },
  boxContainerSkeleton: {
    pl: 3,
    pr: 3,
    pt: 3,
  },
  boxContainerSkeletonMobile: {
    pl: 2,
    pr: 2,
    pt: 2,
  },
  cellHead: {
    background: "#E2E8F0",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    "&.MuiTableCell-root": {
      height: 0,
      padding: 0,
    },
  },
  cellRow: {
    "&.MuiTableCell-root": {
      color: "#293036",
      fontSize: "15px",
      paddingBottom: "5px",
      paddingTop: 0,
      height: "100%",
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
  description: {
    display: "flex",
    justifyContent: "space-between",
  },
  descriptionLabelSkeleton: {
    height: "20px",
    width: { md: "93px", sm: "79px", xs: "55px" },
  },
  descriptionTextSkeleton: {
    height: "20px",
    width: { md: "298px", sm: "180px", xs: "120px" },
  },
  header: {
    "&.MuiTableHead-root": {
      backgroundColor: "transparent",
      color: "text.grey",
      borderBottom: "0.5px solid #CBD5E0",
    },
  },
  mainBox: {
    justifyContent: "flex-start",
    minWidth: "500px",
    width: "100%",
  },
  mainBoxMobile: {
    display: "block",
    width: "100%",
  },
  mainBoxMobileSkeleton: {
    display: "block",
    width: "100%",
  },
  skeletonBody: {
    width: { md: "107px", sm: "98px", xs: "65px" },
  },
  skeletonFooter: {
    width: { md: "138px", sm: "90px", xs: "55px" },
  },
  skeletonMobile: {
    paddingRight: { md: 10, sm: 8, xs: 5 },
  },
  skeletonMobileRectangle: {
    paddingRight: { md: 19, sm: 8, xs: 8 },
  },
  skeletonRectangle: {
    paddingRight: { md: 10, sm: 4, xs: 2 },
  },
  skeletonRectangleMobile: {
    paddingRight: { md: 20, sm: 10, xs: 10 },
  },
  table: {
    width: "100%",
    marginLeft: 0,
    minWidth: { md: 450, xs: 350 },
    marginRight: 0,
  },
  tableContainer: {
    width: "100%",
    marginLeft: 0,
  },
  title: {
    pb: "16px",
    pl: "16px",
    pt: "16px",
    textAlign: "start",
  },
  titleMobile: {
    pb: "16px",
    pl: "16px",
    pt: "16px",
    textAlign: "start",
  },
  titleSkeletonMobile: {
    height: "20px",
    width: "100%",
  },
});

export { TableSkeletonStyles };
