import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const servicesInfoStyles = createNamedStyles({
  contentGrid: {
    width: 1,
    height: "100%",
    position: "relative",
    display: "flex",
    paddingLeft: "10px",
    paddingRight: "20px",
    justifyContent: "space-between",
  },
  gridItem: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  textTitle: {
    marginLeft: "10px",
    marginRight: "15px",
  },
  skeleton: {
    width: 300,
    height: 50,
  },
});
