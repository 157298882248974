import { IUseBusinessRulesListIndexState } from "../../../shared/interfaces/IUseBusinessRulesListIndexState";
import { useAppSelector } from "../../../store/hooks/storeHook";
import { RootState } from "../../../store/store";
import { useParams } from "react-router";
import { setBasicMerchantInformation } from "../../../shared/utils/localStorage";
import { ROUTES } from "../../../shared/constants/routes";
import { useEffect } from "react";
import { defaultTo } from "lodash";

export const useBusinessRulesListIndexState =
  (): IUseBusinessRulesListIndexState => {
    const { merchantId } = useParams();
    const { openModalRuleDetail } = useAppSelector((state: RootState) => ({
      ...state.app,
    }));

    useEffect(() => {
      setBasicMerchantInformation({
        redirectPath: ROUTES.LIST_BUSINESS_RULES.replace(
          ":merchantId",
          defaultTo(merchantId, "")
        ),
      });
    }, []);

    return {
      isOpenModal: openModalRuleDetail,
    };
  };
