import { useLocation, useNavigate } from "react-router-dom";

export const useAppNavigate = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return {
    navigate: (route: string) => navigate(`${route}${location.search}`),
  };
};
