import {
  ActionEnum,
  ActionHistoricEnum,
  ActionKeyEnum,
  ActionRefundHistoryTitleEnum,
  ACTIONS_LIST,
  ProcessorNameEnum,
  RequestActionEnum,
} from "../../../../shared/infrastructure/enums/ActionEnum";
import {
  BackofficeTable,
  TableHeaders,
  IHandleOption,
} from "@kushki/frontend-molecules/backoffice-table";
import {
  Badge,
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  TableContainer,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  DashboardTableProps,
  TableStateProps,
} from "./DashboardTable.interfaces";
import { defaultTo, get } from "lodash";
import React, { Dispatch, useEffect } from "react";
import { set, cloneDeep } from "lodash";
import { AnalysisStateEnum } from "../../../../shared/infrastructure/constants/AnalysisStateEnum";
import { ButtonOption } from "../../../ButtonOption/ButtonOption";
import { CheckboxDashboard } from "../../../CheckboxDashboard/CheckboxDashboard";
import { ColumnsFilter } from "../../../ColumnsFilter/ColumnsFilter";
import { DetailsCard } from "../../../DetailsCard/DetailsCard";
import { format } from "date-fns-tz";
import { formatDateFromString } from "../../../../shared/utils/date";
import NumberFormat from "react-number-format";
import { sortRefundData } from "../../../../store/actionCreators";
import { StatusFlowEnum } from "../../../../shared/infrastructure/enums/StatusFlowEnum";
import { TransactionData } from "../../../../../types/transaction_data";
import { TransactionHistoryData } from "../../../../../types/transaction_history_data";
import { translate } from "../../../../shared/infrastructure/LanguageCatatog";
import TurnLeft from "../../../../assets/images/corner-up-left.svg";
import { TypeCheckBox } from "../../../../shared/infrastructure/TypeCheckBox";
import { useDispatch } from "react-redux";

export type TableComponentProps = DashboardTableProps & TableStateProps;
export const formatNumber = (value: number, currency: string): JSX.Element => {
  return (
    <NumberFormat
      value={value}
      thousandSeparator
      displayType={"text"}
      decimalScale={2}
      suffix={` ${currency}`}
    />
  );
};

export const formatTwoLine = (
  firstValue: string,
  secondValue: string
): JSX.Element => {
  return (
    <>
      <Box id={firstValue + "-" + secondValue + "-TitleBox"}>{firstValue}</Box>
      <Typography variant="body2" color="textSecondary">
        <Box
          fontSize={12}
          id={firstValue + "-" + secondValue + "-SecondaryBox"}
        >
          {secondValue}
        </Box>
      </Typography>
    </>
  );
};

const FORMAT_DATE: string = "dd-MM-yyyy";
const FORMAT_DATE_TO_VALIDATE: string = "dd-MM-yyyy";
const FORMAT_TIME: string = "HH:mm:ss";

const getValidateAlertTime = (tableContent: TransactionData[]) => {
  const dateNow: string = format(new Date(), FORMAT_DATE_TO_VALIDATE, {
    timeZone: "UTC",
  });

  return tableContent.map((item) => {
    const toExpireDate = formatDateFromString(
      get(item, "toExpireDate", new Date().toLocaleDateString()),
      FORMAT_DATE_TO_VALIDATE
    );

    const expirationDate = formatDateFromString(
      get(item, "expirationDate", new Date().toLocaleDateString()),
      FORMAT_DATE_TO_VALIDATE
    );

    if (toExpireDate <= dateNow && dateNow < expirationDate)
      return { ...item, backgrounColor: "#fff1d0" };

    if (expirationDate < dateNow) return { ...item, backgrounColor: "#f6aebb" };

    return { ...item };
  });
};

export const chipBackgroundColor = (trx: TransactionData): string => {
  switch (trx.stateRefund) {
    case AnalysisStateEnum.AUTHORIZED:
      return "#E0FFF8";
    case AnalysisStateEnum.PNDRFN:
      return "#FEECF8";
    case AnalysisStateEnum.RTRY:
      return "#F0F4F9";
    default:
      return "#FFEAEE";
  }
};

export const chipColor = (trx: TransactionData): string => {
  switch (trx.stateRefund) {
    case AnalysisStateEnum.AUTHORIZED:
      return "#0DC298";
    case AnalysisStateEnum.PNDRFN:
      return "#8F4F8B";
    case AnalysisStateEnum.RTRY:
      return "#6D7781";
    default:
      return "#AD0C2A";
  }
};

export const DashboardTable = (props: TableComponentProps): JSX.Element => {
  const {
    activeTab,
    check,
    pagination,
    handleCheckboxChange,
    handleCheckboxChangeAll,
    orderSort,
    filterColumns,
    tableOptions,
    statusFlow,
    setModalRefundData,
    tabValue,
  } = props;

  const { order, orderBy, handleRequestSort } = orderSort;

  const dispatch: Dispatch<Function> = useDispatch();

  useEffect(() => {
    dispatch(
      sortRefundData(orderSort, get(props, "refundData", []), activeTab)
    );
  }, [order, orderBy]);

  const openModal = (trx: TransactionData, title: string): void => {
    setModalRefundData!({
      actionApproval: ActionKeyEnum.RTY,
      context:
        "Al confirmar esta acción la transacción será enviada al Ejecutor para ser revisada nuevamente",
      description: "Escribe el motivo del porque se Reingresa la transacción:",
      hideContext: false,
      openDialog: true,
      title,
      trxData: {
        amount: trx.requestAmount,
        currency: trx.currencyCode,
        merchantId: trx.merchantId,
        merchantName: trx.merchantName,
        transactionId: get(trx, "transactionId", ""),
      },
    });
  };

  const handleLimit = (newLimit: string): void => {
    pagination.handleChangeLimit(Number(newLimit));
  };
  const handlePage = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ): void => {
    pagination.handleChangePage(_event, value, props.refundData.data!);
  };

  const amount = (trx: TransactionData, property: string): JSX.Element => {
    return formatNumber(
      Number(get(trx, property, 0)),
      get(trx, "currencyCode", "")
    );
  };

  const defaultTwoLine = (
    trx: TransactionData,
    fValue: string,
    sValue: string
  ): JSX.Element => {
    return formatTwoLine(
      translate(get(trx, fValue, "")),
      translate(get(trx, sValue, ""))
    );
  };

  const defaultCode = (
    trx: TransactionData,
    type: string,
    defaultValue: string = ""
  ): JSX.Element => {
    return get(trx, type, defaultValue);
  };

  const disabledOption = (trx: TransactionData): boolean => {
    const kushkiAcquirerProcessor = get(trx, "processorName", "");
    const action = get(trx, "action", "");
    const response = get(trx, "response", "");

    return (
      activeTab === 1 &&
      kushkiAcquirerProcessor === ProcessorNameEnum.kushki_acquirer_processor &&
      action === RequestActionEnum.APRRFN &&
      response === ActionHistoricEnum.send_to_processor
    );
  };

  const validate = (trx: TransactionData): boolean => {
    const action = get(trx, "action", "");
    const response = get(trx, "response", "");

    return (
      (action === RequestActionEnum.APRRFN &&
        response === ActionHistoricEnum.send_to_processor) ||
      (action === RequestActionEnum.APRCNL &&
        response === ActionHistoricEnum.send_by_fin_ops) ||
      (action === RequestActionEnum.APRGBCK &&
        response === ActionHistoricEnum.send_by_fin_ops)
    );
  };

  type TEditOptions<TransactionData> = (item: TransactionData) => void;
  const handleOnMoreOptions = (
    handleOnMoreOptions:
      | IHandleOption<TransactionData>[]
      | TEditOptions<TransactionData>,
    trx: TransactionData
  ): IHandleOption<TransactionData>[] | TEditOptions<TransactionData> => {
    const action = get(trx, "action", "");
    const response = get(trx, "response", "");
    const options = cloneDeep(handleOnMoreOptions);

    if (
      action === RequestActionEnum.APRRFN &&
      response === ActionHistoricEnum.send_to_processor
    ) {
      set(options[0], "disabled", true);
      set(options[1], "disabled", true);
      set(options[2], "disabled", false);
      set(options[3], "disabled", false);
    } else if (
      (action === RequestActionEnum.APRCNL &&
        response === ActionHistoricEnum.send_by_fin_ops) ||
      (action === RequestActionEnum.APRGBCK &&
        response === ActionHistoricEnum.send_by_fin_ops)
    ) {
      set(options[0], "disabled", false);
      set(options[1], "disabled", false);
      set(options[2], "disabled", true);
      set(options[3], "disabled", true);
    }

    return options;
  };
  const defaultCodeDate = (trx: TransactionData, type: string): JSX.Element => {
    return formatTwoLine(
      formatDateFromString(get(trx, type, "1"), FORMAT_DATE),
      formatDateFromString(get(trx, type, "1"), FORMAT_TIME)
    );
  };

  const stateRefund = (trx: TransactionData): JSX.Element => {
    return (
      <>
        <Chip
          style={{
            backgroundColor: chipBackgroundColor(trx),
            borderRadius: "6px",
            color: chipColor(trx),
            width: "100%",
          }}
          label={translate(get(trx, "stateRefund", ""))}
          size="small"
        />
      </>
    );
  };

  const action = (
    trx: TransactionData,
    fvalue: string,
    svalue: string
  ): JSX.Element => {
    return (
      <>
        <Box
          id={{ fvalue } + "-" + { svalue } + "-TitleBox"}
          display="flex"
          flexGrow={12}
        >
          <Grid item xs={12}>
            <Typography variant="body2">{fvalue}</Typography>
            <Typography variant="body2" color="textSecondary">
              {svalue}
            </Typography>
          </Grid>
          {props.statusFlow === StatusFlowEnum.VALIDATOR &&
            fvalue === ActionEnum.refund &&
            svalue === ActionHistoricEnum.declined_by_processor && (
              <Button
                onClick={() => {
                  openModal(trx, "Reingresar transacción al Ejecutor");
                }}
              >
                <Tooltip
                  title="Reingresar transacción al Ejecutor"
                  placement="top"
                  arrow
                >
                  <img src={TurnLeft} alt="turn left" width={"40%"} />
                </Tooltip>
              </Button>
            )}
          {tableOptions[0] && (
            <ButtonOption
              hidden={
                props.statusFlow === StatusFlowEnum.EXECUTOR && validate(trx)
              }
              options={[
                {
                  disabled:
                    get(props, "numberItemSelect", 0) > 0 ||
                    disabledOption(trx),
                  handleOnMoreOptions: handleOnMoreOptions(
                    tableOptions[0].handleOnMoreOptions,
                    trx
                  ),
                  icon: tableOptions[0].icon,
                  title: tableOptions[0].title,
                },
              ]}
              item={trx}
            />
          )}
        </Box>
      </>
    );
  };

  const executor = (trx: TransactionData): JSX.Element => {
    const action = defaultTo(get(trx, "action"), ActionKeyEnum.RPY);

    const action_properties: {
      background: string;
      color: string;
      name: string;
    } = ACTIONS_LIST[action] ?? {
      background: "inherit",
      color: "inherit",
      name: "",
    };

    return (
      <Grid container>
        <Grid item xs={4} md={4}>
          <Button
            size={"small"}
            variant="outlined"
            style={{
              backgroundColor: action_properties.background,
            }}
          >
            <Badge
              color="primary"
              style={{
                color: action_properties.color,
              }}
            >
              {action_properties.name}
            </Badge>
          </Button>
        </Grid>
      </Grid>
    );
  };

  const processCheckAll = (trxData: TransactionData[]): JSX.Element => {
    return (
      <CheckboxDashboard
        registerAllData={trxData}
        handleCheckboxChangeAll={handleCheckboxChangeAll}
        typeCheckBox={TypeCheckBox.CHECKBOX_ALL_APPROVED}
        check={check}
      />
    );
  };

  const refundDashboardStatus = (trx: TransactionData): JSX.Element => {
    return (
      <CheckboxDashboard
        key={trx.transactionId}
        registerData={trx}
        handleCheckboxChange={handleCheckboxChange}
        typeCheckBox={TypeCheckBox.CHECK_APPROVED}
      />
    );
  };

  return (
    <Box>
      <TableContainer
        component={Paper}
        style={{ borderRadius: "8px", marginTop: "10px" }}
      >
        <ColumnsFilter
          showColumnsFilter={props.filterColumns.showColumnsFilter!}
          anchorEl={props.filterColumns.columnsFilterAnchorEl!}
          handleClose={props.filterColumns.handleClose!}
          {...props.filterColumns}
        />
        <BackofficeTable
          optionsHeader={tableOptions.length > 0 ? "" : undefined}
          items={
            tabValue === 0 && statusFlow === StatusFlowEnum.EXECUTOR
              ? (getValidateAlertTime(
                  get(props, "refundData.data", [])
                ) as TransactionData[])
              : (get(props, "refundData.data", []) as TransactionData[])
          }
          headers={
            filterColumns.selectedColumns as TableHeaders<
              TransactionData | TransactionHistoryData
            >[]
          }
          skeleton={props.isLoading}
          sort={{
            handleRequestSort,
            order,
            orderBy,
          }}
          customHeaders={{
            refundDashboardStatus: () =>
              processCheckAll(props.refundData.data! as TransactionData[]),
          }}
          customRenderers={{
            action: (trx: TransactionData) =>
              action(
                trx,
                ActionRefundHistoryTitleEnum[get(trx, "action")],
                get(trx, "response")
              ),
            approvedTransactionAmount: (trx: TransactionData) =>
              amount(trx, "approvedTransactionAmount"),
            createAt: (trx: TransactionData) =>
              defaultCodeDate(trx, "createAt"),
            executor,
            merchantName: (trx: TransactionData) =>
              defaultTwoLine(trx, "merchantName", "merchantId"),
            refundDashboardStatus,
            requestAmount: (trx: TransactionData) =>
              amount(trx, "requestAmount"),
            stateRefund,
            transactionId: (trx: TransactionData) =>
              defaultCode(trx, "transactionId"),
            transactionType: (trx: TransactionData) =>
              defaultTwoLine(trx, "transactionType", "transactionStatus"),
            updateAt: (trx: TransactionData) =>
              defaultCodeDate(trx, "updateAt"),
          }}
          options={
            tableOptions.length > 0 &&
            !(activeTab === 1 && statusFlow === StatusFlowEnum.EXECUTOR)
              ? [
                  {
                    disabled: get(props, "numberItemSelect", 0) > 0,
                    handleOnMoreOptions: tableOptions[0].handleOnMoreOptions,
                    icon: tableOptions[0].icon,
                    title: tableOptions[0].title,
                  },
                ]
              : undefined
          }
          emptyState={{
            subtitle: "",
            title: "No se encontraron resultados",
          }}
          pagination={{
            ...props.pagination,
            handleChangeLimit: handleLimit,
            handleChangePage: handlePage,
            limitsPerPage: [25, 50, 100],
            total: get(props, "refundData.total", 0),
          }}
          handleOnRowSelection={props.detailsCard!.handleShowCard}
        />
        <DetailsCard {...props.detailsCard!} />
      </TableContainer>
    </Box>
  );
};
