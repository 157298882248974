import { IStyles } from "../../../shared/interfaces/Styles.interfaces";
import theme from "../../../theme";

export const refundModalStyles: IStyles = {
  dialog: {
    "&.MuiPaper-root-MuiDialog-paper": {
      width: "636px",
      backgroundColor: "red",
    },
  },
  dialogTitle: {},
  sendBtn: {
    "&:hover, &:focus, &:active": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default,
      boxShadow: "none !important",
      border: 0,
    },
    backgroundColor: theme.palette.primary.main,
    border: 0,
    borderRadius: "4px",
    color: theme.palette.background.default,
  },
  cancelBtn: {
    "&:hover": {
      color: "#293036 !important",
      boxShadow: "none !important",
      border: 0,
    },
    border: 0,
    borderRadius: "4px",
    color: "#293036",
  },
  buttonsText: {
    fontSize: "14px",
  },
};
