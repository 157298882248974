import React, { useEffect, useRef, useState } from "react";
import { TrxElasticField, TrxMongoField } from "../../../shared/enums/DbEnum";
import {
  IFiltersDashboard,
  IUseFilterDashboard,
  IUseFilterDashboardProps,
  IUserData,
} from "./useDashboardTransaction.interfaces";
import { TFilterDetailBar } from "@kushki/connect-ui/dist/Components/Molecules/Filters/FilterDetailBar/FilterDetailBar.interfaces";
import {
  cloneDeep,
  defaultTo,
  get,
  has,
  isEmpty,
  isEqual,
  isNil,
  isString,
  omitBy,
  set,
} from "lodash";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { FILTER_LABELS } from "../../../shared/constants/labels/filter_labels";
import { SearchTransactionRequest } from "../../../../types/search_transaction_request";
import {
  Transaction,
  TransactionInfo,
} from "../../../../types/transactions_data";
import { format, set as setDate, sub } from "date-fns";
import {
  chargebackTrxWithEmail,
  downloadFileForTrxSelected,
  getFirebaseID,
  getPaymentReceipt,
  notifySelectCheckChipsChange,
  searchMerchant,
  searchOriginalTrx,
} from "../../../store/thunks/app/app.thunks";
import { ColumnsItem } from "@kushki/connect-ui";
import { HeadCell } from "../../Table/TableHead/TableHead.interfaces";
import {
  buildSelectedHead,
  getColumns,
  getCustomRow,
} from "../../../shared/utils/column_selected";
import { EntityNameEnum } from "../../../shared/enums/EntityNameEnum";
import {
  ActionHeader,
  AdminHeaderTableFixed,
  AdminLeftRowFixed,
  HeaderTableFixed,
  LeftRowFixed,
  StatusHeader,
} from "../../../shared/catalogs/CatalogConfigTableTransaction";
import { getColumnsUser } from "../../../shared/utils/transaction_list_utils";
import { joinItemList } from "../../../shared/utils/joinItemList";
import { FILTER_GROUPS } from "../../../shared/constants/labels/header_filters_labels";
import { RangeAmount } from "../../AmountFilter/state/useAmountFilter.interfaces";
import { TransactionLabelsEnum } from "../../../shared/enums/transactionLabelsEnum";
import {
  builderRows,
  IConfigCells,
} from "../../../shared/constants/table_transactions";
import { ITableRowProps } from "../../Table/TableTransactions/TableTransactions.interfaces";
import { environment } from "../../../environments/environment";
import { CognitoRolesEnum } from "../../../shared/infraestructure/CognitoRolesEnum";
import {
  buildStringFilter,
  downloadTransactions,
  verifySubMethod,
} from "../../../shared/utils/dashboard_transaction_utils";
import { TransactionTypeEnum } from "../../../shared/enums/TransactionTypeEnum";
import { setFirebaseId } from "../../../store/actions/app.actions";
import { revokeReceipt } from "../../../shared/utils/table_transactions_utils";
import { VoidBody } from "../../../store/interfaces/AppState.interfaces";
import {
  convertDateCorrectFormat,
  getFinishDate,
  getStartDate,
} from "../../../shared/utils/timezone";
import { useRollbar } from "@rollbar/react";
import Rollbar from "rollbar";
import { isCustomerRol } from "../../../shared/utils";
import moment from "moment/moment";
import { useLocation } from "react-router";
import { getPaymentMethod } from "../../../shared/utils/chargeback_utils";
import { PaymentMethodsEnum } from "../../../shared/infraestructure/payment-method";

export const useDashboardTransaction = ({
  entityName,
  path,
  transactionData,
  showEmptyScreen,
  mechantFilter,
}: IUseFilterDashboardProps): IUseFilterDashboard => {
  const {
    firebaseId,
    isLoadingTransaction,
    loadingDownload,
    selectItems,
    nodeInfo,
    receipt,
    listButton,
    retrieveChildren,
    isTransactionSearch,
    loadingChargebackAction,
    selectCheckChipsChange,
  } = useAppSelector((state) => ({
    ...state.app,
    ...state.customerReducer,
  }));
  const [subTitle, setSubTitle] = useState<string>("");

  const rollbar: Rollbar = useRollbar();
  const dispatchApp = useAppDispatch();
  const user_profile: IUserData = getColumnsUser();
  const query = new URLSearchParams(useLocation().search);
  const ticket_number: string = defaultTo(
    query.get("ticketNumber") as string,
    ""
  );
  const date_parameter: string = query.get("date") as string;
  const formattedDateTime: string = "yyyy-MM-dd'T'HH:mm:ss";
  const [page, setPage] = useState(0);
  const [isApplyFilter, setIsApplyFilter] = useState<boolean>(false);
  const [isOpenModalDetailInfo, setIsOpenModalDetailInfo] =
    useState<boolean>(false);
  const [itemsSelected, setItemsSelected] = useState<TransactionInfo[]>([]);
  const [transactionInfo, setTransactionInfo] = useState<Transaction>({});
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const totalData: number = get(transactionData, "total.value", 0);
  const [disableDownload, setDisableDownload] = useState<boolean>(false);
  const [initialFilters] = React.useState<IFiltersDashboard>({
    filter: {},
    headerFilters: {
      dateRangeFilter: {
        from: convertDateCorrectFormat(
          getStartDate(rollbar, date_parameter),
          true,
          rollbar
        ),
        to: convertDateCorrectFormat(getFinishDate(rollbar), false, rollbar),
      },
    },
    paginationFilters: {
      offset: rowsPerPage * page,
      rowsPerPage: rowsPerPage,
    },
    sort: {
      field: "created",
      order: "desc",
    },
    text: ticket_number,
    timeZone: user_profile.timeZone,
  });
  const [filterDashboard, setFilterDashboard] =
    useState<IFiltersDashboard>(initialFilters);
  const filterRef = useRef({});
  const [resetFilters, setResetFilters] = useState<boolean>();
  const [rangeAmountInfo, setRangeAmountInfo] = useState<RangeAmount>();
  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const [resetItemChecked, setResetItemChecked] = useState<boolean>();
  const [hasFilters, setHasFilters] = useState<boolean>(false);
  const [countData, setCountData] = useState<number>(0);
  const [isOpenModalChargeback, setIsOpenModalChargeback] =
    useState<boolean>(false);
  const [selectRowChargeback, setSelectRowChargeback] = useState<string>("");
  const [selectedChargebackData, setSelectedChargebackData] = useState<object>(
    {}
  );
  const [selected, setSelected] = useState<ColumnsItem[]>(
    cloneDeep(getColumns(user_profile.admin))
  );

  const handleSelectedRows = (rowsSelected: TransactionInfo[]) => {
    setItemsSelected(rowsSelected);
  };

  const handleData = (items: ColumnsItem[]) => setSelected(items);

  const handleSortQuery = (fetchedQuery: any) => {
    setFilterDashboard((prevState) => ({
      ...prevState,
      sort: fetchedQuery,
    }));
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
    setFilterDashboard({
      ...filterDashboard,
      paginationFilters: {
        ...filterDashboard.paginationFilters,
        offset: rowsPerPage * newPage,
      },
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilterDashboard({
      ...filterDashboard,
      paginationFilters: {
        ...filterDashboard.paginationFilters,
        offset: 0,
        rowsPerPage: parseInt(event.target.value, 10),
      },
    });
  };

  const handleDownloadReceipt = (transactionInfo: TransactionInfo) => {
    dispatchApp(
      getPaymentReceipt({
        body: nodeInfo,
        transactionInfo,
      })
    );
  };

  const builderSearchTransactionRequest = (
    filters: IFiltersDashboard
  ): SearchTransactionRequest => {
    const merchant_filter_aux: string = retrieveChildren
      .map((item) => get(item, "publicMerchantId", []).join())
      .filter((item) => !isEmpty(item))
      .join("|")
      .split(",")
      .join("|");

    if (
      !isEmpty(retrieveChildren) &&
      !isEmpty(mechantFilter.itemsList) &&
      !isEmpty(merchant_filter_aux)
    )
      set(filters, "publicMerchantId", merchant_filter_aux);

    if (isEmpty(mechantFilter.itemsList) && user_profile.admin)
      set(filters, "publicMerchantId", undefined);

    if (!isEmpty(get(filters.filter, "paymentMethod", {})))
      set(filters, "filter", verifySubMethod(filters));

    return {
      customerFilter: get(filters, "customerFilter"),
      filter: get(filters, "filter"),
      from: get(filters, "headerFilters.dateRangeFilter.from"),
      limit: get(filters, "paginationFilters.rowsPerPage"),
      offset: get(filters, "paginationFilters.offset"),
      publicMerchantId: get(filters, "publicMerchantId"),
      rangeAmount: rangeAmountInfo,
      sort: {
        field: get(filters, "sort.field"),
        order: get(filters, "sort.order"),
      },
      text: get(filters, "text"),
      timeZone: get(filters, "timeZone"),
      to: get(filters, "headerFilters.dateRangeFilter.to"),
      userType: user_profile.admin
        ? TransactionLabelsEnum.ADMIN
        : TransactionLabelsEnum.MERCHANT,
    };
  };

  const handleChildrenIds = (
    request: SearchTransactionRequest,
    setPath: boolean
  ): SearchTransactionRequest => {
    const transaction_request = request;

    if (entityName === EntityNameEnum.CUSTOMER) {
      let children_ids: string = get(
        transaction_request,
        "customerFilter.childrenIds",
        ""
      );

      if (children_ids.length <= 0) {
        const list = listButton.map((item) => item.description);

        children_ids = list.join("|");
      }

      set(transaction_request, "customerFilter.childrenIds", children_ids);

      if (setPath) set(transaction_request, "customerFilter.path", path);
    }

    return transaction_request;
  };

  const handleSelect = (event: any) => {
    if (itemsSelected.length > 0) {
      const is_db_mongo: boolean = has(itemsSelected[0], TrxMongoField.ID);
      const target_field_name: string = is_db_mongo
        ? TrxMongoField.ID
        : TrxElasticField.TRANSACTION_ID;
      const field_values: string[] = itemsSelected.map((trx: TransactionInfo) =>
        get(trx, target_field_name)
      );

      dispatchApp(
        downloadFileForTrxSelected({
          field: target_field_name,
          fields: field_values,
          format: event.id,
          timeZone: user_profile.timeZone,
        })
      );
    } else {
      let request: SearchTransactionRequest =
        builderSearchTransactionRequest(filterDashboard);

      request = handleChildrenIds(request, false);
      let children_ids: string = get(request, "customerFilter.childrenIds", "");

      if (
        entityName !== EntityNameEnum.CUSTOMER ||
        (entityName === EntityNameEnum.CUSTOMER && children_ids.length > 0)
      ) {
        dispatchApp(
          getFirebaseID({
            body: {
              ...request,
              filter: get(filterDashboard, "filter"),
              from: get(filterDashboard, "headerFilters.dateRangeFilter.from"),
              rangeAmount: rangeAmountInfo,
              text: get(filterDashboard, "text"),
              to: get(filterDashboard, "headerFilters.dateRangeFilter.to"),
            },
            format: event.id,
            ...(entityName === EntityNameEnum.CUSTOMER
              ? {
                  customerFilter: {
                    childrenIds: children_ids,
                    path,
                  },
                }
              : {}),
          })
        );
      }
    }
  };

  const handleChangeFilterDetails = (
    selectedFilters: TFilterDetailBar[]
  ): void => {
    const filterRequest = {};

    selectedFilters.map((filter: TFilterDetailBar) => {
      switch (filter.title) {
        case FILTER_GROUPS.PAYMENT_METHODS:
          set(filterRequest, "paymentMethod", buildStringFilter(filter.items));
          break;
        case FILTER_GROUPS.STATE:
          set(
            filterRequest,
            "transactionStatus",
            buildStringFilter(filter.items)
          );
          break;
        case FILTER_GROUPS.TYPES:
          set(
            filterRequest,
            "transactionType",
            buildStringFilter(filter.items)
          );
          break;
        case FILTER_LABELS.TICKET_NUMBER:
          set(filterDashboard, "text", buildStringFilter(filter.items));
          break;
        case FILTER_LABELS.RANGE_DATE_FROM:
          set(
            filterDashboard,
            "headerFilters.dateRangeFilter.from",
            buildStringFilter(filter.items)
          );
          break;
        case FILTER_LABELS.RANGE_DATE_TO:
          set(
            filterDashboard,
            "headerFilters.dateRangeFilter.to",
            buildStringFilter(filter.items)
          );
          break;
      }
    });

    setPage(0);
    setFilterDashboard({
      ...filterDashboard,
      filter: filterRequest,
      paginationFilters: {
        ...filterDashboard.paginationFilters,
        offset: 0,
      },
    });
  };

  const filterSelected = (): HeadCell[] => {
    const getHeadSelected: HeadCell[] = buildSelectedHead(
      selected.filter((item: ColumnsItem) => item.isSelected)
    );
    const fixedHead: HeadCell[] = isCustomerRol()
      ? HeaderTableFixed
      : AdminHeaderTableFixed;

    getHeadSelected.push(StatusHeader, ActionHeader);

    return fixedHead.concat(getHeadSelected);
  };
  const buildRows = (): IConfigCells[] => {
    const selectedItems: ColumnsItem[] = selected.filter(
      (item: ColumnsItem) => item.isSelected
    );
    const getRowsFixed: IConfigCells[] = isCustomerRol()
      ? LeftRowFixed
      : AdminLeftRowFixed;
    const fixedRows: IConfigCells[] = getRowsFixed.filter(
      (item) => item.isActive
    );

    return fixedRows.concat(getCustomRow(selectedItems));
  };

  const rows: ITableRowProps[] = builderRows<Transaction>(
    get(transactionData, "data", []),
    buildRows(),
    entityName,
    [
      {
        action: () => {
          setIsOpenModalChargeback(true);
        },
        text: "Iniciar contracargo",
      },
    ],
    page.toString()
  );

  const firstUpdate = useRef(true);

  const setDisableDownloadByMerchantId = (): void => {
    const roles = JSON.parse(localStorage.getItem("roles")!);
    const merchant_ids: string[] = environment.disableDownloadMIDs.split(";");

    if (get(roles, CognitoRolesEnum.BACK_OFFICE_READER, false))
      setDisableDownload(
        merchant_ids.includes(localStorage.getItem("merchantId")!)
      );
  };

  const searchFilters = (filters: SearchTransactionRequest) => {
    const clean_filters: object = omitBy(
      filters,
      (item) => isNil(item) || isEmpty(item)
    );

    filterRef.current = cloneDeep(filters);

    setIsFiltering(
      has(clean_filters, "filter") ||
        has(clean_filters, "name") ||
        has(clean_filters, "from") ||
        has(clean_filters, "to")
    );

    filters = handleChildrenIds(filters, true);

    filters.userType = user_profile.admin
      ? TransactionLabelsEnum.ADMIN
      : TransactionLabelsEnum.MERCHANT;

    let children_ids: string = get(
      filterDashboard,
      "customerFilter.childrenIds",
      ""
    );

    if (
      entityName !== EntityNameEnum.CUSTOMER ||
      (entityName === EntityNameEnum.CUSTOMER && children_ids.length > 0)
    ) {
      dispatchApp(
        searchMerchant({
          body: filters,
          isSecondSearch: false,
        })
      );
    }
  };

  const handleSearchOriginalTrx = (transactionInfo: TransactionInfo) => {
    const to_date = transactionInfo.created
      ? transactionInfo.created
      : `${moment(new Date()).format("YYYY-MM-DD")}T23:59:59`;
    const from_date = `${moment(sub(new Date(to_date), { months: 6 })).format(
      "YYYY-MM-DD"
    )}T00:00:00`;

    let filters: SearchTransactionRequest = {
      filter: {
        transactionType: `${TransactionTypeEnum.SALE}|${TransactionTypeEnum.DEFFERRED}|${TransactionTypeEnum.PREAUTHORIZATION}|${TransactionTypeEnum.CAPTURE}`,
      },
      from: from_date,
      limit: 10,
      offset: 0,
      text: transactionInfo.sale_ticket_number,
      timeZone: user_profile.timeZone,
      to: to_date,
      userType: user_profile.admin
        ? TransactionLabelsEnum.ADMIN
        : TransactionLabelsEnum.MERCHANT,
    };

    filters = handleChildrenIds(filters, true);

    let children_ids: string = get(
      filterDashboard,
      "customerFilter.childrenIds",
      ""
    );

    if (
      entityName !== EntityNameEnum.CUSTOMER ||
      (entityName === EntityNameEnum.CUSTOMER && children_ids.length > 0)
    ) {
      dispatchApp(
        searchOriginalTrx({
          body: filters,
        })
      );
    }
  };

  const handleSubmitChargebackWithEmail = (emailList: string[]) => {
    const transaction: TransactionInfo = get(transactionInfo, "_source", {});
    const paymentMethod: string = getPaymentMethod(transaction);
    const isPos: boolean = isEqual(
      PaymentMethodsEnum.CARD_PRESENT,
      paymentMethod
    );

    let trxChargeback: VoidBody = {
      emailList,
      id: isPos
        ? get(transaction, "transaction_reference", "")
        : get(transaction, "ticket_number", ""),
      kind: isEmpty(get(transaction, "subscription_id", ""))
        ? "card"
        : "subscription",
      paymentMethod: paymentMethod,
      ticketNumber: get(transaction, "ticket_number", ""),
    };

    dispatchApp(chargebackTrxWithEmail(trxChargeback));
  };

  const handleSearchTrxParent = (ticketNumberChild?: string): void => {
    const isChild: boolean =
      !isNil(ticketNumberChild) && isString(ticketNumberChild);

    const filters: any = {
      filter: {
        paymentMethod: "",
        transactionStatus: "",
        transactionType: isChild
          ? ""
          : `${TransactionTypeEnum.SALE}|${TransactionTypeEnum.DEFFERRED}`,
      },
      from: format(
        sub(new Date(), { hours: 0, minutes: 0, months: 6, seconds: 0 }),
        formattedDateTime
      ),
      offset: 0,
      publicMerchantId: user_profile.publicMerchantId,
      text: isChild
        ? ticketNumberChild
        : get(transactionInfo, "_source.sale_ticket_number"),
      timeZone: user_profile.timeZone,
      to: format(
        setDate(new Date(), { hours: 23, minutes: 59, seconds: 59 }),
        formattedDateTime
      ),
      userType: user_profile.admin
        ? TransactionLabelsEnum.ADMIN
        : TransactionLabelsEnum.MERCHANT,
    };

    dispatchApp(searchMerchant({ body: filters, isSecondSearch: true }));
  };

  useEffect(() => {
    if (!isEmpty(firebaseId)) {
      downloadTransactions(firebaseId, dispatchApp);
      dispatchApp(setFirebaseId(""));
    }
  }, [firebaseId]);

  useEffect(() => {
    const filters: SearchTransactionRequest =
      builderSearchTransactionRequest(filterDashboard);

    if (
      firstUpdate.current ||
      (isEmpty(entityName) && !user_profile.admin) ||
      isEqual(filterRef.current, filters)
    ) {
      firstUpdate.current = false;

      return;
    }

    searchFilters(filters);
  }, [filterDashboard]);

  useEffect(() => {
    if (!isEmpty(retrieveChildren) && !isTransactionSearch) {
      const cloneFilterDashboard = { ...filterDashboard };

      if (selectCheckChipsChange) {
        dispatchApp(
          notifySelectCheckChipsChange({
            hasChange: false,
          })
        );
        setPage(0);
        cloneFilterDashboard.paginationFilters.offset = 0;
      }

      searchFilters(builderSearchTransactionRequest(cloneFilterDashboard));
    }
  }, [retrieveChildren]);

  useEffect(() => {
    if (!isEmpty(path)) {
      setHasFilters(false);
      setCountData(0);
      setFilterDashboard({
        ...initialFilters,
      });
    }
    setResetFilters(!resetFilters);
  }, [path, entityName]);

  useEffect(() => {
    setResetItemChecked(!resetItemChecked);
  }, [path, entityName, page]);

  useEffect(() => {
    if (!hasFilters) {
      setCountData(get(transactionData, "total.value", 0));
      setHasFilters(true);
    }
  }, [transactionData]);

  useEffect(() => {
    if (!isNil(selectItems)) {
      const branches: string = joinItemList(selectItems!, "|", "", true);

      setFilterDashboard({
        ...filterDashboard,
        customerFilter: {
          childrenIds: branches,
          path: nodeInfo.path,
        },
      });

      setSubTitle(joinItemList(selectItems!, ", ", "."));
    }
  }, [selectItems]);

  useEffect(() => {
    if (receipt) revokeReceipt(receipt);
  }, [receipt]);

  useEffect(() => {
    setDisableDownloadByMerchantId();
  }, []);

  return {
    countData,
    dataPicker: {
      handleData,
      headerCell: filterSelected(),
      items: selected,
    },
    downloadOptions: {
      disableDownload,
      handleSelect,
      loadingDownload,
    },
    handleChangeFilterDetails,
    handleDownloadReceipt,
    handleSearchTrxParent,
    handleSelectedRows,
    handleSortQuery,
    handleSubmitChargebackWithEmail,
    isApplyFilter,
    isLoadingTransaction,
    itemsSelected,
    loadingChargebackAction,
    modalDetailInfo: {
      handleSearchOriginalTrx,
      isOpenModalDetailInfo,
      setIsOpenModalDetailInfo,
    },
    modalStartChargeback: {
      isOpenModalChargeback,
      setIsOpenModalChargeback,
    },
    paginationProps: {
      handleChangePage,
      handleChangeRowsPerPage,
      page,
      rowsPerPage,
      totalData,
    },
    rangeAmount: isApplyFilter ? rangeAmountInfo : undefined,
    resetFilters,
    rows,
    selectedChargebackData,
    selectRowChargeback,
    setIsApplyFilter,
    setRangeAmount: setRangeAmountInfo,
    setRowSelectChargeback: setSelectRowChargeback,
    setSelectedChargebackData,
    setTransactionInfo,
    showEmptyScreen: isFiltering ? false : showEmptyScreen,
    subTitle,
    transactionInfo,
  };
};
