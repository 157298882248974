enum EnumCellOptionMenu {
  VISUALIZAR = "Visualizar",
  DESCARGAR = "Descargar",
}
enum KindDocument {
  FUENTE = "FUENTE",
  IVA = "IVA",
  ICA = "ICA",
}

enum KindDocumentTranslation {
  FUENTE = "SOURCE",
  IVA = "IVA",
  ICA = "ICA",
}

const KindDocumentEnum: Record<KindDocument, string> = {
  [KindDocument.FUENTE]: KindDocumentTranslation.FUENTE,
  [KindDocument.IVA]: KindDocumentTranslation.IVA,
  [KindDocument.ICA]: KindDocumentTranslation.ICA,
};

enum EventKey {
  TAB = "Tab",
  ESCAPE = "Escape",
}

export { EnumCellOptionMenu, KindDocumentEnum, EventKey };
