import React, { FC } from "react";
import {
  FilterBar,
  ModalLoader,
  ModalSimple,
  TableSimple,
  Title,
} from "@kushki/connect-ui";
import { navigateToUrl } from "single-spa";
import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import { useWebhooksContainer } from "./state/useWebhooksContainer";
import { ROUTES } from "../../shared/constants/routes";
import { WebhookSimulationModal } from "../../WebhookSimulationModal/WebhookSimulationModal";
import {
  WebhookModalDelete,
  WebhookModalLoader,
} from "../../shared/enums/WebhookModals";
import { useLocation } from "react-router-dom";
import { isMaster } from "../../shared/utils/cognito";
import { isEmpty } from "lodash";
import { clipboard } from "../../shared/clipboard";
import { Copy } from "react-feather";
import { webhookStyles } from "./WebhooksContainer.styles";
import { closeWebhookSnackbar } from "../../store/actions/webhook/webhook.actions";
import { useAppDispatch } from "../../store/hooks/storeHook";

const WebhooksContainer: FC = () => {
  const {
    buildBreadCrumbItems,
    rows,
    centralized,
    changeItemsPerPage,
    columns,
    filterPaymentMethods,
    filterStatusOptions,
    handleApplyFilter,
    handlePageClick,
    isLoading,
    itemsPerPage,
    mockFn,
    modals: {
      deleteWebhookModalLoader,
      deleteWebhookModalSimple,
      simulateWebhookModal,
    },
    selectedPage,
    selectedWebhook,
    totalItems,
    handlers,
    webhookSignature,
    handleSearchByDate,
    handleChangeSearchBy,
    handleSelectSearchBy,
    searchItems,
  } = useWebhooksContainer();
  const { pathname } = useLocation();
  const merchantId = pathname.split("/")[2];
  const dispatch = useAppDispatch();

  return (
    <Box sx={{ mx: "50px" }}>
      <Box sx={{ mt: "72px" }}>
        {!isEmpty(webhookSignature) && (
          <Title
            items={buildBreadCrumbItems()}
            onButtonClick={() => {
              dispatch(closeWebhookSnackbar(false));
              navigateToUrl(
                ROUTES.MERCHANT_WEBHOOK_CREATE.replace(
                  "{merchantId}",
                  merchantId
                )
              );
            }}
            textButton={"Agregar webhook"}
            title="Listado de webhooks"
            type={isMaster() || centralized ? "titleBase" : "titleOneAction"}
          />
        )}
      </Box>
      <Box sx={webhookStyles.boxWebhookSignature}>
        <Typography variant={"caption"}>Webhook signature: </Typography>
        {isEmpty(webhookSignature) ? (
          <Skeleton
            data-testid="signature-skeleton"
            variant="rectangular"
            animation="wave"
            sx={webhookStyles.skeletonSignature}
          />
        ) : (
          <Box sx={webhookStyles.textWebhookSignature}>
            <Typography variant={"caption"}>
              {` ${webhookSignature}`}
            </Typography>
            <IconButton
              data-testid="signatureClipboard"
              style={{ padding: 0 }}
              onClick={() => clipboard(webhookSignature)}
            >
              <Copy size={14} />
            </IconButton>
          </Box>
        )}
      </Box>
      {rows && (
        <>
          <FilterBar
            filterSideBar={{
              categoryItems: [
                {
                  isMinimize: true,
                  items: filterPaymentMethods,
                  onExpand: mockFn,
                  onItemsSelect: mockFn,
                  placeHolder: "Medio de pago o producto",
                  selectType: "multiple",
                  type: "byChips",
                },
                {
                  isMinimize: true,
                  items: filterStatusOptions,
                  onExpand: mockFn,
                  onItemsSelect: mockFn,
                  placeHolder: "Estado de conexión",
                  selectType: "single",
                  type: "byChips",
                },
              ],
              filtersCount: 0,
              isMinimized: false,
              isOpen: true,
              onClick: handleApplyFilter,
              title: "Filtrar por",
            }}
            rangeDatePicker={{
              dateFormat: "dd/MMM/yyyy",
              disabledFutureDate: false,
              disabledOldDate: false,
              isFilled: false,
              isOpen: false,
              onDateSelect: handleSearchByDate,
              placeholder: "Buscar por fecha de creación",
            }}
            textFieldSearchBy={{
              items: searchItems,
              onChange: handleChangeSearchBy,
              onItemSelected: handleSelectSearchBy,
              placeholder: "Buscar por Alias",
            }}
          />
          <TableSimple
            data-testid="NotificationsContainerTableSimple"
            header={{
              cells: columns,
              headerProps: {
                isDisable: false,
              },
            }}
            rows={rows}
            showPagination={totalItems > itemsPerPage}
            isLoading={isLoading}
            isInitialLoading={isLoading}
            footerPagination={{
              isDisable: false,
              itemsPerPage: itemsPerPage,
              onItemsPerPageChange: changeItemsPerPage,
              onPageClick: handlePageClick,
              paginationText: "Notificaciones por página",
              selectedPage,
              textFieldSelector: [5, 10, 25, 50, 75, 100],
              totalItems,
            }}
          />
          <ModalSimple
            onClickPrimary={handlers.delete}
            onClickSecondary={deleteWebhookModalSimple.close}
            titleText={WebhookModalDelete.TITLE}
            descriptionText={WebhookModalDelete.DESCRIPTION}
            buttonPrimary={WebhookModalDelete.BTN_PRIMARY}
            buttonSecondary={WebhookModalDelete.BTN_SECONDARY}
            isOpen={deleteWebhookModalSimple.isOpen}
            onClose={deleteWebhookModalSimple.close}
            typeModal={1}
          />
          <ModalLoader
            descriptionText={WebhookModalLoader.DESCRIPTION}
            isOpen={deleteWebhookModalLoader.isOpen}
            titleText={WebhookModalLoader.TITLE}
          />
          <WebhookSimulationModal
            open={simulateWebhookModal.isOpen}
            webhook={selectedWebhook}
            handleClose={simulateWebhookModal.close}
          />
        </>
      )}
    </Box>
  );
};

export default WebhooksContainer;
