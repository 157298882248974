import { createNamedStyles } from "@shared/interfaces/create_named_styles";

export const alarmConfigFormStyles = createNamedStyles({
  container: {
    marginBottom: "32px",
  },
  subtitle: {
    marginBottom: "22px",
  },
  alarmInfoInputs: {
    display: "flex",
    gap: "30px",
  },
  divider: {
    border: "none",
    margin: "32px 0px 22px 0px",
  },
  addButton: {
    padding: "0px",
    fontSize: "14px",
    border: "none !important",
  },
  headerWrapper: {
    display: "flex",
    gap: "10px",
    alignItems: "flex-start",
  },
});
