import React, { useEffect, useState } from "react";
import { IUseSelectComponentSectionState } from "../../../../../shared/infrastructure/interfaces/IUseSelectComponentSectionState";
import { ISelectComponentProps } from "../SelectComponentSection";
import { isEmpty } from "lodash";
import { ICategory } from "@kushki/connect-ui/dist/Components/Molecules/Form/SelectCheck/SelectCheck.interfaces";

export const useSelectComponentSectionState = (
  props: ISelectComponentProps
): IUseSelectComponentSectionState => {
  const [selectValue, setSelectValue] = useState<string>("");

  const [savedValue] = useState<string[]>(props.selectedValue || []);

  useEffect(() => {
    const dataToRetrieve: ICategory[] = savedValue
      .filter((data: string) => !isEmpty(data))
      .map((data) => {
        setSelectValue(data);
        return {
          label: props.conditionProperty,
          value: data,
          selected: true,
        };
      });

    props.setSelectedItems(dataToRetrieve);
  }, [savedValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectValue(event.target.value);
    props.setSelectedItems([
      {
        label: props.conditionProperty,
        value: event.target.value,
        selected: true,
      },
    ]);
  };

  return {
    selectValue,
    setSelectValue,
    handleChange,
  };
};
