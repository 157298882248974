import React from "react";

import { useStylesMerchantMigration } from "./MerchantMigrationIndex.styles";
import { useMerchantMigrationIndexState } from "./state/useMerchantMigrationIndexState";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
} from "@material-ui/core";
import { MerchantMigrationEnum } from "../../shared/constants/infrastructure/MerchantMigrationEnum";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { GenericConfirmModal } from "../../components/GenericConfirmModal/GenericConfirmModal";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { SecurityWrapperEnum } from "../../shared/constants/SecurityWrapperEnum";
import { OptionOperationExportEnum } from "../../shared/constants/OptionOperationExportEnum";

export const MerchantMigrationIndex: React.FC = () => {
  const { openModal, handleCloseModal, handleCallExport, modalData } =
    useMerchantMigrationIndexState();
  const styles = useStylesMerchantMigration();

  return (
    <>
      <Container fixed>
        <Box>
          <Breadcrumb
            items={[]}
            lastItem={MerchantMigrationEnum.TITLE_SECTION}
          />
        </Box>
        <Box className={styles.boxTitle}>
          <Typography variant={"h1"} color={"primary"}>
            {MerchantMigrationEnum.TITLE_SECTION}
          </Typography>
        </Box>
        <Card>
          <CardContent className={styles.content}>
            <Box className={styles.contentBox}>
              <Box className={styles.boxText}>
                <Typography variant="h6" className={styles.titles}>
                  {MerchantMigrationEnum.STEP_ONE}
                </Typography>
                <Box>
                  <ul className={styles.ul}>
                    <li>{MerchantMigrationEnum.TEXT_ONE}</li>
                    <li>{MerchantMigrationEnum.TEXT_TWO}</li>
                    <li>{MerchantMigrationEnum.TEXT_THREE}</li>
                  </ul>
                </Box>
                <ComponentSecurityWrapper
                  componentId={SecurityWrapperEnum.MERCHANT_DOWNLOAD}
                >
                  <Button
                    onClick={() =>
                      handleCallExport(OptionOperationExportEnum.MERCHANTS)
                    }
                    className={styles.downloadButton}
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    {MerchantMigrationEnum.DOWNLOAD_BTN}
                  </Button>
                </ComponentSecurityWrapper>
              </Box>
              <Box className={styles.boxText}>
                <Typography variant="h6" className={styles.titles}>
                  {MerchantMigrationEnum.STEP_TWO}
                </Typography>
                <Box>
                  <ul className={styles.ul}>
                    <li>{MerchantMigrationEnum.TEXT_ONE_STEP_TWO}</li>
                    <li>{MerchantMigrationEnum.TEXT_TWO_STEP_TWO}</li>
                  </ul>
                </Box>
                <ComponentSecurityWrapper
                  componentId={SecurityWrapperEnum.HIERARCHY_DOWNLOAD}
                >
                  <Button
                    onClick={() =>
                      handleCallExport(OptionOperationExportEnum.HIERARCHY)
                    }
                    className={styles.downloadButton}
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    {MerchantMigrationEnum.DOWNLOAD_BTN}
                  </Button>
                </ComponentSecurityWrapper>
              </Box>
            </Box>
            <GenericConfirmModal
              openModal={openModal}
              title={modalData.title}
              subTitle={modalData.subTitle}
              handleCloseModal={handleCloseModal}
              buttonText={MerchantMigrationEnum.ACCEPT_BTN}
            />
          </CardContent>
        </Card>
      </Container>
    </>
  );
};
