import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { DownloadFileResponse } from "../../../../types/download_file_response";

export const downloadFile = createAsyncThunk<
  DownloadFileResponse,
  { path: string; status: string }
>("app/downloadFile", async ({ path, status }) => {
  const response = await axios.post<DownloadFileResponse>(
    API_ROUTES.DOWNLOAD_FILE,
    { path, status }
  );

  return response.data;
});
