export const ACCEPT_BTN_TXT = "Sí, eliminar";
export const CANCEL_BTN_TXT = "Cancelar";
export const DESCRIPTION_TXT =
  "Se borrará toda la información de la cuenta bancaria seleccionada";
export const MODAL_TITLE_TXT =
  "¿Estás seguro de que deseas eliminar la cuenta bancaria?";

export enum AccountOrder {
  ALTERNATE = "alternate",
  PRINCIPAL = "principal",
}

export enum AccountType {
  AHORROS = "0",
  DEBITO = "1",
}
