import { get } from "lodash";
import { PaymentMethodsEnum } from "../infraestructure/payment-method";

import { responseJson } from "../../components/CardInformation/CardInformation.interfaces";

import { Chargeback } from "../../../types/chargeback";

export const jsonToString = (transactionInfo: Chargeback): responseJson => {
  const metadata_obj: {
    metadata: object;
    subscriptionMetadata: object;
  } = {
    metadata: get(transactionInfo, "metadata", {}),
    subscriptionMetadata: get(transactionInfo, "subscription_metadata", {}),
  };
  const taxes_obj: {
    taxes: object;
  } = {
    taxes: get(transactionInfo, "taxes", {}),
  };

  const metadataCredentials_obj: {
    metadata: object;
  } = {
    metadata: get(transactionInfo, "credential_metadata", {}),
  };
  let metadata: string;
  let metadataCredentials: string;
  let taxes: string;
  let value_tax: number = 0;

  if (
    get(transactionInfo, "payment_method", "") === PaymentMethodsEnum.CARD ||
    get(transactionInfo, "subscription_id", "") === ""
  ) {
    Reflect.deleteProperty(metadata_obj, "subscriptionMetadata");
  }

  for (let i in taxes_obj.taxes) {
    if (taxes_obj.taxes.hasOwnProperty(i)) {
      value_tax += taxes_obj.taxes[i];
    }
  }
  metadata = JSON.stringify(metadata_obj, null, " ");
  metadataCredentials = JSON.stringify(metadataCredentials_obj, null, " ");
  taxes = JSON.stringify(taxes_obj, null, " ");

  return {
    metadata,
    metadataCredentials,
    taxes,
    value_tax: value_tax.toString(),
  };
};
