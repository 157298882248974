import {
  getDateFromNumber,
  getDateFromString,
} from "../../../../../../shared/utils/date_utils";
import { TimeLineItem } from "../../../../../../shared/interfaces/TimeLineItem.interfaces";
import { transactionIconMap } from "../../../../../../shared/constants/transactionIconMap";
import {
  ITimeLineTabState,
  IUseTimeLineTabStateProps,
} from "./useTimeLineTabState.interfaces";
import { Observation } from "../../../../../../../types/get_conciliation_transactions_response";
import ApprovedTransactionIcon from "../../../../../../assets/icons/transactionsStates/ApprovedTransactionIcon";
import {
  CONCILIATION_ACTION_TIME_LINE_TRANSLATE,
  ConciliationActionTimeLine,
} from "../../../../../../shared/infrastructure/constants/ConciliationAction";
import { defaultTo, get } from "lodash";
import { FC } from "react";
import { SvgIconProps } from "@mui/material";

const getItemTitle = (conciliationAction: string): string => {
  return defaultTo(
    CONCILIATION_ACTION_TIME_LINE_TRANSLATE[conciliationAction],
    ""
  );
};

const getItemIcon = (reason: string) => {
  return transactionIconMap[`${reason}`] ?? ApprovedTransactionIcon;
};

const getDateTime = (
  modifiedDate: string | undefined
): { date: string; hour: string } => {
  const isNumberDate: boolean = isNaN(Number(modifiedDate));

  if (modifiedDate) {
    return isNumberDate
      ? getDateFromString(modifiedDate)
      : getDateFromNumber(Number(modifiedDate));
  }

  return { date: "", hour: "" };
};

const useTimeLineTabState = ({
  historicalItems,
}: IUseTimeLineTabStateProps): ITimeLineTabState => {
  const sortedHistoricalItems = (historicalItems ?? [])
    .slice()
    .sort((a, b) => Number(a.updatedAt) - Number(b.updatedAt));

  const timelineItems = sortedHistoricalItems.map<TimeLineItem>(
    (hItem: Observation, index: number) => {
      const conciliationAction: string = get(hItem, "conciliationAction", "");
      const title: string = getItemTitle(
        index === 0
          ? ConciliationActionTimeLine.TRX_CREATED
          : conciliationAction
      );
      const icon: FC<SvgIconProps> = getItemIcon(conciliationAction);

      const role: string | undefined = hItem.userRole;
      const { date, hour } = getDateTime(hItem.modifiedDate);
      const name: string =
        get(hItem, "user", "") === "SYSTEM"
          ? "Software"
          : get(hItem, "user", "");
      const reason: string | undefined = hItem.reason;

      return {
        date,
        hour,
        icon,
        name,
        reason,
        role,
        title,
      };
    }
  );

  return {
    items: timelineItems,
  };
};

export default useTimeLineTabState;
