import React from "react";

export const OutlinedCircleIcon: React.FC = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="9.5" stroke="#CBD5E0" strokeWidth="1" />
    </svg>
  );
};
