export const stylesBasicInformation = {
  container: {
    ".MuiGrid-item": {
      padding: "8px",
    },
    margin: 0,
    justifyContent: "flex-start",
    width: "100%",
  },
  inputAdornmentDescription: {
    alignSelf: "end",
    fontSize: "smaller",
  },
  btnSubmit: {
    backgroundColor: "#BFDFFA",
    border: "1px solid #BFDFFA",
    color: "#FFFFFF",
  },
};
