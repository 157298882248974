import { ISelectNewItemList } from "../../components/SelectNewItem/SelectNewItem.interfaces";

export const COUNTRIES_CONSTITUTIONAL: ISelectNewItemList[] = [
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/brasilFlag%402x.png",
    label: "Brasil",
    value: "Brazil",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/chileFlag%402x.png",
    label: "Chile",
    value: "Chile",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/colombiaFlag%402x.png",
    label: "Colombia",
    value: "Colombia",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/costaRicaFlag%402x.png",
    label: "Costa Rica",
    value: "CostaRica",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/ecuadorFlag%402x.png",
    label: "Ecuador",
    value: "Ecuador",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/salvadorFlag%402x.png",
    label: "El Salvador",
    value: "ElSalvador",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/guatemalaFlag%402x.png",
    label: "Guatemala",
    value: "Guatemala",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/hondurasFlag%402x.png",
    label: "Honduras",
    value: "Honduras",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/mexico%402x.png",
    label: "México",
    value: "Mexico",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/nicaraguaFlag%402x.png",
    label: "Nicaragua",
    value: "Nicaragua",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/panamaFlag%402x.png",
    label: "Panamá",
    value: "Panama",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/peruFlag%402x.png",
    label: "Perú",
    value: "Peru",
  },
  {
    flag: "https://kushki-static.s3.amazonaws.com/spa-backoffice/billing-merchant/usaFlag%402x.png",
    label: "Estados Unidos",
    value: "EEUU",
  },
  {
    label: "Afganistán",
    value: "Afganistan",
  },
  {
    label: "Albania",
    value: "Albania",
  },
  {
    label: "Alemania",
    value: "Alemania",
  },
  {
    label: "Andorra",
    value: "Andorra",
  },
  {
    label: "Angola",
    value: "Angola",
  },
  {
    label: "Anguila",
    value: "Anguila",
  },
  {
    label: "Antártida",
    value: "Antartida",
  },
  {
    label: "Antigua y Barbuda",
    value: "AntiguayBarbuda",
  },
  {
    label: "Arabia Saudita",
    value: "ArabiaSaudita",
  },
  {
    label: "Argelia",
    value: "Argelia",
  },
  {
    label: "Argentina",
    value: "Argentina",
  },
  {
    label: "Armenia",
    value: "Armenia",
  },
  {
    label: "Aruba",
    value: "Aruba",
  },
  {
    label: "Australia",
    value: "Australia",
  },
  {
    label: "Austria",
    value: "Austria",
  },
  {
    label: "Azerbaiyán",
    value: "Azerbaiyan",
  },
  {
    label: "Bélgica",
    value: "Belgica",
  },
  {
    label: "Bahamas",
    value: "Bahamas",
  },
  {
    label: "Bahrein",
    value: "Bahrein",
  },
  {
    label: "Bangladesh",
    value: "Bangladesh",
  },
  {
    label: "Barbados",
    value: "Barbados",
  },
  {
    label: "Belice",
    value: "Belice",
  },
  {
    label: "Benín",
    value: "Benín",
  },
  {
    label: "Bhután",
    value: "Bhutan",
  },
  {
    label: "Bielorrusia",
    value: "Bielorrusia",
  },
  {
    label: "Birmania",
    value: "Birmania",
  },
  {
    label: "Bolivia",
    value: "Bolivia",
  },
  {
    label: "Bosnia y Herzegovina",
    value: "BosniayHerzegovina",
  },
  {
    label: "Botsuana",
    value: "Botsuana",
  },
  {
    label: "Brunéi",
    value: "Brunei",
  },
  {
    label: "Bulgaria",
    value: "Bulgaria",
  },
  {
    label: "Burkina Faso",
    value: "BurkinaFaso",
  },
  {
    label: "Burundi",
    value: "Burundi",
  },
  {
    label: "Cabo Verde",
    value: "CaboVerde",
  },
  {
    label: "Camboya",
    value: "Camboya",
  },
  {
    label: "Camerún",
    value: "Camerún",
  },
  {
    label: "Canadá",
    value: "Canada",
  },
  {
    label: "Chad",
    value: "Chad",
  },
  {
    label: "China",
    value: "China",
  },
  {
    label: "Chipre",
    value: "Chipre",
  },
  {
    label: "Ciudad del Vaticano",
    value: "CiudaddelVaticano",
  },
  {
    label: "Comoras",
    value: "Comoras",
  },
  {
    label: "República del Congo",
    value: "RepublicadelCongo",
  },
  {
    label: "República Democrática del Congo",
    value: "RepublicaDemocraticadelCongo",
  },
  {
    label: "Corea del Norte",
    value: "CoreadelNorte",
  },
  {
    label: "Corea del Sur",
    value: "CoreadelSur",
  },
  {
    label: "Costa de Marfil",
    value: "CostadeMarfil",
  },
  {
    label: "Croacia",
    value: "Croacia",
  },
  {
    label: "Cuba",
    value: "Cuba",
  },
  {
    label: "Curazao",
    value: "Curazao",
  },
  {
    label: "Dinamarca",
    value: "Dinamarca",
  },
  {
    label: "Dominica",
    value: "Dominica",
  },
  {
    label: "Egipto",
    value: "Egipto",
  },
  {
    label: "Emiratos Árabes Unidos",
    value: "EmiratosArabesUnidos",
  },
  {
    label: "Eritrea",
    value: "Eritrea",
  },
  {
    label: "Eslovaquia",
    value: "Eslovaquia",
  },
  {
    label: "Eslovenia",
    value: "Eslovenia",
  },
  {
    label: "España",
    value: "Espania",
  },
  {
    label: "Estonia",
    value: "Estonia",
  },
  {
    label: "Etiopía",
    value: "Etiopia",
  },
  {
    label: "Filipinas",
    value: "Filipinas",
  },
  {
    label: "Finlandia",
    value: "Finlandia",
  },
  {
    label: "Fiyi",
    value: "Fiyi",
  },
  {
    label: "Francia",
    value: "Francia",
  },
  {
    label: "Gabón",
    value: "Gabon",
  },
  {
    label: "Gambia",
    value: "Gambia",
  },
  {
    label: "Georgia",
    value: "Georgia",
  },
  {
    label: "Ghana",
    value: "Ghana",
  },
  {
    label: "Gibraltar",
    value: "Gibraltar",
  },
  {
    label: "Granada",
    value: "Granada",
  },
  {
    label: "Grecia",
    value: "Grecia",
  },
  {
    label: "Groenlandia",
    value: "Groenlandia",
  },
  {
    label: "Guadalupe",
    value: "Guadalupe",
  },
  {
    label: "Guam",
    value: "Guam",
  },
  {
    label: "Guayana Francesa",
    value: "GuayanaFrancesa",
  },
  {
    label: "Guernsey",
    value: "Guernsey",
  },
  {
    label: "Guinea",
    value: "Guinea",
  },
  {
    label: "Guinea Ecuatorial",
    value: "GuineaEcuatorial",
  },
  {
    label: "Guinea-Bissau",
    value: "GuineaBissau",
  },
  {
    label: "Guyana",
    value: "Guyana",
  },
  {
    label: "Haití",
    value: "Haiti",
  },
  {
    label: "Hong kong",
    value: "Hongkong",
  },
  {
    label: "Hungría",
    value: "Hungria",
  },
  {
    label: "India",
    value: "India",
  },
  {
    label: "Indonesia",
    value: "Indonesia",
  },
  {
    label: "Irán",
    value: "Iran",
  },
  {
    label: "Irak",
    value: "Irak",
  },
  {
    label: "Irlanda",
    value: "Irlanda",
  },
  {
    label: "Isla Bouvet",
    value: "IslaBouvet",
  },
  {
    label: "Isla de Man",
    value: "IsladeMan",
  },
  {
    label: "Isla de Navidad",
    value: "IsladeNavidad",
  },
  {
    label: "Isla Norfolk",
    value: "IslaNorfolk",
  },
  {
    label: "Islandia",
    value: "Islandia",
  },
  {
    label: "Islas Bermudas",
    value: "IslasBermudas",
  },
  {
    label: "Islas Caimán",
    value: "IslasCaiman",
  },
  {
    label: "Islas Cocos (Keeling)",
    value: "IslasCocos",
  },
  {
    label: "Islas Cook",
    value: "IslasCook",
  },
  {
    label: "Islas de Åland",
    value: "IslasdeAland",
  },
  {
    label: "Islas Feroe",
    value: "IslasFeroe",
  },
  {
    label: "Islas Georgias del Sur y Sandwich del Sur",
    value: "IslasGeorgiasdelSurySandwichdelSur",
  },
  {
    label: "Islas Heard y McDonald",
    value: "IslasHeardyMcDonald",
  },
  {
    label: "Islas Maldivas",
    value: "IslasMaldivas",
  },
  {
    label: "Islas Malvinas",
    value: "IslasMalvinas",
  },
  {
    label: "Islas Marianas del Norte",
    value: "IslasMarianasdelNorte",
  },
  {
    label: "Islas Marshall",
    value: "IslasMarshall",
  },
  {
    label: "Islas Pitcairn",
    value: "IslasPitcairn",
  },
  {
    label: "Islas Salomón",
    value: "IslasSalomon",
  },
  {
    label: "Islas Turcas y Caicos",
    value: "IslasTurcasyCaicos",
  },
  {
    label: "Islas Ultramarinas Menores de Estados Unidos",
    value: "IslasUltramarinasMenoresdeEstadosUnidos",
  },
  {
    label: "Islas Vírgenes Británicas",
    value: "IslasVirgenesBritanicas",
  },
  {
    label: "Islas Vírgenes de los Estados Unidos",
    value: "IslasVirgenesdelosEstadosUnidos",
  },
  {
    label: "Israel",
    value: "Israel",
  },
  {
    label: "Italia",
    value: "Italia",
  },
  {
    label: "Jamaica",
    value: "Jamaica",
  },
  {
    label: "Japón",
    value: "Japon",
  },
  {
    label: "Jersey",
    value: "Jersey",
  },
  {
    label: "Jordania",
    value: "Jordania",
  },
  {
    label: "Kazajistán",
    value: "Kazajistan",
  },
  {
    label: "Kenia",
    value: "Kenia",
  },
  {
    label: "Kirguistán",
    value: "Kirguistan",
  },
  {
    label: "Kiribati",
    value: "Kiribati",
  },
  {
    label: "Kuwait",
    value: "Kuwait",
  },
  {
    label: "Líbano",
    value: "Libano",
  },
  {
    label: "Laos",
    value: "Laos",
  },
  {
    label: "Lesoto",
    value: "Lesoto",
  },
  {
    label: "Letonia",
    value: "Letonia",
  },
  {
    label: "Liberia",
    value: "Liberia",
  },
  {
    label: "Libia",
    value: "Libia",
  },
  {
    label: "Liechtenstein",
    value: "Liechtenstein",
  },
  {
    label: "Lituania",
    value: "Lituania",
  },
  {
    label: "Luxemburgo",
    value: "Luxemburgo",
  },
  {
    label: "Mónaco",
    value: "Monaco",
  },
  {
    label: "Macao",
    value: "Macao",
  },
  {
    label: "Macedônia",
    value: "Macedônia",
  },
  {
    label: "Madagascar",
    value: "Madagascar",
  },
  {
    label: "Malasia",
    value: "Malasia",
  },
  {
    label: "Malawi",
    value: "Malawi",
  },
  {
    label: "Mali",
    value: "Mali",
  },
  {
    label: "Malta",
    value: "Malta",
  },
  {
    label: "Marruecos",
    value: "Marruecos",
  },
  {
    label: "Martinica",
    value: "Martinica",
  },
  {
    label: "Mauricio",
    value: "Mauricio",
  },
  {
    label: "Mauritania",
    value: "Mauritania",
  },
  {
    label: "Mayotte",
    value: "Mayotte",
  },
  {
    label: "Micronesia",
    value: "Micronesia",
  },
  {
    label: "Moldavia",
    value: "Moldavia",
  },
  {
    label: "Mongolia",
    value: "Mongolia",
  },
  {
    label: "Montenegro",
    value: "Montenegro",
  },
  {
    label: "Montserrat",
    value: "Montserrat",
  },
  {
    label: "Mozambique",
    value: "Mozambique",
  },
  {
    label: "Namibia",
    value: "Namibia",
  },
  {
    label: "Nauru",
    value: "Nauru",
  },
  {
    label: "Nepal",
    value: "Nepal",
  },
  {
    label: "Niger",
    value: "Niger",
  },
  {
    label: "Nigeria",
    value: "Nigeria",
  },
  {
    label: "Niue",
    value: "Niue",
  },
  {
    label: "Noruega",
    value: "Noruega",
  },
  {
    label: "Nueva Caledonia",
    value: "NuevaCaledonia",
  },
  {
    label: "Nueva Zelanda",
    value: "NuevaZelanda",
  },
  {
    label: "Omán",
    value: "Oman",
  },
  {
    label: "Países Bajos",
    value: "PaisesBajos",
  },
  {
    label: "Pakistán",
    value: "Pakistan",
  },
  {
    label: "Palau",
    value: "Palau",
  },
  {
    label: "Palestina",
    value: "Palestina",
  },
  {
    label: "Papúa Nueva Guinea",
    value: "PapuaNuevaGuinea",
  },
  {
    label: "Paraguay",
    value: "Paraguay",
  },
  {
    label: "Polinesia Francesa",
    value: "PolinesiaFrancesa",
  },
  {
    label: "Polonia",
    value: "Polonia",
  },
  {
    label: "Portugal",
    value: "Portugal",
  },
  {
    label: "Puerto Rico",
    value: "PuertoRico",
  },
  {
    label: "Qatar",
    value: "Qatar",
  },
  {
    label: "Reino Unido",
    value: "ReinoUnido",
  },
  {
    label: "República Centroafricana",
    value: "RepublicaCentroafricana",
  },
  {
    label: "República Checa",
    value: "RepublicaCheca",
  },
  {
    label: "República Dominicana",
    value: "RepublicaDominicana",
  },
  {
    label: "República de Sudán del Sur",
    value: "RepublicadeSudandelSur",
  },
  {
    label: "Reunión",
    value: "Reunion",
  },
  {
    label: "Ruanda",
    value: "Ruanda",
  },
  {
    label: "Rumanía",
    value: "Rumania",
  },
  {
    label: "Rusia",
    value: "Rusia",
  },
  {
    label: "Sahara Occidental",
    value: "SaharaOccidental",
  },
  {
    label: "Samoa",
    value: "Samoa",
  },
  {
    label: "Samoa Americana",
    value: "SamoaAmericana",
  },
  {
    label: "San Bartolomé",
    value: "SanBartolome",
  },
  {
    label: "San Cristóbal y Nieves",
    value: "SanCristobalyNieves",
  },
  {
    label: "San Marino",
    value: "SanMarino",
  },
  {
    label: "San Martín (Francia)",
    value: "SanMartinFrancia",
  },
  {
    label: "San Pedro y Miquelón",
    value: "SanPedroyMiquelon",
  },
  {
    label: "San Vicente y las Granadinas",
    value: "SanVicenteylasGranadinas",
  },
  {
    label: "Santa Elena",
    value: "SantaElena",
  },
  {
    label: "Santa Lucía",
    value: "SantaLucia",
  },
  {
    label: "Santo Tomé y Príncipe",
    value: "SantoTomeyPrincipe",
  },
  {
    label: "Senegal",
    value: "Senegal",
  },
  {
    label: "Serbia",
    value: "Serbia",
  },
  {
    label: "Seychelles",
    value: "Seychelles",
  },
  {
    label: "Sierra Leona",
    value: "SierraLeona",
  },
  {
    label: "Singapur",
    value: "Singapur",
  },
  {
    label: "Sint Maarten",
    value: "SintMaarten",
  },
  {
    label: "Siria",
    value: "Siria",
  },
  {
    label: "Somalia",
    value: "Somalia",
  },
  {
    label: "Sri lanka",
    value: "Srilanka",
  },
  {
    label: "Sudáfrica",
    value: "Sudafrica",
  },
  {
    label: "Sudán",
    value: "Sudan",
  },
  {
    label: "Suecia",
    value: "Suecia",
  },
  {
    label: "Suiza",
    value: "Suiza",
  },
  {
    label: "Surinám",
    value: "Surinam",
  },
  {
    label: "Svalbard y Jan Mayen",
    value: "SvalbardyJan Mayen",
  },
  {
    label: "Swazilandia",
    value: "Swazilandia",
  },
  {
    label: "Tayikistán",
    value: "Tayikistan",
  },
  {
    label: "Tailandia",
    value: "Tailandia",
  },
  {
    label: "Taiwán",
    value: "Taiwan",
  },
  {
    label: "Tanzania",
    value: "Tanzania",
  },
  {
    label: "Territorio Británico del Océano Índico",
    value: "TerritorioBritanicodelOceanoIndico",
  },
  {
    label: "Territorios Australes y Antárticas Franceses",
    value: "TerritoriosAustralesyAntarticasFranceses",
  },
  {
    label: "Timor Oriental",
    value: "TimorOriental",
  },
  {
    label: "Togo",
    value: "Togo",
  },
  {
    label: "Tokelau",
    value: "Tokelau",
  },
  {
    label: "Tonga",
    value: "Tonga",
  },
  {
    label: "Trinidad y Tobago",
    value: "TrinidadyTobago",
  },
  {
    label: "Tunez",
    value: "Tunez",
  },
  {
    label: "Turkmenistán",
    value: "Turkmenistan",
  },
  {
    label: "Turquía",
    value: "Turquia",
  },
  {
    label: "Tuvalu",
    value: "Tuvalu",
  },
  {
    label: "Ucrania",
    value: "Ucrania",
  },
  {
    label: "Uganda",
    value: "Uganda",
  },
  {
    label: "Uruguay",
    value: "Uruguay",
  },
  {
    label: "Uzbekistán",
    value: "Uzbekistan",
  },
  {
    label: "Vanuatu",
    value: "Vanuatu",
  },
  {
    label: "Venezuela",
    value: "Venezuela",
  },
  {
    label: "Vietnam",
    value: "Vietnam",
  },
  {
    label: "Wallis y Futuna",
    value: "WallisyFutuna",
  },
  {
    label: "Yemen",
    value: "Yemen",
  },
  {
    label: "Yibuti",
    value: "Yibuti",
  },
  {
    label: "Zambia",
    value: "Zambia",
  },
  {
    label: "Zimbabue",
    value: "Zimbabue",
  },
];
