export const ALERT_SUCCESS = "La orden de recarga se ha generado con éxito.";
export const ALERT_FAIL =
  "Ha ocurrido un error al generar la recarga. Vuelve a intentarlo.";
export const RECHARGE_TITLE = "Recargar Billetera";
export const NAVIGATION_INDEX = "Inicio";
export const NAVIGATION_RECHARGE = "Recargar Billetera";
export const NAVIGATION_CHECKOUT = "Checkout";
export const RECHARGE_DATA = "Datos de recarga";
export const RECHARGE_QUESTION = "¿Cúanto quieres recargar?";
export const REQUIRED_INPUT = "Este campo es obligatorio *";
export const ACCEPT_CONDITIONS_MESSAGE =
  "Acepto los Términos y Condiciones de servicio.";
export const ACCEPT_CONDITIONS_ERROR =
  "Debe Aceptar los Términos y Condiciones.";
export const CANCEL_BUTTON = "Cancelar";
export const RECHARGE_BUTTON = "Recargar";
export const PERSONAL_DATA = "Datos personales";
export const NAME_PLACEHOLDER = "Nombre y apellido";
export const PAYMENT_DESCRIPTION = "Concepto de pago";
export const PAYMENT_DESCRIPTION_PLACEHOLDER = "Nombre del Comercio";
export const EMAIL_PLACEHOLDER = "Email";
export const MINIMAL_MOUNT_ERROR = "Ingresa un monto mayor o igual a ";
export const EMAIL_VALUE_ERROR = "Ingresa un correo válido.";
