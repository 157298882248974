import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const selectSectionContainerStyles = createNamedStyles({
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    marginTop: "1%",
    marginBottom: "8%",
    justifyContent: "center",
  },
  optionsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    maxWidth: "800px",
    gap: "12px",
  },
  centered: {
    textAlign: "center",
  },
  ulCentered: {
    textAlign: "center",
    listStylePosition: "inside",
  },
});
