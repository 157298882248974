import React, { RefObject, useEffect, useMemo, useState } from "react";
import { BreadcrumProps } from "../../../components/common/Breadcrumb/Breadcrumb";
import { routes } from "../../../shared/infrastructure/routes";
import { Control, FieldError, NestDataObject, useForm } from "react-hook-form";
import {
  get,
  includes,
  isArray,
  isBoolean,
  isEmpty,
  isObject,
  isString,
} from "lodash";
import { TMerchantDetailsIndexProps } from "../MerchantDetailsIndex";
import {
  AdditionalStepsEnum,
  getStepProcessorsStatus,
  StatusSemaphore,
  StepName,
  StepsEnum,
} from "../../../shared/infrastructure/constants/CreateMerchantConstants";
import { IAppState, StatusStepsState } from "../../../store/reducer";
import {
  SemaphoreData,
  StatusSteps,
} from "../../../../types/remote/semaphore_data";
import { useLocation } from "react-router-dom";
import { User } from "../../../../types/merchant_users";
import { MerchantActiveNotificationRequest } from "../../../../types/merchant_active_notification_request";
import { Color } from "@material-ui/lab/Alert";
import { MerchantProcessors } from "../../../../types/merchant_processors";
import { COUNTRY_DEFAULT_DEFERRED } from "../../../shared/infrastructure/catalogs/CountryEnum";

export type IState = IAppState & {
  expandedSection: ExpandedSection;
  isNewMerchant: boolean;
  isDisableUserStep: boolean;
};

export interface MerchantDetailsIndexState {
  state: IState;
  breadCrumbs: BreadcrumProps;
  handlers: {
    handleSectionExpansion: (
      step: StepsEnum | AdditionalStepsEnum
    ) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
    changeStepStatus: (step: StepName, status: StatusSteps) => void;
  };
  form: {
    errors: NestDataObject<Record<string, any>, FieldError>;
    register: (
      obj: object
    ) => ((instance: any) => void) | RefObject<any> | null | undefined;
    submit: () => void;
    getValues: (payload?: { nest: boolean } | undefined) => Record<string, any>;
    control: Control<Record<string, any>>;
  };
  open: boolean;
  handleOpen: () => void;
  handleBackPage: () => void;
  handleNotify: () => void;
  handleOmit: () => void;
  isCompleted: boolean;
  isOmitted: boolean;
  isLoading: boolean;
}

export type ExpandedSection = StepsEnum | AdditionalStepsEnum | undefined;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const getServices = (
  steps: StatusStepsState[],
  props: TMerchantDetailsIndexProps
) => {
  let activeServices = [];
  if (
    steps.find((step) => step.name === StepsEnum.stepServices)!.status ===
    "omitted"
  ) {
    activeServices.push("Smartlinks");
    return activeServices;
  }
  if (get(props.state.merchantServices, "webcheckoutResponse.status"))
    activeServices.push("Smartlinks");
  if (get(props.state.merchantServices, "vposResponse.active"))
    activeServices.push("Virtual POS");
  if (get(props.state.merchantServices, "cashResponse.preAuthUrl"))
    activeServices.push("Efectivo (URL de Pre autorización)");
  return activeServices;
};

export const getPaymentTypes = (props: TMerchantDetailsIndexProps) => {
  let activePaymentTypes = [];
  const processors = get(props, "state.processors", []);
  if (get(processors, "card", []).length > 0) activePaymentTypes.push("Card");
  if (get(processors, "cash", []).length > 0) activePaymentTypes.push("Cash");
  if (get(processors, "transfer", []).length > 0)
    activePaymentTypes.push("Transfer");
  return activePaymentTypes;
};

export const getMasterUsers = (props: TMerchantDetailsIndexProps) => {
  const users: Array<User> = get(props, "state.merchantUsers.Users", []);
  let userName: string = "";
  let emails: string[] = [];
  users.forEach((user) => {
    user.Groups.forEach((group) => {
      if (group.GroupName === "BackofficeUserMaster") {
        user.Attributes.forEach((attribute) => {
          if (attribute.Name === "email") {
            emails.push(attribute.Value);
          }
        });
        userName = user.Username;
      }
    });
  });
  return { userName, emails };
};

export const checkProcessorsByType = (
  props: TMerchantDetailsIndexProps,
  type: string
): boolean => {
  const processors = get(props, "state.processors", []);
  return get(processors, type, []).length > 0;
};

export const getProcessorsByType = (
  props: TMerchantDetailsIndexProps,
  type: string
): MerchantProcessors[] => {
  const processors = get(props, "state.processors", []);
  return get(processors, type, []);
};

const updateStepDefaultProcessor = (props: TMerchantDetailsIndexProps) => {
  if (!props.state.semaphore) return;
  let childProcessorSteps = [
    {
      childStepName: "statusProcessor",
      newStatus: StatusSemaphore.inProcess,
    },
  ];
  props.updateMultipleChildrenStatus(
    get(props, "state.merchantAffiliation.publicMerchantId", ""),
    StepsEnum.stepProcessor,
    childProcessorSteps
  );
  props.changeStepStatus(
    "stepProcessor",
    getStepProcessorsStatus(props.state.semaphore)
  );
};

const updateStepNoCardProcessor = (props: TMerchantDetailsIndexProps) => {
  if (!props.state.semaphore) return;
  let childProcessorSteps = [
    {
      childStepName: "statusProcessor",
      newStatus: StatusSemaphore.complete,
    },
    {
      childStepName: "statusDeferred",
      newStatus: StatusSemaphore.omitted,
    },
    {
      childStepName: "statusMerchantRules",
      newStatus: StatusSemaphore.omitted,
    },
    {
      childStepName: "statusRetryRules",
      newStatus: StatusSemaphore.omitted,
    },
  ];
  props.updateMultipleChildrenStatus(
    get(props, "state.merchantAffiliation.publicMerchantId", ""),
    StepsEnum.stepProcessor,
    childProcessorSteps
  );
  props.changeStepStatus(
    "stepProcessor",
    getStepProcessorsStatus(props.state.semaphore)
  );
};

const defaultProcessorMatchesSavedProcessors = (
  props: TMerchantDetailsIndexProps,
  processorType: string
): boolean => {
  return (
    getProcessorsByType(props, processorType).filter(
      (processor: MerchantProcessors) =>
        processor.alias ===
        get(props.state, `defaultProcessor.${processorType}`, "")
    ).length !== 0
  );
};

export const useMerchantDetailsIndexState = (
  props: TMerchantDetailsIndexProps
): MerchantDetailsIndexState => {
  const query = useQuery();
  const steps = props.state.steps || [];
  const { register, errors, getValues, control } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const [expandedSection, setExpandedSection] = useState<ExpandedSection>();
  const [open, setOpen] = React.useState(false);
  const [isCompleted, setIsCompleted] = React.useState(false);
  const [isOmitted, setIsOmitted] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  const handleLoading = () => {
    setIsLoading(!isLoading);
  };
  const handleSectionExpansion = (step: StepsEnum | AdditionalStepsEnum) => (
    _event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpandedSection(isExpanded ? step : undefined);
  };
  const handleBackPage = () => {
    localStorage.removeItem("merchantResume");
    window.location.href = "/merchant-list";
  };
  let masterUsers: { emails: any; userName: string };
  let merchantNotificationData: MerchantActiveNotificationRequest;
  const handleNotify = () => {
    masterUsers = getMasterUsers(props);
    merchantNotificationData = {
      publicMerchantId: get(
        props.state,
        "merchantAffiliation.publicMerchantId",
        ""
      ),
      username: get(masterUsers, "userName", ""),
      emails: get(masterUsers, "emails", []),
      activeServices: getServices(steps, props),
      paymentMethods: getPaymentTypes(props),
    };
    props.notifyMerchant(merchantNotificationData);
  };
  const activateMerchant = () => {
    masterUsers = getMasterUsers(props);
    if (
      get(masterUsers, "emails", []).length === 0 ||
      get(masterUsers, "userName", "") === ""
    ) {
      props.setNotification({
        message:
          "Se requiere tener al menos un usuario maestro para activar el comercio.",
        open: true,
        type: "error",
      });
      return;
    }
    handleLoading();
    props.activateMerchant(props.state.merchantAffiliation!.publicMerchantId!);
  };

  const handleOmit = () => {
    setIsOmitted(!isOmitted);
  };
  const isNewMerchant: boolean = useMemo(() => {
    const initialStep = steps.find(
      (step) => step.name === StepsEnum.stepBasicData
    );
    return Boolean(initialStep && initialStep.status !== "complete");
  }, [steps]);

  const isDisableUserStep: boolean = useMemo(() => {
    const names = [
      StepsEnum.stepBasicData,
      StepsEnum.stepConfigRatesAndInvoice,
      StepsEnum.stepProcessor,
    ];
    const step4 = steps.find((step) => step.name === StepsEnum.stepServices);
    if (step4 === undefined) return false;
    const resultOtherSteps = names.every((name) =>
      steps.some(
        (step) => step.name === name && step.status === StatusSemaphore.complete
      )
    );

    return (
      resultOtherSteps &&
      (step4.status === StatusSemaphore.omitted ||
        step4.status === StatusSemaphore.complete)
    );
  }, [steps]);

  const firstNonCompleteStep: ExpandedSection = useMemo(() => {
    const step = steps.find(
      (step) => step.status !== "complete" && step.status !== "omitted"
    );
    return step?.name;
  }, [steps]);

  useEffect(() => {
    setExpandedSection(firstNonCompleteStep);
  }, [firstNonCompleteStep]);

  useEffect(() => {
    const publicMerchantId: string | null = query.get("publicMerchantId");

    if (!publicMerchantId) return;
    props.getMerchantAndSemaphore(publicMerchantId);
  }, []);

  useEffect(() => {
    if (
      get(props, "state.notification.type", "") === "success" &&
      get(props, "state.notification.message", "") === "Activated"
    ) {
      handleLoading();
      setIsCompleted(true);
    }
    if (
      get(props, "state.notification.type", "") === "error" &&
      get(props, "state.notification.message", "") === "Activation failed"
    ) {
      handleLoading();
    }
    if (
      get(props, "state.notification.message", "") ===
      "Se envió la notificación al comercio."
    ) {
      handleBackPage();
    }
  }, [props.state.notification]);

  const getStatusProcessorsByRulesAndDeferred = (statusProcessors: string) => {
    const semaphore: SemaphoreData = props.state.semaphore!;
    semaphore.stepProcessor!.statusProcessor = statusProcessors as StatusSteps;

    return getStepProcessorsStatus(semaphore);
  };

  const updateStatusProcessorsCard = (status: StatusSteps) => {
    let childProcessorSteps: {
      childStepName: string;
      newStatus: StatusSteps;
    }[] = [
      {
        childStepName: "statusProcessor",
        newStatus: status,
      },
    ];
    props.changeStepStatus(
      "stepProcessor",
      getStatusProcessorsByRulesAndDeferred(status)
    );
    props.updateMultipleChildrenStatus(
      get(props, "state.merchantAffiliation.publicMerchantId", ""),
      StepsEnum.stepProcessor,
      childProcessorSteps
    );
  };

  const retrieveStepProcessor = (steps: StatusStepsState[]) => {
    return steps.find(
      (step: StatusStepsState) => step.name === StepsEnum.stepProcessor
    );
  };
  useEffect(() => {
    if (!props.state.steps) return;
    const stepProcessor = retrieveStepProcessor(props.state.steps);
    if (
      !stepProcessor ||
      !props.state.semaphore ||
      !props.state.defaultProcessor ||
      !props.state.processors
    )
      return;
    let processorsExist: boolean =
      Object.keys(props.state.processors).filter(
        (p: string) => props.state.processors![p].length > 0
      ).length > 0;
    let childProcessorSteps: {
      childStepName: string;
      newStatus: StatusSteps;
    }[];
    if (!processorsExist) {
      if (
        stepProcessor.status !== StatusSemaphore.pending ||
        get(
          props,
          "state.semaphore.stepProcessor.statusProcessor",
          StatusSemaphore.complete
        ) !== StatusSemaphore.pending
      ) {
        childProcessorSteps = [
          {
            childStepName: "statusProcessor",
            newStatus: StatusSemaphore.pending,
          },
          {
            childStepName: "statusDeferred",
            newStatus: StatusSemaphore.pending,
          },
          {
            childStepName: "statusMerchantRules",
            newStatus: StatusSemaphore.pending,
          },
          {
            childStepName: "statusRetryRules",
            newStatus: StatusSemaphore.pending,
          },
        ];
        props.updateMultipleChildrenStatus(
          get(props, "state.merchantAffiliation.publicMerchantId", ""),
          StepsEnum.stepProcessor,
          childProcessorSteps
        );
        props.changeStepStatus(
          "stepProcessor",
          getStepProcessorsStatus(props.state.semaphore)
        );
      }
      return;
    }
    if (
      checkProcessorsByType(props, "transfer") &&
      (get(props.state, "defaultProcessor.transfer", "") === "" ||
        !defaultProcessorMatchesSavedProcessors(props, "transfer"))
    ) {
      updateStepDefaultProcessor(props);
      return;
    }
    if (
      checkProcessorsByType(props, "ach") &&
      (get(props.state, "defaultProcessor.ach", "") === "" ||
        !defaultProcessorMatchesSavedProcessors(props, "ach"))
    ) {
      updateStepDefaultProcessor(props);
      return;
    }
    if (checkProcessorsByType(props, "card")) {
      if (
        defaultProcessorMatchesSavedProcessors(props, "card") &&
        props.state.processors.card.length === 1
      ) {
        updateStatusProcessorsCard(StatusSemaphore.complete);
        return;
      }

      if (
        defaultProcessorMatchesSavedProcessors(props, "card") &&
        props.state.processors.card.length > 1
      ) {
        const aux_processors_card: MerchantProcessors[] =
          props.state.processors.card;
        if (
          !(
            aux_processors_card.filter(
              (row: MerchantProcessors) =>
                row.processorName !== aux_processors_card[0].processorName
            ).length > 0
          )
        ) {
          updateStatusProcessorsCard(StatusSemaphore.complete);
          return;
        } else if (
          aux_processors_card.filter(
            (row: MerchantProcessors) =>
              row.processorName !== aux_processors_card[0].processorName
          ).length > 0
        ) {
          updateStatusProcessorsCard(StatusSemaphore.complete);
          return;
        }
      }
      childProcessorSteps = [
        {
          childStepName: "statusProcessor",
          newStatus: StatusSemaphore.inProcess,
        },
      ];
      props.updateMultipleChildrenStatus(
        get(props, "state.merchantAffiliation.publicMerchantId", ""),
        StepsEnum.stepProcessor,
        childProcessorSteps
      );
      props.changeStepStatus(
        "stepProcessor",
        getStepProcessorsStatus(props.state.semaphore)
      );
      return;
    }
    if (stepProcessor.status !== StatusSemaphore.complete) {
      updateStepNoCardProcessor(props);
    }
  }, [props.state.defaultProcessor, props.state.processorRules]);

  useEffect(() => {
    if (!props.state.steps) return;
    const stepProcessor = retrieveStepProcessor(props.state.steps);
    if (
      !stepProcessor ||
      !props.state.semaphore ||
      !props.state.processorRules ||
      !props.state.processorRules.items
    )
      return;
    if (
      (get(
        props,
        "state.semaphore.stepProcessor.statusMerchantRules",
        StatusSemaphore.complete
      ) === StatusSemaphore.omitted ||
        StatusSemaphore.pending) &&
      get(props, "state.processorRules.items", []).length > 0
    ) {
      let childProcessorSteps = [
        {
          childStepName: "statusMerchantRules",
          newStatus: StatusSemaphore.complete,
        },
      ];
      props.updateMultipleChildrenStatus(
        get(props, "state.merchantAffiliation.publicMerchantId", ""),
        StepsEnum.stepProcessor,
        childProcessorSteps
      );
      props.changeStepStatus(
        "stepProcessor",
        getStepProcessorsStatus(props.state.semaphore)
      );
    }
    if (
      get(
        props,
        "state.semaphore.stepProcessor.statusMerchantRules",
        StatusSemaphore.pending
      ) === StatusSemaphore.complete &&
      get(props, "state.processorRules.items", []).length === 0
    ) {
      let childProcessorSteps = [
        {
          childStepName: "statusMerchantRules",
          newStatus: StatusSemaphore.pending,
        },
      ];
      props.updateMultipleChildrenStatus(
        get(props, "state.merchantAffiliation.publicMerchantId", ""),
        StepsEnum.stepProcessor,
        childProcessorSteps
      );
      props.changeStepStatus(
        "stepProcessor",
        getStepProcessorsStatus(props.state.semaphore)
      );
    }
  }, [props.state.processorRules]);

  useEffect(() => {
    if (!props.state.steps) return;
    const stepProcessor = retrieveStepProcessor(props.state.steps);
    if (
      !props.state.merchantAffiliation ||
      !stepProcessor ||
      !props.state.semaphore ||
      !props.state.processors
    )
      return;
    let processorsExist: boolean =
      Object.keys(props.state.processors).filter(
        (p: string) => props.state.processors![p].length > 0
      ).length > 0;
    if (!processorsExist) return;
    const country = get(props, "state.merchantAffiliation.country", "");
    if (!country || country === "") return;
    if (includes(COUNTRY_DEFAULT_DEFERRED, country)) return;
    const deferredStep = get(
      props,
      "state.semaphore.stepProcessor.statusDeferred",
      StatusSemaphore.pending
    );
    if (deferredStep === StatusSemaphore.pending) {
      let childProcessorSteps = [
        {
          childStepName: "statusDeferred",
          newStatus: StatusSemaphore.omitted,
        },
      ];
      props.updateMultipleChildrenStatus(
        get(props, "state.merchantAffiliation.publicMerchantId", ""),
        StepsEnum.stepProcessor,
        childProcessorSteps
      );
      props.changeStepStatus(
        "stepProcessor",
        getStepProcessorsStatus(props.state.semaphore)
      );
    }
  }, [props.state.processors]);

  useEffect(() => {
    if (get(props, "state.retries.data", 0).length > 0) {
      const retryStep = get(
        props,
        "state.semaphore.stepProcessor.statusRetryRules",
        StatusSemaphore.pending
      );
      if (
        retryStep === StatusSemaphore.pending ||
        retryStep === StatusSemaphore.omitted
      ) {
        let childProcessorSteps = [
          {
            childStepName: "statusRetryRules",
            newStatus: StatusSemaphore.complete,
          },
        ];
        props.updateMultipleChildrenStatus(
          get(props, "state.merchantAffiliation.publicMerchantId", ""),
          StepsEnum.stepProcessor,
          childProcessorSteps
        );
      }
    }
  }, [props.state.retries]);

  const isServiceDataFound = (serviceObject: object, propName: string) => {
    const data: unknown = get(serviceObject, propName, false);

    switch (true) {
      case isObject(data):
      case isArray(data):
      case isString(data):
        return !isEmpty(data);
      case isBoolean(data):
        return Boolean(data);
      default:
        return false;
    }
  };

  const isServicesComplete = (merchantServices: object | undefined) => {
    const cardResponse: object = get(merchantServices, "cardResponse", {});
    const cashResponse: object = get(merchantServices, "cashResponse", {});
    const vPosResponse: object = get(merchantServices, "vposResponse", {});

    const lookupProperties: string[] = [
      "sift_science",
      "whiteList",
      "commission",
      "acceptCreditCards",
      "sandboxEnable",
      "preAuthUrl",
      "active",
    ];

    const lookupObjects: object[] = [vPosResponse, cardResponse, cashResponse];

    return lookupProperties.reduce((hasPrevious: boolean, propName: string) => {
      const someObjectHasData: boolean = lookupObjects.reduce(
        (acc: boolean, obj: object) => acc || isServiceDataFound(obj, propName),
        false
      );
      return hasPrevious || someObjectHasData;
    }, false);
  };

  /**
   * Check update semaphore for services step
   */
  useEffect(() => {
    const serviceStatus: string = get(
      props,
      "state.semaphore.stepServices.status",
      StatusSemaphore.omitted
    ).trim();

    const publicMerchantId: string = get(
      props,
      "state.merchantAffiliation.publicMerchantId",
      ""
    );

    const noChange: StatusSemaphore[] = [
      StatusSemaphore.omitted,
      StatusSemaphore.complete,
    ];

    if (noChange.includes(StatusSemaphore[serviceStatus])) return;

    if (isServicesComplete(props.state.merchantServices)) {
      props.updateStepStatus(
        publicMerchantId,
        StepsEnum.stepServices,
        StatusSemaphore.complete
      );
    }
  }, [
    props.state.merchantServices,
    props.state.semaphore?.stepServices?.status,
  ]);

  const onSubmit = (): void => {
    if (!isEmpty(errors)) return;
    if (!props.state.steps) return;
    if (!checkAllSteps()) {
      props.setNotification({
        message: "Se guardaron los datos del formulario.",
        open: true,
        type: "black" as Color,
      });
      return;
    }
    activateMerchant();
  };
  const checkAllSteps = (): boolean => {
    const omissibleSteps = ["stepServices", "developers", "accountPreferences"];
    for (const step of props.state.steps!) {
      if (
        step.status !== StatusSemaphore.complete &&
        !omissibleSteps.includes(step.name)
      )
        return false;
      if (
        omissibleSteps.includes(step.name) &&
        step.status !== StatusSemaphore.complete
      ) {
        if (step.status !== StatusSemaphore.omitted) return false;
      }
    }
    return true;
  };
  return {
    state: {
      ...props.state,
      expandedSection,
      isNewMerchant,
      isDisableUserStep,
    },
    handlers: {
      handleSectionExpansion,
      changeStepStatus: props.changeStepStatus,
    },
    breadCrumbs: {
      items: [
        {
          label: "Comercios",
          url: routes.MERCHANTS,
        },
      ],
      lastItem: "Crear comercio",
    },
    form: {
      errors,
      register,
      getValues,
      submit: onSubmit,
      control: control,
    },
    open,
    handleOpen,
    handleBackPage,
    handleNotify,
    handleOmit,
    isCompleted,
    isOmitted,
    isLoading,
  };
};
