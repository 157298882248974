import { AlertColor } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { hideLoading, showLoading } from "../../../store/actions/app_actions";
import { useAppSelector } from "../../../store/hooks/storeHook";
import {
  clearSelectedRefunds,
  setRefunds,
  setTotalRemoteRefunds,
} from "../../../store/reducers/refunds/refunds";
import {
  refundsSelector,
  totalRefundsSelector,
} from "../../../store/selectors/refunds/refunds.selectors";

export interface IProcessRefundsButtonState {
  actions: {
    handleClick: () => void;
    handleClose: () => void;
    handleCloseAlert: () => void;
    handleConfirm: () => void;
  };
  alertType: AlertColor;
  message: string;
  open: boolean;
  showAlert: boolean;
}

export type IUseProcessRefundsButtonState = (
  selectedTransactions: string[]
) => IProcessRefundsButtonState;

export const useProcessRefundsButtonState: IUseProcessRefundsButtonState = (
  selectedTransactions
) => {
  const [open, setOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("success" as AlertColor);

  const refunds = useAppSelector(refundsSelector);
  const totalRefunds = useAppSelector(totalRefundsSelector);

  const dispatch = useDispatch();

  const handleConfirm = () => {
    const payload = {
      ids: selectedTransactions,
    };

    dispatch(showLoading());
    setOpen(false);

    axios
      .post(API_ROUTES.PROCESS_TRANSACTIONS, payload)
      .then(() => {
        setMessage("Solicitudes procesadas correctamente.");
        setAlertType("success");
        setShowAlert(true);

        dispatch(clearSelectedRefunds());

        const currentRefunds = refunds.filter(
          (itemRefund) => !selectedTransactions.includes(itemRefund.id)
        );

        dispatch(setRefunds(currentRefunds));
        dispatch(
          setTotalRemoteRefunds(totalRefunds - selectedTransactions.length)
        );
      })
      .catch(() => {
        setMessage("Hubo un error al procesar las solicitudes.");
        setAlertType("error");
        setShowAlert(true);
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  const handleClick = () => {
    setOpen(true);
  };

  return {
    actions: {
      handleClick,
      handleClose,
      handleCloseAlert,
      handleConfirm,
    },
    alertType,
    message,
    open,
    showAlert,
  };
};
