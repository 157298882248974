import { IStyles } from "../../shared/interfaces/Styles.interfaces";

const dialogStyles: IStyles = {
  modalWidth: {
    "& .MuiPaper-root": {
      maxWidth: ({ spacing }) => spacing(88.625),
      width: "709px",
      padding: "2%",
    },
  },
  font500: {
    color: "#293036",
    fontWeight: "normal",
  },
  font600: {
    color: "#293036",
    fontWeight: "bold",
    mb: "0px",
  },
  contentTitleFont: {
    fontWeight: "bold",
  },

  acceptButton: {
    fontFamily: "IBM Plex Sans,sans-serif",
    backgroundColor: "#023365",
    borderRadius: "4px 4px",
    color: "#FFFFFF",
    marginRight: "2%",
    borderColor: "#023365",
    "&:hover, &:focus, &:active": {
      background: "#023365",
      borderRadius: "4px 4px",
      color: "#FFFFFF",
      opacity: 1,
      marginRight: "2%",
    },
    "&:disabled": {
      opacity: 0.7,
      background: "#023365",
    },
  },
  cancelButton: {
    fontFamily: "IBM Plex Sans,sans-serif",
    backgroundColor: "#FAFCFD",
    borderRadius: "4px 4px",
    color: "#293036",
    "&:hover, &:focus, &:active:": {
      backgroundColor: "#FAFCFD",
      borderRadius: "4px 4px",
      boxShadow: "none",
      color: "#293036",
    },
  },
  skipField: {
    marginTop: "3%",
    width: "100%",
  },

  formControl: {
    marginTop: "3%",
    width: "50%",
    "& .MuiFormLabel-root": {
      color: "#293036 !important",
      fontSize: "19px",
      paddingTop: "1.5%",
    },
  },
};

export { dialogStyles };
