import React from "react";
import { SvgIcon } from "@mui/material";

interface ITrashIconProps {
  color: string;
}
const TrashIcon: React.FC<ITrashIconProps> = ({ color }: ITrashIconProps) => {
  return (
    <SvgIcon
      viewBox="-4 -4 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.501 15H5.50103C5.33414 15.0007 5.17306 14.9387 5.04963 14.8264C4.9262 14.7141 4.84938 14.5595 4.83436 14.3933L3.83436 3.72667C3.82666 3.63482 3.83809 3.54237 3.86792 3.45516C3.89776 3.36796 3.94535 3.28788 4.0077 3.22C4.07003 3.15094 4.14612 3.09569 4.23108 3.0578C4.31605 3.01991 4.408 3.00022 4.50103 3H12.501C12.5941 3.00022 12.686 3.01991 12.771 3.0578C12.8559 3.09569 12.932 3.15094 12.9944 3.22C13.0567 3.28788 13.1043 3.36796 13.1341 3.45516C13.164 3.54237 13.1754 3.63482 13.1677 3.72667L12.1677 14.3933C12.1527 14.5595 12.0759 14.7141 11.9524 14.8264C11.829 14.9387 11.6679 15.0007 11.501 15ZM6.1077 13.6667H10.8944L11.7677 4.33333H5.23436L6.1077 13.6667Z"
        fill={color}
      />
      <path
        d="M13.166 4.33333H3.83268C3.65587 4.33333 3.4863 4.2631 3.36128 4.13807C3.23625 4.01305 3.16602 3.84348 3.16602 3.66667C3.16602 3.48986 3.23625 3.32029 3.36128 3.19526C3.4863 3.07024 3.65587 3 3.83268 3H13.166C13.3428 3 13.5124 3.07024 13.6374 3.19526C13.7624 3.32029 13.8327 3.48986 13.8327 3.66667C13.8327 3.84348 13.7624 4.01305 13.6374 4.13807C13.5124 4.2631 13.3428 4.33333 13.166 4.33333Z"
        fill={color}
      />
      <path
        d="M10.166 2.33333H6.83268C6.65587 2.33333 6.4863 2.2631 6.36128 2.13807C6.23625 2.01305 6.16602 1.84348 6.16602 1.66667C6.16602 1.48986 6.23625 1.32029 6.36128 1.19526C6.4863 1.07024 6.65587 1 6.83268 1H10.166C10.3428 1 10.5124 1.07024 10.6374 1.19526C10.7624 1.32029 10.8327 1.48986 10.8327 1.66667C10.8327 1.84348 10.7624 2.01305 10.6374 2.13807C10.5124 2.2631 10.3428 2.33333 10.166 2.33333Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default TrashIcon;
