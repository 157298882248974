export enum MerchantMigrationEnum {
  TITLE_SECTION = "Migración de comercios",
  STEP_ONE = "Paso 1: Actualización de información de comercios a migrar a la Consola 3.0",
  STEP_TWO = "Paso 2: Lista de comercios actualizados con nueva estructura de jerarquías",
  TEXT_ONE = "El archivo descargado contiene los datos actuales de todos los comercios junto con los campos que se requieren llenar en la Consola 3.0 para estandarizar la información.",
  TEXT_ONE_STEP_TWO = "El archivo descargado contiene los comercios actualizados junto con sus estructuras jerárquicas listos para ser migrados a la Consola 3.0.",
  TEXT_TWO = "Los comercios que han sido actualizados correctamente no serán mostrados en la lista.",
  TEXT_TWO_STEP_TWO = "Una vez construida la agrupación jerárquica de clientes deberás subir el archivo al S3 correspondiente.",
  TEXT_THREE = "Una vez finalizada la actualización de los campos deberás subir el archivo al S3 correspondiente para continuar con el paso 2.",
  SUCCESS_MODAL_TITLE = "Estamos generando tu reporte",
  WAIT_MODAL_TITLE = "Reporte de comercios en curso",
  ERROR_MODAL_TITLE = "Error al generar la descarga",
  DOWNLOAD_BTN = "Descargar",
  ACCEPT_BTN = "Aceptar",
}
export const MODAL_TEXT = {
  ERROR_MODAL_DETAIL:
    "Ocurrió un error al generar la descarga, por favor intentalo de nuevo.",
  SUCCESS_MODAL_DETAIL: (email: string) =>
    `En breve recibirás el link de descarga en tu correo electrónico: ${email}`,
  WAIT_MODAL_DETAIL:
    "Ya existe un reporte en curso, por favor espere la descarga.",
};
export enum ErrorCodes {
  BCN020 = "BCN020",
  ON_PROCESS = "409",
  UNKNOWN = "UNKNOWN",
}
export enum ErrorMessages {
  ON_PROCESS = "Ejecución ya en curso",
  UNKNOWN = "Unknown error",
}
