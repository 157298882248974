import React from "react";
import { CardListContainer } from "../CardListContainer/CardListContainer";
import { MissingInfoAlert } from "../MissingInfoAlert/MissingInfoAlert";
import { useBasicData } from "./state/useBasicData";

export const BasicData: React.FC = () => {
  const {
    basicDataFields,
    mccDescription,
    mccFields,
    name,
    isCompletedSection,
    isLoading,
  } = useBasicData();

  if (!isLoading) {
    if (
      basicDataFields.some(
        ({ label, text }) => label === undefined || text === undefined
      ) ||
      mccFields.some(
        ({ label, text }) => label === undefined || text === undefined
      )
    ) {
      return <MissingInfoAlert />;
    }
  }

  return (
    <>
      {isLoading ? null : (
        <>
          <>
            {isCompletedSection ? (
              <>
                <CardListContainer items={basicDataFields} title={name} />
                <CardListContainer items={mccFields} title={mccDescription} />
              </>
            ) : (
              <MissingInfoAlert />
            )}
          </>
        </>
      )}
    </>
  );
};
