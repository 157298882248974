import { createNamedStyles } from "../../shared/interfaces/create_named_styles";
import theme from "../../theme";

export const subHeaderStyles = createNamedStyles({
  boxTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: (theme) => theme.spacing(2),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: (theme) => theme.spacing(2),
  },
  primaryBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  secondaryBox: {
    color: "text.primary",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: (theme) => theme.spacing(2),
  },
  secondaryLabels: {
    color: "text.primary",
    fontSize: theme.spacing(1.875),
  },
  customerLabel: {
    color: "text.grey",
    fontSize: theme.spacing(1.62),
  },
  divider: {
    border: "1px solid #98A8B8",
    m: (theme) => theme.spacing(0, 1, 0, 1),
  },
  merchantNameLabel: {
    color: "text.primary",
  },
});
