import * as React from "react";
import { Grid, TextField, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { RangeDatePicker } from "@kushki/connect-ui";
import { searchTextStyles as styles } from "./SearchText.styles";
import { PlaceholderEnum } from "../../shared/enums/PlaceholderEnum";
import { auth } from "../../shared/auth";
import { TimeEnum } from "../../shared/enums/TimeEnum";

export interface searchTextProps {
  onEnter: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onDateSelected: (startDate: string, finishDate: string) => void;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  value?: string;
  showRangePicker: boolean;
  defaultStartDate: string;
  defaultEndDate: string;
  minDate: string;
}

export const SearchText: React.FC<searchTextProps> = (
  props: searchTextProps
) => {
  const isAdmin: boolean = auth.isAdmin();

  const searchMessage: PlaceholderEnum = isAdmin
    ? PlaceholderEnum.SEARCH_MESSAGE_ADMIN
    : PlaceholderEnum.SEARCH_MESSAGE_MASTER;

  return (
    <Grid container item xs={12} md={12} sx={{ alignItems: "center" }}>
      <Grid item xs={isAdmin ? 7 : 6}>
        <Tooltip title={searchMessage}>
          <TextField
            placeholder={searchMessage}
            sx={styles.searchText}
            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
              return props.onEnter(e);
            }}
            value={props.value}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => props.onChange(e)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon color={"primary"} />
                </InputAdornment>
              ),
            }}
            size="medium"
          />
        </Tooltip>
      </Grid>
      <Grid item xs={isAdmin ? 5 : 6} sx={styles.rangeDate}>
        {props.showRangePicker && (
          <RangeDatePicker
            dateFormat={TimeEnum.timeDateFormat}
            onDateSelect={props.onDateSelected}
            placeholder={PlaceholderEnum.SEARCH_DATE_MESSAGE}
            disabledFutureDate={true}
            isOpen={false}
            isFilled={true}
            disabledOldDate={false}
            defaultStartDate={props.defaultStartDate}
            defaultFinishDate={props.defaultEndDate}
            showHour={true}
            minDate={new Date(props.minDate)}
            initialHour={TimeEnum.defaultInitialTime}
            finalHour={TimeEnum.defaultFinalTime}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default SearchText;
