import { CountryEnum } from "../countryEnum";
import { ErrorBillingData } from "../enum/BasicStateMX/ErrorMessageMX";
import { FieldsEnum } from "../enums/FieldsEnum";
import {
  validatePhoneNumberDifferentMX,
  validateZipCodeLength,
} from "../utils/billing_data_utils";
import { ErrorMessageBRA } from "../enum/BasicStateBRA/ErrorMessageBRA";

export const getPhoneInputRules = ({
  validateMaxLenght,
  validateMinLength,
}: {
  validateMaxLenght?: (value: string) => number | string;
  validateMinLength?: (value: string) => number | string;
}) => ({
  [`${CountryEnum.mexico}`]: {
    maxLength: {
      message: ErrorBillingData.PHONE_NUMBER_EMPTY,
      value: validateMaxLenght && validateMaxLenght(FieldsEnum.phoneNumber),
    },
    minLength: {
      message: ErrorBillingData.PHONE_NUMBER,
      value: validateMinLength && validateMinLength(FieldsEnum.phoneNumber),
    },
  },
  [`${CountryEnum.peru}`]: {
    required: { message: ErrorBillingData.PHONE_NUMBER_EMPTY, value: true },
    validate: (value: string | undefined) =>
      validatePhoneNumberDifferentMX(value as string, 9, 10),
  },
  [`${CountryEnum.colombia}`]: {
    required: { message: ErrorBillingData.PHONE_NUMBER_EMPTY, value: true },
    validate: (value: string | undefined) =>
      validatePhoneNumberDifferentMX(value as string, 9, 12),
  },
  [`${CountryEnum.el_salvador}`]: {
    required: {
      message: ErrorBillingData.ADD_PHONE_NUMBER,
      value: true,
    },
    validate: (value: string | undefined) =>
      validatePhoneNumberDifferentMX(value as string, 9, 12),
  },
  [`${CountryEnum.chile}`]: {
    required: { message: ErrorBillingData.ADD_PHONE_NUMBER, value: true },
    validate: (value: string | undefined) =>
      validatePhoneNumberDifferentMX(value as string, 9, 12),
  },
  [`${CountryEnum.brasil}`]: {
    required: ErrorBillingData.ADD_PHONE_NUMBER,
    validate: (value: string | undefined) =>
      validatePhoneNumberDifferentMX(value as string, 9, 12),
  },
  [`${CountryEnum.usa}`]: {
    required: ErrorBillingData.ADD_PHONE_NUMBER,
    validate: (value: string | undefined) =>
      validatePhoneNumberDifferentMX(value as string, 9, 12),
  },
  [`${CountryEnum.guatemala}`]: {
    required: ErrorBillingData.ADD_PHONE_NUMBER,
    validate: (value: string | undefined) =>
      validatePhoneNumberDifferentMX(value as string, 9, 12),
  },
  [`${CountryEnum.ecuador}`]: {
    required: { message: ErrorBillingData.ADD_PHONE_NUMBER, value: true },
    validate: (value: string | undefined) =>
      validatePhoneNumberDifferentMX(value as string, 9, 13),
  },
  [`${CountryEnum.panama}`]: {
    required: { message: ErrorBillingData.ADD_PHONE_NUMBER, value: true },
    validate: (value: string | undefined) =>
      validatePhoneNumberDifferentMX(value as string, 9, 12),
  },
  [`${CountryEnum.honduras}`]: {
    required: { message: ErrorBillingData.ADD_PHONE_NUMBER },
    validate: (value: string | undefined) =>
      validatePhoneNumberDifferentMX(value as string, 9, 12),
  },
  [`${CountryEnum.nicaragua}`]: {
    required: { message: ErrorBillingData.ADD_PHONE_NUMBER },
    validate: (value: string | undefined) =>
      validatePhoneNumberDifferentMX(value as string, 9, 12),
  },
  [`${CountryEnum.costa_rica}`]: {
    required: { message: ErrorBillingData.ADD_PHONE_NUMBER },
    validate: (value: string | undefined) =>
      validatePhoneNumberDifferentMX(value as string, 9, 12),
  },
  [`${CountryEnum.generic}`]: {
    required: ErrorBillingData.ADD_PHONE_NUMBER,
    validate: (value: string | undefined) =>
      validatePhoneNumberDifferentMX(value as string, 9, 12),
  },
});

const generalZipCodeRule = (min: number, max?: number) => ({
  ...(max
    ? {
        maxLength: {
          message: `${ErrorBillingData.MAX_ZIPCODE} ${max ? max : min}`,
          value: max ? max : min,
        },
        minLength: {
          message: `${ErrorBillingData.MIN_ZIPCODE} ${min}`,
          value: min,
        },
      }
    : {
        validate: (value: string | undefined) =>
          validateZipCodeLength(value, min),
      }),
  required: { message: ErrorBillingData.ADD_ZIPCODE, value: true },
});

export const getProvinceRules = () => ({
  [`${CountryEnum.ecuador}`]: {
    required: { message: ErrorBillingData.PROVINCE_ECUADOR, value: true },
  },
});

export const getCitiesRules = () => ({
  [`${CountryEnum.ecuador}`]: {
    required: { message: ErrorBillingData.CITY, value: true },
  },
});

export const getTaxPayerIdRules = () => ({
  [`${CountryEnum.ecuador}`]: {
    required: { message: ErrorBillingData.TAX_PAYER_ID, value: true },
  },
});

export const getActivityRules = () => ({
  [`${CountryEnum.ecuador}`]: {
    required: { message: ErrorBillingData.ACTIVITY, value: true },
  },
});

export const getIncomeSourceConceptRules = () => ({
  [`${CountryEnum.ecuador}`]: {
    required: {
      message: ErrorBillingData.INCOME_SOURCE_CONCEPT_ID,
      value: true,
    },
  },
});
export const AddressInputRule = () => ({
  message: ErrorMessageBRA.MAX_ADDRESS,
  value: 50,
});
export const getZipCodeInputRules = () => ({
  [`${CountryEnum.peru}`]: {
    ...generalZipCodeRule(1, 50),
  },
  [`${CountryEnum.colombia}`]: {
    ...generalZipCodeRule(1, 50),
  },
  [`${CountryEnum.el_salvador}`]: {
    ...generalZipCodeRule(1, 50),
  },
  [`${CountryEnum.chile}`]: {
    ...generalZipCodeRule(1, 50),
  },
  [`${CountryEnum.brasil}`]: {
    ...generalZipCodeRule(5, 10),
  },
  [`${CountryEnum.usa}`]: {
    ...generalZipCodeRule(1, 50),
  },
  [`${CountryEnum.guatemala}`]: {
    ...generalZipCodeRule(1, 50),
  },
  [`${CountryEnum.ecuador}`]: {
    ...generalZipCodeRule(1, 50),
  },
  [`${CountryEnum.panama}`]: {
    ...generalZipCodeRule(1, 50),
  },
  [`${CountryEnum.honduras}`]: {
    ...generalZipCodeRule(1, 50),
  },
  [`${CountryEnum.nicaragua}`]: {
    ...generalZipCodeRule(1, 50),
  },
  [`${CountryEnum.costa_rica}`]: {
    ...generalZipCodeRule(1, 50),
  },
  [`${CountryEnum.panama}`]: {
    ...generalZipCodeRule(1, 50),
  },
  [`${CountryEnum.generic}`]: {
    ...generalZipCodeRule(0, 100),
  },
});

export const WEB_SITE_REGEX =
  /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(\/[^\s]*)?$/;
