import React, { FC } from "react";
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
} from "@mui/material";
import { listDownloadButtonStyles } from "./ListDownloadButton.styles";
import { IListDownloadButtonProps } from "./ListDownloadButton.interfaces";

const ListDownloadButton: FC<IListDownloadButtonProps> = ({
  handleClose,
  handleMenuItemClick,
  id,
  options,
  selectedIndex,
  transitionProps,
}: IListDownloadButtonProps) => {
  return (
    <Grow {...transitionProps}>
      <Paper sx={listDownloadButtonStyles.paper}>
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList id={id}>
            {options.map((option, index) => (
              <MenuItem
                sx={listDownloadButtonStyles.menuItem}
                key={option}
                selected={index === selectedIndex}
                onClick={handleMenuItemClick(index, option)}
              >
                {option}
              </MenuItem>
            ))}
          </MenuList>
        </ClickAwayListener>
      </Paper>
    </Grow>
  );
};

export default ListDownloadButton;
