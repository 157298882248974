import React from "react";
import { Grid } from "@material-ui/core";
import { KshCheckbox } from "../../../Common/KshCheckbox/KshCheckbox";
import { DatePicker } from "@material-ui/pickers";
import { KshSelectInput } from "../../../Common/KshSelectInput/KshSelectInput";
import { makeStyles } from "@material-ui/core/styles";
import {
  getPaymentMethodCatalog,
  PaymentMethodDescriptionEnum,
} from "../../../../shared/infrastructure/PaymentMethodEnum";
import { CountryEnum } from "../../../../shared/infrastructure/CountryEnum";

const useStyles = makeStyles(() => ({
  heightDatepicker: {
    "& > div": {
      height: "auto",
    },
  },
  firstPadding: {
    paddingTop: 25,
  },
  paddingTop: {
    paddingTop: 14,
  },
}));

export interface ParametersProps {
  country?: string;
  paymentDate?: boolean;
  expirationDate?: boolean;
  paymentMethod?: boolean;
  paymentDate_value?: Date;
  expirationDate_value?: Date;
  paymentMethod_value?: string;
  paymentMethodCode?: string;
}

interface ParametersFunctions {
  handleParametersData(referenceData: {
    prop: string;
    value?: boolean | string | Date | null;
  }): void;
}

export type TParameters = ParametersFunctions & ParametersProps;

const getDates = (props: TParameters) => {
  return {
    expirationDate_value: props.expirationDate_value,
    paymentDate_value: props.paymentDate_value,
  };
};

const getChecksBoxSelected = (props: TParameters) => {
  return {
    paymentDate: !!props.paymentDate,
    paymentMethod: !!props.paymentMethod,
    expirationDate: !!props.expirationDate,
  };
};

export const Parameters = (props: TParameters) => {
  const classes = useStyles();

  const [checkboxValues, setCheckboxValues] = React.useState<{
    paymentDate: boolean;
    paymentMethod: boolean;
    expirationDate: boolean;
  }>(getChecksBoxSelected(props));
  const [selectedDate, setSelectedDate] = React.useState<{
    expirationDate_value?: Date | null | string;
    paymentDate_value?: Date | null | string;
  }>(getDates(props));
  const handleDateChange = (value: { prop: string; value: Date | null }) => {
    setSelectedDate({
      ...selectedDate,
      [value.prop]: value.value,
    });
    props.handleParametersData(value);
  };

  const handleCheckbox = (value: { prop: string; value: boolean }) => {
    setCheckboxValues({ ...checkboxValues, [value.prop]: value.value! });
    props.handleParametersData(value);
  };

  const handleChangeSelect = (value: { prop: string; value: string }) => {
    props.handleParametersData(value);
  };

  const renderPaymentMethodRow = (paymentMethodLabel: string) => (
    <>
      <Grid item xs={6} className={classes.paddingTop}>
        <KshCheckbox
          id="paymentMethod"
          checked={checkboxValues.paymentMethod}
          value="paymentMethod"
          label={paymentMethodLabel}
          onChange={handleCheckbox}
        />
      </Grid>
      <Grid item xs={6} className={classes.paddingTop}>
        <KshSelectInput
          id="region"
          label="Seleccionar"
          value={props.paymentMethod_value}
          defaultValue="cash"
          options={getPaymentMethodCatalog(
            props.country ?? CountryEnum.ecuador
          )}
          onChange={(e) =>
            handleChangeSelect({
              prop: "paymentMethod_value",
              value: e.target.value as string,
            })
          }
        />
      </Grid>
    </>
  );

  const renderExpirationDateRow = () => (
    <>
      <Grid item xs={6} className={classes.paddingTop}>
        <KshCheckbox
          id="expirationDate"
          checked={checkboxValues.expirationDate}
          value="expirationDate"
          label="Fecha de expiración"
          onChange={handleCheckbox}
        />
      </Grid>
      <Grid item xs={6} className={classes.paddingTop}>
        <DatePicker
          value={selectedDate.expirationDate_value}
          variant="outlined"
          onChange={(date: Date | null) =>
            handleDateChange({
              prop: "expirationDate_value",
              value: date,
            })
          }
          className={classes.heightDatepicker}
          margin="dense"
          size="small"
          minDate={new Date()}
          fullWidth
        />
      </Grid>
    </>
  );

  const renderPaymentDateRow = () => (
    <>
      <Grid item xs={6} className={classes.firstPadding}>
        <KshCheckbox
          id="paymentDate"
          checked={checkboxValues.paymentDate}
          value="paymentDate"
          label="Fecha de pago"
          onChange={handleCheckbox}
        />
      </Grid>
      <Grid item xs={6} className={classes.firstPadding}>
        <DatePicker
          value={selectedDate.paymentDate_value}
          variant="outlined"
          onChange={(date: Date | null) =>
            handleDateChange({
              prop: "paymentDate_value",
              value: date,
            })
          }
          className={classes.heightDatepicker}
          margin="dense"
          size="small"
          minDate={new Date()}
          fullWidth
        />
      </Grid>
    </>
  );

  const showParameters = () => {
    switch (props.country) {
      case CountryEnum.ecuador:
        return (
          <>
            {renderPaymentMethodRow(
              PaymentMethodDescriptionEnum.globalDescription
            )}
          </>
        );
      case CountryEnum.mexico:
        return (
          <>
            {renderPaymentMethodRow(PaymentMethodDescriptionEnum.mxDescription)}
          </>
        );
      case CountryEnum.colombia:
        return (
          <>
            {renderExpirationDateRow()}
            {renderPaymentMethodRow(
              PaymentMethodDescriptionEnum.globalDescription
            )}
          </>
        );
      case CountryEnum.chile:
        return (
          <>
            {renderPaymentDateRow()}
            {renderExpirationDateRow()}
            {renderPaymentMethodRow(
              PaymentMethodDescriptionEnum.globalDescription
            )}
          </>
        );
      case CountryEnum.peru:
        return <>{renderExpirationDateRow()}</>;
      default:
        return;
    }
  };

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      {showParameters()}
    </Grid>
  );
};
