import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    filterButton: {
      marginBottom: "8px",
      marginTop: "20px",
      paddingLeft: "32px",
      paddingRight: "32px",
    },
  })
);
