import { createNamedStyles } from "../../shared/interfaces/create_named_styles";
import theme from "../../theme";

export const ListUrlsStyles = createNamedStyles({
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    minWidth: theme.spacing(19),
    columnGap: (theme) => theme.spacing(2),
  },
  url: {
    fontSize: theme.spacing(1.875),
    width: "90%",
    color: "text.dark",
  },
  textField: {
    margin: "normal",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    mt: 1,
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    columnGap: (theme) => theme.spacing(3.5),
  },
  urlContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    width: (theme) => `calc( 100% - ${theme.spacing(22)})`,
    columnGap: (theme) => theme.spacing(1),
  },
});
