import { SessionStoragePathEnum } from "../infrastructure/enums/LocalStoragePathEnum";
import { defaultTo, filter, get, isEmpty, map } from "lodash";
import { BranchEditList, Data } from "../../../types/branch_edit_list";
import { Branch } from "../../../types/massive_rules_request";

const defaultBranchEditList: BranchEditList = {
  data: [],
};

export const getBranchEditListStorage = (): BranchEditList => {
  const branchEditList = window.sessionStorage.getItem(
    SessionStoragePathEnum.BRANCH_EDIT_LIST
  );

  if (isEmpty(branchEditList)) return defaultBranchEditList;
  const actualValues = JSON.parse(
    defaultTo(branchEditList, JSON.stringify(defaultBranchEditList))
  );

  return defaultTo(actualValues, defaultBranchEditList);
};

export const getSelectedBranches = (): Branch[] => {
  const branchesList: object = JSON.parse(
    sessionStorage.getItem(SessionStoragePathEnum.BRANCH_EDIT_LIST) || "{}"
  );

  const branchesToEdit: object[] = filter(
    get(branchesList, "data", []),
    (branch: object) => get(branch, "edit", false)
  );

  return map(branchesToEdit, (branch: object) => ({
    branchId: get(branch, "public_merchant_id"),
    name: get(branch, "name"),
    nodeId: get(branch, "node_id"),
  }));
};

const checkIsModified = (values: string[], valueToValidate: string) =>
  values.some((value: string) => valueToValidate === value);

export const setIsModifyByBranch = () => {
  const branchesForUpdate: string[] = getSelectedBranches().map(
    (branch: Branch) => branch.branchId
  );

  const branchEditList: Data[] = getBranchEditListStorage().data || [];

  const branchEditListWithModify: Data[] = branchEditList.map(
    (branchItem: Data) => ({
      ...branchItem,
      isModify:
        branchItem.isModify ||
        checkIsModified(branchesForUpdate, branchItem.merchant_id || ""),
    })
  );

  sessionStorage.setItem(
    SessionStoragePathEnum.BRANCH_EDIT_LIST,
    JSON.stringify({
      data: branchEditListWithModify,
      total: branchEditListWithModify.length,
    })
  );
};
