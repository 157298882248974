import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  DeferredOptionMerchant,
  UpdateDeferredMerchantRequest,
  VariantDeferredOption,
} from "../../../../types/update_deferred_merchant_request";
import {
  setIsDeferredLoaded,
  setNotification,
  updateDeferredMerchant,
} from "../../../store/actionCreators";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../../shared/auth";
import { defaultTo, get, indexOf, set } from "lodash";
import { CountriesEnum } from "../../../shared/infrastructure/constants/CountriesEnum";
import { TDeferred } from "../../../shared/infrastructure/interfaces/TDeferred";
import { IAppState } from "../../../store/reducer";
import { hasValidVariants } from "../../../shared/utils/hasValidVariants";

export interface IMexicoSectionState {
  canAddVariant: boolean;
  type: string;
  banks: IBank[];
  banksListWeb: string[];
  saveDefered: boolean;
  banksModal: string[];
  bankModalIsOpen: boolean[];
  bankModalWebIsOpen: boolean;
  merchantMonths: string[];
  merchantMonthsOfGrace: string[];
  months: string[];
  monthsOfGrace: string[];
  handleBankModal: (banks: string[], index: number) => void;
  handleBankWebModal: (banks: string[]) => void;
  handleSetMonth(event: React.MouseEvent<HTMLElement>, value: string[]): void;
  handleSetMonthVariant(
    event: React.MouseEvent<HTMLElement>,
    value: string[],
    index: number
  ): void;
  handleSetMonthOfGrace(
    event: React.MouseEvent<HTMLElement>,
    value: string[]
  ): void;
  handleCancel(): void;
  handleSave(): void;
  variants: VariantDeferredOption[];
  handleSetBank(name: string, index: number): void;
  handleDeleteBank(name: string, index: number): void;
  handleDeleteVariant(id: number): void;
  handleSetMonthOfGraceVariant(
    event: React.MouseEvent<HTMLElement>,
    value: string[],
    index: number
  ): void;
  handleSetVariant(): void;
  handleCloseBankModal(): void;
}

export interface IBank {
  checked: boolean;
  label: string;
  index?: number;
}

export interface ILocationStateMx {
  entity?: string;
  status?: string;
  processorName?: string;
  deferredType: string[];
  id: string;
  bank?: string[];
  months: string[];
  monthsOfGrace: string[];
  merchantMonths: string[];
  merchantMonthsOfGrace: string[];
  variants?: VariantDeferredOption[];
  type: string;
  allDeferred: TDeferred[];
}

const getBanks = (
  banks: string[],
  variants?: VariantDeferredOption[]
): IBank[] => {
  if (banks === undefined) return [];
  if (variants === undefined)
    return banks.map((bank) => ({
      checked: false,
      index: undefined,
      label: bank,
    }));
  let bankList: IBank[] = banks
    .sort()
    .map((bank) => ({ checked: false, index: undefined, label: bank }));

  variants.forEach((variants, indexVaraint) => {
    variants.bank?.forEach((bank) => {
      const index: number = banks.indexOf(bank);

      if (index >= 0) {
        bankList[index].checked = true;
        bankList[index].index = indexVaraint;
      }
    });
  });

  return bankList;
};

const getBankListWeb = (bankList: IBank[]): string[] => {
  const newBankList: string[] = [];

  bankList.forEach((item) => {
    if (!item.checked) newBankList.push(item.label);
  });

  return newBankList.sort();
};

export const useMexicoSection = (): IMexicoSectionState => {
  const history = useHistory();
  const allDeferred = useSelector((state: IAppState) => state.merchantDeferred);
  const merchant = auth.getAuthMerchant();
  const country = get(merchant, "country", CountriesEnum.ECUADOR);

  const dispatch = useDispatch();

  const location = useLocation<ILocationStateMx>();
  const {
    type,
    bank,
    entity,
    deferredType,
    id,
    processorName,
    status,
    variants,
    months,
    monthsOfGrace,
    merchantMonths,
    merchantMonthsOfGrace,
  } = location.state;

  const withMonthsOfGrace = defaultTo(merchantMonthsOfGrace, []).length !== 0;
  const [bankModalIsOpen, setBankModalIsOpen] = useState<boolean[]>([]);
  const [bankModalWebIsOpen, setBankModalWebIsOpen] = useState<boolean>(false);
  const [banksModal, setBanksModal] = useState<string[]>(["bank1", "bank2"]);
  const [bankList, setBankList] = React.useState<IBank[]>(
    getBanks(bank!, variants)!
  );

  const [availableBanks, setAvailableBanks] = useState<number>(
    bankList.filter((bank) => !bank.checked && bank.index === undefined).length
  );
  const [selectedMonths, setSelectedMonths] = React.useState(merchantMonths);
  const [selectedMonthsOfGrace, setSelectedMonthsOfGrace] = useState<string[]>(
    merchantMonthsOfGrace
  );
  const [saveDefered, setSaveDefered] = React.useState<boolean>(false);
  const [merchantVariants, setMerchantVariants] = useState<
    VariantDeferredOption[]
  >(variants !== undefined ? variants : []);

  const [bankListWeb, setBankListWeb] = React.useState<string[]>(
    getBankListWeb(bankList)
  );
  const [bankListWebSave, setBankListWebSave] = React.useState<string[]>(
    getBankListWeb(bankList)
  );

  const canAddVariant =
    availableBanks > 0 &&
    hasValidVariants(merchantVariants, {
      bank: true,
      month: false,
      monthOfGrace: false,
    });

  const handleAvailableBanks = () => {
    const checkedBanks = bankList.filter((bank) => bank.index === undefined);
    const numAvailableBanks = defaultTo(checkedBanks.length, 0);

    setAvailableBanks(numAvailableBanks);
  };

  const handleSetVariant = () => {
    handleAvailableBanks();

    const banksModal: boolean[] = [...bankModalIsOpen, false];

    setBankModalIsOpen(banksModal);

    setMerchantVariants([
      ...merchantVariants,
      {
        bank: [],
        months: merchantMonths!,
        monthsOfGrace: merchantMonthsOfGrace!,
      },
    ]);
  };

  const handleBankModal = (deferredBanks: string[], index: number) => {
    setBanksModal(deferredBanks);
    const modalsIndex = [...bankModalIsOpen];

    modalsIndex[index] = true;
    setBankModalIsOpen(modalsIndex);
  };

  const handleBankWebModal = (deferredBanks: string[]) => {
    setBanksModal(deferredBanks.filter((_bank, index) => index > 9));
    bankModalWebIsOpen
      ? setBankModalWebIsOpen(false)
      : setBankModalWebIsOpen(true);
  };

  const handleSetMonth = (
    _event: React.MouseEvent<HTMLElement>,
    value: string[]
  ) => {
    setSelectedMonths(value);
  };

  const handleSetMonthVariant = (
    _event: React.MouseEvent<HTMLElement>,
    value: string[],
    index: number
  ) => {
    const data: VariantDeferredOption[] = [...merchantVariants];

    data[index].months = value;
    setMerchantVariants(data);
  };

  const handleSetMonthOfGrace = (
    _event: React.MouseEvent<HTMLElement>,
    value: string[]
  ) => {
    setSelectedMonthsOfGrace(value);
  };

  const handleCancel = () => {
    dispatch(setIsDeferredLoaded(false));
    history.goBack();
  };

  const handleCloseBankModal = (): void =>
    setBankModalIsOpen((bankModalIsOpen) => bankModalIsOpen.map(() => false));

  const handleDeleteVariant = (id: number) => {
    let banks: IBank[] = [...bankList];

    banks = banks.map((bank) => {
      if (bank.index === id) {
        set(bank, "checked", false);
        set(bank, "index", undefined);
      } else if (bank.index !== undefined && bank.index > id) {
        set(bank, "index", bank.index - 1);
      }

      return bank;
    });

    setMerchantVariants(
      merchantVariants.filter((_item, index) => index !== id)
    );
    setBankList(banks);
    setBankListWeb(getBankListWeb(banks));
    setBankListWebSave(getBankListWeb(banks));
    handleAvailableBanks();
  };

  const handleSetBank = (name: string, index: number) => {
    const data: VariantDeferredOption[] = [...merchantVariants];
    const dataBankList: IBank[] = [...bankList];

    const bankSelected = bankList.find((bank) => name === get(bank, "label"));

    setBankListWebSave(getBankListWeb(bankList));

    const indexBankSelected = indexOf(bankList, bankSelected);

    bankList[indexBankSelected].checked = !bankList[indexBankSelected].checked;

    bankList[indexBankSelected].index =
      bankList[indexBankSelected].index === undefined ? index : undefined;

    setBankList(bankList);

    const indexTemp = data[index].bank!.indexOf(name);

    indexTemp >= 0
      ? (data[index].bank = data[index].bank!.filter((e) => e !== name))
      : data[index].bank!.push(name);

    setMerchantVariants(data);

    setBankListWeb(
      getBankListWeb(dataBankList.filter((bank) => bank.label !== name))
    );
    handleAvailableBanks();
  };

  const handleDeleteBank = (name: string, index: number) => {
    const data: VariantDeferredOption[] = [...merchantVariants];

    const bankDelete = bankList.find((bank) => name === get(bank, "label"));

    const indexBankDelete = indexOf(bankList, bankDelete);

    if (indexBankDelete >= 0) {
      bankList[indexBankDelete].checked = !bankList[indexBankDelete].checked;
      bankList[indexBankDelete].index = undefined;
    }

    setBankList(bankList);
    setBankListWeb(getBankListWeb(bankList));

    data[index].bank = data[index].bank!.filter(
      (bank: string) => bank !== name
    );
    setMerchantVariants(data);
    handleAvailableBanks();
  };

  const handleSetMonthOfGraceVariant = (
    _event: React.MouseEvent<HTMLElement>,
    value: string[],
    index: number
  ) => {
    const data: VariantDeferredOption[] = [...merchantVariants];

    data[index].monthsOfGrace = value;
    setMerchantVariants(data);
  };

  const handleSave = () => {
    const merchantDeferred: UpdateDeferredMerchantRequest = {
      country,
      deferredOptions: [
        {
          bank,
          deferredType,
          entity,
          id,
          merchantMonths: defaultTo(selectedMonths, []),
          merchantMonthsOfGrace: defaultTo(selectedMonthsOfGrace, []),
          months,
          monthsOfGrace,
          processorName,
          status,
          variants: merchantVariants,
        },
      ],
    };

    setBankListWebSave(bankListWeb);

    if (
      !hasValidVariants(
        get(merchantDeferred, "deferredOptions[0].variants", []),
        {
          bank: true,
          month: true,
          monthOfGrace: withMonthsOfGrace,
        }
      )
    ) {
      dispatch(
        setNotification({
          message: withMonthsOfGrace
            ? "Todas las variantes deben tener almenos un banco, una mensualidad  y un periodo de gracia seleccionados."
            : "Todas las variantes deben tener almenos un banco y una mensualidad seleccionados.",
          open: true,
          type: "error",
        })
      );
    } else {
      merchantDeferred.deferredOptions = allDeferred!.map((deferred) =>
        deferred.id === id ? merchantDeferred.deferredOptions[0] : deferred
      ) as DeferredOptionMerchant[];
      dispatch(updateDeferredMerchant(merchantDeferred));
      handleCancel();
    }
  };

  useEffect(() => {
    if (bankListWebSave.length < 1) {
      setSaveDefered(true);
    } else {
      setSaveDefered(false);
    }
    if (months.length === 1 && availableBanks <= 1) {
      setAvailableBanks(0);
      setSaveDefered(true);
    }
  });

  return {
    bankModalIsOpen,
    bankModalWebIsOpen,
    banks: bankList!,
    banksListWeb: bankListWeb,
    banksModal,
    canAddVariant,
    handleBankModal,
    handleBankWebModal,
    handleCancel,
    handleCloseBankModal,
    handleDeleteBank,
    handleDeleteVariant,
    handleSave,
    handleSetBank,
    handleSetMonth,
    handleSetMonthOfGrace,
    handleSetMonthOfGraceVariant,
    handleSetMonthVariant,
    handleSetVariant,
    merchantMonths: selectedMonths,
    merchantMonthsOfGrace: selectedMonthsOfGrace,
    months,
    monthsOfGrace,
    saveDefered,
    type,
    variants: merchantVariants,
  };
};
