import React, { FC } from "react";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import { ICellActionMenu, IItemList } from "./CellActionMenu.interfaces";
import { defaultTo, get } from "lodash";
import {
  CatalogTranslateTextAction,
  StatusEnum,
} from "../../../shared/enums/StatusEnum";
import { upperFirstLetter } from "../../../shared/utils/string_utils";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { ComponentsSecurity } from "../../../shared/enums/SecurityWrapperEnum";
import { EntityNameEnum } from "../../../shared/enums/EntityNameEnum";
import { MENU_OPTIONS } from "../../../shared/constants/menu_options";

export const CellActionMenu: FC<ICellActionMenu> = ({
  actions,
  status,
  rowId,
  handleGetRowByMenuAction,
  entityName,
  configsCompleted,
}: ICellActionMenu) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    handleGetRowByMenuAction!(defaultTo(rowId, ""));
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuActions = () => {
    if (
      entityName === EntityNameEnum.CUSTOMER &&
      status === StatusEnum.PENDING
    ) {
      return actions.slice(0, 2);
    } else return actions;
  };

  const getColorByStatus = () => {
    if (entityName === EntityNameEnum.CUSTOMER) {
      return configsCompleted ? "primary" : "secondary";
    } else {
      switch (status) {
        case StatusEnum.PENDING:
          return "secondary";
        default:
          return "primary";
      }
    }
  };

  const builderText = (text: string): string => {
    if (text === "build_by_status") {
      return CatalogTranslateTextAction[defaultTo(status, "")];
    }

    return text;
  };
  const color = getColorByStatus();

  const builderMessageToolTip = () =>
    `Para habilitar las opciones, completa la configuracion de este ${upperFirstLetter(
      entityName
    )}`;

  const enableMenuActionButton = () => {
    if (entityName === EntityNameEnum.CUSTOMER) {
      return configsCompleted;
    } else {
      return status !== StatusEnum.PENDING;
    }
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Tooltip
          title={enableMenuActionButton() ? "" : builderMessageToolTip()}
          placement={"left"}
        >
          <IconButton
            data-testid={"btnIconMenuAction"}
            id="btn-action-menu"
            aria-controls={open ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={enableMenuActionButton() ? handleClick : undefined}
          >
            <MoreHoriz color={color} />
          </IconButton>
        </Tooltip>
        <Menu
          id="list-action-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "btn-action-menu",
          }}
        >
          {menuActions().map((item: IItemList, index: number) => {
            const text = get(item, "text", "");

            return (
              <>
                <ComponentSecurityWrapper
                  componentId={ComponentsSecurity.CUSTOMERS_ACTIVAR_MENU}
                >
                  {[
                    MENU_OPTIONS.RECHARGE_WALLET,
                    MENU_OPTIONS.DEBIT_WALLET,
                  ].includes(text) ? (
                    <ComponentSecurityWrapper
                      componentId={
                        text === MENU_OPTIONS.RECHARGE_WALLET
                          ? ComponentsSecurity.CREDIT
                          : ComponentsSecurity.DEBIT
                      }
                    >
                      <MenuItem
                        key={`menu-item-${index}`}
                        onClick={() => {
                          item.action!();
                          handleClose();
                        }}
                      >
                        {builderText(text)}
                      </MenuItem>
                    </ComponentSecurityWrapper>
                  ) : (
                    <MenuItem
                      key={`menu-item-${index}`}
                      onClick={() => {
                        item.action!();
                        handleClose();
                      }}
                    >
                      {builderText(text)}
                    </MenuItem>
                  )}
                </ComponentSecurityWrapper>
              </>
            );
          })}
        </Menu>
      </div>
    </React.Fragment>
  );
};
