import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ShopifyDomain from "./Shopify/ShopifyDomain";
import Magentov2 from "./Magentov2/Magentov2";
import VTexDomain from "./VTex/VTexDomain";

export interface IConfigSectionPlugin {
  icon: string;
  plugin: string;
  handlerOnClick: (plugin: string) => void;
  show: boolean;
}

const useStyles = makeStyles(() => ({
  labelTitle: {
    color: "#023365",
    fontSize: "16px",
    marginBottom: "8px",
  },
  labelTitleBold: {
    color: "#023365",
    fontSize: "16px",
    marginBottom: "8px",
    fontWeight: 500,
  },
  labelDescription: {
    color: "#6D7781",
    fontSize: "12px",
    lineHeight: "136%",
    marginBottom: "8px",
  },
  icon: {
    width: 50,
    fontWeight: 600,
    color: "#023365",
  },
  button: {
    display: "flex",
    width: "100%",
    backgroundColor: "white",
    borderRadius: "4px",
    boxShadow: "0px 1px 3px #0000004D;",
    marginTop: "24px",
    padding: 26,
    marginBottom: 20,
  },
  saveShopifyButton: {
    borderRadius: 0,
    backgroundColor: "white",
    height: 48,
    marginLeft: 5,
    fontWeight: 500,
  },
  checkoutButton: {
    borderRadius: "4px",
    height: 48,
    fontWeight: 600,
    backgroundColor: "#B4B9BE",
    color: "white !important",
    border: "none",
    width: "100%",
  },
  labelInfo: {
    color: "#6D7781",
    fontSize: "12px",
    lineHeight: "136%",
  },
  containerInfo: {
    marginTop: 5,
  },
  buttonPointer: {
    cursor: "pointer",
  },
}));

export const ConfigSectionPlugin: React.FC<IConfigSectionPlugin> = (
  props: IConfigSectionPlugin
) => {
  const classes = useStyles(),
    { icon, plugin, show, handlerOnClick } = props;

  const isShopify = plugin === "shopify";
  const isVtex = plugin === "vtex";
  const isMagentov2 = plugin === "magentov2";

  const resolveHeader = () => {
    if (isMagentov2) {
      return (
        <Box>
          <Typography className={classes.labelTitleBold}>
            Formulario de integración
          </Typography>
          <Typography className={classes.labelDescription}>
            Indica el nombre de dominio en el que deseas integrar el plugin. Una
            vez guardado y validado, da click en el botón Guardar.
          </Typography>
        </Box>
      );
    }
    if (isVtex) {
      return (
        <Box>
          <Typography className={classes.labelTitleBold}>
            Formulario de integración
          </Typography>
          <Typography className={classes.labelDescription}>
            Indica el nombre de dominio en el que deseas integrar el plugin. Una
            vez guardado y validado, da click en el botón Configura tu tienda.
          </Typography>
        </Box>
      );
    }
    return (
      <Box>
        <Typography className={classes.labelTitle}>
          Configura el contenido y estilo de tu formulario
        </Typography>
        <Typography className={classes.labelDescription}>
          Podrás cambiar la orientación, colores y estilos de botones del
          formulario. El formulario que se muestra por defecto contiene los
          colores y estilos de Kushki.
        </Typography>
      </Box>
    );
  };

  return (
    <>
      {!show ? null : (
        <>
          <Grid
            container
            id={"configure-container"}
            className={`${classes.button} ${
              !(isShopify || isMagentov2 || isVtex) ? classes.buttonPointer : ""
            }`}
            onClick={
              !(isShopify || isVtex || isMagentov2)
                ? () => handlerOnClick(plugin)
                : undefined
            }
          >
            <Grid container item xs={12}>
              <Grid item xs={3} md={1}>
                <img className={classes.icon} src={icon} />
              </Grid>
              <Grid xs={9} md={11}>
                {resolveHeader()}
              </Grid>
            </Grid>
            {isShopify && <ShopifyDomain />}
            {isMagentov2 && <Magentov2 />}
            {isVtex && <VTexDomain />}
          </Grid>
        </>
      )}
    </>
  );
};
