import React, { PropsWithChildren } from "react";
import { Button } from "@mui/material";
import { IGenericAddButtonProps } from "../../../../shared/infrastructure/interfaces/IGenericAddButtonProps";
import { IconPlus } from "@kushki/connect-ui";

export const GenericAddButton: React.FC<IGenericAddButtonProps> = (
  props: PropsWithChildren<IGenericAddButtonProps>
) => {
  return (
    <Button
      onClick={props.handleOnClick}
      startIcon={<IconPlus />}
      className={"buttonLink"}
      color="primary"
    >
      {props.buttonTitle}
    </Button>
  );
};
