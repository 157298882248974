import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppState } from "../../interfaces/AppState.interfaces";
import {
  createRule,
  deleteRuleById,
  disableRuleById,
  getAtomicId,
  getConfiguration,
  getPayoutsRules,
  getProcessors,
  getRuleById,
  updateRule,
} from "../../thunks/app/app.thunks";
import {
  DEFAULT_LOADING_MODAL,
  DEFAULT_SIMPLE_MODAL,
  OPEN_ALERT_INITIAL_STATE,
  RULE_INITIAL_STATE,
} from "../../../shared/utils/initialStates";
import { ISaveRuleModal } from "../../../shared/interfaces/ISimpleModal";
import { ILoadingModal } from "../../../shared/interfaces/ILoadingModal";
import { dismissLoader, showLoader } from "../util";
import { LoadingModalMessages } from "../../../shared/constants/labels/loading_modal_messages";
import { IAlert } from "../../../shared/interfaces/IAlert";
import { AlertEnum } from "../../../shared/enums/AlertEnum";
import { GetPayoutsRulesResponse } from "../../../../types/get_payouts_rules_response";
import { defaultTo } from "lodash";
import { PaymentMethodEnum } from "../../../shared/enums/PaymentMethodEnum";
import { MerchantProcessors } from "../../../../types/merchant_processors";

export const initialState: IAppState = {
  configList: {},
  editRule: RULE_INITIAL_STATE,
  isFilterSearch: false,
  loadingModal: DEFAULT_LOADING_MODAL,
  modalInfo: DEFAULT_SIMPLE_MODAL,
  openAlert: OPEN_ALERT_INITIAL_STATE,
  openConfirmModal: false,
  openModalRuleDetail: false,
  payoutsRules: {
    data: [],
    total: 0,
  },
  pendingRequest: 0,
  processorList: [],
};

export const appSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getProcessors.pending, (state) => {
        showLoader(state, LoadingModalMessages.PROCESSORS);
      })
      .addCase(getProcessors.fulfilled, (state, action) => {
        state.processorList = defaultTo(action.payload, []).filter(
          (processor: MerchantProcessors) =>
            processor.paymentMethod === PaymentMethodEnum.PAYOUTS_TRANSFER
        );
        dismissLoader(state);
      })
      .addCase(getProcessors.rejected, (state) => {
        dismissLoader(state);
      })
      .addCase(getPayoutsRules.pending, (state) => {
        if (!state.isFilterSearch) showLoader(state);
      })
      .addCase(getPayoutsRules.fulfilled, (state, action) => {
        state.payoutsRules = action.payload;
        dismissLoader(state);
      })
      .addCase(getPayoutsRules.rejected, (state) => {
        dismissLoader(state);
      })
      .addCase(getRuleById.pending, (state) => {
        showLoader(state, LoadingModalMessages.GET_RULE);
      })
      .addCase(getRuleById.fulfilled, (state, action) => {
        state.editRule = action.payload;
        dismissLoader(state);
      })
      .addCase(getRuleById.rejected, (state) => {
        dismissLoader(state);
      })
      .addCase(getAtomicId.pending, (state) => {
        showLoader(state);
      })
      .addCase(getAtomicId.fulfilled, (state, action) => {
        state.prefixAtomicId = action.payload.quantity;
        dismissLoader(state);
      })
      .addCase(getAtomicId.rejected, (state) => {
        dismissLoader(state);
      })
      .addCase(deleteRuleById.fulfilled, (state) => {
        state.openAlert = {
          isError: false,
          open: true,
          originAlert: AlertEnum.ORIGIN_DELETE,
        };
        state.loadingModal = DEFAULT_LOADING_MODAL;
      })
      .addCase(deleteRuleById.rejected, (state) => {
        state.openAlert = {
          isError: true,
          open: true,
          originAlert: AlertEnum.ORIGIN_DELETE,
        };
        state.loadingModal = DEFAULT_LOADING_MODAL;
      })
      .addCase(disableRuleById.fulfilled, (state) => {
        state.openAlert = {
          isError: false,
          open: true,
          originAlert: AlertEnum.ORIGIN_DISABLE,
        };
        state.loadingModal = DEFAULT_LOADING_MODAL;
      })
      .addCase(disableRuleById.rejected, (state) => {
        state.openAlert = {
          isError: true,
          open: true,
          originAlert: AlertEnum.ORIGIN_DISABLE,
        };
        state.loadingModal = DEFAULT_LOADING_MODAL;
      })
      .addCase(createRule.pending, (state) => {
        showLoader(state, LoadingModalMessages.CREATE_RULE);
      })
      .addCase(createRule.fulfilled, (state, action) => {
        state.createRuleResponse = action.payload;
        dismissLoader(state);
      })
      .addCase(createRule.rejected, (state) => {
        dismissLoader(state);
      })
      .addCase(getConfiguration.pending, (state) => {
        showLoader(state, LoadingModalMessages.CONFIGURATIONS);
      })
      .addCase(getConfiguration.fulfilled, (state, action) => {
        state.configList = action.payload;
        dismissLoader(state);
      })
      .addCase(getConfiguration.rejected, (state) => {
        dismissLoader(state);
      })
      .addCase(updateRule.pending, (state) => {
        showLoader(state, LoadingModalMessages.EDIT_RULE);
      })
      .addCase(updateRule.fulfilled, (state) => {
        dismissLoader(state);
      })
      .addCase(updateRule.rejected, (state) => {
        dismissLoader(state);
      });
  },
  initialState,
  name: "app",
  reducers: {
    dismissLoadingModal(state) {
      state.loadingModal = DEFAULT_LOADING_MODAL;
    },
    dismissModalInfo(state) {
      state.modalInfo = DEFAULT_SIMPLE_MODAL;
    },
    setIsFilterSearch(state, action: PayloadAction<boolean>) {
      state.isFilterSearch = action.payload;
    },
    setPayoutRules(state, action: PayloadAction<GetPayoutsRulesResponse>) {
      state.payoutsRules = action.payload;
    },
    setPrefixAtomicId(state, action: PayloadAction<string>) {
      state.prefixAtomicId = action.payload;
    },
    showAlert(state, action: PayloadAction<IAlert>) {
      state.openAlert = action.payload;
    },
    showConfirmModal(state, action: PayloadAction<boolean>) {
      state.openConfirmModal = action.payload;
    },
    showLoadingModal(state, action: PayloadAction<ILoadingModal>) {
      state.loadingModal = action.payload;
    },
    showModalInfo(state, action: PayloadAction<ISaveRuleModal>) {
      state.modalInfo = action.payload;
    },
    showModalRuleDetail(state, action: PayloadAction<boolean>) {
      state.openModalRuleDetail = action.payload;
    },
  },
});

export const {
  showLoadingModal,
  dismissLoadingModal,
  showModalInfo,
  dismissModalInfo,
  showAlert,
  showConfirmModal,
  showModalRuleDetail,
  setIsFilterSearch,
  setPayoutRules,
  setPrefixAtomicId,
} = appSlice.actions;

export default appSlice.reducer;
