import { QueryParams } from "../infrastructure/QueryParamEnum";

enum routes {
  USERS = "/users",
}

const CatalogLayout: string[] = [routes.USERS];

export const validateRouteRender = (): boolean => {
  const route: string = window.location.pathname;
  const urlSearchParams: URLSearchParams = new URLSearchParams(
    window.location.search
  );

  const actualRoute: string = `/${route.split("/")[1]}`;

  const isRouteIncluded: boolean = CatalogLayout.includes(actualRoute);

  const hasNotConsoleQueryParam: boolean =
    urlSearchParams.get(QueryParams.CONSOLE) !== "3";

  return !(hasNotConsoleQueryParam && isRouteIncluded);
};
