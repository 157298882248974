export const TotalRecordsStyles = {
  text: (color: string) => ({
    fontSize: "15px",
    color: color,
    marginBottom: "10px",
  }),
  textContainer: {
    display: "flex",
    aligItems: "center",
  },
  textBtn: {
    color: "#4498EE",
  },
  btn: {
    "&:hover .buttonText": {
      color: "#023365",
      transition:
        "all 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
  },
} as const;
