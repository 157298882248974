import { massiveMerchantSlice } from "../reducers/massiveMerchant/massiveMerchant";

export const {
  setStatusError,
  setStatusComplete,
  setTotalMessages,
  setRestartCounters,
  setMassiveResponse,
  setErrorBranches,
  setOpenModalError,
} = massiveMerchantSlice.actions;
