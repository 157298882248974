import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import { InfoTrxProps } from "../ModalBodyInfo/ModalBodyRetentionHistoric";
import { Grid, Paper, Typography } from "@material-ui/core";
import { get } from "lodash";
import React from "react";
import { format, utcToZonedTime } from "date-fns-tz";
import { timeZoneRegion } from "../ModalBodyInfo/utilsModalBody";
import { useModalObservationsStyles } from "./ModalBodyObservationStyles";
import { FormatDateEnum } from "../../shared/infrastructure/FormatDateEnum";

export interface ModalBodyObservationsProps {
  InfoTrxProps: InfoTrxProps;
  country: CountryEnum;
}
const formatDate = (trxDate: string, formatDate: string): string => {
  if (trxDate === "") return "";
  return format(utcToZonedTime(trxDate, timeZoneRegion), formatDate);
};
export const ModalBodyObservations: React.FC<ModalBodyObservationsProps> = (
  props: ModalBodyObservationsProps
) => {
  const classes = useModalObservationsStyles();
  const { InfoTrxProps } = props;
  return (
    <>
      <Paper className={classes.paper}>
        <Grid container spacing={2} color="secondary" className={classes.grid}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item container xs={12}>
                <Grid item xs={4}>
                  <Typography variant="body2" id={"kindData"}>
                    Usuario:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2" color="textSecondary">
                    {get(InfoTrxProps.trxInfo, "backoffice_user", "")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={4}>
                  <Typography variant="body2" id={"kindData"}>
                    Rechazado el:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2" color="textSecondary">
                    {formatDate(
                      get(InfoTrxProps.trxInfo, "processing_date", ""),
                      FormatDateEnum.YMD
                    )}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {formatDate(
                      get(InfoTrxProps.trxInfo, "processing_date", ""),
                      FormatDateEnum.HMS
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={4}>
                  <Typography variant="body2" id={"kindData"}>
                    Motivo:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2" color="textSecondary">
                    {get(InfoTrxProps.trxInfo, "reject.reason", "")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
