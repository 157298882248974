import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Chip, ChipProps } from "@material-ui/core";

interface SimpleChipProps extends ChipProps {
  type: "success" | "warning" | "info" | "default" | "error";
  fixedWidth?: string;
  justifyContent?: string;
  label: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    border: 0,
    color: (props: SimpleChipProps) => {
      switch (props.type) {
        case "success":
          return "#0DC298";
        case "default":
          return theme.palette.grey.A700;
        case "info":
          return theme.palette.primary.main;
        case "warning":
          return theme.palette.warning.main;
        case "error":
          return theme.palette.error.main;
        default:
          return "inherit";
      }
    },
    backgroundColor: (props: SimpleChipProps) => {
      switch (props.type) {
        case "success":
          return "rgba(183, 255, 238, 0.2)";
        case "default":
          return theme.palette.background.default;
        case "info":
          return "#F7FAFC";
        case "error":
          return theme.palette.error.light;
        default:
          return "inherit";
      }
    },
    width: (props: SimpleChipProps) =>
      props.fixedWidth ? props.fixedWidth : "inherit",
    borderRadius: "4px",
    justifyContent: (props: SimpleChipProps) =>
      props.justifyContent ? props.justifyContent : "inherit",
  },
}));

const SimpleChip: React.FC<SimpleChipProps> = (props: SimpleChipProps) => {
  const classes = useStyles(props);

  return (
    <React.Fragment>
      <Chip
        className={classes.chip}
        size="small"
        label={props.label}
        {...props}
      />
    </React.Fragment>
  );
};

export default SimpleChip;
