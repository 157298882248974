import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IMassiveBranch,
  IMassiveBranchResponse,
} from "../../interfaces/massiveBranch/massiveBranch.interfaces";

export const initialState: IMassiveBranch = {
  errorBranches: [],
  massiveResponse: [],
  openModalError: false,
  statusComplete: 0,
  statusError: 0,
  totalMessages: null,
};

export const massiveMerchantSlice = createSlice({
  initialState,
  name: "massiveBranch",
  reducers: {
    setErrorBranches: (
      state,
      { payload }: PayloadAction<IMassiveBranchResponse[]>
    ) => {
      state.errorBranches.push(...payload);
    },
    setMassiveResponse: (
      state,
      { payload }: PayloadAction<IMassiveBranchResponse[]>
    ) => {
      state.massiveResponse.push(...payload);
    },
    setOpenModalError: (state, { payload }: PayloadAction<boolean>) => {
      state.openModalError = payload;
    },
    setRestartCounters: (state) => {
      state.statusComplete = 0;
      state.statusError = 0;
      state.totalMessages = null;
    },
    setStatusComplete: (state) => {
      state.statusComplete += 1;
    },
    setStatusError: (state) => {
      state.statusError += 1;
    },
    setTotalMessages: (state, { payload }: PayloadAction<number>) => {
      state.totalMessages = payload;
    },
  },
});

export default massiveMerchantSlice.reducer;
