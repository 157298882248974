import { TimeZoneCatalog } from "../infraestructure/time-zone-catalogues";
import { get } from "lodash";
import { UtcEnum } from "../infraestructure/utc-enum";
import { utcToZonedTime } from "date-fns-tz";
import { UTC } from "../constants/TimeZoneDefault";

export interface ICatalog {
  description: string;
  region?: string;
  timeZone?: string;
  utc?: string;
}

const _timeZoneDefault: string = "America/Guayaquil";
const timeZoneHour = (text: string | undefined, value: string): string => {
  let index: number;

  if (text === undefined)
    return value === UTC ? UtcEnum.utc_05 : _timeZoneDefault;

  index = TimeZoneCatalog.findIndex(
    (data: ICatalog) => data.description === text
  );

  if (index >= 0) {
    const lang: string | undefined = get(
      TimeZoneCatalog[index],
      value,
      undefined
    );

    return lang !== undefined
      ? lang
      : value === UTC
      ? UtcEnum.utc_05
      : _timeZoneDefault;
  }

  return value === UTC ? UtcEnum.utc_05 : _timeZoneDefault;
};

export const timeZoneRegions = (): string => {
  return timeZoneHour(localStorage.getItem("timeZone")!, "region");
};

export const dateZonedTime = (date: number | string | Date): Date => {
  return utcToZonedTime(new Date(date), timeZoneRegions());
};
