import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const mexicoDeferredInfoStyles = createNamedStyles({
  imgCardBrand: {
    width: 80,
    height: "auto",
  },
  paperMonth: {
    width: 35,
    height: 23,
  },
  monthBox: {
    pb: 2,
    display: "flex",
    flexDirection: "column",
    rowGap: "15px",
  },
  boxDetails: {
    px: 2,
    pt: 3,
    pb: 2,
    bgcolor: "#FBFCFE",
  },
  expansionTitle: {
    color: "#023365",
    lineHeight: "19.2px",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    letterSpacing: "0.0015em",
  },
  title: {
    color: "#023365",
    lineHeight: "120%",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
  },
  subtitle: {
    color: "#6D7781",
    lineHeight: "140%",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    paddingBottom: 2,
  },
  caption: {
    color: "#6D7781",
  },
  empty: {
    fontSize: "14px",
  },
});
