import { CountryEnum } from "../countryEnum";

export enum CountryTypePerson {
  moral = "Moral",
  physic = "Física",
  juridic = "Jurídica",
  natural = "Natural",
}

export const countriesPhysicMoral: string[] = [
  CountryEnum.guatemala,
  CountryEnum.el_salvador,
  CountryEnum.honduras,
  CountryEnum.mexico,
];

export const countriesPhysicJuridic: string[] = [
  CountryEnum.costa_rica,
  CountryEnum.nicaragua,
  CountryEnum.brasil,
];

export const countriesNaturalJuridic: string[] = [
  CountryEnum.ecuador,
  CountryEnum.panama,
  CountryEnum.chile,
  CountryEnum.peru,
  CountryEnum.colombia,
  CountryEnum.usa,
  CountryEnum.generic,
];

export const countriesKushki: string[] = [
  ...countriesPhysicMoral,
  ...countriesPhysicJuridic,
  ...countriesNaturalJuridic,
];
