import { GetConciliationTransactionsRequest } from "../../../../types/get_conciliation_transactions_request";
import { Dispatch } from "redux";
import axiosUtil from "../../../shared/axios-util";
import { GetConciliationTransactionsResponse } from "../../../../types/get_conciliation_transactions_response";
import { API_ENDPOINTS } from "../../../shared/constants/api_endpoints";
import {
  hideLoading,
  setConciliationList,
  setSummary,
  setTotalConciliations,
  showLoading,
} from "../../actions/app/app";
import { setBodyFilter } from "../../actions/filters/filters";

export const getConciliationList = async (
  request: GetConciliationTransactionsRequest,
  dispatch: Dispatch
) => {
  try {
    dispatch(showLoading());
    const response = await axiosUtil.post<GetConciliationTransactionsResponse>(
      API_ENDPOINTS.CONCILIATION_LIST,
      request
    );

    dispatch(setTotalConciliations(response.data.total));
    dispatch(setConciliationList(response.data.data));
    dispatch(setSummary(response.data.summary!));
    dispatch(setBodyFilter(request));

    return response.data;
  } catch (e) {
    return false;
  } finally {
    dispatch(hideLoading());
  }
};
