import React from "react";
import { Routes } from "../../shared/constants/routes";
import { CountryEnum } from "../../shared/countryEnum";
import { get } from "lodash";
import { ModalLoader } from "@kushki/connect-ui";
import { LayoutStepContainer } from "../LayoutStepContainer/LayoutStepContainer";
import {
  isAvailableCountry,
  validateCountryEmpty,
} from "../../shared/services/CatalogPersonTypeByCountry";
import { getCountryName } from "../../shared/constants/Countries";
import BillingDataComponent from "../../components/BillingDataComponent/BillingDataComponent";
import BillingDataFormCountriesMX from "../../components/BillingDataFormCountries/BillingDataFormCountriesMX";
import BillingDataFormCountriesBR from "../../components/BillingDataFormCountries/BillingDataFormCountriesBR";
import BillingDataFormCountriesForm from "../../components/BillingDataFormCountries/BillingDataFormCountriesForm";
import BillingDataFormCountriesEC from "../../components/BillingDataFormCountries/BillingDataFormCountriesEC";
import BillingDataFormCountriesPE from "../../components/BillingDataFormCountries/BillingDataFormCountriesPE";
import BillingDataFormCountriesCR from "../../components/BillingDataFormCountries/BillingDataFormCountriesCR";
import BillingDataFormCountriesCO from "../../components/BillingDataFormCountries/BillingDataFormCountriesCO";
import BillingDataFormCountriesCH from "../../components/BillingDataFormCountries/BillingDataFormCountriesCH";
import BillingDataFormCountriesPA from "../../components/BillingDataFormCountries/BillingDataFormCountriesPA";
import BillingDataFormCountriesEU from "../../components/BillingDataFormCountries/BillingDataFormCountriesEU";
import BillingDataFormCountriesGT from "../../components/BillingDataFormCountries/BillingDataFormCountriesGT";
import BillingDataFormCountriesES from "../../components/BillingDataFormCountries/BillingDataFormCountriesES";
import BillingDataFormCountriesHN from "../../components/BillingDataFormCountries/BillingDataFormCountriesHN";
import BillingDataFormCountriesNI from "../../components/BillingDataFormCountries/BillingDataFormCountriesNI";
import { useBillingDataGeneral } from "../../shared/utils/state/useBillingDataGeneral";
import { BasicContainerSkeleton } from "../../components/Skeletons/BasicContainerSkeleton/BasicContainerSkeleton";
import { basicStateContainerStyles as styles } from "../BasicFormStateContainer/BasicStateContainer.styles";
import { Box } from "@mui/material";
import {
  LOADER_DESCRIPTION,
  LOADER_TITLE,
} from "../../shared/constants/labels/ModalConstants";

const BillingDataContainer = () => {
  const {
    citiesList,
    provincesList,
    form,
    footerLabel,
    handleGetCatalogZipcode,
    handleUseSameInfoSat,
    infoBilling,
    isLoadingBillingData,
    primaryButton,
    secondaryButton,
    showSave,
    zipCodeCatalogs,
    zipCodeSatCatalogs,
    headerProps,
    countriesInfo: { country, constitutionalCountry },
    isCentralizedNode,
    isEdit,
    economicActivityId,
    clientType,
    newEconomicActivitySRI,
    isDisableEconomicActivity,
    isLoadingGetNodeInfo,
  } = useBillingDataGeneral(false);

  const publicMerchantId = get(headerProps, "nodeInfo.merchantId", "");

  const getCountryForm: Record<CountryEnum | any, JSX.Element> = {
    [CountryEnum.mexico]: (
      <BillingDataFormCountriesMX
        isCentralizedNode={isCentralizedNode}
        form={form}
        handleGetCatalogZipcode={handleGetCatalogZipcode}
        handleUseSameInfoSat={handleUseSameInfoSat}
        infoBilling={infoBilling}
        zipCodeCatalogs={zipCodeCatalogs}
        zipCodeSatCatalogs={zipCodeSatCatalogs}
      />
    ),
    [CountryEnum.ecuador]: (
      <BillingDataFormCountriesEC
        valueEconomicActivitySRI={get(newEconomicActivitySRI, "id", "")}
        cityEcuadorCatalog={citiesList}
        form={form}
        economicActivityId={economicActivityId!}
        isCentralizedNode={isCentralizedNode}
        constitutionalCountry={constitutionalCountry}
        isDisableEconomicActivity={isDisableEconomicActivity}
        country={infoBilling.country as CountryEnum}
      />
    ),
    [CountryEnum.peru]: (
      <BillingDataFormCountriesPE
        form={form}
        infoBilling={infoBilling}
        provincePeruCatalog={provincesList}
        cityPeruCatalog={citiesList}
        isCentralizedNode={isCentralizedNode}
      />
    ),
    [CountryEnum.costa_rica]: (
      <BillingDataFormCountriesCR
        form={form}
        infoBilling={infoBilling}
        provinceCostaRicaCatalog={provincesList}
        cityCostaRicaCatalog={citiesList}
        isCentralizedNode={isCentralizedNode}
      />
    ),
    [CountryEnum.colombia]: (
      <BillingDataFormCountriesCO
        cityColombiaCatalog={citiesList}
        form={form}
        infoBilling={infoBilling}
        isCentralizedNode={isCentralizedNode}
      />
    ),
    [CountryEnum.chile]: (
      <BillingDataFormCountriesCH
        cityChileCatalog={citiesList}
        provinceChileCatalog={provincesList}
        form={form}
        infoBilling={infoBilling}
        isCentralizedNode={isCentralizedNode}
      />
    ),
    [CountryEnum.brasil]: (
      <BillingDataFormCountriesBR
        cityBrazilCatalog={citiesList}
        form={form}
        infoBilling={infoBilling}
        isCentralizedNode={isCentralizedNode}
      />
    ),
    [CountryEnum.panama]: (
      <BillingDataFormCountriesPA
        cityPanamaCatalog={citiesList}
        form={form}
        infoBilling={infoBilling}
        isCentralizedNode={isCentralizedNode}
      />
    ),
    [CountryEnum.usa]: (
      <BillingDataFormCountriesEU
        form={form}
        cityCatalog={citiesList}
        isCentralizedNode={isCentralizedNode}
        constitutionalCountry={constitutionalCountry}
      />
    ),
    [CountryEnum.guatemala]: (
      <BillingDataFormCountriesGT
        cityGuatemalaCatalog={citiesList}
        form={form}
        infoBilling={infoBilling}
        isCentralizedNode={isCentralizedNode}
      />
    ),
    [CountryEnum.el_salvador]: (
      <BillingDataFormCountriesES
        cityElSalvadorCatalog={citiesList}
        form={form}
        infoBilling={infoBilling}
        isCentralizedNode={isCentralizedNode}
      />
    ),
    [CountryEnum.honduras]: (
      <BillingDataFormCountriesHN
        form={form}
        cityCatalogHN={citiesList}
        isCentralizedNode={isCentralizedNode}
        constitutionalCountry={constitutionalCountry}
      />
    ),
    [CountryEnum.nicaragua]: (
      <BillingDataFormCountriesNI
        form={form}
        cityCatalogNI={citiesList}
        isCentralizedNode={isCentralizedNode}
        constitutionalCountry={constitutionalCountry}
      />
    ),
    [CountryEnum.generic]: (
      <BillingDataFormCountriesForm
        country={country as CountryEnum}
        form={form}
        infoBilling={infoBilling}
        isCentralizedNode={isCentralizedNode}
      />
    ),
  };

  const countryBillingData = (country: string) => getCountryForm[country];

  return (
    <>
      <LayoutStepContainer
        headerProps={{
          apiUrl: `${Routes.CREATE_NODE}?publicMerchantId=${publicMerchantId}`,
          country: isAvailableCountry(country) ? country : CountryEnum.generic,
          id: publicMerchantId,
          isEdit,
          subTitle: "País de operación: ",
          tabsInfo: headerProps.tabsNodeInfo,
          title: get(headerProps.nodeInfo, "generalInfo.name"),
        }}
        renderComponent={
          <Box sx={isEdit ? styles.boxContentEdit : styles.boxContent}>
            {isLoadingGetNodeInfo ? (
              <BasicContainerSkeleton />
            ) : (
              <BillingDataComponent
                constitutionalCountry={
                  isAvailableCountry(constitutionalCountry)
                    ? constitutionalCountry
                    : CountryEnum.generic
                }
                subtitleCountry={`${getCountryName(
                  constitutionalCountry
                )} - Cliente ${clientType}`}
                countryElement={countryBillingData(
                  validateCountryEmpty(constitutionalCountry)
                )}
                isEdit={isEdit}
              />
            )}
          </Box>
        }
        footerProps={{
          isEdit,
          isLoading: showSave,
          label: footerLabel,
          primaryButton,
          secondaryButton,
        }}
      />
      <ModalLoader
        descriptionText={LOADER_DESCRIPTION}
        isOpen={isLoadingBillingData}
        titleText={LOADER_TITLE}
      />
    </>
  );
};

export default BillingDataContainer;
