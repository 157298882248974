import { IconUser } from "@kushki/connect-ui";
import { Grid, Typography } from "@mui/material";
import { IContactItemProps } from "./ContactItem.interfaces";
import { contactItemStyles as styles } from "./ContactItem.styles";

export const ContactItem: React.FC<IContactItemProps> = ({ email, name }) => (
  <Grid sx={styles.container}>
    <Grid
      sx={{
        width: "25px",
      }}
    >
      <IconUser sx={styles.iconUser} />
    </Grid>
    <Grid sx={{ width: "100%" }}>
      <Typography color="text.grey" variant="caption">
        {" "}
        {name} <br /> {email}
      </Typography>
    </Grid>
  </Grid>
);
