import { ActionTypes } from "./actionType";
import { IRulesState, RulesAction } from "./actionCreator";

export const RULES_INITIAL_STATE: IRulesState = {
  isLoading: false,
  isLoadingGetMerchants: false,
  isLoadingGetRule: false,
  isLoadingGetProcessors: false,
  isLoadingRulesByType: false,
  rulesByType: undefined,
  isLoadingWhiteList: false,
  isLoadingGetSuggestedCredentials: false,
  credentialsSuggested: [],
  whitelistRules: undefined,
  processors: [],
  isRequest: false,
  requestError: false,
  businessRulesList: {
    items: [],
  },
  showMigrationRule: false,
  showFinalModal: false,
};

export const rulesReducer = (
  state: IRulesState = RULES_INITIAL_STATE,
  action: RulesAction
): IRulesState => {
  switch (action.type) {
    case ActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case ActionTypes.SET_SHOW_FINAL_MODAL:
      return {
        ...state,
        showFinalModal: action.showFinalModal,
      };

    case ActionTypes.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.notification,
      };

    case ActionTypes.SET_IS_LOADING_GET_MERCHANTS:
      return {
        ...state,
        isLoadingGetMerchants: action.isLoadingGetMerchants,
      };

    case ActionTypes.SET_IS_LOADING_GET_PROCESSORS:
      return {
        ...state,
        isLoadingGetProcessors: action.isLoadingGetProcessors,
      };

    case ActionTypes.SET_IS_LOADING_GET_RULE:
      return {
        ...state,
        isLoadingGetRule: action.isLoadingGetRule,
      };

    case ActionTypes.SET_SHOW_FINAL_LOADING:
      return {
        ...state,
        showFinalLoading: action.showFinalLoading,
      };

    case ActionTypes.SET_IS_LOADING_GET_MERCHANT:
      return {
        ...state,
        isLoadingGetMerchant: action.isLoadingGetMerchant,
      };

    case ActionTypes.SET_IS_LOADING_RULE_BY_TYPE:
      return {
        ...state,
        isLoadingRulesByType: action.isLoadingRulesByType,
      };

    case ActionTypes.SET_IS_LOADING_WHITELIST:
      return {
        ...state,
        isLoadingWhiteList: action.isLoadingWhiteList,
      };
    case ActionTypes.SET_IS_LOADING_GET_SUGGESTED_CREDENTIALS:
      return {
        ...state,
        isLoadingGetSuggestedCredentials:
          action.isLoadingGetSuggestedCredentials,
      };

    case ActionTypes.SET_MERCHANTS:
      return {
        ...state,
        merchants: action.merchants,
      };

    case ActionTypes.SET_PROCESSORS:
      return {
        ...state,
        processors: action.processors,
      };

    case ActionTypes.SET_CURRENT_SECURITY_RULE:
      return {
        ...state,
        currentSecurityRule: action.currentSecurityRule,
      };

    case ActionTypes.SET_CURRENT_MERCHANT:
      return {
        ...state,
        currentMerchant: action.currentMerchant,
      };

    case ActionTypes.SET_IS_REQUESTING:
      return {
        ...state,
        isRequest: action.isRequest,
      };

    case ActionTypes.SET_REQUEST_ERROR:
      return {
        ...state,
        requestError: action.requestError,
      };

    case ActionTypes.SET_MERCHANT_PROCESSORS:
      return {
        ...state,
        processorsMerchant: action.processorsMerchant,
      };

    case ActionTypes.SET_MERCHANT_CREDENTIALS:
      return {
        ...state,
        credentials: action.credentials,
      };

    case ActionTypes.SET_BUSINESS_RULE:
      return {
        ...state,
        businessRule: action.businessRule,
      };

    case ActionTypes.SET_RULES_BY_TYPE:
      return {
        ...state,
        rulesByType: action.rulesByType,
      };

    case ActionTypes.SET_COMMERCE_RULES:
      return {
        ...state,
        commerceRules: action.commerceRules,
      };

    case ActionTypes.SET_WHITELIST_RULES:
      return {
        ...state,
        whitelistRules: action.whitelistRules,
      };

    case ActionTypes.SET_BUSINESS_RULE_LIST:
      return {
        ...state,
        businessRulesList: action.businessRulesList,
      };

    case ActionTypes.GET_DEFAULT_PROCESSOR:
      return {
        ...state,
        defaultProcessor: action.defaultProcessor,
      };

    case ActionTypes.SET_CURRENT_WHITELIST_RULE:
      return {
        ...state,
        currentWhitelistRule: action.currentWhitelistRule,
      };

    case ActionTypes.SET_SHOW_MIGRATION_RULE:
      return {
        ...state,
        showMigrationRule: action.showMigrationRule,
      };

    case ActionTypes.GET_SUGGESTED_CREDENTIAL:
      return {
        ...state,
        credentialsSuggested: action.credentialsSuggested,
      };

    default:
      return state;
  }
};
