import { ConfigTypeEnum } from "../enums/configEnum";

enum RequiredConfigLabel {
  INCOMPLETE = "incompleto",
}

export const CatalogRequiredConfig = {
  [ConfigTypeEnum.cn001]: `Datos básicos ${RequiredConfigLabel.INCOMPLETE}`,
  [ConfigTypeEnum.cn002]: `Datos de contacto ${RequiredConfigLabel.INCOMPLETE}`,
  [ConfigTypeEnum.cn003]: `Facturación ${RequiredConfigLabel.INCOMPLETE}`,
  [ConfigTypeEnum.cn004]: `Dispersión ${RequiredConfigLabel.INCOMPLETE}`,
  [ConfigTypeEnum.cn005]: `Tarifas ${RequiredConfigLabel.INCOMPLETE}`,
  [ConfigTypeEnum.cn007]: `Procesadores ${RequiredConfigLabel.INCOMPLETE}`,
  [ConfigTypeEnum.cn015]: `Datos legales ${RequiredConfigLabel.INCOMPLETE}`,
  [ConfigTypeEnum.cn016]: `Datos de facturación ${RequiredConfigLabel.INCOMPLETE}`,
  [ConfigTypeEnum.cn017]: `Cobro ${RequiredConfigLabel.INCOMPLETE}`,
  [ConfigTypeEnum.cn018]: `Mínimo comisional ${RequiredConfigLabel.INCOMPLETE}`,
  [ConfigTypeEnum.cn019]: `Usuarios ${RequiredConfigLabel.INCOMPLETE}`,
};
