import React from "react";
const Edit = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16669 3.33398H3.33335C2.89133 3.33398 2.4674 3.50958 2.15484 3.82214C1.84228 4.1347 1.66669 4.55862 1.66669 5.00065V16.6673C1.66669 17.1093 1.84228 17.5333 2.15484 17.8458C2.4674 18.1584 2.89133 18.334 3.33335 18.334H15C15.442 18.334 15.866 18.1584 16.1785 17.8458C16.4911 17.5333 16.6667 17.1093 16.6667 16.6673V10.834"
        stroke="#293036"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4167 2.08417C15.7482 1.75265 16.1978 1.56641 16.6667 1.56641C17.1355 1.56641 17.5852 1.75265 17.9167 2.08417C18.2482 2.41569 18.4345 2.86533 18.4345 3.33417C18.4345 3.80301 18.2482 4.25265 17.9167 4.58417L10 12.5008L6.66669 13.3342L7.50002 10.0008L15.4167 2.08417Z"
        stroke="#293036"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default Edit;
