import * as React from "react";
import { SvgIcon as MuiSvgIcon, styled, SvgIconProps } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../Themes/themekushki";

const SvgIcon = styled(MuiSvgIcon, {
  name: "MopeimIcon",
  shouldForwardProp: (prop) => prop !== "fill",
})<SvgIconProps>(({ color, fontSize }) => ({
  fill: color + "!important",
  fontSize: fontSize,
}));

SvgIcon.defaultProps = {
  "aria-hidden": "true",
  focusable: "false",
  viewBox: "0 0 24 24",
};

const IconChevronUp: React.FunctionComponent<SvgIconProps> = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <SvgIcon
        {...props}
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M2.28772 11.5533C2.19808 11.5503 2.10996 11.5293 2.02863 11.4915C1.9473 11.4537 1.87443 11.3999 1.81439 11.3333C1.7519 11.2713 1.7023 11.1976 1.66846 11.1163C1.63461 11.0351 1.61719 10.948 1.61719 10.8599C1.61719 10.7719 1.63461 10.6848 1.66846 10.6036C1.7023 10.5223 1.7519 10.4486 1.81439 10.3866L7.58772 4.66661C7.65001 4.60482 7.72389 4.55594 7.80512 4.52276C7.88634 4.48959 7.97332 4.47277 8.06105 4.47328C8.14879 4.47277 8.23577 4.48959 8.31699 4.52276C8.39822 4.55594 8.47209 4.60482 8.53439 4.66661L14.1877 10.3266C14.2575 10.3864 14.3142 10.4599 14.3542 10.5426C14.3942 10.6253 14.4167 10.7154 14.4203 10.8072C14.4238 10.8991 14.4083 10.9906 14.3748 11.0762C14.3413 11.1617 14.2905 11.2394 14.2255 11.3044C14.1605 11.3693 14.0828 11.4202 13.9973 11.4537C13.9117 11.4872 13.8202 11.5027 13.7284 11.4991C13.6365 11.4956 13.5465 11.4731 13.4637 11.4331C13.381 11.3931 13.3075 11.3364 13.2477 11.2666L8.06105 6.05328L2.72772 11.3866C2.60618 11.4938 2.44977 11.553 2.28772 11.5533Z" />
      </SvgIcon>
    </ThemeProvider>
  );
};

export default IconChevronUp;
