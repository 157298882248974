import { theme } from "@kushki/connect-ui";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import MainContainer from "./containers/MainContainer/MainContainer";
import { ROUTES, SPA_BASENAME } from "./shared/constants/routes";

import { store } from "./store/store";

const Root = () => {
  return (
    <React.StrictMode>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&display=swap');"
        }
      </style>
      <style>
        {
          "@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500&display=swap');"
        }
      </style>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter basename={SPA_BASENAME}>
            <Routes>
              <Route path={ROUTES.INDEX} element={<MainContainer />} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
};

export default Root;
