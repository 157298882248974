import { ChoiceButton, ModalSimple } from "@kushki/connect-ui";
import { Add, DeleteOutline } from "@mui/icons-material";
import React, { FC, useState } from "react";
import { Box, Button, Container, IconButton } from "@mui/material";
import { ModalConfirm } from "../../components/ModalConfirm/ModalConfirm";
import { VARIABLE_ARRAY_CONFIRM_MODAL } from "../../shared/constants/array_option_confirm_data";
import {
  CANCEL_TEXT,
  CONTINUE_TEXT,
  DESCRIPTION_MODAL_CONFIRM,
  DESCRIPTION_MODAL_VALIDATE,
  MODAL_CONFIRM,
  MODAL_VALIDATE,
} from "../../shared/constants/labels/buttons_labels";
import {
  OPEN_MODAL,
  OPEN_MODAL_CONFIRM,
  OPEN_MODAL_LOADER_CHECKING_FILE,
  OPEN_MODAL_LOADER_CREATE_BRANCHES,
  OPEN_MODAL_VALIDATE,
  OPEN_MODAL_VALIDATE_ERRORS,
  SIDEBAR,
  SUB_MERCHANT,
} from "../../shared/constants/labels/main_containter_labels";
import {
  BRANCH_NAME,
  CLIENT_TYPE_LABEL,
  COUNTRY_CONSTITUTION,
} from "../../shared/constants/labels/table_labels";
import { IConfirmModal } from "../../shared/interfaces/confirmModal.interfaces";
import { mainContainerStyles as styles } from "./MainContainer.styles";
import SelectItemComplete from "../../components/SelectItemComplete/SelectItemComplete";
import { SelectItemCompleteConfig } from "../../shared/infrastructure/SelectItemCompleteConfig";
import { FilterSideBar } from "../../components/Filters/FilterSideBar/FilterSideBar";
import { FilterSideBarProps } from "../../components/Filters/FilterSideBar/FilterSideBar.interfaces";
import { ItemCategoryProps } from "../../components/Filters/ItemCategory/interfaces";
import { FilterTextsEnum } from "../../shared/enums/FilterTextsEnum";
import { ModalValidate } from "../../components/ModalValidate/ModalValidate";
import {
  VARIABLE_ARRAY_VALIDATE_BODY_MODAL,
  VARIABLE_ARRAY_VALIDATE_ERROR_HEADER_MODAL,
  VARIABLE_ARRAY_VALIDATE_HEADER_MODAL,
} from "../../shared/constants/array_option_validate_data";
import { IValidateModal } from "../../shared/interfaces/validateModal.interfaces";
import { InfoCentralizedTooltipComponent } from "../../components/InfoCentralizedTooltip/InfoCentralizedTooltipComponent";
import { TooltipTitleEnums } from "../../shared/enums/TooltipTitleEnums";
import { ALL_COUNTRIES } from "../../shared/infrastructure/AllCountries";
import { TableSimple } from "../../components/Table/TableSimple/TableSimple";
import { TableBodyCellEnum } from "../../components/Table/TableSimple/constants";
import { ITableRowProps } from "../../components/Table/TableSimple/interfaces";
import { ValidationTypeEnum } from "../../components/TableCell/CellInput/CellInput.enums";
import { CLIENT_TYPE } from "../../shared/infrastructure/ClientType";
import { ModalLoader } from "../../components/ModalLoader/ModalLoader";
import {
  LOADER_CHECKING_FILE_DESCRIPTION,
  LOADER_CHECKING_FILE_TITLE,
  LOADER_CREATE_BRANCHES_DESCRIPTION,
  LOADER_CREATE_BRANCHES_TITLE,
} from "../../shared/constants/labels/branches_labels";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconArrows from "../../assets/icons/IconArrows";
import { EditStatusEnum } from "../../shared/enums/EditStatusEnum";

const MainContainer: FC = () => {
  const [openLoader, setOpenLoader] = useState(false);
  const [dataLoader, setDataLoader] = useState({ description: "", title: "" });

  const [openModal, setOpenModal] = useState(false);
  const [rows, setRows] = useState<ITableRowProps[]>([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openValidateModal, setOpenValidateModal] = useState(false);
  const [openValidateErrorModal, setOpenValidateErrorModal] = useState(false);
  const total: string = "40";

  const contentModal: IConfirmModal = {
    hasContent: true,
    options: VARIABLE_ARRAY_CONFIRM_MODAL,
    subtitle: MODAL_CONFIRM.TOTAL_BRANCH(total),
  };

  const contentHeaderModalValidate: IValidateModal = {
    hasContent: true,
    optionsHeader: VARIABLE_ARRAY_VALIDATE_HEADER_MODAL,
    subtitle: MODAL_VALIDATE.TOTAL_BRANCH(total),
  };
  const contentBodyModalValidate: IValidateModal = {
    hasContent: false,
  };

  const contentHeaderModalValidateError: IValidateModal = {
    hasContent: true,
    optionsHeader: VARIABLE_ARRAY_VALIDATE_ERROR_HEADER_MODAL,
    subtitle: MODAL_VALIDATE.TOTAL_BRANCH(total),
  };

  const contentBodyModalValidateError: IValidateModal = {
    hasContent: true,
    optionsBody: VARIABLE_ARRAY_VALIDATE_BODY_MODAL,
  };

  const filterSideBarProps: FilterSideBarProps = {
    categoryItems: [
      {
        groupSection: "Estado",
        isMinimize: true,
        items: [
          {
            label: "Incompleto",
            selected: false,
            value: "Incompleto",
          },
          {
            label: "Sin modificar",
            selected: false,
            value: "SinModificar",
          },
          {
            label: "Modificado",
            selected: false,
            value: "Modificado",
          },
        ],
        selectType: "multiple",
        type: "byChips",
      },
      {
        groupSection: "País",
        isMinimize: true,
        items: [
          {
            label: "México",
            selected: false,
            value: "MEXICO",
          },
          {
            label: "México",
            selected: false,
            value: "MEXICO",
          },
          {
            label: "Colombia",
            selected: false,
            value: "COLOMBIA",
          },
          {
            label: "Ecuador",
            selected: false,
            value: "ECUADOR",
          },
          {
            label: "Ecuador",
            selected: false,
            value: "ECUADOR",
          },
          {
            label: "Ecuador",
            selected: false,
            value: "ECUADOR",
          },
          {
            label: "Ecuador",
            selected: false,
            value: "ECUADOR",
          },
          {
            label: "Perú",
            selected: false,
            value: "PERU",
          },
        ],
        selectType: "multiple",
        type: "byChips",
      },
      {
        groupSection: CLIENT_TYPE_LABEL,
        isMinimize: true,
        items: [
          {
            label: "B2C",
            selected: false,
            value: "B2C",
          },
          {
            label: "B2B",
            selected: false,
            value: "B2B",
          },
          {
            label: "PSP",
            selected: false,
            value: "PSP",
          },
        ],
        selectType: "multiple",
        type: "byChips",
      },
    ],
    filtersCount: 0,
    isMinimized: false,
    isOpen: true,
    onClick: () => {},
    title: FilterTextsEnum.FILTER_BY,
  };

  const CELL_HEADER_SPACING = 1;

  const addRow = () => {
    const rowIndex: number = rows.length;
    const rowsState = [...rows];

    rowsState.push({
      cells: [
        {
          props: {
            cellProps: {
              align: "center",
              minWidth: 8,
              spacing: 1,
            },
            defaultValue: "QWE",
            id: "name",
            label: BRANCH_NAME,
            rowIndex,
            upperCase: true,
            validations: [
              {
                errorMessage: "Requerido",
                type: ValidationTypeEnum.required,
              },
              {
                errorMessage: "Alfanumérico",
                regex: /[^A-Z\d\s]/g,
                type: ValidationTypeEnum.regex,
              },
            ],
          },
          type: TableBodyCellEnum.INPUT_TEXT,
        },
        {
          props: {
            cellProps: {
              align: "center",
              spacing: 1,
            },
            defaultValue: "Mexico",
            id: "country",
            isCountry: true,
            items: ALL_COUNTRIES,
            label: COUNTRY_CONSTITUTION,
            required: true,
            rowIndex,
          },
          type: TableBodyCellEnum.SELECT_ITEM,
        },
        {
          props: {
            cellProps: {
              align: "center",
              spacing: 1,
            },
            defaultValue: "B2B",
            id: "client",
            items: CLIENT_TYPE,
            label: CLIENT_TYPE_LABEL,
            required: true,
            rowIndex,
          },
          type: TableBodyCellEnum.SELECT_ITEM,
        },
      ],
      id: new Date().getTime().toString(),
      rowProps: {
        labelCheck: {
          check: "Descentralizado",
          unCheck: "Centralizado",
        },
      },
    });

    setRows(rowsState);
  };

  const onClickFilterSideBar = (itemCategory: ItemCategoryProps[]) => {
    filterSideBarProps.onClick(itemCategory);
  };

  const rowsCountry: ITableRowProps[] = [
    {
      cells: [
        {
          props: {
            cellProps: {
              align: "left",
              spacing: 1,
            },
            id: "name",
            line1: "Walmart Quito",
            line2: "Creación: 17/MAR/2022",
            rowIndex: 1,
            type: "twoLines",
          },
          type: TableBodyCellEnum.TITLE,
        },
        {
          props: {
            cellProps: {
              align: "center",
              spacing: 1,
            },
            country: "Argentina",
            id: "country",
            rowIndex: 1,
            type: "twolines",
          },
          type: TableBodyCellEnum.FLAG,
        },
        {
          props: {
            cellProps: {
              align: "center",
              spacing: 1,
            },
            id: "client",
            line1: "B2B",
            rowIndex: 1,
            type: "oneLine",
          },
          type: TableBodyCellEnum.TITLE,
        },
        {
          props: {
            cellProps: { align: "center", spacing: 2 },
            color: "primary",
            id: "status",
            rowIndex: 1,
            text: "Sin Modificar",
            value: EditStatusEnum.pending,
          },
          type: TableBodyCellEnum.TAG,
        },
      ],
      id: "1",
      rowProps: {},
    },
    {
      cells: [
        {
          props: {
            cellProps: {
              align: "left",
              spacing: 1,
            },
            id: "name",
            line1: "Walmart Quito",
            line2: "Creación: 17/MAR/2022",
            rowIndex: 2,
            type: "twoLines",
          },
          type: TableBodyCellEnum.TITLE,
        },
        {
          props: {
            cellProps: {
              align: "center",
              spacing: 1,
            },
            country: "Ecuador",
            id: "country",
            rowIndex: 2,
            type: "twolines",
          },
          type: TableBodyCellEnum.FLAG,
        },
        {
          props: {
            cellProps: {
              align: "center",
              spacing: 1,
            },
            id: "client",
            line1: "B2B",
            rowIndex: 2,
            type: "oneLine",
          },
          type: TableBodyCellEnum.TITLE,
        },
        {
          props: {
            cellProps: { align: "center", spacing: 2 },
            color: "primary",
            id: "status",
            rowIndex: 2,
            text: "Sin Modificar",
            value: EditStatusEnum.pending,
          },
          type: TableBodyCellEnum.TAG,
        },
      ],
      id: "2",
      rowProps: {},
    },
  ];

  return (
    <Box sx={styles.boxContainer}>
      <Box sx={styles.boxSidebar}>
        {SIDEBAR}
        <ChoiceButton
          checked={false}
          img={
            <svg
              className="iconChoice"
              fill="none"
              fontSize="36px"
              height="1em"
              viewBox="0 0 40 41"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="20"
                cy="20.5"
                r="19.5"
                stroke="#112B45"
                strokeWidth="2"
              />
              <circle
                cx="20"
                cy="20.5"
                r="15.5"
                stroke="#1E65AE"
                strokeWidth="2"
              />
              <circle
                cx="20"
                cy="20.5"
                r="11.5"
                stroke="#4498EE"
                strokeWidth="2"
              />
              <circle
                className="circleFill"
                cx="20"
                cy="20.5"
                fill="#00E6B2"
                r="8"
              />
            </svg>
          }
          label={SUB_MERCHANT}
          selected
          value="1"
        />
      </Box>
      <Box sx={styles.box}>
        <Box sx={styles.boxHeader}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenModal(true);
            }}
          >
            {OPEN_MODAL}
          </Button>
          <ModalSimple
            onClickPrimary={() => {
              setOpenModal(false);
            }}
            onClickSecondary={() => {
              setOpenModal(false);
            }}
            buttonPrimary={"cerrar"}
            buttonSecondary={"cerrar"}
            descriptionText={"este modal es de prueba"}
            isOpen={openModal}
            onClose={() => {
              setOpenModal(false);
            }}
            titleText={"asdasd"}
            typeModal={1}
            buttonPrimaryDisabled={false}
          />
          <IconButton color="secondary">
            <Add />
          </IconButton>
        </Box>
        <Box sx={styles.boxContent}>
          <SelectItemComplete
            items={ALL_COUNTRIES}
            label={SelectItemCompleteConfig.label}
            value={""}
            disable={false}
            isCountry
          />
        </Box>
        <Container>
          <Box sx={styles.boxContent}>
            <TableSimple
              allowDeletion
              allowSelection
              header={{
                cells: [
                  {
                    align: "left",
                    spacing: CELL_HEADER_SPACING,
                    text: "Nombre",
                    type: "default",
                  },
                  {
                    align: "center",
                    component: (
                      <InfoCentralizedTooltipComponent
                        icon={<InfoOutlinedIcon />}
                        title={TooltipTitleEnums.CONSTITUTION_COUNTRY}
                        placement={"top"}
                      />
                    ),
                    text: COUNTRY_CONSTITUTION,
                    type: "default",
                  },
                  {
                    align: "center",
                    spacing: CELL_HEADER_SPACING,
                    text: CLIENT_TYPE_LABEL,
                    type: "default",
                  },
                ],
                headerProps: {
                  isDisable: false,
                  labelCheck: "Seleccionar Todo",
                },
              }}
              deletionProps={{
                handleDeleteRow: (rowId: number) => {
                  const rowsCopy: ITableRowProps[] = [...rows];

                  rowsCopy.splice(rowId, 1);

                  setRows(rowsCopy);
                },
                icon: <DeleteOutline />,
                isDisabled: false,
                variant: "inherit",
              }}
              rows={rows}
            />
          </Box>
          <Box sx={styles.boxContent}>
            <Button id={"add-row"} data-testid={"add-row"} onClick={addRow}>
              {`Items #${rows.length}`}
            </Button>
          </Box>
        </Container>
        <InfoCentralizedTooltipComponent
          icon={<InfoOutlinedIcon />}
          title={TooltipTitleEnums.INFO_CENTRALIZED}
          placement={"top"}
        />
        <Box sx={styles.boxConfirmModal}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setDataLoader({
                description: LOADER_CREATE_BRANCHES_DESCRIPTION,
                title: LOADER_CREATE_BRANCHES_TITLE,
              });
              setOpenLoader(true);
            }}
          >
            {OPEN_MODAL_LOADER_CREATE_BRANCHES}
          </Button>
          <ModalLoader
            descriptionText={dataLoader.description}
            titleText={dataLoader.title}
            isOpen={openLoader}
          />
        </Box>
        <Box sx={styles.boxConfirmModal}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setDataLoader({
                description: LOADER_CHECKING_FILE_DESCRIPTION,
                title: LOADER_CHECKING_FILE_TITLE,
              });
              setOpenLoader(true);
            }}
          >
            {OPEN_MODAL_LOADER_CHECKING_FILE}
          </Button>
          <ModalLoader
            descriptionText={dataLoader.description}
            titleText={dataLoader.title}
            isOpen={openLoader}
          />
        </Box>
        <Box sx={styles.boxConfirmModal}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenConfirmModal(true);
            }}
          >
            {OPEN_MODAL_CONFIRM}
          </Button>
          <ModalConfirm
            onClickPrimary={() => {
              setOpenConfirmModal(false);
            }}
            onClickSecondary={() => {
              setOpenConfirmModal(false);
            }}
            buttonPrimary={CONTINUE_TEXT}
            buttonSecondary={CANCEL_TEXT}
            descriptionText={DESCRIPTION_MODAL_CONFIRM}
            isOpen={openConfirmModal}
            onClose={() => {
              setOpenConfirmModal(false);
            }}
            titleText={MODAL_CONFIRM.TITLE_MODAL_CONFIRM(total)}
            contentModal={contentModal}
          />
        </Box>
        <Box sx={styles.boxValidateModal}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenValidateModal(true);
            }}
          >
            {OPEN_MODAL_VALIDATE}
          </Button>
          <ModalValidate
            onClickPrimary={() => {
              setOpenValidateModal(false);
            }}
            onClickSecondary={() => {
              setOpenValidateModal(false);
            }}
            buttonPrimary={CONTINUE_TEXT}
            buttonSecondary={CANCEL_TEXT}
            descriptionText={DESCRIPTION_MODAL_VALIDATE}
            isOpen={openValidateModal}
            onClose={() => {
              setOpenValidateModal(false);
            }}
            titleText={MODAL_VALIDATE.TITLE_MODAL_VALIDATE}
            contentHeaderModal={contentHeaderModalValidate}
            contentBodyModal={contentBodyModalValidate}
          />
        </Box>
        <Box sx={styles.boxValidateModal}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenValidateErrorModal(true);
            }}
          >
            {OPEN_MODAL_VALIDATE_ERRORS}
          </Button>
          <ModalValidate
            onClickPrimary={() => {
              setOpenValidateErrorModal(false);
            }}
            onClickSecondary={() => {
              setOpenValidateErrorModal(false);
            }}
            buttonPrimary={CONTINUE_TEXT}
            buttonSecondary={CANCEL_TEXT}
            descriptionText={DESCRIPTION_MODAL_VALIDATE}
            isOpen={openValidateErrorModal}
            onClose={() => {
              setOpenValidateErrorModal(false);
            }}
            titleText={MODAL_VALIDATE.TITLE_MODAL_VALIDATE}
            contentHeaderModal={contentHeaderModalValidateError}
            contentBodyModal={contentBodyModalValidateError}
          />
        </Box>
        <Box sx={styles.boxContent}>
          <FilterSideBar
            data-testid={"filterBar"}
            {...filterSideBarProps}
            categoryItems={filterSideBarProps.categoryItems}
            onClick={onClickFilterSideBar}
          />
        </Box>
        <Box sx={styles.boxContent} style={{ marginTop: "5vh" }}>
          <TableSimple
            data-testid={"tableCountries"}
            allowDeletion={false}
            allowSelection={true}
            header={{
              cells: [
                {
                  align: "left",
                  component: <IconArrows />,
                  spacing: 0,
                  text: "Nombre",
                  type: "default",
                  width: 136,
                },
                {
                  align: "center",
                  component: <IconArrows />,
                  spacing: 0,
                  text: COUNTRY_CONSTITUTION,
                  type: "default",
                  width: 680,
                },
                {
                  align: "center",
                  component: <IconArrows />,
                  spacing: 0,
                  text: CLIENT_TYPE_LABEL,
                  type: "default",
                  width: 480,
                },
                {
                  align: "center",
                  component: <IconArrows />,
                  spacing: 0,
                  text: "Estado",
                  type: "default",
                  width: 340,
                },
              ],
              headerProps: {
                isDisable: false,
                labelCheck: "",
              },
            }}
            rows={rowsCountry}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MainContainer;
