import React, { PropsWithChildren } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { snackbarSpecialConditionAlertStyles } from "./SnackbarSpecialConditionAlert.styles";
import { CONDITIONS_CONFIG_BY_COMMERCE } from "../../../../shared/infrastructure/constants/RuleRequestManagerConstants";

export const SnackbarSpecialConditionAlert: React.FC<{
  rule: string;
}> = (
  props: PropsWithChildren<{
    rule: string;
  }>
) => {
  const classes: {
    container: string;
    iconButton: string;
    text: string;
  } = snackbarSpecialConditionAlertStyles();

  return (
    <Grid>
      <Box className={classes.container}>
        <IconButton className={classes.iconButton}>
          <InfoOutlinedIcon></InfoOutlinedIcon>
        </IconButton>
        <Typography className={classes.text}>
          Al configurar como etiqueta la opción{" "}
          <strong>{CONDITIONS_CONFIG_BY_COMMERCE[props.rule]}</strong> solo
          podrás configurar las Condiciones especiales ó las Condiciones de
          velocidad. Al desactivar las Condiciones especiales se activarán las
          Condiciones de velocidad y viceversa.
        </Typography>
      </Box>
    </Grid>
  );
};
