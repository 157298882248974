import { DataTableHeader } from "../../../../types/data_table_header";
import { CountriesEnum } from "./countries-enum";

export const dataTableHeader: DataTableHeader = [
  { alias: "Public ID", label: "Public ID" },
  { alias: "Alias", label: "Alias" },
  { alias: "Procesador", label: "Procesador" },
  { alias: "Modelo", label: "Modelo" },
  { alias: "Failover", label: "Failover" },
];

export enum PayInOutPopupConstants {
  PAY_IN = "Pay in",
  PAY_OUT = "Pay out",
  CARD = "card",
  TRANSFER = "transfer",
  TRANSFER_PAY_OUT = "payouts-transfer",
  CASH_PAY_OUT = "payouts-cash",
  INTERNATIONAL_TRANSFER_PAY_OUT = "internationalTransfer",
  RECURRENT_DEBIT = "transfer-subscriptions",
  CASH = "cash",
  CARD_TITLE = "Tarjeta",
  TRANSFER_TITLE = "Transferencia",
  RECURRENT_DEBIT_TITLE = "Débito recurrente",
  CASH_TITLE = "Efectivo",
  INTERNATIONAL_TRANSFER = "Transf. Internacional",
}

export enum ProcessingSectionConstants {
  KEY_CONTAINER = "card-container-processing-section",
  HEADER_CARD_TITLE = "Procesadores agregados",
  HEADER_BUTTON_TITLE = "Agregar procesador",
  FOOTER_TITLE = "Edita y revisa todos los procesadores",
  KEY_SKELETON = "skeleton-table",
  KEY_TABLE_CARD = "processor-table-payment-method-card",
  KEY_TABLE_TRANSFER = "processor-table-payment-method-transfer",
  KEY_TABLE_CASH = "processor-table-payment-method-cash",
  KEY_TABLE_CASH_OUT = "processor-table-payment-method-cash-out",
  KEY_TABLE_TRANSFER_OUT = "processor-table-payment-method-transfer-out",
  KEY_TABLE_TRANSFER_SUBSCRIPTIONS = "processor-table-payment-method-transfer-subscriptions",
  KEY_TABLE_INTERNATIONAL_TRANSFER = "processor-table-payment-method-international-transfer",
  CAPTION_TABLE_CARD = "Pay in tarjeta",
  CAPTION_TABLE_TRANSFER_IN = "Pay in transferencia",
  CAPTION_TABLE_CASH = "Pay in efectivo",
  CAPTION_TABLE_TRANSFER_OUT = "Pay out transferencia",
  CAPTION_TABLE_CASH_OUT = "Pay out efectivo",
  CAPTION_TABLE_TRANSFER_SUBSCRIPTIONS = "Pay in débito recurrente",
  CAPTION_TABLE_INTERNATIONAL_TRANSFER = "Pay out transferencia internacional",
}

export enum DeferredSectionConstants {
  KEY_CONTAINER = "card-container-deferred-section",
  HEADER_CARD_TITLE = "Diferidos",
  HEADER_BUTTON_TITLE = "Agregar diferidos",
  FOOTER_TITLE = "Edita y revisa todos los diferidos",
}

export enum ProcessorTableConstants {
  NOT_RECORDS = "No se encontraron registros de",
  BY_DEFAULT = "POR DEFECTO",
}

export enum ConfigIndexConstants {
  PROCESSING_TITLE = "Procesamiento",
  KEY_PROCESSING = "processing-section",
}

export enum ConfigIndexServicesConstants {
  GET_MERCHANT_PROCESSORS_ERROR_TITLE = "No pudimos obtener procesadores de este comercio, inténtalo más tarde.",
  GET_DEFAULT_PROCESSOR_ERROR_TITLE = "No pudimos obtener información de los procesadores por defecto, inténtalo más tarde.",
}

export enum PaymentMethods {
  CARD = "card",
  TRANSFER = "transfer",
  TRANSFER_OUT = "payouts-transfer",
  CASH_OUT = "payouts-cash",
  CASH = "cash",
  SUBSCRIPTIONS = "subscriptions",
  TRANSFER_SUBSCRIPTIONS = "transfer-subscriptions",
  INTERNATIONAL_TRANSFER = "internationalTransfer",
}

export enum PaymentMethodsSpanish {
  CARD = "Tarjeta",
  TRANSFER = "Transferencia",
  CASH = "Efectivo",
  SUBSCRIPTIONS = "Subscripciones",
}

export enum ChipProcessorStatusEnum {
  ENABLED = "HABILITADO",
  DISABLED = "DESHABILITADO",
}

export enum ProcessorStatusEnum {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export const ChipProcessorData: Record<
  string,
  { label: string; type: "default" | "warning" | "success" | "info" | "error" }
> = {
  [ProcessorStatusEnum.ENABLED]: {
    label: ChipProcessorStatusEnum.ENABLED,
    type: "success",
  },
  [ProcessorStatusEnum.DISABLED]: {
    label: ChipProcessorStatusEnum.DISABLED,
    type: "warning",
  },
};

export const ALLOWED_INTERNATIONAL_TRANSFER_COUNTRY: string[] = [
  CountriesEnum.ECUADOR,
  CountriesEnum.COLOMBIA,
  CountriesEnum.PERU,
  CountriesEnum.MEXICO,
];
