import { createNamedStyles } from "../../shared/utils";

export const HeaderFilterStyles = createNamedStyles({
  menuItem: {
    color: "text.dark",
    fontSize: "16px",
    fontWeight: "22.4px",
    height: "45px",
    width: "240px",
  },
  select: {
    borderRadius: "1px",
    color: "text.dark",
    fontSize: "16px",
    fontWeight: "22.4px",
    height: "45px",
    marginRight: "20px",
    width: "240px",
  },
});
