import React from "react";
import { Box } from "@mui/material";
import { addWebhookFormContainerStyles as styles } from "./AddWebhookFormContainer.styles";
import ReturnButton from "../../components/ReturnButton/ReturnButton";
import AddWebhookForm from "../../components/AddWebhookForm/AddWebhookForm";
import { useAddWebhookFormContainerState } from "./store/useAddWebhookFormContainerState";
import { defaultTo, get, isEmpty } from "lodash";
import FooterOptions from "../../components/FooterOptions/FooterOptions";
import { FormProvider } from "react-hook-form";
import SubHeader from "../../components/SubHeader/SubHeader";

const AddWebhookFormContainer = () => {
  const { form, isEdition, handleSave, hookForm, footerLabels, subHeaderData } =
    useAddWebhookFormContainerState();

  return (
    <FormProvider {...hookForm}>
      <Box sx={styles.container}>
        <ReturnButton />
        {isEdition && (
          <SubHeader
            name={subHeaderData.name}
            country={subHeaderData.country}
            customerId={subHeaderData.customerId}
            merchantId={subHeaderData.merchantId}
          />
        )}
        <AddWebhookForm form={form} />
      </Box>
      <Box sx={styles.containerFooter}>
        {isEdition && (
          <FooterOptions
            primaryButton={{
              isHidden: false,
              onAction: handleSave,
            }}
            secondaryButton={{
              isHidden: true,
            }}
            isLoading={true}
            label={{
              date: get(footerLabels, "date", ""),
              editDetail: false,
              isHidden: defaultTo(isEmpty(footerLabels), false),
              text: get(footerLabels, "author", ""),
            }}
          />
        )}
      </Box>
    </FormProvider>
  );
};

export default AddWebhookFormContainer;
