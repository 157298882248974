import { useEffect, useState } from "react";
import { LoadingTextEnum } from "../../../shared/enums/loading_text";
import { RefundState } from "../../../shared/enums/refund_states";
import { isB2CAgent, isB2CCoordinator } from "../../../shared/utils";
import { setCurrentActionTransaction } from "../../../store/actions/transactions/transactions";
import { useAppDispatch, useKshRedux } from "../../../store/hooks/hooks";
import { IResendRefundData } from "../../../store/interfaces/ResendRefundData.interfaces";
import { ITransaction } from "../../../store/interfaces/TransactionsState.interfaces";
import { IUseActionTransactionButtonState } from "./useActionTransactionButtonState.interfaces";
import { FileStatusDownload } from "../../../shared/enums/file_status_download_enum";
import generateCertificate from "../../../store/thunks/certificates/generateCertificates/generate_certificate";
import { GenerateCertificateRequestWS } from "../../../store/thunks/certificates/generateCertificates/generate_certificate.interfaces";
import { showLoading } from "../../../store/actions/actions";

export const useActionTransactionButtonState = (
  payMethod: string,
  transaction: ITransaction
): IUseActionTransactionButtonState => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const baseCondition =
    transaction.status !== "declined" &&
    transaction.fileStatusDownload !== FileStatusDownload.COMPLETED;

  const resendRefund = !(
    transaction.refundState === RefundState.REFUND ||
    transaction.refundState === RefundState.COMPLETED
  );

  const regenerateCertificate =
    transaction.refundState === RefundState.PAID &&
    transaction.fileStatusDownload === "FAILED";

  const validationRoles = isB2CCoordinator() && !isB2CAgent();

  const isButtonDisabled = !(
    baseCondition &&
    validationRoles &&
    (regenerateCertificate || resendRefund)
  );

  const [disabledButton, setDisabledButton] =
    useState<boolean>(isButtonDisabled);

  const dispatch = useAppDispatch();
  const [disabledGenerateCertificate, setDisabledGenerateCertificate] =
    useState<boolean>(transaction.refundState === RefundState.REFUND);
  const {
    actions: { setLoadingText, setRefundModalData, showRefundModal },
  } = useKshRedux();

  const open = Boolean(anchorEl);

  useEffect(() => {
    setLoadingText(LoadingTextEnum.LOADING);
  }, []);

  useEffect(() => {
    setDisabledButton(disabledButton);
  }, [payMethod, transaction]);

  const handleClickButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(setCurrentActionTransaction(transaction));
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGenerateCertificate = async () => {
    setDisabledGenerateCertificate(true);
    const request: GenerateCertificateRequestWS = {
      action: "eventReport",
      id: transaction.transactionId,
    };

    dispatch(showLoading());

    const thunk = await dispatch(
      generateCertificate({
        data: request,
      })
    );

    const { payload: response } = thunk;

    setDisabledGenerateCertificate(false);

    if (response) {
      setDisabledButton(true);
    }

    setAnchorEl(null);
  };

  const handleResendRefundRequest = () => {
    const ResendRefundData: IResendRefundData = {
      email: transaction.email,
      transactionId: transaction.transactionId,
    };

    setRefundModalData(ResendRefundData);
    showRefundModal();
    setAnchorEl(null);
  };

  return {
    actions: {
      handleClickButton,
      handleClose,
      handleGenerateCertificate,
      handleResendRefundRequest,
    },
    anchorEl,
    disabledButton,
    disabledGenerateCertificate,
    open,
  };
};
