export enum RoleEnum {
  WEBCHECKOUT_MASTER = "WebcheckoutMaster",
  WEBCHECKOUT_BASIC = "WebcheckoutBasic",
  BACKOFFICE_USER_READER = "BackofficeUserReader",
  BACKOFFICE_USER_MAKER = "BackofficeUserMaker",
  BACKOFFICE_GLOBAL_REPORTS = "BackofficeGlobalReports",
  SNR_COORDINATOR = "SnrCoordinator",
  SNR_AGENT = "SnrAgent",
  FIN_OPS_BACKOFFICE = "FinOpsBackOffice",
  TREASURY_BACKOFFICE = "TreasuryBackOffice",
}

export const getConsoleRoles = (
  payload: string[]
): Record<RoleEnum, boolean> => {
  return {
    [RoleEnum.WEBCHECKOUT_MASTER]: payload.includes(
      RoleEnum.WEBCHECKOUT_MASTER
    ),
    [RoleEnum.WEBCHECKOUT_BASIC]: payload.includes(RoleEnum.WEBCHECKOUT_BASIC),
    [RoleEnum.BACKOFFICE_USER_READER]: payload.includes(
      RoleEnum.BACKOFFICE_USER_READER
    ),
    [RoleEnum.BACKOFFICE_USER_MAKER]: payload.includes(
      RoleEnum.BACKOFFICE_USER_MAKER
    ),
    [RoleEnum.BACKOFFICE_GLOBAL_REPORTS]: payload.includes(
      RoleEnum.BACKOFFICE_GLOBAL_REPORTS
    ),
    [RoleEnum.SNR_COORDINATOR]: payload.includes(RoleEnum.SNR_COORDINATOR),
    [RoleEnum.SNR_AGENT]: payload.includes(RoleEnum.SNR_AGENT),
    [RoleEnum.FIN_OPS_BACKOFFICE]: payload.includes(
      RoleEnum.FIN_OPS_BACKOFFICE
    ),
    [RoleEnum.TREASURY_BACKOFFICE]: payload.includes(
      RoleEnum.TREASURY_BACKOFFICE
    ),
  };
};
