import { getDate } from "../utils/date_utils";
import { ILastModify } from "../interfaces/LastModify.interfaces";

export const buildLastModifyProps = (
  updatedAt: number,
  userName: string
): ILastModify => ({
  author: userName,
  date: getDate(updatedAt, "dd/MM/yyyy - HH:mm"),
});
