import React from "react";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import {
  GeneralAction,
  HeaderSection,
} from "../../shared/infrastructure/constants/CredentialManagerConstants";
import { Plus } from "react-feather";
import { IconChevronLeft, IconEdit } from "@kushki/connect-ui";
import { makeStyles } from "@mui/styles";
import { useCredentialHeader } from "./state/useCredentialHeader";
import { IAddCredentialModalProps } from "../../shared/infrastructure/interfaces/IAddCredentialModalProps";
import { IMerchantCredential } from "../../shared/infrastructure/interfaces/IMerchantCredential";
import { TextFieldSearchBy } from "@kushki/connect-ui";
import { IItemSelected } from "../../shared/infrastructure/interfaces/IItemSelected";
import { isEmpty } from "lodash";
import { isBackofficeMasterCustomer } from "../../shared/utils/local-storage/getRolesUtils";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";
import { ComponentIdEnum } from "../../shared/infrastructure/enums/ComponentIdEnum";
import { HeaderFilters } from "../HeaderFilters/HeaderFilters";
import { GetCredentialsRequest } from "../../../types/get_credentials_request";

const credentialsStyles = makeStyles({
  divider: {
    height: "25px",
    border: "0px solid #B7B7BAFF",
    backgroundColor: "#B7B7BAFF",
  },
});

export interface ICredentialHeaderProps {
  merchantId: string;
  entityName: string;
  merchantName: string;
  country: string;
  changeCredentialModalProps: (modalProps: IAddCredentialModalProps) => void;
  credentials?: IMerchantCredential[] | undefined;
  isLoadingGetSuggestedCredentials: boolean;
  isNodeLayout: boolean;
  onChangeFilters: (request: Partial<GetCredentialsRequest>) => void;
}

export const CredentialHeader = (props: ICredentialHeaderProps) => {
  const {
    actions,
    merchantCredentials,
    filterCredential,
    reloadCredentials,
    parentMerchantID,
    onChangeFilterSidebar,
    path,
  } = useCredentialHeader(props);
  const { merchantId, merchantName, country, isNodeLayout } = props;
  const is_backofficeMasterCustomer = isBackofficeMasterCustomer();
  return (
    <Box
      sx={{
        padding: "0px !important",
      }}
    >
      <Grid container pt={4} px={0}>
        {isNodeLayout && (
          <React.Fragment>
            <Box pb={2}>
              <Button
                startIcon={<IconChevronLeft />}
                className={"buttonLink"}
                color="primary"
                onClick={actions.handleReturnAction}
              >
                {GeneralAction.RETURN}
              </Button>
            </Box>
            <Grid container mt={1}>
              <Grid item xs={6}>
                <Box pb={1} display={"flex"} alignItems={"center"}>
                  <Typography
                    variant={"h3"}
                    color="text.primary"
                    style={{ float: "left" }}
                    mr={2}
                  >
                    {merchantName}
                  </Typography>
                  <IconEdit color="primary" fontSize="medium" />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box style={{ float: "right" }} mt={2}>
                  <Typography variant={"overline"} color="text.grey">
                    {HeaderSection.CUSTOMER_ID} {parentMerchantID}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container mt={1} mb={3}>
              <Grid item xs={12}>
                <Box pb={2} display={"flex"} alignItems={"center"}>
                  <Typography variant={"caption"} color="text.primary" mr={2}>
                    {HeaderSection.COUNTRY_OPERATION}
                  </Typography>
                  {actions.getCountryFlag()}
                  <Typography variant={"h6"} color="text.primary" ml={1} mr={3}>
                    {country}
                  </Typography>
                  <Divider
                    orientation={"vertical"}
                    className={credentialsStyles().divider}
                  />
                  <Typography variant={"caption"} color="text.primary" ml={3}>
                    {HeaderSection.ID}
                  </Typography>
                  <Typography variant={"h6"} color="text.primary" ml={1}>
                    {merchantId}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        <Grid container>
          <Grid item xs={6}>
            <Box pb={2} mt={1}>
              <Typography variant={"h5"} color="text.primary">
                {HeaderSection.TITLE_CREDENTIALS}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box style={{ float: "right" }}>
              <ComponentSecurityWrapper
                componentId={`${ComponentIdEnum.M_CONSOLE_CREDENTIALS}${ComponentIdEnum.CREATE_CREDENTIALS}`}
              >
                {!is_backofficeMasterCustomer && (
                  <Button
                    startIcon={<Plus />}
                    variant="contained"
                    color="primary"
                    onClick={actions.handleOpenModalAddCredential}
                  >
                    {GeneralAction.ADD_CREDENTIAL}
                  </Button>
                )}
              </ComponentSecurityWrapper>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} mt={1}>
            {!is_backofficeMasterCustomer && (
              <Box pb={2} mt={1}>
                <Typography variant={"body2"} color="text.primary">
                  {HeaderSection.SUB_TITLE_CREDENTIALS}
                </Typography>
              </Box>
            )}
            <Box sx={{ width: "400px" }} mt={1}>
              <TextFieldSearchBy
                items={merchantCredentials}
                onChange={(value: any) => {
                  if (isEmpty(value.target?.value)) {
                    reloadCredentials();
                  }
                }}
                onItemSelected={(itemSelected: IItemSelected) => {
                  filterCredential(itemSelected);
                }}
                placeholder="Buscar"
                type="category"
              />
            </Box>
          </Grid>
          <HeaderFilters
            onChangeFilterSidebar={onChangeFilterSidebar}
            isBackofficeMasterCustomer={is_backofficeMasterCustomer}
            path={path}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
