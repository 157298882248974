import { IBreadcrumbProps } from "../../../shared/interfaces/IBreadcrumbProps";
import {
  ISortClick,
  IUserList,
  IUseUsersContainerState,
} from "../../../shared/interfaces/IUseUsersContainerState";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { IMerchantInfo } from "../../../shared/interfaces/IMerchantInfo";
import { clone, cloneDeep, concat, defaultTo, get, isEmpty } from "lodash";
import {
  CreateMerchantBreadcrumb,
  CreateMerchantsListBreadcrumb,
  HomeBreadcrumb,
  UsersBreadcrumb,
} from "../../../shared/constants/BreadcrumbSections";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { getHierarchyNodeInfo } from "../../../store/thunks/general/general.thunk";
import {
  isAdmin,
  isBackofficeMasterCustomer,
  isUserBackofficeUserMaker,
  isUserBackofficeUserMaster,
  isUserBackofficeUserParent,
  isUserRiskManager,
} from "../../../shared/utils/getRolesUtils";
import { EntityEnum } from "../../../shared/enum/EntityEnum";
import {
  deleteUser,
  deleteUserMassive,
  getFirstParent,
  getNodeInfoByMerchantId,
  getUserList,
  getUserListCustomer,
  restorePassword,
  restorePasswordMassive,
} from "../../../store/thunks/users/users.thunk";
import { RootState } from "../../../store/store";
import { IUserListQueryString } from "../../../store/interfaces/users.interfaces";
import {
  IFiltersSearchRequest,
  IUserTableState,
} from "../UsersContainer.interfaces";
import {
  ActionsUserTypeEnum,
  CREDENTIALS_TABLE_COLUMNS_CUSTOMER,
  FIELD_SORT_CATALOGUE,
  FIELD_SORT_CATALOGUE_CUSTOMER,
  RowsPerPageLabel,
} from "../../../shared/enum/UsersTableEnum";
import { sortObjectArray } from "../../../shared/utils/sortUtils";
import { ROUTE } from "../../../shared/constants/redirect_routes";
import { ITableRowProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { Keys, PATTERNS } from "../../../shared/constants/Patterns";
import {
  setActionsModal,
  setRowstable,
} from "../../../store/reducers/users/users.slice";

import { QueryParamEnum } from "../../../shared/enum/QueryParamEnum";
import { getLocalStorageItem } from "../../../shared/utils/localStorageUtils";
import { ItemCategoryProps } from "@kushki/connect-ui/dist/Components/Molecules/Filters/ItemCategory/interfaces";
import { CatalogUserState } from "../../../shared/enum/StatusUserEnum";
import { UserResponse } from "../../../../types/user_response";
import { usersTableRows } from "../../../shared/utils/BuildUsersData";
import { categoryItemsInitial } from "../../../shared/constants/categoryItems";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const useUsersContainer = (): IUseUsersContainerState => {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const query = useQuery();
  const isRoleBackofficeCustomer = isBackofficeMasterCustomer();
  const isConsole3 = defaultTo(query.get(QueryParamEnum.CONSOLE), "") === "3";
  const { merchantInfo: branchesResponse } = useAppSelector(
    (state) => state.generalData
  );

  const {
    rowsTable,
    isLoadingTable,
    userList,
    firstParent,
    actionsModal,
    nodeInfoCustomer,
    isDisabledTable,
  } = useAppSelector((state: RootState) => state.users);

  const [actualRows, setActualRows] = useState<ITableRowProps[]>(rowsTable);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [merchantInfo, setMerchantInfo] = useState<IMerchantInfo>({
    isHideSideBar: "",
    merchantId: "",
    name: "",
  });

  const [searchInput, setSearchInput] = useState<string>("");
  const [usernameError, setUsernameError] = useState<boolean>(false);

  const [usersTableState, setUsersTableState] = useState<IUserTableState>({
    count: 10,
    limit: 10,
    pageLabel: 1,
    pages: 0,
    previousLastEvaluatedKey: [],
    rowsPerPage: 10,
    username: "",
  });
  const [categoryItems, setCategoryItems] = useState<ItemCategoryProps[]>(
    cloneDeep(categoryItemsInitial)
  );
  const canSearchUser = () => {
    return (
      (isAdmin() && query.get("publicMerchantId")) ||
      isAdmin() ||
      isUserRiskManager() ||
      isRoleBackofficeCustomer
    );
  };

  const [breadCrumbSection, setBreadcrumbSection] = useState<
    IBreadcrumbProps[]
  >([]);

  const [sortClick, setSortClick] = useState<ISortClick>({
    field: "",
    numClick: 0,
  });
  const [entityName, setEntityName] = useState<string>("");
  const [canAddUser, setAddUser] = useState<boolean>(true);

  const fetchUsersList = (): void => {
    const initialSearch: IUserListQueryString = {
      limit: usersTableState.limit.toString(),
      username: usersTableState.username,
    };
    const merchantId: string =
      query.get("publicMerchantId") || merchantInfo.merchantId;
    let request: IUserListQueryString = initialSearch;

    if (merchantId) request = { ...request, merchantId, searchSupport: true };
    if (!isEmpty(usersTableState.lastEvaluatedKey))
      request.paginationToken = usersTableState.lastEvaluatedKey;

    dispatch(
      getUserList({
        queryParams: request,
      })
    );
  };

  const fetchNodeInfoMerchant = () => {
    const merchantId = getLocalStorageItem("merchantId");

    dispatch(getNodeInfoByMerchantId(merchantId));
  };

  useEffect(() => {
    const merchantId: string = defaultTo(query.get("publicMerchantId"), "");
    const name: string = defaultTo(query.get("merchantName"), "");
    const isHideSideBar: string = defaultTo(query.get("hideSideBar"), "");

    setMerchantInfo({
      isHideSideBar,
      merchantId,
      name,
    });

    if (isRoleBackofficeCustomer) {
      fetchNodeInfoMerchant();
    }
    if (merchantId) dispatch(getFirstParent(merchantId));
  }, []);

  useEffect(() => {
    if (!isEmpty(get(merchantInfo, "merchantId"))) {
      setBreadcrumbSection([
        CreateMerchantsListBreadcrumb(isConsole3),
        CreateMerchantBreadcrumb(merchantInfo, isConsole3),
      ]);
    } else {
      setBreadcrumbSection([HomeBreadcrumb]);
    }
  }, [merchantInfo]);

  const breadcrumbItems: IBreadcrumbProps[] = [
    ...breadCrumbSection,
    UsersBreadcrumb,
  ];

  const handleSetUrl = (
    merchantInfoId: string | null,
    merchantName?: string | null
  ) => {
    let baseUrl: string = ROUTE.CREATE_USER;
    let queryParam: string = "";

    const hasConsoleQueryParam: boolean =
      defaultTo(query.get(QueryParamEnum.CONSOLE), "") === "3";

    if (hasConsoleQueryParam) {
      queryParam = `${merchantInfoId || merchantName ? "&" : "?"}console=3`;
    }

    return { baseUrl, hasConsoleQueryParam, queryParam };
  };

  const setUrl = (merchantInfo: IMerchantInfo) => {
    const merchantInfoId = merchantInfo.merchantId;
    const { baseUrl, queryParam } = handleSetUrl(merchantInfoId);

    if (merchantInfoId) {
      return `${baseUrl}?publicMerchantId=${merchantInfoId}&merchantName=${merchantInfo.name}&hideSideBar=${merchantInfo.isHideSideBar}${queryParam}`;
    }

    return `${baseUrl}${queryParam}`;
  };

  const handleAddUser = () => {
    const merchant_query = get(merchantInfo, "merchantId", "");
    const merchant_id = isEmpty(merchant_query)
      ? defaultTo(localStorage.getItem("merchantId"), "")
      : merchant_query;

    if (isRoleBackofficeCustomer && !isEmpty(merchant_id)) {
      navigate({
        pathname: "/",
        search: ROUTE.CREATE_MASSIVE_USERS(merchant_id).toString(),
      });
      navigate(0);
    } else {
      navigate(setUrl(merchantInfo));
    }
  };

  const applyLocalFilters = (data: ITableRowProps[]) => {
    let filteredData: ITableRowProps[] = [...data];

    if (!!searchInput) {
      filteredData = filteredData.filter((user) =>
        user.id?.includes(searchInput)
      );
    }

    if (!!usersTableState.role) {
      filteredData = filteredData.filter((user) => {
        const roles: string[] = get(
          user,
          "cells[5].props.child.props.items",
          []
        ).map((roleItem: string) => roleItem);

        return roles.includes(defaultTo(usersTableState.role, ""));
      });
    }

    if (!!usersTableState.merchantId) {
      filteredData = filteredData.filter((user) => {
        const merchantIdRow: string = get(user, "cells[0].props.line1", []);

        return defaultTo(usersTableState.merchantId, "")
          .split(",")
          .includes(merchantIdRow);
      });
    }

    if (!!usersTableState.status) {
      filteredData = filteredData.filter((user) => {
        const userState: string = get(user, "cells[6].props.text", "");

        return userState.includes(defaultTo(usersTableState.status, ""));
      });
    }

    return filteredData;
  };

  const handleLocalPagination = () => {
    const aux_rows_table: ITableRowProps[] = cloneDeep(rowsTable);
    const exist_skeleton: boolean =
      get(rowsTable, "[0].id", "") === "skeleton-row";

    if (exist_skeleton) {
      aux_rows_table.shift();
    }
    const filteredData = applyLocalFilters(aux_rows_table);

    setActualRows(
      exist_skeleton
        ? concat(filteredData, rowsTable[0]).map((i) => ({
            ...i,
            rowProps: { ...i.rowProps, isSelected: true },
          }))
        : filteredData.map((i) => ({
            ...i,
            rowProps: { ...i.rowProps, isSelected: true },
          }))
    );

    return;
  };

  useEffect(() => {
    setAddUser(
      isAdmin() ||
        isUserBackofficeUserMaker() ||
        isUserBackofficeUserMaster() ||
        isUserBackofficeUserParent() ||
        isUserRiskManager() ||
        isRoleBackofficeCustomer
    );
  }, []);

  useEffect(() => {
    const merchantId: string = get(merchantInfo, "merchantId", "");

    if (!isEmpty(merchantId) && isRoleBackofficeCustomer)
      dispatch(getHierarchyNodeInfo({ merchantId }));
  }, [merchantInfo.merchantId]);

  useEffect(() => {
    if (isRoleBackofficeCustomer) {
      handleLocalPagination();

      return;
    }
    setActualRows(rowsTable);
  }, [rowsTable]);

  const getEntityName = (firstParentEntityName: string): string => {
    switch (firstParentEntityName) {
      case EntityEnum.OWNER:
        return EntityEnum.CUSTOMER;
      case EntityEnum.CUSTOMER:
        return EntityEnum.BRANCH;
    }

    return "";
  };

  useEffect(() => {
    firstParent &&
      setEntityName(getEntityName(get(firstParent, "entityName", "")));
  }, [firstParent]);

  const calculatePageLabel = (): number => {
    switch (usersTableState.limit) {
      case RowsPerPageLabel.TWENTY_LIMIT:
        return 19;
      case RowsPerPageLabel.FIFTY_LABEL:
        return 49;
      default:
        return 9;
    }
  };
  const previousPage = (): void => {
    let page: number = usersTableState.pages - 2;
    let previous_last_evaluated_keys: string[] =
      usersTableState.previousLastEvaluatedKey;

    previous_last_evaluated_keys.splice(page + 2);
    setUsersTableState({
      ...usersTableState,
      count: usersTableState.count - usersTableState.limit,
      lastEvaluatedKey:
        page < 0 ? undefined : usersTableState.previousLastEvaluatedKey[page],
      pageLabel:
        usersTableState.count - usersTableState.limit - calculatePageLabel(),
      pages: usersTableState.pages === 1 ? 0 : page + 1,

      previousLastEvaluatedKey: previous_last_evaluated_keys,
    });
    setSortClick({ field: "", numClick: 0 });
    setSelectedRows([]);
    setCategoryItems(cloneDeep(categoryItemsInitial));
  };

  const nextPage = (): void => {
    const previous_last_evaluated_keys: string[] =
      usersTableState.previousLastEvaluatedKey;

    const paginationToken = get(userList, "PaginationToken", "");

    if (!isEmpty(paginationToken)) {
      previous_last_evaluated_keys.push(paginationToken);
    }
    setUsersTableState({
      ...usersTableState,
      count: usersTableState.count + usersTableState.limit,
      lastEvaluatedKey: paginationToken,
      pageLabel:
        usersTableState.count + usersTableState.limit - calculatePageLabel(),
      pages: usersTableState.pages + 1,
      previousLastEvaluatedKey: previous_last_evaluated_keys,
    });
    setSortClick({ field: "", numClick: 0 });
    setSelectedRows([]);
    setCategoryItems(cloneDeep(categoryItemsInitial));
  };

  const changeRowsPerPage = (e: ChangeEvent<HTMLInputElement>) => {
    setUsersTableState({
      ...usersTableState,
      count: parseInt(e.target.value, 10),
      lastEvaluatedKey: undefined,
      limit: parseInt(e.target.value, 10),
      pageLabel: 1,
      pages: 0,
      previousLastEvaluatedKey: [],
    });
    setSortClick({ field: "", numClick: 0 });
    setCategoryItems(cloneDeep(categoryItemsInitial));
  };

  const handleCountClick = (field: string) => {
    const sortField: string = get(sortClick, "field", "");
    const numClick: number = get(sortClick, "numClick", 0);
    const fieldPath: string = isRoleBackofficeCustomer
      ? FIELD_SORT_CATALOGUE_CUSTOMER[field]
      : FIELD_SORT_CATALOGUE[field];

    if (sortField !== field && sortField !== "") {
      setSortClick({ field, numClick: 1 });
      dispatch(
        setRowstable(sortObjectArray(clone(rowsTable), fieldPath, "asc"))
      );

      return;
    }

    if (numClick >= 2) {
      setSortClick({ field, numClick: 0 });
      dispatch(
        setRowstable(sortObjectArray(clone(rowsTable), fieldPath, "asc"))
      );
    } else {
      setSortClick({
        field,
        numClick: numClick + 1,
      });
      if (numClick === 0) {
        dispatch(
          setRowstable(sortObjectArray(clone(rowsTable), fieldPath, "asc"))
        );
      }
      if (numClick === 1) {
        dispatch(
          setRowstable(sortObjectArray(clone(rowsTable), fieldPath, "desc"))
        );
      }
    }
  };

  const onSelectedRows = (selected: string[]) => {
    setSelectedRows(selected);
  };

  const handleReturn = () => {
    window.location.href = ROUTE.MERCHANT_RESUME(
      defaultTo(query.get("publicMerchantId"), "")
    );
  };

  const handleCloseModal = () => {
    dispatch(
      setActionsModal({
        cancelButton: "",
        confirmButton: "",
        description: "",
        openModal: false,
        title: "",
        type: ActionsUserTypeEnum.EMPTY,
        userSelected: {} as IUserList,
      })
    );
  };

  const handleRestorePassword = () => {
    dispatch(restorePassword(get(actionsModal, "userSelected.userName", "")));
  };

  const handleDeleteAction = () => {
    dispatch(deleteUser(get(actionsModal, "userSelected.userName", "")));
  };

  const handleDeleteMassiveAction = () => {
    dispatch(deleteUserMassive(selectedRows));
  };

  const handleRestorePasswordMassive = () => {
    dispatch(restorePasswordMassive(selectedRows));
  };

  const handleConfirmAction = () => {
    switch (actionsModal.type) {
      case ActionsUserTypeEnum.PASSWORD:
        handleRestorePassword();
        break;
      case ActionsUserTypeEnum.DELETE:
        handleDeleteAction();
        break;
      case ActionsUserTypeEnum.MASSIVE_DELETE:
        handleDeleteMassiveAction();
        break;
      case ActionsUserTypeEnum.MASSIVE_PASSWORD:
        handleRestorePasswordMassive();
        break;
    }

    handleCloseModal();
  };

  const handleLocalSearch = (input: string) => {
    if (!input || isEmpty(input)) {
      setActualRows(clone(rowsTable));

      return;
    }

    const filteredUsers = rowsTable.filter((user) => user.id?.includes(input));

    setActualRows(clone(filteredUsers));
  };

  const searchRef = useRef(true);

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current = false;

      return;
    }
    if (!isEmpty(searchInput)) {
      let reg_expression: RegExp = new RegExp(PATTERNS.alfa1To40WithoutSpace);
      const error: boolean = !reg_expression.test(searchInput);

      setUsernameError(error);

      if (error) return;
    }
    if (!isRoleBackofficeCustomer) {
      setUsersTableState({
        ...usersTableState,
        lastEvaluatedKey: undefined,
        pageLabel: 1,
        pages: 0,
        previousLastEvaluatedKey: [],
        username: searchInput,
      });
    } else {
      handleLocalPagination();
    }
    setSortClick({ field: "", numClick: 0 });
  }, [searchInput]);

  const handleSearchInput = (event: Object): void => {
    const merchantId: string = defaultTo(query.get("publicMerchantId"), "");
    const input: string = get(event, "target.value", "");

    if (isAdmin() && !isEmpty(merchantId)) {
      handleLocalSearch(input);

      return;
    }

    setUsernameError(false);
    if (get(event, "key", "") !== Keys.ENTER) return;

    setSearchInput(input);
    setCategoryItems(cloneDeep(categoryItemsInitial));
  };

  const onChangeFilterSidebar = (request: Partial<IFiltersSearchRequest>) => {
    if (
      get(request, "merchantId", "") !== usersTableState.merchantId ||
      (get(request, "merchantId", "") === usersTableState.merchantId &&
        request.role !== usersTableState.role) ||
      (get(request, "merchantId", "") === usersTableState.merchantId &&
        request.status !== usersTableState.status)
    ) {
      setUsersTableState({
        ...usersTableState,
        lastEvaluatedKey: undefined,
        merchantId: get(request, "merchantId", ""),
        pageLabel: 1,
        pages: 0,
        previousLastEvaluatedKey: [],
        role: request.role,
        status: request.status,
      });
    }
  };

  useEffect(() => {
    if (!isRoleBackofficeCustomer) {
      fetchUsersList();
    } else {
      if (nodeInfoCustomer.path) {
        const request: IUserListQueryString = {
          limit: usersTableState.limit.toString(),
          merchantId: usersTableState.merchantId,
          path: nodeInfoCustomer.path,
          username: usersTableState.username,
        };

        dispatch(
          getUserListCustomer({
            queryParams: request,
          })
        );
      }
    }
  }, [usersTableState]);

  const handleApplyFilter = (selected: ItemCategoryProps[]) => {
    if (isEmpty(selected[0].items)) {
      const rows: ITableRowProps[] = usersTableRows(userList.Users);

      dispatch(setRowstable(rows));
    } else {
      const usersFiltered: UserResponse[] = userList.Users.filter(
        (user) =>
          user.Enabled === CatalogUserState[get(selected[0], "items[0].value")]
      );

      const rows: ITableRowProps[] = usersTableRows(usersFiltered);

      dispatch(setRowstable(rows));
    }
  };

  return {
    actionsModal,
    branchesResponse,
    breadcrumbItems,
    canAddUser,
    canSearchUser,
    categoryItems,
    entityName,
    handleAddUser,
    handleApplyFilter,
    handlers: {
      handleCloseModal,
      handleConfirmAction,
      handleReturn,
      onSelectedRows,
    },
    handleSearchInput,
    header: CREDENTIALS_TABLE_COLUMNS_CUSTOMER(
      handleCountClick,
      { field: sortClick.field },
      sortClick.numClick
    ),
    isDisabledTable,
    isLoadingTable,
    massiveActions: {
      showMassiveActions: isRoleBackofficeCustomer && !isEmpty(selectedRows),
      totalSelected: selectedRows.length,
    },
    onChangeFilterSidebar,
    pagination: {
      changeRowsPerPage,
      count: usersTableState.count,
      disableNext: isEmpty(userList.PaginationToken) || isLoadingTable,
      disablePrevious:
        isEmpty(usersTableState.previousLastEvaluatedKey) || isLoadingTable,
      hidePagination: isRoleBackofficeCustomer
        ? true
        : isEmpty(userList.PaginationToken) &&
          isEmpty(usersTableState.previousLastEvaluatedKey),
      nextPage,
      page: usersTableState.pageLabel,
      previousPage,
      rowsPerPage: usersTableState.limit,
    },
    path: get(nodeInfoCustomer, "path", ""),
    rowsTable: actualRows,
    usernameError,
  };
};
