import {
  PersonTypeInterface,
  PersonTypeObjectInterface,
} from "../BasicStateGeneral/FieldsBasicData";
import { get, isEmpty } from "lodash";
import { findDocTypeCountry } from "../../utils/findDocTypeCountry_utils";
import {
  BasicDataLabels,
  ErrorMessageMX,
  RegularExp,
  TypeElement,
} from "../BasicStateMX/ErrorMessageMX";
import { FieldsNamesRender } from "../FieldsEnumRenderEnum";
import { ErrorMessageSV } from "./ErrorMessageSV";
import { PersonType_SV_Enum } from "../BasicStateGeneral/PersonType";
import { ErrorMessageGeneric } from "../BasicStateGeneral/ErrorMessage";
import { WEB_SITE_REGEX } from "../../constants/billing_form_constants";

export const PersonTypeSVList: Array<PersonTypeObjectInterface> = [
  { name: "Física", value: "01" },
  { name: "Moral", value: "02" },
];

export const docTypeSV: PersonTypeObjectInterface[] = [
  { name: "DUI", value: "0" },
  { default: true, name: "NIT", value: "1" },
];

const labelRender = isEmpty(findDocTypeCountry(docTypeSV))
  ? "NIT"
  : get(findDocTypeCountry(docTypeSV), "name");

export const fieldsRenderSV: Array<PersonTypeInterface> = [
  {
    filter: PersonType_SV_Enum.MORAL,
    label: BasicDataLabels.RAZON_SOCIAL,
    name: FieldsNamesRender.social_reason,
    regExp: RegularExp.CAPITAL_LETTER_NUMBERS,
    rules: {
      maxLength: {
        message: ErrorMessageGeneric.MAX150,
        value: 150,
      },
      minLength: {
        message: ErrorMessageMX.MAX_1_50_CHAR,
        value: 1,
      },
      required: {
        message: ErrorMessageMX.PF_RAZON_SOCIAL,
        value: true,
      },
    },
    type: TypeElement.TEXT_CAPITAL,
    value: 6,
  },
  {
    filter: PersonType_SV_Enum.MORAL,
    label: labelRender,
    name: FieldsNamesRender.rfc,
    regExp: RegularExp.ONLY_NUMBERS,
    rules: {
      maxLength: {
        message: ErrorMessageSV.LIMIT_NIT,
        value: 13,
      },
      minLength: {
        message: ErrorMessageSV.LIMIT_NIT,
        value: 7,
      },
      required: {
        message: ErrorMessageSV.PF_NUM_NIT,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 6,
  },
  {
    filter: PersonType_SV_Enum.MORAL,
    label: BasicDataLabels.TIPO_EMPRESA,
    name: FieldsNamesRender.type_company,
    rules: {
      required: {
        message: ErrorMessageMX.PF_COMPANY,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    value: 6,
  },
  {
    filter: PersonType_SV_Enum.MORAL,
    label: BasicDataLabels.TIPO_INDUSTRIA,
    name: FieldsNamesRender.type_industry,
    rules: {
      required: {
        message: ErrorMessageMX.PF_INDUSTRIA,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    value: 6,
  },
  {
    filter: PersonType_SV_Enum.MORAL,
    label: BasicDataLabels.MCC,
    name: FieldsNamesRender.mcc,
    rules: {
      required: {
        message: ErrorMessageMX.PF_MCC,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    typeArray: TypeElement.COMBO_ARRAY,
    value: 6,
  },
  {
    filter: PersonType_SV_Enum.MORAL,
    label: BasicDataLabels.FECHA_CONST,
    name: FieldsNamesRender.date,
    type: TypeElement.DATE_PICKER,
    value: 6,
  },
  {
    filter: PersonType_SV_Enum.MORAL,
    label: BasicDataLabels.WEB,
    name: FieldsNamesRender.web,
    regExp: RegularExp.WEB,
    rules: {
      pattern: {
        message: ErrorMessageMX.REG_EXP_WEB,
        value: WEB_SITE_REGEX,
      },
      required: {
        message: ErrorMessageMX.PF_WEB,
        value: true,
      },
    },
    type: TypeElement.WEB,
    value: 6,
  },

  {
    filter: PersonType_SV_Enum.FISICA,
    label: BasicDataLabels.NOMBRE,
    name: FieldsNamesRender.names,
    regExp: RegularExp.ONLY_LETTERS,
    rules: {
      maxLength: {
        message: ErrorMessageMX.MAX_1_50_CHAR,
        value: 50,
      },
      minLength: {
        message: ErrorMessageMX.MAX_1_50_CHAR,
        value: 1,
      },
      pattern: {
        message: ErrorMessageMX.LETRAS,
        value: new RegExp(RegularExp.ONLY_LETTERS),
      },
      required: {
        message: ErrorMessageMX.PF_NOMBRE,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 6,
  },
  {
    filter: PersonType_SV_Enum.FISICA,
    label: BasicDataLabels.APELLIDO,
    name: FieldsNamesRender.surname,
    regExp: RegularExp.ONLY_LETTERS,
    rules: {
      maxLength: {
        message: ErrorMessageMX.MAX_1_50_CHAR,
        value: 50,
      },
      minLength: {
        message: ErrorMessageMX.MAX_1_50_CHAR,
        value: 1,
      },
      pattern: {
        message: ErrorMessageMX.LETRAS,
        value: new RegExp(RegularExp.ONLY_LETTERS),
      },
      required: {
        message: ErrorMessageMX.PF_APELLIDO,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 6,
  },
  {
    filter: PersonType_SV_Enum.FISICA,
    label: labelRender,
    name: FieldsNamesRender.rfc,
    regExp: RegularExp.ONLY_NUMBERS,
    rules: {
      maxLength: {
        message: ErrorMessageSV.LIMIT_NIT,
        value: 13,
      },
      minLength: {
        message: ErrorMessageSV.LIMIT_NIT,
        value: 7,
      },
      required: {
        message: ErrorMessageSV.PF_NUM_NIT,
        value: true,
      },
    },
    type: TypeElement.TEXT,
    value: 12,
  },
  {
    filter: PersonType_SV_Enum.FISICA,
    label: BasicDataLabels.TIPO_INDUSTRIA,
    name: FieldsNamesRender.type_industry,
    rules: {
      required: {
        message: ErrorMessageMX.PF_INDUSTRIA,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    value: 12,
  },
  {
    filter: PersonType_SV_Enum.FISICA,
    label: BasicDataLabels.MCC,
    name: FieldsNamesRender.mcc,
    rules: {
      required: {
        message: ErrorMessageMX.PF_MCC,
        value: true,
      },
    },
    type: TypeElement.COMBO,
    typeArray: TypeElement.COMBO_ARRAY,
    value: 6,
  },
  {
    filter: PersonType_SV_Enum.FISICA,
    label: BasicDataLabels.FECHA_CONST,
    name: FieldsNamesRender.date,
    type: TypeElement.DATE_PICKER,
    value: 6,
  },
  {
    filter: PersonType_SV_Enum.FISICA,
    label: BasicDataLabels.WEB,
    name: FieldsNamesRender.web,
    regExp: RegularExp.WEB,
    rules: {
      pattern: {
        message: ErrorMessageMX.REG_EXP_WEB,
        value: WEB_SITE_REGEX,
      },
      required: {
        message: ErrorMessageMX.PF_WEB,
        value: true,
      },
    },
    type: TypeElement.WEB,
    value: 6,
  },
];
