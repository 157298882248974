import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const notificationDetailStyles = createNamedStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    p: (theme) => theme.spacing(3.55, 29.5, 12.5, 8),
    rowGap: (theme) => theme.spacing(5),
  },
  header: {
    ".MuiTypography-h2": {
      paddingTop: "8px",
    },
    ".MuiBreadcrumbs-root": {
      paddingTop: "24px",
    },
    ".MuiTypography-overline": {},
  },
  cardContent: {
    padding: "28px 48px",
    ".MuiGrid-item": {
      paddingTop: "4px",
      paddingBottom: "4px",
    },
  },
  codeBlock: {
    display: "inline-grid",
    ".MuiTypography-root": {
      paddingBottom: "8px",
    },
  },
});
