import React, { FC } from "react";
import { mainSubHeaderStyles as styles } from "./Subheader.styles";
import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { getCountryFlag } from "../../shared/constants/flags/countryFlag";
import { ISubheaderProps } from "./Subheader.interfaces";
import { IconEdit } from "@kushki/connect-ui";
import { SessionStoragePaths } from "../../shared/constants/Session_storage_paths";
import { set } from "lodash";
import { clipboard } from "../../shared/clipboard";
import { Copy } from "react-feather";
import { SubHeaderMerchantSkeleton } from "../Skeletons/SubHeaderMerchantSkeleton/SubHeaderMerchantSkeleton";

export const Subheader: FC<ISubheaderProps> = ({
  title,
  country,
  redirectPath,
  id,
}: ISubheaderProps) => {
  const location = useLocation();

  const saveLastRoute = () => {
    const json = JSON.parse(
      sessionStorage.getItem(SessionStoragePaths.BASIC_MERCHANT_INFORMATION) ||
        "{}"
    );

    set(
      json,
      "layout.lastRoute",
      `/financial-configuration${location.pathname}`
    );
    sessionStorage.setItem("basicMerchantInformation", JSON.stringify(json));
  };

  return (
    <Box>
      {title ? (
        <>
          <Grid container alignItems="center" sx={styles.gridTitle}>
            <Grid item>
              <Typography color="text.primary" variant="h3">
                {title}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => {
                  saveLastRoute();
                  window.location.href = redirectPath;
                }}
              >
                <IconEdit color="primary" fontSize="medium" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item container xs={12} md={"auto"} alignItems="center">
              <Grid item sx={styles.gridText}>
                <Typography variant="caption" color="text.primary">
                  País de operación:{" "}
                </Typography>
              </Grid>
              <Grid item>
                <Box>{getCountryFlag[country]}</Box>
              </Grid>
              <Grid item sx={styles.gridCountry}>
                <Typography variant="body1" color="text.primary">
                  {country}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={"auto"} alignItems="center">
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={styles.divider}
              />
              <Grid item sx={styles.gridId}>
                <Typography variant="caption" color="text.primary">
                  ID:{" "}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" color="text.primary">
                  {id}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  sx={styles.iconEffect}
                  data-testid={"IconCopy"}
                  onClick={(e) => {
                    clipboard(id);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <Copy />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <SubHeaderMerchantSkeleton />
      )}
    </Box>
  );
};

export default Subheader;
