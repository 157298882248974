export const ItemsPerPage = [10, 25, 50, 75, 100];

export enum ConditionsEnum {
  transaction_bankId = "bankId",
  transaction_totalAmount = "totalAmount",
  transaction_accountType = "accountType",
  transaction_documentType = "documentType",
  transaction_enroll = "enroll",
}

export enum CategoryEnum {
  bankId = "Institución Financiera",
  totalAmount = "Monto",
  accountType = "Tipo de cuenta",
  documentType = "Tipo de documento",
  enroll = "Enrolamiento",
}

export enum DateFormatEnum {
  yyyy_mm_dd_hh_mm = "yyyy-MM-dd HH:mm",
}

export const CATEGORY_BY_CONDITIONS: Record<string, string> = {
  [ConditionsEnum.transaction_bankId]: CategoryEnum.bankId,
  [ConditionsEnum.transaction_accountType]: CategoryEnum.accountType,
  [ConditionsEnum.transaction_documentType]: CategoryEnum.documentType,
  [ConditionsEnum.transaction_enroll]: CategoryEnum.enroll,
  [ConditionsEnum.transaction_totalAmount]: CategoryEnum.totalAmount,
};
