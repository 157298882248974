import { useDispatch, useSelector } from "react-redux";
import { parseToEnUSDate } from "../../shared/utils/date_utils";
import downloadTransactions from "../../store/thunks/transactions/downloadTransactions/download_transactions";
import {
  AdminConciliationDownloadRequestWS,
  FormatDownloadRequest,
} from "../../store/thunks/transactions/downloadTransactions/download_transactions.interfaces";
import { IUseDownloadTransactions } from "./useDownloadTransactions.interfaces";
import { RootState } from "../../store/store.interfaces";
import { useEffect, useState } from "react";
import { MessageAlertEnum } from "../../shared/enums/message_alert_enum";
import { AlertColor } from "@mui/material/Alert/Alert";
import { setLoadingDownload } from "../../store/actions/app_actions";
import { get, isEmpty, set } from "lodash";

const useDownloadTransactions = (
  source: "pending" | "historic"
): IUseDownloadTransactions => {
  const app = useSelector((state: RootState) => state.app);
  const filters = useSelector((state: RootState) => state.filters);
  const dispatch = useDispatch();
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [position, setPosition] = useState<"top" | "bottom">("top");
  const [message, setMessage] = useState<string>("test");
  const [type, setType] = useState<AlertColor>("info");

  const handleDownloadTransactions = (downloadType: string) => {
    dispatch(setLoadingDownload(true));
    const request: AdminConciliationDownloadRequestWS = {
      action: "downloadTrxConciliation",
      data: {
        country: filters.headerFilter.country,
        filter: {
          ...filters.general,
        },
        format: downloadType.toLowerCase() as FormatDownloadRequest,
        from: parseToEnUSDate(filters.date.from),
        limit: isEmpty(filters.general) ? 100 : filters.pagination.limit,
        offset: filters.pagination.page * filters.pagination.limit,
        paymentMethod: filters.headerFilter.paymentMethod,
        processorName: filters.headerFilter.processorName,
        sort: {
          field: "created",
          order: "desc",
        },
        source: source,
        to: parseToEnUSDate(filters.date.to),
      },
    };

    if (get(app, "rowsSelected.length", 0) > 0) {
      set(request, "data.filter.rows", app.rowsSelected);
    }
    dispatch(
      downloadTransactions({
        data: request,
      })
    );
  };
  const handleCloseSnackBar = () => setOpenSnackBar(false);
  const handleOpenSuccessSnackBar = () => {
    setMessage(MessageAlertEnum.DOWNLOAD_SUCCESS);
    setPosition("bottom");
    setType("success");
    setOpenSnackBar(true);
  };
  const handleOpenErrorSnackBar = () => {
    setMessage(MessageAlertEnum.DOWNLOAD_ERROR);
    setPosition("top");
    setType("error");
    setOpenSnackBar(true);
  };
  const handleOpenSnackBar = () => {
    app.downloadFile === "SUCCESS"
      ? handleOpenSuccessSnackBar()
      : handleOpenErrorSnackBar();
  };

  useEffect(() => {
    if (app.downloadFile !== "") handleOpenSnackBar();
  }, [app.downloadFile]);

  return {
    actions: {
      handleDownloadTransactions,
    },
    handleCloseSnackBar,
    message,
    openSnackBar,
    position,
    type,
  };
};

export default useDownloadTransactions;
