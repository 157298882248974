export enum ROUTES {
  INDEX = "/",
  OWNER = "/owner",
  CUSTOMER = "/customer",
  BRANCH = "/branch",
  BRANCH_CREATION_OPTIONS = "options",
  BRANCH_BATCH = "batch",
  BRANCH_UPLOAD_FILE = "upload-file",
  BRANCH_EDIT = "edit",
  BRANCH_SELECT_EDITION = "selection",
  RESUME = "/resume",
}

export const SPA_BASENAME = "create-node";
