export const ROUTES = {
  CLIENTS: "/clients",
  CREATE_NODE: "/create-node/customer",
  CREATE_USER: "/users/user/",
  EDIT_USER: "/users/user/:userName",
  FORM: "/form",
  MERCHANT_RESUME: "/merchant-resume",
  RESUME: (publicMerchantIdUrl: string) =>
    `/merchant-resume?publicMerchantId=${publicMerchantIdUrl}&mode=edition`,
  SPA_BASENAME: "console-user",
  USERS: "/users",
  USERS_CONSOLE: "/console-user/users",
};
