/* istanbul ignore file */
import React from "react";
import { applyMiddleware, compose, createStore, Store } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { reducer } from "./store/reducer";
import { environment } from "./environments/environment";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import Navigation from "./navigation.component";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
// @ts-ignore
import Normalize from "react-normalize";
import { resumeStorageMiddleware } from "./store/middleware/resume-storage-middleware";
import { SecurityWrapperIdEnum } from "./shared/infrastructure/catalogs/SecurityWrapperIdEnum";

const composeEnhancers = environment.devTools
  ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose;

export const store: Store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunk, resumeStorageMiddleware))
);

const Root = () => (
  <React.StrictMode>
    <Normalize />
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ModuleSecurityWrapper
          basePath={environment.kushkiUrl}
          componentId={SecurityWrapperIdEnum.M_MERCHANTS}
        >
          <Navigation />
        </ModuleSecurityWrapper>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

export default Root;
