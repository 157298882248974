import {
  HeaderCellProps,
  ITableRowProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { IGetMerchantCardInfoResponse } from "../../../../types/get_merchant_card_info_response";
import { DeferredModalType } from "../../DeferredListModal/DeferredListModal";

export const enum ORIGIN {
  MERCHANT_RESUME = "merchantResume",
}

export interface IDeferred {
  handleEdit: (redirectToCreate?: boolean) => void;
  merchantInfo: IMerchantInfo | null;
  merchantCards: IGetMerchantCardInfoResponse | undefined;
  hasCardProcessor: boolean;
  table: {
    columns: HeaderCellProps[];
    rows: ITableRowProps[];
  };
  deferredOptions: boolean;
  showConfigDeferred: boolean;
  modal: {
    type: DeferredModalType | null;
    data: string[];
    close: () => void;
  };
  isCentralized: boolean;
  getEntityName: string;
}

export interface IDeferredItem {
  merchantMonths: string[];
  bank: string[];
  merchantStatus: string;
  months: string[];
  monthsOfGrace: string[];
  deferredType: string[];
  processorName: string;
  variants: string[];
  id: string;
  merchantMonthsOfGrace: string[];
  entity?: string;
  startDate?: number;
  endDate?: number;
}

export interface IMonths {
  value: string;
  active: boolean;
}

export interface IDeferredList {
  deferredOptions: IDeferredItem[] | null;
}

export interface IDeferredConfig {
  id: string;
  name: string;
  code: string;
  processorName: string;
  configType: string;
  status: string;
  country: string;
  graceMonths: boolean;
  created: number;
  updatedAt: number;
}

export interface IMerchantInfo {
  country: string;
  name?: string;
  nodeId?: string;
  origin?: string;
  publicMerchantId?: string;
  entityName?: string;
  isCentralized?: boolean;
}
