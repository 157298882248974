import { IStyles } from "../../shared/interfaces/Styles.interfaces";

export const ProcessRefundsButtonStyles: IStyles = {
  button: {
    backgroundColor: "#023365",
    border: "none",
    borderRadius: "4px",
    color: "white",
    "&:hover": {
      color: "white",
      backgroundColor: "#023365",
      border: "none",
    },
  },
  container: {
    mr: "46px",
    textAlign: "end",
  },
  text: { fontWeight: 500, typography: "caption" },
};
