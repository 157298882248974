import { GetBusinessRulesResponse } from "../../../../types/get_business_rules_response";
import { LocalStoragePathEnum } from "../../infrastructure/enums/LocalStoragePathEnum";
import { defaultTo, isEmpty } from "lodash";
import { GetPayoutsBusinessRulesResponse } from "../../../../types/get_payouts_business_rules_response";

const defaultBusinessRules: GetBusinessRulesResponse = {
  items: [],
};

export const getBusinessRulesStorage = <T>(
  localStoragePath: LocalStoragePathEnum,
  defaultRules: T
): T => {
  const businessRules = window.localStorage.getItem(localStoragePath);

  if (isEmpty(businessRules)) return defaultRules;
  const actualValues = JSON.parse(
    defaultTo(businessRules, JSON.stringify(defaultRules))
  );

  return defaultTo(actualValues, defaultRules);
};

export const setTimeoutPromise = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getBusinessRulesBatch = () => {
  return new Promise<GetBusinessRulesResponse>((resolve) => {
    resolve(
      getBusinessRulesStorage<GetBusinessRulesResponse>(
        LocalStoragePathEnum.BUSINESS_RULES_BATCH,
        defaultBusinessRules
      )
    );
  });
};

export const getPayoutsBusinessRulesBatch = () => {
  return new Promise<GetPayoutsBusinessRulesResponse>((resolve) => {
    resolve(
      getBusinessRulesStorage<GetPayoutsBusinessRulesResponse>(
        LocalStoragePathEnum.PAYOUTS_BUSINESS_RULES_BATCH,
        {
          data: [],
          total: 0,
        }
      )
    );
  });
};

export const setIsMassiveRules = (isBatch?: boolean) => {
  if (isBatch) {
    window.localStorage.setItem(
      LocalStoragePathEnum.IS_MASSIVE_RULES,
      isBatch.toString()
    );
    return;
  }
  window.localStorage.removeItem(LocalStoragePathEnum.IS_MASSIVE_RULES);
};

export const setBusinessRulesBatch = <T>(
  businessRulesBatch: T,
  storagePath: LocalStoragePathEnum
) => {
  window.localStorage.setItem(storagePath, JSON.stringify(businessRulesBatch));
};
