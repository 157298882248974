import { get } from "lodash";
import { LayoutSavedState } from "../interfaces/nodes/layout.interfaces";

export const getSessionStorageValueByPath = (path: string) =>
  JSON.parse(get(sessionStorage, path, "{}"));

export const setSessionStorageValues = (
  newValues: Partial<LayoutSavedState>,
  path: string
) => {
  const currentValues = getSessionStorageValueByPath(path);

  const layoutUpdateValues = {
    ...currentValues,
    ...newValues,
  };

  sessionStorage.setItem(path, JSON.stringify(layoutUpdateValues));
};
