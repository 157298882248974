import {
  IDataTableComponent,
  IUseDataTableComponent,
} from "../../../shared/infrastructure/interfaces/IDataTableComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  ICellRow,
  IconBlock,
  IconCopy,
  IconEdit,
  IconPower,
  IconSync,
  IconTrash,
  useSnackbar,
} from "@kushki/connect-ui";
import { IAppState } from "../../../shared/infrastructure/interfaces/IAppState";
import React, { useEffect, useState } from "react";
import {
  ITableCellProps,
  ITableRowProps,
  HeaderCellProps,
} from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { get, isEmpty, cloneDeep } from "lodash";
import { ErrorOutline } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import {
  ColorsEnum,
  OptionListTypeEnum,
  RowColorsEnum,
  SnackBarVariantsEnum,
  StylesEnum,
} from "../../../shared/infrastructure/enums/StylesEnum";
import { ItemGetCredentialsResponse } from "../../../../types/item_get_credentials_response";
import { TableBodyCellEnum } from "../DataTableComponent";
import {
  CREDENTIALS_SORT_CATALOG,
  CREDENTIALS_TABLE_COLUMNS,
  CREDENTIALS_TABLE_COLUMNS_CUSTOMER,
  CREDENTIALS_TABLE_CUSTOMER,
  LabelEnum,
  TagLabelsEnum,
} from "../../../shared/infrastructure/enums/LabelEnum";
import { clipboard } from "../../../shared/utils/clipboard";
import {
  deleteCredential,
  regenerateCredential,
  setIsLoading,
  setIsOpenCredentialModal,
  setIsOpenCustomModal,
  setOpenAlert,
  updateCredential,
} from "../../../store/actionCreators";
import { IOptionListItemProps } from "@kushki/connect-ui/dist/Components/Molecules/OptionList/OptionList.interfaces";
import { useParams } from "react-router-dom";
import { IParams } from "../../../shared/infrastructure/interfaces/IParams";
import { getRolesLocalStorage } from "../../../shared/utils/local-storage/basic-merchant-information";
import { RolesEnum } from "../../../shared/constants/RolesEnum";
import { ISortClick } from "../../../shared/infrastructure/interfaces/ISortClick";
import { sortObjectArray } from "../../../shared/utils/sortUtils";
import { getBranchName } from "../../../shared/utils/getBranchName";
import { EntityNameEnum } from "../../../shared/infrastructure/enums/EntityNameEnum";

export const useDataTableComponentState = (
  props: IDataTableComponent
): IUseDataTableComponent => {
  const { showSnackbar } = useSnackbar();
  const { merchantId } = useParams<IParams>();
  const dispatch = useDispatch();
  const isLoading: boolean | undefined = useSelector(
    (state: IAppState) => state.isLoading
  );

  const isLoadingCredentials: boolean | undefined = useSelector(
    (state: IAppState) => state.isLoadingCredentials
  );

  const branchesData = useSelector((state: IAppState) => state.branchesData);

  const [rows, setRows] = useState<ITableRowProps[]>([]);
  const [header, setHeader] = useState<HeaderCellProps[]>([]);

  const [sortClick, setSortClick] = useState<ISortClick>({
    numClick: 0,
    field: "",
  });

  const [alertSuccessMsg, setAlertSuccessMsg] = useState<string>("");
  const [alertErrorMsg, setAlertErrorMsg] = useState<string>("");

  const [openAlert, isError] = useSelector((state: IAppState) => {
    return [
      get(state, "openAlert.open", false),
      get(state, "openAlert.isError", false),
    ];
  });

  const roles = getRolesLocalStorage();
  const isBackofficeMasterCustomer: boolean =
    roles[RolesEnum.BackofficeMasterCustomer];

  useEffect(() => {
    if (openAlert) {
      showSnackbar({
        message: isError ? alertErrorMsg : alertSuccessMsg,
        variant: SnackBarVariantsEnum.SIMPLE,
        color: isError ? ColorsEnum.DANGER : ColorsEnum.INFO,
        icon: isError ? (
          <ErrorOutline
            fontSize={StylesEnum.INHERIT}
            color={StylesEnum.INHERIT}
          />
        ) : (
          <CheckIcon fontSize={StylesEnum.INHERIT} color={StylesEnum.PRIMARY} />
        ),
        withIcon: true,
      });
      dispatch(setOpenAlert({ open: false, isError: false }));
    }
  }, [openAlert]);

  function buildCells(
    item: ItemGetCredentialsResponse,
    credentialsLength: number,
    type: string
  ): ITableCellProps[] {
    const cells: ITableCellProps[] = [];

    if (type === EntityNameEnum.BRANCH) {
      if (isBackofficeMasterCustomer) {
        const branchName: string = getBranchName(
          item,
          get(branchesData, "data", [])
        );
        cells.push(
          buildTextActionCell(
            branchName,
            item._source.merchantId,
            `${branchName}\n${item._source.merchantId}`
          )
        );
        cells.push(
          buildTextActionCell(item._source.alias, "", `${item._source.alias}`)
        );
      }

      cells.push(
        buildTextActionCell(
          isBackofficeMasterCustomer ? "" : item._source.alias,
          item._source.credentialId,
          isBackofficeMasterCustomer
            ? `${item._source.credentialId}`
            : `${item._source.alias}\n${item._source.credentialId}`
        )
      );
      cells.push(
        buildTextActionCell(
          "",
          item._source.publicCredentialId,
          item._source.publicCredentialId
        )
      );
      cells.push(
        buildTextActionCell(
          "",
          item._source.privateCredentialId,
          item._source.privateCredentialId
        )
      );
      cells.push({
        type: TableBodyCellEnum.TAG,
        props: {
          text: item._source.enable
            ? TagLabelsEnum.ENABLED
            : TagLabelsEnum.DISABLED,
          cellProps: commonCellProps,
          color: item._source.enable ? ColorsEnum.SUCCESS : ColorsEnum.WARNING,
        },
      });
      if (!roles[RolesEnum.BackofficeMasterCustomer]) {
        cells.push({
          type: TableBodyCellEnum.OPTIONS,
          props: {
            isDisabled: false,
            cellProps: commonCellProps,
            optionListItems: buildRowOptionListItems(credentialsLength, item),
          },
        });
      }
    }

    if (type === EntityNameEnum.CUSTOMER) {
      cells.push(
        buildTextActionCell(
          item._source.alias,
          item._source.credentialId,
          item._source.credentialId
        )
      );
      cells.push(
        buildTextActionCell(
          "",
          item._source.publicCredentialId,
          item._source.publicCredentialId
        )
      );
      cells.push(
        buildTextActionCell(
          "",
          item._source.privateCredentialId,
          item._source.privateCredentialId
        )
      );
    }
    return cells;
  }

  function transformDataToSchemaRowTable(
    data: ItemGetCredentialsResponse[],
    totalNumberOfCredentials: number,
    type: string
  ): void {
    let tableRowsProps: ITableRowProps[] = [];

    data.forEach((item: ItemGetCredentialsResponse) => {
      tableRowsProps = [
        ...tableRowsProps,
        {
          id: item._source.credentialId,
          rowProps: {
            color: item._source.enable
              ? RowColorsEnum.DEFAULT
              : RowColorsEnum.YELLOW,
            onClick: () => {},
          },
          cells: buildCells(item, totalNumberOfCredentials, type),
        },
      ];
    });

    setRows(tableRowsProps);
  }

  useEffect(() => {
    transformDataToSchemaRowTable(
      get(props, "credentialsResponse.data", []),
      get(props, "credentialsResponse.total", 0),
      props.type
    );
  }, [props.credentialsResponse]);

  const commonCellProps: ICellRow = { align: "left", spacing: 0 };

  function buildRowOptionListItems(
    _credentialsLength: number,
    credential: ItemGetCredentialsResponse
  ): IOptionListItemProps[] {
    if (credential._source.enable) {
      return [
        getOptionListItem(LabelEnum.EDIT_ALIAS, credential),
        getOptionListItem(LabelEnum.RENEW_KEY, credential),
        getOptionListItem(LabelEnum.DEACTIVATE, credential),
        getOptionListItem(LabelEnum.DELETE, credential),
      ];
    }
    return [
      getOptionListItem(LabelEnum.ACTIVATE, credential),
      getOptionListItem(LabelEnum.DELETE, credential),
    ];
  }

  function getOptionListItem(
    type: string,
    credential: ItemGetCredentialsResponse
  ): IOptionListItemProps {
    switch (type) {
      case LabelEnum.DELETE: {
        return {
          Icon: IconTrash,
          disable: false,
          handleOptionClick: () => {
            setAlertSuccessMsg(
              `Se ha eliminado la credencial ${credential._source.alias} con éxito`
            );
            setAlertErrorMsg(LabelEnum.ACTION_REQUEST_ERROR);
            buildModalProps(false, true, credential);
          },
          onClick: () => {
            setAlertSuccessMsg(
              `Se ha eliminado la credencial ${credential._source.alias} con éxito`
            );
            setAlertErrorMsg(LabelEnum.ACTION_REQUEST_ERROR);
            buildModalProps(false, true, credential);
          },
          optionText: LabelEnum.DELETE,
          type: OptionListTypeEnum.TYPE2,
        };
      }
      case LabelEnum.DEACTIVATE: {
        return {
          Icon: IconBlock,
          disable: false,
          handleOptionClick: () => {
            setAlertSuccessMsg(
              `Se ha desactivado la credencial ${credential._source.alias} con éxito`
            );
            setAlertErrorMsg(LabelEnum.ACTION_REQUEST_ERROR);
            buildModalProps(true, false, credential);
          },
          onClick: () => {
            setAlertSuccessMsg(
              `Se ha desactivado la credencial ${credential._source.alias} con éxito`
            );
            setAlertErrorMsg(LabelEnum.ACTION_REQUEST_ERROR);
            buildModalProps(true, false, credential);
          },
          optionText: LabelEnum.DEACTIVATE,
          type: OptionListTypeEnum.TYPE1,
        };
      }
      case LabelEnum.RENEW_KEY: {
        return {
          Icon: IconSync,
          disable: false,
          handleOptionClick: () => {
            setAlertSuccessMsg(
              `Se ha regenerado la credencial ${credential._source.alias} con éxito`
            );
            setAlertErrorMsg(LabelEnum.ACTION_REQUEST_ERROR);
            buildModalProps(false, false, credential);
          },
          onClick: () => {
            setAlertSuccessMsg(
              `Se ha regenerado la credencial ${credential._source.alias} con éxito`
            );
            setAlertErrorMsg(LabelEnum.ACTION_REQUEST_ERROR);
            buildModalProps(false, false, credential);
          },
          optionText: LabelEnum.RENEW_KEY,
          type: OptionListTypeEnum.TYPE1,
        };
      }
      case LabelEnum.EDIT_ALIAS: {
        return {
          Icon: IconEdit,
          disable: false,
          handleOptionClick: () => {
            setAlertSuccessMsg(
              `Se ha modificado la credencial ${credential._source.alias} con éxito`
            );
            setAlertErrorMsg(LabelEnum.ACTION_REQUEST_ERROR);
            buildCredentialModalProps(credential);
          },
          onClick: () => {
            setAlertSuccessMsg(
              `Se ha modificado la credencial ${credential._source.alias} con éxito`
            );
            setAlertErrorMsg(LabelEnum.ACTION_REQUEST_ERROR);
            buildCredentialModalProps(credential);
          },
          optionText: LabelEnum.EDIT_ALIAS,
          type: OptionListTypeEnum.TYPE1,
        };
      }
      case LabelEnum.ACTIVATE: {
        return {
          Icon: IconPower,
          disable: false,
          handleOptionClick: () => {
            setAlertSuccessMsg(
              `Se ha activado la credencial ${credential._source.alias} con éxito`
            );
            setAlertErrorMsg(LabelEnum.ACTION_REQUEST_ERROR);
            changeCredentialStatus(
              true,
              credential._source.credentialId,
              credential._source.alias
            );
          },
          onClick: () => {
            setAlertSuccessMsg(
              `Se ha activado la credencial ${credential._source.alias} con éxito`
            );
            setAlertErrorMsg(LabelEnum.ACTION_REQUEST_ERROR);
            changeCredentialStatus(
              true,
              credential._source.credentialId,
              credential._source.alias
            );
          },
          optionText: LabelEnum.ACTIVATE,
          type: OptionListTypeEnum.TYPE1,
        };
      }
      default:
        return {
          Icon: IconEdit,
          disable: false,
          handleOptionClick: function noRefCheck() {},
          onClick: function noRefCheck() {},
          optionText: LabelEnum.EDIT_ALIAS,
          type: OptionListTypeEnum.TYPE1,
        };
    }
  }

  function buildModalProps(
    isDisabled: boolean,
    isDelete: boolean,
    credential: ItemGetCredentialsResponse
  ): void {
    props.actions.handleModalButton(false);
    if (isDelete) {
      props.changeModalProps({
        isError: true,
        descriptionText: LabelEnum.DESCRIPTION_MODAL_DELETE_CREDENTIAL,
        leftButtonText: LabelEnum.CANCEL,
        rightButtonText: LabelEnum.DELETE,
        titleText: LabelEnum.TITLE_MODAL_DELETE_CREDENTIAL,
        actions: {
          handleLeftButtonAction: () => dispatch(setIsOpenCustomModal(false)),
          handleRightButtonAction: () =>
            removeCredential(credential._source.credentialId, merchantId),
        },
        isRightButtonDisabled: false,
        onClose: () => dispatch(setIsOpenCustomModal(false)),
      });
      dispatch(setIsOpenCustomModal(true));
    } else if (isDisabled) {
      props.changeModalProps({
        isError: true,
        descriptionText: LabelEnum.DESCRIPTION_MODAL_DEACTIVATED_CREDENTIAL,
        leftButtonText: LabelEnum.CANCEL,
        rightButtonText: LabelEnum.DEACTIVATE,
        titleText: LabelEnum.TITLE_MODAL_DEACTIVATED_CREDENTIAL,
        actions: {
          handleLeftButtonAction: () => dispatch(setIsOpenCustomModal(false)),
          handleRightButtonAction: () => {
            changeCredentialStatus(
              false,
              credential._source.credentialId,
              credential._source.alias
            );
            dispatch(setIsOpenCustomModal(false));
          },
        },
        isRightButtonDisabled: false,
        onClose: () => dispatch(setIsOpenCustomModal(false)),
      });
      dispatch(setIsOpenCustomModal(true));
    } else {
      props.changeModalProps({
        isError: false,
        descriptionText: LabelEnum.DESCRIPTION_MODAL_RENEW_CREDENTIAL,
        leftButtonText: LabelEnum.CANCEL,
        rightButtonText: LabelEnum.RENEW_KEY_MODAL,
        titleText: LabelEnum.TITLE_MODAL_RENEW_CREDENTIAL,
        actions: {
          handleLeftButtonAction: () => dispatch(setIsOpenCustomModal(false)),
          handleRightButtonAction: () => renewCredential(credential),
        },
        isRightButtonDisabled: false,
        onClose: () => dispatch(setIsOpenCustomModal(false)),
      });
      dispatch(setIsOpenCustomModal(true));
    }
  }

  function buildCredentialModalProps(
    credential: ItemGetCredentialsResponse
  ): void {
    props.changeCredentialModalProps({
      isEdit: true,
      leftButtonText: LabelEnum.CANCEL,
      rightButtonText: LabelEnum.EDIT,
      titleText: LabelEnum.DESCRIPTION_MODAL_EDIT_CREDENTIAL,
      editCredentialRequest: {
        alias: credential._source.alias,
        recoverCredentials: false,
        credentialId: credential._source.credentialId,
        merchantId: merchantId,
        enable: credential._source.enable,
      },
    });
    dispatch(setIsOpenCredentialModal(true));
  }

  function buildTextActionCell(
    line1: string,
    line2: string,
    clipboardCopyString: string
  ): ITableCellProps {
    return {
      type: TableBodyCellEnum.TEXT_ACTION,
      props: {
        line1,
        line2,
        cellProps: commonCellProps,
        id: line1 || line2,
        icon: <IconCopy color={StylesEnum.PRIMARY} />,
        onSelected: () => clipboard(clipboardCopyString),
      },
    };
  }

  function changeCredentialStatus(
    newStatus: boolean,
    credentialId: string,
    alias: string
  ): void {
    dispatch(
      updateCredential({
        alias,
        credentialId,
        merchantId,
        enable: newStatus,
        recoverCredentials: false,
      })
    );
  }

  function removeCredential(credentialId: string, merchantId: string): void {
    dispatch(
      deleteCredential({
        credentialId,
        merchantId,
      })
    );
  }

  function renewCredential(credential: ItemGetCredentialsResponse): void {
    props.actions.handleModalButton(true);
    dispatch(
      regenerateCredential({
        merchantId,
        alias: credential._source.alias,
        credentialId: credential._source.credentialId,
        enable: credential._source.enable,
        recoverCredentials: true,
      })
    );
  }

  useEffect(() => {
    if (props.type === EntityNameEnum.CUSTOMER) {
      setHeader(CREDENTIALS_TABLE_CUSTOMER);
    }

    if (props.type === EntityNameEnum.BRANCH) {
      roles[RolesEnum.BackofficeMasterCustomer]
        ? setHeader(
            CREDENTIALS_TABLE_COLUMNS_CUSTOMER(
              handleCountClick,
              { field: get(sortClick, "field", "") },
              sortClick.numClick
            )
          )
        : setHeader(CREDENTIALS_TABLE_COLUMNS);
    }
  }, []);

  const handleCountClick = (field: string) => {
    const sortField: string = get(sortClick, "field", "");
    const numClick: number = get(sortClick, "numClick", 0);

    if (sortField !== field && sortField !== "") {
      setSortClick({ field, numClick: 1 });
      return;
    }

    if (numClick >= 2) {
      setSortClick({ field, numClick: 0 });
    } else {
      setSortClick({
        field,
        numClick: numClick + 1,
      });
    }
  };

  useEffect(() => {
    const sortField: string = get(sortClick, "field", "");
    const cellDataField = CREDENTIALS_SORT_CATALOG[sortField];
    if (!isEmpty(sortField)) {
      setHeader(
        cloneDeep(
          CREDENTIALS_TABLE_COLUMNS_CUSTOMER(
            handleCountClick,
            { field: sortField },
            sortClick.numClick
          )
        )
      );
      setRows(
        cloneDeep(sortObjectArray(rows, cellDataField, sortClick.numClick))
      );
      dispatch(setIsLoading(true));
    }
  }, [sortClick]);

  useEffect(() => {
    dispatch(setIsLoading(false));
  }, [header]);

  return {
    header,
    isLoading,
    isLoadingCredentials,
    rows,
  };
};
