import React from "react";
import {
  createStyles,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Copy } from "react-feather";
import { get, isEmpty } from "lodash";
import { InvoiceRecord } from "../../../types/remote/response_search_blling_by_filters";
import { clipboard } from "../../shared/clipboard";
import { formatDate } from "../../shared/utils/date-time-util";

export interface InfoTrxProps {
  trxInfo: InvoiceRecord | undefined;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      boxShadow: "none !important",
      borderRadius: "5px !important",
      backgroundColor: "#F7FAFC !important",
      marginBottom: "40px !important",
    },
    copyIcon: {
      marginLeft: 10,
      color: "#CBD5E0",
    },
  })
);

export const handleCopy = (text: string) => () => {
  clipboard(text);
};

const ModalBodyNoteCreditInfo = (props: InfoTrxProps) => {
  const classes = useStyles();

  return (
    <Grid>
      <Paper className={classes.paper}>
        <Grid
          container
          spacing={2}
          color="secondary"
          style={{
            marginTop: 20,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Grid item xs={12} spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={4} spacing={2}>
                <Typography variant="body2" color="textSecondary">
                  Fecha:
                </Typography>
                <Typography variant="body2">
                  {formatDate(props.trxInfo?.created, "dd MMM'.' yyyy")}
                </Typography>
              </Grid>
              <Grid item xs={4} spacing={2}>
                <Typography variant="body2" color="textSecondary">
                  Cliente:
                </Typography>
                <Typography variant="body2">
                  {props.trxInfo?.merchant_name}
                </Typography>
              </Grid>
              <Grid item xs={4} spacing={2}>
                <Typography variant="body2" color="textSecondary">
                  Tax ID:
                </Typography>
                <Typography variant="body2">{props.trxInfo?.tax_id}</Typography>
              </Grid>
              <Grid item xs={4} spacing={2}>
                <Typography variant="body2" color="textSecondary">
                  Monto total:
                </Typography>
                <Typography variant="body2">{props.trxInfo?.amount}</Typography>
              </Grid>
              <Grid item xs={8} spacing={2}>
                <Typography variant="body2" color="textSecondary">
                  Descripción:
                </Typography>
                <Typography variant="body2">
                  {props.trxInfo?.description}
                </Typography>
              </Grid>
              <Grid item xs={12} spacing={2}>
                <Typography variant="body2" color="textSecondary">
                  Estado:
                </Typography>
                <Typography variant="body2">
                  {props.trxInfo?.status === "completed"
                    ? "Automático"
                    : "Manual"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.paper}>
        <Grid
          container
          spacing={2}
          color="secondary"
          style={{
            marginTop: 20,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Grid item xs={12} spacing={2}>
            <Typography variant="body2" color="textSecondary">
              Factura asociada:
            </Typography>
            <Typography variant="body2">
              {get(
                props,
                "trxInfo.invoice_info.invoice_associate",
                "No hay factura asociada"
              )}
              {isEmpty(props.trxInfo?.invoice_associate) ? (
                ""
              ) : (
                <IconButton className={classes.copyIcon}>
                  <Copy
                    id={"copyIcon"}
                    size={16}
                    onClick={handleCopy(props.trxInfo?.invoice_associate)}
                  />
                </IconButton>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} spacing={2}>
            <Typography variant="body2" color="textSecondary">
              Comentario:
            </Typography>
            <Typography variant="body2">
              {get(props, "trxInfo.invoice_info.invoice_comentary", "-")}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ModalBodyNoteCreditInfo;
