import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const ticketOrderStyles = createNamedStyles({
  orderButton: {
    borderRadius: "4px",
    border: "none",
    fontWeight: "500",
    color: "text.dark",
    "&:hover": {
      background: "transparent",
      borderRadius: "4px",
      border: "none",
      color: "text.dark",
    },
  },
  acceptButton: {
    background: "#023365",
    borderRadius: "4px",
    border: "none",
    fontWeight: "500",
    marginLeft: "20px",
    color: "background.default",
    "&:hover": {
      background: "#112B45",
      color: "background.default",
      borderRadius: "4px",
      border: "none",
    },
  },
});
