import {
  EXPECTED_RESULT,
  FINISH_EDITION_MODAL_COMPLETE,
  FINISH_EDITION_MODAL_INCOMPLETE,
  RuleModal,
  RuleModalProperties,
} from "../../../shared/constants/modal-info";
import { StatusStepEnum } from "../../../shared/enums/StatusStepEnum";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import {
  changeTab,
  setBranchEditList,
  setMerchantDeferredData,
  setOpenMassiveModal,
  setSuccessSaveMassiveDeferred,
  showLoadingModal,
  showModalInfo,
} from "../../../store/reducers/layout/layout.slice";
import { UseModal } from "./UseShowModal.interface";
import {
  getBusinessRulesStatusStep,
  existPendingRulesSent,
  arePendingRules,
} from "../../../shared/utils/get-business-rules-status-step";
import { TabIndex } from "../../../shared/enums/TabIndexEnum";
import { get, isEmpty, defaultTo, set, unset, isEqual } from "lodash";
import { AppRoutes } from "../../../shared/infrastructure/routes";
import { NodeInfoPath } from "../../../shared/enums/NodeInfoPath";
import {
  evaluateDeferredNotSent,
  disableContinueButton,
  getMerchantId,
  isAvailableCardProcessor,
  getStorageBranchEditList,
  setStorageBranchEditList,
  updateBranchIsModify,
} from "../../../utils/utilsFile";
import {
  getNodeInfo,
  centralizeBranch,
  centralizeProcessors,
  updateHierarchyNodeConfig,
  updateMerchantMassiveDeferredData,
} from "../../../store/thunks/layout/layout.thunks";
import { ProcessorConstants } from "../../../shared/constants/ProcessorConstants";
import {
  Config,
  UpdateConfigHierarchyRequest,
} from "../../../../types/update_config_hierarchy_request";
import { ConfigurationCodeEnum } from "../../../shared/enums/ProcessingStepEnum";
import {
  Configs,
  GetNodeInfoResponse,
} from "../../../../types/get_node_info_response";
import { filterNodeInfoByConfiguration } from "../../../shared/utils/filter-node-info-by-conf";
import { isDeferredAllowed } from "../../../containers/ProcessingConfigIndex/state/useOnInitProcessing";
import React, { useEffect, useState } from "react";
import { ICentralizeNodeRequest } from "../../../shared/infrastructure/interfaces/ICentralizeNodeRequest";
import { MessageAlertEnum } from "../../../shared/enums/AlertEnum";
import { BranchListPath } from "../../../shared/enums/BranchListPath";
import { GetNodeInfoRequest } from "../../../../types/get_node_info_request";
import {
  getNodeStatus,
  isBussinessDeferredNotComplete,
} from "../../../shared/utils/get-node-status";
import { BranchesSelectedList } from "../../SelectedBranch/state/IUseSelectedBranch.interfaces";
interface CodeStatus {
  code: string;
  status?: string;
}
import { isConsoleMerchantMassive } from "../../../shared/utils/local-storage/console-merchant-massive";
import {
  DeferredOption1,
  MerchantDeferred,
} from "../../../../types/merchan_deferred";
import { LocalStoragePathEnum } from "../../../shared/infrastructure/enums/LocalStoragePathEnum";
import {
  DeferredOptionPath,
  DeferredPath,
} from "../../../shared/enums/DeferredOptionPath";
import { UpdateMerchantMassiveRequest } from "../../../../types/update_merchant_massive_request";
import {
  BranchEditListPathEnum,
  SessionStoragePathEnum,
} from "../../../shared/infrastructure/enums/SessionStoragePathEnum";
import { BranchList } from "../../../../types/branch_list";
import { CustomerBatchInformation } from "../../../../types/customer_batch_information";
import { FooterProps } from "../Footer.interface";
import { BranchEditList, Data } from "../../../../types/branch_edit_list";
import { STATUS_CODE } from "../../../shared/constants/status_code";
import { changeConfigBranchStatus } from "../../../utils/branch/branch-util";
import { RuleItem } from "../../../../types/get_business_rules_response";
import { PayoutRule } from "../../../../types/get_payouts_business_rules_response";

export const useShowModal = (props: FooterProps): UseModal => {
  const dispatch = useAppDispatch();
  const isMassivePath: boolean = get(props, "isMassive", false);
  const {
    nodeInfo,
    businessRules,
    payoutsBusinessRules,
    tabSelected,
    processor,
    defaultProcessor,
    branchEditList,
    openMassiveModal,
    deferredPeruMassive,
    merchantDeferredData,
    openAlertCentralizeBranch,
    massiveInformation: { listBranches, disableProcessorsTab },
  } = useAppSelector((store) => store.layout);

  const [disabledNextButton, setDisabledNextButton] = useState<boolean>(false);
  const [disabledSaveButton, setDisabledSaveButton] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = React.useState<boolean>(true);
  const [openFinishEditionModal, setOpenFinishEditionModal] = React.useState<
    boolean
  >(false);
  const consoleMerchantMassive: boolean = isConsoleMerchantMassive();
  const merchant_deferred_data: MerchantDeferred = JSON.parse(
    defaultTo(
      localStorage.getItem(LocalStoragePathEnum.MERCHANT_DEFERRED_DATA),
      "{}"
    )
  );

  const businessRulesContinue = () => {
    const stepSectionStatus: StatusStepEnum = getBusinessRulesStatusStep(
      businessRules.items,
      payoutsBusinessRules
    );
    const requestCodes: CodeStatus[] = [
      { code: ConfigurationCodeEnum.BUSINESS_RULES },
    ];
    switch (stepSectionStatus) {
      case StatusStepEnum.COMPLETE:
        const hierarchyRequest: UpdateConfigHierarchyRequest = buildHierarchyRequest(
          requestCodes
        );
        dispatch(updateHierarchyNodeConfig(hierarchyRequest));
        const merchantCountry: string = get(
          nodeInfo,
          NodeInfoPath.InfoCountry,
          ""
        );
        if (!isDeferredAllowed(merchantCountry)) {
          setTimeout(
            () => (window.location.href = AppRoutes.SERVICES(getMerchantId())),
            500
          );
          break;
        }
        dispatch(changeTab(TabIndex.DEFERRED));
        break;
      case StatusStepEnum.PENDING:
        dispatch(showModalInfo(RuleModal.PENDING));
        break;
    }
  };

  const handleCloseModal = (): void => {
    setOpenFinishEditionModal(false);
    dispatch(setOpenMassiveModal(false));
  };

  const handleDeferredContinue = () => {
    const requestCodes: CodeStatus[] = [
      { code: ConfigurationCodeEnum.DEFERRED },
    ];
    const hierarchyRequest: UpdateConfigHierarchyRequest = buildHierarchyRequest(
      requestCodes
    );
    dispatch(updateHierarchyNodeConfig(hierarchyRequest));
    setTimeout(
      () =>
        (window.location.href = !consoleMerchantMassive
          ? AppRoutes.SERVICES(getMerchantId())
          : AppRoutes.EDIT_MASSIVE()),
      1000
    );
  };

  const handleFinishMassiveDeferred = () => {
    const hasDeferredNotSent: boolean = evaluateDeferredNotSent();
    if (hasDeferredNotSent) {
      setOpenFinishEditionModal(hasDeferredNotSent);
    } else handleDeferredContinue();
  };

  const processorsContinue = async () => {
    const nodeData: GetNodeInfoResponse = defaultTo(
      nodeInfo,
      {} as GetNodeInfoResponse
    );
    const processorStepStatus: string = getNodeStatus(
      nodeData,
      ConfigurationCodeEnum.PROCESSOR
    );
    switch (processorStepStatus) {
      case StatusStepEnum.COMPLETE:
        if (
          isAvailableCardProcessor(processor) &&
          isBussinessDeferredNotComplete(nodeData)
        ) {
          dispatch(changeTab(TabIndex.BUSINESS_RULES));
        } else {
          window.location.href = AppRoutes.SERVICES(getMerchantId());
        }
        break;
      case StatusStepEnum.PENDING:
        const requestCodes: CodeStatus[] = [
          { code: ConfigurationCodeEnum.PROCESSOR },
          {
            code: ConfigurationCodeEnum.BUSINESS_RULES,
            status: StatusStepEnum.OMITTED,
          },
          {
            code: ConfigurationCodeEnum.DEFERRED,
            status: StatusStepEnum.OMITTED,
          },
        ];
        const configIds = ConfigurationCodeEnum.PROCESSOR.concat(
          ",",
          ConfigurationCodeEnum.BUSINESS_RULES,
          ",",
          ConfigurationCodeEnum.DEFERRED
        );
        const nodeInfoRequest: GetNodeInfoRequest = {
          publicMerchantId: getMerchantId(),
          configIds,
        };

        if (isAvailableCardProcessor(processor)) {
          const hierarchyRequest: UpdateConfigHierarchyRequest = buildHierarchyRequest(
            requestCodes.filter(
              (req) => req.code === ConfigurationCodeEnum.PROCESSOR
            )
          );
          await dispatch(updateHierarchyNodeConfig(hierarchyRequest));
          await dispatch(getNodeInfo(nodeInfoRequest));
          dispatch(changeTab(TabIndex.BUSINESS_RULES));
        } else {
          const hierarchyRequest: UpdateConfigHierarchyRequest = buildHierarchyRequest(
            requestCodes
          );
          await dispatch(updateHierarchyNodeConfig(hierarchyRequest));
          window.location.href = AppRoutes.SERVICES(getMerchantId());
        }
        break;
    }
  };

  const handleContinue = (): void => {
    if (!isEmpty(getMerchantId())) {
      switch (tabSelected) {
        case TabIndex.PROCESSORS:
          processorsContinue().then();
          break;
        case TabIndex.BUSINESS_RULES:
          businessRulesContinue();
          break;
        case TabIndex.DEFERRED:
          handleDeferredContinue();
          break;
      }
    }
  };

  const handleFirstButtonAction = (): void => {
    if (isMassivePath) {
      switch (tabSelected) {
        case TabIndex.PROCESSORS:
          if (isEqual(openAlertCentralizeBranch.result, EXPECTED_RESULT.OK)) {
            dispatch(showModalInfo(FINISH_EDITION_MODAL_COMPLETE));
            return;
          }
          dispatch(showModalInfo(FINISH_EDITION_MODAL_INCOMPLETE));
          break;
        case TabIndex.BUSINESS_RULES:
          handleButtonFinish();
          break;
        case TabIndex.DEFERRED:
          handleFinishMassiveDeferred();
          break;
      }
      return;
    }

    handleContinue();
  };

  const handleSecondButtonAction = (): void => {
    switch (tabSelected) {
      case TabIndex.PROCESSORS:
        handleSaveMassiveProcessor();
        break;
      case TabIndex.BUSINESS_RULES:
        handleButtonSave();
        break;
      case TabIndex.DEFERRED:
        handleSaveMassiveDeferred();
        break;
    }
  };

  const handleSaveMassiveProcessor = async () => {
    setDisabledSaveButton(true);
    const editList: BranchEditList = getStorageBranchEditList();
    const config: ICentralizeNodeRequest = {
      nodeId: get(nodeInfo, NodeInfoPath.NodeID, ""),
      configs: [
        {
          configuration: ConfigurationCodeEnum.PROCESSOR,
          centralizeschildsNodeIds: getListBranchesField(BranchListPath.NodeID),
        },
      ],
    };
    dispatch(
      showLoadingModal({
        isOpen: true,
        message: MessageAlertEnum.LOADING_SAVE_MASSIVE_PROCESSOR_TITLE,
        description:
          MessageAlertEnum.LOADING_SAVE_MASSIVE_PROCESSOR_DESCRIPTION,
      })
    );
    const result: object = await dispatch(centralizeBranch(config));
    if (get(result, "payload.status") === STATUS_CODE.SUCCESS) {
      setDisabledSaveButton(false);
      onSucessCentralizedProcessor(result, editList);
    }
  };

  const onSucessCentralizedProcessor = (
    result: object,
    editList: BranchEditList
  ) => {
    const nodesCentralized: string[] = get(
      result,
      "meta.arg.configs[0].centralizeschildsNodeIds",
      []
    );
    const branchsEdited: Data[] = get(editList, "data", []).filter((l: Data) =>
      nodesCentralized.some((id: string) => id.includes(get(l, "node_id", "")))
    );
    branchsEdited.forEach((bl: Data) => {
      const configProcessor: Configs | undefined = get(bl, "configs", []).find(
        (conf: Configs) =>
          conf.configuration === ConfigurationCodeEnum.PROCESSOR
      );
      if (configProcessor) {
        set(
          configProcessor,
          "centralizedNodesId",
          get(result, "meta.arg.nodeId", "")
        );
        const configsNotProcessor: Configs[] = get(bl, "configs", []).filter(
          (conf: Configs) =>
            conf.configuration !== ConfigurationCodeEnum.PROCESSOR
        );
        bl.configs = [...configsNotProcessor, configProcessor];
      }
      bl.isModify = true;
    });
    const notEditedBranches: Data[] = get(editList, "data", []).filter(
      (l: Data) =>
        !nodesCentralized.some((id: string) =>
          id.includes(get(l, "node_id", ""))
        )
    );
    editList.data = [...notEditedBranches, ...branchsEdited];
    setStorageBranchEditList(editList);
  };

  const getListBranchesField = (selectedPath: string): string[] => {
    return listBranches.map((bl: BranchesSelectedList) => bl[selectedPath]);
  };

  const handleSaveMassiveDeferred = async () => {
    setIsDisabled(true);
    updateBranchIsModify();
    const branch_list: string[] = [];
    const branch_list_session_storage: BranchList[] = JSON.parse(
      defaultTo(
        sessionStorage.getItem(SessionStoragePathEnum.BRANCH_LIST),
        "[]"
      )
    );

    const customer_batch_information: CustomerBatchInformation = JSON.parse(
      defaultTo(
        localStorage.getItem(LocalStoragePathEnum.CUSTOMER_BATCH_INFORMATION),
        "{}"
      )
    );

    branch_list_session_storage.map((branch: BranchList) => {
      branch_list.push(branch.publicMerchantId);
    });

    const deferred: DeferredOption1[] = get(
      merchant_deferred_data,
      DeferredPath.DeferredOptions,
      []
    );
    const un_sent_deferred: DeferredOption1[] = deferred.filter(
      (def: DeferredOption1) => !get(def, DeferredOptionPath.IsSent, false)
    );

    un_sent_deferred.map((_, index: number) => {
      unset(un_sent_deferred[index], DeferredOptionPath.IsSent);
    });

    const request: UpdateMerchantMassiveRequest = {
      deferredOptions: un_sent_deferred,
      country: get(branchEditList, "data[0].country", ""),
      branches: branch_list,
      customerMerchantId: get(
        customer_batch_information,
        "publicMerchantId",
        ""
      ),
      customerName: get(customer_batch_information, "name", ""),
    };

    dispatch(setOpenMassiveModal(true));
    await dispatch(updateMerchantMassiveDeferredData({ payload: request }));

    deferred.map((_: DeferredOption1, index: number) => {
      set(deferred[index], DeferredOptionPath.IsSent, true);
    });

    const new_merchant_deferred_data: MerchantDeferred = {
      ...merchant_deferred_data,
      deferredOptions: deferred,
    };

    localStorage.setItem(
      LocalStoragePathEnum.MERCHANT_DEFERRED_DATA,
      JSON.stringify(new_merchant_deferred_data)
    );

    dispatch(setMerchantDeferredData(new_merchant_deferred_data));
    dispatch(setSuccessSaveMassiveDeferred(true));

    const updateBranchEditList: Data[] = (get(
      branchEditList,
      BranchEditListPathEnum.DATA,
      []
    ) as Data[]).map((branch: Data) =>
      changeConfigBranchStatus(
        branch,
        ConfigurationCodeEnum.DEFERRED,
        StatusStepEnum.COMPLETE
      )
    );
    setStorageBranchEditList({ data: updateBranchEditList });
    dispatch(setBranchEditList({ data: updateBranchEditList }));
  };

  const buildHierarchyRequest = (
    configurationCodes: CodeStatus[]
  ): UpdateConfigHierarchyRequest => {
    const configs: Config[] = [];

    configurationCodes.forEach((configuration: CodeStatus) => {
      const config: Configs = filterNodeInfoByConfiguration(
        nodeInfo,
        configuration.code
      );
      configs.push({
        configuration: configuration.code,
        value: getMerchantId(),
        centralizedChildsNodeIds: get(config, "centralizedNodesID"),
        status: defaultTo(configuration.status, StatusStepEnum.COMPLETE),
        updatedAt: 0,
        updatedBy: ProcessorConstants.USERNAME_BO,
      });
    });

    return {
      configs,
      nodeId: get(nodeInfo, NodeInfoPath.NodeID, ""),
    };
  };

  const handleButtonFinish = () => {
    if (
      existPendingRulesSent(businessRules.items) ||
      existPendingRulesSent(payoutsBusinessRules)
    ) {
      dispatch(showModalInfo(RuleModalProperties.FINISH));
      return;
    }
    window.location.href = AppRoutes.BRANCH_EDIT;
  };

  const handleButtonSave = () => {
    dispatch(showModalInfo(RuleModalProperties.SAVE));
  };

  const disabledButtonSave = (): boolean => {
    return !(
      arePendingRules<RuleItem>(businessRules.items) ||
      arePendingRules<PayoutRule>(payoutsBusinessRules)
    );
  };

  useEffect(() => {
    const deferred: DeferredOption1[] = get(
      merchant_deferred_data,
      DeferredPath.DeferredOptions,
      []
    );
    const hasDeferredNotSent: boolean = evaluateDeferredNotSent();
    setIsDisabled(!hasDeferredNotSent || isEmpty(deferred));
  }, [deferredPeruMassive, merchantDeferredData]);

  const disabledButtons = (): void => {
    setDisabledNextButton(
      !isMassivePath &&
        (get(processor, "length", 0) <= 0 ||
          disableContinueButton(processor, defaultProcessor))
    );

    setDisabledSaveButton(getStatusSaveButton());
  };

  const getStatusSaveButton = (): boolean => {
    if (tabSelected === TabIndex.PROCESSORS) return disableProcessorsTab;

    return false;
  };

  useEffect(() => {
    disabledButtons();
  }, [processor, defaultProcessor, tabSelected]);

  useEffect(() => {
    if (openAlertCentralizeBranch.result === EXPECTED_RESULT.OK) {
      const merchantIds = getListBranchesField(BranchListPath.MerchantId);
      dispatch(centralizeProcessors({ merchantIds }));
    }
  }, [openAlertCentralizeBranch]);

  return {
    actions: {
      handleContinue,
      handleSaveMassiveDeferred,
      handleCloseModal,
      handleFinishMassiveDeferred,
      handleDeferredContinue,
      handleButtonSave,
      handleButtonFinish,
      disabledButtonSave,
      handleFirstButtonAction,
      handleSecondButtonAction,
    },
    data: {
      processor,
      defaultProcessors: defaultProcessor,
      consoleMerchantMassive,
      isDisabled,
      openFinishEditionModal,
      openMassiveDefModal: defaultTo(openMassiveModal, false),
      disabledNextButton,
      disabledSaveButton,
    },
  };
};
