export enum ROUTES {
  INDEX = "/create",
  NOTIFICATIONS = "/notifications/:publicMerchantId",
  NOTIFICATION_DETAIL = ":publicMerchantId/notifications/detail",
  WEBHOOKS = "/webhook/:publicMerchantId",
  MERCHANT_WEBHOOK_CREATE = "/merchant-webhook/create?publicMerchantId={merchantId}",
  WEBHOOKS_API = "/webhook/v1",
}

export const SPA_BASENAME = "merchant-webhook";
