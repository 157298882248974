import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Inbox } from "react-feather";
import searchImgIcon from "../../assets/images/search.svg";
import emptyDataRetention from "../../assets/images/empty_retention_data.svg";
import { makeStyles } from "@material-ui/core/styles";
export interface IEmptyListProps {
  hiddenInbox?: boolean;
  searchImg?: boolean;
  isProcessData?: boolean;
}

const useStyles = makeStyles({
  title: {
    fontSize: "13px",
    color: "#6D7781",
    fontWeight: 400,
    marginTop: "30px",
  },
  subTitle: {
    fontSize: "16px",
    color: "#023365",
    textAlign: "center",
    fontWeight: 500,
    marginBottom: "20px",
  },
});

export const EmptyList: React.FC<IEmptyListProps> = (
  props: IEmptyListProps
) => {
  const { hiddenInbox, searchImg } = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container style={{ background: "#FFF" }}>
        <Grid
          style={{ height: 400 }}
          item
          xs={12}
          container
          alignItems="center"
          justify="center"
          direction="row"
        >
          {!props.isProcessData ? (
            <div>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={hiddenInbox ? 12 : 8}
                  container
                  alignItems="center"
                  justify={hiddenInbox ? "center" : "flex-start"}
                  direction={"row"}
                >
                  <div>
                    <Typography variant={hiddenInbox ? "h4" : "h6"}>
                      No se ha encontrado ninguna coincidencia
                    </Typography>
                  </div>
                </Grid>
                {!hiddenInbox && (
                  <Grid item xs={12} md={4} id={"Inbox"}>
                    <Inbox size={150} color="rgba(226,232,240,1)" />
                  </Grid>
                )}
                {searchImg && (
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    justify={"center"}
                  >
                    <img src={searchImgIcon} />
                  </Grid>
                )}
              </Grid>
            </div>
          ) : (
            <Grid container>
              <Grid
                item
                xs={12}
                md={hiddenInbox ? 12 : 8}
                container
                alignItems="center"
                justify={"center"}
                direction={"row"}
              >
                <Typography className={classes.title}>
                  YA HAS REVISADO TODO POR HOY
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={hiddenInbox ? 12 : 8}
                container
                alignItems="center"
                justify={"center"}
                direction={"row"}
              >
                <Typography className={classes.subTitle}>
                  Revisa los items procesados visitando la <br /> pestaña
                  &quot;Histórico&quot;
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                container
                alignItems="center"
                justify={"center"}
              >
                <img src={emptyDataRetention} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
