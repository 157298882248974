export enum ROUTES {
  INDEX = "/",
  CREDENTIALS = "/credentials",
  WEBHOOKS_API = "/webhook/v1",
  MERCHANT_WEBHOOK = "/merchant-webhook",
}

export enum EXTERNAL_ROUTES {
  MERCHANT_WEBHOOK_CREATE = "/merchant-webhook/create",
  MERCHANT_WEBHOOK_EDIT = "/merchant-webhook/create?publicMerchantId={merchantId}&mode=edition&menuItem=true",
}

export const enum API_ROUTES {
  TEST_CONNECTION = "/webhook/v1/testConnection",
}

export const SPA_BASENAME = "developers";
