import { IStyles } from "../../shared/infrastructure/interfaces/Styles.interfaces";

export const webhookNotificationsStyles: IStyles = {
  emptyNotifications: {
    display: "flex",
    background: "#fff",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",

    height: "200px",
  },
  text: {
    width: "200px",
    fontWeight: "500",
    fontSize: "16px",
    textAlign: "center",
  },
  titleText: {
    fontSize: "16px",
    fontWeight: 500,
  },
  subtitleText: {
    fontSize: "15px",
    fontWeight: 400,
  },
  divider: {
    width: "-webkit-fill-available",
    margin: "4px 8px",
  },
};
