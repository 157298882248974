import React from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { ICellData } from "../../containers/TableRates/state/useTableRates";

const useStyles = makeStyles({
  head: {
    backgroundColor: "#EEF6FF",
  },
  container: {
    boxShadow: "none",
    background: "none",
    "& td, th": {
      color: "#293036",
      fontSize: "14px",
    },
    "& th": {
      fontWeight: "bold",
      border: "none",
      padding: "8px 8px",
    },
    "& td": {
      padding: "10px 8px",
      borderBottom: "1.5px solid #E2E8F0",
    },
    "& tbody>tr:last-child td": {
      border: "none",
    },
  },
});

export interface ITableSubsection {
  renderData: ICellData[][];
  headerData?: ICellData[];
}

export const TableSubsection = ({
  renderData,
  headerData,
}: ITableSubsection) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table size="small" aria-label="a dense table">
        {headerData !== undefined && (
          <TableHead>
            <TableRow className={classes.head}>
              {headerData.map((cellDaTa, index) => (
                <TableCell key={index} align={cellDaTa.align}>
                  {cellDaTa.text}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}

        <TableBody>
          {renderData.map((register, index) => (
            <TableRow key={index}>
              {register.map((cellDaTa, indexCell) => (
                <TableCell key={indexCell} align={cellDaTa.align}>
                  {cellDaTa.text}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableSubsection;
