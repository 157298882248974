import { useEffect, useState } from "react";
import { auth } from "../../../shared/auth";
import {
  HEADER_SECTIONS,
  RESET_LOCALSTORAGE_ROUTES,
} from "../../../shared/constants/header";
import {
  OperationEnum,
  pathValidateIsContainsUrl,
  Sections,
} from "../../../shared/enums/sections";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  openModal,
  refreshHeaderValuesFromLocalStorage,
  resetValuesFromLocalStorage,
} from "../../../store/actions/layout/layout.actions";
import {
  findNodeByNodeId,
  getNodeInfo,
  getParentNodeByMerchantId,
} from "../../../store/thunks/layout/layout.thunks";
import { UseHeader } from "./useHeader.interfaces";
import {
  MERCHANT_RESUME_CUSTOMER,
  OWNER_MERCHANT_LIST_CUSTOMER,
  ROUTES,
} from "../../../shared/constants/routes";
import { defaultTo, get } from "lodash";
import { LocalStoragePaths } from "../../../shared/enums/localStoragePaths";
import { getSessionStorageValueByPath } from "../../../store/utils/sessionStorage-utils";
import { EntityName } from "../../../shared/enums/entityName";
import { QueryParamsEnum } from "../../../shared/enums/queryParamsEnum";
import { ToolTipEnum } from "../../../shared/enums/toolTipEnum";
import { URLS_FOR_CUSTOMER_BRANCH } from "../../../shared/infrastructure/MenuItemPathsEnum";
import { HeaderEnum } from "../../../shared/infrastructure/HeaderEnum";

export const useHeader = (): UseHeader => {
  const [searchParams] = useSearchParams();
  const {
    ownerName,
    entityName,
    nodeName,
    origin,
    customerName,
    customerEntityName,
    nodeInfo,
    parentNodeId,
  } = useAppSelector((store) => store.layout);

  const dispatch = useAppDispatch();
  const location = useLocation();
  let tooltip: string = ToolTipEnum.OWNER;

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [addCloseButton, setAddCloseButton] = useState<boolean>(false);
  const [getEntityName, setEntityName] = useState<string>("");
  const [typeEntity, setTypeEntity] = useState<string>("");
  const [getNameGeneralInfo, setNameGeneralInfo] = useState<string>("");
  const [isUserPath, setUserPath] = useState<boolean>(false);

  const currentUrl: string = location.pathname;

  const handleSetIsEdit = (): void => {
    const operation: string = defaultTo(
      searchParams.get(QueryParamsEnum.OPERATION),
      ""
    );

    setIsEdit(operation === OperationEnum.EDIT);
  };

  const getParentNode = () => {
    const publicMerchantId = defaultTo(
      searchParams.get(QueryParamsEnum.PUBLIC_MERCHANT_ID),
      searchParams.get(QueryParamsEnum.MERCHANT_ID)
    );

    if (publicMerchantId) {
      dispatch(getParentNodeByMerchantId({ publicMerchantId }));
    }
  };

  const getNodeInfoData = () => {
    const publicMerchantId = defaultTo(
      searchParams.get(QueryParamsEnum.PUBLIC_MERCHANT_ID),
      searchParams.get(QueryParamsEnum.MERCHANT_ID)
    );

    if (publicMerchantId) {
      dispatch(getNodeInfo({ publicMerchantId }));
    }
  };

  const getNode = () => {
    const nodeId = searchParams.get(QueryParamsEnum.NODE_ID);

    if (nodeId) dispatch(findNodeByNodeId({ nodeId }));
  };
  const basicInformation = getSessionStorageValueByPath(
    LocalStoragePaths.BASIC_MERCHANT_INFORMATION
  );

  const handleIsEdition = () => {
    const mode = searchParams.get(QueryParamsEnum.MODE);

    mode && setIsEdit(mode === OperationEnum.EDITION);
  };

  const handleValidateAddCloseButton = () => {
    switch (currentUrl) {
      case ROUTES.CONSOLE_WALLET:
        setAddCloseButton(false);
        break;
      default:
        if (!isEdit) setAddCloseButton(true);
        else setAddCloseButton(false);
        break;
    }
  };

  useEffect(() => {
    getParentNode();
    getNode();
    getNodeInfoData();
    handleSetIsEdit();
    handleIsEdition();
  }, [location.pathname]);

  useEffect(() => {
    handleValidateAddCloseButton();
  }, [isEdit]);

  const setSectionType = () => {
    if (!isEdit) {
      if (getEntityName === EntityName.CUSTOMER) {
        setTypeEntity(`${HEADER_SECTIONS.get(Sections.CREATE_CUSTOMER)}`);
      } else if (getEntityName === EntityName.BRANCH) {
        setTypeEntity(`${HEADER_SECTIONS.get(Sections.CREATE_BRANCH)}`);
      } else {
        setTypeEntity(`${HEADER_SECTIONS.get(Sections.CREATE_OWNER)}`);
      }
    } else {
      if (getEntityName === EntityName.CUSTOMER) {
        setTypeEntity(`${HEADER_SECTIONS.get(Sections.EDIT_CUSTOMER)}`);
      } else if (getEntityName === EntityName.BRANCH) {
        setTypeEntity(`${HEADER_SECTIONS.get(Sections.EDIT_BRANCH)}`);
      } else {
        setTypeEntity(`${HEADER_SECTIONS.get(Sections.EDIT_CUSTOMER)}`);
      }
    }
  };

  useEffect(() => {
    RESET_LOCALSTORAGE_ROUTES.includes(location.pathname as ROUTES)
      ? dispatch(resetValuesFromLocalStorage())
      : dispatch(refreshHeaderValuesFromLocalStorage());
  }, [location]);
  useEffect(() => {
    if (get(nodeInfo, "entityName", "").length > 0) {
      setEntityName(get(nodeInfo, "entityName", ""));
    }
    if (get(nodeInfo, "generalInfo.name", "").length > 0) {
      setNameGeneralInfo(get(nodeInfo, "generalInfo.name", ""));
    }
  }, [nodeInfo]);

  useEffect(() => {
    if (getEntityName.length > 0) {
      setSectionType();
    }
    const ownerId: string = get(nodeInfo, "rootId", "");

    if (ownerId) dispatch(findNodeByNodeId({ nodeId: ownerId }));
  }, [getEntityName]);

  const getMerchantPreferencesTitle = (): string => {
    if (entityName === EntityName.OWNER)
      return `${get(basicInformation, "layout.nodeName")}`;
    else
      return `${get(basicInformation, "layout.nodeName")}/${get(
        basicInformation,
        "layout.customerName",
        ""
      )}`;
  };

  useEffect(() => {
    setUserPath(currentUrl.includes(ROUTES.USERS));
  }, []);

  const validateListWebhookUrl = (url: string): boolean => {
    if (
      url.includes(ROUTES.LIST_MERCHANT_WEBHOOK) ||
      url.includes(ROUTES.LIST_NOTIFICATION_WEBHOOK)
    )
      return true;

    return false;
  };

  const getSectionName = () => {
    if (validateListWebhookUrl(currentUrl))
      return `${get(basicInformation, "layout.nodeName")}`;

    switch (currentUrl) {
      case ROUTES.CREATE_NODE_BRANCH_OPTIONS:
      case ROUTES.CREATE_NODE_BRANCH_BATCH:
      case ROUTES.CREATE_NODE_BRANCH_EDIT:
      case ROUTES.CREATE_NODE_BRANCH_SELECT_EDITION:
      case ROUTES.CREATE_NODE_BRANCH_FILE_UPLOAD:
        tooltip = ToolTipEnum.OWNER_CUSTOMER;

        return `${
          nodeName && entityName ? `${nodeName} / ${customerName}` : ""
        }`;
      case ROUTES.CONSOLE_WALLET:
        tooltip = ToolTipEnum.CUSTOMER;

        return `${nodeName && entityName ? `${nodeName} ` : ""}`;
      case ROUTES.MERCHANT_PREFERENCES:
        return getMerchantPreferencesTitle();
      case ROUTES.CREATE_MERCHANT_WEBHOOK:
        return `${get(basicInformation, "layout.nodeName")}`;
      default:
        return `${nodeName && entityName ? `${nodeName} ` : ""}`;
    }
  };

  const getNameCustomer = (): string => {
    if (currentUrl !== ROUTES.CONSOLE_WALLET) {
      return getEntityName === EntityName.BRANCH
        ? ` - ${getSectionName()} - ${getNameGeneralInfo}`
        : getEntityName === EntityName.CUSTOMER
        ? ` - ${getNameGeneralInfo}`
        : "";
    }

    return "";
  };

  const getTitleEdit = (): string => {
    const entity: string = defaultTo(customerEntityName, "").toLowerCase();
    let header: string = `Edición de ${entity.charAt(0).toUpperCase()}${entity
      .slice(1)
      .toLowerCase()}`;

    return header;
  };

  const getWebhookCreateNameAccent = (isEdit: boolean): string => {
    const defaultNameAccent = `${HEADER_SECTIONS.get(origin as Sections)}`;
    const entityName = get(basicInformation, "layout.entityName");

    switch (entityName) {
      case EntityName.OWNER:
        tooltip = ToolTipEnum.CUSTOMER;
        if (isEdit) return `${HEADER_SECTIONS.get(Sections.EDIT_CUSTOMER)}`;

        return `${HEADER_SECTIONS.get(Sections.CREATE_CUSTOMER)}`;
      case EntityName.CUSTOMER:
        tooltip = ToolTipEnum.BRANCH;
        if (isEdit) return `${HEADER_SECTIONS.get(Sections.EDIT_BRANCH)}`;

        return `${HEADER_SECTIONS.get(Sections.CREATE_BRANCH)}`;
      default:
        return defaultNameAccent;
    }
  };

  const getSectionNameAccent = () => {
    const operation: string = defaultTo(
      searchParams.get(QueryParamsEnum.OPERATION),
      ""
    );
    const actionMode = defaultTo(searchParams.get(QueryParamsEnum.MODE), "");

    if (operation === OperationEnum.EDIT) return getTitleEdit();
    if (
      actionMode === OperationEnum.MASSIVE_EDITION ||
      currentUrl.includes(pathValidateIsContainsUrl.batch)
    )
      return `${HEADER_SECTIONS.get(Sections.MASSIVE_EDITION)}`;

    if (validateListWebhookUrl(currentUrl))
      return getWebhookCreateNameAccent(false);

    if (
      [
        URLS_FOR_CUSTOMER_BRANCH.MERCHANT_BASIC_DETAILS,
        URLS_FOR_CUSTOMER_BRANCH.MERCHANT_BILLING_DETAILS,
        URLS_FOR_CUSTOMER_BRANCH.MERCHANT_LEGAL_DETAILS,
        URLS_FOR_CUSTOMER_BRANCH.MERCHANT_CONTACT_DETAILS,
        URLS_FOR_CUSTOMER_BRANCH.FINANCIAL_BILLING,
        URLS_FOR_CUSTOMER_BRANCH.FINANCIAL_DISPERSION,
        URLS_FOR_CUSTOMER_BRANCH.FINANCIAL_CHARGE,
      ].includes(currentUrl as URLS_FOR_CUSTOMER_BRANCH)
    ) {
      if (customerEntityName === EntityName.CUSTOMER)
        return `${HEADER_SECTIONS.get(Sections.CREATE_NODE)}`;

      return `${HEADER_SECTIONS.get(Sections.CREATE_BRANCH)}`;
    }

    switch (currentUrl) {
      case ROUTES.CONSOLE_WALLET_DASHBOARD:
        return HeaderEnum.WALLET_MODULE;
      case ROUTES.CONSOLE_WALLET:
        return getEntityName === EntityName.BRANCH ? getNameGeneralInfo : "";
      case ROUTES.CREATE_NODE_BRANCH_EDIT:
        return `${HEADER_SECTIONS.get(Sections.EDIT_BRANCH)}`;
      case ROUTES.CREATE_NODE_BRANCH_FILE_UPLOAD:
      case ROUTES.CREATE_NODE_BRANCH_OPTIONS:
      case ROUTES.CREATE_NODE_BRANCH_BATCH:
        return `${HEADER_SECTIONS.get(Sections.CREATE_BRANCHES)}`;
      case ROUTES.MERCHANT_PREFERENCES:
        return entityName === EntityName.CUSTOMER
          ? `${HEADER_SECTIONS.get(Sections.EDIT_BRANCHES)}`
          : `${HEADER_SECTIONS.get(Sections.EDIT_CUSTOMER)}`;
      case ROUTES.MERCHANT_RESUME:
        return typeEntity;
      case ROUTES.CREATE_NODE_BRANCH_SELECT_EDITION:
        return `${HEADER_SECTIONS.get(Sections.SELECT_EDITION_BRANCH)}`;
      case ROUTES.CREATE_MERCHANT_WEBHOOK:
        return getWebhookCreateNameAccent(true);
      default:
        return ` ${HEADER_SECTIONS.get(origin as Sections)}`;
    }
  };

  const handleOpenModal = () => {
    dispatch(openModal());
  };
  const redirectPage = (entityName: string) => {
    if (entityName === EntityName.CUSTOMER) {
      const publicMerchantId = defaultTo(
        searchParams.get(QueryParamsEnum.PUBLIC_MERCHANT_ID),
        searchParams.get(QueryParamsEnum.MERCHANT_ID)
      );

      window.location.href = MERCHANT_RESUME_CUSTOMER(publicMerchantId || "");

      return;
    }
    window.location.href = OWNER_MERCHANT_LIST_CUSTOMER(
      nodeInfo.rootId || parentNodeId || ""
    );
  };

  return {
    addCloseButton,
    fullName: auth.getFullName(),
    handleOpenModal,
    isUserPath,
    nameCustomer: getNameCustomer(),
    redirectPage,
    showAdminChip: auth.isAdmin(),
    title: getEntityName === EntityName.BRANCH ? ownerName! : getSectionName(),
    titleAccent: getSectionNameAccent(),
    tooltip,
  };
};
