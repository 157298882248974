/* istanbul ignore file */

export enum ErrorsEnum {
  REQUIRED_FIELD = "Campo requerido",
  SELECT_SERVICE = "No se encuentra activado ningún medio de pago",
  SELECT_MERCHANT = "Seleccine un comercio",
  REQUIRED_MIN_RULE = "Se requiere al menos una regla",
  MIN_4_CHARACTER = "Mínimo se requiere 4 caracteres",
  MIN_12_CHARACTER = "Mínimo se requiere 12 caracteres",
  MAX_10_CHARACTER = "Máximo se requiere 10 caracteres",
  MAX_12_CHARACTER = "Máximo se requiere 12 caracteres",
  MAX_22_CHARACTER = "Máximo se requiere 22 caracteres",
  MAX_32_CHARACTER = "Máximo se requiere 32 caracteres",
}
