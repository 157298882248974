import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const headerFiltersStyles = createNamedStyles({
  gridFilter: {
    display: "flex",
    justifyContent: "right",
  },
  gridFilterCard: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    "& .css-79elbk": {
      flexWrap: "wrap",
      width: "100%",
    },
  },
});
