import React, { FC } from "react";
import { mainSubHeaderStyles as styles } from "./Subheader.styles";
import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";

import { useLocation, useNavigate } from "react-router-dom";
import { getCountryFlag } from "../../shared/infrastructure/constants/flags/countryFlag";
import { ISubheaderProps } from "./Subheader.interfaces";
import { IconEdit } from "@kushki/connect-ui";
import { SubheaderLabels } from "./Subheader.data";
import { Copy } from "react-feather";
import { MaskSkeleton } from "../MaskSkeleton/MaskSkeleton";
import { convertCountryName } from "../../utils/utils";

export const Subheader: FC<ISubheaderProps> = ({
  title,
  country,
  redirectPath,
  id,
  isLoading,
}: ISubheaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const routeLocalStorage = { layout: { lastRoute: location.pathname } };

  const navigateToBasicInformation = () => {
    localStorage.setItem("basicInformation", JSON.stringify(routeLocalStorage));
    navigate(redirectPath);
  };

  return (
    <Box paddingTop={"32px"}>
      <MaskSkeleton sx={styles.maskTitle} isLoading={isLoading}>
        <Grid container alignItems="center" sx={styles.gridTitle}>
          <Grid item>
            <Typography
              color="text.primary"
              fontSize={26}
              variant="h1"
              fontWeight={600}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={navigateToBasicInformation}>
              <IconEdit color="primary" fontSize="medium" />
            </IconButton>
          </Grid>
        </Grid>
      </MaskSkeleton>
      <Grid container alignItems="center">
        <Grid item container xs={12} md={"auto"} alignItems="center">
          <Grid item sx={styles.gridText}>
            <MaskSkeleton sx={styles.maskCountryTitle} isLoading={isLoading}>
              <Typography variant="caption" color="text.primary">
                {SubheaderLabels.OperationCountry}
              </Typography>
            </MaskSkeleton>
          </Grid>
          <Grid item>
            <MaskSkeleton
              variant={"circular"}
              sx={styles.maskCountryFlag}
              isLoading={isLoading}
            >
              <Box>{getCountryFlag[country]}</Box>
            </MaskSkeleton>
          </Grid>
          <Grid item sx={styles.gridCountry}>
            <MaskSkeleton sx={styles.maskCountryTitle} isLoading={isLoading}>
              <Typography variant="body1" color="text.primary">
                {convertCountryName(country)}
              </Typography>
            </MaskSkeleton>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={"auto"} alignItems="center">
          <Divider orientation="vertical" flexItem sx={styles.divider} />
          <Grid item sx={styles.gridId}>
            <MaskSkeleton sx={styles.maskIdTitle} isLoading={isLoading}>
              <Typography variant="caption" color="text.primary">
                ID:{" "}
              </Typography>
            </MaskSkeleton>
          </Grid>
          <MaskSkeleton sx={styles.maskId} isLoading={isLoading}>
            <Grid item container xs={12} md={"auto"} alignItems="center">
              <Grid item>
                <Typography variant="inherit" color="text.primary">
                  {id}
                </Typography>
              </Grid>
              <Grid>
                <IconButton
                  sx={styles.copyButton}
                  onClick={() => {
                    navigator.clipboard.writeText!(id);
                  }}
                >
                  <Copy />
                </IconButton>
              </Grid>
            </Grid>
          </MaskSkeleton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Subheader;
