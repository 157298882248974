import React from "react";
import {
  Accordion,
  AccordionActions,
  AccordionSummary,
  Box,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import { boardMembersAccordionStyles as styles } from "./BoardMembers.styles";
import {
  IconChevronDown,
  IconEdit,
  IconMore,
  IconTrash,
} from "@kushki/connect-ui";
import BoardMemberContent from "./BoardMemberContent/BoardMemberContent";
import { IBoardMember } from "../../../store/interfaces/LegalDetailsState.interfaces";
import AccordionStyles from "../../Accordion/Accordion.styles";
import DropdownList from "../../DropDownList/DropdownList";
import { useBoardMemberContent } from "./BoardMemberContent/State/useBoardMemberContent";
import { IDropdownItemProps } from "../../DropDownItem/DropdownItem.interfaces";
import { useDispatch } from "react-redux";
import { setEditedBoardMember } from "../../../store/reducers/legalDetails/legalDetails";
import { get } from "lodash";
import {
  BoardMemberContentEnum,
  BoardMemberModalEnum,
} from "../../../shared/enum/BoardMemberContentEnum";

interface IBoardMemberAccordionProps {
  boardMember: IBoardMember;
  showMenuActions: boolean;
  variant: BoardMemberContentEnum;
  openDeleteModal: () => void;
  setSelectedBoardMember: (bm: IBoardMember) => void;
}

const BoardMemberAccordion: React.FC<IBoardMemberAccordionProps> = ({
  boardMember,
  showMenuActions,
  variant,
  openDeleteModal,
  setSelectedBoardMember,
}: IBoardMemberAccordionProps) => {
  const dispatch = useDispatch();
  const { anchorEl, iconRef, handleClose, handleClick, openOptionList, name } =
    useBoardMemberContent(boardMember);

  const dropdownOption: IDropdownItemProps[] = [
    {
      icon: <IconEdit />,
      id: "1",
      isDisable: false,
      onSelect: () => {
        dispatch(
          setEditedBoardMember({
            id: get(boardMember, "id", ""),
            uid: get(boardMember, "uid", ""),
            value: true,
          })
        );
      },
      text: BoardMemberModalEnum.EDIT,
      variant: "withIcon",
    },
    {
      icon: <IconTrash />,
      id: "2",
      isDisable: false,
      onSelect: () => {
        setSelectedBoardMember(boardMember);
        openDeleteModal();
      },
      text: BoardMemberModalEnum.DELETE,
      variant: "withIcon",
    },
  ];

  return (
    <Accordion sx={get(styles, `accordion${variant}`)}>
      <AccordionSummary
        expandIcon={
          <IconChevronDown
            color="primary"
            sx={get(styles, `iconAccordion${variant}`, {})}
          />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={get(styles, `summary${variant}`)}
      >
        <Box>
          <Typography variant="subtitle2">{name}</Typography>
          <Typography variant="caption" sx={styles.subtitle}>
            {BoardMemberModalEnum.CAPTION}
          </Typography>
        </Box>
        {showMenuActions && (
          <Box>
            <AccordionActions>
              <IconButton
                data-testid="menuAction"
                ref={iconRef}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClick();
                }}
              >
                <IconMore />
              </IconButton>
            </AccordionActions>
            <Popover
              data-testid={"popover"}
              sx={AccordionStyles.optionList}
              anchorOrigin={{
                horizontal: "center",
                vertical: "bottom",
              }}
              transformOrigin={{
                horizontal: "right",
                vertical: "top",
              }}
              anchorEl={anchorEl}
              open={openOptionList}
              onClose={(e: { stopPropagation: () => void }) => {
                e.stopPropagation();
                handleClose();
              }}
            >
              <DropdownList
                isOpen={openOptionList}
                items={dropdownOption}
                handleClose={handleClose}
                isSearchable={false}
                isDisabled={false}
              />
            </Popover>
          </Box>
        )}
      </AccordionSummary>
      <BoardMemberContent variant={variant} boardMember={boardMember} />
    </Accordion>
  );
};

export default BoardMemberAccordion;
