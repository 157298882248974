import { LocalStoragePathEnum } from "../../infrastructure/enums/LocalStoragePathEnum";
import { defaultTo, isEqual } from "lodash";

export const isConsoleMerchantMassive = (): boolean => {
  const consoleMerchantMassive: string = defaultTo(
    localStorage.getItem(LocalStoragePathEnum.CONSOLE_MERCHANT_MASSIVE),
    "false"
  );

  return isEqual(consoleMerchantMassive.toLowerCase(), "true");
};
