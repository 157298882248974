import { createSlice } from "@reduxjs/toolkit";
import { AppState } from "../../interfaces/AppState.interfaces";
import {
  ALERT_FAIL,
  ALERT_SUCCESS,
} from "../../../shared/constants/labels/recharge_wallet_labels";

const initialState: AppState = {
  alertErrorMessage: ALERT_FAIL,
  alertSuccessMessage: ALERT_SUCCESS,
  isAlertError: false,
  isAlertSuccess: false,
};

export const appSlice = createSlice({
  initialState,
  name: "app",
  reducers: {
    closeErrorAlert: (state) => {
      state.isAlertError = false;
    },
    closeSuccessAlert: (state) => {
      state.isAlertSuccess = false;
    },
    showErrorAlert: (state) => {
      state.isAlertError = true;
    },
    showSuccessAlert: (state) => {
      state.isAlertSuccess = true;
    },
  },
});

export default appSlice.reducer;
