import React, { useEffect, useState } from "react";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import { Box, createStyles, Grid } from "@material-ui/core";
import { ButtonDownloadFile } from "../ButtonDownloadFile/ButtonDownloadFile";
import { ButtonReprocess } from "../ButtonReprocess/ButtonReprocess";
import { makeStyles } from "@material-ui/core/styles";
import { getUserRole } from "../../shared/infrastructure/constants/UserRoleConstants";
import { UserRolesEnum } from "../../shared/infrastructure/UserRolesEnum";
import { FormatEnum } from "../../shared/infrastructure/FormatEnum";
import { useLocation } from "react-router-dom";
import { StatusFlowEnum } from "../../shared/infrastructure/StatusFlowEnum";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

const useStyles = makeStyles(() =>
  createStyles({
    reprocessButton: {
      paddingLeft: 20,
    },
    downloadButton: {
      marginLeft: "20%",
      position: "relative",
      display: "inline-block",
    },
  })
);

export interface FilterButtonSectionProps {
  country: string;
  reprocess: { reprocessNumber: number; reprocessTransactions: () => void };
  downloadFileState: {
    isLoading: boolean;
    downloadFile: (format: string) => void;
  };
}

export const FilterButtonSection: React.FC<FilterButtonSectionProps> = (
  props
) => {
  const { country, reprocess } = props;
  const classes = useStyles();
  const isBackofficeCoreAccountingExecutor: boolean = getUserRole(
    UserRolesEnum.BackofficeCoreAccountingExecutor
  );
  const isBackofficeCoreAccountingValidator: boolean = getUserRole(
    UserRolesEnum.BackofficeCoreAccountingValidator
  );
  const isAdmin: boolean = getUserRole(UserRolesEnum.BackofficeAdmin);
  const [statusFlow, setStatusFlow] = useState<string>("");
  const { pathname } = useLocation();
  const path: string[] = pathname.split("/");

  useEffect(() => {
    setStatusFlow(path[path.length - 1]);
  }, [pathname]);

  return (
    <React.Fragment>
      {country === CountryEnum.ecuador ? (
        <Grid container alignItems="flex-end">
          <Grid
            item
            xs={10}
            justify="flex-end"
            alignContent="flex-end"
            alignItems="flex-end"
          >
            <ComponentSecurityWrapper componentId={""}>
              <Box className={classes.downloadButton}>
                <ButtonDownloadFile
                  buttonV2={true}
                  options={
                    props.country === CountryEnum.ecuador
                      ? [
                          {
                            text: "Descargar",
                            value: FormatEnum.CSV,
                          },
                          {
                            text: "Descargar retenciones",
                            value: FormatEnum.CSVRETENTIONS,
                          },
                        ]
                      : [
                          {
                            text: "Descargar en .csv",
                            value: FormatEnum.CSV,
                          },
                        ]
                  }
                  isLoading={props.downloadFileState.isLoading}
                  downloadFile={props.downloadFileState.downloadFile}
                  statusFlow={statusFlow}
                  isHistoric={true}
                  isRetention={false}
                />
              </Box>
            </ComponentSecurityWrapper>
          </Grid>
          {(isBackofficeCoreAccountingExecutor ||
            isBackofficeCoreAccountingValidator ||
            isAdmin) &&
            statusFlow === StatusFlowEnum.VALIDATOR && (
              <Grid
                item
                xs={2}
                justify={"flex-end"}
                className={classes.reprocessButton}
              >
                <ButtonReprocess isLoading={false} reprocess={reprocess} />
              </Grid>
            )}
        </Grid>
      ) : (
        <Grid item xs={12} justify={"flex-end"} container>
          <ButtonDownloadFile
            buttonV2={true}
            options={
              props.country === CountryEnum.ecuador
                ? [
                    {
                      text: "Descargar todo",
                      value: FormatEnum.CSV,
                    },
                    {
                      text: "Descargar retenciones",
                      value: FormatEnum.CSVRETENTIONS,
                    },
                  ]
                : [
                    {
                      text: "Descargar en .csv",
                      value: FormatEnum.CSV,
                    },
                  ]
            }
            isLoading={props.downloadFileState.isLoading}
            downloadFile={props.downloadFileState.downloadFile}
            statusFlow={statusFlow}
            isHistoric={true}
            isRetention={false}
          />
        </Grid>
      )}
    </React.Fragment>
  );
};
