import { createWebsocket } from "../create_websockets";
import {
  GenerateCertificateWS,
  IHandleResponseWS,
} from "./generate_certificate_ws.interfaces";

export const callbackMessage =
  (
    handleResponse: (
      response: MessageEvent
    ) => IHandleResponseWS | Promise<IHandleResponseWS>,
    webSocket: WebSocket,
    resolve: (value: boolean | PromiseLike<boolean>) => void
  ) =>
  async (event: MessageEvent) => {
    const { closeConnection, messageError } = await handleResponse(event);

    if (closeConnection) {
      webSocket.close();
      resolve(!messageError);
    }
  };

export const generateCertificateWS: GenerateCertificateWS = async (
  urlWS,
  { data, handleResponse, dispatch }
) => {
  return new Promise((resolve) => {
    try {
      const webSocket = createWebsocket(urlWS);

      webSocket.addEventListener("open", () => {
        webSocket.send(JSON.stringify(data));
      });

      webSocket.addEventListener("error", () => {
        webSocket.close();
        resolve(false);
      });

      webSocket.addEventListener(
        "message",
        callbackMessage(
          (message: MessageEvent<string>) => handleResponse(message, dispatch),
          webSocket,
          resolve
        )
      );
    } catch (error) {
      resolve(false);
    }
  });
};
