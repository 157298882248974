import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { FC, useState } from "react";
import { CreateCommentModalStyles as styles } from "./CreateCommentModal.styles";
import { COMMENTS_TAB_LABELS } from "../../../shared/constants/comments_tab_labels";
import { ICreateCommentModalProps } from "./CreateCommentModal.interfaces";

const CreateCommentModal: FC<ICreateCommentModalProps> = ({
  handleClose,
  handleSave,
  isSaving,
  open,
}: ICreateCommentModalProps) => {
  const [comment, setComment] = useState("");

  return (
    <Modal open={open}>
      <Box sx={styles.boxContainer}>
        <IconButton
          data-testid="btnCloseModal"
          id="btnCloseModal"
          sx={styles.closeButton}
          onClick={() => handleClose(setComment)}
          disabled={isSaving}
        >
          <CloseIcon />
        </IconButton>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography sx={styles.heading}>
              {COMMENTS_TAB_LABELS.WRITE_COMMENT}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoFocus
              disabled={isSaving}
              fullWidth
              inputProps={{
                maxLength: 280,
                sx: styles.textField,
              }}
              label={COMMENTS_TAB_LABELS.LIMIT}
              multiline
              onChange={(e) => setComment(e.target.value)}
              rows={3}
            />
          </Grid>
          <Grid item xs={12} sx={styles.buttonRow}>
            <Button
              sx={styles.cancelButton}
              onClick={() => handleClose(setComment)}
              disabled={isSaving}
            >
              {COMMENTS_TAB_LABELS.CANCEL}
            </Button>
            <Button
              sx={styles.saveButton}
              onClick={() => handleSave(comment, setComment)}
              disabled={isSaving || comment === ""}
            >
              {COMMENTS_TAB_LABELS.SAVE}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default CreateCommentModal;
