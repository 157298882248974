export enum Section {
  COMPANY = "EMPRESA",
  FINANCIAL = "FINANCIERA",
  PROCESSING = "PROCESAMIENTO",
  PROCESSING_BUSINESS_RULES = "PROCESAMIENTO REGLAS DE NEGOCIO",
  PROCESSING_DEFERRED = "PROCESAMIENTO DIFERIDOS",
  SERVICES = "SERVICIOS",
  USERS = "USUARIOS",
  RATES_DISCOUNTS = "TARIFAS Y DESCUENTOS",
  DEVELOPERS = "DESARROLLADORES",
  WALLET = "BILLETERA",
}
