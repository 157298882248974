export enum FileExecutiveStatusEnum {
  DEFAULT = "default",
  UPLOADING = "uploading",
  WITH_FILE = "with_file",
  VALIDATING = "validating",
}

export enum FileUploadExecutiveLabel {
  LOADING_FILE = "Cargando archivo",
  VALIDATING_FILE = "Validando archivo",
  VALIDATE_BUTTON = "Validar archivo",
  DRAG_FILE = "Arrastra el documento hasta aquí",
  SELECT_FILE = "Selecciona un archivo",
  VALID_FORMAT = "Formatos válidos: .xlsx",
}

export enum MessageErrorExecutive {
  TYPE = "El formato de plantilla del archivo es incorrecto. Sube el archivo con el formato de plantilla correcto.",
  MAX_SIZE = "El archivo supera el tamaño máximo permito (5MB).",
  VALIDATION = "Corrige los errores, luego sube el archivo correcto.",
  ERROR_TITLE = "Se detectaron algunos errores",
}
