export enum LocalStoragePathEnum {
  BASIC_MERCHANT_INFORMATION = "basicMerchantInformation",
  MERCHANT_BASIC_INFORMATION = "merchantBasicInformation",
  USERNAME = "username",
  EMAIL = "email",
  BUSINESS_RULES_BATCH = "businessRulesBatch",
  PAYOUTS_BUSINESS_RULES_BATCH = "payoutsBusinessRulesBatch",
  IS_MASSIVE_RULES = "isMassiveRules",
  CONSOLE_MERCHANT_MASSIVE = "consoleMerchantMassive",
  MERCHANT_DEFERRED_DATA = "merchantDeferredData",
  CUSTOMER_BATCH_INFORMATION = "customerBatchInformation",
  USER_EMAIL = "email",
}

export enum SessionStoragePathEnum {
  BRANCH_EDIT_LIST = "branchEditList",
}
