import {
  FlagBrasil,
  FlagChile,
  FlagColombia,
  FlagCostaRica,
  FlagDefault,
  FlagEcuador,
  FlagElSalvador,
  FlagGuatemala,
  FlagHonduras,
  FlagMexico,
  FlagNicaragua,
  FlagPanama,
  FlagPeru,
  FlagUsa,
  IconEdit,
} from "@kushki/connect-ui";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { FC } from "react";
import { IGeneralInfo } from "../../../types/node_info_response";
import { CustomSkeleton } from "../commons/Skeleton/Skeleton";
import { merchantInformationStyles as styles } from "./MerchantInformation.styles";
import { CountriesEnum } from "../../shared/infrastructure/countries-enum";
import { getCountryName } from "../../shared/constants/Countries";
import { clipboard } from "../../shared/clipboard";
import { Copy } from "react-feather";
import { get } from "lodash";

//TODO: Render flag according to country
interface IMerchantInformationProps {
  isLoading?: boolean;
  generalInfo?: IGeneralInfo;
  onClick?: () => void;
}

const flagsWithComponents = {
  [CountriesEnum.ECUADOR]: FlagEcuador,
  [CountriesEnum.COLOMBIA]: FlagColombia,
  [CountriesEnum.PERU]: FlagPeru,
  [CountriesEnum.CHILE]: FlagChile,
  [CountriesEnum.MEXICO]: FlagMexico,
  [CountriesEnum.BRAZIL]: FlagBrasil,
  [CountriesEnum.EL_SALVADOR]: FlagElSalvador,
  [CountriesEnum.PANAMA]: FlagPanama,
  [CountriesEnum.COSTA_RICA]: FlagCostaRica,
  [CountriesEnum.GUATEMALA]: FlagGuatemala,
  [CountriesEnum.HONDURAS]: FlagHonduras,
  [CountriesEnum.NICARAGUA]: FlagNicaragua,
  [CountriesEnum.ESTADOS_UNIDOS]: FlagUsa,
};

export const MerchantInformation: FC<IMerchantInformationProps> = ({
  isLoading = false,
  generalInfo,
  onClick,
}) => {
  let Flag = flagsWithComponents[generalInfo?.country!] ?? FlagDefault;

  return (
    <>
      {isLoading ? (
        <Box data-testid="skeleton-container">
          <CustomSkeleton
            sx={{
              width: {
                md: "25%",
                xs: "75%",
              },
            }}
            showContainer
            variant="h3"
          />
          <CustomSkeleton
            sx={{
              width: {
                md: "50%",
                xs: "100%",
              },
            }}
            showContainer
            variant="h3"
          />
        </Box>
      ) : (
        <>
          <Grid
            container
            columnGap="16px"
            sx={{
              marginBottom: "0.5rem",
            }}
            alignItems="center"
          >
            <Typography color="text.primary" variant="h3">
              {generalInfo?.name}
            </Typography>
            <Button sx={styles.editButton}>
              <IconEdit sx={styles.iconEdit} onClick={onClick} />
            </Button>
          </Grid>

          <Grid container columnGap="10px" alignItems={"center"}>
            <Grid item>
              <Grid container columnGap="1.5rem" alignItems="center">
                <Typography variant="caption" color="text.primary">
                  {" "}
                  País de operación:{" "}
                </Typography>
                <Typography
                  component="span"
                  variant="body1"
                  color="text.primary"
                  //@ts-ignore
                  sx={styles.countryBlock}
                >
                  <Flag />
                  {getCountryName(generalInfo?.country)}
                </Typography>
              </Grid>
            </Grid>
            <Typography
              component="span"
              variant="subtitle1"
              color="text.lightGrey"
            >
              {"|"}
            </Typography>
            <Grid item>
              <Grid container columnGap="16px" alignItems="center">
                <Typography variant="caption" color="text.primary">
                  ID:
                </Typography>
                <Typography variant="body1" color="text.primary">
                  {" "}
                  {generalInfo?.publicMerchantId}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <IconButton
                sx={styles.iconEffect}
                data-testid={"IconCopy"}
                onClick={(e) => {
                  clipboard(get(generalInfo, "publicMerchantId", ""));
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Copy />
              </IconButton>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
