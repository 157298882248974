import { Icon } from "@material-ui/core";
import nonAuthorizedIcon from "../../../../assets/images/NTHRZD.svg";
import React from "react";
import { useStyles } from "../../DetailsCard.styles";

export const NTHRZDIcon = () => {
  const classes = useStyles();

  return (
    <Icon className={classes.tlIcon}>
      <img alt={"non authorized icon"} src={nonAuthorizedIcon} />
    </Icon>
  );
};
