import { CountryEnum } from "../infraestructure/enums/CountryEnum";

export const formatCurrencyWallet = (
  amount: number,
  country: string
): string => {
  const formattedAmount: string =
    country === CountryEnum.CHILE
      ? Intl.NumberFormat("es", {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
          style: "decimal",
          useGrouping: true,
        }).format(amount)
      : Intl.NumberFormat("en", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(amount);

  return Number(formattedAmount) !== 0 ? formattedAmount : "0";
};
