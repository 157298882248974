/* istanbul ignore file */

export enum PaymentMethodEnum {
  CARD = "card",
  TRANSFER = "transfer",
  ACH_TRANSFER = "ach transfer",
  TRANSFER_SUBSCRIPTIONS = "transfer-subscriptions",
}

export const PAYMENT_METHOD = new Map<string, string>([
  [PaymentMethodEnum.CARD, "Tarjeta"],
  [PaymentMethodEnum.TRANSFER, "Transferencia"],
]);
