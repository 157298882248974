import React from "react";
import { CatalogDownloadReportProps } from "./CatalogDownloadReport.interfaces";
import { AlertTriangle } from "react-feather";
import { Alert } from "@material-ui/lab";
import { useStyles, confirmColor } from "./CatalogDownloadReport.styles";
import { useCatalogDownloadReportState } from "./state/useCatalogDownloadReportState";
import { Link } from "@material-ui/core";
import { isEmpty } from "lodash";

export const CatalogDownloadReport: React.FC<CatalogDownloadReportProps> = (
  props: CatalogDownloadReportProps
) => {
  const classes = useStyles();
  const { base64Csv } = props;
  const {
    handleDownloadCsvFileSuccessMessage,
  } = useCatalogDownloadReportState();

  return (
    <Alert
      severity="warning"
      className={classes.alertInfo}
      icon={<AlertTriangle color={confirmColor} />}
    >
      Puede que existan comercios activos con la opción de catálogo configurada
      que desea eliminar.{" "}
      {isEmpty(base64Csv) ? (
        <b className={classes.alertLink}>Descarga el informe</b>
      ) : (
        <Link
          href={`data:text/csv;base64,${base64Csv}`}
          download={`${new Date().getTime()}.csv`}
          className={classes.alertLink}
          onClick={handleDownloadCsvFileSuccessMessage}
        >
          <b>Descarga el informe</b>
        </Link>
      )}{" "}
      con información de los comercios activos afectados.
    </Alert>
  );
};
