import React from "react";
import { get } from "lodash";
import {
  Box,
  IconButton,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from "@mui/material";
import { translate } from "../../shared/constants/LanguageCatatog";
import { MoreHoriz } from "@mui/icons-material";
import { getOptions } from "../../shared/constants/table_transaction_admin";
import { UpdateStatusButtonInterfaces } from "./UpdateStatusButton.interfaces";
import { UpdateStatusButtonStyles } from "./UpdateStatusButton.styles";
import { useUpdateStatusButton } from "./state/useUpdateStatusButton";
import { ChargebackRoles } from "../../shared/constants/ChargebackConstants";
import { ComponentSecurityWrapper } from "@kushki/security-wrapper";

export const UpdateStatusButton: React.FC<UpdateStatusButtonInterfaces> = (
  props: UpdateStatusButtonInterfaces
) => {
  const { id, open, handleClose, handleClick, anchorEl } =
    useUpdateStatusButton();

  return (
    <React.Fragment>
      <ComponentSecurityWrapper
        componentId={`${ChargebackRoles.M_TRANSACTIONS}.${ChargebackRoles.CHARGEBACK_CHANGE_STATUS}`}
      >
        <Box>
          <IconButton
            id={"update-status-button"}
            aria-label="update-status-button"
            data-testid={"update-status-button"}
            sx={UpdateStatusButtonStyles.iconButton}
            onClick={handleClick}
          >
            <MoreHoriz />
          </IconButton>
        </Box>
      </ComponentSecurityWrapper>
      <Popover
        id={id}
        data-testid={"popover"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
      >
        <MenuList id="split-button-menu">
          <MenuItem disabled>{"Marca como"}</MenuItem>
          {getOptions(get(props, "data.transaction_status", "")).map(
            (option) => (
              <MenuItem
                id={option.status}
                key={option.status}
                data-testid={"menu-item"}
                onClick={() =>
                  props.handleChangeChargebackStatus({
                    id: get(props, "data.transaction_reference", ""),
                    status: option.sendStatus!,
                  })
                }
              >
                <Typography sx={{ color: option.color }}>
                  {translate(get(option, "status", ""))}
                </Typography>
              </MenuItem>
            )
          )}
        </MenuList>
      </Popover>
    </React.Fragment>
  );
};

export default UpdateStatusButton;
