import { CountryEnum } from "./CountryEnum";
import { KindEnum } from "./KindEnum";
import { InvoiceModeEnum } from "./InvoiceModeEnum";
import { UserRolesEnum } from "./UserRolesEnum";
import { defaultTo, intersection, uniq } from "lodash";

export const KindCatalog = {
  [CountryEnum.ecuador]: [
    KindEnum.INVOICE,
    KindEnum.CHARGE,
    KindEnum.DISPERSION,
    KindEnum.CREDIT_NOTE,
    KindEnum.RECEIVABLE,
    KindEnum.RETENTION_EC,
  ],
  [CountryEnum.chile]: [
    KindEnum.INVOICE,
    KindEnum.CHARGE,
    KindEnum.DISPERSION,
    KindEnum.RECEIVABLE,
  ],
  [CountryEnum.peru]: [
    KindEnum.INVOICE,
    KindEnum.VOUCHER,
    KindEnum.CHARGE,
    KindEnum.DISPERSION,
    KindEnum.CREDIT_NOTE,
    KindEnum.DEBIT_NOTE,
    KindEnum.RECEIVABLE,
  ],
  [CountryEnum.colombia]: [
    KindEnum.INVOICE,
    KindEnum.CHARGE,
    KindEnum.TO_CHARGE,
    KindEnum.DISPERSION,
    KindEnum.CREDIT_NOTE,
    KindEnum.DEBIT_NOTE,
    KindEnum.RECEIVABLE,
    KindEnum.RETENTION_CO,
  ],
  [CountryEnum.mexico]: [
    KindEnum.INVOICE,
    KindEnum.CHARGE,
    KindEnum.DISPERSION,
    InvoiceModeEnum.WITHOUT_IVA,
    InvoiceModeEnum.WITH_IVA,
    KindEnum.CREDIT_NOTE,
    KindEnum.RECEIVABLE,
    KindEnum.ANNUL_INVOICE,
  ],
};

export const getKindRoleCountryCatalog = (
  country: CountryEnum,
  roles: UserRolesEnum[]
): string[] => {
  let kinds: string[] = [];
  for (const role of roles) {
    kinds.push(
      ...intersection(
        defaultTo(KindCatalog[country], []),
        defaultTo(KindRoleCatalog[role], [])
      )
    );
  }
  return uniq<string>(kinds);
};

export const KindRoleCatalog = {
  [UserRolesEnum.BackofficeCoreAccountingValidator]: [
    KindEnum.DISPERSION,
    KindEnum.CHARGE,
    KindEnum.RECEIVABLE,
    KindEnum.RETENTION,
    KindEnum.ANNUL_INVOICE,
    KindEnum.RETENTION_EC,
    KindEnum.RETENTION_CO,
  ],
  [UserRolesEnum.BackofficeCoreAccountingExecutor]: [
    KindEnum.DISPERSION,
    KindEnum.CHARGE,
    KindEnum.RECEIVABLE,
    KindEnum.RETENTION,
    KindEnum.INVOICE,
    KindEnum.ANNUL_INVOICE,
    KindEnum.RETENTION_EC,
    KindEnum.RETENTION_CO,
  ],
  [UserRolesEnum.BackofficeFinValidator]: [
    KindEnum.INVOICE,
    InvoiceModeEnum.WITHOUT_IVA,
    InvoiceModeEnum.WITH_IVA,
    KindEnum.VOUCHER,
    KindEnum.CREDIT_NOTE,
    KindEnum.DEBIT_NOTE,
    KindEnum.ANNUL_INVOICE,
  ],
  [UserRolesEnum.BackofficeFinExecutor]: [
    KindEnum.INVOICE,
    InvoiceModeEnum.WITHOUT_IVA,
    InvoiceModeEnum.WITH_IVA,
    KindEnum.VOUCHER,
    KindEnum.CREDIT_NOTE,
    KindEnum.DEBIT_NOTE,
    KindEnum.ANNUL_INVOICE,
  ],
  [UserRolesEnum.BackofficeAdmin]: [
    KindEnum.INVOICE,
    InvoiceModeEnum.WITHOUT_IVA,
    InvoiceModeEnum.WITH_IVA,
    KindEnum.VOUCHER,
    KindEnum.CREDIT_NOTE,
    KindEnum.DEBIT_NOTE,
    KindEnum.DISPERSION,
    KindEnum.CHARGE,
    KindEnum.RECEIVABLE,
    KindEnum.RETENTION_EC,
    KindEnum.RETENTION,
    KindEnum.ANNUL_INVOICE,
  ],
};

export const DISPERSION_KINDS: KindEnum[] = [
  KindEnum.CHARGE,
  KindEnum.RECEIVABLE,
  KindEnum.DISPERSION,
];

export const LEDGER_FLOW_KINDS: KindEnum[] = [
  KindEnum.DISPERSION,
  KindEnum.RECEIVABLE,
];
