import React, { FC } from "react";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { ModelStartChargebackSkeleton } from "../Skeleton/ModalStartChargebackSkeleton/ModalStartChargebackSkeleton";
import { ModalStartChargebackInterfaces } from "./ModalStartChargeback.interfaces";
import { ModalContent, TextFieldEmail } from "@kushki/connect-ui";
import {
  ModalContentButtonTypeEnum,
  ModalContentTypeEnum,
} from "@kushki/connect-ui/dist/Components/Molecules/Modal/Content/enums";
import { ModalStartChargebackLabelsEnum } from "../../shared/enums/ModalStartChargebackLabelsEnum";
import { ModalStartChargebackStyles } from "./ModalStartChargeback.styles";
import { CheckBoxOutlined } from "@mui/icons-material";
import { useModalStartChargeback } from "./state/useModalStartChargeback";
import { Controller } from "react-hook-form";
import { ItemEmail } from "@kushki/connect-ui/dist/Components/Molecules/Form/TextFieldEmail/TextFieldEmail.interfaces";
import { get, isEmpty } from "lodash";

export const ModalStartChargeback: FC<ModalStartChargebackInterfaces> = (
  props: ModalStartChargebackInterfaces
) => {
  const {
    email,
    handleAddEmail,
    handleCloseModal,
    control,
    handleSelectedEmail,
    handleSelectedCategory,
    fields,
    isButtonDisable,
    sendChargeback,
    emailLimit,
    totalSelectedEmails,
  } = useModalStartChargeback(props);

  return (
    <>
      {props.modalStartChargeback.isOpenModalChargeback && (
        <ModalContent
          buttonAction={sendChargeback}
          buttonPrimaryDisabled={isButtonDisable}
          buttonSecondaryDisabled={false}
          buttonText={"Aceptar"}
          buttonType={"primary" as ModalContentButtonTypeEnum}
          descriptionText={ModalStartChargebackLabelsEnum.MODAL_DESCRIPTION}
          isOpen={props.modalStartChargeback.isOpenModalChargeback}
          onClose={handleCloseModal}
          buttonSecondaryText={"Regresar"}
          titleText={ModalStartChargebackLabelsEnum.MODAL_TITLE}
          type={"Default" as ModalContentTypeEnum}
          informativeText={totalSelectedEmails}
        >
          <Grid>
            <Grid paddingBottom={2.5}>
              <Typography color="text.dark" variant="body1">
                {ModalStartChargebackLabelsEnum.EMAIL_TEXT}
              </Typography>
            </Grid>
            <Grid item xs={12} paddingBottom={2}>
              <Controller
                name="emailChargeback"
                control={control}
                rules={{
                  required: { message: "nada", value: true },
                }}
                defaultValue={undefined}
                render={() => (
                  <Grid>
                    <TextFieldEmail
                      placeholder={"Correo electrónico"}
                      items={email.map((em: string) => {
                        return { mail: em };
                      })}
                      mailLimit={emailLimit}
                      onItemsChange={(items: ItemEmail[]) => {
                        const emails: string[] = items.map(
                          (email: ItemEmail) => email.mail
                        );

                        handleAddEmail(emails);
                      }}
                      helperText={""}
                      autoWidth={true}
                    />
                  </Grid>
                )}
              />
            </Grid>
            <Typography
              sx={ModalStartChargebackStyles.modalHeader}
              color="text.dark"
              variant="body1"
            >
              {ModalStartChargebackLabelsEnum.EMAIL_LIST_TEXT}
            </Typography>
            {isEmpty(fields) ? (
              <ModelStartChargebackSkeleton />
            ) : (
              fields.map(
                (
                  item: Partial<Record<string, any> & Record<"id", string>>,
                  categoryIndex: number
                ) => (
                  <>
                    <Grid>
                      <FormControlLabel
                        label={item.category}
                        sx={ModalStartChargebackStyles.parentText}
                        control={
                          <Checkbox
                            id={categoryIndex.toString()}
                            checkedIcon={<CheckBoxOutlined />}
                            checked={item.status}
                            disabled={item.disabled}
                            onChange={handleSelectedCategory}
                          />
                        }
                      />
                      {item.emails.map((email: object, emailIndex: number) => (
                        // eslint-disable-next-line react/jsx-key
                        <Box sx={ModalStartChargebackStyles.checkbokContainer}>
                          <FormControlLabel
                            label={get(email, "email", "")}
                            control={
                              <Checkbox
                                id={
                                  "category-" +
                                  categoryIndex +
                                  "|email-" +
                                  emailIndex.toString()
                                }
                                disabled={get(email, "disabled", false)}
                                checked={get(email, "status", "")}
                                checkedIcon={<CheckBoxOutlined />}
                                onChange={handleSelectedEmail}
                              />
                            }
                          />
                        </Box>
                      ))}
                    </Grid>
                    <Grid sx={ModalStartChargebackStyles.gridDivider}>
                      <Divider sx={ModalStartChargebackStyles.divider} />
                    </Grid>
                  </>
                )
              )
            )}
          </Grid>
        </ModalContent>
      )}
    </>
  );
};
