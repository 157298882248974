export enum BoardMemberLabel {
  COUNTRY_NATURAL = "País de residencia:",
  COUNTRY_LEGAL = "País de domicilio:",
  DATE_NATURAL = "Nacimiento:",
  DATE_LEGAL = "Fecha de constitución de la empresa:",
  DOCUMENT = "Identificación:",
}

export enum FormatDateEnum {
  DD_MM_YYYY = "dd/MM/yyyy",
}
