import { IStyles } from "../../shared/Interfaces/Styles.interfaces";

export const shareholdersFormStyles: IStyles = {
  addButton: {
    textTransform: "none",
    mt: 3,
    "&.Mui-disabled": {
      bgcolor: "#8099B2 !important",
      color: "white !important",
    },
  },
  editButton: {
    textTransform: "none",
  },
  deleteButton: {
    textTransform: "none",
    color: "#D62C4B",
    mt: 1,
  },
  summaryDeleteButton: {
    color: "#293036",
    border: "1px solid #CBD5E0",
    textTransform: "none",
  },
  boxSummary: {
    bgcolor: "#F7FAFC",
    borderRadius: 1,
    width: 1,
    py: 3,
    px: 1.75,
  },
  boxSummaryButtons: {
    display: "flex",
    justifyContent: "flex-end",
    columnGap: 2,
    pt: 5,
  },

  boxContainer: {
    bgcolor: "white",
    borderRadius: 0.5,
    boxShadow:
      "0px 1px 4px rgba(2, 51, 101, 0.08), 0px 0px 0px rgba(14, 30, 46, 0.1), 0px 1px 2px rgba(14, 30, 46, 0.2)",
    p: 3,
  },
  title: {
    color: "primary.main",
    fontWeight: 500,
    fontSize: 20,
  },
  subtitle: {
    color: "#6D7781",
    fontWeight: 400,
    fontSize: 15,
  },
  divider: {
    my: 1,
    border: "0px solid #F0F4F9",
  },
  summaryLabel: {
    color: "#023365",
    fontSize: 16,
    fontWeight: 500,
  },
  summaryValue: {
    color: "#293036",
    fontSize: 15,
    fontWeight: 400,
  },
};
