import React from "react";
import { Button, Container, Grid } from "@mui/material";
import { IconChevronLeft, ModalLoader, ModalSimple } from "@kushki/connect-ui";
import { FormProvider } from "react-hook-form";
import { LabelEnum } from "../../shared/constants/labels/rule_labels";
import { RuleComponent } from "../../components/RuleComponent/RuleComponent";
import { useCreateBusinessRuleState } from "./state/useCreateBusinessRule";
import { ActionButtons } from "../../components/ActionButtons/ActionButtons";

export const CreateBusinessRule: React.FC = () => {
  const {
    actions,
    fields,
    form,
    loadingModal,
    saveRuleModal,
    editRule,
    isMassive,
  } = useCreateBusinessRuleState();

  return (
    <React.Fragment>
      <Container fixed>
        <Button
          onClick={actions.handleBack}
          startIcon={
            <IconChevronLeft className={"buttonLink"} fontSize="medium" />
          }
          className={"buttonLink"}
          color="primary"
        >
          {LabelEnum.BACK}
        </Button>
        <FormProvider {...form}>
          <Grid container>
            <RuleComponent
              actions={actions}
              fields={fields}
              isMassive={isMassive}
            />
            <ActionButtons
              editRule={editRule}
              fields={fields}
              onAddRule={form.handleSubmit(actions.handleSaveRuleEnable)}
              onRedirectPath={actions.handleRedirectPath}
              onSaveDraft={form.handleSubmit(actions.handleSaveRuleDraft)}
              isMassive={isMassive}
            />
          </Grid>
        </FormProvider>
        <ModalLoader
          descriptionText=" "
          titleText={loadingModal.message}
          isOpen={loadingModal.isLoading}
        />
        <ModalSimple
          buttonPrimary={saveRuleModal.buttonPrimaryText}
          buttonSecondary={saveRuleModal.buttonSecondary}
          descriptionText={saveRuleModal.descriptionText}
          onClickPrimary={() => actions.handlePrimaryButtonRuleModal()}
          onClickSecondary={() => actions.handleSecondaryButtonRuleModal()}
          onClose={() => actions.handleSecondaryButtonRuleModal()}
          titleText={saveRuleModal.titleText}
          typeModal={1}
          buttonPrimaryDisabled={false}
          isOpen={saveRuleModal.isOpen}
        />
      </Container>
    </React.Fragment>
  );
};
