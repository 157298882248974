import React from "react";
import { Box, Typography } from "@mui/material";
import { companyInformationStyles } from "./CompanyInformation.styles";
import { ICompanyInformationProps } from "./CompanyInformation.interfaces";
import CompanyInformationForm from "../CompanyInformationForm/CompanyInformationForm";

const CompanyInformation: React.FC<ICompanyInformationProps> = ({
  control,
  errors,
  setValue,
}) => {
  return (
    <Box sx={companyInformationStyles.boxContainer}>
      <Box sx={companyInformationStyles.boxTitle}>
        <Typography sx={companyInformationStyles.title}>
          Información de la empresa
        </Typography>
        <Typography sx={companyInformationStyles.subtitle}>
          Edita el CIIU, MCC.
        </Typography>
      </Box>
      <Box sx={companyInformationStyles.boxContainerForm}>
        <CompanyInformationForm
          form={{
            control,
            errors,
            setValue,
          }}
        />
      </Box>
    </Box>
  );
};

export default CompanyInformation;
