import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import {
  getAdminMerchant,
  getProcessors,
  getSmartlinks,
} from "../../../store/thunks/webhook/webhook.thunks";
import {
  ICheckboxItem,
  IUsePaymentMethodCheckList,
  IWebhookEvent,
} from "../interfaces";
import { defaultTo, get } from "lodash";
import { useFormContext, useWatch } from "react-hook-form";
import { IWebhookForm } from "../../../store/interfaces/webhook.interfaces";
import {
  getAvailableProducts,
  getAvailableServices,
  processorIsPaymentMethod,
} from "../../../shared/utils/webhookEvents";
import { ProcessorPaymentMethod } from "../../../../types/processor_payment_method";
import { ServiceOptionEnum } from "../enums";

export const usePaymentMethodCheckList = (): IUsePaymentMethodCheckList => {
  const dispatch = useAppDispatch();

  const {
    getValues,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext<IWebhookForm>();
  const options = useWatch({ name: "options" }) as string[];

  const [productOptions, setProductOptions] = useState<ICheckboxItem[]>([]);
  const [payinOptions, setPayinOptions] = useState<ICheckboxItem[]>([]);
  const [payoutOptions, setPayoutOptions] = useState<ICheckboxItem[]>([]);
  const [additionalServiceOptions, setAdditionalServiceOptions] = useState<
    ICheckboxItem[]
  >([]);
  const [merchantOptions, setMerchantOptions] = useState<string[]>([]);
  const [areAllOptionsSelected, setAreAllOptionsSelected] =
    useState<boolean>(false);
  const [isEmptyProcessor, setIsEmptyProcessor] = useState<boolean>(false);

  const {
    webhookActions: {
      payMethod: {
        processors,
        smartlink: merchantSmartlink,
        vpos: merchantVPOS,
      },
    },
    nodeInfo,
  } = useAppSelector((state) => ({
    ...state.webhook,
  }));

  useEffect(() => {
    if (nodeInfo.merchantId) {
      dispatch(getProcessors(get(nodeInfo, "merchantId", "")));
      dispatch(getAdminMerchant(get(nodeInfo, "merchantId", "")));
      dispatch(getSmartlinks(get(nodeInfo, "merchantId", "")));
    }
  }, [nodeInfo]);

  useEffect(() => {
    const smartlinkActive: boolean = get(merchantSmartlink, "status", false);
    const processorsList: ProcessorPaymentMethod[] = defaultTo(processors, []);
    const subscriptionsActive: boolean = processorIsPaymentMethod(
      processorsList,
      ServiceOptionEnum.CARD
    );
    const vposActive: boolean = get(merchantVPOS, "active", false);

    const products: ICheckboxItem[] = getAvailableProducts(
      smartlinkActive,
      vposActive,
      subscriptionsActive
    );

    setProductOptions(products);
  }, [merchantSmartlink, merchantVPOS, processors]);

  useEffect(() => {
    if (processors) {
      const { payinOpt, payoutOpt, merchantOpt, additionalOpt } =
        getAvailableServices(processors);

      setPayinOptions(payinOpt);
      setPayoutOptions(payoutOpt);
      setMerchantOptions(merchantOpt);
      setAdditionalServiceOptions(additionalOpt);

      if (!processors.length) setIsEmptyProcessor(true);
    }
  }, [processors]);

  useEffect(() => {
    clearErrors("options");

    const optionsCount: number =
      payinOptions.length +
      payoutOptions.length +
      productOptions.length +
      additionalServiceOptions.length;

    if (
      options.includes(ServiceOptionEnum.CHARGEBACK) &&
      !options.includes(ServiceOptionEnum.CHARGEBACK_VOID)
    )
      setValue("options", [...options, ServiceOptionEnum.CHARGEBACK_VOID]);

    setAreAllOptionsSelected(
      optionsCount === options.length && optionsCount > 0
    );
  }, [options]);

  const addOrRemovePaymentOption = (value: string): void => {
    const { options: currentOptions, events: currentEvents } = getValues();
    const isExistingValue: boolean = currentOptions.indexOf(value) > -1;

    if (isExistingValue) {
      const updatedEvents: IWebhookEvent = currentEvents.filter(
        (e: IWebhookEvent[number]) => e.service !== value
      );

      setValue("events", updatedEvents);
      setValue(
        "options",
        currentOptions.filter((opt: string) => opt !== value)
      );
    } else {
      setValue("options", [...currentOptions, value]);
    }
  };

  const handleChange = (value: string): void => {
    if (value === ServiceOptionEnum.CHARGEBACK)
      addOrRemovePaymentOption(ServiceOptionEnum.CHARGEBACK_VOID);

    addOrRemovePaymentOption(value);
  };

  const handleSelectAll = (): void => {
    const { options: currentOptions } = getValues();
    const optionsCount: number =
      payinOptions.length +
      payoutOptions.length +
      productOptions.length +
      additionalServiceOptions.length;

    const areAllSelected: boolean = optionsCount === currentOptions.length;

    if (areAllSelected) {
      setValue("options", []);
      setValue("events", []);
    } else {
      const productValues: string[] = productOptions.map(
        ({ value }) => value as string
      );

      setValue("options", [...merchantOptions, ...productValues]);
    }
  };

  return {
    areAllOptionsSelected,
    errors,
    handler: {
      handleChange,
      handleSelectAll,
    },
    isEmptyProcessor,
    otherOptions: additionalServiceOptions,
    payinOptions,
    payoutOptions,
    productOptions,
    selectedOptions: options,
  };
};
