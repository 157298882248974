import { SvgIconProps, SvgIcon } from "@mui/material";
import React, { FC } from "react";

const ApprovedTransactionIcon: FC<SvgIconProps> = ({ sx, ...rest }) => (
  <SvgIcon
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ ...sx }}
    {...rest}
  >
    <rect width="32" height="32" rx="4" fill="#B7FFEE" fillOpacity="0.2" />
    <path
      fill="none"
      d="M23.5 15.3104V16.0004C23.4991 17.6177 22.9754 19.1914 22.007 20.4868C21.0386 21.7821 19.6775 22.7297 18.1265 23.1883C16.5756 23.6469 14.9179 23.5918 13.4008 23.0313C11.8838 22.4708 10.5885 21.435 9.70822 20.0782C8.82795 18.7214 8.40984 17.1164 8.51625 15.5026C8.62267 13.8888 9.2479 12.3526 10.2987 11.1232C11.3495 9.89375 12.7696 9.03692 14.3471 8.6805C15.9247 8.32407 17.5752 8.48714 19.0525 9.14539"
      stroke="#0DC298"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      d="M23.5 10L16 17.5075L13.75 15.2575"
      stroke="#0DC298"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect width="32" height="32" rx="4" fill="#D2FFF4" fillOpacity="0.3" />
  </SvgIcon>
);

export default ApprovedTransactionIcon;
