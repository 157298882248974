export const SIDEBAR = "sidebar";
export const SUB_MERCHANT = "Subcomercio";
export const OPEN_MODAL = "abrir modal";
export const M_CLIENTS = "M_CLIENTS";

export enum ComponentIdsEnum {
  M_CLIENTS_SAVED = "M_CLIENTS.Datosempresa.Guardar",
  M_CLIENTS_CONTINUE = "M_CLIENTS.Datosempresa.Continuar",
  M_CLIENTS_EDIT = "M_CLIENTS.Datosempresa.Editar",
  M_CLIENTS_ADD_SHAREHOLDER_BUTTON = "M_CLIENTS.Datosempresa.AgregarAccionista",
  M_CLIENTS_ADD_SHAREHOLDER_MODAL_BUTTON = "M_CLIENTS.Datosempresa.AgregarAccionista.Agregar",
  M_CLIENTS_ADD_LEGAL_REPRESENTATIVE_BUTTON = "M_CLIENTS.Datosempresa.AgregarRepresentanteLegal",
  M_CLIENTS_ADD_LEGAL_REPRESENTATIVE_MODAL_BUTTON = "M_CLIENTS.Datosempresa.AgregarRepresentanteLegal.Agregar",
  M_CLIENTS_ADD_SUB_SHAREHOLDER_BUTTON = "M_CLIENTS.Datosempresa.AgregarSubAccionista",
  M_CLIENTS_ADD_CONTACT_PERSON = "M_CLIENTS.Datosempresa.PersonaContacto",
  M_CLIENTS_BRANCHEDIT_GUARDAR = "M_CLIENTS.BRANCHEDIT.GUARDAR",
  M_CLIENTS_BRANCHEDIT_FINALIZAREDICION = "M_CLIENTS.BRANCHEDIT.FINALIZAREDICION",
}
