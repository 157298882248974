import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { LabelsEnum } from "../../shared/enum/LabelsEnum";
import { Flag } from "../Flag/Flag";
import { CountryEnum } from "../../shared/countryEnum";
import React from "react";
import { useStyles as styles } from "../../containers/BillingDataMassive/BillingDataContainer.styles";

export interface BillingDataComponentProps {
  constitutionalCountry: CountryEnum;
  subtitleCountry: string;
  countryElement: JSX.Element;
  isEdit?: boolean;
}

const BillingDataComponent: React.FC<BillingDataComponentProps> = (
  props: BillingDataComponentProps
) => {
  const classes = styles();

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <Box className={classes.boxContent2}>
            <Typography className={classes.titleBilling}>
              {LabelsEnum.BILLING_DATA}
            </Typography>
            <Divider className={classes.divider} />
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Typography className={classes.subTitleCountry}>
                  {LabelsEnum.COUNTRY_CONSTITUTION}
                </Typography>
              </Grid>
              <Grid item alignItems="center">
                <Box className={classes.countryFlag}>
                  <Flag country={props.constitutionalCountry} />
                </Box>
              </Grid>
              <Grid item alignItems="center">
                <Typography className={classes.subTitleCountry}>
                  {props.subtitleCountry}
                </Typography>
              </Grid>
            </Grid>
            <Typography className={classes.subtitle}>
              {LabelsEnum.BILLING_INFORMATION}
            </Typography>
            {props.countryElement}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default BillingDataComponent;
