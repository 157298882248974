import { EntityNameEnum } from "../enums/EntityNameEnum";

export interface ITextByEntityNameResponse {
  rangeDateText: string;
  searchText: string;
  tooltipSearchText: string;
}

export const CatalogTextByEntityName: Record<
  string,
  ITextByEntityNameResponse
> = {
  [EntityNameEnum.CUSTOMER]: {
    rangeDateText: "Buscar por fecha de modificación",
    searchText: "Buscar...",
    tooltipSearchText: "Buscar",
  },
  [EntityNameEnum.BRANCH]: {
    rangeDateText: "Buscar por fecha de modificación",
    searchText: "Buscar por nombre de Branch o Branch ID",
    tooltipSearchText: "Buscar por nombre de Branch o Branch ID",
  },
};
