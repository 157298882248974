import { defaultTo, get, isEmpty, isEqual, map, set } from "lodash";
import {
  IFilterTableComponent,
  IUseFilterDataTableComponent,
} from "../../../shared/infrastructure/interfaces/IGlobalTableComponent";
import { ItemCategoryItem, ItemCategoryProps } from "@kushki/connect-ui";
import { LabelEnum } from "../../../shared/infrastructure/enums/LabelEnum";
import { SearchRuleRequest } from "../../../../types/search_rule_request";
import { format, parse } from "date-fns";
import { es } from "date-fns/locale";
import { useDispatch, useSelector } from "react-redux";
import {
  DateFormatStringEnum,
  FilterEnum,
  FiltersGroup,
  FiltersRequestName,
  SelectorTypeEnum,
  TextSearchModeEnum,
} from "../../../shared/infrastructure/enums/FilterEnum";
import { IAppState, INITIAL_STATE } from "../../../store/reducer";
import { useEffect, useState } from "react";
import { setSelectedFilter } from "../../../store/actionCreators";
import { TOnChangeFilters } from "@kushki/connect-ui/dist/Components/Molecules/Filters/FilterDetailBar/FilterDetailBar.interfaces";
import { StatusEnum } from "../../../shared/infrastructure/enums/StatusEnum";
import {
  FilterPathsEnum,
  ItemCategoryPathsEnum,
} from "../../../shared/infrastructure/enums/PathsEnum";
import { SelectChangeEvent } from "@mui/material";
import { SubTypeEnum } from "../../../shared/infrastructure/enums/CreateRuleConstans";
import { FILTER_COUNTRIES_MAP } from "../../../shared/infrastructure/enums/CountryEnum";
import { getAvailableConditionsFilter } from "../../../utils/utilsFile";
import {
  TEXT_TRANSFORM_MODAL,
  ValueEnum,
} from "../../../shared/infrastructure/enums/ModalEnum";
import { useFilterSearch } from "../../../shared/hooks/filter-search/useFilterSearch";

export const useFilterTableComponentState = (
  props: IFilterTableComponent
): IUseFilterDataTableComponent => {
  const { updateFilter, updateRange, filterSearch } = useFilterSearch();
  const dispatch = useDispatch();
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const defaultFilters: SearchRuleRequest = {
    from: "",
    to: "",
    limit: 0,
    offset: 0,
  };
  const todayDate = new Date();
  const defaultCurrentDate: string = format(
    todayDate,
    DateFormatStringEnum.PATTERN_ddMMMyyyy
  );
  const [categoryItems, setCategoryItems] = useState<ItemCategoryProps[]>();
  const configurationResponse = useSelector(
    (state: IAppState) => state.configuration
  );
  const goBack: boolean | undefined = useSelector(
    (state: IAppState) => state.goBack
  );
  const selected: ItemCategoryProps[] | undefined = useSelector(
    (state: IAppState) => state.selectedFilter
  );
  const [counter, setCounter] = useState(0);
  const [commerceSearchText, setCommerceSearchText] = useState<string>("");
  const [action, setAction] = useState<string>("");
  const [ruleName, setRuleName] = useState<string>("");
  const [hierarchyMode, setHierarchyMode] = useState<string>(
    TextSearchModeEnum.WITHOUT_HIERARCHY
  );

  const addFilters = (
    searchRuleRequest: SearchRuleRequest,
    itemCategoryProps: ItemCategoryProps
  ): void => {
    const filters: string[] = [];
    itemCategoryProps.items.forEach((item: ItemCategoryItem) => {
      filters.push(item.value);
    });
    set(
      searchRuleRequest,
      FiltersRequestName[get(itemCategoryProps, "title", "")].filterPath,
      filters.join("|")
    );
  };

  const getFilterItem = (filterEnum: FilterEnum): ItemCategoryProps => {
    return {
      isDisabled:
        filterEnum === FilterEnum.LABEL &&
        props.tabName !== LabelEnum.WHITE_LISTS &&
        true,
      isMinimize: true,
      items: map(getFilterInfo(filterEnum), (item) => {
        let currentValue: string = get(item, "name", "");
        let currentSelected: boolean = false;
        if (isEqual(filterEnum, FilterEnum.LABEL))
          currentValue = get(item, "field", "");

        if (
          isEqual(
            FiltersRequestName[filterEnum].selectorType,
            SelectorTypeEnum.RULE_STATUS
          )
        )
          currentValue = getStatusFilter(currentValue);

        if (isEqual(filterEnum, FilterEnum.COUNTRY))
          currentValue = FILTER_COUNTRIES_MAP[currentValue];

        if (!isEmpty(selected)) {
          currentSelected = selected!.some((obj: ItemCategoryProps) => {
            return obj.items.some(
              (itemSelected): boolean => itemSelected.label === item.title!
            );
          });
        }

        return {
          label: item.title!,
          selected: currentSelected,
          value: currentValue,
        };
      }),
      onItemsSelect: () => {},
      ...(filterEnum === FilterEnum.RULE_NAME && {
        onChangeTextField: (searchText) => {
          setRuleName(searchText);
        },
      }),
      placeHolder:
        filterEnum === FilterEnum.COUNTRY
          ? LabelEnum.PLACEHOLDER_FILTER_COUNTRY
          : filterEnum === FilterEnum.RULE_NAME
          ? LabelEnum.PLACEHOLDER_RULE_NAME
          : filterEnum,
      selectType:
        filterEnum === FilterEnum.SUB_TYPE ||
        filterEnum === FilterEnum.TYPE ||
        filterEnum === FilterEnum.GENERAL_ACTION
          ? "single"
          : "multiple",
      type: FiltersRequestName[filterEnum].categoryItem,
      title: filterEnum,
    };
  };

  const getFilterInfo = (filterEnum: FilterEnum) => {
    if (isEqual(filterEnum, FilterEnum.LABEL))
      return getAvailableConditionsFilter({
        action: action,
        conditions: get(
          configurationResponse,
          FiltersRequestName[filterEnum].selectorType,
          []
        ),
        tabName: props.tabName,
      });

    return get(
      configurationResponse,
      FiltersRequestName[filterEnum].selectorType,
      []
    );
  };

  const getStatusFilter = (status: string): string => {
    switch (status) {
      case StatusEnum.ENABLE:
        return StatusEnum.RULES_ENABLED;
      case StatusEnum.DISABLE:
        return StatusEnum.RULES_DISABLED;
      default:
        return status;
    }
  };

  const getFilters = (): ItemCategoryProps[] => {
    switch (props.tabName) {
      case LabelEnum.PENDING_REVIEW:
        return [
          getFilterItem(FilterEnum.CONFIGURATION_TYPE),
          getFilterItem(FilterEnum.SUB_TYPE),
          getFilterItem(FilterEnum.ENTITY_NAME),
          getFilterItem(FilterEnum.GENERAL_ACTION),
          getFilterItem(FilterEnum.COUNTRY),
        ];
      case LabelEnum.FIRST_LEVEL_RULES:
        return [
          getFilterItem(FilterEnum.RULE_NAME),
          getFilterItem(FilterEnum.GENERAL_ACTION),
          getFilterItem(FilterEnum.LABEL),
          getFilterItem(FilterEnum.STATUS),
          getFilterItem(FilterEnum.TYPE),
          getFilterItem(FilterEnum.ENTITY_NAME),
          getFilterItem(FilterEnum.COUNTRY),
        ];
      case LabelEnum.REJECTED_APPLICATIONS: {
        return [
          getFilterItem(FilterEnum.CONFIGURATION_TYPE),
          getFilterItem(FilterEnum.SUB_TYPE),
          getFilterItem(FilterEnum.ENTITY_NAME),
          getFilterItem(FilterEnum.GENERAL_ACTION),
          getFilterItem(FilterEnum.COUNTRY),
        ];
      }
      case LabelEnum.WHITE_LISTS:
        return [
          getFilterItem(FilterEnum.STATUS),
          getFilterItem(FilterEnum.SUB_TYPE),
          getFilterItem(FilterEnum.ENTITY_NAME),
          getFilterItem(FilterEnum.COUNTRY),
          getFilterItem(FilterEnum.LABEL),
        ];
      default:
        return [];
    }
  };

  const handleDateChange = (startDate: string, finishDate: string): void => {
    const search: SearchRuleRequest = {
      ...defaultTo(props.searchRuleRequest, defaultFilters),
    };
    const startDateToFormat = parse(
      startDate,
      DateFormatStringEnum.PATTERN_ddMMMyyyy,
      new Date(),
      {
        locale: es,
      }
    );
    const formattedStartDate = `${format(
      startDateToFormat,
      DateFormatStringEnum.PATTERN_yyyyMMdd
    )}T00:00:00`;
    const finishDateToFormat = parse(
      finishDate,
      DateFormatStringEnum.PATTERN_ddMMMyyyy,
      new Date(),
      {
        locale: es,
      }
    );
    const formattedFinishDate = `${format(
      finishDateToFormat,
      DateFormatStringEnum.PATTERN_yyyyMMdd
    )}T23:59:59`;

    set(search, "from", formattedStartDate);
    set(search, "to", formattedFinishDate);

    updateRange(startDateToFormat, finishDateToFormat);
  };

  const setRequestFilter = (selected: ItemCategoryProps[]) => {
    if (selected.length > 0) {
      dispatch(setSelectedFilter(selected));
      const search: SearchRuleRequest = {
        ...defaultTo(props.searchRuleRequest, defaultFilters),
      };
      selected.forEach((item: ItemCategoryProps) => {
        if (FiltersGroup.find((name: string) => name === item.title)) {
          addFilters(search, item);
        }
      });
      set(search, "filter.rule_name", ruleName);
      if (
        props.tabName === LabelEnum.WHITE_LISTS &&
        isEmpty(get(search, FilterPathsEnum.TYPE))
      )
        set(search, FilterPathsEnum.TYPE, `${SubTypeEnum.WHITELIST}`);

      if (
        props.tabName === LabelEnum.REJECTED_APPLICATIONS &&
        isEmpty(get(search, FilterPathsEnum.STATUS))
      ) {
        set(search, FilterPathsEnum.STATUS, `${StatusEnum.REJECTED}`);
      }

      updateFilter({ ...search.filter });
    }
  };

  const changeFilter = (change: TOnChangeFilters) => {
    const search: SearchRuleRequest = {
      ...filterSearch.request,
    };

    FiltersGroup.find((name: string) => {
      if (name === change.affected.title) {
        const filter: [] = get(search, FiltersRequestName[name].filterPath, [])
          .split("|")
          .filter((item: any) => item !== change.affected.itemDeleted.value);
        set(search, FiltersRequestName[name].filterPath, filter.join("|"));
        const updatedFilterList = selected!.map((category) => {
          if (category.title === change.affected.title) {
            category.items = category.items.filter(
              (item) => item.label !== change.affected.itemDeleted.label
            );
          }

          return category;
        });

        dispatch(setSelectedFilter(updatedFilterList));
      }
    });

    if (
      props.tabName === LabelEnum.FIRST_LEVEL_RULES &&
      isEmpty(get(search, FilterPathsEnum.STATUS))
    ) {
      set(
        search,
        FilterPathsEnum.STATUS,
        `${StatusEnum.RULES_ENABLED}|${StatusEnum.RULES_DISABLED}`
      );
    }

    if (
      props.tabName === LabelEnum.FIRST_LEVEL_RULES &&
      isEmpty(get(search, FilterPathsEnum.TYPE))
    )
      set(
        search,
        FilterPathsEnum.TYPE,
        `${SubTypeEnum.SECURITY_COUNTRY}|${SubTypeEnum.SECURITY_COMMERCE}`
      );

    updateFilter({ ...search.filter });
  };

  const setDisableAction = (categoryItems: ItemCategoryProps[]): void => {
    setCounter(2);
    const data: ItemCategoryProps[] = [...categoryItems];
    const indexType: number = data.findIndex(
      (item: ItemCategoryProps) =>
        item.title === FilterEnum.SUB_TYPE || item.title === FilterEnum.TYPE
    );
    const indexEntityName: number = data.findIndex(
      (item: ItemCategoryProps) => item.title === FilterEnum.ENTITY_NAME
    );
    const indexLabel: number = data.findIndex(
      (item: ItemCategoryProps) => item.title === FilterEnum.LABEL
    );
    const indexGeneralAction: number = data.findIndex(
      (item: ItemCategoryProps) => item.title === FilterEnum.GENERAL_ACTION
    );
    set(data[indexType], "onItemsSelect", (item: ItemCategoryItem[]) =>
      disableFilterEntityName(item, indexEntityName)
    );
    set(data[indexGeneralAction], "onItemsSelect", (item: ItemCategoryItem[]) =>
      disableFilterEntityName(item, indexLabel)
    );
  };

  const disableFilterEntityName = (
    item: ItemCategoryItem[],
    indexEntityName: number
  ): void => {
    const data: ItemCategoryProps[] = [...categoryItems!];
    if (!isEmpty(item)) {
      if (
        item[0].label === LabelEnum.FILTER_COUNTRY_LABEL &&
        item[0].selected
      ) {
        data[indexEntityName].items.forEach((filter) => {
          set(filter, ItemCategoryPathsEnum.IS_DISABLED, true);
          set(filter, ItemCategoryPathsEnum.SELECTED, false);
        });
        setCategoryItems(data);
        return;
      }
      if (
        (item[0].label === TEXT_TRANSFORM_MODAL[ValueEnum.AUTHENTICATE] ||
          item[0].label === TEXT_TRANSFORM_MODAL[ValueEnum.VALIDATE]) &&
        item[0].selected
      ) {
        setAction(item[0].label);
        if (indexEntityName !== -1) {
          set(data[indexEntityName], ItemCategoryPathsEnum.IS_DISABLED, false);
          setCategoryItems(data);
        }
        return;
      }

      if (
        item[0].label === LabelEnum.FILTER_COMMERCE_LABEL &&
        item[0].selected
      ) {
        data[indexEntityName].items.forEach((filter) => {
          set(filter, ItemCategoryPathsEnum.IS_DISABLED, false);
        });
        setCategoryItems(data);
      }
    } else {
      if (indexEntityName !== -1) {
        data[indexEntityName].items.forEach((filter) => {
          set(filter, ItemCategoryPathsEnum.IS_DISABLED, false);
          set(filter, ItemCategoryPathsEnum.SELECTED, false);
        });
        set(data[indexEntityName], ItemCategoryPathsEnum.IS_DISABLED, true);
        setCategoryItems(data);
      }
    }
  };

  const handleHierarchyModeChange = (event: SelectChangeEvent): void => {
    setHierarchyMode(event.target.value);
  };

  useEffect(() => {
    if (!isEmpty(filterSearch.request.from)) {
      const fromDate = new Date(filterSearch.request.from);
      const toDate = new Date(filterSearch.request.to);

      setFrom(format(fromDate, DateFormatStringEnum.PATTERN_ddMMMyyyy));
      setTo(format(toDate, DateFormatStringEnum.PATTERN_ddMMMyyyy));
    }
  }, [filterSearch.request.from, filterSearch.request.to]);

  useEffect(() => {
    if (!isEmpty(commerceSearchText))
      props.handleDataChange(
        commerceSearchText,
        isEqual(hierarchyMode, TextSearchModeEnum.WITH_HIERARCHY)
      );
  }, [hierarchyMode]);

  useEffect(() => {
    if (!isEqual(configurationResponse, INITIAL_STATE.configuration)) {
      setCategoryItems(getFilters());
      setCounter(1);
    }
  }, [configurationResponse]);

  useEffect(() => {
    if (!isEqual(configurationResponse, INITIAL_STATE.configuration)) {
      setCounter(1);
    }
  }, [action]);

  useEffect(() => {
    if (categoryItems && counter === 1) {
      setDisableAction(categoryItems);
    }
  }, categoryItems);

  useEffect(() => {
    if (goBack) setRequestFilter(selected!);
  }, [goBack]);

  return {
    categoryItems,
    changeFilter,
    defaultCurrentDate,
    from,
    handleDateChange,
    handleHierarchyModeChange,
    hierarchyMode,
    setCommerceSearchText,
    setRequestFilter,
    to,
  };
};
