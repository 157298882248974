import { ItemCategoryItem } from "@kushki/connect-ui/dist/Components/Molecules/Filters";
import { EntityNameEnum, EntityNameEnumChips } from "../enums/EntityNameEnum";

export const itemCategoryItems: ItemCategoryItem[] = [
  {
    label: EntityNameEnumChips.OWNER,
    selected: false,
    value: EntityNameEnum.NA,
  },
  {
    label: EntityNameEnumChips.CUSTOMER,
    selected: false,
    value: EntityNameEnum.CUSTOMER,
  },
  {
    label: EntityNameEnumChips.BRANCH,
    selected: false,
    value: EntityNameEnum.BRANCH,
  },
];
