import * as React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { ChipTag } from "../../commons/Chip/Chip";
import { ITitleHeader } from "./ITitleHeader.interface";

const TitleHeader = (props: ITitleHeader) => {
  const chipType = props.type === "Principal" ? "success" : "primary";

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
      }}
    >
      <Box
        sx={{
          alignSelf: "center",
          flexShrink: 1,
          paddingRight: "30px",
        }}
      >
        <Typography color="text.dark" variant="subtitle2">
          {props.name}
        </Typography>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <ChipTag text={props.type} type={chipType} />
      </Box>
      <Box
        sx={{
          alignSelf: "start",
          height: "0px",
          width: "100%",
        }}
      >
        <Typography color="text.grey" variant="overline">
          {props.role}
        </Typography>
      </Box>
    </Box>
  );
};

export default TitleHeader;
