import React from "react";
import { Divider, Grid, Skeleton } from "@mui/material";
import { mainSubHeaderStyles as styles } from "../../SubHeader/SubHeader.styles";

export const SubHeaderMerchantSkeleton: React.FC = () => {
  return (
    <Grid
      spacing={2}
      alignItems="center"
      container
      data-testid="sub-header-merchant-skeleton"
    >
      <Grid container alignItems="center" sx={styles.gridTitle} spacing={1}>
        <Grid item style={{ paddingLeft: 8, paddingTop: 8 }}>
          <Skeleton variant="rectangular" width={260} height={30} />
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item container xs={12} md={"auto"} alignItems="center">
          <Grid item sx={styles.gridText}>
            <Skeleton variant="rectangular" width={110} height={20} />
          </Grid>
          <Grid item>
            <Skeleton variant="circular" width={25} height={25} />
          </Grid>
          <Grid item sx={styles.gridCountry}>
            <Skeleton variant="rectangular" width={70} height={20} />
          </Grid>
        </Grid>
        <Grid item container xs={12} md={"auto"} alignItems="center">
          <Divider
            orientation="vertical"
            flexItem
            sx={styles.divider}
            style={{ height: 40 }}
          />
          <Grid item sx={styles.gridId}>
            <Skeleton variant="rectangular" width={25} height={20} />
          </Grid>
          <Grid item>
            <Skeleton variant="rectangular" width={220} height={20} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
