import React from "react";
export const SeccionesCentralizadas = () => {
  return (
    <svg
      width="305"
      height="289"
      viewBox="0 0 305 289"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M280.86 47.7707C241.891 17.9241 204.093 42.2486 161.933 33.6482C119.469 24.9856 102.551 -3.85809 54.1851 9.45606C11.341 21.2498 -14.6555 68.5351 8.89741 108.123C15.6092 119.404 27.0431 127.914 31.7158 140.537C39.2532 160.899 27.78 176.531 30.0181 196.542C39.3182 279.686 166.776 247.481 213.675 221.613C287.227 181.044 336.984 90.7576 280.86 47.7707Z"
        fill="url(#paint0_linear_28_3919)"
        fill-opacity="0.3"
      />
      <path
        opacity="0.24"
        d="M244.5 17C242.322 17 240.145 16.1707 238.487 14.5133C235.171 11.198 235.171 5.80293 238.487 2.48697C241.802 -0.828989 247.198 -0.828989 250.513 2.48697C253.829 5.80293 253.829 11.1974 250.513 14.5133C248.856 16.1707 246.678 17 244.5 17ZM244.501 5.86216C243.824 5.86216 243.149 6.11904 242.634 6.63397C241.606 7.66265 241.606 9.33706 242.634 10.3663C243.663 11.395 245.338 11.3956 246.367 10.3663C247.396 9.33764 247.396 7.66324 246.367 6.63397C245.852 6.11904 245.176 5.86216 244.501 5.86216Z"
        fill="#FAFCFD"
      />
      <path
        opacity="0.24"
        d="M32 205C29.6942 205 27.3878 204.171 25.6329 202.513C22.1224 199.197 22.1224 193.802 25.6329 190.486C29.1434 187.171 34.8566 187.171 38.3671 190.486C41.8776 193.802 41.8776 199.197 38.3671 202.513C36.6122 204.171 34.3058 205 32 205ZM32 193.862C31.2846 193.862 30.5686 194.119 30.024 194.634C28.9341 195.662 28.9341 197.337 30.024 198.366C31.1132 199.395 32.8868 199.395 33.976 198.366C35.0659 197.337 35.0659 195.663 33.976 194.634C33.4314 194.119 32.716 193.862 32 193.862Z"
        fill="#4498EE"
      />
      <path
        opacity="0.48"
        d="M57.3474 235.328C56.7016 235.71 55.9034 235.834 55.1047 235.608L50.2268 234.23L49.0869 239.169C48.7133 240.786 47.1165 241.73 45.5195 241.279C43.9222 240.828 42.93 239.151 43.3037 237.534L45.1195 229.667C45.4931 228.05 47.0899 227.105 48.6869 227.556L56.4567 229.752C58.0541 230.204 59.0462 231.881 58.6726 233.498C58.4852 234.306 57.9932 234.946 57.3474 235.328Z"
        fill="#FAFCFD"
      />
      <path
        opacity="0.48"
        d="M275.432 42.0177C274.802 42.4249 274.009 42.5795 273.202 42.3858L268.274 41.2002L267.33 46.1803C267.02 47.8106 265.461 48.8175 263.848 48.4298C262.234 48.0415 261.177 46.405 261.486 44.7747L262.991 36.8419C263.301 35.2116 264.859 34.2048 266.473 34.5925L274.323 36.481C275.937 36.8692 276.994 38.5058 276.685 40.1361C276.529 40.9509 276.063 41.6105 275.432 42.0177Z"
        fill="#1E65AE"
      />
      <g filter="url(#filter0_dddd_28_3919)">
        <rect
          x="78"
          y="68.4062"
          width="104.081"
          height="127.075"
          rx="8"
          transform="rotate(-17.5626 78 68.4062)"
          fill="#FBFCFE"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M154.04 115.586L144.53 116.833C142.78 117.063 141.173 115.828 140.944 114.078C140.715 112.328 141.949 110.722 143.699 110.492L153.209 109.246C154.959 109.016 156.566 110.25 156.795 112C157.025 113.75 155.79 115.357 154.04 115.586ZM145.361 123.174L164.382 120.681C166.135 120.451 167.739 121.685 167.968 123.435C168.197 125.185 166.967 126.791 165.213 127.021L146.192 129.515C144.442 129.744 142.835 128.51 142.606 126.76C142.377 125.01 143.611 123.403 145.361 123.174ZM173.794 132.346L139.273 136.871C138.303 136.998 137.421 136.391 137.306 135.516L131.072 87.9617C130.957 87.0867 131.654 86.2731 132.624 86.1459L149.886 83.8831L151.195 93.8695C151.848 98.8533 156.237 102.399 160.983 101.777L171.401 100.411L175.349 130.529C175.464 131.404 174.767 132.218 173.794 132.346ZM156.633 86.1528L166.582 94.594L160.152 95.4368C158.903 95.6005 157.73 94.5256 157.535 93.0387L156.633 86.1528ZM176.222 94.3878L156.47 77.6284C155.78 77.0448 154.881 76.7789 153.984 76.8965L131.792 79.8056C127.325 80.3912 124.159 84.4245 124.731 88.7932L130.965 136.348C131.538 140.716 135.637 143.797 140.104 143.212L174.625 138.686C179.092 138.101 182.262 134.067 181.689 129.698L177.325 96.4102C177.222 95.6208 176.828 94.9018 176.222 94.3878Z"
        fill="#CBD5E0"
      />
      <g filter="url(#filter1_dddd_28_3919)">
        <rect
          x="98"
          y="50.0947"
          width="104.081"
          height="127.075"
          rx="8"
          transform="rotate(-0.715089 98 50.0947)"
          fill="#FBFCFE"
        />
      </g>
      <path
        d="M159.702 142.262H132.175C129.742 142.262 127.408 141.278 125.687 139.526C123.967 137.775 123 135.399 123 132.922V89.3392C123 86.8623 123.967 84.4868 125.687 82.7354C127.408 80.984 129.742 80 132.175 80H159.702C162.135 80 164.469 80.984 166.19 82.7354C167.91 84.4868 168.877 86.8623 168.877 89.3392V132.922C168.877 135.399 167.91 137.775 166.19 139.526C164.469 141.278 162.135 142.262 159.702 142.262ZM132.175 86.2262C131.364 86.2262 130.586 86.5541 130.013 87.138C129.439 87.7218 129.117 88.5136 129.117 89.3392V132.922C129.117 133.748 129.439 134.54 130.013 135.124C130.586 135.707 131.364 136.035 132.175 136.035H159.702C160.513 136.035 161.291 135.707 161.864 135.124C162.438 134.54 162.76 133.748 162.76 132.922V89.3392C162.76 88.5136 162.438 87.7218 161.864 87.138C161.291 86.5541 160.513 86.2262 159.702 86.2262H132.175Z"
        fill="#CBD5E0"
      />
      <path
        d="M175.599 95.2923C174.774 95.2923 173.984 95.6184 173.401 96.1988C172.818 96.7792 172.49 97.5664 172.49 98.3872V141.715C172.49 142.536 172.191 143.32 171.608 143.9C171.025 144.48 170.206 144.81 169.381 144.81H141.401C140.577 144.81 139.786 145.136 139.203 145.717C138.62 146.297 138.292 147.084 138.292 147.905C138.292 148.726 138.62 149.513 139.203 150.094C139.786 150.674 140.577 151 141.401 151H169.381C171.855 151 174.228 150.557 175.977 148.815C177.726 147.074 178.708 144.178 178.708 141.715V98.3872C178.708 97.5664 178.38 96.7792 177.797 96.1988C177.214 95.6184 176.424 95.2923 175.599 95.2923Z"
        fill="#CBD5E0"
      />
      <rect
        x="132.831"
        y="124.785"
        width="26.2154"
        height="5.46154"
        rx="2.73077"
        fill="#CBD5E0"
      />
      <rect
        x="132.831"
        y="114.954"
        width="26.2154"
        height="5.46154"
        rx="2.73077"
        fill="#CBD5E0"
      />
      <rect
        x="132.831"
        y="105.123"
        width="16.3846"
        height="5.46154"
        rx="2.73077"
        fill="#CBD5E0"
      />
      <path
        opacity="0.48"
        d="M261.5 173C259.324 173.001 257.147 172.171 255.49 170.513C253.884 168.907 253 166.772 253 164.5C253 162.229 253.884 160.093 255.49 158.487C258.803 155.171 264.196 155.171 267.51 158.487C269.116 160.093 270 162.229 270 164.5C270 166.772 269.116 168.907 267.51 170.513C265.854 172.171 263.677 173 261.5 173ZM261.5 161.862C260.825 161.862 260.149 162.119 259.635 162.634C259.136 163.132 258.862 163.795 258.862 164.5C258.862 165.205 259.137 165.868 259.635 166.366C260.664 167.396 262.337 167.396 263.366 166.366C263.865 165.868 264.138 165.205 264.138 164.5C264.138 163.795 263.864 163.133 263.366 162.634C262.852 162.119 262.176 161.862 261.5 161.862Z"
        fill="#CBD5E0"
      />
      <defs>
        <filter
          id="filter0_dddd_28_3919"
          x="15.0391"
          y="10.0391"
          width="263.496"
          height="278.48"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.575" />
          <feGaussianBlur stdDeviation="1.87891" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.415686 0 0 0 0 0.654902 0 0 0 0 0.772549 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_28_3919"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="7.2" />
          <feGaussianBlur stdDeviation="5.28125" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.415686 0 0 0 0 0.654902 0 0 0 0 0.772549 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_28_3919"
            result="effect2_dropShadow_28_3919"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="18.225" />
          <feGaussianBlur stdDeviation="14.168" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.415686 0 0 0 0 0.654902 0 0 0 0 0.772549 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_28_3919"
            result="effect3_dropShadow_28_3919"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="36" />
          <feGaussianBlur stdDeviation="32.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.415686 0 0 0 0 0.654902 0 0 0 0 0.772549 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="effect3_dropShadow_28_3919"
            result="effect4_dropShadow_28_3919"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect4_dropShadow_28_3919"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_dddd_28_3919"
          x="33.0991"
          y="19.895"
          width="235.46"
          height="258.166"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.575" />
          <feGaussianBlur stdDeviation="1.87891" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.415686 0 0 0 0 0.654902 0 0 0 0 0.772549 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_28_3919"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="7.2" />
          <feGaussianBlur stdDeviation="5.28125" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.415686 0 0 0 0 0.654902 0 0 0 0 0.772549 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_28_3919"
            result="effect2_dropShadow_28_3919"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="18.225" />
          <feGaussianBlur stdDeviation="14.168" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.415686 0 0 0 0 0.654902 0 0 0 0 0.772549 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_28_3919"
            result="effect3_dropShadow_28_3919"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="36" />
          <feGaussianBlur stdDeviation="32.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.415686 0 0 0 0 0.654902 0 0 0 0 0.772549 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="effect3_dropShadow_28_3919"
            result="effect4_dropShadow_28_3919"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect4_dropShadow_28_3919"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_28_3919"
          x1="208.931"
          y1="293.4"
          x2="333.765"
          y2="29.6322"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E1E8F2" />
          <stop offset="1" stop-color="#E2E8F0" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
