export enum BASE_ROUTES {
  NODE_SELECTION = "/node-selection",
  CONFIG = "/config",
  ALARMS = "/alarms",
  RULE_DETAIL = "/rule-detail",
  EXECUTIVES = "/executives",
}

export enum PATH_PARAMS {
  TYPE = "/:type",
  RULE_ID = "/:ruleId",
}

export const ROUTES = {
  ALARMS: `${BASE_ROUTES.ALARMS}/:type`,
  CONFIG_ALARM: `${BASE_ROUTES.CONFIG}${PATH_PARAMS.TYPE}`,
  CONFIG_ALARM_EDIT: `${BASE_ROUTES.CONFIG}${PATH_PARAMS.TYPE}${PATH_PARAMS.RULE_ID}`,
  EXECUTIVES: `${BASE_ROUTES.EXECUTIVES}`,
  NODE_SELECTION: `${BASE_ROUTES.NODE_SELECTION}${PATH_PARAMS.TYPE}`,
  RULE_DETAIL: `${BASE_ROUTES.RULE_DETAIL}${PATH_PARAMS.TYPE}${PATH_PARAMS.RULE_ID}`,
  SPA_BASENAME: "/console-compliance",
};
