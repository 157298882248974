import { ActionTypes } from "./actionType";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import axios from "../shared/axios-util";
import { MerchantInformation } from "../../types/merchant_information";
import { environment } from "../environments/environment";
import { AxiosError, AxiosResponse } from "axios";
import { History, SecurityRule } from "../../types/security_rule";
import { defaultTo, get, isEmpty, isNil, isUndefined, set } from "lodash";
import {
  Countries,
  INotification,
  OriginEnum,
  RulesNotificationResultEnum,
} from "../shared/infrastructure/constants/RulesConstants";
import { MerchantProcessors } from "../../types/merchant_processors";
import { MerchantCredential } from "../../types/merchant_credential";
import { Category } from "../../types/category";
import { BusinessRulesRequest } from "../../types/business_rules_request";
import { BusinessRulesResponse } from "../../types/business_rules_response";
import { UpdateMerchantRulesRequest } from "../../types/update_merchant_rules_request";
import { SecurityRulesByType } from "../../types/security_rules_by_type";
import { WhitelistRules } from "../../types/whitelist_rules";
import { IWhitelistRuleRequest } from "../shared/infrastructure/interfaces/IWhitelistRulesForm";
import { MerchantInfo } from "../../types/merchant_info";
import {
  CreateSecurityRule,
  UpdateSecurityRule,
} from "../shared/infrastructure/constants/SecurityRulesConstants";
import { GeneralActionsEnum } from "../shared/infrastructure/enums/GeneralActionsEnum";
import { RuleTypeEnum } from "../shared/infrastructure/enums/RuleTypeEnum";
import {
  ProcessorsInformation,
  ProcessorsList,
  Processors,
} from "../../types/processors_list";
import { PayloadSearch } from "../../types/payload_search";
import { PathsEnum } from "../shared/infrastructure/constants/PathsEnum";
import { PaymentMethodEnum } from "../shared/infrastructure/constants/PaymentMethodEnum";
import { GetBusinessRulesResponse } from "../../types/get_business_rules_response";
import { MIGRATION_RULE_MESSAGE } from "../shared/infrastructure/constants/BusinessRulesEnum";

const DEFAULT_MESSAGE_ERROR: string = "No fue posible realizar la petición";

export interface RulesAction {
  type: string;
  isLoading?: boolean;
  isLoadingGetMerchants?: boolean;
  isLoadingGetProcessors?: boolean;
  isLoadingGetRule?: boolean;
  isLoadingGetMerchant?: boolean;
  isLoadingRulesByType?: boolean;
  isLoadingWhiteList?: boolean;
  isLoadingGetSuggestedCredentials?: boolean;
  notification?: INotification;
  merchants?: MerchantsData;
  processors?: ProcessorsInformation[];
  currentSecurityRule?: SecurityRule;
  currentMerchant?: MerchantInformation;
  isRequest?: boolean;
  requestError?: boolean;
  businessRule?: BusinessRulesResponse;
  processorsMerchant?: ProcessorsInformation[];
  credentials?: Category[];
  credentialsSuggested?: MerchantCredential[];
  businessRulesList?: GetBusinessRulesResponse;
  defaultProcessor?: object;
  rulesByType?: SecurityRulesByType;
  commerceRules?: SecurityRulesByType;
  whitelistRules?: WhitelistRules;
  currentWhitelistRule?: WhitelistRules;
  showMigrationRule?: boolean;
  showFinalModal?: boolean;
  showFinalLoading?: boolean;
}

export interface IRulesState {
  isLoading?: boolean;
  isLoadingGetMerchants?: boolean;
  isLoadingGetProcessors?: boolean;
  isLoadingGetRule?: boolean;
  isLoadingGetMerchant?: boolean;
  isLoadingRulesByType?: boolean;
  isLoadingWhiteList?: boolean;
  isLoadingGetSuggestedCredentials?: boolean;
  notification?: INotification;
  merchants?: MerchantsData;
  processors?: ProcessorsInformation[];
  currentSecurityRule?: SecurityRule;
  currentMerchant?: MerchantInformation;
  isRequest?: boolean;
  requestError?: boolean;
  businessRule?: BusinessRulesResponse;
  businessRulesList?: GetBusinessRulesResponse;
  processorsMerchant?: ProcessorsInformation[];
  credentials?: Category[];
  credentialsSuggested?: MerchantCredential[];
  defaultProcessor?: object;
  rulesByType?: SecurityRulesByType;
  commerceRules?: SecurityRulesByType;
  whitelistRules?: WhitelistRules;
  currentWhitelistRule?: WhitelistRules;
  showMigrationRule?: boolean;
  showFinalModal?: boolean;
  showFinalLoading?: boolean;
}

export type MerchantsData = {
  data: { _source: MerchantInformation }[];
};

export const setIsLoading = (payload: boolean): RulesAction => {
  return {
    type: ActionTypes.SET_IS_LOADING,
    isLoading: payload,
  };
};

export const setShowFinalModal = (payload: boolean): RulesAction => {
  return {
    type: ActionTypes.SET_SHOW_FINAL_MODAL,
    showFinalModal: payload,
  };
};

export const setIsLoadingGetMerchants = (payload: boolean): RulesAction => {
  return {
    type: ActionTypes.SET_IS_LOADING_GET_MERCHANTS,
    isLoadingGetMerchants: payload,
  };
};

export const setIsLoadingGetProcessors = (payload: boolean): RulesAction => {
  return {
    type: ActionTypes.SET_IS_LOADING_GET_PROCESSORS,
    isLoadingGetProcessors: payload,
  };
};

export const setIsLoadingGetRule = (payload: boolean): RulesAction => {
  return {
    type: ActionTypes.SET_IS_LOADING_GET_RULE,
    isLoadingGetRule: payload,
  };
};

export const setIsLoadingGetMerchant = (payload: boolean): RulesAction => {
  return {
    type: ActionTypes.SET_IS_LOADING_GET_MERCHANT,
    isLoadingGetMerchant: payload,
  };
};

export const setIsLoadingRulesByType = (payload: boolean): RulesAction => {
  return {
    type: ActionTypes.SET_IS_LOADING_RULE_BY_TYPE,
    isLoadingRulesByType: payload,
  };
};

export const setIsLoadingCredentialSuggested = (
  payload: boolean
): RulesAction => {
  return {
    type: ActionTypes.SET_IS_LOADING_GET_SUGGESTED_CREDENTIALS,
    isLoadingGetSuggestedCredentials: payload,
  };
};

export const setIsLoadingWhitelist = (payload: boolean): RulesAction => {
  return {
    type: ActionTypes.SET_IS_LOADING_WHITELIST,
    isLoadingWhiteList: payload,
  };
};

export const setNotification = (payload: INotification): RulesAction => {
  return {
    type: ActionTypes.SET_NOTIFICATION,
    notification: payload,
  };
};

export const setIsRequest = (payload: boolean): RulesAction => {
  return {
    type: ActionTypes.SET_IS_REQUESTING,
    isRequest: payload,
  };
};

export const setRequestError = (payload: boolean): RulesAction => {
  return {
    type: ActionTypes.SET_REQUEST_ERROR,
    requestError: payload,
  };
};

export const setMerchantCredentials = (payload: Category[]): RulesAction => {
  return {
    type: ActionTypes.SET_MERCHANT_CREDENTIALS,
    credentials: payload,
  };
};

export const setBusinessRule = (
  payload: BusinessRulesResponse
): RulesAction => {
  return {
    type: ActionTypes.SET_BUSINESS_RULE,
    businessRule: payload,
  };
};

export const setDefaultProcessor = (payload: object): RulesAction => {
  return {
    type: ActionTypes.GET_DEFAULT_PROCESSOR,
    defaultProcessor: payload,
  };
};

export const setMerchantProcessors = (
  payload: ProcessorsInformation[]
): RulesAction => {
  return {
    type: ActionTypes.SET_MERCHANT_PROCESSORS,
    processorsMerchant: payload,
  };
};

export const setMerchants = (payload: MerchantsData): RulesAction => {
  return {
    type: ActionTypes.SET_MERCHANTS,
    merchants: payload,
  };
};

export const setProcessors = (
  payload: ProcessorsInformation[]
): RulesAction => {
  return {
    type: ActionTypes.SET_PROCESSORS,
    processors: payload,
  };
};

export const setCurrentSecurityRule = (payload: SecurityRule): RulesAction => {
  return {
    type: ActionTypes.SET_CURRENT_SECURITY_RULE,
    currentSecurityRule: payload,
  };
};

export const setCurrentMerchant = (
  payload: MerchantInformation
): RulesAction => {
  return {
    type: ActionTypes.SET_CURRENT_MERCHANT,
    currentMerchant: payload,
  };
};

export const setBusinessRulesList = (
  payload: GetBusinessRulesResponse
): RulesAction => {
  return {
    type: ActionTypes.SET_BUSINESS_RULE_LIST,
    businessRulesList: payload,
  };
};

export const setRulesByType = (payload: SecurityRulesByType): RulesAction => {
  return {
    type: ActionTypes.SET_RULES_BY_TYPE,
    rulesByType: payload,
  };
};

export const setCommerceRules = (payload: SecurityRulesByType): RulesAction => {
  return {
    type: ActionTypes.SET_COMMERCE_RULES,
    commerceRules: payload,
  };
};

export const setWhitelistRules = (payload: WhitelistRules): RulesAction => {
  return {
    type: ActionTypes.SET_WHITELIST_RULES,
    whitelistRules: payload,
  };
};

export const setCurrentWhitelistRule = (
  payload: WhitelistRules
): RulesAction => {
  return {
    type: ActionTypes.SET_CURRENT_WHITELIST_RULE,
    currentWhitelistRule: payload,
  };
};

export const setShowMigrationRule = (payload: boolean): RulesAction => {
  return {
    type: ActionTypes.SET_SHOW_MIGRATION_RULE,
    showMigrationRule: payload,
  };
};

export const setMerchantsAliasSuggested = (
  payload: MerchantCredential[]
): RulesAction => {
  return {
    type: ActionTypes.GET_SUGGESTED_CREDENTIAL,
    credentialsSuggested: payload,
  };
};

export const setShowFinalLoading = (payload: boolean): RulesAction => {
  return {
    type: ActionTypes.SET_SHOW_FINAL_LOADING,
    showFinalLoading: payload,
  };
};

export const getProcessors = (
  merchantId: string,
  text?: string,
  filterProcessor: boolean = true
): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  const processorsList: ProcessorsInformation[] = [];
  const request: object = {
    limit: 10,
    offset: 0,
  };

  if (filterProcessor)
    set(request, "filter", {
      paymentMethod: "card",
    });

  if (text) set(request, "filter", { ...get(request, "filter"), text });

  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    dispatch(setIsRequest(true));
    dispatch(setRequestError(false));
    axios
      .post<MerchantProcessors[]>(
        `${environment.kushkiUrl}/analytics/v1/admin/${merchantId}/processors`,
        request
      )
      .then((axios_response) => {
        get(axios_response, PathsEnum.AXIOS_DATA, []).forEach(
          (processor: Processors) =>
            processorsList.push({ ...processor._source })
        );

        filterProcessors(processorsList, dispatch);
      })
      .catch((e) => {
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_ERROR,
            message: defaultTo(
              get(e, "response.data.message"),
              DEFAULT_MESSAGE_ERROR
            ),
            open: true,
            type: "error",
          })
        );
        dispatch(setIsRequest(false));
        dispatch(setRequestError(true));
        dispatch(setMerchantProcessors([]));
      });
  };
};

export const getMerchantCredential = (
  merchantId: string
): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    dispatch(setIsRequest(true));
    dispatch(setRequestError(false));
    axios
      .get<MerchantCredential[]>(
        `${environment.kushkiUrl}/payment-credentials/v1/admin/credentials/${merchantId}`
      )
      .then((axios_response: AxiosResponse<MerchantCredential[]>) => {
        const response: MerchantCredential[] = get(axios_response, "data");

        buildCredentials(response, dispatch);
      })
      .catch((e) => {
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_ERROR,
            message: defaultTo(
              get(e, "response.data.message"),
              DEFAULT_MESSAGE_ERROR
            ),
            open: true,
            type: "error",
          })
        );
        dispatch(setRequestError(true));
        dispatch(setIsRequest(false));
        dispatch(setMerchantCredentials([]));
      });
  };
};

export const createBusinessRule = (
  payload: SecurityRule,
  merchantInfo: MerchantInfo,
  redirectPath: string
): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    dispatch(setIsLoading(true));
    dispatch(setShowFinalLoading(true));
    const url: string = `${environment.kushkiUrl}/rules/v1/admin/business/rules`;

    axios
      .post<SecurityRule>(url, {
        ...payload,
      })
      .then(() => {
        setInterval(() => {
          dispatch(setShowFinalLoading(false));
          if (
            merchantInfo.origin === OriginEnum.createMerchant &&
            ![Countries.PERU, Countries.COLOMBIA, Countries.CHILE].includes(
              <Countries>merchantInfo.country
            )
          ) {
            dispatch(setShowFinalModal(true));
          } else {
            dispatch(setIsLoading(false));
            window.location.href = redirectPath;
          }
        }, 2000);
      })
      .catch((e) => {
        dispatch(setShowFinalLoading(false));
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_ERROR,
            message: defaultTo(
              get(e, "response.data.message"),
              DEFAULT_MESSAGE_ERROR
            ),
            open: true,
            type: "error",
          })
        );
        dispatch(setIsLoading(false));
      });
  };
};

export const deleteBusinessRule = (
  ruleId: string,
  merchantId: string,
  limit: number
): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    dispatch(setIsLoading(true));
    const url: string = `${environment.kushkiUrl}/rules/v1/admin/business/rules/${ruleId}`;
    axios
      .delete<BusinessRulesRequest>(url)
      .then(() => {
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_SUCCESS,
            message: "Regla eliminada con éxito",
            open: true,
            type: "success",
          })
        );
        dispatch(setIsLoading(false));
        dispatch(getBusinessRules({ merchantId, limit }));
      })
      .catch((e) => {
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_ERROR,
            message: defaultTo(
              get(e, "response.data.message"),
              DEFAULT_MESSAGE_ERROR
            ),
            open: true,
            type: "error",
          })
        );
        dispatch(setIsLoading(false));
      });
  };
};

export const updateBusinessRule = (
  ruleId: string,
  payload: SecurityRule,
  merchantId: string
): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    dispatch(setIsLoading(true));
    const url: string = `${environment.kushkiUrl}/rules/v1/admin/business/rules/${ruleId}`;
    axios
      .patch<SecurityRule>(url, {
        ...payload,
      })
      .then(() => {
        dispatch(setIsLoading(false));
        window.location.href = `/rules/business/${merchantId}`;
      })
      .catch((e) => {
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_ERROR,
            message: defaultTo(
              get(e, "response.data.message"),
              DEFAULT_MESSAGE_ERROR
            ),
            open: true,
            type: "error",
          })
        );
        dispatch(setIsLoading(false));
      });
  };
};

export const getBusinessRule = (
  ruleId: string
): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    dispatch(setIsLoading(true));
    const url: string = `${environment.kushkiUrl}/rules/v1/admin/business/rules/${ruleId}`;
    axios
      .get<BusinessRulesResponse>(url)
      .then((axios_response: AxiosResponse<BusinessRulesResponse>) => {
        const response: object = get(axios_response, "data");
        const rule: BusinessRulesResponse = get(response, "rule");
        dispatch(
          setBusinessRule({
            ...get(rule, "rule"),
            id: rule.id,
          })
        );
        dispatch(setIsLoading(false));
      })
      .catch((e) => {
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_ERROR,
            message: defaultTo(
              get(e, "response.data.message"),
              DEFAULT_MESSAGE_ERROR
            ),
            open: true,
            type: "error",
          })
        );
        dispatch(setIsLoading(false));
      });
  };
};

export const getDefaultProcessor = (
  merchantId: string
): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    const url: string = `${environment.kushkiUrl}/rules/v1/admin/merchant/${merchantId}`;
    axios
      .get(url)
      .then((axios_response: object) => {
        const response: object = get(axios_response, "data");
        dispatch(setDefaultProcessor(response));
        dispatch(setIsLoading(false));
      })
      .catch((e) => {
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_ERROR,
            message: defaultTo(
              get(e, "response.data.message"),
              DEFAULT_MESSAGE_ERROR
            ),
            open: true,
            type: "error",
          })
        );
        dispatch(setIsLoading(false));
      });
  };
};

export const saveDefaultProcessor = (
  payload: UpdateMerchantRulesRequest
): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    dispatch(setIsRequest(true));
    const url: string = `${environment.kushkiUrl}/rules/v1/admin/merchant/${payload.publicMerchantId}`;
    axios
      .patch(url, payload)
      .then(() => {
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_SUCCESS,
            message: "Operación exitosa",
            open: true,
            type: "success",
          })
        );
        dispatch(setIsRequest(false));
      })
      .catch((e) => {
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_ERROR,
            message: defaultTo(
              get(e, "response.data.message"),
              DEFAULT_MESSAGE_ERROR
            ),
            open: true,
            type: "error",
          })
        );
        dispatch(setIsRequest(false));
      });
  };
};

const filterProcessors = (
  merchantProcessors: ProcessorsInformation[],
  dispatch: ThunkDispatch<IRulesState, any, RulesAction>
): void => {
  if (isEmpty(merchantProcessors)) {
    dispatch(
      setNotification({
        action: RulesNotificationResultEnum.RULES_REQUEST_ERROR,
        message: "No existen procesadores para el merchant actual",
        open: true,
        type: "error",
      })
    );
    dispatch(setMerchantProcessors([]));
    dispatch(setIsRequest(false));
    dispatch(setRequestError(true));
  } else {
    dispatch(setMerchantProcessors(merchantProcessors));
    dispatch(setIsRequest(false));
    dispatch(setRequestError(false));
  }
};

const buildCredentials = (
  merchantCredentials: MerchantCredential[],
  dispatch: ThunkDispatch<IRulesState, any, RulesAction>
): void => {
  const credentials: Category[] = [];

  merchantCredentials.forEach((credential: MerchantCredential) => {
    const alias: string | undefined | null = get(credential, "alias");
    let name: string = "";

    if (isNil(alias))
      name = `${credential.publicCredentialId.substr(0, 6)}XXXXXX`;
    else
      name = `${
        alias === "" ? "Alias" : alias
      }_${credential.publicCredentialId.substr(0, 6)}XXXXXX`;

    credentials.push({ name, value: credential.credentialId });
  });

  dispatch(setMerchantCredentials(credentials));
  dispatch(setIsRequest(false));
  dispatch(setRequestError(false));
};

export const getMerchants = (payload: {
  offset: number;
  limit: number;
  text?: string;
}): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    let body: { limit: number; offset: number; text?: string } = {
      limit: payload.limit,
      offset: payload.offset,
    };
    if (Boolean(payload.text)) body = { ...body, text: payload.text };

    dispatch(setIsLoadingGetMerchants(true));
    axios
      .post<MerchantsData>(
        `${environment.kushkiUrl}/analytics/v1/admin/merchant/get`,
        body
      )
      .then((axios_response: AxiosResponse<MerchantsData>) => {
        const response: MerchantsData = axios_response.data;
        dispatch(setMerchants(response));
        dispatch(setIsLoadingGetMerchants(false));
      })
      .catch(() => {
        dispatch(setIsLoadingGetMerchants(false));
      });
  };
};

export const getProcessorsSecurityRules = (
  merchantId: string,
  payload: PayloadSearch
): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    dispatch(setIsLoadingGetProcessors(true));
    concatenateProcessors(merchantId, payload)
      .then((processorsList: ProcessorsInformation[]): void => {
        dispatch(setProcessors(processorsList));
        dispatch(setIsLoadingGetProcessors(false));
      })
      .catch((): void => {
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_ERROR,
            message: "Error",
            open: true,
            type: "error",
          })
        );
        dispatch(setIsLoadingGetProcessors(false));
      });
  };
};

const concatenateProcessors = async (
  merchantId: string,
  payload: PayloadSearch
): Promise<ProcessorsInformation[]> => {
  const processorsList: ProcessorsInformation[] = [];
  const card: AxiosResponse<ProcessorsList> =
    await getProcessorsByPaymentMethod(
      merchantId,
      payload,
      PaymentMethodEnum.CARD
    );
  const transfer: AxiosResponse<ProcessorsList> =
    await getProcessorsByPaymentMethod(
      merchantId,
      payload,
      PaymentMethodEnum.TRANSFER
    );

  get(card, PathsEnum.AXIOS_DATA, []).forEach((processor: Processors) =>
    processorsList.push({ ...processor._source })
  );
  get(transfer, PathsEnum.AXIOS_DATA, []).forEach((processor: Processors) =>
    processorsList.push({ ...processor._source })
  );

  return processorsList;
};

const getProcessorsByPaymentMethod = (
  merchantId: string,
  payload: PayloadSearch,
  paymentMethod: string
): Promise<AxiosResponse<ProcessorsList>> => {
  return axios.post<ProcessorsList>(
    `${environment.kushkiUrl}/analytics/v1/admin/${merchantId}/processors`,
    {
      limit: payload.limit,
      offset: payload.offset,
      filter: {
        paymentMethod,
      },
    }
  );
};

const url_rules: string = `${environment.kushkiUrl}/rules/v1/monitor/security/rules`;
const url_approval: string = `${environment.kushkiUrl}/request-manager/v1/approval-record`;

export const createSecurityRule = (payload: {
  rule: SecurityRule;
  role: string;
}): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    dispatch(setIsLoading(true));
    const auth_rules = get(payload.rule, "authRules", []);
    let url: string = url_rules;
    let data: object = { ...payload.rule };
    let message: string = CreateSecurityRule.ERROR_RULE;

    if (
      auth_rules &&
      payload.rule.type !== RuleTypeEnum.COUNTRY &&
      (payload.rule.rule?.action === GeneralActionsEnum.OTP ||
        payload.rule.rule?.action === GeneralActionsEnum.THREE_DS)
    ) {
      url = url_approval;
      data = { ...auth_rules };
      message = CreateSecurityRule.ERROR_APPROVAL_RULE;
    }

    axios
      .post(url, data)
      .then(() => {
        dispatch(setIsLoading(false));
        window.location.href = "/rules";
      })
      .catch(() => {
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_ERROR,
            message: message,
            open: true,
            type: "error",
          })
        );
        dispatch(setIsLoading(false));
      });
  };
};

export const updateSecurityRule = (payload: {
  rule: SecurityRule;
  ruleId: string;
  updatingStatus?: boolean;
}): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    dispatch(setIsLoading(true));
    const url: string = `${url_rules}/${payload.ruleId}`;
    const rule: object = get(payload, "rule.rule", {});
    const status: string = get(payload, "rule.status", "");
    const historical: History[] = get(payload, "rule.historical", []);

    axios
      .patch(url, {
        rule,
        historical,
        status,
      })
      .then(() => {
        dispatch(setIsLoading(false));
        payload.updatingStatus &&
          dispatch(
            setNotification({
              action: RulesNotificationResultEnum.SUCCESS,
              message: UpdateSecurityRule.SUCCESS_STATUS,
              open: true,
              type: "success",
            })
          );
        window.location.href = "/rules";
      })
      .catch(() => {
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_ERROR,
            message: payload.updatingStatus
              ? UpdateSecurityRule.ERROR_STATUS
              : UpdateSecurityRule.ERROR_UPDATE,
            open: true,
            type: "error",
          })
        );
        dispatch(setIsLoading(false));
      });
  };
};

export const getSecurityRule = (payload: {
  ruleId: string;
}): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    dispatch(setIsLoadingGetRule(true));
    const url: string = `${environment.kushkiUrl}/rules/v1/monitor/security/rules/${payload.ruleId}`;
    axios
      .get<SecurityRule>(url)
      .then((axios_response: AxiosResponse<SecurityRule>) => {
        const data: object = get(axios_response, "data");
        const response: SecurityRule = get(data, "rule");

        dispatch(setCurrentSecurityRule(response));
        if (
          !isEmpty(response.merchantId) &&
          response.type === RuleTypeEnum.MERCHANT
        ) {
          dispatch(
            getProcessorsSecurityRules(get(response, "merchantId", ""), {
              offset: 0,
              limit: 10,
            })
          );
          dispatch(
            getMerchant({ merchantId: get(response, "merchantId", "") })
          );
        }
        dispatch(setIsLoadingGetRule(false));
      })
      .catch(() => {
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_ERROR,
            message: "No se encontró la regla.",
            open: true,
            type: "error",
          })
        );
        dispatch(setIsLoadingGetRule(false));
      });
  };
};

export const getMerchant = (payload: {
  merchantId: string;
}): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    dispatch(setIsLoadingGetMerchant(true));
    const url: string = `${environment.kushkiUrl}/billing-core/v1/merchant/merchantInfo`;
    axios
      .post<MerchantInformation>(url, {
        publicMerchantId: payload.merchantId,
      })
      .then((axios_response: AxiosResponse<MerchantInformation>) => {
        const response: MerchantInformation = axios_response.data;
        dispatch(setCurrentMerchant(response));
        dispatch(setIsLoadingGetMerchant(false));
      })
      .catch(() => {
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_ERROR,
            message: "No se encontró el comercio.",
            open: true,
            type: "error",
          })
        );
        dispatch(setIsLoadingGetMerchant(false));
      });
  };
};

export const deleteSecurityRule = (
  ruleId: string
): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    dispatch(setIsRequest(true));
    const url: string = `${environment.kushkiUrl}/rules/v1/monitor/security/rules/${ruleId}`;
    axios
      .delete(url)
      .then(() => {
        dispatch(
          getSecurityRulesByCommerce({
            lastEvaluatedKey: {},
            limit: 10,
            items: [],
          })
        );
        dispatch(setIsRequest(false));
      })
      .catch((_err) => {
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_ERROR,
            message: "No se pudo eliminar la regla",
            open: true,
            type: "error",
          })
        );
        dispatch(setIsRequest(false));
      });
  };
};

export const getBusinessRules = (payload: {
  merchantId: string;
  limit: number;
  lastEvaluatedKey?: any;
}): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    dispatch(setIsLoading(true));
    const url: string = `${environment.kushkiUrl}/rules/v1/admin/business-type/rules`;
    axios
      .post<GetBusinessRulesResponse>(url, payload)
      .then((axios_response: AxiosResponse<GetBusinessRulesResponse>) => {
        const response: GetBusinessRulesResponse = {
          items: isEmpty(axios_response.data.items)
            ? []
            : axios_response.data.items,
          lastEvaluatedKey: axios_response.data.lastEvaluatedKey,
        };
        dispatch(setBusinessRulesList(response));
        dispatch(getDefaultProcessor(payload.merchantId));
      })
      .catch(() => {
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_ERROR,
            message: "No se encontraron reglas para el comercio.",
            open: true,
            type: "error",
          })
        );
        dispatch(getDefaultProcessor(payload.merchantId));
      });
  };
};

export const getSecurityRulesByCountry = (
  ruleLimit: SecurityRulesByType
): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    dispatch(setIsLoadingRulesByType(true));
    axios
      .post<SecurityRulesByType>(
        `${environment.kushkiUrl}/rules/v1/monitor/security-type/rules/securityCountry`,
        isEmpty(ruleLimit.lastEvaluatedKey)
          ? {
              limit: ruleLimit.limit,
            }
          : {
              limit: ruleLimit.limit,
              lastEvaluatedKey: ruleLimit.lastEvaluatedKey,
            }
      )
      .then((axios_response: AxiosResponse<SecurityRulesByType>) => {
        dispatch(setIsLoadingRulesByType(false));
        dispatch(setRulesByType(axios_response.data));
      })
      .catch((e: string) => {
        dispatch(setIsLoadingRulesByType(false));
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_ERROR,
            message: defaultTo(
              get(e, "response.data.message"),
              RulesNotificationResultEnum.DEFAULT_MESSAGE_ERROR_GET_RULES
            ),
            open: true,
            type: "error",
          })
        );
      });
  };
};

export const getSecurityRulesByCommerce = (
  ruleLimit: SecurityRulesByType
): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    let bodyRequest: object = {
      merchantId: get(ruleLimit, "items[0].merchantId", ""),
      limit: ruleLimit.limit,
    };

    if (!isEmpty(ruleLimit.lastEvaluatedKey))
      bodyRequest = {
        ...bodyRequest,
        lastEvaluatedKey: ruleLimit.lastEvaluatedKey,
      };
    dispatch(setIsLoadingRulesByType(true));
    dispatch(setShowMigrationRule(false));
    axios
      .post<SecurityRulesByType>(
        `${environment.kushkiUrl}/rules/v1/monitor/security-type/rules/securityCommerce`,
        bodyRequest
      )
      .then((axios_response: AxiosResponse<SecurityRulesByType>) => {
        dispatch(setIsLoadingRulesByType(false));
        dispatch(setCommerceRules(axios_response.data));
      })
      .catch((e: AxiosError) => {
        if (e.response?.data["message"] === MIGRATION_RULE_MESSAGE)
          dispatch(setShowMigrationRule(true));
        dispatch(setIsLoadingRulesByType(false));
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_ERROR,
            message: defaultTo(
              get(e, "response.data.message"),
              RulesNotificationResultEnum.DEFAULT_MESSAGE_ERROR_GET_RULES
            ),
            open: true,
            type: "error",
          })
        );
      });
  };
};

export const deleteSecurityRulesByCountry = (
  ruleId: string
): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    dispatch(setIsLoadingRulesByType(true));
    axios
      .delete(
        `${environment.kushkiUrl}/rules/v1/monitor/security/rules/${ruleId}`
      )
      .then(() => {
        dispatch(
          getSecurityRulesByCommerce({
            lastEvaluatedKey: {},
            limit: 10,
            items: [],
          })
        );
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.SUCCESS,
            message: RulesNotificationResultEnum.RULE_DELETE,
            open: true,
            type: "success",
          })
        );
        dispatch(setIsLoadingRulesByType(false));
      })
      .catch((e: string) => {
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_ERROR,
            message: defaultTo(
              get(e, "response.data.message"),
              RulesNotificationResultEnum.DEFAULT_MESSAGE_ERROR_GET_RULES
            ),
            open: true,
            type: "error",
          })
        );
        dispatch(setIsLoadingRulesByType(false));
      });
  };
};

export const getWhitelistRules = (
  whitelistRules: WhitelistRules
): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    let bodyRequest: object = {
      merchantId: get(whitelistRules, "items[0].merchantId", ""),
      limit: whitelistRules.limit,
    };

    if (!isEmpty(whitelistRules.lastEvaluatedKey))
      bodyRequest = {
        ...bodyRequest,
        lastEvaluatedKey: whitelistRules.lastEvaluatedKey,
      };
    dispatch(setIsLoadingWhitelist(true));
    axios
      .post<WhitelistRules>(
        `${environment.kushkiUrl}/rules/v1/monitor/whitelist/rules`,
        bodyRequest
      )
      .then((axios_response: AxiosResponse<WhitelistRules>) => {
        dispatch(setIsLoadingWhitelist(false));
        const data = validateIfWhitelistRulesHasValidationType(
          axios_response.data
        );

        dispatch(setWhitelistRules(data));
      })
      .catch((e: string) => {
        dispatch(setIsLoadingWhitelist(false));
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_ERROR,
            message: defaultTo(
              get(e, "response.data.message"),
              RulesNotificationResultEnum.DEFAULT_MESSAGE_ERROR_GET_RULES
            ),
            open: true,
            type: "error",
          })
        );
      });
  };
};

export const deleteWhiteListRules = (
  merchantId: string
): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    dispatch(setIsLoadingWhitelist(true));
    axios
      .delete(
        `${environment.kushkiUrl}/rules/v1/monitor/whitelist/${merchantId}`
      )
      .then(() => {
        dispatch(
          getWhitelistRules({
            lastEvaluatedKey: {},
            limit: 10,
            items: [],
          })
        );
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.SUCCESS,
            message: RulesNotificationResultEnum.RULE_DELETE,
            open: true,
            type: "success",
          })
        );
        dispatch(setIsLoadingWhitelist(false));
      })
      .catch((e: string) => {
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_ERROR,
            message: defaultTo(
              get(e, "response.data.message"),
              RulesNotificationResultEnum.DEFAULT_MESSAGE_ERROR_GET_RULES
            ),
            open: true,
            type: "error",
          })
        );
        dispatch(setIsLoadingWhitelist(false));
      });
  };
};

export const createWhitelistRule = (
  payload: IWhitelistRuleRequest
): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    dispatch(setIsLoading(true));
    const url: string = `${environment.kushkiUrl}/rules/v1/monitor/whitelist`;

    axios
      .post(url, {
        ...payload,
      })
      .then(() => {
        dispatch(setIsLoading(false));
        window.location.href = "/rules";
      })
      .catch(() => {
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_ERROR,
            message: "No se logró crear la regla.",
            open: true,
            type: "error",
          })
        );
        dispatch(setIsLoading(false));
      });
  };
};

export const getWhitelistRuleMerchant = (
  merchantId: string
): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    dispatch(setIsLoadingWhitelist(true));
    axios
      .post<WhitelistRules>(
        `${environment.kushkiUrl}/rules/v1/monitor/whitelist/rules`,
        { merchantId }
      )
      .then((axios_response: AxiosResponse<WhitelistRules>) => {
        dispatch(setIsLoadingWhitelist(false));
        const data = validateIfWhitelistRulesHasValidationType(
          axios_response.data
        );

        dispatch(setCurrentWhitelistRule(data));
      })
      .catch((e: string) => {
        dispatch(setIsLoadingWhitelist(false));
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_ERROR,
            message: defaultTo(
              get(e, "response.data.message"),
              RulesNotificationResultEnum.DEFAULT_MESSAGE_ERROR_GET_RULES
            ),
            open: true,
            type: "error",
          })
        );
      });
  };
};

export const getMigrateRules = (
  ruleLimit: SecurityRulesByType,
  merchantName: string,
  merchantId: string
): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    let bodyRequest: object = {
      merchantId: get(ruleLimit, "items[0].merchantId", ""),
      limit: ruleLimit.limit,
    };

    if (!isEmpty(ruleLimit.lastEvaluatedKey))
      bodyRequest = {
        ...bodyRequest,
        lastEvaluatedKey: ruleLimit.lastEvaluatedKey,
      };
    dispatch(setIsLoadingRulesByType(true));
    dispatch(setShowMigrationRule(false));
    axios
      .post<SecurityRulesByType>(
        `${environment.kushkiUrl}/rules/v1/migration/${merchantId}`,
        { merchantName }
      )
      .then((_axios_response: AxiosResponse<SecurityRulesByType>) => {
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_SUCCESS,
            message: RulesNotificationResultEnum.RULE_REQUEST_MIGRATE_SUCCESS,
            open: true,
            type: "success",
          })
        );
        dispatch(getSecurityRulesByCommerce(ruleLimit));
        dispatch(setIsLoadingRulesByType(false));
      })
      .catch((e: string) => {
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_ERROR,
            message: defaultTo(
              get(e, "response.data.message"),
              RulesNotificationResultEnum.DEFAULT_MESSAGE_MIGRATE_RULES
            ),
            open: true,
            type: "error",
          })
        );
        dispatch(setShowMigrationRule(true));
        dispatch(setIsLoadingRulesByType(false));
      });
  };
};

export const getSearchKeyCredentials = (
  merchantId: string,
  searchTerm: string
): ThunkAction<void, IRulesState, undefined, RulesAction> => {
  return (dispatch: ThunkDispatch<IRulesState, any, RulesAction>): void => {
    dispatch(setIsLoadingCredentialSuggested(true));
    dispatch(setRequestError(false));
    let bodyRequest: object = {
      merchantId: merchantId,
      searchTerm: searchTerm,
    };

    axios
      .post<MerchantCredential[]>(
        `${environment.kushkiUrl}/payment-credentials/v1/admin/credential/keyCredentials`,
        bodyRequest
      )
      .then((axios_response: AxiosResponse<MerchantCredential[]>) => {
        const response: MerchantCredential[] = get(axios_response, "data.data");
        dispatch(setMerchantsAliasSuggested(response));
        dispatch(setIsLoadingCredentialSuggested(false));
      })
      .catch(() => {
        dispatch(
          setNotification({
            action: RulesNotificationResultEnum.RULES_REQUEST_ERROR,
            message: "No se encontró la credencial.",
            open: true,
            type: "error",
          })
        );
        dispatch(setIsLoadingCredentialSuggested(false));
      });
  };
};

const validateIfWhitelistRulesHasValidationType = (
  data: WhitelistRules
): WhitelistRules => {
  data.items?.forEach((item) => {
    item.validationType =
      isEmpty(item.validationType) || isUndefined(item.validationType)
        ? "maskedCards"
        : item.validationType;
  });

  return data;
};
