import React from "react";

export const FileIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      data-testid={"FileIcon"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#EEF6FF" />
      <path
        d="M23.5 30.5H16.5C15.4391 30.5 14.4217 30.0786 13.6716 29.3284C12.9214 28.5783 12.5 27.5609 12.5 26.5V13.5C12.5 12.4391 12.9214 11.4217 13.6716 10.6716C14.4217 9.92143 15.4391 9.5 16.5 9.5H22.41C22.6752 9.5 22.9296 9.60536 23.1171 9.79289C23.3046 9.98043 23.41 10.2348 23.41 10.5C23.41 10.7652 23.3046 11.0196 23.1171 11.2071C22.9296 11.3946 22.6752 11.5 22.41 11.5H16.5C15.9696 11.5 15.4609 11.7107 15.0858 12.0858C14.7107 12.4609 14.5 12.9696 14.5 13.5V26.5C14.5 27.0304 14.7107 27.5391 15.0858 27.9142C15.4609 28.2893 15.9696 28.5 16.5 28.5H23.5C24.0304 28.5 24.5391 28.2893 24.9142 27.9142C25.2893 27.5391 25.5 27.0304 25.5 26.5V15.41C25.5 15.1448 25.6054 14.8904 25.7929 14.7029C25.9804 14.5154 26.2348 14.41 26.5 14.41C26.7652 14.41 27.0196 14.5154 27.2071 14.7029C27.3946 14.8904 27.5 15.1448 27.5 15.41V26.5C27.5 27.5609 27.0786 28.5783 26.3284 29.3284C25.5783 30.0786 24.5609 30.5 23.5 30.5Z"
        fill="#4498EE"
      />
      <path
        d="M25.5902 17.5H22.4102C22.1474 17.4884 21.8896 17.4252 21.6513 17.3138C21.4131 17.2025 21.1991 17.0453 21.0217 16.8511C20.8443 16.657 20.7069 16.4298 20.6174 16.1825C20.5279 15.9352 20.4881 15.6727 20.5002 15.41V11.59C20.4881 11.3273 20.5279 11.0648 20.6174 10.8175C20.7069 10.5702 20.8443 10.343 21.0217 10.1489C21.1991 9.95473 21.4131 9.7975 21.6513 9.68616C21.8896 9.57482 22.1474 9.51156 22.4102 9.5C22.5571 9.49976 22.7022 9.5319 22.8353 9.59412C22.9684 9.65634 23.0861 9.74712 23.1802 9.86L27.2702 14.77C27.4192 14.9499 27.5006 15.1763 27.5002 15.41C27.5122 15.6727 27.4724 15.9352 27.3829 16.1825C27.2934 16.4298 27.156 16.657 26.9786 16.8511C26.8012 17.0453 26.5873 17.2025 26.349 17.3138C26.1108 17.4252 25.8529 17.4884 25.5902 17.5ZM22.5102 15.5H25.2702L22.5002 12.17V15.41C22.5002 15.41 22.5102 15.48 22.5102 15.5Z"
        fill="#4498EE"
      />
      <path
        d="M23 22H17C16.7348 22 16.4804 21.8946 16.2929 21.7071C16.1054 21.5196 16 21.2652 16 21C16 20.7348 16.1054 20.4804 16.2929 20.2929C16.4804 20.1054 16.7348 20 17 20H23C23.2652 20 23.5196 20.1054 23.7071 20.2929C23.8946 20.4804 24 20.7348 24 21C24 21.2652 23.8946 21.5196 23.7071 21.7071C23.5196 21.8946 23.2652 22 23 22Z"
        fill="#4498EE"
      />
      <path
        d="M23 26H17C16.7348 26 16.4804 25.8946 16.2929 25.7071C16.1054 25.5196 16 25.2652 16 25C16 24.7348 16.1054 24.4804 16.2929 24.2929C16.4804 24.1054 16.7348 24 17 24H23C23.2652 24 23.5196 24.1054 23.7071 24.2929C23.8946 24.4804 24 24.7348 24 25C24 25.2652 23.8946 25.5196 23.7071 25.7071C23.5196 25.8946 23.2652 26 23 26Z"
        fill="#4498EE"
      />
    </svg>
  );
};
