import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import { ROUTES } from "./shared/constants/routes";
import { CreateBusinessRule } from "./containers/CreateBusinessRule/CreateBusinessRule";
import { BusinessRulesListIndex } from "./containers/BusinessRulesList/BusinessRulesListIndex";
import { ModuleSecurityWrapper } from "@kushki/security-wrapper";
import { environment } from "./environments/environment";
import { ComponentIdEnum } from "./shared/enums/ComponentIdEnum";

const Navigation: React.FC = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <ModuleSecurityWrapper
          basePath={environment.kushkiUrl}
          componentId={ComponentIdEnum.M_FRAUD_PREVENTION}
        >
          <Routes>
            <Route
              path={ROUTES.CREATE_BUSINESS_RULE}
              element={<CreateBusinessRule />}
            />
            <Route
              path={ROUTES.EDIT_BUSINESS_RULE}
              element={<CreateBusinessRule />}
            />
            <Route
              path={ROUTES.LIST_BUSINESS_RULES}
              element={<BusinessRulesListIndex />}
            />
            <Route
              path={ROUTES.LIST_BUSINESS_RULES_BATCH}
              element={<BusinessRulesListIndex isBatch={true} />}
            />
          </Routes>
        </ModuleSecurityWrapper>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default connect()(Navigation);
