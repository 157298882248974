import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const mainSubHeaderStyles = createNamedStyles({
  divider: {
    borderWidth: "0px thin 0px 0px",
    borderStyle: "solid",
    borderColor: "#98A8B8",
    color: "text.grey",
  },
  flagPadding: {
    paddingX: 2,
  },
  gridTitle: {
    paddingBottom: 1.5,
  },
  gridText: {
    paddingRight: 2,
  },
  gridCountry: {
    paddingLeft: 1.7,
    paddingRight: 3,
  },
  gridId: {
    paddingLeft: {
      md: 3,
      xs: 1,
    },
    paddingRight: 1.7,
  },
  iconEffect: {
    color: "#023365",
    "&:hover": {
      backgroundColor: "#ECF0F1",
    },
    "&:active": {
      backgroundColor: "#F7F9F9",
    },
  },
});
