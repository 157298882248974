import { useAppDispatch, useAppSelector } from "../../../store/hooks/storeHook";
import { RootState } from "../../../store/store";
import { IUseMassiveUserContainer } from "./useMassiveUserContainer.interfaces";
import { SubHeaderProps } from "../../../components/SubHeader/Subheader";
import { defaultTo, get, isEmpty } from "lodash";
import { ROUTES } from "../../../shared/constants/routes";
import { useEffect, useState } from "react";
import { MerchantNodeData } from "../../../../types/search_merchant_response";
import { MassiveUserData } from "../../../../types/massive_user_request";
import { downloadMassiveUser } from "../../../store/thunks/massiveUserForm/massiveUserForm.thunk";
import { redirectUtil } from "../../../shared/utils/redirectUtil";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from "../../../shared/utils/localStorageUtils";
import {
  setNotification,
  setUrlMassiveUser,
} from "../../../store/reducers/massiveUserForm/massiveUserForm.slice";
import { useSnackbar } from "@kushki/connect-ui";
import { SessionStorageEnum } from "../../../shared/enum/SessionStorageEnum";
import { QueryParamEnum } from "../../../shared/enum/QueryParamEnum";
import { isBackofficeMasterCustomer } from "../../../shared/utils/functions/getRolesUtils";
import { MerchantProperties } from "../../../shared/enum/MerchantEnum";

export const useMassiveUserContainer = (): IUseMassiveUserContainer => {
  const { showSnackbar } = useSnackbar();

  const {
    nodeInfo,
    signedUrl,
    loadingDownload,
    notification,
    base64File,
    fileValidated,
  } = useAppSelector((state: RootState) => ({
    ...state.generalData,
    ...state.massiveUserForm,
  }));
  const [branchList] = useState<MerchantNodeData[]>(
    JSON.parse(sessionStorage.getItem("branchList") || "[]")
  );

  const [isOpenDoubtsModal, setIsOpenDoubtsModal] = useState<boolean>(false);

  const [isOpenProcessingCreationModal, setIsOpenProcessingCreationModal] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();

  const handleBuildHeaderProps = (): SubHeaderProps => {
    const publicMerchantId: string = get(
      nodeInfo,
      MerchantProperties.MERCHANT_ID,
      ""
    );

    return {
      country: get(nodeInfo, "generalInfo.country", ""),
      iconType: {} as JSX.Element,
      id: publicMerchantId,
      isEdit: false,
      redirectPath: `${ROUTES.CREATE_NODE}?publicMerchantId=${publicMerchantId}`,
      subtitle: "País de operación: ",
      title: get(nodeInfo, "generalInfo.name", ""),
    };
  };

  const handleDownloadButton = () => {
    const downloadUserList: MassiveUserData[] = [];

    branchList.map((branchData: MerchantNodeData) => {
      downloadUserList.push({
        merchantId: get(branchData, "merchant_id", ""),
        merchantName: get(branchData, "name", ""),
        nodeId: get(branchData, "node_id", ""),
      });
    });

    const request: {
      request: { branches: MassiveUserData[] };
    } = {
      request: {
        branches: downloadUserList,
      },
    };

    dispatch(downloadMassiveUser(request));
  };

  const onContinueButton = () => {
    const request = {
      request: {
        email: getLocalStorageItem("email"),
        file: base64File,
        merchantId: get(nodeInfo, MerchantProperties.MERCHANT_ID, ""),
      },
    };

    dispatch(downloadMassiveUser(request));
    removeLocalStorageItem(SessionStorageEnum.BRANCH_LIST);
    setIsOpenProcessingCreationModal(true);
  };

  const openDoubtsModal = () => {
    setIsOpenDoubtsModal(true);
  };

  const closeDoubtsModal = () => {
    setIsOpenDoubtsModal(false);
  };

  const onCloseProcessingModal = () => {
    const publicMerchantId: string = get(
      nodeInfo,
      MerchantProperties.MERCHANT_ID,
      ""
    );

    if (isBackofficeMasterCustomer()) {
      redirectUtil(`${ROUTES.USERS_CONSOLE}`);

      return;
    }

    redirectUtil(
      `${ROUTES.MERCHANT_RESUME}?publicMerchantId=${publicMerchantId}&${QueryParamEnum.MODE}=${QueryParamEnum.EDITION}`
    );
  };

  useEffect(() => {
    if (!isEmpty(get(signedUrl, "url"))) {
      const target: HTMLAnchorElement = document.createElement("a");
      const url: string = signedUrl!.url;

      target.style.display = "none";
      document.body.appendChild<HTMLAnchorElement>(target);
      target.href = url;
      target.download = "Plantilla_Usuarios.xlsx";
      target.click();
      window.URL.revokeObjectURL(url);
      dispatch(setUrlMassiveUser({ url: "" }));
    }
  }, [signedUrl]);

  useEffect(() => {
    if (notification) {
      showSnackbar(notification);
      dispatch(setNotification(undefined));
    }
  }, [notification]);

  return {
    closeDoubtsModal,
    handleDownloadButton,
    isOpenDoubtsModal,
    isOpenProcessingCreationModal,
    loadingDownload: defaultTo(loadingDownload, false),
    onCloseProcessingModal,
    openDoubtsModal,
    primaryButton: {
      isDisabled: defaultTo(fileValidated.total_success, 0) === 0,
      onAction: onContinueButton,
    },
    subHeaderProps: handleBuildHeaderProps(),
  };
};
