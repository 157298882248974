import React from "react";
import { Grid, Typography } from "@mui/material";
import { Chargeback } from "../../../../types/chargeback";
import { get } from "lodash";
import { SubtitleSection } from "../../../shared/infraestructure/ChargeBackConstants";

export interface ChargebackSectionProps {
  data: Chargeback;
}

const MerchantSection: React.FC<ChargebackSectionProps> = (
  props: ChargebackSectionProps
) => {
  const merchantId: string = get(props, "data.merchant_id", "");
  const merchantName: string = get(props, "data.merchant_name", "");

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container direction={"row"} spacing={6}>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.grey">
                {SubtitleSection.MERCHANT_NAME}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.grey">
                {merchantName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction={"row"} spacing={6}>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.grey">
                {SubtitleSection.MERCHANT_ID}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={"body2"} color="text.grey">
                {merchantId}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default MerchantSection;
