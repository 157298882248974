import * as React from "react";
import { Grid, Typography } from "@mui/material";
import { paymentMethodStyles as styles } from "./PaymentMethod.styles";
import { PaymentMethodTitle } from "../../shared/enums/PaymentMethodEnum";
import { IPaymentMethodObject } from "../../shared/infrastructure/interface/IPaymentMethodObject";
import PaymentMethodCard from "../PaymentMethodCard/PaymentMethodCard";
import { PaymentMethodTypeEnum } from "../../shared/enums/PaymentMethodTypeEnum";
import { PaymentMethodKeysOrdered } from "../../shared/constants/PaymentMethodConstants";

export interface PaymentMethodInterface {
  paymentMethod?: IPaymentMethodObject;
}

const PaymentMethod = (props: PaymentMethodInterface) => {
  const { paymentMethod } = props;

  return (
    <Grid container sx={styles.gridContainer}>
      <Typography sx={styles.title}>{PaymentMethodTitle.PAY_IN}</Typography>

      <Grid container sx={styles.gridBasic2}>
        {paymentMethod &&
          PaymentMethodKeysOrdered.filter(
            (key: string) =>
              paymentMethod[key].type === PaymentMethodTypeEnum.FUNDRAISING
          ).map((key: string, index: number) => {
            return (
              <PaymentMethodCard
                key={key}
                index={index}
                paymentMethod={paymentMethod[key]}
              />
            );
          })}
      </Grid>
      <Grid item sx={styles.grid}>
        <Typography sx={styles.title}>{PaymentMethodTitle.PAY_OUT}</Typography>
      </Grid>
      <Grid container sx={styles.gridBasic2}>
        {paymentMethod &&
          PaymentMethodKeysOrdered.filter(
            (key: string) =>
              paymentMethod[key].type === PaymentMethodTypeEnum.DISPERSION
          ).map((key: string, index: number) => {
            return (
              <PaymentMethodCard
                key={key}
                index={index}
                paymentMethod={paymentMethod[key]}
              />
            );
          })}
        <Grid item xs={4} />
      </Grid>
    </Grid>
  );
};

export default PaymentMethod;
