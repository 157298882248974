export enum ParentMerchantAttributeName {
  merchantKind = "merchantKind",
  kind = "kind",
}

export enum MerchantKindEnum {
  PARENT = "parent",
  CHILD = "child",
  DEFAULT = "default",
}

export enum MerchantProperties {
  MERCHANT_ID = "merchantId",
  MERCHANT_NAME = "generalInfo.name",
}
