import { createNamedStyles } from "../../../shared/utils/create_named_styles";

export const layoutStyles = createNamedStyles({
  container: {
    alignItems: "center",
    bgcolor: "neutral.grey2",
    borderRadius: "4px 4px 0px 0px",
    display: "flex",
    flexDirection: "row",
    height: "58px",
    p: (theme) => `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  },
});
