import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { X } from "react-feather";
import theme from "../../theme";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { InvoiceRecord } from "../../../types/remote/response_search_blling_by_filters";
import { get, defaultTo } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { IBillingDashboardState } from "../../store/reducer";
import {
  generateCreditNote,
  setNotification,
  setOpenCreditDebitNoteModal,
} from "../../store/actionCreators";
import { Controller, useForm } from "react-hook-form";
import { useCreditNoteStyles } from "./CreditDebitNoteModal.style";
import CheckIcon from "@material-ui/icons/Check";
import NumberFormat from "react-number-format";
import { CreditNoteRequest } from "../../../types/credit_note_request";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import { CreditDebitNoteEnum } from "../../shared/infrastructure/CreditDebitNoteEnum";
import { KindEnum } from "../../shared/infrastructure/KindEnum";

export interface ICreditDebitNoteModal {
  trxInfo: InvoiceRecord | undefined;
  modalKind: CreditDebitNoteEnum;
}

interface CreditDebitNoteFields {
  reason: string;
  newInvoice: boolean;
  fileInvoice: any;
}

const creditNoteReasonsPE: string[] = [
  "Anulación de la operación",
  "Anulación por error en el RUC",
  "Corrección por error en la descripción",
  "Descuento global",
  "Descuento por ítem",
  "Devolución total",
  "Devolución por ítem",
  "Bonificación",
  "Disminución en el valor",
  "Otros Conceptos",
  "Ajustes de operaciones de exportación",
  "Ajustes afectos al IVAP",
];

const creditNoteReasonsCO: string[] = [
  "Devolución de parte de los bienes; no aceptación de partes del servicio",
  "Anulación de factura electrónica",
  "Rebaja total aplicada",
  "Descuento total aplicado",
  "Rescisión: nulidad por falta de requisitos",
  "Otros",
];

const debitNoteReasonsPE: string[] = [
  "Intereses por mora",
  "Aumento en el valor",
  "Penalidades / otros conceptos",
  "Ajustes de operaciones de exportación",
  "Ajustes afectos al IVAP",
];

const debitNoteReasonsCO: string[] = [
  "Intereses",
  "Gastos por cobrar",
  "Cambio del valor",
  "Otros",
];

const CREDIT_NOTE_REASONS: Record<CountryEnum | string, string[]> = {
  [CountryEnum.peru]: creditNoteReasonsPE,
  [CountryEnum.colombia]: creditNoteReasonsCO,
  [CountryEnum.mexico]: [],
  ["default"]: [],
};

const DEBIT_NOTE_REASONS: Record<CountryEnum | string, string[]> = {
  [CountryEnum.peru]: debitNoteReasonsPE,
  [CountryEnum.colombia]: debitNoteReasonsCO,
  ["default"]: [],
};

export const CreditDebitNoteModal: React.FC<ICreditDebitNoteModal> = (
  props: ICreditDebitNoteModal
) => {
  const { trxInfo, modalKind } = props;
  const country: string = get(trxInfo, "country", CountryEnum.peru);
  const [checkInvoice, setCheckInvoice] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const [fileBase64, setFileBase64] = useState<string>("");
  const dispatch = useDispatch();
  const classes = useCreditNoteStyles();
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 230,
      },
    },
  };
  const { handleSubmit, control } = useForm<CreditDebitNoteFields>();
  const { openModal } = useSelector((state: IBillingDashboardState) => ({
    openModal: state.openCreditDebitNoteModal,
  }));

  const actionButtonLabel: string = `Generar Nota de ${modalKind}`;

  const onSubmit = (data: CreditDebitNoteFields) => {
    let creditNoteData: CreditNoteRequest = {
      country,
      fileName,
      kind:
        modalKind === CreditDebitNoteEnum.CREDIT
          ? KindEnum.CREDIT_NOTE
          : KindEnum.DEBIT_NOTE,
      file: fileBase64.split(",")[1],
      transactionId: get(trxInfo, "transaction_id"),
      reason: data.reason,
    };

    dispatch(generateCreditNote(creditNoteData, checkInvoice));
  };
  const handleCheckboxInvoice = (event: any[]) => {
    setCheckInvoice(event[1]);
  };
  const onFileChange = (fileList: FileList | null) => {
    if (!fileList || fileList.length === 0) return;

    const reader = new FileReader();
    const file: File = fileList[0];

    setFileName(file.name);
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFileBase64(reader.result as string);
    };
  };
  useEffect(() => {
    dispatch(
      setNotification({
        open: false,
        message: "",
        type: "success",
      })
    );
  }, []);
  const getTotalAmount = () => {
    const amount = get(trxInfo, "amount", undefined);
    const currency = get(trxInfo, "currency_code", "USD");

    if (isNaN(amount)) return "";

    return (
      <NumberFormat
        value={amount}
        thousandSeparator
        displayType={"text"}
        className={classes.primaryLight}
        decimalScale={2}
        suffix={`${currency}`}
      />
    );
  };

  const translateKind = (kind: string) => {
    const KIND_TRANSLATIONS = {
      invoice: "factura",
      dispersion: "dispersión",
      default: "factura",
    };

    return kind ? KIND_TRANSLATIONS[kind] : KIND_TRANSLATIONS["default"];
  };

  return (
    <Dialog open={openModal!} maxWidth={"md"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle className={classes.root}>
          <Typography variant="h5" color="inherit" className={classes.bold}>
            ¿Deseas generar una Nota de {modalKind}?
          </Typography>

          <IconButton
            onClick={() => dispatch(setOpenCreditDebitNoteModal(false))}
            aria-label="close"
            className={classes.closeButton}
            id={"credit-note-close-dialog"}
          >
            <X size={18} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="h6" color="inherit" id="credit-note-details">
              Estás a punto de aplicar una Nota de {modalKind} a la{" "}
              <span className={classes.primaryLight}>
                {translateKind(trxInfo!.kind)}{" "}
                {[CountryEnum.colombia].includes(
                  get(props.trxInfo, "country", " ")
                )
                  ? get(props.trxInfo, "invoice_id", " ")
                  : get(props.trxInfo, "invoice_number", " ")}
              </span>{" "}
              por un monto total de {getTotalAmount()}. Para generar el
              documento dar click en
              <span className={classes.primaryDark}>
                {" "}
                &quot;{actionButtonLabel}&quot;
              </span>{" "}
              recuerda que esta acción es irreversible.
            </Typography>
          </DialogContentText>
          <Typography variant={"h6"} className={classes.bold}>
            Seleccione el motivo para generar la Nota de {modalKind}
          </Typography>

          <FormControl
            variant="outlined"
            className={classes.textFieldMultiLine}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Motivo de la Nota de {modalKind}
            </InputLabel>
            <Controller
              id={"reasons-controller"}
              as={
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={3}
                  label={`Motivo de la Nota de ${modalKind}`}
                  MenuProps={MenuProps}
                >
                  {defaultTo(
                    modalKind === CreditDebitNoteEnum.CREDIT
                      ? CREDIT_NOTE_REASONS[country]
                      : DEBIT_NOTE_REASONS[country],
                    []
                  ).map((reason) => (
                    <MenuItem value={reason} key={reason}>
                      {reason}
                    </MenuItem>
                  ))}
                </Select>
              }
              name="reason"
              defaultValue=""
              control={control}
            />
          </FormControl>
          <Grid className={classes.uploadFileContainer}>
            <Typography variant={"subtitle2"} className={classes.bold}>
              Adjunte el sustento para generar la Nota de {modalKind}
            </Typography>
            <Typography variant={"body2"} className={classes.sizeMessage}>
              Tamaño máximo de archivo 10 MB .png, .jpg .pdf
            </Typography>
          </Grid>
          <Grid className={classes.uploadFileContainer}>
            <label htmlFor={"invoiceAnnulFile"}>
              <input
                id="invoiceAnnulFile"
                color="primary"
                type="file"
                style={{ display: "none" }}
                name="fileInvoice"
                onChange={(event) => onFileChange(event.target.files)}
              />
              <Grid container>
                <Grid item xs={12} style={{ paddingRight: 10 }}>
                  <Box display="flex">
                    <Box>
                      <Button
                        variant="outlined"
                        component="span"
                        style={{
                          color: theme.palette.primary.dark,
                          border: `1px solid ${theme.palette.primary.dark}`,
                        }}
                        size={"medium"}
                      >
                        Elegir Archivo
                      </Button>
                    </Box>
                    {fileName && (
                      <Box pl={1}>
                        <Typography
                          variant="body1"
                          className={classes.fileSelected}
                          id="credit-note-file-name"
                        >
                          {fileName}
                          <CheckIcon />
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Typography
                    variant={"caption"}
                    className={classes.fileMessage}
                  >
                    Haz clic en “Elegir archivo” para buscar el documento en tu
                    dispositivo.
                  </Typography>
                </Grid>
              </Grid>
            </label>
          </Grid>
          <Divider className={classes.divider} />
          <Controller
            id={"checkbox-invoice"}
            as={
              <FormControlLabel
                control={<Checkbox color={"primary"} />}
                label={
                  "Generar una nueva factura al finalizar la Nota de " +
                  modalKind
                }
              />
            }
            name="newInvoice"
            control={control}
            onChange={handleCheckboxInvoice}
          />
          <Grid container alignItems={"center"}>
            <Grid item xs className={classes.infoIcon}>
              <InfoOutlinedIcon color={"primary"} />
            </Grid>
            <Grid item xs>
              <Typography variant="body1">
                Pueden existir documentos asociados a esta factura que se
                reasignarán a la nueva factura creada.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            id={"credit-note-cancel-dialog"}
            onClick={() => dispatch(setOpenCreditDebitNoteModal(false))}
            className={classes.cancelButton}
          >
            Cancelar
          </Button>
          <Button type={"submit"} autoFocus className={classes.annulButton}>
            {actionButtonLabel}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
