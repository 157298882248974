import { makeStyles } from "@mui/styles";
import { IDialogMessageProps } from "./DialogMessage.interfaces";
import { validationMXProcessorKushki } from "../../../shared/utils/transaction_list_utils";

export const useStyles = makeStyles(() => ({
  styleText: {
    position: "static",
    width: "516.64px",
    left: "0px",
    top: "0px",
    color: "#000000",
    fontSize: "16px",
    marginTop: (props: IDialogMessageProps) =>
      validationMXProcessorKushki(props.transaction!) ? "25px" : "0px",
    marginBottom: "18px",
  },
}));
