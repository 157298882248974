/**
 *
 */
export enum UtcEnum {
  utc_11 = "-11:00",
  utc_10 = "-10:00",
  utc_09 = "-09:00",
  utc_08 = "-08:00",
  utc_07 = "-07:00",
  utc_06 = "-06:00",
  utc_05 = "-05:00",
  utc_04 = "-04:00",
  utc_03 = "-03:00",
  utc_02 = "-02:00",
  utc_01 = "-01:00",
  utc00 = "+00:00",
  utc01 = "+01:00",
  utc02 = "+02:00",
  utc03 = "+03:00",
  utc04 = "+04:00",
  utc05 = "+05:00",
  utc06 = "+06:00",
  utc07 = "+07:00",
  utc08 = "+08:00",
  utc09 = "+09:00",
  utc10 = "+10:00",
  utc11 = "+11:00",
  utc12 = "+12:00",
}
