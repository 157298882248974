export enum SecurityWrapperEnum {
  ROOT_MODULE = "M_GLOBAL_CONFIG",
  RETENCIONES_ELIMINAR_IVA = "M_GLOBAL_CONFIG.ImpuestosRetencionesComercios.EliminarIva",
  RETENCIONES_ELIMINAR_RENTA = "M_GLOBAL_CONFIG.ImpuestosRetencionesComercios.EliminarRenta",
  RETENCIONES_AGREGAR_EDITAR_IVA = "M_GLOBAL_CONFIG.ImpuestosRetencionesComercios.AgregarEditarIva",
  RETENCIONES_AGREGAR_EDITAR_RENTA = "M_GLOBAL_CONFIG.ImpuestosRetencionesComercios.AgregarEditarRenta",
  RETENCIONES_CARGAR_ARCHIVO_IVA_ICA = "M_GLOBAL_CONFIG.ImpuestosRetencionesComercios.CargarArchivoIvaIca",
  RETENCIONES_CARGAR_ARCHIVO_RENTA = "M_GLOBAL_CONFIG.ImpuestosRetencionesComercios.CargarArchivoRenta",
  RETENCIONES_DESCARGAR_PLANTILLA_EXCEL_IVA_ICA = "M_GLOBAL_CONFIG.ImpuestosRetencionesComercios.DescargarPlantillaExcelIvaIca",
  RETENCIONES_DESCARGAR_PLANTILLA_EXCEL_RENTA = "M_GLOBAL_CONFIG.ImpuestosRetencionesComercios.DescargarPlantillaExcelRenta",
  RETENCIONES_DESCARGAR_DATOS_EXCEL_IVA_ICA = "M_GLOBAL_CONFIG.ImpuestosRetencionesComercios.DescargarDatosExcelIvaIca",
  RETENCIONES_DESCARGAR_DATOS_EXCEL_RENTA = "M_GLOBAL_CONFIG.ImpuestosRetencionesComercios.DescargarDatosExcelRenta",
}
