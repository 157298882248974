import React, { PropsWithChildren } from "react";
import { IGeneralTextFieldProps } from "../../../../shared/infrastructure/interfaces/IGeneralTextFieldProps";
import { SelectFieldComponent } from "../SelectFieldComponent/SelectFieldComponent";

export const GeneralTextField: React.FC<IGeneralTextFieldProps> = (
  props: PropsWithChildren<IGeneralTextFieldProps>
) => {
  return (
    <SelectFieldComponent
      conditionProperty={props.conditionProperty}
      isLoading={false}
      placeHolder={props.placeHolder}
      sectionTitle={""}
      items={props.items}
      sectionDescription={""}
      setSelectedItems={props.setSelectedItems}
      selected={props.selected}
      maxSelectedItems={props.maxSelectedItems}
    />
  );
};
