import { IStyles } from "../../shared/interfaces/Styles.interfaces";

const GenericTabsStyles: IStyles = {
  genericTabContent: {
    flexGrow: "1",
    width: "100%",
    height: "100%",
  },
  genericTabContainer: {
    display: "flex",
    marginRight: "8",
    width: "100%",
    flexDirection: "column",
  },

  // after rebuild modalDetailCard change values of height and padding button to the class box tabs
  boxTabs: {
    overflowY: "scroll",
    borderRadius: "4px",
    marginTop: "20px",
    marginBottom: "16px",
    height: "50%",
    paddingBottom: "55em",
  },
  // after rebuild modalDetailCard change values of height and padding button to the class box boxTabsNoMargin
  boxTabsNoMargin: {
    overflowY: "scroll",
    borderRadius: "4px",
    marginTop: 0,
    marginBottom: "16px",
    height: "50%",
    paddingBottom: "55em",
  },
};

export { GenericTabsStyles };
