import React from "react";
import { DialogTitle, IconButton, Theme, Typography } from "@material-ui/core";
import { X } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";

export interface DeferredDialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: (value: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    color: theme.palette.grey[500],
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}));

export const DeferredDialogTitle: React.FC<DeferredDialogTitleProps> = (
  props: DeferredDialogTitleProps
) => {
  const classes = useStyles();

  return (
    <DialogTitle disableTypography className={classes.root} id={props.id}>
      <Typography color={"primary"} variant="h5">
        {props.children}
      </Typography>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={() => props.onClose("cancel")}
      >
        <X size={20} />
      </IconButton>
    </DialogTitle>
  );
};
