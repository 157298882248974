import React from "react";
import { Box, Input, Stack } from "@mui/material";
import CopyClipBoardButton from "../CopyClipBoardButton/CopyClipBoardButton";
import { styles } from "./RawDataViewer.styles";
import { IRawDataViewerProps } from "./RawDataViewer.interfaces";

const RawDataViewer = ({ data }: IRawDataViewerProps) => {
  return (
    <Box sx={styles.informationCard}>
      <Stack sx={styles.copyContainer}>
        <CopyClipBoardButton text={JSON.stringify(data, undefined, 3)} />
      </Stack>
      <Input
        sx={styles.transactionDataText}
        readOnly
        multiline
        defaultValue={JSON.stringify(data, undefined, 3)}
      ></Input>
    </Box>
  );
};

export default RawDataViewer;
