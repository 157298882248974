import { createNamedStyles } from "../../shared/interfaces/create_named_styles";

export const transactionListStyles = createNamedStyles({
  boxContainer: {
    padding: "2rem 3rem",
  },
  boxIcon: {
    display: "flex",
    alignItems: "center",
  },
  boxTable: {
    overflowX: "auto",
    "& > div > table > tbody > tr > td:nth-of-type(3) > div > div": {
      width: "100%",
    },
    "& > div > table > thead > tr ": {
      height: "62px",
    },
    "& > div > table > thead > tr > th > div > h6": {
      fontSize: "14px",
    },
    "& > div > table > tbody > tr > td > div > div > h6": {
      fontSize: "13px !important",
    },
  },
  paginationPageNavigationButton: {
    alignItems: "center",
    borderRadius: "4px",
    boxShadow: "0px 8px 20px rgba(91, 91, 91, 0.0584805)",
    color: "primary.main",
    cursor: "select",
    display: "flex",
    fontSize: "13px",
    height: "32px",
    justifyContent: "center",
    marginLeft: "9px",
    minWidth: "32px",
    padding: 0,
    width: "32px",
  },
  paginationToolbar: {
    "& > .MuiToolbar-root": {
      display: "flex",
      flexWrap: "wrap",
      paddingX: "16px",
      placeContent: "space-between",
      width: "100%",
      "& > .MuiTablePagination-actions": {
        display: "flex",
        flexDirection: "row",
      },
    },
  },
  createCustomerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    mt: "3rem",
  },
  divider: {
    borderWidth: "0px thin 0px 0px",
    borderStyle: "solid",
    color: "text.lightGrey",
    marginTop: "2px",
    marginBottom: "2px",
  },
  emptyCenter: {
    textAlign: "center",
  },
  error1: {
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#AD0C2A",
    },
  },
  goBackBtn: {
    color: "#4399ED",
  },
  gridCountry: {
    paddingLeft: 1.7,
    paddingRight: 2,
    justifyContent: "space-between",
  },
  gridId: {
    paddingLeft: {
      md: 3,
      xs: 1,
    },
    paddingRight: 1.7,
  },
  gridText: {
    paddingRight: 2,
  },
  gridTitle: {
    paddingBottom: 1.5,
  },
  select: {
    width: "328px",
  },
});
