import React from "react";
import { SnackBarProvider, theme } from "@kushki/connect-ui";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import Navigation from "./navigation.component";

const Root = () => {
  return (
    <StyledEngineProvider>
      <React.StrictMode>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <SnackBarProvider>
              <Navigation />
            </SnackBarProvider>
          </ThemeProvider>
        </Provider>
      </React.StrictMode>
    </StyledEngineProvider>
  );
};

export default Root;
