import { TableSimple } from "@kushki/connect-ui";
import React, { FC } from "react";
import { UseTableComponent } from "./state/useTableComponent";
import { TABLE_LABEL } from "../../shared/constants/labels/table_labels";
import { ItemsPerPage } from "../../shared/constants/table_container";
import { ITableComponent } from "./TableComponent.interfaces";
import { get, isEmpty } from "lodash";
import DetailModal from "../DetailModal/DetailModal";
import { useAppSelector } from "../../store/hooks/storeHook";
import { RootState } from "../../store/store";

const TableComponent: FC<ITableComponent> = (props: ITableComponent) => {
  const { cells, rows, isLoadingTrx } = UseTableComponent(props);
  const { showDetailModal, currentTransaction } = useAppSelector(
    (state: RootState) => ({
      ...state.walletTransaction,
    })
  );

  return (
    <React.Fragment>
      <TableSimple
        footerPagination={{
          isDisable: false,
          itemsPerPage: props.itemsPerPage,
          onItemsPerPageChange: (value) =>
            props.action.handleChangeLimit(value),
          onPageClick: (value: number) => props.action.handleChangePage(value),
          paginationText: TABLE_LABEL.footer_pagination,
          selectedPage: props.page,
          textFieldSelector: ItemsPerPage,
          totalItems: get(props, "transactions.total", 0),
        }}
        header={{
          cells,
          headerProps: {
            isDisable: false,
          },
        }}
        rows={rows}
        isLoading={isLoadingTrx}
        showPagination
      />
      {showDetailModal && !isEmpty(currentTransaction) && (
        <DetailModal
          trx={currentTransaction!}
          country={props.country}
          isOpen={showDetailModal}
        />
      )}
    </React.Fragment>
  );
};

export default TableComponent;
