import { defaultTo, get, isInteger, isNil } from "lodash";
import { CountryEnum } from "../enums/CountryEnum";
import { LocalStorageItemsEnum } from "../enums/LocalStorageItemsEnum";
import { COUNTRY_ISO_CODE, CurrencyEnum } from "../enums/CurrencyEnum";
import { REGEX_COMMONS } from "../constants/regex";
import React from "react";

const isCurrencyCOPorCLP = (currency: string): boolean =>
  currency === CurrencyEnum.COP || currency === CurrencyEnum.CLP;

export const getCountryMerchant = (merchantInfoAsString: string | null) => {
  try {
    const merchantInfo = defaultTo(
      merchantInfoAsString,
      JSON.stringify({
        country: null,
      })
    );

    return JSON.parse(merchantInfo);
  } catch {
    return { country: null };
  }
};

export const getCountryMerchantFromLocalStorage = (): CountryEnum | null => {
  const merchantInfo = getCountryMerchant(
    localStorage.getItem(LocalStorageItemsEnum.MERCHANT_INFO)
  );

  return get(merchantInfo, "country", null);
};

export const roundAmount = (amount: number, currency: string): string => {
  if (!REGEX_COMMONS.NUMBER.test(amount.toString())) return "0.00";

  switch (currency) {
    case CurrencyEnum.COP:
    case CurrencyEnum.CLP:
      return amount.toFixed(0);
    case CurrencyEnum.USD:
    case CurrencyEnum.PEN:
    case CurrencyEnum.MXN:
    case CurrencyEnum.UF:
      return amount.toFixed(2);
    default:
      return amount.toString();
  }
};

export const formatInputAmount = (amount: number, currency: string): string => {
  if (isInteger(amount)) return amount.toString();

  if (isCurrencyCOPorCLP(currency)) return Math.trunc(amount).toString();

  const sAmount = amount.toString();

  return sAmount.slice(0, sAmount.indexOf(".") + 3);
};

export const getMaximumFractionPerCountry = (country: string) => {
  switch (country) {
    case CountryEnum.COLOMBIA:
    case CountryEnum.CHILE:
      return 0;
    default:
      return 2;
  }
};

export const formatAmount = (
  amount: number,
  currency: string,
  showCurrency = true
) => {
  let amount_by_currency = roundAmount(amount, currency);
  const country = getCountryMerchantFromLocalStorage();

  if (currency && !isNil(country)) {
    const formatter = new Intl.NumberFormat(`es-${COUNTRY_ISO_CODE[country]}`, {
      currency: currency,
      maximumFractionDigits: getMaximumFractionPerCountry(country),
      style: "currency",
    });

    amount_by_currency = formatter.format(+amount_by_currency);
  }

  return `${amount_by_currency} ${showCurrency ? currency : ""}`;
};

export const validateKeyForInput = (
  event: React.KeyboardEvent<HTMLDivElement>
) => {
  if (REGEX_COMMONS.SPECIAL_NUMBER.test(event.key)) {
    event.preventDefault();
  }
};
