import { IStyles } from "../../../shared/interfaces/Styles.interfaces";

export const downloadButtonStyles: IStyles = {
  containerButton: {
    "&.MuiButton-root": {
      textTransform: "none",
    },
    border: "1px solid #CBD5E0",
    borderRadius: "4px",
    color: "#293036",
    fontSize: "16px",
    fontWeight: 500,
    height: "44px",
    lineHeight: "150%",
    padding: "7px 30px",
    textTransform: "none",
    width: "170px",
    marginLeft: "16px",
  },
  boxCircularColor: {
    paddingLeft: "10px",
  },
  circularColor: {
    color: "#023365",
  },
};
