import React from "react";
import TimelineDot from "@mui/lab/TimelineDot";
import { ChildrenProps } from "../../Timeline.interfaces";
import { styles } from "./TimeLineIcon.styles";

const TimeLineIcon = ({ children }: ChildrenProps) => (
  <TimelineDot variant="outlined" sx={styles.container}>
    {children}
  </TimelineDot>
);

export default TimeLineIcon;
