import React, { FC } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import {
  massiveUserContainerStyles,
  massiveUserContainerStyles as styles,
} from "./MassiveUserContainer.styles";
import {
  IconDownload,
  IconEdit,
  ModalContent,
  ModalContentButtonTypeEnum,
  ModalContentTypeEnum,
  ModalSimple,
} from "@kushki/connect-ui";
import Subheader from "../../components/SubHeader/Subheader";
import { WrapperContainer } from "../WrapperContainer/WrapperContainer";
import { useMassiveUserContainer } from "./state/useMassiveUserContainer";
import { branchListContainerStyles } from "../BranchListContainer/BranchListContainer.styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
import FileUploadContainer from "../../components/FileUploadContainer/FileUploadContainer";
import FooterOptions from "../../components/FooterOptions/FooterOptions";
import {
  MassiveUserContentEnum,
  MassiveUserInstructions,
} from "../../shared/enum/MassiveUserContentEnum";
import { emptyFunction } from "../../shared/utils/emptyFunctionUtil";

const MassiveUserContainer: FC = () => {
  const {
    subHeaderProps,
    handleDownloadButton,
    loadingDownload,
    isOpenDoubtsModal,
    openDoubtsModal,
    closeDoubtsModal,
    primaryButton,
    isOpenProcessingCreationModal,
    onCloseProcessingModal,
  } = useMassiveUserContainer();

  return (
    <WrapperContainer paginationLimit={1}>
      <Box sx={branchListContainerStyles.centerContainer}>
        <Grid
          container
          spacing={1}
          sx={massiveUserContainerStyles.gridButtonBack}
          alignItems="center"
        >
          <Grid
            item
            container
            xs={12}
            md={"auto"}
            alignItems="center"
            sx={massiveUserContainerStyles.contentBack}
          >
            <IconButton
              onClick={() => window.history.go(-1)}
              sx={massiveUserContainerStyles.iconArrow}
            >
              <ArrowBackIosIcon />
              Regresar
            </IconButton>
          </Grid>
        </Grid>
        <Box sx={styles.headerStyle}>
          <Grid container xs={12} spacing={5}>
            <Grid item xs={12}>
              <Subheader {...subHeaderProps} iconType={<IconEdit />} />
            </Grid>
          </Grid>
        </Box>
        <br />
        <Box>
          <Card sx={massiveUserContainerStyles.cardStyle}>
            <CardContent>
              <Container sx={massiveUserContainerStyles.contentStyle}>
                <Box
                  display="flex"
                  flexGrow={1}
                  alignItems={"center"}
                  height={"100%"}
                >
                  <Typography
                    sx={massiveUserContainerStyles.boxTitle}
                    variant="h1"
                    align="left"
                    color="inherit"
                  >
                    Agregar Usuario
                  </Typography>
                </Box>

                <Box sx={massiveUserContainerStyles.stepContainer}>
                  <Typography
                    sx={massiveUserContainerStyles.stepStyle}
                    variant="h2"
                    align="left"
                  >
                    Paso 1:
                  </Typography>
                  <Box>
                    <Box sx={massiveUserContainerStyles.download}>
                      <Typography sx={massiveUserContainerStyles.textStyle}>
                        Descarga la plantilla excel:
                      </Typography>
                      <LoadingButton
                        className={"buttonLink"}
                        startIcon={<IconDownload />}
                        onClick={handleDownloadButton}
                        loading={loadingDownload}
                        loadingPosition={"start"}
                      >
                        Plantilla Usuarios.xlsx
                      </LoadingButton>
                    </Box>
                    <Box>
                      <Typography sx={massiveUserContainerStyles.secondText}>
                        Abre el archivo y completa los campos requeridos en el
                        documento. Luego, guárdalo y súbelo a continuación.
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box sx={massiveUserContainerStyles.stepContainer} mt="30px">
                  <Typography
                    sx={massiveUserContainerStyles.stepStyle}
                    variant="h2"
                    align="left"
                  >
                    Paso 2:
                  </Typography>
                  <FileUploadContainer />
                </Box>
              </Container>
              <Grid
                container
                spacing={2}
                direction={"row"}
                sx={branchListContainerStyles.doubtsContainer}
              >
                <Typography
                  sx={massiveUserContainerStyles.doubtsText}
                  align="left"
                >
                  {MassiveUserContentEnum.DOUBTS_QUESTION}
                  <Typography
                    onClick={openDoubtsModal}
                    sx={massiveUserContainerStyles.promptText}
                  >
                    {MassiveUserContentEnum.PROMPT_HERE}
                  </Typography>
                </Typography>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Box>
        <ModalContent
          titleText={MassiveUserContentEnum.MODAL_DOUBT_TITLE}
          descriptionText={""}
          isOpen={isOpenDoubtsModal}
          onClose={closeDoubtsModal}
          buttonAction={closeDoubtsModal}
          buttonText={MassiveUserContentEnum.UNDERSTOOD}
          buttonType={ModalContentButtonTypeEnum.PRIMARY}
          type={ModalContentTypeEnum.DEFAULT}
        >
          <Typography>
            {MassiveUserContentEnum.INSTRUCTION}
            <ul>
              {MassiveUserInstructions.map((text, index) => (
                <li key={`instr-${index}`}>{text}</li>
              ))}
            </ul>
          </Typography>
        </ModalContent>
      </Box>
      <Box>
        <ModalSimple
          onClickPrimary={onCloseProcessingModal}
          onClickSecondary={emptyFunction}
          buttonPrimary={MassiveUserContentEnum.UNDERSTOOD}
          descriptionText={MassiveUserContentEnum.PROCESSING_DESCRIPTION}
          isOpen={isOpenProcessingCreationModal}
          onClose={onCloseProcessingModal}
          titleText={MassiveUserContentEnum.CONFIGURING_USERS}
          typeModal={1}
          buttonPrimaryDisabled={false}
        />
      </Box>
      <Box sx={branchListContainerStyles.boxFooter}>
        <FooterOptions
          primaryButton={primaryButton}
          secondaryButton={{
            isHidden: true,
          }}
        />
      </Box>
    </WrapperContainer>
  );
};

export default MassiveUserContainer;
