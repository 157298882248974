import { StatusFlowEnum } from "../StatusFlowEnum";
import { ConfirmRetentionModalEnum } from "../enum/RetentionTextValuesEnum";

export const ConfirmRetentionModalConstants = {
  [StatusFlowEnum.EXECUTOR]: {
    title: ConfirmRetentionModalEnum.EXECUTOR_CONFIRM_MESSAGE,
    contentText: ConfirmRetentionModalEnum.EXECUTOR_CONTENT_LABEL,
    acceptText: ConfirmRetentionModalEnum.EXECUTOR_ACCEPT_LABEL,
  },
  [StatusFlowEnum.VALIDATOR]: {
    title: ConfirmRetentionModalEnum.VALIDATOR_CONFIRM_MESSAGE,
    contentText: ConfirmRetentionModalEnum.VALIDATOR_CONTENT_LABEL,
    acceptText: ConfirmRetentionModalEnum.VALIDATOR_ACCEPT_LABEL,
  },
};
