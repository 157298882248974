import React from "react";
import { makeStyles } from "@mui/styles";
import { BRANDS } from "../../shared/infrastructure/constants/CardBrands";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles(() => ({
  cardLogos: {
    height: "32px",
    paddingRight: "8px",
  },
  container: {
    display: "flex",
  },
}));

interface IBrandCellProps {
  processorName: string;
}

export const BrandCell: React.FC<IBrandCellProps> = (
  props: IBrandCellProps
) => {
  const classes = useStyles();

  return (
    <Grid className={classes.container} spacing={3} direction="row">
      {!BRANDS[props.processorName] ? (
        <a> No disponible </a>
      ) : (
        BRANDS[props.processorName].map((brandImage: string, index: number) => (
          <img
            src={brandImage}
            key={index}
            alt={brandImage}
            className={classes.cardLogos}
          />
        ))
      )}
    </Grid>
  );
};
