import { makeStyles } from "@material-ui/core/styles";

export const useNotificationDetailStyles = makeStyles(() => ({
  cardSection: {
    marginBottom: 20,
  },
  cardContent: {
    paddingLeft: 50,
  },
  cardContentRoot: {
    padding: "0 !important ",
  },
  boxTitle: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 10,
    paddingBottom: 10,
  },
}));
