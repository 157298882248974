import { IValueCell } from "../../TableCell/CellHeader/interfaces";
import { findIndex } from "lodash";

export const pushOrReplaceObjectInValueCells = (
  valueCells: IValueCell[],
  valueCell: IValueCell
): IValueCell[] => {
  const index: number = findIndex(valueCells, { id: valueCell.id });

  if (index >= 0) valueCells.splice(index, 1, valueCell);
  else valueCells.push(valueCell);

  return valueCells;
};
