export const links = [
  {
    name: "People",
    href: "/people",
  },
  {
    name: "Planets",
    href: "/planets",
  },
];
