import * as React from "react";
import { TableCell } from "@material-ui/core";
import { defaultTo } from "lodash";
import { ReactNode } from "react";
import { IColumnDefinition } from "../../../shared/infrastructure/interfaces/IColumnDefinition";

export interface ITableCellCustomProps {
  itemHeader: IColumnDefinition;
  headerBg?: string;
  node: string | number | ReactNode;
}
export const TableCellCustom: React.FC<ITableCellCustomProps> = (
  props: ITableCellCustomProps
) => {
  const { itemHeader, headerBg, node } = props;
  return (
    <TableCell
      style={{
        backgroundColor: defaultTo(headerBg, "initial"),
        maxWidth: `${itemHeader.maxWidth ? itemHeader.maxWidth : "initial"}`,
      }}
    >
      {node}
    </TableCell>
  );
};
