import React from "react";
import { IDefaultProcessorForm } from "../../../../../shared/infrastructure/interfaces/IDefaultProcessorForm";
import { useTypedController } from "@hookform/strictly-typed";
import ConnectForm from "../../../../commons/ConnectForm/ConnectForm";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Select,
  Typography,
} from "@material-ui/core";
import { LabelField } from "../../../../../shared/infrastructure/constants/RulesConstants";
import { get } from "lodash";
import { ErrorsEnum } from "../../../../../shared/infrastructure/enums/ErrorEnum";
import { ProcessorsInformation } from "../../../../../../types/processors_list";

export interface SelectProcessorProps {
  title: string;
  isRequest: boolean;
  controllerName: string;
  processors: ProcessorsInformation[];
}

export const SelectProcessor: React.FC<SelectProcessorProps> = (
  props: SelectProcessorProps
) => {
  return (
    <ConnectForm<IDefaultProcessorForm>>
      {({ control, errors }) => {
        const TypedController = useTypedController<IDefaultProcessorForm>({
          control: control,
        });
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant={"body1"} color={"textSecondary"}>
                {props.title}
              </Typography>
            </Grid>
            <Grid item xs={props.isRequest ? 8 : 6}>
              <TypedController
                // @ts-ignore
                name={props.controllerName}
                render={(typedProps) => (
                  <FormControl
                    variant="outlined"
                    data-testid={`actionWrapper_${props.controllerName}`}
                    fullWidth
                    style={{ height: "100%" }}
                    error={!!errors[props.controllerName]}
                  >
                    {props.isRequest ? (
                      <Grid container>
                        <Grid item xs={10}>
                          <Select
                            {...typedProps}
                            native
                            defaultValue={"-1"}
                            disabled
                            style={{ width: "85%" }}
                          >
                            <option key={"Elegir"} value={""} disabled={true}>
                              {LabelField.SELECT_PROCESSOR}
                            </option>
                          </Select>
                        </Grid>
                        <Grid item xs={2} style={{ margin: "auto" }}>
                          <CircularProgress color="inherit" size={20} />
                        </Grid>
                      </Grid>
                    ) : (
                      <Select
                        {...typedProps}
                        native
                        defaultValue={"-1"}
                        error={!!errors[props.controllerName]}
                        required
                      >
                        <option key={"Elegir"} value={""} disabled={true}>
                          {LabelField.SELECT_PROCESSOR}
                        </option>
                        {props.processors.map(
                          (item: ProcessorsInformation, index) => (
                            <option
                              key={`processorName${index}_${props.controllerName}`}
                              value={get(
                                item,
                                "alias",
                                get(
                                  item,
                                  "StoreInformation.CreditInfo.processorAlias",
                                  ""
                                )
                              )}
                            >
                              {get(
                                item,
                                "alias",
                                get(
                                  item,
                                  "StoreInformation.CreditInfo.processorAlias",
                                  ""
                                )
                              )}
                            </option>
                          )
                        )}
                      </Select>
                    )}
                    <FormHelperText error={!!errors[props.controllerName]}>
                      {!!errors[props.controllerName] &&
                        ErrorsEnum.REQUIRED_FIELD}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        );
      }}
    </ConnectForm>
  );
};
