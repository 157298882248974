/**
 * Action Enumeration
 */
import { ActionOptionsEnum } from "./ActionOptionsEnum";
import { StatusFlowEnum } from "./StatusFlowEnum";

export enum ActionEnum {
  refund = "Reembolso",
  refund_v = "Reembolsar",
  giveback = "Devolver",
  skip = "Omitir",
  cancel = "Cancelar",
  reentry = "Reingresar",
}

export enum ActionHistoricEnum {
  approved_fin_ops = "Aprobado por FinOps",
  declined_fin_ops = "Declinado por FinOps",
  send_by_fin_ops = "Enviado por FinOps",
  approved_by_processor = "Aprobado por el procesador",
  declined_by_processor = "Declinado por el procesador",
  send_to_processor = "Enviado al procesador",
  notDefined = "N/A",
}

export enum ActionKeyEnum {
  RPY = "RPY",
  GBCK = "GBCK",
  OMT = "OMT",
  CNCL = "CNCL",
  RTY = "RTY",
}

export enum ActionApprovalEnum {
  ApprovalRPY = "ApprovalRPY",
  NoApprovalRPY = "NoApprovalRPY",
  ApprovalGBCK = "ApprovalGBCK",
  NoApprovalGBCK = "NoApprovalGBCK",
  ApprovalOMT = "ApprovalOMT",
  NoApprovalOMT = "NoApprovalOMT",
  ApprovalCNCL = "ApprovalCNCL",
  NoApprovalCNCL = "NoApprovalCNCL",
  Reentry = "Reentry",
  Error = "Error",
}

export enum ActionRefundEnum {
  REFUND = "REFUND",
  GIVEBACK = "GIVEBACK",
  SKIP = "SKIP",
  OMIT = "OMIT",
  CANCEL = "CANCEL",
  APPROVE = "APPROVE",
  NOAPPROVE = "NOAPPROVE",
}

export enum ActionResponseEnum {
  refundApprove = "Reembolso - Aprobado",
  refundDeclined = "Reembolso - Declinado",
  refundSend = "Reembolso - Enviado",
  givebackApprove = "Devolver - Aprobado",
  givebackDeclined = "Devolver - Declinado",
  givebackSend = "Devolver - Enviado",
  cancelApprove = "Cancelar - Aprobado",
  cancelDeclined = "Cancelar - Declinado",
  cancelSend = "Cancelar - Enviado",
  omit = "Omitir",
}

export const ActionRefundTitleEnum: Record<string, string> = {
  [ActionRefundEnum.REFUND]: "Reembolsar",
  [ActionRefundEnum.GIVEBACK]: "Devolver",
  [ActionRefundEnum.OMIT]: "Omitir",
  [ActionRefundEnum.CANCEL]: "Cancelar",
  [ActionRefundEnum.APPROVE]: "Autorizar transacciones",
  [ActionRefundEnum.NOAPPROVE]: "No Autorizar transacciones",
};

export const ActionRefundSubTitleEnum: Record<string, string> = {
  [ActionRefundEnum.REFUND]:
    "Escribe el motivo del porque se reembolsa las transacciones:",
  [ActionRefundEnum.GIVEBACK]:
    "Escribe el motivo del porque se devuelve las transacciones:",
  [ActionRefundEnum.OMIT]:
    "Escribe el motivo del porque se omite las transacciones:",
  [ActionRefundEnum.CANCEL]:
    "Escribe el motivo del porque se cancela las transacciones:",
  [ActionRefundEnum.APPROVE]:
    "Escribe el motivo del porque se autorizan las transacciones:",
  [ActionRefundEnum.NOAPPROVE]:
    "Escribe el motivo del porque NO se autorizan las transacciones:",
};

export const ACTIONS_LIST: object = {
  [ActionKeyEnum.RPY]: {
    background: "#F0F4F9",
    color: "#6D7781",
    name: ActionEnum.refund,
  },
  [ActionKeyEnum.OMT]: {
    background: "#FFEAEE",
    color: "#AD0C2A",
    name: ActionEnum.skip,
  },
  [ActionKeyEnum.GBCK]: {
    background: "#FFF1D0",
    color: "#DFA720",
    name: ActionEnum.giveback,
  },
  [ActionKeyEnum.CNCL]: {
    background: "#FEECF8",
    color: "#8F4F8B",
    name: ActionEnum.cancel,
  },
};
export enum RequestActionEnum {
  refund_v = "RPY",
  giveback = "GBCK",
  skip = "OMT",
  cancel = "CNCL",
  APRRFN = "APRRFN",
  DCLRFN = "DCLRFN",
  APRGBCK = "APRGBCK",
  DCLGBCK = "DCLGBCK",
  APROMT = "APROMT",
  DCLOMT = "DCLOMT",
  APRCNL = "APRCNL",
  DCLCNL = "DCLCNL",
  RTY = "RTY",
  RPY = "RPY",
  RTRY = "RTRY",
}

export enum FranchiseEnum {
  prosa = "Prosa",
}

export enum ProcessorNameEnum {
  kushki_acquirer_processor = "Kushki Acquirer Processor",
}

export enum ActionDescription {
  refund_v = "Escribe el motivo por el que se está ejecutando esta acción:",
  giveback = "Escribe el motivo del porque se devuelve la transacción:",
  skip = "Escribe el motivo del porque se omite la transacción:",
  cancel = "Escribe el motivo del porque se cancela la transacción:",
  historic = "Escribe el motivo del ingreso de respuesta",
}

export enum ActionSuccessMessage {
  success_general_message = "Transacción enviada a autorizar",
  success_aprrfn_message = "Transacción Autorizada",
  success_dclrfn_message = "Transacción No autorizada",
  success_reentry_message = "Transacción reingresada",
}

export enum HistoricTitleEnum {
  historic_title = "Motivo de ingreso de respuesta",
}

export enum OptionValueApproval {
  approve = "Approve",
  not_approve = "NotApprove",
}

export const ActionRefundHistoryTitleEnum: Record<string, string> = {
  [RequestActionEnum.APRRFN]: ActionEnum.refund,
  [RequestActionEnum.APRGBCK]: ActionEnum.giveback,
  [RequestActionEnum.APRCNL]: ActionEnum.cancel,
  [RequestActionEnum.APROMT]: ActionEnum.skip,
};

export const MENU_OPTIONS_VALIDATOR: object[] = [
  {
    label: ActionOptionsEnum.Autorizar,
    title: "Autorizar transacción",
    value: OptionValueApproval.approve,
  },
  {
    label: ActionOptionsEnum.No_autorizar,
    title: "No Autorizar transacción",
    value: OptionValueApproval.not_approve,
  },
];

export const MENU_ACTIONS: object[] = [
  { label: ActionEnum.refund_v, value: ActionEnum.refund_v },
  { label: ActionEnum.giveback, value: ActionEnum.giveback },
  { label: ActionEnum.cancel, value: ActionEnum.cancel },
  { label: ActionEnum.skip, value: ActionEnum.skip },
];

export const MENU_ACTIONS_HISTORIC: object[] = [
  {
    label: ActionHistoricEnum.approved_fin_ops,
    value: ActionHistoricEnum.approved_fin_ops,
  },
  {
    label: ActionHistoricEnum.declined_fin_ops,
    value: ActionHistoricEnum.declined_fin_ops,
  },
  {
    label: ActionHistoricEnum.approved_by_processor,
    value: ActionHistoricEnum.approved_by_processor,
  },
  {
    label: ActionHistoricEnum.declined_by_processor,
    value: ActionHistoricEnum.declined_by_processor,
  },
];

export const MODAL_ACTIONS_DESCRIPTION: Record<string, string> = {
  [ActionEnum.cancel]: ActionDescription.cancel,
  [ActionEnum.giveback]: ActionDescription.giveback,
  [ActionEnum.skip]: ActionDescription.skip,
  [ActionEnum.refund_v]: ActionDescription.refund_v,
  [ActionHistoricEnum.approved_fin_ops]: ActionDescription.historic,
  [ActionHistoricEnum.declined_fin_ops]: ActionDescription.historic,
  [ActionHistoricEnum.approved_by_processor]: ActionDescription.historic,
  [ActionHistoricEnum.declined_by_processor]: ActionDescription.historic,
  [OptionValueApproval.approve]: ActionDescription.refund_v,
  [OptionValueApproval.not_approve]: ActionDescription.refund_v,
};

export const MAP_ACTION_VALIDATOR: Record<string, ActionApprovalEnum> = {
  [`${OptionValueApproval.approve}/${ActionKeyEnum.RPY}`]:
    ActionApprovalEnum.ApprovalRPY,
  [`${OptionValueApproval.not_approve}/${ActionKeyEnum.RPY}`]:
    ActionApprovalEnum.NoApprovalRPY,
  [`${OptionValueApproval.approve}/${ActionKeyEnum.GBCK}`]:
    ActionApprovalEnum.ApprovalGBCK,
  [`${OptionValueApproval.not_approve}/${ActionKeyEnum.GBCK}`]:
    ActionApprovalEnum.NoApprovalGBCK,
  [`${OptionValueApproval.approve}/${ActionKeyEnum.OMT}`]:
    ActionApprovalEnum.ApprovalOMT,
  [`${OptionValueApproval.not_approve}/${ActionKeyEnum.OMT}`]:
    ActionApprovalEnum.NoApprovalOMT,
  [`${OptionValueApproval.approve}/${ActionKeyEnum.CNCL}`]:
    ActionApprovalEnum.ApprovalCNCL,
  [`${OptionValueApproval.not_approve}/${ActionKeyEnum.CNCL}`]:
    ActionApprovalEnum.NoApprovalCNCL,
  [`${ActionKeyEnum.RTY}/`]: ActionApprovalEnum.Reentry,
  ["/"]: ActionApprovalEnum.Error,
};

export const MODAL_ACTIONS_REQUEST: Record<string, string> = {
  [ActionEnum.cancel]: RequestActionEnum.cancel,
  [ActionEnum.giveback]: RequestActionEnum.giveback,
  [ActionEnum.skip]: RequestActionEnum.skip,
  [ActionEnum.refund_v]: RequestActionEnum.refund_v,
  [ActionKeyEnum.RTY]: RequestActionEnum.RTY,
  [ActionKeyEnum.RPY]: RequestActionEnum.RPY,
  [ActionApprovalEnum.ApprovalRPY]: RequestActionEnum.APRRFN,
  [ActionApprovalEnum.NoApprovalRPY]: RequestActionEnum.DCLRFN,
  [ActionApprovalEnum.ApprovalGBCK]: RequestActionEnum.APRGBCK,
  [ActionApprovalEnum.NoApprovalGBCK]: RequestActionEnum.DCLGBCK,
  [ActionApprovalEnum.ApprovalOMT]: RequestActionEnum.APROMT,
  [ActionApprovalEnum.NoApprovalOMT]: RequestActionEnum.DCLOMT,
  [ActionApprovalEnum.ApprovalCNCL]: RequestActionEnum.APRCNL,
  [ActionApprovalEnum.NoApprovalCNCL]: RequestActionEnum.DCLCNL,
  [ActionApprovalEnum.Reentry]: RequestActionEnum.RTRY,
};

export const MODAL_ACTIONS_SUCCESS_MESSAGE: Record<string, string> = {
  [RequestActionEnum.cancel]: ActionSuccessMessage.success_general_message,
  [RequestActionEnum.giveback]: ActionSuccessMessage.success_general_message,
  [RequestActionEnum.skip]: ActionSuccessMessage.success_general_message,
  [RequestActionEnum.refund_v]: ActionSuccessMessage.success_aprrfn_message,
  [RequestActionEnum.APRRFN]: ActionSuccessMessage.success_aprrfn_message,
  [RequestActionEnum.DCLRFN]: ActionSuccessMessage.success_dclrfn_message,
  [RequestActionEnum.APRGBCK]: ActionSuccessMessage.success_general_message,
  [RequestActionEnum.DCLGBCK]: ActionSuccessMessage.success_dclrfn_message,
  [RequestActionEnum.APROMT]: ActionSuccessMessage.success_general_message,
  [RequestActionEnum.DCLOMT]: ActionSuccessMessage.success_dclrfn_message,
  [RequestActionEnum.APRCNL]: ActionSuccessMessage.success_general_message,
  [RequestActionEnum.DCLCNL]: ActionSuccessMessage.success_dclrfn_message,
  [RequestActionEnum.RTRY]: ActionSuccessMessage.success_reentry_message,
};

export const MODAL_ACTIONS_ERROR_MESSAGE: Record<string, string> = {
  [RequestActionEnum.cancel]: ActionEnum.cancel,
  [RequestActionEnum.giveback]: ActionEnum.giveback,
  [RequestActionEnum.skip]: ActionEnum.skip,
  [RequestActionEnum.refund_v]: ActionEnum.refund_v,
  [RequestActionEnum.APRRFN]: ActionOptionsEnum.Autorizar,
  [RequestActionEnum.DCLRFN]: ActionOptionsEnum.No_autorizar,
  [RequestActionEnum.RTRY]: ActionEnum.reentry,
};

export const defaultMenuOptions: object = {
  [StatusFlowEnum.EXECUTOR]: MENU_ACTIONS,
  [StatusFlowEnum.EXECUTOR_HISTORY]: MENU_ACTIONS_HISTORIC,
  [StatusFlowEnum.VALIDATOR]: MENU_OPTIONS_VALIDATOR,
  [StatusFlowEnum.VALIDATOR_HISTORY]: [],
  [StatusFlowEnum.VIEWER]: MENU_ACTIONS,
};
