import React from "react";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { StyledToggleButtonGroup } from "../commons/StyledToggleButtonGroup/StyledToggleButtonGroup";
import { ToggleButton } from "@material-ui/lab";
import { StyledToggleButtonGroupGrace } from "../commons/StyledToggleButtonGroup/StyledToggleButtonGroupGrace";
import { makeStyles } from "@material-ui/core/styles";
import { useStateApp } from "../../shared/state/useStateApp";

export interface MexicoMonthSectionProps {
  mainTittle: string;
  secondTittle: string;
  mainMonths: string[];
  mainMerchantMonths: string[];
  secondMonths?: string[];
  secondMerchantMonth?: string[];
  index: number;
  handleSetMonthVariant(
    event: React.MouseEvent<HTMLElement>,
    value: string[],
    index: number
  ): void;
  handleSetMonthOfGraceVariant(
    event: React.MouseEvent<HTMLElement>,
    value: string[],
    index: number
  ): void;
}

const useStyles = makeStyles(() => ({
  cardH2: {
    color: "#293036",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "19.6px",
  },
  divider: {
    marginTop: "30px",
  },
  padding: {
    paddingBottom: "15px",
  },
  toggleButtonGroup: {
    flexWrap: "wrap",
  },
}));

export const MexicoMonthSection: React.FC<MexicoMonthSectionProps> = (
  props: MexicoMonthSectionProps
) => {
  const { isMobile } = useStateApp();
  const classes = useStyles();

  return (
    <Box mb={"24px"}>
      <Grid container className={classes.padding}>
        <Grid
          item
          xs={12}
          md={props.secondMonths && props.secondMonths.length > 0 ? 6 : 12}
          container
        >
          <Box pl={"47px"} mt={"26px"}>
            <Typography className={classes.cardH2}>
              {props.mainTittle}
            </Typography>
            <Box pt={"10px"} display={"flex"} flexDirection={"row"}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                width={"100%"}
                justifyContent={"flex-start"}
                flexWrap={"wrap"}
              >
                <StyledToggleButtonGroup
                  value={props.mainMerchantMonths}
                  defaultValue={props.mainMerchantMonths}
                  className={classes.toggleButtonGroup}
                  onChange={(e, value) =>
                    props.handleSetMonthVariant(e, value, props.index)
                  }
                >
                  {props.mainMonths.map((month: string) => (
                    <ToggleButton key={month} value={month}>
                      {month}
                    </ToggleButton>
                  ))}
                </StyledToggleButtonGroup>
              </Box>
            </Box>
          </Box>
        </Grid>
        {props.secondMonths && props.secondMonths.length > 0 && (
          <Grid item xs={12} md={6} container>
            {!isMobile && (
              <Divider
                orientation="vertical"
                flexItem
                className={classes.divider}
              />
            )}
            <Box pl={"47px"} mt={"26px"}>
              <Typography className={classes.cardH2}>
                {props.secondTittle}
              </Typography>
              <Box pt={"10px"} display={"flex"} flexDirection={"row"}>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  width={"100%"}
                  justifyContent={"flex-start"}
                  flexWrap={"wrap"}
                >
                  <StyledToggleButtonGroupGrace
                    value={props.secondMerchantMonth}
                    defaultValue={props.secondMerchantMonth}
                    className={classes.toggleButtonGroup}
                    onChange={(e, value) =>
                      props.handleSetMonthOfGraceVariant(e, value, props.index)
                    }
                  >
                    {props.secondMonths.map((month: string) => (
                      <ToggleButton key={month} value={month}>
                        {month}
                      </ToggleButton>
                    ))}
                  </StyledToggleButtonGroupGrace>
                </Box>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
