import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from "@mui/material";
import { isEmpty } from "lodash";
import React from "react";
import { IRadioForm } from "./RadioForm.interfaces";

const RadioForm: React.FC<IRadioForm> = ({
  error,
  options,
  disable,
  field,
}: IRadioForm) => {
  return (
    <FormControl>
      <RadioGroup
        {...field}
        row
        aria-labelledby="row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        {!isEmpty(options) &&
          !!options &&
          options.map((item) => (
            <FormControlLabel
              key={item.value}
              value={item.value}
              control={<Radio data-testid={"radioTest" + item.name} />}
              label={item.name}
              disabled={disable}
            />
          ))}
      </RadioGroup>
      <FormHelperText>{error ? error.message : null}</FormHelperText>
    </FormControl>
  );
};

export default RadioForm;
