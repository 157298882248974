import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IAppState } from "../../store/reducer";
import { useServicesSection } from "./state/useServicesSection";
import Edit from "../../assets/images/Edit";
import Button from "@material-ui/core/Button";
import { BasicInfoSkeleton } from "../../components/Skeletons/BasicInfoSkeleton/BasicInfoSkeleton";
import { CardTypeList } from "../../components/CardTypeList/CardTypeList";

const useStyles = makeStyles(() => ({
  typography: {
    fontWeight: 500,
    padding: "8px",
    paddingLeft: "0px",
    fontSize: "16px",
    fontStyle: "bold",
    color: "black",
  },
  typographyMobile: {
    fontWeight: 500,
    padding: "8px",
    paddingLeft: "0px",
    fontSize: "16px",
    fontStyle: "bold",
    color: "black",
  },
  descriptionLabel: {
    fontWeight: 200,
    fontSize: "14px",
    color: "#6D7781",
  },
  informationLabel: {
    fontWeight: 200,
    fontSize: "14px",
    color: "#293036",
  },
  copyIcon: {
    marginLeft: 10,
    padding: "0px",
    color: "#CBD5E0",
  },
  padding: {
    paddingBottom: "1px",
  },
  statusBadge: {
    padding: "4px 8px",
    borderRadius: "4px",
    width: 75,
    textAlign: "center",
    marginBottom: 5,
  },
  paper: {
    backgroundColor: "#F7FAFC",
    width: "100%",
  },
  statusLabel: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "100%",
  },
  editInfo: {
    padding: "0px",
    minWidth: "40px",
    backgroundColor: "#F0F4F9",
  },
  gridContainer: {
    paddingBottom: "0px",
  },
}));

export interface IConfigHeaderProps extends IAppState {
  title: string;
}

export const ServicesSection: React.FC<IConfigHeaderProps> = ({
  title,
}: IConfigHeaderProps) => {
  const classes = useStyles();

  const {
    merchant,
    handleEditServices,
    loading,
    activeService,
    conditions,
    subscriptionValidation,
    acceptedCards,
    isMobile,
  } = useServicesSection();
  return (
    <React.Fragment>
      <Grid container spacing={1} className={classes.padding}>
        {loading ? (
          <BasicInfoSkeleton></BasicInfoSkeleton>
        ) : (
          <Grid
            item
            xs={12}
            md={9}
            alignItems="center"
            justify="flex-start"
            direction="row"
          >
            <Box
              display={"flex"}
              alignContent={"center"}
              flexDirection={"row"}
              m={2}
            >
              <Typography
                className={
                  isMobile ? classes.typographyMobile : classes.typography
                }
                color={"primary"}
                variant={isMobile ? "h3" : "h1"}
              >
                {title}
              </Typography>
              <Button
                onClick={() => handleEditServices(merchant)}
                className={classes.editInfo}
              >
                <Edit />
              </Button>
            </Box>
            <Box display={"flex"} m={2}>
              <Grid container spacing={0} xs={12}>
                <Grid item xs={6}>
                  <Typography className={classes.descriptionLabel}>
                    Servicios Activos:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.informationLabel}>
                    {activeService === ""
                      ? "No existen servicios activos"
                      : activeService}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.descriptionLabel}>
                    Condiciones:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.informationLabel}>
                    {conditions === "" ? "No existen condiciones" : conditions}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.descriptionLabel}>
                    Tarjetas para cajita:
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ padding: "0px", margin: "0px" }}>
                  <Box
                    display="flex"
                    flexDirection={"row"}
                    style={{ margin: "0px" }}
                  >
                    {acceptedCards.map((card, index) => (
                      <CardTypeList key={index} name={card} image={card} />
                    ))}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.descriptionLabel}>
                    Cobros de validación:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.informationLabel}>
                    {subscriptionValidation}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default ServicesSection;
