import { IStyles } from "../../../../shared/interfaces/Styles.interfaces";
import { makeStyles } from "@mui/styles";
import { ObservationItemStatus } from "../../../../store/interfaces/TransactionsState.interfaces";
import { ConciliationActionCodes } from "../../../../shared/infrastructure/constants/ConciliationAction";
import { ChipDetailsStylesProps } from "./DetailHeader.interfaces";

export const DetailHeaderStyles: IStyles = {
  clipboardGrid: {
    paddingLeft: "30px",
  },
  container: {},
  copyURLButton: {
    color: "text.primary",
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "140%",
    padding: "0 0 7px 0",
    typography: "subtitle2",
  },
  headerLine: {
    color: "text.primary",
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: "120%",
    typography: "h4",
  },
  subheaderLine: {
    color: "text.grey",
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "140%",
    margin: "8px 0px",
  },
  tab: {
    color: "neutral.grey8",
    fontFamily: "IBM Plex Sans, Helvetica, sans-serif",
    fontSize: "14px",
    lineHeight: "170%",
    width: "33.33%",
  },
  tabsContainer: {
    borderBottom: 1,
    borderColor: "neutral.grey5",
  },
  title: {
    color: "text.grey",
    fontWeight: "normal",
    lineHeight: "136%",
    margin: "4px 0px",
    typography: "caption",
    //@ts-ignore
    fontSize: "15px !important",
    paddingBottom: "16px",
  },
  titleContainer: {
    padding: "16px 24px 9px 24px",
  },
};

export const useChipDetailsStyles = makeStyles(() => ({
  chip: {
    "&.MuiChip-root": {
      backgroundColor: (props: ChipDetailsStylesProps) => {
        switch (props.status) {
          case ConciliationActionCodes.DCLRFN_PRCS:
          case ConciliationActionCodes.APRCNL_FNPS:
          case ConciliationActionCodes.DCLCNL_FNPS:
          case ConciliationActionCodes.APRGBCK_FNPS:
          case ConciliationActionCodes.DCLGBCK_FNPS:
          case ConciliationActionCodes.APRRFN_PRCS:
            return "#FFFFFF";
          case ObservationItemStatus.CONCILIATED:
            return "#E0FFF8";
          case ObservationItemStatus.PENDING_REFUND:
          case ObservationItemStatus.PENDING:
            return "#FEECF8";
          case ObservationItemStatus.REVIEW_KUSHKI:
          case ObservationItemStatus.OMITTED:
          case ObservationItemStatus.REVIEW_PROCESSOR:
            return "#F0F4F9";
          default:
            return "#F0F4F9";
        }
      },
      color: (props: ChipDetailsStylesProps) => {
        switch (props.status) {
          case ConciliationActionCodes.APRRFN_PRCS:
          case ConciliationActionCodes.DCLRFN_PRCS:
          case ConciliationActionCodes.APRCNL_FNPS:
          case ConciliationActionCodes.DCLCNL_FNPS:
          case ConciliationActionCodes.APRGBCK_FNPS:
          case ConciliationActionCodes.DCLGBCK_FNPS:
            return "#677684";
          case ObservationItemStatus.CONCILIATED:
            return "#0DC298";
          case ObservationItemStatus.PENDING_REFUND:
          case ObservationItemStatus.PENDING:
            return "#8F4F8B";
          case ObservationItemStatus.REVIEW_KUSHKI:
          case ObservationItemStatus.OMITTED:
          case ObservationItemStatus.REVIEW_PROCESSOR:
            return "#6D7781";
          default:
            return "#6D7781";
        }
      },
    },
    border: (props: ChipDetailsStylesProps) => {
      switch (props.status) {
        case ConciliationActionCodes.APRRFN_PRCS:
        case ConciliationActionCodes.DCLRFN_PRCS:
        case ConciliationActionCodes.APRCNL_FNPS:
        case ConciliationActionCodes.DCLCNL_FNPS:
        case ConciliationActionCodes.APRGBCK_FNPS:
        case ConciliationActionCodes.DCLGBCK_FNPS:
        case ConciliationActionCodes.APROMT:
          return "1px solid #677684";
        default:
          return "";
      }
    },
    fontSize: "9px!important",
    fontWeight: "500",
    padding: "8px",
    borderRadius: "0px!important",
  },
}));
