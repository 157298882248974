import { createNamedStyles } from "../../shared/infrastructure/interfaces/create_named_styles";

export const useRuleDetailStyles = createNamedStyles({
  detailWrapper: {
    paddingTop: 1,
  },
  firstGrid: {
    borderStyle: "solid",
    borderColor: "neutral.grey4",
    borderTopWidth: "0",
    borderBottomWidth: "0",
    borderLeftWidth: "0",
    borderRightWidth: "0.3",
    paddingRight: "30px",
    maxHeight: "200px",
    overflowY: "scroll",
  },
  middleGrid: {
    paddingLeft: "30px",
    paddingRight: "30px",
    maxHeight: "200px", //OJO
    overflowY: "scroll",
  },
  lastGrid: {
    paddingLeft: "30px",
    paddingRight: "30px",
    maxHeight: "200px",
    overflowY: "scroll",
  },
  chipWrapper: {
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  categoryDetail: {
    backgroundColor: "neutral.grey4",
    padding: "20px",
    maxWidth: "30rem",
  },
});
